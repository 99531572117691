import { ScreenType, screenMatcher } from '@fiji/modes'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import { DesktopNavigation } from '@etta/modules/navigation'
import type { Props } from './types'

export function DesktopScreenByFeatureFlag({
  isDesktopLayoutEnabled,
  isTripCartDisplayed,
  children,
}: Props) {
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  if (isMobile || isDesktopLayoutEnabled) {
    return <>{children}</>
  }

  return (
    <ScreenDesktop>
      <DesktopNavigation isTripCartDisplayed={isTripCartDisplayed} />

      <ScreenDesktop.Content backgroundColor="background">{children}</ScreenDesktop.Content>
    </ScreenDesktop>
  )
}
