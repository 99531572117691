import { Domain } from '@etta/core/enums'
import { Inject, Service } from '@etta/di'
import { CarRentalSearchStore } from '@etta/modules/car-rental-search/interface/stores/car-rental-search.store'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CartAdapter, ItineraryAdapter } from '@etta/modules/review-trip/infra'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import type { AddCarRentalArgs } from '../types'
import { SegmentTypeCheckService } from './segment-type-check.service'

@Service()
export class ReviewTripCarRentalService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,
    @Inject()
    private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  async removeCarRental({ carId }: { carId: string }) {
    const isMod2FlowEnabled = this.displayConfigurationStore.isMod2FlowEnabled

    if (isMod2FlowEnabled) {
      const carRental = this.reviewTripStore.trip?.segments.find((segment) => {
        if (this.segmentTypeCheckService.isCarRentalSegment(segment)) {
          return segment.carId === carId
        }
      })

      const intentId = carRental?.uuid
      const tripId = this.reviewTripStore.tripId
      const result = await this.cartAdapter.removeFromCart({ intentId, cartId: tripId })

      return result
    }

    const result = await this.itineraryAdapter.removeCarRental({
      carId,
      itineraryId: this.reviewTripStore.itineraryId,
    })

    return result
  }

  async replaceCarRental({
    carRentalKey,
    oldCarRentalKey,
  }: {
    carRentalKey: string
    oldCarRentalKey: string
  }) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      const result = await this.cartAdapter.addModifyToCart({
        cartId: this.reviewTripStore.tripId,
        intentDomain: Domain.CarRental,
        searchId: this.carRentalSearchStore.searchId || '',
        inventoryId: carRentalKey,
        reservationId: oldCarRentalKey,
      })
      return result
    }

    const result = await this.itineraryAdapter.replaceCarRental({
      itineraryId: this.reviewTripStore.itineraryId,
      newKey: carRentalKey,
      oldKey: oldCarRentalKey,
    })

    return result
  }

  async addCarRental({ carRentalKey, itineraryId }: AddCarRentalArgs) {
    const { isMod2FlowEnabled } = this.displayConfigurationStore
    if (isMod2FlowEnabled) {
      const result = await this.cartAdapter.addToCart({
        cartId: this.reviewTripStore.tripId,
        searchId: this.carRentalSearchStore.searchId || '',
        intentDomain: Domain.CarRental,
        inventoryId: carRentalKey,
      })

      return result
    }

    const result = await this.itineraryAdapter.addCarRental({
      itineraryId,
      key: carRentalKey,
      searchId: this.carRentalSearchStore.searchId || undefined,
    })

    return result
  }
}
