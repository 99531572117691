export const TravelPreferencesFieldsSettings = {
  homeAirport: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  airMeal: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  airSeatPreference: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  airSpecialRequest: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  airTravelAgencyNote: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  hotelSpecialRequest: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  carRentalType: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
  carRentalSpecialRequest: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
    isRenderedOnActivationPage: false,
  },
}
