import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 24px 0 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Context = styled.div`
  margin-top: 12px;
`
