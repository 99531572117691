import { checkIsPwa } from './check-is-pwa'

export function pwaEnv() {
  let isProgressiveWebAppFromUser: null | boolean = null

  window.setIsProgressiveWebApp = (value) => {
    isProgressiveWebAppFromUser = value
  }

  const isPwa = checkIsPwa()

  return {
    get isProgressiveWebAppEnabled() {
      return isProgressiveWebAppFromUser === null ? isPwa : isProgressiveWebAppFromUser
    },
    set isProgressiveWebAppEnabled(value) {
      isProgressiveWebAppFromUser = value
    },
  }
}
