import { useTranslation } from 'react-i18next'
import { Radio } from '@etta/ui/radio'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import type { SeatPreferences } from '@fiji/graphql/types'
import { Section, SectionTitle } from '../../trip-review-seat-preferences-styles'
import type { PreferencesEU } from '../../types'

type Props = {
  preferenceOptions: SeatPreferences
  preferences: PreferencesEU
  onPreferencesChange: (newPreferences: Partial<PreferencesEU>) => void
}

const i18Base = 'TripReview.SeatPreferencesModal'

export function TripReviewSeatPreferencesEU({
  preferenceOptions,
  onPreferencesChange,
  preferences,
}: Props) {
  const { t } = useTranslation()

  const onSeatChange = (value: string) => {
    onPreferencesChange({ sitNear: { ...preferences.sitNear, seat: value } })
  }

  const onCarriageChange = (value: string) => {
    onPreferencesChange({ sitNear: { ...preferences.sitNear, carriage: value } })
  }
  return (
    <>
      <Section withBorder>
        <SectionTitle>{t(i18Base + '.Labels.SeatPosition')}</SectionTitle>
        {preferenceOptions.seatPositions.map(({ code, name }, index) => (
          <Block key={index} paddingVertical={18}>
            <Radio
              onChange={() => onPreferencesChange({ seatPositions: code })}
              isChecked={preferences.seatPositions === code}>
              {name}
            </Radio>
          </Block>
        ))}
      </Section>
      <Section withBorder>
        <SectionTitle>{t(i18Base + '.Labels.Deck')}</SectionTitle>
        {preferenceOptions.seatDirections.map(({ code, name }, index) => (
          <Block key={index} paddingVertical={18}>
            <Radio
              onChange={() => onPreferencesChange({ seatDirections: code })}
              isChecked={preferences.seatDirections === code}>
              {name}
            </Radio>
          </Block>
        ))}
      </Section>
      <Section withBorder>
        <SectionTitle>{t(i18Base + '.Labels.SitNear')}</SectionTitle>
        <TextField
          type="number"
          onChange={onCarriageChange}
          value={preferences.sitNear.carriage}
          placeholder={t(i18Base + '.Placeholders.Carriage')}
        />
        <Block paddingTop={16}>
          <TextField
            type="number"
            onChange={onSeatChange}
            value={preferences.sitNear.seat}
            placeholder={t(i18Base + '.Placeholders.Seat')}
          />
        </Block>
      </Section>
    </>
  )
}
