import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

export function useReactivatedItinerary() {
  const { tripActions, reactivateTripActions } = useReviewTripContext()

  const {
    displayConfigurationStore: { isMod2FlowEnabled },
  } = useDisplayConfigurationContext()

  const reactivateItinerary = async ({
    action,
    itineraryId,
  }: {
    action: string
    itineraryId?: string
  }) => {
    if (isMod2FlowEnabled) {
      return
    }

    const isSuccess = await reactivateTripActions.handleReactivateTrip()

    if (!isSuccess) {
      throw new Error(`Itinerary reactivation was unsuccessfull at ${action}`)
    }

    if (itineraryId) {
      tripActions.setTripId(itineraryId)
    }
  }

  return { reactivateItinerary }
}
