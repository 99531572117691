import styled from 'styled-components'
import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

export const Description = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'footnoteMedium',
  color: 'mainText1',
}))``
