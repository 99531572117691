import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { AnimationItem } from '@etta/ui/lottie-animation'
import { SegmentType } from '@etta/core/enums'
import { ScreenType, useScreenType } from '@fiji/modes'

type Args = {
  bookingEntitiesKeys: SegmentType[]
  isHoldFlow?: boolean
  isPostBookingFlow?: boolean
}

const i18nBase = 'PaymentSummary'

export function useLottieAnimation({ bookingEntitiesKeys, isHoldFlow, isPostBookingFlow }: Args) {
  const { t } = useTranslation()

  const [flightAnimation, setFlightAnimation] = useState<AnimationItem | null>(null)
  const [hotelAnimation, setHotelAnimation] = useState<AnimationItem | null>(null)
  const [carRentalAnimation, setCarRentalAnimation] = useState<AnimationItem | null>(null)
  const [trainAnimation, setTrainAnimation] = useState<AnimationItem | null>(null)
  const [activeAnimation, setActiveAnimation] = useState<SegmentType | null>(null)
  const [isAnimationPlayed, setIsAnimationPlayed] = useState(false)

  const screenType = useScreenType()

  const isMobile = screenType === ScreenType.Mobile

  const hasAnyAnimation = Boolean(
    flightAnimation || hotelAnimation || carRentalAnimation || trainAnimation,
  )

  const animationStatusLabel = useMemo(() => {
    if (isMobile) {
      return t(`${i18nBase}.BookingStatusLabel`)
    }

    if (isPostBookingFlow) {
      return t(`${i18nBase}.UpdatingStatusLabel`)
    }

    if (isHoldFlow) {
      return t(`${i18nBase}.HoldingStatusLabel`)
    }

    return t(`${i18nBase}.BookingStatusLabel`)
  }, [isHoldFlow, isMobile, isPostBookingFlow, t])

  function getAnimationLabel({
    segmentType,
    statusLabel,
  }: {
    segmentType?: SegmentType | null
    statusLabel: string
  }) {
    switch (segmentType) {
      case SegmentType.Flight:
        return t(`${i18nBase}.FlightBookingLabel`, { statusLabel })
      case SegmentType.CarRental:
        return t(`${i18nBase}.CarRentalBookingLabel`, { statusLabel })
      case SegmentType.Hotel:
        return t(`${i18nBase}.HotelBookingLabel`, { statusLabel })
      case SegmentType.Train:
        return t(`${i18nBase}.RailBookingLabel`, { statusLabel })
      default:
        return ''
    }
  }

  const animationLabel = getAnimationLabel({
    segmentType: activeAnimation,
    statusLabel: animationStatusLabel,
  })

  const animationPromise = useCallback(
    (animation: SegmentType) =>
      new Promise<void>((resolve) => {
        const animationsDict: { [key: string]: AnimationItem | null } = {
          [SegmentType.Flight]: flightAnimation,
          [SegmentType.CarRental]: carRentalAnimation,
          [SegmentType.Hotel]: hotelAnimation,
          [SegmentType.Train]: trainAnimation,
        }

        if (!animationsDict[animation]) {
          resolve()

          return
        }

        animationsDict[animation]?.play()
        setActiveAnimation(animation)

        const onComplete = animationsDict[animation]?.addEventListener('complete', () => {
          const onEnterFrame = animationsDict[animation]?.addEventListener('enterFrame', () => {
            animationsDict[animation]?.removeEventListener('enterFrame', onEnterFrame)
            animationsDict[animation]?.removeEventListener('complete', onComplete)
          })

          animationsDict[animation]?.stop()
          resolve()
        })
      }),
    [carRentalAnimation, flightAnimation, hotelAnimation, trainAnimation],
  )

  const runAnimations = useCallback(async () => {
    for (const segment of bookingEntitiesKeys) {
      await animationPromise(segment)
    }
    setIsAnimationPlayed(true)
  }, [animationPromise, bookingEntitiesKeys])

  useEffect(() => {
    if (!hasAnyAnimation || !bookingEntitiesKeys.length) {
      return
    }

    runAnimations().then(async () => {
      if (isAnimationPlayed) {
        while (true) {
          setIsAnimationPlayed(false)
          await runAnimations()
        }
      }
    })
  }, [runAnimations, isAnimationPlayed, hasAnyAnimation, bookingEntitiesKeys])

  return {
    animationLabel,
    setFlightAnimation,
    setCarRentalAnimation,
    setHotelAnimation,
    setTrainAnimation,
  }
}
