import { Button } from '@etta/ui/button'
import { Description } from '../../trip-no-content-styles'
import { default as Background } from '../../background.svg'
import { DescriptionContent } from '../../description-content'
import type { LayoutProps } from '../types'
import { Wrapper, Container } from './trip-no-content-mobile.styled'

export function TripNoContentMobile({
  descriptionAriaLabel,
  onButtonClick,
  buttonLabel,
  buttonAriaLabelCaption,
  noContentInfoTranslation,
  tripTypeTranslation,
  tripsTranslation,
}: LayoutProps) {
  return (
    <Container>
      <Wrapper>
        <Background style={{ marginTop: '56px' }} />
        <Description role="heading" aria-level={2} aria-label={descriptionAriaLabel}>
          <DescriptionContent
            noContentInfoTranslation={noContentInfoTranslation}
            tripTypeTranslation={tripTypeTranslation}
            tripsTranslation={tripsTranslation}
          />
        </Description>
        <Button onClick={onButtonClick} fullWidth={true} aria-label={buttonAriaLabelCaption}>
          {buttonLabel}
        </Button>
      </Wrapper>
    </Container>
  )
}
