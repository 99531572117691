import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import type { Address, AirportAddress } from '@fiji/graphql/types'
import { changeArrivalDate, changeReturnDate } from './change-date'

export function convertFlightToRangeRTP(flightSegments: FlightSegmentEntity[]) {
  const destinationSegment = flightSegments[0].segments[flightSegments[0].segments.length - 1]
  const theFirstSegmentInSecondFlight =
    flightSegments.length > 1 ? flightSegments[1].segments[0] : null
  const lastFlightSegment = flightSegments[flightSegments.length - 1]
  const lastSegment = lastFlightSegment.segments[lastFlightSegment.segments.length - 1]

  const isRoundTrip =
    flightSegments.length > 1 &&
    flightSegments[0].segments[0].departure.airportCode === lastSegment.arrival.airportCode

  const isOpenJaw =
    flightSegments.length > 1 &&
    flightSegments[0].segments.at(-1)?.arrival.airportCode !==
      theFirstSegmentInSecondFlight?.departure.airportCode

  const isOneWay = !isRoundTrip && !isOpenJaw

  if (isOneWay) {
    return {
      from: getAddress(destinationSegment.arrival),
      to: getAddress(destinationSegment.arrival),
      startDate: changeArrivalDate(flightSegments[0].arrivalDateTime),
      endDate: undefined,
    }
  }

  return {
    from: getAddress(destinationSegment.arrival),
    to: getAddress(theFirstSegmentInSecondFlight?.departure),
    startDate: changeArrivalDate(destinationSegment.arrivalDate),
    endDate: changeReturnDate(theFirstSegmentInSecondFlight?.departureDate),
  }
}

function getAddress(airportAddress: AirportAddress | undefined): Address {
  return {
    city: airportAddress?.address?.city || '',
    countryCode: airportAddress?.address?.countryCode || '',
    ...airportAddress?.address,
    airportCode: airportAddress?.airportCode,
  }
}
