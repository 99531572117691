import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { ContentRightSection } from './hotel-room-desktop-styled'

type Props = {
  ratesLinkSlot: ReactNode
  showDifferingRatesWarning: boolean
}

export const HotelRateButtonDesktop = ({ ratesLinkSlot, showDifferingRatesWarning }: Props) => {
  const { t } = useTranslation()
  const i18Base = 'HotelInfo.Rooms'

  return (
    <ContentRightSection>
      {ratesLinkSlot}
      {showDifferingRatesWarning && (
        <Text variant="captionMedium" color="bodyText1">
          {t(i18Base + '.RoomDetails.DifferentDailyRates')}
        </Text>
      )}
    </ContentRightSection>
  )
}
