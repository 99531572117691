import { Block } from '@etta/ui/block'
import { HotelDistanceSlider } from '..//hotel-distance-slider'
import { HotelDistanceTitle } from '../hotel-distance-title'

type Props = {
  distance?: number
  onChange: (value: number) => void
}

export function HotelDistanceMobile({ distance, onChange }: Props) {
  return (
    <>
      <HotelDistanceTitle distance={distance} />
      <Block paddingHorizontal={14}>
        <HotelDistanceSlider distance={distance} onChange={onChange} />
      </Block>
    </>
  )
}
