import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import type { LayoutProps } from '../types'
import {
  Container,
  Content,
  Title,
  Details,
  Information,
  IconWrapper,
  MapWrapper,
} from './hotel-address-card-layout-desktop-styled'

const i18nBase = 'PostBooking.HotelDetails'

export function HotelAddressCardLayoutDesktop({
  titleSlot,
  mapSlot,
  addressSlot,
  phoneSlot,
  phone,
  address,
  googleUrl,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container data-tracking-id="hotel-address">
      <Title>{titleSlot}</Title>
      <Content>
        <MapWrapper>{mapSlot}</MapWrapper>
        <Details>
          {addressSlot && (
            <>
              <Information
                href={googleUrl}
                target="_blank"
                aria-label={t(i18nBase + '.PickUp', {
                  location: address,
                })}>
                {addressSlot}
                <IconWrapper name="directionsPWA" color="primary1" />
              </Information>
              <Block paddingHorizontal={16}>
                <Separator lineType="dashed" />
              </Block>
            </>
          )}
          {phoneSlot && (
            <>
              <Information
                href={`tel:${phone}`}
                aria-label={t(i18nBase + '.Call', {
                  phone,
                })}>
                {phoneSlot}
                <IconWrapper name="phonePWA" color="primary1" />
              </Information>
              <Block paddingHorizontal={16}>
                <Separator lineType="dashed" />
              </Block>
            </>
          )}
        </Details>
      </Content>
    </Container>
  )
}
