import { Service, Inject } from '@etta/di'
import { dateToIso } from '@fiji/utils/dates'
import { ROUTES } from '@fiji/routes'
import { SegmentType } from '@etta/core/enums'
import { HistoryService } from '@etta/interface/services/history.service'
// eslint-disable-next-line import/no-restricted-paths
import { TravelVertical } from '@fiji/graphql/types'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import type { FailedAlignedSegment } from '@etta/screens/review-trip-page/failed-aligned-segment/types'
import { PreSearchStore } from '@etta/modules/pre-search'
import { BookAgainTravelVerticalEnum } from '../../core/enums'
import { BookTripAgainStore } from '../stores/book-trip-again/book-trip-again.store'
import { BookTripAgainAdapter } from '../../infra/gazoo/book-trip-again/book-trip-again.data-provider'
import type {
  CarRentalPostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
} from '../../core/value-objects'
import type {
  BookAgainCarRentalSegment,
  BookAgainFlightSegment,
  BookAgainHotelSegment,
} from '../../ui/book-trip-again/book-trip-again-modal/types'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'
import { BookTripAgainSearchService } from './book-trip-again-search.service'

@Service()
export class BookTripAgainService {
  constructor(
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private bookTripAgainAdapter: BookTripAgainAdapter,
    @Inject() private bookTripAgainStore: BookTripAgainStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
    @Inject() private readonly preSearchStore: PreSearchStore,
    @Inject() private readonly bookTripAgainSearchService: BookTripAgainSearchService,
  ) {}

  async bookTripAgain() {
    if (
      !this.bookTripAgainStore.startDate ||
      (this.bookTripAgainStore.hasEndDate && !this.bookTripAgainStore.endDate)
    ) {
      return
    }

    this.bookTripAgainStore.setViewState('check-availability')

    const startDate = dateToIso(this.bookTripAgainStore.startDate)

    const result = await this.bookTripAgainAdapter.handleBookTripAgain({
      startDate: dateToIso(this.bookTripAgainStore.startDate),
      endDate: this.bookTripAgainStore.hasEndDate
        ? dateToIso(this.bookTripAgainStore.endDate!)
        : startDate,
      processId: this.postBookingTripStore.trip.processId,
      rebookSegmentTypes: this.selectedSegmentsToTravelVertical(
        Array.from(this.bookTripAgainStore.selectedSegments),
      ),
      customFields: this.preSearchStore.customFields,
    })

    result.match({
      Ok: async (res) => {
        await this.historyService.asyncPush(
          ROUTES.reviewTrip.main({ itineraryId: res.itineraryId }),
          () => {
            this.bookTripAgainStore.setMetadata(res.itineraryMetadata)
            this.bookTripAgainStore.dropStore()
          },
        )
      },
      Err: () => {
        this.bookTripAgainStore.setViewState('error')
      },
    })
  }

  get tripToBookAgainSegments(): {
    flightSegment?: BookAgainFlightSegment | null
    hotelSegment?: BookAgainHotelSegment | null
    carRentalSegment?: BookAgainCarRentalSegment | null
  } {
    const trip = this.postBookingTripStore.trip

    const defaultValue = {
      flightSegment: null,
      hotelSegment: null,
      carRentalSegment: null,
    }

    if (this.postBookingTripStore.isLoading) {
      return defaultValue
    }

    const tripFlightSegment = trip.segments.find(
      (s) => s.type === SegmentType.Flight,
    ) as FlightPostBookingValueObject

    const flightSegment = tripFlightSegment?.segments && {
      originAirportCode: tripFlightSegment.segments[0].departure?.airportCode,
      destinationAirportCode:
        tripFlightSegment.segments[tripFlightSegment.segments?.length - 1].arrival?.airportCode,
      flightNumbers: tripFlightSegment.segments
        .map((s) => {
          return `${s.carrierCode}${s.flightNumber}`
        })
        .filter((s) => !!s),
      serviceClass: tripFlightSegment.segments[0].serviceClass,
    }

    const tripHotelSegment = this.postBookingTripStore.trip.segments.find(
      (s) => s.type === SegmentType.Hotel,
    ) as HotelPostBookingValueObject

    const hotelSegment = {
      name: tripHotelSegment?.name,
      address: tripHotelSegment?.address,
      roomType: tripHotelSegment?.roomType,
      numberOfNights: tripHotelSegment?.numberOfNights,
    }
    const tripCarRentalSegment = this.postBookingTripStore.trip.segments.find(
      (s) => s.type === SegmentType.CarRental,
    ) as CarRentalPostBookingValueObject

    const carRentalSegment = {
      pickUpAddress: tripCarRentalSegment?.pickUpLocation?.address,
      carClass: tripCarRentalSegment?.carClass,
      vendor: tripCarRentalSegment?.vendor?.name,
    }

    return {
      flightSegment: this.validateFlightSegment(flightSegment as BookAgainFlightSegment)
        ? (flightSegment as BookAgainFlightSegment)
        : null,
      hotelSegment: this.validateHotelSegment(hotelSegment as BookAgainHotelSegment)
        ? (hotelSegment as BookAgainHotelSegment)
        : null,
      carRentalSegment: this.validateCarRentalSegment(carRentalSegment as BookAgainCarRentalSegment)
        ? (carRentalSegment as BookAgainCarRentalSegment)
        : null,
    }
  }

  rebookErrors(bookingId?: string) {
    const metadata = this.bookTripAgainStore.metadata
    if (!this.featureFlagsStore.flags.isBookAgainAllowed || !metadata) {
      return []
    }

    const isItineraryContainsFlight = this.reviewTripStore.segments.find(
      (s) => s.type === SegmentType.Flight,
    )

    const isItineraryContainsCarRental = this.reviewTripStore.segments.find(
      (s) => s.type === SegmentType.CarRental,
    )

    const isItineraryContainsHotel = this.reviewTripStore.segments.find(
      (s) => s.type === SegmentType.Hotel,
    )

    if (isItineraryContainsFlight && metadata.flightMatchDetails && metadata.flightSearch) {
      this.bookTripAgainStore.dropMetadataFlightData()
    }

    if (
      isItineraryContainsCarRental &&
      metadata.carRentalMatchDetails &&
      metadata.carRentalSearch
    ) {
      this.bookTripAgainStore.dropMetadataCarRentalData()
    }

    if (isItineraryContainsHotel && metadata.hotelMatchDetails && metadata.hotelSearch) {
      this.bookTripAgainStore.dropMetadataHotelData()
    }

    const itineraryId = this.reviewTripStore.tripId

    return metadata.alignAttemptedSegmentTypes
      .reduce<FailedAlignedSegment[]>((acc, item) => {
        switch (item) {
          case TravelVertical.Air: {
            if (!metadata.flightMatchDetails?.matchFound && !isItineraryContainsFlight) {
              const flightSearchMetadata = metadata.flightSearch
              const onSearchClick = flightSearchMetadata
                ? () =>
                    this.bookTripAgainSearchService.handleSearchFlight(
                      flightSearchMetadata,
                      itineraryId,
                    )
                : undefined
              acc.push({
                travelVertical: 'Air',
                onSearchClick,
              })
            }
            break
          }
          case TravelVertical.Carrental: {
            if (!metadata.carRentalMatchDetails?.matchFound && !isItineraryContainsCarRental) {
              const { carRentalSearch: carRentalSearchMetadata, startDate, endDate } = metadata

              const onSearchClick =
                carRentalSearchMetadata && startDate && endDate
                  ? () =>
                      this.bookTripAgainSearchService.handleSearchCarRental(
                        carRentalSearchMetadata,
                        startDate,
                        endDate,
                        itineraryId,
                        bookingId,
                      )
                  : undefined
              acc.push({
                travelVertical: 'Carrental',
                onSearchClick,
              })
            }
            break
          }
          case TravelVertical.Hotel: {
            if (!metadata.hotelMatchDetails?.matchFound && !isItineraryContainsHotel) {
              const { hotelSearch: hotelSearchMetadata, startDate, endDate } = metadata

              const onSearchClick =
                hotelSearchMetadata && startDate && endDate
                  ? () =>
                      this.bookTripAgainSearchService.handleSearchHotel(
                        hotelSearchMetadata,
                        startDate,
                        endDate,
                        itineraryId,
                        bookingId,
                      )
                  : undefined

              acc.push({
                travelVertical: 'Hotel',
                onSearchClick,
                customLabel: metadata.hotelSearch?.hotelName,
              })
            }
            break
          }
        }

        return acc
      }, [])
      .sort((a, b) => {
        if (a.travelVertical === 'Air') {
          return -1
        } else if (b.travelVertical === 'Air') {
          return 1
        }
        return 0
      })
  }

  private validateFlightSegment = (segment: BookAgainFlightSegment): boolean => {
    if (!segment) {
      return false
    }
    return (
      !!segment?.originAirportCode &&
      !!segment.destinationAirportCode &&
      !!segment.flightNumbers &&
      segment.flightNumbers.length > 0 &&
      !!segment.serviceClass
    )
  }

  private validateHotelSegment = (segment: BookAgainHotelSegment | null): boolean => {
    if (!segment) {
      return false
    }
    return !!segment.name && !!segment.address && !!segment.roomType && segment.numberOfNights > 0
  }

  private validateCarRentalSegment = (segment: BookAgainCarRentalSegment | null): boolean => {
    if (!segment) {
      return false
    }
    return !!segment.pickUpAddress && !!segment.carClass && !!segment.vendor
  }

  private selectedSegmentsToTravelVertical(
    segments: BookAgainTravelVerticalEnum[],
  ): TravelVertical[] {
    return segments.map((s) => {
      switch (s) {
        case BookAgainTravelVerticalEnum.Air:
          return TravelVertical.Air
        case BookAgainTravelVerticalEnum.Carrental:
          return TravelVertical.Carrental
        case BookAgainTravelVerticalEnum.Hotel:
          return TravelVertical.Hotel
      }
    })
  }
}
