import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div``

export const HeaderText = styled(Text)`
  margin-bottom: 6px;
`

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;
`
