import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { RailDetailsTicketRestrictionsModalMobile } from './mobile'
import { RailDetailsTicketRestrictionsModalDesktop } from './desktop'

export function RailDetailsTicketRestrictionsModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailDetailsTicketRestrictionsModalMobile {...props} />}
      desktopSlot={<RailDetailsTicketRestrictionsModalDesktop {...props} />}
    />
  )
}
