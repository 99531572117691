export default function BagIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 44 45" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7999 13.2692H19.1999C18.6844 13.2692 18.2666 13.6825 18.2666 14.1923V16.0385L25.7332 16.0385V14.1923C25.7332 13.6825 25.3154 13.2692 24.7999 13.2692ZM15.4666 14.1923V16.0385H11.7333C9.67147 16.0385 8 17.6916 8 19.7308V30.8077C8 32.8469 9.67147 34.5 11.7333 34.5H32.2667C34.3285 34.5 36 32.8469 36 30.8077V19.7308C36 17.6916 34.3285 16.0385 32.2667 16.0385H28.5332V14.1923C28.5332 12.1531 26.8617 10.5 24.7999 10.5H19.1999C17.138 10.5 15.4666 12.1531 15.4666 14.1923ZM11.7333 18.8077H15.4666V18.8077H28.5332L32.2667 18.8077C32.7821 18.8077 33.2 19.221 33.2 19.7308V23.8846H10.8V19.7308C10.8 19.221 11.2179 18.8077 11.7333 18.8077ZM10.8 26.6539V30.8077C10.8 31.3175 11.2179 31.7308 11.7333 31.7308H32.2667C32.7821 31.7308 33.2 31.3175 33.2 30.8077V26.6539H10.8Z"
        fill="currentColor"
      />
    </svg>
  )
}
