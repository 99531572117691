import { Service, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CostAllocationStore } from '@etta/modules/cost-allocation'
import { LuggageOptionsStore } from '../luggage-options/stores'
import { OutOfPolicyStore } from '../../../review-trip/interface/out-of-policy/stores'
import { PassportStore } from '../passport/stores'

@Service()
export class ResetBookTripInfoService {
  constructor(
    @Inject()
    private readonly costAllocationStore: CostAllocationStore,
    @Inject()
    private readonly passportStore: PassportStore,
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,
    @Inject()
    private readonly luggageOptionsStore: LuggageOptionsStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  resetInfo() {
    this.outOfPolicyStore.dropOopState()
    this.luggageOptionsStore.dropStore()
    this.passportStore.dropState()

    // TODO: Check if we need this. Check when is the correct time to clear the cost allocation store.
    if (!this.displayConfigurationStore.isPreBookCostAllocationEnabled) {
      this.costAllocationStore.resetCostAllocations()
    }
  }
}
