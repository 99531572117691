import { DataProvider } from '@etta/di'
import { GetBrandConfigurationDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetBrandConfigurationQuery,
  GetBrandConfigurationQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class BrandConfigurationDataProvider extends GraphqlDataProvider {
  getBrandConfiguration() {
    return this.client.query<GetBrandConfigurationQuery, GetBrandConfigurationQueryVariables>({
      query: GetBrandConfigurationDocument,
      fetchPolicy: 'no-cache',
    })
  }
}
