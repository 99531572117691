import styled, { css } from 'styled-components'
import { footnoteStrong, subHeadStrong } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

const listCSS = css`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const Container = styled.li`
  display: flex;
  flex-direction: column;
`

export const ServiceTitle = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.colors.mainText};
  text-transform: uppercase;
  ${footnoteStrong};
`

export const BillList = styled.ul`
  ${listCSS};

  &,
  > * + * {
    margin-top: 8px;
  }
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.borderLight};
  margin: 14px 0 12px 0;
`

export const SubtotalList = styled.ul`
  ${listCSS};

  > * {
    display: flex;
    width: 100%;
  }
  > * + * {
    margin-top: 4px;
  }
`

export const PrimarySubtotalListItem = styled.li`
  justify-content: space-between;
  ${({ theme }) => theme.colors.mainText};
  ${subHeadStrong};

  > * {
    display: flex;
    align-items: center;
  }
`

export const StyledIcon = styled(Icon)`
  margin: 1px 0 0 4px;
`

export const SecondarySubtotalListItem = styled.li`
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.bodyText};
  opacity: 0.7;
  ${footnoteStrong};
`
