import type { PropsWithChildren } from 'react'
import { CSSTransition } from 'react-transition-group'
import type { ModalPosition } from '@fiji/types'
import { MODAL_TRANSITION_SLIDE_UP } from './transition-slide-up'
import { MODAL_TRANSITION_SLIDE_DOWN } from './transition-slide-down'
import { MODAL_TRANSITION_SLIDE_RIGHT } from './transition-slide-right'
import { MODAL_TRANSITION_TIMEOUT } from './constants'

type Props = PropsWithChildren<{
  position: ModalPosition
  isAnimated?: boolean
  isVisible: boolean
  onEntered: () => void
  onExited: () => void
}>

function getTransitionName(position: ModalPosition) {
  switch (position) {
    default:
    case 'center':
      return MODAL_TRANSITION_SLIDE_UP
    case 'right':
      return MODAL_TRANSITION_SLIDE_RIGHT
    case 'top':
      return MODAL_TRANSITION_SLIDE_DOWN
  }
}

export function ModalAnimation({
  position,
  isAnimated,
  isVisible,
  children,
  onEntered,
  onExited,
}: Props) {
  if (isAnimated) {
    return (
      <CSSTransition
        appear
        in={isVisible}
        timeout={MODAL_TRANSITION_TIMEOUT}
        unmountOnExit
        classNames={getTransitionName(position)}
        onEntered={onEntered}
        onExited={onExited}>
        {children}
      </CSSTransition>
    )
  }

  return (
    <>
      <CSSTransition
        appear
        in={isVisible}
        timeout={0}
        unmountOnExit
        classNames={''}
        onEntered={onEntered}
        onExited={onExited}>
        <>{isVisible && children}</>
      </CSSTransition>
    </>
  )
}
