import { Action, Inject } from '@etta/di'
import type { CarRentalPostBookingValueObject } from '../../core/value-objects'
import { PostBookingTripModifyService } from '../services/post-booking-trip-modify.service'

@Action()
export class PostBookingTripModifyActions {
  constructor(
    @Inject()
    private postBookingTripModifyService: PostBookingTripModifyService,
  ) {}

  handleModifyCarRental(carRental: CarRentalPostBookingValueObject) {
    this.postBookingTripModifyService.handleModifyCarRental(carRental)
  }
}
