import { useTranslation } from 'react-i18next'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { BodyRightDescription } from '../car-rental-details-eco-check-mobile-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function CarRentalDetailsEcoCheckMobileBodyRight({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.CarRentalDetails'

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return (
        <BodyRightDescription
          dangerouslySetInnerHTML={{ __html: t(i18nBase + '.AverageEmissions') }}
        />
      )
    case EmissionsDeviationLevel.Less:
      return (
        <BodyRightDescription
          dangerouslySetInnerHTML={{ __html: t(i18nBase + '.LowerEmissions') }}
        />
      )
    case EmissionsDeviationLevel.More:
      return (
        <BodyRightDescription
          dangerouslySetInnerHTML={{ __html: t(i18nBase + '.HigherEmissions') }}
        />
      )
  }
}
