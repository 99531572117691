import type { CalculateTripEmissionsResultCar } from '@fiji/graphql/types'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { CarRentalEmission } from './types'

export function getCarRentalEmissionsSummary(
  segments: CarRentalPostBookingValueObject[],
  rawCarEmissions?: CalculateTripEmissionsResultCar[] | null,
): {
  carRentalEmissions: CarRentalEmission[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const carRentalEmissions: CarRentalEmission[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    const carRentalEmission = rawCarEmissions?.find((car) => car.customRef === segment.carId)

    tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, carRentalEmission?.tonnesOfEmissions)
    averageTonnesOfEmissionsSummary = sum(
      averageTonnesOfEmissionsSummary,
      carRentalEmission?.averageEmissionTonnes,
    )

    const {
      plasticBagEquivalenceAmount,
      treeSeedlingEquivalenceAmount,
      smartphoneChargeEquivalenceAmount,
    } = getEquivalencesCount(carRentalEmission?.equivalences)

    treeSeedlingsEquivalenceTotalCount = sum(
      treeSeedlingsEquivalenceTotalCount,
      treeSeedlingEquivalenceAmount,
    )

    plasticBagsEquivalenceTotalCount = sum(
      plasticBagsEquivalenceTotalCount,
      plasticBagEquivalenceAmount,
    )

    smartphoneChargeEquivalenceTotalCount = sum(
      smartphoneChargeEquivalenceTotalCount,
      smartphoneChargeEquivalenceAmount,
    )

    const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
      averageEmissionsTonnes: carRentalEmission?.averageEmissionTonnes || 0,
      tonnesOfEmissions: carRentalEmission?.tonnesOfEmissions || 0,
    })

    carRentalEmissions.push({
      emissionsKg: tonnesToKg(carRentalEmission?.tonnesOfEmissions || 0),
      deviationLevel,
      percentDeviation,
    })
  })

  return {
    carRentalEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
