import type { MutableRefObject } from 'react'
import { useLayoutEffect, useRef, useState } from 'react'

type Props = {
  isOpen: boolean
  popoverRef?: MutableRefObject<HTMLDivElement | null>
}

type ViewportPosition = {
  left: number
  right?: number
  top: number
}

type TooltipViewport = {
  isVisible: boolean
  position: ViewportPosition
}

const basePosition: ViewportPosition = {
  left: -9999,
  top: -9999,
}

export function usePosition({ isOpen, popoverRef }: Props) {
  const positionRef = useRef<HTMLDivElement>(null)
  const [viewport, setViewport] = useState<TooltipViewport>({
    isVisible: false,
    position: basePosition,
  })

  const [isPositionCalculated, setIsPositionCalculated] = useState(false)

  useLayoutEffect(() => {
    if (!positionRef.current) {
      setViewport({
        isVisible: false,
        position: {
          top: 0,
          left: 0,
        },
      })
      return
    }

    const { top, left, right } = positionRef.current.getBoundingClientRect()

    let position: ViewportPosition = {
      top,
      left,
    }

    if (popoverRef?.current) {
      const documentRect = document.documentElement.getBoundingClientRect()
      const { width: popoverWidth } = popoverRef.current?.getBoundingClientRect()

      if (left + popoverWidth > documentRect.width) {
        position = {
          top,
          left: 0,
          right: documentRect.right - right,
        }
      }
    }

    setViewport({
      isVisible: isOpen,
      position: position,
    })

    setIsPositionCalculated(true)
  }, [isOpen, positionRef, popoverRef])

  return {
    viewport: {
      isVisible: isOpen,
      position: isPositionCalculated ? viewport.position : basePosition,
    },
    isPositionCalculated,
    positionRef,
  }
}
