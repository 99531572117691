import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { TravelPreferencesErrors } from '../../core/errors/travel-preferences.errors'
import type { TravelPreferencesAdapterResult } from '../types'
import type { UpdateAirInput, UpdateCarRentalInput, UpdateHotelInput } from './types'
import { TravelPreferencesDataProvider } from './travel-preferences.data-provider'
import { TravelPreferenceInputMapper } from './mappers/travel-preference-input.mapper'

@Adapter()
export class TravelPreferencesAdapter {
  constructor(
    @Inject()
    private travelPreferencesDataProvider: TravelPreferencesDataProvider,
  ) {}

  async updateAir(input: UpdateAirInput): TravelPreferencesAdapterResult<{ success: boolean }> {
    try {
      const { data, errors } = await this.travelPreferencesDataProvider.updateAirTravelPreferences(
        TravelPreferenceInputMapper.mapToUpdateAirTravelPreferencesInput(input),
      )

      if (errors) {
        return Result.Err(new TravelPreferencesErrors.UpdateAirUnexpectedError(''))
      }

      if (!data?.airTravelPreference.success) {
        return Result.Err(new TravelPreferencesErrors.UpdateAirUnexpectedError(''))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new TravelPreferencesErrors.UpdateAirUnexpectedError(''))
    }
  }

  async updateHotel(input: UpdateHotelInput): TravelPreferencesAdapterResult<{ success: boolean }> {
    try {
      const {
        data,
        errors,
      } = await this.travelPreferencesDataProvider.updateHotelTravelPreferences(
        TravelPreferenceInputMapper.mapToUpdateHotelTravelPreferencesInput(input),
      )

      if (errors) {
        return Result.Err(new TravelPreferencesErrors.UpdateHotelUnexpectedError(''))
      }

      if (!data?.hotelTravelPreference.success) {
        return Result.Err(new TravelPreferencesErrors.UpdateHotelUnexpectedError(''))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new TravelPreferencesErrors.UpdateHotelUnexpectedError(''))
    }
  }

  async updateCarRental(
    input: UpdateCarRentalInput,
  ): TravelPreferencesAdapterResult<{ success: boolean }> {
    try {
      const {
        data,
        errors,
      } = await this.travelPreferencesDataProvider.updateCarRentalTravelPreferences(
        TravelPreferenceInputMapper.mapToUpdateCarRentalTravelPreferencesInput(input),
      )

      if (errors) {
        return Result.Err(new TravelPreferencesErrors.UpdateCarRentalUnexpectedError(''))
      }

      if (!data?.carRentalTravelPreference.success) {
        return Result.Err(new TravelPreferencesErrors.UpdateCarRentalUnexpectedError(''))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new TravelPreferencesErrors.UpdateCarRentalUnexpectedError(''))
    }
  }
}
