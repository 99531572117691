import styled from 'styled-components'

export const InfoFooter = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: auto;
  height: 52px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderLight};
  & > div {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -14px;
      top: calc(50% - 4px);
      width: 1px;
      height: 10px;
      background: ${({ theme }) => theme.colors.borderLight};
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
`

export const CarRentalEmissionDeviationContainer = styled.div`
  position: relative;
  white-space: nowrap;
`
