import { getDirectionLabel } from '@etta/screens/trip-confirmation/hooks/use-new-trip-confirmation/use-segment/use-rail/utils'
import { getTimeLabel } from '@etta/screens/trip-confirmation/hooks/use-new-trip-confirmation/use-segment/use-flight/utils'
import type { SegmentDetails } from '../../types'
import type { RailTrain } from '../../../../types'

export function getRailTrains(segments: SegmentDetails['railDetails']['segments']) {
  if (!segments) {
    return []
  }

  return segments?.reduce((acc: RailTrain[], segment) => {
    const railTrains = segment.segments.map((railSegment) => {
      const time = getTimeLabel(railSegment.departureDate, railSegment.arrivalDate, ' - ')

      const direction = getDirectionLabel(
        railSegment.departure.stationName,
        railSegment.arrival.stationName,
        ' - ',
      )

      return {
        direction: direction,
        time: time,
        // FIX
        // carriage,
        // seat,
      }
    })

    return acc.concat(railTrains)
  }, [])
}
