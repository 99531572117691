import type { TravelPolicy } from '@fiji/graphql/hooks'
import { SegmentType } from '@fiji/graphql/hooks'
import type { OOPGenericData } from '@fiji/types'
import { PolicyExplanationNegativeValues } from '@fiji/constants'

type Props = {
  descriptions: OOPGenericData[]
  travelPolicy?: TravelPolicy
}

export function resolveExtraDescription({ descriptions, travelPolicy }: Props) {
  const isPolicyExplanationRequired =
    travelPolicy &&
    travelPolicy.systemBehaviorType &&
    !PolicyExplanationNegativeValues.includes(travelPolicy.systemBehaviorType) &&
    (travelPolicy.isPurposeRequired ||
      descriptions.some((policy) => policy.type === SegmentType.CarService))

  return !!isPolicyExplanationRequired
}
