import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Block } from '@etta/ui/block'
import type { CovidStatistic } from '../types'
import { HORIZONTAL_PADDING } from '../constants'
import { CovidInformationHeader } from './covid-information-header'
import { CovidInformationContent } from './covid-information-content'
import { CovidInformationDetails, CovidInformationModal } from './covid-information-details'

type Props = {
  isLoading: boolean
  covidStatistic: CovidStatistic | undefined
}

export function CovidInformation({ isLoading, covidStatistic }: Props) {
  const hasNoCovidInfo = isLoading ? false : !covidStatistic
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  return (
    <>
      {!hasNoCovidInfo && (
        <Block
          paddingHorizontal={HORIZONTAL_PADDING}
          marginBottom={covidStatistic && (covidStatistic.county || covidStatistic.state) ? 8 : 16}>
          <CovidInformationHeader isLoading={isLoading} onLearnMoreClick={handleOpen} />
        </Block>
      )}
      <Block marginTop={hasNoCovidInfo ? 2 : 0}>
        <CovidInformationContent covidStatistic={covidStatistic} isLoading={isLoading} />
      </Block>
      <CovidInformationModal isOpen={isOpen} onClose={handleClose}>
        <CovidInformationDetails />
      </CovidInformationModal>
    </>
  )
}
