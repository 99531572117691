import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { HotelDetailsEcoCheckModalMobile } from './mobile'
import { HotelDetailsEcoCheckModalDesktop } from './desktop'

export function HotelDetailsEcoCheckModalLayout({ ...props }: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HotelDetailsEcoCheckModalMobile {...props} />}
      desktopSlot={<HotelDetailsEcoCheckModalDesktop {...props} />}
    />
  )
}
