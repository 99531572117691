import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ServiceCategory } from '@fiji/graphql/types'
import { useDeleteSegments } from './use-delete-segments'

type Props = {
  carRentalId: string
  onCloseAfterward?: () => void
}

export function useDeleteCarRental({ carRentalId, onCloseAfterward }: Props) {
  const { tripCarRentalActions } = useReviewTripContext()

  const handleRequestRemoveCarRental = async () => {
    const res = await tripCarRentalActions.handleRemoveCarRental({ carId: carRentalId })
    if (res.isErr()) {
      throw new Error(res.getError().message)
    }
  }

  const { viewStateParams, handleDeleteSegment } = useDeleteSegments({
    serviceCategory: ServiceCategory.RentalCar,
    onCloseAfterward,
    onRemoveSegment: handleRequestRemoveCarRental,
  })

  return {
    handleRemoveCarRental: handleDeleteSegment,
    viewStateParams,
  }
}
