import styled from 'styled-components'
import { captionStrong } from '@fiji/style'
import { HORIZONTAL_PADDING } from '../../../../constants'
import { ListItem } from '../covid-information-statistic-rows/covid-information-statistic-rows-styled'

export const Container = styled.div`
  padding: 0 ${HORIZONTAL_PADDING}px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > ${ListItem} + ${ListItem} {
    margin-top: 16px;
  }
`

export const Label = styled.div`
  ${captionStrong};
  color: ${(p) => p.theme.colors.mainText};
  margin-bottom: 12px;
  text-transform: uppercase;
`
