import { useTranslation } from 'react-i18next'
import { CarTransmission } from '@fiji/enums'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { Skeleton } from '@etta/ui/skeleton'
import { Badge } from '@etta/ui/badge'
import { SafetyCheckTitle } from '@etta/components/safety-check-title'
import { CarRentalFuelType } from '@fiji/graphql/types'
import type { Props } from '../../types'
import {
  Wrapper,
  CarVendorImageWrapper,
  CarVendorImage,
  ImageWrapper,
  CarImage,
  InfoContainer,
  InfoBody,
  SkeletonWrapper,
  InfoWrapper,
  MoreLink,
  SubTitleWrapper,
} from './desktop-styled'
import { Amenity } from './amenity'

const getTransmissioni18nId = (transmission: string) => {
  switch (transmission) {
    case CarTransmission.Automatic:
      return 'A'
    case CarTransmission.Manual:
      return 'M'
    case CarTransmission.Any:
    default:
      return 'A'
  }
}

const i18nBase = 'SearchSegments.CarRental'

export function CarInfoBlockDesktop({
  isLoading = false,
  isAmenitiesLoading = false,
  carRentalTitle,
  amenities,
  vendor,
  carImage,
  carFooterSlot,
  isHybridFuel,
  handleMoreDetails,
  isPreferred,
  handleOpenSafetyCheckDialog,
  customBadge,
}: Props) {
  const { t } = useTranslation()
  const {
    luggage,
    transmission,
    passenger,
    door,
    isAirConditioned,
    unlimitedMileage,
    fuel,
  } = amenities

  const airConditionerText = isAirConditioned ? t(`${i18nBase}.AirConditioner`) : undefined
  const unlimitedMileageText = unlimitedMileage ? t(`${i18nBase}.UnlimitedMileage`) : undefined
  const hybridFuelText = isHybridFuel ? t(`${i18nBase}.HybridFuel`) : undefined
  const transmissionText = transmission ? getTransmissioni18nId(transmission ?? 'ANY') : undefined
  const electricFuelText =
    fuel === CarRentalFuelType.Electric ? t(`${i18nBase}.ElectricFuel`) : undefined
  const currentCarImageWidthParam = 'w_120'
  const wideCarImageWidthParam = 'w_400'
  const carImageWide = carImage?.includes(currentCarImageWidthParam)
    ? carImage.replace(currentCarImageWidthParam, wideCarImageWidthParam)
    : carImage

  return (
    <>
      <Wrapper isLoading={isLoading}>
        <Swap
          is={isLoading}
          isSlot={
            <Skeleton width="100%" height="100%">
              <rect width="34px" height="16px" />
              <rect y="19" width="100%" height="120px" />
            </Skeleton>
          }>
          {vendor?.imageUrl && (
            <CarVendorImageWrapper>
              <CarVendorImage src={vendor?.imageUrl} alt={vendor?.name} />
            </CarVendorImageWrapper>
          )}
          {carImageWide && (
            <ImageWrapper>
              <CarImage src={carImageWide} alt="car image" />
            </ImageWrapper>
          )}
          {customBadge && <Badge variant="customPreferred" label={customBadge} />}
          {isPreferred && !customBadge && <Badge variant="preferredVendor" />}
        </Swap>
      </Wrapper>

      <InfoContainer>
        <InfoBody isLoading={isLoading}>
          <Swap
            is={isLoading}
            isSlot={
              <Skeleton width="100%" height="100px">
                <rect width="226px" height="26px" />
                <rect y="36" width="96px" height="13px" />
                <rect y="61" width="36px" height="13px" />
              </Skeleton>
            }>
            <Text variant="title2" isBlock>
              {carRentalTitle}
            </Text>
            <SubTitleWrapper>
              <SafetyCheckTitle
                onClick={handleOpenSafetyCheckDialog}
                size="extra-small"
                data-tracking-id="car-rental-safety-check-action"
              />
            </SubTitleWrapper>
            <Swap
              is={isAmenitiesLoading}
              isSlot={
                <SkeletonWrapper>
                  <Skeleton width="100%" height="38px">
                    <rect width="360px" height="13px" />
                    <rect y="23" width="55px" height="13px" />
                  </Skeleton>
                </SkeletonWrapper>
              }>
              <InfoWrapper>
                <Amenity value={passenger} icon="peopleNewPWA" />
                <Amenity value={luggage} icon="bagNewPWA" />
                <Amenity value={hybridFuelText} icon="carHybridPWA" />
                <Amenity value={door} icon="doorNewPWA" />
                <Amenity value={airConditionerText} icon="snowNewPWA" />
                <Amenity value={transmissionText} icon="transmissionNewPWA" />
                <Amenity value={unlimitedMileageText} icon="unlimitedMileage" />
                <Amenity value={electricFuelText} icon="electricLinePWA" />
              </InfoWrapper>
              <MoreLink
                tabIndex={0}
                onClick={handleMoreDetails}
                data-tracking-id="car-more-details-link">
                <Text variant="linkSmall" color="primary">
                  {t(`${i18nBase}.MoreDetails`)}
                </Text>
              </MoreLink>
            </Swap>
          </Swap>
        </InfoBody>
        {carFooterSlot}
      </InfoContainer>
    </>
  )
}
