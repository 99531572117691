import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import type { LayoutProps } from '../../types'
import { Container } from './time-range-picker-desktop-styled'

export function TimeRangePickerDesktop({ isVisible, onClose, departureSlot }: LayoutProps) {
  const { ref } = useClickOutside(() => {
    if (isVisible) {
      onClose()
    }
  })
  useShouldOpenInViewport({ elementHeight: 552, isVisible, ref })

  if (!isVisible) {
    return null
  }

  return <Container ref={ref}>{departureSlot}</Container>
}
