import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { HotelAboutLayoutProps } from '../types'
import { HotelAboutMobile } from './mobile'
import { HotelAboutDesktop } from './desktop'

export function HotelAboutLayout(props: HotelAboutLayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<HotelAboutMobile {...props} />}
      desktopSlot={<HotelAboutDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
