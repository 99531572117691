import styled from 'styled-components'
import { linkNormal } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  margin-left: 8px;
  ${linkNormal}
`
