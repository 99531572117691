import { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { AddressInput } from '@fiji/graphql/types'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { useCarSearchQuery } from '@fiji/hooks/search-queries/use-car-search-query/use-car-search-query'
import { useBackSearch } from '@fiji/hooks/search-queries/use-back-search'
import { ROUTES } from '@fiji/routes'
import { PostBookingAction } from '@fiji/enums'
import { isEqualToDate } from '@fiji/utils/dates/is-equal-date'
import { isBeforeDate } from '@fiji/utils/dates/is-before-date'
import { isBeforeTime } from '@fiji/utils/dates/is-before-time'
import { useStoragePlace } from '@fiji/hooks/use-storage-place'
import { useAppSelector } from '@fiji/store'
import { segmentIds } from '@fiji/store/segment-ids'
import type { Place, PlainTime as Time, Dates } from '@fiji/types'
import { appMode, ScreenType, useScreenType } from '@fiji/modes'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { trackAction } from '@fiji/utils/tracking'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { useCarRentalEmissions } from '@fiji/hooks/sustainability'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-form.context'
import i18n from '@fiji/i18n'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { redirectParamsOnRefetchFail } from '@fiji/utils/redirect-params-on-refetch-fail'
import { SegmentType } from '@fiji/graphql/types'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useSelectCarRental } from './use-select-car-rental'
import { useDates } from './use-dates'
import { useLocationsLabel } from './use-locations-label'

type HandleAddCarRentalArgs = {
  carRentalKey?: string
  itineraryId: string
  oldCarRentalKey?: string | null
  bookingId?: string
}

export function useCarRentalSearchResults() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { getRecentSearchesActions } = useRecentSearchesContext()

  const { isShowDelegateName } = useShowDelegateName()

  const { onBack: onBackSearch } = useBackSearch()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore
  const { isActiveItineraryPurchaseFlowEnabled } = flags

  const {
    carRentalSearchActions,
    carRentalFilterActions,
    carFiltersStore,
    carRentalSearchStore,
    carRentalCarDetailsStore,
    carRentalCarDetailsActions,
    carRentalsResetStoreActions,
    carRentalStore,
    carFiltersModalStore,
  } = useCarRentalSearchContext()

  const { isDefaultFilterCompanySettingsExist, isShowClearFiltersInformer } = carFiltersStore

  const { carSearchButtonActions, carSearchFormStore } = useCarRentalSearchFormContext()

  const { reviewTripStore, activeTripStore } = useReviewTripContext()
  const {
    activeTripId,
    isEmptyItinerary,
    isTripExpired,
    isMaximumCarRentalQuantity,
    maximumQuantityToggle,
  } = activeTripStore

  const {
    handleApplyCarTypesFilter,
    handleApplyLocationFilters,
    handleApplySortFilter,
    handleApplyCarClassFilters,
    handleApplyTransmissionFilters,
    handleApplySuppliersFilters,
    handleApplyFuelFilters,
    handleResetCarCompanyFilters,
  } = carRentalFilterActions

  const { dropOffSearchDateTime, pickUpSearchDateTime, isCarFiltersUsed } = carRentalStore
  const { carFiltersToggle } = carFiltersModalStore
  const { queryParams, navigateTo } = useCarSearchQuery()

  const {
    pickUpDate,
    pickUpTime,
    pickUpPlace,
    pickUpAddress,
    dropOffAddress,
    dropOffDate,
    dropOffTime,
    dropOffPlace,
    carTypes,
    bookingId,
    searchId,
    itineraryId,
    postBookingAction,
  } = queryParams

  const { carDetailsToggle, selectedCarDetails } = carRentalCarDetailsStore

  const { handleCloseCarDetailsToggle, handleSelectCarDetails } = carRentalCarDetailsActions

  const { handleDropCarDetails, handleDropCarRentalSearch } = carRentalsResetStoreActions

  const {
    availableCarFilters,
    cars,
    isResultsEmpty,
    carMessages,
    carPreferenceAttribute,
    hasNextPage,
    travelPolicyToggle,
    carRentalSortBy,
    isSecondCarRequestWithoutFilters,
    activeItineraryToggle,
    tripExpiredToggle,
    selectedCarRentalKey,
    viewState,
  } = carRentalSearchStore

  const {
    handleLoadCarRentals,
    handleRefetchCarRentals,
    handleLoadMoreCarRentals,
    createTrip,
    handleLoadSelectedCarRentalKey,
    handleClearSelectedCarRentalKey,
    handleSetViewState,
    handleResetViewState,
  } = carRentalSearchActions

  useEffect(() => {
    handleResetViewState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  getRecentSearchesActions.getRecentCarRentalSearches()

  const onLoadCarRentals = useCallback(async () => {
    await handleLoadCarRentals({ withDefaultCompanyFilter: true })
  }, [handleLoadCarRentals])

  useEffect(() => {
    if (!isSecondCarRequestWithoutFilters) {
      return
    }
    handleResetCarCompanyFilters()
  }, [isSecondCarRequestWithoutFilters, handleResetCarCompanyFilters])

  useEffect(() => {
    onLoadCarRentals()
  }, [onLoadCarRentals])

  useEffect(() => {
    return () => {
      handleDropCarRentalSearch()
      handleDropCarDetails()
    }
  }, [handleDropCarRentalSearch, handleDropCarDetails])

  const redirectParamsOnFail = useMemo(() => {
    return redirectParamsOnRefetchFail({
      isMobile,
      mobileNextPage: ROUTES.carRental.search,
      hasTrip: !isEmptyItinerary,
      nextPage: ROUTES.explore,
      segmentType: SegmentType.Hotel,
    })
  }, [isEmptyItinerary, isMobile])

  const { handleRefetchRequest } = useRefetchRequest({
    refetch: handleRefetchCarRentals,
    loading: carRentalSearchStore.isLoading,
    redirectRouteOnFail: redirectParamsOnFail.page,
    redirectQueryParamsOnFail: redirectParamsOnFail.params,
  })

  const { carRentalId: oldCarRentalKey } = useAppSelector(segmentIds)

  const { handleSubmitCarRental } = useSelectCarRental({
    onUpdateCarRentalStatus: handleSetViewState,
  })

  const handleCreateTrip = useCallback(() => {
    handleSetViewState('loading')
    return createTrip()
  }, [createTrip, handleSetViewState])

  const handleCloseModals = useCallback(() => {
    if (activeItineraryToggle.isOpen) {
      activeItineraryToggle.handleClose()
    }
    if (tripExpiredToggle.isOpen) {
      tripExpiredToggle.handleClose()
    }
  }, [activeItineraryToggle, tripExpiredToggle])

  const handleAddCarRental = useCallback(
    ({ carRentalKey, itineraryId, oldCarRentalKey, bookingId }: HandleAddCarRentalArgs) => {
      handleCloseModals()
      handleSubmitCarRental({
        bookingId,
        carRentalKey,
        itineraryId,
        oldCarRentalKey,
      })
    },
    [handleSubmitCarRental, handleCloseModals],
  )
  const handleSelectCarRental = useCallback(
    async (carRentalKey: string) => {
      const shouldShowActiveItineraryModal = !isEmptyItinerary && !itineraryId && !isMobile
      const shouldShowTripExpirationModal = !isEmptyItinerary && isTripExpired && !isMobile

      if (isActiveItineraryPurchaseFlowEnabled) {
        handleLoadSelectedCarRentalKey(carRentalKey)
        if (shouldShowTripExpirationModal) {
          tripExpiredToggle.handleOpen()
          return
        }

        if (isMaximumCarRentalQuantity) {
          maximumQuantityToggle.handleOpen()
          return
        }

        if (shouldShowActiveItineraryModal) {
          activeItineraryToggle.handleOpen()
          return
        }
      }
      handleClearSelectedCarRentalKey()

      if (isMaximumCarRentalQuantity) {
        maximumQuantityToggle.handleOpen()
        return
      }

      const _itineraryId = itineraryId || (await handleCreateTrip())
      handleAddCarRental({
        carRentalKey,
        oldCarRentalKey,
        bookingId,
        itineraryId: _itineraryId,
      })
    },
    [
      itineraryId,
      activeItineraryToggle,
      tripExpiredToggle,
      handleAddCarRental,
      handleLoadSelectedCarRentalKey,
      handleClearSelectedCarRentalKey,
      bookingId,
      oldCarRentalKey,
      handleCreateTrip,
      isEmptyItinerary,
      isActiveItineraryPurchaseFlowEnabled,
      isTripExpired,
      isMobile,
      isMaximumCarRentalQuantity,
      maximumQuantityToggle,
    ],
  )

  const handleCreateNewTrip = useCallback(async () => {
    handleCloseModals()
    const _itineraryId = await handleCreateTrip()
    handleAddCarRental({
      bookingId,
      carRentalKey: selectedCarRentalKey ?? undefined,
      oldCarRentalKey,
      itineraryId: _itineraryId,
    })
  }, [
    handleCloseModals,
    bookingId,
    oldCarRentalKey,
    selectedCarRentalKey,
    handleAddCarRental,
    handleCreateTrip,
  ])

  const handleAddToCurrentTrip = useCallback(() => {
    handleCloseModals()
    handleAddCarRental({
      bookingId,
      carRentalKey: selectedCarRentalKey ?? undefined,
      itineraryId: activeTripId,
      oldCarRentalKey,
    })
  }, [
    handleCloseModals,
    activeTripId,
    bookingId,
    handleAddCarRental,
    oldCarRentalKey,
    selectedCarRentalKey,
  ])

  const [editPickUpPlace, setEditPickUpPlace] = useState<Place>(pickUpPlace)
  const [editDropOffPlace, setEditDropOffPlace] = useState<Place>(dropOffPlace)
  const [editPickUpTime, setEditPickUpTime] = useState<Time>(pickUpTime)
  const [editDropOffTime, setEditDropOffTime] = useState<Time>(dropOffTime)
  const [editDate, setEditDate] = useState<Dates>({ date: pickUpDate, dateEnd: dropOffDate })
  const [editPickUpAddress, setEditPickUpAddress] = useState<AddressInput>()
  const [editDropOffAddress, setEditDropOffAddress] = useState<AddressInput>()

  const { mobileLocationsLabel, desktopLocationsLabel } = useLocationsLabel({
    pickUpPlace,
    dropOffPlace,
    dropOffAddress,
    pickUpAddress,
  })

  const { mobileDatesLabel, desktopDatesLabel } = useDates({
    dropOffDateTime: dropOffSearchDateTime,
    pickUpDateTime: pickUpSearchDateTime,
  })

  const isError = reviewTripStore.isTripCreatingError || carRentalSearchStore.hasError
  const isLoadingSearchResults = carRentalSearchStore.isLoading
  const isInitialLoading =
    (isLoadingSearchResults && !carRentalSearchStore.carRentals) ||
    reviewTripStore.isTripCreatingLoading
  const isErrorOrLoading = isError || isInitialLoading
  const isEttaMobile = appMode.isEttaMobile

  useEffect(() => {
    if (isInitialLoading) {
      return
    }

    const status = (() => {
      switch (true) {
        case isError:
          return 'error'
        case isResultsEmpty:
          return 'no-results'
        default:
          return 'success'
      }
    })()

    trackAction('car-rental-search-results', {
      status,
    })
  }, [isError, isInitialLoading, isResultsEmpty])

  useEffect(() => {
    setEditPickUpTime(queryParams.pickUpTime)
    setEditDropOffTime(queryParams.dropOffTime)
    setEditDate({ date: queryParams.pickUpDate, dateEnd: queryParams.dropOffDate })

    setEditPickUpPlace(queryParams.pickUpPlace)
    setEditDropOffPlace(queryParams.dropOffPlace)
  }, [queryParams])

  const handleGoToSearch = () => {
    navigateTo(ROUTES.carRental.search)
  }

  function handleGoToRTPAndCloseDialog() {
    navigateTo(ROUTES.reviewTrip.main({ itineraryId: activeTripId, bookingId }))
    maximumQuantityToggle.handleClose()
  }

  const onBack = () => {
    if (postBookingAction === PostBookingAction.Modify) {
      onBackSearch()
      return
    }

    navigateTo(ROUTES.carRental.search, {
      ...carRentalStore.queryParams,
      carVendor: undefined,
      carTransmission: undefined,
      carClass: undefined,
      carTypes: undefined,
      searchId: undefined,
    })
  }

  const { places, addNewPlace } = useStoragePlace('carRental')

  function onPickUpPlaceChange(place: Place, address?: AddressInput) {
    if (place) {
      addNewPlace(place)
    }
    setEditPickUpPlace(place)
    setEditDropOffPlace(place)
    setEditPickUpAddress(address)
    setEditDropOffAddress(address)
  }

  function onDropOffPlaceChange(place: Place, address?: AddressInput) {
    if (place) {
      addNewPlace(place)
    }
    setEditDropOffPlace(place)
    setEditDropOffAddress(address)
  }

  function onPickUpTimeChange(time: Time) {
    setEditPickUpTime(time)
  }

  function onDropOffTimeChange(time: Time) {
    setEditDropOffTime(time)
  }

  function onDatesSelect(dates: Dates) {
    setEditDate({ date: dates.date, dateEnd: dates.dateEnd })
  }

  function applyNewFilters() {
    if (
      !carSearchButtonActions.isTimeValid({
        pickUpDate: editDate.date ?? pickUpDate,
        pickUpTime: editPickUpTime,
        dropOffDate: editDate.dateEnd ?? dropOffDate,
        dropOffTime: editDropOffTime,
      })
    ) {
      carSearchFormStore.setSingleValidationError(
        'dropOffTime',
        i18n.t(`TripPlanner.BaseSearch.Input.InvalidTime`),
      )
      return
    }

    carSearchFormStore.setSingleValidationError('dropOffTime')
    handleApplyLocationFilters(
      {
        pickUpPlace: editPickUpPlace,
        dropOffPlace: editDropOffPlace,
        pickUpTime: editPickUpTime,
        dropOffTime: editDropOffTime,
        pickUpDate: editDate.date ? editDate.date : pickUpDate,
        dropOffDate: editDate.dateEnd ? editDate.dateEnd : dropOffDate,
        pickUpAddress: editPickUpAddress,
        dropOffAddress: editDropOffAddress,
      },
      true,
    )
    carSearchFormStore.editModeToggle.handleClose()
  }

  function resetFilters() {
    setEditPickUpPlace(pickUpPlace)
    setEditDropOffPlace(dropOffPlace)
    setEditPickUpTime(pickUpTime)
    setEditDropOffTime(dropOffTime)
    setEditDate({ date: pickUpDate, dateEnd: dropOffDate })
  }

  const { date: dateStart, dateEnd } = editDate
  const isAbleToEditSearch =
    dateStart && dateEnd && isEqualToDate(dateStart, dateEnd)
      ? isBeforeTime(editPickUpTime, editDropOffTime)
      : isBeforeDate(dateStart!, dateEnd!)
  const isNoResult = !isError && isResultsEmpty
  const onButtonClickNoResult = isNoResult ? undefined : handleRefetchCarRentals

  const {
    existingCarRentalEmissions,
    averageEmissionsTonnes,
    loading: areEmissionsLoading,
  } = useCarRentalEmissions({
    cars,
    pickUpDate,
    dropOffDate,
    pickUpPlace,
    dropOffPlace,
  })

  const carEmissions = {
    averageEmissionsTonnes,
    loading: areEmissionsLoading,
    existingCarRentalEmissions,
  }

  const carEmisison = existingCarRentalEmissions.find(
    (existingCar) => existingCar.customRef === selectedCarDetails?.carId,
  )

  const actualCarEmission = {
    averageEmissionsTonnes,
    loading: areEmissionsLoading,
    customRef: carEmisison?.customRef,
    tonnesOfEmissions: carEmisison?.tonnesOfEmissions,
    equivalences: carEmisison?.equivalences,
  }

  function onSwitchLocations() {
    setEditPickUpAddress(editDropOffAddress)
    setEditDropOffPlace(editPickUpPlace)
    setEditDropOffAddress(editPickUpAddress)
    setEditPickUpPlace(editDropOffPlace)
  }

  const noResultButtons = [
    {
      title: t(
        `SearchSegments.NoResultErrorScreen${
          isError || carRentalSearchStore.isRestrictedCountryError
            ? '.RetryButton'
            : '.SearchButton'
        }`,
      ),
      onClick: onButtonClickNoResult,
      buttonVariant: 'solid' as const,
    },
  ]

  return {
    noResultButtons,
    isFiltersUsed: isCarFiltersUsed,
    filtersToggle: carFiltersToggle,
    mobileLocationsLabel,
    desktopLocationsLabel,
    mobileDatesLabel,
    desktopDatesLabel,
    isResultsEmpty,
    availableCarFilters,
    carTypes,
    onChangeCarType: handleApplyCarTypesFilter,
    reload: handleRefetchRequest,
    cars,
    carPreferenceAttribute,
    isInitialLoading,
    isLoadingMore: carRentalSearchStore.isLoadingMore,
    isErrorOrLoading,
    isError,
    onBack,
    handleGoToSearch,
    handleOpenCarRentalDetails: handleSelectCarDetails,
    dynamicSiteMessages: carMessages,
    hasNextPage,
    onLoadMore: handleLoadMoreCarRentals,
    placesHistory: places,
    onPickUpPlaceChange,
    onDropOffPlaceChange,
    onDropOffTimeChange,
    onPickUpTimeChange,
    onDatesSelect,
    onApplyTransmissionFilters: handleApplyTransmissionFilters,
    onApplyCarClassFilters: handleApplyCarClassFilters,
    onApplySuppliersFilters: handleApplySuppliersFilters,
    onApplyFuelFilters: handleApplyFuelFilters,
    pickUpDate,
    dropOffDate,
    editPickUpPlace,
    editDropOffPlace,
    editPickUpTime,
    editDropOffTime,
    editDate,
    applyNewFilters,
    resetFilters,
    isEttaMobile,
    travelPolicyToggle,
    viewState,
    onAddCar: handleSelectCarRental,
    handleCloseTripStatus: handleResetViewState,
    isAbleToEditSearch,
    selectedCarDetails,
    bookingId,
    handleCloseDetailsModal: handleCloseCarDetailsToggle,
    carRentalDetailsModal: carDetailsToggle,
    isShowDelegateName,
    sortBy: carRentalSortBy,
    onChangeSortBy: handleApplySortFilter,
    actualCarEmission,
    carEmissions,
    searchId,
    itineraryId,
    onSwitchLocations,
    isRestrictedCountryError: carRentalSearchStore.isRestrictedCountryError,
    restrictedErrorMessage: carRentalSearchStore.restrictedErrorMessage,
    isSecondCarRequestWithoutFilters,
    isLoadingSearchResults,
    isDefaultFilterCompanySettingsExist,
    isShowClearFiltersInformer,
    activeItineraryToggle,
    tripExpiredToggle,
    handleAddToCurrentTrip,
    handleCreateNewTrip,
    handleGoToRTPAndCloseDialog,
    maximumQuantityToggle,
  }
}
