import { Action, Inject } from '@etta/di'
import type { PreferredCardsInput, PreferredCreditCardValueObject } from '@etta/modules/user'
import { PreferredCreditCardSegment } from '@etta/modules/user/core/enums/preferred-credit-card-segment.enum'
import { CreditCardBrandType } from '@etta/core/enums'
import { getBrandTypeByCardNumber } from '@fiji/utils/credit-card/get-brand-type'
import { UserService } from '@etta/modules/user/interface/services/get-user.service'
import { PaymentInformationService } from '../services/payment-information.service'
import { PaymentInformationStore } from '../stores/payment-information.store'
import { PreExpirationAlertValues } from '../../core/entities/credit-cards.entity'
import type {
  UserCreditCardEntity,
  PreferredCreditCardsForSegments,
} from '../../core/entities/credit-cards.entity'
import { BaseSettingsService } from '../services/base.service'
import type { AddCreditCardInputValueObject } from '../../core/value-objects/add-credit-card-input.value-object'

@Action()
export class PaymentInformationActions {
  constructor(
    @Inject() private paymentInformationService: PaymentInformationService,
    @Inject() private paymentInformationStore: PaymentInformationStore,
    @Inject() private readonly baseSettingsService: BaseSettingsService,
    @Inject() private readonly userService: UserService,
  ) {}

  handleModalOpen() {
    this.paymentInformationStore.setIsSaveButtonDisabled(true)
    this.paymentInformationStore.creditCardsToggle.handleOpen()
  }

  handleModalClose() {
    this.resetCreditCardsSearchFilter()
    this.paymentInformationStore.setIsSaveButtonDisabled(true)
    this.paymentInformationStore.creditCardsToggle.handleClose()
  }

  handleDefaultCardsOpen() {
    this.paymentInformationStore.setIsSaveButtonDisabled(true)
    this.paymentInformationStore.preferredCardsToggle.handleOpen()
  }

  handleDefaultCardsClose() {
    this.paymentInformationStore.preferredCardsToggle.handleClose()
    this.paymentInformationStore.setIsSaveButtonDisabled(true)
    this.paymentInformationStore.resetSelectedPreferredCards()
  }

  handlePreferredCardSelection<T extends keyof PreferredCreditCardsForSegments>(field: T) {
    return (value: string) => {
      const selectedCard = this.paymentInformationStore.creditCards?.find(
        (card) => card.fullLegacyId === value,
      )
      if (selectedCard) {
        this.paymentInformationStore.setIsSaveButtonDisabled(false)
        this.paymentInformationStore.setSelectedPreferredCard(field, selectedCard)
      }
    }
  }

  async getCreditCards() {
    await this.paymentInformationService.getCreditCards()
  }

  async getSiteCards() {
    await this.paymentInformationService.getSiteCards()
  }

  setCreditCards(creditCards?: UserCreditCardEntity[] | null) {
    this.paymentInformationStore.setCreditCards(creditCards || null)
  }

  setPreferredCards() {
    const { preferredCreditCards } = this.baseSettingsService.getStructuredProfile()
    this.paymentInformationStore.setPreferredCreditCards({
      creditCards: this.paymentInformationStore.creditCards,
      preferredCreditCards,
    })
  }

  getFilteredCreditCards(input: string) {
    const creditCards = this.paymentInformationStore.creditCards
    const siteCards = this.paymentInformationStore.siteCards

    this.paymentInformationStore.setCreditCardsSearch(input)

    if (!input.length) {
      this.paymentInformationStore.setFilteredCreditCards(null)
      this.paymentInformationStore.setFilteredSiteCards(null)
      return
    }

    if (isNaN(Number(input))) {
      const filteredCreditCardString = creditCards?.filter((card) =>
        card.label?.toLowerCase().includes(input.toLowerCase()),
      )
      const filteredSiteCardString = siteCards?.filter((card) =>
        card.genericLabel?.toLowerCase().includes(input.toLowerCase()),
      )
      this.paymentInformationStore.setFilteredCreditCards(filteredCreditCardString || [])
      this.paymentInformationStore.setFilteredSiteCards(filteredSiteCardString || [])
    } else {
      const filteredCreditCardNumber = creditCards?.filter((card) =>
        card.cardNumberLast4?.toLowerCase().includes(input.toLowerCase()),
      )
      this.paymentInformationStore.setFilteredCreditCards(filteredCreditCardNumber || [])
      this.paymentInformationStore.setFilteredSiteCards([])
    }
  }

  resetCreditCardsSearchFilter() {
    this.getFilteredCreditCards('')
  }

  getPreferredCardsStructure() {
    const finalCompositions: PreferredCardsInput[] = []

    const {
      flight: initialFlight,
      car: initialCar,
      hotel: initialHotel,
      rail: initialRail,
    } = this.paymentInformationStore.preferredCreditCardsBySegment

    const {
      flight: selectedFlight,
      car: selectedCar,
      hotel: selectedHotel,
      rail: selectedRail,
    } = this.paymentInformationStore.selectedPreferredCardsBySegment

    const flightId = selectedFlight?.fullLegacyId || initialFlight?.fullLegacyId
    const hotelId = selectedHotel?.fullLegacyId || initialHotel?.fullLegacyId
    const carId = selectedCar?.fullLegacyId || initialCar?.fullLegacyId
    const railId = selectedRail?.fullLegacyId || initialRail?.fullLegacyId

    const isSaveRequired =
      [selectedFlight, selectedCar, selectedHotel, selectedRail].some((card) => card) &&
      (selectedFlight?.fullLegacyId !== initialFlight?.fullLegacyId ||
        selectedHotel?.fullLegacyId !== initialHotel?.fullLegacyId ||
        selectedCar?.fullLegacyId !== initialCar?.fullLegacyId ||
        selectedRail !== initialRail)

    if (flightId) {
      const flightSegments: PreferredCreditCardSegment[] = [PreferredCreditCardSegment.Flight]
      if (flightId === hotelId) {
        flightSegments.push(PreferredCreditCardSegment.Hotel)
      }
      if (flightId === carId) {
        flightSegments.push(PreferredCreditCardSegment.CarRental)
      }
      if (flightId === railId) {
        flightSegments.push(PreferredCreditCardSegment.Rail)
      }

      finalCompositions.push({
        id: flightId,
        segments: flightSegments,
      })
    }

    if (hotelId && hotelId !== flightId) {
      const hotelSegments: PreferredCreditCardSegment[] = [PreferredCreditCardSegment.Hotel]

      if (hotelId === carId) {
        hotelSegments.push(PreferredCreditCardSegment.CarRental)
      }
      if (hotelId === railId) {
        hotelSegments.push(PreferredCreditCardSegment.Rail)
      }

      finalCompositions.push({
        id: hotelId,
        segments: hotelSegments,
      })
    }

    if (carId && carId !== hotelId && carId !== flightId) {
      const carSegments: PreferredCreditCardSegment[] = [PreferredCreditCardSegment.CarRental]

      if (carId === railId) {
        carSegments.push(PreferredCreditCardSegment.Rail)
      }

      finalCompositions.push({
        id: carId,
        segments: carSegments,
      })
    }

    if (railId && railId !== carId && railId !== hotelId && railId !== flightId) {
      const railSegments: PreferredCreditCardSegment[] = [PreferredCreditCardSegment.Rail]

      finalCompositions.push({
        id: railId,
        segments: railSegments,
      })
    }

    return { isSaveRequired, input: finalCompositions }
  }

  getNewCardStructure() {
    const preferredSegments = []
    const preferredInput = this.paymentInformationStore.selectedPreferenceForUpdate

    if (preferredInput.flight) {
      preferredSegments.push(PreferredCreditCardSegment.Flight)
    }
    if (preferredInput.hotel) {
      preferredSegments.push(PreferredCreditCardSegment.Hotel)
    }
    if (preferredInput.car) {
      preferredSegments.push(PreferredCreditCardSegment.CarRental)
    }
    if (preferredInput.rail) {
      preferredSegments.push(PreferredCreditCardSegment.Rail)
    }

    return preferredSegments.length ? { preferredSegments } : undefined
  }

  resetPreferredCardsStatus() {
    this.setPreferredCards()
    this.paymentInformationStore.resetSelectedPreferredCards()
  }

  handleSelectedPreferenceForUpdate(field: 'flight' | 'car' | 'hotel' | 'rail', value: boolean) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    this.paymentInformationStore.setSelectedPreferenceForUpdate(field, value)
  }

  handleExpirationAlertChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    this.paymentInformationStore.setSelectedCardExpiration(value)
  }

  handleOpenNewCardModal() {
    this.paymentInformationStore.creditCardsActionToggle.handleOpen()
  }

  handleCloseCardModal() {
    this.paymentInformationStore.setIsSaveButtonDisabled(true)
    this.paymentInformationStore.creditCardsActionToggle.handleClose()
    this.paymentInformationStore.newCardPresetsToggle.handleClose()
    this.paymentInformationStore.resetSelectedCardForEdit()
  }

  handleCloseCardModalWithRefetch() {
    this.handleCloseCardModal()
    this.getCreditCards()
  }

  handlePreferredCardsSave() {
    const handleClose = this.paymentInformationStore.preferredCardsToggle.handleClose
    const { isSaveRequired, input } = this.getPreferredCardsStructure()

    if (!isSaveRequired) {
      handleClose()
      return
    }

    this.baseSettingsService.saveUser({
      mode: 'preferred-cards',
      input,
      handleClose,
      customActionsAfterReload: this.resetPreferredCardsStatus,
    })
  }

  setSelectedCardForEdit(card: UserCreditCardEntity) {
    const profile = this.baseSettingsService.getStructuredProfile()

    this.paymentInformationStore.setSelectedCardForEdit({
      card,
      preferred: profile.preferredCreditCards?.find(
        (preferredCard) => preferredCard.id === card.fullLegacyId,
      )?.segments,
      expired: profile.creditCards?.find((userCard) => userCard.id === card.fullLegacyId)
        ?.preExpirationAlert,
    })
  }

  handleEditOrDeleteCard(card: UserCreditCardEntity) {
    if (card.brandType === CreditCardBrandType.Other) {
      this.paymentInformationStore.deleteCardActionToggle.handleOpen()
      this.paymentInformationStore.setSelectedCardId(card.fullLegacyId)
      return
    }
    this.setSelectedCardForEdit(card)
  }

  getExpirationPerCard() {
    const expirationCards = this.baseSettingsService.getStructuredProfile().creditCards
    const { selectedCardId, selectedCardExpiration } = this.paymentInformationStore
    const allExpirationCards = expirationCards?.filter((card) => card.id !== selectedCardId) || []

    if (!selectedCardId || !selectedCardExpiration) {
      return
    }

    const inputExpirationCard = {
      preExpirationAlert: selectedCardExpiration,
      id: selectedCardId,
    }

    const allExpirationCardsNoTypename = allExpirationCards.map((card) => ({
      id: card.id,
      preExpirationAlert: card.preExpirationAlert || PreExpirationAlertValues.Never,
    }))

    return [...allExpirationCardsNoTypename, inputExpirationCard]
  }

  getSegmentFilteredOut = (
    segmentType: PreferredCreditCardSegment,
    preferredCards?: PreferredCreditCardValueObject[] | null,
  ) => {
    const haveNoSegment =
      preferredCards?.filter((card) => !card.segments.includes(segmentType)) || []
    const hasSegment = preferredCards?.find((card) => card.segments.includes(segmentType))
    if (hasSegment) {
      const removedSegment = {
        id: hasSegment?.id,
        segments: hasSegment?.segments.filter((segment) => segment !== segmentType),
      }
      return [...haveNoSegment, removedSegment]
    }
    return preferredCards
  }

  getPreferrencePerCard() {
    let allPreferredCards = this.baseSettingsService.getStructuredProfile().preferredCreditCards
    const segments: PreferredCreditCardSegment[] = []
    const { selectedCardId, selectedPreferenceForUpdate } = this.paymentInformationStore

    if (selectedPreferenceForUpdate.flight) {
      segments.push(PreferredCreditCardSegment.Flight)
      allPreferredCards = this.getSegmentFilteredOut(
        PreferredCreditCardSegment.Flight,
        allPreferredCards,
      )
    }

    if (selectedPreferenceForUpdate.hotel) {
      segments.push(PreferredCreditCardSegment.Hotel)
      allPreferredCards = this.getSegmentFilteredOut(
        PreferredCreditCardSegment.Hotel,
        allPreferredCards,
      )
    }

    if (selectedPreferenceForUpdate.car) {
      segments.push(PreferredCreditCardSegment.CarRental)
      allPreferredCards = this.getSegmentFilteredOut(
        PreferredCreditCardSegment.CarRental,
        allPreferredCards,
      )
    }

    if (selectedPreferenceForUpdate.rail) {
      segments.push(PreferredCreditCardSegment.Rail)
      allPreferredCards = this.getSegmentFilteredOut(
        PreferredCreditCardSegment.Rail,
        allPreferredCards,
      )
    }

    const allPreferredCardsNoTypename =
      allPreferredCards
        ?.filter((card) => card.id !== selectedCardId)
        ?.map((card) => ({
          id: card.id,
          segments: card.segments,
        })) || []

    if (!segments.length || !selectedCardId) {
      return allPreferredCardsNoTypename
    }

    const editedCard = {
      id: selectedCardId,
      segments,
    }

    return [...allPreferredCardsNoTypename, editedCard]
  }

  async composeNewCreditCard(): Promise<AddCreditCardInputValueObject | undefined> {
    const {
      cityValidator,
      numberValidator,
      street1Validator,
      street2Validator,
      nicknameValidator,
      stateCodeValidator,
      expirationValidator,
      nameOnCardValidator,
      postalCodeValidator,
      countryCodeValidator,
      cardTypeValidator,
      isEditCard,
    } = this.paymentInformationStore

    const validatorsCheck = await Promise.all([
      cityValidator.submit(),
      street1Validator.submit(),
      street2Validator.submit(),
      nicknameValidator.submit(),
      stateCodeValidator.submit(),
      expirationValidator.submit(),
      nameOnCardValidator.submit(),
      postalCodeValidator.submit(),
      countryCodeValidator.submit(),
      isEditCard ? Promise.resolve({ errors: false }) : numberValidator.submit(),
    ])

    const isError = !!validatorsCheck.find((validator) => validator.errors)

    if (isError) {
      return
    }

    const cardDataInput = {
      creditCardData: {
        brandType: cardTypeValidator.values.cardType,
        cardNumber: numberValidator.values.number,
        expirationDate: expirationValidator.values.expiration.replace(' / ', ''),
        label: nicknameValidator.values.nickname,
        name: nameOnCardValidator.values.nameOnCard,
      },
    }

    const isBillingAddressProvide =
      [
        cityValidator.values.city,
        countryCodeValidator.values.countryCode,
        postalCodeValidator.values.postalCode,
        stateCodeValidator.values.stateCode,
        street1Validator.values.street1,
        street2Validator.values.street2,
      ].filter(Boolean).length > 0

    const addressInput = {
      billingAddress: {
        city: cityValidator.values.city,
        countryCode: countryCodeValidator.values.countryCode,
        postalCode: postalCodeValidator.values.postalCode,
        stateCode: stateCodeValidator.values.stateCode,
        street1: street1Validator.values.street1,
        street2: street2Validator.values.street2,
      },
    }
    return {
      ...cardDataInput,
      ...(isBillingAddressProvide && addressInput),
    } as AddCreditCardInputValueObject
  }

  async handleSaveNewCard() {
    const input = await this.composeNewCreditCard()

    if (!input) {
      return
    }

    await this.paymentInformationService.addCreditCard({
      input,
    })
  }

  async handleEditCard() {
    const shouldEditCard = this.paymentInformationStore.editedCardPart
    const shouldEditProfile = this.paymentInformationStore.editedProfilePart

    const input = await this.composeNewCreditCard()

    if ((!shouldEditCard && !shouldEditProfile) || (shouldEditCard && !input)) {
      return
    }

    const cardExpiration = this.getExpirationPerCard()
    const cardPreference = this.getPreferrencePerCard()

    const creditCardId = this.paymentInformationStore.selectedCardId

    if (!creditCardId) {
      return
    }

    const cardInput = input ? { ...input, creditCardId } : null

    const userInput = {
      creditCards: cardExpiration,
      preferredCreditCards: cardPreference,
    }

    await this.paymentInformationService.editCreditCard({
      input: { cardInput, userInput },
      handleClose: this.handleCloseCardModal,
      shouldEditCard,
      shouldEditProfile,
    })
  }

  async handleNewCreditCardsPresetsSave() {
    const shouldEditProfile = this.paymentInformationStore.editedProfilePart

    const cardExpiration = this.getExpirationPerCard()
    const cardPreference = this.getPreferrencePerCard()

    const creditCardId = this.paymentInformationStore.selectedCardId

    if (!creditCardId) {
      return
    }

    const userInput = {
      creditCards: cardExpiration,
      preferredCreditCards: cardPreference,
    }

    await this.paymentInformationService.setNewCreditCardsPresets({
      input: { userInput },
      shouldEditProfile,
      handleClose: this.handleCloseCardModal,
    })
  }

  async handleDeleteCreditCard() {
    const creditCardId = this.paymentInformationStore.selectedCardId
    if (!creditCardId) {
      return
    }
    this.resetCreditCardsSearchFilter()
    await this.paymentInformationService.deleteCreditCard({
      id: creditCardId,
      handleCloseFirstDialog: this.paymentInformationStore.deleteCardActionToggle.handleClose,
      handleClose: this.handleCloseCardModal,
    })
    await this.userService.reloadUser()
  }

  async handleSubmit() {
    this.resetCreditCardsSearchFilter()
    this.paymentInformationStore.selectedCardId
      ? await this.handleEditCard()
      : await this.handleSaveNewCard()
  }

  handleNumberChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    const cardType = getBrandTypeByCardNumber(value)
    this.paymentInformationStore.setEditedCardPart(true)
    this.paymentInformationStore.setCreditCardType(cardType)
    return this.paymentInformationStore.numberValidator.onFieldChange('number', value)
  }

  handleNameChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.nameOnCardValidator.onFieldChange('nameOnCard', value)
  }

  handleCardTypeChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.cardTypeValidator.onFieldChange('cardType', value)
  }

  handleNicknameChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.nicknameValidator.onFieldChange('nickname', value)
  }

  handleExpirationChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.expirationValidator.onFieldChange('expiration', value)
  }

  handleCvvChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.cvvValidator.onFieldChange('cvv', value)
  }

  handleCityChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.cityValidator.onFieldChange('city', value)
  }

  handleCountryCodeChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.countryCodeValidator.onFieldChange('countryCode', value)
  }

  handleStateCodeChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.stateCodeValidator.onFieldChange('stateCode', value)
  }

  handlePostalCodeChange(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.postalCodeValidator.onFieldChange('postalCode', value)
  }

  handleStreet1Change(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.street1Validator.onFieldChange('street1', value)
  }

  handleStreet2Change(value: string) {
    this.paymentInformationStore.setIsSaveButtonDisabled(false)
    this.paymentInformationStore.setEditedCardPart(true)
    return this.paymentInformationStore.street2Validator.onFieldChange('street2', value)
  }
}
