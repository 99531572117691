import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useBookTripContext } from '@etta/modules/booking'
import type { FailedBookingButton } from '../../types'
import type { HotelData, FailedBookingActions } from './types'

type Args = {
  failedBookingActions: FailedBookingActions
}

const i18nBase = 'PostBooking.TripDetails'

const MAX_NUMBER_OF_RETRIES = 2

export function useFailedBookingButtons({ failedBookingActions }: Args) {
  const { t } = useTranslation()
  const { bookTripStore } = useBookTripContext()

  const { retryBookingData } = bookTripStore
  const numberOfRetries = retryBookingData?.numberOfRetries || 0

  const getChangeHotelButton = useCallback(
    (hotelData: HotelData): FailedBookingButton => {
      return {
        label: t(`${i18nBase}.ChangeHotel`),
        onClick: () => failedBookingActions.onChangeHotel(hotelData),
        variant: 'solid',
      }
    },
    [failedBookingActions, t],
  )

  const getStartNewHotelSearchButton = useCallback(
    (hotelData: HotelData): FailedBookingButton => {
      return {
        label: t(`${i18nBase}.ChangeHotel`),
        onClick: () => failedBookingActions.onStartNewHotelSearch(hotelData),
        variant: 'solid',
      }
    },
    [failedBookingActions, t],
  )

  const getStartOverButton = useCallback((): FailedBookingButton => {
    return {
      label: t(`${i18nBase}.StartOver`),
      onClick: failedBookingActions.onStartOver,
      variant: 'outline',
    }
  }, [failedBookingActions.onStartOver, t])

  const getRetryButton = useCallback((): FailedBookingButton => {
    if (numberOfRetries >= MAX_NUMBER_OF_RETRIES) {
      return {
        label: t(`${i18nBase}.StartOver`),
        onClick: failedBookingActions.onStartOver,
        variant: 'outline',
      }
    }

    return {
      label: t(`${i18nBase}.TryAgain`),
      onClick: failedBookingActions.onTryAgain,
      variant: 'outline',
    }
  }, [failedBookingActions.onStartOver, failedBookingActions.onTryAgain, numberOfRetries, t])

  const getChangeCreditCardButton = useCallback((): FailedBookingButton => {
    return {
      label: t(`${i18nBase}.ChangeCreditCard`),
      onClick: failedBookingActions.onChangeCreditCard,
      variant: 'solid',
    }
  }, [failedBookingActions.onChangeCreditCard, t])

  return {
    getChangeHotelButton,
    getStartOverButton,
    getRetryButton,
    getChangeCreditCardButton,
    getStartNewHotelSearchButton,
  }
}
