import { useTranslation } from 'react-i18next'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { getSeatFeature } from '@etta/modules/seat-map/utils'
import { getSeatFeaturedTitle } from '../get-seat-featured-title'

export function useSeatTitle(seat: SeatMapRowSeatValueObject): string {
  const { t } = useTranslation()

  return getSeatFeaturedTitle(
    { seatFeature: getSeatFeature(seat), seatNumber: seat.number, segmentId: '' },
    t,
  )
}
