import { Service, Inject } from '@etta/di'
import { getFormattedTime } from '@fiji/utils/dates'
import { MultiModalSearchStore } from '../store/multi-modal-search.store'
import { MultiModalSearchAdapter } from '../../infra/multi-modal-search.adapter'
import type { MultiModalSearchArgs } from '../../infra/types'

@Service()
export class MultiModalSearchService {
  constructor(
    @Inject() private multiModalSearchAdapter: MultiModalSearchAdapter,
    @Inject() private multiModalSearchStore: MultiModalSearchStore,
  ) {}

  async loadMultiModal() {
    const {
      originAddress,
      destinationAddress,
      departureDate,
      departureTime,
    } = this.multiModalSearchStore.searchMultiModalForm

    if (!originAddress || !destinationAddress) {
      return
    }

    const params: MultiModalSearchArgs = {
      origin: { address: originAddress },
      destination: { address: destinationAddress },
      departureDate:
        departureDate !== undefined ? getFormattedTime(departureDate, 'yyyy-MM-dd') : undefined,
      departureTime: departureTime !== undefined ? getFormattedTime(departureTime) : undefined,
    }

    return this.multiModalSearchAdapter.search(params)
  }
}
