import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { RoomType } from '@fiji/graphql/types'

type Props = {
  onClick: () => void
  isDisabled: boolean
  roomType: RoomType
}

const i18Base = 'HotelInfo.Rooms'

export function SubmitButton({ onClick, isDisabled, roomType }: Props) {
  const { t } = useTranslation()
  return (
    <Button
      aria-label={t(i18Base + '.SelectButtonAriaLabel', {
        label: t(`${i18Base}.Room`, { roomType }),
      })}
      onClick={onClick}
      size="small"
      disabled={isDisabled}
      data-tracking-id="add-hotel-button">
      {t(i18Base + '.Select')}
    </Button>
  )
}
