import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CancellationPolicy } from '@fiji/graphql/types'
import { useScreenType, ScreenType } from '@fiji/modes'

type Args = {
  cancellationPolicy: CancellationPolicy
  cancellationPolicyDescription?: string[] | null
}

export function useHotelCancellationLabel({
  cancellationPolicy,
  cancellationPolicyDescription,
}: Args) {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const deviceClassActionLabel = screenType === ScreenType.Mobile ? 'tap' : 'click'

  const cancellationPolicyLabel = useMemo(() => {
    switch (cancellationPolicy) {
      case CancellationPolicy.Penalty:
      case CancellationPolicy.Free:
        return cancellationPolicyDescription?.length ? cancellationPolicyDescription[1] : ''
      case CancellationPolicy.NotAvailable:
        return t(`HotelInfo.Rooms.CancellationInfoNotAvailable`, { action: deviceClassActionLabel })
      default:
        return ''
    }
  }, [cancellationPolicy, cancellationPolicyDescription, deviceClassActionLabel, t])

  return { cancellationPolicyLabel }
}
