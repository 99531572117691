import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

export const ModelBlock = styled(Block)<{ isFullWidth?: boolean }>`
  width: 45%;
  margin-right: 5%;
  display: inline-block;
  vertical-align: top;
  ${(p) =>
    p.isFullWidth &&
    css`
      width: 100%;
      margin-right: 0;
    `}
`
