import { Icon } from '@etta/ui/icon'
import type { IconColorPalette, IconSizes } from '@etta/ui/icon'
import { amenityToPwaIconMap } from './amenity-to-pwa-icon-map'

type Props = {
  amenity: string
  size?: IconSizes
  color?: IconColorPalette
}

export function AmenityIcon({ amenity, size, color }: Props) {
  return amenityToPwaIconMap[amenity] ? (
    <Icon name={amenityToPwaIconMap[amenity]} size={size} color={color} />
  ) : null
}
