import type { FlightSafetyCheckDetails } from '@fiji/types'
import type { FareAttributesValueObject } from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'

export function getFlightSafetyCheckDetails(
  fareAttributes: FareAttributesValueObject[],
): FlightSafetyCheckDetails[] {
  const safetyCheckItems =
    fareAttributes[0].content.find((item) => item.location === 'HEADER')?.items || []

  return safetyCheckItems?.map((item) => {
    return {
      imageUrl: item.details[0] ? item.details[0].image.thumbnail : '',
      headline: item.headline || '',
      description: item.details[0] ? item.details[0].body : '',
      url: item.callToAction?.url || '',
    }
  })
}
