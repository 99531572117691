import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const LabelWithBackground = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  isBlock: true,
}))`
  position: relative;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.mainText};
  background: ${(p) => p.theme.colors.borderDark};
  border-radius: 4px;
  margin-left: 8px;
  padding: 2px 6px 2px 17px;

  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
  }
`
