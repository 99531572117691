import { ModalDetails } from '@etta/components/modal-details'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { HotelDetailsContent } from './hotel-details-content'
import type { HotelDetailsProps } from './types'

type Props = {
  isVisible: boolean
  onClose: () => void
} & HotelDetailsProps

export function HotelDetailsModal({ isVisible, onClose, ...props }: Props) {
  const { featureFlags } = useFeatureFlags()
  return (
    <ModalDetails
      isVisible={isVisible}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition={featureFlags.isDesktopLayoutHotelFlowEnabled}>
      <HotelDetailsContent {...props} onClose={onClose} />
    </ModalDetails>
  )
}
