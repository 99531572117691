import type { IconNames, IconColorPalette } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { IconRow, Item } from './badge-styled'

export type BadgeProps = {
  className?: string
  icon: IconNames
  title: string
  textColor?: IconColorPalette
  iconColor?: IconColorPalette
  isRow?: boolean
}

export function Badge({ className, icon, title, textColor, iconColor, isRow }: BadgeProps) {
  return (
    <Item className={className} aria-label={title}>
      <IconRow isRow={isRow}>
        <Icon name={icon} size="small" color={iconColor} />
      </IconRow>
      <Text variant="footnoteMedium" color={textColor}>
        {title}
      </Text>
    </Item>
  )
}
