import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { FlightItinerarySegment } from '@etta/modules/ride-hail/core'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { Container, Detail, Title, DetailLine } from './mobility-itinerary-segment-styled'

type Prop = {
  segment: FlightItinerarySegment
}

const i18nBase = 'Mobility.ItineraryDialog'
const DATE_TIME_FORMAT = 'MMM d, h:mm aa'

export function MobilityItineraryFlightSegment({ segment }: Prop) {
  const { t } = useTranslation()
  const departureDateTime = segment.departureDateTime
    ? getFormattedTime(
        new Date(deleteTimezone(String(segment.departureDateTime))),
        DATE_TIME_FORMAT,
      )
    : null

  const arrivalDateTime = segment.arrivalDateTime
    ? getFormattedTime(new Date(deleteTimezone(String(segment.arrivalDateTime))), DATE_TIME_FORMAT)
    : null

  return (
    <Container>
      <Icon name={'flightPWA'} />
      <Detail>
        <Title
          variant="footnoteStrong"
          color="mainText"
          textOverflow="ellipsis">{`${segment.originAirportCode} -> ${segment.destinationAirportCode}`}</Title>
        {departureDateTime && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.Depart`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {departureDateTime}
            </Text>
          </DetailLine>
        )}
        {arrivalDateTime && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.Arrive`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {arrivalDateTime}
            </Text>
          </DetailLine>
        )}
      </Detail>
    </Container>
  )
}
