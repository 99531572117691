import type { SwipeableHandlers } from 'react-swipeable'
import { Header, Title } from '../../swiping-time-picker-components'
import { Picker } from '../../picker'
import type { SwipingTimePickerData, SwipingPickerSubtype } from '../../types'
import { SelectedTime } from './selected-time'

type Props = {
  label: string
  hour: number
  minute: number
  ampm: number
  minuteGroup: number
  data: SwipingTimePickerData
  variant?: SwipingPickerSubtype
  shouldShowWheelsOnly?: boolean
  handleSwipe: [
    firstColumn: SwipeableHandlers,
    secondColum: SwipeableHandlers,
    thirdColumn: SwipeableHandlers,
  ]
}

export function TimeLayout({
  label,
  hour,
  minute,
  ampm,
  minuteGroup,
  variant,
  data,
  handleSwipe,
  shouldShowWheelsOnly,
}: Props): JSX.Element {
  return (
    <>
      {!shouldShowWheelsOnly && (
        <Header>
          <Title aria-label={label}>{label}</Title>
          <SelectedTime
            hour={hour}
            minute={minute}
            ampm={ampm}
            minuteGroup={minuteGroup}
            variant={variant || 'time'}
          />
        </Header>
      )}
      <Picker
        startYear={new Date().getFullYear()}
        variant={variant || 'time'}
        handleSwipe={handleSwipe}
        data={data}
      />
    </>
  )
}
