import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { ImageFormat } from '@fiji/graphql/types'
import { PostBookingListErrors } from '../../core/errors/post-booking-list.errors'
import type { TripsListValueObject } from '../../core/value-object/trips-list.value-object'
import { OrdersDataProvider } from './orders.data-provider/orders.data-provider'
import { OrdersMapper } from './mappers/orders.mapper'
import type { GetOrdersArgs, OrdersResult } from './types'

@Adapter()
export class OrdersAdapter {
  constructor(
    @Inject()
    private ordersDataProvider: OrdersDataProvider,
  ) {}

  async getOrders({
    offset,
    tripType,
    forceUpdate,
  }: GetOrdersArgs): OrdersResult<TripsListValueObject> {
    try {
      const { data, error } = await this.ordersDataProvider.getOrders(
        {
          input: {
            offset,
            tripType,
            format: ImageFormat.Svg,
          },
        },
        forceUpdate,
      )

      if (error) {
        return Result.Err(new PostBookingListErrors.GetTripError(error))
      }

      return Result.Ok(OrdersMapper.toOrdersValueObject(data.orders))
    } catch (e) {
      return Result.Err(new PostBookingListErrors.GetTripUnexpectedError(e))
    }
  }
}
