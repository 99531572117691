/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import type { Distance } from '@fiji/graphql/types'
import { distanceRecalculationRatio } from '@fiji/constants'
import { useSearchParams } from '@fiji/hooks/use-search-params'

type Args = {
  distance?: Distance
  reloadDistanceUnit?: string
}

function calculateDistanceValue({ distance, reloadDistanceUnit }: Args) {
  return distance?.units?.toLowerCase() === reloadDistanceUnit
    ? distance?.value
    : reloadDistanceUnit === 'km'
    ? // @ts-expect-error
      distance?.value * distanceRecalculationRatio
    : // @ts-expect-error
      distance?.value / distanceRecalculationRatio
}

export function useNormalizedDistance(distance?: Distance) {
  const { searchParams } = useSearchParams()
  const { distanceUnit } = searchParams

  return useMemo(() => {
    const value = distanceUnit
      ? calculateDistanceValue({
          distance,
          reloadDistanceUnit: distanceUnit,
        })
      : distance?.value

    return {
      distance: (value || 0).toFixed(1),
      units: distanceUnit || distance?.units,
    }
  }, [distance])
}
