import styled from 'styled-components'
import { subHeadMedium } from '@fiji/style'

export const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 54px;
  color: ${(props) => props.theme.colors.primary};
  ${subHeadMedium};
  padding-left: 6px;
`

export const TextWrapper = styled.span`
  display: flex;
  padding: 13px 18px 13px 5px;
  flex-grow: 1;
`

export const Text = styled.span<{
  active: boolean
}>`
  display: inline-block;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => (props.active ? props.theme.colors.primary : 'transparent')};
`
