import { parse, stringify } from 'query-string'
import type { SearchParams } from '@fiji/hooks/use-search-params/types'
import { roundMapCoordinate } from './map'

type CenterMarkerQueryParams = {
  center_lat?: number | null
  center_long?: number | null
  center_query?: string
}

export function fromLocationQuery(search: string): SearchParams {
  const query = parse(search)
  const queryString = String(query.query).trim().split(',').filter(Boolean).join(',')
  const hotelName = query['hotel_name'] && {
    hotelName: String(query['hotel_name']),
    hotelNameForSearch: String(query['hotel_name']),
  }

  const hotelNameForFilter = query['hotel_name_filter'] && {
    hotelNameForFilter: String(query['hotel_name_filter']),
  }

  const starRatings = query['star_ratings'] && {
    starRatings: String(query['star_ratings']).split(',').map(Number),
  }
  const amenityIds = query['amenity_ids'] && {
    amenityIds: String(query['amenity_ids']).split(','),
  }
  const brandIds = query['brand_ids'] && {
    brandIds: String(query['brand_ids']).split(','),
  }

  const center = {
    centerLat: Number(query['center_lat']) || 0,
    centerLong: Number(query['center_long']) || 0,
    centerQuery: String(query['center_query'] || ''),
  }
  const position = {
    latitude: Number(query.latitude),
    longitude: Number(query.longitude),
  }
  if (!center.centerLat) {
    center.centerLat = roundMapCoordinate(position.latitude)
    center.centerLong = roundMapCoordinate(position.longitude)
    center.centerQuery = queryString
  }

  const offset = query.offset && {
    offset: Number(query.offset),
  }

  const first = query.first && {
    first: Number(query.first),
  }

  return {
    ...offset,
    ...first,
    orderBy: String(query['order-by']),
    checkIn: String(query['check-in']),
    checkOut: String(query['check-out']),
    ...position,
    guests: Number(query.guests),
    groupId: String(query['group-id'] || query['group_id'] || ''),
    query: queryString,
    itineraryId: String(query['itinerary-id'] || query.itineraryId || ''),
    referencePointId: query['ref-point-id'] ? String(query['ref-point-id']) : '',
    distance: String(query.radius),
    isSkipLinkVisible: !!(query.skip && query.skip === 'true'),
    nextPageLink: query['next-page'] ? String(query['next-page']) : '',
    syncUrl: query['sync-url'] ? String(query['sync-url']) : '',
    hotelRemoveKey: query['hotel-remove-key'] ? String(query['hotel-remove-key']) : '',
    searchBy: query['searchby'] ? String(query['searchby']) : 'Geocode',
    locationName: query['address'] ? String(query['address']) : '',
    stationCode: query['station_code'] ? String(query['station_code']) : '',
    airportCode: query['airport_code'] ? String(query['airport_code']) : '',
    nameSearch: query['name_search'] === 'true',
    distanceUnit: query['radius-unit'] ? String(query['radius-unit']) : '',
    hideSoldOut: query['hide_sold_out'] === 'true',
    isSelectable: query['is_selectable'] === 'true',
    ...center,
    ...starRatings,
    ...amenityIds,
    ...brandIds,
    ...hotelName,
    ...hotelNameForFilter,
    messageType: query['message_type'] ? String(query['message_type']) : '',
    messageKey: query['message_key'] ? String(query['message_key']) : '',
    hotelId: query['hotelId'] ? String(query['hotelId']) : '',
    rateCode: query['rateCode'] ? String(query['rateCode']) : '',
    providerId: query['provider_id'] ? String(query['provider_id']) : '',
    rateCategory: query['rateCategory'] ? String(query['rateCategory']) : '',
  }
}

export function toLocationQuery(searchParams: SearchParams, originalQuery: string = ''): string {
  const starRatings = searchParams.starRatings && {
    star_ratings: searchParams.starRatings?.join(','),
  }

  const amenityIds = searchParams.amenityIds && {
    amenity_ids: searchParams.amenityIds?.join(','),
  }
  const brandIds = searchParams.brandIds && {
    brand_ids: searchParams.brandIds?.join(','),
  }
  const hotelName = searchParams.hotelNameForSearch && {
    hotel_name: searchParams.hotelNameForSearch,
  }

  const hotelNameForFilter = searchParams.hotelNameForFilter && {
    hotel_name_filter: searchParams.hotelNameForFilter,
  }

  const skip = searchParams.isSkipLinkVisible && {
    skip: 'true',
  }

  const stationCode = searchParams.stationCode && {
    station_code: searchParams.stationCode,
  }

  const orderBy = searchParams.stationCode && {
    'order-by': searchParams.orderBy,
  }

  const offset = searchParams.offset && {
    offset: searchParams.offset,
  }

  const first = searchParams.first && {
    first: searchParams.first,
  }

  const center: CenterMarkerQueryParams = {
    center_lat: searchParams.centerLat,
    center_long: searchParams.centerLong,
    center_query: searchParams.centerQuery,
  }

  if (!center.center_lat) {
    center.center_lat = roundMapCoordinate(searchParams.latitude)
    center.center_long = roundMapCoordinate(searchParams.longitude)
    center.center_query = searchParams.query
  }

  const newParams = {
    'check-in': searchParams.checkIn,
    'check-out': searchParams.checkOut,
    latitude: String(searchParams.latitude),
    longitude: String(searchParams.longitude),
    guests: String(searchParams.guests),
    radius: searchParams.distance,
    query: searchParams.query,
    'ref-point-id': searchParams.referencePointId,
    'group-id': searchParams.groupId,
    'next-page': searchParams.nextPageLink,
    'sync-url': searchParams.syncUrl,
    airport_code: searchParams.airportCode,
    searchby: searchParams.searchBy,
    address: searchParams.locationName,
    'hotel-remove-key': searchParams.hotelRemoveKey,
    'itinerary-id': searchParams.itineraryId,
    'radius-unit': searchParams.distanceUnit,
    name_search: searchParams.nameSearch,
    hide_sold_out: searchParams.hideSoldOut,
    is_selectable: searchParams.isSelectable,
    ...first,
    ...offset,
    ...orderBy,
    ...skip,
    ...stationCode,
    ...starRatings,
    ...amenityIds,
    ...brandIds,
    ...hotelName,
    ...hotelNameForFilter,
    ...center,
  }

  const originalParams = parse(originalQuery)
  return stringify({ ...originalParams, ...newParams })
}
