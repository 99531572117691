import type { ReactElement } from 'react'
import { useState, useEffect } from 'react'

type Props = {
  activeDotClassName: string
  dots: ReactElement[]
}

export function useCarouselDots({ dots, activeDotClassName }: Props) {
  const [translateWidth, setTranslateWidth] = useState(dots.length * -20)
  const [activeDotIndex, setActiveDotIndex] = useState(0)
  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(5)

  useEffect(() => {
    setActiveDotIndex(dots.findIndex((dot) => dot.props.className === activeDotClassName))
  }, [dots, activeDotClassName])

  useEffect(() => {
    const translateValue = -20
    if (activeDotIndex === dots.length - 1) {
      const lastDotIndexValue = 80
      const newMinIndex = dots.length - 6
      const newMaxIndex = dots.length - 1
      setTranslateWidth(activeDotIndex * translateValue + lastDotIndexValue)
      setMinIndex(newMinIndex)
      setMaxIndex(newMaxIndex)
    } else if (activeDotIndex === 0) {
      setTranslateWidth(activeDotIndex * translateValue)
      setMinIndex(0)
      setMaxIndex(5)
    } else if (activeDotIndex === minIndex + 1 && activeDotIndex !== dots.length - 2) {
      const firstDotIndexValue = 20
      const newMinIndex = minIndex - 1
      const newMaxIndex = maxIndex - 1
      setTranslateWidth(activeDotIndex * translateValue + firstDotIndexValue)
      setMinIndex(newMinIndex)
      setMaxIndex(newMaxIndex)
    } else if (activeDotIndex === maxIndex - 1) {
      const nextDotIndexValue = 60
      const newMinIndex = minIndex + 1
      const newMaxIndex = maxIndex + 1
      setTranslateWidth(activeDotIndex * translateValue + nextDotIndexValue)
      setMinIndex(newMinIndex)
      setMaxIndex(newMaxIndex)
    }
  }, [activeDotIndex, dots, minIndex, maxIndex])

  return { translateWidth }
}
