import styled, { css } from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { body, white } from '@fiji/style'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll/animations/animation-basic'

export const SearchFieldContent = styled.div`
  display: flex;
  padding: 8px 16px 8px 6px;
`

export const PwaMsgHandler = styled.div`
  background: ${white};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
`

export const PwaLoader = styled.div`
  margin: 80px auto 0;
  width: 100px;
`

export const PwaErrorMsg = styled.div`
  ${body};
  line-height: 26px;
  padding: 30px 28px 0 53px;
  color: ${(p) => p.theme.colors.bodyText};
  white-space: pre-line;
`

export const ResultsListContainer = styled.ul<{ isOpen: boolean }>`
  top: 100%;
  width: 100%;
  padding: 0 0 10px 0;
  margin: 0;
  list-style: none;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0 0 7px 7px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  position: static;
  box-shadow: none;

  ${(p) =>
    p.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const Separator = styled.div`
  position: sticky;
  top: 56px;
  border-bottom: 1px solid transparent;

  ${createHeaderScrollAnimation(
    'search-mechanism-separator',
    css`
      0% {
        border-bottom-color: transparent;
      }
      1%,
      100% {
        border-bottom-color: ${(props) => props.theme.colors.secondary};
      }
    `,
  )}
`

export const BackButton = styled.button`
  width: 44px;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  background: transparent;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  height: 40px;
  margin-right: 8px;
`

export const ButtonIcon = styled(Icon).attrs({ name: 'closePWA' })`
  width: 16px;
  height: 16px;
`
