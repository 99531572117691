import styled, { css } from 'styled-components'
import { headline, captionStrong, subHeadStrong, footnoteMedium, linkSmall } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  margin-top: 16px;
`

export const DuplicateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 16px;
  border-radius: 14px;
`

export const TripName = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  ${headline};
`

export const CollapseButton = styled(Button)`
  ${linkSmall};
  text-align: left;
`

export const DatesLoader = styled.div`
  ${glowingFragment};
  width: 135px;
  height: 16px;
  margin: 6px 0;
`

export const TripDates = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  ${captionStrong}
  margin: 6px 0;
  text-transform: uppercase;
`

export const HeaderContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 16px;
  padding-bottom: 12px;
`

export const CollapsedContainer = styled.div`
  padding: 16px;
`

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`

export const ListItem = styled.li<{ textTransform?: 'capitalize' | 'none' }>`
  display: flex;
  flex-shrink: 0;
  text-transform: ${(p) => p.textTransform || 'capitalize'};
  max-width: 100%;
  ${textCSS};
  &:not(:first-of-type):before {
    content: '\\2022';
    margin: 0 5px;
  }
  line-height: 1;
`

export const BodyLoaderContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const BodyContainer = styled.div`
  padding: 16px;
`

export const Loader = styled.div<{ height: number; width: number }>`
  ${glowingFragment}
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

export const BodyLoaderRightContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const SegmentContainer = styled.div`
  display: flex;
`

export const SegmentTitle = styled.div`
  ${subHeadStrong};
  color: ${(props) => props.theme.colors.mainText};
`

export const AirSegmentTitleContainer = styled.div`
  display: flex;
`

export const SegmentDescriptionContainer = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  margin-top: 6px;
  padding-bottom: 16px;
`

export const SegmentDetailsContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  margin-bottom: 16px;
`
