import type { ReactNode } from 'react'
import type { HeaderColors } from '@etta/ui/header'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { appMode } from '@fiji/modes'
import type { StyledTextVariant } from '@fiji/enums'
import { ModalHeaderWrapper } from '../modal-styles'
import { ModalContext } from '../modal-context'
import { CloseButton } from './close-button'
import { Title } from './modal-title-styles'
import { ModalTitleLayout } from './layout'

type Props = {
  children: ReactNode
  isStatic?: boolean
  withBorder?: boolean
  color?: HeaderColors
  backgroundColor?: LayoutBackgroundColor
  textVariant?: StyledTextVariant
  'aria-label'?: string
  closeButtonPosition?: 'left' | 'right'
  leftSeparatorWidth?: number
  minHeight?: number
}

export function ModalTitle({
  isStatic,
  textVariant,
  children,
  color,
  backgroundColor,
  withBorder,
  'aria-label': ariaLabel,
  closeButtonPosition = 'left',
  leftSeparatorWidth = 32,
  minHeight,
}: Props) {
  const isWebTripPlanner = appMode.isFijiAll

  return (
    <ModalHeaderWrapper isStatic={isStatic} isMobile={!isWebTripPlanner} withBorder={withBorder}>
      <ModalContext.Consumer>
        {(context) => {
          if (isWebTripPlanner) {
            return (
              <>
                <Title textVariant={textVariant}>{children}</Title>
                <CloseButton onClose={context.onClose} />
              </>
            )
          }

          return (
            <ModalTitleLayout
              color={color}
              backgroundColor={backgroundColor}
              onClose={() => context.onClose(false)}
              aria-label={ariaLabel}
              closeButtonPosition={closeButtonPosition}
              leftSeparatorWidth={leftSeparatorWidth}
              minHeight={minHeight}>
              {children}
            </ModalTitleLayout>
          )
        }}
      </ModalContext.Consumer>
    </ModalHeaderWrapper>
  )
}
