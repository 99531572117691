import { useTranslation } from 'react-i18next'
import type { OptionType } from '@etta/ui/select'
import { PreferenceMealOptionsLayout } from './layout'

type Props = {
  mealOptions: OptionType<string>[]
  mealValue?: string | null
  onUpdateMeal: (data?: string | null) => void
}

export function PreferenceMealOptions({ mealOptions, mealValue, onUpdateMeal }: Props) {
  const i18Base = 'FlightPreferences'
  const { t } = useTranslation()

  return (
    <PreferenceMealOptionsLayout
      label={t(`${i18Base}.MealOptional`)}
      mealOptions={mealOptions}
      mealValue={mealValue}
      onUpdateMeal={onUpdateMeal}
      withNoneOption
    />
  )
}
