import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import { Container } from './seat-map-segment-styled'
import { BaseSeatMap } from './seat-map'
import type { Props } from './seat-map-segment.types'

function SeatMapSegmentRef(
  {
    className,
    seatRows,
    seatsNumberList,
    onSeatChecked,
    bookedSeat,
    selectedSeat,
    usedCabinClass,
    isReadonly,
    isNewSeatMap,
    onOpenPopover,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Container ref={ref} className={className}>
      <BaseSeatMap
        onSeatChecked={onSeatChecked}
        seatRows={seatRows}
        seatsNumberList={seatsNumberList}
        bookedSeat={bookedSeat}
        selectedSeat={selectedSeat}
        usedCabinClass={usedCabinClass}
        isReadonly={isReadonly}
        isNewSeatMap={isNewSeatMap}
        onOpenPopover={onOpenPopover}
      />
    </Container>
  )
}

export const SeatMapSegment = forwardRef(SeatMapSegmentRef)
