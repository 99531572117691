import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { Tabs } from '@etta/ui/tabs'
import { TimeRangeDirection } from '@fiji/enums'
import type { Props } from './types'
import { TimeTab } from './time-tab'
import { AirTimePickerLayout } from './layout'
import { useTimeRangePicker } from './use-time-range-picker'

const i18Base = 'FlightSearch.TimePicker'

export function TimeRangePicker({
  originPlace,
  destinationPlace,
  onSubmit,
  selectedTime,
  isVisible,
  onClose,
  date,
  initialCustomTime,
  timeOptions,
  forSegment,
}: Props) {
  const type = useScreenType()
  const { t } = useTranslation()
  const {
    pickedLeaveTime,
    pickedArrivalTime,
    activeTab,
    handleClose,
    handleSubmit,
    handleChangeTab,
    setPickedArrivalTime,
    setPickedLeaveTime,
  } = useTimeRangePicker({
    onClose,
    selectedTime,
    originPlace,
    destinationPlace,
    onSubmit,
    forSegment,
  })

  const leaveTabText = t(i18Base + '.Leave')
  const arriveTabText = t(i18Base + '.Arrive')

  return (
    <AirTimePickerLayout
      isVisible={isVisible}
      onClose={handleClose}
      handleChangeTab={handleChangeTab}
      activeTab={activeTab}
      tabsSlot={
        <Block marginBottom={type === ScreenType.Mobile ? 24 : 18}>
          <Tabs.Header>
            <Tabs.Tab value={TimeRangeDirection.Departure} aria-label={leaveTabText}>
              {leaveTabText}
            </Tabs.Tab>
            <Tabs.Tab value={TimeRangeDirection.Arrival} aria-label={arriveTabText}>
              {arriveTabText}
            </Tabs.Tab>
          </Tabs.Header>
        </Block>
      }
      departureSlot={
        <TimeTab
          actionLabel={t(i18Base + '.ActionLabelLeave')}
          place={originPlace}
          selectedTime={pickedLeaveTime}
          setPickedTime={setPickedLeaveTime}
          onSubmit={handleSubmit}
          date={date}
          initialCustomTime={initialCustomTime}
          timeOptions={timeOptions}
        />
      }
      returnSlot={
        <TimeTab
          actionLabel={t(i18Base + '.ActionLabelArrive')}
          place={destinationPlace}
          selectedTime={pickedArrivalTime}
          setPickedTime={setPickedArrivalTime}
          onSubmit={handleSubmit}
          date={date}
          initialCustomTime={initialCustomTime}
          timeOptions={timeOptions}
        />
      }
    />
  )
}
