import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { RefinementOptionsEnum } from '@etta/modules/ride-hail/core'
import { Spinner } from '@etta/ui/spinner'
import { MobilityZoneSelectionContent } from './mobility-zone-selection-content'
import { MobilityZoneSelectionSkeleton } from './mobility-zone-selection-skeleton'
import { TRANSITION_NAME } from './transition'
import {
  SheetContainer,
  SheetContent,
  MiniPill,
  HeaderContent,
  SubHeaderContent,
  BodyContent,
  ScrollContainer,
  NextButtonWrapper,
  Separator,
} from './mobility-zone-selection-dialog-styled'
import { useMobilityZoneSelectionDialog } from './use-mobility-zone-selection-dialog'
import type { Props } from './types'

const i18nBase = 'Mobility.ZoneSelectionDialog'

export const MobilityZoneSelectionDialog = observer(function MobilityZoneSelectionDialog({
  map,
  isOpen,
  handleOnEntered,
  isFetchingRideHailConfig,
}: Props) {
  const { t } = useTranslation()

  const {
    shouldRenderChildZone,
    zoneName,
    childZones,
    accessPoints,
    handleSelect,
    handleNextButton,
    selectedZone,
    selectedAccessPoint,
    shouldDisableNextButton,
    isLoading,
    dialogContainerRef,
    refinementOptionsAction,
  } = useMobilityZoneSelectionDialog({
    map,
    isOpen,
    handleOnEntered,
    isFetchingRideHailConfig,
  })

  const refinementOptionsTitle = useMemo(() => {
    switch (refinementOptionsAction) {
      case RefinementOptionsEnum.PickUpZoning:
        return t(`${i18nBase}.Header`, { refinement: t(`${i18nBase}.PickUp`) })
      case RefinementOptionsEnum.DropOffZoning:
        return t(`${i18nBase}.Header`, { refinement: t(`${i18nBase}.DropOff`) })
    }
  }, [refinementOptionsAction, t])

  return (
    <SheetContainer ref={dialogContainerRef}>
      <DialogAnimation
        isOpen={isOpen}
        onEntered={handleOnEntered}
        onExited={() => {}}
        isAnimated
        transitionName={TRANSITION_NAME}>
        <SheetContent>
          <MiniPill />
          <Block isFullWidth={true} paddingLeft={16}>
            <Text variant="footnoteMedium" color="bodyText">
              {refinementOptionsTitle}
            </Text>
          </Block>
          <HeaderContent>
            <Swap is={isLoading} isSlot={<MobilityZoneSelectionSkeleton isHeader />}>
              <Text variant="headline" color="mainText">
                {zoneName}
              </Text>
            </Swap>
          </HeaderContent>
          <Separator />
          {!isLoading && !shouldRenderChildZone && selectedZone && (
            <SubHeaderContent>
              <Block marginRight={4}>
                <Text variant="subHeadMedium" color="mainText">
                  {t(`${i18nBase}.SubHeader`)}
                </Text>
              </Block>
              <Text variant="subHeadStrong" color="mainText">
                {selectedZone?.name}
              </Text>
            </SubHeaderContent>
          )}
          <ScrollContainer>
            <BodyContent>
              <Swap
                is={isLoading}
                isSlot={
                  <>
                    <MobilityZoneSelectionSkeleton />
                    <MobilityZoneSelectionSkeleton />
                  </>
                }>
                <MobilityZoneSelectionContent
                  onSelect={handleSelect}
                  zones={childZones}
                  accessPoints={accessPoints}
                  selectedZone={selectedZone ?? undefined}
                  selectedAccessPoint={selectedAccessPoint}
                  shouldRenderChildZones={shouldRenderChildZone}
                />
              </Swap>
            </BodyContent>
          </ScrollContainer>
          <NextButtonWrapper
            btnType="primary"
            onClick={handleNextButton}
            disabled={shouldDisableNextButton}
            data-tracking-id="select-zone-button"
            rightIcon={
              isFetchingRideHailConfig ? (
                <Spinner type="spinout" color="white" size="small" />
              ) : (
                <></>
              )
            }>
            {isFetchingRideHailConfig
              ? t(`${i18nBase}.LoadingButton`)
              : t(`${i18nBase}.NextButton`)}
          </NextButtonWrapper>
        </SheetContent>
      </DialogAnimation>
    </SheetContainer>
  )
})
