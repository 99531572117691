import { SegmentType } from '@etta/core/enums'
import { useCarSearchQuery } from '@fiji/hooks/search-queries/use-car-search-query/use-car-search-query'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { useAppDispatch } from '@fiji/store'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { ScreenType, useScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { getQueryParamsPostBooking } from './get-query-params'

type Args = {
  itineraryId: string
  segments: SegmentPostBookingValueObject[]
  bookingId?: string
}

export function useCarPlacePostBooking({ itineraryId, segments, bookingId }: Args) {
  const { navigateTo, appendQueryParams } = useCarSearchQuery()
  const dispatch = useAppDispatch()
  const isDesktop = useScreenType() !== ScreenType.Mobile
  const { postBookingAddSegmentAction } = usePostBookingContext()

  const onAddCar = () => {
    const carRentalResultsUrl = '/explore/car-rental-results'
    const carRentalFormUrl = '/explore/car-rental-search'

    const params = getQueryParamsPostBooking({
      segments,
      forType: SegmentType.CarRental,
    })

    dispatch(updateTripReviewStatus('update'))
    dispatch(updatePostPurchaseEntrance('fromTripDetails'))

    const isShowCarRentalForm = !params || !params.endDate || !params.endTime

    if (isDesktop && isShowCarRentalForm) {
      appendQueryParams({
        itineraryId,
        pickUpLocationId: '',
        pickUpTime: params?.startTime,
        pickUpDate: params?.startDate,
        pickUpPlace: params?.from,
        dropOffLocationId: '',
        dropOffTime: params?.endTime,
        dropOffDate: params?.endDate,
        dropOffPlace: params?.to,
        bookingId,
        selectedTab: SegmentType.CarRental,
      })
      postBookingAddSegmentAction.handleAddCar()

      return
    }

    navigateTo(isShowCarRentalForm ? carRentalFormUrl : carRentalResultsUrl, {
      itineraryId,
      pickUpLocationId: '',
      pickUpTime: params?.startTime,
      pickUpDate: params?.startDate,
      pickUpPlace: params?.from,
      dropOffLocationId: '',
      dropOffTime: params?.endTime,
      dropOffDate: params?.endDate,
      dropOffPlace: params?.to,
      bookingId,
      selectedTab: SegmentType.CarRental,
    })
  }

  return {
    onAddCar,
  }
}
