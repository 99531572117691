import type { MutableRefObject } from 'react'
import { useCallback } from 'react'
import type { Hotel } from '@fiji/graphql/types'
import { HotelCard } from '../hotel-card'
import { HotelCardContainer, HotelCardContainerWrapper } from './hotel-card-block-styled'
import { useHotelCardBlock } from './use-hotel-card-block'

type Props = {
  parentRef: MutableRefObject<HTMLDivElement | null>
  hotel: Hotel
  onClose: () => void
  onSelect?: (selectedId: string) => void
}

export function HotelCardBlock({ hotel, onClose, parentRef, onSelect }: Props) {
  const { mergedRef, edgeVertical, edgeHorizontal } = useHotelCardBlock({
    hotel,
    onClose,
    parentRef,
  })

  const handleHotelCardClick = useCallback(() => {
    if (onSelect) {
      onSelect(hotel.id)
    }
  }, [hotel.id, onSelect])

  return (
    <HotelCardContainerWrapper edgeVertical={edgeVertical}>
      <HotelCardContainer
        ref={mergedRef}
        edgeHorizontal={edgeHorizontal}
        data-tracking-id="hotel-small-card-map">
        <HotelCard hotel={hotel} onClick={handleHotelCardClick} />
      </HotelCardContainer>
    </HotelCardContainerWrapper>
  )
}
