import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  margin: 24px 0;
  display: flex;
  ${(p) =>
    p.isMobile &&
    css`
      flex-direction: column-reverse;
    `}
  gap: 16px;
`
