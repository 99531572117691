import { useEffect, useState } from 'react'
import { useTravelerInfo } from '@etta/screens/traveler-info/use-traveler-info'
import { usePurchaseAdditionalInfoForm } from '@etta/screens/purchase-additional-info-form/use-purchase-additional-info-form'
import { usePaymentSummary } from '@etta/screens/payment-summary/use-payment-summary'
import { useTravelFusionLinks } from '@etta/screens/travel-fusion-links/use-travel-fusion-links'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

export function useCheckoutContext() {
  const { tripActions, reviewTripStore } = useReviewTripContext()

  useEffect(() => {
    tripActions.setTripIdUrlParams()
    if (reviewTripStore.trip) {
      return
    }
    tripActions.loadTrip()
  }, [tripActions, reviewTripStore])

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState<boolean>(false)
  const setContinueButtonDisabled = (isDisabled: boolean) => setIsContinueButtonDisabled(isDisabled)
  const travelerInfo = useTravelerInfo()
  const additionalInfo = usePurchaseAdditionalInfoForm()
  const paymentSummary = usePaymentSummary()
  const travelFusionLinks = useTravelFusionLinks()

  return {
    travelerInfo,
    additionalInfo,
    paymentSummary,
    travelFusionLinks,
    isContinueButtonDisabled,
    setContinueButtonDisabled,
  }
}
