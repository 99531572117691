import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 228px;
  margin-bottom: 24px;
`

export const Image = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`

export const SwitchButton = styled(Button).attrs(() => ({
  fullWidth: true,
}))`
  font-size: 17px;
  height: 56px;
`

export const Title = styled(Text).attrs(() => ({
  variant: 'largeTitle',
}))`
  font-size: 34px;
`
