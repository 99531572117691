import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'

export function InfoModalHeaderMobile({ title, onClose }: LayoutProps) {
  return (
    <Modal.Header isMobile>
      <Header withBorderBottom leftSlot={<Header.CloseButton onClick={onClose} />}>
        <Header.Title title={title} align="left" />
      </Header>
    </Modal.Header>
  )
}
