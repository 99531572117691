import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Container } from './baggage-information-mobile.styled'

const i18BaseBaggageInfo = 'BaggageInformation.'

type Props = {
  carrierUrl: string
  carrierName: string
}

export function RTPItineraryCardBaggageInformationMobile({ carrierName, carrierUrl }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Block isFlexBlock alignItems="flex-start">
        <div>
          <Icon name="noCheckedBagInfoPWA" color="bodyText" />
        </div>
        <Block paddingLeft={4}>
          <Text variant="footnoteMedium" color="bodyText">
            <Swap
              is={!carrierUrl}
              isSlot={
                <>{t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailableVisitAirlineWebsite')}</>
              }>
              {t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailableFull')}{' '}
              <Link size="small" href={carrierUrl} target="_blank">
                {carrierName}
                <Icon name="externalLinkPWA" color="primary" size={16} />
              </Link>
            </Swap>
          </Text>
        </Block>
      </Block>
    </Container>
  )
}
