import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { RailSearchResultPageMobile } from './mobile'
import { RailSearchResultPageDesktop } from './desktop'

export function RailSearchResultPageLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailSearchResultPageMobile {...props} />}
      desktopSlot={<RailSearchResultPageDesktop {...props} />}
    />
  )
}
