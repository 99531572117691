import { Action, Inject } from '@etta/di'
import { RemoveTripService } from '@etta/modules/review-trip/interface/services'
import { ActiveTripStore } from '../stores/active-trip.store'

@Action()
export class ActiveTripActions {
  constructor(
    @Inject()
    private readonly activeTripStore: ActiveTripStore,

    @Inject()
    private readonly removeTripService: RemoveTripService,
  ) {}

  removeActiveTrip() {
    // TODO: to be removed
    this.removeTripService.removeActiveTrip()
  }

  clearActiveTrip() {
    this.activeTripStore.dropCurrentlyReactivatedID()
    this.activeTripStore.clearStore()
  }

  handleSetIsTripExpired(value: boolean) {
    this.activeTripStore.setIsTripExpired(value)
  }
}
