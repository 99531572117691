import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace RideHailExpenseConfigErrors {
  export const UnexpectedRideHailExpenseConfigError = createErrorClass(
    'UnexpectedRideHailExpenseConfigError',
  )
}

export type RideHailExpenseConfigErrorsInstances = InstanceType<
  typeof RideHailExpenseConfigErrors[keyof typeof RideHailExpenseConfigErrors]
>
