import { Block } from '@etta/ui/block'
import { Screen } from '@etta/ui/screen'
import { DelegateItem } from '@etta/components/delegate-item/delegate-item'
import { DelegateSwitchLayout } from '@fiji/enums'
import type { LayoutProps } from '../../types'
import { Background } from '../../components'

export function TripDetailsMobileLayout({
  trip,
  contentSlot,
  headerSlot,
  footerSlot,
  scrollContainerRef,
  isShowDelegateName,
  currentDelegatedUser,
}: LayoutProps) {
  return (
    <Screen backgroundColor="background">
      <Screen.Container as={'main'} ref={scrollContainerRef}>
        {headerSlot}
        <Background url={trip.cityImageUrl} />
        <Block>
          {isShowDelegateName && (
            <Block paddingTop={15}>
              <DelegateItem
                delegate={currentDelegatedUser}
                layout={DelegateSwitchLayout.TripDetails}
              />
            </Block>
          )}
          {contentSlot}
        </Block>
      </Screen.Container>
      {footerSlot}
    </Screen>
  )
}
