import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import {
  BodyLeft,
  BodyLeftIcon,
  BodyPercent,
  PercentNumber,
} from './flight-details-eco-check-mobile-styled'

export function BodyLeftBlock({
  deviationLevel,
  percentDeviation,
}: {
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}) {
  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return (
        <BodyLeft deviationLevel={deviationLevel} percentDeviation={percentDeviation}>
          <Icon name="averagePWA" size={40} />
        </BodyLeft>
      )
    case EmissionsDeviationLevel.Less:
      return (
        <BodyLeft deviationLevel={deviationLevel} percentDeviation={percentDeviation}>
          <BodyLeftIcon name="arrowDownwardPWA" size={28} />
          <PercentNumber>{percentDeviation}</PercentNumber>
          <BodyPercent>%</BodyPercent>
        </BodyLeft>
      )
    case EmissionsDeviationLevel.More:
      return (
        <BodyLeft deviationLevel={deviationLevel} percentDeviation={percentDeviation}>
          <BodyLeftIcon name="arrowUpwardPWA" size={28} />
          <PercentNumber>{percentDeviation}</PercentNumber>
          <BodyPercent>%</BodyPercent>
        </BodyLeft>
      )
  }
}
