export default function SearchPinPWA() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 35.1692C21.9906 35.1565 21.981 35.1437 21.9712 35.1306C21.6993 34.7657 21.3105 34.238 20.8436 33.5878C19.9097 32.2871 18.6653 30.4986 17.4218 28.5455C16.1771 26.5906 14.9392 24.4799 14.0141 22.5333C13.0794 20.5664 12.5 18.8389 12.5 17.6254C12.5 12.5809 16.7134 8.5 22 8.5C27.2866 8.5 31.5 12.5809 31.5 17.6254C31.5 18.8389 30.9206 20.5664 29.9859 22.5333C29.0608 24.4799 27.8229 26.5906 26.5782 28.5455C25.3347 30.4986 24.0903 32.2871 23.1564 33.5878C22.6895 34.238 22.3007 34.7657 22.0288 35.1306C22.019 35.1437 22.0094 35.1565 22 35.1692ZM16.9542 17.6237C16.9542 20.3689 19.1974 22.4992 22 22.4992C24.8026 22.4992 27.0458 20.3688 27.0458 17.6237C27.0458 14.8785 24.8026 12.7482 22 12.7482C19.1954 12.7482 16.9542 14.8805 16.9542 17.6237Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
