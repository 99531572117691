import { Fragment } from 'react'
import { Text } from '@etta/ui/text'
import { HotelNameWrapper } from './hotel-details-map-styled'
import { HotelMarker } from './hotel-marker'

type MarkerProps = {
  hotelName?: string
}
export function HotelDetailsMarker({ hotelName }: MarkerProps) {
  return (
    <Fragment>
      <HotelMarker />
      {hotelName && (
        <HotelNameWrapper>
          <Text variant="footnoteStrong" color="mainText">
            {hotelName}
          </Text>
        </HotelNameWrapper>
      )}
    </Fragment>
  )
}
