import styled, { css } from 'styled-components'
import { captionMedium, subHeadStrong } from '@fiji/style'

export const Paper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
`

export const Container = styled(Paper)<{ verticalPadding?: number; horizontalPadding?: number }>`
  ${({ verticalPadding = 16, horizontalPadding = 16 }) => css`
    padding: ${verticalPadding}px ${horizontalPadding}px;
  `};
  display: flex;
  flex-wrap: wrap;
`

export const Row = styled.div`
  display: flex;
  width: 100%;

  > * + * {
    margin-left: 16px;
  }
`

export const Cell = styled.div`
  width: 100%;
`

export const CellName = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${captionMedium};
`

export const CellValue = styled.div`
  margin-top: 4px;
  color: ${(p) => p.theme.colors.mainText};
  ${subHeadStrong};
`

export const Separator = styled.div`
  margin: 16px 0;
  width: 100%;
  border-bottom: 1px dashed ${(p) => p.theme.colors.borderDark};
`
