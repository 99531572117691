import { CustomFieldType } from '@fiji/graphql/types'
import type { Props } from './types'
import {
  getTextFieldScheme,
  getNumberScheme,
  getDatetimeScheme,
  getEnumerationScheme,
  getCheckboxScheme,
} from './scheme'

export const getYupScheme = ({ customField }: Props) => {
  switch (customField.type) {
    default:
    case CustomFieldType.Text:
    case CustomFieldType.PreTrip:
      return getTextFieldScheme({ customField })

    case CustomFieldType.Enumeration:
      return getEnumerationScheme({ customField })

    case CustomFieldType.Checkbox:
      return getCheckboxScheme({ customField })

    case CustomFieldType.Number:
    case CustomFieldType.Percentage:
    case CustomFieldType.Monetary:
      return getNumberScheme({ customField })

    case CustomFieldType.Date:
    case CustomFieldType.Time:
    case CustomFieldType.Datetime:
      return getDatetimeScheme({ customField })
  }
}
