import styled from 'styled-components'
import { headline } from '@fiji/style'

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  max-height: 128px;
  min-height: 128px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;

  > * img {
    transform: translateY(-25%);
    width: 100%;
    height: auto;
  }
`

export const Header = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.background1};
`

export const HotelTitle = styled.div`
  ${headline};
  margin-bottom: 8px;
`

export const SustainabilityContainer = styled.div`
  padding-top: 5px;
`
