import { Result } from 'fnscript'
import { ApolloError } from '@apollo/client'
import { Inject, Adapter } from '@etta/di'
import { CarRentalErrors } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import { getRestrictedErrorData } from '@fiji/utils/get-restricted-error-message'
import type {
  CarRentalClassType,
  CarRentalFuelType,
  CarRentalTransmissionType,
} from '@fiji/graphql/hooks'
import type { CarDetailsValueObject, CarRentalsValueObject } from '../../../core/value-objects'
import type { CarRentalSearchResults, CarRentalsArgs, CarDetailsArgs } from '../../types'
import { CarRentalDataProvider } from '../../data-providers'
import { CarRentalMapper } from '../../mappers'

@Adapter()
export class CarRentalAdapter {
  constructor(
    @Inject()
    private carRentalDataProvider: CarRentalDataProvider,
  ) {}

  async getCarRentals({
    input,
    forceUpdate,
  }: CarRentalsArgs): CarRentalSearchResults<CarRentalsValueObject> {
    try {
      const { data, error } = await this.carRentalDataProvider.getCarRentalsWithAbortController(
        {
          ...input,
          classTypes: input.classTypes as CarRentalClassType[],
          fuelTypes: input.fuelTypes as CarRentalFuelType[],
          transmissionTypes: input.transmissionTypes as CarRentalTransmissionType[],
        },
        forceUpdate,
      )

      if (error instanceof ApolloError && error.graphQLErrors) {
        const { isRestrictedCountryError, restrictedErrorMessage } = getRestrictedErrorData(
          error.graphQLErrors || [],
          'restrictionMessage',
        )

        if (isRestrictedCountryError) {
          return Result.Err(
            new CarRentalErrors.RestrictedCountryError({ message: restrictedErrorMessage || '' }),
          )
        }
      }

      if (error) {
        return Result.Err(new CarRentalErrors.CarRentalsResponse(error))
      }

      return Result.Ok(CarRentalMapper.toCarRentalsValueObject(data.carRentals))
    } catch (e) {
      return Result.Err(new CarRentalErrors.UnexpectedErrorCarRentals(e))
    }
  }

  async getCarDetails(
    input: CarDetailsArgs,
    forceUpdate?: boolean,
  ): CarRentalSearchResults<CarDetailsValueObject> {
    try {
      const { data, error } = await this.carRentalDataProvider.getCarDetailsWithAbortController(
        input,
        forceUpdate,
      )

      if (error) {
        return Result.Err(new CarRentalErrors.CarDetailsResponse(error))
      }

      return Result.Ok(CarRentalMapper.toCarDetailsValueObject(data.carDetails))
    } catch (e) {
      return Result.Err(new CarRentalErrors.UnexpectedErrorCarDetails(e))
    }
  }
}
