import { useTranslation } from 'react-i18next'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import type { SafetyCheckFlight } from '@fiji/types'
import { SafetyHeader } from '../../../collapsed-view/collapses-view-styled'
import { ExpandedViewList } from '../../expanded-view-list'
import { Container, SeeMoreDetails } from './expanded-view-desktop-styled'

type Props = {
  flightSafetyChecks: SafetyCheckFlight[]
  hasDetails: boolean
  onModalOpenClick?: () => void
}

export function ExpandedViewDesktop({ flightSafetyChecks, hasDetails, onModalOpenClick }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'
  const seeMoreLink = t(i18nBase + '.SeeMoreLink')
  const seeMoreLinkLabel = t(i18nBase + '.SeeMoreLinkLabel', { label: seeMoreLink })

  return (
    <Container data-tracking-id="flight-details-modal-safety-check-expanded">
      <SafetyHeader>
        <SafetyCheckTitle />
      </SafetyHeader>
      {flightSafetyChecks.map(
        (safetyCheck, index) =>
          !!safetyCheck.headLines.length && (
            <ExpandedViewList
              key={index}
              safetyCheck={safetyCheck}
              multipleFlights={flightSafetyChecks.length > 1}
            />
          ),
      )}
      {hasDetails && (
        <SeeMoreDetails
          role="button"
          tabIndex={0}
          onClick={onModalOpenClick}
          aria-label={seeMoreLinkLabel}>
          {seeMoreLink}
        </SeeMoreDetails>
      )}
    </Container>
  )
}
