import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'

type Props = {
  isOnHold: boolean
  onContinueBooking: () => void
}

const i18Base = 'PostBooking.TripDetails'

export function DetailsFooter({ isOnHold, onContinueBooking }: Props) {
  const { t } = useTranslation()

  if (!isOnHold) {
    return null
  }

  return (
    <Screen.Footer withBorder>
      <Block paddingHorizontal={16} paddingVertical={16}>
        <Button fullWidth variant="solid" onClick={onContinueBooking}>
          {t(i18Base + '.ContinueBooking')}
        </Button>
      </Block>
    </Screen.Footer>
  )
}
