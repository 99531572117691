import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export function SubmitSectionDesktop({ isEnabledToSubmit, handleSearch }: Props) {
  const { t } = useTranslation()

  return (
    <Button
      variant="solid"
      btnType="primary"
      size="normal"
      fullWidth={false}
      disabled={!isEnabledToSubmit}
      onClick={handleSearch}
      data-tracking-id="search-hotels-button">
      <Text variant="headline" color="mainText2">
        {t(i18Base + '.Buttons.SearchHotel')}
      </Text>
    </Button>
  )
}
