import type { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Policy } from '@fiji/graphql/types'
import { CarDetailsMobileOopReasons } from '../car-details-mobile-oop-reasons/car-details-mobile-oop-reasons'
import {
  CarImage,
  CarTitle,
  CarTitleName,
  CloseButtonTop,
  Header,
  VendorImg,
  VendorImgContainer,
  TitlesContainer,
  CloseButton,
  PolicyContainer,
  PreferedLabelContainer,
  Label,
} from './car-details-mobile-header-styled'

type Props = {
  headerRef: MutableRefObject<HTMLDivElement | null>
  onClose: () => void
  isOutOfPolicy: boolean
  carImageUrl?: string | null
  vendorName: string | null
  vendorImage: string | null
  carRentalTitle: string
  isPreferred: boolean
  carPolicy?: Policy
  customBadge?: string | null
}

export function CarDetailsMobileHeader({
  headerRef,
  onClose,
  isOutOfPolicy,
  vendorName,
  vendorImage,
  carImageUrl,
  carRentalTitle,
  isPreferred,
  carPolicy,
  customBadge,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <CarImage carImageUrl={carImageUrl} />
      <CloseButtonTop
        icon="closePWA"
        color="mainText2"
        onClick={onClose}
        aria-label={t('Accessibility.Header.Close')}
      />
      <Header ref={headerRef}>
        <CloseButton
          icon="closePWA"
          color="mainText"
          onClick={onClose}
          aria-label={t('Accessibility.Header.Close')}
        />
        <TitlesContainer>
          <CarTitle>
            <CarTitleName>{carRentalTitle}</CarTitleName>
            <VendorImgContainer>
              {vendorImage && <VendorImg src={vendorImage} alt={vendorName || ''} />}
            </VendorImgContainer>
          </CarTitle>
        </TitlesContainer>
        <PolicyContainer>
          {isPreferred && !customBadge && (
            <PreferedLabelContainer>
              <Icon name="preferredPWA" size="small" aria-hidden />
              <Label role="region">{t('Badge.PreferredVendor')}</Label>
            </PreferedLabelContainer>
          )}
          {customBadge && (
            <PreferedLabelContainer>
              <Icon name="customPreferredPWA" color="success" size="small" aria-hidden />
              <Label role="region">{customBadge}</Label>
            </PreferedLabelContainer>
          )}
          <CarDetailsMobileOopReasons isOutOfPolicy={isOutOfPolicy} carPolicy={carPolicy} />
        </PolicyContainer>
      </Header>
    </>
  )
}
