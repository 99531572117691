import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'
import { Block } from '@etta/ui/block'
import { HORIZONTAL_PADDING_LARGE, HORIZONTAL_PADDING_MEDIUM } from '../../../constants'
import type { LayoutProps } from '../../types'

export function CheckoutPageModalHeaderDesktop({
  title,
  onClose,
  children,
  ...headerProps
}: LayoutProps) {
  return (
    <Modal.Header isMobile>
      <Header
        withBorderBottom
        minHeight={72}
        align="center"
        rightSlot={
          <Block paddingRight={HORIZONTAL_PADDING_MEDIUM}>
            <IconButton onClick={onClose} icon="closeFilledPWA" size="medium" color="bodyText" />
          </Block>
        }
        {...headerProps}>
        <Block paddingLeft={HORIZONTAL_PADDING_LARGE}>
          <Header.Title title={title} align="left" />
        </Block>
      </Header>
      {children}
    </Modal.Header>
  )
}
