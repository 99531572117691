import type { FocusElement } from './types'

export function isNodeInContainer(
  parent: FocusElement | null | undefined,
  node: HTMLElement | null,
) {
  if (!parent) {
    return false
  }
  let findNode = node
  while (findNode) {
    if (findNode === parent) {
      return true
    }
    findNode = findNode.parentElement
  }
  return false
}
