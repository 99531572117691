import { Skeleton } from '@etta/ui/skeleton'

export function GeoSafeScaleSkeleton() {
  return (
    <Skeleton width="100%" height="65">
      <rect width="100%" height="10" y="34" rx="5" />
      <rect width="16%" height="11" y="51" />
      <rect width="16%" height="11" x="84%" y="51" />
    </Skeleton>
  )
}
