import styled from 'styled-components'
import { withStyledText } from '@fiji/style/text-styles'
import { Radio as MobileRadio } from '@etta/ui/radio'

export const Container = styled.div`
  margin-top: 24px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Title = styled.div`
  margin-bottom: 16px;
  ${withStyledText}
`

export const InputContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const CabinClassSelectorContainer = styled.div`
  margin-right: -16px;
`

export const Radio = styled(MobileRadio)`
  margin: 18px 6px;
`
