import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCalendarRender } from '@fiji/hooks/use-date-picker'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { useDayBlocked } from './use-day-blocked'
import type { UseCalenderParams } from './types'

type ChangeHandlerArgs = {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

type ChangeHandlerFn = (args: ChangeHandlerArgs) => void

export function useCalendar({
  date,
  dateEnd,
  minDate,
  maxDate,
  previousDate,
  highlightDates,
  onDaySelect,
  numberOfMonths,
}: UseCalenderParams) {
  const { airSearchFormStore } = useAirSearchFormContext()
  const isMultiCity = airSearchFormStore.searchFlightType === FlightType.MultiCity
  const startDateValue = date ? moment(date) : null
  const endDateValue = dateEnd ? moment(dateEnd) : startDateValue
  const previousDateValue = previousDate ? moment(previousDate) : startDateValue

  const numberOfMonthsValue = useMemo(() => {
    if (!minDate || !date) {
      return numberOfMonths
    }
    if (moment(minDate).isSame(date, 'year')) {
      return numberOfMonths
    }

    return moment(date).diff(minDate, 'month') + 1

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfMonths])

  const { container } = useCalendarRender({ startDateValue })

  const isDayBlockedHandler = useDayBlocked({ minDate, maxDate })
  const handleDatesChange = useCallback<ChangeHandlerFn>(
    ({ startDate, endDate }) => {
      onDaySelect({
        date: startDate?.toDate(),
        dateEnd: endDate?.toDate(),
      })
    },
    [onDaySelect],
  )
  const handleFocusChange = useCallback(() => {}, [])
  const shouldHighlightDay = useCallback(
    (day: moment.Moment) => {
      return highlightDates?.some((date) => moment(day).isSame(date, 'day')) || false
    },
    [highlightDates],
  )

  const isOutsideRangeHandler = useCallback((day: moment.Moment) => {
    return day.isBefore(moment(), 'day') || day.isAfter(moment().add(1, 'y'), 'day')
  }, [])

  const [isLocaleNormalized, setIsLocaleNormalized] = useState(false)

  function initialVisibleMonth() {
    return previousDateValue || moment()
  }

  useEffect(() => {
    if (isLocaleNormalized) {
      return
    }
    if (moment.localeData().firstDayOfWeek() !== 0) {
      moment.updateLocale(moment.locale(), {
        week: {
          dow: 0,
        },
      })
    }
    setIsLocaleNormalized(true)
  }, [isLocaleNormalized])

  return {
    startDateValue,
    endDateValue,
    container,
    isDayBlockedHandler,
    handleDatesChange,
    handleFocusChange,
    shouldHighlightDay,
    isOutsideRangeHandler,
    isLocaleNormalized,
    isMultiCity,
    numberOfMonthsValue,
    initialVisibleMonth,
  }
}
