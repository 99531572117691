import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Radio } from '@etta/ui/radio'
import { Text } from '@etta/ui/text'
import { Section, SectionTitle } from '../rail-filters-styled'
import { useRailStopsFilter } from './use-rail-stops-filter'

const i18Base = 'RailFilters.Rail'

export const RailStopsFilter = observer(function RailStopsFilter() {
  const { t } = useTranslation()
  const { stopsToShow, stopsAttributesMap, onChangeStops, isAny } = useRailStopsFilter()

  return (
    <Section withBorder>
      <SectionTitle>{t(i18Base + '.NumberOfStops')}</SectionTitle>
      <Block marginBottom={10}>
        <Block paddingVertical={18}>
          <Radio isChecked={isAny} onChange={() => onChangeStops([])}>
            <Text variant="subHeadMedium" color="mainText">
              {t(i18Base + '.Stops.Any')}
            </Text>
          </Radio>
        </Block>
        {stopsToShow.map((option, index) => {
          const { isChecked, name } = stopsAttributesMap[option.value]
          return (
            <Block key={index} paddingVertical={18}>
              <Radio isChecked={isChecked} onChange={() => onChangeStops([option])}>
                <Text variant="subHeadMedium" color="mainText">
                  {name}
                </Text>
              </Radio>
            </Block>
          )
        })}
      </Block>
    </Section>
  )
})
