import { MediaLayout } from '@etta/ui/media-layout'
import type { OutOfPolicyModalComponentProps } from '../types'
import { OutOfPolicyModalMobile } from './mobile'
import { OutOfPolicyModalDesktop } from './desktop'

export function OutOfPolicyModalLayout(props: OutOfPolicyModalComponentProps) {
  return (
    <MediaLayout
      mobileSlot={<OutOfPolicyModalMobile {...props} />}
      desktopSlot={<OutOfPolicyModalDesktop {...props} />}
    />
  )
}
