import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { useAirports } from '../../use-flight-details'
import {
  AirportCode,
  AirportCodeWrapper,
  AirportWithTerminal,
  Duration,
  FlexWrapper,
  FlightInfo,
  Time,
  Timeline,
  TimelineBlock,
  TimelineSolidLine,
  Location,
} from './flight-timeline-styled'

type Props = {
  segment: TripDetailsFlightLegSegment
}

const i18nBase = 'PostBooking.FlightDetails'

export const FlightTimeLine = ({ segment }: Props) => {
  const { t } = useTranslation()

  const {
    destinationAirportCode,
    destinationAirportName,
    destinationCity,
    destinationDate,
    destinationGate,
    destinationTerminal,
    destinationTime,
    flightDuration,
    originAirportCode,
    originAirportName,
    originCity,
    originDate,
    originGate,
    originTerminal,
    originTime,
    handleOriginLocationClick,
    handleDestinationLocationClick,
  } = useAirports({ segment })

  const originGateText = originGate ? t(i18nBase + '.Gate', { gate: originGate }) : ''
  const destinationGateText = destinationGate
    ? t(i18nBase + '.Gate', { gate: destinationGate })
    : ''
  const originTerminalText = originTerminal
    ? t(i18nBase + '.Terminal', { terminal: originTerminal })
    : ''
  const destinationTerminalText = destinationTerminal
    ? t(i18nBase + '.Terminal', { terminal: destinationTerminal })
    : ''

  const originalDateText = t(i18nBase + '.TakeOff', {
    date: `${originTime} ${originDate}`,
    airport: originCity,
  })
  const destinationDateText = t(i18nBase + '.Arriving', {
    date: `${destinationTime} ${destinationDate}`,
    airport: destinationCity,
  })
  const ariaLabel = t(i18nBase + '.DepartureText', {
    from: `${originAirportCode}, ${originalDateText}, ${originAirportName}, ${originTerminalText}, ${originGateText}`,
    to: `${destinationAirportCode}, ${destinationDateText}, ${destinationAirportName}, ${destinationTerminalText}, ${destinationGateText}`,
    time: flightDuration,
  })

  return (
    <Block paddingHorizontal={20} paddingTop={20} aria-label={ariaLabel}>
      <FlexWrapper>
        <Timeline>
          <AirportCodeWrapper>
            <AirportCode>{originAirportCode}</AirportCode>
            <TimelineSolidLine />
          </AirportCodeWrapper>
          <TimelineBlock>
            <FlightInfo
              tabIndex={0}
              aria-label={t('FlightDetails.OriginSegmentDetails', {
                airport: originAirportCode,
                time: originTime,
                date: originDate,
                description: `${originAirportCode}, ${originTerminalText}`,
              })}>
              <Time>{originTime}</Time>
              <Block marginTop={3}>
                <Text variant="captionStrongCaps" color="bodyText">
                  {originDate}
                </Text>{' '}
                <Text variant="footnoteMedium" color="bodyText">
                  • {originCity}
                </Text>
              </Block>
              <AirportWithTerminal>
                <Text
                  data-tracking-id="departure-airport-name-text"
                  variant="footnoteMedium"
                  color="bodyText">
                  {originAirportName}
                  {originTerminalText && ','}
                </Text>{' '}
                <Text
                  data-tracking-id="departure-terminal-text"
                  variant="footnoteMedium"
                  color="bodyText">
                  {originTerminalText}
                </Text>{' '}
                <Text
                  data-tracking-id="departure-gate-text"
                  variant="footnoteMedium"
                  color="bodyText">
                  {originGateText}
                </Text>
              </AirportWithTerminal>
              <Location onClick={handleOriginLocationClick}>
                <Icon
                  name="directionsPWA"
                  color="primary"
                  data-tracking-id="origin-direction-icon"
                />
              </Location>
            </FlightInfo>
            <Block marginVertical={18}>
              <Duration>
                <Icon name="timePWA" size="small" />
                {flightDuration}
              </Duration>
            </Block>
          </TimelineBlock>
        </Timeline>
      </FlexWrapper>
      <FlexWrapper tabIndex={0}>
        <AirportCodeWrapper>
          <AirportCode>{destinationAirportCode}</AirportCode>
        </AirportCodeWrapper>
        <TimelineBlock>
          <FlightInfo
            aria-label={t('FlightDetails.DestinationSegmentDetails', {
              airport: destinationAirportCode,
              time: destinationTime,
              date: destinationDate,
              description: `${destinationAirportCode}, ${destinationTerminalText}`,
            })}>
            <Time>{destinationTime}</Time>
            <Block marginTop={3}>
              <Text variant="captionStrongCaps" color="bodyText">
                {destinationDate}
              </Text>{' '}
              <Text variant="footnoteMedium" color="bodyText">
                • {destinationCity}
              </Text>
            </Block>
            <AirportWithTerminal>
              <Text
                data-tracking-id="arrival-airport-name-text"
                variant="footnoteMedium"
                color="bodyText">
                {destinationAirportName}
                {originTerminalText && ','}
              </Text>{' '}
              <Text
                data-tracking-id="arrival-terminal-text"
                variant="footnoteMedium"
                color="bodyText">
                {destinationTerminalText}
              </Text>{' '}
              <Text data-tracking-id="arrival-gate-text" variant="footnoteMedium" color="bodyText">
                {destinationGateText}
              </Text>
            </AirportWithTerminal>
            <Location onClick={handleDestinationLocationClick}>
              <Icon name="directionsPWA" color="primary" data-tracking-id="origin-direction-icon" />
            </Location>
          </FlightInfo>
        </TimelineBlock>
      </FlexWrapper>
    </Block>
  )
}
