import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CountrySearch } from '@etta/components/country-search'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { DateOfBirthPicker } from '@etta/ui/date-of-birth-picker'
import { Text } from '@etta/ui/text'
import { TextField } from '@etta/ui/text-field'
import { default as CountryCodesForShortcodes } from '@etta/modules/settings/core/constants/country-codes-shortcodes.json'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useKnownTravelerNumber } from '../../use-known-traveler-number'
import {
  ExpirationContainer,
  FormWrapper,
  OneColumn,
  TwoColumns,
} from './known-traveler-number-form-desktop.styled'

const i18nBase = 'TravelerInfo.KnownTravelerNumber.'
const i18nBaseAria = 'Accessibility.Settings'

export const KnownTravelerNumberFormDesktop = observer(function KnownTravelerNumberFormDesktop() {
  const { t } = useTranslation()

  const {
    handleTravelerDocumentFieldChange,
    handleKnownTravelerNumberExpirationDate,
    handlePickerOpen,
    handlePickerClose,
    handleRemoveTravelerDocument,
    handleDocumentFieldFocusClear,
    getDisabledCountries,
    removeToggle,
    pickerTogglers,
    knownTravelerNumberValidator,
    isDocumentTouched,
    oldDocument,
    isDelegatedOrImpersonated,
  } = useKnownTravelerNumber()

  const { handleKeyDown: handleKnownTravelerNumberExpirationKeyDown } = useA11yKeydown(() =>
    handlePickerOpen('knownTravelerNumberExpiration'),
  )

  const {
    values: { number, countryCode, expirationDate },
    errors,
  } = knownTravelerNumberValidator

  const isEditForm = !!oldDocument

  return (
    <>
      <Block marginBottom={24}>
        <Text variant="subHeadMedium" color="bodyText">
          {`${t(i18nBase + 'SubHeading')}`}
        </Text>
      </Block>
      <FormWrapper>
        <OneColumn>
          <TextField
            type="text"
            label={t(`${i18nBase}Number`)}
            value={number || ''}
            helperText={errors.number}
            helperType="error"
            onChange={(value) => handleTravelerDocumentFieldChange('number', value)}
            isBlurred={isEditForm && !isDocumentTouched && isDelegatedOrImpersonated}
            onFocus={handleDocumentFieldFocusClear}
          />
        </OneColumn>
        <TwoColumns>
          <ExpirationContainer onKeyDown={handleKnownTravelerNumberExpirationKeyDown} tabIndex={0}>
            <TextField
              label={t(`${i18nBase}ExpirationDate`)}
              value={expirationDate || ''}
              helperText={errors.expirationDate}
              helperType="error"
              onClick={() => handlePickerOpen('knownTravelerNumberExpiration')}
            />
            <DateOfBirthPicker
              onChange={handleKnownTravelerNumberExpirationDate}
              isOpen={pickerTogglers.knownTravelerNumberExpiration.isOpen}
              onClose={() => handlePickerClose('knownTravelerNumberExpiration')}
              withClickOutsideHandle
              areFutureYears
              value={expirationDate ? new Date(expirationDate) : undefined}
            />
          </ExpirationContainer>
          <CountrySearch
            customOptions={CountryCodesForShortcodes}
            disabledOptions={getDisabledCountries()}
            value={countryCode || ''}
            helperText={errors.countryCode}
            helperType="error"
            customLabel={t(`${i18nBase}Country`)}
            customPlaceholder={t(`${i18nBase}Country`)}
            setValue={(value) => handleTravelerDocumentFieldChange('countryCode', value)}
          />
        </TwoColumns>
      </FormWrapper>
      {isEditForm && (
        <Block marginTop={24}>
          <Button
            variant="link"
            onClick={removeToggle.handleOpen}
            paddings={false}
            aria-label={t(`${i18nBaseAria}.Button`, {
              label: t(`${i18nBase}RemoveNumber`),
            })}>
            <Text variant="linkSmall" color="error">
              {t(i18nBase + 'RemoveNumber')}
            </Text>
          </Button>
        </Block>
      )}
      <ConfirmationDialog
        title={t(i18nBase + 'RemoveTitle')}
        content={t(i18nBase + 'RemoveDescription')}
        isOpen={removeToggle.isOpen}
        onConfirm={handleRemoveTravelerDocument}
        onDecline={removeToggle.handleClose}
        confirmButtonText={t(i18nBase + 'Remove')}
        declineButtonText={t(i18nBase + 'Cancel')}
      />
    </>
  )
})
