import { Store } from '@etta/di'
import i18n from '@fiji/i18n'
import type { GuestTravelerPassportValueObject } from '@etta/modules/booking/core/value-objects/guest-traveler-passport.value-object'
import type { FieldErrorValueObject } from '../../../core/value-objects/field-error.value-object'
import { FieldTypeEnum, ErrorTypeEnum } from '../../../core/enums'

export type PassportInput = {
  number: string
  country: string
  issuingCountry: string
  expirationDate?: Date
  issuingDate?: Date
}

type InputErrors = {
  number?: string
  country?: string
  issuingCountry?: string
  expirationDate?: string
  issuingDate?: string
}

const fieldToPropertyMap: Record<FieldTypeEnum, keyof PassportInput> = {
  [FieldTypeEnum.Number]: 'number',
  [FieldTypeEnum.Country]: 'country',
  [FieldTypeEnum.IssuingCountry]: 'issuingCountry',
  [FieldTypeEnum.IssueDate]: 'issuingDate',
  [FieldTypeEnum.ExpirationDate]: 'expirationDate',
}

const defaultPassportInput = {
  number: '',
  country: '',
  issuingCountry: '',
  expirationDate: undefined,
  issuingDate: undefined,
}

const i18nBase = 'Passport.'
const errorsBase = `${i18nBase}Errors.`

const mapErrorMessage = (field: FieldErrorValueObject) => {
  switch (field.errorType) {
    case ErrorTypeEnum.Empty:
      return i18n.t(`${i18nBase}Required`, { field: i18n.t(`${errorsBase}${field.type}`) })
    case ErrorTypeEnum.InvalidDate:
      return i18n.t(`${i18nBase}InvalidDate`, { field: i18n.t(`${errorsBase}${field.type}`) })
    default:
      return i18n.t(`${i18nBase}UnknownError`)
  }
}

const mapInputErrors = (fields: FieldErrorValueObject[]) => {
  const errors: InputErrors = {}

  fields.forEach((field) => {
    const propertyName = fieldToPropertyMap[field.type]
    errors[propertyName] = mapErrorMessage(field)
  })

  return errors
}

@Store()
export class PassportStore {
  passportInput: PassportInput = defaultPassportInput

  guestPassportInput: GuestTravelerPassportValueObject | null = null

  inputErrors: InputErrors = {}

  selectedPassportId: number | null = null

  selectedPassportCountry: string | null = null

  isPassportLoading = false

  isPassportError = false

  setGuestPassportInput(guestPassportInput: GuestTravelerPassportValueObject | null) {
    this.guestPassportInput = guestPassportInput
  }

  setInputErrors(fieldErrors: FieldErrorValueObject[]) {
    this.inputErrors = mapInputErrors(fieldErrors)
  }

  setPassportInput(passportInput: PassportInput) {
    this.passportInput = passportInput
  }

  setPassportInputValue<T extends keyof PassportInput>(field: T, value: PassportInput[T]) {
    const newPassportInput = { ...this.passportInput, [field]: value }
    this.passportInput = newPassportInput
  }

  updateSelectedPassportId(id: number | null) {
    this.selectedPassportId = id
  }

  updateSelectedPassportCountry(country: string | null) {
    this.selectedPassportCountry = country
  }

  setIsPassportLoading(isLoading: boolean) {
    this.isPassportLoading = isLoading
  }

  setIsPassportError(isError: boolean) {
    this.isPassportError = isError
  }

  dropState() {
    this.isPassportLoading = false
    this.isPassportError = false
    this.guestPassportInput = null
  }
}
