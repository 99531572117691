import { useMemo } from 'react'
import type { Props } from '../types'
import { SpecialRequestOptionsLayout } from './layout'

export function SpecialRequestOptions({ values, options, onChange }: Props) {
  const sortedRequests = useMemo(
    () =>
      options
        .filter((option) => option.value !== 'None')
        .sort((a, b) => {
          return a.label.localeCompare(b.label)
        }),
    [options],
  )

  return (
    <SpecialRequestOptionsLayout options={sortedRequests} onChange={onChange} values={values} />
  )
}
