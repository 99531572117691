import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { CarRentalErrors } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import { getRestrictedErrorData } from '@fiji/utils/get-restricted-error-message'
import type {
  CarRentalClassType,
  CarRentalFuelType,
  CarRentalTransmissionType,
} from '@fiji/graphql/hooks'
import type { CarRentalsValueObject } from '../../../core/value-objects'
import type { CarRentalSearchResults, CarRentalsArgs } from '../../types'
import { CarRentalSearchServiceDataProvider } from '../../data-providers'
import { CarRentalMapper } from '../../mappers'

@Adapter()
export class CarRentalSearchServiceAdapter {
  constructor(
    @Inject()
    private carRentalSearchServiceDataProvider: CarRentalSearchServiceDataProvider,
  ) {}

  async createCarRentalSearch({
    input,
  }: CarRentalsArgs): CarRentalSearchResults<{
    carRentals: CarRentalsValueObject | null
    searchId?: string | null
  }> {
    try {
      const {
        data,
        errors,
      } = await this.carRentalSearchServiceDataProvider.createCarRentalSearchWithAbortController({
        ...input,
        classTypes: input.classTypes as CarRentalClassType[],
        fuelTypes: input.fuelTypes as CarRentalFuelType[],
        transmissionTypes: input.transmissionTypes as CarRentalTransmissionType[],
      })

      if (errors) {
        const { isRestrictedCountryError, restrictedErrorMessage } = getRestrictedErrorData(
          errors || [],
          'restrictionMessage',
        )

        if (isRestrictedCountryError) {
          return Result.Err(
            new CarRentalErrors.RestrictedCountryError({ message: restrictedErrorMessage || '' }),
          )
        }
      }

      if (errors) {
        return Result.Err(new CarRentalErrors.CarRentalSearchResponse(errors))
      }

      const carRentals = data?.createCarRentalSearch
        ? CarRentalMapper.toCarRentalsValueObject(data.createCarRentalSearch)
        : null
      const searchId = data?.createCarRentalSearch?.searchId

      return Result.Ok({ carRentals, searchId })
    } catch (e) {
      return Result.Err(new CarRentalErrors.UnexpectedErrorCarRentalSearch(e))
    }
  }
}
