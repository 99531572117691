import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useCancelTripModal } from './use-cancel-trip-modal'
import type { Skin } from './types'
import { CancelTripModalSidebar, CancelTripModalFullScreen } from './variants'

type Props = {
  onClose: () => void
  tripTitle?: string
  skin?: Skin
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'

export const CancelTripModalContent = observer(function CancelTripModalContent({
  onClose,
  tripTitle,
  skin = 'full-screen',
}: Props) {
  const {
    postBookingCancellationStore: { cancellationTypeTitle },
  } = usePostBookingContext()
  const { t } = useTranslation()
  const statement = `${t(`${i18nBase}.Title`)} ${cancellationTypeTitle}`

  const { titleDate, isChecked, setIsChecked } = useCancelTripModal()

  if (skin === 'sidebar') {
    return (
      <CancelTripModalSidebar
        isChecked={isChecked}
        titleDate={titleDate}
        tripTitle={tripTitle}
        statement={statement}
        onChange={setIsChecked}
        onClose={onClose}
      />
    )
  }

  return (
    <CancelTripModalFullScreen
      isChecked={isChecked}
      titleDate={titleDate}
      statement={statement}
      onChange={setIsChecked}
      onClose={onClose}
    />
  )
})
