import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'
import type { ButtonProps } from './button-props'
import { getStylesByButtonSize } from './get-styles-by-size'
import { getStylesByButtonVariant } from './get-styles-by-variant'

export const ButtonWrapper = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid transparent;
  min-width: ${({ minWidth = 6.4 }) => `${minWidth}rem`};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      flex: 1;
    `}
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
  }
  svg {
    path {
      fill: currentColor;
    }
  }
  ${({ size }) => getStylesByButtonSize(size)};
  ${({ variant }) => getStylesByButtonVariant(variant)};
  ${({ paddings }) =>
    paddings
      ? css`
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        `
      : css`
          padding: 0;
        `};
  ${KeyboardNavigationStyle}
`
export const TextWrapper = styled.div<Required<Pick<ButtonProps, 'textOverflow'>>>`
  min-width: 0;
  flex: 1;

  ${({ textOverflow }) => {
    switch (textOverflow) {
      case 'ellipsis':
        return css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      case false:
      default:
    }
  }};
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LeftIconWrapper = styled(IconWrapper)`
  margin-right: 1rem;
`
export const RightIconWrapper = styled(IconWrapper)`
  margin-left: 1rem;
`
