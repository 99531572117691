import { SearchResultsHeaderCompact } from '@etta/components/search-results-header/header-compact/search-results-header-compact'
import { useCarRentalSearchResultsContextSelector } from '../../car-rental-search-results-provider'

export function CarRentalHeaderMobile() {
  const state = useCarRentalSearchResultsContextSelector((state) => {
    const {
      onBack,
      handleGoToSearch,
      mobileLocationsLabel,
      mobileDatesLabel,
      isFiltersUsed,
      isErrorOrLoading,
      filtersToggle,
    } = state
    return {
      filtersToggle,
      onBack,
      handleGoToSearch,
      mobileLocationsLabel,
      mobileDatesLabel,
      isFiltersUsed,
      isErrorOrLoading,
    }
  })

  return (
    <SearchResultsHeaderCompact
      isDisabled={state.isErrorOrLoading}
      onBackClick={state.onBack}
      onLabelClick={state.handleGoToSearch}
      onFilterClick={state.filtersToggle.handleOpen}
      locationsLabel={state.mobileLocationsLabel}
      datesLabel={state.mobileDatesLabel}
      isFilterActive={state.isFiltersUsed}
    />
  )
}
