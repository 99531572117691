import { Icon } from '@etta/ui/icon'
import { IconRow, Item } from '../air-badges-components'
import type { Props } from '../types'

export function Badge({
  icon,
  title,
  skin,
  color,
  tabIndex = -1,
  'data-tracking-id': dataTrackingId,
}: Props) {
  return (
    <Item tabIndex={tabIndex} aria-label={title} skin={skin} data-tracking-id={dataTrackingId}>
      <IconRow skin={skin}>
        <Icon name={icon} size="small" color={color} />
      </IconRow>
      {title}
    </Item>
  )
}
