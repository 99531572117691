import { Action, Inject } from '@etta/di'
import { SeatMapPaginationStore } from '../store/seat-map-pagination.store'
import { SeatMapPaginationService } from '../services/seat-map-pagination.service'

@Action()
export class SeatMapPaginationActions {
  constructor(
    @Inject() private readonly seatMapPaginationStore: SeatMapPaginationStore,
    @Inject() private readonly seatMapPaginationService: SeatMapPaginationService,
  ) {}

  async moveToNextSeatMap() {
    await this.seatMapPaginationService.moveToNextSeatMap()
  }

  async handleSelectFlightCard({
    legIndex,
    segmentIndex,
  }: {
    legIndex: number
    segmentIndex: number
  }) {
    await this.seatMapPaginationService.handleSelectFlightCard({ legIndex, segmentIndex })
  }

  handleDropStore() {
    this.seatMapPaginationStore.dropStore()
  }

  handleDropIndexes() {
    this.seatMapPaginationStore.dropIndexes()
  }
}
