import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { CarRentalMap } from '../../../car-rental-map'
import {
  ContentContainer,
  CarRentalLocationsContentContainer,
  CarRentalLocationsMapContainer,
} from './car-rental-desktop-office-selector-styled'
import { CarRentalDesktopContent } from './car-rental-desktop-content'

export function CarRentalOfficeDesktopSelector(props: LayoutProps) {
  return (
    <>
      <ScreenDesktop.Header>
        <DesktopNavigation />
      </ScreenDesktop.Header>

      <CarRentalLocationsContentContainer>
        <ContentContainer>
          <CarRentalDesktopContent {...props} />
        </ContentContainer>
        <CarRentalLocationsMapContainer>
          <CarRentalMap {...props} />
        </CarRentalLocationsMapContainer>
      </CarRentalLocationsContentContainer>
    </>
  )
}
