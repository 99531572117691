import { useCallback } from 'react'
import type { GroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { usePostBookingContext } from '@etta/modules/post-booking'

type Params = {
  segment: GroupedSegmentValueObject
}

export function useCancelSegment({ segment }: Params) {
  const {
    postBookingCancellationActions: { onCancelSegment },
    postBookingTripDetailsActions: { handleUpdateSelectedDetails },
  } = usePostBookingContext()

  const onCancel = useCallback(() => {
    handleUpdateSelectedDetails(segment)
    onCancelSegment(segment)
  }, [segment, onCancelSegment, handleUpdateSelectedDetails])

  return {
    onCancel,
  }
}
