import { TripReviewAddButton } from './trip-review-add-button'
import { ButtonsContainer } from './trip-review-add-buttons-row-styled'
import type { AddButtonSettings } from './types'

type Props = {
  settings: AddButtonSettings[]
  areTooManySegments?: boolean
  openDialog?: () => void
}

export function TripReviewAddButtonsRow({
  settings,
  areTooManySegments,
  openDialog = () => {},
}: Props) {
  const isSingleButton = settings.filter((setting) => !!setting.isVisible).length === 1
  return (
    <ButtonsContainer>
      {settings.map((setting: AddButtonSettings) => {
        if (!setting.isVisible) {
          return null
        }

        const onClick = areTooManySegments ? openDialog : setting.onClick

        return (
          <TripReviewAddButton
            isSingle={isSingleButton}
            key={setting.type}
            isDisabled={setting.isDisabled}
            type={setting.type}
            onClick={onClick}
          />
        )
      })}
    </ButtonsContainer>
  )
}
