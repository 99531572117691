import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { SegmentState, SegmentType } from '@fiji/graphql/types'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { CarRentalDetailsEcoCheck } from '@etta/components/car-rental-details-eco-check/car-rental-details-eco-check'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Block } from '@etta/ui/block'
import { CarRentalServiceFee } from '@etta/components/car-rental-service-fee'
import { CarRentalDetailsHeader } from '../../car-rental-details-header'
import { CarRentalDetailsPrice } from '../../car-rental-details-price'
import { CarRentalVendorInformation } from '../../car-rental-vendor-information'
import { CarRentalLocation } from '../../car-rental-location'
import type { CarRentalDetailsModalProps } from '../types'
import {
  CarImageContainer,
  CarImage,
  CloseButtonTop,
  Container,
  Section,
} from './car-rental-details-modal-mobile-styled'

export function CarRentalDetailsModalMobile({
  onClose,
  carRental,
  isTripExist,
  scrollContainerRef,
  headerRef,
  carRentalTitle,
  imageUrl,
  isOutOfPolicy,
  amenities,
  locations,
  vendorInformation,
  onDetailsClick,
  onChangeClick,
  onCancelClick,
  carRentalEmission,
}: CarRentalDetailsModalProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()

  return (
    <Modal.Body ref={scrollContainerRef}>
      <Modal.Header isMobile backgroundColor="transparent">
        <Header
          data-tracking-id="segment-details-modal-header"
          headerRef={headerRef}
          withBorderBottom
          backgroundColor="background"
          layout="aligned"
          animation="slide-top"
          leftSlot={<Header.CloseButton onClick={onClose} />}>
          <Header.Title title={carRentalTitle} align="left" />
        </Header>
      </Modal.Header>
      <Container>
        <CarImageContainer>
          <CarImage carImageUrl={carRental.carDetails.imageUrl || ''} />
          <CloseButtonTop
            icon="closePWA"
            color="mainText2"
            onClick={onClose}
            aria-label={t('Accessibility.Header.Close')}
          />
        </CarImageContainer>
        <Section>
          <CarRentalDetailsHeader
            imageUrl={imageUrl}
            carRentalTitle={carRentalTitle}
            isOutOfPolicy={isOutOfPolicy}
            amenities={amenities}
          />
        </Section>
        <Section>
          <CarRentalServiceFee vendorCode={carRental.vendorDetails.code} />
        </Section>
        <Section>
          <CarRentalLocation locations={locations} />
        </Section>
        {featureFlags.isSustainabilityEnabled && (
          <Section>
            <CarRentalDetailsEcoCheck {...carRentalEmission} />
          </Section>
        )}

        <Section>
          <CarRentalVendorInformation
            vendorInformation={vendorInformation}
            safetyCheck={carRental.safetyCheck}
          />
        </Section>
        <Section>
          <CarRentalDetailsPrice
            totalCostRate={carRental.totalCostRate}
            baseRate={featureFlags.isServiceFeeEnabled ? carRental.netRate : carRental.baseRate}
            feesAndTaxesRate={carRental.feesAndTaxesRate}
            serviceFeeRate={featureFlags.isServiceFeeEnabled ? carRental.serviceFeeRate : undefined}
          />
        </Section>
        <Block marginBottom={16}>
          <ActionButtons
            type={SegmentType.CarRental}
            isCancel={!isTripExist || carRental.segmentState === SegmentState.New}
            onDetailsClick={onDetailsClick}
            onChangeClick={onChangeClick}
            onCancelClick={onCancelClick}
          />
        </Block>
      </Container>
    </Modal.Body>
  )
}
