import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Alert, Message } from '../../types'
import { Container, Wrapper, IconContainer } from './no-messages-section-styled'

type Props = {
  alerts: Alert[]
  messages: Message[]
}

const i18Base = 'DynamicSiteMessage'

export function NoMessagesSection({ alerts, messages }: Props) {
  const { t } = useTranslation()

  if (alerts.length || messages.length) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <IconContainer>
          <Icon name="messagesPWA" color="bodyText" size="large" />
        </IconContainer>
      </Wrapper>
      <Block marginTop={10}>
        <Text variant="headline" color="mainText">
          {t(`${i18Base}.NoMoreMessages`)}
        </Text>
      </Block>
    </Container>
  )
}
