import { makeAutoObservable, toJS } from 'mobx'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { CheckoutInfoResult } from '../../../core/value-objects/checkout-info.value-object'
import type { MemberShipPersistValueObject } from '../../../core/value-objects/checkout-info/memberships-persist.value-object'

export class CheckoutInfoMemberships {
  private memberships: CheckoutInfoResult['memberships'] = null

  private membershipsPersist = new ItineraryStorage<MemberShipPersistValueObject>(
    'membershipSelection',
    {
      carRental: null,
      air: null,
      hotel: null,
    },
  )

  constructor() {
    makeAutoObservable(this, { isPersistEmpty: false })
  }

  get savedHotelMemberShip() {
    return this.memberships?.hotel?.data
  }

  get savedAirMemberShip() {
    return this.memberships?.air?.data
  }

  get savedCarRentalMemberShip() {
    return this.memberships?.carRental?.data
  }

  get memberShipData() {
    return this.membershipsPersist.values
  }

  get airMembershipOptions() {
    return this.memberships?.air?.options || null
  }

  get airMembershipValue() {
    return this.membershipsPersist.values.air
  }

  get hotelMembershipOptions() {
    return this.memberships?.hotel?.options || null
  }

  get hotelMembershipValue() {
    return this.membershipsPersist.values.hotel
  }

  get carMembershipOptions() {
    return this.memberships?.carRental?.options || null
  }

  get carMembershipValue() {
    return this.membershipsPersist.values.carRental
  }

  isPersistEmpty() {
    return !Object.values(toJS(this.membershipsPersist.values)).some((value) => value !== null)
  }

  isMembershipEmpty<T extends keyof MemberShipPersistValueObject>(key: T) {
    return this.membershipsPersist.values[key] === null
  }

  dropMembershipDataByKey<T extends keyof MemberShipPersistValueObject>(key: T) {
    this.membershipsPersist.set({ ...this.membershipsPersist.values, [key]: null })
  }

  dropMembershipData() {
    this.memberships = null
  }

  setMembershipData(memberships: CheckoutInfoResult['memberships']) {
    this.memberships = memberships
  }

  appendMembershipValue<T extends keyof MemberShipPersistValueObject>(
    key: T,
    membership: MemberShipPersistValueObject[T],
  ) {
    this.membershipsPersist.set({
      ...this.membershipsPersist.values,
      [key]: membership,
    })
  }
}
