import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'

export const PaginationButton = styled(Button)<{ isMobile?: boolean }>`
  ${(props) =>
    props.isMobile
      ? css`
          margin-bottom: 12px;
          padding: 10px;
          background: ${(props) => props.theme.colors.white};
          border-radius: 0.8rem;
        `
      : css`
          margin-bottom: 16px;
        `}
`
