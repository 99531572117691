import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { IconSizes } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Skeleton } from '@etta/ui/skeleton'
import type { ColorPalette } from '@fiji/types'
import type { TextVariant } from '@etta/ui/text/types'
import { TextWrapper } from './delegate-name-styled'
import { useDelegateName } from './use-delegate-name'

type Props = {
  isWithBooking?: boolean
  isWithDot?: boolean
  color?: keyof ColorPalette
  name?: string
  isLoading?: boolean
  isDesktopMode?: boolean
  variant?: TextVariant
  withSmallIcon?: boolean
}

export function DelegateName({
  isWithBooking,
  isWithDot,
  color = 'mainText',
  name,
  isLoading,
  isDesktopMode,
  variant,
  withSmallIcon = false,
}: Props) {
  const { loadedName, textVariant } = useDelegateName(isWithBooking, isDesktopMode, name)

  if (isLoading) {
    return (
      <Skeleton width="128px" height="14px">
        <rect width="128px" height="14px" />
      </Skeleton>
    )
  }

  let iconSize: IconSizes = name ? 'normal' : 'small'
  if (withSmallIcon) {
    iconSize = 'small'
  }

  return (
    <>
      <Icon name="peopleNewFieldPWA" size={iconSize} color={color} />
      <TextWrapper>
        <Text
          aria-label={name ? name : loadedName}
          variant={variant || textVariant}
          color={color}
          noWrap
          textOverflow="ellipsis">
          {name ? name : loadedName}
        </Text>
      </TextWrapper>

      {isWithDot && <Block marginHorizontal={5}> · </Block>}
    </>
  )
}
