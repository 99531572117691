import styled, { css } from 'styled-components'

export const TRANSITION_NAME = 'SLIDE'
export const TRANSITION_DURATION = 600
export const TRANSITION_FUNCTION = 'cubic-bezier(0.65, 0.05, 0.36, 1)'

export const slideStyle = css`
  transition: bottom ${TRANSITION_DURATION}ms ${TRANSITION_FUNCTION};

  &.${TRANSITION_NAME}-enter,
    &.${TRANSITION_NAME}-appear,
    &.${TRANSITION_NAME}-exit-done,
    &.${TRANSITION_NAME}-exit,
    &.${TRANSITION_NAME}-exit-active {
    bottom: -100%;
  }

  &.${TRANSITION_NAME}-appear-done,
    &.${TRANSITION_NAME}-enter-done,
    &.${TRANSITION_NAME}-appear-active,
    &.${TRANSITION_NAME}-enter-active {
    bottom: 0;
  }
`

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${slideStyle}
`
