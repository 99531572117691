import type { Assets } from './types'

const SCRIPT_ID = 'insert-chrome-script'

export function insertChrome(assets: Assets) {
  const chromeScript = assets!
    // @ts-ignore
    .find(
      (asset: any) =>
        asset.type === 'script' &&
        asset.props &&
        asset.props.src &&
        asset.props.src.includes('chrome3.js'),
    )

  if (!chromeScript) {
    return
  }

  const isInserted = !!document.getElementById(SCRIPT_ID)
  if (isInserted) {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.id = SCRIPT_ID
  scriptElement.async = true
  scriptElement.src = chromeScript.props.src

  scriptElement.onload = () => {
    document.dispatchEvent(
      new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      }),
    )
    window.dispatchEvent(
      new Event('load', {
        bubbles: true,
        cancelable: true,
      }),
    )
  }
  document.getElementsByTagName('head')[0].appendChild(scriptElement)
}
