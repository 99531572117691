import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { useSegmentDetailsEcoCheckModal } from '@fiji/hooks/sustainability/use-segment-details-eco-check-modal'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import type { LayoutProps } from '../../types'
import {
  FlightDetailsEcoCheckHeader,
  FlightDetailsEcoCheckModalImage,
  FlightDetailsEcoCheckModalDesktopLowerBody,
  FlightDetailsEcoCheckModalDesktopUpperBody,
} from './components'
import { EquivalencesContainer } from './flight-details-eco-check-modal-desktop-styled'

export function FlightDetailsEcoCheckModalDesktop({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: LayoutProps) {
  const {
    kgOfEmissions,
    averageEmissionsKg,
    deviationLevel,
    percentDeviation,
  } = useSegmentDetailsEcoCheckModal({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  return (
    <Modal
      position="right"
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<FlightDetailsEcoCheckHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <FlightDetailsEcoCheckModalDesktopUpperBody
          averageEmissionsKg={averageEmissionsKg}
          percentDeviation={percentDeviation}
          deviationLevel={deviationLevel}
          kgOfEmissions={kgOfEmissions}
        />
        <FlightDetailsEcoCheckModalImage deviationLevel={deviationLevel} />
        <EquivalencesContainer>
          <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={kgOfEmissions} />
        </EquivalencesContainer>
        <FlightDetailsEcoCheckModalDesktopLowerBody deviationLevel={deviationLevel} />
      </Modal.Body>
    </Modal>
  )
}
