import styled, { css } from 'styled-components'
import { getFieldStyles } from '@etta/ui/block/get-field-styles'
import { withStyledText } from '@fiji/style'
import type { StyledTextVariant } from '@fiji/enums'
import type { Indent, Position } from './types'

type Props = {
  isFullWidth?: boolean
  position?: Position
  paddingVertical?: number
  paddingHorizontal?: number
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  paddingBottom?: number

  marginVertical?: Indent
  marginHorizontal?: Indent
  marginLeft?: Indent
  marginRight?: Indent
  marginTop?: Indent
  marginBottom?: Indent

  textVariant?: StyledTextVariant

  isFlexBlock?: boolean
  isSpaceBetween?: boolean
  flexGap?: number
  flexOverflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' | 'initial' | 'inherit'

  isFlexOverflowToNextLine?: boolean

  alignItems?: 'flex-start' | 'flex-end' | 'center'

  color?: string
}

export const Block = styled.div<Props>`
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  ${(props) => (props.isFullWidth ? 'width: 100%;' : '')}
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) => {
    return getFieldStyles({
      field: 'padding',
      vertical: props.paddingVertical,
      horizontal: props.paddingHorizontal,
      top: props.paddingTop,
      left: props.paddingLeft,
      right: props.paddingRight,
      bottom: props.paddingBottom,
    })
  }};

  ${(props) => {
    const top = props.marginVertical || props.marginTop
    const left = props.marginHorizontal || props.marginLeft
    const bottom = props.marginVertical || props.marginBottom
    const right = props.marginHorizontal || props.marginRight

    return getFieldStyles({
      field: 'margin',
      top,
      left,
      right,
      bottom,
    })
  }};

  ${withStyledText};

  ${({
    isFlexBlock,
    flexGap,
    isSpaceBetween,
    alignItems,
    flexOverflow,
    isFlexOverflowToNextLine,
  }) =>
    (isFlexBlock || isSpaceBetween) &&
    css`
      display: flex;
      align-items: ${alignItems ? alignItems : 'center'};
      gap: ${flexGap && flexGap + 'px'};
      ${isSpaceBetween ? 'justify-content: space-between;' : ''}
      ${flexOverflow ? 'overflow: ' + flexOverflow + ';' : ''}
      ${isFlexOverflowToNextLine ? 'flex-direction: row; flex-wrap:wrap' : ''}
    `}
`
