import type { PartnerCoBrandingConfigurationQuery } from '@fiji/graphql/hooks'
import type { PartnerCoBrandingConfigurations } from '@etta/modules/partner-cobranding-configuration/core/types'

export class PartnerCoBrandingConfigurationsMapper {
  static toPartnerCoBrandingConfigurations(
    partnerCoBrandingConfiguration: PartnerCoBrandingConfigurationQuery['partnerCoBrandingConfiguration'],
  ): PartnerCoBrandingConfigurations {
    return {
      partnerLogoUrl: partnerCoBrandingConfiguration.partnerLogoUrl || undefined,
      customPartnerLogoUrl: partnerCoBrandingConfiguration.customPartnerLogoUrl || undefined,
      customCompanyLogoUrl: partnerCoBrandingConfiguration.customCompanyLogoUrl || undefined,
      customMobilePartnerLogoUrl:
        partnerCoBrandingConfiguration.customMobilePartnerLogoUrl || undefined,
      customMobileCompanyLogoUrl:
        partnerCoBrandingConfiguration.customMobileCompanyLogoUrl || undefined,
      termsOfUseLabel: partnerCoBrandingConfiguration.termsOfUseLabel || undefined,
      termsOfUseLinkEnabled: partnerCoBrandingConfiguration.termsOfUseLinkEnabled,
      termsOfUseLinkUrl: partnerCoBrandingConfiguration.termsOfUseLinkUrl || undefined,
      termsOfUse: partnerCoBrandingConfiguration.termsOfUse || undefined,
      copyright: partnerCoBrandingConfiguration.copyright,
      privacyPolicyEnabled: partnerCoBrandingConfiguration.privacyPolicyEnabled,
      privacyPolicyLinkEnabled: partnerCoBrandingConfiguration.privacyPolicyLinkEnabled,
      privacyPolicyText: partnerCoBrandingConfiguration.privacyPolicyText || undefined,
      privacyPolicyLabel: partnerCoBrandingConfiguration.privacyPolicyLabel || undefined,
      privacyPolicyLinkUrl: partnerCoBrandingConfiguration.privacyPolicyLinkUrl || undefined,
      customFooterEnabled: partnerCoBrandingConfiguration.customFooterEnabled,
      customFooter: partnerCoBrandingConfiguration.customFooter || undefined,
      supportTextEnabled: partnerCoBrandingConfiguration.supportTextEnabled,
      supportText: partnerCoBrandingConfiguration.supportText || undefined,
      faqLinkEnabled: partnerCoBrandingConfiguration.faqLinkEnabled,
      faqLabel: partnerCoBrandingConfiguration.faqLabel || undefined,
      faqLinkUrl: partnerCoBrandingConfiguration.faqLinkUrl || undefined,
      partnerCoBrandingEnabled: partnerCoBrandingConfiguration.partnerCoBrandingEnabled,
      theme: partnerCoBrandingConfiguration.theme,
    }
  }
}
