import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { RailSearchForm } from '@etta/components/rail-search-form'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'

type Props = {
  onClose: () => void
}

export function EditMode({ onClose }: Props) {
  const { railSearchFormActions } = useRailSearchFormContext()
  const handleClose = () => {
    onClose()
    railSearchFormActions.dropEditCache()
  }

  return (
    <SearchResultsHeaderFull.EditMode onClose={handleClose}>
      <RailSearchForm onSubmit={onClose} />
    </SearchResultsHeaderFull.EditMode>
  )
}
