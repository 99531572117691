import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { useDistanceKey } from '../use-distance-key'
import { DistanceText } from './hotel-distance-title-styled'

type Props = {
  distance?: number
}

export function HotelDistanceTitle({ distance }: Props) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.BaseSearch'

  const { distanceKey } = useDistanceKey()

  return (
    <DistanceText>
      <Block marginRight={5}>
        <Text variant="body" color="mainText">
          {t(i18Base + '.Distance.ShowHotels')}
        </Text>
      </Block>
      <Text variant="subHeadStrong" color="mainText">
        {t(i18Base + distanceKey, { count: distance })}
      </Text>
    </DistanceText>
  )
}
