import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { Text } from '@etta/ui/text'

type Props = {
  date: string
}
export const TripCancellationDateDesktop = ({ date }: Props) => {
  const { t } = useTranslation()
  const label = t('PostBooking.TripDetails.CanceledHeader', {
    date: dateFormat(new Date(deleteTimezone(date)), 'eeee, MMM d'),
  })

  return (
    <Text variant="subHeadStrong" color="error">
      {label}
    </Text>
  )
}
