import { Action, Inject } from '@etta/di'
import { CheckoutInfoStore } from '@etta/modules/booking/interface/checkout-info/checkout-info.store'
import { ReviewTripFlightService } from '../services/review-trip-flight.service'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import type {
  AddFlightArgs,
  RemoveFlightArgs,
  ChangeFlightArgs,
  ChangeFlightSeatsAssignmentArgs,
  FlightSetSeat,
  FlightUnselectSeat,
} from '../types'
import { OutOfPolicyStore } from '../out-of-policy/stores'

@Action()
export class TripFlightActions {
  constructor(
    @Inject() private readonly reviewTripFlightService: ReviewTripFlightService,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject() private readonly outOfPolicyStore: OutOfPolicyStore,
  ) {}
  handleAddFlight(args: AddFlightArgs) {
    this.outOfPolicyStore.dropAirOopState()
    return this.reviewTripFlightService.addFlight(args)
  }

  handleRemoveFlight(args: RemoveFlightArgs) {
    this.checkoutInfoStore.memberships.dropMembershipDataByKey('air')
    return this.reviewTripFlightService.removeFlight(args)
  }

  handleChangeFlight(args: ChangeFlightArgs) {
    this.checkoutInfoStore.specialRequests.dropSpecialRequestDataByKey('air')
    this.outOfPolicyStore.dropAirOopState()
    return this.reviewTripFlightService.changeFlight(args)
  }

  handleChangeFlightSeatsAssignment(args: ChangeFlightSeatsAssignmentArgs) {
    return this.reviewTripFlightService.changeFlightSeatsAssignment(args)
  }

  handleFlightSetSeat(args: FlightSetSeat) {
    this.reviewTripStore.setSeatFlightToTrip(args)
  }

  handleFlightRemoveSeat(args: FlightUnselectSeat) {
    this.reviewTripStore.removeSeatFlightFromTrip(args)
  }
}
