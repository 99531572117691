import type {
  SwipingTimePickerData,
  SwipingTimePickerBars,
  SwipingTimePickerUnits,
  SwipingTimePickerState,
} from '../types'

type Props = {
  height: number
  types: [SwipingTimePickerUnits, SwipingTimePickerUnits, SwipingTimePickerUnits]
  values: [number, number, number]
  ranges: [number[], number[], number[]]
  swipes: [number, number, number]
  flips: [SwipingTimePickerBars, SwipingTimePickerBars, SwipingTimePickerBars | null]
  tops: [SwipingTimePickerBars, SwipingTimePickerBars, SwipingTimePickerBars | null]
  unitGroup: [number, number, number]
  labels?: [string, string, string]
}
export function asembleBarValues({
  height,
  types,
  values,
  ranges,
  swipes,
  flips,
  tops,
  unitGroup,
  labels,
}: Props): SwipingTimePickerData {
  const barData: SwipingTimePickerState[] = ['firstBar', 'secondBar', 'thirdBar'].map((_, i) => ({
    type: types[i],
    value: values[i],
    range: ranges[i],
    height: height,
    swipe: swipes[i],
    flip: flips[i],
    top: tops[i],
    unitGroup: unitGroup[i],
    label: labels ? labels[i] : undefined,
  }))
  return { firstBar: barData[0], secondBar: barData[1], thirdBar: barData[2] }
}
