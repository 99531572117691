import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'

type Props = {
  statement: string
  onClose: () => void
}

export function FullScreenHeader({ statement, onClose }: Props) {
  return (
    <Modal.Header>
      <Header leftSlot={<Header.CloseButton onClick={onClose} />} withBorderBottom>
        <Header.Title
          aria-label={statement}
          title={statement}
          align="left"
          data-tracking-id="cancel-trip-title-text"
        />
      </Header>
    </Modal.Header>
  )
}
