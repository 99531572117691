import { useTranslation } from 'react-i18next'
import { Search } from '@etta/ui/search'
import { SectionWrapper } from '@etta/components/air-search-form/flight/layout/desktop/air-search-flight-desktop-styled.new'
import type { CabinClassType } from '@fiji/types'
import { Menu } from '@etta/ui/menu'
import { Radio } from '@etta/ui/radio'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import type { Option } from '@etta/ui/menu/types'
import type { Props } from '../types'

const i18Base = 'TripPlanner.BaseSearch'

export function CabinClassDesktopNew({ pickedCabinClass, onChange }: Props) {
  const { t } = useTranslation()
  const { getCabinClassLabel } = useCabinClassLabels()
  const { cabinClassesOptions } = useSeatmapCabinClasss()

  const options: Option<CabinClassType>[] = cabinClassesOptions.map((cabinClass) => ({
    id: cabinClass,
    name: getCabinClassLabel(cabinClass.id),
    icon: <Radio name="cabin-class" isChecked={false} onChange={() => {}} />,
  }))

  return (
    <SectionWrapper>
      <Search.Group>
        <Menu<CabinClassType>
          fullWidth
          openByClickOnEl={
            <Search.Element
              wrapperAriaLabel={t(i18Base + '.Input.CabinClassSelectedAriaLabel', {
                label: t(`${i18Base}.Input.CabinClass`),
                value: getCabinClassLabel(pickedCabinClass.id),
              })}
              tabIndex={-1}
              labelTitle={t(`${i18Base}.Input.CabinClass`)}
              value={getCabinClassLabel(pickedCabinClass.id)}
            />
          }
          shouldCloseOnClickOutside
          options={options}
          activeOptionId={pickedCabinClass}
          activeOptionIcon={<Radio name="cabin-class" isChecked onChange={() => {}} />}
          onOptionSelect={onChange}
          data-tracking-id="cabin-class"
        />
      </Search.Group>
    </SectionWrapper>
  )
}
