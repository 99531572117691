import type { ReactNode } from 'react'
import { RoomType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'

const i18nBase = 'HotelDetails.RoomTypes.'

type GroupValue = {
  title: string
  icon: ReactNode
}

export const ROOMS_TYPE_ICONS_AND_TITLES_DICT: Record<RoomType, GroupValue> = {
  [RoomType.King]: {
    title: i18nBase + 'King',
    icon: <Icon name="kingRoomPWA" size="medium" />,
  },
  [RoomType.Queen]: {
    title: i18nBase + 'Queen',
    icon: <Icon name="queenRoomPWA" size="medium" />,
  },
  [RoomType.Double]: {
    title: i18nBase + 'Double',
    icon: <Icon name="twinRoomPWA" size="medium" />,
  },
  [RoomType.Twin]: {
    title: i18nBase + 'Twin',
    icon: <Icon name="twinRoomPWA" size="medium" />,
  },
  [RoomType.Suite]: {
    title: i18nBase + 'Suite',
    icon: <Icon name="hotelRoomPWA" size="medium" />,
  },
  [RoomType.Superior]: {
    title: i18nBase + 'Superior',
    icon: <Icon name="hotelRoomPWA" size="medium" />,
  },
  [RoomType.Other]: {
    title: i18nBase + 'Other',
    icon: <Icon name="hotelRoomPWA" size="medium" />,
  },
  [RoomType.Unknown]: {
    title: i18nBase + 'Unknown',
    icon: <Icon name="hotelRoomPWA" size="medium" />,
  },
}
