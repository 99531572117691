import { css } from 'styled-components'
import type { SkeletonAlign } from './types'

export function getAlign(align?: SkeletonAlign) {
  switch (align) {
    default:
      return ''
    case 'absolute-right':
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
      `
  }
}
