import { TripCardSkeleton } from '@etta/components/trip-card-skeleton/trip-card-skeleton'
import { MonthSkeleton } from '../../month-skeleton'
import { MonthWrapper, CardsWrapper } from './trip-list-skeleton-mobile-styles'

export function TripListSkeletonMobile() {
  return (
    <div>
      <MonthWrapper>
        <MonthSkeleton />
      </MonthWrapper>
      <CardsWrapper>
        <TripCardSkeleton />
        <TripCardSkeleton />
        <TripCardSkeleton />
      </CardsWrapper>
    </div>
  )
}
