import { Trans, useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Tooltip } from '@etta/ui/tooltip'
import { RailSegmentEmission } from '@etta/components/rail-segment-emission/rail-segment-emission'
import { ImageContainer } from '@etta/components/image-container'
import {
  Container,
  CardWrapper,
  DurationContainer,
  DurationInfo,
  TotalDurationTimeContainer,
  IconsRow,
} from './selected-departing-rail-styled'
import { useSelectedDepartingRail } from './use-selected-departing-rail'

const i18nBase = 'SearchSegments.'
const i18Base = 'RailDetails'

export const SelectedDepartingRail = function SelectedDepartingRail() {
  const { t } = useTranslation()

  const {
    originTime,
    departure,
    travelTime,
    destinationTime,
    arrival,
    travelDate,
    carriersInfo,
    travelChanges,
    changeStations,
    actualRailEmission,
    service,
    serviceClassName,
    durationLabel,
  } = useSelectedDepartingRail()

  return (
    <Container>
      <Block>
        <Text variant="subHeadStrong" color="mainText">
          {t(i18nBase + 'Title.SelectedDepartingRail')}
        </Text>
      </Block>

      <CardWrapper>
        <Block>
          <DurationContainer>
            <DurationInfo>
              <Text
                aria-label={originTime}
                variant="bodyLarge"
                color="mainText"
                data-tracking-id="departure-time">
                {originTime}
              </Text>
              <Block
                aria-label={t(i18Base + '.AriaLabels.DepartingTrainLabel', {
                  name: departure?.stationName,
                })}
                marginTop={12}>
                <Text
                  variant="footnoteStrong"
                  color="bodyText"
                  data-tracking-id="departure-location">
                  {departure?.stationName}
                </Text>
              </Block>
              <Block
                aria-label={t(i18Base + '.AriaLabels.DepartingOnDate', {
                  date: travelTime,
                })}>
                <Text variant="footnoteMedium" color="bodyText">
                  {travelTime}
                </Text>
              </Block>
            </DurationInfo>
            <Block>
              <Icon name="flightArrowPWA" size="normal" />
            </Block>
            <DurationInfo aria-label={destinationTime}>
              <Text
                aria-label={destinationTime}
                variant="bodyLarge"
                color="mainText"
                data-tracking-id="arrival-time">
                {destinationTime}
              </Text>
              <Block
                aria-label={t(i18Base + '.AriaLabels.ArrivingTrainLabel', {
                  name: arrival?.stationName,
                })}
                marginTop={12}>
                <Text variant="footnoteMedium" color="bodyText" data-tracking-id="arrival-location">
                  {arrival?.stationName}
                </Text>
              </Block>
              <Block
                aria-label={t(i18Base + '.AriaLabels.ArrivingOnDate', {
                  date: travelTime,
                })}>
                <Text variant="footnoteMedium" color="bodyText">
                  {travelDate}
                </Text>
              </Block>
            </DurationInfo>
          </DurationContainer>
        </Block>
        <Block>
          <IconsRow>
            {carriersInfo.map((logoUrl, index) => (
              <ImageContainer key={index} url={logoUrl} errorIconName="railPWA" />
            ))}
          </IconsRow>
          <TotalDurationTimeContainer aria-label={durationLabel}>
            {travelChanges && (
              <Tooltip
                slot={
                  <Text variant="subHeadMedium">
                    {changeStations?.map((station, index) => (
                      <Fragment key={index}>
                        <Trans i18nKey="RailDetails.ChangeAt">{{ station: station }}</Trans>
                        <br />
                      </Fragment>
                    ))}
                  </Text>
                }
                place="bottom">
                <Block>{travelChanges}</Block>
              </Tooltip>
            )}
            {actualRailEmission && actualRailEmission.kgOfActualEmission && (
              <RailSegmentEmission {...actualRailEmission} />
            )}
          </TotalDurationTimeContainer>
        </Block>
        <Block>
          <Block aria-label={service}>
            <Text variant="subHeadMedium" color="bodyText">
              {service}
            </Text>
          </Block>
          <Block aria-label={serviceClassName ?? ''} marginTop={8}>
            <Text variant="subHeadMedium" color="bodyText">
              {serviceClassName}
            </Text>
          </Block>
        </Block>
      </CardWrapper>
    </Container>
  )
}
