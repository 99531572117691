import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'

export const CloseButton = styled(IconButton)``

export const AddFlightHeader = styled.div`
  position: relative;
  padding: 26px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  align-items: center;
`
