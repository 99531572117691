import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { BillingParameter, CreditCard } from '@fiji/graphql/types'
import { TravelVertical } from '@fiji/graphql/types'
import { VIRTUAL_PAY } from '@fiji/constants'
import { usePaymentSummaryContext } from '@etta/modules/booking'
import type { ChosenCardsMap } from '../types'
import { getSingleTimeUseCard } from './get-single-time-use-card'

type Args = {
  flightBilling?: BillingParameter
  carRentalBilling?: BillingParameter
  hotelBilling?: BillingParameter
  railBilling?: BillingParameter
  chosenCards: ChosenCardsMap
  isCarPayNow: boolean
  singleTimeUseCard?: Partial<CreditCard>
}

type GetCardsArgs = {
  savedCards?: CreditCard[]
  singleUseCard?: Partial<CreditCard>
}

function getCards({ savedCards, singleUseCard }: GetCardsArgs) {
  const cards = savedCards ? savedCards : []
  return singleUseCard ? [singleUseCard, ...cards] : cards
}

export function useCreditCards({
  chosenCards,
  flightBilling,
  carRentalBilling,
  hotelBilling,
  isCarPayNow,
  railBilling,
  singleTimeUseCard,
}: Args) {
  const { t } = useTranslation()
  const { paymentSummaryStore } = usePaymentSummaryContext()
  const { paymentStructure } = paymentSummaryStore
  const { AIR } = paymentStructure
  const flightCards = getCards({
    savedCards: flightBilling?.creditCards,
    singleUseCard: getSingleTimeUseCard({ singleTimeUseCard, paymentDetails: AIR }),
  })
  const carRentalCards = getCards({
    savedCards: carRentalBilling?.creditCards,
    singleUseCard: singleTimeUseCard,
  })
  const hotelCards = getCards({
    savedCards: hotelBilling?.creditCards,
    singleUseCard: singleTimeUseCard,
  })
  const railCards = getCards({
    savedCards: railBilling?.creditCards,
    singleUseCard: singleTimeUseCard,
  })
  const flightCreditCard = flightCards.find((card) => card.id === chosenCards[TravelVertical.Air])
  const carRentalCreditCard = isCarPayNow
    ? carRentalCards.find((card) => card.id === chosenCards[TravelVertical.Carrental])
    : undefined
  const hotelCreditCard = useMemo(() => {
    const chosenCard = chosenCards[TravelVertical.Hotel]
    if (chosenCard === VIRTUAL_PAY) {
      return {
        label: t('CreditCardItem.VirtualPayCard'),
        brandType: VIRTUAL_PAY,
        isSiteCard: undefined,
      }
    }

    return hotelCards.find((card) => card.id === chosenCards[TravelVertical.Hotel])
  }, [chosenCards, hotelCards, t])
  const railCreditCard = railCards.find((card) => card.id === chosenCards[TravelVertical.Rail])

  return {
    flightCreditCard,
    carRentalCreditCard,
    hotelCreditCard,
    railCreditCard,
  }
}
