import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { BlockDefaultMobile } from './mobile'
import { BlockDefaultDesktop } from './desktop'

export function BlockDefaultLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<BlockDefaultMobile {...props} />}
      desktopSlot={<BlockDefaultDesktop {...props} />}
    />
  )
}
