import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Icon } from '@etta/ui/icon'
import { EmissionDeviation } from '@etta/components/emission-deviation/emission-deviation'
import { CarRentalFuelType } from '@fiji/graphql/types'
import type { Props } from '../../types'
import {
  Content,
  Info,
  Logo,
  Title,
  Capacity,
  Unit,
  Image,
  LogoContainer,
  CarRentalEmissionDeviationContainer,
  Label,
  PreferedLabelContainer,
  Wrapper,
} from './mobile-styled'

export function CarInfoBlockMobile({
  carRentalTitle,
  amenities,
  vendor,
  carImage,
  carEmission,
  isHybridFuel,
  isPreferred,
  customBadge,
}: Props) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const isElectric = amenities.fuel === CarRentalFuelType.Electric

  return (
    <Wrapper>
      <Content>
        <Info>
          <LogoContainer>
            {vendor?.imageUrl && <Logo src={vendor?.imageUrl} alt={vendor?.name} />}
          </LogoContainer>
          <Title>{carRentalTitle}</Title>
        </Info>
        <Image src={carImage} alt="car image" aria-hidden />
      </Content>
      <Capacity aria-label={t('SearchSegments.CarRental.Capacity')}>
        <Unit aria-label={`${t('SearchSegments.CarRental.Passengers')} ${amenities.passenger}`}>
          <Icon name="peoplePWA" aria-hidden />
          {amenities.passenger}
        </Unit>
        <Unit aria-label={`${t('SearchSegments.CarRental.Luggage')} ${amenities.luggage}`}>
          <Icon name="bagPWA" aria-hidden />
          {amenities.luggage}
        </Unit>
        {isHybridFuel && (
          <Unit aria-label={t('SearchSegments.CarRental.HybridFuel')}>
            <Icon name="carHybridPWA" aria-hidden />
            {t('SearchSegments.CarRental.HybridFuel')}
          </Unit>
        )}
        {isElectric && (
          <Unit aria-label={t('SearchSegments.CarRental.ElectricFuel')}>
            <Icon name="electricLinePWA" aria-hidden />
            {t('SearchSegments.CarRental.ElectricFuel')}
          </Unit>
        )}

        {featureFlags.isSustainabilityEnabled && (
          <CarRentalEmissionDeviationContainer>
            <EmissionDeviation {...carEmission} />
          </CarRentalEmissionDeviationContainer>
        )}
        {customBadge && (
          <PreferedLabelContainer>
            <Icon name="customPreferredPWA" size="small" color="success" aria-hidden />
            <Label>{customBadge}</Label>
          </PreferedLabelContainer>
        )}
        {isPreferred && !customBadge && (
          <PreferedLabelContainer>
            <Icon name="preferredPWA" size="small" aria-hidden />
            <Label>{t('Badge.PreferredVendor')}</Label>
          </PreferedLabelContainer>
        )}
      </Capacity>
    </Wrapper>
  )
}
