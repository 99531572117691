import { useState } from 'react'
import type { OverlappingTrip } from '@fiji/graphql/types'
import { Container } from './duplicate-list-styled'
import { Duplicate } from './duplicate'

type Props = {
  overlappingTrips: OverlappingTrip[]
}

export function DuplicateList({ overlappingTrips }: Props) {
  const [expandedTrip, setExpandedTrip] = useState<number>()

  return (
    <Container>
      {overlappingTrips.map((trip) => {
        return (
          <Duplicate
            overlappingTrip={trip}
            key={trip.id}
            setExpandedTrip={setExpandedTrip}
            expandedTrip={expandedTrip}
          />
        )
      })}
    </Container>
  )
}
