import { useTranslation } from 'react-i18next'
import type { Photo } from '@fiji/graphql/types'
import { Container, SecondaryImages } from './hotel-details-photos-styled'
import { useHotelPhotos } from './use-hotel-photos'
import { Lightbox } from './lightbox'
import { GalleryLink } from './gallery-link'
import { NoPhoto } from './no-photo'
import { ImageContent } from './image-content'

type Props = {
  photos?: Photo[]
  hotelName: string
}

export function HotelDetailsPhotos({ photos, hotelName }: Props) {
  const { t } = useTranslation()
  const {
    isLightboxOpen,
    handleOpenLightboxByIndex,
    handleCloseLightBox,
    handleUpdateCurrentPhotoIndex,
    currentPhotoIndex,
    groupedPhotos,
    firstFivePhotos,
    isGalleryLink,
  } = useHotelPhotos({ photos })

  if (!photos?.length) {
    return <NoPhoto />
  }

  return (
    <>
      <Container aria-label={t('PostBooking.HotelDetails.Photos')}>
        <ImageContent
          skin="leading-image"
          src={photos[0].url}
          onClick={() => handleOpenLightboxByIndex(0)}
          alt={t('PostBooking.HotelDetails.Hotel')}
        />
        {firstFivePhotos.length > 0 && (
          <SecondaryImages layoutToFit={firstFivePhotos.length}>
            {firstFivePhotos.map((photo, i) => {
              if (isGalleryLink(i)) {
                return (
                  <GalleryLink
                    key={(photo?.large || '') + (photo?.thumb || '') + i}
                    backgroundImage={photo.thumb || photo.large}
                    onClick={() => handleOpenLightboxByIndex(i === 3 ? 0 : i)}
                    photosNumber={groupedPhotos.length}
                    alt={t('PostBooking.HotelDetails.Hotel')}
                  />
                )
              }
              return (
                <ImageContent
                  onClick={() => handleOpenLightboxByIndex(i + 1)}
                  key={(photo?.large || '') + (photo?.thumb || '') + i}
                  src={photo.thumb || photo.large}
                  photosNumber={groupedPhotos.length}
                  alt={t('PostBooking.HotelDetails.Hotel')}
                />
              )
            })}
          </SecondaryImages>
        )}
      </Container>
      {isLightboxOpen && (
        <Lightbox
          onClose={handleCloseLightBox}
          title={hotelName}
          photos={groupedPhotos}
          index={currentPhotoIndex}
          onChange={handleUpdateCurrentPhotoIndex}
          isDisabled={!firstFivePhotos.length}
        />
      )}
    </>
  )
}
