import styled, { css } from 'styled-components'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll/animations/animation-basic'
import { getBasicHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll/animations/animations'
import type { HeaderAnimation, HeaderColors } from './types'
import { CenterSlot } from './center-slot'
import { Border } from './border'

export const Background = styled.header<{
  withBorderBottom?: boolean
  withBoxShadow?: boolean
  backgroundColor: HeaderColors
  animation?: HeaderAnimation
}>`
  position: relative;
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};

  ${(props) => {
    switch (props.animation) {
      case 'opacity':
        return css`
          ${Border},
          ${CenterSlot} {
            ${getBasicHeaderScrollAnimation('opacity-show')}
          }
        `
      case 'slide-top':
        return css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          opacity: 0;
          transform: translateY(-100%);

          ${createHeaderScrollAnimation(
            'header-slide-top',
            css`
               {
                to {
                  transform: translateY(0);
                  opacity: 1;
                }
              }
            `,
          )}
        `
    }
  }};

  ${(props) => {
    if (props.withBoxShadow) {
      return 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);'
    }

    return ''
  }}

  // Prevent 1px gap between header and device screen
  &:before {
    background-color: ${(props) => props.theme.colors[props.backgroundColor]};
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    top: -2px;
  }
`
