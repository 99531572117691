import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'

export function InfoModalHeaderDesktop({ title, onClose }: LayoutProps) {
  return (
    <Modal.Header isMobile>
      <Header
        withBorderBottom
        minHeight={72}
        align="center"
        layout="rtp-info-modal"
        leftSlot={<Header.BackButton onClick={onClose} />}>
        <Header.Title title={title} align="left" />
      </Header>
    </Modal.Header>
  )
}
