import { observer } from 'mobx-react-lite'
import type { ViewState } from '@etta/ui/view-state-modal'
import type { ViewStateStatus } from '@etta/modules/seat-map/core/types'
import { ViewStateModal as EttaViewStateModal } from '@etta/ui/view-state-modal'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { getModalText } from './get-modal-text'

function toViewState(status: ViewStateStatus): ViewState {
  if (status === 'hidden') {
    return 'hidden'
  }

  if (status === 'saving') {
    return 'saving'
  }

  if (status === 'saved') {
    return 'saved'
  }

  if (status === 'removing') {
    return 'removing'
  }

  if (status === 'removed') {
    return 'saved'
  }

  if (status === 'error') {
    return 'error'
  }

  return 'hidden'
}

export const ViewStateModal = observer(function ViewStateModal() {
  const { seatMapStore } = useSeatMapContext()
  const handleError = () => seatMapStore.setViewSateModalStatus('hidden')

  const { viewSateModalStatus } = seatMapStore

  const { title, text } = getModalText(viewSateModalStatus)

  return (
    <EttaViewStateModal
      title={title}
      error={title}
      text={text}
      viewState={toViewState(viewSateModalStatus)}
      onDone={handleError}
    />
  )
})
