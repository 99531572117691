import styled from 'styled-components'
import { DatePicker } from '@etta/ui/date-picker'
import { Modal } from '@etta/ui/modal'

export const StyledDatePicker = styled(DatePicker)`
  max-width: 100%;
`

export const Footer = styled(Modal.Footer)`
  padding: 16px;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.colors.borderDark};
`
