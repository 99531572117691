import styled from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.mainText};
`

export const AppliedFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  border: 1px solid ${(p) => p.theme.colors.borderDark};
  border-radius: 6px;
  cursor: pointer;

  ${KeyboardNavigationStyle}

  :hover {
    > ${IconWrapper} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`
