import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../../types'
import { FlightDetailsEcoCheckDesktopComponent } from './flight-details-eco-check-desktop-component'
import { FlightDetailsEcoCheckDesktopComponentNew } from './flight-details-eco-check-desktop-component-new'
import { FlightDetailsEcoCheckDesktopSkeleton } from './flight-details-eco-check-desktop-skeleton'

export function FlightDetailsEcoCheckDesktop({
  loading,
  tonnesOfEmissions,
  averageRouteEmissionTonnes,
  equivalences,
}: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()
  if (loading) {
    return <FlightDetailsEcoCheckDesktopSkeleton />
  }

  if (!tonnesOfEmissions || !averageRouteEmissionTonnes) {
    return null
  }
  if (isNewEmissionsBrandingEnabled) {
    return (
      <FlightDetailsEcoCheckDesktopComponentNew
        equivalences={equivalences || []}
        tonnesOfEmissions={tonnesOfEmissions}
        averageRouteEmissionTonnes={averageRouteEmissionTonnes}
      />
    )
  }
  return (
    <FlightDetailsEcoCheckDesktopComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageRouteEmissionTonnes={averageRouteEmissionTonnes}
    />
  )
}
