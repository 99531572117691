import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'
import { getBasicHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll/animations/animations'
import bg from '../../images/bg.png'

export const CarImageContainer = styled.div`
  position: relative;
`

export const CarImage = styled.div<{ carImageUrl?: string }>`
  position: relative;
  background: url(${(p) => p.carImageUrl || ''}) left 50% bottom -35px / contain no-repeat,
    url(${bg}) 50% 50% / cover no-repeat;
  width: 100%;
  min-height: 300px;
`

export const CloseButtonTop = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.colors.mainText};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${getBasicHeaderScrollAnimation('opacity-hide')}
`

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
`

export const Section = styled.div`
  margin: 0 16px 16px;
  position: relative;
`
