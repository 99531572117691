import type { CreditCardBrandType, Maybe, VirtualPay } from '@fiji/graphql/types'
import type { BillingError, ColorPalette } from '@fiji/types'
import type { VIRTUAL_PAY } from '@fiji/constants'
import { Container } from './credit-card-item-styled'
import type { LayoutType } from './row'
import { Row } from './row'
import type { CreditCardValidator, CardInformationColor } from './types'

type Props = {
  cardName?: string
  cardNumber?: string
  cardVendor?: Maybe<CreditCardBrandType> | typeof VIRTUAL_PAY
  isCvvRequired?: boolean
  expirationDate?: string
  onChange: () => void
  isEditable?: boolean
  isSelectable?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  onEdit?: () => void
  editLabel?: string
  isLast?: boolean
  layout?: LayoutType
  cardInformationColor?: CardInformationColor
  validator?: CreditCardValidator
  withPadding?: boolean
  virtualPay?: VirtualPay
  billingError?: BillingError
  withEditIcon?: boolean
  cardBackgroundColor?: keyof ColorPalette
  titlePlaceholder?: string
  preferredCardStatus?: string[]
  isBlurred?: boolean
  withDeleteIcon?: boolean
}

export function CreditCardItem({
  onChange,
  isSelected,
  isDisabled,
  cardName = '',
  cardNumber = '',
  expirationDate = '',
  isCvvRequired = false,
  cardVendor,
  isEditable,
  isSelectable,
  onEdit,
  isLast,
  editLabel,
  layout,
  cardInformationColor = 'bodyText2',
  validator,
  withPadding,
  virtualPay,
  billingError,
  withEditIcon,
  cardBackgroundColor,
  titlePlaceholder,
  preferredCardStatus,
  isBlurred,
  withDeleteIcon,
}: Props) {
  return (
    <Container isDisabled={isDisabled} withBorder={isLast} layout={layout}>
      <Row
        isDisabled={!!isDisabled}
        virtualPay={virtualPay}
        isChecked={!!isSelected}
        onChange={onChange}
        isCvvRequired={isCvvRequired}
        isSelectable={!!isSelectable}
        cardNumber={cardNumber}
        cardVendor={cardVendor}
        cardName={cardName}
        expirationDate={expirationDate}
        isEditable={!!isEditable}
        onEdit={onEdit}
        editLabel={editLabel}
        layout={layout}
        cardInformationColor={cardInformationColor}
        validator={validator}
        withPadding={withPadding}
        billingError={billingError}
        withEditIcon={withEditIcon}
        cardBackgroundColor={cardBackgroundColor}
        titlePlaceholder={titlePlaceholder}
        preferredCardStatus={preferredCardStatus}
        isBlurred={isBlurred}
        withDeleteIcon={withDeleteIcon}
      />
    </Container>
  )
}
