import type { ValueType, OptionType } from '../types'
import { findLabelsByValues } from './find-labels-by-values'

type Args = {
  values?: ValueType[]
  options: OptionType[]
}

export const joinValuesByLabel = ({ values, options }: Args) => {
  return findLabelsByValues({ values, options }).join(', ')
}
