import { useHotelResultsContextSelector } from '@etta/screens/hotel-result-page/hotel-results-provider'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { ScreenType, useScreenType } from '@fiji/modes'

export function useHotelListResults() {
  const {
    hotelsResult,
    policyModalToggle,
    isFiltersApplied,
    hotelDetails,
    actualHotelEmission,
    existingHotelEmissions,
  } = useHotelResultsContextSelector((state) => state)
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { hotelSearchDSMLightboxToggler } = dynamicSiteMessagesTogglersStore

  const type = useScreenType()
  const { handleOpen, selectedHotelId } = hotelDetails
  const { handleRefetchRequest } = hotelsResult

  const onButtonClickIsNoResult =
    isFiltersApplied && type === ScreenType.Mobile ? () => {} : undefined

  return {
    policyModalToggle,
    onButtonClickIsNoResult,
    isFiltersApplied,
    handleOpen,
    actualHotelEmission,
    existingHotelEmissions,
    handleRefetchRequest,
    selectedDetailsHotelId: selectedHotelId,
    hotelSearchDSMLightboxToggler,
  }
}
