import { isValidPhoneNumber } from 'libphonenumber-js/min'

export function validatePhoneInput(
  number: string | undefined | null,
  callingCountryCode: string | undefined | null, // we currently use calling country code instead of iso country code
) {
  // valid if both country code and number are not put
  if (!callingCountryCode && !number) {
    return true
  }
  // invalid if either is not put
  if (!callingCountryCode || !number) {
    return false
  }
  // check validation through google phone lib
  return isValidPhoneNumber(number, { defaultCallingCode: callingCountryCode })
}
