import type { PropsWithChildren } from 'react'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { useCovidInformation } from '../../../use-covid-information'
import type { ModalProps } from '../../types'

export function CovidInformationModalLayoutDesktop({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<ModalProps>) {
  const { getTranslation, i18BaseHeader } = useCovidInformation()

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="right"
      horizontalDimension="content-760">
      <Header
        leftSlot={<IconButton icon="arrowBackPWA" size="medium" onClick={onClose} />}
        withBorderBottom>
        <Header.Title title={getTranslation('.Title', i18BaseHeader)} align="left" />
      </Header>
      {children}
    </Modal>
  )
}
