import type { Assets } from './types'

const LINK_ID = 'insert-style-id'

type Args = {
  assets: Assets
  onDone: () => void
}

export function insertStyles({ assets, onDone }: Args) {
  const styles = assets
    // @ts-ignore
    .filter((link: any) => link.props && link.props.rel === 'stylesheet')

  if (!styles.length) {
    onDone()
    return
  }

  const length = styles.length - 1
  styles.forEach((link: any, idx: number) => {
    const onAllDone = () => {
      if (idx === length) {
        onDone()
      }
    }
    const id = `${LINK_ID}-${idx}`
    const isInserted = !!document.getElementById(id)
    if (isInserted) {
      onAllDone()
      return
    }

    const linkElement = document.createElement('link')
    linkElement.id = id
    linkElement.rel = 'stylesheet'
    linkElement.type = 'text/css'
    linkElement.media = 'screen'
    linkElement.href = link.props.href
    linkElement.onload = onAllDone
    document.getElementsByTagName('head')[0].appendChild(linkElement)
  })
}
