import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  background: #f8f8f8;
  position: relative;
  z-index: 11;
  -webkit-transform: translate3d(0, 0, 0);
`

export const WrappedContainer = styled.div`
  margin: 0 auto;
`

export const SkeletonContainer = styled(Container)`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`
