import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useResetDelegateActiveBookingFlow } from '@fiji/hooks/delegates/use-reset-delegate-active-booking-flow'
import { useAppSelector } from '@fiji/store'
import { mainNavigationOngoingBookingFlowSelector } from '@fiji/store/main-navigation'

type Args = {
  handleSelect: () => Promise<void>
}

export function useDelegateConfirmationDialog({ handleSelect }: Args) {
  const isOngoingBookingFlow = useAppSelector(mainNavigationOngoingBookingFlowSelector)
  const { onReset, handleRedirect } = useResetDelegateActiveBookingFlow()
  const { isOpen, handleClose, handleOpen } = useTogglePopup()

  const handleConfirm = async () => {
    if (isOpen) {
      handleClose()
    }
    await handleSelect()
    onReset()
  }

  const handleConfirmMobile = async () => {
    await handleConfirm()
  }

  const handleConfirmDesktop = async () => {
    await handleConfirm()
    handleRedirect()
  }

  const confirmationDialog = {
    isOpen,
    handleClose,
    handleOpen,
    handleConfirmMobile,
    handleConfirmDesktop,
  }

  return {
    confirmationDialog,
    isOngoingBookingFlow,
  }
}
