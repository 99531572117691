import styled, { css } from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import {
  createHeaderScrollAnimation,
  getBasicHeaderScrollAnimation,
} from '@fiji/hooks/use-header-scroll'

import { title2, headline } from '@fiji/style'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'

export const CloseButton = styled(IconButton)`
  left: 16px;
  top: 19px;
  position: absolute;
  opacity: 0;
  ${createHeaderScrollAnimation(
    'opacity-show-custom',
    css`
       {
        to {
          opacity: 1;
          left: 8px;
          top: 5px;
        }
      }
    `,
  )}
`

export const CloseButtonTop = styled(IconButton)`
  position: absolute;
  top: 15px;
  left: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => hexToRgba(p.theme.colors.mainText2, 0.8)};

  ${getBasicHeaderScrollAnimation('opacity-hide')};
`

export const HeaderTop = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  ${createHeaderScrollAnimation(
    'hotel-details-titles-top',
    css`
       {
        to {
          margin-left: 45px;
        }
      }
    `,
  )}
`

export const HeaderSticky = styled.div`
  margin-top: -35px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 23px 0 16px;
  position: sticky;
  z-index: 2;
  top: 0;

  ${createHeaderScrollAnimation(
    'hotel-details-titles-container',
    css`
       {
        to {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
          padding: 9px;
        }
      }
    `,
  )}
`

export const HotelTitle = styled.div`
  margin-bottom: 10px;
  ${title2};
  color: ${(props) => props.theme.colors.mainText};

  ${createHeaderScrollAnimation(
    'hotel-details-header-title',
    css`
       {
        to {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 0;
          ${headline};
        }
      }
    `,
  )}
`

export const HotelOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  > ${Text}:last-child {
    margin-left: 4px;
  }
`
