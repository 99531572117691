import { Screen } from '@etta/ui/screen'
import type { LayoutProps } from '../../types'
import { Footer } from './traveler-info-mobile-styled'

export function TravelerInfoMobile({
  contentRef,
  headerSlot,
  contentSlot,
  footerSlot,
}: LayoutProps) {
  return (
    <Screen backgroundColor="secondary">
      <Screen.Header>{headerSlot}</Screen.Header>

      <Screen.Container as={'main'} ref={contentRef}>
        {contentSlot}
      </Screen.Container>

      <Screen.Footer as={'footer'}>
        <Footer>{footerSlot}</Footer>
      </Screen.Footer>
    </Screen>
  )
}
