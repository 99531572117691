import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { HeaderMobile } from './mobile'
import { HeaderDesktop } from './desktop'

export function HeaderLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<HeaderMobile {...props} />}
      desktopSlot={<HeaderDesktop {...props} />}
    />
  )
}
