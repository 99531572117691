import type { ReactNode } from 'react'
import { SkeletonContent } from '@etta/ui/drop-down-list/drop-down-list-styled'
import { Skeleton } from '@etta/ui/skeleton'
import { ButtonWrapper, Label, Value, Wrapper, Placeholder, ErrorMessage } from './element-styled'

type Props = {
  wrapperAriaLabel?: string
  labelTitle: string
  value?: string | JSX.Element
  shouldTruncateValueAt?: number
  onClick?: () => void
  contentSlot?: ReactNode
  isTextAlignRight?: boolean
  placeholder?: string
  buttonMaxWidth?: number
  tabIndex?: number
  isDisabled?: boolean
  errorMessage?: string
  shouldErrorMessageLeftBorderBeFlat?: boolean
  shouldErrorMessageRightBorderBeFlat?: boolean
  shouldLeftBorderBeFlat?: boolean
  shouldRightBorderBeFlat?: boolean
  shouldAlignCenterLabelIfValueNotExist?: boolean
  isTransparentBackground?: boolean
  isShimmerLoading?: boolean
}

export function Element({
  wrapperAriaLabel,
  labelTitle,
  value,
  shouldTruncateValueAt,
  onClick,
  contentSlot,
  isTextAlignRight,
  placeholder,
  buttonMaxWidth,
  tabIndex = 0,
  isDisabled,
  errorMessage,
  shouldErrorMessageLeftBorderBeFlat,
  shouldErrorMessageRightBorderBeFlat,
  shouldLeftBorderBeFlat,
  shouldRightBorderBeFlat,
  shouldAlignCenterLabelIfValueNotExist,
  isTransparentBackground,
  isShimmerLoading,
}: Props) {
  return (
    <Wrapper
      hasError={!!errorMessage}
      shouldErrorMessageLeftBorderBeFlat={shouldErrorMessageLeftBorderBeFlat}
      shouldErrorMessageRightBorderBeFlat={shouldErrorMessageRightBorderBeFlat}>
      <ButtonWrapper
        aria-label={wrapperAriaLabel}
        tabIndex={tabIndex}
        onClick={onClick}
        isOnlyLabel={!(value || placeholder)}
        isTextAlignRight={isTextAlignRight}
        buttonMaxWidth={buttonMaxWidth}
        disabled={isDisabled || isShimmerLoading}
        shouldLeftBorderBeFlat={shouldLeftBorderBeFlat}
        shouldRightBorderBeFlat={shouldRightBorderBeFlat}
        isAlignCenter={shouldAlignCenterLabelIfValueNotExist && !value}
        isTransparentBackground={isTransparentBackground}>
        {isShimmerLoading ? (
          <SkeletonContent>
            <Skeleton width="100%" height="15px">
              <rect width="77px" height="100%" />
            </Skeleton>
          </SkeletonContent>
        ) : (
          <>
            <Label
              variant={value || placeholder ? 'captionMedium' : 'subHeadStrong'}
              color="bodyText"
              isTextAlignRight={isTextAlignRight}>
              {labelTitle}
            </Label>
            {value ? (
              <Value shouldTruncateValueAt={shouldTruncateValueAt}>{value}</Value>
            ) : placeholder ? (
              <Placeholder>{placeholder}</Placeholder>
            ) : null}
          </>
        )}
      </ButtonWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {contentSlot}
    </Wrapper>
  )
}
