import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { ShowMore } from '@etta/ui/show-more'
import type { Props } from '../../types'
import { ExpandedView } from '../../expanded-view'
import { CollapsedView } from '../../collapsed-view/'
import { RailTabsBlock } from '../../rail-tabs-block/rail-tabs-block'
import { Container, ShowDetails } from './rail-details-legend-mobile-styled'

const i18Base = 'RailDetails'

export function RailDetailsLegendMobile({ rail, activeTab, onTabChange }: Props) {
  const { t } = useTranslation()
  return (
    <Container>
      <ShowMore
        expandedContent={<ExpandedView rail={rail} />}
        notExpandedContent={<CollapsedView rail={rail} />}
        renderButton={(isExpanded, toggleExpansion) => {
          const buttonText = t(i18Base + (isExpanded ? '.HideDetailsButton' : '.ShowDetailsButton'))
          const buttonTextAria = t(
            i18Base + (isExpanded ? '.HideDetailsButtonAria' : '.ShowDetailsButtonAria'),
            { label: buttonText },
          )

          return (
            <ShowDetails
              role="button"
              tabIndex={0}
              onClick={toggleExpansion}
              aria-label={buttonTextAria}>
              {buttonText}
            </ShowDetails>
          )
        }}
      />
      <Block marginHorizontal={-24} marginTop={12}>
        <RailTabsBlock activeTab={activeTab} onTabChange={onTabChange} />
      </Block>
    </Container>
  )
}
