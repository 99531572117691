import styled from 'styled-components'

export const Border = styled.div<{
  withBorderBottom?: boolean
}>`
  border-bottom: 1px solid;
  border-bottom-color: ${(props) =>
    props.withBorderBottom ? props.theme.colors.borderLight : 'transparent'};
  display: ${(props) => (props.withBorderBottom ? 'block' : 'none')};
`
