export default function DirectBookingIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1428 9.6666C20.2565 9.6666 20.3654 9.60527 20.4459 9.4959C20.5263 9.38633 20.5713 9.23809 20.5713 9.0834V5.5834C20.5713 5.42871 20.5263 5.28027 20.4459 5.1709C20.3654 5.06152 20.2565 5 20.1428 5H3.85694C3.62032 5 3.42847 5.26113 3.42847 5.5834V9.0834C3.42847 9.23809 3.47353 9.38633 3.55388 9.4959C3.63438 9.60527 3.74329 9.6666 3.85694 9.6666C4.46938 9.6666 5.03531 10.1113 5.34153 10.8334C5.64789 11.5553 5.64789 12.4448 5.34153 13.1666C5.03532 13.8887 4.46938 14.3334 3.85694 14.3334C3.62032 14.3334 3.42847 14.5945 3.42847 14.9166V18.4166C3.42847 18.5713 3.47353 18.7197 3.55389 18.8291C3.63439 18.9385 3.7433 19 3.85695 19H20.1428C20.2565 19 20.3654 18.9385 20.4459 18.8291C20.5263 18.7197 20.5713 18.5713 20.5713 18.4166V14.9166C20.5713 14.7619 20.5263 14.6137 20.4459 14.5041C20.3654 14.3947 20.2565 14.3334 20.1428 14.3334C19.5304 14.3334 18.9645 13.8887 18.6583 13.1666C18.3519 12.4447 18.3519 11.5552 18.6583 10.8334C18.9645 10.1113 19.5304 9.6666 20.1428 9.6666Z"
        fill="#FF9500"
      />
      <path
        d="M16.3635 8.05454L16.3635 6.90909L15.3271 6.90909L13.0362 9.58181L8.94532 8.24545C8.5635 8.11818 8.12714 8.24545 7.85441 8.56363L7.63623 8.81818L11.509 11.3636L9.92714 13.7182L9.21805 13.2727C8.94532 13.0818 8.61805 13.1455 8.39987 13.4L8.0726 13.7818L9.49078 14.8L10.3635 16.4545L10.6908 16.0727C10.909 15.8182 10.9635 15.4364 10.8544 15.1182L10.5271 14.2909L12.5453 12.5727L14.6726 17.1545L14.9453 16.8364C15.2726 16.4545 15.3817 15.9455 15.218 15.5L14.0726 10.8545L16.3635 8.05454Z"
        fill="white"
      />
    </svg>
  )
}
