import { MediaLayout } from '@etta/ui/media-layout'
import type { FlightLegSubSegment } from '@fiji/graphql/types'
import type { BaggageInfoSegment } from '@fiji/types'
import {
  RTPItineraryCardBaggageInformationMobile,
  RTPItineraryCardBaggageInformationDesktop,
} from './layout'

type Props = {
  flightLegSegments?: FlightLegSubSegment[]
}

export function RTPItineraryCardBaggageInformation({ flightLegSegments }: Props) {
  const baggageInfo: BaggageInfoSegment[] =
    flightLegSegments?.reduce((acc: BaggageInfoSegment[], segment: FlightLegSubSegment) => {
      const mappedSegment = {
        imageUrl: segment.images.rectangle,
        baggageFees: segment.baggageFees,
        name: segment.name,
      }

      if (!acc.length) {
        return [mappedSegment]
      }
      if (acc.some((infoItem: BaggageInfoSegment) => infoItem.name !== segment.name)) {
        return [...acc, mappedSegment]
      }
      return acc
    }, []) || []

  if (!baggageInfo.length) {
    return null
  }

  const isBaggaggeInformationUnavailable =
    baggageInfo.every(
      (info) => !info.baggageFees || !info.baggageFees.items || info.baggageFees.items.length === 0, // unavailability check
    ) || false

  if (!isBaggaggeInformationUnavailable) {
    return null
  }

  const props = {
    carrierName: baggageInfo[0].name || '',
    carrierUrl: baggageInfo[0].baggageFees?.carrierUrl || '',
  }

  return (
    <MediaLayout
      mobileSlot={<RTPItineraryCardBaggageInformationMobile {...props} />}
      desktopSlot={<RTPItineraryCardBaggageInformationDesktop {...props} />}
    />
  )
}
