import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

type Args = {
  currentUrl: string
  usedPath: string
}

export function useLatestRoute(detectPath: string): Args {
  const prevLink = useRef<string>(detectPath)
  const { pathname, search } = useLocation()
  const currentUrl = pathname + search

  const isSamePath = currentUrl === detectPath
  const isPathInside = currentUrl.indexOf(detectPath) === 0 && !isSamePath

  if (isPathInside) {
    prevLink.current = currentUrl
    return { usedPath: detectPath, currentUrl }
  }

  if (isSamePath) {
    prevLink.current = detectPath
  }

  return { usedPath: prevLink.current, currentUrl }
}
