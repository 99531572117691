export enum SegmentType {
  CarRental = 'CAR_RENTAL',
  CarService = 'CAR_SERVICE',
  Drive = 'DRIVE',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  Mobility = 'MOBILITY',
  PublicTransit = 'PUBLIC_TRANSIT',
  RideHail = 'RIDE_HAIL',
  Train = 'TRAIN',
  Unspecified = 'UNSPECIFIED',
  Walk = 'WALK',
}
