import { useTranslation } from 'react-i18next'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import {
  UpperBody,
  EmissionInformation,
  EmissionInformationRow,
  EmissionInformationType,
  EmissionInformationValue,
  DeviationInfo,
} from '../car-rental-details-eco-check-modal-desktop-styled'
import { CarRentalDetailsModalFullPercentDeviationDescription } from './car-rental-details-eco-check-modal-desktop-full-percent-deviation-description'

type Props = {
  deviationLevel: EmissionsDeviationLevel
  averageEmissionsKg: number
  percentDeviation: number
  kgOfEmissions: number
}

export function CarRentalDetailsEcoCheckModalDesktopUpperBody({
  kgOfEmissions,
  averageEmissionsKg,
  deviationLevel,
  percentDeviation,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  return (
    <UpperBody>
      <EmissionInformation>
        <EmissionInformationRow>
          <EmissionInformationType>{t(i18nBase + '.ThisCarClass')}</EmissionInformationType>
          <EmissionInformationValue deviationLevel={deviationLevel}>
            <KgOfCo2 kgOfEmissions={kgOfEmissions} />
          </EmissionInformationValue>
        </EmissionInformationRow>
        <EmissionInformationRow>
          <EmissionInformationType>{t(i18nBase + '.TypicalCarClass')}</EmissionInformationType>
          <EmissionInformationValue aria-label={averageEmissionsKg + ' Kg CO2'}>
            <KgOfCo2 kgOfEmissions={averageEmissionsKg} />
          </EmissionInformationValue>
        </EmissionInformationRow>
      </EmissionInformation>
      <DeviationInfo deviationLevel={deviationLevel}>
        <CarRentalDetailsModalFullPercentDeviationDescription
          deviationLevel={deviationLevel}
          percentDeviation={percentDeviation}
        />
      </DeviationInfo>
    </UpperBody>
  )
}
