import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { FlightDetailsEcoCheckModal } from '@etta/components/flight-details-eco-check-modal'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  BodyRight,
  LearnMore,
  BodyRightDescription,
} from './flight-details-eco-check-mobile-styled'
import { BodyLeftBlock } from './body-left-block'
import EcoCheckAverage from './assets/eco-check-average.svg?url'
import EcoCheckMore from './assets/eco-check-more.svg?url'
import EcoCheckLess from './assets/eco-check-less.svg?url'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

type Props = {
  tonnesOfEmissions: number
  averageRouteEmissionTonnes: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function FlightDetailsEcoCheckMobileComponent({
  tonnesOfEmissions,
  equivalences,
  averageRouteEmissionTonnes,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.FlightDetails'
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions: tonnesOfEmissions,
    averageEmissionsTonnes: averageRouteEmissionTonnes,
  })
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const bodyRightDescription = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.LowerEmissions')
      case EmissionsDeviationLevel.More:
        return t(i18nBase + '.HigherEmissions')
    }
  }, [deviationLevel, t])

  const ecoCheckBackground = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return EcoCheckAverage
      case EmissionsDeviationLevel.Less:
        return EcoCheckLess
      case EmissionsDeviationLevel.More:
        return EcoCheckMore
    }
  }, [deviationLevel])

  return (
    <>
      <Container onClick={handleOpen} data-tracking-id="flight-details-eco-check-action">
        <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
          <img src={EcoCheckLogo} alt="eco-check-logo" />
        </Title>
        <Body>
          <BodyLeftBlock deviationLevel={deviationLevel} percentDeviation={percentDeviation} />
          <BodyRight>
            <BodyRightDescription
              aria-label={bodyRightDescription}
              dangerouslySetInnerHTML={{ __html: bodyRightDescription }}
            />
            <LearnMore
              aria-label={
                t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
              }>
              {t('Emissions.DetailsModal.LearnMore')}
            </LearnMore>
          </BodyRight>
        </Body>
        <EcoCheckBackground src={ecoCheckBackground} />
      </Container>
      <FlightDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageRouteEmissionTonnes}
      />
    </>
  )
}
