import { Modal } from '@etta/ui/modal'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { ModalHeaderLayout } from './layout'

type Props = {
  title: string
  backgroundColor: LayoutBackgroundColor
}

export function ModalHeader({ title, backgroundColor }: Props) {
  return (
    <Modal.Header isMobile>
      <ModalHeaderLayout title={title} backgroundColor={backgroundColor} />
    </Modal.Header>
  )
}
