import { useTranslation } from 'react-i18next'
import { EmissionsDeviationLevel } from '@fiji/enums'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function useFlightDetailsEcoCheckModalQuestion({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return {
        questions: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightAverageQuestion'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightAverageQuestion'),
        },
        answers: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightAverageAnswer'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightAverageAnswer'),
        },
      }
    case EmissionsDeviationLevel.Less:
      return {
        questions: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightLowerQuestion'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightLowerQuestion'),
        },
        answers: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightLowerAnswer'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightLowerAnswer'),
        },
      }
    case EmissionsDeviationLevel.More: {
      return {
        questions: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightHigherQuestion'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightHigherQuestion'),
        },
        answers: {
          howDoWeKnow: t(i18nBase + '.HowDoWeKnowFlightHigherAnswer'),
          howDoWeEstimate: t(i18nBase + '.HowDoWeEstimateFlightHigherAnswer'),
        },
      }
    }
  }
}
