import styled from 'styled-components'
import { Link } from '@etta/ui/link'
import { footnoteMedium, footnoteStrong, linkSmall } from '@fiji/style'

export const NameTrip = styled.div`
  ${footnoteStrong};
  margin-top: 8px;
`

export const ViewTrip = styled(Link)`
  ${linkSmall};
  color: ${(p) => p.theme.colors.primary} !important;
`

export const Content = styled.div`
  ${footnoteMedium};
  margin-top: 4px;
  margin-bottom: 8px;
`
