import { Inject, Service } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { ZooKeeperConfigurationAdapter } from '../../infra'
import { ZooKeeperConfigurationStore } from '../stores'

@Service()
export class ZooKeeperConfigurationService {
  constructor(
    @Inject()
    private readonly zooKeeperConfigurationAdapter: ZooKeeperConfigurationAdapter,
    @Inject()
    private readonly zooKeeperConfigurationStore: ZooKeeperConfigurationStore,
    @Inject()
    private readonly cookieStore: CookieStore,
  ) {}

  async loadZooKeeperConfiguration() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.zooKeeperConfigurationStore.setIsLoading(true)

    const result = await this.zooKeeperConfigurationAdapter.getZookeeperConfiguration()

    result.match({
      Err: () => this.zooKeeperConfigurationStore.setIsLoading(false),
      Ok: (data) => {
        this.zooKeeperConfigurationStore.setZooKeeprConfiguration(data)
        this.zooKeeperConfigurationStore.setIsLoading(false)
      },
    })
  }

  async refetchZooKeeperConfiguration() {
    const result = await this.zooKeeperConfigurationAdapter.getZookeeperConfiguration()

    result.match({
      Err: () => {},
      Ok: (data) => this.zooKeeperConfigurationStore.setZooKeeprConfiguration(data),
    })
  }
}
