import { Skeleton } from '@etta/ui/skeleton'
import { Container, Body } from './change-flight-modal-skeleton-styled'

export function ChangeFlightModalSkeleton() {
  return (
    <Container>
      <Skeleton width={'100%'} height={12} variant="secondary1">
        <rect x={20} y={0} width={72} height={12} />
      </Skeleton>
      <Body>
        <Skeleton width={'100%'} height={58} variant="secondary1">
          <rect x={16} y={0} width={44} height={44} />
          <rect x={92} y={0} width={94} height={17} />
          <rect x={92} y={26} width={146} height={12} />
          <rect x={92} y={48} width={20} height={12} />
        </Skeleton>
      </Body>
      <Skeleton width={'100%'} height={12} variant="secondary1">
        <rect x={20} y={0} width={72} height={12} />
      </Skeleton>
      <Body>
        <Skeleton width={'100%'} height={58} variant="secondary1">
          <rect x={16} y={0} width={44} height={44} />
          <rect x={92} y={0} width={94} height={17} />
          <rect x={92} y={26} width={146} height={12} />
          <rect x={92} y={48} width={20} height={12} />
        </Skeleton>
      </Body>
    </Container>
  )
}
