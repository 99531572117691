import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import type { LayoutProps } from '../types'
import { Container, Message, ToggleLink, GlobalSafaryStyle } from './message-section-desktop-styled'

const i18nBase = 'DynamicSiteMessage'

export function MessageSectionDesktop({
  title,
  message = '',
  messageSlot,
  sectionSlot,
  shouldTruncateMessage,
  shouldShowShowMoreButton,
  isMessageTruncated,
  hasDesktopBannerImage,
  handleExpand,
  handleTruncate,
  messageRef,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      {shouldTruncateMessage && <GlobalSafaryStyle />}
      {title && (
        <Block marginBottom={9}>
          <Text variant="title3" color="mainText">
            {title}
          </Text>
        </Block>
      )}
      <Swap is={!!messageSlot} isSlot={<Message>{messageSlot}</Message>}>
        <Message
          ref={messageRef}
          shouldTruncateMessage={shouldTruncateMessage}
          hasDesktopBannerImage={hasDesktopBannerImage}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {shouldShowShowMoreButton && (
          <ToggleLink onClick={handleExpand}>{t(`${i18nBase}.ShowMore`)}</ToggleLink>
        )}
        {!shouldTruncateMessage && isMessageTruncated && (
          <ToggleLink onClick={handleTruncate}>{t(`${i18nBase}.ShowLess`)}</ToggleLink>
        )}
      </Swap>
      {sectionSlot}
    </Container>
  )
}
