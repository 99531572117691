import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { LazyContent } from '@etta/ui/lazy-content'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import { useFlightFareRulesContext } from '../../interface/flight-fare-rules-modal/use-flight-fare-rules.context'
import { FareRulesLayout } from './layout'

type Props = {
  onClose: () => void
  flightSegmentId: string
  transactionId: string
  airlineLogo?: string
  airlineName?: string
  fares?: Array<string>
  bookingCodes?: Array<string>
}

export const FareRulesContent = observer(
  ({
    onClose,
    flightSegmentId,
    transactionId,
    airlineLogo,
    airlineName,
    fares,
    bookingCodes,
  }: Props) => {
    const { t } = useTranslation()
    const { flightFareRulesActions, flightFareRulesStore } = useFlightFareRulesContext()
    const i18nBase = 'FareRules'

    const segmentsBasis = fares?.join(', ')
    const codes = bookingCodes?.join(', ')

    useEffect(() => {
      flightFareRulesActions.getFlightFareRulesByTransactionId(flightSegmentId, transactionId)
    }, [flightSegmentId, transactionId, flightFareRulesActions])

    return (
      <>
        <InfoModal.Header title={t(`${i18nBase}.Title`)} onClose={onClose} />
        <LazyContent isLoading={flightFareRulesStore.isLoading}>
          <InfoModal.Content>
            <FareRulesLayout
              flightFareRules={flightFareRulesStore.flightFareRules}
              airlineLogo={airlineLogo}
              airlineName={airlineName}
              segmentsBasis={segmentsBasis}
              bookingCodes={codes}
            />
          </InfoModal.Content>
        </LazyContent>
      </>
    )
  },
)
