import type { HotelSegmentEntity } from '@etta/modules/review-trip/core'
import type { RangeResult } from '../types'
import { changeHourInDate } from './change-hour-in-date'

const DEFAULT_CHECKIN_HOUR = 9
const DEFAULT_CHECKOUT_HOUR = 17

function convertHotelToRangeRTP(hotel: HotelSegmentEntity): RangeResult {
  const { checkOut, checkIn, address } = hotel
  return {
    to: address,
    from: address,
    startDate: changeHourInDate({ value: checkIn, hour: DEFAULT_CHECKIN_HOUR }),
    endDate: changeHourInDate({ value: checkOut, hour: DEFAULT_CHECKOUT_HOUR }),
  }
}

export function convertHotelForFlightRTP(hotel: HotelSegmentEntity): RangeResult {
  const result = convertHotelToRangeRTP(hotel)

  return {
    ...result,
    from: undefined,
  }
}

export function convertHotelForCarRTP(hotel: HotelSegmentEntity): RangeResult {
  return convertHotelToRangeRTP(hotel)
}

export function convertHotelForTrainRTP(hotel: HotelSegmentEntity): RangeResult {
  return convertHotelToRangeRTP(hotel)
}
