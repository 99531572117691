import styled, { css } from 'styled-components'
import { footnoteMedium, headline, largeTitle } from '@fiji/style'
import type { HeaderLayout, HeaderTitleAlign, HeaderColors } from '../../../types'

const ellipsisOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TitleContainer = styled.div<{
  align: HeaderTitleAlign
  layout?: HeaderLayout
}>`
  display: flex;
  min-width: 0;
  width: 100%;
  align-items: ${(props) => {
    switch (props.align) {
      default:
      case 'center':
        return 'center'

      case 'left':
        return 'start'
    }
  }};
  flex-direction: column;

  ${(props) =>
    props.layout === 'seat-map' &&
    css`
      margin-top: 5px;
      min-height: 40px;
    `}
`

export const MainTitle = styled.div<{
  layout: HeaderLayout
  toLayout: HeaderLayout
  color: HeaderColors
}>`
  max-width: 100%;
  color: ${(p) => p.theme.colors[p.color]};

  ${(props) => {
    switch (props.layout) {
      case 'content-view':
        return css`
          ${largeTitle};
        `
      case 'aligned':
        return css`
          ${ellipsisOverflow};
          ${headline};
        `
      case 'search-input':
        return css`
          ${ellipsisOverflow};
          ${headline};
        `
      case 'seat-map':
        return css`
          ${ellipsisOverflow};
          ${headline};
        `
      case 'caption':
        return css`
          ${ellipsisOverflow};
          ${headline};
          margin-bottom: 2px;
        `
    }
  }}
`

export const SubTitle = styled.div<{
  layout: HeaderLayout
  toLayout?: HeaderLayout
}>`
  max-width: 100%;

  color: ${({ theme }) => theme.colors.mainText};
  ${ellipsisOverflow};
  ${footnoteMedium};

  ${(props) => {
    switch (props.layout) {
      case 'seat-map':
        return css`
          color: ${({ theme }) => theme.colors.bodyText};
        `
      case 'search-input':
        return css`
          color: ${({ theme }) => theme.colors.bodyText};
          margin-top: 2px;
        `
      case 'caption':
        return css`
          color: ${({ theme }) => theme.colors.bodyText};
        `
    }
  }}
`
