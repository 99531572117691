import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  SubTitle,
  ContinueTitle,
  ContainerContinue,
  IconWrapper,
  ContinueButton,
} from '../../../blocks-mobile-styles'

export function BlockUnavailableMobile({
  title,
  subTitle,
  buttonTitle,
  isLoading,
  onContinue,
}: LayoutProps) {
  return (
    <ContainerContinue>
      <IconWrapper>
        <SeatIcon icon="warning" />
      </IconWrapper>
      <div>
        <ContinueTitle>{title}</ContinueTitle>
        <SubTitle>{subTitle}</SubTitle>
        <ContinueButton variant="outline" size="small" onClick={onContinue} disabled={isLoading}>
          {buttonTitle}
        </ContinueButton>
      </div>
    </ContainerContinue>
  )
}
