import type { GetRideHailSuggestionsQuery } from '@fiji/graphql/hooks'
import type { RideHailSuggestionValueObject } from '../../core/value-objects/ride-hail-suggestion.value-object'
import { mapLocationTypeEnum } from './ride-hail-location-type.mapper'
import { mapSegmentTypeEnum } from './ride-hail-segment-type.mapper'

export function mapToRideHailSuggestionValueObject(
  data: GetRideHailSuggestionsQuery,
): RideHailSuggestionValueObject[] {
  return data.rideHailSuggestions.rideHailSuggestions.map((suggestion) => {
    return {
      canBeBookedWithinEtta: suggestion.canBeBookedWithinEtta,
      pickUpName: suggestion.pickUpName,
      pickUpDate: suggestion.pickUpDate,
      pickUpTime: suggestion.pickUpTime,
      pickUpTimeZoneUtcOffset: suggestion.pickUpTimeZoneUtcOffset,
      pickUpLocation: {
        address: suggestion.pickUpLocation.address ?? undefined,
        geocode: suggestion.pickUpLocation.geocode ?? undefined,
      },
      pickUpLocationType: mapLocationTypeEnum(suggestion.pickUpLocationType),
      pickUpSegmentId: suggestion.pickUpSegmentId ?? undefined,
      pickUpSegmentType: mapSegmentTypeEnum(suggestion.pickUpSegmentType),
      pickUpSegmentEndDate: suggestion.pickUpSegmentEndDate,
      pickUpSegmentEndTime: suggestion.pickUpSegmentEndTime,
      pickUpSegmentEndTimeZoneUtcOffset: suggestion.pickUpSegmentEndTimeZoneUtcOffset ?? undefined,
      dropOffName: suggestion.dropOffName,
      dropOffDate: suggestion.dropOffDate,
      dropOffTime: suggestion.dropOffTime,
      dropOffTimeZoneUtcOffset: suggestion.dropOffTimeZoneUtcOffset ?? undefined,
      dropOffLocationType: mapLocationTypeEnum(suggestion.dropOffLocationType),
      dropOffLocation: {
        address: suggestion.dropOffLocation.address ?? undefined,
        geocode: suggestion.dropOffLocation.geocode ?? undefined,
      },
      dropOffSegmentId: suggestion.dropOffSegmentId ?? undefined,
      dropOffSegmentType: mapSegmentTypeEnum(suggestion.dropOffSegmentType),
      dropOffSegmentStartDate: suggestion.dropOffSegmentStartDate,
      dropOffSegmentStartTime: suggestion.dropOffSegmentStartTime,
      dropOffSegmentStartTimeZoneUtcOffset:
        suggestion.dropOffSegmentStartTimeZoneUtcOffset ?? undefined,
      processId: suggestion.processId ?? undefined,
    }
  })
}
