import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import CallToActionBackgroundImage from './assets/mobility-entry-point.svg?url'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  overflow: hidden;
  padding: 16px 16px 49px 16px;
  background-image: url(${CallToActionBackgroundImage});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
`

export const CTABlock = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  padding: 16px 16px 16px 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`

export const IconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DescriptionContainer = styled.div`
  margin-top: 12px;
  max-width: 300px;
`

export const CTATitle = styled(Text)`
  padding-left: 5px;
`
