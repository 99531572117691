import type { IconColorPalette } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { OnTheFlyContentAssessment } from '@fiji/graphql/types'
import { fareAttributeToPwaIconMap } from './fare-attribute-to-pwa-icon-map'
import { IconRow } from './attr-icon-styled'

type Props = {
  assessment: OnTheFlyContentAssessment
  attrId: string
  iconColor?: IconColorPalette
}

export function AttrIcon({ assessment, attrId, iconColor = 'bodyText' }: Props) {
  const iconName =
    assessment === OnTheFlyContentAssessment.Restriction
      ? fareAttributeToPwaIconMap['non-refundable-cancellation']
      : fareAttributeToPwaIconMap[attrId]

  return (
    <IconRow>
      <Icon name={iconName} color={iconColor} size="small" />
    </IconRow>
  )
}
