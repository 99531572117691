import type { Alert } from '../../types'
import { Anchor } from '../anchor'
import { AlertItem } from './alert-item'
import { Separator } from './alerts-list-styled'

type Props = {
  alerts: Alert[]
  anchorId: string | null
  isWithMessages: boolean
  hasMultipleItems: boolean
  shouldExpandMessages: boolean
  onClose: (id: string) => void
  handleSetAnchor: (node: HTMLDivElement | null) => void
}

export function AlertsList({
  alerts,
  anchorId,
  isWithMessages,
  hasMultipleItems,
  shouldExpandMessages,
  onClose,
  handleSetAnchor,
}: Props) {
  if (!alerts.length) {
    return null
  }

  return (
    <>
      {alerts.map(({ id, header, text, component }) => (
        <>
          <Anchor messageId={id} anchorId={anchorId} handleSetAnchor={handleSetAnchor} />
          <AlertItem
            key={id}
            title={header}
            message={text}
            messageSlot={component}
            hasMultipleItems={hasMultipleItems}
            shouldExpandMessages={shouldExpandMessages}
            onClose={() => onClose(id)}
          />
        </>
      ))}
      {isWithMessages && <Separator />}
    </>
  )
}
