import { rgba } from 'polished'

export const waterBlue = '#1197c2'
export const white = '#ffffff'
export const primaryOrange = '#f36941' // NOT USED
export const primaryOrangeHover = '#f47651' // NOT USED
export const tripAdvisorColor = '#00aa6c'
export const dailyRatesFooterColor = '#f1f5f7' // NOT USED
export const dailyRatesDateColor = '#565656'
export const charcoal20 = '#e2e8ea'
export const charcoal30 = '#dcddde' // NOT USED
export const charcoal40 = '#bbbebf'
export const charcoal50 = '#9b9ea0'
export const charcoal60 = '#7a7f81'
export const charcoal70 = '#666c6e'
export const charcoal80 = '#595f62'
export const charcoal90 = '#394043' // NOT USED
export const charcoal100 = '#252d30'
// TODO Return values when we implement Themes
// export const ceruleanBlue = '#007feb'
export const ceruleanBlue = '#266782'
export const marine = '#064563'
export const lightSkyBlue = '#dee2e6'
export const paleBlue = '#003580'
export const marineLight = '#bdc3d6'
export const marineLightSemitransparent = rgba(marineLight, 0.5)
export const magenta = '#5e11c9'
export const orange = '#ff7a17'
export const green = '#11c957'
export const tripReviewSegmentBlack = '#222222'
export const tripReviewSegmentWhite = '#f8f8f8' // NOT USED
export const tripReviewSegmentGray = '#d7d7d7' // NOT USED
export const tripReviewSegmentBlackLight = '#666666'
export const red = '#ea4f35'
export const tripReviewLink = '#3593c6'
export const tripReviewLinkHover = '#E24300'
export const tripReviewLegend = '#698ea0'
export const tripReviewRulesAndRestrictions = '#666' // NOT USED
export const tripReviewLegendHover = '#222' // NOT USED
export const tripReviewBorderColor = rgba(marineLight, 0.5)
export const maroon = '#b80000'
export const tripReviewPageBackground = '#f1f2f6'
export const tripReviewPageGreyColor = '#6a8fa1'
export const tripReviewPageBlueColor = '#0861a9'
export const boxShadow = '0 0 41px 0 rgba(0, 0, 0, 0.09)'
export const warningPaleRed = '#ffeeeb'
export const warningRed = '#ff573a'
export const selectArrow = '#c0c6d8'
export const tripReviewBlue = '#0083ee'
export const tripReviewModalBorder = '#dde0ea'
export const profileInitialsBackground = '#d1d1d6'
export const tripPlannerInputBck = '#ecedee'

// Apple Buttons
export const blue = '#118fc9'
export const blueDarkestRGB = '48, 131, 251'
export const blueDarkest = `rgb(${blueDarkestRGB})`
export const blueDarkestLight10 = `rgba(${blueDarkestRGB}, 0.9)`

// Default theme
export const basePrimaryFontColor = ceruleanBlue
export const baseSecondFontColor = marine

// Apple theme
export const applePrimaryFontColor = '#1c1c1f'
export const applePrimaryMaxLight = white
export const appleSecondFontColor = '#2c2c37'
export const appleThirdFontColor = blueDarkest
export const appleThirdFontColorLight10 = blueDarkestLight10
export const appleDateRangeColor = rgba(appleThirdFontColor, 0.14)
export const appleRangeHoverColor = rgba(appleThirdFontColor, 0.3)
export const preferredRoomColor = '#c9119c'

// Holland America theme
export const hollandAmericaButtonColor = '#659aca'
export const hollandAmericaButtonHoverColor = rgba(hollandAmericaButtonColor, 0.9)

// CWT theme
export const cwtButtonColor = '#ff694b'
export const cwtButtonColorHover = rgba(cwtButtonColor, 0.9)
export const cwtDateRangeColor = rgba(cwtButtonColor, 0.14)
export const cwtRangeHoverColor = rgba(cwtButtonColor, 0.3)

// EHI Direct theme
export const ehiDirectButtonColor = '#00703c'
export const ehiDirectButtonColorHover = rgba(ehiDirectButtonColor, 0.9)
export const ehiDateRangeColor = rgba(ehiDirectButtonColor, 0.14)
export const ehiRangeHoverColor = rgba(ehiDirectButtonColor, 0.3)

// Deem theme
export const deemButtonColor = ceruleanBlue
export const deemButtonColorHover = '#064663'

export default {
  applePrimaryFontColor,
  appleSecondFontColor,
  appleThirdFontColor,
  applePrimaryMaxLight,
  appleThirdFontColorLight10,
  basePrimaryFontColor,
  baseSecondFontColor,
  waterBlue,
  primaryOrange,
  primaryOrangeHover,
  tripAdvisorColor,
  charcoal20,
  charcoal30,
  charcoal40,
  charcoal50,
  charcoal60,
  charcoal70,
  charcoal80,
  charcoal90,
  charcoal100,
  white,
  dailyRatesFooterColor,
  ceruleanBlue,
  lightSkyBlue,
  marine,
  marineLight,
  green,
  blue,
  orange,
  magenta,
  red,
  tripReviewSegmentBlack,
  tripReviewSegmentWhite,
  tripReviewSegmentGray,
  tripReviewSegmentBlackLight,
  paleBlue,
  tripReviewLegend,
  tripReviewRulesAndRestrictions,
  tripReviewLegendHover,
  maroon,
  tripReviewBorderColor,
  tripReviewPageBackground,
  tripReviewPageGreyColor,
  tripReviewPageBlueColor,
  boxShadow,
  preferredRoomColor,
  warningPaleRed,
  warningRed,
  selectArrow,
  tripReviewModalBorder,
}
