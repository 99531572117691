export default function TimerIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5882 38.7442C29.5873 38.7442 36.1765 32.0481 36.1765 23.9364C36.1765 20.7512 35.1574 17.7641 33.4263 15.3433L34.5711 14.1825C34.948 13.8003 35.1574 13.3473 35.1574 12.8943C35.1574 11.9599 34.4315 11.2238 33.4961 11.2238C32.9517 11.2238 32.5748 11.3795 32.1839 11.7617L31.1369 12.7952C28.9731 10.884 26.2648 9.60993 23.3053 9.24186V7.75542C23.3053 6.77861 22.5375 6 21.5882 6C20.6669 6 19.8712 6.77861 19.8712 7.75542V9.24186C12.6678 10.1196 7 16.4192 7 23.9364C7 32.0481 13.5891 38.7442 21.5882 38.7442ZM21.5882 35.4174C15.3202 35.4174 10.2946 30.2927 10.2946 23.9364C10.2946 17.5801 15.3062 12.4696 21.5882 12.4696C27.8563 12.4696 32.8959 17.5801 32.9098 23.9364C32.9098 30.2927 27.8563 35.4174 21.5882 35.4174ZM21.5882 26.5271C22.9982 26.5271 24.129 25.3662 24.129 23.9506C24.129 22.9879 23.6124 22.1668 22.8167 21.7138V15.584C22.8167 14.8903 22.2723 14.3382 21.5882 14.3382C20.8902 14.3382 20.3458 14.8903 20.3458 15.584V21.7138C19.564 22.1668 19.0475 23.0021 19.0475 23.9506C19.0475 25.3662 20.1783 26.5271 21.5882 26.5271Z"
        fill="currentColor"
      />
    </svg>
  )
}
