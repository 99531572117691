import { TextField } from '@etta/ui/text-field'
import { Select } from '@etta/ui/select'
import { SwipingTimePicker } from '@etta/ui/swiping-time-date-picker'
import { DateOfBirthField } from '@etta/ui/date-of-birth-picker'
import { PickerType } from '../../../../types'
import type { LayoutProps } from '../../types'
import { DatePickerWrapper, SwipingTimePickerWrapper } from './date-field-desktop-styled'

export const DateFieldDesktop = ({
  helperText,
  label,
  value,
  onChangeHandler,
  GMT_ARRAY,
  pickerType,
  handleOpen,
  handleApplyTime,
  datePickerTypes,
  isOpen,
  handleClose,
  handleOnApplyDate,
  formatValue,
  isDisabled,
  getPlainTime,
  field,
  tabIndex,
}: LayoutProps) => {
  if (pickerType === PickerType.TIMEZONE) {
    return (
      <Select
        label={label}
        value={value as string}
        options={GMT_ARRAY}
        onChange={onChangeHandler}
        helperType="error"
        helperText={helperText}
        description={field.tooltip}
        withNoneOption={false}
      />
    )
  }

  if (datePickerTypes.includes(pickerType)) {
    return (
      <DateOfBirthField
        onChange={handleOnApplyDate}
        value={value ? new Date(value) : undefined}
        isOpen={isOpen}
        onClose={handleClose}
        onOpenPicker={handleOpen}
        helperType="error"
        helperText={helperText}
        isDisabled={isDisabled}
        label={label}
        withClickOutsideHandle
        description={field.tooltip}
        tabIndex={tabIndex}
      />
    )
  }

  return (
    <DatePickerWrapper onClick={handleOpen}>
      <SwipingTimePickerWrapper>
        <SwipingTimePicker
          isVisible={isOpen}
          onClose={handleClose}
          onApply={handleApplyTime}
          label={label}
          variant={pickerType}
          value={getPlainTime(value)}
        />
      </SwipingTimePickerWrapper>
      <TextField
        label={label}
        value={formatValue(value)}
        helperText={helperText}
        helperType="error"
        description={field.tooltip}
      />
    </DatePickerWrapper>
  )
}
