import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const CardWrapper = styled.button`
  display: flex;
  padding: 20px 20px 12px;
  gap: 8px;
  border-radius: 14px;
  border: none;
  background-color: ${(p) => p.theme.colors.white};

  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 11px;
  margin-bottom: 5px;
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;

  &:nth-child(2) {
    margin-top: 8px;
  }
`

export const CardActions = styled.div`
  display: flex;
  gap: 16px;
`

export const CardAction = styled(Button)`
  padding: 0;
  min-width: unset;
`

export const CardHeaderRight = styled(Block)`
  display: flex;
  align-items: center;
`

export const TimeTitle = styled(Text)`
  margin-right: 2px;
  width: max-content;
  text-transform: uppercase;
`

export const SegmentStatus = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
`
