import { MediaLayout } from '@etta/ui/media-layout'
import { ETicketReceiptModal } from './e-ticket-receipt-modal'
import { ETicketReceiptContent } from './e-ticket-receipt-content'
import { DisplayNone } from './e-ticket-receipt-modal.styled'
import type { Props } from './types'

export function ETicketReceipt(props: Props) {
  return (
    <MediaLayout
      mobileSlot={
        <DisplayNone>
          <ETicketReceiptContent {...props} />
        </DisplayNone>
      }
      desktopSlot={<ETicketReceiptModal {...props} />}
    />
  )
}
