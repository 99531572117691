import { useLocation } from 'react-router-dom'
import type * as H from 'history'
import { useCallback } from 'react'
import {
  tripReviewItineraryIdSelector,
  tripReviewStatusSelector,
} from '@fiji/store/trip-review-params'
import { useAppSelector } from '@fiji/store'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useLatestRoute } from './use-latest-route'

const EXPLORE_PATH = ROUTES.explore

export function useExplore() {
  const { pathname } = useLocation()
  const { usedPath } = useLatestRoute(EXPLORE_PATH)
  const tripReviewStatus = useAppSelector(tripReviewStatusSelector)
  const tripReviewItineraryId = useAppSelector(tripReviewItineraryIdSelector)
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const tripReviewLink = ROUTES.reviewTrip.main({ itineraryId: tripReviewItineraryId })

  const isExploreActiveHandle = useCallback(
    (_: unknown, location: H.Location) => {
      const isTripMatch = location.pathname.indexOf(tripReviewLink) === 0
      const isExploreMatch = location.pathname.indexOf(EXPLORE_PATH) === 0

      return isExploreMatch || isTripMatch

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [tripReviewLink],
  )

  if (tripReviewStatus === 'none' || !tripReviewItineraryId) {
    return {
      exploreLink: usedPath,
      isExploreActiveHandle,
    }
  }

  const isInTripReview = pathname.indexOf(tripReviewLink) === 0
  if (isInTripReview) {
    return {
      exploreLink: EXPLORE_PATH,
      isExploreActiveHandle,
    }
  }

  return {
    exploreLink: isMobile ? tripReviewLink : EXPLORE_PATH,
    isExploreActiveHandle,
  }
}
