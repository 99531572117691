import { Action, Inject } from '@etta/di'
import { MultiModalRouteType } from '@etta/modules/multi-modal-search/core'
import type {
  MultiModalRouteValueObject,
  SearchMultiModalFormValueObject,
} from '@etta/modules/multi-modal-search/core'
import { MultiModalSearchStore } from '@etta/modules/multi-modal-search/interface/store/multi-modal-search.store'
import { MultiModalSearchService } from '@etta/modules/multi-modal-search/interface/services/multi-modal-search.service'

@Action()
export class MultiModalSearchActions {
  constructor(
    @Inject() private multiModalSearchStore: MultiModalSearchStore,
    @Inject() private multiModalSearchService: MultiModalSearchService,
  ) {}

  handleSetSearchRideHailForm(form: SearchMultiModalFormValueObject) {
    this.multiModalSearchStore.setSearchMultiModalForm(form)
  }

  handlePunchToGoogleMap(route: MultiModalRouteValueObject) {
    const params = new URLSearchParams()
    params.append('origin', `${route.startLocation.address}`)
    params.append('destination', `${route.endLocation.address}`)
    params.append(
      'travelmode',
      `${route.routeType === MultiModalRouteType.Transit ? `transit` : `driving`}`,
    )

    const googleMapsLink = document.createElement('a')
    googleMapsLink.href = `https://www.google.com/maps/dir/?api=1&${params.toString()}`
    googleMapsLink.target = '_blank'
    googleMapsLink.click()
  }

  handlePunchOutWithSearchData() {
    const searchMultiModalForm = this.multiModalSearchStore.searchMultiModalForm

    if (!searchMultiModalForm) {
      return
    }

    const { originAddress, destinationAddress } = searchMultiModalForm

    const params = new URLSearchParams()
    params.append('origin', `${originAddress}`)
    params.append('destination', `${destinationAddress}`)

    const googleMapsLink = document.createElement('a')
    googleMapsLink.href = `https://www.google.com/maps/dir/?api=1&${params.toString()}`
    googleMapsLink.target = '_blank'
    googleMapsLink.click()
  }

  handleDropStore() {
    this.multiModalSearchStore.dropStore()
  }

  async handleSearchMultiModal() {
    this.multiModalSearchStore.setIsError(false)
    this.multiModalSearchStore.setIsLoading(true)

    const result = await this.multiModalSearchService.loadMultiModal()

    result?.match({
      Ok: (result) => {
        this.multiModalSearchStore.setRouteList(result)
        this.multiModalSearchStore.setIsLoading(false)
      },
      Err: () => {
        this.multiModalSearchStore.setIsError(true)
        this.multiModalSearchStore.setIsLoading(false)
      },
    })
  }
}
