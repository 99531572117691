import { useTranslation } from 'react-i18next'
import { CarPriceBlock } from '../../car-price-block'
import { CarFooterBlock } from '../../car-footer-block'
import { CarInfoBlock } from '../../car-info-block'
import type { CarRentalSegmentMobileLayoutProps } from './types'
import { Container } from './mobile-styled'

export function CarRentalSearchSegmentMobile({
  carRentalTitle,
  priceAriaLabel,
  costLabel,
  perDayLabel,
  handleOpenCarRentalDetails,
  vendor,
  details,
  carImage,
  policy,
  isPreferred,
  carEmission,
  isHybridFuel,
  customBadge,
}: CarRentalSegmentMobileLayoutProps) {
  const { t } = useTranslation()

  return (
    <Container
      onClick={handleOpenCarRentalDetails}
      aria-label={t('SearchSegments.CarRental.TapToDetail')}>
      <CarInfoBlock
        carRentalTitle={carRentalTitle}
        amenities={details}
        vendor={vendor}
        carImage={carImage}
        carEmission={carEmission}
        isHybridFuel={isHybridFuel}
        isPreferred={isPreferred}
        customBadge={customBadge}
      />
      <CarFooterBlock
        policy={policy}
        carEmission={carEmission}
        priceSlot={
          <CarPriceBlock
            priceAriaLabel={priceAriaLabel}
            costLabel={costLabel}
            perDayLabel={perDayLabel}
          />
        }
      />
    </Container>
  )
}
