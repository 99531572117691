import type { CalculateRailEmissionsBatchQuery } from '@fiji/graphql/types'
import {
  CalculateEmissionsBatchResultEquivalenceType,
  CalculateEmissionsRailSegmentTravelClassDto,
} from '@fiji/graphql/types'
import { CalculateEmissionsResultErrorReasonEnum } from '@etta/core/enums/calculate-emissions-result-error.enum'
import { CalculateEmissionsEquivalenceTypeEnum } from '@etta/core/enums/calculate-emissions-equivalence-type.enum'
import type {
  RailEmissionsEntity,
  RailEmissionsResultValueObject,
} from '../../core/value-objects/rail-emissions-result.value-object'
import { RailSegmentTravelClassEnum } from '../../core/enums/rail-segment-travel-class.enum'

export function toRailEmissionValueObject(
  query: CalculateRailEmissionsBatchQuery,
): RailEmissionsResultValueObject {
  const calculateRailEmissionsBatch = query.calculateRailEmissionsBatch

  return {
    averageEmissionsTonnes: calculateRailEmissionsBatch.averageEmissionsTonnes,
    errors: calculateRailEmissionsBatch.errors?.map(
      () => CalculateEmissionsResultErrorReasonEnum.Unknown,
    ),
    rails: calculateRailEmissionsBatch.rails?.map(mapRailEmissionsEntity),
  }
}

function mapRailEmissionsEntity(
  rail: NonNullable<CalculateRailEmissionsBatchQuery['calculateRailEmissionsBatch']['rails']>[0],
): RailEmissionsEntity {
  return {
    customRef: rail.customRef ?? undefined,
    equivalences: rail.equivalences.map((equivalence) => {
      return {
        amount: equivalence.amount,
        type: mapEmissionsBatchResultEquivalenceType(equivalence.type),
        unit: equivalence.unit,
      }
    }),
    tonnesOfEmissions: rail.tonnesOfEmissions,
    travelClass: rail.travelClass ? mapRailSegmentTravelClassEnum(rail.travelClass) : undefined,
  }
}

function mapRailSegmentTravelClassEnum(
  input: CalculateEmissionsRailSegmentTravelClassDto,
): RailSegmentTravelClassEnum {
  switch (input) {
    case CalculateEmissionsRailSegmentTravelClassDto.Business:
      return RailSegmentTravelClassEnum.Business
    case CalculateEmissionsRailSegmentTravelClassDto.Economy:
      return RailSegmentTravelClassEnum.Economy
    case CalculateEmissionsRailSegmentTravelClassDto.First:
      return RailSegmentTravelClassEnum.First
  }
}

function mapEmissionsBatchResultEquivalenceType(
  input: CalculateEmissionsBatchResultEquivalenceType,
): CalculateEmissionsEquivalenceTypeEnum {
  switch (input) {
    case CalculateEmissionsBatchResultEquivalenceType.PlasticBag:
      return CalculateEmissionsEquivalenceTypeEnum.PlasticBag
    case CalculateEmissionsBatchResultEquivalenceType.Seedling:
      return CalculateEmissionsEquivalenceTypeEnum.Seedling
    case CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge:
      return CalculateEmissionsEquivalenceTypeEnum.SmartphoneCharge
  }
}
