import styled from 'styled-components'

export const AmenitiesList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 0;
  margin: 0;
`

export const AmenitiesItem = styled.li`
  display: flex;
  align-items: center;
  flex: 0 1 25%;
`

export const AmenitiesIcon = styled.div`
  margin-right: 10px;
`
