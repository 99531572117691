import { observer } from 'mobx-react-lite'
import type { CarRentalSearchSegmentProps } from './types'
import { CarRentalSearchSegmentLayout } from './layout'
import { useCarRentalSearchSegment } from './use-car-rental-search-segment'

export const CarRentalSearchSegment = observer(function CarRentalSearchSegment({
  details,
  policy,
  rate,
  onCarRentalDetailsOpen,
  vendor,
  pickUp,
  dropOff,
  onAddCar,
  carImage,
  isPreferred,
  isHybridFuel,
  isLoading,
  isAmenitiesLoading,
  safetyCheck,
  carRentalKey,
  isSelectable,
  carEmission,
  customBadge,
  hasServiceFee,
}: CarRentalSearchSegmentProps) {
  const {
    carRentalTitle,
    priceAriaLabel,
    costLabel,
    perDayLabel,
    estimate,
    handleAddCar,
    handleOpenCarRentalDetails,
    carSafetyToggle,
  } = useCarRentalSearchSegment({
    details,
    policy,
    rate,
    onCarRentalDetailsOpen,
    vendor,
    pickUp,
    dropOff,
    onAddCar,
    carRentalKey,
    hasServiceFee,
  })

  return (
    <CarRentalSearchSegmentLayout
      carRentalTitle={carRentalTitle}
      priceAriaLabel={priceAriaLabel}
      costLabel={costLabel}
      perDayLabel={perDayLabel}
      estimate={estimate}
      handleAddCar={handleAddCar}
      handleOpenCarRentalDetails={handleOpenCarRentalDetails}
      carSafetyToggle={carSafetyToggle}
      details={details}
      policy={policy}
      carImage={carImage}
      isPreferred={isPreferred}
      isHybridFuel={isHybridFuel}
      vendor={vendor}
      isLoading={isLoading}
      isAmenitiesLoading={isAmenitiesLoading}
      safetyCheck={safetyCheck}
      isSelectable={isSelectable}
      carEmission={carEmission}
      customBadge={customBadge}
    />
  )
})
