import { PriceChangeType, PriceChangeReason } from '@fiji/graphql/types'
import { PriceChangeTypeValueObject } from '../../core/enums/price-change-type.enum'
import { PriceChangeReasonValueObject } from '../../core/enums/price-change-reason.enum'

export function mapPriceChangeTypeValueObject(
  input?: PriceChangeType | null,
): PriceChangeTypeValueObject {
  switch (input) {
    case PriceChangeType.Decrease:
      return PriceChangeTypeValueObject.Decrease
    case PriceChangeType.Increase:
      return PriceChangeTypeValueObject.Increase
    default:
      return PriceChangeTypeValueObject.Unknown
  }
}

export function mapPriceChangeReasonValueObject(
  input?: PriceChangeReason | null,
): PriceChangeReasonValueObject {
  switch (input) {
    case PriceChangeReason.AgencyConfig:
      return PriceChangeReasonValueObject.AgencyConfig
    case PriceChangeReason.Standard:
      return PriceChangeReasonValueObject.Standard
    default:
      return PriceChangeReasonValueObject.Unknown
  }
}
