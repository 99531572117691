import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  padding: 16px;
`

const Title = styled.div`
  width: 59px;
  height: 11px;
  ${glowingFragment}
`

const LongText = styled.div`
  width: 170px;
  height: 16px;
  margin-top: 14px;
  ${glowingFragment};
`

const ShortText = styled.div`
  width: 125px;
  height: 10px;
  margin-top: 8px;
  ${glowingFragment};
`

const RightBlock = styled.div`
  width: 25%;
  height: 80px;
  padding: 12px 16px 12px 21px;
  ${glowingFragment};
`

export function EcoCheckSummarySkeleton() {
  return (
    <Container>
      <div>
        <Title />
        <LongText />
        <ShortText />
      </div>
      <RightBlock />
    </Container>
  )
}
