import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { AddressInput } from '@fiji/graphql/types'
import { useStoragePlace } from '@fiji/hooks/use-storage-place'
import { Modal } from '@etta/ui/modal'
import { Icon } from '@etta/ui/icon'
import type { Place } from '@fiji/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { SearchMechanism } from '@etta/components/search-mechanism'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { Container, MobileBackButton } from './select-location-modal-styled'
import { useMobilitySuggestedLocations } from './hooks'
import type { SelectionState } from './types'

type Props = {
  isVisible: boolean
  onClose: () => void
  onChange: (place?: Place, address?: AddressInput) => void
  state: SelectionState
}

const i18Base = 'Mobility.SelectLocationModal'

export function SelectLocationModal({ isVisible, onClose, onChange, state }: Props) {
  const { t } = useTranslation()
  const { places } = useStoragePlace('mobility')
  const { suggestedPlaces, isLoading } = useMobilitySuggestedLocations()
  const { searchRideHailActions } = useRideHailSearchContext()
  const screenType = useScreenType()

  const isDesktop = screenType !== ScreenType.Mobile
  const placeholder = state === 'pickUp' ? t(`${i18Base}.PickUpFrom`) : t(`${i18Base}.WhereTo`)

  useEffect(() => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) {
      activeElement.blur()
    }
  }, [])

  return (
    <Modal
      position={isDesktop ? 'right' : 'center'}
      isVisible={isVisible}
      handleModalVisibility={searchRideHailActions.openLeavingBookingFlowDialog}>
      <Container>
        <SearchMechanism
          forceScreenType={ScreenType.Mobile}
          key={'location'}
          useNewLocationSearch={true}
          searchType={'mobility'}
          isModalOpen={isVisible}
          hasCurrentLocationSearch
          currentLocationSearchPrecision={'address'}
          onChange={onChange}
          placeholder={placeholder}
          onModalClose={onClose}
          latestPlaces={places}
          suggestionPlaces={suggestedPlaces}
          isExternalLoading={isLoading}
          customBackButtonSlot={
            <MobileBackButton onClick={onClose}>
              <Icon name={'closePWA'} size="medium" color="mainText" />
            </MobileBackButton>
          }
        />
      </Container>
    </Modal>
  )
}
