import styled, { css } from 'styled-components'
import { subHeadStrong, footnoteStrong, KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 24px;
  padding: 0px 16px;
  flex-wrap: nowrap;
  background-color: transparent;
`

export const Image = styled.img`
  margin-right: 7px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
`

export const Title = styled.p`
  margin: 0;
  ${subHeadStrong};
`

export const Price = styled.p`
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteStrong};
`

export const FilterButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  width: 226px;
  flex-shrink: 0;
  padding: 14px 18px;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: background-color 0.3s;

  ${KeyboardNavigationStyle}
  & + & {
    margin-left: 18px;
  }
  ${(props) =>
    !props.isActive &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.borderLight};
      }
    `};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.mainText};
      color: ${theme.colors.mainText2};
      border-color: ${theme.colors.mainText};
      ${Price} {
        color: ${theme.colors.mainText2};
      }
    `}
`
