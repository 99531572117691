import type { GetPasswordSecurityConfigureQuery } from '@fiji/graphql/hooks'
import type { PasswordSecurityConfigureEntity } from '@etta/modules/user'

export class PasswordMapper {
  static toPasswordSecurityConfigurationEntity(
    configure: GetPasswordSecurityConfigureQuery['passwordSecurityConfigure'],
  ): PasswordSecurityConfigureEntity {
    return {
      dictionaryWordsNotAllowed: configure.dictionaryWordsNotAllowed,
      forceAlphaNumericPassword: configure.forceAlphaNumericPassword,
      passwordMinLength: configure.passwordMinLength,
      passwordReusability: configure.passwordReusability,
    }
  }
  static toChangePasswordErrorCode(errorCode: string): string {
    switch (errorCode) {
      case 'HAS_NAME':
      case 'HAS_USER_NAME':
      case 'HAS_EMAIL_ADDRESS':
        return 'isContainName'
      case 'IS_NOT_ALPHANUMERIC':
        return 'isAlphanumeric'
      case 'IS_TOO_SHORT':
        return 'isMinLength'
      case 'IS_TOO_RECENT':
        return 'isRecently'
      case 'PASSWORD_MISMATCH':
        return 'isPasswordMatch'
      case 'HAS_DICTIONARY_WORD':
        return 'isDictionary'
      case 'HAS_NO_SPECIAL_CHARACTERS':
        return ''
      default:
        return ''
    }
  }
}
