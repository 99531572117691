import { Skeleton } from '@etta/ui/skeleton'
import { Container } from './mobility-itinerary-skeleton-styled'

export function MobilityItinerarySkeleton() {
  return (
    <Container>
      <Skeleton width={328} height={79} variant="secondary">
        <rect x={0} y={0} width={24} height={24} />
        <rect x={34} y={4} width={268} height={14} />
        <rect x={34} y={27} width={150} height={14} />
        <rect x={34} y={47} width={140} height={14} />
      </Skeleton>
    </Container>
  )
}
