import type { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import type { Delegate } from '@fiji/graphql/types'
import { DelegateItem } from '@etta/components/delegate-item/delegate-item'
import { DelegateSwitchLayout } from '@fiji/enums'
import { TitleSection, TitleDate, HeadingSection, Heading } from './trip-header-styled'

type Props = {
  tripName: string
  titleDates: string
  onEdit: () => void
  variant: 'topDate' | 'topHeading'
  isEditable: boolean
  bookingRef?: string
  containerRef?: RefObject<HTMLDivElement>
  isShowDelegateName?: boolean
  currentDelegatedUser?: Delegate
}

export function TripHeader({
  tripName,
  titleDates,
  onEdit,
  variant,
  isEditable,
  bookingRef,
  isShowDelegateName,
  currentDelegatedUser,
  containerRef,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.'

  return (
    <TitleSection ref={containerRef}>
      <Block paddingHorizontal={16}>
        {variant === 'topDate' && <TitleDate aria-label={titleDates}>{titleDates}</TitleDate>}
        <HeadingSection aria-label={tripName}>
          <Heading data-tracking-id="trip-destination-text">{tripName}</Heading>
          {isEditable && (
            <IconButton
              onClick={onEdit}
              icon="editPWA"
              size="medium"
              data-tracking-id="trip-review-edit-trip-name-button"
              aria-label={t('Accessibility.EditTripName')}
            />
          )}
        </HeadingSection>
        {variant === 'topHeading' && (
          <Block marginTop={4}>
            <TitleDate data-tracking-id="trip-date-text" aria-label={titleDates}>
              {titleDates}
            </TitleDate>
          </Block>
        )}
        {isShowDelegateName && (
          <Block marginTop={12}>
            <DelegateItem
              delegate={currentDelegatedUser}
              layout={DelegateSwitchLayout.TripDetails}
            />
          </Block>
        )}
        {bookingRef && (
          <Block marginTop={8}>
            <Text variant="captionStrongCaps" color="bodyText1">
              {t(`${i18nBase}BookingRef`, { bookingRef })}
            </Text>
          </Block>
        )}
        {isShowDelegateName && (
          <Block marginTop={16}>
            <Separator />
          </Block>
        )}
      </Block>
    </TitleSection>
  )
}
