import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'
import { screenSize } from '@fiji/style'

const loadingOuterOval = css`
  &::before {
    content: '';
    width: 700px;
    height: 700px;
    position: absolute;
    top: calc(50% - 700px / 2);
    left: calc(50% - 700px / 2);
    background: radial-gradient(
      50% 50% at 48% 50%,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.25) 82%,
      rgba(16, 24, 32, 0.25) 100%
    );
    mix-blend-mode: normal;
    opacity: 0.08;
    border-radius: 50%;
  }
`
const loadingInnerOval = css`
  &::after {
    content: '';
    width: 490px;
    height: 490px;
    position: absolute;
    top: calc(50% - 490px / 2);
    left: calc(50% - 490px / 2);
    background: radial-gradient(
      50% 50% at 48% 50%,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.25) 82%,
      rgba(16, 24, 32, 0.25) 100%
    );
    mix-blend-mode: normal;
    opacity: 0.15;
    border-radius: 50%;
  }
`

export const LoadingContainer = styled.div`
  flex: 1;
  padding: 30px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${loadingOuterOval}
  ${loadingInnerOval}
`
export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  @media (${screenSize.maxMobile}) {
    align-items: flex-start;
  }
`

export const MismatchedSegmentsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const ConfirmButton = styled(Button)`
  min-width: 212px;
`
