import styled from 'styled-components'
import { MediaLayout } from '@etta/ui/media-layout'
import { Spinner } from '@etta/ui/spinner'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'

export const Wrapper = styled.div`
  padding: 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  gap: 8px;
`

const SpinnerElement = styled(Spinner).attrs(() => ({
  type: 'spinout',
  size: 'normal',
}))`
  align-items: flex-start;
  max-width: fit-content;
`

const DesktopIcon = styled(Icon).attrs(() => ({
  name: 'infoOutlinePWA',
}))``

export const IconElement = () => {
  return <MediaLayout mobileSlot={<SpinnerElement />} desktopSlot={<DesktopIcon />} />
}

const TextMobile = styled(Text).attrs((p) => ({
  variant: 'footnoteStrong',
  ...p,
}))``

const DesktopText = styled(TextMobile)`
  display: flex;
  height: 24px;
  align-items: center;
`

export const TextElement = (props: any) => {
  return (
    <MediaLayout mobileSlot={<TextMobile {...props} />} desktopSlot={<DesktopText {...props} />} />
  )
}

const BlockMobile = styled(Block).attrs(() => ({
  paddingHorizontal: 16,
  marginTop: 4,
  marginBottom: 2,
}))``

const BlockDesktop = styled(Block).attrs(() => ({
  paddingHorizontal: 16,
  marginTop: 24,
  marginBottom: 4,
}))``

export const BlockElement = (props: any) => {
  return (
    <MediaLayout
      mobileSlot={<BlockMobile {...props} />}
      desktopSlot={<BlockDesktop {...props} />}
    />
  )
}
