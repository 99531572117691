import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropDownList } from '@etta/ui/drop-down-list'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import { getDropDownProps } from '@etta/screens/traveler-info/purchase-user-info/user-info-form/layout/desktop/get-drop-down-props'
import { ShareTravelerContact } from '@etta/modules/travel-preferences/core/enums/share-traveler-contact.enum'

const i18nBase = 'TravelerInfo.ShareTravelerContact.DropDownLabels.'

export const ShareTravelerContactForm = observer(function ShareTravelerContactForm() {
  const { t } = useTranslation()
  const { travelPreferencesStore } = useTravelPreferencesContext()
  const { shareTravelerContact } = travelPreferencesStore

  const options = [
    { label: t(`${i18nBase}ProvideAll`), value: ShareTravelerContact.PROVIDE_ALL },
    { label: t(`${i18nBase}ProvidePhone`), value: ShareTravelerContact.PROVIDE_PHONE },
    { label: t(`${i18nBase}ProvideEmail`), value: ShareTravelerContact.PROVIDE_EMAIL },
    { label: t(`${i18nBase}ProvideNone`), value: ShareTravelerContact.PROVIDE_NONE },
  ]

  useEffect(() => {
    if (shareTravelerContact === null) {
      travelPreferencesStore.setShareTravelerContact(ShareTravelerContact.PROVIDE_ALL)
    }
  }, [shareTravelerContact, travelPreferencesStore])

  const [selectedOption, setSelectedOption] = useState<string>(
    shareTravelerContact || ShareTravelerContact.PROVIDE_ALL,
  )

  return (
    <DropDownList
      data-tracking-id={'show-share-traveler-contact-dropdown-tracking-id'}
      label={''}
      value={[selectedOption]}
      {...getDropDownProps({
        fieldName: 'shareTravelerContact',
        options: options,
        onFieldChange: (_: any) => (fieldValue: any) => {
          setSelectedOption(fieldValue)
          travelPreferencesStore.setShareTravelerContact(fieldValue)
        },
      })}
      withNoneOption={false}
    />
  )
})
