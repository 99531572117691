import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import { SiteSsoSettingsErrors } from '../core/errors/site-sso-settings.errors'
import type { SiteSsoSettingsErrorsInstances } from '../core/errors/site-sso-settings.errors'
import type { SiteSsoSettingsValueObject } from '../core/value-objects/site-sso-settings.value-object'
import { SiteSsoSettingsDataProvider } from './site-sso-settings.data-provider/site-sso-settings.data-provider'
import { SiteSsoSettingsMapper } from './mappers/site-sso-settings-mapper'

@Adapter()
export class SiteSsoSettingsAdapter {
  constructor(
    @Inject()
    private siteSsoSettingsDataProvider: SiteSsoSettingsDataProvider,
  ) {}

  async getSiteSsoSettings(): PromisedResult<
    SiteSsoSettingsValueObject,
    SiteSsoSettingsErrorsInstances
  > {
    try {
      const { data, error } = await this.siteSsoSettingsDataProvider.getSiteSsoSettings()

      if (error) {
        return Result.Err(new SiteSsoSettingsErrors.GetSiteSsoSettingsResponseError(error))
      }

      const settings = SiteSsoSettingsMapper.toSiteSsoSettingsValueObject(data)

      if (!settings) {
        return Result.Err(new SiteSsoSettingsErrors.GetSiteSsoSettingsMappingError(error))
      }

      return Result.Ok(settings)
    } catch (e) {
      return Result.Err(new SiteSsoSettingsErrors.GetSiteSsoSettingsUnexpectedError(e))
    }
  }
}
