// it can be any function thats why wrapped as Function type
import type { KeyboardEvent } from 'react'

export function useA11yKeydown(cb?: Function) {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>, value?: any) => {
    const isCurrentElementActive = e.currentTarget === document.activeElement
    if (e.code === 'Enter' && isCurrentElementActive) {
      cb?.(value)
    }
  }

  return {
    handleKeyDown,
  }
}
