import { useEffect, useState } from 'react'
import { useNotifications } from '@fiji/hooks/use-notifications'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { useUserContext } from '@etta/modules/user'
import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'

export function useEttaLoader() {
  const { userStore } = useUserContext()
  const { featureFlagsAction } = useFeatureFlagsContext()
  const { partnerCoBrandingConfigurationAction } = usePartnerCoBrandingConfigurationsContext()
  const { homeAirportActions, homeAirportStore } = useAirSearchFormContext()

  const { companyId, locale } = userStore
  useNotifications({ locale })
  const [isFeatureFlagsLoading, setLoadingState] = useState(false)

  useEffect(() => {
    const handleLoad = async () => {
      if (!companyId) {
        return
      }
      setLoadingState(true)
      await Promise.all([
        featureFlagsAction.loadFlagsById(companyId),
        partnerCoBrandingConfigurationAction.loadPartnerCoBrandingConfigurationsBySiteId(companyId),
      ])
      setLoadingState(false)
    }
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCoBrandingConfigurationAction, companyId])

  useEffect(() => {
    homeAirportActions.load()
  }, [homeAirportActions])

  return {
    isHomeAirportLoading: homeAirportStore.isHomeAirportLoading,
    isFeatureFlagsLoading,
  }
}
