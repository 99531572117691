import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CarRentalSearchButtonMobile } from './mobile'
import { CarRentalSearchButtonDesktop } from './desktop'

export function CarRentalSearchButton(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalSearchButtonMobile {...props} />}
      desktopSlot={<CarRentalSearchButtonDesktop {...props} />}
    />
  )
}
