import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'

export const DetailsContainer = styled.div<{ color: keyof ColorPalette }>`
  background-color: ${({ color, theme }) => theme.colors[color]};
  padding: 8px 28px 40px 24px;
`

export const TripDetailContainer = styled.div`
  margin-bottom: 16px;
  padding-top: 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
  background-color: ${(p) => p.theme.colors.background};
`
