import styled from 'styled-components'
import { Button as EttaButton } from '@etta/ui/button'

export const Wrapper = styled.div`
  width: 320px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
`

export const Body = styled.div`
  padding: 26px 24px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Footer = styled.div`
  padding: 19px 24px 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Button = styled(EttaButton)`
  max-height: 44px;
  margin-top: 20px;
`

export const DueTodayCostContainer = styled.div`
  margin-top: 10px;
  margin-left: -5px;
`
