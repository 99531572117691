import { observer } from 'mobx-react-lite'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { AddExpenseCodeMemoLayout } from './layout'

export const AddExpenseCodeMemo = observer(function AddExpenseCodeMemo() {
  const {
    addExpenseCodeMemoActions,
    addExpenseCodeMemoStore,
    expenseFlowStore,
  } = useRideHailSearchContext()

  return (
    <AddExpenseCodeMemoLayout
      onBack={addExpenseCodeMemoActions.handleBack}
      onContinue={addExpenseCodeMemoActions.handleContinue}
      onMemoValueChanged={addExpenseCodeMemoActions.handleMemoValueChanged}
      isMemoRequired={expenseFlowStore.expenseConfig.memoRequired}
      maxMemoLength={addExpenseCodeMemoStore.maxMemoLength}
      memoValue={addExpenseCodeMemoStore.memoValue}
      isContinueButtonDisabled={addExpenseCodeMemoStore.isContinueButtonDisabled}
    />
  )
})
