import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { FlightDetailsHeaderMobile } from './mobile'
import { FlightDetailsHeaderDesktop } from './desktop'

export function FlightDetailsHeaderLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<FlightDetailsHeaderMobile {...props} />}
      desktopSlot={<FlightDetailsHeaderDesktop {...props} />}
    />
  )
}
