import type { RideHailLocation } from '@fiji/graphql/hooks'
import type { RideHailLocationValueObject } from '@etta/modules/ride-hail/core'

export function buildRideHailLocation(input: RideHailLocationValueObject): RideHailLocation {
  return {
    address: input.address,
    geocode: input.geocode,
    place: input.place,
  }
}
