import { observer } from 'mobx-react-lite'
import type { RailLegType } from '@fiji/enums'
import { useRailSearchResultPage } from './use-rail-search-result-page'
import { RailSearchResultProvider } from './rail-search-result-page-provider'
import { RailSearchResultPageContent } from './rail-search-result-page-content'

type Props = {
  railLegType: RailLegType
}

export const RailSearchResultPage = observer(function RailSearchResultPage({ railLegType }: Props) {
  const value = useRailSearchResultPage({ railLegType })

  return (
    <RailSearchResultProvider value={value}>
      <RailSearchResultPageContent railLegType={railLegType} />
    </RailSearchResultProvider>
  )
})
