import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import defaultBackground from './background.svg?url'
import { Container, Image } from './background-styled'

const i18nBase = 'TripDetails'

type Props = {
  url?: string | null
}

export const Background = observer(function Background({ url }: Props) {
  const { postBookingTripPreviewStore } = usePostBookingContext()
  const { cityImageUrl } = postBookingTripPreviewStore.tripPreview
  const imageUrl = url || cityImageUrl || defaultBackground
  const { t } = useTranslation()

  if (!imageUrl) {
    return null
  }

  return (
    <Container>
      <Image src={imageUrl} alt={t(`${i18nBase}.CityImage`)} />
    </Container>
  )
})
