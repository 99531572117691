import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container } from './webfare-note-styled'

export function WebfareNote() {
  const { t } = useTranslation()

  return (
    <Container>
      <Block marginRight={5}>
        <Icon name="infoFilledPWA" color="warning" size="small" />
      </Block>
      <Text variant="footnoteMedium" color="bodyText">
        {t('PostBooking.TripDetails.WebFareNote')}
      </Text>
    </Container>
  )
}
