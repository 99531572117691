import styled from 'styled-components'
import { boldWeightText, headline } from '@fiji/style'
import { slideUp } from './slide-up-transition'

export const Container = styled.div<{
  isShown: boolean
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
`

export const DialogContainerRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 20;
  padding-bottom: 187px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${slideUp};
`

export const DialogContainer = styled.div`
  margin: 0 20px;
  min-width: 0;
  width: 352px;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  background: ${(p) => p.theme.colors.white};
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 41px;
`

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

export const HeaderSection = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Header = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  ${headline};
  ${boldWeightText};
`
export const SegmentSection = styled.div`
  padding: 0 20px;
  overflow-y: auto;
`
