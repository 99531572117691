import type { ReactNode } from 'react'

export const getHoursTitle = (value: number) => {
  if (value === 0) {
    return '12am'
  }
  if (value < 12) {
    return `${value}am`
  }
  if (value === 12) {
    return `12pm`
  }
  return `${value - 12}pm`
}

type DistanceProps = {
  value: number
  maxValue: number
  minValue: number
  distanceUnit?: string
  maxDistance?: number
}

const DEFAULT_MIN_DISTANCE = 0.25
const DEFAULT_MAX_DISTANCE = 20

export const getDistanceTitle = ({
  value,
  maxValue,
  minValue,
  distanceUnit = 'mi',
  maxDistance,
}: DistanceProps) => {
  if (value === minValue) {
    return `${DEFAULT_MIN_DISTANCE} ${distanceUnit}`
  }
  if (value === maxValue) {
    return `${maxDistance || DEFAULT_MAX_DISTANCE} ${distanceUnit}`
  }
}

type Args = {
  markValue: number
  markTitleType?: 'time' | 'distance-edge'
  min: number
  max: number
  distanceUnit?: string
  maxDistance?: number
}

export function calculateMarkTitle({
  markValue,
  markTitleType,
  min,
  max,
  distanceUnit,
  maxDistance,
}: Args): ReactNode {
  switch (markTitleType) {
    case 'time':
      return getHoursTitle(markValue)
    case 'distance-edge':
      return getDistanceTitle({
        value: markValue,
        minValue: min,
        maxValue: max,
        distanceUnit,
        maxDistance,
      })
    default:
  }
}
