import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'
import type { ButtonProps } from '@etta/ui/button'
import type { PopoverPosition } from './types'

const positionStyle = css<{ position: PopoverPosition }>`
  position: absolute;
  left: 50%;
  right: 0;
  bottom: ${({ position }) => (position ? position.bottom : 0)}px;
  transform: translateX(-50%);
`

const visibleStyle = css<{ isVisible: boolean }>`
  opacity: 1;
  visibility: unset;
`

export const Container = styled.div<{ position: PopoverPosition; isVisible: boolean }>`
  display: flex;
  width: 295px;
  padding: 8px;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.background2};
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.12);

  ${({ position }) => position && positionStyle};
  ${({ isVisible }) => isVisible && visibleStyle}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  flex: 1;
  padding: 16px;
  padding-right: 0;
`

export const SelectButton = styled(Button).attrs<ButtonProps, ButtonProps>((props) => ({
  ...props,
  size: 'small',
}))`
  margin-top: 12px;
  align-self: baseline;
`
