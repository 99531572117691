import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { PlainTime as Time } from '@fiji/types'

type TitleFormatter = (date: Date, isMobile?: boolean) => string
type TimeTitleFormatter = (time: Time, isMobile?: boolean) => string

type UseDateTimeControlsParams = {
  date?: Date | null
  time?: Time | null
  dateTitle?: string
  timeTitle?: string
  dateFormatter: TitleFormatter
  timeFormatter: TimeTitleFormatter
  isNowSelected?: boolean
}

export function useDateTimeControlsLabels({
  date,
  time,
  dateTitle,
  timeTitle,
  dateFormatter,
  timeFormatter,
  isNowSelected,
}: UseDateTimeControlsParams) {
  const { t } = useTranslation()
  const type = useScreenType()
  const dateText = dateTitle ?? t('DatePicker.Start')
  const timeText = timeTitle ?? t('DatePicker.Time')
  const timeNow = t('DatePicker.Now')
  const isMobile = type === ScreenType.Mobile

  const dateTitleValue = date ? dateFormatter(date, isMobile) : dateText
  const timeTitleValue = time ? timeFormatter(time, isMobile) : timeText
  const dateAriaLabel = date
    ? t('DatePicker.AriaLabel', { label: dateText, value: dateTitleValue })
    : dateText
  const timeAriaLabel = time
    ? t('DatePicker.AriaLabel', { label: timeText, value: timeTitleValue })
    : timeText

  return {
    dateTitleValue,
    timeTitleValue: isNowSelected ? timeNow : timeTitleValue,
    dateAriaLabel,
    timeAriaLabel,
  }
}
