import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { SupportContactGetHelpNowImage } from '../../support-contact-get-help-now-image'
import {
  GetHelpNowWrapper,
  InformationContainer,
  ImageContainer,
  TextWrapper,
} from './support-contact-get-help-now-desktop-styled'

export function SupportContactGetHelpNowDesktop() {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support.MainPage.GetHelpNow'
  const {
    featureFlags: { isNewHelpCenterLinkEnabled },
  } = useFeatureFlags()
  const { userStore } = useUserContext()
  const identityHash = userStore.profile?.identityHash
  const siteID = userStore.companyId
  const siteName = encodeURIComponent(userStore.profile?.site?.name || '')

  const domain = isNewHelpCenterLinkEnabled
    ? 'https://new-deem.document360.io'
    : 'https://new-etta.document360.io'

  return (
    <GetHelpNowWrapper>
      <InformationContainer>
        <Icon name="helpIconPWA" color="primary" size={'large'} />
        <TextWrapper>
          <Text variant="subHeadStrong">{t(i18Base + '.DescriptionDesktop')}</Text>
          <Link href={`${domain}/?h=${identityHash}&si=${siteID}&sn=${siteName}`} target="_blank">
            {t(i18Base + '.ButtonLabel')}
            <Icon name="openWindowPWA" />
          </Link>
        </TextWrapper>
      </InformationContainer>
      <ImageContainer>
        <SupportContactGetHelpNowImage />
      </ImageContainer>
    </GetHelpNowWrapper>
  )
}
