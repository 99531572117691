import type {
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  HotelSegmentEntity,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { SegmentType } from '@fiji/graphql/types'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { ItineraryRules } from '../rules-and-restriction.types'
import { getFlightRules } from './get-flight-rules'
import { getHotelRules } from './get-hotel-rules'
import { formatAirRules } from './format-air-rules'
import { getCarRentalRules } from './get-car-rental-rules'
import { isInternationalTrip } from './is-international-trip'

type Props = {
  segments: TripSegmentValueObject[] | SegmentPostBookingValueObject[]
}

export function useRulesAndRestrictions({ segments }: Props) {
  const rulesAndRestrictionsToggle = useTogglePopup()
  const itineraryRules = getRules(segments)
  const showRulesAndRestrictions = Object.values(itineraryRules).some(
    (ruleSet) => ruleSet.length > 0,
  )

  return {
    itineraryRules,
    rulesAndRestrictionsToggle,
    showRulesAndRestrictions,
  }
}

function getRules(segments: TripSegmentValueObject[] | SegmentPostBookingValueObject[]) {
  const itineraryRules: ItineraryRules = {
    flight: [],
    hotel: [],
    carRental: [],
  }
  let isAirInternational = true

  segments.forEach((segment) => {
    if (segment.type === SegmentType.Hotel) {
      const hotelRules = getHotelRules(segment as HotelSegmentEntity)
      if (hotelRules) {
        itineraryRules.hotel.push(hotelRules)
      }
    }

    if (segment.type === SegmentType.Flight) {
      const flightRules = getFlightRules(segment as FlightSegmentEntity)
      if (flightRules) {
        itineraryRules.flight.push(flightRules)
      }

      if (isAirInternational) {
        isAirInternational = isInternationalTrip(segment as FlightSegmentEntity)
      }
    }

    if (segment.type === SegmentType.CarRental) {
      const carRentalRules = getCarRentalRules(segment as CarRentalSegmentEntity)
      if (carRentalRules) {
        itineraryRules.carRental.push(carRentalRules)
      }
    }
  })

  return {
    ...itineraryRules,
    flight: formatAirRules(itineraryRules.flight, isAirInternational),
  }
}
