export default function AppleMostPreferredIconFilled() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white" />
      <path
        d="M14.931 17.727H5.05837C3.24403 17.727 2.27148 16.7626 2.27148 14.9565V5.03483C2.27148 3.23684 3.24403 2.27246 5.05837 2.27246H14.931C16.7535 2.27246 17.726 3.23684 17.726 5.03483V14.9565C17.726 16.7545 16.7535 17.727 14.931 17.727ZM9.13719 13.8777C9.45592 13.8777 9.72562 13.7142 9.91359 13.4363L13.5668 7.80535C13.6812 7.62555 13.7711 7.42124 13.7711 7.23326C13.7711 6.79194 13.3788 6.48955 12.962 6.48955C12.6841 6.48955 12.4471 6.63666 12.2592 6.93905L9.11267 11.9489L7.67428 10.1427C7.46996 9.89756 7.26564 9.79949 7.01229 9.79949C6.57097 9.79949 6.21954 10.1509 6.21954 10.5922C6.21954 10.7966 6.29309 10.9845 6.44838 11.1807L8.32809 13.4445C8.55693 13.7305 8.81845 13.8777 9.13719 13.8777Z"
        fill="currentColor"
      />
    </svg>
  )
}
