import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import { usePlacesStorageContext } from '@etta/modules/places-storage/interface/use-places-storage.context'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { PresearchMultiLayerCostAllocation } from '../multi-layer-cost-allocation'
import { useSearchHotelsForm } from './hooks'
import { HotelSearchFormLayout } from './layout'

export const HotelSearchForm = observer(function HotelSearchForm() {
  const { getRecentSearchesActions } = useRecentSearchesContext()
  const { hotelSearchFormStore, hotelSearchFormActions } = useHotelSearchFormContext()
  const { costAllocationFormStore } = useCostAllocationContext()

  const costAllocationValidationErrors = costAllocationFormStore.validationErrors.costAllocation

  useEffect(() => {
    getRecentSearchesActions.getRecentHotelSearches()
  }, [getRecentSearchesActions])

  useEffect(() => {
    getRecentSearchesActions.getRecentHotelSearchesWithoutDates()
  }, [getRecentSearchesActions])

  const {
    handleSearch,
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  } = useSearchHotelsForm()

  const { checkInDate, checkOutDate, location } = hotelSearchFormStore.hotelForm
  const { handleChangeLocation, handleDateChange, onDistanceChange } = hotelSearchFormActions
  const { placesStorageStore } = usePlacesStorageContext()
  const placesHistory = placesStorageStore.getPlaces('hotel')

  return (
    <>
      <HotelSearchFormLayout
        location={location}
        handleLocationChange={handleChangeLocation}
        placesHistory={placesHistory}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        onDateChange={handleDateChange}
        onDistanceChange={onDistanceChange}
        handleSearch={handleSearch}
        costAllocationSlot={
          <PresearchMultiLayerCostAllocation error={costAllocationValidationErrors} />
        }
      />

      <PreSearchCustomFields
        customFields={customFields}
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onBack={onPreSearchBack}
        isVisible={isOpenPreSearchCustomFields}
        onSubmit={onSubmitCustomFieldsHandler}
      />
    </>
  )
})
