import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { AddNewCardContainer, AddNewCardTitle, CardIconWrapper } from './add-new-card-button-styled'

type Props = {
  title: string
  onClick: () => void
}

export const AddNewCardButton = ({ title, onClick }: Props) => {
  const { t } = useTranslation()

  return (
    <AddNewCardContainer>
      <CardIconWrapper>
        <Icon name="cardPWA" size="medium" />
      </CardIconWrapper>
      <AddNewCardTitle>{title}</AddNewCardTitle>
      <Block marginLeft="auto">
        <Button size="small" onClick={onClick}>
          {t('CreditCardItem.SelectCard')}
        </Button>
      </Block>
    </AddNewCardContainer>
  )
}
