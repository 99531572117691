import { DataProvider } from '@etta/di'
import type {
  ApplyUnusedTicketMutation,
  ApplyUnusedTicketMutationVariables,
  RemoveUnusedTicketMutation,
  RemoveUnusedTicketMutationVariables,
  UnusedTicketInput,
} from '@fiji/graphql/hooks'
import { ApplyUnusedTicketDocument, RemoveUnusedTicketDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class UnusedTicketOptProvider extends GraphqlDataProvider {
  applyUnusedTicket(input: UnusedTicketInput) {
    return this.client.mutate<ApplyUnusedTicketMutation, ApplyUnusedTicketMutationVariables>({
      mutation: ApplyUnusedTicketDocument,
      fetchPolicy: 'no-cache',
      variables: { input },
    })
  }

  removeUnusedTicket(input: UnusedTicketInput) {
    return this.client.mutate<RemoveUnusedTicketMutation, RemoveUnusedTicketMutationVariables>({
      mutation: RemoveUnusedTicketDocument,
      fetchPolicy: 'no-cache',
      variables: { input },
    })
  }
}
