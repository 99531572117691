import { Block } from '@etta/ui/block'
import { Tabs } from '@etta/ui/tabs'
import { Separator } from '@etta/ui/separator'
import { StickyContainer } from '@etta/ui/sticky-container'
import type { LayoutProps } from '../../types'
import { StyledTab } from './rail-tabs-block-desktop-styled'

export function RailTabsBlockDesktop({ activeTab, onTabChange, tabs }: LayoutProps) {
  return (
    <Tabs onChangeTab={onTabChange} activeTab={activeTab}>
      <StickyContainer>
        <Block paddingHorizontal={32}>
          <Tabs.Header>
            {tabs.map((tab, index) => (
              <StyledTab key={index} value={tab.value} aria-label={tab.label}>
                {tab.label}
              </StyledTab>
            ))}
          </Tabs.Header>
        </Block>
        <Block marginTop={-1}>
          <Separator />
        </Block>
      </StickyContainer>
    </Tabs>
  )
}
