import type { AirportAddress, TripCost } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { SEPARATOR } from './constants'

type TripData = {
  name?: string | null
  flightNumber?: number | null
  departure?: AirportAddress | null
  departureDate?: string | null
  travelerSeat?: string | null
  arrival?: AirportAddress | null
  arrivalDate?: string | null
  tripCost?: TripCost | null
}

type Props = {
  segments?: TripData[] | null
}

function formatDate(date: string) {
  return dateFormat(new Date(deleteTimezone(date)), 'EEE, MMM d')
}

function formatTime(date: string) {
  return dateFormat(new Date(deleteTimezone(date)), 'h:mm b')
}

export function postBookingFlight({ segments }: Props) {
  if (!segments) {
    return undefined
  }
  return segments
    ?.map(
      ({
        name,
        flightNumber,
        departure,
        departureDate,
        travelerSeat,
        arrival,
        arrivalDate,
        tripCost,
      }) =>
        `
${name || ''} # ${flightNumber || ''}
${
  departure
    ? `Depart ${departure.airportCode || ''} ${departureDate ? formatDate(departureDate) : ''}`
    : ''
}
${departureDate ? `Scheduled: ${formatTime(departureDate)}` : ''}
Seat: ${travelerSeat || 'unassigned'}
${
  arrival ? `Arrive ${arrival.airportCode || ''} ${arrivalDate ? formatDate(arrivalDate) : ''}` : ''
}
${arrivalDate ? `Scheduled: ${formatTime(arrivalDate)}` : ''}
${
  tripCost
    ? `Cost: ${tripCost?.total?.primary.amount || ''} ${tripCost?.total?.primary?.currency || ''}`
    : ''
}
`.trim(),
    )
    .join(`\n${SEPARATOR}\n`)
}
