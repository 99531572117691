import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { ListHeader, ListHeaderRightSide } from './headers-styled'

type Props = {
  label?: string
  selectedNumber: number
  isQuickFilterDesign?: boolean
}

const i18Base = 'QuickFilters.'

export function HeaderSelected({ label, selectedNumber, isQuickFilterDesign }: Props) {
  const { t } = useTranslation()

  return (
    <ListHeader isInlineView isBorder={!isQuickFilterDesign}>
      <Text variant="subHeadStrong">{label}</Text>
      {!!selectedNumber && (
        <ListHeaderRightSide>
          {!!selectedNumber && (
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18Base + 'Selected', { selectedNumber })}
            </Text>
          )}
        </ListHeaderRightSide>
      )}
    </ListHeader>
  )
}
