import { SwipingDatePicker, SwipingTimePicker } from '@etta/ui/swiping-time-date-picker'
import { TextField } from '@etta/ui/text-field'
import { Select } from '@etta/ui/select'
import { Swap } from '@etta/ui/swap'
import { PickerType } from '../../../../types'
import type { LayoutProps } from '../../types'

export const DateFieldMobile = ({
  helperText,
  label,
  value,
  onChangeHandler,
  GMT_ARRAY,
  pickerType,
  handleOpen,
  isOpen,
  handleClose,
  formatValue,
  handleApplyTime,
  handleOnApplyDate,
  datePickerTypes,
  yearBounds,
}: LayoutProps) => {
  if (pickerType === PickerType.TIMEZONE) {
    return (
      <Select
        label={label}
        value={value as string}
        options={GMT_ARRAY}
        onChange={onChangeHandler}
        helperType="error"
        helperText={helperText}
        withNoneOption={false}
      />
    )
  }

  return (
    <div onClick={handleOpen}>
      <Swap
        is={datePickerTypes.includes(pickerType)}
        isSlot={
          <SwipingDatePicker
            isVisible={isOpen}
            onClose={handleClose}
            onApply={handleOnApplyDate}
            label={label}
            variant={pickerType}
            yearBounds={yearBounds}
          />
        }>
        <SwipingTimePicker
          isVisible={isOpen}
          onClose={handleClose}
          onApply={handleApplyTime}
          label={label}
          variant={pickerType}
        />
      </Swap>
      <TextField
        label={label}
        value={formatValue(value)}
        helperText={helperText}
        helperType="error"
        isDisabled
      />
    </div>
  )
}
