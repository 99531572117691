import { isSameYear } from '@fiji/utils/dates/is-same-year'
import { isSameMonth } from '@fiji/utils/dates/is-same-month'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import { isSameDay } from '@fiji/utils/dates/is-same-day'

const DEFAULT_DATE_FORMAT = 'EEE, MMM dd, yyyy'

export function getDateRangeTitle(startDate?: string, endDate?: string | null) {
  if (!startDate) {
    return ''
  }
  const _startDate = isoToDate(deleteTimezone(startDate))
  const _endDate = endDate ? isoToDate(deleteTimezone(endDate)) : undefined
  if (!_startDate && !_endDate) {
    return ''
  }
  if (!_startDate || !_endDate) {
    return dateFormat((_startDate || _endDate)!, DEFAULT_DATE_FORMAT)
  }
  if (isSameDay(_startDate, _endDate)) {
    return dateFormat(_startDate, DEFAULT_DATE_FORMAT)
  }
  const startDateFormat = isSameYear(_startDate, _endDate) ? 'EEE, MMM dd' : DEFAULT_DATE_FORMAT
  const lastDateFormat = dateFormat(
    _endDate,
    isSameMonth(_startDate, _endDate) ? 'EEE, MMM dd, yyyy' : DEFAULT_DATE_FORMAT,
  )

  return `${dateFormat(_startDate, startDateFormat)} - ${lastDateFormat}`
}
