import { ApolloLink } from '@apollo/client'
import { cleanupWithRedirectToLogin } from '@fiji/providers/auth-provider'
import { checkIsCookieExpired } from './check-is-cookie-expired'

const SKIPPED_OPERATION_NAMES = [
  'login',
  'getFeatureFlags',
  'logEvent',
  'ClearSiteRcSessionCookie',
  'ClearDomainRcSessionCookie',
  'partnerCoBrandingConfiguration',
  'clearMeetingInfo',
]

export const tokenProcessLink = new ApolloLink((operation, forward) => {
  if (SKIPPED_OPERATION_NAMES.includes(operation.operationName)) {
    return forward(operation)
  }

  const isCookieExpired = checkIsCookieExpired()

  // Log out when token is expired
  if (isCookieExpired) {
    // TODO: remove logout call after correct pass token for tests
    cleanupWithRedirectToLogin()

    return forward(operation)
  }

  return forward(operation)
})
