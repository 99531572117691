import { useRef } from 'react'

type Props = {
  isExpanded: boolean
  expandedRef?: React.MutableRefObject<HTMLDivElement | null>
}
export function useExpand({ isExpanded, expandedRef }: Props) {
  const collapsedRef = useRef<HTMLDivElement | null>(null)
  const expandedHeight = expandedRef?.current?.clientHeight || 0
  const minHeight = isExpanded ? 0 : expandedHeight

  return { minHeight, collapsedRef }
}
