import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { UserInformationStore } from '@etta/modules/user/interface/stores/user-information.store'
import { UserInformationActions } from '@etta/modules/user/interface/actions/user-information-actions'
import { EmployeeInfoStore } from '@etta/modules/user/interface/stores/employee-info.store'
import { EmployeeInfoActions } from '@etta/modules/user/interface/actions/employee-info.actions'
import { CreatePasswordActions } from '@etta/modules/user/interface/actions/create-password.actions'
import { CreatePasswordStore } from '@etta/modules/user/interface/stores/create-password.store'
import { UserStore, UserActivationStore } from './stores'
import { UserActions, UserActivationActions } from './actions'
import { BusinessContactStore } from './stores/business-contact.store'
import { BusinessContactActions } from './actions/business-contact.actions'
import { UserActivationPaginationStore } from './stores/user-activation-pagination.store'
import { PaymentInfoStore } from './stores/payment-info.store'
import { PaymentInfoActions } from './actions/payment-info.actions'
import { UserProfileActions } from './actions/user-profile.actions'
import { UserProfileStore } from './stores/user-profile.store'
import { TravelPreferencesActions } from './actions/travel-preferences.actions'
import { TravelPreferencesStore } from './stores/travel-preferences.store'
import { UserActivationPaginationAction } from './actions/user-activation-pagination.action'

export const { useModuleContext: useUserContext } = hookContextFactory({
  userStore: UserStore,
  userActions: UserActions,
  userActivationStore: UserActivationStore,
  userActivationActions: UserActivationActions,
  businessContactStore: BusinessContactStore,
  businessContactActions: BusinessContactActions,
  userInformationStore: UserInformationStore,
  userInformationActions: UserInformationActions,
  employeeInfoStore: EmployeeInfoStore,
  employeeInfoActions: EmployeeInfoActions,
  userActivationPaginationStore: UserActivationPaginationStore,
  paymentInfoStore: PaymentInfoStore,
  paymentInfoActions: PaymentInfoActions,
  createPasswordActions: CreatePasswordActions,
  createPasswordStore: CreatePasswordStore,
  userProfileActions: UserProfileActions,
  userProfileStore: UserProfileStore,
  travelPreferencesActions: TravelPreferencesActions,
  travelPreferencesStore: TravelPreferencesStore,
  userActivationPaginationAction: UserActivationPaginationAction,
})
