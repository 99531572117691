import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Container, WarningText } from './mobility-pickup-confirmation-dialog-error-styled'

export function MobilityPickupConfirmationDialogError() {
  const i18nBase = 'Mobility.ConfirmationPage.'
  const { t } = useTranslation()

  return (
    <Container>
      <Icon name="noRideResultPWA" size={84} />
      <WarningText>{t(i18nBase + 'UnexpectedErrorMessage')}</WarningText>
    </Container>
  )
}
