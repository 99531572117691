import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Block } from '@etta/ui/block'
import { Tabs } from '@etta/ui/tabs'
import type { CovidStatistic } from '../../../types'
import { CovidInformationStatistic } from '../covid-information-statistic'
import { HeaderWrapper } from './covid-tabs-styled'

type Props = {
  covidStatistic?: CovidStatistic
}

const i18nBase = 'HotelSafetyCheck.CovidInformation.CovidInformationContent'

export function CovidTabs({ covidStatistic }: Props) {
  const { t } = useTranslation()
  const { county: district, state, country } = covidStatistic || {}
  const i18nStatistic = `${i18nBase}.CovidInformationStatistic`

  const [activeTab, setActiveTab] = useState(
    (district && 'district') || (state && 'state') || (country && 'country') || '',
  )

  return (
    <Tabs activeTab={activeTab} onChangeTab={setActiveTab}>
      <HeaderWrapper>
        <Tabs.Header tabBasis={110}>
          {district && (
            <Tabs.Tab
              aria-label={t(`${i18nBase}.TabVoice`, { tab: t(`${i18nBase}.County`) })}
              value="district">
              {t(`${i18nBase}.County`)}
            </Tabs.Tab>
          )}
          {state && (
            <Tabs.Tab
              aria-label={t(`${i18nBase}.TabVoice`, { tab: t(`${i18nBase}.State`) })}
              value="state">
              {t(`${i18nBase}.State`)}
            </Tabs.Tab>
          )}
          {country && (
            <Tabs.Tab
              aria-label={t(`${i18nBase}.TabVoice`, { tab: t(`${i18nBase}.Country`) })}
              value="country">
              {t(`${i18nBase}.Country`)}
            </Tabs.Tab>
          )}
        </Tabs.Header>
      </HeaderWrapper>

      <Block marginTop={16}>
        <Tabs.Panel value="district">
          {district && (
            <CovidInformationStatistic i18nBase={i18nStatistic} covidStatistic={district} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="state">
          {state && <CovidInformationStatistic i18nBase={i18nStatistic} covidStatistic={state} />}
        </Tabs.Panel>
        <Tabs.Panel value="country">
          {country && (
            <CovidInformationStatistic i18nBase={i18nStatistic} covidStatistic={country} />
          )}
        </Tabs.Panel>
      </Block>
    </Tabs>
  )
}
