/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { useUserContext } from '@etta/modules/user'
import { formatCardNumber } from '@fiji/utils/credit-card/format-card-number'
import type { AppState } from '@fiji/store'
import type { CreditCardFormData } from '../types'
import { useBillingAddressValidator } from './use-billing-address-validator'
import { useCreditCardValidator } from './use-credit-card-validator'
import { getCardBrandType } from './get-card-brand-type'

type Args = {
  onSubmit: (values: CreditCardFormData) => void
  creditCardFormData?: Partial<CreditCardFormData>
  isBillingAddressRequired?: boolean
  isOpen: boolean
  isEditableCreditCardSingleUse?: boolean
}

export function useCreditCard({
  onSubmit,
  creditCardFormData = {},
  isBillingAddressRequired,
  isOpen,
  isEditableCreditCardSingleUse,
}: Args) {
  const { userStore } = useUserContext()
  const { isCreditCardSavingEnabled } = useSelector((state: AppState) => state.displayConfiguration)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isCardNumberFieldEdited, setIsCardNumberFieldEdited] = useState(false)
  const { creditCardData, billingAddress, isCvvRequired } = creditCardFormData
  const { creditCardValidator } = useCreditCardValidator({
    isCvvRequired,
    creditCardData,
    isCardNumberFieldEdited,
  })

  const { billingAddressValidator } = useBillingAddressValidator({
    billingAddress,
  })

  const {
    onFieldChange: onAddressFieldChange,
    errors: addressErrors,
    values: addressValues,
  } = billingAddressValidator
  const {
    onFieldChange: onCardFieldChange,
    values: cardValues,
    errors: cardErrors,
  } = creditCardValidator

  const cardBrandType = getCardBrandType({
    cardNumber: cardValues.cardNumber,
    brandType: creditCardData?.brandType,
    isCardNumberFieldEdited,
  })

  const [isOneTimeUseCard, setIsOneTimeUseCard] = useState(!isCreditCardSavingEnabled)
  const [shouldSaveGuestCard, setShouldSaveGuestCard] = useState(false)

  const handleChangeOneTimeUseCard = (value: boolean) => {
    setIsOneTimeUseCard(value)
  }

  const handleChangeShouldSaveGuestCard = (value: boolean) => {
    setShouldSaveGuestCard(value)
  }

  function formatExpirationDate(expirationDate: string = '', separator = '') {
    const [month, year] = expirationDate.split('/').map((date) => date.trim())
    return `${month}${separator}${year?.slice(-2)}`
  }

  const formatSingleUseCardExpirationDate = (expirationDate: string = '') => {
    const [month, year] = expirationDate.split('/').map((date) => date.trim())
    const fullDate = new Date(`${month}/01/${year}`)
    return dateFormat(fullDate, 'yyyy-MM-dd')
  }
  async function handleSubmit() {
    const submitCardData = await creditCardValidator.submit()
    const submitAddressData = await billingAddressValidator.submit()
    const { expirationDate } = cardValues
    const formattedExpirationDate = formatExpirationDate(expirationDate)
    const isSingleUseCard = (userStore.isGuest && !shouldSaveGuestCard) || isOneTimeUseCard
    const singleUseCardExpirationDate =
      isSingleUseCard || isEditableCreditCardSingleUse
        ? formatSingleUseCardExpirationDate(expirationDate)
        : ''

    const expirationDateResult =
      isSingleUseCard || isEditableCreditCardSingleUse
        ? singleUseCardExpirationDate
        : formattedExpirationDate
    if (isBillingAddressRequired && !submitAddressData.isValid) {
      return
    }
    if (!submitCardData.isValid) {
      return
    }
    setIsSubmitLoading(true)
    onSubmit({
      creditCardData: {
        ...cardValues,
        brandType: cardBrandType,
        expirationDate: expirationDateResult,
      },
      billingAddress: isBillingAddressRequired ? addressValues : undefined,
      isOneTimeUseCard: isSingleUseCard || isEditableCreditCardSingleUse,
    })
    setIsSubmitLoading(false)
  }

  const cardNumber = isCardNumberFieldEdited
    ? cardValues.cardNumber
    : formatCardNumber(cardValues.cardNumber)

  function handleSetCardNumberFieldEdited(value: boolean) {
    setIsCardNumberFieldEdited(value)
  }

  useEffect(() => {
    creditCardValidator.reset()
    billingAddressValidator.reset()
    handleSetCardNumberFieldEdited(false)
  }, [isOpen])

  return {
    handleChangeShouldSaveGuestCard,
    shouldSaveGuestCard,
    isOneTimeUseCard: isOneTimeUseCard,
    handleChangeOneTimeUseCard,
    handleSubmit,
    isSubmitLoading,
    isCvvRequired,
    isSingleUseCreditCardAllowed: creditCardFormData.isOneTimeUseCard,
    cardValues,
    addressValues,
    cardErrors,
    addressErrors,
    onCardFieldChange,
    onAddressFieldChange,
    cardNumber,
    isCardNumberFieldEdited,
    handleSetCardNumberFieldEdited,
    isSavingCreditCardEnabled: isCreditCardSavingEnabled,
  }
}
