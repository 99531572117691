import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { RailTransportationMode, SeatReservationType } from '@fiji/graphql/types'
import type { TrainSegmentSeatInfo } from '@fiji/graphql/types'
import type { AllocatedSeat } from '@etta/screens/payment-summary/types'
import { TrainServiceClass } from '@fiji/enums'

type Props = {
  departDate: Date
  arrivalDate: Date
  serviceClass?: string
  allocatedSeat: AllocatedSeat | null
  railSeatReservationType?: SeatReservationType
  transportationMode?: RailTransportationMode
}

const i18nBase = 'PurchaseReservationSuccess.'

export function useRailRow({
  departDate,
  arrivalDate,
  serviceClass,
  allocatedSeat,
  railSeatReservationType,
  transportationMode,
}: Props) {
  const { t } = useTranslation()

  const isDifferentMonths = departDate.getMonth() !== arrivalDate.getMonth()
  const dateLabel = `${dateFormat(departDate, 'MMM dd, h:mm aaa')} - ${dateFormat(
    arrivalDate,
    isDifferentMonths ? 'MMM dd, h:mm aaa' : 'h:mm aaa',
  )}`

  const hasAllocatedSeat = allocatedSeat && allocatedSeat.length > 0

  const isTubeSegment = transportationMode === RailTransportationMode.Unspecified

  const isBusOrFerrySegment =
    transportationMode === RailTransportationMode.Bus ||
    transportationMode === RailTransportationMode.Ferry

  const validateSeatAndCarriageNumber = (id: string) => id.trim().replace(/^0+/, '')

  const checkSeatInfo = useMemo(() => {
    switch (railSeatReservationType) {
      case SeatReservationType.None:
        if (serviceClass === TrainServiceClass.FirstClass) {
          return t(i18nBase + 'FirstClassTicketInfo')
        }
        return t(i18nBase + 'StandartClassTicketInfo')

      case SeatReservationType.Optional:
        if (serviceClass === TrainServiceClass.FirstClass) {
          return t(i18nBase + 'FirstClassTicketInfoWithoutSeatReservation')
        }
        return t(i18nBase + 'StandartClassTicketInfoWithoutSeatReservation')

      case SeatReservationType.Mandatory:
        if (isTubeSegment) {
          return t(i18nBase + 'FirstClassTicketInfo')
        }

        if (isBusOrFerrySegment) {
          return t(i18nBase + 'FirstClassTicketInfoWithoutSeatReservation')
        }

        return ''
      default:
        return ''
    }
  }, [isBusOrFerrySegment, isTubeSegment, railSeatReservationType, serviceClass, t])

  const allocatedSeatItem = useCallback(
    (item: Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>) => {
      return t(i18nBase + 'RailSeatAndCarriage', {
        carriage: validateSeatAndCarriageNumber(item.carriage),
        seat: validateSeatAndCarriageNumber(item.seat),
      })
    },
    [t],
  )

  return {
    dateLabel,
    checkSeatInfo,
    hasAllocatedSeat,
    allocatedSeatItem,
    validateSeatAndCarriageNumber,
  }
}
