import styled from 'styled-components'

export const Content = styled.div<{ isWideContainer: boolean }>`
  display: flex;
  width: ${({ isWideContainer }) => (isWideContainer ? '960px' : '1080px')};
  align-items: flex-start;
  margin: 41px auto 0;

  > :first-child {
    flex: 1;
  }
`

export const SideBarContent = styled.div`
  width: 320px;
  margin-left: 40px;
`
