import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { Maybe } from '@fiji/graphql/types'
import { StyledButton } from './check-in-button-styled'

type Props = {
  airlineUrl?: Maybe<string>
  isAvailable: boolean
  onClick: (airlineUrl: string) => void
}

const i18nBase = 'PostBooking.FlightDetails'

export function CheckInButton({ airlineUrl, isAvailable, onClick }: Props) {
  const { t } = useTranslation()
  const isDisabled = !isAvailable || !airlineUrl
  const handleClick = useCallback(() => !isDisabled && onClick(airlineUrl), [
    isDisabled,
    onClick,
    airlineUrl,
  ])
  const ariaLabel = !isDisabled
    ? t(i18nBase + '.Button', { label: t(i18nBase + '.CheckInAvailable') })
    : t(i18nBase + '.ButtonDisabled', { label: t(i18nBase + '.CheckIn') })

  return (
    <StyledButton
      variant="text"
      size="small"
      disabled={isDisabled}
      textOverflow={false}
      onClick={handleClick}
      aria-label={ariaLabel}
      data-tracking-id="check-in-airline-button">
      {!isDisabled ? t(i18nBase + '.CheckInAvailable') : t(i18nBase + '.CheckIn')}
    </StyledButton>
  )
}
