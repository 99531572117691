import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { subHeadStrong, captionMedium, screenSize } from '@fiji/style'

export const Label = styled(Text)<{ isTextAlignRight?: boolean }>`
  ${captionMedium}
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: ${({ isTextAlignRight }) => (isTextAlignRight ? 'right' : 'left')};
`

export const Value = styled(Text).attrs(() => ({
  variant: 'subHeadStrong',
}))<{ shouldTruncateValueAt?: number }>`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.mainText};

  ${(p) =>
    p.shouldTruncateValueAt &&
    css`
      max-width: ${p.shouldTruncateValueAt}ch;
    `}
`

const wrapperShouldFlatBorderRight = css`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`

const wrapperShouldFlatBorderLeft = css`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`

export const Placeholder = styled(Text).attrs(() => ({
  variant: 'subHeadStrong',
  color: 'bodyText1',
}))`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ButtonWrapper = styled.button<{
  isOnlyLabel?: boolean
  isTextAlignRight?: boolean
  buttonMaxWidth?: number
  disabled?: boolean
  shouldLeftBorderBeFlat?: boolean
  shouldRightBorderBeFlat?: boolean
  isAlignCenter?: boolean
  isTransparentBackground?: boolean
}>`
  width: 100%;
  ${({ buttonMaxWidth }) =>
    buttonMaxWidth &&
    css`
      max-width: ${buttonMaxWidth}px;
    `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 12px 16px;
  transition: border-color 0.2s;
  transition-timing-function: ease-out;
  cursor: pointer;
  ${({ shouldLeftBorderBeFlat }) => shouldLeftBorderBeFlat && wrapperShouldFlatBorderLeft}
  ${({ shouldRightBorderBeFlat }) => shouldRightBorderBeFlat && wrapperShouldFlatBorderRight}
  ${(props) =>
    props.disabled
      ? css`
          background-color: ${props.theme.colors.background4};
          opacity: 0.4;
        `
      : css`
          background-color: ${({ theme }) => theme.colors.background};
        `}

  &:hover {
    border-color: ${({ theme, disabled }) => (disabled ? 'none' : theme.colors.primary)};
  }

  ${(props) =>
    props.isTransparentBackground &&
    css`
      background-color: transparent;
    `}

  ${({ isOnlyLabel }) =>
    isOnlyLabel &&
    css`
      justify-content: center;

      ${Label} {
        ${subHeadStrong}
        color: ${({ theme }) => theme.colors.bodyText1};
      }
    `}
  ${({ isTextAlignRight }) =>
    isTextAlignRight &&
    css`
      align-items: flex-end;

      ${Value} {
        text-align: right;
      }
    `} @media (${screenSize.minTablet}) {
    &:focus-visible {
      position: relative;
      outline: 2px solid ${({ theme }) => theme.colors.primary};
      outline-offset: 1px;
      z-index: 10;
      border-radius: 8px;
    }
  }
  ${({ isAlignCenter }) =>
    isAlignCenter &&
    css`
      align-items: center;
    `}
`

const wrapperErrorBorder = css`
  border-radius: 8px;
  outline: 1px solid ${(p) => p.theme.colors.error};
  outline-offset: -1px;
`

export const Wrapper = styled.div<{
  hasError: boolean
  shouldErrorMessageLeftBorderBeFlat?: boolean
  shouldErrorMessageRightBorderBeFlat?: boolean
}>`
  ${({ hasError }) => hasError && wrapperErrorBorder}
  ${({ hasError, shouldErrorMessageLeftBorderBeFlat }) =>
    hasError && shouldErrorMessageLeftBorderBeFlat && wrapperShouldFlatBorderLeft}
  ${({ hasError, shouldErrorMessageRightBorderBeFlat }) =>
    hasError && shouldErrorMessageRightBorderBeFlat && wrapperShouldFlatBorderRight}
  position: relative;
  width: 100%;
  height: 100%;
`

export const ErrorMessage = styled(Text).attrs({ variant: 'captionMedium' })`
  position: absolute;
  color: ${(p) => p.theme.colors.error};
  bottom: -20px;
`
