import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Container } from './technical-stop-label-styled'

type Props = {
  isFullWidth: boolean
}

export const TechnicalStopLabel = ({ isFullWidth }: Props) => {
  const { t } = useTranslation()

  return (
    <Container isFullWidth={isFullWidth}>
      <Block marginRight={6}>
        <Icon name="infoOutlinePWA" color="bodyText" />
      </Block>
      <Text variant="footnoteMedium">{t('SearchSegments.Stops.TechnicalStopLayover')}</Text>
    </Container>
  )
}
