import Container from 'typedi'
import { GraphqlCache } from '@etta/worker/graphql-cache'
import { appMode } from '@fiji/modes'
import { Store } from '@etta/worker/graphql-cache/store'
import { SafeLogger } from '@fiji/utils/safe-logger'
import { getCustomLogoutRedirect } from '@fiji/utils/custom-logout-redirect'
import { ActiveTripStore } from '@etta/modules/review-trip/interface/stores/active-trip.store'

// TODO: add broadcast message to service worker for flush graphql cache, instead create new instance
const graphqlCache = new GraphqlCache({
  store: new Store(),
  logger: new SafeLogger('graphql-cache:main-thread'),
})

type Props = {
  isCustomLogoutRedirectEnabled?: boolean
  logoutSsoUrl?: string
  companyId?: number
}

const FULL_PWA_LOGIN_ROUTE = `/app/login`
const FIJI_LOGIN_ROUTE = '/rc/login/main.do'

export function cleanupWithRedirectToLogin(props?: Props) {
  const { logoutSsoUrl, isCustomLogoutRedirectEnabled, companyId } = props || {}

  window.heap?.resetIdentity?.()

  // clean up active trip
  const activeStore = Container.get(ActiveTripStore)
  activeStore.clearStore()
  activeStore.dropCurrentlyReactivatedID()

  graphqlCache.clearCache()
  if (isCustomLogoutRedirectEnabled && logoutSsoUrl) {
    window.location.replace(logoutSsoUrl)
    return
  }

  if (isCustomLogoutRedirectEnabled) {
    const customLoginPath = getCustomLogoutRedirect(companyId)
    if (customLoginPath) {
      window.location.replace(customLoginPath)
      return
    }
  }

  const currentLocation = window.location.toString()
  if (currentLocation.includes('app/login')) {
    return
  }

  const loginRoute = appMode.isFijiAll ? FIJI_LOGIN_ROUTE : FULL_PWA_LOGIN_ROUTE
  const newPath = window.location.origin + loginRoute

  if (window.location.toString() !== newPath) {
    window.location.replace(newPath)
  }
}
