import { getCalculateEmissionsDeviation } from './get-calculate-emissions-deviation'

type Props = {
  tonnesOfEmissions: number
  averageEmissionsTonnes: number
}

export function useSegmentDetailsEcoCheckModal({
  tonnesOfEmissions,
  averageEmissionsTonnes,
}: Props) {
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  const tonnesToKg = (tonnes: number) => Number((tonnes * 1000).toFixed(2))

  return {
    kgOfEmissions: tonnesToKg(tonnesOfEmissions),
    averageEmissionsKg: tonnesToKg(averageEmissionsTonnes),
    deviationLevel,
    percentDeviation,
  }
}
