import { useTranslation } from 'react-i18next'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { SearchTravelPolicyButton } from '@etta/components/search-travel-policy-button/travel-policy-button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { SearchResultFiltersApplied } from '@etta/components/search-result-filters-applied'
import type { LayoutProps } from '../types'
import { ContentHeader, GrayDash, SortBlock } from './list-result-header-desktop-styled'

export function ListResultHeaderDesktop({
  title,
  dataTrackingId,
  sortSlot,
  rightSlot,
  flightDateLabel,
  flightDate,
  isLoading,
  numberOfAppliedFilters,
  isAppliedFiltersVisible,
  isShowDelegateName,
  hasServiceFeeConfiguration,
  onAppliedFiltersClick,
  policyDialogOnOpen,
  taxesDialogOnOpen,
  isTravelPolicyVisible,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <ContentHeader>
      <Text
        as={'h2'}
        id={dataTrackingId || `search-header-title`}
        data-tracking-id={dataTrackingId || `search-header-title`}
        variant="title3"
        aria-label={title}>
        {title}
      </Text>
      <GrayDash />
      {flightDate && !isLoading ? (
        <>
          <Text variant="subHeadStrong" color="bodyText" aria-label={flightDateLabel}>
            {flightDate}
          </Text>
          <GrayDash />
        </>
      ) : null}
      {isShowDelegateName && (
        <>
          <DelegateName isDesktopMode />
          <GrayDash />
        </>
      )}
      {isTravelPolicyVisible && (
        <SearchTravelPolicyButton
          dataTrackingId="travel-policy-button"
          onClick={policyDialogOnOpen}
        />
      )}
      {hasServiceFeeConfiguration && (
        <Button
          aria-label={t('Accessibility.TapToViewWithLabel', {
            label: t('SearchSegments.TaxesAndFees'),
          })}
          data-tracking-id="taxes-and-fees-button"
          variant="text"
          size="normal"
          paddings={false}
          onClick={taxesDialogOnOpen}>
          {t('SearchSegments.TaxesAndFees')}
        </Button>
      )}
      <SortBlock marginLeft="auto" data-tracking-id="sort-button">
        {rightSlot}
        <SearchResultFiltersApplied
          isVisible={Boolean(isAppliedFiltersVisible)}
          numberOfAppliedFilters={numberOfAppliedFilters}
          onAppliedFiltersClick={onAppliedFiltersClick}
        />
        {sortSlot && <Block>{sortSlot}</Block>}
      </SortBlock>
    </ContentHeader>
  )
}
