import type { CarRentalFilters } from '@fiji/graphql/types'

export function getSortedFilters(filters?: CarRentalFilters) {
  return {
    transmission: filters?.transmission
      ? [...filters?.transmission].sort((a, b) => +b.selected - +a.selected)
      : [],
    carClass: filters?.class ? [...filters?.class].sort((a, b) => +b.selected - +a.selected) : [],
    supplier: filters?.vendor ? [...filters?.vendor].sort((a, b) => +b.selected - +a.selected) : [],
    fuel: filters?.fuel ? [...filters.fuel].sort((a, b) => +b.selected - +a.selected) : [],
  }
}
