import { Store } from '@etta/di'
import type { RailEmissionsResultValueObject } from '../../core/value-objects/rail-emissions-result.value-object'

@Store()
export class RailEmissionsStore {
  private _railEmissions: RailEmissionsResultValueObject | null = null
  private _isLoading: boolean = false

  get isLoading(): boolean {
    return this._isLoading
  }

  get railEmissions(): RailEmissionsResultValueObject | null {
    return this._railEmissions
  }

  setIsLoading(value: boolean) {
    this._isLoading = value
  }

  setRailEmissions(value: RailEmissionsResultValueObject | null) {
    this._railEmissions = value
  }
}
