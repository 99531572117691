import { Action, Inject } from '@etta/di'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import { RecentSearchesStore } from '@etta/modules/recent-searches/interface/stores/recent-searches.store'
import { GetRecentSearchesActions } from '@etta/modules/recent-searches/interface/actions/get-recent-searches.action'
import { DelegateStore } from '../stores/delegate.store'
import { DelegateTokenService } from '../services/delegate-token.service'
import { DelegateService } from '../services/delegate.service'

const ORIGINAL_CUSTOMER_ID = 0

@Action()
export class DelegateActions {
  constructor(
    @Inject() private delegateStore: DelegateStore,
    @Inject() private delegateTokenService: DelegateTokenService,
    @Inject() private delegateService: DelegateService,
    @Inject() private userStore: UserStore,
    @Inject() private recentSearchesStore: RecentSearchesStore,
    @Inject() private getRecentSearchesActions: GetRecentSearchesActions,
  ) {}

  setDelegateId(value: number) {
    this.delegateStore.setDelegateId(value)
  }

  dropDelegateState() {
    this.delegateStore.dropDelegateState()
  }

  dropDelegateId() {
    this.delegateStore.dropDelegateId()
  }

  async handleSelectDelegate(id: number) {
    if (id === this.userStore.delegatedId) {
      return
    }
    try {
      const newId = this.getIdForDelegateUpdate(id)

      this.delegateStore.setIsUserInfoUpdating(true)

      const isUpdateTokenSuccess = await this.updateDelegateToken(newId)

      if (!isUpdateTokenSuccess) {
        return
      }

      if (!this.recentSearchesStore.isRecentSearchDisabled) {
        this.getRecentSearchesActions.getRecentCarRentalSearches()
        this.getRecentSearchesActions.getRecentFlightSearches()
        this.getRecentSearchesActions.getRecentHotelSearches()
        this.getRecentSearchesActions.getRecentRailSearches()
      }

      await this.delegateService.loadDelegateContext()

      this.delegateService.clearStates()

      this.delegateStore.delegateModalToggle.handleClose()
    } finally {
      this.delegateStore.setIsUserInfoUpdating(false)
    }
  }

  async updateDelegateToken(id: number) {
    return this.delegateTokenService.updateDelegateTokenCookie(id)
  }

  private getIdForDelegateUpdate(id: number): number {
    if (this.userStore.impersonatedId !== 0 && id === this.userStore.impersonatedId) {
      // a delegater is impersonated
      return ORIGINAL_CUSTOMER_ID
    }

    if (id !== this.userStore.customerId) {
      return id
    }
    return ORIGINAL_CUSTOMER_ID
  }
}
