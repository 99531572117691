import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { CarRulesByTransactionId } from '../../core/car-rental-rules-modal/value-objects/car-rental-rules.value-object'
import { CarRentalRulesErrors } from '../../core/car-rental-rules-modal/errors'
import { CarRentalRulesDataProvider } from './data-provider/car-rental-rules-modal.data-provider'
import type { CarRentalRulesAdapterResult, GetCarRentalRulesArgs } from './types'

@Adapter()
export class CarRentalRulesAdapter {
  constructor(
    @Inject()
    private carRentalRulesDataProvider: CarRentalRulesDataProvider,
  ) {}

  async getCarRentalRules({
    carSegmentId,
    transactionId,
  }: GetCarRentalRulesArgs): CarRentalRulesAdapterResult<CarRulesByTransactionId[]> {
    try {
      const { data, error } = await this.carRentalRulesDataProvider.handleLoadCarRules({
        carSegmentId,
        transactionId,
      })
      if (error) {
        return Result.Err(new CarRentalRulesErrors.LoadCarRentalRulesFail(error))
      }

      return Result.Ok(data.carRulesByTransactionId)
    } catch (e) {
      return Result.Err(new CarRentalRulesErrors.LoadCarRentalRulesFail(e))
    }
  }
}
