import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const ButtonStyled = styled(Button)`
  display: flex;
  margin-left: auto;
  width: 64px;
  height: 100%;
  & > * {
    display: flex;
    justify-content: center;
  }
`
