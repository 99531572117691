import { css } from 'styled-components'

export const CONTENT_TRANSITION_NAME = 'CONTENT_REVIEW_DIALOG'
export const FOOTER_TRANSITION_NAME = 'FOOTER_REVIEW_DIALOG'
const TRANSITION_TIMEOUT = 700

const ANIMATION = 'cubic-bezier(0.4, 0, 0.2, 1)'

export const slideUp = css`
  transition: transform ${TRANSITION_TIMEOUT}ms ${ANIMATION}, opacity ${TRANSITION_TIMEOUT}ms;

  &.${CONTENT_TRANSITION_NAME}-enter, &.${CONTENT_TRANSITION_NAME}-appear {
    transform: translateY(95%);
    opacity: 1;
  }

  &.${CONTENT_TRANSITION_NAME}-appear-done,
    &.${CONTENT_TRANSITION_NAME}-enter-done,
    &.${CONTENT_TRANSITION_NAME}-appear-active,
    &.${CONTENT_TRANSITION_NAME}-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.${CONTENT_TRANSITION_NAME}-exit-active {
    transform: translateY(95%);
    opacity: 1;
  }

  &.${CONTENT_TRANSITION_NAME}-exit-done, &.${CONTENT_TRANSITION_NAME}-exit {
    transform: translateY(95%);
    opacity: 0;
  }
`

export const fadeIn = css`
  transition: ${TRANSITION_TIMEOUT}ms;

  &.${FOOTER_TRANSITION_NAME}-enter, &.${FOOTER_TRANSITION_NAME}-appear {
    opacity: 0;
  }

  &.${FOOTER_TRANSITION_NAME}-appear-done,
    &.${FOOTER_TRANSITION_NAME}-enter-done,
    &.${FOOTER_TRANSITION_NAME}-appear-active,
    &.${FOOTER_TRANSITION_NAME}-enter-active {
    opacity: 1;
  }

  &.${FOOTER_TRANSITION_NAME}-exit-active {
    opacity: 0;
  }

  &.${FOOTER_TRANSITION_NAME}-exit-done, &.${FOOTER_TRANSITION_NAME}-exit {
    opacity: 0;
  }
`
