import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import { ErrorMessage } from '../../error-message'
import {
  Container,
  HeaderTitle,
  Subtitle,
  ActionContainer,
  IconWrapper,
} from './purchase-reservation-error-desktop-styled'

export function PurchaseReservationErrorDesktop({
  labelText,
  titleText,
  messageText,
  messageSecondText,
  messageThirdText,
  errorMsg,
  buttonText,
  onDone,
}: LayoutProps) {
  return (
    <Container aria-label={labelText}>
      <IconWrapper>
        <Icon name="errorPWA" size="large" color="error" />
      </IconWrapper>
      <HeaderTitle role="heading" aria-level={3}>
        {titleText}
      </HeaderTitle>
      <Subtitle>{messageText}</Subtitle>
      <Subtitle>{messageSecondText}</Subtitle>
      <Subtitle>{messageThirdText}</Subtitle>
      <ErrorMessage errorMsg={errorMsg} />
      <ActionContainer>
        <Button fullWidth onClick={onDone}>
          {buttonText}
        </Button>
      </ActionContainer>
    </Container>
  )
}
