import type { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import { Modal } from '@etta/ui/modal'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { CloseButton, ButtonWrapper } from './print-itinerary-modal.styled'
import { PrintItineraryContent } from './print-itinerary-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  trip: TripEntity
  travelerName: string
  handlePrint?: () => void
  printableContentRef?: RefObject<HTMLDivElement>
}

export const PrintItineraryModal = ({
  isOpen,
  onClose,
  trip,
  travelerName,
  handlePrint,
  printableContentRef,
}: Props) => {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Header isMobile>
        <CloseButton icon="closeFilledPWA" size="small" color="mainText1" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <PrintItineraryContent
          trip={trip}
          travelerName={travelerName}
          printableContentRef={printableContentRef}
        />
      </Modal.Body>
      <Modal.Footer padding="normal" withBorder>
        <ButtonWrapper>
          <Button
            size="normal-wide"
            minWidth={13}
            onClick={handlePrint}
            leftIcon={<Icon name="printPWA" />}>
            {t(i18nBase + 'Print')}
          </Button>
        </ButtonWrapper>
      </Modal.Footer>
    </Modal>
  )
}
