import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { SectionTitleDesktop } from './desktop'
import { SectionTitleMobile } from './mobile'

export function ListSectionTitleLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<SectionTitleMobile {...props} />}
      desktopSlot={<SectionTitleDesktop {...props} />}
    />
  )
}
