import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { Props } from './types'
import { DetailsLayout } from './layout'

export const Details = observer(function Details(props: Props) {
  const { postBookingTripStore } = usePostBookingContext()
  const {
    isError,
    isLoading,
    trip,
    groupedSegments,
    failedHotelSegments,
    flightSegments,
    railSegments,
  } = postBookingTripStore
  const { costSummary } = trip

  return (
    <DetailsLayout
      groupedSegments={groupedSegments}
      failedHotelSegments={failedHotelSegments}
      trip={trip}
      costSummary={costSummary}
      isError={isError}
      isLoading={isLoading}
      flightSegments={flightSegments}
      railSegments={railSegments}
      {...props}
    />
  )
})
