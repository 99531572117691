import { EmissionsDeviationLevel } from '@fiji/enums'
import EcoCheckGreenSvg from '../assets/eco-check-green.svg?url'
import EcoCheckAverageSvg from '../assets/eco-check-average.svg?url'
import EcoCheckPolutedSvg from '../assets/eco-check-poluted.svg?url'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}
export function RtpEcoCheckModalImage({ deviationLevel }: Props) {
  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return <img src={EcoCheckAverageSvg} alt="average" />
    case EmissionsDeviationLevel.Less:
      return <img src={EcoCheckGreenSvg} alt="green" />
    case EmissionsDeviationLevel.More:
      return <img src={EcoCheckPolutedSvg} alt="poluted" />
    default:
      return null
  }
}
