import { Modal } from '@etta/ui/modal'
import { SafetyCheckTitle } from '@etta/components/safety-check-title'
import type { ContainerPropsWithChildren } from '../types'
import { InfoWrapper, InfoContent } from './container-styled'

export function Container({ viewType, children, isVisible, onClose }: ContainerPropsWithChildren) {
  if (viewType === 'modal') {
    return (
      <Modal isVisible={isVisible} handleModalVisibility={onClose}>
        <Modal.Title withBorder leftSeparatorWidth={20}>
          <SafetyCheckTitle size="medium" />
        </Modal.Title>
        {children}
      </Modal>
    )
  }

  const isInfoRtpCarDetails = viewType === 'info-rtp-car-details'
  const safetyCheckTitleSize = isInfoRtpCarDetails ? 'small' : 'extra-small'

  return (
    <InfoWrapper isInfoRtpCarDetails={isInfoRtpCarDetails}>
      <InfoContent>
        <SafetyCheckTitle size={safetyCheckTitleSize} />
        {children}
      </InfoContent>
    </InfoWrapper>
  )
}
