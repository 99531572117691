import { PAGE_DEFAULT_ANIMATION_TIMEOUT, TRANSITION_GROUP_TO_NAMES } from '../../page-transitions'
import useGroups from './use-groups'
import useBackDetect from './use-back-detect'
import useTransitionRoute from './use-transition-route'

export default function useNextTransition() {
  const { isRouteChanged, transitionIndex, transitionGroup } = useTransitionRoute()
  const { isBack } = useBackDetect({ transitionIndex, isRouteChanged })
  const { usedTransitionGroup } = useGroups({ transitionGroup, isBack, isRouteChanged })

  const nextTransition = isBack
    ? TRANSITION_GROUP_TO_NAMES[usedTransitionGroup].back
    : TRANSITION_GROUP_TO_NAMES[usedTransitionGroup].front

  return {
    nextTransition,
    timeout: PAGE_DEFAULT_ANIMATION_TIMEOUT,
  }
}
