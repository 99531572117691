import type { PropsWithChildren } from 'react'
import { useContext } from 'react'
import { PageSwitcherContext } from './page-switcher-context'

export type PageProps = PropsWithChildren<{
  pageKey: string
  transitionIndex: number
}>

export function Page({ children, pageKey }: PageProps) {
  const context = useContext(PageSwitcherContext)
  if (pageKey !== context.activePageKey) {
    return null
  }

  return <>{children}</>
}
