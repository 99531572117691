import type { ReactNode } from 'react'
import { Container, Collapsed, Expanded } from './expand-styled'
import { useExpand } from './use-expand'

type Props = {
  collapsedSlot: ReactNode
  expandedSlot: ReactNode
  isExpanded?: boolean
  expandedRef?: React.MutableRefObject<HTMLDivElement | null>
}

export function Expand({ collapsedSlot, expandedSlot, isExpanded = true, expandedRef }: Props) {
  const { collapsedRef, minHeight } = useExpand({
    isExpanded,
    expandedRef,
  })

  return (
    <Container minHeight={minHeight}>
      <Collapsed ref={collapsedRef} isExpanded={isExpanded}>
        {collapsedSlot}
      </Collapsed>
      <Expanded ref={expandedRef} isExpanded={isExpanded}>
        {expandedSlot}
      </Expanded>
    </Container>
  )
}
