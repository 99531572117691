import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Props } from '../types'
import {
  ExplanationContainer,
  Header,
  Description,
} from './unused-ticket-explanation-mobile-styled'

export function UnusedTicketExplanationMobile({ title, description, note, ariaLabel }: Props) {
  return (
    <ExplanationContainer aria-label={ariaLabel}>
      <Header>
        <Icon name="unusedTicketPWA" color="success" size="small" />
        <Text variant="subHeadMedium" color="mainText">
          {title}
        </Text>
      </Header>
      <Description>
        <Text variant="captionMedium" color="bodyText">
          {description}
        </Text>
      </Description>
      <Text variant="captionMedium" color="mainText">
        {note}
      </Text>
    </ExplanationContainer>
  )
}
