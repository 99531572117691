import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { CarRentalDetailsEcoCheckModalMobile } from './mobile'
import { CarRentalDetailsEcoCheckModalDesktop } from './desktop'

export function CarRentalDetailsEcoCheckModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalDetailsEcoCheckModalMobile {...props} />}
      desktopSlot={<CarRentalDetailsEcoCheckModalDesktop {...props} />}
    />
  )
}
