import { useTranslation } from 'react-i18next'
import { ShowMore } from '@etta/ui/show-more'
import { Separator } from '@etta/ui/separator'
import { Block } from '@etta/ui/block'
import type { Props } from '../../types'
import { ExpandedView } from '../../expanded-view'
import { CollapsedView } from '../../collapsed-view'
import { RailTabsBlock } from '../../rail-tabs-block/rail-tabs-block'
import { Container, ShowDetails, ShowDetailsContainer } from './rail-details-legend-desktop-styled'

const i18Base = 'RailDetails'

export function RailDetailsLegendDesktop({ rail, activeTab, onTabChange }: Props) {
  const { t } = useTranslation()
  return (
    <Container>
      <ShowDetailsContainer>
        <ShowMore
          expandedContent={<ExpandedView rail={rail} />}
          notExpandedContent={<CollapsedView rail={rail} />}
          renderButton={(isExpanded, toggleExpansion) => {
            const buttonText = t(
              i18Base + (isExpanded ? '.HideDetailsButton' : '.ShowDetailsButton'),
            )
            const buttonTextAria = t(
              i18Base + (isExpanded ? '.HideDetailsButtonAria' : '.ShowDetailsButtonAria'),
              { label: buttonText },
            )

            return (
              <ShowDetails
                role="button"
                tabIndex={0}
                onClick={toggleExpansion}
                aria-label={buttonTextAria}>
                {buttonText}
              </ShowDetails>
            )
          }}
        />
      </ShowDetailsContainer>
      <Block marginTop={22}>
        <Separator />
      </Block>
      <RailTabsBlock activeTab={activeTab} onTabChange={onTabChange} />
    </Container>
  )
}
