import type { FunctionComponent, ReactNode } from 'react'
import type { Maybe, Rate } from '@fiji/graphql/types'
import {
  Container,
  BillList,
  ServiceTitle,
  Separator,
  SubtotalList,
  PrimarySubtotalListItem,
  StyledIcon,
  SecondarySubtotalListItem,
} from './itinerary-item-styled'
import { useItineraryItem } from './use-itinerary-item'
import { BillListItem } from './bill-list-item'
import { BillListItemTax } from './bill-list-item-tax'

type Props = {
  title: string
  isPaid: boolean
  totalCost?: Maybe<Rate>
  children?: ReactNode
}

type CompoundComponents = {
  BillListItem: typeof BillListItem
  BillListItemTax: typeof BillListItemTax
}

export const ItineraryItem: FunctionComponent<Props> & CompoundComponents = ({
  title,
  isPaid,
  totalCost,
  children,
}) => {
  const { formattedPrimarySubtotal, formattedSecondarySubtotal, totalCostText } = useItineraryItem({
    totalCost,
    isPaid,
  })

  return (
    <Container>
      <ServiceTitle role="heading" aria-level={3}>
        {title}
      </ServiceTitle>
      <BillList>{children}</BillList>
      <Separator />
      <SubtotalList>
        <PrimarySubtotalListItem aria-label={`${totalCostText} ${formattedPrimarySubtotal}`}>
          <span>
            {totalCostText}{' '}
            {isPaid && <StyledIcon name="checkFilledPWA" size="small" color="success" />}
          </span>
          <span>{formattedPrimarySubtotal}</span>
        </PrimarySubtotalListItem>
        <SecondarySubtotalListItem aria-label={`${totalCostText} ${formattedSecondarySubtotal}`}>
          <span>{formattedSecondarySubtotal}</span>
        </SecondarySubtotalListItem>
      </SubtotalList>
    </Container>
  )
}

ItineraryItem.BillListItem = BillListItem

ItineraryItem.BillListItemTax = BillListItemTax
