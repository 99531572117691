import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import { TicketExchangeAcknowledgementMobile } from './layout/mobile/ticket-exchange-acknowledge-mobile'
import { TicketExchangeAcknowledgementDesktop } from './layout/desktop/ticket-exchange-acknowledge-desktop'

export const TicketExchangeAcknowledgement = observer(function TicketExchangeAcknowledgement() {
  return (
    <MediaLayout
      mobileSlot={<TicketExchangeAcknowledgementMobile />}
      desktopSlot={<TicketExchangeAcknowledgementDesktop />}
    />
  )
})
