import type { HotelEmission, CarRentalEmission, FlightEmission, RailEmission } from './types'

type Args = {
  hotelEmissions: HotelEmission[]
  carRentalEmissions: CarRentalEmission[]
  flightEmissions: FlightEmission[]
  railEmissions?: RailEmission[]
}
export function useRtpEcoCheckModal({
  carRentalEmissions,
  flightEmissions,
  railEmissions,
  hotelEmissions,
}: Args) {
  const isHotelOnly =
    flightEmissions.length === 0 && carRentalEmissions.length === 0 && railEmissions?.length === 0

  const isRailOnly =
    flightEmissions.length === 0 && carRentalEmissions.length === 0 && hotelEmissions?.length === 0

  return {
    showSummaryDeviationLevel: isHotelOnly || !isRailOnly,
    isHotelOnly,
  }
}
