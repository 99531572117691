import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { ShowMoreContainer } from '@etta/ui/show-more-container'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { IconButton } from '@etta/ui/icon-button'
import type { Props } from '../../types'
import { useUnusedTicket } from '../../use-unused-ticket'
import {
  AirlineLogo,
  AirlineLogoWrapper,
  Content,
  Headline,
  TicketDetails,
  Wrapper,
  TicketDetail,
} from './unused-ticket-mobile-styled'

const i18nBase = 'UnusedTickets.Ticket'
export const UnusedTicketMobile = observer(function UnusedTicketMobile({ unusedTicket }: Props) {
  const { t } = useTranslation()
  const { isOpen, handleToggle } = useTogglePopup()
  const {
    ticketDetails,
    statusColor,
    statusLabel,
    expiresInColor,
    expirationLabel,
  } = useUnusedTicket({
    unusedTicket,
  })
  return (
    <Wrapper>
      <AirlineLogoWrapper>
        <AirlineLogo src={unusedTicket.airline?.imageUrl ?? ''} />
      </AirlineLogoWrapper>
      <Content>
        <Headline>
          <Text variant="body" color="mainText">
            {unusedTicket.airline?.value}
          </Text>
          <Block>
            <IconButton
              onClick={handleToggle}
              icon={isOpen ? 'chevronUpPWA' : 'chevronDownPWA'}
              color="mainText"
              size="small"
            />
          </Block>
        </Headline>
        <Text variant="captionStrongCaps" color={statusColor}>
          {statusLabel}
        </Text>
        <Text isBlock variant="footnoteMedium" color={expiresInColor}>
          {expirationLabel}
        </Text>
        <ShowMoreContainer
          isAnimated
          isExpanded={isOpen}
          collapsedSlot={<></>}
          expandedSlot={
            <TicketDetails>
              {ticketDetails.map((ticketDetail, key) => (
                <TicketDetail aria-label={t(`${i18nBase}.${ticketDetail.title}`)} key={key}>
                  <Text variant="footnoteMedium" color="bodyText">
                    {t(`${i18nBase}.${ticketDetail.title}`)}
                  </Text>
                  <Text variant="subHeadStrong" color="mainText">
                    {ticketDetail.detail}
                  </Text>
                </TicketDetail>
              ))}
            </TicketDetails>
          }
        />
      </Content>
    </Wrapper>
  )
})
