import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`

export const Footer = styled(Modal.Footer)`
  width: 100%;
`

export const ModalHeader = styled(Modal.Header).attrs({ isMobile: true })`
  width: 100%;
`

export const Body = styled(Modal.Body)`
  width: 100%;
`
