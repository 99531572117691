import { Store } from '@etta/di'
import type { PartnerCoBrandingConfigurations } from '../../core/types'

const initialPartnerCoBrandingConfigurations: PartnerCoBrandingConfigurations = {
  partnerLogoUrl: undefined,
  customPartnerLogoUrl: undefined,
  customCompanyLogoUrl: undefined,
  customMobilePartnerLogoUrl: undefined,
  customMobileCompanyLogoUrl: undefined,
  termsOfUseLabel: undefined,
  termsOfUseLinkEnabled: false,
  termsOfUseLinkUrl: undefined,
  termsOfUse: undefined,
  copyright: '', // Initialized to an empty string
  privacyPolicyEnabled: false,
  privacyPolicyLinkEnabled: false,
  privacyPolicyText: undefined,
  privacyPolicyLabel: undefined,
  privacyPolicyLinkUrl: undefined,
  customFooterEnabled: false,
  customFooter: undefined,
  supportTextEnabled: false,
  supportText: undefined,
  faqLinkEnabled: false,
  faqLabel: undefined,
  faqLinkUrl: undefined,
  partnerCoBrandingEnabled: false,
  theme: undefined,
}

@Store()
export class PartnerCoBrandingConfigurationsStore {
  private isLoading = false
  private isLoaded = false
  private isPartnerCoBrandingAllow = false
  private configurations: PartnerCoBrandingConfigurations = {
    ...initialPartnerCoBrandingConfigurations,
  }

  get isPartnerCoBrandingConfigurationsLoading() {
    return this.isLoading
  }

  get isPartnerCoBrandingConfigurationsLoaded() {
    return this.isLoaded
  }

  get partnerCoBrandingConfigurations() {
    return this.configurations
  }

  get isPartnerCoBrandingEnabledAndSiteIsNotApple() {
    return this.isPartnerCoBrandingAllow
  }

  setPartnerCoBrandingConfigurationsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded
  }

  setPartnerCoBrandingConfigurationsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setPartnerCoBrandingConfigurations(newConfigurations: Partial<PartnerCoBrandingConfigurations>) {
    this.configurations = {
      ...this.configurations,
      ...newConfigurations,
    }
    this.isPartnerCoBrandingAllow = this.getPartnerCobrandingEnableAndNotAppleSite()
    this.isLoaded = true
  }

  resetStore() {
    this.isLoading = false
    this.isLoaded = false
    this.configurations = { ...initialPartnerCoBrandingConfigurations }
  }

  private getPartnerCobrandingEnableAndNotAppleSite() {
    return this.configurations.partnerCoBrandingEnabled && this.configurations.theme !== 'kan'
  }
}
