import styled from 'styled-components'
import { body } from '@fiji/style'

export const TravelSupportContainer = styled.div`
  ${body};
  color: ${({ theme }) => theme.colors.mainText};
  background: ${({ theme }) => theme.colors.white};
  margin-top: 40px;
  min-height: 256px;
  display: flex;
  flex-direction: column;
`

export const NotificationButtonWrapper = styled.div`
  margin: 8px 32px 0 0;
`
