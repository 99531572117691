import { Screen } from '@etta/ui/screen'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import type { LayoutProps } from '../../types'
import { HotelResultHeaderMobile } from './hotel-result-header-mobile'

export function HotelResultPageMobile({ children }: LayoutProps) {
  const { scrollContainerRef } = useHeaderScroll()
  return (
    <Screen backgroundColor="background" statusBarColor="white">
      <Screen.Header>
        <HotelResultHeaderMobile />
      </Screen.Header>

      <Screen.Container as={'main'} ref={scrollContainerRef}>
        {children}
      </Screen.Container>
    </Screen>
  )
}
