import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const ImageWrapper = styled.div`
  height: inherit;
  width: inherit;

  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const RailCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const RailCompanyLogosWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
`

export const RailCompanyList = styled(Text)`
  margin-top: 2px;
  line-height: 19px;
  width: 100%;
  min-width: 200px;
`
export const Container = styled.div`
  height: inherit;
  width: inherit;

  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export const RailLogoWrapper = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 3px;
`

export const FirstLogo = styled(RailLogoWrapper)`
  position: absolute;
  top: 0;
  left: 0;
`

export const SecondLogo = styled(RailLogoWrapper)`
  position: absolute;
  bottom: 0;
  right: 0;
`
