import { Header } from '@etta/ui/header'
import type { ModalTitleLayoutProps } from '../types'
import { LeftSeparator } from './modal-title-desktop-styled'

export function ModalTitleDesktop({
  onClose,
  children,
  color,
  backgroundColor,
  'aria-label': ariaLabel,
  leftSeparatorWidth = 32,
  minHeight,
}: ModalTitleLayoutProps) {
  return (
    <Header backgroundColor={backgroundColor} minHeight={minHeight}>
      <LeftSeparator width={leftSeparatorWidth} minHeight={minHeight} />
      <Header.Title color={color} align="left" title={children} aria-label={ariaLabel} />
      <Header.CloseButton color={color} onClick={onClose} />
    </Header>
  )
}
