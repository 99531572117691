import type { AdaptedFlightSegment } from '@fiji/hooks/use-adaptive-flight/types'
import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { FlightLegSubSegment } from '@fiji/graphql/types'
import { getLayover } from '../get-layover'
import { getAmenities } from './get-amenities'

export function getAdaptedSegments(segments: FlightLegSubSegment[]): AdaptedFlightSegment[] {
  return segments.map(
    (segment, index, array): AdaptedFlightSegment => {
      const nextSegment = array[index + 1]

      return {
        id: segment.segmentId || index.toString(),
        carrierCode: segment.carrierCode || '',
        carrierName: '',
        departureAirportCode: segment.departure.airportCode || '',
        departureFullDate: segment.departureDate,
        departureCity: segment.departure.address?.city,
        departureTime: tripReviewFormatDate(
          TripReviewDateType.TimePWA,
          segment.departureDate,
        ) as string,
        departureDate: tripReviewFormatDate(
          TripReviewDateType.DayAndDate,
          segment.departureDate,
        ) as string,
        departureAirportName: segment.departure.airportName || '',
        departureTerminal: '',
        arrivalAirportCode: segment.arrival.airportCode || '',
        arrivalFullDate: segment.arrivalDate,
        arrivalCity: segment.arrival.address?.city,
        arrivalTime: tripReviewFormatDate(
          TripReviewDateType.TimePWA,
          segment.arrivalDate,
        ) as string,
        arrivalDate: tripReviewFormatDate(
          TripReviewDateType.DayAndDate,
          segment.arrivalDate,
        ) as string,
        arrivalAirportName: segment.arrival.airportName || '',
        arrivalTerminal: '',
        airlineLogo: segment.imageUrl ?? '',
        airlineLogoSquared: segment.images.square ?? '',
        flightNumber: `${segment.name} ${segment.carrierCode} ${segment.flightNumber}`,
        rawFlightNumber: segment.flightNumber,
        aircraft: null,
        flightTime: segment.flightTime!,
        amenities: getAmenities(segment.amenities),
        operatedBy: segment.operatedBy!,
        layover: getLayover(segment.arrivalDate, nextSegment?.departureDate),
        planeType: segment.plane?.type,
        isSeatMapAvailable: segment.isSeatAvailable,
        isInstantPurchaseCarrier: segment.isInstantPurchaseCarrier,
      }
    },
  )
}
