import i18n from '@fiji/i18n'
import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { OptionViewTypes } from '@etta/ui/drop-down-list'
import { EditModalStateType } from '../../core/enums/edit-modal-state-type'
import { PreExpirationAlertValues } from '../../core/entities/credit-cards.entity'

@Store()
export class BaseSettingsStore {
  toggle = new Toggle()

  state: EditModalStateType | null = null
  isPartialUserLoading: boolean = false

  get isSavingInProgress() {
    return this.state === EditModalStateType.Saving
  }

  setSavingState() {
    this.state = EditModalStateType.Saving
  }

  setDeletingState() {
    this.state = EditModalStateType.Removing
  }

  setErrorState() {
    this.state = EditModalStateType.Error
  }

  setSavedState() {
    this.state = EditModalStateType.Saved
  }

  setDeletedState() {
    this.state = EditModalStateType.Removed
  }

  setIsPartialUserLoading(isLoading: boolean) {
    this.isPartialUserLoading = isLoading
  }

  dropState = (handleClose: () => void = () => {}) => {
    this.state = null
    handleClose()
  }

  getExpirationDates() {
    const i18nBase = `Settings`
    const expirations = Object.keys(PreExpirationAlertValues)
    return expirations.map((value) => ({
      label: `${i18n.t(i18nBase + '.ExpirationAlertMonths.' + value)} ${
        value !== 'Never' ? i18n.t(i18nBase + '.PriorExpiration') : ''
      }`,
      value: PreExpirationAlertValues[value as keyof typeof PreExpirationAlertValues],
      viewType: OptionViewTypes.OptionWithCheckboxIcon,
    }))
  }
}
