import styled from 'styled-components'
import { body, white } from '@fiji/style'

export const PwaMsgHandler = styled.div`
  background: ${white};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
`

export const PwaLoader = styled.div`
  margin: 80px auto 0;
  width: 100px;
`

export const PwaErrorMsg = styled.div`
  ${body};
  line-height: 26px;
  padding: 30px 28px 0 53px;
  color: ${(p) => p.theme.colors.bodyText};
  white-space: pre-line;
`
