import styled from 'styled-components'

export const ChildContainer = styled.div<{
  isFooterVisible: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding-bottom: ${(props) => (props.isFooterVisible ? '60px' : '0')};
  flex: 1 0 auto;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`
