import type { StepNameType } from '@etta/screens/checkout-page/types'
import type { RailLegType } from '@fiji/enums'
import { appendSearchFields } from './append-search-fields'

export const ROUTES = {
  admin: {
    main: '/admin',
    heap: '/admin/heap',
  },
  login: {
    email: '/login',
    selectSite: '/login/select-site',
    password: '/login/password',
    question: '/login/question',
    selectMethod: '/login/select-method',
    beginSsoPrompt: '/login/begin-sso-prompt',
  },
  onboarding: '/onboarding',
  userActivation: '/user-activation',
  explore: '/explore',
  meeting: ({ meetingId }: { meetingId?: string }) =>
    `/meeting${appendSearchFields({
      meetingId,
    })}`,
  profile: {
    main: '/profile',
    settings: '/profile/settings',
  },
  support: {
    main: '/support',
    travel: '/support/travel',
    article: ({ articleId = ':articleId' }: { articleId?: string }) =>
      `/support/article/${articleId}`,
  },
  air: {
    search: '/explore/air-search',
    results: ({ legNumber }: { legNumber?: number }) => `/explore/air-results/leg/${legNumber}`,
  },
  rail: {
    search: '/explore/rail-search',
    results: ({ railLegType }: { railLegType?: RailLegType }) =>
      `/explore/rail-results/${railLegType ?? ''}`,
    resultsWithParams: ({
      railLegType,
      selectedFareTier,
      selectedServiceClasses,
    }: {
      railLegType?: RailLegType
      selectedFareTier: string
      selectedServiceClasses?: string[]
    }) =>
      `/explore/rail-results/${railLegType}${appendSearchFields({
        selectedFareTier,
        selectedServiceClasses,
      })}`,
  },
  carRental: {
    search: '/explore/car-rental-search',
    pickUp: '/explore/car-rental-locations/pick-up',
    dropOff: '/explore/car-rental-locations/drop-off',
    confirmation: '/explore/car-rental-locations/confirmation',
    results: '/explore/car-rental-results',
    car: ({ carId = ':carId' }: { carId?: string }) => `/explore/car-rental-details/${carId}`,
  },
  deepLink: {
    flightSegment: ({
      processId = ':id',
      departureAirportCode,
      arrivalAirportCode,
    }: {
      processId?: string
      departureAirportCode?: string
      arrivalAirportCode?: string
    }) =>
      `/flight-segment/${processId}${appendSearchFields({
        'departure-airport': departureAirportCode,
        'arrival-airport': arrivalAirportCode,
      })}`,
  },
  hotel: {
    search: '/explore/hotel-search',
    results: '/explore/hotel-results',
  },
  // These routes are in between transition process from /etta-mobility/ to /mobility/
  ettaMobility: {
    search: '/etta-mobility/search',
    uberRideSupport: '/etta-mobility/uber-ride-support',
    learnMore: '/etta-mobility/learn-more',
  },
  mobility: {
    search: '/mobility/search',
    result: '/mobility/search-flow',
    resultPostExpense: '/mobility/search-flow-post-expense',
    uberRideSupport: '/mobility/uber-ride-support',
    confirm: '/mobility/confirm',
    selectExpenseCode: '/mobility/select-expense-code',
    addExpenseCodeMemo: '/mobility/add-expense-code-memo',
    enterCustomExpenseCode: '/mobility/enter-custom-expense-code',
    learnMore: '/mobility/learn-more',
    searchWithParams: (props?: { processId?: String; transactionGroupId?: number }) =>
      `/mobility/search${appendSearchFields({
        'process-id': props?.processId,
        'transaction-group-id': props?.transactionGroupId,
      })}`,
  },
  postBooking: {
    trips: (props?: { isForceRefetch?: boolean }) =>
      `/trips${appendSearchFields({ 'is-force-refetch': props?.isForceRefetch })}`,
    trip: ({
      id = ':id',
      transactionGroupId,
      isForceRefetch,
    }: {
      id?: string
      transactionGroupId?: string
      isForceRefetch?: boolean
    }) =>
      `/trips/${id}${appendSearchFields({
        'transaction-group-id': transactionGroupId,
        'is-force-refetch': isForceRefetch,
      })}`,
    heldTrips: '/held-trips',
  },
  purchase: {
    summary: ({
      itineraryId = ':itineraryId',
      bookingId,
    }: {
      itineraryId?: string
      bookingId?: string
    }) =>
      `/checkout/purchase-summary/${itineraryId}${appendSearchFields({ bookingId: bookingId })}`,
    fusionLinks: ({
      itineraryId = ':itineraryId',
      bookingId,
    }: {
      itineraryId?: string
      bookingId?: string
    }) =>
      `/checkout/purchase-terms-and-conditions/${itineraryId}${appendSearchFields({
        bookingId: bookingId,
      })}`,
  },
  checkout: ({
    itineraryId = ':itineraryId',
    bookingId,
    isHoldFlow,
    checkoutStep = ':checkoutStep',
  }: {
    itineraryId?: string
    bookingId?: string
    isHoldFlow?: boolean
    checkoutStep?: StepNameType | ':checkoutStep'
  }) =>
    `/checkout/${checkoutStep}/${itineraryId}/${appendSearchFields({
      isHoldFlow,
      bookingId,
    })}`,
  traveler: {
    information: ({
      itineraryId = ':itineraryId',
      bookingId,
    }: {
      itineraryId?: string
      bookingId?: string
    }) =>
      `/checkout/traveler-information/${itineraryId}${appendSearchFields({
        bookingId: bookingId,
      })}`,
    addPurchase: ({
      itineraryId = ':itineraryId',
      bookingId,
    }: {
      itineraryId?: string
      bookingId?: string
    }) =>
      `/checkout/traveler-information/purchase-add-form/${itineraryId}${appendSearchFields({
        bookingId,
      })}`,
  },
  reviewTrip: {
    main: ({
      itineraryId = ':itineraryId',
      bookingId,
    }: {
      itineraryId?: string
      bookingId?: string
    }) => `/trip-review/${itineraryId}${appendSearchFields({ bookingId: bookingId })}`,
  },
}
