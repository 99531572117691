import * as R from 'ramda'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { Maybe } from '@fiji/graphql/types'

type Props = {
  date: Date
  displayYear?: Maybe<boolean>
  displayMonth?: Maybe<boolean>
  displayDay?: Maybe<boolean>
  displayHour?: Maybe<boolean>
  displayMinute?: Maybe<boolean>
  displayTimeZone?: Maybe<boolean>
}

export const formatDateField = ({ date, displayYear, displayMonth, displayDay }: Props) => {
  const format = R.pipe(
    () => (displayMonth ? ['MMM'] : ['']),
    (currentFormat) => (displayDay ? [...currentFormat, 'd'] : currentFormat),
    (currentFormat) => (displayYear ? [...currentFormat, 'yyyy'] : currentFormat),
  )()

  const formattedDate = dateFormat(date, format.filter(Boolean).join(' '))

  return formattedDate
}
