import { MediaLayout } from '@etta/ui/media-layout'
import { EnterCustomExpenseCodeMobile } from './mobile'
import type { LayoutProps } from './types'

export function EnterCustomExpenseCodeLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<EnterCustomExpenseCodeMobile {...props} />}
      desktopSlot={<EnterCustomExpenseCodeMobile {...props} />}
    />
  )
}
