import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

type Props = {
  onClose?: () => void
}

const i18nPath = 'Delegate.'

export function SwitchErrorState({ onClose }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Text variant="title3" color="mainText">
        {t(i18nPath + 'SwitchError.Title')}
      </Text>
      <Block marginTop={24}>
        <Text variant="footnoteMedium" color="bodyText" align="center">
          {t(i18nPath + 'SwitchError.Description')}
        </Text>
      </Block>
      <Block isFullWidth marginTop={24}>
        <Button onClick={onClose} fullWidth>
          {t(i18nPath + 'SwitchError.ButtonText')}
        </Button>
      </Block>
    </>
  )
}
