import { MediaLayout } from '@etta/ui/media-layout'
import { IndexLabelMobile } from './layout/mobile/index-label-mobile'
import { IndexLabelDesktop } from './layout/desktop/index-label.desktop'
import type { Props } from './types'

export function IndexLabel(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<IndexLabelMobile {...props} />}
      desktopSlot={<IndexLabelDesktop {...props} />}
    />
  )
}
