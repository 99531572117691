import { observer } from 'mobx-react-lite'
import { useRef, useEffect, useState } from 'react'
import type { FC } from 'react'
import { MapProvider } from '@etta/modules/display-configuration/core/enums'
import { usePrevious } from '@fiji/hooks/use-previous'
import { useThemeConfigurationContext } from '@etta/modules/theme'
import { useEttaMapContext } from '../../interface/use-etta-map.context'
import type { EttaMapProps } from '../types'
import { Container } from './apple-map-styled'
import { AppleMapMarkers } from './apple-map-markers'
import { useOptions } from './use-options'
import { useAppleMapContext } from './apple-map.context'

const SYNC_CENTER_DIFF = 0.00001

export const AppleMap: FC<EttaMapProps> = observer(function AppleMap({
  children,
  onMapLoaded,
  center,
  defaultCenter,
  defaultZoom = 16,
  options = {},
  onAnnotationDeselect,
}) {
  const { draggable: isInteractive = true, maxZoom, zoomControl, scaleControl } = options
  const { ettaMapActions } = useEttaMapContext()
  const containerRef = useRef<HTMLDivElement>(null)
  const { themeConfigurationStore } = useThemeConfigurationContext()
  const isDarkTheme = themeConfigurationStore.isDarkTheme
  const mapRef = useRef<mapkit.Map | null>(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const previousMaxZoom = usePrevious(maxZoom)
  const { selectedCallout, setMapInstance } = useAppleMapContext()

  const { buildConstructorOptions, buildZoomLevel } = useOptions()

  // initialize the map
  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const constructorOptions = buildConstructorOptions({
      isInteractive,
      zoomControl,
      center,
      defaultCenter,
      scaleControl,
    })

    const map = new mapkit.Map(containerRef.current, constructorOptions)

    map.cameraDistance = buildZoomLevel(defaultZoom, map.center.latitude)

    if (maxZoom) {
      map.cameraZoomRange = new mapkit.CameraZoomRange(buildZoomLevel(maxZoom, map.center.latitude))
    }

    mapRef.current = map

    const handleDeselect = () => {
      setTimeout(() => {
        if (map.selectedAnnotation) {
          return
        }
        onAnnotationDeselect?.()
      })
    }

    if (onAnnotationDeselect) {
      map.addEventListener('deselect', handleDeselect)
    }

    const ettaMap = ettaMapActions.createEttaMap({
      map,
      provider: MapProvider.AppleMapkit,
    })

    setIsMapLoaded(true)
    onMapLoaded?.(ettaMap)
    setMapInstance(map)

    return () => {
      if (onAnnotationDeselect) {
        map.removeEventListener('deselect', handleDeselect)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mapRef.current) {
      return
    }

    if (!center?.lat || !center?.lng) {
      return
    }
    const prevCenter = mapRef.current.center
    const latDiff = Math.abs(center.lat - prevCenter.latitude)
    const lngDiff = Math.abs(center.lng - prevCenter.longitude)
    if (latDiff + lngDiff > SYNC_CENTER_DIFF) {
      const newCenter = new mapkit.Coordinate(center.lat, center.lng)
      mapRef.current.setCenterAnimated(newCenter)
    }
  }, [center?.lat, center?.lng])

  useEffect(() => {
    if (!mapRef.current) {
      return
    }
    if (!maxZoom || maxZoom === previousMaxZoom) {
      return
    }
    mapRef.current.setCameraZoomRangeAnimated(
      new mapkit.CameraZoomRange(buildZoomLevel(maxZoom, mapRef.current.center.latitude)),
    )
  }, [previousMaxZoom, maxZoom, buildZoomLevel])

  useEffect(() => {
    if (!mapRef.current) {
      return
    }
    if (isDarkTheme) {
      mapRef.current.colorScheme = mapkit.Map.ColorSchemes.Dark
      return
    }
    mapRef.current.colorScheme = mapkit.Map.ColorSchemes.Light
  }, [isDarkTheme])

  return (
    <Container isInteractive={isInteractive} ref={containerRef}>
      <AppleMapMarkers isMapLoaded={isMapLoaded} instance={mapRef.current}>
        {children}
      </AppleMapMarkers>
      {selectedCallout}
    </Container>
  )
})
