import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { RailDetailsLegend } from '@etta/components/rail-details-legend/rail-details-legend'
import { RailDetailsHeader } from '@etta/components/rail-details/rail-details-header'
import { FareBlock } from '@etta/components/rail-details/fare-block'
import type { Props } from '../../types'

export function RailDetailsContentDesktop({
  activeTab,
  actualRailEmission,
  adaptedSegments,
  faresToShow,
  headerTitle,
  isPreferred,
  onSelectFareSeats,
  onTabChange,
  railLabels,
  railLogo,
  squareRailLogos,
  fareConditions,
  rail,
  handleModalClose,
  handleOpenTicketRestrictions,
  handleOpenPermittedStations,
}: Props) {
  const { t } = useTranslation()
  return (
    <>
      <Modal.Header>
        <Header
          withBorderBottom
          rightSlot={
            <Block paddingRight={12} paddingBottom={16}>
              <IconButton
                icon="closeFilledPWA"
                size="medium"
                color="bodyText"
                onClick={handleModalClose}
                aria-label={t('Accessibility.Header.CloseLabel')}
              />
            </Block>
          }>
          <Block paddingLeft={30} paddingBottom={16}>
            <RailDetailsHeader
              title={headerTitle}
              railLabels={railLabels}
              railLogo={railLogo}
              squareRailLogos={squareRailLogos}
            />
          </Block>
        </Header>
      </Modal.Header>

      <Modal.Body>
        <RailDetailsLegend onTabChange={onTabChange} activeTab={activeTab} rail={rail} />
        <FareBlock
          isPreferred={!!isPreferred}
          faresToShow={faresToShow}
          adaptedSegments={adaptedSegments}
          onSelectFareSeats={onSelectFareSeats}
          onInfoIconClick={handleOpenTicketRestrictions}
          handleOpenPermittedStations={handleOpenPermittedStations}
          fareConditions={fareConditions}
          actualRailEmission={actualRailEmission}
        />
      </Modal.Body>
    </>
  )
}
