import styled, { css } from 'styled-components'

type Props = {
  marginSize?: 'md' | 'sm' | 'none'
  borderStyle?: 'solid' | 'dashed'
}

export const CarRentalMobileDivider = styled.div<Props>`
  width: 100%;
  border-top: 1px ${({ borderStyle = 'solid' }) => borderStyle} ${(p) => p.theme.colors.borderLight};
  ${({ marginSize = 'md' }) => {
    switch (marginSize) {
      case 'md':
        return css`
          margin: 16px 0;
        `
      case 'sm':
        return css`
          margin: 14px 0;
        `
      default:
        return ''
    }
  }}
`
