import { useTranslation } from 'react-i18next'
import { SegmentState } from '@fiji/graphql/types'
import type { Props } from './types'
import { SegmentStateLabelLayout } from './layout'

export function SegmentStateLabel({ segmentState }: Props) {
  const i18nBase = 'TravelerInfo.'
  const { t } = useTranslation()

  const cardStatuses = {
    [SegmentState.Changed]: t(`${i18nBase}SegmentStateChanged`),
    [SegmentState.New]: t(`${i18nBase}SegmentStateAdded`),
  }

  return <SegmentStateLabelLayout activeSegmentState={cardStatuses[segmentState]} />
}
