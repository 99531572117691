import styled from 'styled-components'
import { forwardRef } from 'react'
import type { LottieAnimationProps, AnimationItem } from '@etta/ui/lottie-animation'
import { LottieAnimation } from '@etta/ui/lottie-animation'

export const AnimationsWrapper = styled.div`
  background-color: #ecedee;
  position: absolute;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`

export const StyledLottieAnimation = styled(
  forwardRef<AnimationItem, LottieAnimationProps>((props, ref) => (
    <LottieAnimation {...props} ref={ref} />
  )),
)<{
  zIndex: number
}>`
  position: absolute;
  top: 0;
  left: 50%;
  width: 120%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
  transform: translateX(-50%);
`

export const BookingText = styled.div`
  font-weight: 500;
  text-align: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 50px;
  font-size: 1.9rem;
`
