import { observer } from 'mobx-react-lite'
import { HotelDistanceModal } from '@etta/components/sliders'
import { Search } from '@etta/ui/search'
import { useHotelDistance } from '@etta/components/hotel-search-form/hotel-distance-section/use-hotel-distance'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'

export const DistancePicker = observer(function DistancePicker() {
  const { hotelSearchFormStore, hotelSearchFormActions } = useHotelSearchFormContext()
  const distance = hotelSearchFormStore.hotelForm.filters?.distance

  const {
    ref: distanceRef,
    hotelDistanceModal: distanceModal,
    label: distanceLabel,
    value: distanceValue,
    distanceKey,
    ariaLabel: distanceAriaLabel,
  } = useHotelDistance({
    distance,
    onDistanceChange: hotelSearchFormActions.onDistanceChange,
    withUppercase: true,
  })

  return (
    <Search.Group ref={distanceRef}>
      <Search.Element
        wrapperAriaLabel={distanceAriaLabel}
        labelTitle={distanceLabel}
        value={distanceValue}
        onClick={distanceModal?.handleOpen}
        contentSlot={
          <HotelDistanceModal
            distanceKey={distanceKey}
            modalWidth={368}
            isOpen={distanceModal?.isOpen || false}
            onChange={hotelSearchFormActions.onDistanceChange}
            distance={distance}
            onClose={distanceModal?.handleClose}
          />
        }
      />
    </Search.Group>
  )
})
