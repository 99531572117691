import { MediaLayout } from '@etta/ui/media-layout'
import { RailCardsMobile } from './layout/mobile/rail-cards-mobile'
import { RailCardsDesktop } from './layout/desktop/rail-cards-desktop'
import type { Props } from './types'

export const RailCards = (props: Props) => {
  return (
    <MediaLayout
      mobileSlot={<RailCardsMobile {...props} />}
      desktopSlot={<RailCardsDesktop {...props} />}
    />
  )
}
