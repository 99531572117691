import type { ReactNode } from 'react'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import type { ConfirmationDialog } from '../../types'
import { DelegateModalDesktopDetailsLoader } from './delegate-modal-desktop-details-loader'
import { DelegateModalDekstopConfirmation } from './delegate-modal-desktop-confirmation'

type Props = {
  searchSlot: ReactNode
  delegatesSlot: ReactNode
  searchLabel: string
  isLoading: boolean
  confirmationDialog: ConfirmationDialog
}

export function DelegateModalDesktopDetailsBody({
  searchSlot,
  searchLabel,
  isLoading,
  delegatesSlot,
  confirmationDialog,
}: Props) {
  if (confirmationDialog.isOpen) {
    return (
      <DelegateModalDekstopConfirmation
        handleClose={confirmationDialog.handleClose}
        handleConfirm={confirmationDialog.handleConfirmDesktop}
      />
    )
  }

  return (
    <Modal.Body>
      <Block aria-label={searchLabel} marginTop={24} paddingHorizontal={32}>
        {searchSlot}
      </Block>
      <Block marginTop={16} paddingHorizontal={32}>
        {delegatesSlot}
      </Block>
      <DelegateModalDesktopDetailsLoader isLoading={isLoading} />
    </Modal.Body>
  )
}
