import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { KeyboardNavigationStyle, footnoteStrong, screenSize } from '@fiji/style'

export const Wrapper = styled(Link)<{
  $isFirst: boolean
  isMobile?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-decoration: none;
  margin-top: ${(props) => (props.$isFirst ? 12 : 16)}px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      &:active {
        background-color: ${({ theme }) => theme.colors.background};
      }
    `}
  @media (${screenSize.minLaptop}) {
    margin-top: 0;
  }
  ${KeyboardNavigationStyle}
`

export const Time = styled.div`
  ${footnoteStrong};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.bodyText1};
`
