export function getTimezoneOffset(timestamp: string): number | undefined {
  const match = timestamp.match(/([+-]\d{2}):?(\d{2})|Z/)
  if (!match) {
    return undefined
  }

  if (match[0] === 'Z') {
    return 0
  }

  const hours = parseInt(match[1], 10)
  const minutes = parseInt(match[2], 10)
  const totalOffsetMinutes = hours * 60 + (hours < 0 ? -minutes : minutes)

  return totalOffsetMinutes * 60 * 1000
}
