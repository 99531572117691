import { calculateTripTotal } from '@fiji/utils/calculate-trip-total'
import type { TripCost } from '@fiji/graphql/types'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'

export function useItineraryData() {
  const { reviewTripStore, segmentTypeCheckActions } = useReviewTripContext()
  const { airCheckFarePolicyStore } = useAirSearchContext()

  const { trip, isTripLoading, isHoldTripAllowed, complianceMessages } = reviewTripStore

  const tripCost = trip?.tripCost
  const availableSegments = trip?.availableSegments
  const segments = trip?.segments || []
  const itineraryTrip = trip?.tripInfo
  const flightId = trip?.flightId
  const dynamicSiteMessage = trip?.messages
  const continuityMessages = trip?.continuityMessage
  const overlappingTrips = trip?.overlappingTrips
  const hotelSegments = trip?.segments.filter(segmentTypeCheckActions.isHotelSegment) || []
  const firstHotel = hotelSegments.length ? hotelSegments[0] : undefined
  const isMultiCity = !!trip?.isMultiCity

  const carPriceChangeInfo = trip?.priceChanges.car
  const flightCostFiltered = tripCost?.payNow?.flight?.filter((cost): cost is TripCost => !!cost)
  const flightCost = calculateTripTotal(flightCostFiltered)?.total
  const carRentalCost = tripCost?.payLater?.car?.total
  const tripStatus = trip?.tripStatus

  const messages = {
    dynamicSiteMessage,
    complianceMessages,
    continuityMessages,
  }

  const tripLevelPolicy = trip?.tripLevelPolicy

  return {
    isLoading: isTripLoading,
    isHoldTripAllowed,
    tripCost,
    availableSegments,
    segments,
    itineraryTrip,
    flightId,
    messages,
    overlappingTrips,
    firstHotel,
    airPriceChangeInfo: airCheckFarePolicyStore.airPriceChange,
    carPriceChangeInfo,
    flightCost,
    carRentalCost,
    isMultiCity,
    areSegmentsChangeable: !!tripStatus?.areSegmentsChangeable,
    tripLevelPolicy,
  }
}
