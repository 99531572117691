import { observer } from 'mobx-react-lite'
import { HotelDetailsModal } from '@etta/components/hotel-details-modal/hotel-details-modal'
import { TravelPolicyDialog } from '@etta/components/travel-policy-dialog/travel-policy-dialog'
import { TripStatusModal } from '@etta/components/trip-status-modal'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { SearchResultsActiveItineraryDialog } from '@etta/components/search-results-active-itinerary-dialog'
import { SearchResultsTripExpiredDialog } from '@etta/components/search-results-trip-expired-dialog'
import { SegmentType } from '@etta/core/enums'
import { SegmentBookingLimitDialog } from '@etta/components/segment-booking-limit-dialog'
import { MultipleHotelProvidersDialog } from '@etta/modules/multiple-hotel-providers/ui/multiple-hotel-providers-dialog'
import { HotelFilters } from './hotel-filters'
import { useHotelResultsContextSelector } from './hotel-results-provider'

export const HotelResultModals = observer(function HotelResultModals() {
  const {
    hotelSearchResultsStore,
    hotelFiltersStore,
    hotelFiltersAction,
  } = useHotelSearchResultsContext()
  const {
    policyModalToggle,
    hotelDetails,
    actualHotelEmission,
    activeItineraryToggle,
    tripExpiredToggle,
    handleGoToRTPAndCloseDialog,
    maximumQuantityToggle,
    providerName,
    multipleHotelProvidersSearchDialog,
    handleBackToTripAndCloseDialog,
  } = useHotelResultsContextSelector((state) => state)
  const { handleCreateNewTrip, handleAddToCurrentTrip, serviceFeeLabel } = hotelDetails

  return (
    <>
      <HotelFilters
        isOpen={hotelFiltersStore.filterModal.isOpen}
        onClose={hotelFiltersAction.handleCloseFiltersModal}
      />
      <TravelPolicyDialog
        isVisible={policyModalToggle.isOpen}
        handleVisibility={policyModalToggle.handleClose}
      />
      {hotelDetails.hotelDetails && (
        <HotelDetailsModal
          isVisible={hotelDetails.isVisible}
          onClose={hotelDetails.onClose}
          isHotelDetailsLoading={hotelDetails.isHotelDetailsLoading}
          areHotelAboutDatesDisplayed={hotelDetails.areHotelAboutDatesDisplayed}
          onRoomSelect={hotelDetails.onRoomSelect}
          hotelDetails={hotelDetails.hotelDetails}
          isError={hotelDetails.hasHotelDetailsError}
          onRetry={hotelDetails.retryHotelDetails}
          hotelEmission={actualHotelEmission}
          perDiemRateCap={hotelSearchResultsStore.hotelResults?.perDiemRate}
          serviceFeeLabel={serviceFeeLabel}
        />
      )}
      <TripStatusModal tripStateStatus={hotelDetails.viewState} onClick={hotelDetails.onClose} />
      <SearchResultsActiveItineraryDialog
        isOpen={activeItineraryToggle.isOpen}
        onClose={activeItineraryToggle.handleClose}
        onAddToCurrentTrip={handleAddToCurrentTrip}
        onCreateNewTrip={handleCreateNewTrip}
        onBack={activeItineraryToggle.handleClose}
      />
      <SearchResultsTripExpiredDialog
        isOpen={tripExpiredToggle.isOpen}
        onClose={tripExpiredToggle.handleClose}
        onStartAsNewTrip={handleCreateNewTrip}
      />
      <SegmentBookingLimitDialog
        segmentType={SegmentType.Hotel}
        isOpen={maximumQuantityToggle.isOpen}
        onClick={handleGoToRTPAndCloseDialog}
        onClose={maximumQuantityToggle.handleClose}
      />
      <MultipleHotelProvidersDialog
        isOpen={multipleHotelProvidersSearchDialog.isOpen}
        actionType="change"
        providerName={providerName}
        onClick={multipleHotelProvidersSearchDialog.handleClose}
        onClose={multipleHotelProvidersSearchDialog.handleClose}
        onBack={handleBackToTripAndCloseDialog}
      />
    </>
  )
})
