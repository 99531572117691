import { getTokenExpirationDateFromCookie } from '@fiji/utils/get-cookie-expiration-date'

export function getTokenExpireTime(): number {
  const tokenExpirationDate = getTokenExpirationDateFromCookie()

  if (!tokenExpirationDate) {
    return 0
  }

  const timeBeforeExpire = tokenExpirationDate.getTime()

  return timeBeforeExpire
}
