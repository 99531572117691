import styled from 'styled-components'

export const ButtonContainer = styled.div`
  position: absolute;
  right: 14px;
  top: 24px;
  z-index: 14;
`

export const ButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 19px;
  height: 19px;
  transition: background ease 0.2s;
`
export const Line = styled.span`
  position: absolute;
  top: -2px;
  height: 23px;
  width: 2px;
  background-color: ${(props) => props.theme.secondaryColor};

  &:first-child {
    left: 9px;
    transform: rotate(45deg);
  }

  &:last-child {
    right: 8px;
    transform: rotate(-45deg);
  }
`
