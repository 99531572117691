import { Modal } from '@etta/ui/modal'
import { UberNewInfo } from '../uber-new-info'

type Props = {
  isVisible: boolean
  onClose: () => void
  isCloseModalOnlyOnExit?: boolean
}

export function UberInfoModal({ isVisible, onClose, isCloseModalOnlyOnExit }: Props) {
  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      data-tracking-id={'mobility-learn-more'}>
      <UberNewInfo isCloseModalOnlyOnExit={isCloseModalOnlyOnExit} onClose={onClose} />
    </Modal>
  )
}
