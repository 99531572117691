import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'

const i18nBase = 'DynamicSiteMessage'

export function ModalHeaderDesktop() {
  const { t } = useTranslation()

  return (
    <Modal.Title color="mainText2" backgroundColor="plum" leftSeparatorWidth={24} minHeight={62}>
      {t(`${i18nBase}.Messages`)}
    </Modal.Title>
  )
}
