import type { ReactElement } from 'react'
import { DotsContainer, DotsWrapper, Dots } from './carousel-dots.styled'
import { useCarouselDots } from './use-carousel-dots'

type Props = {
  dots: ReactElement[]
  dotContainerClassName: string
  activeDotClassName: string
}

CarouselDots.defaultProps = {
  dotContainerClassName: 'magic-dots slick-dots',
  activeDotClassName: 'slick-active',
}

export function CarouselDots({ dots, dotContainerClassName, activeDotClassName }: Props) {
  const { translateWidth } = useCarouselDots({ dots, activeDotClassName })

  return (
    <DotsContainer>
      <DotsWrapper className={dotContainerClassName}>
        <Dots translateWidth={translateWidth} dotsLength={dots.length}>
          {dots}
        </Dots>
      </DotsWrapper>
    </DotsContainer>
  )
}
