import type { ReactNode } from 'react'

import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { LabelContainer, Label } from './preferences-label-styled'

type Props = {
  icon: IconNames
  children: ReactNode
}

export function PreferencesLabel({ icon, children }: Props) {
  return (
    <LabelContainer>
      <Icon name={icon} />
      <Label>{children}</Label>
    </LabelContainer>
  )
}
