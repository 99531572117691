import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { TripReviewSeatPreferencesMobile } from './layout/mobile/trip-review-seat-preferences-mobile'
import { TripReviewSeatPreferencesDesktop } from './layout/desktop/trip-review-seat-preferences-desktop'

export function TripReviewSeatPreferencesContent(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<TripReviewSeatPreferencesMobile {...props} />}
      desktopSlot={<TripReviewSeatPreferencesDesktop {...props} />}
    />
  )
}
