import styled from 'styled-components'
import { Link } from '@etta/ui/link'

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px 0 32px;
  margin: 0;
  box-shadow: 0px 1px 0px ${({ theme }) => theme.colors.borderLight};
  z-index: 1;
`

export const DoneLink = styled(Link)`
  margin-left: auto;
`
