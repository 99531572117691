import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { CarRentalDetailsMap } from '@etta/components/car-rental-details-map/car-rental-details-map'
import type { CarRentalLocationLayoutProps } from '../../types'
import {
  Container,
  Content,
  MapWrapper,
  Location,
  LocationItem,
} from './car-rental-location-mobile-styled'

export function CarRentalLocationMobile({
  locations,
  onAddressClick,
}: CarRentalLocationLayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalModal.'

  return (
    <Container>
      {locations.geocode && (
        <MapWrapper onClick={onAddressClick}>
          <CarRentalDetailsMap isBottomRounded={false} carCoords={locations.geocode} />
        </MapWrapper>
      )}
      <Content>
        <Location>
          <LocationItem>
            <Text variant="captionStrongCaps" color="bodyText" role="heading" aria-level={5}>
              {t(i18Base + 'PickUpTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text variant="subHeadStrong">{locations.pickUp.date}</Text>
          </LocationItem>
          <Text variant="footnoteMedium" color="bodyText">
            {locations.pickUp.location}
          </Text>
        </Location>
        <Location>
          <LocationItem>
            <Text variant="captionStrongCaps" color="bodyText" role="heading" aria-level={5}>
              {t(i18Base + 'DropOffTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text variant="subHeadStrong">{locations.dropOff.date}</Text>
          </LocationItem>
          <Text variant="footnoteMedium" color="bodyText">
            {locations.dropOff.location}
          </Text>
        </Location>
      </Content>
    </Container>
  )
}
