import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'
import { KeyboardNavigationStyle, UnstyledButton } from '@fiji/style'

export const PaginationWrapper = styled.div`
  display: flex;
  max-width: 360px;
  height: 32px;
  justify-content: center;
`

export const Button = styled(UnstyledButton)<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: ${(props) =>
    props.selected ? props.theme.colors.primary : props.theme.colors.white};
  border: 1px solid #dfe3e8;
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.mainText)};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  margin: 0 4px;

  ${KeyboardNavigationStyle}
`

export const Ellipsis = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-weight: bold;
  font-size: 14px;
  margin: 0 4px;
  color: ${(props) => props.theme.colors.mainText};
`

export const ArrowButton = styled(IconButton)`
  border-radius: 4px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #dfe3e8;
`
