import styled from 'styled-components'
import { Text } from '../text'

export const Container = styled.div`
  padding-bottom: 19px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
`

export const Content = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

export const ContactIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`
export const ContactText = styled(Text)`
  word-break: break-all;
`
