import { SeatMap } from '../../seat-map'
import { SeatFooter } from '../../seat-footer'
import { Legend } from '../../legend'
import type { LayoutProps } from '../../air-seat-map.types'
import { Wrapper, Body, RightColumn, LeftColumn } from './air-seat-map-desktop-styled'
import { LeftColumnContent } from './left-column-content'
import { ReadonlyBanner } from './readonly-banner'

export function AirSeatMapDesktop({
  cabinClass,
  segmentsSeatRows,
  onSelectedSegmentIdChange,
  isLoading,
  selectAndApproveSeat,
  moveToNextSeatSelection,
  seatsForApprove,
  seatRowsBySegment,
  usedSegmentId,
  seatNumbersBySegmentId,
  approvedSegmentsSeatNumbers,
  selectedSegmentsSeatNumbers,
  isSeatSelectionAvailable,
  onNextSegment,
  isSeatAssignLoading,
  isSeatAssignFailed,
  onSeatAssignFailedContinue,
  footerHeight,
  onChangeFooterHeight,
  adaptedFlightSegments,
  isUnexpectedError,
  isReadonly,
  headline,
  finishAllSeatsSelection,
  skipSeatSelection,
  hasNextSegment,
  allowSkipSeatSelection,
  hasFooter,
  isSeatMapAvailable,
}: LayoutProps) {
  return (
    <Wrapper>
      {isReadonly ? <ReadonlyBanner /> : null}
      <Body>
        <LeftColumn>
          <LeftColumnContent
            hasNextSegment={hasNextSegment}
            allowSkipSeatSelection={allowSkipSeatSelection}
            skipSeatSelection={skipSeatSelection}
            isReadonly={isReadonly}
            approvedSegmentsSeatNumbers={
              selectedSegmentsSeatNumbers.length
                ? selectedSegmentsSeatNumbers
                : approvedSegmentsSeatNumbers
            }
            segmentsSeatRows={segmentsSeatRows}
            adaptedFlightSegments={adaptedFlightSegments}
            usedSegmentId={usedSegmentId}
            onSelectedSegmentIdChange={onSelectedSegmentIdChange}
            headline={headline}
          />
          <Legend isPreferredInOneLine />
        </LeftColumn>
        <RightColumn showGrayBackground={isUnexpectedError || !isSeatMapAvailable}>
          <SeatMap
            isUnexpectedError={isUnexpectedError}
            isLoading={isLoading}
            usedCabinClass={cabinClass}
            seatRows={seatRowsBySegment}
            seatsNumberList={seatNumbersBySegmentId}
            onSeatChecked={selectAndApproveSeat}
            onUnavailableSeatMapContinue={onNextSegment}
            selectedSegmentId={usedSegmentId}
            footerHeight={footerHeight}
            isReadonly={isReadonly}
            isSeatMapAvailable={isSeatMapAvailable}
          />
        </RightColumn>
      </Body>
      {hasFooter ? (
        <SeatFooter
          finishAllSeatsSelection={finishAllSeatsSelection}
          seatsForApprove={seatsForApprove}
          onSelect={moveToNextSeatSelection}
          isSeatSelectionAvailable={isSeatSelectionAvailable}
          isLoading={isSeatAssignLoading}
          isSeatMapLoading={isLoading}
          isSeatAssignFailed={isSeatAssignFailed}
          onSeatAssignFailedContinue={onSeatAssignFailedContinue}
          selectedSegmentId={usedSegmentId}
          onSeatSelectionUnavailableContinue={onNextSegment}
          onChangeFooterHeight={onChangeFooterHeight}
          isAbsolutePosition
        />
      ) : null}
    </Wrapper>
  )
}
