import { getCookieValue } from '@fiji/utils/cookies'

export const getTokenExpirationDateFromCookie = (): Date | null => {
  try {
    const exp: string | null = getCookieValue('exp')

    if (!exp) {
      return null
    }

    return new Date(parseInt(exp) * 1000)
  } catch {
    return null
  }
}
