import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { RailLayoutType } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { RailLoyaltyPrograms } from '../rail-loyalty-programs'
import { RailEticketSelector } from '../rail-eticket-selector'
import type { RailPreferencesType } from './types'
import { useRailPreferences } from './use-rail-preferences'

const i18nBase = 'RailPreferences.'

export const RailPreferencesContent = observer(function RailPreferencesContent({
  loyaltyProgram,
  onClose,
  onSubmit,
  existingMembership,
  railLayout = RailLayoutType.UK,
  isEticket,
  isEticketDisabled,
  isStationTicketMachineDisabled,
  departureStationName,
}: RailPreferencesType) {
  const { t } = useTranslation()
  const loyalityLabel =
    railLayout === RailLayoutType.UK ? t(i18nBase + 'RailCards') : t(i18nBase + 'LoyaltyCards')
  const {
    selectedMethod,
    loyaltyProgramValues,
    onChangeLoyaltyProgram,
    handleSubmit,
    selectMethodHandler,
    isRailCardSelectionEnabled,
  } = useRailPreferences({ loyaltyProgram, onSubmit, existingMembership, isEticket })

  return (
    <>
      <CheckoutPageModal.Header title={t(`${i18nBase}Title`)} onClose={onClose} />
      <CheckoutPageModal.Body>
        {isRailCardSelectionEnabled && !!loyaltyProgram.options.length && (
          <Block>
            <RailLoyaltyPrograms
              label={loyalityLabel}
              selectLabel={t(i18nBase + 'SelectRailcard')}
              memberLabel={t(i18nBase + 'MembershipNumber')}
              options={loyaltyProgram.options}
              value={loyaltyProgramValues}
              onUpdate={onChangeLoyaltyProgram}
              railLayout={railLayout}
            />
          </Block>
        )}
        <Block>
          <RailEticketSelector
            departureStationName={departureStationName}
            isStationTicketMachineDisabled={isStationTicketMachineDisabled}
            isEticketDisabled={isEticketDisabled}
            selectedMethod={selectedMethod}
            selectMethodHandler={selectMethodHandler}
          />
        </Block>
      </CheckoutPageModal.Body>
      <CheckoutPageModal.Footer
        submitLabel={t(`${i18nBase}Button`)}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </>
  )
})
