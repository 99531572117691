import { useCallback, Fragment } from 'react'
import type { SelectSearchOptionType } from '@etta/ui/select-search/types'
import { Icon } from '@etta/ui/icon'
import { IconContainer, Option } from './option-row-styled'

type Props = {
  item: SelectSearchOptionType
  index: number
  onSelect: (option: SelectSearchOptionType) => void
  renderOptionMobile?: (option: SelectSearchOptionType, index: number) => JSX.Element
  isDisabled?: boolean
}

export function OptionRow({ item, onSelect, renderOptionMobile, index, isDisabled }: Props) {
  const handleClick = useCallback(() => onSelect(item), [onSelect, item])

  const content = renderOptionMobile ? (
    renderOptionMobile(item, index)
  ) : (
    <Fragment>
      <IconContainer>
        {item.iconName && <Icon name={item.iconName} size={item.iconSize} color={item.iconColor} />}
      </IconContainer>
      {item.label}
    </Fragment>
  )
  return (
    <Option
      onClick={!isDisabled ? handleClick : () => {}}
      data-tracking-id={item.dataTrackingId}
      isDisabled={isDisabled}>
      {content}
    </Option>
  )
}
