import styled from 'styled-components'

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const ContentSubHeader = styled.div`
  display: flex;
  padding-top: 8px;
`
