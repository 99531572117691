import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function useCovidInformation() {
  const { t } = useTranslation()
  const i18Base = 'HotelSafetyCheck.CovidInformation'
  const i18BaseContent = i18Base + '.CovidInformationDetails'
  const i18BaseHeader = i18Base + '.CovidInformationHeader'

  const getTranslation = useCallback(
    (key: string, base = i18BaseContent) => {
      return t(base + key)
    },
    [t, i18BaseContent],
  )

  return {
    getTranslation,
    i18BaseHeader,
  }
}
