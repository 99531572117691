import { useState } from 'react'
import { Image } from './apple-banner.styled'
import BannerVertical from './assets/banner-vertical.png'
import BannerHorizontal from './assets/banner-horizontal.png'

type Props = {
  orientation: 'horizontal' | 'vertical'
}

export function AppleBanner({ orientation }: Props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const src = orientation === 'horizontal' ? BannerHorizontal : BannerVertical
  return (
    <Image
      isLoaded={isLoaded}
      onLoad={() => {
        setIsLoaded(true)
      }}
      loading="lazy"
      src={src}
      alt="Travel at Apple mobile"
      orientation={orientation}
    />
  )
}
