import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { PostBookingTripActions } from './actions/post-booking-trip.actions'
import { PostBookingTripStore } from './stores/trip/post-booking-trip.store'
import { PostBookingTripPreviewStore } from './stores/trip-preview/post-booking-trip-preview.store'
import { PostBookingTripPreviewActions } from './actions/post-booking-trip-preview.actions'
import { PostBookingTripModifyActions } from './actions/post-booking-trip-modify.actions'
import { SegmentTypeCheckActions } from './actions/post-booking-segment-type-check.actions'
import { PostBookingCancellationActions } from './actions/post-booking-trip-cancellation.actions'
import { PostBookingTripDetailsActions } from './actions/post-booking-trip-details.actions'
import { PostBookingTripDetailsStore } from './stores/trip-details/post-booking-trip-details.store'
import { PostBookingCancellationStore } from './stores/trip-cancellation/post-booking-cancellation.store'
import { BookTripAgainStore } from './stores/book-trip-again/book-trip-again.store'
import { BookTripAgainActions } from './actions/book-trip-again.actions'
import { BookTripAgainService } from './services/book-trip-again.service'
import { PostBookingAddSegmentAction } from './actions/post-booking-add-segment.action'

export const { useModuleContext: usePostBookingContext } = hookContextFactory({
  postBookingTripModifyActions: PostBookingTripModifyActions,
  postBookingTripActions: PostBookingTripActions,
  postBookingTripStore: PostBookingTripStore,
  postBookingCancellationActions: PostBookingCancellationActions,
  postBookingCancellationStore: PostBookingCancellationStore,
  postBookingTripDetailsStore: PostBookingTripDetailsStore,
  postBookingTripDetailsActions: PostBookingTripDetailsActions,
  postBookingTripPreviewActions: PostBookingTripPreviewActions,
  postBookingTripPreviewStore: PostBookingTripPreviewStore,
  segmentTypeCheckActions: SegmentTypeCheckActions,
  bookTripAgainStore: BookTripAgainStore,
  bookTripAgainActions: BookTripAgainActions,
  bookTripAgainService: BookTripAgainService,
  postBookingAddSegmentAction: PostBookingAddSegmentAction,
})
