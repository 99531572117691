import styled, { css } from 'styled-components'
import { DelegateSwitchLayout } from '@fiji/enums'

export const DelegatesContainer = styled.div<{ layout: DelegateSwitchLayout }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) => {
    switch (p.layout) {
      case DelegateSwitchLayout.Explore:
        return css`
          margin: -35px auto 35px;
          padding: 12px 16px;
          background-color: ${(p) => p.theme.colors.white};
          width: calc(100% - 32px);
          border-radius: 10px;
        `

      case DelegateSwitchLayout.PostBooking:
        return css`
          padding: 4px 8px;
          border-radius: 8px;
          background-color: ${(p) => p.theme.colors.background1};
          margin-left: 8px;
          max-height: 32px;
        `
    }
  }}
`
