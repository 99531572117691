import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const AirlineLogoWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-top: 6px;
`

export const AirlineLogo = styled.img`
  width: inherit;
  height: inherit;
`

export const TicketDetails = styled.div`
  display: grid;
  gap: 16px 0;
  margin-top: 12px;
`

export const TicketDetail = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(2):nth-last-child(3) {
    grid-column: 2;
  }
`
