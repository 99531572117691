import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { TripDetailsCarServiceDetailsRules } from '@fiji/graphql/types'

type Props = {
  detailsRules?: TripDetailsCarServiceDetailsRules | null
}

const i18nBase = 'CarServiceDetails.DetailsAndRules'

export function DetailsAndRules({ detailsRules }: Props) {
  const { t } = useTranslation()

  const { generalMessage, chargeDisclaimer, chargeQualifier, cancelPolicy } = detailsRules ?? {}

  return (
    <Block marginHorizontal={16}>
      <Block marginVertical={24}>
        <Block marginBottom={8}>
          <Text variant="title3" color="mainText">
            {t(`${i18nBase}.GeneralMessage`)}
          </Text>
        </Block>
        <Text variant="body" color="bodyText">
          {generalMessage}
        </Text>
      </Block>
      <Block marginVertical={24}>
        <Block marginBottom={8}>
          <Text variant="title3" color="mainText">
            {t(`${i18nBase}.TermsAndConditions`)}
          </Text>
        </Block>
        <Text variant="body" color="bodyText">
          <Block marginBottom={20}>{chargeDisclaimer}</Block>
          <Block>{chargeQualifier}</Block>
        </Text>
      </Block>
      <Block marginVertical={24}>
        <Block marginBottom={8}>
          <Text variant="title3" color="mainText">
            {t(`${i18nBase}.CancellationPolicy`)}
          </Text>
        </Block>
        <Text variant="body" color="bodyText">
          {cancelPolicy}
        </Text>
      </Block>
    </Block>
  )
}
