import { cloneElement } from 'react'
import { StyledTextVariant } from '@fiji/enums'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { Container, IconWrapper, TitleWrapper, Title } from './row-desktop-styled'

export function RowDesktop({ iconSlot, children }: LayoutProps) {
  return (
    <Container>
      <IconWrapper>{cloneElement(iconSlot, { color: 'mainText1' })}</IconWrapper>

      <TitleWrapper>
        <Title>
          <Text variant={StyledTextVariant.subHeadMedium} color="mainText">
            {children}
          </Text>
        </Title>
      </TitleWrapper>
    </Container>
  )
}
