import type { CostSegment } from '@fiji/graphql/types'
import type { CostSegmentValueObject } from '../../core/value-objects/cost-segment.value-object'

export function toCostSegmentValueObject(input: CostSegment): CostSegmentValueObject {
  return {
    description: input.description ?? undefined,
    id: input.id,
    isActive: input.isActive,
    isRequired: input.isRequired,
    isSplitCostAllocation: input.isSplitCostAllocation,
    isSplitCostRequired: input.isSplitCostRequired,
    name: input.name,
    splitCostAllocationTooltip: input.splitCostAllocationTooltip ?? undefined,
    tooltip: input.tooltip ?? undefined,
  }
}
