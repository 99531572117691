import styled from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
`

export const Headline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Content = styled.div<{ isExpanded: boolean }>`
  transition: transform 0.3s ease-in-out, max-height 0.4s ease-in-out;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  max-height: ${(props) => (props.isExpanded ? '500px' : '0px')};
  transform: scaleY(${(props) => (props.isExpanded ? 1 : 0)});
  transform-origin: top;
`
