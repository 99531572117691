import { SegmentType } from '@etta/core/enums'
import { Service } from '@etta/di'
import type {
  HotelPostBookingValueObject,
  FlightPostBookingValueObject,
  CarRentalPostBookingValueObject,
  TrainPostBookingValueObject,
  SegmentPostBookingValueObject,
  CarServicePostBookingValueObject,
  HotelGroupedSegmentValueObject,
  FlightGroupedSegmentValueObject,
  CarRentalGroupedSegmentValueObject,
  TrainGroupedSegmentValueObject,
  CarServiceGroupedSegmentValueObject,
  GroupedSegmentValueObject,
} from '../../core/value-objects'

@Service()
export class PostBookingSegmentTypeCheckService {
  isHotelSegment(segment: SegmentPostBookingValueObject): segment is HotelPostBookingValueObject {
    if (segment.type === SegmentType.Hotel) {
      return true
    }
    return false
  }

  isGroupedHotel(segment: GroupedSegmentValueObject): segment is HotelGroupedSegmentValueObject {
    if (segment.type === SegmentType.Hotel) {
      return true
    }
    return false
  }

  isCarRentalSegment(
    segment: SegmentPostBookingValueObject,
  ): segment is CarRentalPostBookingValueObject {
    if (segment.type === SegmentType.CarRental) {
      return true
    }
    return false
  }

  isGroupedCarRental(
    segment: GroupedSegmentValueObject,
  ): segment is CarRentalGroupedSegmentValueObject {
    if (segment.type === SegmentType.CarRental) {
      return true
    }
    return false
  }

  isFlightSegment(segment: SegmentPostBookingValueObject): segment is FlightPostBookingValueObject {
    if (segment.type === SegmentType.Flight) {
      return true
    }
    return false
  }

  isGroupedFlight(segment: GroupedSegmentValueObject): segment is FlightGroupedSegmentValueObject {
    if (segment.type === SegmentType.Flight) {
      return true
    }
    return false
  }

  isCarServiceSegment(
    segment: SegmentPostBookingValueObject,
  ): segment is CarServicePostBookingValueObject {
    if (segment.type === SegmentType.CarService) {
      return true
    }
    return false
  }

  isGroupedCarService(
    segment: GroupedSegmentValueObject,
  ): segment is CarServiceGroupedSegmentValueObject {
    if (segment.type === SegmentType.CarService) {
      return true
    }
    return false
  }

  isTrainSegment(segment: SegmentPostBookingValueObject): segment is TrainPostBookingValueObject {
    if (segment.type === SegmentType.Train) {
      return true
    }
    return false
  }

  isGroupedTrain(segment: GroupedSegmentValueObject): segment is TrainGroupedSegmentValueObject {
    if (segment.type === SegmentType.Train) {
      return true
    }
    return false
  }
}
