import { Action, Inject } from '@etta/di'
import { EmployeeInfoStore } from '@etta/modules/user/interface/stores/employee-info.store'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import type { EmployeeInformationFieldsSettings } from '@etta/modules/settings/interface/stores/profile-field-settings/account-information-fields'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import type { EmployeeValueObject } from '../../core/value-objects/employee.value-object'
import type { MisValueObject } from '../../core/value-objects/mis.value-object'
import { UserProfileStore } from '../stores/user-profile.store'
import { UserProfileValidatorBuilder } from '../services/user-profile-validator-builder'

@Action()
export class EmployeeInfoActions {
  constructor(
    @Inject() private readonly employeeInfoStore: EmployeeInfoStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {}

  initializeEmployeeInfo() {
    const profile = this.userStore.profile
    const category = FieldSettingsCategory.EmployeeInformation

    this.setFormValue('isActiveInCompany', category, profile?.employee?.isActiveInCompany)
    this.setFormValue('employeeType', category, this.getDefaultFormValueByField('employeeType'))
    this.setFormValue('employeeId', category, profile?.employee?.employeeId, 'employeeID')
    this.setFormValue('jobTitle', category, profile?.employee?.jobTitle)
    this.setFormValue('jobLevel', category, this.getDefaultFormValueByField('jobLevel'))
    this.setFormValue('managerId', category, profile?.employee?.manager?.externalId, 'managerID')
    this.setFormValue('costCenter', category, this.getDefaultFormValueByField('costCenter'))
    this.setFormValue('departmentCode', category, this.getDefaultFormValueByField('departmentCode'))
    this.setFormValue('departmentName', category, this.getDefaultFormValueByField('departmentName'))
    this.setFormValue(
      'division',
      category,
      profile?.employee?.division || this.employeeInfoStore.getDefaultValue('divisionCode'),
      'divisionCode',
    )
    this.setFormValue('businessUnit', category, this.getDefaultFormValueByField('businessUnit'))
    this.setFormValue('companyName', category, this.getDefaultFormValueByField('companyName'))
    this.setFormValue(
      'expenseApproverId',
      category,
      profile?.approvers?.expenseApproverId,
      'expenseApproverName',
    )
    this.setFormValue(
      'travelApproverId',
      category,
      profile?.approvers?.travelApproverId,
      'travelApproverID',
    )

    this.setFormValue('mis1', category, this.getDefaultFormValueByMisField('mis1'))
    this.setFormValue('mis2', category, this.getDefaultFormValueByMisField('mis2'))
    this.setFormValue('mis3', category, this.getDefaultFormValueByMisField('mis3'))
    this.setFormValue('mis4', category, this.getDefaultFormValueByMisField('mis4'))
    this.setFormValue('mis5', category, this.getDefaultFormValueByMisField('mis5'))
    this.setFormValue('mis6', category, this.getDefaultFormValueByMisField('mis6'))
    this.setFormValue('mis7', category, this.getDefaultFormValueByMisField('mis7'))

    this.employeeInfoStore.setField(
      'purchaseApproverId',
      this.getStrValueOr(profile?.approvers?.purchaseApproverId),
    ) // Missing FieldsSettings?
  }

  handleEmployeeInfoFieldChange(key: Parameters<typeof this.employeeInfoStore.setField>[0]) {
    return (value: string | boolean) => this.employeeInfoStore.setField(key, value)
  }

  initializeEmployeeInfoAdditionalInfoForm() {
    const additionalInformation =
      this.userProfileStore.userProfile?.employeeInformation.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.employeeInfoStore.setAdditionalInfoValidator(validator)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) =>
      this.employeeInfoStore.setAdditionalInfoValue(key, value)
  }

  private setFormValue(
    validatorKey: Parameters<typeof this.employeeInfoStore.setField>[0],
    category: FieldSettingsCategory,
    value?: string | boolean | null,
    profileSettingKey?: string,
  ) {
    const profileSettingField = this.employeeInfoStore.fieldSettings[category][
      profileSettingKey || validatorKey
    ]

    if (!value || !profileSettingField?.isRenderedOnActivationPage) {
      return
    }

    const initializedValue = typeof value === 'string' ? this.getStrValueOr(value) : value
    this.employeeInfoStore.setField(validatorKey, initializedValue)
  }

  private getDefaultFormValueByField(
    field: keyof EmployeeValueObject & keyof typeof EmployeeInformationFieldsSettings,
  ) {
    const employeeFieldValue = this.userStore.profile?.employee?.[field]
    return employeeFieldValue || this.employeeInfoStore.getDefaultValue(field)
  }

  private getDefaultFormValueByMisField(
    field: keyof MisValueObject & keyof typeof EmployeeInformationFieldsSettings,
  ) {
    const mis = this.userStore.profile?.mis?.[field]
    return mis || this.employeeInfoStore.getDefaultValue(field)
  }

  private getStrValueOr(value?: string | null, defaultValue: string = '') {
    if (value) {
      return value
    }
    return defaultValue
  }
}
