import type { TripReviewDateType } from '@fiji/utils/dates'
import { getTime, separateLabels } from '../../utils'

export const getDatesLabel = (
  checkInDate: string,
  checkOutDate: string,
  type: TripReviewDateType,
  separator: string,
) => {
  return separateLabels([getTime(checkInDate, type), getTime(checkOutDate, type)], separator)
}

export const getRoomTypeLabel = (roomCount: number, roomType: string, separator: string) => {
  return separateLabels([roomCount, roomType], separator)
}
