import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field'
import { PreSearchCustomFieldsLayout } from '@etta/components/pre-search-custom-fields/layout/pre-search-custom-fields-layout'

type Props = {
  customFields: CustomFieldCollectionItemExtension[]
  values: Record<string, any>
  errors: Record<string, string>
  onFieldChange(field: string): (value: any) => void
  onBack(): void
  isVisible: boolean
  onSubmit(): void
}

export function PreSearchCustomFields(props: Props) {
  return <PreSearchCustomFieldsLayout {...props} />
}
