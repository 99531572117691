import { useTranslation } from 'react-i18next'
import type { SeatmapCabinClass } from '@fiji/graphql/types'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import type { ConfirmationNumberData } from '@etta/screens/payment-summary/types'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Skeleton } from '@etta/ui/skeleton'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { SubTitle } from '../../titles'

type Props = {
  origin: string
  destination: string
  departDate: string
  airlineName?: string
  cabinClass?: SeatmapCabinClass
  confirmationData?: ConfirmationNumberData
  isTripLoading?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function FlightRow({
  origin,
  destination,
  departDate,
  cabinClass,
  confirmationData,
  isTripLoading,
}: Props) {
  const { t } = useTranslation()
  const { getCabinClassLabel } = useCabinClassLabels()
  const cabinClassLabel = getCabinClassLabel(cabinClass)
  const usedCabinClassLabel = cabinClassLabel ? `(${cabinClassLabel}) ` : ''
  const date = tripReviewFormatDate(TripReviewDateType.DayMonthDateAndTime, departDate)
  const flightRowLabel = t(i18nBase + 'FlightRowLabel', {
    origin,
    destination,
    class: usedCabinClassLabel,
    date,
  })
  const showConfirmationData =
    !isTripLoading && confirmationData?.confirmationNumber && confirmationData?.companyName

  return (
    <Block
      marginBottom={12}
      aria-label={flightRowLabel}
      data-tracking-id="flight-origin-destination-container">
      <SubTitle data-tracking-id="flight-origin-destination-title">
        <Text variant="footnoteStrong" color="mainText">
          <span data-tracking-id="flight-origin-text">{origin}</span>
          <Icon name="arrowForwardPWA" size="small" />
          <span data-tracking-id="flight-destination-text">{destination + ' '}</span>
          <span data-tracking-id="flight-cabin-class-text">{usedCabinClassLabel}</span>
          <span data-tracking-id="flight-date-text">{' ' + date}</span>
        </Text>
      </SubTitle>
      {isTripLoading && (
        <Skeleton width="188px" height="19px">
          <rect width="188px" height="19px" />
        </Skeleton>
      )}
      {showConfirmationData && (
        <SubTitle data-tracking-id="flight-confirmation-number">
          <span data-tracking-id="flight-confirmation-number-text">
            <span data-tracking-id="flight-airline-name-class-text">
              {confirmationData.companyName + ' '}
            </span>
            {t(i18nBase + 'Confirmation')} {confirmationData.confirmationNumber}
          </span>
        </SubTitle>
      )}
    </Block>
  )
}
