import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useUberUniversalLink } from '@fiji/hooks/mobility/use-uber-universal-link'
import { MobilitySearchResultErrorType } from './mobility-search-result-error-type.enum'
import NoRideSVG from './assets/no-ride-result.svg?url'
import RideHailPaymentSVG from './assets/ride-hail-payment.svg?url'
import RideHailSearchErrorDistanceExceeded from './assets/ride-hail-search-error-distance-exceeded.svg?url'

type Props = {
  type?: MobilitySearchResultErrorType
  onRetry: () => void
  onEditSearch: () => void
}

export function useMobilitySearchResultError({ type: errorType, onRetry, onEditSearch }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Mobility.SearchPage.Dialog.Error.'

  const { searchRideHailStore } = useRideHailSearchContext()
  const {
    pickUpPlace,
    pickUpDate,
    pickUpTime,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm
  const { openUberAppWithoutParams, openUberApp } = useUberUniversalLink({
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  })

  const getImage = useMemo(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.ErrorPaymentMethodNotSet:
        return RideHailPaymentSVG
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        return null
      case MobilitySearchResultErrorType.DistanceExceeded:
        return RideHailSearchErrorDistanceExceeded
      default:
        return NoRideSVG
    }
  }, [errorType])

  const getWarningText = useMemo(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.NoAdvancedBookingAvailable:
        return t(i18nBase + 'NoAdvancedBookingAvailable')
      case MobilitySearchResultErrorType.NoRidesInLocation:
        return t(i18nBase + 'NoRidesAvailableInLocation')
      case MobilitySearchResultErrorType.ErrorPaymentMethodNotSet:
        return t(i18nBase + 'UpdatePayment')
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
        return t('Mobility.SearchPage.Dialog.OutOfPolicy')
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        return t('Mobility.SearchPage.Dialog.LocationUnavailable')
      case MobilitySearchResultErrorType.DistanceExceeded:
        return t(i18nBase + 'DistanceExceeded')
      case MobilitySearchResultErrorType.AirportPickupNotAvailable:
        return t(i18nBase + 'ReserveAirportPickupNotAvailable')
      case MobilitySearchResultErrorType.ArrivalFlightNotFound:
        return t('Mobility.SearchPage.Dialog.ArrivalFlightNotFound')
      default:
        return t(i18nBase + 'Unexpected')
    }
  }, [t, errorType])

  const getDescription = useMemo(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.NoAdvancedBookingAvailable:
        return t(i18nBase + 'NoAdvancedBookingAvailableDescription')
      case MobilitySearchResultErrorType.NoRidesInLocation:
        return t(i18nBase + 'NoRidesAvailableInLocationDescription')
      case MobilitySearchResultErrorType.ErrorPaymentMethodNotSet:
        return t(i18nBase + 'UpdatePaymentDescription')
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
        return t('Mobility.SearchPage.Dialog.DescriptionOutOfPolicy')
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        return t('Mobility.SearchPage.Dialog.DescriptionOutSideUS')
      case MobilitySearchResultErrorType.DistanceExceeded:
        return t(i18nBase + 'DistanceExceededDescription')
      case MobilitySearchResultErrorType.AirportPickupNotAvailable:
        return t(i18nBase + 'ReserveAirportPickupNotAvailableDescription')
      case MobilitySearchResultErrorType.ArrivalFlightNotFound:
        return t('Mobility.SearchPage.Dialog.DescriptionArrivalFlightNotFound')
      default:
        return t(i18nBase + 'UnexpectedDescription')
    }
  }, [t, errorType])

  const getButtonText = useMemo(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.ArrivalFlightNotFound:
        return t(i18nBase + 'GoToUberButton')
      case MobilitySearchResultErrorType.NoAdvancedBookingAvailable:
      case MobilitySearchResultErrorType.NoRidesInLocation:
      case MobilitySearchResultErrorType.AirportPickupNotAvailable:
      case MobilitySearchResultErrorType.DistanceExceeded:
        return t(i18nBase + 'EditSearchButton')
      case MobilitySearchResultErrorType.ErrorPaymentMethodNotSet:
        return t(i18nBase + 'UpdateUberButton')
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
        return t(i18nBase + 'CloseButton')
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        return t(i18nBase + 'BookInUberButton')
      default:
        return t(i18nBase + 'TryAgainButton')
    }
  }, [t, errorType])

  const handleClick = useCallback(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.NoAdvancedBookingAvailable:
      case MobilitySearchResultErrorType.NoRidesInLocation:
      case MobilitySearchResultErrorType.DistanceExceeded:
      case MobilitySearchResultErrorType.AirportPickupNotAvailable:
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
        onEditSearch()
        return
      case MobilitySearchResultErrorType.ErrorPaymentMethodNotSet:
      case MobilitySearchResultErrorType.ArrivalFlightNotFound:
        openUberAppWithoutParams()
        return
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        openUberApp()
        return
      default:
        onRetry()
    }
  }, [errorType, onEditSearch, onRetry, openUberAppWithoutParams, openUberApp])

  const getStyle = useMemo(() => {
    switch (errorType) {
      case MobilitySearchResultErrorType.AirportPickupOutOfPolicy:
      case MobilitySearchResultErrorType.ErrorLocationUnavailable:
        return {
          warningTextVariant: StyledTextVariant.title2,
          descriptionTextVariant: StyledTextVariant.body,
        }
      default:
        return {
          iconHeight: 84,
          warningTextVariant: StyledTextVariant.headline,
          descriptionTextVariant: StyledTextVariant.footnoteMedium,
        }
    }
  }, [errorType])

  return {
    getImage,
    getWarningText,
    getDescription,
    getButtonText,
    getStyle,
    handleClick,
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  }
}
