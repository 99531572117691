import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { Container, ClearButton, ApplyButton } from './filters-styled'

const i18Base = 'HotelFilters'

export const Footer = observer(function Footer() {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()
  const { t } = useTranslation()

  return (
    <Container>
      <ClearButton
        disabled={hotelFiltersStore.isClearButtonDisabled}
        onClick={hotelFiltersAction.handleClearAllFilters}
        variant="link">
        {t(i18Base + '.ClearAll')}
      </ClearButton>
      <ApplyButton
        disabled={hotelFiltersStore.isFiltersEqual}
        onClick={hotelFiltersAction.handleApplyFilters}>
        {t(i18Base + '.ApplyFilters')}
      </ApplyButton>
    </Container>
  )
})
