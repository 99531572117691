import * as R from 'ramda'
import { useMemo } from 'react'
import type { DailyRate, Money, Room } from '@fiji/graphql/types'
import type { Currency } from '@fiji/store/user'

export function getRoomsAverage({
  shouldShowAverage,
  primary,
  dailyRates,
  selectedCurrency,
}: {
  shouldShowAverage: boolean
  primary?: Money
  dailyRates?: DailyRate[]
  selectedCurrency?: Currency
}) {
  const isAverageAvailable = shouldShowAverage && dailyRates?.length
  if (!dailyRates || !isAverageAvailable) {
    return primary
  }

  const average = R.converge(R.divide, [R.sum, R.length])
  return {
    amount: average(
      dailyRates.map(({ rate }) => {
        if (!selectedCurrency) {
          return rate.secondary ? rate.secondary.amount : rate.primary.amount
        }
        return selectedCurrency?.name === rate.secondary?.currency && rate.secondary
          ? rate.secondary.amount
          : rate.primary.amount
      }),
    ),
    currency: !selectedCurrency
      ? dailyRates[0].rate.secondary
        ? dailyRates[0].rate.secondary.currency
        : dailyRates[0].rate.primary.currency
      : selectedCurrency?.name === dailyRates[0].rate.secondary?.currency &&
        dailyRates[0].rate.secondary
      ? dailyRates[0].rate.secondary.currency
      : dailyRates[0].rate.primary.currency,
  }
}

export function useRoomsAverage({
  shouldShowAverage,
  primary,
  dailyRates,
  selectedCurrency,
}: {
  shouldShowAverage: boolean
  primary?: Money
  dailyRates?: DailyRate[]
  selectedCurrency?: Currency
}): Money | undefined {
  return useMemo(() => {
    return getRoomsAverage({
      primary,
      dailyRates,
      shouldShowAverage,
      selectedCurrency,
    })
  }, [primary, dailyRates, shouldShowAverage, selectedCurrency])
}

export function useRoomsAverageMap(
  rooms?: Room[] | null,
  selectedCurrency?: Currency,
): Money | undefined {
  const room = rooms && rooms[0]
  let averageRate, dailyRates
  if (room) {
    averageRate = room.averageRate
    dailyRates = room.dailyRates
  }
  const shouldShowAverage = checkExistDailyRatesAverage(dailyRates)
  const primaryRate =
    selectedCurrency?.name === averageRate?.secondary?.currency && averageRate?.secondary
      ? averageRate?.secondary
      : averageRate?.primary
  return useRoomsAverage({ shouldShowAverage, primary: primaryRate, dailyRates, selectedCurrency })
}

export function checkExistDailyRatesAverage(dailyRates?: DailyRate[]): boolean {
  return !!(dailyRates && dailyRates?.length > 1)
}

export function getRoomsSubtotal(shouldShowAverage: boolean, dailyRates: DailyRate[]) {
  const isSubtotalAvailable = shouldShowAverage && dailyRates && dailyRates.length
  if (!isSubtotalAvailable) {
    return undefined
  }
  return {
    amount: dailyRates.reduce(
      (sum, { rate }) => sum + (rate.secondary?.amount ?? rate.primary.amount),
      0,
    ),
    currency: dailyRates[0].rate.secondary?.currency ?? dailyRates[0].rate.primary.currency,
  }
}

export function useRoomsSubtotal(
  _: Money,
  dailyRates: DailyRate[],
  shouldShowAverage: boolean,
): Money | undefined {
  return useMemo(() => {
    return getRoomsSubtotal(shouldShowAverage, dailyRates)
  }, [dailyRates, shouldShowAverage])
}
