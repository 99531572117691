import { Inject, Service } from '@etta/di'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { AddCreditCardInputValueObject } from '../../core/value-objects/add-credit-card-input.value-object'
import type { EditCreditCardInputValueObject } from '../../core/value-objects/edit-credit-card-input.value-object'
import { SettingsAdapter } from '../../infra/settings.adapter'
import { BaseSettingsStore } from '../stores/base.store'
import { PaymentInformationStore } from '../stores/payment-information.store'
import { BaseSettingsService } from './base.service'

@Service()
export class PaymentInformationService {
  constructor(
    @Inject() private baseSettingsService: BaseSettingsService,
    @Inject() private settingsAdapter: SettingsAdapter,
    @Inject() private paymentInformationStore: PaymentInformationStore,
    @Inject() private readonly baseSettingsStore: BaseSettingsStore,
  ) {}

  async getCreditCards() {
    this.paymentInformationStore.setIsCreditCardsLoading(true)
    this.paymentInformationStore.setIsCreditCardError(false)
    this.paymentInformationStore.setCreditCards(null)

    const result = await this.settingsAdapter.getCreditCards()
    const { preferredCreditCards } = this.baseSettingsService.getStructuredProfile()

    result.match({
      Ok: (creditCards) => {
        this.paymentInformationStore.setCreditCards(creditCards)
        this.paymentInformationStore.setPreferredCreditCards({
          creditCards,
          preferredCreditCards,
        })
        this.paymentInformationStore.setIsCreditCardsLoading(false)
      },
      Err: () => {
        this.paymentInformationStore.setIsCreditCardError(true)
        this.paymentInformationStore.setIsCreditCardsLoading(false)
      },
    })
  }

  async getSiteCards() {
    this.paymentInformationStore.setIsSiteCardsLoading(true)
    this.paymentInformationStore.setIsSiteCardError(false)
    this.paymentInformationStore.setSiteCards(null)

    const result = await this.settingsAdapter.getSiteCards()

    result.match({
      Ok: (siteCards) => {
        this.paymentInformationStore.setSiteCards(siteCards)
        this.paymentInformationStore.setPreferredSiteCards(siteCards)
        this.paymentInformationStore.setIsSiteCardsLoading(false)
      },
      Err: () => {
        this.paymentInformationStore.setIsSiteCardError(true)
        this.paymentInformationStore.setIsSiteCardsLoading(false)
      },
    })
  }

  async addCreditCard({
    input,
    handleClose,
  }: {
    input: AddCreditCardInputValueObject
    handleClose?: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.addCreditCard(input)

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return result
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(300)
    this.baseSettingsStore.dropState(handleClose)
    this.paymentInformationStore.setSelectedCardId(result.getValue().legacyId)
    this.paymentInformationStore.newCardPresetsToggle.handleOpen()
  }

  async setNewCreditCardsPresets({
    input,
    shouldEditProfile,
    handleClose,
  }: {
    input: EditCreditCardInputValueObject
    shouldEditProfile?: boolean
    handleClose: () => void
  }) {
    if (shouldEditProfile) {
      this.baseSettingsStore.setSavingState()

      const userResult = await this.baseSettingsService.saveUser({
        mode: 'new-credit-card',
        input: input.userInput,
        handleClose,
      })

      if (userResult.isErr()) {
        this.baseSettingsStore.setErrorState()

        return
      }
    } else {
      this.baseSettingsStore.setSavedState()
    }

    this.paymentInformationStore.setSelectedCardId(null)
    await this.getCreditCards()
    this.baseSettingsStore.dropState(handleClose)
  }

  async editCreditCard({
    input,
    handleClose,
    shouldEditCard,
    shouldEditProfile,
  }: {
    input: EditCreditCardInputValueObject
    handleClose: () => void
    shouldEditCard?: boolean
    shouldEditProfile?: boolean
  }) {
    this.baseSettingsStore.setSavingState()

    const { cardInput, userInput } = input

    if (shouldEditCard && cardInput) {
      const cardResult = await this.settingsAdapter.editCreditCard(cardInput)

      if (cardResult.isErr()) {
        this.baseSettingsStore.setErrorState()
        return
      }
    }

    if (shouldEditProfile) {
      const userResult = await this.baseSettingsService.saveUser({
        mode: 'new-credit-card',
        input: userInput,
        handleClose,
      })

      if (userResult.isErr()) {
        this.baseSettingsStore.setErrorState()

        return
      }
    } else {
      this.baseSettingsStore.setSavedState()
    }

    await this.getCreditCards()
    this.baseSettingsStore.dropState(handleClose)
  }

  async deleteCreditCard({
    id,
    handleClose,
    handleCloseFirstDialog,
  }: {
    id: string
    handleClose: () => void
    handleCloseFirstDialog: () => void
  }) {
    handleCloseFirstDialog()
    this.baseSettingsStore.setDeletingState()
    const result = await this.settingsAdapter.deleteCreditCard({ creditCardId: id })

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setDeletedState()
    await delayForSuccess(300)
    this.baseSettingsStore.dropState(handleClose)
    await this.getCreditCards()
  }
}
