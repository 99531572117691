import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { body } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  line-height: 26px;
  ${(p) => p.theme.colors.mainText};
  ${body};
`

export const StyledText = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))<{ marginTop?: number }>`
  line-height: 19px;
  margin-top: ${({ marginTop = 4 }) => marginTop}px;

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const SectionLogo = styled.div`
  img {
    display: block;
    height: 24px;
  }
`
