import { MediaLayout } from '@etta/ui/media-layout'
import type { TooltipProps } from './types'
import { UnusedTicketBreakdownTooltipDesktop } from './layout/desktop/unused-ticket-breakdown-tooltip-desktop'
import { UnusedTicketBreakdownTooltipMobile } from './layout/mobile/unused-ticket-breakdown-tooltip-mobile'

export function UnusedTicketBreakdownTooltipLayout(props: TooltipProps) {
  return (
    <MediaLayout
      mobileSlot={<UnusedTicketBreakdownTooltipMobile {...props} />}
      desktopSlot={<UnusedTicketBreakdownTooltipDesktop {...props} />}
    />
  )
}
