import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { UseControlsParams } from './types'

export function useControlsLabels({
  date,
  dateEnd,
  endTitle,
  startTitle,
  titleFormatter,
}: UseControlsParams) {
  const { t } = useTranslation()
  const type = useScreenType()
  const startDateText = startTitle ?? t('DatePicker.Start')
  const endDateText = endTitle ?? t('DatePicker.End')
  const isMobile = type === ScreenType.Mobile

  const startDateTitle = date ? titleFormatter(date, isMobile) : startDateText
  const endDateTitle = dateEnd ? titleFormatter(dateEnd, isMobile) : endDateText
  const startDateAriaLabel = date
    ? t('DatePicker.AriaLabel', { label: startDateText, value: startDateTitle })
    : startDateText
  const endDateAriaLabel = dateEnd
    ? t('DatePicker.AriaLabel', { label: endDateText, value: endDateTitle })
    : endDateText

  return {
    startDateTitle,
    endDateTitle,
    startDateAriaLabel,
    endDateAriaLabel,
  }
}
