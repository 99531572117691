import styled from 'styled-components'
import { title3 } from '@fiji/style'

export const BlockTitle = styled.h6`
  margin: 40px 0 4px;
  color: ${(p) => p.theme.colors.mainText};
  ${title3};
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    margin-right: 6px;
  }

  > div {
    margin-right: 18px;
  }
`
