import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { StyledTextVariant } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import { LocationSearchField } from '@etta/components/location-search-field/location-search-field'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { usePlacesStorageContext } from '@etta/modules/places-storage/interface/use-places-storage.context'
import { ErrorBlock, ErrorText } from './location-section-mobile-styled'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationSectionMobile = observer(function LocationSectionMobile() {
  const { placesStorageStore } = usePlacesStorageContext()
  const placesHistory = placesStorageStore.getPlaces('rail')

  const {
    railSearchFormActions,
    railSearchFormStore,
    railSearchButtonActions,
  } = useRailSearchFormContext()
  const { originPlace, destinationPlace } = railSearchFormStore.railForm

  const { t } = useTranslation()
  const fromLabel = t(i18Base + '.Input.From')
  const toLabel = t(i18Base + '.Input.To')

  return (
    <>
      <Block paddingHorizontal={16} paddingVertical={6}>
        <InputButtonGroup
          aria-label={t(i18Base + '.Input.Depart')}
          direction={InputButtonGroupDirection.Vertical}
          isToggle
          onToggle={railSearchFormActions.onSwitchLocations}>
          <LocationSearchField
            onChange={railSearchFormActions.onOriginPlaceChange}
            value={originPlace}
            label={fromLabel}
            latestPlaces={placesHistory}
            aria-label={
              originPlace
                ? t(i18Base + '.Input.PlaceSelectedAriaLabel', {
                    label: fromLabel,
                    value: originPlace.name,
                  })
                : t(i18Base + '.Input.PlaceAriaLabel', { label: fromLabel })
            }
            inputAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', { label: fromLabel })}
            isOutboundTrip
            useNewLocationSearch
            hasCurrentLocationSearch
            searchType="train"
            recentSearchesType="rail"
            customModalDescription={t(i18Base + '.CustomRailLocationModalTitle')}
          />
          <LocationSearchField
            onChange={railSearchFormActions.onDestinationPlaceChange}
            value={destinationPlace}
            label={toLabel}
            aria-label={
              destinationPlace
                ? t(i18Base + '.Input.PlaceSelectedAriaLabel', {
                    label: toLabel,
                    value: destinationPlace.name,
                  })
                : t(i18Base + '.Input.PlaceAriaLabel', { label: toLabel })
            }
            inputAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', { label: toLabel })}
            latestPlaces={placesHistory}
            useNewLocationSearch
            hasCurrentLocationSearch
            searchType="train"
            recentSearchesType="rail"
            customModalDescription={t(i18Base + '.CustomRailLocationModalTitle')}
          />
        </InputButtonGroup>
      </Block>

      {railSearchButtonActions.isErrorInput && (
        <ErrorBlock>
          <Icon name="errorFilledPWA" size="normal" color="error" />
          <ErrorText textVariant={StyledTextVariant.captionStrong}>
            {t(i18Base + '.Input.DestinationError')}
          </ErrorText>
        </ErrorBlock>
      )}
    </>
  )
})
