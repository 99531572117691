import { Inject, Service } from '@etta/di'
import { UserStore } from '@etta/modules/user'
import { ROUTES } from '@fiji/routes'
import { ScreenType, screenMatcher } from '@fiji/modes'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { CreatePasswordStore } from '../stores/create-password.store'

@Service()
export class MainPageService {
  constructor(
    @Inject() private userStore: UserStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
    @Inject() private createPasswordStore: CreatePasswordStore,
  ) {}

  getMainPage() {
    // TODO: FRI-493 - remove isUserActivationEnabled for the conditional when the user activation flow implementation is finished

    const isActivationRequired =
      this.featureFlagsStore.flags.isUserActivationEnabled && this.userStore.isActivationRequired
    const isPasswordResetRequired =
      this.userStore.isResetPassword && !this.createPasswordStore.isSSOOnlySite
    const isDesktop = screenMatcher.getScreenType() !== ScreenType.Mobile

    if (isDesktop && (isActivationRequired || isPasswordResetRequired)) {
      return ROUTES.userActivation
    }
    return ROUTES.explore
  }
}
