import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Header } from '@etta/ui/header'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { FlightPreferencesModal } from '@etta/components/travel-preferences/flight-preferences-modal/flight-preferences-modal'
import { HotelPreferencesModal } from '@etta/components/travel-preferences/hotel-preferences-modal/hotel-preferences-modal'
import { CarRentalPreferencesModal } from '@etta/components/travel-preferences/car-rental-preferences-modal/car-rental-preferences-modal'
import { PurchaseUserInfo } from '@etta/screens/traveler-info/purchase-user-info/purchase-user-info'
import { CostWithCta } from '@etta/components/footer-costs/cost-with-cta/cost-with-cta'
import { PassportForm } from '@etta/components/passport/passport-form'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { RailPreferencesModal } from '@etta/components/travel-preferences/rail-preferences-modal/rail-preferences-modal'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { RailLayoutType } from '@fiji/enums'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { useUserContext } from '@etta/modules/user'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { TravelerInfoContent } from './traveler-info-content'
import { TravelerInfoLayout } from './layout'
import { FooterSkeleton } from './skeletons'
import { Title } from './traveler-info-styled'

const i18nBase = 'TravelerInfo.'
const i18nCostBase = 'CostWithCTA.'

export const TravelerInfo = observer(function TravelerInfo() {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const { purchaseInfoActions } = usePurchaseInfoContext()
  const { checkoutInfoStore } = useCheckoutInfoContext()
  const { profile } = userStore

  const {
    travelerInfo,
    isContinueButtonDisabled,
    setContinueButtonDisabled,
    paymentSummary,
  } = useCheckoutContextSelector((state) => state)
  const {
    viewStateParams,
    isHoldFlow,
    onSaveProfile,
    tripRate,
    flightPreferences,
    carRentalPreferences,
    hotelPreferences,
    isProfileValid,
    personalInfo,
    primaryTraveler,
    profileErrorMessage,
    segmentsState,
    isError,
    isLoading,
    isFlightsExist,
    contentRef,
    primaryTravelerOptions,
    bookingId,
    segmentsToRender,
    membershipState,
    availableToSelect,
    isPassportRequired,
    passports,
    newPassportToggle,
    existingPassportToggle,
    isPassportSelected,
    updateEditPassport,
    editPassportId,
    onBack,
    onSubmit,
    onReload,
    startOverModalSlot,
    isShowDelegateName,
    profileOptions,
    railPreferences,
    isCostDisplayed,
    isRailExist,
  } = travelerInfo

  const headerText = isHoldFlow ? t(`HoldTrip.HoldTrip`) : t(`${i18nBase}Header`)
  const delegateGuestText = t(`Delegate.BookingFor`, { user: 'Guest' })
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const shouldShowGuestLabel = isMobile && userStore.isGuest

  return (
    <>
      <TravelerInfoLayout
        contentRef={contentRef}
        headerSlot={
          <Header
            layout="sub-title"
            leftSlot={<Header.BackButton color="mainText2" onClick={onBack} />}
            backgroundColor="background2">
            <Title>{headerText}</Title>
            {isShowDelegateName && (
              <Block isFlexBlock>
                <DelegateName isWithBooking color="mainText2" />
              </Block>
            )}
            {shouldShowGuestLabel && !isShowDelegateName && (
              <Block isFlexBlock color="white">
                <DelegateName
                  variant="footnoteMedium"
                  withSmallIcon
                  name={delegateGuestText}
                  color="white"
                />
              </Block>
            )}
          </Header>
        }
        contentSlot={
          <TravelerInfoContent
            isStationTicketMachineDisabled={railPreferences.value.isStationTicketMachineDisabled}
            departureStationName={railPreferences.departureStationName}
            isError={isError}
            onReload={onReload}
            isLoading={isLoading}
            profileErrorMessage={profileErrorMessage}
            displayedPersonalInfo={personalInfo}
            primaryTraveler={primaryTraveler}
            primaryTravelerOptions={primaryTravelerOptions}
            isProfileValid={isProfileValid}
            isPassportRequired={isPassportRequired}
            profileModalOnOpen={purchaseInfoActions.onOpenProfileModal}
            passports={passports}
            newPassportToggle={newPassportToggle}
            updateEditPassport={updateEditPassport}
            isPassportSelected={isPassportSelected}
            segmentsToRender={segmentsToRender}
            segmentsState={segmentsState}
            hotelPreferences={hotelPreferences.value}
            hotelPreferencesOnEdit={hotelPreferences.hotelPreferencesModalToggle.handleOpen}
            membershipState={membershipState}
            availableToSelectHotel={availableToSelect.hotel}
            carPreferences={carRentalPreferences.value}
            carPreferencesOnEdit={carRentalPreferences.carRentalPreferencesModalToggle.handleOpen}
            availableToSelectCar={availableToSelect.car}
            availableToSelectFlight={availableToSelect.air}
            flightPreferences={flightPreferences.composedFlightPreferences}
            flightPreferencesOnEdit={flightPreferences.flightModalToggle.handleOpen}
            onSubmit={onSubmit}
            railPreferences={railPreferences.value}
            availableToSelectRail={availableToSelect.rail}
            railPreferencesOnEdit={railPreferences.railPreferencesModalToggle.handleOpen}
            onPassportError={setContinueButtonDisabled}
            isRailEticket={railPreferences.value.isETicket}
            showShareTravelerContact={checkoutInfoStore?.showTravelerContact}
          />
        }
        footerSlot={
          <Swap is={isLoading} isSlot={<FooterSkeleton />}>
            <CostWithCta
              rate={tripRate}
              onSubmit={onSubmit}
              isError={isError}
              isCostDisplayed={isCostDisplayed}
              data-tracking-id="traveler-info-continue-button"
              isDisabled={isContinueButtonDisabled}
              label={
                paymentSummary.isRailAccreditationEnabled
                  ? t(i18nCostBase + 'TotalEstimatedCostRailAccreditation')
                  : undefined
              }
            />
          </Swap>
        }
      />

      {personalInfo && (
        <PurchaseUserInfo
          profileOptions={profileOptions}
          personalInfo={personalInfo}
          primaryTraveler={primaryTraveler}
          isOpen={purchaseInfoActions.isProfileModalOpen()}
          onSubmit={onSaveProfile}
          onClose={purchaseInfoActions.closeProfileModal}
          travelerOptions={primaryTravelerOptions}
          isPostPurchase={!!bookingId}
          isAir={isFlightsExist}
          isRail={isRailExist}
          isKtnHidden={true}
          isRedressNumber={true}
          preferenceExcerpt={{
            knownTravelerNumber: flightPreferences.knownTravelerNumber,
            knownTravelerIssuingCountry: flightPreferences.knownTravelerIssuingCountry,
            redressNumber: flightPreferences.redressNumber,
          }}
        />
      )}

      {flightPreferences.flightTravelPreferences && (
        <FlightPreferencesModal
          isOpen={flightPreferences.flightModalToggle.isOpen}
          onClose={flightPreferences.flightModalToggle.handleClose}
          mealOptions={flightPreferences.mealOptions}
          loyaltyProgramOptions={flightPreferences.airMemberships}
          loyaltyFlightValue={flightPreferences.loyaltyFlightValue}
          specialRequestsOptions={flightPreferences.airSpecialRequests}
          notification={profile?.notification || null}
          preference={flightPreferences.flightTravelPreferences}
          onChangeLoyaltyFlight={flightPreferences.onChangeLoyaltyFlight}
          onChangeLoyaltyFlightToRender={flightPreferences.onChangeLoyaltyFlightToRender}
          onSubmit={flightPreferences.onFlightSubmit}
          isDisabled={flightPreferences.isDisabled}
          flightLoyaltyErrors={flightPreferences.flightLoyaltyErrors}
        />
      )}

      <HotelPreferencesModal
        isOpen={hotelPreferences.hotelPreferencesModalToggle.isOpen}
        onClose={hotelPreferences.hotelPreferencesModalToggle.handleClose}
        specialRequest={hotelPreferences.hotelSpecialRequest}
        loyaltyProgram={hotelPreferences.hotelLoyaltyProgram}
        existingMembership={hotelPreferences.existingMembership}
        onSubmit={hotelPreferences.onHotelPreferencesSubmit}
        disabledMemberships={membershipState.hotelMembershipsDisabled}
      />

      <CarRentalPreferencesModal
        isOpen={carRentalPreferences.carRentalPreferencesModalToggle.isOpen}
        onClose={carRentalPreferences.carRentalPreferencesModalToggle.handleClose}
        loyaltyProgram={carRentalPreferences.carRentalLoyaltyProgram}
        existingMembership={carRentalPreferences.existingMembership}
        specialRequest={carRentalPreferences.carRentalSpecialRequest}
        onSubmit={carRentalPreferences.onCarRentalPreferencesSubmit}
      />

      <RailPreferencesModal
        isOpen={railPreferences.railPreferencesModalToggle.isOpen}
        onClose={railPreferences.railPreferencesModalToggle.handleClose}
        loyaltyProgram={railPreferences.railLoyaltyProgram}
        existingMembership={railPreferences.existingMembership}
        onSubmit={railPreferences.onRailPreferencesSubmit}
        railLayout={RailLayoutType.UK}
        isEticket={railPreferences.value.isETicket}
        isStationTicketMachineDisabled={railPreferences.value.isStationTicketMachineDisabled}
        departureStationName={railPreferences.departureStationName}
        isEticketDisabled={railPreferences.value.isEticketDisabled}
      />

      <PassportForm
        isVisible={newPassportToggle.isOpen}
        handleModalVisibility={newPassportToggle.handleClose}
      />

      <PassportForm
        isVisible={existingPassportToggle.isOpen}
        handleModalVisibility={existingPassportToggle.handleClose}
        passport={passports?.find((passport) => passport.id && passport.id === editPassportId)}
        isPassportDisabled
      />

      <ViewStateModal viewState={viewStateParams.viewState} onDone={viewStateParams.onClose} />

      {startOverModalSlot}
    </>
  )
})
