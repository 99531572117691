import { Container, Mode } from './train-modes-styled'
import type { Props } from './type'
import { useTrainModes } from './use-train-modes'

export function TrainModes(props: Props) {
  const { modes } = useTrainModes(props)
  return (
    <Container>
      {modes.map((modeContent, index) => (
        <Mode key={index}>{modeContent}</Mode>
      ))}
    </Container>
  )
}
