import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 324px;
  height: 599px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.mainText};
  border-radius: 14px;
  overflow: hidden;
`

export const Wrap = styled.div`
  width: 85%;
`

export const TripHeaderContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 18px;
`

export const PayableSectionsContainer = styled.div`
  margin-top: 21px;
  margin-bottom: 23px;
`

export const FooterCheckoutContainer = styled.div`
  margin-top: 42px;
  margin-bottom: 14px;
`
