import styled from 'styled-components'
import { captionMedium, subHeadStrong } from '@fiji/style'
import { Text } from '@etta/ui/text'

const TOGGLE_KEY_ANIMATION = '250ms ease-out'
const TWO_ROWS_SCREEN_SIZE = 'max-width: 359px'

export const Container = styled.div`
  display: flex;
  margin-top: 18px;

  @media (${TWO_ROWS_SCREEN_SIZE}) {
    & {
      flex-direction: column;
    }
  }
`

export const InputButton = styled.button`
  position: relative;
  background-color: ${(p) => p.theme.colors.background};
  padding: 12px;
  display: flex;
  cursor: pointer;
  border: 0;
  text-align: left;

  & + & {
    margin: 0 0 0 1px;
  }

  &:first-of-type {
    border-radius: 8px 0px 0px 8px;
  }

  &:last-of-type {
    border-radius: 0px 8px 8px 0px;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.background1};
  }

  @media (${TWO_ROWS_SCREEN_SIZE}) {
    & + & {
      margin: 1px 0 0 0;
    }

    &:first-of-type {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-of-type {
      border-radius: 0px 0px 8px 8px;
    }
  }
`

export const DateInputButton = styled(InputButton)`
  min-width: 120px;
  width: 35%;

  @media (${TWO_ROWS_SCREEN_SIZE}) {
    & {
      min-width: auto;
      width: 100%;
    }
  }
`

export const TimeInputButton = styled(InputButton)`
  width: 65%;

  @media (${TWO_ROWS_SCREEN_SIZE}) {
    & {
      min-width: auto;
      width: 100%;
    }
  }
`

export const InputButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity ${TOGGLE_KEY_ANIMATION};
`

export const LabelGroup = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  ${(p) => `color: ${p.theme.colors.bodyText}`};
`

export const Label = styled.span`
  ${captionMedium};
  margin-left: 2px;
`

export const Value = styled.span<{ state: 'placeholder' | 'disabled' | 'value' }>`
  ${subHeadStrong};
  margin-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${(props) => {
    switch (props.state) {
      case 'placeholder':
        return `color: ${props.theme.colors.bodyText}`
      case 'disabled':
        return `color: ${props.theme.colors.bodyText}`
      default:
        return `color: ${props.theme.colors.mainText}`
    }
  }};
`

export const ToggleIconButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ToggleIconButton = styled.button<{
  isToggle: boolean
}>`
  height: 34px;
  position: relative;
  border: 2px solid ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.06);
  color: ${(props) => (props.isToggle ? props.theme.colors.primary : props.theme.colors.mainText)};
  width: ${(props) => (props.isToggle ? '100%' : '98px')};
  transition: width ${TOGGLE_KEY_ANIMATION};
`

export const TextToggleButton = styled(Text)`
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 700;
`
