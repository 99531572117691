import type { CalculateTripEmissionsResultCar } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { CarRentalEmission } from './types'

export function getCarRentalEmissionsSummary(
  segments: TripSegmentValueObject[],
  rawCarEmissions?: CalculateTripEmissionsResultCar[] | null,
): {
  carRentalEmissions: CarRentalEmission[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  const carRentalEmissions: CarRentalEmission[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    if (typeCheck.isCarRentalSegment(segment)) {
      const carRentalEmission = rawCarEmissions?.find((car) => car.customRef === segment?.carId)

      tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, carRentalEmission?.tonnesOfEmissions)
      averageTonnesOfEmissionsSummary = sum(
        averageTonnesOfEmissionsSummary,
        carRentalEmission?.averageEmissionTonnes,
      )

      const {
        plasticBagEquivalenceAmount,
        treeSeedlingEquivalenceAmount,
        smartphoneChargeEquivalenceAmount,
      } = getEquivalencesCount(carRentalEmission?.equivalences)

      treeSeedlingsEquivalenceTotalCount = sum(
        treeSeedlingsEquivalenceTotalCount,
        treeSeedlingEquivalenceAmount,
      )

      plasticBagsEquivalenceTotalCount = sum(
        plasticBagsEquivalenceTotalCount,
        plasticBagEquivalenceAmount,
      )

      smartphoneChargeEquivalenceTotalCount = sum(
        smartphoneChargeEquivalenceTotalCount,
        smartphoneChargeEquivalenceAmount,
      )

      const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
        averageEmissionsTonnes: carRentalEmission?.averageEmissionTonnes || 0,
        tonnesOfEmissions: carRentalEmission?.tonnesOfEmissions || 0,
      })

      carRentalEmissions.push({
        name: segment?.name,
        emissionsKg: tonnesToKg(carRentalEmission?.tonnesOfEmissions || 0),
        deviationLevel,
        percentDeviation,
      })
    }
  })

  return {
    carRentalEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
