import { lazy } from 'react'
import { ROUTES, getDocumentTitle } from '@fiji/routes'
import type { RouteItem } from '@fiji/routes/types'

const SettingsPage = lazy(() => import('./page'))

export const settingsRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.profile.main,
      exact: true,
    },
    documentTitle: getDocumentTitle('Profile'),
    transitionIndex: 7,
    transitionGroup: 'fade-through',
    component: () => <SettingsPage />,
  },
]
