import type {
  SegmentValueObject,
  SearchFlightLegSegmentValueObject,
  SearchFlightLegFareValueObject,
  ItineraryFlightLegSegmentValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import { SeatMapMapper } from './seat-map.mapper'
import { FaresMapper } from './fares.mapper'

type FeaturesArgs = {
  carrierName?: string
  flightNumber?: number
  aircraftName?: string | null
}

export class SegmentsMapper {
  static toSegmentEntities(
    flightSegments: SearchFlightLegSegmentValueObject[],
    fares: SearchFlightLegFareValueObject[],
  ): SegmentValueObject[] {
    return flightSegments.map((segment) => ({
      id: segment.id,
      seatMap: SeatMapMapper.toSeatMapEntity(),
      origin: segment.origin.code,
      destination: segment.destination.code,
      location: segment.destination.city,
      icon: segment.carrier?.logoImageUrl || '',
      features: this.getFeatures({
        carrierName: segment.carrier?.name,
        flightNumber: segment.flightNumber,
        aircraftName: segment.aircraft?.name,
      }),
      carrierName: segment.carrier?.name || '',
      carrierCode: segment.carrier?.id,
      flightNumber: segment.flightNumber,
      departureDate: segment.departureDate,
      isSeatMapAvailable: segment.isSeatMapAvailable && !segment.isInstantPurchaseCarrier,
      isInstantPurchaseCarrier: segment.isInstantPurchaseCarrier,
      fares: FaresMapper.toFareEntities(fares),
    }))
  }

  static toSegmentEntitiesFromItineraryFlight(
    flightSegments: ItineraryFlightLegSegmentValueObject[],
  ): SegmentValueObject[] {
    return flightSegments.map((segment) => ({
      id: segment.segmentId || '',
      seatMap: SeatMapMapper.toSeatMapEntity(),
      origin: segment.departure.airportCode || '',
      destination: segment.arrival.airportCode || '',
      location: segment.arrival.address?.city || '',
      icon: segment.images.square || '',
      features: this.getFeatures({
        carrierName: segment.name,
        flightNumber: segment.flightNumber,
        aircraftName: segment.aircraft?.type,
      }),
      carrierName: segment.name,
      carrierCode: segment.carrierCode,
      flightNumber: segment.flightNumber,
      departureDate: segment.departureDate,
      isSeatMapAvailable: segment.isSeatAvailable && !segment.isInstantPurchaseCarrier,
      isInstantPurchaseCarrier: segment.isInstantPurchaseCarrier,
      fares: FaresMapper.toFareEntitiesFromItineraryFlight(segment),
      travelerId: segment.travelerId,
      travelerSeat: segment.travelerSeat,
    }))
  }

  static getFeatures({ carrierName, flightNumber, aircraftName }: FeaturesArgs) {
    const carrierNameWithFlightNumber = [carrierName, flightNumber].filter(Boolean).join(' ')

    return [carrierNameWithFlightNumber, aircraftName].filter(Boolean).join(' • ')
  }
}
