import * as yup from 'yup'
import cardValidator from 'card-validator'
import { useTranslation } from 'react-i18next'
import type { CreditCardInput } from '@fiji/graphql/types'
import { CreditCardBrandType } from '@fiji/graphql/types'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import { getBrandTypeByCardNumber } from '../../../utils/credit-card/get-brand-type'

type Args = {
  creditCardData?: CreditCardInput
  isCvvRequired?: boolean
  isCardNumberFieldEdited: boolean
}

export function useCreditCardValidator({
  creditCardData,
  isCvvRequired,
  isCardNumberFieldEdited,
}: Args) {
  const { t } = useTranslation()
  const i18BaseErrors = 'CreditCardForm.Errors.'

  const cardScheme = useScheme(() => {
    return {
      cardNumber: yup
        .string()
        .when('$isCardNumberFieldEdited', {
          is: (isCardNumberFieldEdited: boolean) => isCardNumberFieldEdited,
          then: (schema) =>
            schema
              .test(
                'is-card-brand-valid',
                t(i18BaseErrors + 'CardNumber.BrandInvalid'),
                (value) => {
                  if (!value) {
                    return true
                  }
                  return getBrandTypeByCardNumber(value) !== CreditCardBrandType.Other
                },
              )
              .test('is-credit-card-number', t(i18BaseErrors + 'CardNumber.Invalid'), (value) => {
                return cardValidator.number(value).isValid
              })
              .required(t(i18BaseErrors + 'CardNumber.Required')),
          otherwise: (schema) => schema.required(t(i18BaseErrors + 'CardNumber.Required')),
        })
        .default(creditCardData?.cardNumber),
      name: yup
        .string()
        .required(t(i18BaseErrors + 'Name'))
        .default(creditCardData?.name),
      expirationDate: yup
        .string()
        .test(
          'is-expiration-date',
          t(i18BaseErrors + 'ExpirationDate.Invalid'),
          (value) => cardValidator.expirationDate(value).isValid,
        )
        .required(t(i18BaseErrors + 'ExpirationDate.Required'))
        .default(creditCardData?.expirationDate),
      securityCode: isCvvRequired
        ? yup
            .string()
            .min(3)
            .required(t(i18BaseErrors + 'SecurityCode.Invalid'))
            .default(creditCardData?.securityCode)
        : yup.string().optional().default(''),
      label: yup
        .string()
        .required(t(i18BaseErrors + 'Label'))
        .default(creditCardData?.label),
    }
  }, [creditCardData])

  const creditCardValidator = useValidation(cardScheme, { isCardNumberFieldEdited })

  return { creditCardValidator }
}
