import { useCallback } from 'react'
import {
  useRegisterClientForNotificationMutation,
  useToggleNotificationPreferenceMutation,
  useAckNotificationEventMutation,
} from '@fiji/graphql/hooks'
import { NotificationClientType, PnsEventKey } from '@fiji/graphql/types'

const ETTA_BRAND = 'etta'

type Args = {
  locale: string
}

export function useNotificationServer({ locale }: Args) {
  const [registerPushNotificationClient] = useRegisterClientForNotificationMutation()
  const [toggleNotificationPreference] = useToggleNotificationPreferenceMutation()
  const [ackNotificationEvent] = useAckNotificationEventMutation()

  const registerDevice = useCallback(
    async (token: string) => {
      const response = await registerPushNotificationClient({
        variables: {
          input: {
            recipientId: token,
            clientType: NotificationClientType.HybridAndroid,
            locale: locale.replace('-', '_'),
            brand: ETTA_BRAND,
          },
        },
      })
      return response.data?.registerClientForNotification.clientId
    },
    [locale, registerPushNotificationClient],
  )

  const toggleNotification = useCallback(async () => {
    await toggleNotificationPreference({
      variables: {
        input: {
          clientType: NotificationClientType.HybridAndroid,
          preferences: [
            {
              eventKey: PnsEventKey.TravelFlightAlert,
              turnedOn: true,
            },
            {
              eventKey: PnsEventKey.TravelDsmRequired,
              turnedOn: true,
            },
            {
              eventKey: PnsEventKey.TravelDsmOptional,
              turnedOn: true,
            },
            {
              eventKey: PnsEventKey.ExpenseApprover,
              turnedOn: true,
            },
            {
              eventKey: PnsEventKey.ExpenseSubmitter,
              turnedOn: true,
            },
          ],
        },
      },
    })
  }, [toggleNotificationPreference])

  const ackNotification = useCallback(
    (id: string) => {
      ackNotificationEvent({
        variables: {
          input: {
            clientType: NotificationClientType.HybridAndroid,
            eventId: id,
          },
        },
      })
    },
    [ackNotificationEvent],
  )

  return {
    registerDevice,
    toggleNotification,
    ackNotification,
  }
}
