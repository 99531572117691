import type { OptionType } from '@fiji/types'
import countries from './countries.json'

export function getCountryDecoded(code?: string | null): string | null {
  if (!code) {
    return null
  }

  const countryDecoded = (countries as OptionType[]).find((country) => country.value === code)
  return countryDecoded?.name || code
}
