import { MediaLayout } from '@etta/ui/media-layout'
import { SupportContactGetHelpNowDesktop } from './desktop/support-contact-get-help-now-desktop'
import { SupportContactGetHelpNowMobile } from './mobile/support-contact-get-help-now-mobile'

export function SupportContactGetHelpNowLayout() {
  return (
    <MediaLayout
      mobileSlot={<SupportContactGetHelpNowMobile />}
      desktopSlot={<SupportContactGetHelpNowDesktop />}
    />
  )
}
