import { Action, Inject } from '@etta/di'
import { UserService } from '../services'

@Action()
export class UserActions {
  constructor(@Inject() private getUserService: UserService) {}

  async getUser() {
    await this.getUserService.getUser()
  }

  async reloadUser() {
    await this.getUserService.reloadUser()
  }
}
