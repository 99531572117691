import { observer } from 'mobx-react-lite'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface'
import { ViewMode } from './view-mode'
import { EditMode } from './edit-mode'

export const CarRentalHeaderDesktop = observer(function CarRentalHeaderDesktop() {
  const { carSearchFormStore } = useCarRentalSearchFormContext()

  return (
    <SearchResultsHeaderFull.Container
      isEditOpen={carSearchFormStore.editModeToggle.isOpen}
      viewSlot={<ViewMode />}
      editSlot={<EditMode />}
    />
  )
})
