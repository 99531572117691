export const FLIGHT_ERROR_MESSAGES = {
  FLIGHT_NUMBER: 'flight number is missing or has a wrong value',
  ORIGIN_AIRPORT_CODE: 'originAirportCode is missing or it has a wrong value',
  DESTINATION_AIRPORT_CODE: 'destinationAirportCode is missing or it has a wrong value',
  DEPARTURE_DATE_TIME: 'departureDateTime is missing or it has a wrong value',
  CLASS: 'class is missing or it has a wrong value',
}

export const CAR_ERROR_MESSAGES = {
  PICK_UP_PLACE_LATITUDE: 'pickUpPlace.latitude is missing or has a wrong value',
  PICK_UP_PLACE_LONGITUDE: 'pickUpPlace.longitude is missing or it has a wrong value',
  DROP_OFF_PLACE_LATITUDE: 'dropOffPlace.latitude is missing or has a wrong value',
  DROP_OFF_PLACE_LONGITUDE: 'dropOffPlace.longitude is missing or it has a wrong value',
  ORIGIN_LATITUDE: 'origin.lat is missing or has a wrong value',
  ORIGIN_LONGITUDE: 'origin.long is missing or it has a wrong value',
  DESTINATION_LATITUDE: 'destination.lat is missing or has a wrong value',
  DESTINATION_LONGITUDE: 'destination.long is missing or it has a wrong value',
  PICK_UP_DATE: 'pickUpDate is missing or it has a wrong value',
  DROP_OFF_DATE: 'dropOffDate is missing or it has a wrong value',
  DAYS: 'days is missing or it has a wrong value',
  PICK_UP_DATE_TIME: 'pickUpDateTime is missing or it has a wrong value',
  VEHICLE_CODE: 'vehicleCode is missing or it has a wrong value',
}

export const HOTEL_ERROR_MESSAGES = {
  LOCATION_LATITUDE: 'location.latitude is missing or has a wrong value',
  LOCATION_LONGITUDE: 'location.longitude is missing or it has a wrong value',
  NIGHTS: 'nights is missing or it has a wrong value',
  CHECK_IN: 'checkIn is missing or it has a wrong value',
  HOTEL_ID: 'hotelId is missing or it has a wrong value',
  NAME: 'name is missing or it has a wrong value',
  GEOCODE_LATITUDE: 'geocode.lat is missing or it has a wrong value',
  GEOCODE_LONGITUDE: 'geocode.long is missing or it has a wrong value',
  CHECK_IN_DATE: 'checkInDate is missing or it has a wrong value',
  CHECK_OUT_DATE: 'checkOutDate is missing or it has a wrong value',
}

export const RAIL_ERROR_MESSAGES = {
  ORIGIN_STATION_CODE: 'originStationCode is missing or it has a wrong value',
  DESTINATION_STATION_CODE: 'originStationCode is missing or it has a wrong value',
  DEPARTURE_DATE: 'departureDate is missing or it has a wrong value',
  CLASS: 'class is missing or it has a wrong value',
}
export const SKIP_VALIDATION_ERROR = 'Error: Skip validation'
