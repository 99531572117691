import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { SegmentDetails } from '../../types'
import type { FlightSegment } from '../../../../types'
import { getCreditCardLabel } from '../../utils'
import { getFlightLegs } from './get-flight-legs'

type Args = {
  flightDetails: SegmentDetails['flightDetails']
}

const i18nBase = 'PostBooking.TripDetails'

export function useFlight({ flightDetails }: Args) {
  const { t } = useTranslation()
  const { segments, tripCost, creditCard } = flightDetails

  const getTranslation = useCallback(
    (label: string) => {
      return t(`${i18nBase}.${label}`)
    },
    [t],
  )

  const { mainCost, secondCost } = useMemo(() => {
    return formatRate(tripCost.total)
  }, [tripCost])

  const legs = useMemo(() => {
    return getFlightLegs(segments, getTranslation)
  }, [getTranslation, segments])

  const creditCardLabel = useMemo(() => {
    if (!creditCard) {
      return
    }

    return getCreditCardLabel(creditCard)
  }, [creditCard])

  const flight: FlightSegment = {
    details: {
      title: t(`${i18nBase}.SegmentsHeadline.Flights`),
    },
    payment: {
      title: t(`${i18nBase}.SegmentsHeadline.Payment`),
      mainTotal: mainCost,
      card: creditCardLabel,
      secondaryTotal: secondCost,
    },
    legs: legs,
  }

  if (!flightDetails.segments?.length) {
    return
  }

  return flight
}
