export enum CreditCardBrandType {
  Ax = 'AX',
  Ca = 'CA',
  Cb = 'CB',
  Dc = 'DC',
  Ds = 'DS',
  Ec = 'EC',
  Jc = 'JC',
  Other = 'OTHER',
  Tp = 'TP',
  Vi = 'VI',
}
