import { useEffect, useState } from 'react'
import { useNotifications } from '@fiji/hooks/use-notifications'
import { useUserContext } from '@etta/modules/user'
import { useQueryPermission } from './use-query-permission'
import { useRequestNotificationsTranslation } from './use-request-notifications-translation'

type Props = {
  onBack: (args: { isGranted: boolean }) => void
}

export function useRequestNotifications({ onBack }: Props) {
  const { userStore } = useUserContext()
  const { locale } = userStore
  const translation = useRequestNotificationsTranslation()
  const notifications = useNotifications({ locale })
  const { checkIsPermissionGranted } = useQueryPermission()
  const [isGeolocationGranted, setGeolocationGranted] = useState<boolean | null>(null)
  const handleBack = () => {
    if (isGeolocationGranted !== null) {
      onBack({ isGranted: isGeolocationGranted })
    }
  }

  useEffect(() => {
    const checkPermission = async () => {
      const isGranted = await checkIsPermissionGranted('geolocation')

      setGeolocationGranted(isGranted)
    }

    if (isGeolocationGranted === null) {
      checkPermission()
    }
  }, [checkIsPermissionGranted, isGeolocationGranted])

  return { translation, notifications, handleBack, isGeolocationGranted }
}
