import { Momentum } from '../swiping-time-picker-components'
import { returnValidDate } from '../assets'
import type { SwipingPickerSubtype } from '../types'

type Props = {
  month: number
  day: number
  year: number
  variant?: SwipingPickerSubtype
  startYear: number
}

export function SelectedDate({ month, day, year, variant, startYear }: Props): JSX.Element {
  const isInvalidDate =
    (variant === 'date' || variant === 'future-date') &&
    !!returnValidDate({ day: day + 1, month, year, variant })
  const date = new Date(startYear + year, month, day + 1)

  const resolveFormat = () => {
    switch (variant) {
      case 'only-month':
        return `${date.toLocaleString('default', { month: 'long' }).replace(',', '')}`
      case 'only-year':
        return startYear + year
      case 'only-day':
        return `${new Date(2020, 0, day + 1)
          .toLocaleString('default', { day: '2-digit' })
          .replace(',', '')}`
      default:
        return `${date
          .toLocaleString('default', { year: 'numeric', month: 'short', day: '2-digit' })
          .replace(',', '')}`
    }
  }

  return <Momentum>{isInvalidDate ? '-' : resolveFormat()}</Momentum>
}
