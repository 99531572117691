import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 20px 16px;
`

export const BaggageInfoContainer = styled.div<{ isWithSpaceBetween?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isWithSpaceBetween ? 'space-between' : 'normal')};
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background4};
  border-radius: 10px;
  padding: 7px 10px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
