import { Store } from '@etta/di'
import type { TravelPolicyValueObject } from '../../core/value-objects'

@Store()
export class TravelPolicyStore {
  isLoading: boolean = false

  travelPolicy: TravelPolicyValueObject = {
    purposeLabel: undefined,
    isPurposeRequired: undefined,
    systemBehaviorType: undefined,
    minAdvancePurchaseDays: 0,
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setTravelPolicy(travelPolicy: TravelPolicyValueObject) {
    this.travelPolicy = travelPolicy
  }
}
