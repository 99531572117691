import i18n from 'i18next'
import type { BadgeVariant } from './types'

const i18nBase = 'Badge'

const textsMap: Record<BadgeVariant, string> = {
  booking: 'Booking',
  gbt_direct: 'GBTDirect',
  trip_source: 'TripSource',
}

export function getTitle(variant: BadgeVariant) {
  return i18n.t(`${i18nBase}.${textsMap[variant]}`)
}
