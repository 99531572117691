import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { AirSearchFormInputsActions } from '@etta/modules/air-search'
import { HomeAirportStore } from './stores/configs/home-airport.store'
import { AirSearchFormStore } from './stores/air-search-form.store'
import { HomeAirportActions } from './actions/home-airport.action'
import { SubmitButtonActions } from './actions/submit-button.action'
import { AirSearchQueryStore } from './stores/air-search-query.store'

export const { useModuleContext: useAirSearchFormContext } = hookContextFactory({
  airSearchFormStore: AirSearchFormStore,
  airSearchQueryStore: AirSearchQueryStore,
  homeAirportActions: HomeAirportActions,
  homeAirportStore: HomeAirportStore,
  airSearchFormInputsActions: AirSearchFormInputsActions,
  submitButtonActions: SubmitButtonActions,
})
