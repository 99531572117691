import { Store } from '@etta/di'
import type { TripStateStatus } from '@etta/core/entities'
import { Toggle } from '@etta/interface/services/toggle'
import { TrainServiceActiveTab } from '@fiji/enums'
import { deleteTimezone, isSameDay, isoToDate } from '@fiji/utils/dates'
import { RailSortByEnum } from '../../infra/types'
import type { RailSearchResultValueObject } from '../../core/value-objects/rail-search-result.value-object'
import type { TicketRestrictionsInfo } from '../../core/value-objects/ticket-restrictions-info.value-object'
import type { SearchRailLegEntity } from '../../core/entities/search-rail-leg.entity'
import type { PermittedStationsInfoValueObject } from '../../core/value-objects/permitted-stations-info.value-object'

@Store()
export class RailStore {
  private _isGetRailError: boolean = false
  private _isCreateRailSearchError: boolean = false
  private _isLoading: boolean = false
  private _sortBy: RailSortByEnum = RailSortByEnum.Departure
  // user customised filter
  private _railSearchResult: RailSearchResultValueObject | null = null

  private _railDetailsToggle = new Toggle()
  private _policyModalToggle = new Toggle()
  private _filtersModalToggle = new Toggle()
  private _ticketRestrictionsToggle = new Toggle()
  private _editTimeModalToggle = new Toggle()
  private _editRailSearchModalToggle = new Toggle()
  private _permittedStationsToggle = new Toggle()

  private _selectRailStatus: TripStateStatus = null

  private _activeLegId: string | null = null // active when click to check fare or other info

  private _ticketRestrictionsInfo: TicketRestrictionsInfo = {
    tripDate: '',
    tripFare: '',
    tripRoute: '',
    ticketRestrictions: [],
  }

  private _permittedStationsInfo: PermittedStationsInfoValueObject | undefined = undefined

  private _trainServiceActiveTab: TrainServiceActiveTab = TrainServiceActiveTab.Standard

  private _currentPage: number = 0

  get trainServiceActiveTab(): TrainServiceActiveTab {
    return this._trainServiceActiveTab
  }

  get selectRailStatus() {
    return this._selectRailStatus
  }

  get isRailDetailsOpen(): boolean {
    return this._railDetailsToggle.isOpen
  }

  get isPolicyModalOpen(): boolean {
    return this._policyModalToggle.isOpen
  }

  get isFiltersModalOpen(): boolean {
    return this._filtersModalToggle.isOpen
  }

  get isTicketRestrictionsOpen(): boolean {
    return this._ticketRestrictionsToggle.isOpen
  }

  get isPermittedStationsOpen(): boolean {
    return this._permittedStationsToggle.isOpen
  }

  // should cover !!getRailError || !!createRailSearchState.error || isTripCreatingError
  get isError(): boolean {
    return this._isGetRailError || this._isCreateRailSearchError
  }

  get isGetRailError(): boolean {
    return this._isGetRailError
  }

  get isCreateRailSearchError(): boolean {
    return this._isCreateRailSearchError
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  get railSearchResult(): RailSearchResultValueObject | null {
    return this._railSearchResult
  }

  get sortBy(): RailSortByEnum {
    return this._sortBy
  }

  get hasNextPage() {
    if (!this._railSearchResult?.maxResultsSize || !this._railSearchResult.pageEnd) {
      return false
    }

    return this._railSearchResult.pageEnd + 1 < this._railSearchResult.maxResultsSize
  }

  get dynamicSiteMessages() {
    return this._railSearchResult?.messages
  }

  get railLegs() {
    return this._railSearchResult?.railLegs
  }

  get isCustomTimeReplaced() {
    return this._railSearchResult?.isCustomTimeReplaced
  }

  get railPolicies() {
    return this._railSearchResult?.railPolicies
  }

  get railFilters() {
    return this._railSearchResult?.filters
  }

  get activeLeg() {
    return this.railLegs?.find(({ legId }) => legId === this._activeLegId)
  }

  get fareConditions() {
    return this._railSearchResult?.fareConditions
  }

  get ticketRestrictionsInfo() {
    return this._ticketRestrictionsInfo
  }

  get permittedStationsInfo() {
    return this._permittedStationsInfo
  }

  get isEditTimeModalOpen(): boolean {
    return this._editTimeModalToggle.isOpen
  }

  get isEditRailSearchModalOpen(): boolean {
    return this._editRailSearchModalToggle.isOpen
  }

  get currentPage() {
    return this._currentPage
  }

  setTrainServiceActiveTab(value: TrainServiceActiveTab) {
    this._trainServiceActiveTab = value
  }

  setSelectRailStatus(status: TripStateStatus) {
    this._selectRailStatus = status
  }

  setActiveLegId(legId: string) {
    this._activeLegId = legId
  }

  setRailDetailsOpen() {
    this._railDetailsToggle.handleOpen()
  }

  setRailDetailsClose() {
    this._railDetailsToggle.handleClose()
  }

  setPolicyModalOpen() {
    this._policyModalToggle.handleOpen()
  }

  setPolicyModalClose() {
    this._policyModalToggle.handleClose()
  }

  setFiltersModalOpen() {
    this._filtersModalToggle.handleOpen()
  }

  setFiltersModalClose() {
    this._filtersModalToggle.handleClose()
  }

  setTicketRestrictionsOpen() {
    this._ticketRestrictionsToggle.handleOpen()
  }

  setTicketRestrictionsClose() {
    this._ticketRestrictionsToggle.handleClose()
  }

  setPermittedStationsOpen() {
    this._permittedStationsToggle.handleOpen()
  }

  setPermittedStationClose() {
    this._permittedStationsToggle.handleClose()
  }

  setIsGetRailError(value: boolean) {
    this._isGetRailError = value
  }

  setIsCreateRailSearchError(value: boolean) {
    this._isCreateRailSearchError = value
  }

  setIsLoading(value: boolean) {
    this._isLoading = value
  }

  setRailSearchResult(value: RailSearchResultValueObject | null, searchDate: Date | undefined) {
    if (!searchDate || value === null) {
      this._railSearchResult = value
      return
    }

    const setShowDateHeaderInRailLegs = (
      railLegs: SearchRailLegEntity[],
      searchDate: Date | undefined,
    ): SearchRailLegEntity[] => {
      return railLegs.map((currentLeg, index, railLegs) => {
        const previousLegDepartureDate =
          index === 0 ? undefined : isoToDate(deleteTimezone(railLegs[index - 1].departureDate))

        const currentLegDepartureDate = isoToDate(deleteTimezone(currentLeg.departureDate))

        const isCurrentLegInSearchDate = searchDate
          ? isSameDay(currentLegDepartureDate, searchDate)
          : false

        const isFirstLegAndDifferentDepartureDateThanSearch =
          index === 0 && !isCurrentLegInSearchDate

        const isNotFirstLegAndDifferentDepartureDateThanPreviousLeg = previousLegDepartureDate
          ? !isSameDay(currentLegDepartureDate, previousLegDepartureDate)
          : false

        currentLeg.showDepartureDate =
          isFirstLegAndDifferentDepartureDateThanSearch ||
          isNotFirstLegAndDifferentDepartureDateThanPreviousLeg
        return currentLeg
      })
    }

    value.railLegs = setShowDateHeaderInRailLegs(value.railLegs, searchDate)

    this._railSearchResult = value
  }

  setSortBy(value: RailSortByEnum) {
    this._sortBy = value
  }

  setTicketRestrictionsInfo(info: TicketRestrictionsInfo) {
    this._ticketRestrictionsInfo = info
  }

  setPermittedStationsInfo(info: PermittedStationsInfoValueObject) {
    const leg = this.activeLeg
    this._permittedStationsInfo = {
      permittedOriginStations: info.permittedOriginStations,
      permittedDestinationStations: info.permittedDestinationStations,
      onClick: info.onClick,
      originStation: leg?.origin,
      destinationStation: leg?.destination,
    }
  }

  setCurrentPage(page: number) {
    this._currentPage = page
  }

  setEditTimeModalOpen() {
    this._editTimeModalToggle.handleOpen()
  }

  setEditTimeModalClose() {
    this._editTimeModalToggle.handleClose()
  }

  setEditRailSearchModalOpen() {
    this._editRailSearchModalToggle.handleOpen()
  }

  setEditRailSearchModalClose() {
    this._editRailSearchModalToggle.handleClose()
  }

  dropStore() {
    this._isGetRailError = false
    this._isCreateRailSearchError = false
    this._isLoading = false
    this._sortBy = RailSortByEnum.Departure
    this._railSearchResult = null
    this._selectRailStatus = null
    this._currentPage = 0
    this._permittedStationsInfo = undefined
  }
}
