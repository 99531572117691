import { useTranslation } from 'react-i18next'
import { estimatedEmissionsLinks } from '@etta/components/flight-details-eco-check/constants'
import { Icon } from '@etta/ui/icon'
import { LearnMoreLinkWrapper, LearnMoreLink } from './flight-details-eco-check-modal-styled'

export function FlightDetailsModalLearnMore() {
  const { t } = useTranslation()
  return (
    <LearnMoreLinkWrapper>
      <LearnMoreLink
        aria-label={`${t('Emissions.DetailsModal.LearnMoreAboutEmissionsEstimates')} - ${t(
          'Accessibility.OpenLink',
        )}`}
        target="_blank"
        size="small"
        href={estimatedEmissionsLinks.viewSourceLink}>
        {t('Emissions.DetailsModal.LearnMoreAboutEmissionsEstimates')}
        <Icon name={'openWindowPWA'} size={20} color="primary1" />
      </LearnMoreLink>
    </LearnMoreLinkWrapper>
  )
}
