import styled from 'styled-components'
import { captionStrong, title2, footnoteMedium, linkSmall } from '@fiji/style'
import { Text } from '@etta/ui/text'
import type { TapHighlightColor } from '../../types'

export const Container = styled.div<{ tapHighlightColor: TapHighlightColor }>`
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  &:active {
    background-color: ${(p) => p.theme.colors[p.tapHighlightColor]};
  }
`

export const BackgroundImage = styled.img<{ isWithDisclaimer: boolean }>`
  border-radius: ${(props) => (!props.isWithDisclaimer ? '14px' : 'unset')};
`

export const LeftBlock = styled.div`
  padding: 15px 16px;
`

export const Title = styled.p`
  color: ${(props) => props.theme.colors.bodyText};
  display: inline-block;
  margin: 0;
  ${captionStrong};
  font-weight: 700;
`

export const EmissionsSummary = styled.p`
  margin: 0;
  ${title2};
  margin-top: 5px;
`

export const ModalButton = styled.div`
  color: ${(props) => props.theme.colors.primary};
  ${footnoteMedium}
`

export const ModalButtonText = styled.p`
  margin: 0;
  ${linkSmall};
`

export const IconRelativeContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const IconAbsoluteContainer = styled.div`
  position: absolute;
  bottom: -2px;
`
export const EcoCheckWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const DisclaimerMessageContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
`

export const DisclaimerMessageText = styled(Text)`
  margin-left: 5px;
`
