import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { CarRentalSearchPage } from '@etta/screens/car-rental-search-page'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { CloseIconWrapper, ModalContent, TitleWrapper } from './styled'

const DATA_TRACKING_ID = 'POST_BOOKING_CAR_SEARCH_FORM_MODAL'

export const CarSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const { postBookingTripStore } = usePostBookingContext()

  useEffect(() => {
    return () => {
      postBookingTripStore.carSearchFormModal.handleClose()
    }
  }, [postBookingTripStore])

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={postBookingTripStore.carSearchFormModal.handleClose}
      isVisible={postBookingTripStore.carSearchFormModal.isOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton
          onClick={postBookingTripStore.carSearchFormModal.handleClose}
          color="bodyText"
          icon="closePWA"
          size="medium"
        />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('PostBooking.CarRentalDetails.Modify')}</Text>
      </TitleWrapper>
      <ModalContent>
        <CarRentalSearchPage isBlockView />
      </ModalContent>
    </Modal>
  )
})
