import type { MouseEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { DynamicSiteMessage } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useTruncateDsmMessage } from '@fiji/hooks/use-truncate-dsm-message'
import type { Alert } from '../types'
import { getViewMoreTitle } from './get-view-more-title'
import { useItemsToShow } from './use-items-to-show'
import { useMessagesSlider } from './use-messages-slider'
import { useScrollModalMessage } from './use-scroll-modal-message'
import { getSelectedMessage } from './get-selected-message'
import { useRecalculateTruncate } from './use-recalculate-truncate'

type Args = {
  initialMessages: DynamicSiteMessage[]
  initialAlerts: Alert[]
  isPreview?: boolean
  withMobileTextIfExist: boolean
}

const i18Base = 'DynamicSiteMessage.'

export function useDSMState({
  initialMessages,
  initialAlerts,
  isPreview,
  withMobileTextIfExist,
}: Args) {
  const { messagesToShow, alertsToShow, handleRemoveItem } = useItemsToShow({
    initialMessages,
    initialAlerts,
    withMobileTextIfExist,
  })

  const { t } = useTranslation()
  const modalToggle = useTogglePopup()
  const [isExpanded, setIsExpanded] = useState(!isPreview)
  const messagesCount = messagesToShow.length
  const alertsCount = alertsToShow.length
  const isWithAlerts = alertsCount > 0
  const isWithMessages = messagesCount > 0
  const isWithAlertsAndMessages = isWithAlerts && isWithMessages
  const totalCount = alertsCount + messagesCount
  const totalCountUntilExpandTruncateMessages = 3
  const alertsAndMessages = [...alertsToShow, ...messagesToShow]

  const { messageIndex, handleUpdateMessageIndex } = useMessagesSlider({
    totalCount,
  })

  const {
    anchorId,
    scrollContainerRef,
    handleSetAnchor,
    handleSetAnchorId,
  } = useScrollModalMessage({
    isOpen: modalToggle.isOpen,
  })

  const { title, message, messageId, messageSlot, hasDesktopBannerImage } = getSelectedMessage({
    messages: alertsAndMessages,
    messageIndex,
  })
  const { shouldRecalculateTruncate } = useRecalculateTruncate({ message })

  const handleExpand = useCallback(() => {
    if (isPreview && !isExpanded) {
      setIsExpanded(true)
    }
  }, [isPreview, isExpanded])
  const hasMultipleItems = totalCount > 1
  const shouldExpandMessages = totalCount < totalCountUntilExpandTruncateMessages
  const hasAlertIcon = messageIndex < alertsCount

  const { messageRef, isMessageTruncated } = useTruncateDsmMessage({
    hasMultipleItems,
    shouldRecalculateTruncate,
  })

  const handleModalOpen = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation()

      modalToggle.handleOpen()
    },
    [modalToggle],
  )

  const hasMessages = Boolean(totalCount)

  const viewMoreTitle = getViewMoreTitle({ messagesCount, alertsCount })
  const modalTitle = isWithAlerts ? t(i18Base + 'AlertsAndMessages') : t(i18Base + 'Messages')

  useEffect(() => {
    if (!modalToggle.isOpen) {
      handleSetAnchorId(messageId)
    }
  }, [messageId, modalToggle, handleSetAnchorId])

  return {
    hasMessages,
    messagesToShow,
    alertsToShow,
    isWithAlerts,
    handleExpand,
    title,
    modalTitle,
    message,
    messageRef,
    messageSlot,
    isExpanded,
    handleModalOpen,
    viewMoreTitle,
    hasMultipleItems,
    isWithMessages,
    isWithAlertsAndMessages,
    isMessageTruncated,
    modalToggle,
    handleRemoveItem,
    messageIndex,
    hasDesktopBannerImage,
    totalCount,
    anchorId,
    scrollContainerRef,
    shouldExpandMessages,
    handleSetAnchor,
    hasAlertIcon,
    handleUpdateMessageIndex,
    handleSetAnchorId,
  }
}
