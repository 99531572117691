import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { MediaLayout } from '@etta/ui/media-layout'
import missingCarImageReplacement from '../car.png'
import { TripCarRentalHeadlineMobile } from './layout/mobile'
import { TripCarRentalHeadlineDesktop } from './layout/desktop'
import type { LayoutProps } from './layout/types'

type Props = {
  carRental: CarRentalPostBookingValueObject
}

export function TripCarRentalHeadline({ carRental }: Props) {
  const carType = carRental.carType
  const carClass = carRental.carClass
  const carImageUrl = carRental.carImageUrl || missingCarImageReplacement
  const { carRentalTitle } = translateCarNameDetails({
    carType,
    carClass,
  })

  const layoutProps: LayoutProps = {
    carImageUrl,
    carRentalTitle,
  }

  return (
    <MediaLayout
      mobileSlot={<TripCarRentalHeadlineMobile {...layoutProps} />}
      desktopSlot={<TripCarRentalHeadlineDesktop {...layoutProps} />}
    />
  )
}
