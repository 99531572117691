import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../types'
import { Wrapper } from './autocancellation-message-mobile-styled'

export function AutoCancellationMessageMobile({ translationSlot, customBackground }: LayoutProps) {
  return (
    <Wrapper customBackground={customBackground}>
      <Icon name="infoOutlinePWA" />
      <Text variant={'footnoteMedium'} color={'mainText'}>
        {translationSlot}
      </Text>
    </Wrapper>
  )
}
