import type { PropsWithChildren } from 'react'
import { ScreenType, useScreenType } from '@fiji/modes'
import { HeadingMobile, HeadingDesktop } from './heading-styled'

export function Heading({ children }: PropsWithChildren<{}>) {
  const screenType = useScreenType()
  if (screenType === ScreenType.Mobile) {
    return <HeadingMobile>{children}</HeadingMobile>
  }

  return <HeadingDesktop>{children}</HeadingDesktop>
}
