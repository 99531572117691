import { createErrorClass } from '@etta/core/errors/create-error-class'
// eslint-disable-next-line import/no-restricted-paths
import type { FieldErrorValueObject } from '@etta/modules/booking/core/value-objects/field-error.value-object'

export namespace SettingsErrors {
  export const GetCreditCardsResponseError = createErrorClass('Get Credit Cards Error Response')
  export const GetCreditCardsUnexpectedError = createErrorClass('Get Credit Cards Unexpected Error')
  export const AddCreditCardResponseError = createErrorClass('Add Credit Card Error Response')
  export const AddCreditCardUnexpectedError = createErrorClass('Add Credit Card Unexpected Error')
  export const EditCreditCardResponseError = createErrorClass('Edit Credit Card Error Response')
  export const EditCreditCardUnexpectedError = createErrorClass('Edit Credit Card Unexpected Error')
  export const DeleteCreditCardResponseError = createErrorClass('Delete Credit Card Error Response')
  export const DeleteCreditCardUnexpectedError = createErrorClass(
    'Delete Credit Card Unexpected Error',
  )
  export const GetSuggestedHomeAirportsResponseError = createErrorClass(
    'Get Suggested Home Airports Error Response',
  )
  export const GetSuggestedHomeAirportsUnexpectedError = createErrorClass(
    'Get Suggested Home Airports Unexpected Error',
  )
  export const GetAvailableVendorsResponseError = createErrorClass(
    'Get Available Vendors Error Response',
  )
  export const GetAvailableVendorsUnexpectedError = createErrorClass(
    'Get Available Vendors Unexpected Error',
  )
  export const UpdateVisaResponseError = createErrorClass('Update Visa Error Response')
  export const UpdateVisaUnexpectedError = createErrorClass('Update Visa Unexpected Error')
  export const RemoveVisaResponseError = createErrorClass('Remove Visa Error Response')
  export const RemoveVisaUnexpectedError = createErrorClass('Remove Visa Unexpected Error')
  export const UpdateNationalIdCardResponseError = createErrorClass(
    'Update National ID card Error Response',
  )
  export const UpdateNationalIdCardUnexpectedError = createErrorClass(
    'Update National ID card Unexpected Error',
  )
  export const RemoveNationalIdCardResponseError = createErrorClass(
    'Remove National Id Card Error Response',
  )
  export const RemoveNationalIdCardUnexpectedError = createErrorClass(
    'Remove National Id Card Unexpected Error',
  )
  export const UpdatePassportInputError = createErrorClass<FieldErrorValueObject[]>(
    'Update passport input failed the validation',
  )
  export const UpdatePassportResponseError = createErrorClass('Update Passport Error Response')
  export const UpdatePassportUnexpectedError = createErrorClass('Update Passport Unexpected Error')
  export const GetProfileFieldsSettingsResponseError = createErrorClass(
    'Get Profile Settings Fields Error Response',
  )
  export const GetProfileFieldsSettingsUnexpectedError = createErrorClass(
    'Get Profile Settings Fields Unexpected Error',
  )

  export const GetTimeZonesResponseError = createErrorClass('Get Time Zones Error Response')
  export const GetTimeZonesUnexpectedError = createErrorClass('Get Time Zones Unexpected Error')

  export const GetCurrenciesResponseError = createErrorClass('Get Currency Error Response')
  export const GetCurrenciesUnexpectedError = createErrorClass('Get Currency Unexpected Error')

  export const GetLanguagesResponseError = createErrorClass('Get Language Error Response')
  export const GetLanguagesUnexpectedError = createErrorClass('Get Language Unexpected Error')

  export const GetDelegatesResponseError = createErrorClass('Get Delegates Error Response')
  export const GetDelegatesUnexpectedError = createErrorClass('Get Delegates Unexpected Error')

  export const UpdateDelegatesResponseError = createErrorClass('Update Delegates Error Response')
  export const UpdateDelegatesUnexpectedError = createErrorClass(
    'Update Delegates Unexpected Error',
  )

  export const UpdateAuthorizerResponseError = createErrorClass('Update Authorizer Error Response')
  export const UpdateAuthorizerUnexpectedError = createErrorClass(
    'Update Authorizer Unexpected Error',
  )
  export const GetAvailableDelegatesResponseError = createErrorClass(
    'Get All Delegates Error Response',
  )
  export const GetAvailableDelegatesUnexpectedError = createErrorClass(
    'Get All Delegates Unexpected Error',
  )

  export const ChangePasswordResponseError = createErrorClass('Change Password Error Response')
  export const ChangePassswordUnexpectedError = createErrorClass('Change Password Unexpected Error')
  export const ChangePasswordBadCredentials = createErrorClass('Change Password Current Is Invalid')
  export const ChangePasswordMismatch = createErrorClass('Change Password Mismatch')
  export const ChangePasswordWeak = createErrorClass('Change Password Weak')

  export const GetAvailableSpecialRequestsResponseError = createErrorClass(
    'Get Special Requests Error Response',
  )
  export const GetAvailableSpecialRequestsUnexpectedError = createErrorClass(
    'Get Special Requests Unexpected Error',
  )
  export const RemoveDelegateResponseError = createErrorClass('Remove Delegate Error Response')

  export const UpdateFlightPreferencesResponseError = createErrorClass(
    'Update Flight Preferences Error Response',
  )
  export const UpdateFlightPreferencesUnexpectedError = createErrorClass(
    'Update Flight Preferences Unexpected Error',
  )
  export const UpdateHotelPreferencesResponseError = createErrorClass(
    'Update Hotel Preferences Error Response',
  )
  export const UpdateHotelPreferencesUnexpectedError = createErrorClass(
    'Update Hotel Preferences Unexpected Error',
  )

  export const UpdateCarRentalPreferencesResponseError = createErrorClass(
    'Update Car Rental Preferences Error Response',
  )
  export const UpdateCarRentalPreferencesUnexpectedError = createErrorClass(
    'Update Car Rental Preferences Unexpected Error',
  )
}

export type SettingsErrorsInstances = InstanceType<
  typeof SettingsErrors[keyof typeof SettingsErrors]
>
