import { SegmentType } from '@etta/core/enums'
import { SegmentCard, useSegmentCard } from '../segment-card'
import { SectionDate } from '../date-segments-styled'
import { useCarServiceSegment } from './use-car-service-segment'
import type { LayoutProps } from './types'

export function CarServiceSegment({
  segment,
  isUpcoming,
  isOnHold,
  currentDate,
  onClick,
}: LayoutProps) {
  const { isPast, dateToShow } = useSegmentCard({ segment, isOnHold, currentDate })
  const { ariaLabel, title } = useCarServiceSegment({ dateToShow })

  return (
    <SegmentCard
      isPast={isPast}
      isUpcoming={isUpcoming}
      ariaLabel={ariaLabel}
      titleSlot={title}
      segmentIcon="carServicePWA"
      dateSlot={<SectionDate isDisabled>{dateToShow}</SectionDate>}
      onClick={onClick}
      segmentType={SegmentType.CarService}
      isOnHold={isOnHold}
    />
  )
}
