import { formatRate } from '@fiji/utils/money'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import type { SegmentDetails } from '../../types'
import type { RentalCar } from '../../../../types'
import { getLocationLabel } from './utils'

export function getCarRentals(
  segments: SegmentDetails['carDetails']['segments'],
  getTranslation: (label: string) => string,
  isPayment: boolean,
) {
  if (!segments) {
    return []
  }

  return segments?.map((segment) => {
    const { mainCost, secondCost } = formatRate(segment.totalCostRate)

    const separator = ` ${getTranslation('On')} `

    const carRental: RentalCar = {
      title: translateCarNameDetails({
        carType: segment.carDetails.type,
        carClass: segment.carDetails.class,
      }).carRentalTitle,
      pickUp: getLocationLabel(segment.pickUpTime, segment.carLocationType?.pickup, separator),
      dropOff: getLocationLabel(segment.dropOffTime, segment.carLocationType?.dropoff, separator),
      // TODO
      // confirmation
      payment: {
        title: isPayment
          ? getTranslation('SegmentsHeadline.Payment')
          : getTranslation('PaymentDueAtPickUp'),
        mainTotal: mainCost,
        secondaryTotal: secondCost,
      },
    }

    return carRental
  })
}
