import i18n from 'i18next'
import { SeatmapCabinClass } from '@etta/modules/seat-map/core/enums'

export function getCabinClassTitle(cabinClass?: SeatmapCabinClass): string {
  switch (cabinClass) {
    case SeatmapCabinClass.First:
      return i18n.t('FlightSearch.CabinClass.First')
    case SeatmapCabinClass.Business:
      return i18n.t('FlightSearch.CabinClass.Business')
    case SeatmapCabinClass.PremiumCoach:
      return i18n.t('FlightSearch.CabinClass.PremiumEconomy')
    case SeatmapCabinClass.Coach:
    default:
      return i18n.t('FlightSearch.CabinClass.Economy')
  }
}
