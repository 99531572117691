import { formatRate } from '@fiji/utils/money/format-rate'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { SegmentType } from '@etta/core/enums'
import type { CancelTripDataValueObject } from '../../../core/value-objects/cancel-trip-data.value-object'
import type {
  CarRentalPostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
  RideHailPostBookingValueObject,
  SegmentPostBookingValueObject,
  TrainPostBookingValueObject,
} from '../../../core/value-objects/trip-segments'
import type { TripDetailsCostSummaryValueObject } from '../../../core/value-objects/trip-details'

type Props = {
  costSummary?: TripDetailsCostSummaryValueObject
  segments: SegmentPostBookingValueObject[]
}

type GroupResult = {
  flights: CancelTripDataValueObject[]
  hotels: CancelTripDataValueObject[]
  carRentals: CancelTripDataValueObject[]
  trains: CancelTripDataValueObject[]
  rideHails: CancelTripDataValueObject[]
}

function createFlightItem(
  segment: FlightPostBookingValueObject,
  index: number,
): CancelTripDataValueObject {
  const segmentPath = segment.segments

  return {
    id: (segmentPath && segmentPath[0].segmentId) || index,
    type: SegmentType.Flight,
    name: `${segmentPath?.[0].departure?.airportCode}${
      segmentPath?.length ? ` - ${segmentPath[segmentPath.length - 1]?.arrival?.airportCode}` : ''
    }`,
    icon: 'flightPWA',
    cost: formatRate(segment.rate).mainCost,
    startDate: segment.departureDateTime,
    endDate: segment.arrivalDateTime,
    isRoundTripLeg: segment.isRoundTripLeg,
    isRoundTripCost: segment.isRoundTripCost,
  }
}

function createHotelItem(
  segment: HotelPostBookingValueObject,
  index: number,
): CancelTripDataValueObject {
  return {
    id: segment.hotelId || index,
    type: SegmentType.Hotel,
    name: segment.name || '',
    icon: 'hotelPWA',
    cost: segment.totalCost
      ? formatMoney(segment.totalCost, { isRounded: true, morpheme: 'postfix' })
      : '',
    key: segment.id,
  }
}

function createCarRentalItem(
  segment: CarRentalPostBookingValueObject,
  index: number,
): CancelTripDataValueObject {
  return {
    id: segment.carId || index,
    type: SegmentType.CarRental,
    name: segment.vendor?.name || '',
    icon: 'carRentalPWA',
    cost: formatRate(segment.totalCost)?.mainCost || '',
    key: segment.carId,
  }
}

function createTrainItem(
  segment: TrainPostBookingValueObject,
  index: number,
): CancelTripDataValueObject {
  return {
    id: segment.id || index,
    type: SegmentType.Train,
    name: ['PostBooking.TrainDetails.TrainTo', segment.arrivalStationCode ?? ''],
    icon: 'railPWA',
    cost: formatRate(segment.totalCost).mainCost || '',
  }
}

function createRideHailItem(
  segment: RideHailPostBookingValueObject,
  index: number,
): CancelTripDataValueObject {
  return {
    id: Number(segment.bookingId) || index,
    type: SegmentType.RideHail,
    name: segment.productName || '',
    icon: null,
    startDate: segment.pickUpTime && dateFormat(new Date(segment.pickUpTime), 'MMM d, h:mm aa'),
    cost: formatRate(segment.price).mainCost || '',
    dropOffAddress: segment.dropOffAddress,
    status: segment.status,
  }
}

export function getCancelTripData({ costSummary, segments }: Props): CancelTripDataValueObject[] {
  if (!costSummary) {
    return []
  }

  const {
    carRentals,
    flights,
    hotels,
    rideHails,
    trains,
  }: GroupResult = segments.reduce<GroupResult>(
    (acc, segment, index) => {
      switch (segment.type) {
        case SegmentType.Flight:
          const flightItem: CancelTripDataValueObject = createFlightItem(segment, index)
          acc.flights.push(flightItem)
          break
        case SegmentType.Hotel:
          const hotelItem: CancelTripDataValueObject = createHotelItem(segment, index)
          acc.hotels.push(hotelItem)
          break
        case SegmentType.CarRental:
          const carRentalItem: CancelTripDataValueObject = createCarRentalItem(segment, index)
          acc.carRentals.push(carRentalItem)
          break
        case SegmentType.Train:
          const trainItem: CancelTripDataValueObject = createTrainItem(segment, index)
          acc.trains.push(trainItem)
          break
        case SegmentType.RideHail:
          const rideHailItem: CancelTripDataValueObject = createRideHailItem(segment, index)
          acc.rideHails.push(rideHailItem)
          break
      }
      return acc
    },
    {
      flights: [],
      hotels: [],
      carRentals: [],
      trains: [],
      rideHails: [],
    },
  )

  // Note: The order is important here. We should display segments in cancellation based off it's priority.
  // Flight > Hotel > Car Rental > Train > Ride Hail
  const cancelTripData = [...flights, ...hotels, ...carRentals, ...trains, ...rideHails]

  return cancelTripData
}
