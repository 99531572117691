import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace DelegateErrors {
  export const UpdateDelegateTokenResponse = createErrorClass('Update Delegate token failed')
  export const UpdateDelegateCookieResponse = createErrorClass(
    'Update Delegate token via cookie failed',
  )
  export const UnexpectedErrorUpdateDelegateToken = createErrorClass('Update Delegate token failed')
}

export type DelegateErrorsInstances = InstanceType<
  typeof DelegateErrors[keyof typeof DelegateErrors]
>
