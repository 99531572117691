import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'

export function formatLocation(location: CarRentalMultiVendorLocation): string {
  const { airportLocation, airportName, companyLocationName, address } = location
  const { street1, city, stateCode, countryCode } = address

  const airport = airportName || companyLocationName

  return `${airportLocation && airport ? airport : street1}, ${city}, ${stateCode || countryCode}`
}
