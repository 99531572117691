import type * as React from 'react'
import { is } from 'ramda'

export function isElementOfType<P = {}>(
  element: any,
  ComponentType: React.ComponentType<P>,
): element is React.ReactElement<P> {
  return (
    element != null &&
    element.type != null &&
    element.type.displayName != null &&
    element.type.displayName === ComponentType.displayName
  )
}

export function isFunction(value: any): value is Function {
  return typeof value === 'function'
}

export function safeInvoke<A, B, C, R>(
  func: ((arg1: A, arg2: B, arg3: C) => R) | undefined,
  arg1: A,
  arg2: B,
  arg3: C,
): R | undefined

export function safeInvoke(func: Function | undefined, ...args: any[]) {
  if (isFunction(func)) {
    return func(...args)
  }
  return undefined
}

export function isString(value: any): value is string {
  return is(String, value)
}
