import { useHistory, useParams } from 'react-router-dom'
import type { TermsAndConditions, TravelerAddressInputDto } from '@fiji/graphql/types'
import { ProfileDataSource } from '@etta/core/enums'
import {
  useLuggageOptionsContext,
  usePassportContext,
  usePaymentSummaryContext,
} from '@etta/modules/booking'
import { usePreSearchContext } from '@etta/modules/pre-search'
import { ROUTES } from '@fiji/routes'
import { useGetPreBookInfoQuery } from '@fiji/graphql/hooks'
import { StepName } from '@etta/screens/checkout-page/types'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useStartOverModal } from '@fiji/hooks/use-start-over-modal/use-start-over-modal'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { mapLuggageOption } from '@fiji/utils/map-luggage-option'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { AddressValueObject } from '@etta/core/value-objects/address.value-object'
import type { FormatTypes } from './types'

export function useTravelFusionLinks() {
  const history = useHistory()
  const { isShowDelegateName } = useShowDelegateName()
  const { costAllocationStore } = useCostAllocationContext()
  const { luggageOptionsStore } = useLuggageOptionsContext()
  const { costAllocationForBooking } = costAllocationStore
  const isTravelFusionLinksPage = useRouteMatch(StepName.PurchaseTermsAndConditions)
  const { passportStore } = usePassportContext()
  const { outOfPolicyStore } = useOutOfPolicyContext()
  const { selectedPassportId } = passportStore
  const { oopExplanation, oopSegments } = outOfPolicyStore
  const { returnLuggageOption, departureLuggageOption } = luggageOptionsStore

  const { paymentSummaryStore } = usePaymentSummaryContext()
  const { customFields: purchaseCustomFields, paymentStructure } = paymentSummaryStore
  const { preSearchStore } = usePreSearchContext()
  const { checkoutInfoStore } = useCheckoutInfoContext()

  const purhcaseCustomFieldsBooking = purchaseCustomFields.map((field) => ({
    value: String(field.value),
    name: String(field.name),
    collectionId: String(field.collectionId),
  }))

  const customFields = [...preSearchStore.customFields, ...purhcaseCustomFieldsBooking]
  const { itineraryId, bookingId } = useParams<{ itineraryId: string; bookingId?: string }>()

  const { data, loading, error } = useGetPreBookInfoQuery({
    variables: {
      input: {
        itineraryId,
        payment: paymentStructure,
        customFields,
        oopExplanation: oopExplanation || undefined,
        oopSegments,
        costAllocation: costAllocationForBooking,
        passportId: selectedPassportId,
        profileDataSource: ProfileDataSource.Profile,
        departureLuggageOption: mapLuggageOption(departureLuggageOption),
        returnLuggageOption: mapLuggageOption(returnLuggageOption),
        traveler: {
          phoneNumber: checkoutInfoStore.phoneNumbers,
          address:
            checkoutInfoStore.address && toPrebookInfoAddressInput(checkoutInfoStore.address),
          gender: checkoutInfoStore.gender,
          dateOfBirth: checkoutInfoStore.dateOfBirth,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !isTravelFusionLinksPage,
  })

  const { startOverModalSlot } = useStartOverModal({
    isLoading: loading,
    bookingId: itineraryId,
    isPreventForward: isTravelFusionLinksPage,
    isPreventBack: false,
    canOpenPostBookingTripInsteadOfExplore: false,
  })

  const onBack = () => {
    history.push(ROUTES.checkout({ itineraryId, bookingId, checkoutStep: 'payment-info' }))
  }

  const getTermsFormatType = (terms: TermsAndConditions): FormatTypes | null => {
    const { format, content } = terms
    switch (true) {
      case (format as FormatTypes) === 'URL' || !!content?.startsWith('http'):
        return 'URL'
      case (format as FormatTypes) === 'TEXT' && !!content:
        return 'TEXT'
      default:
        return null
    }
  }

  const termsAndConditionsSorted = data?.preBookInfo?.termsAndConditions?.slice().sort((info) => {
    if (getTermsFormatType(info) === 'URL') {
      return 1
    }
    if (getTermsFormatType(info) === 'TEXT') {
      return -1
    }
    return 0
  })

  return {
    onBack,
    getTermsFormatType,
    areTermsLoading: !!loading,
    areTermsError: !!error || !!(termsAndConditionsSorted?.length === 0),
    termsAndConditions: termsAndConditionsSorted,
    startOverModalSlot,
    isShowDelegateName,
  }
}

function toPrebookInfoAddressInput(address: AddressValueObject): TravelerAddressInputDto {
  return {
    city: address.city,
    countryCode: address.countryCode,
    postalCode: address.postalCode,
    stateCode: address.stateCode,
    street1: address.street1,
    street2: address.street2,
  }
}
