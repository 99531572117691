import type { ReactNode } from 'react'
import { ScreenType, useScreenType } from '@fiji/modes'

type Props = {
  mobileSlot: ReactNode
  desktopSlot: ReactNode
  tabletSlot?: ReactNode
  forceScreenType?: null | ScreenType
}

export function MediaLayout({ mobileSlot, desktopSlot, tabletSlot, forceScreenType }: Props) {
  const defaultScreenType = useScreenType()
  const screenType = forceScreenType || defaultScreenType

  switch (screenType) {
    case ScreenType.Desktop:
      return <>{desktopSlot}</>
    case ScreenType.Tablet:
      return <>{tabletSlot || desktopSlot}</>
    case ScreenType.Mobile:
    default:
      return <>{mobileSlot}</>
  }
}
