import { useTranslation } from 'react-i18next'
import type { TravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { Icon } from '@etta/ui/icon'
import type { IconNames } from '@etta/ui/icon'

import {
  DepartureAndArrival,
  Departure,
  ChangesLine,
  ChangesWrapper,
  ChangesDot,
  Arrival,
  Time,
  Date,
  StationWrapper,
  StationName,
} from './rail-departure-and-arrival-styled'

type Props = {
  departure: TravelRailLocation
  arrival: TravelRailLocation
  travelChanges: number
  iconNames: IconNames[]
}

const i18nBaseLabels = 'RailDetails.AriaLabels.'

export function RailDepartureAndArrival({ departure, arrival, travelChanges, iconNames }: Props) {
  const { t } = useTranslation()

  const getLabels = (railLocation: TravelRailLocation) => {
    return {
      date: railLocation.date,
      time: railLocation.time,
      station: railLocation.stationName,
    }
  }

  const departureLabel = t(i18nBaseLabels + 'DepartureLabel', getLabels(departure))
  const arrivalLabel = t(i18nBaseLabels + 'ArrivalLabel', getLabels(arrival))

  return (
    <>
      <DepartureAndArrival>
        <Departure aria-label={departureLabel}>
          <Date variant="subHeadMedium" color="bodyText">
            {departure.date}
          </Date>
          <Time variant="title3" color="mainText">
            {departure.time}
          </Time>
        </Departure>
        <ChangesLine>
          <ChangesWrapper>
            {iconNames?.length
              ? iconNames.map((name) => (
                  <Icon key={name} name={name} size="small" color="mainText" />
                ))
              : Array.from(Array(travelChanges).keys()).map((_, index) => (
                  <ChangesDot key={index} />
                ))}
          </ChangesWrapper>
        </ChangesLine>
        <Arrival aria-label={arrivalLabel}>
          <Date variant="subHeadMedium" color="bodyText">
            {arrival.date}
          </Date>
          <Time variant="title3" color="mainText">
            {arrival.time}
          </Time>
        </Arrival>
      </DepartureAndArrival>
      <StationWrapper marginTop={4}>
        <StationName variant="footnoteMedium" color="bodyText">
          {departure.stationName}
        </StationName>
        <StationName variant="footnoteMedium" color="bodyText" align="right">
          {arrival.stationName}
        </StationName>
      </StationWrapper>
    </>
  )
}
