import { Inject } from 'typedi'
import { Action } from '@etta/di'
import { SettingsService } from '@etta/modules/settings/interface/services/settings.service'
import type { UserSuffix, UserTitle } from '@etta/modules/user'
import type { Gender } from '@etta/core/enums'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { UserInformationStore } from '../stores/user-information.store'
import { UserStore } from '../stores/user.store'
import { UserProfileValidatorBuilder } from '../services/user-profile-validator-builder'
import { UserProfileStore } from '../stores/user-profile.store'

@Action()
export class UserInformationActions {
  constructor(
    @Inject() private readonly userInformationStore: UserInformationStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly settingsService: SettingsService,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {}

  initializePersonalInfoAddationalInfoForm() {
    const additionalInformation = (
      this.userProfileStore.userProfile?.personalInformation.additionalInformation ?? []
    )
      .concat(this.userProfileStore.userProfile?.homeAddress.additionalInformation ?? [])
      .concat(this.userProfileStore.userProfile?.emergencyContact.additionalInformation ?? [])

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.userInformationStore.setAdditionalInfoValidator(validator)
  }

  initializeConfirmationEmail() {
    const profile = this.userStore.profile
    this.userInformationStore.setFormValue(
      'confirmationEmail',
      this.getStrValueOr(profile?.confEmail),
    )
    if (profile?.confEmail) {
      this.userInformationStore.setIsConfEmailInputVisible(true)
    }
  }

  initializePersonalInfo() {
    const profile = this.userStore.profile
    const category = FieldSettingsCategory.PersonalInformation
    this.setFormValue('title', category, profile?.title)
    this.setFormValue('firstName', category, profile?.firstName)
    this.setFormValue('middleName', category, profile?.middleName)
    this.setFormValue('lastName', category, profile?.lastName)
    this.setFormValue('suffix', category, profile?.suffix)
    this.setFormValue('gender', category, profile?.gender)
    this.setFormValue(
      'dateOfBirth',
      category,
      profile?.dateOfBirth ? getDateWithoutTimezoneOffset(profile.dateOfBirth) : null,
    )
    this.setFormValue('email', category, profile?.personalContact?.email)
    this.setFormValue('username', category, profile?.username)
  }

  initializeHomeContactInfo() {
    const profile = this.userStore.profile
    const category = FieldSettingsCategory.HomeAddress
    this.setFormValue(
      'homeCountryCode',
      category,
      profile?.personalContact?.address?.countryCode,
      'countryCode',
    )
    this.setFormValue(
      'homePostalCode',
      category,
      profile?.personalContact?.address?.postalCode,
      'addressBlock',
    )
    this.setFormValue(
      'homeStateCode',
      category,
      profile?.personalContact?.address?.stateCode,
      'addressBlock',
    )
    this.setFormValue(
      'homeMailStop',
      category,
      profile?.personalContact?.address?.mailStop,
      'mailStop',
    )
    this.setFormValue('homeCity', category, profile?.personalContact?.address?.city, 'addressBlock')
    this.setFormValue(
      'homeStreet1',
      category,
      profile?.personalContact?.address?.street1,
      'addressBlock',
    )
    this.setFormValue(
      'homeStreet2',
      category,
      profile?.personalContact?.address?.street2,
      'addressBlock',
    )
  }

  initializeEmergencyContactInfo() {
    const profile = this.userStore.profile
    const category = FieldSettingsCategory.EmergencyContact
    this.setFormValue('emergencyName', category, profile?.emergencyContact?.name, 'name')
    this.setFormValue(
      'emergencyRelationship',
      category,
      profile?.emergencyContact?.relationship,
      'relationship',
    )
    this.setFormValue(
      'emergencyPrimaryPhoneCountryCode',
      category,
      profile?.emergencyContact?.primaryPhone?.countryCode,
      'primaryPhone',
    )
    this.setFormValue(
      'emergencyPrimaryPhone',
      category,
      profile?.emergencyContact?.primaryPhone?.number,
      'primaryPhone',
    )
    this.setFormValue(
      'emergencyAlternatePhoneCountryCode',
      category,
      profile?.emergencyContact?.alternatePhone?.countryCode,
      'alternatePhone',
    )
    this.setFormValue(
      'emergencyAlternatePhone',
      category,
      profile?.emergencyContact?.alternatePhone?.number,
      'alternatePhone',
    )
    this.setFormValue('emergencyEmail', category, profile?.emergencyContact?.email, 'email')
    this.setFormValue(
      'emergencyCountryCode',
      category,
      profile?.emergencyContact?.address?.countryCode,
      'countryCode',
    )
    this.setFormValue(
      'emergencyPostalCode',
      category,
      profile?.emergencyContact?.address?.postalCode,
      'addressBlock',
    )
    this.setFormValue(
      'emergencyStateCode',
      category,
      profile?.emergencyContact?.address?.stateCode,
      'addressBlock',
    )
    this.setFormValue(
      'emergencyMailStop',
      category,
      profile?.emergencyContact?.address?.mailStop,
      'mailStop',
    )
    this.setFormValue(
      'emergencyCity',
      category,
      profile?.emergencyContact?.address?.city,
      'addressBlock',
    )
    this.setFormValue(
      'emergencyStreet1',
      category,
      profile?.emergencyContact?.address?.street1,
      'addressBlock',
    )
    this.setFormValue(
      'emergencyStreet2',
      category,
      profile?.emergencyContact?.address?.street2,
      'addressBlock',
    )
  }

  async fetchTimeZones() {
    const timeZoneOptions = await this.settingsService.fetchTimeZones()
    this.userInformationStore.setTimeZoneOptions(timeZoneOptions)
  }

  handleConfEmailInputVisible(value: boolean) {
    this.userInformationStore.setIsConfEmailInputVisible(value)
  }

  handleUserInfoChange(key: Parameters<typeof this.userInformationStore.setFormValue>[0]) {
    return (value: string | boolean | UserTitle | UserSuffix | Gender | null | Date) =>
      this.userInformationStore.setFormValue(key, value)
  }

  handleDobChange(value?: Date) {
    this.handleUserInfoChange('dateOfBirth')(value ?? '')
    this.userInformationStore.setIsDobTouched(true)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) =>
      this.userInformationStore.setAdditionalInfoValue(key, value)
  }

  private setFormValue(
    validatorKey: Parameters<typeof this.userInformationStore.setFormValue>[0],
    category: FieldSettingsCategory,
    value?: string | boolean | UserTitle | UserSuffix | Gender | null | Date,
    profileSettingKey?: string,
  ) {
    const profileSettingField = this.userInformationStore.fieldSettings[category][
      profileSettingKey || validatorKey
    ]

    if (!value || !profileSettingField?.isRenderedOnActivationPage) {
      return
    }

    const initializedValue = typeof value === 'string' ? this.getStrValueOr(value) : value
    this.userInformationStore.setFormValue(validatorKey, initializedValue)
  }

  private getStrValueOr(value?: string | null, defaultValue: string = '') {
    if (value) {
      return value
    }
    return defaultValue
  }
}
