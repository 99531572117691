import { SegmentType } from '@fiji/graphql/types'
import type { PWAIconNames } from '@etta/ui/icon'

export function getIconNameBySegment(type: SegmentType): PWAIconNames {
  switch (type) {
    case SegmentType.Flight:
      return 'flightPWA'
    case SegmentType.Hotel:
      return 'hotelPWA'
    case SegmentType.CarRental:
      return 'carRentalPWA'
    case SegmentType.Train:
      return 'railPWA'
    case SegmentType.CarService:
      return 'editPWA'
    default:
      return 'flightPWA'
  }
}
