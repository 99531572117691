import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'
import { captionMedium, footnoteMedium } from '@fiji/style'

export const Body = styled(Modal.Body)`
  padding: 24px;
  height: 100%;
`

export const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 16px;
`

export const CharCounter = styled.div`
  margin-top: 6px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.bodyText};
  ${captionMedium}
`

export const Container = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`
export const ProgressContainer = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HeaderWrapper = styled.div`
  padding-right: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Error = styled.div`
  color: ${(p) => p.theme.colors.error};
  margin-top: 5px;
  ${footnoteMedium};
`
