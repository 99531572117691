import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const SeatColumn = styled.div`
  display: grid;
  place-items: center;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.bodyText1};
  ${footnoteMedium}
`
