import { formatRate } from '@fiji/utils/money/format-rate'
import type { CreditCard, TripCost } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { HotelReservationRow } from '../types'
import { getCardLabel } from '../payment-card/get-card-label'

type Args = {
  tripCost: TripCost
  segments: TripSegmentValueObject[]
  creditCard?: CreditCard
  isPayNow?: boolean
}

export function useHotelDetails({ tripCost, segments, creditCard, isPayNow }: Args) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { mainCost, secondCost } = formatRate(tripCost.total)
  const cardDetails = creditCard && getCardLabel(creditCard)

  return {
    cardDetails,
    mainCost,
    secondCost,
    rate: tripCost.total,
    creditCard,
    isPayNow,
    rows: segments.reduce<HotelReservationRow[]>((acc, segment) => {
      if (!segmentTypeCheckActions.isHotelSegment(segment)) {
        return acc
      }
      acc.push({
        segmentId: segment.id,
        checkInDate: segment.checkIn,
        checkOutDate: segment.checkOut,
        hotelName: segment.name,
        selectedRoom: segment.roomType,
        roomCount: segment.roomCount,
      })
      return acc
    }, []),
  }
}
