import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { MessageMobile } from './mobile'
import { MessageDesktop } from './desktop'

export function MessageLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<MessageMobile {...props} />}
      desktopSlot={<MessageDesktop {...props} />}
    />
  )
}
