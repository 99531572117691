export type FareAttributesResponseValueObject = {
  legId: string
  fareAttributes: FareAttributesValueObject[]
}

export type FareAttributesValueObject = {
  fareId: string
  comparison: FlightComparisonValueObject
  content: OnTheFlyContentValueObject[]
}

type FlightComparisonValueObject = {
  brand: FlightComparisonBrandValueObject
  utas: UniversalTicketAttributeValueObject[]
}

export type OnTheFlyContentValueObject = {
  items: OnTheFlyContentItemValueObject[]
  linkIconClass?: string | null
  location?: OnTheFlyContentLocationEnum | null
  segmentIndex?: number | null
}

type FlightComparisonBrandValueObject = {
  name: string
}

type UniversalTicketAttributeValueObject = {
  assessment: OnTheFlyContentAssessmentEnum
  category: string
  description: string
}

type UniversalTicketAttributeCallToActionValueObject = {
  url: string
}

type OnTheFlyContentItemValueObject = {
  body: string
  callToAction?: UniversalTicketAttributeCallToActionValueObject | null
  details: OnTheFlyContentItemDetailValueObject[]
  headline: string
}

type OnTheFlyContentItemDetailValueObject = {
  body: string
  image: OnTheFlyContentItemDetailImageValueObject
}

type OnTheFlyContentItemDetailImageValueObject = {
  thumbnail: string
}

export enum OnTheFlyContentAssessmentEnum {
  Benefit = 'BENEFIT',
  Fee = 'FEE',
  Neutral = 'NEUTRAL',
  Restriction = 'RESTRICTION',
  Unspecified = 'UNSPECIFIED',
}

export enum OnTheFlyContentLocationEnum {
  Carrier = 'CARRIER',
  Destination = 'DESTINATION',
  Details = 'DETAILS',
  Features = 'FEATURES',
  Header = 'HEADER',
  Origin = 'ORIGIN',
  Unspecified = 'UNSPECIFIED',
}
