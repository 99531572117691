import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 14px 0;
`

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  &:last-child {
    border: 0;
  }
`
