import type { KeyboardEvent } from 'react'
import { TextField } from '@etta/ui/text-field'
import { useDescendantsWrapper } from '@etta/components/descendant'

type Props = {
  onChange: (search: string) => void
  placeholder: string
  value: string
  ariaLabel: string
}

export function TextInput({ placeholder, ariaLabel, onChange, value }: Props) {
  const { shouldFocusFirstElement, handleFocusFirstListElement } = useDescendantsWrapper()

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' && !shouldFocusFirstElement) {
      handleFocusFirstListElement()
    }
  }

  return (
    <TextField
      type="search"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onKeyDown={handleKeyDown}
      aria-label={ariaLabel}
    />
  )
}
