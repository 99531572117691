import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
// eslint-disable-next-line import/no-restricted-paths
import type { GetSearchInitiateInfoQuery } from '@fiji/graphql/types'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'

@Store()
export class PreSearchStore {
  constructor(
    @Inject() private historyStore: HistoryStore,
    @Inject() private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  pageQueryParams = new QueryParams<{
    itineraryId?: string
    customFields?: CustomFieldBookingValueObject[]
  }>({}, { caseStyle: 'kebab-case', arrayFields: ['customFields'] })

  private _customFields = new LocalStorageItem<CustomFieldBookingValueObject[]>(
    'preSearchCustomFields',
  )
  searchInitiateInfo?: GetSearchInitiateInfoQuery = undefined
  isOpenPreSearchCustomFields: boolean = false

  private get queryParams() {
    return this.pageQueryParams.getQueryParams(this.historyStore.search)
  }

  private get searchQueryCustomFields() {
    return this.pageQueryParams.getQueryParams(this.historyStore.search).customFields
  }

  get selectedCustomFields() {
    return this.searchQueryCustomFields ?? this.customFields
  }

  get isShowingPreSearchModal() {
    const isSearchInitiated = !!this.queryParams.itineraryId

    if (isSearchInitiated) {
      return false
    }

    if (this.displayConfigurationStore.isPreBookCostAllocationEnabled) {
      return true
    }

    const isPresearchCustomfieldRequired = !!this.searchInitiateInfo?.searchInitiateInfo
      ?.customFieldsConfiguration?.customFieldCollection?.length

    return isPresearchCustomfieldRequired
  }

  get customFields() {
    return this._customFields.value ?? []
  }

  handleClosePreSearchCustomFields() {
    this.isOpenPreSearchCustomFields = false
  }

  handleOpenPreSearchCustomFields() {
    this.isOpenPreSearchCustomFields = true
  }

  setCustomFields(value: CustomFieldBookingValueObject[]) {
    this._customFields.set(value)
  }

  setSearchInitiateInfo(value: GetSearchInitiateInfoQuery) {
    this.searchInitiateInfo = value
  }

  dropCustomFields() {
    this._customFields.remove()
  }
}
