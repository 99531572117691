import { lazy } from 'react'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

const CheckoutPage = lazy(() => import('@etta/screens/checkout-page'))

export const checkoutRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.checkout({}),
      exact: false,
    },
    documentTitle: getDocumentTitle('Checkout'),
    transitionIndex: 6,
    transitionGroup: 'slide',
    component: () => <CheckoutPage />,
  },
]
