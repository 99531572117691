import { useEffect } from 'react'
import { trackAction } from '@fiji/utils/tracking/track-action'
import { SelectionState } from '../types'

const ACTION_NAME_DROP_OFF = 'mobility-drop-off-modal'
const ACTION_NAME_PICK_UP = 'mobility-pick-up-modal'
const ACTION_NAME_DATE_TIME = 'mobility-date-time-modal'

export type Props = {
  isVisible: boolean
  state?: SelectionState
}

export function useTrackMobilitySelectLocationModal({ state, isVisible }: Props) {
  useEffect(() => {
    if (isVisible) {
      switch (state) {
        case SelectionState.dropOff:
          trackAction(ACTION_NAME_DROP_OFF)
          break
        case SelectionState.pickUp:
          trackAction(ACTION_NAME_PICK_UP)
          break
        case SelectionState.dateTime:
          trackAction(ACTION_NAME_DATE_TIME)
          break
      }
    }
  }, [isVisible, state])
}
