import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'

export const AccountInformationIdentifiers = {
  5: 'title',
  6: 'firstName',
  7: 'middleName',
  8: 'lastName',
  3: 'username',
  9: 'suffix',
  11: 'gender',
  44: 'dateOfBirth',
  123: 'profilePin',
  118: 'vipIndicator',
  32: 'employeeType',
  36: 'employeeID',
  35: 'employeeStatus',
  12: 'jobTitle',
  16: 'jobLevel',
  17: 'managerID',
  107: 'expenseApproverName',
  162: 'travelApproverID',
  13: 'costCenter',
  14: 'departmentName',
  30: 'departmentCode',
  18: 'divisionCode',
  15: 'businessUnit',
  41: 'companyName',
  140: 'mis1',
  141: 'mis2',
  142: 'mis3',
  143: 'mis4',
  144: 'mis5',
  145: 'mis6',
  146: 'mis7',
  157: 'addressBlock',
  19: AddressFields.Mailstop,
  51: AddressFields.Country,
  50: AddressFields.Zip,
  49: AddressFields.State,
  48: AddressFields.City,
  46: AddressFields.Street1,
  47: AddressFields.Street2,
  158: 'addressBlock',
  52: AddressFields.Mailstop,
  29: AddressFields.Country,
  28: AddressFields.Zip,
  27: AddressFields.State,
  26: AddressFields.City,
  24: AddressFields.Street1,
  25: AddressFields.Street2,
  116: 'homePhone',
  117: 'homeEmail',
  21: 'officePhone',
  23: 'faxPhone',
  22: 'mobilePhone',
  45: 'groupwareId',
  20: 'businessEmail',
}
