import { useMemo } from 'react'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { screenMatcher, ScreenType } from '@fiji/modes'

type Args = {
  checkInDate?: Date | null
  checkOutDate?: Date | null
}

export function useDatesLabel({ checkInDate, checkOutDate }: Args) {
  const screenType = screenMatcher.getScreenType()
  const datesLabel = useMemo(() => {
    const datesFormat = screenType === ScreenType.Mobile ? 'MMM dd' : 'EEE, MMM dd'
    const checkInDateFormatted = checkInDate ? dateFormat(checkInDate, datesFormat) : null
    const checkOutDateFormatted = checkOutDate ? dateFormat(checkOutDate, datesFormat) : null
    return [checkInDateFormatted, checkOutDateFormatted].filter(Boolean).join(' - ')
  }, [checkInDate, checkOutDate, screenType])

  return { datesLabel }
}
