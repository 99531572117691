import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const StopsLineContainer = styled.div<{ topPosition?: number }>`
  text-align: center;
  position: relative;
  width: auto;
  flex: 1;
  top: ${(props) => (props.topPosition ? `${props.topPosition}px` : 'auto')};
`

export const StopsContaier = styled.div`
  position: absolute;
  display: flex;
  margin: 0;
  padding: 0 5px;
  bottom: 2px;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium}
`

const LineSeparator = css`
  content: '';
  position: absolute;
  top: 2px;
  height: 14px;
  width: 1px;
  left: 0;
  background: ${(props) => props.theme.colors.borderDark};
`

export const Stop = styled.p<{ hasRightMargin?: boolean; hasLineSeparator?: boolean }>`
  position: relative;
  margin: ${(props) => (props.hasRightMargin ? '0 4px 0 0' : '0')};
  padding-left: ${(props) => (props.hasLineSeparator ? '5px' : '0')};
  display: inline;
  &:after {
    ${(props) => props.hasLineSeparator && LineSeparator};
  }
`

export const HiddenStopsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 18px;
  padding: 0 4px 0 5px;
  &:after {
    ${LineSeparator}
  }
`

export const HiddenStop = styled.div`
  margin-left: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.bodyText};
  &:first-child {
    margin-left: 0;
  }
`

export const DurationLineContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.inputBorderColor};
  bottom: 10px;
`

export const StopsBlock = styled.div`
  display: flex;
  padding: 0 5px;
  background-color: ${(p) => p.theme.colors.white};
`

export const StopsAligner = styled.div`
  display: flex;
  gap: 7px;
  align-items: baseline;
`

export const StopCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 2px;
  background-color: ${(p) => p.theme.colors.white};
`

export const StopCode = styled(Text)`
  padding: 2px 4px;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  border-radius: 2px;
`
