import { useState, useCallback, useMemo, useEffect } from 'react'
import { useMobilityViewClose } from '@fiji/hooks/mobility'
import { LogEventType, useLogEventMutation } from '@fiji/graphql/hooks'
import { useUserContext } from '@etta/modules/user'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { appMode } from '@fiji/modes'
import { useQueryParams } from '@fiji/hooks/use-query'
import type { CustomerUberAccountStatus } from '@fiji/graphql/types'
import { mapToUberAccountStatusEnum } from '@etta/modules/ride-hail/infra/mappers/customer-uber-account-status.mapper'
import { useGetUberInfoActionText } from './hooks/use-get-uber-info-action-text'

export function useUberInfoActionCard() {
  const { handleClose } = useMobilityViewClose()
  const {
    createCustomerUberAccountActions,
    createCustomerUberAccountStore,
    getCustomerUberAccountStatusStore,
  } = useRideHailSearchContext()
  const {
    status,
    linkingUrl,
    isUserAccountSelfCreationEnabled,
    isError: isGetCustomerUberAccountStatusError,
  } = getCustomerUberAccountStatusStore
  const { isError: isCreateCustomerUberAccountError } = createCustomerUberAccountStore

  const { userStore } = useUserContext()
  const { tripsListStore: ordersStore } = useTripsListContext()

  const { companyId, customerId, profile } = userStore
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isCreateAttempted, setIsCreateAttempted] = useState(false)
  const [logMobilitySubmission] = useLogEventMutation()
  const { title, description, buttonText } = useGetUberInfoActionText(
    isSubmitted,
    isCreateAttempted,
  )
  const { isOpenInIOS } = appMode

  const { queryParams } = useQueryParams<{
    'user-status': CustomerUberAccountStatus
    'linking-url': string
    'user-uber-account-self-creation-enabled': boolean
    'hide-action-button': boolean
  }>()
  const {
    'user-status': queryParamsUserStatus,
    'linking-url': queryParamsLinkingUrl,
    'user-uber-account-self-creation-enabled': queryParamsUserUberAccountSelfCreationEnabled,
    // used by iOS or any other external client to set the visibility of the button panel
    'hide-action-button': queryParamsHideActionButton,
  } = queryParams

  const isShowCard = useMemo(() => {
    if (queryParamsHideActionButton || !companyId || !customerId || !profile) {
      return false
    }

    if (status !== UberAccountStatusEnum.Ready) {
      return true
    }

    return (
      !ordersStore.orders[TripType.Upcoming] ||
      ordersStore.orders[TripType.Upcoming]?.totalResults === 0
    )
  }, [companyId, customerId, ordersStore.orders, profile, queryParamsHideActionButton, status])

  useEffect(() => {
    if (isOpenInIOS) {
      getCustomerUberAccountStatusStore.setStatus(mapToUberAccountStatusEnum(queryParamsUserStatus))
      getCustomerUberAccountStatusStore.setLinkingUrl(queryParamsLinkingUrl)
      getCustomerUberAccountStatusStore.setIsUserAccountSelfCreationEnabled(
        queryParamsUserUberAccountSelfCreationEnabled,
      )
    }
  }, [
    getCustomerUberAccountStatusStore,
    isOpenInIOS,
    queryParamsUserStatus,
    queryParamsLinkingUrl,
    queryParamsUserUberAccountSelfCreationEnabled,
  ])

  useEffect(() => {
    if (linkingUrl && isCreateAttempted && status === UberAccountStatusEnum.NotLinked) {
      window.open(linkingUrl, '_blank')
      handleClose()
    }
  }, [isCreateAttempted, linkingUrl, status, handleClose])

  const handleSubmit = useCallback(() => {
    if (status === UberAccountStatusEnum.NotFound && isUserAccountSelfCreationEnabled) {
      setIsCreateAttempted(true)
      createCustomerUberAccountActions.createCustomerUberAccount()
      return
    }

    if (status === UberAccountStatusEnum.NotLinked && linkingUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(linkingUrl, '_blank')
      return handleClose()
    }

    logMobilitySubmission({
      variables: {
        input: {
          type: LogEventType.MobilityEntrypointSubmitted,
          payload: JSON.stringify({
            companyId: companyId,
            companyName: profile?.site?.name,
            customerId: customerId,
            customerEmail: profile?.personalContact?.email,
            customerFullName: `${profile?.firstName} ${profile?.lastName}`,
            customerProviderStatus: status,
          }),
        },
      },
    })

    if (status === UberAccountStatusEnum.Unspecified) {
      return handleClose()
    }
    if (status === UberAccountStatusEnum.Ready) {
      if (isOpenInIOS) {
        const OPEN_BOOK_TAB = 'mobility://openBookTab?event=onBookATripButtonTapped'
        document.location.href = OPEN_BOOK_TAB
        return
      }

      return handleClose()
    }

    return setIsSubmitted(true)
  }, [
    logMobilitySubmission,
    companyId,
    profile?.site?.name,
    profile?.personalContact?.email,
    profile?.firstName,
    profile?.lastName,
    customerId,
    status,
    linkingUrl,
    isUserAccountSelfCreationEnabled,
    handleClose,
    createCustomerUberAccountActions,
    isOpenInIOS,
  ])

  return {
    title,
    description,
    buttonText,
    isSubmitted,
    isShowCard,
    handleSubmit,
    isCreateCustomerUberAccountExecuting:
      createCustomerUberAccountStore.isCreateCustomerUberAccountExecuting,
    isError:
      isGetCustomerUberAccountStatusError ||
      isCreateCustomerUberAccountError ||
      status === UberAccountStatusEnum.Unspecified,
  }
}
