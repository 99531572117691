import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { KeyboardNavigationStyle } from '@fiji/style'

const BagContainerDisabledStyle = css`
  background-color: ${({ theme }) => theme.colors.background};
  pointer-events: none;
`

const BagContainerEnabledStyle = css`
  background-color: ${({ theme }) => theme.colors.primary};
  ${KeyboardNavigationStyle}
`

export const BagContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 67px;
  height: 38px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7px;
  gap: 3px;
  cursor: pointer;
  ${({ disabled }) => (disabled ? BagContainerDisabledStyle : BagContainerEnabledStyle)}
`

export const AmountContainer = styled(Text)`
  position: relative;
  top: 2px;
`
