import type { ReactNode } from 'react'
import { deemHeaderSelector } from '@fiji/store/deem-header'
import { default as DeemFooter } from '@fiji/components/deem-footer'
import { default as DeemHeader } from '@fiji/components/deem-header'
import type { AppState } from '@fiji/store'
import { useAppSelector } from '@fiji/store'
import { Container, Footer } from './deem-container-components'

type Props = {
  children: ReactNode
}

export function DeemContainer({ children }: Props) {
  const { isFooterVisible, isMobileMapVisible } = useAppSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const {
    areStylesheetsLoaded,
    deemHeaderData,
    deemHeaderError,
    isDeemHeaderLoading,
  } = useAppSelector(deemHeaderSelector)

  return (
    <>
      <Container isFooterVisible={!(isMobileMapVisible || !isFooterVisible)}>
        <DeemHeader
          areStylesheetsLoaded={areStylesheetsLoaded}
          loading={isDeemHeaderLoading}
          error={deemHeaderError}
          content={deemHeaderData?.deemHeader.header as string}
        />

        {children}

        <Footer>
          <DeemFooter
            loading={isDeemHeaderLoading}
            error={deemHeaderError}
            content={deemHeaderData?.deemHeader.footer as string}
          />
        </Footer>
      </Container>
    </>
  )
}
