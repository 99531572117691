import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { ArrowButton } from './arrow-button'
import { Container, SliderContainer, Dot, DotContainer } from './footer-styles'

type Props = {
  totalCount: number
  messageIndex: number
  onOpen: (e: MouseEvent<HTMLButtonElement>) => void
  onUpdateMessageIndex: (index: number) => void
  handleSetAnchorId: (messageId: string | null) => void
}

const i18nBase = 'DynamicSiteMessage'

export function Footer({
  totalCount,
  messageIndex,
  onOpen,
  onUpdateMessageIndex,
  handleSetAnchorId,
}: Props) {
  const { t } = useTranslation()
  const isLeftArrowDisabled = messageIndex === 0
  const isRightArrowDisabled = messageIndex === totalCount - 1

  return (
    <Container>
      <Button
        variant="text"
        data-tracking-id="dsm-all-messages"
        size="small"
        paddings={false}
        onClick={(e) => {
          handleSetAnchorId(null)
          onOpen(e)
        }}>
        {t(`${i18nBase}.AllMessages`, { count: totalCount })}
      </Button>
      <SliderContainer>
        <ArrowButton
          aria-label={t(`${i18nBase}.Right`)}
          dataTrackingId="dsm-slider-arrow-left"
          iconName="chevronLeftPWA"
          isDisabled={isLeftArrowDisabled}
          onClick={() => onUpdateMessageIndex(messageIndex - 1)}
        />
        {Array.from(Array(totalCount).keys()).map((idx: number) => (
          <DotContainer key={idx}>
            <Dot isActive={messageIndex === idx} />
          </DotContainer>
        ))}
        <ArrowButton
          aria-label={t(`${i18nBase}.Left`)}
          dataTrackingId="dsm-slider-arrow-right"
          iconName="chevronRightPWA"
          isDisabled={isRightArrowDisabled}
          onClick={() => onUpdateMessageIndex(messageIndex + 1)}
        />
      </SliderContainer>
    </Container>
  )
}
