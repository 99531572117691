import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Wrapper = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 2px solid ${(p) => p.theme.colors.borderLight};
`
