import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'

export function isOpenJawFlight(segments: FlightSegmentEntity[]): boolean {
  if (segments.length > 2) {
    return true
  }

  if (segments.length === 2) {
    const [, firstSegmentArrivalAirport, lastSegmentDepartureAirport] = segments?.reduce(
      (acc: string[], segment) => {
        const firstSegmentAirport = String(segment.segments.at(0)?.departure.airportCode)
        const lastSegmentAirport = String(segment.segments.at(-1)?.arrival.airportCode)
        return [...acc, firstSegmentAirport, lastSegmentAirport]
      },
      [],
    )

    if (firstSegmentArrivalAirport !== lastSegmentDepartureAirport) {
      return true
    }
  }

  return false
}
