import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Container } from './footer-skeleton-desktop-styled'

export function FooterSkeletonDesktop() {
  const { t } = useTranslation()
  const i18Base = 'CostWithCTA'

  return (
    <Container>
      <Button variant="solid" minWidth={14} disabled>
        {t(`${i18Base}.Continue`)}
      </Button>
    </Container>
  )
}
