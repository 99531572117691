import type { PropsWithChildren } from 'react'
import { Fragment } from 'react'
import { ChildContainer } from './switch-screen-styled'
import { ChildWithAnimation } from './child-with-animation'
import { ChildWithoutAnimation } from './child-without-animation'
import { PreloadPwaCommonData } from './preload-pwa-common-data'

type Props = PropsWithChildren<{
  isAnimationAvailable: boolean
  isFooterVisible: boolean
  isUsePreload: boolean
}>

const PAGE_ROLE = 'page-container'
export function SwitchScreen({
  children,
  isAnimationAvailable,
  isUsePreload,
  isFooterVisible,
}: Props) {
  const RootContainer = isAnimationAvailable ? ChildWithAnimation : ChildWithoutAnimation
  const CommonData = isUsePreload ? PreloadPwaCommonData : Fragment

  return (
    <RootContainer>
      <ChildContainer id={PAGE_ROLE} isFooterVisible={isFooterVisible} data-role={PAGE_ROLE}>
        <CommonData>{children}</CommonData>
      </ChildContainer>
    </RootContainer>
  )
}
