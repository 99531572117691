type buildMetadataLogsArgs = {
  isPhoenixHotelSearchEnabled?: boolean
  isPhoenixCarRentalSearchEnabled?: boolean
  itineraryId: string | undefined
}

export function buildMetadataLogs({
  isPhoenixHotelSearchEnabled,
  isPhoenixCarRentalSearchEnabled,
  itineraryId,
}: buildMetadataLogsArgs) {
  if (isPhoenixHotelSearchEnabled || isPhoenixCarRentalSearchEnabled) {
    return { itineraryId }
  }
  return null
}
