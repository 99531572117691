import styled from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const PriceList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  > * + * {
    margin-top: 10px;
  }
`

type PriceListItemProps = {
  variant: 'primary' | 'secondary'
}

export const PriceListItem = styled.li<PriceListItemProps>`
  ${footnoteMedium};
  display: flex;
  justify-content: space-between;
  color: ${(p) => (p.variant === 'secondary' ? p.theme.colors.mainText : p.theme.colors.bodyText)};
`

export const TotalCost = styled.div`
  ${headline};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
