import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useModifyHotel } from '@etta/screens/trip-hotel/hooks'
import type { HotelGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates'
import { ScreenType, useScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useHotelCancellationLabel } from '@fiji/hooks/use-hotel-cancellation-label'
import { useShareSegment } from '../../../use-trip-details/use-share-segment'

type Params = {
  segment: HotelGroupedSegmentValueObject
  itineraryId?: string
  bookingId?: string
  isUpcoming: boolean
  isOnHold: boolean
  isTripInProgress?: boolean
  onCancel?: () => void
  onClick?: () => void
}

const i18Base = 'PostBooking.TripDetails'
const LABELS_DIVIDER = ' • '
const CANCEL_TRANSLATION = 'PostBooking.TripDetails.Cancel'
const MODIFY_TRANSLATION = 'PostBooking.TripDetails.Modify'

export function useHotelSegment({
  segment,
  itineraryId,
  isUpcoming,
  isOnHold,
  bookingId,
  onCancel,
}: Params) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { handleModifyHotel } = useModifyHotel({
    hotel: segment,
    itineraryId: itineraryId || '',
    processId: bookingId || '',
  })
  const { postBookingTripStore } = usePostBookingContext()
  const { trip } = postBookingTripStore
  const { isHotelChangeAllowed, isHotelCancelAllowed } = trip.supportedActions
  const isCancelable = segment.isCancellable
  const { handleShare } = useShareSegment({ segment })
  const { t } = useTranslation()
  const noModifyHotel = useTogglePopup()
  const hotel = segment
  const address = hotel.address
  const isModificationAllowed = isHotelChangeAllowed
  const isCancellationAllowed = isHotelCancelAllowed && isCancelable
  const actionTitle = segment.isCheckIn ? t(i18Base + '.CheckIn') : t(i18Base + '.CheckOut')
  const typeTitle = t('PostBooking.HotelDetails.CancelType')

  const title = [actionTitle, segment.hotelName].join(' ')
  const ariaLabel = t(`${i18Base}.SegmentAriaLabel`, { label: `${title}` })
  const nights = segment.numberOfNights
    ? t(`${i18Base}.HotelNights`, { count: segment.numberOfNights })
    : ''

  const nightsAndRoom = `${nights} ${LABELS_DIVIDER} ${hotel.roomType}`

  const addressLabel = address
    ? `${address.street1 ? `${address.street1}, ` : ''}${address.city}, ${address.postalCode}`
    : ''

  const dataTexts = segment.isCheckOut ? [] : [nightsAndRoom]

  const checkInTime = segment.isCheckIn ? segment.date || '' : undefined

  const buttons = [
    {
      visible: isUpcoming,
      title: t(CANCEL_TRANSLATION),
      onClick: isCancellationAllowed ? onCancel : noModifyHotel.handleOpen,
    },
    {
      visible: isUpcoming && !isOnHold,
      title: t(MODIFY_TRANSLATION),
      onClick: isModificationAllowed ? handleModifyHotel : noModifyHotel.handleOpen,
    },
  ]

  const [cardTitleDates, checkInAndOutLabel, segmentPreview] = useMemo(() => {
    const { checkIn, checkOut, photos } = segment
    const checkInFormatDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkIn)
    const checkOutFormatDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkOut)
    const checkInLabel = `${t(i18Base + '.Check-in')} ${tripReviewFormatDate(
      TripReviewDateType.MonthAndDayAndYear,
      checkIn,
    )}`
    const checkOutLabel = `${t(i18Base + '.Check-out')} ${tripReviewFormatDate(
      TripReviewDateType.MonthAndDayAndYear,
      checkOut,
    )}`
    const checkLabel = isMobile
      ? `${checkInLabel}\n${checkOutLabel}`
      : `${checkInLabel} - ${checkOutLabel}`

    return [`${checkInFormatDate} - ${checkOutFormatDate}`, checkLabel, photos?.at(0)?.url]
  }, [segment, isMobile, t])

  const { cancellationPolicyLabel } = useHotelCancellationLabel({
    cancellationPolicy: segment.cancellationPolicy,
    cancellationPolicyDescription: segment.cancellationPolicyDescription,
  })

  // update after move hotel logic to new arch
  const handleOpenRateAndCancellationDetails = async () => {
    await new Promise((r) => setTimeout(r, 0))
    const xpath = "//a[@data-tracking-id='hotel-trip-segment-room-rate-details']"
    const matchingElement = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue
    ;(matchingElement as HTMLElement).click()
  }

  return {
    ariaLabel,
    nights,
    title,
    dataTexts,
    buttons,
    checkInTime,
    typeTitle,
    addressLabel,
    hotelStatus: segment.status,
    isNoModifyHotelOpen: noModifyHotel.isOpen,
    handleShare,
    handleNoModifyHotelClose: noModifyHotel.handleClose,
    cardTitleDates,
    cardTitle: segment.hotelName,
    checkInAndOutLabel,
    segmentPreview,
    cancellationPolicyLabel,
    handleOpenRateAndCancellationDetails,
    shouldDisplayManageBookingMenu: buttons.some((b) => b.visible),
  }
}
