import { Skeleton } from '@etta/ui/skeleton'
import { Container, Header, Body, Left, Right } from './rtp-itinerary-card-skeleton-styled-desktop'

export function RtpItineraryCardSkeletonDesktop() {
  return (
    <Container>
      <Header>
        <Left>
          <Skeleton width={106} height={19}>
            <rect width={106} height={19} />
          </Skeleton>
        </Left>
        <Right>
          <Skeleton width={113} height={19}>
            <rect width={113} height={19} />
          </Skeleton>
        </Right>
      </Header>
      <Body>
        <Left>
          <Skeleton width={222} height="100%">
            <rect y={22} width={222} height={19} />
            <rect y={56} width={156} height={14} />
            <rect y={86} width={90} height={14} />
          </Skeleton>
        </Left>
        <Right>
          <Skeleton width={93} height="100%">
            <rect y={22} width={93} height={19} />
            <rect x={77} y={86} width={15} height={14} />
          </Skeleton>
        </Right>
      </Body>
    </Container>
  )
}
