import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { MobilitySearchResultErrorType } from '@etta/components/mobility-search-result-dialog'
import type {
  SearchRideHailForm,
  SearchRideHailQueryParamsType,
  SearchRideHailTripInfo,
} from '../types'
import { RenderState } from '../types'
import type {
  SearchRideHailResultsValueObject,
  SearchRideHailResultValueObject,
} from '../../core/value-objects'

@Store()
export class SearchRideHailStore {
  searchRideHailQueryParams = new QueryParams<SearchRideHailQueryParamsType>(
    {},
    { caseStyle: 'kebab-case' },
  )

  private _searchRideHailError: MobilitySearchResultErrorType | null = null
  private _isLoading: boolean = false
  private _renderState: RenderState = RenderState.whereTo
  private _isFetchingRideHailConfig: boolean = false
  private _searchRideHailResults: SearchRideHailResultsValueObject | null = null
  private _selectedSearchRideHail: SearchRideHailResultValueObject | null = null
  private _searchRideHailForm: SearchRideHailForm = {}
  private _searchRideHailTripInfo: SearchRideHailTripInfo = {
    processId: '',
  }
  private _mobilitySearchDesktopModal: boolean = false
  private _leavingBookingFlowDialog: boolean = false

  constructor(@Inject() private historyStore: HistoryStore) {}

  get queryParams() {
    return this.searchRideHailQueryParams.getQueryParams(this.historyStore.search)
  }

  get sortedSearchRideHailResults() {
    const sortByPrice = (
      searchRideHail1: SearchRideHailResultValueObject,
      searchRideHail2: SearchRideHailResultValueObject,
    ) => {
      if (!searchRideHail1.estimatedPrice) {
        return 1
      }
      if (!searchRideHail2.estimatedPrice) {
        return -1
      }
      return searchRideHail1.estimatedPrice.amount - searchRideHail2.estimatedPrice.amount
    }

    return this._searchRideHailResults
      ? [...this._searchRideHailResults.results].sort(sortByPrice)
      : []
  }

  get isOnDemandRide() {
    return !this._searchRideHailForm?.pickUpDate && !this._searchRideHailForm?.pickUpTime
  }

  get isAirportDropOff() {
    return !!this._searchRideHailForm.dropOffPlace?.airportCode
  }

  get isError() {
    return this._searchRideHailError !== null
  }

  get searchRideHailError() {
    return this._searchRideHailError
  }

  setSearchRideHailError(error: MobilitySearchResultErrorType | null) {
    this._searchRideHailError = error
  }

  get selectedSearchRideHail() {
    return this._selectedSearchRideHail
  }

  setSelectedRideHail(selected: SearchRideHailResultValueObject | null) {
    this._selectedSearchRideHail = selected
  }

  get isLoading() {
    return this._isLoading
  }

  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  get renderState() {
    return this._renderState
  }

  setRenderState(renderState: RenderState) {
    this._renderState = renderState
  }

  get isFetchingRideHailConfig() {
    return this._isFetchingRideHailConfig
  }

  setIsFetchingRideHailConfig(value: boolean) {
    this._isFetchingRideHailConfig = value
  }

  get searchRideHailResults() {
    return this._searchRideHailResults
  }

  setSearchRideHailResults(result: SearchRideHailResultsValueObject) {
    this._searchRideHailResults = result
  }

  get searchRideHailTripInfo() {
    return this._searchRideHailTripInfo
  }

  setSearchRideHailTripInfo(searchRideHailTripInfo: SearchRideHailTripInfo) {
    this._searchRideHailTripInfo = searchRideHailTripInfo
  }

  get searchRideHailForm() {
    return this._searchRideHailForm
  }

  setLeavingBookingFlowDialog(leavingBookingFlowDialog: boolean) {
    this._leavingBookingFlowDialog = leavingBookingFlowDialog
  }

  get isOpenLeavingBookingFlowDialog() {
    return this._leavingBookingFlowDialog
  }

  setSearchRideHailForm(form: SearchRideHailForm) {
    this._searchRideHailForm = form
  }

  setMobilitySearchDesktopModel(isOpen: boolean) {
    this._mobilitySearchDesktopModal = isOpen
  }

  get isOpenMobilitySearchDesktopModal() {
    return this._mobilitySearchDesktopModal
  }

  dropSearchRideHailTripInfo() {
    this._searchRideHailTripInfo = {
      processId: '',
    }
  }

  dropSearchRideHailForm() {
    this._searchRideHailForm = {}
  }

  dropSearchRideHailResults() {
    this._searchRideHailResults = null
  }

  dropResults() {
    this._searchRideHailResults = null
    this._selectedSearchRideHail = null
    this._searchRideHailError = null
    this._isLoading = false
  }

  dropStore() {
    this._renderState = RenderState.whereTo
    this._isFetchingRideHailConfig = false
    this.dropResults()
    this.dropSearchRideHailForm()
  }
}
