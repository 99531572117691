import { useEffect, useRef, useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'

type Args = {
  hasMultipleItems?: boolean
  shouldExpandMessages?: boolean
  shouldRecalculateTruncate?: boolean
}

function checkMessageTruncated(el: HTMLDivElement | null) {
  return el && el.scrollHeight > el.clientHeight
}

export function useTruncateDsmMessage({
  hasMultipleItems,
  shouldExpandMessages = false,
  shouldRecalculateTruncate = false,
}: Args) {
  const messageRef = useRef<HTMLDivElement>(null)
  const [isMessageTruncated, setIsMessageTruncated] = useState(
    checkMessageTruncated(messageRef.current),
  )
  const {
    isOpen: isTruncated,
    handleOpen: handleExpand,
    handleClose: handleTruncate,
  } = useTogglePopup()
  const shouldTruncateMessage = !isTruncated && hasMultipleItems
  const shouldShowShowMoreButton = shouldTruncateMessage && isMessageTruncated

  useEffect(() => {
    setIsMessageTruncated(checkMessageTruncated(messageRef.current))

    if (shouldExpandMessages) {
      handleExpand()
    }
  }, [handleExpand, shouldExpandMessages])

  useEffect(() => {
    if (!shouldRecalculateTruncate) {
      return
    }
    setIsMessageTruncated(checkMessageTruncated(messageRef.current))
  }, [shouldRecalculateTruncate])

  return {
    handleExpand,
    handleTruncate,
    shouldTruncateMessage: shouldTruncateMessage ?? false,
    shouldShowShowMoreButton: shouldShowShowMoreButton ?? false,
    isMessageTruncated: isMessageTruncated ?? false,
    messageRef,
  }
}
