import styled, { css } from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'
import { ScreenType } from '@fiji/modes'
import { rowCSS } from '../../flight-details-styled'

export const Section = styled.div<{ screenType: ScreenType }>`
  ${rowCSS};
  ${(p) => {
    switch (p.screenType) {
      case ScreenType.Desktop:
        return css`
          flex-direction: row-reverse;
        `
    }
  }}
  padding: 16px;
`

export const RowCenter = styled.div`
  ${rowCSS};
  margin-top: 4px;
  justify-content: flex-start;
  align-items: center;
`

export const TitleSection = styled.div`
  ${rowCSS};
  flex-direction: column;
`

type StatusProps = TextProps & { isDelayed: boolean }

export const Status = styled(Text).attrs<StatusProps, TextProps>(({ isDelayed }) => ({
  variant: 'captionStrongCaps',
  color: isDelayed ? 'warning' : 'success',
  isBlock: true,
}))<StatusProps>`
  display: flex;
  margin-left: 4px;
  line-height: 15px;
`

export const Logo = styled.div<{ screenType: ScreenType }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 44px;
  width: 44px;
  height: 44px;
  max-height: 44px;
  flex-wrap: wrap;
  ${(p) => {
    switch (p.screenType) {
      case ScreenType.Desktop:
        return css`
          margin-right: 16px;
        `
    }
  }}

  img {
    max-width: 44px;
    max-height: 44px;
    width: 100%;
    height: auto;
  }
`
