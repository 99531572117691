import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { HotelRateCap } from '@etta/components/hotel-rate-cap/hotel-rate-cap'
import type { RoomType } from '@fiji/graphql/types'
import type { LayoutProps } from '../types'
import { RoomsContent } from '../rooms-content'
import { useRoomsContentLayoutDesktop } from './use-rooms-content-layout-desktop'
import { HotelServiceFee } from './hotel-service-fee'
import { TitleContainer, TitleWrapper, RootContainer } from './rooms-content-layout-desktop-styled'
import { ROOMS_TYPE_ICONS_AND_TITLES_DICT } from './room-types'

const roomTypes = Object.keys(ROOMS_TYPE_ICONS_AND_TITLES_DICT) as RoomType[]

export function RoomsContentLayoutDesktop({
  rooms,
  perDiemRateCap,
  areRoomsSelectable,
  onRoomSelect,
  onRoomDetailsSelect,
  serviceFeeLabel,
  totalNights,
}: LayoutProps) {
  const { groupedRooms, filteredSortedRoomTypes } = useRoomsContentLayoutDesktop({
    rooms,
    roomTypes,
  })
  const { t } = useTranslation()

  return (
    <>
      <HotelRateCap perDiemRateCap={perDiemRateCap} skin="hotel-details" />
      {serviceFeeLabel && (
        <Block marginHorizontal={16} marginTop={8} marginBottom={16}>
          <HotelServiceFee serviceFeeLabel={serviceFeeLabel} />
        </Block>
      )}

      {filteredSortedRoomTypes.map((groupKey) => {
        const rooms = groupedRooms[groupKey]
        if (!rooms) {
          return null
        }

        const { title, icon } = ROOMS_TYPE_ICONS_AND_TITLES_DICT[groupKey]

        return (
          <RootContainer key={groupKey}>
            {areRoomsSelectable && (
              <Block marginBottom={16} paddingHorizontal={16}>
                <Text
                  aria-label={t(title, { count: rooms.length || 0 })}
                  variant="calloutStrong"
                  color="mainText1">
                  <TitleContainer>
                    {icon}
                    <TitleWrapper>{t(title, { count: rooms.length || 0 })}</TitleWrapper>
                  </TitleContainer>
                </Text>
              </Block>
            )}
            <RoomsContent
              rooms={groupedRooms[groupKey as RoomType]}
              areRoomsSelectable={areRoomsSelectable}
              onRoomSelect={onRoomSelect}
              onRoomDetailsSelect={onRoomDetailsSelect}
              totalNights={totalNights}
            />
          </RootContainer>
        )
      })}
    </>
  )
}
