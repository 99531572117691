import { useCallback, useState, useEffect } from 'react'
import { useViewState } from '@fiji/hooks/use-view-state'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { Props } from './types'

const INPUT_DISALLOWED_CHARACTERS_PATTERN = /[`~!@#$%^&*_+=[\]{}|;:"<>?]/gi

export function useEditTripNameDialog({ value, onClose, onTripNameUpdate, isOpen }: Props) {
  const [inputValue, setInputValue] = useState(value)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { onChangeViewState, viewState, onClose: onCloseViewStateModal } = useViewState()

  const onNameUpdate = async (name: string) => {
    try {
      onChangeViewState('saving')
      await onTripNameUpdate(name)
      onChangeViewState('saved')
      await delayForSuccess()
      onChangeViewState('hidden')
      onClose()
    } catch {
      onChangeViewState('error')
    }
  }

  const onSubmit = async () => {
    setIsDisabled(true)
    setSubmitted(true)
    await onNameUpdate(inputValue)
    setSubmitted(false)
  }

  const initialInputValue = value

  const onChange = useCallback(
    (value: string) => {
      setIsDisabled(false)
      const characters = value.replace(INPUT_DISALLOWED_CHARACTERS_PATTERN, '')
      if (characters === initialInputValue) {
        setIsDisabled(true)
      }
      setInputValue(characters)
    },
    [initialInputValue],
  )

  useEffect(() => {
    setInputValue(value)
    setIsDisabled(true)
  }, [value, isOpen])

  const hasSubmitFailed = viewState === 'error'
  const isLoading = viewState === 'saving'
  const isDone = isSubmitted && !hasSubmitFailed && !isLoading

  return {
    inputValue,
    onChange,
    onCloseViewStateModal,
    onSubmit,
    isLoading,
    viewState,
    hasSubmitFailed,
    isDone,
    isDisabled,
  }
}
