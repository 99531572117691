import { Action, Inject } from '@etta/di'
import { AddExpenseCodeMemoService } from '@etta/modules/ride-hail/interface/services/add-expense-code-memo.service'

@Action()
export class AddExpenseCodeMemoActions {
  constructor(
    @Inject()
    private readonly addExpenseCodeMemoService: AddExpenseCodeMemoService,
  ) {}

  handleBack() {
    this.addExpenseCodeMemoService.goBack()
  }

  handleContinue() {
    this.addExpenseCodeMemoService.navigateToReview()
  }

  handleMemoValueChanged(value: string | null) {
    this.addExpenseCodeMemoService.handleMemoValueChanged(value)
  }
}
