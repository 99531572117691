import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { OverlappingTrip } from '@fiji/graphql/types'
import { useTripDuplicate } from '../../../use-trip-duplicate'
import { DuplicateTripModal } from './duplicate-trip-modal'
import {
  DuplicateContainer,
  CollapseButton,
  DatesLoader,
  CollapsedContainer,
} from './duplicate-list-styled'

type Props = {
  overlappingTrip: OverlappingTrip
}

export function Duplicate({ overlappingTrip }: Props) {
  const { isLoading, formattedDate, trip } = useTripDuplicate({ overlappingTrip })
  const duplicateSegmentToggle = useTogglePopup()

  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'
  return (
    <>
      <DuplicateContainer>
        <CollapsedContainer>
          <Text variant="title3" color="mainText">
            {overlappingTrip.name}
          </Text>
          {isLoading ? (
            <DatesLoader />
          ) : (
            <Block paddingTop={6} paddingBottom={6}>
              <Text variant="captionStrongCaps" color="bodyText">
                {formattedDate}
              </Text>
            </Block>
          )}
          {isLoading ? (
            <DatesLoader />
          ) : (
            <CollapseButton
              variant="text"
              color="primary"
              onClick={duplicateSegmentToggle.handleOpen}>
              {t(`${i18Base}ViewBookedTrip`)}
            </CollapseButton>
          )}
        </CollapsedContainer>
      </DuplicateContainer>
      {trip && (
        <Modal
          isVisible={duplicateSegmentToggle.isOpen}
          handleModalVisibility={duplicateSegmentToggle.handleClose}
          withMaxHeight>
          <DuplicateTripModal
            overlappingTrip={overlappingTrip}
            formattedDate={formattedDate}
            trip={trip}
            handleClose={duplicateSegmentToggle.handleClose}
          />
        </Modal>
      )}
    </>
  )
}
