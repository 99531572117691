import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  position: relative;
`

export const StyledBlock = styled(Block)<{ isCardSelected?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 8px 4px;

  ${(props) =>
    props.isCardSelected
      ? css`
          border: 1px solid ${(props) => props.theme.colors.borderLight};
          border-radius: 8px;
        `
      : css`
          border: 1px solid;
          border-color: transparent;
        `}
`
