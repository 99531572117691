import i18n from '@fiji/i18n'
import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'

export function getHeaderTitle(segments: SearchRailLegSubSegmentEntity[]) {
  const originCity = segments[0].origin.name
  const destinationCity = segments[segments.length - 1].destination.name

  return `${i18n.t('RailDetails.TrainFromTo', {
    origin: originCity,
    destination: destinationCity,
  })}`
}
