import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { HotelInfoList } from '@etta/components/hotel-info-list/hotel-info-list'
import { HotelImage } from '@etta/components/hotel-image/hotel-image'
import type { HotelCardLayoutProps } from '../types'
import { useHotelCard } from '../use-hotel-card'
import { Footer } from './footer'
import { HotelTitle, Header, ImageWrapper } from './hotel-card-mobile-styled'
import { HotelSustainabilityDeviation } from './hotel-sustainability-deviation'

const i18nBase = 'SearchResults.Hotel'

export function HotelCardMobile({
  hotel,
  isHotelLoading,
  isTripAdvisorReviewsEnabled,
  hotelEmission,
}: HotelCardLayoutProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const {
    chainCode,
    logoPhotoUrl,
    isPreferred,
    photos,
    name,
    starRating,
    medianReviewRating,
    distance,
    preference,
    isEcoFriendly,
  } = hotel

  const { isOutOfPolicy, isAllRoomsOutOfPolicy, firstRoom } = useHotelCard({ hotel })

  return (
    <>
      <ImageWrapper>
        <HotelImage
          photos={photos}
          logoPhotoUrl={logoPhotoUrl}
          chainCode={chainCode}
          isHotelLoading={isHotelLoading}
        />
      </ImageWrapper>
      <Header>
        <HotelTitle aria-label={t(`${i18nBase}.HotelNameAriaLabel`, { hotelName: name })}>
          {name}
        </HotelTitle>
        <HotelInfoList
          starRating={starRating}
          isTripAdvisorReviewsEnabled={isTripAdvisorReviewsEnabled}
          medianReviewRating={medianReviewRating}
          distance={distance}
          isPreferred={isPreferred}
          isOutOfPolicy={isOutOfPolicy}
          isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
          preference={preference}
        />
        {featureFlags.isSustainabilityEnabled && (
          <HotelSustainabilityDeviation {...hotelEmission} isEcoFriendly={isEcoFriendly} />
        )}
      </Header>
      <Footer
        firstRoom={firstRoom}
        isPreferred={isPreferred}
        isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
        isHotelLoading={isHotelLoading}
      />
    </>
  )
}
