import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { useCarRentalDetailsEcoCheckModalQuestion } from '../../use-car-retal-details-eco-check-modal-question'
import {
  EmisisonDetailsHeader,
  EmisisonDetailsDescription,
  EmisisonDetails,
  LearnMoreLinkWrapper,
} from '../car-rental-details-eco-check-modal-desktop-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function CarRentalDetailsEcoCheckModalDesktopLowerBody({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const { question, answer } = useCarRentalDetailsEcoCheckModalQuestion({ deviationLevel })

  return (
    <>
      <EmisisonDetails>
        <EmisisonDetailsHeader>{question}</EmisisonDetailsHeader>
        <EmisisonDetailsDescription>{answer}</EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.HowDoWeEstimateCarRentalQuestion')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.HowDoWeEstimateCarRentalAnswer')}
          <LearnMoreLinkWrapper>
            <Link
              href="https://www.thrustcarbon.com/policies/methodology"
              target="_blank"
              size="small">
              {t('Emissions.DetailsModal.LearnMore')}
            </Link>
          </LearnMoreLinkWrapper>
        </EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHeader')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.OtherWaysToReduceEmissionsFlightDescription')}
        </EmisisonDetailsDescription>
      </EmisisonDetails>
    </>
  )
}
