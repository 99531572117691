import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { DynamicSiteMessagesMobile } from './mobile'
import { DynamicSiteMessagesDesktop } from './desktop'

export function DynamicSiteMessagesLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<DynamicSiteMessagesMobile {...props} />}
      desktopSlot={<DynamicSiteMessagesDesktop {...props} />}
    />
  )
}
