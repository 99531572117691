import styled from 'styled-components'
import { UnstyledButton, linkNormal } from '@fiji/style'

export const SoldOutContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background1};
  padding: 56px 77px;
`

export const LinkContainer = styled(UnstyledButton)`
  ${linkNormal};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.mainText1};
  margin: 0px auto;
`
