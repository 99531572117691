import { DeviceClass } from '@fiji/graphql/hooks'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'

type Args = {
  pickUpDateTime: string
  dropOffDateTime: string
}

export function useDates({ pickUpDateTime, dropOffDateTime }: Args) {
  const deviceClass =
    screenMatcher.getScreenType() === ScreenType.Desktop ? DeviceClass.Desktop : DeviceClass.Mobile

  const datesFormat = deviceClass === DeviceClass.Desktop ? 'E, MMM dd' : 'MMM dd'
  const mobileDatesLabel =
    pickUpDateTime && dropOffDateTime
      ? `${dateFormat(new Date(deleteTimezone(pickUpDateTime)), datesFormat)} - ${dateFormat(
          new Date(deleteTimezone(dropOffDateTime)!),
          datesFormat,
        )}`
      : ''
  const desktopDatesLabel =
    pickUpDateTime && dropOffDateTime
      ? `${dateFormat(new Date(deleteTimezone(pickUpDateTime)), 'EEE, MMM dd')} - ${dateFormat(
          new Date(deleteTimezone(dropOffDateTime)!),
          'EEE, MMM dd',
        )}`
      : ''

  return {
    mobileDatesLabel,
    desktopDatesLabel,
  }
}
