import type { Rate } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { usePrice } from './use-price'

type Props = {
  rate?: Rate | null
  taxes?: Rate | null
  total?: Rate | null
  transactionFees?: Rate | null
}

export function Price({ rate, taxes, total, transactionFees }: Props) {
  const { priceDetailsList, formattedTotal } = usePrice({
    rate,
    taxes,
    total,
    transactionFees,
  })

  return <PriceDetails priceDetailsList={priceDetailsList} totalCost={formattedTotal} />
}
