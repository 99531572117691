import { useTranslation } from 'react-i18next'
import { CopyableValue } from '@etta/ui/copyable-value'
import { Separator } from '@etta/ui/separator'
import { ContactOptionsContainer, ContactsList, ContactsListItem } from '../support-page-components'

type Props = {
  title: string
  contacts: {
    label?: string | null
    value: string
  }[]
  isPhoneContacts?: boolean
}

export function SupportContactOptions({ contacts, isPhoneContacts }: Props) {
  const { t } = useTranslation()

  return (
    <ContactOptionsContainer>
      <ContactsList>
        {contacts.map((contact, i) => {
          const isLastElement = !isPhoneContacts && i === contacts.length - 1

          return (
            <ContactsListItem key={i}>
              <CopyableValue
                title={
                  contact.label
                    ? isPhoneContacts
                      ? t('TripPlanner.Support.PhoneOptions.Label', { label: contact.label })
                      : contact.label
                    : ''
                }
                bodyIconName={isPhoneContacts ? 'phonePWA' : null}
                value={contact.value}
                hrefContent={(isPhoneContacts ? `tel:` : `mailto:`) + contact.value}
                iconColor="primary"
              />
              {!isLastElement && <Separator lineType="dashed" />}
            </ContactsListItem>
          )
        })}
      </ContactsList>
    </ContactOptionsContainer>
  )
}
