import { isFuture, isPast, isToday } from 'date-fns'
import i18n from '@fiji/i18n'
import { Store } from '@etta/di'
import { Validator } from '@etta/interface/services/validator'
import { Toggle } from '@etta/interface/services/toggle'
import { OptionViewTypes } from '@etta/ui/drop-down-list'
import { VisaTypeOptions } from '../../core/entities/visa.entity'

export type PassportInput = {
  id?: number
  number: string
  issueCountry: string
  country: string
  issueDate: string
  expirationDate: string
  preExpirationAlert?: string
  unmaskedNumber?: string
}

export type VisaInput = {
  id?: string
  number: string
  country: string
  expirationDate: string
  visaType: string
  preExpirationAlert?: string
}

export type NationalIdCardInput = {
  id?: string
  number: string
  country: string
  issueDate: string
  expirationDate: string
  preExpirationAlert?: string
}

export type KnownTravelerNumberInput = {
  id?: string
  number: string
  country: string
  expirationDate: string
  preExpirationAlert?: string
}

export type RedressNumberInput = {
  id?: string
  number: string
  country: string
}

const i18nBase = 'Settings.TravelDocuments'
const i18BaseErrors = 'Settings.Errors'

const defaultAdditionalDocumentsPreferences = {
  redressNumbers: [],
  knownTravelerNumbers: [],
}

@Store()
export class TravelDocumentsStore {
  isSaveButtonDisabled = true
  toggle = new Toggle()
  removeToggle = new Toggle()

  passportActionToggle = new Toggle()
  visaActionToggle = new Toggle()
  nationalIdActionToggle = new Toggle()
  redressNumberActionToggle = new Toggle()
  knownTravelerNumberActionToggle = new Toggle()

  pickerTogglers = {
    passportExpiration: new Toggle(),
    passportIssue: new Toggle(),
    visaExpiration: new Toggle(),
    nationalIdCardExpiration: new Toggle(),
    nationalIdCardIssue: new Toggle(),
    knownTravelerNumberExpiration: new Toggle(),
  }

  additionalDocumentsPreferences = { ...defaultAdditionalDocumentsPreferences }

  isPassportNumberTouched = false
  setIsPassportNumberTouched(value: boolean) {
    this.isPassportNumberTouched = value
  }

  isVisaNumberTouched = false
  setIsVisaNumberTouched(value: boolean) {
    this.isVisaNumberTouched = value
  }

  isNationalIdNumberTouched = false
  setIsNationalIdNumberTouched(value: boolean) {
    this.isNationalIdNumberTouched = value
  }

  isRedressNumberTouched = false
  setIsRedressNumberTouched(value: boolean) {
    this.isRedressNumberTouched = value
  }

  isKnownTravelerNumberTouched = false
  setIsKnownTravelerNumberTouched(value: boolean) {
    this.isKnownTravelerNumberTouched = value
  }

  passportValidator = new Validator({
    id: Validator.scheme.mixed().optional().default(undefined),
    number: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Passport.Number`),
        }),
      )
      .default(''),
    issueCountry: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Passport.IssuingCountry`),
        }),
      )
      .default(''),
    country: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Passport.Country`),
        }),
      )
      .default(''),
    issueDate: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Passport.IssueDate`),
        }),
      )
      .test(
        'invalid-issue-date',
        i18n.t(`${i18nBase}.Errors.InvalidIssueDate`),
        (value) => !value || isToday(new Date(value)) || isPast(new Date(value)),
      )
      .default(''),
    expirationDate: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Passport.ExpirationDate`),
        }),
      )
      .test(
        'invalid-expiration-date',
        i18n.t(`${i18nBase}.Errors.InvalidExpirationDate`),
        (value) => !value || isFuture(new Date(value)),
      )
      .default(''),
    preExpirationAlert: Validator.scheme.string().default(''),
    unmaskedNumber: Validator.scheme.string().default(''),
  })

  visaValidator = new Validator({
    id: Validator.scheme.mixed().optional().default(undefined),
    number: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Visa.Number`),
        }),
      )
      .default(''),
    country: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Visa.Country`),
        }),
      )
      .default(''),
    expirationDate: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.Visa.ExpirationDate`),
        }),
      )
      .test(
        'invalid-expiration-date',
        i18n.t(`${i18nBase}.Errors.InvalidExpirationDate`),
        (value) => !value || isFuture(new Date(value)),
      )
      .default(''),
    visaType: Validator.scheme.string().default(''),
    preExpirationAlert: Validator.scheme.string().default(''),
  })

  nationalIdCardValidator = new Validator({
    id: Validator.scheme.mixed().optional().default(undefined),
    number: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.NationalId.Number`),
        }),
      )
      .default(''),
    country: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.NationalId.Country`),
        }),
      )
      .default(''),
    issueDate: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.NationalId.IssueDate`),
        }),
      )
      .test(
        'invalid-issue-date',
        i18n.t(`${i18nBase}.Errors.InvalidIssueDate`),
        (value) => !value || isToday(new Date(value)) || isPast(new Date(value)),
      )
      .default(''),
    expirationDate: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.NationalId.ExpirationDate`),
        }),
      )
      .test(
        'invalid-expiration-date',
        i18n.t(`${i18nBase}.Errors.InvalidExpirationDate`),
        (value) => !value || isFuture(new Date(value)),
      )
      .default(''),
    preExpirationAlert: Validator.scheme.string().default(''),
  })

  knownTravelerNumberValidator = new Validator({
    id: Validator.scheme.mixed().optional().default(undefined),
    number: Validator.scheme
      .string()
      .matches(/^[a-zA-Z0-9]*$/, i18n.t(`${i18nBase}.Errors.InvalidKnownTravelerNumber`))
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.KnownTravelerNumber.Number`),
        }),
      )
      .default(''),
    country: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.KnownTravelerNumber.Country`),
        }),
      )
      .default(''),
    expirationDate: Validator.scheme
      .string()
      .test(
        'invalid-expiration-date',
        i18n.t(`${i18nBase}.Errors.InvalidExpirationDate`),
        (value) => !value || isFuture(new Date(value)),
      )
      .default(''),
    preExpirationAlert: Validator.scheme.string().default('PT0S'),
  })

  redressNumberValidator = new Validator({
    id: Validator.scheme.mixed().optional().default(undefined),
    number: Validator.scheme
      .string()
      .matches(/^[a-zA-Z0-9]*$/, i18n.t(`${i18nBase}.Errors.InvalidRedressNumber`))
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.RedressNumber.Number`),
        }),
      )
      .default(''),
    country: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldIsRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.RedressNumber.Country`),
        }),
      )
      .default(''),
  })

  setPassport(field: keyof PassportInput, value: string | number) {
    this.passportValidator.onFieldChange(field, value)
  }

  setRedressNumber(field: keyof RedressNumberInput, value: string | number) {
    this.redressNumberValidator.onFieldChange(field, value)
  }

  setKnownTravelerNumber(field: keyof KnownTravelerNumberInput, value: string | number) {
    this.knownTravelerNumberValidator.onFieldChange(field, value)
  }

  resetRedressNumber() {
    this.redressNumberValidator.reset()
  }

  resetKnownTravelerNumber() {
    this.knownTravelerNumberValidator.reset()
  }

  resetPassport() {
    this.passportValidator.reset()
  }

  get visaTypes() {
    return Object.keys(VisaTypeOptions).map((value) => ({
      label: i18n.t(`${i18nBase}.Visa.VisaTypeOptions.${value}`),
      value: VisaTypeOptions[value as keyof typeof VisaTypeOptions],
      viewType: OptionViewTypes.OptionWithCheckboxIcon,
    }))
  }

  setVisa(field: keyof VisaInput, value: string) {
    this.visaValidator.onFieldChange(field, value)
  }

  resetVisa() {
    this.visaValidator.reset()
  }

  setNationalIdCard(field: keyof NationalIdCardInput, value: string) {
    this.nationalIdCardValidator.onFieldChange(field, value)
  }

  resetNationalIdCard() {
    this.nationalIdCardValidator.reset()
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
