import { useMainPageContext } from '@etta/modules/user/interface/use-main-page.context'
import { useStep } from './use-step'
import { RequestLocation } from './request-location'
import { LocationEnabled, useLocationEnabledTranslation } from './location-enabled'
import { LocationDisabled, useLocationDisabledTranslation } from './location-disabled'
import { RequestNotifications, useRequestNotificationsTranslation } from './request-notifications'

export function Permissions() {
  const { mainPageActions } = useMainPageContext()
  const { step, changeStep } = useStep()
  const onNotificationProceed = () => mainPageActions.handleNavigate()
  const locationEnabledTranslation = useLocationEnabledTranslation()
  const locationDisabledTranslation = useLocationDisabledTranslation()
  const requestNotificationsTranslation = useRequestNotificationsTranslation()

  switch (step) {
    case 'location':
      return (
        <RequestLocation
          onSuccess={() => changeStep('notifications')}
          onFail={() => changeStep('notifications')}
          onSkip={() => changeStep('notifications')}
          nextTranslation={requestNotificationsTranslation}
        />
      )
    case 'locationEnabled':
      return (
        <LocationEnabled
          onSuccess={() => changeStep('notifications')}
          nextTranslation={requestNotificationsTranslation}
        />
      )
    case 'locationDisabled':
      return (
        <LocationDisabled
          onSuccess={() => changeStep('notifications')}
          onSkip={() => changeStep('notifications')}
          nextTranslation={requestNotificationsTranslation}
        />
      )
    case 'notifications':
      return (
        <RequestNotifications
          onSuccess={onNotificationProceed}
          onSkip={onNotificationProceed}
          onFail={onNotificationProceed}
          onBack={({ isGranted }) => {
            changeStep(isGranted ? 'locationEnabled' : 'locationDisabled')
          }}
          getPreviousTranslation={({ isGranted }) =>
            isGranted ? locationEnabledTranslation : locationDisabledTranslation
          }
        />
      )
    default:
      return null
  }
}
