import type { IconNames, IconColorPalette, IconSizes } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Container, IconWrapper } from './list-item.style'

type Props = {
  title: React.ReactNode
  footNote?: React.ReactNode
  icon?: IconNames
  onIconClick?: React.MouseEventHandler<HTMLButtonElement>
  iconColor?: IconColorPalette
  iconSize?: IconSizes
}

export function ListItem({ title, footNote, icon, onIconClick, iconColor, iconSize }: Props) {
  return (
    <Container>
      <div>
        {title}
        {footNote && <div>{footNote}</div>}
      </div>
      {icon && (
        <IconWrapper>
          <IconButton icon={icon} onClick={onIconClick} size={iconSize} color={iconColor} />
        </IconWrapper>
      )}
    </Container>
  )
}
