import { useHistory, useLocation } from 'react-router-dom'
import type * as H from 'history'
import { useHistoryContext } from '@etta/modules/app/interface/use-history-context'

type Props = {
  history?: H.History
}

export function HistoryAdapter({ history }: Props) {
  const defaultHistory = useHistory()
  const usedHistory = history || defaultHistory
  const { search, pathname, state } = useLocation()
  const { historyStore, routerHistory } = useHistoryContext()

  routerHistory.setHistory(usedHistory)

  historyStore.updateState(search, pathname, state)

  return null
}
