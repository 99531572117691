import styled from 'styled-components'
import { subHeadMedium, subHeadStrong } from '@fiji/style'

export const TripCostSection = styled.button`
  display: block;
  width: 100%;
  border: none;
  background: none;
  padding: 0 16px;
  margin: 24px 0 20px;
`

export const Title = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${subHeadStrong}
`
export const Segment = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${(p) => p.theme.colors.mainText};
`

export const IconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Text = styled.div`
  line-height: 20px;
  margin-left: 10px;
  ${subHeadStrong};
`

export const Cost = styled.div`
  line-height: 20px;
  ${subHeadMedium}
`

export const CanceledMessageContainer = styled.div`
  text-align: left;
`
