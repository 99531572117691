import type { LayoutProps } from '../types'
import { flightAnimation, carRentalAnimation, hotelAnimation, trainAnimation } from './assets'
import {
  AnimationsWrapper,
  StyledLottieAnimation,
  BookingText,
} from './booking-animation-container-mobile-styled'

export function BookingAnimationContainerMobile({
  animationLabel,
  setFlightAnimation,
  setCarRentalAnimation,
  setHotelAnimation,
  setTrainAnimation,
}: LayoutProps) {
  return (
    <AnimationsWrapper>
      <StyledLottieAnimation ref={setFlightAnimation} zIndex={1} src={flightAnimation} />
      <StyledLottieAnimation ref={setHotelAnimation} zIndex={1} src={hotelAnimation} />
      <StyledLottieAnimation ref={setCarRentalAnimation} zIndex={1} src={carRentalAnimation} />
      <StyledLottieAnimation ref={setTrainAnimation} zIndex={1} src={trainAnimation} />
      {animationLabel && <BookingText>{animationLabel}</BookingText>}
    </AnimationsWrapper>
  )
}
