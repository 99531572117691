import { useCallback, useState } from 'react'

export function useVisibility() {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleVisibility = useCallback(() => {
    setIsVisible((prevState) => !prevState)
  }, [])

  const hide = useCallback(() => {
    setIsVisible(false)
  }, [])

  return {
    isVisible,
    toggleVisibility,
    hide,
  }
}
