import { Block } from '@etta/ui/block'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import type { LayoutProps } from '../types'
import { Description } from './safety-check-header-mobile-styled'

export function SafetyCheckHeaderMobile({ description, poweredByLabel }: LayoutProps) {
  return (
    <div aria-label={`${description} ${poweredByLabel}`}>
      <SafetyCheckTitle />
      <Block marginTop={4}>
        <Description>{description}</Description>
        <Description>{poweredByLabel}</Description>
      </Block>
    </div>
  )
}
