import { useSelector } from 'react-redux'
import { flightDisplayConfigurationSelector } from '@fiji/store/display-configuration/selectors'
import type { CabinClassType } from '@fiji/types'
import { SeatmapCabinClass } from '@fiji/graphql/types'
import { cabinClasses } from '@fiji/constants'

export function useSeatmapCabinClasss() {
  const {
    isBusinessClassEnabled,
    isEconomyClassEnabled,
    isFirstClassEnabled,
    isPremiumEconomyClassEnabled,
  } = useSelector(flightDisplayConfigurationSelector)

  const cabinClassesConfig = {
    [SeatmapCabinClass.Business]: isBusinessClassEnabled,
    [SeatmapCabinClass.Coach]: isEconomyClassEnabled,
    [SeatmapCabinClass.First]: isFirstClassEnabled,
    [SeatmapCabinClass.PremiumCoach]: isPremiumEconomyClassEnabled,
  }

  const cabinClassesOptionsKeys = Object.keys(cabinClasses) as Array<SeatmapCabinClass>

  const cabinClassesOptions = cabinClassesOptionsKeys.reduce(
    (acc: Array<CabinClassType>, key: SeatmapCabinClass) => {
      const el = cabinClasses[key]
      if (!cabinClassesConfig[key]) {
        return acc
      }
      return [...acc, el]
    },
    [],
  )

  return { cabinClassesOptions }
}
