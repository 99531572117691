import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 10px;
`

export const Header = styled.header``

export const Main = styled.main`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borderLight};
`

export const LeftSection = styled.div`
  width: 45%;
`

export const RightSection = styled.div`
  width: 45%;
`
