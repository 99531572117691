import { useTranslation } from 'react-i18next'
import type { Amenity } from '@fiji/graphql/types'
import { useAmenities } from './use-amenities'
import { AmenityItem, MoreText } from './amenities-styled'

type Props = {
  amenities: Amenity[]
  exclusiveAmenities?: Amenity[] | null
}

const i18nBase = 'SearchResults.Hotel'

export function Amenities({ exclusiveAmenities, amenities }: Props) {
  const { t } = useTranslation()
  const { slicedAmenityNames, isMore, moreCount } = useAmenities({ amenities, exclusiveAmenities })

  return (
    <>
      {slicedAmenityNames.map((amenity) => {
        return <AmenityItem key={amenity}>{amenity}</AmenityItem>
      })}
      {isMore && (
        <MoreText variant="footnoteMedium" color="bodyText">
          {t(`${i18nBase}.MoreAmenities`, { count: moreCount })}
        </MoreText>
      )}
    </>
  )
}
