import { useTranslation } from 'react-i18next'
import { Badge } from '@etta/ui/badge'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Badges, Container } from './ndc-fare-acknowledgment-styled'

const i18nBase = 'NDCFareAcknowledgment'
export const NDCFareAcknowledgment = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Block marginBottom={8}>
        <Text variant="footnoteStrong">{t(i18nBase + '.Title')}</Text>
      </Block>
      <Block marginBottom={12}>
        <Text variant="footnoteMedium" color="bodyText1">
          {t(i18nBase + '.Description')}
        </Text>
      </Block>
      <Badges>
        <Badge
          variant="creditCard"
          iconSize="small"
          color="bodyText1"
          label={t(i18nBase + '.CreditCard')}
        />
        <Badge
          variant="refundable"
          iconSize="small"
          color="bodyText1"
          label={t(i18nBase + '.Refundable')}
        />
        <Badge
          variant="eticket"
          iconSize="small"
          color="bodyText1"
          label={t(i18nBase + '.ETicket')}
        />
      </Badges>
    </Container>
  )
}
