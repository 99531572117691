import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace CarRentalErrors {
  export const LocationsResponse = createErrorClass('Get car rental locations failed')
  export const UnexpectedErrorLocations = createErrorClass('Get car rental locations failed')
  export const CarRentalsResponse = createErrorClass('Get car rentals failed')
  export const UnexpectedErrorCarRentals = createErrorClass('Get car rentals failed')
  export const CarRentalSearchResponse = createErrorClass('Create Car rental search failed')
  export const UnexpectedErrorCarRentalSearch = createErrorClass('Create Car rental search failed')
  export const CarDetailsResponse = createErrorClass('Get car details failed')
  export const UnexpectedErrorCarDetails = createErrorClass('Get car details failed')
  export const AbortControllerError = createErrorClass('AbortControllerError')
  export const RestrictedCountryError = createErrorClass<{ message: string }>(
    'Restricted country error',
  )
}

export type CarRentalErrorsInstances = InstanceType<
  typeof CarRentalErrors[keyof typeof CarRentalErrors]
>

export function isRestrictedCountryError(
  err: CarRentalErrorsInstances,
): err is InstanceType<typeof CarRentalErrors.RestrictedCountryError> {
  return err instanceof CarRentalErrors.RestrictedCountryError
}
