import type { Maybe, TrainStop } from '@fiji/graphql/hooks'
import { RailRouteGrid } from './rail-route-grid'
import { MainStationRow } from './main-station-row'
import { ShowMoreStations } from './show-more-stations'

export type TravelRouteProps = {
  originTime: string
  originStation?: string | null
  travelTime: string
  destinationTime: string
  destinationStation?: string | null
  stops?: Maybe<Pick<TrainStop, 'stationName' | 'departingTime'>[]>
  isRtp: boolean
}

export function RailRouteDetails({
  originTime,
  originStation,
  travelTime,
  destinationTime,
  destinationStation,
  stops,
  isRtp,
}: TravelRouteProps) {
  return (
    <RailRouteGrid
      isRtp={isRtp}
      originStationSlot={
        <MainStationRow isRtp={isRtp} stationTime={originTime} stationName={originStation} />
      }
      destinationStationSlot={
        <MainStationRow
          isRtp={isRtp}
          stationTime={destinationTime}
          stationName={destinationStation}
        />
      }
      viewHideStationsSlot={
        <ShowMoreStations isRtp={isRtp} travelTime={travelTime} stops={stops} />
      }
    />
  )
}
