import { useCallback, useState } from 'react'
import type { SafetyCheckFlight } from '@fiji/types'
import { getUniqFlightSafetyCheckIconNames } from '@fiji/utils/flight-safety-check/get-flight-safety-checks-icon-names'

type Args = {
  flightSafetyChecks?: SafetyCheckFlight[]
  refetchSafetyChecks?: () => void
}

export function useFlightSafetyCheck({ flightSafetyChecks, refetchSafetyChecks }: Args) {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleSafetyChecksExpand = () => {
    setIsExpanded((state) => !state)
  }

  // this is not used as per VIVA-3050, once it's confirmed we do not bring back retry we can remove fare attributes refetch function too
  const handleSafetyChecksReload = useCallback(() => {
    refetchSafetyChecks && refetchSafetyChecks()
  }, [refetchSafetyChecks])

  const uniqSafetyCheckIconNames = flightSafetyChecks
    ? getUniqFlightSafetyCheckIconNames(flightSafetyChecks)
    : []

  const isSafetyChecksHeadlines =
    flightSafetyChecks && !!flightSafetyChecks.find((item) => !!item.headLines.length)

  return {
    isSafetyChecksExpanded: isExpanded,
    isSafetyChecksHeadlines,
    uniqSafetyCheckIconNames,
    handleSafetyChecksExpand,
    handleSafetyChecksReload,
  }
}
