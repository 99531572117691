import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { Checkbox } from '@etta/ui/checkbox'
import { BrandCheckbox } from '../brand-checkbox'
import {
  Content,
  ControlButton,
  Header,
  NestedHeader,
  Options,
  SelectedBrandsCount,
  Wrapper,
} from './super-brand-select-styled'
import { useSuperBrandSelect } from './use-super-brand-select'

type Props = {
  isSuperBrandExpanded?: boolean
  superBrand: SuperBrandFilter
  selectedIdsMap: Set<string>
  onSelect: (idsMap: Set<string>) => void
  isNewLayout: boolean
}

export function SuperBrandSelect({
  selectedIdsMap,
  onSelect,
  superBrand,
  isSuperBrandExpanded,
  isNewLayout,
}: Props) {
  const {
    superBrandId,
    isAllSelected,
    isMixedSelected,
    brandsToggle,
    selectedBrandsLength,
    onSuperBrandChange,
    onBrandChange,
    handleKeyDown,
  } = useSuperBrandSelect({
    isSuperBrandExpanded,
    selectedIdsMap,
    onSelect,
    superBrand,
  })

  return (
    <Wrapper hasNewLayout={isNewLayout}>
      <Header>
        <NestedHeader>
          <Checkbox
            data-tracking-id={`hotel-super-brand-${superBrandId}`}
            checked={isAllSelected}
            label={superBrand.brandDisplayName}
            value={superBrandId}
            onChange={onSuperBrandChange}
            mixed={isMixedSelected}
          />
          <Options>
            {!!selectedBrandsLength && (
              <SelectedBrandsCount variant="captionStrongCaps" color="mainText2" align="center">
                {selectedBrandsLength}
              </SelectedBrandsCount>
            )}
            <ControlButton
              tabIndex={0}
              onKeyDown={handleKeyDown}
              isOpen={brandsToggle.isOpen}
              onClick={brandsToggle.handleToggle}
            />
          </Options>
        </NestedHeader>
      </Header>
      <Content isExpanded={brandsToggle.isOpen}>
        {superBrand.brands.map((brand: BrandFilter) => {
          const isCheckedItem = selectedIdsMap.has(brand.brandId)
          return (
            <BrandCheckbox
              key={brand.brandId}
              brand={brand}
              isChecked={isCheckedItem}
              onChange={(isChecked) => onBrandChange(isChecked, brand)}
            />
          )
        })}
      </Content>
    </Wrapper>
  )
}
