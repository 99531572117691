import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'

export function ProfileDesktop({ headerSlot, contentSlot }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <ScreenDesktop.Content backgroundColor="background">
        {headerSlot}

        {contentSlot}
      </ScreenDesktop.Content>
    </ScreenDesktop>
  )
}
