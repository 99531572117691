import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { EntrancePoint } from '@fiji/types'

const slice = createSlice({
  name: 'post-purchase-status',
  initialState: null as EntrancePoint,
  reducers: {
    updatePostPurchaseEntrance(_, action: PayloadAction<EntrancePoint>) {
      return action.payload
    },
  },
})

export const postPurchaseEntranceReducer = slice.reducer
export const { updatePostPurchaseEntrance } = slice.actions
