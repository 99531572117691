import { formatTravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import type { AdaptedRailSegment } from '../../types'
import { getLayover } from '../../get-layover'

export function getAdaptedSegments(
  segments: SearchRailLegSubSegmentEntity[],
): AdaptedRailSegment[] {
  return segments.map<AdaptedRailSegment>((segment, index, array) => {
    const nextSegment = array[index + 1]

    return {
      id: segment.id,
      carrierCode: segment.carrier?.id || '',
      carrierName: segment.carrier?.name || '',
      departure: formatTravelRailLocation(segment.origin, segment.departureDate.toISOString()),
      arrival: formatTravelRailLocation(segment.destination, segment.arrivalDate.toISOString()),
      railLogo: segment.carrier?.logoImageUrl ?? null,
      trainId: `${segment.trainId}`,
      train: segment.train?.name ?? null,
      railTime: segment.railTime,
      amenities: segment?.amenities ?? [],
      operatedBy: segment?.codeshareCarrier?.name ?? null,
      layover: getLayover(
        segment.arrivalDate.toISOString(), // TODO: FRI-111 check date string format
        nextSegment?.departureDate.toISOString(), // TODO: FRI-111 check date string format
      ),
      isSeatMapAvailable: segment.isSeatMapAvailable,
      isPreferredAny: segment.isPreferredAny,
      stops: segment.stops?.map((stop) => ({
        ...stop,
        departingTime: stop.departingTime?.toISOString() || '-',
      })),
      changeDuration: segment.changeDuration,
      transportationMode: segment.transportationMode || RailTransportationModeEnum.Unspecified,
    }
  })
}
