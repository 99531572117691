import styled from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 16px;
  border-radius: 14px;
`

export const ContentBody = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: 16px 16px 18.5px;
`

export const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 13px 16px;
  align-items: center;
`

export const WrapperImage = styled.div`
  height: 54px;
  min-width: 54px;
  margin-right: 20px;
  background-color: #f4f4f4;
  border-radius: 3px;
  display: grid;
  place-items: center;
`

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
`

export const WrapperNameContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const WrapperTime = styled.div`
  display: flex;
  min-width: 0;
`

export const WrapperDeparture = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 3px 0;
`
