import styled from 'styled-components'

import type { HeaderColors } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { DetailsContainer } from '../../../trip-hotel-styled'

export const ContentWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  background-color: ${(props) => props.theme.colors.background};
`

export const HotelDetailsPhotosWrapper = styled.div`
  margin: 30px 0 20px 0;
`

export const ModalHeader = styled.div<{ color: HeaderColors }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 20px 14px 32px;
  background-color: ${(props) => props.theme.colors[props.color]};
`

export const ModalHeaderContentWrapper = styled(Block)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  color: ${(props) => props.theme.colors.mainText2};
`

export const HotelPhotosContainer = styled(DetailsContainer)`
  padding: 16px 32px 48px 32px;
`

export const TravelerWrapper = styled.div`
  border-radius: 14px;
  margin-top: -28px;
`
