import { useState } from 'react'
import { Block } from '@etta/ui/block'
import { HotelDetailsPhotos } from '@etta/components/hotel-details-photos/hotel-details-photos'
import { ScrollByContent } from '@etta/components/scroll-by-content'
import { ScrollByContentContext } from '@etta/components/scroll-by-content/scroll-by-content-context'
import type { LayoutProps } from '../types'
import { BlockWrapper } from './block-wrapper'
import { HotelDetailsBody, HotelDetailsBodyWhite } from './hotel-details-layout-desktop-styled'
import { HotelDetailsHeaderDesktop } from './hotel-details-header'
import { TabsBlock } from './tabs-block'

export function HotelDetailsLayoutDesktop({
  children,
  scrollContainerRef,
  hotelDetails,
  onClose,
  aboutSlot,
  amenitiesSlot,
  locationSlot,
  reviewsSlot,
  roomsSlot,
  safetyCheckSlot,
  photos,
  areHotelReviewsDisplayed,
  ecoCheckSlot,
}: LayoutProps) {
  const [activeTab, setActiveTab] = useState('about')
  return (
    <ScrollByContent
      scrollContainerRef={scrollContainerRef}
      onAnchorAppear={(name) => {
        setActiveTab(name)
      }}>
      <HotelDetailsHeaderDesktop
        hotelDetails={hotelDetails}
        onClose={onClose}
        tabsSlot={
          <ScrollByContentContext.Consumer>
            {(scrollContext) => {
              return (
                <TabsBlock
                  activeTab={activeTab}
                  areHotelReviewsDisplayed={areHotelReviewsDisplayed}
                  onTabChange={(name) => {
                    scrollContext.openAnchor(name)
                  }}
                />
              )
            }}
          </ScrollByContentContext.Consumer>
        }
      />
      <ScrollByContent.Anchor name="about" isZeroPoint />
      <HotelDetailsBodyWhite>
        <Block paddingHorizontal={32} paddingVertical={24}>
          <HotelDetailsPhotos hotelName={hotelDetails.name} photos={photos} />
        </Block>
        <Block paddingHorizontal={32}>{aboutSlot}</Block>
      </HotelDetailsBodyWhite>
      <HotelDetailsBody>
        <ScrollByContent.Anchor name="rooms" />
        <Block paddingHorizontal={16} paddingVertical={16}>
          {roomsSlot}
        </Block>
      </HotelDetailsBody>
      <HotelDetailsBodyWhite>
        <ScrollByContent.Anchor name="amenities" />
        {amenitiesSlot}
        <ScrollByContent.Anchor name="location" />
        <Block paddingHorizontal={32} paddingBottom={32}>
          {locationSlot}
        </Block>
        <Block paddingHorizontal={32} paddingBottom={32}>
          {ecoCheckSlot}
        </Block>
        <ScrollByContent.Anchor name="safety-check" />
        <BlockWrapper slot={safetyCheckSlot} />
        {areHotelReviewsDisplayed && (
          <>
            <ScrollByContent.Anchor name="reviews" />
            <BlockWrapper slot={reviewsSlot} />
          </>
        )}
        {children}
      </HotelDetailsBodyWhite>
    </ScrollByContent>
  )
}
