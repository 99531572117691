import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { Address } from '@fiji/graphql/types'
import { TimeDuration } from '@etta/components/time-duration'
import { LocationTypes } from '../location-types'
import { useLocation } from './use-location'
import { Container } from './location-styled'

type Props = {
  time?: string | null
  address?: Address | null
  indexOfStop?: number
  isDirect?: boolean | null
  type: LocationTypes
  duration?: string
}

const i18nBase = 'CarServiceDetails.PickUpAndDropOff.DriverInstructions'

function Caption({
  isDirect,
  type,
  indexOfStop,
  date,
}: Pick<Props, 'isDirect' | 'type' | 'indexOfStop'> & { date: string | null }) {
  const { t } = useTranslation()
  const textVariant = LocationTypes.Stop === type ? 'captionStrongCaps' : 'subHeadStrong'

  const directText = t(`${i18nBase}.DriveAsDirected`)

  const defaultText =
    LocationTypes.Stop === type ? t(`${i18nBase}.Stop`, { count: indexOfStop }) : date

  return (
    <Text variant={textVariant} color="mainText">
      {isDirect ? directText : defaultText}
    </Text>
  )
}

function Label({ type }: Pick<Props, 'type'>) {
  const { t } = useTranslation()
  if (LocationTypes.Stop === type) {
    return null
  }

  const getLabel = () => {
    switch (type) {
      case LocationTypes.PickUp:
        return t(`${i18nBase}.PickUp`)
      case LocationTypes.DropOff:
        return t(`${i18nBase}.DropOff`)
    }
  }

  return (
    <Block paddingLeft={15}>
      <Text variant="captionStrongCaps" color="bodyText1">
        {getLabel()}
      </Text>
    </Block>
  )
}

export function Location({ time, address, type, indexOfStop, isDirect, duration }: Props) {
  const { date, location } = useLocation({ time, address })

  return (
    <Block>
      {duration && type === LocationTypes.DropOff && (
        <Block marginLeft={16} marginBottom={10}>
          <TimeDuration duration={duration} />
        </Block>
      )}
      <Label type={type} />
      <Container type={type}>
        <Block marginVertical={4}>
          <Caption isDirect={isDirect} type={type} indexOfStop={indexOfStop} date={date} />
        </Block>
        {!isDirect && (
          <Text variant="footnoteMedium" color="bodyText">
            {location}
          </Text>
        )}
      </Container>
    </Block>
  )
}
