import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { useAppSelector } from '@fiji/store'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { MultiLayerCostAllocationContent } from './multi-layer-cost-allocation-content'
import { usePresearchMultiLayerCostAllocation } from './use-presearch-multi-layer-cost-allocation'

type Props = {
  type?: 'additional-info' | 'presearch'
  error?: string
  variant?: 'one-line' | 'default'
  onChange?(field: 'allocation'): (value: string) => void
}

export const PresearchMultiLayerCostAllocation = observer(
  ({ type, error, variant, onChange }: Props) => {
    const { t } = useTranslation()
    const i18nBase = 'PreSearchCostAllocation'
    const { onCostAllocationSubmit } = usePresearchMultiLayerCostAllocation({ onChange })

    const { costAllocationStore, costAllocationActions } = useCostAllocationContext()

    const { isPreBookCostAllocationEnabled } = useAppSelector(displayConfigurationSelector)

    useEffect(() => {
      if (isPreBookCostAllocationEnabled) {
        costAllocationActions.getTopLevelCostSegments()
      }
    }, [isPreBookCostAllocationEnabled, costAllocationActions])

    if (!isPreBookCostAllocationEnabled || costAllocationStore.topLevelCostSegments.length === 0) {
      return null
    }

    return (
      <>
        {costAllocationStore.topLevelCostSegments.map((segment) => (
          <MultiLayerCostAllocationContent
            key={segment.id.toString()}
            segmentId={segment.id}
            title={t(i18nBase + '.CostAllocation')}
            type={type}
            error={error}
            variant={variant}
            modal={costAllocationStore.getCostAllocationModalToggleById(segment.id)}
            label={costAllocationStore.getCostAllocationDisplayNameById(segment.id)}
            onSubmit={onCostAllocationSubmit(segment.id)}
          />
        ))}
      </>
    )
  },
)
