import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px dashed ${(p) => p.theme.colors.borderLight};
  display: flex;
  justify-content: space-between;
`

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: end;
`
