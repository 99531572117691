import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { CopyableWrapper } from '@etta/ui/copyable-value'
import { useScreenType } from '@fiji/modes'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import type { CheckInData } from '../use-trip-flight'
import { Container, Row, Cell, CellName, CellValue } from './traveler-styled'
import { CheckInList } from './check-in-list'
import { CancellationLabel } from './cancellation-label'

type Props = {
  travelerName: string
  pnrNumber: string
  isCheckInAvailable: boolean
  isTripCancelled: boolean
  cancellationDate?: string | null
  confirmationNumber: string
  checkInData: CheckInData
  isCompleted: boolean
  isFlightCancelled?: boolean
}

const i18nBase = 'PostBooking.FlightDetails'

export function Traveler({
  travelerName,
  pnrNumber,
  isTripCancelled,
  cancellationDate,
  checkInData,
  isCompleted,
  isFlightCancelled,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const topAriaLabel = `${t(`${i18nBase}.PNRNumber`)}: ${pnrNumber}. ${t(
    `${i18nBase}.Traveler`,
  )}: ${travelerName}`
  const hasCheckInList = !isTripCancelled && !isFlightCancelled && !!checkInData.length
  const pnrNumberTranslation = brandConfigurationStore.isAppleTheme
    ? t(`${i18nBase}.AppleTravelReference`)
    : t(`${i18nBase}.PNRNumber`)

  return (
    <>
      <Container withPaddingBottom={!hasCheckInList} screenType={screenType}>
        <Block marginBottom={hasCheckInList ? 16 : 0}>
          <CancellationLabel
            cancellationDate={cancellationDate}
            isTripCancelled={isTripCancelled}
            isFlightCancelled={isFlightCancelled}
          />
          <Row aria-label={topAriaLabel}>
            <Cell aria-label={`${t(`${i18nBase}.Traveler`)}, ${travelerName}`}>
              <CellName data-tracking-id="traveler-text">{t(`${i18nBase}.Traveler`)}</CellName>
              <CellValue aria-label={travelerName} data-tracking-id="passenger-name-text">
                {travelerName}
              </CellValue>
            </Cell>
            <Cell aria-label={`${pnrNumberTranslation}, ${pnrNumber}`}>
              <CellName data-tracking-id="pnr-number-text">{pnrNumberTranslation}</CellName>
              <CellValue aria-label={pnrNumber} data-tracking-id="record-locator-text">
                <CopyableWrapper value={pnrNumber}>{pnrNumber}</CopyableWrapper>
              </CellValue>
            </Cell>
          </Row>
        </Block>
        {!isCompleted && hasCheckInList && <CheckInList checkInData={checkInData} />}
      </Container>
    </>
  )
}
