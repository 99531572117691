import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { useAppDispatch, useAppSelector } from '@fiji/store'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import type { CreditCardFormData } from '@etta/components/credit-card-modal/types'
import { useAddCreditCardMutation } from '@fiji/graphql/hooks'
import type { CreditCard } from '@fiji/graphql/types'
import { PreBookRequestRequirement, SegmentType, TravelVertical } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useItinerary } from '@fiji/hooks/itinerary/use-itinerary'
import { useStartOverModal } from '@fiji/hooks/use-start-over-modal/use-start-over-modal'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useLoadPostBooking } from '@fiji/hooks/use-load-post-booking'
import { ROUTES } from '@fiji/routes'
import { SINGLE_USE_CREDIT_CARD_ID } from '@fiji/constants'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { ScreenType, useScreenType } from '@fiji/modes'
import { StepName } from '@etta/screens/checkout-page/types'
import { useRailSeatPreferences } from '@etta/screens/review-trip-page/hooks/use-rail-seat-preferences'
import { usePaymentSummaryContext, usePurchaseInfoContext } from '@etta/modules/booking/interface'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { LuggageOptionValueObject } from '@etta/modules/booking/core/value-objects'
import { useLuggageOptionsContext } from '@etta/modules/booking/interface/luggage-options'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { useTripsListContext } from '@etta/modules/post-booking-list'
import { resetMainNavigation } from '@fiji/store/main-navigation'
import type { CreditCardType, Section } from '../types'
import { useSingleTimeUseCard } from './use-single-time-use-card'
import { getCreditCardStructure } from './get-credit-card-structure'
import { useSegments } from './use-segments'
import { useBookedSegments } from './use-booked-segments'
import { getBillingConditions } from './get-billing-conditions'
import { useBillingModal } from './use-billing-modal'
import { usePaymentValidator } from './use-payment-validator'
import { useTripCost } from './use-trip-cost'
import { useDeletedSegmentsCost } from './use-post-booking-features'
import { useChosenCards } from './use-chosen-cards'
import { useLoadData } from './use-load-data'
import { useCreditCards } from './use-credit-cards'
import { useLoadPurchaseInfo } from './use-load-purchase-info'
import { useBookTrip } from './use-book-trip'
import { useShareTripsDetail } from './use-share-trips-detail'
import type { BookType } from './types'

export function usePaymentSummary() {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const { purchaseInfoActions } = usePurchaseInfoContext()
  const { luggageOptionsActions, luggageOptionsStore } = useLuggageOptionsContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const bookingToggle = useTogglePopup()
  const holdTripConfirmationToggle = useTogglePopup()
  const addLuggageToggle = useTogglePopup()
  const addCreditCardToggle = useTogglePopup()
  const { reviewTripStore } = useReviewTripContext()
  const { autoCancellationTime, isHoldTripAllowed } = reviewTripStore
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { paymentSummaryStore, paymentSummaryActions } = usePaymentSummaryContext()
  const { tripsListActions } = useTripsListContext()
  const {
    checkoutInfoStore: { isRulesAcknowledgementRequired, isBillingDataLoading, isBillingDataError },
  } = useCheckoutInfoContext()

  const { paymentStructure } = paymentSummaryStore

  const {
    privacyPolicy,
    isExpiringCreditCardForHotelAllowed,
    carRentals: { requireCarPaymentForm: isCarPaymentFormRequired },
  } = useAppSelector(displayConfigurationSelector)
  const { isShowDelegateName } = useShowDelegateName()
  const isPaymentInfoPage = useRouteMatch(StepName.PaymentInfo)
  const isTravelFusionLinksPage = useRouteMatch(StepName.PurchaseTermsAndConditions)
  const isConfirmationPage = useRouteMatch(StepName.Confirmation)
  const skip = !(isPaymentInfoPage || isTravelFusionLinksPage || isConfirmationPage)
  const screenType = useScreenType()

  const { itineraryId } = useItinerary()
  const {
    queryParams: { bookingId, isHoldFlow },
  } = useCheckoutQuery()
  const isHoldButtonVisible = isHoldTripAllowed && isPaymentInfoPage
  const {
    tripCost,
    segments,
    isDataLoading,
    isDataError,
    virtualPay,
    startDate,
    endDate,
    refetchItinerary,
    tripName,
  } = useLoadData({
    skip,
  })
  const {
    preBookRequestRequirement,
    dynamicSiteMessages,
    carRentalBilling,
    flightBilling,
    hotelBilling,
    railBilling,
    areCustomFieldsExist,
    isCostAllocationExist,
  } = useLoadPurchaseInfo()
  const singleTimeUseCard = useSingleTimeUseCard()

  const {
    handleBookTrip,
    tripConfirmationType: tripBookedType,
    isBooked,
    isBookTripLoading,
    isBookTripError,
    errorMsg,
    railAllocatedSeatMap,
    isRailEticketDeliveryOption,
    tripDynamicSiteMessages,
  } = useBookTrip()

  const { bookedSegments, isTripOnHold, isTripLoading, pnrNumber } = useLoadPostBooking({
    bookingId,
  })

  const {
    flightSegments,
    flightSegmentState,
    hotelSegments,
    hotelSegmentState,
    carRentalSegments,
    carRentalSegmentState,
    railSegments,
    railSegmentState,
    isTrainOnlySegments,
  } = useSegments(segments)

  const confirmationNumbersData = useBookedSegments({ bookedSegments })

  const {
    flight: flightPreviousBooking,
    hotel: hotelPreviousBooking,
    carRental: carRentalPreviousBooking,
    rail: railPreviousBooking,
  } = useDeletedSegmentsCost({
    currentSegments: segments,
    bookedSegments: bookedSegments,
  })

  const { currentSeatReservationType: railSeatReservationTypeMap } = useRailSeatPreferences({
    segments: railSegments,
    itineraryId,
  })

  const {
    isCarRentalPayingNow,
    isHotelPayingNow,
    carRentalTripCost,
    flightTripCost,
    hotelTripCost,
    railTripCost,
  } = useTripCost(tripCost)

  const { chosenCards, onCardChange } = useChosenCards({
    carRentalBilling,
    flightBilling,
    hotelBilling,
    railBilling,
    stashedPayment: paymentStructure,
    virtualPay,
    singleUseCreditCard: singleTimeUseCard.card,
  })

  const { carRentalCreditCard, flightCreditCard, hotelCreditCard, railCreditCard } = useCreditCards(
    {
      chosenCards,
      carRentalBilling,
      flightBilling,
      hotelBilling,
      railBilling,
      isCarPayNow: isCarRentalPayingNow || isCarPaymentFormRequired,
      singleTimeUseCard: singleTimeUseCard.card,
    },
  )
  const { handleShare: onShareTripsDetail } = useShareTripsDetail({
    tripName,
    totalTripCost: tripCost,
    carRentalTripCost,
    flightTripCost,
    hotelTripCost,
    startDate,
    endDate,
    segments,
  })

  const newFlightDetails = {
    segments: flightSegments,
    tripCost: flightTripCost,
    creditCard: flightCreditCard,
  }

  const newHotelDetails = {
    segments: hotelSegments,
    tripCost: hotelTripCost,
    creditCard: hotelCreditCard,
    isPayNow: isHotelPayingNow,
  }

  const newCarDetails = {
    segments: carRentalSegments,
    tripCost: carRentalTripCost,
    creditCard: carRentalCreditCard,
  }

  const newRailDetails = {
    segments: railSegments,
    tripCost: railTripCost,
    creditCard: railCreditCard,
    isEticket: railSegments?.[0]?.isEticket,
    allocatedSeatMap: railAllocatedSeatMap,
  }

  const flightDetails = useMemo(() => {
    return flightSegments.length > 0
      ? ({
          segments: flightSegments,
          billing: flightBilling,
          tripCost: flightTripCost,
          creditCard: flightCreditCard,
          segmentState: flightSegmentState,
          previousBookingRate: flightPreviousBooking,
        } as Section)
      : undefined
  }, [
    flightSegments,
    flightBilling,
    flightCreditCard,
    flightTripCost,
    flightSegmentState,
    flightPreviousBooking,
  ])

  const hotelDetails = useMemo(() => {
    return hotelSegments.length > 0
      ? ({
          segments: hotelSegments,
          billing: hotelBilling,
          tripCost: hotelTripCost,
          isPayNow: isHotelPayingNow,
          creditCard: hotelCreditCard,
          segmentState: hotelSegmentState,
          previousBookingRate: hotelPreviousBooking,
          virtualPay,
        } as Section)
      : undefined
  }, [
    hotelSegments,
    hotelBilling,
    hotelTripCost,
    isHotelPayingNow,
    hotelCreditCard,
    hotelSegmentState,
    hotelPreviousBooking,
    virtualPay,
  ])

  const carRentalDetails = useMemo(() => {
    return carRentalSegments.length > 0
      ? ({
          segments: carRentalSegments,
          billing: carRentalBilling,
          tripCost: carRentalTripCost,
          isPayNow: isCarRentalPayingNow,
          creditCard: carRentalCreditCard,
          segmentState: carRentalSegmentState,
          previousBookingRate: carRentalPreviousBooking,
        } as Section)
      : undefined
  }, [
    carRentalSegments,
    carRentalBilling,
    carRentalTripCost,
    isCarRentalPayingNow,
    carRentalSegmentState,
    carRentalPreviousBooking,
    carRentalCreditCard,
  ])

  const railDetails = useMemo(() => {
    return railSegments.length > 0
      ? ({
          segments: railSegments,
          billing: railBilling,
          tripCost: railTripCost,
          creditCard: railCreditCard,
          segmentState: railSegmentState,
          previousBookingRate: railPreviousBooking,
          isRailEticket: railSegments[0].isEticket,
          departureStationName: railSegments[0].segments[0].departure.stationName,
          railSeatReservationTypeMap,
          railAllocatedSeatMap,
        } as Section)
      : undefined
  }, [
    railSegments,
    railBilling,
    railTripCost,
    railCreditCard,
    railSegmentState,
    railPreviousBooking,
    railSeatReservationTypeMap,
    railAllocatedSeatMap,
  ])

  const isSegmentPaymentMissing = useCallback(
    ({ section }: { section?: Section }) => {
      if (!section) {
        return false
      }

      if (bookingId && !section?.segmentState) {
        return false
      }

      return !section?.creditCard
    },
    [bookingId],
  )

  const validator = usePaymentValidator({
    isCar:
      carRentalCreditCard &&
      !carRentalCreditCard.isSiteCard &&
      carRentalBilling?.metadata.isCvvRequired,
    isFlight:
      flightCreditCard && !flightCreditCard.isSiteCard && flightBilling?.metadata.isCvvRequired,
    isHotel: hotelCreditCard && !hotelCreditCard.isSiteCard && hotelBilling?.metadata.isCvvRequired,
    isRail: railCreditCard && !railCreditCard.isSiteCard && railBilling?.metadata.isCvvRequired,
  })

  const carRentalCardCheck = getBillingConditions({
    details: carRentalDetails,
    isCardOop: !!carRentalDetails?.billing?.creditCards.find(
      (card) => card.id === chosenCards.CARRENTAL,
    )?.isCardOutOfPolicy,
  })
  const hotelCardCheck = getBillingConditions({
    details: hotelDetails,
    isExpiringCreditCardForHotelAllowed,
    isCardOop: !!hotelDetails?.billing?.creditCards.find((card) => card.id === chosenCards.HOTEL)
      ?.isCardOutOfPolicy,
  })
  const airCardCheck = getBillingConditions({
    details: flightDetails,
    isCardOop: !!flightDetails?.billing?.creditCards.find((card) => card.id === chosenCards.AIR)
      ?.isCardOutOfPolicy,
  })
  const railCardCheck = getBillingConditions({
    details: railDetails,
    isCardOop: !!railDetails?.billing?.creditCards.find((card) => card.id === chosenCards.RAIL)
      ?.isCardOutOfPolicy,
  })

  const isPreBookRequired = useMemo(() => {
    return preBookRequestRequirement === PreBookRequestRequirement.Mandatory
  }, [preBookRequestRequirement])

  const bookButtonText = useMemo(() => {
    if (isPreBookRequired) {
      return t('PaymentSummary.PreBookText')
    }
    return t('PaymentSummary.ButtonText')
  }, [isPreBookRequired, t])

  const holdButtonText = useMemo(() => {
    if (isPreBookRequired) {
      return t('PaymentSummary.PreBookText')
    }
    return t('HoldTrip.HoldTrip')
  }, [isPreBookRequired, t])

  const isFlightSingleUseCard = useMemo(() => flightCreditCard?.id === SINGLE_USE_CREDIT_CARD_ID, [
    flightCreditCard?.id,
  ])
  const isHotelSingleUseCard = useMemo(
    () => (hotelCreditCard as Partial<CreditCard> | undefined)?.id === SINGLE_USE_CREDIT_CARD_ID,
    [hotelCreditCard],
  )
  const isCarSingleUseCard = useMemo(() => carRentalCreditCard?.id === SINGLE_USE_CREDIT_CARD_ID, [
    carRentalCreditCard?.id,
  ])
  const isRailSingleUseCard = useMemo(() => railCreditCard?.id === SINGLE_USE_CREDIT_CARD_ID, [
    railCreditCard?.id,
  ])

  const inputCreditCards = () => ({
    [TravelVertical.Air]: getCreditCardStructure({
      id: chosenCards[TravelVertical.Air],
      cvv: flightBilling?.metadata.isCvvRequired && validator.values.securityCodeFlight,
      singleUseCreditCard: isFlightSingleUseCard ? singleTimeUseCard.card : undefined,
    }),
    [TravelVertical.Hotel]: getCreditCardStructure({
      id: chosenCards[TravelVertical.Hotel],
      cvv: hotelBilling?.metadata.isCvvRequired && validator.values.securityCodeHotel,
      singleUseCreditCard: isHotelSingleUseCard ? singleTimeUseCard.card : undefined,
    }),
    [TravelVertical.Carrental]: getCreditCardStructure({
      id: chosenCards[TravelVertical.Carrental],
      cvv: carRentalBilling?.metadata.isCvvRequired && validator.values.securityCodeCar,
      singleUseCreditCard: isCarSingleUseCard ? singleTimeUseCard.card : undefined,
    }),
    [TravelVertical.Rail]: getCreditCardStructure({
      id: chosenCards[TravelVertical.Rail],
      cvv: railBilling?.metadata.isCvvRequired && validator.values.securityCodeRail,
      singleUseCreditCard: isRailSingleUseCard ? singleTimeUseCard.card : undefined,
    }),
  })

  const {
    billingModalToggle,
    onBillingModalOpen,
    billingParams,
    setBillingModalType,
  } = useBillingModal({
    carRentalBilling,
    hotelBilling,
    flightBilling,
    railBilling,
  })

  const openAddCreditCardModal = (type: CreditCardType) => () => {
    setBillingModalType(type)
    addCreditCardToggle.handleOpen()
  }

  const [addCreditCard] = useAddCreditCardMutation()

  const viewState = useViewState()

  async function handleAddCreditCard({
    creditCardData,
    billingAddress,
    isOneTimeUseCard,
  }: CreditCardFormData) {
    if (isOneTimeUseCard) {
      if (!billingParams?.travelVertical) {
        return
      }
      const id = SINGLE_USE_CREDIT_CARD_ID
      singleTimeUseCard.handleAddCard({ ...creditCardData, id, billingAddress })
      onCardChange({ id, travelVertical: billingParams.travelVertical })
      addCreditCardToggle.handleClose()
      return
    }
    viewState.onChangeViewState('saving')

    const response = await addCreditCard({
      variables: {
        input: { creditCardData, billingAddress },
      },
    })

    await purchaseInfoActions.fetchBillingFromPurchaseInfo()

    if (response.data?.addCreditCard.success) {
      addCreditCardToggle.handleClose()
    }

    await Promise.all(onReload())
    viewState.onChangeViewState('saved')
    await delayForSuccess()
    viewState.onChangeViewState('hidden')
  }

  const isNDCFare = !!reviewTripStore.trip?.segments.some((segment) => {
    if (segment.type === SegmentType.Flight) {
      return !!(segment as FlightSegmentEntity).isNDCFare
    }
    return false
  })

  const isShowTravelerAcknowledgeModal =
    !paymentSummaryStore.isTravelerAcknowledgeModalToggled &&
    !!reviewTripStore.trip?.flightId &&
    displayConfigurationStore.displayConfiguration.isShowTravelerAcknowledgement &&
    !isNDCFare
  const isMobile = screenType === ScreenType.Mobile
  const isShowNDCFareAcknowledgeModal =
    isMobile && !paymentSummaryStore.isNDCFareAcknowledgeModalToggled && isNDCFare

  const onSubmit = async (bookType?: BookType) => {
    if (isShowNDCFareAcknowledgeModal) {
      paymentSummaryActions.handleOpenNDCFareAcknowledgeModal(bookType)
      return
    }
    if (isShowTravelerAcknowledgeModal) {
      paymentSummaryActions.handleOpenTravelerAcknowledgeModal(bookType)
      return
    }
    const submitType = bookType || getDefaultSubmitType()

    await handleBookTrip({
      payment: isPreBookRequired ? paymentStructure : inputCreditCards(),
      putOnHold: submitType === 'hold',
      bookingId: bookingId ? String(bookingId) : undefined,
    })
  }

  const onReset = useCallback(() => {
    dispatch(updateTripReviewStatus('none'))
    paymentSummaryActions.updatePaymentStructure({
      AIR: null,
      HOTEL: null,
      CARRENTAL: null,
      RAIL: null,
    })
  }, [dispatch, paymentSummaryActions])

  const onDone = useCallback(() => {
    onReset()
    tripsListActions.dropAllTrips()
    if (screenType !== ScreenType.Desktop && tripBookedType === 'hold') {
      history.replace(ROUTES.postBooking.heldTrips)
      return
    }
    dispatch(resetMainNavigation())
    history.replace(ROUTES.postBooking.trips({ isForceRefetch: true }))
  }, [dispatch, history, onReset, screenType, tripBookedType, tripsListActions])

  const onAnotherTrip = useCallback(() => {
    onReset()
    history.replace(ROUTES.explore)
  }, [history, onReset])

  const onPreBookAdvance = () => {
    paymentSummaryActions.updatePaymentStructure(inputCreditCards())
    history.push(ROUTES.purchase.fusionLinks({ itineraryId, bookingId }))
  }

  const getDefaultSubmitType = () => {
    if (isHoldFlow) {
      return 'hold'
    }
    return 'regular'
  }

  const [submitType, setSubmitType] = useState(getDefaultSubmitType())

  const onPaymentSubmit = async (submitType?: BookType) => {
    const bookType = submitType || getDefaultSubmitType()
    setSubmitType(bookType)

    if (isRulesAcknowledgementRequired) {
      const { isValid } = await paymentSummaryStore.paymentSummaryValidator.submit()
      if (!isValid) {
        return
      }
    }
    const submitData = await validator.submit()
    if (!submitData.isValid) {
      return
    }
    if (isPreBookRequired) {
      onPreBookAdvance()
      return
    }
    if (bookType === 'hold') {
      holdTripConfirmationToggle.handleOpen()
      return
    }
    await onSubmit('regular')
  }

  const onBack = () => {
    if (areCustomFieldsExist || isCostAllocationExist) {
      history.push(
        ROUTES.checkout({ itineraryId, bookingId, checkoutStep: 'additional-info', isHoldFlow }),
      )

      return
    }
    history.push(
      ROUTES.checkout({ itineraryId, bookingId, checkoutStep: 'traveler-info', isHoldFlow }),
    )
  }

  const onReload = () => {
    return [purchaseInfoActions.fetchBillingFromPurchaseInfo(), refetchItinerary()]
  }

  useEffect(() => {
    if (isBookTripLoading) {
      return
    }

    if (screenType === ScreenType.Mobile) {
      return
    }

    if (isBooked) {
      history.push(
        ROUTES.checkout({
          checkoutStep: StepName.Confirmation,
          itineraryId,
          bookingId,
          isHoldFlow,
        }),
      )
      return
    }

    if (isBookTripError) {
      history.push(
        ROUTES.checkout({ checkoutStep: StepName.Error, itineraryId, bookingId, isHoldFlow }),
      )
    }
  }, [
    isBooked,
    isBookTripError,
    isBookTripLoading,
    screenType,
    itineraryId,
    bookingId,
    isHoldFlow,
    history,
  ])

  const isLoading = isDataLoading || isBillingDataLoading || isBookTripLoading
  const isLoadingError = !!(isBillingDataError || isDataError)

  const isCarPaymentRequired = useMemo(() => {
    return isCarPaymentFormRequired && !flightDetails && !hotelDetails && !railDetails
  }, [isCarPaymentFormRequired, flightDetails, hotelDetails, railDetails])

  const hasCarRentalDisablingConditions = useMemo(() => {
    return isSegmentPaymentMissing({ section: carRentalDetails }) && isCarPaymentRequired
  }, [isCarPaymentRequired, carRentalDetails, isSegmentPaymentMissing])

  const isBilledToCompanyAccount = true
  const hasRailDisablingConditions = useMemo(() => {
    return !isBilledToCompanyAccount && isSegmentPaymentMissing({ section: railDetails })
  }, [railDetails, isSegmentPaymentMissing, isBilledToCompanyAccount])

  const hasDisablingConditions =
    !![carRentalCardCheck, hotelCardCheck, airCardCheck, railCardCheck].find(
      (card) => !card.isBillingAllowed,
    ) ||
    isSegmentPaymentMissing({ section: flightDetails }) ||
    isSegmentPaymentMissing({ section: hotelDetails }) ||
    hasCarRentalDisablingConditions ||
    hasRailDisablingConditions
  const isFlightCardChangeable = !(
    flightDetails?.billing?.creditCards.reduce((count, creditCard) => {
      if (creditCard.metadata.isRestricted) {
        return count + 1
      }
      return count
    }, 0) === 1 && flightDetails?.creditCard?.metadata?.isRestricted
  )
  const isHotelCardChangeable = !(
    hotelDetails?.billing?.creditCards.reduce((count, creditCard) => {
      if (creditCard.metadata.isRestricted) {
        return count + 1
      }
      return count
    }, 0) === 1 && hotelDetails?.creditCard?.metadata?.isRestricted
  )
  const isCarRentalCardChangeable = !(
    carRentalDetails?.billing?.creditCards.reduce((count, creditCard) => {
      if (creditCard.metadata.isRestricted) {
        return count + 1
      }
      return count
    }, 0) === 1 && carRentalDetails?.creditCard?.metadata?.isRestricted
  )
  const isRailCardChangeable = !(
    railDetails?.billing?.creditCards.reduce((count, creditCard) => {
      if (creditCard.metadata.isRestricted) {
        return count + 1
      }
      return count
    }, 0) === 1 && railDetails?.creditCard?.metadata?.isRestricted
  )

  const isPostBooking = !reviewTripStore.trip?.isOnHold && !!bookingId

  const { startOverModalSlot } = useStartOverModal({
    isLoading,
    bookingId,
    isPreventForward: isPaymentInfoPage && !isBooked,
    isPreventBack: false,
  })

  const handleSelectLuggageOption = (luggageOption?: LuggageOptionValueObject) => {
    luggageOptionsActions.setLuggageOption(luggageOption)
    addLuggageToggle.handleClose()
  }

  const luggageOptions = flightSegments && flightSegments[0]?.luggageOptions
  const showLuggageOptions =
    luggageOptionsStore.isLuggageOptionsApplicable && !!luggageOptions?.length
  const isRailAccreditationEnabled = isTrainOnlySegments && featureFlags.isRailAccreditationEnabled

  return {
    isRailEticketDeliveryOption,
    bookingToggle,
    flightDetails,
    hotelDetails,
    carRentalDetails,
    railDetails,
    isCarPaymentFormRequired,
    isBookTripError,
    isLoadingError,
    isBooked,
    tripCost,
    onCardChange,
    onSubmit,
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    onBack,
    onReload,
    isLoading,
    isBookTripLoading,
    addCreditCardToggle,
    holdTripConfirmationToggle,
    isHoldButtonVisible,
    handleAddCreditCard,
    dynamicSiteMessages,
    validator,
    billingModalToggle,
    onBillingModalOpen,
    billingParams,
    carRentalCardCheck,
    hotelCardCheck,
    airCardCheck,
    railCardCheck,
    hasDisablingConditions,
    bookingId,
    chosenCards,
    errorMsg,
    privacyPolicy,
    isCreditCardChangeable: {
      flight: isFlightCardChangeable,
      hotel: isHotelCardChangeable,
      carRental: isCarRentalCardChangeable,
      rail: isRailCardChangeable,
    },
    singleTimeUseCard,
    isPreBookRequired,
    onPaymentSubmit,
    startOverModalSlot,
    isShowDelegateName,
    tripName,
    areCustomFieldsExist,
    isCostAllocationExist,
    viewState,
    isRailAccreditationEnabled,
    isTripOnHold,
    isTripLoading,
    tripDynamicSiteMessages,
    confirmationNumbersData,
    pnrNumber,
    addLuggageToggle,
    handleSelectLuggageOption,
    luggageOptions,
    showLuggageOptions,
    openAddCreditCardModal,
    bookButtonText,
    holdButtonText,
    autoCancellationTime,
    isHoldFlow,
    tripBookedType,
    selectedLuggageOption: luggageOptionsStore.departureLuggageOption,
    isAppleBannerShown: featureFlags.isAppleBannerEnabled && brandConfigurationStore.isAppleTheme,
    isCarPaymentRequired,
    newFlightDetails,
    newHotelDetails,
    newCarDetails,
    newRailDetails,
    onReset,
    submitType,
    isPostBooking,
    isBilledToCompanyAccount,
  }
}
