import { useTranslation } from 'react-i18next'
import type { ViewState } from '@etta/ui/view-state-modal'

export const useDeleteCarRentalTitle = (removeCarStateStatus: ViewState): string => {
  const { t } = useTranslation()

  const i18Base = 'CarRentalDetailsModal'

  if (removeCarStateStatus === 'saving') {
    return t(i18Base + '.RemovingCarRental')
  }

  return t(i18Base + '.RemovedCarRental')
}
