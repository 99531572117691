import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const SubheadWrapper = styled.div`
  opacity: 0.8;
`
