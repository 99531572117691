import { Inject, Service } from '@etta/di'
import { FeatureFlagsAdapter } from '@etta/modules/feature-flags/infra/feature-flags.adapter'
import { FeatureFlagsStore } from '@etta/modules/feature-flags/interface/stores/feature-flags.store'

@Service()
export class FeatureFlagsService {
  constructor(
    @Inject()
    private readonly featureFlagsAdapter: FeatureFlagsAdapter,
    @Inject()
    private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  async loadFlags(companyId?: number) {
    this.featureFlagsStore.setIsLoading(true)
    const result = await this.featureFlagsAdapter.getFlags(companyId)
    this.featureFlagsStore.setIsLoading(false)
    if (result.isErr()) {
      return
    }
    const flags = result.getValue()
    this.featureFlagsStore.setFlags(flags)
    this.featureFlagsStore.setIsLoaded(true)
  }

  async getFlags(companyId?: number) {
    await this.loadFlags(companyId)
    return this.featureFlagsStore.flags
  }
}
