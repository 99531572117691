import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { ActiveTripStore } from '@etta/modules/review-trip/interface/stores/active-trip.store'
import { AddSegmentModalsStore } from '@etta/modules/review-trip/interface/stores/add-segment-modals.store'
import {
  TripActions,
  SegmentTypeCheckActions,
  ActiveTripActions,
  TripCarRentalActions,
  TripHotelsActions,
  AddSegmentModalsActions,
} from './actions'
import { TripFlightActions } from './actions/trip-flight.actions'
import { TripRailActions } from './actions/trip-rail.actions'
import { AlignTripAction } from './actions/align-trip.actions'
import { PostBookingReviewTripPageStore } from './stores/post-booking-review-trip-page.store'
import { PostBookingReviewTripPageActions } from './actions/post-booking-review-trip-page.actions'
import { ReactivateTripActions } from './actions/reactivate-trip.actions'

export const { useModuleContext: useReviewTripContext } = hookContextFactory({
  tripActions: TripActions,
  tripCarRentalActions: TripCarRentalActions,
  tripFlightActions: TripFlightActions,
  tripHotelActions: TripHotelsActions,
  tripRailActions: TripRailActions,
  reviewTripStore: ReviewTripStore,
  segmentTypeCheckActions: SegmentTypeCheckActions,
  activeTripActions: ActiveTripActions,
  activeTripStore: ActiveTripStore,
  addSegmentModalsActions: AddSegmentModalsActions,
  addSegmentModalsStore: AddSegmentModalsStore,
  alignTripActions: AlignTripAction,
  postBookingReviewTripPageStore: PostBookingReviewTripPageStore,
  postBookingReviewTripPageActions: PostBookingReviewTripPageActions,
  reactivateTripActions: ReactivateTripActions,
})
