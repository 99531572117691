import * as R from 'ramda'
import type * as yup from 'yup'
import i18n from 'i18next'
import type { ValidationScheme } from '@etta/interface/services/validator/types'
import { Validator } from '@etta/interface/services/validator'
import { isBeforeDate } from '@fiji/utils/dates/is-before-date'
import { getYear } from '@fiji/utils/dates/get-year'
import { getMonth } from '@fiji/utils/dates/get-month'
import type {
  UserProfileUnionEntity,
  UserProfileNumberEntity,
  UserProfileTextEntity,
  UserProfileCheckBoxEntity,
  UserProfileListEntity,
  UserProfileDateTimeEntity,
} from '../../core/entities/user-profile.entity'

const i18nBase = 'AdditionalInfoForm.'
// TODO : do we need to ask the backend for a more generic date? something like 1900-01-01T00:00:00?
const gazooEmptyDateTime = new Date('1970-01-01T00:00:00').toLocaleDateString()

export class UserProfileValidatorBuilder {
  static build(settings: UserProfileUnionEntity[]) {
    const schema: ValidationScheme = settings.reduce((prev, curr) => {
      if (isNumberTypeField(curr)) {
        return {
          ...prev,
          [curr.id]: this.getNumberScheme(curr),
        }
      } else if (isTextTypeField(curr)) {
        return {
          ...prev,
          [curr.id]: this.getTextScheme(curr),
        }
      } else if (isCheckBoxTypeField(curr)) {
        return {
          ...prev,
          [curr.id]: this.getCheckBoxScheme(curr),
        }
      } else if (isListTypeField(curr)) {
        return {
          ...prev,
          [curr.id]: this.getListScheme(curr),
        }
      } else if (isDateTimeTypeField(curr)) {
        return {
          ...prev,
          [curr.id]: this.getDateTimeScheme(curr),
        }
      }

      return prev
    }, {} as ValidationScheme)

    return new Validator(schema)
  }

  private static getNumberScheme(setting: UserProfileNumberEntity) {
    const numberScheme = Validator.scheme
      .string()
      .nullable()
      .test(
        'min-number',
        i18n.t(`${i18nBase}Errors.MinNumber`, { number: setting.minNumber }),
        (value) => {
          if (
            value !== null &&
            value !== undefined &&
            !isNaN(Number(value)) &&
            setting.minNumber !== null &&
            setting.minNumber !== undefined
          ) {
            return Number(value) >= setting.minNumber
          }
          return true
        },
      )
      .test(
        'test-max-number',
        i18n.t(`${i18nBase}Errors.MaxNumber`, { number: setting.maxNumber }),
        (value: any) => {
          if (
            value !== null &&
            value !== undefined &&
            setting.maxNumber !== null &&
            setting.maxNumber !== undefined
          ) {
            return Number(value) <= setting.maxNumber
          }
          return true
        },
      )
      .test(
        'test-decimal-places',
        i18n.t(`${i18nBase}Errors.DecimalPlaces`, {
          number: setting.numberDecimal,
        }),
        (value) => {
          if (value === null || value === undefined) {
            return true
          }

          if (setting.numberDecimal === null || setting.numberDecimal === undefined) {
            return true
          }

          return getDecimalLength(Number(value)) <= setting.numberDecimal
        },
      )
    return this.getBaseScheme(numberScheme, setting.mandatory, setting.value, setting.defaultNumber)
  }

  private static getTextScheme(setting: UserProfileTextEntity) {
    const textScheme = Validator.scheme
      .string()
      .nullable()
      .test(
        'min-characters',
        i18n.t(`${i18nBase}Errors.MinCharacters`, { count: setting.minLength ?? 0 }),
        (value) => {
          if (value !== undefined && value !== null && setting.minLength) {
            return value.length >= setting.minLength
          }

          return true
        },
      )
      .test(
        'max-characters',
        i18n.t(`${i18nBase}Errors.MaxCharacters`, {
          count: setting.maxLength ?? 0,
        }),
        (value) => {
          if (value !== undefined && value !== null && setting.maxLength) {
            return value.length <= setting.maxLength
          }

          return true
        },
      )

    return this.getBaseScheme(textScheme, setting.mandatory, setting.value, setting.defaultText)
  }

  private static getCheckBoxScheme(setting: UserProfileCheckBoxEntity) {
    let checkBoxScheme = Validator.scheme.string().nullable()
    if (setting.checkedRequired) {
      checkBoxScheme = checkBoxScheme.test(
        'proceedBlock',
        i18n.t(`${i18nBase}Errors.Proceed`),
        (value) => {
          return value !== 'false'
        },
      )
    }

    return this.getBaseScheme(
      checkBoxScheme,
      setting.mandatory,
      setting.value,
      setting.defaultChecked,
    )
  }

  private static getListScheme(setting: UserProfileListEntity) {
    return this.getBaseScheme(
      Validator.scheme.string().nullable(),
      setting.mandatory,
      setting.value,
      setting.defaultValue,
    )
  }

  private static getDateTimeScheme(setting: UserProfileDateTimeEntity) {
    let scheme = Validator.scheme.mixed<Date | string>()

    const { displayDay, displayMonth, displayYear, displayHour, displayMinute } = setting

    const isTimeOnly =
      [displayYear, displayMonth, displayDay].every((v) => !v) &&
      [displayHour, displayMinute].some(Boolean)

    if (setting.mandatory) {
      scheme = scheme.test(
        'requiredDate',
        i18n.t(`${i18nBase}Errors.Required`),
        (value) => !this.isEmptyDate(value),
      )
    }

    if (isTimeOnly) {
      return this.getBaseScheme(scheme, setting.mandatory, setting.value && new Date(setting.value))
    }

    if (setting.isAllowedPastDateTime === false) {
      scheme = scheme.test('passDate', i18n.t(`${i18nBase}Errors.PassDate`), (value) =>
        !this.isEmptyDate(value) ? !isBeforeDate(new Date(value!), new Date()) : true,
      )
    }
    if (setting.startYear) {
      scheme = scheme.test(
        'minYear',
        i18n.t(`${i18nBase}Errors.MinYear`, { number: setting.startYear }),
        (value) =>
          !this.isEmptyDate(value) ? getYear(new Date(value!)) >= setting.startYear! : true,
      )
    }
    if (setting.endYear) {
      scheme = scheme.test(
        'maxYear',
        i18n.t(`${i18nBase}Errors.MaxYear`, { number: setting.endYear }),
        (value) =>
          !this.isEmptyDate(value) ? getYear(new Date(value!)) <= setting.endYear! : true,
      )
    }
    if (setting.startMonth) {
      scheme = scheme.test(
        'minMonth',
        i18n.t(`${i18nBase}Errors.MinMonth`, { number: setting.startMonth }),
        (value) =>
          !this.isEmptyDate(value) ? getMonth(new Date(value!)) >= setting.startMonth! : true,
      )
    }
    if (setting.endMonth) {
      scheme = scheme.test(
        'maxMonth',
        i18n.t(`${i18nBase}Errors.MaxMonth`, { number: setting.endMonth }),
        (value) =>
          !this.isEmptyDate(value) ? getMonth(new Date(value!)) <= setting.endMonth! : true,
      )
    }
    if (setting.startDay) {
      scheme = scheme.test(
        'minDay',
        i18n.t(`${i18nBase}Errors.MinDay`, { number: setting.startDay }),
        (value) => {
          return !this.isEmptyDate(value) ? new Date(value!).getDate() >= setting.startDay! : true
        },
      )
    }
    if (setting.endDay) {
      scheme = scheme.test(
        'maxDay',
        i18n.t(`${i18nBase}Errors.MaxDay`, { number: setting.endDay }),
        (value) =>
          !this.isEmptyDate(value) ? new Date(value!).getDate() <= setting.endDay! : true,
      )
    }

    return this.getBaseScheme(scheme, false, setting.value && new Date(setting.value))
  }

  private static getBaseScheme<T extends yup.BaseSchema = yup.BaseSchema>(
    scheme: T,
    mandatory: boolean,
    value?: any,
    defaultValue?: any,
  ) {
    return R.pipe(
      () => {
        return mandatory ? scheme.required(i18n.t(`${i18nBase}Errors.Required`)) : scheme
      },
      (scheme: T) => {
        if (value !== undefined && value !== null) {
          return scheme.default(value)
        }
        if (defaultValue) {
          return scheme.default(defaultValue)
        }
        return scheme.default(value)
      },
    )() as T
  }

  private static isEmptyDate = (date: string | Date | undefined): boolean => {
    return date ? new Date(date).toLocaleDateString() === gazooEmptyDateTime : true
  }
}

const isNumberTypeField = (setting: UserProfileUnionEntity): setting is UserProfileNumberEntity =>
  setting.type === 'NUMBER'

const isTextTypeField = (setting: UserProfileUnionEntity): setting is UserProfileTextEntity =>
  setting.type === 'TEXT'

const isCheckBoxTypeField = (
  setting: UserProfileUnionEntity,
): setting is UserProfileCheckBoxEntity => setting.type === 'CHECK_BOX'

const isListTypeField = (setting: UserProfileUnionEntity): setting is UserProfileListEntity =>
  setting.type === 'LIST'

const isDateTimeTypeField = (
  setting: UserProfileUnionEntity,
): setting is UserProfileDateTimeEntity => setting.type === 'DATE_TIME'

const SEPARATOR_LENGTH = 1

function getDecimalLength(num: number) {
  const int = Math.floor(num)
  const decimalLength = String(num).length - String(int).length
  return decimalLength >= 2 ? decimalLength - SEPARATOR_LENGTH : 0
}
