import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { CarRentalSearchPage } from '@etta/screens/car-rental-search-page'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { CloseIconWrapper, ModalContent, TitleWrapper } from './styled'

const DATA_TRACKING_ID = 'RTP_CAR_SEARCH_FORM_MODAL'

export const CarSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const { addSegmentModalsStore, addSegmentModalsActions } = useReviewTripContext()
  const onClose = useCallback(() => addSegmentModalsActions.closeCarSearchModal(), [
    addSegmentModalsActions,
  ])

  useEffect(() => {
    return () => onClose()
  }, [onClose])

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={onClose}
      isVisible={addSegmentModalsStore.isCarSearchModalOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton onClick={onClose} color="bodyText" icon="closePWA" size="medium" />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('TripReview.AddSegments.Car')}</Text>
      </TitleWrapper>
      <ModalContent>
        <CarRentalSearchPage isBlockView />
      </ModalContent>
    </Modal>
  )
})
