import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@etta/core/enums'
import { Text } from '@etta/ui/text'
import { Wrapper } from './segments-container-styled'

type Props = PropsWithChildren<{
  segmentType: SegmentType
  'data-tracking-id'?: string
}>

const i18nBase = 'PaymentSummary'

export function SegmentsContainerList({
  segmentType,
  children,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()

  function getTitle(segmentType: SegmentType) {
    switch (segmentType) {
      case SegmentType.Flight:
        return t(`${i18nBase}.SegmentsHeadline.Flights`)
      case SegmentType.Hotel:
        return t(`${i18nBase}.SegmentsHeadline.Hotels`)
      case SegmentType.CarRental:
        return t(`${i18nBase}.SegmentsHeadline.CarRental`)
      case SegmentType.Train:
        return t(`${i18nBase}.SegmentsHeadline.Rail`)
    }
  }
  return (
    <Wrapper data-tracking-id={dataTrackingId}>
      <Text variant="headline" color="mainText">
        {getTitle(segmentType)}
      </Text>
      {children}
    </Wrapper>
  )
}
