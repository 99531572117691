import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { ROUTES } from '@fiji/routes'
import { useTogglePopup } from '../use-toggle-popup'

type Props = {
  isAllowed: boolean
  modifyAction: () => void
}

export const useTripModifyModal = ({ isAllowed, modifyAction }: Props) => {
  const history = useHistory()
  const toggleModifyModal = useTogglePopup()

  const handleViewSupport = useCallback(() => {
    history.push(ROUTES.support.main)
  }, [history])

  return {
    toggleModifyModal,
    handleViewSupport,
    modalAction: isAllowed ? modifyAction : toggleModifyModal.handleOpen,
  }
}
