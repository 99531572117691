import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import { ScreenType, useScreenType } from '@fiji/modes'
import { IconButton } from '@etta/ui/icon-button'
import { getWarningTranslation } from '../warning-translation'
import { Dialog, Footer } from './align-dates-alert.styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onContinueToCheckout: () => void
  flightStartDateTime: string
  lastFlightStartDateTime: string
  mismatchedTypes: AlignTripTravelVerticalValueObject[]
}

const i18nBase = 'AlignDatesAlert.'

export function AlignDatesAlert({
  isOpen,
  onClose,
  onConfirm,
  onContinueToCheckout,
  mismatchedTypes,
  lastFlightStartDateTime,
  flightStartDateTime,
}: Props) {
  const { t } = useTranslation()
  const isMobile = useScreenType() === ScreenType.Mobile

  const description = getWarningTranslation({
    mismatchedTypes,
    flightStartDateTime,
    lastFlightStartDateTime,
  })

  const footerSlot = (
    <Footer>
      <Button fullWidth onClick={onConfirm}>
        {t(i18nBase + 'ConfirmButton')}
      </Button>
      <Button variant="outline" fullWidth onClick={onContinueToCheckout}>
        {t(i18nBase + 'DeclineButton')}
      </Button>
    </Footer>
  )

  if (isMobile) {
    return (
      <Dialog isOpen={isOpen} onClose={onClose}>
        <Dialog.Header
          closeIcon={
            <Block marginLeft="auto">
              <IconButton
                size={'medium'}
                onClick={onClose}
                icon="closeFilledPWA"
                color="bodyText1"
              />{' '}
            </Block>
          }
          title={
            <Text align="center" isBlock variant="title3" color="mainText">
              {t(i18nBase + 'Header')}
            </Text>
          }
          onClose={onClose}
        />
        <Dialog.Body>
          <Block marginTop={24} marginBottom={32}>
            <Text align="left" variant="subHeadMedium" color="mainText">
              {description}
            </Text>
          </Block>
          {footerSlot}
        </Dialog.Body>
      </Dialog>
    )
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Text align="center" isBlock variant="title3" color="mainText">
        {t(i18nBase + 'Header')}
      </Text>
      <Dialog.Body>
        <Block marginTop={24} marginBottom={32}>
          <Text align="center" variant="subHeadStrong" color="bodyText">
            {description}
          </Text>
        </Block>
        {footerSlot}
      </Dialog.Body>
    </Dialog>
  )
}
