import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace TravelPreferencesErrors {
  export const UnexpectedError = createErrorClass('Unexpected Error')
  export const UpdateAirUnexpectedError = createErrorClass('Update Air Unexpected Error')
  export const UpdateHotelUnexpectedError = createErrorClass('Update Hotel Unexpected Error')
  export const UpdateCarRentalUnexpectedError = createErrorClass(
    'Update Car Rental Unexpected Error',
  )
}

export type TravelPreferencesErrorsInstances = InstanceType<
  typeof TravelPreferencesErrors[keyof typeof TravelPreferencesErrors]
>
