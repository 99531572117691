import i18n from 'i18next'
import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'

export function getPercentageOnTimeLabels(segments: SearchRailLegSubSegmentEntity[]) {
  return segments.map(({ percentageOnTime }) => {
    if (!percentageOnTime) {
      return i18n.t('FlightDetails.N/A')
    }

    return `${percentageOnTime}%`
  })
}
