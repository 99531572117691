import { useTranslation } from 'react-i18next'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import type { Props } from './type'

const i18nBase = 'PostBooking.TrainDetails'

export function useJourneyDetails({ segment }: Props) {
  const { t } = useTranslation()
  const { departureDate, arrivalDate, stations, duration } = segment
  const departureTime = departureDate ? new Date(deleteTimezone(departureDate)) : undefined
  const originTime = departureTime ? dateFormat(departureTime, 'h:mm aaa') : '-'

  const arrivalTime = arrivalDate ? new Date(deleteTimezone(arrivalDate)) : undefined
  const destinationTime = arrivalTime ? dateFormat(arrivalTime, 'h:mm aaa') : '-'

  const departureStationName = stations?.departure?.stationName || '-'
  const arrivalStationName = stations?.arrival?.stationName || '-'

  const hour = duration?.hours ? t(i18nBase + '.Hours', { hours: duration.hours }) : ''
  const minute = duration?.minutes ? t(i18nBase + '.Minutes', { minutes: duration.minutes }) : ''

  const travelTime = [hour, minute].join(' ')

  return {
    travelTime,
    originTime,
    departureStationName,
    destinationTime,
    arrivalStationName,
  }
}
