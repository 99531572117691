import { Skeleton } from '@etta/ui/skeleton'
import { default as NoHotelIcon } from '../no-hotel-preview-icon.svg'
import { HotelButton, Thumbnail, ThumbnailImageBg } from './thumbnail-image-styled'
import { useThumbnailImage } from './use-thumbnail-image'

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  key: number
  src?: string
  isActive: boolean
}

export function ThumbnailImage({ onClick, key, src, isActive }: Props) {
  const {
    isImageLoaded,
    hasImageError,
    isReadyForRender,
    updateImageLoaded,
    updateImageError,
  } = useThumbnailImage()

  const isError = hasImageError || !src

  return (
    <>
      <HotelButton key={key} onClick={onClick} isLoaded={!!(isReadyForRender && src)}>
        <Thumbnail
          src={src}
          isActive={isActive}
          onLoad={updateImageLoaded}
          onError={updateImageError}
        />
      </HotelButton>
      {!isImageLoaded && !isError && (
        <HotelButton key={key} onClick={onClick} isLoaded={true}>
          <Skeleton width="100%" height="100%">
            <rect width="100%" height="100%" clipPath="url(#round-corner)" />
          </Skeleton>
        </HotelButton>
      )}
      {isError && (
        <HotelButton key={key} isLoaded={true} onClick={onClick}>
          <ThumbnailImageBg isActive={isActive}>
            <NoHotelIcon />
          </ThumbnailImageBg>
        </HotelButton>
      )}
    </>
  )
}
