import { Inject, Service } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores'
// TODO: remove fiji import
import { TimeRangeOption, FlightDirection, TimeRangeDirection } from '@fiji/enums'
// TODO: remove fiji import
import {
  customOriginTime,
  customReturnTime,
  anyTimeOption,
  airTimeRangeOptions,
} from '@fiji/hooks/time-configuration/use-flight/constants'

@Service()
export class InitialFlightTimeService {
  constructor(
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  private get(timeRangeBy: TimeRangeDirection, flightDirection: FlightDirection) {
    const {
      isDefaultAnyTime,
      isMorningAfternoonEveningEnabled,
      isAnytimeEnabled,
    } = this.displayConfigurationStore.flightConfigurations

    if (
      !isDefaultAnyTime &&
      isMorningAfternoonEveningEnabled &&
      flightDirection === FlightDirection.Origin
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Morning], timeRangeBy }
    }

    if (
      !isDefaultAnyTime &&
      isMorningAfternoonEveningEnabled &&
      flightDirection === FlightDirection.Return
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Evening], timeRangeBy }
    }

    if (!isAnytimeEnabled && flightDirection === FlightDirection.Origin) {
      return { ...customOriginTime, timeRangeBy }
    }

    if (!isAnytimeEnabled && flightDirection === FlightDirection.Return) {
      return { ...customReturnTime, timeRangeBy }
    }

    if (isAnytimeEnabled && !isDefaultAnyTime && flightDirection === FlightDirection.Origin) {
      return { ...customOriginTime, timeRangeBy }
    }

    if (isAnytimeEnabled && !isDefaultAnyTime && flightDirection === FlightDirection.Return) {
      return { ...customReturnTime, timeRangeBy }
    }

    return {
      ...anyTimeOption,
      timeRangeBy,
    }
  }

  get timeRange() {
    return this.get(TimeRangeDirection.Departure, FlightDirection.Origin)
  }

  get returnTimeRange() {
    return this.get(TimeRangeDirection.Departure, FlightDirection.Return)
  }
}
