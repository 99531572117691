import { Action, Inject } from '@etta/di'
import { HomeAirportService } from '../services/home-airport.service'

@Action()
export class HomeAirportActions {
  constructor(
    @Inject()
    private readonly homeAirportService: HomeAirportService,
  ) {}

  load() {
    this.homeAirportService.get()
  }
  reload() {
    this.homeAirportService.reload()
  }
}
