import styled from 'styled-components'

export const Container = styled.div<{ isInteractive: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  pointer-events: ${({ isInteractive }) => (isInteractive ? 'auto' : 'none')};
  & .mapkit-anchor {
    width: 0;
    height: 0;
    position: relative;
  }

  & .mapkit-callout {
    width: 0;
    height: 0;
    position: relative;
  }

  & .mapkit-mobility-marker {
    background: ${(p) => p.theme.colors.white};
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 4px solid ${(p) => p.theme.colors.mainText1};
  }

  & .mapkit-mobility-marker-selected {
    background: ${(p) => p.theme.colors.white};
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 4px solid ${(p) => p.theme.colors.primary};
  }
`

export const ClickWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`
