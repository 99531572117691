import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import type { Rate } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Unit, Label } from '../segment-section/segment-section-styled'
import { useSegmentSpecificUnit } from './use-segment-specific-unit'

type Props = {
  labelSlot: ReactNode
  cost?: Rate | null
  voiceLabel?: string
}

export function SegmentSpecificUnit({ labelSlot, cost, voiceLabel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PaymentSummary.'

  const { mainCost } = useSegmentSpecificUnit({ cost })
  return (
    <Unit
      aria-label={
        voiceLabel &&
        t(i18nBase + 'SegmentAccessibilityLabel', {
          name: voiceLabel,
          amount: mainCost,
        })
      }>
      <Label variant="footnoteMedium" color="bodyText">
        {labelSlot}
      </Label>
      {mainCost && (
        <Text variant="footnoteMedium" color="bodyText">
          {mainCost}
        </Text>
      )}
    </Unit>
  )
}
