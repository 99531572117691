import styled, { css } from 'styled-components'
import {
  captionStrongCaps,
  footnoteMedium,
  footnoteStrong,
  title2,
  linkSmall,
  subHeadStrong,
  UnstyledButton,
} from '@fiji/style'
import type { ButtonProps } from '@etta/ui/button'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

const BORDER_RADIUS = '14px'

const separator = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Container = styled.div<{ hasBorder: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${({ hasBorder, theme }) => (hasBorder ? theme.colors.error : 'transparent')};
`

export const Header = styled.div<{ hasBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  flex-wrap: wrap;
  border-radius: ${(props) => {
    if (props.hasBorder) {
      return `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`
    }

    return '0'
  }};
  ${separator};
`

export const HeaderDateContainer = styled.div`
  max-width: 65%;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`

export const StyledDate = styled.span`
  color: ${({ theme }) => theme.colors.bodyText};
  ${captionStrongCaps};
`

export const CostWrapper = styled.div`
  max-width: 65%;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Cost = styled.span<{ isCrossedOut?: boolean }>`
  color: ${({ theme, isCrossedOut }) => theme.colors[isCrossedOut ? 'bodyText2' : 'mainText']};
  ${subHeadStrong};

  ${({ isCrossedOut }) =>
    isCrossedOut &&
    css`
      text-decoration: line-through;
    `}}

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const UnusedTicket = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 8px 0;
`

export const UnusedTicketAppliedLabel = styled(Text).attrs({
  variant: 'footnoteMedium',
  color: 'bodyText',
})`
  border-bottom: dashed 1px;
`

const BODY_HORIZONTAL_PADDING = 16

export const Body = styled.div<{ hasBorderRadius: boolean }>`
  padding: 0 ${BODY_HORIZONTAL_PADDING}px 0 ${BODY_HORIZONTAL_PADDING}px;
  ${({ hasBorderRadius }) =>
    hasBorderRadius &&
    css`
      border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
    `}
    };
`

export const BodyContent = styled(UnstyledButton)`
  width: 100%;
  margin: 12px 0px 13px;
  &:active {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.mainText};
  ${title2};
`

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const Location = styled.span`
  ${textCSS};
`

export const ListItem = styled.li`
  display: flex;
  flex-shrink: 0;
  line-height: 1.5;
  text-transform: capitalize;
  max-width: 100%;
  ${textCSS};
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;

  & li {
    margin-bottom: 5px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const FooterRow = styled.div<{ isInvalid?: boolean; hasBorder: boolean }>`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 12px 16px;
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
      margin-top: 1px;
    `}
  ${({ isInvalid, theme }) =>
    isInvalid &&
    css`
      background-color: ${theme.colors.error1};
    `};
`

export const FooterBadge = styled.div`
  max-width: 70%;
  display: flex;
  gap: 6px;
  align-items: center;
  ${footnoteStrong};
  color: ${({ theme }) => theme.colors.mainText};
`

export const SelectReasonButton = styled(Button).attrs<ButtonProps, ButtonProps>(() => ({
  variant: 'outline',
  size: 'small',
}))`
  background: transparent;
`

export const OopDesc = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 7px;
  margin-top: 2px;
  width: 100%;
`

export const OopLine = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${linkSmall};
  padding-top: 2px;
  width: 100%;
`

export const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${BODY_HORIZONTAL_PADDING}px 0 ${BODY_HORIZONTAL_PADDING}px;
  margin: 0 -${BODY_HORIZONTAL_PADDING}px 0 -${BODY_HORIZONTAL_PADDING}px;
  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }
`

export const ChangeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;
  padding-bottom: 13px;
`

export const ChangeBlockSeparator = styled.div`
  border: 1px dashed ${(p) => p.theme.colors.borderLight};
`

export const CardStatusBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 15px;
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  background-color: ${(p) => p.theme.colors.borderDark};
`

export const CardStatus = styled.div<{ isHidingBeforeStatus?: boolean }>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.mainText};
  ${captionStrongCaps}

  ${(p) => {
    if (!p.isHidingBeforeStatus) {
      return `&::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 7.5px;
        background-color: ${p.theme.colors.primary};
        margin-right: 3px;
      }`
    }

    return ''
  }}
`

export const ChevronIconContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 31px;
`
