import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import type { PromisedResult } from '@etta/core/type-utils'
import { AutocompletePlacesError } from '../core/errors/autocomplete-places.errors'
import type { AutocompletePlacesErrorInstances } from '../core/errors/autocomplete-places.errors'
import { GetAutocompleteDataProvider } from './get-autocomplete.data-provider'
import { mapGraphqlPlaces } from './mappers'
import type { GetPlacesDTO } from './types'

@Adapter()
export class GetAutocompleteAdapter {
  constructor(
    @Inject() private readonly getAutocompleteDataProvider: GetAutocompleteDataProvider,
  ) {}

  async getPlaces(dto: GetPlacesDTO): PromisedResult<Place[], AutocompletePlacesErrorInstances> {
    try {
      const { error, data } = await this.getAutocompleteDataProvider.getPlaces(dto)
      if (error) {
        return Result.Err(new AutocompletePlacesError.UnexpectedError(error))
      }

      const places = mapGraphqlPlaces(data.autocompletePlaces).filter(
        (place) => place.placeType === PlaceAutocompleteType.GooglePlaces,
      )

      return Result.Ok(places)
    } catch (e) {
      return Result.Err(new AutocompletePlacesError.UnexpectedError(e))
    }
  }
}
