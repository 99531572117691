import { Action, Inject } from '@etta/di'
// TODO: remove fiji import
import type { TimeRange, CabinClassType, Dates, Place, SwitchPlacesProps } from '@fiji/types'
// TODO: remove fiji import
import type { Flight, FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { AirSearchFormInputsService } from '../services/air-search-form/air-search-form-inputs.service'
import { AirSearchFormService } from '../services/air-search-form/air-search-form.service'

@Action()
export class AirSearchFormInputsActions {
  constructor(
    @Inject()
    private readonly airSearchFormInputsService: AirSearchFormInputsService,
    @Inject()
    private readonly airSearchFormService: AirSearchFormService,
  ) {}

  createOriginPlaceChnage(legNumber: number) {
    return (place: Place) =>
      this.airSearchFormInputsService.handleOriginPlaceChange(legNumber, place)
  }

  createDestinationPlaceChnage(legNumber: number) {
    return (place: Place) =>
      this.airSearchFormInputsService.handleDestinationPlaceChange(legNumber, place)
  }

  createDateChange(legNumber: number) {
    return (result: Dates) => this.airSearchFormInputsService.handleDateChange(legNumber, result)
  }

  createTimeChange(legNumber: number) {
    return (time: TimeRange) => this.airSearchFormInputsService.handleTimeChange(legNumber, time)
  }

  createReturnTimeChnage(legNumber: number) {
    return (time: TimeRange) =>
      this.airSearchFormInputsService.handleReturnTimeChange(legNumber, time)
  }

  createCabinClassChange(legNumber: number) {
    return (cabinClass: CabinClassType) =>
      this.airSearchFormInputsService.handleCabinClassChange(legNumber, cabinClass)
  }

  createRemoveFlight(id: number) {
    return () => this.airSearchFormService.handleRemoveFlight(id)
  }

  switchPlaces({
    legNumber,
    flightSearchTimeRange,
    flightSearchTimeRangeNonUS,
  }: SwitchPlacesProps) {
    return () =>
      this.airSearchFormService.handleSwitchPlaces({
        legNumber,
        flightSearchTimeRange,
        flightSearchTimeRangeNonUS,
      })
  }

  addFlightToSearch() {
    this.airSearchFormService.handleAddFlightToSearch()
  }

  changeType(type: FlightType) {
    this.airSearchFormService.flightTypeChange(type)
  }

  getHighlightedDates(excludedDate?: Date) {
    return this.airSearchFormService.getHighlightedDates(excludedDate)
  }

  checkIsErrorPlaces(legNumber: number) {
    return this.airSearchFormService.handleCheckIsErrorPlaces(legNumber)
  }

  checkIsDateRangeValid() {
    return this.airSearchFormService.checkIsDateRangeValid()
  }

  updateSearchFlightsEditCache() {
    this.airSearchFormService.updateSearchFlightsEditCache()
  }

  dropEditCacheAndSwitchToViewMode() {
    this.airSearchFormService.dropEditCacheAndSwitchToViewMode()
  }

  handleSetFlights(flights: Flight[], flightType: FlightType) {
    this.airSearchFormInputsService.handleChangeFlights({ flights, flightType })
  }

  handleDestinationPlaceChange(place: Place) {
    this.airSearchFormInputsService.handleDestinationPlaceChange(0, place)
  }

  handleDateChange(result: Dates) {
    this.airSearchFormInputsService.handleDateChange(0, result)
  }
}
