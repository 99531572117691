import type { HeaderColors } from '@etta/ui/header'
import { RowMessageItem } from '../row-message-item'

type Props = {
  title: string
  message: string
  color: HeaderColors
  hasMultipleItems: boolean
  hasDesktopBannerImage: boolean
  shouldExpandMessages: boolean
  onClose: () => void
}

export function MessageItem({
  color,
  title,
  message,
  hasMultipleItems,
  hasDesktopBannerImage,
  shouldExpandMessages,
  onClose,
}: Props) {
  return (
    <RowMessageItem
      iconName="infoFilledPWA"
      iconColor={color}
      title={title}
      message={message}
      hasMultipleItems={hasMultipleItems}
      hasDesktopBannerImage={hasDesktopBannerImage}
      shouldExpandMessages={shouldExpandMessages}
      isClosable
      onClose={onClose}
    />
  )
}
