import type { DefaultTheme, ThemedStyledProps } from 'styled-components'
import styled from 'styled-components'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import { footnoteMedium } from '@fiji/style'

type GetSeatSizeProps = {
  isEconomyClass: boolean
  actualRowLength: number
}

function getSeatSize(
  props: ThemedStyledProps<
    Pick<
      DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      'key' | keyof HTMLAttributes<HTMLDivElement>
    > &
      GetSeatSizeProps,
    DefaultTheme
  >,
): number {
  switch (props.actualRowLength) {
    case 8:
      return props.isEconomyClass ? 32 : 38
    case 9:
      return props.isEconomyClass ? 28 : 32
    case 10:
      return props.isEconomyClass ? 26 : 27
    case 11:
      return props.isEconomyClass ? 22 : 24
    case 12:
      return props.isEconomyClass ? 20 : 20
    default:
      return props.isEconomyClass ? 36 : 46
  }
}

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 0px;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
`

export const Container = styled.div<GetSeatSizeProps>`
  text-align: center;
  min-width: ${getSeatSize}px;
  max-width: ${getSeatSize}px;
  max-height: ${getSeatSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const SeatRowNumber = styled.span<GetSeatSizeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${getSeatSize}px;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors.seatMap.charcoal2};

  ${footnoteMedium}
`
