/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Momentum } from '../../../swiping-time-picker-components'
import type { SwipingPickerSubtype } from '../../../types'
import { AmPm } from './selected-time-styled'

type Props = {
  hour: number
  minute: number
  ampm: number
  minuteGroup: number
  variant?: SwipingPickerSubtype
}

export function SelectedTime({ hour, minute, ampm, minuteGroup, variant }: Props): JSX.Element {
  const { t } = useTranslation()
  const basePath = 'SwipingDateTimePicker.'
  const HourAndMin = () => {
    switch (variant) {
      case 'only-hour':
        return <>{(hour && hour < 10 ? '0' : '') + Math.abs(hour % 12 || 12)}</>
      case 'only-min':
        return <>{(minute < 10 ? '0' : '') + minute}</>
      default:
        return (
          <>
            {(hour && hour < 10 ? '0' : '') + Math.abs(hour % 12 || 12)}:
            {minute * minuteGroup || '00'}
          </>
        )
    }
  }

  const useFormattedTime = () => {
    const formattedHourValue = useMemo(
      () => (hour && hour < 10 ? '0' : '') + Math.abs(hour % 12 || 12),
      [hour],
    )
    const formattedMinuteValue = useMemo(() => minute * minuteGroup || '00', [minute, minuteGroup])
    const formattedAmPmValue = useMemo(() => (ampm ? t(basePath + 'Pm') : t(basePath + 'Am')), [
      ampm,
    ])

    if (variant === 'only-min') {
      return `${formattedMinuteValue}`
    }
    if (variant === 'only-hour') {
      return `${formattedHourValue} ${formattedAmPmValue}`
    }
    return `${formattedHourValue}:${formattedMinuteValue} ${formattedAmPmValue}`
  }

  return (
    <Momentum aria-label={`${t('Accessibility.CurrentValue')}: ${useFormattedTime()}`}>
      <HourAndMin />{' '}
      {variant !== 'only-min' && <AmPm>{ampm ? t(basePath + 'Pm') : t(basePath + 'Am')}</AmPm>}
    </Momentum>
  )
}
