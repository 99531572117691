import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace CheckoutInfoErrors {
  export const TripNotFoundError = createErrorClass('Trip Not Found Error')
  export const UnexpectedError = createErrorClass('Unexpected Error')
}

export type CheckoutInfoErrorsInstances = InstanceType<
  typeof CheckoutInfoErrors[keyof typeof CheckoutInfoErrors]
>
