import type { PropsWithChildren } from 'react'
import { CSSTransition } from 'react-transition-group'
import { AnimatedWrapper } from './modal-animation-styled'

type Props = {
  isOpen?: boolean
}
type ComponentProps = PropsWithChildren<Props>

const duration = 300

export function ModalAnimation({ isOpen, children }: ComponentProps) {
  return (
    <CSSTransition in={isOpen} timeout={duration} appear mountOnEnter unmountOnExit>
      {(state) => (
        <AnimatedWrapper transitionDuration={duration} className={state}>
          {children}
        </AnimatedWrapper>
      )}
    </CSSTransition>
  )
}
