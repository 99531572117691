import styled from 'styled-components'

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  max-width: 320px;
`

export const Body = styled.div`
  padding: 0 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const NDCFareAcknowledgmentContainer = styled.div`
  padding: 8px 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`
