import { useEffect } from 'react'
import { useSettingsContext } from '@etta/modules/settings/interface/use-settings-context'

export function useProfileFieldsSettings() {
  const { baseSettingsService } = useSettingsContext()

  useEffect(() => {
    baseSettingsService.loadProfileFieldsSettings()
  }, [baseSettingsService])
}
