import { useTranslation } from 'react-i18next'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Text } from '@etta/ui/text'
import { Badge } from '@etta/ui/badge'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { StarRating } from '@etta/ui/star-rating'
import { formatRate } from '@fiji/utils/money'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { SegmentWrapper, SegmentLogo, HotelReviewWrapper } from './print-itinerary-modal.styled'

type Props = {
  segment: HotelPostBookingValueObject
}

export function HotelSegment({ segment }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const {
    imageUrl,
    name,
    address,
    phone,
    roomType,
    status,
    checkIn,
    checkOut,
    averageRate,
    numberOfGuests,
    confirmationNumber,
    starRating,
    membership,
    specialRequests,
    preferredDescription,
  } = segment

  const addressLine = [
    address?.street1,
    address?.street2,
    address?.city,
    address?.stateCode,
    address?.postalCode,
    address?.countryCode,
  ]
    .filter(Boolean)
    .join(', ')

  const contactLine = [addressLine, phone].join(' • ')

  const roomLine = [
    roomType ? t(i18nBase + 'Room', { roomType }) : '',
    averageRate
      ? t(i18nBase + 'PerNight', { rate: formatRate(averageRate, { morpheme: 'prefix' }).mainCost })
      : '',
    numberOfGuests?.adults ? t(i18nBase + 'Guests', { guests: numberOfGuests?.adults }) : '',
  ]
    .filter(Boolean)
    .join(' • ')

  const customBadge = preferredDescription?.customBadge.name

  return (
    <SegmentWrapper>
      {imageUrl && <SegmentLogo src={imageUrl} alt={name || ''} />}
      <Text variant="subHeadStrong" color="mainText">
        {name}
      </Text>
      {customBadge && <Badge variant="customPreferred" iconSize="small" label={customBadge} />}
      {!customBadge && preferredDescription?.code && (
        <Badge variant="preferredVendor" iconSize="small" />
      )}
      <Text variant="footnoteMedium" color="mainText">
        {contactLine}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {checkIn &&
          t(i18nBase + 'CheckIn', {
            date: dateFormat(new Date(deleteTimezone(checkIn)), 'ccc, LLL d, yyyy'),
          })}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {checkOut &&
          t(i18nBase + 'CheckOut', {
            date: dateFormat(new Date(deleteTimezone(checkOut)), 'ccc, LLL d, yyyy'),
          })}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {roomLine}
      </Text>
      {membership?.map(({ vendorName, number }, index) => (
        <Text key={number} variant="footnoteMedium" color="mainText">
          {index === 0 && <>{t(i18nBase + 'Membership')}:&nbsp;</>}
          {vendorName} - {number}
        </Text>
      ))}
      {specialRequests && specialRequests.length > 0 && (
        <Text variant="footnoteMedium" color="mainText">
          {t(i18nBase + 'SpecialRequests')}: {specialRequests?.join(', ')}
        </Text>
      )}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'ReservationNo')}:&nbsp;
        <Text variant="footnoteStrong" color="mainText">
          {confirmationNumber}
        </Text>
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Status')}: {status && capitalizeFirstLetter(status.split('_').join(' '))}
      </Text>
      <HotelReviewWrapper>
        {starRating ? (
          <>
            <StarRating rating={starRating} color={'warning'} />
            <Text variant="footnoteMedium" color="mainText">
              {t(i18nBase + 'StarRating.Title')}
            </Text>
            <Text variant="captionMedium" color="bodyText">
              {t(i18nBase + `StarRating.Description.${starRating}`)}
            </Text>
          </>
        ) : null}
      </HotelReviewWrapper>
    </SegmentWrapper>
  )
}
