import { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { formatMinutes } from '@fiji/utils/dates/format-minutes'

type Args = {
  date?: string | number | Date | null
  shouldTimerBeActive?: boolean
  handleClose: () => void
}

export function useCountdownTimer({ date, shouldTimerBeActive = true, handleClose }: Args) {
  const [isTimerExpired, setIsTimerExpired] = useState(false)

  const { hours, minutes, seconds, pause, restart, isRunning } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      pause()
      setIsTimerExpired(true)
    },
    autoStart: false,
  })

  const countdownTimer = formatMinutes({ hours, minutes, seconds })

  useEffect(() => {
    if (!shouldTimerBeActive) {
      setIsTimerExpired(false)
    }
  }, [shouldTimerBeActive])

  useEffect(() => {
    if (date && !isRunning && shouldTimerBeActive) {
      restart(new Date(date))
      return
    }

    if (isRunning && !shouldTimerBeActive) {
      pause()
      return
    }

    if (!date && !isTimerExpired) {
      pause()
      handleClose()
    }
  }, [date, restart, pause, isRunning, isTimerExpired, shouldTimerBeActive, handleClose])

  return {
    countdownTimer,
    isTimerExpired,
  }
}
