import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useGlobalNavigationLinks } from '@fiji/hooks/navigation'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useLogOut } from '@fiji/hooks/use-log-out'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useGetUnusedTickets } from '@fiji/hooks/use-get-unused-tickets'
import { ROUTES } from '@fiji/routes'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import { useUserContext } from '@etta/modules/user'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useNavigationContext } from '../../interface/use-navigation-context'
import { useSiteSwitcher } from './use-site-switcher'

const i18Base = 'TripPlanner.MainNavigation'

export const useDesktopNavigation = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { handleSiteSwitch, switchModalSlot } = useSiteSwitcher()
  const { userStore } = useUserContext()
  const { customerId, delegatedId, profile, isSelfServiceSite } = userStore
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { tripsLink, exploreLink, supportLink, isExploreActiveHandle } = useGlobalNavigationLinks()

  const { delegateStore } = useDelegateContext()
  const { navigationStore } = useNavigationContext()
  const { navigationModalToggle } = navigationStore

  const { onLogout } = useLogOut()

  const externalLinks = displayConfigurationStore.displayConfiguration.externalLinks

  const currentUser = {
    fullName: [profile?.firstName, profile?.lastName].join(' '),
    email: profile?.personalContact?.email,
  }

  const handleNavigateProfile = () => {
    navigationModalToggle.handleClose()
    history.push(ROUTES.profile.main)
  }

  const {
    isOpen: unusedModalIsOpen,
    handleClose: handleCloseUnusedModal,
    handleOpen: handleOpenUnusedTicketsModal,
  } = useTogglePopup()

  const handleOpenUnusedModal = () => {
    navigationModalToggle.handleClose()
    handleOpenUnusedTicketsModal()
  }

  const handleOpenDelegateModal = () => {
    navigationStore.navigationModalToggle.handleClose()
    delegateStore.delegateModalToggle.handleOpen()
  }

  const { unusedTickets, isLoading: unusedTicketsIsLoading } = useGetUnusedTickets()

  const unusedTicketsCount = unusedTickets ? unusedTickets.length : 0

  const bookLinkText = t(`${i18Base}.Book`)
  const navigationItems = [
    {
      title: bookLinkText,
      to: exploreLink,
      isActive: isExploreActiveHandle,
    },
    { title: t(`${i18Base}.Trips`), to: tripsLink, isActive: undefined },
    { title: t(`${i18Base}.Support`), to: supportLink, isActive: undefined },
  ]

  const {
    featureFlags: {
      isDesktopDelegatedFlowEnabled,
      isDelegatedFlowEnabled,
      isDesktopTripCartEnabled,
    },
  } = useFeatureFlags()

  const isTripCartEnabled = isDesktopTripCartEnabled
  const isDelegatesExist = !!customerId && !!userStore.profile?.authorizers?.length
  const isDelegateSwitchEnabled =
    isDelegatedFlowEnabled && isDesktopDelegatedFlowEnabled && isDelegatesExist
  const isInDelegateMode = !!delegatedId

  return {
    isTripCartEnabled,
    isDelegateSwitchEnabled,
    isInDelegateMode,
    isSelfServiceSite,
    handleSiteSwitch,
    exploreLink,
    bookLinkText,
    navigationItems,
    switchModalSlot,
    isNavigationModalOpen: navigationModalToggle.isOpen,
    currentUser,
    handleNavigateProfile,
    onLogout,
    unusedTickets,
    unusedTicketsCount,
    unusedTicketsIsLoading,
    unusedModalIsOpen,
    handleCloseUnusedModal,
    handleOpenUnusedModal,
    handleOpenDelegateModal,
    externalLinks,
  }
}
