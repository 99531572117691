import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { BookTripErrors } from '@etta/modules/booking/core/errors'
import { ActionTrackingService } from '@etta/modules/action-tracker'
import { BookTripAgainErrors } from '@etta/modules/post-booking/core/errors/book-trip-again.errors'
import type { BookTripAgainResults, BookTripAgainArgs } from './types'
import { BookTripAgainDataProvider } from './book-trip-again.data-provider'

const ACTION_NAME = 'book-trip-again'

@Adapter()
export class BookTripAgainAdapter {
  constructor(
    @Inject() private readonly bookTripAgainDataProvider: BookTripAgainDataProvider,
    @Inject() private readonly actionTrackingService: ActionTrackingService,
  ) {}

  async handleBookTripAgain({
    startDate,
    endDate,
    processId,
    rebookSegmentTypes,
    customFields,
  }: BookTripAgainArgs): BookTripAgainResults {
    try {
      const { data, errors } = await this.bookTripAgainDataProvider.handleBookTripAgain({
        startDate,
        endDate,
        alignSegmentTypes: rebookSegmentTypes,
        processId,
        customFields,
      })

      if (errors || !data) {
        return Result.Err(new BookTripAgainErrors.BookTripAgainResponse(errors))
      }

      this.actionTrackingService.track({ status: 'success', name: ACTION_NAME })
      return Result.Ok({
        itineraryId: data.bookAgainTrip.itinerary.itineraryId,
        itineraryMetadata: { ...data.bookAgainTrip.itineraryMetadata, startDate, endDate },
      })
    } catch (e) {
      return Result.Err(new BookTripErrors.UnexpectedErrorBookTrip(e))
    }
  }
}
