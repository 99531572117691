import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: 24px;

  @media (${screenSize.maxMobile}) {
    flex-wrap: wrap;
    padding: 0;
  }
`

export const TripImage = styled.img`
  width: 268px;
  height: 122px;
  border-radius: 14px;

  @media (${screenSize.maxMobile}) {
    width: 100%;
    height: auto;
    border-radius: 14px 14px 0 0;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (${screenSize.maxMobile}) {
    flex-direction: column;
    gap: 0;
  }
`

export const ContentDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${screenSize.maxMobile}) {
    padding: 16px;
  }
`

export const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 5px 0;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`
