import type { TicketAttributes } from '@fiji/types'
import { FareCardAttributes } from './fare-card/fare-card-attributes'

type Props = {
  amenities?: TicketAttributes[]
}

export function FareAmenities({ amenities }: Props) {
  return amenities ? <FareCardAttributes ticketAttributes={amenities} /> : <></>
}
