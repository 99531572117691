import styled, { css } from 'styled-components'
import { Layout } from '@etta/ui/layout'
import { subHeadMedium } from '@fiji/style'

export const ModalHeaderWrapper = styled(Layout.Header)`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const SeparatorLine = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.borderDark};
  margin: 0;
`

export const ModalBodyContainer = styled.div`
  min-height: 230px;
  max-height: 550px;
  padding: 16px 24px;
  margin-top: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.borderDark};
  }
`

export const Container = styled.div`
  min-height: 230px;
  max-height: 550px;
  padding: 0 36px;
  margin-top: 2px;
  margin-right: 8px;
  overflow: hidden auto;

  &::-webkit-scrollbar-track {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.borderDark};
  }
`

export const Content = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`

const imageBannerStyles = css`
  img {
    // We are overwriting the original height and width to make the image stretch nicely
    width: 100% !important;
    height: auto !important;
  }
`

export const Message = styled.span<{
  hasDesktopBannerImage?: boolean | null
}>`
  word-wrap: break-word;
  color: ${({ theme }) => theme.colors.bodyText};
  ${subHeadMedium};

  ${({ hasDesktopBannerImage }) => hasDesktopBannerImage && imageBannerStyles}

  p {
    margin: unset;
  }

  // We overwrite the text to make sure it is the same color, font-family, and weight in every theme
  * {
    color: ${(p) => p.theme.colors.bodyText} !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
  }
  br {
    content: '';
  }
  br::after {
    content: '';
    display: block;
    margin: 5px;
  }
  a {
    color: ${(p) => p.theme.colors.primary} !important;
  }
`
