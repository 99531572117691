import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { OfflineBlock } from '@etta/components/offline-block'
import { Button } from '@etta/ui/button'
import { WarningContainer } from './error-container-styled'

type Props = {
  title?: string
  reloadTitle?: string
  onReload: () => void
  ariaLabel?: string
  align?: 'default' | 'central'
}

export function ErrorContainer({
  title,
  reloadTitle,
  onReload,
  ariaLabel,
  align = 'default',
}: Props) {
  const { t } = useTranslation()
  const isCentralAlign = align === 'central'

  return (
    <WarningContainer data-tracking-id="error-container">
      <OfflineBlock onButtonClick={onReload}>
        <Icon name="warningFilledPWA" size="medium" />
        <Block marginTop={3} marginBottom={isCentralAlign ? 0 : 12} marginHorizontal={32}>
          <Text variant="subHeadMedium" color="mainText" align="center" as="p">
            {title || t('ErrorContainer.Title')}
          </Text>
        </Block>
        <Button
          variant="link"
          onClick={onReload}
          data-tracking-id="reload-button"
          aria-label={ariaLabel}
          size="small">
          {reloadTitle || t('ErrorContainer.ReloadTitle')}
        </Button>
      </OfflineBlock>
    </WarningContainer>
  )
}
