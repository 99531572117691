import { Service, Inject } from '@etta/di'
import { TravelSupportAdapter } from '../../infra/travel-support.adapter'
import { TravelSupportStore } from '../stores/travel-support.store'

@Service()
export class TravelSupportService {
  constructor(
    @Inject() private travelSupportStore: TravelSupportStore,
    @Inject() private travelSupportAdapter: TravelSupportAdapter,
  ) {}

  async getDocument360SupportArticleById(articleId: string) {
    this.travelSupportStore.setIsSupportArticleLoading(true)
    const result = await this.travelSupportAdapter.getDocument360SupportArticleById({
      id: articleId,
    })

    result.match({
      Ok: (supportArticle) => {
        this.travelSupportStore.setSupportArticle(supportArticle)
        this.travelSupportStore.setIsSupportArticleLoading(false)
      },
      Err: () => {
        this.travelSupportStore.setIsSupportArticleLoading(false)
      },
    })
  }
}
