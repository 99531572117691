import type {
  MealRequestOption,
  AirMembershipOption,
  AirSpecialRequestOption,
  MealRequestValue,
  AirSpecialRequestValue,
  AirMembershipValue,
} from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import type { PreferenceOptions } from '@etta/modules/booking/core/value-objects/purchase-info-details.value-object'
import type { LoyaltyProgramValueObject } from '@etta/modules/travel-preferences/core/value-objects/loyalty-program.value-object'
import type { LoyaltyProgram } from '@fiji/graphql/hooks'

export const mapMealOption = (mealOption: MealRequestOption): PreferenceOptions => {
  return {
    label: mealOption.mealName || '',
    value: mealOption.code,
  }
}

export const mapAirMembershipOption = (
  airMembershipOption: AirMembershipOption,
): PreferenceOptions => {
  return {
    label: airMembershipOption.carrierName || '',
    value: airMembershipOption.carrierCode,
  }
}

export const mapAirSpecialRequestOption = (
  airSpecialRequestOption: AirSpecialRequestOption,
): PreferenceOptions => {
  return {
    label: airSpecialRequestOption.name || '',
    value: airSpecialRequestOption.value,
  }
}

export const mapMealRequestValue = (mealRequestValue: MealRequestValue): PreferenceOptions => {
  return {
    label: mealRequestValue.mealName || '',
    value: mealRequestValue.code,
  }
}

export const mapAirSpecialRequestValue = (
  airSpecialRequestValue: AirSpecialRequestValue,
): PreferenceOptions => {
  return { label: airSpecialRequestValue.name || '', value: airSpecialRequestValue.value }
}

export const mapAirMembershipValue = (
  airMembershipValue: AirMembershipValue,
): PreferenceOptions => {
  return {
    label: airMembershipValue.carrierName || '',
    value: airMembershipValue.carrierCode,
  }
}

export const mapAirLoyaltyProgram = (membership: AirMembershipValue): LoyaltyProgramValueObject => {
  return {
    vendorCode: membership.carrierCode,
    number: membership.number,
    label: membership.carrierName,
  }
}

export const toSpecialRequestValues = (
  codes: string[],
  options: AirSpecialRequestOption[],
): AirSpecialRequestValue[] => {
  return options.reduce<AirSpecialRequestValue[]>((acc, option) => {
    if (codes.includes(option.value)) {
      return [...acc, { name: option.name, value: option.value, id: 'md_air_special_request' }]
    }
    return acc
  }, [])
}

export const toMealValue = (
  code: string | undefined,
  options: MealRequestOption[],
): MealRequestValue | null => {
  if (!code) {
    return null
  }
  const meal = options.find((item) => item.code === code)

  return meal ? { code: meal.code, mealName: meal.mealName, id: 'md_air_meal' } : null
}

export const toAirMembership = (
  programs: LoyaltyProgram[],
  options: AirMembershipOption[],
): AirMembershipValue[] => {
  return options.reduce<AirMembershipValue[]>((acc, option) => {
    const program = programs.find((program) => program.vendorCode === option.carrierCode)
    if (program) {
      return [
        ...acc,
        {
          carrierCode: option.carrierCode,
          carrierName: option.carrierName,
          id: 'md_air_membership',
          number: program.number || '',
        },
      ]
    }
    return acc
  }, [])
}
