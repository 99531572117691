import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const StyledButton = styled(Button)`
  padding: 0 8px;

  &:disabled {
    color: ${({ theme }) => theme.colors.borderDark};
  }
`
