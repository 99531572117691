import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'
import { Checkbox } from '@etta/ui/checkbox'
import type { BookAgainTravelVerticalEnum } from '@etta/modules/post-booking/core/enums'
import {
  Container,
  TextContainer,
  Title,
  Description,
} from './book-trip-again-modal-segment-styled'

export type Props = {
  title: ReactNode
  description: string
  segmentType: BookAgainTravelVerticalEnum
  isChecked: boolean
  onChange: (value: boolean, segmentType: BookAgainTravelVerticalEnum) => void
}

export function BookTripAgainModalSegment({
  title,
  description,
  isChecked,
  onChange,
  segmentType,
}: Props) {
  return (
    <Container>
      <Block marginTop={-6}>
        <Checkbox
          checked={isChecked}
          onChange={(value) => onChange(value, segmentType)}
          value=""
          label={<></>}
        />
      </Block>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  )
}
