export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M99.9999 22.8789C99.9999 33.2101 91.6231 41.5853 81.2935 41.5853C70.962 41.5853 62.5872 33.2101 62.5872 22.8789C62.5872 12.5477 70.962 4.17249 81.2935 4.17249C91.6231 4.17249 99.9999 12.5477 99.9999 22.8789Z"
        fill="#EFEFF4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.1555 23.0437C96.1555 31.1603 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1603 66.7598 23.0437C66.7598 14.9254 73.3397 8.34497 81.4568 8.34497C89.5755 8.34497 96.1555 14.9254 96.1555 23.0437Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.745 13.774C81.1965 12.9596 82.3229 12.9596 82.7743 13.774L90.2367 27.2361C90.6888 28.0518 90.1249 29.0723 89.2221 29.0723H74.2973C73.3944 29.0723 72.8305 28.0518 73.2826 27.2361L80.745 13.774ZM80.8506 18.1632L80.8505 22.7087L81.7597 23.6178L82.6687 22.7087L82.6688 18.1632H80.8506ZM80.6803 25.9296C80.6805 25.3238 81.1636 24.8325 81.7597 24.8325C82.3558 24.8325 82.8391 25.3239 82.839 25.9298C82.8389 26.5356 82.3556 27.0268 81.7594 27.0268C81.1634 27.0268 80.6802 26.5353 80.6803 25.9296Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.3956 36.161V28.0596H58.9727V36.161H55.5792C55.5792 34.4995 54.1954 32.7854 52.5251 32.7854H32.8431C31.1986 32.7854 29.789 34.5251 29.789 36.161H26.3956ZM62.0268 40.8868C63.1514 40.8868 64.0629 41.7936 64.0629 42.9122V60.1276L60.3301 55.0643H25.0381L21.3054 60.1276V42.9122C21.3054 41.7936 22.217 40.8868 23.3415 40.8868H62.0268Z"
        fill="white"
      />
    </svg>
  )
}
