import { Result } from 'fnscript'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { MarkerOptions } from '../../core/marker.options'
import type { Coords } from '../../core/coords'
import type { Bounds } from '../../core/bounds'
import type { PolygonOptions } from '../../core/polygon'
import type { CreateRouteArgs, CreateRouteResult, CreateRouteOptions } from '../../core/route'
import type { EttaMapEntity } from '../../core/etta-map.entity'
import { GoogleMapHelpers } from './google-map.helpers'

export class EttaGoogleMap implements EttaMapEntity {
  private directionsService?: google.maps.DirectionsService
  private directionRenderer?: google.maps.DirectionsRenderer
  private polygon?: google.maps.Polygon
  private instance: google.maps.Map
  private markers: google.maps.Marker[]

  constructor(instance: google.maps.Map) {
    this.instance = instance
    this.markers = []
  }

  public createRoute({
    origin,
    destination,
    travelMode,
    options,
  }: CreateRouteArgs): Promise<CreateRouteResult> {
    const directionsService = this.getGoogleDirectionService()
    const directionRenderer = this.getGoogleDirectionRenderer(options)

    return new Promise((resolve) => {
      directionsService.route(
        {
          origin: GoogleMapHelpers.getPlaceFromRoute(origin),
          destination: GoogleMapHelpers.getPlaceFromRoute(destination),
          travelMode: GoogleMapHelpers.getTravelMode(travelMode),
        },
        (result, status) => {
          if (status !== google.maps.DirectionsStatus.OK) {
            resolve(Result.Err('Directions request failed due to ' + status))
          }

          directionRenderer.setOptions({ preserveViewport: true })
          directionRenderer.setDirections(result)

          const response = GoogleMapHelpers.getRouteResultFromGoogleDirection(result)
          resolve(Result.Ok(response))
        },
      )
    })
  }

  public removeRoute(): void {
    this.directionRenderer?.setMap(null)
    this.directionRenderer = undefined
  }

  public get hasRoutes(): boolean {
    const routes = this.directionRenderer?.getDirections()?.routes
    if (routes) {
      return routes.length > 0
    }
    return Boolean(routes)
  }

  public panBy(x: number, y: number): void {
    this.instance.panBy(x, y)
  }

  public setZoom(zoom: number): void {
    this.instance.setZoom(zoom)
  }

  public getZoom(): number {
    return this.instance.getZoom()
  }

  public async zoomOn(zoom: number, duration: number = 1000): Promise<void> {
    const stepDuration = duration / Math.abs(zoom - this.getZoom())
    if (zoom > this.getZoom()) {
      await this.zoomIn(zoom, this.getZoom(), Math.min(stepDuration, 200))
    } else {
      await this.zoomOut(zoom, this.getZoom(), Math.min(stepDuration, 200))
    }
  }

  public panTo(lat: number, lng: number): void {
    const coords = new google.maps.LatLng(lat, lng)
    this.instance.panTo(coords)
  }

  public stopRouting() {
    this.directionRenderer?.setMap(null)
  }

  public startRouting() {
    this.directionRenderer?.setMap(this.instance)
  }

  public onBoundsChange(cb: () => void) {
    this.instance.addListener('bounds_changed', cb)
  }

  public getBounds(): Bounds | undefined {
    const bounds = this.instance.getBounds()

    if (!bounds) {
      return
    }

    return this.getBoundsFromGoogleBounds(bounds)
  }

  public createBoundsByCoordinates(coordinates: Coords[]): Bounds {
    const bounds = GoogleMapHelpers.createBoundsByCoordinates(coordinates)
    return this.getBoundsFromGoogleBounds(bounds)
  }

  public setBounds(
    bounds: Bounds,
    padding?: { top: number; left: number; bottom: number; right: number },
  ): void {
    const googleBounds = new google.maps.LatLngBounds(bounds.sw, bounds.ne)
    this.instance.fitBounds(googleBounds, padding)
  }

  public setRoutingBounds(padding?: {
    top: number
    left: number
    bottom: number
    right: number
  }): void {
    if (!this.hasRoutes) {
      return
    }
    const directionRenderer = this.getGoogleDirectionRenderer()
    const directions = directionRenderer.getDirections()
    // During runtime, it is possible that directions is undefined
    // due to the directionRenderer does not have routes data.
    if (!directions) {
      return
    }
    const directionBound = GoogleMapHelpers.getDirectionBoundFromGoogleDirection(directions)
    if (directionBound) {
      this.instance.fitBounds(directionBound, padding)
    }
  }

  public checkIsCoordinatesEqual(v1: Coords, v2: Coords): boolean {
    return GoogleMapHelpers.checkIsCoordinatesEqual(v1, v2)
  }

  public checkOutOfRange(args: {
    boundsCenter: Coords
    mapCenter: Coords
    boundsDistanceMeters?: number | undefined
  }): boolean {
    const { boundsCenter, mapCenter, boundsDistanceMeters } = args

    return GoogleMapHelpers.checkOutOfRange({
      boundsCenter,
      mapCenter,
      boundsDistanceMeters,
    })
  }

  public getCenter(): Coords {
    const center = this.instance.getCenter()
    return this.getCoordsFromGoogleCoords(center)
  }

  public setPolygon(coords: Coords[], options?: PolygonOptions): void {
    if (this.polygon) {
      this.clearPolygon()
    }

    this.polygon = new google.maps.Polygon({
      ...options,
      paths: coords,
    })

    this.polygon.setMap(this.instance)
  }

  public clearPolygon(): void {
    this.polygon?.setMap(null)
    this.polygon = undefined
  }

  public setMarkers(coordinates: Coords[], options?: MarkerOptions): void {
    const { tobeUpdateMarkers, newMarkersCoord } = this.getMarkers(coordinates)
    this.markers = this.markers.filter((marker) => !tobeUpdateMarkers.includes(marker))

    tobeUpdateMarkers.forEach((marker) => {
      const newIcon = GoogleMapHelpers.createMarkerIcon(options)
      if (marker.getIcon() !== newIcon) {
        marker.setIcon(newIcon)
        this.markers.push(marker)
      }
    })

    newMarkersCoord.forEach((coord) => {
      const marker = GoogleMapHelpers.createMarker(coord, options)
      marker.setMap(this.instance)
      this.markers.push(marker)
    })
  }

  public clearMarkers(): void {
    this.markers.forEach((marker) => {
      marker.setMap(null)
    })
    this.markers = []
  }

  private getBoundsFromGoogleBounds(googleBounds: google.maps.LatLngBounds): Bounds {
    return {
      sw: googleBounds.getSouthWest().toJSON(),
      ne: googleBounds.getNorthEast().toJSON(),
      center: googleBounds.getCenter().toJSON(),
    }
  }

  private getCoordsFromGoogleCoords(googleCoords: google.maps.LatLng): Coords {
    return {
      lat: googleCoords.lat(),
      lng: googleCoords.lng(),
    }
  }

  private getGoogleDirectionService(): google.maps.DirectionsService {
    if (!this.directionsService) {
      this.directionsService = new google.maps.DirectionsService()
    }

    return this.directionsService
  }

  private getGoogleDirectionRenderer(options?: CreateRouteOptions): google.maps.DirectionsRenderer {
    if (!this.directionRenderer) {
      this.directionRenderer = new google.maps.DirectionsRenderer(options)
    }

    return this.directionRenderer
  }

  private async zoomOut(zoomLevel: number, nextZoomLevel: number, duration: number): Promise<void> {
    if (nextZoomLevel <= zoomLevel) {
      return
    }

    this.setZoom(nextZoomLevel)

    await delayForSuccess(duration)
    await this.zoomOut(zoomLevel, nextZoomLevel - 1, duration)
  }

  private async zoomIn(zoomLevel: number, nextZoomLevel: number, duration: number): Promise<void> {
    if (nextZoomLevel >= zoomLevel) {
      return
    }

    this.setZoom(nextZoomLevel)

    await delayForSuccess(duration)
    await this.zoomIn(zoomLevel, nextZoomLevel + 1, duration)
  }

  private getMarkers(coordinates: Coords[]) {
    const tobeUpdateMarkers: google.maps.Marker[] = []
    const newMarkersCoord: Coords[] = []
    coordinates.forEach((coord) => {
      const matched = this.markers.find((marker) => {
        const position = marker.getPosition()
        if (!position) {
          return false
        }
        return GoogleMapHelpers.checkIsCoordinatesEqual(
          coord,
          this.getCoordsFromGoogleCoords(position),
        )
      })
      if (matched) {
        tobeUpdateMarkers.push(matched)
      } else {
        newMarkersCoord.push(coord)
      }
    })

    return { tobeUpdateMarkers, newMarkersCoord }
  }
}
