import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { PhoneSupport, EmailSupport } from '@fiji/graphql/types'
import { Skeleton } from '@etta/ui/skeleton'
import {
  SupportContactsContainer,
  SupportContactsWrapper,
  SupportContactsSubTitleWrapper,
  SupportContactsSubTitle,
  SupportContactsSubTitleDescription,
} from '../support-page-components'
import { SupportContactOptions } from './support-contact-options'
import { SupportContactGetHelpNow } from './support-contact-get-help-now/support-contact-get-help-now'

type Props = {
  phoneNumbers?: PhoneSupport[]
  email?: EmailSupport | null
  isLoading: boolean
}

export function SupportContacts({ phoneNumbers, email, isLoading }: Props) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support'

  const phoneNumbersList = useMemo(
    () =>
      phoneNumbers?.map((phoneNumber) => ({
        label: phoneNumber.label,
        value: phoneNumber.phoneNumber,
      })) || [],
    [phoneNumbers],
  )
  const emailsList = useMemo(
    () =>
      email
        ? [
            {
              label: email.label,
              value: email.emailAddress,
            },
          ]
        : [],
    [email],
  )

  const hasPhoneNumbers = phoneNumbersList.length > 0
  const hasEmails = emailsList.length > 0

  return (
    <SupportContactsContainer>
      <SupportContactsSubTitleWrapper>
        <SupportContactsSubTitle variant="title3" as={'h3'}>
          {t(i18Base + '.MainPage.SubTitle')}
        </SupportContactsSubTitle>
        <SupportContactsSubTitleDescription variant="footnoteMedium">
          {t(i18Base + '.MainPage.SubTitleDescription')}
        </SupportContactsSubTitleDescription>
      </SupportContactsSubTitleWrapper>

      <SupportContactsWrapper>
        {isLoading && (
          <>
            <Skeleton width="45%" height={72} variant="secondary1">
              <rect width="100%" height={72} />
            </Skeleton>
            <Skeleton width="45%" height={72} variant="secondary1">
              <rect width="100%" height={72} />
            </Skeleton>
          </>
        )}
        {hasPhoneNumbers && (
          <SupportContactOptions
            title={t(i18Base + '.PhoneOptions.Title')}
            contacts={phoneNumbersList}
            isPhoneContacts
          />
        )}

        {hasEmails && (
          <SupportContactOptions title={t(i18Base + '.EmailOptions.Title')} contacts={emailsList} />
        )}
      </SupportContactsWrapper>
      <SupportContactGetHelpNow />
    </SupportContactsContainer>
  )
}
