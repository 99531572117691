import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import type { Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'

type Args = {
  flightDirection: string
  flightRate?: Rate
  feesAndTaxesRate?: Rate
  totalRate?: Rate
  transactionFeeRate?: Rate
  unusedTicketRate?: Rate | null
  unusedTicketAmount?: Rate | null
  unusedTicketForfeiture?: Rate | null
  serviceFeeRate?: Rate | null
}
export function useFlightDetails({
  flightDirection,
  flightRate,
  feesAndTaxesRate,
  transactionFeeRate,
  totalRate,
  unusedTicketRate,
  unusedTicketAmount,
  unusedTicketForfeiture,
  serviceFeeRate,
}: Args) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetailsModal.PriceDetails.'

  const unusedTicketValue = useMemo(() => {
    if (!unusedTicketRate) {
      return null
    }

    const negativeSymbol = '-'

    return negativeSymbol + formatRate(unusedTicketRate, { morpheme: 'none' }).mainCost
  }, [unusedTicketRate])
  const priceDetailsList = useMemo(() => {
    const detailsList = [
      {
        title: flightDirection,
        value: formatRate(flightRate, { morpheme: 'none' }).mainCost,
      },
    ]

    if (feesAndTaxesRate) {
      detailsList.push({
        title: t(i18nBase + 'TaxesAndFees'),
        value: formatRate(feesAndTaxesRate, { morpheme: 'none' }).mainCost,
      })
    }

    if (transactionFeeRate) {
      detailsList.push({
        title: t(i18nBase + 'TransactionFee'),
        value: formatRate(transactionFeeRate, { morpheme: 'none' }).mainCost,
      })
    }

    if (serviceFeeRate) {
      detailsList.push({
        title: t(i18nBase + 'ServiceFee'),
        value: formatRate(serviceFeeRate, { morpheme: 'none' }).mainCost,
      })
    }

    if (unusedTicketValue) {
      detailsList.push({
        title: t(i18nBase + 'UnusedTicketPrice'),
        value: unusedTicketValue,
      })
    }

    if (unusedTicketValue) {
      detailsList.push({
        title: t(i18nBase + 'UnusedTicketForfeiture'),
        value: formatRate(unusedTicketForfeiture, { morpheme: 'none' }).mainCost,
      })
    }

    return detailsList
  }, [
    flightDirection,
    flightRate,
    unusedTicketValue,
    feesAndTaxesRate,
    transactionFeeRate,
    t,
    unusedTicketForfeiture,
    serviceFeeRate,
  ])

  const totalRateTitle = formatRate(unusedTicketAmount || totalRate, { morpheme: 'none' }).mainCost

  return {
    totalRateTitle,
    priceDetailsList,
  }
}
