import type { TravelTime, Maybe, SearchFlightLegSubSegment, Rate } from '@fiji/graphql/types'
import type { SegmentType } from '@etta/core/enums'
import type { TimeRange, CabinClassType, Place } from '@fiji/types'
import type { PostBookingAction } from '@fiji/enums'
import type { AirFiltersType } from '@etta/modules/air-search/core/value-objects/air-filters'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'

export type AirSearchQueryType = {
  flights: Flight[]
  selectedFlights: SelectedFlight[]
  searchId: string | null
  itineraryId: string
  areNearbyAirportsIncluded: boolean
  flightType: FlightType
  bookingId?: string
  filters?: AirFiltersType
  activeLegId: string
  selectedTab?: SegmentType
  searchRestrictions?: AirSearchRestrictions
  previousLegFilters?: AirFiltersType
  nextLegFilters?: AirFiltersType
  postBookingAction?: PostBookingAction
  returnFlightSearchStartDate?: string // allowedSearchDateRange in trip return flight rule from post booking (trip detail)
  returnFlightSearchEndDate?: string
  isReturnFlightChangeOnly?: boolean
  customFields?: CustomFieldBookingValueObject[]
}

export enum FlightType {
  OneWay = 'Oneway',
  Round = 'Round',
  MultiCity = 'MultiCity',
}

export type Flight = {
  id: number
  originPlace?: Place
  destinationPlace?: Place
  timeRange: TimeRange
  departureDate?: Date
  cabinClass: CabinClassType
  returnDate?: Date
  returnTimeRange: TimeRange
}

export type Stop = {
  code: SearchFlightLegSubSegment['destination']['code']
  name: SearchFlightLegSubSegment['destination']['name']
  flightTime: string
}

export type SelectedFlight = {
  id: string
  selectedFareTier: string
  selectedFareTierName: string
  selectedFareId: string
  selectedServiceClasses: string[]
  selectedBrandedFare: string
  selectedSeats: SegmentSeatNumber[]
  isRefundable?: Maybe<boolean>
  departurePlaceCode: string
  returnPlaceCode: string
  flightTime: TravelTime
  stops: Stop[]
  flightLogos: string[]
  arrivalDate: string
  departureDate: string
  headerTitle: string
  totalTime: TravelTime
  isOutOfPolicy?: Maybe<boolean>
  totalPrice: Rate
  segments: Array<SelectedFlightSegmentInfo>
}

type SelectedFlightSegmentInfo = {
  carrierLogoUrl: string
  carrierName: string
  flightNumber: number
  preferredBadgeText: string
  isPreferredAny: boolean
  codeshareCarrierName: string
}

export enum AirSearchRestrictions {
  NoCarrierNoDestinationChange = 'NoCarrierNoDestinationChange',
  NoDestinationChange = 'NoDestinationChange',
  NoCarrierChange = 'NoCarrierChange',
}
