import * as R from 'ramda'
import type { Address, CarLocationDetails } from '@fiji/graphql/types'

export function formatPickUpDropOffAddress(
  address: Address,
  carLocationDetails?: CarLocationDetails,
): string {
  const { street1, street2, city, stateCode, postalCode, locationName } = address

  const airportAddress =
    carLocationDetails &&
    R.ifElse(R.hasPath(['code']), R.path(['code']), () => address.airportCode)(carLocationDetails)

  const addressWithoutDuplicates = R.union(
    [locationName],
    [airportAddress, street1, street2, city, stateCode, +postalCode! === 0 ? '' : postalCode],
  )
  return R.pipe(
    // @ts-ignore
    R.filter((v) => Boolean(v)),
    R.join(', '),
    // @ts-expect-error
  )([...addressWithoutDuplicates])
}
