import styled from 'styled-components'

export const CallEHIContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 13px 15px;
  border: 2px solid ${(props) => props.theme.colors.borderLight};
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;

  > :first-child {
    margin-right: 6px;
  }
`
