import type {
  SeatMapRowValueObject,
  SeatMapRowSeatValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import type { SegmentSeatNumber } from '../air-seat-map.types'

type Args = {
  selectedSegmentId: string
  seatRowsBySegment: SeatMapRowValueObject[]
  selectedSegmentsSeatNumbers: SegmentSeatNumber[]
}

export function getSeatsForApprove({
  seatRowsBySegment,
  selectedSegmentsSeatNumbers,
  selectedSegmentId,
}: Args): SeatMapRowSeatValueObject[] {
  const checkedSeatNumbers = selectedSegmentsSeatNumbers
    .filter((seats) => seats.segmentId === selectedSegmentId)
    .map((selectedSeats) => selectedSeats.seatNumber)

  return seatRowsBySegment.reduce<SeatMapRowSeatValueObject[]>((results, seatRow) => {
    const findSeat = seatRow.seat.find((seat) => checkedSeatNumbers.includes(seat.number))
    if (findSeat) {
      results.push(findSeat)
    }
    return results
  }, [])
}
