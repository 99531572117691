import { Block } from '@etta/ui/block'
import { Skeleton } from '@etta/ui/skeleton'

type Props = {
  isHeader?: boolean
}

export function MobilityZoneSelectionSkeleton({ isHeader }: Props) {
  if (isHeader) {
    return (
      <Block>
        <Skeleton width={264} height={20} variant="secondary1">
          <rect x={0} y={0} width={191} height={14} />
        </Skeleton>
      </Block>
    )
  }

  return (
    <Block marginHorizontal={24} marginTop={20}>
      <Skeleton width={264} height={60} variant="secondary1">
        <rect y={0} width={34} height={34} />
        <rect x={50} y={0} width={61} height={14} />
        <rect x={50} y={26} width={191} height={14} />
      </Skeleton>
    </Block>
  )
}
