import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Separator } from '@etta/ui/separator'
import { Tooltip } from '@etta/ui/tooltip'
import type { RailAction } from '../types/props'
import { Wrapper, RightBlock, ActionBlock } from './rail-action-styled'

type Props = {
  action: RailAction
  isDesktop?: boolean
}

export function RailActions({ action, isDesktop }: Props) {
  if (isDesktop) {
    return (
      <Tooltip
        slot={
          <Text variant="footnoteMedium" color="white" preLine>
            {action.tooltipText}
          </Text>
        }
        isIndependentOfParentWidth
        align="left"
        place="top">
        <ActionBlock isFlexBlock marginTop={0} isDisabled={!!action.isDisabled}>
          {action.icon && (
            <Block marginRight={4}>
              <Icon name={action.icon} color={action.isDisabled ? 'bodyText1' : 'primary1'} />
            </Block>
          )}
          <Link
            size="small"
            onClick={action.onClick}
            isDisabled={action.isDisabled}
            disabledLinkColor="bodyText1">
            {action.buttonText}
          </Link>
        </ActionBlock>
      </Tooltip>
    )
  }

  return (
    <>
      <Separator lineType="dashed" color="borderLight" />
      <Wrapper onClick={action.onClick}>
        {action.icon && (
          <Icon
            name={action.icon}
            color={action.isDisabled ? 'bodyText1' : 'primary1'}
            size="small"
          />
        )}
        <Text
          variant="linkSmall"
          color={action.isDisabled ? 'bodyText1' : 'primary1'}
          noWrap
          textOverflow="ellipsis">
          {action.buttonText}
        </Text>
        {!action.isDisabled && (
          <RightBlock>
            <Icon name="chevronRightPWA" color="bodyText" />
          </RightBlock>
        )}
      </Wrapper>
    </>
  )
}
