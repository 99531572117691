import { useTranslation } from 'react-i18next'
import type { TripStateStatus } from '@etta/components/trip-status-modal/types'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ScreenType, useScreenType } from '@fiji/modes'
import { ROUTES } from '@fiji/routes'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { PostBookingAction } from '@fiji/enums'

type Props = {
  tripStateStatus: TripStateStatus
  onClick?: () => void
  soldOutDescription?: string
}
export function TripStateDetails({ tripStateStatus, onClick, soldOutDescription }: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'TripStatus'
  const screenType = useScreenType()
  const { airSearchFormQueryService, airSearchQueryStore } = useAirSearchContext()
  const { searchQueryFlightType: flightType } = airSearchQueryStore
  const { queryParams } = useAirSearchQuery()
  const { previousLegFilters, postBookingAction } = queryParams

  const toOutboundSearch = () => {
    const route = ROUTES.air.results({ legNumber: 0 })
    airSearchFormQueryService.navigateTo(route, {
      filters: previousLegFilters,
    })
  }

  const toExplore = () => {
    const isMobile = screenType === ScreenType.Mobile
    const route = isMobile ? ROUTES.air.search : ROUTES.explore

    airSearchFormQueryService.navigateTo(route, {
      flights: airSearchQueryStore.searchQueryFlights,
      flightType: airSearchQueryStore.searchQueryFlightType,
    })
  }

  const title = () => {
    switch (tripStateStatus) {
      case 'sold-out':
        return t(i18nBasePath + '.SoldOut.FareSoldOut')
      case 'fare-reprice-fail':
        return t(i18nBasePath + '.SoldOut.FareValidationError')
      case 'change-return-flight-not-allowed':
        return t(i18nBasePath + '.ChangeFlightNotAllow.Title')
    }
  }

  const description = () => {
    switch (tripStateStatus) {
      case 'sold-out':
        return soldOutDescription ?? ''
      case 'fare-reprice-fail':
        return t(i18nBasePath + '.SoldOut.FareValidationErrorDescription')
      case 'change-return-flight-not-allowed':
        return t(i18nBasePath + '.ChangeFlightNotAllow.Description')
    }
  }

  const isModifyReturn = postBookingAction === PostBookingAction.ModifyReturn
  const actionButton = () => {
    switch (tripStateStatus) {
      case 'sold-out':
      case 'fare-reprice-fail':
        return (
          <>
            <Block isFullWidth marginTop={24}>
              <Button
                onClick={
                  flightType === FlightType.Round && !isModifyReturn ? toOutboundSearch : onClick
                }
                fullWidth>
                {t(i18nBasePath + '.SoldOut.SelectAnotherFareBtn')}
              </Button>
            </Block>
            <Block isFullWidth marginTop={16}>
              <Button onClick={toExplore} variant="outline" fullWidth>
                {t(i18nBasePath + '.SoldOut.SearchAgainBtn')}
              </Button>
            </Block>
          </>
        )
      case 'change-return-flight-not-allowed':
        return (
          <Block isFullWidth marginTop={24}>
            <Button onClick={onClick} fullWidth>
              {t(i18nBasePath + '.ChangeFlightNotAllow.ButtonText')}
            </Button>
          </Block>
        )
    }
  }

  return (
    <>
      <Text variant="title3" color="mainText" data-tracking-id={`${tripStateStatus}-title`}>
        {title()}
      </Text>
      <Block marginTop={24}>
        <Text
          variant="footnoteMedium"
          color="bodyText"
          align="center"
          data-tracking-id={`${tripStateStatus}-description`}>
          {description()}
        </Text>
      </Block>
      {actionButton()}
    </>
  )
}
