import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { SpaceBetween } from '../../../rtp-price-summary-styled'
import { SectionDescription } from '../../payable-sections-styled'

type Props = {
  totalHotelTripCost: string
  hasHotelTripCost: boolean
}

export function HotelsSection({ totalHotelTripCost, hasHotelTripCost }: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'ReviewTrip.PriceSummary'

  if (!hasHotelTripCost) {
    return null
  }

  return (
    <>
      <Block marginTop={8} />
      <SpaceBetween>
        <SectionDescription aria-label={t(i18nBasePath + '.Hotels')}>
          {t(i18nBasePath + '.Hotels')}
        </SectionDescription>
        <SectionDescription aria-label={totalHotelTripCost}>
          {totalHotelTripCost}
        </SectionDescription>
      </SpaceBetween>
    </>
  )
}
