import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

const HORIZONTAL_PADDING = 32

export const SubTitle = styled.h3`
  ${subHeadStrong}
  margin-bottom:12px;
  &:last-of-type {
    margin-top: 24px;
  }
`

export const ListOfStations = styled.ul`
  padding: 0;
`

export const Station = styled.li`
  list-style: none;
  padding: 18px 0;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: calc(100% + ${HORIZONTAL_PADDING}px);
    height: 1px;
    background: ${(props) => props.theme.colors.borderLight};
    left: 0;
    bottom: 0;
    z-index: 2;
  }
`
