import { lazy } from 'react'
import { ROUTES, getDocumentTitle } from '@fiji/routes'
import type { RouteItem } from '@fiji/routes/types'

const LoginEmail = lazy(() => import('./login-email'))
const LoginSite = lazy(() => import('./login-site'))
const LoginPassword = lazy(() => import('./login-password'))
const LoginQuestion = lazy(() => import('./login-question'))
const SelectMethod = lazy(() => import('./select-method'))
const BeginSsoPrompt = lazy(() => import('./begin-sso-prompt'))

export const authRoutes: Array<RouteItem> = [
  {
    route: {
      path: `${ROUTES.login.email}/(sso)?`,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isNavBarHidden: true,
    isSkipAuth: true,
    transitionIndex: 1,
    transitionGroup: 'fade-through',
    component: () => <LoginEmail />,
  },
  {
    route: {
      path: ROUTES.login.selectSite,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isSkipAuth: true,
    isNavBarHidden: true,
    transitionIndex: 1.2,
    transitionGroup: 'slide',
    component: () => <LoginSite />,
  },
  {
    route: {
      path: ROUTES.login.selectMethod,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isSkipAuth: true,
    isNavBarHidden: true,
    transitionIndex: 1.3,
    transitionGroup: 'slide',
    component: () => <SelectMethod />,
  },
  {
    route: {
      path: ROUTES.login.password,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isSkipAuth: true,
    isNavBarHidden: true,
    transitionIndex: 1.4,
    transitionGroup: 'slide',
    component: () => <LoginPassword />,
  },
  {
    route: {
      path: ROUTES.login.beginSsoPrompt,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isSkipAuth: true,
    isNavBarHidden: true,
    transitionIndex: 1.4,
    transitionGroup: 'slide',
    component: () => <BeginSsoPrompt />,
  },
  {
    route: {
      path: ROUTES.login.question,
      exact: true,
    },
    documentTitle: getDocumentTitle('Login'),
    isSkipAuth: true,
    isNavBarHidden: true,
    transitionIndex: 1.5,
    transitionGroup: 'slide',
    component: () => <LoginQuestion />,
  },
]
