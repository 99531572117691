import { useTranslation } from 'react-i18next'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { CollectGuideView } from '@etta/components/rail-delivery-option/rail-delivery-guide-modal/collect-guide-view'

const i18nBase = 'PostBooking.TripDetails.RailGuideModal.'

export type Props = {
  onClose: () => void
}

export function RailDeliveryGuideModal({ onClose }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <CheckoutPageModal.Header title={t(i18nBase + 'CollectGuideTitle')} onClose={onClose} />
      <CheckoutPageModal.Body>
        <CollectGuideView />
      </CheckoutPageModal.Body>
    </>
  )
}
