import styled from 'styled-components'
import { headline } from '@fiji/style'

export const ActionButton = styled.button<{
  isCancel?: boolean
  isDownload?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;

  ${(p) => {
    if (p.isDisabled) {
      return `
        opacity: 0.4;
        pointer-events: none;
      `
    }

    return 'cursor: pointer;'
  }}

  color: ${(p) =>
    p.isCancel
      ? p.theme.colors.error
      : p.isDownload
      ? p.theme.colors.primary
      : p.theme.colors.mainText};
  line-height: 22px;
  padding: 19px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderDark};
  ${headline}
`

export const ButtonContainer = styled.div`
  padding: 0 24px;
  &:active {
    background-color: ${(p) => p.theme.colors.background1};
  }
`
