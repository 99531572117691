import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const CardBlock = styled(UnstyledButton)<{ isError: boolean; noCreditCard: boolean }>`
  text-align: left;
  padding: 2px 16px;
  width: 100%;
  margin-top: 12px;
  background-color: ${(p) => p.theme.colors.white};
  border: ${(p) =>
    p.isError || p.noCreditCard
      ? `1px solid ${p.theme.colors.error}`
      : `1px solid ${p.theme.colors.borderDark}`};
  border-radius: 10px;

  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }
`
