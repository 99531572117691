import type { MultiLayerCostAllocation, MultiLayerCostAllocationUnit } from '@fiji/graphql/types'
import type { CostAllocationEntity } from '../../core/entities/cost-allocation.entity'
import type { CostAllocationResultValueObject } from '../../core/value-objects/cost-allocation-result.value-object'

export function toCostAllocationResultValueObject(
  input: MultiLayerCostAllocation,
): CostAllocationResultValueObject {
  return {
    costAllocation: input.allocation.map(toCostAllocationEntity),
    totalResults: input.totalResults ?? undefined,
  }
}

function toCostAllocationEntity(input: MultiLayerCostAllocationUnit): CostAllocationEntity {
  return {
    name: input.name,
    segmentId: input.segmentId,
    allocationId: input.allocationId,
    childrenRef: input.childrenRef ?? undefined,
    parentRef: input.parentRef ?? undefined,
    code: input.code ?? undefined,
    stats: input.stats
      ? {
          activeChildren: input.stats.activeChildren ?? undefined,
        }
      : undefined,
  }
}
