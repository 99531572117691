import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { useCarRentalPolicyReasons } from '@etta/components/car-rental-details/use-car-rental-policy-reasons'
import type { Policy } from '@fiji/graphql/types'
import {
  CarOutOfPolicyTitle,
  CarOutOfPolicyContainer,
  OutOfPolicy,
  OutOfPolicyReasonTextList,
  OutOfPolicyReasonText,
  OutOfPolicyReasonContainer,
} from './car-details-mobile-oop-reasons-styled'

type Props = {
  isOutOfPolicy: boolean
  carPolicy?: Policy
}

export function CarDetailsMobileOopReasons({ carPolicy, isOutOfPolicy }: Props) {
  const { t } = useTranslation()
  const { isOnePolicyReason, outOfPolicyTexts } = useCarRentalPolicyReasons(carPolicy)

  if (!isOutOfPolicy) {
    return null
  }

  return (
    <CarOutOfPolicyContainer isInline={isOnePolicyReason}>
      <CarOutOfPolicyTitle>
        <Icon name="outOfPolicyPWA" size="small" />
        <OutOfPolicy>
          {t('CarRentalDetails.OutOfPolicy')}:
          {isOnePolicyReason && outOfPolicyTexts && (
            <OutOfPolicyReasonText>{outOfPolicyTexts[0]}</OutOfPolicyReasonText>
          )}
        </OutOfPolicy>
      </CarOutOfPolicyTitle>
      {!isOnePolicyReason && outOfPolicyTexts && (
        <OutOfPolicyReasonContainer>
          {outOfPolicyTexts.map((text) => (
            <OutOfPolicyReasonTextList key={text}>{text}</OutOfPolicyReasonTextList>
          ))}
        </OutOfPolicyReasonContainer>
      )}
    </CarOutOfPolicyContainer>
  )
}
