import { useCallback, useState } from 'react'
import { CustomFocusedInput } from './types'

type Args = {
  defaultFocusedInputActive?: CustomFocusedInput
}

export function useDateTimePickerControls({ defaultFocusedInputActive }: Args) {
  const [focusedInput, setFocusedInput] = useState<CustomFocusedInput>(
    defaultFocusedInputActive ?? CustomFocusedInput.date,
  )

  const toggleFocusedInput = useCallback(() => {
    if (focusedInput === CustomFocusedInput.time) {
      setFocusedInput(CustomFocusedInput.date)
    }
    if (focusedInput === CustomFocusedInput.date) {
      setFocusedInput(CustomFocusedInput.time)
    }
  }, [focusedInput])

  const changeFocusedInput = useCallback((focusedInput) => {
    setFocusedInput(focusedInput)
  }, [])

  return {
    focusedInput,
    toggleFocusedInput,
    changeFocusedInput,
  }
}
