import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'
import type { ModalContent, ModalType } from './types'
import { PartnerCoBrandingConfigModalType } from './types'

export function usePartnerCoBrandingConfigDSMModal() {
  const { t } = useTranslation()
  const { partnerCoBrandingConfigurationsStore } = usePartnerCoBrandingConfigurationsContext()
  const {
    privacyPolicyLabel,
    privacyPolicyLinkUrl,
    privacyPolicyEnabled,
    privacyPolicyLinkEnabled,
    privacyPolicyText,
    termsOfUse,
    termsOfUseLabel,
    termsOfUseLinkEnabled,
    termsOfUseLinkUrl,
  } = partnerCoBrandingConfigurationsStore.partnerCoBrandingConfigurations

  const enablePrivacyPolicyModal = useMemo(
    () =>
      privacyPolicyEnabled &&
      !privacyPolicyLinkEnabled &&
      !privacyPolicyLinkUrl &&
      privacyPolicyText,
    [privacyPolicyEnabled, privacyPolicyLinkEnabled, privacyPolicyLinkUrl, privacyPolicyText],
  )

  const enableTermsOfUseModal = useMemo(
    () => !termsOfUseLinkEnabled && !termsOfUseLinkUrl && termsOfUse,
    [termsOfUseLinkEnabled, termsOfUseLinkUrl, termsOfUse],
  )

  const [modalContent, setModalContent] = useState<ModalContent>({
    isVisible: false,
    header: '',
    text: '',
  })

  const handleOnClick = useCallback(
    (type: ModalType) => {
      if (
        (type === PartnerCoBrandingConfigModalType.Privacy && !enablePrivacyPolicyModal) ||
        (type === PartnerCoBrandingConfigModalType.Terms && !enableTermsOfUseModal)
      ) {
        return
      }

      const modalSettings = {
        [PartnerCoBrandingConfigModalType.Privacy]: {
          isVisible: true,
          header: privacyPolicyLabel ? privacyPolicyLabel : t('Profile.PrivacyPolicy'),
          text: privacyPolicyText ?? '',
        },
        [PartnerCoBrandingConfigModalType.Terms]: {
          isVisible: true,
          header: termsOfUseLabel ? termsOfUseLabel : t('Profile.TermsOfUse'),
          text: termsOfUse ?? '',
        },
      }

      setModalContent(modalSettings[type])
    },
    [
      enablePrivacyPolicyModal,
      enableTermsOfUseModal,
      privacyPolicyLabel,
      t,
      privacyPolicyText,
      termsOfUseLabel,
      termsOfUse,
    ],
  )

  const closeModal = useCallback(() => {
    setModalContent((prev) => ({ ...prev, isVisible: false }))
  }, [])

  const messages = useMemo(
    () => [
      {
        header: modalContent.header,
        isDialog: true,
        mobileText: modalContent.text,
        text: modalContent.text,
        hasDesktopBannerImage: false,
      },
    ],
    [modalContent],
  )

  return {
    messages,
    modalContent,
    handleOnClick,
    closeModal,
    privacyPolicyLabel,
    privacyPolicyLinkUrl,
    termsOfUseLabel,
    termsOfUseLinkUrl,
    enablePrivacyPolicyModal,
    enableTermsOfUseModal,
  }
}
