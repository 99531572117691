import styled from 'styled-components'
import { captionStrong } from '@fiji/style'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 224px;
  max-height: 309px;
  top: 55px;
  right: 0;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  z-index: 20;
`

export const DesktopNavigationCompanyResoursesModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 360px;
  top: 55px;
  right: 180px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  z-index: 20;
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const UserInfoText = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'headline',
}))<{ marginTop?: number }>`
  margin-bottom: 3px;
`

export const MenuItem = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`

export const UnusedBadge = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.mainText2};
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 6px;
  ${captionStrong}
`
