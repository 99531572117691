import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Text } from '@etta/ui/text'
import {
  SubheaderWrapper,
  SubheaderContent,
  TitleWrapper,
  TabsWrapper,
} from './trips-page-desktop-styled'

export function Subheader({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation()
  const {
    featureFlags: { isDesktopHeldTripsFlowEnabled },
  } = useFeatureFlags()
  const subheaderTitle = t('Trips.TitleShort')

  return (
    <SubheaderWrapper>
      <SubheaderContent>
        <TitleWrapper>
          <Text as={'h2'} variant={'title1'}>
            {subheaderTitle}
          </Text>
        </TitleWrapper>
        <TabsWrapper hasExtraTabs={!!isDesktopHeldTripsFlowEnabled}>{children}</TabsWrapper>
      </SubheaderContent>
    </SubheaderWrapper>
  )
}
