import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { RailCardWrapper } from './rail-card-styled'

type Props = {
  railCardName: string
}

const i18nBase = 'PaymentSummary.'

export function RailCard({ railCardName }: Props) {
  const { t } = useTranslation()

  return (
    <RailCardWrapper paddingTop={12} marginTop={13}>
      <Icon name="loyaltyCardPWA" color="success" />
      <Text variant="footnoteMedium" color="mainText">
        {`${railCardName} `}
      </Text>
      <Text variant="footnoteMedium" color="bodyText">
        {t(`${i18nBase}Applied`)}
      </Text>
    </RailCardWrapper>
  )
}
