import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { InputButton } from '@etta/ui/input-button'
import { RailCardModal } from '@etta/components/rail-card-modal/rail-card-modal'
import { useRailCard } from '../../use-rail-cards'
import type { Props } from '../../types'

export const RailCardsMobile = observer(function RailCard({ railCards, layout }: Props) {
  const {
    railCardModal,
    placeHolder,
    ariaLabel,
    currentUKCard,
    currentEUCards,
    handleSelectUKCard,
    handleSelectEUCard,
    cardGroups,
    railCardsLoading,
    errorText,
    isCardSelected,
    handleClearCardSelection,
  } = useRailCard({ railCards, layout })

  return (
    <>
      <Block paddingHorizontal={16} paddingVertical={6}>
        <InputButton
          value={currentUKCard?.name || currentEUCards}
          aria-label={ariaLabel}
          onClick={railCardModal.handleOpen}
          placeholder={placeHolder}
          iconRight={isCardSelected ? 'closePWA' : undefined}
          iconRightSize={isCardSelected ? 'medium' : undefined}
          iconRightColor={isCardSelected ? 'bodyText' : undefined}
          onRightIconClick={isCardSelected ? handleClearCardSelection : undefined}
        />
      </Block>

      <RailCardModal // TODO: AC of VIVA-2944 not to show saved cards. Add here savedCards from useRailCard hook later.
        isOpen={railCardModal.isOpen}
        onClose={railCardModal.handleClose}
        layout={layout}
        cardGroups={cardGroups}
        onSelectUKCard={handleSelectUKCard}
        onSelectEUCard={handleSelectEUCard}
        cardsLoading={railCardsLoading}
        errorText={errorText}
      />
    </>
  )
})
