import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  RemoveCarRentalMutationVariables,
  ReplaceCarRentalMutationVariables,
  AddCarRentalMutationVariables,
  UpdateTripNameMutationVariables,
  SelectRoomMutationVariables,
  ReplaceRoomMutationVariables,
  RemoveRoomMutationVariables,
  ChangeSeatsAssignmentMutationVariables,
} from '@fiji/graphql/hooks'
import {
  AddFlightDocument,
  ChangeFlightDocument,
  RemoveFlightSegmentDocument,
  GetItineraryDocument,
  CreateItineraryDocument,
  AddCarRentalDocument,
  RemoveCarRentalDocument,
  ReplaceCarRentalDocument,
  RemoveItineraryDocument,
  UpdateTripNameDocument,
  SelectRoomDocument,
  ReplaceRoomDocument,
  RemoveRoomDocument,
  AddRailDocument,
  RemoveRailSegmentDocument,
  AlignTripDocument,
  ChangeSeatsAssignmentDocument,
  ReactivateItineraryDocument,
} from '@fiji/graphql/hooks'
import type {
  GetItineraryQuery,
  GetItineraryQueryVariables,
  CreateItineraryMutation,
  CreateItineraryInput,
  CreateItineraryMutationVariables,
  AddCarRentalMutation,
  RemoveCarRentalMutation,
  ReplaceCarRentalMutation,
  RemoveItineraryMutation,
  RemoveItineraryMutationVariables,
  AddFlightMutation,
  AddFlightMutationVariables,
  ChangeFlightMutation,
  ChangeFlightMutationVariables,
  RemoveFlightSegmentMutation,
  RemoveFlightSegmentMutationVariables,
  UpdateTripNameMutation,
  SelectRoomMutation,
  ReplaceRoomMutation,
  RemoveRoomMutation,
  AddRailMutationVariables,
  AddRailMutation,
  RemoveRailSegmentMutationVariables,
  RemoveRailSegmentMutation,
  ChangeSeatsAssignmentMutation,
  AlignTripMutationVariables,
  AlignTripMutation,
  ReactivateItineraryMutationVariables,
  ReactivateItineraryMutation,
} from '@fiji/graphql/types'
import { getRefetchItineraryOptions } from '@fiji/utils/refetch-options/get-refetch-itinerary-options'

@DataProvider()
export class ItineraryDataProvider extends GraphqlDataProvider {
  getItinerary(input: GetItineraryQueryVariables, forceUpdate?: boolean) {
    return this.client.query<GetItineraryQuery, GetItineraryQueryVariables>({
      query: GetItineraryDocument,
      variables: {
        itineraryId: input.itineraryId,
        deviceClass: input.deviceClass,
        imageOptions: input.imageOptions,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  createItinerary(input?: CreateItineraryInput) {
    return this.client.mutate<CreateItineraryMutation, CreateItineraryMutationVariables>({
      mutation: CreateItineraryDocument,
      variables: {
        input,
      },
    })
  }

  updateTripName(input: UpdateTripNameMutationVariables['input']) {
    return this.client.mutate<UpdateTripNameMutation, UpdateTripNameMutationVariables>({
      mutation: UpdateTripNameDocument,
      variables: {
        input,
      },
    })
  }

  removeItinerary(input: RemoveItineraryMutationVariables) {
    return this.client.mutate<RemoveItineraryMutation, RemoveItineraryMutationVariables>({
      mutation: RemoveItineraryDocument,
      variables: input,
    })
  }

  reactivateItinerary(input: ReactivateItineraryMutationVariables) {
    return this.client.mutate<ReactivateItineraryMutation, ReactivateItineraryMutationVariables>({
      mutation: ReactivateItineraryDocument,
      variables: input,
    })
  }

  addCarRental(input: AddCarRentalMutationVariables['input']) {
    return this.client.mutate<AddCarRentalMutation, AddCarRentalMutationVariables>({
      mutation: AddCarRentalDocument,
      variables: { input },
    })
  }

  replaceCarRental(input: ReplaceCarRentalMutationVariables['input']) {
    return this.client.mutate<ReplaceCarRentalMutation, ReplaceCarRentalMutationVariables>({
      mutation: ReplaceCarRentalDocument,
      variables: { input },
    })
  }

  removeCarRental(input: RemoveCarRentalMutationVariables) {
    return this.client.mutate<RemoveCarRentalMutation, RemoveCarRentalMutationVariables>({
      mutation: RemoveCarRentalDocument,
      variables: input,
    })
  }

  addFlight(input: AddFlightMutationVariables['input']) {
    return this.client.mutate<AddFlightMutation, AddFlightMutationVariables>({
      mutation: AddFlightDocument,
      variables: { input },
    })
  }

  changeFlight(input: ChangeFlightMutationVariables['input']) {
    return this.client.mutate<ChangeFlightMutation, ChangeFlightMutationVariables>({
      mutation: ChangeFlightDocument,
      variables: { input },
    })
  }

  removeFlight(input: RemoveFlightSegmentMutationVariables) {
    return this.client.mutate<RemoveFlightSegmentMutation, RemoveFlightSegmentMutationVariables>({
      mutation: RemoveFlightSegmentDocument,
      variables: input,
    })
  }

  changeFlightSeatsAssignment(input: ChangeSeatsAssignmentMutationVariables['input']) {
    return this.client.mutate<
      ChangeSeatsAssignmentMutation,
      ChangeSeatsAssignmentMutationVariables
    >({
      mutation: ChangeSeatsAssignmentDocument,
      variables: { input },
    })
  }

  selectRoom(input: SelectRoomMutationVariables) {
    return this.client.mutate<SelectRoomMutation, SelectRoomMutationVariables>({
      mutation: SelectRoomDocument,
      variables: input,
    })
  }

  replaceRoom(input: ReplaceRoomMutationVariables) {
    return this.client.mutate<ReplaceRoomMutation, ReplaceRoomMutationVariables>({
      mutation: ReplaceRoomDocument,
      variables: input,
    })
  }

  removeRoom({ itineraryId, roomKey }: RemoveRoomMutationVariables) {
    return this.client.mutate<RemoveRoomMutation, RemoveRoomMutationVariables>({
      mutation: RemoveRoomDocument,
      variables: { itineraryId, roomKey },
      awaitRefetchQueries: true,
      refetchQueries: [getRefetchItineraryOptions(itineraryId)],
    })
  }

  addRail(input: AddRailMutationVariables['input']) {
    return this.client.mutate<AddRailMutation, AddRailMutationVariables>({
      mutation: AddRailDocument,
      variables: { input },
    })
  }

  removeRail(input: RemoveRailSegmentMutationVariables) {
    return this.client.mutate<RemoveRailSegmentMutation, RemoveRailSegmentMutationVariables>({
      mutation: RemoveRailSegmentDocument,
      variables: input,
    })
  }

  alignTrip(input: AlignTripMutationVariables) {
    return this.client.mutate<AlignTripMutation, AlignTripMutationVariables>({
      mutation: AlignTripDocument,
      variables: input,
    })
  }
}
