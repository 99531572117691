import { Inject, Service } from '@etta/di'
import { TravelPreferencesStore } from '@etta/modules/travel-preferences'
import { PaymentSummaryStore } from '../../payment-summary/stores/payment-summary.store'
import type { BookTripArgs } from '../types'
import { formatCustomFields } from './format-custom-field'

@Service()
export class BookTripInputService {
  constructor(
    @Inject() private readonly travelPreferencesStore: TravelPreferencesStore,
    @Inject() private readonly paymentSummaryStore: PaymentSummaryStore,
  ) {}

  public buildBookTripInput({
    payment,
    putOnHold,
    bookingId,
  }: Pick<BookTripArgs, 'payment' | 'putOnHold' | 'bookingId'>): BookTripArgs {
    const { shareTravelerContact } = this.travelPreferencesStore

    const customFields = formatCustomFields(this.paymentSummaryStore.customFields)

    return {
      bookingId,
      putOnHold,
      payment,
      shareTravelerContact: shareTravelerContact ? shareTravelerContact : undefined,
      customFields,
    }
  }
}
