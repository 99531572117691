import { RailDetailsHeader } from '@etta/components/rail-details/rail-details-header'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { RailTicketRestrictionsContent } from '@etta/components/rail-ticket-restrictions/rail-ticket-restrictions-content'
import { RailDepartureAndArrivalOpenReturn } from '@etta/components/rail-departure-and-arrival-open-return'
import type { LayoutProps } from '../types'
import { RailTravelInformation } from '../../rail-travel-information'
import {
  Container,
  Section,
  RailDetailsSection,
} from './rail-details-ticket-restrictions-modal-desktop-styled'

export function RailDetailsTicketRestrictionsModalDesktop({
  headerTitle,
  carrierLogoUrl,
  railLeg,
  seatReservationType,
  isRailEticket,
  squareRailLogos,
  isSeatRequested,
  appliedRailCard,
  ticketRestrictions,
}: LayoutProps) {
  return (
    <>
      <Modal.Title withBorder>
        <RailDetailsHeader
          title={headerTitle}
          railLabels={[]}
          railLogo={carrierLogoUrl}
          squareRailLogos={squareRailLogos}
        />
      </Modal.Title>
      <Modal.Body>
        <Block paddingVertical={32} paddingHorizontal={20}>
          <RailDepartureAndArrivalOpenReturn rail={railLeg} />
        </Block>
        <Container>
          <Block paddingHorizontal={32}>
            <Section>
              <RailTravelInformation
                rail={railLeg}
                seatReservationType={seatReservationType}
                isRailEticket={isRailEticket}
                isSeatRequested={isSeatRequested}
                appliedRailCard={appliedRailCard}
              />
            </Section>
            <RailDetailsSection>
              <RailTicketRestrictionsContent
                ticketRestrictionsInfo={ticketRestrictions}
                needTicketRestrictionTitle={true}
              />
            </RailDetailsSection>
          </Block>
        </Container>
      </Modal.Body>
    </>
  )
}
