import { DateFieldLayout } from './layout'
import type { Props } from './types'
import { useDateField } from './use-date-field'

export function DateField(props: Props) {
  const dateFieldHookProps = useDateField({
    field: props.field,
    onChangeHandler: props.onChangeHandler,
  })

  return <DateFieldLayout {...props} {...dateFieldHookProps} />
}
