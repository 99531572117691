import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { SelectedBlockProps } from '../types'
import {
  UpperContainer,
  Container,
  FlexBlock,
  TextBlock,
  ImageContainer,
  Image,
} from './select-block-styled'

export function SelectBlock({
  imageUrl,
  title,
  descriptions,
  isSelected,
  onClick,
  isDisabled,
  footerSlot,
}: SelectedBlockProps) {
  return (
    <Container
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={isDisabled ? () => {} : onClick}>
      <UpperContainer>
        <Block marginRight={23}>
          <ImageContainer>
            <Image src={imageUrl} alt="" />
          </ImageContainer>
        </Block>
        <FlexBlock>
          <Text variant="title3" color="mainText">
            {title}
          </Text>
          <Block marginTop={16}>
            <TextBlock variant="subHeadMedium" color="bodyText">
              {descriptions}
            </TextBlock>
          </Block>
        </FlexBlock>
      </UpperContainer>
      {footerSlot}
    </Container>
  )
}
