import { action, makeObservable, observable } from 'mobx'

type OptionsType<T> = {
  initialValue: T
}

type Maybe<T> = T | undefined | null

export class LocalStorageItem<T> {
  value: Maybe<T>

  constructor(private readonly key: string, private readonly options?: OptionsType<T>) {
    this.value = this.getValue()
    window.addEventListener('storage', (event) => {
      if (!event.key || event.key === key) {
        this.value = this.getValue()
      }
    })

    makeObservable(this, {
      value: observable,
      set: action,
    })
  }

  set(value: T) {
    let data = (value as unknown) as string
    this.value = value
    try {
      if (typeof value !== 'string') {
        data = JSON.stringify(value) as string
      }
    } catch {
      data = (value as unknown) as string
    }
    window.localStorage.setItem(this.key, data)
    this.dispatchEvent()
  }

  private getValue(): Maybe<T> {
    const value = window.localStorage.getItem(this.key)
    let data = value
    try {
      if (value !== null) {
        data = JSON.parse(value)
      }
      if (typeof data === 'string') {
        data = value
      }
    } catch {
      data = value
    }
    if (data === undefined) {
      return this.options?.initialValue
    }
    return (data as unknown) as Maybe<T>
  }

  remove() {
    this.value = undefined
    window.localStorage.removeItem(this.key)

    return this.dispatchEvent()
  }

  private dispatchEvent() {
    window.dispatchEvent(new StorageEvent('storage', { key: this.key }))
  }
}
