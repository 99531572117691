import type { FunctionComponent, PropsWithChildren } from 'react'
import { Skeleton } from '@etta/ui/skeleton'
import { CarRentalMobileDivider } from '../car-rental-mobile-divider'
import { Container, Title, Content } from './car-rental-mobile-section-styled'

type Props = PropsWithChildren<{
  dataTrackingId?: string
  title?: string
  isLoading?: boolean
}>

type CompoundComponents = {
  Content: typeof Content
}

const TitleSkeleton = () => (
  <Skeleton width="100%" height="16">
    <rect x="0" y="0" width="150" height="100%" />
  </Skeleton>
)

export const CarRentalMobileSection: FunctionComponent<Props> & CompoundComponents = ({
  dataTrackingId,
  title,
  children,
  isLoading,
}) => (
  <Container data-tracking-id={dataTrackingId}>
    {title && (
      <>
        <Title role="heading" aria-level={5}>
          {isLoading ? <TitleSkeleton /> : title}
        </Title>
        <CarRentalMobileDivider marginSize="none" />
      </>
    )}
    {children}
  </Container>
)

CarRentalMobileSection.Content = Content
