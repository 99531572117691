import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { RangeResult } from '../types'
import { changeArrivalDate, changeReturnDate } from './change-date'

export function convertTrainToRangeRTP(trainSegments: TrainSegmentEntity[]): RangeResult {
  const destinationSegment = trainSegments[0].segments[trainSegments[0].segments.length - 1]

  const lastTrainSegment = trainSegments[trainSegments.length - 1]
  const firstTrainInLast = lastTrainSegment.segments[0]
  const lastSegment = lastTrainSegment.segments[lastTrainSegment.segments.length - 1]

  const isRoundTrip =
    trainSegments.length > 1 &&
    trainSegments[0].segments[0].departure.stationCode === lastSegment.arrival.stationCode

  return {
    from: {
      ...destinationSegment.arrival.address,
    },
    to: {
      ...destinationSegment.arrival.address,
    },
    ...(isRoundTrip
      ? {
          startDate: changeArrivalDate(destinationSegment.arrivalDate),
          endDate: changeReturnDate(firstTrainInLast.departureDate),
        }
      : {
          startDate: changeArrivalDate(trainSegments[0].arrivalDateTime),
          endDate: undefined,
        }),
  }
}
