import { SegmentType, IntentState, AlignState } from '@fiji/graphql/types'
import type { HotelSegmentEntity } from '../../../core/entity'
import { SegmentState } from '../../../core/enum/segment-state.enum'
import type { HotelDomain } from './types'

export class HotelDomainMapper {
  static toHotelSegmentEntity({
    domainObject,
    intentId,
    state,
    isInPolicy,
  }: {
    domainObject: HotelDomain
    intentId: string
    state: IntentState
    isInPolicy: boolean
  }): HotelSegmentEntity {
    const segmentState = state === IntentState.Modified ? SegmentState.Changed : undefined

    const {
      address,
      amenities,
      checkIn,
      checkOut,
      distance,
      hasDeposit,
      isCancellable,
      isPreferred,
      hotelId,
      medianReviewRating,
      name,
      photos,
      selectedRoom,
      starRating,
      chainCode,
      exclusiveAmenities,
      isEcoFriendly,
      logoPhotoUrl,
      phoneNumber,
    } = domainObject

    return {
      position: 0,
      uuid: intentId,
      type: SegmentType.Hotel,
      segmentState,
      address,
      amenities,
      cancellationPolicy: selectedRoom.cancellationPolicy,
      checkIn,
      checkOut,
      dailyRates: selectedRoom.dailyRates,
      exclusiveAmenities: exclusiveAmenities || [],
      hotelId,
      id: selectedRoom.id,
      imageUrl: logoPhotoUrl || '',
      isCancellable: !!isCancellable,
      name,
      numberOfGuests: {
        adults: selectedRoom.numberOfGuests || 1,
      },
      photos,
      policy: {
        isInPolicy,
      },
      rate: selectedRoom.rate ?? selectedRoom.averageRate,
      roomType: selectedRoom.type,
      totalCost: selectedRoom.totalCost,
      averageRate: selectedRoom.averageRate,
      baseRate: selectedRoom.baseRate,
      cancellationPolicyDescription: selectedRoom.cancellationPolicyDescription,
      distance,
      hasDeposit,
      isEcoFriendly,
      isPreferred,
      medianReviewRating,
      phone: phoneNumber,
      rateQualifier: selectedRoom.rateQualifier,
      roomCount: 1,
      roomDescription: selectedRoom.description,
      rulesAndRestrictions: [],
      source: selectedRoom.source,
      starRating,
      vendorCode: chainCode,
      feesAndTaxesRate: {
        primary: selectedRoom.feesAndTaxes,
      },
      preferredDescription: undefined,
      totalCostRate: {
        primary: selectedRoom.totalCost,
      },
      isReserved: false,
      alignedWithFlightDate: true,
      alignState: AlignState.Aligned,
    }
  }
}
