import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'

type Props = {
  statement: string
  onClose: () => void
}

export function SidebarHeader({ statement, onClose }: Props) {
  const { t } = useTranslation()
  return (
    <Header
      rightSlot={
        <Block paddingRight={13}>
          <IconButton
            aria-label={t('Accessibility.Header.CloseLabel')}
            icon="closeFilledPWA"
            color="bodyText"
            size="medium"
            onClick={onClose}
          />
        </Block>
      }
      minHeight={72}
      withBorderBottom>
      <Block paddingLeft={32}>
        <Header.Title title={statement} align="left" data-tracking-id="cancel-trip-title-text" />
      </Block>
    </Header>
  )
}
