import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'
import { Button } from '@etta/ui/button'
import { KeyboardNavigationStyle } from '@fiji/style'

export const ListWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 64px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 364px;
  min-height: 336px;
  left: calc(50% - 210px);
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.17);
  z-index: 12;
`

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`

export const CloseIcon = styled(IconButton)`
  margin-left: auto;
  ${KeyboardNavigationStyle}
`

export const ItemsContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  & > div:nth-child(2) {
    border-bottom: 0;
  }
`

export const ReviewButton = styled(Button)`
  max-height: 44px;
  margin-top: auto;
  ${KeyboardNavigationStyle}
`
