import type { TripDetailsSegment } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { DuplicateTripCarRentalSegment } from './duplicate-trip-segments/duplicate-trip-car-rental-segment'
import { DuplicateTripFlightSegment } from './duplicate-trip-segments/duplicate-trip-flight-segment'
import { DuplicateTripHotelSegment } from './duplicate-trip-segments/duplicate-trip-hotel-segment'

type Props = {
  segment: TripDetailsSegment
}

export function DuplicateSegment({ segment }: Props) {
  switch (segment.type) {
    case SegmentType.Flight: {
      if (!segment.flightLeg) {
        return null
      }

      return <DuplicateTripFlightSegment flightLeg={segment.flightLeg} />
    }
    case SegmentType.Hotel: {
      return <DuplicateTripHotelSegment hotel={segment.hotel} />
    }
    case SegmentType.CarRental: {
      return <DuplicateTripCarRentalSegment car={segment.carRental} />
    }
    default:
      return null
  }
}
