import type { AppState } from '@fiji/store'

export function displayConfigurationSelector(state: AppState) {
  return state.displayConfiguration
}

export function exploreDisplayConfigurationSelector(state: AppState) {
  return state.displayConfiguration.explore
}

export function flightDisplayConfigurationSelector(state: AppState) {
  return state.displayConfiguration.flights
}
