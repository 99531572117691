import { observer } from 'mobx-react-lite'
import { SegmentType } from '@etta/core/enums'
import { NoModifySegment } from '@etta/components/no-modify-segment/no-modify-segment'
import { SegmentCard, useSegmentCard } from '../segment-card'
import { SectionDate } from '../date-segments-styled'
import { useCarRentalSegment } from './use-car-rental-segment'
import type { LayoutProps } from './types'

export const CarRentalSegment = observer(function CarRentalSegment({
  segment,
  isUpcoming,
  isOnHold,
  currentDate,
  isTripInProgress,
  onClick,
  onCancel,
}: LayoutProps) {
  const { isPast, dateToShow } = useSegmentCard({ segment, currentDate, isOnHold })

  const {
    ariaLabel,
    title,
    carNameAndDateLabel,
    carRentalStatus,
    dataTexts,
    timePickUpCard,
    buttons,
    typeTitle,
    isNoModifyCarRentalOpen,
    handleNoModifyCarRentalClose,
    handleShare,
    carRentalTitle,
    headerDate,
    cardInfo,
    segmentPreview,
    cardSubTitle,
    infoLine,
  } = useCarRentalSegment({
    segment,
    dateToShow,
    isUpcoming,
    isTripInProgress,
    onCancel,
    isOnHold,
  })

  return (
    <>
      <SegmentCard
        isPast={isPast}
        isUpcoming={isUpcoming}
        ariaLabel={ariaLabel}
        segmentIcon="carRentalPWA"
        onClick={onClick}
        titleSlot={<span>{title}</span>}
        dateSlot={<SectionDate>{dateToShow}</SectionDate>}
        departureTime={timePickUpCard}
        dataTexts={dataTexts}
        buttons={buttons}
        onShare={handleShare}
        label={carNameAndDateLabel}
        segmentStatus={carRentalStatus}
        segmentType={SegmentType.CarRental}
        cardTitle={carRentalTitle}
        headerDate={headerDate}
        cardSubTitle={cardSubTitle}
        cardInfo={[cardInfo]}
        segmentPreview={segmentPreview}
        isOnHold={isOnHold}
        infoLine={infoLine}
      />
      <NoModifySegment
        type={typeTitle}
        isOpen={isNoModifyCarRentalOpen}
        onClose={handleNoModifyCarRentalClose}
      />
    </>
  )
})
