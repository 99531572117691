import styled from 'styled-components'

export const Container = styled.div`
  padding: 4px;
  display: inline-flex;
  background-color: ${({ theme }) => theme.colors.background4};
  border-radius: 6px;
  align-items: center;
`

export const MobileContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 20px;
  right: 20px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.mainText};
  max-width: 200px;
`
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 180px;
`
