import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Container, IconWrapper } from './callout.styled'

type CalloutProps = {
  message: string
}

export const Callout = ({ message }: CalloutProps) => {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  return (
    <Container>
      <IconWrapper>
        <Icon name={'infoFilledPWA'} size={isMobile ? 'small' : 'normal'} />
      </IconWrapper>
      <Text variant="footnoteMedium" color="mainText">
        {message}
      </Text>
    </Container>
  )
}
