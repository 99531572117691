import styled from 'styled-components'
import { UnstyledButton, footnoteMedium } from '@fiji/style'

export const PreferenceIcons = styled(UnstyledButton)`
  position: relative;
  background: transparent;
  border-width: 0;
  outline-width: 0;
  padding: 0;
  text-align: left;
`

export const AvailableIcons = styled.div`
  padding: 10px 14px 4px;
  > div {
    margin: 6px;
  }
`

export const UnavailablePreferences = styled.div`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
  margin: 3px 22px 5px;
`
