export enum TripStatus {
  ActionRequired = 'ACTION_REQUIRED',
  AutoCancelled = 'AUTO_CANCELLED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Reserved = 'RESERVED',
  Ticketed = 'TICKETED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
  WaitListed = 'WAIT_LISTED',
}
