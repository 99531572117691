import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import type { TripFlightLayoutProps } from '../types'
import { TripFlightContentMobileLayout } from './mobile'
import { TripFlightContentDesktopLayout } from './desktop'

export function TripFlightContentLayout(props: TripFlightLayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<TripFlightContentMobileLayout {...props} />}
      desktopSlot={<TripFlightContentDesktopLayout {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
