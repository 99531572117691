import { Fragment } from 'react'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { SegmentType } from '@etta/core/enums'
import type { CarRentalSegment, FlightSegment, HotelSegment, RailSegment } from '../../../types'
import { Container } from './segments-container-styled'
import { SegmentsContainerList } from './segments-container-list'
import { useSegmentsContainerLabel } from './use-segments-container-label'

type Props = {
  flights: FlightSegment[]
  hotels: HotelSegment[]
  carRentals: CarRentalSegment[]
  rails: RailSegment[]
}

export function SegmentsContainer({ flights, hotels, carRentals, rails }: Props) {
  const {
    getFlightLabel,
    getHotelLabel,
    getRailLabel,
    getCarRentalLabel,
  } = useSegmentsContainerLabel()

  return (
    <Container>
      {Boolean(flights.length) && (
        <SegmentsContainerList data-tracking-id="flight-segment" segmentType={SegmentType.Flight}>
          {flights.map((flight) => {
            const { departureAirport, arrivalAirport, departureDateTime } = getFlightLabel(flight)

            return (
              <Text key={flight.id} variant="footnoteStrong" color="mainText">
                {departureAirport}
                <Icon name="arrowForwardPWA" color="mainText" size="small" />
                {arrivalAirport}
                {departureDateTime}
              </Text>
            )
          })}
        </SegmentsContainerList>
      )}
      {Boolean(rails.length) && (
        <SegmentsContainerList data-tracking-id="rail-segment" segmentType={SegmentType.Train}>
          {rails.map((rail) => {
            const { departureToArrival, departureDateTime } = getRailLabel(rail)

            return (
              <Text key={rail.id} variant="footnoteStrong" color="mainText">
                {departureToArrival}
                {departureDateTime}
              </Text>
            )
          })}
        </SegmentsContainerList>
      )}
      {Boolean(hotels.length) && (
        <SegmentsContainerList data-tracking-id="hotel-segment" segmentType={SegmentType.Hotel}>
          {hotels.map((hotel) => {
            const { hotelLabel } = getHotelLabel(hotel)

            return (
              <Text key={hotel.id} variant="footnoteStrong" color="mainText">
                {hotelLabel}
              </Text>
            )
          })}
        </SegmentsContainerList>
      )}
      {Boolean(carRentals.length) && (
        <SegmentsContainerList
          data-tracking-id="car-rental-segment"
          segmentType={SegmentType.CarRental}>
          {carRentals.map((carRental) => {
            const { pickUpLabel, dropOffLabel } = getCarRentalLabel(carRental)

            return (
              <Fragment key={carRental.id}>
                <Text variant="footnoteStrong" color="mainText">
                  {pickUpLabel}
                </Text>
                <Text variant="footnoteStrong" color="mainText">
                  {dropOffLabel}
                </Text>
              </Fragment>
            )
          })}
        </SegmentsContainerList>
      )}
    </Container>
  )
}
