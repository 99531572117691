import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { ConcernScoreListMobile } from './mobile'
import { ConcernScoreListDesktop } from './desktop'

export function ConcernScoreListLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ConcernScoreListMobile {...props} />}
      desktopSlot={<ConcernScoreListDesktop {...props} />}
    />
  )
}
