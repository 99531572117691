import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { DocumentType } from '@etta/modules/booking/interface/checkout-info/documents/checkout-info-documents.store'
import type { Props } from '../../layout'
import { KnownTravelerNumberForm } from '../../../known-traveler-number-form'
import { useTravelerDocuments } from '../../../use-traveler-documents'
import { RedressNumberForm } from '../../../redress-number-form'
import { FooterWrapper } from './traveler-document-action-mobile.styled'

const i18nBase = 'TravelerInfo.TravelerDocuments.'

function DocumentForm() {
  const { documentType } = useTravelerDocuments()

  if (documentType === DocumentType.KTN) {
    return <KnownTravelerNumberForm />
  }

  if (documentType === DocumentType.Redress) {
    return <RedressNumberForm />
  }

  return <></>
}

export function TravelerDocumentActionMobile({
  isOpen,
  onClose,
  title,
  onSave,
  isSaveDisabled,
}: Props) {
  const { t } = useTranslation()

  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="center">
      <Modal.Header withBorder isMobile>
        <Header minHeight={56} align="center" leftSlot={<Header.CloseButton onClick={onClose} />}>
          <Header.Title title={title} align="left" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <Block paddingHorizontal={20} paddingTop={16}>
          <DocumentForm />
        </Block>
      </Modal.Body>
      <Modal.Footer withBorder>
        <FooterWrapper>
          <Button disabled={isSaveDisabled} size="normal" onClick={onSave} fullWidth>
            {t(`${i18nBase}Save`)}
          </Button>
        </FooterWrapper>
      </Modal.Footer>
    </Modal>
  )
}
