import styled from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const Container = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  margin: 0 0 8px;
  ${headline};
`

export const Text = styled.div`
  margin: 0 0 10px;
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium}
`
