import type { PermissionTranslation } from '../shared'
import { PermissionScreen } from '../shared'
import { useGeolocation } from './use-geolocation'
import animation from './animation.json'
import { useRequestLocationTranslation } from './use-request-location-translation'

type Props = {
  onSuccess: () => void
  onFail: () => void
  onSkip: () => void
  nextTranslation: PermissionTranslation
}

export function RequestLocation({ onSkip, onSuccess, onFail, nextTranslation }: Props) {
  const geolocation = useGeolocation()
  const translation = useRequestLocationTranslation()

  return (
    <PermissionScreen
      animationSrc={animation}
      isButtonDisabled={geolocation.isLoading}
      onRequest={geolocation.getCurrentPosition}
      onSkip={onSkip}
      onSuccess={onSuccess}
      onFail={onFail}
      translation={translation}
      nextTranslation={nextTranslation}
    />
  )
}
