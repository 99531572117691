import { format } from 'date-fns'

type TimeType = {
  hours?: number
  minutes?: number
  seconds?: number
}

export function getFormattedTime(time: TimeType | Date, formatType = 'HH:mm:ss') {
  if (time instanceof Date) {
    return format(time, formatType)
  }
  const { hours = 0, minutes = 0, seconds = 0 } = time
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  date.setMilliseconds(0)
  return format(date, formatType)
}
