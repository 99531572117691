import { useTranslation } from 'react-i18next'
import { formatNational } from '@fiji/utils/phone/format-national'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { CarRentalSafetyCheck } from '@etta/components/car-rental-safety-check/car-rental-safety-check'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import { CarRentalMobileDivider } from '../car-rental-mobile-divider'
import { CarRentalMobileSection } from '../car-rental-mobile-section'
import {
  Address,
  InformationList,
  InformationListItem,
  InformationListItemContent,
  InformationListItemIcon,
  InformationListItemTitle,
  InformationListItemValue,
  VendorLogo,
  VendorLogoContainer,
} from './car-rental-mobile-supplier-styled'

type Props = {
  car?: CarRentalCarDetailsValueObject | null
}

const i18Base = 'CarRentalDetails'

export function CarRentalMobileSupplier({ car }: Props) {
  const { t } = useTranslation()
  const { safetyCheck, vendor } = car || {}
  const pickUpPhoneNumber = car?.pickUp.phone?.number
  const vendorName = car?.vendor.name

  function formatWorkingHours(time: string) {
    const [hours, minutes] = time.split(':')
    return getFormattedTime({ hours: parseInt(hours), minutes: parseInt(minutes) }, 'hh aa')
  }

  const startHourFormatted = car?.pickUp.workingHours
    ? formatWorkingHours(car?.pickUp.workingHours.start)
    : ''
  const endHourFormatted = car?.pickUp.workingHours
    ? formatWorkingHours(car?.pickUp.workingHours.end)
    : ''
  const vendorLogo = car?.vendor.imageUrl
  const pickUpAddress = car?.pickUp.address ? formatPickUpDropOffAddress(car?.pickUp.address) : null

  return (
    <CarRentalMobileSection title={t(i18Base + '.SectionTitles.SupplierInformation')}>
      <CarRentalMobileSection.Content>
        <InformationList>
          <InformationListItem>
            {vendorLogo && (
              <VendorLogoContainer>
                <VendorLogo src={vendorLogo} />
              </VendorLogoContainer>
            )}
            <Address>{`${vendorName}${pickUpAddress ? `, ${pickUpAddress}` : ''}`}</Address>
            <CarRentalMobileDivider borderStyle="dashed" />
          </InformationListItem>

          <InformationListItem>
            <InformationListItemContent>
              <div>
                <InformationListItemTitle>{t(i18Base + '.DeskTimings')}</InformationListItemTitle>
                <InformationListItemValue>
                  {startHourFormatted} - {endHourFormatted}
                </InformationListItemValue>
              </div>
              <InformationListItemIcon name="timePWA" color="bodyText" />
            </InformationListItemContent>

            <CarRentalMobileDivider borderStyle="dashed" />
          </InformationListItem>

          {pickUpPhoneNumber && (
            <InformationListItem>
              <InformationListItemContent>
                <div>
                  <InformationListItemTitle>
                    {t(i18Base + '.CallVendor', { supplier: vendorName })}
                  </InformationListItemTitle>
                  <InformationListItemValue
                    as="a"
                    href={`tel:${pickUpPhoneNumber}`}
                    aria-label={pickUpPhoneNumber?.split('').join(' ')}>
                    <span>{formatNational(pickUpPhoneNumber)}</span>
                  </InformationListItemValue>
                </div>
                <InformationListItemIcon name="phonePWA" color="primary" />
              </InformationListItemContent>
              <CarRentalMobileDivider borderStyle="dashed" />
            </InformationListItem>
          )}
          <InformationListItem>
            <CarRentalSafetyCheck
              vendorName={vendor?.name}
              messageCode={safetyCheck?.messageCode}
              badgeUrl={safetyCheck?.badgeUrl}
              url={safetyCheck?.url}
            />
          </InformationListItem>
        </InformationList>
      </CarRentalMobileSection.Content>
    </CarRentalMobileSection>
  )
}
