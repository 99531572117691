import { useTranslation } from 'react-i18next'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { useFlightDetailsEcoCheckModalQuestion } from '../../use-flight-details-eco-check-modal-question'
import {
  EmisisonDetailsHeader,
  EmisisonDetailsDescription,
  EmisisonDetails,
  EmisisonDetailsAboutDescription,
} from '../flight-details-eco-check-modal-desktop-new-styled'
import { FlightDetailsModalLearnMore } from '../../components/flight-details-eco-check-modal-learn-more'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function FlightDetailsEcoCheckModalDesktopLowerBodyNew({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const { questions, answers } = useFlightDetailsEcoCheckModalQuestion({ deviationLevel })

  return (
    <>
      <EmisisonDetails>
        <EmisisonDetailsAboutDescription>
          {t(i18nBase + '.EmissionsEstimatePerPassenger')}
        </EmisisonDetailsAboutDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>{questions.howDoWeKnow}</EmisisonDetailsHeader>
        <EmisisonDetailsDescription>{answers.howDoWeKnow}</EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>{questions.howDoWeEstimate}</EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {answers.howDoWeEstimate}
          <FlightDetailsModalLearnMore />
        </EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHeader')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.OtherWaysToReduceEmissionsFlightDescription')}
        </EmisisonDetailsDescription>
      </EmisisonDetails>
    </>
  )
}
