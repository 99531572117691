import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  padding: 16px 0 14px 0;

  &:not(:last-of-type) {
    border-bottom: 1px dashed ${(props) => props.theme.colors.borderLight};
  }
`
export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 9px;
  min-width: 100px;
`

export const Title = styled(Text)`
  margin: 2px 0 4px 0;
  letter-spacing: normal;
`

export const DetailLine = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 1px;
  }
`
