import { SearchResultsHeaderCompact } from '@etta/components/search-results-header/header-compact/search-results-header-compact'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useRailSearchResultContextSelector } from '../../rail-search-result-page-provider'

export function RailResultsHeaderMobile() {
  const state = useRailSearchResultContextSelector((state) => state)
  const { railActions, railFiltersStore } = useRailContext()
  const {
    featureFlagsStore: { flags },
  } = useFeatureFlagsContext()

  return (
    <SearchResultsHeaderCompact
      onBackClick={state.onBackByType}
      onLabelClick={railActions.onBackToSearch}
      onFilterClick={railActions.handleFiltersModalOpen}
      locationsLabel={state.locationsLabel}
      datesLabel={state.datesLabel}
      isFilterActive={railFiltersStore.isFiltersApplied}
      isShowFilters={flags.isRailSearchFiltersEnabled}
    />
  )
}
