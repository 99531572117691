import { createContext } from 'react'
import type { HistoryBlockHandler } from './types'

type Props = {
  block: (handler: HistoryBlockHandler) => () => void
}

export const HistoryBlockContext = createContext<Props>({
  block: () => () => {},
})
