import i18n from 'i18next'
import { CarRentalType } from '@fiji/graphql/types'

const TRANSLATES_MAP = {
  [CarRentalType.Car]: 'CarRentalTypes.Car',
  [CarRentalType.Suv]: 'CarRentalTypes.Suv',
  [CarRentalType.Van]: 'CarRentalTypes.Van',
  [CarRentalType.Pickup]: 'CarRentalTypes.Pickup',
}

export function translateCarType(carType?: CarRentalType | null): string {
  if (!carType) {
    return ''
  }
  const key = TRANSLATES_MAP[carType] || carType
  return i18n.t(key) || carType
}
