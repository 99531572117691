import { useState } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'

export function useCancelTripModal() {
  const {
    postBookingTripPreviewStore,
    postBookingCancellationStore,
    postBookingTripStore,
  } = usePostBookingContext()
  const { tripPreview } = postBookingTripPreviewStore
  const { cancellationDateRange } = postBookingCancellationStore
  const { trip } = postBookingTripStore
  const { start, end } = cancellationDateRange
  const { costSummary } = trip

  const startDate = start ? start : deleteTimezone(tripPreview.startTime)
  const endDate = end ? end : deleteTimezone(tripPreview.endTime)

  const titleDate = useDateRangeTitle(startDate, endDate)

  const [isChecked, setIsChecked] = useState(false)

  return {
    titleDate,
    isChecked,
    setIsChecked,
    costSummary,
  }
}
