import { setContext } from '@apollo/client/link/context'
import { BrandConfigurationStore } from '@etta/modules/brand-configuration/interface/store/brand-config.store'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { launchMode } from '@fiji/modes'
import { getClientIdentifier } from './get-client-identifier'
import { getLcid } from './get-lcid'

export const authLink = setContext((_, { headers }) => {
  const lcid = getLcid()
  const clientIdentifier = getClientIdentifier()

  // TEMP: for splunk dashboard purpose
  const { tripId } = ReviewTripStore.getInstance()
  const { theme } = BrandConfigurationStore.getInstance()
  return {
    headers: {
      ...headers,
      'x-lcid': lcid,
      'x-app-mode': launchMode,
      'x-fiji-client': clientIdentifier,
      ...(tripId ? { 'x-trace-id': tripId } : {}),
      ...(tripId ? { 'x-trip-id': tripId } : {}),
      ...(theme ? { 'x-theme': theme } : {}),
    },
  }
})
