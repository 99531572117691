import { useTranslation } from 'react-i18next'
import DeemFooterSkeleton from '@fiji/components/deem-footer/deem-footer-skeleton'
import { appMode, screenMatcher, ScreenType } from '@fiji/modes'
import { Container, WrappedContainer } from './deem-footer-components'

type Props = {
  loading: boolean
  error: boolean
  content: string
}

function DeemFooter({ loading, error, content }: Props) {
  const { t } = useTranslation()

  if (appMode.isEttaAll) {
    return null
  }

  if (screenMatcher.getScreenType() === ScreenType.Mobile) {
    return null
  }

  if (loading) {
    return (
      <Container>
        <DeemFooterSkeleton />
      </Container>
    )
  }

  if (error) {
    return <>{t('DeemFooter.Error')}</>
  }

  return (
    <Container className="deem-footer">
      <WrappedContainer>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </WrappedContainer>
    </Container>
  )
}

export default DeemFooter
