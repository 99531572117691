import { isFuture } from 'date-fns'
import i18n from '@fiji/i18n'
import { Store } from '@etta/di'
import { Validator } from '@etta/interface/services/validator'
import { Toggle } from '@etta/interface/services/toggle'

export enum DocumentType {
  KTN = 'knownTravelerNumbers',
  Redress = 'RedressNumbers',
}

export type KnownTravelerNumber = {
  number: string
  countryCode: string
  expirationDate: string
}

export type RedressNumber = {
  number: string
  countryCode: string
}

export type Document = KnownTravelerNumber | RedressNumber
type KeysOfUnion<T> = T extends T ? keyof T : never
export type DocumentField = KeysOfUnion<Document>

const i18nBase = 'TravelerInfo'
const i18BaseErrors = 'TravelerInfo.TravelerDocuments'

@Store()
export class CheckoutInfoDocumentStore {
  isSaveButtonDisabled = true
  documentType: DocumentType | null = null
  oldDocument: Document | null = null

  toggle = new Toggle()
  removeToggle = new Toggle()
  travelerDocumentActionToggle = new Toggle()
  travelerDocumentsToggle = new Toggle()
  pickerTogglers = {
    knownTravelerNumberExpiration: new Toggle(),
  }

  isDocumentTouched = false
  setIsDocumentTouched(value: boolean) {
    this.isDocumentTouched = value
  }

  knownTravelerNumberValidator = new Validator({
    number: Validator.scheme
      .string()
      .matches(/^[a-zA-Z0-9]*$/, i18n.t(`${i18nBase}.KnownTravelerNumber.InvalidNumber`))
      .required(
        i18n.t(`${i18BaseErrors}.FieldRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.KnownTravelerNumber.Number`),
        }),
      )
      .default(''),
    countryCode: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.KnownTravelerNumber.Country`),
        }),
      )
      .default(''),
    expirationDate: Validator.scheme
      .string()
      .test(
        'invalid-expiration-date',
        i18n.t(`${i18nBase}.KnownTravelerNumber.InvalidExpirationDate`),
        (value) => !value || isFuture(new Date(value)),
      )
      .default(''),
  })

  redressNumberValidator = new Validator({
    number: Validator.scheme
      .string()
      .matches(/^[a-zA-Z0-9]*$/, i18n.t(`${i18nBase}.RedressNumber.InvalidNumber`))
      .required(
        i18n.t(`${i18BaseErrors}.FieldRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.RedressNumber.Number`),
        }),
      )
      .default(''),
    countryCode: Validator.scheme
      .string()
      .required(
        i18n.t(`${i18BaseErrors}.FieldRequired`, {
          fieldLabel: i18n.t(`${i18nBase}.RedressNumber.Country`),
        }),
      )
      .default(''),
  })

  setDocumentField(field: DocumentField, value: string | number) {
    switch (this.documentType) {
      case DocumentType.KTN:
        this.knownTravelerNumberValidator.onFieldChange(field as keyof KnownTravelerNumber, value)
        break
      case DocumentType.Redress:
        this.redressNumberValidator.onFieldChange(field as keyof RedressNumber, value)
        break
    }
  }

  resetDocument() {
    switch (this.documentType) {
      case DocumentType.KTN:
        this.knownTravelerNumberValidator.reset()
        break
      case DocumentType.Redress:
        this.redressNumberValidator.reset()
        break
    }
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }

  setOldDocument(document: Document | null) {
    this.oldDocument = document
  }

  setDocumentType(documentType: DocumentType | null) {
    this.documentType = documentType
  }
}
