import type { RoomType } from '@fiji/graphql/types'
import { RateQualifier } from '@fiji/graphql/types'
import { LabelBadge } from '@etta/ui/label-badge'
import { ROOM_PROVIDERS } from '@fiji/constants'
import { Type, TypeWrapper } from './room-type-header-styled'

type Props = {
  roomType: RoomType
  source?: string | null
  rateQualifier?: RateQualifier | null
}

export function RoomTypeHeader({ roomType, source, rateQualifier }: Props) {
  const isBookingShown = source === ROOM_PROVIDERS.BOOKINGCOM
  const isGBTDirectShown =
    source === ROOM_PROVIDERS.HOTELHUB && rateQualifier === RateQualifier.Prepaid
  const isTripSource = source === ROOM_PROVIDERS.TRIPSOURCE

  return (
    <TypeWrapper>
      <Type aria-label={roomType}>
        {roomType}
        {isBookingShown && <LabelBadge variant="booking" />}
        {isGBTDirectShown && <LabelBadge variant="gbt_direct" />}
        {isTripSource && <LabelBadge variant="trip_source" />}
      </Type>
    </TypeWrapper>
  )
}
