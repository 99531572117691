import i18n from 'i18next'
import type { ComplianceMessage, OverlappingTrip } from '@fiji/graphql/types'
import { concat } from '@fiji/utils/text'
import { generateId } from '@fiji/utils/generate-id'

type Props = {
  overlappingTrips?: OverlappingTrip[] | null
  complianceMessages?: ComplianceMessage | null
}

export function useTravelAlerts({ overlappingTrips, complianceMessages }: Props) {
  const i18nBasePath = 'TripReview.TravelAlert.'

  const countryRestrictions = complianceMessages?.countryRestrictions || []

  const header =
    !countryRestrictions && overlappingTrips ? i18n.t(i18nBasePath + 'DuplicateBooking.Title') : ''

  const countryRestrictionsMessages = countryRestrictions
    ?.map((restriction: string) => `<p>${restriction}</p>`)
    .join('')

  const overlappingTripsMessages =
    overlappingTrips && overlappingTrips.length > 0
      ? `<p>
          ${i18n.t(i18nBasePath + 'DuplicateBooking.Message')}
          <span>
            ${concat(
              overlappingTrips.map(
                (trip) => trip.name,
                i18n.t(i18nBasePath + 'DuplicateBooking.And'),
              ),
            )}
          </span>
          ${i18n.t(i18nBasePath + 'DuplicateBooking.ConfirmationMessage')}
        </p>`
      : ''

  if (!countryRestrictionsMessages && !overlappingTripsMessages) {
    return { travelAlerts: [] }
  }

  return {
    travelAlerts: [
      {
        id: generateId('alert-for-travel', header),
        header,
        text: countryRestrictionsMessages + overlappingTripsMessages,
      },
    ],
  }
}
