import type { FC, ReactNode } from 'react'
import { isValidElement, Children, memo } from 'react'
import type { EttaMapMarkerProps } from '../types'
import { AppleMapMarker } from './apple-map-marker'

type Props = {
  instance: mapkit.Map | null
  isMapLoaded: boolean
}

export const AppleMapMarkers: FC<Props> = memo(({ children, instance }) => {
  if (!children || !instance) {
    return null
  }

  return (
    <>
      {Children.map<ReactNode, ReactNode>(children, (child) => {
        if (!isValidElement(child)) {
          return null
        }

        const {
          lat = instance.center.latitude,
          lng = instance.center.longitude,
          calloutAppearStrategy,
        } = child.props as Partial<EttaMapMarkerProps>

        return (
          <AppleMapMarker
            key={child.key}
            calloutAppearStrategy={calloutAppearStrategy}
            lat={lat}
            lng={lng}
            instance={instance}
            child={child}
          />
        )
      })}
    </>
  )
})
