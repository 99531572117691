export enum StepName {
  ReviewTrip = 'trip-review',
  TravelerInfo = 'traveler-info',
  AdditionalInfo = 'additional-info',
  PaymentInfo = 'payment-info',
  TravelFusionLinks = 'travel-fusion-links',
  PurchaseTermsAndConditions = 'purchase-terms-and-conditions',
  Confirmation = 'confirmation',
  Error = 'error',
}

export type StepNameType = `${StepName}`

export type LayoutProps = {
  travelerInfoSlot: JSX.Element
  additionInfoSlot: JSX.Element
  confirmationSlot: JSX.Element
  errorSlot: JSX.Element
  dynamicSiteMessagesSlot: JSX.Element
  autoCancellationTimeSlot: JSX.Element
  railDeliveryOptionSlot: JSX.Element
}
