import styled from 'styled-components'

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SecondLine = styled.div`
  margin-top: 7px;
`
