import { useState, useEffect } from 'react'
import type { LuggageOptionValueObject } from '../../../core/value-objects'
import { AddBaggageModalLayout } from './layout'

type Props = {
  isOpen: boolean
  onClose: () => void
  selectedBaggage?: LuggageOptionValueObject
  luggageOptions?: LuggageOptionValueObject[] | null
  handleSelectBaggageOption: (luggageOption?: LuggageOptionValueObject) => void
}

export function AddBaggageModal({
  isOpen,
  onClose,
  luggageOptions,
  selectedBaggage,
  handleSelectBaggageOption,
}: Props) {
  const [luggageOption, setLuggageOption] = useState(selectedBaggage)

  useEffect(() => {
    setLuggageOption(selectedBaggage)
  }, [selectedBaggage, isOpen])

  const onSelect = () => {
    handleSelectBaggageOption(luggageOption)
  }

  if (!luggageOptions) {
    return null
  }

  return (
    <AddBaggageModalLayout
      luggageOption={luggageOption}
      setLuggageOption={setLuggageOption}
      handleSelectBaggageOption={onSelect}
      isOpen={isOpen}
      luggageOptions={luggageOptions}
      onClose={onClose}
    />
  )
}
