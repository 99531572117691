import styled from 'styled-components'

export const WeekHeaderContainer = styled.div<{ weekHeaderOfFirstMonth?: boolean }>`
  z-index: 10;
  width: 280px;
  height: 20px;
  position: absolute;
  top: 100px;
  left: ${({ weekHeaderOfFirstMonth }) => (weekHeaderOfFirstMonth ? 20 : 329)}px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const WeekDay = styled.span`
  text-align: center;
  flex-grow: 1;

  &:first-child,
  &:last-child {
    color: ${(props) => props.theme.colors.bodyText1};
  }
`
