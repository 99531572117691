import { Inject, Service } from '@etta/di'
import { dateToIso } from '@fiji/utils/dates/date-to-iso'
import type { GenderWithNone } from '@etta/modules/user/core/value-objects/user-gender-with-none.value-object'
import type {
  MisValueObject,
  PersonalContactPhoneValueObject,
  UserInputValueObject,
} from '../../core/value-objects'
import { UserAdapter } from '../../infra/user.adapter'
import { UserStore } from '../stores/user.store'
import { UserInformationStore } from '../stores/user-information.store'
import { BusinessContactStore } from '../stores/business-contact.store'
import { EmployeeInfoStore } from '../stores/employee-info.store'
import type { Step } from '../types'
import { RegistrationStatus } from '../../core/enums'
import { PaymentInfoStore } from '../stores/payment-info.store'
import { UpdateTravelPreferencesService } from './update-travel-preferences.service'
import { UserProfileService } from './get-user-profile.service'

type ActivateUserInput = UserInputValueObject & {
  mobilePhone?: PersonalContactPhoneValueObject
  homePhone?: PersonalContactPhoneValueObject
  timeZone?: string
  authorization: { registration: RegistrationStatus }
  approvers?: { travelApproverId?: string }
  profilePin?: string
  mis?: MisValueObject
  creditCards?: Array<{ id: string; preExpirationAlert: string }>
  genderWithNone?: GenderWithNone | null
}

@Service()
export class ActivateUserService {
  constructor(
    @Inject() private userAdapter: UserAdapter,
    @Inject() private userStore: UserStore,
    @Inject() private userInformationStore: UserInformationStore,
    @Inject() private businessContactStore: BusinessContactStore,
    @Inject() private employeeInfoStore: EmployeeInfoStore,
    @Inject() private updateTravelPreferencesService: UpdateTravelPreferencesService,
    @Inject() private userProfileService: UserProfileService,
    @Inject() private paymentInfoStore: PaymentInfoStore,
  ) {}

  async saveActivationDataByStep(step: Step, isCompletionStep: boolean): Promise<boolean> {
    if (step === 'create-password') {
      return true
    }

    let isSaveAdditionalInformationSuccess = true

    if (step === 'user-information') {
      const isSuccess = await this.saveUserInformationAdditionalInformation()
      isSaveAdditionalInformationSuccess = isSuccess
    }
    if (step === 'company-information') {
      const isSuccess = await this.saveUserCompanyInformationAdditionalInformation()
      isSaveAdditionalInformationSuccess = isSuccess
    }
    if (step === 'travel-preferences') {
      const isSuccess = await this.updateTravelPreferencesService.update()
      isSaveAdditionalInformationSuccess = isSuccess
    }
    if (step === 'employee-information') {
      const isSuccess = await this.saveEmployeeInformationAdditionalInformation()
      isSaveAdditionalInformationSuccess = isSuccess
    }

    this.userProfileService.fetchUserProfile(this.userStore.isOpenEnrollmentAccount)

    if (!isSaveAdditionalInformationSuccess) {
      return false
    }

    const input = this.buildUserActivationInputByStep(step, isCompletionStep)

    if (step === 'employee-information') {
      const creditCardId = await this.savePaymentCard()
      input.creditCards = this.buildCreditCardInput(creditCardId)
    }

    const result = await this.userAdapter.updateUser({ input })

    return result.isOk()
  }

  private async saveUserInformationAdditionalInformation() {
    const results = await Promise.all([
      this.userProfileService.saveUserProfilePersonalInfo(
        this.userInformationStore.validators.additionalInformationValidator.values,
      ),
      this.userProfileService.saveUserProfileHomeAddress(
        this.userInformationStore.validators.additionalInformationValidator.values,
      ),
      this.userProfileService.saveUserProfileEmergencyContact(
        this.userInformationStore.validators.additionalInformationValidator.values,
      ),
    ])

    return results.every((v) => v)
  }

  private async saveUserCompanyInformationAdditionalInformation() {
    return this.userProfileService.saveUserProfileBusinessAddress(
      this.businessContactStore.validators.additionalInformationValidator?.values || {},
    )
  }

  private async saveEmployeeInformationAdditionalInformation() {
    const isSaveEmployeeInfoSuccess = await this.userProfileService.saveUserProfileEmployeeInformation(
      this.employeeInfoStore.validators.additionalInformationValidator.values,
    )
    return isSaveEmployeeInfoSuccess
  }

  private async savePaymentCard(): Promise<string> {
    const {
      cardNumber,
      expirationDate,
      nameOnCard,
      cardName,
    } = this.paymentInfoStore.validator.values

    const shouldSavePaymentCard = Boolean(cardNumber && expirationDate && nameOnCard && cardName)

    if (!shouldSavePaymentCard) {
      return ''
    }

    const cardResult = await this.userAdapter.addPaymentCard({
      creditCardData: {
        brandType: this.paymentInfoStore.creditCardBrand,
        cardNumber: cardNumber,
        expirationDate: expirationDate.replace(' / ', ''),
        label: cardName,
        name: nameOnCard,
      },
    })

    if (cardResult.isOk()) {
      return cardResult.getValue().legacyId || ''
    }

    return ''
  }

  private buildCreditCardInput(creditCardId: string): ActivateUserInput['creditCards'] {
    if (!creditCardId) {
      return undefined
    }

    return [
      {
        id: creditCardId,
        preExpirationAlert: this.paymentInfoStore.displayAlert,
      },
    ]
  }

  private buildUserActivationInputByStep(step: Step, isCompletionStep: boolean): ActivateUserInput {
    const { companyId, customerId, profile } = this.userStore

    if (!companyId) {
      throw new Error('companyId is required for user activation')
    }

    if (!customerId) {
      throw new Error('customerId is required for user activation')
    }

    const authorization = {
      registration: isCompletionStep ? RegistrationStatus.Completed : RegistrationStatus.Started,
    }

    const { values: userInfo } = this.userInformationStore.validators.userInfoValidator
    const firstName = userInfo.firstName || profile?.firstName
    const lastName = userInfo.lastName || profile?.lastName
    const middleName = userInfo.middleName || profile?.middleName || undefined

    switch (step) {
      case 'user-information':
        return {
          timeZone: userInfo.timeZone ?? undefined,
          companyId,
          customerId,
          firstName,
          lastName,
          middleName,
          suffix: this.getValueOrNone(userInfo.suffix) ?? undefined,
          title: this.getValueOrNone(userInfo.title) ?? undefined,
          dateOfBirth: userInfo.dateOfBirth ? dateToIso(userInfo.dateOfBirth) : undefined,
          email: this.getValueOrUndefined(userInfo.email),
          genderWithNone: this.getValueOrNone(userInfo.gender) ?? undefined,
          personalAddress: {
            // Home address
            city: this.getValueOrUndefined(userInfo.homeCity),
            countryCode: this.getValueOrUndefined(userInfo.homeCountryCode),
            postalCode: this.getValueOrUndefined(userInfo.homePostalCode),
            stateCode: this.getValueOrUndefined(userInfo.homeStateCode),
            mailStop: this.getValueOrUndefined(userInfo.homeMailStop),
            street1: this.getValueOrUndefined(userInfo.homeStreet1),
            street2: this.getValueOrUndefined(userInfo.homeStreet2),
          },
          confEmail: this.getValueOrUndefined(userInfo.confirmationEmail),
          emergencyContact: {
            address: {
              city: this.getValueOrUndefined(userInfo.emergencyCity),
              countryCode: this.getValueOrUndefined(userInfo.emergencyCountryCode),
              postalCode: this.getValueOrUndefined(userInfo.emergencyPostalCode),
              stateCode: this.getValueOrUndefined(userInfo.emergencyStateCode),
              mailStop: this.getValueOrUndefined(userInfo.emergencyMailStop),
              street1: this.getValueOrUndefined(userInfo.emergencyStreet1),
              street2: this.getValueOrUndefined(userInfo.emergencyStreet2),
            },
            email: this.getValueOrUndefined(userInfo.emergencyEmail),
            name: this.getValueOrUndefined(userInfo.emergencyName),
            primaryPhone: {
              number: this.getValueOrUndefined(userInfo.emergencyPrimaryPhone),
              countryCode: this.getValueOrUndefined(userInfo.emergencyPrimaryPhoneCountryCode),
            },
            alternatePhone: {
              number: this.getValueOrUndefined(userInfo.emergencyAlternatePhone),
              countryCode: this.getValueOrUndefined(userInfo.emergencyAlternatePhoneCountryCode),
            },
            relationship: this.getValueOrUndefined(userInfo.emergencyRelationship),
          },
          authorization,
        }
      case 'company-information':
        const {
          values: businessContact,
        } = this.businessContactStore.validators.businessContactValidator
        return {
          firstName,
          lastName,
          middleName,
          companyId,
          customerId,
          profilePin: this.getValueOrUndefined(businessContact.profilePin),
          homeEmail: this.getValueOrUndefined(businessContact.homeEmail),
          businessAddress: {
            city: this.getValueOrUndefined(businessContact.city),
            countryCode: this.getValueOrUndefined(businessContact.countryCode),
            postalCode: this.getValueOrUndefined(businessContact.postalCode),
            stateCode: this.getValueOrUndefined(businessContact.stateCode),
            mailStop: this.getValueOrUndefined(businessContact.mailStop),
            street1: this.getValueOrUndefined(businessContact.street1),
            street2: this.getValueOrUndefined(businessContact.street2),
          },
          mobilePhone: {
            number: this.getValueOrUndefined(businessContact.mobilePhoneNumber),
            countryCode: this.getValueOrUndefined(businessContact.mobilePhoneCountryCode),
          },
          homePhone: {
            number: this.getValueOrUndefined(businessContact.homePhoneNumber),
            countryCode: this.getValueOrUndefined(businessContact.homePhoneCountryCode),
          },
          faxPhone: {
            number: this.getValueOrUndefined(businessContact.faxPhoneNumber),
            countryCode: this.getValueOrUndefined(businessContact.faxPhoneCountryCode),
          },
          officePhone: {
            number: this.getValueOrUndefined(businessContact.officePhoneNumber),
            countryCode: this.getValueOrUndefined(businessContact.officePhoneCountryCode),
            officeExtension: this.getValueOrUndefined(businessContact.officePhoneExtension),
          },
          employee: {
            isVip: businessContact.isVip,
          },
          groupware: { groupwareId: businessContact.groupwareId },
          authorization,
        }
      case 'employee-information':
        const { values: employeeInfo } = this.employeeInfoStore.validators.employeeInfoValidator
        return {
          firstName,
          lastName,
          middleName,
          companyId,
          customerId,
          approvers: {
            travelApproverId: this.getValueOrUndefined(employeeInfo.travelApproverId),
          },
          employee: {
            businessUnit: this.getValueOrUndefined(employeeInfo.businessUnit),
            companyName: this.getValueOrUndefined(employeeInfo.companyName),
            costCenter: this.getValueOrUndefined(employeeInfo.costCenter),
            departmentCode: this.getValueOrUndefined(employeeInfo.departmentCode),
            departmentName: this.getValueOrUndefined(employeeInfo.departmentName),
            division: this.getValueOrUndefined(employeeInfo.division),
            employeeId: this.getValueOrUndefined(employeeInfo.employeeId),
            employeeType: this.getValueOrUndefined(employeeInfo.employeeType),
            isActiveInCompany: employeeInfo.isActiveInCompany ?? undefined,
            jobLevel: this.getValueOrUndefined(employeeInfo.jobLevel),
            jobTitle: this.getValueOrUndefined(employeeInfo.jobTitle),
          },
          mis: {
            mis1: this.getValueOrUndefined(employeeInfo.mis1),
            mis2: this.getValueOrUndefined(employeeInfo.mis2),
            mis3: this.getValueOrUndefined(employeeInfo.mis3),
            mis4: this.getValueOrUndefined(employeeInfo.mis4),
            mis5: this.getValueOrUndefined(employeeInfo.mis5),
            mis6: this.getValueOrUndefined(employeeInfo.mis6),
            mis7: this.getValueOrUndefined(employeeInfo.mis7),
          },
          authorization,
        }
      case 'travel-preferences':
      default:
        return {
          firstName,
          lastName,
          middleName,
          companyId,
          customerId,
          authorization,
        }
    }
  }

  private getValueOrNone<T>(value: T | '') {
    if (value === '') {
      return 'NONE'
    }
    return value
  }

  private getValueOrUndefined(value: string | null) {
    if (!value) {
      return undefined
    }
    return value
  }
}
