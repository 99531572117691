import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'

@Store()
export class CarFiltersModalStore {
  transmissionToggle = new Toggle()
  carClassToggle = new Toggle()
  vendorsToggle = new Toggle()
  fuelTypeToggle = new Toggle()

  carFiltersToggle = new Toggle()
}
