import { useMemo } from 'react'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { getGeocodes } from '@fiji/utils/get-geocodes'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { Address } from '@fiji/graphql/types'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Params = {
  carRental: CarRentalPostBookingValueObject
}

export function useLocations({ carRental }: Params) {
  const geocode = useMemo(() => getGeocodes(carRental.pickUpLocation?.address?.geocode), [
    carRental.pickUpLocation?.address?.geocode,
  ])
  const pickUp = useMemo(
    () => ({
      date: tripReviewFormatDate(TripReviewDateType.DayAndDate, carRental.pickUpTime) || '',
      location: formatPickUpDropOffAddress(carRental.pickUpLocation?.address as Address),
    }),
    [carRental.pickUpTime, carRental.pickUpLocation?.address],
  )
  const dropOff = useMemo(
    () => ({
      date: tripReviewFormatDate(TripReviewDateType.DayAndDate, carRental.dropOffTime) || '',
      location: formatPickUpDropOffAddress(carRental.dropOffLocation?.address as Address),
    }),
    [carRental.dropOffTime, carRental.dropOffLocation?.address],
  )

  return {
    geocode,
    pickUp,
    dropOff,
  }
}
