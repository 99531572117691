import type { FlightLegSubSegment } from '@fiji/graphql/types'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'
import { getFlightSeatAssignment } from './get-flight-seat-assignment'
import { filterRemoveSeats } from './filter-remove-seats'

type Args = {
  segments?: FlightLegSubSegment[]
  initialSeats: SegmentSeatNumber[]
  newSeats: SegmentSeatNumber[]
}

export function getSeatsInput({ initialSeats, newSeats }: Args) {
  const addSeats = getFlightSeatAssignment({ newSeats })
  const removeSeats = getFlightSeatAssignment({ newSeats: initialSeats })

  return {
    addSeats,
    removeSeats: filterRemoveSeats({ addSeats, removeSeats }),
  }
}
