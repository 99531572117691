import styled from 'styled-components'

export const IconWrapper = styled.div`
  align-self: flex-start;
`

export const AmenitiesRow = styled.div`
  display: flex;
  flex-direction: row;
  > * + * {
    margin-left: 5px;
  }
`

export const AmenityRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AmenitiesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > * + * {
    margin-top: 8px;
  }
`
