import * as IconPack from './icon-pack'

const Icons = {
  economyCallToBook: IconPack.EconomyCallToBook,
  economyNotAvailable: IconPack.EconomyNotAvailable,
  economyPaid: IconPack.EconomyPaid,
  economyPremium: IconPack.EconomyPremium,
  economySelected: IconPack.EconomySelected,
  economyNewSelected: IconPack.EconomyNewSelected,
  economyAvailable: IconPack.EconomyAvailable,

  businessCallToBook: IconPack.BusinessCallToBook,
  businessNotAvailable: IconPack.BusinessNotAvailable,
  businessPaid: IconPack.BusinessPaid,
  businessPremium: IconPack.BusinessPremium,
  businessSelected: IconPack.BusinessSelected,
  businessNewSelected: IconPack.BusinessNewSelected,
  businessAvailable: IconPack.BusinessAvailable,

  alert: IconPack.Alert,
  warning: IconPack.Warning,
  exitHand: IconPack.ExitHand,
  seat: IconPack.Seat,
}

export type SeatIconName = keyof typeof Icons

type IconProps = {
  icon: SeatIconName
}

export function SeatIcon({ icon }: IconProps) {
  const IconFromPack = Icons[icon]

  return <IconFromPack aria-hidden />
}
