import type { ReactNode } from 'react'
import type { HeaderColors } from '@etta/ui/header'
import type { IconNames } from '@etta/ui/icon'
import { RowMessageItemLayout } from './layout'

type Props = {
  iconName: IconNames
  iconColor: HeaderColors
  title: string
  message?: string
  messageSlot?: ReactNode
  sectionSlot?: ReactNode
  isClosable?: boolean
  hasIcon?: boolean
  hasMultipleItems?: boolean
  hasDesktopBannerImage?: boolean
  shouldExpandMessages: boolean
  onClose?: () => void
}

export function RowMessageItem({
  iconColor,
  iconName,
  title,
  message = '',
  messageSlot,
  sectionSlot,
  onClose,
  isClosable,
  hasIcon,
  hasMultipleItems,
  hasDesktopBannerImage,
  shouldExpandMessages,
}: Props) {
  return (
    <RowMessageItemLayout
      iconColor={iconColor}
      iconName={iconName}
      title={title}
      message={message}
      messageSlot={messageSlot}
      sectionSlot={sectionSlot}
      isClosable={isClosable}
      hasIcon={hasIcon}
      hasMultipleItems={hasMultipleItems}
      hasDesktopBannerImage={hasDesktopBannerImage}
      shouldExpandMessages={shouldExpandMessages}
      onClose={onClose}
    />
  )
}
