import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { CostAllocationFormStore } from './stores/cost-allocation-form.store'
import { CostAllocationStore } from './stores/cost-allocation.store'
import { CostAllocationActions } from './actions/cost-allocation-actions'

export const { useModuleContext: useCostAllocationContext } = hookContextFactory({
  costAllocationFormStore: CostAllocationFormStore,
  costAllocationStore: CostAllocationStore,
  costAllocationActions: CostAllocationActions,
})
