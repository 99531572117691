import { Action, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import { HistoryService } from '@etta/interface/services/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { CarSearchQueryType } from '@fiji/hooks/search-queries/use-car-search-query/types'
import { CarRentalLocationService } from '../services/car-rental-location.service'
import { CarRentalStore } from '../stores/car-rental.store'
import { CarRentalQueryService } from '../services/car-rental-query.service'

@Action()
export class CarRentalLocationActions {
  constructor(
    @Inject()
    private readonly carRentalStore: CarRentalStore,
    @Inject()
    private readonly carRentalLocationService: CarRentalLocationService,
    @Inject()
    private readonly carRentalQueryService: CarRentalQueryService,
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  async handleLoadData() {
    const { isPickUpPage, isDropOffPage, isConfirmationPage } = this.carRentalStore

    if (isPickUpPage || isConfirmationPage) {
      await this.carRentalLocationService.loadPickUpLocations()
    }
    if (isDropOffPage || isConfirmationPage) {
      await this.carRentalLocationService.loadDropOffLocations()
    }
  }

  onSearchValue(value: string) {
    this.carRentalStore.setFilterLocationsValue(value)
  }

  onBack() {
    const isDropOff = this.carRentalStore.isDropOffPage
    const { pickUpPlace, bookingId } = this.carRentalStore.queryParams
    const isPickUpScreenAvailable = isDropOff && !pickUpPlace.airportCode

    const route = isPickUpScreenAvailable ? ROUTES.carRental.pickUp : ROUTES.carRental.search

    const nextPath = this.carRentalStore.carSearchQueryParams.getNextPath({
      route,
      search: this.historyStore.search,
      newQueryParams: { bookingId },
    })
    this.historyService.push(nextPath)
  }

  navigateTo(route: string, params?: Partial<CarSearchQueryType>) {
    this.carRentalQueryService.navigateTo(route, params)
  }

  handleDropStore() {
    this.carRentalStore.dropStore()
  }
}
