import { Icon } from '@etta/ui/icon'
import { useAmenities } from '../../use-amenities'
import type { LayoutProps } from '../../types'
import { List, ListItem } from './amenities-desktop-styled'

export function AmenitiesDesktop({ carRental }: LayoutProps) {
  const { ariaLabel, amenities } = useAmenities({ carRental })

  return (
    <List aria-label={ariaLabel}>
      {amenities.map((amenity) => (
        <ListItem key={amenity.title}>
          <Icon name={amenity.icon} size="small" />
          {amenity.title}
        </ListItem>
      ))}
    </List>
  )
}
