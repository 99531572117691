import { useTranslation } from 'react-i18next'
import { UnavailableMessage } from '../unavailable-message'

export function SpecialRequestUnavailable() {
  const { t } = useTranslation()

  return (
    <UnavailableMessage>
      {t('PreferenceSpecialRequests.SpecialRequestsUnavilable')}
    </UnavailableMessage>
  )
}
