import { useCallback } from 'react'
import type { SliderValues } from '../../../types'

type Args<T extends SliderValues> = {
  isDisabled?: boolean
  onChange: (value: T) => void
}
export function useSlider<T extends SliderValues>({ isDisabled, onChange }: Args<T>) {
  const handleSliderOnChange = useCallback(
    (newValue: number | number[] | null | undefined) => {
      if (isDisabled) {
        return
      }

      const isNewValueExist = newValue || newValue === 0
      if (!isNewValueExist) {
        return
      }

      onChange(newValue as T)
    },
    [isDisabled, onChange],
  )

  return {
    handleSliderOnChange,
  }
}
