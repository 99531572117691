import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { PriceLayoutMobile } from './price-layout.mobile'
import { PriceLayoutDesktop } from './price-layout.desktop'

export function PriceLayout(props: LayoutProps) {
  return (
    <MediaLayout
      desktopSlot={<PriceLayoutDesktop {...props} />}
      mobileSlot={<PriceLayoutMobile {...props} />}
    />
  )
}
