import { PriceDetails } from '@etta/components/price-details'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from './types'

export function PriceLayoutDesktop({ isTripCancelled, priceDetailsList, totalCost }: LayoutProps) {
  if (isTripCancelled) {
    return null
  }
  return (
    <Block marginTop={16}>
      <PriceDetails
        type="price-breakdown"
        priceDetailsList={priceDetailsList}
        totalCost={totalCost}
      />
    </Block>
  )
}
