import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const StyledBlock = styled(Block)<{ withBottomBorder?: boolean }>`
  ${(p) =>
    p?.withBottomBorder &&
    `
		border-bottom: 1px solid ${p.theme.colors.borderLight};
	`}
  display: flex;
  justify-content: space-between;
  align-items: center;
`
