import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { EcoCheckEquivalenceMobile } from './mobile'
import { EcoCheckEquivalencesDesktop } from './desktop'

export function EcoCheckEquivalencesLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<EcoCheckEquivalenceMobile {...props} />}
      desktopSlot={<EcoCheckEquivalencesDesktop {...props} />}
    />
  )
}
