import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Link } from '@etta/ui/link'
import { Label } from '@etta/components/rail-search-segment-details/rail-search-segment-details-styled'
import { Tooltip } from '@etta/ui/tooltip'
import { Swap } from '@etta/ui/swap'
import { RailPermittedStationsContent } from '@etta/components/rail-permitted-stations/rail-permitted-stations-content'
import type { LayoutProps } from '../../types'
import { useService } from '../../use-service'
import {
  ContainerShort,
  InfoIconWrapper,
  StyledWrapper,
  RailIconContainer,
} from './rail-fare-card-desktop.styled'

const i18Base = 'RailDetails.Fare'

function FareInfo({
  fare,
  fareConditions,
  onSelect,
  moreFaresCount,
  onInfoIconClick,
  isSoldOut,
  onMoreFaresClick,
}: Omit<LayoutProps, 'fareClassLabel'>) {
  const { t } = useTranslation()

  const buttonText = t(i18Base + '.Buttons.Select')
  const buttonAriaLabel = t(i18Base + '.Buttons.SelectFareAria', { label: buttonText })

  const {
    price,
    fareName,
    hasPermittedStations,
    permittedOriginStations,
    permittedDestinationStations,
  } = useService({ fare, fareConditions })

  if (isSoldOut) {
    return (
      <StyledWrapper>
        <Text aria-label={t(i18Base + '.SoldOut')} variant="body" color="bodyText">
          {t(i18Base + '.SoldOut')}
        </Text>
      </StyledWrapper>
    )
  }

  if (!fare) {
    return (
      <StyledWrapper>
        <Text aria-label={t(i18Base + '.NotAvailable')} variant="body" color="bodyText">
          {t(i18Base + '.NotAvailable')}
        </Text>
      </StyledWrapper>
    )
  }

  return (
    <>
      <Block marginTop={10}>
        <Text aria-label={price} variant="title3" color="mainText" data-tracking-id="fare-price">
          {price}
        </Text>
        {fareName && (
          <Block aria-label={fareName} isFlexBlock>
            <Text variant="footnoteMedium" color="bodyText">
              {fareName}
            </Text>
            <Swap
              is={hasPermittedStations}
              isSlot={
                <Tooltip
                  slot={
                    <RailPermittedStationsContent
                      permittedOriginStations={permittedOriginStations}
                      permittedDestinationStations={permittedDestinationStations}
                      onClick={() => onInfoIconClick(fareName)}
                    />
                  }
                  place="top"
                  displayOn="click"
                  isWhiteTooltip={true}
                  align="right"
                  fixWidth={220}
                  isClickable={true}>
                  <InfoIconWrapper>
                    <Icon size={20} name="infoPWA" />
                  </InfoIconWrapper>
                </Tooltip>
              }
            />
          </Block>
        )}
      </Block>
      <Block isFlexBlock marginTop={16}>
        <Button
          variant="outline-desktop"
          minWidth={8.8}
          onClick={onSelect}
          size="small"
          disabled={false}
          aria-label={buttonAriaLabel}
          data-tracking-id="add-rail-button">
          {buttonText}
        </Button>
        {!!moreFaresCount && (
          <Block
            aria-label={t(i18Base + '.Buttons.SelectButton', {
              label: t(i18Base + '.ViewMore', { count: moreFaresCount }),
            })}
            marginLeft={15}>
            <Link size="small" onClick={onMoreFaresClick}>
              {t(i18Base + '.ViewMore', { count: moreFaresCount })}
            </Link>
          </Block>
        )}
      </Block>
    </>
  )
}

export function RailFareCardDesktopShort({ fareClassLabel, isCheapest, ...props }: LayoutProps) {
  const { t } = useTranslation()
  const railCardName = props.fare?.appliedRailCards?.[0]

  return (
    <ContainerShort>
      <Block isFlexBlock>
        <Text aria-label={fareClassLabel} variant="captionStrongCaps" color="bodyText1">
          {fareClassLabel}
        </Text>
        {isCheapest && props.fare && (
          <Block marginLeft={8}>
            <Label aria-label={t('RailDetails.Labels.Cheapest')} type="cheapest">
              {t('RailDetails.Labels.Cheapest')}
            </Label>
          </Block>
        )}
        {railCardName && (
          <RailIconContainer>
            <Tooltip
              isIndependentOfParentWidth
              slot={
                <>
                  <Text variant="subHeadStrong">{railCardName}</Text>
                  <Text variant="subHeadMedium">&nbsp;{t(i18Base + '.Applicable')}</Text>
                </>
              }>
              <Icon name="loyaltyCardPWA" size="small" color="success" />
            </Tooltip>
          </RailIconContainer>
        )}
      </Block>
      <FareInfo {...props} />
    </ContainerShort>
  )
}
