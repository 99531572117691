import { useCallback } from 'react'
import { useAppDispatch } from '@fiji/store'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query/use-hotel-search-query'
import type { Address } from '@fiji/graphql/types'
import { updateHotelId } from '@fiji/store/segment-ids'
import { ROUTES } from '@fiji/routes'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates/get-date-without-timezone-offset'
import { PostBookingAction } from '@fiji/enums'
import { resolveLocation } from '../../trip-hotel/helpers'

type Props = {
  itineraryId: string
  handleCloseChangeModal: () => void
  id: string
  checkIn: string
  checkOut: string
  address: Address
}

export function useChangeRoom({
  itineraryId,
  handleCloseChangeModal,
  id,
  checkIn,
  checkOut,
  address,
}: Props) {
  const dispatch = useAppDispatch()

  const { navigateTo, queryParams } = useHotelSearchQuery()
  const { bookingId } = queryParams

  const handleChangeRoom = useCallback(() => {
    const checkInDate = getDateWithoutTimezoneOffset(checkIn)
    const checkOutDate = getDateWithoutTimezoneOffset(checkOut)
    dispatch(updateHotelId({ hotelId: id }))
    handleCloseChangeModal()
    navigateTo(ROUTES.hotel.results, {
      itineraryId,
      checkInDate,
      checkOutDate,
      location: resolveLocation(address),
      bookingId,
      postBookingAction: PostBookingAction.Modify,
    })
  }, [
    dispatch,
    handleCloseChangeModal,
    navigateTo,
    itineraryId,
    id,
    checkIn,
    checkOut,
    address,
    bookingId,
  ])

  return {
    handleChangeRoom,
  }
}
