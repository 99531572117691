import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { HotelDetailsEcoCheckDesktop } from './desktop'
import { HotelDetailsEcoCheckMobile } from './mobile'

export function HotelDetailsEcoCheckLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HotelDetailsEcoCheckMobile {...props} />}
      desktopSlot={<HotelDetailsEcoCheckDesktop {...props} />}
    />
  )
}
