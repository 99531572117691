import { Screen } from '@etta/ui/screen'
import type { LayoutProps } from '../../types'
import { CarRentalHeaderMobile } from './car-rental-header-mobile'

export function CarRentalSearchResultsMobile({ contentSlot }: LayoutProps) {
  return (
    <Screen backgroundColor="background">
      <Screen.Header>
        <CarRentalHeaderMobile />
      </Screen.Header>
      <Screen.Container as={'main'}>{contentSlot}</Screen.Container>
    </Screen>
  )
}
