import { Store } from '@etta/di'

@Store()
export class CookieStore {
  private cookieValue(name: string) {
    const regex: RegExp = new RegExp(`;?\\s*${name}=([^;]+)`)
    const match: string[] | null = document.cookie.match(regex)

    return match?.[1] ?? null
  }

  isLoggedIn() {
    return this.cookieValue('exp') !== null
  }
}
