import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const SelectorsContainer = styled.div`
  gap: 12px;
  display: flex;

  max-width: 57%;
  @media ((screen) and (${screenSize.isTablet})) {
    max-width: 44%;
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 1600px;
  width: 100%;
  padding: 8px 32px;
  gap: 12px;
`
