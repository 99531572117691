import type { BookRideHailResponse } from '@fiji/graphql/hooks'
import type { RideHailBookingValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-booking.value-object'
import { deleteTimezone, getTimezoneOffset } from '@fiji/utils/dates'
import type { PlainTime } from '@fiji/types'

export function mapToRideHailBookingValueObject(
  input: BookRideHailResponse,
): RideHailBookingValueObject {
  const pickUpDate = input.bookingInfo?.pickUpTime
    ? new Date(deleteTimezone(input.bookingInfo?.pickUpTime))
    : undefined
  const pickUpTime: PlainTime = {
    hours: pickUpDate?.getHours() ?? 0,
    minutes: pickUpDate?.getMinutes() ?? 0,
  }
  const pickUpTimezoneOffset = input.bookingInfo?.pickUpTime
    ? getTimezoneOffset(input.bookingInfo.pickUpTime)
    : undefined
  return {
    bookingId: input.bookingInfo ? input.bookingInfo?.bookingId : input.bookingID,
    description: input.bookingInfo?.description ?? undefined,
    dropOffAddress: input.bookingInfo?.dropOffAddress ?? undefined,
    estimatedPriceRange: input.bookingInfo?.estimatedPriceRange ?? undefined,
    pickUpAddress: input.bookingInfo?.pickUpAddress ?? undefined,
    pickUpDate,
    pickUpTime,
    pickUpTimezoneOffset,
    price: input.bookingInfo?.price ?? undefined,
    productName: input.bookingInfo?.productName ?? undefined,
    status: input.bookingInfo?.status ?? undefined,
  }
}
