import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

export const HotelCardWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  height: 96px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 0 4px;
`
export const HotelImageWrapper = styled.div`
  position: relative;
  width: 27%;
  overflow: hidden;
  border-radius: 10px;

  > * img {
    width: 200%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
export const HotelTitle = styled.div`
  ${subHeadStrong}
`

export const HotelCardDescriptionWrapper = styled.div`
  width: 73%;
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
`

export const HotelCardDescription = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

export const HotelCardImage = styled.div`
  position: relative;
  width: 27%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const HotelDetails = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`

export const StarRatingPlaceholder = styled.div`
  height: 18.5px;
`
