import { StyledTextVariant } from '@fiji/enums'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { Text } from '@etta/ui/text'
import type { TimePickerProps } from '../types'
import { Container, Header, Label, List, Option } from './swiping-time-picker-desktop-styled'
import { useSwipingTimePickerDesktop } from './use-swiping-time-picker-desktop'

export function SwipingTimePickerDesktop({
  isVisible,
  label,
  defaultValue,
  value,
  onApply,
  onClose,
}: TimePickerProps) {
  const { ref } = useClickOutside(() => {
    if (isVisible) {
      onClose()
    }
  })
  useShouldOpenInViewport({ isVisible, ref })

  const { timeLabel, timeRangeOptions, handleTimeSelect } = useSwipingTimePickerDesktop({
    defaultValue,
    value,
    onApply,
    onClose,
  })

  if (!isVisible) {
    return null
  }

  return (
    <Container ref={ref}>
      <Header>
        <Label>
          <Text variant={StyledTextVariant.captionMedium} color="bodyText">
            {label}
          </Text>
        </Label>

        <Text variant={StyledTextVariant.headline} color="mainText">
          {timeLabel}
        </Text>
      </Header>

      <List>
        {timeRangeOptions.map((time, i) => (
          <Option key={i} onClick={handleTimeSelect(time)}>
            <Text variant={StyledTextVariant.subHeadMedium} color="mainText">
              {getFormattedTime(time, 'p')}
            </Text>
          </Option>
        ))}
      </List>
    </Container>
  )
}
