import type { FlightFareSegment, FlightLegSubSegment } from '@fiji/graphql/types'
import type { SearchFlightLegFareValueObject } from '@etta/modules/seat-map/core/value-objects'

export class FaresMapper {
  static toFareEntities(fares: SearchFlightLegFareValueObject[]) {
    return (
      fares.map(({ segments }) => {
        return {
          segments,
        }
      }) || []
    )
  }

  static toFareEntitiesFromItineraryFlight(
    flightSegments: FlightLegSubSegment,
  ): Array<{
    segments?: FlightFareSegment[] | null
  }> {
    return [
      {
        segments: [
          {
            bookingCode: flightSegments.bookingCode || '',
            segmentId: flightSegments.segmentId || '',
            serviceClass: flightSegments.serviceClass,
            displayServiceClass: flightSegments.serviceClass,
            basisCode: flightSegments.fare,
          },
        ],
      },
    ]
  }
}
