import type { GeocodeValueObject } from '@etta/core/value-objects'
import { Inject, Service } from '@etta/di'
import type { CreateAirSearchInput } from '@etta/modules/air-search/core/value-objects/create-air-search.input'
import type { FlightLegSearchInputValueObject } from '@etta/modules/air-search/core/value-objects/flight-leg-search.input'
import type { GetFlightsInput } from '@etta/modules/air-search/core/value-objects/get-flights.input'
import { PostBookingAction, TimeRangeDirection, TimeRangeOption } from '@fiji/enums'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { dateFormat, dateToStringWithoutTZ } from '@fiji/utils/dates'
// eslint-disable-next-line import/no-restricted-paths
import { FareTier } from '@fiji/graphql/hooks'
import type { ExistingOutboundFlightSegmentInput } from '@etta/modules/air-search/core/value-objects/existing-outbound-flight-segment.input'
import { PostBookingTripStore } from '@etta/modules/post-booking/interface/stores/trip/post-booking-trip.store'
import type { GetFlightsInputArgs } from '../../types'
import { AirSearchQueryStore } from '../../stores/air-search-query.store'
import { AirSearchStore } from '../../stores/air-search/air-search.store'

@Service()
export class AirSearchInputService {
  constructor(
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private postBookingTripStore: PostBookingTripStore,
  ) {}

  public createAirSearchInput(
    flightLegSearch: FlightLegSearchInputValueObject[] | null = null,
  ): CreateAirSearchInput {
    return {
      flightLegSearch: flightLegSearch ?? this.getFlightLegSearch(),
      numberOfPassengers: 1,
      // TODO handle nearbyAirports
      includeNearbyAirports: true,
      processId: this.getProcessId(),
      isReturnFlightChangeOnly: this.airSearchQueryStore.isReturnFlightChangeOnly,
      existingOutboundFlightSegments: this.getExistingOutboundFlightSegments(),
    }
  }

  private getExistingOutboundFlightSegments(): ExistingOutboundFlightSegmentInput[] {
    const {
      searchQueryFlightType: flightType,
      additionalQueries: { postBookingAction },
    } = this.airSearchQueryStore
    const { flightSegments } = this.postBookingTripStore
    const outboundSegments = flightSegments[0]?.segments

    if (flightType === FlightType.Round && postBookingAction === PostBookingAction.ModifyReturn) {
      return (outboundSegments || []).map((segment) => ({
        airlineCode: segment.carrierCode,
        flightNumber: segment.flightNumber,
        originAirportCode: segment.departure?.airportCode,
        destinationAirportCode: segment.arrival?.airportCode,
        serviceClass: segment.serviceClass,
        fareBasisCode: segment.fare,
        departureDateTime: segment.departureDate,
        arrivalDateTime: segment.arrivalDate,
      }))
    }
    return []
  }

  private getFlightLegSearch(): FlightLegSearchInputValueObject[] {
    const {
      searchQueryFlightType: flightType,
      searchQueryFlights: flights,
    } = this.airSearchQueryStore

    const flightLegSearch = flights.map<FlightLegSearchInputValueObject>((flight, index) => {
      const { cabinClass, timeRange, departureDate, destinationPlace, originPlace } = flight
      const selectedCabinClass =
        flightType === FlightType.Round ? flights[0].cabinClass : cabinClass
      return {
        number: index,
        destination: destinationPlace?.airportCode,
        origin: originPlace?.airportCode,
        destinationLocation: this.assignGeocode({
          lat: destinationPlace?.latitude,
          long: destinationPlace?.longitude,
        }),
        originLocation: this.assignGeocode({
          lat: originPlace?.latitude,
          long: originPlace?.longitude,
        }),
        departureDate: departureDate ? dateFormat(departureDate, 'y-MM-dd') : '0000-00-00',
        timeRangeBy: timeRange.timeRangeBy || TimeRangeDirection.Departure,
        startTimeRange: timeRange.startTimeRange,
        endTimeRange: timeRange.endTimeRange,
        preferredTime: '',
        serviceClass: selectedCabinClass.id,
        destinationCity: destinationPlace?.city,
        destinationCountryCode: destinationPlace?.countryCode,
        destinationStateCode: destinationPlace?.stateCode,
        originCity: originPlace?.city,
        originCountryCode: originPlace?.countryCode,
        originStateCode: originPlace?.stateCode,
      }
    })

    if (flightType === FlightType.OneWay) {
      return [flightLegSearch[0]]
    }

    if (flightType === FlightType.Round) {
      return [flightLegSearch[0], flightLegSearch[1]]
    }

    return flightLegSearch
  }

  public getFlightsInput(args: GetFlightsInputArgs = {}): GetFlightsInput {
    const searchId = this.airSearchStore.searchId
    const currentLegNumber = this.airSearchStore.legNumber

    if (!searchId) {
      throw new Error("Can't get flights without searchId")
    }

    if (currentLegNumber === null) {
      throw new Error('Leg number is missing')
    }

    const { additionalQueries, searchQueryFlights } = this.airSearchQueryStore
    const selectedFlights = args.selectedFlights ?? this.airSearchQueryStore.selectedFlights

    const sortBy = this.airSearchStore.sortBy
    const filters = args.filters ?? additionalQueries.filters
    const after = this.airSearchStore.searchPageEnd
    const flightLeg = searchQueryFlights[currentLegNumber]
    const stopFilters = filters?.stops?.map((stop) => stop.value)
    const airlineFilters = filters?.airlines?.map((airline) => airline.value)
    const filterFareTiers = filters?.fareTypes?.map((fare) => fare.value)
    const destinationAirportFilters = filters?.destinationAirports
      ?.map((airport) => airport.code)
      .filter(Boolean)
    const originAirportFilters = filters?.originAirports
      ?.map((airport) => airport.code)
      .filter(Boolean)
    const excludeOutOfPolicy = filters?.isMatchedWithPolicy

    const originTime = filters?.originTime
    const destinationTime = filters?.destinationTime

    const takeoffLandingRanges: GetFlightsInput['takeoffLandingRanges'] =
      originTime && destinationTime
        ? [
            {
              legPosition: currentLegNumber,
              landing: {
                end: dateToStringWithoutTZ(destinationTime?.end),
                start: dateToStringWithoutTZ(destinationTime?.start),
              },
              takeoff: {
                end: dateToStringWithoutTZ(originTime?.end),
                start: dateToStringWithoutTZ(originTime?.start),
              },
            },
          ]
        : []

    const selectedLegs: GetFlightsInput['selectedLegs'] =
      selectedFlights?.slice(0, currentLegNumber)?.map((selectedFlight, index) => {
        return { id: selectedFlight.id, position: index }
      }) || []

    const selectedFareTiers: GetFlightsInput['filterFareTiers'] =
      selectedFlights?.slice(0, currentLegNumber).map((selectedFlight) => {
        const fareTier =
          Object.values(FareTier).find((value) => value === selectedFlight.selectedFareTier) ||
          FareTier.RestrictedExpectFees
        return fareTier
      }) || []

    const selectedServiceClasses: string[][] =
      selectedFlights?.slice(0, currentLegNumber).map((selectedFlight) => {
        return selectedFlight.selectedServiceClasses
      }) || []

    const selectedFareIds: GetFlightsInput['selectedFareIds'] =
      selectedFlights?.slice(0, currentLegNumber)?.map((selectedFlight) => {
        return selectedFlight.selectedFareId
      }) || []

    return {
      searchId,
      legPosition: currentLegNumber,
      stopFilters,
      airlineFilters,
      filterFareTiers,
      destinationAirportFilters,
      originAirportFilters,
      after,
      isCustomTimeSearch: flightLeg.timeRange.id === TimeRangeOption.CustomTime,
      excludeOutOfPolicy,
      sortBy,
      selectedLegs,
      selectedFareTiers,
      selectedFareIds,
      selectedServiceClasses,
      takeoffLandingRanges,
      unusedTicket: true,
      includeNearbyAirports: true,
      isReturnFlightChangeOnly: this.airSearchQueryStore.isReturnFlightChangeOnly,
      isNdc: this.getIsNdc(),
      existingOutboundFlightSegments: this.getExistingOutboundFlightSegments(),
      bookingProvider: this.getBookingProvider(),
    }
  }

  private getBookingProvider() {
    const { flightSegments } = this.postBookingTripStore
    const {
      additionalQueries: { bookingId },
    } = this.airSearchQueryStore

    return bookingId && flightSegments[0]?.segments?.[0]?.bookingProvider
  }

  private getIsNdc() {
    const {
      searchQueryFlightType: flightType,
      additionalQueries: { postBookingAction },
    } = this.airSearchQueryStore

    return flightType === FlightType.Round && postBookingAction === PostBookingAction.ModifyReturn
      ? this.postBookingTripStore.isNdc
      : undefined
  }

  private assignGeocode({ lat, long }: Partial<GeocodeValueObject>) {
    return lat && long ? { lat, long } : undefined
  }

  private getProcessId() {
    const {
      additionalQueries: { bookingId, postBookingAction },
    } = this.airSearchQueryStore

    if (bookingId && postBookingAction !== PostBookingAction.Add) {
      return parseInt(bookingId)
    }

    return undefined
  }
}
