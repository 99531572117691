import { rollbar } from '@fiji/rollbar'
import { Action, Inject } from '@etta/di'
import { NavigationService } from '@etta/modules/navigation/interface/services/navigation.service'
import { RedirectUrl } from '../../core/enums'

@Action()
export class NavigationAction {
  constructor(
    @Inject()
    private navigationService: NavigationService,
  ) {}

  async handleSetRcSessionCookie(url?: string) {
    if (!url) {
      const errorText = 'Site url does not exist'
      rollbar.error(errorText)
      throw new Error(errorText)
    }

    await this.navigationService.handleSetRcSessionCookie(url)
  }

  async handleSetRcSessionCookieForGazooRegistrationPage(url?: string) {
    if (!url) {
      const errorText = 'Site url does not exist'
      rollbar.error(errorText)
      throw new Error(errorText)
    }

    await this.navigationService.handleSetRcSessionCookie(
      url,
      false,
      RedirectUrl.GAZOO_ACOUNT_REGISTRATION_URL,
    )
  }
}
