const DEFAULT_SLIDER_VALUES = [0.25, 0.5, 1, 2, 5, 10, 20]

export const getSlider = (max: number) => {
  const slider = [...DEFAULT_SLIDER_VALUES]
  if (slider.findIndex((el) => el > max) === -1) {
    slider.splice(slider.length - 1, 1, max)
    return slider
  }
  const index = slider.findIndex((el) => el >= max)
  const deleteCount = slider.length - index
  slider.splice(index, deleteCount, max)

  return slider
}
