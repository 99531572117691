import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { RangeResult } from '../types'
import { changeArrivalDate, changeReturnDate } from './change-date'

export function convertTrainToRangePostBooking(
  trainSegments: TrainPostBookingValueObject[],
): RangeResult {
  if (!trainSegments.length || !trainSegments[0].segments) {
    return {}
  }

  const destinationSegment = trainSegments[0].segments[trainSegments[0].segments.length - 1]

  const lastTrainSegment = trainSegments[trainSegments.length - 1]
  const firstTrainInLast = lastTrainSegment.segments?.[0]
  const lastSegment = lastTrainSegment.segments?.[lastTrainSegment.segments.length - 1]

  const isRoundTrip =
    trainSegments.length > 1 &&
    trainSegments[0].segments[0].departureStationCode === lastSegment?.arrivalStationCode

  return {
    ...(isRoundTrip
      ? {
          startDate: destinationSegment.arrivalDate
            ? changeArrivalDate(destinationSegment.arrivalDate)
            : undefined,
          endDate: firstTrainInLast?.departureDate
            ? changeReturnDate(firstTrainInLast.departureDate)
            : undefined,
        }
      : {
          startDate: changeArrivalDate(trainSegments[0].arrivalDateTime),
          endDate: undefined,
        }),
  }
}
