import styled from 'styled-components'
import { getKeyboardNavigationStyle, linkSmall } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`

export const ManageButton = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  ${linkSmall};
  ${getKeyboardNavigationStyle({})}
`

export const ButtonList = styled.div`
  z-index: 11;
  position: absolute;
  top: -5px;
  width: 170px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 8px;
  box-shadow: 0px 12px 12px 0px #0000000f;
  overflow: hidden;
`

export const ButtonItem = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  color: ${({ theme }) => theme.colors.mainText};
  transition: all 0.3s;
  border: 0;
  background-color: ${({ theme }) => theme.colors.white};
  ${linkSmall};
  &:hover {
    background-color: ${({ theme }) => theme.colors.background4};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
  ${getKeyboardNavigationStyle({ offset: -3 })};
`

export const ChevronIcon = styled(Icon).attrs(() => ({
  name: 'chevronDownPWA',
  size: 'small',
  color: 'primary',
}))``
