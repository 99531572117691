import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import { WeekContainerDesktopStyled } from './desktop/week-container-desktop-styled'

import { WeekContainerMobileStyled } from './mobile/week-container-mobile-styled'

export function WeekContainerLayout(props: PropsWithChildren<unknown>) {
  return (
    <MediaLayout
      mobileSlot={<WeekContainerMobileStyled {...props} />}
      desktopSlot={<WeekContainerDesktopStyled {...props} />}
    />
  )
}
