import styled, { css } from 'styled-components'

export enum Grids {
  OriginStation = 'originStation',
  DestinationStation = 'destinationStation',
  IntermediateStation = 'intermediateStation',
  ViewHideStations = 'viewHideStations',
}

export const GridContainer = styled.div<{ gridTemplateAreas: string }>`
  display: grid;
  grid-template-areas: ${(p) => p.gridTemplateAreas};
  margin-top: 14px;
`

export const GridRowItem = styled.div<{
  gridArea: Grids
  isFirstItem?: boolean
  isLastItem?: boolean
  isRtp: boolean
}>`
  position: relative;
  grid-area: ${(p) => p.gridArea};
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    content: '';
    left: 6px;
    width: 2px;
    height: ${(p) => {
      if (p.isFirstItem || p.isLastItem) {
        return '50%'
      }

      return '100%'
    }};
    z-index: 0;
    background-color: ${(p) => p.theme.colors[p.isRtp ? 'bodyText' : 'borderDark']};

    ${(p) => {
      if (p.isFirstItem) {
        return css`
          transform: translate(0, 50%);
        `
      }

      if (p.isLastItem) {
        return css`
          transform: translate(0, -50%);
        `
      }
    }}
  }
`
