import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'

export function CheckoutPageModalHeaderMobile({
  title,
  onClose,
  children,
  ...headerProps
}: LayoutProps) {
  return (
    <Modal.Header isMobile>
      <Header leftSlot={<Header.CloseButton onClick={onClose} />} {...headerProps}>
        <Header.Title title={title} align="left" />
      </Header>
      {children}
    </Modal.Header>
  )
}
