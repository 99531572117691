import { RideHailSuggestionLocationType } from '@fiji/graphql/hooks'
import { RideHailSuggestionLocationEnum } from '../../core/enum/suggestion-location-type.enum'

export function mapLocationTypeEnum(
  type: RideHailSuggestionLocationType,
): RideHailSuggestionLocationEnum {
  switch (type) {
    case RideHailSuggestionLocationType.Airport:
      return RideHailSuggestionLocationEnum.Airport
    case RideHailSuggestionLocationType.Home:
      return RideHailSuggestionLocationEnum.Home
    case RideHailSuggestionLocationType.Hotel:
      return RideHailSuggestionLocationEnum.Hotel
    case RideHailSuggestionLocationType.Unspecified:
      return RideHailSuggestionLocationEnum.Unspecified
  }
}
