import styled from 'styled-components'
import { CONTENT_MARGIN_TOP } from '@etta/screens/trip-flight/constants'
import type { ColorPalette } from '@fiji/types'

export const DetailsContainer = styled.div<{ color: keyof ColorPalette }>`
  background-color: ${({ color, theme }) => theme.colors[color]};
  padding: 8px 0 ${Math.abs(CONTENT_MARGIN_TOP) + 22}px 24px;
`

export const Paper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  overflow: hidden;
`
