import { formatRate } from '@fiji/utils/money/format-rate'
import { subtractRate } from '@fiji/utils/rate'
import type { Rate, TripCost } from '@fiji/graphql/types'

type Args = {
  tripCost: TripCost
  unusedTicketRate?: Rate | null
  unusedTicketPenalty?: Rate | null
  hasOptOutUnusedTickets?: boolean
}

function calculateTotalWithTicketRate(total: Rate, ticket: Rate): Rate {
  if (ticket.primary.amount > total.primary.amount) {
    return {
      primary: {
        amount: 0,
        currency: total.primary.currency,
      },
      secondary: total.secondary
        ? {
            amount: 0,
            currency: total.secondary.currency,
          }
        : undefined,
    }
  }

  return subtractRate(total, ticket)
}

export function getFlightsPrices({ tripCost, unusedTicketRate, hasOptOutUnusedTickets }: Args) {
  const { mainCost: baseRate } = formatRate(tripCost.base, {
    morpheme: 'none',
  })

  // TODO: move this calculations to graphql side after
  const totalWithUnusedTicketRate =
    unusedTicketRate && !hasOptOutUnusedTickets
      ? calculateTotalWithTicketRate(tripCost.total, unusedTicketRate)
      : tripCost.total

  return {
    totalWithUnusedTicketRate,
    baseRate,
  }
}
