import { useCallback, useEffect, useMemo } from 'react'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { TravelerOptions } from '@fiji/graphql/types'
import { useLoadPurchaseInfo } from '@etta/screens/payment-summary/use-payment-summary/use-load-purchase-info'
import { usePurchaseInfoContext } from '@etta/modules/booking/interface/purchase-info/purchase-info.context'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { getMembershipAvailability } from './get-membership-availability'
import { getSegmentsExisting } from './get-segments-existing'
import { getSegmentsState } from './get-segments-state'

export function useLoadTravelerInfo() {
  const { reviewTripStore, tripActions } = useReviewTripContext()

  useEffect(() => {
    if (reviewTripStore.trip) {
      return
    }
    tripActions.loadTrip()
  }, [reviewTripStore.trip, tripActions])

  const {
    traveler,
    travelerOptions,
    areCustomFieldsExist,
    isCostAllocationExist,
    isPassportRequired,
  } = useLoadPurchaseInfo()

  const { purchaseInfoActions } = usePurchaseInfoContext()

  const segments = reviewTripStore.segments

  const segmentsExisting = getSegmentsExisting(segments)
  const segmentsState = getSegmentsState(segments)

  const {
    checkoutInfoStore: { isTravelerDataLoading, isTravelerDataError },
  } = useCheckoutInfoContext()

  const isTravelerInfoLoading = reviewTripStore.isTripLoading || isTravelerDataLoading
  const isTravelerInfoError = reviewTripStore.isError || isTravelerDataError

  const tripCost = reviewTripStore.trip?.tripCost

  const tripRate = tripCost?.totalWithUnusedTicket || tripCost?.total

  const membershipState = getMembershipAvailability({ segments })

  const primaryTravelerOptions: TravelerOptions = useMemo(() => {
    if (!travelerOptions) {
      return {}
    }

    const isDateOfBirthRequired =
      travelerOptions.isDateOfBirthRequired || segmentsExisting.isRailExist

    return {
      ...travelerOptions,
      isDateOfBirthRequired: isDateOfBirthRequired,
      isGenderRequired: travelerOptions.isGenderRequired || segmentsExisting.isRailExist,
    }
  }, [travelerOptions, segmentsExisting.isRailExist])

  const onReloadTravelerInfo = useCallback(() => {
    tripActions.updateTrip()
    purchaseInfoActions.fetchTravelerDataFromCheckoutInfo()
  }, [purchaseInfoActions, tripActions])

  return {
    onReloadTravelerInfo,
    tripRate,
    isTravelerInfoLoading,
    isTravelerInfoError,
    segmentsExisting,
    segmentsState,
    primaryTravelerOptions,
    areCustomFieldsExist,
    membershipState,
    isPassportRequired,
    primaryTraveler: traveler,
    isCostAllocationExist,
  }
}
