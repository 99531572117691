import type { MouseEvent } from 'react'
import { useCallback } from 'react'
import type { Props } from '../types'
import { InputButtonSize } from '../types'
import { InputIcon } from '../input-icon'
import { Button, Value, Label, ValueWrapper, Placeholder } from './input-button-mobile-styled'

export function InputButtonMobile({
  value,
  icon,
  label,
  onClick,
  size = InputButtonSize.Normal,
  placeholder,
  isMediumWeight,
  className,
  isFilled = true,
  isInteractive = true,
  'aria-label': ariaLabel,
  'data-tracking-id': dataTrackingId,
  hasError = false,
  disabled,
  iconRight,
  iconRightSize,
  iconRightColor,
  onRightIconClick,
}: Props) {
  const isValueExist = !!(value || placeholder)
  const isIconExist = !!icon

  const handleRightIconClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      onRightIconClick?.()
    },
    [onRightIconClick],
  )

  return (
    <Button
      disabled={disabled}
      hasError={hasError}
      data-tracking-id={dataTrackingId}
      aria-label={ariaLabel || label}
      onClick={() => (isInteractive ? onClick() : null)}
      className={className}>
      <Label aria-hidden isValueExist={isValueExist} isIconExist={isIconExist} size={size}>
        {!isValueExist && <InputIcon icon={icon} isValueExist={isValueExist} />}
        <div>{label}</div>
      </Label>

      {isValueExist && (
        <ValueWrapper size={size} isLabelExist={!!label}>
          <InputIcon
            icon={icon}
            isValueExist={isValueExist}
            isPlaceholder={!!(placeholder && !value)}
            color={isFilled ? 'mainText' : 'bodyText2'}
            size="small"
          />
          <Value size={size} isMediumWeight={isMediumWeight} isAvalible={isFilled}>
            {value || <Placeholder>{placeholder}</Placeholder>}
          </Value>
          <InputIcon
            icon={iconRight}
            size={iconRightSize}
            color={iconRightColor}
            isValueExist={isValueExist}
            onClick={handleRightIconClick}
          />
        </ValueWrapper>
      )}
    </Button>
  )
}
