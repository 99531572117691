import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProps } from './types'
import { HotelDetailsLayoutMobile } from './mobile'
import { HotelDetailsLayoutDesktop } from './desktop'

export function HotelDetailsLayout({ children, ...props }: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<HotelDetailsLayoutMobile {...props}>{children}</HotelDetailsLayoutMobile>}
      desktopSlot={<HotelDetailsLayoutDesktop {...props}>{children}</HotelDetailsLayoutDesktop>}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
