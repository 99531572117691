import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type { CheckoutItineraryInput } from '@fiji/graphql/hooks'
import { GetCheckoutInfoDocument, CheckoutItineraryDocument } from '@fiji/graphql/hooks'
import type {
  GetCheckoutInfoQuery,
  GetCheckoutInfoQueryVariables,
  CheckoutItineraryMutation,
  CheckoutItineraryMutationVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class CheckoutInfoDataProvider extends GraphqlDataProvider {
  handleCheckoutInfo(input: GetCheckoutInfoQueryVariables['input'], forceUpdate?: boolean) {
    return this.client.query<GetCheckoutInfoQuery, GetCheckoutInfoQueryVariables>({
      query: GetCheckoutInfoDocument,
      variables: { input },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  handleCheckoutItinerary(input: CheckoutItineraryInput) {
    return this.client.mutate<CheckoutItineraryMutation, CheckoutItineraryMutationVariables>({
      mutation: CheckoutItineraryDocument,
      variables: { input },
    })
  }
}
