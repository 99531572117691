import { observer } from 'mobx-react-lite'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { useTripExpirationTimer } from '@fiji/hooks/use-trip-expiration-timer'
import { Bag } from './bag'
import { List } from './list'
import { Wrapper, Container } from './trip-cart-styled'
import { useTripCart } from './use-trip-cart'
import { TripCartHiddenTimer } from './trip-cart-hidden-timer'

export const TripCart = observer(function TripCart() {
  const {
    listItems,
    isEmptyItinerary,
    itemsAmount,
    isDisabled,
    isListOpen,
    handleOpenList,
    handleCloseList,
    handleKeyDown,
    listCityLabel,
    handleReview,
    datesLabel,
    popupRef,
    toggleRef,
    onTripExpired,
  } = useTripCart()

  const {
    isExpiredTrip,
    isTripRemovingLoading,
    handleDeleteTrip,
    viewState,
    handleExpireEvent,
  } = useTripExpirationTimer({
    isActive: true,
    isEmptyItinerary,
    onExpire: onTripExpired,
  })

  if (isEmptyItinerary) {
    return null
  }

  return (
    <Wrapper>
      <ViewStateModal viewState={viewState} />
      <Container expired={isExpiredTrip}>
        <Bag
          isExpiredItinerary={isExpiredTrip}
          itemsAmount={itemsAmount}
          onMouseEnter={handleOpenList}
          onKeyDown={handleKeyDown}
          onReview={isExpiredTrip ? () => {} : handleReview}
          forwardRef={toggleRef}
          isDisabled={isDisabled}
        />
      </Container>
      <List
        deleteTripLoading={isTripRemovingLoading}
        onDeleteTripClick={handleDeleteTrip}
        isTripExpired={isExpiredTrip}
        forwardRef={popupRef}
        isOpen={isListOpen}
        cityLabel={listCityLabel}
        isDisabled={isDisabled}
        dateLabel={datesLabel}
        onClose={handleCloseList}
        handleReviewAndCheckout={handleReview}
        items={listItems}
      />
      <TripCartHiddenTimer
        isActive
        isEmptyItinerary={isEmptyItinerary}
        isExpiredTrip={false}
        handleExpireEvent={handleExpireEvent}
      />
    </Wrapper>
  )
})
