import styled, { css } from 'styled-components'

import { transitionsClassnames } from '../page-transitions'

export const Container = styled.div<{
  isAnimationAvailable: boolean
}>`
  height: 100%;

  ${(props) => {
    if (!props.isAnimationAvailable) {
      return ''
    }

    return css`
      position: relative;
      overflow: hidden;
      ${transitionsClassnames};
    `
  }};
`
