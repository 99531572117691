import { Dialog } from '@etta/ui/dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { LayoutProps } from '../../types'
import { EditTripNameDialogMobileContent } from './edit-trip-name-dialog-mobile-content'
import { DialogWrapper } from './edit-trip-name-dialog-mobile-styled'

export function EditTripNameDialogMobile({
  isOpen,
  onClose,
  viewState,
  onCloseViewStateModal,
  ...props
}: LayoutProps) {
  return (
    <>
      <DialogWrapper isOpen={isOpen} onClose={onClose}>
        <Dialog.Body>
          <EditTripNameDialogMobileContent {...props} onClose={onClose} />
        </Dialog.Body>
      </DialogWrapper>
      <ViewStateModal viewState={viewState} onDone={onCloseViewStateModal} />
    </>
  )
}
