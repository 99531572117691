import styled, { css } from 'styled-components'
import { screenSize, withStyledText } from '@fiji/style'
import type { StyledTextVariant } from '@fiji/enums'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { Layout } from '@etta/ui/layout'
import type { ModalPosition } from '@fiji/types'
import { slideRight } from './modal-animation/transition-slide-right'
import { slideUp } from './modal-animation/transition-slide-up'
import { slideDown } from './modal-animation/transition-slide-down'

export const Container = styled(Layout.Root)<{
  position: ModalPosition
  isAnimated: boolean
  isHalfModal?: boolean
}>`
  top: ${(props) => (props.isHalfModal ? 'unset' : 0)};
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: auto;
  height: ${(props) => (props.isHalfModal ? 'auto' : '100%')};
  border-radius: ${(props) => (props.isHalfModal ? '12px' : '0px')};
  @media (${screenSize.minTablet}) {
    background-color: transparent;
  }
  z-index: 21;
  ${(props) => {
    if (!props.isAnimated) {
      return
    }

    switch (props.position) {
      default:
      case 'center':
        return slideUp
      case 'right':
        return slideRight
      case 'top':
        return slideDown
    }
  }}
`

export const Title = styled.div<{
  textVariant?: StyledTextVariant
}>`
  display: flex;
  margin: 0 0 26px;
  font-weight: bold;
  font-size: 2rem;
  padding-right: 26px;
  line-height: 21px;
  justify-content: center;
  ${withStyledText};
`

export const ModalContainer = styled(Layout.Container)<{
  padding?: 'none' | 'tablet' | 'normal'
}>`
  ${(props) => {
    switch (props.padding) {
      default:
        return css`
          padding: 0;
        `
      case 'tablet':
        return css`
          padding: 20px 14px 10px;
          @media (${screenSize.maxMobile}) {
            padding: 0;
          }
        `
      case 'normal':
        return css`
          padding: 20px;
        `
    }
  }};
`

export const ModalHeaderWrapper = styled(Layout.Header)<{
  isMobile?: boolean
  backgroundColor?: LayoutBackgroundColor
  withBorder?: boolean
}>`
  padding-top: ${(props) => (props.isMobile ? '0' : '20px')};
  border-bottom: ${({ theme, withBorder }) =>
    withBorder ? `1px solid ${theme.colors.borderLight}` : 'none'};
`

export const ModalFooter = styled(Layout.Footer)<{
  height?: string
  padding?: 'top' | 'normal'
  withBorder?: boolean
}>`
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: ${(props) => {
    switch (props.padding) {
      default:
        return '0'
      case 'top':
        return '16px 0 0 0'
      case 'normal':
        return '16px'
    }
  }};
  border-top: ${(p) => (p.withBorder ? `1px solid ${p.theme.colors.borderLight}` : 'none')};
  width: auto;
`
