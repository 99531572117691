import { TripCardSkeleton } from '@etta/components/trip-card-skeleton/trip-card-skeleton'
import { MonthSkeleton } from '../../month-skeleton'
import { ListWrapper, MonthWrapper, CardsWrapper } from './trip-list-skeleton-desktop-styles'

export function TripListSkeletonDesktop() {
  return (
    <ListWrapper>
      <div>
        <MonthWrapper>
          <MonthSkeleton />
        </MonthWrapper>
        <CardsWrapper>
          <TripCardSkeleton />
          <TripCardSkeleton />
          <TripCardSkeleton />
        </CardsWrapper>
      </div>
    </ListWrapper>
  )
}
