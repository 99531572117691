import styled from 'styled-components'
import { title3 } from '@fiji/style'

export const Container = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 13px 16px 10px;
`

export const Price = styled.div`
  display: flex;
  justify-content: space-between;
  ${title3}
`
