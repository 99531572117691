import { useState, useMemo } from 'react'
import type { CalculateCarEmissionsBatchResultCar } from '@fiji/graphql/hooks'
import { useCalculateCarEmissionsBatchQuery } from '@fiji/graphql/hooks'
import type { Place } from '@fiji/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { Cars } from '../../../etta-screens/car-rental-search-results/types'
import { logValidationErrorToRollbar, SKIP_VALIDATION_ERROR } from '../common'
import { mapToThrustCarbonCar, mapNumberOfDays } from './map-to-thrust-carbon-car'
import { carRentalEmissionsQueryExecuteValidation } from './car-rental-emissions-query-execute-validation'

type CarRentalEmissionsArgs = {
  cars: Cars
  pickUpDate: Date
  dropOffDate: Date
  pickUpPlace: Place
  dropOffPlace: Place
}

export function useCarRentalEmissions({
  cars,
  pickUpDate,
  dropOffDate,
  pickUpPlace,
  dropOffPlace,
}: CarRentalEmissionsArgs) {
  const {
    featureFlags: { isSustainabilityEnabled },
  } = useFeatureFlags()
  const [existingCarRentalEmissions, setExistingCarRentalEmissions] = useState<
    CalculateCarEmissionsBatchResultCar[]
  >([])
  const [averageEmissionsTonnes, setAverageEmissionsTonnes] = useState(0)

  const newCarRentals = useMemo(
    () =>
      cars.filter((car: Cars[0]) => {
        if (!existingCarRentalEmissions.find((existingCar) => existingCar.customRef === car.key)) {
          return car
        }
      }),
    [cars, existingCarRentalEmissions],
  )

  const tcCars = useMemo(
    () =>
      newCarRentals.map((car: Cars[0]) => {
        return { ...mapToThrustCarbonCar(car, pickUpDate, dropOffDate) }
      }),
    [newCarRentals, pickUpDate, dropOffDate],
  )

  const maybeCarRentalValidationError = carRentalEmissionsQueryExecuteValidation({
    cars: tcCars,
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    dropOffDate,
  })

  const isSkipValidationError =
    !maybeCarRentalValidationError.isNothing() &&
    maybeCarRentalValidationError.getValue().text() === SKIP_VALIDATION_ERROR

  if (!isSkipValidationError && !maybeCarRentalValidationError.isNothing()) {
    logValidationErrorToRollbar(maybeCarRentalValidationError, {
      cars: tcCars,
      pickUpPlace,
      dropOffPlace,
      pickUpDate,
      dropOffDate,
    })
  }

  const { data, loading, error } = useCalculateCarEmissionsBatchQuery({
    variables: {
      input: {
        compare: [
          {
            days: mapNumberOfDays(pickUpDate, dropOffDate),
            origin: {
              // We expect the values to always be present.
              // Any issues related to this query is tracked via Rollbar
              lat: pickUpPlace.latitude as number,
              long: pickUpPlace.longitude as number,
            },
            destination: {
              lat: dropOffPlace.latitude as number,
              long: dropOffPlace.longitude as number,
            },
          },
        ],
        cars: tcCars,
      },
    },
    onCompleted: (data) => {
      if (data.calculateCarEmissionsBatch.errors) {
        return
      }

      const result = [...existingCarRentalEmissions, ...data?.calculateCarEmissionsBatch?.cars!]

      if (data?.calculateCarEmissionsBatch.averageEmissionsTonnes) {
        setAverageEmissionsTonnes(data?.calculateCarEmissionsBatch.averageEmissionsTonnes)
      }

      setExistingCarRentalEmissions(result)
    },
    skip:
      !isSustainabilityEnabled ||
      isSkipValidationError ||
      !maybeCarRentalValidationError.isNothing(),
  })

  return {
    data,
    loading,
    error,
    averageEmissionsTonnes,
    existingCarRentalEmissions,
  }
}
