import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'

export function useGetRemoveText(
  type: SegmentType,
  isPostBooking: boolean,
  isTrainTicketRefundable: boolean,
) {
  const { t } = useTranslation()
  const i18nBase = 'ActionButtons.'
  const cancelTrainButton = isTrainTicketRefundable ? 'RefundTrainButton' : 'CancelTrainButton'

  switch (type) {
    case SegmentType.Flight:
      const flightText = isPostBooking ? 'CancelFlightButton' : 'RemoveFlightButton'
      return t(i18nBase + flightText)
    case SegmentType.Hotel:
      const hotelText = isPostBooking ? 'CancelHotelButton' : 'RemoveHotelButton'
      return t(i18nBase + hotelText)
    case SegmentType.CarRental:
      const carText = isPostBooking ? 'CancelCarRentalButton' : 'RemoveCarRentalButton'
      return t(i18nBase + carText)
    case SegmentType.CarService:
      const carService = isPostBooking ? 'CancelCarServiceButton' : 'RemoveCarServiceButton'
      return t(i18nBase + carService)
    case SegmentType.Train:
      const train = isPostBooking ? cancelTrainButton : 'RemoveTrainButton'
      return t(i18nBase + train)
    case SegmentType.RideHail:
      return t(i18nBase + 'CancelRideHailButton')
    default:
      return 'Remove'
  }
}
