import type { CalculateTripEmissionsResult } from '@fiji/graphql/types'
import { EcoCheckSummary } from '@etta/components/eco-check-summary/eco-check-summary'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useRtpEcoCheckSummary } from './use-rtp-eco-check-summary'

type Props = {
  segments: TripSegmentValueObject[]
  tripEmissionsResult: CalculateTripEmissionsResult
}

export function RtpEcoCheckSummary({ segments, tripEmissionsResult }: Props) {
  const result = useRtpEcoCheckSummary({ segments, tripEmissionsResult })

  return <EcoCheckSummary tapHighlightColor="background" page="rtp" {...result} />
}
