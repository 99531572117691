import styled from 'styled-components'

export const Container = styled.div``

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > * + * {
    margin-top: 8px;
  }
`

export const ListItem = styled.li``
