import type { ReactNode } from 'react'
import type { Message, Alert } from '../types'

type Args = {
  messages: Message[] | Alert[]
  messageIndex: number
}

function isMessageType(message: Message | Alert): message is Message {
  return 'hasDesktopBannerImage' in message
}

function isAlertType(message: Message | Alert): message is Alert {
  return 'component' in message
}

function getBannerImage(message: Message | Alert): boolean {
  if (!isMessageType(message)) {
    return false
  }

  return !!message.hasDesktopBannerImage
}

function getMessageSlot(message: Message | Alert): ReactNode {
  if (!isAlertType(message)) {
    return null
  }

  return message.component
}

export function getSelectedMessage({ messages, messageIndex = 0 }: Args) {
  const message = messages[messageIndex] || {}
  const { id, header, text } = message

  return {
    title: header,
    message: text,
    messageId: id,
    messageSlot: getMessageSlot(message),
    hasDesktopBannerImage: getBannerImage(message),
  }
}
