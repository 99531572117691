import styled from 'styled-components'
import { headline } from '@fiji/style'

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText1};
  ${headline}
`

export const Content = styled.div`
  padding: 24px 16px 18px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.white};
`

export const CloseButtonWrapper = styled.div`
  border-radius: 50%;
  background: ${(p) => p.theme.colors.bodyText};
`

export const ConfirmButtonWrapper = styled.div`
  width: 40%;
`
