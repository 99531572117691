import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  AddToCartMutation,
  AddToCartMutationVariables,
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables,
  AddModifyToCartMutation,
  AddModifyToCartMutationVariables,
  AddModifyToCartInput,
  GetCostSummaryQuery,
  GetCostSummaryQueryVariables,
  SetOutOfPolicyJustificationMutationVariables,
  SetCostAllocationInput,
  SetCostAllocationMutation,
  SetCostAllocationMutationVariables,
  CartCostAllocationQuery,
  CartCostAllocationQueryVariables,
} from '@fiji/graphql/hooks'
import {
  GetCartDocument,
  CreateCartDocument,
  AddToCartDocument,
  RemoveFromCartDocument,
  AddModifyToCartDocument,
  GetCostSummaryDocument,
  GetOopInfoDocument,
  SetOutOfPolicyJustificationDocument,
  CartCostAllocationDocument,
} from '@fiji/graphql/hooks'
import type {
  GetCartInput,
  GetCartQuery,
  GetCartQueryVariables,
  CreateCartMutation,
  CreateCartMutationVariables,
  AddToCartInput,
  RemoveFromCartInput,
  GetOopInfoQueryVariables,
  SetOutOfPolicyJustificationInput,
  GetOopInfoQuery,
  SetOutOfPolicyJustificationMutation,
} from '@fiji/graphql/types'

@DataProvider()
export class CartDataProvider extends GraphqlDataProvider {
  getCart(input: GetCartInput, forceUpdate?: boolean) {
    return this.client.query<GetCartQuery, GetCartQueryVariables>({
      query: GetCartDocument,
      variables: {
        input: {
          cartId: input.cartId,
        },
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  createCart() {
    return this.client.mutate<CreateCartMutation, CreateCartMutationVariables>({
      mutation: CreateCartDocument,
    })
  }

  addToCart(input: AddToCartInput) {
    return this.client.mutate<AddToCartMutation, AddToCartMutationVariables>({
      mutation: AddToCartDocument,
      variables: {
        input,
      },
    })
  }

  removeFromCart(input: RemoveFromCartInput) {
    return this.client.mutate<RemoveFromCartMutation, RemoveFromCartMutationVariables>({
      mutation: RemoveFromCartDocument,
      variables: {
        input,
      },
    })
  }

  addModifyToCart(input: AddModifyToCartInput) {
    return this.client.mutate<AddModifyToCartMutation, AddModifyToCartMutationVariables>({
      mutation: AddModifyToCartDocument,
      variables: {
        input,
      },
    })
  }

  getCostSummary(cartId: string, forceUpdate?: boolean) {
    return this.client.query<GetCostSummaryQuery, GetCostSummaryQueryVariables>({
      query: GetCostSummaryDocument,
      variables: {
        cartId,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  handleSetOOPReason(input: SetOutOfPolicyJustificationInput) {
    return this.client.mutate<
      SetOutOfPolicyJustificationMutation,
      SetOutOfPolicyJustificationMutationVariables
    >({
      mutation: SetOutOfPolicyJustificationDocument,
      variables: {
        input,
      },
    })
  }

  handleGetOOPInfo(variables: GetOopInfoQueryVariables, forceUpdate?: boolean) {
    return this.client.query<GetOopInfoQuery, GetOopInfoQueryVariables>({
      query: GetOopInfoDocument,
      variables,
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  handleSetCostAllocation(input: SetCostAllocationInput) {
    return this.client.mutate<SetCostAllocationMutation, SetCostAllocationMutationVariables>({
      mutation: SetOutOfPolicyJustificationDocument,
      variables: {
        input,
      },
    })
  }

  handleGetCostAllocation(cartId: string, forceUpdate?: boolean) {
    return this.client.query<CartCostAllocationQuery, CartCostAllocationQueryVariables>({
      query: CartCostAllocationDocument,
      variables: {
        cartId,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
