import type { CreditCard, Maybe } from '@fiji/graphql/types'
import { formatCardNumber } from '@fiji/utils/credit-card/format-card-number'
import { getCardVendor } from '@fiji/utils/credit-card/get-card-vendor'

export function getCardLabelString({
  formattedCardNumber,
  vendor,
  label,
}: {
  formattedCardNumber: string
  vendor: string
  label?: Maybe<string>
}) {
  return [formattedCardNumber, vendor, label].filter(Boolean).join(' ')
}

export function getCardLabel(creditCard: CreditCard, getTemplate = getCardLabelString): string {
  const { label, brandType, cardNumber } = creditCard

  const vendor = getCardVendor(brandType).toUpperCase()

  return getTemplate({
    formattedCardNumber: formatCardNumber(cardNumber),
    vendor,
    label,
  })
}
