import React from 'react'
import type { CalendarLayoutProps } from '../../../types'
import { CalendarMobileContainer } from './calendar-mobile-components'

export function CalendarMobile({
  calendarSlot,
  renderDayContents,
  renderMonthText,
}: CalendarLayoutProps) {
  return (
    <CalendarMobileContainer>
      {React.cloneElement(calendarSlot, {
        orientation: 'verticalScrollable',
        renderDayContents,
        renderMonthText,
      })}
    </CalendarMobileContainer>
  )
}
