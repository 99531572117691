import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Dialog } from '@etta/ui/dialog'
import { Container, StyledButton } from './search-results-active-itinerary-dialog-styled'

type Props = {
  isOpen: boolean
  isAddButtonEnabled?: boolean
  onClose: () => void
  onAddToCurrentTrip: () => void
  onCreateNewTrip: () => void
  onBack: () => void
}

const i18nBase = 'SearchResults.ActiveItineraryDialog.'

export function SearchResultsActiveItineraryDialog({
  isOpen,
  isAddButtonEnabled = true,
  onClose,
  onAddToCurrentTrip,
  onCreateNewTrip,
  onBack,
}: Props) {
  const { t } = useTranslation()
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      data-tracking-id="search-results-active-itinerary-dialog">
      <Container paddingHorizontal={20} paddingVertical={20}>
        <Container isFlexBlock marginBottom={32}>
          <Block marginBottom={24}>
            <Text variant="title3">{t(`${i18nBase}Title`)}</Text>
          </Block>
          <Block>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {t(`${i18nBase}Subtitle`)}
            </Text>
          </Block>
        </Container>
        <Container isFlexBlock>
          {isAddButtonEnabled && (
            <Block marginBottom={16} isFullWidth>
              <Button size="normal-wide" fullWidth onClick={onAddToCurrentTrip}>
                {t(`${i18nBase}AddToCurrentTrip`)}
              </Button>
            </Block>
          )}
          <Block marginBottom={16} isFullWidth>
            <Button variant="outline" size="normal-wide" fullWidth onClick={onCreateNewTrip}>
              {t(`${i18nBase}CreateNewTrip`)}
            </Button>
          </Block>
          <Block isFullWidth>
            <StyledButton size="normal-wide" variant="text" fullWidth onClick={onBack}>
              {t(`${i18nBase}BackToSearch`)}
            </StyledButton>
          </Block>
        </Container>
      </Container>
    </Dialog>
  )
}
