import styled, { css } from 'styled-components'
import { InputButton as EttaInputButton } from '@etta/ui/input-button'

export const InputButton = styled(EttaInputButton)<{ isForSearchHeader?: boolean }>`
  min-width: 50%;
  ${({ isForSearchHeader }) =>
    isForSearchHeader &&
    css`
      max-height: 64px;
      & > div:last-child {
        margin-top: 5px;
      }
    `}
`
