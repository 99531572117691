import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import type { SegmentType } from '@etta/core/enums'
import { Container } from './segment-booking-limit-dialog-styled'
import { getDescription } from './get-description'

type Props = {
  segmentType: SegmentType
  isOpen: boolean
  onClick: () => void
  onClose: () => void
}

const i18nBase = 'BookingLimitDialog'

export const SegmentBookingLimitDialog = observer(function SegmentBookingLimitDialog({
  segmentType,
  isOpen,
  onClick,
  onClose,
}: Props) {
  const { t } = useTranslation()
  const description = getDescription({ segmentType })

  return (
    <Dialog isOpen={isOpen} onClose={onClose} horizontalDimension="narrow-desktop">
      <Dialog.Body>
        <Container>
          <Block marginBottom={24}>
            <Text variant="title3" color="mainText">
              {t(`${i18nBase}.Title`)}
            </Text>
          </Block>
          <Block marginBottom={32}>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {description}
            </Text>
          </Block>
          <Button variant="solid" onClick={onClick} fullWidth>
            {t(`${i18nBase}.ToReviewPage`)}
          </Button>
        </Container>
      </Dialog.Body>
    </Dialog>
  )
})
