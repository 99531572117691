import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Container, Unit, Separator, CheckedIcon, DoubleCurrency } from './segment-section-styled'
import type { Props } from './types'
import { useSegmentSection } from './use-segment-section'

const i18nBase = 'PaymentSummary.'

export function SegmentSection({
  variant,
  segmentType,
  segmentSpecificSlot,
  taxesCost,
  totalAmount,
  taxesCostMoney,
  totalAmountMoney,
}: Props) {
  const { t } = useTranslation()

  const { title, totalLabel, taxes, mainTotal, secondaryTotal } = useSegmentSection({
    type: segmentType,
    variant,
    taxesCost,
    totalAmount,
    taxesCostMoney,
    totalAmountMoney,
  })

  return (
    <Container>
      <Text variant="footnoteStrong">{title}</Text>
      {segmentSpecificSlot}
      {taxes && (
        <Unit>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'TaxesAndFees')}
          </Text>
          <Text variant="footnoteMedium" color="bodyText">
            {taxes}
          </Text>
        </Unit>
      )}
      <Separator />
      <Unit>
        <Text variant="subHeadStrong">
          {totalLabel}
          {variant === 'paid' && <CheckedIcon name="checkFilledPWA" size="small" color="success" />}
        </Text>
        <DoubleCurrency>
          <Text variant="subHeadStrong">{mainTotal}</Text>
          <Text variant="footnoteMedium" color="bodyText">
            {secondaryTotal}
          </Text>
        </DoubleCurrency>
      </Unit>
    </Container>
  )
}
