import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container, Wrapper, Content } from './alert-card-mobile-styled'

type Props = {
  title: string
  message: string
  onRedirect: () => void
}

export const AlertCardMobile = observer(function AlertCardMobile({
  title,
  message,
  onRedirect,
}: Props) {
  return (
    <Container onClick={onRedirect}>
      <Wrapper>
        <Block marginRight={20}>
          <Icon name="warningFilledPWA" color="warning" />
        </Block>
        <Content>
          <Block marginBottom={6}>
            <Text aria-label={title} variant="subHeadStrong" color="mainText">
              {title}
            </Text>
          </Block>
          <Text aria-label={message} variant="footnoteMedium" color="bodyText">
            {message}
          </Text>
        </Content>
      </Wrapper>
    </Container>
  )
})
