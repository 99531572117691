// @ts-ignore
import { Number } from 'react-credit-card-primitives'
import { useTranslation } from 'react-i18next'
import type { IconSizes } from '@etta/ui/icon'
import { TextField } from '@etta/ui/text-field'
import type { CreditCardBrandType } from '@etta/core/enums'
import { BrandTypeWithinNumber } from './brand-type-within-number'

type Props = {
  value: string
  onChange: (value: string) => void
  error: string
  creditIconSize?: IconSizes
  isCardNumberFieldEdited: boolean
  isDisabled: boolean
  isBlurred?: boolean
  brandType?: CreditCardBrandType
  isBrandTypeDisplay?: boolean
  handleSetCardNumberFieldEdited: (value: boolean) => void
}

type InputProps = {
  value: string
  onChange: (s: { target: { value: string } }) => void
}

const i18Base = 'CreditCardForm.'

export function CreditCardNumber({
  value,
  error,
  onChange,
  creditIconSize = 'normal',
  isCardNumberFieldEdited,
  isDisabled,
  isBlurred,
  brandType,
  isBrandTypeDisplay,
  handleSetCardNumberFieldEdited,
}: Props) {
  const { t } = useTranslation()

  return (
    <Number
      value={value}
      onChange={(args: { value: string }) => {
        onChange(args.value)
      }}
      render={(renderProps: { getInputProps: () => InputProps }) => {
        const { getInputProps } = renderProps
        const inputProps = getInputProps()
        return (
          <TextField
            {...inputProps}
            onChange={(value) => {
              inputProps.onChange({
                target: { value },
              })
              handleSetCardNumberFieldEdited(true)
            }}
            onFocus={() => {
              if (isCardNumberFieldEdited) {
                return
              }
              inputProps.onChange({
                target: { value: '' },
              })
            }}
            helperText={error}
            label={t(i18Base + 'InputLabels.CardNumber')}
            startSlot={
              <BrandTypeWithinNumber
                isBrandTypeDisplay={!!isBrandTypeDisplay}
                brandType={brandType}
                creditIconSize={creditIconSize}
              />
            }
            placeholder=""
            isDisabled={isDisabled}
            isBlurred={isBlurred}
          />
        )
      }}
    />
  )
}
