import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate } from '@fiji/graphql/types'
import { Footer, FooterTotalPriceLine } from './car-rental-details-footer-styled'
import { CarRentalDetailsFooterSkeleton } from './car-rental-details-footer-skeleton'

type Props = {
  totalDays: number
  totalCost?: Rate | null
  isSelectable?: boolean
  isLoading?: boolean
  onButtonClick: () => void
}

const i18Base = 'CarRentalDetails'

export function CarRentalDetailsFooter({
  totalDays,
  totalCost,
  isSelectable,
  isLoading,
  onButtonClick,
}: Props) {
  const { t } = useTranslation()
  const { mainCost: totalPriceFormatted } = formatRate(totalCost)

  if (isLoading) {
    return <CarRentalDetailsFooterSkeleton />
  }

  return (
    <Footer>
      <FooterTotalPriceLine>
        <Block marginBottom={7}>
          <Text variant="captionMedium" color="bodyText" role="heading" aria-level={5}>
            {t(`${i18Base}.SectionTitles.EstimatedTotalDesktop`, { days: totalDays })}
          </Text>
        </Block>
        <Text aria-label={totalPriceFormatted} variant="title3" color="mainText">
          {totalPriceFormatted}
        </Text>
      </FooterTotalPriceLine>
      <Button
        minWidth={24}
        disabled={!isSelectable}
        onClick={onButtonClick}
        aria-label={t('ReviewTrip.ButtonsLabel', { label: t(`${i18Base}.Select`) })}
        data-tracking-id="add-car-rental-button">
        {t(`${i18Base}.Select`)}
      </Button>
    </Footer>
  )
}
