import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { RailTransportationMode } from '@fiji/graphql/types'
import { LONDON_UNDERGROUND_TUBE_URL, RAIL_LOGO_GENERIC } from '@fiji/constants'
import { useDeleteRail } from '../../hooks'
import { getRailLabelsRtp } from '../../../../utils/get-rail-labels-rtp'
import { useTicketRestrictionsRtp } from './use-ticket-restrictions-rtp'
import { useChangeRail } from '.'

const i18nBase = 'RailDetailsModal.'

type Props = {
  itineraryId: string
  railSegmentsLength: number
  railLeg: TrainSegmentEntity
  onClose: () => void
}

export function useRailDetailsModal({ itineraryId, railLeg, railSegmentsLength, onClose }: Props) {
  const { t } = useTranslation()

  const changeModalToggle = useTogglePopup()
  const removeDialogToggle = useTogglePopup()
  const fareConditions = railLeg.fareConditions
  const {
    onOpenModalClick,
    ticketRestrictionModalToggle,
    ticketRestrictions,
  } = useTicketRestrictionsRtp(fareConditions)

  const { handleRemoveRail, viewStateParams } = useDeleteRail({
    itineraryId,
    legId: railLeg.legId,
    onCloseAfterward: onClose,
    handleCloseRemoveModal: removeDialogToggle.handleClose,
  })
  const { handleChangeRail } = useChangeRail({
    itineraryId,
    handleCloseChangeModal: changeModalToggle.handleClose,
    departure: railLeg.departureDateTime,
    arrival: railLeg.arrivalDateTime,
    departureStationName: railLeg.segments[0].departure.stationName,
    arrivalStationName: railLeg.segments[railLeg.segments.length - 1].arrival.stationName,
  })

  const [railSegment] = railLeg.segments
  const { carrierLogoUrl } = railSegment
  const squareRailLogos = railLeg.segments
    .map((segment) => {
      if (segment.transportationMode === RailTransportationMode.Train) {
        return segment.carrierLogoUrl || RAIL_LOGO_GENERIC
      }
      if (
        segment.transportationMode === RailTransportationMode.Unspecified ||
        segment.transportationMode === RailTransportationMode.Metro
      ) {
        return LONDON_UNDERGROUND_TUBE_URL
      }

      return ''
    })
    .filter(Boolean)
  const headerTitle = t(i18nBase + 'FaresDetails.FromToStations', {
    from: railLeg.segments[0].departure.stationName,
    to: railLeg.segments[railLeg.segments.length - 1].arrival.stationName,
  })

  const informationText = getRailLabelsRtp(railLeg.segments)

  const tripDate =
    tripReviewFormatDate(TripReviewDateType.DayAndDate, railLeg.departureDateTime) || ''

  const changeModalTitleLabel = t(i18nBase + 'ChangeTrain.Title')
  const changeModalContentLabel = t(i18nBase + 'ChangeTrain.InfoText', {
    count: railSegmentsLength,
  })
  const changeModalConfirmLabel = t(i18nBase + 'ChangeTrain.ConfirmButtonText')
  const changeModalDeclineLabel = t(i18nBase + 'ChangeTrain.DeclineButtonText')

  const removeModalTitleLabel = t(i18nBase + 'RemoveTrain')
  const removeModalContentLabel = t(i18nBase + 'RemoveTrainExplanation')
  const removeModalConfirmLabel = t(i18nBase + 'RemoveConfirmButton')
  const removeModalDeclineLabel = t(i18nBase + 'RemoveDeclineButton')

  return {
    changeModalConfirmLabel,
    changeModalContentLabel,
    changeModalDeclineLabel,
    changeModalTitleLabel,
    changeModalToggle,
    handleChangeRail,
    handleRemoveRail,
    headerTitle,
    carrierLogoUrl: carrierLogoUrl || '',
    informationText,
    removeDialogToggle,
    removeModalConfirmLabel,
    removeModalContentLabel,
    removeModalDeclineLabel,
    removeModalTitleLabel,
    viewStateParams,
    onOpenModalClick,
    ticketRestrictionModalToggle,
    ticketRestrictions,
    tripDate,
    squareRailLogos,
  }
}
