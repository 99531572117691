import * as yup from 'yup'
import i18n from 'i18next'
import cardValidator from 'card-validator'

const MAX_LENGTHS = [3, 4]

export function getCarSecurityCodeScheme() {
  const i18BaseErrors = 'CreditCardForm.Errors.'
  return yup
    .string()
    .min(3)
    .test(
      'is-security-code',
      i18n.t(i18BaseErrors + 'SecurityCode.Invalid'),
      (value) => cardValidator.cvv(value, MAX_LENGTHS).isValid,
    )
}
