import type { CustomFieldPrefix, CustomUuidArgs } from './types'

export const parseCustomUuid = (customUuid: string): CustomUuidArgs => {
  const [prefix, name, parentIndex, itemIndex] = customUuid.split('-')

  return {
    prefix: prefix as CustomFieldPrefix,
    name,
    itemIndex: parseInt(itemIndex),
    parentIndex: parseInt(parentIndex),
  }
}
