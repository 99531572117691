import { Block } from '@etta/ui/block'
import type { Props } from '../../types'

export function AirSearchFlightMobile({
  cabinClassSlot,
  calendarSlot,
  locationSlot,
  warningSlot,
}: Omit<Props, 'legNumber'>) {
  return (
    <>
      {locationSlot}
      {calendarSlot}
      {cabinClassSlot}
      {warningSlot && (
        <Block marginTop={6} marginHorizontal={16} marginBottom={45}>
          {warningSlot}
        </Block>
      )}
    </>
  )
}
