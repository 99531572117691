import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace UnusedTicketsErrors {
  export const GetUnusedTicketsErrorResponse = createErrorClass('Get Unused Tickets Response error')
  export const GetUnusedTicketsUnexpectedError = createErrorClass(
    'Get Unused Tickets Unexpected error',
  )
}

export type UnusedTicketsErrorsInstances = InstanceType<
  typeof UnusedTicketsErrors[keyof typeof UnusedTicketsErrors]
>
