import type { SeatReservationType } from '@fiji/graphql/types'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { RailDetailsTicketRestrictionsModalLayout } from './layout'
import { useRailDetailsTicketRestrictionsModal } from './use-rail-details-ticket-restrictions-modal'

type Props = {
  railLeg: TrainSegmentEntity
  seatReservationType: SeatReservationType
  isRailEticket: boolean
  isSeatRequested: boolean
  appliedRailCard: string
}

export function RailDetailsTicketRestrictionsModalContent({
  railLeg,
  seatReservationType,
  isRailEticket,
  isSeatRequested,
  appliedRailCard,
}: Props) {
  const {
    headerTitle,
    carrierLogoUrl,
    squareRailLogos,
    ticketRestrictions,
  } = useRailDetailsTicketRestrictionsModal({
    railLeg,
  })

  return (
    <RailDetailsTicketRestrictionsModalLayout
      railLeg={railLeg}
      carrierLogoUrl={carrierLogoUrl}
      headerTitle={headerTitle}
      seatReservationType={seatReservationType}
      isRailEticket={isRailEticket}
      squareRailLogos={squareRailLogos}
      isSeatRequested={isSeatRequested}
      appliedRailCard={appliedRailCard}
      ticketRestrictions={ticketRestrictions}
    />
  )
}
