import * as assets from './assets'

export const pwaIcons = {
  appleMostPreferredPWA: assets.AppleMostPreferredIconPWA,
  appleMostPreferredFilledPwa: assets.AppleMostPreferredIconFilledPwa,
  appleLogoIconPWA: assets.AppleLogoIconPWA,
  audioPWA: assets.AudioIconPWA,
  busPWA: assets.BusIconPWA,
  cardPWA: assets.CardPWA,
  creditCardPWA: assets.CreditCardPWA,
  errorFilledPWA: assets.ErrorFilledPWA,
  errorPWA: assets.ErrorPWA,
  wifiPWA: assets.WifiPWA,
  spinnerPWA: assets.SpinnerPWA,
  addPWA: assets.AddPWA,
  mealPWA: assets.MealPWA,
  mealFilledPWA: assets.MealFilledPWA,
  loyaltyPWA: assets.LoyaltyPWA,
  loyaltyFilledPWA: assets.LoyaltyFilledPWA,
  notificationPWA: assets.NotificationPWA,
  notificationV2PWA: assets.NotificationV2PWA,
  notificationFilledPWA: assets.NotificationFilledPWA,
  specialRequestPWA: assets.SpecialRequestPWA,
  specialRequestFilledPWA: assets.SpecialRequestFilledPWA,
  arrowBackPWA: assets.ArrowBackPWA,
  arrowBackLargePWA: assets.ArrowBackLargePWA,
  arrowDownwardPWA: assets.ArrowDownwardPWA,
  arrowForwardPWA: assets.ArrowForwardPWA,
  arrowUpwardPWA: assets.ArrowUpwardPWA,
  chevronUpPWA: assets.ChevronUpPWA,
  chevronDownPWA: assets.ChevronDownPWA,
  chevronLeftPWA: assets.ChevronLeftPWA,
  chevronRightPWA: assets.ChevronRightPWA,
  documentPWA: assets.DocumentPWA,
  editPWA: assets.EditPWA,
  editV2PWA: assets.EditV2PWA,
  mailPWA: assets.MailPWA,
  mailFilledPWA: assets.MailFilledPWA,
  ondemandVideoPWA: assets.OndemandVideoPWA,
  powerPWA: assets.PowerPWA,
  queryBuilderPWA: assets.QueryBuilderPWA,
  theatresPWA: assets.TheatresPWA,
  tvPWA: assets.TvPWA,
  eyeHidePWA: assets.EyeHidePWA,
  calendarPWA: assets.CalendarIconPWA,
  carRentalPWA: assets.CarRentalIconPWA,
  carryOnBagPWA: assets.CarryOnBagIconPWA,
  checkedBagAllowancePWA: assets.CheckedBagAllowanceIconPWA,
  closePWA: assets.CloseIconPWA,
  drinksPWA: assets.DrinksIconPWA,
  customPreferredPWA: assets.customPreferredIconPWA,
  filterPWA: assets.FilterIconPWA,
  flightPWA: assets.FlightIconPWA,
  foodPWA: assets.FoodIconPWA,
  hotelPWA: assets.HotelIconPWA,
  iCloudExclamationPWA: assets.ICloudExclamationIconPWA,
  lieFlatSeatPWA: assets.LieFlatSeatIconPWA,
  loungePWA: assets.LoungeIconPWA,
  noCarryOnBagPWA: assets.NoCarryOnBagIconPWA,
  noCheckedBagAllowancePWA: assets.NoCheckedBagAllowanceIconPWA,
  noSeatSelectionPWA: assets.NoSeatSelectionIconPWA,
  nonRefundablePWA: assets.NonRefundableIconPWA,
  nonRefundableFilledPWA: assets.NonRefundableFilledPWA,
  nonSmokingPWA: assets.NonSmokingIconPWA,
  organisationPWA: assets.OrganisationIconPWA,
  outOfPolicyPWA: assets.OutOfPolicyIconPWA,
  outletsAndUSBPWA: assets.OutletsAndUSBIconPWA,
  paidCarryOnBagPWA: assets.PaidCarryOnBagIconPWA,
  paidSeatSelectionPWA: assets.PaidSeatSelectionIconPWA,
  passwordPWA: assets.PasswordPWA,
  penaltyAppliesPWA: assets.PenaltyAppliesIconPWA,
  preferredPWA: assets.PreferredIconPWA,
  priorityBoardingPWA: assets.PriorityBoardingIconPWA,
  railPWA: assets.RailIconPWA,
  refundablePWA: assets.RefundableIconPWA,
  refundableFilledPWA: assets.RefundableFilledPWA,
  sameDayPWA: assets.SameDayIconPWA,
  searchPWA: assets.SearchIconPWA,
  searchPinPWA: assets.SearchPinIconPWA,
  seatSelectionPWA: assets.SeatSelectionIconPWA,
  switchPWA: assets.SwitchIconPWA,
  tickPWA: assets.TickIconPWA,
  timePWA: assets.TimeIconPWA,
  timerPWA: assets.TimerIconPWA,
  upgradePWA: assets.UpgradeIconPWA,
  warningFilledPWA: assets.WarningFilledPWA,
  webFarePWA: assets.WebFareIconPWA,
  directBookingIcon: assets.DirectBookingPWA,
  webFareV2PWA: assets.WebFareIconV2PWA,
  workInProgressPWA: assets.WorkInProgressPWA,
  peoplePWA: assets.PeopleIconPWA,
  peopleNewPWA: assets.PeopleNewPWA,
  carMarkerPWA: assets.CarMarkerIconPWA,
  carHybridPWA: assets.CarHybridPWA,
  phonePWA: assets.PhoneIconPWA,
  copyPWA: assets.CopyIconPWA,
  infoPWA: assets.InfoIconPWA,
  baggageIconPWA: assets.BaggageIconPWA,
  usersIconPWA: assets.UsersIconPWA,
  transmissionPWA: assets.TransmissionIconPWA,
  travelBagPWA: assets.TravelBagIconPWA,
  transmissionNewPWA: assets.TransmissionNewIconPWA,
  bagPWA: assets.BagIconPWA,
  bagNewPWA: assets.BagNewIconPWA,
  terminalPWA: assets.TerminalIconPWA,
  odometerPWA: assets.OdometerIconPWA,
  snowflakePWA: assets.SnowflakeIconPWA,
  snowNewPWA: assets.SnowNewIconPWA,
  doorPWA: assets.DoorIconPWA,
  doorNewPWA: assets.DoorNewIconPWA,
  ettaLogoPWA: assets.EttaLogoIcon,
  filtersHorizontalPWA: assets.FiltersHorizontalPWA,
  filtersHorizontalActivePWA: assets.FiltersHorizontalActivePWA,
  sortPWA: assets.SortPWA,
  policyPWA: assets.PolicyPWA,
  infoOutlinePWA: assets.InfoOutlinePWA,
  infoFilledPWA: assets.InfoFilledPWA,
  messagesPWA: assets.MessagesPWA,
  viewListPWA: assets.ViewListPWA,
  unlimitedMileage: assets.UnlimitedMileage,
  // @todo:
  // Remove rename to tickPWA
  newTickPWA: assets.NewTickIconPWA,
  tickBoldPWA: assets.TickBoldIconPWA,
  newTickSharpPWA: assets.NewTickSharpIconPWA,
  closeFilledPWA: assets.CloseFilledPWA,
  CloseFilledDarkPWA: assets.CloseFilledDarkPWA,
  closeFilledTransparentPWA: assets.CloseFilledTransparentPWA,
  checkInPriorityPWA: assets.CheckInPriorityPWA,
  locationDisabledPWA: assets.LocationDisabledPWA,
  advanceChangePWA: assets.AdvanceChangeIconPWA,
  checkFilledPWA: assets.CheckFilledPWA,
  checkFilledSmoothPWA: assets.CheckFilledSmoothPWA,
  checkOutlinePWA: assets.CheckOutlinePWA,
  flightArrowPWA: assets.FlightArrowPWA,
  sharePWA: assets.SharePWA,
  tripPWA: assets.TripIconPWA,
  cancelTripPWA: assets.CancelTripPWA,
  hyperlinkPWA: assets.HyperlinkPWA,
  externalLinkPWA: assets.ExternalLinkPWA,
  pickUpPointFilledPWA: assets.PickUpPointFilledPWA,
  directionsPWA: assets.DirectionsPWA,
  dutyFreePWA: assets.DutyFreePWA,
  telephonePWA: assets.TelephonePWA,
  expandLessPWA: assets.ExpandLessPWA,
  expandMorePWA: assets.ExpandMorePWA,
  flightTakeoffPWA: assets.FlightTakeoffPWA,
  flightLandPWA: assets.FlightLandPWA,
  layoverTimePWA: assets.LayoverTimePWA,
  starPWA: assets.StarIconPWA,
  tripadvisorPWA: assets.TripadvisorIconPWA,
  locationPWA: assets.LocationIconPWA,
  ecoFriendlyPWA: assets.EcoFriendlyPWA,
  arrowBgDownwardPWA: assets.ArrowBgDownwardPWA,
  sameDayArrowsPWA: assets.SameDayArrowsPWA,
  profilePWA: assets.ProfilePWA,
  supportPWA: assets.SupportPWA,
  mapPWA: assets.MapPWA,
  swapPWA: assets.SwapPWA,
  gpsPWA: assets.GpsPWA,
  homePWA: assets.HomePWA,
  carServicePWA: assets.CarServicePWA,
  maskPWA: assets.MaskPWA,
  clipboardPWA: assets.ClipboardPWA,
  sanitizerPWA: assets.SanitizerPWA,
  negativeReportPWA: assets.NegativeReportPWA,
  automaticTransmissionPWA: assets.AutomaticTransmissionPWA,
  checkInBagPWA: assets.CheckInBagPWA,
  paidCheckInBagPWA: assets.PaidCheckInBagPWA,
  economyPWA: assets.EconomyPWA,
  premiumEconomyPWA: assets.PremiumEconomyPWA,
  businessClassPWA: assets.BusinessClassPWA,
  firstClassPWA: assets.FirstClassPWA,
  cancelTripV2PWA: assets.CancelTripV2PWA,
  uberLogoPWA: assets.UberLogoPWA,
  unusedTicketPWA: assets.UnusedTicketPWA,
  directionSignPWA: assets.DirectionSignPWA,
  flightArrowsPWA: assets.FlightArrowsPWA,
  deletePWA: assets.DeletePWA,
  goodsPWA: assets.GoodsPwa,
  hotFoodPWA: assets.HotFoodPWA,
  newsPaperPWA: assets.NewsPaperPWA,
  quiteZonePWA: assets.QuiteZonePWA,
  restaurantPWA: assets.RestaurantPWA,
  snackPWA: assets.SnackPWA,
  openWindowPWA: assets.OpenWindowPWA,
  peopleNewFieldPWA: assets.PeopleNewFilledPWA,
  queenRoomPWA: assets.QueenRoomPWA,
  kingRoomPWA: assets.KingRoomPWA,
  hotelRoomPWA: assets.HotelRoomPWA,
  twinRoomPWA: assets.TwinRoomPWA,
  timerNewPWA: assets.TimerNewPWA,
  policyNewPWA: assets.PolicyNewPWA,
  sixtyMinutesPWA: assets.SixtyMinutesPWA,
  leafPWA: assets.LeafPWA,
  averagePWA: assets.AveragePWA,
  homeFilledPWA: assets.HomeFilledPWA,
  eticketPWA: assets.ETicketPWA,
  upArrowPWA: assets.UpArrowPWA,
  virtualCardPWA: assets.VirtualCardPWA,
  cancelledTripPWA: assets.CancelledTripPWA,
  peopleFilledNewPWA: assets.PeopleFilledNewPWA,
  undergroundPWA: assets.UndergroundPWA,
  heldTripPWA: assets.HeldTripPWA,
  thickCheckMarkPWA: assets.ThickCheckMarkPWA,
  faceMaskPWA: assets.FaceMaskPWA,
  questionPWA: assets.QuestionPWA,
  ettaUberPWA: assets.EttaUberPWA,
  undergroundColoredPWA: assets.UndergroundColoredPWA,
  rideHailPWA: assets.RideHailPWA,
  returnFlightArrowPWA: assets.ReturnFlightArrowPWA,
  carriagePWA: assets.CarriagePWA,
  dynamicSiteMessagesPWA: assets.DynamicSiteMessagesPWA,
  kioskPWA: assets.KioskPWA,
  printPWA: assets.PrintPWA,
  appPWA: assets.AppPWA,
  tapPWA: assets.TapPWA,
  insertCardPWA: assets.InsertCardPWA,
  typePWA: assets.TypePWA,
  cancelRideHailPWA: assets.CancelRideHailPWA,
  busNewPWA: assets.BusNewPWA,
  changePWA: assets.ChangePWA,
  ferryPWA: assets.FerryPWA,
  tramPWA: assets.TramPWA,
  tubePWA: assets.TubePWA,
  walkFilledPWA: assets.WalkFilledPWA,
  noRailCardPWA: assets.NoRailCardPWA,
  loyaltyCardPWA: assets.LoyaltyCardPWA,
  feedbackPWA: assets.FeedBackPWA,
  navBookPWA: assets.NavBookPWA,
  navTripsPWA: assets.NavTripsPWA,
  navSupportPWA: assets.NavSupportPWA,
  navProfilePWA: assets.NavProfilePWA,
  goToExternalPWA: assets.GoToExternalPWA,
  yogaPWA: assets.YogaPWA,
  syncPWA: assets.SyncPWA,
  clickPWA: assets.ClickPWA,
  receiptPWA: assets.ReceiptPWA,
  warningCirclePWA: assets.WarningCirclePWA,
  noRideResultPWA: assets.NoRideResultPWA,
  ettaLogoGoPWA: assets.EttaLogoGoIcon,
  alertPWA: assets.AlertPWA,
  settingsPWA: assets.SettingsPWA,
  helpIconPWA: assets.HelpIconPWA,
  travelerPWA: assets.TravelerPWA,
  drivePWA: assets.DrivePWA,
  delegateBookingPWA: assets.DelegateBookingPWA,
  clockPWA: assets.ClockPWA,
  announcementPWA: assets.AnnouncementPWA,
  deemLogoPWA: assets.DeemLogoPWA,
  deemUberLogoPWA: assets.DeemUberLogoPWA,
  NDCFarePWA: assets.NDCFarePWA,
  serviceFeePWA: assets.ServiceFeePWA,
  electricLinePWA: assets.ElectricLine,
  noCheckedBagInfoPWA: assets.NoCheckedBagInfoPWA,
  listPWA: assets.ListPWA,
  flightReturnArrowPWA: assets.FlightReturnArrowPWA,
  lowerEmissionsCloudsPWA: assets.LowerEmissionsCloudsPWA,
  AverageEmissionsCloudsPWA: assets.AverageEmissionsCloudsPWA,
  moreHorizontalPWA: assets.MoreHorizontalPWA,
}
