import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'

type Args = {
  segments?: TripSegmentValueObject[]
}

export function checkCheckoutAbility({ segments }: Args) {
  const segmentState = segments?.find((segment) => {
    return segment?.segmentState
  })

  return !!segmentState
}
