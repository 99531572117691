import type { EmissionsDeviationLevel } from '@fiji/enums'
import {
  CircleWrap,
  MaskFull,
  MaskHalf,
  Fill,
  InsideCircle,
} from './hotel-details-eco-check-desktop-styled'

export function BodyLeftBlock({
  deviationLevel,
  sustainabilityScore,
}: {
  deviationLevel: EmissionsDeviationLevel
  sustainabilityScore: number
}) {
  return (
    <CircleWrap>
      <div>
        <MaskFull className="full" sustainabilityScore={sustainabilityScore}>
          <Fill deviationLevel={deviationLevel} sustainabilityScore={sustainabilityScore}></Fill>
        </MaskFull>
        <MaskHalf>
          <Fill deviationLevel={deviationLevel} sustainabilityScore={sustainabilityScore}></Fill>
        </MaskHalf>
        <InsideCircle deviationLevel={deviationLevel}>
          {Math.round(sustainabilityScore)}
        </InsideCircle>
      </div>
    </CircleWrap>
  )
}
