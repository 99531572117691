import * as R from 'ramda'
import { Inject, Service } from '@etta/di'
import { CarRentalAdapter } from '@etta/modules/car-rental-search/infra/adapters'
import { CarRentalSearchStore } from '@etta/modules/car-rental-search/interface/stores/car-rental-search.store'
import { isRestrictedCountryError } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import { CarFiltersStore } from '../stores/car-filters.store'
import type { CarRentalInputValueObject } from '../../core/value-objects'

@Service()
export class CarRentalService {
  constructor(
    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,
    @Inject()
    private readonly carRentalAdapter: CarRentalAdapter,

    @Inject()
    private readonly carFiltersStore: CarFiltersStore,
  ) {}

  async getCarRentals(input: CarRentalInputValueObject) {
    const { isSomeFiltersAppliedByCompanySettings } = this.carRentalSearchStore

    this.carRentalSearchStore.setIsLoading(true)
    this.carRentalSearchStore.setIsErrorCarRentals(false)
    this.carRentalSearchStore.setIsRestrictedCountryError(false)
    this.carRentalSearchStore.setRestrictedErrorMessage(null)

    const result = await this.carRentalAdapter.getCarRentals({ input })

    result.match({
      Ok: (carRentals) => {
        this.carRentalSearchStore.setPageEnd(carRentals.page.end + 1)
        this.carRentalSearchStore.setCarRentals(carRentals)
        this.carRentalSearchStore.setIsLoading(false)
        if (
          this.carFiltersStore.isDefaultFilterCompanySettingsExist &&
          isSomeFiltersAppliedByCompanySettings &&
          carRentals.car.length === 0
        ) {
          this.carRentalSearchStore.setIsSecondCarRequestWithoutFilters(true)
          return
        }
        this.carRentalSearchStore.setIsSecondCarRequestWithoutFilters(false)
      },
      Err: (err) => {
        if (isRestrictedCountryError(err)) {
          this.carRentalSearchStore.setIsRestrictedCountryError(true)
          this.carRentalSearchStore.setRestrictedErrorMessage(err.error.message)
        } else {
          this.carRentalSearchStore.setIsErrorCarRentals(true)
        }
        this.carRentalSearchStore.setIsLoading(false)
        this.carRentalSearchStore.setIsSecondCarRequestWithoutFilters(false)
      },
    })
  }

  async loadMoreCarRentals(input: CarRentalInputValueObject) {
    this.carRentalSearchStore.setIsLoadingMore(true)
    this.carRentalSearchStore.setIsErrorCarRentals(false)

    const result = await this.carRentalAdapter.getCarRentals({ input })

    result.match({
      Ok: (carRentals) => {
        this.carRentalSearchStore.setPageEnd(carRentals.page.end + 1)
        // TODO: remove ramda
        const newRentals = {
          ...this.carRentalSearchStore.carRentals!,
          car: R.uniqWith(R.eqProps('key'), [
            ...this.carRentalSearchStore.carRentals!.car,
            ...carRentals.car,
          ]),
          page: carRentals.page,
        }
        this.carRentalSearchStore.setCarRentals(newRentals)
        this.carRentalSearchStore.setIsLoadingMore(false)
      },
      Err: () => {
        this.carRentalSearchStore.setIsErrorCarRentals(true)
        this.carRentalSearchStore.setIsLoadingMore(false)
      },
    })
  }

  async refetchGetCarRentals(input: CarRentalInputValueObject) {
    this.carRentalSearchStore.setIsLoading(true)
    this.carRentalSearchStore.setCarRentals(null)
    this.carRentalSearchStore.setIsErrorCarRentals(false)

    const result = await this.carRentalAdapter.getCarRentals({ input, forceUpdate: true })

    result.match({
      Ok: (carRentals) => {
        this.carRentalSearchStore.setPageEnd(carRentals.page.end + 1)
        this.carRentalSearchStore.setCarRentals(carRentals)
        this.carRentalSearchStore.setIsLoading(false)
      },
      Err: () => {
        this.carRentalSearchStore.setIsErrorCarRentals(true)
        this.carRentalSearchStore.setIsLoading(false)
      },
    })
  }
}
