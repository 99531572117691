import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'
import type { PermittedStationsInfoValueObject } from '@etta/modules/rail/core/value-objects/permitted-stations-info.value-object'
import type { GroupedSegmentValueObject } from '../../../core/value-objects'

@Store()
export class PostBookingTripDetailsStore {
  selectedDetails: null | GroupedSegmentValueObject = null

  selectedDetailsToggle = new Toggle()
  selectedOpenReturnDetailsToggle = new Toggle()
  permittedStationsToggle = new Toggle()
  ticketRestrictionsToggle = new Toggle()
  ticketRestrictionsInfo: TicketRestrictionsInfo = {
    tripDate: '',
    tripFare: '',
    tripRoute: '',
    ticketRestrictions: [],
  }

  permittedStationsInfo: PermittedStationsInfoValueObject | undefined = undefined

  setSelectedDetails(selectedDetails: GroupedSegmentValueObject | null) {
    this.selectedDetails = selectedDetails
  }

  setTicketRestrictionsInfo(info: TicketRestrictionsInfo) {
    this.ticketRestrictionsInfo = info
  }

  setPermittedStationsInfo(info: PermittedStationsInfoValueObject) {
    this.permittedStationsInfo = {
      permittedOriginStations: info.permittedOriginStations,
      permittedDestinationStations: info.permittedDestinationStations,
      onClick: info.onClick,
      originStation: info.originStation,
      destinationStation: info.destinationStation,
    }
  }

  get isTicketRestrictionsOpen() {
    return this.ticketRestrictionsToggle.isOpen
  }

  get isPermittedStationsOpen() {
    return this.permittedStationsToggle.isOpen
  }

  dropTripDetailsStore() {
    this.selectedDetails = null
    this.selectedDetailsToggle = new Toggle()
    this.selectedOpenReturnDetailsToggle = new Toggle()
    this.permittedStationsToggle = new Toggle()
    this.ticketRestrictionsToggle = new Toggle()
    this.ticketRestrictionsInfo = {
      tripDate: '',
      tripFare: '',
      tripRoute: '',
      ticketRestrictions: [],
    }
  }
}
