import styled from 'styled-components'

export const Image = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
`
