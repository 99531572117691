import { Icon } from '@etta/ui/icon'
import type { AirSearchRestrictions } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { PostBookingAction } from '@fiji/enums'
import { WarningMsgContainer, WarningMsgText } from '../air-search-form-styled'
import { useSearchRestrictions } from './use-search-restrictions.tsx'

type Props = {
  type: AirSearchRestrictions
  postBookingAction?: PostBookingAction
}

export function WarningMessage({ type, postBookingAction }: Props) {
  const { staticLocationWarning } = useSearchRestrictions({ type, postBookingAction })

  return (
    <WarningMsgContainer>
      <Icon name="warningFilledPWA" color="error" />
      <WarningMsgText>{staticLocationWarning}</WarningMsgText>
    </WarningMsgContainer>
  )
}
