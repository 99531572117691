import styled from 'styled-components'
import { footnoteStrong, captionStrongCaps, subHeadMedium } from '@fiji/style'
import type { CardLayoutType } from '../../../types'

export const RowContainer = styled.div<{ withPaddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding-bottom: ${(props) => props.withPaddingBottom && '16px;'};
`

export const CardContainer = styled.div`
  width: 100%;
`

export const CVVFieldWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding-top: 16px;
  line-height: 16px;
`

export const CVVField = styled.div`
  width: 156px;
`

export const CardInfoContainer = styled.div<{ layout?: CardLayoutType }>`
  display: flex;
  flex-direction: column;
  ${(p) => {
    switch (p.layout) {
      case 'summary':
        return 'margin-left: 9px;'

      default:
        return 'margin-left: 19px;'
    }
  }}
  padding-right: 5px;
`

export const CardName = styled.div<{ layout?: CardLayoutType }>`
  color: ${({ theme }) => theme.colors.mainText};
  margin-bottom: 3px;
  ${(p) => (p.layout === 'summary' ? subHeadMedium : footnoteStrong)};
`

export const PreferredLabel = styled.span`
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.success};
  ${captionStrongCaps};
  margin-left: auto;
  margin-right: 37px;
  background: ${(p) => p.theme.colors.ecoCheck1};
  padding: 1px 4px;
  border-radius: 4px;

  :last-child {
    margin-right: 73px;
  }

  + div {
    margin-left: 0;
  }
`
