import styled from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  border-radius: 10px;
  background: ${(p) => p.theme.colors.white};
  overflow: hidden;

  ${KeyboardNavigationStyle}
`

export const Content = styled.div`
  min-height: 205px;
  display: grid;
  grid-template-columns: 192px 1fr 224px;
  gap: 40px 44px;
`
