import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Label = styled.label<{ isHidden?: boolean }>`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
  width: 100%;
`

export const RadioPoint = styled.div<{
  isChecked: boolean
  disabled?: boolean
}>`
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  margin-right: 12px;

  content: '';
  border: 2px solid
    ${(props) =>
      props.isChecked && props.disabled
        ? props.theme.colors.primary3
        : props.theme.colors.borderDark};
  background: ${(props) =>
    props.isChecked && props.disabled ? props.theme.colors.primary3 : props.theme.colors.white};
  box-shadow: ${(props) => (props.isChecked && props.disabled ? 'inset 0 0 0 2px #fff' : '')};

  transition: all 250ms ease;

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primary3};
  }

  ${KeyboardNavigationStyle}
`

export const RadioInput = styled.input<{ isDisabledAndSelected?: boolean }>`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 0;
  height: 0;
  appearance: none;

  &:checked + ${RadioPoint} {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.background1 : props.theme.colors.primary3};
    border: 1px solid
      ${(props) => (props.disabled ? props.theme.colors.background1 : props.theme.colors.primary3)};
    box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.white};
  }

  ${(p) =>
    p.isDisabledAndSelected &&
    css`
      & + ${RadioPoint} {
        background-color: ${(props) => props.theme.colors.background1};
        border: 1px solid ${(props) => props.theme.colors.background1};
        box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.white};
      }
    `}
`
