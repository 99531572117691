import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import { Wrapper, Text, Icon } from './add-button-styled'

type AddButtonProps = {
  type: SegmentType.Flight | SegmentType.Hotel | SegmentType.CarRental
  isDisabled?: boolean
}

const buttonTranslatesMap = {
  [SegmentType.Flight]: 'Flight',
  [SegmentType.Hotel]: 'Hotel',
  [SegmentType.CarRental]: 'CarRental',
}

export function AddButton({ type, isDisabled }: AddButtonProps) {
  const { t } = useTranslation()
  const i18nPath = 'TripReview.AddButtonsPWA.'
  const label = t(i18nPath + buttonTranslatesMap[type])

  return (
    <Wrapper disabled={isDisabled} aria-label={type}>
      <Icon
        icon={getIconNameBySegment(type)}
        size="medium"
        color={isDisabled ? 'bodyText' : 'mainText'}
      />
      <Text>{label}</Text>
    </Wrapper>
  )
}
