import type { MoneyValueObject } from '@etta/core/value-objects/money.value-object'
import type { MobilitySearchQueryType } from '@fiji/hooks/search-queries/use-mobility-search-query'
import type { Place, PlainTime as Time } from '@fiji/types'

export type LoadLocationRefinementOptionsArgs = {
  latitude: number
  longitude: number
  searchResultID: string
}

export type RideHailAirPortPickupQueryParamsType = Pick<
  MobilitySearchQueryType,
  'processId' | 'transactionGroupId' | 'pickUpPlace'
>

export type LocationRefinementQueryParamsType = Pick<
  MobilitySearchQueryType,
  'pickUpPlace' | 'pickUpAddress' | 'dropOffPlace' | 'dropOffAddress' | 'reserveItemId'
>

export type SearchRideHailQueryParamsType = Pick<
  MobilitySearchQueryType,
  | 'pickUpPlace'
  | 'pickUpAddress'
  | 'dropOffPlace'
  | 'dropOffAddress'
  | 'pickUpDate'
  | 'pickUpTime'
  | 'processId'
>

export type SearchRideHailTripInfo = {
  processId: string
  transactionGroupId?: number
  dropOffSegmentId?: string
}

export type SearchRideHailForm = {
  pickUpPlace?: Place
  pickUpAddress?: string
  pickUpDate?: Date
  pickUpTime?: Time
  dropOffPlace?: Place
  dropOffAddress?: string

  isSkipPopulate?: boolean
  estimatedPrice?: MoneyValueObject
  displayName?: string
  reserveItemId?: string
  dropOffSegmentType?: string
  dropOffSegmentId?: string
}

export enum RenderState {
  whereTo = 'whereTo',
  searchResult = 'searchResult',
  zoning = 'zoning',
  zoneRefining = 'zoneRefining',
  expenseCode = 'expenseCode',
  pickUpConfirm = 'pickUpConfirm',
  review = 'review',
  loading = 'loading',
  confirm = 'confirm',
  reserveAirportPickUp = 'reserveAirportPickUp',
  searchForm = 'searchForm',
}

export type BookRideHailQueryParamsType = Pick<
  MobilitySearchQueryType,
  | 'bookingId'
  | 'bookingError'
  | 'pickUpPlace'
  | 'pickUpAddress'
  | 'dropOffPlace'
  | 'dropOffAddress'
  | 'pickUpDate'
  | 'pickUpTime'
  | 'processId'
>

export enum ExpenseCodePage {
  SelectExpenseCode = 'SelectExpenseCode',
  EnterCustomExpenseCode = 'EnterCustomExpenseCode',
  AddExpenseCode = 'AddExpenseCode',
}
