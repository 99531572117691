import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const Container = styled.div<{ isFooterVisible: boolean }>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  @media (${screenSize.maxTablet}) {
    ${(props) => !props.isFooterVisible && 'height: 100%; overflow: hidden;'}
  }
`

export const Footer = styled.div`
  flex-shrink: 0;
  margin-top: auto;
`
