import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { ModalAnimation } from '@etta/ui/modal-animation'
import { HotelDistanceSlider } from './hotel-distance-slider'
import { useHotelDistance } from './use-hotel-distance'
import { Container, Header, Footer } from './hotel-distance-styled'

const i18base = 'TripPlanner.BaseSearch.Distance'

export type Props = {
  modalWidth?: number
  isOpen: boolean
  distance?: number
  distanceKey: string
  onChange: (value: number) => void
  onClose?: () => void
}

export function HotelDistanceModal({
  distance,
  isOpen,
  onChange,
  onClose,
  modalWidth,
  distanceKey,
}: Props) {
  const { t } = useTranslation()
  const {
    newDistance,
    handleApplyButton,
    handleChangeDistance,
    showFooter,
    ref,
  } = useHotelDistance({ distance, onChange, onClose })

  return (
    <ModalAnimation isOpen={isOpen}>
      <Container containerWidth={modalWidth} ref={ref} withFooter={showFooter}>
        <Header>
          <Text variant="captionMedium" color="bodyText">
            {t(`${i18base}.ShowHotels`)}
          </Text>
          <Text variant="headline" color="mainText">
            {t(distanceKey, { count: newDistance })}
          </Text>
        </Header>
        <Block paddingHorizontal={24} paddingVertical={16}>
          <HotelDistanceSlider onChange={handleChangeDistance} distance={newDistance} />
        </Block>
        {showFooter && (
          <Footer>
            <Button fullWidth onClick={handleApplyButton}>
              {t(`${i18base}.ApplyButton`)}
            </Button>
          </Footer>
        )}
      </Container>
    </ModalAnimation>
  )
}
