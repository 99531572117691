import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'

export const TripHeaderContainer = styled.div`
  width: 100%;
  padding: 16px 28px 18px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const EditTitleButton = styled(IconButton)`
  align-items: flex-start;
  justify-content: flex-end;
`

export const DatesContainer = styled.div`
  display: flex;
  gap: 6px;
`
