import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import {
  Container,
  FlightDetailsContainer,
  AirlineLogo,
} from './ride-hail-flight-details-components'

export type Props = {
  flightDesignator?: string
  flightDate?: string
}

const i18nBase = 'Mobility.ReviewFlightDetails'

export const RideHailCustomFlightDetail = ({ flightDesignator = '', flightDate = '' }: Props) => {
  const { t } = useTranslation()

  const i18nBaseForBrand = 'Mobility.UberNewInformationPage.Brand.'

  const { brandConfigurationStore } = useBrandConfigurationContext()
  const brandLabel = brandConfigurationStore.isAppleTheme
    ? t(i18nBaseForBrand + 'Kan')
    : t(i18nBaseForBrand + 'Deem')

  const arrivalDate = dateFormat(new Date(flightDate), 'MMM d, y')

  return (
    <Container isWhiteBackground={false} isSelectable={false} isSelected={false}>
      <AirlineLogo isWhiteBackground={false}>
        <Icon name="flightPWA" size="large" color={'white'} />
      </AirlineLogo>
      <FlightDetailsContainer>
        <Text variant="subHeadStrong" color={'white'}>
          {`${flightDesignator} - ${arrivalDate}`}
        </Text>
        <Block marginTop={6}>
          <Text variant="footnoteMedium" color={'white'}>
            {t(`${i18nBase}.CustomAddFlight`, { brand: brandLabel })}
          </Text>
        </Block>
      </FlightDetailsContainer>
    </Container>
  )
}
