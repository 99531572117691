import { Store } from '@etta/di'
import type { UserProfileEntity } from '../../core/entities/user-profile.entity'

@Store()
export class UserProfileStore {
  isUserProfileLoading = false

  isUserProfileError = false

  userProfile: UserProfileEntity | null = null

  setIsLoading(isLoading: boolean) {
    this.isUserProfileLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isUserProfileError = isError
  }

  setUserProfile(userProfile: UserProfileEntity | null) {
    this.userProfile = userProfile
  }
}
