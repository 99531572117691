import styled from 'styled-components'
import { ErrorBlock } from '../../../air-search-form-styled'

export const LocationPickerWrapper = styled.div`
  width: 50%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledErrorBlock = styled(ErrorBlock)`
  padding: 0;
`

export const ErrorBlockWrapper = styled.div`
  position: absolute;
  bottom: -29px;
`
