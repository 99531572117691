import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { Container, Content } from './connection-status-styled'
import { TRANSITION_CLASS, TRANSITION_DURATION } from './transition'
import { useConnectionStatus } from './hooks'

const i18nBase = 'Screen.ConnectionStatus'

export const ConnectionStatus = observer(function ConnectionStatus() {
  const { isStatusVisible, isOnline, previousIsStatusVisible, isAuth } = useConnectionStatus()
  const { t } = useTranslation()

  if (!isAuth) {
    return null
  }

  return (
    <CSSTransition
      classNames={TRANSITION_CLASS}
      in={isStatusVisible}
      appear
      timeout={TRANSITION_DURATION}>
      <Container>
        <Content
          backgroundColor={isOnline ? 'success' : 'background2'}
          isNoAnimate={!previousIsStatusVisible && isStatusVisible}>
          <Text variant="captionMedium" color="mainText2">
            {t(`${i18nBase}.${isOnline ? 'Online' : 'Offline'}`)}
          </Text>
        </Content>
      </Container>
    </CSSTransition>
  )
})
