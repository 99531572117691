import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'
import { CONTENT_MARGIN_TOP, CONTENT_WITHOUT_PHOTOS_MARGIN_TOP } from './constants'

const CONTENT_MARGIN_TOP_ABS = Math.abs(CONTENT_MARGIN_TOP)
const CONTENT_WITHOUT_PHOTOS_MARGIN_TOP_ABS = Math.abs(CONTENT_WITHOUT_PHOTOS_MARGIN_TOP)

export const DetailsContainer = styled.div<{
  color: keyof ColorPalette
  hasPhotos: boolean
}>`
  background-color: ${({ color, theme }) => theme.colors[color]};
  padding: 20px 16px
    ${(p) =>
      p.hasPhotos ? CONTENT_MARGIN_TOP_ABS + 4 : CONTENT_WITHOUT_PHOTOS_MARGIN_TOP_ABS + 14}px
    16px;
`

export const Paper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
`
