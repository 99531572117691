import { useMemo } from 'react'
import type { FlightLegSegment, SeatmapCabinClass } from '@fiji/graphql/types'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'

type Args = {
  flight: FlightLegSegment
}

export function useFlightDetailsFares({ flight }: Args) {
  const firstSegment = flight.segments[0]
  const flightSeats = useMemo(() => {
    return flight.segments
      .map((segment) => segment.travelerSeat)
      .filter(Boolean)
      .join(', ')
  }, [flight])

  const { getCabinClassLabel } = useCabinClassLabels()

  const cabinClassLabel = getCabinClassLabel(firstSegment.serviceClass as SeatmapCabinClass)

  const isSeatMapAvailable = flight.segments.some(({ isSeatAvailable }) => isSeatAvailable)

  return {
    cabinClassLabel,
    flightSeats,
    firstSegment,
    isSeatMapAvailable,
  }
}
