import i18next from 'i18next'
import { UserProfileCustomFieldType } from '@fiji/graphql/hooks'
import type { GetUserProfileQuery } from '@fiji/graphql/hooks'
import type {
  CommonFields,
  UserProfileCheckBoxEntity,
  UserProfileDateTimeEntity,
  UserProfileEntity,
  UserProfileListEntity,
  UserProfileNumberEntity,
  UserProfileTextEntity,
  UserProfileUnionEntity,
} from '@etta/modules/user/core/entities/user-profile.entity'
import { NumberCustomFieldType } from '@fiji/graphql/types'
import type {
  CheckBoxAdditionalInformation,
  DateTimeAdditionalInformation,
  ListAdditionalInformation,
  NumberAdditionalInformation,
  TextAdditionalInformation,
} from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { NumberFieldType } from '../../../core/entities/user-profile.entity'
import { UserProfileFieldType } from '../../../core/enums/user-profile-field-type.enum'

export class UserProfileMapper {
  static toUserProfileEntity(
    userProfile: GetUserProfileQuery['getUserProfile'],
  ): UserProfileEntity {
    return {
      businessAddress: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.businessAddress?.additionalInformation || [],
        ),
      },
      employeeInformation: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.employeeInformation?.additionalInformation || [],
        ),
      },
      homeAddress: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.homeAddress?.additionalInformation || [],
        ),
      },
      personalInformation: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.personalInformation?.additionalInformation || [],
        ),
      },
      emergencyContact: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.emergencyContact?.additionalInformation || [],
        ),
      },
      travelPreferences: {
        additionalInformation: this.toAdditionalInformationList(
          userProfile.travelPreferences?.additionalInformation || [],
        ),
      },
    }
  }

  private static toAdditionalInformationList(
    additionalInformation: GetUserProfileQuery['getUserProfile']['businessAddress']['additionalInformation'],
  ): UserProfileUnionEntity[] {
    return additionalInformation.reduce((result: UserProfileUnionEntity[], info) => {
      const commonFields = {
        id: info.id,
        label: info.label,
        name: info.name,
        mandatory: info.mandatory,
        tooltip: info.tooltip,
        type: this.toFieldType(info.fieldType),
      } as CommonFields

      switch (info.fieldType) {
        case UserProfileCustomFieldType.CheckBox:
          result.push(
            this.toUserProfileCheckBoxEntity(
              commonFields,
              info.additionalFieldProperties as CheckBoxAdditionalInformation,
            ),
          )
          break
        case UserProfileCustomFieldType.Text:
          result.push(
            this.toUserProfileTextEntity(
              commonFields,
              info.additionalFieldProperties as TextAdditionalInformation,
            ),
          )
          break
        case UserProfileCustomFieldType.Number:
          result.push(
            this.toUserProfileNumberEntity(
              commonFields,
              info.additionalFieldProperties as NumberAdditionalInformation,
            ),
          )
          break
        case UserProfileCustomFieldType.List:
          result.push(
            this.toUserProfileListEntity(
              commonFields,
              info.additionalFieldProperties as ListAdditionalInformation,
            ),
          )
          break
        case UserProfileCustomFieldType.DateTime:
          result.push(
            this.toUserProfileDateTimeEntity(
              commonFields,
              info.additionalFieldProperties as DateTimeAdditionalInformation,
            ),
          )
          break
      }

      return result
    }, [])
  }

  private static toCheckBoxDisplayValue(value?: boolean | null) {
    if (value === null || value === undefined) {
      return '-'
    }

    return value
      ? i18next.t('Settings.Settings.Modal.Yes')
      : i18next.t('Settings.Settings.Modal.No')
  }

  private static toUserProfileCheckBoxEntity(
    commonFields: CommonFields,
    checkBox: CheckBoxAdditionalInformation,
  ): UserProfileCheckBoxEntity {
    return {
      ...commonFields,
      defaultChecked: checkBox.defaultChecked,
      value: checkBox.checkBoxValue,
      checkedRequired: checkBox.checkedRequired,
      displayValue: this.toCheckBoxDisplayValue(checkBox.checkBoxValue),
    }
  }

  private static toUserProfileTextEntity(
    commonFields: CommonFields,
    text: TextAdditionalInformation,
  ): UserProfileTextEntity {
    return {
      ...commonFields,
      singleLine: text.singleLine,
      numbersOfLine: text.numbersOfLine,
      minLength: text.minLength,
      maxLength: text.maxLength,
      defaultText: text.defaultText,
      value: text.textValue,
      displayValue: text.textValue ?? '-',
    }
  }

  private static toFieldType(
    customFieldType: UserProfileCustomFieldType | undefined | null,
  ): UserProfileFieldType {
    switch (customFieldType) {
      case UserProfileCustomFieldType.CheckBox:
        return UserProfileFieldType.CheckBox
      case UserProfileCustomFieldType.DateTime:
        return UserProfileFieldType.DateTime
      case UserProfileCustomFieldType.List:
        return UserProfileFieldType.List
      case UserProfileCustomFieldType.Number:
        return UserProfileFieldType.Number
      case UserProfileCustomFieldType.Text:
        return UserProfileFieldType.Text
      default:
        return UserProfileFieldType.Unspecified
    }
  }

  private static toNumberFieldType(
    numberCustomFieldType: NumberCustomFieldType | undefined | null,
  ): NumberFieldType {
    switch (numberCustomFieldType) {
      case NumberCustomFieldType.Currency:
        return NumberFieldType.CURRENCY
      case NumberCustomFieldType.Number:
        return NumberFieldType.NUMBER
      case NumberCustomFieldType.Percentage:
        return NumberFieldType.PERCENTAGE
      default:
        return NumberFieldType.UNSPECIFIED
    }
  }

  private static toNumberFieldDisplayValue(
    value: number | undefined | null,
    fieldType: NumberCustomFieldType | undefined | null,
  ): string {
    if (!value) {
      return '-'
    }

    if (fieldType === NumberCustomFieldType.Currency) {
      return `$${value}`
    }

    if (fieldType === NumberCustomFieldType.Percentage) {
      return `${value}%`
    }

    return `${value}`
  }

  private static toUserProfileNumberEntity(
    commonFields: CommonFields,
    number: NumberAdditionalInformation,
  ): UserProfileNumberEntity {
    return {
      ...commonFields,
      defaultNumber: number.defaultNumber,
      minNumber: number.minNumber,
      maxNumber: number.maxNumber,
      numberDecimal: number.numberDecimal,
      value: number.numberValue,
      numberType: this.toNumberFieldType(number.numberType),
      displayValue: this.toNumberFieldDisplayValue(number.numberValue, number.numberType),
    }
  }

  private static toUserProfileListEntity(
    commonFields: CommonFields,
    list: ListAdditionalInformation,
  ): UserProfileListEntity {
    return {
      ...commonFields,
      fieldType: list.listType,
      alignment: list.listAlignment,
      defaultValue: list.defaultValue,
      isAlphabeticallySorted: list.isAlphabeticallySorted,
      value: list.value,
      displayValue: list.options?.find((option) => option.value === list.value)?.name ?? '-',
      options: list.options
        ? list.options.map((option) => {
            return {
              key: option.name,
              value: option.value,
            }
          })
        : [],
    }
  }

  private static toUserProfileDateTimeEntity(
    commonFields: CommonFields,
    dateTime: DateTimeAdditionalInformation,
  ): UserProfileDateTimeEntity {
    return {
      ...commonFields,
      isAllowedPastDateTime: dateTime.isAllowedPastDateTime,
      displayYear: dateTime.displayYear,
      displayMonth: dateTime.displayMonth,
      displayDay: dateTime.displayDay,
      displayHour: dateTime.displayHour,
      displayMinute: dateTime.displayMinute,
      displayTimeZone: dateTime.displayTimeZone,
      startYear: dateTime.minYear,
      endYear: dateTime.maxYear,
      startMonth: dateTime.minMonth,
      endMonth: dateTime.maxMonth,
      startDay: dateTime.minDay,
      endDay: dateTime.maxDay,
      value: dateTime.dateValue,
      displayValue: this.toDateTimeDisplayValue({
        dateTime: dateTime.dateValue,
        displayMonth: !!dateTime.displayMonth,
        displayDay: !!dateTime.displayDay,
        displayYear: !!dateTime.displayYear,
        displayHour: !!dateTime.displayHour,
        displayMinute: !!dateTime.displayMinute,
      }),
    }
  }

  // Jan, 20 2025 01:10 pm
  private static toDateTimeDisplayValue(input: {
    dateTime: string | null | undefined
    displayMonth: boolean
    displayDay: boolean
    displayYear: boolean
    displayHour: boolean
    displayMinute: boolean
  }): string {
    if (!input.dateTime) {
      return '-'
    }

    let formatStr = ''
    if (input.displayMinute && input.displayHour) {
      formatStr = `hh:mm aaa`
    } else if (input.displayMinute) {
      formatStr = `mm`
    } else if (input.displayHour) {
      formatStr = `h aa`
    }

    if (input.displayYear) {
      formatStr = formatStr ? `yyyy ${formatStr}` : 'yyyy'
    }
    if (input.displayDay) {
      formatStr = formatStr ? `d ${formatStr}` : 'd'
    }
    if (input.displayMonth) {
      formatStr = formatStr ? `MMM, ${formatStr}` : 'MMM'
    }

    // input.dateTime does not contain time zone, so not using getDateWithoutTimezoneOffset
    return formatStr ? dateFormat(new Date(input.dateTime), formatStr) : input.dateTime
  }
}
