import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries'
import { RailTripType } from '@fiji/hooks/search-queries'
import { anyTimeOption } from '@fiji/hooks/time-configuration/use-rail/constants'
import { ROUTES } from '@fiji/routes'

type SearchRailEditCache = Pick<
  RailSearchQueryType,
  | 'originTime'
  | 'destinationTime'
  | 'railTripType'
  | 'originPlace'
  | 'destinationPlace'
  | 'originDate'
  | 'destinationDate'
  | 'searchRestrictions'
  | 'railCards'
> & {
  itineraryId?: string
}

@Store()
export class RailSearchFormStore {
  railSearchQueryParams = new QueryParams<RailSearchQueryType>(
    {
      originTime: anyTimeOption,
      destinationTime: anyTimeOption,
      railTripType: RailTripType.Round,
      filters: {},
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: ['railCards'],
    },
  )

  searchRailEditCache: SearchRailEditCache = {
    originPlace: {
      placeId: '1',
      name: '',
    },
    destinationPlace: { placeId: '2', name: '' },
    originDate: new Date(),
    destinationDate: new Date(),
    originTime: anyTimeOption,
    destinationTime: anyTimeOption,
    railTripType: RailTripType.OneWay,
    railCards: [],
  }

  constructor(
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  get isEditMode() {
    const currentPath = this.historyStore.currentPath
    const { itineraryId } = this.historyStore.getParams<{ itineraryId: string }>() || {}
    const isRTP = this.historyStore.matchPathname(ROUTES.reviewTrip.main({ itineraryId }))

    if (isRTP) {
      return true
    }

    return currentPath.includes(ROUTES.rail.results({}))
  }

  get railForm() {
    const {
      originDate,
      originTime,
      destinationDate,
      destinationTime,
      originPlace,
      destinationPlace,
      railTripType,
      itineraryId,
      railCards,
      searchRestrictions,
    } = this.isEditMode ? this.searchRailEditCache : this.railSearchQuery
    return {
      originDate,
      originTime,
      destinationDate,
      destinationTime,
      originPlace,
      destinationPlace,
      railTripType: railTripType ?? RailTripType.Round,
      itineraryId,
      railCards,
      filters: {},
      searchRestrictions,
    }
  }

  get railSearchQuery() {
    const {
      originPlace,
      destinationPlace,
      originDate,
      originTime,
      destinationDate,
      destinationTime,
      railTripType,
      searchRestrictions,
      railCards,
      itineraryId,
      bookingId,
      filters,
    } = this.railSearchQueryParams.getQueryParams(this.historyStore.search)
    const mappedRailCards = railCards
      ? railCards.map((railCard) => {
          return {
            code: railCard.code,
            groupName: railCard.groupName,
            name: railCard.name,
            vendorCode: railCard.vendorCode,
            vendorName: railCard.vendorName,
          }
        })
      : undefined
    return {
      originPlace,
      destinationPlace,
      originDate,
      originTime,
      destinationDate,
      destinationTime,
      railTripType,
      searchRestrictions,
      railCards: mappedRailCards,
      itineraryId,
      bookingId,
      filters,
    }
  }

  setSearchRailEditCache(data: SearchRailEditCache) {
    this.searchRailEditCache = {
      ...this.searchRailEditCache,
      ...data,
    }
  }
}
