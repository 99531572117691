import { Modal } from '@etta/ui/modal'
import { AirSeatMap } from '../air-seat-map'
import type { Props } from './air-seat-map-modal.types'

export function AirSeatMapModal({ isVisible, onClose, ...props }: Props) {
  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <AirSeatMap onClose={onClose} {...props} />
    </Modal>
  )
}
