import type { PassportInputValueObject } from '@etta/modules/booking/core/value-objects'
import { dateFormat } from '@fiji/utils/dates'

const DATE_FORMAT = 'yyyy-MM-dd'

export function getPassportInputBody(values: {
  number: string
  country: string
  issuingCountry: string
  expirationDate?: Date
  issuingDate?: Date
}): PassportInputValueObject {
  const { number, issuingCountry, country, issuingDate, expirationDate } = values

  const formatIssueDate = issuingDate ? dateFormat(issuingDate, DATE_FORMAT) : ''
  const formatExpirationDate = expirationDate ? dateFormat(expirationDate, DATE_FORMAT) : ''

  return {
    number,
    country,
    issueCountry: issuingCountry,
    issueDate: formatIssueDate,
    expirationDate: formatExpirationDate,
  }
}
