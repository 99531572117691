import { Store, Inject } from '@etta/di'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'

import {
  updateZooKeeperConfigLoading,
  updateZooKeeperConfiguration,
  resetZooKeeperConfiguration,
} from '@fiji/store/zookeeper-configuration'
import type { ZookeeperConfigurationValueObject } from '../../core/value-objects'

@Store()
export class ZooKeeperConfigurationStore {
  private _isLoading: boolean = false

  zooKeeperConfiguration: ZookeeperConfigurationValueObject = {
    policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled: false,
    southwestCheckInEmailNotificationEnabled: false,
    southwestCheckInSmsNotificationEnabled: false,
    removeNotificationSectionOnTheCheckoutPageEnabled: false,
    nonStopFilterByDefaultEnabled: false,
    blockBasicEconomyEnabled: false,
    remoteProfileMessage: '',
    remoteProfileEnabled: false,
    removeHotelStarRatingEnabled: false,
    disableTsaHazmatMessageEnabled: false,
    tieredCostAllocationEnabled: false,
    removePerDiemLinkEnabled: null,
  }

  constructor(
    @Inject()
    private readonly fijiStoreProxy: FijiStoreProxyService,
  ) {}

  get isLoading() {
    return this._isLoading
  }

  get policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled() {
    return this.zooKeeperConfiguration.policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled
  }

  get southwestCheckInEmailNotificationEnabled() {
    return this.zooKeeperConfiguration.southwestCheckInEmailNotificationEnabled
  }

  get southwestCheckInSmsNotificationEnabled() {
    return this.zooKeeperConfiguration.southwestCheckInSmsNotificationEnabled
  }

  get removeNotificationSectionOnTheCheckoutPageEnabled() {
    return this.zooKeeperConfiguration.removeNotificationSectionOnTheCheckoutPageEnabled
  }

  get nonStopFilterByDefaultEnabled() {
    return this.zooKeeperConfiguration.nonStopFilterByDefaultEnabled
  }

  get blockBasicEconomyEnabled() {
    return this.zooKeeperConfiguration.blockBasicEconomyEnabled
  }

  get remoteProfileMessage() {
    return this.zooKeeperConfiguration.remoteProfileMessage
  }

  get remoteProfileEnabled() {
    return this.zooKeeperConfiguration.remoteProfileEnabled
  }

  get removeHotelStarRatingEnabled() {
    return this.zooKeeperConfiguration.removeHotelStarRatingEnabled
  }

  get disableTsaHazmatMessageEnabled() {
    return this.zooKeeperConfiguration.disableTsaHazmatMessageEnabled
  }

  get tieredCostAllocationEnabled() {
    return this.zooKeeperConfiguration.tieredCostAllocationEnabled
  }

  get removePerDiemLinkEnabled() {
    return this.zooKeeperConfiguration.removePerDiemLinkEnabled
  }

  setIsLoading(value: boolean) {
    this._isLoading = value

    this.fijiStoreProxy.dispatch(
      updateZooKeeperConfigLoading({
        zooKeeperConfigLoading: value,
      }),
    )
  }

  setZooKeeprConfiguration(zooKeeperConfiguration: ZookeeperConfigurationValueObject) {
    this.zooKeeperConfiguration = zooKeeperConfiguration

    this.fijiStoreProxy.dispatch(
      updateZooKeeperConfiguration({
        zooKeeperConfigLoading: false,
        ...zooKeeperConfiguration,
      }),
    )
  }

  resetZooKeeperConfiguration() {
    this.fijiStoreProxy.dispatch(resetZooKeeperConfiguration())
  }
}
