import type { ReactNode } from 'react'
import type { TextFieldHelperType } from '@etta/ui/text-field/types'

export type RenderMode = 'component' | 'simple'

export type DropDownListProps<T = ValueType> = {
  label: string
  isDisabled?: boolean
  onChange: (value: T[]) => void
  value?: T[]
  options: OptionType<T>[]
  type?: ListTypes
  defaultLabel?: string
  'data-tracking-id'?: string
  customAriaLabel?: string
  withListBodyPadding?: boolean
  withThinLabel?: boolean
  withListHeader?: boolean
  withVisibleLabel?: boolean
  withFullHeight?: boolean
  withNoneOption: boolean
  helperText?: string
  helperType?: TextFieldHelperType
  isOutsideAlignRight?: boolean
  isFixedPositioned?: boolean
  scrollParentRef?: React.RefObject<HTMLElement>
  shouldCloseOnScroll?: boolean
  renderMode?: RenderMode
  isBlurred?: boolean
}

export type ListConfig = {
  x: number
  y: number
  width: number
  excludeHeight: number
  height?: number
}

export enum OptionViewTypes {
  Title = 'title',
  Option = 'option',
  // https://www.figma.com/file/BKN43UJAbzNUwqyXMHbehV/PWA-Web-Master-File?node-id=2799%3A67525
  OptionWithCheckboxIcon = 'option-with-checkbox-icon',
}

export type OptionType<T = ValueType> = {
  label: ReactNode
  value: T
  shortLabel?: string
  isDefaultValue?: boolean
  viewType?: OptionViewTypes
}

export enum ListTypes {
  Radio = 'Radio',
  Select = 'Select',
}

export type ValueType = string | number | null | undefined
