import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle, bodyLarge, captionMedium } from '@fiji/style'

export const Label = styled.label`
  ${captionMedium};
  line-height: 16px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Value = styled.div<{ isLabel: boolean }>`
  ${bodyLarge};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  ${(props) =>
    props.isLabel &&
    css`
      color: ${(props) => props.theme.colors.bodyText1};
    `}
`

export const InputContainer = styled.button<{ disabled?: boolean }>`
  text-align: left;
  padding: 0 17px;
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : props.theme.inputBorderColor)};
  color: ${(props) => props.theme.inputColor};
  max-height: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.background)};
  ${KeyboardNavigationStyle}
`
