import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import { CarLegType } from '@fiji/enums'
import { PresearchMultiLayerCostAllocation } from '@etta/components/multi-layer-cost-allocation'
import { SegmentDateTime } from '@etta/components/segment-date-time/segment-date-time'
import { Screen } from '@etta/ui/screen'
import { Block } from '@etta/ui/block'
import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { Header as MobileHeader } from '@etta/ui/header'
import { SwipingTimePicker } from '@etta/ui/swiping-time-date-picker'
import { CarRentalSearchFormMobileRecentSearches } from '@etta/modules/recent-searches/ui/car-rental-form-list/mobile'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { CarRentalPlace } from '../../car-rental-place'
import { CarRentalSearchButton } from '../../car-rental-search-button'
import type { LayoutProps } from '../../types'

export function CarRentalSearchPageMobile({
  pickUpPlace,
  onPickUpPlaceChange,
  dropOffPlace,
  onDropOffPlaceChange,
  pickUpDate,
  dropOffDate,
  onDatesSelect,
  pickUpTime,
  dropOffTime,
  onDropOffTimeChange,
  onPickUpTimeChange,
  onSearchClick,
  onBack,
  placesHistory,
  pickUpDateToggle,
  dropOffDateToggle,
  pickUpTimeToggle,
  dropOffTimeToggle,
  validationErrors,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.BaseSearch'
  const { costAllocationFormStore } = useCostAllocationContext()
  const costAllocationValidationErrors = costAllocationFormStore.validationErrors.costAllocation

  return (
    <Screen>
      <Screen.Container>
        <Screen.Header>
          <MobileHeader withBorderBottom leftSlot={<MobileHeader.CloseButton onClick={onBack} />}>
            <MobileHeader.Title title={t(`${i18Base}.Header.CarRental`)} align="left" />
          </MobileHeader>
        </Screen.Header>

        <Block paddingVertical={30} paddingHorizontal={16}>
          <CarRentalPlace
            onPickUpPlaceChange={onPickUpPlaceChange}
            pickUpPlace={pickUpPlace}
            onDropOffPlaceChange={onDropOffPlaceChange}
            dropOffPlace={dropOffPlace}
            placesHistory={placesHistory}
            validationErrors={validationErrors}
          />

          <Block marginVertical={12}>
            <SegmentDateTime
              segmentType={SegmentType.CarRental}
              type={CarLegType.PickUp}
              onOpenDatePicker={pickUpDateToggle.handleOpen}
              onOpenTimePicker={pickUpTimeToggle.handleOpen}
              date={pickUpDate}
              time={pickUpTime}
              onDateSelect={onDatesSelect}
              startDateTitle={t(`${i18Base}.Input.PickUp`)}
              endDateTitle={t(`${i18Base}.Input.DropOff`)}
              timePickerTitle={t(`${i18Base}.Time.PickUp`)}
              datePicker={pickUpDate}
              datePickerEnd={dropOffDate}
              onApplyTimePicker={onPickUpTimeChange}
              dateErrorMessage={
                validationErrors.dropOffDate ? undefined : validationErrors.pickUpDate
              }
              hasError={!!validationErrors.pickUpDate}
            />
          </Block>

          <Block marginVertical={12}>
            <SegmentDateTime
              isDatePickerReturnDate
              segmentType={SegmentType.CarRental}
              type={CarLegType.DropOff}
              onOpenDatePicker={dropOffDateToggle.handleOpen}
              onOpenTimePicker={dropOffTimeToggle.handleOpen}
              date={dropOffDate}
              time={dropOffTime}
              onDateSelect={onDatesSelect}
              startDateTitle={t(`${i18Base}.Input.PickUp`)}
              endDateTitle={t(`${i18Base}.Input.DropOff`)}
              timePickerTitle={t(`${i18Base}.Time.DropOff`)}
              datePicker={pickUpDate}
              datePickerEnd={dropOffDate}
              onApplyTimePicker={onDropOffTimeChange}
              dateErrorMessage={validationErrors.dropOffDate}
              timeErrorMessage={validationErrors.dropOffTime}
            />
          </Block>
        </Block>

        <Block marginTop={-30} paddingHorizontal={16}>
          <PresearchMultiLayerCostAllocation error={costAllocationValidationErrors} />
        </Block>

        <Block marginTop={50}>
          <CarRentalSearchFormMobileRecentSearches />
        </Block>

        <Block marginTop="auto" marginBottom={16} marginHorizontal={16}>
          <CarRentalSearchButton onSearchClick={onSearchClick} />
        </Block>

        <DatePickerModal
          isEndDateSame
          withConfirmOneWayButton={false}
          isOpen={pickUpDateToggle.isOpen}
          onSelect={onDatesSelect}
          onClose={pickUpDateToggle.handleClose}
          startTitle={t(`${i18Base}.Input.PickUp`)}
          endTitle={t(`${i18Base}.Input.DropOff`)}
          isRange
          date={pickUpDate}
          dateEnd={dropOffDate}
          minDate={new Date()}
        />
        <DatePickerModal
          isEndDateSame
          withConfirmOneWayButton={false}
          isOpen={dropOffDateToggle.isOpen}
          onSelect={onDatesSelect}
          onClose={dropOffDateToggle.handleClose}
          startTitle={t(`${i18Base}.Input.PickUp`)}
          endTitle={t(`${i18Base}.Input.DropOff`)}
          isRange
          date={pickUpDate}
          dateEnd={dropOffDate}
          minDate={new Date()}
        />
        <SwipingTimePicker
          value={pickUpTime}
          label={t(`${i18Base}.Time.PickUp`)}
          isVisible={pickUpTimeToggle.isOpen}
          onClose={pickUpTimeToggle.handleClose}
          onApply={onPickUpTimeChange}
        />
        <SwipingTimePicker
          value={dropOffTime}
          label={t(`${i18Base}.Time.DropOff`)}
          isVisible={dropOffTimeToggle.isOpen}
          onClose={dropOffTimeToggle.handleClose}
          onApply={onDropOffTimeChange}
        />
      </Screen.Container>
    </Screen>
  )
}
