import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { MediaLayout } from '@etta/ui/media-layout'
import type { FlightSafetyCheckHeadline } from '@fiji/types'
import { IconContainer, ModelBlock } from './expanded-view-item-styled'

type Props = {
  headLine: FlightSafetyCheckHeadline
}

export function ExpandedViewItem({ headLine }: Props) {
  return (
    <MediaLayout
      mobileSlot={
        <Block marginBottom={8} data-tracking-id={`expanded-view_${headLine.title}`}>
          {headLine.iconName && (
            <IconContainer
              data-tracking-id={`expanded_icon_${headLine.iconName}`}
              name={headLine.iconName}
              color="bodyText"
            />
          )}
          <Text variant="footnoteMedium">{headLine.title}</Text>
        </Block>
      }
      desktopSlot={
        <ModelBlock marginBottom={8}>
          {headLine.iconName && <IconContainer name={headLine.iconName} color="bodyText" />}
          <Text variant="footnoteMedium">{headLine.title}</Text>
        </ModelBlock>
      }
    />
  )
}
