import { truncateText } from '@fiji/utils/get-truncate-text'
import type { Props } from '../../types'
import { getFirstLineWithText } from '../../get-first-line-with-text'
import { Container } from './message-mobile-styled'

export function MessageMobile({ message }: Props) {
  return (
    <Container dangerouslySetInnerHTML={{ __html: truncateText(getFirstLineWithText(message)) }} />
  )
}
