import styled from 'styled-components'
import { footnoteMedium, title3 } from '@fiji/style'

export const Header = styled.div`
  padding: 12px 0 14px;
`

export const HeaderTitle = styled.div`
  ${title3};
  color: ${(props) => props.theme.colors.mainText};
`

export const TripTitle = styled.div`
  margin-top: 2px;
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  min-width: 200px;
`
