import { useTranslation } from 'react-i18next'
import { TextField } from '@etta/ui/text-field'
import { Text } from '@etta/ui/text'
import { CardIcon } from '../../../card-icon'
import { Edit } from '../../../edit'
import { EditCardButton } from '../../../edit-card-button'
import { RowError } from '../../row-error'
import type { LayoutProps } from '../types'
import {
  CardInfoContainer,
  CardName,
  RowContainer,
  CardContainer,
  CVVFieldWrapper,
  CVVField,
  PreferredLabel,
} from './row-desktop-styled'

const i18nBaseAria = 'Accessibility.Settings'

export function RowDesktop({
  cardVendor,
  cardName,
  title,
  CVVValue,
  CVVHelperText,
  editLabel,
  layout,
  cardInformationColor,
  cardBackgroundColor,
  withEditIcon,
  withDeleteIcon,
  isChecked,
  isSelectable,
  isEditable,
  isVirtualPayRestricted,
  isCvvRequired,
  onChange,
  onEdit,
  onCVVFieldChange,
  billingError,
  isDisabled,
  preferredCardStatus,
  isBlurred,
}: LayoutProps) {
  const { t } = useTranslation()
  return (
    <CardContainer>
      <RowContainer
        aria-label={t('CreditCardItem.PaymentCardAria', {
          cardName,
          title,
        })}
        withPaddingBottom={isCvvRequired}>
        <Edit
          isDisabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange}
          isSelectable={isSelectable}>
          <CardIcon cardVendor={cardVendor} backgroundColor={cardBackgroundColor} />
          <CardInfoContainer layout={layout}>
            <CardName
              aria-label={t(`${i18nBaseAria}.CardName`, { label: cardName })}
              layout={layout}>
              {cardName}
            </CardName>
            {title && (
              <Text
                isBlurred={isBlurred}
                variant="footnoteMedium"
                color={cardInformationColor}
                aria-label={t(`${i18nBaseAria}.CardNumberAndExpiryDate`, {
                  label: isBlurred ? '****1234, Exp: 00/2022' : title,
                })}>
                {isBlurred ? '****1234, Exp: 00/2022' : title}
              </Text>
            )}
            {!isDisabled && <RowError billingError={billingError} onEdit={onEdit} />}
          </CardInfoContainer>
          {preferredCardStatus?.length ? (
            <PreferredLabel
              aria-label={t(`${i18nBaseAria}.Status`, {
                label: t('CreditCardItem.DefaultFor', { segments: preferredCardStatus.join(', ') }),
              })}>
              {t('CreditCardItem.DefaultFor', { segments: preferredCardStatus.join(', ') })}
            </PreferredLabel>
          ) : (
            <></>
          )}
        </Edit>
        <EditCardButton
          isDisabled={isDisabled}
          editLabel={editLabel}
          isEditable={isEditable}
          isVirtualPayRestricted={isVirtualPayRestricted}
          withEditIcon={withEditIcon}
          onEdit={onEdit}
          withDeleteIcon={withDeleteIcon}
          withButton={layout === 'summary'}
        />
      </RowContainer>
      {isCvvRequired && (
        <CVVFieldWrapper>
          <CVVField>
            <TextField
              type="cvv"
              value={CVVValue || ''}
              helperText={CVVHelperText}
              onChange={(value) => onCVVFieldChange?.('securityCode')(value)}
            />
          </CVVField>
        </CVVFieldWrapper>
      )}
    </CardContainer>
  )
}
