import { CustomFieldComponent } from './fields/custom-field'
import type { CustomFieldCollectionItemExtension } from './types'
import { TitleHelperText, BlockTitle } from './custom-field-styled'

type Props = {
  field: CustomFieldCollectionItemExtension
  onChange(field: string): (value: string | Date | boolean | null | undefined) => void
  values: Record<string, string>
  errors: Record<string | number, string>
}

export function CustomField({ field, onChange, values, errors }: Props) {
  return (
    <>
      <BlockTitle>{field.collectionLabel}</BlockTitle>
      {!!field.collectionDescription && (
        <TitleHelperText>{field.collectionDescription}</TitleHelperText>
      )}
      <CustomFieldComponent
        onChange={onChange}
        values={values}
        fields={field.customFields}
        errors={errors}
      />
    </>
  )
}
