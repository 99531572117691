import type { PreferenceContent } from '@fiji/types'

export function checkEmptyPreference(preference?: PreferenceContent, phone?: string) {
  return !(
    preference &&
    (preference.loyaltyProgram?.some((item) => item.number) ||
      preference.specialRequest?.length ||
      preference.meal ||
      phone ||
      preference.isETicket)
  )
}
