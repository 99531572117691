import { DataProvider } from '@etta/di'
import type {
  GetTravelPreferencesQuery,
  GetTravelPreferencesQueryVariables,
} from '@fiji/graphql/hooks'
import { GetTravelPreferencesDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class TravelPreferencesProvider extends GraphqlDataProvider {
  getTravelPreferences() {
    return this.client.query<GetTravelPreferencesQuery, GetTravelPreferencesQueryVariables>({
      query: GetTravelPreferencesDocument,
      fetchPolicy: 'no-cache',
    })
  }
}
