import { Action, Inject } from '@etta/di'
import { GetBrandConfigurationService } from '../service/get-brand-config.service'

@Action()
export class GetBrandConfigurationAction {
  constructor(
    @Inject()
    private readonly getBrandConfigurationService: GetBrandConfigurationService,
  ) {}

  async getBrandConfiguration() {
    await this.getBrandConfigurationService.get()
  }

  async reloadBrandConfiguration() {
    await this.getBrandConfigurationService.reload()
  }
}
