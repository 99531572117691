import styled from 'styled-components'
import { slideUp } from './transition'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  top: -1;
  bottom: 0;
  position: absolute;
  z-index: 22;
  ${slideUp};
`

export const Body = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 24px;
`

export const HeaderTextWrapper = styled.div`
  padding-bottom: 4px;
`

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  width: 100%;
  padding: 16px;
`
