import { Dialog } from '@etta/ui/dialog'
import { InactiveModal, ExpiredModal } from './blocks'
import { useSessionModal } from './use-session-modal'

type Props = {
  isOpen: boolean
  handleStayConnect: () => void
  handleClose: () => void
}

export function SessionModal({ isOpen, handleStayConnect, handleClose }: Props) {
  const { countdownTimer, isTimerExpired, onLogout } = useSessionModal({
    isOpen,
    handleClose,
  })

  if (!isOpen) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => {}}>
      <Dialog.Body>
        {isTimerExpired ? (
          <ExpiredModal onLogout={onLogout} />
        ) : (
          <InactiveModal
            countdownTimer={countdownTimer}
            onStayConnect={handleStayConnect}
            onLogOut={onLogout}
          />
        )}
      </Dialog.Body>
    </Dialog>
  )
}
