import { useTheme } from '@fiji/hooks/use-theme'

type Props = {
  color?: string
}
export default function OutOfPolicyIcon({ color }: Props) {
  const theme = useTheme()
  const usedColor = (color ? color : theme.colors.error) as string

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0077 38C22.3009 38 22.7175 37.8728 23.1187 37.6662C32.0065 33.0243 35 30.4809 35 24.7899V13.1058C35 11.0551 34.2902 10.2444 32.5774 9.49727C30.9727 8.81371 25.4178 6.82663 23.8902 6.31793C23.3039 6.12717 22.5941 6 22.0077 6C21.4059 6 20.7116 6.14307 20.1252 6.31793C18.5822 6.81073 13.0273 8.82961 11.438 9.49727C9.70979 10.2285 9 11.0551 9 13.1058V24.7899C9 30.4809 12.1015 32.8336 20.8967 37.6662C21.2979 37.8887 21.7145 38 22.0077 38ZM27.2077 28.7799C26.7294 28.7799 26.3436 28.6051 26.0504 28.2871L21.9923 24.1381L17.965 28.2871C17.6564 28.621 17.2552 28.7799 16.7923 28.7799C15.8819 28.7799 15.1721 28.0487 15.1721 27.1108C15.1721 26.6816 15.3573 26.2683 15.6659 25.9662L19.724 21.7854L15.6659 17.5887C15.3573 17.2866 15.1721 16.8733 15.1721 16.4441C15.1721 15.5062 15.8819 14.7909 16.7923 14.7909C17.2552 14.7909 17.6409 14.9498 17.9341 15.2678L21.9923 19.4327L26.0813 15.2678C26.4053 14.918 26.7602 14.775 27.2231 14.775C28.1335 14.775 28.8279 15.4903 28.8279 16.4282C28.8279 16.8733 28.6736 17.2548 28.3496 17.5728L24.2914 21.7854L28.3496 25.9503C28.6427 26.2683 28.8279 26.6657 28.8279 27.1108C28.8279 28.0487 28.1181 28.7799 27.2077 28.7799Z"
        fill={usedColor}
      />
    </svg>
  )
}
