/* eslint-disable import/no-restricted-paths */
import type {
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  HotelSegmentEntity,
} from '@etta/modules/review-trip/core'
import type { Address } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

export class SegmentAddressMapper {
  private static getNameByAddress(address?: Address | null): string {
    if (!address) {
      return ''
    }
    return [address?.city, address?.stateCode, address?.postalCode, address?.countryCode]
      .filter(Boolean)
      .join(', ')
  }

  private static toShortName({
    city,
    stateCode,
    countryCode,
    airportCode,
  }: {
    city?: string
    stateCode?: string
    countryCode?: string
    airportCode?: string
  }): string {
    const code = airportCode ? `(${airportCode})` : ''
    const region = [city, stateCode, countryCode].filter(Boolean).join(', ')
    return [region, code].join(' ')
  }

  public static mapHotelSegmentAddressToLocation(hotel: HotelSegmentEntity) {
    const { address } = hotel
    return SegmentAddressMapper.mapSegmentAddressToLocation(address)
  }

  public static mapCarSegmentAddressToLocation(
    car: CarRentalSegmentEntity,
    location: 'pickup' | 'dropoff',
  ) {
    const { pickUpLocation, dropOffLocation } = car
    if (location === 'pickup' && pickUpLocation.address) {
      const { address } = pickUpLocation
      return SegmentAddressMapper.mapSegmentAddressToLocation(address)
    }

    const { address } = dropOffLocation
    return SegmentAddressMapper.mapSegmentAddressToLocation(address)
  }

  public static mapAirSegmentAddressToLocation(flight: FlightSegmentEntity, _kind: 'departure') {
    const [segment] = flight?.segments?.slice(-1)
    const address = segment?.arrival?.address
    return SegmentAddressMapper.mapSegmentAddressToLocation(address)
  }

  public static mapSegmentAddressToLocation(address?: Address | null): Place {
    return {
      placeId: '',
      latitude: address?.geocode?.lat,
      longitude: address?.geocode?.long,
      airportCode: address?.airportCode || undefined,
      countryCode: address?.countryCode,
      name: SegmentAddressMapper.getNameByAddress(address),
      city: address?.city,
      shortName: SegmentAddressMapper.toShortName({
        city: address?.city,
        stateCode: address?.stateCode || '',
        countryCode: address?.countryCode,
        airportCode: address?.airportCode || '',
      }),
    }
  }
}
