import { memo } from 'react'
import type { Moment } from 'moment'
import { Month } from './calendar-components'

const MONTH_JAN = 0

type DayContentProps = {
  date: Moment
}

export const DayContent = memo<DayContentProps>(function ({ date }) {
  return (
    <>
      <span className="CalendarDay__aspect" />
      <span className="CalendarDay__background" />
      <span className="CalendarDay__title">{date.format('D')}</span>
    </>
  )
})

type MonthTextProps = {
  day: Moment
  isMobile?: boolean
}

export const MonthText = memo<MonthTextProps>(function ({ day, isMobile }) {
  const isMonthJan = day.month() === MONTH_JAN
  const mobileDayFormat = isMonthJan ? 'MMM YYYY' : 'MMM'
  const desktopDayFormat = 'MMM YYYY'
  const dayFormat = isMobile ? mobileDayFormat : desktopDayFormat
  const dayTitle = day.format(dayFormat)
  const dayOfWeek = day.clone().startOf('month').day()
  const dayOfWeekClassName = `CalendarMonth__title_day-of-week_${dayOfWeek}`

  return (
    <Month
      className={`CalendarMonth__title ${dayOfWeekClassName} ${isMonthJan ? 'is-month-jan' : ''}`}>
      {dayTitle}
    </Month>
  )
})
