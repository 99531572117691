import { Modal } from '@etta/ui/modal'
import { MobilitySearchPage } from '@etta/screens/mobility-search-page'
import { ScreenType, useScreenType } from '@fiji/modes'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const MobilitySearchFlowModal = ({ isOpen, onClose }: Props) => {
  const type = useScreenType()
  const modalPosition = type !== ScreenType.Mobile ? 'right' : 'center'
  return (
    <Modal position={modalPosition} isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Body>
        <MobilitySearchPage />
      </Modal.Body>
    </Modal>
  )
}
