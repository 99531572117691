import { useCallback, useEffect } from 'react'

export function useEscPressed(cb: Function) {
  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === 'Escape') {
        cb()
      }
    },
    [cb],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])
}
