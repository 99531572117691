import type { ErrorResponse } from '@apollo/client/link/error'
import type { GraphQLError } from 'graphql'
import type Rollbar from 'rollbar'

type Await<T> = T extends Promise<infer U> ? U : T

const rollbar = new Proxy<Rollbar>({} as Rollbar, {
  get(_, prop: keyof Omit<Await<typeof window.__rollbar>, 'options'>) {
    return (...args: unknown[]) => {
      window.__rollbar.then((rollbarInstance) => {
        if (rollbarInstance[prop] && typeof rollbarInstance[prop] === 'function') {
          // @ts-ignore
          rollbarInstance[prop](...args)
        }
      })
    }
  },
})

export { rollbar }

const ignoredNetworkErrors = [
  // Firefox
  'NetworkError when attempting to fetch resource.',
  // Chrome & Edge
  'Failed to fetch',
  // Safari
  'cancelled',
]

function isAuthenticationError(extensions: GraphQLError['extensions'], path: GraphQLError['path']) {
  return extensions?.code === 'UNAUTHENTICATED' && path && path[0] === 'login'
}

export function reportGraphQLErrors(error: ErrorResponse) {
  const { operation, graphQLErrors, networkError } = error

  const { operationName, query, variables } = operation
  const { identifier, password, token, email, ssoToken, answer, ...safeInput } =
    variables?.input || {}
  const context = operation.getContext()
  const lcid = context.headers['x-lcid']
  const errorPayload: Record<string, string> = {
    operationName,
    query: JSON.stringify(query),
    variables: JSON.stringify({ input: safeInput }),
  }

  if (lcid) {
    rollbar.configure({
      payload: {
        lcid,
      },
    })
  }

  if (Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ extensions, path, message, originalError, locations, name }) => {
      if (isAuthenticationError(extensions, path)) {
        return
      }

      const graphQLErrorPayload = {
        ...errorPayload,
        name,
        locations: JSON.stringify(locations),
        extensions: JSON.stringify(extensions),
      }

      rollbar.error(
        `[GraphQLError at ${operationName}] ${message}`,
        originalError ?? graphQLErrorPayload,
        originalError ? graphQLErrorPayload : undefined,
      )
    })
  }

  const shouldBeErrorSend = networkError && !ignoredNetworkErrors.includes(networkError.message)

  if (shouldBeErrorSend) {
    rollbar.error(networkError)
  }
}
