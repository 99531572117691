import { Action, Inject } from '@etta/di'
import { RailLegType } from '@fiji/enums'
import { ROUTES } from '@fiji/routes'
import { RailTripType } from '@fiji/hooks/search-queries'
import { RailSearchFormStore } from '../stores/rail-search-form.store'
import { RailSearchFormQueryService } from '../services/rail-search-form-query.service'

@Action()
export class RailSearchButtonActions {
  constructor(
    @Inject()
    private readonly railSearchFormQueryService: RailSearchFormQueryService,
    @Inject()
    private readonly railSearchFormStore: RailSearchFormStore,
  ) {}

  get isErrorInput() {
    const { destinationPlace, originPlace } = this.railSearchFormStore.railForm

    if (
      destinationPlace &&
      originPlace &&
      (destinationPlace.name === originPlace.name ||
        destinationPlace.placeId === originPlace.placeId)
    ) {
      return true
    }

    return false
  }

  get isDateRangeValid() {
    const { railTripType, originDate, destinationDate } = this.railSearchFormStore.railForm
    if (railTripType === RailTripType.OneWay) {
      return true
    }
    if (originDate && destinationDate && originDate > destinationDate) {
      return false
    }
    return true
  }

  get isSubmitEnabled() {
    const {
      originPlace,
      originDate,
      originTime,
      destinationPlace,
      destinationDate,
      destinationTime,
      railTripType,
    } = this.railSearchFormStore.railForm

    if (this.isErrorInput) {
      return false
    }

    if (!this.isDateRangeValid) {
      return false
    }

    const oneWayFields = [originPlace, originDate, originTime, destinationPlace]

    if (railTripType !== RailTripType.Round) {
      return oneWayFields.every(Boolean)
    }

    return [...oneWayFields, destinationDate, destinationTime].every(Boolean)
  }

  handleSubmit() {
    const { bookingId } = this.railSearchFormStore.railSearchQuery
    const {
      originPlace,
      originDate,
      originTime,
      destinationPlace,
      destinationDate,
      destinationTime,
      railCards,
      railTripType,
    } = this.railSearchFormStore.railForm

    this.railSearchFormQueryService.navigateTo(
      ROUTES.rail.results({
        railLegType: RailLegType.Departure,
      }),
      {
        bookingId,
        originPlace,
        originDate,
        originTime,
        destinationPlace,
        destinationDate,
        destinationTime,
        railCards,
        searchId: undefined,
        filters: {},
      },
    )

    this.railSearchFormStore.setSearchRailEditCache({
      originPlace,
      originDate,
      originTime,
      destinationPlace,
      destinationDate,
      destinationTime,
      railCards,
      railTripType,
    })
  }
}
