import { useCallback, useEffect, useMemo, useState } from 'react'
import { SINGLE_USE_CREDIT_CARD_ID, VIRTUAL_PAY } from '@fiji/constants'
import type { BillingParameter, CreditCard, VirtualPay } from '@fiji/graphql/types'
import { TravelVertical } from '@fiji/graphql/types'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { BillingPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/billing-persist.value-object'
import type { PaymentStructure } from '../types'
import type { ChosenCardsMap } from '../../types'
import { getDefaultCardId } from './find-default-card'

type Args = {
  flightBilling?: BillingParameter
  carRentalBilling?: BillingParameter
  hotelBilling?: BillingParameter
  railBilling?: BillingParameter
  stashedPayment?: PaymentStructure
  virtualPay?: VirtualPay | null
  singleUseCreditCard: Partial<CreditCard> | undefined
}

export function useChosenCards({
  carRentalBilling,
  flightBilling,
  hotelBilling,
  railBilling,
  stashedPayment,
  virtualPay,
  singleUseCreditCard,
}: Args) {
  const stashedAirCard = stashedPayment?.AIR?.creditCard?.id
  const stashedHotelCard = stashedPayment?.HOTEL?.creditCard?.id
  const stashedCarRentalCard = stashedPayment?.CARRENTAL?.creditCard?.id
  const stashedRailRentalCard = stashedPayment?.RAIL?.creditCard?.id

  const { checkoutInfoBillingAction } = useCheckoutInfoContext()

  const defaultCards: ChosenCardsMap = useMemo(() => {
    const hotelDefaultCard = getDefaultCardId({
      billing: hotelBilling,
      stashedCardId: stashedHotelCard,
    })
    const { isVirtualPayRestricted, isVirtualPayEnabled } = virtualPay || {}
    const isDefaultsToVirtualPay =
      isVirtualPayRestricted || (isVirtualPayEnabled && !hotelDefaultCard)
    return {
      [TravelVertical.Air]: getDefaultCardId({
        billing: flightBilling,
        stashedCardId: stashedAirCard,
      }),
      [TravelVertical.Hotel]: isDefaultsToVirtualPay ? VIRTUAL_PAY : hotelDefaultCard,
      [TravelVertical.Carrental]: getDefaultCardId({
        billing: carRentalBilling,
        stashedCardId: stashedCarRentalCard,
      }),
      [TravelVertical.Rail]: getDefaultCardId({
        billing: railBilling,
        stashedCardId: stashedRailRentalCard,
      }),
      [TravelVertical.CarService]: null,
      [TravelVertical.Cruise]: null,
      [TravelVertical.Limo]: null,
      [TravelVertical.Unspecified]: null,
    }
  }, [
    flightBilling,
    hotelBilling,
    carRentalBilling,
    railBilling,
    stashedAirCard,
    stashedHotelCard,
    stashedCarRentalCard,
    virtualPay,
    stashedRailRentalCard,
  ])

  const [chosenCards, setChosenCards] = useState<ChosenCardsMap>(() => defaultCards)

  const onCardChange = ({ travelVertical, id }: { travelVertical: TravelVertical; id: string }) => {
    setChosenCards({ ...chosenCards, [travelVertical]: id })
  }

  const setCreditCard = useCallback(
    ({
      travelVertical,
      key,
    }: {
      key: keyof BillingPersistValueObject
      travelVertical: TravelVertical
    }) => {
      if (chosenCards[travelVertical] === VIRTUAL_PAY) {
        checkoutInfoBillingAction.setSingleUseCreditCard({
          key,
          creditCard: {
            isCardOutOfPolicy: false,
            isCorporateCard: false,
            id: VIRTUAL_PAY,
            data: {
              id: VIRTUAL_PAY,
            },
          },
        })
        return
      }

      if (singleUseCreditCard && chosenCards[travelVertical] === SINGLE_USE_CREDIT_CARD_ID) {
        checkoutInfoBillingAction.setSingleUseCreditCard({
          key,
          creditCard: {
            data: {
              id: SINGLE_USE_CREDIT_CARD_ID,
              cvv: singleUseCreditCard.securityCode,
              expirationDate: singleUseCreditCard.expirationDate,
              label: singleUseCreditCard.label,
              nameOnTheCard: singleUseCreditCard.label,
              number: singleUseCreditCard.cardNumber,
              type: singleUseCreditCard.brandType,
            },
            isCardOutOfPolicy: false,
            isCorporateCard: false,
            id: SINGLE_USE_CREDIT_CARD_ID,
            address: singleUseCreditCard.billingAddress,
          },
        })
        return
      }

      checkoutInfoBillingAction.setCardById(key, chosenCards[travelVertical])
    },
    [checkoutInfoBillingAction, chosenCards, singleUseCreditCard],
  )

  useEffect(() => {
    setCreditCard({ key: 'air', travelVertical: TravelVertical.Air })
    setCreditCard({ key: 'hotel', travelVertical: TravelVertical.Hotel })
    setCreditCard({ key: 'rail', travelVertical: TravelVertical.Rail })
    setCreditCard({
      key: 'carRental',
      travelVertical: TravelVertical.Carrental,
    })
  }, [checkoutInfoBillingAction, chosenCards, setCreditCard, singleUseCreditCard])

  useEffect(() => {
    setChosenCards((prevState) => {
      return {
        ...prevState,
        [TravelVertical.Air]: getDefaultCardId({
          billing: flightBilling,
          stashedCardId: prevState[TravelVertical.Air],
        }),
        [TravelVertical.Hotel]: getDefaultCardId({
          billing: hotelBilling,
          stashedCardId: prevState[TravelVertical.Hotel],
        }),
        [TravelVertical.Carrental]: getDefaultCardId({
          billing: carRentalBilling,
          stashedCardId: prevState[TravelVertical.Carrental],
        }),
        [TravelVertical.Rail]: getDefaultCardId({
          billing: railBilling,
          stashedCardId: prevState[TravelVertical.Rail],
        }),
      }
    })
  }, [
    stashedAirCard,
    stashedHotelCard,
    stashedCarRentalCard,
    stashedRailRentalCard,
    flightBilling,
    hotelBilling,
    carRentalBilling,
    railBilling,
  ])

  return {
    onCardChange,
    chosenCards,
  }
}
