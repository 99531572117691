import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import type { BillingError } from '@fiji/types'
import type { CreditCard } from '@fiji/graphql/types'
import { PaymentCreditCardError } from '@etta/screens/payment-summary/payment-credit-card-error'
import type { CreditCardType } from '@etta/screens/payment-summary/types'
import { AddNewCardButton } from '../add-new-card-button'
import { AddCardTextWarning } from '../add-new-card-button/add-card-text-warning'
import { CardBlock } from './credit-card-block-styled'

type Props = PropsWithChildren<{
  billingError?: BillingError
  creditCard?: CreditCard
  isCardExpiringEarlierThanHotel?: boolean
  onAddNewCreditCard?: () => void
  onEdit?: (type: CreditCardType) => void
  type?: CreditCardType
  isBilledToCompanyAccount?: boolean
}>

const i18nBase = 'PaymentSummary.'

export function CreditCardBlock({
  creditCard,
  children,
  billingError,
  isCardExpiringEarlierThanHotel,
  onEdit,
  type,
  isBilledToCompanyAccount,
}: Props) {
  const { t } = useTranslation()
  const isCreditCardExist = !!creditCard || !!isBilledToCompanyAccount
  const isError = billingError ? Object.values(billingError).some((error) => error) : false
  return (
    <>
      <CardBlock
        isError={isError}
        noCreditCard={!isCreditCardExist}
        data-tracking-id="card-block-button"
        type="button"
        onClick={() => onEdit && type && onEdit(type)}>
        <Swap is={isCreditCardExist} isSlot={children}>
          <AddNewCardButton title={t(i18nBase + 'AddNewCardTitle')} />
        </Swap>
      </CardBlock>
      <AddCardTextWarning hasCreditCard={isCreditCardExist} />
      <PaymentCreditCardError
        creditCard={creditCard}
        billingError={billingError}
        isCardExpiringEarlierThanHotel={isCardExpiringEarlierThanHotel}
      />
    </>
  )
}
