import { Action, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import i18n from '@fiji/i18n'
import { appMode } from '@fiji/modes'
// eslint-disable-next-line import/no-restricted-paths
import { SubmitSearchService } from '@etta/modules/recent-searches/interface/services/submit-search.service'
// eslint-disable-next-line import/no-restricted-paths
import { AddSegmentModalsActions } from '@etta/modules/review-trip/interface/actions'
import { CostAllocationFormStore } from '@etta/modules/cost-allocation/interface/stores/cost-allocation-form.store'
import type { ValidationErrors } from '@etta/modules/hotel-search'
import { HotelSearchFormStore } from '@etta/modules/hotel-search'
import { PreSearchStore } from '@etta/modules/pre-search'
import { HotelSearchResultsStore } from '@etta/modules/hotel-search/interface/stores/hotel-search-results.store'
import { HotelSearchFormQueryService } from '../services/hotel-search-form-query.service'
import { HotelResultsAction } from './hotel-results.action'
import { LoadHotelsAction } from './load-hotels.action'

const i18nBase = 'TripPlanner.BaseSearch.Input'

@Action()
export class HotelSearchButtonActions {
  constructor(
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly submitSearchService: SubmitSearchService,
    @Inject()
    private readonly addSegmentModalsActions: AddSegmentModalsActions,
    @Inject()
    private readonly hotelResultsAction: HotelResultsAction,
    @Inject()
    private readonly loadHotelsAction: LoadHotelsAction,
    @Inject()
    private readonly costAllocationFormStore: CostAllocationFormStore,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
  ) {}

  isInputValid() {
    const { location, checkInDate, checkOutDate } = this.hotelSearchFormStore.hotelForm
    const validationErrors: ValidationErrors = {}

    if (!location) {
      validationErrors.location = i18n.t(`${i18nBase}.EnterLocation`)
    }

    if (!checkInDate) {
      validationErrors.checkinDate = i18n.t(`${i18nBase}.SelectDates`)
    }

    if (!checkOutDate) {
      validationErrors.checkoutDate = i18n.t(`${i18nBase}.SelectDates`)
    }

    this.hotelSearchFormStore.setValidationErrors(validationErrors)

    return !Object.keys(validationErrors).length
  }

  async handleSubmit() {
    const {
      location,
      checkInDate,
      checkOutDate,
      itineraryId,
      bookingId,
    } = this.hotelSearchFormStore.hotelForm

    const distance = this.hotelSearchFormStore.hotelForm.filters?.distance

    const isInputValid = this.isInputValid()
    const isMobile = !appMode.isEttaDesktop
    const isCostAllocationValid = this.costAllocationFormStore.checkCostAllocation()
    const shouldCostValidationBeChecked = bookingId && !isMobile ? false : !isCostAllocationValid

    // The check is located outside isInputValid to force to not add assertions in the recent searches input
    if (
      !isInputValid ||
      shouldCostValidationBeChecked ||
      !location ||
      !checkInDate ||
      !checkOutDate ||
      !distance
    ) {
      return
    }

    if (!this.hotelSearchFormStore.isEditMode) {
      this.submitSearchService.submitNewHotelSearch({
        checkInDate,
        checkOutDate,
        distance,
        location,
      })
    }

    this.hotelSearchFormStore.setInitialLocation({
      lat: location?.latitude!,
      lng: location?.longitude!,
    })

    this.addSegmentModalsActions.closeHotelSearchModal()

    this.hotelResultsAction.dropHotelStore()

    const filters = this.hotelSearchFormStore.hotelForm.filters

    if (!filters) {
      return
    }

    if (this.hotelSearchFormStore.isEditMode) {
      await this.handleEditSubmit()
      return
    }

    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.navigateTo(ROUTES.hotel.results, {
          checkInDate,
          checkOutDate,
          location,
          itineraryId,
          bookingId,
          isSearchByNewArea: false,
          hotelFilters: {
            ...filters,
            distance,
          },
          customFields: this.preSearchStore.customFields,
        }),
      )
    })

    this.hotelSearchFormStore.updateEditCache({
      location,
      checkInDate,
      checkOutDate,
      itineraryId,
      bookingId,
      distance: distance || filters.distance,
    })
  }

  async handleEditSubmit() {
    const { location, checkInDate, checkOutDate, itineraryId } = this.hotelSearchFormStore.hotelForm
    const filters = this.hotelSearchFormStore.hotelForm.filters

    const distance = this.hotelSearchFormStore.hotelForm.filters?.distance

    this.hotelSearchFormStore.editFormModalToggle.handleClose()

    if (!filters || !distance) {
      return
    }

    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.navigateTo(ROUTES.hotel.results, {
          checkInDate,
          checkOutDate,
          location,
          itineraryId,
          isSearchByNewArea: false,
          hotelFilters: {
            ...filters,
            distance,
          },
        }),
      )
    })

    if (!this.hotelSearchResultsStore.isCreateSearchLoading) {
      await this.loadHotelsAction.loadInitialHotels()
    }
  }
}
