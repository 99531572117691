import styled, { css } from 'styled-components'
import { footnoteStrong } from '@fiji/style'

const Day = css`
  .CalendarMonth_table td {
    // rewrite daySize prop for correct drawing different month
    width: 6% !important;
  }
`

const MonthCaption = css`
  .CalendarMonth_caption {
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 14.6%;
  }

  .CalendarMonth_caption strong {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
  }

  .CalendarMonth__title {
    white-space: nowrap;

    ${footnoteStrong}
  }

  .CalendarMonth__title_day-of-week_0 {
    margin-left: 4%;
  }
  .CalendarMonth__title_day-of-week_1 {
    margin-left: 17%;
  }
  .CalendarMonth__title_day-of-week_2 {
    margin-left: 32%;
  }
  .CalendarMonth__title_day-of-week_3 {
    margin-left: 46%;
  }
  .CalendarMonth__title_day-of-week_4 {
    margin-left: 60%;
  }
  .CalendarMonth__title_day-of-week_5 {
    margin-left: 74%;
  }
  .CalendarMonth__title_day-of-week_6 {
    margin-left: 89%;
  }
  .CalendarMonth__title_day-of-week_6.is-month-jan {
    margin-left: 0;
    margin-right: 1%;
    text-align: right;
  }
`

const Picker = css`
  .DayPicker_focusRegion {
    overflow: auto;
  }

  .CalendarMonthGrid__vertical_scrollable {
    overflow: auto;
  }

  .DayPicker_transitionContainer__verticalScrollable {
    padding: 0;
    position: relative;
    overflow: auto;
  }

  .CalendarMonthGrid {
    width: 100% !important;
  }

  .CalendarMonth {
    padding: 0 !important;
  }

  .DayPicker_weekHeader,
  .DayPickerKeyboardShortcuts_buttonReset,
  .DayPickerNavigation {
    display: none;
  }

  .DayPicker__hidden {
    visibility: visible;
  }

  .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }
`

export const CalendarMobileContainer = styled.div`
  ${Picker}
  ${Day}
  ${MonthCaption}
`
