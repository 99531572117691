import { SegmentType } from '@fiji/graphql/types'
import type { AvailablePreferences } from '@fiji/graphql/hooks'
import type { TravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'

type Args = {
  type: SegmentType
  preferenceData: TravelPreferencesValueObject
  availablePreferences?: AvailablePreferences
}

export function getRequestsByType({ preferenceData, availablePreferences, type }: Args) {
  switch (type) {
    case SegmentType.Hotel:
      return {
        specialRequests: preferenceData?.hotel.specialRequest,
        availableRequests: availablePreferences?.hotelSpecialRequests,
      }
    case SegmentType.CarRental:
      return {
        specialRequests: preferenceData?.carRental.specialRequest,
        availableRequests: availablePreferences?.carRentalSpecialRequests,
      }
    case SegmentType.Flight:
      return {
        specialRequests: preferenceData?.flight.specialRequest,
        availableRequests: availablePreferences?.airSpecialRequests,
      }
  }

  return {
    specialRequests: [],
    availableRequests: [],
  }
}
