import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const BuildInfoText = styled.div<{ isHidden: boolean }>`
  opacity: ${(props) => (props.isHidden ? '0' : '1')};
  color: ${(props) => props.theme.colors.bodyText};
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 24px;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NewBrandContainer = styled.div<{ isHidden: boolean }>`
  margin-top: auto;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: ${(props) => (props.isHidden ? '0' : '1')};
`
export const EttaLogo = styled(Icon)`
  width: 100px;
  height: 28px;
  svg {
    width: 100%;
    height: 100%;
    max-height: 28px;
  }
`
