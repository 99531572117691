export default function () {
  return (
    <svg
      width="121"
      height="118"
      viewBox="0 0 121 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1003 110.92C81.1233 110.92 103.84 88.2029 103.84 60.18C103.84 59.6696 103.833 59.1609 103.818 58.6541C102.395 58.9387 100.922 59.088 99.4156 59.088C87.081 59.088 77.0824 49.089 77.0824 36.7547C77.0824 30.2071 79.8999 24.3176 84.3889 20.2325C75.7687 13.4714 64.9053 9.44 53.1003 9.44C25.0774 9.44 2.36035 32.1571 2.36035 60.18C2.36035 88.2029 25.0774 110.92 53.1003 110.92Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.36 36.581C120.36 47.659 111.38 56.64 100.299 56.64C89.2208 56.64 80.2402 47.6588 80.2402 36.581C80.2402 25.5013 89.2206 16.52 100.299 16.52C111.38 16.52 120.36 25.5012 120.36 36.581Z"
        fill="currentColor"
      />
      <path
        d="M108.805 44.0998L108.806 44.101C108.924 44.2172 108.99 44.3794 108.99 44.5782C108.99 44.9853 108.705 45.2698 108.315 45.2698C108.101 45.2698 107.926 45.2 107.788 45.07L103.458 40.7701L103.026 40.3419L102.518 40.6749C101.408 41.4018 100.075 41.8273 98.652 41.8273C94.7728 41.8273 91.6105 38.6845 91.6105 34.8587C91.6105 31.0329 94.7727 27.8902 98.652 27.8902C102.531 27.8902 105.693 31.0329 105.693 34.8587C105.693 36.345 105.22 37.7233 104.418 38.8456L104.047 39.3641L104.499 39.8138L108.805 44.0998ZM92.1892 34.8587C92.1892 38.3887 95.1059 41.268 98.652 41.268C102.198 41.268 105.115 38.3887 105.115 34.8587C105.115 31.3393 102.199 28.4494 98.652 28.4494C95.105 28.4494 92.1892 31.3393 92.1892 34.8587Z"
        stroke="white"
        strokeWidth="1.50037"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7944 51.8234V43.0007H71.9773V51.8234H68.293C68.293 50.0141 66.7905 48.1473 64.9771 48.1473H40.7946C39.0091 48.1473 37.4787 50.0419 37.4787 51.8234H33.7944ZM74.5396 56.97C75.7606 56.97 76.7502 57.9576 76.7502 59.1757V77.9241L72.6974 72.4099H33.0741L29.0215 77.9241V59.1757C29.0215 57.9576 30.0112 56.97 31.2321 56.97H74.5396Z"
        fill="white"
      />
    </svg>
  )
}
