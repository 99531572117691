import { useTranslation } from 'react-i18next'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import type { Maybe, TrainStop, TravelTime } from '@fiji/graphql/types'

import type { TravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { ImageContainer } from '@etta/components/image-container'
import { RailRouteDetails } from '@etta/components/rail-route-details'
import { Block } from '@etta/ui/block'
import { RailChangeDuration } from '@etta/components/rail-change-duration/rail-change-duration'
import { RailChangeTransport } from '@etta/components/rail-change-transport/rail-change-transport'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import {
  OperatorInfoContainer,
  OperatorLogoWrapper,
  OperatorNameWrapper,
  TrainNumberWrapper,
} from './rail-travel-route-details-styled'

type Props = {
  stops?: Maybe<Pick<TrainStop, 'stationName' | 'departingTime'>[]>
  changeDuration: TravelTime | null | undefined
  departure: TravelRailLocation
  arrival: TravelRailLocation
  railLogo?: string | null
  carrierName?: string | null
  trainNumber: string
  travelTime: TravelTime | null | undefined
  isRtp?: boolean
  transportationMode: RailTransportationModeEnum
  isFirstSegment: boolean
  isLastSegment: boolean
  isNextSegmentTrain: boolean
}

const i18nBase = 'RailDetails'

export function RailTravelRouteDetails({
  stops,
  travelTime,
  changeDuration,
  carrierName,
  railLogo,
  trainNumber,
  departure,
  arrival,
  isRtp = false,
  transportationMode,
  isFirstSegment,
  isLastSegment,
  isNextSegmentTrain,
}: Props) {
  const { t } = useTranslation()
  const getDuration = useRailDuration()
  const isTrainSegment = transportationMode === RailTransportationModeEnum.Train

  const carrierNameAriaLabel = t(`${i18nBase}.AriaLabels.CarrierLabel`, {
    name: carrierName,
  })

  const trainSegmentLabel = t(`${i18nBase}.AriaLabels.TrainSegmentLabel`, {
    from: departure.stationName,
    to: arrival.stationName,
  })

  const travelTimeText = getDuration(travelTime)

  return (
    <Block>
      {isTrainSegment ? (
        <Block aria-label={trainSegmentLabel} marginVertical={24}>
          <OperatorInfoContainer>
            <OperatorLogoWrapper>
              <ImageContainer key={railLogo} url={railLogo} errorIconName="railPWA" />
            </OperatorLogoWrapper>
            <OperatorNameWrapper aria-label={carrierNameAriaLabel} variant="subHeadStrong">
              {carrierName}
            </OperatorNameWrapper>
            <TrainNumberWrapper variant="footnoteMedium">{trainNumber}</TrainNumberWrapper>
          </OperatorInfoContainer>
          <RailRouteDetails
            isRtp={isRtp}
            originTime={departure.time}
            originStation={departure.stationName}
            destinationTime={arrival.time}
            destinationStation={arrival.stationName}
            travelTime={travelTimeText}
            stops={stops}
          />
        </Block>
      ) : (
        <RailChangeTransport
          arrival={arrival}
          changeDuration={changeDuration}
          departure={departure}
          transportationMode={transportationMode}
          travelTime={travelTime}
          isRtp={isRtp}
          isFirstSegment={isFirstSegment}
          isLastSegment={isLastSegment}
        />
      )}

      {!isLastSegment && (
        <RailChangeDuration
          arrival={arrival}
          changeDuration={changeDuration}
          isRtp={isRtp}
          isTrainSegment={isTrainSegment}
          isNextSegmentTrain={isNextSegmentTrain}
        />
      )}
    </Block>
  )
}
