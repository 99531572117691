import { useTranslation } from 'react-i18next'
import { SegmentType } from '@etta/core/enums'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import { Icon } from '@etta/ui/icon'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import {
  Wrapper,
  Conditions,
  Flex,
  LogoContainer,
  AgencyWrapper,
  Section,
} from './e-ticket-receipt-modal.styled'
import { FlightSegment } from './flight-segment'
import { BillingAndPayment } from './billing-and-payment'
import type { ContentProps } from './types'

export const ETicketReceiptContent = ({
  trip,
  travelerName,
  printableContentRef,
  selectedETicketIndex,
}: ContentProps) => {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const {
    name,
    rollUpStartTime,
    rollUpEndTime,
    segments,
    recordLocator,
    creationTime,
    airTickets,
    agencyInfo,
  } = trip

  const airTicket = airTickets?.length ? airTickets[selectedETicketIndex] : undefined

  const tripTimeLine =
    rollUpStartTime &&
    rollUpEndTime &&
    [
      dateFormat(new Date(deleteTimezone(rollUpStartTime)), 'ccc, LLL d'),
      dateFormat(new Date(deleteTimezone(rollUpEndTime)), 'ccc LLL d, yyyy'),
    ].join(' - ')

  return (
    <Wrapper ref={printableContentRef}>
      <LogoContainer>
        <Icon name={'deemLogoPWA'} size="medium" color={'mainText'} />
      </LogoContainer>
      <Flex>
        <div>
          <Text variant="headline" color="mainText" isBlock>
            {name}
          </Text>
          <Text variant="footnoteMedium" color="mainText" isBlock>
            {tripTimeLine}
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock>
            {t(i18nBase + 'Traveler')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {travelerName}
            </Text>
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock>
            {t(i18nBase + 'Agent')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {airTicket?.issuingAgent}
            </Text>
          </Text>
        </div>
        <div>
          <Text variant="footnoteMedium" color="bodyText" isBlock align="right">
            {t(i18nBase + 'GDSRecordLocator')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {recordLocator}
            </Text>
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock align="right">
            {t(i18nBase + 'TicketNumber')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {airTicket?.ticketNumber}
            </Text>
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock align="right">
            {t(i18nBase + 'IATANumber')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {airTicket?.iataNumber}
            </Text>
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock align="right">
            {t(i18nBase + 'DateBooked')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {creationTime &&
                dateFormat(new Date(deleteTimezone(creationTime)), 'ccc, LLL d, yyyy')}
            </Text>
          </Text>
        </div>
      </Flex>
      <Separator width={2} color={'borderDark'} margin={'16px 0px'} />
      <Section>
        {segments.map((segment) => {
          if (segment.type === SegmentType.Flight) {
            return segment.segments?.map((leg) => {
              const ticketSegment = airTicket?.ticketSegmentInfo?.find(
                (ticketSegment) => ticketSegment.fareBasis === leg.fare,
              )
              return (
                <FlightSegment key={leg.segmentId} segment={leg} ticketSegment={ticketSegment} />
              )
            })
          }
        })}
      </Section>
      <Separator color={'borderLight'} margin={'24px 0px'} />
      <AgencyWrapper>
        <Text variant="subHeadStrong">{t(i18nBase + 'AgencyInfo')}</Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'AgencyName')}
        </Text>
        <Text variant="footnoteStrong">{agencyInfo?.agencyName}</Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'AgencyMessage')}
        </Text>
        <Text variant="footnoteMedium">{agencyInfo?.agencyMessage}</Text>
      </AgencyWrapper>
      <Separator color={'borderLight'} margin={'24px 0px'} />
      <BillingAndPayment airTicket={airTicket} />
      <Separator color={'borderLight'} margin={'12px 0px 24px 0px'} />
      <Section>
        <Text variant="footnoteStrong">{t(i18nBase + 'Identification')}</Text>
        <Conditions>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'Conditions')}
          </Text>
        </Conditions>
      </Section>
    </Wrapper>
  )
}
