import styled, { css } from 'styled-components'
import { buttonSmall, KeyboardNavigationStyle } from '@fiji/style'
import { ScreenType } from '@fiji/modes'

export const Text = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.colors.mainText};
`

export const Container = styled.button<{ screenType: ScreenType }>`
  width: 100%;
  padding: 12px;
  margin: 0;
  border: none;
  appearance: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  min-height: 64px;
  cursor: pointer;
  outline: none;
  position: relative;
  gap: 4px;

  ${KeyboardNavigationStyle};

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:disabled {
    cursor: default;

    > * {
      opacity: 0.4;
    }
  }

  ${(p) => {
    switch (p.screenType) {
      case ScreenType.Tablet:
        return css`
          flex-direction: column;
        `
    }
  }}
`
