import styled, { css } from 'styled-components'
import { Radio } from '@etta/ui/radio'
import { footnoteMedium, captionMedium, headline } from '@fiji/style'
import { Text } from '@etta/ui/text'
import type { TextProps } from '@etta/ui/text'

export const TitleHelperText = styled.span`
  display: block;
  margin-top: 14px;
  color: ${(p) => p.theme.colors.bodyText};
  line-height: 19px;
  margin-top: 4px;
  ${footnoteMedium}
`

const titleStyles = css`
  margin: 40px 0 0;

  &:first-child {
    margin-top: 32px;
  }
`

export const BlockTitle = styled.h6`
  color: ${(p) => p.theme.colors.mainText};
  ${titleStyles};
  ${headline};
`

export const Header = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  color: 'mainText',
  variant: 'subHeadStrong',
}))`
  ${titleStyles}
`

export const TextFieldGroup = styled.div<{ hasMarginBottom?: boolean }>`
  width: 100%;
  & > * + * {
    margin-top: 10px;
  }

  & > *:first-child {
    margin-top: 12px;
  }

  ${({ hasMarginBottom }) => hasMarginBottom && 'margin-bottom: 24px;'}
`

export const RadioContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const StyledRadio = styled(Radio)`
  padding: 18px 6px;
`

export const HelperContainer = styled.div`
  color: ${(props) => props.theme.colors.error};
  display: flex;
  align-items: center;
  ${captionMedium};
`

export const CheckboxContainer = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
`

export const Wrapper = styled.div``
