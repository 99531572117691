export default function FilterHorizontalActive() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 13C41 15.2091 39.2091 17 37 17C34.7909 17 33 15.2091 33 13C33 10.7909 34.7909 9 37 9C39.2091 9 41 10.7909 41 13Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 22C18.171 22 20.8496 19.8915 21.7101 17H32.5278C31.5777 15.9385 31 14.5367 31 13H21.7101C20.8496 10.1085 18.171 8 15 8C11.829 8 9.15043 10.1085 8.28988 13H4L2 15L4 17H8.28988C9.15043 19.8915 11.829 22 15 22ZM15 18.5C16.933 18.5 18.5 16.933 18.5 15C18.5 13.067 16.933 11.5 15 11.5C13.067 11.5 11.5 13.067 11.5 15C11.5 16.933 13.067 18.5 15 18.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 38C25.829 38 23.1504 35.8915 22.2899 33H4L2 31L4 29H22.2899C23.1504 26.1085 25.829 24 29 24C32.171 24 34.8496 26.1085 35.7101 29H40L42 31L40 33H35.7101C34.8496 35.8915 32.171 38 29 38ZM29 34.5C27.067 34.5 25.5 32.933 25.5 31C25.5 29.067 27.067 27.5 29 27.5C30.933 27.5 32.5 29.067 32.5 31C32.5 32.933 30.933 34.5 29 34.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
