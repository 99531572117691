import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { getDateRangeTitle } from '@fiji/utils/dates/get-date-range-title/get-date-range-title'
import { TripStatus } from '@fiji/graphql/types'
import { useStatusDetails } from './use-status-details'

type Params = {
  startTime: string
  endTime: string
  tripName: string
  tripStatus: TripStatus
  isOpenReturn?: boolean
}

const i18nBase = 'PostBooking'
const i18nBaseAcc = 'Accessibility'
const EMPTY_STRING = ''

export function useDetailsHeader({
  startTime,
  endTime,
  tripName,
  tripStatus,
  isOpenReturn,
}: Params) {
  const { postBookingTripPreviewStore } = usePostBookingContext()
  const { tripPreview } = postBookingTripPreviewStore
  const { t } = useTranslation()

  const titleDate = useMemo(() => {
    const start = startTime || deleteTimezone(tripPreview.startTime)
    const end = endTime || deleteTimezone(tripPreview.endTime)

    if (!start || !end) {
      return EMPTY_STRING
    }

    let tripDate = getDateRangeTitle({
      startDate: new Date(start),
      endDate: new Date(end),
    })

    if (isOpenReturn) {
      tripDate += t(`${i18nBase}.TripDetails.OpenReturn.DateText`).toUpperCase()
    }
    return tripDate
  }, [startTime, tripPreview.startTime, tripPreview.endTime, endTime, isOpenReturn, t])

  const tripTitle = useMemo(() => {
    return tripName || tripPreview.title || EMPTY_STRING
  }, [tripName, tripPreview.title])
  const ariaLabel = `${titleDate}. ${tripTitle}`
  const shareTitle = t('ActionButtons.Share')
  const editIconAriaLabel = t(`${i18nBase}.TripDetails.EditTripName`)
  const { statusTitle, statusColor } = useStatusDetails({
    tripStatus,
  })
  const statusAriaLabel = t(`${i18nBase}.TripDetails.StatusAriaLabel`, {
    date: titleDate,
    status: statusTitle,
  })
  const shareButtonAriaLabel = t(`${i18nBaseAcc}.ButtonTapToShare`, { label: shareTitle })

  const isOnHold = tripStatus === TripStatus.OnHold

  return {
    titleDate,
    tripTitle,
    statusTitle,
    statusColor,
    ariaLabel,
    shareTitle,
    editIconAriaLabel,
    isOnHold,
    statusAriaLabel,
    shareButtonAriaLabel,
  }
}
