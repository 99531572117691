import { useEffect, useState, useCallback } from 'react'
import { getHotelLogoURL } from '@fiji/utils/hotel-logo'
import type { Photo } from '@fiji/graphql/types'

type Props = {
  photos: Photo[]
  chainCode?: string | null
  isHotelLoading?: boolean
  logoPhotoUrl?: string | null
}

export function useHotelImage({ photos, chainCode, isHotelLoading, logoPhotoUrl }: Props) {
  const [headerImage, setHeaderImage] = useState('')
  const [hasImage, setHasImage] = useState(true)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState(true)

  useEffect(() => {
    if (photos.length) {
      setHeaderImage(photos[0].url)
      setHasImage(true)
      return
    }
    if (logoPhotoUrl) {
      setHeaderImage(logoPhotoUrl)
      setHasImage(true)
    }
  }, [photos, logoPhotoUrl])

  const handleImageError = () => {
    const hotelLogoURL = chainCode ? getHotelLogoURL(chainCode) : null
    if (hotelLogoURL) {
      setHeaderImage(hotelLogoURL)
    } else {
      setHasImage(false)
    }
  }

  const onLoadStart = useCallback(() => {
    setIsImageLoaded(false)
    setIsImageLoading(true)
  }, [])

  const onLoad = useCallback(() => {
    setIsImageLoaded(true)
    setIsImageLoading(false)
  }, [])

  useEffect(() => {
    if (!isHotelLoading && !isImageLoading) {
      onLoad()
    }
  }, [onLoad, isHotelLoading, isImageLoading])

  return { headerImage, hasImage, isImageLoaded, handleImageError, onLoad, onLoadStart }
}
