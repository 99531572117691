import { Action, Inject } from '@etta/di'
import { PreSearchStore } from '@etta/modules/pre-search/interface/stores/pre-search.store'
import { BookTripAgainStore } from '../stores/book-trip-again/book-trip-again.store'
import { BookTripAgainService } from '../services/book-trip-again.service'

@Action()
export class BookTripAgainActions {
  constructor(
    @Inject() private bookTripAgainStore: BookTripAgainStore,
    @Inject() private preSearchStore: PreSearchStore,
    @Inject() private bookTripAgainService: BookTripAgainService,
  ) {}

  async handleContinue(callback: (id: number) => void) {
    const hasEndDate = this.bookTripAgainStore.hasEndDate
    if (hasEndDate) {
      this.bookTripAgainStore.endDate
        ? this.bookTripAgainStore.resetEndDateErrorMessage()
        : this.bookTripAgainStore.setEndDateErrorMessage('Select date')
    }

    this.bookTripAgainStore.startDate
      ? this.bookTripAgainStore.resetStartDateErrorMessage()
      : this.bookTripAgainStore.setStartDateErrorMessage('Select date')

    if (!this.bookTripAgainStore.startDate && !this.bookTripAgainStore.endDate && hasEndDate) {
      return
    }

    try {
      // swtich delegates, the callback handles the case when it's current user
      if (this.bookTripAgainStore.delegatedId !== undefined) {
        this.bookTripAgainStore.setIsSwitchDelegateInProgress(true)
        await callback(this.bookTripAgainStore.delegatedId)
      }
    } finally {
      this.bookTripAgainStore.setIsSwitchDelegateInProgress(false)

      if (this.preSearchStore.isShowingPreSearchModal) {
        this.preSearchStore.handleOpenPreSearchCustomFields()
        return
      }

      await this.bookTripAgainService.bookTripAgain()
    }
  }

  onCloseViewState() {
    this.bookTripAgainStore.setViewState('hidden')
  }
}
