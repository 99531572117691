import styled from 'styled-components'

type LineType = 'solid' | 'dotted' | 'dashed'

export const HorizontalDelimiter = styled.div<{ lineType?: LineType }>`
  width: 100%;
  height: 1px;
  border-bottom: 1px ${({ lineType = 'solid' }) => lineType}
    ${(props) => props.theme.colors.borderLight};
`
