import { useTranslation } from 'react-i18next'
import type { GetTripQuery } from '@fiji/graphql/hooks'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import type { OverlappingTrip, TripDetailsSegment } from '@fiji/graphql/types'
import { CityBackground } from '@etta/components/city-background'
import { DuplicateSegment } from './duplicate-segment'
import { CustomHeader, CloseButtonContainer, DuplicateTripBody } from './duplicate-list-styled'

type Props = {
  trip: GetTripQuery['trip']
  handleClose: () => void
  overlappingTrip: OverlappingTrip
  formattedDate: string
}

export function DuplicateTripModal({ trip, handleClose, formattedDate, overlappingTrip }: Props) {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'

  return (
    <>
      <Modal.Header isMobile withBorder>
        <CustomHeader>
          <CityBackground cityImageUrl={trip.cityImageUrl} isFullWidth={false} />
          <CloseButtonContainer onClick={handleClose}>
            <Icon name="closeFilledPWA" size={33} />
          </CloseButtonContainer>
        </CustomHeader>
      </Modal.Header>
      <Modal.Body>
        <DuplicateTripBody>
          <Text variant="captionStrongCaps" color="bodyText">
            {formattedDate}
          </Text>
          <Block paddingBottom={4}>
            <Text variant="title2" color="mainText">
              {overlappingTrip.name}
            </Text>
          </Block>
          <Text variant="captionStrongCaps" color="bodyText">
            {t(`${i18Base}BookingRef`, { id: overlappingTrip.id })}
          </Text>

          <Block marginTop={24}>
            {trip?.segments?.map((segment) => {
              return (
                <DuplicateSegment key={segment.position} segment={segment as TripDetailsSegment} />
              )
            })}
          </Block>
        </DuplicateTripBody>
      </Modal.Body>
    </>
  )
}
