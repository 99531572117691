import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { SubmitRecentSearchActions } from './actions/submit-search.action'
import { GetRecentSearchesActions } from './actions/get-recent-searches.action'
import { RecentSearchDataAction } from './actions/recent-search-data.action'
import { RecentSearchesStore } from './stores/recent-searches.store'

export const { useModuleContext: useRecentSearchesContext } = hookContextFactory({
  submitRecentSearchActions: SubmitRecentSearchActions,
  getRecentSearchesActions: GetRecentSearchesActions,
  recentSearchesStore: RecentSearchesStore,
  recentSearchDataAction: RecentSearchDataAction,
})
