import styled from 'styled-components'
import { footnoteMedium, footnoteStrong, headline } from '@fiji/style'

type LineType = 'solid' | 'dotted' | 'dashed'

export const Container = styled.div`
  padding: 16px 16px 0 16px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`
export const IconContainer = styled.div`
  display: flex;
  background-color: rgba(93, 41, 95, 0.06);
  border-radius: 8px;
  min-width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
`

export const HeaderTextContainer = styled.div`
  padding-left: 14px;
`

export const Circle = styled.div`
  background: ${(props) => props.theme.colors.background};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const SeparatorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -26px;
  padding: 16px 0;
`

export const HorizontalDelimiter = styled.div<{ lineType?: LineType }>`
  width: 100%;
  height: 1px;
  border-bottom: 1px ${({ lineType = 'dashed' }) => lineType}
    ${(props) => props.theme.colors.borderLight};
`

export const HeaderTitle = styled.p`
  ${headline}
  padding: 0;
  margin: 0;
`

export const HeaderDescription = styled.p`
  padding: 0;
  margin: 0;
  ${footnoteStrong};
  color: ${(props) => props.theme.colors.bodyText};
`

export const TransferPartContainer = styled.div`
  padding-top: 15px;
`
export const TransferType = styled.p`
  ${footnoteStrong}
  padding: 0;
  margin: 0;
  padding-bottom: 3px;
`

export const TransferDetails = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  padding: 0;
  margin: 0;
`

export const FareInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  ${footnoteStrong}
  padding-bottom: 15px;
`
