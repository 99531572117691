import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { SeatmapCabinClass, CreditCard, TripCost } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { FlightReservationRow } from '../types'
import { getCardLabel } from '../payment-card/get-card-label'

type Args = {
  tripCost: TripCost
  segments: TripSegmentValueObject[]
  creditCard?: CreditCard
}

export function useFlightsDetails({ tripCost, segments, creditCard }: Args) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { mainCost, secondCost } = formatRate(tripCost.total)
  const cardDetails = creditCard && getCardLabel(creditCard)

  return {
    cardDetails,
    mainCost,
    secondCost,
    rate: tripCost.total,
    creditCard,
    rows: segments.reduce<FlightReservationRow[]>((acc, segment) => {
      if (!segmentTypeCheckActions.isFlightSegment(segment)) {
        return acc
      }

      acc.push({
        legId: segment.legId,
        directions: segment.segments.map(
          ({ segmentId, serviceClass, departureDate, arrival, departure, name }) => {
            return {
              segmentId: segmentId || '',
              origin: departure.airportCode || '',
              destination: arrival.airportCode || '',
              cabinClass: serviceClass as SeatmapCabinClass,
              departDate: deleteTimezone(departureDate),
              city: arrival.address?.city || '',
              airlineName: name,
            }
          },
        ),
        isRoundTrip: !!segment.isRoundTripLeg,
      })
      return acc
    }, []),
  }
}
