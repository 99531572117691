import { useTranslation } from 'react-i18next'
import { FlightSafetyCheckItem } from '@etta/components/flight-safety-check-item'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import type { LayoutProps } from '../../types'

export function FlightSafetyCheckModalDesktop({ flightSafetyCheckDetails, onClose }: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'

  return (
    <>
      <InfoModal.Header title={t(`${i18nBase}.Title`)} onClose={onClose} />
      <InfoModal.Content>
        {flightSafetyCheckDetails &&
          flightSafetyCheckDetails.map((detail, index) => (
            <FlightSafetyCheckItem key={index} detail={detail} />
          ))}
      </InfoModal.Content>
    </>
  )
}
