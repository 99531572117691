import type { TripStateStatus } from '../types'
import { useStatusTitle } from './use-status-title'
import { getViewState } from './get-view-state'

export const useTripStatus = (tripStateStatus: TripStateStatus) => {
  const title = useStatusTitle(tripStateStatus)
  const viewState = getViewState(tripStateStatus)

  return {
    title,
    viewState,
  }
}
