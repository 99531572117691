import { Inject, Action } from '@etta/di'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'

@Action()
export class PostBookingAddSegmentAction {
  constructor(
    @Inject()
    private readonly postBookingTripStore: PostBookingTripStore,
  ) {}

  public handleAddFlight() {
    this.postBookingTripStore.airSearchFormModal.handleOpen()
  }

  public handleAddHotel() {
    this.postBookingTripStore.hotelSearchFormModal.handleOpen()
  }

  public handleAddCar() {
    this.postBookingTripStore.carSearchFormModal.handleOpen()
  }
}
