import { Result } from 'fnscript'
import { ApolloError } from '@apollo/client'
import { Inject, Adapter } from '@etta/di'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass } from '@etta/core/enums'
import type { PromisedResult } from '@etta/core/type-utils'
import { getRestrictedErrorData } from '@fiji/utils/get-restricted-error-message'
import type { CreateHotelSearchInput } from '../core/value-objects/create-hotel-search.input'
import type { HotelSearchErrorsInstances } from '../core/errors/hotel-search.errors'
import { HotelSearchErrors } from '../core/errors/hotel-search.errors'
import type { GetHotelsInput } from '../core/value-objects/get-hotels.input'
import type { HotelsResultsValueObject } from '../core/value-objects/hotels-results.value-object'
import type { GetHotelByIdInput } from '../core/value-objects/get-hotel-by-id.input'
import type { HotelEntity } from '../core/entities/hotel.entity'
import { HotelsDataProvider } from './hotels.data-provider/get-hotels.data-provider'

@Adapter()
export class HotelSearchAdapter {
  constructor(
    @Inject()
    private hotelDataProvider: HotelsDataProvider,
  ) {}

  async createHotelSearch(
    input: CreateHotelSearchInput,
  ): PromisedResult<{ searchId: string }, HotelSearchErrorsInstances> {
    try {
      const { data } = await this.hotelDataProvider.createHotelSearch(input)

      if (!data?.createSearch.hotelResultId) {
        return Result.Err(new HotelSearchErrors.CreateSearchError('No search id returned'))
      }

      return Result.Ok({ searchId: data.createSearch.hotelResultId })
    } catch (err: unknown) {
      return Result.Err(new HotelSearchErrors.UnexpectedErrorCreateSearch(err))
    }
  }

  async getHotels(
    input: GetHotelsInput,
  ): PromisedResult<HotelsResultsValueObject, HotelSearchErrorsInstances> {
    try {
      const deviceClass =
        screenMatcher.getScreenType() === ScreenType.Mobile
          ? DeviceClass.Mobile
          : DeviceClass.Desktop
      const { data, error } = await this.hotelDataProvider.getHotels({
        ...input,
        deviceClass,
      })

      if (error) {
        return Result.Err(new HotelSearchErrors.GetHotelsError(error))
      }

      if (!data?.hotels) {
        return Result.Err(new HotelSearchErrors.GetHotelsError('No hotels returned'))
      }

      return Result.Ok(data.hotels)
    } catch (e: unknown) {
      if (e instanceof ApolloError && e.graphQLErrors) {
        const { isRestrictedCountryError, restrictedErrorMessage } = getRestrictedErrorData(
          e.graphQLErrors || [],
          'restrictionMessage',
        )

        if (isRestrictedCountryError) {
          return Result.Err(
            new HotelSearchErrors.RestrictedCountryError({ message: restrictedErrorMessage || '' }),
          )
        }
      }
      return Result.Err(new HotelSearchErrors.UnexpectedErrorGetHotels(e))
    }
  }

  async getHotelById(
    input: GetHotelByIdInput,
  ): PromisedResult<HotelEntity, HotelSearchErrorsInstances> {
    try {
      const { data, error } = await this.hotelDataProvider.getHotelById(input)

      if (error) {
        return Result.Err(new HotelSearchErrors.GetHotelByIdError(error))
      }

      if (!data?.hotel) {
        return Result.Err(new HotelSearchErrors.GetHotelByIdError('No hotels returned'))
      }

      return Result.Ok(data.hotel)
    } catch (err: unknown) {
      return Result.Err(new HotelSearchErrors.UnexpectedErrorGetHotels(err))
    }
  }
}
