import { useMemo } from 'react'
import type { OOPGenericData } from '@fiji/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import type { Policy } from '@fiji/graphql/types'
import { SegmentType, OutOfPolicyEnum } from '@fiji/graphql/types'

export function useExtractPolicyFromSegments(
  segments: TripSegmentValueObject[],
  tripLevel?: Policy[] | null,
): OOPGenericData[] {
  return useMemo(() => {
    const oopSegments = segments
      ? segments.flatMap((segment) => {
          if (!segment.policy.isInPolicy) {
            return {
              type: segment.type,
              oopReasons: segment.policy.outOfPolicyReasons || [],
              oopData: segment.policy.outOfPolicyData || [],
            }
          }
          return []
        })
      : []

    const hotelOopTrip = tripLevel?.find((policy) =>
      policy.outOfPolicyReasons?.includes(OutOfPolicyEnum.HotelRequiredForOvernightTrip),
    )
      ? [
          {
            type: SegmentType.Hotel,
            oopReasons: [OutOfPolicyEnum.HotelRequiredForOvernightTrip],
          },
        ]
      : []

    const carRentalOopTrip = tripLevel?.find((policy) =>
      policy.outOfPolicyReasons?.includes(OutOfPolicyEnum.CarRentalRequiredViolation),
    )
      ? [
          {
            type: SegmentType.CarRental,
            oopReasons: [OutOfPolicyEnum.CarRentalRequiredViolation],
          },
        ]
      : []

    return [...oopSegments, ...hotelOopTrip, ...carRentalOopTrip]
  }, [segments, tripLevel])
}
