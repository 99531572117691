import styled from 'styled-components'
import { MediaLayout } from '@etta/ui/media-layout'
import { Modal } from '@etta/ui/modal'
import { Radio as MobileRadio } from '@etta/ui/radio'

export const Container = styled.div``

export const Radio = styled(MobileRadio)`
  margin: 22px 0;
`

const ModalHeaderMobile = styled(Modal.Title).attrs((p) => ({
  ...p,
  withBorder: true,
}))``

const ModalHeaderDesktop = styled(Modal.Title).attrs((p) => ({
  ...p,
  withBorder: true,
  leftSeparatorWidth: 24,
  minHeight: 64,
}))``

export const ModalHeader = (props: any) => {
  return (
    <MediaLayout
      mobileSlot={<ModalHeaderMobile {...props} />}
      desktopSlot={<ModalHeaderDesktop {...props} />}
    />
  )
}

export const ResetFilterBlock = styled.div`
  padding: 24px 20px 22px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-left: 0;
  border-right: 0;
`
