import styled from 'styled-components'
import { captionStrongCaps, subHeadMedium, footnoteMedium } from '@fiji/style/typography'

export const Container = styled.div`
  padding: 16px;
  margin-top: -25px;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const HeaderText = styled.div`
  ${captionStrongCaps};
  color: ${(props) => props.theme.colors.bodyText};
  padding-left: 11px;
`

export const List = styled.div``

export const ListItem = styled.div`
  display: flex;
  position: relative;
  padding: 16px 0 0 16px;
`

export const ArrowIconWrapper = styled.span`
  padding: 0 6px;
  margin-top: -2px;
`

export const Location = styled.div`
  ${subHeadMedium};
  color: ${(props) => props.theme.colors.mainText};
`

export const LeftBlock = styled.div`
  padding-right: 8px;
`

export const RightBlock = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  height: calc(100% - 2px);
  margin-bottom: 2px;
`

export const TopLine = styled.div`
  display: flex;
`

export const TopLineWithoutDates = styled.div`
  display: flex;
  padding-bottom: 10px;
`

export const BottomLine = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  padding-bottom: 16px;
`
