import { observer } from 'mobx-react-lite'
import type { FC, Key } from 'react'
import { useEttaMapContext } from '../interface/use-etta-map.context'
import { useAppleMapContext } from './apple-map/apple-map.context'

export const EttaMapDialog: FC<{ markerKey: Key }> = observer(function EttaMapDialog({
  children,
  markerKey,
}) {
  const { ettaMapStore } = useEttaMapContext()
  const { setCallout } = useAppleMapContext()
  const isAppleMap = ettaMapStore.isAppleMap

  if (isAppleMap) {
    setCallout(markerKey, <>{children}</>)
    return null
  }

  return <>{children}</>
})
