import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetAutocompletePlacesQuery,
  GetAutocompletePlacesQueryVariables,
} from '@fiji/graphql/hooks'
import { GetAutocompletePlacesDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class GetAutocompleteDataProvider extends GraphqlDataProvider {
  private abortController = new AbortController()

  getPlaces(input: GetAutocompletePlacesQueryVariables['input']) {
    this.abortPreviousRequest()

    return this.client.query<GetAutocompletePlacesQuery, GetAutocompletePlacesQueryVariables>({
      query: GetAutocompletePlacesDocument,
      variables: { input },
      context: {
        fetchOptions: {
          controller: this.abortController,
        },
      },
    })
  }

  private abortPreviousRequest() {
    this.abortController.abort()
    this.abortController = new AbortController()
  }
}
