import { TextField } from '@etta/ui/text-field'
import { SwipingDatePicker } from '@etta/ui/swiping-time-date-picker'
import type { LayoutProps } from '../types'

export function PassportFormDatePickerFieldMobile({
  value,
  label,
  pickerLabel,
  onFieldClick,
  helperText,
  isDisabled,
  isPickerOpen,
  date,
  onSelect,
  onPickerClose,
  variant,
}: LayoutProps) {
  return (
    <>
      <TextField
        value={value}
        label={label}
        onClick={onFieldClick}
        helperType="error"
        helperText={helperText}
        isDisabled={isDisabled}
      />
      <SwipingDatePicker
        date={date}
        label={pickerLabel}
        isVisible={isPickerOpen}
        onClose={onPickerClose}
        onApply={onSelect}
        areFutureDatesSelectable={variant === 'expiration-date'}
        defaultDate={new Date()}
        variant={variant === 'expiration-date' ? 'future-date' : undefined}
      />
    </>
  )
}
