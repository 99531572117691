import { Text } from '@etta/ui/text'
import { Checkbox } from '@etta/ui/checkbox'
import { Swap } from '@etta/ui/swap'
import { Radio } from '@etta/ui/radio'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { ListTypes, OptionViewTypes } from '../types'
import type { ValueType, OptionType } from '../types'
import {
  ListBodyItem,
  OptionTypeTextWrapper,
  OptionWithCheckboxIconWrapper,
  TickIcon,
} from './list-styled'

type Props<T = ValueType> = {
  type: ListTypes
  options: OptionType<T>[]
  chosenValues: T[]
  handleClickItem: (value: T) => () => void
}

export function ListOption<T extends ValueType = ValueType>({
  options,
  type,
  chosenValues,
  handleClickItem,
}: Props<T>) {
  const { handleKeyDown } = useA11yKeydown((value: T) => handleClickItem(value)())

  return (
    <>
      {options.map(({ label, value, viewType = OptionViewTypes.Option }, idx) => {
        if (viewType === OptionViewTypes.Title) {
          return (
            <OptionTypeTextWrapper key={idx} tabIndex={0}>
              <Text variant="subHeadMedium">{label}</Text>
            </OptionTypeTextWrapper>
          )
        }

        if (viewType === OptionViewTypes.OptionWithCheckboxIcon) {
          return (
            <OptionWithCheckboxIconWrapper
              tabIndex={0}
              key={idx}
              onClick={handleClickItem(value)}
              onKeyPress={(e) => handleKeyDown(e, value)}>
              <Text variant="subHeadMedium">{label}</Text>
              {chosenValues.includes(value) && <TickIcon name="tickPWA" color="primary3" />}
            </OptionWithCheckboxIconWrapper>
          )
        }

        return (
          <ListBodyItem key={idx}>
            <Swap
              is={type === ListTypes.Select}
              isSlot={
                <Checkbox
                  label={label}
                  value={String(value)}
                  checked={chosenValues.includes(value)}
                  onChange={handleClickItem(value)}
                />
              }>
              <Radio
                tabIndex={0}
                isChecked={chosenValues.includes(value)}
                onChange={handleClickItem(value)}>
                <Text variant="subHeadMedium">{label}</Text>
              </Radio>
            </Swap>
          </ListBodyItem>
        )
      })}
    </>
  )
}
