import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const Title = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'title3',
  color: 'mainText',
}))``

export const Description = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'captionMedium',
  color: 'bodyText',
}))``

export const AwayText = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'captionStrong',
  color: 'mainText',
}))``
