import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightDetailsModalMobile } from './mobile'
import { FlightDetailsModalDesktop } from './desktop'

export function FlightDetailsModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightDetailsModalMobile {...props} />}
      desktopSlot={<FlightDetailsModalDesktop {...props} />}
    />
  )
}
