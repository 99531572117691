import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { Button } from '@etta/ui/button'
import { FullWidthButtonWrapper, Separator, Footer } from '../cancel-trip-modal-styled'

type Props = {
  statement: string
  isChecked: boolean
}

export const FullScreenFooter = observer(function FullScreenFooter({
  statement,
  isChecked,
}: Props) {
  const { postBookingCancellationActions } = usePostBookingContext()
  return (
    <Footer>
      <Separator />
      <FullWidthButtonWrapper>
        <Button
          data-tracking-id="cancel-trip-button"
          variant={'solid'}
          disabled={!isChecked}
          onClick={postBookingCancellationActions.onSubmitCancel}
          fullWidth>
          {statement}
        </Button>
      </FullWidthButtonWrapper>
    </Footer>
  )
})
