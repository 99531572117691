import { SegmentType } from '@etta/core/enums'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type {
  SegmentPostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
  CarRentalPostBookingValueObject,
  TrainPostBookingValueObject,
  CarServicePostBookingValueObject,
  HotelGroupedSegmentValueObject,
  TrainGroupedSegmentValueObject,
  RideHailGroupedSegmentValueObject,
  CarRentalGroupedSegmentValueObject,
  CarServiceGroupedSegmentValueObject,
  GroupedSegmentValueObject,
  RideHailPostBookingValueObject,
  FlightGroupedSegmentValueObject,
  GroupedSegmentsGroupValueObject,
} from '../../../core/value-objects'

export function toGroupSegmentsGroup(
  segments: SegmentPostBookingValueObject[],
  // NOTE: if `withSplit=false` we will not split segments (car rental and hotels) into several segments,
  // this is necessary for the implementation of the LIMA-1972 (new desing for trip details page)
  withSplit = true,
): GroupedSegmentsGroupValueObject[] {
  const groupedSegments = toGroupSegments(segments, withSplit)
  const segmentMap = groupedSegments.reduce<Record<string, GroupedSegmentValueObject[]>>(
    (acc, segment) => {
      const date = getDateWithTimezone(segment.date)
      const key = dateFormat(new Date(date), 'yyyyMMdd')
      const segments = acc[key] || []
      return { ...acc, [key]: [...segments, segment] }
    },
    {},
  )

  return Object.entries(segmentMap)
    .sort(([a], [b]) => Number(a) - Number(b))
    .reduce<GroupedSegmentsGroupValueObject[]>((acc, [_, segments]) => {
      const sortedSegments = segments.slice().sort((a, b) => sortSegment(a, b))
      acc.push({
        day: sortedSegments[0].date,
        segments: sortedSegments,
      })
      return acc
    }, [])
}

function toGroupSegments(
  segments: SegmentPostBookingValueObject[],
  withSplit = true,
): GroupedSegmentValueObject[] {
  return segments.reduce<GroupedSegmentValueObject[]>((acc, segment) => {
    switch (segment.type) {
      case SegmentType.Flight: {
        const flightSegments = groupFlightSegment(segment)
        acc.push(...flightSegments)
        return acc
      }
      case SegmentType.Train: {
        const trainSegments = groupTrainSegment(segment)
        acc.push(...trainSegments)
        return acc
      }
      case SegmentType.RideHail: {
        const rideHailSegments = groupRideHailSegment(segment)
        acc.push(...rideHailSegments)
        return acc
      }
      case SegmentType.CarRental: {
        const carRentalSegments = groupCarRentalSegment(segment, withSplit)
        acc.push(...carRentalSegments)
        return acc
      }
      case SegmentType.CarService: {
        const carServiceSegments = groupCarServiceSegment(segment)
        acc.push(...carServiceSegments)
        return acc
      }
      case SegmentType.Hotel: {
        const hotelSegments = groupHotelSegment(segment, withSplit)
        acc.push(...hotelSegments)
        return acc
      }
    }
  }, [])
}

function sortSegment(a: GroupedSegmentValueObject, b: GroupedSegmentValueObject): number {
  // NOTE: We sort all segments based off date, except the hotel
  // IF THE HOTEL IS CHECK IN - We place it at the end of the list of segments
  // IF THE HOTEL IS CHECK OUT - We place it at the start of the list of segments
  if (a.type === SegmentType.Hotel) {
    return a.isCheckIn ? 1 : -1
  }
  if (b.type === SegmentType.Hotel) {
    return b.isCheckIn ? -1 : 1
  }

  const aDate = a.date ? new Date(a.date).getTime() : undefined
  const bDate = b.date ? new Date(b.date).getTime() : undefined

  if (aDate && bDate) {
    return sortByDate(aDate, bDate)
  }

  return -1
}

function sortByDate(d1: number, d2: number): number {
  if (d1 === d2) {
    return 0
  }
  return d1 > d2 ? 1 : -1
}

function groupFlightSegment(
  flight: FlightPostBookingValueObject,
): FlightGroupedSegmentValueObject[] {
  const flightSegments: FlightGroupedSegmentValueObject[] = []
  if (flight.departureDateTime && flight.segments) {
    const date = deleteTimezone(flight.departureDateTime)
    const lastSegment = flight.segments[flight.segments.length - 1]
    const mappedFlight: FlightGroupedSegmentValueObject = {
      ...flight,
      date,
      destination: lastSegment.arrival?.airportCode || '',
    }
    flightSegments.push(mappedFlight)
  }

  return flightSegments
}

function groupTrainSegment(train: TrainPostBookingValueObject): TrainGroupedSegmentValueObject[] {
  const trainSegments: TrainGroupedSegmentValueObject[] = []
  if (train.departureDateTime && train.segments) {
    const date = deleteTimezone(train.departureDateTime)
    const lastSegmentArivalStation = train.segments[train.segments.length - 1].stations?.arrival
    const destinationCity = lastSegmentArivalStation?.location?.city
    const destinationStation = lastSegmentArivalStation?.stationName
    const mappedTrain: TrainGroupedSegmentValueObject = {
      ...train,
      date,
      destination: destinationStation || destinationCity || '',
    }

    trainSegments.push(mappedTrain)
  }

  return trainSegments
}

function groupCarRentalSegment(
  carRental: CarRentalPostBookingValueObject,
  withSplit = true,
): CarRentalGroupedSegmentValueObject[] {
  const carRentalSegments: CarRentalGroupedSegmentValueObject[] = []
  if (carRental.pickUpTime) {
    const date = deleteTimezone(carRental.pickUpTime)
    const carRentalPickUp: CarRentalGroupedSegmentValueObject = {
      ...carRental,
      isPickUp: true,
      isDropOff: false,
      date,
      provider: carRental.vendor?.name || '',
    }
    carRentalSegments.push(carRentalPickUp)
  }

  if (carRental.dropOffTime && withSplit) {
    const date = deleteTimezone(carRental.dropOffTime)

    const carRentalDropOff: CarRentalGroupedSegmentValueObject = {
      ...carRental,
      date,
      provider: carRental.vendor?.name || '',
      isPickUp: false,
      isDropOff: true,
    }
    carRentalSegments.push(carRentalDropOff)
  }

  return carRentalSegments
}

function groupCarServiceSegment(
  carService: CarServicePostBookingValueObject,
): CarServiceGroupedSegmentValueObject[] {
  const carServiceSegments: CarServiceGroupedSegmentValueObject[] = []
  if (carService.pickUpTime) {
    const date = deleteTimezone(carService.pickUpTime)
    const carServiceSegment: CarServiceGroupedSegmentValueObject = {
      ...carService,
      date,
    }
    carServiceSegments.push(carServiceSegment)
  }

  return carServiceSegments
}

function groupRideHailSegment(
  rideHail: RideHailPostBookingValueObject,
  position?: number | null,
): RideHailGroupedSegmentValueObject[] {
  const rideHailSegments: RideHailGroupedSegmentValueObject[] = []

  if (rideHail.pickUpTime) {
    const date = deleteTimezone(rideHail.pickUpTime)

    const {
      bookingId,
      description,
      dropOffAddress,
      dropOffTime,
      estimatedPriceRange,
      pickUpAddress,
      pickUpTime,
      price,
      productName,
      status,
    } = rideHail

    const mappedRideHail: RideHailGroupedSegmentValueObject = {
      position,
      type: SegmentType.RideHail,
      tripStatus: status,
      date,
      bookingId: bookingId || '',
      description: description || '',
      dropOffAddress: dropOffAddress || '',
      dropOffTime: dropOffTime || '',
      estimatedPriceRange,
      pickUpAddress: pickUpAddress || '',
      pickUpTime,
      price,
      productName: productName || '',
    }
    rideHailSegments.push(mappedRideHail)
  }

  return rideHailSegments
}

function groupHotelSegment(
  hotel: HotelPostBookingValueObject,
  withSplit = true,
): HotelGroupedSegmentValueObject[] {
  const hotelSegments: HotelGroupedSegmentValueObject[] = []
  if (hotel.checkIn) {
    const hotelCheckIn: HotelGroupedSegmentValueObject = {
      ...hotel,
      hotelName: hotel.name || '',
      date: hotel.checkIn,
      numberOfNights: hotel.numberOfNights,
      isCheckIn: true,
      isCheckOut: false,
    }
    hotelSegments.push(hotelCheckIn)
  }

  if (hotel.checkOut && withSplit) {
    const hotelCheckOut: HotelGroupedSegmentValueObject = {
      ...hotel,
      hotelName: hotel.name || '',
      date: hotel.checkOut,
      isCheckIn: false,
      isCheckOut: true,
    }

    hotelSegments.push(hotelCheckOut)
  }

  return hotelSegments
}

function getDateWithTimezone(date: string): string {
  const NULL_TIMEZONE = 'T00:00:00.000'
  const doesDateIncludesTimezone = date.length > 10

  if (!date.length || doesDateIncludesTimezone || date.includes('T')) {
    return date
  }
  return date + NULL_TIMEZONE
}
