import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { tripReviewFormatDate, TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { useAppSelector } from '@fiji/store'
import { userMonthAndDayAndTimeFormatSelector } from '@fiji/store/user'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import { useDeleteCarRental } from '../../../hooks/use-delete-car-rental'
import { useChangeCarRental } from '../../../car-rental-details-modal/use-change-car-rental'
import { useTripContent } from '../../use-trip-content'

type Args = {
  segment: CarRentalSegmentEntity
  isChangeAllowed: boolean
  itineraryId: string
  carRentalId: string
  isTripExist: boolean
}

export function useCarRentalSegmentRtp({
  segment,
  isChangeAllowed,
  itineraryId,
  carRentalId,
  isTripExist,
}: Args) {
  const { t } = useTranslation()
  const detailsModalToggle = useTogglePopup()
  const changeCarModalToggle = useTogglePopup()
  const removalDialogToggle = useTogglePopup()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore

  const {
    handleRemoveCarRental,
    viewStateParams: { viewState: removalViewState, title: removalTitle },
  } = useDeleteCarRental({ carRentalId })

  const handleConfirmRemoveCarRental = useCallback(() => {
    handleRemoveCarRental()
    removalDialogToggle.handleClose()
  }, [handleRemoveCarRental, removalDialogToggle])

  const updateChangeModalOpen = () => {
    changeCarModalToggle.handleOpen()
    detailsModalToggle.handleClose()
  }
  const handleCloseModal = () => {
    detailsModalToggle.handleClose()
    changeCarModalToggle.handleClose()
  }

  const handleChangeCarRentalLink =
    isTripExist && isChangeAllowed ? updateChangeModalOpen : undefined
  const carRental = segment
  const shouldDisplayMonth =
    tripReviewFormatDate(TripReviewDateType.Month, carRental.pickUpTime) !==
    tripReviewFormatDate(TripReviewDateType.Month, carRental.dropOffTime)

  const startDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, carRental.pickUpTime)

  const endDate = tripReviewFormatDate(
    shouldDisplayMonth ? TripReviewDateType.DayAndDate : TripReviewDateType.DayAndDate,
    carRental.dropOffTime,
  )

  const longDateLabel = `${tripReviewFormatDate(
    TripReviewDateType.DayAndDate,
    carRental.pickUpTime,
  )} - ${tripReviewFormatDate(TripReviewDateType.DayAndDate, carRental.dropOffTime)}`

  const { cardStatus } = useTripContent({ segmentState: carRental.segmentState })

  const { handleChangeCarRental } = useChangeCarRental({
    itineraryId,
    carRental,
    handleCloseChangeModal: handleCloseModal,
  })

  const { monthAndDayAndTimeFormat } = useAppSelector(userMonthAndDayAndTimeFormatSelector)

  const shouldShowServiceFeeLabel = flags.isServiceFeeEnabled && !!carRental.serviceFeeRate

  const cardActions = []

  if (!handleChangeCarRentalLink && !isTripExist && isChangeAllowed) {
    cardActions.push({
      label: t('CarRentalDetailsModal.ChangeCarRental.Title'),
      onClick: updateChangeModalOpen,
    })
  }

  const { carRentalTitle: titleSlot } = translateCarNameDetails({
    carType: carRental.carDetails.type,
    carClass: carRental.carDetails.class,
  })

  return {
    removalViewState,
    removalTitle,
    cardStatus,
    detailsModalToggle,
    removalDialogToggle,
    carRental,
    startDate,
    endDate,
    longDateLabel,
    handleChangeCarRentalLink,
    handleChangeCarRental,
    handleCloseModal,
    handleConfirmRemoveCarRental,
    changeCarModalToggle,
    cardActions,
    monthAndDayAndTimeFormat,
    titleSlot,
    shouldShowServiceFeeLabel,
  }
}
