import styled from 'styled-components'

export const ControlsContainerDesktopStyled = styled.div`
  padding: 0 19px;
  width: unset;
  height: 56px;
  min-height: 56px;
  display: flex;
  flex-direction: column;

  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`
