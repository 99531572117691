import styled from 'styled-components'
import { footnoteMedium, subHeadStrong, headline, title3, subHeadMedium } from '@fiji/style'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const UpperBody = styled.div`
  padding: 10px 20px;
`

export const EmissionInformation = styled.div``

export const EmissionInformationRow = styled.div<{ withNegativeMarginTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: ${(props) => (props.withNegativeMarginTop ? '-5px' : 0)};
`

export const EmissionInformationType = styled.p`
  ${subHeadMedium}
`

export const EmissionInformationValue = styled.p<{ deviationLevel?: EmissionsDeviationLevel }>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.mainText1};
  ${subHeadStrong};
`

export const DeviationInfo = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.bodyText};
  background-color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.ecoCheck1
      : props.theme.colors.background};
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  ${subHeadStrong};
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const DownArrow = styled(Icon)`
  transform: rotate(180deg);
`

export const CarouselContainer = styled.div`
  margin-top: 16px;
`

export const EmisisonDetails = styled.div`
  padding: 0 20px;
`

export const EmisisonDetailsHeader = styled.p`
  margin: 0;
  margin-top: 5px;
  ${headline};
`

export const EmisisonDetailsDescription = styled.p<{ withBorder?: boolean }>`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  border-bottom: ${(props) => (props.withBorder ? '1px' : 0)} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 15px;
`

export const ClapContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
`
export const ClapTitle = styled.p`
  margin-left: 10px;
  ${title3}
`

export const DeviationDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LearnMoreLinkWrapper = styled.div`
  margin: 0;
  margin-top: 8px;
`
