import styled from 'styled-components'

export const Warning = styled.div`
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
`

export const WarningIcon = styled.div`
  height: 100%;
`

export const WarningText = styled.div`
  line-height: 18px;
  font-size: 13px;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.bodyText};
`
