import styled from 'styled-components'
import { regularListItem, subHeadMedium, captionStrongCaps } from '@fiji/style'

export const ListItem = styled.li`
  cursor: pointer;
  position: relative;
  padding: 2px 0;
  margin: 0;
  ${subHeadMedium};
  color: ${(p) => p.theme.primaryColor};

  &:hover {
    background-color: ${(p) => p.theme.inputBorderColor};
  }

  ${regularListItem};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    left: 46px;
    border-bottom: 1px solid ${(p) => p.theme.colors.background1};
  }
`

export const TitleContainer = styled.div`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 4px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${captionStrongCaps};
`
