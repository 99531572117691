import { useMemo } from 'react'
import type { CustomFieldCollectionItem } from '@fiji/graphql/types'
import type {
  CustomFieldExtension,
  CustomFieldCollectionItemExtension,
} from '@etta/ui/custom-field/types'
import { stripTags } from './strip-tags'
import { CustomFieldPrefix } from './types'
import { generateCustomUuid } from './generate-custom-uuid'

export function useComputedCustomFields(
  fields?: CustomFieldCollectionItem[],
): CustomFieldCollectionItemExtension[] {
  return useMemo(() => {
    if (!fields || !fields.length) {
      return []
    }

    return fields.map((field, parentIndex) => {
      return {
        ...field,
        collectionLabel: stripTags(field.collectionLabel),
        collectionDescription: stripTags(field.collectionDescription),
        customFields: field.customFields.reduce<CustomFieldExtension[]>(
          (acc, customField, itemIndex) => {
            if (!customField.type || !customField.dateTimeDisplay) {
              acc.push({
                ...customField,
                customUuid: generateCustomUuid({ name: customField.name, itemIndex, parentIndex }),
              })
              return acc
            }

            const {
              displayYear,
              displayMonth,
              displayDay,

              displayHour,
              displayMinute,

              displayTimeZone,
            } = customField.dateTimeDisplay

            if (displayDay || displayYear || displayMonth) {
              acc.push({
                ...customField,
                customUuid: generateCustomUuid({
                  prefix: CustomFieldPrefix.DayYearMonth,
                  name: customField.name,
                  itemIndex,
                  parentIndex,
                }),
                dateTimeDisplay: {
                  ...customField.dateTimeDisplay,
                  displayTimeZone: false,
                  displayHour: false,
                  displayMinute: false,
                },
              })
            }

            if (displayHour || displayMinute) {
              acc.push({
                ...customField,
                customUuid: generateCustomUuid({
                  prefix: CustomFieldPrefix.HourMinute,
                  name: customField.name,
                  itemIndex,
                  parentIndex,
                }),
                dateTimeDisplay: {
                  displayHour,
                  displayMinute,
                },
              })
            }

            if (displayTimeZone) {
              acc.push({
                ...customField,
                customUuid: generateCustomUuid({
                  prefix: CustomFieldPrefix.Timezone,
                  name: customField.name,
                  itemIndex,
                  parentIndex,
                }),
                dateTimeDisplay: {
                  displayTimeZone,
                },
              })
            }

            return acc
          },
          [],
        ),
      }
    }, [])
  }, [fields])
}
