import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import type { TripHotelLayoutProps } from '../types'
import { TripHotelLayoutMobile } from './mobile'
import { TripHotelContentDesktop } from './desktop'

export function TripHotelContentLayout(props: TripHotelLayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<TripHotelLayoutMobile {...props} />}
      desktopSlot={<TripHotelContentDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
