import type { GetPurchaseInfoQuery } from '@fiji/graphql/types'
import type { PurchaseInfoValueObject } from '../../../core/value-objects'

export class PurchaseInfoMapper {
  static toValueObject(
    purchaseInfo: GetPurchaseInfoQuery['purchaseInfo'],
  ): PurchaseInfoValueObject {
    return {
      additionalTravelers: purchaseInfo.additionalTravelers,
      billingParameters: this.arrayFilter(purchaseInfo.billingParameters),
      details: purchaseInfo.details,
      dynamicSiteMessages: purchaseInfo.dynamicSiteMessages,
      primaryTraveler: purchaseInfo.primaryTraveler,
      customFieldsConfiguration: purchaseInfo.customFieldsConfiguration,
    }
  }

  static arrayFilter<T>(arr?: unknown[] | null) {
    return arr?.filter((item): item is T => !!item)
  }
}
