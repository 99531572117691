import styled from 'styled-components'
import { headline, smallText } from '@fiji/style'

export const Price = styled.div`
  ${smallText}
  color: ${(p) => p.theme.colors.bodyText};
  display: flex;
  align-items: flex-end;
  margin-right: 3px;
`

export const Cost = styled.span`
  margin-right: 4px;
  ${headline}
  color: ${(p) => p.theme.colors.mainText};
  line-height: 0.9;
`
