import { useCallback } from 'react'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import type { AdaptiveFlight } from '@etta/components/flight-details/types'
import type {
  FlightLegSubSegment,
  SearchFlightLegSubSegment,
  TripDetailsFlightLegSegment,
} from '@fiji/graphql/types'
import { useExpanded } from './use-expanded'
import { FlightDetailsLegendWithToggleLayout } from './layout'

type Props = {
  flight: AdaptiveFlight
  flightLegSegments?:
    | FlightLegSubSegment[]
    | TripDetailsFlightLegSegment[]
    | SearchFlightLegSubSegment[]
  backgroundColor?: LayoutBackgroundColor
  segmentDaysInBetween?: number
  shouldShowAirportChangeBadge?: boolean
  onToggle?: () => void
}

export function FlightDetailsLegendWithToggle({
  flight,
  backgroundColor,
  segmentDaysInBetween,
  flightLegSegments,
  shouldShowAirportChangeBadge,
  onToggle,
}: Props) {
  const { isExpanded, toggleExpansion, buttonText, buttonTextAria } = useExpanded()

  const handleToggleExpansion = useCallback(() => {
    if (onToggle) {
      onToggle()
    }

    toggleExpansion()
  }, [onToggle, toggleExpansion])

  return (
    <FlightDetailsLegendWithToggleLayout
      flight={flight}
      flightLegSegments={flightLegSegments}
      backgroundColor={backgroundColor}
      buttonText={buttonText}
      buttonTextAria={buttonTextAria}
      isExpanded={isExpanded}
      toggleExpansion={handleToggleExpansion}
      segmentDaysInBetween={segmentDaysInBetween}
      shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
    />
  )
}
