import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { Block } from '@etta/ui/block'
import { SelectedDateTitleContainer } from './selected-date-title-styled'

type Props = {
  isMobile: boolean
  dateTitle?: string
}

export function SelectedDateTitle({ isMobile, dateTitle }: Props) {
  if (isMobile) {
    return <>{dateTitle}</>
  }
  return (
    <SelectedDateTitleContainer>
      <Block marginRight={4}>
        <PWAIcon name="calendarPWA" />
      </Block>
      <span>{dateTitle}</span>
    </SelectedDateTitleContainer>
  )
}
