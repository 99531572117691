import type { PlainTime, Place } from '@fiji/types'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { AddressValueObject } from '@etta/core/value-objects/address.value-object'
import { Service } from '@etta/di'

@Service()
export class CarRentalModifyService {
  resolveTime(stamp?: string | null): PlainTime | undefined {
    if (!stamp) {
      return undefined
    }
    const stampFiltered = deleteTimezone(stamp)
    return {
      hours: new Date(stampFiltered).getHours(),
      minutes: new Date(stampFiltered).getMinutes(),
    }
  }

  resolveLocation(address?: AddressValueObject | null): Place | undefined {
    if (!address) {
      return undefined
    }
    const { airportCode, city, stateCode, geocode, countryCode } = address
    return {
      placeId: '',
      name: airportCode
        ? ''
        : `${city}${stateCode ? `, ${stateCode}` : ''}${countryCode ? `, ${countryCode}` : ''}`,
      airportCode: airportCode || undefined,
      latitude: geocode?.lat,
      longitude: geocode?.long,
    }
  }

  resolveDate(date?: string | null): Date | undefined {
    if (!date) {
      return undefined
    }
    return new Date(deleteTimezone(date))
  }
}
