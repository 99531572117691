/* eslint-disable react-hooks/exhaustive-deps */
import type { RefObject } from 'react'
import { useEffect } from 'react'

type Props = {
  calendarContainer: Element | null
  nextButton: Element | null
  container: RefObject<HTMLDivElement>
  onNextButtonClick: () => void
  onPrevButtonClick: () => void
}

export function useInfiniteScroll({
  calendarContainer,
  nextButton,
  container,
  onPrevButtonClick,
  onNextButtonClick,
}: Props) {
  useEffect(() => {
    if (!calendarContainer) {
      return
    }
    if (!nextButton) {
      return
    }

    const handleShowPrevMonth = () => {
      const el = calendarContainer.querySelector('.CalendarDay__blocked_calendar')
      if (el) {
        return
      }

      onPrevButtonClick()
    }

    const onScroll = () => {
      const calendarElement = calendarContainer.getBoundingClientRect()
      const containerElement = container.current?.getBoundingClientRect()

      if (!containerElement) {
        return
      }

      if (calendarElement.bottom - containerElement.bottom <= containerElement.height) {
        onNextButtonClick()
      }

      if (calendarElement.top > 0) {
        handleShowPrevMonth()
      }
    }

    container.current?.addEventListener('scroll', onScroll)

    return () => container.current?.removeEventListener('scroll', onScroll)
  }, [calendarContainer, nextButton])
}
