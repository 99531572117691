import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

export const Icon = styled.div`
  width: 100px;
  height: 85px;
  margin: 0 0 16px 14px;
`

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  width: 100px;
`
