import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 15px;
`

export const AlignCenter = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SubTitleContainer = styled.div`
  text-align: end;
`

export const IconWrapper = styled.span<{ withBackgroundColor?: boolean }>`
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ withBackgroundColor, theme }) =>
    withBackgroundColor && `background-color: ${theme.colors.seatMap.charcoal11}`};
  border-radius: 4px;
  margin-right: 4px;
`

export const SeatIconSmallWrapper = styled.div`
  width: 12px;
  height: 16px;
  color: ${(props) => props.theme.colors.primary};
`

export const SeatIconWrapper = styled.span`
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 18px;
  color: ${(props) => props.theme.colors.warning};
`
