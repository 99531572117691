import { isRestrictedCountryError } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import { Inject, Service } from '@etta/di'
import { CarRentalSearchStore } from '@etta/modules/car-rental-search/interface/stores/car-rental-search.store'
import { CarRentalSearchServiceAdapter } from '@etta/modules/car-rental-search/infra/adapters'
import type { CarRentalInputValueObject } from '../../core/value-objects'

@Service()
export class CarRentalSearchService {
  constructor(
    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,
    @Inject()
    private readonly carRentalSearchServiceAdapter: CarRentalSearchServiceAdapter,
  ) {}

  async createCarRentalSearch(input: CarRentalInputValueObject) {
    this.carRentalSearchStore.setIsLoading(true)
    this.carRentalSearchStore.setIsErrorCreatingSearch(false)
    this.carRentalSearchStore.setIsRestrictedCountryError(false)
    this.carRentalSearchStore.setRestrictedErrorMessage(null)
    this.carRentalSearchStore.setCarRentals(null)

    const result = await this.carRentalSearchServiceAdapter.createCarRentalSearch({ input })

    result.match({
      Ok: (result) => {
        if (result.searchId) {
          this.carRentalSearchStore.setSearchId(result.searchId)
        }
        if (result.carRentals) {
          this.carRentalSearchStore.setCarRentals(result.carRentals)
          this.carRentalSearchStore.setPageEnd(result.carRentals.page.end + 1)
        }
        this.carRentalSearchStore.setIsLoading(false)
      },
      Err: (err) => {
        if (isRestrictedCountryError(err)) {
          this.carRentalSearchStore.setIsRestrictedCountryError(true)
          this.carRentalSearchStore.setRestrictedErrorMessage(err.error.message)
        } else {
          this.carRentalSearchStore.setIsErrorCreatingSearch(true)
        }
        this.carRentalSearchStore.setIsLoading(false)
      },
    })
  }
}
