import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style'
import { Link } from '@etta/ui/link'

export const PayableBlock = styled.div`
  padding-top: 24px;
  color: ${({ theme }) => theme.colors.mainText1};
`

export const PayableTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.mainText};

  ${captionStrongCaps}
`
export const DetailsLink = styled(Link)`
  display: block;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.primary1};
`
