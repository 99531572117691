import { useCallback } from 'react'
import { useRailSearchQuery } from '@fiji/hooks/search-queries/use-rail-search-query/use-rail-search-query'
import { ROUTES } from '@fiji/routes'
import { deleteTimezone, TimeStrFormat } from '@fiji/utils/dates/delete-timezone'
import { useGetPlaceByStationName } from './use-get-place-by-station-name'

type Props = {
  arrival: string
  arrivalStationName: string
  departure: string
  departureStationName: string
  itineraryId: string
  handleCloseChangeModal: () => void
}

export function useChangeRail({
  arrival,
  arrivalStationName,
  departure,
  departureStationName,
  handleCloseChangeModal,
  itineraryId,
}: Props) {
  const { place: originPlace } = useGetPlaceByStationName({ stationName: departureStationName })
  const { place: destinationPlace } = useGetPlaceByStationName({ stationName: arrivalStationName })
  const { navigateTo, queryParams } = useRailSearchQuery({
    originPlace,
    destinationPlace,
  })
  const { bookingId } = queryParams
  const handleChangeRail = useCallback(() => {
    const originDate = new Date(deleteTimezone(departure, TimeStrFormat.ISO))
    const destinationDate = new Date(deleteTimezone(arrival, TimeStrFormat.ISO))

    handleCloseChangeModal()
    navigateTo(ROUTES.rail.search, {
      itineraryId,
      bookingId,
      filters: {},
      destinationDate,
      originDate,
      originPlace,
      destinationPlace,
    })
  }, [
    departure,
    arrival,
    handleCloseChangeModal,
    navigateTo,
    itineraryId,
    bookingId,
    originPlace,
    destinationPlace,
  ])

  return {
    handleChangeRail,
  }
}
