import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { CabinClassType } from '@fiji/types'

type SubSegments = FlightPostBookingValueObject['segments']

type Args = {
  segments?: SubSegments
  options: CabinClassType[]
}

export function resolveCabinClass({ segments, options }: Args): CabinClassType {
  if (!segments?.length) {
    return options[0]
  }
  const cabinClass = segments[0].serviceClass

  return options.find((option) => option.id === cabinClass) || options[0]
}
