import { useTranslation } from 'react-i18next'
import { Badge } from '@etta/ui/badge'
import type { IconSizes } from '@etta/ui/icon'
import type { TooltipPlace } from '@etta/ui/tooltip'
import { Tooltip } from '@etta/ui/tooltip'
import { TooltipMultilineContent } from '@etta/ui/tooltip/tooltip-multiline-content/tooltip-multiline-content'
import { ScreenType, useScreenType } from '@fiji/modes'

type Props = {
  badgeSize: IconSizes
  outOfPolicyTexts?: string[]
  tooltipPlace?: TooltipPlace
}

export function OutOfPolicyBadge({ badgeSize, outOfPolicyTexts, tooltipPlace }: Props) {
  const { t } = useTranslation()
  const tooltipTitle = t('TooltipText.OutOfPolicyReasons')

  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (!outOfPolicyTexts || !outOfPolicyTexts.length || isMobile) {
    return <Badge variant="outOfPolicy" color="bodyText" iconSize={badgeSize} />
  }

  return (
    <Tooltip
      slot={<TooltipMultilineContent title={tooltipTitle} textItems={outOfPolicyTexts} />}
      place={tooltipPlace}
      isIndependentOfParentWidth>
      <Badge variant="outOfPolicy" color="bodyText" iconSize={badgeSize} />
    </Tooltip>
  )
}
