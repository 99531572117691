import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import type { Room } from '@fiji/graphql/types'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { segmentIds } from '@fiji/store/segment-ids'
import { useAppSelector } from '@fiji/store'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { formatPrice } from '@fiji/utils/money/format-price'
import { mapHotelDetailsFromHotel } from './map-hotel-details-from-hotel'
import type { HandleAddHotelRoomArgs, HotelDetailsModalProps } from './types'
import { useSelectHotel } from './use-select-hotel'

export function useHotelDetailsModal({
  checkIn,
  checkOut,
  hotelResultId,
  itineraryId,
  bookingId,
}: HotelDetailsModalProps) {
  const { t } = useTranslation()
  const {
    hotelSearchResultsStore,
    hotelResultsAction,
    loadHotelsAction,
  } = useHotelSearchResultsContext()
  const {
    multipleHotelProvidersStore,
    multipleHotelProvidersActions,
  } = useMultipleHotelProvidersContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const {
    activeItineraryToggle,
    selectedRoom,
    tripExpiredToggle,
    viewState,
    hotelDetailsModalToggle,
    selectedHotelId,
  } = hotelSearchResultsStore
  const {
    handleSetSelectedRoom,
    handleClearSelectedRoom,
    handleSetViewState,
    handleResetViewState,
    handleSetSelectedHotelId,
    handleResetSelectedHotelId,
  } = hotelResultsAction
  const { getServiceFeeConfiguration } = displayConfigurationStore
  const { createTrip } = loadHotelsAction
  const { handleSetProviderName, areMultipleProvidersExist } = multipleHotelProvidersActions
  const { multipleHotelProvidersSearchDialog } = multipleHotelProvidersStore
  const { hotelId } = useAppSelector(segmentIds)
  const { siteName } = useSelector(displayConfigurationSelector)
  const hotelDetails = mapHotelDetailsFromHotel({
    hotel: hotelSearchResultsStore.pickedHotel,
    checkIn,
    checkOut,
  })
  const { activeTripStore } = useReviewTripContext()
  const {
    activeTripId,
    maximumQuantityToggle,
    isEmptyItinerary,
    isTripExpired,
    isMaximumHotelQuantity,
  } = activeTripStore
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore
  const { isActiveItineraryPurchaseFlowEnabled, isServiceFeeEnabled } = flags

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const areHotelAboutDatesDisplayed = !!isMobile

  const serviceFeeLabel = useMemo(() => {
    if (!isServiceFeeEnabled) {
      return
    }

    const serviceFee = getServiceFeeConfiguration.hotelFares.find((hotelFare) =>
      hotelFare.vendorCodesList.some(
        (vendorCode) => vendorCode === hotelSearchResultsStore.pickedHotel?.chainCode,
      ),
    )

    if (!serviceFee) {
      return
    }

    const serviceFeesAmount = formatPrice({
      amount: serviceFee.serviceFeesAmount,
      currency: serviceFee.serviceFeesAmountCurrency,
    })

    return t('HotelDetails.ServiceFeeLabel', {
      description: serviceFee.description,
      serviceFeesAmount,
      serviceFeesCurrency: serviceFee.serviceFeesAmountCurrency,
    })
  }, [
    t,
    isServiceFeeEnabled,
    getServiceFeeConfiguration.hotelFares,
    hotelSearchResultsStore.pickedHotel?.chainCode,
  ])

  const handleOpenHotelDetailsModal = (hotelId: string) => {
    hotelResultsAction.pickHotel(hotelId)
    handleSetSelectedHotelId(hotelId)
    hotelDetailsModalToggle.handleOpen()
  }
  const handleCloseHotelDetailsModal = () => {
    handleResetSelectedHotelId()
    handleResetViewState()
    hotelDetailsModalToggle.handleClose()
  }

  const { handleSubmitHotelRoom } = useSelectHotel({
    onUpdateViewStatus: handleSetViewState,
  })

  const handleCreateTrip = useCallback(() => {
    handleSetViewState('loading')
    return createTrip()
  }, [createTrip, handleSetViewState])

  const handleCloseModals = useCallback(() => {
    if (activeItineraryToggle.isOpen) {
      activeItineraryToggle.handleClose()
    }
    if (tripExpiredToggle.isOpen) {
      tripExpiredToggle.handleClose()
    }
  }, [activeItineraryToggle, tripExpiredToggle])

  const handleAddHotelRoom = useCallback(
    ({
      itineraryId,
      bookingId,
      hotelDetails,
      checkIn,
      checkOut,
      room,
      hotelResultId,
      hotelId,
    }: HandleAddHotelRoomArgs) => {
      handleCloseModals()
      handleSubmitHotelRoom({
        itineraryId,
        bookingId,
        hotelDetails,
        checkIn,
        checkOut,
        room,
        hotelResultId,
        hotelId,
      })
    },
    [handleSubmitHotelRoom, handleCloseModals],
  )

  const handleRoomSelect = useCallback(
    async (room: Room) => {
      const shouldShowActiveItineraryModal = !isEmptyItinerary && !itineraryId && !isMobile
      const shouldShowTripExpirationModal = !isEmptyItinerary && isTripExpired && !isMobile

      if (isActiveItineraryPurchaseFlowEnabled) {
        handleSetSelectedRoom(room)

        if (shouldShowTripExpirationModal) {
          tripExpiredToggle.handleOpen()
          return
        }

        if (isMaximumHotelQuantity) {
          maximumQuantityToggle.handleOpen()
          return
        }

        if (areMultipleProvidersExist(room.source)) {
          handleSetProviderName(room.source)
          multipleHotelProvidersSearchDialog.handleOpen()
          return
        }

        if (shouldShowActiveItineraryModal) {
          activeItineraryToggle.handleOpen()
          return
        }
      }
      handleClearSelectedRoom()

      if (isMaximumHotelQuantity) {
        maximumQuantityToggle.handleOpen()
        return
      }

      if (areMultipleProvidersExist(room.source)) {
        handleSetProviderName(room.source)
        multipleHotelProvidersSearchDialog.handleOpen()
        return
      }

      const _itineraryId = itineraryId || (await handleCreateTrip())
      handleAddHotelRoom({
        itineraryId: _itineraryId,
        bookingId,
        hotelDetails,
        checkIn,
        checkOut,
        room,
        hotelResultId,
        hotelId,
      })
    },
    [
      itineraryId,
      activeItineraryToggle,
      handleAddHotelRoom,
      handleSetSelectedRoom,
      handleClearSelectedRoom,
      bookingId,
      checkIn,
      checkOut,
      hotelDetails,
      hotelId,
      hotelResultId,
      handleCreateTrip,
      isEmptyItinerary,
      isActiveItineraryPurchaseFlowEnabled,
      tripExpiredToggle,
      isMobile,
      isTripExpired,
      isMaximumHotelQuantity,
      maximumQuantityToggle,
      handleSetProviderName,
      areMultipleProvidersExist,
      multipleHotelProvidersSearchDialog,
    ],
  )

  const handleCreateNewTrip = useCallback(async () => {
    handleCloseModals()
    const _itineraryId = await handleCreateTrip()
    handleAddHotelRoom({
      bookingId,
      hotelDetails,
      checkIn,
      checkOut,
      room: selectedRoom,
      hotelResultId,
      hotelId,
      itineraryId: _itineraryId,
    })
  }, [
    bookingId,
    hotelDetails,
    checkIn,
    checkOut,
    hotelResultId,
    hotelId,
    handleAddHotelRoom,
    selectedRoom,
    handleCreateTrip,
    handleCloseModals,
  ])

  const handleAddToCurrentTrip = useCallback(() => {
    handleAddHotelRoom({
      itineraryId: activeTripId,
      bookingId,
      hotelDetails,
      checkIn,
      checkOut,
      room: selectedRoom,
      hotelResultId,
      hotelId,
    })
  }, [
    activeTripId,
    bookingId,
    selectedRoom,
    checkIn,
    checkOut,
    handleAddHotelRoom,
    hotelDetails,
    hotelId,
    hotelResultId,
  ])

  return {
    viewState,
    companyName: siteName,
    isVisible: hotelDetailsModalToggle.isOpen,
    handleOpen: handleOpenHotelDetailsModal,
    onClose: handleCloseHotelDetailsModal,
    hotelDetails,
    serviceFeeLabel,
    isHotelDetailsLoading: hotelSearchResultsStore.pickedHotel?.isLoading,
    areHotelAboutDatesDisplayed,
    onRoomSelect: handleRoomSelect,
    hasHotelDetailsError: hotelSearchResultsStore.pickedHotel?.isError,
    retryHotelDetails: () =>
      loadHotelsAction.retryLoadHotel(hotelSearchResultsStore.pickedHotel?.id),
    selectedHotelId,
    handleCreateNewTrip,
    handleAddToCurrentTrip,
  }
}
