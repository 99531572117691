import { useState, useMemo } from 'react'
import type { Moment } from 'moment'
import moment from 'moment'
import { getRange } from './get-range'

type ViewState = 'year-picker' | 'day-picker'

type Args = {
  onChange: (date?: Date) => void
  onClose?: () => void
  value?: Date
  yearsBefore?: number
  yearsAfter?: number
}

export function useDateOfBirthPicker({
  onChange,
  onClose = () => {},
  value,
  yearsBefore,
  yearsAfter,
}: Args) {
  const currentDate = new Date()
  const [date, setDate] = useState<Moment>(value ? moment(value) : moment())
  const [choosenYear, setChoosenYear] = useState<number | undefined>(
    value ? value.getFullYear() : undefined,
  )
  const [currentState, setCurrentState] = useState<ViewState>('day-picker')
  const [monthText, setMonthText] = useState<string>('')

  const buttonDisabled = useMemo(() => {
    if (choosenYear && currentState === 'year-picker') {
      return false
    }
    if (currentState === 'day-picker' && date) {
      return false
    }
    return true
  }, [choosenYear, currentState, date])

  const onDoneClick = () => {
    if (currentState === 'year-picker') {
      if (choosenYear) {
        setDate((date) => moment(date).set('year', choosenYear))
      }
      setCurrentState('day-picker')
    } else {
      onChange(date.toDate())
      onClose()
    }
  }

  const onClearClick = () => {
    onChange(undefined)
    onClose()
  }

  const onYearClick = (year: number) => {
    setChoosenYear(year)
    setDate((date) => moment(date).set('year', year))
  }

  const yearsArray = getRange(currentDate.getFullYear() - 100, currentDate.getFullYear())
  const futureYearsArray = getRange(currentDate.getFullYear(), currentDate.getFullYear() + 19)
  const customYearsArray = getRange(
    currentDate.getFullYear() - (yearsBefore || 0),
    currentDate.getFullYear() + (yearsAfter || 0),
  )

  const changeState = () => {
    if (currentState === 'year-picker') {
      setCurrentState('day-picker')
    } else {
      setCurrentState('year-picker')
    }
  }
  const onDaySelect = (date: Moment | null) => {
    if (date) {
      setDate(date)
    }
  }

  const yearHeaderText = `${monthText} ${choosenYear || date.format('YYYY')}`

  return {
    buttonDisabled,
    onDoneClick,
    onClearClick,
    onYearClick,
    onDaySelect,
    choosenYear,
    currentState,
    date,
    yearsArray,
    futureYearsArray,
    customYearsArray,
    changeState,
    setMonthText,
    yearHeaderText,
  }
}
