import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getHotelSustainabilityDeviation } from '@fiji/hooks/sustainability/get-hotel-sustainability-deviation'
import type { CalculateEmissionsResultHotelCertification } from '@fiji/graphql/types'
import { CalculateEmissionsResultHotelCertificationType } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'
import type { ColorPalette } from '@fiji/types'

type Props = {
  averageSustainabilityScore: number
  sustainabilityScore: number
  certifications: CalculateEmissionsResultHotelCertification[]
  isEcoFriendly?: boolean | null
}

export function useHotelSustainability(props: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.Hotel'

  const { deviationLevel } = getHotelSustainabilityDeviation(props)

  const sustainabilityDescription = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.AverageSustainable')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.MoreSustainable')
      case EmissionsDeviationLevel.More:
        return !props.isEcoFriendly ? t(i18nBase + '.LessSustainable') : ''
    }
  }, [deviationLevel, props.isEcoFriendly, t])

  const sustainablityConditionColor = useMemo((): keyof ColorPalette => {
    if (props.isEcoFriendly) {
      switch (deviationLevel) {
        case EmissionsDeviationLevel.More:
        case EmissionsDeviationLevel.Less:
          return 'success'
        case EmissionsDeviationLevel.Average:
          return 'borderDark'
      }
    }

    switch (deviationLevel) {
      case EmissionsDeviationLevel.Less:
        return 'success'
      default:
        return 'borderDark'
    }
  }, [deviationLevel, props.isEcoFriendly])

  const isHotelCertificationPresent = useMemo(() => {
    return props.certifications?.find(
      (certification) =>
        certification.type === CalculateEmissionsResultHotelCertificationType.HotelCertification,
    )
  }, [props.certifications])

  const sustainabilityAdditionalDescription = useMemo(() => {
    const i18nBase = 'Emissions.Hotel'

    if (props.isEcoFriendly) {
      return t(i18nBase + '.EcoFriendly')
    }

    if (isHotelCertificationPresent) {
      return t(i18nBase + '.EcoCertified')
    }
  }, [props.isEcoFriendly, isHotelCertificationPresent, t])

  return {
    isHotelCertificationPresent,
    sustainabilityDescription,
    sustainabilityAdditionalDescription,
    sustainablityConditionColor,
    deviationLevel,
  }
}
