import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@etta/ui/icon'
import { Container, Label } from './option-components'

type Props = {
  label: ReactNode
  isChosen?: boolean
  onClick(): any
}
const i18nBase = 'Accessibility.Options'

export function Option({ label, isChosen = false, onClick }: Props) {
  const { t } = useTranslation()

  const ariaLabel = isChosen
    ? t(i18nBase + '.Selected', { option: label })
    : t(i18nBase + '.Select', { option: label })
  return (
    <Container aria-label={ariaLabel} onClick={onClick}>
      <Label>{label}</Label>
      {isChosen && <Icon name="newTickPWA" size="normal" color="primary3" />}
    </Container>
  )
}
