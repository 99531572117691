import { observer } from 'mobx-react-lite'
import { ListTypes } from '@etta/ui/drop-down-list'
import { CarRentalFiltersType } from '@fiji/enums'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { CarRentalOfficeDropdown } from '../car-rental-office-dropdown'
import type { FilterProps } from '../types'
import { FilterContainer } from '../filter-container'
import { useCarClassFilter } from './use-car-class-filter'
import { CarClassFilterWrapper } from './car-class-filter-styled'
import { CarClassOptions } from './car-class-options'

export const CarClassFilter = observer(function CarClassFilter({
  isDropdownFilter = true,
}: FilterProps) {
  const {
    isCarRentalsNewFiltersEnabled,
    handleChangeCarClass,
    carClass,
    label,
    carFiltersStore,
    carRentalFilterActions,
    carRentalSearchStore,
    carClassValue,
    isClearDisabled,
    handleApplyCarClassFilters,
  } = useCarClassFilter()

  if (!isCarRentalsNewFiltersEnabled) {
    return (
      <CarClassFilterWrapper>
        <CarRentalOfficeDropdown
          label={label}
          data-tracking-id="car-class-filter-dropdown"
          onChange={handleChangeCarClass}
          options={carClass.options}
          filters={carClass.options}
          defaultLabel="All"
          type={ListTypes.Select}
          filtersType={CarRentalFiltersType.Class}
          value={carClass.selectedOptions}
        />
      </CarClassFilterWrapper>
    )
  }

  if (isDropdownFilter) {
    return (
      <CarClassFilterWrapper>
        <QuickFilterLayout
          data-tracking-id="transmission-filter-dropdown"
          isOpen={carFiltersStore.carClassModal.isOpen}
          onOpen={carFiltersStore.carClassModal.handleOpen}
          onClose={carFiltersStore.carClassModal.handleClose}
          onClear={carRentalFilterActions.clearHandlers.clearCarClass}
          onApply={handleApplyCarClassFilters}
          label={label}
          value={carClassValue}
          isShimmerLoading={carRentalSearchStore.isLoading && !carRentalSearchStore.carRentals}
          isLoading={carRentalSearchStore.isLoading}
          selectedNumber={carFiltersStore.selectedFilters.carClass.length}
          isApplyButtonDisabled={carFiltersStore.isEqual}
          isClearButtonDisabled={isClearDisabled}>
          <CarClassOptions />
        </QuickFilterLayout>
      </CarClassFilterWrapper>
    )
  }

  return (
    <FilterContainer
      title={label}
      selectedNumber={carFiltersStore.selectedFilters.carClass.length}
      isClearButtonDisabled={isClearDisabled}
      onClear={carRentalFilterActions.clearHandlers.clearCarClass}>
      <CarClassOptions withBottomBorder={false} optionsPaddingVertical={11} />
    </FilterContainer>
  )
})
