import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'

import { CarRentalTypeFilterBarMobile } from './mobile'
import { CarRentalTypeFilterBarDesktop } from './desktop'

export function CarRentalTypeFilterBar(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalTypeFilterBarMobile {...props} />}
      desktopSlot={<CarRentalTypeFilterBarDesktop {...props} />}
    />
  )
}
