import { CarRentalSearchSegment } from '@etta/components/car-rental-search-segment/car-rental-search-segment'
import { InfiniteScroll } from '@etta/ui/infinite-scroll'
import type { CalculateCarEmissionsBatchResultCar } from '@fiji/graphql/types'
import { CarRentalFuelType } from '@fiji/graphql/types'
import type { Cars, CarPreferenceAttribute } from '../types'
import { Container } from './car-rental-list-styled'
import { getCarRentalEmission } from './get-car-rental-emission'

type Props = {
  cars: Cars
  carPreferenceAttribute?: CarPreferenceAttribute | null
  onCarRentalDetailsOpen: ({
    carId,
    isSelectable,
    isHybridFuel,
  }: {
    carId: string
    isSelectable: boolean
    isHybridFuel: boolean
  }) => void
  withAnimation: boolean
  onAddCar: (carRentalKey: string) => void
  carEmissions: {
    loading: boolean
    averageEmissionsTonnes: number
    existingCarRentalEmissions: CalculateCarEmissionsBatchResultCar[]
  }
}

export function CarRentalList({
  cars,
  carPreferenceAttribute,
  onCarRentalDetailsOpen,
  withAnimation,
  onAddCar,
  carEmissions,
}: Props) {
  return (
    <Container>
      <InfiniteScroll.List withAnimation={withAnimation}>
        {cars.map((car, index) => {
          const {
            details,
            policy,
            vendor,
            rate,
            key,
            isPreferred,
            isSelectable,
            pickUp,
            dropOff,
            safetyCheck,
            unlimitedMileage,
            serviceFee,
          } = car
          const { type, luggage, passenger, isAirConditioned, transmission, fuel } = details
          const isHybridFuel = fuel === CarRentalFuelType.Hybrid
          const customBadge = carPreferenceAttribute?.find(
            (attr) => attr.vendor.code === vendor.code,
          )?.preference?.customBadge?.name
          const hasServiceFee = Boolean(serviceFee)

          return (
            <div data-tracking-id={`car-item_${index}`} key={key}>
              <CarRentalSearchSegment
                details={{
                  class: details.class,
                  type,
                  luggage,
                  passenger,
                  isAirConditioned,
                  transmission,
                  unlimitedMileage,
                  fuel,
                }}
                pickUp={pickUp}
                dropOff={dropOff}
                policy={policy}
                vendor={vendor}
                carImage={details.carImageUrl || details.imageUrl}
                rate={rate}
                onCarRentalDetailsOpen={() => {
                  onCarRentalDetailsOpen({ carId: key, isSelectable, isHybridFuel })
                }}
                onAddCar={onAddCar}
                carRentalKey={key}
                isHybridFuel={isHybridFuel}
                isPreferred={!!isPreferred}
                safetyCheck={safetyCheck}
                isSelectable={isSelectable}
                carEmission={getCarRentalEmission({ carId: car.key, carEmissions })}
                customBadge={customBadge}
                hasServiceFee={hasServiceFee}
              />
            </div>
          )
        })}
      </InfiniteScroll.List>
    </Container>
  )
}
