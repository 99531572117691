import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const AmenityItem = styled.span`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
  & + & {
    &:before {
      content: '•';
      margin: 0 6px;
    }
  }
`

export const MoreText = styled(Text)`
  margin-left: 10px;
`
