import styled from 'styled-components'

import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'

export const InterceptModal = styled(Modal)`
  justify-content: center;
`

export const Intercept = styled.div`
  padding: 0 16px 16px 16px;
`

export const CloseButton = styled(IconButton)`
  align-self: flex-end;
`
