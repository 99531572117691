export enum PickerType {
  DATE = 'date',
  TIME = 'time',
  ONLY_MIN = 'only-min',
  ONLY_HOUR = 'only-hour',
  ONLY_DAY = 'only-day',
  ONLY_MONTH = 'only-month',
  ONLY_YEAR = 'only-year',
  TIMEZONE = 'timezone',
}
