import { useTranslation } from 'react-i18next'
import type { TripType } from '@etta/core/enums/trip-type.enum'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { CityImageUrlStore } from '@fiji/utils/city-image-url-store'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useFormattedDate } from './use-formatted-date'

type Props = {
  name?: string | null
  startDate: string
  endDate: string
  tripType: TripType
  cityImageUrl: string
  defaultBackground: string
}

const i18nBase = 'Trips'

export function useTripCard({
  name,
  startDate,
  endDate,
  tripType,
  cityImageUrl,
  defaultBackground,
}: Props) {
  const { postBookingTripPreviewActions } = usePostBookingContext()
  const {
    updateCityImageUrl,
    updateTimes,
    updateTitle,
    updateTripType,
  } = postBookingTripPreviewActions
  const { t } = useTranslation()

  const tripDateText = useFormattedDate({
    start: deleteTimezone(startDate),
    end: deleteTimezone(endDate),
  })

  const tripCardAriaText = t(i18nBase + '.TripItemAriaLabel', { title: name, date: tripDateText })
  const cityImageText = t(i18nBase + '.CityImage')

  const handleCardClick = () => {
    if (name) {
      updateTitle(name)
    }

    CityImageUrlStore.set(cityImageUrl)

    updateTimes({ startTime: startDate, endTime: endDate })

    updateTripType(tripType)

    updateCityImageUrl(cityImageUrl || defaultBackground)
  }

  return {
    tripCardAriaText,
    tripDateText,
    handleCardClick,
    cityImageText,
  }
}
