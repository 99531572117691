import styled from 'styled-components'

export const Element = styled.div`
  display: flex;
  padding: 0;
`

export const DateTimeContainer = styled.div`
  display: flex;
  padding-bottom: 18px;
`

export const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  padding-right: 24px;
`

export const LocationIconContainer = styled.div`
  padding-right: 29px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LocationIcon = styled.div<{ isPickUp: boolean }>`
  background: ${(p) => p.theme.colors.white};
  width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: ${(p) => (p.isPickUp ? '100%' : '0%')};
  border: 4px solid ${(p) => p.theme.colors.mainText1};
`

export const VerticalDelimiter = styled.div`
  width: 1px;
  height: 100%;
  min-height: 10px;
  background-color: ${(props) => props.theme.colors.mainText};
`

export const FixedVerticalDelimiter = styled.div`
  width: 1px;
  height: 33px;
  margin-left: 11.5px;
  background-color: ${(props) => props.theme.colors.mainText};
`
