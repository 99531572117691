import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { templates } from '@fiji/hooks/use-share-via-email'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Props = {
  tripName?: string | null
  hotel: HotelPostBookingValueObject
  startDate: string
  endDate: string
}

export function useActionButtons({ tripName, hotel, startDate, endDate }: Props) {
  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({ tripName, startDate, endDate }),
    body: templates.postBookingHotel({ hotel }),
  })

  return { handleShare }
}
