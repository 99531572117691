export enum SearchByType {
  Address = 'Address',
  Geocode = 'Geocode',
  Airport = 'Airport',
  Station = 'Station',
}

export enum RedirectPage {
  HoldTrip = 'HoldTrip',
  PurchaseMain = 'PurchaseMain',
}

export enum StyledTextVariant {
  largeTitle = 'largeTitle',
  title1 = 'title1',
  title2 = 'title2',
  title3 = 'title3',
  title4 = 'title4',
  headline = 'headline',
  subHeadMedium = 'subHeadMedium',
  subHeadStrong = 'subHeadStrong',
  captionMedium = 'captionMedium',
  captionStrongCaps = 'captionStrongCaps',
  body = 'body',
  bodyValue = 'bodyValue',
  bodyLarge = 'bodyLarge',
  calloutMedium = 'calloutMedium',
  calloutStrong = 'calloutStrong',
  footnoteMedium = 'footnoteMedium',
  footnoteStrong = 'footnoteStrong',
  captionStrong = 'captionStrong',
  buttonLarge = 'buttonLarge',
  buttonNormal = 'buttonNormal',
  buttonSmall = 'buttonSmall',
  linkNormal = 'linkNormal',
  linkSmall = 'linkSmall',
  tabs = 'tabs',
  regularText = 'regularText',
  smallText = 'smallText',
  mediumText = 'mediumText',
  largeText = 'largeText',
  smallTextSemiRegularCaps = 'smallTextSemiRegularCaps',
}

export enum Direction {
  OneWay = 'one-way',
  RoundTrip = 'round-trip',
  MultiCity = 'multi-city',
}

export enum STAGE_NAME {
  STAGE2 = 'stage2',
  QA10 = 'qa10',
  QA8 = 'qa8',
  QA9 = 'qa9',
  STAGE3 = 'stage3',
  RCSUSTAIN = 'rcsustain',
  PROD = 'prod',
  DEV = 'dev',
}

export enum PenaltyType {
  NonRefundable = 'NON_REFUNDABLE',
  Change = 'CHANGE',
  AdvancedPurchase = 'ADVANCED_PURCHASE',
  Cancel = 'CANCEL',
}

export enum TimeRangeOption {
  AnyTime = 'any-time',
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
  CustomTime = 'custom-time',
}

export enum TimeRangeDirection {
  Departure = 'DEPART',
  Arrival = 'ARRIVAL',
}

export enum FlightDirection {
  Origin = 'ORIGIN',
  Return = 'RETURN',
}

export enum CarTransmission {
  Any = 'ANY',
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum FlightLegType {
  Departure = 'departure',
  Return = 'return',
}

export enum RailLegType {
  Departure = 'departure',
  Return = 'return',
}

export enum GazooCarRentalType {
  Doors2To3 = 'DOORS2TO3',
  Doors2To4 = 'DOORS2TO4',
  Doors4To5 = 'DOORS4TO5',
  Coupe = 'COUPE',
  Convertible = 'CONVERTIBLE',
  Roadster = 'ROADSTER',
  Suv = 'SUV',
  Crossover = 'CROSSOVER',
  Van = 'VAN',
  PickupRegCab = 'PICKUP_REG_CAB',
  PickupExtCab = 'PICKUP_EXT_CAB',
  Special = 'SPECIAL',
  Automatic = 'AUTOMATIC',
}

export enum PlaceLocalStorageType {
  Air = 'air',
  Hotel = 'hotel',
  CarRental = 'carRental',
}

export enum HotelsSortBy {
  Preferred = 'preference',
  Policy = 'policy',
  Distance = 'distance',
  Price = 'price',
  Rating = 'starrating',
}

export enum TripServices {
  FLIGHT = 'FLIGHT',
  CAR_SERVICE = 'CAR_SERVICE',
  HOTEL = 'HOTEL',
}

export enum KeyType {
  Click = 'click',
  Other = 'other',
}

export enum CarLegType {
  PickUp = 'pick-up',
  DropOff = 'drop-off',
}

export enum MobilityLegType {
  PickUp = 'pick-up',
}

export enum PurchaseErrorList {
  IncompleteAddress = 'INCOMPLETE_ADDRESS',
  NoCVV = 'NO_CVV',
  OopCard = 'OOP_CARD',
  BrandType = 'BRAND_TYPE',
  BookingComBrandType = 'BOOKING_COM_BRAND_TYPE',
}

export enum ClientIdentifier {
  'Fiji' = 'fiji',
  'PWAMobile' = 'pwa-mobile',
  'PWADesktop' = 'pwa-desktop',
  'PWATablet' = 'pwa-tablet',
  'WebDesktop' = 'web-desktop',
  'WebTablet' = 'web-tablet',
  'WebMobile' = 'web-mobile',
  'Unspecified' = 'unspecified',
}

export enum JWTErrorCode {
  TokenExpiredError = 'TokenExpiredError',
  JsonWebTokenError = 'JsonWebTokenError',
}

export enum SearchLocationType {
  UNSPECIFIED = 0,
  AIRPORT = 1,
  CITY = 2,
  ADDRESS = 3,
  STATION = 4,
  HOTEL_PROPERTY = 5,
}

export enum AirTravelType {
  RoundTrip = 'RoundTrip',
  OneWay = 'Oneway',
  MultiCity = 'MultiCity',
}

export enum CarRentalFiltersType {
  Transmission = 'Transmission',
  Class = 'Class',
  Supplier = 'Supplier',
  Fuel = 'Fuel',
}

export enum DelegateSwitchLayout {
  Explore = 'explore',
  PostBooking = 'postBooking',
  TripDetails = 'tripDetails',
  Desktop = 'desktop',
}

export enum RailLayoutType {
  UK = 'UK',
  EU = 'EU',
}

export enum EmissionsDeviationLevel {
  Average,
  More,
  Less,
}

export enum RecommendedItemSlot {
  HomeToTransportHub = 'HOME_TO_TRANSPORT_HUB',
  TransportHubToHotel = 'TRANSPORT_HUB_TO_HOTEL',
  HotelToTransportHub = 'HOTEL_TO_TRANSPORT_HUB',
  TransportHubToHome = 'TRANSPORT_HUB_TO_HOME',
}

export enum AlgoliaLocationType {
  Station = 'Station',
  StationGroup = 'StationGroup',
}

export enum PostBookingAction {
  Add = 'add',
  Modify = 'modify',
  ModifyReturn = 'modify_return',
}

export enum TrainServiceClass {
  Standard = 'Standard',
  FirstClass = 'FirstClass',
}

export enum TrainServiceActiveTab {
  Standard = 'standard',
  FirstClass = 'firstclass',
}

export enum TrainDeliveryOptionCode {
  Eticket = 'ETK',
  Collect = 'TVM',
}

export enum TrainLabel {
  Fastest = 'Fastest',
  Cheapest = 'Cheapest',
}

export enum TrainDeliveryOptionCodeRTP {
  Eticket = 'AtocETicket',
  Collect = 'AtocKiosk',
}

export enum RideHailSuggestedLocationType {
  AIRPORT = 'AIRPORT',
  HOME = 'HOME',
  HOTEL = 'HOTEL',
  UNSPECIFIED = 'UNSPECIFIED',
}
