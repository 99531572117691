import { Badge } from '@etta/ui/badge'
import { Swap } from '@etta/ui/swap'
import { Skeleton } from '@etta/ui/skeleton'
import { EmissionDeviation } from '@etta/components/emission-deviation/emission-deviation'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Tooltip } from '@etta/ui/tooltip'
import { CarRentalDetailsDesktopTooltipOopReasons } from '@etta/components/car-rental-details/layout/desktop/car-rental-details-desktop-tooltip-oop-reasons'
import type { Props } from '../../types'
import { InfoFooter, CarRentalEmissionDeviationContainer } from './desktop-styled'

export function CarFooterBlockDesktop({ isLoading, policy, carEmission }: Props) {
  const { featureFlags } = useFeatureFlags()
  return (
    <InfoFooter>
      <Swap
        is={isLoading}
        isSlot={
          <Skeleton width="100%" height="13px">
            <rect width="86px" height="13px" />
          </Skeleton>
        }>
        {!policy?.isInPolicy && (
          <Tooltip slot={<CarRentalDetailsDesktopTooltipOopReasons carPolicy={policy} />}>
            <Badge variant="outOfPolicy" />
          </Tooltip>
        )}
        {featureFlags.isSustainabilityEnabled && (
          <CarRentalEmissionDeviationContainer>
            <EmissionDeviation {...carEmission} />
          </CarRentalEmissionDeviationContainer>
        )}
      </Swap>
    </InfoFooter>
  )
}
