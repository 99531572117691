/* eslint-disable react-hooks/exhaustive-deps */
import type { MutableRefObject } from 'react'
import { useCallback, useEffect } from 'react'

type Handlers = {
  [index: string]: (e: Event) => void
}

function addHandlers(node: HTMLElement, handlers: Handlers) {
  Object.keys(handlers).forEach((eventName) => {
    node.addEventListener(eventName, handlers[eventName])
  })
}

function removeHandlers(node: HTMLElement, handlers: Handlers) {
  Object.keys(handlers).forEach((eventName) => {
    node.removeEventListener(eventName, handlers[eventName])
  })
}

export function useContainer(ref: MutableRefObject<HTMLElement | null>, handlers: Handlers) {
  const scrollContainerRef = useCallback((node) => {
    if (ref.current) {
      removeHandlers(ref.current, handlers)
    }
    if (node) {
      ref.current = node
      addHandlers(node, handlers)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!ref.current) {
        return
      }
      removeHandlers(ref.current, handlers)
    }
  }, [])

  return scrollContainerRef
}
