import { STORAGE_KEY } from './consts'

export function removeExpiredItinerary() {
  const keys = Object.keys(localStorage)

  const itineraryKeys = keys.filter((key) => key.startsWith(STORAGE_KEY))

  itineraryKeys.forEach((key) => {
    const { expTime } = JSON.parse(localStorage.getItem(key) || '{}')
    if (expTime < Date.now()) {
      localStorage.removeItem(key)
    }
  })
}
