import { Store, Inject } from '@etta/di'
// TODO: remove graphql import
// eslint-disable-next-line import/no-restricted-paths
import { SeatmapCabinClass } from '@fiji/graphql/types'
// TODO: remove fiji import
// eslint-disable-next-line import/no-restricted-paths
import type { CabinClassType } from '@fiji/types'
// TODO: remove fiji import
import { cabinClasses } from '@fiji/constants'
// eslint-disable-next-line import/no-restricted-paths
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores'

@Store()
export class CabinClassConfigurationStore {
  constructor(
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  get cabinClassesConfig() {
    const {
      isBusinessClassEnabled,
      isEconomyClassEnabled,
      isFirstClassEnabled,
      isPremiumEconomyClassEnabled,
    } = this.displayConfigurationStore.flightConfigurations
    return [
      {
        cabinClass: SeatmapCabinClass.Coach,
        isEnabled: isEconomyClassEnabled,
      },
      {
        cabinClass: SeatmapCabinClass.PremiumCoach,
        isEnabled: isPremiumEconomyClassEnabled,
      },
      {
        cabinClass: SeatmapCabinClass.Business,
        isEnabled: isBusinessClassEnabled,
      },

      {
        cabinClass: SeatmapCabinClass.First,
        isEnabled: isFirstClassEnabled,
      },
    ]
  }

  get cabinClassOptions() {
    const cabinClassesOptions = this.cabinClassesConfig.reduce((acc: CabinClassType[], config) => {
      const cabinClass = cabinClasses[config.cabinClass]

      if (!config.isEnabled) {
        return acc
      }
      acc.push(cabinClass)
      return acc
    }, [])

    return cabinClassesOptions
  }

  get defaultCabinClass() {
    return this.cabinClassOptions[0] || cabinClasses[SeatmapCabinClass.Coach]
  }
}
