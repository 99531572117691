import { Icon } from '@etta/ui/icon'
import { DownloadLabel, Footer } from './trip-costs-sidebar-styled'

type Props = {
  ariaLabel: string
  label: String
  onOpen: () => void
}

export function DownloadETicketButton({ label, ariaLabel, onOpen }: Props) {
  return (
    <Footer
      aria-label={ariaLabel}
      fullWidth
      variant="text"
      paddings={false}
      data-tracking-id="download-e-ticket-trip-button"
      leftIcon={
        <>
          <Icon color="primary" name="eticketPWA" />
          <DownloadLabel color="primary" variant="subHeadStrong">
            {label}
          </DownloadLabel>
        </>
      }
      color={undefined}
      onClick={onOpen}
    />
  )
}
