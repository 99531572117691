export function checkIsPwa() {
  const isStandaloneApp =
    'matchMedia' in window && window.matchMedia('(display-mode: standalone)').matches
  const isPwaSource = document.location.search.includes('?source=pwa')

  const isDevelopment = process.env.NODE_ENV === 'development'

  const isPwa = isStandaloneApp || isPwaSource || isDevelopment || false
  return isPwa
}
