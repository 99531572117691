import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { Container, ItemWrapper, HeaderWrapper, Left } from '../../rail-result-skeleton-styled'

function SkeletonItem() {
  return (
    <>
      <ItemWrapper>
        <HeaderWrapper>
          <Left>
            <Skeleton width="100%" height="99px">
              <rect width="210px" height="19px" />
              <rect width="93px" height="26px" y={45} />
              <rect width="149px" height="19px" y={85} />
            </Skeleton>
          </Left>
          <Block isFlexBlock marginRight={140}>
            <Skeleton width="83px" height="17px">
              <rect width="100%" height="100%" />
            </Skeleton>
          </Block>
          <Block isFlexBlock marginRight={140}>
            <Skeleton width="83px" height="17px">
              <rect width="100%" height="100%" />
            </Skeleton>
          </Block>
        </HeaderWrapper>
      </ItemWrapper>
    </>
  )
}

export function RailResultSkeletonDesktop() {
  return (
    <Container>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </Container>
  )
}
