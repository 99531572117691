import { MediaLayout } from '@etta/ui/media-layout'
import type { Document } from '@etta/modules/booking/interface/checkout-info/documents/checkout-info-documents.store'
import { TravelerDocumentsListDesktop } from './desktop'
import { TravelerDocumentsListMobile } from './mobile'

export type Props = {
  documents: Document[]
}

export function TravelerDocumentsListLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TravelerDocumentsListMobile {...props} />}
      desktopSlot={<TravelerDocumentsListDesktop {...props} />}
    />
  )
}
