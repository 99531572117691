import { clone } from 'ramda'
import { appMode } from '@fiji/modes'
import { DEFAULT_LOCALE } from '@fiji/constants'
import type { User, Currency } from './types'

const initialState: User = {
  locale: DEFAULT_LOCALE,
  trustedLocale: undefined,
  currency: undefined,
  customerId: undefined,
  companyId: undefined,
  partnerId: undefined,
  currencyList: [
    { value: 'primary', name: '' },
    { value: 'secondary', name: '' },
  ],
  selectedCurrency: { value: 'primary', name: '' },
  shouldUpdateCurrency: true,
  sessionTimeout: 0,
  delegatedId: undefined,
}

export function getInitialState() {
  return clone(initialState)
}

export const initialBrandConfiguration = {
  isLoading: false,
  isThemeEnabled: false,
  theme: appMode.isAppleTravel ? 'kan' : 'etta',
  brandTitleLabel: '',
  termsOfUseLinkEnabled: false,
}

export const initialUser: User = {
  locale: DEFAULT_LOCALE,
  trustedLocale: undefined,
  currency: undefined,
  profile: {
    site: {
      isSelfServiceSite: false,
    },
    personalContact: {
      address: {
        city: 'San Francisco',
        countryCode: 'US',
        postalCode: '94127',
        stateCode: 'CA',
        street1: '600 Gennessee Street',
      },
    },
  },
  currencyList: [
    { value: 'primary' as Currency['value'], name: '' },
    { value: 'secondary' as Currency['value'], name: '' },
  ],
  selectedCurrency: { value: 'primary' as Currency['value'], name: 'USD' },
  shouldUpdateCurrency: true,
  sessionTimeout: 0,
}

export const initialSupportInformation = {
  phoneNumbers: [
    {
      label: 'AMR Region support',
      phoneNumber: '12-234567-78',
    },
    {
      label: 'APAC Region support',
      phoneNumber: '6264748498',
    },
    {
      label: 'EMEA Region support',
      phoneNumber: '1800-23849-28377',
    },
    {
      label: 'Help Desk- Apple',
      phoneNumber: '23-05585777878',
    },
  ],
  email: {
    label: 'Support Mail address',
    emailAddress: 'support@deem.com',
  },
}
