import { Text } from '@etta/ui/text'

type Props = {
  noContentInfoTranslation: string
  tripTypeTranslation: string
  tripsTranslation: string
}

export function DescriptionContent({
  noContentInfoTranslation,
  tripsTranslation,
  tripTypeTranslation,
}: Props) {
  return (
    <>
      <div>
        <Text variant="body">{noContentInfoTranslation}</Text>
      </div>
      <Text variant="body" textTransform="lowercase">
        {tripTypeTranslation} {tripsTranslation}
      </Text>
    </>
  )
}
