import { Maybe } from 'fnscript'
import type {
  CalculateTripEmissionsFlightInput,
  CalculateTripEmissionsHotelInput,
  CalculateTripEmissionsCarInput,
  CalculateTripEmissionsRailInput,
} from '@fiji/graphql/hooks'
import { mapVehicleCode } from '@fiji/utils/thrust-carbon/map-vehicle-code'
import { mapToThrustCarbonCabinClass } from '@fiji/utils/thrust-carbon/map-to-thrust-carbon-cabin-class'
import type {
  SegmentPostBookingValueObject,
  HotelPostBookingValueObject,
  CarRentalPostBookingValueObject,
  FlightPostBookingValueObject,
  TrainPostBookingValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { SegmentType } from '@etta/core/enums'
import type { TrainServiceClass } from '@fiji/enums'
import { mapToThrustCarbonRailClass } from '../use-rail-emissions/map-to-thrust-carbon-rail'

export function toCalculateTripEmissionsInput(segments: SegmentPostBookingValueObject[]) {
  const flights: CalculateTripEmissionsFlightInput[] = []
  const hotels: CalculateTripEmissionsHotelInput[] = []
  const cars: CalculateTripEmissionsCarInput[] = []
  const rails: CalculateTripEmissionsRailInput[] = []

  segments.forEach((segment) => {
    if (segment.type === SegmentType.Hotel) {
      const tcHotelSegment = mapTripHotelToThrustCarbonHotel(segment)
      hotels.push(tcHotelSegment)
      return
    }

    if (segment.type === SegmentType.CarRental) {
      const tcCarRentalSegment = mapTripCarRetalToThrustCarbonCarRental(segment)
      cars.push(tcCarRentalSegment)
      return
    }

    if (segment.type === SegmentType.Flight) {
      const tcFlightSegment = mapTripFlightToThrustCarbonFlight(segment)
      flights.push(tcFlightSegment)
    }

    if (segment.type === SegmentType.Train) {
      const filteredSegments = segment.segments?.filter((trainSegment) => {
        return !(
          trainSegment.stations?.arrival?.location?.countryCode === 'US' ||
          trainSegment.stations?.arrival?.location?.countryCode === 'CA'
        )
      })

      const trainSegmentWithFilteredSegments = {
        ...segment,
        segments: filteredSegments,
      }

      if (filteredSegments && filteredSegments.length) {
        const tcRailSegment = mapTripRailToThrustCarbonRail(trainSegmentWithFilteredSegments)
        rails.push(tcRailSegment)
      }
    }
  })

  return {
    flights,
    hotels,
    cars,
    rails,
  }
}

function mapTripFlightToThrustCarbonFlight(
  flight: FlightPostBookingValueObject,
): CalculateTripEmissionsFlightInput {
  // We expect the values to always be present.
  // Any issues related to this query is tracked via Rollbar
  const segments = flight.segments?.map((segment) => {
    return {
      aircraftType: segment.plane?.code,
      carrier: segment.carrierCode,
      class: mapToThrustCarbonCabinClass(segment.serviceClass as string),
      departureDateTime: segment.departureDate as string,
      flightNumber: segment.flightNumber as number,
      originAirportCode: segment.departure?.airportCode as string,
      destinationAirportCode: segment.arrival?.airportCode as string,
    }
  })

  return { customRef: flight.legId, segments: segments || [] }
}

function mapTripCarRetalToThrustCarbonCarRental(
  carRental: CarRentalPostBookingValueObject,
): CalculateTripEmissionsCarInput {
  // We expect the values to always be present.
  // Any issues related to this query is tracked via Rollbar
  return {
    customRef: carRental.carId,
    origin: {
      lat: carRental.pickUpLocation?.address?.geocode?.lat as number,
      long: carRental.pickUpLocation?.address?.geocode?.long as number,
    },
    destination: {
      lat: carRental.dropOffLocation?.address?.geocode?.lat as number,
      long: carRental.dropOffLocation?.address?.geocode?.long as number,
    },
    pickUpDateTime: carRental.pickUpTime as string,
    vehicleCode: mapVehicleCode(carRental.carId as string),
    days: carRental.daysCount as number,
  }
}

function mapTripHotelToThrustCarbonHotel(
  hotel: HotelPostBookingValueObject,
): CalculateTripEmissionsHotelInput {
  // We expect the values to always be present.
  // Any issues related to this query is tracked via Rollbar
  return {
    customRef: hotel.hotelId,
    name: hotel.name as string,
    rating: Maybe.from(hotel.starRating).withDefault(0),
    location: {
      lat: hotel.address?.geocode?.lat as number,
      long: hotel.address?.geocode?.long as number,
    },
    checkIn: hotel.checkIn as string,
    nights: hotel.numberOfNights,
    hotelId: hotel.hotelId || '',
  }
}

export function mapTripRailToThrustCarbonRail(
  rail: TrainPostBookingValueObject,
): CalculateTripEmissionsRailInput {
  const segments = rail.segments?.map((segment) => {
    // We expect the values to always be present.
    // Any issues related to this query is tracked via Rollbar
    return {
      class: mapToThrustCarbonRailClass(segment.serviceClassName as TrainServiceClass),
      departureDate: segment.departureDate || '',
      destinationStationCode: segment.arrivalStationCode || '',
      originStationCode: segment.departureStationCode || '',
    }
  })

  return {
    customRef: rail.id,
    segments: segments || [],
  }
}
