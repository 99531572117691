import { Trans } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import { RAIL_CHECK_STATIONS_LINK } from '@fiji/constants'

import { Wrapper } from './rail-collect-ticket-disabled.styled'

type Props = {
  stationName?: string
}

const LinkElement = <Link size="small" target="_blank" href={RAIL_CHECK_STATIONS_LINK} />

export function RailCollectTicketDisabled({ stationName }: Props) {
  return (
    <Wrapper>
      <Text variant="footnoteMedium" color="mainText">
        <Trans
          values={{ stationName }}
          i18nKey={'RailDeliveryOption.CollectTicketMachineDisabled'}
          components={[LinkElement]}
        />
      </Text>
    </Wrapper>
  )
}
