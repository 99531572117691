import { useMemo } from 'react'
import type { UnusedTicket, ValidatedTicket } from '@fiji/graphql/types'

type Args = {
  unusedTicket?: UnusedTicket | null
}
export const useUnusedTicketRate = ({ unusedTicket }: Args): ValidatedTicket | null => {
  return useMemo(() => {
    if (!unusedTicket?.validated) {
      return null
    }

    return unusedTicket?.validated
  }, [unusedTicket])
}
