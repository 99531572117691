import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DelegateList } from '@etta/components/delegate-list/delegate-list'
import { TextField } from '@etta/ui/text-field'
import { DelegateModalLayout } from './delegate-modal-layout'
import type { Props } from './types'
import { useDelegateModal } from './hooks/use-delegate-modal'

const i18nPath = 'Delegate.'

export const DelegateModal = observer(function DelegateModal({
  delegates,
  isModalVisible,
  handleClose,
  handleSelectDelegate,
  isLoading,
}: Props) {
  const { t } = useTranslation()

  const {
    delegateFilter,
    filteredDelegates,
    handleDelegatesFilter,
    handleDelegatesModalClose,
    handleConfirm,
    handlePreSelect,
    anchorRef,
    scrollContainerRef,
    isConfirmUserButtonDisabled,
    choosenDelegateId,
    confirmationDialog,
  } = useDelegateModal({ delegates, handleSelectDelegate, handleClose })

  const searchText = t(i18nPath + 'SearchText')
  const searchLabel = t(i18nPath + 'SearchLabel', { label: searchText })

  return (
    <DelegateModalLayout
      confirmationDialog={confirmationDialog}
      isConfirmButtonDisabled={isConfirmUserButtonDisabled}
      isModalVisible={isModalVisible}
      scrollContainerRef={scrollContainerRef}
      anchorSlot={<div ref={anchorRef} />}
      handleConfirm={handleConfirm}
      handleClose={handleDelegatesModalClose}
      isLoading={isLoading}
      delegatesSlot={
        <DelegateList
          delegates={filteredDelegates}
          handleSelectDelegate={handlePreSelect}
          choosenDelegateId={choosenDelegateId}
        />
      }
      searchSlot={
        <TextField
          type="search"
          value={delegateFilter}
          size="small"
          onChange={handleDelegatesFilter}
          placeholder={searchText}
        />
      }
      searchLabel={searchLabel}
    />
  )
})
