import { CustomField } from '@etta/ui/custom-field'
import { Form } from '@etta/ui/form'
import type { FormProps } from './types'

export function PreSearchCustomFieldsForm({
  customFields,
  errors,
  onFieldChange,
  values,
  onSubmit,
}: FormProps) {
  return (
    <Form onSubmit={onSubmit}>
      {customFields.map((field) => (
        <CustomField
          field={field}
          onChange={onFieldChange}
          values={values}
          key={field.collectionId}
          errors={errors}
        />
      ))}
    </Form>
  )
}
