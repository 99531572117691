import { useTranslation } from 'react-i18next'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CabinClassType } from '@fiji/types'

const i18Base = 'TripPlanner.BaseSearch'

type Props = {
  cabinClass: CabinClassType
}

export function useCabinClass({ cabinClass }: Props) {
  const { t } = useTranslation()
  const { getCabinClassLabel } = useCabinClassLabels()
  const label = t(i18Base + '.Input.CabinClass')
  // WARNING: making the id requered will fail explore-page.a11y-test.ts
  const value = getCabinClassLabel(cabinClass?.id)!
  const cabinClassModal = useTogglePopup()

  return {
    label,
    value,
    cabinClassModal,
  }
}
