/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'

import type { SetFields, MapUnion } from './types'

export function useFieldChange<T extends MapUnion = MapUnion>(
  setErrors: SetFields<T>,
  setValues: SetFields<T>,
) {
  return useCallback(
    (fieldName: keyof T) => (fieldValue: any) => {
      setErrors((prevErrors) => {
        if (!prevErrors[fieldName]) {
          return prevErrors
        }

        return {
          ...prevErrors,
          [fieldName]: undefined,
        }
      })
      setValues((prevState) => {
        if (process.env.NODE_ENV === 'development' && !(fieldName in prevState)) {
          throw new Error(
            `You are trying to set not defined field "${String(
              fieldName,
            )}" in scheme, please check scheme definition`,
          )
        }
        return {
          ...prevState,
          [fieldName]: fieldValue,
        }
      })
    },
    [],
  )
}
