import { useCallback, useState } from 'react'
import type { Location } from '@etta/components/hotel-map/map-locations/types'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

export function useSearchAreaBlock() {
  const { loadHotelsAction } = useHotelSearchResultsContext()

  const [isNewAreaSearchVisible, setSearchVisibility] = useState(false)
  const [centerBounds, setCenterBounds] = useState<Location>()
  const onBoundsLeave = useCallback((newCenter: Location) => {
    setSearchVisibility(true)
    setCenterBounds(newCenter)
  }, [])

  const onBoundsReturn = useCallback(() => {
    setSearchVisibility(false)
  }, [])

  const onNewAreaSearchClick = useCallback(() => {
    if (!centerBounds) {
      return
    }
    loadHotelsAction.loadHotelsByNewArea(centerBounds)
    setSearchVisibility(false)
  }, [centerBounds, loadHotelsAction])

  return {
    onBoundsLeave,
    onBoundsReturn,
    onNewAreaSearchClick,
    isNewAreaSearchVisible,
  }
}
