import { InputContainer, SelectTextField } from './search-box-styled'

type Props = {
  searchText: string
  onChange: (searchText: string) => void
  placeholder: string
  ariaLabel?: string
  tabIndex?: number
}

export const SearchBox = ({ searchText, onChange, placeholder, ariaLabel, tabIndex }: Props) => {
  return (
    <InputContainer>
      <SelectTextField
        type="search"
        tabIndex={tabIndex}
        autoFocus
        data-tracking-id="search-hotel-mechanism-search-box"
        value={searchText}
        onChange={onChange}
        placeholder={placeholder}
        aria-label={ariaLabel || ''}
        size="small"
      />
    </InputContainer>
  )
}
