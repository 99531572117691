import { Spinner } from '@etta/ui/spinner'
import { appMode } from '@fiji/modes'
import { SpinnerContainer } from './app-spinner-styled'

export function AppSpinner() {
  if (appMode.isFijiAll) {
    return <Spinner size="large" />
  }

  return (
    <SpinnerContainer>
      <Spinner type="spinout" />
    </SpinnerContainer>
  )
}
