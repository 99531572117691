import styled from 'styled-components'
import {
  captionStrong,
  title2,
  calloutMedium,
  getKeyboardNavigationStyle,
  linkSmall,
} from '@fiji/style'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-flow: column;
`

export const BackgroundImage = styled.img<{ isWithDisclaimer: boolean }>`
  border-radius: ${(props) => (!props.isWithDisclaimer ? '0 14px 14px 0' : 'unset')};
  position: absolute;
  z-index: 1;
  right: 0;
`

export const LeftBlock = styled.div`
  padding: 15px 24px;
  z-index: 10;
`

export const Title = styled.p`
  color: ${(props) => props.theme.colors.bodyText};
  display: inline-block;
  margin: 0;
  ${captionStrong};
  font-weight: 700;
`

export const EmissionsSummary = styled.p`
  margin: 0;
  ${title2};
  margin-top: 5px;
`

export const ModalButton = styled.button`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 7px;
  cursor: pointer;
  background: transparent;
  border: none;
  ${calloutMedium};
  ${getKeyboardNavigationStyle({ offset: 2, borderRadius: 3 })}
`

export const ModalButtonText = styled.div`
  margin: 0;
  ${linkSmall};
`

export const IconRelativeContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const IconAbsoluteContainer = styled.div`
  position: absolute;
  bottom: -2px;
`

export const EcoCheckWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const DisclaimerMessageContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  display: flex;
  padding: 12px 16px;
  align-items: center;
`

export const DisclaimerMessageText = styled(Text)`
  margin-left: 5px;
`
