import type { FeatureFlagsValueObject } from '@etta/modules/feature-flags/core/value-object/feature-flags.value-object'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import { updateFeatureFlagsValues } from '@fiji/store/feature-flags'
import { Store, Inject, Container } from '@etta/di'

@Store()
export class FeatureFlagsStore {
  isLoading: boolean = false

  isLoaded: boolean = false

  flags: FeatureFlagsValueObject = {
    isAddTrainToItineraryEnabled: false,
    isFlightSafetyCheckEnabled: false,
    isHotelSafetyCheckEnabled: false,
    isHotelRateCapEnabled: false,
    isDelegatedFlowEnabled: false,
    isDesktopDelegatedFlowEnabled: false,
    isDesktopLayoutFlightFlowEnabled: false,
    isDesktopLayoutHotelFlowEnabled: false,
    isDesktopLayoutPurchaseFlowEnabled: false,
    isDesktopLayoutRTPFlowEnabled: false,
    isDesktopLayoutTripsFlowEnabled: false,
    isDesktopTripCartEnabled: false,
    isEttaAutoTranslationEnabled: false,
    isFijiPurchaseFixEnabled: false,
    isMultiCityEnabled: false,
    isPasswordExpirationFlowEnabled: false,
    isRailServiceEnabled: false,
    isSSOEnabled: false,
    isSplitCostAllocationEnabled: false,
    isSustainabilityEnabled: false,
    isUberEnabled: false,
    isReserveAirportPickupEnabled: false,
    isMobilityExpenseEnabled: false,
    isMobilityZoningEnabled: false,
    isMobilityDesktopEnabled: false,
    isMobilityMultiModalEnabled: false,
    isMobilitySearchFormV3Enabled: false,
    isMobilityBookTabEnabled: false,
    isUnusedTicketsEnabled: false,
    isDesktopUnusedTicketsEnabled: false,
    isDesktopLayoutTrainTabEnabled: false,
    isDesktopLayoutMultiCityEnabled: false,
    isRailSearchFiltersEnabled: false,
    isRailSearchSortEnabled: false,
    isRailAccreditationEnabled: false,
    isRailCardSelectionEnabled: false,
    isRailOutofPolicyEnabled: false,
    isMobileHeldTripsFlowEnabled: false,
    isKeyboardNavigationStylesEnabled: false,
    isDesktopHeldTripsFlowEnabled: false,
    isWhatFixPWAEnabled: false,
    isToggleEttaThemeEnabled: false,
    isFeedbackEnabled: false,
    isTripExpiredDateGeneratedByUTCEnable: false,
    isRecentSearchesEnabled: false,
    isAppleMapProviderEnabled: false,
    isUnusedTicketsOptOutEnabled: false,
    isDesktopUnusedTicketsOptOutEnabled: false,
    isCustomLogoutRedirectEnabled: false,
    isOnHoldEnabled: false,
    isSettingsPageInformationSectionEnabled: false,
    isSettingsPageSettingsSectionEnabled: false,
    isSettingsPageOrganizationSectionEnabled: false,
    isRedirectOnSiteDomainEnabled: false,
    isNewAirFiltersModalEnabled: false,
    isNewAirExposedFiltersEnabled: false,
    isNewHotelExposedFiltersEnabled: false,
    isNewHotelFiltersModalEnabled: false,
    isAppleBannerEnabled: false,
    isMobilityOnDemandRidesEnabled: false,
    isBookItineraryTravelPreferencesEnabled: false,
    isSsoSettingsEnabled: false,
    isAlertsEnabled: false,
    isCarRentalsNewFiltersEnabled: false,
    isRecentSearchesFilteringEnabled: false,
    isExternalLinksEnabled: false,
    isActiveItineraryPurchaseFlowEnabled: false,
    isHotelRetryWorkflowEnabled: false,
    isNewCheckoutPageLayoutEnabled: false,
    isTripAlignmentWorkflowEnabled: false,
    isChangingReturnFlightEnabled: false,
    isBookAgainAllowed: false,
    isNewEttaOnlyEnabled: false,
    isActivateAccountFlowEnabled: false,
    isNewHelpCenterLinkEnabled: false,
    isBookAgainDelegateFlowEnabled: false,
    isNewTripDetailsPageEnabled: false,
    isNewLoadingConfirmationEnabled: false,
    isOOPModalOnSearchResultFlightPageEnabled: true,
    isServiceFeeEnabled: false,
    isUserActivationEnabled: false,
    isQualtricsEnabled: false,
    isOOPModalEnabled: false,
    isPWACookieConsentEnabled: false,
    isNewEmissionsBrandingEnabled: false,
    isRetailShoppingUIEnabled: false,
  }

  constructor(
    @Inject()
    private readonly fijiStoreProxy: FijiStoreProxyService,
  ) {}

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsLoaded(value: boolean) {
    this.isLoaded = value
  }

  setFlags(newFlags: FeatureFlagsValueObject) {
    this.flags = newFlags
    // TODO: remove after migration
    this.fijiStoreProxy.dispatch(
      updateFeatureFlagsValues({
        values: newFlags,
      }),
    )
  }

  static getInstance() {
    return Container.get(FeatureFlagsStore)
  }
}
