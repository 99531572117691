import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { CovidIconContainer, CovidEmptyDescription } from './covid-information-empty-styled'
import { default as CovidIcon } from './covid-icon.svg'

const i18nBase = `HotelSafetyCheck.CovidInformation.CovidInformationContent.CovidInformationEmpty`

export function CovidInformationEmpty() {
  const { t } = useTranslation()

  return (
    <div>
      <CovidIconContainer>
        <CovidIcon />
      </CovidIconContainer>
      <CovidEmptyDescription paddingHorizontal={16} isFullWidth isFlexBlock>
        <Text variant="subHeadMedium" color="mainText" align="center" tabIndex={0}>
          {t(`${i18nBase}.Empty`)}
        </Text>
      </CovidEmptyDescription>
    </div>
  )
}
