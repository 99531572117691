import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import type { Props } from '../../types'
import { Container, Footer, MainSection } from './car-rental-card-skeleton-mobile-styled'

export function CarRentalCardSkeletonMobile({ className }: Props) {
  return (
    <Container className={className}>
      <MainSection>
        <Block>
          <Skeleton width="100%" height="78px">
            <rect width="58px" height="14px" />
            <rect y={30} width="158px" height="14px" />
            <rect y={59} width="38px" height="14px" />
          </Skeleton>
        </Block>
        <Block>
          <Skeleton width="108px" height="78px">
            <rect y={7} width="100%" height="66px" />
          </Skeleton>
        </Block>
      </MainSection>
      <Footer>
        <Skeleton width="128px" height="14px">
          <rect width="128px" height="14px" />
        </Skeleton>
      </Footer>
    </Container>
  )
}
