import type { Hotel } from '@fiji/graphql/types'
import { useOutOfPolicyMessages } from '@fiji/hooks/use-out-of-policy-messages'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal/types'

export function useOutOfPolicyHotelData(hotel: Hotel | HotelDetailsType) {
  const { policy, rooms } = hotel

  const { messages } = useOutOfPolicyMessages({ outOfPolicyData: policy?.outOfPolicyData })
  const outOfPolicyReasons = policy?.outOfPolicyReasons
  const outOfPolicyTexts = outOfPolicyReasons
    ? outOfPolicyReasons.map((code) => messages[code])
    : []

  return {
    isOutOfPolicy: policy ? !policy.isInPolicy : false,
    isAllRoomsOutOfPolicy: !!rooms?.every((room) => !room.policy?.isInPolicy),
    outOfPolicyTexts,
  }
}
