import styled from 'styled-components'
import { captionMedium, footnoteMedium } from '@fiji/style'

export const LegendItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.bodyText};
  ${captionMedium}
`

export const LegendContainer = styled.div<{ isPreferredInOneLine?: boolean }>`
  position: relative;
  width: 100%;
  z-index: 2;
  min-height: 64px;
  display: flex;
  background-color: #fff;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  ${({ isPreferredInOneLine, theme }) =>
    isPreferredInOneLine &&
    `
    border: none;
    background-color: ${theme.colors.background};
    flex-wrap: wrap;
    gap: 16px;
    margin-top: auto;
    &::after {
      content: '';
      flex: auto;
    }
    ${LegendItem} {
      flex-direction: row;
      gap: 6px;
      justify-content: flex-start;
      ${footnoteMedium}
    }
  `}
`

export const IconContainer = styled.div`
  width: 29.5px;
  height: 29.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
`
