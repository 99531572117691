import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { Wrapper } from './autocancellation-message-desktop-styled'

export function AutoCancellationMessageDesktop({ translationSlot }: LayoutProps) {
  return (
    <Wrapper>
      <Text variant={'footnoteMedium'} color={'mainText'}>
        {translationSlot}
      </Text>
    </Wrapper>
  )
}
