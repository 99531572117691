import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { HotelDistanceSectionMobile } from './mobile'
import { HotelDistanceSectionDesktop } from './desktop'

export function HotelDistanceSectionLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<HotelDistanceSectionMobile {...props} />}
      desktopSlot={<HotelDistanceSectionDesktop {...props} />}
    />
  )
}
