import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { ConfirmationDialogMobile } from './mobile'
import { ConfirmationDialogDesktop } from './desktop'

export function ConfirmationDialogLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ConfirmationDialogMobile {...props} />}
      desktopSlot={<ConfirmationDialogDesktop {...props} />}
    />
  )
}
