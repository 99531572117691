import { Inject, Service } from '@etta/di'
import { RouterHistory } from './router-history'

@Service()
export class HistoryService<T = unknown> {
  constructor(
    @Inject()
    private routerHistory: RouterHistory<T>,
  ) {}

  push(route: string, state?: T) {
    this.routerHistory.history?.push(route, state)
  }

  replace(route: string, state?: T) {
    this.routerHistory.history?.replace(route, state)
  }

  goBack() {
    this.routerHistory.history?.goBack()
  }

  asyncPush(route: string, callback: () => void) {
    this.push(route)
    setTimeout(callback, 0)
  }
}
