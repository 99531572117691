import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Gender, UserTitle, UserSuffix } from '@fiji/graphql/types'

type Args = {
  genders?: Gender[]
  titles?: UserTitle[]
  suffixes?: UserSuffix[]
}

const i18nBase = 'UserInfo.'
const labelsBase = i18nBase + 'Labels.'
const suffixesBase = i18nBase + 'Suffixes.'
const gendersBase = i18nBase + 'Genders.'

export function useProfileOptions({ genders, titles, suffixes }: Args) {
  const { t } = useTranslation()

  const genderOptions = useMemo(() => {
    if (!genders) {
      return []
    }

    return genders.map((gender) => ({
      label: t(gendersBase + gender),
      value: gender,
    }))
  }, [t, genders])

  const titleOptions = useMemo(() => {
    if (!titles) {
      return []
    }

    return titles.map((title) => ({
      label: t(labelsBase + title),
      value: title,
    }))
  }, [t, titles])

  const suffixOptions = useMemo(() => {
    if (!suffixes) {
      return []
    }

    return suffixes.map((suffix) => ({
      label: t(suffixesBase + suffix),
      value: suffix,
    }))
  }, [t, suffixes])

  return {
    suffixes: suffixOptions,
    titles: titleOptions,
    genders: genderOptions,
  }
}
