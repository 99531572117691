import { useLayoutEffect, useRef } from 'react'
import type { BaseTextFieldProps } from './base-text-field'
import { BaseTextField } from './base-text-field'
import { Password } from './password'
import { SearchTextField } from './search-text-field'
import { numeric, decimals, phone, cvv } from './parsers'

function TextElementComponent({ type = 'text', ...props }: BaseTextFieldProps) {
  switch (type) {
    default:
    case 'text':
      return <BaseTextField {...props} type="text" />

    case 'hidden':
      return <BaseTextField {...props} type="hidden" />

    case 'email':
      return <BaseTextField {...props} type="email" inputMode="email" />

    case 'phone':
      return (
        <BaseTextField {...props} type="phone" valueParser={phone} inputMode="tel" maxLength={15} />
      )
    case 'numeric':
      return <BaseTextField {...props} type="numeric" valueParser={numeric} inputMode="numeric" />
    case 'number':
    case 'decimal':
      return <BaseTextField {...props} type="decimal" valueParser={decimals} inputMode="decimal" />

    case 'password':
      return <Password {...props} />
    case 'cvv':
      return (
        <Password
          {...props}
          valueParser={cvv}
          label="CVC/CVV"
          inputMode="numeric"
          autocomplete="off"
          aria-autocomplete="none"
        />
      )
    case 'search':
      return <SearchTextField {...props} />
  }
}

export function TextField({ type = 'text', tabIndex, onKeyPress, ...props }: BaseTextFieldProps) {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const isExistError = props.fieldUUID && props.firstErrorUUID
    if (props.helperText && isExistError) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      ref.current?.focus()
    }
  }, [props.firstErrorUUID, props.fieldUUID, props.helperText])

  return (
    <div ref={ref} tabIndex={tabIndex} onKeyPress={onKeyPress}>
      <TextElementComponent type={type} {...props} tabIndex={tabIndex} />
    </div>
  )
}
