import type { FlightKeys } from './types'

const i18nBase = 'TripPlanner.BaseSearch.Input'

export const flightKeysWithErrorData: FlightKeys = [
  { key: 'originPlace', errorTexti18nKey: `${i18nBase}.EnterOrigin` },
  {
    key: 'destinationPlace',
    errorTexti18nKey: `${i18nBase}.EnterDestination`,
  },
  { key: 'timeRange' },
  { key: 'departureDate', errorTexti18nKey: `${i18nBase}.SelectDates` },
  { key: 'cabinClass' },
]

export const roundTripFlightKeysWithErrorData: FlightKeys = [
  ...flightKeysWithErrorData,
  { key: 'returnDate', errorTexti18nKey: `${i18nBase}.SelectDates` },
  { key: 'returnTimeRange' },
]
