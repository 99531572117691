import { action, makeObservable, observable, observe } from 'mobx'
import { Container } from '@etta/di'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { LocalStorageItem } from '../local-storage-item'
import { EXPIRATION_TIME, STORAGE_KEY } from './consts'
import { removeExpiredItinerary } from './remove-expired-itinerary'

type Storage<T> = {
  values: Record<string, T>
  expTime: number
}

// This handles to remove all expired
// itineraries from local storage
removeExpiredItinerary()

export class ItineraryStorage<T> {
  private readonly key: string
  private persistStorage: LocalStorageItem<Storage<T>> | null = null
  private readonly defaultValue: T
  values: T

  constructor(key: string, defaultValue: T) {
    this.key = key

    this.values = this.defaultValue = defaultValue

    if (this.checkIfActiveItinerary()) {
      this.createPersistStorage()
      this.setStorage()
    }

    observe(this.getReviewTripStore(), (change) => {
      if (change.name === 'tripId') {
        this.createPersistStorage()
        this.setStorage()
      }
    })

    makeObservable(this, {
      values: observable,
      set: action,
    })
  }

  public set(newValues: T) {
    if (!this.checkIfActiveItinerary()) {
      return
    }

    if (!this.persistStorage) {
      return
    }

    this.values = newValues

    const { value } = this.persistStorage

    this.persistStorage.set({
      values: {
        ...(value?.values ?? {}),
        [this.key]: newValues,
      },
      expTime: this.getExpirationTime(),
    })
  }

  public unset() {
    if (this.persistStorage) {
      const val = this.persistStorage.value?.values

      delete val?.[this.key]

      this.persistStorage?.set({
        values: {
          ...(val ?? {}),
        },
        expTime: this.persistStorage.value?.expTime ?? this.getExpirationTime(),
      })
    }

    this.values = this.defaultValue
  }

  private setStorage() {
    if (!this.persistStorage) {
      this.values = this.defaultValue

      return
    }

    this.values = this.persistStorage.value?.values[this.key] ?? this.defaultValue
  }

  private checkIfActiveItinerary() {
    return this.getActiveItinerary()
  }

  private createPersistStorage() {
    if (this.checkIfActiveItinerary()) {
      this.persistStorage = new LocalStorageItem<Storage<T>>(this.getStorageKey())
    }
  }

  private getExpirationTime() {
    return Date.now() + EXPIRATION_TIME
  }

  private getActiveItinerary() {
    return this.urlItineraryId || Container.get(ReviewTripStore).tripId
  }

  // We need this to support book trip again feature.
  get urlItineraryId() {
    const pathname = window.location.pathname

    if (pathname.startsWith('/app/trip-review')) {
      const splittedPathname = pathname.split('/')

      return splittedPathname[splittedPathname.length - 1]
    }
    return undefined
  }

  private getStorageKey() {
    return `${STORAGE_KEY}::${this.getActiveItinerary()}`
  }

  private getReviewTripStore() {
    return Container.get(ReviewTripStore)
  }
}
