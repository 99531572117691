import styled, { css } from 'styled-components'

export const ContainerScroll = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`

export const Container = styled.div`
  padding: 24px 16px 0;
`

const ListContainer = css`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 16px;
  }
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0 3px 8px 3px;
  padding: 0;

  ${ListContainer};
`

export const SpaceContainer = styled.div`
  height: 56px;
`

export const SkeletonContainer = styled.div`
  ${ListContainer};
`
