import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { FareTaxInfoValueObject } from '@etta/modules/review-trip/core'
import { Unit } from './tax-breakdown-list-styled'

const i18nBase = 'PaymentSummary.'

type Props = {
  taxes?: FareTaxInfoValueObject[]
}

export function TaxBreakdownList({ taxes }: Props) {
  const { t } = useTranslation()
  return (
    <Block>
      {(taxes || []).map((tax) => (
        <Unit>
          <Text variant="footnoteMedium" color="bodyText" data-tracking-id="gst-detail-description">
            {t(`${i18nBase}GSTTaxDetail`, {
              code: tax.code,
              name: tax.name,
            })}
          </Text>
          <Text variant="footnoteMedium" color="bodyText" data-tracking-id="gst-detail-amount">
            {
              formatRate(tax.amount, {
                morpheme: 'none',
              }).mainCost
            }
          </Text>
        </Unit>
      ))}
    </Block>
  )
}
