import type { PreferenceContent } from '@fiji/types'

type Props = {
  preferences?: PreferenceContent
  disabledVendors?: string[]
}

export function removeDisabledMembership({
  preferences,
  disabledVendors,
}: Props): PreferenceContent | undefined {
  if (!preferences) {
    return undefined
  }

  if (!disabledVendors || disabledVendors.length === 0) {
    return preferences
  }

  return {
    ...preferences,
    loyaltyProgram: preferences?.loyaltyProgram.filter(
      (membership) => !disabledVendors?.includes(membership.vendorCode),
    ),
  }
}
