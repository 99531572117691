import { Action, Inject } from '@etta/di'
import type { TripType } from '@etta/core/enums/trip-type.enum'
import { TripsListStore } from '../stores/orders.store'
import { GetTripsListService } from '../services/get-trips-list.service'

@Action()
export class TripsListActions {
  constructor(
    @Inject()
    private readonly getOrdersService: GetTripsListService,

    @Inject()
    private readonly ordersStore: TripsListStore,
  ) {}

  load(type: TripType) {
    if (!this.ordersStore.orders[type]) {
      this.getOrdersService.reload(type)
      return
    }
    this.getOrdersService.load(type)
  }

  fetchMore(type: TripType) {
    this.getOrdersService.fetchMore(type)
  }

  reload(type: TripType) {
    this.getOrdersService.reload(type)
  }

  update(type: TripType) {
    this.getOrdersService.update(type)
  }

  dropAllTrips() {
    this.ordersStore.dropAllTrips()
  }

  dropAndReloadAllTrips() {
    this.getOrdersService.dropAndReloadAllOrders()
  }
}
