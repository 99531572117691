import { useTranslation } from 'react-i18next'
import type { CarRentalTypeFilter, CarRentalType } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { imageByFilterType, FILTER_I18N_KEYS } from '../../constants'
import type { Props } from '../../types'
import { Container, InfoContainer, FilterButton, Image, Title, Price } from './components'

export function CarRentalTypeFilterBarDesktop({
  onOptionClick,
  activeFilterOptions,
  availableFilters,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalTypeFilterBar'

  if (!availableFilters || availableFilters.length === 0) {
    return null
  }

  const handleOptionClick = (filter: CarRentalType, isFilterActive: boolean) => {
    if (isFilterActive) {
      onOptionClick(activeFilterOptions?.filter((option) => option !== filter) || [])
    } else {
      onOptionClick([...(activeFilterOptions || []), filter])
    }
  }

  return (
    <Container aria-label={t(`${i18Base}.Title`)}>
      {availableFilters.map((filter: CarRentalTypeFilter & { customName?: string }, index) => {
        const isFilterActive = !!activeFilterOptions?.includes(filter.code)
        const filterName = filter.customName ? filter.customName : t(FILTER_I18N_KEYS[filter.code])
        const { mainCost } = formatRate(filter.lowestCost, { isInteger: true })
        const costLabel = t(`${i18Base}.LowestCost`, {
          cost: mainCost,
        })

        return (
          <FilterButton
            key={filter.code}
            isActive={isFilterActive}
            aria-pressed={isFilterActive}
            aria-label={t(i18Base + '.CarTypeFilter', { filterName, index: index + 1 })}
            onClick={() => handleOptionClick(filter.code, isFilterActive)}
            data-tracking-id={`car-rental-filter-type-button_${filterName}`}>
            <Image
              width="56"
              src={imageByFilterType[filter.code]}
              alt={`Car type ${filterName} filter`}
            />
            <InfoContainer>
              <Title>{filterName}</Title>
              <Price>{costLabel}</Price>
            </InfoContainer>
          </FilterButton>
        )
      })}
    </Container>
  )
}
