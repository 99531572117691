import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'

export const DetailsContainer = styled.div<{ color: keyof ColorPalette }>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  padding: 8px 16px 34px 16px;
`

export const Paper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
`

export const Separator = styled.div`
  margin: 16px 0;
  width: 100%;
  border-bottom: 1px dashed ${(p) => p.theme.colors.borderDark};
`
