import styled from 'styled-components'
import { VisuallyHidden } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const HiddenSpan = styled.span`
  ${VisuallyHidden}
`
