import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { body, KeyboardNavigationStyle } from '@fiji/style'
import { withStyledText } from '@fiji/style/text-styles'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
`

export const SupportContactsContainer = styled.div`
  margin-top: 8px;
  padding: 0 16px;
`

export const TravelAssistanceTitle = styled.div`
  ${withStyledText};
  color: ${(props) => props.theme.colors.mainText};
  margin-bottom: 16px;
  line-height: 25px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonItem = styled.button`
  appearance: none;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  padding: 8px;
  flex: 1;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary1};
  margin-left: 9px;
  ${KeyboardNavigationStyle}

  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }

  &:first-child {
    margin-left: 0;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all;
  }
`

export const ButtonIconContainer = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`

export const ButtonTitle = styled.div`
  display: inline-block;
  ${withStyledText};
  color: ${(props) => props.theme.colors.primary1};
  margin-top: 4px;
  line-height: 20px;
`

export const TravelSupportContainer = styled.div`
  ${body};
  color: ${(props) => props.theme.colors.mainText};
  background: ${(props) => props.theme.colors.white};
  min-height: 256px;
  display: flex;
  flex-direction: column;
`

export const TravelSupportHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 0px;
`

export const TravelSupportBody = styled.div`
  margin-top: 18px;
  margin: auto 0;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const TravelSupportHeaderTitle = styled.div`
  ${withStyledText};
`

export const NavItem = styled(NavLink)<{ error: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  color: ${(p) => (p.error ? p.theme.colors.primary2 : p.theme.colors.primary)};

  ${(p) => p.error && `pointer-events: none;`}
`

export const NavText = styled.span`
  ${withStyledText};
`

export const ContactsDescription = styled.p`
  margin: 32px 0 0;
  padding: 0 16px;
  color: ${(p) => p.theme.colors.mainText};
  line-height: 26px;
  letter-spacing: -0.35%;
  ${withStyledText};
`

export const ContactsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 6px;
`

export const ContactsListItem = styled.li`
  margin-left: 16px;
  padding: 0 16px 20px 0;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

const contactIconContainerBase = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const CopyButton = styled.div`
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  width: 44px;
  height: 44px;
  ${contactIconContainerBase}
`

export const ContactOptionsContainer = styled.div``

export const SupportContactsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  background: #ffffff;
  border-radius: 14px;
  padding: 16px 0 0;
`

export const SupportContactsSubTitleWrapper = styled.div`
  padding-bottom: 16px;
`

export const SupportContactsSubTitle = styled(Text)`
  padding-bottom: 8px;
`
export const SupportContactsSubTitleDescription = styled(Text)``
