import { MediaLayout } from '@etta/ui/media-layout'
import { AddFlightModalMobile } from './mobile'
import type { LayoutProps } from './types'

export function AddFlightModalLayout(props: LayoutProps) {
  // TODO: Replace desktop layout
  return (
    <MediaLayout
      mobileSlot={<AddFlightModalMobile {...props} />}
      desktopSlot={<AddFlightModalMobile {...props} />}
    />
  )
}
