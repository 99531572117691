import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'

type Params = {
  tripName?: string | null
  startDate: string
  endDate: string
}

function formatDate(date: string): string {
  return dateFormat(new Date(deleteTimezone(date)), `eee, LLL dd`)
}

export function postBookingSubject({ tripName, startDate, endDate }: Params) {
  const usedTripName = tripName ? tripName : ''
  if (!startDate || !endDate) {
    return usedTripName
  }

  return `${usedTripName} (${formatDate(startDate)} - ${formatDate(endDate)})`.trim()
}
