import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'

export function useSearchHotelsForm() {
  const { hotelSearchButtonActions } = useHotelSearchFormContext()
  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    handleOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    shouldShowPreSearchModal,
  } = usePreSearch({ onSubmit: hotelSearchButtonActions.handleSubmit })

  const handleSearch = () => {
    if (shouldShowPreSearchModal && hotelSearchButtonActions.isInputValid()) {
      handleOpenPreSearchCustomFields()
    } else {
      hotelSearchButtonActions.handleSubmit()
    }
  }

  return {
    handleSearch,
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  }
}
