import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Container } from './time-duration-styled'

type Props = {
  duration: string
}

export const TimeDuration = ({ duration }: Props) => {
  return (
    <Container>
      <Icon name="timePWA" color="bodyText" />
      <Text variant="footnoteStrong" color="bodyText">
        {duration}
      </Text>
    </Container>
  )
}
