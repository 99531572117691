import { LocationMap } from '@etta/components/location-map/location-map'
import { Locations } from './locations'
import { DriverInstructions } from './driver-instructions'
import type { LocationsArgs } from './types'
import { Container, MapWrapper } from './pickup-and-dropoff-styled'
import { Marker } from './marker'

type Props = LocationsArgs

export function PickupAndDropOff({ pickUp, dropOff, stops }: Props) {
  return (
    <Container>
      {!!pickUp?.location?.geocode && pickUp.address && (
        <MapWrapper>
          <LocationMap
            location={{
              lat: pickUp.location.geocode.lat,
              lng: pickUp.location.geocode.long,
            }}>
            <Marker address={pickUp.address.street1} />
          </LocationMap>
        </MapWrapper>
      )}
      <Locations pickUp={pickUp} dropOff={dropOff} stops={stops} />
      <DriverInstructions
        pickUpInstructions={pickUp?.pickupInstructions}
        dropOffInstructions={dropOff?.dropOffInstructions}
      />
    </Container>
  )
}
