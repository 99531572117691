import { observer } from 'mobx-react-lite'
import { Popover } from '@etta/modules/seat-map/ui/popover'
import { SeatMapLoading } from './seat-map-loading/seat-map-loading'
import { SeatMapUnavailable } from './seat-map-unavailable'
import { useSeatMap } from './use-seat-map'
import type { Props } from './seat-map.types'
import { SeatMapLayout } from './layout'
import { Container } from './seat-map-styled'

export const SeatMap = observer(function SeatMap({
  seatRows,
  selectedSegmentId,
  isLoading,
  onUnavailableSeatMapContinue,
  footerHeight,
  onSeatChecked,
  seatsNumberList,
  isReadonly,
  isUnexpectedError,
  bookedSeat,
  selectedSeat,
  isSeatMapAvailable,
  isNewSeatMap = false,
  handleFareDetailsTabChange,
}: Props) {
  const {
    wrapperRef,
    airSeatMapRef,
    onInteractOnce,
    shouldShowOverlay,
    maxScale,
    defaultScale,
    zoomHandler,
    overlayHeight,
    scrollOffset,
    isZoomable,
    popoverRef,
    position,
    handleOpenPopover,
    airSeatMapHeight,
  } = useSeatMap({ selectedSegmentId, footerHeight, seatRows })

  if (isLoading) {
    return <SeatMapLoading isNewSeatMap={isNewSeatMap} />
  }

  if (!isSeatMapAvailable || isUnexpectedError) {
    return (
      <SeatMapUnavailable onContinue={onUnavailableSeatMapContinue} isNewSeatMap={isNewSeatMap} />
    )
  }

  return (
    <Container isNewSeatMap={isNewSeatMap}>
      <SeatMapLayout
        seatRows={seatRows}
        onUnavailableSeatMapContinue={onUnavailableSeatMapContinue}
        isLoading={isLoading}
        onSeatChecked={onSeatChecked}
        bookedSeat={bookedSeat}
        selectedSeat={selectedSeat}
        seatsNumberList={seatsNumberList}
        wrapperRef={wrapperRef}
        airSeatMapRef={airSeatMapRef}
        onInteractOnce={onInteractOnce}
        shouldShowOverlay={shouldShowOverlay}
        maxScale={maxScale}
        defaultScale={defaultScale}
        zoomHandler={zoomHandler}
        overlayHeight={overlayHeight}
        scrollOffset={scrollOffset}
        isZoomable={isZoomable}
        isReadonly={isReadonly}
        isNewSeatMap={isNewSeatMap}
        onOpenPopover={handleOpenPopover}
        airSeatMapHeight={airSeatMapHeight}
      />
      {isNewSeatMap && (
        <Popover
          ref={popoverRef}
          position={position}
          handleFareDetailsTabChange={handleFareDetailsTabChange}
        />
      )}
    </Container>
  )
})
