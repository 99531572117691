/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import type { CurrentPositionModalState, HistoryModals, ModalId } from './types'
import { checkCurrentState } from './check-current-state'
import { MODAL_TYPE } from './constants'

type Args = {
  isInState: boolean
  isOpen: boolean
  currentId: ModalId
  onClose: (bool: boolean) => void
}

export function usePopFromHistory({ isInState, isOpen, currentId, onClose }: Args) {
  const history = useHistory<HistoryModals>()
  const prevState = useRef<CurrentPositionModalState>('none')

  const currentState = checkCurrentState(currentId)

  const pop = useCallback(() => {
    const currentState = checkCurrentState(currentId)
    if (currentState !== 'last') {
      return
    }

    history.replace({
      pathname: history.location.pathname,
      search: history.location.search,
      state: {
        direction: 'backward',
        modalIds: history.location.state.modalIds,
        type: MODAL_TYPE,
      },
    })
    history.goBack()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      prevState.current = 'none'
      return
    }
    if (!isInState && isOpen && currentState !== 'last' && prevState.current === 'last') {
      onClose(false)
    }
    if (currentState !== prevState.current) {
      prevState.current = currentState
    }
  }, [isInState, isOpen, currentState])

  return pop
}
