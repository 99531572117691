import styled from 'styled-components'

export const InputWrapper = styled.div`
  max-height: 64px;
`

export const OneLineWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`
