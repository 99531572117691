const DISTANCE_MILE_TRANSLATION_KEY = '.Distance.Mile'
const DISTANCE_MILE_UPPERCASE_TRANSLATION_KEY = '.Distance.MileUppercase'
const DISTANCE_KM_TRANSLATION_KEY = '.Distance.Km'

export function getDistanceKey(distanceUnit: 'mi' | 'km', withUppercase?: boolean) {
  if (withUppercase && distanceUnit === 'mi') {
    return DISTANCE_MILE_UPPERCASE_TRANSLATION_KEY
  }

  return distanceUnit === 'km' ? DISTANCE_KM_TRANSLATION_KEY : DISTANCE_MILE_TRANSLATION_KEY
}
