import styled from 'styled-components'

export const Container = styled.div<{
  isWhiteBackground: boolean
  isSelectable: boolean
  isSelected: boolean
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  border-radius: 14px;
  border: 2px solid
    ${(p) => {
      switch (p.isSelectable) {
        case true:
          return p.isSelected ? p.theme.colors.primary : p.theme.colors.borderLight
        default:
          return p.theme.colors.white
      }
    }};
  padding: 14px;
  background-color: ${(props) =>
    props.isWhiteBackground ? props.theme.colors.white : props.theme.colors.duskBlue};
`

export const FlightDetailsContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
`

export const AirlineLogo = styled.div<{ isWhiteBackground?: boolean }>`
  width: 44px;
  min-width: 44px;
  height: 44px;
  overflow: hidden;
  background-color: ${(p) => (p.isWhiteBackground ? 'white' : 'none')};
  border-radius: 8px;
`

export const Image = styled.img`
  width: 44px;
  height: 44px;
`
