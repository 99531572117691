import { useTranslation } from 'react-i18next'
import type { PriceDetailsHeaderProps } from '../../types'
import { Heading } from './price-details-header-mobile-styled'

export function PriceDetailsHeaderMobile({ headerLevel }: PriceDetailsHeaderProps) {
  const { t } = useTranslation()
  const i18nBase = 'PriceDetails'

  return (
    <Heading role="heading" aria-level={headerLevel} variant="captionStrongCaps" isBlock>
      {t(i18nBase + '.Title')}
    </Heading>
  )
}
