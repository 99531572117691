import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'

export const Title = styled(Text)`
  margin: 32px 0 24px;
  font-size: 34px;
  line-height: 41px;
`

export const SubHeader = styled(Block)`
  justify-content: space-between;
  padding-right: 364px;
`

export const Main = styled.div`
  display: flex;
  gap: 39px;
`
export const PriceInformation = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 10px 0px;
  background-color: #dce4ea;
`

export const LeftSide = styled.div`
  width: 100%;
`

export const RightSide = styled.div``

export const Content = styled.div`
  padding: 0 16px;
  margin-bottom: 108px;
  width: 100%;
`
