import { createErrorClass } from '@etta/core/errors/create-error-class'
import type { FieldErrorValueObject } from '../value-objects/field-error.value-object'

export namespace PassportErrors {
  export const AddPassportError = createErrorClass('Add passport error')
  export const RemovePassportError = createErrorClass('Remove passport error')
  export const UnexpectedAddPassportError = createErrorClass('Unexpected add passport error')
  export const UnexpectedRemovePassportError = createErrorClass('Unexpected remove passport error')
  export const InvalidInputError = createErrorClass<FieldErrorValueObject[]>(
    'The provided input failed the validation',
  )
}

export type PassportErrorsInstances = InstanceType<
  typeof PassportErrors[keyof typeof PassportErrors]
>
