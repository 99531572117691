import type { SwipeEventData } from 'react-swipeable'
import type {
  SwipeData,
  SwipingTimePickerBars,
  SwipingPickerSubtype,
  SwipingTimePickerUnits,
  YearBounds,
} from '../types'
import { SWIPE_SENSITIVITY, SWIPE_THRESHOLD } from '../constants'
import { getEnvironment } from './get-environment'

type SwipeAssets = {
  e: SwipeEventData
  type: SwipingTimePickerUnits
  increment: number
  yearBoundsInput?: YearBounds
  variant?: SwipingPickerSubtype
  hourlyRange?: number
}

type SwipeAction = {
  e: SwipeEventData
  swipeData: SwipeData
  yearBoundsInput?: YearBounds
  setIncrement: (e: any) => void
  variant?: SwipingPickerSubtype
  hourlyRange?: number
}

type ValidDateCheck = {
  day: number
  month: number
  year: number
  variant?: SwipingPickerSubtype
}

type FlipColumn = {
  values: SwipingTimePickerBars
  swipeData: SwipeData
  yearBoundsInput?: YearBounds
  variant?: SwipingPickerSubtype
  hourlyRange?: number
}

type SweepVelocity = {
  velocity: number
  direction: string
  type: SwipingTimePickerUnits
  yearBoundsInput?: YearBounds
  variant?: SwipingPickerSubtype
  hourlyRange?: number
}

export const flipColumn = ({
  values,
  swipeData,
  variant,
  hourlyRange,
  yearBoundsInput,
}: FlipColumn) => {
  const { getBarHeight, getMinBar, getMaxBar, constants } = getEnvironment(
    variant,
    hourlyRange,
    yearBoundsInput,
  )
  const { BAR_VIEWPORT, INIT_BARS } = constants
  const { type, direction, sweep, unitHeight } = swipeData

  const columnsToFlip = () => {
    switch (type) {
      case 'year':
      case 'day':
        return 2
      case 'min':
        return variant === 'only-min' ? 2 : 4
      default:
        return 4
    }
  }
  const minThresholdToFlip = () => {
    switch (type) {
      case 'min':
        return variant === 'only-min' ? -0.5 : 1
      case 'year':
      case 'day':
        return -0.5
      default:
        return 1
    }
  }

  const maxThresholdToFlip = () => {
    switch (type) {
      case 'min':
        return variant === 'only-min' ? 0.5 : 0
      case 'year':
      case 'day':
        return 0.5
      default:
        return 0
    }
  }

  const minThreshold =
    -getBarHeight(type) - getBarHeight(type) * minThresholdToFlip() + BAR_VIEWPORT
  const maxThreshold = -getBarHeight(type) * maxThresholdToFlip() + BAR_VIEWPORT
  const flipValue = columnsToFlip() * getBarHeight(type) * (direction === 'Down' ? -1 : 1)

  if (
    direction === 'Down' &&
    getMinBar(columnsToFlip(), values) - sweep * unitHeight >= minThreshold
  ) {
    return values.map((el) =>
      el === getMaxBar(columnsToFlip(), values) ? flipValue : 0,
    ) as SwipingTimePickerBars
  }
  if (
    direction === 'Up' &&
    getMaxBar(columnsToFlip(), values) - sweep * unitHeight <= maxThreshold
  ) {
    return values.map((el) =>
      el === getMinBar(columnsToFlip(), values) ? flipValue : 0,
    ) as SwipingTimePickerBars
  }
  return INIT_BARS
}

export const sweepVelocity = ({
  velocity,
  direction,
  type,
  variant,
  hourlyRange,
  yearBoundsInput,
}: SweepVelocity) => {
  const { countBarUnits } = getEnvironment(variant, hourlyRange, yearBoundsInput)

  const getDirection = () => {
    switch (direction) {
      default:
        return 0
      case 'Up':
        return 1
      case 'Down':
        return -1
    }
  }
  const move = velocity / SWIPE_SENSITIVITY
  const moveLimit = move > SWIPE_THRESHOLD ? Math.max(move, 1) : 0
  return getDirection() * Math.min(Math.floor(moveLimit), countBarUnits(type) - 1)
}

export const calcSwipeAssets = ({
  e,
  type,
  increment,
  variant,
  hourlyRange,
  yearBoundsInput,
}: SwipeAssets) => {
  const { countBarUnits, getBarHeight } = getEnvironment(variant, hourlyRange, yearBoundsInput)
  const sweep = sweepVelocity({
    velocity: e.velocity,
    direction: e.dir,
    type,
    variant,
    yearBoundsInput,
    hourlyRange,
  })
  const unitHeight = getBarHeight(type) / countBarUnits(type)
  const totalSwipeEffect = increment + sweep
  const direction = e.dir

  return { sweep, unitHeight, totalSwipeEffect, direction, type }
}

export const setSwipeAction = ({
  e,
  swipeData,
  setIncrement,
  variant,
  hourlyRange,
  yearBoundsInput,
}: SwipeAction) => {
  const { countBarUnits } = getEnvironment(variant, hourlyRange, yearBoundsInput)
  const { type, totalSwipeEffect } = swipeData
  if (e.dir === 'Down') {
    if (totalSwipeEffect < 0) {
      setIncrement(countBarUnits(type) - Math.abs(totalSwipeEffect))
    } else {
      setIncrement(totalSwipeEffect)
    }
  }

  if (e.dir === 'Up') {
    if (totalSwipeEffect > countBarUnits(type) - 1) {
      setIncrement(totalSwipeEffect % countBarUnits(type))
    } else {
      setIncrement(totalSwipeEffect)
    }
  }
  return totalSwipeEffect
}

export const returnValidDate = ({ day, month, year, variant }: ValidDateCheck) => {
  if (variant && variant !== 'date') {
    return
  }
  if (day === 31 && [3, 5, 8, 10].includes(month)) {
    return 30
  }
  if ((day === 29 || day === 31 || day === 30) && month === 1 && year % 4 !== 0) {
    return 28
  }
  if ((day === 31 || day === 30) && month === 1) {
    return 29
  }
}
