import { Action, Inject } from '@etta/di'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import { PreSearchService } from '../service/pre-search.service'
import { PreSearchStore } from '../stores'

@Action()
export class PreSearchActions {
  constructor(
    @Inject()
    private readonly preSearchStore: PreSearchStore,
    private readonly preSearchService: PreSearchService,
  ) {}

  setCustomFields(customFields: CustomFieldBookingValueObject[]) {
    this.preSearchStore.setCustomFields(customFields)
  }

  handleClosePreSearchCustomFields() {
    this.preSearchStore.handleClosePreSearchCustomFields()
  }

  handleOpenPreSearchCustomFields() {
    this.preSearchStore.handleOpenPreSearchCustomFields()
  }

  async loadSearchInitiateInfo() {
    await this.preSearchService.loadSearchInitiateInfo()
  }

  clearSelectedPresearchCustomFields() {
    this.preSearchStore.dropCustomFields()
  }
}
