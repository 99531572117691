import { useMemo } from 'react'
import { useHotelReviews } from '@fiji/hooks/use-hotel-reviews'
import type { HotelReview, Maybe } from '@fiji/graphql/types'

type Args = {
  hotelId: string
  medianReviewRating?: number | null
  totalReviewCount?: number | null
  reviews?: Maybe<HotelReview>[]
  isTripAdvisorReviewsEnabled?: boolean
}

export function useHotelRating({
  hotelId,
  medianReviewRating,
  totalReviewCount,
  reviews,
  isTripAdvisorReviewsEnabled,
}: Args) {
  // TODO: move this logic outside from hotel reviews and stay this component clear
  const { data, loading: isLoading, error } = useHotelReviews(hotelId, isTripAdvisorReviewsEnabled)

  const reviewsList = useMemo<HotelReview[]>(() => {
    const loadedReviews = data?.hotelReviews.reviews as HotelReview[]
    if (loadedReviews && loadedReviews.length > 0) {
      return loadedReviews
    }
    if (!reviews || reviews.length === 0) {
      return []
    }
    return reviews as HotelReview[]
  }, [data?.hotelReviews.reviews, reviews])
  const medianRating = medianReviewRating || data?.hotelReviews.medianReviewRating || 0
  const reviewCount = totalReviewCount || data?.hotelReviews.totalReviewCount || 0

  return {
    medianRating,
    reviewCount,
    reviewsList,
    isLoading,
    error,
  }
}
