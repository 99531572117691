import styled from 'styled-components'
import type { IconColorPalette } from '@etta/ui/icon'
import { subHeadStrong } from '@fiji/style'

export const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  border-radius: 14px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  background-color: ${(p) => p.theme.colors.white};
  overflow: hidden;
  z-index: 2;
`

export const ShadowCard = styled.div`
  position: relative;
  height: 20px;
  margin: 0 14px;
  border-radius: 14px;
  opacity: 50%;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  z-index: 1;
`

export const DecoratedLine = styled.div<{ color: IconColorPalette }>`
  width: 100%;
  height: 8px;
  background-color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.plum)};
`

export const Content = styled.div`
  padding: 12px 16px 9px 16px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.mainText};
  ${subHeadStrong};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;

  p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const IconSection = styled.div`
  margin-right: 5px;
`

export const MessageSection = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0px 0px 3px 3px;
`
