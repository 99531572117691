export default function NonRefundableIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0476 11.4154C37.5089 14.3442 39 18.0915 39 22.1389C39 31.3629 31.3 39 22 39C18.9628 39 16.0933 38.1825 13.6068 36.7595L15.4292 34.6062C17.3882 35.6187 19.6217 36.1898 22 36.1898C29.8667 36.1898 36.1833 29.9413 36.1667 22.1389C36.1597 18.9046 35.0686 15.9328 33.2313 13.5635L35.0476 11.4154ZM36.1292 5.31119C36.6965 5.78334 36.7707 6.62224 36.2946 7.18492L9.80923 38.491C9.33319 39.0537 8.48726 39.1271 7.91994 38.6549C7.35262 38.1828 7.27872 37.3439 7.75476 36.7812L34.2402 5.47513C34.7162 4.91244 35.5618 4.83904 36.1292 5.31119ZM21.9833 5.27781C25.026 5.27781 27.8992 6.09706 30.3881 7.52192L28.5631 9.67734C26.6004 8.66159 24.3636 8.08799 21.9833 8.08799C14.1167 8.08799 7.85 14.353 7.85 22.1389C7.85 25.3777 8.9327 28.3487 10.7619 30.7162L8.94673 32.8641C6.48944 29.9373 5 26.1913 5 22.1389C5 12.9314 12.7 5.27781 21.9833 5.27781ZM25.8116 22.3335C26.8461 23.0497 27.3667 24.0723 27.3667 25.4119C27.3667 27.7767 25.5568 29.2364 23.1839 29.5943L22.7833 29.6437V30.8009C22.7833 31.2637 22.4333 31.5943 21.9833 31.5943C21.5896 31.5943 21.2851 31.3539 21.2152 30.9726L21.2 30.8009V29.6272C20.7371 29.5793 20.29 29.4894 19.8679 29.3601L22.781 25.9121L22.7833 27.7593C24.3526 27.5914 25.1833 26.832 25.3018 25.793L25.3167 25.5277C25.3167 24.8517 25.0778 24.3289 24.4682 23.9232L25.8116 22.3335ZM21.9833 12.8157C22.3771 12.8157 22.6944 13.0688 22.7676 13.4422L22.7833 13.6092V14.7498C23.2256 14.7978 23.6491 14.8884 24.0467 15.0179L21.1988 18.3795L21.2 16.6508C19.9014 16.8629 19.0789 17.589 18.9509 18.4852L18.9333 18.7336C18.9333 19.3285 19.1671 19.7986 19.678 20.1758L18.3673 21.7263C17.3815 21.026 16.8833 20.0776 16.8833 18.8328C16.8833 16.7096 18.4739 15.2056 20.7848 14.8083L21.2 14.7498V13.6092C21.2 13.1298 21.5333 12.8157 21.9833 12.8157Z"
        fill="currentColor"
      />
    </svg>
  )
}
