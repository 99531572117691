import type { InputHTMLAttributes } from 'react'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import type { RecentSearchesType } from '@etta/modules/recent-searches/core/value-objects/recent-searches-type.value-object'
import { SearchMechanismLayout } from './layout'
import { useSearchMechanism } from './use-search-mechanism'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  disabled?: boolean
  hasCurrentLocationSearch?: boolean
  searchType?: SearchType
  inputProps?: object
  isModalOpen: boolean
  isOutboundTrip?: boolean
  latestPlaces?: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  onChange: (value: Place, address?: AddressInput) => void
  onModalClose: () => void
  customSearchDescription?: string
  recentSearchesType?: RecentSearchesType
}

export function SearchMechanism({
  disabled,
  inputProps,
  searchType,
  isModalOpen,
  isOutboundTrip,
  latestPlaces = [],
  locationsSortOrder,
  recentSearchesType,
  onChange,
  onModalClose,
  customSearchDescription,
  hasCurrentLocationSearch,
}: Props) {
  const {
    menuProps,
    setIsLoading,
    hasAnyPlaces,
    isLoading,
    errorMessage,
    isHotelSearch,
    referencePoints,
    modifiedPlaces,
    places,
    highlightedIndex,
    getItemProps,
    isTrainNoResultVisible,
    handleCurrentLocation,
    searchInputProps,
    searchInputContainerProps,
    inputValue,
    reset,
    isResultsListOpen,
    searchFieldPlaceholder,
    anchorRef,
    scrollContainerRef,
    searchInputRef,
    noResultMessage,
  } = useSearchMechanism({
    onModalClose,
    onChange,
    searchType,
    locationsSortOrder,
    latestPlaces,
    isModalOpen,
    isOutboundTrip,
    inputProps,
  })

  return (
    <SearchMechanismLayout
      recentSearchesType={recentSearchesType}
      scrollContainerRef={scrollContainerRef}
      onModalClose={onModalClose}
      searchFieldPlaceholder={searchFieldPlaceholder}
      menuProps={menuProps}
      setIsLoading={setIsLoading}
      hasAnyPlaces={hasAnyPlaces}
      isLoading={isLoading}
      errorMessage={errorMessage}
      isHotelSearch={isHotelSearch}
      referencePoints={referencePoints}
      modifiedPlaces={modifiedPlaces}
      places={places}
      highlightedIndex={highlightedIndex}
      getItemProps={getItemProps}
      isTrainNoResultVisible={isTrainNoResultVisible}
      handleCurrentLocation={handleCurrentLocation}
      customSearchDescription={customSearchDescription}
      hasCurrentLocationSearch={hasCurrentLocationSearch}
      searchType={searchType}
      latestPlaces={latestPlaces}
      isResultsListOpen={isResultsListOpen}
      searchInputRef={searchInputRef}
      anchorSlot={<div ref={anchorRef} />}
      searchInputContainerProps={searchInputContainerProps}
      searchInputProps={searchInputProps}
      isSearchInputDisabled={!!disabled}
      searchInputValue={inputValue}
      noResultMessage={noResultMessage}
      onReset={reset}
    />
  )
}
