import type { CheckoutItineraryMutation } from '@fiji/graphql/types'
import type { FailedBookingValueObject } from '../../core/value-objects/failed-booking.value-object'

export class FailedBookingMapper {
  static mapToFailedBookingValueObject(
    input: NonNullable<
      Extract<
        CheckoutItineraryMutation['checkoutItinerary'],
        { __typename: 'OrderDetails' }
      >['failedBookings']
    >[0],
  ): FailedBookingValueObject {
    if (!input.hotelFailedBooking) {
      return {}
    }

    const { error, hotelData } = input.hotelFailedBooking

    if (!error) {
      return {}
    }

    return {
      hotelFailedBooking: {
        error,
        hotelData: {
          hotelId: hotelData.hotelId ?? undefined,
          checkIn: hotelData.checkIn ?? undefined,
          checkOut: hotelData.checkOut ?? undefined,
          name: hotelData.name ?? undefined,
          roomType: hotelData.roomType ?? undefined,
          address: hotelData.address ?? undefined,
          numberOfNights: hotelData.numberOfNights,
        },
      },
    }
  }
}
