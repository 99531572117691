import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { SectionBlockMobile } from './mobile'
import { SectionBlockDesktop } from './desktop'

export function SectionBlockLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SectionBlockMobile {...props} />}
      desktopSlot={<SectionBlockDesktop {...props} />}
    />
  )
}
