import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const CovidIconContainer = styled.div`
  width: 32px;
  height: 32px;
  color: ${(p) => p.theme.colors.plum};
  margin: 0 auto;
`

export const CovidEmptyDescription = styled(Block)`
  flex-direction: column;
`
