import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { useMobilityBookingError } from '@etta/screens/mobility-confirmation-page/components/mobility-booking-error/use-mobility-booking-error'
import { useMobilityViewClose } from '@fiji/hooks/mobility'
import { ScreenType, useScreenType } from '@fiji/modes'
import { UberTrackingRideViaQRCode } from '@etta/components/uber-tracking-ride-via-qr-code/uber-tracking-ride-via-qr-code'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import {
  Container,
  Content,
  IconWrapper,
  Title,
  Footnote,
  RideHailErrorMessage,
  ErrorTitle,
  ErrorContentWithUberLink,
  ErrorContentWithoutUberLink,
  ErrorLine,
  ErrorButtonContainer,
  ErrorButtonText,
  ButtonContainer,
  UberQrCodeWrapper,
} from './mobility-booking-error-with-message-styled'

type Props = {
  errorMessage: string
  isShowUberExternalLink: boolean
}

const i18nBasePath = 'Mobility.BookingErrorWithMessagePage.'

export function MobilityBookingErrorWithMessage({ errorMessage, isShowUberExternalLink }: Props) {
  const { t } = useTranslation()
  const { handleRedirectToSearch, handleOpenUber } = useMobilityBookingError()
  const { handleClose } = useMobilityViewClose()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const { searchRideHailStore } = useRideHailSearchContext()
  const {
    pickUpPlace,
    pickUpDate,
    pickUpTime,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm

  return (
    <Container>
      <Content>
        <IconWrapper>
          <Icon name="errorPWA" color="error" size={41} />
        </IconWrapper>

        <Title>{t(i18nBasePath + 'Title')}</Title>
        <Footnote>
          {isShowUberExternalLink
            ? t(i18nBasePath + 'DescriptionWithExternalUberLink')
            : t(i18nBasePath + 'DescriptionWithoutExternalUberLink')}
        </Footnote>
        <RideHailErrorMessage>
          <ErrorTitle>{t(i18nBasePath + 'ErrorTitle')}</ErrorTitle>
          {isShowUberExternalLink && !isDesktop ? (
            <>
              <ErrorContentWithUberLink>{errorMessage}</ErrorContentWithUberLink>
              <ErrorLine />
              <ErrorButtonContainer onClick={handleOpenUber}>
                <ErrorButtonText>{t(i18nBasePath + 'ErrorButtonText')}</ErrorButtonText>
                <Icon name="goToExternalPWA" size={12} color="primary" />
              </ErrorButtonContainer>
            </>
          ) : (
            <>
              <ErrorContentWithoutUberLink>{errorMessage}</ErrorContentWithoutUberLink>
            </>
          )}
        </RideHailErrorMessage>

        <ButtonContainer>
          <Button fullWidth onClick={handleRedirectToSearch}>
            {t(i18nBasePath + 'GoToSearchForm')}
          </Button>
          {!isDesktop && (
            <Button fullWidth variant="outline" onClick={handleClose}>
              {t(i18nBasePath + 'Close')}
            </Button>
          )}
        </ButtonContainer>
        {isDesktop && (
          <UberQrCodeWrapper>
            <UberTrackingRideViaQRCode
              pickUpPlace={pickUpPlace}
              dropOffPlace={dropOffPlace}
              pickUpDate={pickUpDate}
              pickUpTime={pickUpTime}
              isUberWithParam
              title={t(i18nBasePath + 'ScanToTryBookingAgain')}
            />
          </UberQrCodeWrapper>
        )}
      </Content>
    </Container>
  )
}
