import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Tabs } from '@etta/ui/tabs'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import type { Props } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const TabsSectionMobile = observer(function TabsSectionMobile({ isDisabled }: Props) {
  const { railSearchFormActions, railSearchFormStore } = useRailSearchFormContext()
  const railTripType = railSearchFormStore.railForm.railTripType

  const { t } = useTranslation()
  const oneWayTitle = t(i18Base + '.Input.OneWay')
  const roundTripTitle = t(i18Base + '.Input.RoundTrip')
  const openReturnTitle = t(i18Base + '.Input.OpenReturn')

  return (
    <Block marginBottom={24}>
      <Tabs activeTab={railTripType} onChangeTab={railSearchFormActions.onTripTypeChange}>
        <Tabs.Header>
          <Tabs.Tab
            value={RailTripType.OneWay}
            aria-label={oneWayTitle}
            data-tracking-id="one-way-tab"
            isDisabled={isDisabled}>
            {oneWayTitle}
          </Tabs.Tab>
          <Tabs.Tab
            value={RailTripType.Round}
            aria-label={roundTripTitle}
            data-tracking-id="round-trip-tab"
            isDisabled={isDisabled}>
            {roundTripTitle}
          </Tabs.Tab>
          <Tabs.Tab
            value={RailTripType.OpenReturn}
            aria-label={openReturnTitle}
            data-tracking-id="open-return-tab"
            isDisabled={isDisabled}>
            {openReturnTitle}
          </Tabs.Tab>
        </Tabs.Header>
      </Tabs>
    </Block>
  )
})
