import { calculateTimeFromSeconds } from '@fiji/utils/dates/calculate-time-from-seconds'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { getDifferenceInFullMinutes } from '@fiji/utils/dates/get-difference-in-full-minutes'
import { Block } from '@etta/ui/block'
import type { LocationsArgs } from '../types'
import { Location } from './location'
import { LocationTypes } from './location-types'

type Props = LocationsArgs

export function Locations({ pickUp, dropOff, stops }: Props) {
  const parsedPickUpDate = new Date(deleteTimezone(pickUp?.time || ''))
  const parsedDropOffDate = new Date(deleteTimezone(dropOff?.time || ''))
  const duration = calculateTimeFromSeconds(
    getDifferenceInFullMinutes(parsedDropOffDate, parsedPickUpDate) * 60,
  )

  return (
    <Block marginHorizontal={16} marginTop={16}>
      <Location time={pickUp?.time} address={pickUp?.address} type={LocationTypes.PickUp} />
      {stops?.map((stop, idx) => (
        <Location
          key={`${idx}-${stop.street1}`}
          indexOfStop={idx + 1}
          address={stop}
          type={LocationTypes.Stop}
        />
      ))}
      {dropOff && (
        <Location
          duration={duration}
          isDirect={dropOff?.location?.asDirected}
          time={dropOff?.time}
          address={dropOff?.address}
          type={LocationTypes.DropOff}
        />
      )}
    </Block>
  )
}
