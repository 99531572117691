import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Props } from '../../types'
import { Footer, Status, Label } from './mobile-styled'

export function CarFooterBlockMobile({ policy, priceSlot }: Props) {
  const { t } = useTranslation()

  return (
    <Footer>
      <Status aria-label={t('SearchSegments.CarRental.Status')}>
        {!policy?.isInPolicy && (
          <>
            <Icon name="outOfPolicyPWA" size="small" aria-hidden />
            <Label>{t('SearchSegments.Badges.OutOfPolicy')}</Label>
          </>
        )}
      </Status>
      {priceSlot}
    </Footer>
  )
}
