import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { LegendNode } from './legend-node'
import { Container, LegendsContainer } from './legends-styled'

const i18nBase = 'AirSeatMap.Legend'

type Props = {
  isVisible: boolean
  onClick: () => void
}

export function Legends({ isVisible, onClick }: Props) {
  const { t } = useTranslation()

  const { ref } = useClickOutside(() => {
    if (isVisible) {
      onClick()
    }
  })

  return (
    <Container ref={ref} isVisible={isVisible}>
      <LegendsContainer>
        <LegendNode icon="available" text={t('AirSeatMap.Legend.Available')} />
        <LegendNode icon="paid" text={t('AirSeatMap.Legend.PaidSeat')} />
        <LegendNode icon="premium" text={t('AirSeatMap.Legend.Premium')} />
        <LegendNode icon="unavailable" text={t('AirSeatMap.Legend.Unavailable')} />
        <LegendNode icon="galley" text={t('AirSeatMap.Legend.Galley')} />
        <LegendNode icon="lavatory1" text={t('AirSeatMap.Legend.Lavatory')} />
        <LegendNode icon="closet" text={t('AirSeatMap.Legend.Closet')} />
        <LegendNode icon="lavatory2" text={t('AirSeatMap.Legend.Lavatory')} />
      </LegendsContainer>
      <IconButton
        data-tracking-id="seat-map-close-legend-button"
        aria-label={t(`${i18nBase}.CloseLegends`)}
        icon="closeFilledPWA"
        color="bodyText"
        size="normal"
        onClick={onClick}
      />
    </Container>
  )
}
