import { useMemo } from 'react'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/'
import { getSortedFilters } from './get-sorted-filters'

export function useCarRentalFilters() {
  const {
    carRentalSearchStore,
    carRentalFilterActions,
    carFiltersModalStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { carFiltersToggle } = carFiltersModalStore
  const { availableCarFilters } = carRentalSearchStore
  const {
    handleApplySelectedFilters,
    handleResetToDefaultFilters: handleResetToDefaultFiltersAction,
  } = carRentalFilterActions

  const {
    selectedFilters: values,
    isEqual: isValuesEqual,
    isDefaultFilterCompanySettingsExist,
    isShowResetToDefaultFiltersButton: isResetToDefaultFiltersButtonEnabled,
  } = carFiltersStore

  const sortedFilters = useMemo(() => getSortedFilters(availableCarFilters), [availableCarFilters])

  function handleApplyFilters() {
    handleApplySelectedFilters()
  }

  function handleResetToDefaultFilters() {
    if (isResetToDefaultFiltersButtonEnabled) {
      carFiltersToggle.handleClose()
      handleResetToDefaultFiltersAction()
    }
  }

  const handlers = carRentalFilterActions.handlers
  const handlersClear = carRentalFilterActions.clearHandlers

  function handleClearAllFilters() {
    handlersClear.clearAllSelectedFilters()
  }

  return {
    isValuesEqual,
    values,
    sortedFilters,
    handlers,
    handleClearAllFilters,
    handleApplyFilters,
    handlersClear,
    handleResetToDefaultFilters,
    isDefaultFilterCompanySettingsExist,
    isResetToDefaultFiltersButtonEnabled,
  }
}
