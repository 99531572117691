import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { TRANSITION_DURATION, TRANSITION_CLASS_NAME } from './transition'
import { Container, DelegateNotchButton, DelegateNotchNameWrapper } from './delegate-notch-styled'

const i18Base = 'Delegate.DelegateNotch'

type Props = {
  delegatedName: string
  delegateGuestTitle?: string
  isVisible: boolean
  onChange: () => void
  onExit: () => void
}

export function DelegateNotch({
  delegatedName,
  delegateGuestTitle,
  onChange,
  onExit,
  isVisible,
}: Props) {
  const { t } = useTranslation()

  return (
    <CSSTransition
      appear={isVisible}
      in={isVisible}
      classNames={TRANSITION_CLASS_NAME}
      timeout={TRANSITION_DURATION}>
      <Container isVisible={isVisible}>
        <Icon size={21} name="peopleNewFieldPWA" color="mainText2" />
        <DelegateNotchNameWrapper>
          <Swap
            is={!!delegateGuestTitle}
            isSlot={
              <Text variant="footnoteMedium" color="mainText2">
                {delegateGuestTitle}
              </Text>
            }>
            <Text variant="footnoteMedium" color="mainText2">
              {t(i18Base + '.Assisting')}
            </Text>
            <Text variant="footnoteStrong" color="mainText2">
              {delegatedName}
            </Text>
          </Swap>
        </DelegateNotchNameWrapper>
        <DelegateNotchButton
          aria-label={t('Delegate.DelegateSwitchLabel', { name: delegatedName })}
          onClick={onChange}>
          {t(i18Base + '.Change')}
        </DelegateNotchButton>
        <DelegateNotchButton
          aria-label={t('Delegate.DelegateExitLabel', { name: delegatedName })}
          onClick={onExit}>
          {t(i18Base + '.Exit')}
        </DelegateNotchButton>
      </Container>
    </CSSTransition>
  )
}
