import styled from 'styled-components'
import { bodyLarge, headline } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 34px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04);
  background-color: ${({ theme }) => theme.colors.white};
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.mainText};
  ${headline};
`

export const Cost = styled.span`
  color: ${({ theme }) => theme.colors.mainText};
  ${bodyLarge};
`
