import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CabinClassSectionMobile } from './layout/mobile'
import { CabinClassSectionDesktop } from './layout/desktop'

export function CabinClassSection(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CabinClassSectionMobile {...props} />}
      desktopSlot={<CabinClassSectionDesktop {...props} />}
    />
  )
}
