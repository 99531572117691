const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60

export function getDateWithoutTimezoneOffset(date: Date | number | string): Date {
  const parsedDate = new Date(date)
  const timezoneOffsetInMs =
    parsedDate.getTimezoneOffset() * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND

  parsedDate.setTime(parsedDate.getTime() + timezoneOffsetInMs)

  return parsedDate
}
