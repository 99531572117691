import styled from 'styled-components'
import { Dialog } from '@etta/ui/dialog'
import { Button } from '@etta/ui/button'

export const Footer = styled(Dialog.Footer)`
  padding: 14px 32px;
  display: flex;
  justify-content: space-between;
`

export const Body = styled(Dialog.Body)`
  padding: 26px 32px 32px 32px;
`

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.mainText};
`

export const ConfirmButton = styled(Button)`
  flex: 0 1 210px;
`
