import { useState, useCallback } from 'react'
import { rollbar } from '@fiji/rollbar'
import type { Place } from '@fiji/types'
import type { AddressInput } from '@fiji/graphql/types'
import type { GoogleLocationTypes } from './get-place-by-lat-long'
import { getPlaceByLatLong } from './get-place-by-lat-long'

export type Props = {
  setIsLoading?: (v: boolean) => void
  searchTypes?: GoogleLocationTypes[]
  onClick: (value: Place, address?: AddressInput) => void
}

export function useCurrentLocationSearch({ onClick, setIsLoading, searchTypes }: Props) {
  const [isActive, setActive] = useState(false)
  const [isDenied, setDenied] = useState(false)

  const handleDeny = useCallback(
    (value: boolean) => {
      setDenied(value)
    },
    [setDenied],
  )

  const handleResult = useCallback(
    (value?: Place, address?: AddressInput) => {
      setActive(false)
      if (setIsLoading) {
        setIsLoading(false)
      }
      if (value) {
        onClick(value, address)
      }
    },
    [setActive, setIsLoading, onClick],
  )

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude: lat, longitude: long } = position.coords
        setActive(true)
        if (setIsLoading) {
          setIsLoading(true)
        }
        getPlaceByLatLong({ lat, long, onDone: handleResult, searchTypes })
      },
      (err) => {
        if (err.code === 1) {
          // PERMISSION_DENIED
          setDenied(true)
        } else {
          rollbar.error(
            new Error(`Get current position error: ${err?.message || 'Unknown error'}`),
            {
              err,
            },
          )
        }
      },
    )
  }, [handleResult, searchTypes, setIsLoading])

  const handleGeolocation = useCallback(() => {
    if (navigator.permissions?.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (['granted', 'prompt'].includes(result.state)) {
          getCurrentPosition()
        } else if (result.state === 'denied') {
          setDenied(true)
        }
      })
      return
    }

    // Permissions API doesn't support on Safari and IOS until 16 version. So we are using Navigator API instead.
    getCurrentPosition()
  }, [setDenied, getCurrentPosition])

  return {
    isActive,
    isDenied,
    handleGeolocation,
    handleDeny,
  }
}
