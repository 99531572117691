import { Store } from '@etta/di'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { OopSegmentSelection } from '@etta/components/out-of-policy-modal/types'
import type { OutOfPolicyReasonValueObject } from '@etta/core/value-objects'
import type {
  OopBySegmentValueObject,
  OopSegmentReasonValueObject,
} from '@etta/modules/review-trip/core/value-objects/oop-by-segment.value-object'
import type { OOPSelections } from '@etta/modules/review-trip/core/value-objects/oop-selections'

@Store()
export class OutOfPolicyStore {
  oopConfiguration: OutOfPolicyReasonValueObject[] = []
  oopExplanationPersist = new ItineraryStorage<string | null>('oopExplanation', null)

  private oopSegmentsPersist = new ItineraryStorage<OopBySegmentValueObject>('oopSegments', {
    carrental: null,
    air: null,
    hotel: null,
    rail: null,
  })

  private oopSegmentsReasonPersist = new ItineraryStorage<OopSegmentReasonValueObject>(
    'oopSegmentsReason',
    {
      carrentalReason: null,
      airReason: null,
      hotelReason: null,
      railReason: null,
    },
  )

  private oopSelectionsPersist = new ItineraryStorage<OopSegmentSelection>('oopSelections', {
    flight: null,
    hotel: null,
    carRentals: null,
    rail: null,
    description: null,
  })

  isOOPConfigurationLoading = false
  isUpdatingOOP = false

  get oopSegments() {
    return this.oopSegmentsPersist.values
  }

  get oopSegmentsReason() {
    return this.oopSegmentsReasonPersist.values
  }

  get oopSelections() {
    return this.oopSelectionsPersist.values
  }

  get oopExplanation() {
    return this.oopExplanationPersist.values
  }

  setOOPConfiguration(oopConfiguration: OutOfPolicyReasonValueObject[]) {
    this.oopConfiguration = oopConfiguration
  }

  setIsOOPConfigurationLoading(isLoading: boolean) {
    this.isOOPConfigurationLoading = isLoading
  }

  setIsUpdatingOOP(isUpdating: boolean) {
    this.isUpdatingOOP = isUpdating
  }

  setOOPSelection(oopSelection: OOPSelections) {
    this.oopSelectionsPersist.set(oopSelection.oopSelections)
    this.oopExplanationPersist.set(oopSelection.oopExplanation)
    this.oopSegmentsPersist.set(oopSelection.oopSegments)
    this.oopSegmentsReasonPersist.set(oopSelection.oopSegmentsReason)
  }

  dropCarRentalOopState() {
    this.setOOPSelection({
      oopExplanation: this.oopExplanation,
      oopSegments: { ...this.oopSegments, carrental: null },
      oopSegmentsReason: {
        ...this.oopSegmentsReason,
        carrentalReason: null,
      },
      oopSelections: {
        ...this.oopSelections,
        carRentals: null,
      },
    })
  }

  dropHotelOopState() {
    this.setOOPSelection({
      oopExplanation: this.oopExplanation,
      oopSegments: { ...this.oopSegments, hotel: null },
      oopSegmentsReason: {
        ...this.oopSegmentsReason,
        hotelReason: null,
      },
      oopSelections: {
        ...this.oopSelections,
        hotel: null,
      },
    })
  }

  dropAirOopState() {
    this.setOOPSelection({
      oopExplanation: this.oopExplanation,
      oopSegments: { ...this.oopSegments, air: null },
      oopSegmentsReason: {
        ...this.oopSegmentsReason,
        airReason: null,
      },
      oopSelections: {
        ...this.oopSelections,
        flight: null,
      },
    })
  }

  dropOopState() {
    this.oopExplanationPersist.set(null)
    this.oopSegmentsPersist.set({
      carrental: null,
      air: null,
      hotel: null,
      rail: null,
    })
    this.oopSelectionsPersist.set({
      flight: null,
      hotel: null,
      carRentals: null,
      rail: null,
      description: null,
    })
    this.oopSegmentsReasonPersist.set({
      carrentalReason: null,
      airReason: null,
      hotelReason: null,
      railReason: null,
    })
    this.oopConfiguration = []
    this.isUpdatingOOP = false
  }
}
