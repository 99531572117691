import { css } from 'styled-components'
import { basePrimaryFontColor, screenSize } from '@fiji/style'

export const deemHeaderStyled = css`
  .deem-header {
    .deem-chrome-header {
      padding: 0;
    }

    .deem-chrome-meeting-assist-msg {
      background-color: transparent;
      font-size: 14px;
    }

    .deem-chrome-menu-items {
      z-index: 11;
    }

    @media (${screenSize.maxTablet}) {
      .deem-chrome-navbar .deem-chrome-menu:hover .deem-chrome-menu-head {
        min-width: auto;
      }

      .deem-chrome-navbar .deem-chrome-menu-items {
        min-width: auto;
      }

      .deem-chrome-navbar {
        min-width: auto;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          width: 40px;
          background-image: linear-gradient(
            to right,
            rgba(5, 130, 232, 0),
            ${basePrimaryFontColor}
          );
        }

        .deem-chrome-box {
          > div:last-child {
            overflow: auto;
            display: flex;
            white-space: nowrap;
          }
        }
      }

      .deem-chrome-box {
        min-width: auto;
      }

      .deem-chrome-logo {
        width: 100px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .deem-chrome-branding {
        height: 50px;
      }

      .deem-chrome-actions {
        width: calc(100% - 100px);
        display: flex;
        justify-content: flex-end;
      }

      .deem-chrome-company {
        width: auto;
        line-height: 1;
        height: 50px;
      }

      .deem-chrome-company-head {
        padding: 0;
        height: 50px;
        line-height: 1;
        display: flex;
        align-items: center;
      }

      .deem-chrome-company-name {
        line-height: 1;
        font-size: 1.5rem;
      }

      .deem-chrome-navbar {
        .deem-chrome-menu-head {
          min-width: auto;
          padding-left: 1.4rem;
        }
      }

      .deem-chrome-menu-head {
        padding: 0 0 0 2px;
        min-width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 300;
      }

      .deem-chrome-navbar-link {
        font-size: 1.2rem;
        font-weight: 300;
        margin-left: 16px;

        &:last-child {
          padding-right: 30px;
        }
      }
    }

    @media (${screenSize.maxMobile}) {
      .deem-chrome-company-head {
        display: none;
      }
    }
  }
`
