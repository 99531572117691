import { Icon } from '@etta/ui/icon'
import type { AddButtonComponentProps } from '../../../types'
import { Container, Text } from './trip-review-add-button-mobile-styled'

export function TripReviewAddButtonMobile({
  isDisabled,
  onClick,
  iconName,
  ariaLabel,
  label,
  iconColor,
  isSingle,
}: AddButtonComponentProps) {
  return (
    <Container
      disabled={isDisabled}
      onClick={onClick}
      aria-label={ariaLabel}
      isSingle={isSingle}
      data-tracking-id={`trip-review-add-button_${label}`}>
      <Icon name={iconName} size="medium" color={iconColor} />
      <Text>{label}</Text>
    </Container>
  )
}
