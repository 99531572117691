import React from 'react'

export function useExpand() {
  const [isExpanded, setExpanded] = React.useState(false)

  const toggleExpansion = () => {
    setExpanded((prevState) => !prevState)
  }

  return {
    isExpanded,
    toggleExpansion,
  }
}
