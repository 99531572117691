import { useTranslation } from 'react-i18next'
import type { CovidStatisticItem } from '../../../../types'

type Props = {
  item: CovidStatisticItem
  i18nBase: string
}

const i18nKeysByType: Record<Props['item']['type'], string> = {
  daily: 'Daily',
  total: 'Total',
  trend: 'Trend',
}

export function useCovidInformationStatisticItem({ item, i18nBase }: Props) {
  const { t } = useTranslation()
  const isTrend = item.type === 'trend'
  // We have to check if type is trend, otherwise typescript narrowing won't work
  const isGrowing = item.type === 'trend' ? item.isGrowing : false
  let value: string

  if (isTrend) {
    value = `${isGrowing ? '+' : '-'}${item.value}%`
  } else {
    value = formatTotalValue(item.value)
  }

  return {
    label: t(`${i18nBase}.${i18nKeysByType[item.type]}`),
    value,
    isTrend,
    isGrowing,
  }
}

const THOUSAND = 1000
const MILLION = 1000000

function formatTotalValue(value: number): string {
  if (value >= MILLION) {
    return `${(value / MILLION).toFixed(1)}M`
  }

  if (value >= THOUSAND) {
    return `${(value / THOUSAND).toFixed(1)}K`
  }

  return String(value)
}
