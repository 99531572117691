import { Store } from '@etta/di'
import type {
  RideHailSuggestionValueObject,
  SuggestedDateValueObject,
} from '../../core/value-objects/ride-hail-suggestion.value-object'
import { toSuggestedDates } from './to-suggested-dates'

@Store()
export class SuggestionStore {
  private _isLoading: boolean = false
  private _isError: boolean = false
  private _suggestions: RideHailSuggestionValueObject[] = []

  get suggestedDates(): SuggestedDateValueObject[] {
    return toSuggestedDates(this._suggestions)
  }

  get suggestions(): RideHailSuggestionValueObject[] {
    return this._suggestions
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  get isError(): boolean {
    return this._isError
  }

  setSuggestions(value: RideHailSuggestionValueObject[]) {
    this._suggestions = value
  }

  setIsLoading(value: boolean) {
    this._isLoading = value
  }

  setIsError(value: boolean) {
    this._isError = value
  }

  dropStore() {
    this._isLoading = false
    this._isError = false
    this._suggestions = []
  }
}
