import { Action, Inject } from '@etta/di'
import type { TrainServiceActiveTab } from '@fiji/enums'
import { RailLegType } from '@fiji/enums'
import { ROUTES } from '@fiji/routes'
import i18n from '@fiji/i18n'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { TripActions } from '@etta/modules/review-trip/interface/actions/trip.actions'
import { RailSearchFormStore } from '@etta/modules/rail-search-form/interface/stores/rail-search-form.store'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { RailETicketTypeEnum } from '../../infra/types'
import type { RailSortByEnum } from '../../infra/types'
import type { RailFareValueObject } from '../../core/value-objects/rail-fare.value-object'
import { RailStore } from '../stores/rail.store'
import { RailQueryParamsStore } from '../stores/rail-query-param.store'
import type { SearchRailLegEntity } from '../../core/entities/search-rail-leg.entity'
import type { RailSegmentTravelClassEnum } from '../../core/enums/rail-segment-travel-class.enum'
import { RailService } from '../services/rail.service'
import { RailQueryParamsService } from '../services/rail-query-params.service'
import { FiltersStore } from '../stores/filters.store'
import { RailEmissionsService } from '../services/rail-emissions.service'
import type { PermittedStationsInfoValueObject } from '../../core/value-objects/permitted-stations-info.value-object'

@Action()
export class RailActions {
  constructor(
    @Inject()
    private readonly railStore: RailStore,
    @Inject()
    private readonly railService: RailService,
    @Inject()
    private readonly railEmissionsService: RailEmissionsService,
    @Inject()
    private readonly railFiltersStore: FiltersStore,
    @Inject()
    private readonly queryParamsStore: RailQueryParamsStore,
    @Inject()
    private readonly railSearchFormStore: RailSearchFormStore,
    @Inject()
    private readonly railQueryParamsService: RailQueryParamsService,
    @Inject()
    private readonly tripActions: TripActions,
  ) {}

  async handleSearch(legType?: RailLegType) {
    await this.railService.getRailSearchResults(legType)
  }

  handleRailDetailsOpen(legId: string) {
    this.railStore.setActiveLegId(legId)
    this.railStore.setRailDetailsOpen()
  }

  handleTripStatusClose() {
    this.railStore.setSelectRailStatus(null)
    this.railStore.setRailDetailsClose()
  }

  handleRailDetailsClose() {
    this.railStore.setRailDetailsClose()
  }

  handlePolicyModalOpen() {
    this.railStore.setPolicyModalOpen()
  }

  handlePolicyModalClose() {
    this.railStore.setPolicyModalClose()
  }

  handleTicketRestrictionsInfoOpen(tierName: string, railLeg: SearchRailLegEntity) {
    const tripDateStr =
      tripReviewFormatDate(
        TripReviewDateType.DayAndDate,
        railLeg.segments[0].departureDate.toString(),
      ) || ''

    const originCity = railLeg.segments[0].origin.name
    const destinationCity = railLeg.segments[railLeg.segments.length - 1].destination.name
    const tripRouteStr = `${i18n.t('RailDetails.TrainFromTo', {
      origin: originCity,
      destination: destinationCity,
    })}`

    const tripFareStr =
      this.railStore.fareConditions?.find((item) => item.tier.name === tierName)
        ?.localizedFareConditions[0].fareTypeDescription || ''

    const ticketRestrictions =
      this.railStore.fareConditions?.find((item) => item.tier.name === tierName)
        ?.localizedFareConditions[0].conditions || []

    this.railStore.setTicketRestrictionsInfo({
      tripDate: tripDateStr,
      tripRoute: tripRouteStr,
      tripFare: tripFareStr,
      ticketRestrictions,
    })

    this.railStore.setTicketRestrictionsOpen()
  }

  handlePermittedStationsInfoOpen(permittedStationsInfo: PermittedStationsInfoValueObject) {
    this.railStore.setPermittedStationsInfo(permittedStationsInfo)

    this.railStore.setPermittedStationsOpen()
  }

  handleTicketRestrictionsInfoClose() {
    this.railStore.setTicketRestrictionsClose()
  }

  handlePermittedStationsInfoClose() {
    this.railStore.setPermittedStationClose()
  }

  handleFiltersModalOpen() {
    if (this.railStore.isError || this.railStore.isLoading) {
      return
    }

    this.railStore.setFiltersModalOpen()
  }

  handleFiltersModalClose() {
    this.railStore.setFiltersModalClose()
  }

  handleTrainServiceActiveTab(activeTab: TrainServiceActiveTab) {
    this.railStore.setTrainServiceActiveTab(activeTab)
  }

  async onSortByChange(value: RailSortByEnum) {
    this.railStore.setSortBy(value)
    await this.handleSearch()
  }

  async selectFareSeats(railLegType: RailLegType, railFare: RailFareValueObject) {
    await this.railService.selectFareSeats(railLegType, railFare, this.queryParamsStore.bookingId)
  }

  async getRailEmissions(travelClass?: RailSegmentTravelClassEnum) {
    await this.railEmissionsService.getEmissions(travelClass)
  }

  async createRailSearch() {
    if (!this.queryParamsStore.searchId) {
      await this.railService.createRailSearch()
    }
  }

  async getETickets(itineraryId: string, type: RailETicketTypeEnum = RailETicketTypeEnum.Pdf) {
    return this.railService.getETickets(itineraryId, type)
  }

  async handleRefetchRequest() {
    await this.fetchItineraryId()

    if (this.railStore.isCreateRailSearchError) {
      await this.railService.createRailSearch()
    }
    if (this.railStore.isGetRailError) {
      await this.railService.getRailSearchResults()
    }
  }

  async fetchItineraryId() {
    if (this.queryParamsStore.itineraryId) {
      this.tripActions.setTripId(this.queryParamsStore.itineraryId)
    } else {
      await this.createItinerary()
    }
  }

  async handleSearchFormSubmit() {
    this.dropStore()
    // isEditMode indicated that the search form appears on either RTP or Rail search result screen
    if (this.railSearchFormStore.isEditMode) {
      // FIXME: FRI-82 itinerary creation is a temporary approach until the rail removal is ready.
      await this.createItinerary()
      await this.railService.createRailSearch()
      await this.railService.getRailSearchResults()
      await this.railEmissionsService.getEmissions()
    }
  }

  private async createItinerary() {
    this.railStore.setIsLoading(true)
    const newTripId = await this.tripActions.createTrip()
    this.railStore.setIsLoading(false)
    this.railQueryParamsService.appendQueryParams({ itineraryId: newTripId })
  }

  appendQueryParams(params: Partial<RailSearchQueryType>) {
    this.railQueryParamsService.appendQueryParams(params)
  }

  onBackToSearch() {
    this.railQueryParamsService.navigateTo(ROUTES.rail.search, {
      filters: {},
    })
  }

  onBackByType(railLegType: RailLegType) {
    if (railLegType === RailLegType.Return) {
      // FIXME: FRI-82 this will remove the itinerary and affect other segment
      this.railService.removeItinerary()
      this.railQueryParamsService.navigateTo(
        ROUTES.rail.results({ railLegType: RailLegType.Departure }),
        {
          filters: {},
        },
      )
    } else {
      this.onBackToSearch()
    }
    this.railStore.setCurrentPage(0)
    this.railStore.setEditTimeModalClose()
  }

  dropStore() {
    this.railStore.dropStore()
    this.railFiltersStore.dropStore()

    this.railStore.setCurrentPage(0)
    this.railStore.setEditTimeModalClose()
  }
}
