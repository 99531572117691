import type { Maybe } from 'fnscript'
import { Just, Nothing } from 'fnscript'
import { DeviceClass } from '@etta/core/enums'
import { Adapter, Inject } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import type { GetSearchInitiateInfoQuery } from '@fiji/graphql/types'
import { ScreenType, screenMatcher } from '@fiji/modes'
import { SearchInitiateInfoDataProvider } from './search-initiate-info.data-provider'

@Adapter()
export class PreSearchAdapter {
  constructor(
    @Inject()
    private searchInitiateInfoDataProvider: SearchInitiateInfoDataProvider,
  ) {}

  async getSearchInitiateInfo(): Promise<Maybe<GetSearchInitiateInfoQuery>> {
    const deviceClass =
      screenMatcher.getScreenType() === ScreenType.Desktop
        ? DeviceClass.Desktop
        : DeviceClass.Mobile

    const { data, error } = await this.searchInitiateInfoDataProvider.getSearchInitiateInfo({
      deviceClass,
    })

    if (error) {
      return Nothing()
    }

    return Just(data)
  }
}
