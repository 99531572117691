import { useRef } from 'react'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates'
import type { HotelDetailsType } from '../types'
import { useRoomRatesModal } from './use-room-rates-modal'

type Args = {
  hotelDetails: HotelDetailsType
}

export function useHotelDetailsModal({ hotelDetails }: Args) {
  const { featureFlags } = useFeatureFlags()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const {
    hotelId,
    name,
    checkIn,
    checkOut,
    address,
    phoneNumber,
    amenities,
    exclusiveAmenities,
  } = hotelDetails
  const {
    onRoomDetailsSelect,
    roomDetails,
    isRoomDetailsOpen,
    onRoomDetailsClose,
  } = useRoomRatesModal({
    hotelId,
    name,
    checkIn: isoToDate(checkIn),
    checkOut: isoToDate(checkOut),
  })

  const isAddressCardVisible = !!(address || phoneNumber)
  const isAmenitiesVisible = !!(amenities.length || exclusiveAmenities.length)
  const totalNights = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )

  return {
    isAmenitiesVisible,
    isAddressCardVisible,
    onRoomDetailsSelect,
    roomDetails,
    isRoomDetailsOpen,
    onRoomDetailsClose,
    isMobile,
    featureFlags,
    scrollContainerRef,
    totalNights,
  }
}
