import type {
  ChangeFee,
  Decode,
  GetUnusedTicketsQuery,
  Maybe,
  OriginalCost,
} from '@fiji/graphql/types'
import type { UnusedTicketValueObject } from '../../core/value-objects/unused-ticket.value-object'

export class UnusedTicketsMapper {
  static toValueObject(
    unusedTickets: GetUnusedTicketsQuery['unusedTickets']['unusedTickets'],
  ): UnusedTicketValueObject[] {
    return unusedTickets.map((unusedTicket) => {
      return {
        ...(unusedTicket?.airline && this.toAirline(unusedTicket.airline)),
        ...(unusedTicket?.airlineCode && {
          airlineCode: unusedTicket?.airlineCode,
        }),
        ...(unusedTicket?.expirationDate && {
          expirationDate: unusedTicket?.expirationDate,
        }),
        ...(unusedTicket?.recordLocator && {
          recordLocator: unusedTicket?.recordLocator,
        }),
        ...(unusedTicket?.ticketNumber && {
          ticketNumber: unusedTicket?.ticketNumber,
        }),
        ...(unusedTicket?.changeFee && this.toChangeFee(unusedTicket.changeFee)),
        ...(unusedTicket?.originalCost && this.toOriginalCost(unusedTicket.originalCost)),
        ...(unusedTicket?.status && {
          status: unusedTicket?.status,
        }),
      } as UnusedTicketValueObject
    })
  }

  private static toAirline(airline: Maybe<Pick<Decode, 'code' | 'imageUrl' | 'value'>>) {
    return {
      airline: {
        code: airline?.code ? airline.code : undefined,
        imageUrl: airline?.imageUrl ? airline.imageUrl : undefined,
        value: airline?.value ? airline.value : undefined,
      },
    }
  }

  private static toChangeFee(
    changeFee: Maybe<
      Pick<ChangeFee, 'amount' | 'code' | 'requestedCurrencyAmount' | 'requestedCurrencyUnit'>
    >,
  ) {
    return {
      changeFee: {
        amount: this.toAmount(changeFee?.amount),
        code: changeFee?.code ? changeFee.code : undefined,
        requestedCurrencyAmount: this.toAmount(changeFee?.requestedCurrencyAmount),
        requestedCurrencyUnit: changeFee?.requestedCurrencyUnit
          ? changeFee.requestedCurrencyUnit
          : undefined,
      },
    }
  }

  private static toOriginalCost(
    originalCost: Maybe<
      Pick<OriginalCost, 'amount' | 'code' | 'requestedCurrencyAmount' | 'requestedCurrencyUnit'>
    >,
  ) {
    return {
      originalCost: {
        amount: this.toAmount(originalCost?.amount),
        code: originalCost?.code ? originalCost.code : undefined,
        requestedCurrencyAmount: this.toAmount(originalCost?.requestedCurrencyAmount),
        requestedCurrencyUnit: originalCost?.requestedCurrencyUnit
          ? originalCost.requestedCurrencyUnit
          : undefined,
      },
    }
  }

  private static toAmount(amount: Maybe<number> | undefined) {
    return amount !== undefined ? amount : undefined
  }
}
