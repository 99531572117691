import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightsDesktopLayout } from './desktop'
import { FlightsMobileLayout } from './mobile'

export function FlightsLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightsMobileLayout {...props} />}
      desktopSlot={<FlightsDesktopLayout {...props} />}
    />
  )
}
