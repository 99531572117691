import { useTranslation } from 'react-i18next'
import { RailLayoutType } from '@fiji/enums'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { Spinner } from '@etta/ui/spinner'
import { Text } from '@etta/ui/text'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import type { RailCardModalProps } from '../../types'
import { RailCardModalContent } from '../../rail-card-modal-content/rail-card-modal-content'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

export function RailCardModalMobile({
  savedCards,
  cardGroups,
  onClose,
  onSelectUKCard,
  onSelectEUCard,
  layout,
  cardsLoading,
  errorText,
  isOpen,
}: RailCardModalProps) {
  const { t } = useTranslation()
  const { anchorRef, scrollContainerRef } = useHeaderScroll()

  const title =
    layout === RailLayoutType.UK ? t(i18Base + '.SelectRailCard') : t(i18Base + '.Cards')

  return (
    <Modal data-tracking-id={'rail-cards-modal'} isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body ref={scrollContainerRef}>
        {cardsLoading && <Spinner type="spinout" />}
        {!cardsLoading && errorText && (
          <Block marginHorizontal={20} marginVertical={20}>
            <Text variant="captionStrong" color="primary">
              {errorText}
            </Text>
          </Block>
        )}
        {!cardsLoading && !errorText && (
          <RailCardModalContent
            savedCards={savedCards}
            cardGroups={cardGroups}
            onClose={onClose}
            onSelectUKCard={onSelectUKCard}
            onSelectEUCard={onSelectEUCard}
            layout={layout}
            anchorRef={anchorRef}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
