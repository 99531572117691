import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { useGlobalModalsContext } from '@etta/modules/global-modals/interface/global-modals.context'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { ScreenType, screenMatcher } from '@fiji/modes'

type Props = {
  onExpire?: () => void
}

export function useTripExpiredTimer({ onExpire }: Props) {
  const { globalModalsStore } = useGlobalModalsContext()
  const { tripExpiredModalToggle } = globalModalsStore
  const tripExpiredModal = tripExpiredModalToggle

  const { reviewTripStore } = useReviewTripContext()
  const { itineraryExpiredDate } = reviewTripStore

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const { pause, restart, isRunning } = useTimer({
    expiryTimestamp: itineraryExpiredDate ?? new Date(),
    onExpire: () => {
      onExpire?.()
      if (!tripExpiredModal.isOpen) {
        tripExpiredModal.handleOpen()
      }
    },
    autoStart: false,
  })

  useEffect(() => {
    if (isMobile) {
      pause()
      return
    }

    if (itineraryExpiredDate && !isRunning) {
      restart(itineraryExpiredDate)
    }
  }, [itineraryExpiredDate, restart, pause, isRunning, isMobile])

  return {
    tripExpiredModal,
  }
}
