import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'

type Props = {
  segment: TripDetailsFlightLegSegment
}

export function useCabinClass({ segment }: Props) {
  const { getCabinClassLabel } = useCabinClassLabels()

  const cabinClass = segment?.serviceClass ? getCabinClassLabel(segment?.serviceClass) : '-'
  const seat = segment.travelerSeat || '-'

  const baggageClaim = segment.baggageClaim || '-'

  return {
    cabinClass,
    seat,
    baggageClaim,
  }
}
