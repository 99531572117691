import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace BrandConfigurationErrors {
  export const BrandConfigurationResponse = createErrorClass('Failed brand configuration')
  export const BrandConfigurationUnexpectedError = createErrorClass(
    'Unexpected error brand configuration',
  )
}

export type BrandConfigurationErrorsInstances = InstanceType<
  typeof BrandConfigurationErrors[keyof typeof BrandConfigurationErrors]
>
