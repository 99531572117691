import type { ReactNode } from 'react'
import type { Option } from '../types'
import { Container, CheckmarkIconWrapper, Title } from './menu-option-components'

type Props<T> = {
  isActive?: boolean
  activeOptionIcon?: ReactNode
  option: Option<T>
  onOptionSelect: () => void
  tabIndex: number
}

export function MenuOption<T>({
  isActive,
  activeOptionIcon,
  option,
  onOptionSelect,
  tabIndex,
}: Props<T>) {
  const iconComponent = (() => {
    if (isActive && activeOptionIcon) {
      return activeOptionIcon
    } else if (option.icon) {
      return option.icon
    }

    return null
  })()

  return (
    <Container
      aria-label={option?.ariaLabel}
      tabIndex={tabIndex}
      role="menuitem"
      aria-disabled={false}
      onClick={onOptionSelect}>
      <CheckmarkIconWrapper aria-hidden>{iconComponent}</CheckmarkIconWrapper>

      <Title>{option.name}</Title>
    </Container>
  )
}
