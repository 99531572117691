import { useTranslation } from 'react-i18next'
import { Icon } from '../../icon'
import { Text } from '../../text'
import { Container } from './success-notification-styled'

type Props = {
  isVisible: boolean
}

export function SuccessNotification({ isVisible }: Props) {
  const { t } = useTranslation()

  return (
    <Container isVisible={isVisible}>
      <Icon name="tickPWA" color="mainText2" size="large" />
      <Text variant="subHeadMedium" color="mainText2">
        {t('CopyableValue.Copied')}
      </Text>
    </Container>
  )
}
