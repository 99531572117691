import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { CarRentalDetailsMap } from '@etta/components/car-rental-details-map/car-rental-details-map'
import type { CarRentalLocationLayoutProps } from '../../types'
import {
  Container,
  Content,
  MapWrapper,
  Location,
  LocationItem,
} from './car-rental-location-desktop-styled'

export function CarRentalLocationDesktop({
  locations,
  onAddressClick,
}: CarRentalLocationLayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalModal.'

  return (
    <Container>
      <Content>
        <Location>
          <LocationItem>
            <Text
              aria-label={t(i18Base + 'PickUpTitle')}
              variant="captionStrongCaps"
              color="bodyText1"
              role="heading"
              aria-level={5}>
              {t(i18Base + 'PickUpTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text aria-label={locations.pickUp.date} variant="subHeadStrong" color="mainText">
              {locations.pickUp.date}
            </Text>
          </LocationItem>
          <Text aria-label={locations.pickUp.location} variant="footnoteMedium" color="bodyText">
            {locations.pickUp.location}
          </Text>
        </Location>
        <Location>
          <LocationItem>
            <Text
              aria-label={t(i18Base + 'DropOffTitle')}
              variant="captionStrongCaps"
              color="bodyText1"
              role="heading"
              aria-level={5}>
              {t(i18Base + 'DropOffTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text aria-label={locations.dropOff.date} variant="subHeadStrong" color="mainText">
              {locations.dropOff.date}
            </Text>
          </LocationItem>
          <Text aria-label={locations.dropOff.location} variant="footnoteMedium" color="bodyText">
            {locations.dropOff.location}
          </Text>
        </Location>
      </Content>
      {locations.geocode && (
        <MapWrapper
          role="button"
          aria-label={t('HotelResultPage.ViewMapButtonAreaLabel')}
          onClick={onAddressClick}>
          <CarRentalDetailsMap
            fullHeight
            isTopRounded={false}
            isBottomRounded={false}
            carCoords={locations.geocode}
          />
        </MapWrapper>
      )}
    </Container>
  )
}
