import { Inject } from 'typedi'
import { Service } from '@etta/di'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { SupportTravelAdapter } from '../../infra/support-travel.adapter'
import { SupportTravelStore } from '../stores/support-travel.store'

const NUMBER_OF_ARTICLES = 6
@Service()
export class GetSupportArticleService {
  constructor(
    @Inject() private travelInfoAdapter: SupportTravelAdapter,
    @Inject() private travelInfoStore: SupportTravelStore,
  ) {}

  async getSupportArticle() {
    this.travelInfoStore.setIsLoading(true)
    this.travelInfoStore.setIsError(false)

    const deviceClass = screenMatcher.getScreenType() === ScreenType.Mobile ? 'Mobile' : 'Desktop'

    const result = await this.travelInfoAdapter.getSupportArticles({
      offset: this.travelInfoStore.isPreview ? NUMBER_OF_ARTICLES : 0,
      deviceClass,
    })
    result.match({
      Err: () => {
        this.travelInfoStore.setIsError(true)
      },
      Ok: (data) => {
        this.travelInfoStore.setSupportArticles(data)
      },
    })

    this.travelInfoStore.setIsLoading(false)
  }

  setIsPreview(isPreview: boolean) {
    this.travelInfoStore.setIsPreview(isPreview)
  }
}
