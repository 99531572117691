import type { ReactNode } from 'react'
import { useState } from 'react'
import { Container } from './show-more-components'

type Props = {
  notExpandedContent: ReactNode
  expandedContent: ReactNode
  isExpandedDefaultValue?: boolean
  renderButton: (isExpanded: boolean, handleButtonClick: () => void) => ReactNode
}

export function ShowMore({
  notExpandedContent,
  expandedContent,
  isExpandedDefaultValue = false,
  renderButton,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedDefaultValue)
  function handleButtonClick() {
    setIsExpanded(!isExpanded)
  }
  return (
    <Container>
      {isExpanded ? <>{expandedContent}</> : <>{notExpandedContent}</>}
      {renderButton && expandedContent && renderButton(isExpanded, handleButtonClick)}
    </Container>
  )
}
