import { useTranslation } from 'react-i18next'
import { CovidInformationStatisticRows } from '../../covid-information-statistic-rows'
import type { LayoutProps } from '../types'
import { Container, Label, List } from '../covid-information-statistic-styled'
import { Title, Separator } from './covid-information-statistic-layout-mobile-styled'

export function CovidInformationStatisticLayoutMobile({
  covidStatistic,
  i18nBase,
  shouldColumnNameRender = true,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      {shouldColumnNameRender && <Title>{covidStatistic.name}</Title>}
      <Label>{t(`${i18nBase}.Cases`)}</Label>
      <List data-tracking-id="covid-list_cases">
        <CovidInformationStatisticRows list={covidStatistic.cases} i18nBase={i18nBase} />
      </List>
      <Separator />
      <Label>{t(`${i18nBase}.Deaths`)}</Label>
      <List data-tracking-id="covid-list_deaths">
        <CovidInformationStatisticRows list={covidStatistic.deaths} i18nBase={i18nBase} />
      </List>
    </Container>
  )
}
