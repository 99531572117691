import { Modal } from '@etta/ui/modal'
import type { Props } from './types'
import { DuplicateTripsModalContent } from './duplicate-trips-modal-content'

export function DuplicateTripsModal({ isOpen, onClose, onApply, overlappingTrips, errors }: Props) {
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose} withMaxHeight>
      <DuplicateTripsModalContent
        onApply={onApply}
        errors={errors}
        onClose={onClose}
        overlappingTrips={overlappingTrips}
      />
    </Modal>
  )
}
