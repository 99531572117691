import { Skeleton } from '@etta/ui/skeleton'

export function FlightSafetyCheckSkeleton() {
  return (
    <Skeleton width={328} height={75} variant="secondary1">
      <rect width={132} height={17} />
      <rect y={29} width={255} height={11} />
      <rect y={60} width={71} height={11} />
    </Skeleton>
  )
}
