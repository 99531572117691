import type { MutableRefObject } from 'react'
import { useEffect, useCallback } from 'react'

type Args = {
  elementHeight?: number
  elementWidth?: number
  isVisible: boolean
  ref?: MutableRefObject<HTMLElement | null>
}

const OFFSET = 10

export function useShouldOpenInViewport({
  elementHeight: customElementHeight,
  elementWidth: customElementWidth,
  isVisible,
  ref,
}: Args) {
  const handleReposition = useCallback(() => {
    if (!ref?.current || !isVisible) {
      return
    }
    const rect = ref.current.getBoundingClientRect()
    const { height, top, width, left } = rect
    const elementHeight = customElementHeight || height
    const elementWidth = customElementWidth || width
    const viewPortHeight = window.innerHeight
    const viewPortWidth = window.innerWidth
    const bottomOffset = Math.max(viewPortHeight - top - elementHeight, -elementHeight / 2)
    const rightOffset = Math.max(viewPortWidth - left - elementWidth, -elementWidth / 2)
    if (top < 0) {
      ref.current.style.top = `${-top + OFFSET}px`
    }
    if (bottomOffset < 0) {
      ref.current.style.top = `${bottomOffset - OFFSET}px`
    }
    if (left < 0) {
      ref.current.style.left = `${-left + OFFSET}px`
    }
    if (rightOffset < 0) {
      ref.current.style.left = `${rightOffset - OFFSET}px`
    }
  }, [customElementHeight, customElementWidth, isVisible, ref])

  useEffect(() => {
    handleReposition()
  }, [handleReposition])
}
