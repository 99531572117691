import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money'
import { TrainDeliveryOptionCode } from '@fiji/enums'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { SegmentType } from '@fiji/graphql/types'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { RailSegment } from '../../../../types'
import type { SegmentDetails } from '../../types'
import { getCreditCardLabel } from '../../utils'
import { getRailTrains } from './get-rail-trains'

type Args = {
  railDetails: SegmentDetails['railDetails']
}

const i18nBase = 'PostBooking.TripDetails.SegmentsHeadline'

export function useRail({ railDetails }: Args) {
  const { t } = useTranslation()
  const { travelerInfo } = useCheckoutContextSelector((state) => state)
  const { postBookingTripStore } = usePostBookingContext()
  const { railPreferences, personalInfo } = travelerInfo

  const { segments, tripCost, creditCard } = railDetails

  const { mainCost, secondCost } = useMemo(() => {
    return formatRate(tripCost.total)
  }, [tripCost])

  const trains = useMemo(() => {
    return getRailTrains(segments)
  }, [segments])

  const creditCardLabel = useMemo(() => {
    if (!creditCard) {
      return
    }

    return getCreditCardLabel(creditCard)
  }, [creditCard])

  const railDeliveryCode = railPreferences.value.isETicket
    ? TrainDeliveryOptionCode.Eticket
    : TrainDeliveryOptionCode.Collect

  if (!segments?.length) {
    return
  }

  const railSegment = postBookingTripStore.segments.find(
    (segment) => segment.type === SegmentType.Train,
  ) as TrainPostBookingValueObject
  const rail: RailSegment = {
    details: {
      title: t(`${i18nBase}.Trains`),
    },
    payment: {
      title: t(`${i18nBase}.Payment`),
      mainTotal: mainCost,
      card: creditCardLabel,
      secondaryTotal: secondCost,
    },
    delivery: {
      code: railDeliveryCode,
      isCollectFromStationAvailable: !railPreferences.value.isStationTicketMachineDisabled,
      countStations: 1700,
      collectFromStation: railPreferences?.departureStationName,
      travelerEmail: railSegment?.traveler?.email || personalInfo?.email,
    },
    trains,
  }

  return rail
}
