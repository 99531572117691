import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { TripCancellationDateLayout } from './layout'

type Props = {
  date: string
}

export function TripCancellationDate({ date }: Props) {
  const { t } = useTranslation()
  const label = t('PostBooking.TripDetails.CanceledHeader', {
    date: dateFormat(new Date(deleteTimezone(date)), 'eeee, MMM d'),
  })

  return <TripCancellationDateLayout label={label} />
}
