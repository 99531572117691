import styled, { css } from 'styled-components'
import { getKeyboardNavigationStyle, tabs } from '@fiji/style'

type ContainerProps = {
  isActive: boolean
  isDisabled?: boolean
  tabBasis?: number
  tabIndex?: number
}

export const TabContainer = styled.button<ContainerProps>`
  text-align: center;
  flex: ${({ tabBasis = 0 }) => `${tabBasis ? 0 : 1} 0 ${tabBasis}px`};
  padding: 8px;
  user-select: none;
  background: none;
  border: none;
  cursor: pointer;
  ${(p) =>
    p.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
  color: ${(p) => (p.isActive ? p.theme.colors.primary : p.theme.colors.bodyText)};
  ${tabs}
  ${getKeyboardNavigationStyle({ offset: -1 })}
`
