import { composeMessageOfElements } from '@fiji/utils/dom-manipulation'
import { isString } from '@fiji/utils/type'
import type { BrandConfiguration } from '@fiji/graphql/hooks'

export function parseMessage(message: string): string {
  let parsed = message.replace(/\\r\\n/g, '<br/>').replace(/\\t/g, ' ')

  try {
    parsed = decodeURIComponent(
      JSON.parse('"' + parsed.replace(/'/g, "\\\\\\'").replace(/%/g, '%25') + '"'),
    )
  } catch {}

  return parsed.replace(/\\+(['"])/g, '$1').replace(/\\[^u]/g, '')
}

export const shortenTextTo = (text: string, maxChars: number) => {
  const shortenedText = String(text).slice(0, maxChars)
  const lastWhitespaceIndex = shortenedText.lastIndexOf(' ')
  return shortenedText.slice(0, lastWhitespaceIndex > 0 ? lastWhitespaceIndex : 0) + '...'
}

export const formatMessage = (message: string) => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = parseMessage(message)
  const testLength = wrapper.textContent ? wrapper.textContent.length : wrapper.innerHTML.length

  if (testLength > 190) {
    composeMessageOfElements(wrapper, 140)
  }
  return wrapper.innerHTML
}

export function trimStart(value: string, symbols = '\\s') {
  if (!isString(value)) {
    throw new TypeError(`Only string value can be trimmed. Provided: ${typeof value}`)
  }

  // eslint-disable-next-line security/detect-non-literal-regexp
  return value.replace(new RegExp(`^[${symbols}]+`), '')
}

// eslint-disable-next-line no-control-regex
const WORDS_PATTERN = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g

export function toWords(text: string, pattern = WORDS_PATTERN): string[] {
  return String(text).match(pattern) || []
}

export function toSnakeCase(text: string): string {
  return toWords(text)
    .reduce((result, word, i) => result + (i ? '_' : '') + word, '')
    .toLowerCase()
}

export function formatLabel(label: string, site: BrandConfiguration | undefined) {
  const isAppleSite = site?.isThemeEnabled && site.theme === 'kan'
  const escapedLabel = label
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f')

  const finalLabel = isAppleSite ? escapedLabel : escapedLabel.replace('\\uF8FF', '')

  try {
    return JSON.parse(`"${finalLabel}"`)
  } catch {
    return finalLabel
  }
}

export function toKebabCase(text: string): string {
  return toWords(text)
    .reduce((result, word, i) => result + (i ? '-' : '') + word, '')
    .toLowerCase()
}

export function concat<T>(items: T[], trailingSeparator = 'and', separator = ','): string {
  if (items.length === 1) {
    return `${items[0]}`
  }
  if (items.length === 2) {
    return `${items[0]} ${trailingSeparator} ${items[1]}`
  }
  if (items.length > 2) {
    // eslint-disable-next-line max-params
    return items.reduce((acc, val, index, arr) => {
      if (index !== arr.length - 1) {
        return `${acc.length ? acc + separator : acc} ${val}`
      }
      return `${acc} ${trailingSeparator} ${val}`
    }, '')
  }
  return ''
}
