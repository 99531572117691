import { useTranslation } from 'react-i18next'
import { HotelDetailsEcoCheckModal } from '@etta/components/hotel-details-eco-check-modal/hotel-details-eco-check-modal'
import type {
  CalculateEmissionsResultHotelCertification,
  CalculateEmissionsBatchResultEquivalence,
} from '@fiji/graphql/types'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useHotelDetailsSustainability } from '../../use-hotel-details-sustainability'
import {
  Container,
  Title,
  Body,
  BodyRight,
  EcoCerfiedLabel,
  LearnMore,
  MainContainer,
  BottomLine,
  EmissionsDescription,
} from './hotel-details-eco-check-desktop-styled'
import { BodyLeftBlock } from './body-left-block'
import { EcoCheckDesktopBackground } from './eco-check-desktop-background'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

export type Props = {
  certifications?: CalculateEmissionsResultHotelCertification[]
  sustainabilityScore: number
  tonnesOfEmissions: number
  averageSustainabilityScore: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function HotelDetailsEcoCheckDesktopComponent(props: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.HotelDetails'
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const {
    isHotelCertificationPresent,
    deviationLevel,
    bodyRightDescription,
  } = useHotelDetailsSustainability(props)

  const { handleKeyDown } = useA11yKeydown(handleOpen)

  return (
    <>
      <Container
        tabIndex={0}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        data-tracking-id="hotel-details-eco-check-action">
        <MainContainer>
          <div>
            <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
              <img src={EcoCheckLogo} alt="eco check logo" />
            </Title>
            <Body>
              <BodyLeftBlock
                deviationLevel={deviationLevel}
                sustainabilityScore={props.sustainabilityScore}
              />
              <BodyRight aria-label={bodyRightDescription}>
                {isHotelCertificationPresent && (
                  <EcoCerfiedLabel
                    aria-label={t('Accessibility.ThisHotelHas') + t(i18nBase + '.EcoCerfified')}>
                    {t(i18nBase + '.EcoCerfified')}
                  </EcoCerfiedLabel>
                )}
                <EmissionsDescription>{bodyRightDescription}</EmissionsDescription>
                <LearnMore
                  onKeyDown={handleKeyDown}
                  aria-label={
                    t('Emissions.DetailsModal.LearnMoreVoice') + ' ' + t('Accessibility.TapToView')
                  }>
                  {t('Emissions.DetailsModal.LearnMore')}
                </LearnMore>
              </BodyRight>
            </Body>
          </div>
          <EcoCheckDesktopBackground deviationLevel={deviationLevel} />
        </MainContainer>
        <BottomLine deviationLevel={deviationLevel} />
      </Container>
      <HotelDetailsEcoCheckModal
        equivalences={props.equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={props.tonnesOfEmissions}
        certifications={props.certifications || []}
        sustainabilityScore={props.sustainabilityScore}
        averageSustainabilityScore={props.averageSustainabilityScore}
      />
    </>
  )
}
