import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { Dates } from '@fiji/hooks/use-date-picker'
import type { ViewState } from '@etta/ui/view-state-modal'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import type { ItineraryMetadataValueObject } from '@etta/modules/post-booking/core/value-objects/book-trip-again/itinerary-metadata.value-object'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import { BookAgainTravelVerticalEnum } from '@etta/modules/post-booking/core/enums'
import { PostBookingTripStore } from '../trip/post-booking-trip.store'

@Store()
export class BookTripAgainStore {
  startDate?: Date | null = undefined
  endDate?: Date | null = undefined

  viewState: ViewState = 'hidden'

  selectedSegments: Set<BookAgainTravelVerticalEnum> = new Set([])

  startDateErrorMessage?: string = undefined
  endDateErrorMessage?: string = undefined

  isBookTripAgainAllowed: boolean = false

  bookTripAgainToggle = new Toggle()

  delegatedId?: number = undefined

  isSwitchDelegateInProgress: boolean = false

  private bookAgainMetadata = new ItineraryStorage<ItineraryMetadataValueObject>('metadata', {
    alignAttemptedSegmentTypes: [],
    hotelMatchDetails: null,
    carRentalMatchDetails: null,
    flightMatchDetails: null,
    flightSearch: null,
    hotelSearch: null,
    carRentalSearch: null,
    startDate: null,
    endDate: null,
  })

  constructor(
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  get metadata() {
    return this.bookAgainMetadata.values
  }

  get isPostBookingBookAgainAllowed(): boolean {
    return (
      this.postBookingTripStore.trip.supportedActions.isBookAgainTripAllowed &&
      this.featureFlagsStore.flags.isBookAgainAllowed
    )
  }

  get isBookTripAgainFailed(): boolean {
    const flightMatchDetails = this.bookAgainMetadata.values.flightMatchDetails
    const hotelMatchDetails = this.bookAgainMetadata.values.hotelMatchDetails
    const carRentalMatchDetails = this.bookAgainMetadata.values.carRentalMatchDetails

    if (!flightMatchDetails && !hotelMatchDetails && !carRentalMatchDetails) {
      return false
    }

    const isFlightMatchFail = !flightMatchDetails?.isExactMatch
    const isHotelMatchFail = !hotelMatchDetails?.isExactMatch
    const isCarMatchFail = !carRentalMatchDetails?.isExactMatch

    return isFlightMatchFail && isHotelMatchFail && isCarMatchFail
  }

  get hasEndDate(): boolean {
    const selectiedValues = Array.from(this.selectedSegments.values())

    if (!selectiedValues.every((segment) => segment === BookAgainTravelVerticalEnum.Air)) {
      return true
    }

    return !!this.postBookingTripStore.flightSegments.every((segment) => segment.isRoundTripLeg)
  }

  onDatesChange(result: Dates) {
    this.startDate = result.date
    this.endDate = result.dateEnd
  }

  resetDates() {
    this.startDate = undefined
    this.endDate = undefined
  }

  onSelectedSegmentsChange(value: boolean, segmentType: BookAgainTravelVerticalEnum) {
    if (value && !this.selectedSegments.has(segmentType)) {
      this.selectedSegments.add(segmentType)
      return
    }
    this.selectedSegments.delete(segmentType)
  }

  onInitializeSelectedSegments(selectedSegments: BookAgainTravelVerticalEnum[]) {
    this.selectedSegments = new Set(selectedSegments)
  }

  setStartDateErrorMessage(msg: string) {
    this.startDateErrorMessage = msg
  }

  setEndDateErrorMessage(msg: string) {
    this.endDateErrorMessage = msg
  }

  resetStartDateErrorMessage() {
    this.startDateErrorMessage = undefined
  }

  resetEndDateErrorMessage() {
    this.endDateErrorMessage = undefined
  }

  setViewState(v: ViewState) {
    this.viewState = v
  }

  setMetadata(metadata: ItineraryMetadataValueObject) {
    this.bookAgainMetadata.set(metadata)
  }

  setDelegatedId(id?: number) {
    this.delegatedId = id
  }

  setIsSwitchDelegateInProgress(bool: boolean) {
    this.isSwitchDelegateInProgress = bool
  }

  dropMetadataHotelData() {
    this.bookAgainMetadata.set({
      ...this.bookAgainMetadata.values,
      hotelMatchDetails: null,
      hotelSearch: null,
    })
  }

  dropMetadataCarRentalData() {
    this.bookAgainMetadata.set({
      ...this.bookAgainMetadata.values,
      carRentalMatchDetails: null,
      carRentalSearch: null,
    })
  }

  dropMetadataFlightData() {
    this.bookAgainMetadata.set({
      ...this.bookAgainMetadata.values,
      flightMatchDetails: null,
      flightSearch: null,
    })
  }

  dropStore() {
    this.bookTripAgainToggle.handleClose()
    this.viewState = 'hidden'
    this.selectedSegments = new Set([])
    this.startDate = undefined
    this.endDate = undefined
    this.startDateErrorMessage = undefined
    this.endDateErrorMessage = undefined
    this.delegatedId = undefined
    this.isSwitchDelegateInProgress = false
  }
}
