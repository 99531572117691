import type { RailCard } from '@fiji/graphql/types'
import type { RailCardGroup } from '../types'

export function getMockedRailUkSavedCardsData(): RailCard[] {
  return [
    {
      vendorCode: '4A',
      vendorName: '4A',
      groupName: '4A',
      name: 'Annual Gold Card',
      code: '45364354353',
    },
    {
      vendorCode: '2B',
      vendorName: '2B',
      groupName: '2B',
      name: 'Cambrian Railcard',
      code: '3432423432342',
    },
    {
      vendorCode: '7A',
      vendorName: '7A',
      groupName: '7A',
      name: 'Dales Railcard',
      code: '342341535345',
    },
    {
      vendorCode: '4C',
      vendorName: '4C',
      groupName: '4C',
      name: 'Esk Valley Railcard',
      code: '346355435346',
    },
  ]
}

export function getMockedRailEuSavedCardsData(): RailCard[] {
  return [
    {
      vendorCode: '2A',
      vendorName: '2A',
      groupName: '2A',
      name: 'Deutsche Bahn',
      code: '45364354353',
    },
    {
      vendorCode: '2V',
      vendorName: '2V',
      groupName: '2V',
      name: 'Amtrak',
      code: '3432423432342',
    },
    {
      vendorCode: '9F',
      vendorName: '9F',
      groupName: '9F',
      name: 'Eurostar',
      code: '346355435345',
    },
  ]
}

export function getMockedRailUkCardsData(): RailCardGroup[] {
  return [
    {
      name: 'POPULAR RAILCARDS',
      cards: [
        {
          vendorCode: '4A',
          vendorName: '4A',
          groupName: '4A',
          name: 'Annual Gold Card',
          code: '45364354353',
        },
        {
          vendorCode: '2B',
          vendorName: '2B',
          groupName: '2B',
          name: 'Cambrian Railcard',
          code: '3432423432342',
        },
        {
          vendorCode: '7A',
          vendorName: '7A',
          groupName: '7A',
          name: 'Dales Railcard',
          code: '342341535345',
        },
      ],
    },
    {
      name: 'Other rail cards',
      cards: [
        {
          vendorCode: '2A',
          vendorName: '2A',
          groupName: '2A',
          name: 'Deutsche Bahn',
          code: '45364354353',
        },
        {
          vendorCode: '2V',
          vendorName: '2V',
          groupName: '2V',
          name: 'Amtrak',
          code: '3432423432342',
        },
        {
          vendorCode: '9F',
          vendorName: '9F',
          groupName: '9F',
          name: 'Eurostar',
          code: '346355435345',
        },
      ],
    },
  ]
}

export function getMockedRailEuCardsData(): RailCardGroup[] {
  return [
    {
      name: 'Eurostar',
      cards: [
        {
          vendorCode: '5F',
          vendorName: '5F',
          groupName: '5F',
          name: 'Eurostar',
          code: '346355435345',
        },
      ],
    },
    {
      name: 'Deutsche Bahn',
      cards: [
        {
          vendorCode: '5B',
          vendorName: '5B',
          groupName: '5B',
          code: '45364354353',
          name: 'BahnBonus Card',
        },
        {
          vendorCode: '5C',
          vendorName: '5C',
          groupName: '5C',
          code: '3432423432342',
          name: 'BahnCard 25 1st Class',
        },
        {
          vendorCode: '5F',
          vendorName: '5F',
          groupName: '5F',
          code: '342341535345',
          name: 'BahnCard 50 1st Class',
        },
      ],
    },
    {
      name: 'SNCF',
      cards: [
        {
          vendorCode: '6A',
          vendorName: '6A',
          groupName: '6A',
          code: '45364354353',
          name: 'BahnBonus Card',
        },
        {
          vendorCode: '6V',
          vendorName: '6V',
          groupName: '6V',
          code: '3432423432342',
          name: 'BahnCard 25 1st Class',
        },
        {
          vendorCode: '6C',
          vendorName: '6C',
          groupName: '6C',
          code: '342341535345',
          name: 'BahnCard 50 1st Class',
        },
      ],
    },
  ]
}
