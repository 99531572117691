import { useCallback } from 'react'
import { Icon as IconComponent } from '@etta/ui/icon'
import type { ResultType } from '../types'
import TrainSearchIcon from './train-search-icon'
import CarRentalIcon from './car-rental-icon'
import CarRentalAlertIcon from './car-rental-alert-icon'
import FlightSearchIcon from './flight-search-icon'
import FlightAlertIcon from './flight-alert-icon'
import HotelSearchIcon from './hotel-search-icon'
import HotelAlertIcon from './hotel-alert-icon'
import SearchPinIcon from './search-pin-icon'
import BookingAdvancedTimeFailIcon from './booking-advanced-time-fail-icon'
import SearchPinIconDesktop from './search-pin-icon-desktop'
import LastPermittedDateIcon from './last-permitted-date-icon'
import LastPermittedDateHoursIcon from './last-permitted-date-hours-icon'
import { IconWrapper } from './icon-styled'

type Props = {
  type: ResultType
}

export function Icon({ type }: Props) {
  const getNoResultIcon = useCallback(() => {
    switch (type) {
      case 'flight':
        return <FlightSearchIcon />
      case 'flight-alert':
        return <FlightAlertIcon />
      case 'last-permitted-date':
        return <LastPermittedDateIcon />
      case 'last-permitted-date-hours':
        return <LastPermittedDateHoursIcon />
      case 'train':
        return <TrainSearchIcon />
      case 'car-rental':
        return <CarRentalIcon />
      case 'car-rental-alert':
        return <CarRentalAlertIcon />
      case 'car-rental-office':
        return <SearchPinIcon />
      case 'hotel':
        return <HotelSearchIcon />
      case 'hotel-alert':
        return <HotelAlertIcon />
      case 'car-rental-office-desktop':
        return <SearchPinIconDesktop />
      case 'car-rental-office-desktop-error':
        return <IconComponent size="medium" name="warningFilledPWA" />
      case 'booking-advanced-time-fail':
        return <BookingAdvancedTimeFailIcon />
      default:
        return null
    }
  }, [type])

  return <IconWrapper>{getNoResultIcon()}</IconWrapper>
}
