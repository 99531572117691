import { Skeleton } from '@etta/ui/skeleton'
import { Container, DecoratedLine, ShadowCard } from './dynamic-site-messages-skeleton-styled'

export function DynamicSiteMessagesSkeleton() {
  return (
    <>
      <Container>
        <DecoratedLine />
        <Skeleton width="100%" height="69px">
          <rect y={14} x={20} width={20} height={20} />
          <rect y={14} x={49} width={259} height={13} />
          <rect y={37} x={49} width={161} height={10} />
        </Skeleton>
      </Container>
      <ShadowCard />
    </>
  )
}
