import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { PostBookingAction } from '@fiji/enums'
import { RailSearchPageMobile } from './mobile/rail-search-page-mobile'
import { RailSearchPageDesktop } from './desktop/rail-search-page-desktop'

type Props = PropsWithChildren<{
  onBack: () => void
  postBookingAction?: PostBookingAction
}>

export const RailSearchPageLayout = (props: Props) => {
  return (
    <MediaLayout
      desktopSlot={<RailSearchPageDesktop {...props} />}
      mobileSlot={<RailSearchPageMobile {...props} />}
    />
  )
}
