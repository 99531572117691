import { Skeleton } from '@etta/ui/skeleton'
import { Container, Header, Body, Left, Right } from './mobility-search-result-skeleton-styled'

export function MobilitySearchResultSkeleton() {
  return (
    <Container>
      <Header>
        <Skeleton width={328} height={64} variant="secondary1" skin="bg-white-round-14">
          <rect x={16} y={0} width={64} height={64} />
          <rect x={92} y={0} width={61} height={14} />
          <rect x={92} y={26} width={29} height={14} />
          <rect x={92} y={48} width={191} height={14} />
        </Skeleton>
      </Header>
      <Body>
        <Left>
          <Skeleton width={71} height={14}>
            <rect width={71} height={14} />
          </Skeleton>
        </Left>
        <Right>
          <Skeleton width={71} height={14}>
            <rect width={71} height={14} />
          </Skeleton>
        </Right>
      </Body>
    </Container>
  )
}
