import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import type { Rate } from '@fiji/graphql/types'
import { useReviewTripContext } from '@etta/modules/review-trip'
import ContextualMessages from '@etta/components/rtp-price-summary/contextual-message/contextual-message'
import { SpaceBetween, Text } from '../rtp-price-summary-styled'
import { FooterCheckoutContainer, FooterCheckoutWrap, InfoButton } from './footer-checkout-styled'

type Props = {
  totalCost: Rate
  isDisabled?: boolean
  isHoldTripAllowed: boolean
  onHold: () => void
  onCheckout: () => void
  onOpenTripCostSummary: () => void
  warningMessages: string[]
}

export function FooterCheckout({
  totalCost,
  isDisabled,
  onCheckout,
  onOpenTripCostSummary,
  onHold,
  isHoldTripAllowed,
  warningMessages,
}: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'ReviewTrip.PriceSummary'
  const { mainCost } = formatRate(totalCost, { morpheme: 'none', isInteger: false })
  const { reviewTripStore } = useReviewTripContext()

  return (
    <FooterCheckoutContainer>
      <SpaceBetween hasAlignItemsCenter>
        <FooterCheckoutWrap>
          <Text aria-label={t(i18nBasePath + '.EstTotal')} variant="headline" withoutLineHeight>
            {reviewTripStore.isTotalPrice
              ? t(i18nBasePath + '.TripTotal')
              : t(i18nBasePath + '.EstTotal')}
          </Text>
          <InfoButton
            data-tracking-id="trip-info-icon"
            aria-label={t(i18nBasePath + '.EstimatedTotalInfo')}
            onClick={onOpenTripCostSummary}
            variant="text"
            leftIcon={<Icon name="infoPWA" size="medium" color="mainText" />}
          />
        </FooterCheckoutWrap>
        <Text aria-label={mainCost} variant="headline">
          {mainCost}
        </Text>
      </SpaceBetween>

      <Block marginTop={6} />
      <Button
        aria-label={t('Accessibility.ReviewTripPage.Checkout')}
        fullWidth
        onClick={onCheckout}
        data-tracking-id="checkout-trip-button"
        disabled={isDisabled}>
        {t('ReviewTrip.Checkout')}
      </Button>
      {isHoldTripAllowed && (
        <Block marginTop={20}>
          <Button fullWidth onClick={onHold} variant={'outline'}>
            {t('ReviewTrip.Hold')}
          </Button>
        </Block>
      )}
      <ContextualMessages messages={warningMessages} />
    </FooterCheckoutContainer>
  )
}
