import styled from 'styled-components'

import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'

export const CompanyResoursesModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 360px;
  top: 55px;
  right: 180px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  z-index: 20;
`

export const CompanyResoursesModalBody = styled.div`
  padding: 20px;
`

export const CompanyResoursesLinksContainer = styled.div`
  display: flex;
  flex-flow: column;
`

export const CompanyResoursesLinkItem = styled(Link)``

export const CompanyInformationTitle = styled(Text)`
  margin-bottom: 12px;
`
export const CompanyResoursesTitleDescription = styled(Text)`
  margin-bottom: 12px;
`
export const CompanyResoursesTitle = styled(Text)`
  margin-bottom: 4px;
`
