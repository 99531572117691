import styled from 'styled-components'
import { footnoteMedium, footnoteStrong } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Label = styled.div`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
`

export const Value = styled.div`
  ${footnoteStrong};
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.mainText};
`
