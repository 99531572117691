import type { GetFlightDynamicSiteMessagesQuery, DynamicSiteMessage } from '@fiji/graphql/types'
import type {
  FlightDynamicSiteMessage,
  GetFlightDynamicSiteMessagesResult,
} from '../../core/value-objects/get-flight-dynamic-site-messages.result'

function mapFlightDynamicSiteMessageToValueObject(
  input: DynamicSiteMessage,
): FlightDynamicSiteMessage {
  return {
    header: input.header,
    isDialog: input.isDialog,
    text: input.text,
    hasDesktopBannerImage: input.hasDesktopBannerImage,
    mobileText: input.mobileText,
  }
}

export function mapFlightDynamicSiteMessagesResult(
  input: GetFlightDynamicSiteMessagesQuery,
): GetFlightDynamicSiteMessagesResult {
  return {
    messages: input.getFlightDynamicSiteMessages.messages.map(
      mapFlightDynamicSiteMessageToValueObject,
    ),
  }
}
