import { useTranslation } from 'react-i18next'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'
import type { TripCostType } from '../../../types'
import { Wrapper, Details } from './trip-cost-styled'

type Props = {
  tripCost: TripCostType
}

const i18nBase = 'PostBooking.TripDetails'

export function TripCost({ tripCost }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { mainTotal, secondaryTotal } = tripCost
  const textVariant = isMobile ? 'subHeadStrong' : 'headline'
  return (
    <Wrapper>
      <Details>
        <Text variant={textVariant} color="mainText">
          {t(`${i18nBase}.TripTotal`)}
        </Text>
      </Details>
      <Details alightRight>
        <Text variant={textVariant} color="mainText">
          {mainTotal}
        </Text>
        {secondaryTotal && (
          <Text variant="footnoteMedium" color="bodyText1">
            {secondaryTotal}
          </Text>
        )}
      </Details>
    </Wrapper>
  )
}
