import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { MessageContainer } from '../../message-container'
import { MessageSection } from '../../message-section'
import { CloseButtonContainer } from './row-message-item-mobile-styled'

export function RowMessageItemMobile({
  title,
  message,
  messageSlot,
  sectionSlot,
  iconName,
  iconColor,
  isClosable,
  hasMultipleItems,
  onClose,
}: LayoutProps) {
  return (
    <MessageContainer isClosable={isClosable}>
      <Block marginRight={14}>
        <Icon name={iconName} color={iconColor} />
      </Block>

      <MessageSection
        hasMultipleItems={hasMultipleItems}
        shouldExpandMessages={true}
        title={title}
        message={message}
        messageSlot={messageSlot}
        sectionSlot={sectionSlot}
      />

      {isClosable && (
        <CloseButtonContainer>
          <IconButton
            icon="closePWA"
            color="bodyText"
            size="normal"
            onClick={onClose}
            width={24}
            height={24}
          />
        </CloseButtonContainer>
      )}
    </MessageContainer>
  )
}
