import type { GetTripQuery } from '@fiji/graphql/hooks'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { TripEntity } from '../../../core/entities/trip.entity'
import { TripStatus } from '../../../core/enums'
import { SegmentMapper } from './trip-segments.mapper'
import { SupportedActionsMapper } from './supported-actions.mapper'

export class TripMapper {
  static toTripEntity({
    trip,
    processId,
  }: {
    trip: GetTripQuery['trip']
    processId: string
  }): TripEntity {
    const type = trip.type || TripStatus.Unknown
    const { failedSegments, segments } = trip.segments
      ? SegmentMapper.toTripSegments(trip.segments)
      : { failedSegments: [], segments: [] }

    const supportedActions = SupportedActionsMapper.toTripSupportedActions(trip.supportedActionsV2)
    return {
      type,
      failedSegments,
      name: trip.tripName || '',
      bookingRef: trip.tripId || '',
      segments,
      rollUpStartTime: deleteTimezone(trip.rollUpStartTime || ''),
      rollUpEndTime: deleteTimezone(trip.rollUpEndTime || ''),
      tripCost: trip.tripCost,
      cancellationTime: trip.cancellationTime,
      processId,
      flightId: trip.flightId,
      supportedActions,
      holdRules: trip.holdRules,
      tripId: trip.processId || 0,
      isMultiCity: trip.isMultiCity,
      costSummary: trip.costSummary,
      cityImageUrl: trip.cityImageUrl,
      transactionRegistryServiceItemId: null, // Will be done in MAAS-472
      messages: trip.messages || [],
      appliedRailCards: trip.appliedRailCards || [],
      airTickets: trip.airTickets || [],
      isNDCTrip: trip.isNDCTrip ?? false,
      creationTime: trip.creationTime,
      recordLocator: trip.recordLocator,
      agencyInfo: trip.agencyInfo,
      billingInfo: trip.billingInfo,
      meetingInfo: trip.meetingInfo,
    }
  }
}
