import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { useHotelDetailsEcoCheckModal } from '../../use-hotel-details-eco-check-modal'
import type { LayoutProps } from '../../types'
import {
  HotelDetailsEcoCheckModalMobileImage,
  FlightDetailsEcoCheckModalMobileHeader,
  HotelDetailsEcoCheckModalMobileUpperBody,
  HotelDetailsEcoCheckModalDesktopLowerBody,
} from './components'

export function HotelDetailsEcoCheckModalMobile({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageSustainabilityScore,
  sustainabilityScore,
  equivalences,
  certifications,
}: LayoutProps) {
  const { deviationLevel, kgOfEmissions, hotelCertifications } = useHotelDetailsEcoCheckModal({
    averageSustainabilityScore,
    sustainabilityScore,
    tonnesOfEmissions,
    certifications,
  })

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<FlightDetailsEcoCheckModalMobileHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <HotelDetailsEcoCheckModalMobileUpperBody
          sustainabilityScore={sustainabilityScore}
          averageSustainabilityScore={averageSustainabilityScore}
          deviationLevel={deviationLevel}
        />
        <HotelDetailsEcoCheckModalMobileImage deviationLevel={deviationLevel} />
        <HotelDetailsEcoCheckModalDesktopLowerBody
          deviationLevel={deviationLevel}
          hotelCertifications={hotelCertifications}
          equivalences={equivalences}
          kgOfEmissions={kgOfEmissions}
        />
      </Modal.Body>
    </Modal>
  )
}
