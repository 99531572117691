import { useCallback, useEffect, useMemo, useState, useRef, useLayoutEffect } from 'react'
import type { ChangeEvent } from 'react'
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import {
  DEFAULT_SCALE,
  MAX_SCALE,
  POPOVER_PADDING,
  SEAT_ACTION_HEIGHT,
} from '@etta/modules/seat-map/core/constants'
import type { PopoverPosition } from '@etta/modules/seat-map/ui/popover/types'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import type { SeatMapRowValueObject } from '@etta/modules/seat-map/core/value-objects'

type Props = {
  selectedSegmentId: string
  footerHeight?: number
  seatRows: Array<SeatMapRowValueObject>
}

export function useSeatMap({ seatRows, selectedSegmentId, footerHeight }: Props) {
  const [shouldShowOverlay, setShowOverlay] = useState(false)
  const [airSeatMapRef, setAirSeatMapRef] = useState<null | HTMLDivElement>(null)
  const [wrapperRef, setWrapperRef] = useState<null | HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const [position, setPosition] = useState<PopoverPosition>({ bottom: 0 })
  const [lastSegmentId, setLastSegmentId] = useState('')
  const [transformRef, setTransformRef] = useState<null | ReactZoomPanPinchRef>(null)
  const [scrollOffset, setScrollOffset] = useState(0)
  const [airSeatMapHeight, setAirSeatMapHeight] = useState(0)
  const isZoomable = useMemo(() => seatRows.some((row) => row.seat.length >= 5), [seatRows])
  const { seatMapStore } = useSeatMapContext()

  useLayoutEffect(() => {
    const airSeatMapTable = airSeatMapRef?.querySelector('[data-tracking-id="seat-map"]')
    let timer: NodeJS.Timeout
    if (airSeatMapTable) {
      timer = setTimeout(() => {
        const airSeatMapHeightValue = airSeatMapTable.getBoundingClientRect().height || 0
        setAirSeatMapHeight(airSeatMapHeightValue)
      }, 0)

      return () => clearTimeout(timer)
    }
  })

  const shouldOpenUp = (popoverHeight: number, availableSpace: number) => {
    if (popoverHeight > availableSpace) {
      return true
    }

    return false
  }

  function calculatePopoverPosition(seatEvent: ChangeEvent<HTMLInputElement>) {
    if (!seatEvent.target.checked) {
      return
    }

    if (!(airSeatMapRef && airSeatMapRef?.contains(seatEvent.target) && popoverRef.current)) {
      return
    }

    const popoverDefaultHeight = popoverRef.current.getBoundingClientRect().height
    const airSeatMapBottom = airSeatMapRef.getBoundingClientRect().bottom
    const {
      bottom: seatEventBottom,
      height: seatEventHeight,
    } = seatEvent.target.getBoundingClientRect()

    const spaceAboveSeatEvent = airSeatMapBottom - seatEventBottom
    const availableViewportSpace = airSeatMapBottom - window.innerHeight
    const popoverHeight = popoverDefaultHeight + SEAT_ACTION_HEIGHT
    const availableSpace = spaceAboveSeatEvent - availableViewportSpace

    if (shouldOpenUp(popoverHeight, availableSpace)) {
      setPosition({
        bottom: spaceAboveSeatEvent + seatEventHeight + POPOVER_PADDING,
      })
      return
    }

    setPosition({
      bottom: spaceAboveSeatEvent - popoverDefaultHeight - POPOVER_PADDING,
    })
  }

  function handleOpenPopover(seatEvent: ChangeEvent<HTMLInputElement>) {
    setTimeout(() => {
      calculatePopoverPosition(seatEvent)
      seatMapStore.seatPopup.handleOpen()
    }, 0)
  }

  useEffect(() => {
    if (!seatMapStore.seatPopup.isOpen) {
      return
    }

    const handleCloseOnScroll = ({ target }: Event) => {
      if (airSeatMapRef && !airSeatMapRef.contains(target as Node)) {
        seatMapStore.seatPopup.handleClose()
        seatMapStore.dropSeat()
      }
    }

    window.addEventListener('scroll', handleCloseOnScroll, true)

    return () => {
      window.removeEventListener('scroll', handleCloseOnScroll, true)
    }
  }, [seatMapStore, seatMapStore.seatPopup.isOpen, airSeatMapRef])

  const handleInteractOnce = useCallback(() => {
    if (shouldShowOverlay) {
      setShowOverlay(false)
    }
  }, [shouldShowOverlay])

  const zoomHandler = useCallback((ref: ReactZoomPanPinchRef) => {
    setTransformRef(ref)
    setTimeout(() => {
      if (!ref) {
        return
      }
      ref.zoomToElement('zoomToNode', 0)
    }, 5)
  }, [])

  useEffect(() => {
    if (selectedSegmentId !== lastSegmentId) {
      setLastSegmentId(selectedSegmentId)
      if (isZoomable) {
        setShowOverlay(true)
        setTimeout(() => setShowOverlay(false), 1200)
      }
    }

    if (!isZoomable || !airSeatMapRef || !wrapperRef) {
      return
    }

    return () => {
      if (isZoomable) {
        setShowOverlay(false)
      }
    }
  }, [selectedSegmentId, isZoomable, airSeatMapRef, wrapperRef, lastSegmentId])

  useEffect(() => {
    if (!transformRef || !airSeatMapRef || !wrapperRef) {
      return
    }

    if (
      footerHeight &&
      Math.abs(transformRef?.state.positionY) > footerHeight * transformRef?.state.scale
    ) {
      setScrollOffset(transformRef?.state.positionY + footerHeight)
    } else {
      setScrollOffset(0)
    }
  }, [transformRef, footerHeight, selectedSegmentId, airSeatMapRef, wrapperRef])

  return {
    wrapperRef: setWrapperRef,
    airSeatMapRef: setAirSeatMapRef,
    airSeatMapHeight,
    shouldShowOverlay,
    setShowOverlay,
    onInteractOnce: handleInteractOnce,
    lastSegmentId,
    maxScale: MAX_SCALE,
    defaultScale: transformRef?.state.scale || DEFAULT_SCALE,
    zoomHandler,
    overlayHeight: `${
      wrapperRef &&
      transformRef?.state.scale &&
      wrapperRef?.clientHeight / transformRef?.state.scale
    }px`,
    scrollOffset,
    isZoomable,
    popoverRef,
    position,
    handleOpenPopover,
  }
}
