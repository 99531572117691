import { Store } from '@etta/di'
import type { HeapAnalyticsIdentityValueObject } from '../../../core/value-objects/heap-analytics-identity.value-object'

const MAX_SAVED_IDENTITIES = 10

@Store()
export class HeapAnalyticsIdentityStore {
  email: string = ''

  isLoading: boolean = false

  identities: HeapAnalyticsIdentityValueObject[] = []

  addIdentity(identity: HeapAnalyticsIdentityValueObject) {
    const size = this.identities.length

    if (size > MAX_SAVED_IDENTITIES) {
      this.identities.pop()
    }

    this.identities.unshift(identity)
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setEmail(email: string) {
    this.email = email
  }

  dropData() {
    this.identities = []
    this.isLoading = false
  }
}
