import { useMemo } from 'react'
import type { PreferenceOptions, SegmentType } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import type { AvailablePreferences } from '@fiji/graphql/hooks'
import type { TravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'
import { getLoyaltyProgram } from './get-loyalty-program'
import { getProgramByType } from './get-program-by-type'

type Args = {
  type: SegmentType
  preferenceData: TravelPreferencesValueObject
  segments: TripSegmentValueObject[]
  availablePreferences?: AvailablePreferences
}

export function useLoyaltyProgram({ type, preferenceData, segments, availablePreferences }: Args) {
  const { loyaltyProgram, options } = getProgramByType({
    type,
    preferenceData,
    availablePreferences,
  })

  return useMemo(() => {
    return {
      value: getLoyaltyProgram({
        type,
        segments,
        loyaltyProgram,
      }),
      options: (options || []) as PreferenceOptions[],
    }
  }, [segments, loyaltyProgram, type, options])
}
