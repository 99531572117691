const basePath = `${process.env.PUBLIC_URL}/img/hotel-logos`

export const HotelsLogoMap = {
  '6C': `${basePath}/6C.jpg`,
  AL: `${basePath}/AL.jpg`,
  BR: `${basePath}/BR.jpg`,
  BW: `${basePath}/BW.jpg`,
  CP: `${basePath}/CP.jpg`,
  CY: `${basePath}/CY.jpg`,
  DN: `${basePath}/HY.jpg`,
  DO: `${basePath}/DO.jpg`,
  EA: `${basePath}/EA.jpg`,
  EM: `${basePath}/EM.jpg`,
  FN: `${basePath}/FN.jpg`,
  GI: `${basePath}/GI.jpg`,
  HI: `${basePath}/HI.jpg`,
  HP: `${basePath}/HY.jpg`,
  HU: `${basePath}/HY.jpg`,
  HY: `${basePath}/HY.jpg`,
  JV: `${basePath}/HY.jpg`,
  KC: `${basePath}/KC.jpg`,
  MC: `${basePath}/MC.jpg`,
  MX: `${basePath}/MX.jpg`,
  RA: `${basePath}/RA.jpg`,
  RU: `${basePath}/RU.jpg`,
  SI: `${basePath}/SI.jpg`,
  LF: `${basePath}/HY.jpg`,
  TL: `${basePath}/TL.jpg`,
  TM: `${basePath}/HY.jpg`,
  VG: `${basePath}/VG.jpg`,
  EO: `${basePath}/EO.jpg`,
  WI: `${basePath}/WI.jpg`,
  XV: `${basePath}/XV.jpg`,
  QI: `${basePath}/QI.jpg`,
  RI: `${basePath}/RI.jpg`,
  AA: `${basePath}/AA.jpg`,
  AR: `${basePath}/AR.jpg`,
  BU: `${basePath}/BU.jpg`,
  CI: `${basePath}/CI.jpg`,
  CC: `${basePath}/CC.jpg`,
  SZ: `${basePath}/SZ.jpg`,
  CQ: `${basePath}/CQ.jpg`,
  DE: `${basePath}/DE.jpg`,
  DR: `${basePath}/DR.jpg`,
  EC: `${basePath}/EC.jpg`,
  ES: `${basePath}/ES.jpg`,
  FP: `${basePath}/FP.jpg`,
  HG: `${basePath}/HG.jpg`,
  HT: `${basePath}/HT.jpg`,
  IC: `${basePath}/IC.jpg`,
  KI: `${basePath}/KI.jpg`,
  MD: `${basePath}/MD.jpg`,
  OZ: `${basePath}/OZ.jpg`,
  RD: `${basePath}/RD.jpg`,
  RZ: `${basePath}/RZ.jpg`,
  SK: `${basePath}/SK.jpg`,
  TO: `${basePath}/TO.jpg`,
  WG: `${basePath}/WG.jpg`,
  WO: `${basePath}/WO.jpg`,
  YO: `${basePath}/YO.jpg`,
  AK: `${basePath}/AK.jpg`,
  BH: `${basePath}/BH.jpg`,
  BV: `${basePath}/BV.jpg`,
  CM: `${basePath}/CM.jpg`,
  CX: `${basePath}/CX.jpg`,
  DI: `${basePath}/DI.jpg`,
  DT: `${basePath}/DT.jpg`,
  EH: `${basePath}/EH.jpg`,
  FA: `${basePath}/FA.jpg`,
  FS: `${basePath}/FS.jpg`,
  HH: `${basePath}/HH.jpg`,
  HX: `${basePath}/HX.jpg`,
  IN: `${basePath}/IN.jpg`,
  RC: `${basePath}/RC.jpg`,
  LQ: `${basePath}/LQ.jpg`,
  MT: `${basePath}/MT.jpg`,
  PY: `${basePath}/PY.jpg`,
  RT: `${basePath}/RT.jpg`,
  SB: `${basePath}/SB.jpg`,
  SL: `${basePath}/SL.jpg`,
  TW: `${basePath}/TW.jpg`,
  WH: `${basePath}/WH.jpg`,
  XE: `${basePath}/XE.jpg`,
  YZ: `${basePath}/YZ.jpg`,
}

export function getHotelLogoURL(chainCode?: string): string | undefined {
  return HotelsLogoMap[chainCode as keyof typeof HotelsLogoMap]
}
