import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { MouseEventHandler } from 'react'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Spinner } from '@etta/ui/spinner'
import { BaseTextField } from './base-text-field'
import type { BaseTextFieldProps } from './base-text-field'

export function SearchTextField({
  onChange,
  size,
  value,
  isLoading,
  skin = 'search',
  onClear,
  customAriaLabel,
  ...props
}: BaseTextFieldProps) {
  const { t } = useTranslation()

  const onSearchClear: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onChange?.('')
      onClear?.()
    },
    [onChange, onClear],
  )

  const paddingBySize = size === 'small' || size === 'small-font' ? 8 : 12

  return (
    <BaseTextField
      {...props}
      value={value}
      onChange={onChange}
      size={size}
      type="text"
      skin={skin}
      customAriaLabel={customAriaLabel}
      startSlot={
        <Block paddingHorizontal={paddingBySize}>
          <Icon name="searchPWA" size="normal" color="mainText" />
        </Block>
      }
      endSlot={
        <Swap
          is={isLoading}
          isSlot={
            <div style={{ marginRight: '10px' }}>
              <Spinner size="small" type="spinout" />
            </div>
          }>
          {!!value && (
            <IconButton
              tabIndex={0}
              onClick={onSearchClear}
              icon="closeFilledPWA"
              size="normal"
              color="mainText"
              aria-label={t('Accessibility.TextField.Search.Clear')}
              width={40}
            />
          )}
        </Swap>
      }
    />
  )
}
