import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProp } from './types'
import { HotelRoomMobile } from './mobile'
import { HotelRoomDesktop } from './desktop'

export function HotelRoomLayout({ ...props }: LayoutProp) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<HotelRoomMobile {...props} />}
      desktopSlot={<HotelRoomDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
