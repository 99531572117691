import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Args = {
  carRental: CarRentalPostBookingValueObject
}
const i18nBase = 'PostBooking.CarRentalDetails'

export function usePrice({ carRental }: Args) {
  const { t } = useTranslation()
  const { taxesAndFees, totalBaseCost, totalCost } = carRental
  const { mainCost: formattedTotalCost } = formatRate(totalCost, { morpheme: 'none' })

  const priceDetailsList = useMemo(() => {
    return [
      {
        title: t(`${i18nBase}.RentalCost`),
        value: formatRate(totalBaseCost, { morpheme: 'none' }).mainCost,
      },
      {
        title: t(`${i18nBase}.Taxes`),
        value: formatRate(taxesAndFees, { morpheme: 'none' }).mainCost,
      },
    ]
  }, [t, taxesAndFees, totalBaseCost])

  return {
    priceDetailsList,
    formattedTotalCost,
  }
}
