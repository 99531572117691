import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import type { Props } from '../../types'
import { FooterContainer, ClearButton, ApplyButton } from './footer-desktop-styled'

export function FooterDesktop({ onClearClick, onApplyClick, isApplyDisabled }: Props) {
  const { t } = useTranslation()

  return (
    <Modal.Footer>
      <FooterContainer>
        <ClearButton variant="link" onClick={onClearClick}>
          {t('CarRentalFilters.Buttons.ClearAll')}
        </ClearButton>
        <ApplyButton onClick={onApplyClick} disabled={isApplyDisabled}>
          {t('CarRentalFilters.Buttons.ApplyFilters')}
        </ApplyButton>
      </FooterContainer>
    </Modal.Footer>
  )
}
