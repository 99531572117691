import type { FlightFragment, Segment } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { SegmentSpecificUnit } from '../segment-specific-unit'
import { useFlightCostLabelAndName } from './use-flight-cost-label-and-name'

type Props = {
  flightFragment?: FlightFragment | null
  flightSegment?: Segment | null
}

export function FlightCostLabelAndName({ flightFragment, flightSegment }: Props) {
  const { cost, iconName, originAddress, destinationAddress } =
    useFlightCostLabelAndName({
      segment: flightFragment || flightSegment?.flightLeg,
    }) || {}

  return (
    <SegmentSpecificUnit
      cost={cost}
      labelSlot={
        <>
          {originAddress} {iconName && <Icon name={iconName} size="small" />} {destinationAddress}
        </>
      }
    />
  )
}
