import type { OutOfPolicyReasonValueObject } from '@etta/core/value-objects'
import { Domain, SegmentType } from '@etta/core/enums'
import type { CartOOPConfiguration } from '../types'
import type { OOPInfoResponse } from './types'

const domainToSegmentTypeMap = {
  [Domain.Air]: SegmentType.Flight,
  [Domain.CarRental]: SegmentType.CarRental,
  [Domain.Hotel]: SegmentType.Hotel,
  [Domain.Rail]: SegmentType.Train,
  [Domain.Unspecified]: SegmentType.Unspecified,
}

export class OOPMapper {
  static toOOPReasons(data: OOPInfoResponse): CartOOPConfiguration {
    const options = data.intentComplianceCodes.reduce<CartOOPConfiguration['options']>(
      (acc, value) => {
        const options: OutOfPolicyReasonValueObject[] = value.options
          .map((option) => ({
            segment: domainToSegmentTypeMap[value.domain],
            reason: option.label,
            id: (option.code as unknown) as number,
            codeForBooking: option.code,
          }))
          .filter(
            (item) => !acc.some(({ codeForBooking }) => item.codeForBooking === codeForBooking),
          )
        return [...acc, ...options]
      },
      [],
    )

    if (data.explanation?.value) {
    }
    const tripSelection = data.explanation?.value
      ? {
          code: data.explanation.value,
          label: data.explanation.label || null,
        }
      : null

    const flightSelectionCode = data.intentComplianceCodes.find(
      (item) => item.domain === Domain.Air,
    )?.code
    const carRentalSelectionCode = data.intentComplianceCodes.find(
      (item) => item.domain === Domain.CarRental,
    )?.code
    const hotelSelectionCode = data.intentComplianceCodes.find(
      (item) => item.domain === Domain.Hotel,
    )?.code
    const railSelectionCode = data.intentComplianceCodes.find((item) => item.domain === Domain.Rail)
      ?.code

    const flightSelectedOption = flightSelectionCode
      ? options.find(
          (option) =>
            option.segment === SegmentType.Flight && option.codeForBooking === flightSelectionCode,
        )
      : null

    const carRentalSelectedOption = carRentalSelectionCode
      ? options.find(
          (option) =>
            option.segment === SegmentType.CarRental &&
            option.codeForBooking === carRentalSelectionCode,
        )
      : null

    const hotelSelectedOption = hotelSelectionCode
      ? options.find(
          (option) =>
            option.segment === SegmentType.Hotel && option.codeForBooking === hotelSelectionCode,
        )
      : null

    const railSelectedOption = railSelectionCode
      ? options.find(
          (option) =>
            option.segment === SegmentType.Train && option.codeForBooking === railSelectionCode,
        )
      : null

    const flightSelection = flightSelectedOption?.codeForBooking
      ? {
          code: flightSelectedOption.codeForBooking,
          label: flightSelectedOption.reason || null,
        }
      : null

    const carRentalSelection = carRentalSelectedOption?.codeForBooking
      ? {
          code: carRentalSelectedOption.codeForBooking,
          label: carRentalSelectedOption.reason || null,
        }
      : null

    const hotelSelection = hotelSelectedOption?.codeForBooking
      ? {
          code: hotelSelectedOption.codeForBooking,
          label: hotelSelectedOption.reason || null,
        }
      : null

    const railSelection = railSelectedOption?.codeForBooking
      ? {
          code: railSelectedOption.codeForBooking,
          label: railSelectedOption.reason || null,
        }
      : null

    return {
      options,
      selections: {
        oopExplanation: tripSelection ? tripSelection.code : null,
        oopSegments: {
          air: flightSelection ? flightSelection.code : null,
          carrental: carRentalSelection ? carRentalSelection.code : null,
          hotel: hotelSelection ? hotelSelection.code : null,
          rail: railSelection ? railSelection.code : null,
        },
        oopSegmentsReason: {
          airReason: flightSelection ? flightSelection.label : null,
          carrentalReason: carRentalSelection ? carRentalSelection.label : null,
          hotelReason: hotelSelection ? hotelSelection.label : null,
          railReason: railSelection ? railSelection.label : null,
        },
        oopSelections: {
          flight: flightSelection ? flightSelection.label : null,
          carRentals: carRentalSelection ? carRentalSelection.label : null,
          hotel: hotelSelection ? hotelSelection.label : null,
          description: tripSelection ? tripSelection.code : null,
          rail: railSelection ? railSelection.label : null,
        },
      },
    }
  }
}
