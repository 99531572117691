import { css } from 'styled-components'
import { defaultFontFamily, screenSize } from '@fiji/style'

export const bodyStyled = css<{ bodyMargin?: string }>`
  body {
    ${defaultFontFamily};
    font-size: 1.6rem;
    margin: ${({ bodyMargin }: { bodyMargin?: string }) => bodyMargin || '0'};
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.body-overflowed {
      overflow: hidden;

      @media (${screenSize.maxTablet}) {
        margin: 0 !important;
      }
    }

    &.body-prevent-pull-refresh {
      overscroll-behavior-y: contain;
    }

    &.body-fixed {
      overflow: hidden;
      position: fixed;
    }
  }
`
