import { useMemo } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import { DynamicSiteMessagesboxModalDesktop } from './desktop'
import type { LayoutProps } from './types'

export function DynamicSiteMessagesModalLayout({
  mobileSlot = null,
  ...props
}: LayoutProps & { mobileSlot?: React.ReactNode }) {
  const desktopSlot = useMemo(() => <DynamicSiteMessagesboxModalDesktop {...props} />, [props])

  return <MediaLayout mobileSlot={mobileSlot} desktopSlot={desktopSlot} />
}
