import { useCallback, useState } from 'react'

type Args = {
  totalCount: number
}

export function useMessagesSlider({ totalCount }: Args) {
  const [messageIndex, setMessageIndex] = useState<number>(0)

  const handleUpdateMessageIndex = useCallback(
    (index: number) => {
      const indexWithBoundaries: number = (() => {
        switch (true) {
          case index < 0:
            return 0
          case index >= totalCount - 1:
            return totalCount - 1
          default:
            return index
        }
      })()

      setMessageIndex(indexWithBoundaries)
    },
    [totalCount],
  )

  return {
    messageIndex,
    handleUpdateMessageIndex,
  }
}
