import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useAppSelector } from '@fiji/store'
import { flightDisplayConfigurationSelector } from '@fiji/store/display-configuration/selectors'
import { TimeRangeDirection, FlightDirection } from '@fiji/enums'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { FlightType } from './types'
import type { AirSearchQueryType } from './types'

// TODO: remove
export function useAirSearchQuery() {
  const { homeAirportStore } = useAirSearchFormContext()
  const { cabinClassesOptions } = useSeatmapCabinClasss()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()
  const { defaultAirTravelType } = useAppSelector(flightDisplayConfigurationSelector)

  const leg0Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Origin)
  const returnFlightTime = getTimeForInitialFlight(
    TimeRangeDirection.Departure,
    FlightDirection.Return,
  )

  const {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  } = useQueryParams<AirSearchQueryType>(
    {
      flightType: defaultAirTravelType === FlightType.OneWay ? FlightType.OneWay : FlightType.Round,
      flights: [
        {
          id: -1,
          originPlace: homeAirportStore.homeAirport || undefined,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg0Time,
          returnTimeRange: returnFlightTime,
        },
        {
          id: -2,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg0Time,
          returnTimeRange: returnFlightTime,
        },
      ],
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: ['flights', 'selectedFlights'],
    },
  )

  return {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  }
}
