import { useTranslation } from 'react-i18next'
import type { Props } from './type'

const i18nBase = 'PostBooking.TrainDetails'

export function useHeadline({ train }: Props) {
  const { t } = useTranslation()

  const { segments } = train

  const firstSegment = segments?.[0]
  const fareName = train.fareConditions?.[0]?.tier.name || ''

  const headLineSubTitle = [
    t(`${i18nBase}.Headline.OpenReturn.Legend`),
    t(`${i18nBase}.Headline.OpenReturn.Conditions`),
    fareName,
  ]
    .filter(Boolean)
    .join(' · ')

  const arrivalCountryName = firstSegment?.stations?.departure?.countryName
  const stationName = firstSegment?.stations?.departure?.stationName
  const arrivalPlace = firstSegment?.stations?.departure?.stationName + ', ' + arrivalCountryName

  const ariaLabel = `${t(i18nBase + '.TrainTo')} ${arrivalPlace}`
  return { headLineSubTitle, ariaLabel, arrivalCountryName, stationName }
}
