import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import { formatCancellationDate } from '@fiji/utils/dates/cancellation-date-format'
import { useUserContext } from '@etta/modules/user'
import type { Props } from './types'
import { HoldTripConfirmationModalDesktop } from './layout/desktop/hold-trip-confirmation-modal-desktop'
import { HoldTripConfirmationModalMobile } from './layout/mobile/hold-trip-confirmation-modal-mobile'

export const HoldTripConfirmationModal = observer(function HoldTripConfirmationModal({
  autoCancellationTime,
  ...props
}: Props) {
  const { userStore } = useUserContext()
  const timeZone = userStore.profile?.timeZone
  const translationSlot = useMemo(() => {
    if (!autoCancellationTime) {
      return null
    }
    return (
      <Trans
        i18nKey="HoldTrip.AutoCancellation"
        values={{
          time: formatCancellationDate(autoCancellationTime, timeZone),
        }}
        components={{ bold: <strong /> }}
      />
    )
  }, [autoCancellationTime, timeZone])

  return (
    <MediaLayout
      mobileSlot={<HoldTripConfirmationModalMobile translationSlot={translationSlot} {...props} />}
      desktopSlot={
        <HoldTripConfirmationModalDesktop translationSlot={translationSlot} {...props} />
      }
    />
  )
})
