import type { PlainTime } from '@fiji/types'
import type { TimePickerProps } from '../types'

export function useSwipingTimePickerMobile({
  onApply,
  onClose,
}: {
  onApply: TimePickerProps['onApply']
  onClose: TimePickerProps['onClose']
}) {
  const handleTimeSelect = (time: PlainTime) => {
    onApply(time)
    onClose()
  }

  return {
    handleTimeSelect,
  }
}
