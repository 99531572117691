import type { GetCarDetailsQuery, GetCarRentalsQuery } from '@fiji/graphql/hooks'
import type { CarRentalsValueObject, CarDetailsValueObject } from '../../core/value-objects'

export class CarRentalMapper {
  static toCarRentalsValueObject(
    carRentals: GetCarRentalsQuery['carRentals'],
  ): CarRentalsValueObject {
    const {
      location,
      filters,
      includesOutOfPolicy,
      includesPreferred,
      maxResults,
      message,
      preferenceAttribute,
      primaryVendorsMissing,
      secondaryVendorsMissing,
      car,
      page,
    } = carRentals

    return {
      location,
      filters: {
        class: filters.class,
        type: filters.type,
        fuel: filters.fuel,
        transmission: filters.transmission,
        vendor: filters.vendor,
      },
      page,
      preferenceAttribute,
      includesOutOfPolicy,
      includesPreferred,
      maxResults,
      message,
      primaryVendorsMissing,
      secondaryVendorsMissing,
      car,
    }
  }

  static toCarDetailsValueObject(
    carDetails: GetCarDetailsQuery['carDetails'],
  ): CarDetailsValueObject {
    return {
      carCosts: carDetails.carCosts,
      carCostRates: carDetails.carCostRates,
      taxes: carDetails.taxes,
      totalCost: carDetails.totalCost,
      baseRate: carDetails.baseRate,
      rate: carDetails.rate,
      netRate: carDetails.netRate,
      serviceFeeRate: carDetails.serviceFeeRate,
    }
  }
}
