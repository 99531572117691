import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: inline-flex;
  align-items: center;

  > *:first-child {
    margin-right: 4px;
  }
`
export const BadgeText = styled(Text)`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`
