import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { Skeleton } from '@etta/ui/skeleton'
import type { Props } from '../../types'
import {
  PriceContainer,
  CostContainer,
  CostPerDay,
  EstimateBlock,
  SelectButton,
} from './desktop-styled'

export function CarPriceBlockDesktop({
  isLoading = false,
  priceAriaLabel,
  costLabel,
  perDayLabel,
  onSelectClick,
  estimate,
  isSelectable,
}: Props) {
  const { t } = useTranslation()

  return (
    <PriceContainer isLoading={isLoading}>
      <Swap
        is={isLoading}
        isSlot={
          <Skeleton width="100%" height="100%">
            <rect width="158px" height="26px" />
            <rect y="38" width="85px" height="11px" />
          </Skeleton>
        }>
        <CostContainer aria-label={priceAriaLabel}>
          <Text variant="title2" color="mainText">
            {costLabel}
          </Text>
          &nbsp;
          <CostPerDay variant="footnoteStrong" color="bodyText">
            {perDayLabel}
          </CostPerDay>
        </CostContainer>
        <EstimateBlock>
          <Text variant="footnoteMedium" color="bodyText">
            {estimate?.label}
          </Text>
          <Text variant="footnoteMedium" color="bodyText" isBlock>
            {estimate?.price}
          </Text>
        </EstimateBlock>
        <SelectButton
          onClick={onSelectClick}
          disabled={!isSelectable}
          data-tracking-id="select-car-button">
          {t('CarRentalDetails.Select')}
        </SelectButton>
      </Swap>
    </PriceContainer>
  )
}
