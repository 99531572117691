import {
  mainNavigationProfileSelector,
  updateMainNavigationProfile,
  mainNavigationSupportSelector,
  updateMainNavigationSupport,
  MAINNAVIGATIONVALUE,
} from '@fiji/store/main-navigation'
import { useGlobalExploreRoute } from './use-global-explore-route'
import { useGlobalLatestRoute } from './use-global-latest-route'
import { useGlobalTripsRoute } from './use-global-trips-route'

export function useGlobalNavigationLinks() {
  const { usedPath: supportLink } = useGlobalLatestRoute({
    detectPath: MAINNAVIGATIONVALUE.SUPPORT,
    action: updateMainNavigationSupport,
    selector: mainNavigationSupportSelector,
  })

  const { usedPath: profileLink } = useGlobalLatestRoute({
    detectPath: MAINNAVIGATIONVALUE.PROFILE,
    action: updateMainNavigationProfile,
    selector: mainNavigationProfileSelector,
  })

  const { exploreLink, isExploreActiveHandle } = useGlobalExploreRoute()

  const { tripsLink, isTripsActiveHandle } = useGlobalTripsRoute()

  return {
    tripsLink,
    supportLink,
    profileLink,
    exploreLink,
    isExploreActiveHandle,
    isTripsActiveHandle,
  }
}
