import styled, { css, keyframes } from 'styled-components'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'
import { SEAT_ACTION_HEIGHT } from '@etta/modules/seat-map/core/constants'
import { Button as EttaButton } from '@etta/ui/button'
import type { ButtonProps } from '@etta/ui/button'

const slideAnimation = keyframes`
  from {
    z-index: 0;
    transform: translateX(100%);
  };
  top {
    z-index: 1;
    transform: translateX(0);
  };
`

const slideStyle = css<{ isVisible: boolean }>`
  margin-bottom ${({ isVisible }) => (isVisible ? '0' : `-${SEAT_ACTION_HEIGHT}`)}px;
  transition: margin-bottom 600ms 150ms cubic-bezier(0.4, 0, 0.2, 1);
`

export const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${SEAT_ACTION_HEIGHT}px;
  bottom: 0;
  padding: 10px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, 0.8)};
  backdrop-filter: blur(5px);
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.17);

  ${slideStyle}
`

export const Content = styled.div`
  display: flex;
  overflow: hidden;
`

export const SeatContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  animation: ${slideAnimation} 900ms ease-in-out;
`

export const SeatIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.background1};
`

export const Button = styled(EttaButton).attrs<ButtonProps, ButtonProps>((props) => ({
  ...props,
  size: 'normal-wide',
}))`
  z-index: 2;
  padding: 10px 25px;
`
