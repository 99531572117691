import { Block } from '@etta/ui/block'
import { SafetyCheckHeader } from '../../safety-check-header'
import { GeoSafeScores } from '../../geo-safe-scores'
import { ConcernScoreList } from '../../concern-score-list'
import { CovidInformation } from '../../covid-information'
import { HORIZONTAL_PADDING } from '../../constants'
import type { LayoutProps } from '../types'
import { Container } from './hotel-safety-check-mobile-styled'

export function HotelSafetyCheckMobile({
  location,
  scores,
  concernList,
  covidStatistic,
  isLoading,
}: LayoutProps) {
  return (
    <Container>
      <Block marginBottom={24} paddingHorizontal={HORIZONTAL_PADDING}>
        <SafetyCheckHeader />
      </Block>
      <Block marginBottom={14} paddingHorizontal={HORIZONTAL_PADDING}>
        <GeoSafeScores location={location} scores={scores} isLoading={isLoading} />
      </Block>
      <Block marginBottom={20}>
        <ConcernScoreList concernList={concernList} isLoading={isLoading} />
      </Block>
      <Block marginBottom={8}>
        <CovidInformation covidStatistic={covidStatistic} isLoading={isLoading} />
      </Block>
    </Container>
  )
}
