import { useState, useCallback, useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useReviewTripContext } from '@etta/modules/review-trip'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import type { UnmatchedSegment } from '@etta/components/align-dates/align-dates-modal/types'
import type { TripMetadataValueObject } from '@etta/modules/review-trip/core/value-objects/trip-metadata.value-object'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { TravelVertical } from '@etta/modules/booking/core/enums'
import { useCarSearchQuery, useHotelSearchQuery } from '@fiji/hooks/search-queries'
import { deleteTimezone } from '@fiji/utils/dates'
import { ROUTES } from '@fiji/routes'
import { resolveLocation } from '@etta/screens/trip-hotel/helpers/resolve-location'
import { PostBookingAction } from '@fiji/enums'
import { AlignState } from '@fiji/graphql/types'
import { getInitialFilters } from '@fiji/hooks/search-queries/use-hotel-search-query/get-initial-filters'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import type { FailedAlignedSegment } from '../failed-aligned-segment/types'
import { convertToPlainTime } from '../car-rental-details-modal/utils/convert-to-plain-time'
import { convertToPlace } from '../car-rental-details-modal/utils'
import { getTimeRangeForAlignment } from './get-align-dates-time-range'

type HookProps = {
  onCheckout?: () => void
  bookingId?: string
}

const isAlignTripAlertShownPersist = new ItineraryStorage('isAlignTripAlertShown', false)

export function useAlignDates({ onCheckout, bookingId }: HookProps) {
  const { alignTripActions, segmentTypeCheckActions, reviewTripStore } = useReviewTripContext()
  const { navigateTo: navigateToHotelSearch } = useHotelSearchQuery()
  const { navigateTo: navigateToCarRentalSearch } = useCarSearchQuery()
  const {
    handleClose: handleCloseAlignDatesModal,
    handleOpen: handleOpenAlignDatesModal,
    isOpen: isAlignDatesModalOpen,
  } = useTogglePopup()
  const {
    handleClose: handleCloseAlignDatesAlert,
    handleOpen: handleOpenAlignDatesAlert,
    isOpen: isAlignDatesAlertOpen,
  } = useTogglePopup()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const [isLoading, setIsLoading] = useState(false)
  const [metadata, setMetadata] = useState<TripMetadataValueObject | null>(null)

  const handleSearchCarRental = useCallback(
    (metadata: NonNullable<TripMetadataValueObject['carRentalSearch']>) => {
      const flightSegments = reviewTripStore.segments.filter(
        segmentTypeCheckActions.isFlightSegment,
      )
      const roundTripStartTime = deleteTimezone(flightSegments[0]?.arrivalDateTime || '')
      const roundTripEndTime = deleteTimezone(
        flightSegments[flightSegments.length - 1]?.departureDateTime || '',
      )
      const pickUpDate = new Date(roundTripStartTime)
      const dropOffDate = new Date(roundTripEndTime)
      const pickUpLocationId = metadata.pickUp.locationId || undefined
      const dropOffLocationId = metadata.dropOff.locationId || undefined

      const pickUpPlace = convertToPlace({
        address: metadata.pickUp.address,
      })
      const dropOffPlace = convertToPlace({
        address: metadata.dropOff.address,
        isDropoff: true,
      })

      navigateToCarRentalSearch(ROUTES.carRental.results, {
        itineraryId: reviewTripStore.tripId,
        bookingId,
        pickUpDate,
        pickUpAddress: undefined,
        pickUpTime: convertToPlainTime(pickUpDate),
        pickUpPlace,
        dropOffDate,
        dropOffAddress: undefined,
        dropOffTime: convertToPlainTime(dropOffDate),
        dropOffPlace,
        pickUpLocationId,
        dropOffLocationId,
        postBookingAction: PostBookingAction.Add,
      })
    },
    [
      reviewTripStore.segments,
      reviewTripStore.tripId,
      segmentTypeCheckActions.isFlightSegment,
      navigateToCarRentalSearch,
      bookingId,
    ],
  )

  const {
    displayConfigurationStore: { hotelSearchRadius },
  } = useDisplayConfigurationContext()

  const defaultHotelFilters = getInitialFilters({
    initialDistance: hotelSearchRadius.default,
  })

  const handleSearchHotel = useCallback(
    (metadata: NonNullable<TripMetadataValueObject['hotelSearch']>) => {
      const flightSegments = reviewTripStore.segments.filter(
        segmentTypeCheckActions.isFlightSegment,
      )
      const roundTripStartTime = deleteTimezone(flightSegments[0]?.arrivalDateTime || '')
      const roundTripEndTime = deleteTimezone(
        flightSegments[flightSegments.length - 1]?.departureDateTime || '',
      )
      const checkInDate = roundTripStartTime ? new Date(roundTripStartTime) : undefined
      const checkOutDate = roundTripEndTime ? new Date(roundTripEndTime) : undefined

      navigateToHotelSearch(ROUTES.hotel.results, {
        itineraryId: reviewTripStore.tripId,
        checkInDate,
        checkOutDate,
        location: resolveLocation(metadata.address),
        bookingId,
        postBookingAction: PostBookingAction.Add,
        hotelFilters: defaultHotelFilters,
      })
    },
    [
      reviewTripStore.segments,
      reviewTripStore.tripId,
      segmentTypeCheckActions.isFlightSegment,
      navigateToHotelSearch,
      bookingId,
      defaultHotelFilters,
    ],
  )

  const errors = useMemo<FailedAlignedSegment[]>(() => {
    if (!featureFlagsStore.flags.isTripAlignmentWorkflowEnabled || !metadata) {
      return []
    }
    return metadata.alignAttemptedSegmentTypes.reduce<FailedAlignedSegment[]>((acc, item) => {
      if (item === TravelVertical.Carrental && !metadata.carRentalMatchDetails?.matchFound) {
        const carRentalSearchMetadata = metadata.carRentalSearch
        const onSearchClick = carRentalSearchMetadata
          ? () => handleSearchCarRental(carRentalSearchMetadata)
          : undefined
        acc.push({
          travelVertical: 'Carrental',
          onSearchClick,
        })
      }

      if (item === TravelVertical.Hotel && !metadata.hotelMatchDetails?.matchFound) {
        const hotelSearchMetadata = metadata.hotelSearch
        const onSearchClick = hotelSearchMetadata
          ? () => handleSearchHotel(hotelSearchMetadata)
          : undefined

        acc.push({
          travelVertical: 'Hotel',
          onSearchClick,
          customLabel: metadata.hotelSearch?.hotelName,
        })
      }
      return acc
    }, [])
  }, [
    featureFlagsStore.flags.isTripAlignmentWorkflowEnabled,
    metadata,
    handleSearchHotel,
    handleSearchCarRental,
  ])

  const { mismatchedSegments, mismatchedSegmentTypes, flightSegments } = useMemo(() => {
    if (!featureFlagsStore.flags.isTripAlignmentWorkflowEnabled || errors.length) {
      return { mismatchedSegments: [], mismatchedSegmentTypes: [], flightSegments: [] }
    }

    const flightSegments = reviewTripStore.segments.filter(segmentTypeCheckActions.isFlightSegment)
    const carRentalSegmentsSize = reviewTripStore.segments.filter(
      segmentTypeCheckActions.isCarRentalSegment,
    ).length
    const hotelSegmentsSize = reviewTripStore.segments.filter(
      segmentTypeCheckActions.isHotelSegment,
    ).length
    const isRoundTrip = flightSegments.every((segment) => segment.isRoundTripLeg)

    if (!flightSegments.length || !isRoundTrip || reviewTripStore.trip?.isTripAligned) {
      return { mismatchedSegments: [], mismatchedSegmentTypes: [], flightSegments: [] }
    }

    const { mismatchedSegmentTypes, mismatchedSegments } = reviewTripStore.segments.reduce<{
      mismatchedSegments: UnmatchedSegment[]
      mismatchedSegmentTypes: AlignTripTravelVerticalValueObject[]
    }>(
      (acc, item) => {
        if (
          segmentTypeCheckActions.isCarRentalSegment(item) &&
          (carRentalSegmentsSize > 1
            ? item.alignState === AlignState.OutOfBounds
            : item.alignState !== AlignState.Aligned)
        ) {
          acc.mismatchedSegmentTypes.push('Carrental')
          acc.mismatchedSegments.push({
            id: item.uuid,
            startDateTime: item.pickUpTime,
            endDateTime: item.dropOffTime,
            type: 'Carrental',
          })
        }
        if (
          segmentTypeCheckActions.isHotelSegment(item) &&
          (hotelSegmentsSize > 1
            ? item.alignState === AlignState.OutOfBounds
            : item.alignState !== AlignState.Aligned)
        ) {
          acc.mismatchedSegmentTypes.push('Hotel')
          acc.mismatchedSegments.push({
            id: item.uuid,
            startDateTime: item.checkIn,
            endDateTime: item.checkOut,
            type: 'Hotel',
          })
        }
        return acc
      },
      { mismatchedSegments: [], mismatchedSegmentTypes: [] },
    )

    const mappedFlightSegments = flightSegments.map((item) => ({
      startDateTime: item.departureDateTime,
      endDateTime: item.arrivalDateTime,
      origin: item.segments[0].departure.airportCode || '',
      destination: item.segments[item.segments.length - 1].arrival.airportCode || '',
    }))

    return {
      mismatchedSegments,
      mismatchedSegmentTypes,
      flightSegments: mappedFlightSegments,
    }
  }, [
    errors.length,
    featureFlagsStore.flags.isTripAlignmentWorkflowEnabled,
    reviewTripStore.segments,
    reviewTripStore.trip?.isTripAligned,
    segmentTypeCheckActions,
  ])

  const flightStartDateTime = flightSegments[0]?.startDateTime
  const firstFlightEndDateTime = flightSegments[0]?.endDateTime
  const lastFlightStartDateTime = flightSegments[flightSegments.length - 1]?.startDateTime

  const handleAlignTrip = useCallback(
    async (alignSegmentTypes: AlignTripTravelVerticalValueObject[]) => {
      const { endDate, startDate } = getTimeRangeForAlignment({
        alignSegmentTypes,
        flightEndTime: lastFlightStartDateTime,
        flightStartTime: firstFlightEndDateTime,
      })
      setIsLoading(true)
      const result = await alignTripActions.handleAlignTrip({
        startDate,
        endDate,
        alignSegmentTypes,
      })
      if (result.isOk()) {
        const { metadata } = result.getValue()
        setMetadata(metadata)
        handleCloseAlignDatesModal()
      }
      setIsLoading(false)
    },
    [alignTripActions, handleCloseAlignDatesModal, firstFlightEndDateTime, lastFlightStartDateTime],
  )

  const handleOpenAlert = useCallback(() => {
    isAlignTripAlertShownPersist.set(true)
    handleOpenAlignDatesAlert()
  }, [handleOpenAlignDatesAlert])

  const handleConfirmAlignDatesAlert = useCallback(() => {
    handleCloseAlignDatesAlert()
    handleOpenAlignDatesModal()
  }, [handleCloseAlignDatesAlert, handleOpenAlignDatesModal])

  const handleOnContinueToCheckout = () => {
    handleCloseAlignDatesAlert()
    onCheckout?.()
  }

  return {
    isAlignDatesAlertOpen,
    isAlignDatesModalOpen,
    handleCloseAlignDatesModal,
    handleCloseAlignDatesAlert,
    handleOnContinueToCheckout,
    handleAlignTrip,
    flightSegments,
    mismatchedSegments,
    isLoading,
    flightStartDateTime,
    lastFlightStartDateTime,
    handleConfirmAlignDatesAlert,
    handleOpenAlignDatesAlert: handleOpenAlert,
    handleOpenAlignDatesModal,
    mismatchedSegmentTypes,
    hasMismatchedSegments: mismatchedSegments.length > 0,
    errors,
    isAlignTripAlertShown: isAlignTripAlertShownPersist.values,
  }
}
