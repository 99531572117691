import styled from 'styled-components'

export const HeadingContent = styled.div<{ isSingleColumn?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSingleColumn }) =>
    isSingleColumn ? '100%' : '32px calc(70% - 32px) 30%'};
  width: 100%;
`

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 3px;
`

export const HeadingItem = styled.span<{ alignRight?: boolean }>`
  line-height: 25px;
  margin-top: 3px;
  word-break: break-word;
  ${({ alignRight }) => alignRight && `text-align: right`};
`
