import { differenceInHours } from 'date-fns'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { CheckInData } from './types'

type Props = {
  segments: FlightPostBookingValueObject['segments'] | null | undefined
}

export function useCheckInData({ segments }: Props): CheckInData {
  if (!segments) {
    return []
  }

  return segments.map(({ checkIn, confirmationNumber, departureDate, name, images }) => ({
    ...checkIn,
    confirmationNumber: confirmationNumber || '-',
    isConfirmationNumberAvailable: !!confirmationNumber,
    isAvailable:
      departureDate && checkIn.checkInWindowInHours
        ? differenceInHours(new Date(deleteTimezone(departureDate)), new Date()) <
          checkIn.checkInWindowInHours
        : false,
    airlineName: name || '',
    airlineLogo: images?.square || '',
  }))
}
