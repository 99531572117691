import styled, { css } from 'styled-components'

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  min-height: ${(p) => (p.isMobile ? 'auto' : '164px')};
`

export const TitleWrapper = styled.div<{ isMobile: boolean }>`
  margin: ${(p) => (p.isMobile ? '0 8px 9px 0' : '0 24px 9px 0')};
`

const multipleFlights = css`
  min-height: 110px;
  padding-bottom: 5px;
`

export const ImageWrapper = styled.div<{ isMultipleFlights: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${(p) => p.isMultipleFlights && multipleFlights}
`

export const Image = styled.img<{ isMobile: boolean }>`
  ${(p) =>
    !p.isMobile &&
    css`
      transform: translateY(15px);
    `};
`

export const SecondImage = styled.img<{ isMobile: boolean }>`
  position: absolute;
  height: auto;

  ${(p) =>
    p.isMobile
      ? css`
          width: 69%;
          transform: translateY(-50px);
        `
      : css`
          width: 80%;
          transform: translate(56px, -56px);
        `}
`
