import { useTranslation } from 'react-i18next'
import { HotelDetailsEcoCheckModal } from '@etta/components/hotel-details-eco-check-modal/hotel-details-eco-check-modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type {
  CalculateEmissionsResultHotelCertification,
  CalculateEmissionsBatchResultEquivalence,
} from '@fiji/graphql/types'
import { useHotelDetailsSustainability } from '../../use-hotel-details-sustainability'
import {
  Container,
  Title,
  Body,
  BodyRight,
  EcoCerfiedLabel,
  LearnMore,
  BodyRightDescription,
} from './hotel-details-eco-check-mobile-styled'
import { BodyLeftBlock } from './body-left-block'
import { EcoCheckMobileBackground } from './eco-check-mobile-background'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

export type Props = {
  certifications?: CalculateEmissionsResultHotelCertification[]
  sustainabilityScore: number
  tonnesOfEmissions: number
  averageSustainabilityScore: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function HotelDetailsEcoCheckMobileComponent(props: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.HotelDetails'
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const {
    isHotelCertificationPresent,
    deviationLevel,
    bodyRightDescription,
  } = useHotelDetailsSustainability(props)

  return (
    <>
      <Container onClick={handleOpen} data-tracking-id="hotel-details-eco-check-action">
        <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
          <img src={EcoCheckLogo} alt="eco check logo" />
        </Title>
        <Body>
          <BodyLeftBlock
            deviationLevel={deviationLevel}
            sustainabilityScore={props.sustainabilityScore}
          />
          <BodyRight aria-label={bodyRightDescription}>
            {isHotelCertificationPresent && (
              <EcoCerfiedLabel
                aria-label={t('Accessibility.ThisHotelHas') + t(i18nBase + '.EcoCerfified')}>
                {t(i18nBase + '.EcoCerfified')}
              </EcoCerfiedLabel>
            )}
            <BodyRightDescription>{bodyRightDescription}</BodyRightDescription>
            <LearnMore
              aria-label={
                t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
              }>
              {t('Emissions.DetailsModal.LearnMore')}
            </LearnMore>
          </BodyRight>
        </Body>
        <EcoCheckMobileBackground deviationLevel={deviationLevel} />
      </Container>
      <HotelDetailsEcoCheckModal
        equivalences={props.equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={props.tonnesOfEmissions}
        certifications={props.certifications || []}
        sustainabilityScore={props.sustainabilityScore}
        averageSustainabilityScore={props.averageSustainabilityScore}
      />
    </>
  )
}
