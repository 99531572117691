import type { BaggageInfoSegment } from '@fiji/types'

type Props = {
  onBaggageModalOpen: () => void
  baggageButtonLabel: string
  baggageInfo?: BaggageInfoSegment[]
}

export function useBaggageInformation({
  baggageInfo,
  onBaggageModalOpen,
  baggageButtonLabel,
}: Props) {
  const isBaggageDetailsAvailable =
    baggageInfo?.some((bagInfo) =>
      Boolean(bagInfo.baggageFees?.items && bagInfo.baggageFees.items.length > 0),
    ) || false

  const carrierUrl = baggageInfo?.[0]?.baggageFees?.carrierUrl || ''
  const name = baggageInfo?.[0]?.name || ''

  return {
    onBaggageModalOpen,
    baggageButtonLabel,
    isBaggageDetailsAvailable,
    carrierUrl,
    carrierName: name,
  }
}
