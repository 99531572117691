import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { HeaderBlockDesktop } from './desktop'
import { HeaderBlockMobile } from './mobile'

export function HeaderBlockLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HeaderBlockMobile {...props} />}
      desktopSlot={<HeaderBlockDesktop {...props} />}
    />
  )
}
