import i18n from 'i18next'
import { dateFormat, deleteTimezone, isSameDay } from '@fiji/utils/dates'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'

const i18nBase = 'AlignDatesWarning.'

type Args = {
  flightStartDateTime: string
  lastFlightStartDateTime: string
  mismatchedTypes: AlignTripTravelVerticalValueObject[]
}

function getDates(flightStartDateTime: string, lastFlightStartDateTime: string) {
  const startDate = new Date(deleteTimezone(flightStartDateTime))
  const endDate = new Date(deleteTimezone(lastFlightStartDateTime))

  if (isSameDay(startDate, endDate)) {
    return dateFormat(startDate, 'MMM d, yyyy')
  }
  return [dateFormat(startDate, 'MMM d'), dateFormat(endDate, 'MMM d, yyyy')].join(' - ')
}

export const getWarningTranslation = ({
  lastFlightStartDateTime,
  flightStartDateTime,
  mismatchedTypes,
}: Args) => {
  const translationValues = mismatchedTypes.reduce<Set<string>>((acc, type) => {
    if (type === 'Carrental') {
      acc.add(i18n.t(i18nBase + 'CarRentalTitle'))
    }
    if (type === 'Hotel') {
      acc.add(i18n.t(i18nBase + 'HotelTitle'))
    }
    return acc
  }, new Set([]))
  const translationDates = getDates(flightStartDateTime, lastFlightStartDateTime)

  return i18n.t(i18nBase + 'Description', {
    dates: translationDates,
    values: Array.from(translationValues).join(' ' + i18n.t('and') + ' '),
  })
}
