import { MediaLayout } from '@etta/ui/media-layout'
import { AddExpenseCodeMemoMobile } from './mobile'
import type { LayoutProps } from './types'

export function AddExpenseCodeMemoLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<AddExpenseCodeMemoMobile {...props} />}
      desktopSlot={<AddExpenseCodeMemoMobile {...props} />}
    />
  )
}
