import { Skeleton } from '@etta/ui/skeleton'
import { Ticket, Tickets } from './unused-tickets-loading-desktop-styled'

export function UnusedTicketsLoadingDesktop() {
  const loadingStates = Array.from({ length: 4 })
  return (
    <>
      <Skeleton width="100%" height="22">
        <rect x="0" y="0" width="204" height="22" />
      </Skeleton>
      <Tickets>
        {loadingStates.map((_, key) => (
          <Ticket key={key}>
            <Skeleton width="100%" height="155">
              <rect x="0" y="0" width="32" height="32" />
              <rect x="47" y="0" width="164" height="16" />
              <rect x="47" y="20" width="120" height="16" />
              <rect x="47" y="55" width="120" height="16" />
              <rect x="229" y="55" width="120" height="16" />

              <rect x="47" y="75" width="80" height="16" />
              <rect x="229" y="75" width="80" height="16" />

              <rect x="47" y="110" width="120" height="16" />
              <rect x="229" y="110" width="120" height="16" />

              <rect x="47" y="130" width="80" height="16" />
              <rect x="229" y="130" width="80" height="16" />
            </Skeleton>
          </Ticket>
        ))}
      </Tickets>
    </>
  )
}
