import { useTranslation } from 'react-i18next'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { CarEmission } from '@fiji/types'
import { RTPItineraryCard } from '@etta/components/rtp-itinerary-card/rtp-itinerary-card'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import type { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import { CarRentalDetailsModal } from '../../../car-rental-details-modal'
import { useCarRentalSegmentRtp } from './use-car-rental-segment-rtp'

type Props = {
  segment: CarRentalSegmentEntity
  onOpenOOP: () => void
  outOfPolicyExp?: string | null
  isCheckoutActivated?: boolean
  isTripExist: boolean
  itineraryId: string
  isChangeAllowed: boolean
  carRentalId: string
  carPriceChangeType?: PriceChangeTypeValueObject | null
  isRemoveAllowed: boolean
  carRentalEmission: CarEmission
  isMismatched: boolean
}

const i18nPathAccessability = 'Accessibility.ReviewTripPage'

export const CarRentalSegmentRTP = ({
  segment,
  onOpenOOP,
  outOfPolicyExp,
  isCheckoutActivated,
  isTripExist,
  carRentalId,
  itineraryId,
  isChangeAllowed,
  carPriceChangeType,
  isRemoveAllowed,
  carRentalEmission,
  isMismatched,
}: Props) => {
  const { t } = useTranslation()
  const i18Base = 'CarRentalDetailsModal.'
  const {
    removalDialogToggle,
    removalViewState,
    removalTitle,
    detailsModalToggle,
    startDate,
    endDate,
    longDateLabel,
    carRental,
    cardStatus,
    handleChangeCarRentalLink,
    handleChangeCarRental,
    handleCloseModal,
    handleConfirmRemoveCarRental,
    changeCarModalToggle,
    cardActions,
    monthAndDayAndTimeFormat,
    titleSlot,
    shouldShowServiceFeeLabel,
  } = useCarRentalSegmentRtp({
    segment,
    isChangeAllowed,
    itineraryId,
    carRentalId,
    isTripExist,
  })

  return (
    <>
      <RTPItineraryCard
        key={segment.uuid}
        data-tracking-id={`car-rental-rtp-card_${segment.type}-${segment.position}`}
        cardStatus={cardStatus}
        serviceType={segment.type}
        isOutOfPolicy={!carRental.policy.isInPolicy}
        hasComplianceCode={!!carRental.policy.outOfPolicyComplianceCodes?.length}
        startDate={startDate}
        fullStartDate={new Date(segment.pickUpTime)}
        isInvalid={isMismatched}
        isWarningIcon={isMismatched}
        endDate={endDate}
        longDateLabel={longDateLabel}
        rate={carRental.totalCostRate}
        isCheckoutActivated={isCheckoutActivated}
        vendor={carRental.vendorDetails}
        bodyAriaLabel={t(`${i18nPathAccessability}.CarRentalCard`, {
          car: `${carRental.carDetails.class} ${carRental.carDetails.type}`,
        })}
        titleSlot={titleSlot}
        informationSlot={
          <>
            <RTPItineraryCard.Text aria-label={carRental.vendorDetails.name}>
              {carRental.vendorDetails.name}
            </RTPItineraryCard.Text>
            <RTPItineraryCard.Text
              aria-label={tripReviewFormatDate(monthAndDayAndTimeFormat, carRental.pickUpTime)}>
              {tripReviewFormatDate(monthAndDayAndTimeFormat, carRental.pickUpTime)}{' '}
            </RTPItineraryCard.Text>
          </>
        }
        onClick={detailsModalToggle.handleOpen}
        onSelectReason={onOpenOOP}
        outOfPolicyExp={outOfPolicyExp}
        changeLinkText={t('ReviewTripPWA.CarRentalCard.ChangeLink')}
        onChange={handleChangeCarRentalLink}
        actions={cardActions}
        onDelete={isRemoveAllowed ? removalDialogToggle.handleOpen : undefined}
        priceChangeType={carPriceChangeType}>
        <RTPItineraryCard.Text
          aria-label={formatPickUpDropOffAddress(carRental.pickUpLocation.address)}>
          {formatPickUpDropOffAddress(carRental.pickUpLocation.address)}
        </RTPItineraryCard.Text>
        {shouldShowServiceFeeLabel && (
          <RTPItineraryCard.Text aria-label={t('ReviewTripPWA.Details.ServiceFee')}>
            {t('ReviewTripPWA.Details.ServiceFee')}
          </RTPItineraryCard.Text>
        )}
      </RTPItineraryCard>
      <CarRentalDetailsModal
        onClose={detailsModalToggle.handleClose}
        isOpen={detailsModalToggle.isOpen}
        carRental={carRental}
        carRentalId={carRentalId}
        itineraryId={itineraryId}
        isTripExist={isTripExist}
        carRentalEmission={carRentalEmission}
      />
      <ConfirmationDialog
        title={t(`${i18Base}ChangeCarRental.Title`)}
        content={t(`${i18Base}ChangeCarRental.InfoText`)}
        isOpen={changeCarModalToggle.isOpen}
        onDecline={handleCloseModal}
        onConfirm={handleChangeCarRental}
        confirmButtonText={t(`${i18Base}ChangeCarRental.ConfirmButtonText`)}
        declineButtonText={t(`${i18Base}ChangeCarRental.DeclineButtonText`)}
      />
      <ConfirmationDialog
        isOpen={removalDialogToggle.isOpen}
        onDecline={removalDialogToggle.handleClose}
        onConfirm={handleConfirmRemoveCarRental}
        title={t(`${i18Base}RemoveCarRental`)}
        content={t(`${i18Base}RemoveCarRentalExplanation`)}
        confirmButtonText={t(`${i18Base}RemoveConfirmButton`)}
        declineButtonText={t(`${i18Base}RemoveDeclineButton`)}
      />
      <ViewStateModal viewState={removalViewState} title={removalTitle} />
    </>
  )
}
