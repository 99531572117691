import type { Hotel } from '@fiji/graphql/types'
import { useOutOfPolicyHotelData } from '@fiji/hooks/use-out-of-policy-hotel-data'

type Args = {
  hotel: Hotel
}

const MAX_NAME_LENGTH = 60

export function useHotelCard({ hotel }: Args) {
  const { name, rooms } = hotel
  const firstRoom = rooms?.[0]

  const { isOutOfPolicy, isAllRoomsOutOfPolicy, outOfPolicyTexts } = useOutOfPolicyHotelData(hotel)
  const slicedName = name.length > MAX_NAME_LENGTH ? name.slice(0, MAX_NAME_LENGTH) + '...' : name

  return {
    firstRoom,
    slicedName,
    isOutOfPolicy,
    isAllRoomsOutOfPolicy,
    outOfPolicyTexts,
  }
}
