import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { Action, Inject } from '@etta/di'
import { NotificationsTopicEnum } from '../../core/enums/notifications-topic.enum'
import { NotificationsService } from '../services/notifications.service'
import { NotificationsStore } from '../stores/notifications.store'

@Action()
export class NotificationsActions {
  constructor(
    @Inject()
    private readonly notificationsService: NotificationsService,
    @Inject()
    private readonly notificationsStore: NotificationsStore,
  ) {}

  handleModalOpen() {
    this.notificationsStore.setIsSaveButtonDisabled(true)
    this.notificationsStore.toggle.handleOpen()
  }

  handleModalClose() {
    this.notificationsStore.toggle.handleClose()
  }

  getNotifications() {
    return this.notificationsService.getNotifications()
  }

  handleFieldNotificationChange(
    field: 'email' | 'phoneCountryCode' | 'phoneNumber',
    value: string,
  ) {
    this.notificationsStore.setIsSaveButtonDisabled(false)
    this.notificationsStore.setNotificationValue(field, value)
  }

  handleNotificationsSave() {
    this.notificationsService.saveNotifications()
  }

  handleActionFlightNotificationOpen() {
    this.notificationsStore.setIsSaveButtonDisabled(true)
    this.notificationsStore.flightNotificationActionToggle.handleOpen()
    this.notificationsStore.setNotificationTopic(NotificationsTopicEnum.FLIGHT)
  }

  async handleActionFlightNotificationClose() {
    this.notificationsStore.setIsSaveButtonDisabled(true)
    this.notificationsStore.flightNotificationActionToggle.handleClose()
    await delayForSuccess(300)
    this.notificationsStore.resetState()
  }

  handleActionItineraryNotificationOpen() {
    this.notificationsStore.setIsSaveButtonDisabled(true)
    this.notificationsStore.itineraryNotificationActionToggle.handleOpen()
    this.notificationsStore.setNotificationTopic(NotificationsTopicEnum.ITINERARY)
  }

  async handleActionItineraryNotificationClose() {
    this.notificationsStore.itineraryNotificationActionToggle.handleClose()
    await delayForSuccess(300)
    this.notificationsStore.resetState()
  }

  handleActionSouthwestNotificationOpen() {
    this.notificationsStore.southwestNotificationActionToggle.handleOpen()
    this.notificationsStore.setNotificationTopic(NotificationsTopicEnum.SOUTHWEST)
  }

  async handleActionSouthwestNotificationClose() {
    this.notificationsStore.southwestNotificationActionToggle.handleClose()
    await delayForSuccess(300)
    this.notificationsStore.resetState()
  }

  handleEditFlightNotification(id: number) {
    this.handleActionFlightNotificationOpen()
    this.notificationsService.handleEditNotification(id)
  }

  handleEditItineraryNotification(id: number) {
    this.handleActionItineraryNotificationOpen()
    this.notificationsService.handleEditNotification(id)
  }

  handleEditSouthwestNotification(id: number) {
    this.handleActionSouthwestNotificationOpen()
    this.notificationsService.handleEditNotification(id)
  }

  handleRemoveNotification() {
    this.notificationsService.handleRemoveNotification()
  }
}
