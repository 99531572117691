import { Text } from '@etta/ui/text'
import { Container, Carrier, CarrierImage } from './provider-details-styled'

type Props = {
  trainId?: string | null
  carrierName?: string | null
  carrierImage?: string | null
}
export function ProviderDetails({ trainId, carrierName, carrierImage }: Props) {
  return (
    <Container>
      {!!(carrierName && carrierImage) && (
        <Carrier>
          <CarrierImage src={carrierImage} alt={carrierName} />
          <Text variant="subHeadMedium" color="mainText">
            {carrierName}
          </Text>
        </Carrier>
      )}
      {!!trainId && (
        <Text variant="captionMedium" color="mainText">
          {trainId}
        </Text>
      )}
    </Container>
  )
}
