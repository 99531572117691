import { Action, Inject } from '@etta/di'
import { SelectExpenseCodeService } from '@etta/modules/ride-hail/interface/services/select-expense-code.service'

@Action()
export class SelectExpenseCodeActions {
  constructor(
    @Inject()
    private readonly selectExpenseCodeService: SelectExpenseCodeService,
  ) {}

  handleBack() {
    this.selectExpenseCodeService.goBack()
  }

  handleAddCustomExpenseCode() {
    this.selectExpenseCodeService.goToEnterCustomExpenseCode()
  }

  handleContinue() {
    this.selectExpenseCodeService.handleContinue()
  }

  handleSearchTextValueChanged(newValue: string) {
    this.selectExpenseCodeService.handleSearchTextValueChanged(newValue)
  }

  handleExpenseCodeSelected(expenseCodeId: string) {
    this.selectExpenseCodeService.handleExpenseCodeSelected(expenseCodeId)
  }
}
