import styled, { css } from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const rowCSS = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Row = styled.div`
  ${rowCSS};
`

export const StatusBar = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'captionStrongCaps',
  color: 'mainText',
  isBlock: true,
}))`
  background-color: ${(p) => p.theme.colors.borderDark};
  padding: 7px 16px 5px;
`
