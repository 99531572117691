import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { subHeadStrong } from '@fiji/style'

export const Row = styled.div`
  display: flex;
`

const ItemStyled = css`
  max-width: 360px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 0 48px 0;
  ${subHeadStrong}
  color: ${({ theme }) => theme.colors.bodyText};
  text-decoration: none;
  display: block;
`

export const LeftItem = styled(NavLink)`
  ${ItemStyled}
  text-align: left;
`

export const RightItem = styled(NavLink)`
  ${ItemStyled}
  text-align: right;
  margin-left: auto;
`
