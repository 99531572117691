import { useTranslation } from 'react-i18next'
import { Spinner } from '@etta/ui/spinner'
import { PwaErrorMsg, PwaLoader, PwaMsgHandler } from './messages-styled'

type Props = {
  isLoading: boolean
  errorMessage: string
}

export function Messages({ isLoading, errorMessage }: Props) {
  const { t } = useTranslation()
  const i18nPath = 'SearchResults.'

  const isAbleToShow = isLoading || errorMessage

  if (!isAbleToShow) {
    return null
  }

  return (
    <PwaMsgHandler>
      {isLoading && (
        <PwaLoader>
          <Spinner type="spinout" />
        </PwaLoader>
      )}
      {errorMessage && !isLoading && (
        <PwaErrorMsg>
          {t(i18nPath + 'PwaNoResults')}
          <br />
          {t(i18nPath + 'PwaSearchOtherKeywords')}
        </PwaErrorMsg>
      )}
    </PwaMsgHandler>
  )
}
