import { Inject, Store } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { SegmentTypeCheckService } from '@etta/modules/review-trip/interface/services/segment-type-check.service'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import type { LuggageOptionValueObject } from '../../../core/value-objects'

@Store()
export class LuggageOptionsStore {
  constructor(
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}
  departureLuggageOption?: LuggageOptionValueObject = undefined
  returnLuggageOption?: LuggageOptionValueObject = undefined

  // we show luggage options only for round  and one way flights
  get isLuggageOptionsApplicable(): boolean {
    const flights = this.reviewTripStore.segments.filter((segment) =>
      this.segmentTypeCheckService.isFlightSegment(segment),
    )

    const isOneWay = flights.length === 1
    const isRoundTrip = flights.length === 2

    return isOneWay || isRoundTrip
  }

  setDepartureLuggageOption(luggageOption?: LuggageOptionValueObject) {
    this.departureLuggageOption = luggageOption
  }

  setReturnLuggageOption(luggageOption?: LuggageOptionValueObject) {
    this.returnLuggageOption = luggageOption
  }

  dropStore() {
    this.departureLuggageOption = undefined
    this.returnLuggageOption = undefined
  }
}
