import styled from 'styled-components'
import { title2, headline } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Content = styled.div`
  padding: 0 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Title = styled.div`
  ${headline};
  color: ${(p) => p.theme.colors.mainText2};
`

export const SubTitle = styled.div`
  ${title2};
  color: ${(p) => p.theme.colors.mainText};
  margin: 35px 0 18px;
  padding-left: 4px;
`

export const Chevron = styled(Icon)`
  position: absolute;
  right: 12px;
  top: 17px;
  color: ${(p) => p.theme.colors.bodyText};
`
