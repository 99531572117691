import type { Covid19Statistic, GeosureScore, GeosureScoresResult } from '@fiji/graphql/types'
import type { CovidStatisticArea, Location, CovidStatisticItem, ConcernList } from '../types'
import type { HotelSafetyCheckData } from './types'

export function mapSafetyCheck(data: GeosureScoresResult): HotelSafetyCheckData {
  const { county, state, country } = data.covid19Summary || {}

  return {
    concernList: mapConcernList(data.scores),
    covidStatistic:
      county || state || country
        ? {
            county: mapCovidStatisticArea(county),
            state: mapCovidStatisticArea(state),
            country: mapCovidStatisticArea(country),
          }
        : undefined,
    location: mapLocation(data),
    scores: data.compositeScore || undefined,
  }
}

function mapConcernList(list: GeosureScore[]): ConcernList {
  return list
    .filter((item) => item.label !== 'Composite Score')
    .sort((a, b) => {
      const labelA = a.label.toLowerCase()
      const labelB = b.label.toLowerCase()

      if (labelA > labelB) {
        return 1
      }
      if (labelA < labelB) {
        return -1
      }

      return 0
    })
}

function mapCovidStatisticArea(
  data: Covid19Statistic | undefined | null,
): CovidStatisticArea | null {
  if (!data) {
    return null
  }

  return {
    name: data.name || '',
    deaths: mapCovidStatisticItem({
      daily: data.deathsNewDaily,
      total: data.deathsTotal,
      trend: data.deathsFourteenDayTrend,
    }),
    cases: mapCovidStatisticItem({
      daily: data.confirmedNewDaily,
      total: data.confirmedTotal,
      trend: data.confirmedFourteenDayTrend,
    }),
  }
}

type MapCovidStatisticItemProps = {
  daily: number | null | undefined
  total: number | null | undefined
  trend: number | null | undefined
}

function mapCovidStatisticItem({
  daily,
  total,
  trend,
}: MapCovidStatisticItemProps): CovidStatisticItem[] {
  const list: CovidStatisticItem[] = []

  if (typeof daily === 'number') {
    list.push({
      type: 'daily',
      value: daily,
    })
  }
  if (typeof total === 'number') {
    list.push({
      type: 'total',
      value: total,
    })
  }
  if (typeof trend === 'number') {
    list.push({
      type: 'trend',
      value: Math.abs(Math.floor(trend * 100)),
      isGrowing: Math.sign(trend) !== -1,
    })
  }

  return list
}

function mapLocation({
  city,
  district,
  distance,
}: Pick<GeosureScoresResult, 'city' | 'district' | 'distance'>): Location {
  const name: string[] = []

  if (district) {
    name.push(district)
  }
  if (city) {
    name.push(city)
  }

  return {
    name: name.join(', '),
    distance: distance ? parseFloat(distance.toFixed(1)) : 0,
  }
}
