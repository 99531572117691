import { PaymentSummary } from '@etta/screens/payment-summary'
import { TravelFusionLinks } from '@etta/screens/travel-fusion-links'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const purchaseRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.purchase.summary({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Payment Summary'),
    transitionIndex: 8,
    transitionGroup: 'slide',
    isNavBarHidden: true,
    component: () => <PaymentSummary />,
  },
  {
    route: {
      path: ROUTES.purchase.fusionLinks({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Travel Fusion Links'),
    transitionIndex: 8.1,
    transitionGroup: 'slide',
    isNavBarHidden: true,
    component: () => <TravelFusionLinks />,
  },
]
