import styled from 'styled-components'

export const MainImg = styled.img<{ isLoaded?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  ${(p) => !p.isLoaded && 'display: none'};
`
export const MainImgBg = styled.div`
  background: ${(props) => props.theme.colors.bodyText};
  color: ${(props) => props.theme.colors.borderDark};
  position: relative;
  width: 100%;
  max-height: 241px;
  max-width: 360px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 103px;
    height: auto;
  }
`

export const MainImgLoading = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin: auto;
  background: ${(p) => p.theme.colors.borderLight};
  display: flex;
  justify-content: center;
  align-items: center;
`
