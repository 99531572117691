import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace GetUpcomingFlightsErrors {
  export const UnexpectedGetUpcomingFlightsError = createErrorClass(
    'UnexpectedGetUpcomingFlightsError',
  )
}

export type GetUpcomingFlightsErrorsIntances = InstanceType<
  typeof GetUpcomingFlightsErrors[keyof typeof GetUpcomingFlightsErrors]
>
