import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { TripCancellationDate } from '@etta/components/trip-cancellation-date/trip-cancellation-date'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { exploreDisplayConfigurationSelector } from '@fiji/store/display-configuration'
import { useAppSelector } from '@fiji/store'
import { AutoCancellationMessage } from '@etta/components/auto-cancellation-message'
import { Block } from '@etta/ui/block'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { MobilityLeavingBookingFlowWarningDialog } from '@etta/components/mobility-leaving-booking-flow-warning-dialog'
import { AirSearchFormModalLayout } from '@etta/modules/post-booking/ui/air-search-form-modal'
import { HotelSearchFormModalLayout } from '@etta/modules/post-booking/ui/hotel-search-form-modal'
import { CarSearchFormModalLayout } from '@etta/modules/post-booking/ui/car-search-form-modal'
import type { LayoutProps } from '../../types'
import { DetailsSkeleton } from '../../details-skeleton'
import { DetailsError } from '../../details-error'
import { DateSegment } from '../../date-segment'
import { PastTripHeader } from '../desktop/past-trip-header'
import { TripDetailsAddButtonsRow } from '../../trip-details-add-buttons-row'
import { MobilitySearchFlowModal } from '../../mobility-search-flow-modal/mobility-search-flow-modal'
import { EcoCheckSummary, HotelBookingFailed } from '../common'
import {
  SegmentsWrapper,
  EcoCheckSummaryContainer,
  HotelBookingFailedWrapper,
} from './details-layout-desktop-styled'
import { useDetailsLayoutDesktop } from './use-details-layout-desktop'

export const DetailsLayoutDesktop = observer(function DetailsLayoutDesktop({
  isLoading,
  isError,
  trip,
  groupedSegments,
  failedHotelSegments,
  isTripInProgress,
  onRetry,
  onSeatMapOpen,
  tripEmissionsResult,
  areTripSegmentsEmissionsLoading,
  dynamicSiteMessagesSlot,
}: LayoutProps) {
  const {
    isUpcoming,
    isCanceled,
    isOnHold,
    isPastTripHeaderVisible,
    addButtonSettings,
    areTooManySegments,
    handleRefetchRequest,
  } = useDetailsLayoutDesktop({
    trip,
    onRetry,
    isLoading,
  })
  const { postBookingTripPreviewStore, postBookingTripStore } = usePostBookingContext()
  const { featureFlags } = useFeatureFlags()
  const { tripType } = postBookingTripPreviewStore.tripPreview
  const { isOnHold: isTripOnHold } = postBookingTripStore
  const isShownAddButtonSkeleton = tripType === TripType.Upcoming
  const {
    isCarRentalsSearchEnabled,
    isFlightsSearchEnabled,
    isHotelsSearchEnabled,
  } = useAppSelector(exploreDisplayConfigurationSelector)
  const addButtonEnabledFlags = [
    isCarRentalsSearchEnabled,
    isFlightsSearchEnabled,
    isHotelsSearchEnabled,
  ].filter((flag) => flag)
  const { searchRideHailStore, searchRideHailActions } = useRideHailSearchContext()

  if (isLoading) {
    return (
      <DetailsSkeleton
        addButtonCount={addButtonEnabledFlags.length}
        isShown={isShownAddButtonSkeleton}
      />
    )
  }

  if (isError || !trip) {
    return <DetailsError onButtonClick={handleRefetchRequest} />
  }

  return (
    <>
      {dynamicSiteMessagesSlot}
      {!!failedHotelSegments?.length && (
        <HotelBookingFailedWrapper>
          <HotelBookingFailed segments={failedHotelSegments} />
        </HotelBookingFailedWrapper>
      )}
      {isUpcoming && (
        <>
          <TripDetailsAddButtonsRow
            settings={addButtonSettings}
            areTooManySegments={areTooManySegments}
            isTripInProgress={isTripInProgress}
          />
          <HotelSearchFormModalLayout />
          <AirSearchFormModalLayout />
          <CarSearchFormModalLayout />
        </>
      )}
      {isUpcoming && featureFlags.isMobilityDesktopEnabled && (
        <Block marginBottom={32}>
          <MobilityCallToAction
            processId={trip.processId}
            tripType={trip.type}
            onStartMobilitySearchFlow={searchRideHailActions.openMobilitySearchDesktopModal}
          />
        </Block>
      )}
      {featureFlags.isMobilityDesktopEnabled && (
        <>
          <MobilitySearchFlowModal
            isOpen={searchRideHailStore.isOpenMobilitySearchDesktopModal}
            onClose={searchRideHailActions.openLeavingBookingFlowDialog}
          />
          <MobilityLeavingBookingFlowWarningDialog
            isOpen={searchRideHailStore.isOpenLeavingBookingFlowDialog}
          />
        </>
      )}
      {isCanceled && trip.cancellationTime && <TripCancellationDate date={trip.cancellationTime} />}
      {isPastTripHeaderVisible && <PastTripHeader />}
      {isTripOnHold && trip.holdRules?.autoCancellationTime && (
        <Block marginBottom={32}>
          <AutoCancellationMessage isHolded time={trip.holdRules?.autoCancellationTime} />
        </Block>
      )}
      <SegmentsWrapper isNewTripdetails={featureFlags.isNewTripDetailsPageEnabled}>
        {groupedSegments.map((group, index) => (
          <DateSegment
            key={index}
            day={group.day}
            date={group.day}
            segments={group.segments}
            isOnHold={isOnHold}
            allSegments={trip.segments}
            flightId={trip.flightId}
            isUpcoming={isUpcoming}
            onSeatMapOpen={onSeatMapOpen}
            itineraryId={trip.bookingRef}
            bookingId={trip.processId}
            supportedActions={trip.supportedActions}
            isTripInProgress={isTripInProgress}
            airTickets={trip.airTickets}
          />
        ))}
      </SegmentsWrapper>
      {featureFlags.isSustainabilityEnabled && (
        <EcoCheckSummaryContainer>
          <EcoCheckSummary
            tripEmissionsResult={tripEmissionsResult}
            loading={areTripSegmentsEmissionsLoading}
            segments={trip.segments}
          />
        </EcoCheckSummaryContainer>
      )}
    </>
  )
})
