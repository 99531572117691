import styled from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const DateTimePickerContainer = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  ${KeyboardNavigationStyle}
`

export const TimePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const TimePickerWrapper = styled.div`
  // Slider inside TimePicker component has margin-top: 40px;
  margin-top: -40px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 28px 20px;
`
