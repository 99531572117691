import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { Props } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export function SubmitSectionMobile({ isEnabledToSubmit, handleSearch }: Props) {
  const { t } = useTranslation()

  return (
    <Button
      btnType="primary"
      onClick={handleSearch}
      size="large"
      aria-label={`${t(i18Base + '.Buttons.SearchHotel')} ${t('Accessibility.Button')}`}
      disabled={!isEnabledToSubmit}
      fullWidth
      data-tracking-id="search-hotels-button">
      {t(i18Base + '.Buttons.SearchHotel')}
    </Button>
  )
}
