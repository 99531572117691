import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { CarRentalLocationActions } from './actions/car-rental-location.actions'
import { CarRentalStore } from './stores/car-rental.store'
import { CarFiltersModalStore } from './stores/car-filters-modal.store'
import { CarRentalSearchStore } from './stores/car-rental-search.store'
import { CarRentalCarDetailsStore } from './stores/car-rental-car-details.store'
import { CarRentalSearchActions } from './actions/car-rental-search.actions'
import { CarRentalCarDetailsActions } from './actions/car-rental-car-details.actions'
import { CarRentalsResetStoreActions } from './actions/car-rental-reset-store.actions'
import { CarRentalFilterActions } from './actions/car-rental-filters.actions'
import { CarFiltersStore } from './stores/car-filters.store'

export const { useModuleContext: useCarRentalSearchContext } = hookContextFactory({
  carFiltersStore: CarFiltersStore,
  carFiltersModalStore: CarFiltersModalStore,
  carRentalLocationActions: CarRentalLocationActions,
  carRentalStore: CarRentalStore,
  carRentalSearchStore: CarRentalSearchStore,
  carRentalSearchActions: CarRentalSearchActions,
  carRentalCarDetailsStore: CarRentalCarDetailsStore,
  carRentalCarDetailsActions: CarRentalCarDetailsActions,
  carRentalFilterActions: CarRentalFilterActions,
  carRentalsResetStoreActions: CarRentalsResetStoreActions,
})
