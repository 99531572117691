import type { Coords } from '@etta/modules/etta-map/core/coords'
import type { Place } from '@fiji/types'

export function getCoordinate(coords?: Coords, place?: Place): Coords | undefined {
  if (coords) {
    return coords
  }
  if (place && place.latitude && place.longitude) {
    return { lat: place.latitude, lng: place.longitude }
  }
  return undefined
}
