import styled from 'styled-components'

export const DatePickerWrapper = styled.div`
  position: relative;
`

export const DatePickerModalWrapper = styled.div`
  top: 100%;
  position: absolute;
`

export const SwipingTimePickerWrapper = styled.div`
  top: 100%;
  position: absolute;

  > div {
    left: 0 !important;
  }
`
