import type { ReactElement } from 'react'
import { createContext } from 'react'
import type { PageProps } from './page'

export type PageSwitcherContextType = {
  activePageKey: string
  activePage: ReactElement<PageProps> | null
  isNext: boolean
  isBack: boolean
  next: () => void
  back: () => void
  gotoPage: (key: string) => void
}

export const PageSwitcherContext = createContext<PageSwitcherContextType>({
  activePageKey: '',
  activePage: null,
  isNext: false,
  isBack: false,
  next: () => {
    return undefined
  },
  back: () => {
    return undefined
  },
  gotoPage: (_: string) => {
    return undefined
  },
})
