import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { noop } from '@fiji/utils/noop'
import { OutsideContainer } from '@etta/ui/outside-container'
import { TextLayout } from '@etta/ui/quick-filter-layout/text-layout'
import { Container } from './quick-filter-layout-styled'

type Props = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onClear: () => void
  onApply: () => void
  label: string
  value: string
  selectedNumber: number
  children: ReactNode
  isShimmerLoading?: boolean
  isDisabled?: boolean
  isApplyButtonDisabled?: boolean
  isClearButtonDisabled?: boolean
  isLoading?: boolean
  'data-tracking-id'?: string
}

const i18nBase = 'Accessibility'

export function QuickFilterLayout({
  isOpen,
  onOpen,
  onClose,
  onClear,
  onApply,
  label,
  value,
  selectedNumber,
  children,
  isDisabled,
  isApplyButtonDisabled,
  isClearButtonDisabled,
  isLoading,
  isShimmerLoading,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()

  const handleOpenText = isShimmerLoading || isLoading ? noop : onOpen

  return (
    <Container
      data-tracking-id={dataTrackingId}
      aria-label={t(i18nBase + '.TapToEditFilterWithLabelAndValue', {
        label,
        value,
      })}
      aria-expanded={isOpen}>
      <TextLayout
        isOpen={isOpen}
        onOpen={handleOpenText}
        label={label}
        value={value}
        isShimmerLoading={isShimmerLoading}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
      <OutsideContainer isQuickFilterDesign isOpen={isOpen} onClose={onClose}>
        <OutsideContainer.HeaderSelected
          isQuickFilterDesign={false}
          selectedNumber={selectedNumber}
          label={label}
        />
        <OutsideContainer.FullHeightBody>{children}</OutsideContainer.FullHeightBody>
        <OutsideContainer.Footer
          isQuickFilterDesign={false}
          onApply={onApply}
          isDisabled={isApplyButtonDisabled}
          onClear={onClear}
          shouldHideClear={false}
          isClearButtonDisabled={isClearButtonDisabled}
        />
      </OutsideContainer>
    </Container>
  )
}
