import styled, { css } from 'styled-components'

export const ImageRow = styled.div<{ skin?: 'small-logo' | 'big-logo' | 'default-logo' }>`
  display: flex;
  align-items: center;
  ${(props) => {
    switch (props.skin) {
      case 'default-logo':
        return css`
          max-width: 44px;
          max-height: 44px;
        `
      case 'big-logo':
        return css`
          max-width: 22px;
          max-height: 22px;
          margin-top: -1.5px;
        `
      case 'small-logo':
      default:
        return css`
          width: 20px;
          height: 20px;
        `
    }
  }}
`
