import type { MutableRefObject, LegacyRef, RefCallback } from 'react'

export function mergeRefs<T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>,
): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (!ref) {
        return
      }
      if (typeof ref === 'function') {
        ref(value)
      } else {
        (ref as MutableRefObject<T | null>).current = value
      }
    })
  }
}
