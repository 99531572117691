import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { ButtonContent, ViewListWrapper } from './map-show-button-styled'

type Props = {
  isVisible: boolean
  onMapOpen: () => void
}

const i18nBase = 'HotelResultPage'
export function MapShowButton({ isVisible, onMapOpen }: Props) {
  const { t } = useTranslation()
  return (
    <ViewListWrapper isVisible={isVisible}>
      <Button
        aria-label={t(`${i18nBase}.ViewMapButtonAreaLabel`)}
        size="normal"
        color="primary"
        onClick={onMapOpen}
        data-tracking-id="map-show-button">
        <ButtonContent>
          <Icon name="mapPWA" color="mainText2" />
          <Block marginLeft={10}>
            <Text variant="buttonSmall" color="mainText2">
              {t(i18nBase + '.ViewMap')}
            </Text>
          </Block>
        </ButtonContent>
      </Button>
    </ViewListWrapper>
  )
}
