import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Separator } from '@etta/ui/separator'
import { RAIL_CHECK_STATIONS_LINK, RAIL_SEE_VIDEO_LINK } from '@fiji/constants'
import { Container } from '../rail-delivery-guide-modal-styled'
import { ItemBlock } from '../item-block'

const i18nBase = 'RailDeliveryOption.UsageGuideModal.'
export function CollectGuideView() {
  const { t } = useTranslation()

  return (
    <Container>
      <Block marginTop={10} marginBottom={16}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'CollectGuideSubTitle')}
        </Text>
        <Link size="small" href={RAIL_CHECK_STATIONS_LINK} target="_blank">
          {t(i18nBase + 'CheckStations')}
        </Link>
      </Block>
      <Separator />
      <Block marginTop={16}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'WatchCollectVideo')}
        </Text>
        <Block>
          <Link size="small" href={RAIL_SEE_VIDEO_LINK} target="_blank">
            {t(i18nBase + 'SeeVideo')}
          </Link>
        </Block>
      </Block>
      <Block marginTop={30}>
        <ItemBlock iconName="tapPWA" itemText={t(i18nBase + 'CollectMachineGuide')} />
        <ItemBlock iconName="insertCardPWA" itemText={t(i18nBase + 'CollectCardGuide')} />
        <ItemBlock iconName="typePWA" itemText={t(i18nBase + 'CollectReferenceGuide')} />
        <ItemBlock iconName="printPWA" itemText={t(i18nBase + 'CollectPrintGuide')} />
      </Block>
    </Container>
  )
}
