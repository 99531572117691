import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import { IconButton } from '@etta/ui/icon-button'
import type { RideHailGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { SegmentType, TripStatus } from '@fiji/graphql/types'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { MobilityRideDetails } from '@etta/components/mobility-ride-details/mobility-ride-details'
import { UberRideSupport } from '@etta/components/uber-ride-support/uber-ride-support'
import { Headline } from '../../headline'
import { Traveler } from '../../traveler'
import { Price } from '../../price'
import { useTripRideHailContent } from '../../use-ride-hail/use-trip-ride-hail-content'
import {
  DetailsContainer,
  TripDetailContainer,
  ContentWrapper,
} from './trip-ride-hail-content-desktop-layout-styled'

export type TripRideHailContentProps = {
  rideHail: RideHailGroupedSegmentValueObject
  onCancel: () => void
  onClose: () => void
  tripName?: string | null
}

export function TripRideHailContentDeskTopLayout({
  onCancel,
  onClose,
  rideHail,
}: TripRideHailContentProps) {
  const {
    pickUpTime: pickUpDateTime,
    pickUpAddress,
    dropOffAddress,
    description,
    productName,
    price,
    bookingId,
    estimatedPriceRange,
    tripStatus,
  } = rideHail
  const { scrollContainerRef, headerRef } = useHeaderScroll()
  const { pickUpDate, pickUpTime, headerBackgroundColor, actionButtons } = useTripRideHailContent({
    pickUpDateTime,
    pickUpAddress,
    bookingId,
    description,
    productName,
    tripStatus,
  })

  const { t } = useTranslation()
  const i18nBase = 'PostBooking.RideHailDetails'
  return (
    <Modal.Body ref={scrollContainerRef}>
      <Modal.Header isMobile backgroundColor="transparent">
        <Header
          headerRef={headerRef}
          backgroundColor={headerBackgroundColor}
          rightSlot={<IconButton icon="CloseFilledDarkPWA" size="medium" onClick={onClose} />}
        />
      </Modal.Header>
      <DetailsContainer color={headerBackgroundColor}>
        <Headline
          title={productName ?? ''}
          subTitle={t(`${i18nBase}.RideHailToDestination`, { destination: dropOffAddress })}
        />
      </DetailsContainer>
      <ContentWrapper>
        <Block marginTop={-20} paddingHorizontal={16}>
          <Block marginBottom={16}>
            <Traveler tripStatus={tripStatus} />
          </Block>

          <TripDetailContainer>
            <Block marginBottom={12} paddingHorizontal={16}>
              <Text variant="captionStrongCaps" color={'bodyText'}>
                {t(`${i18nBase}.TripDetails`)}
              </Text>
            </Block>
            <Separator lineType="solid" color="borderLight" />
            <Block paddingTop={24} paddingBottom={20} paddingHorizontal={16}>
              <MobilityRideDetails
                pickUpDate={pickUpDate}
                dropOffAddress={dropOffAddress ?? ''}
                pickUpAddress={pickUpAddress ?? ''}
                pickUpTime={pickUpTime}
              />
            </Block>
            {tripStatus !== TripStatus.Cancelled && tripStatus !== TripStatus.Completed && (
              <Block paddingHorizontal={16}>
                <UberRideSupport />
              </Block>
            )}
          </TripDetailContainer>
          {tripStatus !== TripStatus.Cancelled && (
            <Block>
              <Price
                estimatedPriceRange={estimatedPriceRange}
                price={price}
                tripStatus={tripStatus}
              />
            </Block>
          )}
        </Block>
        {tripStatus !== TripStatus.Cancelled && (
          <Block marginTop={46} marginBottom={16}>
            <ActionButtons
              type={SegmentType.RideHail}
              isPostBooking
              onShareClick={actionButtons.handleShare}
              onCancelClick={onCancel}
              onChangeClick={() => {}}
              isChange={false}
              isCancel={tripStatus === TripStatus.Confirmed}
              isChangeAllowed={false}
            />
          </Block>
        )}
      </ContentWrapper>
    </Modal.Body>
  )
}
