import { useTranslation } from 'react-i18next'
import {
  CardInfoContainer,
  CardName,
} from '@etta/components/credit-card-item/row/layout/mobile/row-mobile-styled'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { CardIconWrapper } from '@etta/components/credit-card-item/card-icon/card-icon-styled'
import type { ColorPalette } from '@fiji/types'

type Props = {
  backgroundColor?: keyof ColorPalette
}

export function OnAccountCreditCardInfo({ backgroundColor }: Props) {
  const { t } = useTranslation()

  return (
    <Block isFlexBlock paddingVertical={16} flexGap={8}>
      <CardIconWrapper backgroundColor={backgroundColor}>
        <Icon name="cardPWA" size="medium" />
      </CardIconWrapper>
      <CardInfoContainer layout={'summary'}>
        <CardName aria-label={t('PaymentSummary.BilledToYourCompanyAccount')}>
          {t('PaymentSummary.BilledToYourCompanyAccount')}
        </CardName>
      </CardInfoContainer>
    </Block>
  )
}
