import styled from 'styled-components'

export const BackgroundSection = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  margin: 8px 0 0 12px;
`

export const BgImage = styled.div<{ url?: string | null }>`
  min-height: 220px;
  background-image: url(${(props) => props.url});
  background-position: center center;
  background-size: cover;
`
