import type { CSSProperties } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

type Props = {
  isVisible: boolean
  contentAnimationDuration: number
}

const ANIMATION_DURATION = 300

export function useScoreListItem({ isVisible, contentAnimationDuration }: Props) {
  const isVisibleRef = useRef(isVisible)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isAppearedRef = useRef(false)
  const [style, setStyle] = useState<CSSProperties>({
    width: isVisible ? '100%' : '0%',
    transition: `width ${ANIMATION_DURATION}ms linear ${contentAnimationDuration * 0.25}ms`,
  })
  const showAnimation = useCallback(() => {
    if (isAppearedRef.current) {
      return
    }
    const container = containerRef.current
    if (!container) {
      return
    }
    const onTransitionEnd = () => {
      if (isVisibleRef.current && !isAppearedRef.current) {
        isAppearedRef.current = true
      }
      if (!isVisibleRef.current && !isAppearedRef.current) {
        setStyle((state) => ({
          ...state,
          width: '0%',
        }))
      }

      container.removeEventListener('transitionend', onTransitionEnd)
    }
    container.addEventListener('transitionend', onTransitionEnd)

    setStyle((state) => ({
      ...state,
      width: '100%',
    }))
  }, [])

  useEffect(() => {
    isVisibleRef.current = isVisible

    if (isVisible) {
      showAnimation()
    }
  }, [isVisible, showAnimation])

  return { scaleAnimation: { ref: containerRef, style } }
}
