import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 1px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 100;
  > button {
    background-color: ${(p) => p.theme.colors.white};
    border: 0;
  }
`
