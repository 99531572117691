import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import type { PrivacyPolicyConfiguration } from '@fiji/graphql/types'

type Props = {
  onShowBookingConditions?: () => void
  nationalRailConditions?: string
  privacyPolicy: PrivacyPolicyConfiguration
}

const i18nBase = 'PaymentSummary'

export function Footer({ onShowBookingConditions, privacyPolicy, nationalRailConditions }: Props) {
  const { t } = useTranslation()
  const startText = t(`${i18nBase}.BookingConditionPhraseStart`)
  const bookingConditionText = t(`${i18nBase}.BookingConditionLink`)
  const nationalRailConditionsText = t(`${i18nBase}.NationalRailConditionsLink`)
  const termsOfServiceText = t(`${i18nBase}.TermsOfService`)
  const privacyPolicyText = privacyPolicy.label || t(`${i18nBase}.PrivacyPolicy`)
  const ariaLabel = t(`${i18nBase}.FooterLabel`, {
    start_text: startText,
    booking_condition: onShowBookingConditions ? bookingConditionText : nationalRailConditionsText,
    terms_of_service: termsOfServiceText,
    privacy_policy: privacyPolicyText,
  })

  return (
    <div aria-label={ariaLabel}>
      {startText}{' '}
      {onShowBookingConditions && (
        <>
          <Link
            onClick={onShowBookingConditions}
            size="caption"
            data-tracking-id="booking-condition-link">
            {bookingConditionText}
          </Link>
          {', '}
        </>
      )}
      {nationalRailConditions && (
        <>
          <Link
            href={nationalRailConditions}
            size="caption"
            target="_blank"
            data-tracking-id="national-rail-conditions-link">
            {nationalRailConditionsText}
          </Link>
          {', '}
        </>
      )}
      <Link
        href="https://www.deem.com/privacy#Terms-of-Use"
        size="caption"
        target="_blank"
        data-tracking-id="terms-of-service-link">
        {termsOfServiceText}
      </Link>
      <span>{` ${t(`${i18nBase}.And`)} `}</span>
      <Link
        href={privacyPolicy.url}
        size="caption"
        target="_blank"
        data-tracking-id="privacy-policy-link">
        {privacyPolicyText}
      </Link>
    </div>
  )
}
