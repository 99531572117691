import { useTranslation } from 'react-i18next'
import { ReservationTitle } from './reservation-number-styled'

type Props = {
  number?: string
}

const i18nBase = 'PurchaseReservationSuccess.'

export function ReservationNumber({ number }: Props) {
  const { t } = useTranslation()

  if (!number) {
    return null
  }

  return (
    <ReservationTitle aria-label={t(i18nBase + 'ReservationNumberLabel', { number })}>
      <>
        <span aria-hidden>{t(i18nBase + 'ReservationNum')}</span>{' '}
        <span aria-label={t(i18nBase + 'ReservationNum')}>{number}</span>
      </>
    </ReservationTitle>
  )
}
