import { useTranslation } from 'react-i18next'
import { useExpand } from '@fiji/hooks/use-expand/use-expand'

export function useExpanded() {
  const { t } = useTranslation()
  const { isExpanded, toggleExpansion } = useExpand()

  const i18nBase = 'FlightDetails.'
  const buttonText = t(i18nBase + (isExpanded ? 'HideDetailsButton' : 'ShowDetailsButton'))
  const buttonTextAria = t(
    i18nBase + (isExpanded ? 'HideDetailsButtonAria' : 'ShowDetailsButtonAria'),
    { label: buttonText },
  )

  return {
    isExpanded,
    toggleExpansion,
    buttonText,
    buttonTextAria,
  }
}
