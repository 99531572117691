import { createPortal } from 'react-dom'
import type { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  target?: string
}>

export function Portal({ children, target }: Props) {
  let targetElement = target ? (document.querySelector(target) as Element) : document.body

  if (process.env.NODE_ENV === 'test' && !targetElement) {
    targetElement = document.body
  }

  if (process.env.NODE_ENV !== 'production' && !targetElement) {
    throw new Error(
      `Looks like target prop for <Portal> component is not correct, please check "${target}"`,
    )
  }
  return createPortal(
    <div data-test="portal" data-tracking-id="portal">
      {children}
    </div>,
    targetElement,
  )
}
