import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Spinner } from '@etta/ui/spinner'
import { useAlertContext } from '@etta/modules/alert/interface'
import { Swap } from '@etta/ui/swap'
import { AlertCardMobile } from '../../../alert-card/mobile'
import { AlertError } from '../../../alert-error'

const i18nBase = 'Alerts'

export const AlertModalMobile = observer(function AlertModalMobile() {
  const { t } = useTranslation()
  const { alertStore } = useAlertContext()
  const { alerts, isError, isLoading } = alertStore

  return (
    <>
      <Modal.Header isMobile>
        <Modal.Title aria-label={t(`${i18nBase}.Title`)} color="white" backgroundColor="plum">
          {t(`${i18nBase}.Title`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Swap is={isError} isSlot={<AlertError />}>
          <Swap is={isLoading} isSlot={<Spinner type="spinout" />}>
            {alerts.map(({ title, message }, id) => {
              return (
                <AlertCardMobile
                  key={`${title}-${id}`}
                  title={title}
                  message={message}
                  onRedirect={() => {}}
                />
              )
            })}
          </Swap>
        </Swap>
      </Modal.Body>
    </>
  )
})
