import type { Assets } from './types'

const SCRIPT_ID = 'insert-chat-script'

export function insertChat(assets: Assets) {
  // @ts-ignore
  const chatScript = assets?.find(
    // @ts-ignore
    (asset) =>
      asset.type === 'script' &&
      asset.props?.dangerouslySetInnerHTML?.__html?.includes('destygo-webchat'),
  )

  if (!chatScript) {
    return
  }

  const isInserted = !!document.getElementById(SCRIPT_ID)
  if (isInserted) {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.id = SCRIPT_ID
  scriptElement.innerHTML = chatScript.props?.dangerouslySetInnerHTML?.__html
  document.body.appendChild(scriptElement)
}
