/* eslint import/no-restricted-paths: 0 */
import { Inject, Service } from '@etta/di'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { AddSegmentModalsStore } from '@etta/modules/review-trip/interface/stores/add-segment-modals.store'
import { CarSearchFormStore } from '@etta/modules/car-rental-search/interface/stores/car-search-form.store'
import { dateToTime } from '@fiji/utils/dates'
import type { Place, PlainTime } from '@fiji/types'
import { SegmentAddressMapper } from '@etta/modules/review-trip/core'
import { CarSearchFormService } from '@etta/modules/car-rental-search/interface/services/car-search-form.service'
import { CarSearchButtonActions } from '@etta/modules/car-rental-search/interface/actions/car-search-button.action'
import { AutocompleteService } from '@etta/modules/autocomplete'
import { PlacesSearchType } from '@fiji/graphql/types'
import { setUtcDate } from '@fiji/utils/dates/set-utc-date'
import { convertFlightToRangeRTP } from '@fiji/hooks/use-add-button-settings/get-query-params/selectors/convert-flight-to-range'
import { SegmentTypeCheckActions } from '../actions/segment-type-check.actions'

const PICK_UP_TIME = { hours: 9, minutes: 0 }
const DROP_OFF_TIME = { hours: 17, minutes: 0 }

@Service()
export class AddCarSegmentService {
  constructor(
    @Inject()
    private readonly reviewTripPageStore: ReviewTripStore,
    @Inject()
    private readonly carSearchFormService: CarSearchFormService,
    @Inject()
    private readonly carSearchButtonActions: CarSearchButtonActions,
    @Inject()
    private readonly autocompleteService: AutocompleteService,
    @Inject()
    private readonly addSegmentModalsStore: AddSegmentModalsStore,
    @Inject()
    private readonly carSearchFormStore: CarSearchFormStore,
    @Inject()
    private readonly segmentTypeCheck: SegmentTypeCheckActions,
  ) {}

  public async handleAddCar() {
    this.carSearchFormStore.updateEditCache({
      itineraryId: this.reviewTripPageStore.itineraryId,
      pickUpPlace: undefined,
      dropOffPlace: undefined,
      pickUpDate: undefined,
      dropOffDate: undefined,
      pickUpTime: undefined,
      dropOffTime: undefined,
    })

    if (this.reviewTripPageStore.segments?.some(this.segmentTypeCheck.isCarRentalSegment)) {
      this.addSegmentModalsStore.openCarSearchModal()
      return
    }

    const flights = this.reviewTripPageStore.segments?.filter(this.segmentTypeCheck.isFlightSegment)
    const isMultiCityTrip = flights.length > 2
    if (isMultiCityTrip) {
      this.addSegmentModalsStore.openCarSearchModal()
      return
    }

    const flightData = this.findFlightData()
    if (flightData) {
      this.prefillCarRentalSearchForm({
        fromLocation: flightData.fromLocation,
        toLocation: flightData.toLocation,
        pickUpDate: flightData.arrivalDate,
        pickUpTime: flightData.arrivalTime,
        dropOffDate: flightData.returnDate,
        dropOffTime: flightData.returnTime,
      })
      return
    }
    const hotelData = await this.findHotelData()

    if (hotelData) {
      this.prefillCarRentalSearchForm({
        fromLocation: { ...hotelData.location, airportCode: undefined },
        toLocation: { ...hotelData.location, airportCode: undefined },
        pickUpDate: hotelData.checkInDate,
        pickUpTime: PICK_UP_TIME,
        dropOffDate: hotelData.checkOutDate,
        dropOffTime: DROP_OFF_TIME,
      })
      return
    }

    this.addSegmentModalsStore.openCarSearchModal()
  }

  private async prefillCarRentalSearchForm({
    fromLocation,
    toLocation,
    pickUpDate,
    pickUpTime,
    dropOffDate,
    dropOffTime,
  }: {
    fromLocation: Place
    toLocation: Place
    pickUpDate: Date
    pickUpTime: PlainTime
    dropOffDate?: Date
    dropOffTime?: PlainTime
  }) {
    const startLocation = await this.findLocation(fromLocation)
    if (!startLocation?.shortName && fromLocation?.shortName) {
      startLocation.shortName = fromLocation.shortName
    }
    const endLocation = await this.findLocation(toLocation)
    if (!endLocation?.shortName && toLocation?.shortName) {
      endLocation.shortName = toLocation.shortName
    }

    if (startLocation) {
      this.carSearchFormService.changePickUpPlace(startLocation)
    }

    if (endLocation) {
      this.carSearchFormService.changeDropOffPlace(endLocation)
    }

    if (pickUpTime) {
      this.carSearchFormService.changePickUpTime(pickUpTime)
    }

    if (dropOffTime) {
      this.carSearchFormService.changeDropOffTime(dropOffTime)
    }

    if (pickUpDate && dropOffDate) {
      this.carSearchFormService.changeDates({ date: pickUpDate, dateEnd: dropOffDate })
    }

    if (!this.carSearchButtonActions.isInputValid()) {
      this.carSearchFormStore.updateEditCache({
        itineraryId: this.reviewTripPageStore.itineraryId,
        pickUpPlace: fromLocation,
        dropOffPlace: toLocation,
        pickUpDate: pickUpDate,
        pickUpTime: pickUpTime,
        dropOffDate: dropOffDate,
        dropOffTime: dropOffTime,
      })
      this.addSegmentModalsStore.openCarSearchModal()
    } else {
      this.carSearchButtonActions.handleSubmit()
    }
  }

  private findFlightData() {
    const flights = this.reviewTripPageStore.segments?.filter(this.segmentTypeCheck.isFlightSegment)
    const isMultiCityTrip = flights?.length > 2

    if (!flights.length || isMultiCityTrip) {
      return null
    }

    const range = convertFlightToRangeRTP(flights)

    const startFlightArrivalDate = new Date(range.startDate)

    if (range.endDate) {
      const endFlightDepartureDate = new Date(range.endDate)
      return {
        arrivalDate: startFlightArrivalDate,
        arrivalTime: dateToTime(startFlightArrivalDate),
        returnDate: endFlightDepartureDate,
        returnTime: dateToTime(endFlightDepartureDate),
        fromLocation: SegmentAddressMapper.mapSegmentAddressToLocation(range.from),
        toLocation: SegmentAddressMapper.mapSegmentAddressToLocation(range.to),
      }
    }

    return {
      arrivalDate: startFlightArrivalDate,
      arrivalTime: dateToTime(startFlightArrivalDate),
      returnDate: undefined,
      returnTime: undefined,
      fromLocation: SegmentAddressMapper.mapSegmentAddressToLocation(range.from),
      toLocation: SegmentAddressMapper.mapSegmentAddressToLocation(range.to),
    }
  }

  private async findHotelData() {
    const hotelSegment = this.reviewTripPageStore.segments?.find(
      this.segmentTypeCheck.isHotelSegment,
    )

    if (!hotelSegment) {
      return null
    }

    const { checkIn, checkOut } = hotelSegment

    const location = SegmentAddressMapper.mapHotelSegmentAddressToLocation(hotelSegment)

    const foundLocation = await this.findLocation(location)

    return {
      checkInDate: setUtcDate(new Date(checkIn)),
      checkOutDate: setUtcDate(new Date(checkOut)),
      location: foundLocation,
    }
  }

  private async findLocation(currentLocation: Place) {
    const foundLocations = await this.autocompleteService.getPlaces({
      query: currentLocation.name,
      searchType: PlacesSearchType.CarRentalSearch,
    })

    if (!foundLocations?.length) {
      return currentLocation
    }

    let foundedLocation = foundLocations[0]

    if (currentLocation.airportCode) {
      foundedLocation = { ...foundedLocation, airportCode: currentLocation.airportCode }
    }

    return foundedLocation
  }
}
