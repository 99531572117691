import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace RailETicketsErrors {
  export const GetETicketsError = createErrorClass('Get e-tickets Error')
  export const UnexpectedErrorGetETickets = createErrorClass(
    'Unexpected error while getting e-tickets',
  )
}

export type RailETicketsErrorsInstances = InstanceType<
  typeof RailETicketsErrors[keyof typeof RailETicketsErrors]
>
