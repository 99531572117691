import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const i18nBase = 'Trips'

export function useTripListReloadErrorMobile() {
  const { t } = useTranslation()

  const translations = useMemo(
    () => ({
      errorText: t(i18nBase + '.Error.Title'),
      retryButtonText: t(i18nBase + '.Error.ReloadButton'),
    }),
    [t],
  )

  return {
    translations,
  }
}
