import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { Props } from '../../types'
import { ButtonWrapper } from './search-button-desktop.styled'

const i18Base = 'TripPlanner.BaseSearch'

export function SearchButtonDesktop({ handleSearchClick, isIcon, isDisabled }: Props) {
  const { t } = useTranslation()

  return (
    <ButtonWrapper
      variant="solid"
      btnType="primary"
      size="large"
      fullWidth={false}
      onClick={handleSearchClick}
      paddings={false}
      aria-label={t(i18Base + '.Buttons.SearchAir')}
      data-tracking-id="header-search-button"
      disabled={isDisabled}
      isIcon={isIcon}>
      {isIcon ? (
        <Icon name="searchPWA" size={36} color="mainText2" />
      ) : (
        <Text variant="headline" color="mainText2">
          {t(i18Base + '.Buttons.SearchAir')}
        </Text>
      )}
    </ButtonWrapper>
  )
}
