import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { Spinner } from '@etta/ui/spinner'
import {
  Container,
  DescriptionContainer,
  TitleContainer,
  TextContainer,
  ButtonContainer,
  ButtonWrapper,
} from './uber-info-action-card-styled'
import { useUberInfoActionCard } from './use-uber-info-action-card'

export const UberInfoActionCard = observer(function UberInfoActionCard() {
  const {
    title,
    description,
    buttonText,
    isShowCard,
    isSubmitted,
    handleSubmit,
    isError,
    isCreateCustomerUberAccountExecuting,
  } = useUberInfoActionCard()
  if (!isShowCard) {
    return null
  }

  return (
    <Container>
      {title && <TitleContainer variant="headline">{title}</TitleContainer>}
      <DescriptionContainer variant="footnoteMedium">
        <Swap is={!isSubmitted && !isError} isSlot={description}>
          <Icon
            name={isError ? 'warningFilledPWA' : 'preferredPWA'}
            color={isError ? 'warning' : undefined}
            size="medium"
          />
          <TextContainer>{description}</TextContainer>
        </Swap>
      </DescriptionContainer>
      <ButtonContainer>
        <ButtonWrapper
          fullWidth
          disabled={isSubmitted}
          variant={isSubmitted ? 'outline' : 'solid'}
          onClick={handleSubmit}
          rightIcon={
            isCreateCustomerUberAccountExecuting && (
              <Spinner type="spinout" color="white" size="small" />
            )
          }>
          {buttonText}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})
