import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { Inject, Service } from '@etta/di'
import { Device } from '@etta/core/entities/notification.entity'
import type { NotificationEntity } from '@etta/core/entities/notification.entity'
import { NotificationsTopicEnum } from '../../core/enums/notifications-topic.enum'
import { NotificationsStore } from '../stores/notifications.store'
import { isNotificationDuplicated } from './utils/is-notification-duplicated'
import { BaseSettingsService } from './base.service'

@Service()
export class NotificationsService {
  constructor(
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly notificationsStore: NotificationsStore,
  ) {}

  getNotifications() {
    const { notification } = this.baseSettingsService.getStructuredProfile()

    if (!notification) {
      return {
        itineraryNotifications: [],
        flightNotifications: [],
        southwestNotifications: [],
      }
    }

    return {
      itineraryNotifications: notification.filter(
        ({ topic }) => topic === NotificationsTopicEnum.ITINERARY,
      ),
      flightNotifications: notification.filter(
        ({ topic }) => topic === NotificationsTopicEnum.FLIGHT,
      ),
      southwestNotifications: notification.filter(
        ({ topic }) => topic === NotificationsTopicEnum.SOUTHWEST,
      ),
    }
  }

  getNotificationsInput() {
    const {
      notificationsValidator: {
        values: {
          email: newEmail,
          phoneCountryCode: newPhoneCountryCode,
          phoneNumber: newPhoneNumber,
        },
      },
      notificationIdEditMode: id,
      notificationTopic: currentTopic,
    } = this.notificationsStore

    const { notification } = this.baseSettingsService.getStructuredProfile()
    const isEmptyData = !newEmail && (!newPhoneNumber || !newPhoneCountryCode)
    const isDuplicated = isNotificationDuplicated(notification ?? [], {
      newEmail,
      newPhoneCountryCode,
      newPhoneNumber,
      currentTopic,
    })

    if (isEmptyData || isDuplicated || !currentTopic) {
      return
    }

    const existingNotification = notification
      ? notification.map((item) => {
          if (item.id === id) {
            return {
              topic: item.topic,
              device: item.device,
              email: newEmail || undefined,
              phone:
                newPhoneNumber && newPhoneCountryCode
                  ? {
                      isoCountryCode: newPhoneCountryCode,
                      number: newPhoneNumber,
                    }
                  : undefined,
            }
          }

          return this.mapNotification(item)
        })
      : []

    if (id) {
      return [...existingNotification]
    }

    const emailNotification = newEmail
      ? [
          {
            topic: currentTopic,
            device: Device.Email,
            email: newEmail,
          },
        ]
      : []
    const phoneNotification =
      newPhoneNumber && newPhoneCountryCode
        ? [
            {
              topic: currentTopic,
              device: Device.Sms,
              phone: {
                isoCountryCode: newPhoneCountryCode,
                number: newPhoneNumber,
              },
            },
          ]
        : []

    return [...existingNotification, ...emailNotification, ...phoneNotification]
  }

  async saveNotifications() {
    const input = this.getNotificationsInput()
    const toggle = this.notificationsStore.currentNotificationToggle
    const validatorCheck = await this.notificationsStore.notificationsValidator.submit()

    if (!input || !toggle || !validatorCheck.isValid) {
      return
    }

    const result = await this.baseSettingsService.saveUser({
      mode: 'notifications',
      input,
      handleClose: toggle.handleClose,
    })

    if (result.isErr()) {
      return
    }

    await delayForSuccess(300)
    this.notificationsStore.resetState()
  }

  formatNotificationChannel(notification: NotificationEntity) {
    const { email, phone } = notification

    return email || [phone?.country, phone?.number].filter(Boolean).join('-')
  }

  mapNotification(notification: NotificationEntity) {
    const { topic, device, email, phone } = notification

    return {
      topic,
      device,
      email: email || undefined,
      phone:
        phone && phone.number && phone.isoCountryCode
          ? {
              isoCountryCode: phone.isoCountryCode,
              number: phone.number,
            }
          : undefined,
    }
  }

  handleEditNotification(id: number) {
    const { notification } = this.baseSettingsService.getStructuredProfile()
    const editedNotification = notification?.find((item) => item.id === id)

    if (!editedNotification) {
      return
    }

    const { email, phone } = editedNotification
    this.notificationsStore.setNotificationIdEditMode(id)

    if (email) {
      this.notificationsStore.setNotificationValue('email', email)
      this.notificationsStore.setIsPhoneFieldHidden(true)
    }

    if (phone?.number && phone?.isoCountryCode) {
      this.notificationsStore.setNotificationValue('phoneCountryCode', phone.isoCountryCode)
      this.notificationsStore.setNotificationValue('phoneNumber', phone.number)
      this.notificationsStore.setIsEmailFieldHidden(true)
    }
  }

  async handleRemoveNotification() {
    const { notificationIdEditMode, notificationRemoveToggle } = this.notificationsStore
    const { notification } = this.baseSettingsService.getStructuredProfile()
    const toggle = this.notificationsStore.currentNotificationToggle
    const filteredNotification = notification?.filter((item) => item.id !== notificationIdEditMode)

    if (!filteredNotification || !toggle) {
      return
    }

    const input = filteredNotification?.map((item) => {
      return this.mapNotification(item)
    })
    notificationRemoveToggle.handleClose()

    await this.baseSettingsService.saveUser({
      mode: 'notifications',
      input,
      handleClose: toggle.handleClose,
    })

    await delayForSuccess(300)
    this.notificationsStore.resetState()
  }
}
