import { useTranslation } from 'react-i18next'
import { MobilitySelectLocationModal } from '@etta/components/mobility-select-location-modal'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { UberInfoModal } from '../uber-info-modal'
import { BrandLogo } from '../brand-logo'
import { MobilitySearchFormDialog } from '../mobility-search-form-dialog'
import {
  SheetContainer,
  SheetContent,
  MiniPill,
  ScrollContainer,
  ScrollContent,
  HeaderContainer,
  WhereToButton,
} from './mobility-where-to-dialog-styled'
import { TRANSITION_NAME } from './transition'
import { MobilitySuggestedDropOff } from './mobility-suggested-drop-off'
import { useMobilityWhereToDialog } from './use-mobility-where-to-dialog'
import { useTrackMobilityWhereTo } from './hooks'

type Prop = {
  isOpen: boolean
  handleOnEntered: () => void
  handleStartSearch: () => void
  containerRef?: (node: HTMLDivElement) => void
}
const i18nBasePath = 'Mobility.WhereToPage.'

export function MobilityWhereToDialog({
  isOpen,
  handleOnEntered,
  handleStartSearch,
  containerRef,
}: Prop) {
  const { t } = useTranslation()
  const {
    handleSelectSuggestedAsDropOff,
    handleSubmitSearch,
    handleModalClose,
    handleExited,
    isModalOpen,
    dropOffPlace,
    dropOffAddress,
    handleSelectWhereTo,
    isCustomDropOff,
    isSelectLocationModalVisible,
    isModalInfoOpen,
    handleModalInfoClose,
    handleModalInfoOpen,
    isModalSearchOpen,
    isModalSearchVisible,
    handleModalSearchClose,
    handleModalSearchExited,
  } = useMobilityWhereToDialog({ handleStartSearch })
  useTrackMobilityWhereTo({ isOpen })

  return (
    <>
      <SheetContainer ref={containerRef}>
        <DialogAnimation
          isOpen={isOpen}
          onEntered={handleOnEntered}
          onExited={() => {}}
          isAnimated
          transitionName={TRANSITION_NAME}>
          <SheetContent>
            <MiniPill />
            <ScrollContainer>
              <ScrollContent>
                <HeaderContainer>
                  <BrandLogo height="30" />
                  <IconButton
                    icon="infoPWA"
                    size="medium"
                    color="mainText1"
                    onClick={handleModalInfoOpen}
                  />
                </HeaderContainer>
                <Block>
                  <WhereToButton
                    data-tracking-id="mobility-where-to-search-button"
                    fullWidth={true}
                    variant="link"
                    size="normal"
                    leftIcon={<Icon name="searchPWA" size={28} />}
                    onClick={handleSelectWhereTo}>
                    {t(i18nBasePath + 'SearchDropOffTextButton')}
                  </WhereToButton>
                </Block>
                <MobilitySuggestedDropOff onDropOffSelected={handleSelectSuggestedAsDropOff} />
              </ScrollContent>
            </ScrollContainer>
          </SheetContent>
        </DialogAnimation>
      </SheetContainer>
      {isModalOpen && (
        <MobilitySelectLocationModal
          isOnlyPickUp={!isCustomDropOff}
          isVisible={isSelectLocationModalVisible}
          dropOffPlace={dropOffPlace}
          dropOffAddress={dropOffAddress}
          onClose={handleModalClose}
          onSubmit={handleSubmitSearch}
          onExited={handleExited}
        />
      )}
      {isModalSearchOpen && (
        <MobilitySearchFormDialog
          isVisible={isModalSearchVisible}
          onClose={handleModalSearchClose}
          onExited={handleModalSearchExited}
          handleStartSearch={handleStartSearch}
        />
      )}
      <UberInfoModal
        isVisible={isModalInfoOpen}
        isCloseModalOnlyOnExit={true}
        onClose={handleModalInfoClose}
      />
    </>
  )
}
