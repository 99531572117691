import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1 0px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`

export const LeftSide = styled.div`
  width: 90%;
  overflow-y: auto;
`

export const RightSide = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const RightSideContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  padding-right: 20px;
`
