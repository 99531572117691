import type { Dispatch, SetStateAction } from 'react'
import { useSwipeable } from 'react-swipeable'
import { getYearBounds } from '@etta/ui/swiping-time-date-picker/assets/get-year-bounds'
import { SWIPE_THRESHOLD } from '@etta/ui/swiping-time-date-picker/constants'
import type { SwipingTimePickerBars, SwipingPickerSubtype } from '../types'
import { calcSwipeAssets, setSwipeAction, flipColumn } from '../assets/swipe-effects'

type Props = {
  variant?: SwipingPickerSubtype
  hour: number
  minute: number
  minuteBarTop: SwipingTimePickerBars
  hourBarTop: SwipingTimePickerBars
  setHour: Dispatch<SetStateAction<number>>
  setMinute: Dispatch<SetStateAction<number>>
  setAmpm: Dispatch<SetStateAction<number>>
  setMinuteSwiping: Dispatch<SetStateAction<number>>
  setHourSwiping: Dispatch<SetStateAction<number>>
  setAmpmSwiping: Dispatch<SetStateAction<number>>
  setMinuteFlipping: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setHourFlipping: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setMinuteBarTop: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setHourBarTop: Dispatch<SetStateAction<SwipingTimePickerBars>>
  hourlyRange?: number
}

export function useTimeHandlers({
  variant,
  hour,
  minute,
  minuteBarTop,
  hourBarTop,
  setHour,
  setMinute,
  setAmpm,
  setMinuteSwiping,
  setHourSwiping,
  setAmpmSwiping,
  setMinuteFlipping,
  setHourFlipping,
  setMinuteBarTop,
  setHourBarTop,
  hourlyRange,
}: Props) {
  const yearBoundsInput = getYearBounds(new Date(), undefined, 'time-range')

  const hourHandlers = useSwipeable({
    trackMouse: true,
    onSwiped: (e) => {
      setHourSwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right') {
        return
      }
      const swipeData = calcSwipeAssets({
        e,
        type: 'hour',
        increment: hour,
        variant,
        hourlyRange,
        yearBoundsInput,
      })
      const { sweep, unitHeight } = swipeData
      setSwipeAction({ e, swipeData, setIncrement: setHour, variant, hourlyRange, yearBoundsInput })
      const flipEffect = flipColumn({
        values: hourBarTop,
        swipeData,
        variant,
        hourlyRange,
        yearBoundsInput,
      })
      setHourFlipping(flipEffect)
      setHourBarTop(
        hourBarTop.map(
          (_el, i) => hourBarTop[i] - sweep * unitHeight + flipEffect[i],
        ) as SwipingTimePickerBars,
      )
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setHourSwiping(1.5)
      }
      if (e.dir === 'Up') {
        return setHourSwiping(-1.5)
      }
    },
  })

  const minuteHandlers = useSwipeable({
    trackMouse: true,
    onSwiped: (e) => {
      setMinuteSwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right') {
        return
      }
      const swipeData = calcSwipeAssets({
        e,
        type: 'min',
        increment: minute,
        variant,
        hourlyRange,
        yearBoundsInput,
      })
      const { sweep, unitHeight } = swipeData
      setSwipeAction({
        e,
        swipeData,
        setIncrement: setMinute,
        variant,
        hourlyRange,
        yearBoundsInput,
      })
      const flipEffect = flipColumn({
        values: minuteBarTop,
        swipeData,
        variant,
        hourlyRange,
        yearBoundsInput,
      })
      setMinuteFlipping(flipEffect)
      setMinuteBarTop(
        minuteBarTop.map(
          (_el, i) => minuteBarTop[i] - sweep * unitHeight + flipEffect[i],
        ) as SwipingTimePickerBars,
      )
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setMinuteSwiping(1.5)
      }
      if (e.dir === 'Up') {
        return setMinuteSwiping(-1.5)
      }
    },
  })

  const ampmHandlers = useSwipeable({
    trackMouse: true,
    onSwiped: (e) => {
      setAmpmSwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right' || e.velocity < SWIPE_THRESHOLD) {
        return
      }
      if (e.dir === 'Down') {
        setAmpm(0)
      }
      if (e.dir === 'Up') {
        setAmpm(1)
      }
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setAmpmSwiping(2)
      }
      if (e.dir === 'Up') {
        return setAmpmSwiping(-2)
      }
    },
  })

  return { hourHandlers, minuteHandlers, ampmHandlers }
}
