// https://deem.atlassian.net/wiki/spaces/JA/pages/1740374021/URL+definitions+to+call+the+iOS+actions+from+the+PWA

const MobilityIOSScheme = 'mobility'

export enum MobilityIOSAction {
  Close = 'closeWebView',
  RefreshItinerary = 'refreshItinerary',
  OpenBookTab = 'openBookTab',
}

export enum MobilityPWAEvent {
  BookATripButtonTapped = 'onBookATripButtonTapped',
  BookingDoneTapped = 'onBookingDoneTapped',
}

export function generateMobilityURLForIOS(
  iOSAction: MobilityIOSAction,
  pwaEvent?: MobilityPWAEvent,
): string {
  let result = MobilityIOSScheme + '://' + iOSAction
  if (pwaEvent) {
    result = result + '?event=' + pwaEvent
  }
  return result
}
