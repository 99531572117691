import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { TripTitle } from './trip-item-title-styled'

type Props = {
  departureCode: string
  destinationCode: string
  isRoundTrip?: boolean
}

export function TripItemTitle({ departureCode, destinationCode, isRoundTrip }: Props) {
  return (
    <TripTitle>
      <Text variant="subHeadStrong">{departureCode}</Text>
      {isRoundTrip ? (
        <Icon name="flightArrowsPWA" size="normal" />
      ) : (
        <Icon name="flightArrowPWA" size="normal" />
      )}
      <Text variant="subHeadStrong">{destinationCode}</Text>
    </TripTitle>
  )
}
