import { Store } from '@etta/di'

@Store()
export class ConfigurationStore {
  private _advanceBookingInDays = 90

  get advanceBookingInDays() {
    return this._advanceBookingInDays
  }
}
