import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { SpaceBetween, Text } from '../rtp-price-summary-styled'
import { TripHeaderContainer, EditTitleButton, DatesContainer } from './trip-header-styled'

type Props = {
  tripName: string
  tripDates: string
  isEditable: boolean
  isDatesWarning: boolean
  onEdit: () => void
}

export function TripHeader({ tripName, tripDates, isEditable, onEdit, isDatesWarning }: Props) {
  const { t } = useTranslation()

  return (
    <TripHeaderContainer>
      <SpaceBetween>
        <Text as={'h2'} aria-label={tripName} variant="title3" hasBreakWord>
          {tripName}
        </Text>
        {isEditable && (
          <EditTitleButton
            onClick={onEdit}
            icon="editPWA"
            size="normal"
            aria-label={t('Accessibility.EditTripName')}
          />
        )}
      </SpaceBetween>

      <DatesContainer>
        {isDatesWarning && <Icon size={'small'} color="error" name="warningFilledPWA" />}
        <Text
          aria-label={tripDates}
          variant="footnoteStrong"
          color={isDatesWarning ? 'error' : 'bodyText'}>
          {tripDates}
        </Text>
      </DatesContainer>
    </TripHeaderContainer>
  )
}
