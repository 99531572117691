import styled from 'styled-components'
import { ellipsis } from 'polished'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.li`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
  line-height: 1.2;
`

export const ListItemLabel = styled.span`
  ${ellipsis(191, 1)};
`

export const FlightName = styled.div`
  display: flex;
  align-items: center;
`

export const Unit = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
`
