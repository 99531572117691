import type { RouteItem } from '@fiji/routes'
import { Permissions } from '@etta/screens/permissions'
import { ROUTES } from '../../routes'
import { getDocumentTitle } from '../../get-document-title'

export const onboardingRoutes: RouteItem[] = [
  {
    route: {
      path: ROUTES.onboarding,
      exact: true,
    },
    documentTitle: getDocumentTitle('Onboarding'),
    isNavBarHidden: true,
    transitionIndex: 1,
    transitionGroup: 'slide',
    component: () => <Permissions />,
  },
]
