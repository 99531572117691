import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import type { LayoutProps } from '../../types'
import { mapEmissionsEquivalenceToGqlType } from '../../../../next/modules/rail/infra/mappers/utils'
import {
  CarouselContainer,
  RailDetailsEcoCheckModalImage,
} from './rail-details-eco-check-modal-desktop-styled'
import {
  RailDetailsEcoCheckHeader,
  RailDetailsEcoCheckModalDesktopLowerBody,
  RailDetailsEcoCheckModalDesktopUpperBody,
} from './components'
import EcoCheckRailIllustration from './assets/eco-check-rail-illustration.svg?url'

export function RailDetailsEcoCheckModalDesktop({
  isVisible,
  onClose,
  kgOfActualEmission,
  equivalences,
}: LayoutProps) {
  const mappedEmissionsEquivalences = equivalences.map(mapEmissionsEquivalenceToGqlType)

  return (
    <Modal
      position="right"
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<RailDetailsEcoCheckHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <RailDetailsEcoCheckModalDesktopUpperBody kgOfActualEmission={kgOfActualEmission} />
        <RailDetailsEcoCheckModalImage src={EcoCheckRailIllustration} />
        <CarouselContainer>
          <EcoCheckEquivalences
            equivalences={mappedEmissionsEquivalences}
            kgOfEmissions={kgOfActualEmission}
          />
        </CarouselContainer>
        <RailDetailsEcoCheckModalDesktopLowerBody />
      </Modal.Body>
    </Modal>
  )
}
