import { LONDON_UNDERGROUND_TUBE } from '@fiji/constants'
import { RailTransportationMode } from '@fiji/graphql/types'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'

export function getRailLabelsRtp(segments: TrainSegmentEntity['segments']): string[] {
  return segments.map((segment) => {
    if (
      segment.transportationMode &&
      (segment.transportationMode === RailTransportationMode.Unspecified ||
        segment.transportationMode === RailTransportationMode.Metro)
    ) {
      return LONDON_UNDERGROUND_TUBE
    }

    if (segment.transportationMode && segment.transportationMode !== RailTransportationMode.Train) {
      return (
        segment.transportationMode.toString().slice(0, 1) +
        segment.transportationMode.slice(1).toLowerCase()
      )
    }

    return `${segment.carrierName} ${segment.trainId}`
  })
}
