import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { DelegateModalMobile, DelegateModalDesktop } from './layout'
import type { LayoutProps } from './types'

export function DelegateModalLayout(props: LayoutProps) {
  const {
    featureFlags: { isDesktopDelegatedFlowEnabled },
  } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<DelegateModalMobile {...props} />}
      desktopSlot={<DelegateModalDesktop {...props} />}
      forceScreenType={isDesktopDelegatedFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
