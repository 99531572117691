import { useMemo } from 'react'
import type { ReactNode } from 'react'
import { Link } from '@etta/ui/link'
import type { MobileLinkSize } from '@etta/ui/link/types'
import { LinksContainer, Separator } from './desktop-footer-cobranding-styles'

type Props = {
  url?: string
  dataTrackingId?: string
  label?: string
  ariaLabel?: string
  showSeparatorBefore?: boolean
  showSeparatorEnd?: boolean
  size?: MobileLinkSize
  children?: ReactNode
  target?: string
  isDisabled?: boolean
  onClick?: () => void
}

const LinkWithSeparator: React.FC<Props> = ({
  url = '#',
  dataTrackingId,
  label,
  ariaLabel,
  showSeparatorBefore = true,
  showSeparatorEnd = true,
  size = 'normal',
  children,
  target,
  isDisabled = false,
  onClick,
}) => {
  const linkContent = useMemo(() => label || children, [label, children])

  return (
    <LinksContainer>
      {showSeparatorBefore && <Separator role="separator" />}
      <Link
        href={isDisabled ? undefined : url}
        target={target}
        size={size}
        aria-label={ariaLabel || label}
        data-tracking-id={dataTrackingId}
        isDisabled={isDisabled}
        onClick={onClick}>
        {linkContent}
      </Link>
      {showSeparatorEnd && <Separator role="separator" />}
    </LinksContainer>
  )
}

export default LinkWithSeparator
