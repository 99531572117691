import { useEffect, useState, useCallback, useMemo } from 'react'
import type { MultiLayerCostAllocationUnit } from '@fiji/graphql/types'

import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'
import type { Toggle } from '@etta/interface/services/toggle'
import { useCostAllocationContext } from '@etta/modules/cost-allocation/interface/use-cost-allocation.context'
import type { CostAllocationEntity } from '@etta/modules/cost-allocation/core/entities/cost-allocation.entity'
import type { MultiLayerCostAllocationProps } from './types'

type Props = {
  segmentId: number
  label?: string
  modal?: Toggle
  onSubmit?: (val: CostAllocationSubmitValueObject, displayName: string) => void
}

const PAGE_SIZE = 8000
export function useMultiLayerCostAllocationContent({
  segmentId,
  modal,
  onSubmit,
}: Props): MultiLayerCostAllocationProps {
  const [layer, setLayer] = useState<number>(0)
  const [parentPath, setParentPath] = useState<string[]>([])
  const [filteredData, setFilteredData] = useState<MultiLayerCostAllocationUnit[] | null>(null)
  const [activeLayerUrl, setActiveLayerUrl] = useState<string | undefined>(undefined)
  const [layerHistory, setLayerHistory] = useState<(string | undefined)[]>([undefined])
  const [searchLabel, setSearchLabel] = useState<string>('')
  const [currentAllocationData, setCurrentAllocationData] = useState<CostAllocationEntity[]>([])
  const [
    selectedCostAllocation,
    setSelectedCostAllocation,
  ] = useState<MultiLayerCostAllocationUnit | null>(null)

  const { costAllocationStore, costAllocationActions } = useCostAllocationContext()

  const isFilterOverSearch = useMemo(() => (currentAllocationData.length || 0) < PAGE_SIZE, [
    currentAllocationData,
  ])

  const costAllocationQuery = useMemo(
    () => (isFilterOverSearch || searchLabel.length < 2 ? undefined : searchLabel),
    [isFilterOverSearch, searchLabel],
  )

  const getAllocationData = useCallback(
    async (url: string | undefined, isFilter?: boolean) => {
      await costAllocationActions.getCostAllocations(segmentId, costAllocationQuery, url, PAGE_SIZE)

      const allocationId = `${segmentId}_${costAllocationQuery}_${url}`
      const allocationData = costAllocationStore.getCostAllocationById(allocationId)
      if (!allocationData) {
        return
      }

      if (isFilter) {
        setFilteredData(allocationData.costAllocation || null)
      }
      setCurrentAllocationData(allocationData.costAllocation)
    },
    [segmentId, costAllocationActions, costAllocationQuery, costAllocationStore],
  )

  const resetData = useCallback(() => {
    setSearchLabel('')
    setLayer(0)
    setSelectedCostAllocation(null)
    setActiveLayerUrl(undefined)
    setLayerHistory([undefined])
    setFilteredData(null)
    setParentPath([])
    getAllocationData(undefined)
  }, [getAllocationData])

  const handleClearSearch = useCallback(() => {
    setSearchLabel('')
    setFilteredData(null)
  }, [])

  const handleModalClose = useCallback(() => {
    modal?.handleClose()
    resetData()
  }, [modal, resetData])

  const handleModalOpen = useCallback(() => {
    modal?.handleOpen()
  }, [modal])

  const handleCancel = useCallback(() => {
    resetData()
  }, [resetData])

  const handleBack = useCallback(() => {
    if (!searchLabel.length && !layer) {
      handleModalClose()
      return
    }
    if (searchLabel.length) {
      handleClearSearch()
      return
    }
    setActiveLayerUrl(layerHistory[layerHistory.length - 1])
    setParentPath((prev) => prev.slice(0, prev.length - 1))
    setLayer((prev: number) => prev - 1)
    getAllocationData(layerHistory[layerHistory.length - 1])
    setLayerHistory((prev) => prev.slice(0, prev.length - 1))
  }, [layer, searchLabel, layerHistory, getAllocationData, handleClearSearch, handleModalClose])

  const handleLayerSearch = useCallback(
    (value: string) => {
      setSearchLabel(value)
      if (isFilterOverSearch) {
        return setFilteredData(
          currentAllocationData.filter((allocation) =>
            allocation.name.toLowerCase().includes(value.toLowerCase()),
          ) || null,
        )
      }
      getAllocationData(activeLayerUrl, true)
    },
    [isFilterOverSearch, getAllocationData, activeLayerUrl, currentAllocationData],
  )

  const getFullName = (name: string | null | undefined, parentPath: string[]) => {
    const ancestorNames = parentPath.map((parentName) => parentName.trim())

    const fullName = [...ancestorNames, name?.trim()]
    return fullName.filter((name) => name && name.length > 0).join(' : ')
  }

  const handleSubmit = useCallback(() => {
    if (selectedCostAllocation) {
      const { allocationId, name, segmentId } = selectedCostAllocation
      const costAllocation = {
        allocationId: String(allocationId),
        name,
        segmentId: String(segmentId),
      }
      const fullName = getFullName(costAllocation.name, parentPath)
      onSubmit?.(costAllocation, fullName)
      handleModalClose()
      resetData()
    }
  }, [handleModalClose, onSubmit, parentPath, selectedCostAllocation, resetData])

  const handleAllocationSelect = useCallback(
    (unit: MultiLayerCostAllocationUnit) => {
      const { childrenRef, stats, name } = unit
      if (!stats?.activeChildren || !childrenRef) {
        setSelectedCostAllocation(unit)
        return
      }
      if (layer) {
        setLayerHistory((prev) => [...prev, activeLayerUrl])
      }
      setParentPath((prev) => [...prev, name])
      setSearchLabel('')
      setActiveLayerUrl(childrenRef)
      setLayer((prev: number) => prev + 1)
      setFilteredData(null)
      getAllocationData(childrenRef)
    },
    [activeLayerUrl, layer, getAllocationData],
  )

  const handleRefetch = useCallback(() => {
    resetData()
  }, [resetData])

  useEffect(() => {
    getAllocationData(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isCostSegmentsLoading: costAllocationStore.isTopLevelCostSegmentLoading,
    isError: !!(
      costAllocationStore.isCostAllocationError || costAllocationStore.isTopLevelCostSegmentError
    ),
    isLoading:
      costAllocationStore.isCostAllocationLoading ||
      costAllocationStore.isTopLevelCostSegmentLoading,
    costAllocationData: filteredData || currentAllocationData,
    selectedCostAllocation,
    searchLabel,
    parentPath,
    handleSubmit,
    handleModalClose,
    handleModalOpen,
    handleBack,
    handleAllocationSelect,
    handleLayerSearch,
    handleCancel,
    handleRefetch,
    handleClearSearch,
  }
}
