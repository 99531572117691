import { useState } from 'react'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import { ROUTES } from '@fiji/routes'
import type { CarRentalMapProps, Tile } from '../../types'

export function useCarRentalMapDesktopLayout({
  locations = [],
  center,
  selectedPickUpLocation,
  selectedDropOffLocation,
}: Omit<CarRentalMapProps, 'onLocationClick'>) {
  const { lat, long: lng } = center
  const [selectedTile, selectTile] = useState<Tile>('roadmap')
  const isConfirmationPage = useRouteMatch(ROUTES.carRental.confirmation)

  // https://stackoverflow.com/a/14399178
  const convertTime = (time: string) => {
    if (!time) {
      return ''
    }

    const hours = +time.slice(0, 2)
    const correctHours = ((hours + 11) % 12) + 1
    const suffix = hours >= 12 ? 'PM' : 'AM'

    return `${correctHours}${time.slice(2).replace(/:[^:]+$/, '')} ${suffix}`
  }

  let filteredLocations: CarRentalMapProps['locations'] = [...locations]

  if (isConfirmationPage && selectedPickUpLocation && selectedDropOffLocation) {
    filteredLocations = locations.filter(({ locationId }) =>
      [selectedPickUpLocation.locationId, selectedDropOffLocation.locationId].includes(locationId),
    )
  }

  const markerData = filteredLocations.map((location) => {
    const { isOpen, address, hours, airportLocation, vendors } = location

    return {
      lat: address.geocode?.lat,
      lng: address.geocode?.long,
      isOpen,
      isPickUp: location.locationId === selectedPickUpLocation?.locationId,
      isDropOff: location.locationId === selectedDropOffLocation?.locationId,
      street: address.street1 ?? '',
      fullAddress: `${address.street1}, ${address.city}, ${address.postalCode}`,
      workingHours: `${convertTime(hours[0]?.hours[0]?.start)} - ${convertTime(
        hours[0]?.hours[0]?.end,
      )}`,
      vendorLogos: vendors.map(({ imageUrl }) => imageUrl || '').filter(Boolean),
      isAirportLocation: airportLocation,
      location,
    }
  })

  const handleTileChange = (tile: Tile) => {
    selectTile(tile)
  }

  return {
    defaultCenter: { lat, lng },
    handleTileChange,
    markerData,
    selectedTile,
    isInteractive: !isConfirmationPage,
  }
}
