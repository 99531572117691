import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { PasswordMapper } from '@etta/modules/user/infra/mappers/password.mapper'
import type { PromisedResult } from '@etta/core/type-utils'
import type { UserErrorsInstances } from '@etta/modules/user/core/errors'
import { UserErrors } from '@etta/modules/user/core/errors'
import { SettingsErrors } from '@etta/modules/settings/core/errors/settings.errors'
import type {
  AddCreditCardMutationVariables,
  ChangePasswordMutationVariables,
} from '@fiji/graphql/hooks'
import type { SiteEntity } from '@etta/modules/auth/core/entities/site.entity'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass } from '@fiji/graphql/types'
import type { UserEntity, PasswordSecurityConfigureEntity } from '../core/entities'
import { UserDataProvider } from './user.data-provider'
import type { UserAdapterResult, UpdateUserArgs, SetUserPasswordArgs } from './types'
import { UserMapper } from './mappers'

@Adapter()
export class UserAdapter {
  constructor(
    @Inject()
    private userDataProvider: UserDataProvider,
  ) {}

  async addPaymentCard(
    input: AddCreditCardMutationVariables['input'],
  ): UserAdapterResult<{ legacyId?: string | null }> {
    try {
      const { data, errors } = await this.userDataProvider.addPaymentCard(input)
      const typename = data?.addPaymentCard.__typename

      if (typename === 'PaymentCard' && data?.addPaymentCard.cardId) {
        return Result.Ok({ legacyId: data.addPaymentCard.legacyId })
      }
      return Result.Err(new UserErrors.AddPaymentCardUnexpectedError(errors))
    } catch (e) {
      return Result.Err(new UserErrors.AddPaymentCardUnexpectedError(e))
    }
  }

  async setUserPassword({ input }: SetUserPasswordArgs): UserAdapterResult<{ success: boolean }> {
    try {
      const { data, errors } = await this.userDataProvider.setUserPassword(input)

      if (errors) {
        return Result.Err(new UserErrors.SavePasswordUnexpectedError(errors))
      }

      if (data?.setPassword.__typename === 'BaseResponse' && !data?.setPassword.success) {
        switch (data.setPassword.code) {
          case 'BAD_CREDENTIALS':
            return Result.Err(new SettingsErrors.ChangePasswordBadCredentials(''))
          case 'PASSWORD_MISMATCH':
            return Result.Err(new SettingsErrors.ChangePasswordMismatch(''))
          case 'WEAK_PASSWORD':
            return Result.Err(new SettingsErrors.ChangePasswordWeak(''))
          default:
            return Result.Err(new SettingsErrors.ChangePasswordResponseError(errors))
        }
      }

      if (data?.setPassword.__typename === 'UnexpectedError') {
        return Result.Err(new UserErrors.SavePasswordUnexpectedError(data.setPassword.message))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserErrors.SavePasswordUnexpectedError(e))
    }
  }

  async changePassword(
    input: ChangePasswordMutationVariables['input'],
  ): PromisedResult<{ success: boolean }, UserErrorsInstances> {
    try {
      const { data, errors } = await this.userDataProvider.changePassword(input)

      if (errors) {
        return Result.Err(new UserErrors.ChangePasswordUnexpectedError(errors))
      }

      if (data?.changePassword.__typename === 'UnexpectedError') {
        return Result.Err(new UserErrors.ChangePasswordUnexpectedError(data.changePassword.message))
      }

      if (data?.changePassword.__typename === 'BaseResponse' && !data?.changePassword.success) {
        if (data.changePassword.code === 'PASSWORD_MISMATCH') {
          return Result.Err(
            new UserErrors.ChangePasswordBusinessError({
              errorCodes: [PasswordMapper.toChangePasswordErrorCode('PASSWORD_MISMATCH')],
            }),
          )
        }
        if (!data?.changePassword.errorCodes) {
          return Result.Err(new UserErrors.ChangePasswordUnexpectedError(data.changePassword.code))
        }
        return Result.Err(
          new UserErrors.ChangePasswordBusinessError({
            errorCodes: data?.changePassword.errorCodes.map(
              PasswordMapper.toChangePasswordErrorCode,
            ),
          }),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserErrors.ChangePasswordUnexpectedError(e))
    }
  }

  async getSitesByEmail(email: string): PromisedResult<SiteEntity[], UserErrorsInstances> {
    try {
      const deviceClass =
        screenMatcher.getScreenType() === ScreenType.Desktop
          ? DeviceClass.Desktop
          : DeviceClass.Mobile

      const { data, error } = await this.userDataProvider.getSitesByEmail({
        email,
        deviceClass,
      })
      if (error) {
        return Result.Err(new UserErrors.GetSitesUnexpectedError(error))
      }
      const sites = data.sitesByUserEmail.map(UserMapper.toSiteEntity)
      return Result.Ok(sites)
    } catch (e) {
      return Result.Err(new UserErrors.GetSitesUnexpectedError(e))
    }
  }

  async getUserPasswordSecurityConfigure(): PromisedResult<
    PasswordSecurityConfigureEntity,
    UserErrorsInstances
  > {
    try {
      const { data, error } = await this.userDataProvider.getUserPasswordSecurityConfigure()

      if (error) {
        return Result.Err(new UserErrors.GetPasswordSecurityConfigureUnexpectedError(error))
      }

      return Result.Ok(
        PasswordMapper.toPasswordSecurityConfigurationEntity(data.passwordSecurityConfigure),
      )
    } catch (e) {
      return Result.Err(new UserErrors.GetPasswordSecurityConfigureUnexpectedError(e))
    }
  }

  async getUser(): UserAdapterResult<UserEntity> {
    try {
      const { data, error } = await this.userDataProvider.getUser()

      if (error) {
        return Result.Err(new UserErrors.GetUserErrorResponse(error))
      }

      return Result.Ok(UserMapper.toUserEntity(data.user))
    } catch (e) {
      return Result.Err(new UserErrors.GetUserUnexpectedError(e))
    }
  }

  async updateUser({ input }: UpdateUserArgs): UserAdapterResult<{ success: boolean }> {
    try {
      const { data, errors } = await this.userDataProvider.updateUser(input)

      if (errors) {
        return Result.Err(new UserErrors.UpdateUserErrorResponse(errors))
      }

      if (!data?.user.success) {
        return Result.Err(new UserErrors.UpdateUserErrorResponse(data?.user.message))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserErrors.UpdateUserUnexpectedError(e))
    }
  }
}
