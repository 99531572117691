import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { FlightCarrierData } from '@fiji/types'
import { getFlightSafetyChecks } from '@fiji/utils/flight-safety-check/get-flight-safety-checks'
import { getFlightSafetyCheckDetails } from '@fiji/utils/flight-safety-check/get-flight-safety-check-details'
import type { SearchFlightLeg } from '@fiji/graphql/types'
import type { FareAttributesValueObject } from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'
import type { AdaptiveFlight } from '../types'
import { getGeneralAmenities } from './getters/get-general-amenities'
import { getHeaderTitle } from './getters/get-header-title'
import { getAirlinesLogos } from './getters/get-airlines-logos'
import { getFlightLabels } from './getters/get-flight-labels'
import { getAdaptedSegments } from './getters/get-adapted-segments'
import { getFlightStops } from './getters/get-flight-stops'
import { getTotalFlightDuration } from './getters/get-total-flight-duration'
import { getPercentageOnTimeLabels } from './getters/get-percentage-on-time-labels'
import { getFares } from './getters/get-fares'
import { getCustomPreferredText } from './getters/get-custom-preferred-text'

export function useSearchAdaptiveFlight(
  flightLeg: SearchFlightLeg,
  fareAttributes?: FareAttributesValueObject[],
): { flight: AdaptiveFlight } {
  const { t } = useTranslation()

  const stops = flightLeg.stops
    ? t('SearchSegments.Stops.Stop', { count: flightLeg.stops })
    : t('SearchSegments.Stops.Nonstop')

  const flightStopsLabel = flightLeg.hasTechnicalStop
    ? t('SearchSegments.Stops.TechnicalStop')
    : stops

  const flight = useMemo(() => {
    const { connectionInfo, segments, fares, legId } = flightLeg
    const squareAirlineLogos = getAirlinesLogos(segments)
    const adaptedSegments = getAdaptedSegments(segments)
    const isPreferred = adaptedSegments.every((segment) => segment.isPreferredAny)
    const carrierNames: FlightCarrierData[] = segments.map((segment) => {
      return {
        carrierName: segment.carrier?.name || '',
        carrierLogo: segment.carrier?.logoRectangleImageUrl || '',
        flightNumber: segment.flightNumber,
      }
    })
    const flightSafetyChecks =
      carrierNames && fareAttributes && !!fareAttributes.length
        ? getFlightSafetyChecks(carrierNames, fareAttributes)
        : []
    const flightSafetyCheckDetails =
      fareAttributes && !!fareAttributes.length ? getFlightSafetyCheckDetails(fareAttributes) : []

    const operatedBy = adaptedSegments
      .map((value) => value.operatedBy)
      .filter(Boolean)
      .join(', ')

    return {
      id: legId,
      headerTitle: getHeaderTitle(segments),
      airlineLogos: {
        square: squareAirlineLogos,
        rectangle: [],
      },
      flightLabels: getFlightLabels(segments),
      adaptedSegments,
      flightStops: getFlightStops(connectionInfo, segments),
      totalFlightDuration: getTotalFlightDuration(flightLeg?.totalTime),
      flightStopsLabel,
      percentageOnTimeLabels: getPercentageOnTimeLabels(segments),
      fares: getFares(fares, fareAttributes),
      generalAmenities: getGeneralAmenities(segments),
      isPreferred,
      customPreferredText: getCustomPreferredText(segments),
      flightSafetyChecks,
      flightSafetyCheckDetails,
      operatedBy,
      hasTechnicalStop: flightLeg.hasTechnicalStop,
      policy: flightLeg.policy,
    } as AdaptiveFlight
  }, [fareAttributes, flightLeg, flightStopsLabel])

  return {
    flight,
  }
}
