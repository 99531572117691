import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Radio as MobileRadio } from '@etta/ui/radio'

export const Container = styled.div`
  padding: 0 32px;
  display: flex;
  flex: 1;
  background: ${(props) => props.theme.colors.background};
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 32px;
`

export const HeaderText = styled(Text).attrs(() => ({
  variant: 'subHeadMedium',
  color: 'mainText',
}))`
  margin: 24px 0px 16px 0;
`

export const BaggageListContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const Radio = styled(MobileRadio)`
  padding: 18px 16px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const RadioContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled(Text).attrs(() => ({
  variant: 'subHeadMedium',
}))`
  flex: 1 1 70%;
`

export const Price = styled(Text).attrs(() => ({
  variant: 'subHeadStrong',
  color: 'bodyText',
  noWrap: true,
}))`
  text-align: right;
  flex: 1 0 auto;
  margin-left: 8px;
`

export const Footer = styled(Block).attrs(() => ({
  isFlexBlock: true,
  isFullWidth: true,
  marginTop: 16,
  marginBottom: 16,
}))`
  padding: 0 32px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
`
