import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { SegmentType } from '@etta/core/enums'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { useAppDispatch } from '@fiji/store'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { FlightDirection, PostBookingAction, TimeRangeDirection } from '@fiji/enums'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import type { Place } from '@fiji/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { AirSearchQueryType } from '../search-queries/use-air-search-query/types'
import { FlightType } from '../search-queries/use-air-search-query/types'
import { getQueryParamsPostBooking } from './get-query-params'

type Args = {
  itineraryId: string
  bookingId?: string
  segments: SegmentPostBookingValueObject[]
}

export function useFlightPlacePostBooking({ itineraryId, bookingId, segments }: Args) {
  const { navigateTo, appendQueryParams } = useAirSearchQuery()
  const dispatch = useAppDispatch()
  const { postBookingAddSegmentAction } = usePostBookingContext()
  const { cabinClassesOptions } = useSeatmapCabinClasss()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()
  const leg0Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Origin)
  const leg1Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Return)
  const { homeAirportStore } = useAirSearchFormContext()
  const isDesktop = useScreenType() !== ScreenType.Mobile

  const onAddFlight = () => {
    const searchResultUrl = '/explore/air-results/leg/0'
    const airFormUrl = '/explore/air-search'
    const params = getQueryParamsPostBooking({
      segments,
      forType: SegmentType.Flight,
    })
    const isSameLocation = homeAirportStore.homeAirport?.airportCode === params?.to.airportCode

    const airSearchParams: Omit<
      AirSearchQueryType,
      'selectedFlights' | 'searchId' | 'areNearbyAirportsIncluded' | 'activeLegId'
    > = {
      itineraryId,
      bookingId,
      flightType: FlightType.Round,
      postBookingAction: PostBookingAction.Add,
      flights: [
        {
          id: -1,
          originPlace: undefined,
          destinationPlace: undefined,
          departureDate: params?.startDate,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg0Time,
          returnDate: params?.endDate,
          returnTimeRange: leg1Time,
        },
        {
          id: -2,
          originPlace: undefined,
          destinationPlace: undefined,
          departureDate: params?.endDate,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg1Time,
          returnTimeRange: leg0Time,
        },
      ],
      selectedTab: SegmentType.Flight,
    }

    if (!homeAirportStore.homeAirport) {
      airSearchParams.flights = [
        {
          ...airSearchParams.flights[0],
          originPlace: undefined,
          destinationPlace: params?.to,
        },
        {
          ...airSearchParams.flights[1],
          originPlace: params?.to,
          destinationPlace: undefined,
        },
      ]
    } else if (homeAirportStore.homeAirport) {
      airSearchParams.flights = [
        {
          ...airSearchParams.flights[0],
          originPlace: homeAirportStore.homeAirport as Place,
          destinationPlace: isSameLocation ? undefined : params?.to,
        },
        {
          ...airSearchParams.flights[1],
          originPlace: isSameLocation ? undefined : params?.to,
          destinationPlace: homeAirportStore.homeAirport as Place,
        },
      ]
    }

    const isMissingRequiredSearchParams = Boolean(
      airSearchParams.flights?.some((flight) => {
        if (flight.originPlace && flight.destinationPlace) {
          return false
        }
        return true
      }),
    )

    if (isDesktop && isMissingRequiredSearchParams) {
      appendQueryParams(airSearchParams)
      postBookingAddSegmentAction.handleAddFlight()
      return
    }

    dispatch(updateTripReviewStatus('update'))
    dispatch(updatePostPurchaseEntrance('fromTripDetails'))

    navigateTo(isSameLocation ? airFormUrl : searchResultUrl, airSearchParams)
  }

  return {
    onAddFlight,
  }
}
