import { Action, Inject } from '@etta/di'
import { GetCustomerUberAccountStatusService } from '@etta/modules/ride-hail/interface/services/get-customer-uber-account-status.service'

@Action()
export class GetCustomerUberAccountStatusActions {
  constructor(
    @Inject()
    private readonly getCustomerUberAccountStatusService: GetCustomerUberAccountStatusService,
  ) {}

  getCustomerUberAccountStatus() {
    this.getCustomerUberAccountStatusService.getCustomerUberAccountStatus()
  }
}
