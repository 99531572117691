import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { HotelSearchFormMobile } from './mobile'
import { HotelSearchFormDesktop } from './desktop'

export function HotelSearchFormLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HotelSearchFormMobile {...props} />}
      desktopSlot={<HotelSearchFormDesktop {...props} />}
    />
  )
}
