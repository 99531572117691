import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Vendor } from '@fiji/graphql/types'
import { CarRentalVendorLocations } from '../../../../car-rental-vendor-locations'
import {
  PlaceContainer,
  TypeIndicator,
  Description,
  ClosedLabel,
  Name,
  Distance,
  LogoContainer,
} from './mobile-place-styled'

type Props = {
  formattedLocation: string
  vendors: Vendor[]
  isAirport: boolean
  isOpen: boolean
  distance?: string | null
  onClick: () => void
  'aria-label'?: string
}

export function Place({ formattedLocation, vendors, isAirport, isOpen, distance, onClick }: Props) {
  const { t } = useTranslation()
  const ariaLabel = `${formattedLocation} ${t('Accessibility.At')} ${distance ? distance : ''}. ${t(
    'Accessibility.TapToSelect',
  )}`

  return (
    <PlaceContainer
      role="button"
      isOpen={isOpen}
      onClick={isOpen ? onClick : undefined}
      aria-label={ariaLabel}
      aria-disabled={!isOpen}
      data-tracking-id="car-rental-place"
      data-testvalue={formattedLocation}>
      <TypeIndicator>
        <Icon size="medium" name={isAirport ? 'flightPWA' : 'searchPinPWA'} color="primary" />
      </TypeIndicator>
      <Description>
        {!isOpen && <ClosedLabel>Closed</ClosedLabel>}
        <Name>
          {formattedLocation}
          {distance && <Distance>({distance})</Distance>}
        </Name>
        <LogoContainer>
          <CarRentalVendorLocations vendors={vendors} />
        </LogoContainer>
      </Description>
    </PlaceContainer>
  )
}
