import { useHistory } from 'react-router'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import { ROUTES } from '@fiji/routes'
import { useAppDispatch } from '@fiji/store'
import { rollbar } from '@fiji/rollbar'
import { updateCarRentalId } from '@fiji/store/segment-ids'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
// TODO: should import only from module level
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useReactivatedItinerary } from '@fiji/hooks/use-reactivated-itinerary'

type HandleProcessArgs = {
  handler: () => Promise<void>
  itineraryId: string
  bookingId?: string
}

type HandleAddCarArgs = {
  itineraryId: string
  carRentalKey: string
  bookingId?: string
}

type HandleReplaceCarArgs = {
  carRentalKey: string
  itineraryId: string
  oldCarRentalKey: string
  bookingId?: string
}

type HandleSubmitCarRentalArgs = {
  carRentalKey?: string
  itineraryId?: string
  oldCarRentalKey?: string | null
  bookingId?: string
}

type Args = {
  onUpdateCarRentalStatus: (value: TripStateStatus) => void
}

export function useSelectCarRental({ onUpdateCarRentalStatus }: Args) {
  const { tripActions, tripCarRentalActions, reviewTripStore } = useReviewTripContext()

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { reactivateItinerary } = useReactivatedItinerary()

  const handleProcess = async ({ handler, itineraryId, bookingId }: HandleProcessArgs) => {
    onUpdateCarRentalStatus('loading')

    try {
      await handler()
      if (!reviewTripStore.tripId) {
        tripActions.setTripId(itineraryId)
      }
      await tripActions.updateTrip()
      onUpdateCarRentalStatus('success')
      await delayForSuccess(500)
      dispatch(updateCarRentalId({ carRentalId: null }))
      history.push(ROUTES.reviewTrip.main({ itineraryId, bookingId }))
    } catch (e) {
      onUpdateCarRentalStatus('error')

      if (e instanceof Error) {
        rollbar.error(e)
      } else {
        rollbar.error(new Error('Unknown error'))
      }
    }
  }

  async function handleAddCar({ itineraryId, bookingId, carRentalKey }: HandleAddCarArgs) {
    await handleProcess({
      itineraryId,
      bookingId,
      handler: async () => {
        await reactivateItinerary({ action: 'add car rental', itineraryId })

        const addCarResponse = await tripCarRentalActions.handleAddCarRental({
          carRentalKey,
          itineraryId,
        })

        if (addCarResponse.isErr()) {
          throw new Error('add car rental error')
        }
      },
    })
  }

  async function handleReplaceCarRental({
    itineraryId,
    bookingId,
    oldCarRentalKey,
    carRentalKey,
  }: HandleReplaceCarArgs) {
    await handleProcess({
      itineraryId,
      bookingId,
      handler: async () => {
        await reactivateItinerary({ action: 'replace car rental', itineraryId })

        const replaceCarResponse = await tripCarRentalActions.handleReplaceCarRental({
          carRentalKey,
          oldCarRentalKey,
        })
        if (replaceCarResponse.isErr()) {
          throw new Error(replaceCarResponse.getError().message)
        }
      },
    })
  }

  function handleSubmitCarRental({
    itineraryId,
    bookingId,
    oldCarRentalKey,
    carRentalKey,
  }: HandleSubmitCarRentalArgs) {
    if (!itineraryId || !carRentalKey) {
      return
    }
    if (oldCarRentalKey) {
      handleReplaceCarRental({ itineraryId, bookingId, oldCarRentalKey, carRentalKey })
      return
    }
    handleAddCar({ carRentalKey, itineraryId, bookingId })
  }

  return { handleSubmitCarRental }
}
