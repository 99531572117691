import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DropDownList, ListTypes } from '@etta/ui/drop-down-list'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { AmenitiesFilter } from '@etta/screens/hotel-result-page/hotel-filters/amenities-filter'
import { HotelAmenitiesFilterWrapper } from './view-mode-styled'

const i18nBase = 'HotelFilters.Hotel'

export const HotelAmenitiesFilter = observer(function HotelAmenitiesFilter() {
  const {
    hotelFiltersAction,
    hotelFiltersStore,
    hotelSearchResultsStore,
    hotelQuickFiltersStore,
    hotelQuickFiltersAction,
  } = useHotelSearchResultsContext()

  const { t } = useTranslation()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  const options = hotelSearchResultsStore.filterOptions.amenities.amenities.map(
    ({ amenityDisplayName, amenityId }) => ({
      label: amenityDisplayName,
      value: amenityId,
    }),
  )

  const onChange = useCallback(
    (newValue) => {
      if (hotelFiltersStore.selectedFilters.amenityIds.length === 0 && newValue.length === 0) {
        return
      }
      hotelFiltersAction.handleSetAmenities(newValue)
      hotelFiltersAction.handleApplyFilters()
    },
    [hotelFiltersAction, hotelFiltersStore.selectedFilters.amenityIds.length],
  )

  if (isNewHotelExposedFiltersEnabled) {
    return (
      <QuickFilterLayout
        data-tracking-id="hotel-amenities-filter-dropdown"
        isOpen={hotelQuickFiltersStore.amenitiesModal.isOpen}
        onOpen={hotelQuickFiltersStore.amenitiesModal.handleOpen}
        onClose={hotelQuickFiltersStore.amenitiesModal.handleClose}
        onClear={hotelFiltersAction.clearAmenities}
        onApply={hotelQuickFiltersAction.handleApplyAmenities}
        label={t(i18nBase + '.HotelAmenities')}
        value={hotelQuickFiltersStore.amenitiesValue}
        isShimmerLoading={
          hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.hotelResults
        }
        isLoading={hotelSearchResultsStore.isLoading}
        selectedNumber={hotelFiltersStore.selectedFilters.amenityIds.length}
        isApplyButtonDisabled={hotelQuickFiltersStore.isAmenitiesApplyButtonDisable}
        isClearButtonDisabled={hotelQuickFiltersStore.isAmenitiesClearButtonDisable}>
        <AmenitiesFilter />
      </QuickFilterLayout>
    )
  }

  return (
    <HotelAmenitiesFilterWrapper>
      <DropDownList
        label={t(i18nBase + '.HotelAmenities')}
        options={options}
        value={hotelFiltersStore.selectedFilters.amenityIds}
        onChange={onChange}
        type={ListTypes.Select}
        defaultLabel={t(i18nBase + '.Any')}
        data-tracking-id="hotel-amenities-filter-dropdown"
        withNoneOption={false}
      />
    </HotelAmenitiesFilterWrapper>
  )
})
