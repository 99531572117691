import { useState } from 'react'
import { Checkbox } from '@etta/ui/checkbox'
import type { CancelTripDataValueObject } from '@etta/modules/post-booking/core/value-objects'
import { FullScreenRideHailSegment as RideHailSegment } from '../ride-hail-segment/full-screen'
import { Container } from './trip-segment-checkbox-styled'

type Props = {
  rideHailSegment: CancelTripDataValueObject
}

export function TripSegmentCheckbox({ rideHailSegment }: Props) {
  const [checked, setChecked] = useState(true)

  return (
    <Container>
      <Checkbox
        value={rideHailSegment.id.toString()}
        label={
          <RideHailSegment
            key={rideHailSegment.id}
            name={rideHailSegment.name}
            cost={rideHailSegment.cost}
            dropOffName={rideHailSegment.dropOffAddress}
            pickUpDateTime={rideHailSegment.startDate}
          />
        }
        checked={checked}
        isDisabled
        onChange={(isChecked) => {
          setChecked(isChecked)
        }}
        data-tracking-id="cancel-rideHail-segment-checkbox"
      />
    </Container>
  )
}
