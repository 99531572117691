export default function OrganisationIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5 6.33766V39.5H34.0714V32.1688C34.0714 31.2471 33.3243 30.5 32.4026 30.5H29.5974C28.7776 30.5 28.0968 31.0906 27.9554 31.8694L27.9514 31.8916L27.9494 31.9141L27.9306 32.1242L27.9286 32.1464V32.1688V39.5H4.5V19.1948C4.5 18.1799 5.32275 17.3571 6.33766 17.3571H19.6623C20.6772 17.3571 21.5 18.1799 21.5 19.1948H22H22.5V6.33766C22.5 5.32275 23.3227 4.5 24.3377 4.5H37.6623C38.6773 4.5 39.5 5.32275 39.5 6.33766ZM12.2143 27.1429V26.6429H11.7143H7.85715H7.35715V27.1429V31V31.5H7.85715H11.7143H12.2143V31V27.1429ZM18.6429 27.1429V26.6429H18.1429H14.2857H13.7857V27.1429V31V31.5H14.2857H18.1429H18.6429V31V27.1429ZM29.7198 21.1099V20.6099H29.2198H25.3626H24.8626V21.1099V24.9671V25.4671H25.3626H29.2198H29.7198V24.9671V21.1099ZM36.3462 21.1099V20.6099H35.8462H31.989H31.489V21.1099V24.9671V25.4671H31.989H35.8462H36.3462V24.9671V21.1099ZM12.2143 20.7143V20.2143H11.7143H7.85715H7.35715V20.7143V24.5714V25.0714H7.85715H11.7143H12.2143V24.5714V20.7143ZM18.6429 20.7143V20.2143H18.1429H14.2857H13.7857V20.7143V24.5714V25.0714H14.2857H18.1429H18.6429V24.5714V20.7143ZM29.7198 14.4835V13.9835H29.2198H25.3626H24.8626V14.4835V18.3407V18.8407H25.3626H29.2198H29.7198V18.3407V14.4835ZM36.3462 14.4835V13.9835H35.8462H31.989H31.489V14.4835V18.3407V18.8407H31.989H35.8462H36.3462V18.3407V14.4835ZM36.3462 7.85713V7.35713H35.8462H31.989H31.489V7.85713V11.7143V12.2143H31.989H35.8462H36.3462V11.7143V7.85713ZM29.7198 7.85713V7.35713H29.2198H25.3626H24.8626V7.85713V11.7143V12.2143H25.3626H29.2198H29.7198V11.7143V7.85713Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
