import { useTranslation } from 'react-i18next'
import type { Delegate } from '@fiji/graphql/types'
import { DelegateSwitchLayout } from '@fiji/enums'
import { useUserContext } from '@etta/modules/user/interface/use-user.context'

type Props = {
  delegate?: Delegate
  isSelected?: boolean
  layout?: DelegateSwitchLayout
}

const i18nPath = 'Delegate.'

export function useDelegateItem({ delegate, isSelected, layout }: Props) {
  const { t } = useTranslation()
  const youText = t(i18nPath + 'You')

  const firstNameLetter = delegate?.firstName?.charAt(0) ?? ''
  const lastNameLetter = delegate?.lastName?.charAt(0) ?? ''
  const initials = [firstNameLetter, lastNameLetter].join('')

  const { userStore } = useUserContext()
  const { customerId, impersonatedId } = userStore
  const currentDelegatorId = impersonatedId || customerId
  const isCurrentUserCustomer = currentDelegatorId === Number(delegate?.userId)

  const youTitle = isCurrentUserCustomer ? `(${youText})` : ''
  const fullName = [delegate?.firstName, delegate?.lastName, youTitle].filter(Boolean).join(' ')
  const userEmail = delegate?.email ?? ''
  const delegateSelectedItemLabel = isSelected
    ? t(i18nPath + 'Selected', { fullName })
    : t(i18nPath + 'TapSelect', { fullName })
  const delegateItemLabel =
    layout !== DelegateSwitchLayout.TripDetails ? delegateSelectedItemLabel : fullName

  const showNameWithInitials =
    layout === DelegateSwitchLayout.Explore || layout === DelegateSwitchLayout.TripDetails
  const showName = layout === DelegateSwitchLayout.PostBooking

  return {
    delegateItemLabel,
    initials,
    fullName,
    userEmail,
    showNameWithInitials,
    showName,
  }
}
