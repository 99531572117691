import { CreditCardItem } from '@etta/components/credit-card-item/credit-card-item'
import type { CreditCard } from '@fiji/graphql/types'
import { CreditCardTypes } from '@fiji/graphql/types'
import { getBillingConditions } from '../use-purchase-payment-information-form/get-billing-conditions'
import { formatExpirationDate } from '../use-purchase-payment-information-form/format-expiration-date'

type Props = {
  creditCards: Partial<CreditCard>[]
  selectedCard: string | null
  withBorderBottom?: boolean
  handleSelectCard: (id?: string) => void
  handleEditCreditCardClick: (card: Partial<CreditCard>) => void
}

export function CreditCardList({
  creditCards,
  selectedCard,
  withBorderBottom,
  handleSelectCard,
  handleEditCreditCardClick,
}: Props) {
  return (
    <>
      {creditCards.map((card, index, array) => {
        const isCardEditable =
          card.metadata?.isEditable &&
          card.metadata?.type !== CreditCardTypes.PersonalCorporateCreditCard
        const { billingError } = getBillingConditions({ creditCard: card })
        const isCardDisabled = Boolean(billingError.brandType)

        return (
          <CreditCardItem
            isDisabled={isCardDisabled}
            key={card.id}
            cardName={card.label || ''}
            cardNumber={card.cardNumber || ''}
            billingError={billingError}
            expirationDate={formatExpirationDate(card.expirationDate)}
            cardVendor={card.brandType}
            isEditable={isCardEditable}
            isSelectable
            isSelected={selectedCard === card.id}
            withEditIcon
            onChange={() => handleSelectCard(card.id)}
            onEdit={() => handleEditCreditCardClick(card)}
            isLast={withBorderBottom || index !== array.length - 1}
          />
        )
      })}
    </>
  )
}
