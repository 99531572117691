import { Result } from 'fnscript'
import type { PromisedResult } from '@etta/core/type-utils'
import { Adapter, Inject } from '@etta/di'
import type { BrandConfigurationErrorsInstances } from '../core/errors/brand-configuration.errors'
import { BrandConfigurationErrors } from '../core/errors/brand-configuration.errors'
import type { BrandConfigurationValueObject } from '../core/value-object/brand-configration.value-object'
import { BrandConfigurationDataProvider } from './data-provider/brand-configuration.data-provider'

@Adapter()
export class BrandConfigurationAdapter {
  constructor(
    @Inject()
    private brandConfigurationDataProvider: BrandConfigurationDataProvider,
  ) {}

  async getBrandConfiguration(): PromisedResult<
    BrandConfigurationValueObject,
    BrandConfigurationErrorsInstances
  > {
    try {
      const { data, error } = await this.brandConfigurationDataProvider.getBrandConfiguration()

      if (error) {
        return Result.Err(new BrandConfigurationErrors.BrandConfigurationResponse(error))
      }

      return Result.Ok({
        brandTitleLabel: data.brandConfiguration.brandTitleLabel || undefined,
        customMobileCompanyLogoUrl: data.brandConfiguration.customMobileCompanyLogoUrl || undefined,
        customMobilePartnerLogoUrl: data.brandConfiguration.customMobilePartnerLogoUrl || undefined,
        isThemeEnabled: data.brandConfiguration.isThemeEnabled,
        partnerLogoUrl: data.brandConfiguration.partnerLogoUrl || undefined,
        theme: data.brandConfiguration.theme,
        termsOfUseLinkEnabled: false,
      })
    } catch (e) {
      return Result.Err(new BrandConfigurationErrors.BrandConfigurationUnexpectedError(e))
    }
  }
}
