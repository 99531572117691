import { observer } from 'mobx-react-lite'
import { usePreventPullRefresh } from '@fiji/hooks/body/use-prevent-pull-refresh'
import { useHotelResult } from './use-hotel-result'
import { HotelResultsProvider } from './hotel-results-provider'
import { HotelResultPageLayout } from './layout'
import { HotelList } from './hotel-list'
import { HotelResultModals } from './hotel-result-modals'

export const HotelResultPage = observer(function HotelResultPage() {
  usePreventPullRefresh()
  const value = useHotelResult()

  return (
    <HotelResultsProvider value={value}>
      <HotelResultPageLayout>
        <HotelList />
      </HotelResultPageLayout>

      <HotelResultModals />
    </HotelResultsProvider>
  )
})
