import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 4px;
  padding: 12px;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.background4};
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* Should be same as line height of text */
  height: 19px;
`
