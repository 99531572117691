import { Action, Inject } from '@etta/di'
import { AccountInformationService } from '../services/account-information.service'

@Action()
export class AccountInformationActions {
  constructor(
    @Inject()
    private readonly accountInformationService: AccountInformationService,
  ) {}

  getAccountInformation() {
    return this.accountInformationService.getStructuredAccountInformation()
  }
}
