import React from 'react'

export type ModalContextType = {
  onClose: (value: boolean) => void
}

const ModalContext = React.createContext({
  onClose: () => {},
} as ModalContextType)

export { ModalContext }
