import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const ListItem = styled.li<{ textTransform?: 'capitalize' | 'none' }>`
  display: flex;
  flex-shrink: 0;
  text-transform: ${(p) => p.textTransform || 'capitalize'};
  max-width: 100%;
  ${textCSS};
  &:not(:first-of-type):before {
    content: '\\2022';
    margin: 0 5px;
  }
  line-height: 1;
`
