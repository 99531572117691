import { useTheme } from '@fiji/hooks/use-theme'
import type { IconColorPalette, IconSizes, PWAIconNames } from '../types'
import { Container } from './pwa-icon-container'
import { pwaIcons } from './icons'

export type PWAIconProps = {
  size?: IconSizes
  color?: IconColorPalette
  className?: string
  'aria-label'?: string
  name: PWAIconNames
}

export function PWAIcon({
  name,
  size = 'normal',
  color,
  className,
  'aria-label': ariaLabel,
}: PWAIconProps) {
  const theme = useTheme()
  const IconComponent = pwaIcons[name]
  const usedColor = color ? (theme.colors[color] as string) : undefined

  return (
    <Container
      size={size}
      iconColor={color}
      className={className}
      aria-hidden
      aria-label={ariaLabel ?? `icon-${name}`}
      data-icon-name={`icon-${name}`}
      data-tracking-id={`icon-${name}`}>
      <IconComponent color={usedColor} />
    </Container>
  )
}
