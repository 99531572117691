import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { SelectSearch } from '@etta/ui/select-search'
import { Text } from '@etta/ui/text'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { Section } from '../filters-styled'
import { useSearchHotelsFilter } from './use-search-hotels-filter'

export const SearchHotel = observer(function SearchHotel() {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()
  const { t } = useTranslation()

  const {
    options,
    handleClearFilter,
    handleHotelNamesSearch,
    handleSelectedItemChange,
    isLoading,
  } = useSearchHotelsFilter()

  const i18nPath = 'HotelFilters.Hotel'
  const searchHotelText = t(i18nPath + '.SearchHotel')
  const placeholder = t(i18nPath + '.SearchHotelByName')

  return (
    <Section withBorder>
      <Block marginBottom={10}>
        <Text variant="headline" color="mainText">
          {searchHotelText}
        </Text>
      </Block>
      <SelectSearch
        data-tracking-id="search-hotel-filter"
        data-tracking-field-id="hotel-name-filter-text"
        options={options}
        onOptionSelect={(value) => {
          handleSelectedItemChange(value)
          hotelFiltersAction.handleSetFilter('hotelName', value)
        }}
        onChangeValue={handleHotelNamesSearch}
        onClear={handleClearFilter}
        label={searchHotelText}
        placeholder={placeholder}
        size="small"
        fieldType="search"
        isLoading={isLoading}
        value={hotelFiltersStore.selectedFilters.hotelName}
        defaultValue={hotelFiltersStore.selectedFilters.hotelName}
      />
    </Section>
  )
})
