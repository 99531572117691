import { useMemo } from 'react'
import type {
  TripSegmentValueObject,
  CarRentalSegmentEntity,
  HotelSegmentEntity,
  FlightSegmentEntity,
  TrainSegmentEntity,
} from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

export function useOopSegments(segments: TripSegmentValueObject[]) {
  const { segmentTypeCheckActions } = useReviewTripContext()

  const flights = useMemo(
    () =>
      segments.filter(
        (segment): segment is FlightSegmentEntity =>
          segmentTypeCheckActions.isFlightSegment(segment) &&
          !segment.policy.isInPolicy &&
          !segment.policy.outOfPolicyComplianceCodes?.length,
      ),
    [segments, segmentTypeCheckActions],
  )
  const hotels = useMemo(
    () =>
      segments.filter(
        (segment): segment is HotelSegmentEntity =>
          segmentTypeCheckActions.isHotelSegment(segment) &&
          !segment.policy.isInPolicy &&
          !segment.policy.outOfPolicyComplianceCodes?.length,
      ),
    [segments, segmentTypeCheckActions],
  )
  const carRentals = useMemo(
    () =>
      segments.filter(
        (segment): segment is CarRentalSegmentEntity =>
          segmentTypeCheckActions.isCarRentalSegment(segment) &&
          !segment.policy.isInPolicy &&
          !segment.policy.outOfPolicyComplianceCodes?.length,
      ),
    [segments, segmentTypeCheckActions],
  )
  const rails = useMemo(
    () =>
      segments.filter(
        (segment): segment is TrainSegmentEntity =>
          segmentTypeCheckActions.isTrainSegment(segment) &&
          !segment.policy.isInPolicy &&
          !segment.policy.outOfPolicyComplianceCodes?.length,
      ),
    [segments, segmentTypeCheckActions],
  )
  return { flights, hotels, carRentals, rails }
}
