import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Container, EditBtn } from './edit-card-button-styled'

type Props = {
  isEditable: boolean
  withEditIcon: boolean
  withDeleteIcon?: boolean
  editLabel?: string
  isDisabled: boolean
  isVirtualPayRestricted?: boolean
  onEdit?: () => void
  withChevron?: boolean
  withButton?: boolean
}

export function EditCardButton({
  editLabel,
  isEditable,
  isVirtualPayRestricted,
  withEditIcon,
  withDeleteIcon,
  onEdit,
  isDisabled,
  withChevron,
  withButton,
}: Props) {
  const { t } = useTranslation()

  if (withDeleteIcon) {
    return (
      <Container>
        <IconButton isDisabled={isDisabled} icon="deletePWA" color="primary" onClick={onEdit} />
      </Container>
    )
  }

  if (!isEditable || isVirtualPayRestricted) {
    return null
  }

  if (withChevron) {
    return (
      <Container>
        <Icon name="chevronRightPWA" color="primary" />
      </Container>
    )
  }

  if (withButton) {
    return (
      <Container>
        <Button size="small" onClick={onEdit}>
          {t('CreditCardItem.ChangeCard')}
        </Button>
      </Container>
    )
  }

  if (withEditIcon) {
    return (
      <Container>
        <IconButton
          isDisabled={isDisabled}
          icon="editPWA"
          color="primary"
          onClick={onEdit}
          aria-label={t('Accessibility.Settings.EditButton')}
        />
      </Container>
    )
  }

  return (
    <Container withEditLabel>
      <EditBtn aria-label={t('CreditCardItem.EditArea')} onClick={onEdit}>
        {editLabel || t('CreditCardItem.Edit')}
      </EditBtn>
    </Container>
  )
}
