import i18n from 'i18next'
import type { BookType } from '@etta/screens/payment-summary/use-payment-summary/types'

type Args = {
  isBooking?: boolean
  isDueTodayCostDisplayed: boolean
}

const i18nBase = 'Purchase.PriceBlock'

function getLabel({ isDueTodayCostDisplayed, isBooking }: Args, type: BookType) {
  switch (true) {
    case isBooking:
      return type === 'regular' ? i18n.t(`${i18nBase}.BookNow`) : i18n.t('HoldTrip.HoldTrip')

    case isDueTodayCostDisplayed:
      return i18n.t(`${i18nBase}.AgreeAndBook`)

    default:
      return i18n.t(`${i18nBase}.Continue`)
  }
}

export function getButtonLabels(args: Args) {
  return { buttonLabel: getLabel(args, 'regular'), holdButtonLabel: getLabel(args, 'hold') }
}
