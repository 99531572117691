import { useTranslation } from 'react-i18next'
import type { OptionType } from '@etta/ui/drop-down-list'
import { DropDownList, OptionViewTypes } from '@etta/ui/drop-down-list'
import { StyledTextVariant } from '@fiji/enums'
import { OutOfPolicy } from '../../out-of-policy'
import type { PolicyReasonProps } from '../../types'
import {
  Container,
  Heading,
  ReasonMessage,
  Label,
  Error,
  DropDownWrapper,
} from './policy-reason-desktop-styled'

export function PolicyReasonDesktop({
  reasons,
  segment,
  description,
  policyData,
  onChangeValue,
  value,
  heading,
  error,
  dataTrackingId,
  scrollParentRef,
}: PolicyReasonProps) {
  const { t } = useTranslation()
  const i18nBase = 'OutOfPolicyModal.'
  const dataPerSegment = reasons.filter((el) => el.segment === segment)

  if (!dataPerSegment.length) {
    return null
  }

  const handleChangeValue = (value: number[]) => {
    if (value[0]) {
      onChangeValue(value[0])
    }
  }
  const dropDownListOptions = dataPerSegment.map(
    (policy) =>
      ({
        label: policy.reason || '',
        value: policy.id,
        viewType: OptionViewTypes.OptionWithCheckboxIcon,
      } as OptionType<number>),
  )

  return (
    <Container>
      <div data-tracking-id={`${dataTrackingId}_oop-section`}>
        <Heading>{heading}</Heading>
        <ReasonMessage>
          <OutOfPolicy
            textStyle={StyledTextVariant.subHeadMedium}
            outOfPolicyReasons={description}
            outOfPolicyData={policyData}
          />
        </ReasonMessage>
        <Label>{t(i18nBase + 'PolicyReasonLabel')}</Label>
        <DropDownWrapper>
          <DropDownList<number>
            label={t(i18nBase + 'PolicyReasonPlaceholder')}
            value={value ? [value] : undefined}
            options={dropDownListOptions}
            onChange={handleChangeValue}
            withListBodyPadding={false}
            withListHeader={false}
            withThinLabel
            withVisibleLabel
            data-tracking-id={`${dataTrackingId}_oop-reason-dropdown`}
            isFixedPositioned
            withFullHeight
            scrollParentRef={scrollParentRef}
            shouldCloseOnScroll
            withNoneOption={false}
          />
        </DropDownWrapper>
        {error && <Error>{error}</Error>}
      </div>
    </Container>
  )
}
