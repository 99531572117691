import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { PriceChangeDialogDesktop } from './desktop'
import { PriceChangeDialogMobile } from './mobile'

export function PriceChangeDialogLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<PriceChangeDialogMobile {...props} />}
      desktopSlot={<PriceChangeDialogDesktop {...props} />}
    />
  )
}
