import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import type { Props } from '../../types'
import { SectionTitle, SelectedText } from './list-section-title-desktop-styled'

export function SectionTitleDesktop({ title, selectedAmount = 0, onClear }: Props) {
  const { t } = useTranslation()

  return (
    <SectionTitle>
      {title}{' '}
      {selectedAmount > 0 && (
        <>
          <SelectedText>{t('CarRentalFilters.Selected', { option: selectedAmount })}</SelectedText>
          <Link onClick={onClear}>{t('CarRentalFilters.Buttons.Clear')}</Link>
        </>
      )}
    </SectionTitle>
  )
}
