import { TravelerInfo } from '@etta/screens/traveler-info'
import { PurchaseAdditionalInfoForm } from '@etta/screens/purchase-additional-info-form'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const travelerInformationRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.traveler.information({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Traveler Information'),
    transitionIndex: 6,
    transitionGroup: 'slide',
    isNavBarHidden: true,
    component: () => <TravelerInfo />,
  },
  {
    route: {
      path: ROUTES.traveler.addPurchase({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Traveler Information'),
    transitionIndex: 7,
    transitionGroup: 'slide',
    isNavBarHidden: true,
    component: () => <PurchaseAdditionalInfoForm />,
  },
]
