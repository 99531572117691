import type { AdaptedFlightSegment } from '@fiji/hooks/use-adaptive-flight/types'
import type { FlightFareSegment, FlightLegSubSegment } from '@fiji/graphql/types'
import type { FetchSeatMapSegment } from './types'

export function getSeatMapSegmentFetchStructure(
  segment: AdaptedFlightSegment,
  fareSegment: FlightFareSegment | FlightLegSubSegment,
): FetchSeatMapSegment {
  const fareBasisCode =
    'basisCode' in fareSegment && fareSegment.basisCode
      ? fareSegment.basisCode
      : 'fare' in fareSegment && fareSegment.fare
      ? fareSegment.fare
      : ''

  return {
    segmentId: segment.id,
    departureFullDate: segment.departureFullDate,
    departureAirportCode: segment.departureAirportCode,
    arrivalAirportCode: segment.arrivalAirportCode,
    carrierCode: segment.carrierCode,
    rawFlightNumber: segment.rawFlightNumber,
    fareBookingCode: fareSegment.bookingCode || '',
    fareServiceClass: fareSegment.serviceClass,
    isSeatMapAvailable: segment.isSeatMapAvailable,
    isInstantPurchaseCarrier: segment.isInstantPurchaseCarrier,
    fareBasisCode: fareBasisCode,
  }
}
