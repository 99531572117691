import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Swap } from '@etta/ui/swap'
import { Icon } from '@etta/ui/icon'
import { TextFieldGroup } from '@etta/ui/text-field-group'
import type { OptionType } from '@etta/ui/select'
import { PreferencesLabel } from '@etta/components/preferences-label/preferences-label'
import { RailCardModal } from '@etta/components/rail-card-modal/rail-card-modal'
import type { DisabledMembership } from '@fiji/types'
import type { LoyaltyProgram } from '@fiji/graphql/types'
import type { RailLayoutType } from '@fiji/enums'
import { UnavailableMessage } from '../unavailable-message'
import { DisabledMembershipWarning } from './disabled-membership-warning'
import { useLoyalty } from './use-loyalty'

type Props = {
  label: string
  selectLabel: string
  memberLabel: string
  options: OptionType<string>[]
  value: LoyaltyProgram[] | null
  onUpdate: (data: LoyaltyProgram[]) => void
  disabledMemberships?: DisabledMembership[]
  railLayout: RailLayoutType
}

export const RailLoyaltyPrograms = observer(function RailLoyaltyPrograms({
  label,
  selectLabel,
  options,
  value,
  onUpdate,
  disabledMemberships,
  railLayout,
  memberLabel,
}: Props) {
  const { t } = useTranslation()
  const {
    onSelectUKCard,
    onSelectEUCard,
    onHideModal,
    onShowModal,
    isOpen,
    // savedCards,
    cardGroups,
    onNumberUpdate,
  } = useLoyalty({
    options,
    value,
    onUpdate,
  })

  return (
    <Block>
      <PreferencesLabel icon="loyaltyPWA">{label}</PreferencesLabel>
      <Swap
        is={!options.length}
        isSlot={<UnavailableMessage>{t('RailtMembershipUnavailable')}</UnavailableMessage>}>
        {value?.map((membership, index) => {
          if (disabledMemberships?.map((el) => el.code)?.includes(membership.vendorCode)) {
            return ''
          }
          return (
            <Block marginTop={index ? 20 : 0} key={'frequent-flyer-' + index}>
              <TextFieldGroup>
                <div onClick={onShowModal}>
                  <TextField
                    label={selectLabel}
                    value={membership.label || ''}
                    endSlot={
                      <Block paddingRight={12}>
                        <Icon name="expandMorePWA" size="medium" color="mainText" />
                      </Block>
                    }
                  />
                </div>
                <TextField
                  label={memberLabel}
                  value={membership.number || ''}
                  onChange={(number: string) => {
                    onNumberUpdate(number, index, membership)
                  }}
                  data-tracking-id={`loyalty-programs-membership-input_${membership.label}`}
                />
              </TextFieldGroup>
            </Block>
          )
        })}
      </Swap>
      <RailCardModal
        isOpen={isOpen}
        onClose={onHideModal}
        layout={railLayout}
        // savedCards={savedCards}
        cardGroups={cardGroups}
        onSelectUKCard={onSelectUKCard}
        onSelectEUCard={onSelectEUCard}
      />
      <DisabledMembershipWarning isVisible={!!disabledMemberships?.length} />
    </Block>
  )
})
