import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import type { ViewState } from '@etta/ui/view-state-modal'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import type { AddSeatStateStatus } from '@etta/screens/review-trip-page/flight-details-modal/types'

type Props = { status: AddSeatStateStatus; closeModal: () => void }

const baseLocale = 'ReviewTrip.AddingSeatModal'

export function getAddingSeatModalTitle(
  status: AddSeatStateStatus,
  t: TFunction,
): { title: string; error?: string; viewState: ViewState } | null {
  switch (status) {
    case 'loading':
      return { title: t(`${baseLocale}.LoadingTitle`), viewState: 'loading' }
    case 'success':
      return { title: t(`${baseLocale}.SuccessTitle`), viewState: 'saved' }
    case 'sold-out':
      return { title: t(`${baseLocale}.SoldOutTitle`), viewState: 'custom' }
    case 'error':
      return { title: '', error: t(`${baseLocale}.ErrorText`), viewState: 'error' }
    case 'idle':
    default:
      return { viewState: 'hidden', title: '' }
  }
}

export function SeatMapsModal({ status, closeModal }: Props) {
  const { t } = useTranslation()
  const viewStateProps = getAddingSeatModalTitle(status, t)

  if (viewStateProps === null) {
    return null
  }

  return (
    <ViewStateModal {...viewStateProps} onDone={closeModal}>
      {status === 'sold-out' && (
        <>
          <Text variant="title3" color="mainText">
            {viewStateProps.title}
          </Text>
          <Block marginTop={24}>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${baseLocale}.SoldOutNewText`)}
            </Text>
          </Block>
          <Block isFullWidth marginTop={32}>
            <Button onClick={closeModal} fullWidth>
              {t(`${baseLocale}.SelectAnotherFareBtn`)}
            </Button>
          </Block>
          <Block isFullWidth marginTop={16}>
            <Button onClick={closeModal} variant="outline" fullWidth>
              {t(`${baseLocale}.SearchAgainBtn`)}
            </Button>
          </Block>
        </>
      )}
    </ViewStateModal>
  )
}
