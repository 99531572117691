import firebase from 'firebase/app'
import 'firebase/messaging'

const config = {
  apiKey: String(process.env.REACT_APP_FIREBASE_API_KEY),
  authDomain: 'fiji-nonprod.firebaseapp.com',
  projectId: 'fiji-nonprod',
  storageBucket: 'fiji-nonprod.appspot.com',
  messagingSenderId: '757856585424',
  appId: '1:757856585424:web:b03b8b9c3b138dc47100d5',
  measurementId: 'G-82LJ3XELD2',
}

firebase.initializeApp(config)

export { firebase }
