import type { Segment } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'

export function extractFromSegments(segments: Segment[] | undefined, type: SegmentType) {
  if (!segments) {
    return []
  }
  if (type === SegmentType.CarRental) {
    return segments.map((segment) => segment.carRental?.carId).filter(Boolean) as string[]
  }
  if (type === SegmentType.Hotel) {
    return segments.map((segment) => segment.hotel?.hotelId).filter(Boolean) as string[]
  }
  return []
}
