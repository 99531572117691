import type { ReactElement } from 'react'
import { useMemo, useState } from 'react'
import type { PageProps } from './page'
import type { PageSwitcherContextType } from './page-switcher-context'

export function useCreatePageSwitcherContext(childrens: ReactElement[] = []) {
  const [activePageIndex, setActivePageIndex] = useState<number>(0)
  const activePage = useMemo(() => childrens[activePageIndex], [activePageIndex, childrens])
  const activePageKey = useMemo(() => childrens[activePageIndex]?.props?.pageKey, [
    activePageIndex,
    childrens,
  ])

  const isNext = useMemo(() => activePageIndex + 1 < childrens.length, [
    activePageIndex,
    childrens.length,
  ])

  const isBack = useMemo(() => activePageIndex - 1 >= 0, [activePageIndex])

  const context: PageSwitcherContextType = useMemo(() => {
    return {
      activePage,
      activePageKey,
      isNext,
      isBack,
      next: () => {
        if (isNext) {
          setActivePageIndex(activePageIndex + 1)
        }
      },
      back: () => {
        if (isBack) {
          setActivePageIndex(activePageIndex - 1)
        }
      },
      gotoPage: (key: string) => {
        const foundPageIndex = childrens.findIndex(
          (child: ReactElement<PageProps>) => child?.props?.pageKey === key,
        )
        if (foundPageIndex >= 0) {
          setActivePageIndex(foundPageIndex)
        }
      },
    }
  }, [activePage, activePageIndex, activePageKey, childrens, isBack, isNext])

  return {
    context,
  }
}
