import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { TrainGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { AllocatedSeat } from '@etta/screens/payment-summary/types'
import { dateFormat, deleteTimezone, getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useShareSegment } from '@etta/screens/trip-details/use-trip-details/use-share-segment'
import { useTripDetailsContextSelector } from '@etta/screens/trip-details/trip-details-provider'
import type { RailOpenReturnDetailsProps } from '@etta/components/rail-open-return-details/types'
import { TrainDeliveryOptionCode } from '@fiji/enums'
import { RouteType } from '@fiji/graphql/types'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'
import { ScreenType, useScreenType } from '@fiji/modes'

type Params = {
  segment: TrainGroupedSegmentValueObject
  dateToShow: string
  isUpcoming: boolean
  isOnHold: boolean
  onCancel: () => void
  isTripInProgress?: boolean
}

type AllocatedSeatItem = AllocatedSeat[number]

const i18Base = 'PostBooking.TrainDetails.'
const SEGMENT_LABEL = 'PostBooking.TripDetails.SegmentAriaLabel'

const TIME_FORMAT = 'h:mma'
const LABELS_DIVIDER = ' • '
const NO_REFUNDS_FARE = 'No refunds'

export function useTrainSegment({
  segment,
  dateToShow,
  isUpcoming,
  isOnHold,
  onCancel,
  isTripInProgress,
}: Params) {
  const { t } = useTranslation()

  const screenType = useScreenType()
  const isMobile = ScreenType.Mobile === screenType

  const {
    postBookingTripStore,
    postBookingTripDetailsActions,
    postBookingTripDetailsStore,
  } = usePostBookingContext()

  const { trip } = postBookingTripStore

  const { ticketRestrictionsToggle } = postBookingTripDetailsStore

  const { tripDetails } = useTripDetailsContextSelector((state) => state)

  const { handleShare } = useShareSegment({ segment })

  const notAllowedTrainModal = useTogglePopup()

  const typeTitle = t('PostBooking.TrainDetails.CancelType')

  const isTrainChangeAllowed = trip?.supportedActions.isTrainChangeAllowed && !trip.isMultiCity
  const isOneWayTrain = postBookingTripStore.railSegments.length === 1

  const title = t('PostBooking.TrainDetails.TrainCardTitle', getStations())

  const ariaLabel = t(SEGMENT_LABEL, { label: `${title}. ${dateToShow}` })

  const departureDateTime = segment.departureDateTime || ''
  const arrivalDateTime = segment.arrivalDateTime || ''

  const departureTime = dateFormat(new Date(deleteTimezone(departureDateTime)), TIME_FORMAT)

  const arrivalTime = dateFormat(new Date(deleteTimezone(arrivalDateTime)), TIME_FORMAT)

  const trainDateLabel = [departureTime.toLowerCase(), arrivalTime.toLowerCase()]
    .filter(Boolean)
    .join(' - ')

  const isOpenReturn = RouteType.OpenReturn === segment.routeType

  const departureDate = dateFormat(
    getDateWithoutTimezoneOffset(segment.departureDateTime),
    'EEE, MMM d',
  )

  let headerDate = departureDate
  if (isOpenReturn) {
    headerDate += t(`${i18Base}OpenReturn`)
  }
  const carrierImage = segment.segments?.[0]?.carrierImage

  const seat = useMemo(() => {
    const trainSeat = segment.segments?.reduce<Partial<AllocatedSeatItem>>((acc, item) => {
      if (item.allocatedSeat && item.allocatedSeat.length > 0) {
        if (item.allocatedSeat[0]?.carriage && item.allocatedSeat[0]?.seat) {
          return { ...acc, ...item.allocatedSeat[0] }
        }
      }
      return acc
    }, {})
    if (trainSeat?.seat && trainSeat?.carriage) {
      return t(i18Base + 'SeatNumber', { seatNumber: `${trainSeat.seat}${trainSeat.carriage}` })
    }
    return ''
  }, [segment.segments, t])

  const platformNumber = segment.platform
    ? t(i18Base + 'Platform', { platNumber: segment.platform })
    : ''

  const infoText = [platformNumber, seat].filter(Boolean).join(LABELS_DIVIDER)

  const dataTexts = [infoText].filter(Boolean)

  const fareConditions = segment.fareConditions?.[0]
  const refundableText = getRefundableInfo()
  const serviceClassName = fareConditions?.tier.name || ''

  const ticketInformationText =
    segment.selectedDeliveryOption?.code === TrainDeliveryOptionCode.Eticket
      ? t(`${i18Base}Modal.FaresDetails.Eticket`)
      : t(`${i18Base}Modal.FaresDetails.Collect`)

  const handleOpenReturnTicketRestrictionsClick = useCallback(() => {
    postBookingTripDetailsActions.handleOpenSelectedOpenReturnDetails(segment)
  }, [postBookingTripDetailsActions, segment])

  const openReturnProperties = isOpenReturn
    ? ({
        serviceClassName,
        ticketInformationText,
        refundableText,
        onViewRestrictionsClick: handleOpenReturnTicketRestrictionsClick,
      } as RailOpenReturnDetailsProps)
    : null

  const handleTicketRestrictionsClick = useCallback(() => {
    const ticketRestrictions: TicketRestrictionsInfo = {
      tripFare: fareConditions?.localizedFareConditions?.[0]?.fareTypeDescription || '',
      ticketRestrictions: fareConditions?.localizedFareConditions?.[0]?.conditions || [],
    }
    postBookingTripDetailsStore.setTicketRestrictionsInfo(ticketRestrictions)
    ticketRestrictionsToggle.handleOpen()
  }, [
    ticketRestrictionsToggle,
    postBookingTripDetailsStore,
    fareConditions?.localizedFareConditions,
  ])

  const handleCancel = useCallback(() => {
    if (trip?.supportedActions.isTrainCancelAllowed) {
      return onCancel
    }

    return notAllowedTrainModal.handleOpen
  }, [notAllowedTrainModal.handleOpen, onCancel, trip?.supportedActions.isTrainCancelAllowed])

  const onModify = useCallback(() => {
    return tripDetails.handleModify()
  }, [tripDetails])

  const handlerModify = useCallback(() => {
    if (isTrainChangeAllowed && isOneWayTrain && !isTripInProgress) {
      return onModify
    }

    return notAllowedTrainModal.handleOpen
  }, [
    isOneWayTrain,
    isTrainChangeAllowed,
    isTripInProgress,
    notAllowedTrainModal.handleOpen,
    onModify,
  ])

  const buttons = [
    {
      visible: isUpcoming,
      title: t(i18Base + 'Cancel'),
      onClick: handleCancel(),
    },
    {
      visible: isUpcoming && !isOnHold,
      title: t(i18Base + 'Modify'),
      onClick: handlerModify(),
    },
  ]

  return {
    title,
    typeTitle,
    ariaLabel,
    trainDateLabel,
    trainStatus: segment.segments?.[0]?.status,
    departureTime,
    headerDate,
    dataTexts,
    buttons,
    handleShare,
    isAllowedTrainModalOpen: notAllowedTrainModal.isOpen,
    handleNotAllowedTrainModalClose: notAllowedTrainModal.handleClose,
    carrierImage,
    infoText,
    isOpenReturn,
    openReturnProperties,
    handleTicketRestrictionsClick:
      !isMobile && !isOpenReturn ? handleTicketRestrictionsClick : undefined,
  }

  function getStations() {
    return {
      departure: segment.segments?.[0]?.stations?.departure?.stationName || '',
      arrival:
        segment.segments?.[segment.segments.length - 1]?.stations?.arrival?.stationName || '',
    }
  }

  function getRefundableInfo() {
    const fareConditions = segment.fareConditions?.[0]
    const fareAttributes: string[] =
      fareConditions?.localizedFareConditions?.[0]?.summaryConditions.split('.').filter(Boolean) ||
      []
    const isRefundable = fareAttributes.findIndex((item) => item.trim() === NO_REFUNDS_FARE) === -1
    return isRefundable ? 'Refundable' : 'Non Refundable'
  }
}
