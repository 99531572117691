import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { Rate, TripCostSummary, TripCost, SegmentState } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { SegmentStatus } from '@etta/components/segment-status/segment-status'
import { formatRate } from '@fiji/utils/money/format-rate'
import { ChangedSegmentRate } from '../changed-segment-rate'
import { TripType, CostContainer, Text, Container } from '../../payment-summary-styled'
import { SectionContainer } from '../section-container'
import { RailSegment } from './rail-segment'

type Props = PropsWithChildren<{
  segments?: TrainSegmentEntity[]
  segmentState?: SegmentState
  railTripCost?: TripCost
  previousBookingRate?: Rate | null
  isFromPreviousBooking?: boolean
  tripCostSummary?: TripCostSummary | null
  totalWithUnusedTicketRate?: Rate | null
  withMarginTop?: boolean
  isRailAccreditationEnabled?: boolean
}>

const i18nBase = 'PaymentSummary.'

export function Rail({
  segments,
  isFromPreviousBooking,
  segmentState,
  previousBookingRate,
  tripCostSummary,
  railTripCost,
  children,
  withMarginTop,
  isRailAccreditationEnabled,
}: Props) {
  const { t } = useTranslation()

  if (!segments || segments.length === 0) {
    return <></>
  }
  const unusedTicketRate = tripCostSummary?.unusedTicket?.validated?.original

  const { mainCost: unusedTicketCost } = formatRate(unusedTicketRate, {
    morpheme: 'none',
  })

  return (
    <SectionContainer withMarginTop={withMarginTop}>
      <Container>
        <TripType>
          <span aria-label={t(i18nBase + 'Train')}>{t(i18nBase + 'Train')}</span>
          <SegmentStatus
            segmentState={segmentState}
            isFromPreviousBooking={isFromPreviousBooking}
          />
        </TripType>
        <ChangedSegmentRate
          segmentState={segmentState}
          rate={previousBookingRate}
          isFromPreviousBooking={isFromPreviousBooking}
          type={SegmentType.Train}
        />
        <RailSegment
          segments={segments}
          railTripCost={railTripCost}
          segmentState={segmentState}
          isRailAccreditationEnabled={isRailAccreditationEnabled}
        />

        {unusedTicketRate && (
          <CostContainer>
            <Text aria-label={t(i18nBase + 'UnusedTicketAccessibilityLabel')}>
              {t(i18nBase + 'UnusedTicket')}
            </Text>
            <Text>{unusedTicketCost}</Text>
          </CostContainer>
        )}
      </Container>
      {children}
    </SectionContainer>
  )
}
