import { useDebounce } from 'use-debounce'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import type { LayoutProps } from '../../types'
import { Container, Menu, List } from './location-picker-desktop-styled'

export function LocationPickerDesktop({
  isSearchWindowOpen,
  onModalClose,
  inputSlot,
  listSlot,
  isForSearchHeader = false,
}: LayoutProps) {
  const { ref } = useClickOutside(() => {
    if (isSearchWindowOpen) {
      onModalClose()
    }
  })
  const [isVisible] = useDebounce(isSearchWindowOpen, 300)
  useShouldOpenInViewport({
    elementHeight: 460,
    isVisible,
    ref,
  })

  return (
    <Container>
      {inputSlot}
      {isVisible && (
        <Menu ref={ref} isForSearchHeader={isForSearchHeader} isVisible={isSearchWindowOpen}>
          <List>{listSlot}</List>
        </Menu>
      )}
    </Container>
  )
}
