import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Button } from '@etta/ui/button'
import type { ViewState } from '@etta/ui/view-state-modal'

import { ConfirmationBody, WarningIcon } from './confirmation-modal-styled'

type Props = {
  confirmationModalViewState: ViewState
  onSwitch: () => void
  handleCloseModal: () => void
}

export function ConfirmationModal({
  confirmationModalViewState,
  onSwitch,
  handleCloseModal,
}: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'SwitchSiteModal'

  return (
    <ViewStateModal viewState={confirmationModalViewState}>
      <ConfirmationBody>
        <WarningIcon />
        <Block marginTop={4} marginBottom={16}>
          <Text variant="title3" align="center">
            {t(`${i18nBasePath}.SwitchExperience`)}
          </Text>
        </Block>
        <Block marginBottom={32}>
          <Text variant="footnoteMedium" align="center" color="bodyText">
            {t(`${i18nBasePath}.SwitchSiteDescription`)}
          </Text>
        </Block>
        <Button fullWidth onClick={onSwitch}>
          {t(`${i18nBasePath}.SwitchExperienceButton`)}
        </Button>
        <Block marginTop={16} isFullWidth>
          <Button onClick={handleCloseModal} variant="outline" fullWidth>
            {t(`${i18nBasePath}.CancelButton`)}
          </Button>
        </Block>
      </ConfirmationBody>
    </ViewStateModal>
  )
}
