import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { UnusedTicketMobile } from './layout/mobile'
import { UnusedTicketDesktop } from './layout/desktop'

export function UnusedTicketLayout({ unusedTicket }: Props) {
  return (
    <MediaLayout
      mobileSlot={<UnusedTicketMobile unusedTicket={unusedTicket} />}
      desktopSlot={<UnusedTicketDesktop unusedTicket={unusedTicket} />}
    />
  )
}
