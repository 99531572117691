import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ServiceCategory } from '@fiji/graphql/types'
import { useDeleteSegments } from './use-delete-segments'

type Props = {
  itineraryId: string
  legId: string
  onCloseAfterward?: () => void
  handleCloseRemoveModal?: () => void
}

export function useDeleteRail({
  itineraryId,
  legId,
  onCloseAfterward,
  handleCloseRemoveModal,
}: Props) {
  const { tripRailActions } = useReviewTripContext()

  const handleRequestRemoveRail = async () => {
    handleCloseRemoveModal && handleCloseRemoveModal()
    const res = await tripRailActions.handleRemoveRail({ itineraryId, railId: legId })
    if (res.isErr()) {
      throw new Error(res.getError().message)
    }
  }

  const { viewStateParams, handleDeleteSegment } = useDeleteSegments({
    serviceCategory: ServiceCategory.Train,
    onCloseAfterward,
    onRemoveSegment: handleRequestRemoveRail,
  })

  return {
    handleRemoveRail: handleDeleteSegment,
    viewStateParams,
  }
}
