import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/screens/payment-summary/payment-summary-styled'
import type { BillingError } from '@fiji/types'
import type { CreditCard } from '@fiji/graphql/types'
import { PurchaseCardError } from '@etta/components/purchase-card-error/purchase-card-error'
import { isCardExpired } from './is-card-expired'
import { CardExpired } from './payment-credit-card-error.styled'

type Props = {
  billingError?: BillingError
  isCardExpiringEarlierThanHotel?: boolean
  creditCard?: CreditCard
}

const i18nBase = 'PaymentSummary.'

export function PaymentCreditCardError({
  creditCard,
  isCardExpiringEarlierThanHotel,
  billingError,
}: Props) {
  const { t } = useTranslation()
  const isCreditCardExist = !!creditCard
  const isExpiringEarlierThanHotel = !!isCardExpiringEarlierThanHotel
  const isExpired =
    creditCard?.expirationDate &&
    isCardExpired(creditCard.expirationDate) &&
    !isExpiringEarlierThanHotel

  if (!isCreditCardExist) {
    return null
  }

  return (
    <>
      <PurchaseCardError withMargin err={billingError} />
      {isExpired && (
        <CardExpired>
          <Icon name="infoFilledPWA" color="error" size="small" />
          <Text marginLeft={8} color="error">
            {t(i18nBase + 'CardExpired')}
          </Text>
        </CardExpired>
      )}
      {isExpiringEarlierThanHotel && (
        <CardExpired>
          <Icon name="infoFilledPWA" color="error" size="small" />
          <Text marginLeft={8} color="error">
            {t(i18nBase + 'CardExpiringEarlierThanHotelStay')}
          </Text>
        </CardExpired>
      )}
    </>
  )
}
