import type { ReactNode } from 'react'
import { Spinner } from '@etta/ui/spinner'
import { SpinnerWrapper } from './lazy-content-styles'

type Props = {
  children: ReactNode
  isLoading?: boolean
  loaderSlot?: ReactNode
  height?: string
}

export function LazyContent({ isLoading, loaderSlot, children, height }: Props) {
  if (isLoading) {
    return loaderSlot ? (
      <>{loaderSlot}</>
    ) : (
      <SpinnerWrapper height={height}>
        <Spinner type="spinout" />
      </SpinnerWrapper>
    )
  }

  return <>{children}</>
}
