import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'

type Args = {
  personalInfo: PersonalInfo | null
  primaryTraveler?: PurchaseInfoTravelerValueObject
  travelerOptions?: TravelerOptions | null
  isGuest?: boolean
}

type Result = {
  fullName: string
  initials: string
  email: string
}

export function useProfileBox({
  personalInfo,
  primaryTraveler,
  travelerOptions,
  isGuest,
}: Args): Result {
  const { isNameChangeable } = travelerOptions || {}
  const firstName = isNameChangeable ? personalInfo?.firstName : primaryTraveler?.firstName
  const middleName = isNameChangeable ? personalInfo?.middleName : primaryTraveler?.middleInitial
  const lastName = isNameChangeable ? personalInfo?.lastName : primaryTraveler?.lastName

  const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ')

  return {
    fullName,
    email: (isGuest ? primaryTraveler?.email : personalInfo?.email) || '',
    initials: isNameChangeable
      ? `${personalInfo?.firstName?.substring(0, 1)}${personalInfo?.lastName?.substring(0, 1)}`
      : `${primaryTraveler?.firstName?.substring(0, 1)}${primaryTraveler?.lastName?.substring(
          0,
          1,
        )}`,
  }
}
