import type { MouseEventHandler } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import {
  AirlineLogo,
  AirportTripTitle,
  Container,
  FlightInfoText,
  Row,
  SecondaryText,
} from './flight-card-styled'

import type { Props } from './types'

export function FlightCard(props: Props) {
  const {
    airports: { from, to },
    airlineLogo,
    flightInfo,
    status,
    selectedSeat,
    onEditSeatClick,
    onCardClick,
    onSkipSeatSelectionClick,
    active,
    id,
    isLastFlight,
    showSkipButton,
  } = props

  const { t } = useTranslation()

  const isSeatMapAvailable = status !== 'notAvailable'

  const showEditAction = status === 'seatSelected'
  const showSkipAction = showSkipButton && !!onSkipSeatSelectionClick

  const handleClick = useCallback(() => {
    onCardClick?.(id)
  }, [onCardClick, id])

  const handleEditButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      onEditSeatClick?.(id)
    },
    [onEditSeatClick, id],
  )

  const handleSkipSelectionClick = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation()
      onSkipSeatSelectionClick?.()
    },
    [onSkipSeatSelectionClick],
  )

  const skipButtonText = isLastFlight
    ? t('FlightCard.SkipSeatSelectionAndContinue')
    : t('FlightCard.SkipSeatSelection')

  const flightInfoText = [flightInfo.airlineName, flightInfo.flightNumber, flightInfo.aircraftType]
    .filter(Boolean)
    .join(' • ')

  return (
    <Container
      aria-label={t('FlightDetails.FlightCartLabel', {
        departure: from,
        arrival: to,
        flightNumber: flightInfo.flightNumber,
        airline: flightInfo.airlineName,
      })}
      id={id}
      tabIndex={0}
      onClick={handleClick}
      isActive={active}>
      <AirlineLogo src={airlineLogo} alt={flightInfo.airlineName} />
      <div style={{ flexGrow: 1 }}>
        <Row as="header">
          <AirportTripTitle disabled={status === 'notAvailable'}>
            {from} <Icon name="arrowForwardPWA" size={16} /> {to}
          </AirportTripTitle>
          {showEditAction && (
            <Row>
              <SecondaryText style={{ marginRight: 4 }}>{selectedSeat}</SecondaryText>
              <IconButton
                width={24}
                height={24}
                onClick={handleEditButtonClick}
                aria-label="change seat"
                size="normal"
                icon="editPWA"
                color="primary"
              />
            </Row>
          )}
          {!isSeatMapAvailable && (
            <SecondaryText>{t('FlightCard.SeatMapNotAvailable')}</SecondaryText>
          )}
        </Row>
        {active && (
          <Block>
            <Block marginTop={2}>
              <FlightInfoText>{flightInfoText}</FlightInfoText>
            </Block>
            {showSkipAction && (
              <Block marginTop={4}>
                <Button
                  aria-label={t('ReviewTrip.ButtonsLabel', { label: skipButtonText })}
                  paddings={false}
                  variant="link"
                  size="small"
                  onClick={handleSkipSelectionClick}>
                  {skipButtonText}
                </Button>
              </Block>
            )}
          </Block>
        )}
      </div>
    </Container>
  )
}
