import { SegmentType } from '@etta/core/enums'
import type {
  RideHailSuggestionValueObject,
  SuggestedDateValueObject,
} from '../../core/value-objects/ride-hail-suggestion.value-object'
import { RideHailSuggestionLocationEnum } from '../../core/enum/suggestion-location-type.enum'
import { RideHailLocationType } from '../../core/enum/ride-hail-location-type.enum'

export function toSuggestedDates(suggestions: RideHailSuggestionValueObject[]) {
  return buildSuggestedDates(suggestions)
}

function buildSuggestedDates(suggestions: RideHailSuggestionValueObject[]) {
  const airportPickUpSuggestions = suggestions
    .filter((s) => Boolean(s.processId))
    .filter((suggestion) => isAirportPickUp(suggestion))
  const airportDropOffSuggestions = suggestions
    .filter((s) => Boolean(s.processId))
    .filter((suggestion) => isAirportDropOff(suggestion))
  const suggestedDates: SuggestedDateValueObject[] = []

  airportPickUpSuggestions.forEach((suggestion) => {
    const airportCode = getAirportCodeFromAddressName(suggestion.pickUpName)
    const date = suggestion.pickUpSegmentEndDate
    if (airportCode && date && isNotPast(date)) {
      suggestedDates.push({
        airportCode,
        date,
        type: RideHailLocationType.PickUp,
        processId: suggestion.processId!,
      })
    }
  })
  airportDropOffSuggestions.forEach((suggestion) => {
    const airportCode = getAirportCodeFromAddressName(suggestion.dropOffName)
    const date = suggestion.dropOffSegmentStartDate
    if (airportCode && date && isNotPast(date)) {
      suggestedDates.push({
        airportCode,
        date,
        type: RideHailLocationType.DropOff,
        processId: suggestion.processId!,
      })
    }
  })

  return suggestedDates.sort((item, nextItem) => Date.parse(item.date) - Date.parse(nextItem.date))
}

function isNotPast(date: string) {
  return date && new Date(date) >= new Date()
}

function isAirportPickUp(suggestion: RideHailSuggestionValueObject) {
  return (
    suggestion.pickUpLocationType === RideHailSuggestionLocationEnum.Airport &&
    suggestion.pickUpSegmentType === SegmentType.Flight
  )
}

function isAirportDropOff(suggestion: RideHailSuggestionValueObject) {
  return (
    suggestion.dropOffLocationType === RideHailSuggestionLocationEnum.Airport &&
    suggestion.dropOffSegmentType === SegmentType.Flight
  )
}

function getAirportCodeFromAddressName(name: string) {
  const result = name?.replace(',', '').split(' ')
  if (result && result.length === 2) {
    return result.find((str) => str.match('^[A-Z]{3}$')) // SFO
  }
  if (result && result.length > 2) {
    return result.find((str) => str.match('\\(([A-Z]{3})\\)'))?.slice(1, -1) // (SFO)
  }
}
