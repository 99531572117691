import styled from 'styled-components'
import { captionMedium } from '@fiji/style'

export const WeekContainerMobileStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  top: 0;
  min-height: 24px;
  z-index: 1;
  width: 100%;
  ${captionMedium}
`
