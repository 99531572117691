import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Props } from '../types'
import {
  Description,
  ExplanationContainer,
  Header,
} from './unused-ticket-explanation-desktop-styled'

export function UnusedTicketExplanationDesktop({
  title,
  description,
  note,
  ariaLabel,
  isWideSettingsModal,
}: Props) {
  return (
    <ExplanationContainer aria-label={ariaLabel} isWide={isWideSettingsModal}>
      <Header>
        <Icon name="unusedTicketPWA" color="success" size="small" />
        <Text variant="subHeadStrong" color="mainText">
          {title}
        </Text>
      </Header>
      <Description>
        <Text variant="subHeadMedium" color="mainText">
          {description}
        </Text>
      </Description>
      <Text variant="subHeadMedium" color="bodyText">
        {note}
      </Text>
    </ExplanationContainer>
  )
}
