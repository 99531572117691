import type { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { Icon } from '@etta/ui/icon'
import {
  LeadingElement,
  SearchField,
  SearchFieldContainer,
  StyledIconButton,
} from './search-input-styled'

type Props = {
  isResultsListOpen: boolean
  searchInputRef: MutableRefObject<HTMLInputElement>
  containerProps: object
  inputProps: object
  isDisabled: boolean
  placeholder: string
  value: string
  onReset: () => void
}

export function SearchInput({
  isResultsListOpen,
  searchInputRef,
  containerProps,
  inputProps,
  isDisabled,
  placeholder,
  value,
  onReset,
}: Props) {
  const { t } = useTranslation()

  return (
    <SearchFieldContainer {...containerProps} isOpen={isResultsListOpen}>
      <LeadingElement textVariant={StyledTextVariant.footnoteMedium} isOpen={isResultsListOpen}>
        <Icon name="searchPWA" />
      </LeadingElement>
      <SearchField
        ref={searchInputRef}
        {...inputProps}
        disabled={isDisabled}
        placeholder={placeholder}
        aria-label={t('TripPlanner.BaseSearch.Input.PlaceAriaLabel', {
          label: placeholder,
        })}
      />
      {value && (
        <StyledIconButton
          width={40}
          height={40}
          onClick={onReset}
          color="bodyText"
          icon="closeFilledPWA"
        />
      )}
    </SearchFieldContainer>
  )
}
