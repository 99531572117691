import styled, { css } from 'styled-components'
import {
  linkNormal,
  linkSmall,
  linkCaption,
  linkMedium,
  KeyboardNavigationStyle,
} from '@fiji/style'
import type { TextColor } from '@etta/ui/text/types'
import type { MobileLinkSize } from './types'

const linkDisabledStyle = css<{ disabledLinkColor: TextColor }>`
  cursor: default;
  color: ${({ theme, disabledLinkColor }) => theme.colors[disabledLinkColor]};
`

export const LinkWrapper = styled.a<{
  size?: MobileLinkSize
  isDisabled?: boolean
  disabledLinkColor: TextColor
}>`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  ${KeyboardNavigationStyle}

  ${(props) => {
    switch (props.size) {
      default:
      case 'normal':
        return linkNormal
      case 'small':
        return linkSmall
      case 'caption':
        return linkCaption
      case 'medium':
        return linkMedium
    }
  }}

  ${(p) => {
    if (p.isDisabled) {
      return linkDisabledStyle
    }
  }}
`
