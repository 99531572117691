import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { PassportErrors } from '../../core/errors'
import type { PassportResults, RemovePassportArgs, AddPassportArgs } from './types'
import { PassportDataProvider } from './passport.data-provider'
import { PassportMapper } from './mappers'

@Adapter()
export class PassportAdapter {
  constructor(
    @Inject()
    private readonly passportDataProvider: PassportDataProvider,
  ) {}

  async addPassport(
    input: AddPassportArgs,
  ): PassportResults<{ code: string; message: string; success: boolean }> {
    try {
      const { data, errors } = await this.passportDataProvider.addPassport(input)

      if (data?.addPassport.__typename === 'BaseResponse' && data.addPassport.success) {
        return Result.Ok(data.addPassport)
      }

      if (data?.addPassport.__typename === 'InvalidInputError') {
        return Result.Err(
          new PassportErrors.InvalidInputError(
            data.addPassport.fields.map(PassportMapper.toFieldErrorValueObject),
          ),
        )
      }

      if (errors) {
        return Result.Err(new PassportErrors.AddPassportError(errors))
      }

      return Result.Err(new PassportErrors.AddPassportError(data?.addPassport.message))
    } catch (e) {
      return Result.Err(new PassportErrors.UnexpectedAddPassportError(e))
    }
  }

  async removePassport(
    input: RemovePassportArgs,
  ): PassportResults<{ code: string; message: string; success: boolean }> {
    try {
      const { data, errors } = await this.passportDataProvider.removePassport(input)

      if (errors) {
        return Result.Err(new PassportErrors.RemovePassportError(errors))
      }

      if (!data || !data?.removePassport.success) {
        return Result.Err(new PassportErrors.RemovePassportError(data?.removePassport.message))
      }

      return Result.Ok(data.removePassport)
    } catch (e) {
      return Result.Err(new PassportErrors.UnexpectedRemovePassportError(e))
    }
  }
}
