import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style'

export const Container = styled.div``

export const Content = styled.fieldset`
  width: 100%;
  border: none;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 36px;
  }
`

export const Title = styled.legend<{ isActive?: boolean }>`
  color: ${({ isActive, theme }) => (isActive ? theme.colors.mainText1 : theme.colors.bodyText)};
  margin-bottom: 12px;

  ${captionStrongCaps}
`
