import type { ComponentType } from 'react'
import { Icon } from '@etta/ui/icon'
import type { CovidStatisticItem } from '../../../../types'
import { Label, Value, Container } from './covid-information-statistic-item-styled'
import { useCovidInformationStatisticItem } from './use-covid-information-statistic-item'

type Props = CovidStatisticItem & {
  className?: string
  i18nBase: string
  element?: string | ComponentType<any>
}

export function CovidInformationStatisticItem(props: Props) {
  const { i18nBase, className, element = 'div' } = props
  const { label, value, isTrend, isGrowing } = useCovidInformationStatisticItem({
    i18nBase,
    item: props,
  })

  return (
    <Container
      data-tracking-id={`covid-info-container_${label}-${value}`}
      className={className}
      as={element}>
      <Label>{label}</Label>
      <Value>
        {isTrend && (
          <Icon
            name={isGrowing ? 'arrowUpwardPWA' : 'arrowDownwardPWA'}
            color={isGrowing ? 'error' : 'success'}
            size="small"
          />
        )}
        {value}
      </Value>
    </Container>
  )
}
