import { checkIsCookieExpired } from '../check-is-cookie-expired'
import { updateTokens } from './update-tokens'

const MIN_IN_MS = 60 * 1000
const DEFERRED_TIMEOUT_MS = MIN_IN_MS * 5
const AVAILABLE_RANGE_MS = MIN_IN_MS * 60 * 23 // 23 hours

let timerId: number

export function deferredTokenUpdate(): void {
  if (timerId) {
    window.clearTimeout(timerId)
  }

  const isCookieExpired = checkIsCookieExpired()
  const isCookieUpdateAvailable = checkIsCookieExpired(AVAILABLE_RANGE_MS)

  if (!isCookieExpired && isCookieUpdateAvailable) {
    updateTokens()
  }

  timerId = window.setTimeout(() => {
    deferredTokenUpdate()
  }, DEFERRED_TIMEOUT_MS * MIN_IN_MS)
}
