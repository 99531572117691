import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { ShareTravelerContactForm } from '../../share-traveler-contact-form/share-traveler-contact-form'

const i18nBase = 'TravelerInfo.ShareTravelerContact.'

export function ShareTravelerContactDesktop() {
  const { t } = useTranslation()

  return (
    <Block>
      <Block marginBottom={12}>
        <Text variant="subHeadStrong" color="mainText">
          {t(`${i18nBase}Title`)}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t(`${i18nBase}Description`)}
        </Text>
      </Block>
      <ShareTravelerContactForm />
    </Block>
  )
}
