import type { GetFlightFareAttributesQuery } from '@fiji/graphql/hooks'
import { OnTheFlyContentAssessment, OnTheFlyContentLocation } from '@fiji/graphql/hooks'
import type { GetFareAttributesInputValueObject } from '@etta/modules/air-search/core/value-objects/get-fare-attributes-input.value-object'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity/flight-segment.entity'
import type { FlightLegValueObject } from '@etta/core/value-objects/search-flight-leg.value-object'
import type { FlightFareAttributesLegsInput } from '@fiji/graphql/types'
import type {
  FareAttributesResponseValueObject,
  FareAttributesValueObject,
} from '../../core/value-objects/fare-attributes.value-object'
import {
  OnTheFlyContentAssessmentEnum,
  OnTheFlyContentLocationEnum,
} from '../../core/value-objects/fare-attributes.value-object'

// mapping from GQL type to value object
export function toFareAttributesResponseValueObject(
  gqlFareAttributesResp: GetFlightFareAttributesQuery['flightFareAttributesInBatch'],
): FareAttributesResponseValueObject[] {
  return gqlFareAttributesResp.map((resp) => {
    return {
      legId: resp.legId,
      fareAttributes: resp.fareAttributes.map(toFareAttributesValueObject),
    }
  })
}

function toFareAttributesValueObject(
  fareAttribute: GetFlightFareAttributesQuery['flightFareAttributesInBatch'][0]['fareAttributes'][0],
): FareAttributesValueObject {
  return {
    fareId: fareAttribute.fareId,
    comparison: {
      brand: fareAttribute.comparison.brand,
      utas: fareAttribute.comparison.utas.map((uta) => {
        return {
          assessment: toOnTheFlyContentAssessmentEnum(uta.assessment),
          category: uta.category,
          description: uta.description,
        }
      }),
    },
    content: fareAttribute.content.map((content) => {
      return {
        items: content.items,
        linkIconClass: content.linkIconClass,
        location: toOnTheFlyContentLocationEnum(content.location),
        segmentIndex: content.segmentIndex,
      }
    }),
  }
}

function toOnTheFlyContentAssessmentEnum(
  gqlEnum: OnTheFlyContentAssessment,
): OnTheFlyContentAssessmentEnum {
  switch (gqlEnum) {
    case OnTheFlyContentAssessment.Benefit:
      return OnTheFlyContentAssessmentEnum.Benefit
    case OnTheFlyContentAssessment.Fee:
      return OnTheFlyContentAssessmentEnum.Fee
    case OnTheFlyContentAssessment.Neutral:
      return OnTheFlyContentAssessmentEnum.Neutral
    case OnTheFlyContentAssessment.Restriction:
      return OnTheFlyContentAssessmentEnum.Restriction
    default:
      return OnTheFlyContentAssessmentEnum.Unspecified
  }
}

function toOnTheFlyContentLocationEnum(
  gqlEnum: OnTheFlyContentLocation | undefined | null,
): OnTheFlyContentLocationEnum | undefined {
  if (!gqlEnum) {
    return undefined
  }

  switch (gqlEnum) {
    case OnTheFlyContentLocation.Carrier:
      return OnTheFlyContentLocationEnum.Carrier
    case OnTheFlyContentLocation.Destination:
      return OnTheFlyContentLocationEnum.Destination
    case OnTheFlyContentLocation.Details:
      return OnTheFlyContentLocationEnum.Details
    case OnTheFlyContentLocation.Features:
      return OnTheFlyContentLocationEnum.Features
    case OnTheFlyContentLocation.Header:
      return OnTheFlyContentLocationEnum.Header
    case OnTheFlyContentLocation.Origin:
      return OnTheFlyContentLocationEnum.Origin
    default:
      return OnTheFlyContentLocationEnum.Unspecified
  }
}

// mapping from value object to GQL type
export function toGQLOnTheFlyContentAssessment(
  enumVO: OnTheFlyContentAssessmentEnum,
): OnTheFlyContentAssessment {
  switch (enumVO) {
    case OnTheFlyContentAssessmentEnum.Benefit:
      return OnTheFlyContentAssessment.Benefit
    case OnTheFlyContentAssessmentEnum.Fee:
      return OnTheFlyContentAssessment.Fee
    case OnTheFlyContentAssessmentEnum.Neutral:
      return OnTheFlyContentAssessment.Neutral
    case OnTheFlyContentAssessmentEnum.Restriction:
      return OnTheFlyContentAssessment.Restriction
    default:
      return OnTheFlyContentAssessment.Unspecified
  }
}

export const getFlightFareAttributesInput = (
  searchFlightLegs: FlightLegValueObject[],
  itineraryId?: string,
): GetFareAttributesInputValueObject => {
  const legs: FlightFareAttributesLegsInput[] = searchFlightLegs.map((searchFlightLeg) => {
    return {
      id: searchFlightLeg.legId,
      segments: searchFlightLeg.segments.map((segment) => ({
        id: segment.id,
        origin: {
          code: segment.origin.code,
        },
        destination: {
          code: segment.destination.code,
        },
        departureDate: segment.departureDate,
        flightNumber: segment.flightNumber,
        carrier: {
          id: segment.carrier?.id ?? '',
          name: segment.carrier?.name ?? '',
        },
      })),
      fares: searchFlightLeg.fares.map((fare, index) => ({
        id: String(index),
        segments:
          fare.segments?.map((fareSegment) => ({
            segmentId: fareSegment.segmentId,
            basisCode: fareSegment.basisCode || '',
            serviceClass: fareSegment.serviceClass.toUpperCase(),
          })) ?? [],
      })),
    }
  })

  return {
    legs,
    logsMetadata: {
      itineraryId,
    },
  }
}

// This is for flight in RTP and post booking that has no fares
export function getFlightFareAttributesInputForOneFlight(
  flights: Pick<FlightSegmentEntity, 'segments' | 'legId'>[],
  itineraryId?: string,
): GetFareAttributesInputValueObject {
  return {
    legs: flights.map((flight) => {
      const { segments } = flight
      return {
        id: flight.legId,
        segments: segments.map((segment) => ({
          id: segment.segmentId || '',
          origin: {
            code: segment.departure.airportCode ?? '',
          },
          destination: {
            code: segment.arrival.airportCode ?? '',
          },
          departureDate: segment.departureDate,
          flightNumber: segment.flightNumber,
          carrier: {
            id: segment.carrierCode ?? '',
            name: segment.carrierCode ?? '',
          },
        })),
        fares: segments.map((_, i) => ({
          id: String(i),
          segments:
            segments?.map((segment) => ({
              segmentId: segment.segmentId || '',
              basisCode: segment.fare || '',
              serviceClass: segment.serviceClass.toUpperCase(),
            })) ?? [],
        })),
      }
    }),
    logsMetadata: {
      itineraryId,
    },
  }
}
