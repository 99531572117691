import type { LayoutProps } from './types'
import { RoomDetailsLayout } from './layout'

export function RoomDetails({
  checkIn,
  checkOut,
  handleOpenDetails,
  roomDetails,
  isUpcoming,
}: LayoutProps) {
  return (
    <RoomDetailsLayout
      handleOpenDetails={handleOpenDetails}
      checkIn={checkIn}
      checkOut={checkOut}
      roomDetails={roomDetails}
      isUpcoming={isUpcoming}
    />
  )
}
