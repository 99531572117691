import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { HotelCard } from '@etta/components/hotel-card/hotel-card'
import { HotelRateCap } from '@etta/components/hotel-rate-cap/hotel-rate-cap'
import { Pagination } from '@etta/ui/pagination'
import { Swap } from '@etta/ui/swap'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import type { LayoutProps } from '../types'
import { getHotelEmission } from '../../../../use-hotel-result'
import {
  Container,
  Footer,
  List,
  RightBlock,
  SkeletonContainer,
} from './hotel-list-results-desktop-styled'
import { DefaultSkeletons, PageSkeletons } from './skeletons'
import { useHotelListResultsDesktop } from './use-hotel-list-results-desktop'

export const HotelListResultsDesktop = observer(function HotelListResultsDesktop({
  headerSlot,
  handleOpen,
  hotelsEmissions,
  selectedHotelId,
}: LayoutProps) {
  const { hotelSearchResultsStore } = useHotelSearchResultsContext()

  const { t } = useTranslation()
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const {
    firstIndex,
    lastIndex,
    isFooterVisible,
    currentPage,
    totalPages,
    isSkeletonVisible,
    handlePageChange,
    isPageSkeletonVisible,
    hotelsCount,
  } = useHotelListResultsDesktop({
    anchorRef,
  })

  return (
    <>
      <div ref={anchorRef} />
      <Container>
        {headerSlot}
        <HotelRateCap perDiemRateCap={hotelSearchResultsStore.hotelResults?.perDiemRate} />
        <Swap
          is={isSkeletonVisible}
          isSlot={
            <SkeletonContainer>
              {isPageSkeletonVisible ? PageSkeletons : DefaultSkeletons}
            </SkeletonContainer>
          }>
          <List>
            {hotelSearchResultsStore.hotels.map((hotel) => {
              const isSelected = selectedHotelId === hotel.id

              return (
                <HotelCard
                  key={hotel.id}
                  hotel={hotel}
                  onClick={handleOpen}
                  as="li"
                  isSelected={isSelected}
                  hotelEmission={getHotelEmission({ hotelId: hotel.id, hotelsEmissions })}
                />
              )
            })}
          </List>
        </Swap>

        {isFooterVisible && (
          <Footer>
            <Text variant="footnoteMedium" color="bodyText">
              {t('SearchResults.Hotels.ShowingOfHotels', {
                firstIndex,
                lastIndex,
                totalHotels: hotelsCount,
              })}
            </Text>
            <RightBlock>
              <Pagination value={currentPage} total={totalPages} onChange={handlePageChange} />
            </RightBlock>
          </Footer>
        )}
      </Container>
    </>
  )
})
