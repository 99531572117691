import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import type { CarSearchQueryType } from './types'

export function useCarSearchQuery() {
  const {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  } = useQueryParams<CarSearchQueryType>(
    {
      pickUpTime: { hours: 9, minutes: 0 },
      dropOffTime: { hours: 17, minutes: 0 },
      carTypes: [],
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: [
        'carTypes',
        'allowedVendors',
        'carClass',
        'carTransmission',
        'carVendor',
        'carFuel',
      ],
    },
  )

  return {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  }
}
