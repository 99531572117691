import type { ReactNode, RefObject } from 'react'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { Modal } from '@etta/ui/modal'
import { ModalDetails } from '@etta/components/modal-details'
import { ModalHeader } from './modal-header'
import { BodyContainer } from './dynamic-site-messages-modal-styled'

type Props = {
  title: string
  isOpen: boolean
  color: LayoutBackgroundColor
  scrollContainerRef: RefObject<HTMLDivElement>
  children: ReactNode
  onClose: () => void
}

export function DynamicSiteMessagesModal({
  title,
  isOpen,
  color,
  scrollContainerRef,
  onClose,
  children,
}: Props) {
  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <ModalHeader title={title} backgroundColor={color} />
      <Modal.Body ref={scrollContainerRef}>
        <BodyContainer>{children}</BodyContainer>
      </Modal.Body>
    </ModalDetails>
  )
}
