import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import { UnusedTicketExplanationMobile } from './mobile'
import { UnusedTicketExplanationDesktop } from './desktop'

type Props = {
  isWideSettingsModal?: boolean
}

const i18nBase = 'UnusedTickets.Hint'

export function UnusedTicketExplanationLayout({ isWideSettingsModal }: Props) {
  const { t } = useTranslation()

  const title = t(`${i18nBase}.WhatIsThis`)
  const description = t(`${i18nBase}.Description`)
  const note = t(`${i18nBase}.Note`)

  const ariaLabel = [title, description, note].join(', ')

  const args = useMemo(() => ({ title, description, note, ariaLabel, isWideSettingsModal }), [
    title,
    description,
    note,
    ariaLabel,
    isWideSettingsModal,
  ])

  return (
    <MediaLayout
      mobileSlot={<UnusedTicketExplanationMobile {...args} />}
      desktopSlot={<UnusedTicketExplanationDesktop {...args} />}
    />
  )
}
