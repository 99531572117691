import { Header } from '@etta/ui/header'
import type { Props } from '../../types'

export function FlightDetailsHeaderMobile({ headerRef, title, onClose }: Props) {
  return (
    <Header
      data-tracking-id="segment-details-modal-header"
      headerRef={headerRef}
      withBorderBottom
      backgroundColor="background"
      layout="aligned"
      animation="opacity"
      leftSlot={<Header.CloseButton onClick={onClose} />}>
      <Header.Title title={title} align="left" />
    </Header>
  )
}
