export type ModalContent = {
  isVisible: boolean
  header: string
  text: string
}

export const enum PartnerCoBrandingConfigModalType {
  Privacy = 'privacy',
  Terms = 'terms',
}

export type ModalType = typeof PartnerCoBrandingConfigModalType[keyof typeof PartnerCoBrandingConfigModalType]
