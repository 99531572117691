import { Icon } from '@etta/ui/icon'
import { FlightTitleAirport, FlightTitleContainer } from '../rtp-eco-check-modal-mobile-styled'

type Props = {
  origin?: string | null
  destination?: string | null
}

export function RtpEcoCheckModalFlightTitle({ origin, destination }: Props) {
  return (
    <FlightTitleContainer>
      <FlightTitleAirport>{origin}</FlightTitleAirport>
      <Icon name="flightArrowPWA" />
      <FlightTitleAirport>{destination}</FlightTitleAirport>
    </FlightTitleContainer>
  )
}
