import { Description, NonFilled } from './expanded-preferences-styled'

type Props = {
  description?: string | null
  nonFilledLabel: string
}

export function Preference({ description, nonFilledLabel }: Props) {
  return (
    <Description aria-label={description || nonFilledLabel}>
      {description || <NonFilled>{nonFilledLabel}</NonFilled>}
    </Description>
  )
}
