import type { TimeRange } from '@fiji/types'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'

export const railTimeRangeOptions = {
  [TimeRangeOption.CustomTime]: {
    id: TimeRangeOption.CustomTime,
    i18next: 'RailSearch.TimePicker.CustomTime',
    startTimeRange: '08:00:00',
    endTimeRange: '09:00:00',
    customTimeHours: 8,
  },
}

export const initialCustomTime = railTimeRangeOptions[TimeRangeOption.CustomTime]

export const customOriginTime: TimeRange = {
  ...railTimeRangeOptions[TimeRangeOption.CustomTime],
  timeRangeBy: TimeRangeDirection.Departure,
}

export const customDestinationTime: TimeRange = {
  ...railTimeRangeOptions[TimeRangeOption.CustomTime],
  timeRangeBy: TimeRangeDirection.Arrival,
  startTimeRange: '9:00:00',
  endTimeRange: '11:00:00',
  customTimeHours: 9,
}

export const anyTimeOption: TimeRange = {
  ...railTimeRangeOptions[TimeRangeOption.CustomTime],
  timeRangeBy: TimeRangeDirection.Departure,
}
