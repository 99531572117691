import { PickerDialog } from '../picker-dialog'
import type { DatePickerProps } from './types'
import { DatePicker } from './date-picker'

type Props = DatePickerProps & {
  onClose: () => void
  isVisible: boolean
}

export function SwipingDatePicker({ isVisible, onClose, onApply, ...props }: Props) {
  return (
    <PickerDialog isVisible={isVisible} onClose={onClose}>
      <DatePicker
        {...props}
        onApply={(value: Date) => {
          onApply(value)
          onClose()
        }}
      />
    </PickerDialog>
  )
}
