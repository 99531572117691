import { observer } from 'mobx-react-lite'
import { MobilityBookingErrorWithMessage } from './components/mobility-booking-error-with-message'
import { MobilityBookingSuccess } from './components/mobility-booking-success'
import { MobilityBookingLoading } from './components/mobility-booking-loading'
import { MobilityBookingError } from './components/mobility-booking-error'
import { useMobilityConfirmation, useTrackMobilityConfirmation } from './hooks'

const animationTimeout = 3500

export const MobilityConfirmationPage = observer(function MobilityConfirmationPage() {
  const {
    showAnimation,
    bookingError,
    handleDone,
    bookingErrorMessage,
    isShowUberExternalLink,
    isOnDemandRide,
  } = useMobilityConfirmation(animationTimeout)
  useTrackMobilityConfirmation({ showAnimation })

  if (showAnimation) {
    return <MobilityBookingLoading />
  }

  if (!bookingError) {
    return <MobilityBookingSuccess handleDone={handleDone} isOnDemandRide={isOnDemandRide} />
  }

  if (!bookingErrorMessage) {
    return <MobilityBookingError />
  }

  return (
    <MobilityBookingErrorWithMessage
      errorMessage={bookingErrorMessage}
      isShowUberExternalLink={isShowUberExternalLink}
    />
  )
})
