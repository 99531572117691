// eslint-disable-next-line import/no-restricted-paths
import type { CarRentalTravelPreferenceInput, GazooMembership } from '@fiji/graphql/hooks'
import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { VendorEntity } from '../../core/entities/available-vendors.entity'
import type { SpecialRequestEntity } from '../../core/entities/available-special-requests.entity'
import { ValidatorMaker } from '../../validators/validator-maker'
import { FieldSettingsStore } from './field-settings'

@Store()
export class CarRentalPreferencesStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  isSaveButtonDisabled = true

  toggle = new Toggle()
  carMembershipToggle = new Toggle()
  carMembershipRemoveToggle = new Toggle()

  availableCarVendors: VendorEntity[] | null = null
  carRentalAvailableSpecialRequests: SpecialRequestEntity[] | null = null

  carMembership = {
    vendorCode: '',
    number: '',
  }
  isCarAvailableVendorsLoading = false
  isEditExistingProgramMode: boolean = false
  isAvailableCarRentalSpecialRequestsLoading: boolean = false

  carRentalPreferencesValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeCarrentalPreferencesValidator()

  get isViewingExistingMembership(): Boolean {
    const { vendorCode } = this.carMembership
    const carMemberships = this.carRentalPreferencesValidator.values.membership as GazooMembership[]
    return !!carMemberships.find((item) => item.vendorCode === vendorCode)
  }

  setCarRentalPreferencesValue<
    T extends keyof Omit<CarRentalTravelPreferenceInput, 'carClassWithNone'>
  >(field: T, value: CarRentalTravelPreferenceInput[T]) {
    this.carRentalPreferencesValidator.onFieldChange(field, value)
  }

  setCarRentalPreferencesInput(input: CarRentalTravelPreferenceInput) {
    this.setCarRentalPreferencesValue('carClass', input.carClass)
    this.setCarRentalPreferencesValue('specialRequest', input.specialRequest)
    this.setCarRentalPreferencesValue('membership', input.membership)
  }

  setCarMembership(input: { vendorCode: string; number: string }) {
    this.carMembership = input
  }

  setAvailableCarVendors(availableVendors: VendorEntity[] | null) {
    this.availableCarVendors = availableVendors
  }

  setIsCarAvailableVendorsLoading(input: boolean) {
    this.isCarAvailableVendorsLoading = input
  }

  setCarRentalAvailableSpecialRequests(specialRequests: SpecialRequestEntity[] | null) {
    this.carRentalAvailableSpecialRequests = specialRequests
  }

  setIsEditExistingProgramMode(isEditMode: boolean) {
    this.isEditExistingProgramMode = isEditMode
  }

  setIsAvailableCarRentalSpecialRequestsLoading(isLoading: boolean) {
    this.isAvailableCarRentalSpecialRequestsLoading = isLoading
  }

  resetCarMembership() {
    this.carMembership = {
      vendorCode: '',
      number: '',
    }
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
