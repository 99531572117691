import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Container, TitleContainer } from './expired-modal-styled'

type Props = {
  onLogout: () => void
}

const i18nBase = 'SessionModal.Expired'

export function ExpiredModal({ onLogout }: Props) {
  const { t } = useTranslation()
  const descriptionLines = t(`${i18nBase}.Description`).split('\n')

  return (
    <Container>
      <TitleContainer>
        <Text variant="title3" color="mainText" align="center">
          {t(`${i18nBase}.Title`)}
        </Text>
      </TitleContainer>
      <Block marginBottom={8}>
        {descriptionLines.map((descriptionLine) => (
          <Block marginBottom={16}>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {descriptionLine}
            </Text>
          </Block>
        ))}
      </Block>
      <Button onClick={onLogout} fullWidth>
        {t(`${i18nBase}.SignIn`)}
      </Button>
    </Container>
  )
}
