import { Inject, Service } from '@etta/di'
import { AirSearchStore } from '@etta/modules/air-search/interface/stores/air-search/air-search.store'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import { ActiveTripStore } from '../stores/active-trip.store'

@Service()
export class RemoveTripService {
  constructor(
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly activeTripStore: ActiveTripStore,
    @Inject()
    private readonly airSearchStore: AirSearchStore,
  ) {}

  removeTrip() {
    this.removeItineraryStore()
    this.airSearchStore.dropStore()
  }

  removeActiveTrip() {
    this.removeItineraryStore()
  }

  private removeItineraryStore() {
    this.reviewTripStore.setIsTripRemovingError(false)
    this.activeTripStore.clearStore()
    this.activeTripStore.dropCurrentlyReactivatedID()
  }
}
