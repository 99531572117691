import { useCallback, useEffect, useState } from 'react'
import { useUpdateTripNameByProcessIdMutation } from '@fiji/graphql/hooks'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'

type Args = {
  name: string
  processId: string
}

export function useEditTripName({ processId, name }: Args) {
  const { tripsListActions } = useTripsListContext()
  const [tripName, setTripName] = useState(name)
  const [updateTripName] = useUpdateTripNameByProcessIdMutation()
  const editNameToggle = useTogglePopup()

  useEffect(() => {
    setTripName(name)
  }, [name])

  const handleUpdateTripName = useCallback(
    async (value: string) => {
      const name = value.trim()
      const res = await updateTripName({
        variables: {
          input: {
            name,
            processId,
          },
        },
      })
      if (!res.data?.updateTripNameByProcessId.success) {
        throw new Error(res.data?.updateTripNameByProcessId.message)
      }
      setTripName(name)
      tripsListActions.dropAndReloadAllTrips()
    },
    [updateTripName, processId, tripsListActions],
  )

  return { editNameToggle, handleUpdateTripName, tripName }
}
