import { useAirportChangeText } from '@fiji/hooks/use-airport-change-text'
import type { AirportChangeTextProps } from '@fiji/hooks/use-airport-change-text/types'

type Props = {
  flightLegSegments?: AirportChangeTextProps['flightLegSegments']
  size: AirportChangeTextProps['size']
}

export function useAirportChangeBadge({ flightLegSegments, size }: Props) {
  const { changeText: badgeText } = useAirportChangeText({
    flightLegSegments,
    size,
  })

  return {
    badgeText,
  }
}
