import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import type { RailOpenReturnDetailsProps } from '../../types'
import { Container, List, ListItem, Header } from '../../rail-open-return-details-styled'

const i18Base = 'ReviewTrip.OpenReturn.'

export function RailOpenReturnDetailsDesktop({
  serviceClassName,
  ticketInformationText,
  refundableText,
  onViewRestrictionsClick,
}: RailOpenReturnDetailsProps) {
  const { t } = useTranslation()
  return (
    <Container
      onClick={(e) => {
        e.stopPropagation() // stop parent event from opening detail modal
      }}>
      <Header>
        <Text variant="footnoteStrong" color="mainText">
          {t(`${i18Base}DetailText`)}
        </Text>
        <Link size="small" onClick={onViewRestrictionsClick}>
          {t(`${i18Base}ButtonRestrictionsText`)}
        </Link>
      </Header>
      <List>
        {serviceClassName && <ListItem>{serviceClassName}</ListItem>}
        {ticketInformationText && <ListItem>{ticketInformationText}</ListItem>}
      </List>
      <ListItem>{t(`ReviewTripPWA.Details.PenaltyType.${refundableText}`)}</ListItem>
    </Container>
  )
}
