import { useTranslation } from 'react-i18next'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { DelegateModal } from '@etta/components/delegate-modal/delegate-modal'
import { DelegateItem } from '@etta/components/delegate-item/delegate-item'
import { SwitchErrorState } from '../../delegate-switch-error-state'
import type { DelegateSwitchMobileLayoutProps } from '../../types'
import { DelegatesContainer } from './delegate-switch-mobile.styled'

export function DelegateSwitchMobile({
  isDelegatesLoading,
  isDelegatesExist,
  isDelegateModalOpen,
  handleCloseSwitchErrorModal,
  handleDelegateItemClick,
  handleSelectDelegateMobile,
  handleDelegateModalClose,
  delegatedUsers,
  viewState,
  selectedDelegate,
  fullName,
  layout,
}: DelegateSwitchMobileLayoutProps) {
  const { t } = useTranslation()

  if (!isDelegatesLoading && !isDelegatesExist) {
    return null
  }

  return (
    <>
      <DelegatesContainer
        aria-label={t('Delegate.DelegateSwitchLabel', { name: fullName })}
        layout={layout}>
        <DelegateItem
          delegate={selectedDelegate}
          onClick={handleDelegateItemClick}
          isLoading={isDelegatesLoading}
          layout={layout}
        />
      </DelegatesContainer>

      <DelegateModal
        isLoading={isDelegatesLoading}
        delegates={delegatedUsers}
        isModalVisible={isDelegateModalOpen}
        handleClose={handleDelegateModalClose}
        handleSelectDelegate={handleSelectDelegateMobile}
      />

      <ViewStateModal viewState={viewState} onDone={handleCloseSwitchErrorModal}>
        <SwitchErrorState onClose={handleCloseSwitchErrorModal} />
      </ViewStateModal>
    </>
  )
}
