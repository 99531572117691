import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { RangeResult } from '../types'
import { changeArrivalDate, changeReturnDate } from './change-date'

export function convertFlightToRangePostBooking(
  flightSegments: FlightPostBookingValueObject[],
): RangeResult {
  if (!flightSegments.length || !flightSegments[0].segments) {
    return {}
  }

  const destinationSegment = flightSegments[0].segments[flightSegments[0].segments.length - 1]
  const lastFlightSegment = flightSegments[flightSegments.length - 1]
  const firstFlightInLast = lastFlightSegment.segments?.[0]
  const lastSegment = lastFlightSegment.segments?.[lastFlightSegment.segments.length - 1]

  const isRoundTrip =
    flightSegments.length > 1 &&
    flightSegments[0].segments[0].departure?.airportCode === lastSegment?.arrival?.airportCode

  const from = destinationSegment.arrival
    ? {
        city: destinationSegment.arrival.address?.city || '',
        countryCode: destinationSegment?.arrival.address?.countryCode || '',
        ...destinationSegment.arrival.address,
        airportCode: destinationSegment.arrival.airportCode,
      }
    : undefined

  const to = destinationSegment.arrival
    ? {
        city: destinationSegment.arrival.address?.city || '',
        countryCode: destinationSegment?.arrival.address?.countryCode || '',
        ...destinationSegment.arrival.address,
        airportCode: destinationSegment.arrival.airportCode,
      }
    : undefined

  return {
    from,
    to,
    ...(isRoundTrip
      ? {
          startDate: destinationSegment?.arrivalDate
            ? changeArrivalDate(destinationSegment.arrivalDate)
            : undefined,
          endDate: firstFlightInLast?.departureDate
            ? changeReturnDate(firstFlightInLast.departureDate)
            : undefined,
        }
      : {
          startDate: flightSegments[0].arrivalDateTime
            ? changeArrivalDate(flightSegments[0].arrivalDateTime)
            : undefined,
          endDate: undefined,
        }),
  }
}
