import { useTranslation } from 'react-i18next'
import { Button } from './pagination-styled'

type PaginationButtonProps = {
  page: number
  selectedPage: number
  total: number
  handleChange: (value: number) => void
}

export const PaginationButton = ({
  page,
  selectedPage,
  total,
  handleChange,
}: PaginationButtonProps) => {
  const { t } = useTranslation()
  const i18nBase = `Pagination`

  return (
    <Button
      key={page}
      aria-label={
        selectedPage === page
          ? t(`${i18nBase}.SelectedPage`, { page, total })
          : t(`${i18nBase}.GotoPage`, { page, total })
      }
      selected={selectedPage === page}
      onClick={() => handleChange(page)}>
      {page}
    </Button>
  )
}
