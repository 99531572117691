import styled from 'styled-components'
import { screenSize } from '@fiji/style/screen-sizes'

export const Container = styled.div`
  display: block;
  background: #f8f8f8;
  min-height: 110px;
  position: relative;

  @media (${screenSize.maxTablet}) {
    min-height: 90px;
  }
`

export const WrappedContainer = styled.div<{ isHeaderVisible: boolean }>`
  height: 110px;
  margin: 0 auto;

  @media (${screenSize.maxTablet}) {
    height: 90px;
    ${(props) => !props.isHeaderVisible && 'display: none;'}
  }
`

export const SkeletonContainer = styled(Container)`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`
