import type * as H from 'history'
import { Service } from '@etta/di'

@Service()
export class RouterHistory<T = unknown> {
  private historyInstance: null | H.History<T> = null

  setHistory(history: H.History<T>) {
    if (this.historyInstance !== history) {
      this.historyInstance = history
    }
  }

  get history() {
    return this.historyInstance
  }
}
