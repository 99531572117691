import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'

export const UpperContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
`

export const Container = styled.div<{ isSelected?: boolean; isDisabled?: boolean }>`
  border-radius: 14px;
  border: 2px solid ${(p) => (p.isSelected ? p.theme.colors.primary : p.theme.colors.white)};
  margin-bottom: 17px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ${(p) =>
    p.isDisabled &&
    css`
      border: 2px solid ${(p) => p.theme.colors.borderDark};

      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    `}
`

export const FlexBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextBlock = styled(Text)`
  display: block;
  margin-bottom: 8px;
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  height: 100%;
`
