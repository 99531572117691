import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate } from '@fiji/graphql/types'

type Args = {
  rate?: Rate | null
  taxes?: Rate | null
  total?: Rate | null
  transactionFees?: Rate | null
}

const i18nBase = 'PostBooking.TrainDetails.Price'

export function usePrice({ rate, taxes, total, transactionFees }: Args) {
  const { t } = useTranslation()

  const { mainCost: formattedTotal } = formatRate(total, { morpheme: 'none' })

  const priceDetailsList = useMemo(() => {
    return [
      {
        title: t(`${i18nBase}.TrainFare`),
        value: formatRate(rate, { morpheme: 'none' }).mainCost,
        titleTestId: 'city-pair-text',
        valueTestId: 'cost-text',
      },
      {
        title: t(`${i18nBase}.Taxes`),
        value: formatRate(taxes, { morpheme: 'none' }).mainCost,
        titleTestId: 'taxes-text',
        valueTestId: 'tax-amount-text',
      },
      {
        title: t(`${i18nBase}.TransactionFee`),
        value: formatRate(transactionFees, { morpheme: 'none' }).mainCost,
        titleTestId: 'transaction-fee-text',
        valueTestId: 'transaction-fee-amount-text',
      },
    ]
  }, [rate, t, taxes, transactionFees])

  return {
    priceDetailsList,
    formattedTotal,
  }
}
