import styled, { css } from 'styled-components'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll'

import { KeyboardNavigationStyle, subHeadMedium } from '@fiji/style'

export const Separator = styled.div`
  position: sticky;
  top: 56px;
  border-bottom: 1px solid transparent;

  ${createHeaderScrollAnimation(
    'search-mechanism-separator',
    css`
      0% {
        border-bottom-color: transparent;
      }
      1%,
      100% {
        border-bottom-color: ${(props) => props.theme.colors.secondary};
      }
    `,
  )}
`

export const MobileBackButton = styled.button`
  width: 44px;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  background: transparent;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  height: 40px;
  margin-right: 8px;

  & > div {
    width: 16px;
    height: 16px;
  }
  ${KeyboardNavigationStyle}
`

export const ResultsListContainer = styled.ul<{ isOpen: boolean }>`
  top: 100%;
  width: 100%;
  padding: 0 0 10px 0;
  margin: 0;
  list-style: none;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0 0 7px 7px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  position: static;
  box-shadow: none;

  ${(p) =>
    p.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const CurrentLocationTitle = styled.span<{ withBorder?: boolean }>`
  padding: 13px 18px 13px 0;
  border-bottom: 1px solid
    ${({ theme, withBorder }) => (withBorder ? theme.colors.background1 : 'transparent')};
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-grow: 1;
  ${subHeadMedium};
`
