import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Search } from '@etta/ui/search'
import { LocationPicker } from '@etta/components/location-picker/location-picker'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { StyledTextVariant } from '@fiji/enums'
import type { LayoutProps } from '../../types'
import { ErrorText } from '../../../air-search-form-styled'
import {
  ErrorBlockWrapper,
  LocationPickerWrapper,
  StyledErrorBlock,
  Wrapper,
} from './location-section-desktop-layout-styled'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationSectionDesktopNew = observer(function LocationSectionDesktopNew({
  originPlace,
  destinationPlace,
  onSwitch,
  onOriginPlaceChange,
  onDestinationPlaceChange,
  placesHistory,
  isDisabled,
  flightType,
  isErrorInput,
  validationErrors,
  isLoading,
}: LayoutProps) {
  const { t } = useTranslation()

  const shouldTruncateLocationPickerInputValueAt = flightType === FlightType.Round ? 17 : 25
  const origin = originPlace?.airportCode || originPlace?.name
  const originVoice = originPlace?.name
  const destination = destinationPlace?.airportCode || destinationPlace?.name
  const destinationVoice = destinationPlace?.name

  return (
    <Wrapper>
      <Search.Group>
        <LocationPickerWrapper>
          <LocationPicker
            recentSearchesType="flight"
            label={t(`${i18Base}.Input.From`)}
            searchType="air"
            value={originPlace}
            latestPlaces={placesHistory}
            isForSearchHeader
            isOutboundTrip
            hasCurrentLocationSearch
            disabled={isDisabled}
            onChange={onOriginPlaceChange}
            inputSlot={
              <Search.Element
                wrapperAriaLabel={
                  originVoice
                    ? t(`${i18Base}.Input.DepartingFrom`, { origin: originVoice })
                    : t(`${i18Base}.Input.FromAirport`)
                }
                labelTitle={t(`${i18Base}.Input.From`)}
                value={origin}
                shouldTruncateValueAt={shouldTruncateLocationPickerInputValueAt}
                errorMessage={validationErrors.originPlace}
                isDisabled={isDisabled}
                isShimmerLoading={isLoading}
              />
            }
          />
        </LocationPickerWrapper>
        <Search.SwapLocationButton isCentered onClick={onSwitch} isDisabled={isDisabled} />
        <LocationPickerWrapper>
          <LocationPicker
            recentSearchesType="flight"
            label={t(`${i18Base}.Input.To`)}
            searchType="air"
            value={destinationPlace}
            latestPlaces={placesHistory}
            isForSearchHeader
            hasCurrentLocationSearch
            disabled={isDisabled}
            onChange={onDestinationPlaceChange}
            inputSlot={
              <Search.Element
                wrapperAriaLabel={
                  destinationVoice
                    ? t(`${i18Base}.Input.ArrivingAt`, { destination: destinationVoice })
                    : t(`${i18Base}.Input.ToAirport`)
                }
                labelTitle={t(`${i18Base}.Input.To`)}
                value={destination}
                shouldTruncateValueAt={shouldTruncateLocationPickerInputValueAt}
                errorMessage={validationErrors.destinationPlace}
                isDisabled={isDisabled}
                isShimmerLoading={isLoading}
              />
            }
          />
        </LocationPickerWrapper>
      </Search.Group>
      {isErrorInput && (
        <ErrorBlockWrapper>
          <StyledErrorBlock>
            <Icon name="errorFilledPWA" size="small" color="error" />
            <ErrorText textVariant={StyledTextVariant.captionStrong}>
              {t(i18Base + '.Input.Error')}
            </ErrorText>
          </StyledErrorBlock>
        </ErrorBlockWrapper>
      )}
    </Wrapper>
  )
})
