import type { PropsWithChildren, ReactNode } from 'react'
import { SelectedSearchParametersBlock } from '../../shared/selected-search-parameters-block'
import { Container, SelectorsContainer } from './header-full-view-mode-styled'
import { FilterButton } from './filter-button'

type Props = PropsWithChildren<{
  datesLabel?: string
  locationsLabel?: ReactNode
  withFilterButton?: boolean
  isDisabled?: boolean
  isFilterActive?: boolean
  onFilterClick?: () => void
  onEdit: () => void
  numberOfAppliedFilters?: number
}>

export function HeaderFullViewMode({
  children,
  datesLabel = '',
  locationsLabel = '',
  isDisabled,
  isFilterActive,
  withFilterButton,
  onEdit,
  onFilterClick,
  numberOfAppliedFilters,
}: Props) {
  return (
    <Container>
      <SelectedSearchParametersBlock
        datesLabel={datesLabel}
        isDisabled={isDisabled}
        locationsLabel={locationsLabel}
        onLabelClick={onEdit}
        withIcon
      />
      <SelectorsContainer>{children}</SelectorsContainer>
      {withFilterButton && (
        <FilterButton
          numberOfAppliedFilters={numberOfAppliedFilters}
          onClick={onFilterClick}
          isDisabled={isDisabled}
          isFilterActive={isFilterActive}
        />
      )}
    </Container>
  )
}
