import { RailSegmentTravelClassEnum } from '@etta/modules/rail/core/enums/rail-segment-travel-class.enum'
import type { RailEmissionsResultValueObject } from '@etta/modules/rail/core/value-objects/rail-emissions-result.value-object'
import type { RailEmission } from '@fiji/types'

export function useActualRailEmissions(
  railLegId?: string,
  railEmissionResult?: RailEmissionsResultValueObject,
  travelClass = RailSegmentTravelClassEnum.Economy,
): RailEmission {
  const railEmission = railEmissionResult?.rails?.find(
    (existingRailEmission) =>
      existingRailEmission.customRef === railLegId &&
      existingRailEmission.travelClass === travelClass,
  )

  const kgOfActualEmission =
    railEmission?.tonnesOfEmissions && Number((railEmission?.tonnesOfEmissions * 1000).toFixed(2))

  return {
    averageRouteEmissionTonnes: railEmissionResult?.averageEmissionsTonnes,
    customRef: railEmission?.customRef,
    equivalences: railEmission?.equivalences,
    tonnesOfEmissions: railEmission?.tonnesOfEmissions,
    kgOfActualEmission,
  }
}
