import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { checkExistDailyRatesAverage, useRoomsAverage } from '@fiji/utils/room-price'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { useHotelAndRoomPrice } from '@fiji/hooks/use-hotel-and-room-price'
import { useOutOfPolicyMessages } from '@fiji/hooks/use-out-of-policy-messages'
import type { Room } from '@fiji/graphql/types'
import { useUserContext } from '@etta/modules/user'
import type { PriceSkin } from './types'

type Args = {
  firstRoom?: Room
  isPreferred?: boolean
  isAllRoomsOutOfPolicy?: boolean
  skin?: PriceSkin
}

const i18Base = 'SearchResults.Hotels'

export function usePrice({ firstRoom, isPreferred, isAllRoomsOutOfPolicy, skin }: Args) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const { locale } = userStore

  const numberOfNights = firstRoom?.dailyRates.length
  const primary = firstRoom?.averageRate.secondary ?? firstRoom?.averageRate.primary
  const dailyRates = firstRoom?.dailyRates

  const shouldShowAverage = checkExistDailyRatesAverage(dailyRates)
  const roomsAverage = useRoomsAverage({ shouldShowAverage, primary, dailyRates })

  const averageText = t(i18Base + '.PerNight')
  const fromText = t(i18Base + '.From')
  const isAverage = numberOfNights ? numberOfNights > 1 && roomsAverage : false

  const { price } = useHotelAndRoomPrice(firstRoom?.averageRate ?? null)
  const soldOutText = t(i18Base + '.SoldOut')

  const showAllRoomsOutOfPolicy = skin === 'column' && isPreferred && isAllRoomsOutOfPolicy

  const { messages } = useOutOfPolicyMessages({
    outOfPolicyData: firstRoom?.policy?.outOfPolicyData,
  })
  const outOfPolicyReasons = firstRoom?.policy?.outOfPolicyReasons
  const outOfPolicyTexts = outOfPolicyReasons
    ? outOfPolicyReasons.map((code) => messages[code])
    : []

  const finalPrice = useMemo(() => {
    if (price) {
      return price
    }

    if (isAverage && roomsAverage) {
      return formatMoney(roomsAverage, {
        locale,
        hideDecimal: true,
      })
    }

    return null
  }, [price, roomsAverage, isAverage, locale])

  return {
    isAverage,
    finalPrice,
    averageText,
    soldOutText,
    outOfPolicyTexts,
    showAllRoomsOutOfPolicy,
    fromText,
  }
}
