type Props = {
  kgOfEmissions: number
}

export function KgOfCo2({ kgOfEmissions }: Props) {
  return (
    <span aria-label={kgOfEmissions + ' Kg CO2'}>
      {kgOfEmissions} Kg CO<sub>2</sub>
    </span>
  )
}
