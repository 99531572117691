import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { BookAgainTripDocument } from '@fiji/graphql/hooks'
import type { BookAgainTripMutation, BookAgainTripMutationVariables } from '@fiji/graphql/types'

@DataProvider()
export class BookTripAgainDataProvider extends GraphqlDataProvider {
  handleBookTripAgain(input: BookAgainTripMutationVariables['input']) {
    return this.client.mutate<BookAgainTripMutation, BookAgainTripMutationVariables>({
      mutation: BookAgainTripDocument,
      variables: { input },
    })
  }
}
