import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money'
import type { AirTicket } from '@fiji/graphql/types'
import { Section } from './e-ticket-receipt-modal.styled'

type Props = {
  airTicket?: AirTicket
}

export function BillingAndPayment({ airTicket }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  return (
    <Section>
      <Text variant="subHeadStrong">{t(i18nBase + 'BillingAndPayment')}</Text>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'FormOfPayment')}
        </Text>
        <Text variant="footnoteMedium" isBlock>
          {airTicket?.formOfPayment}
        </Text>
      </div>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'Endorsement')}:
        </Text>
        {airTicket?.endorsement?.map((endorsement) => (
          <Text key={endorsement} variant="footnoteMedium" isBlock>
            {endorsement}
          </Text>
        ))}
      </div>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'FareCalculation')}:
        </Text>
        <Text variant="footnoteMedium" isBlock preLine>
          {airTicket?.fareLinear}
        </Text>
      </div>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'Fare')}:
        </Text>
        <Text variant="footnoteMedium" isBlock>
          {formatRate(airTicket?.baseFare, { morpheme: 'prefix' }).mainCost}
        </Text>
      </div>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'TaxesFeesCharges')}:
        </Text>
        {airTicket?.fareTaxInfo?.map((fareTaxItem) => (
          <Text key={fareTaxItem.taxCode} variant="footnoteMedium" isBlock>
            {formatRate(fareTaxItem.taxAmount, { morpheme: 'prefix' }).mainCost}&nbsp;
            {fareTaxItem.taxCode} {fareTaxItem.taxLabel}
          </Text>
        ))}
      </div>

      <div>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'Total')}:
        </Text>
        <Text variant="headline" isBlock>
          {formatRate(airTicket?.totalFare, { morpheme: 'prefix' }).mainCost}
        </Text>
      </div>
    </Section>
  )
}
