import i18n from 'i18next'
import type { SearchFlightLegSubSegment } from '@fiji/graphql/types'

export function getPercentageOnTimeLabels(segments: SearchFlightLegSubSegment[]) {
  return segments.map(({ percentageOnTime }) => {
    if (!percentageOnTime) {
      return i18n.t('FlightDetails.N/A')
    }

    return `${percentageOnTime}%`
  })
}
