import { Action, Inject } from '@etta/di'
import { Themes } from '../../core/enums'
import { ThemeConfigurationStore } from '../stores'

@Action()
export class ThemeConfigurationAction {
  constructor(
    @Inject()
    private readonly themeConfigurationStore: ThemeConfigurationStore,
  ) {}

  toggleDarkTheme() {
    const isDarkTheme = this.themeConfigurationStore.theme === Themes.EttaDark
    this.themeConfigurationStore.setTheme(isDarkTheme ? Themes.Etta : Themes.EttaDark)
  }
}
