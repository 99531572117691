import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`

export const Wrapper = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div`
  margin: auto;
`
