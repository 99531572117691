import { Action, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
// eslint-disable-next-line import/no-restricted-paths
import { CreateTripService } from '@etta/modules/review-trip/interface/services'
import type { GetHotelsSortBy } from '../../core/value-objects/get-hotels-sorty-by.value-object'
import { LoadHotelsService } from '../services/load-hotels.service'
import { HotelSearchFormQueryService } from '../services/hotel-search-form-query.service'
import { HotelSearchFormStore } from '../stores'
import { HotelQueryStore } from '../stores/hotel-search-query.store'
import { HotelSearchResultsStore } from '../stores/hotel-search-results.store'
import { DEFAULT_HOTEL_FILTERS } from '../stores/constants'
import { HotelFiltersStore } from '../stores/hotel-filters.store'

@Action()
export class LoadHotelsAction {
  constructor(
    @Inject()
    private readonly loadHotelsService: LoadHotelsService,
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly tripStore: ReviewTripStore,
    @Inject()
    private readonly hotelFiltersStore: HotelFiltersStore,
    @Inject()
    private readonly createTripService: CreateTripService,
  ) {}

  async loadInitialHotels() {
    const {
      isMod2FlowEnabled,
      isHotelSearchServiceEnabled,
      isMod2DoubleWriteEnabled,
    } = this.displayConfigurationStore
    this.hotelSearchResultsStore.setIsLoading(true)
    if (isMod2FlowEnabled || isMod2DoubleWriteEnabled) {
      this.createTrip()
    }

    this.hotelSearchResultsStore.setHotels([])
    this.hotelSearchResultsStore.setHotelResults(null)
    this.hotelSearchResultsStore.setIsError(false)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)
    this.hotelSearchResultsStore.setSearchId(null)

    if (this.hotelQueryStore.searchId) {
      this.hotelSearchResultsStore.setSearchId(this.hotelQueryStore.searchId)
    }

    this.hotelSearchResultsStore.setOffset(0)
    this.hotelSearchResultsStore.setSortBy('preference')

    if (isHotelSearchServiceEnabled) {
      await this.loadHotelsService.loadSearchId()
    }

    if (this.hotelSearchResultsStore.isError) {
      this.hotelSearchResultsStore.setIsLoading(false)
      return
    }
    await this.loadHotelsService.loadHotels()

    const filters = this.hotelQueryStore.hotelSearchQuery.hotelFilters

    this.hotelFiltersStore.dropFiltersToInitial()

    if (!filters) {
      this.hotelSearchFormQueryService.appendQueryParams({
        hotelFilters: {
          ...DEFAULT_HOTEL_FILTERS,
          groupId: this.hotelSearchResultsStore.initialGroupId,
        },
      })
      this.hotelSearchResultsStore.setIsLoading(false)
      return
    }

    this.hotelSearchFormQueryService.appendQueryParams({
      hotelFilters: {
        ...filters,
        groupId: this.hotelSearchResultsStore.initialGroupId,
      },
    })

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  async createTrip() {
    const tripId = this.hotelQueryStore.hotelSearchQuery.itineraryId

    if (tripId) {
      this.tripStore.setTripId(tripId)
      return tripId
    }

    await this.createTripService.createTrip()

    this.hotelSearchFormQueryService.appendQueryParams({ itineraryId: this.tripStore.tripId })
    return this.tripStore.tripId
  }

  async loadHotelsByNewArea({ lat, lng }: { lat: number; lng: number }) {
    this.hotelSearchResultsStore.setIsLoading(true)
    this.hotelSearchResultsStore.setIsError(false)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)

    const location = this.hotelSearchFormStore.hotelForm.location
    if (!location) {
      return
    }
    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.appendQueryParams({
          isSearchByNewArea: true,
          location: {
            ...location,
            latitude: lat,
            longitude: lng,
          },
        }),
      )
    })

    this.hotelSearchResultsStore.setSearchId(null)

    this.hotelSearchResultsStore.setOffset(0)

    if (this.displayConfigurationStore.isHotelSearchServiceEnabled) {
      await this.loadHotelsService.loadSearchId()
    }

    if (this.hotelSearchResultsStore.isError) {
      this.hotelSearchResultsStore.setIsLoading(false)
      return
    }
    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  async retryLoadHotels() {
    this.hotelSearchResultsStore.setIsLoading(true)
    this.hotelSearchResultsStore.setIsError(false)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)

    if (
      this.displayConfigurationStore.isHotelSearchServiceEnabled &&
      !this.hotelSearchResultsStore.searchId
    ) {
      await this.loadHotelsService.loadSearchId()
    }

    if (
      this.displayConfigurationStore.isHotelSearchServiceEnabled &&
      !this.hotelSearchResultsStore.searchId &&
      this.hotelSearchResultsStore.isError
    ) {
      this.hotelSearchResultsStore.setIsLoading(false)
      return
    }

    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  async retryLoadHotel(id?: string) {
    if (!id) {
      return
    }
    await this.loadHotelsService.loadHotelById(id)
  }

  async onSortOptionChange(option: GetHotelsSortBy) {
    this.hotelSearchResultsStore.setIsLoading(true)
    this.hotelSearchResultsStore.setIsError(false)
    this.hotelSearchResultsStore.setOffset(0)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)
    this.hotelSearchResultsStore.setSortBy(option)

    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  async loadMoreHotelsDesktop(offset?: number) {
    this.hotelSearchResultsStore.setIsFetchMoreLoading(true)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)

    await this.loadHotelsService.loadMoreHotelsDesktop(offset)
    this.hotelSearchResultsStore.setIsFetchMoreLoading(false)
  }

  async loadMoreHotels(offset?: number) {
    this.hotelSearchResultsStore.setIsFetchMoreLoading(true)
    this.hotelSearchResultsStore.setIsFetchMoreError(false)

    await this.loadHotelsService.loadMoreHotels(offset)
    this.hotelSearchResultsStore.setIsFetchMoreLoading(false)
  }
}
