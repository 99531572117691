import { Action, Inject } from '@etta/di'
import { CheckoutInfoStore } from '@etta/modules/booking/interface/checkout-info/checkout-info.store'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { ReviewTripHotelService } from '../services/review-trip-hotel.service'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import type { SelectRoomArgs, ReplaceRoomArgs, RemoveRoomArgs } from '../types'
import { OutOfPolicyStore } from '../out-of-policy/stores'

@Action()
export class TripHotelsActions {
  constructor(
    @Inject()
    private readonly reviewTripHotelService: ReviewTripHotelService,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,
  ) {}
  async handleSelectRoom(args: SelectRoomArgs) {
    const { inventoryId, ...restArgs } = args
    const {
      isMod2DoubleWriteEnabled,
      isMod2FlowEnabled,
    } = this.displayConfigurationStore.displayConfiguration

    if (isMod2FlowEnabled) {
      return this.reviewTripHotelService.addRoomToCart({
        inventoryId,
        hotelResultId: args.hotelResultId,
        cartId: this.reviewTripStore.tripId,
      })
    }

    if (isMod2DoubleWriteEnabled) {
      const [result] = await Promise.all([
        this.reviewTripHotelService.selectRoom(restArgs),
        this.reviewTripHotelService.addRoomToCart({
          inventoryId,
          hotelResultId: args.hotelResultId,
          cartId: this.reviewTripStore.cartId,
        }),
      ])
      return result
    }

    this.outOfPolicyStore.dropHotelOopState()
    return this.reviewTripHotelService.selectRoom(restArgs)
  }

  handleRemoveRoom(args: RemoveRoomArgs) {
    this.checkoutInfoStore.memberships.dropMembershipDataByKey('hotel')
    this.checkoutInfoStore.specialRequests.dropSpecialRequestDataByKey('hotel')
    return this.reviewTripHotelService.removeRoom(args)
  }

  handleReplaceRoom(args: ReplaceRoomArgs) {
    this.checkoutInfoStore.memberships.dropMembershipDataByKey('hotel')
    this.checkoutInfoStore.specialRequests.dropSpecialRequestDataByKey('hotel')
    this.outOfPolicyStore.dropHotelOopState()
    return this.reviewTripHotelService.replaceRoom(args)
  }
}
