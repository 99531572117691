import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import type { LayoutProps } from '../../types'
import { Container, ContentContainer } from './held-trips-page-mobile-styled'
import { useHeldTripsMobile } from './use-held-trips-mobile'

export function HeldTripsMobilePage({ tripListSlot, handleGoBack }: LayoutProps) {
  const { updateRef, headerRef, headerTitle, anchorRef } = useHeldTripsMobile()
  return (
    <Screen backgroundColor="background">
      <Container ref={updateRef}>
        <Screen.Header>
          <Header
            headerRef={headerRef}
            backgroundColor="duskBlue"
            leftSlot={<Header.BackButton color="mainText2" onClick={handleGoBack} />}>
            <Header.Title
              color="mainText2"
              aria-label={headerTitle}
              title={headerTitle}
              align="left"
            />
          </Header>
        </Screen.Header>
        <div ref={anchorRef} />
        <ContentContainer paddingHorizontal={16}>{tripListSlot}</ContentContainer>
      </Container>
    </Screen>
  )
}
