import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px 16px;
  background-color: ${(props) => props.theme.colors.white};
`

export const Body = styled.div`
  display: flex;
  width: 100%;
`

const Title = styled.div`
  width: 90px;
  height: 20px;
  margin-bottom: 24px;
  ${glowingFragment}
`

const BodyLeft = styled.div`
  width: 90px;
  height: 60px;
  margin-right: 24px;
  ${glowingFragment}
`

const LongText = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: 11px;
  ${glowingFragment};
`

const ShortText = styled.div`
  width: 60%;
  height: 16px;
  ${glowingFragment};
`

const BodyRightContainer = styled.div`
  margin-left: 8px;
  width: 200px;
`

const Background = styled.div`
  width: 258px;
  height: 122px;
  ${glowingFragment};
`
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`
export const LeftContainer = styled.div`
  width: 50%;
`

export function FlightDetailsEcoCheckDesktopSkeleton() {
  return (
    <Container>
      <MainContainer>
        <LeftContainer>
          <Title />
          <Body>
            <BodyLeft />
            <BodyRightContainer>
              <LongText />
              <ShortText />
            </BodyRightContainer>
          </Body>
        </LeftContainer>
        <Background />
      </MainContainer>
    </Container>
  )
}
