import { Icon } from '@etta/ui/icon'
import { HotelMarkerWrapper } from './hotel-marker.styled'

export function HotelMarker() {
  return (
    <HotelMarkerWrapper>
      <Icon name="hotelPWA" color="mainText2" size="medium" />
    </HotelMarkerWrapper>
  )
}
