import styled, { css } from 'styled-components'
import { footnoteMedium, footnoteStrong, subHeadStrong } from '@fiji/style'
import { CostContainer } from '../../payment-summary-styled-common'
import type { DueTodayProps, TextProps } from '../../types'

export const Text = styled.span<TextProps>`
  display: block;
  color: ${(p) => {
    if (!p.color) {
      return p.theme.colors.bodyText
    }

    return p.theme.colors[p.color]
  }};
  margin-left: ${(p) => p.marginLeft || 0}px;

  ${(p) => {
    if (p.withTextOverflow) {
      return `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 15px;
      `
    }

    return ''
  }}
  line-height: 19px;

  ${footnoteMedium};
`

export const TripType = styled.span`
  line-height: 19px;
  color: ${(p) => p.theme.colors.mainText};
  ${footnoteStrong};
`

export const DueToday = styled(CostContainer)<DueTodayProps>`
  margin-top: 16px;
  padding-top: 10px;
  ${({ isBorderVisible, theme }) =>
    isBorderVisible &&
    css`
      border-top: 1px dashed ${theme.colors.borderDark};
    `}

  ${Text} {
    line-height: 20px;
    color: ${(p) => p.theme.colors.mainText};
    ${subHeadStrong};
  }
`

export const Content = styled.div`
  padding: 38px 20px 18px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const FooterWrapper = styled.div``
