import { useEffect, useState } from 'react'
import type { Place } from '@fiji/types'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import { useLocationAutocomplete } from '@fiji/hooks/use-location-autocomplete/use-location-autocomplete'

type Args = {
  cityName?: string
}

export function useGetPlaceByCityName({ cityName }: Args) {
  const [places, setPlaces] = useState<Place[]>([])
  const setFilteredPlaces = (places: Place[]) => {
    const googlePlaces = places.filter(
      (place) => place?.placeType === PlaceAutocompleteType.GooglePlaces,
    )
    setPlaces(googlePlaces)
  }

  const { handleFindPlaces } = useLocationAutocomplete({
    onComplete: setFilteredPlaces,
    useNewService: true,
  })

  useEffect(() => handleFindPlaces({ place: cityName }), [handleFindPlaces, cityName])

  return {
    place: places[0],
  }
}
