export default function UsersIconPWA() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.8005 16.0107H35.3005V15.5107V14.3621C35.3005 13.5622 35.0802 12.8782 34.5976 12.3951C34.115 11.9121 33.4418 11.7015 32.6831 11.7015H11.3169C10.5469 11.7015 9.87137 11.9109 9.39051 12.3968C8.91043 12.8819 8.69945 13.5669 8.69945 14.3621V15.5107V16.0107H9.19945H34.8005ZM9.19945 18.1147H8.69945V18.6147V28.6516C8.69945 29.4468 8.9105 30.1296 9.39218 30.6117C9.87387 31.0939 10.5494 31.2985 11.3169 31.2985H32.6831C33.4394 31.2985 34.1125 31.0926 34.5959 30.6134C35.0801 30.1333 35.3005 29.4515 35.3005 28.6516V18.6147V18.1147H34.8005H9.19945ZM36.5 28.7747C36.5 30.1037 36.1623 31.0095 35.5752 31.5873C34.9866 32.1666 34.0626 32.5 32.7104 32.5H11.2896C9.92997 32.5 9.00658 32.1663 8.42014 31.5877C7.83496 31.0104 7.5 30.1049 7.5 28.7747V14.239C7.5 12.9087 7.83499 11.9992 8.421 11.4183C9.00782 10.8365 9.93118 10.5 11.2896 10.5H32.7104C34.0614 10.5 34.9854 10.8362 35.5743 11.4187C36.1622 12.0001 36.5 12.91 36.5 14.239V28.7747ZM17.0874 25.4382C17.0874 25.7109 16.9965 25.9052 16.8692 26.0308C16.7414 26.1568 16.5391 26.2509 16.2486 26.2509H12.9016C12.6111 26.2509 12.4088 26.1568 12.2811 26.0308C12.1538 25.9052 12.0628 25.7109 12.0628 25.4382V22.9084C12.0628 22.6444 12.1527 22.4485 12.2824 22.3196C12.4119 22.1909 12.6147 22.0957 12.9016 22.0957H16.2486C16.5356 22.0957 16.7384 22.1909 16.8679 22.3196C16.9976 22.4485 17.0874 22.6444 17.0874 22.9084V25.4382Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
