import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Footer } from '../../footer'
import { Message } from '../../message'
import type { LayoutProps } from '../types'
import { DsmDesktopIcon } from './dsm-desktop-icon'
import { DsmDesktopShowMoreButton } from './dsm-desktop-show-more-button'
import {
  Container,
  Content,
  MessageContainer,
  MessageWrapper,
  MessageSlotContainer,
  Title,
} from './dynamic-site-messages-desktop-styled'

export function DynamicSiteMessagesDesktop({
  title,
  message,
  messageIndex,
  messageSlot,
  messageRef,
  totalCount,
  hasDesktopBannerImage,
  hasMultipleItems,
  handleModalOpen,
  hasAlertIcon,
  isMessageTruncated,
  handleUpdateMessageIndex,
  handleSetAnchorId,
}: LayoutProps) {
  return (
    <Container data-tracking-id="dsm-container">
      <Content>
        <DsmDesktopIcon hasAlertIcon={hasAlertIcon} hasDesktopBannerImage={hasDesktopBannerImage} />
        <MessageContainer>
          <Block marginBottom={8}>
            <Swap is={title.length > 0} isSlot={<Title>{title}</Title>}>
              <Title withEllipsisOverflow dangerouslySetInnerHTML={{ __html: message }} />
            </Swap>
          </Block>
          <MessageWrapper>
            <Swap
              is={!!messageSlot}
              isSlot={<MessageSlotContainer>{messageSlot}</MessageSlotContainer>}>
              <Message
                messageRef={messageRef}
                message={message}
                hasDesktopBannerImage={hasDesktopBannerImage}
              />
            </Swap>
            <DsmDesktopShowMoreButton
              onClickShowMore={handleModalOpen}
              isMessageTruncated={isMessageTruncated}
            />
          </MessageWrapper>
        </MessageContainer>
      </Content>
      {hasMultipleItems && (
        <Footer
          totalCount={totalCount}
          messageIndex={messageIndex}
          onOpen={handleModalOpen}
          onUpdateMessageIndex={handleUpdateMessageIndex}
          handleSetAnchorId={handleSetAnchorId}
        />
      )}
    </Container>
  )
}
