import { lazy } from 'react'
import type { RouteItem } from '../types'

const HotelSearch = lazy(() => import('@fiji/components/hotel-search'))
const TripPlanner = lazy(() => import('@fiji/components/trip-planner'))
const TripReview = lazy(() => import('@fiji/components/trip-review'))

export const tripPlannerRoutes: Array<RouteItem> = [
  {
    route: {
      path: '/trip-planner',
      exact: true,
    },
    transitionIndex: 1,
    isDeemWrapperUsed: true,
    component: () => <TripPlanner />,
  },
  {
    route: {
      path: '/trip-planner/hotel-search',
    },
    transitionIndex: 30,
    isDeemWrapperUsed: true,
    component: () => <HotelSearch />,
  },
  {
    route: {
      path: '/trip-planner/:itineraryId/review',
      exact: true,
    },
    transitionIndex: 40,
    isDeemWrapperUsed: true,
    component: () => <TripReview />,
  },
]
