import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { slideUp } from './transition'

export const SheetContainer = styled.div`
  width: 100%;
  height: 80%;
  top: -1;
  bottom: 0;
  position: absolute;
`

export const SheetContent = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${(p) => p.theme.colors.background};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  ${slideUp};
`

export const MiniPill = styled.div`
  background: ${(p) => p.theme.colors.borderDark};
  border-radius: 50rem;
  height: 4px;
  width: 42px;
  margin-top: 11px;
  margin-bottom: 15px;
  flex-shrink: 0;
`

export const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 0px 20px;
`

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const WhereToButton = styled(Button)`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.06);
  text-align: left;
  padding: 15px;
  margin-top: 26px;
`

export const LogoIconContainer = styled.div`
  margin-bottom: 28px;
  margin-top: 14px;
`
