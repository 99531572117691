import { useTranslation } from 'react-i18next'
import { Search } from '@etta/ui/search'
import { LocationPicker } from '@etta/components/location-picker'
import { getLocationValue } from '@fiji/utils/get-location-value'
import type { Props } from '../types'

const i18Base = 'TripPlanner.BaseSearch'

export function CarRentalPlaceDesktop({
  onPickUpPlaceChange,
  pickUpPlace,
  onDropOffPlaceChange,
  dropOffPlace,
  placesHistory,
  pickUpAddress,
  dropOffAddress,
  onSwitchLocations,
}: Props) {
  const { t } = useTranslation()

  return (
    <Search.Group>
      <LocationPicker
        recentSearchesType="carRental"
        value={pickUpPlace}
        onChange={onPickUpPlaceChange}
        placeholder={t(`${i18Base}.Input.SelectLocation`)}
        searchType="car-rental"
        latestPlaces={placesHistory}
        isOutboundTrip
        hasCurrentLocationSearch
        inputSlot={
          <Search.Element
            labelTitle={t(`${i18Base}.Input.PickupLocation`)}
            value={getLocationValue(pickUpPlace, pickUpAddress)}
            shouldTruncateValueAt={19}
          />
        }
      />
      <Search.SwapLocationButton onClick={onSwitchLocations} />
      <LocationPicker
        recentSearchesType="carRental"
        value={dropOffPlace}
        onChange={onDropOffPlaceChange}
        placeholder={t(`${i18Base}.Input.SelectLocation`)}
        searchType="car-rental"
        latestPlaces={placesHistory}
        hasCurrentLocationSearch
        inputSlot={
          <Search.Element
            labelTitle={t(`${i18Base}.Input.DropoffLocation`)}
            value={getLocationValue(dropOffPlace, dropOffAddress)}
            shouldTruncateValueAt={19}
          />
        }
      />
    </Search.Group>
  )
}
