import { useMemo } from 'react'
import type { SwipingTimePickerUnits, SwipingPickerSubtype } from '../../types'

type Args = {
  type: SwipingTimePickerUnits
  variant?: SwipingPickerSubtype
  unitGroup?: number
  hourlyRange?: number
  range: number[]
  startYear: number
}

export function useTimeUnits({ variant, type, unitGroup, hourlyRange, startYear }: Args) {
  const resolveMonthName = useMemo(
    () =>
      Array.from(Array(12), (_el, i) => i).map((month) => {
        const extractMonth = new Date(2020 /* any year*/, month)
        return extractMonth.toLocaleString('default', {
          month: variant === 'only-month' ? 'long' : 'short',
        })
      }),
    [variant],
  )

  const getTimeUnit = (index: number) => {
    switch (type) {
      case 'hour':
        return index && index < 10 ? '0' + index : index || (hourlyRange ? '00' : 12)
      case 'min':
        if (!index) {
          return '00'
        }
        if (variant === 'only-min') {
          return index < 10 ? '0' + index : index
        }
        return index * (unitGroup || 1)
      case 'month':
        return resolveMonthName[index]
      case 'day':
        return index < 9 ? '0' + (index + 1) : index + 1
      case 'year':
        return index + startYear
    }
  }

  return { getTimeUnit }
}
