import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightSafetyCheckMobile } from './mobile'
import { FlightSafetyCheckDesktop } from './desktop'

export function FlightSafetyCheckLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightSafetyCheckMobile {...props} />}
      desktopSlot={<FlightSafetyCheckDesktop {...props} />}
    />
  )
}
