import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type {
  PartnerCoBrandingConfigurations,
  PartnerCoBrandingConfigurationsnParams,
} from '@etta/modules/partner-cobranding-configuration/core/types'
import type { PartnerCoBrandingConfigurationsErrorsInstances } from '../core/errors/partner-cobranding-configurations.errors'
import { PartnerCoBrandingConfigurationsErrors } from '../core/errors/partner-cobranding-configurations.errors'
import { PartnerCoBrandingConfigurationsDataProvider } from './partner-cobranding-configurations.data-provider'
import { PartnerCoBrandingConfigurationsMapper } from './mappers/partner-cobranding-configurations.mapper'

@Adapter()
export class PartnerCoBrandingConfigurationsAdapter {
  constructor(
    @Inject()
    private partnerCoBrandingConfigurationDataProvider: PartnerCoBrandingConfigurationsDataProvider,
  ) {}
  async getConfigurations(
    params: PartnerCoBrandingConfigurationsnParams,
  ): PromisedResult<
    PartnerCoBrandingConfigurations,
    PartnerCoBrandingConfigurationsErrorsInstances
  > {
    try {
      const {
        data,
        error,
      } = await this.partnerCoBrandingConfigurationDataProvider.getPartnerCoBrandingConfigurations(
        params,
      )

      if (error) {
        return Result.Err(
          new PartnerCoBrandingConfigurationsErrors.PartnerCoBrandingConfigurationsResponse(error),
        )
      }

      const configurations = PartnerCoBrandingConfigurationsMapper.toPartnerCoBrandingConfigurations(
        data.partnerCoBrandingConfiguration,
      )
      return Result.Ok(configurations)
    } catch (e) {
      return Result.Err(
        new PartnerCoBrandingConfigurationsErrors.UnexpectedErrorPartnerCoBrandingConfigurations(e),
      )
    }
  }
}
