import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const TooltipTextContainer = styled.div`
  max-width: 300px;
  padding: 8px;
`

export const BreakdownItemName = styled.div`
  max-width: 60%;
`

export const BreakdownItemContainer = styled(Block).attrs(() => ({
  isFlexBlock: true,
  flexGap: 8,
}))`
  flex-direction: column;
`

export const BreakdownItem = styled(Block).attrs(() => ({
  isFlexBlock: true,
  isSpaceBetween: true,
  alignItems: 'flex-start',
  isFullWidth: true,
}))``
