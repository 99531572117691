import { useTranslation } from 'react-i18next'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container } from './hotel-safety-check-empty-styled'

type Props = {
  i18nBase: string
}

export function HotelSafetyCheckEmpty({ i18nBase }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Block marginBottom={4}>
        <Icon name="locationPWA" color="plum" size="medium" />
      </Block>
      <Block marginBottom={4}>
        <SafetyCheckTitle />
      </Block>
      <Text variant="subHeadMedium" color="bodyText">
        {t(`${i18nBase}.Description`)}
      </Text>
    </Container>
  )
}
