import { useTranslation } from 'react-i18next'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { useFlightDetailsEcoCheckModalQuestion } from '../../use-flight-details-eco-check-modal-question'
import {
  EmisisonDetailsHeaderMobile,
  EmisisonDetailsDescriptionMobile,
  EmisisonDetailsMobile,
} from '../flight-details-eco-check-modal-mobile-new-styled'
import { FlightDetailsModalLearnMore } from '../../components/flight-details-eco-check-modal-learn-more'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function FlightDetailsEcoCheckModalMobileLowerBodyNew({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const { questions, answers } = useFlightDetailsEcoCheckModalQuestion({ deviationLevel })

  return (
    <>
      <EmisisonDetailsMobile>
        <EmisisonDetailsDescriptionMobile>
          {t(i18nBase + '.EmissionsEstimatePerPassenger')}
        </EmisisonDetailsDescriptionMobile>
      </EmisisonDetailsMobile>
      <EmisisonDetailsMobile>
        <EmisisonDetailsHeaderMobile isFirstHeader>
          {questions.howDoWeKnow}
        </EmisisonDetailsHeaderMobile>
        <EmisisonDetailsDescriptionMobile withBorder>
          {answers.howDoWeKnow}
        </EmisisonDetailsDescriptionMobile>
      </EmisisonDetailsMobile>
      <EmisisonDetailsMobile>
        <EmisisonDetailsHeaderMobile>{questions.howDoWeEstimate}</EmisisonDetailsHeaderMobile>
        <EmisisonDetailsDescriptionMobile withBorder>
          {answers.howDoWeEstimate}
          <FlightDetailsModalLearnMore />
        </EmisisonDetailsDescriptionMobile>
      </EmisisonDetailsMobile>
      <EmisisonDetailsMobile>
        <EmisisonDetailsHeaderMobile>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHeader')}
        </EmisisonDetailsHeaderMobile>
        <EmisisonDetailsDescriptionMobile>
          {t(i18nBase + '.OtherWaysToReduceEmissionsFlightDescription')}
        </EmisisonDetailsDescriptionMobile>
      </EmisisonDetailsMobile>
    </>
  )
}
