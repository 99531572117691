export function hasDailyRates(source?: string | null): boolean {
  switch (source?.toLowerCase()) {
    case 'apollo':
    case 'galileo':
    case 'worldspan':
      return false
    default:
      return true
  }
}
