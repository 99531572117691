import { ReviewTripPage } from '@etta/screens/review-trip-page'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const tripReviewRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.reviewTrip.main({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Trip Review'),
    transitionIndex: 4.3,
    transitionGroup: 'slide',
    component: () => <ReviewTripPage />,
  },
]
