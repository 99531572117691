import styled from 'styled-components'
import { transition } from './transition'

export const ScreenHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  display: flex;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 0 8px;
  > * {
    width: 100%;
    background-color: transparent;

    > * {
      min-height: 56px;
    }
  }

  ${transition};
`
