import { useEffect, useState } from 'react'
import { dateFormat } from '@fiji/utils/dates'
import { WheelPickerItem } from './wheel-picker-item'
import { Highlight, WheelPickerContainer } from './wheel-date-time-picker-styled'
import { useWheelDateTimePicker } from './use-wheel-date-time-picker'

type Props = {
  selectedDate: Date
  onSelectedDateChange: (value: Date) => void
  minDate?: Date
  maxDate?: Date
}

export const WheelDateTimePicker = ({
  selectedDate,
  minDate,
  maxDate,
  onSelectedDateChange,
}: Props) => {
  const { dateItems, hourItems, minuteItems, timePeriodItems, parseDate } = useWheelDateTimePicker({
    minDate,
    maxDate,
  })

  const [dateValue, setDateValue] = useState(dateFormat(selectedDate, 'yyyy-MM-dd'))
  const [hourValue, setHourValue] = useState(dateFormat(selectedDate, 'h'))
  const [minuteValue, setMinuteValue] = useState(
    `${String(Math.round(selectedDate.getMinutes() / 5) * 5).padStart(2, '0')}`,
  )
  const [timePeriodValue, setTimePeriodValue] = useState(dateFormat(selectedDate, 'a'))

  useEffect(() => {
    const date = parseDate(dateValue, hourValue, minuteValue, timePeriodValue)
    onSelectedDateChange(date)
  }, [dateValue, hourValue, minuteValue, timePeriodValue, onSelectedDateChange, parseDate])

  return (
    <WheelPickerContainer>
      <Highlight />
      <WheelPickerItem
        minWidth="120px"
        perspective="left"
        textAlign="right"
        items={dateItems}
        value={dateValue}
        onValueChange={setDateValue}
      />
      <WheelPickerItem
        loop
        minWidth="50px"
        perspective="middle"
        items={hourItems}
        value={hourValue}
        onValueChange={setHourValue}
      />
      <WheelPickerItem
        loop
        minWidth="50px"
        perspective="middle"
        items={minuteItems}
        value={minuteValue}
        onValueChange={setMinuteValue}
      />
      <WheelPickerItem
        minWidth="50px"
        perspective="right"
        textAlign="left"
        value={timePeriodValue}
        items={timePeriodItems}
        onValueChange={setTimePeriodValue}
      />
    </WheelPickerContainer>
  )
}
