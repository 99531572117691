import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { CardWrapper } from './info-card-styled'

type Props = {
  headline: string
  value: string
}

export function Card({ headline, value }: Props) {
  return (
    <CardWrapper>
      <Text variant="captionMedium" color="bodyText">
        {headline}
      </Text>
      <Block marginTop={4}>
        <Text variant="subHeadStrong" color="mainText1">
          {value}
        </Text>
      </Block>
    </CardWrapper>
  )
}
