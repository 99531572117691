import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import { CarRentalMobileSection } from '../car-rental-mobile-section'
import { CarRentalMobileDivider } from '../car-rental-mobile-divider'
import { useCarRentalPrice } from './use-car-rental-mobile-price'
import { PriceList, PriceListItem, TotalCost } from './car-rental-mobile-price-styled'
import { CarRentalMobilePriceSkeleton } from './car-rental-mobile-price-skeleton'

type Props = {
  car: CarRentalCarDetailsValueObject | null
  isLoading: boolean
  isServiceFeeEnabled: boolean
}

const i18Base = 'CarRentalDetails'

export function CarRentalMobilePrice({ car, isLoading, isServiceFeeEnabled }: Props) {
  const { t } = useTranslation()
  const { priceList, totalPrice } = useCarRentalPrice({ car, isServiceFeeEnabled })

  return (
    <CarRentalMobileSection
      title={t(i18Base + '.SectionTitles.PriceDetails')}
      dataTrackingId="car-rental-price"
      isLoading={isLoading}>
      <CarRentalMobileSection.Content>
        <Swap is={isLoading} isSlot={<CarRentalMobilePriceSkeleton />}>
          <PriceList>
            {priceList.map(({ testId, title, value, variant }) => {
              if (!value) {
                return ''
              }
              return (
                <PriceListItem key={testId} variant={variant}>
                  <span>{title}</span> <span>{value}</span>
                </PriceListItem>
              )
            })}
          </PriceList>

          <CarRentalMobileDivider marginSize="sm" />

          <TotalCost>
            <span>{t(i18Base + '.SectionTitles.EstimatedTotal')}</span> <span>{totalPrice}</span>
          </TotalCost>
        </Swap>
      </CarRentalMobileSection.Content>
    </CarRentalMobileSection>
  )
}
