import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Icon } from '@etta/ui/icon'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import { BookAgainTravelVerticalEnum } from '@etta/modules/post-booking/core/enums'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { useResetDelegateActiveBookingFlow } from '@fiji/hooks/delegates/use-reset-delegate-active-booking-flow'
import { formatAddress } from '@fiji/utils/address'
import type { LayoutProps } from '../types'
import { BookTripAgainModalSegment } from '../components/book-trip-again-modal-segment'
import { BookTripAgainModalDataPicker } from '../components'
import { BookTripAgainDelegatePicker } from '../components/book-trip-again-delegate-picker'

export const BookTripAgainModalMobile = observer(function BookTripAgainModalMobile({
  isOpen,
  onClose,
  flightSegment,
  hotelSegment,
  carRentalSegment,
  tripName,
  regularUser,
  currentUserId,
}: LayoutProps) {
  const { t } = useTranslation()
  const { bookTripAgainStore, bookTripAgainActions } = usePostBookingContext()
  const {
    delegateActions: { handleSelectDelegate },
  } = useDelegateContext()

  const { onReset } = useResetDelegateActiveBookingFlow()
  const handleContinue = async () => {
    await bookTripAgainActions.handleContinue(handleSelectDelegate)
    onReset()
  }

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose} horizontalDimension="content-760">
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={
            <>
              <Block paddingRight={13} isFlexBlock flexGap={24}>
                <IconButton icon="closePWA" color="bodyText" size="medium" onClick={onClose} />
                <Text variant="title3" noWrap={true}>
                  {t('TripReview.BookAgain.BookThisTripAgain')}
                </Text>
              </Block>
            </>
          }></Header>
      </Modal.Header>
      <Modal.Body>
        <Block>
          <Block paddingHorizontal={16}>
            <Block paddingTop={24}>
              <Text variant="title3">{tripName}</Text>
            </Block>
            <Block paddingTop={22}>
              {flightSegment && (
                <BookTripAgainModalSegment
                  title={
                    <Text variant="footnoteStrong" color="mainText">
                      {flightSegment.originAirportCode}{' '}
                      <Icon name={'arrowForwardPWA'} size={'small'} />{' '}
                      {flightSegment.destinationAirportCode} (
                      <Text variant="footnoteStrong" textTransform="capitalize">
                        {flightSegment.serviceClass.toLowerCase()}
                      </Text>
                      )
                    </Text>
                  }
                  description={flightSegment.flightNumbers.join(', ')}
                  segmentType={BookAgainTravelVerticalEnum.Air}
                  isChecked={bookTripAgainStore.selectedSegments.has(
                    BookAgainTravelVerticalEnum.Air,
                  )}
                  onChange={(value, segmentType) =>
                    bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                  }
                />
              )}
              {hotelSegment && (
                <Block paddingTop={8}>
                  <BookTripAgainModalSegment
                    title={
                      <Text variant="footnoteStrong" color="mainText">
                        {hotelSegment.name}
                      </Text>
                    }
                    description={`${hotelSegment.roomType}, ${t('ReviewTripPWA.Details.Nights', {
                      count: hotelSegment.numberOfNights,
                    })}, ${formatAddress(hotelSegment.address)}`}
                    segmentType={BookAgainTravelVerticalEnum.Hotel}
                    isChecked={bookTripAgainStore.selectedSegments.has(
                      BookAgainTravelVerticalEnum.Hotel,
                    )}
                    onChange={(value, segmentType) =>
                      bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                    }
                  />
                </Block>
              )}
              {carRentalSegment && (
                <Block paddingTop={8}>
                  <BookTripAgainModalSegment
                    title={
                      <>
                        <Text variant="footnoteStrong" color="mainText">
                          {carRentalSegment.vendor}
                        </Text>{' '}
                        •{' '}
                        <Text variant="footnoteStrong" textTransform="capitalize">
                          {carRentalSegment.carClass.toLowerCase()}
                        </Text>
                      </>
                    }
                    description={`${formatAddress(carRentalSegment.pickUpAddress)}`}
                    segmentType={BookAgainTravelVerticalEnum.Carrental}
                    isChecked={bookTripAgainStore.selectedSegments.has(
                      BookAgainTravelVerticalEnum.Carrental,
                    )}
                    onChange={(value, segmentType) =>
                      bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                    }
                  />
                </Block>
              )}
            </Block>
            <Block paddingTop={24}>
              <BookTripAgainDelegatePicker
                regularUser={regularUser}
                currentUserId={currentUserId || 0}
                onUserSelect={(id) => bookTripAgainStore.setDelegatedId(id)}
              />
            </Block>
            <Block paddingTop={24}>
              <BookTripAgainModalDataPicker
                startDate={bookTripAgainStore.startDate}
                endDate={bookTripAgainStore.endDate}
                onDateChange={(result: Dates) => bookTripAgainStore.onDatesChange(result)}
                startDateErrorMessage={bookTripAgainStore.startDateErrorMessage}
                endDateErrorMessage={bookTripAgainStore.endDateErrorMessage}
              />
            </Block>
          </Block>
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Block paddingHorizontal={16} paddingBottom={16}>
          <Button
            onClick={handleContinue}
            disabled={!bookTripAgainStore.selectedSegments.size}
            fullWidth>
            <Text variant="buttonNormal" color="mainText2">
              {t('TripReview.BookAgain.BookThisTripAgain')}
            </Text>
          </Button>
        </Block>
      </Modal.Footer>
      <ViewStateModal
        viewState={bookTripAgainStore.viewState}
        onDone={bookTripAgainActions.onCloseViewState}
      />
    </Modal>
  )
})
