import { Trans, useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { FailedAlignedSegment as Props } from '../../types'
import { BottomRow, Container, TopRow, SearchButton } from './failed-aligned-segment.desktop.styled'

const i18nBase = 'FailedAlignedSegment.'

export function FailedAlignedSegmentDesktop({ travelVertical, onSearchClick, customLabel }: Props) {
  const { t } = useTranslation()

  if (travelVertical === 'Air') {
    return (
      <Container>
        <TopRow>
          <Block>
            <Icon name="flightPWA" color="error" />
            <Text color="error" variant="headline">
              {t(i18nBase + 'FlightsTitle')}
            </Text>
          </Block>
          {onSearchClick && (
            <SearchButton variant="solid" onClick={onSearchClick}>
              {t(i18nBase + 'SearchFlights')}
            </SearchButton>
          )}
        </TopRow>
        <BottomRow hasSearchButton={Boolean(onSearchClick)}>
          <Text color="mainText" variant="footnoteStrong">
            {t(i18nBase + 'FlightsDescription')}{' '}
          </Text>
        </BottomRow>
      </Container>
    )
  }

  if (travelVertical === 'Carrental') {
    return (
      <Container>
        <TopRow>
          <Block>
            <Icon name="carRentalPWA" color="error" />
            <Text color="error" variant="headline">
              {t(i18nBase + 'CarRentalTitle')}
            </Text>
          </Block>
          {onSearchClick && (
            <SearchButton variant="solid" onClick={onSearchClick}>
              {t(i18nBase + 'SearchCarRentals')}
            </SearchButton>
          )}
        </TopRow>
        <BottomRow hasSearchButton={Boolean(onSearchClick)}>
          <Text color="mainText" variant="footnoteStrong">
            {t(i18nBase + 'CarRentalDescription')}{' '}
          </Text>
        </BottomRow>
      </Container>
    )
  }

  if (travelVertical === 'Hotel') {
    return (
      <Container>
        <TopRow>
          <Block isFlexBlock>
            <Icon name="hotelPWA" color="error" />
            <Text color="error" variant="headline">
              {t(i18nBase + 'HotelTitle')}
            </Text>
          </Block>
          {onSearchClick && (
            <SearchButton variant="solid" onClick={onSearchClick}>
              {t(i18nBase + 'SearchHotels')}
            </SearchButton>
          )}
        </TopRow>
        <BottomRow hasSearchButton={Boolean(onSearchClick)}>
          <Text color="mainText" variant="footnoteStrong">
            <Trans
              values={{ label: customLabel }}
              i18nKey={i18nBase + 'HotelDescription'}
              components={{ bold: <strong /> }}
            />
          </Text>
        </BottomRow>
      </Container>
    )
  }
  return null
}
