import { useReviewTripContext } from '@etta/modules/review-trip'
import { getFlightsPrices } from './get-flights-prices'
import { FlightsLayout } from './layout'
import type { Props } from './types'

export function Flights({
  segments,
  segmentState,
  flightTripCost,
  isFromPreviousBooking,
  tripCostSummary,
  children,
  isDesktopTripCostSummary,
  isHoldFlow,
  showTaxBreakdown,
}: Props) {
  const { reviewTripStore } = useReviewTripContext()

  if (!segments || segments.length === 0 || !flightTripCost) {
    return null
  }

  const unusedTicket =
    reviewTripStore.validatedUnusedTickets ?? reviewTripStore.unvalidatedUnusedTickets
  const allowUnusedTicketOptout = tripCostSummary?.allowUnusedTicketOptout ?? false
  const hasOptOutUnusedTickets = tripCostSummary?.hasOptOutUnusedTickets ?? false

  const { totalWithUnusedTicketRate } = getFlightsPrices({
    tripCost: flightTripCost,
    unusedTicketRate: unusedTicket?.total,
    hasOptOutUnusedTickets,
  })

  const isLeadingNewSegment = !!(!isFromPreviousBooking && segmentState)

  return (
    <FlightsLayout
      flightTripCost={flightTripCost}
      isDesktopTripCostSummary={isDesktopTripCostSummary}
      isFromPreviousBooking={isFromPreviousBooking}
      segmentState={segmentState}
      segments={segments}
      totalWithUnusedTicketRate={totalWithUnusedTicketRate}
      allowUnusedTicketOptout={allowUnusedTicketOptout}
      hasOptOutUnusedTickets={hasOptOutUnusedTickets}
      isLeadingNewSegment={isLeadingNewSegment}
      contentSlot={children}
      isHoldFlow={isHoldFlow}
      showTaxBreakdown={showTaxBreakdown}
    />
  )
}
