import { default as NoHotelIcon } from './assets/no-hotel-preview-icon.svg'
import { HeaderImageBg } from './hotel-image-styled'
import type { HotelImagePlaceholderSkin } from './types'

type Props = {
  skin?: HotelImagePlaceholderSkin
}

export function HeaderImagePlaceholder({ skin }: Props) {
  return (
    <HeaderImageBg skin={skin}>
      <NoHotelIcon />
    </HeaderImageBg>
  )
}
