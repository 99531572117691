import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { FareCardAttributesMobile } from './mobile'
import { FareCardAttributesDesktop } from './desktop'

export function FareCardAttributesLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<FareCardAttributesMobile {...props} />}
      desktopSlot={<FareCardAttributesDesktop {...props} />}
    />
  )
}
