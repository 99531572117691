import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { FilterButtonWrapper } from './filter-button-styled'
import { useFilterButton } from './use-filter-button'

type Props = {
  onClick?: () => void
  isDisabled?: boolean
  isFilterActive?: boolean
  numberOfAppliedFilters?: number
}

const i18nBase = 'AirSearchResults.Header.'

export function FilterButton({
  onClick,
  isDisabled,
  isFilterActive = false,
  numberOfAppliedFilters = 0,
}: Props) {
  const { t } = useTranslation()
  const { handleClick } = useFilterButton({
    isDisabled,
    onClick,
  })
  return (
    <FilterButtonWrapper isFilterActive={isFilterActive} isDisabled={isDisabled}>
      <Button
        variant="link"
        onClick={handleClick}
        aria-label={t(i18nBase + 'Filters')}
        data-tracking-id="header-more-filters-button"
        leftIcon={<Icon name={'filtersHorizontalPWA'} color={'primary'} />}>
        {numberOfAppliedFilters && isFilterActive
          ? t(i18nBase + 'AllFiltersCount', { count: numberOfAppliedFilters })
          : t(i18nBase + 'AllFilters')}
      </Button>
    </FilterButtonWrapper>
  )
}
