import type { Money } from '@fiji/graphql/types'
import { insertMorpheme } from '../insert-morpheme'
import type { CurrencyMorpheme } from '../insert-morpheme'
import { roundToDecimal } from './round-to-decimals'

export function formatMoney(
  money: Money,
  options?: {
    hideDecimal?: boolean
    prefix?: boolean
    locale?: string
    isRounded?: boolean
    morpheme?: CurrencyMorpheme
  },
): string {
  const { currency, amount } = money
  const { locale, hideDecimal, prefix, isRounded, morpheme } = options || {}

  const params = {
    style: 'currency',
    currency,
    ...(hideDecimal && { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  }

  const usedLocale = locale || window.navigator.language

  const value = hideDecimal || isRounded ? amount : roundToDecimal(amount)

  const formattedMoney = new Intl.NumberFormat(usedLocale, params).format(value)

  return insertMorpheme(formattedMoney, currency, prefix ? 'prefix' : morpheme)
}
