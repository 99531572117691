import { Service, Inject } from '@etta/di'
import { CarRentalRulesAdapter } from '@etta/modules/rules-modals/infra/car-rental-rules-modal/car-rental-rules-modal.adapter'
import { CarRentalRulesStore } from '../store/car-rental-rules.store'

@Service()
export class CarRentalRulesService {
  constructor(
    @Inject() private carRentalRulesStore: CarRentalRulesStore,
    @Inject() private carRentalRulesAdapter: CarRentalRulesAdapter,
  ) {}

  async getCarRentalRules(carSegmentId: string, transactionId: string) {
    this.carRentalRulesStore.setIsLoading(true)
    const result = await this.carRentalRulesAdapter.getCarRentalRules({
      carSegmentId,
      transactionId,
    })

    result.match({
      Ok: (carRules) => {
        this.carRentalRulesStore.setCarRules(carRules)
        this.carRentalRulesStore.setIsLoading(false)
        this.carRentalRulesStore.setIsError(false)
      },
      Err: () => {
        this.carRentalRulesStore.setIsLoading(false)
        this.carRentalRulesStore.setIsError(true)
      },
    })
  }
}
