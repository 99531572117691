import type { SpinnerColor, SpinnerSizes } from '../types'
import { SpinnerWrapper, IconWrapper } from './spinout-styled'
import { getSize } from './get-size'

type Props = {
  size?: SpinnerSizes
  color?: SpinnerColor
  isAnimated?: boolean
  className?: string
}

export function Spinout({ isAnimated, color = 'primary3', size = 'large', className }: Props) {
  const usedSize = getSize(size)
  return (
    <SpinnerWrapper className={className}>
      <IconWrapper isAnimated={!!isAnimated} size={usedSize} color={color} />
    </SpinnerWrapper>
  )
}
