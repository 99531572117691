import { useMemo } from 'react'
import type { ViewState } from '@etta/ui/view-state-modal/types'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { GazooMembership, LoyaltyProgram } from '@fiji/graphql/hooks'
import { useReviewTripContext } from '@etta/modules/review-trip'
import type { useLoyaltyProgram } from '../../fields/use-loyalty-program'
import type { useSpecialRequest } from '../../fields/use-special-request'
import {
  mapHotelSpecialRequestValue,
  mapHotelSpecialRequestOption,
  mapHotelMembershipOption,
  toHotelMembership,
  toSpecialRequestValues,
} from './mappers'

type Args = {
  onChangeViewState: (viewState: ViewState) => void
}

type HotelSubmitArgs = {
  membership: GazooMembership[]
  specialRequestCodes: string[]
  loyaltyProgramValues?: LoyaltyProgram[]
}

export function useCheckoutHotelPreferences({ onChangeViewState }: Args) {
  const hotelPreferencesModalToggle = useTogglePopup()
  const { checkoutInfoStore, checkoutInfoPreferencesActions } = useCheckoutInfoContext()
  const { reviewTripStore, segmentTypeCheckActions } = useReviewTripContext()
  const { memberships, specialRequests } = checkoutInfoStore
  const { hotelMembershipOptions, hotelMembershipValue, savedHotelMemberShip } = memberships
  const { hotelSpecialRequestOptions, hotelSpecialRequestValue } = specialRequests
  const { segments } = reviewTripStore

  const hotelLoyaltyProgram = useMemo<ReturnType<typeof useLoyaltyProgram>>(() => {
    const vendorCodes = segments.reduce<Set<string>>((acc, segment) => {
      if (segmentTypeCheckActions.isHotelSegment(segment) && segment.vendorCode) {
        acc.add(segment.vendorCode)
      }
      return acc
    }, new Set([]))

    const value = Array.from(vendorCodes).map((code) => {
      const matchByCode = hotelMembershipValue?.find((item) => item.vendorCode === code)
      if (matchByCode?.number) {
        return {
          vendorCode: code,
          number: matchByCode?.number,
          label: matchByCode?.vendorName,
        }
      }
      const matchByChain = hotelMembershipValue?.find((item) =>
        item.appliesToChain.some((chainCode) => chainCode === code),
      )

      return {
        vendorCode: matchByChain?.vendorCode ?? code,
        number: matchByChain?.number,
        label: matchByChain?.vendorName,
      }
    })

    return {
      options: hotelMembershipOptions?.map(mapHotelMembershipOption) || [],
      value,
    }
  }, [hotelMembershipOptions, hotelMembershipValue, segmentTypeCheckActions, segments])
  const hotelSpecialRequest = useMemo<ReturnType<typeof useSpecialRequest>>(() => {
    return {
      options: hotelSpecialRequestOptions?.map(mapHotelSpecialRequestOption) || [],
      value: hotelSpecialRequestValue?.map(mapHotelSpecialRequestValue) || [],
    }
  }, [hotelSpecialRequestOptions, hotelSpecialRequestValue])

  const existingMembership = useMemo(() => {
    return (
      savedHotelMemberShip?.reduce<GazooMembership[]>((acc, membership) => {
        if (membership.number) {
          acc.push({
            vendorCode: membership.vendorCode,
            number: membership.number,
          })
        }
        return acc
      }, []) || []
    )
  }, [savedHotelMemberShip])

  const onHotelPreferencesSubmit = async ({
    specialRequestCodes,
    loyaltyProgramValues = [],
  }: HotelSubmitArgs) => {
    onChangeViewState('saving')
    await delayForSuccess()
    checkoutInfoPreferencesActions.setHotelPreferences({
      membership: toHotelMembership(
        loyaltyProgramValues,
        hotelMembershipOptions || [],
        savedHotelMemberShip,
      ),
      specialRequest: toSpecialRequestValues(specialRequestCodes, hotelSpecialRequestOptions || []),
    })
    onChangeViewState('saved')
    await delayForSuccess()
    hotelPreferencesModalToggle.handleClose()
    onChangeViewState('hidden')
  }

  return {
    value: {
      loyaltyProgram: hotelLoyaltyProgram.value,
      specialRequest: hotelSpecialRequest.value,
    },

    hotelPreferencesModalToggle,
    hotelSpecialRequest,
    hotelLoyaltyProgram,
    existingMembership,
    onHotelPreferencesSubmit,
  }
}
