import styled from 'styled-components'

export const Wrapper = styled.div`
  width: calc(100% - 16px);
  padding-top: 12px;
`

export const Container = styled.div`
  min-height: 260px;
  padding: 12px 0;
`
