import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { HotelSearchFormStore } from '@etta/modules/hotel-search'
import { HotelSearchFormActions } from './actions/hotel-search-form.action'
import { HotelSearchButtonActions } from './actions/hotel-search-button.action'

export const { useModuleContext: useHotelSearchFormContext } = hookContextFactory({
  hotelSearchFormStore: HotelSearchFormStore,
  hotelSearchFormActions: HotelSearchFormActions,
  hotelSearchButtonActions: HotelSearchButtonActions,
})
