import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  CarRentalCarInteraction,
  CarRentalClassType,
  CarRentalType,
} from '@fiji/graphql/types'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental/translate-car-name-details'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { getDifferenceInDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-days-between-start-and-end-Iso-strings'
import type { Policy, Rate, Vendor } from '@fiji/graphql/hooks'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useUserContext } from '@etta/modules/user'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { CarRentalDetailsExcerpt } from './types'

type Args = {
  details: CarRentalDetailsExcerpt
  policy: Policy
  rate: Rate
  vendor?: Vendor
  onCarRentalDetailsOpen: () => void
  pickUp?: CarRentalCarInteraction
  dropOff?: CarRentalCarInteraction
  onAddCar: (carRentalKey: string) => void
  carRentalKey: string
  hasServiceFee: boolean
}

export function useCarRentalSearchSegment({
  details,
  rate,
  onCarRentalDetailsOpen,
  pickUp,
  dropOff,
  onAddCar,
  carRentalKey,
  hasServiceFee,
}: Args) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const { featureFlagsStore } = useFeatureFlagsContext()

  const { locale } = userStore
  const carClass = details.class as CarRentalClassType

  const hasServiceFeeLabel = hasServiceFee && featureFlagsStore.flags.isServiceFeeEnabled

  const { carRentalTitle } = translateCarNameDetails({
    carClass: carClass as CarRentalClassType,
    carType: details.type as CarRentalType,
  })

  const costLabel = formatMoney(rate.secondary || rate.primary, { locale, hideDecimal: true })
  const perDayLabel = t('SearchSegments.CarRental.PerDay')
  const serviceFeeLabel = t('SearchSegments.CarRental.ServiceFee')

  const priceAriaLabel = `${t('SearchSegments.CarRental.Price')} ${costLabel} ${perDayLabel}`

  const handleOpenCarRentalDetails = () => {
    onCarRentalDetailsOpen()
  }

  const handleAddCar = useCallback(() => {
    onAddCar(carRentalKey)
  }, [onAddCar, carRentalKey])

  let estimate: { label?: string; price?: string } = {}
  if (pickUp?.time && dropOff?.time) {
    const daysBetweenDates = getDifferenceInDaysBetweenStartAndEndIsoStrings(
      pickUp.time,
      dropOff.time,
    )
    const currentRate = rate.secondary || rate.primary
    const newRate = {
      ...currentRate,
      amount: currentRate.amount * daysBetweenDates,
    }
    const rateCost = formatMoney(newRate, { locale, hideDecimal: true })

    estimate = {
      label: t('SearchSegments.CarRental.EstimateLabel', { count: daysBetweenDates }),
      price: hasServiceFeeLabel ? `${rateCost} + ${serviceFeeLabel}` : rateCost,
    }
  }

  const carSafetyToggle = useTogglePopup()
  const carDetailsToggle = useTogglePopup()

  return {
    handleOpenCarRentalDetails,
    carRentalTitle,
    priceAriaLabel,
    costLabel,
    perDayLabel,
    estimate,
    handleAddCar,
    carSafetyToggle,
    carDetailsToggle,
  }
}
