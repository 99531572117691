import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { RailTabsBlockDesktop } from './desktop'
import { RailTabsBlockMobile } from './mobile'

export function RailTabsBlockLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailTabsBlockMobile {...props} />}
      desktopSlot={<RailTabsBlockDesktop {...props} />}
    />
  )
}
