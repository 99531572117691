import { useTranslation } from 'react-i18next'
import type { FlightLegType, MobilityLegType } from '@fiji/enums'
import { CarLegType } from '@fiji/enums'
import { placeFormatDate } from './format-dates'

type Args = {
  date?: Date
  type: CarLegType | FlightLegType | MobilityLegType
}

const i18Base = 'TripPlanner.BaseSearch'
const i18Input = 'TripPlanner.BaseSearch.Input'

export function useCarRentalDateTime({ date, type }: Args) {
  const { t } = useTranslation()
  const isPickUp = type === CarLegType.PickUp
  const groupLabel = t(i18Base + (isPickUp ? '.PickUpDateAndTime' : '.DropOffDateAndTime'))

  const testIdDatePicker = isPickUp ? 'date-pick-up' : 'date-drop-off'

  const testIdTimePicker = isPickUp ? 'time-pick-up' : 'time-drop-off'

  const datePlaceholder = t(`${i18Base}.Input.Select`)

  const selectedDate = placeFormatDate(date)
  const selectedDateLabel = t(i18Input + (isPickUp ? '.PickUpDate' : '.DropoffDate'))

  const ariaLabelDate = selectedDate
    ? t(i18Base + '.Input.DateSelectedAriaLabel', {
        label: selectedDateLabel,
        value: selectedDate,
      })
    : t(i18Base + '.Input.DateAriaLabel', { label: selectedDateLabel })

  return {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  }
}
