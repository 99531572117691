import styled from 'styled-components'

export const Container = styled.div<{ fromModal?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(p) => (p.fromModal ? '0px;' : '10px;')};
  margin-top: ${(p) => (p.fromModal ? '0px;' : '10px;')};
`

export const Details = styled.ul<{ fromModal?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(p) => (p.fromModal ? '0 20px 20px 20px;' : '0 10px 10px 10px;')};
  margin: 0;
`
