import styled, { css } from 'styled-components'

export const Container = styled.div<{ isMobile?: boolean }>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  margin: 0;
  padding: 16px 0;
  overflow: hidden;

  ${({ isMobile }) =>
    isMobile &&
    css`
      &:active {
        background-color: ${(p) => p.theme.colors.background};
      }
    `}

  ${({ isMobile, theme }) =>
    !isMobile &&
    css`
      border: 1px solid ${theme.colors.borderLight};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      min-height: 156px;
    `}
`
