import type {
  SeatMapValueObject,
  AirSeatMapValueObject,
  SeatMapRowSeatValueObject,
  SeatMapRowValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import { EnhancedSeatMapCabinClass } from '@fiji/graphql/hooks'
import type { GetAirEnhancedSeatMapQuery } from '@fiji/graphql/hooks'
import { SeatmapCabinClass } from '../../core/enums/seat-map-cabin-class.enum'

type GqlEnhancedSeatMap = Extract<
  GetAirEnhancedSeatMapQuery['enhancedSeatMap'],
  { __typename: 'EnhancedSeatMap' }
>

export class SeatMapMapper {
  static toSeatMapEntity(seatMap?: AirSeatMapValueObject): SeatMapValueObject {
    if (!seatMap) {
      return {
        isLoading: false,
        isError: false,
        rows: [],
        seatRow: null,
        selectedSeat: null,
      }
    }

    return {
      isLoading: false,
      isError: false,
      rows: seatMap.rows,
      seatRow: null,
      selectedSeat: null,
    }
  }

  static toSeatMapEntityFromEnhancedSeatMap(seatMap: GqlEnhancedSeatMap): SeatMapValueObject {
    const rows: SeatMapValueObject['rows'] = seatMap.cabins.reduce((prev, curr) => {
      prev.push(...this.fromGQLCabinToSeatMapRowValueObject(curr))
      return prev
    }, [] as SeatMapValueObject['rows'])

    return {
      isLoading: false,
      isError: false,
      rows: rows,
      seatRow: null,
      selectedSeat: null,
    }
  }

  private static fromGQLCabinToSeatMapRowValueObject(
    input: GqlEnhancedSeatMap['cabins'][number],
  ): SeatMapRowValueObject[] {
    return input.rows.map((v) => this.fromGQLRowToSeatMapRowValueObject(v, input.cabinClass))
  }

  private static mapGQLCabinClassToCabinClass(
    input: GqlEnhancedSeatMap['cabins'][number]['cabinClass'],
  ): SeatmapCabinClass {
    switch (input) {
      case EnhancedSeatMapCabinClass.Coach:
        return SeatmapCabinClass.Coach
      case EnhancedSeatMapCabinClass.PremiumCoach:
        return SeatmapCabinClass.PremiumCoach
      case EnhancedSeatMapCabinClass.Business:
        return SeatmapCabinClass.Business
      case EnhancedSeatMapCabinClass.First:
        return SeatmapCabinClass.First
    }
  }

  private static fromGQLSeatToSeatMapRowSeatValueObject(
    input: GqlEnhancedSeatMap['cabins'][number]['rows'][number]['seat'][number],
  ): SeatMapRowSeatValueObject {
    return {
      number: input.number,
      column: input.column,
      available: input.available,
      isExitRowSeat: input.isExitRowSeat,
      isNearAisle: input.isNearAisle,
      isNearWindow: input.isNearWindow,
      isOverWing: input.isOverWing,
      isPaid: input.isPaid,
      isPremium: input.isPremium,
      isSmokingAllowed: input.isSmokingAllowed,
      isSuitableForHandicapped: input.isSuitableForHandicapped,
      isUpperDeck: input.isUpperDeck,
      hasBulkhead: input.hasBulkhead,
      hasGalley: input.hasGalley,
      hasLavatory: input.hasLavatory,
    }
  }

  private static fromGQLRowToSeatMapRowValueObject(
    input: GqlEnhancedSeatMap['cabins'][number]['rows'][number],
    cabinClass: GqlEnhancedSeatMap['cabins'][number]['cabinClass'],
  ): SeatMapRowValueObject {
    return {
      cabinClass: this.mapGQLCabinClassToCabinClass(cabinClass),
      number: input.number,
      seat: input.seat.map((v) => this.fromGQLSeatToSeatMapRowSeatValueObject(v)),
    }
  }
}
