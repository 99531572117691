import { Inject } from 'typedi'
import { Store } from '@etta/di'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import type { AirTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/air-travel-preference-input.value-object'
import type { HotelTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/hotel-travel-preference-input.value-object'
import type { CarRentalTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/car-rental-preference-input.value-object'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import type { Validator } from '@etta/interface/services/validator'
import { AccountActivationValidatorMaker } from '../validator-maker'
import { UserProfileStore } from './user-profile.store'

@Store()
export class TravelPreferencesStore {
  private readonly _flightPreferencesValidator: ReturnType<
    typeof AccountActivationValidatorMaker.prototype.makeFlightPreferencesValidator
  >
  private readonly _hotelPreferencesValidator: ReturnType<
    typeof AccountActivationValidatorMaker.prototype.makeHotelPreferencesValidator
  >
  private readonly _carrentalPreferencesValidator: ReturnType<
    typeof AccountActivationValidatorMaker.prototype.makeCarrentalPreferencesValidator
  >

  private _additionalInformationValidator: Validator | null = null

  constructor(
    @Inject() private readonly fieldSettingsStore: FieldSettingsStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {
    const validatorMaker = new AccountActivationValidatorMaker(this.fieldSettingsStore)
    this._flightPreferencesValidator = validatorMaker.makeFlightPreferencesValidator()
    this._hotelPreferencesValidator = validatorMaker.makeHotelPreferencesValidator()
    this._carrentalPreferencesValidator = validatorMaker.makeCarrentalPreferencesValidator()
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator?.onFieldChange(fieldName, fieldValue)
  }

  setFlightPreferencesValue<T extends keyof AirTravelPreferenceInputValueObject>(
    field: T,
    value: AirTravelPreferenceInputValueObject[T],
  ) {
    this._flightPreferencesValidator.onFieldChange(field, value)
  }

  setHotelPreferencesValue<T extends keyof HotelTravelPreferenceInputValueObject>(
    field: T,
    value: HotelTravelPreferenceInputValueObject[T],
  ) {
    this._hotelPreferencesValidator.onFieldChange(field, value)
  }

  setCarrentalPreferencesValue<T extends keyof CarRentalTravelPreferenceInputValueObject>(
    field: T,
    value: CarRentalTravelPreferenceInputValueObject[T],
  ) {
    this._carrentalPreferencesValidator.onFieldChange(field, value)
  }

  get isShowAirSeatPreference() {
    return this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.TravelPreferences]
      .airSeatPreference.isRenderedOnActivationPage
  }

  get validators() {
    return {
      flightPreferenceValidator: this._flightPreferencesValidator,
      hotelPreferencesValidator: this._hotelPreferencesValidator,
      carrentalPreferencesValidator: this._carrentalPreferencesValidator,
      additionalInformationValidator: this._additionalInformationValidator,
    }
  }

  get shouldRenderedTravelPreferencesFields(): boolean {
    return Object.entries(
      this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.TravelPreferences],
    ).some(([_, value]) => value.isRenderedOnActivationPage)
  }

  get shouldRenderedAdditionalTravelPreferencesFields(): boolean {
    return Boolean(
      this.userProfileStore.userProfile?.travelPreferences.additionalInformation.length,
    )
  }
}
