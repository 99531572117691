import { css } from 'styled-components'

export const BlockedDay = css`
  // blocked days
  .CalendarDay__blocked_calendar .CalendarDay__title,
  .CalendarDay__blocked_calendar .CalendarDay__background {
    background-color: transparent !important;
  }
  .CalendarDay__blocked_calendar:hover .CalendarDay__title,
  .CalendarDay__blocked_calendar .CalendarDay__title {
    color: ${(props) => props.theme.colors.bodyText2} !important;
  }
`
