import { MediaLayout } from '@etta/ui/media-layout'
import { DynamicSiteMessagesLightboxModalDesktop } from './desktop'
import type { LayoutProps } from './types'

// TODO: Migrate Lightbox Modal to updated design per SPEC-1445 (https://deem.atlassian.net/browse/SPEC-1445) in the next sprint.
export function DynamicSiteMessagesLightboxModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={null}
      desktopSlot={<DynamicSiteMessagesLightboxModalDesktop {...props} />}
    />
  )
}
