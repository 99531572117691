import {
  RootContainer,
  Container,
  ScreenFooter,
  StyledLottieAnimation,
  IdleLottieAnimation,
  ScreenFooterViewport,
} from './permission-screen-styled'
import idle from './idleAnimation.json'
import { useLottieAnimation } from './use-lottie-animation'
import type { PermissionTranslation } from './types'
import { Header } from './header'
import { Footer } from './footer'
import { LOTTIE_TRANSITION_TOTAL_DURATION } from './constants'
import { useFooter } from './use-footer'

type Props = {
  onBack?: () => void
  onSkip?: () => void
  onRequest?: () => Promise<unknown>
  onSuccess: () => void
  onFail?: () => void
  animationSrc: object
  isButtonDisabled?: boolean
  translation: PermissionTranslation
  previousTranslation?: PermissionTranslation
  nextTranslation?: PermissionTranslation
}

const waitLottieTransitionEnd = () =>
  new Promise((resolve) => setTimeout(resolve, LOTTIE_TRANSITION_TOTAL_DURATION))

export function PermissionScreen({
  onBack,
  onSkip,
  animationSrc,
  isButtonDisabled = false,
  onRequest,
  onSuccess,
  onFail,
  translation,
  previousTranslation,
  nextTranslation,
}: Props) {
  const {
    showPreviousFooter,
    setShowPreviousFooter,
    showNextFooter,
    setShowNextFooter,
    previousFooterRef,
    footerRef,
    nextFooterRef,
    footerHeight,
  } = useFooter()
  const {
    animationState,
    setIdleAnimation,
    setTransitionAnimation,
    startTransitionAnimation,
  } = useLottieAnimation()
  const displayNextFooter = async () => {
    if (nextTranslation) {
      setShowNextFooter(true)

      await waitLottieTransitionEnd()
    }
  }
  const handleButtonClick = async () => {
    let isSuccess = false

    try {
      await onRequest?.()

      isSuccess = true
    } catch {
    } finally {
      await startTransitionAnimation()

      await displayNextFooter()
    }

    isSuccess ? onSuccess() : onFail?.()
  }
  const handleSkip = onSkip
    ? async () => {
        await startTransitionAnimation()

        await displayNextFooter()

        onSkip()
      }
    : undefined
  const handleBack = onBack
    ? async () => {
        await startTransitionAnimation()

        setShowPreviousFooter(true)

        await waitLottieTransitionEnd()

        onBack()
      }
    : undefined

  return (
    <RootContainer>
      <Header
        isVisible={!showNextFooter && !showPreviousFooter}
        handleSkip={handleSkip}
        handleBack={handleBack}
      />
      <Container>
        <StyledLottieAnimation
          ref={setTransitionAnimation}
          src={animationSrc}
          zIndex={2}
          keepLastFrame
        />
        <IdleLottieAnimation
          ref={setIdleAnimation}
          zIndex={1}
          isVisible={!animationState.isIdlePlayed && animationState.isIdlePlaying}
          src={idle}
          loop
        />
      </Container>
      <ScreenFooter height={footerHeight}>
        <ScreenFooterViewport showPrevious={showPreviousFooter} showNext={showNextFooter}>
          <Footer
            ref={previousFooterRef}
            isVisible={showPreviousFooter}
            onButtonClick={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
            {...previousTranslation}
          />
          <Footer
            ref={footerRef}
            isVisible={!showNextFooter && !showPreviousFooter}
            onButtonClick={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
            {...translation}
          />
          <Footer
            ref={nextFooterRef}
            isVisible={showNextFooter}
            onButtonClick={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
            {...nextTranslation}
          />
        </ScreenFooterViewport>
      </ScreenFooter>
    </RootContainer>
  )
}
