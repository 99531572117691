import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Amenity } from '@fiji/graphql/types'
import { Swap } from '@etta/ui/swap'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { GridContainer } from '../hotel-details-amenities-desktop-styled'
import { AmenityUnit } from './amenity'

type Props = {
  amenities: Amenity[]
  isOpen: boolean
  onToggle: () => void
}

const VISIBLE_AMENITIES = 12

export function AmenitiesGrid({ amenities, isOpen, onToggle }: Props) {
  const { t } = useTranslation()

  const visibleAmenities = useMemo(() => {
    return amenities.slice(0, VISIBLE_AMENITIES)
  }, [amenities])

  return (
    <>
      <GridContainer>
        <Swap
          is={isOpen}
          isSlot={amenities.map((amenity, i) => (
            <AmenityUnit amenity={amenity} key={amenity.name + i} />
          ))}>
          {visibleAmenities.map((amenity, i) => (
            <AmenityUnit amenity={amenity} key={amenity.name + i} />
          ))}
        </Swap>
      </GridContainer>
      {amenities.length > VISIBLE_AMENITIES && (
        <Block marginTop={-10} marginBottom={24}>
          <Button
            aria-label={t('ReviewTrip.ButtonsLabel', {
              label: t(
                isOpen
                  ? 'HotelInfo.Amenities.showLessAmenities'
                  : 'HotelInfo.Amenities.showMoreAmenities',
              ),
            })}
            paddings={false}
            variant="text"
            onClick={onToggle}>
            {t(
              isOpen
                ? 'HotelDetails.ShowLessAmenitiesDesktop'
                : 'HotelDetails.ShowMoreAmenitiesDesktop',
            )}
          </Button>
        </Block>
      )}
    </>
  )
}
