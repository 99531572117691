import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const ReferencePointBg = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  opacity: 0.5;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.06);
  border-radius: 52px;
  width: 52px;
  height: 52px;
  position: absolute;
  transform: translate(-4px, -4px);
`
export const ReferencePointIconContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 44px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ReferencePointContainer = styled.div`
  position: relative;
  height: 44px;
  width: 44px;
`

export const HotelCardContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: 10;
`

export const ReferencePointTooltipContent = styled.div`
  width: 270px;
`
export const SearchPinPointIcon = styled(Icon)`
  svg {
    z-index: 4;
  }
`
