import { Trans, useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Container, TitleContainer } from './inactive-modal-styled'

type Props = {
  countdownTimer: string
  onStayConnect: () => void
  onLogOut: () => void
}

const i18nBase = 'SessionModal.Inactive'

export function InactiveModal({ countdownTimer, onStayConnect, onLogOut }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <TitleContainer>
        <Text variant="title3" color="mainText" align="center">
          {t(`${i18nBase}.Title`)}
        </Text>
      </TitleContainer>
      <Block marginBottom={32}>
        <Text variant="footnoteMedium" color="bodyText" align="center">
          <Trans i18nKey={`${i18nBase}.Description`}>{{ minutes: countdownTimer }}</Trans>
        </Text>
      </Block>
      <Block marginBottom={16}>
        <Button onClick={onStayConnect} fullWidth>
          {t(`${i18nBase}.StayConnected`)}
        </Button>
      </Block>
      <Button variant="outline" onClick={onLogOut} fullWidth>
        {t('Profile.SignOut')}
      </Button>
    </Container>
  )
}
