export type TripsListValueObject = {
  hasNextPage: boolean
  pageSize: number
  startIndex: number
  totalResults: number
  groups: GroupedTripsByMonth[]
}

export type GroupedTripsByMonth = {
  month: string
  year: string
  trips: TripsList[]
}

export type TripsList = {
  cityImageUrl: string
  lastModifiedTime?: string | null
  name?: string | null
  rollUpEndTime: string
  rollUpStartTime: string
  serviceCategoryList: ServiceCategory[]
  transactionGroupId?: string | null
  transactionId?: string | null
  travelerId?: string | null
  travelerName?: string
  meetingInfo?: TripMeetingInfoValueObject | null
}

export type TripMeetingInfoValueObject = {
  id: string
  name: string
}

export enum TripStatus {
  ActionRequired = 'ACTION_REQUIRED',
  AutoCancelled = 'AUTO_CANCELLED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Reserved = 'RESERVED',
  Ticketed = 'TICKETED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
  WaitListed = 'WAIT_LISTED',
}

export enum ServiceCategory {
  CarService = 'CAR_SERVICE',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  RentalCar = 'RENTAL_CAR',
  RideHail = 'RIDE_HAIL',
  Train = 'TRAIN',
}
