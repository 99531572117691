import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { MediaLayout } from '@etta/ui/media-layout'
import { AlertNotificationButtonMobile } from './mobile'
import { AlertNotificationButtonDesktop } from './desktop'

export function AlertNotificationButtonLayout() {
  const { featureFlags } = useFeatureFlags()
  const { isAlertsEnabled } = featureFlags

  if (!isAlertsEnabled) {
    return null
  }

  return (
    <MediaLayout
      mobileSlot={<AlertNotificationButtonMobile />}
      desktopSlot={<AlertNotificationButtonDesktop />}
    />
  )
}
