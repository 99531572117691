import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { AirSearchQueryType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { AirSearchRestrictions } from '@fiji/hooks/search-queries/use-air-search-query/types'

@Store()
export class AirSearchQueryStore {
  airSearchQueryParams = new QueryParams<AirSearchQueryType>(
    {
      flightType: undefined,
      flights: [],
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: ['flights', 'selectedFlights'],
    },
  )

  constructor(@Inject() private historyStore: HistoryStore) {}

  get selectedFlights() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).selectedFlights
  }

  get filters() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).filters
  }

  get nextLegFilters() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).nextLegFilters
  }

  get isPreSearchFilters() {
    if (!this.additionalQueries.filters) {
      return true
    }
    return Object.keys(this.additionalQueries.filters).length === 0
  }

  get searchId() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).searchId
  }

  get additionalQueries() {
    const {
      bookingId,
      filters,
      searchRestrictions,
      postBookingAction,
      itineraryId,
      isReturnFlightChangeOnly,
    } = this.airSearchQueryParams.getQueryParams(this.historyStore.search)
    const isLocationDisabled =
      searchRestrictions === AirSearchRestrictions.NoDestinationChange ||
      searchRestrictions === AirSearchRestrictions.NoCarrierNoDestinationChange

    const isAirlineCarrierDisabled =
      searchRestrictions === AirSearchRestrictions.NoCarrierChange ||
      searchRestrictions === AirSearchRestrictions.NoCarrierNoDestinationChange

    return {
      bookingId,
      filters,
      isLocationDisabled,
      isAirlineCarrierDisabled,
      searchRestrictions,
      postBookingAction,
      itineraryId,
      isReturnFlightChangeOnly,
    }
  }

  get searchQueryFlights() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).flights
  }

  get searchQueryFlightType() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search).flightType
  }

  get returnFlightSearchStartDate() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search)
      .returnFlightSearchStartDate
  }

  get returnFlightSearchEndDate() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search)
      .returnFlightSearchEndDate
  }

  // is this overlapped with postBookingAction (PostBookingAction.ModifyReturn) in additionalQueries?
  get isReturnFlightChangeOnly() {
    return this.airSearchQueryParams.getQueryParams(this.historyStore.search)
      .isReturnFlightChangeOnly
  }
}
