import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { Dates } from '@etta/ui/date-picker'
import { ScreenType, useScreenType } from '@fiji/modes'

type Args = Dates & {
  isOpen: boolean
  onClose: () => void
  onSelect: (result: Dates) => void
  isRange?: boolean
  withConfirmOneWayButton?: boolean
  withConfirmButton?: boolean
  isDepartureDatePickerDisabled?: boolean
}

export default function useDatePicker({
  date,
  dateEnd,
  onSelect,
  onClose,
  isOpen,
  withConfirmOneWayButton,
  withConfirmButton,
  isRange,
  isDepartureDatePickerDisabled,
}: Args) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const shouldHaveConfirmationButton = withConfirmButton ?? isMobile
  const shouldHaveConfirmationOneWayButton = withConfirmOneWayButton ?? isMobile

  const { t } = useTranslation()
  const [selectedDates, setSelectedDates] = useState<Dates>({ date, dateEnd })

  let isFooterVisible = !!date || !!selectedDates.date
  const isEndDateSelected = !!dateEnd || !!selectedDates.dateEnd

  if (dateEnd) {
    isFooterVisible = !!selectedDates.date && !!selectedDates.dateEnd
  }

  let footerTitle = ''
  if (isEndDateSelected || shouldHaveConfirmationButton) {
    footerTitle = t('DatePickerModal.Confirm')
  } else if (shouldHaveConfirmationOneWayButton) {
    footerTitle = t('DatePickerModal.ConfirmOneway')
  }

  const resetDates = useCallback(() => setSelectedDates({ date, dateEnd }), [date, dateEnd])
  const handleSelect = useCallback(() => {
    if (isDepartureDatePickerDisabled) {
      onSelect({ date, dateEnd: selectedDates.dateEnd })
      onClose()
      return
    }

    onSelect(selectedDates)
    onClose()
  }, [date, selectedDates, onClose, onSelect, isDepartureDatePickerDisabled])

  const handleClose = useCallback(() => {
    resetDates()
    onClose()
  }, [onClose, resetDates])

  useEffect(() => {
    resetDates()
  }, [isOpen, resetDates])

  const handleDates = useCallback(
    ({ date, dateEnd }: Dates) => {
      if (isRange && date && dateEnd) {
        onSelect({ date, dateEnd })
        onClose()
      }

      if (!isRange && date) {
        onSelect({ date })
        onClose()
      }

      setSelectedDates({ date, dateEnd })
    },
    [isRange, onClose, onSelect],
  )

  const handleDatesSelect = useCallback(
    (dates: Dates) => {
      const modifiedDate: Dates = {
        date: isDepartureDatePickerDisabled ? date : dates.date,
        dateEnd: dates.dateEnd,
      }

      if (shouldHaveConfirmationButton || shouldHaveConfirmationOneWayButton) {
        setSelectedDates(modifiedDate)
        return
      }

      handleDates(modifiedDate)
    },
    [
      date,
      shouldHaveConfirmationButton,
      shouldHaveConfirmationOneWayButton,
      isDepartureDatePickerDisabled,
      handleDates,
    ],
  )

  return {
    dates: selectedDates,
    handleDatesSelect,
    handleSelect,
    handleClose,
    footerTitle,
    isFooterVisible,
  }
}
