import { useEffect, useState } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMobilityBookingDone } from '@fiji/hooks/mobility'

export function useMobilityConfirmation(animationTimeout: number) {
  const { rideHailBookActions, rideHailBookStore, searchRideHailStore } = useRideHailSearchContext()

  useEffect(() => {
    rideHailBookActions.handleBook()
    return () => rideHailBookActions.handleDone()
  }, [rideHailBookActions])

  const { handleDone } = useMobilityBookingDone()
  const [shouldShowAnimation, setShouldShowAnimation] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShouldShowAnimation(false)
    }, animationTimeout)
  }, [animationTimeout])

  return {
    // should show animation for at least the animationTimeout duration and continue until the booking completes
    showAnimation: rideHailBookStore.isLoading || shouldShowAnimation,
    bookingError: rideHailBookStore.isError,
    handleDone,
    bookingErrorMessage: rideHailBookStore.errorMessage,
    isShowUberExternalLink: rideHailBookStore.isShowUberExternalLink,
    isOnDemandRide: searchRideHailStore.isOnDemandRide,
  }
}
