import { useSelector } from 'react-redux'
import type { AppState } from '@fiji/store'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal/types'
import { useOutOfPolicyHotelData } from '@fiji/hooks/use-out-of-policy-hotel-data'

export function useHotelBadges(hotelDetails: HotelDetailsType) {
  const { isTripAdvisorReviewsEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )
  const { isOutOfPolicy, isAllRoomsOutOfPolicy, outOfPolicyTexts } = useOutOfPolicyHotelData(
    hotelDetails,
  )

  return {
    isTripAdvisorReviewsEnabled,
    isOutOfPolicy,
    isAllRoomsOutOfPolicy,
    outOfPolicyTexts,
  }
}
