import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import type { RideHailAirportPickupFlightValueObject } from '../../../../core/value-objects/ride-hail-airport-pickup.value-object'
import {
  Container,
  FlightDetailsContainer,
  AirlineLogo,
  Image,
} from './ride-hail-flight-details-components'

const i18nBase = 'Mobility.ReviewFlightDetails'

export type Props = {
  flight: RideHailAirportPickupFlightValueObject | null
  isWhiteBackground: boolean
  isSelectable: boolean
  isSelected: boolean
  onClick?: (flight: RideHailAirportPickupFlightValueObject) => void
}

export const RideHailFlightDetails = ({
  flight,
  isWhiteBackground,
  isSelectable,
  isSelected,
  onClick,
}: Props) => {
  const { t } = useTranslation()

  if (!flight) {
    return null
  }

  const { airlineName, flightDesignator, arrivalAirportCode, arrivalDate, imageUrl } = flight
  const arrivalDateTime = dateFormat(new Date(deleteTimezone(arrivalDate)), 'MMM d, h:mm aaa')

  return (
    <Container
      isWhiteBackground={isWhiteBackground}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => {
        if (onClick) {
          onClick(flight)
        }
      }}>
      <AirlineLogo isWhiteBackground>
        {imageUrl && <Image src={imageUrl} alt={airlineName} />}
      </AirlineLogo>
      <FlightDetailsContainer>
        <Text variant="subHeadStrong" color={isWhiteBackground ? 'mainText' : 'white'}>
          {t(`${i18nBase}.FlightTo`, { airportCode: arrivalAirportCode })}
        </Text>
        <Block marginTop={6}>
          <Text variant="footnoteMedium" color={isWhiteBackground ? 'bodyText' : 'white'}>
            {airlineName} - {flightDesignator}
            <br />
            {t(`${i18nBase}.ScheduledArrivalOn`, { arrivalDateTime })}
          </Text>
        </Block>
      </FlightDetailsContainer>
    </Container>
  )
}
