import type { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { appMode } from '@fiji/modes'
import { EttaLoader } from './etta-loader'
import { FijiLoader } from './fiji-loader'
import { useContextLoader } from './use-context-loader'
import { useInsertWhatFix } from '@root-app/app-routing/context-loader/use-insert-whatfix-snippet'

type Props = {
  children: ReactNode
  loaderSlot: ReactNode
}

export const ContextLoader = observer(function ContextLoader({ children, loaderSlot }: Props) {
  const { isLoading } = useContextLoader()
  useInsertWhatFix()
  const child = isLoading ? loaderSlot : children

  if (appMode.isFijiAll) {
    return <FijiLoader loaderSlot={loaderSlot}>{child}</FijiLoader>
  }

  return <EttaLoader loaderSlot={loaderSlot}>{child}</EttaLoader>
})
