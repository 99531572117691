import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '@etta/ui/error-container'
import type { LayoutProps } from '../types'

export function ErrorStateDesktop({ onSubmit }: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TravelFusionLinks.Error'

  return (
    <ErrorContainer title={t(`${i18nBase}.UnableToLoadInfo`)} align="central" onReload={onSubmit} />
  )
}
