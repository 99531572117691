import { Service, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { RailAdapter } from '../../infra/rail.adapter'
import { RailStore } from '../stores/rail.store'
import { RailEmissionsStore } from '../stores/rail-emissions.store'
import { RailSegmentTravelClassEnum } from '../../core/enums/rail-segment-travel-class.enum'
import { RailEmissionsValidatorService } from './rail-emissions-validator.service'
import { RailEmissionsMapperService } from './rail-emissions-mapper.service'

@Service()
export class RailEmissionsService {
  constructor(
    @Inject() private railAdapter: RailAdapter,
    @Inject() private railStore: RailStore,
    @Inject() private railEmissionsStore: RailEmissionsStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  async getEmissions(travelClass: RailSegmentTravelClassEnum = RailSegmentTravelClassEnum.Economy) {
    if (!this.featureFlagsStore.flags.isSustainabilityEnabled) {
      return
    }

    const newRailLegs =
      this.railStore.railLegs?.filter((rail) => {
        const isRailEmissionExist = this.railEmissionsStore.railEmissions?.rails?.find(
          (existingRail) =>
            rail.legId === existingRail.customRef && existingRail.travelClass === travelClass,
        )

        if (!isRailEmissionExist) {
          return rail
        }
      }) ?? []

    const railEmissionMapper = new RailEmissionsMapperService()
    const tcRails = newRailLegs.map((leg) =>
      railEmissionMapper.mapToThrustCarbonRail(leg, travelClass),
    )
    const validator = new RailEmissionsValidatorService()
    const maybeOriginStationCode = validator.maybeGetOriginStationCode(newRailLegs)
    const maybeDestinationStationCode = validator.maybeGetDestinationStationCode(newRailLegs)
    const maybeRailsValidationError = validator.railEmissionsQueryExecuteValidation({
      rails: tcRails,
      maybeOriginStationCode,
      maybeDestinationStationCode,
    })

    const isSkipValidationError =
      !maybeRailsValidationError.isNothing() &&
      maybeRailsValidationError.getValue().text() === 'Error: Skip validation'

    if (!maybeRailsValidationError.isNothing() && !isSkipValidationError) {
      // TODO: Log other error than 'Error: Skip validation'  to Rollbar
    }

    if (isSkipValidationError || !maybeRailsValidationError.isNothing()) {
      return
    }

    this.railEmissionsStore.setIsLoading(true)

    const result = await this.railAdapter.getEmissions({
      compare: [
        {
          origin: {
            stationCode: maybeOriginStationCode.withDefault(''),
          },
          destination: {
            stationCode: maybeDestinationStationCode.withDefault(''),
          },
        },
      ],
      rails: tcRails,
    })

    result.match({
      Ok: (resp) => {
        this.railEmissionsStore.setIsLoading(false)
        if (resp.errors || !resp.rails) {
          return
        }

        const existingRailEmission = this.railEmissionsStore.railEmissions?.rails ?? []
        const newRails = [...existingRailEmission, ...resp.rails]

        this.railEmissionsStore.setRailEmissions({
          rails: newRails,
          averageEmissionsTonnes: resp.averageEmissionsTonnes,
        })
      },
      Err: () => {
        this.railEmissionsStore.setIsLoading(false)
      },
    })
  }
}
