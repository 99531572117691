import type { LayoutProps } from '../../types'
import { RailDetailsEcoCheckDesktopComponent } from './rail-details-eco-check-desktop-component'
import { RailDetailsEcoCheckDesktopSkeleton } from './rail-details-eco-check-desktop-skeleton'

export function RailDetailsEcoCheckDesktop({
  tonnesOfEmissions,
  averageRouteEmissionTonnes,
  equivalences,
  kgOfActualEmission,
  isLoading,
}: LayoutProps) {
  if (isLoading) {
    return <RailDetailsEcoCheckDesktopSkeleton />
  }

  if (!tonnesOfEmissions || !averageRouteEmissionTonnes || !kgOfActualEmission) {
    return null
  }

  return (
    <RailDetailsEcoCheckDesktopComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageRouteEmissionTonnes={averageRouteEmissionTonnes}
      kgOfActualEmission={kgOfActualEmission}
    />
  )
}
