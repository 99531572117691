import i18n from 'i18next'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { formatMoneyRTP } from '@fiji/utils/money'
import { Location } from '@fiji/graphql/types'

const i18nBasePath = 'TripReview.RulesAndRestrictions.'
export function getCarRentalRules(segment: CarRentalSegmentEntity) {
  let label
  const carRentalRules: string[] = []
  const service = segment.serviceFee
  if (service?.id) {
    carRentalRules.push(
      ...[
        i18n.t(mapCarRentalServiceDescription(service.id), {
          amount: service.rate?.amount ? formatMoneyRTP('en', service.rate) : '',
          location: resolveCarAddress(segment),
        }),
      ],
    )
    label = i18n.t(i18nBasePath + 'Car')
    return {
      rules: carRentalRules,
      label,
    }
  }
}

function mapCarRentalServiceDescription(code: string) {
  const base = 'TripReview.RulesAndRestrictions.ServiceFees.'
  if (code === 'CARRENTAL_SERVICE_FEE_0') {
    return base + 'CarrentalServiceFee_0'
  }
  return base + 'DefaultCarService'
}

export function resolveCarAddress(segment: CarRentalSegmentEntity): string {
  const location = segment.pickUpLocation.address
  const locationType = segment.carLocationType
  let addressResolved = `${location.city} ${location.stateCode}`
  if (locationType) {
    switch (locationType.pickup.type) {
      case Location.Airport:
        addressResolved = locationType.pickup.code || location.city
        break
      case Location.Private:
        addressResolved = [
          location.street1,
          location.city,
          location.stateCode,
          location.countryCode,
        ]
          .filter(Boolean)
          .join(', ')
        break
    }
  }

  return addressResolved
}
