import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { DatePickerModalMobile } from './mobile'
import { DatePickerModalDesktop } from './desktop'

export function DatePickerModal(props: Props) {
  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<DatePickerModalMobile {...props} />}
      desktopSlot={<DatePickerModalDesktop {...props} />}
    />
  )
}
