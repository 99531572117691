import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { DesktopFooter } from '@etta/components/desktop-footer'
import { DesktopNavigation } from '@etta/modules/navigation'
import { Icon } from '@etta/ui/icon'
import { ScreenDesktop } from '@etta/ui/screen'
import { useTravelSupportContext } from '../interface/use-travel-support.context'
import { TravelSupportArticleContent } from './travel-support-article-content'
import {
  ArticleWrapper,
  BackLink,
  Document360Container,
  PageColumn,
  PageContent,
} from './travel-support-article-styled'
import { TravelSupportArticlePagination } from './travel-support-article-pagination'

const i18Base = 'TripPlanner.Support.MainPage'
export const TravelSupportArticle = observer(() => {
  const { t } = useTranslation()
  const { articleId } = useParams<{ articleId: string }>()
  const { travelSupportActions } = useTravelSupportContext()

  useEffect(() => {
    if (!articleId) {
      return
    }

    travelSupportActions.handleLoadSupportArticle(articleId)
  }, [travelSupportActions, articleId])

  return (
    <ScreenDesktop>
      <ScreenDesktop.Header>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <PageContent>
        <PageColumn>
          <BackLink size="normal" onClick={travelSupportActions.handleNavigateToMain}>
            <Icon name="arrowBackLargePWA" />
            {t(`${i18Base}.Back`)}
          </BackLink>
          <ArticleWrapper>
            <Document360Container>
              <TravelSupportArticleContent />
            </Document360Container>
          </ArticleWrapper>
          <TravelSupportArticlePagination articleId={articleId} />
        </PageColumn>
      </PageContent>
      <DesktopFooter />
    </ScreenDesktop>
  )
})
