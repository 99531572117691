import type { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'

export type HighlightElementValueObject = {
  fieldSettingsCategory: FieldSettingsCategory
  elementId: number
}

export function areHighlightedElementEquals(
  el1: HighlightElementValueObject,
  el2: HighlightElementValueObject,
) {
  if (el1.fieldSettingsCategory !== el2.fieldSettingsCategory) {
    return false
  }

  if (el1.elementId !== el2.elementId) {
    return false
  }

  return true
}
