import styled from 'styled-components'

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
`

export const Filter = styled.div`
  padding: 6px 16px;
  position: relative;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  > svg {
    position: absolute;
    width: 13px;
    height: 13px;
    left: 35px;
    top: 21px;
    path {
      fill: ${(p) => p.theme.colors.bodyText};
    }
  }
`
