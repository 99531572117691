import styled from 'styled-components'
import { Link } from '@etta/ui/link'

export const MapWrapper = styled.div`
  height: 160px;
`

export const VendorLogo = styled.img`
  display: block;
  width: 96px;
  height: auto;
  margin: 12px 0;
`

export const Address = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  > * + * {
    margin-left: 8px;
  }
`

export const WorkingHours = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const PhoneLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
