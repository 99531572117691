import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'

@Store()
export class GlobalModalsStore {
  sessionModalToggle = new Toggle()

  tripExpiredModalToggle = new Toggle()

  get isOnlyTripExpiredModalOpen() {
    return this.tripExpiredModalToggle.isOpen && !this.sessionModalToggle.isOpen
  }
}
