import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { ValidatedTicketValueObject } from '@etta/modules/review-trip/core/value-objects'
import { FlightsSection, HotelsSection, RailSection, CarRentalSection } from '../blocks'

type Props = {
  totalFlightTripCosts: {
    total: string
    base: string
    feesAndTaxes: string
  }
  totalNowHotelTripCost: string
  totalNowCarRentalTripCost: string
  totalRailTripCost: string
  hasFlightTripCost: boolean
  hasNowHotelTripCost: boolean
  hasNowCarRentalTripCost: boolean
  hasRailTripCost: boolean
  unusedTicket?: ValidatedTicketValueObject | null
  isPayNow: boolean
}

export function PayNowSection({
  totalFlightTripCosts,
  totalNowHotelTripCost,
  totalNowCarRentalTripCost,
  totalRailTripCost,
  hasFlightTripCost,
  hasNowHotelTripCost,
  hasNowCarRentalTripCost,
  hasRailTripCost,
  isPayNow,
}: Props) {
  const { t } = useTranslation()

  if (!isPayNow) {
    return null
  }

  return (
    <div>
      <Text variant="captionStrongCaps" color="mainText" isBlock>
        {t('ReviewTrip.PriceSummary.PayableNow')}
      </Text>
      <FlightsSection
        totalFlightTripCosts={totalFlightTripCosts}
        hasFlightTripCost={hasFlightTripCost}
      />
      <HotelsSection
        totalHotelTripCost={totalNowHotelTripCost}
        hasHotelTripCost={hasNowHotelTripCost}
      />
      <CarRentalSection
        totalCarRentalTripCost={totalNowCarRentalTripCost}
        hasCarRentalTripCost={hasNowCarRentalTripCost}
      />
      <RailSection totalRailTripCost={totalRailTripCost} hasRailTripCost={hasRailTripCost} />
    </div>
  )
}
