import { Inject, Service } from '@etta/di'
import { ItineraryAdapter } from '@etta/modules/review-trip/infra'
import type { RemoveRailArgs, AddRailArgs } from '../types'

@Service()
export class ReviewTripRailService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
  ) {}

  addRail({ itineraryId, key, tripName, direction, routeType }: AddRailArgs) {
    return this.itineraryAdapter.addRail({
      itineraryId,
      key,
      tripName,
      direction,
      routeType,
    })
  }

  removeRail({ itineraryId, railId }: RemoveRailArgs) {
    return this.itineraryAdapter.removeRail({
      itineraryId,
      railId,
    })
  }
}
