import type { ReactNode } from 'react'
import { Radio } from '@etta/ui/radio'
import { DisabledRadio } from './credit-card-item-styled'

type Props = {
  onChange: () => void
  isSelectable: boolean
  isDisabled: boolean
  isChecked: boolean
  children: ReactNode
}

export function Edit({ isSelectable, onChange, isChecked, children, isDisabled }: Props) {
  if (isDisabled) {
    return (
      <DisabledRadio onChange={() => {}} isChecked={isChecked} isDisabled>
        {children}
      </DisabledRadio>
    )
  }
  if (isSelectable) {
    return (
      <Radio isChecked={isChecked} onChange={onChange}>
        {children}
      </Radio>
    )
  }

  return <>{children}</>
}
