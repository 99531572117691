import styled, { css } from 'styled-components'
import * as Typography from '@fiji/style/typography'
import { KeyboardNavigationStyle } from '@fiji/style'
import { InputButtonSize } from '../types'

const buttonErrorBorder = css`
  outline: 1px solid ${(p) => p.theme.colors.error};
  outline-offset: -1px;
  border: none;
  z-index: 5;
`

export const Button = styled.button<{
  hasError: boolean
}>`
  position: relative;
  outline: none;
  border: none;
  ${(p) => p.hasError && buttonErrorBorder}
  width: 100%;
  min-height: 64px;
  border-radius: 8px;
  padding: 10px 12px 10px 16px;
  text-align: left;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}

  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};

  &:active {
    background-color: ${(props) => props.theme.colors.background1};
  }
  ${KeyboardNavigationStyle}
`

type LabelProps = {
  isValueExist: boolean
  isIconExist: boolean
  size: InputButtonSize
}

export const Label = styled.div<LabelProps>`
  display: flex;
  color: ${(props) =>
    props.isValueExist ? props.theme.colors.bodyText : props.theme.colors.bodyText1};

  ${(props) => {
    if (props.size === InputButtonSize.Big) {
      return props.isValueExist ? Typography.captionMedium : Typography.bodyLarge
    }

    if (props.isIconExist && !props.isValueExist) {
      return Typography.subHeadMedium
    }

    return props.isValueExist ? Typography.captionMedium : Typography.subHeadStrong
  }}
`

type ValueProps = {
  size: InputButtonSize
  isMediumWeight?: boolean
  isAvalible: boolean
}

export const Value = styled.div<ValueProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: ${(props) =>
    props.isAvalible ? props.theme.colors.mainText : props.theme.colors.bodyText2};

  ${(props) => {
    if (props.size === InputButtonSize.Big) {
      return Typography.bodyLarge
    }

    if (props.isMediumWeight) {
      return Typography.subHeadMedium
    }

    return Typography.subHeadStrong
  }};
`

export const ValueWrapper = styled.div<{ size: InputButtonSize; isLabelExist: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) =>
    props.size === InputButtonSize.Big || !props.isLabelExist ? '0' : '8px'};
`

export const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.bodyText1};
`
