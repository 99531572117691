import { Store } from '@etta/di'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'

@Store()
export class GetCustomerUberAccountStatusStore {
  private _isError: boolean = false
  private _errorMessage: string = ''
  private _linkingUrl: string = ''
  private _status: UberAccountStatusEnum = UberAccountStatusEnum.Unspecified
  private _isUserAccountSelfCreationEnabled: boolean = false

  get isError() {
    return this._isError
  }

  setIsError(isError: boolean) {
    this._isError = isError
  }

  get errorMessage() {
    return this._errorMessage
  }

  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage
  }

  get linkingUrl() {
    return this._linkingUrl
  }

  setLinkingUrl(linkingUrl: string) {
    this._linkingUrl = linkingUrl
  }

  get status() {
    return this._status
  }

  setStatus(status: UberAccountStatusEnum) {
    this._status = status
  }

  get isUserAccountSelfCreationEnabled() {
    return this._isUserAccountSelfCreationEnabled
  }

  setIsUserAccountSelfCreationEnabled(isUserAccountSelfCreationEnabled: boolean) {
    this._isUserAccountSelfCreationEnabled = isUserAccountSelfCreationEnabled
  }

  get isUserAccountStatusReady() {
    return this._status === UberAccountStatusEnum.Ready
  }

  dropStore() {
    this.setIsError(false)
    this.setErrorMessage('')
    this.setLinkingUrl('')
    this.setStatus(UberAccountStatusEnum.Unspecified)
    this.setIsUserAccountSelfCreationEnabled(false)
  }
}
