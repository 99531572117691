import styled, { css } from 'styled-components'
import type { HeaderLayout } from '../types'

export const LeftSlot = styled.div<{
  layout: HeaderLayout
}>`
  min-height: 0;
  margin: 0;

  ${(props) => {
    switch (props.layout) {
      case 'content-view':
        return css`
          min-height: 24px;
          margin: 8px 0 8px 16px;
        `
      case 'aligned':
        return css`
          min-height: 32px;
          margin: 12px 24px 12px 8px;
        `
      case 'search-input':
        return css`
          min-height: 32px;
          margin: 12px 16px 12px 8px;
        `
      case 'seat-map':
        return css`
          margin: 6px 21px 18px 8px;
        `
      case 'search-result':
        return css`
          margin: 15px 8px 15px 15px;
        `
      case 'terminal':
        return css`
          margin: 15px 17px 19px 8px;
        `
      case 'caption':
        return css`
          margin: 10px 10px 22px 16px;
        `
      case 'title-icon':
        return css`
          margin: 20px 8px 20px 32px;
        `
      case 'rtp-info-modal':
        return css`
          margin: 12px 8px 12px 32px;
        `
      case 'flight-check-in':
        return css`
          min-height: 32px;
          margin: 20px 8px 20px 32px;
        `
      default:
        return css`
          min-height: 32px;
          margin: 12px 24px 12px 8px;
        `
    }
  }}
`
