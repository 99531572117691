import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import { ListResultHeaderDesktop } from './desktop'
import { ListResultHeaderMobile } from './mobile'
import type { LayoutProps } from './types'

export function ListResultHeaderLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<ListResultHeaderMobile {...props} />}
      desktopSlot={<ListResultHeaderDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutFlightFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
