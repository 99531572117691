import { Store } from '@etta/di'
import type {
  MultiModalRouteValueObject,
  SearchMultiModalFormValueObject,
  MultiModalRouteList,
} from '@etta/modules/multi-modal-search/core'
import { MultiModalRouteType } from '../../core/enum/multi-modal-route-type.enum'

@Store()
export class MultiModalSearchStore {
  private _searchMultiModalForm: SearchMultiModalFormValueObject = {}
  private _routesList: MultiModalRouteList = []
  private _isError: boolean = false
  private _isLoading: boolean = false

  get sortRoutesList() {
    const sortByRouteType = (
      route1: MultiModalRouteValueObject,
      route2: MultiModalRouteValueObject,
    ) => {
      if (route1.routeType === MultiModalRouteType.Transit) {
        return -1
      }
      if (route2.routeType === MultiModalRouteType.Driving) {
        return 1
      }
      return 0
    }

    return [...this._routesList].sort(sortByRouteType)
  }

  get searchMultiModalForm() {
    return this._searchMultiModalForm
  }

  get isError() {
    return this._isError
  }

  get isLoading() {
    return this._isLoading
  }

  setSearchMultiModalForm(form: SearchMultiModalFormValueObject) {
    this._searchMultiModalForm = form
  }

  setRouteList(routeList: MultiModalRouteList) {
    this._routesList = routeList
  }

  setIsError(error: boolean) {
    this._isError = error
  }

  setIsLoading(load: boolean) {
    this._isLoading = load
  }

  dropStore() {
    this._routesList = []
    this._searchMultiModalForm = {}
  }
}
