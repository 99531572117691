import { observer } from 'mobx-react-lite'
import { useMobilitySearch } from './use-mobility-search'
import { MobilitySearchPageProvider } from './mobility-search-page-provider'
import { MobilitySearchPageContent } from './mobility-search-page-content'

export const MobilitySearchPage = observer(function MobilitySearchPage() {
  const value = useMobilitySearch()

  return (
    <MobilitySearchPageProvider value={value}>
      <MobilitySearchPageContent />
    </MobilitySearchPageProvider>
  )
})
