import type { Props } from '../types'
import { InputButtonSize } from '../types'
import { InputIcon } from '../input-icon'
import { Button, Value, Label, ValueWrapper, Placeholder } from './input-button-desktop-styled'

export function InputButtonDesktop({
  value,
  icon,
  iconRight,
  label,
  onClick,
  size = InputButtonSize.Normal,
  placeholder,
  isMediumWeight,
  className,
  isFilled = true,
  isInteractive = true,
  'aria-label': ariaLabel,
  'data-tracking-id': dataTrackingId,
  disabled,
}: Props) {
  const isValueExist = !!(value || placeholder)
  const isIconExist = !!icon

  return (
    <Button
      disabled={disabled}
      data-tracking-id={dataTrackingId}
      aria-label={ariaLabel || label}
      onClick={() => (isInteractive ? onClick() : null)}
      tabIndex={0}
      className={className}>
      <Label aria-hidden isValueExist={isValueExist} isIconExist={isIconExist} size={size}>
        {!isValueExist && <InputIcon icon={icon} isValueExist={isValueExist} />}
        <div>{label}</div>
      </Label>
      {isValueExist && (
        <ValueWrapper size={size} isLabelExist={!!label}>
          <InputIcon
            icon={icon}
            isValueExist={isValueExist}
            isPlaceholder={!!(placeholder && !value)}
            color={isFilled ? 'mainText' : 'bodyText2'}
            size="small"
          />
          <Value size={size} isMediumWeight={isMediumWeight} isAvalible={isFilled}>
            {value || <Placeholder>{placeholder}</Placeholder>}
          </Value>
          <InputIcon icon={iconRight} isValueExist={isValueExist} />
        </ValueWrapper>
      )}
    </Button>
  )
}
