import type { FlightSafetyCheckDetails } from '@fiji/types'
import { MediaLayout } from '@etta/ui/media-layout'
import { FlightSafetyCheckItemMobile } from './mobile'
import { FlightSafetyCheckItemDesktop } from './desktop'

type Props = {
  detail: FlightSafetyCheckDetails
  isAirlineName: boolean
  isDisclaimer: boolean
  isSubtitle: boolean
}

export function FlightSafetyCheckLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<FlightSafetyCheckItemMobile {...props} />}
      desktopSlot={<FlightSafetyCheckItemDesktop {...props} />}
    />
  )
}
