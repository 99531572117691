import { SeatMapSegment } from '../../seat-map-segment'
import type { LayoutProps } from '../../seat-map.types'

export function SeatMapDesktop({
  airSeatMapRef,
  seatRows,
  onUnavailableSeatMapContinue,
  footerHeight,
  onSeatChecked,
  bookedSeat,
  selectedSeat,
  seatsNumberList,
  isReadonly,
  isNewSeatMap,
  onOpenPopover,
}: LayoutProps) {
  return (
    <SeatMapSegment
      ref={airSeatMapRef}
      seatRows={seatRows}
      onUnavailableSeatMapContinue={onUnavailableSeatMapContinue}
      footerHeight={footerHeight}
      onSeatChecked={onSeatChecked}
      seatsNumberList={seatsNumberList}
      bookedSeat={bookedSeat}
      selectedSeat={selectedSeat}
      isReadonly={isReadonly}
      isNewSeatMap={isNewSeatMap}
      onOpenPopover={onOpenPopover}
    />
  )
}
