import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { ROUTES } from '@fiji/routes'
import { MeetingNameBadgeDesktop } from '@etta/modules/meeting/ui/meeting-information/components/meeting-name-badge-desktop'
import { Time, Wrapper } from '../../trip-card-styles'
import { TripCardServiceIcon } from '../../trip-card-service-icon'
import defaultBackground from '../../background.svg?url'
import type { LayoutProps } from '../types'
import { TravelerNameBadgeDesktop } from '../traveler-name-badge'
import {
  Container,
  Image,
  ContainerWrapper,
  MainInfo,
  Name,
  ServiceIconWrapper,
  TravelerNameBlock,
  BottomBlock,
} from './trip-card-desktop-styled'

const i18Base = 'PostBooking.TripDetails'

export function TripCardDesktop({
  tripCardAriaText,
  onCardClick,
  transactionGroupId,
  transactionId,
  tripDateText,
  cityImageUrl,
  cityImageText,
  name,
  services,
  isCancelled,
  itineraryId,
  travelerName,
  meetingInfo,
}: LayoutProps) {
  const { t } = useTranslation()
  const bookingRefText = t(`${i18Base}.BookingRef`, { bookingRef: itineraryId })

  return (
    <Wrapper
      data-tracking-id="trip-card"
      aria-label={tripCardAriaText}
      $isFirst={false}
      to={ROUTES.postBooking.trip({
        id: transactionId || '',
        transactionGroupId: transactionGroupId || undefined,
        isForceRefetch: true,
      })}
      onClick={onCardClick}>
      <Container>
        <ContainerWrapper>
          <Image src={cityImageUrl || defaultBackground} alt={cityImageText} isGrey={isCancelled} />
          <MainInfo>
            <Time>{tripDateText}</Time>
            <Name role="heading" aria-level={2} data-tracking-id="booking-name-text">
              {name}
            </Name>
            {meetingInfo && <MeetingNameBadgeDesktop meetingName={meetingInfo.name} />}
            {itineraryId && (
              <BottomBlock>
                <Text variant={'footnoteStrong'} color={'bodyText1'}>
                  {bookingRefText}
                </Text>
              </BottomBlock>
            )}
          </MainInfo>
        </ContainerWrapper>
        <ServiceIconWrapper aria-hidden>
          {services.map((service) => (
            <TripCardServiceIcon key={service} name={service} size="medium" />
          ))}
        </ServiceIconWrapper>

        <TravelerNameBlock>
          <TravelerNameBadgeDesktop name={travelerName} />
        </TravelerNameBlock>
      </Container>
    </Wrapper>
  )
}
