import i18n from '@fiji/i18n'
import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { ValidatorMaker } from '@etta/modules/settings/validators/validator-maker'
import { Validator } from '@etta/interface/services/validator'
import { FieldSettingsCategory } from '../../core/enums/field-settings-category'
import { FieldSettingsStore } from './field-settings'

const i18nBase = 'Settings.AccountInformation.Modal'

export type EmployeeInformationInput = {
  isVip: boolean | null
  isActiveInCompany: boolean | null
  employeeType: string
  employeeId: string
  jobTitle: string
  jobLevel: string
  managerId: string
  costCenter: string
  departmentCode: string
  departmentName: string
  division: string
  businessUnit: string
  companyName: string
  approvers: {
    expenseApproverId: string
    purchaseApproverId: string
    travelApproverId: string
  }
  mis: {
    mis1: string
    mis2: string
    mis3: string
    mis4: string
    mis5: string
    mis6: string
    mis7: string
  }
}

@Store()
export class EmployeeInformationStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  isSaveButtonDisabled = true

  toggle = new Toggle()

  employeeInformationValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeEmployeeInformationValidator()

  private _additionalInformationValidator: Validator = new Validator({})
  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }

  setEmployeeInformation(employeeInformation: EmployeeInformationInput) {
    this.setEmployeeInformationValue('isVip', employeeInformation.isVip)
    this.setEmployeeInformationValue('isActiveInCompany', employeeInformation.isActiveInCompany)
    this.setEmployeeInformationValue('employeeType', employeeInformation.employeeType)
    this.setEmployeeInformationValue('employeeId', employeeInformation.employeeId)
    this.setEmployeeInformationValue('jobTitle', employeeInformation.jobTitle)
    this.setEmployeeInformationValue('jobLevel', employeeInformation.jobLevel)
    this.setEmployeeInformationValue('managerId', employeeInformation.managerId)
    this.setEmployeeInformationValue('costCenter', employeeInformation.costCenter)
    this.setEmployeeInformationValue('departmentCode', employeeInformation.departmentCode)
    this.setEmployeeInformationValue('departmentName', employeeInformation.departmentName)
    this.setEmployeeInformationValue('division', employeeInformation.division)
    this.setEmployeeInformationValue('businessUnit', employeeInformation.businessUnit)
    this.setEmployeeInformationValue('companyName', employeeInformation.companyName)

    this.setEmployeeInformationApprover(
      'expenseApproverId',
      employeeInformation.approvers.expenseApproverId,
    )
    this.setEmployeeInformationApprover(
      'purchaseApproverId',
      employeeInformation.approvers.purchaseApproverId,
    )
    this.setEmployeeInformationApprover(
      'travelApproverId',
      employeeInformation.approvers.travelApproverId,
    )

    this.setEmployeeInformationMis('mis1', employeeInformation.mis.mis1)
    this.setEmployeeInformationMis('mis2', employeeInformation.mis.mis2)
    this.setEmployeeInformationMis('mis3', employeeInformation.mis.mis3)
    this.setEmployeeInformationMis('mis4', employeeInformation.mis.mis4)
    this.setEmployeeInformationMis('mis5', employeeInformation.mis.mis5)
    this.setEmployeeInformationMis('mis6', employeeInformation.mis.mis6)
    this.setEmployeeInformationMis('mis7', employeeInformation.mis.mis7)
  }

  setEmployeeInformationValue<T extends keyof Omit<EmployeeInformationInput, 'approvers' | 'mis'>>(
    field: T,
    value: EmployeeInformationInput[T],
  ) {
    const defaultValueToSubmit = this.fieldSettingsStore.getEmployeeInfoDefaultValue(field)
    if (defaultValueToSubmit && defaultValueToSubmit !== value) {
      this.setIsSaveButtonDisabled(false)
    }
    this.employeeInformationValidator.onFieldChange(
      field,
      value !== null && value !== undefined ? value : defaultValueToSubmit,
    )
  }

  setEmployeeInformationApprover<T extends keyof EmployeeInformationInput['approvers']>(
    field: T,
    value: EmployeeInformationInput['approvers'][T],
  ) {
    this.employeeInformationValidator.onFieldChange(field, value)
  }

  setEmployeeInformationMis<T extends keyof EmployeeInformationInput['mis']>(
    field: T,
    value: EmployeeInformationInput['mis'][T],
  ) {
    const defaultValueToSubmit = this.fieldSettingsStore.getEmployeeInfoDefaultValue(field)
    if (defaultValueToSubmit && defaultValueToSubmit !== value) {
      this.setIsSaveButtonDisabled(false)
    }
    this.employeeInformationValidator.onFieldChange(field, value || defaultValueToSubmit)
  }

  get employeeStatusValue() {
    const {
      values: { isActiveInCompany },
    } = this.employeeInformationValidator

    return (
      i18n.t(
        `Settings.AccountInformation.Modal.Statuses.${isActiveInCompany ? 'Active' : 'Inactive'}`,
      ) || '-'
    )
  }

  get misFields() {
    return [...Array(7).keys()].map((num) => ({
      name: `mis${num + 1}`,
      label: this.fieldSettingsStore.getFieldLabel(
        `mis${num + 1}` as keyof EmployeeInformationInput['mis'],
        FieldSettingsCategory.EmployeeInformation,
        i18n.t(`${i18nBase}.MISField${num + 1}`),
      ),
      value:
        this.employeeInformationValidator.values[
          `mis${num + 1}` as keyof EmployeeInformationInput['mis']
        ] || '',
    }))
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
