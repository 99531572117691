import styled, { css } from 'styled-components'

export const WheelPickerContainer = styled.div<{ minWidth?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 240px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;

  :before,
  :after {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    display: block;
    height: calc(50% - 32px / 2);
    z-index: 1;
    pointer-events: none;
  }

  :before {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    top: -0.5px;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
  }
  :after {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    bottom: -0.5px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`

export const Highlight = styled.div`
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  height: 34px;
  background: #ecedee;
  border-radius: 8px;
  pointer-events: none;
  z-index: -1;
`

export const Container = styled.div<{ minWidth?: string; textAlign?: string }>`
  min-width: ${({ minWidth }) => minWidth};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  ${({ textAlign }) => getMargin(textAlign)}
`

export const PickerViewport = styled.div<{ perspective: 'middle' | 'left' | 'right' }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  perspective: 1000px;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${({ perspective }) => getPerspectiveOrigin(perspective)}
`

const getPerspectiveOrigin = (perspective: 'middle' | 'left' | 'right') => {
  if (perspective === 'left') {
    return css`
      perspective-origin: calc(50% + 50px) 50%;
    `
  }

  if (perspective === 'right') {
    return css`
      perspective-origin: calc(50% - 50px) 50%;
    `
  }

  return ''
}

export const PickerContainer = styled.div`
  height: 34px;
  width: 100%;
  transform-style: preserve-3d;
  will-change: transform;
`

export const PickerSlide = styled.div<{
  wheelReady: boolean
  textAlign?: string
  opacity: number
  transform: string
}>`
  cursor: pointer;
  top: 0 !important;
  left: 0 !important;
  text-align: center;
  backface-visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 34px;
  line-height: 34px;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  position: ${({ wheelReady }) => (wheelReady ? 'absolute' : 'static')};
  opacity: ${({ opacity, wheelReady }) => (wheelReady ? opacity : 0)};
  transform: ${({ transform, wheelReady }) => (wheelReady ? transform : 'none')};
  display: block;
`

function getMargin(textAlign?: string) {
  if (textAlign === 'right') {
    return css`
      margin-right: 14px;
    `
  }
  if (textAlign === 'left') {
    return css`
      margin-left: 14px;
    `
  }
}
