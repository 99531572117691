import { useEffect, useState } from 'react'

type Props = {
  onOnline?: () => void
  onOffline?: () => void
  onStatusChanged?: () => void
}

export function useNetworkStatus({ onOnline, onOffline, onStatusChanged }: Props = {}) {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
      onOnline?.()
      onStatusChanged?.()
    }
    const handleOffline = () => {
      setIsOnline(false)
      onOffline?.()
      onStatusChanged?.()
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [onOffline, onOnline, onStatusChanged])

  return { isOnline, isOffline: !isOnline }
}
