import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { HeldTripsMobilePage } from './mobile'
import { HeldTripsDesktopPage } from './desktop'

export function HeldTripsPageLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HeldTripsMobilePage {...props} />}
      desktopSlot={<HeldTripsDesktopPage {...props} />}
    />
  )
}
