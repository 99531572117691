import type { RouteItem } from '@fiji/routes'
import { appMode, ScreenType, useScreenType } from '@fiji/modes'

export function useProgressiveFooterVisible(currentRouteItem?: RouteItem) {
  const screenType = useScreenType()

  const { isEttaAll } = appMode
  const isMobile = isEttaAll && screenType === ScreenType.Mobile
  const isDeemWrapperUsed = currentRouteItem?.isDeemWrapperUsed
  const isNavBarHidden = currentRouteItem?.isNavBarHidden

  const isFooterVisible = isMobile && !isNavBarHidden && !isDeemWrapperUsed

  return {
    isFooterVisible,
  }
}
