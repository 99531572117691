import { useState, useRef, useCallback, useEffect } from 'react'
import type { EmblaCarouselType } from 'embla-carousel-react'
import useEmblaCarousel from 'embla-carousel-react'
import {
  Container,
  PickerContainer,
  PickerSlide,
  PickerViewport,
} from './wheel-date-time-picker-styled'

export type WheelPickerItemValue = {
  value: string
  label: string
}

const CIRCLE_DEGREES = 360
const WHEEL_ITEM_SIZE = 30
const WHEEL_ITEM_COUNT = 18
const WHEEL_ITEMS_IN_VIEW = 4
const WHEEL_ITEM_RADIUS = CIRCLE_DEGREES / WHEEL_ITEM_COUNT
const IN_VIEW_DEGREES = WHEEL_ITEM_RADIUS * WHEEL_ITEMS_IN_VIEW
const WHEEL_RADIUS = Math.round(WHEEL_ITEM_SIZE / 2 / Math.tan(Math.PI / WHEEL_ITEM_COUNT))

const isInView = (wheelLocation: number, slidePosition: number): boolean =>
  Math.abs(wheelLocation - slidePosition) < IN_VIEW_DEGREES

type SlideStylesType = {
  opacity: number
  transform: string
}

const getSlideStyles = (
  emblaApi: EmblaCarouselType,
  index: number,
  loop: boolean,
  slideCount: number,
  totalRadius: number,
): SlideStylesType => {
  const wheelLocation = emblaApi.scrollProgress() * totalRadius
  const positionDefault = emblaApi.scrollSnapList()[index] * totalRadius
  const positionLoopStart = positionDefault + totalRadius
  const positionLoopEnd = positionDefault - totalRadius

  let inView = false
  let angle = index * -WHEEL_ITEM_RADIUS

  if (isInView(wheelLocation, positionDefault)) {
    inView = true
  }

  if (loop && isInView(wheelLocation, positionLoopEnd)) {
    inView = true
    angle = -CIRCLE_DEGREES + (slideCount - index) * WHEEL_ITEM_RADIUS
  }

  if (loop && isInView(wheelLocation, positionLoopStart)) {
    inView = true
    angle = -(totalRadius % CIRCLE_DEGREES) - index * WHEEL_ITEM_RADIUS
  }

  if (inView) {
    return {
      opacity: 1,
      transform: `rotateX(${angle}deg) translateZ(${WHEEL_RADIUS}px)`,
    }
  }
  return { opacity: 0, transform: 'none' }
}

export const getContainerStyles = (wheelRotation: number): Pick<SlideStylesType, 'transform'> => ({
  transform: `translateZ(${WHEEL_RADIUS}px) rotateX(${wheelRotation}deg)`,
})

export const getItemValuesStyles = (
  emblaApi: EmblaCarouselType | undefined,
  loop: boolean,
  items: WheelPickerItemValue[],
  totalRadius: number,
): Array<
  WheelPickerItemValue & {
    opacity: number
    transform: string
  }
> => {
  return items?.map((item, index) => {
    return emblaApi
      ? {
          ...item,
          ...getSlideStyles(emblaApi, index, loop, items.length, totalRadius),
        }
      : { ...item, opacity: 0, transform: 'none' }
  })
}

type Props = {
  loop?: boolean
  value: string
  onValueChange: (value: string) => void
  items: WheelPickerItemValue[]
  minWidth: string
  perspective: 'middle' | 'left' | 'right'
  textAlign?: string
}

export const WheelPickerItem = ({
  value,
  items = [],
  loop = false,
  minWidth,
  onValueChange,
  perspective,
  textAlign = 'center',
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop,
    axis: 'y',
    dragFree: true,
  })
  const [wheelReady, setWheelReady] = useState(false)
  const [wheelRotation, setWheelRotation] = useState(0)
  const rootNodeSize = useRef(0)
  const totalRadius = items.length * WHEEL_ITEM_RADIUS
  const rotationOffset = loop ? 0 : WHEEL_ITEM_RADIUS
  const containerStyles = getContainerStyles(wheelRotation)
  const itemValues = getItemValuesStyles(emblaApi, loop, items, totalRadius)

  const inactivateEmblaTransform = useCallback(() => {
    if (!emblaApi) {
      return
    }
    const { translate, slideLooper } = emblaApi.internalEngine()
    translate.clear()
    translate.toggleActive(false)

    slideLooper.loopPoints.forEach((point) => {
      point.translate.clear()
      point.translate.toggleActive(false)
    })
  }, [emblaApi])

  const readRootNodeSize = useCallback(() => {
    if (!emblaApi) {
      return 0
    }
    return emblaApi.rootNode().getBoundingClientRect().height
  }, [emblaApi])

  const rotateWheel = useCallback(() => {
    if (!emblaApi) {
      return
    }
    const rotation = items.length * WHEEL_ITEM_RADIUS - rotationOffset
    setWheelRotation(rotation * emblaApi.scrollProgress())
  }, [emblaApi, items.length, rotationOffset, setWheelRotation])

  const handleSelect = useCallback(() => {
    if (!emblaApi) {
      return
    }
    if (emblaApi.selectedScrollSnap() < itemValues?.length) {
      onValueChange(itemValues[emblaApi.selectedScrollSnap()].value)
    }
  }, [emblaApi, itemValues, onValueChange])

  const handlePointerUp = useCallback(() => {
    if (!emblaApi) {
      return
    }

    const { scrollTo, target, location } = emblaApi.internalEngine()
    const diffToTarget = target.get() - location.get()
    const factor = Math.abs(diffToTarget) < WHEEL_ITEM_SIZE / 3 ? 20 : 0.1
    const distance = diffToTarget * factor
    scrollTo.distance(distance, true)
  }, [emblaApi])

  const handleScroll = useCallback(() => {
    rotateWheel()
  }, [rotateWheel])

  const handleResize = useCallback(() => {
    if (!emblaApi) {
      return
    }

    const newRootNodeSize = readRootNodeSize()
    if (rootNodeSize.current === newRootNodeSize) {
      return
    }

    rootNodeSize.current = newRootNodeSize
    setWheelReady(false)
    setWheelReady(() => {
      emblaApi.reInit()
      inactivateEmblaTransform()
      rotateWheel()
      return true
    })
  }, [emblaApi, inactivateEmblaTransform, readRootNodeSize, rotateWheel])

  useEffect(() => {
    if (!emblaApi) {
      return
    }
    rootNodeSize.current = readRootNodeSize()

    emblaApi.scrollTo(
      items.findIndex((item: WheelPickerItemValue) => item.value === value),
      true,
    )

    emblaApi.on('select', handleSelect)
    emblaApi.on('pointerUp', handlePointerUp)
    emblaApi.on('scroll', handleScroll)
    emblaApi.on('resize', handleResize)
    emblaApi.reInit()
    setWheelReady(true)
    inactivateEmblaTransform()
    rotateWheel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, inactivateEmblaTransform, readRootNodeSize, rotateWheel])

  const handleClick = useCallback(
    (index: number) => {
      if (!emblaApi) {
        return
      }

      emblaApi.scrollTo(index)
    },
    [emblaApi],
  )

  return (
    <Container minWidth={minWidth} textAlign={textAlign}>
      <PickerViewport ref={emblaRef} perspective={perspective}>
        <PickerContainer style={wheelReady ? containerStyles : { transform: 'none' }}>
          {itemValues.map((item, index) => (
            <PickerSlide
              wheelReady={wheelReady}
              textAlign={textAlign}
              key={item.value}
              onClick={() => handleClick(index)}
              opacity={item?.opacity}
              transform={item?.transform}>
              {item.label}
            </PickerSlide>
          ))}
        </PickerContainer>
      </PickerViewport>
    </Container>
  )
}
