import styled from 'styled-components'
import {
  getKeyboardNavigationStyle,
  footnoteMedium,
  linkSmall,
  calloutStrong,
  title3,
} from '@fiji/style'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }
`

export const EcoCheckBackground = styled.img`
  width: 100%;
  border-radius: 0 0 14px 14px;
  margin-bottom: -4px;
`

export const Title = styled.p`
  ${calloutStrong};
  color: ${(props) => props.theme.colors.mainText};
  margin: 0px;
  flex: 1;
`

export const Body = styled.div`
  padding: 4px 16px;
  width: 100%;
`

export const BodyBottom = styled.div``

export const LearnMore = styled.span`
  margin: 0 0 0 4px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  ${linkSmall};
  ${getKeyboardNavigationStyle({})}
  white-space: nowrap;
`

export const BodyBottomDescription = styled.p`
  display: inline;
  margin: 0;
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.mainText1};
`
export const BodyTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const EmissionInformationValue = styled.p`
  margin: 0;
  ${title3};
  color: ${(props) => props.theme.colors.mainText};
`
export const EstimatedEmissionsHeader = styled.div`
  padding: 16px 16px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
