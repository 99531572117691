import { BrowserRouter as Router } from 'react-router-dom'
import type { PropsWithChildren } from 'react'
import { HistoryBlock } from '@fiji/providers/history-block-provider'
import { APP_BASE_PATH } from '@fiji/constants'
import { HistoryAdapter } from '@etta/modules/app/ui/app-routing/history-adapter'
import { RouteAuthorize } from './route-authorize'

export function AppRouting({ children }: PropsWithChildren<{}>) {
  return (
    <Router basename={APP_BASE_PATH}>
      <HistoryAdapter />
      <HistoryBlock>
        <RouteAuthorize>{children}</RouteAuthorize>
      </HistoryBlock>
    </Router>
  )
}
