import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { TripMapper } from '@etta/modules/post-booking/infra/gazoo/mappers/trip-mapper'
import { CheckoutInfoErrors } from '../../core/errors/checkout-info.errors'
import type { CheckoutItineraryInputValueObject } from '../../core/value-objects/checkout-itinerary.input.value-object'
import { FailedBookingMapper } from '../mappers/failed-booking.mapper'
import { CheckoutInfoDataProvider } from './checkout-info.data-provider/checkout-info.data-provider'
import type { CheckoutInfoResults, CheckoutItineraryResults } from './types'

@Adapter()
export class CheckoutInfoAdapter {
  constructor(@Inject() private readonly checkoutInfoDataProvider: CheckoutInfoDataProvider) {}

  async handleCheckoutInfo({
    bookingId,
    itineraryId,
  }: {
    itineraryId: string
    bookingId?: string
  }): CheckoutInfoResults {
    try {
      const { data } = await this.checkoutInfoDataProvider.handleCheckoutInfo(
        {
          itineraryId,
          bookingId,
        },
        true,
      )

      switch (data.getCheckoutInfo.__typename) {
        case 'CheckoutInfoResult': {
          return Result.Ok(data.getCheckoutInfo)
        }

        case 'ItineraryNotFoundResponse': {
          return Result.Err(new CheckoutInfoErrors.TripNotFoundError(''))
        }

        default:
          return Result.Err(new CheckoutInfoErrors.UnexpectedError(''))
      }
    } catch (e) {
      return Result.Err(new CheckoutInfoErrors.UnexpectedError(e))
    }
  }

  async handleCheckoutItinerary(
    input: CheckoutItineraryInputValueObject,
  ): CheckoutItineraryResults {
    try {
      const { data } = await this.checkoutInfoDataProvider.handleCheckoutItinerary(input)

      switch (data?.checkoutItinerary.__typename) {
        case 'OrderDetails': {
          return Result.Ok({
            trip: TripMapper.toTripEntity({
              trip: data.checkoutItinerary,
              processId: data.checkoutItinerary.processId?.toString() || '',
            }),
            failedBookings: data.checkoutItinerary.failedBookings?.map(
              FailedBookingMapper.mapToFailedBookingValueObject,
            ),
          })
        }

        default:
          return Result.Err(new CheckoutInfoErrors.UnexpectedError(''))
      }
    } catch (e) {
      return Result.Err(new CheckoutInfoErrors.UnexpectedError(e))
    }
  }
}
