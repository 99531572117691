import { useTranslation } from 'react-i18next'
import type { CarLegType, MobilityLegType } from '@fiji/enums'
import { FlightLegType } from '@fiji/enums'
import { placeFormatDate } from './format-dates'

type Args = {
  date?: Date
  type: CarLegType | FlightLegType | MobilityLegType
}

export function useFlightDateTime({ date, type }: Args) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.BaseSearch'
  const i18Input = 'TripPlanner.BaseSearch.Input'
  const isDepartOn = type === FlightLegType.Departure
  const groupLabel = t(i18Base + (isDepartOn ? '.DepartOnDateAndTime' : '.ReturnDateAndTime'))

  const testIdDatePicker = isDepartOn ? 'date-depart-on' : 'date-return'

  const testIdTimePicker = isDepartOn ? 'time-depart-on' : 'time-return'

  const datePlaceholder = t(i18Input + '.Select')
  const selectedDate = placeFormatDate(date)
  const selectedDateLabel = t(i18Input + (isDepartOn ? '.DepartOnDate' : '.ReturnDate'))

  const ariaLabelDate = selectedDate
    ? t(i18Base + '.Input.DateSelectedAriaLabel', {
        label: selectedDateLabel,
        value: selectedDate,
      })
    : t(i18Base + '.Input.DateAriaLabel', { label: selectedDateLabel })

  return {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  }
}
