import { useCallback, useState } from 'react'
import {
  checkExistDailyRatesAverage,
  getRoomsAverage,
  getRoomsSubtotal,
} from '@fiji/utils/room-price'
import { dateFormat } from '@fiji/utils/dates/date-format'

import type { Room } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { RoomRatesTypes } from '../../room-rates-modal/types'

type Args = {
  hotelId?: string
  name?: string
  checkIn: Date
  checkOut: Date
}

export type OpenHotelRulesModalArgs = Pick<
  Room,
  | 'dailyRates'
  | 'totalCost'
  | 'id'
  | 'type'
  | 'averageRate'
  | 'feesAndTaxes'
  | 'totalCostRate'
  | 'feesAndTaxesRate'
  | 'source'
>

const DATE_FORMAT = 'yyyy-MM-dd'

export function useRoomRatesModal({ hotelId, name, checkIn, checkOut }: Args) {
  const [roomDetails, setRoomDetails] = useState<RoomRatesTypes | null>(null)

  const {
    isOpen: isRoomDetailsOpen,
    handleOpen,
    handleClose: handleRoomDetailsClose,
  } = useTogglePopup()

  const handleRoomDetailsOpen = useCallback(
    ({
      dailyRates,
      totalCost,
      totalCostRate,
      id,
      type,
      averageRate,
      feesAndTaxes,
      feesAndTaxesRate,
      source,
    }: OpenHotelRulesModalArgs) => {
      if (!hotelId) {
        return
      }

      const shouldShowAverage = checkExistDailyRatesAverage(dailyRates)
      const roomAverage = getRoomsAverage({
        shouldShowAverage,
        dailyRates,
        primary: averageRate.secondary ?? averageRate.primary,
      })
      const roomsSubtotal = getRoomsSubtotal(shouldShowAverage, dailyRates)

      setRoomDetails({
        searchId: null,
        totalCost: totalCost?.amount ? totalCost : undefined,
        totalCostRate: totalCostRate || undefined,
        hotelId: hotelId,
        roomId: id,
        feesAndTaxes,
        feesAndTaxesRate: feesAndTaxesRate || undefined,
        hotelName: name || '',
        roomType: type,
        checkIn: dateFormat(checkIn, DATE_FORMAT),
        checkOut: dateFormat(checkOut, DATE_FORMAT),
        roomAverage,
        roomsSubtotal,
        dailyRates,
        source,
      })

      handleOpen()
    },
    [hotelId, name, checkIn, checkOut, handleOpen],
  )

  return {
    roomDetails,
    isRoomDetailsOpen,
    onRoomDetailsSelect: handleRoomDetailsOpen,
    onRoomDetailsClose: handleRoomDetailsClose,
  }
}
