import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { SegmentType } from '@etta/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { RailTicketRestrictionsContent } from '../rail-ticket-restrictions/rail-ticket-restrictions-content'
import { Content, HeadlineWrapper } from './trip-train-open-return-modal-styled'
import { Headline, InfoCard, TicketDelivery } from './components'
import { useTripTrainOpenReturnModal } from './use-trip-train-open-return-modal'

export const TripTrainOpenReturnModal = observer(function TripTrainOpenReturnModal() {
  const { postBookingTripDetailsStore } = usePostBookingContext()
  const { selectedDetails, selectedOpenReturnDetailsToggle } = postBookingTripDetailsStore
  const segment = selectedDetails ? (selectedDetails as TrainPostBookingValueObject) : null
  const {
    isCollect,
    collectionRefNumber,
    headerRef,
    headerTitle,
    scrollContainerRef,
    modalPosition,
    travelerName,
    transactionId,
    isMobile,
    ticketRestrictions,
  } = useTripTrainOpenReturnModal({ train: segment })

  if (!segment || SegmentType.Train !== segment.type) {
    return null
  }
  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={selectedOpenReturnDetailsToggle.isOpen}
      handleModalVisibility={selectedOpenReturnDetailsToggle.handleClose}
      position={modalPosition}>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor="plum"
            animation="opacity"
            leftSlot={
              <Header.BackButton
                color="mainText2"
                onClick={selectedOpenReturnDetailsToggle.handleClose}
              />
            }>
            <Header.Title title={headerTitle} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <HeadlineWrapper>
          <Headline train={segment} />
        </HeadlineWrapper>
        <Content isMobile={isMobile}>
          <InfoCard
            travelerName={travelerName}
            transactionId={transactionId}
            collectionRefNumber={collectionRefNumber}
            isCollect={isCollect}
          />
          <Block marginTop={24} marginBottom={46}>
            <TicketDelivery
              deliveryOptionCode={segment.selectedDeliveryOption?.code}
              collectionReferences={segment.collectionReferences}
              email={segment.traveler?.email}
            />
          </Block>
          <Block>
            {ticketRestrictions && (
              <RailTicketRestrictionsContent
                needTicketRestrictionTitle={true}
                ticketRestrictionsInfo={ticketRestrictions}
              />
            )}
          </Block>
        </Content>
      </Modal.Body>
    </Modal>
  )
})
