import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { Address } from '@fiji/graphql/types'

type Args = {
  time?: string | null
  address?: Address | null
}

export const useLocation = ({ time, address }: Args) => {
  const parsedDate = time ? new Date(deleteTimezone(time)) : null
  const parsedAndFormattedDate = parsedDate && dateFormat(parsedDate, `eee, LLL dd 'at' p`)
  const location = [
    address?.street1,
    address?.city,
    address?.stateCode,
    address?.postalCode,
    address?.countryCode,
  ]
    .filter(Boolean)
    .join(', ')

  return {
    date: parsedAndFormattedDate,
    location,
  }
}
