import type { UserPreset } from '../types'

export const rcqa10: UserPreset[] = [
  {
    site: 'fijiweb',
    user: 'fijiweb',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiwebmobi',
    user: 'fijiweb',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluVXNlciI6ZmFsc2UsImN1c3RvbWVySWQiOjMwNzc3NjEsImxvY2FsZSI6ImVuLUdCIiwiaW1wZXJzb25hdGVkSWQiOjAsImRlbGVnYXRlZElkIjowLCJjb21wYW55SWQiOjIzNzUxMCwicGFydG5lcklkIjoxLCJ1c2VybmFtZSI6ImZpaml3ZWJAZGVlbS5jb20iLCJjcmVhdGVkVGltZSI6MTY0MzcyMDk0ODIxNCwiY2x1c3RlciI6ImNsdXN0ZXJhIiwic2Vzc2lvbklkIjoiZTA4NzZhMTItZTJiMC00M2VhLTg4NWYtYjVkZjIyMzJkNjFlIiwic2Vzc2lvblRpbWVvdXQiOjE4MDAsImdkc0lkIjoxMDEwMSwicmNTZXNzaW9uIjoiMTYxOTEtMEVFODQ3QjYwNzAzNzUwNUMyMkIwMDY0NkEwRDA1RjMtOTBENTU2RkY4RTZFNjFCRDU2REI1MkFEMzYyQzM0RjE2QkJFMjg4RjU4NDU1MDNFRUE3RkJDMzRCQjRENUVENTgyMDgzQzU2RTgwMkQxRkE3OTE0MjFEQzRGMkVFNkJCNEU1RDk5NTc3MTUyNjNGRjNFQ0EyQUMwQkMzOTU2MUNEOUFEMzA0ODY1MkU4NjhBNUQyNTQwQTg2ODY2NTVBMTIyOTY1QTVEMDMyRTI3RkI0M0ZFMzZCNUVBNEREQUY1RDM4RUY4Mjk5MkZERDY2M0VFNUZBQkM5OTAwQ0YxM0UwOUUzRDY2RDc4ODQ1MzJERTY0QTc2QjI1RDAwRDZGQzhEMkMwRDIzNzc4MEI1RjYxNzk0ODI3MzY5NTI3NkM2MUJEM0U3QkM2M0Y0QUQwOTBFNUZEOTM0NUZFQTY2MTAiLCJpYXQiOjE2NDM3MjA5NDgsImV4cCI6MTk1OTI5Njk0OH0.5fXC0f1mT40T9WwA0S_45YUxsjyXR3jcVTI-zJNj3b4',
  },
  {
    site: 'fijiphx',
    user: 'phxuser',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiphx',
    user: 'fijiweb',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiphx',
    user: 'fijisabre',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiphx-prh',
    user: 'fijiweb',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiphx-prh',
    user: 'phxuser',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
  {
    site: 'fijiphx-prh',
    user: 'fijiweb',
    rcSession:
      '17041-D01B0428E802E4941955442E72F40B64-2C9F6A2866E33D395A6F0515A279D058D7800EA514B06AD700A126C28D0B675AC16EF84A09AC56C9422C9DE228C1969CD3C34D17D14399A3ADF45669F68E0249AAEF266DAC1F8B51EB61056C0C61E0442DBC8676F0F3B27E1B406711982E17A6B0D541C148A5AF72D376126829D50FD1C282C3478E43513CB2C7D3AB48ED2D6824D1D0D12BB60B4CF6D90E7D38C7F473383B74E04FE7F0AE09609C33DDD0A7CCEE29AE781CBB7747951B195F22F7AEAE4DD546889FF085BBBFB66F359317AD33',
  },
]
