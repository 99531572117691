import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 16px;
  border-radius: 10px;
`

export const Header = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  padding-bottom: 17px;
  border-bottom: 1px dashed ${(props) => props.theme.colors.borderLight};
`

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
