import { useCallback } from 'react'
import type { Coords } from '../../core/coords'
import { AppleMapHelpers } from '../../interface/apple/apple-map.helpers'

type Options = {
  zoomControl?: boolean
  center?: Coords
  scaleControl?: boolean
  defaultCenter?: Coords
  isInteractive: boolean
}

export const useOptions = () => {
  const buildZoomLevel = useCallback((zoom: number, centerLat: number) => {
    return AppleMapHelpers.convertZoomLevelToMeters(zoom, centerLat)
  }, [])

  const buildCenter = useCallback((center: Coords) => {
    return AppleMapHelpers.createCoordinate(center)
  }, [])

  const buildConstructorOptions = useCallback(
    (options: Options) => {
      const { zoomControl, isInteractive, scaleControl } = options
      const mapCenter = options.center || options.defaultCenter

      if (!mapCenter) {
        throw new Error('You should provide center or defaultCenter to the map')
      }

      const appleMapOptions: mapkit.MapConstructorOptions = {
        showsScale:
          scaleControl ?? isInteractive
            ? mapkit.FeatureVisibility.Visible
            : mapkit.FeatureVisibility.Hidden,
        showsZoomControl: zoomControl ?? isInteractive,
        showsMapTypeControl: isInteractive,
        showsCompass: mapkit.FeatureVisibility.Hidden,
        center: buildCenter(mapCenter),
      }

      return appleMapOptions
    },
    [buildCenter],
  )

  return { buildConstructorOptions, buildCenter, buildZoomLevel }
}
