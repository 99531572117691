import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { DeemUberLogoPWA } from '@etta/ui/icon/pwa-icon/assets'
import { AppleTitle } from './brand-title-styled'

export function BrandTitle() {
  const { brandConfigurationStore } = useBrandConfigurationContext()

  if (brandConfigurationStore.isAppleTheme) {
    return <AppleTitle data-tracking-id="kan-logo" />
  }

  return <DeemUberLogoPWA height="22px" data-tracking-id="deem-uber-logo" />
}
