import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  GetUserProfileDocument,
  UpdateUserProfileBusinessAddressDocument,
  UpdateUserProfileContactInformationDocument,
  UpdateUserProfileEmergencyContactDocument,
  UpdateUserProfileEmployeeInformationDocument,
  UpdateUserProfileHomeAddressDocument,
  UpdateUserProfilePersonalInformationDocument,
  UpdateUserProfileTravelPreferencesDocument,
} from '@fiji/graphql/hooks'
import type {
  GetUserProfileQuery,
  GetUserProfileQueryVariables,
  UpdateUserProfileBusinessAddressMutation,
  UpdateUserProfileBusinessAddressMutationVariables,
  UpdateUserProfileContactInformationMutation,
  UpdateUserProfileContactInformationMutationVariables,
  UpdateUserProfileEmergencyContactMutation,
  UpdateUserProfileEmergencyContactMutationVariables,
  UpdateUserProfileEmployeeInformationMutation,
  UpdateUserProfileEmployeeInformationMutationVariables,
  UpdateUserProfileHomeAddressMutation,
  UpdateUserProfileHomeAddressMutationVariables,
  UpdateUserProfilePersonalInformationMutation,
  UpdateUserProfilePersonalInformationMutationVariables,
  UpdateUserProfileTravelPreferencesMutation,
  UpdateUserProfileTravelPreferencesMutationVariables,
} from '@fiji/graphql/hooks'

@DataProvider()
export class UserProfileDataProvider extends GraphqlDataProvider {
  getUserProfile(includeOpenEnrollmentRegistrationCustomFields?: boolean) {
    return this.client.query<GetUserProfileQuery, GetUserProfileQueryVariables>({
      query: GetUserProfileDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        includeOpenEnrollmentRegistrationCustomFields,
      },
    })
  }

  updateUserProfileTravelPreferences(
    input: UpdateUserProfileTravelPreferencesMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfileTravelPreferencesMutation,
      UpdateUserProfileTravelPreferencesMutationVariables
    >({
      mutation: UpdateUserProfileTravelPreferencesDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfileBusinessAddress(
    input: UpdateUserProfileBusinessAddressMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfileBusinessAddressMutation,
      UpdateUserProfileBusinessAddressMutationVariables
    >({
      mutation: UpdateUserProfileBusinessAddressDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfileContactInformation(
    input: UpdateUserProfileContactInformationMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfileContactInformationMutation,
      UpdateUserProfileContactInformationMutationVariables
    >({
      mutation: UpdateUserProfileContactInformationDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfilePersonalInformation(
    input: UpdateUserProfilePersonalInformationMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfilePersonalInformationMutation,
      UpdateUserProfilePersonalInformationMutationVariables
    >({
      mutation: UpdateUserProfilePersonalInformationDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfileHomeAddress(input: UpdateUserProfileHomeAddressMutationVariables['input']) {
    return this.client.mutate<
      UpdateUserProfileHomeAddressMutation,
      UpdateUserProfileHomeAddressMutationVariables
    >({
      mutation: UpdateUserProfileHomeAddressDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfileEmergencyContact(
    input: UpdateUserProfileEmergencyContactMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfileEmergencyContactMutation,
      UpdateUserProfileEmergencyContactMutationVariables
    >({
      mutation: UpdateUserProfileEmergencyContactDocument,
      variables: {
        input,
      },
    })
  }

  updateUserProfileEmployeeInformation(
    input: UpdateUserProfileEmployeeInformationMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateUserProfileEmployeeInformationMutation,
      UpdateUserProfileEmployeeInformationMutationVariables
    >({
      mutation: UpdateUserProfileEmployeeInformationDocument,
      variables: {
        input,
      },
    })
  }
}
