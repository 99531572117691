import { TripType } from '@etta/core/enums/trip-type.enum'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import {
  ErrorDetailsText,
  RetryButton,
  ReloadErrorBlock,
} from './trip-list-layout-error-desktop-styled'
import { useTripListLayoutErrorDesktop } from './use-trip-list-layout-error-desktop'

export function TripListLayoutErrorDesktop({
  onReload,
  tripType = TripType.Upcoming,
}: LayoutProps) {
  const { translations } = useTripListLayoutErrorDesktop({
    tripType,
  })

  return (
    <ReloadErrorBlock>
      <ErrorDetailsText variant="headline" color="mainText">
        {translations.title}
      </ErrorDetailsText>
      <Block marginTop={8}>
        <ErrorDetailsText variant="footnoteMedium" color="bodyText">
          {translations.errorText}
        </ErrorDetailsText>
      </Block>
      <Block marginTop={8}>
        <RetryButton size="small" onClick={onReload}>
          {translations.retryButtonText}
        </RetryButton>
      </Block>
    </ReloadErrorBlock>
  )
}
