import { Inject, Service } from '@etta/di'
import type { ReviewTripValueObject } from '@etta/modules/review-trip/core'
import { ActiveTripStore } from '../stores/active-trip.store'
import type { ActiveItineraryEntity } from '../../core/entity/active-itinerary.entity'

@Service()
export class ActiveTripService {
  constructor(
    @Inject()
    private readonly activeTripStore: ActiveTripStore,
  ) {}

  setActiveTripData(data: ReviewTripValueObject) {
    let currentExpiredAt = ''
    if (this.activeTripStore.activeTripId === data.itineraryId) {
      currentExpiredAt = this.activeTripStore.expiredAt ?? ''
    }

    this.activeTripStore.clearStore()

    // keep this in case of being used in changing flight
    if (data.flightId) {
      this.activeTripStore.setActiveTripFlightId(data.flightId)
    }
    this.activeTripStore.setActiveTripId(data.itineraryId)

    const itineraryData: ActiveItineraryEntity = {
      id: data.itineraryId,
      flightId: data.flightId ?? '',
      segments: data.segments,
      availableSegments: data.availableSegments || null,
      tripName: data.tripInfo.name,
      startDate: data.tripInfo.startDate,
      endDate: data.tripInfo.endDate || null,
      isNameEditable: data.tripInfo.isNameEditable,
      imageUrl: data.tripInfo.imageUrl || null,
      expiredAt: currentExpiredAt ? currentExpiredAt : data.expiredAt,
    }
    this.activeTripStore.setActiveItinerary(itineraryData)
    this.activeTripStore.setShouldFetchActiveItinerary(false)
  }

  clearStore() {
    this.activeTripStore.dropCurrentlyReactivatedID()
    this.activeTripStore.clearStore()
  }

  public getActiveExpiredAt(itinerary: string) {
    if (this.activeTripStore.activeTripId === itinerary) {
      return this.activeTripStore.expiredAt
    }
    return undefined
  }
}
