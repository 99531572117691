import { useCallback } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useReviewTripContext } from '@etta/modules/review-trip'

export function useTripDropActions() {
  const { tripActions } = useReviewTripContext()
  const { postBookingTripActions } = usePostBookingContext()

  const dropTripAndPostBooking = useCallback(() => {
    tripActions.dropTrip()
    postBookingTripActions.dropTripStore()
  }, [postBookingTripActions, tripActions])

  return {
    dropTripAndPostBooking,
  }
}
