import type { Location } from 'history'
import { useCallback } from 'react'
import { ROUTES } from '@fiji/routes'
import {
  mainNavigationTripsSelector,
  updateMainNavigationTrips,
  MAINNAVIGATIONVALUE,
} from '@fiji/store/main-navigation'
import { useGlobalLatestRoute } from './use-global-latest-route'

const heldTripsPath = ROUTES.postBooking.heldTrips
const tripsPath = ROUTES.postBooking.trips()

export function useGlobalTripsRoute() {
  const { usedPath: tripsLink } = useGlobalLatestRoute({
    detectPath: MAINNAVIGATIONVALUE.TRIPS,
    action: updateMainNavigationTrips,
    selector: mainNavigationTripsSelector,
  })

  const isTripsActiveHandle = useCallback((_: unknown, location: Location) => {
    const isHeldTripMatch = (location.pathname + location.search).indexOf(heldTripsPath) === 0
    const isTripsMatch = location.pathname.indexOf(tripsPath) === 0

    return isHeldTripMatch || isTripsMatch
  }, [])

  return { tripsLink, isTripsActiveHandle }
}
