import { checkIsPwa } from '@fiji/config/check-is-pwa'

export enum LaunchMode {
  FIJI = 'FIJI', // old trip planner
  ETTA_STANDALONE = 'ETTA_STANDALONE', // run under android apk or as PWA application in browser
  ETTA_WEB = 'ETTA_WEB', // run as ordinary browser
}

function getLaunchMode() {
  const isWebTripPlanner = document.location.pathname.includes('/app/trip-planner')
  if (isWebTripPlanner) {
    return LaunchMode.FIJI
  }

  if (checkIsPwa()) {
    return LaunchMode.ETTA_STANDALONE
  }
  return LaunchMode.ETTA_WEB
}

export const launchMode = getLaunchMode()
