import type { KeyboardEvent, PropsWithChildren, MouseEvent } from 'react'
import { useCallback } from 'react'
import { Item } from './descendant-styled'
import { useDescendant } from './descendant-context'
import { useListContext } from './descendant-list'

type Props = PropsWithChildren<{
  isDisabled?: boolean
  isActiveStateDisabled?: boolean
  onSelect?: (e: KeyboardEvent<HTMLElement> | MouseEvent) => void
}>

export function DescendantItem({
  children,
  isDisabled = false,
  isActiveStateDisabled,
  onSelect,
  ...rest
}: Props) {
  const { index, register } = useDescendant({
    disabled: isDisabled,
  })

  const { setFocusIndex } = useListContext()

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.code === 'Enter') {
        onSelect?.(e)
      }
    },
    [onSelect],
  )

  const onFocus = () => {
    setFocusIndex(index)
  }

  return (
    <Item
      tabIndex={0}
      ref={register}
      {...rest}
      onKeyDown={handleKeyDown}
      onClick={onSelect}
      onFocus={onFocus}
      isActiveStateDisabled={isActiveStateDisabled}>
      {children}
    </Item>
  )
}
