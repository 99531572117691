import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'

export const Item = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  margin-bottom: 20px;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.bodyText2 : theme.colors.mainText};
  transition: 0.3s background-color;
  &:active {
    background-color: ${({ theme }) => theme.colors.background1};
  }
`

export const Header = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 0 16px;
`

export const Body = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const HeaderDate = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  color: 'bodyText',
}))``

export const CardTitle = styled(Text).attrs(() => ({
  variant: 'subHeadStrong',
}))``

export const InfoLine = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))``

export const SubTitle = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  color: 'bodyText',
}))`
  display: flex;
  gap: 4px;
  line-height: 19px;
  align-items: center;
`

export const Status = styled(Text).attrs(({ color }) => ({
  variant: 'captionStrong',
  color,
}))``

export const CardRow = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))`
  white-space: pre-line;
`

export const CancelationBlock = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 8px;
  align-items: center;
`

export const CancelationIcon = styled(Icon).attrs(() => ({
  name: 'infoFilledPWA',
  color: 'warning',
}))``

export const CanceleationText = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))``
