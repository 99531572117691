/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import type { ChosenCardsMap } from '@etta/screens/payment-summary/types'
import type { TravelVertical } from '@fiji/graphql/types'

type Args = {
  chosenCards: ChosenCardsMap
  travelVertical: TravelVertical
}

export function useSelectedCard({ chosenCards, travelVertical }: Args) {
  const [selectedCard, setSelectedCard] = useState(chosenCards[travelVertical])
  useEffect(() => {
    setSelectedCard(chosenCards[travelVertical])
  }, [travelVertical])

  const handleSelectCard = useCallback((id?: string) => {
    setSelectedCard(id || null)
  }, [])

  return {
    selectedCard,
    handleSelectCard,
  }
}
