import { useTranslation } from 'react-i18next'
import { CloseButtonRounded } from '@etta/components/close-button-rounded/close-button-rounded'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'
import { HotelBadges } from '@etta/components/hotel-details-modal/hotel-badges'
import { HotelTitle } from './hotel-title-block-styled'

type Props = {
  hotelDetails: HotelDetailsType
  onClose: () => void
}

export function HotelTitleBlock({ hotelDetails, onClose }: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'
  const { name } = hotelDetails

  return (
    <>
      <HotelTitle aria-label={t(`${i18Base}.HotelNameAriaLabel`, { hotelName: name })}>
        {name}
      </HotelTitle>
      <CloseButtonRounded onClick={onClose} />
      <HotelBadges hotelDetails={hotelDetails} isFullInfo />
    </>
  )
}
