import { observer } from 'mobx-react-lite'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { BrandsSelect } from './brands-select'

type Props = {
  isModal?: boolean
}

export const SearchBrands = observer(function SearchBrands({ isModal = false }: Props) {
  const {
    hotelFiltersAction,
    hotelFiltersStore,
    hotelSearchResultsStore,
  } = useHotelSearchResultsContext()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  return (
    <BrandsSelect
      isModal={isModal}
      isNewLayout={isNewHotelExposedFiltersEnabled && !isModal}
      onChange={(value) => hotelFiltersAction.handleSetBrandIds(value)}
      selectedBrands={hotelFiltersStore.selectedFilters.brandIds}
      brands={hotelSearchResultsStore.filterOptions.brands.brands}
      superBrands={hotelSearchResultsStore.filterOptions.brands.superBrands}
      sortedBrands={hotelSearchResultsStore.filterOptions.brands}
    />
  )
})
