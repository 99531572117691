import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
  margin-top: -50px;
  margin-bottom: 24px;
  padding: 16px;
`

export const Heading = styled.div`
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px dashed ${(props) => props.theme.colors.borderDark};
    padding-bottom: 16px;
  }
`

export const CardWrapper = styled.div`
  width: 50%;
`
