import { Skeleton } from '@etta/ui/skeleton'

type Props = {
  idx: number
}

const concernScoreItems = 7

function ConcernScoreItem({ idx }: Props) {
  const y = 42
  const lineY = 60
  const iconPosition = y * idx
  const titlePosition = y * idx
  const linePosition = lineY + y * (idx - 1)

  return (
    <>
      <rect width="30" height="24" y={iconPosition} />
      <rect width="120" height="10" x="38" y={titlePosition} />
      <rect width="70%" height="4" x="38" y={linePosition} />
    </>
  )
}

export function ConcernScoreListSkeleton() {
  return (
    <Skeleton width="100%" height="360">
      <rect width="122" height="18" />
      {Array(concernScoreItems)
        .fill('')
        .map((_, i: number) => (
          <ConcernScoreItem key={`concern-score-item-${i + 1}`} idx={i + 1} />
        ))}
    </Skeleton>
  )
}
