import { Result } from 'fnscript'
import { Service, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { DEFAULT_LOCALE } from '@fiji/constants'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { UserAdapterResult } from '@etta/modules/user/infra/types'
import { UserAdapter } from '../../infra/user.adapter'
import type { UserInputValueObject } from '../../core/value-objects'
import type { UserErrorsInstances } from '../../core/errors'
import { UserStore } from '../stores'
import { UserService } from './get-user.service'

@Service()
export class UpdateUserService {
  constructor(
    @Inject() private userService: UserService,
    @Inject() private userAdapter: UserAdapter,
    @Inject() private userStore: UserStore,
    @Inject() private displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  async updateUser(input: UserInputValueObject): UserAdapterResult<{ success: boolean }> {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      const result = await this.handleUpdateUserMod2Flow(input)

      return result
    }

    this.userStore.setIsUpdating(true)
    this.userStore.setIsUpdatingError(false)

    const result = await this.userAdapter.updateUser({ input })

    if (result.isErr()) {
      this.userStore.setIsUpdatingError(true)
      this.userStore.setIsUpdating(false)
      return result
    }

    const updatedUser = await this.userAdapter.getUser()

    if (updatedUser.isErr()) {
      this.userStore.setIsUpdatingError(true)
      this.userStore.setIsUpdating(false)
      return Result.Err(updatedUser.getError())
    }

    this.userService.updateUser(updatedUser.getValue())

    this.userStore.setIsUpdating(false)

    return result
  }

  // TEMPORARY SOLUTION FOR mod2 flow!.
  private async handleUpdateUserMod2Flow(input: UserInputValueObject) {
    this.userStore.setIsUpdating(true)
    await delayForSuccess(500)
    this.userStore.setIsUpdating(false)
    this.setUserForMod2Flow(input)
    return Result.Ok<{ success: boolean }, UserErrorsInstances>({ success: true })
  }

  // TEMPORARY SOLUTION FOR mod2 flow!.
  private setUserForMod2Flow({
    companyId,
    customerId,
    firstName,
    lastName,
    dateOfBirth,
    email,
    gender,
    middleName,
    mobilePhoneExt,
    mobilePhoneNumber,
    personalAddress,
    phone,
    suffix,
    title,
  }: UserInputValueObject) {
    const user = this.userStore.user
    const profile = this.userStore.profile

    this.userStore.setUser({
      companyId: companyId ?? user?.companyId,
      customerId: customerId ?? user?.customerId,
      locale: user?.locale || DEFAULT_LOCALE,
      sessionTimeout: user?.sessionTimeout || 0,
      currency: user?.profile?.currency,
      delegatedId: user?.delegatedId,
      partnerId: user?.partnerId,
      trustedLocale: user?.trustedLocale,
      profile: {
        currency: user?.profile?.currency,
        genderOptions: profile?.genderOptions || [],
        titleOptions: profile?.titleOptions || [],
        suffixOptions: profile?.suffixOptions || [],
        firstName: firstName ?? profile?.firstName,
        lastName: lastName ?? profile?.lastName,
        middleName: middleName ?? profile?.middleName,
        dateOfBirth: dateOfBirth ?? profile?.dateOfBirth,
        title: title ?? profile?.title,
        suffix: suffix ?? profile?.suffix,
        gender: gender ?? profile?.gender,
        timeZone: profile?.timeZone,
        site: profile?.site,
        pwaWelcomeMessage: profile?.pwaWelcomeMessage,
        personalContact: {
          address: {
            countryCode:
              personalAddress?.countryCode ??
              (profile?.personalContact?.address?.countryCode || ''),
            mailStop: profile?.personalContact?.address?.mailStop,
            postalCode:
              personalAddress?.postalCode ?? profile?.personalContact?.address?.postalCode,
            street1: personalAddress?.street1 ?? profile?.personalContact?.address?.street1,
            street2: personalAddress?.street2 ?? profile?.personalContact?.address?.street2,
            stateCode: personalAddress?.stateCode ?? profile?.personalContact?.address?.stateCode,
            city: personalAddress?.city ?? profile?.personalContact?.address?.city,
          },
          email: email ?? profile?.personalContact?.email,
          phone: profile?.personalContact?.phone,
        },
        businessContact: {
          address: profile?.businessContact?.address,
          mobilePhone: {
            ext: mobilePhoneExt ?? profile?.businessContact?.mobilePhone?.ext,
            number: mobilePhoneNumber || phone || profile?.businessContact?.mobilePhone?.number,
          },
        },
        notification: profile?.notification,
        passports: profile?.passports,
        visas: profile?.visas,
        nationalIdCards: profile?.nationalIdCards,
        identityHash: profile?.identityHash || '',
        hourlyTimeFormat: profile?.hourlyTimeFormat,
        measurementUnit: profile?.measurementUnit,
        rawSuffix: profile?.rawSuffix,
      },
    })
  }
}
