import { useRoutesContext } from '@etta/modules/app/interface/use-routes-context'
import { AppErrorBoundary } from '@etta/modules/app/ui/content/app-error-boundary'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { GlobalStyle, GlobalStyleThemed } from '@etta/modules/app/ui/content/global-style'
import { RoutesSwitcher } from '@etta/modules/app/ui/content/routes-switcher'
import { ConnectionStatus } from './connection-status'
import { LanguagePreference } from './language-preference'

export function Content() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { routesStore } = useRoutesContext()

  return (
    <>
      <LanguagePreference />
      <GlobalStyle
        isKeyboardNavigationStylingEnabled={
          !!featureFlagsStore.flags.isKeyboardNavigationStylesEnabled
        }
      />
      <GlobalStyleThemed />
      <ConnectionStatus />
      <AppErrorBoundary>
        <RoutesSwitcher routes={routesStore.routes} />
      </AppErrorBoundary>
    </>
  )
}
