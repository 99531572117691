import { useTranslation } from 'react-i18next'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { InfoModal } from '../../info-modal'
import { RulesModalLayout } from './layout'

type Props = {
  carSegmentId: string
  itineraryId: string
  onClose: () => void
}

export function RulesModalContentModFlow({ carSegmentId, onClose }: Props) {
  const { t } = useTranslation()
  const { reviewTripStore, segmentTypeCheckActions } = useReviewTripContext()
  const title = t('CarRentalDetailsModal.RulesModal.Title')

  const carTrips = reviewTripStore.segments
    .map((segment) => {
      if (segmentTypeCheckActions.isCarRentalSegment(segment)) {
        return segment
      }

      return undefined
    })
    .filter(Boolean)

  const carRental = carTrips?.find((segment) => {
    if (segment?.carId === carSegmentId) {
      return segment
    }
  })

  return (
    <>
      <InfoModal.Header title={title} onClose={onClose} />
      <InfoModal.Content>
        <RulesModalLayout carRules={carRental?.termsAndConditions!} />
      </InfoModal.Content>
    </>
  )
}
