import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { TextField } from '@etta/ui/text-field'
import { useSearchBrands } from '@etta/screens/hotel-result-page/hotel-filters/brands-multi-select/use-search-brands'
import { Swap } from '@etta/ui/swap'
import { BrandsMultiSelect } from '@etta/screens/hotel-result-page/hotel-filters/brands-multi-select'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { BrandsShowMore } from '../../brands-show-more'
import type { LayoutProps } from '../../types'
import { Container, Wrapper } from './brands-block-desktop-styled'

const i18nBase = 'HotelFilters.Hotel'
export const BrandsBlockDesktop = observer(function BrandsBlockDesktop({
  superBrands,
  selectedBrands,
  brands,
  sortedBrands,
  onChange,
  isModal,
  isNewLayout,
}: LayoutProps) {
  const { t } = useTranslation()

  const {
    selectedIdsMap,
    filteredSuperBrands,
    filteredBrands,
    searchText,
    handleSearch,
    handleUpdateSelection,
  } = useSearchBrands({
    brands,
    superBrands,
    selectedBrands,
    onApply: (values: string[]) => {
      onChange(values)
    },
    onSelect: (values: string[]) => {
      onChange(values)
    },
  })

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  if (!isModal && isNewHotelExposedFiltersEnabled) {
    return (
      <Wrapper>
        <TextField
          placeholder={t(i18nBase + '.SearchBrand')}
          type="search"
          size="small"
          onChange={handleSearch}
          value={searchText}
        />
        <Container>
          <BrandsMultiSelect
            isNewLayout={isNewLayout}
            isSuperBrandExpanded={!!searchText}
            superBrands={filteredSuperBrands}
            brands={filteredBrands}
            selectedIdsMap={selectedIdsMap}
            onSelect={handleUpdateSelection}
          />
        </Container>
      </Wrapper>
    )
  }

  return (
    <>
      <TextField
        placeholder={t(i18nBase + '.SearchBrand')}
        type="search"
        size="small"
        onChange={handleSearch}
        value={searchText}
      />

      <Swap
        is={!!searchText}
        isSlot={
          <Container>
            <BrandsMultiSelect
              isNewLayout={isNewLayout}
              isSuperBrandExpanded={!!searchText}
              superBrands={filteredSuperBrands}
              brands={filteredBrands}
              selectedIdsMap={selectedIdsMap}
              onSelect={handleUpdateSelection}
            />
          </Container>
        }>
        <BrandsShowMore onChange={onChange} selected={selectedBrands} sortedBrands={sortedBrands} />
      </Swap>
    </>
  )
})
