import { HotelSearchForm } from '@etta/components/hotel-search-form'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'

type Props = {
  onClose: () => void
}

export function EditMode({ onClose }: Props) {
  return (
    <SearchResultsHeaderFull.EditMode onClose={onClose}>
      <HotelSearchForm />
    </SearchResultsHeaderFull.EditMode>
  )
}
