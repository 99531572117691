import { css } from 'styled-components'
import * as Typography from '@fiji/style/typography'
import type { StyledTextVariant } from '@fiji/enums'

export const heavyWeightText = css`
  font-weight: 900;
`

export const boldWeightText = css`
  font-weight: 700;
`

export const semiBoldWeightText = css`
  font-weight: 400;
  // font-weight: 600;
`

export const mediumWeightText = css`
  font-weight: 500;
`

export const normalWeightText = css`
  font-weight: 400;
`

export const lightWeightText = css`
  font-weight: 300;
`

export const defaultFontFamily = css`
  font-family: ${(p) => p.theme.fontFamily || 'Inter'}, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;

  // google maps style overriding
  .gm-style {
    font: 400 11px ${(p) => p.theme.fontFamily || 'Inter'}, 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }
`

export const withStyledText = css<{ textVariant?: StyledTextVariant }>`
  ${(props) => props.textVariant && Typography[props.textVariant]};
`

export default {
  heavyWeightText,
  boldWeightText,
  semiBoldWeightText,
  mediumWeightText,
  normalWeightText,
  lightWeightText,
  withStyledText,
}
