import { useTranslation } from 'react-i18next'
import { AmenityIcon } from '@etta/ui/amenity-icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { FlightDetailsAirlinesLogos } from '@etta/components/flight-details-airlines-logos/flight-details-airlines-logos'
import { useExpand } from '@fiji/hooks/use-expand/use-expand'
import type { FlightAmenity } from '@fiji/graphql/types'
import {
  Container,
  AirlineLogosWrapper,
  Heading,
  HeadingTitle,
  AmenitiesContainer,
  AmenitiesList,
  AmenitiesItem,
  IconRow,
  ToggleButton,
} from './flight-details-amenities-styled'

type Props = {
  squareAirlinesLogos: string[]
  airlinesNames: string[]
  flightLabels: string[]
  amenities: FlightAmenity[]
}

const i18nPathAccessability = 'Accessibility.ReviewTripPage'

export function FlightDetailsAmenities({
  squareAirlinesLogos,
  airlinesNames,
  flightLabels,
  amenities,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.FlightAirFares'
  const { isExpanded, toggleExpansion } = useExpand()

  return (
    <Container>
      <Heading
        aria-label={`${airlinesNames.join(', ')} ${flightLabels.join(', ')}`}
        data-tracking-id="flight-number-details-block">
        <div>
          <HeadingTitle>{airlinesNames.join(', ')}</HeadingTitle>
          <Text color="bodyText" variant="footnoteStrong">
            {flightLabels.join(', ')}
          </Text>
        </div>
        <AirlineLogosWrapper>
          <FlightDetailsAirlinesLogos airlinesLogos={squareAirlinesLogos} />
        </AirlineLogosWrapper>
      </Heading>
      {!!amenities.length && (
        <AmenitiesContainer aria-label={t(i18nPathAccessability + '.AmenitiesList')}>
          <AmenitiesList isExpanded={isExpanded}>
            {amenities.map((amenity) => {
              return (
                <AmenitiesItem key={amenity.id}>
                  <IconRow>
                    <AmenityIcon amenity={amenity.id} />
                  </IconRow>
                  {isExpanded && t(`${i18Base}.Amenities.${amenity.id}`)}
                </AmenitiesItem>
              )
            })}
          </AmenitiesList>
          <ToggleButton>
            <IconButton
              onClick={toggleExpansion}
              icon={isExpanded ? 'expandLessPWA' : 'expandMorePWA'}
            />
          </ToggleButton>
        </AmenitiesContainer>
      )}
    </Container>
  )
}
