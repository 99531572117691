import { useCallback } from 'react'
import { NetworkStatus } from '@apollo/client'
import { useGetSafetyCheckQuery } from '@fiji/graphql/hooks'
import type { HotelSafetyCheckProps } from '../types'
import type { HotelSafetyCheckResult } from './types'
import { mapSafetyCheck } from './mappers'

type Args = Omit<HotelSafetyCheckProps, 'hotelName'>

export function useHotelSafetyCheck({ lat, lng }: Args): HotelSafetyCheckResult {
  const { data, error, networkStatus, refetch } = useGetSafetyCheckQuery({
    variables: {
      lat: lat,
      lng: lng,
    },
    notifyOnNetworkStatusChange: true,
  })
  const isLoading = networkStatus === NetworkStatus.loading
  const isRefetching = networkStatus === NetworkStatus.refetch
  const handleRefetch = useCallback(() => {
    if (isLoading || isRefetching) {
      return
    }

    refetch()
  }, [isLoading, isRefetching, refetch])

  if (!error && isLoading && !data) {
    return {
      isLoading: true,
      hasError: false,
      data: undefined,
      isRefetching: false,
      onRefetch: handleRefetch,
    }
  }

  if ((error && !isLoading) || !data) {
    return {
      isLoading: false,
      hasError: true,
      data: undefined,
      isRefetching: false,
      onRefetch: handleRefetch,
    }
  }

  if (isRefetching) {
    return {
      isLoading: false,
      hasError: false,
      data: undefined,
      isRefetching: true,
      onRefetch: handleRefetch,
    }
  }

  return {
    isLoading: false,
    hasError: false,
    data: mapSafetyCheck(data.geosureScores),
    isRefetching: false,
    onRefetch: handleRefetch,
  }
}
