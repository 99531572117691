import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { RailDetailsModalMobile } from './mobile'
import { RailDetailsModalDesktop } from './desktop'

export function RailDetailsModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailDetailsModalMobile {...props} />}
      desktopSlot={<RailDetailsModalDesktop {...props} />}
    />
  )
}
