import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { BookingConditions } from '@etta/screens/payment-summary/booking-conditions'
import { Swap } from '@etta/ui/swap'
import { NATIONAL_RAIL_CONDITIONS_URL } from '@fiji/constants'
import { TripCostSummary } from '@etta/screens/review-trip-page/trip-cost-summary'
import { NDCFareAcknowledgment } from '@etta/components/ndc-fare-acknowledgment'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { usePriceSummaryCard } from './use-price-summary-card'
import { Footer } from './footer'
import { ErrorContainer } from './error-container'
import { PayableSection } from './payable-section'
import { Wrapper, Body, NDCFareAcknowledgmentContainer } from './price-summary-card-styled'
import { LoadingSkeleton } from './loading-skeleton'

type Props = {
  isDueTodayCostDisplayed?: boolean
}

const i18nBase = 'Purchase'

export const PriceSummaryCard = observer(function PriceSummaryCard({
  isDueTodayCostDisplayed = false,
}: Props) {
  const {
    tripCostSummaryToggle,
    bookingToggle,
    privacyPolicy,
    flightNowPrice,
    trainNowPrice,
    hotelNowPrice,
    isPayableNowExist,
    hotelLaterPrice,
    carNowPrice,
    carLaterPrice,
    isPayableLaterExist,
    totalCost,
    dueTodayCost,
    tripCost,
    segments,
    buttonLabel,
    isError,
    isButtonDisabled,
    isBooking,
    onSubmit,
    onHoldTrip,
    holdButtonLabel,
    isHoldFlow,
    isLoading,
    isHoldButtonVisible,
    isRailAccreditationEnabled,
  } = usePriceSummaryCard({ isDueTodayCostDisplayed })

  const {
    checkoutInfoStore: { shouldShowNdcFareAcknowledgment },
  } = useCheckoutInfoContext()

  const { t } = useTranslation()

  if (isLoading) {
    return (
      <LoadingSkeleton
        buttonLabel={buttonLabel}
        isDueTodayCostDisplayed={isDueTodayCostDisplayed}
      />
    )
  }

  return (
    <Wrapper aria-label={t(`${i18nBase}.PriceSummary`)}>
      <Swap
        is={isError}
        isSlot={
          <ErrorContainer
            buttonLabel={buttonLabel}
            isDueTodayCostDisplayed={isDueTodayCostDisplayed}
          />
        }>
        <Body>
          <PayableSection
            isPayableNowExist={isPayableNowExist}
            isPayableLaterExist={isPayableLaterExist}
            flightNowPrice={flightNowPrice}
            trainNowPrice={trainNowPrice}
            hotelNowPrice={hotelNowPrice}
            hotelLaterPrice={hotelLaterPrice}
            carNowPrice={carNowPrice}
            carLaterPrice={carLaterPrice}
            onDetailsClick={tripCostSummaryToggle.handleOpen}
            isHoldFlow={isHoldFlow}
          />
        </Body>
        {shouldShowNdcFareAcknowledgment && (
          <NDCFareAcknowledgmentContainer>
            <NDCFareAcknowledgment />
          </NDCFareAcknowledgmentContainer>
        )}
        <Footer
          isHoldFlow={isHoldFlow}
          isHoldButtonVisible={isHoldButtonVisible}
          disabledButton={isButtonDisabled}
          buttonLabel={buttonLabel}
          holdButtonLabel={holdButtonLabel}
          priceString={totalCost}
          dueTodayCost={dueTodayCost}
          isBooking={isBooking}
          isDueTodayCostDisplayed={isDueTodayCostDisplayed}
          onShowBookingConditions={
            !isRailAccreditationEnabled ? bookingToggle.handleOpen : undefined
          }
          nationalRailConditions={
            isRailAccreditationEnabled ? NATIONAL_RAIL_CONDITIONS_URL : undefined
          }
          privacyPolicy={privacyPolicy}
          onHoldTrip={onHoldTrip}
          onSubmit={onSubmit}
        />
      </Swap>
      {tripCost && (
        <TripCostSummary
          segments={segments}
          tripCost={tripCost}
          isOpen={tripCostSummaryToggle.isOpen}
          onClose={tripCostSummaryToggle.handleClose}
          isHoldFlow={isHoldFlow}
          showTaxBreakDown={true}
        />
      )}
      <BookingConditions isVisible={bookingToggle.isOpen} onClose={bookingToggle.handleClose} />
    </Wrapper>
  )
})
