import styled from 'styled-components'
import { headline, footnoteStrong, footnoteMedium, subHeadStrong } from '@fiji/style'

export const Container = styled.div`
  padding: 10px 20px;
`

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText2};
  ${headline};
`

export const Section = styled.div`
  padding: 16px 0;
`

export const SectionTitle = styled.div`
  text-transform: uppercase;
  margin: 0 0 8px;
  ${footnoteStrong}
`

export const SectionContent = styled.div`
  padding: 0 0 8px;
  margin: 0 0 12px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 6px;
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium}
`

export const SectionSum = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SectionSumMain = styled.div`
  margin: 0 0 2px;
  ${subHeadStrong}
`

export const SectionSumPrice = styled.div`
  text-align: right;
`

export const SectionSumSecondary = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium}
`
