import type { ReactNode } from 'react'
import { OfflineBlock } from '@etta/components/offline-block'
import type { MobileButtonVariantProps } from '@etta/ui/button'
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  ButtonContainer,
  Button,
} from './no-result-search-screen-styled'
import type { ResultType } from './types'
import { Icon } from './icon'

type ButtonProp = {
  title: string
  onClick?: () => void
  buttonVariant?: MobileButtonVariantProps
}

type Props = {
  type: ResultType
  title?: string | ReactNode
  subTitle?: string | ReactNode
  isSubTitleBold?: boolean
  buttons: ButtonProp[]
}

export function NoResultSearchScreen({ type, isSubTitleBold, buttons, title, subTitle }: Props) {
  const areButtonsShown = buttons.some((button) => button.onClick)
  const onOfflineBlockClick = buttons[0]?.onClick
  const shouldButtonsHavePaddings = buttons.length < 2

  return (
    <Wrapper>
      <Container data-tracking-id="no-results-screen" data-testvalue={type}>
        <OfflineBlock onButtonClick={onOfflineBlockClick}>
          <Icon type={type} />
          {title && (
            <Title variant="headline" color="mainText">
              {title}
            </Title>
          )}
          {subTitle && (
            <SubTitle
              variant="footnoteMedium"
              color="bodyText"
              align="center"
              isBold={isSubTitleBold}>
              {subTitle}
            </SubTitle>
          )}
          {areButtonsShown && (
            <ButtonContainer>
              {buttons.map(({ title, onClick, buttonVariant }, index) => (
                <Button
                  key={index}
                  onClick={onClick}
                  variant={buttonVariant ?? 'text'}
                  fullWidth
                  paddings={shouldButtonsHavePaddings}>
                  {title}
                </Button>
              ))}
            </ButtonContainer>
          )}
        </OfflineBlock>
      </Container>
    </Wrapper>
  )
}
