import { SegmentState } from '@fiji/graphql/types'

type Props = {
  isPayingNow?: boolean
  segmentState?: SegmentState | null
}

export function getPayLabel({ isPayingNow, segmentState }: Props) {
  if (segmentState === SegmentState.Changed && isPayingNow) {
    return 'UpdatedPrice'
  }
  if (isPayingNow) {
    return 'DueToday'
  }
  return 'PayableLater'
}
