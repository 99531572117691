import { Action, Inject } from '@etta/di'
import { PostBookingTripDetailsStore } from '../stores/trip-details/post-booking-trip-details.store'
import type { GroupedSegmentValueObject } from '../../core/value-objects'

@Action()
export class PostBookingTripDetailsActions {
  constructor(@Inject() private postBookingTripSelectedDetailsStore: PostBookingTripDetailsStore) {}

  handleOpenSelectedDetails(segment: GroupedSegmentValueObject) {
    this.postBookingTripSelectedDetailsStore.setSelectedDetails(segment)
    this.postBookingTripSelectedDetailsStore.selectedDetailsToggle.handleOpen()
  }

  handleOpenSelectedOpenReturnDetails(segment: GroupedSegmentValueObject) {
    this.postBookingTripSelectedDetailsStore.setSelectedDetails(segment)
    this.postBookingTripSelectedDetailsStore.selectedOpenReturnDetailsToggle.handleOpen()
  }

  handleUpdateSelectedDetails(segment: GroupedSegmentValueObject) {
    this.postBookingTripSelectedDetailsStore.setSelectedDetails(segment)
  }

  dropTripDetailsStore() {
    this.postBookingTripSelectedDetailsStore.dropTripDetailsStore()
  }
}
