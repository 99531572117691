import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { TravelTime } from '@fiji/graphql/types'

const i18nBase = 'RailDetails'

export function useRailDuration() {
  const { t } = useTranslation()
  return useCallback(
    (travelTime?: TravelTime | null) => {
      if (!travelTime) {
        return ''
      }

      const hour = travelTime.hours ? t(i18nBase + '.Hours', { hours: travelTime.hours }) : ''
      const minute = travelTime.minutes
        ? t(i18nBase + '.Minutes', { minutes: travelTime.minutes })
        : ''

      return [hour, minute].filter(Boolean).join(' ')
    },
    [t],
  )
}
