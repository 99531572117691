import { useTranslation } from 'react-i18next'
import { Container, Button } from './legend-button-styled'

const i18nBase = 'AirSeatMap.Legend'

type Props = {
  isVisible: boolean
  isReadonly?: boolean
  onClick: () => void
}

export function LegendButton({ isVisible, isReadonly = false, onClick }: Props) {
  const { t } = useTranslation()

  return (
    <Container isVisible={isVisible} isReadonly={isReadonly}>
      <Button
        data-tracking-id="seat-map-show-legends-button"
        aria-label={t(`${i18nBase}.Title`)}
        onClick={onClick}>
        {t(`${i18nBase}.Title`)}
      </Button>
    </Container>
  )
}
