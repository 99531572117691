import { Action, Inject } from '@etta/di'
import { ItineraryAdapter } from '../../infra'
import type { AlignTripTravelVerticalValueObject } from '../../core/value-objects/align-trip-travel-vertical.value-object'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import { GetTripService } from '../services/get-trip.service'

export type HandleAlignArgs = {
  alignSegmentTypes: AlignTripTravelVerticalValueObject[]
  startDate: string
  endDate: string
}

@Action()
export class AlignTripAction {
  constructor(
    @Inject() private readonly itineraryAdapter: ItineraryAdapter,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly tripActions: GetTripService,
  ) {}

  async handleAlignTrip({ alignSegmentTypes, startDate, endDate }: HandleAlignArgs) {
    const result = await this.itineraryAdapter.alignItineraryDates({
      itineraryId: this.reviewTripStore.itineraryId,
      startDate,
      endDate,
      alignSegmentTypes,
    })

    if (result.isOk()) {
      await this.tripActions.updateTrip()
    }

    return result
  }
}
