import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { Container, Content, Message } from './dynamic-site-messages-lightbox-modal-desktop-styled'

const i18nBase = 'DynamicSiteMessage.Lightbox'

// TODO: Migrate Lightbox Modal to updated design per SPEC-1445 (https://deem.atlassian.net/browse/SPEC-1445) in the next sprint.
export const DynamicSiteMessagesLightboxModalDesktop = observer(
  function DynamicSiteMessagesLightboxModalDesktop({ messages, isVisible, onClose }: LayoutProps) {
    const { t } = useTranslation()

    return (
      <Modal isVisible={isVisible} handleModalVisibility={onClose}>
        <Modal.Header isMobile>
          <Header
            rightSlot={
              <IconButton
                aria-label={t(`${i18nBase}.AllyCloseModal`)}
                icon="closeFilledPWA"
                size="medium"
                color="bodyText"
                onClick={onClose}
              />
            }
          />
        </Modal.Header>
        <Block marginBottom={36}>
          <Modal.Body>
            <Container>
              {messages.map(({ id, header, text, hasDesktopBannerImage }) => {
                return (
                  <Content key={id}>
                    <Block marginBottom={16}>
                      <Text aria-label={header} variant="title3" color="mainText">
                        {header}
                      </Text>
                    </Block>
                    <Message
                      aria-label={text}
                      hasDesktopBannerImage={hasDesktopBannerImage}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </Content>
                )
              })}
            </Container>
          </Modal.Body>
        </Block>
      </Modal>
    )
  },
)
