import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  CreateCustomerUberAccountMutation,
  CreateCustomerUberAccountMutationVariables,
  GetCustomerUberAccountStatusQuery,
  GetCustomerUberAccountStatusQueryVariables,
} from '@fiji/graphql/hooks'
import {
  CreateCustomerUberAccountDocument,
  GetCustomerUberAccountStatusDocument,
} from '@fiji/graphql/hooks'

@DataProvider()
export class CustomerUberAccountManagementDataProvider extends GraphqlDataProvider {
  createCustomerUberAccountAbortController: AbortController | null = null
  getCustomerUberAccountStatusAbortController: AbortController | null = null

  createCustomerUberAccount() {
    this.abortCreateCustomerUberAccount()
    return this.client.mutate<
      CreateCustomerUberAccountMutation,
      CreateCustomerUberAccountMutationVariables
    >({
      mutation: CreateCustomerUberAccountDocument,
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          controller: this.createCustomerUberAccountAbortController,
        },
      },
    })
  }

  abortCreateCustomerUberAccount() {
    this.createCustomerUberAccountAbortController?.abort()
    this.createCustomerUberAccountAbortController = new AbortController()
  }

  getCustomerUberAccountStatus() {
    this.abortGetCustomerUberAccountStatus()
    return this.client.query<
      GetCustomerUberAccountStatusQuery,
      GetCustomerUberAccountStatusQueryVariables
    >({
      query: GetCustomerUberAccountStatusDocument,
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        context: {
          fetchOptions: {
            controller: this.getCustomerUberAccountStatusAbortController,
          },
        },
      },
    })
  }

  abortGetCustomerUberAccountStatus() {
    this.getCustomerUberAccountStatusAbortController?.abort()
    this.getCustomerUberAccountStatusAbortController = new AbortController()
  }
}
