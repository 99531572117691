import styled, { css } from 'styled-components'
import { footnoteMedium, headline, title3 } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'
import {
  createHeaderScrollAnimation,
  getBasicHeaderScrollAnimation,
} from '@fiji/hooks/use-header-scroll'
import bg from './images/bg.png'

const BORDER_RADIUS = '14px'
const TITLES_CONTAINER_X = '48px'

export const CarImage = styled.div<{ carImageUrl?: string | null }>`
  position: relative;
  background: url(${(p) => p.carImageUrl || ''}) left 50% bottom calc(-35px + ${BORDER_RADIUS}) /
      contain no-repeat,
    url(${bg}) 50% 50% / cover no-repeat;
  width: 100%;
  min-height: 300px;
  margin-bottom: -${BORDER_RADIUS};
`

export const CloseButton = styled(IconButton)`
  left: 8px;
  top: 10px;
  position: absolute;
  ${getBasicHeaderScrollAnimation('opacity-show')}
`

export const CarTitleName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CarTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${title3};

  ${createHeaderScrollAnimation(
    'car-rental-details-header-title',
    css`
       {
        to {
          margin-bottom: 0;
          ${headline};
        }
      }
    `,
  )}
`

export const TitlesContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  transform: translateX(0px);

  ${createHeaderScrollAnimation(
    'car-rental-details-titles-container',
    css`
       {
        to {
          transform: translateX(${TITLES_CONTAINER_X});
        }
      }
    `,
  )}
`

export const CloseButtonTop = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.colors.mainText};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${getBasicHeaderScrollAnimation('opacity-hide')}
`

export const Header = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: 16px;
  ${createHeaderScrollAnimation(
    'car-rental-details-header',
    css`
       {
        to {
          padding: 9px;
          border-radius: 0;
        }
      }
    `,
  )}
`

export const VendorImgContainer = styled.div`
  height: 30px;
  max-width: 100%;
  display: flex;
  align-items: center;
  opacity: 1;
  transform: translateX(0px);

  ${createHeaderScrollAnimation(
    'car-rental-details-vendor',
    css`
       {
        to {
          opacity: 0;
          transform: translateX(-${TITLES_CONTAINER_X});
        }
      }
    `,
  )};
`

export const VendorImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const PreferedLabelContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

export const Label = styled.div`
  margin-left: 5px;
`

export const PolicyContainer = styled.div`
  ${footnoteMedium}
  margin-top: 5px;
`
