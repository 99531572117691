import { Domain } from '@fiji/graphql/types'
import type { CarRentalDomain, HotelDomain, Intent, FlightDomain } from './types'

export class CheckDomain {
  static isCar = (
    domain: Domain,
    domainObject: Intent['domainObject'],
  ): domainObject is CarRentalDomain => {
    return domainObject && domain === Domain.CarRental
  }

  static isHotel = (
    domain: Domain,
    domainObject: Intent['domainObject'],
  ): domainObject is HotelDomain => {
    return domainObject && domain === Domain.Hotel
  }

  static isFlight = (
    domain: Domain,
    domainObject: Intent['domainObject'],
  ): domainObject is FlightDomain => {
    return domainObject && domain === Domain.Air
  }
}
