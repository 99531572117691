import { Icon } from '@etta/ui/icon'
import { TextField } from '@etta/ui/text-field'
import type { SingleFieldViewProps } from '../types'
import { IconContainer } from './select-styled'

export function Select({
  size,
  value,
  helperType,
  helperText,
  label,
  placeholder,
  isDisabled,
  onClick,
  'data-tracking-id': dataTrackingId,
}: SingleFieldViewProps) {
  return (
    <TextField
      data-tracking-id={dataTrackingId}
      onClick={onClick}
      placeholder={placeholder}
      label={label}
      value={value}
      size={size}
      endSlot={
        <IconContainer onClick={onClick}>
          <Icon name="expandMorePWA" size="medium" color="mainText" />
        </IconContainer>
      }
      isDisabled={isDisabled}
      helperText={helperText}
      helperType={helperType}
    />
  )
}
