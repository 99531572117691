import { initialSearchParams } from '@fiji/constants'
import type { SearchParams } from '@fiji/hooks/use-search-params/types'
import { parseDistance, DISTANCE_MAX_DEFAULT } from '@fiji/utils/distance'
import type {
  FailedBooking,
  GetHotelQueryVariables,
  GetHotelsQueryVariables,
} from '@fiji/graphql/types'
import { SearchLocationType } from '@fiji/enums'
import { SearchBy } from '@fiji/types'

type GetHotelsQueryBuilderArg = {
  variables: {
    first?: number
    offset?: number
    orderBy?: string
  }
  searchParams: SearchParams
  hotelSearchRadius: number
  isPhoenixHotelSearchEnabled?: boolean | undefined
  hotelResultId?: string
}

type FailedBookingArgs = {
  hotelId?: string
  rateCode?: string
  providerId?: string
}

function getFailedBooking({
  hotelId,
  rateCode,
  providerId,
}: FailedBookingArgs): FailedBooking | undefined {
  if (!hotelId) {
    return undefined
  }
  if (!rateCode || !providerId) {
    return {
      hotelIds: [hotelId],
      rooms: [],
    }
  }
  return {
    hotelIds: [hotelId],
    rooms: [
      {
        hotelId,
        providerId,
        rateCode,
      },
    ],
  }
}

export function buildGetHotelQueryVariables(
  id: string,
  searchParams = initialSearchParams,
): GetHotelQueryVariables {
  return {
    id,
    checkIn: searchParams.checkIn,
    checkOut: searchParams.checkOut,
    guests: searchParams.guests,
    latitude: searchParams.latitude,
    longitude: searchParams.longitude,
    groupId: searchParams.groupId,
  }
}

export function buildGetHotelsQueryVariables(
  {
    variables,
    searchParams,
    hotelSearchRadius,
    isPhoenixHotelSearchEnabled,
    hotelResultId,
  }: GetHotelsQueryBuilderArg = {
    isPhoenixHotelSearchEnabled: false,
    searchParams: initialSearchParams,
    hotelSearchRadius: DISTANCE_MAX_DEFAULT,
    variables: {},
  },
): GetHotelsQueryVariables {
  const failedBooking = getFailedBooking({
    hotelId: searchParams.hotelId,
    rateCode: searchParams.rateCode,
    providerId: searchParams.providerId,
  })

  return {
    checkIn: searchParams.checkIn,
    checkOut: searchParams.checkOut,
    latitude: searchParams.latitude,
    longitude: searchParams.longitude,
    guests: searchParams.guests,
    groupId: searchParams.groupId,
    locationName: searchParams.locationName,
    stationCode: searchParams.stationCode,
    airportCode: searchParams.airportCode,
    nameSearch: searchParams.nameSearch,
    hideSoldOut: searchParams.hideSoldOut,
    first: variables.first || searchParams.first,
    offset: variables.offset || searchParams.offset,
    orderBy: variables.orderBy,
    amenityIds: searchParams.amenityIds,
    brandIds: searchParams.brandIds,
    starRatings: searchParams.starRatings,

    hotelResultId,
    distance: parseDistance(searchParams.distance, hotelSearchRadius),
    searchBy:
      document.location.search.includes('original-trip-page') &&
      // FIXME: As of 09 SEP 20, SearchParams.Address is a temporary fix
      // and should be removed once the GAZOO release in late September happens.
      (searchParams as SearchParams).searchBy === SearchBy.Address
        ? undefined
        : (searchParams as SearchParams).searchBy,
    hotelName: isPhoenixHotelSearchEnabled
      ? searchParams.hotelNameForFilter
      : getHotelNameQueryVariable(searchParams),
    failedBooking: isPhoenixHotelSearchEnabled ? failedBooking : undefined,
  }
}

export function buildCreateSearchQueryVariables(
  searchParams = initialSearchParams,
  maxDistance = DISTANCE_MAX_DEFAULT,
) {
  return {
    itineraryId: searchParams.itineraryId,
    searchBy: getHotelSearchBy(searchParams.searchBy || ''),
    latitude: searchParams.latitude,
    longitude: searchParams.longitude,
    distance: parseDistance(searchParams.distance, maxDistance),
    checkIn: searchParams.checkIn,
    checkOut: searchParams.checkOut,
    guests: searchParams.guests,
    locale: 'en',
    hotelName: getHotelNameQueryVariable(searchParams),
    airportCode: searchParams.airportCode,
  }
}

function getHotelNameQueryVariable(searchParams = initialSearchParams) {
  if (searchParams.hotelNameForSearch) {
    return searchParams.hotelNameForSearch
  }

  if (searchParams.nameSearch && !searchParams.hotelNameForSearch) {
    return searchParams.query
  }

  return ''
}

export function getHotelSearchBy(searchBy: string): number {
  switch (searchBy) {
    case SearchBy.Address:
      return SearchLocationType.ADDRESS
    case SearchBy.Geocode:
      return SearchLocationType.CITY
    case SearchBy.Airport:
      return SearchLocationType.AIRPORT
    case SearchBy.Station:
      return SearchLocationType.STATION
    default:
      return SearchLocationType.UNSPECIFIED
  }
}
