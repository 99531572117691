import type { SeatPreference } from '@fiji/graphql/types'

type Props = {
  preference: SeatPreference[]
  value?: string | null
}

export function getLabelFromValue({ value, preference }: Props) {
  if (value === 'NONE' || !value) {
    return ''
  }

  return preference.find((item) => item.code === value)?.name
}
