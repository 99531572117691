import { Inject, Store } from '@etta/di'
import type { Gender } from '@etta/core/enums'
import type { UserSuffix, UserTitle } from '@etta/modules/user'
import { Toggle } from '@etta/interface/services/toggle'
import { ValidatorMaker } from '@etta/modules/settings/validators/validator-maker'
import { Validator } from '@etta/interface/services/validator'
import { FieldSettingsStore } from './field-settings'

export type PersonalInformationInput = {
  title: UserTitle | null
  firstName: string
  middleName: string
  lastName: string
  suffix: UserSuffix | null
  gender: Gender | null
  dateOfBirth?: Date | null
  email: string
  username: string
  profilePin: string
  homePhoneNumber: string
  homePhoneCountryCode: string
}

@Store()
export class PersonalInformationStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}
  private _additionalInformationValidator: Validator = new Validator({})
  isSaveButtonDisabled = true

  toggle = new Toggle()
  isDobTouched: boolean = false
  isGenderTouched = false

  setIsDobTouched(isDobTouched: boolean) {
    this.isDobTouched = isDobTouched
  }
  setIsGenderTouched(isGenderTouched: boolean) {
    this.isGenderTouched = isGenderTouched
  }

  personalInformationValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makePersonalInformationValidator()

  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }

  setPersonalInformation(personalInformationValidator: PersonalInformationInput) {
    this.setPersonalInformationValue('title', personalInformationValidator.title)
    this.setPersonalInformationValue('firstName', personalInformationValidator.firstName)
    this.setPersonalInformationValue('middleName', personalInformationValidator.middleName)
    this.setPersonalInformationValue('lastName', personalInformationValidator.lastName)
    this.setPersonalInformationValue('suffix', personalInformationValidator.suffix)
    this.setPersonalInformationValue('gender', personalInformationValidator.gender)
    this.setPersonalInformationValue('dateOfBirth', personalInformationValidator.dateOfBirth)
    this.setPersonalInformationValue('email', personalInformationValidator.email)
    this.setPersonalInformationValue('username', personalInformationValidator.username)
    this.setPersonalInformationValue('profilePin', personalInformationValidator.profilePin)
    this.setPersonalInformationValue(
      'homePhoneNumber',
      personalInformationValidator.homePhoneNumber,
    )
    this.setPersonalInformationValue(
      'homePhoneCountryCode',
      personalInformationValidator.homePhoneCountryCode,
    )
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setPersonalInformationValue<T extends keyof PersonalInformationInput>(
    field: T,
    value: PersonalInformationInput[T],
  ) {
    this.personalInformationValidator.onFieldChange(field, value)
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
