/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation } from 'react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import { getPath } from '@etta/interface/services/query-params/params/get-path'
import type { OptionsType } from './types'
import { useParsedData } from './use-parsed-data'

export function useQueryParams<T>(initialData?: Partial<T>, options?: OptionsType<T>) {
  const caseStyle = options?.caseStyle

  const { search, pathname } = useLocation()
  const history = useHistory()

  const { getParsedData } = useParsedData<T>({ options, initialData })

  const parsedQueryParams = useMemo(() => getParsedData(search), [search])

  const [searchParams, setSearchParams] = useState<T>(parsedQueryParams)

  useEffect(() => {
    if (!checkIsEqual(parsedQueryParams, searchParams)) {
      setSearchParams(parsedQueryParams)
    }
  }, [parsedQueryParams, searchParams])

  const appendQueryParams = useCallback(
    (queryParams: Partial<T>): void => {
      const newParams = Object.assign(searchParams, queryParams)

      const newPath = getPath<T>(pathname, newParams, caseStyle)
      setSearchParams(newParams)
      history.replace(newPath)
    },
    [searchParams, pathname, caseStyle, history],
  )

  function clearQueryParams(): void {
    const params = getParsedData('')
    setSearchParams(params)
    const newPath = getPath<T>(pathname, params, caseStyle)
    history.replace(newPath)
  }

  function navigateTo(path: string, appendParams?: Partial<T>): void {
    const newParams = Object.assign(searchParams, appendParams)
    setSearchParams(newParams)
    const newPath = getPath<T>(path, newParams, caseStyle)
    history.push(newPath)
  }

  return {
    queryParams: searchParams,
    appendQueryParams,
    clearQueryParams,
    navigateTo,
  }
}
