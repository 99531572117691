import { Result } from 'fnscript'
import { ApolloError } from '@apollo/client'
import { Inject, Adapter } from '@etta/di'
import { CarRentalErrors } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'
import { CarRentalLocationSortBy } from '@fiji/graphql/types'
import { getRestrictedErrorData } from '@fiji/utils/get-restricted-error-message'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import type {
  CarRentalSearchResults,
  GetCarRentalLocationsArgs,
  GetCarRentalLocationsResult,
} from '../../types'
import { CarRentalDataProvider } from '../../data-providers'

@Adapter()
export class CarRentalLocationAdapter {
  constructor(
    @Inject()
    private carRentalDataProvider: CarRentalDataProvider,
  ) {}

  async getCarRentalLocations({
    geocode,
    searchDateTime,
    allowedVendors,
    itineraryId,
    isDropOff,
  }: GetCarRentalLocationsArgs): CarRentalSearchResults<GetCarRentalLocationsResult> {
    try {
      const { data, error } = await this.carRentalDataProvider.getCarRentalLocations({
        sortBy: CarRentalLocationSortBy.Distance,
        startIndex: 0,
        geocode,
        searchDateTime,
        allowedVendors,
        logsMetadata: {
          itineraryId,
        },
        isDropOff,
      })
      if (error) {
        return Result.Err(new CarRentalErrors.LocationsResponse(error))
      }

      // TODO: add mapper for locations
      const locations: CarRentalMultiVendorLocation[] =
        data?.carRentalMultiVendorLocations?.location || []
      const messages: DynamicSiteMessageValueObject[] =
        data?.carRentalMultiVendorLocations?.message || []

      return Result.Ok({ locations, messages })
    } catch (e) {
      if (e instanceof ApolloError && e.graphQLErrors) {
        const { isRestrictedCountryError, restrictedErrorMessage } = getRestrictedErrorData(
          e.graphQLErrors || [],
          'restrictionMessage',
        )

        if (isRestrictedCountryError) {
          return Result.Err(
            new CarRentalErrors.RestrictedCountryError({ message: restrictedErrorMessage || '' }),
          )
        }
      }

      return Result.Err(new CarRentalErrors.UnexpectedErrorLocations(e))
    }
  }
}
