import { Action, Inject } from '@etta/di'
import { CreateCustomerUberAccountService } from '@etta/modules/ride-hail/interface/services/create-customer-uber-account.service'

@Action()
export class CreateCustomerUberAccountActions {
  constructor(
    @Inject()
    private readonly createCustomerUberAccountService: CreateCustomerUberAccountService,
  ) {}

  async createCustomerUberAccount() {
    await this.createCustomerUberAccountService.createCustomerUberAccount()
  }

  abortCreateCustomerUberAccount() {
    this.createCustomerUberAccountService.abortCreateCustomerUberAccount()
  }
}
