import { Inject, Store } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import type { AmenityFilter, Room } from '@fiji/graphql/types'
import { Toggle } from '@etta/interface/services/toggle'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import type { HotelEntity } from '../../core/entities/hotel.entity'
import type { GetHotelsSortBy } from '../../core/value-objects/get-hotels-sorty-by.value-object'
import type { HotelsResultsValueObject } from '../../core/value-objects/hotels-results.value-object'
import { getFilterOptions } from './get-filter-options'
import { HotelQueryStore } from './hotel-search-query.store'

export const PAGE_SIZE = 10

@Store()
export class HotelSearchResultsStore {
  constructor(
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
  ) {}
  activeItineraryToggle = new Toggle()
  tripExpiredToggle = new Toggle()
  hotelDetailsModalToggle = new Toggle()
  offset = 0
  sortBy: GetHotelsSortBy = 'preference'

  hotelResults: Omit<HotelsResultsValueObject, 'hotels'> | null = null
  hotels: HotelEntity[] = []
  searchId: string | null = null
  isLoading = true
  isCreateSearchLoading = false
  isFetchMoreLoading = false
  isFetchMoreError = false
  isError = false
  isRestrictedCountryError = false
  restrictedErrorMessage = ''

  pickedHotel: HotelEntity | null = null

  amenitySearchQuery = ''
  selectedRoom: Room | null = null
  viewState: TripStateStatus = null
  selectedHotelId: string | null = null

  setSelectHotelId(value: string | null) {
    this.selectedHotelId = value
  }

  setAmenitySearchQuery(value: string) {
    this.amenitySearchQuery = value
  }

  setSelectedRoom(room: Room | null) {
    this.selectedRoom = room
  }

  setViewState(value: TripStateStatus | null) {
    this.viewState = value
  }

  get amenitySearchResults(): AmenityFilter[] {
    if (!this.amenitySearchQuery) {
      return this.filterOptions.amenities.sortedAmenities
    }

    return this.filterOptions.amenities.sortedAmenities.filter((amenity) =>
      amenity.amenityDisplayName.toLowerCase().includes(this.amenitySearchQuery.toLowerCase()),
    )
  }

  get filterOptions() {
    return getFilterOptions({
      selectedFilters: this.hotelQueryStore.hotelSearchQuery.hotelFilters,
      brandFilters: this.hotelResults?.brandFilters,
      groups: this.hotelResults?.groups,
      amenityFilters: this.hotelResults?.amenityFilters,
      superBrandFilters: this.hotelResults?.superBrandFilters,
    })
  }

  get initialGroupId() {
    return (
      this.hotelResults?.defaultGroup?.id ||
      this.hotelResults?.groups?.find((group) => group.count > 0)?.id ||
      ''
    )
  }

  get isNextPage() {
    if (this.isFetchMoreError) {
      return false
    }
    if (!this.hotelResults?.totalCount) {
      return false
    }
    return this.hotelResults.totalCount > this.offset + PAGE_SIZE
  }

  setOffset(value: number) {
    this.offset = value
  }

  setSortBy(value: GetHotelsSortBy) {
    this.sortBy = value
  }

  setHotelResults(value: Omit<HotelsResultsValueObject, 'hotels'> | null) {
    this.hotelResults = value
  }

  setHotels(value: HotelEntity[]) {
    this.hotels = value
  }

  setMoreHotels(value: HotelEntity[]) {
    const newHotels = [...this.hotels, ...value].reduce<Record<string, HotelEntity>>(
      (acc, item) => {
        acc[item.id] = item
        return acc
      },
      {},
    )
    this.hotels = Object.values(newHotels)
  }

  setHotelById(value: Partial<HotelEntity>) {
    const hotelIndex = this.hotels.findIndex((hotel) => hotel.id === value.id)

    if (hotelIndex === -1) {
      return
    }

    if (this.pickedHotel && value.id === this.pickedHotel.id) {
      this.setPickedHotel({ ...this.pickedHotel, ...value })
    }

    this.hotels[hotelIndex] = { ...this.hotels[hotelIndex], ...value }
  }

  setSearchId(value: string | null) {
    this.searchId = value
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsCreateSearchLoading(value: boolean) {
    this.isCreateSearchLoading = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setIsRestrictedCountryError(value: boolean) {
    this.isRestrictedCountryError = value
  }

  setRestrictedCountryMessage(value: string) {
    this.restrictedErrorMessage = value
  }

  setIsFetchMoreLoading(value: boolean) {
    this.isFetchMoreLoading = value
  }

  setIsFetchMoreError(value: boolean) {
    this.isFetchMoreError = value
  }

  setPickedHotel(hotel: HotelEntity) {
    this.pickedHotel = hotel
  }

  dropHotelStore() {
    this.setIsLoading(true)
    this.setIsError(false)
    this.setIsFetchMoreError(false)
    this.setHotelResults(null)
    this.setHotels([])
    this.setSearchId(null)
    this.setIsCreateSearchLoading(false)
  }
}
