import type { RefObject } from 'react'
import { useLayoutEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { CountrySearch } from '@etta/components/country-search/country-search'
import { Block } from '@etta/ui/block'
import { Select } from '@etta/ui/select'
import { TextField } from '@etta/ui/text-field'
import { SwipingDatePicker } from '@etta/ui/swiping-time-date-picker'
import { Button } from '@etta/ui/button'
import { TextFieldGroup } from '@etta/ui/text-field-group'
import { helperTypeUndefinedCheck } from '@etta/screens/traveler-info/purchase-user-info/user-info-form/data-checks'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import { dateToString } from '../../date-to-string'
import { default as countryCodesForPhone } from '../../country-codes-phone.json'
import type { ComponentProps } from '../../types'
import {
  Body,
  Footer,
  HalfBlock,
  HalfBlockInline,
  Headline,
  Subheadline,
} from './user-info-form-mobile-styled'

const DEFAULT_MAX_LENGTH = 255

type RefsKeys =
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'suffix'
  | 'gender'
  | 'dob'
  | 'email'
  | 'phone'
  | 'address'
  | 'phoneCountryCode'
  | 'personalMobile'
  | 'personalMobileCountryCode'

export function UserInfoFormMobile({
  profileOptions,
  isDateOfBirthPopupVisible,
  onDateOfBirthOpen,
  onDateOfBirthClose,
  errors,
  values,
  onFieldChange,
  handleSubmit,
  isGenderRequired,
  isDateOfBirthRequired,
  isFirstNameRequired,
  isLastNameRequired,
  isMiddleNameRequired,
  isSuffixRequired,
  isEMailRequired,
  isPhoneNumberRequired,
  isAddressRequired,
  isKtnHidden,
  isRedressNumber,
  isGenderInputDisabled,
  isDateOfBirthInputDisabled,
  isSuffixInputDisabled,
  isTitleDisabled,
  isEmailInputDisabled,
  isFirstNameDisabled,
  isMiddleNameDisabled,
  isLastNameDisabled,
  isAddressDisabled,
  isPhoneNumberInputDisabled,
  isPersonalMobileInputDisabled,
}: ComponentProps) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const { purchaseInfoStore } = usePurchaseInfoContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refs: Record<RefsKeys, RefObject<HTMLDivElement>> = {
    firstName: useRef<HTMLDivElement>(null),
    middleName: useRef<HTMLDivElement>(null),
    lastName: useRef<HTMLDivElement>(null),
    suffix: useRef<HTMLDivElement>(null),
    gender: useRef<HTMLDivElement>(null),
    dob: useRef<HTMLDivElement>(null),
    email: useRef<HTMLDivElement>(null),
    phone: useRef<HTMLDivElement>(null),
    address: useRef<HTMLDivElement>(null),
    phoneCountryCode: useRef<HTMLDivElement>(null),
    personalMobile: useRef<HTMLDivElement>(null),
    personalMobileCountryCode: useRef<HTMLDivElement>(null),
  }

  const i18nBase = 'UserInfo.'
  const isUserInDelegatedOrImpersonatedMode = !!(userStore.delegatedId || userStore.impersonatedId)
  const isDobObfuscated = isUserInDelegatedOrImpersonatedMode && !purchaseInfoStore.isDobTouched

  const dateOfBirthValue = useMemo(() => {
    if (values.dateOfBirth) {
      const date = dateToString(values.dateOfBirth)
      if (isDobObfuscated) {
        return date.replace(/[A-Za-z0-9]/g, '*')
      }
      return date
    }

    return ''
  }, [values.dateOfBirth, isDobObfuscated])
  const dateOfBirthSwipeValue = useMemo(() => {
    if (isDobObfuscated) {
      return new Date()
    }
    return values.dateOfBirth
  }, [values.dateOfBirth, isDobObfuscated])

  const genderValue = useMemo(() => {
    if (values.gender) {
      if (isUserInDelegatedOrImpersonatedMode) {
        return values.gender.replace(/[A-Za-z0-9]/g, '*')
      }

      return values.gender
    }

    return ''
  }, [values.gender, isUserInDelegatedOrImpersonatedMode])

  useLayoutEffect(() => {
    const errorKeys = Object.keys(errors)
    const errorKeysWithError = errorKeys.filter((errorKey) => {
      if (errors[errorKey] !== '') {
        return errorKey
      }
    })
    if (errorKeysWithError.length) {
      const [firstErrorKey] = errorKeysWithError as RefsKeys[]
      refs[firstErrorKey]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      refs[firstErrorKey]?.current?.focus()
    }
  }, [errors, refs])

  return (
    <>
      <Body>
        <Block paddingHorizontal={20}>
          <Block marginTop={23}>
            <Headline>{t(i18nBase + 'Identification')}</Headline>
            <Subheadline>{t(i18nBase + 'ConfirmName')}</Subheadline>
          </Block>
          <HalfBlock marginTop={27}>
            <Select
              label={t(i18nBase + 'OptionalTitle')}
              options={profileOptions.titles}
              value={values.title}
              onChange={onFieldChange('title')}
              isDisabled={isTitleDisabled}
              withNoneOption
            />
          </HalfBlock>
          <Block marginTop={8} ref={refs.firstName}>
            <TextField
              value={values.firstName}
              onChange={onFieldChange('firstName')}
              label={t(i18nBase + (isFirstNameRequired ? 'FirstName' : 'OptionalFirstName'))}
              maxLength={DEFAULT_MAX_LENGTH}
              helperType="error"
              helperText={errors.firstName}
              isDisabled={isFirstNameDisabled}
            />
          </Block>
          <Block marginTop={8} ref={refs.middleName}>
            <TextField
              value={values.middleName}
              onChange={onFieldChange('middleName')}
              label={t(i18nBase + (isMiddleNameRequired ? 'MiddleName' : 'OptionalMiddleName'))}
              maxLength={DEFAULT_MAX_LENGTH}
              helperType="error"
              helperText={errors.middleName}
              isDisabled={isMiddleNameDisabled}
            />
          </Block>
          <Block marginTop={8} ref={refs.lastName}>
            <TextField
              value={values.lastName}
              onChange={onFieldChange('lastName')}
              label={t(i18nBase + (isLastNameRequired ? 'LastName' : 'OptionalLastName'))}
              maxLength={DEFAULT_MAX_LENGTH}
              helperType="error"
              helperText={errors.lastName}
              isDisabled={isLastNameDisabled}
            />
          </Block>
          <HalfBlock marginTop={10} ref={refs.suffix}>
            <Select
              label={t(i18nBase + (isSuffixRequired ? 'Suffix' : 'OptionalSuffix'))}
              options={profileOptions.suffixes}
              value={values.suffix}
              onChange={onFieldChange('suffix')}
              helperType="error"
              helperText={errors.suffix}
              isDisabled={isSuffixInputDisabled}
              withNoneOption={!isSuffixRequired}
            />
          </HalfBlock>
          <Block marginTop={8} ref={refs.gender}>
            <Select
              label={t(i18nBase + (isGenderRequired ? 'Gender' : 'OptionalGender'))}
              options={profileOptions.genders}
              value={genderValue}
              onChange={onFieldChange('gender')}
              helperType={isGenderRequired ? 'error' : undefined}
              helperText={errors.gender}
              isBlurred={isUserInDelegatedOrImpersonatedMode}
              isDisabled={isGenderInputDisabled}
              withNoneOption={!isGenderRequired}
            />
          </Block>
          <Block marginTop={8} ref={refs.dob}>
            <TextField
              value={dateOfBirthValue}
              label={t(i18nBase + (isDateOfBirthRequired ? 'DOB' : 'OptionalDOB'))}
              onClick={onDateOfBirthOpen}
              helperType="error"
              helperText={errors.dateOfBirth}
              isBlurred={isDobObfuscated}
              isDisabled={isDateOfBirthInputDisabled}
            />
          </Block>
          <Block marginTop={8} ref={refs.email}>
            <TextField
              value={values.email}
              onChange={onFieldChange('email')}
              label={t(i18nBase + (isEMailRequired ? 'Email' : 'OptionalEmail'))}
              helperType="error"
              isDisabled={isEmailInputDisabled}
              helperText={errors.email}
            />
          </Block>
          <Block marginTop={8}>
            <TextFieldGroup>
              <Block ref={refs.phoneCountryCode}>
                <CountrySearch
                  customOptions={countryCodesForPhone}
                  value={values.phoneCountryCode}
                  setValue={onFieldChange('phoneCountryCode')}
                  helperType="error"
                  isDisabled={isPhoneNumberInputDisabled}
                />
              </Block>
              <Block ref={refs.phone}>
                <TextField
                  type="phone"
                  value={values.phone}
                  onChange={onFieldChange('phone')}
                  label={t(i18nBase + (isPhoneNumberRequired ? 'Phone' : 'OptionalPhone'))}
                  helperType="error"
                  helperText={[errors.phone, errors.phoneCountryCode].filter(Boolean).join(', ')}
                  isDisabled={isPhoneNumberInputDisabled}
                />
              </Block>
            </TextFieldGroup>
          </Block>
          <Block marginTop={8} marginBottom={40}>
            <TextFieldGroup>
              <Block ref={refs.personalMobileCountryCode}>
                <CountrySearch
                  customOptions={countryCodesForPhone}
                  value={values.personalMobileCountryCode}
                  setValue={onFieldChange('personalMobileCountryCode')}
                  helperType="error"
                  isDisabled={isPersonalMobileInputDisabled}
                />
              </Block>
              <Block ref={refs.personalMobile}>
                <TextField
                  type="phone"
                  value={values.personalMobile}
                  onChange={onFieldChange('personalMobile')}
                  label={t(i18nBase + (isPhoneNumberRequired ? 'Mobile' : 'OptionalMobile'))}
                  helperType="error"
                  helperText={[errors.personalMobile, errors.personalMobileCountryCode]
                    .filter(Boolean)
                    .join(', ')}
                  isDisabled={isPersonalMobileInputDisabled}
                />
              </Block>
            </TextFieldGroup>
          </Block>
          {isAddressRequired && (
            <>
              <Headline>
                {t(i18nBase + (isAddressRequired ? 'Address' : 'AddressOptional'))}
              </Headline>
              <Block marginTop={5} ref={refs.address}>
                <CountrySearch
                  value={values.country}
                  setValue={onFieldChange('country')}
                  helperType={isAddressRequired ? 'error' : undefined}
                  helperText={errors.country}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <HalfBlockInline marginTop={8}>
                <TextField
                  value={values.zip}
                  label={t(i18nBase + 'ZIP')}
                  onChange={onFieldChange('zip')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.zip}
                  isDisabled={isAddressDisabled}
                />
                <TextField
                  value={values.state}
                  label={t(i18nBase + 'State')}
                  onChange={onFieldChange('state')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.state}
                  isDisabled={isAddressDisabled}
                />
              </HalfBlockInline>
              <Block marginTop={8}>
                <TextField
                  value={values.city}
                  label={t(i18nBase + 'City')}
                  onChange={onFieldChange('city')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.city}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <Block marginTop={8}>
                <TextField
                  value={values.street1}
                  label={t(i18nBase + 'Street1')}
                  onChange={onFieldChange('street1')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.street1}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <Block marginTop={8} marginBottom={40}>
                <TextField
                  value={values.street2}
                  onChange={onFieldChange('street2')}
                  label={t(i18nBase + 'Street2')}
                  isDisabled={isAddressDisabled}
                />
              </Block>
            </>
          )}

          {!isKtnHidden && (
            <>
              <Headline>{t(i18nBase + 'KnownTravellerNumberTitle')}</Headline>
              <Block marginTop={8}>
                <TextField
                  value={values.knownTravelerNumber}
                  label={t(i18nBase + 'KnownTravellerNumber')}
                  onChange={onFieldChange('knownTravelerNumber')}
                />
              </Block>
              <Block marginTop={8} marginBottom={40}>
                <CountrySearch
                  value={values.knownTravelerIssuingCountry}
                  customLabel={t(i18nBase + 'KnownTravellerNumber')}
                  setValue={onFieldChange('knownTravelerIssuingCountry')}
                />
              </Block>
            </>
          )}
          {!isRedressNumber && (
            <>
              <Headline>{t(i18nBase + 'RedressNumber')}</Headline>
              <Block marginTop={8} marginBottom={40}>
                <TextField
                  value={values.redressNumber}
                  label={t(i18nBase + 'RedressNumber')}
                  onChange={onFieldChange('redressNumber')}
                />
              </Block>
            </>
          )}
        </Block>
      </Body>
      <Footer>
        <Button onClick={handleSubmit} variant="solid" btnType="primary" fullWidth>
          {t(i18nBase + 'Save')}
        </Button>
      </Footer>
      <SwipingDatePicker
        date={dateOfBirthSwipeValue}
        label={t(i18nBase + 'SelectDOB')}
        isVisible={isDateOfBirthPopupVisible}
        onClose={onDateOfBirthClose}
        onApply={onFieldChange('dateOfBirth')}
      />
    </>
  )
}
