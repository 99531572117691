import { useTranslation } from 'react-i18next'
import type { Props } from './types'
import { BookingConditionsLayout } from './layout'

const i18nBookingCondition = 'BookingCondition.'

export function BookingConditions(props: Props) {
  const { t } = useTranslation()
  const titleText = t(i18nBookingCondition + 'Title')
  const firstConditionText = t(i18nBookingCondition + 'FirstCondition')
  const secondConditionText = t(i18nBookingCondition + 'SecondCondition')
  const thirdConditionText = t(i18nBookingCondition + 'ThirdCondition')
  const conditionsLabel = [firstConditionText, secondConditionText, thirdConditionText].join(', ')

  return (
    <BookingConditionsLayout
      {...props}
      titleText={titleText}
      firstConditionText={firstConditionText}
      secondConditionText={secondConditionText}
      thirdConditionText={thirdConditionText}
      conditionsLabel={conditionsLabel}
    />
  )
}
