import styled, { css } from 'styled-components'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll'

export const Container = styled.div`
  position: sticky;
  z-index: 2;
  top: -1px;
  left: 0;
  height: auto;
  padding-top: 14px;
  ${createHeaderScrollAnimation(
    'trip-tabs',
    css`
       {
        to {
          background-color: ${({ theme }) => theme.colors.background4};
        }
      }
    `,
  )};
`
