import { RailSearchSegmentDetails } from '@etta/components/rail-search-segment-details/rail-search-segment-details'
import { RailFareCard } from '@etta/components/rail-fare-card/rail-fare-card'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { TrainServiceActiveTab } from '@fiji/enums'
import type { LayoutProps } from '../../types'
import { Container, FareSeparator } from './rail-search-segment-desktop-styled'

export function RailSearchSegmentDesktop({
  segment,
  origin,
  destination,
  onSelect,
  isLimited,
  isDisruptions,
  railLegType,
  useRailSearchSegmentValue,
}: LayoutProps) {
  const { railActions } = useRailContext()
  if (!useRailSearchSegmentValue) {
    return null
  }
  const {
    start,
    end,
    tripTime,
    travelChangesNumber,
    travelChangesLabel,
    daysInBetween,
    railwayCarriersInfo,
    segmentLabel,
    isSoldOut,
    uniqueTransportIconNames,
    isCheapest,
    isFastest,
    actualRailEmission,
    isPreferred,
    standardClassLabel,
    firstClassLabel,
    changeStations,
  } = useRailSearchSegmentValue

  const onInfoIconClick = (fareTier: string) =>
    railActions.handleTicketRestrictionsInfoOpen(fareTier, segment)

  // TODO: isHighlightFromLocation - Awaiting on Karthik to advise when to apply highlight
  return (
    <Container aria-label={segmentLabel} tabIndex={0}>
      <RailSearchSegmentDetails
        isHighlightFromLocation={!!origin?.airportCode && segment.origin !== origin?.airportCode}
        isHighlightToLocation={
          !!destination?.airportCode && segment.destination !== destination?.airportCode
        }
        fromLocation={segment.origin}
        toLocation={segment.destination}
        startTime={start}
        endTime={end}
        travelTime={tripTime}
        travelChanges={travelChangesLabel}
        daysInBetween={daysInBetween}
        carriersInfo={railwayCarriersInfo}
        isFastest={isFastest}
        isLimited={isLimited}
        changesNumber={travelChangesNumber}
        iconNames={uniqueTransportIconNames}
        actualRailEmission={actualRailEmission}
        onViewDetailsClick={() => {
          onSelect?.(TrainServiceActiveTab.Standard)
        }}
        isDisruptions={isDisruptions}
        changeStations={changeStations}
        isSoldOut={isSoldOut}
      />
      <FareSeparator />
      <RailFareCard
        isPreferred={!!isPreferred}
        fare={segment.standardClassFares[0]}
        onSelect={() => railActions.selectFareSeats(railLegType, segment.standardClassFares[0])}
        onInfoIconClick={onInfoIconClick}
        fareClassLabel={standardClassLabel}
        moreFaresCount={segment.standardClassFares.length - 1}
        isSoldOut={isSoldOut}
        onMoreFaresClick={() => {
          onSelect?.(TrainServiceActiveTab.Standard)
        }}
        isCheapest={isCheapest}
        isShortDesktopFareCard
      />
      <FareSeparator />
      <RailFareCard
        isPreferred={!!isPreferred}
        fare={segment.firstClassFares[0]}
        onSelect={() => railActions.selectFareSeats(railLegType, segment.firstClassFares[0])}
        onInfoIconClick={onInfoIconClick}
        fareClassLabel={firstClassLabel}
        moreFaresCount={segment.firstClassFares.length - 1}
        isSoldOut={isSoldOut}
        onMoreFaresClick={() => {
          onSelect?.(TrainServiceActiveTab.FirstClass)
        }}
        isShortDesktopFareCard
      />
    </Container>
  )
}
