import { SearchRideHailErrorReason } from '@fiji/graphql/types'
import { MobilitySearchResultErrorType } from '@etta/components/mobility-search-result-dialog'

export function mapSearchRideHailErrorReason(
  error: SearchRideHailErrorReason,
): MobilitySearchResultErrorType {
  switch (error) {
    case SearchRideHailErrorReason.PaymentMethodNotConfigured:
      return MobilitySearchResultErrorType.ErrorPaymentMethodNotSet
    case SearchRideHailErrorReason.MaxDistanceExceeded:
      return MobilitySearchResultErrorType.DistanceExceeded
    case SearchRideHailErrorReason.AirportPickupNotAvailable:
      return MobilitySearchResultErrorType.AirportPickupNotAvailable
    case SearchRideHailErrorReason.ArrivalFlightNotFound:
      return MobilitySearchResultErrorType.ArrivalFlightNotFound
    case SearchRideHailErrorReason.Unknown:
      return MobilitySearchResultErrorType.Undefined
  }

  return MobilitySearchResultErrorType.Undefined
}
