import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Header as MobileHeader } from '@etta/ui/header'
import { TextField } from '@etta/ui/text-field'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import type { LayoutProps } from '../../types'
import { useCarRentalOfficeSelector } from './use-car-rental-office-selector'
import { Filter, Header } from './car-rental-mobile-office-selector-styled'
import { CarRentalMobileContent } from './car-rental-mobile-content'

export const CarRentalOfficeMobileSelector = observer(function CarRentalOfficeMobileSelector({
  searchPoint,
  locations,
  shouldUseKm,
  isDropoff,
  searchValue,
  onLocationClick,
  onSearchChange,
}: Omit<LayoutProps, 'confirmation'>) {
  const { t } = useTranslation()
  const { carRentalLocationActions, carRentalStore } = useCarRentalSearchContext()

  const { headerTitle, subTitle, inputPlaceholder } = useCarRentalOfficeSelector({
    isDropoff,
    searchPoint,
  })

  // :TODO change to correct header layout
  return (
    <>
      <Header>
        <MobileHeader
          layout="caption"
          leftSlot={<MobileHeader.BackButton onClick={carRentalLocationActions.onBack} />}
          withBorderBottom>
          <MobileHeader.Title
            layout="caption"
            align="left"
            title={headerTitle}
            subTitle={subTitle}
            aria-label={`${headerTitle} ${t('Accessibility.For')} ${subTitle}`}
          />
        </MobileHeader>
        <Filter>
          <TextField
            type="search"
            size="small"
            placeholder={inputPlaceholder}
            onChange={onSearchChange}
            value={searchValue || ''}
            aria-label={`${t('Accessibility.SearchBar')}. ${t(
              'Accessibility.TapTo',
            )} ${inputPlaceholder}`}
          />
        </Filter>
      </Header>
      <CarRentalMobileContent
        isLoading={carRentalStore.isLocationsLoading}
        locations={locations}
        shouldUseKm={shouldUseKm}
        onLocationClick={onLocationClick}
        isRestrictedCountryError={carRentalStore.isRestrictedCountryError}
        restrictedErrorMessage={carRentalStore.restrictedErrorMessage || ''}
      />
    </>
  )
})
