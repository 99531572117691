import type { FlightFragment, FlightLegSegment } from '@fiji/graphql/types'
import type { IconNames } from '@etta/ui/icon'

type Props = {
  segment?: FlightFragment | FlightLegSegment | null
}

export function useFlightCostLabelAndName({ segment }: Props) {
  if (!segment) {
    return
  }

  const { isSameCarrierName, isRoundTripLeg, isRoundTripCost } = segment

  const cost = (segment as FlightFragment).rate || (segment as FlightLegSegment).baseRate

  const iconName: IconNames =
    isSameCarrierName && isRoundTripLeg && isRoundTripCost ? 'sameDayArrowsPWA' : 'flightArrowPWA'
  const originAddress = segment.segments?.[0].departure?.airportCode
  const destinationAddress = segment.segments?.[segment.segments.length - 1].arrival?.airportCode

  return {
    cost,
    iconName,
    originAddress,
    destinationAddress,
  }
}
