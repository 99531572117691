import styled from 'styled-components'
import { headline } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  &:first-child {
    margin-top: 16px;
  }
`

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.inputBorderColor};
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

export const PriceRow = styled.div<{ isSmallMobile?: boolean; isSpaceBetween?: boolean }>`
  display: flex;
  ${(p) => (p.isSpaceBetween ? 'justify-content: space-between;' : 'justify-content: flex-end;')}
  width: 100%;
`

export const RailSearchSegmentRates = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const RailSearchSegmentStandartRate = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  ${headline};
  line-height: 22px;
`
export const RailSearchSegmentFirstClassRate = styled.div`
  display: flex;
  align-items: center;
`

export const RailSearchRateLabelContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`

export const IconWrapper = styled(Icon)``
