import styled, { css } from 'styled-components'

export const Container = styled.div<{ isLoading?: boolean }>`
  background-color: ${(p) => p.theme.colors.white};
  ${({ isLoading }) =>
    !isLoading &&
    css`
      > * {
        margin-top: 16px;
      }
    `};
`
