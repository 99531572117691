import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

type Props = {
  type: string
  isCancellationTripWithRideHailError?: boolean
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'

export function useSuccessDialog({ type, isCancellationTripWithRideHailError }: Props) {
  const { t } = useTranslation()

  const title = `${type} ${t(`${i18nBase}.Canceled`)}`

  const button = useMemo(() => {
    return isCancellationTripWithRideHailError ? t(`${i18nBase}.Ok`) : t(`${i18nBase}.Done`)
  }, [isCancellationTripWithRideHailError, t])

  const description = useMemo(() => {
    return type.toLowerCase() === 'ride'
      ? t(`${i18nBase}.RideHailSuccess`)
      : isCancellationTripWithRideHailError
      ? t(`${i18nBase}.TripWithRideHailErrorDescription`)
      : t(`${i18nBase}.Success`, { type: type.toLowerCase() })
  }, [isCancellationTripWithRideHailError, type, t])

  return {
    title,
    description,
    button,
  }
}
