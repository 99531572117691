import type { ColorPalette, PreferenceContent } from '@fiji/types'
import type { Notification } from '@fiji/graphql/types'
import type { IconNames } from '@etta/ui/icon'
import type { AvailablePreferencesToSelect } from '../types'
import { doesLoyaltyProgramExist } from './does-loyalty-program-exist'

type Props = {
  preference?: PreferenceContent
  notification?: Notification[]
  availability?: AvailablePreferencesToSelect | null
}

export function getPreferenceBoxData({ preference, notification, availability }: Props) {
  const loyaltyStatus =
    availability?.isMembership && doesLoyaltyProgramExist(preference?.loyaltyProgram)
      ? {
          name: 'loyaltyFilledPWA' as IconNames,
          color: 'mainText1' as keyof ColorPalette,
        }
      : {
          name: 'loyaltyPWA' as IconNames,
          color: 'bodyText1' as keyof ColorPalette,
        }

  const specialRequestStatus =
    availability?.isSpecialRequest && preference?.specialRequest?.length
      ? {
          name: 'specialRequestFilledPWA' as IconNames,
          color: 'mainText1' as keyof ColorPalette,
        }
      : {
          name: 'specialRequestPWA' as IconNames,
          color: 'bodyText1' as keyof ColorPalette,
        }

  const notificationStatus = notification
    ? {
        name: 'notificationFilledPWA' as IconNames,
        color: 'mainText1' as keyof ColorPalette,
      }
    : {
        name: 'notificationPWA' as IconNames,
        color: 'bodyText1' as keyof ColorPalette,
      }

  const mealStatus =
    availability?.isMeal && preference?.meal
      ? {
          name: 'mealFilledPWA' as IconNames,
          color: 'mainText1' as keyof ColorPalette,
        }
      : {
          name: 'mealPWA' as IconNames,
          color: 'bodyText1' as keyof ColorPalette,
        }

  const eTicketStatus = preference?.isETicket
    ? {
        name: 'eticketPWA' as IconNames,
        color: 'mainText1' as keyof ColorPalette,
      }
    : {
        name: 'kioskPWA' as IconNames,
        color: 'mainText1' as keyof ColorPalette,
      }

  const railCardStatus = preference?.railCard
    ? {
        name: 'loyaltyCardPWA' as IconNames,
        color: 'mainText1' as keyof ColorPalette,
      }
    : {
        name: 'noRailCardPWA' as IconNames,
        color: 'mainText1' as keyof ColorPalette,
      }

  const areAllPreferencesDisabled =
    !availability?.isMeal &&
    !availability?.isMembership &&
    !availability?.isSpecialRequest &&
    !availability?.isRailPreferences

  return {
    loyaltyStatus,
    specialRequestStatus,
    notificationStatus,
    mealStatus,
    areAllPreferencesDisabled,
    eTicketStatus,
    railCardStatus,
  }
}
