import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'

@Store()
export class AddSegmentModalsStore {
  private airSearchFormModal = new Toggle()
  private hotelSearchFormModal = new Toggle()
  private carSearchFormModal = new Toggle()

  get isAirSearchModalOpen() {
    return this.airSearchFormModal.isOpen
  }

  openAirSearchModal() {
    this.airSearchFormModal.handleOpen()
  }

  closeAirSearchModal() {
    this.airSearchFormModal.handleClose()
  }

  get isHotelSearchModalOpen() {
    return this.hotelSearchFormModal.isOpen
  }

  openHotelSearchModal() {
    this.hotelSearchFormModal.handleOpen()
  }

  closeHotelSearchModal() {
    this.hotelSearchFormModal.handleClose()
  }

  get isCarSearchModalOpen() {
    return this.carSearchFormModal.isOpen
  }

  openCarSearchModal() {
    this.carSearchFormModal.handleOpen()
  }

  closeCarSearchModal() {
    this.carSearchFormModal.handleClose()
  }
}
