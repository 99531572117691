import { Service, Inject } from '@etta/di'
import { FlightFareRulesService } from '../services/flight-fare-rules.service'

@Service()
export class FlightFareRulesActions {
  constructor(@Inject() private flightFareRulesService: FlightFareRulesService) {}

  getFlightFareRulesByTransactionId(flightSegmentId: string, transactionId: string) {
    this.flightFareRulesService.getFlightFareRulesByTransactionId(flightSegmentId, transactionId)
  }
}
