import styled from 'styled-components'
import { HORIZONTAL_PADDING } from '../../constants'

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0 ${HORIZONTAL_PADDING}px;
  margin-top: 58px;
`
