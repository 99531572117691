import { ScreenType } from '../screen-mode/types'
import { screenMatcher } from '../screen-mode/screen-matcher'
import { launchMode, LaunchMode } from './launch-mode'

const isEttaWeb = launchMode === LaunchMode.ETTA_WEB
const isEttaStandalone = launchMode === LaunchMode.ETTA_STANDALONE
const isEttaAll = isEttaStandalone || isEttaWeb
const isFiji = launchMode === LaunchMode.FIJI

export const appMode = {
  get screenType() {
    return screenMatcher.getScreenType()
  },
  // ETTA_WEB
  get isEttaWebDesktop() {
    return isEttaWeb && this.screenType === ScreenType.Desktop
  },
  get isEttaWebTablet() {
    return isEttaWeb && this.screenType === ScreenType.Tablet
  },
  get isEttaWebMobile() {
    return isEttaWeb && this.screenType === ScreenType.Mobile
  },
  // ETTA_STANDALONE
  get isEttaStandaloneDesktop() {
    return isEttaStandalone && this.screenType === ScreenType.Desktop
  },
  get isEttaStandaloneTablet() {
    return isEttaStandalone && this.screenType === ScreenType.Tablet
  },
  get isEttaStandaloneMobile() {
    return isEttaStandalone && this.screenType === ScreenType.Mobile
  },
  // FIJI
  get isFijiMobile() {
    return isFiji && this.screenType === ScreenType.Mobile
  },
  get isFijiDesktop() {
    return isFiji && this.screenType !== ScreenType.Mobile
  },
  get isMobility() {
    return document.location.pathname.includes('mobility')
  },
  get isAppleTravel() {
    return navigator.userAgent.includes('kan-ios')
  },
  get isOpenInIOS() {
    return navigator.userAgent.includes('etta-ios') || this.isAppleTravel
  },
  get isAndroid() {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('android')
  },
  // Additional types
  isEttaAll,
  isEttaWebAll: isEttaWeb,
  isEttaStandaloneAll: isEttaStandalone,
  isFijiAll: isFiji,
  get isEttaDesktop() {
    return isEttaAll && this.screenType === ScreenType.Desktop
  },
  get isEttaTablet() {
    return isEttaAll && this.screenType === ScreenType.Tablet
  },
  get isEttaMobile() {
    return isEttaAll && this.screenType === ScreenType.Mobile
  },
}
