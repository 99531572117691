import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { SelectSearchMobile } from './mobile'
import { SelectSearchDesktop } from './desktop'

export function SelectSearchLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SelectSearchMobile {...props} />}
      desktopSlot={<SelectSearchDesktop {...props} />}
    />
  )
}
