import { useTranslation } from 'react-i18next'
import React from 'react'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import type { RailCardGroupProps } from '../../types'
import { RailCardCheckedItem } from './rail-card-checked-item'
import { RailCardItem } from './rail-card-item'
import { GroupTitleBlock } from './rail-card-group-styled'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

export function RailCardGroup({
  name,
  cards,
  handleCardClick,
  isSavedCards,
  isCheckBoxEnabled,
  checkSavedCard,
}: RailCardGroupProps) {
  const { t } = useTranslation()

  return (
    <>
      <GroupTitleBlock
        isSavedCards={isSavedCards}
        aria-label={isSavedCards ? t(i18Base + '.SavedCards') : name}>
        <Text variant="captionStrongCaps">{isSavedCards ? t(i18Base + '.SavedCards') : name}</Text>
      </GroupTitleBlock>
      {cards.map((card, index) => (
        <React.Fragment key={index}>
          {isCheckBoxEnabled && isSavedCards ? (
            <RailCardCheckedItem
              card={card}
              handleCardClick={handleCardClick}
              checkSavedCard={checkSavedCard}
            />
          ) : (
            <RailCardItem card={card} handleCardClick={handleCardClick} />
          )}

          <Block paddingLeft={20}>
            <Separator />
          </Block>
        </React.Fragment>
      ))}
    </>
  )
}
