import { useTranslation } from 'react-i18next'
import { BlockContinueLayout } from './layout'

type Props = {
  title: string
  subTitle?: string
  onContinue: () => void
  isLoading: boolean
  finishAllSeatsSelection?: () => void
}

export function BlockContinue({
  title,
  subTitle,
  onContinue,
  isLoading,
  finishAllSeatsSelection,
}: Props) {
  const { t } = useTranslation()
  const buttonTitle = t('AirSeatMap.Continue')

  return (
    <BlockContinueLayout
      finishAllSeatsSelection={finishAllSeatsSelection}
      title={title}
      subTitle={subTitle}
      buttonTitle={buttonTitle}
      onContinue={onContinue}
      isLoading={isLoading}
    />
  )
}
