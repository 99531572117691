import { rgba } from 'polished'
import type { ColorPalette, Theme } from '@fiji/types'
import { tripReviewPageGreyColor } from '@fiji/style'

const colors: ColorPalette = {
  primary: '#FE7035',
  primary1: '#FF7E47',
  primary2: '#F59A74',
  primary3: '#FC4C02',
  secondary: '#292929',
  mainText: '#FFFFFF',
  mainText1: '#A6A9AC',
  mainText2: '#ffffff',
  bodyText: '#E0E2E5',
  bodyText1: '#E0E2E5',
  bodyText2: '#D9DBDF',
  borderDark: '#373A3D',
  borderLight: '#242729',
  background: '#000000',
  background1: '#101820',
  background2: '#D9DBDF',
  background3: '#2B2D2E',
  background4: '#232527',
  white: '#14191F',
  error: '#B80E19',
  error1: '#fdf5f6',
  warning: '#E3A921',
  success: '#4BC213',
  segmentBus: '#008A7D',
  duskBlue: '#1E316E',
  glacierBlue: '#7DAFD4',
  plum: '#4B214D',
  seatMap: {
    white: '#fff',
    charcoal1: '#414B54',
    charcoal2: '#5D646C',
    charcoal3: '#767B80',
    charcoal4: '#9FA5AC',
    charcoal5: '#ACB9C3',
    charcoal6: '#BCC1C7',
    charcoal61: '#BAC9D4',
    charcoal7: '#C8D4DD',
    charcoal8: '#D1D5DA',
    charcoal9: '#DADEE2',
    charcoal10: '#E6EAF0',
    charcoal11: '#EFEFF4',
    charcoal12: '#F2F5F8',
    tangerine1: '#C13A01',
    tangerine2: '#D54101',
    tangerine3: '#E54501',
    tangerine4: '#FC4C02',
    gradiant1: '#E6EAF0',
    gradiant2: '#BFC4CA',
    red1: '#FF3B30',
    dark1: '#373737',
    dark2: '#868686',
    dark3: '#EEEEEE',
  },
  transparent: 'transparent',
  ecoCheck: '#3DA00F',
  ecoCheck1: '#ECF5E7',
  ecoCheck2: '#7AA9CC',
  thulianPink: '#E564A7',
}

export const ettaDark: Theme = {
  name: 'etta-dark',
  primaryColor: colors.mainText,
  secondaryColor: colors.mainText1,
  dateRangeColor: rgba(colors.primary, 0.14),
  dateRangeHoverColor: colors.primary2,
  dateRangeSelectedColor: colors.primary,
  btnBackground: colors.primary,
  btnBackgroundHover: colors.primary1,
  inputLabelColor: colors.bodyText,
  inputColor: colors.mainText,
  inputBorderColor: colors.background,
  inputBackgroundColor: colors.background,
  helperText: tripReviewPageGreyColor,
  colors,
}
