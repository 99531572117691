import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { Container } from './car-rental-safety-check-empty-styled'

const i18n = 'CarRentalDetails.SafetyCheck.Empty'

export function CarRentalSafetyCheckEmpty() {
  const { t } = useTranslation()

  return (
    <Container>
      <Block marginBottom={2}>
        <Icon name="carRentalPWA" size="medium" color="plum" />
      </Block>
      <Block marginBottom={4}>
        <SafetyCheckTitle size="extra-small" />
      </Block>
      <Text variant="subHeadMedium" color="bodyText">
        {t(`${i18n}.Description`)}
      </Text>
    </Container>
  )
}
