import type { Geocode } from '@fiji/graphql/types'

export function getGeocodes(geocode?: Geocode | null) {
  if (geocode) {
    return {
      lat: geocode?.lat,
      lng: geocode?.long,
    }
  }
  return null
}
