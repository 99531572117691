import { cloneElement } from 'react'
import type { LayoutProps } from '../types'
import { Layer, Overlay, Container, Content, OverlayLayoutBlock } from './modal-desktop-styled'

export function ModalDesktop({
  position,
  onCloseOverlay,
  withOverlay,
  withShadow,
  children,
  animationSlot,
  containerSlot,
  horizontalDimension,
  borderRadius,
  withMaxHeight,
  disableOverflow = true,
}: LayoutProps) {
  return (
    <Layer>
      {withOverlay && <Overlay />}

      {cloneElement(animationSlot, {
        children: cloneElement(containerSlot, {
          children: (
            <Container position={position}>
              {withOverlay && <OverlayLayoutBlock onClick={onCloseOverlay} />}
              <Content
                disableOverflow={disableOverflow}
                position={position}
                horizontalDimension={horizontalDimension}
                borderRadius={borderRadius}
                withMaxHeight={withMaxHeight}
                withShadow={withShadow}>
                {children}
              </Content>
            </Container>
          ),
        }),
      })}
    </Layer>
  )
}
