import type { FocusedInputShape } from 'react-dates'
import type { Dates } from './types'

const FOCUSED_START_DATE = 'startDate'

export function getResults(dates: Dates, isRange: boolean, focusedInput: FocusedInputShape): Dates {
  if (!isRange || focusedInput === FOCUSED_START_DATE) {
    return {
      date: dates.date,
    }
  }

  return dates
}
