import styled, { css } from 'styled-components'
import { Input } from '../base-text-field/base-text-field-styles'
import { BaseTextField } from '../base-text-field'

export const PasswordTextField = styled(BaseTextField)<{
  isValueVisible?: boolean
}>`
  ${Input} {
    ${(props) =>
      !props.isValueVisible &&
      css`
        letter-spacing: 0.3rem;
      `}
  }
`
