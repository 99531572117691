import type { TextFieldHelperType, TextFieldValue, TextFieldSize } from '@etta/ui/text-field/types'
import type { FieldViewType } from './types'
import { Select } from './fields/select'
import { Text } from './fields/text'
import { Search } from './fields/search'

type Props = {
  onClear?: () => void
  onClick: () => void
  fieldType?: FieldViewType
  size?: TextFieldSize
  label?: string
  placeholder?: string
  value: TextFieldValue
  isDisabled?: boolean
  helperText?: string
  helperType?: TextFieldHelperType
  ariaLabel?: string
  'data-tracking-id'?: string
}

export function FieldView({ fieldType, ...props }: Props) {
  switch (fieldType) {
    default:
    case 'select':
      return <Select {...props} />
    case 'text':
      return <Text {...props} />
    case 'search':
      return <Search {...props} />
  }
}
