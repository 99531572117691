import type { DefaultTheme } from 'styled-components'
import type { ColorPalette } from '@fiji/types'

export function getBackgroundColor(
  theme: DefaultTheme,
  bgColor?: 'transparent' | keyof ColorPalette,
): string {
  if (!bgColor || bgColor === 'seatMap') {
    return theme.colors.white
  }
  if (bgColor === 'transparent') {
    return 'transparent'
  }

  return theme.colors[bgColor] || theme.colors.white
}
