import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { CostWithCostWithCtaButtonsLayout } from '../cost-with-cta-buttons-layout'
import { Container, Main, LeftBlock, RightBlock, Extras } from './cost-with-cta-desktop-styled'

export function CostWithCtaDesktop({
  rate,
  label,
  usedCost,
  buttonText,
  extraText,
  onSubmit,
  isDisabled,
  isCostDisplayed,
  withAllignButtonToBottom,
  isHoldButtonVisible,
  holdButtonText,
  isHoldFlow,
  onHoldTrip,
  'data-tracking-id': dataTrackingId,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CostWithCTA'
  return (
    <Container>
      {extraText && <Extras>{extraText}</Extras>}
      <Main aria-label={[label, usedCost].join(', ')}>
        {rate && isCostDisplayed && (
          <LeftBlock>
            <Text data-tracking-id="total-estimated-cost-label" variant="subHeadMedium">
              {label}
            </Text>
            <Text data-tracking-id="total-estimated-cost-text" variant="title1">
              {usedCost}
            </Text>
          </LeftBlock>
        )}
        <RightBlock withAlligContentToBottom={withAllignButtonToBottom}>
          <CostWithCostWithCtaButtonsLayout
            isHoldButtonVisible={isHoldButtonVisible}
            isHoldFlow={isHoldFlow}
            buttonSlot={
              <Button
                variant="solid"
                size="normal-wide"
                aria-label={t(i18Base + '.ContinueAria', { buttonText })}
                minWidth={14}
                onClick={onSubmit}
                disabled={isDisabled}
                data-tracking-id={dataTrackingId}>
                {buttonText}
              </Button>
            }
            holdButtonSlot={
              <Button
                variant={isHoldFlow ? 'solid' : 'outline'}
                minWidth={14}
                size="normal-wide"
                onClick={onHoldTrip}
                disabled={isDisabled}>
                {holdButtonText}
              </Button>
            }
          />
        </RightBlock>
      </Main>
    </Container>
  )
}
