import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const HeaderTitle = styled(Text)`
  margin-bottom: 8px;
`

export const OverallRating = styled(Text)`
  margin-bottom: 8px;
`

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const RatingValue = styled(Text)`
  margin-right: 4px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
