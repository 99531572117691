import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ShortlistHotel } from '@fiji/types'
import type { Shortlist } from './types'

const initialState: Shortlist = {
  hotels: [],
  isVisible: false,
}

const slice = createSlice({
  name: 'shortlist',
  initialState,
  reducers: {
    updateShortlistVisibility(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload !== undefined ? action.payload : !state.isVisible
    },

    addHotelToShortlist(state, action: PayloadAction<ShortlistHotel>) {
      state.hotels = state.hotels.concat(action.payload)
    },

    removeHotelFromShortlistById(state, action: PayloadAction<string>) {
      state.hotels = state.hotels.filter((hotel) => hotel.id !== action.payload)
    },

    clearShortlist(state) {
      state.hotels = []
    },
  },
})

export const shortlistReducer = slice.reducer
export const {
  updateShortlistVisibility,
  addHotelToShortlist,
  removeHotelFromShortlistById,
  clearShortlist,
} = slice.actions
