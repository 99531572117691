import { useCallback } from 'react'
import type {
  GroupedSegmentValueObject,
  TripDetailsAirTicketValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { SegmentType } from '@etta/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { FlightSegment } from '../flight-segment'
import { TrainSegment } from '../train-segment'
import { CarServiceSegment } from '../car-service-segment'
import { CarRentalSegment } from '../car-rental-segment'
import { HotelSegment } from '../hotel-segment'
import { RideHailSegment } from '../ride-hail-segment'
import { useCancelSegment } from './use-cancel-segment'

type Props = {
  isUpcoming: boolean
  isOnHold: boolean
  segment: GroupedSegmentValueObject
  itineraryId?: string
  bookingId?: string
  isTripInProgress?: boolean
  onSeatMapOpen?: (segmentLegId?: string | null) => void
  flightId?: string | null
  airTicket?: TripDetailsAirTicketValueObject
}

export function Segment({
  isUpcoming,
  isOnHold,
  segment,
  itineraryId,
  bookingId,
  isTripInProgress,
  onSeatMapOpen,
  flightId,
  airTicket,
}: Props) {
  const currentDate = new Date()
  const { postBookingTripDetailsActions, postBookingTripStore } = usePostBookingContext()

  const handleSegmentClick = useCallback(() => {
    postBookingTripDetailsActions.handleOpenSelectedDetails(segment)
  }, [postBookingTripDetailsActions, segment])

  const { onCancel } = useCancelSegment({
    segment,
  })

  switch (segment.type) {
    case SegmentType.Flight: {
      return (
        <FlightSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          currentDate={currentDate}
          onClick={handleSegmentClick}
          onCancel={onCancel}
          onSeatMapOpen={onSeatMapOpen}
          itineraryId={itineraryId}
          bookingId={bookingId}
          flightId={flightId}
          isTripInProgress={isTripInProgress}
          isNDCTrip={postBookingTripStore.trip.isNDCTrip}
          airTicket={airTicket}
        />
      )
    }
    case SegmentType.Train: {
      return (
        <TrainSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          currentDate={currentDate}
          onClick={handleSegmentClick}
          onCancel={onCancel}
          isTripInProgress={isTripInProgress}
        />
      )
    }
    case SegmentType.CarService: {
      return (
        <CarServiceSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          currentDate={currentDate}
          onClick={handleSegmentClick}
        />
      )
    }
    case SegmentType.CarRental: {
      return (
        <CarRentalSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          currentDate={currentDate}
          isTripInProgress={isTripInProgress}
          onClick={handleSegmentClick}
          onCancel={onCancel}
        />
      )
    }
    case SegmentType.Hotel: {
      return (
        <HotelSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          itineraryId={itineraryId}
          bookingId={bookingId}
          isTripInProgress={isTripInProgress}
          onClick={handleSegmentClick}
          onCancel={onCancel}
        />
      )
    }
    case SegmentType.RideHail: {
      return (
        <RideHailSegment
          segment={segment}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          currentDate={currentDate}
          onClick={handleSegmentClick}
          onCancel={onCancel}
        />
      )
    }
    default: {
      return null
    }
  }
}
