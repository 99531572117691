import styled, { css } from 'styled-components'
import { largeTitle } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const HeaderIconBox = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  border: none;
  background: none;

  & > div {
    cursor: pointer;
  }
`

export const HeaderTitle = styled.div`
  margin-top: 12px;
  ${largeTitle};
  color: ${(props) => props.theme.colors.mainText};
`

const containerBorder = css`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
`

export const Container = styled.div<{ withBorder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ withBorder }) => withBorder && containerBorder}
`

export const HeaderTripName = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 23px;
  margin-bottom: 24px;
`

export const HeldTripIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.plum};
  border-radius: 50px;
`
