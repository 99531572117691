import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { TripStatus } from '@fiji/graphql/types'
import { Paper } from '../trip-ride-hail-styled'
import { Row, TravelerCell, CellName, CellValue, StatusCell } from './traveler-styled'
import { useTraveler } from './use-traveler'

type Props = {
  tripStatus?: TripStatus | null
}

const i18nBase = 'PostBooking.RideHailDetails.TravelerInfo'

export const Traveler = observer(function Traveler({ tripStatus }: Props) {
  const { t } = useTranslation()
  const { tripStatusText, isTripConfirm, isTripComplete, travelerFullName } = useTraveler({
    tripStatus,
  })

  return (
    <Paper>
      <Block paddingVertical={16} paddingHorizontal={16}>
        <Row>
          <TravelerCell>
            <CellName data-tracking-id="traveler-text">{t(`${i18nBase}.Traveler`)}</CellName>
            <CellValue aria-label={'travelerName'} data-tracking-id="traveler-name-text">
              <Text variant="subHeadStrong" color="mainText" textOverflow="ellipsis-two-line">
                {travelerFullName}
              </Text>
            </CellValue>
          </TravelerCell>
          {!isTripConfirm && !isTripComplete && (
            <StatusCell>
              <CellName data-tracking-id="tripStatus-text">{t(`${i18nBase}.TripStatus`)}</CellName>
              <CellValue aria-label={'tripStatusName'} data-tracking-id="trip-status-name-text">
                <Text variant="subHeadStrong" color="mainText">
                  {tripStatusText}
                </Text>
              </CellValue>
            </StatusCell>
          )}
        </Row>
      </Block>
    </Paper>
  )
})
