import { useState } from 'react'
import type { GazooMembership, LoyaltyProgram } from '@fiji/graphql/types'

export function useLoyaltyProgram(value: LoyaltyProgram[]) {
  const [loyaltyProgramValues, setLoyaltyProgramValues] = useState(value)
  const onChangeLoyaltyProgram = (programs: LoyaltyProgram[]) => {
    setLoyaltyProgramValues(programs)
  }

  const getLoyaltyWithNumbers = () => {
    const membership: GazooMembership[] = loyaltyProgramValues
      .filter((program) => !!program.number)
      .map(({ vendorCode, number }) => {
        return {
          vendorCode,
          number: number || '',
        }
      })

    return membership
  }

  return {
    loyaltyProgramValues,
    getLoyaltyWithNumbers,
    onChangeLoyaltyProgram,
  }
}
