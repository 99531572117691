import { useState } from 'react'
import { CarouselImagePlaceholder } from '../carousel-image-placeholder'
import { Image } from './carousel-image-styled'

type Props = {
  src: string
}

export function CarouselImage({ src }: Props) {
  const [hasError, setError] = useState(false)

  if (hasError) {
    return <CarouselImagePlaceholder />
  }

  return <Image src={src} onError={() => setError(true)} alt="" />
}
