import {
  PlaceContainer,
  TypeIndicator,
  Description,
  LoadingImage,
  LoadingName,
  LoadingLogos,
  LogoContainer,
} from './desktop-place-styled'

export function DesktopLoadingPlace() {
  return (
    <PlaceContainer isClickable={false}>
      <TypeIndicator>
        <LoadingImage />
      </TypeIndicator>
      <Description>
        <LoadingName />
        <LogoContainer>
          <LoadingLogos />
        </LogoContainer>
      </Description>
    </PlaceContainer>
  )
}
