import type { IconSizes, IconColorPalette } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { BadgeText, Container } from './badge-styled'
import type { BadgeVariant } from './types'
import { getIconProps } from './get-icon-props'
import { getTextProps } from './get-text-props'
import { getTitle } from './get-title'

type Props = {
  variant: BadgeVariant
  color?: IconColorPalette
  iconSize?: IconSizes
  label?: string
  className?: string
}

export function Badge({ color, iconSize = 24, label = '', variant, className }: Props) {
  const title = getTitle(variant)
  const ariaLabel = `${title} ${label}`.trim()

  const iconProps = getIconProps(variant, color)
  const textProps = getTextProps(variant, color)

  if (!iconProps) {
    return null
  }
  return (
    <Container aria-label={ariaLabel} className={className}>
      <Icon name={iconProps.name} color={iconProps.color} size={iconSize} />
      <BadgeText variant={textProps.variant} color={textProps.color}>
        {label || title}
      </BadgeText>
    </Container>
  )
}
