import { useLayoutEffect, useRef } from 'react'
import { DescendantsManager } from './descendants-manager'

export function useDescendants<
  T extends HTMLElement = HTMLElement,
  K extends Record<string, any> = {}
>() {
  const descendants = useRef(new DescendantsManager<T, K>())
  useLayoutEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => descendants.current.destroy()
  })
  return descendants.current
}
