import styled from 'styled-components'
import { subHeadStrong, linkSmall, footnoteMedium } from '@fiji/style/typography'

export const Container = styled.article<{ isActive?: boolean }>`
  display: flex;
  cursor: pointer;

  width: 100%;
  min-height: 48px;
  padding: 12px 9px 12px 12px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};

  overflow: hidden;
  border: 2px solid ${(props) => (props.isActive ? props.theme.colors.primary : 'transparent')};
  max-height: ${(props) => (props.isActive ? '500px' : '48px')};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AirlineLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  object-fit: contain;
  margin-right: 8px;
`

export const AirportTripTitle = styled.p<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  ${subHeadStrong};
  color: ${(props) =>
    props.disabled ? props.theme.colors.bodyText1 : props.theme.colors.mainText};
`

export const SecondaryText = styled.p`
  margin: 0;
  ${linkSmall};
  line-height: 18px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const FlightInfoText = styled.p`
  margin: 0;
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
`

export const SelectSeatText = styled.span`
  margin: 0;
  ${linkSmall};
  color: ${(props) => props.theme.colors.primary};
`
