import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { ScoreList } from '../../score-list'
import type { LayoutProps } from '../types'
import { ConcernScoreListSkeleton } from './concern-score-list-desktop-skeleton'
import { Container, SkeletonContainer } from './concern-score-list-desktop-styled'

const i18nBase = `HotelSafetyCheck.ConcernScoresList`
export function ConcernScoreListDesktop({ concernList, isLoading }: LayoutProps) {
  const { t } = useTranslation()
  const title = t(`${i18nBase}.Title`)

  return (
    <Swap
      is={isLoading}
      isSlot={
        <SkeletonContainer>
          <ConcernScoreListSkeleton />
        </SkeletonContainer>
      }>
      <Container>
        <Text aria-label={title} variant="subHeadStrong" color="mainText">
          {title}
        </Text>
        {concernList && <ScoreList isOpen concernList={concernList} />}
      </Container>
    </Swap>
  )
}
