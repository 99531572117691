import type { Result, Maybe } from 'fnscript'
import { Error as NestedError, Err, Ok, Just, Nothing } from 'fnscript'
import { Inject, Service } from '@etta/di'
import { dateToIso, getFormattedTime, isBeforeDate, isSameDay } from '@fiji/utils/dates'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import type {
  SubmitNewFlightSearchParamsSegmentInput,
  SubmitNewSearchTimeRange,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import {
  SeatmapCabinClass,
  RecentSearchesFlightSearchParamsFlightType,
  RecentSearchesFlightSearchParamsCabinClass,
  SubmitNewSearchTimeRangeType,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import { TimeRangeDirection } from '@fiji/enums'
import type { TimeRange } from '@fiji/types'
import type { SubmitFlightSearchInput } from '../../core/value-objects/submit-flight-search-input.value-object'
import type { Subset } from './validators'
import { isCoordinate, isDate, isEmptyString } from './validators'

const US_FLIGHT_COUNTRY_CODES = ['us', 'ca']
const HOURS_PER_DAY = 24
const MINUTES_PER_HOUR = 60

const flightTypeMap = {
  [FlightType.MultiCity]: RecentSearchesFlightSearchParamsFlightType.MultiDestination,
  [FlightType.OneWay]: RecentSearchesFlightSearchParamsFlightType.OneWay,
  [FlightType.Round]: RecentSearchesFlightSearchParamsFlightType.RoundTrip,
}

const cabinClassMap = {
  [SeatmapCabinClass.Business]: RecentSearchesFlightSearchParamsCabinClass.Business,
  [SeatmapCabinClass.Coach]: RecentSearchesFlightSearchParamsCabinClass.Coach,
  [SeatmapCabinClass.First]: RecentSearchesFlightSearchParamsCabinClass.First,
  [SeatmapCabinClass.PremiumCoach]: RecentSearchesFlightSearchParamsCabinClass.Premium,
}

@Service()
export class FlightMapService {
  constructor(
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  getType(type: FlightType): RecentSearchesFlightSearchParamsFlightType {
    return flightTypeMap[type]
  }

  getFlights(
    input: SubmitFlightSearchInput,
  ): Result<SubmitNewFlightSearchParamsSegmentInput[], NestedError> {
    switch (input.type) {
      case FlightType.OneWay: {
        const flightSegmentResult = this.toFlightSegment(input.flights[0])

        if (flightSegmentResult.isErr()) {
          return Err(
            NestedError.new(
              `failed to validate submitNewFlightSearch flight segment;${flightSegmentResult
                .getError()
                .getMessage()}`,
            ),
          )
        }

        return Ok([flightSegmentResult.getValue()])
      }

      case FlightType.Round: {
        const roundTripFlightsResult = this.toRoundTripSegments(input.flights[0])
        if (roundTripFlightsResult.isErr()) {
          return Err(
            NestedError.new(
              `failed to validate submitNewFlightSearch round trip flight segments;${roundTripFlightsResult
                .getError()
                .getMessage()}`,
            ),
          )
        }
        return Ok(roundTripFlightsResult.getValue())
      }
      case FlightType.MultiCity: {
        const result: SubmitNewFlightSearchParamsSegmentInput[] = []
        const errors: NestedError[] = []
        const flightSegmentsResultList = input.flights.map((flight) => this.toFlightSegment(flight))

        const maybeFlightDatesValidationError = this.validateMultiCityFlightsDates(input.flights)

        if (maybeFlightDatesValidationError.isValue()) {
          errors.push(maybeFlightDatesValidationError.getValue())
        }

        flightSegmentsResultList.forEach((f) => {
          if (f.isErr()) {
            errors.push(f.getError())
            return
          }

          result.push(f.getValue())
        })

        if (errors.length > 0) {
          const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

          return Err(
            NestedError.new(
              `failed to validate multi destination flights segments;${validationError.getMessage()}`,
            ),
          )
        }

        return Ok(result)
      }
    }
  }

  toRoundTripSegments(
    flight: Flight,
  ): Result<SubmitNewFlightSearchParamsSegmentInput[], NestedError> {
    const result: SubmitNewFlightSearchParamsSegmentInput[] = []
    const errors: NestedError[] = []

    const maybeRoundFlightDatesValidationError = this.validateRoundFlightsDates(flight)

    if (maybeRoundFlightDatesValidationError.isValue()) {
      errors.push(maybeRoundFlightDatesValidationError.getValue())
    }

    const maybeDepartureFlightSegmentResult = this.toFlightSegment(flight)
    if (maybeDepartureFlightSegmentResult.isErr()) {
      const error = NestedError.new(
        `failed to validate round trip origin flight segment;${maybeDepartureFlightSegmentResult
          .getError()
          .getMessage()}`,
      )
      errors.push(error)
    }

    if (maybeDepartureFlightSegmentResult.isOk()) {
      result.push(maybeDepartureFlightSegmentResult.getValue())
    }

    const returnFlightSegmentArgs = {
      ...flight,
      departureDate: flight.returnDate,
      originPlace: flight.destinationPlace,
      destinationPlace: flight.originPlace,
      timeRange: flight.returnTimeRange,
    }

    const maybeReturnFlightSegmentResult = this.toFlightSegment(returnFlightSegmentArgs)

    if (maybeReturnFlightSegmentResult.isErr()) {
      const error = NestedError.new(
        `failed to validate round trip return flight segment;${maybeReturnFlightSegmentResult
          .getError()
          .getMessage()}`,
      )
      errors.push(error)
    }

    if (maybeReturnFlightSegmentResult.isOk()) {
      result.push(maybeReturnFlightSegmentResult.getValue())
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Err(
        NestedError.new(`failed to validate round trip flight segments`).wrap(validationError),
      )
    }

    return Ok(result)
  }

  private toFlightSegment(
    flight: Flight,
  ): Result<SubmitNewFlightSearchParamsSegmentInput, NestedError> {
    const isUSFlight =
      US_FLIGHT_COUNTRY_CODES.includes(flight.originPlace?.countryCode?.toLowerCase() || '') &&
      US_FLIGHT_COUNTRY_CODES.includes(flight.destinationPlace?.countryCode?.toLowerCase() || '')
    const maybeTimeRange = this.toTimeRange(flight.timeRange, isUSFlight)

    const searchSegment = {
      departureDate: flight.departureDate ? dateToIso(flight.departureDate) : flight.departureDate,
      origin: {
        name: flight.originPlace?.name,
        airportCode: flight.originPlace?.airportCode,
        countryCode: flight.originPlace?.countryCode,
        geocode:
          flight.originPlace?.latitude && flight.originPlace?.longitude
            ? {
                lat: flight.originPlace.latitude,
                long: flight.originPlace.longitude,
              }
            : undefined,
      },
      destination: {
        name: flight.destinationPlace?.name,
        airportCode: flight.destinationPlace?.airportCode,
        countryCode: flight.destinationPlace?.countryCode,
        geocode:
          flight.destinationPlace?.latitude && flight.destinationPlace?.longitude
            ? {
                lat: flight.destinationPlace.latitude,
                long: flight.destinationPlace.longitude,
              }
            : undefined,
      },
      searchTimeRange: maybeTimeRange.isValue() ? maybeTimeRange.getValue() : undefined,
      cabinClass: cabinClassMap[flight.cabinClass.id],
    }

    const maybeFlightValidationError = this.validateSubmitNewFlightSearchFlightSegmentArgs(flight)

    if (this.checkIsSearchSegmentFullyFilled(flight, searchSegment)) {
      return Ok(searchSegment)
    }

    return Err(
      NestedError.new(
        `failed to validate flight segment with id: ${
          flight.id
        };${maybeFlightValidationError.getValue()}`,
      ),
    )
  }

  private toTimeRange(timeRange: TimeRange, isUSFlight: boolean): Maybe<SubmitNewSearchTimeRange> {
    if (!timeRange.startTimeRange || !timeRange.endTimeRange) {
      return Nothing()
    }

    if (!timeRange.customTimeHours) {
      return Just({
        type: SubmitNewSearchTimeRangeType.Departure,
        start: timeRange.startTimeRange,
        end: timeRange.endTimeRange,
      })
    }

    return this.toCustomTimeRange(timeRange, isUSFlight)
  }

  private toCustomTimeRange(
    timeRange: TimeRange,
    isUSFlight: boolean,
  ): Maybe<SubmitNewSearchTimeRange> {
    const displayConfiguration = this.displayConfigurationStore.displayConfiguration
    const timeRangeBeforeMinutes = isUSFlight
      ? displayConfiguration.flightSearchTimeRange?.before
      : displayConfiguration.flightSearchTimeRangeNonUS?.before
    const timeRangeAfterMinutes = isUSFlight
      ? displayConfiguration.flightSearchTimeRange?.after
      : displayConfiguration.flightSearchTimeRangeNonUS?.after

    if (
      typeof timeRange.customTimeHours !== 'number' ||
      typeof timeRangeBeforeMinutes !== 'number' ||
      typeof timeRangeAfterMinutes !== 'number'
    ) {
      return Nothing()
    }

    const timeRangeBefore = timeRangeBeforeMinutes / MINUTES_PER_HOUR
    const timeRangeAfter = timeRangeAfterMinutes / MINUTES_PER_HOUR

    const beforeDateTime =
      timeRange.customTimeHours - timeRangeBefore < 0
        ? { hours: 0 }
        : { hours: timeRange.customTimeHours - timeRangeBefore }
    const afterDateTime =
      timeRange.customTimeHours + timeRangeAfter >= HOURS_PER_DAY
        ? { hours: HOURS_PER_DAY - 1, minutes: MINUTES_PER_HOUR - 1 }
        : { hours: timeRange.customTimeHours + timeRangeAfter }

    return Just({
      type: SubmitNewSearchTimeRangeType.Departure,
      start: getFormattedTime(beforeDateTime),
      end: getFormattedTime(afterDateTime),
      customHours: timeRange.customTimeHours,
    })
  }

  private checkIsSearchSegmentFullyFilled = (
    flight: Flight,
    searchSegment: Subset<SubmitNewFlightSearchParamsSegmentInput>,
  ): searchSegment is SubmitNewFlightSearchParamsSegmentInput => {
    if (this.validateSubmitNewFlightSearchFlightSegmentArgs(flight).isValue() || !searchSegment) {
      return false
    }
    return true
  }

  private validateRoundFlightsDates(flight: Flight): Maybe<NestedError> {
    const errors: NestedError[] = []

    const flightDepartureDate = flight.departureDate
    const flightReturnDate = flight.returnDate
    if (
      flightDepartureDate &&
      flightReturnDate &&
      !isBeforeDate(flightDepartureDate, flightReturnDate) &&
      !isSameDay(flightDepartureDate, flightReturnDate)
    ) {
      const error = NestedError.new(`flight.departureDate should be <= flight.returnDate`)
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(
          `failed to validate round trip flight dates;${validationError.getMessage()}`,
        ),
      )
    }

    return Nothing()
  }

  private validateMultiCityFlightsDates(flights: Flight[]): Maybe<NestedError> {
    const errors: NestedError[] = []

    flights.forEach((flight, i) => {
      if (i === 0) {
return
}

      const previousFlightDepartureDate = flights[i - 1].departureDate

      if (
        previousFlightDepartureDate &&
        flight.departureDate &&
        !isBeforeDate(previousFlightDepartureDate, flight.departureDate) &&
        !isSameDay(previousFlightDepartureDate, flight.departureDate)
      ) {
        const error = NestedError.new(
          `flight[${i - 1}].departureDate should be <=  flight[${i}].departureDate`,
        )
        errors.push(error)
      }
    })

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(`failed to validate flights dates;${validationError.getMessage()}`),
      )
    }

    return Nothing()
  }

  private validateSubmitNewFlightSearchFlightSegmentArgs(flight: Flight): Maybe<NestedError> {
    const errors: NestedError[] = []

    if (!isDate(flight.departureDate)) {
      const error = NestedError.new('departureDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (isEmptyString(flight.originPlace?.name)) {
      const error = NestedError.new('originPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (!flight.originPlace?.airportCode && !isCoordinate(flight.originPlace?.latitude)) {
      const error = NestedError.new('originPlace.latitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (!flight.originPlace?.airportCode && !isCoordinate(flight.originPlace?.longitude)) {
      const error = NestedError.new('originPlace.longitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(flight.destinationPlace?.name)) {
      const error = NestedError.new('destinationPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (!flight.destinationPlace?.airportCode && !isCoordinate(flight.destinationPlace?.latitude)) {
      const error = NestedError.new('destinationPlace.latitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (
      !flight.destinationPlace?.airportCode &&
      !isCoordinate(flight.destinationPlace?.longitude)
    ) {
      const error = NestedError.new('destinationPlace.longitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(flight.timeRange?.startTimeRange)) {
      const error = NestedError.new('timeRange.startTimeRange is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(flight.timeRange?.endTimeRange)) {
      const error = NestedError.new('timeRange.endTimeRange is missing or it has a wrong value')
      errors.push(error)
    }

    if (
      flight.timeRange?.timeRangeBy !== TimeRangeDirection.Arrival &&
      flight.timeRange?.timeRangeBy !== TimeRangeDirection.Departure
    ) {
      const error = NestedError.new('timeRange.timeRangeBy is missing or it has a wrong value')
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(`failed to validate flight segment args;${validationError.getMessage()}`),
      )
    }

    return Nothing()
  }
}
