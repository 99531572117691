import { observer } from 'mobx-react-lite'
import { CarRentalFiltersType } from '@fiji/enums'
import { ListTypes } from '@etta/ui/drop-down-list'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import type { FilterProps } from '../types'
import { CarRentalOfficeDropdown } from '../car-rental-office-dropdown'
import { FilterContainer } from '../filter-container'
import { useFuelFilter } from './use-fuel-filter'
import { FuelFilterWrapper } from './fuel-filter-styled'
import { FuelOptions } from './fuel-options'

export const FuelFilter = observer(function FuelFilter({
  isDropdownFilter = true,
  withBottomBorder = true,
}: FilterProps) {
  const {
    isCarRentalsNewFiltersEnabled,
    label,
    handleChangeFuelType,
    fuelType,
    carFiltersStore,
    carRentalFilterActions,
    fuelTypeValue,
    carRentalSearchStore,
    isClearDisabled,
  } = useFuelFilter()

  if (!isCarRentalsNewFiltersEnabled) {
    return (
      <FuelFilterWrapper>
        <CarRentalOfficeDropdown
          label={label}
          data-tracking-id="fuel-filter-dropdown"
          onChange={handleChangeFuelType}
          options={fuelType.options}
          filters={fuelType.options}
          defaultLabel="All"
          type={ListTypes.Select}
          filtersType={CarRentalFiltersType.Fuel}
          value={fuelType.selectedOptions}
        />
      </FuelFilterWrapper>
    )
  }

  if (isDropdownFilter) {
    return (
      <FuelFilterWrapper>
        <QuickFilterLayout
          data-tracking-id="fuel-filter-dropdown"
          isOpen={carFiltersStore.fuelModal.isOpen}
          onOpen={carFiltersStore.fuelModal.handleOpen}
          onClose={carFiltersStore.fuelModal.handleClose}
          onClear={carRentalFilterActions.clearHandlers.clearFuelType}
          onApply={carRentalFilterActions.handleApplyFuelFilters}
          label={label}
          value={fuelTypeValue}
          isShimmerLoading={carRentalSearchStore.isLoading && !carRentalSearchStore.carRentals}
          isLoading={carRentalSearchStore.isLoading}
          selectedNumber={carFiltersStore.selectedFilters.fuelType.length}
          isApplyButtonDisabled={carFiltersStore.isEqual}
          isClearButtonDisabled={isClearDisabled}>
          <FuelOptions />
        </QuickFilterLayout>
      </FuelFilterWrapper>
    )
  }

  return (
    <FilterContainer
      title={label}
      selectedNumber={carFiltersStore.selectedFilters.fuelType.length}
      isClearButtonDisabled={isClearDisabled}
      onClear={carRentalFilterActions.clearHandlers.clearFuelType}
      withBottomBorder={withBottomBorder}>
      <FuelOptions withBottomBorder={false} optionsPaddingVertical={11} />
    </FilterContainer>
  )
})
