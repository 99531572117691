import { ImageContainer } from '@etta/components/image-container'
import { FirstLogo, SecondLogo, Container } from './rail-details-header-styles'

type Props = {
  railLogo: string
  squareRailLogos?: string[]
}

export function RailDetailsHeaderLogos({ railLogo, squareRailLogos }: Props) {
  const uniqueLogos = [...new Set(squareRailLogos)]

  if (uniqueLogos && uniqueLogos.length > 1) {
    return (
      <Container>
        <FirstLogo>
          <ImageContainer url={uniqueLogos?.[0]} />
        </FirstLogo>
        <SecondLogo>
          <ImageContainer url={uniqueLogos?.[1]} />
        </SecondLogo>
      </Container>
    )
  }

  return <ImageContainer url={railLogo} errorIconName="railPWA" />
}
