import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Wrapper } from './rules-and-restrictions-button.styled'

type Props = {
  onOpenRulesAndRestrictions: () => void
}

export function RulesAndRestrictionsButton({ onOpenRulesAndRestrictions }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (!isMobile) {
    return null
  }

  return (
    <Wrapper onClick={onOpenRulesAndRestrictions}>
      <Text variant="linkSmall" color="mainText1">
        {t(`TripReview.RulesAndRestrictions.title`)}
      </Text>
      <Icon name="chevronRightPWA" size="normal" color="bodyText" />
    </Wrapper>
  )
}
