import { observer } from 'mobx-react-lite'
import { SegmentType } from '@fiji/graphql/hooks'
import { PolicyReasonHeading } from './policy-reason-heading'
import type { OutOfPolicyModalProps } from './types'
import { OutOfPolicyModalLayout } from './layout'
import { useOutOfPolicyModal } from './use-out-of-policy-modal'

export const OutOfPolicyModal = observer(function OutOfPolicyModal({
  isOpen,
  segments,
  descriptions,
  handleClose,
  travelPolicy,
  isPolicyExplanationRequired = false,
  oopSelections,
  isHotelRequiredForOvernightTrip = false,
  isCarRentalRequiredViolation = false,
}: OutOfPolicyModalProps) {
  const useData = useOutOfPolicyModal({
    segments,
    descriptions,
    handleClose,
    oopSelections,
    isPolicyExplanationRequired,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
  })
  const hotelHeading = isHotelRequiredForOvernightTrip ? (
    <PolicyReasonHeading
      type={SegmentType.Hotel}
      title={''}
      key={''}
      isHotelRequiredForOvernightTrip={isHotelRequiredForOvernightTrip}
    />
  ) : (
    ''
  )
  const componentProps = {
    isOpen,
    descriptions,
    travelPolicy,
    isPolicyExplanationRequired,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
    hotelHeading,
    ...useData,
  }

  return <OutOfPolicyModalLayout {...componentProps} />
})
