import { isRestrictedCountryError } from '@etta/modules/car-rental-search/core/errors/car-rental.errors'
import { Inject, Service } from '@etta/di'
import { CarRentalStore } from '@etta/modules/car-rental-search/interface/stores/car-rental.store'
import { CarRentalLocationAdapter } from '@etta/modules/car-rental-search/infra/adapters'

@Service()
export class CarRentalLocationService {
  constructor(
    @Inject()
    private readonly carRentalStore: CarRentalStore,
    @Inject()
    private readonly carRentalLocationAdapter: CarRentalLocationAdapter,
  ) {}

  async loadDropOffLocations() {
    this.carRentalStore.setIsLoadingDropOff(true)
    this.carRentalStore.setIsErrorDropOff(false)
    this.carRentalStore.setDropOffLocations([])
    this.carRentalStore.setDropOffMessages([])

    const result = await this.carRentalLocationAdapter.getCarRentalLocations({
      geocode: this.carRentalStore.dropOffGeocode,
      searchDateTime: this.carRentalStore.dropOffSearchDateTime,
      allowedVendors: this.carRentalStore.queryParams.allowedVendors,
      itineraryId: this.carRentalStore.queryParams.itineraryId,
      isDropOff: true,
    })
    this.carRentalStore.setIsLoadingDropOff(false)

    if (result.isErr()) {
      this.carRentalStore.setIsErrorDropOff(true)
      return
    }
    const { locations, messages } = result.getValue()
    this.carRentalStore.setDropOffLocations(locations)
    this.carRentalStore.setDropOffMessages(messages)
  }

  async loadPickUpLocations() {
    this.carRentalStore.setIsLoadingPickUp(true)
    this.carRentalStore.setIsErrorPickUp(false)
    this.carRentalStore.setIsRestrictedCountryError(false)
    this.carRentalStore.setRestrictedErrorMessage(null)
    this.carRentalStore.setPickupLocations([])
    this.carRentalStore.setPickUpMessages([])

    const result = await this.carRentalLocationAdapter.getCarRentalLocations({
      geocode: this.carRentalStore.pickUpGeocode,
      searchDateTime: this.carRentalStore.pickUpSearchDateTime,
      itineraryId: this.carRentalStore.queryParams.itineraryId,
    })
    this.carRentalStore.setIsLoadingPickUp(false)

    if (result.isErr() && isRestrictedCountryError(result.getError())) {
      this.carRentalStore.setIsRestrictedCountryError(true)
      this.carRentalStore.setRestrictedErrorMessage(result.getError().error.message)
      return
    }

    if (result.isErr()) {
      this.carRentalStore.setIsErrorPickUp(true)
      return
    }

    const { locations, messages } = result.getValue()

    this.carRentalStore.setPickupLocations(locations)
    this.carRentalStore.setPickUpMessages(messages)
  }
}
