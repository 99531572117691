import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'

export const ContentWrapper = styled.div<{ isInfo: boolean }>`
  min-height: 422px;
  position: relative;
  padding: 20px 20px 32px;
  ${({ isInfo }) =>
    isInfo &&
    css`
      padding: 10px 0 0;
      min-height: 91px;
    `}
`

export const Title = styled(Text)<{ isInfo: boolean }>`
  ${({ isInfo }) =>
    isInfo &&
    css`
      max-width: 370px;
    `};
`

export const BodyText = styled(Text)<{ isInfo: boolean }>`
  margin-top: ${({ isInfo }) => (isInfo ? '8px' : '16px')};
  line-height: 19px;
  br {
    content: ' ';
    display: block;
    height: 13px;
  }
`

export const LogoWrapper = styled.img<{ isInfo: boolean }>`
  height: 120px;
  width: 120px;
  float: right;
  margin: 22px 0 0 10px;
  ${({ isInfo }) =>
    isInfo &&
    css`
      height: 74px;
      width: 74px;
      margin-top: 0;
    `}
`

export const ReadMoreLink = styled(Link)<{ isInfo: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: ${({ isInfo }) => (isInfo ? '8px' : '16px')};
`

export const ToggleLink = styled(Link)`
  display: block;
  margin-top: 8px;
`
