import type { AirportAddressFragment } from '@fiji/graphql/types'
import { getTime, separateLabels } from '../../utils'

export const getTimeLabel = (departureDate: string, arrivalDate: string, separator: string) => {
  return separateLabels([getTime(departureDate), getTime(arrivalDate)], separator)
}

export const getDirection = (direction: AirportAddressFragment) => {
  const separator = ' '
  return separateLabels([direction.address?.city ?? '', `(${direction.airportCode})`], separator)
}

export const getDirectionLabel = (
  departure: AirportAddressFragment,
  arrival: AirportAddressFragment,
  separator: string,
) => {
  return separateLabels([getDirection(departure), getDirection(arrival)], separator)
}
