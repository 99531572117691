import type { Moment } from 'moment'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'

type Args = {
  setMonthText: (text: string) => void
  changeState: () => void
  choosenYear?: number
}

export function initializeMonthElementRenderer({ setMonthText, changeState, choosenYear }: Args) {
  return ({ month }: { month: Moment }) => {
    const handleClick = () => {
      setMonthText(month.format('MMM'))
      changeState()
    }
    const { handleKeyDown } = useA11yKeydown(handleClick)

    return (
      <Text
        onKeyDown={handleKeyDown}
        tabIndex={0}
        onClick={handleClick}
        variant="subHeadStrong"
        color="primary1">
        {month.format('MMM')} {month.format('YYYY') || choosenYear}{' '}
        <Icon size="small" name="chevronDownPWA" />
      </Text>
    )
  }
}
