import { useTheme } from '@fiji/hooks/use-theme'

export function NoConnectionIcon() {
  const theme = useTheme()

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill={theme.colors.plum} />
      <path
        d="M100 22.8788C100 33.21 91.6232 41.5852 81.2936 41.5852C70.9621 41.5852 62.5873 33.21 62.5873 22.8788C62.5873 12.5476 70.9621 4.17245 81.2936 4.17245C91.6232 4.17245 100 12.5476 100 22.8788Z"
        fill="#EFEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1555 23.0436C96.1555 31.1602 89.5755 37.7407 81.4567 37.7407C73.3397 37.7407 66.7597 31.1602 66.7597 23.0436C66.7597 14.9253 73.3397 8.34491 81.4567 8.34491C89.5755 8.34491 96.1555 14.9253 96.1555 23.0436Z"
        fill={theme.colors.plum}
      />
      <path
        d="M88.4568 17.4528L82.8668 23.0428L88.4568 28.6328L87.0476 30.042L81.4576 24.452L75.8676 30.042L74.4584 28.6328L80.0483 23.0428L74.4584 17.4528L75.8676 16.0435L81.4576 21.6335L87.0476 16.0435L88.4568 17.4528Z"
        fill={theme.colors.white}
      />
      <path
        d="M28.6856 44.7304C32.5689 40.9098 37.235 38.9994 42.6841 38.9994C48.1958 38.9994 52.8619 40.9098 56.6826 44.7304L52.7367 48.7702C49.9808 46.0143 46.63 44.6364 42.6841 44.6364C38.7382 44.6364 35.3873 46.0143 32.6315 48.7702L28.6856 44.7304ZM36.6713 52.81C38.2998 51.1816 40.304 50.3673 42.6841 50.3673C45.0641 50.3673 47.0684 51.1816 48.6968 52.81L42.6841 58.8228L36.6713 52.81ZM20.606 36.7447C26.744 30.6693 34.1034 27.6316 42.6841 27.6316C51.3274 27.6316 58.6868 30.6693 64.7622 36.7447L60.7224 40.7845C55.7117 35.8365 49.699 33.3625 42.6841 33.3625C35.6692 33.3625 29.6564 35.8365 24.6458 40.7845L20.606 36.7447Z"
        fill={theme.colors.white}
      />
    </svg>
  )
}
