import { forwardRef } from 'react'
import type { ForwardedRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { mergeRefs } from '@fiji/utils/merge-refs'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { Container, Content, SelectButton } from './popover-styled'
import type { Props } from './types'

const i18nBase = 'AirSeatMap'

export const Popover = observer(
  forwardRef(
    ({ position, handleFareDetailsTabChange }: Props, ref: ForwardedRef<HTMLDivElement>) => {
      const { t } = useTranslation()
      const { seatMapStore, seatMapActions } = useSeatMapContext()
      const { handleSubmitSeat, handleClosePopover } = seatMapActions
      const { seatMap, seatPopup, isSeatBooked, isReadonly } = seatMapStore
      const { available, isPaid } = seatMap.seatRow || {}
      const { type, typeText = '', feature = '', cabinClass = '' } = seatMap.selectedSeat || {}
      const seatFeature = type ? `${feature} (${type})` : feature

      const isPossibleBook = available && !isPaid

      const selectButtonText = isSeatBooked
        ? t(`${i18nBase}.UnselectSeat`)
        : t(`${i18nBase}.SelectSeat`)

      const hasButton = isReadonly || isPossibleBook
      const buttonText = isReadonly ? t('AirSearchResults.SelectFare') : selectButtonText

      function handleSubmit() {
        if (isReadonly) {
          seatMapActions.handleClosePopover()
          handleFareDetailsTabChange?.()
          return
        }

        handleSubmitSeat()
      }

      const { ref: clickOutsideRef } = useClickOutside(() => {
        if (seatPopup.isOpen) {
          handleClosePopover()
        }
      })

      const mergedRefs = mergeRefs([ref, clickOutsideRef])

      return (
        <Container ref={mergedRefs} position={position} isVisible={seatPopup.isOpen}>
          <Content>
            <Text variant="subHeadStrong" color="white">
              {seatFeature}
            </Text>
            <Block marginBottom={4}>
              <Text variant="footnoteMedium" color="white">
                {cabinClass}
              </Text>
            </Block>
            <Text variant="footnoteMedium" color="white">
              {typeText}
            </Text>
            {hasButton && (
              <SelectButton data-tracking-id="seat-map-submit-seat-button" onClick={handleSubmit}>
                {buttonText}
              </SelectButton>
            )}
          </Content>
          <IconButton
            aria-label={t(`${i18nBase}.Ally.CloseModalButton`)}
            icon="closePWA"
            color="white"
            size="normal"
            onClick={handleClosePopover}
          />
        </Container>
      )
    },
  ),
)
