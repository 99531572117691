import styled, { css } from 'styled-components'
import { captionMedium, subHeadStrong } from '@fiji/style'
import { ScreenType } from '@fiji/modes'
import { Paper } from '../trip-flight-styled'

export const Container = styled(Paper)<{ withPaddingBottom: boolean; screenType: ScreenType }>`
  padding: 16px 16px ${(p) => (p.withPaddingBottom ? 16 : 0)}px 16px;
  ${(p) =>
    p.screenType !== ScreenType.Mobile &&
    css`
      margin-top: -60px;
    `}
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const Cell = styled.div`
  width: 100%;
`

export const CellName = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${captionMedium};
`

export const CellValue = styled.div`
  margin-top: 4px;
  color: ${(p) => p.theme.colors.mainText};
  ${subHeadStrong};
`

export const ButtonCell = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: ${(p) => p.theme.colors.primary1};
`

export const ButtonCellValue = styled.div`
  max-width: 240px;
  ${subHeadStrong};
`
