import { useEffect, useRef } from 'react'
import { appMode } from '@fiji/modes'
import { useUpdateTokensWithCookieMutation } from '@fiji/graphql/hooks'

const DEFAULT_TIMER = 1000 * 60

export function useFijiSessions() {
  const timerRef = useRef<number | null>(null)
  const [updateTokenWithCookie] = useUpdateTokensWithCookieMutation()

  useEffect(() => {
    if (!appMode.isFijiAll) {
      return
    }

    const handleUpdateTokenWithCookie = async (): Promise<void> => {
      await updateTokenWithCookie()
    }

    const handleEvent = () => {
      if (timerRef.current) {
        return
      }
      timerRef.current = window.setTimeout(() => {
        timerRef.current = null
        handleUpdateTokenWithCookie()
      }, DEFAULT_TIMER)
    }

    window.addEventListener('mousedown', handleEvent)

    return () => {
      window.removeEventListener('mousedown', handleEvent)
    }
  }, [updateTokenWithCookie])
}
