import type { SegmentDetails } from '../../types'
import type { FlightLeg } from '../../../../types'
import { separateLabels } from '../../utils'
import { getDirectionLabel, getTimeLabel } from './utils'

export function getFlightLegs(
  segments: SegmentDetails['flightDetails']['segments'],
  getTranslation: (label: string) => string,
) {
  if (!segments) {
    return []
  }

  return segments?.reduce((acc: FlightLeg[], segment) => {
    let isWebFare = false
    const flightLegs = segment.segments.map((flightSegment) => {
      if (flightSegment.bookingProvider === 'TRAVEL_FUSION') {
        isWebFare = true
      }
      const direction = getDirectionLabel(
        flightSegment.departure,
        flightSegment.arrival,
        ` ${getTranslation('To')} `,
      )
      const time = getTimeLabel(flightSegment.departureDate, flightSegment.arrivalDate, ' - ')

      const aircraft = separateLabels([flightSegment.name, flightSegment.flightNumber], ' ')

      return {
        direction,
        time,
        aircraft,
        cabinClass: flightSegment.serviceClass,
        isWebFare,
        // TODO
        // confirmation
      }
    })

    return acc.concat(flightLegs)
  }, [])
}
