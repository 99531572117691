import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  margin: 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 16px 0 20px;
  overflow-y: auto;
`

export const Footer = styled.div`
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.mainText};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
`

export const InfoIconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Price = styled(Text)`
  text-align: right;
`

export const FareAttributes = styled.ul`
  list-style-type: none;
  padding: 0 16px 12px 20px;
  margin: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.background1};
`

export const OutOfPolicy = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 16px 0px 20px;
`

export const RailCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 16px;
`
