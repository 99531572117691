import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'
import { IndexLabelRow, IconButton } from './index-label-mobile.styled'

const i18Base = 'TripPlanner.BaseSearch'

export function IndexLabelMobile({ number, isCloseButtonShow, onButtonClick }: Props) {
  const { t } = useTranslation()
  const titleText = t(i18Base + '.Input.FlightNumber', { number })
  const closeLabel = t(i18Base + '.Input.Close', { number })

  return (
    <IndexLabelRow>
      <Text
        textTransform="uppercase"
        variant="footnoteStrong"
        color="mainText"
        aria-label={titleText}>
        {titleText}
      </Text>
      {isCloseButtonShow && (
        <IconButton onClick={onButtonClick} aria-label={closeLabel}>
          <Icon name="closePWA" color="mainText" size="medium" />
        </IconButton>
      )}
    </IndexLabelRow>
  )
}
