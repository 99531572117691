/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useScrollbarWidth } from '@fiji/hooks/use-scrollbar-width'

export function useDialogScrollbar() {
  const scrollbarWidth = useScrollbarWidth()
  const element = document.body
  useEffect(() => {
    element.style.marginRight = `${scrollbarWidth}px`
    return () => {
      element.style.marginRight = '0'
    }
  }, [])
}
