import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { HandleCardClickProps, RailCardGroup } from '@etta/components/rail-card-modal/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { LoyaltyProgram, RailCard } from '@fiji/graphql/types'
import { useGetRailCardsQuery } from '@fiji/graphql/hooks'
import { RailLayoutType } from '@fiji/enums'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import { getUniqueGroupNames } from './get-unique-group-names'
import { getMappedGroups } from './get-mapped-groups'

const i18Base = 'TripPlanner.BaseSearch'

type useRailCardProps = {
  layout: RailLayoutType
  railCards?: RailCard[]
}

export function useRailCard({ layout, railCards }: useRailCardProps) {
  const { t } = useTranslation()
  const placeHolder =
    layout === RailLayoutType.UK ? t(i18Base + '.Input.RailCard') : t(i18Base + '.Input.RailCardEU')
  const placeHolderDesktop =
    layout === RailLayoutType.UK
      ? t(i18Base + '.Input.RailCardDesktop')
      : t(i18Base + '.Input.RailCardEU')
  const ariaLabel =
    layout === RailLayoutType.UK
      ? t(i18Base + '.Input.RailCardAriaLabel')
      : t(i18Base + '.Input.RailCardAriaLabelEU')
  const ariaLabelDesktop =
    layout === RailLayoutType.UK
      ? t(i18Base + '.Input.RailCardDesktopAriaLabel')
      : t(i18Base + '.Input.RailCardAriaLabelEU')
  const errorText = t(i18Base + '.RailCard.Errors.CardListError')
  const temporaryCurrentUKCard: HandleCardClickProps = {
    // FIXME: Should be fixed when currentCard will be available from backend
    vendorCode: '',
    vendorName: '',
    code: '',
    name: '',
    groupName: '',
    isChecked: false,
  }

  const [currentSavedCards, setCurrentSavedCards] = useState<LoyaltyProgram[]>([])
  const [cardGroups, setCardGroups] = useState<RailCardGroup[]>([])
  const [currentUKCard, setCurrentUKCard] = useState<HandleCardClickProps>(temporaryCurrentUKCard)
  const [currentEUCards, setCurrentEUCards] = useState('')

  const railCardModal = useTogglePopup()
  const { travelPreferencesStore } = useTravelPreferencesContext()
  const { railSearchFormActions } = useRailSearchFormContext()
  const rail = travelPreferencesStore.travelPreferences.rail

  const { loading: railCardsLoading, error: railCardsError } = useGetRailCardsQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const cards: RailCard[] = data.railCards.cards.map((card) => {
        return {
          code: card.code,
          groupName: card.groupName,
          name: card.name,
          vendorCode: card.vendorCode,
          vendorName: card.vendorName,
        }
      })
      const uniqueGroups = getUniqueGroupNames(cards)
      const mappedGroups = getMappedGroups(uniqueGroups, cards)

      setCardGroups(mappedGroups)
    },
  })

  useEffect(() => {
    setCurrentSavedCards(rail.loyaltyProgram)
  }, [rail.loyaltyProgram])

  useEffect(() => {
    if (railCards && railCards?.length > 0) {
      const card: RailCard = railCards[0]
      setCurrentUKCard(card)
    }
  }, [railCards])

  const handleSelectUKCard = (card: HandleCardClickProps) => {
    railSearchFormActions.onRailCardSelect(card)
    setCurrentUKCard(card)
  }

  const handleSelectEUCard = (numbers: string[], membership: LoyaltyProgram[]) => {
    const cardLabels = numbers
      .map((number) => membership.find((item) => item.number === number)?.label)
      .filter(Boolean)
      .join(', ')

    setCurrentEUCards(cardLabels)
  }
  const handleClearCardSelection = () => {
    setCurrentUKCard(temporaryCurrentUKCard)
    setCurrentEUCards('')
    railSearchFormActions.onRailCardDeselect()
  }
  const isCardSelected = currentUKCard.vendorCode !== ''

  return {
    railCardModal,
    placeHolder,
    placeHolderDesktop,
    ariaLabel,
    ariaLabelDesktop,
    currentUKCard,
    currentEUCards,
    handleSelectUKCard,
    handleSelectEUCard,
    handleClearCardSelection,
    savedCards: currentSavedCards,
    cardGroups,
    railCardsLoading,
    errorText: railCardsError ? errorText : '',
    isCardSelected,
  }
}
