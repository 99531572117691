import type { Maybe, Result } from 'fnscript'
import { Just, Nothing, Error as NestedError, Ok, Err } from 'fnscript'
import { Service } from '@etta/di'
import { dateToIso, dateFormat } from '@fiji/utils/dates'
import { isBeforeDate } from '@fiji/utils/dates/is-before-date'
import { isSameDay } from '@fiji/utils/dates/is-same-day'
import type { PlainTime } from '@fiji/types'
// eslint-disable-next-line import/no-restricted-paths
import type { SubmitNewCarRentalSearchInput } from '@fiji/graphql/hooks'
import type { SubmitCarRentalSearchInput } from '../../core/value-objects/submit-car-rental-search-input.value-object'
import type { Subset } from './validators'
import { isDate, isCoordinate, isEmptyString, isZeroNumber } from './validators'

@Service()
export class CarRentalMapService {
  getCarRental(
    input: SubmitCarRentalSearchInput,
  ): Result<SubmitNewCarRentalSearchInput, Maybe<NestedError>> {
    const submitSearch = {
      searchParams: {
        pickUp: {
          date: dateToIso(input.pickUpDate),
          time: this.timeToString(input.pickUpTime),
          location: {
            airportCode: input.pickUpPlace.airportCode,
            name: input.pickUpPlace.name,
            geocode: {
              lat: input.pickUpPlace.latitude,
              long: input.pickUpPlace.longitude,
            },
          },
        },
        dropOff: {
          date: dateToIso(input.dropOffDate),
          time: this.timeToString(input.dropOffTime),
          location: {
            airportCode: input.dropOffPlace.airportCode,
            name: input.dropOffPlace.name,
            geocode: {
              lat: input.dropOffPlace.latitude,
              long: input.dropOffPlace.longitude,
            },
          },
        },
      },
    }

    if (this.checkIsSubmitNewCarRentalSearchFullyFilled(input, submitSearch)) {
      return Ok(submitSearch)
    }
    return Err(this.validateSubmitNewCarRentalSearchArgs(input))
  }

  private checkIsSubmitNewCarRentalSearchFullyFilled(
    input: SubmitCarRentalSearchInput,
    submitSearch: Subset<SubmitNewCarRentalSearchInput>,
  ): submitSearch is SubmitNewCarRentalSearchInput {
    if (this.validateSubmitNewCarRentalSearchArgs(input).isValue() && !submitSearch) {
      return false
    }
    return true
  }

  private validateSubmitNewCarRentalSearchArgs(
    params: SubmitCarRentalSearchInput,
  ): Maybe<NestedError> {
    const errors: NestedError[] = []

    if (!isDate(params.pickUpDate)) {
      const error = NestedError.new('pickUpDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (!isDate(params.dropOffDate)) {
      const error = NestedError.new('dropOffDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (
      !isBeforeDate(params.pickUpDate, params.dropOffDate) &&
      !isSameDay(params.pickUpDate, params.dropOffDate)
    ) {
      const error = NestedError.new('params.pickUpDate should be <= params.dropOffDate')
      errors.push(error)
    }

    if (isZeroNumber(params.pickUpTime.hours)) {
      const error = NestedError.new('pickUpTime.hours is missing or it has a wrong value')
      errors.push(error)
    }

    if (typeof params.pickUpTime.minutes !== 'number') {
      const error = NestedError.new('pickUpTime.minutes is missing or it has a wrong value')
      errors.push(error)
    }

    if (!isCoordinate(params.pickUpPlace?.latitude)) {
      const error = NestedError.new('pickUpPlace.latitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (!isCoordinate(params.pickUpPlace?.longitude)) {
      const error = NestedError.new('pickUpPlace.longitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.pickUpPlace?.name)) {
      const error = NestedError.new('pickUpPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (isZeroNumber(params.dropOffTime.hours)) {
      const error = NestedError.new('dropOffTime.hours is missing or it has a wrong value')
      errors.push(error)
    }

    if (typeof params.dropOffTime.minutes !== 'number') {
      const error = NestedError.new('dropOffTime.minutes is missing or it has a wrong value')
      errors.push(error)
    }

    if (!isCoordinate(params.dropOffPlace?.latitude)) {
      const error = NestedError.new('dropOffPlace.latitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (!isCoordinate(params.dropOffPlace?.longitude)) {
      const error = NestedError.new('dropOffPlace.longitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.dropOffPlace?.name)) {
      const error = NestedError.new('dropOffPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(
          `failed to validate submitNewCarRentalSearch input params;${validationError.getMessage()}`,
        ),
      )
    }

    return Nothing()
  }

  private timeToString(plainTime: PlainTime): string {
    return dateFormat(new Date().setHours(plainTime.hours, plainTime.minutes, 0), 'HH:mm:ss')
  }
}
