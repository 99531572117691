import type { FlightLegSegment } from '@fiji/graphql/types'
import { Arrow } from './flight-title-name-styled'

type Props = {
  flight: FlightLegSegment
}

export function FlightTitleName({ flight }: Props) {
  const flightSegments = flight.segments
  if (!flight.segments.length) {
    return <></>
  }

  const iconName =
    flight.isSameCarrierName && flight.isRoundTripLeg && flight.isRoundTripCost
      ? 'sameDayArrowsPWA'
      : 'flightArrowPWA'

  return (
    <>
      {flightSegments[0].departure.airportCode}
      <Arrow name={iconName} />
      {flightSegments[flightSegments.length - 1].arrival.airportCode}
    </>
  )
}
