import { Block } from '@etta/ui/block'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ScreenType, useScreenType } from '@fiji/modes'
import { MeetingInformationCard } from '@etta/modules/meeting/ui/meeting-information/components/meeting-information-card'
import type { LayoutProps } from '../../types'
import { TripCostsSidebar } from '../../trip-costs-sidebar'
import { CostSummary } from '../../cost-summary'
import { Content, SideBarContent } from './trip-details-desktop-layout-styled'

export function TripDetailsDesktopLayout({
  contentSlot,
  headerSlot,
  trip,
  isLoading,
  isError,
  onCancelModalOpen,
  onContinueBooking,
  onETicketsDownloading,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
  onOpenETicketReceipt,
}: LayoutProps) {
  const {
    bookingRef,
    costSummary,
    type,
    segments,
    cityImageUrl,
    tripCost,
    airTickets,
    recordLocator,
    creationTime,
  } = trip
  const toggle = useTogglePopup()
  const screenType = useScreenType()

  const isWideContainer = screenType === ScreenType.Tablet

  return (
    <>
      {headerSlot}
      <Content as={'main'} isWideContainer={isWideContainer}>
        <Block paddingLeft={16}>{contentSlot}</Block>
        {!isError && (
          <SideBarContent>
            <TripCostsSidebar
              isLoading={isLoading}
              bookingRef={bookingRef}
              costs={costSummary}
              segments={segments}
              type={type}
              onOpenBreakdown={toggle.handleOpen}
              onCancelModalOpen={onCancelModalOpen}
              onContinueBooking={onContinueBooking}
              onETicketsDownloading={onETicketsDownloading}
              cityImageUrl={cityImageUrl}
              isUUTApplied={!!tripCost?.unusedTicket?.primary.amount}
              onOpenRulesAndRestrictions={onOpenRulesAndRestrictions}
              showRulesAndRestrictions={showRulesAndRestrictions}
              onOpenETicketReceipt={onOpenETicketReceipt}
              airTickets={airTickets}
              recordLocator={recordLocator}
              creationTime={creationTime}
            />
            {trip.meetingInfo && (
              <Block marginTop={32}>
                <MeetingInformationCard meeting={trip.meetingInfo} />
              </Block>
            )}
          </SideBarContent>
        )}
      </Content>
      {segments && toggle.isOpen && <CostSummary onClose={toggle.handleClose} />}
    </>
  )
}
