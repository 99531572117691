import { DataProvider, Inject } from '@etta/di'
import type {
  SetRcSessionCookieWithCookieMutation,
  SetRcSessionCookieWithCookieMutationVariables,
} from '@fiji/graphql/hooks'
import { SetRcSessionCookieWithCookieDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { ApolloService } from '@etta/infra/services/apollo.service'

@DataProvider()
export class NavigationDataProvider extends GraphqlDataProvider {
  constructor(
    @Inject()
    private readonly apolloService: ApolloService,
  ) {
    super()
  }

  setRcSessionCookie({ url }: { url?: string }) {
    const client = this.apolloService.createClient(url)

    return client.mutate<
      SetRcSessionCookieWithCookieMutation,
      SetRcSessionCookieWithCookieMutationVariables
    >({
      mutation: SetRcSessionCookieWithCookieDocument,
    })
  }
}
