import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { PassportEntity } from '@etta/core/entities'
import { useTravelerDocuments } from '../traveler-documents/use-traveler-documents'
import { TravelerDocuments } from '../traveler-documents'
import { TravelerDocumentAction } from '../traveler-documents/traveler-document-action'
import { PassportBoxLayout } from './layout'
import { usePassportBox } from './use-passport-box'

type Props = {
  passports?: PassportEntity[] | null
  isPassportSelected: boolean
  onAddNewPassport: () => void
  onOpenExistingPassport: (id?: number | null) => void
  onError?: (isError: boolean) => void
  isPassportRequired: boolean
}

export const PassportBox = observer(function PassportBox({
  passports,
  isPassportSelected,
  onAddNewPassport,
  onOpenExistingPassport,
  onError,
  isPassportRequired,
}: Props) {
  const { t } = useTranslation()
  const { boxValue, updateBoxValue, areNoPassport, isError } = usePassportBox({
    passports,
    isPassportSelected,
    onError,
    isPassportRequired,
  })

  const {
    i18Base,
    documents,
    knownTravelerNumbersSize,
    redressNumbersSize,
    travelerDocumentsToggle,
    travelerDocumentActionToggle,
    handleTravelerDocumentOpen,
    handleTravelerDocumentClose,
    handleTravelerDocumentSubmit,
    isSaveButtonDisabled,
  } = useTravelerDocuments()

  const layoutProps = {
    passports,
    boxValue,
    updateBoxValue,
    areNoPassport,
    isError,
    onAddNewPassport,
    onOpenExistingPassport,
    handleTravelerDocumentOpen,
    knownTravelerNumbersSize,
    redressNumbersSize,
    isPassportRequired,
  }
  return (
    <>
      <PassportBoxLayout {...layoutProps} />

      <TravelerDocuments
        isOpen={travelerDocumentsToggle.isOpen}
        title={t(i18Base + 'Title')}
        onClose={handleTravelerDocumentClose}
        documents={documents}
      />
      <TravelerDocumentAction
        isOpen={travelerDocumentActionToggle.isOpen}
        title={t(i18Base + 'Title')}
        onClose={handleTravelerDocumentClose}
        onSave={handleTravelerDocumentSubmit}
        isSaveDisabled={isSaveButtonDisabled}
      />
    </>
  )
})
