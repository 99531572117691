import type { PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { AppSpinner } from '@etta/modules/app'
import { appMode } from '@fiji/modes'
import { AppAuth } from './app-auth'
import { ContextLoader } from './context-loader'
import { useRouteAuthorize } from './use-route-authorize'

const Loader = <AppSpinner />

export const RouteAuthorize = observer(({ children }: PropsWithChildren<{}>) => {
  const { previousPath, isLoading, isRouteNotExist, isRouteShouldUseAuth } = useRouteAuthorize()

  if (isLoading) {
    return Loader
  }

  switch (true) {
    case isRouteNotExist && appMode.isFijiAll:
      return <Redirect to="/trip-planner" />
    case !isRouteShouldUseAuth:
      return <>{children}</>
    default:
      return (
        <AppAuth loaderSlot={Loader} previousPath={previousPath}>
          <ContextLoader loaderSlot={Loader}>{children}</ContextLoader>
        </AppAuth>
      )
  }
})
