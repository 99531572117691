import { HotelCardSkeleton } from '@etta/components/hotel-card/skeleton'
import { PAGE_HOTEL_SIZE } from '@etta/screens/hotel-result-page/constants'

function createSkeletons(numberOfSkeletons: number) {
  return new Array(numberOfSkeletons).fill(0).map((_, index) => <HotelCardSkeleton key={index} />)
}

const DEFAULT_SKELETONS_NUMBER = 3

export const DefaultSkeletons = <>{createSkeletons(DEFAULT_SKELETONS_NUMBER)}</>

export const PageSkeletons = <>{createSkeletons(PAGE_HOTEL_SIZE)}</>
