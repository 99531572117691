import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { HomeAirportErrors } from '../core/errors/home-airport.errors'
import { HomeAirportDataProvider } from './home-airport.data-provider/home-airport.data-provider'
import type { HomeAirportResults } from './types'
// TODO: remove fiji import
// eslint-disable-next-line import/order
import type { Place } from '@fiji/types'

@Adapter()
export class HomeAirportAdapter {
  constructor(
    @Inject()
    private homeAirportDataProvider: HomeAirportDataProvider,
  ) {}

  async get(forceUpdate?: boolean): HomeAirportResults<Place> {
    try {
      const { data, error } = await this.homeAirportDataProvider.get(forceUpdate)
      if (error) {
        return Result.Err(new HomeAirportErrors.GetHomeAirportError(error))
      }

      const airport = data.homeAirport[0]

      if (!airport) {
        return Result.Err(new HomeAirportErrors.GetHomeAirportEmpty(data.homeAirport))
      }

      const { name, placeId, address, airportCode, latitude, longitude, placeType } = airport

      return Result.Ok({
        name,
        placeId,
        city: address?.city ?? undefined,
        airportCode: airportCode ?? undefined,
        latitude: latitude ?? undefined,
        longitude: longitude ?? undefined,
        placeType: placeType ?? undefined,
        countryCode: address?.countryCode ?? undefined,
        timezone: airport.timezone ?? undefined,
      })
    } catch (e) {
      return Result.Err(new HomeAirportErrors.UnexpectedHomeAirportError(e))
    }
  }
}
