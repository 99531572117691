import styled from 'styled-components'

export const Container = styled.div`
  height: inherit;
  width: inherit;

  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export const AirlineLogoWrapper = styled.div`
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 3px;
`

export const FirstLogo = styled(AirlineLogoWrapper)`
  position: absolute;
  top: 0;
  left: 0;
`

export const SecondLogo = styled(AirlineLogoWrapper)`
  position: absolute;
  bottom: 0;
  right: 0;
`
