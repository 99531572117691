import i18n from 'i18next'
import type { SearchType } from './types'

type Args = {
  searchType?: SearchType
  hasAnyPlaces: boolean
  hasSearchValue: boolean
  isFetchStarted: boolean
  isLoading: boolean
}

const i18nBase = 'SearchResults'

export function getNoResultMessage({
  searchType,
  hasAnyPlaces,
  hasSearchValue,
  isFetchStarted,
  isLoading,
}: Args): string | undefined {
  if (hasAnyPlaces || isLoading || !hasSearchValue || !isFetchStarted) {
    return
  }

  const searchTypeLabel = getSearchType(searchType)
  if (!searchTypeLabel) {
    return
  }

  return i18n.t(`${i18nBase}.NoResult`, { searchType: searchTypeLabel })
}

function getSearchType(searchType?: SearchType): SearchType | null {
  switch (searchType) {
    case 'air':
      return i18n.t(`${i18nBase}.NoResultFlightLabel`)
    case 'hotel':
      return i18n.t(`${i18nBase}.NoResultHotelLabel`)
    case 'car-rental':
      return i18n.t(`${i18nBase}.NoResultCarLabel`)
    default:
      return null
  }
}
