import { Badge } from '@etta/ui/badge'
import type { Room } from '@fiji/graphql/types'
import { Price } from '../../price'
import { Container } from './footer-styled'

type Props = {
  firstRoom?: Room
  isPreferred?: boolean
  isAllRoomsOutOfPolicy?: boolean
  isHotelLoading: boolean
}

export function Footer({ firstRoom, isPreferred, isAllRoomsOutOfPolicy, isHotelLoading }: Props) {
  const showOutOfPolicy = isPreferred && isAllRoomsOutOfPolicy

  return (
    <Container>
      <div>{showOutOfPolicy && <Badge variant="outOfPolicy" color="mainText" />}</div>
      <Price firstRoom={firstRoom} isHotelLoading={isHotelLoading} />
    </Container>
  )
}
