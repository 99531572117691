import type { ValueType } from '@etta/ui/drop-down-list'
import { DropDownList } from '@etta/ui/drop-down-list'
import type { LayoutProps } from '../types'

export function PreferenceMealOptionsDesktop({
  label,
  mealOptions,
  mealValue,
  onUpdateMeal,
  withNoneOption,
}: LayoutProps) {
  function handleChangeValue(values: ValueType[]) {
    if (values.length === 0) {
      return
    }

    onUpdateMeal(String(values[0]))
  }

  return (
    <DropDownList
      label={label}
      options={mealOptions}
      value={[mealValue]}
      onChange={handleChangeValue}
      withListHeader={false}
      withListBodyPadding={false}
      withThinLabel
      withVisibleLabel
      withNoneOption={withNoneOption}
    />
  )
}
