import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { CarRentalDetailsHeaderProps } from '../../types'
import { Amenities } from './amenities'
import {
  Container,
  Header,
  Title,
  CarImage,
  CarImageContainer,
  TitleWrapper,
  CarIconContainer,
  Row,
  CloseButtonTop,
  AmenitiesWrapper,
} from './car-rental-details-header-desktop-styled'

export function CarRentalDetailsHeaderDesktop({
  carRentalTitle,
  isOutOfPolicy,
  amenities,
  isPreferred,
  onClose,
  carImage,
}: CarRentalDetailsHeaderProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>
            <Text variant="title3" color="mainText">
              {carRentalTitle}
            </Text>
          </Title>
          <Row>
            {isOutOfPolicy && (
              <CarIconContainer>
                <Icon name="outOfPolicyPWA" size="small" />
                <Text variant="footnoteMedium" color="bodyText">
                  {t('CarRentalDetails.OutOfPolicy')}
                </Text>
              </CarIconContainer>
            )}
            {isPreferred && (
              <>
                <Block marginLeft={4}>
                  <Text variant="footnoteMedium" color="bodyText">
                    •
                  </Text>
                </Block>
                <CarIconContainer>
                  <Icon name="preferredPWA" size="small" />
                  <Text variant="footnoteMedium" color="bodyText">
                    {t('CarRentalDetails.Preferred')}
                  </Text>
                </CarIconContainer>
              </>
            )}
          </Row>
        </TitleWrapper>
        <CloseButtonTop
          icon="closePWA"
          color="mainText2"
          onClick={onClose}
          aria-label={t('ReviewTrip.ButtonsLabel', { label: t('Accessibility.Header.Close') })}
        />
      </Header>
      <AmenitiesWrapper>
        <Amenities amenities={amenities} />
        <CarImageContainer>
          {carImage && <CarImage src={carImage} alt={carRentalTitle} />}
        </CarImageContainer>
      </AmenitiesWrapper>
    </Container>
  )
}
