import type { MainNavigation } from './types'
import { MAINNAVIGATIONVALUE } from './types'

export const initialState: MainNavigation = {
  support: MAINNAVIGATIONVALUE.SUPPORT,
  trips: MAINNAVIGATIONVALUE.TRIPS,
  profile: MAINNAVIGATIONVALUE.PROFILE,
  explore: MAINNAVIGATIONVALUE.EXPLORE,
  isOngoingBookingFlowActive: false,
  isTripsForceUpdateEnabled: false,
}
