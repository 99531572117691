import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { getClient } from '@fiji/providers'

export class GraphqlDataProvider {
  private apolloClient?: ApolloClient<NormalizedCacheObject>

  get client() {
    if (this.apolloClient) {
      return this.apolloClient
    }
    this.apolloClient = getClient()
    return this.apolloClient
  }
}
