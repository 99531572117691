import { Inject, Service } from '@etta/di'
import { HomeAirportAdapter } from '../../infra/home-airport.adapter'
import { HomeAirportStore } from '../stores/configs/home-airport.store'

@Service()
export class HomeAirportService {
  constructor(
    @Inject()
    private readonly homeAirportAdapter: HomeAirportAdapter,
    @Inject()
    private readonly homeAirportStore: HomeAirportStore,
  ) {}

  async get() {
    this.homeAirportStore.setHomeAirport(null)
    this.homeAirportStore.setIsHomeAirportLoading(true)

    const result = await this.homeAirportAdapter.get()

    result.match({
      Err: () => {
        this.homeAirportStore.setIsHomeAirportLoading(false)
      },
      Ok: (result) => {
        this.homeAirportStore.setHomeAirport(result)
        this.homeAirportStore.setIsHomeAirportLoading(false)
      },
    })
  }

  async reload() {
    this.homeAirportStore.setHomeAirport(null)

    const result = await this.homeAirportAdapter.get(true)

    result.match({
      Err: () => {},
      Ok: (result) => {
        this.homeAirportStore.setHomeAirport(result)
      },
    })
  }
}
