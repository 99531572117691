import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HotelSearchForm } from '@etta/components/hotel-search-form'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { CloseIconWrapper, ModalContent, TitleWrapper } from './styled'

const DATA_TRACKING_ID = 'POST_BOOKING_HOTEL_SEARCH_FORM_MODAL'

export const HotelSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const { postBookingTripStore } = usePostBookingContext()

  useEffect(() => {
    return () => {
      postBookingTripStore.hotelSearchFormModal.handleClose()
    }
  }, [postBookingTripStore])

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={postBookingTripStore.hotelSearchFormModal.handleClose}
      isVisible={postBookingTripStore.hotelSearchFormModal.isOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton
          onClick={postBookingTripStore.hotelSearchFormModal.handleClose}
          icon={'closePWA'}
          size={'medium'}
        />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('PostBooking.HotelDetails.Modify')}</Text>
      </TitleWrapper>
      <ModalContent>
        <HotelSearchForm />
      </ModalContent>
    </Modal>
  )
})
