import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Header as MobileHeader } from '@etta/ui/header'
import { useSupportTravelContext } from '@etta/modules/support-travel/interface/use-support-travel.context'
import { SupportTravelPageLayout } from './layout'
import { SupportTravelBody } from './components/support-travel-body'
import { PreviewSupportTravel } from './preview-support-travel'

export const SupportTravelPage = observer(() => {
  const { supportTravelAction, supportTravelStore } = useSupportTravelContext()

  useEffect(() => {
    supportTravelAction.handleGetSupportArticles()
  }, [supportTravelAction])

  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support'

  if (supportTravelStore.isPreview) {
    return <PreviewSupportTravel />
  }

  return (
    <SupportTravelPageLayout
      headerSlot={
        <MobileHeader
          layout="content-view"
          leftSlot={<MobileHeader.BackButton onClick={supportTravelAction.handleBackButton} />}>
          <MobileHeader.Title
            layout="content-view"
            align="left"
            title={t(i18Base + '.TravelSupport.Title')}
          />
        </MobileHeader>
      }
      contentSlot={<SupportTravelBody />}
    />
  )
})
