import styled, { css } from 'styled-components'
import type { HeaderLayout } from '../types'

export const RightSlot = styled.div<{
  layout: HeaderLayout
}>`
  display: flex;
  align-items: center;

  ${(props) => {
    switch (props.layout) {
      case 'search-result':
        return css`
          margin-left: 8px;
          margin-right: 16px;
        `
      case 'seat-map':
        return css`
          padding-top: 12px;
          align-items: flex-start;
          margin: 0 16px 0 8px;
        `
      case 'content-view':
        return css`
          display: none;
        `
      default:
        return css`
          margin-left: 8px;
          margin-right: 8px;
        `
    }
  }}
`
