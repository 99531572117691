export default function HyperlinkPWA() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5329 16.0185L17.4607 10.9237L10.986 10.9224V17.1171L15.9058 22.2153L16.5189 21.5947L18.6627 23.5021L16.6219 25.5509H15.1723L8 18.2261V8L18.7605 8.00056L25.933 15.315L25.9339 16.7368L23.9824 18.6351L21.9196 16.6282L22.5329 16.0185Z"
        fill="currentColor"
      />
      <path
        d="M27.3782 18.449H28.8278L36 25.7736V36L25.2395 35.9994L18.067 28.6849L18.0662 27.2631L20.0175 25.3648L22.0803 27.3717L21.467 27.9814L26.5394 33.0761L33.014 33.0775V26.8828L28.0942 21.7845L27.2376 22.5484L25.2566 20.5252L27.3782 18.449Z"
        fill="currentColor"
      />
      <path
        d="M18.867 16.7653H16.6509V18.9342L25.8094 27.8976H28.0255V25.7287L18.867 16.7653Z"
        fill="currentColor"
      />
    </svg>
  )
}
