import { NavLink as NavLinkUnstyled } from 'react-router-dom'
import styled from 'styled-components'
import { withStyledText } from '@fiji/style/text-styles'

export const PreviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`

export const PreviewContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 992px;
`

export const TravelSupportHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;
`

export const TravelSupportHeaderTitle = styled.div`
  ${withStyledText};
`

export const NavLink = styled(NavLinkUnstyled)`
  text-decoration: none;
`
