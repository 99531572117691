import { css } from 'styled-components'
import { CONTAINER_HEIGHT } from './constants'

export const TRANSITION_CLASS = 'connection-status'
export const TRANSITION_DURATION = 300

export const transition = css`
  height: 0;
  overflow: hidden;
  transition: height ${TRANSITION_DURATION}ms linear;

  &.${TRANSITION_CLASS}-enter,
    &.${TRANSITION_CLASS}-appear,
    &.${TRANSITION_CLASS}-exit-done,
    &.${TRANSITION_CLASS}-exit {
    height: 0;
  }

  &.${TRANSITION_CLASS}-appear-done,
    &.${TRANSITION_CLASS}-enter-done,
    &.${TRANSITION_CLASS}-appear-active,
    &.${TRANSITION_CLASS}-enter-active,
    &.${TRANSITION_CLASS}-exit-active {
    height: ${CONTAINER_HEIGHT}px;
  }
`
