import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'
import { HorizontalDelimiter } from '../../../hotel-details-styled'
import type { HotelDetailsHeaderProps } from '../../types'
import { HeaderSticky } from './hotel-details-header-desktop-styled'
import { HotelTitleBlock } from './hotel-title-block'

type Props = HotelDetailsHeaderProps & {
  tabsSlot: ReactNode
}

export function HotelDetailsHeaderDesktop({ hotelDetails, tabsSlot, onClose }: Props) {
  return (
    <HeaderSticky data-tracking-id="segment-details-modal-header">
      <Block paddingHorizontal={32} paddingVertical={12}>
        <HotelTitleBlock hotelDetails={hotelDetails} onClose={onClose} />
      </Block>
      <HorizontalDelimiter />
      <Block paddingHorizontal={32} paddingTop={6}>
        {tabsSlot}
      </Block>
      <HorizontalDelimiter />
    </HeaderSticky>
  )
}
