import { useTranslation } from 'react-i18next'
import { AirTimeRangePicker } from '@etta/components/air-time-range-picker/air-time-range-picker'
import { Block } from '@etta/ui/block'
import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { InputButton } from '@etta/ui/input-button'
import { InputButtonGroup } from '@etta/ui/input-button-group'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { getTimeAriaLabel } from '@fiji/utils/get-time-aria-label'
import type { LayoutProps } from '../../types'
import { useCalendarTimeSection } from '../../use-calendar-time-section'

const i18Base = 'TripPlanner.BaseSearch'

export function CalendarTimeSectionMobile({
  flightType,
  departureDate,
  returnDate,
  timeRange,
  originPlace,
  destinationPlace,
  returnTimeRange,
  onDateChange,
  isDateRangeValid,
  onTimeChange,
  onReturnTimeRangeChange,
  highlightDates,
  isDepartureDatePickerDisabled,
  isDepartureTimeRangeDisabled,
  isReturnDatePickerDisabled,
  isReturnTimeRangeDisabled,
  validationErrors,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    datePicketModal,
    originTimeModal,
    returnTimeModal,
    isRoundTrip,
    convertedOriginDate,
    originDateLabel,
    originTimeString,
    originTimeLabel,
    originAriaLabel,
    convertedReturnDate,
    returnDateLabel,
    returnTimeString,
    returnTimeLabel,
    returnAriaLabel,
  } = useCalendarTimeSection({
    flightType,
    departureDate,
    returnDate,
    timeRange,
    returnTimeRange,
  })
  return (
    <>
      <Block paddingHorizontal={16} paddingVertical={6}>
        <InputButtonGroup
          aria-label="depart dates"
          errorMessage={validationErrors.departureDate}
          hasError={!!validationErrors.departureDate}>
          <InputButton
            disabled={isDepartureDatePickerDisabled}
            icon="calendarPWA"
            isFilled={!!departureDate}
            value={convertedOriginDate}
            aria-label={originAriaLabel}
            label={originDateLabel}
            onClick={datePicketModal.handleOpen}
            placeholder={t(i18Base + '.Input.Select')}
          />
          <InputButton
            disabled={isDepartureTimeRangeDisabled}
            value={originTimeString}
            icon="timePWA"
            isInteractive
            label={originTimeLabel}
            aria-label={getTimeAriaLabel({
              i18next: timeRange.i18next,
              customHours: timeRange.customTimeHours,
              startHours: timeRange.startTimeHours,
              endHours: timeRange.endTimeHours,
              value: originTimeString,
              label: originTimeLabel,
            })}
            isFilled={!!timeRange}
            onClick={originTimeModal.handleOpen}
          />
        </InputButtonGroup>
      </Block>

      {isRoundTrip && (
        <Block paddingHorizontal={16} paddingVertical={6}>
          <InputButtonGroup
            aria-label="arrive dates"
            errorMessage={validationErrors.returnDate}
            hasError={!!validationErrors.returnDate}>
            <InputButton
              disabled={isReturnDatePickerDisabled}
              icon="calendarPWA"
              isFilled={!!returnDate}
              value={isDateRangeValid() ? convertedReturnDate : null}
              label={returnDateLabel}
              aria-label={returnAriaLabel}
              onClick={datePicketModal.handleOpen}
              placeholder={t(i18Base + '.Input.Select')}
            />
            <InputButton
              disabled={isReturnTimeRangeDisabled}
              value={returnTimeString}
              icon="timePWA"
              isInteractive
              label={returnTimeLabel}
              aria-label={getTimeAriaLabel({
                i18next: returnTimeRange!.i18next,
                customHours: returnTimeRange!.customTimeHours,
                startHours: returnTimeRange!.startTimeHours,
                endHours: returnTimeRange!.endTimeHours,
                value: returnTimeString,
                label: returnTimeLabel,
              })}
              isFilled={!!returnTimeRange}
              onClick={returnTimeModal.handleOpen}
            />
          </InputButtonGroup>
        </Block>
      )}

      <AirTimeRangePicker
        isVisible={originTimeModal.isOpen}
        onClose={originTimeModal.handleClose}
        onSubmit={onTimeChange}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
        selectedTime={timeRange}
        date={departureDate}
      />

      {returnTimeRange && (
        <AirTimeRangePicker
          isVisible={returnTimeModal.isOpen}
          onClose={returnTimeModal.handleClose}
          onSubmit={onReturnTimeRangeChange}
          originPlace={destinationPlace}
          destinationPlace={originPlace}
          selectedTime={returnTimeRange}
          date={returnDate}
        />
      )}

      <DatePickerModal
        isEndDateSame
        date={departureDate}
        dateEnd={isDateRangeValid() && isRoundTrip ? returnDate : null}
        isRange={isRoundTrip}
        onSelect={onDateChange}
        isOpen={datePicketModal.isOpen}
        onClose={datePicketModal.handleClose}
        withConfirmOneWayButton={!isRoundTrip}
        withConfirmButton={flightType === FlightType.MultiCity}
        minDate={new Date()}
        highlightDates={highlightDates}
        isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
      />
    </>
  )
}
