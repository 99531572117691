import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { AppState } from '@fiji/store'
import { RoomType } from '@fiji/graphql/types'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates'
import { hasDailyRates } from '@fiji/utils/has-daily-rates'
import type { RoomDetailsType } from '../types'

type Params = {
  roomDetails: RoomDetailsType
  isUpcoming: boolean
  checkIn?: string | null
  checkOut?: string | null
}

const i18nBase = 'PostBooking.HotelDetails'

export function useRoomDetails({ roomDetails, checkIn, checkOut }: Params) {
  const { t } = useTranslation()
  const locale = useSelector((state: AppState) => state.user.locale)
  const roomType = roomDetails.roomType || RoomType.Unknown

  const roomDateCheckIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkIn)
  const roomDateCheckOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkOut)

  const titleAriaLabel = useMemo(
    () =>
      `${t(`${i18nBase}.Room`, { roomType })}. ${
        roomDetails.roomAverage &&
        formatMoney(roomDetails.roomAverage, {
          locale,
          hideDecimal: true,
        })
      } ${t(`${i18nBase}.AvgPerNight`)}`,
    [roomDetails.roomAverage, locale, t, roomType],
  )
  const title = t(`${i18nBase}.Room`, { roomType })

  const roomRateDetails = t(`${i18nBase}.RoomDetails`)
  const roomRateDetailsAriaLabel = t(i18nBase + '.Button', { label: roomRateDetails })

  const price = useMemo(
    () =>
      roomDetails.roomAverage &&
      formatMoney(roomDetails.roomAverage, {
        locale,
        hideDecimal: true,
      }),
    [locale, roomDetails.roomAverage],
  )

  const avgPerNightTranslation = t(`${i18nBase}.AvgPerNight`)

  const checkInTranslation = t(`${i18nBase}.CheckIn`)
  const checkOutTranslation = t(`${i18nBase}.CheckOut`)

  const totalNights = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )
  const showDifferingRatesLabel =
    totalNights > 1 && (roomDetails.dailyRates.length === 0 || !hasDailyRates(roomDetails.source))

  const differingRatesLabel = t(i18nBase + '.DifferentDailyRates')

  return {
    roomDateCheckIn,
    roomDateCheckOut,
    titleAriaLabel,
    roomRateDetails,
    title,
    price,
    avgPerNightTranslation,
    roomRateDetailsAriaLabel,
    checkInTranslation,
    checkOutTranslation,
    showDifferingRatesLabel,
    differingRatesLabel,
  }
}
