import type { MouseEvent, TouchEvent, MutableRefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { TooltipPlace, TooltipAlign } from './types'
import { getPosition } from './get-position'

type Args = {
  place: TooltipPlace
  align: TooltipAlign
  parentRef?: MutableRefObject<HTMLDivElement | null>
}

type ViewportPosition = {
  left: number
  top: number
}

type TooltipViewport = {
  isVisible: boolean
  position: ViewportPosition
}

const MAX_TOOLTIP_WIDTH_IN_PX = 100

export function useTooltip({ place: defaultPlace, align: defaultAlign, parentRef }: Args) {
  const [place, setPlace] = useState(defaultPlace)
  const [align, setAlign] = useState(defaultAlign)

  const ref = useRef<HTMLSpanElement>(null)
  const [viewport, setViewport] = useState<TooltipViewport>({
    isVisible: false,
    position: {
      left: 0,
      top: 0,
    },
  })

  const updateViewport = useCallback(
    (node: HTMLElement) => {
      const rect = node.getBoundingClientRect()

      if (parentRef) {
        const parentRect = parentRef.current?.getBoundingClientRect()
        if (!parentRect) {
          return
        }
        const elementRect = node.getBoundingClientRect()
        const topDiff = elementRect.top - parentRect.top

        if (topDiff < 100) {
          setPlace('bottom')
        }

        const rightDiff = elementRect.right - parentRect.right
        const leftDiff = elementRect.left - parentRect.left

        if (leftDiff < 135) {
          setAlign('left')
        }

        if (rightDiff > -135) {
          setAlign('right')
        }

        if (leftDiff > 135 && rightDiff < -135) {
          setAlign('center')
        }
      }

      const position = getPosition({
        place,
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      })
      setViewport({ isVisible: true, position })
    },
    [parentRef, place],
  )

  const handleEnter = (ev: MouseEvent<HTMLDivElement>) => {
    const node = ev.currentTarget as HTMLElement

    updateViewport(node)
  }
  const handleLeave = () => {
    setViewport({ isVisible: false, position: { left: 0, top: 0 } })
  }

  const handleTouch = (ev: TouchEvent<HTMLDivElement>) => {
    const node = ev.currentTarget as HTMLElement
    updateViewport(node)
  }

  useEffect(() => {
    if (!ref) {
      return
    }
    if (!ref.current) {
      return
    }

    const nextNode = ref?.current?.nextSibling
    // TODO: add wrapper for text via nextNode.nodeName -> #text

    if (!nextNode) {
      return
    }

    if (viewport.isVisible) {
      updateViewport(nextNode as HTMLElement)
    }
  }, [place, ref, updateViewport, viewport.isVisible])

  const calculateParentWidth = () => {
    const parentElement = ref.current?.parentElement
    if (!parentElement) {
      return MAX_TOOLTIP_WIDTH_IN_PX
    }
    const parentElementWidth = parentElement.offsetWidth
    const { paddingLeft, paddingRight, marginLeft, marginRight } = getComputedStyle(parentElement)
    const width = [paddingLeft, paddingRight, marginLeft, marginRight].reduce((acc, style) => {
      return acc - parseFloat(style)
    }, parentElementWidth)

    return width > 0 ? width : MAX_TOOLTIP_WIDTH_IN_PX
  }

  return {
    handleEnter,
    handleLeave,
    handleTouch,
    ref,
    viewport,
    maxWidth: calculateParentWidth(),
    place,
    align,
  }
}
