import styled from 'styled-components'
import { footnoteMedium, screenSize } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const Container = styled.div``

export const EmissionData = styled.div`
  width: 100%;
`

export const EmissionDescription = styled.span<{
  deviationLevel: EmissionsDeviationLevel
}>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.mainText
      : props.theme.colors.bodyText};

  ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less &&
    `
      background-color: ${props.theme.colors.background};
      border-radius: 4px;
      padding: 4px;
      @media (${screenSize.maxMobile}) {
        padding: 2px 6px;
         border-radius: 2px;
      }
    `}

  ${footnoteMedium};
`

export const Loader = styled.div`
  height: 24px;
  width: 168px;
  ${glowingFragment};
`
