import { useTranslation } from 'react-i18next'
import { PurchaseReservationErrorLayout } from './layout'

type Props = {
  onDone: () => void
  errorMsg?: string[] | null
}

const i18nBase = 'PurchaseReservationError.'
const i18nBaseAccessibility = 'Accessibility.'

export function PurchaseReservationError({ onDone, errorMsg }: Props) {
  const { t } = useTranslation()
  const titleText = t(i18nBase + 'Title')
  const messageText = t(i18nBase + 'Message')
  const messageSecondText = t(i18nBase + 'MessageSecond')
  const messageThirdText = t(i18nBase + 'MessageThird')
  const buttonText = t(i18nBase + 'SeeUpcomingTrips')
  const tapToSelectText = t(i18nBaseAccessibility + 'TapToSelect')
  const labelText = [
    titleText,
    messageText,
    messageSecondText,
    messageThirdText,
    `${buttonText} ${tapToSelectText}`,
  ].join(' ')

  return (
    <PurchaseReservationErrorLayout
      labelText={labelText}
      titleText={titleText}
      messageText={messageText}
      messageSecondText={messageSecondText}
      messageThirdText={messageThirdText}
      errorMsg={errorMsg}
      buttonText={buttonText}
      onDone={onDone}
    />
  )
}
