import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'

export function useGetChangeText(type: SegmentType, isPostBooking: boolean) {
  const { t } = useTranslation()
  const i18nBase = 'ActionButtons.'

  switch (type) {
    case SegmentType.Flight:
      const flightText = isPostBooking ? 'ModifyFlightButton' : 'ChangeFlightButton'
      return t(i18nBase + flightText)
    case SegmentType.Hotel:
      const hotelText = isPostBooking ? 'ModifyHotelButton' : 'ChangeHotelButton'
      return t(i18nBase + hotelText)
    case SegmentType.CarRental:
      const carText = isPostBooking ? 'ModifyCarRentalButton' : 'ChangeCarRentalButton'
      return t(i18nBase + carText)
    case SegmentType.CarService:
      const carServiceText = isPostBooking ? 'ModifyCarServiceButton' : 'ChangeCarServiceButton'
      return t(i18nBase + carServiceText)
    case SegmentType.Train:
      const trainText = isPostBooking ? 'ModifyTrainButton' : 'ChangeTrainButton'
      return t(i18nBase + trainText)
    default:
      return 'Change'
  }
}
