import { useTranslation } from 'react-i18next'
import { formatCardNumber } from '@fiji/utils/credit-card/format-card-number'
import type { CreditCardBrandType, Maybe, VirtualPay } from '@fiji/graphql/types'
import type { BillingError, ColorPalette } from '@fiji/types'
import type { VIRTUAL_PAY } from '@fiji/constants'
import type { CreditCardValidator, CardLayoutType, CardInformationColor } from '../types'
import { RowLayout } from './layout'

export type LayoutType = CardLayoutType

type Props = {
  cardNumber: string
  cardName: string
  expirationDate: string
  isCvvRequired: boolean
  cardVendor?: Maybe<CreditCardBrandType> | typeof VIRTUAL_PAY
  isChecked: boolean
  isEditable: boolean
  isSelectable: boolean
  onEdit?: () => void
  onChange: () => void
  editLabel?: string
  layout?: LayoutType
  cardInformationColor: CardInformationColor
  cardBackgroundColor?: keyof ColorPalette
  validator?: CreditCardValidator
  withPadding?: boolean
  virtualPay?: VirtualPay
  billingError?: BillingError
  withEditIcon?: boolean
  isDisabled: boolean
  titlePlaceholder?: string
  preferredCardStatus?: string[]
  isBlurred?: boolean
  withDeleteIcon?: boolean
}

export function Row({
  cardNumber,
  cardName,
  cardVendor,
  isCvvRequired,
  expirationDate,
  isEditable,
  onEdit,
  onChange,
  isChecked,
  isSelectable,
  editLabel,
  cardInformationColor,
  cardBackgroundColor,
  layout,
  validator,
  withPadding = true,
  virtualPay,
  billingError,
  withEditIcon = false,
  isDisabled,
  titlePlaceholder,
  preferredCardStatus,
  isBlurred,
  withDeleteIcon = false,
}: Props) {
  const { t } = useTranslation()
  const title =
    cardNumber && expirationDate
      ? `${formatCardNumber(cardNumber)}, ${t('CreditCardItem.Expiration')}: ${expirationDate}`
      : ''

  const { errors, values, onFieldChange } = validator || {}

  return (
    <RowLayout
      isDisabled={isDisabled}
      cardVendor={cardVendor}
      cardName={cardName}
      title={titlePlaceholder || title}
      expirationDate={expirationDate}
      CVVValue={values?.securityCode}
      CVVHelperText={errors?.securityCode}
      layout={layout}
      cardInformationColor={cardInformationColor}
      cardBackgroundColor={cardBackgroundColor}
      editLabel={editLabel}
      withPadding={withPadding}
      isChecked={isChecked}
      isSelectable={isSelectable}
      isEditable={isEditable}
      isVirtualPayRestricted={virtualPay?.isVirtualPayRestricted}
      isCvvRequired={isCvvRequired}
      onChange={onChange}
      onEdit={onEdit}
      onCVVFieldChange={onFieldChange}
      billingError={billingError}
      withEditIcon={withEditIcon}
      preferredCardStatus={preferredCardStatus}
      isBlurred={isBlurred}
      withDeleteIcon={withDeleteIcon}
    />
  )
}
