import { useTranslation } from 'react-i18next'
import { EmissionsDeviationLevel } from '@fiji/enums'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function useCarRentalDetailsEcoCheckModalQuestion({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return {
        question: t(i18nBase + '.HowDoWeKnowCarRentalAverageQuestion'),
        answer: t(i18nBase + '.HowDoWeKnowCarRentalAverageAnswer'),
      }
    case EmissionsDeviationLevel.Less:
      return {
        question: t(i18nBase + '.HowDoWeKnowCarRentalLowerQuestion'),
        answer: t(i18nBase + '.HowDoWeKnowCarRentalLowerAnswer'),
      }
    case EmissionsDeviationLevel.More: {
      return {
        question: t(i18nBase + '.HowDoWeKnowCarRentalHigherQuestion'),
        answer: t(i18nBase + '.HowDoWeKnowCarRentalHigherAnswer'),
      }
    }
  }
}
