import styled, { css } from 'styled-components'
import { subHeadStrong, footnoteStrong, footnoteMedium, subHeadMedium } from '@fiji/style'
import { CostContainer } from '../../payment-summary-styled-common'
import type { DueTodayProps, TextProps } from '../../types'

export const Text = styled.span<TextProps>`
  display: block;
  color: ${(p) => {
    if (!p.color) {
      return p.theme.colors.bodyText
    }

    return p.theme.colors[p.color]
  }};
  margin-left: ${(p) => p.marginLeft || 0}px;

  ${(p) => {
    if (p.withTextOverflow) {
      return `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 15px;
      `
    }

    return ''
  }}
  line-height: 19px;

  ${(p) => {
    if (p.isStrong) {
      return subHeadMedium
    }

    return footnoteMedium
  }}

  font-size: ${(p) => (p.isStrong ? '15px' : '13px')};
`

export const TripType = styled.span`
  line-height: 19px;
  color: ${(p) => p.theme.colors.mainText};
  ${footnoteStrong};
`

export const DueToday = styled(CostContainer)<DueTodayProps>`
  margin-top: 16px;

  ${({ isBorderVisible, theme }) =>
    isBorderVisible &&
    css`
      border-top: 1px solid ${theme.colors.borderLight};
      padding-top: 10px;
    `}

  ${Text} {
    line-height: 20px;
    color: ${(p) => p.theme.colors.mainText};
    ${subHeadStrong};
  }
`

export const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const FooterWrapper = styled.div`
  overflow: hidden;
  border-radius: 0 0 14px 14px;
`
