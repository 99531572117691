import { MediaLayout } from '@etta/ui/media-layout'
import { RtpBookTripAgainFailStateMobile } from './mobile'
import { RtpBookTripAgainFailStateDesktop } from './desktop'

export function RtpBookTripAgainFailStateLayout() {
  return (
    <MediaLayout
      mobileSlot={<RtpBookTripAgainFailStateMobile />}
      desktopSlot={<RtpBookTripAgainFailStateDesktop />}
    />
  )
}
