import { formatRate } from '@fiji/utils/money'
import { TripReviewDateType } from '@fiji/utils/dates'
import { getDifferenceInDays } from '@fiji/utils/dates/get-difference-in-days'
import type { SegmentDetails } from '../../types'
import type { HotelRoom } from '../../../../types'
import { getDatesLabel, getRoomTypeLabel } from './utils'

export const getHotelRooms = (
  segments: SegmentDetails['hotelDetails']['segments'],
  getTranslation: (label: string) => string,
  isPayment?: boolean,
) => {
  if (!segments) {
    return []
  }

  return segments?.map((segment) => {
    const nightsCount =
      getDifferenceInDays(new Date(segment.checkOut), new Date(segment.checkIn)) || 1

    const dates = getDatesLabel(
      segment.checkIn,
      segment.checkOut,
      TripReviewDateType.DayAndDate,
      ' - ',
    )
    const roomType = getRoomTypeLabel(nightsCount, segment.roomType, ' x ')
    const { mainCost, secondCost } = formatRate(segment.totalCostRate)

    const hotelRoom: HotelRoom = {
      hotelName: segment.name,
      dates: dates,
      nightsCount,
      roomType: roomType,
      // TODO
      // confirmation,
      payment: {
        title: isPayment
          ? getTranslation('SegmentsHeadline.Payment')
          : getTranslation('PaymentDueAtHotel'),
        mainTotal: mainCost,
        secondaryTotal: secondCost,
      },
    }

    return hotelRoom
  })
}
