import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { SkeletonContainer } from './car-rental-price-details-styled'

export function CarRentalPriceDetailsSkeleton() {
  return (
    <SkeletonContainer>
      <Block paddingHorizontal={16} paddingVertical={20}>
        <Skeleton width={101} height={17}>
          <rect width="100%" height="16px" />
        </Skeleton>
      </Block>
      <Separator />
      <Block paddingHorizontal={16} paddingTop={30} paddingBottom={20}>
        <Skeleton width={664} height={146}>
          <rect width="17%" height="12px" />
          <rect y={26} width="17%" height="12px" />
          <rect y={52} width="17%" height="12px" />
          <rect y={78} width="17%" height="12px" />

          <rect x="95%" width="5%" height="12px" />
          <rect x="95%" y={26} width="5%" height="12px" />
          <rect x="95%" y={52} width="5%" height="12px" />
          <rect x="95%" y={78} width="5%" height="12px" />

          <rect y={130} width="12%" height="16px" />
          <rect x="93%" y={130} width="7%" height="16px" />
        </Skeleton>
      </Block>
    </SkeletonContainer>
  )
}
