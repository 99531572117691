import { Block } from '@etta/ui/block'
import { SafetyCheckHeader } from '../../safety-check-header'
import { GeoSafeScores } from '../../geo-safe-scores'
import { ConcernScoreList } from '../../concern-score-list'
import { CovidInformation } from '../../covid-information'
import type { LayoutProps } from '../types'
import {
  Container,
  Header,
  Main,
  LeftSection,
  RightSection,
} from './hotel-safety-check-desktop-styled'

export function HotelSafetyCheckDesktop({
  hotelName,
  location,
  scores,
  concernList,
  covidStatistic,
  isLoading,
}: LayoutProps) {
  return (
    <Container>
      <Header>
        <SafetyCheckHeader />
      </Header>
      <Main>
        <LeftSection>
          <GeoSafeScores
            hotelName={hotelName}
            location={location}
            scores={scores}
            isLoading={isLoading}
          />
        </LeftSection>
        <RightSection>
          <ConcernScoreList concernList={concernList} isLoading={isLoading} />
        </RightSection>
      </Main>
      <Block marginVertical={24}>
        <CovidInformation covidStatistic={covidStatistic} isLoading={isLoading} />
      </Block>
    </Container>
  )
}
