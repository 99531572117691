import { Inject, Action } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { GetTripService } from '@etta/modules/review-trip/interface/services/get-trip.service'
import { PurchaseInfoActions } from '@etta/modules/booking/interface/purchase-info/actions/purchase-info.actions'
import { UnusedTicketOptService } from '../services/unused-ticket-opt.service'
import { UnusedTicketOptStore } from '../stores/unused-ticket-opt.store'

@Action()
export class UnusedTicketOptAction {
  constructor(
    @Inject()
    private readonly unusedTicketOptService: UnusedTicketOptService,

    @Inject()
    private readonly unusedTicketOptStore: UnusedTicketOptStore,

    @Inject()
    private readonly getTripService: GetTripService,

    @Inject()
    private readonly purchaseInfoActions: PurchaseInfoActions,
  ) {}

  async applyUnusedTicket() {
    this.unusedTicketOptStore.setIsLoading(true)
    const { success } = await this.unusedTicketOptService.applyUnusedTicket()

    if (success) {
      await this.updateTrip()
    }

    this.unusedTicketOptStore.setIsLoading(false)
  }

  async removeUnusedTicket() {
    this.unusedTicketOptStore.setIsLoading(true)
    const { success } = await this.unusedTicketOptService.removeUnusedTicket()

    if (success) {
      await this.updateTrip()
    }

    this.unusedTicketOptStore.setIsLoading(false)
  }

  private async updateTrip() {
    await this.getTripService.updateTrip()
    await this.purchaseInfoActions.fetchBillingFromPurchaseInfo()
  }
}
