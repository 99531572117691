import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

const savedCardsGroupTitleStyle = css`
  background-color: ${(p) => p.theme.colors.plum};
  color: ${(p) => p.theme.colors.mainText2};
`

const defaultGroupTitleStyle = css`
  background-color: ${(p) => p.theme.colors.background};
  color: ${(p) => p.theme.colors.mainText};
`

export const GroupTitleBlock = styled.div<{ isSavedCards?: boolean }>`
  padding: 4px 16px;

  ${(p) => (p.isSavedCards ? savedCardsGroupTitleStyle : defaultGroupTitleStyle)}
`

export const StyledItemBlock = styled(Block)`
  cursor: pointer;
`
