import styled, { css } from 'styled-components'
import { footnoteStrong, bodyLarge, captionMedium } from '@fiji/style'
import { screenSize } from '@fiji/style/screen-sizes'

export const Container = styled.div`
  width: 100%;
`

const mainColumn = css`
  flex-direction: column;
  gap: 10px;
`
export const Main = styled.div<{ isColumn: boolean }>`
  background: ${(p) => p.theme.colors.white};
  padding: 14px 20px;
  display: flex;
  ${({ isColumn }) => isColumn && mainColumn}
  @media (${screenSize.maxMobileSmall}) {
    button {
      min-width: 11rem;
    }
  }
`

const costContainerTwoButtons = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CostContainer = styled.div<{ isTwoButtons: boolean }>`
  ${({ isTwoButtons }) => isTwoButtons && costContainerTwoButtons}
`

const rightBlockTwoButtons = css`
  display: flex;
  gap: 12px;
`
const rightBlock = css`
  margin-left: auto;
  padding: 2px 0;
`
export const RightBlock = styled.div<{ isTwoButtons: boolean }>`
  ${({ isTwoButtons }) => (isTwoButtons ? rightBlockTwoButtons : rightBlock)}
`

export const Text = styled.div`
  flex: 1;
  color: ${(p) => p.theme.colors.mainText};
`

const labelTwoButtons = css`
  display: flex;
  align-items: center;
`

export const Label = styled.div<{ isTwoButtons: boolean }>`
  ${footnoteStrong};
  line-height: 1.9rem;
  ${({ isTwoButtons }) => isTwoButtons && labelTwoButtons}
`

export const Cost = styled.div`
  ${bodyLarge};
  line-height: 2.8rem;
`

export const Extras = styled.div`
  ${captionMedium}
  line-height: 1.9rem;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.bodyText1};
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 5px 20px 8px;
`
