import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'
import { IndexLabelRow } from './index-label-desktop.styled'

const i18Base = 'TripPlanner.BaseSearch'

export function IndexLabelDesktop({ number }: Props) {
  const { t } = useTranslation()
  const titleText = t(i18Base + '.Input.FlightNumber', { number })

  return (
    <IndexLabelRow>
      <Text
        textTransform="uppercase"
        variant="captionStrong"
        color="mainText"
        aria-label={titleText}>
        {titleText}
      </Text>
    </IndexLabelRow>
  )
}
