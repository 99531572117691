import type { CreditCard, PaymentDetails } from '@fiji/graphql/types'
import { VIRTUAL_PAY } from '@fiji/constants'

type Props = {
  id?: string | null
  cvv?: string | null | false
  singleUseCreditCard?: Partial<CreditCard>
}

export function getCreditCardStructure({
  id,
  cvv,
  singleUseCreditCard,
}: Props): PaymentDetails | null {
  if (!id) {
    return null
  }

  if (id === VIRTUAL_PAY) {
    return {
      isVirtualPaymentSelected: true,
    }
  }

  const paymentDetails: PaymentDetails = {}
  if (singleUseCreditCard) {
    const { brandType, cardNumber, expirationDate, securityCode, label, name } = singleUseCreditCard
    if (cardNumber && name && expirationDate && brandType) {
      paymentDetails.creditCardData = {
        brandType,
        cardNumber,
        securityCode,
        label,
        name,
        expirationDate,
      }
    }
  }
  if (singleUseCreditCard?.billingAddress) {
    const {
      countryCode,
      postalCode,
      stateCode,
      city,
      street1,
      street2,
    } = singleUseCreditCard.billingAddress
    if (countryCode && postalCode && stateCode && city && street1) {
      paymentDetails.billingAddress = {
        countryCode,
        postalCode,
        stateCode,
        city,
        street1,
        street2: street2 ?? '',
      }
    }
  }
  return {
    creditCard: {
      id,
      cvv: cvv || null,
    },
    creditCardData: paymentDetails.creditCardData,
    billingAddress: paymentDetails.billingAddress,
  }
}
