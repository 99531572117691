import styled from 'styled-components'
import { screenSize } from '@fiji/style'
import { Icon as EttaIcon } from '@etta/ui/icon'
import { TextField as EttaTextField } from '@etta/ui/text-field'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px 0;
`

export const ChevronValueIcon = styled(EttaIcon)`
  margin-top: 3px;
  margin-right: 6px;
  &:first-child {
    margin-left: -48px;
  }
  @media (${screenSize.maxMobile}) {
    margin-right: 3px;
  }
`

export const TextField = styled(EttaTextField)`
  margin-top: 14px;
  padding: 0 16px 16px;
`

export const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 14px 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  @media (${screenSize.maxMobile}) {
    padding: 12px 30px 12px 24px;
    margin-left: 43px;
  }
`

export const ValueText = styled.div`
  line-height: 27px;
`

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  padding: 16px;
`
