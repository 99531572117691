/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { usePopFromHistory } from './use-pop-from-history'
import { usePushToHistory } from './use-push-to-history'
import { getModalIds } from './get-modal-ids'

type Args = {
  isOpen: boolean
  onClose: (e: boolean) => void
}

export function useCloseModalByBrowserBackButton({ isOpen, onClose }: Args) {
  const currentId = useRef(uuid()).current

  const modalIds = getModalIds()
  const isInState = modalIds.includes(currentId)

  const push = usePushToHistory(currentId)
  const pop = usePopFromHistory({
    isOpen,
    isInState,
    currentId,
    onClose,
  })

  useEffect(() => {
    if (!isOpen && isInState) {
      pop()
    }
    if (isOpen && !isInState) {
      push()
    }
  }, [isOpen])
}
