import { Service } from '@etta/di'
import { launchMode, screenMatcher } from '@fiji/modes'
import type { UserEntity } from '../../core/entities'

@Service()
export class UserTrackingActionsService {
  initUserActions(user: UserEntity) {
    if (!user.profile) {
      return
    }

    const screenType = screenMatcher.getScreenType()

    window.heap?.identify?.(user.profile.identityHash)
    window.heap?.clearEventProperties?.()
    window.heap?.addEventProperties?.({
      partnerID: user.partnerId,
      siteID: user.companyId,
      siteName: user.profile?.site?.name,
      screenType,
      launchMode,
    })
  }
}
