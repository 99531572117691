export default function PaidSeatSelectionIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1652 6.5C14.2165 6.5 14.3244 8.56359 14.3301 8.78083L14.3304 14.2458L14.3372 14.2635L16.176 19.6273H26.8488C28.1961 19.6273 28.2859 20.9627 28.2919 21.1407L28.2923 21.1602C28.2923 22.6072 27.0349 22.7037 26.8672 22.7101L17.2328 22.7106L18.3188 25.8789L24.5217 25.3029C24.6025 25.2951 24.6778 25.2944 24.7509 25.2971C24.8433 25.2818 24.914 25.2772 24.9436 25.2759L24.9587 25.2753H32.3813C32.4621 25.2753 32.5221 25.2961 32.5942 25.305C32.7866 25.3142 32.9184 25.3513 32.9184 25.3513C34.2404 25.7272 33.995 27.0517 33.9591 27.2158L33.9553 27.2325L33.0053 30.9956C32.7669 31.9411 32.1126 32.1203 31.6705 32.1266L31.5557 32.1244L25.8695 32.1255V36.0646H31.7976C32.7968 36.0646 32.8737 37.0441 32.8796 37.1948L32.8801 37.2142V37.3507C32.8801 38.4116 31.9578 38.4932 31.8159 38.4995L17.0829 38.5C16.0835 38.5 16.0066 37.5207 16.0007 37.3701L16.0002 37.2142C16.0002 36.153 16.9228 36.0714 17.0647 36.0651L23.0111 36.0646V32.1255H17.4556C16.5386 32.1255 16.0128 31.7125 15.7079 31.2373C15.4624 30.9166 15.3341 30.6049 15.3024 30.5224L15.2946 30.5013L10.2677 15.837C10.2123 15.6756 10.1767 15.5244 10.1504 15.3781C10.0237 14.9885 10.0037 14.6495 10.0006 14.5606L10 8.79926C10 6.5 12.1652 6.5 12.1652 6.5Z"
        fill="currentColor"
      />
      <path
        d="M28 8.375C31.3827 8.375 34.125 11.1172 34.125 14.5C34.125 17.8827 31.3827 20.625 28 20.625C24.6172 20.625 21.875 17.8827 21.875 14.5C21.875 11.1172 24.6172 8.375 28 8.375Z"
        fill="#F44901"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 14.5C21 10.634 24.134 7.5 28 7.5C31.866 7.5 35 10.634 35 14.5C35 18.366 31.866 21.5 28 21.5C24.134 21.5 21 18.366 21 14.5ZM28 9.25C25.1005 9.25 22.75 11.6005 22.75 14.5C22.75 17.3995 25.1005 19.75 28 19.75C30.8995 19.75 33.25 17.3995 33.25 14.5C33.25 11.6005 30.8995 9.25 28 9.25Z"
        fill="white"
      />
    </svg>
  )
}
