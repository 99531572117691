import { useTranslation } from 'react-i18next'
import { TravelerExcerpt } from '@etta/components/traveler-excerpt/traveler'
import { CityBackground } from '@etta/components/city-background/city-background'
import type { Rate, TripCostSummary } from '@fiji/graphql/types'
import { Container, Wrap } from './rtp-price-summary-styled'
import { TripHeader } from './trip-header'
import { PayableSections } from './payable-sections'
import { FooterCheckout } from './footer-checkout'
import { RulesAndRestrictionsButton } from './rules-and-restrictions-button'

export type RTPPriceSummaryProps = {
  tripName: string
  tripDates: string
  tripCost: TripCostSummary
  totalCost: Rate
  isEditable: boolean
  isHoldTripAllowed: boolean
  isDisabled?: boolean
  cityImageUrl?: string | null
  onEdit: () => void
  onCheckout: () => void
  onHold: () => void
  onOpenTripCostSummary: () => void
  warningSlot: JSX.Element | null
  warningMessages: string[]
  onOpenRulesAndRestrictions: () => void
  showRulesAndRestrictions: boolean
}

export function RTPPriceSummary({
  tripName,
  tripDates,
  tripCost,
  totalCost,
  isEditable,
  isDisabled,
  cityImageUrl,
  onEdit,
  onCheckout,
  onOpenTripCostSummary,
  onHold,
  isHoldTripAllowed,
  warningSlot,
  warningMessages,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
}: RTPPriceSummaryProps) {
  const i18Base = 'ReviewTrip'
  const { t } = useTranslation()

  return (
    <Container aria-label={t(`${i18Base}.ReviewTripSummary`)}>
      <CityBackground cityImageUrl={cityImageUrl} />
      <TripHeader
        tripName={tripName}
        tripDates={tripDates}
        isDatesWarning={!!warningSlot}
        onEdit={onEdit}
        isEditable={isEditable}
      />
      {warningSlot && warningSlot}
      <Wrap>
        <TravelerExcerpt hasBorderTop />
        <PayableSections tripCost={tripCost} />
        {showRulesAndRestrictions && (
          <RulesAndRestrictionsButton onOpenRulesAndRestrictions={onOpenRulesAndRestrictions} />
        )}
        <FooterCheckout
          onHold={onHold}
          isHoldTripAllowed={isHoldTripAllowed}
          totalCost={totalCost}
          onCheckout={onCheckout}
          onOpenTripCostSummary={onOpenTripCostSummary}
          isDisabled={isDisabled}
          warningMessages={warningMessages}
        />
      </Wrap>
    </Container>
  )
}
