import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router'
import { useHistoryContext } from '@etta/modules/app/interface/use-history-context'

export const HistoryParamsAdapter = observer(function HistoryParamsAdapter() {
  const { historyStore } = useHistoryContext()
  const data = useRouteMatch(historyStore.currentPath || '')

  if (data?.params) {
    historyStore.updateParams(data.params)
  }

  return null
})
