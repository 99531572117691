import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 36px auto 0;
`

export const ContainerPage = styled.div<{ isMaxLaptop?: boolean }>`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${({ isMaxLaptop }) =>
    isMaxLaptop &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}
`
