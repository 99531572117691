import { useTranslation } from 'react-i18next'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { ChangeTripNotAllowedModal } from '@etta/components/change-trip-not-allowed-modal'
import { DetailsContainer } from '../../../trip-car-rental-styled'
import { Amenities } from '../../../amenities'
import { Location } from '../../../location'
import type { TripCarRentalLayoutProps } from '../../types'

const i18nBase = 'PostBooking.CarRentalDetails'

export function TripCarRentalMobile({
  carRental,
  onClose,
  tripType,
  priceSlot,
  ecoCheckSlot,
  tripCarTravelerSlot,
  actionButtonsSlot,
  headlineSlot,
  handleViewSupport,
  toggleModifyModal,
}: TripCarRentalLayoutProps) {
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const { t } = useTranslation()
  const backgroundColor = getTripDetailsSegmentBackground({
    tripStatus: tripType,
    segmentStatus: carRental.status,
  })

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor={backgroundColor}
            animation="opacity"
            leftSlot={<Header.BackButton color="mainText2" onClick={onClose} />}>
            <Header.Title title={carRental.vendor?.name || ''} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <DetailsContainer color={backgroundColor}>
          {headlineSlot}
          <Amenities carRental={carRental} />
        </DetailsContainer>
        <div ref={anchorRef} />
        <Block paddingHorizontal={16} marginTop={-14}>
          {tripCarTravelerSlot}
          <Block marginTop={16}>
            <Location carRental={carRental} />
          </Block>
          {ecoCheckSlot && ecoCheckSlot}
          {priceSlot}
        </Block>
        <Block marginTop={28} marginBottom={32}>
          {actionButtonsSlot}
        </Block>
      </Modal.Body>

      <ChangeTripNotAllowedModal
        isOpen={toggleModifyModal.isOpen}
        onClose={toggleModifyModal.handleClose}
        onViewSupport={handleViewSupport}
        modalTitle={t(`${i18nBase}.Modify`)}
      />
    </>
  )
}
