import type { SelectedFiltersValueObject } from '../../core/value-objects/selected-filters.value-object'

export const DEFAULT_DISTANCE_VALUE = 5

export const DEFAULT_HOTEL_FILTERS: SelectedFiltersValueObject = {
  amenityIds: [],
  brandIds: [],
  distance: DEFAULT_DISTANCE_VALUE,
  groupId: '',
  hotelName: '',
  initialDistance: DEFAULT_DISTANCE_VALUE,
  starRatings: [],
}
