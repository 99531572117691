import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../../types'
import {
  EquivalencesContainer,
  EquivalencesContainerWrapper,
} from './eco-check-equivalences-desktop-styled'
import { EcoCheckEquivalenceCard } from './eco-check-equivalence-card-desktop'
import { EcoCheckEquivalenceCardNew } from './eco-check-equivalence-card-desktop-new'

export function EcoCheckEquivalencesDesktop({ equivalences }: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()

  if (isNewEmissionsBrandingEnabled) {
    return (
      <EquivalencesContainerWrapper>
        <EquivalencesContainer>
          {equivalences.map((equivalence, index) => (
            <EcoCheckEquivalenceCardNew {...equivalence} withMarginRight={index === 0} />
          ))}
        </EquivalencesContainer>
      </EquivalencesContainerWrapper>
    )
  }
  return (
    <EquivalencesContainerWrapper>
      <EquivalencesContainer>
        {equivalences.map((equivalence, index) => (
          <EcoCheckEquivalenceCard {...equivalence} withMarginRight={index === 0} />
        ))}
      </EquivalencesContainer>
    </EquivalencesContainerWrapper>
  )
}
