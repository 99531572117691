import { UnusedTicketLabel } from '@etta/screens/review-trip-page/flight-details-modal/unused-ticket-label'
import { CustomActions } from '@etta/components/action-buttons/custom-actions'
import { FlightDetailsLegend } from '@etta/components/flight-details-legend/flight-details-legend'
import { FlightDetailsEcoCheck } from '@etta/components/flight-details-eco-check/flight-details-eco-check'
import { SegmentType } from '@fiji/graphql/types'
import { Modal } from '@etta/ui/modal'
import { SeatMapRTPModal } from '@etta/screens/review-trip-page/seat-map-rtp-modal'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { FlightDetailsHeader } from '../../flight-details-header'
import { FlightDetailsFares } from '../../flight-details-fares'
import { FlightSafetyCheck } from '../../flight-safety-check'
import { FlightDetailsPrice } from '../../flight-details-price'
import { SeatMapsModal } from '../../seat-maps-modal'
import type { LayoutProps } from '../../types'
import {
  Container,
  CustomActionsContainer,
  FlightDetailsLegendContainer,
  Section,
} from './flight-details-modal-desktop-styled'
import { useFlightDetailsModalDesktop } from './use-flight-details-modal-desktop'

export function FlightDetailsModalDesktop({
  flight,
  flightSafetyCheckDetails,
  adaptiveFlight,
  flightSafetyChecks,
  flightCostSegment,
  isFlightFareAttributesError,
  isFlightSafetyCheckEnabled,
  isFlightFareAttributesLoading,
  onClose,
  onBaggageOpenClick,
  onDetailsClick,
  refetchFlightFareAttributes,
  unusedTicket,
  itineraryId,
  flightEmission,
  shouldShowAirportChangeBadge,
  flightLegPosition = 1,
  baggageInfo,
}: LayoutProps) {
  const { featureFlags } = useFeatureFlags()
  const {
    headerTitle,
    flightLabels,
    seatStateStatus,
    resetSeatStateStatus,
    rtpSeatModalLvl2Toggle,
    handleSeatMapOpenClick,
    isDesktopUnusedTicketsEnabled,
  } = useFlightDetailsModalDesktop({
    adaptiveFlight,
    flight,
    itineraryId,
    onClose,
    flightLegPosition,
  })

  const fareDetails = (
    <>
      <FlightDetailsLegendContainer>
        <FlightDetailsLegend
          flight={adaptiveFlight}
          withToggle
          flightLegSegments={flight.segments}
          shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        />
      </FlightDetailsLegendContainer>
      <Container>
        {isDesktopUnusedTicketsEnabled && unusedTicket && (
          <Section>
            <UnusedTicketLabel unusedTicketRate={unusedTicket?.original} />
          </Section>
        )}
        <FlightDetailsFares
          onBaggageModalOpen={onBaggageOpenClick}
          flight={flight}
          onSeatMapOpen={handleSeatMapOpenClick}
          baggageInfo={baggageInfo}
        />
        {isFlightSafetyCheckEnabled && (
          <Section>
            <FlightSafetyCheck
              flightSafetyChecks={flightSafetyChecks}
              flightSafetyCheckDetails={flightSafetyCheckDetails}
              refetchSafetyChecks={refetchFlightFareAttributes}
              isSafetyChecksLoading={isFlightFareAttributesLoading}
              isSafetyChecksError={isFlightFareAttributesError}
            />
          </Section>
        )}
        {featureFlags.isSustainabilityEnabled && (
          <Section>
            <FlightDetailsEcoCheck {...flightEmission} />
          </Section>
        )}
        <Section>
          <FlightDetailsPrice
            flightDirection={adaptiveFlight.headerTitle}
            flightRate={flightCostSegment?.base!}
            totalRate={flightCostSegment?.total}
            feesAndTaxesRate={flightCostSegment?.feesAndTaxes!}
            serviceFeeRate={flightCostSegment?.serviceFee}
            transactionFeeRate={flightCostSegment?.transactionFee!}
            unusedTicketRate={isDesktopUnusedTicketsEnabled ? unusedTicket?.original : undefined}
            unusedTicketAmount={
              isDesktopUnusedTicketsEnabled ? unusedTicket?.exchangedTicket : undefined
            }
            unusedTicketForfeiture={
              isDesktopUnusedTicketsEnabled ? unusedTicket?.residual : undefined
            }
          />
        </Section>
        <CustomActionsContainer>
          <CustomActions
            onDetailsClick={onDetailsClick}
            type={SegmentType.Flight}
            isSeparatorDisabled
          />
        </CustomActionsContainer>
      </Container>
    </>
  )

  return (
    <>
      <SeatMapsModal closeModal={resetSeatStateStatus} status={seatStateStatus} />
      <Modal.Header withBorder isMobile>
        <FlightDetailsHeader
          title={headerTitle}
          flightLabels={flightLabels}
          airlinesLogos={adaptiveFlight.airlineLogos.square || []}
          flightStopsLabel={adaptiveFlight.flightStopsLabel}
          onClose={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        {fareDetails}
        <SeatMapRTPModal
          isVisible={rtpSeatModalLvl2Toggle.isOpen}
          onClose={rtpSeatModalLvl2Toggle.handleClose}
        />
      </Modal.Body>
    </>
  )
}
