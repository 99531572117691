import styled, { css } from 'styled-components'

import type { IconSizes } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'

export const StartSlotBlock = styled(Block)<{ iconSize: IconSizes }>`
  ${({ iconSize }) => {
    switch (iconSize) {
      case 'medium':
        return css`
          margin: 0 10px;
        `
      default:
        return css`
          margin: 0 14px;
        `
    }
  }}
`
