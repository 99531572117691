import type { HotelAboutProps } from './types'
import { HotelAboutLayout } from './layout'
import { useHotelAbout } from './use-hotel-about'

export function HotelAbout({
  hotelDetails,
  areHotelAboutDatesDisplayed,
  isHotelDescriptionDisplayed,
}: HotelAboutProps) {
  const { dateRangeTitle } = useHotelAbout({
    checkIn: hotelDetails.checkIn,
    checkOut: hotelDetails.checkOut,
  })

  return (
    <HotelAboutLayout
      hotelDetails={hotelDetails}
      dateRangeTitle={dateRangeTitle}
      areDatesDisplayed={areHotelAboutDatesDisplayed}
      isHotelDescriptionDisplayed={isHotelDescriptionDisplayed}
    />
  )
}
