import { useTranslation, Trans } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { RailCollectTicketDisabled } from '@etta/components/rail-collect-ticket-disabled'
import { Container } from './collect-view-traveler-information-styled'

const i18nBase = 'RailDeliveryOption.'

type Props = {
  onModalOpen: () => void
  stationName?: string
  isStationTicketMachineDisabled: boolean
}

export function CollectViewTravelerInformation({
  onModalOpen,
  isStationTicketMachineDisabled,
  stationName,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Container paddingHorizontal={16} paddingVertical={12} isFlexBlock>
        <Block marginRight={14}>
          <Icon name="kioskPWA" size="medium" />
        </Block>
        <Block>
          <Text variant="footnoteMedium" color="mainText">
            <Trans i18nKey={`${i18nBase}CollectFromAny`} />
          </Text>
          <Block marginTop={4}>
            <Link size="small" onClick={onModalOpen}>
              {t(i18nBase + 'LearnHow')}
            </Link>
          </Block>
        </Block>
      </Container>
      {isStationTicketMachineDisabled && <RailCollectTicketDisabled stationName={stationName} />}
    </>
  )
}
