import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { Vendor } from '@fiji/graphql/types'
import { LogoImage } from './car-rental-vendor-locations-styled'

type Props = {
  vendors: Vendor[]
}

const MAXIMUM_VENDORS_SIZE = 3

export function CarRentalVendorLocations({ vendors }: Props) {
  const { t } = useTranslation()
  const leftVendorsLength = vendors.length - MAXIMUM_VENDORS_SIZE
  const shownVendors = useMemo(() => {
    return R.pipe(
      (vendors: Vendor[]) => R.sortBy(R.compose(R.toLower, R.prop('name')))(vendors),
      (vendors: Vendor[]) => R.slice(0, MAXIMUM_VENDORS_SIZE)(vendors),
    )(vendors || [])
  }, [vendors])

  return (
    <>
      {shownVendors.map(
        (vendor, key) =>
          vendor?.imageUrl && (
            <LogoImage
              src={vendor.imageUrl}
              alt={vendor.name || t('Accessibility.CarRental.VendorLogoAlt')}
              key={key}
            />
          ),
      )}
      {leftVendorsLength > 0 && (
        <Block marginLeft={4}>
          <Text variant="linkSmall" color="bodyText">
            {t('TripPlanner.Place.MoreVendors', { count: leftVendorsLength })}
          </Text>
        </Block>
      )}
    </>
  )
}
