import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Footer = styled.div`
  padding-left: 16px;
  align-items: center;
`
