import i18next from 'i18next'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { Location, CarRentalFuelType } from '@fiji/graphql/types'
import type { CarRentalAmenities } from '../types'

const i18nBase = 'CarRentalModal.Amenities.'

export function getAmenities(carRental: CarRentalSegmentEntity): CarRentalAmenities[] {
  const amenities: CarRentalAmenities[] = [
    {
      name: 'carHybridPWA' as const,
      value: i18next.t(i18nBase + 'HybridFuel'),
      isVisible: carRental.carDetails.fuel === CarRentalFuelType.Hybrid,
    },
    {
      name: 'peoplePWA' as const,
      value: `${carRental.carDetails.capacity} ${i18next.t(i18nBase + 'Seats')}`,
      isVisible: true,
    },
    {
      name: 'bagPWA' as const,
      value: `${carRental.carDetails.luggage} ${i18next.t(i18nBase + 'Bags')}`,
      isVisible: true,
    },
    {
      name: 'transmissionPWA' as const,
      value: `${carRental.carDetails.transmission?.toLocaleLowerCase()}`,
      isVisible: true,
    },
    // TODO: Find a way how we can get this data (count of doors) from API or remove this mock data
    // { name: 'doorPWA', value: `4 ${i18next.t(i18nBase + 'Doors')}` },
    {
      name: 'unlimitedMileage' as const,
      value: i18next.t(i18nBase + 'UnlimitedMileage'),
      isVisible: carRental.carCosts.isMileageUnlimited,
    },
    {
      name: 'snowflakePWA' as const,
      value: i18next.t(i18nBase + 'AirConditioning'),
      isVisible: carRental.carDetails.isAirConditioned,
    },
    {
      name: 'terminalPWA' as const,
      value: i18next.t(i18nBase + 'InTerminal'),
      isVisible: carRental.carLocationType?.pickup.type === Location.Airport,
    },
  ]
    .filter((amenity) => amenity.isVisible)
    .map(({ name, value }) => ({ name, value }))

  return amenities
}
