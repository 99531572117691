import { isSameDay } from '@fiji/utils/dates/is-same-day'
import { isSameMonth } from '@fiji/utils/dates/is-same-month'
import { isSameYear } from '@fiji/utils/dates/is-same-year'
import { dateFormat } from '@fiji/utils/dates/date-format'

export function getSubtitleDate({
  originDate,
  destinationDate,
}: {
  originDate: Date | undefined
  destinationDate: Date | undefined
}) {
  if (!originDate || !destinationDate) {
    return '-'
  }

  if (
    isSameDay(originDate, destinationDate) &&
    isSameMonth(originDate, destinationDate) &&
    isSameYear(originDate, destinationDate)
  ) {
    return dateFormat(originDate, 'LLL d, y')
  }
  if (isSameMonth(originDate, destinationDate)) {
    const origin = dateFormat(originDate, 'LLL d')
    const destination = dateFormat(destinationDate, 'd, y')
    return [origin, destination].join(' - ')
  }

  const origin = dateFormat(originDate, 'LLL d')
  const destination = dateFormat(destinationDate, 'LLL d, y')

  return [origin, destination].join(' - ')
}
