import type { ReactNode } from 'react'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { useMapLocations } from './use-map-locations'
import type { Location } from './types'

type Props = {
  children: ReactNode
  locations: Location[]
  center: Location
  distance?: string | number
  onBoundsLeave: (newCenter: Location) => void
  onBoundsReturn: () => void
  onAnnotationDeselect?: () => void
}

export function MapLocations({
  children,
  center,
  distance,
  onBoundsReturn,
  onBoundsLeave,
  onAnnotationDeselect,
  locations,
}: Props) {
  const { defaultZoom, onMapLoaded } = useMapLocations({
    distance,
    onBoundsLeave,
    onBoundsReturn,
    locations,
    center,
  })

  return (
    <EttaMap
      onAnnotationDeselect={onAnnotationDeselect}
      defaultZoom={defaultZoom}
      center={center}
      onMapLoaded={onMapLoaded}>
      {children}
    </EttaMap>
  )
}
