import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { RailDeliveryOptionCheckoutWrapper } from '@etta/components/rail-delivery-option/rail-delivery-option-checkout-wrapper'
import { AppleBanner } from '@etta/components/apple-banner'
import { HeaderBlock } from '../../header-block'
import { CarBlock } from '../../car-block'
import { FlightBlock } from '../../flight-block'
import { HotelBlock } from '../../hotel-block'
import { RailBlock } from '../../rail-block'
import { TripTotalBlock } from '../../trip-total-block'
import type { LayoutProps } from '../../types'
import { Footer } from './purchase-reservation-success-desktop-styled'

export function PurchaseReservationSuccessDesktop({
  onDone,
  onAnotherTrip,
  onShareTripsDetail,
  carRentals,
  hotels,
  flights,
  isPostBooking,
  rail,
  tripTotal,
  travelerEmail,
  dynamicSiteMessagesSlot,
  confirmationNumbersData,
  isTripLoading,
  isAppleBannerShown,
  pnrNumber,
  isStationTicketMachineDisabled,
  isRailEticketDeliveryOption,
  isTripOnHold,
  doneButtonLabel,
  autoCancellationTime,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <>
      {dynamicSiteMessagesSlot}
      <Block marginHorizontal={24} paddingTop={50}>
        <HeaderBlock
          isRailEticket={isRailEticketDeliveryOption}
          isTripOnHold={isTripOnHold}
          autoCancellationTime={autoCancellationTime}
          isPostBooking={isPostBooking}
          onShareTripsDetail={onShareTripsDetail}
          isTripLoading={isTripLoading}
          pnrNumber={pnrNumber}
        />

        {flights && (
          <FlightBlock
            flights={flights}
            confirmationNumbersData={confirmationNumbersData}
            isTripLoading={isTripLoading}
          />
        )}
        {rail && <RailBlock rail={rail} />}
        {hotels && (
          <HotelBlock
            hotels={hotels}
            confirmationNumbersData={confirmationNumbersData}
            isTripLoading={isTripLoading}
          />
        )}
        {carRentals && (
          <CarBlock
            carRentals={carRentals}
            confirmationNumbersData={confirmationNumbersData}
            isTripLoading={isTripLoading}
          />
        )}
        {tripTotal && <TripTotalBlock hasRail={!!rail} tripTotal={tripTotal} />}
      </Block>
      {!!rail && (
        <RailDeliveryOptionCheckoutWrapper
          isStationTicketMachineDisabled={isStationTicketMachineDisabled}
          isEticket={isRailEticketDeliveryOption}
          email={travelerEmail || ''}
          stationName={rail.departureStationName}
          isPurchaseSuccessScreen
        />
      )}
      <Footer>
        <Button
          variant="outline"
          fullWidth
          style={{ marginRight: 8, backgroundColor: 'transparent' }}
          data-tracking-id="book-another-trip-button"
          onClick={onAnotherTrip}>
          {t('PurchaseReservationSuccess.BookAnotherTrip')}
        </Button>
        <Button
          aria-label={t('ReviewTrip.ButtonsLabel', {
            label: doneButtonLabel,
          })}
          fullWidth
          style={{ marginLeft: 8 }}
          onClick={onDone}
          data-tracking-id="see-upcoming-trips-button">
          {doneButtonLabel}
        </Button>
      </Footer>
      {isAppleBannerShown && (
        <Block marginTop={32}>
          <AppleBanner orientation="horizontal" />
        </Block>
      )}
    </>
  )
}
