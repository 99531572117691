import styled from 'styled-components'

export const Container = styled.div`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 16px;
`

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const DsmWrapper = styled.div`
  margin-bottom: 24px;
`

export const List = styled.div`
  & > div {
    margin-bottom: 42px;
    position: relative;
    &:not(:last-child):after {
      content: '';
      display: flex;
      position: absolute;
      left: 40px;
      bottom: -42px;
      width: 1px;
      height: 42px;
      background-color: ${({ theme }) => theme.colors.borderDark};
    }
  }
`

export const Container1 = styled.div`
  display: flex;
  > :first-child {
    flex: 1;
  }
`

export const Container2 = styled.div`
  width: 1080px;
  align-items: flex-start;
  margin: 41px auto 0;
`
