import { useTranslation } from 'react-i18next'
import type { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { EcoCheckEquivalenceCardImage } from '../eco-check-equivalences-card-image'
import { useEquivalenceDescription } from '../use-equivalence-description'
import {
  CardContainerMobile,
  LeftBlockMobile,
  RightBlockMobile,
  DescriptionBlockMobile,
  AmountBlockMobile,
  ViewSourceLinkMobile,
} from './eco-check-equivalences-mobile-new-styled'

type CardProps = {
  amount: number
  type: CalculateEmissionsBatchResultEquivalenceType
  unit: string
}

export function EcoCheckEquivalenceMobileCardNew({ amount, type }: CardProps) {
  const { t } = useTranslation()
  const equivalenceDescription = useEquivalenceDescription(type)

  return (
    <CardContainerMobile>
      <LeftBlockMobile>
        <DescriptionBlockMobile aria-label={equivalenceDescription.firstPart}>
          {equivalenceDescription.firstPart}
        </DescriptionBlockMobile>
        <AmountBlockMobile>{amount.toLocaleString('en-US')}</AmountBlockMobile>
        <ViewSourceLinkMobile
          aria-label={`${t('Emissions.DetailsModal.ViewSource')} - ${t('Accessibility.OpenLink')}`}
          target="_blank"
          size="small"
          href={equivalenceDescription.linkTo}>
          {t('Emissions.DetailsModal.ViewSource')}
          <Icon name={'openWindowPWA'} size={20} color="primary1" />
        </ViewSourceLinkMobile>
      </LeftBlockMobile>
      <RightBlockMobile>
        <EcoCheckEquivalenceCardImage type={type} />
      </RightBlockMobile>
    </CardContainerMobile>
  )
}
