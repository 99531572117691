import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import type { FailedBookingButton } from '../../types'
import { ButtonsWrapper } from './failed-segment-styled'

type Variant = 'partial' | 'unconfirmed'

type Props = {
  errorDescription: string
  segmentTitle: string
  segmentDescription?: string | ReactNode
  buttons: FailedBookingButton[]
  variant: Variant
}

export function FailedSegmentBody({
  errorDescription,
  segmentTitle,
  segmentDescription,
  buttons,
  variant,
}: Props) {
  return (
    <Fragment>
      <Block marginVertical={14}>
        <Text variant="footnoteMedium" color="mainText">
          {errorDescription}
        </Text>
      </Block>
      <Block marginBottom={4}>
        <Text variant="subHeadStrong" color="mainText">
          {segmentTitle}
        </Text>
      </Block>
      {segmentDescription && (
        <Block marginBottom={2}>
          <Text variant="footnoteMedium" color="bodyText1">
            {segmentDescription}
          </Text>
        </Block>
      )}
      {!!buttons.length && (
        <ButtonsWrapper isColumn={variant === 'unconfirmed'}>
          {buttons.map((button, idx) => (
            <Button fullWidth onClick={button.onClick} variant={button.variant} key={idx}>
              {button.label}
            </Button>
          ))}
        </ButtonsWrapper>
      )}
    </Fragment>
  )
}
