import { Skeleton } from '@etta/ui/skeleton'
import { CardContainer } from './hotel-card-mobile-skeleton-styled'

export const HotelCardMobileSkeleton = (
  <CardContainer>
    <Skeleton width="100%" height="248px">
      <rect x="0" y="0" width="100%" height="130" rx="10" ry="10" />
      <rect x="0" y="10" width="100%" height="130" />
      <rect y="153" x="18" width="60%" height="16px" rx="2" ry="2" />
      <rect y="179" x="18" width="34%" height="16px" rx="2" ry="2" />
      <rect y="215" x="70%" width="24%" height="16px" rx="2" ry="2" />
    </Skeleton>
  </CardContainer>
)
