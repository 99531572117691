import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Dialog } from '@etta/ui/dialog'
import { Icon } from '@etta/ui/icon'
import { Container } from './search-results-trip-expired-dialog-styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  onStartAsNewTrip: () => void
}

const i18nBase = 'SearchResults.TripExpiredDialog.'

export function SearchResultsTripExpiredDialog({ isOpen, onClose, onStartAsNewTrip }: Props) {
  const { t } = useTranslation()
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Container paddingHorizontal={20} paddingVertical={20}>
        <Container isFlexBlock marginBottom={32}>
          <Icon name="warningFilledPWA" size="large" color="error" />
          <Block marginBottom={24} marginTop={24}>
            <Text variant="title3">{t(`${i18nBase}Title`)}</Text>
          </Block>
          <Block>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {t(`${i18nBase}Subtitle`)}
            </Text>
          </Block>
        </Container>
        <Container isFlexBlock>
          <Block marginBottom={16} isFullWidth>
            <Button size="normal-wide" fullWidth onClick={onStartAsNewTrip}>
              {t(`${i18nBase}StartAsNewTrip`)}
            </Button>
          </Block>
          <Block isFullWidth>
            <Button variant="outline" size="normal-wide" fullWidth onClick={onClose}>
              {t(`${i18nBase}Close`)}
            </Button>
          </Block>
        </Container>
      </Container>
    </Dialog>
  )
}
