import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace PostBookingErrors {
  export const GetTripNotFoundError = createErrorClass('Get trip not found error')
  export const GetTripError = createErrorClass('Get trip error')
  export const GetTripUnexpectedError = createErrorClass('Get trip unexpected error')
  export const CancelTripError = createErrorClass('Cancel trip error')
  export const CancelTripUnexpectedError = createErrorClass('Cancel trip unexpected error')
  export const CancelTripSegmentError = createErrorClass('Cancel trip segment error')
  export const CancelTripSegmentUnexpectedError = createErrorClass(
    'Cancel trip segment unexpected error',
  )
  export const NoReservationIdError = createErrorClass('No reservation id error')
  export const CancelRideHailError = createErrorClass('Cancel ride hail error')
  export const CancelRideHailErrorInWholeTripCancel = createErrorClass(
    'Cancel ride hail error in whole trip cancellation',
  )
  export const CancelRideHailUnexpectedError = createErrorClass('Cancel ride hail unexpected error')
  export const CancelRideHailAlreadyCompletedError = createErrorClass(
    'Cancel ride hail already completed error',
  )
  export const ChangeFlightSeatsError = createErrorClass('Change flight seats error')
  export const UnexpectedErrorChangeFlightSeats = createErrorClass('Change flight seats error')
}

export type PostBookingErrorsInstances = InstanceType<
  typeof PostBookingErrors[keyof typeof PostBookingErrors]
>
