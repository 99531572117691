import styled from 'styled-components'
import { DatePicker } from '@etta/ui/date-picker-temp'
import { Modal } from '@etta/ui/modal'
import { DATE_PICKER_WIDTH } from './const'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 100;
`

export const StyledDatePicker = styled(DatePicker)`
  max-width: ${DATE_PICKER_WIDTH}px;
  min-width: ${DATE_PICKER_WIDTH}px;
`

export const Footer = styled(Modal.Footer)`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.colors.borderDark};
`
