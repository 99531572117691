import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style'

export const Title = styled.span`
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 4px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${captionStrongCaps};
`
