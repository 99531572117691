import { useEffect, useState } from 'react'
import { SegmentType } from '@etta/core/enums'
import { trackAction } from '@fiji/utils/tracking/track-action'
import type { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'

const ACTION_NAME_TRIP_DETAIL = 'mobility-cta_trip-detail'
const ACTION_NAME_HOTEL_DETAIL = 'mobility-cta_hotel-detail'
const ACTION_NAME_FLIGHT_DETAIL = 'mobility-cta_flight-detail'

export type Props = {
  segmentType?: SegmentType
  status?: UberAccountStatusEnum
  isShown: boolean
}

export function useTrackMobilityCallToAction({ segmentType, status, isShown }: Props) {
  const [isTracked, setIsTracked] = useState(false)

  useEffect(() => {
    if (isShown && !isTracked) {
      const value = status ? { status } : undefined
      switch (segmentType) {
        case SegmentType.Hotel:
          trackAction(ACTION_NAME_HOTEL_DETAIL, value)
          break
        case SegmentType.Flight:
          trackAction(ACTION_NAME_FLIGHT_DETAIL, value)
          break
        default:
          trackAction(ACTION_NAME_TRIP_DETAIL, value)
          break
      }
      setIsTracked(true)
    }
  }, [isShown, segmentType, status, isTracked])
}
