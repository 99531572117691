import styled from 'styled-components'
import { headline, footnoteMedium } from '@fiji/style'

export const BaggageFeeHeading = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 16px 0 10px;
  margin: 12px 16px 0;
`

export const BaggageFeeItem = styled.div`
  padding: 0 0 4px 0;

  &:nth-child(2) {
    ${BaggageFeeHeading} {
      margin: 0 16px;
    }
  }
`

export const BaggageFeeTitle = styled.div`
  ${headline};
`

export const BaggageFeeContent = styled.div`
  padding: 0 16px;
  margin: 4px 0;
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium};
`
