import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../types'

export function ModalHeaderMobile({ title, backgroundColor }: LayoutProps) {
  return (
    <Modal.Title color="mainText2" backgroundColor={backgroundColor}>
      {title}
    </Modal.Title>
  )
}
