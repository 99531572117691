// eslint-disable-next-line import/no-restricted-paths
import type { AirportFilter } from '@fiji/graphql/types'
import type { AirFilterAirport } from '@etta/modules/air-search/core/value-objects/air-filters'

export function getFilterAirports(airport?: AirportFilter[]) {
  const originAirports: AirFilterAirport[] = []
  const destinationAirports: AirFilterAirport[] = []

  airport?.forEach((airport) => {
    const isOriginAirportExist = originAirports.some(
      (originAirport) => originAirport.code === airport.origin.code,
    )

    const isDestinationAirportExist = destinationAirports.some(
      (destinationAirport) => destinationAirport.code === airport.destination.code,
    )
    if (!isOriginAirportExist) {
      originAirports.push(airport.origin)
    }
    if (!isDestinationAirportExist) {
      destinationAirports.push(airport.destination)
    }
  })

  const sortedOrigin = originAirports.sort((a, b) => a.code.localeCompare(b.code))
  const sortedDestination = destinationAirports.sort((a, b) => a.code.localeCompare(b.code))

  return { originAirports: sortedOrigin, destinationAirports: sortedDestination }
}
