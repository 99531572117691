import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DatePickerModal } from '@etta/ui/date-picker-modal-temp'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { Search } from '@etta/ui/search'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import type { DatePickerProps } from '../../../types'

function formatDate(date?: Date | null) {
  if (!date) {
    return ''
  }
  return dateFormat(date, 'LLL d')
}

const i18Base = 'TripPlanner.BaseSearch.Input'

export const DatePicker = observer(function DatePicker({
  checkInDate,
  checkOutDate,
  onDateChange,
}: DatePickerProps) {
  const { t } = useTranslation()
  const { hotelSearchFormStore } = useHotelSearchFormContext()
  const { validationErrors } = hotelSearchFormStore

  const datePickerCheckInLabel = t(`${i18Base}.Checkin`)
  const datePickerCheckOutLabel = t(`${i18Base}.Checkout`)

  const checkInValue = formatDate(checkInDate)
  const checkOutValue = formatDate(checkOutDate)

  const checkInWrapperAriaLabel = checkInDate
    ? t(i18Base + '.DateAriaLabel', {
        label: datePickerCheckInLabel + ' ' + checkInValue,
      })
    : t(i18Base + '.DateAriaLabel', { label: datePickerCheckInLabel })

  const checkOutWrapperAriaLabel = checkOutDate
    ? t(i18Base + '.DateAriaLabel', {
        label: datePickerCheckOutLabel + ' ' + checkInValue,
      })
    : t(i18Base + '.DateAriaLabel', { label: datePickerCheckOutLabel })

  const checkInDatePickerModalToggle = useTogglePopup()
  const checkOutDatePickerModalToggle = useTogglePopup()

  const shouldHighlightGroup = !!(validationErrors.checkinDate && validationErrors.checkoutDate)

  return (
    <>
      <Search.Group
        gap={2}
        errorMessage={shouldHighlightGroup ? validationErrors.checkinDate : undefined}>
        <Search.Element
          wrapperAriaLabel={checkInWrapperAriaLabel}
          onClick={checkInDatePickerModalToggle.handleOpen}
          labelTitle={datePickerCheckInLabel}
          value={checkInValue}
          placeholder={t(`${i18Base}.Select`)}
          errorMessage={shouldHighlightGroup ? undefined : validationErrors.checkinDate}
          shouldRightBorderBeFlat
        />
        <Search.Element
          wrapperAriaLabel={checkOutWrapperAriaLabel}
          onClick={checkOutDatePickerModalToggle.handleOpen}
          labelTitle={datePickerCheckOutLabel}
          value={checkOutValue}
          placeholder={t(`${i18Base}.Select`)}
          errorMessage={shouldHighlightGroup ? undefined : validationErrors.checkoutDate}
          shouldLeftBorderBeFlat
        />
      </Search.Group>
      <DatePickerModal
        startTitle={datePickerCheckInLabel}
        endTitle={datePickerCheckOutLabel}
        isRange
        date={checkInDate}
        dateEnd={checkOutDate}
        onSelect={onDateChange}
        isOpen={checkInDatePickerModalToggle.isOpen}
        onClose={checkInDatePickerModalToggle.handleClose}
        withConfirmOneWayButton={false}
        minDate={new Date()}
      />
      <DatePickerModal
        startTitle={datePickerCheckInLabel}
        endTitle={datePickerCheckOutLabel}
        isRange
        isReturnDate
        date={checkInDate}
        dateEnd={checkOutDate}
        onSelect={onDateChange}
        isOpen={checkOutDatePickerModalToggle.isOpen}
        onClose={checkOutDatePickerModalToggle.handleClose}
        withConfirmOneWayButton={false}
        minDate={new Date()}
      />
    </>
  )
})
