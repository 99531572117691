import styled from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  padding: 0 16px 4px 16px;
`

export const Section = styled.div`
  padding: 16px 0;
`

export const Row = styled.div`
  display: flex;
  border-top: 1px dashed ${(props) => props.theme.colors.borderDark};
`

export const LeftSection = styled.div`
  position: relative;
  flex-basis: 50%;
  padding: 16px 20px 16px 0;
`

export const RightSection = styled.div`
  padding: 16px 0 16px 20px;
  position: relative;
  flex-basis: 50%;
  &:before {
    left: 0;
    top: 10px;
    content: '';
    position: absolute;
    width: 1px;
    height: 51px;
    background-color: ${(props) => props.theme.colors.borderLight};
  }
`

export const CarVendorLogo = styled.div`
  margin: 0 0 2px;
`

export const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InformationLabel = styled.div`
  margin: 0 0 2px;
`

export const LogoImage = styled.img`
  max-width: 100px;
`

export const VendorInformationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const VendorInformationTitleContainer = styled.div``
export const CarOutOfPolicyContainer = styled.div``
