import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { footnoteMedium } from '@fiji/style'

export const AmenitiesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 12px 16px;
  background-color: ${(p) => p.theme.colors.white};
`

export const AmenitiesListItem = styled.li`
  margin-bottom: 3px;
  display: flex;
  align-items: center;
`

export const AmenitiesIcon = styled(Icon)`
  margin-right: 8px;
`

export const AmenitiesText = styled.span`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.mainText};
`
