import { useTranslation } from 'react-i18next'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ContentWrapper } from './change-trip-not-allowed-modal-styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  onViewSupport: () => void
  modalTitle?: string
  modalErrorText?: string
}

const i18nBase = 'PostBooking.TripDetails.ChangeTripModal'

export function ChangeTripNotAllowedModal({
  onClose,
  onViewSupport,
  isOpen,
  modalTitle,
  modalErrorText,
}: Props) {
  const { t } = useTranslation()

  return (
    <Dialog onClose={onClose} isOpen={isOpen}>
      <ContentWrapper>
        <Text variant="title3" color="mainText">
          {modalTitle?.length ? modalTitle : t(`${i18nBase}.Title`)}
        </Text>
        <Block marginTop={24}>
          <Text variant="footnoteMedium" color="bodyText" align="center">
            {modalErrorText?.length ? modalErrorText : t(`${i18nBase}.ErrorDescription`)}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          <Button variant="solid" onClick={onViewSupport} fullWidth>
            {t(`${i18nBase}.Support`)}
          </Button>
        </Block>
        <Block isFullWidth marginTop={20}>
          <Button variant="outline" onClick={onClose} fullWidth>
            {t(`${i18nBase}.Back`)}
          </Button>
        </Block>
      </ContentWrapper>
    </Dialog>
  )
}
