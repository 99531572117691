import type { Maybe } from 'fnscript'
import { Error as NestedError, Nothing, Just } from 'fnscript'
import { Service } from '@etta/di'
import type { SearchRailLegEntity } from '../../core/entities/search-rail-leg.entity'
import type {
  RailEmissionsBatchRailInput,
  RailEmissionsBatchRailSegmentInput,
} from '../../infra/types'

const SKIP_VALIDATION_ERROR = 'Error: Skip validation'
const RAIL_ERROR_MESSAGES = {
  ORIGIN_STATION_CODE: 'originStationCode is missing or it has a wrong value',
  DESTINATION_STATION_CODE: 'destinationStationCode is missing or it has a wrong value',
  DEPARTURE_DATE: 'departureDate is missing or it has a wrong value',
  CLASS: 'class is missing or it has a wrong value',
}
const ERROR_MESSAGE = 'CalculateRailEmissionsBatch query not executed'

@Service()
export class RailEmissionsValidatorService {
  railEmissionsQueryExecuteValidation({
    rails,
    maybeOriginStationCode,
    maybeDestinationStationCode,
  }: {
    rails?: RailEmissionsBatchRailInput[]
    maybeOriginStationCode: Maybe<string>
    maybeDestinationStationCode: Maybe<string>
  }): Maybe<NestedError> {
    if (!rails || rails.length === 0) {
      return Just(NestedError.new(SKIP_VALIDATION_ERROR))
    }

    const maybeRailCompareSegmentsErrors = this.validateRailCompareSegments({
      maybeOriginStationCode,
      maybeDestinationStationCode,
    })

    const maybeRailsError = this.validateTcRails(rails)

    if (maybeRailsError.isValue() || maybeRailCompareSegmentsErrors.isValue()) {
      return Just(
        NestedError.new(
          `${ERROR_MESSAGE}: ${!maybeRailsError.isNothing() && maybeRailsError.getValue()} ${
            !maybeRailCompareSegmentsErrors.isNothing() && maybeRailCompareSegmentsErrors.getValue()
          }`,
        ),
      )
    }

    return Nothing()
  }

  private validateRailCompareSegments({
    maybeDestinationStationCode,
    maybeOriginStationCode,
  }: {
    maybeDestinationStationCode: Maybe<string>
    maybeOriginStationCode: Maybe<string>
  }) {
    const compareSegmentErrors: NestedError[] = []

    if (maybeDestinationStationCode.isNothing() || maybeDestinationStationCode.getValue() === '') {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.DESTINATION_STATION_CODE)
      compareSegmentErrors.push(error)
    }

    if (maybeOriginStationCode.isNothing() || maybeOriginStationCode.getValue() === '') {
      const error = NestedError.new(RAIL_ERROR_MESSAGES.ORIGIN_STATION_CODE)
      compareSegmentErrors.push(error)
    }

    if (compareSegmentErrors.length > 0) {
      const validationError = NestedError.new(compareSegmentErrors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(
          `failed to validate rail station code compare object;${validationError.getMessage()}`,
        ),
      )
    }

    return Nothing()
  }

  private validateTcRails(rails: RailEmissionsBatchRailInput[]) {
    const railErrors: NestedError[] = []

    rails.forEach((rail, idx) => {
      const maybeSegmentErrors = this.validateTcRailSegments(rail.segments)

      if (maybeSegmentErrors.isValue()) {
        railErrors.push(
          NestedError.new(
            `failed to validate rail with index: ${idx};${maybeSegmentErrors.getValue()}`,
          ),
        )
      }
    })

    if (railErrors.length > 0) {
      const validationError = NestedError.new(railErrors.map((e) => e.text()).join(';'))

      return Just(NestedError.new(validationError.getMessage()))
    }

    return Nothing()
  }

  private validateTcRailSegments(segments: RailEmissionsBatchRailSegmentInput[]) {
    const segmentErrors: NestedError[] = []

    segments.forEach((segment) => {
      if (isEmptyString(segment.departureDate)) {
        const error = NestedError.new(RAIL_ERROR_MESSAGES.DEPARTURE_DATE)
        segmentErrors.push(error)
      }

      if (isEmptyString(segment.destinationStationCode)) {
        const error = NestedError.new(RAIL_ERROR_MESSAGES.DESTINATION_STATION_CODE)
        segmentErrors.push(error)
      }

      if (isEmptyString(segment.originStationCode)) {
        const error = NestedError.new(RAIL_ERROR_MESSAGES.ORIGIN_STATION_CODE)
        segmentErrors.push(error)
      }

      if (isEmptyString(segment.class)) {
        const error = NestedError.new(RAIL_ERROR_MESSAGES.CLASS)
        segmentErrors.push(error)
      }
    })

    if (segmentErrors.length > 0) {
      const validationError = NestedError.new(segmentErrors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(`failed to validate rail segment args;${validationError.getMessage()}`),
      )
    }

    return Nothing()
  }

  maybeGetOriginStationCode(railList: SearchRailLegEntity[]): Maybe<string> {
    if (railList.length === 0) {
      return Nothing()
    }

    return Just(railList[0].originCode)
  }

  maybeGetDestinationStationCode(railList: SearchRailLegEntity[]): Maybe<string> {
    if (railList.length === 0) {
      return Nothing()
    }

    return Just(railList[0].destinationCode)
  }
}

function isEmptyString(string?: string | null) {
  if (typeof string !== 'string') {
    return true
  }

  return string.length === 0
}
