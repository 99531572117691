import { useTranslation } from 'react-i18next'
import { OutsideContainer } from '@etta/ui/outside-container'
import { BrandsMultiSelect } from '@etta/screens/hotel-result-page/hotel-filters/brands-multi-select'
import { useSearchBrands } from '@etta/screens/hotel-result-page/hotel-filters/brands-multi-select/use-search-brands'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { SearchBox } from '@etta/screens/hotel-result-page/hotel-filters/search-box'
import { FiltersContainer } from './brands-filter-styled'

const i18nBase = 'HotelFilters.Hotel'

type Props = {
  brandIds: string[]
  superBrands: SuperBrandFilter[]
  brands: BrandFilter[]
  onApply: (brandIds: string[]) => void
  onSelect: (brandIds: string[]) => void
  isApplyButtonDisabled?: boolean
}

export function BrandsFilterContent({
  brandIds,
  brands,
  superBrands,
  onApply,
  onSelect,
  isApplyButtonDisabled,
}: Props) {
  const { t } = useTranslation()

  const {
    selectedIdsMap,
    filteredSuperBrands,
    filteredBrands,
    searchText,
    handleSearch,
    handleClear,
    handleApply,
    handleUpdateSelection,
  } = useSearchBrands({
    selectedBrands: brandIds,
    brands,
    superBrands,
    onApply,
    onSelect,
  })

  return (
    <>
      <OutsideContainer.HeaderSelected
        label={t(i18nBase + '.HotelBrands')}
        selectedNumber={selectedIdsMap.size}
      />
      <OutsideContainer.Body>
        <FiltersContainer>
          <SearchBox
            placeholder={`${t(i18nBase + '.SearchBrand')} ${t(i18nBase + '.Name')}`}
            searchText={searchText}
            onChange={handleSearch}
          />
          <BrandsMultiSelect
            superBrands={filteredSuperBrands}
            brands={filteredBrands}
            selectedIdsMap={selectedIdsMap}
            onSelect={handleUpdateSelection}
          />
        </FiltersContainer>
      </OutsideContainer.Body>
      <OutsideContainer.Footer
        onClear={handleClear}
        isDisabled={isApplyButtonDisabled}
        onApply={handleApply}
      />
    </>
  )
}
