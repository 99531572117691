import { Validator } from '@etta/interface/services/validator'

function isEmail(value: string) {
  try {
    Validator.scheme.string().email(`Invalid Email`).validateSync(value)
    return true
  } catch {
    return false
  }
}

export function generateHref(value?: string) {
  if (!value) {
    return
  }

  if (isEmail(value)) {
    return {
      href: `mailto:${value}`,
      hrefText: value,
      target: '_blank',
    }
  }

  return {
    href: `tel:${value}`,
    hrefText: value,
    target: undefined,
  }
}
