export default function () {
  return (
    <svg
      width="101"
      height="87"
      viewBox="0 0 101 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="43.9043" cy="43.4043" r="43.4043" fill="currentColor" />
      <path
        d="M100.5 22.5531C100.5 31.7187 93.0684 39.1489 83.9043 39.1489C74.7385 39.1489 67.3086 31.7187 67.3086 22.5531C67.3086 13.3876 74.7385 5.9574 83.9043 5.9574C93.0684 5.9574 100.5 13.3876 100.5 22.5531Z"
        fill="#ECEDEE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M99.031 22.6714C99.031 30.9607 92.311 37.6812 84.0195 37.6812C75.7298 37.6812 69.0098 30.9607 69.0098 22.6714C69.0098 14.3804 75.7298 7.65991 84.0195 7.65991C92.311 7.65991 99.031 14.3804 99.031 22.6714Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.9084 33.1144C84.0759 33.1144 84.314 33.0406 84.5432 32.9207C89.6212 30.2272 91.3315 28.7513 91.3315 25.449V18.6691C91.3315 17.4791 90.926 17.0087 89.9474 16.5751C89.0305 16.1785 85.8568 15.0254 84.984 14.7303C84.649 14.6196 84.2434 14.5458 83.9084 14.5458C83.5646 14.5458 83.1679 14.6288 82.8329 14.7303C81.9513 15.0162 78.7775 16.1877 77.8695 16.5751C76.8821 16.9995 76.4766 17.4791 76.4766 18.6691V25.449C76.4766 28.7513 78.2486 30.1165 83.2737 32.9207C83.5029 33.0499 83.7409 33.1144 83.9084 33.1144ZM86.8794 27.7643C86.6061 27.7643 86.3857 27.6628 86.2182 27.4783L83.8996 25.0708L81.5986 27.4783C81.4223 27.6721 81.1931 27.7643 80.9286 27.7643C80.4085 27.7643 80.003 27.34 80.003 26.7957C80.003 26.5467 80.1087 26.3069 80.2851 26.1316L82.6037 23.7056L80.2851 21.2703C80.1087 21.0951 80.003 20.8552 80.003 20.6062C80.003 20.062 80.4085 19.6469 80.9286 19.6469C81.1931 19.6469 81.4135 19.7391 81.581 19.9236L83.8996 22.3404L86.2359 19.9236C86.421 19.7206 86.6238 19.6376 86.8882 19.6376C87.4084 19.6376 87.8051 20.0527 87.8051 20.597C87.8051 20.8552 87.7169 21.0766 87.5318 21.2611L85.2132 23.7056L87.5318 26.1224C87.6993 26.3069 87.8051 26.5375 87.8051 26.7957C87.8051 27.34 87.3996 27.7643 86.8794 27.7643Z"
        fill="#CA0F1C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5533 24.563H33.7339V27.673L35.5533 29.0059L37.3728 27.673V24.563H35.5533ZM38.7374 25.8959H47.8347V28.5616L51.0187 30.783L54.2029 28.5616V25.8959H62.3905V58.3733C62.3905 60.8167 60.369 62.7718 57.8418 62.7718H28.7303C26.2033 62.7718 24.1816 60.8167 24.1816 58.3733V25.8959H32.3693V28.5616L35.5533 30.783L38.7374 28.5616V25.8959ZM58.2965 59.2175C58.7516 59.2175 58.7516 58.7731 58.7516 58.3287V35.6702H27.8206V58.3733C27.8206 58.8621 27.8206 58.9952 28.2754 59.2175H58.2965ZM49.1992 24.563H51.0187H52.8381V27.673L51.0187 29.0059L49.1992 27.673V24.563ZM40.7386 43.1042L43.2857 40.5569L45.8332 43.1042L43.2861 45.6514L40.7386 43.1042ZM31.8233 43.1044L34.3705 40.5571L36.9178 43.1044L34.3706 45.6516L31.8233 43.1044ZM52.2013 40.5569L49.6542 43.1042L52.2016 45.6514L54.7487 43.1042L52.2013 40.5569ZM31.8234 51.9903L34.3706 49.4429L36.9179 51.9903L34.3706 54.5374L31.8234 51.9903ZM43.2857 49.4429L40.7386 51.9903L43.2861 54.5374L45.8332 51.9903L43.2857 49.4429ZM49.6542 51.9903L52.2013 49.4429L54.7487 51.9903L52.2016 54.5374L49.6542 51.9903Z"
        fill="white"
      />
    </svg>
  )
}
