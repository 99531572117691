import { Store } from '@etta/di'

import { Toggle } from '@etta/interface/services/toggle'

@Store()
export class PostBookingReviewTripPageStore {
  flightSearchFormModal = new Toggle()

  private _bookingId: string = ''

  setBookingId(id: string) {
    this._bookingId = id
  }

  get bookingId() {
    return this._bookingId || undefined
  }
}
