import styled from 'styled-components'
import { headline } from '@fiji/style'

export const Container = styled.div`
  padding: 0 16px;
`

export const Wrapper = styled.div`
  padding: 12px 0 16px;
  display: flex;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderDark};
  border-left: 0;
  border-right: 0;
`

export const Initials = styled.div`
  background: ${({ theme }) => theme.colors.bodyText1};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  ${headline}
  text-transform: uppercase;
`

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
