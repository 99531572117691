import { useRef, useEffect } from 'react'
import { useIntersection } from 'react-use'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { getIconName } from './get-icon-name'

type Props = {
  isSelected: boolean
  isBooked: boolean
  isNewSeatMap?: boolean
  isEconomyClass: boolean
  isReadonly: boolean
  seatItem?: SeatMapRowSeatValueObject
}

export function useSeatItem({
  isSelected,
  isBooked,
  isNewSeatMap,
  isEconomyClass,
  isReadonly,
  seatItem,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const intersection = useIntersection(inputRef, {
    threshold: 1,
  })

  useEffect(() => {
    if (!intersection || !inputRef?.current) {
      return
    }
    if (isSelected && intersection.intersectionRatio < 1) {
      inputRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    inputRef.current = null
  }, [intersection, isSelected])

  const isAvailable = Boolean(seatItem?.available)
  const isPremium = Boolean(seatItem?.isPremium)
  const isPaid = Boolean(seatItem?.isPaid)

  const dataTrackIdAvailable = isAvailable
    ? isPremium
      ? 'seat-premium-available-label'
      : 'seat-available-label'
    : 'seat-not-available-label'

  const seatIcon = getIconName({
    isEconomyClass,
    isSelected,
    isBooked,
    isNewSeatMap,
    isAvailable,
    isPremium,
    isPaid,
  })

  const dataTrackId = isSelected ? 'seat-selected-label' : dataTrackIdAvailable

  const isDisabled = isNewSeatMap ? !isAvailable : isReadonly || !isAvailable

  return {
    inputRef,
    number: seatItem?.number,
    isAvailable,
    isDisabled,
    dataTrackId,
    seatIcon,
  }
}
