import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container } from './no-unused-tickets-styled'

const i18nBase = 'UnusedTickets'

export function NoUnusedTickets() {
  const { t } = useTranslation()
  return (
    <Container>
      <Block marginBottom={12}>
        <Icon name="unusedTicketPWA" color="bodyText" size="large" />
      </Block>
      <Text isBlock variant="subHeadMedium" color="mainText">
        {t(`${i18nBase}.NoUnusedTickets`)}
      </Text>
    </Container>
  )
}
