export const VisaTypeOptions = {
  BusinessVisa: 'Business Visa',
  TouristVisa: 'Tourist Visa',
  E1: 'E-1 Treaty Trader Visa',
  E2: 'E-2 Treaty Trader Visa',
  F1: 'F-1 Student Visa',
  H1B: 'H-1B Work Visa',
  H2B: 'H-2B Work Visa',
  L1: 'L-1 Visa',
  K1: 'K-1 Visa',
  TN: 'TN Nafta Work Visa',
  GreenCard: 'Green Card',
  ResidentAlien: 'Resident Alien',
  Other: 'Other',
}
