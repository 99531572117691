// eslint-disable-next-line import/no-restricted-paths
import type { AmenityFilter, BrandFilter, HotelGroup, SuperBrandFilter } from '@fiji/graphql/types'
import type { SelectedFiltersValueObject } from '../../core/value-objects/selected-filters.value-object'

export type Args = {
  selectedFilters?: SelectedFiltersValueObject
  groups?: HotelGroup[] | null
  brandFilters?: BrandFilter[]
  superBrandFilters?: SuperBrandFilter[]
  amenityFilters?: AmenityFilter[]
}

export function getFilterOptions({
  selectedFilters,
  amenityFilters,
  groups,
  brandFilters,
  superBrandFilters,
}: Args) {
  const amenityFiltersList = amenityFilters || []
  const checkedAmenities = amenityFiltersList.filter((item) =>
    selectedFilters?.amenityIds?.includes(item.amenityId),
  )
  const uncheckedAmenities = amenityFiltersList.filter(
    (item) => !selectedFilters?.amenityIds?.includes(item.amenityId),
  )
  const amenities = {
    checkedAmenities,
    uncheckedAmenities,
    sortedAmenities: [...checkedAmenities, ...uncheckedAmenities],
    amenities: amenityFiltersList,
  }

  const brandFiltersList = brandFilters || []
  const superBrandFiltersList = superBrandFilters || []
  const selected = selectedFilters?.brandIds || []
  const selectedSuperBrands: SuperBrandFilter[] = superBrandFiltersList.filter(({ brands }) =>
    brands.some(({ brandId }) => selected.includes(brandId)),
  )
  const selectedBrands: BrandFilter[] = brandFiltersList.filter(({ brandId }) =>
    selected.includes(brandId),
  )

  const notSelectedSuperBrands: SuperBrandFilter[] = superBrandFiltersList.filter(({ brands }) =>
    brands.every(({ brandId }) => !selected.includes(brandId)),
  )
  const notSelectedBrands: BrandFilter[] = brandFiltersList.filter(
    ({ brandId }) => !selected.includes(brandId),
  )

  const brands = {
    brands: brandFiltersList,
    superBrands: superBrandFiltersList,
    selectedSuperBrands,
    selectedBrands,
    notSelectedSuperBrands,
    notSelectedBrands,
  }

  return {
    hotelName: '',
    amenities,
    brands,
    groups: groups || [],
  }
}
