import { DesktopLoadingPlace } from '../desktop-place'

const PREFILL = Array.from(Array(10).fill(0))

export function LocationsLoadingDesktopSkeleton() {
  return (
    <div data-tracking-id="car-rental-locations-loading">
      {PREFILL.flatMap((_, i) => (
        <DesktopLoadingPlace key={'loading-state-' + i} />
      ))}
    </div>
  )
}
