import { useTranslation } from 'react-i18next'
import { CarTransmission } from '@fiji/enums'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import { CarRentalFuelType } from '@fiji/graphql/types'
import {
  AmenitiesIcon,
  AmenitiesList,
  AmenitiesListItem,
  AmenitiesText,
} from './car-rental-mobile-amenities-styled'

type Props = {
  car: CarRentalCarDetailsValueObject | null
  isHybridFuel?: boolean
}

const i18Base = 'CarRentalDetails.Amenities.'

export function CarRentalMobileAmenities({ isHybridFuel, car }: Props) {
  const { t } = useTranslation()
  const passengers = car?.details.passenger
  const luggage = car?.details.luggage
  const isAutomaticTransmission = car?.details.transmission === CarTransmission.Automatic
  const unlimitedMileage = car?.unlimitedMileage
  const isAirConditioned = car?.details.isAirConditioned
  const isElectricFuel = car?.details.fuel === CarRentalFuelType.Electric

  return (
    <AmenitiesList>
      {isHybridFuel && (
        <AmenitiesListItem>
          <AmenitiesIcon name="carHybridPWA" />
          <AmenitiesText>{t(`${i18Base}HybridFuel`)}</AmenitiesText>
        </AmenitiesListItem>
      )}
      {isElectricFuel && (
        <AmenitiesListItem>
          <AmenitiesIcon name="electricLinePWA" />
          <AmenitiesText>{t(`${i18Base}ElectricFuel`)}</AmenitiesText>
        </AmenitiesListItem>
      )}
      <AmenitiesListItem>
        <AmenitiesIcon name="peoplePWA" />
        <AmenitiesText>
          {passengers} {t(`${i18Base}Seats`)}
        </AmenitiesText>
      </AmenitiesListItem>
      {isAutomaticTransmission && (
        <AmenitiesListItem>
          <AmenitiesIcon name="transmissionPWA" />
          <AmenitiesText>{t(`${i18Base}AutomaticTransmission`)}</AmenitiesText>
        </AmenitiesListItem>
      )}
      <AmenitiesListItem>
        <AmenitiesIcon name="bagPWA" />
        <AmenitiesText>
          {luggage} {t(`${i18Base}Bags`)}
        </AmenitiesText>
      </AmenitiesListItem>
      {unlimitedMileage && (
        <AmenitiesListItem>
          <AmenitiesIcon name="odometerPWA" />
          <AmenitiesText>{t(`${i18Base}UnlimitedMileage`)}</AmenitiesText>
        </AmenitiesListItem>
      )}
      {isAirConditioned && (
        <AmenitiesListItem>
          <AmenitiesIcon name="snowflakePWA" />
          <AmenitiesText> {t(`${i18Base}AirConditioning`)}</AmenitiesText>
        </AmenitiesListItem>
      )}
    </AmenitiesList>
  )
}
