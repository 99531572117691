import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
// Fix below. Should not import from another nested component
import { CabinClassSection } from '@etta/components/air-search-form/cabin-class-section'
import { FlightTypeItem, LeftSide, RightSide, WrapperNew } from './tabs-section-desktop-styled'

const i18Base = 'TripPlanner.BaseSearch'

type Props = {
  isDisabled?: boolean
}
export const TabsSectionDesktopNew = observer(function TabsSectionDesktopNew({
  isDisabled,
}: Props) {
  const { airSearchFormInputsActions, airSearchFormStore } = useAirSearchFormContext()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isDesktopLayoutMultiCityEnabled } = featureFlagsStore.flags
  const flightType = airSearchFormStore.searchFlightType
  const { t } = useTranslation()
  const oneWayTitle = t(i18Base + '.Input.OneWay')
  const roundTripTitle = t(i18Base + '.Input.RoundTrip')
  const multiCityTitle = t(i18Base + '.Input.MultiCity')
  const getSectionTitle = useCallback(
    (flightType: FlightType) => {
      switch (flightType) {
        case FlightType.MultiCity:
          return multiCityTitle
        case FlightType.OneWay:
          return oneWayTitle
        case FlightType.Round:
          return roundTripTitle
      }
    },
    [multiCityTitle, oneWayTitle, roundTripTitle],
  )

  if (isDisabled) {
    return (
      <WrapperNew>
        <FlightTypeItem isActive>{getSectionTitle(flightType)}</FlightTypeItem>
      </WrapperNew>
    )
  }

  return (
    <WrapperNew>
      <LeftSide>
        <FlightTypeItem
          data-tracking-id="round-trip-tab"
          aria-label={
            flightType === FlightType.Round
              ? t(i18Base + '.Input.Selected', { label: roundTripTitle })
              : t(i18Base + '.Input.PlaceAriaLabel', { label: roundTripTitle })
          }
          onClick={() => airSearchFormInputsActions.changeType(FlightType.Round)}
          isActive={flightType === FlightType.Round}>
          {roundTripTitle}
        </FlightTypeItem>
        <FlightTypeItem
          data-tracking-id="one-way-tab"
          aria-label={
            flightType === FlightType.OneWay
              ? t(i18Base + '.Input.Selected', { label: oneWayTitle })
              : t(i18Base + '.Input.PlaceAriaLabel', { label: oneWayTitle })
          }
          onClick={() => airSearchFormInputsActions.changeType(FlightType.OneWay)}
          isActive={flightType === FlightType.OneWay}>
          {oneWayTitle}
        </FlightTypeItem>
        {isDesktopLayoutMultiCityEnabled && (
          <FlightTypeItem
            data-tracking-id="multi-city-tab"
            aria-label={
              flightType === FlightType.MultiCity
                ? t(i18Base + '.Input.Selected', { label: multiCityTitle })
                : t(i18Base + '.Input.PlaceAriaLabel', { label: multiCityTitle })
            }
            onClick={() => airSearchFormInputsActions.changeType(FlightType.MultiCity)}
            isActive={flightType === FlightType.MultiCity}>
            {multiCityTitle}
          </FlightTypeItem>
        )}
      </LeftSide>
      <RightSide>
        {flightType !== FlightType.MultiCity && (
          <CabinClassSection
            cabinClass={airSearchFormStore.searchFlightsToShow[0].cabinClass}
            onChange={airSearchFormInputsActions.createCabinClassChange(0)}
          />
        )}
      </RightSide>
    </WrapperNew>
  )
})
