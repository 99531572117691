import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  overflow: hidden;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  height: 160px;
  ${KeyboardNavigationStyle}
`

export const HotelNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 156px;
  top: 30px;
  left: -78px;

  > ${Text} {
    text-align: center;
  }
`
