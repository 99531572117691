import { TripStatusType } from '@fiji/graphql/hooks'
import type { TripDetailsPostReservationValueObject } from '../../../core/value-objects/trip-details'

export class SupportedActionsMapper {
  static toTripSupportedActions(tripType: TripStatusType): TripDetailsPostReservationValueObject {
    const isUpcoming = tripType === TripStatusType.Upcoming
    return {
      isAddCarRentalAllowed: false,
      isAddFlightAllowed: false,
      isAddHotelAllowed: false,
      isBookAgainTripAllowed: false,
      isCancelTripAllowed: false,
      isCarRentalCancelAllowed: isUpcoming,
      isCarRentalChangeAllowed: isUpcoming,
      isChangeTripAllowed: false,
      isFlightCancelAllowed: false,
      isFlightChangeAllowed: false,
      isFlightChangeCarrierAllowed: false,
      isFlightChangeDestinationAllowed: false,
      isHotelCancelAllowed: false,
      isHotelChangeAllowed: false,
      isTrainCancelAllowed: false,
      isTrainChangeAllowed: false,
      flightChangeRules: [],
    }
  }
}
