import type { PropsWithChildren } from 'react'
import { Block } from '@etta/ui/block'
import { useScreenType, ScreenType } from '@fiji/modes'

type Props = PropsWithChildren<{
  withMarginTop?: boolean
  isLeadingNewSegment?: boolean
}>

const MARGIN_OFFSET_VALUE = 40
const MARGIN_MOBILE_DEFAULT = 28
const MARGIN_MOBILE_LEADING_NEW_SEGMENT = 24
const MARGIN_DESKTOP_DEFAULT = 0

export function SectionContainer({ withMarginTop, children, isLeadingNewSegment }: Props) {
  const screenType = useScreenType()
  const mobileDefaultMargin = isLeadingNewSegment
    ? MARGIN_MOBILE_LEADING_NEW_SEGMENT
    : MARGIN_MOBILE_DEFAULT

  if (screenType === ScreenType.Mobile) {
    return (
      <Block marginTop={withMarginTop ? MARGIN_OFFSET_VALUE : mobileDefaultMargin}>
        {children}
      </Block>
    )
  }

  return (
    <Block marginTop={withMarginTop ? MARGIN_OFFSET_VALUE : MARGIN_DESKTOP_DEFAULT}>
      {children}
    </Block>
  )
}
