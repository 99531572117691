import styled from 'styled-components'
import { Header as ETTAHeader } from '@etta/ui/header'

export const Header = styled(ETTAHeader).attrs((props) => ({
  ...props,
  minHeight: 72,
}))`
  padding-left: 32px;
  padding-right: 20px;
`
