import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  CreateAirSearchMutationVariables,
  CreateAirSearchMutation,
  GetFlightsQuery,
  GetFlightsQueryVariables,
  GetFlightDynamicSiteMessagesQueryVariables,
  GetFlightDynamicSiteMessagesQuery,
  GetFlightFareAttributesQuery,
  GetFlightFareAttributesQueryVariables,
  GetAlternatePolicyTripsQuery,
  GetAlternatePolicyTripsQueryVariables,
} from '@fiji/graphql/hooks'
import {
  CreateAirSearchDocument,
  GetFlightDynamicSiteMessagesDocument,
  GetFlightFareAttributesDocument,
  GetFlightsDocument,
  GetAlternatePolicyTripsDocument,
} from '@fiji/graphql/hooks'

@DataProvider()
export class AirSearchDataProvider extends GraphqlDataProvider {
  createAirSearch(input: CreateAirSearchMutationVariables['input']) {
    return this.client.mutate<CreateAirSearchMutation, CreateAirSearchMutationVariables>({
      mutation: CreateAirSearchDocument,
      variables: {
        input,
      },
    })
  }

  getFlights(input: GetFlightsQueryVariables['input'], forceRefetch = false) {
    return this.client.query<GetFlightsQuery, GetFlightsQueryVariables>({
      query: GetFlightsDocument,
      variables: {
        input,
      },
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  getFlightDsmMessages(
    input: GetFlightDynamicSiteMessagesQueryVariables['input'],
    forceRefetch = false,
  ) {
    return this.client.query<
      GetFlightDynamicSiteMessagesQuery,
      GetFlightDynamicSiteMessagesQueryVariables
    >({
      query: GetFlightDynamicSiteMessagesDocument,
      variables: {
        input,
      },
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  getFareAttributes(input: GetFlightFareAttributesQueryVariables['input'], forceRefetch = false) {
    return this.client.query<GetFlightFareAttributesQuery, GetFlightFareAttributesQueryVariables>({
      query: GetFlightFareAttributesDocument,
      variables: {
        input,
      },
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  getAlternatePolicyTrips(
    input: GetAlternatePolicyTripsQueryVariables['input'],
    forceRefetch: boolean,
  ) {
    return this.client.query<GetAlternatePolicyTripsQuery, GetAlternatePolicyTripsQueryVariables>({
      query: GetAlternatePolicyTripsDocument,
      variables: {
        input,
      },
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }
}
