import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Container } from './additional-information-styled'

export function AdditionalInformation() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const i18nBase = 'PostBooking.TripDetails.CancelTripModal.AdditionalInformation'
  const isDesktop = screenType !== ScreenType.Mobile

  return (
    <Container isDesktop={isDesktop}>
      <Block marginBottom={4}>
        <Text variant="subHeadStrong">{t(`${i18nBase}.Header`)}</Text>
      </Block>

      <Text variant="footnoteMedium">{t(`${i18nBase}.Body`)}</Text>
    </Container>
  )
}
