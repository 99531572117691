import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { TripState } from '../../types'
import { Wrapper } from './header-styled'
import { TripIcon } from './trip-icon'

type Props = {
  tripState: TripState
}

export function Header({ tripState }: Props) {
  const { confirmationType, title, subTitle } = tripState
  const textColor = (() => {
    switch (confirmationType) {
      case 'unconfirmed':
      case 'partial': {
        return 'mainText'
      }
      case 'hold':
      case 'regular':
      default:
        return 'white'
    }
  })()

  return (
    <Wrapper confirmationType={confirmationType}>
      <Block marginBottom={12}>
        <TripIcon confirmationType={confirmationType} />
      </Block>
      <Text align="center" variant="title1" color={textColor}>
        {title}
      </Text>
      {subTitle && (
        <Block marginVertical={4} marginHorizontal={35}>
          <Text variant="subHeadMedium" color={textColor} align="center">
            {subTitle}
          </Text>
        </Block>
      )}
    </Wrapper>
  )
}
