import styled from 'styled-components'
import {
  getKeyboardNavigationStyle,
  mediumWeightText,
  tripPlannerInputBck,
  white,
} from '@fiji/style'

export const Container = styled.div`
  display: block;
`

export const IconContainer = styled.span<{ isPWA?: boolean }>`
  display: flex;
  width: 42px;
  height: 24px;
  border: 1px solid ${(props) => (props.isPWA ? tripPlannerInputBck : props.theme.colors.primary3)};
  border-radius: 12px;
  margin-right: 6px;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  background: ${(props) => props.isPWA && tripPlannerInputBck};

  ${getKeyboardNavigationStyle({ borderRadius: 12, offset: 2 })}
`

export const IconItem = styled.span<{ isPWA?: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${(props) => (props.isPWA ? white : props.theme.colors.primary3)};
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 2px;
  top: 2px;
  transition: all 0.3s ease-in-out;
`

type LabelProps = {
  labelPlacement?: 'left'
}
export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  ${({ labelPlacement }: LabelProps) => {
    if (labelPlacement === 'left') {
      return `
      flex-direction: row-reverse;
      ${IconContainer} {
        margin-right: 0;
        margin-left: 6px;
      }
      `
    }
  }}
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked {
    + span {
      background-color: ${(props) => props.theme.colors.primary3};
      span {
        background-color: #fff;
        left: 100%;
        transform: translateX(calc(-100% - 2px));
      }
    }
  }
`

export const Text = styled.span`
  color: ${(props) => props.theme.secondaryColor};
  font-size: 1.4rem;
  ${mediumWeightText}// custom-font-variant
`
