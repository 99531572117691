import { useEffect } from 'react'
import { useZooKeeperConfigurationContext } from '@etta/modules/display-configuration'

export function useZooKeeperConfiguration() {
  const { zooKeeperConfigurationAction } = useZooKeeperConfigurationContext()

  useEffect((): void => {
    zooKeeperConfigurationAction.loadZooKeeperConfiguration()
  }, [zooKeeperConfigurationAction])
}
