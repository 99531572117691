import { Background } from './carousel-image-placeholder-styled'
import { NoHotelIcon } from './assets'

export function CarouselImagePlaceholder() {
  return (
    <Background>
      <NoHotelIcon />
    </Background>
  )
}
