import { useSelector } from 'react-redux'
import { observer } from 'mobx-react-lite'
import type { AppState } from '@fiji/store'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useHotelResultsContextSelector } from '@etta/screens/hotel-result-page/hotel-results-provider'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { noop } from '@fiji/utils/noop'
import { StarRatingFilter } from './star-rating-filter'
import { HotelAmenitiesFilter } from './hotel-amenities-filter'
import { BrandsFilter } from './brands-filter'
import { HotelCategoriesFilter } from './hotel-categories-filter'

type Props = {
  onEdit: () => void
}

export const ViewMode = observer(function ViewMode({ onEdit }: Props) {
  const {
    hotelFiltersAction,
    hotelSearchResultsStore,
    hotelFiltersStore,
  } = useHotelSearchResultsContext()
  const state = useHotelResultsContextSelector((state) => {
    const { locationsLabel, datesLabel } = state
    return {
      locationsLabel,
      datesLabel,
    }
  })
  const {
    hotels: { isStarRatingEnabled },
  } = useSelector((state: AppState) => state.displayConfiguration)

  return (
    <SearchResultsHeaderFull.ViewMode
      onEdit={onEdit}
      datesLabel={state.datesLabel}
      locationsLabel={state.locationsLabel}
      onFilterClick={
        hotelSearchResultsStore.isLoading ? noop : hotelFiltersAction.handleOpenFiltersModal
      }
      numberOfAppliedFilters={hotelFiltersStore.numberOfAppliedFilters}
      withFilterButton
      isDisabled={hotelSearchResultsStore.isLoading}
      isFilterActive={hotelFiltersStore.isFiltersActive}>
      <BrandsFilter />
      {isStarRatingEnabled && <StarRatingFilter />}
      <HotelAmenitiesFilter />
      <HotelCategoriesFilter />
    </SearchResultsHeaderFull.ViewMode>
  )
})
