import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CarRentalPlaceMobile } from './mobile'
import { CarRentalPlaceDesktop } from './desktop'

export function CarRentalPlace(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalPlaceMobile {...props} />}
      desktopSlot={<CarRentalPlaceDesktop {...props} />}
    />
  )
}
