import styled, { css } from 'styled-components'
import type { HeaderLayout, HeaderAlign } from '../types'

export const Layout = styled.div<{
  layout: HeaderLayout
  minHeight?: number
  align?: HeaderAlign
}>`
  display: flex;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '52px')};
  align-items: ${({ align }) => {
    switch (align) {
      case 'start':
        return 'flex-start'

      case 'center':
        return 'center'

      case 'end':
        return 'flex-end'

      default:
        return 'unset'
    }
  }};

  ${(props) => {
    switch (props.layout) {
      case 'content-view':
        return css`
          flex-direction: column;
        `
      case 'search-result':
        return css`
          min-height: 66px;
        `
      case 'sub-title':
        return ''
    }
  }}
`
