import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { CallEHIContainer } from './call-ehi-styled'

type Props = {
  notes: string
}

export function CallEHI({ notes }: Props) {
  return (
    <CallEHIContainer>
      <Icon name="infoFilledPWA" color="bodyText1" size="small" />
      <Text variant="footnoteMedium" color="mainText">
        {notes}
      </Text>
    </CallEHIContainer>
  )
}
