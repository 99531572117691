import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { SegmentValueObject } from '@etta/modules/seat-map/core/value-objects'
import { Container, Input, Title, Logo, Content } from './flight-segment-styled'

type Props = {
  flight: SegmentValueObject
  seatFeature?: string
  isActive: boolean
  onChange: () => void
}

export function FlightSegment({ flight, seatFeature = '', isActive, onChange }: Props) {
  const { id, icon, carrierName, origin, destination, features } = flight

  return (
    <Container isActive={isActive}>
      <Logo src={icon} alt={carrierName} />
      <Input value={id} checked={isActive} onChange={onChange} />
      <Content>
        <Title>
          {origin}
          <Icon name="arrowForwardPWA" color="mainText" size="small" />
          {destination}
        </Title>
        {isActive && (
          <Text variant="footnoteMedium" color="bodyText">
            {features}
          </Text>
        )}
      </Content>
      {!isActive && (
        <Text variant="subHeadStrong" color="bodyText">
          {seatFeature}
        </Text>
      )}
    </Container>
  )
}
