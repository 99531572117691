import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { RailResultsHeaderDesktop } from './rail-results-header-desktop'

export function RailSearchResultPageDesktop({ contentSlot }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <RailResultsHeaderDesktop />

      <ScreenDesktop.Content as={'main'} backgroundColor="background">
        {contentSlot}
      </ScreenDesktop.Content>
    </ScreenDesktop>
  )
}
