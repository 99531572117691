import { TripDetailsAddButton } from './trip-details-add-button'
import { ButtonsContainer } from './trip-details-add-buttons-row-styled'
import type { AddButtonSettings } from './types'

type Props = {
  settings: AddButtonSettings[]
  areTooManySegments?: boolean
  isTripInProgress?: boolean
  onOpenDialog?: () => void
}

export function TripDetailsAddButtonsRow({
  settings,
  areTooManySegments,
  isTripInProgress,
  onOpenDialog = () => {},
}: Props) {
  return (
    <ButtonsContainer>
      {settings.map((setting: AddButtonSettings) => {
        const handleClick = areTooManySegments ? onOpenDialog : setting.onClick

        return (
          <TripDetailsAddButton
            key={setting.type}
            isDisabled={setting.isDisabled || isTripInProgress}
            type={setting.type}
            onClick={handleClick}
          />
        )
      })}
    </ButtonsContainer>
  )
}
