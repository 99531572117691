import { useCallback } from 'react'

const permissions = window.navigator.permissions

export function useQueryPermission() {
  const queryPermission = useCallback(
    (permission: PermissionDescriptor): Promise<PermissionStatus | null> => {
      if (!permissions) {
        return Promise.resolve(null)
      }

      return permissions.query(permission)
    },
    [],
  )
  const checkIsPermissionGranted = useCallback(
    async (permission: PermissionName) => {
      const status = await queryPermission({ name: permission })

      if (!status) {
        return false
      }

      return status.state === 'granted'
    },
    [queryPermission],
  )

  return { checkIsPermissionGranted }
}
