export default function PriorityBoardingIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.1346 12.6C28.1741 12.68 30.5459 16.64 34.1828 18.2C35.2897 18.68 35.8036 19.96 35.3292 21.12C34.9734 21.92 34.1433 22.44 33.3131 22.44C33.0364 22.44 32.7597 22.4 32.483 22.28C31.3761 21.8 30.3878 21.2 29.4786 20.52C29.4391 21.56 29.3995 22.68 29.2019 23.84C27.9764 32.08 20.8608 39.12 20.5446 39.4C20.1493 39.8 19.5958 40 19.0819 40C18.5285 40 18.0146 39.8 17.5798 39.36C16.7496 38.48 16.7891 37.16 17.6193 36.32L17.6817 36.257C18.3456 35.5814 24.0421 29.6379 25.0907 23.12C25.4464 20.92 25.3278 18.96 25.0907 17.48C24.1024 18.6 22.9955 20.36 22.2444 22.88C21.8886 24.04 20.7027 24.68 19.5958 24.32C19.5762 24.3138 19.5567 24.3074 19.5374 24.3008L11.0395 36.4488C11.1524 36.7082 11.2004 37.0007 11.1649 37.3021C11.0601 38.1926 10.2663 38.8237 9.39198 38.7117C8.51765 38.5998 7.89386 37.7872 7.9987 36.8967C8.04782 36.4796 8.24813 36.1193 8.53709 35.8658L7.89543 35.3408C6.95166 34.5679 6.73045 33.1892 7.34403 32.146L7.48834 31.9279L10.7435 27.5317C11.9714 25.8734 14.1684 25.4253 15.893 26.4313L16.1758 26.6124L18.3921 23.3891C18.0779 22.8809 17.9794 22.2486 18.1727 21.64C19.9516 15.72 23.4303 13 24.7744 12.12C24.893 12.04 25.0116 11.92 25.1697 11.84C26.1975 11.2 27.5416 11.56 28.1346 12.6ZM30.3483 24.28L36.7918 37C37.2662 38 36.9104 39.2 35.9222 39.72C35.6059 39.84 35.3292 39.92 35.0129 39.92C34.3453 39.92 33.7401 39.604 33.364 39.0561L33.234 38.84L28.6484 29.72C29.4391 28.04 30.032 26.2 30.3483 24.28ZM8.99381 37.0241C8.95486 37.3549 9.18656 37.6567 9.51131 37.6983C9.83606 37.7398 10.1309 37.5054 10.1698 37.1747C10.2017 36.9042 10.0525 36.653 9.81935 36.5477C9.7545 36.5385 9.69219 36.522 9.6305 36.5033L9.65233 36.5006C9.32758 36.459 9.03275 36.6934 8.99381 37.0241ZM26.2371 4C28.071 4 29.5577 5.50431 29.5577 7.36001C29.5577 9.21568 28.071 10.72 26.2371 10.72C24.4031 10.72 22.9165 9.21568 22.9165 7.36001C22.9165 5.50431 24.4031 4 26.2371 4Z"
        fill="currentColor"
      />
    </svg>
  )
}
