import { useOopSegments } from '@fiji/hooks/use-oop-segments'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import type { OOPGenericData } from '@fiji/types'
import type { OopSegmentSelection } from '../types'
import { useOopReasons } from './use-oop-reasons'
import { useOopSave } from './use-oop-save'
import { useOopSchema } from './use-oop-schema'

type Args = {
  segments: TripSegmentValueObject[]
  descriptions: OOPGenericData[]
  handleClose: () => void
  oopSelections: OopSegmentSelection
  isPolicyExplanationRequired: boolean
  isHotelRequiredForOvernightTrip?: boolean
  isCarRentalRequiredViolation?: boolean
}

export function useOutOfPolicyModal({
  segments,
  descriptions,
  handleClose,
  oopSelections,
  isPolicyExplanationRequired,
  isHotelRequiredForOvernightTrip,
  isCarRentalRequiredViolation,
}: Args) {
  const { reasons } = useOopReasons(descriptions)
  const { flights, hotels, carRentals, rails } = useOopSegments(segments)
  const { submit, values, onFieldChange, errors } = useOopSchema({
    flights,
    hotels,
    carRentals,
    rails,
    isPolicyExplanationRequired,
    oopSelections,
    reasons,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
  })
  const { onSaveOopValidation, isLoading } = useOopSave({
    values,
    reasons,
    onSubmit: submit,
    handleClose,
  })

  const onClose = () => {
    if (isLoading) {
      return
    }
    handleClose()
  }

  return {
    values,
    flights,
    isLoading,
    hotels,
    carRentals,
    rails,
    reasons,
    onSaveOopValidation,
    onFieldChange,
    errors,
    onClose,
  }
}
