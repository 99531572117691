import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { AppState } from '@fiji/store'
import { appMode, screenMatcher, ScreenType } from '@fiji/modes'
import { Container, SkeletonContainer, WrappedContainer } from './deem-header-components'
import DeemHeaderSkeleton from './deem-header-skeleton'

type Props = {
  loading: boolean
  areStylesheetsLoaded: boolean
  error: boolean
  content: string
}

function DeemHeader({ loading, content, areStylesheetsLoaded, error }: Props) {
  const { t } = useTranslation()
  const { isFooterVisible, isMobileMapVisible } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )

  if (appMode.isEttaAll) {
    return null
  }

  if (screenMatcher.getScreenType() === ScreenType.Mobile) {
    return null
  }

  if (loading) {
    return (
      <Container data-tracking-id="deem-header-loading">
        <DeemHeaderSkeleton />
      </Container>
    )
  }

  if (error) {
    return <>{t('DeemHeader.Error')}</>
  }

  return (
    <Container className="deem-header">
      {!areStylesheetsLoaded ? (
        <SkeletonContainer>
          <DeemHeaderSkeleton />
        </SkeletonContainer>
      ) : (
        <WrappedContainer isHeaderVisible={!(isMobileMapVisible || !isFooterVisible)}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </WrappedContainer>
      )}
    </Container>
  )
}

export default DeemHeader
