import type { ReactNode } from 'react'
import { useTruncateDsmMessage } from '@fiji/hooks/use-truncate-dsm-message'
import { MessageSectionLayout } from './layout'

export type Props = {
  title: string
  hasMultipleItems?: boolean
  hasDesktopBannerImage?: boolean
  shouldExpandMessages: boolean
  message?: string
  messageSlot?: ReactNode
  sectionSlot?: ReactNode
}

export function MessageSection({
  title,
  message,
  hasMultipleItems,
  hasDesktopBannerImage,
  shouldExpandMessages,
  messageSlot,
  sectionSlot,
}: Props) {
  const {
    handleExpand,
    handleTruncate,
    shouldTruncateMessage,
    shouldShowShowMoreButton,
    isMessageTruncated,
    messageRef,
  } = useTruncateDsmMessage({
    hasMultipleItems,
    shouldExpandMessages,
  })

  return (
    <MessageSectionLayout
      handleExpand={handleExpand}
      handleTruncate={handleTruncate}
      shouldTruncateMessage={shouldTruncateMessage}
      shouldShowShowMoreButton={shouldShowShowMoreButton}
      isMessageTruncated={isMessageTruncated}
      hasDesktopBannerImage={hasDesktopBannerImage}
      messageRef={messageRef}
      title={title}
      message={message}
      messageSlot={messageSlot}
      sectionSlot={sectionSlot}
    />
  )
}
