import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { PaymentBlock } from '../payment-block'
import type { HotelRoom as HotelRoomType } from '../../../../types'
import { HotelRoomDatesAndType } from './hotel-room-dates-and-type'

type Props = {
  room: HotelRoomType
}

const i18nBase = 'PostBooking.TripDetails'

export function HotelRoom({ room }: Props) {
  const { t } = useTranslation()

  const { roomType, hotelName, confirmation, nightsCount, dates, payment } = room

  const roomNights = t(`${i18nBase}.HotelNights`, { count: nightsCount })

  return (
    <>
      <Block marginBottom={24}>
        <Block marginBottom={4}>
          <Text variant="footnoteStrong" color="mainText">
            {hotelName}
          </Text>
        </Block>
        <Block marginBottom={2}>
          <HotelRoomDatesAndType roomType={roomType} dates={dates} roomNights={roomNights} />
        </Block>
        {confirmation && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(`${i18nBase}.HotelConfirmation`, { confirmation })}
            </Text>
          </Block>
        )}
      </Block>
      <PaymentBlock payment={payment} />
    </>
  )
}
