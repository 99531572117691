import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import { useSegmentDetailsEcoCheckModal } from '@fiji/hooks/sustainability/use-segment-details-eco-check-modal'
import type { LayoutProps } from '../../types'
import {
  FlightDetailsEcoCheckHeader,
  FlightDetailsEcoCheckModalImage,
  FlightDetailsEcoCheckModalMobileUpperBody,
  FlightDetailsEcoCheckModalMobileLowerBody,
} from './components'
import { CarouselContainer } from './flight-details-eco-check-modal-mobile-styled'

export function FlightDetailsEcoCheckModalMobile({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: LayoutProps) {
  const {
    kgOfEmissions,
    averageEmissionsKg,
    deviationLevel,
    percentDeviation,
  } = useSegmentDetailsEcoCheckModal({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<FlightDetailsEcoCheckHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <FlightDetailsEcoCheckModalMobileUpperBody
          averageEmissionsKg={averageEmissionsKg}
          percentDeviation={percentDeviation}
          kgOfEmissions={kgOfEmissions}
          deviationLevel={deviationLevel}
        />
        <FlightDetailsEcoCheckModalImage deviationLevel={deviationLevel} />
        <CarouselContainer>
          <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={kgOfEmissions} />
        </CarouselContainer>
        <FlightDetailsEcoCheckModalMobileLowerBody deviationLevel={deviationLevel} />
      </Modal.Body>
    </Modal>
  )
}
