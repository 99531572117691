import { useTranslation } from 'react-i18next'
import { ROUTES } from '@fiji/routes'
import { useFormattedSearchPoint } from '@fiji/hooks/use-formatted-search-point'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import type { Address } from '@fiji/graphql/types'

type Args = {
  searchPoint: { address?: Address; datetime: string; name?: string }
  isDropoff?: boolean
}

const i18nBase = 'TripPlanner.CarRentalOfficeSearch.'
const COUNTRY_CODE_US = 'US'

export function useCarRentalDesktopContent({ searchPoint, isDropoff }: Args) {
  const { t } = useTranslation()

  const isConfirmationPage = useRouteMatch(ROUTES.carRental.confirmation)
  const { city, stateCode, countryCode } = searchPoint?.address || {}

  const code = countryCode === COUNTRY_CODE_US ? stateCode : countryCode
  const street = [city, code].filter(Boolean).join(', ')

  const inputPlaceholder = t(`${i18nBase}Search${isDropoff ? 'Dropoff' : 'Pickup'}`)
  const headerTitle = t(`${i18nBase}Select${isDropoff ? 'Dropoff' : 'Pickup'}`)

  const subTitle = useFormattedSearchPoint({
    street: searchPoint?.address ? street : searchPoint?.name || '',
    datetime: searchPoint.datetime,
  })

  const desktopStreet = `${city}, ${stateCode}, ${countryCode}`

  const subtitle = t(`${i18nBase}${isDropoff ? 'SubtitleDropOff' : 'SubtitlePickUp'}`)
  const desktopSubtitle = `${subtitle} ${
    searchPoint?.address ? desktopStreet : searchPoint?.name || ''
  }`

  return {
    isConfirmationPage,
    inputPlaceholder,
    headerTitle,
    subTitle,
    desktopSubtitle,
  }
}
