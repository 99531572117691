import type { PropsWithChildren } from 'react'
import type { TabName } from '../types'
import { TabContainer } from './tab-styled'
import { useTab } from './use-tab'

export type TabProps = PropsWithChildren<{
  isDisabled?: boolean
  value: TabName
  'aria-label'?: string
  'data-tracking-id'?: string
  className?: string
  tabIndex?: number
}>

export function Tab({
  children,
  value,
  isDisabled,
  className,
  'aria-label': ariaLabel,
  'data-tracking-id': dataTrackingId,
}: TabProps) {
  const { isActive, tabBasis, tabLabel, onClick } = useTab({
    value,
    ariaLabel,
  })

  return (
    <TabContainer
      className={className}
      role="tab"
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={onClick}
      tabIndex={0}
      aria-selected={isActive}
      aria-label={tabLabel}
      data-tracking-id={dataTrackingId}
      tabBasis={tabBasis}>
      {children}
    </TabContainer>
  )
}
