import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const StartsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`

export const StarItem = styled.div<{
  isHighlighted?: boolean
}>`
  display: flex;
  color: ${(p) =>
    p.isHighlighted ? `${p.theme.colors.mainText}` : `${p.theme.colors.borderDark}`};
`
