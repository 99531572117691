import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { ButtonCopyClipboard } from '@etta/ui/button-copy-clipboard'
import { QualtricsIntercept } from '@etta/modules/qualtrics/ui/intercept-modal/qualtrics-intercept'
import { IconButton } from '@etta/components/air-search-form/index-label/layout/mobile/index-label-mobile.styled'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'

import {
  Content,
  ContentDetails,
  HeaderBlock,
  Item,
  Row,
  TripImage,
  Wrapper,
} from './trip-overview-styled'

export type Props = {
  onShareClick?: () => void
}

const i18nBase = 'PostBooking.TripDetails'

export const TripOverview = observer(function TripOverview({ onShareClick }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { postBookingTripStore } = usePostBookingContext()

  const {
    trainConfirmationNumber,
    appliedRailCard,
    pnrNumber: recordLocator,
    travelerNames,
    trip: {
      name: tripName,
      cityImageUrl,
      transactionRegistryServiceItemId: transactionId,
      bookingRef: reference,
      rollUpStartTime,
      rollUpEndTime,
    },
    hasTrainOpenReturnSegment,
  } = postBookingTripStore
  const { travelerFullName } = travelerNames ?? {}
  const startDateNoTimezone = rollUpStartTime ? deleteTimezone(rollUpStartTime) : ''
  const endDateNoTimezone = rollUpEndTime ? deleteTimezone(rollUpEndTime) : ''
  let dateRange = useDateRangeTitle(startDateNoTimezone, endDateNoTimezone)
  if (hasTrainOpenReturnSegment) {
    dateRange += t(`${i18nBase}.OpenReturn.DateText`)
  }
  return (
    <>
      <QualtricsIntercept />
      <Wrapper>
        <Content>
          {cityImageUrl && <TripImage src={cityImageUrl} alt={tripName} />}
          <ContentDetails>
            <HeaderBlock>
              <Text variant="title2" color="mainText">
                {tripName}
              </Text>
              {onShareClick && (
                <IconButton onClick={onShareClick}>
                  <Icon name="sharePWA" color="bodyText" size="medium" />
                </IconButton>
              )}
            </HeaderBlock>
            <Block marginTop={9}>
              {dateRange && (
                <Row>
                  <Item>
                    <Icon name="calendarPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText">
                      {dateRange}
                    </Text>
                  </Item>
                  {travelerFullName && (
                    <Item>
                      <Icon name="travelerPWA" color="bodyText" size="small" />
                      <Text variant="footnoteMedium" color="bodyText">
                        {travelerFullName}
                      </Text>
                    </Item>
                  )}
                </Row>
              )}
              {recordLocator && (
                <Row>
                  <Item>
                    <Icon name="documentPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText">
                      {t(`${i18nBase}.PNRRecordLocator`)}
                    </Text>
                    <Text variant="footnoteMedium" color="mainText">
                      {recordLocator}
                    </Text>
                    {isMobile && (
                      <ButtonCopyClipboard value={recordLocator} color="bodyText" size="small" />
                    )}
                  </Item>
                </Row>
              )}
              {trainConfirmationNumber && (
                <Row>
                  <Item>
                    <Icon name="tripPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText" noWrap>
                      {t(`${i18nBase}.RailBookingConfirmationNumber`)}
                    </Text>
                    <Text variant="footnoteMedium" color="mainText">
                      {trainConfirmationNumber}
                    </Text>
                    {isMobile && (
                      <ButtonCopyClipboard
                        value={trainConfirmationNumber}
                        color="bodyText"
                        size="small"
                      />
                    )}
                  </Item>
                </Row>
              )}
              {reference && (
                <Row>
                  <Item>
                    <Icon name="tripPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText" noWrap>
                      {t(`${i18nBase}.DeemBookingRef`)}
                    </Text>
                    <Text variant="footnoteMedium" color="mainText">
                      {reference}
                    </Text>
                    {isMobile && (
                      <ButtonCopyClipboard value={reference} color="bodyText" size="small" />
                    )}
                  </Item>
                </Row>
              )}
              {appliedRailCard && (
                <Row>
                  <Item>
                    <Icon name="loyaltyCardPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText" noWrap>
                      {t(`${i18nBase}.AppliedRailCard`)}
                    </Text>
                    <Text variant="footnoteMedium" color="mainText">
                      {appliedRailCard}
                    </Text>
                    {isMobile && (
                      <ButtonCopyClipboard value={appliedRailCard} color="bodyText" size="small" />
                    )}
                  </Item>
                </Row>
              )}
              {transactionId && (
                <Row>
                  <Item>
                    <Icon name="documentPWA" color="bodyText" size="small" />
                    <Text variant="footnoteMedium" color="bodyText">
                      {t(`${i18nBase}.TransactionId`)}
                    </Text>
                    <Text variant="footnoteMedium" color="mainText">
                      {transactionId}
                    </Text>
                  </Item>
                </Row>
              )}
            </Block>
          </ContentDetails>
        </Content>
      </Wrapper>
    </>
  )
})
