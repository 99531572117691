export default function NoCarryOnBagIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.9957 5.90956C32.7131 6.32376 32.9589 7.24116 32.5447 7.9586L14.2163 39.7044C13.8021 40.4218 12.8847 40.6676 12.1673 40.2534C11.4498 39.8392 11.204 38.9218 11.6182 38.2044L29.9467 6.4586C30.3609 5.74116 31.2783 5.49534 31.9957 5.90956ZM31 16.432V32C31 34.2091 29.2091 36 27 36V38C27 39.1046 26.1046 40 25 40C23.8954 40 23 39.1046 23 38V36H19.702L21.204 33.4H27C27.7088 33.4 28.2945 32.8733 28.3872 32.19L28.4 32V20.936L31 16.432ZM19 37.218V38C19 38.96 18.3236 39.7621 17.4213 39.9555L19 37.218ZM27 4V5.40438C26.9175 5.52036 26.8397 5.64128 26.7671 5.76702L25.476 8L25 8.826L21.666 14.6H15C14.2912 14.6 13.7055 15.1267 13.6128 15.81L13.6 16L13.598 28.576L11.1019 32.9013C11.0352 32.6116 11 32.3099 11 32V16C11 13.7909 12.7909 12 15 12H17V8H15V4H27ZM23 8H19V12H23V8Z"
        fill="currentColor"
      />
    </svg>
  )
}
