import type { ConcernList } from '../../types'
import { ScoreListLayout } from './layout'

type Props = {
  isOpen: boolean
  concernList: ConcernList
}

export function ScoreList({ isOpen, concernList }: Props) {
  return <ScoreListLayout isOpen={isOpen} concernList={concernList} />
}
