import type { RailCard } from '@fiji/graphql/types'
import type { RailCardGroup } from '@etta/components/rail-card-modal/types'

export function getMappedGroups(uniqueGroups: string[], cards?: RailCard[]): RailCardGroup[] {
  return uniqueGroups.map((group) => {
    return {
      name: group,
      cards: cards
        ? cards
            .reduce<RailCard[]>((acc, card) => {
              if (card?.groupName !== group) {
                return acc
              }
              return [...acc, card]
            }, [])
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        : [],
    }
  })
}
