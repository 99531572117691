import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { TripDetailsDynamicSiteMessagesLayout } from './layout'

export const TripDetailsDynamicSiteMessages = observer(function TripDetailsDynamicSiteMessages() {
  const { postBookingTripStore } = usePostBookingContext()
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { tripDetailsDSMLightboxToggler } = dynamicSiteMessagesTogglersStore
  const { messages } = postBookingTripStore.trip
  if (!messages.length) {
    return null
  }

  return (
    <>
      <TripDetailsDynamicSiteMessagesLayout messages={messages} />
      <DynamicSiteMessagesLightboxModal
        messages={messages}
        isVisible={tripDetailsDSMLightboxToggler.isOpen}
        onClose={tripDetailsDSMLightboxToggler.handleClose}
      />
    </>
  )
})
