import styled, { css } from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const Item = styled.div<{ isActiveStateDisabled?: boolean }>`
  &:hover {
    cursor: pointer;
  }

  ${({ isActiveStateDisabled }) =>
    !isActiveStateDisabled &&
    css`
      &:active {
        background-color: ${({ theme }) => theme.colors.background};
      }
    `}

  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const List = styled.div``
