import type { CovidStatisticArea } from '../../../types'
import { CovidInformationStatisticLayout } from './layout'

type Props = {
  i18nBase: string
  covidStatistic: CovidStatisticArea
  shouldColumnNameRender?: boolean
}

export function CovidInformationStatistic({
  i18nBase,
  covidStatistic,
  shouldColumnNameRender = true,
}: Props) {
  return (
    <CovidInformationStatisticLayout
      i18nBase={i18nBase}
      covidStatistic={covidStatistic}
      shouldColumnNameRender={shouldColumnNameRender}
    />
  )
}
