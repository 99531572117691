import { TripStatusType } from '@fiji/graphql/hooks'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { TripEntity } from '../../../core/entities/trip.entity'
import { TripStatus } from '../../../core/enums'
import { ReservationMapper } from './trip-reservation.mapper'
import { SupportedActionsMapper } from './supported-actions.mapper'
import { TripCostSummaryMapper } from './trip-cost-summary.mapper'
import type { GetTripDetails } from './types'

const tripStatusMap: Record<TripStatusType, TripStatus> = {
  [TripStatusType.Canceled]: TripStatus.Cancelled,
  [TripStatusType.Past]: TripStatus.Completed,
  [TripStatusType.Unspecified]: TripStatus.Unknown,
  [TripStatusType.Upcoming]: TripStatus.Confirmed,
}

export class TripMapper {
  static toTripEntity(trip: GetTripDetails): TripEntity {
    const { tripSummary, tripId, reservations, tripType, tripCostDetails } = trip
    const { title, startDate, endDate } = tripSummary
    const type = tripStatusMap[tripType]
    const segments = ReservationMapper.toTripSegments(reservations)
    const costSummary = TripCostSummaryMapper.toTripCostSummary(tripCostDetails, reservations)

    return {
      type,
      failedSegments: [],
      name: title,
      bookingRef: tripId,
      segments,
      rollUpStartTime: deleteTimezone(startDate),
      rollUpEndTime: deleteTimezone(endDate),
      processId: tripId,
      supportedActions: SupportedActionsMapper.toTripSupportedActions(tripType),
      isMultiCity: false,
      messages: [],
      tripId: Number(tripId) || 0,
      costSummary,
      // TODO: map appliedRailCards in case it's necessary for mod2.1
      appliedRailCards: [],
    }
  }
}
