import type { LayoutProps } from '../../types'
import { HotelDetailsEcoCheckDesktopComponent } from './hotel-details-eco-check-desktop-component'
import { HotelDetailsEcoCheckDesktopSkeleton } from './hotel-details-eco-check-desktop-skeleton'

export function HotelDetailsEcoCheckDesktop(props: LayoutProps) {
  const {
    loading,
    tonnesOfEmissions,
    averageLocationSustainabilityScore,
    equivalences,
    sustainabilityScore,
    certifications,
  } = props
  if (loading) {
    return <HotelDetailsEcoCheckDesktopSkeleton />
  }

  if (!tonnesOfEmissions || !averageLocationSustainabilityScore || !sustainabilityScore) {
    return null
  }

  return (
    <HotelDetailsEcoCheckDesktopComponent
      certifications={certifications || []}
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      sustainabilityScore={sustainabilityScore}
      averageSustainabilityScore={averageLocationSustainabilityScore}
    />
  )
}
