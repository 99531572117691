import { useTranslation } from 'react-i18next'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { SuperBrandSelect } from './super-brand-select'
import { BrandCheckbox } from './brand-checkbox'
import { useBrandsMultiSelect } from './use-brands-multi-select'

type Props = {
  visibleRows?: number
  isSuperBrandExpanded?: boolean
  isEmptyBlockHidden?: boolean
  superBrands: SuperBrandFilter[]
  brands: BrandFilter[]
  selectedIdsMap: Set<string>
  onSelect: (idsMap: Set<string>) => void
  isNewLayout?: boolean
}

const i18Base = 'SearchFilterPanel'

export function BrandsMultiSelect({
  visibleRows,
  brands,
  onSelect,
  selectedIdsMap,
  superBrands,
  isSuperBrandExpanded,
  isEmptyBlockHidden,
  isNewLayout = false,
}: Props) {
  const { t } = useTranslation()
  const { onBrandChange, brandsView, superBrandsView, isEmpty } = useBrandsMultiSelect({
    visibleRows,
    brands,
    onSelect,
    selectedIdsMap,
    superBrands,
  })

  if (isEmpty && isEmptyBlockHidden) {
    return null
  }

  if (isEmpty) {
    return (
      <Block paddingTop={8} paddingBottom={9}>
        <Text variant="subHeadMedium" color="mainText">
          {t(i18Base + '.NoResultsFound')}
        </Text>
      </Block>
    )
  }

  return (
    <>
      {superBrandsView.map((superBrand) => {
        return (
          <SuperBrandSelect
            isNewLayout={isNewLayout}
            isSuperBrandExpanded={isSuperBrandExpanded}
            key={superBrand.brandId}
            superBrand={superBrand}
            selectedIdsMap={selectedIdsMap}
            onSelect={onSelect}
          />
        )
      })}
      {brandsView?.map((brand: BrandFilter) => {
        const isCheckedItem = selectedIdsMap.has(brand.brandId)
        return (
          <BrandCheckbox
            isNewLayout={isNewLayout}
            key={brand.brandId}
            brand={brand}
            isChecked={isCheckedItem}
            onChange={(isChecked) => onBrandChange(isChecked, brand)}
          />
        )
      })}
    </>
  )
}
