import i18n from 'i18next'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { PenaltyType } from '@fiji/enums'
import type { PenaltyFee } from '@fiji/graphql/types'

const i18nBasePath = 'TripReview.RulesAndRestrictions.'
export function getFlightRules(segment: FlightSegmentEntity) {
  let label
  let flightRules: string[] = []

  // get fare rules
  if (segment.fareRules) {
    flightRules.push(...segment.fareRules)
  }

  // get penalty fee rules from each segment
  segment.segments.forEach((stop, index) => {
    flightRules.push(...mapFlightPenaltyFees(stop.penaltyFees))

    // create a label using first segment's airport code
    if (index === 0) {
      label = i18n.t(i18nBasePath + 'FlightFrom') + ' ' + stop.departure.airportCode + ':'
    }
  })

  // remove duplicate rules
  flightRules = Array.from(new Set(flightRules))

  return {
    rules: flightRules,
    label: label || '',
  }
}

function mapFlightPenaltyFees(penaltyFees?: Array<PenaltyFee | null> | null): string[] {
  const fees: string[] = []

  penaltyFees &&
    penaltyFees.forEach((fee) => {
      if (fee?.type === PenaltyType.AdvancedPurchase) {
        fee.days
          ? fees.push(
              i18n.t(i18nBasePath + 'AdvancedPurchase', {
                days: fee.days,
              }),
            )
          : fees.push(i18n.t(i18nBasePath + 'AdvancedPurchaseNoDays'))
      }
    })
  return fees
}
