import type { MouseEvent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { PlainTime } from '@fiji/types'
import { Portal } from '@etta/ui/portal'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { MaterialTimePicker } from '@etta/ui/material-time-picker'
import { Link } from '@etta/ui/link'
import {
  BackDrop,
  Container,
  DialogContainer,
  DialogContainerRoot,
  Footer,
  Header,
} from './mobility-material-time-picker-modal-styled'

type Props = {
  isOpen: boolean
  initialTime?: PlainTime
  onClose: () => void
  onSubmit: (selectedTime: PlainTime) => void
  portalTarget?: string
}

const i18nBase = 'Mobility.MaterialTimePickerModal.'

export function MobilityMaterialTimePickerModal({
  isOpen,
  initialTime,
  onClose,
  onSubmit,
  portalTarget = '#mobility-modals-container',
}: Props) {
  const [isShownDialog, setIsShownDialog] = useState(false)
  const [isShownContinueButton, setIsShownContinueButton] = useState(false)
  const [time, setTime] = useState<PlainTime>()
  const { t } = useTranslation()
  const clearedInitialTime: PlainTime = { hours: 0, minutes: 0 }

  const handleTapBackDrop = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleTapContinue = () => {
    onSubmit(time!)
    onClose()
  }

  return (
    <Portal target={portalTarget}>
      <Container isShown={isShownDialog || isOpen} role={'dialog'}>
        <BackDrop aria-hidden onClick={() => onClose()} />
        <DialogAnimation
          isOpen={isOpen}
          onEntered={() => setIsShownDialog(true)}
          onExited={() => setIsShownDialog(false)}
          isAnimated>
          <DialogContainerRoot aria-hidden onClick={handleTapBackDrop}>
            <DialogContainer>
              <Header>{t(i18nBase + 'SelectTime')}</Header>
              <MaterialTimePicker
                initialTime={isShownDialog ? initialTime : clearedInitialTime}
                onChange={setTime}
                onModeChange={(mode) => setIsShownContinueButton(mode === 'minutes')}
              />
              <Footer>
                {isShownContinueButton && (
                  <Link onClick={handleTapContinue}>{t(i18nBase + 'Continue')}</Link>
                )}
              </Footer>
            </DialogContainer>
          </DialogContainerRoot>
        </DialogAnimation>
      </Container>
    </Portal>
  )
}
