import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import type { TripCost, TripCostSummary } from '@fiji/graphql/types'
import { calculateTrainTripTotal } from '@fiji/utils/calculate-train-trip-total'
import { calculateTripTotal } from '@fiji/utils/calculate-trip-total'

const DEFAULT_TRIP_COST = {
  total: { primary: { amount: 0, currency: 'USD' } },
  base: { primary: { amount: 0, currency: 'USD' } },
  feesAndTaxes: { primary: { amount: 0, currency: 'USD' } },
  allowUnusedTicketOptout: false,
}

export function useTripCost(tripCost?: TripCostSummary | null) {
  const { postBookingTripStore } = usePostBookingContext()

  const flightCostFiltered = tripCost?.payNow?.flight?.filter((cost): cost is TripCost => !!cost)

  const flightTripCost = calculateTripTotal(flightCostFiltered) || DEFAULT_TRIP_COST

  const payNowHotel = tripCost?.payNow?.hotel
  const payLaterHotel = tripCost?.payLater?.hotel

  const hotelTripCost = payNowHotel || payLaterHotel || DEFAULT_TRIP_COST
  const payNowHotelTripCost = payNowHotel || DEFAULT_TRIP_COST
  const payLaterHotelTripCost = payLaterHotel || DEFAULT_TRIP_COST

  const payNowCarRental = tripCost?.payNow?.car
  const payLaterCarRental = tripCost?.payLater?.car

  const carRentalTripCost = payNowCarRental || payLaterCarRental || DEFAULT_TRIP_COST
  const payNowCarRentalTripCost = payNowCarRental || DEFAULT_TRIP_COST
  const payLaterCarRentalTripCost = payLaterCarRental || DEFAULT_TRIP_COST

  const isHotelPayingNow = !!payNowHotel
  const isHotelPayingLater = !!payLaterHotel
  const isCarRentalPayingNow = !!payNowCarRental
  const isCarRentalPayingLater = !!payLaterCarRental
  // TODO: Decouple RTP and PostBooking information - Trip Confirmation page using RTP info right now
  // Prioritise to get train cost from PostBookingTripStore if it's available
  // The information provided by this component is used in RTP but also in the confirmation page
  const trainCostFiltered = tripCost?.payNow?.train?.filter((cost): cost is TripCost => !!cost)
  const postBookingTrainCost = postBookingTripStore.tripCostSummary?.train
  const railTripCost =
    calculateTrainTripTotal(postBookingTrainCost) ||
    calculateTripTotal(trainCostFiltered) ||
    DEFAULT_TRIP_COST

  return {
    isCarRentalPayingNow,
    isCarRentalPayingLater,
    isHotelPayingNow,
    isHotelPayingLater,
    payNowHotelTripCost,
    payLaterHotelTripCost,
    flightTripCost,
    hotelTripCost,
    carRentalTripCost,
    payNowCarRentalTripCost,
    payLaterCarRentalTripCost,
    railTripCost,
  }
}
