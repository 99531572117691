import type { AddressInput } from '@fiji/graphql/types'
import type { PlainTime as Time, Place } from '@fiji/types'

export type PlaceWithAddress = Place & { address?: string }

export type SelectedValue = {
  dropOff: { place?: PlaceWithAddress; address?: AddressInput }
  dropOffAddress?: string
  pickUp: { place?: PlaceWithAddress; address?: AddressInput }
  pickUpAddress?: string
  dateTime: SelectedDateTime
}

export type SelectedDateTime = {
  date?: Date
  time?: Time
}

export enum SelectionState {
  dropOff = 0,
  pickUp = 1,
  dateTime = 2,
}
