import { useTranslation } from 'react-i18next'
import { BlockUnavailableLayout } from './layout'

type Props = {
  title: string
  subTitle: string
  onContinue: () => void
  isLoading: boolean
}

export function BlockUnavailable({ title, subTitle, onContinue, isLoading }: Props) {
  const { t } = useTranslation()
  const buttonTitle = t('AirSeatMap.Continue')

  return (
    <BlockUnavailableLayout
      title={title}
      subTitle={subTitle}
      buttonTitle={buttonTitle}
      onContinue={onContinue}
      isLoading={isLoading}
    />
  )
}
