import { lazy } from 'react'
import type { RouteItem } from '@fiji/routes/types'
import { ROUTES, getDocumentTitle } from '@fiji/routes'

const UserActivationPage = lazy(() => import('./user-activation'))

export const activationRoutes: RouteItem[] = [
  {
    route: {
      path: ROUTES.userActivation,
      exact: true,
    },
    documentTitle: getDocumentTitle('UserActivation'),
    isNavBarHidden: true,
    transitionIndex: 1,
    transitionGroup: 'slide',
    component: () => <UserActivationPage />,
  },
]
