import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { TravelPreferencesErrors } from '../core/errors/travel-preferences.errors'
import type { TravelPreferencesValueObject } from '../core/value-objects/travel-preferences.value-object'
import { TravelPreferencesProvider } from './travel-preferences.data-provider/travel-preferences.data-provider'
import { TravelPreferencesMapper } from './travel-preferences.mapper'
import type { TravelPreferencesAdapterResult } from './types'

@Adapter()
export class TravelPreferencesAdapter {
  constructor(
    @Inject()
    private travelPreferencesProvider: TravelPreferencesProvider,
  ) {}

  async loadTravelPreference(): TravelPreferencesAdapterResult<TravelPreferencesValueObject> {
    try {
      const { data, error } = await this.travelPreferencesProvider.getTravelPreferences()

      if (error) {
        return Result.Err(new TravelPreferencesErrors.GetTravelPreferencesErrorResponse(error))
      }

      if (!data.travelPreferences) {
        return Result.Err(new TravelPreferencesErrors.GetTravelPreferencesErrorResponse('No data'))
      }

      return Result.Ok(
        TravelPreferencesMapper.toTravelPreferencesValueObject(data.travelPreferences),
      )
    } catch (e) {
      return Result.Err(new TravelPreferencesErrors.GetTravelPreferencesUnexpectedError(e))
    }
  }
}
