import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  flex-direction: row;
`

export const ItemContent = styled.div`
  overflow: hidden;
  flex-grow: 1;
  margin-left: 8px;
`

export const ItemTitle = styled.div`
  margin-bottom: 6px;
`

export const ContentWrapper = styled.div`
  padding: 12px 16px 8px 20px;
  background: ${(p) => p.theme.colors.background};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const RideHailSegmentContainer = styled.div`
  padding: 16px;
  background: ${(p) => p.theme.colors.background};
`

export const CostWrapper = styled.div`
  padding-top: 8px;
  align-self: flex-start;
`
