import styled from 'styled-components'
import { headline } from '@fiji/style'

export const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;

  > div {
    align-items: center;
  }

  > div:not(:first-child) {
    margin-left: 5px;
  }
`

export const Type = styled.div`
  ${headline}
`
