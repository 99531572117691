import { Store } from '@etta/di'
// TODO: remove fiji import
import type { Place } from '@fiji/types'

@Store()
export class HomeAirportStore {
  homeAirport: null | Place = null

  isHomeAirportLoading: boolean = true

  setHomeAirport(place: Place | null) {
    this.homeAirport = place
  }

  setIsHomeAirportLoading(value: boolean) {
    this.isHomeAirportLoading = value
  }
}
