import i18n from 'i18next'

const i18nBasePath = 'Dimension.Time.'

export function calculateTimeFromSeconds(duration: number): string {
  const hours = ~~(duration / 3600)
  let minutes: string | number = Number(~~((duration % 3600) / 60))
  let result = ''

  if (hours > 0) {
    result += i18n.t(i18nBasePath + 'Hours', { count: hours }) + ' '
  }

  if (minutes === 0 && hours === 0) {
    minutes = '<1'
  }

  result += minutes + ` ${i18n.t(i18nBasePath + 'Minutes')} `

  return result
}
