import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { NavigationErrorsInstances } from '@etta/modules/navigation/core/navigation.errors'
import { NavigationErrors } from '@etta/modules/navigation/core/navigation.errors'
import type { PromisedResult } from '@etta/core/type-utils'
import { NavigationDataProvider } from './navigation.data-provider'

@Adapter()
export class NavigationAdapter {
  constructor(
    @Inject()
    private navigationDataProvider: NavigationDataProvider,
  ) {}

  async setRcSessionCookie({
    url,
  }: {
    url: string
  }): PromisedResult<null, NavigationErrorsInstances> {
    try {
      const { errors } = await this.navigationDataProvider.setRcSessionCookie({
        url,
      })

      if (errors) {
        return Result.Err(new NavigationErrors.SetRCSessionResponse(errors))
      }
      return Result.Ok(null)
    } catch (e) {
      return Result.Err(new NavigationErrors.UnexpectedErrorRCSessionSet(e))
    }
  }
}
