import type { ReactNode } from 'react'
import type { SeatIconName } from '../seat-icon'
import { SeatIcon } from '../seat-icon'
import { IconContainer, LegendItem } from './legend-styled'

type LegendNodeProps = {
  children: ReactNode
  icon: SeatIconName
}

export function LegendNode({ children, icon }: LegendNodeProps) {
  return (
    <LegendItem>
      <IconContainer>
        <SeatIcon icon={icon} />
      </IconContainer>
      {children}
    </LegendItem>
  )
}
