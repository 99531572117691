import { Inject, Service } from '@etta/di'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { MobilitySearchResultErrorType } from '@etta/components/mobility-search-result-dialog'
import { SearchRideHailResultErrors } from '../../core'
import type { RideHailAirportPickupFlightValueObject } from '../../core/value-objects/ride-hail-airport-pickup.value-object'
import { RefinementOptionsEnum } from '../../core/enum'
import { RideHailSearchAdapter } from '../../infra/ride-hail-search.adapter'
import { RideHailAirportPickupStore } from '../store/ride-hail-airport-pickup-store'
import { mapSearchRideHailErrorReason } from '../store/map-search-ride-hail-error'
import { RideHailSearchLocationRefinementStore } from '../store/ride-hail-search-location-refinement.store'
import { SearchRideHailStore } from '../store/search-ride-hail.store'
import { mapToRideHailLocation } from './map-to-ride-hail-location'

@Service()
export class SearchRideHailService {
  constructor(
    @Inject() private rideHailSearchAdapter: RideHailSearchAdapter,
    @Inject() private searchRideHailStore: SearchRideHailStore,
    @Inject() private rideHailSearchLocationRefinementStore: RideHailSearchLocationRefinementStore,
    @Inject() private rideHailAirportPickupStore: RideHailAirportPickupStore,
  ) {}

  async loadSearchRideHail(isOnDemand: boolean) {
    this.searchRideHailStore.setIsLoading(true)
    this.searchRideHailStore.setSearchRideHailError(null)
    const params = this.getSearchRideHailParams()

    const result = await this.rideHailSearchAdapter.getSearchRideHail(params)

    result.match({
      Ok: (results) => {
        this.searchRideHailStore.setIsLoading(false)
        if (results.results.length === 0) {
          if (isOnDemand) {
            this.searchRideHailStore.setSearchRideHailError(
              MobilitySearchResultErrorType.NoRidesInLocation,
            )
          } else {
            this.searchRideHailStore.setSearchRideHailError(
              MobilitySearchResultErrorType.NoAdvancedBookingAvailable,
            )
          }
        } else {
          this.searchRideHailStore.setSearchRideHailResults(results)
        }
      },
      Err: (err) => {
        this.searchRideHailStore.setIsLoading(false)
        this.searchRideHailStore.dropSearchRideHailResults()
        switch (true) {
          case err instanceof SearchRideHailResultErrors.SearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(mapSearchRideHailErrorReason(err.error))
            break
          case err instanceof SearchRideHailResultErrors.UnexpectedSearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(MobilitySearchResultErrorType.Undefined)
        }
      },
    })
  }

  async loadSearchRideHailWithFlight(flight: RideHailAirportPickupFlightValueObject) {
    this.searchRideHailStore.setIsLoading(true)
    this.searchRideHailStore.setSearchRideHailError(null)
    const { pickUp, dropOff, pickUpDate, pickUpTime } = this.getSearchRideHailParams()

    const result = await this.rideHailSearchAdapter.getSearchRideHail({
      pickUp,
      dropOff,
      pickUpDate,
      pickUpTime,
      flight: flight,
    })

    result.match({
      Ok: () => {
        this.searchRideHailStore.setIsLoading(false)
      },
      Err: (err) => {
        this.searchRideHailStore.setIsLoading(false)
        switch (true) {
          case err instanceof SearchRideHailResultErrors.SearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(mapSearchRideHailErrorReason(err.error))
            break
          case err instanceof SearchRideHailResultErrors.UnexpectedSearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(MobilitySearchResultErrorType.Undefined)
        }
      },
    })
  }

  async loadSearchRideHailWithLocationOption() {
    this.searchRideHailStore.setIsLoading(true)
    this.searchRideHailStore.setSearchRideHailError(null)
    const params = this.getSearchRideHailParams()

    const result = await this.rideHailSearchAdapter.getSearchRideHail(params)

    const displayName = this.searchRideHailStore.selectedSearchRideHail?.displayName

    result.match({
      Ok: (results) => {
        this.searchRideHailStore.setIsLoading(false)
        const matchResult = results.results.find((result) => result.displayName === displayName)
        this.searchRideHailStore.setSearchRideHailResults(results)
        if (matchResult) {
          this.searchRideHailStore.setSelectedRideHail(matchResult)
        }
      },
      Err: (err) => {
        this.searchRideHailStore.setIsLoading(false)
        switch (true) {
          case err instanceof SearchRideHailResultErrors.SearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(mapSearchRideHailErrorReason(err.error))
            break
          case err instanceof SearchRideHailResultErrors.UnexpectedSearchRideHailResultError:
            this.searchRideHailStore.setSearchRideHailError(MobilitySearchResultErrorType.Undefined)
        }
      },
    })
  }

  private getSearchRideHailParams() {
    const {
      pickUpTime,
      pickUpDate,
      pickUpPlace,
      pickUpAddress,
      dropOffPlace,
      dropOffAddress,
    } = this.searchRideHailStore.searchRideHailForm

    const {
      refinementOptionsAction,
      selectedAccessPoint,
    } = this.rideHailSearchLocationRefinementStore

    const pickUpAp =
      selectedAccessPoint && refinementOptionsAction === RefinementOptionsEnum.PickUpZoning
        ? selectedAccessPoint
        : undefined

    const dropOffAp =
      selectedAccessPoint &&
      (refinementOptionsAction === RefinementOptionsEnum.DropOffZoning ||
        refinementOptionsAction === RefinementOptionsEnum.NoRefinement)
        ? selectedAccessPoint
        : undefined

    return {
      pickUp: mapToRideHailLocation(pickUpPlace, pickUpAddress, pickUpAp),
      dropOff: mapToRideHailLocation(dropOffPlace, dropOffAddress, dropOffAp),
      pickUpDate: pickUpDate !== undefined ? getFormattedTime(pickUpDate, 'yyyy-MM-dd') : undefined,
      pickUpTime: pickUpTime !== undefined ? getFormattedTime(pickUpTime) : undefined,
      flight: this.rideHailAirportPickupStore.arrivalFlight || undefined,
    }
  }
}
