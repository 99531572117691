import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type { CheckoutCartMutation, CheckoutCartMutationVariables } from '@fiji/graphql/hooks'
import { CheckoutCartDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class CheckoutCartDataProvider extends GraphqlDataProvider {
  handleCheckoutCart(input: CheckoutCartMutationVariables['input']) {
    return this.client.mutate<CheckoutCartMutation, CheckoutCartMutationVariables>({
      mutation: CheckoutCartDocument,
      variables: { input },
    })
  }
}
