import type { IconBadgeVariant } from '@etta/ui/badge/types'
import type { IconNames } from '@etta/ui/icon'

export function getIconPWAName(variant: IconBadgeVariant): IconNames | undefined {
  switch (variant) {
    case 'outOfPolicy':
      return 'outOfPolicyPWA'
    case 'applePreferred':
    case 'preferred':
    case 'preferredVendor':
    case 'alternatePreferred':
    case 'preferredCaption':
      return 'preferredPWA'
    case 'customPreferred':
      return 'customPreferredPWA'
    case 'nonRefundable':
      return 'nonRefundableFilledPWA'
    case 'refundable':
    case 'corporateRates':
    case 'privateRates':
    case 'privateRate':
    case 'penalityApplies':
    case 'refundableGray':
    case 'cancellable':
      return 'refundableFilledPWA'
    case 'webFare':
      return 'webFarePWA'
    case 'hybridCar':
      return 'carHybridPWA'
    case 'reserve':
    case 'GBTDirect':
    case 'tripSource':
    case 'prepaid':
    case 'deposit':
    case 'guarantee':
    case 'unRestricted':
      return 'checkFilledPWA'
    case 'tripAdvisor':
      return 'tripadvisorPWA'
    case 'distance':
    case 'locationHotel':
      return 'locationPWA'
    case 'ecoFriendly':
      return 'ecoFriendlyPWA'
    case 'seatMapNotAvailable':
      return 'infoOutlinePWA'
    case 'creditCard':
      return 'creditCardPWA'
    case 'eticket':
      return 'eticketPWA'
  }
}
