import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { TripTabs } from '../../trip-tabs'
import { Subheader } from './subheader'
import { ContentWrapper } from './trips-page-desktop-styled'

export function TripsPageDesktop({ tripListSlot, handleChangeTab, activeTab }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <Subheader>
        <TripTabs onChange={handleChangeTab} activeTab={activeTab} />
      </Subheader>
      <ContentWrapper as={'main'} backgroundColor="background">
        {tripListSlot}
      </ContentWrapper>
    </ScreenDesktop>
  )
}
