import { useTranslation } from 'react-i18next'

import { Button } from '@etta/ui/button'
import { ErrorContainer } from '@etta/ui/error-container'
import { Swap } from '@etta/ui/swap'
import type { LayoutProps } from '../../types'
import { ContentDesktop, Footer } from './purchase-additional-info-form-desktop-styled'

export function PurchaseAdditionalInfoFormDesktop({
  contentSlot,
  isLoading,
  onSubmitHandler,
  isButtonDisabled,
  onReload,
  isError,
}: LayoutProps) {
  const i18nBase = 'AdditionalInfoForm.'
  const { t } = useTranslation()

  return (
    <>
      <ContentDesktop>
        {!isLoading && !isError && <div>{t(i18nBase + 'HelpText')}</div>}
        <Swap
          is={isError}
          isSlot={
            <ErrorContainer
              onReload={onReload}
              title={t(i18nBase + 'Errors.UnableToLoadInfo')}
              align="central"
            />
          }>
          {contentSlot}
        </Swap>
      </ContentDesktop>
      <Footer>
        <Button
          minWidth={14}
          onClick={onSubmitHandler}
          size="normal-wide"
          disabled={isButtonDisabled}
          data-tracking-id="additional-info-continue-button">
          {t(`${i18nBase}ButtonText`)}
        </Button>
      </Footer>
    </>
  )
}
