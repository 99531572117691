import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import type { Arguments, HookReturnType } from './types'

export function useFlightSegment({ segment, isMultiCityFlight }: Arguments): HookReturnType {
  const iconName =
    segment.isSameCarrierName &&
    segment.isRoundTripLeg &&
    segment.isRoundTripCost &&
    !isMultiCityFlight
      ? 'sameDayArrowsPWA'
      : 'flightArrowPWA'

  const firstSegment = segment.segments[0]
  const segmentsLength = segment.segments.length
  const lasSegment = segment.segments[segmentsLength - 1]
  const originAddress = firstSegment.departure.airportCode || ''
  const destinationAddress = lasSegment.arrival.airportCode || ''

  const { mainCost: baseRateCost } = formatRate(segment.netRate, {
    morpheme: 'none',
  })

  const { getCabinClassLabel } = useCabinClassLabels()
  const serviceClass = segment.segments[0]?.serviceClass
  const cabinClassLabel = getCabinClassLabel(serviceClass)

  const departureDateTime = tripReviewFormatDate(
    TripReviewDateType.DayMonthDateAndTime,
    segment?.departureDateTime,
  )

  const isWebFare = useMemo(() => {
    let isWebFare: boolean = false
    segment.segments?.forEach((segment) => {
      if (segment.bookingProvider === 'TRAVEL_FUSION') {
        isWebFare = true
      }
    })

    return isWebFare
  }, [segment])

  return {
    iconName,
    originAddress,
    destinationAddress,
    baseRateCost,
    cabinClassLabel,
    departureDateTime,
    isWebFare,
    isNDCFare: !!segment.isNDCFare,
  }
}
