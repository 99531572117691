import { Action, Inject } from '@etta/di'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { BaseSettingsStore } from '@etta/modules/settings/interface/stores/base.store'
import { BaseSettingsService } from '../services/base.service'
import { HomeAddressStore } from '../stores/home-address.store'
import type { HomeAddressInput } from '../stores/home-address.store'

@Action()
export class HomeAddressActions {
  constructor(
    @Inject()
    private readonly homeAddressStore: HomeAddressStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  handleFieldChange<T extends keyof Omit<HomeAddressInput, 'homePhone'>>(field: T) {
    return (value: HomeAddressInput[T]) => {
      this.homeAddressStore.setIsSaveButtonDisabled(false)
      return this.homeAddressStore.setHomeAddressValue(field, value)
    }
  }

  handlePhoneChange(field: 'CountryCode' | 'Number', value: string) {
    this.homeAddressStore.setIsSaveButtonDisabled(false)
    return this.homeAddressStore.setHomePhoneValue(field, value)
  }

  async handleHomeAddressSave() {
    const validatorCheck = await this.homeAddressStore.homeAddressValidator.submit()
    const additionalValidatorCheck = await this.homeAddressStore.additionalInformationValidator.submit()
    if (!validatorCheck.isValid || !additionalValidatorCheck.isValid) {
      return
    }

    const {
      values: {
        homeEmail,
        homePhoneCountryCode,
        homePhoneNumber,
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
    } = this.homeAddressStore.homeAddressValidator

    const input = {
      homeEmail,
      homePhone: {
        countryCode: homePhoneCountryCode,
        number: homePhoneNumber,
      },
      personalAddress: {
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
    }
    const updateAdditionalInformationSuccess: boolean = await this.userProfileService.saveUserProfileHomeAddress(
      this.homeAddressStore.additionalInformationValidator.values,
    )
    this.userProfileService.fetchUserProfile()

    if (updateAdditionalInformationSuccess) {
      this.baseSettingsService.saveUser({
        mode: 'personal-information',
        input,
        handleClose: this.homeAddressStore.toggle.handleClose,
      })
    } else {
      this.baseSettingsStore.setErrorState()
    }
  }

  handleEditModalOpening() {
    const { homeAddress } = this.baseSettingsService.getStructuredProfile()
    this.homeAddressStore.setHomeAddress(homeAddress)
    this.homeAddressStore.toggle.handleOpen()
    this.homeAddressStore.setIsSaveButtonDisabled(true)

    const additionalInformation =
      this.userProfileStore.userProfile?.homeAddress.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.homeAddressStore.setAdditionalInfoValidator(validator)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.homeAddressStore.setIsSaveButtonDisabled(false)
      return this.homeAddressStore.setAdditionalInfoValue(key, value)
    }
  }
}
