const HEAP_DEV_ID = 2387170654 // for stage2, stage3 and other dev checkings
const HEAP_PROD_ID = 901785420 // for prod only

export function heapInit() {
  const isDevLocal = process.env.NODE_ENV === 'development'
  const isProd = window._config.environment === 'prod'
  const isDev = window._config.environment === 'development' || isDevLocal
  const isEnabled = isProd || !isDev

  if (!isEnabled) {
    return
  }

  const heapId = isProd ? HEAP_PROD_ID : HEAP_DEV_ID

  window?.heap?.load?.(heapId)
}
