import { observer } from 'mobx-react-lite'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import { AirTimeRangePicker } from '@etta/components/air-time-range-picker/air-time-range-picker'
import { SegmentType } from '@fiji/graphql/types'
import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { InputButtonSize } from '@etta/ui/input-button/types'
import { airTimeRangeOptions } from '@fiji/hooks/time-configuration/use-flight/constants'
import { TimeRangeOption } from '@fiji/enums'
import { Search } from '@etta/ui/search'
import { DatePickerModal as NewDatePickerModal } from '@etta/ui/date-picker-modal-temp'
import { SwipingTimePicker as NewSwipingTimePicker } from '@etta/components/sliders'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { Props } from '../types'
import { useSegmentDateTimeModals } from '../use-segment-date-time-modals'
import { usePlaceDates } from '../use-date-time'
import { useTime } from './use-time'
import { InputButton } from './segment-date-time-desktop-styled'

export const SegmentDateTimeDesktop = observer(function SegmentDateTimeDesktop({
  date,
  time,
  type,
  segmentType,
  onDateSelect,
  startDateTitle,
  endDateTitle,
  datePicker,
  datePickerEnd,
  onApplyTimePicker,
  timePickerTitle,
  timePickerLabel,
  isForSearchHeader = false,
  originPlace,
  destinationPlace,
  timeRange = airTimeRangeOptions[TimeRangeOption.AnyTime],
  onTimeRangeChange,
  isRange = true,
  withConfirmOneWayButton = false,
  isDatePickerReturnDate = false,
  dateErrorMessage,
  timeErrorMessage,
}: Props) {
  const isNewCarRentalLayout = segmentType === SegmentType.CarRental

  const {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  } = usePlaceDates({
    date,
    type,
    segmentType,
  })

  const { datePickerModal, timePickerModal, handleModalOpen } = useSegmentDateTimeModals()

  const { ariaLabelTime, selectedTimeLabel, selectedTime } = useTime({
    segmentType,
    time,
    type,
    timeRange,
  })

  if (isNewCarRentalLayout) {
    return (
      <Search.Group percentageWidth={23.5} isTwoInputs>
        <Search.Element
          labelTitle={selectedDateLabel}
          value={date ? dateFormat(date, 'MMM dd') : undefined}
          onClick={datePickerModal.handleOpen}
          placeholder={datePlaceholder}
          errorMessage={dateErrorMessage}
          shouldErrorMessageRightBorderBeFlat
          contentSlot={
            <NewDatePickerModal
              isEndDateSame
              isOpen={datePickerModal.isOpen}
              onSelect={onDateSelect}
              onClose={datePickerModal.handleClose}
              startTitle={startDateTitle}
              endTitle={endDateTitle}
              isRange
              date={datePicker}
              dateEnd={datePickerEnd}
              minDate={new Date()}
              isReturnDate={isDatePickerReturnDate}
            />
          }
        />
        <Search.Element
          isTextAlignRight
          labelTitle={timePickerLabel || timePickerTitle}
          value={time ? getFormattedTime(time, 'p').toLowerCase() : undefined}
          onClick={timePickerModal.handleOpen}
          shouldErrorMessageLeftBorderBeFlat
          errorMessage={timeErrorMessage}
          contentSlot={
            <NewSwipingTimePicker
              value={time}
              label={timePickerLabel || timePickerTitle}
              isVisible={timePickerModal.isOpen}
              onClose={timePickerModal.handleClose}
              onApply={onApplyTimePicker}
            />
          }
        />
      </Search.Group>
    )
  }

  return (
    <InputButtonGroup aria-label={groupLabel} direction={InputButtonGroupDirection.Horizontal}>
      <InputButton
        data-tracking-id={testIdDatePicker}
        icon="calendarPWA"
        onClick={() => handleModalOpen(datePickerModal)}
        value={selectedDate}
        label={selectedDateLabel}
        aria-label={ariaLabelDate}
        placeholder={datePlaceholder}
        isForSearchHeader={isForSearchHeader}
        size={InputButtonSize.Big}
      />
      <DatePickerModal
        isEndDateSame
        withConfirmOneWayButton={withConfirmOneWayButton}
        isOpen={datePickerModal.isOpen}
        onSelect={onDateSelect}
        onClose={datePickerModal.handleClose}
        startTitle={startDateTitle}
        endTitle={endDateTitle}
        isRange={isRange}
        date={datePicker}
        dateEnd={datePickerEnd}
        minDate={new Date()}
      />
      <InputButton
        data-tracking-id={testIdTimePicker}
        icon="timePWA"
        onClick={() => handleModalOpen(timePickerModal)}
        value={selectedTime}
        label={selectedTimeLabel}
        aria-label={ariaLabelTime}
        isForSearchHeader={isForSearchHeader}
        size={InputButtonSize.Big}
      />
      (
      <AirTimeRangePicker
        date={date}
        isVisible={timePickerModal.isOpen}
        onClose={timePickerModal.handleClose}
        destinationPlace={destinationPlace}
        originPlace={originPlace}
        onSubmit={onTimeRangeChange}
        selectedTime={timeRange}
      />
      )
    </InputButtonGroup>
  )
})
