import { observer } from 'mobx-react-lite'
import { Checkbox } from '@etta/ui/checkbox'
import type { FilterOptionsProps } from '../../types'
import { StyledBlock } from '../../car-rental-filters-styled'
import { useCarClassOptions } from './use-car-class-options'

export const CarClassOptions = observer(function CarClassOptions({
  optionsPaddingVertical = 10,
  withBottomBorder = true,
}: FilterOptionsProps) {
  const { options, isChecked, handleSelectCarClass } = useCarClassOptions()

  return (
    <>
      {options.map((option) => {
        return (
          <StyledBlock
            paddingVertical={optionsPaddingVertical}
            key={option.id}
            withBottomBorder={withBottomBorder}>
            <Checkbox
              data-tracking-id="car-class-filter"
              value={option.id}
              label={option.title}
              checked={isChecked(option.id)}
              onChange={handleSelectCarClass(option.id)}
            />
          </StyledBlock>
        )
      })}
    </>
  )
})
