import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Checkbox } from '@etta/ui/checkbox'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import {
  Body,
  ClearAllButton,
  Footer,
  HeaderWrapper,
  Description,
} from './duplicate-trips-modal-content-desktop-styled'
import { DuplicateList } from './duplicate-list'

export function DuplicateTripsModalContentDesktop({
  onApply,
  onClose,
  headerTitle,
  toggleApplied,
  overlappingTrips,
  isApplied,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'

  return (
    <>
      <HeaderWrapper>
        <Modal.Title aria-label={headerTitle} leftSeparatorWidth={32}>
          {headerTitle}
        </Modal.Title>
      </HeaderWrapper>
      <Body>
        <Description>{t(`${i18Base}Description`)}</Description>
        <DuplicateList overlappingTrips={overlappingTrips} />
        <Checkbox
          aria-label={t(isApplied ? 'Accessibility.Selected' : 'Accessibility.TapToSelect')}
          label={
            <Text aria-label={t(`${i18Base}Understand`)} variant="subHeadStrong">
              {t(`${i18Base}Understand`)}
            </Text>
          }
          value="isApplied"
          checked={isApplied}
          onChange={toggleApplied}
        />
      </Body>
      <Modal.Footer>
        <Footer>
          <ClearAllButton
            aria-label={t('ReviewTrip.ButtonsLabel', { label: t(`${i18Base}Cancel`) })}
            variant="outline"
            onClick={onClose}
            minWidth={17}>
            {t(`${i18Base}Cancel`)}
          </ClearAllButton>
          <Button
            aria-label={t('ReviewTrip.ButtonsLabel', { label: t(`${i18Base}ContinueToCheckout`) })}
            disabled={!isApplied}
            onClick={onApply}
            fullWidth>
            {t(`${i18Base}ContinueToCheckout`)}
          </Button>
        </Footer>
      </Modal.Footer>
    </>
  )
}
