import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripType } from '@fiji/graphql/hooks'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { useTripDropActions } from '@etta/screens/trip-confirmation/hooks/use-new-trip-confirmation/use-trip-drop-actions'
import { usePurchaseReservationSuccess } from './use-purchase-reservation-success'
import type { Props } from './types'
import { PurchaseReservationSuccessLayout } from './layout'

export const PurchaseReservationSuccess = observer(function PurchaseReservationSuccess({
  onDone,
  onAnotherTrip,
  isTripOnHold,
  dynamicSiteMessages,
  ...props
}: Props) {
  const { t } = useTranslation()
  const { dropTripAndPostBooking } = useTripDropActions()
  const { postBookingTripPreviewActions } = usePostBookingContext()
  usePurchaseReservationSuccess()
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { purchaseReservationDSMLightboxToggler } = dynamicSiteMessagesTogglersStore

  const doneButtonLabel = isTripOnHold
    ? t('PurchaseReservationSuccess.SeeHeldTrips')
    : t('PurchaseReservationSuccess.SeeUpcomingTrips')

  const handleDone = () => {
    const tripType = isTripOnHold ? TripType.OnHold : TripType.Upcoming
    postBookingTripPreviewActions.updateTripType(tripType)
    onDone()
    dropTripAndPostBooking()
  }
  const handleAnotherTrip = () => {
    onAnotherTrip()
    dropTripAndPostBooking()
  }

  return (
    <>
      <PurchaseReservationSuccessLayout
        onDone={handleDone}
        onAnotherTrip={handleAnotherTrip}
        isTripOnHold={isTripOnHold}
        doneButtonLabel={doneButtonLabel}
        {...props}
      />
      <DynamicSiteMessagesLightboxModal
        messages={dynamicSiteMessages}
        isVisible={purchaseReservationDSMLightboxToggler.isOpen}
        onClose={purchaseReservationDSMLightboxToggler.handleClose}
      />
    </>
  )
})
