import { Container, Inject, Module } from '@etta/di'
import { serviceWorkerRegistration } from '@etta/worker/registration'
import { AppModule } from '../app'
import { ScreensService } from './interface/screens.service'

@Module()
export class MainModule {
  static getInstance() {
    return Container.get(MainModule)
  }

  constructor(
    @Inject()
    private screensService: ScreensService,
    @Inject()
    private appModule: AppModule,
  ) {}

  start() {
    this.screensService.handlerRegisterRoutes()
    serviceWorkerRegistration.register()

    this.appModule.start()
  }
}
