import { MediaLayout } from '@etta/ui/media-layout'
import { RailResultSkeletonDesktop } from './layout/desktop/rail-result-skeleton-desktop'
import { RailResultSkeletonMobile } from './layout/mobile/rail-result-skeleton-mobile'

export function RailResultSkeleton() {
  return (
    <MediaLayout
      mobileSlot={<RailResultSkeletonMobile />}
      desktopSlot={<RailResultSkeletonDesktop />}
    />
  )
}
