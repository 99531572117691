import styled from 'styled-components'
import { footnoteStrong, headline } from '@fiji/style'

export const Container = styled.div`
  padding: 15px 20px;
  width: 100%;
`

export const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 3px;
`

export const PriceItemEstimated = styled(PriceItem)`
  ${headline};
  line-height: 1.3;
`

export const PriceItemToday = styled(PriceItem)`
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteStrong};
  line-height: 1.5;
`
