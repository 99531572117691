import { observer } from 'mobx-react-lite'
import { ModalDetails } from '@etta/components/modal-details'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { TripStatusModal } from '@etta/components/trip-status-modal'
import { useCarRentalDetailsModal } from './use-car-rental-details-modal'
import { CarRentalDetails } from './car-rental-details'

type Props = {
  isShow: boolean
  carId: string
  bookingId: string
  isSelectable?: boolean
  isHybridFuel?: boolean
  onClose: () => void
  onAddCar: (carRentalKey: string) => void
  carEmission: {
    loading: boolean
    tonnesOfEmissions?: number
    averageEmissionsTonnes: number
    equivalences?: CalculateEmissionsBatchResultEquivalence[]
  }
}

export const CarRentalDetailsModal = observer(function CarRentalDetailsModal({
  isShow,
  carId,
  onClose,
  isSelectable,
  isHybridFuel,
  onAddCar,
  carEmission,
}: Props) {
  const {
    isLoading,
    isError,
    carDetails,
    onReload,
    viewState,
    handleCloseTripStatus,
    handleAddCarRental,
    carRentalTitle,
    totalDays,
    isOutOfPolicy,
    carImage,
    amenities,
    vendorInformation,
    vendorCode,
    vendorName,
    vendorImage,
    isPreferred,
    safetyCheckMessageCode,
    safetyCheckLink,
    locations,
    priceDetailsList,
    totalCostRate,
    estTotalCost,
    handleAddressClick,
    customBadge,
  } = useCarRentalDetailsModal({
    carId,
    onAddCar,
  })

  return (
    <ModalDetails
      isVisible={isShow}
      onClose={onClose}
      backgroundColor="background"
      horizontalDimension="content-760"
      isAdaptivePosition>
      <CarRentalDetails
        isLoading={isLoading}
        isError={isError}
        onClose={onClose}
        car={carDetails}
        onRetry={onReload}
        onAddCar={handleAddCarRental}
        isSelectable={isSelectable}
        isHybridFuel={isHybridFuel}
        isPreferred={isPreferred}
        carEmission={carEmission}
        carRentalTitle={carRentalTitle}
        carImage={carImage}
        totalDays={totalDays}
        isOutOfPolicy={isOutOfPolicy}
        amenities={amenities}
        locations={locations}
        vendorInformation={vendorInformation}
        vendorCode={vendorCode}
        vendorName={vendorName}
        vendorImage={vendorImage}
        safetyCheckMessageCode={safetyCheckMessageCode}
        safetyCheckLink={safetyCheckLink}
        priceDetailsList={priceDetailsList}
        totalCostRate={totalCostRate}
        estTotalCost={estTotalCost}
        handleAddressClick={handleAddressClick}
        customBadge={customBadge}
      />
      <TripStatusModal tripStateStatus={viewState} onClick={handleCloseTripStatus} />
    </ModalDetails>
  )
})
