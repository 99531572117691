import { css } from 'styled-components'
import { screenSize } from '@fiji/style'
import 'react-bnb-gallery/dist/style.css'
import '@fortawesome/fontawesome-free/css/all.css'

export const mediaStyled = css`
  .mobile-view {
    display: none;

    @media (${screenSize.maxTablet}) {
      display: block !important;
    }
  }

  .mobile-hidden {
    @media (${screenSize.maxMobileSmall}) {
      display: none !important;
    }
  }

  .mobile-visible {
    display: none;

    @media (${screenSize.maxMobileSmall}) {
      display: flex !important;
    }
  }

  .tablet-hidden {
    @media (${screenSize.maxMobile}) {
      display: none !important;
    }
  }

  .tablet-visible {
    display: none;

    @media (${screenSize.maxMobile}) {
      display: flex !important;
    }
  }

  .desktop-view {
    @media (${screenSize.maxTablet}) {
      display: none !important;
    }
  }

  @media (${screenSize.maxMobile}) {
    *:focus {
      outline: none;
    }
  }
`
