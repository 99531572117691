import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import {
  LeftBlock,
  RightBlock,
} from '../../../car-rental-details-eco-check-modal/layout/desktop/car-rental-details-eco-check-modal-desktop-styled'
import { EcoCheckEquivalenceCardImage } from '../eco-check-equivalences-card-image'
import { ecoCheckEquivalencesLinks } from '../../constants'
import {
  AmountBlock,
  DescriptionBlock,
  LearnMore,
  CardContainer,
} from './eco-check-equivalences-desktop-styled'

type CardProps = {
  amount: number
  type: CalculateEmissionsBatchResultEquivalenceType
  unit: string
  withMarginRight: boolean
}

export function EcoCheckEquivalenceCard({ amount, type }: CardProps) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.Equivalence'

  const equivalenceDescription = useMemo(() => {
    switch (type) {
      case CalculateEmissionsBatchResultEquivalenceType.Seedling:
        return {
          firstPart: t(i18nBase + '.Seedling1'),
          secondPart: t(i18nBase + '.Seedling2'),
          linkTo: ecoCheckEquivalencesLinks.seedlingLink,
        }
      case CalculateEmissionsBatchResultEquivalenceType.PlasticBag:
        return {
          firstPart: t(i18nBase + '.PlasticBag1'),
          secondPart: t(i18nBase + '.PlasticBag2'),
          linkTo: ecoCheckEquivalencesLinks.plasticBagLink,
        }
      case CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge:
        return {
          firstPart: t(i18nBase + '.SmartphoneCharge1'),
          secondPart: t(i18nBase + '.SmartphoneCharge2'),
          linkTo: ecoCheckEquivalencesLinks.smartphoneChargeLink,
        }
    }
  }, [t, type])

  return (
    <CardContainer>
      <LeftBlock>
        <DescriptionBlock aria-label={equivalenceDescription.firstPart}>
          {equivalenceDescription.firstPart}
        </DescriptionBlock>
        <AmountBlock>{amount.toLocaleString('en-US')}</AmountBlock>
        <DescriptionBlock aria-label={equivalenceDescription.secondPart}>
          {equivalenceDescription.secondPart}
        </DescriptionBlock>
        <LearnMore
          target="_blank"
          href={equivalenceDescription.linkTo}
          aria-label={t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')}>
          {t('Emissions.DetailsModal.LearnMore')}
        </LearnMore>
      </LeftBlock>
      <RightBlock>
        <EcoCheckEquivalenceCardImage type={type} />
      </RightBlock>
    </CardContainer>
  )
}
