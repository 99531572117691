import styled from 'styled-components'
import { Header } from '@etta/ui/header'

export const TitleWrapper = styled(Header)`
  padding: 0px 32px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: right;
  min-height: 72px;
  padding: 14px 32px;
`
