import styled from 'styled-components'

export const ControlsContainerMobileStyled = styled.div`
  width: 100%;
  height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;

  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`
