import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import { RailDetailsEcoCheckModal } from '@etta/components/rail-details-eco-check-modal'
import type { CalculateEmissionsEquivalenceValueObject } from '@etta/core/value-objects/calculate-emissions-equivalence.object-value'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  RailEmissionDescriptionWrapper,
  LearnMore,
  RailEmissionDescription,
  EmissionsSummary,
} from './rail-details-eco-check-mobile-styled'
import EcoCheckRailIllustration from './assets/eco-check-rail-illustration.svg?url'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

type Props = {
  tonnesOfEmissions: number
  averageRouteEmissionTonnes: number
  kgOfActualEmission: number
  equivalences: CalculateEmissionsEquivalenceValueObject[]
}

export function RailDetailsEcoCheckMobileComponent({ kgOfActualEmission, equivalences }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.RailDetails'
  const { handleOpen, handleClose, isOpen } = useTogglePopup()

  return (
    <>
      <Container onClick={handleOpen} data-tracking-id="flight-details-eco-check-action">
        <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
          <img src={EcoCheckLogo} alt="eco-check-logo" />
        </Title>
        <Body>
          <EmissionsSummary>
            <KgOfCo2 kgOfEmissions={kgOfActualEmission} />
          </EmissionsSummary>
          <RailEmissionDescriptionWrapper>
            <RailEmissionDescription
              variant="footnoteMedium"
              color="bodyText"
              aria-label={t(i18nBase + '.RailEmissionInfo')}>
              {t(i18nBase + '.RailEmissionInfo')}
            </RailEmissionDescription>
            <LearnMore
              aria-label={
                t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
              }>
              {t('Emissions.DetailsModal.LearnMore')}
            </LearnMore>
          </RailEmissionDescriptionWrapper>
        </Body>
        <EcoCheckBackground src={EcoCheckRailIllustration} />
      </Container>
      <RailDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        kgOfActualEmission={kgOfActualEmission}
      />
    </>
  )
}
