// @todo Refactor and move as isolated component
import { useTranslation } from 'react-i18next'
import { RailDeliveryGuideModal } from '@etta/components/rail-delivery-option/rail-delivery-guide-modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Header, HeadlineWrapper } from './ticket-delivery-styled'

type Props = {
  collectionReferences?: (string | null)[] | null
}

const i18nBase = 'RailDeliveryOption'

export function Collect({ collectionReferences }: Props) {
  const { t } = useTranslation()
  const { isOpen, handleClose, handleOpen } = useTogglePopup()

  const collectionReferencesNumber = collectionReferences?.length
    ? collectionReferences.join(', ')
    : '-'

  return (
    <>
      <Header>
        <Icon name="kioskPWA" size="medium" />
        <HeadlineWrapper>
          <Text variant="subHeadMedium" color="mainText1">
            {t(i18nBase + '.YourCollectFrom')}
          </Text>
          {collectionReferencesNumber && (
            <Text variant="subHeadStrong" color="mainText1">
              {collectionReferencesNumber}
            </Text>
          )}
        </HeadlineWrapper>
      </Header>
      <Block marginTop={12}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + '.CollectFromAny')}
          <Block>
            <Link size="small" onClick={handleOpen}>
              {t(i18nBase + '.FindOutHow')}
            </Link>
          </Block>
        </Text>
      </Block>
      <CheckoutPageModal isVisible={isOpen} onClose={handleClose}>
        <RailDeliveryGuideModal isEticket={false} onClose={handleClose} />
      </CheckoutPageModal>
    </>
  )
}
