/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { MODAL_ID, MODAL_TYPE } from './constants'
import type { HistoryModals, ModalId } from './types'
import { getModalIds } from './get-modal-ids'

export function usePushToHistory(currentId: ModalId) {
  const history = useHistory<HistoryModals>()
  const push = useCallback(() => {
    const searchQuery = new URLSearchParams(history.location.search)
    searchQuery.set(MODAL_ID, currentId)
    const modalIds = getModalIds()
    history.push({
      pathname: history.location.pathname,
      search: searchQuery.toString(),
      state: {
        direction: 'forward',
        modalIds: [...modalIds, currentId],
        type: MODAL_TYPE,
      },
    })
  }, [])

  return useCallback(() => {
    if (history.location.state?.direction === 'backward') {
      const checkHandler = () => {
        window.removeEventListener('popstate', checkHandler)
        push()
      }
      window.addEventListener('popstate', checkHandler)
    } else {
      push()
    }
  }, [])
}
