import { Inject, Service } from '@etta/di'
import { IdentityAdapter } from '@etta/modules/admin/infra/identity.adapter'
import { HeapAnalyticsIdentityStore } from '@etta/modules/admin/interface/heap-analytics-identity/stores/heap-analytics-identity.store'
import type { HeapAnalyticsIdentityValueObject } from '@etta/modules/admin/core/value-objects/heap-analytics-identity.value-object'

@Service()
export class HeapAnalyticsIdentityService {
  constructor(
    @Inject()
    private readonly identityAdapter: IdentityAdapter,
    @Inject()
    private readonly heapAnalyticsIdentityStore: HeapAnalyticsIdentityStore,
  ) {}

  async fetchIdentity(email: string) {
    if (!email.trim().length) {
      return
    }

    this.heapAnalyticsIdentityStore.setIsLoading(true)
    const result = await this.identityAdapter.getIdentityHash(email)

    this.heapAnalyticsIdentityStore.setIsLoading(false)

    result.match({
      Ok: (identity) => {
        this.heapAnalyticsIdentityStore.addIdentity(identity)
      },
      Err: () => {},
    })

    return result
  }

  copyIdentityToClipboard(identity: HeapAnalyticsIdentityValueObject) {
    if ('clipboard' in navigator) {
      void navigator.clipboard.writeText(identity.hashValue)
    }
  }
}
