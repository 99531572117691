import { SegmentType } from '@fiji/graphql/types'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { mapFeesAndTaxesRate } from './map-fees-and-taxes-rate'
import { mapAirSubSegments } from './map-air-sub-segments'

type Params = {
  segments: FlightPostBookingValueObject[]
}

export function mapAirSeatMapSegments({ segments }: Params): FlightSegmentEntity[] {
  const result = segments.reduce(
    (acc: FlightSegmentEntity[], segment: FlightPostBookingValueObject) => {
      const { position, type } = segment

      const {
        legId,
        departureDateTime,
        arrivalDateTime,
        flightStops,
        stops,
        isSameCarrierName,
        isRoundTripLeg,
        isRoundTripCost,
        flightTime,
        rate,
        taxes,
        totalCost,
        transactionFees,
        unusedTicketRate,
        policy,
        segments,
      } = segment

      const adaptedSubSegments = mapAirSubSegments({ segments })

      if (!adaptedSubSegments) {
        return acc
      }

      return [
        ...acc,
        {
          position: position || 0,
          type: type || SegmentType.Flight,
          uuid: '',
          departureDateTime,
          arrivalDateTime,
          legId: legId || '',
          flightTime: {
            hours: flightTime?.hours || 0,
            minutes: flightTime?.minutes || 0,
          },
          totalTime: {
            hours: flightTime?.hours || 0,
            minutes: flightTime?.minutes || 0,
          },
          flightStops: flightStops || 0,
          stops,
          isSameCarrierName,
          isRoundTripLeg,
          isRoundTripCost,
          rate: {
            primary: {
              amount: totalCost?.primary?.amount || 0,
              currency: totalCost?.primary?.currency || '',
            },
            secondary: {
              amount: totalCost?.secondary?.amount || 0,
              currency: totalCost?.secondary?.currency || '',
            },
          },
          baseRate: rate,
          totalCostRate: totalCost,
          feesAndTaxesRate: mapFeesAndTaxesRate({ transactionFees, taxes }),
          unusedTicketRate,
          policy: {
            outOfPolicyReasons: policy?.outOfPolicyReasons || null,
            isInPolicy: policy?.isInPolicy || false,
            outOfPolicyData: policy?.outOfPolicyData || null,
          },
          segments: adaptedSubSegments,
        },
      ]
    },
    [],
  )

  return result
}
