import styled from 'styled-components'
import { Button as ETTAButton } from '@etta/ui/button'
import type { ButtonProps } from '@etta/ui/button'

const VERTICAL_MARGIN = 15
const DEFAULT_BOTTOM = 60
const EXCLUDE_HEIGHT = VERTICAL_MARGIN * 2 + DEFAULT_BOTTOM

export const Container = styled.div<{ isVisible: boolean; isReadonly: boolean }>`
  position: absolute;
  margin: ${VERTICAL_MARGIN}px 0;
  bottom: ${({ isReadonly }) => (isReadonly ? '0' : `${DEFAULT_BOTTOM}`)}px;
  left: 75%;
  transform: translateX(-60%)
    translateY(${({ isVisible }) => (isVisible ? '0%' : `calc(100% + ${EXCLUDE_HEIGHT}px)`)});
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
`

export const Button = styled(ETTAButton).attrs<ButtonProps, ButtonProps>((props) => ({
  ...props,
  btnType: 'secondary',
  size: 'normal-wide',
}))`
  border-radius: 24px;
  box-shadow: 0px -7px 12px rgba(0, 0, 0, 0.18);
`
