import { useTranslation } from 'react-i18next'
import type { LayoutProps } from '../types'
import {
  ScaleContainer,
  Scale,
  PointerContainer,
  PointerTip,
  Pointer,
  LabelsContainer,
  Label,
} from './geo-safe-scale-mobile-styled'
import { GeoSafeScaleSkeleton } from './geo-safe-scale-mobile-skeleton'

const skeleton = <GeoSafeScaleSkeleton />

const i18nBase = 'HotelSafetyCheck.GeoSafeScores'

export function GeoSafeScaleMobile({ scores, isLoading }: LayoutProps) {
  const { t } = useTranslation()

  if (isLoading || !scores) {
    return skeleton
  }

  return (
    <div>
      <ScaleContainer>
        <Scale>
          <PointerContainer progress={scores}>
            <PointerTip />
            <Pointer />
          </PointerContainer>
        </Scale>
      </ScaleContainer>
      <LabelsContainer>
        <Label>{t(`${i18nBase}.GeoSafeScale.Unsafe`)}</Label>
        <Label>{t(`${i18nBase}.GeoSafeScale.Safe`)}</Label>
      </LabelsContainer>
    </div>
  )
}
