import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { RailPermittedStationsContent } from '@etta/components/rail-permitted-stations'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { useRailDetailsPermittedStations } from '../../use-rail-details-permitted-stations'
import { Container, ViewStationsWrapper } from './rail-details-permitted-stations-desktop-styled'

const i18nBase = 'PostBooking.TripDetails.'

export function RailDetailsPermittedStationsDesktop({
  originStation,
  destinationStation,
  permittedOriginStations,
  permittedDestinationStations,
  ticketRestrictions,
}: LayoutProps) {
  const { t } = useTranslation()
  const [isExpanded, setExpanded] = useState(false)
  const { handleTicketRestrictionsOpen } = useRailDetailsPermittedStations({
    originStation,
    destinationStation,
    permittedOriginStations,
    permittedDestinationStations,
    ticketRestrictions,
  })

  return (
    <Container onClick={() => setExpanded((state) => !state)}>
      <ViewStationsWrapper>
        <Text variant="subHeadStrong" color="mainText1">
          {t(`${i18nBase}ViewStations`)}
        </Text>
        <Icon name={isExpanded ? 'chevronUpPWA' : 'chevronDownPWA'} color="mainText1" size={22} />
      </ViewStationsWrapper>
      {isExpanded && (
        <Block paddingHorizontal={10}>
          <RailPermittedStationsContent
            originStation={originStation}
            destinationStation={destinationStation}
            permittedOriginStations={permittedOriginStations}
            permittedDestinationStations={permittedDestinationStations}
            onClick={handleTicketRestrictionsOpen}
          />
        </Block>
      )}
    </Container>
  )
}
