import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 7px;

    &:not(:last-of-type):after {
      right: -6px;
      top: calc(50% - 5px);
      position: absolute;
      content: '';
      width: 1px;
      height: 10px;
      background: ${(props) => props.theme.colors.borderLight};
    }
  }
`

export const ReviewsLoader = styled.div`
  width: 40px;
  height: 12px;
  ${glowingFragment}
`
