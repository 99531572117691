import { Skeleton } from '@etta/ui/skeleton'
import { Wrapper } from './trip-card-skeleton-mobile-styles'

export function TripCardSkeletonMobile() {
  return (
    <Wrapper>
      <Skeleton width="100%" height="228px">
        <rect width="114" height="16" x="16" y="16" />
        <rect width="203" height="24" x="16" y="42" />
      </Skeleton>
    </Wrapper>
  )
}
