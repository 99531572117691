import { memo } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { ListMobile } from './mobile'
import { ListDesktop } from './desktop'

export const List = memo(function List(props: Props) {
  return (
    <MediaLayout mobileSlot={<ListMobile {...props} />} desktopSlot={<ListDesktop {...props} />} />
  )
})
