export default function CloseFilledTransparent() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 40C31.9411 40 40 31.9411 40 22C40 12.0589 31.9411 4 22 4C12.0589 4 4 12.0589 4 22C4 31.9411 12.0589 40 22 40ZM23.6107 22L30 15.6107L28.3893 14L22 20.3893L15.6107 14L14 15.6107L20.3893 22L14 28.3893L15.6107 30L22 23.6107L28.3893 30L30 28.3893L23.6107 22Z"
        fill="currentColor"
      />
    </svg>
  )
}
