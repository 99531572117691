import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { Hotel } from '@fiji/graphql/types'
import { HotelImage } from '@etta/components/hotel-image/hotel-image'
import { HotelInfoList } from '@etta/components/hotel-info-list/hotel-info-list'
import { useOutOfPolicyHotelData } from '@fiji/hooks/use-out-of-policy-hotel-data'
import { useHotelCardAriaLabel } from '@fiji/hooks/use-hotel-card-aria-label'
import { StarRating } from '@etta/ui/star-rating'
import type { AppState } from '@fiji/store'
import {
  HotelCardWrapper,
  HotelCardDescriptionWrapper,
  HotelCardDescription,
  HotelTitle,
  HotelDetails,
  StarRatingContainer,
  HotelImageWrapper,
} from './hotel-card-styled'

type Props = {
  hotel: Hotel
  onClick?: () => void
}
const i18nBase = 'SearchResults.Hotel'

export function HotelCard({ hotel, onClick }: Props) {
  const { t } = useTranslation()
  const hotelCardAriaLabel = useHotelCardAriaLabel({ hotel })
  const {
    distance,
    logoPhotoUrl,
    name,
    starRating,
    chainCode,
    isPreferred,
    photos,
    preference,
  } = hotel
  const {
    hotels: { isStarRatingEnabled },
  } = useSelector((state: AppState) => state.displayConfiguration)

  const { isOutOfPolicy, isAllRoomsOutOfPolicy } = useOutOfPolicyHotelData(hotel)

  return (
    <HotelCardWrapper
      onClick={onClick}
      aria-label={hotelCardAriaLabel}
      data-tracking-id={hotel.id + '-card'}>
      <HotelImageWrapper>
        <HotelImage photos={photos} logoPhotoUrl={logoPhotoUrl} chainCode={chainCode} />
      </HotelImageWrapper>
      <HotelCardDescriptionWrapper>
        {isStarRatingEnabled && (
          <StarRatingContainer>
            <StarRating
              color="mainText"
              aria-label={t(`SearchResults.Hotel.HotelRatingAriaLabel`, { rating: starRating })}
              rating={starRating}
            />
          </StarRatingContainer>
        )}
        <HotelCardDescription>
          <HotelTitle aria-label={t(`${i18nBase}.HotelNameAriaLabel`, { hotelName: name })}>
            {name}
          </HotelTitle>
          <HotelDetails>
            <HotelInfoList
              isSmallIcons
              distance={distance}
              isPreferred={isPreferred}
              preference={preference}
              isOutOfPolicy={isOutOfPolicy}
              isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
            />
          </HotelDetails>
        </HotelCardDescription>
      </HotelCardDescriptionWrapper>
    </HotelCardWrapper>
  )
}
