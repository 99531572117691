import i18n from 'i18next'
import { v4 as uuid } from 'uuid'
import {
  PlaceAutocompleteType,
  RecentRailSearchesRailType,
  RecentSearchesSearchTimeRangeType,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import { dateFormat, getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { Service } from '@etta/di'
import type { RailRecentSearch } from '@etta/modules/recent-searches/core/value-objects/get-rail-searches.value-object'
import type { Rail } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import type { Place, PlainTime, TimeRange } from '@fiji/types'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'
import { airTimeRangeOptions } from '@fiji/hooks/time-configuration/use-flight/constants'
import { railTimeRangeOptions } from '@fiji/hooks/time-configuration/use-rail/constants'

const i18nBase = 'RecentSearches'
const formatString = "iii', 'MMM dd"

type Segment = RailRecentSearch['railSearchParams']['segments'] extends (infer U)[] | undefined
  ? U
  : never

type RecentSearchTimeRange = Segment['searchTimeRange']

type Location = Segment['origin']

const railTypeMap = {
  [RecentRailSearchesRailType.OneWay]: RailTripType.OneWay,
  [RecentRailSearchesRailType.RoundTrip]: RailTripType.Round,
  [RecentRailSearchesRailType.OpenReturn]: RailTripType.OpenReturn,
  [RecentRailSearchesRailType.Unspecified]: RailTripType.OneWay,
}

const timeRangeMap = {
  [RecentSearchesSearchTimeRangeType.Departure]: TimeRangeDirection.Departure,
  [RecentSearchesSearchTimeRangeType.Arrival]: TimeRangeDirection.Arrival,
}

@Service()
export class RailRecentSearchDataService {
  get(searchParams: RailRecentSearch['railSearchParams']) {
    return {
      bottomLine: this.getBottomLine(searchParams),
      originLocationName: searchParams.segments?.[0]?.origin.name || '',
      destinationLocationName: searchParams.segments?.[0]?.destination.name || '',
      searchQueryParams: this.toSearchQuery(searchParams),
    }
  }

  private toSearchQuery(searchParams: RailRecentSearch['railSearchParams']): Rail {
    const { timeRange, returnTimeRange } = this.toSearchQueryTimeRange({ searchParams })

    return {
      originPlace: this.getRailSegmentLocation(searchParams.segments?.[0]?.origin),
      destinationPlace: this.getRailSegmentLocation(searchParams.segments?.[0]?.destination),
      originDate: getDateWithoutTimezoneOffset(new Date(searchParams.segments?.[0]?.departureDate)),
      destinationDate:
        searchParams.railType === RecentRailSearchesRailType.RoundTrip &&
        searchParams.segments.length > 1
          ? getDateWithoutTimezoneOffset(
              new Date(searchParams.segments[searchParams.segments.length - 1].departureDate),
            )
          : undefined,
      originTime: timeRange,
      destinationTime: returnTimeRange,
      railTripType: railTypeMap[searchParams.railType],
      railCards: searchParams.railCards,
    }
  }

  private getRailSegmentLocation(location: Location): Place {
    return {
      placeId: uuid(),
      name: location.name,
      latitude: location.geocode?.lat,
      longitude: location.geocode?.long,
      airportCode: undefined,
      placeType: PlaceAutocompleteType.AlgoliaPlaces,
      countryCode: location.locationCode || undefined,
      uniqueCode: location.locationCode || undefined,
    }
  }

  private getBottomLine(searchParams: RailRecentSearch['railSearchParams']) {
    const departureDate = new Date(searchParams.segments?.[0]?.departureDate)
    const departureDateWithoutTimezone = getDateWithoutTimezoneOffset(departureDate)
    const formattedDepartureDate = dateFormat(departureDateWithoutTimezone, formatString)

    if (searchParams.railType === RecentRailSearchesRailType.RoundTrip) {
      const returnDate = new Date(
        searchParams.segments[searchParams.segments.length - 1].departureDate,
      )
      const returnDateWithoutTimezone = getDateWithoutTimezoneOffset(returnDate)
      const formattedReturnDate = dateFormat(returnDateWithoutTimezone, formatString)

      return `${formattedDepartureDate} - ${formattedReturnDate}`
    }

    const tripType =
      searchParams.railType === RecentRailSearchesRailType.OneWay
        ? i18n.t(`${i18nBase}.Rail.OneWay`)
        : i18n.t(`${i18nBase}.Rail.OpenReturn`)

    return `${tripType} - ${formattedDepartureDate}`
  }

  toSearchQueryTimeRange({ searchParams }: { searchParams: RailRecentSearch['railSearchParams'] }) {
    const defaultReturnTimeRange: TimeRange = {
      ...railTimeRangeOptions[TimeRangeOption.CustomTime],
      timeRangeBy: TimeRangeDirection.Departure,
    }

    if (searchParams.railType === RecentRailSearchesRailType.RoundTrip) {
      const returnTimeRange =
        searchParams.segments[searchParams.segments.length - 1].searchTimeRange
      return {
        timeRange: this.formatTimeRange(searchParams.segments[0].searchTimeRange),
        returnTimeRange: this.formatTimeRange(returnTimeRange),
      }
    }

    return {
      timeRange: this.formatTimeRange(searchParams.segments[0].searchTimeRange),
      returnTimeRange: defaultReturnTimeRange,
    }
  }

  formatTimeRange(timeRange: RecentSearchTimeRange): TimeRange {
    const startPlainTime = this.toPlainTime(timeRange.start)
    const endPlainTime = this.toPlainTime(timeRange.end)
    const timeRangeBy = timeRangeMap[timeRange.type]

    const timeRangeResult: TimeRange = {
      startTimeRange: timeRange.start,
      endTimeRange: timeRange.end,
      startTimeHours: startPlainTime.hours,
      endTimeHours: endPlainTime.hours,
      timeRangeBy,
      id: TimeRangeOption.CustomTime,
      i18next: 'RailSearch.TimePicker.CustomTime',
      customTimeHours: timeRange.customHours || undefined,
    }

    const timeRangeStart = timeRange.start
    const timeRangeEnd = timeRange.end

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.AnyTime].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.AnyTime].endTimeRange
    ) {
      return {
        ...airTimeRangeOptions[TimeRangeOption.AnyTime],
        timeRangeBy,
      }
    }

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Morning].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Morning].endTimeRange
    ) {
      return {
        ...airTimeRangeOptions[TimeRangeOption.Morning],
        timeRangeBy,
      }
    }

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Afternoon].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Afternoon].endTimeRange
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Afternoon], timeRangeBy }
    }
    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Evening].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Evening].endTimeRange
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Evening], timeRangeBy }
    }

    return timeRangeResult
  }

  toPlainTime(time: string): PlainTime {
    const date = new Date('1970-01-01T' + time)

    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    }
  }
}
