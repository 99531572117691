import { Store, Inject } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import type { CarRentalRecentSearch } from '../../core/value-objects/get-car-rental-searches.value-object'
import type { FlightRecentSearch } from '../../core/value-objects/get-flight-searches.value-object'
import type { HotelRecentSearch } from '../../core/value-objects/get-hotel-searches.value-object'
import type { RailRecentSearch } from '../../core/value-objects/get-rail-searches.value-object'
import type {
  RecentSearchesStoreType,
  RecentSearchesType,
} from '../../core/value-objects/recent-searches-type.value-object'
import type { CarRentalRecentSearchWithoutDates } from '../../core/value-objects/get-car-rental-searches-without-dates.value-object'
import type { FlightRecentSearchWithoutDates } from '../../core/value-objects/get-flight-searches-without-dates.value-object'
import type { HotelRecentSearchWithoutDates } from '../../core/value-objects/get-hotel-searches-without-dates.value-object'
import type { RecentSearchesWithoutDatesStoreType } from '../../core/value-objects/recent-searches-without-dates-type.value-object'

@Store()
export class RecentSearchesStore {
  constructor(
    @Inject()
    private readonly historyStore: HistoryStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  isRecentSearchCall: boolean = false
  recentSearchCallback: () => void = () => {}

  recentSearches: RecentSearchesStoreType = {
    flight: { items: [], isLoading: false },
    hotel: { items: [], isLoading: false },
    carRental: { items: [], isLoading: false },
    rail: { items: [], isLoading: false },
  }

  recentSearchesWithoutDates: RecentSearchesWithoutDatesStoreType = {
    flight: { items: [], isLoading: false },
    hotel: { items: [], isLoading: false },
    carRental: { items: [], isLoading: false },
  }

  get isRecentSearchDisabled() {
    if (!this.featureFlagsStore.flags.isRecentSearchesEnabled) {
      return true
    }

    if (this.historyStore.search.includes('booking-id')) {
      return true
    }

    if (this.reviewTripStore.isTripExist) {
      return true
    }

    return false
  }

  get flightRecentSearches() {
    return this.recentSearches.flight.items.slice(0, 4)
  }

  get flightRecentSearchesWithoutDates() {
    return this.recentSearchesWithoutDates.flight.items.slice(0, 4)
  }

  get isShowFlightRecentSearch(): boolean {
    return !!this.flightRecentSearches.length || this.isRecentSearchDisabled
  }

  get isShowFlightRecentSearchWithoutDates(): boolean {
    return !!this.flightRecentSearchesWithoutDates.length || this.isRecentSearchDisabled
  }

  get carRentalRecentSearches() {
    return this.recentSearches.carRental.items.slice(0, 4)
  }

  get carRentalRecentSearchesWithoutDates() {
    return this.recentSearchesWithoutDates.carRental.items.slice(0, 4)
  }

  get isShowCarRentaltRecentSearch(): boolean {
    return !!this.carRentalRecentSearches.length || this.isRecentSearchDisabled
  }

  get isShowCarRentalRecentSearchWithoutDates(): boolean {
    return !!this.carRentalRecentSearchesWithoutDates.length || this.isRecentSearchDisabled
  }

  get hotelRecentSearches() {
    return this.recentSearches.hotel.items.slice(0, 4)
  }

  get hotelRecentSearchesWithoutDates() {
    return this.recentSearchesWithoutDates.hotel.items.slice(0, 4)
  }

  get isShowHotelRecentSearch(): boolean {
    return !!this.hotelRecentSearches.length || this.isRecentSearchDisabled
  }

  get isShowHotelRecentSearchWithoutDates(): boolean {
    return !!this.hotelRecentSearchesWithoutDates.length || this.isRecentSearchDisabled
  }

  isSegmentListEmpty(segmentType?: RecentSearchesType) {
    if (!segmentType) {
      return false
    }

    return !this.recentSearches[segmentType].items.length
  }

  setRecentCarRentalSearches(recentSearches: CarRentalRecentSearch[]) {
    this.recentSearches.carRental.items = recentSearches
  }

  setIsLoadingRecentCarRentalSearches(value: boolean) {
    this.recentSearches.carRental.isLoading = value
  }

  setRecentFlighSearches(recentSearches: FlightRecentSearch[]) {
    this.recentSearches.flight.items = recentSearches
  }

  setIsLoadingRecentFlighSearches(value: boolean) {
    this.recentSearches.flight.isLoading = value
  }

  setRecentHotelSearches(recentSearches: HotelRecentSearch[]) {
    this.recentSearches.hotel.items = recentSearches
  }

  setIsLoadingRecentHotelSearches(value: boolean) {
    this.recentSearches.hotel.isLoading = value
  }

  setRecentRailSearches(recentSearches: RailRecentSearch[]) {
    this.recentSearches.rail.items = recentSearches
  }

  setRecentCarRentalSearchesWithoutDates(
    recentSearchesWithoutDates: CarRentalRecentSearchWithoutDates[],
  ) {
    this.recentSearchesWithoutDates.carRental.items = recentSearchesWithoutDates
  }

  setRecentFlightSearchesWithoutDates(
    recentSearchesWithoutDates: FlightRecentSearchWithoutDates[],
  ) {
    this.recentSearchesWithoutDates.flight.items = recentSearchesWithoutDates
  }

  setRecentHotelSearchesWithoutDates(recentSearchesWithoutDates: HotelRecentSearchWithoutDates[]) {
    this.recentSearchesWithoutDates.hotel.items = recentSearchesWithoutDates
  }

  setRecentSearchCallback(callback: () => void) {
    this.isRecentSearchCall = true
    this.recentSearchCallback = callback
  }

  clearRecentSearchCallback() {
    this.isRecentSearchCall = false
    this.recentSearchCallback = () => {}
  }

  setIsLoadingRecentRailSearches(value: boolean) {
    this.recentSearches.rail.isLoading = value
  }

  setIsLoadingRecentCarRentalSearchesWithoutDates(value: boolean) {
    this.recentSearchesWithoutDates.carRental.isLoading = value
  }

  setIsLoadingRecentFlightSearchesWithoutDates(value: boolean) {
    this.recentSearchesWithoutDates.flight.isLoading = value
  }

  setIsLoadingRecentHotelSearchesWithoutDates(value: boolean) {
    this.recentSearchesWithoutDates.hotel.isLoading = value
  }
}
