import { useEffect, useMemo } from 'react'
import { useGetReferencePointsLazyQuery } from '@fiji/graphql/hooks'
import type { ReferencePoint } from '@fiji/graphql/types'
import { uniqueReferencePoints } from './unique-reference-points'

type Args = {
  latitude: number
  longitude: number
  shouldLoadPoints: boolean
}

const REFERENCE_POINTS_COUNT = 5

export function useFetchReferencePoints({ latitude, longitude, shouldLoadPoints }: Args) {
  const [getReferencePointsQuery, { data, loading, error }] = useGetReferencePointsLazyQuery()

  useEffect(() => {
    if (!shouldLoadPoints) {
      return
    }

    getReferencePointsQuery({
      variables: {
        latitude,
        longitude,
        pageSize: REFERENCE_POINTS_COUNT,
      },
    })
  }, [shouldLoadPoints, latitude, longitude, getReferencePointsQuery])

  const referencePoints: ReferencePoint[] = useMemo(() => {
    if (loading || error) {
      return []
    }
    return data?.referencePoints
      ? uniqueReferencePoints(
          data?.referencePoints.referencePoints.filter(
            (point: ReferencePoint) => point.address.geocode?.lat && point.address.geocode?.long,
          ),
        )
      : []
  }, [data?.referencePoints, error, loading])

  return {
    referencePoints,
  }
}
