export default function LieFlatSeatIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.56 10.5087C10.48 9.08143 9.76 6.15517 12.64 5.15601C16.24 3.90706 16.6001 8.70271 16.6001 8.88113C16.6001 9.05955 15.8801 14.769 11.56 10.5087ZM4 11.7358H7.24851C8.11622 11.7358 8.89969 12.2504 9.2378 13.0426L12.1419 19.8466C12.4491 20.5664 13.1275 21.063 13.9119 21.1423L22.2092 21.9817C22.9717 22.0588 23.6358 22.5306 23.9544 23.2215L25.2401 26.0095H28.256C29.058 26.0095 29.794 26.4499 30.1675 27.1533L32.0491 30.6973C32.5539 31.6478 31.8587 32.7895 30.7749 32.7895H12.0793C11.1292 32.7895 10.2906 32.174 10.0133 31.2732L4 11.7358ZM12.6402 33.8823H27.0402V38.8781C27.0402 39.4691 26.5567 39.9487 25.9602 39.9487H13.7202C13.1237 39.9487 12.6402 39.4691 12.6402 38.8781V33.8823ZM13.3984 18.8117L11.9181 15.2482C11.5566 14.3779 12.1289 13.4052 13.071 13.2884L16.9603 12.8065L21.2803 20.4786L14.5597 19.6856C14.0414 19.6244 13.5971 19.2901 13.3984 18.8117ZM26.14 24.9391L24.16 21.0138L31.563 21.6253C32.3287 21.6885 33.0034 22.1506 33.3339 22.8384L40 36.7149H38.0412C37.2514 36.7149 36.5246 36.2877 36.1455 35.6005L30.687 25.6994C30.4359 25.2438 29.9549 24.9593 29.4311 24.9565L26.14 24.9391Z"
        fill="currentColor"
      />
    </svg>
  )
}
