import type { Rate } from '@fiji/graphql/types'
import { formatPrice } from '@fiji/utils/money/format-price'
import type { CurrencyMorpheme } from './insert-morpheme'

type Result = {
  mainCost: string
  secondCost?: string
}

type Options = {
  morpheme?: CurrencyMorpheme
  isInteger?: boolean
}

export function formatRate(
  rate?: Rate | null,
  options: Options = {
    morpheme: 'postfix',
    isInteger: false,
  },
): Result {
  if (!rate) {
    return {
      mainCost: '',
    }
  }

  const { primary, secondary } = rate
  const mainCost = formatPrice(primary, options)
  if (!secondary || secondary.currency === primary.currency) {
    return {
      mainCost,
    }
  }

  return {
    mainCost: formatPrice(secondary, options),
    secondCost: mainCost,
  }
}
