import { CarRentalClassType } from '@etta/modules/car-rental-search/core/enums'

export function classTypeMapper(classType?: string | null): CarRentalClassType | undefined {
  switch (classType) {
    case 'M':
      return CarRentalClassType.Mini
    case 'E':
      return CarRentalClassType.Economy
    case 'C':
      return CarRentalClassType.Compact
    case 'I':
      return CarRentalClassType.Intermediate
    case 'S':
      return CarRentalClassType.Standard
    case 'F':
      return CarRentalClassType.Fullsize
    case 'P':
      return CarRentalClassType.Premium
    case 'L':
      return CarRentalClassType.Luxury
    case 'O':
      return CarRentalClassType.Oversize
    case 'X':
      return CarRentalClassType.Special
    default:
      return undefined
  }
}
