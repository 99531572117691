import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Wrapper } from './hold-trip-styled'

type Props = {
  isHoldTrip: boolean
  holdTripDate?: string
}

const i18nBase = 'PostBooking.TripDetails'

export function HoldTrip({ isHoldTrip, holdTripDate }: Props) {
  const { t } = useTranslation()

  if (!isHoldTrip) {
    return null
  }

  return (
    <Wrapper>
      <Text variant="footnoteMedium" color="mainText">
        {t(`${i18nBase}.AutoCancelMessage`, { date: holdTripDate })}
      </Text>
    </Wrapper>
  )
}
