import type { RideHailExpenseConfigResponseValueObject } from '@etta/modules/ride-hail/core'
import type { RideHailExpenseConfigResponse } from '@fiji/graphql/hooks'
import { mapToRideHailExpenseCode } from '@etta/modules/ride-hail/infra/mappers/ride-hail-expense-code.mapper'

export function mapToRideHailExpenseConfigResponseValueObject(
  input: RideHailExpenseConfigResponse,
): RideHailExpenseConfigResponseValueObject {
  return {
    expenseEnabled: input.expenseEnabled,
    customExpenseEnabled: input.customExpenseEnabled,
    memoEnabled: input.memoEnabled,
    memoRequired: input.memoRequired,
    expenseCodes: input.expenseCodes.map(mapToRideHailExpenseCode),
  }
}
