import { lazy } from 'react'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

const ExplorePage = lazy(() => import('@etta/screens/explore-page'))

export const exploreRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.explore,
      exact: true,
    },
    documentTitle: getDocumentTitle('Book'),
    transitionIndex: 2,
    transitionGroup: 'fade-through',
    component: () => <ExplorePage />,
  },
]
