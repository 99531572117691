import { BookRideHailErrorReason } from '@fiji/graphql/hooks'
import i18n from '@fiji/i18n'

export function mapBookRideHailErrorReasonToErrorMessage(error: BookRideHailErrorReason | null) {
  switch (error) {
    case BookRideHailErrorReason.ApplePayNotSupported:
      return i18n.t('Mobility.BookingErrorWithMessagePage.ApplePayNotSupported')
    case BookRideHailErrorReason.SearchResultIdInvalid:
      return i18n.t('Mobility.BookingErrorWithMessagePage.SearchResultIdInvalid')
    case BookRideHailErrorReason.SearchResultIdExpired:
      return i18n.t('Mobility.BookingErrorWithMessagePage.SearchResultIdExpired')
    case BookRideHailErrorReason.PaymentMethodInvalid:
      return i18n.t('Mobility.BookingErrorWithMessagePage.PaymentMethodInvalid')
    case BookRideHailErrorReason.OutOfPolicy:
      return i18n.t('Mobility.BookingErrorWithMessagePage.OutOfPolicy')
    case BookRideHailErrorReason.PickupTimeInvalid:
      return i18n.t('Mobility.BookingErrorWithMessagePage.PickupTimeInvalid')
    case BookRideHailErrorReason.ExpenseCodeMaxLengthExceeded:
      return i18n.t('Mobility.BookingErrorWithMessagePage.ExpenseCodeMaxLengthExceeded')
    case BookRideHailErrorReason.BookingExistedForSelectedTime:
      return i18n.t('Mobility.BookingErrorWithMessagePage.BookingExistedForSelectedTime')
    default:
      return undefined
  }
}

export function mapBookRideHailErrorReasonToIsShowUberExternalLink(
  error: BookRideHailErrorReason | null,
) {
  switch (error) {
    case BookRideHailErrorReason.ExpenseCodeMaxLengthExceeded:
      return false
    default:
      return true
  }
}
