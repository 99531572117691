import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { FlightSegmentContainer, FlightSegmentContent } from './segments.styled'

type Props = {
  origin: string
  destination: string
  startDateTime: string
  endDateTime: string
}

const DATE_FORMAT_VALUE = 'MMM d, h:mm a'

export const TripFlightSegment = ({ endDateTime, startDateTime, destination, origin }: Props) => {
  const { t } = useTranslation()
  const departureDate = dateFormat(new Date(deleteTimezone(startDateTime)), DATE_FORMAT_VALUE)
  const arrivalDate = dateFormat(new Date(deleteTimezone(endDateTime)), DATE_FORMAT_VALUE)
  return (
    <FlightSegmentContainer>
      <Icon size={30} name="flightPWA" color="bodyText" />
      <FlightSegmentContent>
        <Text variant="footnoteStrong" color="bodyText">
          {origin} <Icon size={'small'} name="arrowForwardPWA" /> {destination}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t('AlignDatesModal.Departure', { date: departureDate })}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t('AlignDatesModal.Arrival', { date: arrivalDate })}
        </Text>
      </FlightSegmentContent>
    </FlightSegmentContainer>
  )
}
