import type { PlainTime as Time } from '@fiji/types'

export function dateTimeToShort(date: Date, time: Time): string {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const dateObject = new Date(
    Date.UTC(year, month, day, time.hours, time.minutes, time.seconds || 0),
  )
  return dateObject.toISOString().slice(0, -1)
}
