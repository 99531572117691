import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div<{ withEditLabel?: boolean }>`
  margin-left: auto;

  ${({ withEditLabel }) =>
    withEditLabel &&
    css`
      position: relative;
      display: flex;
      align-self: flex-start;
    `}
`

export const EditBtn = styled(Button).attrs((p) => ({
  ...p,
  variant: 'link',
  size: 'small',
  paddings: false,
}))`
  min-width: auto;
`
