import { useTranslation } from 'react-i18next'
import { FlightDetailsAirlinesLogos } from '@etta/components/flight-details-airlines-logos/flight-details-airlines-logos'
import { Text } from '@etta/ui/text'
import { IconButton } from '@etta/ui/icon-button'
import type { Props } from '../../types'
import {
  Container,
  Section,
  AirlineLogosWrapper,
  AirlinesWrapper,
  Ellipse,
} from './flight-details-header-desktop-styled'

export function FlightDetailsHeaderDesktop({
  title,
  airlinesLogos,
  flightLabels,
  flightStopsLabel,
  onClose,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container data-tracking-id="segment-details-modal-header">
      <Section>
        <AirlineLogosWrapper>
          <FlightDetailsAirlinesLogos airlinesLogos={airlinesLogos} />
        </AirlineLogosWrapper>
        <AirlinesWrapper>
          <Text aria-label={title} variant="title3">
            {title}
          </Text>
          <Text aria-label={flightLabels.join(', ')} variant="footnoteMedium" color="bodyText">
            {flightLabels.join(', ')}
            <Ellipse>•</Ellipse>
            <Text
              aria-label={flightStopsLabel}
              data-tracking-id="flight-stops-text"
              variant="footnoteMedium"
              color="bodyText">
              {flightStopsLabel}
            </Text>
          </Text>
        </AirlinesWrapper>
      </Section>

      <IconButton
        onClick={onClose}
        icon="closeFilledPWA"
        size="medium"
        color="bodyText"
        aria-label={t('Accessibility.Header.CloseLabel')}
      />
    </Container>
  )
}
