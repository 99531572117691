import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { subHeadMedium } from '@fiji/style'
import { Modal } from '@etta/ui/modal'

export const CheckboxWrapper = styled.div`
  margin-left: 17px;
`

export const Footer = styled.footer`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  padding: 14px 24px;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClearAllButton = styled(Button)`
  margin-right: 31px;
`

export const Body = styled(Modal.Body)`
  padding: 20px 74px 0 24px;
  overflow-x: hidden;
  overflow-y: auto;
`

export const HeaderWrapper = styled.div`
  padding-right: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Description = styled.div`
  ${subHeadMedium};
  color: ${(props) => props.theme.colors.bodyText};
`
