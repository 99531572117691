import type { ReactElement } from 'react'
import { useEffect, forwardRef, useImperativeHandle } from 'react'
import { Page } from './page'
import { PageSwitcherContext } from './page-switcher-context'
import { useCreatePageSwitcherContext } from './use-create-page-switcher-context'
import { PageContainer } from './page-switcher-styled'
import { SwitchScreen } from './switch-screen'

type Props = {
  children: React.ReactNode[]
  onPageChanged?: (key: string) => void
}

export type PageSwitcherRef = {
  isNext: boolean
  isBack: boolean
  onNext: () => void
  onBack: () => void
  gotoPage: (key: string) => void
}

function PageSwitcher(
  { children, onPageChanged }: Props,
  ref: React.ForwardedRef<PageSwitcherRef>,
) {
  const { context } = useCreatePageSwitcherContext(children as ReactElement[])

  useEffect(() => {
    if (onPageChanged) {
      onPageChanged(context.activePageKey)
    }
  }, [context.activePageKey, onPageChanged])

  useImperativeHandle(
    ref,
    () => ({
      isNext: context.isNext,
      isBack: context.isBack,
      onNext: () => context.next(),
      onBack: () => context.back(),
      gotoPage: (key: string) => context.gotoPage(key),
    }),
    [context],
  )

  return (
    <PageSwitcherContext.Provider value={context}>
      <PageContainer>
        <SwitchScreen>{children}</SwitchScreen>
      </PageContainer>
    </PageSwitcherContext.Provider>
  )
}

const PageSwitcherNamespace = Object.assign(forwardRef(PageSwitcher), { Page })

export { PageSwitcherNamespace as PageSwitcher }
