import { useCallback, useMemo } from 'react'
import { useUserContext } from '@etta/modules/user'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { PersonalInfo } from '@fiji/types'
import type { AirPreferenceExcerptInput, TravelerOptions, Phone } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import type { Gender } from '@etta/core/enums'
import type { AirPreferenceExcerpt, ProfileOptions } from '../types'
import { usePurchaseInfo } from './use-purchase-info'
import type { ComponentProps } from './types'

type Props = {
  personalInfo: PersonalInfo
  onSubmit: (data: PersonalInfo, excerpt?: AirPreferenceExcerptInput | null) => void
  travelerOptions?: TravelerOptions | null
  preferenceExcerpt?: AirPreferenceExcerpt
  primaryTraveler?: PurchaseInfoTravelerValueObject
  profileOptions: ProfileOptions
  isAir?: boolean
  isKtnHidden?: boolean
  isRedressNumber?: boolean
  onClose?: () => void
  isRail?: boolean
  isPostPurchase?: boolean
}

export function useUserInfoForm({
  personalInfo,
  onSubmit,
  travelerOptions,
  preferenceExcerpt,
  primaryTraveler,
  profileOptions,
  isAir,
  isKtnHidden,
  isRedressNumber,
  isRail,
  isPostPurchase,
  onClose = () => {},
}: Props) {
  const {
    isOpen: isDateOfBirthPopupVisible,
    handleOpen: onDateOfBirthOpen,
    handleClose: onDateOfBirthComponentClose,
  } = useTogglePopup()
  const { userStore } = useUserContext()
  const { purchaseInfoActions } = usePurchaseInfoContext()
  const {
    errors,
    values,
    onFieldChange,
    handleSubmit,
    initialDob,
    initialGender,
    initialSuffix,
    initialEmail,
    initialFirstName,
    initialMiddleName,
    initialLastName,
    initialAddress,
    initialPhoneNumber,
    initialPersonalMobile,
  } = usePurchaseInfo({
    personalInfo,
    onSubmit,
    travelerOptions,
    preferenceExcerpt,
    primaryTraveler,
    profileOptions,
  })
  const {
    isTitleChangeable,
    isNameChangeable,
    isDateOfBirthChangeable,
    isGenderRequired,
    isDateOfBirthRequired,
    isGenderChangeable,
    isSuffixChangeable,
    isFirstNameRequired,
    isLastNameRequired,
    isMiddleNameRequired,
    isSuffixRequired,
    isEMailRequired,
    isPhoneNumberRequired,
    isAddressRequired,
    isAddressChangeable,
    isPhoneNumberChangeable,
    isEMailChangeable,
  } = travelerOptions || {}

  const isGuest = userStore.isGuest

  const isDateOfBirthInputDisabled = useMemo(
    () =>
      isRail
        ? !(!isPostPurchase || (!initialDob && isDateOfBirthRequired))
        : !(isDateOfBirthChangeable || (!initialDob && isDateOfBirthRequired)),
    [initialDob, isDateOfBirthChangeable, isDateOfBirthRequired, isRail, isPostPurchase],
  )

  const isGenderInputDisabled = useMemo(
    () =>
      isRail
        ? !(!isPostPurchase || (!initialGender && isGenderRequired))
        : !(isGenderChangeable || (!initialGender && isGenderRequired)),
    [isGenderChangeable, isGenderRequired, initialGender, isRail, isPostPurchase],
  )

  const isSuffixInputDisabled = useMemo(() => {
    if (isGuest) {
      return false
    }

    return !(isSuffixChangeable || (!initialSuffix && isSuffixRequired))
  }, [isGuest, isSuffixRequired, isSuffixChangeable, initialSuffix])

  const isTitleDisabled = useMemo(() => (isGuest ? false : !isTitleChangeable), [
    isGuest,
    isTitleChangeable,
  ])

  const isEmailInputDisabled = useMemo(
    () => (isGuest ? false : !(isEMailChangeable || (!initialEmail && isEMailRequired))),
    [initialEmail, isEMailRequired, isGuest, isEMailChangeable],
  )

  const isFirstNameDisabled = useMemo(
    () => (isGuest ? false : !(isNameChangeable || (!initialFirstName && isFirstNameRequired))),
    [isGuest, isNameChangeable, isFirstNameRequired, initialFirstName],
  )

  const isMiddleNameDisabled = useMemo(
    () => (isGuest ? false : !(isNameChangeable || (!initialMiddleName && isMiddleNameRequired))),
    [isGuest, isNameChangeable, isMiddleNameRequired, initialMiddleName],
  )

  const isLastNameDisabled = useMemo(
    () => (isGuest ? false : !(isNameChangeable || (!initialLastName && isLastNameRequired))),
    [isGuest, isNameChangeable, isLastNameRequired, initialLastName],
  )

  const isAddressDisabled = useMemo(() => {
    const isAddressAvailable =
      initialAddress?.street1 &&
      initialAddress?.city &&
      initialAddress?.postalCode &&
      initialAddress?.countryCode

    return isGuest ? false : !(isAddressChangeable || (!isAddressAvailable && isAddressRequired))
  }, [isGuest, isAddressChangeable, isAddressRequired, initialAddress])

  const isPhoneNumberComplete = (phone?: Phone) => {
    return phone?.number && phone?.countryCode && phone?.type
  }

  const isPhoneNumberInputDisabled = useMemo(
    () =>
      isGuest
        ? false
        : !(
            isPhoneNumberChangeable ||
            (!(initialPhoneNumber instanceof Array
              ? initialPhoneNumber.every((phoneNumber) => isPhoneNumberComplete(phoneNumber))
              : isPhoneNumberComplete(initialPhoneNumber)) &&
              isPhoneNumberRequired)
          ),
    [isGuest, isPhoneNumberChangeable, initialPhoneNumber, isPhoneNumberRequired],
  )

  const isPersonalMobileInputDisabled = useMemo(
    () =>
      isGuest
        ? false
        : !(
            isPhoneNumberChangeable ||
            (!isPhoneNumberComplete(initialPersonalMobile) && isPhoneNumberRequired)
          ),
    [isGuest, isPhoneNumberChangeable, initialPersonalMobile, isPhoneNumberRequired],
  )
  const isKtnEditingDisabled = Boolean(preferenceExcerpt?.knownTravelerNumber)
  const isRedressNumberEditingDisabled = Boolean(preferenceExcerpt?.redressNumber)

  const formattedValues = useMemo(() => {
    return {
      ...values,
      title: values.title?.toUpperCase().replace('.', ''),
      suffix: values.suffix?.toUpperCase(),
    }
  }, [values])

  const onDateOfBirthClose = () => {
    purchaseInfoActions.setIsDOBTouched(true)
    onDateOfBirthComponentClose()
  }

  const onChangeOfGender = useCallback(
    (value: Gender[]) => {
      if (value.length > 0) {
        purchaseInfoActions.setIsGenderTouched(true)
      }
      onFieldChange('gender')(value[0])
    },
    [purchaseInfoActions, onFieldChange],
  )

  const componentProps: ComponentProps = {
    profileOptions,
    isAir,
    isDateOfBirthPopupVisible,
    onDateOfBirthOpen,
    onDateOfBirthClose,
    onChangeOfGender,
    errors,
    values: formattedValues,
    onFieldChange,
    handleSubmit,
    isGenderRequired,
    isDateOfBirthRequired,
    isFirstNameRequired,
    isLastNameRequired,
    isMiddleNameRequired,
    isSuffixRequired,
    isEMailRequired,
    isPhoneNumberRequired,
    isAddressRequired,
    isKtnHidden,
    isRedressNumber,
    onClose,
    isDateOfBirthInputDisabled,
    isGenderInputDisabled,
    isSuffixInputDisabled,
    isKtnEditingDisabled,
    isRedressNumberEditingDisabled,
    isTitleDisabled,
    isEmailInputDisabled,
    isFirstNameDisabled,
    isMiddleNameDisabled,
    isLastNameDisabled,
    isAddressDisabled,
    isPhoneNumberInputDisabled,
    isPersonalMobileInputDisabled,
  }

  return {
    componentProps,
  }
}
