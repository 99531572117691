import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'
import { Spinner } from '@etta/ui/spinner'
import { TextField } from '@etta/ui/text-field'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import {
  Body,
  CharCounter,
  Footer,
  Container,
  ProgressContainer,
  HeaderWrapper,
  Error,
} from './edit-trip-modal-dialog-desktop-styled'

const INPUT_MAX_LENGTH = 64
const i18nBase = 'TripReview.EditTripNameDialog'

export function EditTripNameDialogDesktopContent({
  value,
  onChange,
  onSubmit,
  onClose,
  isLoading,
  isDone,
  isDisabled,
  hasSubmitFailed,
}: Omit<LayoutProps, 'isOpen'>) {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (hasSubmitFailed) {
      setErrorMessage(t(`${i18nBase}.SubmitFailedError`))
    }
  }, [hasSubmitFailed, t])

  const handleSubmit = () => {
    onSubmit()
  }

  if (isLoading) {
    return (
      <ProgressContainer>
        <div>
          <Spinner type="spinout" />
        </div>
        <Block marginTop={12}>
          <Text variant="subHeadStrong" color="mainText">
            {t(`${i18nBase}.Saving`)}
          </Text>
        </Block>
      </ProgressContainer>
    )
  }

  if (isDone) {
    return (
      <ProgressContainer>
        <Icon name="checkOutlinePWA" color="primary" size={56} />
        <Block marginTop={12}>
          <Text variant="subHeadStrong" color="mainText">
            {t(`${i18nBase}.Saved`)}
          </Text>
        </Block>
      </ProgressContainer>
    )
  }

  return (
    <Container>
      <HeaderWrapper>
        <Modal.Title leftSeparatorWidth={24}>{t(`${i18nBase}.Title`)}</Modal.Title>
      </HeaderWrapper>
      <Body>
        <TextField
          type="text"
          label={t(`${i18nBase}.TripName`)}
          value={value}
          onChange={onChange}
          maxLength={INPUT_MAX_LENGTH}
        />
        <CharCounter>
          {t(`${i18nBase}.CharValidation`, {
            length: value.length,
            maxLength: INPUT_MAX_LENGTH,
          })}
        </CharCounter>
        {errorMessage && <Error>{errorMessage}</Error>}
      </Body>
      <Footer withBorder>
        <Button variant="link" onClick={onClose} data-tracking-id="edit-trip-name-cancel">
          {t(`${i18nBase}.Cancel`)}
        </Button>
        <Button
          minWidth={21}
          isSubmit
          disabled={!value || isDisabled}
          onClick={handleSubmit}
          data-tracking-id="edit-trip-name-save">
          {t(`${i18nBase}.Save`)}
        </Button>
      </Footer>
    </Container>
  )
}
