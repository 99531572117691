import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { EcoCheckSummaryMobile } from './mobile'
import { EcoCheckSummaryDesktop } from './desktop'

export function EcoCheckSummaryLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<EcoCheckSummaryMobile {...props} />}
      desktopSlot={<EcoCheckSummaryDesktop {...props} />}
    />
  )
}
