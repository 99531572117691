import styled from 'styled-components'

export const GetHelpNowWrapper = styled.div`
  padding-left: 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 97px;
`
export const InformationContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`
export const ButtonWrapper = styled.a`
  text-decoration: none;
  margin-top: 12px;
`

export const ImageContainer = styled.div`
  width: 250px;
  position: relative;
  top: 15px;
`

export const TextWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-flow: column;
`
