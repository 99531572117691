import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { ModalAnimation } from '@etta/ui/modal-animation'
import { mergeRefs } from '@fiji/utils/merge-refs'
import { useFocusLock } from '@fiji/hooks/use-focus-lock'
import type { LayoutProps } from '../types'
import { DATE_PICKER_DEFAULT_HEIGHT, DATE_PICKER_WIDTH } from './const'
import { Container, StyledDatePicker } from './date-picker-modal-desktop-styled'
import { useCloseOnEscape } from './use-close-on-escape'

export function DatePickerModalDesktop({
  minDate,
  maxDate,
  startDateLegend,
  endDateLegend,
  isRange,
  isOpen,
  onDatesSelect,
  onClose,
  startTitle,
  endTitle,
  isEndDateSame,
  numberOfMonths,
  isOutsideRange,
  dates,
  previousDate,
  customElementHeight = DATE_PICKER_DEFAULT_HEIGHT,
  isReturnDate,
  highlightDates,
  isDepartureDatePickerDisabled,
}: LayoutProps) {
  const { ref } = useClickOutside(() => {
    if (isOpen) {
      onClose()
    }
  })

  useShouldOpenInViewport({
    elementHeight: customElementHeight,
    elementWidth: DATE_PICKER_WIDTH,
    isVisible: isOpen,
    ref,
  })

  useCloseOnEscape({
    isOpen,
    onClose,
    ref,
  })

  const { focusLockContainerRef, catchFocus } = useFocusLock({
    disabled: !isOpen,
    autoFocus: true,
  })

  const mergedRefs = mergeRefs([ref, focusLockContainerRef])

  return (
    <ModalAnimation isOpen={isOpen}>
      <Container ref={mergedRefs} onFocus={catchFocus}>
        <StyledDatePicker
          tabIndex={0}
          isEndDateSame={isEndDateSame}
          minDate={minDate}
          maxDate={maxDate}
          date={dates.date}
          dateEnd={dates.dateEnd}
          startDateLegend={startDateLegend}
          endDateLegend={endDateLegend}
          previousDate={previousDate}
          isRange={isRange}
          onSelect={onDatesSelect}
          startTitle={startTitle}
          endTitle={endTitle}
          numberOfMonths={numberOfMonths}
          isOutsideRange={isOutsideRange}
          isOpen={isOpen}
          isReturnDate={isReturnDate}
          highlightDates={highlightDates}
          isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
          onClose={onClose}
        />
      </Container>
    </ModalAnimation>
  )
}
