import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query/use-hotel-search-query'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ROUTES } from '@fiji/routes'
import { PostBookingAction } from '@fiji/enums'
import { getInitialFilters } from '@fiji/hooks/search-queries/use-hotel-search-query/get-initial-filters'
import { useBackSearch } from '@fiji/hooks/search-queries/use-back-search'
import { ScreenType, useScreenType } from '@fiji/modes'
import { trackAction } from '@fiji/utils/tracking'
import { useHotelsEmissions } from '@fiji/hooks/sustainability'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import { useHotelDetailsModal } from './use-hotel-details-modal'
import { useFetchHotels } from './use-fetch-hotels'
import { useDatesLabel } from './use-dates-label'

export function useHotelResult() {
  const {
    hotelSearchResultsStore,
    hotelResultsAction,
    hotelFiltersAction,
  } = useHotelSearchResultsContext()
  const { activeTripStore } = useReviewTripContext()
  const { multipleHotelProvidersStore } = useMultipleHotelProvidersContext()
  const history = useHistory()
  const { activeItineraryToggle, tripExpiredToggle } = hotelSearchResultsStore
  const { handleResetViewState } = hotelResultsAction
  const { providerName, multipleHotelProvidersSearchDialog } = multipleHotelProvidersStore
  const { maximumQuantityToggle, activeTripId } = activeTripStore
  const { queryParams, navigateTo } = useHotelSearchQuery()
  const { onBack } = useBackSearch()
  const policyModalToggle = useTogglePopup()
  const screenType = useScreenType()
  const { hotelSearchFormActions } = useHotelSearchFormContext()

  const { hotelsResult, itineraryId, filterOptions, appliedFilters } = useFetchHotels({
    queryParams,
  })
  const { t } = useTranslation()

  useEffect(() => {
    handleResetViewState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hotelSearchResultsStore.isLoading) {
      return
    }

    const status = (() => {
      switch (true) {
        case hotelSearchResultsStore.isError:
          return 'error'
        case hotelSearchResultsStore.hotels.length === 0:
          return 'no-results'
        default:
          return 'success'
      }
    })()

    trackAction('hotel-search-results', {
      status,
    })
  }, [
    hotelSearchResultsStore.hotels.length,
    hotelSearchResultsStore.isError,
    hotelSearchResultsStore.isLoading,
  ])

  const locationsLabel = useMemo(() => {
    return queryParams.isSearchByNewArea
      ? t('HotelResultPage.MapArea')
      : queryParams.location?.name || ''
  }, [queryParams.isSearchByNewArea, queryParams.location?.name, t])

  const { datesLabel } = useDatesLabel({
    checkInDate: queryParams.checkInDate,
    checkOutDate: queryParams.checkOutDate,
  })

  const hotelFilters = {
    filterOptions,
    appliedFilters,
  }

  const isFiltersApplied = false

  useEffect(() => {
    return () => {
      hotelSearchFormActions.handleCloseEditModal()
    }
  }, [hotelSearchFormActions])

  const hotelDetails = useHotelDetailsModal({
    checkIn: queryParams.checkInDate!,
    checkOut: queryParams.checkOutDate!,
    itineraryId,
    bookingId: queryParams.bookingId,
    hotelResultId: hotelSearchResultsStore.searchId,
  })

  const onBackToExplore = () => {
    navigateTo(ROUTES.explore, {})
  }

  const handleGoToRTPAndCloseDialog = () => {
    history.replace(
      ROUTES.reviewTrip.main({ itineraryId: activeTripId, bookingId: queryParams.bookingId }),
    )
    maximumQuantityToggle.handleClose()
  }

  const handleBackToTripAndCloseDialog = () => {
    const route = ROUTES.postBooking.trip({
      id: queryParams.bookingId,
      transactionGroupId: queryParams.transactionGroupId,
      isForceRefetch: false,
    })

    history.replace(route)
    multipleHotelProvidersSearchDialog.handleClose()
  }

  const handleBackClick = () => {
    if (screenType !== ScreenType.Mobile) {
      hotelFiltersAction.handleClearAllFilters()
      onBackToExplore()
      return
    }

    if (queryParams.postBookingAction === PostBookingAction.Modify) {
      onBack()
      return
    }

    navigateTo(ROUTES.hotel.search, {
      hotelFilters: {
        ...getInitialFilters({
          initialDistance: queryParams.hotelFilters?.distance,
        }),
      },
    })
  }

  const handleOpenDefault = () => {
    if (screenType !== ScreenType.Mobile) {
      onBackToExplore()
      return
    }

    navigateTo(ROUTES.hotel.search)
  }

  const hotelDates = useMemo(() => {
    return {
      checkInDate: queryParams.checkInDate,
      checkOutDate: queryParams.checkOutDate,
    }
  }, [queryParams])

  const {
    averageSustainabilityScore,
    existingHotelEmissions,
    loading: areHotelEmissionsLoading,
  } = useHotelsEmissions({
    hotels: hotelSearchResultsStore.hotels,
    geocode: {
      lat: queryParams?.location?.latitude,
      long: queryParams?.location?.longitude,
    },
    checkInDate: queryParams.checkInDate,
    checkOutDate: queryParams.checkOutDate,
  })

  const hotelEmission = existingHotelEmissions.find(
    (hotelEmission) => hotelEmission.customRef === hotelDetails.hotelDetails?.hotelId,
  )

  const actualHotelEmission = {
    averageLocationSustainabilityScore: averageSustainabilityScore,
    customRef: hotelEmission?.customRef,
    tonnesOfEmissions: hotelEmission?.tonnesOfEmissions,
    sustainabilityScore: hotelEmission?.sustainabilityScore,
    equivalences: hotelEmission?.equivalences,
    certifications: hotelEmission?.certifications,
    loading: areHotelEmissionsLoading,
  }

  return {
    hotelDates,
    handleOpenDefault,
    handleBackClick,
    hotelDetails,
    policyModalToggle,
    hotelsResult,
    hotelFilters,
    locationsLabel,
    datesLabel,
    location: queryParams.location,
    latitude: queryParams.location!.latitude!,
    longitude: queryParams.location!.longitude!,
    distance: queryParams.hotelFilters?.distance,
    itineraryId,
    isFiltersApplied,
    actualHotelEmission,
    existingHotelEmissions,
    activeItineraryToggle,
    tripExpiredToggle,
    handleGoToRTPAndCloseDialog,
    maximumQuantityToggle,
    multipleHotelProvidersSearchDialog,
    handleBackToTripAndCloseDialog,
    providerName,
  }
}
