import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { RideHailBookingValueObject } from '@etta/modules/ride-hail/core'
import { BookRideHailErrors } from '../../core/errors/book-ride-hail.error'
import type { BookRideHailArgs, RideHailBookAdapterResult } from '../types'
import { buildBookRideHailRequest } from '../builders/ride-hail-book.request-builder'
import { mapToRideHailBookingValueObject } from '../mappers/ride-hail-booking.mapper'
import { RideHailBookDataProvider } from './ride-hail-book.data-provider/ride-hail-book.data-provider'

@Adapter()
export class RideHailBookAdapter {
  constructor(
    @Inject()
    private rideHailBookDataProvider: RideHailBookDataProvider,
  ) {}

  async book(args: BookRideHailArgs): RideHailBookAdapterResult<RideHailBookingValueObject> {
    try {
      const request = buildBookRideHailRequest(args)
      const { data, errors } = await this.rideHailBookDataProvider.bookRideHail(request)

      if (errors || !data) {
        return Result.Err(new BookRideHailErrors.BookRideHailError('DataSourceError'))
      }

      if (data?.bookRideHailWithError.__typename === 'BookRideHailErrorResponse') {
        return Result.Err(
          new BookRideHailErrors.BookRideHailError(data.bookRideHailWithError.reason),
        )
      }

      if (data?.bookRideHailWithError.__typename === 'UnexpectedError') {
        throw data.bookRideHailWithError.__typename
      }

      return Result.Ok(mapToRideHailBookingValueObject(data.bookRideHailWithError))
    } catch (e) {
      return Result.Err(new BookRideHailErrors.UnexpectedBookRideHailError(e))
    }
  }
}
