import { useState } from 'react'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { config } from '@fiji/config'
import { STAGE_NAME } from '@fiji/enums'
import { useUserContext } from '@etta/modules/user'

const SHA_SLICE_END = 6
const DEFAULT_COMMIT = '______'

const envTitle = config.stageName

const commitHash = process.env.REACT_APP_GIT_SHA?.slice(0, SHA_SLICE_END) || DEFAULT_COMMIT
const currentTime = process.env.REACT_APP_GET_TIMESTAMP || Date.now()
const buildTime = dateFormat(new Date(currentTime), 'yyyy MMM dd HH:MM')
const OPEN_COUNT = 3
const initialCount = config.stageName === STAGE_NAME.PROD ? 0 : OPEN_COUNT

export function useBuildInfo() {
  const { userStore } = useUserContext()
  const [count, setCount] = useState(initialCount)
  const { partnerId, companyId, customerId } = userStore
  const onClick = () => {
    setCount((prev) => prev + 1)
  }

  const isHidden = count < OPEN_COUNT

  return {
    isHidden,
    onClick,
    buildTime,
    commitHash,
    envTitle,
    partnerId,
    companyId,
    customerId,
  }
}
