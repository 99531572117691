import { Store } from '@etta/di'
import type { TripsList } from '@etta/modules/post-booking-list/core/value-object/trips-list.value-object'

@Store()
export class RideHailSearchOnBookTabStore {
  tripList: TripsList[] = []
  isLoading: boolean = false
  isError: boolean = false
  isOpenTripSelectionModal = false

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setTripsList(tripsList: TripsList[]) {
    this.tripList = tripsList
  }

  setIsOpenTripSelectionModal(isOpen: boolean) {
    this.isOpenTripSelectionModal = isOpen
  }
}
