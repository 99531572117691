import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import { Container, Wrapper, Content } from './alert-card-desktop-styled'

type Props = {
  title: string
  message: string
  onRedirect: () => void
}

export const AlertCardDesktop = observer(function AlertCardDesktop({
  title,
  message,
  onRedirect,
}: Props) {
  return (
    <Container onClick={onRedirect}>
      <Wrapper>
        <Block marginRight={16}>
          <Icon name="warningFilledPWA" color="warning" />
        </Block>
        <Content>
          <Block marginBottom={10} isFlexBlock>
            <Text aria-label={title} variant="title3" color="mainText">
              {title}
            </Text>
            <Icon name="chevronRightPWA" color="bodyText" size="small" />
          </Block>
          <Text aria-label={message} variant="subHeadMedium" color="bodyText">
            {message}
          </Text>
        </Content>
      </Wrapper>
      <Separator />
    </Container>
  )
})
