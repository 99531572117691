import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTextVariant, TimeRangeOption } from '@fiji/enums'
import type { TimeRange } from '@fiji/types'
import {
  AirportTitle as AirportTitleNew,
  TabLabel as TabLabelNew,
  TimeSelectorContainer as TimeSelectorContainerNew,
  InputContainer as InputContainerNew,
  SliderContainer as SliderContainerNew,
  Radio as RadioNew,
  ConfirmButton as ConfirmButtonNew,
  TimeLabel,
  Footer,
} from '@etta/components/sliders/time-range-picker/time-range-picker-styled'
import { StyledSlider } from '@etta/components/sliders/shared'
import type { Props } from '../types'

const i18BasePath = 'FlightSearch.TimePicker'

export function TimeTabDesktop({
  actionLabel,
  selectedTime,
  onSubmit,
  convertTimeLabel,
  customTimeValue,
  onCustomTimeChange,
  onRadioButtonClick,
  timeOptions,
  title,
}: Props) {
  const { t } = useTranslation()

  const isShowingConfirmButton = selectedTime.id === TimeRangeOption.CustomTime

  const handleRadioButtonClick = useCallback(
    (time: TimeRange) => () => {
      onRadioButtonClick(time)
      if (time.id !== TimeRangeOption.CustomTime) {
        onSubmit(time)
      }
    },
    [onRadioButtonClick, onSubmit],
  )

  return (
    <>
      <TabLabelNew aria-label={actionLabel}>{actionLabel}</TabLabelNew>

      <AirportTitleNew aria-label={title}>{title}</AirportTitleNew>

      <TimeSelectorContainerNew>
        {timeOptions.map((time) => {
          const timeLabel = convertTimeLabel(time)

          return (
            <InputContainerNew
              key={time.id}
              isCustomTimeSelected={
                selectedTime.id === TimeRangeOption.CustomTime &&
                time.id === TimeRangeOption.CustomTime
              }>
              <RadioNew
                key={time.id}
                aria-label={timeLabel}
                isChecked={time.id === selectedTime.id}
                onChange={handleRadioButtonClick(time)}>
                <TimeLabel variant={StyledTextVariant.subHeadMedium} color="mainText">
                  {timeLabel}
                </TimeLabel>
              </RadioNew>
              {time.id === selectedTime.id && time.id === TimeRangeOption.CustomTime && (
                <SliderContainerNew>
                  <StyledSlider
                    onChange={(result) => onCustomTimeChange(result as number)}
                    value={customTimeValue}
                    min={0}
                    max={23}
                    marks={[0, 23]}
                    markTitleType="time"
                  />
                </SliderContainerNew>
              )}
            </InputContainerNew>
          )
        })}
      </TimeSelectorContainerNew>
      {isShowingConfirmButton && (
        <Footer>
          <ConfirmButtonNew onClick={() => onSubmit(selectedTime)}>
            {t(i18BasePath + '.ConfirmButton')}
          </ConfirmButtonNew>
        </Footer>
      )}
    </>
  )
}
