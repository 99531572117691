import { Inject, Store } from '@etta/di'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type { RailFilterOptionsValueObject } from '../../core/value-objects/rail-search-result.value-object'
import { RailQueryParamsStore } from './rail-query-param.store'

@Store()
export class FiltersStore {
  private _appliedFilters: RailFilterOptionsValueObject = {}
  private _changedFilters: RailFilterOptionsValueObject = {}

  constructor(@Inject() private railQueryParamsStore: RailQueryParamsStore) {
    this._appliedFilters = this.getInitialFilters()
  }

  get isFiltersEqual() {
    return checkIsEqual(this._appliedFilters, this._changedFilters)
  }

  get isFiltersApplied() {
    return !checkIsEqual(this.appliedFilters, this._changedFilters)
  }

  get changedFilters() {
    return this._changedFilters
  }

  get appliedFilters() {
    return this._appliedFilters
  }

  setChangedFilters(newFilters: Partial<RailFilterOptionsValueObject>) {
    this._changedFilters = { ...this._changedFilters, ...newFilters }
  }

  setAppliedFilters(filter: RailFilterOptionsValueObject) {
    this._appliedFilters = filter
  }

  dropStore() {
    this._changedFilters = {}
    this._appliedFilters = this.getInitialFilters()
  }

  getInitialFilters(railLegType?: string): RailFilterOptionsValueObject {
    const filters = {
      destinationTimeRange: {},
      originTimeRange: {},
      stops: [],
      trainCompany: [],
      excludeOutOfPolicy: false,
    }
    const origin = this.railQueryParamsStore.originPlace?.name
      ? [{ code: this.railQueryParamsStore.originPlace?.name }]
      : []
    const destination = this.railQueryParamsStore.destinationPlace?.name
      ? [{ code: this.railQueryParamsStore.destinationPlace?.name }]
      : []
    if (railLegType === 'return') {
      return {
        ...filters,
        origin: destination,
        destination: origin,
      }
    }

    return {
      ...filters,
      origin: origin,
      destination: destination,
    }
  }
}
