import { useTripExpirationCountdownTimer } from "@fiji/hooks/use-trip-expiration-countdown-timer"

type Props = {
  isActive?: boolean
  isEmptyItinerary?: boolean
  isExpiredTrip: boolean
  handleExpireEvent: () => void
}

export const TripCartHiddenTimer = (props: Props) => {
  useTripExpirationCountdownTimer(props)
  return <></>
}
