import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { RoomRatesModalContentMobile } from './mobile'
import { RoomRatesModalContentDesktop } from './desktop'

export function RoomRatesModalContentLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RoomRatesModalContentMobile {...props} />}
      desktopSlot={<RoomRatesModalContentDesktop {...props} />}
    />
  )
}
