import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Hotels } from '@etta/screens/payment-summary/sections/hotels'
import { CarRentals } from '@etta/screens/payment-summary/sections/car-rentals'
import { Flights } from '@etta/screens/payment-summary/sections/flights'
import { Rail } from '@etta/screens/payment-summary/sections/rail'
import type { TripCostSummaryLayoutProps } from '../../types'
import { TripCostSummarySection } from '../../trip-cost-summary-section'
import { TripCostPrice } from '../../trip-cost-price'
import { Container } from './trip-cost-summary-mobile-styled'

export function TripCostSummaryMobile({
  onClose,
  onCheckout,
  tripCost,
  carServicesCost,
  carRentalSegments,
  hotelSegments,
  flightSegments,
  railSegments,
  hotelSegmentState,
  carRentalSegmentState,
  flightSegmentState,
  railSegmentState,
  isCarRentalPayingNow,
  isHotelPayingNow,
  carRentalTripCost,
  flightTripCost,
  hotelTripCost,
  railTripCost,
  isRailAccreditationEnabled,
  isCheckoutDisabled,
  isHoldFlow,
}: TripCostSummaryLayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TripCostSummary.'

  return (
    <>
      <Header
        withBorderBottom
        backgroundColor="background2"
        leftSlot={<Header.CloseButton color="mainText2" onClick={onClose} />}>
        <Header.Title title={t(i18nBase + 'Title')} color="mainText2" align="left" />
      </Header>
      <Modal.Body>
        <Container>
          <Hotels
            segments={hotelSegments}
            segmentState={hotelSegmentState}
            hotelTripCost={hotelTripCost}
            isPayingNow={isHotelPayingNow && !isHoldFlow}
          />
          <CarRentals
            segments={carRentalSegments}
            segmentState={carRentalSegmentState}
            carTripCost={carRentalTripCost}
            isPayingNow={isCarRentalPayingNow && !isHoldFlow}
          />

          {carServicesCost &&
            carServicesCost.carServicesCost?.map((cost, index) => {
              const isCounterNeeded =
                carServicesCost.carServicesCost && carServicesCost.carServicesCost.length > 1

              return (
                <Block key={carServicesCost.type + index} paddingTop={26}>
                  <TripCostSummarySection
                    title={t(i18nBase + 'CarServices')}
                    subTitle={t(i18nBase + 'CarServices')}
                    cost={cost}
                    type={carServicesCost.type}
                    index={isCounterNeeded ? index + 1 : undefined}
                  />
                </Block>
              )
            })}
          <Flights
            segments={flightSegments}
            segmentState={flightSegmentState}
            flightTripCost={flightTripCost}
            tripCostSummary={tripCost}
            isHoldFlow={isHoldFlow}
          />
          <Rail
            segments={railSegments}
            segmentState={railSegmentState}
            railTripCost={railTripCost}
            tripCostSummary={tripCost}
            isRailAccreditationEnabled={isRailAccreditationEnabled}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <TripCostPrice
          isDisabled={isCheckoutDisabled}
          totalRate={tripCost.totalWithUnusedTicket || tripCost.total}
          onSubmit={onCheckout}
        />
      </Modal.Footer>
    </>
  )
}
