import { UnusedTicketExplanationLayout } from '../../../unused-ticket-explanation'
import type { Args } from '../../types'
import { Wrapper } from './unused-tickets-mobile-styled'
import { UnusedTicketsContentMobile } from './unused-tickets-content-mobile'

export function UnusedTicketsMobile({ unusedTickets, isLoading }: Args) {
  return (
    <Wrapper>
      <UnusedTicketsContentMobile isLoading={isLoading} unusedTickets={unusedTickets} />
      <UnusedTicketExplanationLayout />
    </Wrapper>
  )
}
