import type { SegmentPostBookingValueObject } from '../../../core/value-objects'
import { CarRentalReservationMapper } from './car-rental-reservation.mapper'
import type { TripReservations } from './types'

export class ReservationMapper {
  static toTripSegments(reservations: TripReservations): SegmentPostBookingValueObject[] {
    return reservations.reduce<SegmentPostBookingValueObject[]>((acc, reservation) => {
      if (reservation.__typename === 'CarRentalReservation') {
        const carRental = CarRentalReservationMapper.toCarRentalValueObject(reservation)
        acc.push(carRental)
        return acc
      }

      return acc
    }, [])
  }
}
