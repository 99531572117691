import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useGlobalNavigationLinks } from '@fiji/hooks/navigation/use-global-navigation-links'

const i18Base = 'TripPlanner.MainNavigation'

export function useMobileNavigation() {
  const { t } = useTranslation()

  const {
    exploreLink,
    isExploreActiveHandle,
    isTripsActiveHandle,
    tripsLink,
    supportLink,
    profileLink,
  } = useGlobalNavigationLinks()

  const navigationItems = useMemo(
    () => [
      {
        icon: 'navBookPWA' as const,
        title: t(i18Base + '.Book'),
        to: exploreLink,
        isActive: isExploreActiveHandle,
        trackingId: 'navigation_book',
      },
      {
        icon: 'navTripsPWA' as const,
        title: t(i18Base + '.Trips'),
        to: tripsLink,
        isActive: isTripsActiveHandle,
        trackingId: 'navigation_trips',
      },
      {
        icon: 'navSupportPWA' as const,
        title: t(i18Base + '.Support'),
        to: supportLink,
        isActive: undefined,
        trackingId: 'navigation_support',
      },
      {
        icon: 'navProfilePWA' as const,
        title: t(i18Base + '.Profile'),
        to: profileLink,
        isActive: undefined,
        trackingId: 'navigation_profile',
      },
    ],
    [
      exploreLink,
      isExploreActiveHandle,
      isTripsActiveHandle,
      profileLink,
      supportLink,
      t,
      tripsLink,
    ],
  )

  return {
    navigationItems,
  }
}
