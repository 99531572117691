import styled from 'styled-components'
import { footnoteMedium, largeTitle2 } from '@fiji/style'

export const Container = styled.section`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  max-width: 576px;
  border-radius: 14px;
  box-sizing: border-box;
`

export const HeaderTitle = styled.div`
  text-align: center;
  ${largeTitle2};
  margin-top: 16px;
  margin-bottom: 20px;
`

export const Subtitle = styled.div`
  ${footnoteMedium};
  margin: 12px 0;
  text-align: center;
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const ErrorSubtitle = styled(Subtitle)`
  color: ${(props) => props.theme.colors.error};
`

export const ActionContainer = styled.div`
  margin-top: 28px;
  width: 100%;
`

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
