import { useTranslation } from 'react-i18next'
import { BlockWrapper, ContentContainer } from '../support-page-desktop-styled'
import { Title } from './technical-support-block-styled'

type Props = {
  content: string
}

const i18Base = 'TripPlanner.Support.MainPage'

export function TechnicalSupportBlock({ content }: Props) {
  const { t } = useTranslation()

  return (
    <BlockWrapper aria-labelledby="technical-support">
      <Title as={'h2'} id="technical-support">
        {t(`${i18Base}.TechnicalSupport`)}
      </Title>
      <ContentContainer dangerouslySetInnerHTML={{ __html: content }} />
    </BlockWrapper>
  )
}
