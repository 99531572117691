import { v4 as uuid } from 'uuid'
import {
  PlaceAutocompleteType,
  RecentSearchesFlightSearchParamsCabinClass,
  RecentSearchesFlightSearchParamsFlightType,
  RecentSearchesSearchTimeRangeType,
  SeatmapCabinClass,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import type { Place, PlainTime, TimeRange } from '@fiji/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { Service } from '@etta/di'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'
import { airTimeRangeOptions } from '@fiji/hooks/time-configuration/use-flight/constants'
import type { FlightRecentSearchWithoutDates } from '@etta/modules/recent-searches/core/value-objects/get-flight-searches-without-dates.value-object'

type Segment = FlightRecentSearchWithoutDates['flightSearchParams']['segments'] extends
  | (infer U)[]
  | undefined
  ? U
  : never

type RecentSearchTimeRange = Segment['searchTimeRange']

type Location = Segment['origin']

const flightTypeMap = {
  [RecentSearchesFlightSearchParamsFlightType.OneWay]: FlightType.OneWay,
  [RecentSearchesFlightSearchParamsFlightType.RoundTrip]: FlightType.Round,
  [RecentSearchesFlightSearchParamsFlightType.MultiDestination]: FlightType.MultiCity,
}

const timeRangeMap = {
  [RecentSearchesSearchTimeRangeType.Departure]: TimeRangeDirection.Departure,
  [RecentSearchesSearchTimeRangeType.Arrival]: TimeRangeDirection.Arrival,
}

const cabinClassMap = {
  [RecentSearchesFlightSearchParamsCabinClass.Coach]: SeatmapCabinClass.Coach,
  [RecentSearchesFlightSearchParamsCabinClass.Business]: SeatmapCabinClass.Business,
  [RecentSearchesFlightSearchParamsCabinClass.Premium]: SeatmapCabinClass.PremiumCoach,
  [RecentSearchesFlightSearchParamsCabinClass.First]: SeatmapCabinClass.First,
}

@Service()
export class FlightRecentSearchWithoutDatesDataService {
  get(searchParams: FlightRecentSearchWithoutDates['flightSearchParams']) {
    return {
      queryParams: this.toSearchQuery(searchParams),
      originLocationName: this.getOriginLocationName(searchParams),
      destinationLocationName: this.getDestinationLocationName(searchParams),
      flightType: flightTypeMap[searchParams.flightType],
    }
  }

  private toSearchQuery(
    searchParams: FlightRecentSearchWithoutDates['flightSearchParams'],
  ): Flight[] {
    return searchParams.segments.map((segment, index) => {
      const { timeRange, returnTimeRange } = this.toSearchQueryTimeRange({ segment, searchParams })
      return {
        id: index,
        originPlace: this.getFlightSegmentLocation(segment.origin),
        destinationPlace: this.getFlightSegmentLocation(segment.destination),
        cabinClass: {
          id: cabinClassMap[segment.cabinClass],
        },
        timeRange,
        returnTimeRange,
      }
    })
  }

  private getFlightSegmentLocation(location: Location): Place {
    return {
      placeId: uuid(),
      name: location.name,
      latitude: location.geocode.lat,
      longitude: location.geocode.long,
      airportCode: location.airportCode || undefined,
      placeType: location.airportCode
        ? PlaceAutocompleteType.Airports
        : PlaceAutocompleteType.GooglePlaces,
      countryCode: location.countryCode || undefined,
    }
  }

  private getOriginLocationName = (
    searchParams: FlightRecentSearchWithoutDates['flightSearchParams'],
  ) => {
    return searchParams.segments[0].origin.airportCode || searchParams.segments[0].origin.name
  }

  private getDestinationLocationName = (
    searchParams: FlightRecentSearchWithoutDates['flightSearchParams'],
  ) => {
    if (searchParams.flightType === RecentSearchesFlightSearchParamsFlightType.MultiDestination) {
      const segmentsLenght = searchParams.segments.length
      const location = searchParams.segments[segmentsLenght - 1].destination
      return location.airportCode || location.name
    }

    const location = searchParams.segments[0].destination
    return location.airportCode || location.name
  }

  toSearchQueryTimeRange({
    segment,
    searchParams,
  }: {
    segment: Segment
    searchParams: FlightRecentSearchWithoutDates['flightSearchParams']
  }) {
    const defaultReturnTimeRange = {
      id: TimeRangeOption.AnyTime,
      i18next: 'FlightSearch.TimePicker.AnyTime',
    }

    if (searchParams.flightType === RecentSearchesFlightSearchParamsFlightType.RoundTrip) {
      const returnTimeRange =
        searchParams.segments[searchParams.segments.length - 1].searchTimeRange
      return {
        timeRange: this.formatTimeRange(segment.searchTimeRange),
        returnTimeRange: this.formatTimeRange(returnTimeRange),
      }
    }

    return {
      timeRange: this.formatTimeRange(segment.searchTimeRange),
      returnTimeRange: defaultReturnTimeRange,
    }
  }

  formatTimeRange(timeRange: RecentSearchTimeRange): TimeRange {
    const startPlainTime = this.toPlainTime(timeRange.start)
    const endPlainTime = this.toPlainTime(timeRange.end)
    const timeRangeBy = timeRangeMap[timeRange.type]

    const timeRangeResult: TimeRange = {
      startTimeRange: timeRange.start,
      endTimeRange: timeRange.end,
      startTimeHours: startPlainTime.hours,
      endTimeHours: endPlainTime.hours,
      timeRangeBy,
      id: TimeRangeOption.CustomTime,
      i18next: 'FlightSearch.TimePicker.CustomTime',
      customTimeHours: timeRange.customHours || undefined,
    }

    const timeRangeStart = timeRange.start
    const timeRangeEnd = timeRange.end

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.AnyTime].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.AnyTime].endTimeRange
    ) {
      return {
        ...airTimeRangeOptions[TimeRangeOption.AnyTime],
        timeRangeBy,
      }
    }

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Morning].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Morning].endTimeRange
    ) {
      return {
        ...airTimeRangeOptions[TimeRangeOption.Morning],
        timeRangeBy,
      }
    }

    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Afternoon].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Afternoon].endTimeRange
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Afternoon], timeRangeBy }
    }
    if (
      timeRangeStart === airTimeRangeOptions[TimeRangeOption.Evening].startTimeRange &&
      timeRangeEnd === airTimeRangeOptions[TimeRangeOption.Evening].endTimeRange
    ) {
      return { ...airTimeRangeOptions[TimeRangeOption.Evening], timeRangeBy }
    }

    return timeRangeResult
  }

  toPlainTime(time: string): PlainTime {
    const date = new Date('1970-01-01T' + time)

    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    }
  }
}
