import styled, { css } from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'
import { TextField as EttaTextField } from '@etta/ui/text-field'

export const Container = styled.div<{ isOutsideAlignRight?: boolean }>`
  position: relative;
  ${({ isOutsideAlignRight }) =>
    isOutsideAlignRight &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`

export const TextWrapper = styled.div<{ isOpen: boolean }>`
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 0;
      opacity: 0;
    `}

  ${getKeyboardNavigationStyle({ borderRadius: 10, offset: 0 })}
`

const ellipsisOverflow = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TextField = styled(EttaTextField)<{ withThinLabel?: boolean }>`
  pointer-events: none;
  label input {
    ${ellipsisOverflow}

    ${({ withThinLabel }) =>
      !withThinLabel &&
      css`
        font-weight: 700;
      `}

    ${({ isLoading, theme }) =>
      isLoading &&
      css`
        color: ${theme.colors.bodyText2};
      `}
  }
`
