import styled from 'styled-components'
import { captionStrongCaps, title2, buttonNormal, subHeadMedium, title3 } from '@fiji/style'
import type { ColorPalette } from '@fiji/types'
import { Text } from '@etta/ui/text'

export const Container = styled.div``

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
`

export const UpperBody = styled.div`
  padding: 0 72px;
  text-align: center;
`

export const TotalEmissionHeader = styled.p`
  margin: 0;
  margin-top: 24px;
  ${captionStrongCaps}
  color: ${(props) => props.theme.colors.bodyText}
`

export const SummaryKgContainer = styled.div`
  margin-top: 8px;
  padding: 8px 20px;
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  display: inline-block;
  ${title2};
`

export const TotalDeviationDescription = styled.p<{ color: keyof ColorPalette }>`
  margin: 0;
  color: ${(props) => props.theme.colors[props.color]};
  margin-top: -1px;
`

export const LowerBody = styled.div`
  padding: 0 72px;
`

export const SegmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  &:first-child {
    margin-top: 24px;
  }
`

export const SegmentLeftBlock = styled.div``

export const SegmentRightBlock = styled.div`
  display: flex;

  white-space: nowrap;
  ${title3}
`

export const SegmentRightBlockTitle = styled.p`
  margin: 0;
  ${title3}
  max-width: 216px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
`

export const TotalDeviationDescriptionContainer = styled.div`
  margin-top: 8px;
  ${buttonNormal};
`

export const SegmentDeviationDescriptionContainer = styled.div`
  margin-top: 6px;
  margin-right: 4px;
  ${subHeadMedium}
`

export const SegmentTitle = styled.p`
  margin: 24px 0 12px 0;

  ${captionStrongCaps}
  color: ${(props) => props.theme.colors.bodyText}
`

export const SegmentTypeContainer = styled.div<{ withBorderBottom?: boolean }>`
  border-bottom: ${(props) => (props.withBorderBottom ? '1px' : '0px')} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 24px;
`

export const FlightTitleAirport = styled.p`
  display: inline;
  margin: 0;
`

export const FlightTitleContainer = styled.div`
  white-space: nowrap;
`

export const SegmentRightTotalEmissions = styled.div`
  margin-left: 8px;
`

export const DisclaimerMessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 72px;
`

export const DisclaimerMessageText = styled(Text)`
  margin-left: 5px;
`
