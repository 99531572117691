import styled, { css } from 'styled-components'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'
import { footnoteMedium, headline } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const ProfileBoxContainer = styled.div<{ isError?: boolean }>`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding: 15px 16px 14px;
  margin-bottom: 40px;
  background: ${(p) => (p.isError ? hexToRgba(p.theme.colors.error, 0.04) : p.theme.colors.white)};
  border-radius: 14px;
  border: 2px solid ${(p) => (p.isError ? p.theme.colors.error : p.theme.colors.white)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
`

export const Initials = styled.div`
  ${headline};
  color: ${(p) => p.theme.colors.mainText2};
  background: ${(p) => p.theme.colors.bodyText1};
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  text-align: center;
  line-height: 4.2rem;
  margin: -1px 14px 0 -2px;
`

export const Name = styled.div`
  ${headline};
  padding-right: 26px;
  word-break: break-word;
`
export const Email = styled.div`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
  line-height: 1.9rem;
`

export const ErrorSection = styled.div`
  ${footnoteMedium};
  position: relative;
  line-height: 1.9rem;
  width: 100%;
  padding: 0 0 0px 23px;
  margin: 5px 0;
`

export const ErrorMessageSection = styled.div``

export const InlineLink = styled.div`
  padding: 2px 0 0 2px;
`

export const EditProfile = styled.div`
  position: absolute;
  top: 2px;
  right: -2px;
`

export const Personal = styled.div`
  position: relative;
  flex: 1;
`

export const ErrorIcon = styled(Icon)`
  position: absolute;
  left: -2px;
`

export const Container = styled.div``

export const UserInfoWrapper = styled.div<{ isError?: boolean }>`
  display: flex;
  align-items: center;
  padding: 15px 45px 32px 24px;
  ${({ isError, theme }) =>
    isError &&
    css`
      background-color: ${theme.colors.error1};
      border: 1px solid ${theme.colors.error};
      padding: 15px 24px;
      margin-bottom: 24px;
    `}
`

export const UserInfo = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`

export const UserAvatar = styled.div`
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.bodyText1};
  ${headline};
  color: ${(p) => p.theme.colors.mainText2};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
`

export const UserInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const UserDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`
