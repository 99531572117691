import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { ContentWrapper } from './held-trips-page-desktop-styled'

export function HeldTripsDesktopPage({ tripListSlot }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <ContentWrapper backgroundColor="background">{tripListSlot}</ContentWrapper>
    </ScreenDesktop>
  )
}
