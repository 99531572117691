import { ViewStateModal } from '@etta/ui/view-state-modal'
import { CreditCardModal } from '@etta/components/credit-card-modal/credit-card-modal'
import type { BillingParameter, CreditCard } from '@fiji/graphql/types'
import type { ChosenCardsMap } from '@etta/screens/payment-summary/types'
import { PurchasePaymentInformationFormLayout } from './layout'
import type { SubmitType } from './type'
import { usePurchasePaymentInformationForm } from './use-purchase-payment-information-form'

type Props = {
  onClose: () => void
  onSubmit: (data: SubmitType) => void
  billingParameter: BillingParameter
  chosenCards: ChosenCardsMap
  onAddSingleTimeUseCard: (card: Partial<CreditCard>) => void
  singleTimeUseCard?: Partial<CreditCard>
  isVirtualPayEnabled?: boolean
}

export function PurchasePaymentInformationContent({
  onClose,
  onSubmit,
  billingParameter,
  chosenCards,
  onAddSingleTimeUseCard,
  singleTimeUseCard,
  isVirtualPayEnabled,
}: Props) {
  const {
    creditCards,
    selectedCard,
    handleSelectCard,
    addCreditCardToggle,
    editCreditCardToggle,
    handleEditCreditCardClick,
    handleAddCreditCard,
    handleEditCreditCard,
    handleSubmit,
    editCreditCardInitialData,
    isSingleUseCreditCardAllowed,
    isBillingAddressRequired,
    viewState,
    isSearchResultEmpty,
    isFilterAvailable,
    filterValue,
    handleChangeFilterValue,
    isAddNewCardAvailable,
    isEditableCreditCardSingleUse,
    isHotelBooking,
  } = usePurchasePaymentInformationForm({
    billingParameter,
    onSubmit,
    chosenCards,
    onAddSingleTimeUseCard,
    singleTimeUseCard,
  })

  return (
    <>
      <PurchasePaymentInformationFormLayout
        isVirtualPayEnabled={isVirtualPayEnabled}
        creditCards={creditCards}
        filterValue={filterValue}
        selectedCard={selectedCard}
        isFilterAvailable={isFilterAvailable}
        isSearchResultEmpty={isSearchResultEmpty}
        isAddNewCardAvailable={isAddNewCardAvailable}
        isHotelBooking={isHotelBooking}
        handleChangeFilterValue={handleChangeFilterValue}
        handleOpenCreditCart={addCreditCardToggle.handleOpen}
        handleSelectCard={handleSelectCard}
        handleEditCreditCardClick={handleEditCreditCardClick}
        onClose={onClose}
        onSubmit={handleSubmit}
      />

      <CreditCardModal
        isOpen={addCreditCardToggle.isOpen}
        onClose={addCreditCardToggle.handleClose}
        onSubmit={handleAddCreditCard}
        isBillingAddressRequired={isBillingAddressRequired}
        isSingleUseCreditCardAllowed={isSingleUseCreditCardAllowed}
      />

      <CreditCardModal
        isOpen={editCreditCardToggle.isOpen}
        onClose={editCreditCardToggle.handleClose}
        onSubmit={handleEditCreditCard}
        isBillingAddressRequired={isBillingAddressRequired}
        isEditForm
        creditCardFormData={editCreditCardInitialData}
        isEditableCreditCardSingleUse={isEditableCreditCardSingleUse}
      />

      <ViewStateModal viewState={viewState.viewState} onDone={viewState.onClose} />
    </>
  )
}
