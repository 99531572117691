import { Store } from '@etta/di'
import type { FlightFareRulesByTransactionId } from '@etta/modules/rules-modals/core/flight-fare-rules-modal/value-objects/get-flight-fare-rules.value-object'

@Store()
export class FlightFareRulesStore {
  flightFareRules: FlightFareRulesByTransactionId[] = []
  isLoading = false
  isError = false

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setFlightFareRules(value: FlightFareRulesByTransactionId[]) {
    this.flightFareRules = value
  }
}
