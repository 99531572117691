import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace BookRideHailErrors {
  export const UnexpectedBookRideHailError = createErrorClass('UnexpectedBookRideHailError')

  export const BookRideHailError = createErrorClass('BookRideHailError')
}

export type BookRideHailErrorsIntances = InstanceType<
  typeof BookRideHailErrors[keyof typeof BookRideHailErrors]
>
