import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`

export const TextWrapper = styled.div`
  text-transform: uppercase;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
`
