/* eslint-disable react-hooks/exhaustive-deps */
import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { getHotelLogoURL } from '@fiji/utils/hotel-logo'
import { Swap } from '@etta/ui/swap'
import { Container, Image } from './image-container-components'

type Props = {
  url?: string | null
  chainCode?: string | null
  onErrorLoadImage?: (e: any) => void
  errorIconName?: IconNames
  errorSlot?: ReactNode
  isHotelInfoWindowMobile?: boolean
  isForceImagePortrait?: boolean
  'data-tracking-id'?: string
  alt?: string
  'aria-label'?: string
}

export function ImageContainer({
  url,
  onErrorLoadImage,
  chainCode,
  errorIconName = 'hotelPWA',
  errorSlot,
  isHotelInfoWindowMobile,
  isForceImagePortrait,
  'data-tracking-id': dataTrackingId,
  'aria-label': ariaLabel,
  alt,
}: Props) {
  const [imageURL, setImageURL] = useState(url)
  const [isImageVisible, setIsImageVisible] = useState(!!imageURL)
  const [isImagePortrait, setIsImagePortrait] = useState(false)
  const [isHotelLogoURL, setIsHotelLogoURL] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (url) {
      setImageURL(url)
      setIsImageVisible(true)
    } else {
      handleErrorLoadImage()
    }
  }, [url])

  function handleErrorLoadImage() {
    const hotelLogoURL = chainCode ? getHotelLogoURL(chainCode) : null
    if (!isHotelLogoURL && hotelLogoURL) {
      setImageURL(hotelLogoURL)
      setIsImagePortrait(true)
      setIsHotelLogoURL(true)
      setIsImageVisible(true)
    } else {
      setIsImageVisible(false)
      onErrorLoadImage && onErrorLoadImage(true)
    }
  }

  const onLoad = useCallback(() => {
    if (imageRef.current && imageRef.current.naturalHeight > imageRef.current.naturalWidth) {
      setIsImagePortrait(true)
    }
  }, [imageURL, imageRef, isImagePortrait])

  useEffect(() => {
    if (imageURL && /media_not_available/i.test(imageURL)) {
      setIsImageVisible(false)
    }
  }, [imageURL])

  return (
    <Container aria-label={ariaLabel} data-tracking-id={dataTrackingId}>
      <Swap
        is={!isImageVisible}
        isSlot={
          <Swap is={!!errorSlot} isSlot={errorSlot}>
            <Icon name={errorIconName} />
          </Swap>
        }>
        <Image
          isFullHeight={isHotelInfoWindowMobile}
          src={imageURL!}
          onError={handleErrorLoadImage}
          isImagePortrait={isForceImagePortrait || isImagePortrait}
          ref={imageRef}
          onLoad={onLoad}
          alt={alt}
        />
      </Swap>
    </Container>
  )
}
