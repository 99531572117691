import styled from 'styled-components'
import { Icon as EttaIcon } from '@etta/ui/icon'
import { getKeyboardNavigationStyle, KeyboardNavigationStyle } from '@fiji/style'

export const ListBodyItem = styled.div`
  height: 56px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  &:last-of-type {
    border-bottom: none;
  }
  & > * {
    width: 100%;
  }
`

export const OptionWithCheckboxIconWrapper = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const TickIcon = styled(EttaIcon)``

export const OptionTypeTextWrapper = styled.div`
  margin: 15px 0 10px 0;

  ${KeyboardNavigationStyle}
`
