import { Fragment } from 'react'
import { Skeleton } from '@etta/ui/skeleton'
import { ItineraryBlock } from '../../details-skeleton-styled'
import type { Props } from '../../types'
import { AddButtonBlockSkeleton } from './add-button-block-skeleton'

const itineraryItem = (
  <ItineraryBlock borderRadius={14}>
    <Skeleton width="100%" height="100px">
      <rect x="20" y="32" width="302" height="16" />
      <rect x="20" y="56" width="70" height="12" />
    </Skeleton>
  </ItineraryBlock>
)

const itineraryBlockSkeleton = [itineraryItem, itineraryItem, itineraryItem].map((item, index) => (
  <Fragment key={index}>{item}</Fragment>
))

export function DetailsSkeletonDesktop({ addButtonCount, isShown }: Props) {
  return (
    <>
      {!!addButtonCount && isShown && <AddButtonBlockSkeleton buttonCount={addButtonCount} />}
      <Skeleton width="100%" height="39px" variant="secondary1">
        <rect width="121px" height="20px" />
      </Skeleton>
      {itineraryBlockSkeleton}
    </>
  )
}
