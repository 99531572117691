import type {
  CheckoutInfoResult,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'
import type { BillingPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/billing-persist.value-object'
import type { PrimaryTravelerPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/primary-traveler-persist.value-object'
import type { MealPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/meal-persist.value-object'
import type { MemberShipPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/memberships-persist.value-object'
import type { SpecialRequestPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/special-request-persist.value-object'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import {
  isCarSegment,
  isFlightSegment,
  isHotelSegment,
  isRailSegment,
} from '@etta/modules/review-trip/core/guards/identify-exact-segment'
import type {
  CostAllocationSubmitValueObject,
  LuggageOptionValueObject,
  PaymentStructureValueObject,
} from '@etta/modules/booking/core/value-objects'
import { VIRTUAL_PAY } from '@fiji/constants'
import { mapLuggageOption } from '@fiji/utils/map-luggage-option'
import type { ShareTravelerContact } from '@etta/modules/travel-preferences/core/enums/share-traveler-contact.enum'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import type { Billing } from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import { CheckoutItineraryBillingMapper } from './mappers/checkout-itinerary-billing.mapper'
import { MembershipMapper } from './mappers/membership.mapper'
import { PrimaryTravelerMapper } from './mappers/primary-traveler.mapper'
import { SpecialRequestMapper } from './mappers/special-request.mapper'
import { ShareTravelerContactMapper } from './mappers/share-traveler-contact.mapper'

type Args = {
  itineraryId: string
  primaryTraveler: PrimaryTravelerPersistValueObject
  segments: TripSegmentValueObject[]
  bookingId?: string | null
  putOnHold?: boolean
  billing?: BillingPersistValueObject
  hotelBillingFromCheckoutInfo?: Billing['hotel']
  meal?: MealPersistValueObject
  memberships?: MemberShipPersistValueObject
  specialRequests?: SpecialRequestPersistValueObject
  travelerFields?: CheckoutInfoResult['primaryTraveler']['travelerFields']
  costAllocation: CostAllocationSubmitValueObject[]
  outOfPolicyExplanation: CheckoutItineraryInputValueObject['outOfPolicyExplanation']
  customFields?: CustomFieldBookingValueObject[] | null
  payment?: PaymentStructureValueObject
  departureLuggageOption?: LuggageOptionValueObject | null
  returnLuggageOption?: LuggageOptionValueObject | null
  shareTravelerContact?: ShareTravelerContact | null
}

export class CheckoutItineraryInputMapper {
  static toInput({
    itineraryId,
    bookingId,
    putOnHold,
    billing,
    hotelBillingFromCheckoutInfo,
    primaryTraveler,
    meal,
    memberships,
    specialRequests,
    travelerFields,
    segments,
    costAllocation,
    outOfPolicyExplanation,
    customFields,
    departureLuggageOption,
    returnLuggageOption,
    payment,
    shareTravelerContact,
  }: Args): CheckoutItineraryInputValueObject {
    const requestArgs: CheckoutItineraryInputValueObject = {
      itineraryId: itineraryId,
      primaryTraveler: {
        data: PrimaryTravelerMapper.toPrimaryTravelerInput(primaryTraveler),
        travelerFields: travelerFields,
      },
      segments: this.toSegmentsInput(segments),
      billing: CheckoutItineraryBillingMapper.toBillingInput(billing, payment),
      specialRequests: SpecialRequestMapper.toSpecialRequestInput(specialRequests),
      mealRequest: meal?.air?.id,
      memberships: MembershipMapper.toMembershipInput(memberships),
      costAllocation: costAllocation,
      outOfPolicyExplanation,
      bookingId,
      customFields,
      isOnHold: putOnHold,
      isVirtualPaySelected:
        !!hotelBillingFromCheckoutInfo && billing?.hotel?.creditCard?.id === VIRTUAL_PAY,
      departureLuggageOption: mapLuggageOption(departureLuggageOption),
      returnLuggageOption: mapLuggageOption(returnLuggageOption),
      shareTravelerContact: ShareTravelerContactMapper.toShareTravelerContactInput(
        shareTravelerContact,
      ),
    }

    return requestArgs
  }

  private static toSegmentsInput(
    segments: TripSegmentValueObject[],
  ): CheckoutItineraryInputValueObject['segments'] {
    return segments.reduce(
      (acc: CheckoutItineraryInputValueObject['segments'], segment) => {
        if (isFlightSegment(segment)) {
          acc.air = { id: segment.legId }
          return acc
        }
        if (isHotelSegment(segment)) {
          acc.hotel?.push({ id: segment.id })
          return acc
        }
        if (isCarSegment(segment)) {
          acc.carRental?.push({ id: segment.carId })
          return acc
        }
        if (isRailSegment(segment)) {
          acc.rail = { id: segment.legId }
          return acc
        }
        return acc
      },
      {
        carRental: [],
        hotel: [],
      },
    )
  }
}
