import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Text } from '@etta/ui/text'
import { SegmentType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { FlightNameWrapper } from './trip-segment-styled'

type Props = {
  name: string | string[]
  segmentType?: SegmentType
  isRoundTrip?: boolean | null
}

export function TripSegmentName({ name, segmentType, isRoundTrip }: Props) {
  const { t } = useTranslation()

  const tripName = useMemo(() => {
    if (!Array.isArray(name)) {
      return name
    }

    const [translation, ...rest] = name
    return [t(translation), ...rest].join(' ')
  }, [name, t])

  const [tripDepartureAirport, tripArrivalAirport] = useMemo(() => tripName.split(' - '), [
    tripName,
  ])

  if (segmentType === SegmentType.Flight) {
    return (
      <FlightNameWrapper>
        <Text variant="subHeadStrong" isBlock data-tracking-id="cancel-trip-city-pairs-text">
          {tripDepartureAirport}
        </Text>
        <Icon name={isRoundTrip ? 'returnFlightArrowPWA' : 'flightArrowPWA'} />
        <Text variant="subHeadStrong" isBlock data-tracking-id="cancel-trip-city-pairs-text">
          {tripArrivalAirport}
        </Text>
      </FlightNameWrapper>
    )
  }

  return (
    <Text variant="subHeadStrong" isBlock data-tracking-id="cancel-trip-city-pairs-text">
      {tripName}
    </Text>
  )
}
