import { Header } from '@etta/ui/header'
import { SeatMap } from '../../seat-map'
import { SeatFooter } from '../../seat-footer'
import { Legend } from '../../legend'
import { SegmentTabs } from '../../segment-tabs'
import type { LayoutProps } from '../../air-seat-map.types'
import { Container, Footer, ModalHeader, Body } from './air-seat-map-mobile-styled'

export function AirSeatMapMobile({
  cabinClass,
  skin,
  onClose,
  segmentsSeatRows,
  onSelectedSegmentIdChange,
  subTitle = '',
  isLoading,
  headerTitle,
  headerButtonTitle,
  onHeaderButtonClick,
  onSeatChecked,
  onSeatApprove,
  seatsForApprove,
  seatRowsBySegment,
  usedSegmentId,
  seatNumbersBySegmentId,
  isSeatSelectionAvailable,
  onNextSegment,
  isSeatAssignLoading,
  isSeatAssignFailed,
  onSeatAssignFailedContinue,
  footerHeight,
  onChangeFooterHeight,
  isSeatMapAvailable,
}: LayoutProps) {
  return (
    <Container>
      <ModalHeader>
        {skin !== 'no-mobile-header' && (
          <Header
            layout="seat-map"
            leftSlot={<Header.CloseButton onClick={onClose} />}
            rightSlot={
              <Header.TextButton onClick={onHeaderButtonClick}>
                {headerButtonTitle}
              </Header.TextButton>
            }
            withBorderBottom>
            <Header.Title layout="seat-map" title={headerTitle} subTitle={subTitle} align="left" />
          </Header>
        )}
        {segmentsSeatRows.length > 1 && (
          <SegmentTabs
            selectedSegmentId={usedSegmentId}
            segmentSeatRows={segmentsSeatRows}
            onChange={onSelectedSegmentIdChange}
          />
        )}
      </ModalHeader>
      <Body>
        <SeatMap
          usedCabinClass={cabinClass}
          seatRows={seatRowsBySegment}
          seatsNumberList={seatNumbersBySegmentId}
          onSeatChecked={onSeatChecked}
          onUnavailableSeatMapContinue={onNextSegment}
          isLoading={isLoading}
          selectedSegmentId={usedSegmentId}
          footerHeight={footerHeight}
          isReadonly={false}
          isSeatMapAvailable={isSeatMapAvailable}
        />
      </Body>
      <Footer>
        <SeatFooter
          seatsForApprove={seatsForApprove}
          onSelect={onSeatApprove}
          isSeatSelectionAvailable={isSeatSelectionAvailable}
          isLoading={isSeatAssignLoading}
          isSeatAssignFailed={isSeatAssignFailed}
          onSeatAssignFailedContinue={onSeatAssignFailedContinue}
          selectedSegmentId={usedSegmentId}
          onSeatSelectionUnavailableContinue={onNextSegment}
          onChangeFooterHeight={onChangeFooterHeight}
        />
        {isSeatMapAvailable && <Legend />}
      </Footer>
    </Container>
  )
}
