import type { FieldError, ErrorType, FieldType } from '@fiji/graphql/types'
import { ErrorTypeEnum, FieldTypeEnum } from '../../../core/enums'
import type { FieldErrorValueObject } from '../../../core/value-objects/field-error.value-object'

export class PassportMapper {
  static toErrorTypeEnum(errorType: ErrorType): ErrorTypeEnum {
    return ErrorTypeEnum[errorType]
  }

  static toFieldTypeEnum(type: FieldType): FieldTypeEnum {
    return FieldTypeEnum[type]
  }

  static toFieldErrorValueObject(fieldError: FieldError): FieldErrorValueObject {
    return {
      message: fieldError.message,
      errorType: PassportMapper.toErrorTypeEnum(fieldError.errorType),
      type: PassportMapper.toFieldTypeEnum(fieldError.type),
    }
  }
}
