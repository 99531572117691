import { useMemo } from 'react'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { useBrandChange } from './use-brand-change'

type Args = {
  visibleRows?: number
  superBrands: SuperBrandFilter[]
  brands: BrandFilter[]
  selectedIdsMap: Set<string>
  onSelect: (idsMap: Set<string>) => void
}

export function useBrandsMultiSelect({
  visibleRows,
  brands,
  onSelect,
  selectedIdsMap,
  superBrands,
}: Args) {
  const onBrandChange = useBrandChange({ selectedIdsMap, onSelect })

  const superBrandsView = useMemo(() => {
    if (visibleRows === undefined) {
      return superBrands
    }
    if (visibleRows === 0) {
      return []
    }
    return superBrands.slice(0, visibleRows)
  }, [visibleRows, superBrands])

  const brandsView = useMemo(() => {
    if (visibleRows === undefined) {
      return brands
    }

    const remainsRows = visibleRows - superBrands.length
    if (remainsRows <= 0) {
      return null
    }
    return brands.splice(0, remainsRows)
  }, [visibleRows, brands, superBrands])

  const isEmpty = !superBrandsView?.length && !brandsView?.length
  return {
    isEmpty,
    onBrandChange,
    brandsView,
    superBrandsView,
  }
}
