import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { TripStatus } from '@fiji/graphql/types'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'

type Params = {
  onRetry: () => void
  isLoading: boolean
  trip: TripEntity
}

export function useDetailsLayoutMobile({ onRetry, trip, isLoading }: Params) {
  const { handleRefetchRequest } = useRefetchRequest({
    refetch: onRetry,
    redirectRouteOnFail: '/trips',
    loading: isLoading,
    totalAttempts: 3,
  })

  const {
    isOpen: isCostSummaryModalOpen,
    handleOpen: handleCostSummaryModalOpen,
    handleClose: handleCostSummaryModalClose,
  } = useTogglePopup()
  const isUpcoming =
    trip?.type !== TripStatus.AutoCancelled &&
    trip?.type !== TripStatus.Completed &&
    trip?.type !== TripStatus.Cancelled
  const isOnHold = trip?.type === TripStatus.OnHold

  return {
    handleRefetchRequest,
    isCostSummaryModalOpen,
    handleCostSummaryModalOpen,
    handleCostSummaryModalClose,
    isUpcoming,
    isOnHold,
  }
}
