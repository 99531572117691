import i18n from 'i18next'
import { SegmentType } from '@etta/core/enums'
import { Store, Inject } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { ViewState } from '@etta/ui/view-state-modal'
import type {
  CancelTripDataValueObject,
  CarRentalRulesPropsValueObject,
  FlightFareRulesPropsValueObject,
  GroupedSegmentValueObject,
  HotelRulesPropsValueObject,
} from '../../../core/value-objects'
import { PostBookingTripStore } from '../trip/post-booking-trip.store'
import { getCancelTripData } from './get-cancel-trip-data'

@Store()
export class PostBookingCancellationStore {
  isSuccess = false

  isRideHailError = false

  viewState: ViewState = 'hidden'

  cancellationSegment: GroupedSegmentValueObject | null = null

  flightFareRulesProps: Record<string, FlightFareRulesPropsValueObject> = {}
  carRentalRulesProps: Record<string, CarRentalRulesPropsValueObject> = {}
  hotelRulesProps: Record<string, HotelRulesPropsValueObject> = {}

  cancelTripToggle = new Toggle()
  canceledTripSegmentToggle = new Toggle()
  cancelFlightsWarningToggle = new Toggle()

  constructor(@Inject() private postBookingTripStore: PostBookingTripStore) {}

  get cancelTripData(): CancelTripDataValueObject[] {
    return getCancelTripData({
      costSummary: this.postBookingTripStore.trip.costSummary,
      segments: this.postBookingTripStore.trip.segments,
    })
  }

  get cancellationTypeTitle(): string {
    switch (this.cancellationSegment?.type) {
      case SegmentType.Flight:
        return i18n.t('PostBooking.FlightDetails.CancelType')
      case SegmentType.CarRental:
        return i18n.t('TripReview.CarRentalSegment.CancelType')
      case SegmentType.CarService:
        return i18n.t('TripReview.CarServiceSegment.CancelType')
      case SegmentType.Hotel:
        return i18n.t('PostBooking.HotelDetails.CancelType')
      case SegmentType.Train:
        return i18n.t('PostBooking.TrainDetails.CancelType')
      case SegmentType.RideHail:
        return i18n.t('PostBooking.RideHailDetails.CancelType')
      default:
        return i18n.t(`PostBooking.TripDetails.CancelTripModal.Trip`)
    }
  }

  get isCancelAllowed() {
    const supportedActions = this.postBookingTripStore.trip.supportedActions

    switch (this.cancellationSegment?.type) {
      case SegmentType.CarRental:
        return supportedActions.isCarRentalCancelAllowed
      case SegmentType.Flight:
        return supportedActions.isFlightCancelAllowed
      case SegmentType.Hotel:
        return supportedActions.isHotelCancelAllowed
      case SegmentType.Train:
        return supportedActions.isTrainCancelAllowed
      case SegmentType.RideHail:
        return true
      default:
        return supportedActions.isCancelTripAllowed
    }
  }

  get cancellationDateRange() {
    const cancellationSegment = this.cancellationSegment
    switch (cancellationSegment?.type) {
      case SegmentType.CarRental:
        return { start: cancellationSegment.pickUpTime, end: cancellationSegment.dropOffTime }

      case SegmentType.Flight:
        const flights = this.postBookingTripStore.flightSegments
        if (flights.length !== 2) {
          return {
            start: cancellationSegment.departureDateTime,
            end: cancellationSegment.arrivalDateTime,
          }
        }

        return {
          start: flights[0].departureDateTime,
          end: flights[1].arrivalDateTime,
        }

      case SegmentType.Hotel:
        return {
          start: cancellationSegment.checkIn,
          end: cancellationSegment.checkOut,
        }

      case SegmentType.Train:
        return {
          start: cancellationSegment.departureDateTime,
          end: cancellationSegment.arrivalDateTime,
        }

      case SegmentType.RideHail:
        return { start: cancellationSegment.pickUpTime, end: cancellationSegment.pickUpTime }

      default: {
        return {
          start: this.postBookingTripStore.trip.rollUpStartTime,
          end: this.postBookingTripStore.trip.rollUpEndTime,
        }
      }
    }
  }

  get cancellationSegments() {
    const cancellationSegment = this.cancellationSegment
    switch (cancellationSegment?.type) {
      case SegmentType.CarRental:
        return this.cancelTripData.filter(
          ({ type, key }) => type === SegmentType.CarRental && key === cancellationSegment.carId,
        )
      case SegmentType.Flight:
        return this.cancelTripData.filter(({ type }) => type === SegmentType.Flight)
      case SegmentType.Hotel:
        return this.cancelTripData.filter(
          ({ type, key }) => type === SegmentType.Hotel && key === cancellationSegment.id,
        )
      case SegmentType.Train:
        return this.cancelTripData.filter(({ type }) => type === SegmentType.Train)
      case SegmentType.RideHail:
        return this.cancelTripData.filter(
          ({ type, id }) =>
            type === SegmentType.RideHail && id === Number(cancellationSegment.bookingId),
        )
      default:
        return this.cancelTripData
    }
  }

  get isCanOpenHotelRulesModal(): boolean {
    const { dailyRates, totalCost, id, averageRate, feesAndTaxes, roomType } = this.hotelRulesProps

    if (!dailyRates || !totalCost || !id || !roomType || !averageRate || !feesAndTaxes) {
      return false
    }

    return true
  }

  setViewState(viewState: ViewState) {
    this.viewState = viewState
  }

  setIsRideHailError(isRideHailError: boolean) {
    this.isRideHailError = isRideHailError
  }

  setIsSuccess(isSuccess: boolean) {
    this.isSuccess = isSuccess
  }

  setCancellationSegment(cancellationSegment: GroupedSegmentValueObject | null) {
    this.cancellationSegment = cancellationSegment
  }

  setFlightFareRulesProps(value: FlightFareRulesPropsValueObject) {
    this.flightFareRulesProps[value.flightSegmentId] = value
  }

  setCarRentalRulesProps(value: CarRentalRulesPropsValueObject) {
    this.carRentalRulesProps[value.carId] = value
  }

  setHotelRulesProps(value: HotelRulesPropsValueObject) {
    this.hotelRulesProps[value.hotelId] = value
  }

  dropCancellationStore() {
    this.cancelTripToggle = new Toggle()
    this.cancelFlightsWarningToggle = new Toggle()
    this.canceledTripSegmentToggle = new Toggle()
    this.cancellationSegment = null
    this.isRideHailError = false
    this.isSuccess = false
    this.viewState = 'hidden'
  }
}
