import i18next from 'i18next'
import type { Rate } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { isSameDay } from '@fiji/utils/dates/is-same-day'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental/translate-car-name-details'
import type {
  HotelSegmentEntity,
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  TrainSegmentEntity,
} from '@etta/modules/review-trip/core'

export function getCarItems(cars: CarRentalSegmentEntity[], locale: string) {
  return cars.map((carRental) => {
    const { carRentalTitle } = translateCarNameDetails({
      carClass: carRental?.carDetails.class,
      carType: carRental?.carDetails.type,
    })
    const infoLabel = getCarInfoLabel(carRental)
    const price = getPriceLabel(carRental?.totalCostRate, locale)

    return {
      type: SegmentType.CarRental,
      titleLabel: carRentalTitle,
      infoLabel,
      price: price,
    }
  })
}

export function getHotelItems(hotels: HotelSegmentEntity[], locale: string) {
  return hotels.map((hotel) => ({
    type: SegmentType.Hotel,
    titleLabel: hotel!.name,
    infoLabel: getHotelInfoLabel(hotel),
    price: getPriceLabel(hotel?.totalCostRate, locale),
  }))
}

export function getFlightItem(flightLeg: FlightSegmentEntity, locale: string) {
  const infoLabel = getFlightInfoLabel(flightLeg)
  const price = getPriceLabel(flightLeg.totalCostRate, locale)
  const flightFirstSegment = flightLeg.segments[0]
  const flightLastSegment =
    flightLeg.segments.length > 1 && flightLeg.segments[flightLeg.segments.length - 1]
  const departureCode = flightFirstSegment.departure.airportCode || ''
  const destinationCode = flightLastSegment
    ? flightLastSegment.arrival.airportCode!
    : flightFirstSegment.arrival.airportCode!

  return {
    type: SegmentType.Flight,
    infoLabel,
    price,
    departureCode,
    destinationCode,
  }
}

export function getTrainItem(
  trainLeg: TrainSegmentEntity,
  locale: string,
  startDate: string,
  endDate: string,
) {
  const infoLabel = getTrainInfoLabel(trainLeg, startDate, endDate)
  const price = getPriceLabel(trainLeg?.rate, locale)
  const trainFirstSegment = trainLeg.segments[0]
  const trainLastSegment = trainLeg.segments[trainLeg.segments.length - 1]
  const departureCode = trainFirstSegment.departure.stationName || ''
  const destinationCode =
    (trainLastSegment
      ? trainLastSegment.arrival.stationName
      : trainFirstSegment.arrival.stationName) || ''

  return {
    type: SegmentType.Train,
    infoLabel,
    price,
    departureCode,
    destinationCode,
  }
}

function getDateLabel(dateStart: string, dateEnd: string) {
  const startDate = new Date(deleteTimezone(dateStart))
  const endDate = new Date(deleteTimezone(dateEnd))
  const startMonth = dateFormat(startDate, 'MMM')
  const endMonth = dateFormat(endDate, 'MMM')
  let dateLabel = ''

  if (isSameDay(startDate, endDate)) {
    dateLabel = `${endMonth} ${dateFormat(startDate, 'dd')}`
  } else if (startMonth === endMonth) {
    dateLabel = `${endMonth} ${dateFormat(startDate, 'dd')} - ${dateFormat(endDate, 'dd')}`
  } else {
    dateLabel = `${dateFormat(startDate, 'MMM dd')} - ${dateFormat(endDate, 'MMM dd')}`
  }

  return dateLabel
}

function getFlightInfoLabel(flight: FlightSegmentEntity) {
  const dateLabel = getDateLabel(flight.departureDateTime, flight.arrivalDateTime)
  const flightSegments = flight?.segments || []
  const segment = flightSegments[0]
  let carrierLabel = ''
  if (segment) {
    carrierLabel = `${segment.carrierCode}-${segment.flightNumber}`
  }

  return `${dateLabel} ${carrierLabel ? ` • ${carrierLabel}` : ''}`
}

function getTrainInfoLabel(train: TrainSegmentEntity, startDate: string, endDate: string) {
  const trainSegments = train?.segments || []
  const dateLabel = getDateLabel(startDate, endDate)
  const carrierLabel =
    trainSegments.length > 1
      ? i18next.t('TripPlanner.TripCart.MultiCarriers')
      : `${trainSegments[trainSegments.length - 1].carrierName}`

  return `${dateLabel} ${carrierLabel ? ` • ${carrierLabel}` : ''}`
}

function getCarInfoLabel(car?: CarRentalSegmentEntity | null) {
  let dateLabel = ''
  if (car) {
    dateLabel = getDateLabel(car.pickUpTime, car.dropOffTime)
  }

  return `${dateLabel} • ${car?.vendorDetails.name}`
}

function getHotelInfoLabel(hotel: HotelSegmentEntity) {
  const datesLabel = getDateLabel(hotel.checkIn, hotel.checkOut)
  const roomLabel = i18next.t('TripReview.HotelSegment.Room')

  return `${datesLabel} • ${hotel.roomType} ${roomLabel}`
}

function getPriceLabel(rate?: Rate | null, locale?: string) {
  let price = ''
  if (rate?.secondary || rate?.primary) {
    price = formatMoney(rate.secondary || rate.primary, { locale, hideDecimal: true })
  }

  return price
}
