import { Action, Inject } from '@etta/di'
import { CostAllocationStore } from '../stores/cost-allocation.store'
import { CostAllocationAdapter } from '../../infra/cost-allocation.adapter'

@Action()
export class CostAllocationActions {
  constructor(
    @Inject() private readonly costAllocationStore: CostAllocationStore,
    @Inject() private readonly costAllocationAdapter: CostAllocationAdapter,
  ) {}

  setActiveCostAllocationDisplayName(segmentId: number, val: string) {
    this.costAllocationStore.setActiveCostAllocationDisplayName(segmentId, val)
  }

  setActiveSplitCostAllocationDisplayName(segmentId: number, val: string) {
    this.costAllocationStore.setActiveSplitCostAllocationDisplayName(segmentId, val)
  }

  async getTopLevelCostSegments() {
    this.costAllocationStore.setIsTopLevelCostSegmentLoadError(false)
    this.costAllocationStore.setIsTopLevelCostSegmentLoading(true)

    const result = await this.costAllocationAdapter.getCostSegments()

    this.costAllocationStore.setIsTopLevelCostSegmentLoading(false)

    if (result.isErr()) {
      this.costAllocationStore.setIsTopLevelCostSegmentLoadError(true)
      return
    }

    this.costAllocationStore.setTopLevelCostSegments(result.getValue())
  }

  async getCostAllocations(id: number, query?: string, url?: string, pageSize?: number) {
    this.costAllocationStore.setIsCostAllocationLoadError(false)
    this.costAllocationStore.setIsCostAllocationLoading(true)

    const result = await this.costAllocationAdapter.getCostAllocations(id, query, url, pageSize)

    this.costAllocationStore.setIsCostAllocationLoading(false)

    if (result.isErr()) {
      this.costAllocationStore.setIsCostAllocationLoadError(true)
      return
    }

    const data = result.getValue()
    if (data) {
      const allocationsId = `${id}_${query}_${url}`
      this.costAllocationStore.setCostAllocation(allocationsId, data)
    }
  }

  resetCostAllocations() {
    this.costAllocationStore.resetCostAllocations()
  }
}
