import { useCallback } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { SegmentDateTimeModal } from './types'

export const useSegmentDateTimeModals = () => {
  const datePickerModal = useTogglePopup()
  const timePickerModal = useTogglePopup()

  const isAnyModalVisible = [datePickerModal, timePickerModal].find(({ isOpen }) => isOpen)

  const handleModalOpen = useCallback(
    (modal: SegmentDateTimeModal) => {
      if (isAnyModalVisible) {
        isAnyModalVisible.handleClose()
      }

      modal.handleOpen()
    },
    [isAnyModalVisible],
  )

  return {
    datePickerModal,
    timePickerModal,
    handleModalOpen,
  }
}
