import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Text } from '@etta/ui/text'
import { useExplore } from '@fiji/hooks/navigation/use-explore'
import { useUserContext } from '@etta/modules/user'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import { screenMatcher, ScreenType } from '@fiji/modes'
import {
  BackButton,
  Container,
  Wrapper,
  TitleContainer,
  NavigationLink,
  TopWrapper,
  GuestUserNudge,
} from './purchase-header-styled'
import { PurchaseHeaderLogo } from './purchase-header-logo'

type Props = {
  onBack: () => void
  isHoldFlow?: boolean
}

const i18nBase = 'TravelerInfo'

export function PurchaseHeader({ onBack, isHoldFlow }: Props) {
  const { exploreLink } = useExplore()
  const { userStore } = useUserContext()
  const { delegateStore } = useDelegateContext()
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const shouldShowGuestUserNudge =
    !isMobile && userStore.isGuest && !userStore.isUserLoading && !delegateStore.isDelegateGuestUser
  const { t } = useTranslation()

  const logoLinkText = t(`${i18nBase}.LogoLinkText`)
  const title = useMemo(() => {
    if (isHoldFlow) {
      return t('HoldTrip.HoldTrip')
    }
    return t(`${i18nBase}.Header`)
  }, [t, isHoldFlow])

  return (
    <Container>
      <TopWrapper>
        {shouldShowGuestUserNudge && (
          <GuestUserNudge>
            <Text variant="footnoteMedium" color="mainText2">
              {t(`${i18nBase}.GuestBooking`)}
            </Text>
          </GuestUserNudge>
        )}
      </TopWrapper>
      <Wrapper>
        <TitleContainer>
          <BackButton
            onClick={onBack}
            icon="arrowBackLargePWA"
            size="medium"
            color="mainText"
            aria-label={t('Accessibility.Header.Back')}
          />
          <Text aria-label={title} variant="title1">
            {title}
          </Text>
        </TitleContainer>

        <NavigationLink to={exploreLink} link-name={logoLinkText} aria-label={logoLinkText}>
          <PurchaseHeaderLogo />
        </NavigationLink>
      </Wrapper>
    </Container>
  )
}
