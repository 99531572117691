import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { extractFromSegments } from '@fiji/utils/trip-review/extract-from-segments'
import { SegmentType } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { TripHeader } from '@etta/components/trip-header/trip-header'
import { TripBackground } from '@etta/components/trip-background/trip-background'
import { OutOfPolicyModal } from '@etta/components/out-of-policy-modal/out-of-policy-modal'
import { RTPPriceSummary } from '@etta/components/rtp-price-summary/rtp-price-summary'
import { EditTripNameDialog } from '@etta/components/edit-trip-name-dialog/edit-trip-name-dialog'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { Swap } from '@etta/ui/swap'
import { AirSearchFormModalLayout } from '@etta/screens/review-trip-page/air-search-form-modal'
import { HotelSearchFormModalLayout } from '@etta/screens/review-trip-page/hotel-search-form-modal'
import { CarSearchFormModalLayout } from '@etta/screens/review-trip-page/car-search-form-modal'
import { AppleBanner } from '@etta/components/apple-banner'
import { RtpBookTripAgainFailState } from '@etta/components/rtp-book-trip-again-fail-state'
import { AlignDatesAlert, AlignDatesModal, AlignDatesWarning } from '@etta/components/align-dates'
import { MultipleHotelProvidersDialog } from '@etta/modules/multiple-hotel-providers/ui/multiple-hotel-providers-dialog'
import { AirSearchFormModalLayout as AirChangeFormModalLayout } from '@etta/modules/review-trip/ui/air-search-form-modal'
import { RulesAndRestrictionsModal } from '@etta/components/rules-and-restrictions'
import { MeetingInformationCard } from '@etta/modules/meeting/ui/meeting-information/components/meeting-information-card'
import { useMeetingModeContext } from '@etta/modules/meeting/use-meeting-mode.context'
import { TripContent } from './trip-content'
import { TripReviewAddButtonsRow } from './trip-review-add-buttons-row'
import { TripCostSummary } from './trip-cost-summary'
import { FooterCheckout } from './footer-checkout'
import { EmptyTrips } from './empty-trips'
import { TripReviewDynamicSiteMessages } from './trip-review-dynamic-site-messages'
import { ReviewTripPageLayout } from './layout'
import { PriceChangeDialog } from './price-change-dialog'
import { DuplicateTripsModal } from './duplicate-trips-modal'
import { DuplicatedList } from './duplicated-list'
import { useReviewTripPageContextSelector } from './review-trip-page-provider'
import { SeatMapRTPModal } from './seat-map-rtp-modal'

export const ReviewTripPageContent = observer(function ReviewTripPageContent() {
  const { anchorRef, headerRef, scrollContainerRef, offsetRef } = useHeaderScroll({
    scrollAfterValue: 0.35,
    scrollAfterOffset: 6,
  })

  const i18Base = 'ReviewTrip'
  const { t } = useTranslation()

  const {
    areTooManySegments,
    isAbleToCheckout,
    messages,
    overlappingTrips,
    firstHotel,
    oopSelections,
    tripCostSummaryToggle,
    addButtonSettings,
    segments,
    tripCost,
    totalCost,
    tripData,
    isTripExist,
    onCheckout,
    isLoading,
    descriptions,
    travelPolicy,
    isCheckoutActivated,
    isPolicyExplanationRequired,
    outOfPolicyToggle,
    itineraryId,
    flightId,
    flightSegments,
    onAbandonTripChanges,
    tooManySegmentsToggle,
    totalPriceChange,
    isTotalPriceChangePositive,
    postPurchaseActions,
    airPriceChangeToggle,
    airPriceChangeInfo,
    flightCost,
    isMultiCity,
    errorsTripToggle,
    handleDuplicatedTripClick,
    startOverModalSlot,
    isShowDelegateName,
    currentDelegatedUser,
    airPriceChangeInfoState,
    handleAirPriceChangeClose,
    bookingId,
    tripEmissionsResult,
    editNameToggle,
    handleUpdateTripName,
    areTripEmissionLoading,
    handleChangeFlights,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
    tripExpiredModalSlot,
    isHoldTripAllowed,
    onProceedToCheckout,
    onHold,
    isAppleBannerShown,
    rtpSeatMapModalToggle,
    tripDatesAlignment,
    segmentsToDisplay,
    isMobile,
    isBookTripAgainFailed,
    multipleHotelProvidersRTPDialog,
    handleClickMultipleHotelProviders,
    rulesAndRestrictionsToggle,
    itineraryRules,
    showRulesAndRestrictions,
  } = useReviewTripPageContextSelector((state) => state)
  const { meetingModeStore } = useMeetingModeContext()

  if (isBookTripAgainFailed && segments.length === 0) {
    if (!isLoading) {
      return <RtpBookTripAgainFailState />
    }
  }

  return (
    <>
      <ReviewTripPageLayout
        isLoading={isLoading}
        scrollContainerRef={scrollContainerRef}
        mobileHeaderSlot={
          <Header
            headerRef={headerRef}
            layout="seat-map"
            animation="slide-top"
            leftSlot={<Header.CloseButton onClick={onAbandonTripChanges} />}
            rightSlot={
              tripData.isNameEditable && (
                <IconButton
                  onClick={editNameToggle.handleOpen}
                  icon="editPWA"
                  size="medium"
                  aria-label={t('Accessibility.SearchBar')}
                />
              )
            }
            withBoxShadow>
            <Header.Title
              aria-label={`${tripData.name}, ${tripData.titleDateRange}`}
              layout="seat-map"
              align="left"
              title={tripData.name}
              subTitle={tripData.titleDateRange}
            />
          </Header>
        }
        desktopPriceSummarySlot={
          <>
            {!!tripCost && (
              <RTPPriceSummary
                cityImageUrl={tripData.imageUrl}
                onEdit={editNameToggle.handleOpen}
                isEditable={tripData.isNameEditable}
                isDisabled={!isAbleToCheckout}
                onOpenTripCostSummary={tripCostSummaryToggle.handleOpen}
                onCheckout={onCheckout}
                onHold={onHold}
                isHoldTripAllowed={isHoldTripAllowed}
                tripCost={tripCost}
                totalCost={totalCost!}
                tripDates={tripData.titleDateRange}
                tripName={tripData.name}
                warningSlot={
                  tripDatesAlignment.hasMismatchedSegments ? (
                    <AlignDatesWarning
                      flightStartDateTime={tripDatesAlignment.flightStartDateTime}
                      lastFlightStartDateTime={tripDatesAlignment.lastFlightStartDateTime}
                      onAlignDates={tripDatesAlignment.handleOpenAlignDatesModal}
                      mismatchedSegmentTypes={tripDatesAlignment.mismatchedSegmentTypes}
                    />
                  ) : null
                }
                warningMessages={messages.complianceMessages?.warns || []}
                onOpenRulesAndRestrictions={rulesAndRestrictionsToggle.handleOpen}
                showRulesAndRestrictions={showRulesAndRestrictions}
              />
            )}
            {isAppleBannerShown && (
              <Block marginTop={32}>
                <AppleBanner orientation="vertical" />
              </Block>
            )}
          </>
        }
        mobileTopContentSlot={
          <>
            <TripBackground url={tripData.imageUrl} onClose={onAbandonTripChanges} withIcon />

            <TripHeader
              containerRef={offsetRef}
              tripName={tripData.name}
              titleDates={tripData.titleDateRange}
              variant="topHeading"
              onEdit={editNameToggle.handleOpen}
              isEditable={tripData.isNameEditable}
              isShowDelegateName={isShowDelegateName}
              currentDelegatedUser={currentDelegatedUser}
              bookingRef={bookingId ? itineraryId : ''}
            />
            <div ref={anchorRef} />
          </>
        }
        contentSlot={
          <>
            {meetingModeStore.isInMeetingMode && meetingModeStore.meetingInfo && (
              <MeetingInformationCard meeting={meetingModeStore.meetingInfo.meeting} />
            )}
            {tripDatesAlignment.hasMismatchedSegments && isMobile && (
              <AlignDatesWarning
                flightStartDateTime={tripDatesAlignment.flightStartDateTime}
                lastFlightStartDateTime={tripDatesAlignment.lastFlightStartDateTime}
                onAlignDates={tripDatesAlignment.handleOpenAlignDatesModal}
                mismatchedSegmentTypes={tripDatesAlignment.mismatchedSegmentTypes}
              />
            )}
            <TripReviewAddButtonsRow
              settings={addButtonSettings}
              areTooManySegments={areTooManySegments}
              openDialog={tooManySegmentsToggle.handleOpen}
            />
            <HotelSearchFormModalLayout />
            <AirSearchFormModalLayout />
            <CarSearchFormModalLayout />
            <AirChangeFormModalLayout />
            <Swap is={!tripCost && !segments?.length} isSlot={<EmptyTrips />}>
              <TripReviewDynamicSiteMessages
                dynamicSiteMessage={messages.dynamicSiteMessage}
                complianceMessages={messages.complianceMessages}
                continuityMessages={messages.continuityMessages}
                overlappingTrips={overlappingTrips}
                itineraryId={itineraryId}
                firstHotelId={firstHotel?.hotelId}
                segmentKeys={{
                  carRental: extractFromSegments(segments, SegmentType.CarRental),
                  hotel: extractFromSegments(segments, SegmentType.Hotel),
                }}
              />
              <TripContent
                segments={segments}
                segmentsToDisplay={segmentsToDisplay}
                onOpenOOP={outOfPolicyToggle.handleOpen}
                mismatchedSegments={tripDatesAlignment.mismatchedSegments}
                oopSelections={oopSelections}
                isCheckoutActivated={isCheckoutActivated}
                tripCost={tripCost!}
                itineraryId={itineraryId}
                flightId={flightId}
                flightSegments={flightSegments}
                postPurchaseActions={postPurchaseActions}
                isTripExist={isTripExist}
                airPriceChangeType={airPriceChangeInfoState?.type}
                isMultiCity={isMultiCity}
                airPriceChangeInfo={airPriceChangeInfoState}
                tripEmissionsResult={tripEmissionsResult}
                areTripEmissionLoading={areTripEmissionLoading}
                onOpenRulesAndRestrictions={rulesAndRestrictionsToggle.handleOpen}
                showRulesAndRestrictions={showRulesAndRestrictions}
              />
            </Swap>
          </>
        }
        mobileFooterSlot={
          tripCost && (
            <FooterCheckout
              isHoldTripAllowed={isHoldTripAllowed}
              isDisabled={!isAbleToCheckout}
              onOpenTripCostSummary={tripCostSummaryToggle.handleOpen}
              onHold={onHold}
              onCheckout={onCheckout}
              totalCost={tripCost.totalWithUnusedTicket || tripCost.total}
              changedCost={totalPriceChange}
              isCostChangePositive={isTotalPriceChangePositive}
            />
          )
        }
      />
      {tripDatesAlignment.hasMismatchedSegments && (
        <>
          <AlignDatesAlert
            isOpen={tripDatesAlignment.isAlignDatesAlertOpen}
            flightStartDateTime={tripDatesAlignment.flightStartDateTime}
            lastFlightStartDateTime={tripDatesAlignment.lastFlightStartDateTime}
            onContinueToCheckout={tripDatesAlignment.handleOnContinueToCheckout}
            onConfirm={tripDatesAlignment.handleConfirmAlignDatesAlert}
            onClose={tripDatesAlignment.handleCloseAlignDatesAlert}
            mismatchedTypes={tripDatesAlignment.mismatchedSegmentTypes}
          />
          <AlignDatesModal
            isOpen={tripDatesAlignment.isAlignDatesModalOpen}
            isLoading={tripDatesAlignment.isLoading}
            handleClose={tripDatesAlignment.handleCloseAlignDatesModal}
            mistmatchedSegments={tripDatesAlignment.mismatchedSegments}
            tripFlightSegments={tripDatesAlignment.flightSegments}
            handleSubmit={tripDatesAlignment.handleAlignTrip}
            flightStartDateTime={tripDatesAlignment.flightStartDateTime}
            lastFlightStartDateTime={tripDatesAlignment.lastFlightStartDateTime}
          />
        </>
      )}
      <EditTripNameDialog
        onTripNameUpdate={handleUpdateTripName}
        isOpen={editNameToggle.isOpen}
        value={tripData.name}
        onClose={editNameToggle.handleClose}
      />
      <PriceChangeDialog
        isOpen={airPriceChangeToggle.isOpen}
        onClose={handleAirPriceChangeClose}
        onChangeFlight={handleChangeFlights}
        priceChangeInfo={airPriceChangeInfo}
        currentPrice={flightCost}
      />
      <ConfirmationDialog
        title={t(`${i18Base}.TooManySegmentsDialog.Title`)}
        content={t(`${i18Base}.TooManySegmentsDialog.Description`)}
        isOpen={tooManySegmentsToggle.isOpen}
        onDecline={tooManySegmentsToggle.handleClose}
        onConfirm={tooManySegmentsToggle.handleClose}
        confirmButtonText={t(`${i18Base}.TooManySegmentsDialog.ConfirmationButton`)}
      />
      {startOverModalSlot}
      {tripExpiredModalSlot}
      {tripCost && (
        <TripCostSummary
          isCheckoutDisabled={!isAbleToCheckout}
          onCheckout={onCheckout}
          segments={segments}
          tripCost={tripCost}
          isOpen={tripCostSummaryToggle.isOpen}
          onClose={tripCostSummaryToggle.handleClose}
        />
      )}
      {!!segments.length && (
        <OutOfPolicyModal
          segments={segments}
          descriptions={descriptions}
          isOpen={outOfPolicyToggle.isOpen}
          handleClose={outOfPolicyToggle.handleClose}
          travelPolicy={travelPolicy}
          oopSelections={oopSelections}
          isPolicyExplanationRequired={isPolicyExplanationRequired}
          isHotelRequiredForOvernightTrip={isHotelRequiredForOvernightTrip}
          isCarRentalRequiredViolation={isCarRentalRequiredViolation}
        />
      )}
      {overlappingTrips && (
        <DuplicateTripsModal
          isOpen={errorsTripToggle.isOpen}
          overlappingTrips={overlappingTrips}
          errors={[
            {
              title: t(`${i18Base}.ErrorsList.DuplicateTrip`),
              messageSlot: (
                <DuplicatedList trips={overlappingTrips} onClick={handleDuplicatedTripClick} />
              ),
            },
          ]}
          onApply={onProceedToCheckout}
          onClose={errorsTripToggle.handleClose}
        />
      )}

      {Boolean(flightSegments.length) && (
        <SeatMapRTPModal
          isVisible={rtpSeatMapModalToggle.isOpen}
          onClose={rtpSeatMapModalToggle.handleClose}
        />
      )}

      <MultipleHotelProvidersDialog
        actionType="add"
        isOpen={multipleHotelProvidersRTPDialog.isOpen}
        onClick={handleClickMultipleHotelProviders}
        onBack={multipleHotelProvidersRTPDialog.handleClose}
        onClose={multipleHotelProvidersRTPDialog.handleClose}
      />

      <RulesAndRestrictionsModal
        isOpen={rulesAndRestrictionsToggle.isOpen}
        onClose={rulesAndRestrictionsToggle.handleClose}
        itineraryRules={itineraryRules}
      />
    </>
  )
})
