import type { TripCost } from '@fiji/graphql/types'
import type { TrainTripDetailsCostSummaryValueObject } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-cost-summary.value-object'

export function calculateTrainTripTotal(
  trainCostSummary?: TrainTripDetailsCostSummaryValueObject | null,
): TripCost | null {
  if (!trainCostSummary) {
    return null
  }

  const { total, fare } = trainCostSummary
  return {
    total: total || fare?.total || { primary: { amount: 0, currency: 'USD' } },
    feesAndTaxes: fare?.tax,
    transactionFee: fare?.transactionFee,
    serviceFee: fare?.serviceFee,
  }
}
