import { Text } from '@etta/ui/text'
import { ListHeader } from './headers-styled'

type Props = {
  label: string
  subLabel?: string
  isQuickFilterDesign?: boolean
}

export function HeaderTitle({ label, subLabel, isQuickFilterDesign }: Props) {
  return (
    <ListHeader isInlineView={false} isBorder={!isQuickFilterDesign}>
      {subLabel && <Text variant="captionMedium">{subLabel}</Text>}
      <Text variant="subHeadStrong">{label}</Text>
    </ListHeader>
  )
}
