/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import type { AddressInput, PlacesAutocompleteInput, PlacesSearchType } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import { useGetAutocompletePlacesLazyQuery } from '@fiji/graphql/hooks'
import { mapGraphqlPlaces } from './mappers'

type Props = {
  setIsLoading?: (value: boolean) => void
  onError: () => void
  onChange?: (value: Place, address?: AddressInput) => void
  onModalClose?: (e: boolean) => void
  onComplete?: (value: Place[]) => void
}

const START_SEARCH_LENGTH = 3

export const useGraphqlAutocomplete = ({
  setIsLoading,
  onError,
  onChange,
  onModalClose,
  onComplete,
}: Props) => {
  const fetchAutocompletePlacesAbort = useRef<AbortController | null>(null)
  const [isNewData, setNewData] = useState(false)

  const [getAutocompletePlaces, { data, loading, error }] = useGetAutocompletePlacesLazyQuery({
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (loading || !data) {
      return
    }
    const places = mapGraphqlPlaces(data.autocompletePlaces)
    setNewData(false)
    onComplete?.(places)
  }, [isNewData, data, loading])

  const handleFindPlaces = useCallback(
    ({
      query,
      sortOrder,
      searchType,
    }: {
      query: string
      sortOrder?: PlacesAutocompleteInput['sortOrder']
      searchType?: PlacesSearchType
    }) => {
      const controller = new AbortController()
      fetchAutocompletePlacesAbort.current?.abort()

      fetchAutocompletePlacesAbort.current = controller

      if (query.length < START_SEARCH_LENGTH) {
        return
      }
      setNewData(true)

      getAutocompletePlaces({
        variables: { input: { query, sortOrder, searchType, includeTimezone: true } },
        context: {
          queryDeduplication: false,
          fetchOptions: {
            // If we want make request cancellable, we pass AbortController instance to 'controller' property because of apollo-link-timeout
            // https://github.com/drcallaway/apollo-link-timeout/blob/6ffe29f8f86d0946923405d27a8c3b62458d9046/src/timeoutLink.ts#L31
            // It's a bit confusing since apollo-link-http uses 'signal' property and expects AbortController.signal instead whole instance
            // https://github.com/apollographql/apollo-link/blob/eeb1d520b1f6db9f357330eee0baed75f796f1d2/packages/apollo-link-http/src/httpLink.ts#L102
            controller,
          },
        },
      })
    },
    [],
  )

  const handleSelectedItemChange = ({ selectedItem }: { selectedItem: Place }) => {
    onChange?.(selectedItem)

    onModalClose?.(false)
  }

  useEffect(() => {
    setIsLoading?.(loading)
  }, [setIsLoading, loading])

  useEffect(() => {
    if (!data && !loading && error) {
      onError()
    }
  }, [onError])

  return { handleFindPlaces, handleSelectedItemChange }
}
