import { Fragment } from 'react'
import { Text } from '@etta/ui/text'
import { ChevronValueIcon, ValueContainer, ValueText } from './select-allocation-modal-styled'

type Props = {
  allocationsList: string[]
}

export function Breadcrumbs({ allocationsList }: Props) {
  if (!allocationsList.length) {
    return null
  }

  return (
    <ValueContainer>
      {allocationsList.map((item, index) => {
        return (
          <Fragment key={index + item}>
            <ChevronValueIcon name="chevronRightPWA" size="small" />
            <ValueText>
              <Text variant="subHeadStrong" color="mainText">
                {item}
              </Text>
            </ValueText>
          </Fragment>
        )
      })}
    </ValueContainer>
  )
}
