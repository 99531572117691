import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { Props } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export function SearchButtonMobile({ handleSearchClick }: Props) {
  const { t } = useTranslation()
  const buttonText = t(i18Base + '.Buttons.SearchAir')
  const buttonLabel = t(i18Base + '.Buttons.SearchAirLabel', { text: buttonText })

  return (
    <Button
      btnType="primary"
      onClick={handleSearchClick}
      size="large"
      fullWidth
      aria-label={buttonLabel}
      data-tracking-id="search-flights-button">
      {buttonText}
    </Button>
  )
}
