import type { Place } from '@fiji/types'
import type { AddressInput } from '@fiji/graphql/types'
import { divideAddressArrayToObject } from '@fiji/utils/address/divide-address-array-to-object'
import { getAirportCode } from '@fiji/utils/get-airport-code'
import { rollbar } from '@fiji/rollbar'

// https://developers.google.com/maps/documentation/places/web-service/supported_types
export enum GoogleLocationTypes {
  AIRPORT = 'airport',
  LODGING = 'lodging',
  LOCALITY = 'locality',
  POLITICAL = 'political',
  POSTAL_TOWN = 'postal_town',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
}

export type GetPlaceByLatLongProps = {
  lat: number
  long: number
  onDone: (place?: Place, address?: AddressInput) => void
  searchTypes?: GoogleLocationTypes[]
}

export function mapCurrentLocationName(
  addressComponents?: google.maps.places.PlaceResult['address_components'],
  defaultName = '',
): string {
  let city = ''
  let country = ''
  let countryCode = ''
  let stateCode = ''

  if (!addressComponents) {
    return defaultName
  }

  addressComponents.forEach((address) => {
    if (
      (address.types.includes('locality') && address.types.includes('political')) ||
      address.types.includes('postal_town')
    ) {
      city = address.long_name
    }

    if (address.types.includes('country') && address.types.includes('political')) {
      country = address.long_name
      countryCode = address.short_name
    }

    if (
      address.types.includes('administrative_area_level_1') &&
      address.types.includes('political')
    ) {
      stateCode = address.short_name
    }
  })

  if (!city && !countryCode) {
    return defaultName
  }

  return countryCode.trim().toLowerCase() === 'us'
    ? `${city}${stateCode && stateCode !== city ? `, ${stateCode}` : ''}, ${country}`
    : `${city}, ${country}`
}

export function getPlaceByLatLong({ lat, long, onDone, searchTypes }: GetPlaceByLatLongProps) {
  if (!google?.maps?.places) {
    return onDone()
  }

  const placesService = new google.maps.places.PlacesService(document.createElement('div'))
  const location = new google.maps.LatLng(lat, long)

  placesService.nearbySearch(
    { location, radius: 15 },
    (results: google.maps.places.PlaceResult[], status: string) => {
      if (status !== 'OK') {
        rollbar.error(new Error(`Google maps nearbySearch wrong status: ${status}`), {
          location,
          results,
        })
        return onDone()
      }
      if (!results.length) {
        return onDone()
      }
      const currentPlace = !searchTypes
        ? results[0]
        : results.find((place) => searchTypes.every((type) => place.types?.includes(type)))
      if (!currentPlace?.place_id) {
        return onDone()
      }

      const getDetailsReq = {
        placeId: currentPlace.place_id,
        fields: ['name', 'geometry', 'types', 'address_components', 'formatted_address'],
      }

      placesService.getDetails(
        getDetailsReq,
        (googlePlace: google.maps.places.PlaceResult, status: string) => {
          if (status !== 'OK') {
            rollbar.error(
              new Error(`Google maps getDetails wrong status: ${status}`),
              getDetailsReq,
            )
            return onDone()
          }

          const { city, countryCode, stateCode, postalCode, street1 } = divideAddressArrayToObject(
            googlePlace.address_components,
          )

          const newPlace: Place = {
            placeId: currentPlace.place_id!,
            name: mapCurrentLocationName(
              googlePlace.address_components,
              googlePlace.formatted_address,
            ),
          }

          if (googlePlace.geometry?.location) {
            newPlace.latitude = googlePlace.geometry.location.lat()
            newPlace.longitude = googlePlace.geometry.location.lng()
          }

          const address: AddressInput = {
            lat: newPlace.latitude,
            long: newPlace.longitude,
            city,
            countryCode,
            stateCode,
            postalCode,
            street1,
          }

          if (googlePlace.types?.includes('airport')) {
            newPlace.airportCode = getAirportCode(currentPlace!.name)
          }

          if (googlePlace.types?.includes('lodging')) {
            newPlace.isNameSearch = true
          }

          return onDone(newPlace, address)
        },
      )
    },
  )
}
