import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type props = {
  callbackPath: string | null
  callbackParams: string | null
  forceFetchSuggestions: boolean
}

const initialState: props = {
  callbackPath: null,
  callbackParams: null,
  forceFetchSuggestions: false,
}

const slice = createSlice({
  name: 'mobility',
  initialState,
  reducers: {
    updateCallbackPath: (
      state,
      { payload }: PayloadAction<Pick<props, 'callbackPath' | 'callbackParams'>>,
    ) => {
      const { callbackPath, callbackParams } = payload
      state.callbackPath = callbackPath
      state.callbackParams = callbackParams
    },

    updateForceFetchSuggestions: (
      state,
      { payload }: PayloadAction<Pick<props, 'forceFetchSuggestions'>>,
    ) => {
      const { forceFetchSuggestions } = payload
      state.forceFetchSuggestions = forceFetchSuggestions
    },
  },
})

export const mobilityReducer = slice.reducer
export const { updateCallbackPath, updateForceFetchSuggestions } = slice.actions
