import { useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { TripCost, Rate } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { calculateTripTotal } from '@fiji/utils/calculate-trip-total'
import { formatRate } from '@fiji/utils/money/format-rate'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import type { StepNameType } from '@etta/screens/checkout-page/types'
import { StepName } from '@etta/screens/checkout-page/types'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context/checkout-context-provider'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { subtractRate } from '@fiji/utils/rate'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import type { BookType } from '@etta/screens/payment-summary/use-payment-summary/types'
import { getButtonLabels } from './get-button-label'

type Args = {
  isDueTodayCostDisplayed: boolean
}

export function usePriceSummaryCard({ isDueTodayCostDisplayed }: Args) {
  const { tripActions, reviewTripStore } = useReviewTripContext()
  const {
    travelerInfo,
    additionalInfo,
    paymentSummary,
    travelFusionLinks,
    isContinueButtonDisabled,
  } = useCheckoutContextSelector((state) => state)
  const { checkoutStep } = useParams<{ checkoutStep: StepNameType }>()
  const { queryParams } = useCheckoutQuery()
  const { isHoldFlow } = queryParams
  const { isRailAccreditationEnabled } = paymentSummary

  useEffect(() => {
    tripActions.setTripIdUrlParams()
    if (reviewTripStore.trip) {
      return
    }
    tripActions.loadTrip()
  }, [reviewTripStore, tripActions])

  const {
    onSubmit: onTravelerSubmit,
    isError: isTravelerError,
    isLoading: isTravelerLoading,
  } = travelerInfo
  const {
    onSubmitHandler: onAdditionalSubmit,
    isError: isAdditionalError,
    isLoading: isAdditionalLoading,
    hasFormErrors: hasAdditionalFormErrors,
  } = additionalInfo
  const {
    onPaymentSubmit,
    onSubmit: onBookItinerarySubmit,
    isLoadingError: isPaymentError,
    holdTripConfirmationToggle,
    isLoading: isPaymentLoading,
    hasDisablingConditions,
    isPreBookRequired,
    isHoldButtonVisible,
  } = paymentSummary
  const {
    areTermsError: isTravelFusionLinksError,
    areTermsLoading: isTravelFusionLinksLoading,
  } = travelFusionLinks
  const { handleOpen: handleOpenHoldTripModal } = holdTripConfirmationToggle

  const isTravelerPage = checkoutStep === StepName.TravelerInfo
  const isAdditionalPage = checkoutStep === StepName.AdditionalInfo
  const isPaymentPage = checkoutStep === StepName.PaymentInfo

  const isError = useMemo(
    () =>
      [isTravelerError, isAdditionalError, isPaymentError, isTravelFusionLinksError].some(Boolean),
    [isTravelerError, isAdditionalError, isPaymentError, isTravelFusionLinksError],
  )

  const isLoading = useMemo(
    () =>
      [isTravelerLoading, isAdditionalLoading, isPaymentLoading, isTravelFusionLinksLoading].some(
        Boolean,
      ),
    [isTravelerLoading, isAdditionalLoading, isPaymentLoading, isTravelFusionLinksLoading],
  )
  const isDisabled = useMemo(
    () => [hasAdditionalFormErrors, hasDisablingConditions].some(Boolean),
    [hasAdditionalFormErrors, hasDisablingConditions],
  )

  const isButtonDisabled = useMemo(() => {
    const isLoadingOrError = isLoading || isError
    if (checkoutStep === StepName.TravelerInfo) {
      return isLoadingOrError || isContinueButtonDisabled
    }

    if (checkoutStep === StepName.AdditionalInfo) {
      return isLoadingOrError || hasAdditionalFormErrors
    }

    return isLoadingOrError || isDisabled || isContinueButtonDisabled
  }, [
    checkoutStep,
    isLoading,
    isError,
    isDisabled,
    isContinueButtonDisabled,
    hasAdditionalFormErrors,
  ])

  const isBooking = isPaymentPage && !isPreBookRequired

  const handleSubmit = useCallback(
    async (bookType: BookType) => {
      if (isTravelerPage) {
        onTravelerSubmit()
        return
      }
      if (isAdditionalPage) {
        await onAdditionalSubmit()
        return
      }
      if (isPaymentPage) {
        await onPaymentSubmit(bookType)
        return
      }

      if (bookType === 'hold') {
        handleOpenHoldTripModal()
        return
      }

      await onBookItinerarySubmit('regular')
    },
    [
      isTravelerPage,
      isAdditionalPage,
      isPaymentPage,
      onTravelerSubmit,
      onAdditionalSubmit,
      onPaymentSubmit,
      onBookItinerarySubmit,
      handleOpenHoldTripModal,
    ],
  )

  const onSubmit = useCallback(async () => {
    await handleSubmit('regular')
  }, [handleSubmit])

  const onHoldTrip = useCallback(() => {
    handleSubmit('hold')
  }, [handleSubmit])

  const tripCostSummaryToggle = useTogglePopup()
  const bookingToggle = useTogglePopup()
  const { privacyPolicy } = useSelector(displayConfigurationSelector)
  const { buttonLabel, holdButtonLabel } = getButtonLabels({ isBooking, isDueTodayCostDisplayed })

  const { trip } = reviewTripStore

  const tripCost = trip?.tripCost
  const segments = trip?.segments || []

  const flightCostFiltered = tripCost?.payNow?.flight?.filter((cost): cost is TripCost => !!cost)
  const flightNowRate = calculateTripTotal(flightCostFiltered)?.total

  const flightNowRateWithUnused =
    flightNowRate && reviewTripStore.unusedTicketsRate
      ? subtractRate(flightNowRate, reviewTripStore.unusedTicketsRate)
      : flightNowRate

  const trainCostFiltered = tripCost?.payNow?.train?.filter((cost): cost is TripCost => !!cost)
  const trainNowRate = calculateTripTotal(trainCostFiltered)?.total

  const trainNowRateWithUnused =
    trainNowRate && tripCost?.unusedTicket?.validated?.total
      ? subtractRate(trainNowRate, tripCost?.unusedTicket?.validated?.total)
      : trainNowRate

  const hotelNowRate = tripCost?.payNow?.hotel?.total
  const hotelLaterRate = tripCost?.payLater?.hotel?.total
  const carNowRate = tripCost?.payNow?.car?.total
  const carLaterRate = tripCost?.payLater?.car?.total
  const totalCostRate = tripCost?.totalWithUnusedTicket
  const dueTodayRate = tripCost?.payNow?.subTotal

  const { mainCost: flightNowPrice } = getRate(flightNowRateWithUnused)
  const { mainCost: trainNowPrice } = getRate(trainNowRateWithUnused)
  const { mainCost: hotelNowPrice } = getRate(hotelNowRate)
  const { mainCost: hotelLaterPrice } = getRate(hotelLaterRate)
  const { mainCost: carNowPrice } = getRate(carNowRate)
  const { mainCost: carLaterPrice } = getRate(carLaterRate)
  const { mainCost: totalCost } = getRate(totalCostRate)
  const { mainCost: dueTodayCost } = getRate(dueTodayRate)

  const isPayableNowExist =
    !isHoldFlow && [flightNowPrice, trainNowPrice, hotelNowPrice, carNowPrice].some(Boolean)
  const isPayableLaterExist = isHoldFlow || [hotelLaterPrice, carLaterPrice].some(Boolean)

  return {
    buttonLabel,
    holdButtonLabel,
    tripCostSummaryToggle,
    bookingToggle,
    privacyPolicy,
    flightNowPrice,
    trainNowPrice,
    hotelNowPrice,
    isPayableNowExist,
    hotelLaterPrice,
    carNowPrice,
    carLaterPrice,
    isPayableLaterExist,
    totalCost,
    dueTodayCost,
    tripCost,
    segments,
    onSubmit,
    isHoldFlow,
    onHoldTrip,
    isButtonDisabled,
    isError,
    isBooking,
    isLoading,
    isHoldButtonVisible,
    isRailAccreditationEnabled,
  }
}

function getRate(rate?: Rate | null) {
  return formatRate(rate, {
    morpheme: 'none',
  })
}
