import { Text } from '@etta/ui/text'
import { Loader, LeafIcon, RailEmissionContainer } from './rail-segment-emission-styled'

export type RailSegmentEmissionProps = {
  tonnesOfEmissions?: number
  averageEmissionsTonnes?: number
  kgOfActualEmission?: number
  loading?: boolean
}

export function RailSegmentEmission({
  kgOfActualEmission,
  tonnesOfEmissions,
  loading,
}: RailSegmentEmissionProps) {
  if (loading) {
    return <Loader />
  }

  if (!kgOfActualEmission || !tonnesOfEmissions) {
    return null
  }

  return (
    <RailEmissionContainer>
      <LeafIcon name="ecoFriendlyPWA" size="small" color="success" />
      <Text variant="footnoteMedium" color="bodyText">
        {`${kgOfActualEmission} kg CO`}
        <sub>2</sub>
      </Text>
    </RailEmissionContainer>
  )
}
