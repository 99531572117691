import styled from 'styled-components'
import { footnoteMedium, subHeadStrong } from '@fiji/style'

export const MessageText = styled.div`
  margin-top: 4px;
  word-wrap: break-word;
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium};

  p {
    &:first-child {
      margin-top: 0;
    }
  }

  * {
    // We overwrite the text to make sure it is the same color in every theme
    color: ${(p) => p.theme.colors.bodyText} !important;
  }
`

export const MessageTitle = styled.div`
  ${subHeadStrong};
`

export const MessageSection = styled.div`
  width: 80%;
  margin-top: 2px;
`
