import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import type { RailFareValueObject } from '@etta/modules/rail/core/value-objects/rail-fare.value-object'
import { useSearchAdaptiveRail } from '@fiji/hooks/use-adaptive-rail/use-search-adaptive-rail/use-search-adaptive-rail'
import { TrainServiceActiveTab } from '@fiji/enums'
import { useActualRailEmissions } from '@fiji/hooks/sustainability/use-actual-rail-emissions/use-actual-rail-emissions'
import { RailSegmentTravelClassEnum } from '@etta/modules/rail/core/enums/rail-segment-travel-class.enum'

import type { PermittedStationsInfoValueObject } from '@etta/modules/rail/core/value-objects/permitted-stations-info.value-object'
import { RailDetailsContentLayout } from './layout'
import type { LayoutProps } from './types'

const i18nBase = 'RailDetails'

// Temporary solution for storybook work, should be filtered based off the real data.
const filterFares = ({ segments }: RailFareValueObject, tab: TrainServiceActiveTab) => {
  return segments?.[0].displayServiceClass?.toLowerCase() === tab
}

function activeTabToTravelClass(activeTab: TrainServiceActiveTab) {
  switch (activeTab) {
    case TrainServiceActiveTab.Standard:
      return RailSegmentTravelClassEnum.Economy
    case TrainServiceActiveTab.FirstClass:
      return RailSegmentTravelClassEnum.First
    default:
      return RailSegmentTravelClassEnum.Economy
  }
}

export const RailDetailsContent = observer(function RailDetailsContent({
  handleModalClose,
  railLegType,
}: LayoutProps) {
  const { t } = useTranslation()

  const { railStore, railActions, railEmissionStore } = useRailContext()

  const activeTab = railStore.trainServiceActiveTab

  const travelClass = activeTabToTravelClass(activeTab)

  useEffect(() => {
    railActions.getRailEmissions(travelClass)
  }, [travelClass, railActions])

  const actualRailEmission = useActualRailEmissions(
    railStore.activeLeg!.legId,
    railEmissionStore.railEmissions ?? undefined,
    travelClass,
  )

  const { rail } = useSearchAdaptiveRail(railStore.activeLeg!)
  const { headerTitle, isPreferred, adaptedSegments, railLabels, railLogo, squareRailLogos } = rail

  const faresToShow = rail.fares.filter((fare) => filterFares(fare, activeTab))

  const fareClassLabel =
    activeTab === 'standard' ? t(i18nBase + '.Tabs.Standard') : t(i18nBase + '.Tabs.FirstClass')

  const layoutProps = {
    activeTab,
    onTabChange: railActions.handleTrainServiceActiveTab,
    railLabels,
    railLogo,
    rail,
    squareRailLogos,
    headerTitle,
    isPreferred,
    adaptedSegments,
    railPolicies: railStore.railPolicies,
    faresToShow,
    handleOpenTicketRestrictions: (tierName: string) =>
      railActions.handleTicketRestrictionsInfoOpen(tierName, railStore.activeLeg!),
    handleOpenPermittedStations: (permittedStationsInfo: PermittedStationsInfoValueObject) =>
      railActions.handlePermittedStationsInfoOpen(permittedStationsInfo),
    actualRailEmission: { ...actualRailEmission, isLoading: railEmissionStore.isLoading },
    fareClassLabel,
    onSelectFareSeats: (fare: RailFareValueObject) =>
      railActions.selectFareSeats(railLegType, fare),
    fareConditions: railStore.fareConditions,
    handleModalClose,
  }

  return <RailDetailsContentLayout {...layoutProps} />
})
