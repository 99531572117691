import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { MobileFormContent } from './air-search-form-mobile-styled'

export function AirSearchFormMobile({
  tabsSlot,
  submitButtonSlot,
  preSearchCustomFieldsModalSlot,
  recentSearchesSlot,
  children,
  addNewFlightButtonSlot,
  costAllocationSlot,
}: LayoutProps) {
  return (
    <>
      <MobileFormContent>
        {tabsSlot}
        {children}
        {costAllocationSlot && (
          <Block paddingHorizontal={16} marginBottom={6}>
            {costAllocationSlot}
          </Block>
        )}
        {addNewFlightButtonSlot && (
          <Block marginTop={18} marginBottom={5} marginHorizontal={16}>
            {addNewFlightButtonSlot}
          </Block>
        )}
        {recentSearchesSlot}
      </MobileFormContent>

      <Block marginTop={16} marginBottom={16} marginHorizontal={16}>
        {submitButtonSlot}
      </Block>
      {preSearchCustomFieldsModalSlot}
    </>
  )
}
