import { useState } from 'react'
import { createContext } from './create-context'

type UseDescendantsWrapper = {
  shouldFocusFirstElement: boolean
  handleFocusFirstListElement: () => void
  handleResetFocusState: () => void
}

export const [
  DescendantsWrapperProvider,
  useDescendantsWrapper,
] = createContext<UseDescendantsWrapper>()

export function DescendantsWrapper({ children }: { children: React.ReactNode }) {
  const [shouldFocusFirstElement, setShouldFocusFirstElement] = useState(false)

  const handleFocusFirstListElement = () => {
    setShouldFocusFirstElement(true)
  }

  const handleResetFocusState = () => {
    setShouldFocusFirstElement(true)
  }
  return (
    <DescendantsWrapperProvider
      value={{ shouldFocusFirstElement, handleFocusFirstListElement, handleResetFocusState }}>
      {children}
    </DescendantsWrapperProvider>
  )
}
