import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { TimeRange } from '@fiji/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useTimeUtils } from '@fiji/hooks/use-time-utils'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { CalendarTimeModal } from './types'

type HookProps = {
  flightType: FlightType
  departureDate?: Date
  returnDate?: Date
  timeRange: TimeRange
  returnTimeRange?: TimeRange
  isShortCustomTime?: boolean
}

const i18Base = 'TripPlanner.BaseSearch'

export function useCalendarTimeSection({
  flightType,
  departureDate,
  returnDate,
  timeRange,
  returnTimeRange,
  isShortCustomTime,
}: HookProps) {
  const { getTimeLabel, getTimeValue } = useTimeUtils()
  const { t } = useTranslation()

  const datePicketModal = useTogglePopup()

  const returnDatePicketModal = useTogglePopup()

  const originTimeModal = useTogglePopup()

  const returnTimeModal = useTogglePopup()

  const formatDisplayDate = useCallback((date?: Date): string => {
    return date ? dateFormat(date, 'MMM dd') : ''
  }, [])

  const calendarTimeModals: CalendarTimeModal[] = [
    datePicketModal,
    returnDatePicketModal,
    originTimeModal,
    returnTimeModal,
  ]

  const isAnyModalVisible = calendarTimeModals.find(({ isOpen }) => isOpen)

  const isRoundTrip = flightType === FlightType.Round
  const convertedOriginDate = formatDisplayDate(departureDate)
  const originDateLabel = t(i18Base + '.Input.DepartOn')
  const originTimeString = getTimeValue(timeRange, isShortCustomTime)
  const originTimeLabel = t(i18Base + getTimeLabel(timeRange))
  const originAriaLabel = departureDate
    ? t(i18Base + '.Input.DateSelectedAriaLabel', {
        label: originDateLabel,
        value: convertedOriginDate,
      })
    : t(i18Base + '.Input.DateAriaLabel', { label: originDateLabel })

  const convertedReturnDate = formatDisplayDate(returnDate)
  const returnDateLabel = t(i18Base + '.Input.ReturnOn')

  const returnTimeString = returnTimeRange ? getTimeValue(returnTimeRange, isShortCustomTime) : ''
  const returnTimeLabel = returnTimeRange ? t(i18Base + getTimeLabel(returnTimeRange)) : ''

  const returnAriaLabel = convertedReturnDate
    ? t(i18Base + '.Input.DateSelectedAriaLabel', {
        label: returnDateLabel,
        value: convertedReturnDate,
      })
    : t(i18Base + '.Input.DateAriaLabel', { label: returnDateLabel })

  const handleModalOpen = useCallback(
    (modal: CalendarTimeModal) => {
      if (isAnyModalVisible) {
        isAnyModalVisible.handleClose()
      }

      modal.handleOpen()
    },
    [isAnyModalVisible],
  )

  return {
    datePicketModal,
    returnDatePicketModal,
    originTimeModal,
    returnTimeModal,
    isRoundTrip,
    convertedOriginDate,
    originDateLabel,
    originTimeString,
    originTimeLabel,
    originAriaLabel,
    convertedReturnDate,
    returnDateLabel,
    returnTimeString,
    returnTimeLabel,
    returnAriaLabel,
    handleModalOpen,
  }
}
