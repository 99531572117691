import styled from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 15px 16px 15px 20px;

  ${KeyboardNavigationStyle}

  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }
`

export const Details = styled.div`
  display: flex;
  align-items: center;
`

export const CountTickets = styled.div`
  padding: 8px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 6px;
  max-height: 24px;
  display: flex;
  align-items: center;
`
