import type { CreditCardBrandType, Maybe } from '@fiji/graphql/types'
import type { VIRTUAL_PAY } from '@fiji/constants'
import type { ColorPalette } from '@fiji/types'
import { CardIconWrapper } from './card-icon-styled'
import { IconByVendorType } from './icon-by-card-vendor'

type Props = {
  cardVendor?: Maybe<CreditCardBrandType> | typeof VIRTUAL_PAY
  backgroundColor?: keyof ColorPalette
}

export function CardIcon({ cardVendor, backgroundColor }: Props) {
  return (
    <CardIconWrapper backgroundColor={backgroundColor}>
      <IconByVendorType cardVendor={cardVendor} />
    </CardIconWrapper>
  )
}
