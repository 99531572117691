import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProps } from '../types'
import { HotelDetailsAmenitiesLayoutMobile } from './mobile'
import { HotelDetailsAmenitiesLayoutDesktop } from './desktop'

export function HotelDetailsAmenitiesLayout({ ...props }: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={
        <HotelDetailsAmenitiesLayoutMobile {...props}></HotelDetailsAmenitiesLayoutMobile>
      }
      desktopSlot={
        <HotelDetailsAmenitiesLayoutDesktop {...props}></HotelDetailsAmenitiesLayoutDesktop>
      }
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
