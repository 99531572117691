import { Inject, Service } from '@etta/di'
// TODO: remove fiji import
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
// TODO: remove fiji import
import type { TimeRange, CabinClassType, Dates, Place } from '@fiji/types'
// eslint-disable-next-line import/no-restricted-paths
import { PlacesStorageAction } from '@etta/modules/places-storage/interface/actions/places-storage.action'
import { AirSearchFormStore } from '../../stores/air-search-form.store'
import { AirSearchQueryService } from '../air-search-query/air-search-query.service'

@Service()
export class AirSearchFormInputsService {
  constructor(
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly airSearchQueryService: AirSearchQueryService,
    @Inject()
    private readonly placesStorageAction: PlacesStorageAction,
  ) {}

  handleOriginPlaceChange(legNumber: number, place: Place) {
    this.handleUpdateFlight(legNumber, { originPlace: place })
    this.placesStorageAction.addNewPlace('air')(place)
  }

  handleDestinationPlaceChange(legNumber: number, place: Place) {
    const { searchFlights: flights, searchFlightType: flightType } = this.airSearchFormStore

    const nextFlight = legNumber + 1

    const updatedFlights = flights.map((flight, index) => {
      if (index === legNumber) {
        return { ...flight, destinationPlace: place }
      }
      if (index === nextFlight && !flight.destinationPlace) {
        return { ...flight, originPlace: place }
      }
      return flight
    })

    this.handleChangeFlights({ flights: updatedFlights, flightType })
    this.placesStorageAction.addNewPlace('air')(place)
  }

  handleDateChange(legNumber: number, result: Dates) {
    const { searchFlights: flights } = this.airSearchFormStore

    const updatedFlights = flights.map((flight, index) => {
      if (index === legNumber) {
        return {
          ...flight,
          departureDate: result.date ?? undefined,
          returnDate: result.dateEnd ?? flight.returnDate,
        }
      }
      return flight
    })

    const { searchFlightType: flightType } = this.airSearchFormStore

    if (flightType === FlightType.MultiCity) {
      updatedFlights.sort(
        (a, b) =>
          new Date(a.departureDate || '').valueOf() - new Date(b.departureDate || '').valueOf(),
      )
    }
    this.handleChangeFlights({ flights: updatedFlights, flightType })
  }

  handleTimeChange(legNumber: number, time: TimeRange) {
    this.handleUpdateFlight(legNumber, {
      timeRange: time,
    })
  }

  handleReturnTimeChange(legNumber: number, time: TimeRange) {
    this.handleUpdateFlight(legNumber, {
      returnTimeRange: time,
    })
  }

  handleCabinClassChange(legNumber: number, cabinClass: CabinClassType) {
    this.handleUpdateFlight(legNumber, {
      cabinClass,
    })
  }

  checkIsLocationInputError(legNumber: number) {
    const { searchFlights: flights } = this.airSearchFormStore
    const flight = flights[legNumber]
    if (!flight) {
      return false
    }

    const { destinationPlace, originPlace } = flight
    const isDestinationCityExist = !!destinationPlace?.city
    const isOriginCityExist = !!originPlace?.city
    const isSameCity =
      isDestinationCityExist && isOriginCityExist && destinationPlace.city === originPlace.city

    if (!destinationPlace || !originPlace) {
      return false
    }

    return (
      isSameCity ||
      destinationPlace.name === originPlace.name ||
      destinationPlace.placeId === originPlace.placeId
    )
  }

  private handleUpdateFlight(legNumber: number, newFlight: Partial<Flight>) {
    const { searchFlights: flights, searchFlightType: flightType } = this.airSearchFormStore

    const updatedFlights = flights.map((flight, index) => {
      if (index === legNumber) {
        return { ...flight, ...newFlight }
      }
      return flight
    })

    this.handleChangeFlights({ flights: updatedFlights, flightType })
  }

  handleChangeFlights({ flights, flightType }: { flights: Flight[]; flightType: FlightType }) {
    if (this.airSearchFormStore.isEditMode) {
      this.airSearchFormStore.updateSearchFlightsEditCache({
        flights,
        flightType,
      })
      return
    }
    this.airSearchQueryService.appendQueryParams({ flights, flightType })
  }
}
