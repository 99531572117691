import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { RailTimeRangePicker } from '@etta/components/rail-time-range-picker/rail-time-range-picker'
import { Block } from '@etta/ui/block'
import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { InputButton } from '@etta/ui/input-button'
import { InputButtonGroup } from '@etta/ui/input-button-group'
import { getTimeAriaLabel } from '@fiji/utils/get-time-aria-label'
import type { Props } from '../../types'
import { useCalendarTimeSection } from '../../use-calendar-time-section'

const i18Base = 'TripPlanner.BaseSearch'

export const CalendarTimeSectionMobile = observer(function TabsSectionDesktop({
  isDateRangeValid,
  railTripType,
  originPlace,
  destinationPlace,
  originDate,
  originTime,
  destinationTime,
  destinationDate,
}: Props) {
  const { t } = useTranslation()

  const {
    departDateToggle,
    departTimeToggle,
    returnTimeToggle,
    isRoundTrip,
    originDateString,
    originDateLabel,
    destinationDateString,
    destinationDateLabel,
    originTimeString,
    originTimeLabel,
    destinationTimeString,
    destinationTimeLabel,
    onOriginTimeChange,
    onDestinationTimeChange,
    onDatesSelect,
  } = useCalendarTimeSection({
    railTripType,
    originDate,
    destinationDate,
    originTime,
    destinationTime,
  })

  return (
    <>
      <Block paddingHorizontal={16} paddingVertical={6}>
        <InputButtonGroup aria-label="depart dates">
          <InputButton
            icon="calendarPWA"
            isFilled={!!originDate}
            value={originDateString}
            aria-label={
              originDate
                ? t(i18Base + '.Input.DateSelectedAriaLabel', {
                    label: originDateLabel,
                    value: originDateString,
                  })
                : t(i18Base + '.Input.DateAriaLabel', { label: originDateLabel })
            }
            label={originDateLabel}
            onClick={departDateToggle.handleOpen}
            placeholder={t(i18Base + '.Input.Select')}
          />
          <InputButton
            value={originTimeString}
            icon="timePWA"
            isInteractive
            label={originTimeLabel}
            aria-label={getTimeAriaLabel({
              i18next: originTime.i18next,
              customHours: originTime.customTimeHours,
              startHours: originTime.startTimeHours,
              endHours: originTime.endTimeHours,
              value: originTimeString,
              label: originTimeLabel,
            })}
            isFilled={!!originTime}
            onClick={departTimeToggle.handleOpen}
          />
        </InputButtonGroup>
      </Block>
      {isRoundTrip && (
        <Block paddingHorizontal={16} paddingVertical={6}>
          <InputButtonGroup aria-label="arrive dates">
            <InputButton
              icon="calendarPWA"
              isFilled={!!destinationDate}
              value={isDateRangeValid ? destinationDateString : null}
              label={t(i18Base + '.Input.ReturnOn')}
              aria-label={
                destinationDate
                  ? t(i18Base + '.Input.DateSelectedAriaLabel', {
                      label: destinationDateLabel,
                      value: destinationDateString,
                    })
                  : t(i18Base + '.Input.DateAriaLabel', { label: destinationDateLabel })
              }
              onClick={departDateToggle.handleOpen}
              placeholder={t(i18Base + '.Input.Select')}
            />
            <InputButton
              value={destinationTimeString}
              icon="timePWA"
              isInteractive
              label={destinationTimeLabel}
              aria-label={getTimeAriaLabel({
                i18next: destinationTime!.i18next,
                customHours: destinationTime!.customTimeHours,
                startHours: destinationTime!.startTimeHours,
                endHours: destinationTime!.endTimeHours,
                value: destinationTimeString,
                label: destinationTimeLabel,
              })}
              isFilled={!!destinationTime}
              onClick={returnTimeToggle.handleOpen}
            />
          </InputButtonGroup>
        </Block>
      )}

      <RailTimeRangePicker
        isVisible={departTimeToggle.isOpen}
        onClose={departTimeToggle.handleClose}
        onSubmit={onOriginTimeChange}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
        selectedTime={originTime}
        date={originDate}
      />

      <RailTimeRangePicker
        isVisible={returnTimeToggle.isOpen}
        onClose={returnTimeToggle.handleClose}
        onSubmit={onDestinationTimeChange}
        originPlace={destinationPlace}
        destinationPlace={originPlace}
        selectedTime={destinationTime}
        date={destinationDate}
      />

      <DatePickerModal
        isEndDateSame
        date={originDate}
        dateEnd={isDateRangeValid && isRoundTrip ? destinationDate : null}
        isRange={isRoundTrip}
        onSelect={onDatesSelect}
        isOpen={departDateToggle.isOpen}
        onClose={departDateToggle.handleClose}
        withConfirmButton={true}
        minDate={new Date()}
      />
    </>
  )
})
