import type { PropsWithChildren } from 'react'
import { useContext } from 'react'
import { TabsContext } from '../tabs-context'
import type { TabName } from '../types'
import { Container } from './tab-panel-styled'

type Props = PropsWithChildren<{
  value: TabName
  withAnimation?: boolean
}>

export function TabPanel({ children, value, withAnimation = true }: Props) {
  const context = useContext(TabsContext)
  const isActive = context.activeTab === value

  if (!isActive) {
    return null
  }

  return <Container withAnimation={withAnimation}>{children}</Container>
}
