import { SegmentType } from '@fiji/graphql/types'
import type { Section } from '../types'
import { getSegmentDetails } from './get-segments'

type Props = {
  flightDetails?: Section
  carRentalDetails?: Section
  hotelDetails?: Section
  railDetails?: Section
  isPostBooking: boolean
}

export function useSegmentStatusSplit({
  flightDetails,
  hotelDetails,
  carRentalDetails,
  railDetails,
  isPostBooking,
}: Props) {
  if (!isPostBooking) {
    return {
      newFlights: flightDetails,
      newHotels: hotelDetails,
      newCarRentals: carRentalDetails,
      newRail: railDetails,
    }
  }

  const { newDetails: newFlights, bookedDetails: bookedFlights } = getSegmentDetails({
    details: flightDetails,
    type: SegmentType.Flight,
  })

  const { newDetails: newHotels, bookedDetails: bookedHotels } = getSegmentDetails({
    details: hotelDetails,
    type: SegmentType.Hotel,
  })

  const { newDetails: newCarRentals, bookedDetails: bookedCarRentals } = getSegmentDetails({
    details: carRentalDetails,
    type: SegmentType.CarRental,
  })

  const { newDetails: newRail, bookedDetails: bookedRail } = getSegmentDetails({
    details: railDetails,
    type: SegmentType.Train,
  })

  return {
    bookedFlights,
    bookedCarRentals,
    bookedHotels,
    bookedRail,
    newFlights,
    newCarRentals,
    newHotels,
    newRail,
  }
}
