import { Store, Inject } from '@etta/di'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'
import type { Validator } from '@etta/interface/services/validator'
import { AccountActivationValidatorMaker } from '../validator-maker'
import { UserProfileStore } from './user-profile.store'

type ValidatorType = ReturnType<
  typeof AccountActivationValidatorMaker.prototype.makeBusinessContactValidator
>

@Store()
export class BusinessContactStore {
  private readonly _businessContactValidator: ValidatorType
  private _additionalInformationValidator: Validator | null = null

  constructor(
    @Inject() private readonly fieldSettingsStore: FieldSettingsStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {
    const validatorMaker = new AccountActivationValidatorMaker(this.fieldSettingsStore)
    this._businessContactValidator = validatorMaker.makeBusinessContactValidator()
  }

  setField(
    key: Parameters<typeof this._businessContactValidator.onFieldChange>[0],
    value: string | boolean,
  ) {
    this._businessContactValidator.onFieldChange(key, value)
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator?.onFieldChange(fieldName, fieldValue)
  }

  get fieldSettings() {
    return this.fieldSettingsStore.fieldsSettings
  }

  get shouldRenderedBusinessContactFields() {
    const businessContactField = Object.keys(
      this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.BusinessContact],
    )
    return businessContactField.some(
      (key) =>
        this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.BusinessContact][key]
          .isRenderedOnActivationPage,
    )
  }

  get shouldRenderedBusinessAddressFields() {
    const businessAddressField = [AddressFields.Country, AddressFields.Mailstop, 'addressBlock']
    return businessAddressField.some(
      (key) =>
        this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.BusinessAddress][key]
          .isRenderedOnActivationPage,
    )
  }

  get shouldShowPersonalInfoSection() {
    const { vipIndicator, homeEmail, homePhone } = this.fieldSettingsStore.fieldsSettings[
      FieldSettingsCategory.PersonalInformation
    ]
    return (
      vipIndicator.isRenderedOnActivationPage ||
      homeEmail.isRenderedOnActivationPage ||
      homePhone.isRenderedOnActivationPage
    )
  }

  get shouldRenderBusinessContact() {
    return (
      this.shouldRenderedBusinessContactFields ||
      this.shouldRenderedBusinessAddressFields ||
      this.shouldShowPersonalInfoSection
    )
  }

  get shouldRenderedAdditionalBusinessContactFields() {
    return Boolean(this.userProfileStore.userProfile?.businessAddress.additionalInformation.length)
  }

  get validators() {
    return {
      businessContactValidator: this._businessContactValidator,
      additionalInformationValidator: this._additionalInformationValidator,
    }
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }
}
