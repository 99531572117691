import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { SegmentType } from '@etta/core/enums'
import type { PromisedResult } from '@etta/core/type-utils'
import type { OutOfPolicyReasonValueObject } from '@etta/core/value-objects'
import type { OOPErrorsInstances } from '../../core/errors/oop.errors'
import { OOPErrors } from '../../core/errors/oop.errors'
import type { SendOopCodesInput } from '../../core/value-objects/send-oop-codes-input'
import { OOPDataProvider } from './oop.data-provider'

@Adapter()
export class OOPAdapter {
  constructor(
    @Inject()
    private oopDataProvider: OOPDataProvider,
  ) {}

  async getOOPConfiguration(
    segments: SegmentType[],
    forceUpdate?: boolean,
  ): PromisedResult<OutOfPolicyReasonValueObject[], OOPErrorsInstances> {
    try {
      const { data, errors } = await this.oopDataProvider.getOOPConfiguration(
        { segments },
        forceUpdate,
      )
      if (errors) {
        return Result.Err(new OOPErrors.GetOOPConfigurationError(errors))
      }

      if (!data.outOfPolicyReason) {
        return Result.Err(new OOPErrors.GetOOPConfigurationError('No data returned'))
      }

      return Result.Ok(data.outOfPolicyReason as OutOfPolicyReasonValueObject[])
    } catch (e) {
      return Result.Err(new OOPErrors.UnexpectedGetOOPConfigurationError(e))
    }
  }

  async sendOopCodes(
    input: SendOopCodesInput,
  ): PromisedResult<{ success: boolean }, OOPErrorsInstances> {
    try {
      const { data, errors } = await this.oopDataProvider.sendOopCodes({ input })

      if (!data?.sendOopCodes.success) {
        return Result.Err(new OOPErrors.SendOopCodesError(data?.sendOopCodes.message))
      }

      if (errors) {
        return Result.Err(new OOPErrors.SendOopCodesError(errors))
      }

      return Result.Ok(data.sendOopCodes)
    } catch (e) {
      return Result.Err(new OOPErrors.SendOopCodesError(e))
    }
  }
}
