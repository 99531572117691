import { TrainDeliveryOptionCode } from '@fiji/enums'
import { Container } from './ticket-delivery-styled'
import { Eticket } from './eticket'
import { Collect } from './collect'

type Props = {
  deliveryOptionCode?: string
  collectionReferences?: (string | null)[] | null
  email?: string | null
}

export function TicketDelivery({ deliveryOptionCode, email, collectionReferences }: Props) {
  if (!deliveryOptionCode) {
    return null
  }

  const isEticket = deliveryOptionCode === TrainDeliveryOptionCode.Eticket
  const isCollect = deliveryOptionCode === TrainDeliveryOptionCode.Collect

  return (
    <Container>
      {isEticket && <Eticket email={email} />}
      {isCollect && <Collect collectionReferences={collectionReferences} />}
    </Container>
  )
}
