import styled from 'styled-components'
import { title3 } from '@fiji/style'
import { Dialog } from '@etta/ui/dialog'

export const DialogWrapper = styled(Dialog)`
  padding: 24px 32px;
  border-radius: 1.6rem;
  text-align: center;

  > div {
    margin-left: auto;
    margin-right: auto;
  }
`

export const Title = styled.div`
  ${title3}
  font-size: 17px;
  line-height: 22px;
  margin-top: 17px;
`

export const Divider = styled.div`
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #e5e5e9;
`

export const DialogText = styled.div`
  font-size: 13px;
  line-height: 19px;
  color: #656b72;
  margin-bottom: 24px;
`

export const CurrentLocationText = styled.span<{
  active: boolean
}>`
  display: inline-block;
  padding-bottom: 6px;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => (props.active ? props.theme.colors.primary : 'transparent')};
`
