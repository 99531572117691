import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { HotelInfoList } from '@etta/components/hotel-info-list/hotel-info-list'
import { HotelImage } from '@etta/components/hotel-image/hotel-image'
import { useHotelRating } from '@etta/components/hotel-reviews/use-hotel-rating'
import type { HotelCardLayoutProps } from '../types'
import { Price } from '../price'
import { useHotelCard } from '../use-hotel-card'
import {
  Content,
  Container,
  HotelTitle,
  ImageWrapper,
  AmenityContainer,
  Footer,
  PriceWrapper,
} from './hotel-card-desktop-styled'
import { Amenities } from './amenities'
import { HotelSustainabilityDeviation } from './hotel-sustainability-deviation'

const i18nBase = 'SearchResults.Hotel'

export function HotelCardDesktop({
  hotel,
  isHotelLoading,
  isTripAdvisorReviewsEnabled,
  hotelEmission,
}: HotelCardLayoutProps) {
  const { t } = useTranslation()
  const {
    chainCode,
    logoPhotoUrl,
    isPreferred,
    photos,
    name,
    starRating,
    medianReviewRating,
    isEcoFriendly,
    privateRate,
    amenities,
    exclusiveAmenities,
    distance,
    preference,
    id,
    reviews,
  } = hotel
  const { featureFlags } = useFeatureFlags()

  const { medianRating, isLoading: isRatingLoading } = useHotelRating({
    hotelId: id,
    medianReviewRating,
    totalReviewCount: reviews.length || 0,
    reviews,
    isTripAdvisorReviewsEnabled,
  })

  const {
    isOutOfPolicy,
    isAllRoomsOutOfPolicy,
    slicedName,
    firstRoom,
    outOfPolicyTexts,
  } = useHotelCard({ hotel })

  return (
    <Container>
      <ImageWrapper>
        <HotelImage
          photos={photos}
          logoPhotoUrl={logoPhotoUrl}
          chainCode={chainCode}
          isHotelLoading={isHotelLoading}
          placeholderSkin="light"
        />
      </ImageWrapper>
      <Content>
        <HotelInfoList
          starRating={starRating}
          isTripAdvisorReviewsEnabled={isTripAdvisorReviewsEnabled}
          medianReviewRating={medianRating}
          privateRate={privateRate}
          isReviewLoading={isRatingLoading}
        />
        <HotelTitle aria-label={t(`${i18nBase}.HotelNameAriaLabel`, { hotelName: name })}>
          {slicedName}
        </HotelTitle>
        <AmenityContainer>
          <Amenities amenities={amenities} exclusiveAmenities={exclusiveAmenities} />
        </AmenityContainer>
        {featureFlags.isSustainabilityEnabled && (
          <HotelSustainabilityDeviation {...hotelEmission} isEcoFriendly={isEcoFriendly} />
        )}
        <Footer>
          <HotelInfoList
            distance={distance}
            isPreferred={isPreferred}
            preference={preference}
            isOutOfPolicy={isOutOfPolicy}
            isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
            outOfPolicyTexts={outOfPolicyTexts}
          />
          <PriceWrapper>
            <Price
              firstRoom={firstRoom}
              isHotelLoading={isHotelLoading}
              isPreferred={isPreferred}
              isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
              skin="column"
            />
          </PriceWrapper>
        </Footer>
      </Content>
    </Container>
  )
}
