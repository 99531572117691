import styled from 'styled-components'
import { captionStrongCaps, subHeadMedium, footnoteMedium } from '@fiji/style/typography'
import { UnstyledButton, getKeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  padding: 16px 0;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const HeaderText = styled.div`
  ${captionStrongCaps};
  color: ${(props) => props.theme.colors.bodyText};
  padding-left: 11px;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ItemContainer = styled(UnstyledButton)`
  padding: 0px 16px;
  width: 100%;
  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }
  ${getKeyboardNavigationStyle({ offset: -6 })}
`

export const ListItem = styled.div`
  display: flex;
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ArrowIconWrapper = styled.span`
  padding: 0 6px;
  margin-top: -2px;
`

export const Location = styled.div`
  ${subHeadMedium};
  color: ${(props) => props.theme.colors.mainText};
`

export const LeftBlock = styled.div``

export const CentralBlock = styled.div`
  padding-left: 8px;
`
export const RightBlock = styled.div`
  position: absolute;
  right: 0;
`

export const TopLine = styled.div`
  display: flex;
`

export const BottomLine = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
`
