import type { PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { SegmentType } from '@fiji/graphql/hooks'
import type { PostBookingAction } from '@fiji/enums'
import { useAppSelector } from '@fiji/store'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { screenSize } from '@fiji/style'
import { useServiceSearchLayout } from './use-service-search-layout'
import {
  PageIcon,
  SearchBlock,
  SearchButton,
  SearchLine,
  Container,
  Header,
  HeaderWrapper,
} from './service-search-layout-styled'

type Props = PropsWithChildren<{
  onBack: () => void
  isAbleToSubmit?: boolean
  onSearchClick?: () => void
  type: SegmentType.Flight | SegmentType.Hotel | SegmentType.CarRental
  postBookingAction?: PostBookingAction
  costAllocationSlot?: ReactNode
  isNewLayout?: boolean
}>

export function ServiceSearchLayout({
  onBack,
  onSearchClick,
  isAbleToSubmit,
  type,
  children,
  postBookingAction,
  costAllocationSlot,
  isNewLayout = false,
}: Props) {
  const { t } = useTranslation()
  const { headerText, iconName, isDisabled } = useServiceSearchLayout({
    type,
    postBookingAction,
    isAbleToSubmit,
  })

  const { isPreBookCostAllocationEnabled } = useAppSelector(displayConfigurationSelector)
  const isMaxLaptop = useMedia(`only screen and (${screenSize.maxLaptop})`)

  return (
    <Container>
      <SearchBlock isNewLayout={isNewLayout}>
        <HeaderWrapper>
          <Header isMaxLaptop={isMaxLaptop}>
            <Block paddingRight={12} marginLeft={-44}>
              <IconButton
                aria-label={t('Accessibility.Header.Back')}
                icon="arrowBackLargePWA"
                size="medium"
                onClick={onBack}
              />
            </Block>
            <Text variant="title2">{headerText}</Text>
          </Header>
        </HeaderWrapper>
        <SearchLine>
          {children}
          {!!onSearchClick && (
            <Block marginTop={type === SegmentType.Flight ? 36 : 0}>
              <SearchButton
                aria-label={t('Accessibility.ReviewTripPage.SearchButton')}
                onClick={onSearchClick}
                disabled={!isDisabled}>
                <Icon name="searchPWA" size="medium" color="mainText2" />
              </SearchButton>
            </Block>
          )}
        </SearchLine>
        {isPreBookCostAllocationEnabled && <Block marginTop={20}>{costAllocationSlot}</Block>}
      </SearchBlock>
      <PageIcon name={iconName} color="bodyText2" />
    </Container>
  )
}
