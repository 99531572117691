import styled from 'styled-components'

export const Container = styled.div`
  padding: 14px 16px;
`

export const DurationContainer = styled.div`
  margin-bottom: 6px;
  display: flex;
  position: relative;
  justify-content: space-between;
`

export const FooterContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ChangesLine = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 8px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  display: flex;
  align-items: center;
  align-self: center;
`

export const ChangesWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
`

export const ChangesDot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.borderDark};
  }
`
export const RailCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0px;
`
