import type { OutOfPolicyReason } from '@fiji/graphql/types'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'

type Values = Record<
  'policyExplanation' | 'flightReason' | 'hotelReason' | 'carRentalReason' | 'railReason',
  any
>

type Props = {
  values: Values
  reasons: OutOfPolicyReason[]
  onSubmit: () => Promise<{ isValid: boolean }>
  handleClose: () => void
}

type Args = {
  values: Values
  reasons: OutOfPolicyReason[]
  isMod2FlowEnabled: boolean
}

const getReasonCodes = ({ values, reasons, isMod2FlowEnabled }: Args) => {
  const findKey = isMod2FlowEnabled ? 'codeForBooking' : 'id'

  const airReason = values.flightReason
    ? reasons.find((el) => el[findKey] === values.flightReason)
    : null
  const hotelReason = values.hotelReason
    ? reasons.find((el) => el[findKey] === values.hotelReason)
    : null
  const carRentalReason = values.carRentalReason
    ? reasons.find((el) => el[findKey] === values.carRentalReason)
    : null
  const railReason = values.railReason
    ? reasons.find((el) => el[findKey] === values.railReason)
    : null

  return {
    airReason,
    hotelReason,
    carRentalReason,
    railReason,
  }
}

export function useOopSave({ values, reasons, onSubmit, handleClose }: Props) {
  const { outOfPolicyActions, outOfPolicyStore } = useOutOfPolicyContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { handleSetOOPSelection } = outOfPolicyActions
  const onSaveOopValidation = async () => {
    const { isValid } = await onSubmit()

    if (!isValid) {
      return
    }

    const { airReason, carRentalReason, hotelReason, railReason } = getReasonCodes({
      values,
      reasons,
      isMod2FlowEnabled: displayConfigurationStore.isMod2FlowEnabled,
    })

    const { success } = await handleSetOOPSelection({
      oopExplanation: values.policyExplanation,
      oopSegments: {
        air: airReason?.codeForBooking || null,
        hotel: hotelReason?.codeForBooking || null,
        carrental: carRentalReason?.codeForBooking || null,
        rail: railReason?.codeForBooking || null,
      },
      oopSegmentsReason: {
        airReason: airReason?.reason || null,
        hotelReason: hotelReason?.reason || null,
        carrentalReason: carRentalReason?.reason || null,
        railReason: railReason?.reason || null,
      },
      oopSelections: {
        flight: airReason?.reason || null,
        hotel: hotelReason?.reason || null,
        carRentals: carRentalReason?.reason || null,
        rail: railReason?.reason || null,
        description: values.policyExplanation,
      },
    })

    if (!success) {
      return
    }

    handleClose()
  }

  return { onSaveOopValidation, isLoading: outOfPolicyStore.isUpdatingOOP }
}
