import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import type { TextProps } from '@etta/ui/text'
import { visuallyHiddenStyle } from '@fiji/style'

export const Container = styled.label<{ isActive?: boolean }>`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'flex-start' : 'center')};
  cursor: pointer;
  width: 100%;
  min-height: 48px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.transparent)};

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const SkeletonContainer = styled(Container)`
  border: 2px solid ${({ theme }) => theme.colors.transparent};
`

export const Logo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  object-fit: contain;
  margin-right: 8px;
`

export const Title = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'subHeadStrong',
}))<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.bodyText1 : theme.colors.mainText};
`

export const Input = styled.input.attrs((props) => ({
  ...props,
  type: 'radio',
  name: 'radio',
}))`
  ${visuallyHiddenStyle}
`

export const Content = styled.span`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`
