import { useTranslation } from 'react-i18next'
import { CarSafetyCheck } from '@etta/components/car-safety-check/car-safety-check'
import { CarPriceBlock } from '../../car-price-block'
import { CarInfoBlock } from '../../car-info-block'
import { CarFooterBlock } from '../../car-footer-block'
import type { CarRentalSegmentDesktopLayoutProps } from './types'
import { Container, Content } from './desktop-styled'

export function CarRentalSearchSegmentDesktop({
  carRentalTitle,
  priceAriaLabel,
  costLabel,
  perDayLabel,
  handleOpenCarRentalDetails,
  vendor,
  details,
  carImage,
  policy,
  isPreferred,
  carSafetyToggle,
  safetyCheck,
  isLoading,
  isAmenitiesLoading,
  handleAddCar,
  estimate,
  isSelectable,
  isHybridFuel,
  carEmission,
  customBadge,
}: CarRentalSegmentDesktopLayoutProps) {
  const { t } = useTranslation()

  return (
    <Container
      data-tracking-id={`car-item-container-${carRentalTitle}`}
      aria-label={t('SearchSegments.CarRental.TapToDetailLabel', {
        supplierName: vendor?.name,
        carRentalTitle,
        class: details.class,
        seatsCount: details.passenger,
        bagsCount: details.luggage,
      })}>
      <CarSafetyCheck
        type={safetyCheck?.messageCode}
        isVisible={carSafetyToggle.isOpen}
        onClose={carSafetyToggle.handleClose}
        readMoreLink={safetyCheck?.url}
        vendorCode={vendor?.code}
        vendorName={vendor?.name}
        viewType="modal"
      />
      <Content>
        <CarInfoBlock
          isLoading={isLoading}
          isAmenitiesLoading={isAmenitiesLoading}
          carRentalTitle={carRentalTitle}
          amenities={details}
          carImage={carImage}
          vendor={vendor}
          handleMoreDetails={handleOpenCarRentalDetails}
          isPreferred={isPreferred}
          handleOpenSafetyCheckDialog={carSafetyToggle.handleOpen}
          carFooterSlot={
            <CarFooterBlock isLoading={isLoading} policy={policy} carEmission={carEmission} />
          }
          isHybridFuel={isHybridFuel}
          carEmission={carEmission}
          customBadge={customBadge}
        />
        <CarPriceBlock
          isLoading={isLoading}
          costLabel={costLabel}
          perDayLabel={perDayLabel}
          priceAriaLabel={priceAriaLabel}
          onSelectClick={handleAddCar}
          estimate={estimate}
          isSelectable={isSelectable}
        />
      </Content>
    </Container>
  )
}
