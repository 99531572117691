import { css } from 'styled-components'

export const SlickDots = css`
  .slick-dots li.slick-active button:before {
    color: white;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 8px;
  }

  .slick-dots li {
    margin: 0;
    pointer-events: none;
  }

  .slick-dots li button {
    cursor: default;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: transparent;
  }

  .slick-dots ul {
    margin: 0;
    height: 20px;
    padding: 0;
  }
`
