import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Spinner } from '@etta/ui/spinner'
import type { LayoutProps } from '../types'
import {
  TRANSITION_NAME,
  EXIT_TRANSITION_DURATION,
  Container,
  Wrapper,
  AnimationContainer,
  StyledLottieAnimation,
  AnimationLabelContainer,
  AnimationLabelWrapper,
  AnimationLabel,
  TripOverviewLabel,
  Separator,
  SpinnerContainer,
  SpinnerWrapper,
  SpinnerLabel,
} from './booking-animation-container-desktop-styled'
import { flightAnimation, carRentalAnimation, hotelAnimation, trainAnimation } from './assets'
import { SegmentsContainer } from './segments-container'

const i18nBase = 'PaymentSummary'

export function BookingAnimationContainerDesktop({
  tripTitle,
  tripDateRange,
  travelerFullName,
  flights,
  hotels,
  carRentals,
  rails,
  animationLabel,
  setFlightAnimation,
  setCarRentalAnimation,
  setHotelAnimation,
  setTrainAnimation,
  isNewLoadingConfirmationEnabled,
}: LayoutProps) {
  const { t } = useTranslation()

  if (isNewLoadingConfirmationEnabled) {
    return (
      <Container>
        <Wrapper>
          <Block marginTop={100}>
            <Text variant="title1" color="mainText">
              {tripTitle}
            </Text>
          </Block>
          <Block marginTop={8} isFlexBlock>
            <TripOverviewLabel>
              <Icon name="calendarPWA" color="bodyText" size="small" />
              {tripDateRange}
            </TripOverviewLabel>
            {travelerFullName && (
              <>
                <Separator>{'|'}</Separator>
                <TripOverviewLabel>
                  <Icon name="travelerPWA" color="bodyText" size="small" />
                  {travelerFullName}
                </TripOverviewLabel>
              </>
            )}
          </Block>
          <AnimationContainer>
            <StyledLottieAnimation ref={setFlightAnimation} src={flightAnimation} />
            <StyledLottieAnimation ref={setCarRentalAnimation} src={carRentalAnimation} />
            <StyledLottieAnimation ref={setHotelAnimation} src={hotelAnimation} />
            <StyledLottieAnimation ref={setTrainAnimation} src={trainAnimation} />
            {animationLabel && (
              <AnimationLabelContainer>
                <AnimationLabelWrapper>
                  <SwitchTransition>
                    <CSSTransition
                      key={animationLabel}
                      classNames={TRANSITION_NAME}
                      timeout={EXIT_TRANSITION_DURATION}
                      mountOnEnter
                      unmountOnExit>
                      <AnimationLabel>{animationLabel}</AnimationLabel>
                    </CSSTransition>
                  </SwitchTransition>
                </AnimationLabelWrapper>
              </AnimationLabelContainer>
            )}
          </AnimationContainer>
          <SegmentsContainer
            flights={flights}
            hotels={hotels}
            carRentals={carRentals}
            rails={rails}
          />
        </Wrapper>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <SpinnerWrapper>
        <Spinner type="spinout" size="large" />
      </SpinnerWrapper>
      <SpinnerLabel>{t(`${i18nBase}.BookingContainerLoading`)}</SpinnerLabel>
    </SpinnerContainer>
  )
}
