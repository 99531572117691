import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Radio as MobileRadio } from '@etta/ui/radio'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  width: 384px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.borderDark};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
`

export const TabLabel = styled(Text).attrs(() => ({
  variant: 'subHeadStrong',
  color: 'bodyText',
}))`
  margin-bottom: 9px;
  padding: 16px 20px 0;
`

export const AirportTitle = styled(Text).attrs(() => ({
  variant: 'headline',
  color: 'mainText',
}))`
  margin-bottom: 10px;
  padding: 0 20px;
`
export const TimeLabel = styled(Text).attrs(() => ({
  variant: 'subHeadMedium',
  color: 'mainText',
}))``

export const TimeSelectorContainer = styled.div``

export const Radio = styled(MobileRadio)`
  margin: 18px 6px 18px 0;
`

export const InputContainer = styled.div<{ isCustomTimeSelected: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  max-height: ${(props) => (props.isCustomTimeSelected ? `131px` : `56px`)};
  transition: all 250ms ease-out;
  overflow: ${({ isCustomTimeSelected }) => (isCustomTimeSelected ? '' : 'hidden')};
  padding: 0 20px;

  &:last-child {
    border: none;
  }
`

export const SliderContainer = styled.div`
  padding: 0px 20px 0px 23px;
  margin-bottom: 20px;
`

export const Footer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 24px 20px;
`

export const ConfirmButton = styled(Button).attrs(() => ({ fullWidth: true }))``
