import { Icon } from '@etta/ui/icon'
import { RailTitleStation, RailTitleContainer } from '../rtp-eco-check-modal-mobile-styled'

type Props = {
  origin?: string | null
  destination?: string | null
}

export function RtpEcoCheckModalRailTitle({ origin, destination }: Props) {
  return (
    <RailTitleContainer>
      <RailTitleStation>
        {origin}
        <Icon name="flightArrowPWA" />
        {destination}
      </RailTitleStation>
    </RailTitleContainer>
  )
}
