import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { ButtonWrapper } from './state-error-mobile-styled'
import { getErrorText } from './get-error-text'

export function StateErrorMobile({ title, onClick, viewState }: LayoutProps) {
  const { title: usedTitle, button: usedButton } = getErrorText({ title, viewState })

  return (
    <>
      <Block marginTop={7}>
        <Icon name="errorPWA" color="error" size="large" />
      </Block>
      <Block marginTop={16}>
        <Text variant="subHeadStrong" color="mainText" align="center">
          {usedTitle}
        </Text>
      </Block>
      {onClick && (
        <ButtonWrapper>
          <Button onClick={onClick} fullWidth>
            {usedButton}
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}
