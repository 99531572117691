import { useTranslation } from 'react-i18next'
import { formatPrice } from '@fiji/utils/money'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { MultiModalRouteValueObject } from '../../core/value-objects/multi-modal-route.value-object'
import { MultiModalRouteType } from '../../core/enum/multi-modal-route-type.enum'
import {
  Container,
  ContentBody,
  ContentFooter,
  WrapperContent,
  WrapperDeparture,
  WrapperImage,
  WrapperNameContent,
  WrapperTime,
} from './multi-modal-block-styled'

type Props = {
  route: MultiModalRouteValueObject
  openGoogleMaps: () => void
}

const i18nBase = 'Mobility.SearchResultPage.MultiModal'

export function MultiModalBlock({ route, openGoogleMaps }: Props) {
  const { t } = useTranslation()
  const { routeType, estimatedDuration, routeDescription, routeDistance, estimatePrice } = route

  const estimatedDurationFromSecondToMinutes = Math.floor(estimatedDuration / 60)
  const routeDistanceFromMetersToMiles = routeDistance ? routeDistance * 0.000621 : 0

  return (
    <Container
      onClick={openGoogleMaps}
      data-tracking-id={
        routeType === MultiModalRouteType.Transit
          ? 'search-result-transit-button'
          : 'search-result-driving-button'
      }>
      <ContentBody>
        <WrapperImage>
          <Icon
            name={routeType === MultiModalRouteType.Transit ? 'railPWA' : 'drivePWA'}
            color={'mainText'}
            size={40}
          />
        </WrapperImage>
        <WrapperContent>
          <WrapperNameContent>
            <Text variant="calloutStrong" color="mainText1" textOverflow="ellipsis">
              {t(
                `${i18nBase}${
                  routeType === MultiModalRouteType.Transit
                    ? `.TransitRouteName`
                    : `.DriveRouteName`
                }`,
              )}
            </Text>
            <WrapperTime>
              <Text variant="calloutStrong" color="mainText1" textOverflow="ellipsis">
                {`${estimatedDurationFromSecondToMinutes} ${t(`${i18nBase}.Min`)}`}
              </Text>
              <Block marginLeft={4}>
                <Icon name={'directionsPWA'} color={'primary'} />
              </Block>
            </WrapperTime>
          </WrapperNameContent>
          {routeDescription && (
            <WrapperDeparture>
              <Icon name={'timerPWA'} color={'mainText1'} size={'small'} />
              <Text variant="footnoteMedium" color="mainText1" textOverflow="ellipsis">
                {routeDescription}
              </Text>
            </WrapperDeparture>
          )}
          {routeType === MultiModalRouteType.Driving && (
            <Text variant="footnoteMedium" color="mainText1" textOverflow="ellipsis">
              {`${routeDistanceFromMetersToMiles.toFixed(1)} ${t(`${i18nBase}.MileDrive`)}`}
            </Text>
          )}
        </WrapperContent>
      </ContentBody>
      {routeType === MultiModalRouteType.Transit && estimatePrice.currency && (
        <ContentFooter>
          <Text variant="captionMedium" color="bodyText" textOverflow="ellipsis">
            {t(`${i18nBase}.EstimatedPrice`)}
          </Text>
          <Block paddingLeft={6} isFlexBlock>
            <Text variant="headline" color="mainText1" textOverflow="ellipsis">
              {formatPrice(estimatePrice)}
            </Text>
          </Block>
        </ContentFooter>
      )}
    </Container>
  )
}
