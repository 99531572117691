import i18n from 'i18next'
import type { SearchFlightLeg } from '@fiji/graphql/types'
import type { FareWithFareAttributes, TicketAttributes } from '@fiji/types'
import type { FareAttributesValueObject } from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'
import { OnTheFlyContentAssessmentEnum } from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'
import { toGQLOnTheFlyContentAssessment } from '@etta/modules/air-search/infra/air-search.data-provider/to-fare-attributes.mapper'
import {
  CANCELLATION,
  CARRY_ON_BAG_ALLOWANCE,
  CHECKED_BAG_ALLOWANCE,
  NO_CHECKED_BAG_ALLOWANCE,
  SEAT_SELECTION,
} from '../../constants'

function mapTicketAttributes(fareAttributes: FareAttributesValueObject): TicketAttributes[] {
  const hasCheckedBagAllowance = fareAttributes.comparison.utas.some(
    (uta) => uta.category === CHECKED_BAG_ALLOWANCE,
  )
  const utas = hasCheckedBagAllowance
    ? fareAttributes.comparison.utas
    : [
        ...fareAttributes.comparison.utas,
        {
          headline: 'UNSPECIFIED',
          category: NO_CHECKED_BAG_ALLOWANCE,
          description: i18n.t('BaggageInformation.BaggageInfoUnavailable'),
          assessment: OnTheFlyContentAssessmentEnum.Unspecified,
        },
      ]

  return utas.map((uta) => ({
    id: uta.category,
    name: uta.description,
    assessment: toGQLOnTheFlyContentAssessment(uta.assessment),
  }))
}

function sortTicketAttributes(ticketAttributes: TicketAttributes[]) {
  const sortedItems = []
  const carryOnBagAllowance = ticketAttributes.find(({ id }) => id === CARRY_ON_BAG_ALLOWANCE)
  const checkedBagAllowance = ticketAttributes.find(({ id }) => id === CHECKED_BAG_ALLOWANCE)
  const noCheckedBagAllowance = ticketAttributes.find(({ id }) => id === NO_CHECKED_BAG_ALLOWANCE)
  const seatSelection = ticketAttributes.find(({ id }) => id === SEAT_SELECTION)

  if (carryOnBagAllowance) {
    sortedItems.push(carryOnBagAllowance)
  }

  if (checkedBagAllowance) {
    sortedItems.push(checkedBagAllowance)
  }

  if (noCheckedBagAllowance) {
    sortedItems.push(noCheckedBagAllowance)
  }

  if (seatSelection) {
    sortedItems.push(seatSelection)
  }

  ticketAttributes.forEach((attribute) => {
    if (
      [
        CARRY_ON_BAG_ALLOWANCE,
        CHECKED_BAG_ALLOWANCE,
        NO_CHECKED_BAG_ALLOWANCE,
        SEAT_SELECTION,
        CANCELLATION,
      ].includes(attribute.id)
    ) {
      return
    }

    sortedItems.push(attribute)
  })

  return sortedItems
}

export function getFares(
  fares: SearchFlightLeg['fares'],
  fareAttributes?: FareAttributesValueObject[],
): FareWithFareAttributes[] {
  return fares.map((fare, index) => {
    const fareAttribute = fareAttributes?.[index]

    if (!fareAttribute) {
      return fare
    }

    return {
      ...fare,
      title: fareAttribute.comparison.brand.name,
      ticketAttributes: sortTicketAttributes(mapTicketAttributes(fareAttribute)),
    }
  })
}
