import styled, { css } from 'styled-components'

export const SLIDE_LEFT_IN = 'SLIDE_LEFT_IN'
export const SLIDE_RIGHT_IN = 'SLIDE_RIGHT_IN'
export const TRANSITION_TIMEOUT = 500

export const slideLeftIn = css`
  &.${SLIDE_LEFT_IN}-enter {
    transform: translateX(-100%);
  }

  &.${SLIDE_LEFT_IN}-enter-active {
    transform: translateX(0%);
    transition: transform ${TRANSITION_TIMEOUT}ms;
  }

  &.${SLIDE_LEFT_IN}-exit {
    transform: translateX(0%);
  }

  &.${SLIDE_LEFT_IN}-exit-active {
    transform: translateX(-100%);
    transition: transform ${TRANSITION_TIMEOUT}ms;
  }
`

export const slideRightIn = css`
  &.${SLIDE_RIGHT_IN}-enter {
    transform: translateX(100%);
  }

  &.${SLIDE_RIGHT_IN}-enter-active {
    transform: translateX(0%);
    transition: transform ${TRANSITION_TIMEOUT}ms;
  }

  &.${SLIDE_RIGHT_IN}-exit {
    transform: translateX(0%);
  }

  &.${SLIDE_RIGHT_IN}-exit-active {
    transform: translateX(100%);
    transition: transform ${TRANSITION_TIMEOUT}ms;
  }
`

export const ContainerWrapper = styled.div`
  position: absolute;
  width: 100%;

  ${slideLeftIn};
  ${slideRightIn};
`
