import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'

export function getEquivalencesCount(equivalences?: CalculateEmissionsBatchResultEquivalence[]) {
  const treeSeedlingEquivalenceAmount = equivalences?.find(
    (eq) => eq.type === CalculateEmissionsBatchResultEquivalenceType.Seedling,
  )?.amount

  const plasticBagEquivalenceAmount = equivalences?.find(
    (eq) => eq.type === CalculateEmissionsBatchResultEquivalenceType.PlasticBag,
  )?.amount

  const smartphoneChargeEquivalenceAmount = equivalences?.find(
    (eq) => eq.type === CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge,
  )?.amount

  return {
    treeSeedlingEquivalenceAmount,
    plasticBagEquivalenceAmount,
    smartphoneChargeEquivalenceAmount,
  }
}
