import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
// eslint-disable-next-line import/no-restricted-paths
import { RecentSearchesFlightSearchParamsFlightType } from '@fiji/graphql/types'
import { DescendantItem } from '@etta/components/descendant'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { TitleContainer } from '../../recent-searches-list-mobile-styled'
import { useRecentSearchesContext } from '../../../../../interface/use-recent-searches.context'
import {
  ArrowIconWrapper,
  BottomLine,
  LeftBlock,
  ListItem,
  Location,
  RightBlock,
  TopLine,
} from '../recent-search-items-styled'

type Props = {
  onModalClose?: () => void
}

export const RecentFlightSearchList = observer(function RecentFlightSearchList({
  onModalClose,
}: Props) {
  const { t } = useTranslation()
  const title = t('RecentSearches.Title')
  const { featureFlags } = useFeatureFlags()
  const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
  const recentSearchesList = recentSearchesStore.recentSearches.flight.items
  const [recentSearches, setRecentSearches] = useState(recentSearchesList)
  const { autocompleteStore } = useAutocompleteContext()

  useEffect(() => {
    if (!featureFlags.isRecentSearchesFilteringEnabled) {
      return
    }

    const filteredSearhes = recentSearchesList.filter((rs) => {
      const {
        originLocationName,
        destinationLocationName,
      } = recentSearchDataAction.getRecentFlightSearches(rs.flightSearchParams)
      const query = autocompleteStore.query?.toLowerCase() || ''
      const origin = originLocationName.toLowerCase()
      const destination = destinationLocationName.toLowerCase()

      return origin.includes(query) || destination.includes(query)
    })

    setRecentSearches(filteredSearhes)
  }, [autocompleteStore.query, recentSearchesList, featureFlags, recentSearchDataAction])

  if (!recentSearchesList.length) {
    return null
  }

  return (
    <>
      <TitleContainer aria-label={title}>{title}</TitleContainer>
      {recentSearches.map((item) => {
        const {
          bottomLine,
          destinationLocationName,
          fillSearchForm,
          originLocationName,
        } = recentSearchDataAction.getRecentFlightSearches(item.flightSearchParams)
        return (
          <DescendantItem
            key={item.id}
            onSelect={() => {
              onModalClose && onModalClose()
              fillSearchForm()
            }}>
            <ListItem
              onClick={() => {
                onModalClose && onModalClose()
                fillSearchForm()
              }}
              data-tracking-id="flight-search-mechanism-recent-searches-list">
              <LeftBlock>
                <Icon name="flightPWA" size={35} />
              </LeftBlock>
              <RightBlock>
                <TopLine>
                  <Location>{originLocationName}</Location>
                  <ArrowIconWrapper>
                    {item.flightSearchParams.flightType ===
                    RecentSearchesFlightSearchParamsFlightType.OneWay ? (
                      <Icon name="flightArrowPWA" color="bodyText" />
                    ) : (
                      <Icon name="returnFlightArrowPWA" color="bodyText" />
                    )}
                  </ArrowIconWrapper>
                  <Location>{destinationLocationName}</Location>
                </TopLine>
                <BottomLine>{bottomLine}</BottomLine>
              </RightBlock>
            </ListItem>
          </DescendantItem>
        )
      })}
    </>
  )
})
