import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions'
import { SegmentTypeCheckActions as PostBookingSegmentType } from '@etta/modules/post-booking/interface/actions/post-booking-segment-type-check.actions'

import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

export function extractId(segment: TripSegmentValueObject) {
  const segmentCheck = SegmentTypeCheckActions.getInstance()
  if (segmentCheck.isHotelSegment(segment)) {
    return segment.hotelId
  }
  if (segmentCheck.isCarRentalSegment(segment)) {
    return segment.carId
  }
  if (segmentCheck.isFlightSegment(segment)) {
    return segment.legId
  }
  return null
}

export function extractPostBookingId(segment: SegmentPostBookingValueObject) {
  const segmentCheck = PostBookingSegmentType.getInstance()
  if (segmentCheck.isHotelSegment(segment)) {
    return segment.hotelId
  }
  if (segmentCheck.isCarRentalSegment(segment)) {
    return segment.carId
  }
  if (segmentCheck.isFlightSegment(segment)) {
    return segment.legId
  }
  return null
}
