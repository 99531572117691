import type { UpdateTokensWithCookieMutation } from '@fiji/graphql/hooks'
import { UpdateTokensWithCookieDocument } from '@fiji/graphql/hooks'
import { getClient } from '../../apollo'

let isUpdateTokensCalled = false

export function updateTokens() {
  if (isUpdateTokensCalled) {
    return
  }
  const client = getClient()
  if (!client) {
    return
  }

  isUpdateTokensCalled = true
  return client
    .mutate<UpdateTokensWithCookieMutation>({
      mutation: UpdateTokensWithCookieDocument,
    })
    .then(() => {
      isUpdateTokensCalled = false
    })
}
