import styled from 'styled-components'

export const Container = styled.div<{ isMobile: boolean; skin: 'hotel-results' | 'hotel-details' }>`
  display: flex;
  margin: ${({ skin, isMobile }) => {
    switch (skin) {
      case 'hotel-details':
        return isMobile ? '16px' : '8px 16px 16px'
      default:
        return '16px 0 0'
    }
  }};
  padding: ${({ isMobile }) => (isMobile ? '10px 19px' : '16px 16px 16px 19px')};
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
`
