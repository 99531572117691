import type { ReactNode } from 'react'

import type { IconNames } from '@etta/ui/icon'
import type { IconSizes } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { RowLayout } from './layout'

type Props = {
  countryName?: string
  searchType?: SearchType
  isGrouped?: boolean | null
  role?: string
  tabIndex?: number
  onClick?: () => void
  icon: IconNames
  children: ReactNode
  iconSize?: IconSizes
}

export function Row({
  role,
  tabIndex,
  onClick,
  icon,
  children,
  iconSize = 'large',
  isGrouped,
  countryName,
  searchType,
}: Props) {
  return (
    <div role={role} tabIndex={tabIndex} onClick={() => onClick && onClick()}>
      <RowLayout
        searchType={searchType}
        countryName={countryName}
        isGrouped={isGrouped}
        children={children}
        iconSlot={<Icon name={icon} size={iconSize} color="primary" />}
      />
    </div>
  )
}
