import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { BaggageInfoSegment } from '@fiji/types'
import { Section, BaggageInfoContainer, TitleContainer } from './baggage-info-styled'

const i18nBase = 'PostBooking.FlightDetails'
const i18nBaseBaggageInfo = 'BaggageInformation'

type Props = {
  baggageInfo: BaggageInfoSegment
  firstBaggageCost: string
  onBaggageClick: () => void
}

export function BaggageInfo({ onBaggageClick, firstBaggageCost, baggageInfo }: Props) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (firstBaggageCost) {
    return (
      <Section data-tracking-id="baggage-link-text">
        <BaggageInfoContainer isWithSpaceBetween>
          <BaggageInfoDetails onBaggageClick={onBaggageClick} firstBaggageCost={firstBaggageCost} />
        </BaggageInfoContainer>
      </Section>
    )
  }

  if (isMobile) {
    return null
  }

  return (
    <Section data-tracking-id="baggage-unavailable-text">
      <BaggageInfoContainer>
        <BaggageInfoDetailsUnavailable baggageInfo={baggageInfo} />
      </BaggageInfoContainer>
    </Section>
  )
}

function BaggageInfoDetails({ onBaggageClick, firstBaggageCost }: Omit<Props, 'baggageInfo'>) {
  const { t } = useTranslation()
  const titleLabel = t(`${i18nBase}.BaggageInfoPrice`, { price: firstBaggageCost })

  return (
    <>
      <TitleContainer aria-label={titleLabel}>
        <Icon name="paidCheckInBagPWA" color="bodyText" size="small" />
        <Block marginLeft={8}>
          <Text variant="captionMedium" color="mainText">
            {titleLabel}
          </Text>
        </Block>
      </TitleContainer>
      <Block>
        <Link
          size="small"
          onClick={onBaggageClick}
          aria-label={t(i18nBase + '.Button', {
            label: t(`${i18nBase}.BaggageInfoLink`),
          })}
          data-tracking-id="baggage-info-button">
          {t(`${i18nBase}.BaggageInfoLink`)}
        </Link>
      </Block>
    </>
  )
}

function BaggageInfoDetailsUnavailable({ baggageInfo }: Pick<Props, 'baggageInfo'>) {
  const { t } = useTranslation()

  return (
    <>
      <TitleContainer>
        <Icon name="noCheckedBagInfoPWA" color="bodyText" size="normal" />
      </TitleContainer>
      <Block marginLeft={14}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(`${i18nBaseBaggageInfo}.CheckedBaggageInfoUnavailableFull`)}&nbsp;
          {baggageInfo.baggageFees?.carrierUrl ? (
            <Link size="small" href={baggageInfo.baggageFees.carrierUrl} target="_blank">
              {baggageInfo.name}
              <Icon name="externalLinkPWA" color="primary" size={16} />
            </Link>
          ) : (
            <>{t(`${i18nBaseBaggageInfo}.CheckAirlineWebsite`)}</>
          )}
        </Text>
      </Block>
    </>
  )
}
