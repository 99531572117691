import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const TitleSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 16px;
`

export const TitleDate = styled(Text)`
  text-transform: uppercase;
`

export const Status = styled(Text)`
  margin-left: 4px;
  letter-spacing: normal;
`

export const HeadingSection = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Heading = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

export const IconWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`
