import { ChangeType } from '@fiji/graphql/hooks'
import type { SupportedActionsV2Fragment } from '@fiji/graphql/hooks'
import { FlightActionChangeType } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-actions-value-object'
import type { TripDetailsFlightSearchDateRangeValueObject } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-search-date-range-value-object'
import type { TripDetailsPostReservationValueObject } from '../../../core/value-objects/trip-details'

function toFlightActionChangeType(input?: ChangeType | null): FlightActionChangeType | undefined {
  switch (input) {
    case ChangeType.EntireFlight:
      return FlightActionChangeType.EntireFlight
    case ChangeType.ReturnLeg:
      return FlightActionChangeType.ReturnLeg
    default:
      return undefined
  }
}

function mapFlightSearchDateRange(
  start?: string | null,
  end?: string | null,
): TripDetailsFlightSearchDateRangeValueObject | undefined {
  if (!start || !end) {
    return undefined
  }

  return {
    start,
    end,
  }
}

export class SupportedActionsMapper {
  static toTripSupportedActions(
    actions: SupportedActionsV2Fragment | null | undefined,
  ): TripDetailsPostReservationValueObject {
    const {
      allowAddFlight,
      allowAddHotel,
      allowAddCar,
      allowBookAgain,
      allowChange,
      allowCancel,
      segmentChangeActions,
    } = actions || {}

    const entireFlightChangeRules = segmentChangeActions?.flightBooking?.flightChangeRules?.find(
      (rule) => rule.changeType === ChangeType.EntireFlight,
    )?.flightChangeBehavior

    return {
      isAddCarRentalAllowed: !!allowAddCar,
      isAddFlightAllowed: !!allowAddFlight,
      isAddHotelAllowed: !!allowAddHotel,
      isBookAgainTripAllowed: !!allowBookAgain,
      isCancelTripAllowed: !!allowCancel,
      isChangeTripAllowed: !!allowChange,
      isCarRentalCancelAllowed: !!segmentChangeActions?.carBooking?.allowCancel,
      isCarRentalChangeAllowed: !!segmentChangeActions?.carBooking?.allowChange,
      isFlightCancelAllowed: !!segmentChangeActions?.flightBooking?.allowCancel,
      isFlightChangeAllowed: !!segmentChangeActions?.flightBooking?.allowChange,
      isFlightChangeCarrierAllowed: !!entireFlightChangeRules?.allowChangeCarrier,
      isFlightChangeDestinationAllowed: !!entireFlightChangeRules?.allowChangeAirports, // kept same logic as in GQL
      isHotelCancelAllowed: !!segmentChangeActions?.hotelBooking?.allowCancel,
      isHotelChangeAllowed: !!segmentChangeActions?.hotelBooking?.allowChange,
      isTrainCancelAllowed: !!segmentChangeActions?.trainBooking?.allowCancel,
      isTrainChangeAllowed: !!segmentChangeActions?.trainBooking?.allowChange,
      flightChangeRules:
        segmentChangeActions?.flightBooking?.flightChangeRules?.map((r) => {
          return {
            allowedSearchDateRange: mapFlightSearchDateRange(
              r.flightChangeBehavior?.allowedSearchStartDate,
              r.flightChangeBehavior?.allowedSearchEndDate,
            ),
            isChangeCarrierAllowed: !!r.flightChangeBehavior?.allowChangeCarrier,
            isChangeFareTypeAllowed: !!r.flightChangeBehavior?.allowChangeFareType,
            isChangeAirportAllowed: !!r.flightChangeBehavior?.allowChangeAirports,
            isChangeCabinAllowed: !!r.flightChangeBehavior?.allowChangeCabin,
            changeType: toFlightActionChangeType(r.changeType),
          }
        }) ?? [],
    }
  }
}
