import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import {
  Container,
  LeftContainer,
  BlockWithBorderBottom,
  IconWithBorderBottom,
} from './mobility-zone-selection-item-styled'

type ItemProps = {
  onSelect: () => void
  locationName: string
  isSelected: boolean
  isAccessPoint?: boolean
}

export function MobilityZoneSelectionItem({
  onSelect,
  locationName,
  isSelected,
  isAccessPoint,
}: ItemProps) {
  const { handleKeyDown } = useA11yKeydown(onSelect)
  return (
    <Container onClick={onSelect} onKeyDown={handleKeyDown} role={'button'}>
      <LeftContainer>
        <Block marginRight={14} marginLeft={18} paddingVertical={22}>
          <Icon
            name={isAccessPoint ? 'carMarkerPWA' : 'locationPWA'}
            size="medium"
            color="primary"
          />
        </Block>
        <BlockWithBorderBottom>
          <Text variant={'subHeadStrong'} color="mainText">
            {locationName}
          </Text>
        </BlockWithBorderBottom>
        <IconWithBorderBottom>
          {isSelected && <Icon name="thickCheckMarkPWA" size="normal" color="primary" />}
        </IconWithBorderBottom>
      </LeftContainer>
    </Container>
  )
}
