import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { CabinClassType } from '@fiji/types'
import { Menu } from '@etta/ui/menu'
import { Text } from '@etta/ui/text'
import { Radio } from '@etta/ui/radio'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import type { Props } from '../types'
import { ChevronDownIcon } from './cabin-class-desktop-styled'

const i18Base = 'FlightSearch.CabinClass'

type Options = CabinClassType['id'] & { ariaLabel?: string }

export function CabinClassDesktop({ pickedCabinClass, onChange, onClose }: Props) {
  const { t } = useTranslation()

  const { getCabinClassLabel } = useCabinClassLabels()
  const { cabinClassesOptions } = useSeatmapCabinClasss()

  const handleChange = useCallback(
    (id: CabinClassType['id']) => {
      const cabinClass = cabinClassesOptions.find((option) => option.id === id)

      onChange(cabinClass!)
      onClose()
    },
    [cabinClassesOptions, onChange, onClose],
  )

  const options = cabinClassesOptions.map((cabinClass) => ({
    id: cabinClass.id,
    name: getCabinClassLabel(cabinClass.id),
    ariaLabel:
      cabinClass.id === pickedCabinClass.id
        ? t(i18Base + '.SelectedCabinClass', { label: getCabinClassLabel(cabinClass.id) })
        : t(i18Base + '.SelectCabinClass', { label: getCabinClassLabel(cabinClass.id) }),
    icon: (
      <Radio name="cabin-class" isChecked={false} onChange={() => handleChange(cabinClass.id)} />
    ),
  }))

  return (
    <Menu<Options>
      openByClickOnEl={
        <>
          <Text variant="linkNormal" color="primary">
            {getCabinClassLabel(pickedCabinClass.id)}
          </Text>

          <ChevronDownIcon name="chevronDownPWA" color="primary" size="small" />
        </>
      }
      shouldCloseOnClickOutside
      options={options}
      activeOptionId={pickedCabinClass.id}
      activeOptionIcon={
        <Radio name="cabin-class" isChecked onChange={() => handleChange(pickedCabinClass.id)} />
      }
      onOptionSelect={handleChange}
      data-tracking-id="cabin-class"
      tabIndex={-1}
    />
  )
}
