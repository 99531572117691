import { useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { FareSegmentSeatRows } from '@fiji/types'
import type { AdaptedFlightSegment } from '@etta/components/flight-details/types'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import type { SeatMapMode, SegmentSeatNumber } from '../air-seat-map.types'
import { useSegmentSeats } from './use-segment-seats'
import { getSeatsForApprove } from './get-seats-for-approve'

type Props = {
  segmentsSeatRows: FareSegmentSeatRows[]
  initialSegmentSeatNumberList: SegmentSeatNumber[]
  onApprove: (segmentSeatNumbers: SegmentSeatNumber[]) => void
  selectedSegmentId: string
  onSkip?: () => void
  onSelectedSegmentIdChange?: (selectedSegmentId: string) => void
  mode: SeatMapMode
  adaptedFlightSegments: AdaptedFlightSegment[]
  isReadonly: boolean
}

export function useAirSeatMap({
  selectedSegmentId,
  initialSegmentSeatNumberList,
  segmentsSeatRows,
  onApprove,
  onSelectedSegmentIdChange,
  mode,
  onSkip,
  adaptedFlightSegments,
  isReadonly,
}: Props) {
  const { t } = useTranslation()
  const [footerHeight, setFooterHeight] = useState(0)
  const unavailableSegmentsSeatRows = useMemo(
    () => segmentsSeatRows.filter((item) => item.readOnly).map(({ segmentId }) => segmentId),
    [segmentsSeatRows],
  )

  const { seatMapActions } = useSeatMapContext()

  const isAddMode = mode === 'add'
  const [lastAdaptedSegment] = adaptedFlightSegments.slice(-1)
  const destinationCity = lastAdaptedSegment?.arrivalCity || ''

  const initialSelectedSegmentSeats = useMemo(() => {
    return initialSegmentSeatNumberList.filter(
      (item) => !unavailableSegmentsSeatRows.includes(item.segmentId),
    )
  }, [initialSegmentSeatNumberList, unavailableSegmentsSeatRows])

  const {
    selectedSegmentsSeatNumbers,
    approvedSegmentsSeatNumbers,
    onSeatChecked,
    onSeatApprove,
    onNextSegment,
    isSeatAssignLoading,
    isSeatAssignFailed,
    onSeatAssignFailedContinue,
    isSeatSelectionAvailable,
    hasNextSegment,
    skipSeatSelection,
    finishAllSeatsSelection,
    selectAndApproveSeat,
    moveToNextSeatSelection,
  } = useSegmentSeats({
    initialSelectedSegmentSeats,
    selectedSegmentId,
    segmentsSeatRows,
    onApprove,
    onSelectedSegmentIdChange,
  })

  const { seatRowsBySegment, seatRowsBySegmentErrorOccurred } = useMemo(() => {
    const { seatRows: seatRowsBySegment = [], meta } =
      segmentsSeatRows.find(({ segmentId }) => segmentId === selectedSegmentId) || {}
    return { seatRowsBySegment, seatRowsBySegmentErrorOccurred: Boolean(meta?.errorOccurred) }
  }, [segmentsSeatRows, selectedSegmentId])

  const seatsForApprove = getSeatsForApprove({
    selectedSegmentId,
    seatRowsBySegment,
    selectedSegmentsSeatNumbers,
  })

  const seatNumbersBySegmentId = useMemo(() => {
    return selectedSegmentsSeatNumbers.filter((seats) => seats.segmentId === selectedSegmentId)
  }, [selectedSegmentId, selectedSegmentsSeatNumbers])

  const headerButtonTitle = isAddMode ? t('AirSeatMap.Skip') : t('AirSeatMap.Done')
  const onHeaderButtonClick = () => {
    if (isAddMode) {
      onSkip?.()
      return
    }
    onNextSegment()
  }

  const headerTitle = isAddMode ? t('AirSeatMap.SelectSeats') : t('AirSeatMap.EditSeats')
  const headline = isAddMode
    ? t('FlightDetailsModal.SelectSeatsTabContent.SelectFlight')
    : t('FlightDetailsModal.SelectSeatsTabContent.FlightTo', { destinationCity })

  const onChangeFooterHeight = useCallback(
    (newFooterHeight: number) => {
      setFooterHeight(newFooterHeight)
    },
    [setFooterHeight],
  )

  const isInstantPurchaseCarrier = adaptedFlightSegments.find(({ id }) => id === selectedSegmentId)
    ?.isInstantPurchaseCarrier

  const isSeatMapAvailable = !!seatRowsBySegment.length && !isInstantPurchaseCarrier

  const hasFooter = isSeatMapAvailable && !isReadonly

  useEffect(() => {
    seatMapActions.handleSetIsReadonly(isReadonly)
  }, [seatMapActions, isReadonly])

  return {
    headerTitle,
    headline,
    headerButtonTitle,
    onHeaderButtonClick,
    onSeatChecked,
    onSeatApprove,
    seatsForApprove,
    seatRowsBySegment,
    seatRowsBySegmentErrorOccurred,
    seatNumbersBySegmentId,
    isSeatSelectionAvailable,
    onNextSegment,
    isSeatAssignLoading,
    isSeatAssignFailed,
    onSeatAssignFailedContinue,
    footerHeight,
    onChangeFooterHeight,
    approvedSegmentsSeatNumbers,
    selectedSegmentsSeatNumbers,
    hasNextSegment,
    hasFooter,
    skipSeatSelection,
    finishAllSeatsSelection,
    selectAndApproveSeat,
    moveToNextSeatSelection,
    isSeatMapAvailable,
  }
}
