import styled from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

type SeatItemLabelProps = {
  isAvailable: boolean
}

export const DummySeatItem = styled.label`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
`

export const Input = styled.input``

export const SeatItemLabel = styled(DummySeatItem)<SeatItemLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  cursor: ${(props) => (props.isAvailable ? 'pointer' : 'default')};

  ${Input} {
    cursor: ${(props) => (props.isAvailable ? 'pointer' : 'default')};
    position: absolute;
    outline: none;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${getKeyboardNavigationStyle({ borderRadius: 5 })}
`

export const FloatingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`

export const SeatIconWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`
