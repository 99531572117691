import styled, { css } from 'styled-components'
import { Text as EttaText } from '@etta/ui/text'
import type { TextProps } from '@etta/ui/text'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.seatMap.charcoal7};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const FlightImage = styled.img`
  max-width: 250px;
  width: 100%;
  height: auto;
  margin-bottom: 12px;
`

export const Text = styled(EttaText).attrs<TextProps, TextProps>((props) => ({
  ...props,
  color: 'bodyText',
  align: 'center',
}))`
  white-space: pre-line;
  width: 230px;
  margin-bottom: 15px;
`

export const OldSeatMapErrorContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const EmptyContainer = styled.div<{
  marginAuto?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px;
  ${(p) =>
    p.marginAuto
      ? css`
          margin: auto;
        `
      : css`
          margin-top: 60px;
        `}
`

export const EmptyButton = styled(Button)`
  min-width: 132px;
`
