import styled, { css } from 'styled-components'
import { screenMatcher, ScreenType } from '@fiji/modes'

const MOBILE_HEADER_HEIGHT = '56px'
const MOBILE_FOOTER_HEIGHT = '63px'

const TAB_ROW_HEIGHT = '44px'
const BORDER_HEIGHT = '3px'

// Since we expect to have identical seat map on both mobile and desktop,
// this function was made as a replacement for the layout component.
// If in the future we do need to have different seat maps for different screen sizes,
// please create a proper layout
function getSeatMapHeight() {
  return screenMatcher.getScreenType() === ScreenType.Mobile
    ? css`
        min-height: calc(
          100vh - ${TAB_ROW_HEIGHT} - ${MOBILE_HEADER_HEIGHT} - ${MOBILE_FOOTER_HEIGHT} -
            ${BORDER_HEIGHT}
        );
      `
    : css`
        height: 100%;
      `
}

export const Container = styled.div`
  ${getSeatMapHeight}
  width: 100%;
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  padding: 0 10px;
`

export const SeatCabinClass = styled.div<{ isNewSeatMap?: boolean }>`
  ${getSeatMapHeight}
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 12px solid ${(props) => props.theme.colors.seatMap.charcoal7};
  border-left: 12px solid ${(props) => props.theme.colors.seatMap.charcoal7};
  ${({ isNewSeatMap }) => isNewSeatMap && 'padding-bottom: 120px'};
`

export const ZoomAnchor = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
`
