import { Result } from 'fnscript'
import type { PromisedResult } from '@etta/core/type-utils'
import { Adapter, Inject } from '@etta/di'
import type { EttaMapErrorsInstances } from '../core/errors/etta-map.errors'
import { EttaMapErrors } from '../core/errors/etta-map.errors'
import { EttaMapDataProvider } from './etta-map.data-provider/etta-map.data-provider'

@Adapter()
export class EttaMapAdapter {
  constructor(@Inject() private ettaMapDataProvider: EttaMapDataProvider) {}

  async createAppleMapToken(): PromisedResult<string, EttaMapErrorsInstances> {
    try {
      const { data, errors } = await this.ettaMapDataProvider.createAppleMapToken()

      if (errors) {
        return Result.Err(new EttaMapErrors.CreateTokenError(errors))
      }
      if (!data?.createMapKitToken.token) {
        return Result.Err(new EttaMapErrors.CreateTokenError('No token returned'))
      }

      return Result.Ok(data.createMapKitToken.token)
    } catch (error) {
      return Result.Err(new EttaMapErrors.CreateTokenUnexpectedError(error))
    }
  }
}
