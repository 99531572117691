import styled from 'styled-components'

export const Container = styled.div``

export const LeftContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
`

export const BlockWithBorderBottom = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding: 22px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const IconWithBorderBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 22px 22px 22px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`
