import type { AirSearchQueryType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { deleteTimezone } from '@fiji/utils/dates'
import { PostBookingAction } from '@fiji/enums'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { getFlightSearchRestrictions } from '@fiji/utils/get-flight-search-restrictions'
import type { CabinClassType, TimeRange } from '@fiji/types'
import { getRestrictedAirCarrierFilters } from '@fiji/utils/get-restricted-air-filters'
import { resolveCabinClass } from '../helpers/resolve-cabin-class'
import { resolvePlaces } from '../helpers/resolve-prefill-airports'

export type Args = {
  itineraryId: string
  processId: string
  departureFlight?: FlightPostBookingValueObject
  returnFlight?: FlightPostBookingValueObject
  flightSegments: FlightPostBookingValueObject[]
  isFlightChangeCarrierAllowed: boolean
  isFlightChangeDestinationAllowed: boolean
  leg0Time: TimeRange
  leg1Time: TimeRange
  cabinClassesOptions: CabinClassType[]
}

export function getChangeQueryParams({
  itineraryId,
  processId,
  departureFlight,
  returnFlight,
  flightSegments,
  isFlightChangeCarrierAllowed,
  isFlightChangeDestinationAllowed,
  leg0Time,
  leg1Time,
  cabinClassesOptions,
}: Args): Partial<AirSearchQueryType> {
  const searchRestrictions = getFlightSearchRestrictions({
    isChangeCarrierAllowed: isFlightChangeCarrierAllowed,
    isChangeDestinationAllowed: isFlightChangeDestinationAllowed,
    flightSegments,
    isPostBooking: !!processId,
  })

  const { filtersForFirstLeg, filtersForReturnLeg } = getRestrictedAirCarrierFilters({
    searchRestrictions,
    legs: flightSegments,
  })

  return {
    itineraryId,
    bookingId: processId,
    flights: [
      {
        id: 1,
        originPlace: resolvePlaces(departureFlight?.segments)?.originPlace,
        destinationPlace: resolvePlaces(departureFlight?.segments)?.destinationPlace,
        departureDate: departureFlight?.departureDateTime
          ? new Date(deleteTimezone(departureFlight.departureDateTime))
          : undefined,
        returnDate: returnFlight?.arrivalDateTime
          ? new Date(deleteTimezone(returnFlight.arrivalDateTime))
          : undefined,
        cabinClass: resolveCabinClass({
          segments: departureFlight?.segments,
          options: cabinClassesOptions,
        }),
        timeRange: leg0Time,
        returnTimeRange: leg1Time,
      },
      {
        id: 2,
        originPlace: resolvePlaces(returnFlight?.segments)?.destinationPlace,
        destinationPlace: resolvePlaces(returnFlight?.segments)?.originPlace,
        departureDate: returnFlight?.departureDateTime
          ? new Date(deleteTimezone(returnFlight.departureDateTime))
          : undefined,
        returnDate: returnFlight?.arrivalDateTime
          ? new Date(deleteTimezone(returnFlight.arrivalDateTime))
          : undefined,
        cabinClass: resolveCabinClass({
          segments: returnFlight?.segments,
          options: cabinClassesOptions,
        }),
        timeRange: leg1Time,
        returnTimeRange: leg0Time,
      },
    ],
    flightType: returnFlight ? FlightType.Round : FlightType.OneWay,
    searchRestrictions,
    filters: {
      airlines: filtersForFirstLeg,
      destinationAirports: [],
      originTime: null,
      destinationTime: null,
      fareTypes: [],
      originAirports: [],
      stops: [],
      isMatchedWithPolicy: false,
    },
    nextLegFilters: {
      airlines: filtersForReturnLeg,
      destinationAirports: [],
      originTime: null,
      destinationTime: null,
      fareTypes: [],
      originAirports: [],
      stops: [],
      isMatchedWithPolicy: false,
    },
    postBookingAction: PostBookingAction.Modify,
    isReturnFlightChangeOnly: false,
  }
}
