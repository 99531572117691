import { MediaLayout } from '@etta/ui/media-layout'
import type { SafetyCheckFlight } from '@fiji/types'
import { ExpandedViewMobile, ExpandedViewDesktop } from './layout'

type Props = {
  flightSafetyChecks: SafetyCheckFlight[]
  isExpanded?: boolean
  handleExpand?: () => void
  onModalOpenClick?: () => void
  hasDetails?: boolean
}

export function ExpandedView({
  isExpanded,
  handleExpand,
  flightSafetyChecks,
  onModalOpenClick,
  hasDetails,
}: Props) {
  return (
    <MediaLayout
      mobileSlot={
        onModalOpenClick &&
        handleExpand && (
          <ExpandedViewMobile
            isExpanded={!!isExpanded}
            handleExpand={handleExpand}
            flightSafetyChecks={flightSafetyChecks}
            onModalOpenClick={onModalOpenClick}
            hasDetails={!!hasDetails}
          />
        )
      }
      desktopSlot={
        <ExpandedViewDesktop
          flightSafetyChecks={flightSafetyChecks}
          hasDetails={!!hasDetails}
          onModalOpenClick={onModalOpenClick}
        />
      }
    />
  )
}
