import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

export const ScreenFooterContent = styled.div<{ isVisible: boolean }>`
  width: 100%;
  flex-shrink: 0;
  align-self: flex-start;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 200ms linear;
`

export const FooterContainer = styled.div`
  width: 100%;
  padding: 0 16px;
`

export const Title = styled((p) => <Text {...p} />).attrs<TextProps, TextProps>(() => ({
  variant: 'bodyLarge',
  color: 'mainText',
}))`
  margin-bottom: 8px;
`

export const StyledText = styled((p) => <Text {...p} />).attrs<TextProps, TextProps>(() => ({
  variant: 'footnoteMedium',
  color: 'mainText',
}))`
  margin-bottom: 32px;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.borderDark};
  margin-bottom: 16px;
`

export const ActionButton = styled(Button)`
  width: 100%;
`
