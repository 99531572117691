import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const AmenitiesContainer = styled.div<{
  isShowDetails: boolean
}>`
  margin-top: 5px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.isShowDetails &&
    css`
      margin-top: 18px;
      padding-top: 16px;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid ${(props) => props.theme.colors.background1};
      overflow-y: auto;
      flex: 1;
    `}
`

export const ButtonContainer = styled.div`
  margin-left: -1.6rem;
`

export const Item = styled.div<{ isShowDetails?: boolean }>`
  display: flex;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.bodyText};
  align-items: flex-start;
  ${footnoteMedium};
  margin-right: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.isShowDetails &&
    css`
      margin-bottom: 7px;
    `}
`
