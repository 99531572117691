import { useCallback, useMemo } from 'react'
import type { Place, PlainTime as Time } from '@fiji/types'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { appMode } from '@fiji/modes'

export type Props = {
  pickUpPlace?: Place
  dropOffPlace?: Place
  pickUpDate?: Date
  pickUpTime?: Time
}

const BASE_UBER_UNIVERSAL_LINK = 'https://m.uber.com/ul/'

export function useUberUniversalLink(props?: Props) {
  const uberUrl = useMemo(() => {
    const clientId = window._config.uberClientId
    const params = new URLSearchParams()

    if (!props) {
      return `${BASE_UBER_UNIVERSAL_LINK}?action=setPickup`
    }

    const { pickUpPlace, dropOffPlace, pickUpDate, pickUpTime } = props
    if (pickUpPlace && dropOffPlace) {
      params.append('client_id', `${clientId}`)
      params.append('pickup[latitude]', `${pickUpPlace.latitude}`)
      params.append('pickup[longitude]', `${pickUpPlace.longitude}`)
      params.append('pickup[nickname]', `${pickUpPlace.name}`)
      params.append('dropoff[latitude]', `${dropOffPlace.latitude}`)
      params.append('dropoff[longitude]', `${dropOffPlace.longitude}`)
      params.append('dropoff[nickname]', `${dropOffPlace.name}`)

      if (pickUpDate && pickUpTime) {
        const dateTime = new Date(pickUpDate.setUTCHours(pickUpTime.hours, pickUpTime.minutes))
        const pickUpDateTime = getDateWithoutTimezoneOffset(dateTime).toISOString()
        params.append('datetime[formatted_date]', `${pickUpDateTime}`)
      }
    }
    return `${BASE_UBER_UNIVERSAL_LINK}?action=setPickup&${params.toString()}`
  }, [props])

  const openUrlWithTargetBlank = useCallback((url: string) => {
    const { isOpenInIOS } = appMode
    if (isOpenInIOS) {
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.target = '_blank'
      anchor.click()
    } else {
      document.location.href = url
    }
  }, [])

  const openUberAppWithoutParams = useCallback(() => {
    openUrlWithTargetBlank(BASE_UBER_UNIVERSAL_LINK)
  }, [openUrlWithTargetBlank])

  const openUberApp = useCallback(() => {
    openUrlWithTargetBlank(uberUrl)
  }, [uberUrl, openUrlWithTargetBlank])

  return {
    uberUrl,
    uberUrlWithoutParams: BASE_UBER_UNIVERSAL_LINK,
    openUberAppWithoutParams,
    openUberApp,
  }
}
