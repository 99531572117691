export default function CopyIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.9628 8.20867C30.8396 8.07565 30.6635 8 30.4783 8H19.3913C18.3126 8 17.4348 8.84857 17.4348 9.8913V13.0435H8.95652C7.87783 13.0435 7 13.892 7 14.9348V35.1087C7 36.1514 7.87783 37 8.95652 37H24.6087C25.6874 37 26.5652 36.1514 26.5652 35.1087V31.9565H35.0435C36.1222 31.9565 37 31.108 37 30.0652V16.1957V14.9348C37 14.7791 36.9407 14.629 36.8324 14.513L30.9628 8.20867ZM29.8261 9.26087H30.188L35.6957 15.1769V15.5652H29.8261V9.26087ZM25.2609 20.6087H18.7391V14.3043H19.1213L25.2609 20.2393V20.6087ZM25.2609 35.1087C25.2609 35.4561 24.968 35.7391 24.6087 35.7391H8.95652C8.59717 35.7391 8.30435 35.4561 8.30435 35.1087V14.9348C8.30435 14.5874 8.59717 14.3043 8.95652 14.3043H17.4348V21.2391C17.4348 21.5871 17.727 21.8696 18.087 21.8696H25.2609V35.1087ZM35.0435 30.6957H26.5652V19.9783C26.5652 19.8112 26.4967 19.6504 26.3741 19.5325L19.8524 13.2282C19.7304 13.1097 19.5641 13.0435 19.3913 13.0435H18.7391V9.8913C18.7391 9.54393 19.032 9.26087 19.3913 9.26087H28.5217V16.1957C28.5217 16.5437 28.8139 16.8261 29.1739 16.8261H35.6957V30.0652C35.6957 30.4126 35.4028 30.6957 35.0435 30.6957Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
