import type { TFunction } from 'i18next'
import { SeatFeatures } from '@etta/modules/seat-map/core/enums'
import type { SegmentSeatNumber } from './air-seat-map.types'

const seatFeaturesLocales: Record<SeatFeatures, string> = {
  [SeatFeatures.NoFeature]: '',
  [SeatFeatures.PaidPremium]: 'AirSeatMap.SeatTitle.Premium',
  [SeatFeatures.Premium]: 'AirSeatMap.SeatTitle.Premium',
  [SeatFeatures.Paid]: 'AirSeatMap.SeatTitle.Paid',
  [SeatFeatures.ExitRowSeat]: 'AirSeatMap.SeatTitle.ExitSeat',
  [SeatFeatures.OverWing]: 'AirSeatMap.SeatTitle.OverWing',
  [SeatFeatures.NearWindow]: 'AirSeatMap.SeatTitle.WindowSeat',
}

export function getSeatFeaturedTitle(seat: SegmentSeatNumber, t: TFunction): string {
  const { seatNumber, seatFeature } = seat

  if (!seatFeature || seatFeature === SeatFeatures.NoFeature) {
    return seatNumber
  }

  const featureText = t(seatFeaturesLocales[seatFeature])
  return `${featureText} - ${seatNumber}`
}
