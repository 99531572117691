enum ShareTravelerContact {
  PROVIDE_ALL = 'PROVIDE_ALL',
  PROVIDE_EMAIL = 'PROVIDE_EMAIL',
  PROVIDE_PHONE = 'PROVIDE_PHONE',
  PROVIDE_NONE = 'PROVIDE_NONE',
}

namespace ShareTravelerContact {
  export function getBy(value: string): ShareTravelerContact | null {
    if (Object.values(ShareTravelerContact).some((stc) => stc === value)) {
      return value as ShareTravelerContact
    }
    return null
  }
}

export { ShareTravelerContact }
