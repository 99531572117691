import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 14px;
  background: ${(props) => props.theme.colors.white};
`

export const MapWrapper = styled.div`
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  height: 130px;
  overflow: hidden;
`
