import { Icon } from '@etta/ui/icon'
import { useScreenType } from '@fiji/modes'
import type { AddButtonSettings } from '../types'
import { Container, Text } from './trip-details-add-button-styled'
import { useTripDetailsAddButton } from './use-trip-details-add-button'

export function TripDetailsAddButton({ type, isDisabled, onClick }: AddButtonSettings) {
  const { label, ariaLabel, iconName, iconColor } = useTripDetailsAddButton({ type, isDisabled })
  const screenType = useScreenType()

  return (
    <Container
      tabIndex={0}
      screenType={screenType}
      disabled={isDisabled}
      onClick={onClick}
      aria-label={ariaLabel}
      data-tracking-id={`trip-details-add-button_${label}`}>
      <Icon name={iconName} size="medium" color={iconColor} />
      <Text>{label}</Text>
    </Container>
  )
}
