import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { CancellationPolicy, Maybe, Money } from '@fiji/graphql/types'
import { RoomType } from '@fiji/graphql/types'
import { useHotelCancellationLabel } from '@fiji/hooks/use-hotel-cancellation-label'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { useUserContext } from '@etta/modules/user'
import type { RoomDetailsType } from '../types'

type Args = {
  room?: RoomType | null
  cancellationPolicyDescription?: string[] | null
  checkIn?: string | null
  checkOut?: string | null
  roomDetails: RoomDetailsType
  feesAndTaxes?: Maybe<Money>
  cancellationPolicy: CancellationPolicy
  totalCost?: Money
}

const i18nBase = 'PostBooking.HotelDetails'

export function usePrice({
  cancellationPolicyDescription,
  checkIn,
  checkOut,
  room,
  roomDetails,
  feesAndTaxes,
  cancellationPolicy,
  totalCost,
}: Args) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const { locale } = userStore

  const { cancellationPolicyLabel } = useHotelCancellationLabel({
    cancellationPolicy,
    cancellationPolicyDescription,
  })

  const roomType = room || RoomType.Unknown
  const totalDays = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )

  const rateToShow = useMemo(() => {
    if (totalCost) {
      const roomSubtotal: Money = {
        amount: feesAndTaxes ? totalCost?.amount - feesAndTaxes?.amount : totalCost?.amount,
        currency: totalCost?.currency || roomDetails?.roomAverage?.currency || '',
      }
      return formatMoney(roomSubtotal, { locale })
    }
    if (roomDetails?.roomAverage) {
      return formatMoney(roomDetails.roomAverage, { locale })
    }
    return ''
  }, [totalCost, roomDetails.roomAverage, feesAndTaxes, locale])

  const totalPriceValue = useMemo(() => {
    if (totalCost) {
      return formatMoney(totalCost, { locale })
    }
    return ''
  }, [locale, totalCost])

  const priceDetailsList = useMemo(() => {
    return [
      {
        title: `${roomType && t(`${i18nBase}.Room`, { roomType })} (${
          totalDays && t(`${i18nBase}.Nights`, { count: totalDays })
        })`,
        value: rateToShow,
      },
      {
        title: t(`${i18nBase}.Taxes`),
        value: feesAndTaxes
          ? formatMoney(feesAndTaxes, { locale })
          : t(`${i18nBase}.TaxesAndFeesIncluded`),
      },
    ]
  }, [feesAndTaxes, locale, rateToShow, roomType, t, totalDays])

  return {
    priceDetailsList,
    cancellationPolicyLabel,
    totalPriceValue,
  }
}
