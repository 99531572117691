import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetSupportArticlesDocument } from '@fiji/graphql/hooks'
import type { GetSupportArticlesQuery, GetSupportArticlesQueryVariables } from '@fiji/graphql/types'

@DataProvider()
export class SupportTravelDataProvider extends GraphqlDataProvider {
  private _getSupportArticlesController: AbortController | null = null

  getSupportArticles(input: GetSupportArticlesQueryVariables['input']) {
    const controller = new AbortController()
    this._getSupportArticlesController?.abort()
    this._getSupportArticlesController = controller

    return this.client.query<GetSupportArticlesQuery, GetSupportArticlesQueryVariables>({
      query: GetSupportArticlesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }
}
