import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { SubmitSectionMobile } from './mobile'
import { SubmitSectionDesktop } from './desktop'

export function SubmitSectionLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<SubmitSectionMobile {...props} />}
      desktopSlot={<SubmitSectionDesktop {...props} />}
    />
  )
}
