import cardValidator from 'card-validator'
import { Store } from '@etta/di'
import i18n from '@fiji/i18n'
import { CreditCardBrandType } from '@etta/core/enums'
import { Toggle } from '@etta/interface/services/toggle'
import { Validator } from '@etta/interface/services/validator'
import { OptionViewTypes } from '@etta/ui/drop-down-list'
import { getBrandTypeByCardNumber } from '@fiji/utils/credit-card/get-brand-type'
import type { PreferredCreditCardValueObject } from '@etta/modules/user'
import { PreferredCreditCardSegment } from '@etta/modules/user/core/enums/preferred-credit-card-segment.enum'
import { AddressFields } from '../../core/enums/address-fields'
import { PreExpirationAlertValues } from '../../core/entities/credit-cards.entity'
import type {
  UserCreditCardEntity,
  PreferredCreditCardsForSegments,
  SelectedCreditCard,
  PreferredSiteCardsForSegments,
} from '../../core/entities/credit-cards.entity'
import type { SiteCardEntity } from '../../core/entities/site-cards.entity'

export type GetPreferredCreditCardsArgs = {
  creditCards?: UserCreditCardEntity[] | null
  preferredCreditCards?: PreferredCreditCardValueObject[] | null
}

type Option = {
  value: string
  label: string
  viewType: OptionViewTypes.OptionWithCheckboxIcon
}

const CardTypes = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  AmericanExpress: 'AmericanExpress',
  DiscoverCard: 'DiscoverCard',
  JCB: 'JCB',
  DinersClub: 'DinersClub',
  CarteBlanche: 'CarteBlanche',
  Eurocard: 'Eurocard',
  UniversalAirTravelCard: 'UniversalAirTravelCard',
  Other: 'Other',
}

const i18nBase = `Settings.PaymentInformation.`
const i18BaseErrors = 'CreditCardForm.Errors.'

@Store()
export class PaymentInformationStore {
  CENTURY_DIGITS = '20'
  creditCardsToggle = new Toggle()
  preferredCardsToggle = new Toggle()
  newCardPresetsToggle = new Toggle()
  creditCardsActionToggle = new Toggle()
  deleteCardActionToggle = new Toggle()
  isCreditCardsLoading = false
  isCreditCardsError = false
  isSiteCardsError = false
  isSiteCardsLoading = false
  filteredCreditCards: UserCreditCardEntity[] | null | undefined = null
  creditCards: UserCreditCardEntity[] | null | undefined = null
  siteCards: SiteCardEntity[] | null | undefined = null
  filteredSiteCards: SiteCardEntity[] | null | undefined = null
  preferredCreditCardsBySegment: PreferredCreditCardsForSegments = {
    flight: null,
    car: null,
    hotel: null,
    rail: null,
  }
  preferredSiteCardsBySegment: PreferredSiteCardsForSegments = {
    flight: false,
    car: false,
    hotel: false,
    rail: false,
  }
  selectedPreferredCardsBySegment: PreferredCreditCardsForSegments = {
    flight: null,
    car: null,
    hotel: null,
    rail: null,
  }
  selectedCardId?: string | null = null
  selectedCardExpiration: string | null = null

  editedCardPart?: boolean
  editedProfilePart?: boolean

  selectedCreditCard: SelectedCreditCard | null = null
  selectedPreferenceForUpdate = {
    flight: false,
    car: false,
    hotel: false,
    rail: false,
  }

  preferredCardsOptions: Option[] = []
  isWarningActive: boolean = false
  creditCardSearch: string = ''
  companyCardLabel = i18n.t('Settings.PaymentInformation.CompanyCard')

  isSaveButtonDisabled = true

  get isEditCard() {
    return !!this.selectedCardId && this.numberValidator.values.number.length === 4
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }

  isCardExpired = (card: UserCreditCardEntity) => {
    return !cardValidator.expirationDate(card.expirationDate).isValid
  }

  hasBillingAddress = (card: UserCreditCardEntity) => {
    return [
      AddressFields.Country,
      AddressFields.State,
      AddressFields.Zip,
      AddressFields.City,
      AddressFields.Street1,
    ].every((field) => card.billingAddress?.[field])
  }

  setCreditCards(cards: UserCreditCardEntity[] | null) {
    this.creditCards = cards
  }

  setSiteCards(cards: SiteCardEntity[] | null) {
    this.siteCards = cards?.map((card) => ({
      ...card,
      genericLabel: this.companyCardLabel,
    }))
  }

  setSelectedCardId(id?: string | null) {
    this.selectedCardId = id
  }

  setEditedCardPart(value: boolean) {
    this.editedCardPart = value
  }
  setEditedProfilePart(value: boolean) {
    this.editedProfilePart = value
  }

  setSelectedCardExpiration(exp?: string | null) {
    const getStandardizedExpiration = () => {
      switch (true) {
        case exp === PreExpirationAlertValues.Month1:
          return PreExpirationAlertValues.Month1
        case exp === PreExpirationAlertValues.Month3:
          return PreExpirationAlertValues.Month3
        case exp === PreExpirationAlertValues.Month6:
          return PreExpirationAlertValues.Month6
        case exp === PreExpirationAlertValues.Month9:
          return PreExpirationAlertValues.Month9
        case exp === PreExpirationAlertValues.Year1:
          return PreExpirationAlertValues.Year1
        default:
          return PreExpirationAlertValues.Never
      }
    }

    this.setEditedProfilePart(true)
    this.selectedCardExpiration = getStandardizedExpiration()
  }

  setSelectedCardForEdit({
    card,
    preferred,
    expired,
  }: {
    card: UserCreditCardEntity
    preferred?: PreferredCreditCardSegment[]
    expired?: string | null
  }) {
    const {
      name,
      cardNumberLast4,
      brandType,
      expirationDate,
      label,
      billingAddress,
      fullLegacyId,
    } = card
    const { countryCode, stateCode, postalCode, city, street1, street2 } = billingAddress || {}

    name && this.nameOnCardValidator.onFieldChange('nameOnCard', name)
    cardNumberLast4 && this.numberValidator.onFieldChange('number', cardNumberLast4)
    brandType && this.cardTypeValidator.onFieldChange('cardType', brandType)
    expirationDate &&
      this.expirationValidator.onFieldChange(
        'expiration',
        expirationDate.slice(0, 2) + ' / ' + expirationDate.slice(2, 4),
      )
    label && this.nicknameValidator.onFieldChange('nickname', label)
    this.setSelectedCardId(fullLegacyId)

    countryCode && this.countryCodeValidator.onFieldChange(AddressFields.Country, countryCode)
    stateCode && this.stateCodeValidator.onFieldChange(AddressFields.State, stateCode)
    postalCode && this.postalCodeValidator.onFieldChange(AddressFields.Zip, postalCode)
    city && this.cityValidator.onFieldChange(AddressFields.City, city)
    street1 && this.street1Validator.onFieldChange(AddressFields.Street1, street1)
    street2 && this.street2Validator.onFieldChange(AddressFields.Street2, street2)

    expired && this.setSelectedCardExpiration(expired)

    preferred?.includes(PreferredCreditCardSegment.Flight) &&
      this.setSelectedPreferenceForUpdate('flight', true)
    preferred?.includes(PreferredCreditCardSegment.Hotel) &&
      this.setSelectedPreferenceForUpdate('hotel', true)
    preferred?.includes(PreferredCreditCardSegment.CarRental) &&
      this.setSelectedPreferenceForUpdate('car', true)
    preferred?.includes(PreferredCreditCardSegment.Rail) &&
      this.setSelectedPreferenceForUpdate('rail', true)

    this.setEditedCardPart(false)
    this.setEditedProfilePart(false)
    this.creditCardsActionToggle.handleOpen()
  }

  resetSelectedCardForEdit() {
    this.nameOnCardValidator.onFieldChange('nameOnCard', '')
    this.numberValidator.onFieldChange('number', '')
    this.cardTypeValidator.onFieldChange('cardType', '')
    this.cvvValidator.onFieldChange('cvv', '')
    this.expirationValidator.onFieldChange('expiration', '')
    this.nicknameValidator.onFieldChange('nickname', '')

    this.countryCodeValidator.onFieldChange(AddressFields.Country, '')
    this.stateCodeValidator.onFieldChange(AddressFields.State, '')
    this.postalCodeValidator.onFieldChange(AddressFields.Zip, '')
    this.cityValidator.onFieldChange(AddressFields.City, '')
    this.street1Validator.onFieldChange(AddressFields.Street1, '')
    this.street2Validator.onFieldChange(AddressFields.Street2, '')
    this.setSelectedCardId(null)

    this.setSelectedCardExpiration(null)

    this.setSelectedPreferenceForUpdate('flight', false)
    this.setSelectedPreferenceForUpdate('hotel', false)
    this.setSelectedPreferenceForUpdate('car', false)
    this.setSelectedPreferenceForUpdate('rail', false)

    this.setEditedCardPart(false)
    this.setEditedProfilePart(false)

    this.setSelectedCardId(null)
  }

  setSelectedPreferenceForUpdate = (field: 'flight' | 'car' | 'hotel' | 'rail', value: boolean) => {
    const newSelectedPreferenceForUpdate = {
      ...this.selectedPreferenceForUpdate,
      [field]: value,
    }
    this.selectedPreferenceForUpdate = newSelectedPreferenceForUpdate
    this.setEditedProfilePart(true)
  }

  setFilteredCreditCards(cards: UserCreditCardEntity[] | null) {
    this.filteredCreditCards = cards
  }

  setFilteredSiteCards(cards: SiteCardEntity[] | null) {
    this.filteredSiteCards = cards
  }

  setIsCreditCardsLoading(isLoading: boolean) {
    this.isCreditCardsLoading = isLoading
  }

  setIsSiteCardsLoading(isLoading: boolean) {
    this.isSiteCardsLoading = isLoading
  }

  setIsCreditCardError(isError: boolean) {
    this.isCreditCardsError = isError
  }

  setIsSiteCardError(isError: boolean) {
    this.isSiteCardsError = isError
  }

  setCreditCardsSearch(input: string) {
    this.creditCardSearch = input
  }

  setSelectedPreferredCard = (
    field: keyof PreferredCreditCardsForSegments,
    value: UserCreditCardEntity,
  ) => {
    this.selectedPreferredCardsBySegment = {
      ...this.selectedPreferredCardsBySegment,
      [field]: value,
    }
  }

  setCreditCardType(type: CreditCardBrandType | null) {
    if (!type || type === CreditCardBrandType.Other) {
      this.cardTypeValidator.onFieldChange('cardType', null)
      return
    }
    this.cardTypeValidator.onFieldChange('cardType', type)
  }

  resetSelectedPreferredCards = () => {
    this.selectedPreferredCardsBySegment = {
      flight: null,
      car: null,
      hotel: null,
      rail: null,
    }
  }

  setPreferredCreditCards = ({
    creditCards,
    preferredCreditCards,
  }: GetPreferredCreditCardsArgs) => {
    const flightCard =
      !this.preferredSiteCardsBySegment.flight &&
      preferredCreditCards?.find((card) =>
        card.segments.includes(PreferredCreditCardSegment.Flight),
      )?.id
    const hotelCard =
      !this.preferredSiteCardsBySegment.hotel &&
      preferredCreditCards?.find((card) => card.segments.includes(PreferredCreditCardSegment.Hotel))
        ?.id
    const carCard =
      !this.preferredSiteCardsBySegment.car &&
      preferredCreditCards?.find((card) =>
        card.segments.includes(PreferredCreditCardSegment.CarRental),
      )?.id
    const railCard = preferredCreditCards?.find((card) =>
      card.segments.includes(PreferredCreditCardSegment.Rail),
    )?.id

    this.preferredCreditCardsBySegment = {
      flight: creditCards?.find((card) => card.fullLegacyId === flightCard),
      car: creditCards?.find((card) => card.fullLegacyId === carCard),
      hotel: creditCards?.find((card) => card.fullLegacyId === hotelCard),
      rail: creditCards?.find((card) => card.fullLegacyId === railCard),
    }

    this.preferredCardsOptions = creditCards
      ?.map((card) => {
        if (card.fullLegacyId && card.label && !this.isCardExpired(card)) {
          return {
            label: card.label,
            value: card.fullLegacyId,
            viewType: OptionViewTypes.OptionWithCheckboxIcon,
          }
        }
      })
      .filter(Boolean) as Option[]
  }

  setPreferredSiteCards = (siteCards: SiteCardEntity[]) => {
    this.preferredSiteCardsBySegment = {
      flight: !!siteCards?.find(
        (card) => card.isActive && card.isRestricted && card.isEnabledForAir,
      ),
      hotel: !!siteCards?.find(
        (card) => card.isActive && card.isRestricted && card.isEnabledForHotel,
      ),
      car: !!siteCards?.find(
        (card) => card.isActive && card.isRestricted && card.isEnabledForCarRental,
      ),
      rail: false,
    }
  }

  getPreferredCardStatus = (id?: string | null) => {
    const status: string[] = []

    if (!id) {
      return []
    }

    const { flight, car, hotel } = this.preferredCreditCardsBySegment

    if (id === flight?.fullLegacyId) {
      status.push(i18n.t(i18nBase + 'Modal.Airlines'))
    }
    if (id === hotel?.fullLegacyId) {
      status.push(i18n.t(i18nBase + 'Modal.Hotel'))
    }
    if (id === car?.fullLegacyId) {
      status.push(i18n.t(i18nBase + 'Modal.CarRental'))
    }

    return status
  }

  getPreferredSiteCardStatus = (card: SiteCardEntity) => {
    const status: string[] = []

    const {
      isRestricted,
      isActive,
      isEnabledForAir,
      isEnabledForHotel,
      isEnabledForCarRental,
      isEnabledForCarService,
    } = card

    if (!isRestricted || !isActive) {
      return []
    }

    if (isEnabledForAir) {
      status.push(i18n.t(i18nBase + 'Modal.Airlines'))
    }
    if (isEnabledForHotel) {
      status.push(i18n.t(i18nBase + 'Modal.Hotel'))
    }
    if (isEnabledForCarRental) {
      status.push(i18n.t(i18nBase + 'Modal.CarRental'))
    }
    if (isEnabledForCarService) {
      status.push(i18n.t(i18nBase + 'Modal.Rail'))
    }
    return status
  }

  setDateFromString = (input?: string | null) => {
    if (input?.length !== 4) {
      return input
    }
    return `${input.substring(0, 2)}/${this.CENTURY_DIGITS}${input.substring(2, 4)}`
  }

  getCardTypeValues = (type: string) => {
    switch (true) {
      case type === CardTypes.Visa: {
        return CreditCardBrandType.Vi
      }
      case type === CardTypes.MasterCard: {
        return CreditCardBrandType.Ca
      }
      case type === CardTypes.AmericanExpress: {
        return CreditCardBrandType.Ax
      }
      case type === CardTypes.DiscoverCard: {
        return CreditCardBrandType.Ds
      }
      case type === CardTypes.JCB: {
        return CreditCardBrandType.Jc
      }
      case type === CardTypes.DinersClub: {
        return CreditCardBrandType.Dc
      }
      case type === CardTypes.CarteBlanche: {
        return CreditCardBrandType.Cb
      }
      case type === CardTypes.Eurocard: {
        return CreditCardBrandType.Ec
      }
      case type === CardTypes.UniversalAirTravelCard: {
        return CreditCardBrandType.Other
      }
      case type === CardTypes.Other: {
        return CreditCardBrandType.Other
      }
      default:
        return CreditCardBrandType.Other
    }
  }

  getCardTypes = () => {
    const cards = Object.keys(CardTypes)
    return cards.map((value) => ({
      label: i18n.t(i18nBase + 'CreditCardActions.Cards.' + value),
      value: this.getCardTypeValues(value),
      viewType: OptionViewTypes.OptionWithCheckboxIcon,
    }))
  }

  nameOnCardValidator = new Validator({
    nameOnCard: Validator.scheme.string().required(i18n.t(i18BaseErrors + 'Name')),
  })

  numberValidator = new Validator({
    number: Validator.scheme
      .string()
      .test('is-credit-card-number', i18n.t(i18BaseErrors + 'CardNumber.Invalid'), (value) => {
        return cardValidator.number(value).isValid
      })
      .test('is-card-type-invalid', i18n.t(i18BaseErrors + 'CardNumber.BrandInvalid'), (value) => {
        if (!value) {
          return true
        }
        return getBrandTypeByCardNumber(value) !== CreditCardBrandType.Other
      })
      .required(i18n.t(i18BaseErrors + 'CardNumber.Required')),
  })

  cardTypeValidator = new Validator({
    cardType: Validator.scheme.string().required(i18n.t(i18BaseErrors + `CardNumber.BrandInvalid`)),
  })

  expirationValidator = new Validator({
    expiration: Validator.scheme
      .string()
      .test(
        'is-expiration-date',
        i18n.t(i18BaseErrors + 'ExpirationDate.Invalid'),
        (value) => cardValidator.expirationDate(value).isValid,
      )
      .required(i18n.t(i18BaseErrors + 'ExpirationDate.Required')),
  })

  nicknameValidator = new Validator({
    nickname: Validator.scheme.string().required(i18n.t(i18BaseErrors + 'Label')),
  })

  cvvValidator = new Validator({
    cvv: Validator.scheme
      .string()
      .min(3)
      .required(i18n.t(i18BaseErrors + 'SecurityCode.Invalid')),
  })

  countryCodeValidator = new Validator({
    countryCode: Validator.scheme.string().optional(),
  })

  postalCodeValidator = new Validator({
    postalCode: Validator.scheme.string().optional(),
  })

  stateCodeValidator = new Validator({
    stateCode: Validator.scheme.string().optional(),
  })

  cityValidator = new Validator({
    city: Validator.scheme.string().optional(),
  })

  street1Validator = new Validator({
    street1: Validator.scheme.string().optional(),
  })

  street2Validator = new Validator({
    street2: Validator.scheme.string().optional(),
  })
}
