import styled, { css } from 'styled-components'
import type { TooltipAlign, TooltipPlace } from './types'

export const Anchor = styled.span`
  display: none;
`

export const Point = styled.div<{ left: number; top: number }>`
  position: fixed;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  width: 100%;
  z-index: 1000;
`

const calculateAlignX = (align: TooltipAlign) => {
  switch (align) {
    case 'center':
      return `translateX(-50%)`
    case 'left':
      return `translateX(-30px)`
    case 'right':
      return `translateX(calc(-100% + 30px))`
  }
}

export const Container = styled.div<{ place: TooltipPlace; align: TooltipAlign }>`
  position: absolute;

  ${(p) => {
    switch (p.place) {
      default:
      case 'top':
        return css`
          bottom: 0;
          transform: ${calculateAlignX(p.align)};
        `
      case 'bottom':
        return css`
          top: 0;
          transform: ${calculateAlignX(p.align)};
        `
      case 'left':
        return css`
          right: 0;
          transform: translateY(-50%);
        `
      case 'right':
        return css`
          left: 0;
          transform: translateY(-50%);
        `
    }
  }};
`

export const StyledContainer = styled.div<{
  maxWidth?: number
  fixWidth?: number
  isWhiteTooltip?: boolean
  padding?: number
}>`
  color: ${(p) => p.theme.colors.mainText2};
  padding: ${(p) => p.padding}px;
  margin: 8px;
  opacity: 1;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: ${(p) => (p.fixWidth ? `${p.fixWidth}px` : 'auto')};
  max-width: ${(p) => (p.fixWidth ? `${p.fixWidth}px` : p.maxWidth ? `${p.maxWidth}px` : 'auto')};
  background-color: ${(p) => (p.isWhiteTooltip ? p.theme.colors.white : p.theme.colors.mainText1)};
  ${(p) => p.isWhiteTooltip && `border: 2px solid ${p.theme.colors.mainText}`}
`

export const Triangle = styled.div<{ place: TooltipPlace }>`
  width: 0;
  height: 0;
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  border-style: solid;
  ${(p) => {
    switch (p.place) {
      default:
      case 'top':
        return css`
          top: -8px;
          border-width: 10px 10px 0 10px;
          border-color: ${(p) => p.theme.colors.mainText} transparent transparent transparent;
        `
      case 'bottom':
        return css`
          top: 8px;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent ${(p) => p.theme.colors.mainText} transparent;
        `
      case 'left':
        return css`
          left: -8px;
          border-width: 10px 0 10px 10px;
          border-color: transparent transparent transparent ${(p) => p.theme.colors.mainText};
        `
      case 'right':
        return css`
          left: 8px;
          border-width: 10px 10px 10px 0;
          border-color: transparent ${(p) => p.theme.colors.mainText} transparent transparent;
        `
    }
  }};
`
