import styled from 'styled-components'
import { title3 } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 800px;
  padding: 20px;
`

export const Image = styled.img<{ isGrey?: boolean }>`
  width: 262px;
  object-fit: cover;
  height: 122px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background4};
  ${(p) => p.isGrey && 'filter: grayscale(100%);'}
`

export const ContainerWrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  padding: 2px 0;
`

export const Name = styled.span`
  ${title3};
  width: 350px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-clamp: 3;
  color: ${({ theme }) => theme.colors.mainText};
`

export const BottomBlock = styled.div`
  position: absolute;
  bottom: 0;
`

export const ServiceIconWrapper = styled.div`
  > div {
    margin-left: 3px;
  }
`

export const TravelerNameBlock = styled.div`
  position: absolute;
  bottom: 32px;
  right: 20px;
`
