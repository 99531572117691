import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  margin-block: 24px;
  padding-inline: 20px;
  height: 560px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: scroll;
  margin: 0;
  padding: 0;
  padding-block-end: 20px;
  list-style-type: none;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`

export const InlineText = styled(Text)`
  display: inline;
`
