import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Portal } from '@etta/ui/portal'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import UberEconomyRides from './assets/uber-economy-rides.svg?url'

import {
  BackDrop,
  DialogContainer,
  DialogContainerRoot,
  DialogContent,
  Container,
  InfoImage,
} from './mobility-search-result-reserve-airport-pickup-info-dialog-styled'

const i18nBase = 'Mobility.ReserveAirportPickupInfoDialog'

type Props = {
  onClose: () => void
  isOpen: boolean
}

export function MobilitySearchResultReserveAirportPickupInfoDialog({ onClose, isOpen }: Props) {
  const { t } = useTranslation()
  const [isShownDialog, setIsShownDialog] = useState(false)
  return (
    <Portal target="#mobility-modals-container">
      <Container isShown={isShownDialog || isOpen} role={'dialog'}>
        <BackDrop aria-hidden />
        <DialogAnimation
          isOpen={isOpen}
          onEntered={() => setIsShownDialog(true)}
          onExited={() => setIsShownDialog(false)}
          isAnimated>
          <DialogContainerRoot>
            <DialogContainer>
              <DialogContent>
                <InfoImage src={UberEconomyRides} />
                <Block paddingTop={2} paddingBottom={12}>
                  <Text variant={'title3'} color={'mainText'}>
                    {t(`${i18nBase}.Title`)}
                  </Text>
                </Block>
                <Block paddingBottom={24}>
                  <Text variant={'subHeadMedium'} color={'mainText1'} align={'center'}>
                    <Trans i18nKey={`${i18nBase}.Description`} components={{ bold: <strong /> }} />
                  </Text>
                </Block>
                <Button fullWidth onClick={onClose}>
                  {t(`${i18nBase}.OkButton`)}{' '}
                </Button>
              </DialogContent>
            </DialogContainer>
          </DialogContainerRoot>
        </DialogAnimation>
      </Container>
    </Portal>
  )
}
