import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container, ExpiredMessageTextContainer } from './expired-message-styled'

export function ExpiredMessage() {
  const { t } = useTranslation()
  return (
    <Container>
      <Block marginRight={8} marginTop={16}>
        <Icon name="timerPWA" color="error" size="normal" />
      </Block>
      <ExpiredMessageTextContainer>
        <Block marginBottom={2}>
          <Text variant="subHeadMedium" color="error">
            {t('ItineraryExpiration.ExpiredText')}
          </Text>
        </Block>
        <Text variant="subHeadMedium" color="bodyText">
          {t('ItineraryExpiration.ExpiredDescription')}
        </Text>
      </ExpiredMessageTextContainer>
    </Container>
  )
}
