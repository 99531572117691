import FlipMove from 'react-flip-move'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { PostBookingAction } from '@fiji/enums'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { AirSearchFormMobileRecentSearches } from '@etta/modules/recent-searches/ui/air-search-form-list/mobile'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { PresearchMultiLayerCostAllocation } from '../multi-layer-cost-allocation'
import { useSearch } from './hooks/use-search'
import { TabsSection } from './tabs-section'
import { SearchButtonSection } from './search-button-section'
import { AirSearchFlight } from './flight'
import { AirSearchFormLayout } from './layout'
import { WarningMessage } from './components'
import { TRANSITION_DURATION } from './transition'
import { AddNewFlightLabel } from './add-new-flight-label'

type Props = {
  isLoading?: boolean
  onSubmit?: () => void
}

export const AirSearchForm = observer(function AirSearchForm({
  isLoading = false,
  onSubmit,
}: Props) {
  const { getRecentSearchesActions } = useRecentSearchesContext()
  const {
    airSearchFormInputsActions,
    airSearchFormStore,
    airSearchQueryStore,
  } = useAirSearchFormContext()
  const { costAllocationFormStore } = useCostAllocationContext()
  const costAllocationValidationErrors = costAllocationFormStore.validationErrors.costAllocation

  useEffect(() => {
    getRecentSearchesActions.getRecentFlightSearches()
  }, [getRecentSearchesActions])

  useEffect(() => {
    getRecentSearchesActions.getRecentFlightSearchesWithoutDates()
  }, [getRecentSearchesActions])

  const { searchRestrictions, postBookingAction } = airSearchQueryStore.additionalQueries

  const isMultiCity = airSearchFormStore.searchFlightType === FlightType.MultiCity

  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    handleSearchClick,
  } = useSearch({
    onSubmit,
  })
  return (
    <AirSearchFormLayout
      isOneRow={!isMultiCity}
      tabsSlot={
        <TabsSection
          isDisabled={
            postBookingAction === PostBookingAction.Modify ||
            postBookingAction === PostBookingAction.ModifyReturn
          }
        />
      }
      submitButtonSlot={
        <SearchButtonSection
          isIcon={!isMultiCity}
          handleSearchClick={handleSearchClick}
          isDisabled={isLoading}
        />
      }
      recentSearchesSlot={<AirSearchFormMobileRecentSearches />}
      preSearchCustomFieldsModalSlot={
        <PreSearchCustomFields
          customFields={customFields}
          values={values}
          errors={errors}
          onFieldChange={onFieldChange}
          onBack={onPreSearchBack}
          isVisible={isOpenPreSearchCustomFields}
          onSubmit={onSubmitCustomFieldsHandler}
        />
      }
      warningSlot={searchRestrictions && <WarningMessage type={searchRestrictions} />}
      costAllocationSlot={
        <PresearchMultiLayerCostAllocation error={costAllocationValidationErrors} />
      }
      addNewFlightButtonSlot={
        isMultiCity && (
          <AddNewFlightLabel
            isAbleToAddFlight={airSearchFormStore.isAbleToAddFlight}
            onClick={airSearchFormInputsActions.addFlightToSearch}
          />
        )
      }>
      <FlipMove disableAllAnimations={!isMultiCity} duration={TRANSITION_DURATION}>
        {airSearchFormStore.searchFlightsToShow.map((flight, index) => {
          return (
            <div key={flight.id}>
              <AirSearchFlight
                legNumber={index}
                flightLegInputs={flight}
                isLoading={isLoading}
                warningSlot={
                  searchRestrictions && (
                    <WarningMessage
                      type={searchRestrictions}
                      postBookingAction={postBookingAction}
                    />
                  )
                }
                costAllocationSlot={
                  searchRestrictions && (
                    <PresearchMultiLayerCostAllocation
                      type="additional-info"
                      error={costAllocationValidationErrors}
                    />
                  )
                }
              />
            </div>
          )
        })}
      </FlipMove>
    </AirSearchFormLayout>
  )
})
