import { MediaLayout } from '@etta/ui/media-layout'
import { DateTimeSelectorMobile } from './mobile'
import type { Props } from './types'

export function DateTimeSelector(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<DateTimeSelectorMobile {...props} />}
      desktopSlot={<DateTimeSelectorMobile {...props} />}
    />
  )
}
