import { ShowMore } from '@etta/ui/show-more'
import { Swap } from '@etta/ui/swap'
import { Section, ShowMoreButton } from './list-section-styled'
import { CheckboxList } from './checkbox-list'
import { ListSectionTitle } from './list-section-title'
import type { ListFields } from './types'

const SHOW_N_ITEMS = 3

type Props<T extends ListFields> = {
  title?: string
  titleLess?: string
  titleMore?: string
  classesList?: T[]
  values: Set<string>
  onChange: (value: T['code']) => void
  onClearSection: () => void
  type?: string
}

export function ListSection<T extends ListFields>({
  title,
  titleLess,
  titleMore,
  classesList,
  values,
  onChange,
  onClearSection,
  type,
}: Props<T>) {
  if (!classesList || classesList.length === 0) {
    return null
  }

  const isShowMoreVisible = classesList ? classesList.length > SHOW_N_ITEMS : false
  const notExpandedList = classesList?.slice(0, SHOW_N_ITEMS)

  return (
    <Section>
      <ListSectionTitle title={title} selectedAmount={values.size} onClear={onClearSection} />
      <Swap
        is={!isShowMoreVisible}
        isSlot={
          <CheckboxList classesList={classesList} values={values} onChange={onChange} type={type} />
        }>
        <ShowMore
          notExpandedContent={
            <CheckboxList
              classesList={notExpandedList}
              values={values}
              onChange={onChange}
              type={type}
            />
          }
          expandedContent={
            <CheckboxList
              classesList={classesList}
              values={values}
              onChange={onChange}
              type={type}
            />
          }
          renderButton={(isExpanded, handleButtonClick) => (
            <ShowMoreButton variant="text" onClick={handleButtonClick}>
              {isExpanded ? titleLess : titleMore}
            </ShowMoreButton>
          )}
        />
      </Swap>
    </Section>
  )
}
