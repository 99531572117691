import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Label = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  isBlock: true,
}))`
  position: relative;
  text-transform: uppercase;
  margin-left: 32px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.primary};

  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
  }
`
