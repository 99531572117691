import type { TripCostSummary } from '@fiji/graphql/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useTripCostSummary } from './use-trip-cost-summary'
import { PayableSectionsContainer } from './payable-sections-styled'
import { PayNowSection } from './pay-now-section'
import { PayLaterSection } from './pay-later-section'

type Props = {
  tripCost: TripCostSummary
}

export function PayableSections({ tripCost }: Props) {
  const {
    hasFlightTripCost,
    hasNowHotelTripCost,
    hasLaterHotelTripCost,
    hasNowCarRentalTripCost,
    hasLaterCarRentalTripCost,
    isPayNow,
    isPayLater,
    totalFlightTripCosts,
    totalRailTripCost,
    hasRailTripCost,
    totalNowHotelTripCost,
    totalLaterHotelTripCost,
    totalNowCarRentalTripCost,
    totalLaterCarRentalTripCost,
  } = useTripCostSummary({ tripCost })
  const { featureFlags } = useFeatureFlags()

  const unusedTicket = featureFlags.isDesktopUnusedTicketsEnabled
    ? tripCost?.unusedTicket?.validated
    : null

  return (
    <PayableSectionsContainer>
      <PayNowSection
        totalFlightTripCosts={totalFlightTripCosts}
        totalNowCarRentalTripCost={totalNowCarRentalTripCost}
        totalNowHotelTripCost={totalNowHotelTripCost}
        totalRailTripCost={totalRailTripCost}
        hasNowHotelTripCost={hasNowHotelTripCost}
        hasNowCarRentalTripCost={hasNowCarRentalTripCost}
        hasFlightTripCost={hasFlightTripCost}
        hasRailTripCost={hasRailTripCost}
        unusedTicket={unusedTicket}
        isPayNow={isPayNow}
      />
      <PayLaterSection
        totalLaterHotelTripCost={totalLaterHotelTripCost}
        totalLaterCarRentalTripCost={totalLaterCarRentalTripCost}
        hasLaterHotelTripCost={hasLaterHotelTripCost}
        hasLaterCarRentalTripCost={hasLaterCarRentalTripCost}
        isPayLater={isPayLater}
      />
    </PayableSectionsContainer>
  )
}
