import styled, { css } from 'styled-components'

export const Item = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  background: ${(p) => p.theme.colors.background};
  color: ${(p) => p.theme.colors.mainText};
`

export const ItemContent = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  margin-left: 8px;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    `}
`

export const ItemTitle = styled.div`
  margin-bottom: 5px;
`

export const CostWrapper = styled.div<{ isDesktop?: boolean }>`
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      display: flex;
      flex-flow: column;
      align-items: flex-end;
    `}
`

export const FlightNameWrapper = styled.div`
  display: flex;
  align-items: center;
`
