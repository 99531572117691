import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'
import type { Dimension } from './types'
import { getDimension } from './get-dimension'

export const Container = styled.button<{
  width: Dimension
  height: Dimension
  disabled?: boolean
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => getDimension(props.width)};
  height: ${(props) => getDimension(props.height)};
  border: none;
  background-color: transparent;
  padding: 0;
  ${KeyboardNavigationStyle}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.6;
    `}
`
