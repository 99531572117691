// eslint-disable-next-line import/no-restricted-paths
import type { HotelTravelPreferenceInput, GazooMembership } from '@fiji/graphql/hooks'
import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { VendorEntity } from '../../core/entities/available-vendors.entity'
import type { SpecialRequestEntity } from '../../core/entities/available-special-requests.entity'
import { ValidatorMaker } from '../../validators/validator-maker'
import { FieldSettingsStore } from './field-settings'

@Store()
export class HotelPreferencesStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  isSaveButtonDisabled = true

  toggle = new Toggle()
  hotelLoyaltyProgramActionToggle = new Toggle()
  hotelLoyaltyProgramRemoveToggle = new Toggle()

  _availableHotelVendors: VendorEntity[] | null = null
  hotelAvailableSpecialRequests: SpecialRequestEntity[] | null = null

  hotelLoyaltyProgram: GazooMembership = {
    vendorCode: '',
    number: '',
  }
  isEditExistingProgramMode: boolean = false
  isAvailableHotelVendorsLoading: boolean = false
  isAvailableHotelSpecialRequestsLoading: boolean = false

  hotelPreferencesValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeHotelPreferencesValidator()

  get isViewingExistingMembership(): Boolean {
    const { vendorCode } = this.hotelLoyaltyProgram
    const hotelMemberships = this.hotelPreferencesValidator.values.membership as GazooMembership[]
    return !!hotelMemberships.find((item) => item.vendorCode === vendorCode)
  }

  get availableHotelVendors() {
    return this._availableHotelVendors
  }

  setHotelPreferencesValue<T extends keyof HotelTravelPreferenceInput>(
    field: T,
    value: HotelTravelPreferenceInput[T],
  ) {
    this.hotelPreferencesValidator.onFieldChange(field, value)
  }

  setHotelPreferencesInput(input: HotelTravelPreferenceInput) {
    this.setHotelPreferencesValue('specialRequest', input.specialRequest)
    this.setHotelPreferencesValue('membership', input.membership)
  }

  setHotelLoyaltyProgram(loyaltyProgram: GazooMembership) {
    this.hotelLoyaltyProgram = loyaltyProgram
  }

  resetHotelLoyaltyProgram() {
    this.hotelLoyaltyProgram = {
      vendorCode: '',
      number: '',
    }
  }

  setAvailableHotelVendors(availableVendors: VendorEntity[] | null) {
    this._availableHotelVendors = availableVendors
  }

  setHotelAvailableSpecialRequests(specialRequests: SpecialRequestEntity[] | null) {
    this.hotelAvailableSpecialRequests = specialRequests
  }

  setIsEditExistingProgramMode(isEditMode: boolean) {
    this.isEditExistingProgramMode = isEditMode
  }

  setIsAvailableHotelVendorsLoading(isLoading: boolean) {
    this.isAvailableHotelVendorsLoading = isLoading
  }

  setIsAvailableHotelSpecialRequestsLoading(isLoading: boolean) {
    this.isAvailableHotelSpecialRequestsLoading = isLoading
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
