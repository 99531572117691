import { Inject, Service } from '@etta/di'
import { PartnerCoBrandingConfigurationsStore } from '../../interface/stores/partner-cobranding-configurations.store'
import { PartnerCoBrandingConfigurationsAdapter } from '../../infra/partner-cobranding-configurations.adapter'
import type { PartnerCoBrandingConfigurationsnParams } from '../../core/types'

@Service()
export class PartnerCoBrandingConfigurationsService {
  constructor(
    @Inject()
    private readonly partnerCoBrandingConfigurationsAdapter: PartnerCoBrandingConfigurationsAdapter,
    @Inject()
    private readonly partnerCoBrandingConfigurationsStore: PartnerCoBrandingConfigurationsStore,
  ) {}

  private async fetchConfigurations(params: PartnerCoBrandingConfigurationsnParams) {
    this.partnerCoBrandingConfigurationsStore.setPartnerCoBrandingConfigurationsLoading(true)
    this.partnerCoBrandingConfigurationsStore.setPartnerCoBrandingConfigurationsLoaded(false)

    const result = await this.partnerCoBrandingConfigurationsAdapter.getConfigurations(params)

    result.match({
      Err: (_error) => {
        // Handle error
      },
      Ok: (data) => {
        this.partnerCoBrandingConfigurationsStore.setPartnerCoBrandingConfigurations(data)
        this.partnerCoBrandingConfigurationsStore.setPartnerCoBrandingConfigurationsLoaded(true)
      },
    })

    // Ensure loading is set to false after the match
    this.partnerCoBrandingConfigurationsStore.setPartnerCoBrandingConfigurationsLoading(false)
  }

  // Public method to load configurations by site name
  async loadConfigurationsBySiteName(params: PartnerCoBrandingConfigurationsnParams) {
    await this.fetchConfigurations(params)
  }

  // Public method to load configurations by site id
  async loadConfigurationsBySiteId(params: PartnerCoBrandingConfigurationsnParams) {
    await this.fetchConfigurations(params)
  }
}
