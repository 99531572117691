import { useTranslation } from 'react-i18next'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { RailLegType } from '@fiji/enums'
import { useRailSearchQuery } from '@fiji/hooks/search-queries/use-rail-search-query/use-rail-search-query'

type Props = {
  railLegType: RailLegType
}

export function useHeaderTripReviewTitle({ railLegType }: Props) {
  const { t } = useTranslation()

  const { queryParams } = useRailSearchQuery({})

  const {
    originPlace,
    destinationPlace,
    originDate,
    destinationDate,
    railTripType,
    railCards,
  } = queryParams

  if (!originPlace || !destinationPlace) {
    const loading = t('SearchSegments.HeaderLoading')
    return { locationsLabel: loading, datesLabel: loading }
  }

  const originPlaceName = (originPlace?.shortName ?? originPlace.name).split(',')[0]
  const destinationPlaceName = (destinationPlace?.shortName ?? destinationPlace.name).split(',')[0]
  const originLocation = originPlace.airportCode ?? originPlaceName
  const destinationLocation = destinationPlace.airportCode ?? destinationPlaceName

  const location = [originLocation, destinationLocation]

  const locationToShow =
    railLegType === RailLegType.Departure ? location.join(' - ') : location.reverse().join(' - ')

  const originDateToShow = dateFormat(originDate, 'MMM d')
  const destinationDateToShow = destinationDate ? dateFormat(destinationDate, 'MMM d') : ''

  const roundTrip = railTripType === RailTripType.Round
  const date = [originDateToShow]
  if (roundTrip) {
    date.push(destinationDateToShow)
  }

  const dateToShow = date.join(' - ')

  const cardName = railCards?.[0].name
  const dateWithCardToShow = [dateToShow, cardName].filter(Boolean).join(' • ')

  return { locationsLabel: locationToShow, datesLabel: dateWithCardToShow }
}
