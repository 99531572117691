import { useEffect } from 'react'
import { useAppSelector } from '@fiji/store'
import { userSelector } from '@fiji/store/user'
import { deemHeaderSelector } from '@fiji/store/deem-header'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'
import { useDeemHeader } from './use-deem-header'

export function useFijiLoader() {
  useDeemHeader()
  const { featureFlagsAction } = useFeatureFlagsContext()
  const { companyId } = useAppSelector(userSelector)
  const { isDeemHeaderLoading } = useAppSelector(deemHeaderSelector)
  const { partnerCoBrandingConfigurationAction } = usePartnerCoBrandingConfigurationsContext()

  useEffect(() => {
    const handleLoad = () => {
      if (!companyId) {
        return
      }

      Promise.all([
        featureFlagsAction.loadFlagsById(companyId),
        partnerCoBrandingConfigurationAction.loadPartnerCoBrandingConfigurationsBySiteId(companyId),
      ])
    }

    handleLoad()
  }, [companyId, featureFlagsAction, partnerCoBrandingConfigurationAction])

  return { isDeemHeaderLoading }
}
