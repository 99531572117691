import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { RailLayoutType } from '@fiji/enums'
import { RailTripType } from '@fiji/hooks/search-queries'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { RailSearchFormMobileRecentSearches } from '@etta/modules/recent-searches/ui/rail-search-form-list/mobile'
import { PresearchMultiLayerCostAllocation } from '../multi-layer-cost-allocation'
import { useSearch } from './use-rail-search-form/use-search'
import { RailSearchFormLayout } from './layout/rail-search-form-layout'
import { LocationSection } from './location-section/location-section'
import { TabsSection } from './tabs-section/tabs-section'
import { RailCards } from './rail-cards/rail-cards'
import { CalendarTimeSection } from './calendar-time-section/calendar-time-section'
import { SubmitButton } from './submit-button/submit-button'

const i18Base = 'TripPlanner.BaseSearch'

type Props = {
  onSubmit?: () => void
}

export const RailSearchForm = observer(function RailSearchForm({ onSubmit }: Props) {
  const { getRecentSearchesActions } = useRecentSearchesContext()
  const { railSearchButtonActions, railSearchFormStore } = useRailSearchFormContext()
  const { isDateRangeValid, isSubmitEnabled } = railSearchButtonActions
  const {
    railTripType,
    originPlace,
    destinationPlace,
    originDate,
    originTime,
    destinationDate,
    destinationTime,
    railCards,
    searchRestrictions,
  } = railSearchFormStore.railForm

  const { t } = useTranslation()

  useEffect(() => {
    getRecentSearchesActions.getRecentRailSearches()
  }, [getRecentSearchesActions])

  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    handleSearchClick,
  } = useSearch({
    onSubmit,
  })

  return (
    <RailSearchFormLayout
      isTwoSections={railTripType === RailTripType.Round}
      isAbleToSubmit={isSubmitEnabled}
      onSearchClick={handleSearchClick}
      tabsSlot={<TabsSection isDisabled={!!searchRestrictions} />}
      locationSlot={<LocationSection />}
      calendarSlot={
        <CalendarTimeSection
          railTripType={railTripType}
          originPlace={originPlace}
          destinationPlace={destinationPlace}
          originDate={originDate}
          destinationDate={destinationDate}
          originTime={originTime}
          destinationTime={destinationTime}
          isDateRangeValid={isDateRangeValid}
        />
      }
      costAllocationSlot={<PresearchMultiLayerCostAllocation />}
      railCardsSlot={<RailCards railCards={railCards} layout={RailLayoutType.UK} />}
      submitButtonSlot={
        <SubmitButton
          disabled={!isSubmitEnabled}
          onClick={handleSearchClick}
          label={`${t(i18Base + '.Buttons.SearchRail')} ${t('Accessibility.Button')}`}>
          {t(i18Base + '.Buttons.SearchRail')}
        </SubmitButton>
      }
      preSearchCustomFieldsModalSlot={
        <PreSearchCustomFields
          customFields={customFields}
          values={values}
          errors={errors}
          onFieldChange={onFieldChange}
          onBack={onPreSearchBack}
          isVisible={isOpenPreSearchCustomFields}
          onSubmit={onSubmitCustomFieldsHandler}
        />
      }
      recentSearchesSlot={<RailSearchFormMobileRecentSearches />}
    />
  )
})
