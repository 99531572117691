import { Header } from '@etta/ui/header'
import { Screen } from '@etta/ui/screen'
import { DetailsSection } from '../../details-section'
import type { LayoutProps } from '../../types'

export function DetailsHeaderMobile({
  headerRef,
  tripName,
  tripStatus,
  isTripNameChangable,
  onEdit,
  onBackButtonClick,
  startTime,
  endTime,
  isOpenReturn,
}: LayoutProps) {
  return (
    <>
      <Screen.Header>
        <Header
          headerRef={headerRef}
          animation="opacity"
          leftSlot={<Header.BackButton onClick={onBackButtonClick} />}>
          <Header.Title title={tripName} align="left" />
        </Header>
      </Screen.Header>
      <DetailsSection
        tripName={tripName}
        tripStatus={tripStatus}
        startTime={startTime}
        endTime={endTime}
        onEditClick={onEdit}
        isTripNameChangable={isTripNameChangable}
        isOpenReturn={isOpenReturn}
      />
    </>
  )
}
