import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import {
  Container,
  Wrapper,
  FlightImage,
  Text,
  OldSeatMapErrorContainer,
  EmptyContainer,
  EmptyButton,
} from './seat-map-unavailable.styled'
import { Flight, Flight2x, Flight3x } from './assets'

type Props = {
  isNewSeatMap: boolean
  onContinue: () => void
}

export const SeatMapUnavailable = observer(function SeatMapUnavailable({
  isNewSeatMap,
  onContinue,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (isNewSeatMap) {
    return (
      <Container>
        <Wrapper>
          <FlightImage src={Flight} srcSet={`${Flight2x} 2x, ${Flight3x} 3x`} />
          <Text data-tracking-id="seat-map-unavailable-text" variant="subHeadStrong">
            {t('AirSeatMap.Unavailable')}
          </Text>
        </Wrapper>
      </Container>
    )
  }

  return (
    <OldSeatMapErrorContainer>
      <EmptyContainer marginAuto>
        <FlightImage src={Flight} srcSet={`${Flight2x} 2x, ${Flight3x} 3x`} />
        <Text
          data-tracking-id="seat-map-unavailable-text"
          variant={isMobile ? 'headline' : 'subHeadStrong'}>
          {t('AirSeatMap.Unavailable')}
        </Text>
        {isMobile && (
          <EmptyButton size="small" fullWidth onClick={onContinue}>
            {t('AirSeatMap.Continue')}
          </EmptyButton>
        )}
      </EmptyContainer>
    </OldSeatMapErrorContainer>
  )
})
