import { useEffect, useState } from 'react'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'

type Props = {
  totalAttempts?: number
  refetch: () => void
  loading: boolean
  redirectRouteOnFail?: string
  redirectQueryParamsOnFail?: Record<string, string | undefined>
  onFail?: () => void
}

export function useRefetchRequest({
  refetch,
  loading,
  redirectRouteOnFail,
  redirectQueryParamsOnFail,
  onFail,
  totalAttempts = 2,
}: Props) {
  const { navigateTo } = useQueryParams()

  const [attempt, setAttempt] = useState(0)

  const handleRefetchRequest = () => {
    setAttempt((prev) => prev + 1)
    refetch()
  }

  useEffect(() => {
    if (attempt >= totalAttempts && !loading) {
      setAttempt(0)
      onFail && onFail()
      redirectRouteOnFail && navigateTo(redirectRouteOnFail, redirectQueryParamsOnFail)
    }
  }, [
    loading,
    attempt,
    totalAttempts,
    onFail,
    redirectRouteOnFail,
    navigateTo,
    redirectQueryParamsOnFail,
  ])

  return { handleRefetchRequest }
}
