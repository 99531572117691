import { Bone, BoneWrapper } from '../support-travel-page-components'

export function SupportTravelBodySkeleton() {
  const minWidth = 60
  const maxWidth = 80
  const maxBones = 4

  const boneWidthList = Array.from({ length: maxBones }).map(() =>
    Math.floor(Math.random() * (maxWidth - minWidth) + minWidth),
  )
  return (
    <>
      {boneWidthList.map((boneWidth, idx) => (
        <BoneWrapper key={`${boneWidth}-${idx}`}>
          <Bone width={boneWidth} />
        </BoneWrapper>
      ))}
    </>
  )
}
