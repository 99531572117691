import { MediaLayout } from '@etta/ui/media-layout'
import { SubmitButtonMobile } from './layout/mobile/submit-button-mobile'
import { SubmitButtonDesktop } from './layout/desktop/submit-button-desktop'
import type { Props } from './types'

export const SubmitButton = (props: Props) => {
  return (
    <MediaLayout
      mobileSlot={<SubmitButtonMobile {...props} />}
      desktopSlot={<SubmitButtonDesktop {...props} />}
    />
  )
}
