import { Store } from '@etta/di'

@Store()
export class CreateCustomerUberAccountStore {
  private _isError: boolean = false
  private _errorMessage: string = ''
  private _isCreateCustomerUberAccountExecuting = false

  get isError() {
    return this._isError
  }

  setIsError(isError: boolean) {
    this._isError = isError
  }

  get errorMessage() {
    return this._errorMessage
  }

  setErrorMessage(errorMessage: string) {
    this._errorMessage = errorMessage
  }

  get isCreateCustomerUberAccountExecuting() {
    return this._isCreateCustomerUberAccountExecuting
  }

  setIsCreateCustomerUberAccountExecuting(isExecuting: boolean) {
    this._isCreateCustomerUberAccountExecuting = isExecuting
  }

  dropStore() {
    this.setIsError(false)
    this.setErrorMessage('')
    this.setIsCreateCustomerUberAccountExecuting(false)
  }
}
