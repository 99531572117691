import type { PriceDetailsHeaderProps } from '../../types'
import { usePriceDetailsHeaderDesktop } from './use-price-details-header-desktop'
import { Heading } from './price-details-header-desktop-styled'

export function PriceDetailsHeaderDesktop({
  headerLevel,
  type = 'price-details',
  isCarServicePriceDetails,
}: PriceDetailsHeaderProps) {
  const { title } = usePriceDetailsHeaderDesktop({ type })

  return (
    <Heading
      role="heading"
      aria-label={title}
      aria-level={headerLevel}
      variant="headline"
      isBlock
      isCarServicePriceDetails={isCarServicePriceDetails}>
      {title}
    </Heading>
  )
}
