import { useTranslation } from 'react-i18next'
import { Container, ArrowIcon } from './airport-title-styled'

type Props = {
  origin: string
  destination: string
}

const i18nBase = 'Accessibility.'

export function AirportTitle({ origin, destination }: Props) {
  const { t } = useTranslation()
  return (
    <Container
      aria-label={t(i18nBase + 'ReviewTripPage.FlightCard', { origin, destination })}
      data-tracking-id="origin-to-destination-text">
      <span data-tracking-id={'origin-code-text'}>{origin}</span> <ArrowIcon />{' '}
      <span data-tracking-id={'destination-code-text'}>{destination}</span>
    </Container>
  )
}
