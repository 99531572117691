import type { PreferenceOptions } from '@fiji/graphql/types'

type Args = {
  specialRequests?: PreferenceOptions[]
  availableRequests?: PreferenceOptions[] | null
}

export function filterSpecialRequest({ specialRequests = [], availableRequests = [] }: Args) {
  return specialRequests.filter((request) =>
    availableRequests?.some((availableRequest) => availableRequest.value === request.value),
  )
}
