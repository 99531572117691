import type { CacheKey, GraphQLOperation } from './types'

function getHash(value: string): CacheKey {
  return Array.from(value).reduce((hash, character) => 0 | (31 * hash + character.charCodeAt(0)), 0)
}

export function getKey(params: GraphQLOperation) {
  const key = getHash(JSON.stringify(params))
  const opName = params?.operationName || 'unknown'
  return `${opName}-${key}`
}
