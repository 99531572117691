import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'
import { GetSupportArticleService } from '../services/get-support-article.service'

@Action()
export class SupportTravelAction {
  constructor(
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly getSupportArticleService: GetSupportArticleService,
  ) {}

  handleBackButton() {
    this.historyService.replace(ROUTES.support.main)
  }

  handleGetSupportArticles() {
    this.getSupportArticleService.getSupportArticle()
  }

  setIsPreview(isPreview: boolean) {
    this.getSupportArticleService.setIsPreview(isPreview)
  }
}
