import { Inject, Service } from '@etta/di'
import { CarRentalStore } from '@etta/modules/car-rental-search/interface/stores/car-rental.store'
import { CarRentalSearchStore } from '@etta/modules/car-rental-search/interface/stores/car-rental-search.store'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
import { DeviceClass } from '@etta/core/enums'
import { buildMetadataLogs } from '@fiji/utils/build-metadata-logs'
import { dateTimeToShort } from '@fiji/utils/dates/date-time-to-short'
import type { AddressInputValueObject, AddressValueObject } from '@etta/core/value-objects'
import type { Place } from '@fiji/types'
import type { CarRentalInputValueObject } from '../../core/value-objects'
import type { CarRentalInputArgs, CarRentalFilters } from '../types'

type GetAirportArgs = {
  place: Place
  address?: AddressValueObject | AddressInputValueObject
}

const CAR_RENTAL_IMAGE_WIDTH = 120
const CAR_DETAILS_IMAGE_WIDTH = 400
const PAGE_SIZE_DEFAULT = 10

@Service()
export class CarRentalInputService {
  constructor(
    @Inject()
    private readonly carRentalStore: CarRentalStore,

    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,

    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,
  ) {}

  getCarRentalInput({
    carFilters,
    itineraryId,
    searchId,
    sortBy,
    locationFilters,
    typeFilters,
  }: CarRentalInputArgs = {}): CarRentalInputValueObject {
    const { carRentalSortBy, searchPageEnd } = this.carRentalSearchStore
    const { isPhoenixCarRentalSearchEnabled } = this.displayConfigurationStore
    const { queryParams } = this.carRentalStore

    const { carVendor, carClass, carFuel, carTransmission } = this.buildCarFilters(carFilters)

    const {
      dropOffAirport,
      pickUpAirport,
      dropOffSearchDateTime,
      pickUpSearchDateTime,
      pickUpLocationId,
      dropOffLocationId,
    } = this.buildLocationFilters(locationFilters)

    const deviceClass =
      screenMatcher.getScreenType() === ScreenType.Mobile ? DeviceClass.Mobile : DeviceClass.Desktop

    return {
      logsMetadata: buildMetadataLogs({
        itineraryId: itineraryId ?? queryParams.itineraryId,
        isPhoenixCarRentalSearchEnabled,
      }),
      sortBy: sortBy ?? carRentalSortBy,
      dropOffAirport,
      pickUpAirport,
      searchId: searchId ?? queryParams.searchId,
      carTypes: typeFilters ?? queryParams.carTypes,
      dropOffDateTime: dropOffSearchDateTime,
      pickUpDateTime: pickUpSearchDateTime,
      pickUpLocationId: `${pickUpLocationId}`,
      dropOffLocationId: `${dropOffLocationId}`,
      deviceClass,
      vendors: carVendor,
      fuelTypes: carFuel,
      classTypes: carClass,
      transmissionTypes: carTransmission,
      pageSize: PAGE_SIZE_DEFAULT,
      startIndex: searchPageEnd,
      imageInputOptions: {
        size: { width: CAR_RENTAL_IMAGE_WIDTH },
      },
      carDetailsImageInputOptions: {
        size: { width: CAR_DETAILS_IMAGE_WIDTH },
      },
    }
  }
  private buildCarFilters(carFilters?: CarRentalFilters) {
    const { carVendor, carClass, carFuel, carTransmission } = this.carRentalStore.queryParams

    return {
      carVendor: carFilters?.carVendor ?? carVendor,
      carClass: carFilters?.carClass ?? carClass,
      carFuel: carFilters?.carFuel ?? carFuel,
      carTransmission: carFilters?.carTransmission ?? carTransmission,
    }
  }

  private buildLocationFilters(locationFilters: CarRentalInputArgs['locationFilters']) {
    if (!locationFilters) {
      const {
        dropOffSearchDateTime,
        pickUpSearchDateTime,
        pickUpLocationId,
        dropOffLocationId,
      } = this.carRentalStore
      const {
        dropOffAddress,
        dropOffPlace,
        pickUpAddress,
        pickUpPlace,
      } = this.carRentalStore.queryParams

      return {
        dropOffAirport: this.getAirport({ place: dropOffPlace, address: dropOffAddress }),
        pickUpAirport: this.getAirport({ place: pickUpPlace, address: pickUpAddress }),
        dropOffSearchDateTime,
        pickUpSearchDateTime,
        pickUpLocationId,
        dropOffLocationId,
      }
    }

    const {
      dropOffDate,
      dropOffPlace,
      dropOffTime,
      pickUpDate,
      pickUpPlace,
      pickUpTime,
      dropOffAddress,
      pickUpAddress,
    } = locationFilters

    const dropOffAirport = this.getAirport({ place: dropOffPlace, address: dropOffAddress })
    const pickUpAirport = this.getAirport({ place: pickUpPlace, address: pickUpAddress })
    const dropOffSearchDateTime =
      dropOffDate && dropOffTime ? dateTimeToShort(dropOffDate, dropOffTime) : ''
    const pickUpSearchDateTime =
      pickUpDate && pickUpTime ? dateTimeToShort(pickUpDate, pickUpTime) : ''

    return {
      dropOffAirport,
      pickUpAirport,
      dropOffSearchDateTime,
      pickUpSearchDateTime,
      pickUpLocationId: pickUpPlace.placeId,
      dropOffLocationId: dropOffPlace.placeId,
    }
  }

  private getAirport({ place, address }: GetAirportArgs) {
    if (address && 'airportCode' in address) {
      return place?.airportCode || address.airportCode
    }
    return place?.airportCode
  }
}
