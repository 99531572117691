import { getTokenExpirationDateFromCookie } from '@fiji/utils/get-cookie-expiration-date'

export const checkIsCookieExpired = (beforeExpireMilliseconds: number = 0): boolean => {
  const expirationDate = getTokenExpirationDateFromCookie()

  if (!expirationDate) {
    return true
  }

  const interval = expirationDate.getTime() - Date.now()

  return interval <= beforeExpireMilliseconds
}
