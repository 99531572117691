import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { IconButton } from '@etta/ui/icon-button'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  background: ${(p) => p.theme.colors.white};
  overflow: hidden;
`
export const Wrapper = styled.div``

export const Content = styled.div`
  margin: 0px 24px;
  border-bottom: 2px dashed ${(p) => p.theme.colors.borderDark};
  border-top: 2px dashed ${(p) => p.theme.colors.borderDark};
`

export const Row = styled(Block)`
  display: flex;
  justify-content: space-between;
`

export const TotalRow = styled(Row)`
  :last-child {
    margin-bottom: 23px;
  }

  > :first-child > span {
    margin-right: 8px;
  }
`
export const MidButton = styled(Button)`
  display: flex;
  text-align: left;
  padding: 20px 24px 24px;
  border-radius: 0;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};

  > :last-child {
    margin-right: 0.5rem;
  }
`

export const Footer = styled(Button)`
  display: flex;
  text-align: left;
  padding: 20px 24px 24px;
  border-radius: 0;
  border-top: 2px solid ${(p) => p.theme.colors.borderLight};

  > :last-child {
    margin-right: 0.5rem;
  }

  ${getKeyboardNavigationStyle({ offset: -3, borderRadius: 15 })}
`

export const CancelLabel = styled(Text)`
  margin-top: 0.2rem;
  margin-left: 0.95rem;
`

export const DownloadLabel = styled(Text)`
  margin-top: 0.2rem;
  margin-left: 0.95rem;
`

export const PaymentLabel = styled(Text)`
  margin-right: 5px;

  + div svg {
    margin-top: 1px;
  }
`

export const MainCostText = styled(Text)`
  margin-top: 7px;
`

export const CostSegment = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;

  :last-child {
    margin-top: 10px;
    margin-bottom: 16px;
  }
`

export const IconBreakdown = styled(IconButton)`
  display: inline-block;
  margin-left: -5px;

  svg {
    margin-top: 4px;
  }
`

export const SkeletonContainer = styled(Container)`
  height: 420px;
`

export const ReferencesList = styled(Block)`
  margin: 0 24px;
  padding: 12px 0;

  > ${Block}:last-child {
    margin-bottom: 0;
  }
`
