import { observer } from 'mobx-react-lite'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { FilterButton } from '@etta/components/search-results-header/header-full/header-full-view-mode/filter-button'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import { FilterButtonWrapper } from '@etta/screens/car-rental-search-results/layout/desktop/view-mode/view-mode-styled'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface'
import { TransmissionFilter } from '@etta/components/car-rental-filters/transmission-filter/transmission-filter'
import { CarClassFilter } from '@etta/components/car-rental-filters/car-class-filter'
import { SupplierFilter } from '@etta/components/car-rental-filters/supplier-filter'
import { FuelFilter } from '@etta/components/car-rental-filters/fuel-filter'
import { useCarRentalSearchResultsContextSelector } from '../../../car-rental-search-results-provider'

export const ViewMode = observer(function ViewMode() {
  const state = useCarRentalSearchResultsContextSelector((state) => state)
  const {
    carFiltersModalStore,
    carFiltersStore,
    carRentalSearchStore,
  } = useCarRentalSearchContext()
  const { carSearchFormStore } = useCarRentalSearchFormContext()

  return (
    <SearchResultsHeaderFull.ViewMode
      onEdit={carSearchFormStore.editModeToggle.handleOpen}
      datesLabel={state.desktopDatesLabel}
      locationsLabel={state.desktopLocationsLabel}
      isDisabled={carRentalSearchStore.isLoading}>
      <TransmissionFilter />
      <CarClassFilter />
      <SupplierFilter />
      <FuelFilter />
      <FilterButtonWrapper>
        <FilterButton
          onClick={carFiltersModalStore.carFiltersToggle.handleOpen}
          isFilterActive={!!carFiltersStore.numberOfAppliedFilters}
          numberOfAppliedFilters={carFiltersStore.numberOfAppliedFilters}
          isDisabled={carRentalSearchStore.isLoading}
        />
      </FilterButtonWrapper>
    </SearchResultsHeaderFull.ViewMode>
  )
})
