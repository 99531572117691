import styled from 'styled-components'
import type { HTMLAttributes } from 'react'

export const Container = styled.div<HTMLAttributes<HTMLDivElement>>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
