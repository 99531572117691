import { Domain } from '@etta/core/enums'
import type { TripDetailsCostSummaryValueObject } from '../../../core/value-objects'
import type {
  TripReservations,
  TripCostDetails,
  DomainCostSummary,
  CarRentalReservation,
} from './types'

export class TripCostSummaryMapper {
  static toTripCostSummary(
    costDetails: TripCostDetails,
    reservations: TripReservations,
  ): TripDetailsCostSummaryValueObject {
    const costSummary = costDetails.domainCostSummary.reduce<TripDetailsCostSummaryValueObject>(
      (acc, costSummary) => {
        if (costSummary.domain === Domain.CarRental) {
          if (acc.carRental) {
            return acc
          }

          const carRentalReservations = reservations.reduce(
            (acc: CarRentalReservation[], reservation) => {
              if (reservation.__typename === 'CarRentalReservation') {
                acc.push(reservation)
              }
              return acc
            },
            [],
          )

          const carRental = this.mapCarRentalCostSummary({
            costSummary,
            domains: costDetails.domainCostSummary,
            isPaid: carRentalReservations.every((reservation) => reservation.isPaid),
          })
          return {
            ...acc,
            carRental,
          }
        }
        return acc
      },
      {},
    )

    return {
      ...costSummary,
      totalCost: {
        primary: costDetails.total.primary,
        secondary: costDetails.total.secondary,
      },
    }
  }

  private static mapCarRentalCostSummary({
    costSummary,
    domains,
    isPaid,
  }: {
    costSummary: DomainCostSummary
    domains: DomainCostSummary[]
    isPaid: boolean
  }): TripDetailsCostSummaryValueObject['carRental'] {
    if (costSummary.__typename !== 'CarRentalReservationsCostSummary') {
      return
    }
    const { totalCost, totalRate, totalTaxes, domain } = costSummary
    return {
      cost: totalRate,
      tax: totalTaxes,
      total: totalCost,
      isPaid,
      itemCount: domains.filter((costSummary) => costSummary.domain === domain).length,
    }
  }
}
