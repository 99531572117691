import { useTranslation } from 'react-i18next'
import { TripStatusModal } from '@etta/components/trip-status-modal'
import { AirSeatMapLayout } from './layout'
import type { Props } from './air-seat-map.types'
import { useAirSeatMap } from './use-air-seat-map'

export function AirSeatMap({
  onClose,
  mode,
  skin = 'default',
  subTitle,
  segmentsSeatRows,
  selectedSegmentId,
  cabinClass,
  onApprove,
  initialSegmentSeatNumberList,
  onSkip,
  isLoading,
  onSelectedSegmentIdChange,
  adaptedFlightSegments = [],
  isReadonly = false,
  isErrorSeatMap = false,
  allowSkipSeatSelection,
  seatStateStatus,
  onResetFlightStatus,
  handleFareDetailsTabChange,
}: Props) {
  const { t } = useTranslation()
  const usedSegmentId = selectedSegmentId || segmentsSeatRows[0]?.segmentId
  const props = useAirSeatMap({
    mode,
    segmentsSeatRows,
    initialSegmentSeatNumberList,
    onApprove,
    onSkip,
    isReadonly,
    selectedSegmentId: usedSegmentId,
    onSelectedSegmentIdChange,
    adaptedFlightSegments,
  })

  const selectFlightStatus = seatStateStatus === 'idle' ? null : seatStateStatus

  return (
    <>
      <AirSeatMapLayout
        allowSkipSeatSelection={allowSkipSeatSelection}
        skin={skin}
        onClose={onClose}
        cabinClass={cabinClass}
        isLoading={isLoading}
        segmentsSeatRows={segmentsSeatRows}
        onSelectedSegmentIdChange={onSelectedSegmentIdChange}
        usedSegmentId={usedSegmentId}
        subTitle={subTitle}
        adaptedFlightSegments={adaptedFlightSegments}
        isReadonly={isReadonly}
        isUnexpectedError={props.seatRowsBySegmentErrorOccurred || isErrorSeatMap}
        handleFareDetailsTabChange={handleFareDetailsTabChange}
        {...props}
      />
      {selectFlightStatus && (
        <TripStatusModal
          tripStateStatus={selectFlightStatus}
          onClick={onResetFlightStatus}
          soldOutDescription={t('FlightDetails.Status.SoldOut.SoldOutNewText')}
        />
      )}
    </>
  )
}
