import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace PartnerCoBrandingConfigurationsErrors {
  export const PartnerCoBrandingConfigurationsResponse = createErrorClass(
    'Failed partner co-branding configurations',
  )
  export const UnexpectedErrorPartnerCoBrandingConfigurations = createErrorClass(
    'Processed partner co-branding configurations',
  )
}

export type PartnerCoBrandingConfigurationsErrorsInstances = InstanceType<
  typeof PartnerCoBrandingConfigurationsErrors[keyof typeof PartnerCoBrandingConfigurationsErrors]
>
