import { useState, useCallback, useLayoutEffect } from 'react'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import type { UnmatchedSegment } from './types'

type HookProps = {
  isOpen: boolean
  mistmatchedSegments: UnmatchedSegment[]
}

export function useAlignDatesModal({ mistmatchedSegments, isOpen }: HookProps) {
  const [selectedTypes, setSelectedTypes] = useState(
    new Set<AlignTripTravelVerticalValueObject>([]),
  )

  useLayoutEffect(() => {
    if (isOpen) {
      setSelectedTypes(new Set(mistmatchedSegments.map((item) => item.type)))
    }
  }, [isOpen, mistmatchedSegments])

  const handleSelect = useCallback((type: AlignTripTravelVerticalValueObject) => {
    setSelectedTypes((prev) => {
      const newValue = new Set(prev)
      if (newValue.has(type)) {
        newValue.delete(type)
      } else {
        newValue.add(type)
      }
      return newValue
    })
  }, [])

  return { handleSelect, selectedTypes }
}
