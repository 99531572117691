import { ExpandedView } from '../../expanded-view'
import type { LayoutProps } from '../../types'
import { Container } from './flight-safety-check-desktop-styled'

export function FlightSafetyCheckDesktop({
  safetyChecks,
  safetyCheckDetails,
  onModalOpen,
}: LayoutProps) {
  return (
    <Container>
      {safetyChecks && (
        <ExpandedView
          flightSafetyChecks={safetyChecks}
          hasDetails={!!safetyCheckDetails?.length}
          onModalOpenClick={onModalOpen}
        />
      )}
    </Container>
  )
}
