import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initial-state'

const slice = createSlice({
  name: 'main-navigation',
  initialState,
  reducers: {
    resetMainNavigation(state) {
      return {
        ...state,
        explore: initialState.explore,
        profile: initialState.profile,
        support: initialState.support,
        trips: initialState.trips,
      }
    },
    updateMainNavigationSupport(state, { payload }: PayloadAction<string>) {
      state.support = payload
    },
    updateMainNavigationTrips(state, { payload }: PayloadAction<string>) {
      state.trips = payload
    },
    updateMainNavigationProfile(state, { payload }: PayloadAction<string>) {
      state.profile = payload
    },
    updateMainNavigationExplore(state, { payload }: PayloadAction<string>) {
      state.explore = payload
    },
    updateMainNavigationOngoingBookingFlow(state, { payload }: PayloadAction<boolean>) {
      state.isOngoingBookingFlowActive = payload
    },
    updateMainNavigationTripsForceUpdate(state, { payload }: PayloadAction<boolean>) {
      state.isTripsForceUpdateEnabled = payload
    },
  },
})

export const mainNavigationReducer = slice.reducer

export const {
  resetMainNavigation,
  updateMainNavigationProfile,
  updateMainNavigationSupport,
  updateMainNavigationTrips,
  updateMainNavigationExplore,
  updateMainNavigationOngoingBookingFlow,
  updateMainNavigationTripsForceUpdate,
} = slice.actions
