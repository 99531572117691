import type { Props } from '../types'
import {
  Container,
  InputButton,
  InputButtonGroup,
  LocationIcon,
  LocationIconContainer,
  Value,
  VerticalDelimiter,
} from './location-selector-mobile-styled'

export function LocationSelectorMobile({ pickUp, dropOff, onPickUpClick, onDropOffClick }: Props) {
  return (
    <Container>
      <InputButtonGroup>
        <InputButton onClick={onPickUpClick}>
          <Value>{pickUp}</Value>
        </InputButton>
        <InputButton onClick={onDropOffClick}>
          <Value>{dropOff}</Value>
        </InputButton>
      </InputButtonGroup>
      <LocationIconContainer>
        <LocationIcon icon="pick-up" />
        <VerticalDelimiter />
        <LocationIcon icon="drop-off" />
      </LocationIconContainer>
    </Container>
  )
}
