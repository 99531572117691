import { EmissionsDeviationLevel } from '@fiji/enums'
import EcoCheckAverageSVG from '../assets/eco-check-average.svg?url'
import EcoCheckGreenSVG from '../assets/eco-check-green.svg?url'
import EcoCheckPolutedSVG from '../assets/eco-check-poluted.svg?url'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function HotelDetailsEcoCheckModalMobileImage({ deviationLevel }: Props) {
  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return <img src={EcoCheckAverageSVG} alt="average" />
    case EmissionsDeviationLevel.Less:
      return <img src={EcoCheckGreenSVG} alt="green" />
    case EmissionsDeviationLevel.More: {
      return <img src={EcoCheckPolutedSVG} alt="poluted" />
    }
    default:
      return null
  }
}
