import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate, SegmentType } from '@fiji/graphql/types'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import { HeadingItem, HeadingContent, HeadingContainer } from './policy-reason-heading-styled'

type Props = {
  rate?: Rate | null
  title: string | ReactNode
  type: SegmentType
  ariaLabel?: string
  isHotelRequiredForOvernightTrip?: boolean
}

export function PolicyReasonHeading({
  rate,
  title,
  type,
  ariaLabel,
  isHotelRequiredForOvernightTrip,
}: Props) {
  const { mainCost } = formatRate(rate, { morpheme: 'none', isInteger: true })
  const icon = getIconNameBySegment(type)

  const { t } = useTranslation()
  const i18nBase = 'OutOfPolicyModal.'

  if (isHotelRequiredForOvernightTrip) {
    return (
      <HeadingContainer>
        <HeadingContent isSingleColumn>
          {t(i18nBase + 'HotelRequiredForOvernightTripTitle')}
        </HeadingContent>
      </HeadingContainer>
    )
  }

  return (
    <HeadingContainer>
      <HeadingContent aria-label={ariaLabel}>
        <Icon size="medium" name={icon} />
        <HeadingItem>{title}</HeadingItem>
        <HeadingItem alignRight aria-label={mainCost}>
          {mainCost}
        </HeadingItem>
      </HeadingContent>
    </HeadingContainer>
  )
}
