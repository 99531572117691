export default function CarryOnBagIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 8V4H16V8H18V12H16C13.7909 12 12 13.7909 12 16V32C12 34.2091 13.7909 36 16 36V38C16 39.1046 16.8954 40 18 40C19.1046 40 20 39.1046 20 38V36H24V38C24 39.1046 24.8954 40 26 40C27.1046 40 28 39.1046 28 38V36C30.2091 36 32 34.2091 32 32V16C32 13.7909 30.2091 12 28 12H26V8H28ZM20 8H24V12H20V8ZM16 14.5C15.1716 14.5 14.5 15.1716 14.5 16V32C14.5 32.8284 15.1716 33.5 16 33.5H28C28.8284 33.5 29.5 32.8284 29.5 32V16C29.5 15.1716 28.8284 14.5 28 14.5H16Z"
        fill="currentColor"
      />
    </svg>
  )
}
