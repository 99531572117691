import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'

export const StyledButton = styled(Button)`
  line-height: 100%;
`

export const StyledText = styled(Text)<{ paddingRight?: number }>`
  line-height: 100%;
  ${({ paddingRight }) =>
    paddingRight &&
    `
    padding-right: ${paddingRight}px;
  `}
`

export const StyledLink = styled(Link)`
  line-height: 100%;
`

export const StyledBlock = styled(Block)`
  align-items: flex-end;
`

export const Container = styled(Block)<{ withBottomBorder: boolean }>`
  ${(p) =>
    p?.withBottomBorder &&
    `
    border-bottom: 1px solid ${p.theme.colors.borderLight};
  `}
`
