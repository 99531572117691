import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { CarRentalDetailsEcoCheckMobile } from './mobile'
import { CarRentalDetailsEcoCheckDesktop } from './desktop'

export function CarRentalDetailsEcoCheckLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalDetailsEcoCheckMobile {...props} />}
      desktopSlot={<CarRentalDetailsEcoCheckDesktop {...props} />}
    />
  )
}
