/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import type { Dates } from '@etta/ui/date-picker'

type Args = Dates & {
  isOpen: boolean
  onClose: () => void
  onSelect: (result: Dates) => void
  withConfirmOneWayButton: boolean
  withConfirmButton: boolean
  shouldCloseOnClickOutside?: boolean
  withNextButton: boolean
  isDepartureDatePickerDisabled?: boolean
}

export default function useDatePicker({
  date,
  dateEnd,
  onSelect,
  onClose,
  isOpen,
  withConfirmOneWayButton,
  withConfirmButton,
  shouldCloseOnClickOutside,
  withNextButton,
  isDepartureDatePickerDisabled,
}: Args) {
  const { t } = useTranslation()
  const [dates, setDates] = useState<Dates>({ date, dateEnd })

  let isFooterVisible = !!date || !!dates.date
  const isEndDateSelected = !!dateEnd || !!dates.dateEnd

  if (dateEnd) {
    isFooterVisible = !!dates.date && !!dates.dateEnd
  }

  const resetDates = () => setDates({ date, dateEnd })
  const selectHandler = () => {
    if (isDepartureDatePickerDisabled) {
      onSelect({ date, dateEnd: dates.dateEnd })
      onClose()
      return
    }

    onSelect(dates)
    onClose()
  }

  const closeHandler = () => {
    resetDates()
    onClose()
  }

  useEffect(() => {
    resetDates()
  }, [isOpen])

  const { ref } = useClickOutside(() => {
    if (shouldCloseOnClickOutside && isOpen) {
      onClose()
    }
  })

  const handleDatesSelect = (dates: Dates) => {
    if (isDepartureDatePickerDisabled) {
      setDates({ date, dateEnd: dates.dateEnd })
      return
    }

    setDates(dates)
  }

  let footerTitle = ''
  if (isEndDateSelected || withConfirmButton) {
    footerTitle = t('DatePickerModal.Confirm')
  } else if (withConfirmOneWayButton) {
    footerTitle = t('DatePickerModal.ConfirmOneway')
  } else if (withNextButton) {
    footerTitle = t('DatePickerModal.Next')
  }

  return {
    dates,
    handleDatesSelect,
    footerTitle,
    selectHandler,
    closeHandler,
    isFooterVisible,
    ref,
  }
}
