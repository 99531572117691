import { UberTrackingRideViaQRCode } from '@etta/components/uber-tracking-ride-via-qr-code'
import { Swap } from '@etta/ui/swap/swap'
import { ScreenType, useScreenType } from '@fiji/modes'
import {
  Container,
  Description,
  RetryButton,
  InfoImage,
  WarningText,
  UberQrCodeWrapper,
} from './mobility-search-result-error-styled'
import { MobilitySearchResultErrorType } from './mobility-search-result-error-type.enum'
import { useMobilitySearchResultError } from './use-mobility-search-result-error'

type Props = {
  type?: MobilitySearchResultErrorType
  onRetry: () => void
  onEditSearch: () => void
  uberQrcodeTitle: string
}

export function MobilitySearchResultError({
  type = MobilitySearchResultErrorType.Undefined,
  onRetry,
  onEditSearch,
  uberQrcodeTitle,
}: Props) {
  const {
    getImage,
    getWarningText,
    getDescription,
    getButtonText,
    getStyle,
    handleClick,
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  } = useMobilitySearchResultError({ type, onRetry, onEditSearch })
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const shouldShowGoToUberQrCode =
    isDesktop &&
    (type === MobilitySearchResultErrorType.ErrorLocationUnavailable ||
      type === MobilitySearchResultErrorType.ArrivalFlightNotFound)
  const shouldGoToUberWithParam = type === MobilitySearchResultErrorType.ErrorLocationUnavailable

  return (
    <Container data-tracking-id={`ride-hail-error_${type}`}>
      {getImage && getStyle.iconHeight && <InfoImage src={getImage} height={getStyle.iconHeight} />}
      <WarningText textVariant={getStyle.warningTextVariant}>{getWarningText}</WarningText>
      <Description textVariant={getStyle.descriptionTextVariant}>{getDescription}</Description>
      <Swap
        is={shouldShowGoToUberQrCode}
        isSlot={
          <UberQrCodeWrapper>
            <UberTrackingRideViaQRCode
              pickUpPlace={pickUpPlace}
              dropOffPlace={dropOffPlace}
              pickUpDate={pickUpDate}
              pickUpTime={pickUpTime}
              isUberWithParam={shouldGoToUberWithParam}
              title={uberQrcodeTitle}
            />
          </UberQrCodeWrapper>
        }>
        <RetryButton onClick={handleClick}>{getButtonText}</RetryButton>
      </Swap>
    </Container>
  )
}
