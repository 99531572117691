import { Service, Inject } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { BaseSettingsStore } from '@etta/modules/settings/interface/stores/base.store'
import { TravelPreferencesStore } from '../stores/travel-preferences.store'
import { TravelPreferencesAdapter } from '../../infra/travel-preferences.adapter'
import type { Preference } from '../types'

@Service()
export class TravelPreferencesService {
  constructor(
    @Inject() private travelPreferencesStore: TravelPreferencesStore,
    @Inject() private travelPreferencesAdapter: TravelPreferencesAdapter,
    @Inject() private baseSettingsStore: BaseSettingsStore,
    @Inject() private cookieStore: CookieStore,
  ) {}

  async loadTravelPreferences() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.travelPreferencesStore.setIsError(false)
    this.travelPreferencesStore.setIsLoading(true)

    const result = await this.travelPreferencesAdapter.loadTravelPreference()

    result.match({
      Ok: (data) => {
        this.travelPreferencesStore.setTravelPreferences(data)
        this.travelPreferencesStore.setIsLoading(false)
      },
      Err: () => {
        this.travelPreferencesStore.setIsError(true)
        this.travelPreferencesStore.setIsLoading(false)
      },
    })
  }

  async refetchTravelPreferences(preference?: Preference) {
    this.travelPreferencesStore.setIsError(false)
    this.baseSettingsStore.setIsPartialUserLoading(true)

    const result = await this.travelPreferencesAdapter.loadTravelPreference()

    result.match({
      Ok: (data) => {
        if (preference) {
          this.travelPreferencesStore.setSingleTravelPreference(data[preference], preference)
          return
        }
        this.baseSettingsStore.setIsPartialUserLoading(false)
        this.travelPreferencesStore.setTravelPreferences(data)
      },
      Err: () => {
        this.baseSettingsStore.setIsPartialUserLoading(false)
        this.travelPreferencesStore.setIsError(true)
      },
    })
  }
}
