import { Inject, Service } from '@etta/di'
import { UserService } from '@etta/modules/user'
import { PassportAdapter } from '../../../infra/passport'
import { PassportErrors } from '../../../core/errors'
import { PassportStore } from '../stores'
import type { AddPassportArgs, RemovePassportArgs } from '../types'

@Service()
export class PassportService {
  constructor(
    @Inject()
    private readonly passportStore: PassportStore,
    @Inject()
    private readonly passportAdapter: PassportAdapter,
    @Inject()
    private readonly getUserService: UserService,
  ) {}

  async addPassport(args: AddPassportArgs) {
    this.passportStore.setIsPassportError(false)
    this.passportStore.setIsPassportLoading(true)
    const result = await this.passportAdapter.addPassport(args)
    await this.getUserService.reloadUser()

    result.match({
      Ok: () => {
        this.passportStore.updateSelectedPassportId(null)
        this.passportStore.updateSelectedPassportCountry(args.passport.issueCountry || null)
        this.passportStore.setIsPassportLoading(false)
      },
      Err: (res) => {
        this.passportStore.setIsPassportError(true)
        this.passportStore.setIsPassportLoading(false)
        if (res instanceof PassportErrors.InvalidInputError) {
          this.passportStore.setInputErrors(res.error)
        }
      },
    })

    return result
  }

  async removePassport(args: RemovePassportArgs) {
    this.passportStore.setIsPassportError(false)
    this.passportStore.setIsPassportLoading(true)
    const result = await this.passportAdapter.removePassport(args)
    await this.getUserService.reloadUser()

    result.match({
      Ok: () => {
        this.passportStore.updateSelectedPassportId(null)
        this.passportStore.updateSelectedPassportCountry(null)
        this.passportStore.setIsPassportLoading(false)
      },
      Err: () => {
        this.passportStore.setIsPassportError(true)
        this.passportStore.setIsPassportLoading(false)
      },
    })

    return result
  }
}
