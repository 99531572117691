import type { CalculateTripEmissionsResult } from '@fiji/graphql/types'
import { TripDetailsEcoCheckSummary } from '@etta/components/trip-details-eco-check-summary/trip-details-eco-check-summary'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { EcoCheckSummarySkeleton } from './eco-check-sumary-skeleton'

type Props = {
  loading: boolean
  tripEmissionsResult?: CalculateTripEmissionsResult
  segments?: SegmentPostBookingValueObject[]
}

export function EcoCheckSummary({ loading, tripEmissionsResult, segments }: Props) {
  if (loading) {
    return <EcoCheckSummarySkeleton />
  }

  if (tripEmissionsResult && tripEmissionsResult.errors?.length === 0) {
    return (
      <TripDetailsEcoCheckSummary tripEmissionsResult={tripEmissionsResult} segments={segments} />
    )
  }

  return null
}
