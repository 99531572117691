import type { ConcernList } from '../types'
import { ConcernScoreListLayout } from './layout'

type Props = {
  isLoading: boolean
  concernList: ConcernList | undefined
}

export function ConcernScoreList({ concernList, isLoading }: Props) {
  if (!isLoading && !concernList) {
    return null
  }

  return <ConcernScoreListLayout concernList={concernList} isLoading={isLoading} />
}
