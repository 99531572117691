import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CancelTripDataValueObject } from '@etta/modules/post-booking/core/value-objects'
import { InfoWrapper, SegmentContainer, TextWrapper } from '../cancel-trip-modal-styled'
import { TripSegmentCheckbox } from '../trip-segment-checkbox/trip-segment-checkbox'

type Props = {
  rideHailSegmentData: CancelTripDataValueObject[]
}
const i18nBase = 'PostBooking.RideHailDetails.'

export function FullScreenRideHailCancelSection({ rideHailSegmentData }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Text variant="headline" color="mainText">
        {t(i18nBase + 'CancelTripTitle')}
      </Text>
      <Block marginTop={10}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'CancelTripContent')}
        </Text>
      </Block>
      <Block marginTop={16}>
        <SegmentContainer>
          {rideHailSegmentData.map((item) => (
            <TripSegmentCheckbox rideHailSegment={item} />
          ))}
        </SegmentContainer>
      </Block>
      <Block marginTop={10}>
        <InfoWrapper>
          <Icon name="infoOutlinePWA" color="bodyText" />
          <TextWrapper>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'CancelTripInfo')}
            </Text>
          </TextWrapper>
        </InfoWrapper>
      </Block>
    </>
  )
}
