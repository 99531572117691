import styled from 'styled-components'

export const LoadingContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const SpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.seatMap.charcoal7};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`
