import { ErrorSubtitle } from '../purchase-reservation-error-styled'
import { useErrorMsgComposed } from './use-error-msg-composed'

type Props = {
  errorMsg?: string[] | null
}

export function ErrorMessage({ errorMsg }: Props) {
  const msg = useErrorMsgComposed(errorMsg)
  if (!msg || msg.length === 0) {
    return <></>
  }
  return <ErrorSubtitle>{msg}</ErrorSubtitle>
}
