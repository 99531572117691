import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  gap: 2px;
  color: ${(p) => p.theme.colors.bodyText};
  align-items: center;
  ${footnoteMedium};
`
