import styled from 'styled-components'
import { largeTitle, footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const IconWrapper = styled.div`
  margin-bottom: 20px;
`

export const Title = styled.div`
  ${largeTitle};
  color: ${(props) => props.theme.colors.mainText};
  text-align: center;
  margin-bottom: 32px;
`

export const Footnote = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  text-align: center;
  margin-bottom: 30px;
`

export const UberQrCodeWrapper = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  border-radius: 14px;
`
