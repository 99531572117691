import styled from 'styled-components'
import { screenSize } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 13px 17px 0;
  min-height: 48px;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.background1};
  }
  @media (${screenSize.maxMobile}) {
    padding: 17px 26px 16px 0;
    margin-left: 42px;
  }
`

export const InlineIcon = styled(Icon)`
  position: absolute;
  right: 0;
  @media (${screenSize.maxMobile}) {
    right: 20px;
  }
`
