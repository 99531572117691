import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type { TravelPolicyValueObject } from '../core/value-objects'
import type { TravelPolicyErrorsInstances } from '../core/errors'
import { TravelPolicyErrors } from '../core/errors'
import { TravelPolicyMapper } from './mapper'
import { TravelPolicyDataProvider } from './travel-policy.data-provider'

@Adapter()
export class TravelPolicyAdapter {
  constructor(
    @Inject()
    private travelPolicyDataProvider: TravelPolicyDataProvider,
  ) {}

  async getTravelPolicy(): PromisedResult<TravelPolicyValueObject, TravelPolicyErrorsInstances> {
    try {
      const { data, error } = await this.travelPolicyDataProvider.getTravelPolicy()

      if (error) {
        return Result.Err(new TravelPolicyErrors.TravelPolicyResponse(error))
      }
      const travelPolicy = TravelPolicyMapper.toValueObject(data.travelPolicy)

      return Result.Ok(travelPolicy)
    } catch (e) {
      return Result.Err(new TravelPolicyErrors.TravelPolicyUnexpectedError(e))
    }
  }
}
