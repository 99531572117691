import type { NotificationEntity } from '@etta/core/entities'
import type { NotificationsTopicEnum } from '@etta/modules/settings/core/enums/notifications-topic.enum'

export const isNotificationDuplicated = (
  existingNotifications: NotificationEntity[],
  {
    newEmail,
    newPhoneCountryCode,
    newPhoneNumber,
    currentTopic,
  }: {
    newEmail?: string
    newPhoneCountryCode?: string
    newPhoneNumber?: string
    currentTopic?: NotificationsTopicEnum | null
  },
): boolean =>
  existingNotifications &&
  existingNotifications.some(({ email, phone, topic }: NotificationEntity) => {
    const isSameTopic = topic === currentTopic
    const isSameEmail = email && newEmail && email === newEmail
    const isSamePhoneNumber =
      phone &&
      newPhoneNumber &&
      newPhoneCountryCode &&
      phone.number === newPhoneNumber &&
      phone.isoCountryCode === newPhoneCountryCode

    return isSameTopic && (isSameEmail || isSamePhoneNumber)
  })
