import styled, { css } from 'styled-components'
import { Radio } from '@etta/ui/radio'
import { RadioInput, RadioPoint } from '@etta/ui/radio/radio-styled'
import type { CardLayoutType } from './types'

export const Container = styled.div<{
  withBorder?: boolean
  isDisabled?: boolean
  layout?: CardLayoutType
}>`
  display: flex;
  align-items: center;
  padding: 14px 0;
  border-bottom: ${(props) =>
    props.withBorder && css`1px solid ${props.theme.colors.borderLight};`};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  &:active {
    background-color: ${(p) => (p.layout === 'normal' ? p.theme.colors.background : 'inherit')};
  }
`

export const DisabledRadio = styled(Radio)`
  pointer-events: none;

  ${RadioInput} {
    &:checked + ${RadioPoint} {
      background-color: ${(props) => props.theme.colors.borderLight};
      border: 2px solid ${(props) => props.theme.colors.borderLight};
    }
  }
  ${RadioPoint} {
    border: 2px solid
      ${(props) =>
        props.isChecked ? props.theme.colors.borderLight : props.theme.colors.borderDark};
    background: ${(props) =>
      props.isChecked ? props.theme.colors.borderLight : props.theme.colors.white};
  }
`
