import { useTranslation } from 'react-i18next'
import type { Key } from 'react'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { useEttaMapContext } from '@etta/modules/etta-map/interface/use-etta-map.context'
import {
  Container,
  ActiveMarkerTooltip,
  ActiveMarkerContainer,
  Dialog,
  Flex,
  VerticalFlex,
  Image,
  TimeContainer,
  TimeContainerText,
  VendorLogos,
  ButtonContainer,
} from './marker-styled'
import { ActiveMarker, InactiveMarker } from './assets'
import { useMarker } from './use-marker'

type Props = {
  isInteractive: boolean
  isOpen: boolean
  isDropOff?: boolean
  isPickUp?: boolean
  onSelect: () => void
  fullAddress: string
  workingHours: string
  vendorLogos: string[]
  isAirportLocation?: boolean
  markerKey: Key
}

const i18nBase = 'CarRentalOfficeSearch.Map'
const MAX_VENDOR_LOGOS = 1

export function Marker({
  isInteractive,
  isOpen,
  isDropOff,
  isPickUp,
  fullAddress,
  markerKey,
  workingHours,
  vendorLogos,
  isAirportLocation,
  onSelect,
}: Props) {
  const { ettaMapStore } = useEttaMapContext()
  const { isAppleMap } = ettaMapStore
  const { t } = useTranslation()
  const { activeMarkerText, isActive } = useMarker({ isDropOff, isPickUp })
  const iconName = isAirportLocation ? 'flightPWA' : 'searchPinPWA'

  const buttonLabel = t(`${i18nBase}.Select`)

  return (
    <Container isActive={isActive} aria-label={buttonLabel}>
      <ActiveMarkerContainer>
        {isActive ? (
          <>
            <ActiveMarker />

            <ActiveMarkerTooltip variant="captionStrongCaps" color="mainText2">
              {activeMarkerText}
            </ActiveMarkerTooltip>
          </>
        ) : (
          <InactiveMarker />
        )}
      </ActiveMarkerContainer>
      <EttaMap.Dialog markerKey={markerKey}>
        <Dialog isAppleMap={isAppleMap}>
          <Flex>
            <Icon name={iconName} color="primary" />
            <VerticalFlex>
              <Text variant="footnoteStrong">{fullAddress}</Text>
              <VendorLogos>
                {vendorLogos.slice(0, MAX_VENDOR_LOGOS).map((logo) => (
                  <Image key={logo} alt={t('Accessibility.CarRental.VendorLogoAlt')} src={logo} />
                ))}

                {vendorLogos.length > MAX_VENDOR_LOGOS && (
                  <Block marginLeft={4}>
                    <Text variant="linkSmall" color="bodyText">
                      {t('TripPlanner.Place.MoreVendors', {
                        count: vendorLogos.length - MAX_VENDOR_LOGOS,
                      })}
                    </Text>
                  </Block>
                )}
              </VendorLogos>
            </VerticalFlex>
          </Flex>
          <TimeContainer>
            <Icon name="timePWA" color="bodyText" />
            <TimeContainerText variant="footnoteStrong" color="bodyText">
              {workingHours}
            </TimeContainerText>
          </TimeContainer>
          {isInteractive && (
            <ButtonContainer>
              <Button
                fullWidth
                size="small"
                disabled={!isOpen}
                onClick={isOpen ? onSelect : undefined}
                data-tracking-id="car-rental-map-marker-select-button">
                {buttonLabel}
              </Button>
            </ButtonContainer>
          )}
        </Dialog>
      </EttaMap.Dialog>
    </Container>
  )
}
