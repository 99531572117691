import { Block } from '@etta/ui/block'
import { RoomRatesModalSkeleton } from '../../../room-rates-modal-skeleton'
import { Container } from '../../../room-rates-modal-styled'
import type { LayoutProps } from '../types'

export function RoomRatesModalContentDesktop({
  hotelNameTextSlot,
  isDetailsLoading,
  roomRatesSlot,
  roomDetailsSlot,
  roomTypeTextSlot,
  isUpcoming,
}: LayoutProps) {
  if (isDetailsLoading) {
    return (
      <>
        <RoomRatesModalSkeleton />
        <RoomRatesModalSkeleton />
      </>
    )
  }

  return (
    <Container>
      <Block marginTop={16}>
        {hotelNameTextSlot}
        <Block marginTop={5}>{roomTypeTextSlot}</Block>
      </Block>
      <Block marginTop={18}>{roomRatesSlot}</Block>
      {isUpcoming && <Block marginTop={36}>{roomDetailsSlot}</Block>}
    </Container>
  )
}
