import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container } from './contextual-message-styled'

type Props = {
  messages: string[]
}

export default function ContextualMessages({ messages }: Props) {
  const { t } = useTranslation()

  return (
    <>
      {messages.length !== 0 && (
        <Container>
          {messages.map((message) => {
            if (t(`ComplianceMessageTitles.${message}`) === `ComplianceMessageTitles.${message}`) {
              return null
            }

            return (
              <>
                <Block isFlexBlock paddingBottom={6}>
                  <Icon name="unusedTicketPWA" color="warning" />
                  <Text
                    data-tracking-id="contextual-message-title"
                    variant="footnoteStrong"
                    color="mainText1">
                    {t(`ComplianceMessageTitles.${message}`, message)}
                  </Text>
                </Block>
                <Text
                  data-tracking-id="contextual-message-details"
                  variant="footnoteMedium"
                  color={'bodyText'}>
                  {t(`ComplianceMessages.${message}`, message)}
                </Text>
              </>
            )
          })}
        </Container>
      )}
    </>
  )
}
