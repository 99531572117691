import { useMemo } from 'react'
import type { Segments } from '../../types'

type Args = {
  segments: Segments
}

export function useTripDetails({ segments }: Args) {
  const isWebFare = useMemo(() => {
    const hasTravelFusionSegment = segments.flight?.legs.some((s) => {
      if (s.isWebFare) {
        return true
      }
    })

    return hasTravelFusionSegment
  }, [segments])

  return {
    isWebFare,
  }
}
