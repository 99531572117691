import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import type { CarServicePostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ROUTES } from '@fiji/routes'

type Args = {
  carService: CarServicePostBookingValueObject
  tripType: TripStatus
}

const i18Base = 'CarServiceDetails.Headline'

export const useTripCarService = ({ tripType, carService }: Args) => {
  const history = useHistory()
  const cancelTripModal = useTogglePopup()
  const detailsModal = useTogglePopup()
  const modifyTripModal = useTogglePopup()

  const { t } = useTranslation()
  const isCancelled = tripType === TripStatus.Cancelled
  const isCompleted = tripType === TripStatus.Completed
  const isOnHold = tripType === TripStatus.OnHold

  const { dropOff, pickUp } = carService

  const isDirect = dropOff?.location?.asDirected

  const onViewSupport = useCallback(() => {
    history.push(ROUTES.support.main)
  }, [history])

  const headline = useMemo(() => {
    if (isDirect || !dropOff) {
      return t(`${i18Base}.RideFrom`, { location: pickUp?.address?.city })
    }

    return t(`${i18Base}.RideTo`, { location: dropOff?.address?.city })
  }, [isDirect, pickUp, dropOff, t])

  return {
    isCancelled,
    isCompleted,
    isOnHold,
    headline,
    detailsModal,
    modifyTripModal,
    cancelTripModal,
    onViewSupport,
  }
}
