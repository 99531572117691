import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  List,
  ItemContainer,
  ListItem,
  Location,
  LeftBlock,
  RightBlock,
  BottomLine,
  CentralBlock,
  TopLine,
} from './hotel-search-page-mobile-recent-searches-styled'

export const HotelSearchFormMobilePageRecentSearchItems = observer(
  function HotelSearchFormMobilePageRecentSearchItems() {
    const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()

    const recentSearchesList = recentSearchesStore.recentSearches.hotel.items

    if (!recentSearchesStore.isShowHotelRecentSearch) {
      return null
    }

    return (
      <List>
        {recentSearchesList?.map((item) => {
          const { bottomLine, recreateSearch } = recentSearchDataAction.getRecentHotelSearches(
            item.hotelSearchParams,
          )
          return (
            <li>
              <ItemContainer onClick={recreateSearch} data-tracking-id="hotel-recent-search-card">
                <ListItem>
                  <LeftBlock>
                    <Icon name="hotelPWA" />
                  </LeftBlock>
                  <CentralBlock>
                    <TopLine>
                      <Location>{item.hotelSearchParams.location.name}</Location>
                    </TopLine>
                    <BottomLine>{bottomLine}</BottomLine>
                  </CentralBlock>
                  <RightBlock>
                    <Icon name="chevronRightPWA" color="borderLight" size={22} />
                  </RightBlock>
                </ListItem>
              </ItemContainer>
            </li>
          )
        })}
      </List>
    )
  },
)
