import { Inject, Store } from '@etta/di'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import { Toggle } from '@etta/interface/services/toggle'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import type { HotelSearchQueryType } from '@fiji/hooks/search-queries/use-hotel-search-query/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { ROUTES } from '@fiji/routes'
import { HotelQueryStore } from './hotel-search-query.store'

type InitialFiltersArgs = {
  groupId?: string
  initialDistance?: number
}

export type ValidationErrors = {
  checkoutDate?: string
  checkinDate?: string
  location?: string
}

export type HotelEditCache = Partial<
  Pick<HotelSearchQueryType, 'location' | 'checkInDate' | 'checkOutDate' | 'bookingId'>
> & {
  distance?: number
  itineraryId?: string
}

const DEFAULT_DISTANCE_VALUE = 5

@Store()
export class HotelSearchFormStore {
  validationErrors: ValidationErrors = {}
  editFormModalToggle = new Toggle()

  hotelInitialLocation = new LocalStorageItem<{ lat: number; lng: number }>(
    '@hotel/search-location',
    {
      initialValue: {
        lat: 0,
        lng: 0,
      },
    },
  )
  hotelEditCache: HotelEditCache = {}

  constructor(
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly historyStore: HistoryStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
  ) {}

  get isEditMode() {
    const { itineraryId } = this.historyStore.getParams<{ itineraryId: string }>() || {}
    const isRTP = this.historyStore.matchPathname(ROUTES.reviewTrip.main({ itineraryId }))

    const isDesktop = screenMatcher.getScreenType() !== ScreenType.Mobile
    const isHotelResults = this.historyStore.matchPathname(ROUTES.hotel.results)

    return isDesktop && (isRTP || isHotelResults)
  }

  get hotelForm() {
    if (this.isEditMode) {
      const {
        location = null,
        checkInDate = null,
        checkOutDate = null,
        itineraryId,
        bookingId,
      } = this.hotelEditCache
      return {
        itineraryId,
        location,
        checkInDate,
        checkOutDate,
        bookingId,
        filters: this.defaultForm.hotelFilters,
      }
    }
    const params = this.hotelSearchQuery
    return {
      location: params.location || this.defaultForm.location,
      filters: params.filters || this.defaultForm.hotelFilters,
      checkInDate: params.checkInDate || this.defaultForm.checkInDate,
      checkOutDate: params.checkOutDate || this.defaultForm.checkOutDate,
      postBookingAction: params.postBookingAction || this.defaultForm.postBookingAction,
      itineraryId: params.itineraryId,
      bookingId: params.bookingId,
    }
  }

  get hotelSearchQuery() {
    const {
      location,
      hotelFilters,
      checkInDate,
      checkOutDate,
      postBookingAction,
      itineraryId,
      hotelResultId,
      isSearchByNewArea,
      bookingId,
    } = this.hotelQueryStore.hotelSearchQuery
    return {
      location,
      filters: hotelFilters,
      checkInDate,
      checkOutDate,
      postBookingAction,
      itineraryId,
      hotelResultId,
      isSearchByNewArea,
      bookingId,
    }
  }

  setValidationErrors(input: ValidationErrors) {
    this.validationErrors = input
  }

  setInitialLocation(location: { lat: number; lng: number }) {
    this.hotelInitialLocation.set(location)
  }

  updateEditCache(data: Partial<HotelEditCache>) {
    this.hotelEditCache = {
      ...this.hotelEditCache,
      ...data,
    }
  }

  private get defaultForm(): HotelSearchQueryType {
    const displayConfigurationSearchRadius = this.displayConfigurationStore.displayConfiguration
      .hotelSearchRadius.default
    const initialDistance = this.isEditMode
      ? this.hotelEditCache.distance || displayConfigurationSearchRadius
      : displayConfigurationSearchRadius

    return {
      location: null,
      hotelFilters: this.initialFilters({
        initialDistance,
      }),
    }
  }

  private initialFilters(filters: InitialFiltersArgs) {
    const { groupId = '', initialDistance = DEFAULT_DISTANCE_VALUE } = filters
    return {
      initialDistance,
      distance: initialDistance,
      amenityIds: [],
      brandIds: [],
      hotelName: '',
      starRatings: [],
      groupId,
    }
  }
}
