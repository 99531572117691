import { Store } from '@etta/di'

@Store()
export class UnusedTicketOptStore {
  isLoading = false

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }
}
