import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import type { CarVendorInformation } from '../../../types'
import {
  Container,
  Section,
  CarVendorLogo,
  Information,
  InformationLabel,
  LogoImage,
  Row,
  LeftSection,
  RightSection,
  VendorInformationTitleContainer,
  VendorInformationHeaderContainer,
  CarOutOfPolicyContainer,
} from './car-rental-details-vendor-information-styled'

type Props = {
  vendorInformation: CarVendorInformation | null
  isPreferred?: boolean
  isInPolicy?: boolean
}

export function CarRentalDetailsVendorInformation({
  vendorInformation,
  isPreferred,
  isInPolicy,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalModal.'

  const vendorAriaLabel = t('CarRentalModal.VendorAriaLabel', {
    supplier: vendorInformation?.name,
    location: vendorInformation?.address,
    timings: vendorInformation?.workingHours,
    phoneNumber: vendorInformation?.phone || '-',
  })

  if (!vendorInformation) {
    return null
  }

  const linkAriaLabel = vendorInformation.phone?.split('').join(' ')

  return (
    <Container aria-label={vendorAriaLabel}>
      <Section>
        <VendorInformationHeaderContainer>
          <VendorInformationTitleContainer>
            {vendorInformation.imageUrl && (
              <CarVendorLogo>
                <LogoImage src={vendorInformation.imageUrl} alt={vendorInformation.title} />
              </CarVendorLogo>
            )}
            <Text aria-label={vendorInformation.title} color="mainText" variant="footnoteMedium">
              {vendorInformation.title}
            </Text>
          </VendorInformationTitleContainer>
          {!isInPolicy && !isPreferred && (
            <CarOutOfPolicyContainer>
              <Icon name="outOfPolicyPWA" size="small" />
              <Text variant="footnoteMedium" color="bodyText">
                {t('CarRentalDetails.OutOfPolicy')}
              </Text>
            </CarOutOfPolicyContainer>
          )}
        </VendorInformationHeaderContainer>
      </Section>

      <Row>
        {vendorInformation.phone && (
          <LeftSection>
            <Information data-tracking-id="car-rental-trip-segment-phone-container">
              <div>
                <Link href={`tel:${vendorInformation.phone}`} aria-label={linkAriaLabel}>
                  <InformationLabel>
                    <Text variant="captionMedium" color="bodyText">
                      {t(i18Base + 'CallVendor', { supplier: vendorInformation.name })}
                    </Text>
                  </InformationLabel>
                  <Text variant="subHeadStrong" color="mainText">
                    {vendorInformation.phone}
                  </Text>
                </Link>
              </div>
              <Link href={`tel:${vendorInformation.phone}`} aria-label={linkAriaLabel}>
                <Icon name="phonePWA" color="bodyText" size="medium" />
              </Link>
            </Information>
          </LeftSection>
        )}
        {vendorInformation.workingHours && (
          <RightSection>
            <Information>
              <div>
                <InformationLabel>
                  <Text variant="captionMedium" color="bodyText">
                    {t(i18Base + 'DeskTimings')}
                  </Text>
                </InformationLabel>
                <Text variant="subHeadStrong">{vendorInformation.workingHours}</Text>
              </div>
              <Text variant="captionMedium" color="bodyText">
                <Icon name="timePWA" size="medium" />
              </Text>
            </Information>
          </RightSection>
        )}
      </Row>
    </Container>
  )
}
