import type { LayoutControlsProps } from '@etta/ui/date-time-picker/controls/types'
import { MediaLayout } from '@etta/ui/media-layout'

import { ControlsLayoutMobile } from './mobile/controls-layout-mobile'
import { ControlsLayoutDesktop } from './desktop/controls-layout-desktop'

export function ControlsLayout(props: LayoutControlsProps) {
  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<ControlsLayoutMobile {...props} />}
      desktopSlot={<ControlsLayoutDesktop {...props} />}
    />
  )
}
