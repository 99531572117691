import { Inject, Service } from '@etta/di'
import { ItineraryAdapter, CartAdapter } from '@etta/modules/review-trip/infra'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { PreSearchStore } from '@etta/modules/pre-search'
// eslint-disable-next-line import/no-restricted-paths
import { ResetBookTripInfoService } from '@etta/modules/booking/interface/reset-book-trip-info/reset-book-trip-info.service'
import { ReviewTripStore } from '../stores/review-trip-page.store'

@Service()
export class CreateTripService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
    @Inject()
    private readonly resetBookTripInfoService: ResetBookTripInfoService,
  ) {}

  async createTrip() {
    const {
      isMod2FlowEnabled,
      isMod2DoubleWriteEnabled,
    } = this.displayConfigurationStore.displayConfiguration

    if (isMod2FlowEnabled) {
      await this.createCart()
      return
    }

    if (isMod2DoubleWriteEnabled) {
      await Promise.allSettled([this.createCartForModFlow(), this.createItinerary()])
      return
    }
    await this.createItinerary()
  }

  private async createCartForModFlow() {
    const result = await this.cartAdapter.createCart()
    result.match({
      Err: () => {},
      Ok: (data) => {
        this.reviewTripStore.setCartId(data.cartId)
      },
    })
  }

  private async createCart() {
    this.resetBookTripInfoService.resetInfo()
    this.reviewTripStore.setIsTripCreatingLoading(true)
    this.reviewTripStore.setIsTripCreatingError(false)

    const result = await this.cartAdapter.createCart()

    result.match({
      Err: () => {
        this.reviewTripStore.setIsTripCreatingError(true)
        this.reviewTripStore.setIsTripCreatingLoading(false)
      },
      Ok: (data) => {
        this.reviewTripStore.setTripId(data.cartId)
        this.reviewTripStore.setIsTripCreatingLoading(false)
      },
    })
  }

  private async createItinerary() {
    this.resetBookTripInfoService.resetInfo()
    this.reviewTripStore.setIsTripCreatingLoading(true)
    this.reviewTripStore.setIsTripCreatingError(false)
    this.reviewTripStore.setTripId('')

    const customFields = this.preSearchStore.selectedCustomFields
    const result = await this.itineraryAdapter.createItinerary({
      customFields,
    })

    result.match({
      Err: () => {
        this.reviewTripStore.setIsTripCreatingError(true)
        this.reviewTripStore.setIsTripCreatingLoading(false)
      },
      Ok: (data) => {
        this.reviewTripStore.setTripId(data.id)
        this.reviewTripStore.setIsTripCreatingLoading(false)
      },
    })
  }
}
