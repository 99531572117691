import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { LazyContent } from '@etta/ui/lazy-content'
import { Screen } from '@etta/ui/screen'
import { Swap } from '@etta/ui/swap'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { TotalCost } from '@etta/components/footer-costs/total-cost/total-cost'
import type { LayoutProps } from '../../types'
import { ErrorState } from '../../error-state'
import { TravelFusionLinksContent } from '../../travel-fusion-links-content/travel-fusion-links-content'
import { Title } from './travel-fusion-links-mobile-styled'

export function TravelFusionLinksMobile({
  payNow,
  termsAndConditions,
  estimatedCost,
  dueTodayCost,
  isShowDelegateName,
  isHoldFlow,
  isError,
  isDisabled,
  isLoading,
  onBack,
  onSubmit,
  handleGetTermsFormatType,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TravelFusionLinks.'
  const headerText = isHoldFlow ? t('HoldTrip.HoldTrip') : t(`${i18nBase}Title`)

  return (
    <Screen backgroundColor="background">
      <Screen.Header>
        <Header
          layout="sub-title"
          leftSlot={<Header.BackButton color="mainText2" onClick={onBack} />}
          backgroundColor="background2">
          <Title>{headerText}</Title>
          {isShowDelegateName && (
            <Block isFlexBlock>
              <DelegateName isWithBooking color="mainText2" />
            </Block>
          )}
        </Header>
      </Screen.Header>

      <Swap
        is={isError}
        isSlot={
          <Screen.Container>
            <ErrorState onSubmit={onBack} />
          </Screen.Container>
        }>
        <Screen.Container>
          <LazyContent isLoading={isLoading}>
            <Block paddingHorizontal={20} paddingVertical={40}>
              <TravelFusionLinksContent
                termsAndConditions={termsAndConditions}
                handleGetTermsFormatType={handleGetTermsFormatType}
              />
            </Block>
          </LazyContent>
        </Screen.Container>

        <Screen.Footer withBorder>
          {!!payNow && !!estimatedCost && !!dueTodayCost && (
            <TotalCost
              estimatedCost={estimatedCost}
              dueTodayCost={dueTodayCost}
              onSubmit={onSubmit}
              isDisabled={isDisabled}
            />
          )}
        </Screen.Footer>
      </Swap>
    </Screen>
  )
}
