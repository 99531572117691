import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'

type Options = {
  shouldUseKm?: boolean
  title: string
}

export function getDistance(place: CarRentalMultiVendorLocation, { shouldUseKm, title }: Options) {
  if (shouldUseKm && place.distanceAsKilometers) {
    return place.distanceAsKilometers.toFixed(2) + title
  }
  if (place.distanceAsMiles) {
    return place.distanceAsMiles.toFixed(2) + title
  }
  return null
}
