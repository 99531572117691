import { Inject, Service } from '@etta/di'
import { ItineraryAdapter } from '../../infra'

@Service()
export class ReactivateTripService {
  constructor(@Inject() private readonly itineraryAdapter: ItineraryAdapter) {}

  async reactivateTrip(processId: string) {
    const result = await this.itineraryAdapter.reactivateItinerary({ processId })

    return result.isOk()
  }
}
