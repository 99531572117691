import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style'

export const DateTitle = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  ${captionStrongCaps}
`

export const SegmentContainer = styled.div<{ segmentsGap?: number }>`
  display: flex;
  flex-direction: column;
  ${(p) => p.segmentsGap && `gap: ${p.segmentsGap}px;`};

  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;

  & > * {
    background-color: ${(p) => p.theme.colors.white};
    margin-bottom: 1px;
    min-height: 74px;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.background};
  }
`

export const SectionDate = styled.div<{ isDisabled?: boolean }>`
  text-transform: uppercase;
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText2 : p.theme.colors.bodyText)};
  line-height: 16px;
  text-align: start;
  ${captionStrongCaps};
`
