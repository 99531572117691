import {
  largeTitle,
  title1,
  title2,
  title3,
  headline,
  subHeadMedium,
  subHeadStrong,
  captionMedium,
  captionStrong,
  captionStrongCaps,
  body,
  bodyValue,
  bodyLarge,
  calloutMedium,
  calloutStrong,
  footnoteMedium,
  footnoteStrong,
  buttonNormal,
  buttonLarge,
  buttonSmall,
  linkNormal,
  linkSmall,
  tabs,
  linkCaption,
} from '@fiji/style'

export const textVariantToStyleMap = {
  largeTitle: largeTitle,
  title1: title1,
  title2: title2,
  title3: title3,
  headline: headline,
  subHeadMedium: subHeadMedium,
  subHeadStrong: subHeadStrong,
  captionMedium: captionMedium,
  captionStrong: captionStrong,
  captionStrongCaps: captionStrongCaps,
  body: body,
  bodyValue: bodyValue,
  bodyLarge: bodyLarge,
  calloutMedium: calloutMedium,
  calloutStrong: calloutStrong,
  footnoteMedium: footnoteMedium,
  footnoteStrong: footnoteStrong,
  buttonNormal: buttonNormal,
  buttonLarge: buttonLarge,
  buttonSmall: buttonSmall,
  linkNormal: linkNormal,
  linkSmall: linkSmall,
  tabs: tabs,
  linkCaption,
}
