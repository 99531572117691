import { Fragment } from 'react'
import { Text } from '@etta/ui/text'
import type { Label, PrepareLabel, PrepareBoldLabel } from './types'

export type Props = {
  label: string
  inputValue: string
  'data-tracking-id'?: string
}

export function OptionLabel({ label, inputValue, 'data-tracking-id': dataTrackingId }: Props) {
  if (label.length < inputValue.length || !inputValue.length) {
    return <span>{label}</span>
  }

  const inputValueLength = inputValue.length
  const result: Label[] = []
  let i = 0
  let nonMatchingSlice = ''

  for (; i < label.length; ) {
    const slice = label.slice(i, i + inputValueLength)

    if (slice.toLowerCase() === inputValue.toLowerCase()) {
      prepareLabel({ nonMatchingSlice, result })
      nonMatchingSlice = ''

      prepareBoldLabel({ slice, result, 'data-tracking-id': dataTrackingId })
      i += inputValueLength
    } else {
      nonMatchingSlice += label[i]
      i++
    }
  }

  prepareLabel({ nonMatchingSlice, result })

  return (
    <>
      {result.map(({ key, node }, id) => {
        return <Fragment key={`${key}-${id}`}>{node}</Fragment>
      })}
    </>
  )
}

const prepareLabel = ({ nonMatchingSlice, result }: PrepareLabel) => {
  if (nonMatchingSlice.length) {
    result.push({ key: nonMatchingSlice, node: <span>{nonMatchingSlice}</span> })
  }
}

const prepareBoldLabel = ({
  slice,
  result,
  'data-tracking-id': dataTrackingId,
}: PrepareBoldLabel) => {
  result.push({
    key: slice,
    node: (
      <Text data-tracking-id={dataTrackingId} variant="subHeadStrong">
        {slice}
      </Text>
    ),
  })
}
