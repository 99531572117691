import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { KeyboardNavigationStyle } from '@fiji/style'

export const PriceContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isLoading }) => (isLoading ? '27px' : '24px')} 24px 24px 0;
`

export const CostContainer = styled.span`
  color: ${({ theme }) => theme.colors.bodyText};
  display: flex;
  align-items: center;

  ${KeyboardNavigationStyle}
`
export const CostText = styled(Text)`
  color: ${({ theme }) => theme.colors.mainText};
`
export const CostPerDay = styled(Text)`
  line-height: 1.3333;
`

export const EstimateBlock = styled.div`
  margin-top: 4px;
`
export const SelectButton = styled(Button)`
  margin-top: auto;
`
