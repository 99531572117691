import type { LoyaltyProgram, Notification } from '@fiji/graphql/types'
import { doesLoyaltyProgramExist } from '../does-loyalty-program-exist'

type Input = Notification[] | LoyaltyProgram[] | string[]

const TOPIC_FOR_NOTIFICATION = '/travel/flight/status' // TODO: edit/remove this once emails are accepted

function getContentList(arr: Input): string[] | null {
  if (typeof arr[0] === 'string') {
    return arr as string[]
  }

  if ((arr[0] as Notification).id) {
    return (arr as Notification[])
      .filter((el) => el.phone && el.topic === TOPIC_FOR_NOTIFICATION)
      .map((el) => el.phone?.number || '') as string[]
  }

  if (!doesLoyaltyProgramExist(arr as LoyaltyProgram[])) {
    return null
  }

  return (arr as LoyaltyProgram[])
    .filter((el) => el.vendorCode)
    .map((el) => el.label + (el.number ? ' - ' + el.number : '')) as string[]
}

export function preferredContentToString(arr?: Input | false, isPrefferedInOneLine?: boolean) {
  if (!arr || !arr.length) {
    return null
  }
  const joinString = isPrefferedInOneLine ? ', ' : ', \n'

  const contentList = getContentList(arr)
  if (!contentList) {
    return null
  }

  return contentList.map((item) => item.toString().trim()).join(joinString)
}
