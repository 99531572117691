import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'
import { Icon } from '@etta/ui/icon'
import { Badge } from '@etta/components/air-badges/components/badge'
import { Container, Label, OnTimeContainer } from './details-styled'

type Props = {
  flight: AdaptiveFlight
}

export const Details: FC<Props> = ({ flight }) => {
  const { t } = useTranslation()

  const {
    totalFlightDuration,
    percentageOnTimeLabels,
    flightStopsLabel,
    customPreferredText,
  } = flight

  return (
    <Container>
      <Label>{totalFlightDuration}</Label>

      <Label>•</Label>

      <Label>{flightStopsLabel}</Label>

      {!percentageOnTimeLabels.includes('n/a') && (
        <>
          <Label>•</Label>

          <OnTimeContainer>
            <Icon name="timePWA" size="small" color="bodyText" />

            <Label>
              {t('FlightDetails.OnTime')}: {percentageOnTimeLabels.join(', ')}
            </Label>
          </OnTimeContainer>
        </>
      )}

      {customPreferredText && (
        <Badge
          tabIndex={0}
          skin="small-inner-space"
          title={customPreferredText}
          icon="customPreferredPWA"
          color="success"
          data-tracking-id="custom-preferred-badge"
        />
      )}
    </Container>
  )
}
