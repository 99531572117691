import styled from 'styled-components'
import { slideUp } from '@etta/components/mobility-complex-pickup-dialog/transition/dialog-transition'

export const InfoImage = styled.img`
  display: block;
  height: auto;
  margin: auto;
`

export const Container = styled.div<{
  isShown: boolean
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
`

export const DialogContainerRoot = styled.div`
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  ${slideUp};
`

export const DialogContainer = styled.div`
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px 14px 0 0;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  position: absolute;
  bottom: 0;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 41px;
`

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
`
