import { MediaLayout } from '@etta/ui/media-layout'
import { TabsSectionMobile } from './layout/mobile/tabs-section-mobile'
import { TabsSectionDesktop } from './layout/desktop/tabs-section-desktop'
import type { Props } from './types'

export const TabsSection = (props: Props) => {
  return (
    <MediaLayout
      mobileSlot={<TabsSectionMobile {...props} />}
      desktopSlot={<TabsSectionDesktop {...props} />}
    />
  )
}
