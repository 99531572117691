import { useCallback, useEffect, useState } from 'react'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import type { RideHailSuggestionLocation } from '@fiji/graphql/types'

type Props = {
  defaultLocation?: RideHailSuggestionLocation
}

export function useMobilitySearchCurrentLocation({ defaultLocation }: Props) {
  const [currentCoordinate, setCurrentCoordinate] = useState<Coords>({
    lat: 0,
    lng: 0,
  })

  const getGeoSuccess = useCallback((position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords
    setCurrentCoordinate({ lat: latitude, lng: longitude })
  }, [])

  const getGeoError = useCallback(
    (_: GeolocationPositionError) => {
      const geocoder = new google.maps.Geocoder()
      if (defaultLocation?.geocode) {
        setCurrentCoordinate({
          lat: defaultLocation.geocode.lat,
          lng: defaultLocation.geocode.long,
        })
        return
      }

      if (defaultLocation?.address) {
        geocoder.geocode({ address: defaultLocation.address }, function (results, status) {
          if (status === 'OK') {
            const result = results[0]
            setCurrentCoordinate({
              lat: result?.geometry.location.lat() ?? 0,
              lng: result?.geometry.location.lng() ?? 0,
            })
          }
        })
      }
    },
    [defaultLocation],
  )

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getGeoSuccess, getGeoError)
    }
  }, [getGeoSuccess, getGeoError])

  return { currentCoordinate }
}
