import styled from 'styled-components'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'

export const Button = styled.button`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.primary};
  border: 6px solid ${(p) => hexToRgba(p.theme.colors.primary, 0.1)};
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
  }
`
