import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Item, ItemContent, ItemTitle } from './full-screen-styled'

type Props = {
  name: string | string[]
  cost: string
  dropOffName?: string | null
  pickUpDateTime?: string | null
}

const i18nBase = 'PostBooking.RideHailDetails.CancelRideHailToDestination'

export function FullScreenRideHailSegment({ name, cost, dropOffName, pickUpDateTime }: Props) {
  const { t } = useTranslation()

  return (
    <Item>
      <Icon name={'rideHailPWA'} size="medium" color="mainText" />
      <ItemContent>
        <ItemTitle>
          <Text variant="subHeadStrong" color="mainText" textTransform="capitalize">
            {name}
          </Text>
        </ItemTitle>

        <Text variant="footnoteStrong" color="bodyText1" textOverflow="ellipsis-two-line">
          {t(i18nBase, {
            destination: dropOffName,
          })}
        </Text>

        <Text
          variant="footnoteStrong"
          color="bodyText"
          data-tracking-id="cancel-ride-hail-date-price-text">
          {pickUpDateTime} • {cost}
        </Text>
      </ItemContent>
    </Item>
  )
}
