import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'

export function CheckoutPageModalFooterMobile({
  submitLabel,
  onSubmit,
  submitBtnType,
  isDisabled,
}: LayoutProps) {
  return (
    <Modal.Footer withBorder padding="normal">
      <Button disabled={isDisabled} fullWidth onClick={onSubmit} btnType={submitBtnType}>
        {submitLabel}
      </Button>
    </Modal.Footer>
  )
}
