import { Action, Inject } from '@etta/di'
import { CarRentalCarDetailsStore } from '../stores/car-rental-car-details.store'
import { CarRentalDetailsService } from '../services/car-rental-details.service'
import type { SelectedCarDetails } from '../types'

@Action()
export class CarRentalCarDetailsActions {
  constructor(
    @Inject()
    private readonly carRentalCarDetails: CarRentalCarDetailsStore,

    @Inject()
    private readonly carRentalDetailsService: CarRentalDetailsService,
  ) {}

  async handleLoadCarDetails(carId: string) {
    await this.carRentalDetailsService.loadCarDetails({ carId })
  }

  async handleRefetchCarDetails(carId: string) {
    await this.carRentalDetailsService.loadCarDetails({ carId, forceUpdate: true })
  }

  handleSelectCarDetails(carDetails: SelectedCarDetails) {
    this.carRentalCarDetails.setSelectedCarDetails(carDetails)
    this.carRentalCarDetails.carDetailsToggle.handleOpen()
  }

  handleCloseCarDetailsToggle() {
    this.carRentalCarDetails.carDetailsToggle.handleClose()
    this.carRentalCarDetails.setSelectedCarDetails(null)
  }
}
