import { useTranslation } from 'react-i18next'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { IconNames } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money'
import { sumRates } from '@fiji/utils/sum-rate'
import { StyledFlightArrowIcon } from '../cost-summary-styled'

import { Container, FlightName, Unit } from './flight-section-styled'

type Props = {
  flightSegments: FlightPostBookingValueObject[]
  currentSegment: FlightPostBookingValueObject
}

const i18nBase = 'PostBooking.TripDetails.CostSummary'

export function FlightSection({ flightSegments, currentSegment }: Props) {
  const { t } = useTranslation()

  const origin = currentSegment?.segments?.[0]?.departure?.airportCode
  const destination =
    currentSegment?.segments?.[currentSegment?.segments?.length - 1]?.arrival?.airportCode
  const ariaLabel = `${origin} ${destination}`
  const iconName: IconNames =
    currentSegment.isRoundTripLeg && currentSegment.isSameCarrierName
      ? 'sameDayArrowsPWA'
      : 'flightArrowPWA'

  const { mainCost: formattedPrice } = formatRate(currentSegment.rate, { morpheme: 'postfix' })

  const { mainCost: formattedTaxes } = formatRate(
    sumRates(flightSegments.map((segment) => segment.taxes)),
    { morpheme: 'postfix' },
  )
  const { mainCost: unusedTicketPrice } = formatRate(
    sumRates(flightSegments.map((segment) => segment.unusedTicketRate)),
    {
      morpheme: 'postfix',
    },
  )

  const isAfterLastLeg = currentSegment?.legId === flightSegments[flightSegments.length - 1].legId

  return (
    <>
      <Container aria-label={ariaLabel}>
        <FlightName>
          {origin} <StyledFlightArrowIcon name={iconName} /> {destination}
        </FlightName>
        <Text variant="footnoteMedium">{formattedPrice}</Text>
      </Container>
      {isAfterLastLeg && (
        <Unit>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + '.Tax')}
          </Text>
          <Text variant="footnoteMedium">{formattedTaxes}</Text>
        </Unit>
      )}
      {isAfterLastLeg && unusedTicketPrice && (
        <Unit>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + '.UnusedTicketPrice')}
          </Text>
          <Text variant="footnoteMedium">{unusedTicketPrice}</Text>
        </Unit>
      )}
    </>
  )
}
