import { Checkbox } from '@etta/ui/checkbox'
import type { RailCardItemProps } from '../../../types'
import { StyledItemBlock } from '../rail-card-group-styled'

export function RailCardCheckedItem({ card, handleCardClick, checkSavedCard }: RailCardItemProps) {
  if (!card.name) {
    return null
  }

  return (
    <StyledItemBlock aria-label={card.name} paddingHorizontal={20} paddingVertical={9}>
      <Checkbox
        value={card.name}
        label={card.name}
        checked={checkSavedCard ? checkSavedCard(card.code || '') : false}
        onChange={(isChecked) =>
          handleCardClick({
            ...card,
            isChecked: isChecked,
          })
        }
      />
    </StyledItemBlock>
  )
}
