/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { TextField } from '@etta/ui/text-field'
import { Text } from '@etta/ui/text'
import { ExpirationDateField } from '@etta/ui/expiration-date-field'
import type { AddRailCardModalProps } from '../types'
import { useAddRailCardModal } from './use-add-rail-card-modal'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

export function AddRailCardModal({
  isOpen,
  onClose,
  currentClickedEuCard,
  handleAddSavedCard,
}: AddRailCardModalProps) {
  const { t } = useTranslation()
  const { values, errors, onFieldChange, handleSubmit } = useAddRailCardModal({
    onClose,
    handleAddSavedCard,
  })

  return (
    <Modal
      data-tracking-id={'add-rail-cards-modal'}
      isVisible={isOpen}
      handleModalVisibility={onClose}>
      <Modal.Title withBorder>{currentClickedEuCard}</Modal.Title>
      <Modal.Body>
        <Block paddingHorizontal={20} paddingTop={34}>
          <Text variant="headline">{t(i18Base + '.CardInformation')}</Text>
        </Block>
        <Block paddingHorizontal={20} paddingTop={16}>
          <TextField
            type="numeric"
            value={values.cardNumber}
            onChange={onFieldChange('cardNumber')}
            label={t(i18Base + '.CardNumber')}
            helperType="error"
            helperText={[errors.cardNumber].filter(Boolean).join(', ')}
          />
        </Block>
        <Block paddingHorizontal={20} paddingTop={16}>
          <ExpirationDateField
            label={t(i18Base + '.Expiry')}
            value={values.expiry}
            error={errors.expiry}
            onChange={onFieldChange('expiry')}
          />
        </Block>
      </Modal.Body>
      <Modal.Footer withBorder>
        <Block paddingHorizontal={16} paddingVertical={16}>
          <Button fullWidth onClick={handleSubmit}>
            {t(i18Base + '.SaveCard')}
          </Button>
        </Block>
      </Modal.Footer>
    </Modal>
  )
}
