import { css } from 'styled-components'

export const TRANSITION_NAME = 'DIALOG_SLIDE_UP'
export const TRANSITION_TIMEOUT = 450

const ANIMATION = 'cubic-bezier(0.4, 0, 0.2, 1)'

export const slideUp = css`
  transition: transform ${TRANSITION_TIMEOUT}ms ${ANIMATION};

  &.${TRANSITION_NAME}-enter, &.${TRANSITION_NAME}-appear {
    transform: translateY(100vh);
  }

  &.${TRANSITION_NAME}-appear-done,
    &.${TRANSITION_NAME}-enter-done,
    &.${TRANSITION_NAME}-appear-active,
    &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit-done, &.${TRANSITION_NAME}-exit {
    transform: translateY(0%);
  }

  &.${TRANSITION_NAME}-exit-active {
    transform: translateY(100vh);
  }
`
