/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useLoginMutation } from '@fiji/graphql/hooks'
import { useCookieContext } from '@etta/modules/cookie'
import { useCurrentRoute } from '@etta/modules/app/ui/use-current-route'
import { useFijiSessions } from './fiji-auth/use-fiji-sessions'
import { useEttaSessions } from './etta-auth/use-etta-sessions'

type Args = {
  isForceLoad?: boolean
  isCleanTokenBeforeLogin?: boolean
}

export function useUserLogin(params?: Args) {
  const { isForceLoad } = params || {}
  const [isLoading, setIsLoading] = useState(true)
  const { cookieStore } = useCookieContext()
  const [login] = useLoginMutation()
  const isCookieExist = cookieStore.isLoggedIn()
  const { currentRouteItem } = useCurrentRoute()
  const { isOpen: isSessionModalOpen, handleStayConnect, handleClose } = useEttaSessions({
    isLoading,
  })

  const doLogin = useCallback(async () => {
    try {
      await login()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('user-login-silent-error', e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isCookieExist && !isForceLoad) {
      setIsLoading(false)
      return
    }

    doLogin()
  }, [])

  useFijiSessions()

  return {
    isLoading,
    isAuthorized: isCookieExist && !isLoading,
    isSessionModalOpen,
    handleStayConnect,
    handleClose,
    isCurrentRouteExist: !!currentRouteItem,
  }
}
