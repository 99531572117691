import styled from 'styled-components'
import { title3 } from '@fiji/style'

export const HotelTitle = styled.div`
  ${title3};
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.mainText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
`
