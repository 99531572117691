import { Service, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import type { HotelEntity } from '../../core/entities/hotel.entity'
import { isRestrictedCountryError } from '../../core/errors/hotel-search.errors'
import { HotelSearchAdapter } from '../../infra/hotel-search.adapter'
import { HotelSearchResultsStore, PAGE_SIZE } from '../stores/hotel-search-results.store'
import { GetHotelsInputs } from './get-hotels-inputs.service'
import { HotelSearchFormQueryService } from './hotel-search-form-query.service'

@Service()
export class LoadHotelsService {
  constructor(
    @Inject()
    private readonly hotelSearchAdapter: HotelSearchAdapter,
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly getHotelsInputs: GetHotelsInputs,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
  ) {}

  async loadSearchId() {
    const input = this.getHotelsInputs.createSearchInput()
    this.hotelSearchResultsStore.setIsCreateSearchLoading(true)

    if (!input) {
      this.hotelSearchResultsStore.setIsError(true)
      this.hotelSearchResultsStore.setIsCreateSearchLoading(false)
      return
    }

    const result = await this.hotelSearchAdapter.createHotelSearch(input)
    result.match({
      Ok: (value) => {
        this.hotelSearchResultsStore.setSearchId(value.searchId)
        this.hotelSearchFormQueryService.appendQueryParams({ hotelResultId: value.searchId })
        this.hotelSearchResultsStore.setIsCreateSearchLoading(false)
      },
      Err: () => {
        this.hotelSearchResultsStore.setIsError(true)
        this.hotelSearchResultsStore.setIsCreateSearchLoading(false)
      },
    })
  }

  async loadHotels() {
    const input = this.getHotelsInputs.hotelsInput()

    if (!input) {
      this.hotelSearchResultsStore.setIsError(true)
      return
    }

    const result = await this.hotelSearchAdapter.getHotels(input)

    result.match({
      Ok: (value) => {
        const { hotels, ...results } = value
        this.hotelSearchResultsStore.setHotelResults(results)
        this.hotelSearchResultsStore.setHotels(hotels)
        this.loadEachHotelById(hotels)
      },
      Err: (error) => {
        if (isRestrictedCountryError(error)) {
          this.hotelSearchResultsStore.setIsRestrictedCountryError(true)
          this.hotelSearchResultsStore.setRestrictedCountryMessage(error.error.message)
        }
        this.hotelSearchResultsStore.setIsError(true)
      },
    })
  }

  async loadMoreHotelsDesktop(offset?: number) {
    const input = this.getHotelsInputs.hotelsInput()

    const newOffset = offset ?? this.hotelSearchResultsStore.offset + PAGE_SIZE

    if (!input) {
      this.hotelSearchResultsStore.setIsFetchMoreError(true)
      return
    }

    const result = await this.hotelSearchAdapter.getHotels({ ...input, offset: newOffset })

    result.match({
      Ok: (value) => {
        this.hotelSearchResultsStore.setOffset(newOffset)
        this.hotelSearchResultsStore.setHotels(value.hotels)
        this.loadEachHotelById(value.hotels)
      },
      Err: () => {
        this.hotelSearchResultsStore.setIsFetchMoreError(true)
      },
    })
  }

  async loadMoreHotels(offset?: number) {
    const input = this.getHotelsInputs.hotelsInput()

    const newOffset = offset ?? this.hotelSearchResultsStore.offset + PAGE_SIZE

    if (!input) {
      this.hotelSearchResultsStore.setIsFetchMoreError(true)
      return
    }

    const result = await this.hotelSearchAdapter.getHotels({ ...input, offset: newOffset })

    result.match({
      Ok: (value) => {
        this.hotelSearchResultsStore.setOffset(newOffset)
        this.hotelSearchResultsStore.setMoreHotels(value.hotels)
        this.loadEachHotelById(value.hotels)
      },
      Err: () => {
        this.hotelSearchResultsStore.setIsFetchMoreError(true)
      },
    })
  }

  async loadHotelById(id: string) {
    this.hotelSearchResultsStore.setHotelById({ id, isLoading: true, isError: false })

    const input = this.getHotelsInputs.hotelByIdInput(id)

    if (!input) {
      return
    }

    const result = await this.hotelSearchAdapter.getHotelById(input)

    result.match({
      Ok: (value) => {
        this.hotelSearchResultsStore.setHotelById({ ...value, isLoading: false, isError: false })
      },
      Err: () => {
        this.hotelSearchResultsStore.setHotelById({ id, isLoading: false, isError: true })
      },
    })
  }

  private async loadEachHotelById(hotels: HotelEntity[]) {
    if (this.displayConfigurationStore.isHotelSearchServiceEnabled) {
      return
    }
    if (!hotels.length) {
      return
    }

    const promises = hotels.map(async (hotel) => {
      await this.loadHotelById(hotel.id)
    })
    await Promise.allSettled(promises)
  }
}
