const CITY_IMAGE_URL_KEY = '@city-image-url'

export class CityImageUrlStore {
  static set(cityImageUrl?: string) {
    if (!window) {
      return
    }
    window.localStorage.setItem(CITY_IMAGE_URL_KEY, cityImageUrl || '')
  }
  static get() {
    return window.localStorage.getItem(CITY_IMAGE_URL_KEY)
  }
}
