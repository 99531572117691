import { useFareSeatMaps } from '@etta/screens/review-trip-page/flight-details-modal/fare-seat-maps/use-fare-seat-maps'
import { useReviewTripPageContextSelector } from '@etta/screens/review-trip-page/review-trip-page-provider'
import type { AdaptiveFlight } from '@etta/components/flight-details/types'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { FlightLegSegment } from '@fiji/graphql/types'
import { getHeaderTitle } from './get-header-title'
import { getFlightLabels } from './get-flight-labels'

type Args = {
  adaptiveFlight: AdaptiveFlight
  flight: FlightLegSegment
  itineraryId?: string
  onClose: () => void
  flightLegPosition?: number
}

export function useFlightDetailsModalDesktop({
  adaptiveFlight,
  flight,
  itineraryId = '',
  onClose,
  flightLegPosition = 1,
}: Args) {
  const headerTitle = getHeaderTitle(adaptiveFlight)
  const flightLabels = getFlightLabels(adaptiveFlight)
  const { isDesktopUnusedTicketsEnabled } = useFeatureFlags().featureFlags

  const { seatMapPaginationActions } = useSeatMapContext()

  const { seatStateStatus, resetSeatStateStatus } = useFareSeatMaps({
    itineraryId,
    segments: flight.segments,
    adaptiveFlight,
    onClose,
  })

  const { rtpSeatModalLvl2Toggle } = useReviewTripPageContextSelector((state) => state)

  const handleSeatMapOpenClick = () => {
    rtpSeatModalLvl2Toggle.handleOpen()
    seatMapPaginationActions.handleSelectFlightCard({
      legIndex: flightLegPosition - 1,
      segmentIndex: 0,
    })
  }

  return {
    headerTitle,
    flightLabels,
    seatStateStatus,
    resetSeatStateStatus,
    rtpSeatModalLvl2Toggle,
    handleSeatMapOpenClick,
    isDesktopUnusedTicketsEnabled,
  }
}
