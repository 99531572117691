import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { screenSize } from '@fiji/style/screen-sizes'
import { DialogWrapper, Title, Divider, DialogText } from './disabled-geo-dialog-styled'

export type DisabledGeoDialogProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
}

const i18nPath = 'SearchResults.CurrentLocation.LocationDisabledDialog.'

export function DisabledGeoDialog({ isOpen, setOpen }: DisabledGeoDialogProps) {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <DialogWrapper isOpen={isOpen} onClose={handleClose} customSize={[320, screenSize.maxMobile]}>
      <Icon name="locationDisabledPWA" size="large" />
      <Title>{t(`${i18nPath}Title`)}</Title>
      <Divider />
      <DialogText>{t(`${i18nPath}Description`)}</DialogText>
      <Button onClick={handleClose}>{t(`${i18nPath}ButtonTitle`)}</Button>
    </DialogWrapper>
  )
}
