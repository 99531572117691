import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ShowMore } from '@etta/ui/show-more'
import { Text } from '@etta/ui/text'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { TextField } from '@etta/ui/text-field'
import { Container } from './amenities-filter-styled'
import { AmenitiesMultipleItems } from './amenities-multiple-items'

const MAX_EXPANDED_ITEMS_DESKTOP = 5
const MAX_EXPANDED_ITEMS_MOBILE = 3

type Props = {
  isModal?: boolean
}

const i18nBase = 'HotelFilters.Hotel'

export const AmenitiesFilter = observer(function AmenitiesFilter({ isModal = false }: Props) {
  const { hotelSearchResultsStore, hotelFiltersAction } = useHotelSearchResultsContext()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const MAX_EXPANDED_ITEMS = isMobile ? MAX_EXPANDED_ITEMS_MOBILE : MAX_EXPANDED_ITEMS_DESKTOP

  const { t } = useTranslation()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled, isNewHotelFiltersModalEnabled } = featureFlagsStore.flags

  const withBorder = !isModal
  const withSpace = !isModal && isNewHotelExposedFiltersEnabled && !isMobile
  const withGridLayout = isModal && isNewHotelFiltersModalEnabled

  if (isNewHotelExposedFiltersEnabled && !isMobile) {
    if (isModal && isNewHotelFiltersModalEnabled) {
      return (
        <>
          <TextField
            placeholder={t(i18nBase + '.SearchAmenities')}
            type="search"
            size="small"
            onChange={hotelFiltersAction.handleAmenitiesSearchQueryChange}
            value={hotelSearchResultsStore.amenitySearchQuery}
          />
          <Container>
            <AmenitiesMultipleItems
              withBorder={withBorder}
              withSpace={withSpace}
              isGridLayout={withGridLayout}
              options={hotelSearchResultsStore.amenitySearchResults}
            />
          </Container>
        </>
      )
    }

    return (
      <AmenitiesMultipleItems
        withBorder={withBorder}
        withSpace={withSpace}
        isGridLayout={withGridLayout}
        options={hotelSearchResultsStore.filterOptions.amenities.sortedAmenities}
      />
    )
  }

  return (
    <>
      <ShowMore
        notExpandedContent={
          <AmenitiesMultipleItems
            options={hotelSearchResultsStore.filterOptions.amenities.sortedAmenities.slice(
              0,
              MAX_EXPANDED_ITEMS,
            )}
          />
        }
        expandedContent={
          <AmenitiesMultipleItems
            options={hotelSearchResultsStore.filterOptions.amenities.sortedAmenities}
          />
        }
        renderButton={(isExpanded, handleButtonClick) => (
          <Block marginTop={8}>
            <Button variant="text" onClick={handleButtonClick}>
              <Text variant="buttonNormal" color="primary1" textTransform="capitalize">
                {t(`Callout.${isExpanded ? 'ShowLess' : 'ShowMore'}`)} {t(i18nBase + '.Amenities')}
              </Text>
            </Button>
          </Block>
        )}
      />
    </>
  )
})
