import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { TripCardSkeletonDesktop } from './desktop'
import { TripCardSkeletonMobile } from './mobile'

export function TripCardSkeletonLayout() {
  const { featureFlags } = useFeatureFlags()
  return (
    <MediaLayout
      mobileSlot={<TripCardSkeletonMobile />}
      desktopSlot={<TripCardSkeletonDesktop />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
