import { useMemo } from 'react'
import { Block } from '@etta/ui/block'
import {
  Filter,
  StyledText,
  StyledIconButton,
  StyledIcon,
  FilterWrapper,
} from './search-filter-components'

type Props = {
  onClick: () => void
  onClear?: () => void
  placeholder: string
  value?: string
}

export const SearchFilter = ({ onClick, onClear, placeholder, value }: Props) => {
  const textColor = useMemo(() => (value ? 'mainText' : 'bodyText'), [value])
  const text = useMemo(() => value || placeholder, [placeholder, value])

  return (
    <FilterWrapper>
      <Filter onClick={onClick}>
        <Block marginRight={10}>
          <StyledIcon name="searchPWA" />
        </Block>
        <StyledText
          data-tracking-id="hotel-name-filter-text"
          variant="footnoteMedium"
          noWrap
          textOverflow="ellipsis"
          color={textColor}>
          {text}
        </StyledText>
      </Filter>
      {value && onClear && <StyledIconButton icon="closeFilledPWA" onClick={onClear} />}
    </FilterWrapper>
  )
}
