import { useTranslation } from 'react-i18next'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { ScreenType, useScreenType } from '@fiji/modes'
import { LONDON_UNDERGROUND_OPERATOR } from '@fiji/constants'
import { TrainDeliveryOptionCode } from '@fiji/enums'
import type { ModalPosition } from '@fiji/types'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'

type Args = {
  train: TrainPostBookingValueObject | null
}

export function useTripTrainOpenReturnModal({ train }: Args) {
  const { scrollContainerRef, headerRef } = useHeaderScroll()
  const { t } = useTranslation()
  const screenType = useScreenType()
  if (!train) {
    return {
      isMobile: false,
      scrollContainerRef: () => {},
      headerRef: undefined,
      modalPosition: 'center' as ModalPosition,
      headerTitle: '',
      isCollect: false,
      travelerName: '',
      transactionId: '',
      collectionRefNumber: '',
      segments: [],
      ticketRestrictions: undefined,
    }
  }
  const isMobile = screenType === ScreenType.Mobile
  const modalPosition: ModalPosition = isMobile ? 'center' : 'right'

  const segments = train.segments?.filter(
    (segment) => segment.carrierName !== LONDON_UNDERGROUND_OPERATOR,
  )

  const lastSegment = segments?.length ? segments?.[segments?.length - 1] : undefined

  const arrivalCountryName = lastSegment?.stations?.arrival?.countryName
  const arrivalPlace = lastSegment?.stations?.arrival?.stationName + ', ' + arrivalCountryName

  const headerTitle = t('PostBooking.TrainDetails.TrainToHeader', { arrivalPoint: arrivalPlace })

  // Info Card
  const isCollect = train.selectedDeliveryOption?.code === TrainDeliveryOptionCode.Collect
  const travelerName = [train.traveler?.firstName, train.traveler?.lastName].join(' ')
  const transactionId = train?.transactionId ?? '-'
  const collectionRefNumber = train?.collectionReferences?.length
    ? train?.collectionReferences?.join(', ')
    : null

  const fareConditions = train.fareConditions?.[0]
  const ticketRestrictions: TicketRestrictionsInfo = {
    tripFare: fareConditions?.localizedFareConditions[0].fareTypeDescription || '',
    ticketRestrictions: fareConditions?.localizedFareConditions[0].conditions || [],
  }

  return {
    isMobile,
    scrollContainerRef,
    headerRef,
    modalPosition,
    headerTitle,
    isCollect,
    travelerName,
    transactionId,
    collectionRefNumber,
    segments,
    ticketRestrictions,
  }
}
