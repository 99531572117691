import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { Container, Label, Input, IconContainer, IconItem, Text } from './switch-components'

type SwitchProps = {
  label: string
  labelPlacement?: 'left'
  checked: boolean
  onChange: (e: any) => void
  'data-tracking-id'?: string
  isPWA?: boolean
}

export function Switch({
  label,
  labelPlacement,
  checked,
  onChange,
  'data-tracking-id': dataTrackingId,
  isPWA,
}: SwitchProps) {
  const { handleKeyDown } = useA11yKeydown(onChange)
  return (
    <Container>
      <Label tabIndex={-1} labelPlacement={labelPlacement}>
        <Input
          tabIndex={-1}
          data-tracking-id={dataTrackingId}
          checked={checked}
          type="checkbox"
          onChange={onChange}
        />
        <IconContainer onKeyDown={handleKeyDown} tabIndex={0} isPWA={isPWA}>
          <IconItem isPWA={isPWA} />
        </IconContainer>
        <Text>{label}</Text>
      </Label>
    </Container>
  )
}
