import { useTranslation } from 'react-i18next'
import { Tooltip } from '@etta/ui/tooltip'
import { TooltipMultilineContent } from '@etta/ui/tooltip/tooltip-multiline-content/tooltip-multiline-content'
import type { BadgeSkinType } from '../types'
import { TooltipOuterContainer } from '../air-badges-components'
import { Badge } from './badge'

const i18Base = 'SearchSegments.Badges'

type Props = {
  skin?: BadgeSkinType
  outOfPolicyTexts?: string[]
}

export function OutOfPolicyBadge({ skin, outOfPolicyTexts }: Props) {
  const { t } = useTranslation()

  if (outOfPolicyTexts) {
    const tooltipTitle = t('TooltipText.OutOfPolicyReasons')

    return (
      <TooltipOuterContainer skin={skin}>
        <Tooltip
          slot={<TooltipMultilineContent title={tooltipTitle} textItems={outOfPolicyTexts} />}
          place="top"
          isIndependentOfParentWidth>
          <Badge
            skin={skin}
            key="out-of-policy"
            title={t(i18Base + '.OutOfPolicy')}
            icon="outOfPolicyPWA"
            data-tracking-id="out-of-policy-badge"
          />
        </Tooltip>
      </TooltipOuterContainer>
    )
  }

  return (
    <Badge
      skin={skin}
      key="out-of-policy"
      title={t(i18Base + '.OutOfPolicy')}
      icon="outOfPolicyPWA"
      data-tracking-id="out-of-policy-badge"
    />
  )
}
