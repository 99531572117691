import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { RideHailGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { useShareSegment } from '@etta/screens/trip-details/use-trip-details/use-share-segment'

const i18Base = 'PostBooking.TripDetails'
const TIME_FORMAT = 'h:mma'

type Props = {
  segment: RideHailGroupedSegmentValueObject
  isUpcoming: boolean
  onCancel?: () => void
}

export function useRideHailSegment({ segment, isUpcoming, onCancel }: Props) {
  const { t } = useTranslation()
  const { handleShare } = useShareSegment({ segment })

  const titleRideHail = useMemo(() => {
    if (!segment.description) {
      return ''
    }
    return segment.description
  }, [segment])

  const ariaLabel = useMemo(() => t(`${i18Base}.SegmentAriaLabel`, { label: `${titleRideHail}` }), [
    t,
    titleRideHail,
  ])

  const pickUpLocationLabel = useMemo(
    () => t(`${i18Base}.RideHailPickUpLocation`, { location: segment.pickUpAddress }),
    [segment.pickUpAddress, t],
  )

  const pickUpTimeLabel = useMemo(() => {
    if (!segment.pickUpTime) {
      return ''
    }
    return t(`${i18Base}.RideHailPickUpBy`, {
      time: dateFormat(new Date(deleteTimezone(segment.pickUpTime)), TIME_FORMAT),
    })
  }, [segment.pickUpTime, t])

  const buttons = useMemo(
    () => [
      {
        visible: isUpcoming,
        title: t(`${i18Base}.CancelRideHail`),
        onClick: onCancel ? onCancel : undefined,
      },
    ],
    [isUpcoming, onCancel, t],
  )

  return {
    titleRideHail,
    ariaLabel,
    pickUpLocationLabel,
    pickUpTimeLabel,
    buttons,
    handleShare,
  }
}
