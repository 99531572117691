import { Inject, Service } from '@etta/di'
// TODO: remove fiji import
import { ROUTES } from '@fiji/routes'
import { PreSearchStore } from '@etta/modules/pre-search'
import { AirSearchFormStore } from '../../stores/air-search-form.store'
import { AirSearchQueryStore } from '../../stores/air-search-query.store'
import { AirSearchQueryService } from '../air-search-query/air-search-query.service'

@Service()
export class SubmitFormService {
  constructor(
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly airSearchQueryStore: AirSearchQueryStore,
    @Inject()
    private readonly airSearchFormQueryService: AirSearchQueryService,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
  ) {}

  handleSubmitForm() {
    const {
      bookingId,
      filters,
      isLocationDisabled,
      isAirlineCarrierDisabled,
      isReturnFlightChangeOnly,
    } = this.airSearchQueryStore.additionalQueries
    const legNumber = isReturnFlightChangeOnly ? 1 : 0
    this.airSearchFormQueryService.navigateTo(ROUTES.air.results({ legNumber: legNumber }), {
      flightType: this.airSearchFormStore.searchFlightType,
      flights: this.airSearchFormStore.flightLegSearchInput.flights,
      bookingId,
      searchId: undefined,
      itineraryId: this.airSearchFormStore.existingTripId,
      filters: isLocationDisabled || isAirlineCarrierDisabled ? filters : undefined,
      customFields: this.preSearchStore.customFields,
    })

    this.airSearchFormStore.updateSearchFlightsEditCache({
      flights: this.airSearchFormStore.flightLegSearchInput.flights,
      flightType: this.airSearchFormStore.isEditMode
        ? this.airSearchFormStore.searchFlightsEditCache.flightType
        : this.airSearchQueryStore.searchQueryFlightType ||
          this.airSearchFormStore.searchFlightType,
    })
  }
}
