import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;

  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
`

export const TitleContainer = styled(Text)`
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.mainText1};
`

export const DescriptionContainer = styled(Text)`
  display: flex;
  color: ${(props) => props.theme.colors.bodyText};
`

export const TextContainer = styled.div`
  margin-left: 12px;
`

export const ButtonWrapper = styled(Button)`
  margin-top: 16px;
  margin-bottom: 14px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.bodyText1};
    color: ${({ theme }) => theme.colors.bodyText1};
  }
`

export const ButtonContainer = styled.div``
