import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { FlightDirection, TimeRangeDirection } from '@fiji/enums'
import { SegmentType } from '@fiji/graphql/types'
import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useAppDispatch } from '@fiji/store'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { ROUTES } from '@fiji/routes'
import { getQueryParamsRTP } from './get-query-params'

type Args = {
  itineraryId: string
  bookingId?: string
  segments: TripSegmentValueObject[]
}

export function useFlightPlaceRTP({ itineraryId, bookingId, segments }: Args) {
  const { navigateTo } = useAirSearchQuery()
  const dispatch = useAppDispatch()
  const { cabinClassesOptions } = useSeatmapCabinClasss()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()
  const leg0Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Origin)
  const leg1Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Return)
  const { addSegmentModalsActions } = useReviewTripContext()

  const isDesktop = useScreenType() !== ScreenType.Mobile
  const {
    featureFlags: { isDesktopLayoutRTPFlowEnabled },
  } = useFeatureFlags()

  const onAddFlight = () => {
    if (isDesktop && isDesktopLayoutRTPFlowEnabled) {
      addSegmentModalsActions.addFlight()
      return
    }

    const url = ROUTES.air.search
    const params = getQueryParamsRTP({
      segments,
      forType: SegmentType.Flight,
    })

    dispatch(updateTripReviewStatus('update'))
    navigateTo(url, {
      itineraryId,
      bookingId,
      flights: [
        {
          id: -1,
          destinationPlace: params?.to,
          departureDate: params?.startDate,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg0Time,
          returnDate: params?.endDate,
          returnTimeRange: leg1Time,
        },
        {
          id: -2,
          originPlace: params?.to,
          departureDate: params?.endDate,
          cabinClass: cabinClassesOptions[0],
          timeRange: leg1Time,
          returnTimeRange: leg1Time,
        },
      ],
      selectedTab: isDesktop ? undefined : SegmentType.Flight,
    })
  }

  return {
    onAddFlight,
  }
}
