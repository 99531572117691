import type { HttpOptions } from '@apollo/client'
import { HttpLink } from '@apollo/client'
import fetch from 'cross-fetch'
import { GAZOO_ENV } from '@fiji/constants'

type Props = {
  url: string
}

export function createHttpLink({ url }: Props) {
  const httpLinkOptions: HttpOptions = {
    uri: url,
    credentials: 'include',
    headers: {},
    fetch,
  }

  if (process.env.NODE_ENV === 'development') {
    const gazooEnv = window.localStorage.getItem(GAZOO_ENV)
    if (gazooEnv) {
      httpLinkOptions.headers['X-Gazoo-Env'] = gazooEnv
    }
  }

  return new HttpLink(httpLinkOptions)
}
