import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const HeaderContainer = styled.div`
  width: 100%;
  align-self: flex-end;
`

export const ErrorContainer = styled.div`
  margin: auto;
`
