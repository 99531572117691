import { CreditCardBrandType } from '@fiji/graphql/types'
import { getBrandTypeByCardNumber } from '../../../utils/credit-card/get-brand-type'

type Args = {
  cardNumber: string
  brandType?: CreditCardBrandType
  isCardNumberFieldEdited: boolean
}

export function getCardBrandType({ cardNumber, brandType, isCardNumberFieldEdited }: Args) {
  if (isCardNumberFieldEdited) {
    return getBrandTypeByCardNumber(cardNumber)
  }

  return brandType || CreditCardBrandType.Other
}
