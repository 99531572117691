import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CarRentalGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects/trip-segments'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useShareSegment } from '../../../use-trip-details/use-share-segment'
import { useGetInfoLabels } from './use-get-info-labels'

type Params = {
  segment: CarRentalGroupedSegmentValueObject
  dateToShow: string
  isUpcoming: boolean
  isTripInProgress?: boolean
  onCancel?: () => void
  isOnHold: boolean
}

const PICK_UP_TRANSLATION = 'PostBooking.TripDetails.PickUp'
const DROP_OFF_TRANSLATION = 'PostBooking.TripDetails.DropOff'
const PICK_UP_AT_TRANSLATION = 'PostBooking.TripDetails.PickUpAt'
const DROP_OFF_AT_TRANSLATION = 'PostBooking.TripDetails.DropOffAt'
const SEGMENT_ARIA_LABEL = 'PostBooking.TripDetails.SegmentAriaLabel'
const CANCEL_TRANSLATION = 'PostBooking.TripDetails.Cancel'
const MODIFY_TRANSLATION = 'PostBooking.TripDetails.Modify'
const DATE_FORMAT = 'MMM d'
const TIME_FORMAT = 'h:mma'
const TIME_FORMAT_FOR_CARD = 'h:mm a'
const LOCATION_DIVIDER = ', '
const EMPTY_STR = ''

export function useCarRentalSegment({
  segment,
  dateToShow,
  isUpcoming,
  isTripInProgress,
  onCancel,
  isOnHold,
}: Params) {
  const { postBookingTripStore, postBookingTripModifyActions } = usePostBookingContext()
  const { trip } = postBookingTripStore
  const { isCarRentalChangeAllowed, isCarRentalCancelAllowed } = trip.supportedActions
  const isModificationAllowed = isCarRentalChangeAllowed && !isTripInProgress
  const isCancelAllowed = isCarRentalCancelAllowed && !isTripInProgress
  const { handleShare } = useShareSegment({ segment })
  const { t } = useTranslation()
  const isPickUp = segment.isPickUp
  const noModifyCarRental = useTogglePopup()
  const typeTitle = t('PostBooking.CarRentalDetails.CancelType')
  const actionTitle = isPickUp ? t(PICK_UP_TRANSLATION) : t(DROP_OFF_TRANSLATION)
  const { street1: pickUpStreet, city: pickUpCity } = segment.pickUpLocation?.address || {}
  const { street1: dropOffStreet, city: dropOffCity } = segment.dropOffLocation?.address || {}

  const title = [actionTitle, segment.provider].join(' ')

  const ariaLabel = t(`${SEGMENT_ARIA_LABEL}`, { label: `${title}. ${dateToShow}` })

  const pickUpOrDropOffTime = isPickUp ? segment.pickUpTime : segment.dropOffTime

  const timePickUpCard = dateFormat(
    new Date(deleteTimezone(pickUpOrDropOffTime || EMPTY_STR)),
    TIME_FORMAT_FOR_CARD,
  )

  const rentalTime = dateFormat(
    new Date(deleteTimezone(pickUpOrDropOffTime || EMPTY_STR)),
    TIME_FORMAT,
  )
  const rentalDate = dateFormat(
    new Date(deleteTimezone(pickUpOrDropOffTime || EMPTY_STR)),
    DATE_FORMAT,
  )

  const carNameAndDateLabel = `${rentalDate}, ${rentalTime.toLowerCase()}`

  const locationDetails = isPickUp
    ? [segment.provider, pickUpStreet, pickUpCity]
    : [segment.provider, dropOffStreet, dropOffCity]
  const formattedLocationDetails = locationDetails.filter(Boolean).join(LOCATION_DIVIDER)

  const locationTitle = isPickUp ? t(PICK_UP_AT_TRANSLATION) : t(DROP_OFF_AT_TRANSLATION)
  const dataTexts = [`${locationTitle} ${formattedLocationDetails}`]

  const onModify = useCallback(() => {
    return postBookingTripModifyActions.handleModifyCarRental(segment)
  }, [segment, postBookingTripModifyActions])

  const buttons = [
    {
      visible: isUpcoming,
      title: t(CANCEL_TRANSLATION),
      onClick: isCancelAllowed ? onCancel : noModifyCarRental.handleOpen,
    },
    {
      visible: isUpcoming && !isOnHold,
      title: t(MODIFY_TRANSLATION),
      onClick: isModificationAllowed ? onModify : noModifyCarRental.handleOpen,
    },
  ]

  const {
    headerDate,
    carRentalTitle,
    cardInfo,
    segmentPreview,
    cardSubTitle,
    infoLine,
  } = useGetInfoLabels({
    segment,
  })

  return {
    title,
    ariaLabel,
    carRentalStatus: segment.status,
    carNameAndDateLabel,
    timePickUpCard,
    dataTexts,
    buttons,
    typeTitle,
    isNoModifyCarRentalOpen: noModifyCarRental.isOpen,
    handleShare,
    handleNoModifyCarRentalClose: noModifyCarRental.handleClose,
    carRentalTitle,
    headerDate,
    cardInfo,
    segmentPreview,
    cardSubTitle,
    infoLine,
  }
}
