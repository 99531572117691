import { useCallback, useMemo, useState, useRef } from 'react'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

type Props = {
  longitude: number
  latitude: number
}

export const useHotelResultsMap = ({ longitude, latitude }: Props) => {
  const { hotelSearchResultsStore } = useHotelSearchResultsContext()

  const nextHotelCoordsRef = useRef<{
    lat: number
    lng: number
  }>()

  const centerPoint = useMemo(() => {
    let center = {
      lat: latitude,
      lng: longitude,
    }

    const [nextHotel] = hotelSearchResultsStore.hotels.map((hotel) => ({
      lng: hotel.address.geocode?.long,
      lat: hotel.address.geocode?.lat,
    }))

    if (nextHotel?.lng && nextHotel?.lat && !checkIsEqual(nextHotel, nextHotelCoordsRef.current)) {
      center = {
        lat: nextHotel.lat,
        lng: nextHotel.lng,
      }

      nextHotelCoordsRef.current = { ...center }
    }

    return center
  }, [hotelSearchResultsStore.hotels, latitude, longitude])

  const [selectedId, setSelectedId] = useState<string | null>('')
  const [highlightedId, setHighlightedId] = useState<string | null>('')

  const handleSelected = useCallback((selectedId: string | null) => {
    setSelectedId(selectedId)
    setHighlightedId(selectedId)
  }, [])

  const highlightHotelCard = useCallback((selectedId: string | null) => {
    setHighlightedId(selectedId)
  }, [])

  const selectedHotel = useMemo(
    () => hotelSearchResultsStore.hotels.find((hotel) => hotel.id === selectedId),
    [hotelSearchResultsStore.hotels, selectedId],
  )

  const highlightedHotel = useMemo(
    () => hotelSearchResultsStore.hotels.find((hotel) => hotel.id === highlightedId),
    [hotelSearchResultsStore.hotels, highlightedId],
  )

  return {
    centerPoint,
    handleSelected,
    selectedHotel,
    highlightedHotel,
    highlightHotelCard,
  }
}
