import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const VerticalDelimiter = styled.div`
  width: 1px;
  height: 10px;
  margin: 0 8px;
  background-color: ${(props) => props.theme.colors.borderLight};
`

export const HeaderWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
`

export const HeaderContainer = styled.div`
  padding: 15px 23px 16px 16px;
`

export const HotelOptions = styled.div<{ hasExtendedMargin?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${(props) => (props.hasExtendedMargin ? '15px' : '8px')};

  &:last-child {
    margin-bottom: 0;
  }

  > ${Text}:last-child {
    margin-left: 4px;
  }
`

export const HotelDetailsDescriptionTitle = styled(Text)``
