import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightBlockDesktop } from './desktop'
import { FlightBlockMobile } from './mobile'

export function FlightBlockLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightBlockMobile {...props} />}
      desktopSlot={<FlightBlockDesktop {...props} />}
    />
  )
}
