import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Separator } from '@etta/ui/separator'

const i18nBase = 'RailDeliveryOption.'

type Props = {
  email?: string
  onModalOpen: () => void
}

export function EticketViewPostBooking({ email, onModalOpen }: Props) {
  const { t } = useTranslation()

  if (!email) {
    return null
  }

  return (
    <Block paddingHorizontal={16} paddingVertical={12}>
      <Block marginBottom={18} isFlexBlock>
        <Block marginRight={14}>
          <Icon name="eticketPWA" size="medium" />
        </Block>
        <Text variant="subHeadStrong" color="mainText">
          {t(i18nBase + 'Eticket')}
        </Text>
      </Block>
      <Separator />
      <Block marginTop={12}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'DeliveryEticketPostBooking')}
          <Link size="small" href={`mailto:${email}`}>
            {email}
          </Link>
        </Text>
        <Block marginTop={4}>
          <Link size="small" onClick={onModalOpen}>
            {t(i18nBase + 'LearnMore')}
          </Link>
        </Block>
      </Block>
    </Block>
  )
}
