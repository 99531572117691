import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import {
  YearPickerContainer,
  YearPickerWrapper,
  YearPickerHeader,
  YearItem,
} from './year-picker-styled'

type Props = {
  isHide: boolean
  onHeaderClick: () => void
  headerText: string
  years: number[]
  onYearsItemClick: (year: number) => void
  currentYear?: number
}

export function YearPicker({
  isHide,
  onHeaderClick,
  headerText,
  years,
  onYearsItemClick,
  currentYear,
}: Props) {
  const { handleKeyDown } = useA11yKeydown(onYearsItemClick)
  const { handleKeyDown: handleHeaderKeyDown } = useA11yKeydown(onHeaderClick)

  return (
    <YearPickerContainer isHide={isHide}>
      <YearPickerHeader onClick={onHeaderClick} tabIndex={0} onKeyDown={handleHeaderKeyDown}>
        <Text variant="subHeadStrong" color="primary1">
          {headerText} <Icon size="small" name="chevronUpPWA" />
        </Text>
      </YearPickerHeader>
      <YearPickerWrapper>
        {years.map((year) => (
          <YearItem
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, year)}
            key={year}
            onClick={() => onYearsItemClick(year)}
            isActive={currentYear === year}>
            {year}
          </YearItem>
        ))}
      </YearPickerWrapper>
    </YearPickerContainer>
  )
}
