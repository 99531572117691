export function phone(oldValue: string, value: string) {
  const result = parseFloat(value)
  if (!value) {
    return value
  }
  if (isNaN(result)) {
    return String(oldValue)
  }
  return value.replace(/\D/g, '')
}
