import { Inject, Service } from '@etta/di'
import type {
  FilterTime,
  AirFilterStop,
  AirFilterAirline,
  AirFilterFareType,
  AirFilterAirport,
} from '@etta/modules/air-search/core/value-objects/air-filters'
import { anyStopsOption } from '@etta/modules/air-search/core/constants/stops'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { AirFiltersStore } from '../../stores/air-search/air-filters.store'
import { AirSearchStore } from '../../stores/air-search/air-search.store'

@Service()
export class AirFiltersService {
  constructor(
    @Inject() private airFiltersStore: AirFiltersStore,
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  handleResetFilters() {
    this.airFiltersStore.setFilters(this.airSearchStore.afterSearchFilters)
  }

  handleDropStops() {
    this.airFiltersStore.setSelectedStops([anyStopsOption])
  }

  handleDropAirlines() {
    this.airFiltersStore.setSelectedAirlines([])
  }

  handleDropFareTypes() {
    this.airFiltersStore.setSelectedFareTypes([])
  }

  handleDropOriginAirport() {
    if (this.featureFlagsStore.flags.isNewAirExposedFiltersEnabled) {
      const originAirportInitial = this.airSearchStore.afterSearchFilters.originAirports || []
      this.airFiltersStore.setSelectedOriginAirports(originAirportInitial)
      return
    }
    this.airFiltersStore.setSelectedOriginAirports([])
  }

  handleDropDestinationAirport() {
    if (this.featureFlagsStore.flags.isNewAirExposedFiltersEnabled) {
      const destinationAirports = this.airSearchStore.afterSearchFilters.destinationAirports || []
      this.airFiltersStore.setSelectedDestinationAirports(destinationAirports)
      return
    }
    this.airFiltersStore.setSelectedDestinationAirports([])
  }

  handleOriginTimeChange(time: FilterTime) {
    this.airFiltersStore.setOriginTime(time)
  }

  handleDestinationTimeChange(time: FilterTime) {
    this.airFiltersStore.setDestinationTime(time)
  }

  handleSelectStops(stops: AirFilterStop[]) {
    this.airFiltersStore.setSelectedStops(stops)
  }

  handleSelectAirlines(airline: AirFilterAirline) {
    const isFilterSelected = this.airFiltersStore.isAirlinesSelected(airline)
    if (isFilterSelected) {
      const filtered = this.filterItem(this.airFiltersStore.selectedAirlines, airline)
      this.airFiltersStore.setSelectedAirlines(filtered)
      return
    }
    const newSelected = [...this.airFiltersStore.selectedAirlines, airline]
    this.airFiltersStore.setSelectedAirlines(newSelected)
  }

  handleSelectFareTypes(fareType: AirFilterFareType) {
    const isFilterSelected = this.airFiltersStore.isFareTypeSelected(fareType)
    if (isFilterSelected) {
      const filtered = this.filterItem(this.airFiltersStore.selectedFareTypes, fareType)
      this.airFiltersStore.setSelectedFareTypes(filtered)
      return
    }
    const newSelected = [...this.airFiltersStore.selectedFareTypes, fareType]
    this.airFiltersStore.setSelectedFareTypes(newSelected)
  }

  handleSelectOriginAirport(airport: AirFilterAirport) {
    const isFilterSelected = this.airFiltersStore.isOriginAirportSelected(airport)
    if (isFilterSelected) {
      const filtered = this.filterItem(this.airFiltersStore.selectedOriginAirports, airport)
      this.airFiltersStore.setSelectedOriginAirports(filtered)
      return
    }
    const newSelected = [...this.airFiltersStore.selectedOriginAirports, airport]
    this.airFiltersStore.setSelectedOriginAirports(newSelected)
  }

  handleSelectDestinationAirports(airport: AirFilterAirport) {
    const isFilterSelected = this.airFiltersStore.isDestinationAirportSelected(airport)
    if (isFilterSelected) {
      const filtered = this.filterItem(this.airFiltersStore.selectedDestinationAirports, airport)
      this.airFiltersStore.setSelectedDestinationAirports(filtered)
      return
    }
    const newSelected = [...this.airFiltersStore.selectedDestinationAirports, airport]
    this.airFiltersStore.setSelectedDestinationAirports(newSelected)
  }

  handleSetMatchedWithPolicy(value: boolean) {
    this.airFiltersStore.setIsMatchedWithPolicy(value)
  }

  filterItem<T extends { id?: string | number; code?: string }>(array: T[], incomingItem: T) {
    return array.filter((item) => item.id !== incomingItem.id || item.code !== incomingItem.code)
  }
}
