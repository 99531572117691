import { Action, Inject } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
// eslint-disable-next-line import/no-restricted-paths
import { SegmentTypeCheckService } from '@etta/modules/review-trip/interface/services/segment-type-check.service'
import { LuggageOptionsStore } from '../stores'
import type { LuggageOptionValueObject } from '../../../core/value-objects'

@Action()
export class LuggageOptionsActions {
  constructor(
    @Inject() private readonly luggageOptionsStore: LuggageOptionsStore,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  setLuggageOption(luggageOption?: LuggageOptionValueObject) {
    const flights = this.reviewTripStore.segments.filter((segment) =>
      this.segmentTypeCheckService.isFlightSegment(segment),
    )
    const isOneWay = flights.length === 1

    if (isOneWay) {
      this.luggageOptionsStore.setDepartureLuggageOption(luggageOption)
      this.luggageOptionsStore.setReturnLuggageOption(undefined)
      return
    }

    this.luggageOptionsStore.setDepartureLuggageOption(luggageOption)
    this.luggageOptionsStore.setReturnLuggageOption(luggageOption)
  }

  resetLuggageOption() {
    this.luggageOptionsStore.dropStore()
  }
}
