import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const MobileBackButton = styled.button`
  width: 44px;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  background: transparent;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  margin-right: 8px;

  & > div {
    width: 16px;
    height: 16px;
  }
`

export const TransitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .slide-right-enter {
    transform: translateX(100%);
  }

  .slide-right-enter-active,
  .slide-right-appear-done,
  .slide-right-enter-done {
    transform: translateX(0%);
    transition: transform 300ms;
  }

  .slide-right-exit,
  .slide-right-done {
    transform: translateX(0%);
  }

  .slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms;
  }

  .slide-left-enter {
    transform: translateX(-100%);
  }

  .slide-left-enter-active,
  .slide-left-appear-done,
  .slide-left-enter-done {
    transform: translateX(0%);
    transition: transform 300ms;
  }

  .slide-left-exit,
  .slide-left-exit-done {
    transform: translateX(0%);
  }

  .slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
  }
`

export const AnimateContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${screenSize.minMobile}) {
    position: absolute;
  }
`
