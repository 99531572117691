import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  margin: 0 32px;
`

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  margin: 24px 32px 0;
`

export const ErrorContainer = styled.div`
  margin-top: 250px;
`

export const NoResultsContainer = styled.div`
  margin-top: 195px;
`
