import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { ExpiredMessage } from './expired-message'

type Props = {
  isTripExpired: boolean
}

export function PriceInformation({ isTripExpired }: Props) {
  const { t } = useTranslation()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isHoldTripAllowed } = displayConfigurationStore
  const { isOnHoldEnabled } = featureFlagsStore.flags

  if (isTripExpired) {
    return <ExpiredMessage />
  }

  return (
    <Block isFlexBlock alignItems="flex-start" paddingBottom={16} paddingTop={12}>
      <Block marginRight={4}>
        <Icon name="timerPWA" color="bodyText" size="normal" />
      </Block>
      <Text variant="subHeadMedium" color="bodyText">
        {isHoldTripAllowed && isOnHoldEnabled
          ? t('TripPlanner.TripCart.HoldTripPriceInfo')
          : t('TripPlanner.TripCart.PriceInfo')}
      </Text>
    </Block>
  )
}
