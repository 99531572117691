import { RideHailProvider } from '@etta/modules/ride-hail/core'
import { RideHailProvider as GqlRideHailProvider } from '@fiji/graphql/hooks'

export function buildRideHailProvider(input: RideHailProvider): GqlRideHailProvider {
  switch (input) {
    case RideHailProvider.Uber:
      return GqlRideHailProvider.Uber
    case RideHailProvider.Unspecified:
      return GqlRideHailProvider.Unspecified
  }
}
