import type { LayoutProps } from '../../types'
import { useAmenities } from '../../use-amenities'
import { Container, List, ListItem, StyledChevronIcon, StyledIcon } from './amenities-mobile-styled'

export function AmenitiesMobile({ carRental }: LayoutProps) {
  const { amenitiesCount, handleToggle, isExpanded, ariaLabel, amenities } = useAmenities({
    carRental,
  })

  return (
    <Container onClick={handleToggle} aria-label={ariaLabel} aria-expanded={isExpanded}>
      <List isColumn={isExpanded} aria-hidden={!isExpanded} amenitiesCount={amenitiesCount}>
        {amenities.map((amenity) => (
          <ListItem key={amenity.title}>
            <StyledIcon name={amenity.icon} />
            {isExpanded && amenity.title}
          </ListItem>
        ))}
      </List>
      <StyledChevronIcon name="chevronDownPWA" rotate={isExpanded} color="mainText2" />
    </Container>
  )
}
