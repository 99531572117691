import type { ScreenType } from '@fiji/modes'
import type { PlainTime as Time } from '@fiji/types'

export enum CustomFocusedInput {
  date = 'date',
  time = 'time',
}

export type LayoutControlsProps = {
  isOpen: boolean
  focusedInput: CustomFocusedInput
  date?: Date | null
  time?: Time | null
  dateTitle?: string
  timeTitle?: string
  dateAriaLabel?: string
  timeAriaLabel?: string
  handleClickTabDate: () => void
  handleClickTabTime: () => void
  isNowSelected?: boolean
  forceScreenType?: null | ScreenType
}
