import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { checkEmptyPreference } from './check-empty-preference'
import { getPreferencesAvailability } from './get-preferences-availability'
import { removeDisabledMembership } from './remove-disabled-membership'
import type { Args } from './types'

export function usePreferenceBox({
  preferences,
  availableToSelect,
  disabledMemberships,
  type,
}: Args) {
  const isPreferencesEmpty = type !== 'rail' ? checkEmptyPreference(preferences) : false
  const { handleClose, isOpen, handleOpen } = useTogglePopup(isPreferencesEmpty)
  const filteredPreferences = removeDisabledMembership({
    preferences,
    disabledVendors: disabledMemberships?.map((membership) => membership.code).filter(Boolean),
  })
  const areAllPreferencesUnavailable = getPreferencesAvailability(availableToSelect)

  return {
    isPreferencesEmpty,
    handleClose,
    isOpen: type === 'rail' || isOpen,
    handleOpen,
    filteredPreferences,
    areAllPreferencesUnavailable,
  }
}
