export const TravelPreferencesIdentifiers = {
  85: 'homeAirport',
  86: 'airMeal',
  87: 'airSeatPreference',
  90: 'airSpecialRequest',
  96: 'hotelSpecialRequest',
  100: 'carRentalType',
  103: 'carRentalSpecialRequest',
  122: 'airTravelAgencyNote',
}
