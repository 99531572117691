import { observer } from 'mobx-react-lite'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { CarRentalFiltersModal } from '../car-rental-filters-modal'
import { NewCarRentalFiltersModal } from '../new-car-rental-filters-modal'

export const CarRentalAllFiltersModal = observer(function CarRentalAllFiltersModal() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore

  if (flags.isCarRentalsNewFiltersEnabled) {
    return <NewCarRentalFiltersModal />
  }

  return <CarRentalFiltersModal />
})
