import { useMedia } from 'react-use'
import { ServiceSearchLayout } from '@etta/components/service-search-layout'
import { PresearchMultiLayerCostAllocation } from '@etta/components/multi-layer-cost-allocation'
import { SegmentType } from '@fiji/graphql/types'
import { screenSize } from '@fiji/style'
import type { LayoutProps } from '../../types'
import { Container, ContainerPage } from './car-rental-search-page-desktop-new-styled'
import { CarRentalSearchPageDesktopContent } from './car-rental-search-page-content'

export function CarRentalSearchPageDesktopNew({
  pickUpPlace,
  onPickUpPlaceChange,
  dropOffPlace,
  onDropOffPlaceChange,
  pickUpDate,
  dropOffDate,
  onDatesSelect,
  pickUpTime,
  dropOffTime,
  onDropOffTimeChange,
  onPickUpTimeChange,
  onSearchClick,
  placesHistory,
  pickUpDateToggle,
  dropOffDateToggle,
  pickUpTimeToggle,
  dropOffTimeToggle,
  onSwitchLocations,
  pickUpAddress,
  dropOffAddress,
  isBlockView,
  postBookingAction,
  onBack,
  validationErrors,
}: LayoutProps) {
  const isMaxLaptop = useMedia(`only screen and (${screenSize.maxLaptop})`)

  if (isBlockView) {
    return (
      <Container>
        <CarRentalSearchPageDesktopContent
          pickUpPlace={pickUpPlace}
          dropOffPlace={dropOffPlace}
          pickUpDate={pickUpDate}
          dropOffDate={dropOffDate}
          pickUpAddress={pickUpAddress}
          dropOffAddress={dropOffAddress}
          pickUpTime={pickUpTime}
          dropOffTime={dropOffTime}
          placesHistory={placesHistory}
          pickUpDateToggle={pickUpDateToggle}
          dropOffDateToggle={dropOffDateToggle}
          pickUpTimeToggle={pickUpTimeToggle}
          dropOffTimeToggle={dropOffTimeToggle}
          onSwitchLocations={onSwitchLocations}
          onPickUpPlaceChange={onPickUpPlaceChange}
          onDropOffPlaceChange={onDropOffPlaceChange}
          onDatesSelect={onDatesSelect}
          onDropOffTimeChange={onDropOffTimeChange}
          onPickUpTimeChange={onPickUpTimeChange}
          onSearchClick={onSearchClick}
          validationErrors={validationErrors}
        />
      </Container>
    )
  }

  return (
    <ServiceSearchLayout
      type={SegmentType.CarRental}
      postBookingAction={postBookingAction}
      onBack={onBack}
      isNewLayout
      costAllocationSlot={<PresearchMultiLayerCostAllocation />}>
      <ContainerPage isMaxLaptop={isMaxLaptop}>
        <CarRentalSearchPageDesktopContent
          pickUpPlace={pickUpPlace}
          dropOffPlace={dropOffPlace}
          pickUpDate={pickUpDate}
          dropOffDate={dropOffDate}
          pickUpAddress={pickUpAddress}
          dropOffAddress={dropOffAddress}
          pickUpTime={pickUpTime}
          dropOffTime={dropOffTime}
          placesHistory={placesHistory}
          pickUpDateToggle={pickUpDateToggle}
          dropOffDateToggle={dropOffDateToggle}
          pickUpTimeToggle={pickUpTimeToggle}
          dropOffTimeToggle={dropOffTimeToggle}
          onSwitchLocations={onSwitchLocations}
          onPickUpPlaceChange={onPickUpPlaceChange}
          onDropOffPlaceChange={onDropOffPlaceChange}
          onDatesSelect={onDatesSelect}
          onDropOffTimeChange={onDropOffTimeChange}
          onPickUpTimeChange={onPickUpTimeChange}
          onSearchClick={onSearchClick}
          validationErrors={validationErrors}
        />
      </ContainerPage>
    </ServiceSearchLayout>
  )
}
