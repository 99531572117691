import styled, { css } from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { subHeadStrong } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 310px;
`

export const NewPriceContainer = styled.div`
  margin: 24px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

export const PriceChangeContainer = styled.div`
  display: flex;

  & span {
    ${subHeadStrong};
    line-height: 20px;
  }
  color: ${({ theme }) => theme.colors.error};
`

export const PriceIcon = styled(Icon)<{ withRotate?: boolean }>`
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 1px;
  ${({ withRotate }) =>
    withRotate &&
    css`
      transform: rotate(-180deg);
    `};
`
