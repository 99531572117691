import { SegmentType, Location, IntentState, AlignState } from '@fiji/graphql/types'
import type { CarRentalSegmentEntity } from '../../../core/entity'
import { SegmentState } from '../../../core/enum/segment-state.enum'
import type { CarRentalDomain } from './types'

export class CarRentalDomainMapper {
  static toCarRentalSegmentEntity({
    domainObject,
    intentId,
    state,
    isInPolicy,
  }: {
    domainObject: CarRentalDomain
    intentId: string
    state: IntentState
    isInPolicy: boolean
  }): CarRentalSegmentEntity {
    const baseRate = domainObject.rate.totalRate
    const isMileageUnlimited = domainObject.rate.distanceUnlimited
    const extraDayRate = domainObject.rate.extraDayCharge
    const extraHourRate = domainObject.rate.extraHourCharge
    const perDayRate = domainObject.rate.dailyRate
    // will be provided from the graphql
    const perWeekRate = undefined
    const contractName = undefined
    const pickUpWorkingHours = {
      // hardcoded for now
      isAllDay: false,
      range: [
        `${domainObject.pickupLocation.openingHours[0]?.times[0]?.openingTime} - ${domainObject.pickupLocation.openingHours[0]?.times[0]?.closingTime}`,
      ],
    }
    const dropOffWorkingHours = {
      // hardcoded for now
      isAllDay: false,
      range: [
        `${domainObject.dropOffLocation.openingHours[0]?.times[0]?.openingTime} - ${domainObject.dropOffLocation.openingHours[0]?.times[0]?.closingTime}`,
      ],
    }

    const segmentState = state === IntentState.Modified ? SegmentState.Changed : undefined

    return {
      position: 0,
      type: SegmentType.CarRental,
      uuid: intentId,
      carClass: '',
      carType: '',
      carTransmission: '',
      vendor: '',
      vendorCode: '',
      isCarAirConditioned: true,
      carCapacity: 0,
      carLuggage: 0,
      baseRate,
      carCostRates: {
        isMileageUnlimited,
        extraDayRate,
        extraHourRate,
        perDayRate,
        perWeekRate,
      },
      carCosts: {
        isMileageUnlimited: isMileageUnlimited,
        // ??
        extraDay: extraDayRate?.primary,
        // ??
        extraHour: extraHourRate?.primary,
        // ??
        perDay: perDayRate?.primary,
        // ??
        perWeek: perWeekRate,
      },

      segmentState,
      carDetails: {
        capacity: domainObject.vehicle.passengerCapacity,
        isAirConditioned: domainObject.vehicle.isAirCondition,
        luggage: domainObject.vehicle.baggageCapacity,
        class: domainObject.vehicle.vehicleClass,
        fuel: domainObject.vehicle.fuelType,
        transmission: domainObject.vehicle.transmissionType,
        contractName,
        imageUrl: domainObject.vehicle.imageUrl,
        type: domainObject.vehicle.category,
      },
      carId: domainObject.key,
      carLocationType: {
        dropoff: {
          type: domainObject.dropOffLocation.isAirport ? Location.Airport : Location.Private,
          code: domainObject.dropOffLocation.isAirport
            ? domainObject.dropOffLocation.locationAddress.airportCode
            : domainObject.dropOffLocation.locationId,
          name: domainObject.dropOffLocation.locationAddress.locationName,
        },
        pickup: {
          type: domainObject.pickupLocation.isAirport ? Location.Airport : Location.Private,
          code: domainObject.pickupLocation.isAirport
            ? domainObject.pickupLocation.locationAddress.airportCode
            : domainObject.pickupLocation.locationId,
          name: domainObject.pickupLocation.locationAddress.locationName,
        },
      },

      // hardcoded field for now
      isPreferred: false,
      dropOffTime: domainObject.dropOffTime,
      dropOffLocation: {
        address: domainObject.dropOffLocation.locationAddress,
        date: domainObject.dropOffDate,
        // hardcoded for now
        isDeliveryCollection: null,
        phone: {
          number: domainObject.dropOffLocation.phone,
          // hardcoded for now
          type: '',
        },
        workingHours: dropOffWorkingHours,
      },
      name: domainObject.vehicle.modelName,
      pickUpLocation: {
        address: domainObject.pickupLocation.locationAddress,
        date: domainObject.pickupDate,
        // hardcoded for now
        isDeliveryCollection: null,
        phone: {
          number: domainObject.pickupLocation.phone,
          // hardcoded for now
          type: '',
        },
        workingHours: pickUpWorkingHours,
      },
      pickUpTime: domainObject.pickupTime,
      rate: domainObject.rate.totalRate,
      policy: {
        isInPolicy,
      },
      // provided from the graphql
      safetyCheck: {
        messageCode: domainObject.safetyCheck.messageCode,
        badgeUrl: domainObject.safetyCheck.badgeUrl,
        url: domainObject.safetyCheck.url,
      },

      vendorDetails: {
        code: domainObject.vendor.code,
        name: domainObject.vendor.name,
        imageUrl: domainObject.vendor.imageUrl,
      },
      feesAndTaxesRate: domainObject.rate.totalTaxes,
      totalCostRate: domainObject.rate.totalCost,
      pickupLocationId: domainObject.pickupLocation.locationId,
      dropoffLocationId: domainObject.dropOffLocation.locationId,
      termsAndConditions: domainObject.termsAndConditions,
      alignedWithFlightDate: true,
      alignedWithFlightTime: true,
      alignState: AlignState.Aligned,

      // hardcoded for now
      isReserved: false,

      // serviceFee:  // not consumed by the UI
      // preferredDescription?: Maybe<PreferredDescription> // not needed right now
    }
  }
}
