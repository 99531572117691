import { Maybe } from 'fnscript'
import { KG_IN_TONNE } from '@fiji/constants'

export function sum(acc: number, amount?: number) {
  return acc + Maybe.from(amount).withDefault(0)
}

export function tonnesToKg(tonnes: number) {
  return Number((tonnes * KG_IN_TONNE).toFixed(2))
}
