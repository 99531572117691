// eslint-disable-next-line import/no-restricted-paths
import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { Validator } from '@etta/interface/services/validator'

@Store()
export class TravelPreferencesAdditionalInformationStore {
  private _isSaveButtonDisabled = true

  private _toggle = new Toggle()

  private _additionalInformationValidator: Validator = new Validator({})

  get isSaveButtonDisabled() {
    return this._isSaveButtonDisabled
  }

  get toggle() {
    return this._toggle
  }

  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this._isSaveButtonDisabled = isDisabled
  }
}
