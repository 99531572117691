import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { AmenityIcon } from '@etta/ui/amenity-icon'
import type { AdaptiveFlight } from '@etta/components/flight-details/types'
import { useScreenType } from '@fiji/modes'
import { FlightImage } from '@etta/components/flight-image'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { screenSize } from '@fiji/style'
import type {
  FlightLegSubSegment,
  SearchFlightLegSubSegment,
  TripDetailsFlightLegSegment,
} from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { pluralizeTime } from '../utils'
import {
  Container,
  FlexWrapper,
  Timeline,
  TimelineSolidLine,
  TimelineDashedLine,
  AirportCodeWrapper,
  AirportCode,
  TimelineBlock,
  Time,
  Date,
  AirportWithTerminal,
  Terminal,
  AirlineLogoWrapper,
  FlightBlock,
  FlightNumber,
  FlightAircraft,
  FlightTime,
  Amenities,
  AmenityIconWrapper,
  Divider,
  RowWrapper,
  FlightDetailsContainer,
  BlockContainer,
  ArrivesIn,
  OperatedBy,
  Row,
  TechnicalStopDashed,
  Ellipse,
  EllipseContainer,
} from './expanded-view-styled'
import { Layover } from './layover'
import { TechnicalStop } from './technical-stop'

type Props = {
  flight: AdaptiveFlight
  backgroundColor?: LayoutBackgroundColor
  isExpanded?: boolean
  daysInBetween?: number
  flightLegSegments?:
    | FlightLegSubSegment[]
    | TripDetailsFlightLegSegment[]
    | SearchFlightLegSubSegment[]
  shouldShowAirportChangeBadge?: boolean
}

const i18nBase = 'FlightDetails'

export function ExpandedView({
  flight,
  backgroundColor,
  isExpanded,
  daysInBetween,
  shouldShowAirportChangeBadge,
  flightLegSegments,
}: Props) {
  const screenType = useScreenType()
  const { t } = useTranslation()
  const { adaptedSegments } = flight
  const isMinTablet = useMedia(`only screen and (${screenSize.minTablet})`)
  const tabIndex = isExpanded ? 0 : -1

  return (
    <Container
      aria-label={t(i18nBase + '.ExpandedFlightDetails')}
      data-tracking-id="flight-details-legend-expanded">
      {adaptedSegments.map((segment, index) => {
        const duration = pluralizeTime(segment.flightTime.hours, segment.flightTime.minutes)
        const departureTerminalWithTranslation = `${t('FlightDetails.Terminal')} ${
          segment.departureTerminal
        }`
        const arrivalTerminalWithTranslation = `${t('FlightDetails.Terminal')} ${
          segment.arrivalTerminal
        }`
        const isLastSegment = index === adaptedSegments.length - 1
        const layoverText = `${segment.layover} ${t('FlightDetails.Layover')}`
        const isNextSegmentDepartAirportDifferentFromConnecting =
          flightLegSegments?.[index + 1]?.isDepartAirportDifferentFromConnecting
        const airportChangeBadgeSegments = isNextSegmentDepartAirportDifferentFromConnecting
          ? [flightLegSegments[index], flightLegSegments[index + 1]]
          : undefined

        return (
          <React.Fragment key={segment.id}>
            <FlexWrapper>
              <Timeline>
                <AirportCodeWrapper>
                  <AirportCode>{segment.departureAirportCode}</AirportCode>
                  <TimelineSolidLine />
                </AirportCodeWrapper>

                <TimelineBlock>
                  <BlockContainer
                    tabIndex={tabIndex}
                    aria-label={t(i18nBase + '.OriginSegmentDetails', {
                      airport: segment.departureAirportCode,
                      time: segment.departureTime,
                      date: segment.departureDate,
                      description: `${segment.departureAirportName}, ${departureTerminalWithTranslation}`,
                    })}>
                    <Time>{segment.departureTime}</Time>

                    <Date>{segment.departureDate}</Date>

                    <AirportWithTerminal>
                      {segment.departureCity} - {` `}
                      {segment.departureAirportName}
                      {segment.departureTerminal && (
                        <Terminal data-tracking-id="departure-terminal">
                          , {departureTerminalWithTranslation}
                        </Terminal>
                      )}
                    </AirportWithTerminal>
                  </BlockContainer>

                  <FlightBlock
                    tabIndex={tabIndex}
                    aria-label={t(i18nBase + '.FlightInfo', {
                      flight: segment.flightNumber,
                      aircraft: segment.aircraft,
                      duration,
                    })}>
                    <Row>
                      <AirlineLogoWrapper>
                        <FlightImage
                          key={segment.airlineLogoSquared}
                          image={segment.airlineLogoSquared}
                        />
                      </AirlineLogoWrapper>

                      <FlightNumber>{segment.flightNumber}</FlightNumber>
                    </Row>

                    <Divider />

                    <RowWrapper screenType={screenType}>
                      <FlightDetailsContainer>
                        <FlightAircraft>{segment.aircraft}</FlightAircraft>
                        <FlightTime>{segment.planeType}</FlightTime>
                        <FlightTime>{duration}</FlightTime>
                      </FlightDetailsContainer>

                      <Amenities screenType={screenType}>
                        {segment.amenities &&
                          segment.amenities.map((amenity) => (
                            <AmenityIconWrapper key={amenity.id}>
                              <AmenityIcon amenity={amenity.id} size="normal" color="mainText" />
                            </AmenityIconWrapper>
                          ))}
                      </Amenities>
                    </RowWrapper>

                    <Divider />

                    {segment.operatedBy && (
                      <OperatedBy data-tracking-id="operated-by">
                        {t('FlightDetails.OperatedBy', { operatedBy: segment.operatedBy })}
                      </OperatedBy>
                    )}
                  </FlightBlock>
                </TimelineBlock>
              </Timeline>
            </FlexWrapper>

            {flight.hasTechnicalStop && (
              <>
                <FlexWrapper tabIndex={tabIndex}>
                  <AirportCodeWrapper>
                    <EllipseContainer>
                      <Ellipse />
                    </EllipseContainer>
                    <TechnicalStopDashed />
                  </AirportCodeWrapper>
                  <TimelineBlock>
                    <TechnicalStop />
                  </TimelineBlock>
                </FlexWrapper>
                <FlexWrapper>
                  <Block paddingBottom={50} />
                  <AirportCodeWrapper>
                    <EllipseContainer>
                      <Ellipse />
                    </EllipseContainer>
                    <TimelineSolidLine />
                  </AirportCodeWrapper>
                </FlexWrapper>
              </>
            )}

            <FlexWrapper
              tabIndex={tabIndex}
              aria-label={t(i18nBase + '.DestinationSegmentDetails', {
                airport: segment.arrivalAirportCode,
                time: segment.arrivalTime,
                date: segment.arrivalDate,
                description: `${segment.arrivalAirportName}, ${segment.arrivalTerminal}`,
              })}>
              <AirportCodeWrapper>
                <AirportCode>{segment.arrivalAirportCode}</AirportCode>
                {segment.layover && <TimelineDashedLine />}
              </AirportCodeWrapper>

              <TimelineBlock>
                <Time>{segment.arrivalTime}</Time>

                <Date>
                  {segment.arrivalDate}
                  {isLastSegment && !!daysInBetween && (
                    <ArrivesIn>
                      {' '}
                      {t(i18nBase + '.ArrivesInNDays', { count: daysInBetween })}
                    </ArrivesIn>
                  )}
                </Date>

                <AirportWithTerminal>
                  {segment.arrivalCity} - {` `}
                  {segment.arrivalAirportName}
                  {segment.arrivalTerminal && (
                    <Terminal data-tracking-id="arrival-terminal">
                      , {arrivalTerminalWithTranslation}
                    </Terminal>
                  )}
                </AirportWithTerminal>
                {shouldShowAirportChangeBadge &&
                isNextSegmentDepartAirportDifferentFromConnecting ? (
                  <Layover
                    contentSlot={
                      <AirportChangeBadge
                        flightLegSegments={airportChangeBadgeSegments}
                        additionalText={`${isMinTablet ? ' - ' : ''}${layoverText}`}
                        isTransparent
                        withLineBreak={!isMinTablet}
                        size="small"
                        horizontalPadding={0}
                        verticalPadding={0}
                      />
                    }
                    backgroundColor={backgroundColor}
                  />
                ) : (
                  segment.layover && (
                    <Layover contentSlot={layoverText} backgroundColor={backgroundColor} />
                  )
                )}
              </TimelineBlock>
            </FlexWrapper>
          </React.Fragment>
        )
      })}
    </Container>
  )
}
