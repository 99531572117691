import { dateToTime } from '@fiji/utils/dates/date-to-time'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import type { Address } from '@fiji/graphql/types'
import { getNameByAddress } from './get-name-by-address'

type RangeResult = null | {
  to?: Address | null
  from?: Address | null
  startDate?: string
  endDate?: string
}

export function convertRangeToQuery(result?: RangeResult) {
  if (!result) {
    return null
  }

  const { to, from } = result

  return {
    startDate: result?.startDate ? isoToDate(deleteTimezone(result.startDate)) : undefined,
    endDate: result?.endDate ? isoToDate(deleteTimezone(result.endDate)) : undefined,
    startTime: result?.startDate ? dateToTime(new Date(result?.startDate)) : undefined,
    endTime: result?.endDate ? dateToTime(new Date(result?.endDate)) : undefined,
    to: {
      placeId: '',
      latitude: to?.geocode?.lat,
      longitude: to?.geocode?.long,
      airportCode: to?.airportCode || undefined,
      name: getNameByAddress(to),
      city: to?.city,
      countryCode: to?.countryCode,
    },
    from: {
      placeId: '',
      latitude: from?.geocode?.lat,
      longitude: from?.geocode?.long,
      airportCode: from?.airportCode || undefined,
      name: getNameByAddress(from),
      city: from?.city,
      countryCode: from?.countryCode,
    },
  }
}
