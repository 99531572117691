import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Container, Content, IconWrapper, Timeline } from './journey-details-styled'
import type { Props } from './type'
import { useJourneyDetails } from './use-journey-details'

export function JourneyDetails({ segment }: Props) {
  const {
    travelTime,
    originTime,
    departureStationName,
    destinationTime,
    arrivalStationName,
  } = useJourneyDetails({ segment })

  return (
    <Container>
      <Timeline>
        <IconWrapper>
          <Icon name="railPWA" />
        </IconWrapper>
        <IconWrapper>
          <Icon name="locationPWA" />
        </IconWrapper>
      </Timeline>
      <Content>
        <Block marginVertical={4}>
          <Text variant="subHeadStrong" color="mainText">
            {originTime}
          </Text>
        </Block>
        <Text variant="subHeadMedium" color="mainText">
          {departureStationName}
        </Text>
        <Block marginVertical={17}>
          <Text variant="linkSmall" color="bodyText">
            {travelTime}
          </Text>
        </Block>
        <Block marginVertical={4}>
          <Text variant="subHeadStrong" color="mainText">
            {destinationTime}
          </Text>
        </Block>
        <Text variant="subHeadMedium" color="mainText">
          {arrivalStationName}
        </Text>
      </Content>
    </Container>
  )
}
