import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'

export function UnusedTicketRatesSkeleton() {
  return (
    <Block marginTop={28}>
      <Skeleton width="100%" height="60px">
        <rect width="100%" height="24px" />
        <rect width="20%" y="46px" height="16px" />
        <rect width="40%" y="46px" x="80%" height="16px" />
      </Skeleton>
    </Block>
  )
}
