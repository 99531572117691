import type { RefObject } from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import type { AnchorsMap, SetAnchorType } from '../types'
import { getMarginForZero } from './get-margin-for-zero'

const MARGIN_GAP = 2

type Args = {
  scrollContainerRef: RefObject<HTMLDivElement>
}

export function useScrollContextValue({ scrollContainerRef }: Args) {
  const topAnchorRef = useRef<HTMLDivElement | null>(null)
  const anchorsRef = useRef<AnchorsMap>({})
  const zeroAnchorNameRef = useRef('')
  const marginFromZeroValueRef = useRef(0)
  const firstAnchorRef = useRef('')

  const calculateZero = useCallback(() => {
    if (marginFromZeroValueRef.current) {
      return
    }
    if (!zeroAnchorNameRef.current) {
      return
    }
    marginFromZeroValueRef.current = getMarginForZero(
      anchorsRef.current[zeroAnchorNameRef.current],
      topAnchorRef,
    )
  }, [])

  useEffect(() => {
    calculateZero()
  }, [calculateZero])

  const contextValue = useMemo(() => {
    return {
      setAnchor: ({ name, node, isZeroPoint }: SetAnchorType) => {
        if (isZeroPoint) {
          zeroAnchorNameRef.current = name
        }
        anchorsRef.current[name] = node
        if (!firstAnchorRef.current) {
          firstAnchorRef.current = name
        }
      },
      dropAnchor: (nodeName: string) => {
        delete anchorsRef.current[nodeName]
      },
      openAnchor: (nodeName: string) => {
        if (!topAnchorRef.current) {
          return
        }
        const nodeRef = anchorsRef.current[nodeName]
        if (!nodeRef?.current) {
          return
        }

        calculateZero()

        const rectTop = nodeRef.current.getBoundingClientRect().top
        const beaconRectTop = topAnchorRef.current.getBoundingClientRect().top
        const margin = marginFromZeroValueRef.current

        const top = rectTop - beaconRectTop - margin + MARGIN_GAP
        scrollContainerRef.current?.scroll({
          top,
          behavior: 'smooth',
        })
      },
    }
  }, [calculateZero, scrollContainerRef])

  return {
    calculateZero,
    contextValue,
    topAnchorRef,
    marginFromZeroValueRef,
    anchorsRef,
  }
}
