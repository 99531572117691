import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import type { Props } from '../types'
import {
  ProfileBoxContainer,
  Initials,
  Name,
  Email,
  ErrorSection,
  InlineLink,
  EditProfile,
  Personal,
  ErrorIcon,
} from './profile-box-mobile-styled'

export function ProfileBoxMobile({
  isValid,
  initials,
  fullName,
  email,
  errorMessage,
  onLinkClick,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TravelerInfo.'

  return (
    <ProfileBoxContainer isError={!isValid}>
      <Initials>{initials}</Initials>
      <Personal>
        <Name>{fullName}</Name>
        <Email>{email}</Email>
        <EditProfile>
          {!errorMessage && (
            <Link size="small" onClick={onLinkClick}>
              {t(i18Base + 'Edit')}
            </Link>
          )}
        </EditProfile>
      </Personal>
      {errorMessage && (
        <ErrorSection>
          <ErrorIcon name="warningFilledPWA" size="small" />
          <div>{errorMessage}</div>
          <InlineLink>
            <Link size="small" onClick={onLinkClick}>
              {t(i18Base + 'UpdateProfile')}
            </Link>
          </InlineLink>
        </ErrorSection>
      )}
    </ProfileBoxContainer>
  )
}
