import { getNodes } from './get-nodes'
import type { FocusElement } from './types'

export function setFocusOwnChild(parentNode: FocusElement) {
  const currentFocusedElement = document?.activeElement
  const nodes = getNodes(parentNode)
  if (currentFocusedElement) {
    const ownChild = nodes.find((focusItem) => focusItem.node === currentFocusedElement)
    if (!ownChild) {
      nodes[0]?.node.focus()
    }
  } else {
    nodes[0]?.node.focus()
  }
}
