import { shortenTextTo } from './text'

function resolveType(part: HTMLElement): string | null {
  if (part.nodeType === 1 && part.tagName === 'BR') {
    return 'br-el'
  }
  if (part.nodeType === 1 && part.tagName !== 'TITLE') {
    return part.innerText
  }
  if (part.nodeType === 3) {
    return part.textContent as string
  }
  return null
}

function removeNextChildren(parent: any, index: number) {
  for (let surplusIndex = parent.childNodes.length - 1; surplusIndex > index; surplusIndex--) {
    parent.childNodes[surplusIndex] && parent.removeChild(parent.childNodes[surplusIndex])
  }
}

export const composeMessageOfElements = (
  el: HTMLElement | Element | ChildNode,
  threshold: number,
): HTMLElement | Element | ChildNode => {
  const children = el.childNodes

  const parent = el
  let thresholdLeft = threshold
  let finalMessage = false

  Array.from(children, (part, i) => {
    const nodeOrText = resolveType(part as HTMLElement)
    if (thresholdLeft > 0 && nodeOrText && nodeOrText !== 'br-el' && !finalMessage) {
      if (nodeOrText.length < thresholdLeft) {
        thresholdLeft -= nodeOrText.length
      } else {
        if (part.childNodes.length === 0) {
          parent.childNodes[i].textContent = parent.childNodes[i].textContent
            ? shortenTextTo(parent.childNodes[i].textContent!, thresholdLeft)
            : null
          removeNextChildren(parent, i)

          finalMessage = true
        } else {
          composeMessageOfElements(parent.childNodes[i], thresholdLeft)
        }
        thresholdLeft = 0
      }
    } else if (thresholdLeft === 0 || finalMessage) {
      removeNextChildren(parent, i - 1)
    }
  })
  return parent
}
