import type { Props } from '../../types'
import { AttrIcon } from '../../attr-icon'
import { Container, Item } from './fare-card-attributes-desktop-styled'

export function FareCardAttributesDesktop({ ticketAttributes }: Props) {
  if (!ticketAttributes?.length) {
    return null
  }

  return (
    <Container>
      {ticketAttributes.map((attribute) => (
        <Item key={attribute.id} aria-label={attribute.name}>
          <AttrIcon assessment={attribute.assessment} attrId={attribute.id} iconColor="mainText" />
          {attribute.name}
        </Item>
      ))}
    </Container>
  )
}
