import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { HotelMarker } from '@fiji/types'
import { config } from '@fiji/config'

const initialState: HotelMarker[] = []

const slice = createSlice({
  name: 'hotel-markers',
  initialState,
  reducers: {
    updateHotelMarkers(state, action: PayloadAction<HotelMarker[]>) {
      if (config.enableAsyncHotelMarkersLoading) {
        return [...state, ...action.payload].filter(
          (hotel, index, self) => index === self.findIndex((t) => t.hotelId === hotel.hotelId),
        )
      }
      return action.payload
    },
    clearHotelMarkers() {
      return []
    },
  },
})

export const hotelMarkersReducer = slice.reducer
export const { updateHotelMarkers, clearHotelMarkers } = slice.actions
