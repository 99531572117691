import { css } from 'styled-components'
import { screenSize } from '@fiji/style'

export const deemFooterStyled = css`
  .deem-footer {
    margin-top: 10px;

    @media (${screenSize.maxTablet}) {
      .deem-chrome-footer {
        min-width: auto;
      }

      .deem-chrome-box {
        min-width: auto;
        width: 100%;

        &.deem-chrome-footer-box {
          min-width: auto;
          display: flex;
          flex-direction: column;

          > div {
            margin-bottom: 15px;
          }
        }
      }

      .deem-chrome-support {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .deem-chrome-support-content {
        width: auto;
      }
    }
  }
`
