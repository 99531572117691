import styled from 'styled-components'
import { Link } from '@etta/ui/link'

export const Hr = styled.div`
  margin: 16px 0;
  background-color: ${(props) => props.theme.colors.borderLight};
  height: 1px;
`

export const LinkWithIcon = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`
