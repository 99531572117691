import { usePlacesStorageContext } from '@etta/modules/places-storage/interface/use-places-storage.context'
import type { PlacesStorageType } from '@etta/modules/places-storage/core/value-objects/places-storage.value-object'

export function useStoragePlace(type: PlacesStorageType) {
  const { placesStorageAction, placesStorageStore } = usePlacesStorageContext()

  return {
    places: placesStorageStore.getPlaces(type),
    /** @deprecated Use `addNewPlace' instead. */
    setPlaces: placesStorageAction.addNewPlace(type),
    addNewPlace: placesStorageAction.addNewPlace(type),
  }
}
