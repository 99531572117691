import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
`

const TileSwitcherButton = styled.button`
  padding: 10px 16px;
  margin: 0;
  display: block;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`

export const MapButton = styled(TileSwitcherButton)`
  border-right: 1px solid ${({ theme }) => theme.colors.borderLight};
`
export const SatelliteButton = TileSwitcherButton
