import { createElement, useState } from 'react'
import type { ViewState } from '@etta/ui/view-state-modal'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ConfirmationModal } from './confirmation-modal'

type Params = {
  onTripDeleted: () => void
}

export function useSwitchModal({ onTripDeleted }: Params) {
  const { activeTripStore, activeTripActions } = useReviewTripContext()
  const { removeActiveTrip } = activeTripActions

  const [viewState, setViewState] = useState<ViewState>('hidden')
  const showSwitchModal = () => {
    setViewState('custom')
  }
  const closeSwitchModal = () => {
    setViewState('hidden')
  }
  const hasActiveItinerary = !!activeTripStore.segments.length

  const deleteItinerary = () => {
    setViewState('loading')
    removeActiveTrip()
    onTripDeleted()
    closeSwitchModal()
  }

  const switchModalSlot = createElement(ConfirmationModal, {
    confirmationModalViewState: viewState,
    onSwitch: deleteItinerary,
    handleCloseModal: closeSwitchModal,
  })

  return {
    isModalEnabled: hasActiveItinerary,
    switchModalSlot,
    showSwitchModal,
  }
}
