import type { FunctionComponent, MutableRefObject, ReactNode } from 'react'
import { Background } from './header-styles'
import type { HeaderAnimation, HeaderColors, HeaderLayout, HeaderAlign } from './types'
import { CloseButton } from './buttons/close-button'
import { TextButton } from './buttons/text-button'
import { BackButton } from './buttons/back-button'
import { Title } from './title'
import { LeftSlot } from './left-slot'
import { CenterSlot } from './center-slot'
import { Layout } from './layout'
import { Border } from './border'
import { RightSlot } from './right-slot'

export type MobileHeaderProps = {
  headerRef?: MutableRefObject<HTMLElement | null>
  layout?: HeaderLayout
  animation?: HeaderAnimation
  children?: ReactNode
  leftSlot?: ReactNode
  rightSlot?: ReactNode
  backgroundColor?: HeaderColors
  withBorderBottom?: boolean
  withBoxShadow?: boolean
  className?: string
  minHeight?: number
  align?: HeaderAlign
  'data-tracking-id'?: string
}

type CompoundComponents = {
  CloseButton: typeof CloseButton
  BackButton: typeof BackButton
  TextButton: typeof TextButton
  Title: typeof Title
}

export const Header: FunctionComponent<MobileHeaderProps> & CompoundComponents = function ({
  headerRef,
  leftSlot,
  animation,
  children,
  rightSlot,
  className,
  minHeight,
  align,
  backgroundColor = 'white',
  withBorderBottom = false,
  withBoxShadow = false,
  layout = 'aligned',
  'data-tracking-id': dataTrackingId,
}) {
  const isRightSlotExist = !!rightSlot
  const isLeftSlotExist = !!leftSlot
  return (
    <Background
      data-tracking-id={dataTrackingId}
      backgroundColor={backgroundColor}
      ref={headerRef}
      animation={animation}
      withBoxShadow={withBoxShadow}>
      <Layout className={className} layout={layout} minHeight={minHeight} align={align}>
        {leftSlot && <LeftSlot layout={layout}>{leftSlot}</LeftSlot>}
        <CenterSlot
          layout={layout}
          isRightSlotExist={isRightSlotExist}
          isLeftSlotExist={isLeftSlotExist}>
          {children}
        </CenterSlot>
        {isRightSlotExist && <RightSlot layout={layout}>{rightSlot}</RightSlot>}
      </Layout>
      <Border withBorderBottom={withBorderBottom} />
    </Background>
  )
}

Header.CloseButton = CloseButton
Header.BackButton = BackButton
Header.TextButton = TextButton
Header.Title = Title
