import { Inject, Service } from '@etta/di'

import type { Place } from '@fiji/types'
import type { GetPlacesDTO, PlaceAutocompleteType } from '../../../infra'
import { GetAutocompleteAdapter } from '../../../infra'

@Service()
export class AutocompleteService {
  constructor(
    @Inject()
    private readonly getAutocompleteAdapter: GetAutocompleteAdapter,
  ) {}

  async getPlaces(dto: GetPlacesDTO, allowedPlaces?: PlaceAutocompleteType[]) {
    const result = await this.getAutocompleteAdapter.getPlaces(dto)

    if (result.isErr()) {
      return
    }

    return this.filterPlaces(result.getValue(), allowedPlaces)
  }

  private filterPlaces(places: Place[], allowedPlaces?: PlaceAutocompleteType[]): Place[] {
    if (!allowedPlaces?.length) {
      return places
    }

    return places.filter(({ placeType }) => !placeType || allowedPlaces?.includes(placeType))
  }
}
