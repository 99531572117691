import { Block } from '@etta/ui/block'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { DateTitle, SegmentContainer } from '../../date-segments-styled'
import type { LayoutProps } from '../types'
import { Segment } from '../segment'

export function DateSegmentMobile({
  date,
  segments,
  isUpcoming,
  isOnHold,
  isTripInProgress,
}: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewTripDetailsPageEnabled },
    },
  } = useFeatureFlagsContext()

  if (isNewTripDetailsPageEnabled) {
    return (
      <Block
        paddingHorizontal={16}
        marginVertical={20}
        data-tracking-id={`segments-under-date_${date}`}>
        {segments.map((segment, index) => (
          <Segment
            key={index}
            isOnHold={isOnHold}
            isUpcoming={isUpcoming}
            segment={segment}
            isTripInProgress={isTripInProgress}
          />
        ))}
      </Block>
    )
  }

  return (
    <Block
      paddingHorizontal={16}
      paddingVertical={25}
      data-tracking-id={`segments-under-date_${date}`}>
      <Block marginLeft={10} marginBottom={12}>
        <DateTitle
          role="heading"
          aria-level={3}
          aria-label={date}
          data-tracking-id="flight-date-text">
          {date}
        </DateTitle>
      </Block>
      <SegmentContainer>
        {segments.map((segment, index) => (
          <Segment
            key={index}
            isOnHold={isOnHold}
            isUpcoming={isUpcoming}
            segment={segment}
            isTripInProgress={isTripInProgress}
          />
        ))}
      </SegmentContainer>
    </Block>
  )
}
