import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace RailEmissionsErrors {
  export const GetEmissionsError = createErrorClass('Get Emissions Error')
  export const UnexpectedErrorGetEmissions = createErrorClass(
    'Unexpected error while getting emissions',
  )
}

export type RailEmissionsErrorsInstances = InstanceType<
  typeof RailEmissionsErrors[keyof typeof RailEmissionsErrors]
>
