import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { UserProfileAdapterResult } from '../types'
import { UserProfileErrors } from '../../core/errors/user-profile.errors'
import type { UserProfileEntity } from '../../core/entities/user-profile.entity'
import type { UserProfileAdditionalInfoInputValueObject } from '../../core/value-objects/user-profile-additional-info-input.value-object'
import { UserProfileMapper } from './mappers'
import { UserProfileDataProvider } from './user-profile.data-provider'
import { UserProfileInputMapper } from './mappers/update-user-profile-input.mapper'

@Adapter()
export class UserProfileAdapter {
  constructor(
    @Inject()
    private userProfileDataProvider: UserProfileDataProvider,
  ) {}

  async getUserProfile(
    shouldIncludeOpenEnrollmentRegistrationCustomFields?: boolean,
  ): UserProfileAdapterResult<UserProfileEntity> {
    try {
      const { data, error } = await this.userProfileDataProvider.getUserProfile(
        shouldIncludeOpenEnrollmentRegistrationCustomFields,
      )

      if (error) {
        return Result.Err(new UserProfileErrors.GetUserProfileErrorResponse(error))
      }

      return Result.Ok(UserProfileMapper.toUserProfileEntity(data.getUserProfile))
    } catch (e) {
      return Result.Err(new UserProfileErrors.GetUserProfileUnexpectedError(e))
    }
  }

  async updateUserProfileTravelPreferences(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateTravelPreferencesInput = UserProfileInputMapper.mapToUpdateUserProfileInput(input)

      const {
        data,
        errors,
      } = await this.userProfileDataProvider.updateUserProfileTravelPreferences(
        updateTravelPreferencesInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileTravelPreferencesErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfileTravelPreferences.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileTravelPreferencesErrorResponse(
            data?.updateUserProfileTravelPreferences.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserProfileErrors.UpdateUserProfileTravelPreferencesUnexpectedError(e))
    }
  }

  async updateUserProfileBusinessAddress(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateBusinessAddressInput = UserProfileInputMapper.mapToUpdateUserProfileInput(input)

      const { data, errors } = await this.userProfileDataProvider.updateUserProfileBusinessAddress(
        updateBusinessAddressInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileBusinessAddressErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfileBusinessAddress.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileBusinessAddressErrorResponse(
            data?.updateUserProfileBusinessAddress.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserProfileErrors.UpdateUserProfileBusinessAddressUnexpectedError(e))
    }
  }

  async updateUserProfileContactInformation(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateContactInformationInput = UserProfileInputMapper.mapToUpdateUserProfileInput(
        input,
      )

      const {
        data,
        errors,
      } = await this.userProfileDataProvider.updateUserProfileContactInformation(
        updateContactInformationInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileContactInformationErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfileContactInformation.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileContactInformationErrorResponse(
            data?.updateUserProfileContactInformation.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserProfileErrors.UpdateUserProfileContactInformationUnexpectedError(e))
    }
  }

  async updateUserProfilePersonalInformation(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updatePersonalInformationInput = UserProfileInputMapper.mapToUpdateUserProfileInput(
        input,
      )

      const {
        data,
        errors,
      } = await this.userProfileDataProvider.updateUserProfilePersonalInformation(
        updatePersonalInformationInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfilePersonalInformationErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfilePersonalInformation.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfilePersonalInformationErrorResponse(
            data?.updateUserProfilePersonalInformation.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(
        new UserProfileErrors.UpdateUserProfilePersonalInformationUnexpectedError(e),
      )
    }
  }

  async updateUserProfileHomeAddress(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateHomeAddressInput = UserProfileInputMapper.mapToUpdateUserProfileInput(input)

      const { data, errors } = await this.userProfileDataProvider.updateUserProfileHomeAddress(
        updateHomeAddressInput,
      )

      if (errors) {
        return Result.Err(new UserProfileErrors.UpdateUserProfileHomeAddressErrorResponse(errors))
      }

      if (!data?.updateUserProfileHomeAddress.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileHomeAddressErrorResponse(
            data?.updateUserProfileHomeAddress.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserProfileErrors.UpdateUserProfileHomeAddressUnexpectedError(e))
    }
  }

  async updateUserProfileEmergencyContact(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateEmergencyContactInput = UserProfileInputMapper.mapToUpdateUserProfileInput(input)

      const { data, errors } = await this.userProfileDataProvider.updateUserProfileEmergencyContact(
        updateEmergencyContactInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileEmergencyContactErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfileEmergencyContact.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileEmergencyContactErrorResponse(
            data?.updateUserProfileEmergencyContact.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new UserProfileErrors.UpdateUserProfileEmergencyContactUnexpectedError(e))
    }
  }

  async updateUserProfileEmployeeInformation(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UserProfileAdapterResult<{ success: boolean }> {
    try {
      const updateEmployeeInformationInput = UserProfileInputMapper.mapToUpdateUserProfileInput(
        input,
      )

      const {
        data,
        errors,
      } = await this.userProfileDataProvider.updateUserProfileEmployeeInformation(
        updateEmployeeInformationInput,
      )

      if (errors) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileEmployeeInformationErrorResponse(errors),
        )
      }

      if (!data?.updateUserProfileEmployeeInformation.success) {
        return Result.Err(
          new UserProfileErrors.UpdateUserProfileEmployeeInformationErrorResponse(
            data?.updateUserProfileEmployeeInformation.message,
          ),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(
        new UserProfileErrors.UpdateUserProfileEmployeeInformationUnexpectedError(e),
      )
    }
  }
}
