import { Inject, Action } from '@etta/di'
import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { UserProfileValidatorBuilder } from '../services/user-profile-validator-builder'
import { BusinessContactStore } from '../stores/business-contact.store'
import { UserStore } from '../stores/user.store'
import { UserProfileStore } from '../stores/user-profile.store'

@Action()
export class BusinessContactActions {
  constructor(
    @Inject() private readonly businessContactStore: BusinessContactStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {}

  handleFieldChange(key: Parameters<typeof this.businessContactStore.setField>[0]) {
    return (value: string | boolean) => this.businessContactStore.setField(key, value)
  }

  initializeBusinessContactInfo() {
    const profile = this.userStore.profile
    const addressCategory = FieldSettingsCategory.BusinessAddress
    this.setFormValue(
      AddressFields.Country,
      addressCategory,
      profile?.businessContact?.address?.countryCode,
    )
    this.setFormValue(
      AddressFields.Zip,
      addressCategory,
      profile?.businessContact?.address?.postalCode,
      'addressBlock',
    )
    this.setFormValue(
      AddressFields.Mailstop,
      addressCategory,
      profile?.businessContact?.address?.mailStop,
    )
    this.setFormValue(
      AddressFields.State,
      addressCategory,
      profile?.businessContact?.address?.stateCode,
      'addressBlock',
    )
    this.setFormValue(
      AddressFields.City,
      addressCategory,
      profile?.businessContact?.address?.city,
      'addressBlock',
    )
    this.setFormValue(
      AddressFields.Street1,
      addressCategory,
      profile?.businessContact?.address?.street1,
      'addressBlock',
    )
    this.setFormValue(
      AddressFields.Street2,
      addressCategory,
      profile?.businessContact?.address?.street2,
      'addressBlock',
    )

    const contactCategory = FieldSettingsCategory.BusinessContact
    this.setFormValue('businessEmail', contactCategory, profile?.personalContact?.email)
    this.setFormValue('groupwareId', contactCategory, profile?.groupware?.groupwareId)
    this.setFormValue(
      'officePhoneExtension',
      contactCategory,
      profile?.businessContact?.officePhone?.officeExtension,
      'officePhone',
    )
    this.setFormValue(
      'officePhoneNumber',
      contactCategory,
      profile?.businessContact?.officePhone?.number,
      'officePhone',
    )
    this.setFormValue(
      'officePhoneCountryCode',
      contactCategory,
      profile?.businessContact?.officePhone?.countryCode,
      'officePhone',
    )

    this.setFormValue(
      'mobilePhoneNumber',
      contactCategory,
      profile?.businessContact?.mobilePhone?.number,
      'mobilePhone',
    )
    this.setFormValue(
      'mobilePhoneCountryCode',
      contactCategory,
      profile?.businessContact?.mobilePhone?.countryCode,
      'mobilePhone',
    )

    this.setFormValue(
      'faxPhoneNumber',
      contactCategory,
      profile?.businessContact?.faxPhone?.number,
      'faxPhone',
    )
    this.setFormValue(
      'faxPhoneCountryCode',
      contactCategory,
      profile?.businessContact?.faxPhone?.countryCode,
      'faxPhone',
    )

    const personalInfoCategory = FieldSettingsCategory.PersonalInformation
    this.setFormValue(
      'homePhoneNumber',
      personalInfoCategory,
      profile?.personalContact?.phone?.number,
      'homePhone',
    )
    this.setFormValue(
      'homePhoneCountryCode',
      personalInfoCategory,
      profile?.personalContact?.phone?.countryCode,
      'homePhone',
    )

    this.setFormValue(
      'homeEmail',
      personalInfoCategory,
      profile?.personalContact?.homeEmail,
      'homeEmail',
    )
    this.setFormValue('profilePin', personalInfoCategory, profile?.profilePin)
    this.setFormValue('isVip', personalInfoCategory, profile?.employee?.isVip, 'vipIndicator')
  }

  initializeBusinessContactAdditionalInfoForm() {
    const additionalInformation =
      this.userProfileStore.userProfile?.businessAddress.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.businessContactStore.setAdditionalInfoValidator(validator)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) =>
      this.businessContactStore.setAdditionalInfoValue(key, value)
  }

  private setFormValue(
    validatorKey: Parameters<typeof this.businessContactStore.setField>[0],
    category: FieldSettingsCategory,
    value?: string | boolean | null,
    profileSettingKey?: string,
  ) {
    const profileSettingField = this.businessContactStore.fieldSettings[category][
      profileSettingKey || validatorKey
    ]

    if (!value || !profileSettingField?.isRenderedOnActivationPage) {
      return
    }

    const initializedValue = typeof value === 'string' ? this.getStrValueOr(value) : value
    this.businessContactStore.setField(validatorKey, initializedValue)
  }

  private getStrValueOr(value?: string | null, defaultValue: string = '') {
    if (value) {
      return value
    }
    return defaultValue
  }
}
