import styled from 'styled-components'
import { body, title3 } from '@fiji/style'

export const Container = styled.div`
  padding: 0 0 20px;
  display: flex;
  flex: 1;
  background: ${(props) => props.theme.colors.background};
  flex-direction: column;
`

export const Footer = styled.div`
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  background: ${(props) => props.theme.colors.white};
`

export const PolicyExplanation = styled.div`
  padding: 35px 20px 25px;
  ${body};
`

export const PolicyExplanationTitle = styled.div`
  margin: 0 0 10px;
  ${title3};
`
