import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { formatPrice } from '@fiji/utils/money'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { UberTermsConditionModal } from '@etta/components/mobility-terms-condition-modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { useIntervalTimer } from '@fiji/hooks/use-interval-timer'
import {
  CONTENT_TRANSITION_NAME,
  FOOTER_TRANSITION_NAME,
} from '@etta/components/mobility-ride-review-dialog/transition'
import { MobilityRideDetails } from '@etta/components/mobility-ride-details/mobility-ride-details'
import { MobilityRideReserveButton } from '@etta/components/mobility-ride-review-dialog/mobility-ride-reserve-button'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { MobilityRideReviewSkeleton } from './mobility-ride-review-skeleton/mobility-ride-review-skeleton'
import { UberTermAndPrivacyNotice } from './uber-term-and-privacy-notice'
import { useMobilityRideReviewDialog } from './use-mobility-ride-review-dialog'
import { useTrackMobilityRideReview } from './hooks'
import {
  Header,
  Footer,
  ImageContainer,
  RideDetailContainer,
  Image,
  RideContent,
  RideEstimatePrice,
  RideInformation,
  Price,
  Content,
  Container,
  IconWrapper,
} from './mobility-ride-review-dialog-styled'

type Props = {
  isOpenDialog: boolean
  containerRef?: (node: HTMLDivElement) => void
  handleOnEntered: () => void
}

export const MobilityRideReviewDialog = observer(function MobilityRideReviewDialog({
  isOpenDialog,
  containerRef,
  handleOnEntered,
}: Props) {
  const { isLoading, dropOffTime } = useMobilityRideReviewDialog()
  const { t } = useTranslation()
  const { handleClose, handleOpen, isOpen } = useTogglePopup()
  const {
    searchRideHailStore,
    rideHailAirportPickupStore,
    searchRideHailActions,
  } = useRideHailSearchContext()

  useTrackMobilityRideReview({ isOpenDialog })
  const isOnDemand = searchRideHailStore.isOnDemandRide

  useIntervalTimer({
    intervalInSeconds: 60,
    isRunning: isOnDemand,
    handleOnExpire: () => {
      searchRideHailActions.loadSearchRideHailWithLocationOption()
    },
  })

  if (!searchRideHailStore.selectedSearchRideHail) {
    return null
  }

  const basePath = 'RideAssist.CarSegment.'

  const {
    imageUrl,
    displayName,
    shortDescription,
    passengerCapacity,
    estimatedPrice,
  } = searchRideHailStore.selectedSearchRideHail

  const {
    pickUpDate,
    pickUpTime,
    pickUpPlace,
    pickUpAddress,
    dropOffAddress,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm

  const { arrivalFlight } = rideHailAirportPickupStore

  return (
    <Container ref={containerRef}>
      <UberTermsConditionModal isVisible={isOpen} isAnimated onClose={handleClose} />
      <DialogAnimation
        isOpen={isOpenDialog}
        onEntered={handleOnEntered}
        onExited={() => {}}
        isAnimated
        transitionName={CONTENT_TRANSITION_NAME}>
        <Content>
          <Header>
            <ImageContainer>
              {imageUrl && <Image src={imageUrl} alt={displayName} />}
            </ImageContainer>
            <RideDetailContainer>
              <RideContent>
                <Text variant="title3" color="mainText1" textOverflow="ellipsis-two-line">
                  {displayName}
                </Text>
                <Swap is={isLoading} isSlot={<MobilityRideReviewSkeleton />}>
                  <RideEstimatePrice onClick={handleOpen} data-tracking-id="ride-estimate-price">
                    <Text variant="captionMedium" color="bodyText">
                      {t(basePath + 'PriceText')}
                    </Text>
                    <Price>
                      <Text variant="title3" color="mainText1">
                        {estimatedPrice && formatPrice(estimatedPrice)}
                      </Text>
                    </Price>
                    <IconWrapper>
                      <Icon name="infoOutlinePWA" color="bodyText" size={'small'} />
                    </IconWrapper>
                  </RideEstimatePrice>
                </Swap>
              </RideContent>
              <RideInformation>
                <Block isFlexBlock={true}>
                  <Block paddingRight={4}>
                    <Icon size={'small'} name="peopleFilledNewPWA" />
                  </Block>
                  <Text variant="footnoteMedium" color="mainText1">
                    {passengerCapacity}
                  </Text>
                </Block>
                {isOnDemand && (
                  <Block isFlexBlock={true} marginLeft={17}>
                    <Block paddingRight={2}>
                      <Icon size={'small'} name="timerPWA" />
                    </Block>
                    <Swap is={isLoading} isSlot={<MobilityRideReviewSkeleton />}>
                      <Text variant="footnoteMedium" color="mainText1">
                        {t(basePath + 'DropOff')} {dropOffTime}
                      </Text>
                    </Swap>
                  </Block>
                )}
              </RideInformation>
              <Text variant="footnoteMedium" color="mainText1" textOverflow="ellipsis-two-line">
                {shortDescription}
              </Text>
            </RideDetailContainer>
          </Header>
          <Block paddingTop={26}>
            <MobilityRideDetails
              isShowFlightDetails={!!arrivalFlight}
              isCustomFlight={rideHailAirportPickupStore.isCustomFlight}
              airlineName={arrivalFlight?.airlineName || ''}
              flightDesignator={arrivalFlight?.flightDesignator || ''}
              pickUpDate={pickUpDate}
              pickUpTime={pickUpTime}
              pickUpPlace={pickUpPlace}
              pickUpAddress={pickUpAddress}
              dropOffPlace={dropOffPlace}
              dropOffAddress={dropOffAddress}
              isOnDemandRide={isOnDemand}
              isBooked={false}
            />
          </Block>
        </Content>
      </DialogAnimation>
      <DialogAnimation
        isOpen={isOpenDialog}
        onEntered={handleOnEntered}
        onExited={() => {}}
        isAnimated
        transitionName={FOOTER_TRANSITION_NAME}>
        <Footer>
          <UberTermAndPrivacyNotice onOpenTermAndPrivacyNotice={handleOpen} />
          <MobilityRideReserveButton
            onReserveRideClick={() => searchRideHailActions.setRenderState(RenderState.confirm)}
            isLoading={isLoading}
          />
        </Footer>
      </DialogAnimation>
    </Container>
  )
})
