import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 14px;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Footer = styled.div`
  padding-left: 16px;
  align-items: center;
`
