export default function HotelIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16.5579V11H34V16.5579H31.6842C31.6842 15.4181 30.7398 14.2421 29.6 14.2421H14.4C13.2777 14.2421 12.3158 15.4356 12.3158 16.5579H10ZM35.6105 19.8C36.378 19.8 37 20.4221 37 21.1895V33L34.4526 29.5263H9.5473L7 33V21.1895C7 20.4221 7.62209 19.8 8.38947 19.8H35.6105Z"
        fill="currentColor"
      />
    </svg>
  )
}
