import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import type { LayoutProps } from '../types'
import { DelegateSwitchDesktop } from './desktop'
import { DelegateSwitchMobile } from './mobile'

export function DelegateSwitchLayout(props: LayoutProps) {
  const {
    featureFlags: { isDesktopDelegatedFlowEnabled },
  } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<DelegateSwitchMobile {...props} />}
      desktopSlot={<DelegateSwitchDesktop {...props} />}
      forceScreenType={isDesktopDelegatedFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
