import { Maybe } from 'fnscript'
import { isSameDay } from 'date-fns'
import { getDifferenceInDays } from '@fiji/utils/dates/get-difference-in-days'
import { mapVehicleCode } from '@fiji/utils/thrust-carbon/map-vehicle-code'
import type { CalculateCarEmissionsBatchCarInput } from '@fiji/graphql/hooks'
import type { Cars } from '@etta/screens/car-rental-search-results/types'

export function mapToThrustCarbonCar(
  car: Cars[0],
  pickUpDate?: Date | null,
  dropOffDate?: Date | null,
): CalculateCarEmissionsBatchCarInput {
  const days = mapNumberOfDays(pickUpDate, dropOffDate)

  return {
    customRef: car.key,
    origin: {
      // We expect the values to always be present.
      // Any issues related to this query is tracked via Rollbar
      lat: car.pickUp.address.geocode?.lat as number,
      long: car.pickUp.address.geocode?.long as number,
    },
    destination: {
      // We expect the values to always be present.
      // Any issues related to this query is tracked via Rollbar
      lat: car.dropOff.address.geocode?.lat as number,
      long: car.dropOff.address.geocode?.long as number,
    },
    pickUpDateTime: Maybe.from(pickUpDate?.toISOString()).withDefault(''),
    vehicleCode: mapVehicleCode(car.key),
    days: days,
  }
}

export function mapNumberOfDays(checkIn?: Date | null, checkOut?: Date | null): number {
  if (!checkIn || !checkOut || isSameDay(checkIn, checkOut)) {
    return 1
  }

  return getDifferenceInDays(checkOut, checkIn)
}
