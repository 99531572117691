import { useTranslation } from 'react-i18next'
import type { Distance, TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Badge } from '@etta/ui/badge'
import { dateFormat, deleteTimezone, getDifferenceInCalendarDays } from '@fiji/utils/dates'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { SegmentWrapper, SegmentLogo } from './print-itinerary-modal.styled'

type Props = {
  segment: TripDetailsFlightLegSegment
  stops: number
  distance?: Distance | null
  stopIndex: number
}

function getDistanceLabel(distance?: Distance | null) {
  if (!distance) {
    return ''
  }

  return `${new Intl.NumberFormat().format(distance.value)}${distance.units?.toLowerCase()}`
}

export function FlightSegment({ segment, stops, distance, stopIndex }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const {
    name,
    images,
    plane,
    flightNumber,
    percentageOnTime,
    codeshareCarrier,
    departure,
    arrival,
    originTerminal,
    destinationTerminal,
    travelerSeat,
    isSeatAvailable,
    mealService,
    flightTime,
    departureDate,
    arrivalDate,
    confirmationNumber,
    serviceClass,
    status,
    membership,
    specialRequests,
    preferredDescription,
  } = segment

  const flightTimeLine =
    Number.isFinite(flightTime?.hours) &&
    Number.isFinite(flightTime?.minutes) &&
    `${flightTime?.hours}h ${flightTime?.minutes}m`

  const distanceLine = stops === 0 ? getDistanceLabel(distance) : ''

  const flightLine = [
    plane?.type,
    [name, flightNumber].filter(Boolean).join(' '),
    flightTimeLine,
    stops === 0 && t(i18nBase + 'Nonstop'),
    distanceLine &&
      t(i18nBase + 'Distance', {
        distance: distanceLine,
      }),
    percentageOnTime && t(i18nBase + 'PercentageOnTime', { percent: percentageOnTime }),
  ]
    .filter(Boolean)
    .join(' • ')

  const departureAddress = [departure?.airportCity, departure?.address?.stateCode]
    .filter(Boolean)
    .join(', ')
  const arrivalAddress = [arrival?.airportCity, arrival?.address?.stateCode]
    .filter(Boolean)
    .join(', ')

  const localDepartureDate = departureDate ? new Date(deleteTimezone(departureDate)) : null
  const localArrivalDate = arrivalDate ? new Date(deleteTimezone(arrivalDate)) : null

  const daysInFlight =
    localDepartureDate && localArrivalDate
      ? getDifferenceInCalendarDays(localArrivalDate, localDepartureDate)
      : 0
  const daysInFlightLabel = daysInFlight
    ? ` (${t(i18nBase + 'Nights', { count: daysInFlight })})`
    : ''

  const departTime = [
    localDepartureDate &&
      t(i18nBase + 'DepartOn', {
        date: dateFormat(localDepartureDate, 'ccc LLL d, yyyy h:mm a'),
      }),
    originTerminal &&
      t(i18nBase + 'Terminal', {
        terminal: originTerminal,
      }),
  ]
    .filter(Boolean)
    .join(' • ')
  const arrivalTime = [
    localArrivalDate &&
      t(i18nBase + 'ArriveAt', {
        date: dateFormat(localArrivalDate, 'ccc LLL d, yyyy h:mm a'),
      }) + daysInFlightLabel,
    destinationTerminal &&
      t(i18nBase + 'Terminal', {
        terminal: destinationTerminal,
      }),
  ]
    .filter(Boolean)
    .join(' • ')

  const mealLine =
    mealService?.map((meal) => capitalizeFirstLetter(meal.split('_').join(' ')))?.join(', ') || '--'

  const customBadge = preferredDescription?.customBadge.name

  return (
    <SegmentWrapper>
      {images.rectangle && <SegmentLogo src={images.rectangle} alt={name + 'logo'} />}
      <Text variant="subHeadStrong" color="mainText">
        {departureAddress} ({departure?.airportCode})
        <Icon name="arrowForwardPWA" size="small" />
        {arrivalAddress} ({arrival?.airportCode})
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {flightLine}
      </Text>
      {codeshareCarrier?.name && (
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'OperatedBy', { codeShareLabel: codeshareCarrier.name })}
        </Text>
      )}
      {customBadge && <Badge variant="customPreferred" iconSize="small" label={customBadge} />}
      {!customBadge && preferredDescription?.code && <Badge variant="preferred" iconSize="small" />}
      <Text variant="footnoteMedium" color="mainText">
        {departTime}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {arrivalTime}
      </Text>
      {stopIndex === 0 && codeshareCarrier?.name && (
        <Text variant="footnoteMedium" color="mainText">
          {t(i18nBase + 'Comments')}:&nbsp;
          {t(i18nBase + 'CheckInWith', { codeShareLabel: codeshareCarrier.name })}
        </Text>
      )}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Class')}: {serviceClass ? capitalizeFirstLetter(serviceClass) : '--'}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Seat')}:&nbsp;
        {isSeatAvailable ? (
          <Text variant="footnoteStrong" color="mainText">
            {travelerSeat || '--'}
          </Text>
        ) : (
          <Text variant="footnoteMedium" color="mainText">
            {t(i18nBase + 'SeatSelectionNotAllowed')}
          </Text>
        )}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'MealService')}: {mealLine}
      </Text>
      {membership?.map(({ number }, index) => (
        <Text key={number} variant="footnoteMedium" color="mainText">
          {index === 0 && <>{t(i18nBase + 'Membership')}:&nbsp;</>}
          {name} - {number}
        </Text>
      ))}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'SpecialRequests')}: {specialRequests?.join(', ')}
      </Text>
      {confirmationNumber && (
        <Text variant="footnoteMedium" color="mainText">
          {t(i18nBase + 'ReservationNo')}:&nbsp;
          <Text variant="footnoteStrong" color="mainText">
            {confirmationNumber}
          </Text>
        </Text>
      )}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Status')}: {status && capitalizeFirstLetter(status.split('_').join(' '))}
      </Text>
    </SegmentWrapper>
  )
}
