import styled from 'styled-components'

export const Container = styled.div<{ isVisible: boolean }>`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 16px;
  background: rgba(38, 38, 38, 0.8);
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  margin-top: ${(p) => (p.isVisible ? 0 : -10000)}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 150ms linear, margin-top 0ms linear ${(p) => (p.isVisible ? 0 : 150)}ms;
  z-index: 1;
`
