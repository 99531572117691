import styled, { css } from 'styled-components'

const ANIMATION = '0.5s ease-in-out'

const opacityAnimation = css`
  transition: ${ANIMATION};
`

export const Container = styled.div<{
  minHeight: number
}>`
  position: relative;
  overflow: hidden;
  min-height: ${(p) => p.minHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  transition: min-height;
`

export const Collapsed = styled.div<{ isExpanded: boolean }>`
  position: relative;
  opacity: ${(p) => (p.isExpanded ? '0' : '1')};
  z-index: ${(p) => (p.isExpanded ? 'auto' : '1')};
  ${opacityAnimation}
`
export const Expanded = styled.div<{ isExpanded: boolean }>`
  position: relative;
  opacity: ${(p) => (p.isExpanded ? '1' : '0')};
  z-index: ${(p) => (p.isExpanded ? '1' : 'auto')};
  max-height: ${(p) => (p.isExpanded ? '300' : '0')}px;
`
