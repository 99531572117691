import { observer } from 'mobx-react-lite'
import { usePaymentSummaryContext } from '@etta/modules/booking'
import { CheckoutContext } from '@etta/screens/checkout-context'
import { TravelerAcknowledgeConfirmationModal } from '../payment-summary/travel-acknowledge'
import { TravelFusionLinksWrapper } from './travel-fusion-links-wrapper'

export const TravelFusionLinks = observer(() => {
  const { paymentSummaryStore } = usePaymentSummaryContext()
  return (
    <>
      <CheckoutContext>
        <TravelFusionLinksWrapper />

        <TravelerAcknowledgeConfirmationModal
          isOpen={paymentSummaryStore.travelerAcknowledgeModal.isOpen}
          handleClose={paymentSummaryStore.travelerAcknowledgeModal.handleClose}
        />
      </CheckoutContext>
    </>
  )
})
