import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { SearchMechanismMobile } from './mobile'
import { SearchMechanismDesktop } from './desktop'

export function SearchMechanismLayout(props: LayoutProps) {
  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<SearchMechanismMobile {...props} />}
      desktopSlot={<SearchMechanismDesktop {...props} />}
    />
  )
}
