import { useTranslation } from 'react-i18next'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useScreenType, ScreenType } from '@fiji/modes'
import { RailDetailsEcoCheck } from '@etta/components/rail-details-eco-check'
import { RailFareCard } from '@etta/components/rail-fare-card/rail-fare-card'
import type { RailPolicies } from '@fiji/graphql/types'
import type { RailEmission } from '@fiji/types'
import type { TrainServiceActiveTab } from '@fiji/enums'
import type { RailFareValueObject } from '@etta/modules/rail/core/value-objects/rail-fare.value-object'
import type { FareConditionValueObject } from '@etta/modules/rail/core/value-objects/rail-search-result.value-object'
import type { PermittedStationsInfoValueObject } from '@etta/modules/rail/core/value-objects/permitted-stations-info.value-object'
import type { AdaptedRailSegment } from '../../../hooks/use-adaptive-rail/types'
import { RailEcoCheckContainer } from '../rail-details-styles'
import { FaresWrapper } from './fare-block-styled'

type Props = {
  isPreferred: boolean
  activeTab?: TrainServiceActiveTab
  faresToShow: RailFareValueObject[]
  adaptedSegments: AdaptedRailSegment[]
  onSelectFareSeats: (fare: RailFareValueObject) => void
  fareClassLabel?: string
  onInfoIconClick: (fareTier: string) => void
  handleOpenPermittedStations: (permittedStationsInfo: PermittedStationsInfoValueObject) => void
  fareConditions?: FareConditionValueObject[]
  railPolicies?: RailPolicies
  actualRailEmission?: RailEmission
}

const i18nBase = 'RailDetails.Fare'

export function FareBlock({
  isPreferred,
  faresToShow,
  actualRailEmission,
  onSelectFareSeats,
  ...props
}: Props) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (faresToShow.length === 0) {
    return (
      <FaresWrapper isMobile={isMobile}>
        <NoResultSearchScreen
          type="train"
          title={t(i18nBase + '.NoFares')}
          subTitle={t(i18nBase + '.NoFaresSubtitle')}
          buttons={[]}
        />
      </FaresWrapper>
    )
  }

  return (
    <FaresWrapper isMobile={isMobile}>
      {faresToShow.map((fare, index) => (
        <RailFareCard
          key={index}
          isPreferred={!!isPreferred}
          fare={fare}
          onSelect={() => onSelectFareSeats(fare)}
          {...props}
        />
      ))}
      {featureFlags.isSustainabilityEnabled && actualRailEmission && (
        <RailEcoCheckContainer>
          <RailDetailsEcoCheck {...actualRailEmission} />
        </RailEcoCheckContainer>
      )}
    </FaresWrapper>
  )
}
