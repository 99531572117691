import { Icon } from '@etta/ui/icon'
import { IconRow, Item } from '../rail-badges-styled'
import type { Props } from '../types'

export function Badge({ icon, title, skin }: Props) {
  return (
    <Item aria-label={title} skin={skin}>
      <IconRow>
        <Icon name={icon} size="small" />
      </IconRow>
      {title}
    </Item>
  )
}
