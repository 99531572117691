import { useState } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { AllocatedSeat, AllocatedSeatMap } from '../../types'

export function useGetTripDetailsQuery() {
  const { postBookingTripActions, segmentTypeCheckActions } = usePostBookingContext()

  const [isLoadingAllocatedSeat, setIsLoadingAllocatedSeat] = useState(false)
  const [isRailEticketDeliveryOption, setIsRailEticketDeliveryOption] = useState(true)
  const [allocatedSeatMap, setAllocatedSeatMap] = useState<AllocatedSeatMap | null>(null)

  const getAllocatedSeats = async (id: string) => {
    setIsLoadingAllocatedSeat(true)
    try {
      const result = await postBookingTripActions.refetchTripDetails({ id })
      if (result.isErr()) {
        throw new Error(result.getError().message)
      }
      const trip = result.getValue()

      const allocatedSeatData =
        trip.segments.reduce<AllocatedSeatMap>((acc, segment, index) => {
          if (segmentTypeCheckActions.isTrainSegment(segment)) {
            const allocatedSeats = segment.segments
              ?.map((trainSegment) => trainSegment.allocatedSeat)
              .filter((allocatedSeat): allocatedSeat is AllocatedSeat => Boolean(allocatedSeat))
            if (allocatedSeats) {
              acc[index] = allocatedSeats
            }
          }

          return acc
        }, {}) || {}

      const isRailEticket =
        trip.segments.find(segmentTypeCheckActions.isTrainSegment)?.selectedDeliveryOption?.code ===
        'ETK'
      setIsRailEticketDeliveryOption(isRailEticket)
      setAllocatedSeatMap(allocatedSeatData)
      setIsLoadingAllocatedSeat(false)
    } catch (error) {
      setIsLoadingAllocatedSeat(false)
    }
  }

  return {
    isLoadingAllocatedSeat,
    allocatedSeatMap,
    getAllocatedSeats,
    isRailEticketDeliveryOption,
  }
}
