import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace SiteSsoSettingsErrors {
  export const GetSiteSsoSettingsMappingError = createErrorClass(
    'Get Site SSO settings Mapping Error',
  )
  export const GetSiteSsoSettingsResponseError = createErrorClass(
    'Get Site SSO settings Error Response',
  )
  export const GetSiteSsoSettingsUnexpectedError = createErrorClass(
    'Get Site SSO settings Unexpected Error',
  )
}

export type SiteSsoSettingsErrorsInstances = InstanceType<
  typeof SiteSsoSettingsErrors[keyof typeof SiteSsoSettingsErrors]
>
