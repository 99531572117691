import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { TRANSITION_DURATION, TRANSITION_CLASS_NAME } from './transition'
import { Container, NotchButton, NotchNameWrapper } from './meeting-notch.styled'

const i18Base = 'Meeting.MeetingNotch'

export type MeetingNotchProps = {
  meetingName: string
  participantName: string
  isVisible: boolean
  isMeetingModeDelegate: boolean
  onExit: () => void
}

export const MeetingNotch = ({
  meetingName,
  participantName,
  isMeetingModeDelegate,
  isVisible,
  onExit,
}: MeetingNotchProps) => {
  const { t } = useTranslation()

  return (
    <CSSTransition
      appear={isVisible}
      in={isVisible}
      classNames={TRANSITION_CLASS_NAME}
      timeout={TRANSITION_DURATION}>
      <Container isVisible={isVisible}>
        <Icon size={21} name="calendarPWA" color="mainText2" />
        <NotchNameWrapper>
          {isMeetingModeDelegate && (
            <Text variant="footnoteMedium" color="mainText2">
              {t(i18Base + '.YouAreAssisting', { participantName: participantName, meetingName })}
            </Text>
          )}
          {!isMeetingModeDelegate && (
            <Text variant="footnoteMedium" color="mainText2">
              {t(i18Base + '.BookingFor', { name: meetingName })}
            </Text>
          )}
        </NotchNameWrapper>
        <NotchButton onClick={onExit}>{t(i18Base + '.Exit')}</NotchButton>
      </Container>
    </CSSTransition>
  )
}
