import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import type { ValueType } from '@etta/ui/drop-down-list'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { prepareFilters } from '../prepare-filters'

const i18nBase = 'CarRentalFilters.SectionTitles'

export function useSupplierFilter() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isCarRentalsNewFiltersEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()

  const {
    carRentalFilterActions,
    carRentalSearchStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const label = t(`${i18nBase}.Supplier`)

  const handleChangeSupplier = (values: ValueType[]) => {
    carRentalFilterActions.handlers.setVendor(
      values?.map((v: ValueType) => v?.toString() || '').filter(Boolean),
    )
    carRentalFilterActions.handleApplySuppliersFilters()
  }

  const vendor = prepareFilters(filterOptions.vendors, carFiltersStore.selectedFilters.vendors)

  const suppliersValue = useMemo(() => {
    const filterValues = []
    for (const filterOption of carRentalSearchStore.filterOptions.vendors) {
      if (carFiltersStore.appliedFilters.vendors.includes(filterOption.id)) {
        filterValues.push(filterOption.title)
      }
    }
    if (!filterValues.length) {
      return ''
    }
    return filterValues.join(', ')
  }, [carFiltersStore.appliedFilters.vendors, carRentalSearchStore.filterOptions.vendors])

  const isClearDisabled = useMemo(() => {
    return carFiltersStore.isEqual && !carFiltersStore.selectedFilters.vendors.length
  }, [carFiltersStore.isEqual, carFiltersStore.selectedFilters.vendors])

  return {
    isCarRentalsNewFiltersEnabled,
    label,
    handleChangeSupplier,
    vendor,
    carFiltersStore,
    carRentalFilterActions,
    carRentalSearchStore,
    suppliersValue,
    isClearDisabled,
  }
}
