import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { PreferenceBlockMobile } from './mobile'
import { PreferenceBlockDesktop } from './desktop'

export function PreferenceBlockLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<PreferenceBlockMobile {...props} />}
      desktopSlot={<PreferenceBlockDesktop {...props} />}
    />
  )
}
