import { useTranslation } from 'react-i18next'
import type { TooltipProps } from '@etta/components/unused-ticket-breakdown-tooltip/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import {
  BreakdownItem,
  BreakdownItemContainer,
  BreakdownItemName,
} from '@etta/components/unused-ticket-breakdown-tooltip/layout/desktop/unused-ticket-breakdown-tooltip-styled-desktop'
import { formatRate } from '@fiji/utils/money'
import type { RateValueObject } from '@etta/core/value-objects'
import { Swap } from '@etta/ui/swap'

const i18nBase = 'AirSearchResults.UnusedTicketTooltip'

type Item = {
  name: string
  cost?: RateValueObject | null
  isMinusPrefix?: boolean
}

export function UnusedTicketBreakdownContent({
  addCollect,
  penalty,
  forfeiture,
  unusedTicketPrice,
  newTicketPrice,
  isMobile,
  isUnusedTicketValidated: isValidated,
  unusedTicketRate,
  isPostBooking,
}: TooltipProps) {
  const { t } = useTranslation()

  function isValidAmount(rate?: RateValueObject | null) {
    return rate != null && rate.primary.amount !== 0
  }

  const isUnvalidated = !isValidated
  const isPreBooking = !isPostBooking

  const items: Item[] = [
    {
      name: t(`${i18nBase}.NewFareAmount`),
      cost: newTicketPrice,
    },
    {
      name: t(`${i18nBase}.ChangePenalty`),
      cost: penalty,
    },
    {
      name: t(`${i18nBase}.UnusedTicketPrice`),
      cost: unusedTicketPrice,
      isMinusPrefix: true,
    },
    {
      name: t(`${i18nBase}.FareForfeiture`),
      cost: forfeiture,
    },
  ].filter((item) => isValidAmount(item.cost))

  const { mainCost: unusedTicketCost } = formatRate(unusedTicketRate, {
    morpheme: 'prefix',
  })

  return (
    <>
      <Block>
        <Text variant="footnoteMedium" color="bodyText">
          <Swap
            is={!!unusedTicketRate && isUnvalidated && isPreBooking}
            isSlot={t('AirSearchResults.ViewUnusedTicketsTooltip', {
              unusedTicketCost: unusedTicketCost,
            })}>
            {t(`${i18nBase}.SearchResultDescription`)}
          </Swap>
        </Text>

        {((isValidated && isPreBooking) || (isValidAmount(penalty) && isPostBooking)) && (
          <>
            <Swap is={isMobile} isSlot={<Block marginBottom={16} />}>
              <Separator lineType="dashed" margin="12px 0px" color="bodyText" />
            </Swap>
            <BreakdownItemContainer>
              {items.map((item, index) => {
                return (
                  <BreakdownItem key={`${item.name}-${index}`} data-tracking-id={item.name}>
                    <BreakdownItemName>
                      <Text variant="footnoteMedium" color="bodyText">
                        {item.name}
                      </Text>
                    </BreakdownItemName>
                    <Text variant="footnoteStrong" color="bodyText">
                      {item.isMinusPrefix && '-'}
                      {
                        formatRate(item.cost, {
                          morpheme: 'postfix',
                        }).mainCost
                      }
                    </Text>
                  </BreakdownItem>
                )
              })}
            </BreakdownItemContainer>
            <Separator lineType="dashed" margin="12px 0px" color="bodyText" />
            <Block isFlexBlock isSpaceBetween alignItems="flex-start">
              <Text variant="footnoteMedium" color="bodyText">
                {t(`${i18nBase}.AddCollect`)}
              </Text>
              <Text variant="footnoteStrong" color="bodyText">
                {
                  formatRate(addCollect, {
                    morpheme: 'postfix',
                  }).mainCost
                }
              </Text>
            </Block>
          </>
        )}
      </Block>
    </>
  )
}
