import type { Result, Maybe } from 'fnscript'
import { Ok, Err, Error as NestedError, Nothing, Just } from 'fnscript'
import { Service } from '@etta/di'
import type {
  SubmitNewRailSearchMutationVariables,
  GeocodeInput,
  RailCard,
  SubmitNewRailSearchParamsRailCardInput,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import {
  SubmitNewSearchTimeRangeType,
  SubmitNewRailSearchParamsRailType,
  // eslint-disable-next-line import/no-restricted-paths
} from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import { TimeRangeDirection } from '@fiji/enums'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { dateToIso } from '@fiji/utils/dates'
import type { SubmitRailSearchInput } from '../../core/value-objects/submit-rail-search-input.value-object'
import { isDate, isCoordinate, isEmptyString } from './validators'

const mapTimeRangeDirection = {
  [TimeRangeDirection.Arrival]: SubmitNewSearchTimeRangeType.Arrival,
  [TimeRangeDirection.Departure]: SubmitNewSearchTimeRangeType.Departure,
}

const mapRailType = {
  [RailTripType.OneWay]: SubmitNewRailSearchParamsRailType.OneWay,
  [RailTripType.Round]: SubmitNewRailSearchParamsRailType.RoundTrip,
  [RailTripType.OpenReturn]: SubmitNewRailSearchParamsRailType.OpenReturn,
}

@Service()
export class RailMapService {
  toRailType(type: RailTripType): SubmitNewRailSearchParamsRailType {
    return mapRailType[type]
  }
  toRailCards(railCards?: RailCard[]): SubmitNewRailSearchParamsRailCardInput[] {
    if (!railCards) {
      return []
    }

    return railCards.map((card) => {
      return {
        name: card.name,
        code: card.code,
        groupName: card.groupName,
        vendorName: card.vendorName,
        vendorCode: card.vendorCode,
      }
    })
  }
  getRail(
    input: SubmitRailSearchInput,
  ): Result<
    SubmitNewRailSearchMutationVariables['input']['searchParams']['segments'],
    NestedError
  > {
    switch (input.railTripType) {
      case RailTripType.OneWay: {
        const railSegmentResult = this.toRailSegment(input)

        if (railSegmentResult.isErr()) {
          return Err(
            NestedError.new(
              `failed to validate submitNewRailSearch rail segment;${railSegmentResult
                .getError()
                .getMessage()}`,
            ),
          )
        }

        return Ok([railSegmentResult.getValue()])
      }

      case RailTripType.Round: {
        const roundTripRailsResult = this.toRoundTripSegments(input)

        if (roundTripRailsResult.isErr()) {
          return Err(
            NestedError.new(
              `failed to validate submitNewRailSearch round trip rail segments;${roundTripRailsResult
                .getError()
                .getMessage()}`,
            ),
          )
        }

        return Ok(roundTripRailsResult.getValue())
      }
      case RailTripType.OpenReturn: {
        const openReturnRailsResult = this.toRailSegment(input)

        if (openReturnRailsResult.isErr()) {
          return Err(
            NestedError.new(
              `failed to validate submitNewRailSearch open return trip rail segments;${openReturnRailsResult
                .getError()
                .getMessage()}`,
            ),
          )
        }

        return Ok([openReturnRailsResult.getValue()])
      }
    }
  }

  private toRoundTripSegments(
    params: SubmitRailSearchInput,
  ): Result<
    SubmitNewRailSearchMutationVariables['input']['searchParams']['segments'],
    NestedError
  > {
    const result: SubmitNewRailSearchMutationVariables['input']['searchParams']['segments'] = []
    const errors: NestedError[] = []

    const maybeDepartureRailSegmentResult = this.toRailSegment(params)
    if (maybeDepartureRailSegmentResult.isErr()) {
      const error = NestedError.new(
        `failed to validate origin rail segment;${maybeDepartureRailSegmentResult
          .getError()
          .getMessage()}`,
      )
      errors.push(error)
    }

    const returnRailSegmentArgs = {
      ...params,
      originDate: params.destinationDate!,
      originPlace: params.destinationPlace,
      originTime: params.destinationTime,
      destinationPlace: params.originPlace,
    }

    const maybeReturnRailSegmentResult = this.toRailSegment(returnRailSegmentArgs)

    if (maybeReturnRailSegmentResult.isErr()) {
      const error = NestedError.new(
        `failed to validate return rail segment;${maybeReturnRailSegmentResult
          .getError()
          .getMessage()}`,
      )
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Err(NestedError.new(`failed to validate trip rail segments`).wrap(validationError))
    }

    result.push(maybeDepartureRailSegmentResult.getValue())
    result.push(maybeReturnRailSegmentResult.getValue())
    return Ok(result)
  }

  private toRailSegment(
    params: SubmitRailSearchInput,
  ): Result<
    SubmitNewRailSearchMutationVariables['input']['searchParams']['segments'][0],
    NestedError
  > {
    const maybeRailValidationError = this.validateSubmitNewRailSearchRailSegmentArgs(params)
    if (maybeRailValidationError.isValue()) {
      return Err(
        NestedError.new(`failed to validate rail segment;${maybeRailValidationError.getValue()}`),
      )
    }

    return Ok({
      departureDate: dateToIso(params.originDate!),
      origin: {
        name: params.originPlace.name,
        locationCode: params.originPlace.uniqueCode,
        geocode: this.getGeocode(params.originPlace),
      },
      destination: {
        name: params.destinationPlace.name,
        locationCode: params.destinationPlace.uniqueCode,
        geocode: this.getGeocode(params.destinationPlace),
      },
      searchTimeRange: {
        type: mapTimeRangeDirection[params.originTime!.timeRangeBy!],
        start: params.originTime!.startTimeRange!,
        end: params.originTime!.endTimeRange!,
        customHours: params.originTime.customTimeHours,
      },
    })
  }

  private getGeocode(place?: Place): GeocodeInput | null {
    return place?.latitude && place?.longitude
      ? {
          lat: place.latitude,
          long: place.longitude,
        }
      : null
  }

  private validateSubmitNewRailSearchRailSegmentArgs(
    params: SubmitRailSearchInput,
  ): Maybe<NestedError> {
    const errors: NestedError[] = []

    if (!isDate(params.originDate)) {
      const error = NestedError.new('originDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (isEmptyString(params.originPlace.name)) {
      const error = NestedError.new('originPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.originPlace.uniqueCode)) {
      const locationErrors: NestedError[] = []

      if (!isCoordinate(params.originPlace!.latitude)) {
        const error = NestedError.new('originPlace.latitude is missing or it has a wrong value')
        locationErrors.push(error)
      }

      if (!isCoordinate(params.originPlace?.longitude)) {
        const error = NestedError.new('originPlace.longitude is missing or it has a wrong value')
        locationErrors.push(error)
      }

      if (locationErrors.length > 0) {
        const locationError = NestedError.new(locationErrors.map((e) => e.text()).join(';'))

        errors.push(
          NestedError.new(
            `missing one of required location data;originPlace.uniqueCode is missing or it has a wrong value;${locationError.getMessage()}`,
          ),
        )
      }
    }

    if (isEmptyString(params.destinationPlace?.name)) {
      const error = NestedError.new('destinationPlace.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.destinationPlace.uniqueCode)) {
      const locationErrors: NestedError[] = []

      if (!isCoordinate(params.destinationPlace!.latitude)) {
        const error = NestedError.new(
          'destinationPlace.latitude is missing or it has a wrong value',
        )
        locationErrors.push(error)
      }

      if (!isCoordinate(params.destinationPlace?.longitude)) {
        const error = NestedError.new(
          'destinationPlace.longitude is missing or it has a wrong value',
        )
        locationErrors.push(error)
      }

      if (locationErrors.length > 0) {
        const locationError = NestedError.new(locationErrors.map((e) => e.text()).join(';'))

        errors.push(
          NestedError.new(
            `missing one of required location data;destinationPlace.uniqueCode is missing or it has a wrong value;${locationError.getMessage()}`,
          ),
        )
      }
    }

    if (isEmptyString(params.originTime?.startTimeRange)) {
      const error = NestedError.new('originTime.startTimeRange is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.originTime?.endTimeRange)) {
      const error = NestedError.new('originTime.endTimeRange is missing or it has a wrong value')
      errors.push(error)
    }

    if (
      !Object.values(TimeRangeDirection).includes(
        params.originTime?.timeRangeBy as TimeRangeDirection,
      )
    ) {
      const error = NestedError.new('originTime.timeRangeBy is missing or it has a wrong value')
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(`failed to validate rail segment args;${validationError.getMessage()}`),
      )
    }

    return Nothing()
  }
}
