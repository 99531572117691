import type { CarLocationDetails } from '@fiji/graphql/types'
import { TripReviewDateType } from '@fiji/utils/dates'
import { getTime, separateLabels } from '../../utils'

const getLocation = (carLocation?: CarLocationDetails | null) => {
  if (!carLocation) {
    return ''
  }
  return `${carLocation.name}(${carLocation.code})`
}

export const getLocationLabel = (
  time: string,
  carLocation?: CarLocationDetails | null,
  separator?: string,
) => {
  return separateLabels(
    [getLocation(carLocation), getTime(time, TripReviewDateType.MonthAndDayAndTime)],
    separator!,
  )
}
