import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  UpdateDelegatedIdTokenDocument,
  UpdateDelegatedIdCookieDocument,
} from '@fiji/graphql/hooks'
import type {
  UpdateDelegatedIdTokenMutation,
  UpdateDelegatedIdTokenMutationVariables,
  UpdateDelegatedIdCookieMutation,
  UpdateDelegatedIdCookieMutationVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class DelegateDataProvider extends GraphqlDataProvider {
  updateDelegatedIdToken(input: UpdateDelegatedIdTokenMutationVariables['input']) {
    return this.client.mutate<
      UpdateDelegatedIdTokenMutation,
      UpdateDelegatedIdTokenMutationVariables
    >({
      mutation: UpdateDelegatedIdTokenDocument,
      variables: {
        input,
      },
    })
  }

  updateDelegatedIdCookie(input: UpdateDelegatedIdCookieMutationVariables['input']) {
    return this.client.mutate<
      UpdateDelegatedIdCookieMutation,
      UpdateDelegatedIdCookieMutationVariables
    >({
      mutation: UpdateDelegatedIdCookieDocument,
      variables: {
        input,
      },
    })
  }
}
