import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '@fiji/store'
import { mobilitySelector } from '@fiji/store/mobility'
import { appMode, ScreenType } from '@fiji/modes'
import { useScreenType } from '@fiji/modes/screen-mode/use-screen-type'
import {
  generateMobilityURLForIOS,
  MobilityIOSAction,
} from '@fiji/hooks/mobility/mobility-ios-scheme'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'

export function useMobilityViewClose() {
  const { isOpenInIOS } = appMode
  const history = useHistory()
  const mobility = useAppSelector(mobilitySelector)
  const {
    rideHailSearchItineraryActions,
    searchRideHailActions,
    rideHailAirportPickupActions,
  } = useRideHailSearchContext()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const handleClose = useCallback(() => {
    rideHailAirportPickupActions.dropStore()

    if (isDesktop) {
      searchRideHailActions.closeMobilitySearchDesktopModal()
      return
    }

    rideHailSearchItineraryActions.dropItineraryStore()
    if (isOpenInIOS) {
      document.location.href = generateMobilityURLForIOS(MobilityIOSAction.Close)
      return
    }

    if (mobility.callbackPath) {
      return history.replace({
        pathname: mobility.callbackPath,
        search: mobility.callbackParams ?? '',
      })
    }

    history.goBack()
  }, [
    rideHailAirportPickupActions,
    isDesktop,
    rideHailSearchItineraryActions,
    isOpenInIOS,
    mobility.callbackPath,
    mobility.callbackParams,
    history,
    searchRideHailActions,
  ])

  return {
    handleClose,
  }
}
