import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { getDate } from '../../utils'

type Args = {
  checkInDate?: Date | null
  checkOutDate?: Date | null
}

const i18Base = 'TripPlanner.BaseSearch'

export function useDateSectionMobile({ checkInDate, checkOutDate }: Args) {
  const { t } = useTranslation()

  const checkInDateLabel = t(i18Base + '.Input.CheckIn')
  const checkOutDateLabel = t(i18Base + '.Input.CheckOut')

  const checkIn = getDate(checkInDate)
  const checkOut = getDate(checkOutDate)

  const checkInAreaLabel = useMemo(() => {
    return checkIn
      ? t(i18Base + '.Input.DateSelectedAriaLabel', {
          label: checkInDateLabel,
          value: checkIn,
        })
      : t(i18Base + '.Input.TapToSelectDateAriaLabel', { type: checkInDateLabel })
  }, [t, checkIn, checkInDateLabel])

  const checkOutAreaLabel = useMemo(() => {
    return checkOut
      ? t(i18Base + '.Input.DateSelectedAriaLabel', {
          label: checkOutDateLabel,
          value: checkOut,
        })
      : t(i18Base + '.Input.TapToSelectDateAriaLabel', { type: checkOutDateLabel })
  }, [t, checkOut, checkOutDateLabel])

  return {
    checkIn,
    checkOut,
    checkInDateLabel,
    checkOutDateLabel,
    checkInAreaLabel,
    checkOutAreaLabel,
  }
}
