import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { HotelItinerarySegment } from '@etta/modules/ride-hail/core'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { Container, Detail, Title, DetailLine } from './mobility-itinerary-segment-styled'

type Prop = {
  segment: HotelItinerarySegment
}

const i18nBase = 'Mobility.ItineraryDialog'
const DATE_FORMAT = 'MMM d'

export function MobilityItineraryHotelSegment({ segment }: Prop) {
  const { t } = useTranslation()

  const checkInDate = segment.checkInDate
    ? getFormattedTime(new Date(deleteTimezone(String(segment.checkInDate))), DATE_FORMAT)
    : null

  const checkOutDate = segment.checkOutDate
    ? getFormattedTime(new Date(deleteTimezone(String(segment.checkOutDate))), DATE_FORMAT)
    : null

  return (
    <Container>
      <Icon name={'hotelPWA'} />
      <Detail>
        <Title variant="footnoteStrong" color="mainText" textOverflow="ellipsis">
          {t(`${i18nBase}.StayAt`)} {segment.name}
        </Title>
        {checkInDate && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.CheckIn`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {checkInDate}
            </Text>
          </DetailLine>
        )}
        {checkOutDate && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.CheckOut`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {checkOutDate}
            </Text>
          </DetailLine>
        )}
        <DetailLine>
          <Text variant="footnoteMedium" color="bodyText">
            {t(`${i18nBase}.Duration`)}:
          </Text>
          &nbsp;
          <Text variant="footnoteMedium" color="mainText">
            {t(`${i18nBase}.Nights`, { count: segment.duration })}
          </Text>
        </DetailLine>
      </Detail>
    </Container>
  )
}
