import { NavLink as NavLinkUnstyled } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { withStyledText } from '@fiji/style/text-styles'

export const PreviewContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`

export const TravelSupportHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
`

export const TravelSupportHeaderTitle = styled.div`
  ${withStyledText};
`

export const NavLink = styled(NavLinkUnstyled)`
  text-decoration: none;
`

export const NavItem = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.primary2 : theme.colors.primary)};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

export const NavText = styled.span`
  ${withStyledText}
`
