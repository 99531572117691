import styled from 'styled-components'
import {
  subHeadMedium,
  subHeadStrong,
  headline,
  title3,
  captionStrongCaps,
  footnoteMedium,
  footnoteStrong,
} from '@fiji/style'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
`

export const UpperBody = styled.div`
  padding: 10px 0px 24px 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`

export const DownArrow = styled(Icon)`
  transform: rotate(180deg);
`

export const EquivalencesContainer = styled.div`
  margin-top: 16px;
`

export const EmisisonDetails = styled.div`
  padding: 0 72px;
`

export const EmisisonDetailsHeader = styled.p`
  margin: 0;
  margin-top: 24px;
  ${headline};
`

export const EmisisonDetailsDescription = styled.p<{ withBorder?: boolean }>`
  color: ${(props) => props.theme.colors.bodyText};
  border-bottom: ${(props) => (props.withBorder ? '1px' : 0)} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 15px;
  ${footnoteMedium};
  margin: 0px;
  margin-top: 8px;
`

export const ClapContainer = styled.div`
  padding: 0 72px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`
export const ClapTitle = styled.p`
  margin-left: 10px;
  ${title3}
`

export const SustainabilityInformation = styled.div``
export const SustainabilityInformationTitle = styled.div`
  padding-top: 10px;
  text-transform: uppercase;
  ${captionStrongCaps};
`

export const SustainabilityRow = styled.div`
  padding-top: 20px;
`

export const SustainabilityDescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 5px;
`

export const SustainabilityBar = styled.div<{
  deviationLevel?: EmissionsDeviationLevel
  sustainabilityScore: number
}>`
  width: ${(props) => props.sustainabilityScore}%;
  border-radius: 4px;
  height: 4px;
  background-color: ${(props) => {
    switch (props.deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return props.theme.colors.ecoCheck2
      case EmissionsDeviationLevel.Less:
        return props.theme.colors.success
      case EmissionsDeviationLevel.More:
        return props.theme.colors.bodyText
      default:
        return props.theme.colors.ecoCheck2
    }
  }};
`

export const SustainabilityBarContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  height: 4px;
  background-color: ${(props) => props.theme.colors.borderDark};
`

export const SustainabilityScoreDescription = styled.div`
  ${subHeadMedium};
`

export const SustainabilityOutOfHundredScore = styled.div<{
  deviationLevel?: EmissionsDeviationLevel
}>`
  margin: 0;
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.mainText};
`

export const SustainabilityScore = styled.p`
  margin: 0;
  display: inline-block;
  ${subHeadStrong};
`

export const OutOfHundred = styled.p`
  margin: 0;
  display: inline-block;
  ${captionStrongCaps};
`

export const EcoCertificationContainer = styled.div`
  padding: 0 72px;
  margin-top: 13px;
`

export const EcoCertificationTitle = styled.p`
  margin: 0;
  ${title3};
`

export const EcoCertification = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  padding-top: 12px;
`

export const EcoCertificationName = styled.p`
  margin: 0;
  padding-left: 5px;
  display: inline-block;
  ${subHeadMedium};
`

export const DeemNote = styled.p`
  margin: 0;
  margin-top: 13px;
  ${subHeadMedium};
`

export const CardContainer = styled.div<{ withMarginRight: boolean }>`
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.mainText1};
  border-radius: 14px;
  margin-right: ${(props) => (props.withMarginRight ? '12px' : '0')};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100px;
  width: 50%;
`

export const LeftBlock = styled.div``
export const RightBlock = styled.div``

export const EmissionKg = styled.p`
  color: ${(props) => props.theme.colors.mainText2};
  margin: 0;
  ${headline};
`

export const DescriptionBlock = styled.p`
  margin: 0;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.mainText2};
  ${footnoteStrong};
`

export const LearnMoreLinkWrapper = styled.div`
  margin: 0;
  margin-top: 8px;
`
