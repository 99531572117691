/* eslint-disable react-hooks/exhaustive-deps */
import type { Moment } from 'moment'
import moment from 'moment'
import { useCallback } from 'react'

type Props = {
  minDate?: Date
  maxDate?: Date
}

// We don't allow user to select more than 331 day from now during searches.
const MAX_DAY_DIFFERENCE = 331
const DEFAULT_MAX_DATE = moment().add(MAX_DAY_DIFFERENCE, 'days')

export function useDayBlocked({ minDate, maxDate }: Props) {
  const minDateValue = minDate ? moment(minDate) : null
  const maxDateValue = maxDate ? moment(maxDate) : DEFAULT_MAX_DATE

  return useCallback(
    (day: Moment) => {
      const isMinOutOfRange = minDateValue && minDateValue.isAfter(day, 'day')
      if (isMinOutOfRange) {
        return true
      }

      const isMaxOutOfRange = maxDateValue && maxDateValue.isBefore(day, 'day')
      if (isMaxOutOfRange) {
        return true
      }

      return false
    },
    [minDate, maxDate],
  )
}
