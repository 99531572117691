import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style/typography'

export const IconWrapper = styled.div`
  border-radius: 4px;
  margin-right: 4px;
  background: ${(props) => props.theme.colors.bodyText2};
`

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 26px;
  background-color: ${(props) => props.theme.colors.bodyText};
`

export const Text = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.mainText2};
  margin: 0;
`
