import { useTranslation } from 'react-i18next'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ContentWrapper } from './app-error-boundary-fallback-styled'

const i18Base = 'AppErrorBoundary'

type Props = {
  onErrorClear: () => void
}

export function AppErrorBoundaryFallback({ onErrorClear }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog onClose={() => {}} isOpen>
      <ContentWrapper>
        <Text variant="title3" color="mainText">
          {t(i18Base + '.Title')}
        </Text>
        <Block marginTop={24}>
          <Text variant="footnoteMedium" color="bodyText" align="center">
            {t(i18Base + '.Message')}
          </Text>
        </Block>
        <Block isFullWidth marginTop={20}>
          <Button variant="outline" onClick={onErrorClear} fullWidth>
            {t(i18Base + '.ButtonLabel')}
          </Button>
        </Block>
      </ContentWrapper>
    </Dialog>
  )
}
