import type { AddButtonComponentProps } from '../../../types'
import { Container, Text, Icon } from './trip-review-add-button-desktop-styled'

export function TripReviewAddButtonDesktop({
  isDisabled,
  onClick,
  iconName,
  ariaLabel,
  label,
  iconColor,
}: AddButtonComponentProps) {
  return (
    <Container
      disabled={isDisabled}
      onClick={onClick}
      aria-label={ariaLabel}
      data-tracking-id={`trip-review-add-button_${label}`}>
      <Icon name={iconName} size="medium" color={iconColor} />
      <Text>{label}</Text>
    </Container>
  )
}
