import { Text } from '@etta/ui/text'
import type { LayoutProps } from './types'
import { useCancellationDate } from './use-cancellation-date'

export function CancellationDateDesktop({ date }: LayoutProps) {
  const { label } = useCancellationDate({ date })

  return (
    <Text variant="subHeadStrong" color="error">
      {label}
    </Text>
  )
}
