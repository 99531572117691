import type { UserPreset } from '../types'

export const stage2: UserPreset[] = [
  {
    site: 'fijiwebrtp',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluVXNlciI6ZmFsc2UsImN1c3RvbWVySWQiOjIzMzQyNDEsImxvY2FsZSI6ImVuLVVTIiwiaW1wZXJzb25hdGVkSWQiOjAsImRlbGVnYXRlZElkIjowLCJjb21wYW55SWQiOjUxMzU1NSwicGFydG5lcklkIjoyLCJ1c2VybmFtZSI6ImZpaml3ZWIiLCJjcmVhdGVkVGltZSI6MTYyNDM2NDMwNDM0MywiY2x1c3RlciI6ImNsdXN0ZXJhIiwic2Vzc2lvbklkIjoiM2U3YmM0Y2QtNDAzMS00NGY3LThjMDItYmM3ZTgzNjRmMWEyIiwic2Vzc2lvblRpbWVvdXQiOjE4MDAsImlhdCI6MTYyNDUzMzM4NywiZXhwIjoxOTQwMTA5Mzg3fQ.l2H0z_EjVjTsw7WAE6IuREo8vbo1pQIP8iUsZVRgLdk',
  },
  {
    site: 'fijiwebrtp',
    user: 'fijisitecard',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
  },
  {
    site: 'fijiwebmobi',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
  },
  {
    site: 'fijiweb2',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluVXNlciI6ZmFsc2UsImN1c3RvbWVySWQiOjIzMDQyMTEsImxvY2FsZSI6ImVuLVVTIiwiaW1wZXJzb25hdGVkSWQiOjAsImRlbGVnYXRlZElkIjowLCJjb21wYW55SWQiOjUwMDAxMCwicGFydG5lcklkIjoyLCJ1c2VybmFtZSI6ImZpaml3ZWJAZGVlbS5jb20iLCJjcmVhdGVkVGltZSI6MTYyNDI1ODA3ODQ1MCwiY2x1c3RlciI6ImNsdXN0ZXJhIiwic2Vzc2lvbklkIjoiNzU5MjU4MTAtMzM5OS00MmUxLTllZTAtNDgyNjFlNWQzMmI4Iiwic2Vzc2lvblRpbWVvdXQiOjE4MDAsImF1dGhUaWNrZXQiOiIzNDMwMzgzMTM2Mzc2MTMzMmQzMDM5NjEzOTJkMzQzMjY2NjMyZDYyMzU2MTM2MmQzNTYyMzIzMzM5MzQzNzM4MzMzNzY1MzAzYTdjM2EzMTJlMzAyMzM1MzAzMDMwMzEzMCIsImVudGl0bGVtZW50cyI6eyJjb29raWUiOiJDQVJTRVJWSUNFMiBDQVJTRVJWSUNFX1JFU1YgRkxJR0hUX1NFQVJDSCBUUkFWRUwiLCJlbnRpdGxlbWVudHMiOlsiQ0FSU0VSVklDRTIiLCJUUkFWRUwiLCJGTElHSFRfU0VBUkNIIiwiQ0FSU0VSVklDRV9SRVNWIl19LCJpYXQiOjE2MjQyNTgwODQsImV4cCI6MTkzOTgzNDA4NH0.DIlfZZYosyGx8IQXnBbS7ez5xuw-C3syxx0GIsWt7hA',
  },
  {
    site: 'fijiwebtest',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
  },
  {
    site: 'fijiwebapple',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
  },
  {
    site: 'fijiphx',
    user: 'fijiweb',
    rcSession:
      '14520-0FCBDC638A38AB1F06A2023E10E3CB10-7B56D0536A611C778FD67F69488C19357DEBCB1E0626D07C2F6435C43B831A77B74F4EC79F367E000665C71AAE5EE6C3D56DB09B66AD70A313AF8F3B2D0A22C2FD958A98F8E02DB864372374D864ADF37FE6D162239261A686DF7D63DEFD32F857380BE7E6F52DD8BD008648C7BA536E053D4BD94A30375DAF6303A4ED6971FA77662AD9EEC5F1BAA23D02864FC7ED460BB38B9A8673EBE7F46AD6BA472FCA92447FB204EBEE9E0232D1EAA1F3035504F61313E2325BDAD66752EAE85CA5C949',
  },
]
