import { useRef } from 'react'
import type { RouteItem } from '@fiji/routes'
import { useCurrentRoute } from '@etta/modules/app/ui/use-current-route'

export default function useTransitionRoute() {
  const lastRouteRef = useRef<RouteItem>()

  const { currentRouteItem } = useCurrentRoute()

  const isRouteChanged = currentRouteItem?.route.path !== lastRouteRef.current?.route.path
  if (isRouteChanged) {
    lastRouteRef.current = currentRouteItem
  }

  const lastRouteValue = lastRouteRef.current

  return {
    isRouteChanged,
    transitionIndex: lastRouteValue?.transitionIndex,
    transitionGroup: lastRouteValue?.transitionGroup,
  }
}
