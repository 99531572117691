import styled from 'styled-components'
import { Radio } from '@etta/ui/radio'
import { footnoteMedium, headline, title3, captionMedium } from '@fiji/style'

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText2};
  ${headline}
`

export const InfoTitle = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${title3};
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 14px 14px;
  overflow: hidden;
`

export const HelperText = styled.span`
  display: block;
  margin-top: 14px;
  color: ${(p) => p.theme.colors.bodyText};
  line-height: 19px;
  ${footnoteMedium}
`

export const TitleHelperText = styled(HelperText)`
  margin-top: 4px;
`

export const TextFieldGroup = styled.div`
  width: 100%;
  & > * + * {
    margin-top: 10px;
  }

  & > *:first-child {
    margin-top: 14px;
  }
`

export const Content = styled.div`
  padding: 38px 20px 18px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ContentDesktop = styled.div`
  padding: 24px;
`

export const SpinnerWrapper = styled.div

export const RadioContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const StyledRadio = styled(Radio)`
  padding: 18px 6px;
`

export const HelperContainer = styled.div`
  color: ${(props) => props.theme.colors.error};
  display: flex;
  align-items: center;
  ${captionMedium};
`
