import { Skeleton } from '@etta/ui/skeleton'
import { CarRentalMobileDivider } from '../car-rental-mobile-divider'
import { PriceList, PriceListItem, TotalCost } from './car-rental-mobile-price-styled'

export function CarRentalMobilePriceSkeleton() {
  return (
    <>
      <PriceList>
        <PriceListItem variant="primary">
          <Skeleton width="120" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
          <Skeleton width="55" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
        </PriceListItem>
        <PriceListItem variant="primary">
          <Skeleton width="120" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
          <Skeleton width="55" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
        </PriceListItem>
        <PriceListItem variant="primary">
          <Skeleton width="120" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
          <Skeleton width="55" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
        </PriceListItem>
        <PriceListItem variant="secondary">
          <Skeleton width="120" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
          <Skeleton width="55" height="19">
            <rect width="100%" height="16" />
          </Skeleton>
        </PriceListItem>
      </PriceList>

      <CarRentalMobileDivider marginSize="sm" />

      <TotalCost>
        <Skeleton width="90" height="22">
          <rect width="100%" height="18" />
        </Skeleton>
        <Skeleton width="100" height="22">
          <rect width="100%" height="18" />
        </Skeleton>
      </TotalCost>
    </>
  )
}
