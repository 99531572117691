import type { DailyRate, Money } from '@fiji/graphql/types'
import { RoomType } from '@fiji/graphql/types'
import {
  useRoomsAverage,
  useRoomsSubtotal,
  checkExistDailyRatesAverage,
} from '@fiji/utils/room-price'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

export function useRoomDetails(hotel: HotelPostBookingValueObject) {
  const { isOpen, handleOpen, handleClose } = useTogglePopup()

  const {
    averageRate,
    dailyRates,
    hotelId,
    name: hotelName,
    feesAndTaxes,
    id: roomId,
    checkIn,
    checkOut,
    roomType: room,
    totalCost,
    source,
  } = hotel
  const roomType = room || RoomType.Unknown
  const shouldShowAverage = checkExistDailyRatesAverage(dailyRates as DailyRate[])
  const primaryRate = averageRate?.secondary ?? averageRate?.primary
  const roomAverage = useRoomsAverage({
    shouldShowAverage,
    primary: primaryRate,
    dailyRates: dailyRates as DailyRate[],
  })
  const roomsSubtotal = useRoomsSubtotal(
    averageRate?.primary as Money,
    dailyRates as DailyRate[],
    shouldShowAverage,
  )

  const roomDetails = {
    searchId: null,
    roomAverage,
    roomsSubtotal,
    dailyRates: dailyRates || [],
    totalCost: totalCost?.amount ? totalCost : undefined,
    hotelId: hotelId || '',
    roomId: roomId || '',
    feesAndTaxes,
    hotelName: hotelName || '',
    roomType,
    checkIn: checkIn || '',
    checkOut: checkOut || '',
    source,
  }

  return {
    isOpen,
    roomDetails,
    handleOpen,
    handleClose,
  }
}
