import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  Container,
  TitleContainer,
  IconWrapper,
  SubTitleContainer,
} from '../../../blocks-desktop-styles'

export function BlockUnavailableDesktop({
  title,
  subTitle,
  buttonTitle,
  isLoading,
  onContinue,
}: LayoutProps) {
  return (
    <Container>
      <Block marginRight={32}>
        <TitleContainer>
          <IconWrapper>
            <SeatIcon icon="warning" />
          </IconWrapper>
          <Text variant="headline" color="mainText">
            {title}
          </Text>
        </TitleContainer>
        <SubTitleContainer>
          <Text color="bodyText" variant="footnoteMedium" align="right">
            {subTitle}
          </Text>
        </SubTitleContainer>
      </Block>
      <Button
        variant="outline"
        size="normal"
        minWidth={13.6}
        onClick={onContinue}
        disabled={isLoading}>
        {buttonTitle}
      </Button>
    </Container>
  )
}
