import * as yup from 'yup'
import i18n from 'i18next'
import type { Props } from '../types'
import { getBaseScheme } from './get-base-scheme'

const i18nBase = 'AdditionalInfoForm.'

export const getTextFieldScheme = ({ customField }: Props) => {
  let yupScheme = yup.string()

  if (customField.textDisplay?.minLength) {
    yupScheme = yupScheme.test(
      'min-characters',
      i18n.t(`${i18nBase}Errors.MinCharacters`, { count: customField.textDisplay.minLength }),
      (value) => {
        if (value) {
          return value.length >= customField.textDisplay!.minLength
        }

        return true
      },
    )
  }

  if (customField.textDisplay?.maxLength) {
    yupScheme = yupScheme.test(
      'max-characters',
      i18n.t(`${i18nBase}Errors.MaxCharacters`, {
        count: customField.textDisplay!.maxLength,
      }),
      (value) => {
        if (value) {
          return value.length <= customField.textDisplay!.maxLength
        }

        return true
      },
    )
  }

  return getBaseScheme({ scheme: yupScheme, customField })
}
