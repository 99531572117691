import { Action, Inject } from '@etta/di'
import type { AccessPointValueObject } from '../../core/value-objects/access-point.value-object'
import type { ZoneValueObject } from '../../core/value-objects/zone.value-object'
import { RideHailSearchLocationRefinementService } from '../services/ride-hail-search-location-refinement.service'
import { RideHailSearchLocationRefinementStore } from '../store/ride-hail-search-location-refinement.store'
import { SearchRideHailStore } from '../store/search-ride-hail.store'
import { RenderState } from '../types'

@Action()
export class RideHailSearchLocationRefinementActions {
  constructor(
    @Inject()
    private rideHailSearchLocationRefinementService: RideHailSearchLocationRefinementService,
    @Inject()
    private rideHailSearchLocationRefinementStore: RideHailSearchLocationRefinementStore,
    @Inject()
    private searchRideHailStore: SearchRideHailStore,
  ) {}

  async loadLocationRefinementOptions() {
    await this.rideHailSearchLocationRefinementService.loadLocationRefinementOptions()
  }

  async handleContinue(defaultNavigation: () => void) {
    await this.rideHailSearchLocationRefinementService.navigateToExpenseCodeSelection(
      defaultNavigation,
    )
  }

  goBack(defaultBack: () => void) {
    this.rideHailSearchLocationRefinementService.goBack(defaultBack)
  }

  handleCancelLocationRefinementRequest() {
    this.searchRideHailStore.setRenderState(RenderState.searchResult)
    this.rideHailSearchLocationRefinementService.cancelLocationRefinementRequest()
  }

  handleBack() {
    this.rideHailSearchLocationRefinementService.goBackFromLocationRefinement()
  }

  handleUpdateRenderType(renderType: 'ChildZone' | 'AccessPoint') {
    this.rideHailSearchLocationRefinementStore.setRenderType(renderType)
  }

  handleUpdateSelectedZone(zone: ZoneValueObject | null) {
    this.rideHailSearchLocationRefinementStore.setSelectedZone(zone)
  }

  handleUpdateSelectedAccessPoint(accessPoint: AccessPointValueObject | null) {
    this.rideHailSearchLocationRefinementStore.setSelectedAccessPoint(accessPoint)
  }

  dropLocationRefinementOptions() {
    this.rideHailSearchLocationRefinementStore.dropStore()
  }

  cancelRequests() {
    this.rideHailSearchLocationRefinementService.cancelRequests()
  }
}
