import 'reflect-metadata'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { RENDERING_START_PERFORMANCE_MARK } from '@fiji/constants'
import { MainModule } from '@etta/modules/main'
import './rollbar'
import './i18n'

performance.mark(RENDERING_START_PERFORMANCE_MARK)

const mainModule = MainModule.getInstance()

mainModule.start()
