import { isLocalhost } from '@fiji/utils/is-localhost'
import { DevEnvSelector } from './dev-env-selector'

export function DevSelector() {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }
  if (!isLocalhost) {
    return null
  }
  if (window._config.withDevEnvSelector === false) {
    return null
  }

  return <DevEnvSelector />
}
