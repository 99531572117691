import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  }
`

export const ViewStationsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 20px 20px 20px;
  margin: 0;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
