import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const SuggestionSectionWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.06);
  margin-top: 16px;
`

export const SuggestionSectionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  &:last-child {
    border: 0;
  }
  padding: 14px;
`

export const SuggestionTitle = styled(Text)`
  color: ${(props) => props.theme.colors.mainText};
`

export const SuggestionDescription = styled(Text)`
  color: ${(props) => props.theme.colors.bodyText};
`

export const SuggestionIConContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  margin-right: 8px;
`

export const TextWrapper = styled.div`
  overflow: hidden;
`
