import { QueryParams } from '@etta/interface/services/query-params'
import { Store, Inject } from '@etta/di'
import type { Place } from '@fiji/types'
import type { CarSearchQueryType } from '@fiji/hooks/search-queries/use-car-search-query/types'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { ROUTES } from '@fiji/routes'
import { getGeocode } from '@etta/modules/car-rental-search/interface/stores/get-geocode'
import { dateTimeToShort } from '@fiji/utils/dates/date-time-to-short'
import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import { getFilteredLocations } from './get-filtered-locations'

@Store()
export class CarRentalStore {
  carSearchQueryParams = new QueryParams<CarSearchQueryType>(
    {
      pickUpTime: { hours: 9, minutes: 0 },
      dropOffTime: { hours: 17, minutes: 0 },
      carTypes: [],
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: [
        'carTypes',
        'allowedVendors',
        'carClass',
        'carTransmission',
        'carVendor',
        'carFuel',
      ],
    },
  )

  pickUpLocations: CarRentalMultiVendorLocation[] = []

  pickUpMessages: DynamicSiteMessageValueObject[] = []

  dropOffLocations: CarRentalMultiVendorLocation[] = []

  dropOffMessages: DynamicSiteMessageValueObject[] = []

  isLoadingPickUp = false

  isLoadingDropOff = false

  isErrorPickUp = false

  isErrorDropOff = false

  isRestrictedCountryError = false

  restrictedErrorMessage: string | null = null

  filterLocationsValue = ''

  constructor(
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  setFilterLocationsValue(value: string) {
    this.filterLocationsValue = value
  }

  setPickUpMessages(messages: any[]) {
    this.pickUpMessages = messages
  }

  setPickupLocations(locations: any[]) {
    this.pickUpLocations = locations
  }

  setDropOffMessages(messages: any[]) {
    this.dropOffMessages = messages
  }

  setDropOffLocations(locations: any[]) {
    this.dropOffLocations = locations
  }

  get queryParams() {
    return this.carSearchQueryParams.getQueryParams(this.historyStore.search)
  }

  get isCarFiltersUsed() {
    const { carClass, carTransmission, carVendor } = this.queryParams

    return Boolean(carTransmission?.length || carClass?.length || carVendor?.length)
  }

  get pickUpSearchDateTime() {
    const { pickUpDate, pickUpTime } = this.queryParams
    return pickUpDate && pickUpTime ? dateTimeToShort(pickUpDate, pickUpTime) : ''
  }

  get pickUpLocationId() {
    const { pickUpPlace, pickUpLocationId } = this.queryParams

    return pickUpLocationId || pickUpPlace.placeId
  }

  get dropOffLocationId() {
    const { dropOffLocationId, dropOffPlace } = this.queryParams

    return dropOffLocationId || dropOffPlace.placeId
  }

  get dropOffSearchDateTime() {
    const { dropOffDate, dropOffTime } = this.queryParams
    return dropOffDate && dropOffTime ? dateTimeToShort(dropOffDate, dropOffTime) : ''
  }

  get searchDateTimeByPage() {
    if (this.isPickUpPage) {
      return this.pickUpSearchDateTime
    }
    return this.dropOffSearchDateTime
  }

  get pickUpGeocode() {
    const { pickUpPlace, dropOffPlace } = this.queryParams
    return getGeocode({ isPickUp: true, pickUpPlace, dropOffPlace })
  }

  get dropOffGeocode() {
    const { pickUpPlace, dropOffPlace } = this.queryParams
    return getGeocode({ isPickUp: false, pickUpPlace, dropOffPlace })
  }

  get geocode() {
    if (this.isPickUpPage) {
      return this.pickUpGeocode
    }
    if (this.isDropOffPage) {
      return this.dropOffGeocode
    }
    return this.pickUpGeocode
  }

  get isDropOffPlaceSameAsPickUpPlace() {
    const { pickUpPlace, dropOffPlace } = this.queryParams
    if (!pickUpPlace) {
      return false
    }
    const isSamePlaceId = pickUpPlace?.placeId === dropOffPlace?.placeId
    const isSameCoordinates = this.checkIsPickUpPlaceSameAsDropOffPlaceByCoordinates(
      pickUpPlace,
      dropOffPlace,
    )

    return isSamePlaceId || isSameCoordinates
  }

  private checkIsPickUpPlaceSameAsDropOffPlaceByCoordinates(
    pickUpPlace: Place,
    dropOffPlace: Place,
  ) {
    const { longitude: pickUpPlaceLongitude, latitude: pickUpPlaceLatitude } = pickUpPlace
    const { longitude: dropOffPlaceLongitude, latitude: dropOffPlaceLatitude } = dropOffPlace

    return (
      pickUpPlaceLatitude === dropOffPlaceLatitude && pickUpPlaceLongitude === dropOffPlaceLongitude
    )
  }

  get combinedLocations() {
    return [...this.pickUpLocations, ...this.dropOffLocations]
  }

  get filteredLocations() {
    return getFilteredLocations({
      locations: this.locations,
      searchValue: this.filterLocationsValue,
    })
  }

  get isConfirmationPage() {
    return this.historyStore.matchPathname(ROUTES.carRental.confirmation)
  }

  get isDropOffPage() {
    return this.historyStore.matchPathname(ROUTES.carRental.dropOff)
  }

  get isPickUpPage() {
    return this.historyStore.matchPathname(ROUTES.carRental.pickUp)
  }

  get isLocationsPage() {
    return this.isConfirmationPage || this.isDropOffPage || this.isPickUpPage
  }

  get isLocationsLoading() {
    return this.isLoadingPickUp || this.isLoadingDropOff
  }

  setIsLoadingPickUp(value: boolean) {
    this.isLoadingPickUp = value
  }

  setIsLoadingDropOff(value: boolean) {
    this.isLoadingDropOff = value
  }

  setIsErrorPickUp(value: boolean) {
    this.isErrorPickUp = value
  }

  setIsErrorDropOff(value: boolean) {
    this.isErrorDropOff = value
  }

  setIsRestrictedCountryError(value: boolean) {
    this.isRestrictedCountryError = value
  }

  setRestrictedErrorMessage(value: string | null) {
    this.restrictedErrorMessage = value
  }

  get locations() {
    if (this.isDropOffPage) {
      return this.dropOffLocations
    }
    if (this.isPickUpPage) {
      return this.pickUpLocations
    }
    return this.combinedLocations
  }

  get hasLocations() {
    return !!this.locations.length
  }

  get messages() {
    if (this.isDropOffPage) {
      return this.dropOffMessages
    }
    if (this.isPickUpPage) {
      return this.pickUpMessages
    }
    return []
  }

  get isError() {
    return this.isErrorPickUp || this.isErrorDropOff
  }

  dropStore() {
    this.pickUpLocations = []
    this.pickUpMessages = []
    this.dropOffLocations = []
    this.dropOffMessages = []
    this.isLoadingPickUp = false
    this.isLoadingDropOff = false
    this.isErrorPickUp = false
    this.isErrorDropOff = false
  }
}
