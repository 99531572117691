import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Container, MobileContainer, TextWrapper } from './traveler-name-badge-styled'

type Props = {
  name?: string
}

export function TravelerNameBadgeDesktop({ name }: Props) {
  return name ? (
    <Container>
      <Icon name="peopleNewFieldPWA" color="mainText" size="normal" />
      <Text variant="footnoteMedium" color="mainText">
        {name}
      </Text>
    </Container>
  ) : null
}

export function TravelerNameBadgeMobile({ name }: Props) {
  return name ? (
    <MobileContainer>
      <Icon name="peopleNewFieldPWA" size="small" color="mainText" />
      <TextWrapper>
        <Text
          aria-label={name}
          variant="footnoteMedium"
          color="mainText"
          noWrap
          textOverflow="ellipsis">
          {name}
        </Text>
      </TextWrapper>
    </MobileContainer>
  ) : null
}
