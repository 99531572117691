import type { OptionsType } from '../types'
import { parseParams } from './parse-params'
import { changeObjectTopLevelKeys } from './change-object-top-level-keys'

type Args<T> = {
  str: string
  initialData?: Partial<T>
  options?: OptionsType<T>
}

export function getParsedData<T>({ str, initialData, options }: Args<T>) {
  const parsedData = parseParams(str)
  const convertedData = changeObjectTopLevelKeys(parsedData, options?.caseStyle && 'camelCase')
  const result = (options?.arrayFields || []).reduce(
    (acc, field) => {
      if (acc[field] === undefined) {
        return acc
      }
      if (!Array.isArray(acc[field])) {
        // @ts-ignore
        acc[field] = [acc[field]]
      }
      return acc
    },
    {
      ...initialData,
      ...convertedData,
    } as T,
  )

  return result
}
