import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { Document360SupportArticleByIdInput } from '@fiji/graphql/hooks'
import { TravelSupportErrors } from '../core/errors'
import type { SupportArticleValueObject } from '../core/value-objects'
import { TravelSupportDataProvider } from './data-provider/travel-support.data-provider'
import type { TravelSupportAdapterResult } from './types'

@Adapter()
export class TravelSupportAdapter {
  constructor(
    @Inject()
    private travelSupportDataProvider: TravelSupportDataProvider,
  ) {}

  async getDocument360SupportArticleById({
    id,
  }: Document360SupportArticleByIdInput): TravelSupportAdapterResult<SupportArticleValueObject> {
    try {
      const {
        data,
        errors,
      } = await this.travelSupportDataProvider.getDocument360SupportArticleById({
        id,
      })
      if (errors) {
        return Result.Err(new TravelSupportErrors.GetDocument360SupportArticleByIdFail(errors))
      }

      return Result.Ok(data.document360SupportArticleById)
    } catch (e) {
      return Result.Err(new TravelSupportErrors.GetDocument360SupportArticleByIdFail(e))
    }
  }
}
