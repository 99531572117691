import { useTranslation } from 'react-i18next'
import type {
  TrainCostSummary,
  TrainPostBookingValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { formatRate } from '@fiji/utils/money'
import { Text } from '@etta/ui/text'
import { RailCard } from '@etta/screens/payment-summary/sections/rail/rail-card'
import {
  Container,
  CheckedIcon,
  DoubleCurrency,
  Unit,
  Separator,
} from '../segment-section/segment-section-styled'
import { RailCostLabelAndName } from './rail-cost-label-and-name'

type Props = {
  segments: TrainPostBookingValueObject[]
  appliedRailCard: string
  costSummary: TrainCostSummary
}

const i18nBase = 'PaymentSummary.'

export function RailSegments({ segments, appliedRailCard, costSummary }: Props) {
  const { t } = useTranslation()

  if (!segments?.length) {
    return null
  }

  const taxes = formatRate(costSummary?.fare?.tax, {
    morpheme: 'none',
  }).mainCost

  const mainTotal = formatRate(costSummary?.fare?.total, {
    morpheme: 'postfix',
  }).mainCost

  const secondaryTotal = formatRate(costSummary?.fare?.total, {
    morpheme: 'postfix',
  }).secondCost

  const serviceFee = formatRate(costSummary?.fare?.serviceFee, {
    morpheme: 'none',
  }).mainCost

  const transactionFee = formatRate(costSummary?.fare?.transactionFee, {
    morpheme: 'none',
  }).mainCost

  const paymentFee = formatRate(costSummary?.fare?.paymentFee, {
    morpheme: 'none',
  }).mainCost

  return (
    <Container>
      <Text variant="footnoteStrong">{t(i18nBase + 'Train')}</Text>
      {segments.map((segment, index) => {
        if (!segment.totalCost) {
          return null
        }

        return (
          <RailCostLabelAndName key={index} railFragment={segment} isReturnSegment={index === 1} />
        )
      })}
      <Unit>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'BookingFees')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {transactionFee}
        </Text>
      </Unit>
      <Unit>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'DeliveryFee')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {serviceFee}
        </Text>
      </Unit>
      <Unit>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'PaymentFee')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {paymentFee}
        </Text>
      </Unit>
      <Unit>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'TaxesAndFees')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {taxes}
        </Text>
      </Unit>
      {appliedRailCard && <RailCard railCardName={appliedRailCard} />}
      <Separator />
      <Unit>
        <Text variant="subHeadStrong">
          {t(i18nBase + 'Paid')}
          <CheckedIcon name="checkFilledPWA" size="small" color="success" />
        </Text>
        <DoubleCurrency>
          <Text variant="subHeadStrong">{mainTotal}</Text>
          <Text variant="footnoteMedium" color="bodyText">
            {secondaryTotal}
          </Text>
        </DoubleCurrency>
      </Unit>
    </Container>
  )
}
