import styled, { css } from 'styled-components'
import { Text as SwitchLabel, Label as SwitchContainer } from '@etta/ui/switch/switch-components'
import { subHeadStrong, subHeadMedium } from '@fiji/style'

const SectionWithBorder = css`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Section = styled.div<{ withBorder?: boolean }>`
  padding: 24px 16px;
  ${({ withBorder }) => withBorder && SectionWithBorder}
`
export const SectionTitle = styled.h2`
  margin-top: 0;
  ${subHeadStrong}
  margin-bottom:12px;
`

export const ButtonContainer = styled.div`
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const SwitchSection = styled(Section)`
  ${SwitchContainer} {
    justify-content: space-between;
    width: 100%;
  }

  ${SwitchLabel} {
    max-width: 73%;
    ${subHeadMedium};
    color: ${(props) => props.theme.colors.mainText};
  }
`
