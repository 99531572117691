import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '@etta/ui/error-container'
import { Swap } from '@etta/ui/swap'
import { RailDeliveryOptionCheckoutWrapper } from '@etta/components/rail-delivery-option/rail-delivery-option-checkout-wrapper'
import { ShareTravelerContact } from '@etta/screens/traveler-info/share-traveler-contact/share-traveler-contact'
import { ProfileBox } from '../../../profile-box'
import { PreferenceBox } from '../../../preference-box'
import { ContentSkeleton } from '../../../skeletons'
import { PassportBox } from '../../../passport-box'
import type { Props } from '../../types'
import { Content, SubTitle } from './traveler-info-content-mobile-styled'

const i18nBase = 'TravelerInfo.'

export function TravelerInfoContentMobile({
  isError,
  onReload,
  isLoading,
  profileErrorMessage,
  displayedPersonalInfo,
  primaryTraveler,
  primaryTravelerOptions,
  isProfileValid,
  isPassportRequired,
  profileModalOnOpen,
  passports,
  newPassportToggle,
  updateEditPassport,
  isPassportSelected,
  segmentsToRender,
  segmentsState,
  hotelPreferences,
  hotelPreferencesOnEdit,
  membershipState,
  availableToSelectHotel,
  carPreferences,
  carPreferencesOnEdit,
  availableToSelectCar,
  availableToSelectFlight,
  flightPreferences,
  flightPreferencesOnEdit,
  railPreferences,
  availableToSelectRail,
  railPreferencesOnEdit,
  onPassportError,
  isRailEticket,
  isStationTicketMachineDisabled,
  departureStationName,
  showShareTravelerContact,
}: Props) {
  const { t } = useTranslation()

  return (
    <Content>
      <SubTitle>{t(i18nBase + 'Title')}</SubTitle>
      <Swap
        is={isError}
        isSlot={<ErrorContainer onReload={onReload} title={t(i18nBase + 'UnableToLoad')} />}>
        <Swap is={isLoading} isSlot={<ContentSkeleton />}>
          <ProfileBox
            errorMessage={profileErrorMessage}
            personalInfo={displayedPersonalInfo}
            primaryTraveler={primaryTraveler}
            travelerOptions={primaryTravelerOptions}
            isValid={isProfileValid}
            onLinkClick={profileModalOnOpen}
          />
          <PassportBox
            passports={passports}
            onAddNewPassport={newPassportToggle.handleOpen}
            onOpenExistingPassport={updateEditPassport}
            isPassportSelected={isPassportSelected}
            onError={onPassportError}
            isPassportRequired={isPassportRequired}
          />
          {segmentsToRender.isAir && (
            <PreferenceBox
              type="flight"
              segmentState={segmentsState.flightState}
              preferences={flightPreferences}
              onEdit={flightPreferencesOnEdit}
              availableToSelect={availableToSelectFlight}
            />
          )}
          {segmentsToRender.isHotel && (
            <PreferenceBox
              type="hotel"
              segmentState={segmentsState.hotelState}
              preferences={hotelPreferences}
              onEdit={hotelPreferencesOnEdit}
              disabledMemberships={membershipState.hotelMembershipsDisabled}
              availableToSelect={availableToSelectHotel}
            />
          )}
          {segmentsToRender.isCar && (
            <PreferenceBox
              type="car"
              segmentState={segmentsState.carRentalState}
              preferences={carPreferences}
              onEdit={carPreferencesOnEdit}
              availableToSelect={availableToSelectCar}
            />
          )}
          {segmentsToRender.isRail && (
            <PreferenceBox
              type="rail"
              segmentState={segmentsState.railState}
              preferences={railPreferences}
              onEdit={railPreferencesOnEdit}
              availableToSelect={availableToSelectRail}
            />
          )}
          {segmentsToRender.isRail && (
            <RailDeliveryOptionCheckoutWrapper
              stationName={departureStationName}
              isStationTicketMachineDisabled={isStationTicketMachineDisabled}
              isEticket={isRailEticket}
            />
          )}
        </Swap>
      </Swap>
      {showShareTravelerContact && <ShareTravelerContact />}
    </Content>
  )
}
