import { Action, Inject } from '@etta/di'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import { TravelPreferencesService } from '@etta/modules/settings/interface/services/travel-preferences.service'
import { TravelPreferencesAdditionalInformationStore } from '../stores/travel-preferences-additional-information.store'

@Action()
export class TravelPreferencesAdditionalInformationActions {
  constructor(
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    private readonly travelPreferencesService: TravelPreferencesService,
    @Inject()
    private readonly travelPreferencesAdditionalInformationStore: TravelPreferencesAdditionalInformationStore,
  ) {}

  handleEditModalOpening() {
    this.travelPreferencesAdditionalInformationStore.setIsSaveButtonDisabled(true)

    const additionalInformation =
      this.userProfileStore.userProfile?.travelPreferences.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.travelPreferencesAdditionalInformationStore.setAdditionalInfoValidator(validator)

    this.travelPreferencesAdditionalInformationStore.toggle.handleOpen()
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.travelPreferencesAdditionalInformationStore.setIsSaveButtonDisabled(false)
      return this.travelPreferencesAdditionalInformationStore.setAdditionalInfoValue(key, value)
    }
  }

  async handleAdditionalInformationSave() {
    const additionalValidatorCheck = await this.travelPreferencesAdditionalInformationStore.additionalInformationValidator.submit()
    if (!additionalValidatorCheck.isValid) {
      return
    }

    await this.travelPreferencesService.saveAdditionalInformation(
      this.travelPreferencesAdditionalInformationStore.additionalInformationValidator.values,
      () => {
        this.travelPreferencesAdditionalInformationStore.toggle.handleClose()
      },
    )
  }
}
