import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { ValueType } from '@etta/ui/drop-down-list'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { prepareFilters } from '../prepare-filters'

const i18nBase = 'CarRentalFilters.SectionTitles'

export function useFuelFilter() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isCarRentalsNewFiltersEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()
  const label = t(`${i18nBase}.Fuel`)
  const {
    carRentalFilterActions,
    carRentalSearchStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const handleChangeFuelType = (values: ValueType[]) => {
    carRentalFilterActions.handlers.setFuelType(
      values?.map((v: ValueType) => v?.toString() || '').filter(Boolean),
    )
    carRentalFilterActions.handleApplyFuelFilters()
  }

  const fuelType = prepareFilters(filterOptions.fuelTypes, carFiltersStore.selectedFilters.fuelType)

  const fuelTypeValue = useMemo(() => {
    const filterValues = []
    for (const filterOption of carRentalSearchStore.filterOptions.fuelTypes) {
      if (carFiltersStore.appliedFilters.fuelType.includes(filterOption.id)) {
        filterValues.push(filterOption.title)
      }
    }
    if (!filterValues.length) {
      return ''
    }
    return filterValues.join(', ')
  }, [carFiltersStore.appliedFilters.fuelType, carRentalSearchStore.filterOptions.fuelTypes])

  const isClearDisabled = useMemo(() => {
    return carFiltersStore.isEqual && !carFiltersStore.selectedFilters.fuelType.length
  }, [carFiltersStore.isEqual, carFiltersStore.selectedFilters.fuelType])

  return {
    isCarRentalsNewFiltersEnabled,
    label,
    handleChangeFuelType,
    fuelType,
    carFiltersStore,
    carRentalFilterActions,
    fuelTypeValue,
    carRentalSearchStore,
    isClearDisabled,
  }
}
