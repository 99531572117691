import { Service, Inject } from '@etta/di'
import { AddFlightSegmentService } from '@etta/modules/review-trip/interface/services/add-flight-segment.service'
import { AddHotelSegmentService } from '@etta/modules/review-trip/interface/services/add-hotel-segment.service'
import { AddCarSegmentService } from '@etta/modules/review-trip/interface/services/add-car-segment.service'
import { AddSegmentModalsStore } from '@etta/modules/review-trip/interface/stores/add-segment-modals.store'

@Service()
export class AddSegmentModalsActions {
  constructor(
    @Inject()
    private readonly addFlightSegmentService: AddFlightSegmentService,
    @Inject()
    private readonly addHotelSegmentService: AddHotelSegmentService,
    @Inject()
    private readonly addCarSegmentService: AddCarSegmentService,
    @Inject()
    private readonly addSegmentModalsStore: AddSegmentModalsStore,
  ) {}

  addFlight() {
    this.addFlightSegmentService.handleAddFlight()
  }

  closeAirSearchModal() {
    this.addSegmentModalsStore.closeAirSearchModal()
  }

  addHotel() {
    this.addHotelSegmentService.prefillDataIfExist()
  }

  addCar() {
    this.addCarSegmentService.handleAddCar()
  }

  closeCarSearchModal() {
    this.addSegmentModalsStore.closeCarSearchModal()
  }

  closeHotelSearchModal() {
    this.addSegmentModalsStore.closeHotelSearchModal()
  }
}
