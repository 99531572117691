export type CustomUuidArgs = {
  prefix?: CustomFieldPrefix
  name: string
  itemIndex: number
  parentIndex: number
}

export enum CustomFieldPrefix {
  Timezone = 'timezone',
  HourMinute = 'hourMinute',
  DayYearMonth = 'dayYearMonth',
  None = '',
}
