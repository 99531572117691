import type { SliderValues } from './types'

export const checkTrackFilled = (index: number, isFilled: boolean, isValueArray: boolean) => {
  if (!isFilled) {
    return false
  }

  if (!isValueArray) {
    // only for NOT array value, should fill only first track
    return index === 0
  }

  // for array value, should fill track between values
  return index === 1
}

export const checkMarkFilled = (markValue: number, value: SliderValues, isFilled: boolean) => {
  if (!isFilled) {
    return false
  }

  const isValueArray = Array.isArray(value)

  if (isValueArray) {
    const arrayValue = value as Array<number>
    return arrayValue[0] <= markValue && markValue <= arrayValue[1]
  }

  return markValue <= value
}
