import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CalendarTimeSectionMobile } from './layout/mobile/calendar-time-section-mobile'
import { CalendarTimeSectionDesktop } from './layout/desktop/calendar-time-section-desktop'

export function CalendarTimeSection(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CalendarTimeSectionMobile {...props} />}
      desktopSlot={<CalendarTimeSectionDesktop {...props} />}
    />
  )
}
