import { Action, Inject } from '@etta/di'
import { BaseSettingsService } from '../services/base.service'

@Action()
export class ProfileActions {
  constructor(
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
  ) {}

  getProfile() {
    return this.baseSettingsService.getStructuredProfile()
  }

  getProfileOptions() {
    const profile = this.baseSettingsService.getProfileFromUser()
    return {
      genders: profile?.genderOptions,
      suffixes: profile?.suffixOptions,
      titles: profile?.titleOptions,
    }
  }
}
