import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { TextFieldValue } from '@etta/ui/text-field/types'
import { FieldDescription } from '../field-description'
import {
  Container,
  Title,
  Info,
  TextArea,
  Message,
  Error,
  Placeholder,
  AreaContainer,
} from './textarea-description-styled'

type Props = {
  title?: string
  value: TextFieldValue
  subtitle?: string
  placeholder?: string
  maxChar?: number
  error?: string
  ariaLabel?: string
  onChange: (e: string | null) => void
  withFloatPlaceholder?: boolean
  withCharRemain?: boolean
  description?: string | null
}

export function TextAreaDescription({
  title,
  subtitle,
  value,
  placeholder,
  maxChar,
  error,
  ariaLabel,
  onChange,
  withFloatPlaceholder = false,
  withCharRemain = true,
  description,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'TextAreaDescription.'
  const [text, setText] = useState<string>(String(value))
  const placeholderText = placeholder || t(i18nBase + 'Default')

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Info>{subtitle}</Info>}
      {description && <FieldDescription description={description} />}
      <AreaContainer doesValueExist={!!value} isError={!!error}>
        {withFloatPlaceholder && <Placeholder>{placeholderText}</Placeholder>}
        <TextArea
          aria-label={ariaLabel}
          maxLength={maxChar}
          withFloatPlaceholder={withFloatPlaceholder}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            const input = e.currentTarget.value
            setText(input)
            onChange(input)
          }}
          placeholder={!withFloatPlaceholder ? placeholderText : undefined}
          value={value}
        />
      </AreaContainer>
      {(withCharRemain || !!error) && (
        <Message isOpen={!!(maxChar || error)} isError={!!error}>
          {maxChar && !error
            ? t(i18nBase + 'Remaining', { max: maxChar, number: text?.length || 0 })
            : ''}
          {error ? <Error>{error}</Error> : ''}
        </Message>
      )}
    </Container>
  )
}
