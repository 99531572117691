import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 36px 32px 13px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 24px;
  > div:nth-child(3n) {
    width: 30%;
  }
`

export const AmenityUnitContainer = styled.div`
  margin-top: 8px;
  margin-left: -2px;
  display: flex;
  width: 34.5%;
  padding-right: 6px;
  > :first-child {
    margin-right: 7px;
    transform: scale(0.9);
  }
`
