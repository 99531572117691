import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  flex-grow: 1;
`

export const Content = styled.div`
  padding: 24px 36px;
`

export const ContentHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 17px;
`

export const Badge = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 4px 8px;
  border-radius: 6px;
`

export const Tickets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
