import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import { TripListSkeletonDesktop } from './desktop'
import { TripListSkeletonMobile } from './mobile'

export function TripListSkeletonLayout() {
  const { featureFlags } = useFeatureFlags()
  return (
    <MediaLayout
      mobileSlot={<TripListSkeletonMobile />}
      desktopSlot={<TripListSkeletonDesktop />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
