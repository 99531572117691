import i18n from 'i18next'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { TimeRange } from '@fiji/types'
import { TimeRangeOption } from '@fiji/enums'

export const convertTimeRange = (time: TimeRange) => {
  const label = i18n.t(time.i18next)
  if (time.id === TimeRangeOption.CustomTime) {
    const customTime = getFormattedTime(
      {
        hours: time.customTimeHours,
      },
      'h:mm aaa',
    )
    return customTime
  }

  if (time.id === TimeRangeOption.AnyTime) {
    return label
  }

  const startTime = getFormattedTime(
    {
      hours: time.startTimeHours!,
    },
    'h aaa',
  )
  const endTime = getFormattedTime(
    {
      hours: time.endTimeHours!,
    },
    'h aaa',
  )

  return `${startTime} - ${endTime}`
}
