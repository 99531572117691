import { useMemo } from 'react'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { templates } from '@fiji/hooks/use-share-via-email'
import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { SegmentType } from '@etta/core/enums'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'

export type UseShareProps = {
  tripName: string
  segments: SegmentPostBookingValueObject[]
  startDate: string
  endDate: string
}

const MAX_FUTURE_DATE = 8_640_000_000_000_000

export function useShare({ segments, tripName, startDate, endDate }: UseShareProps) {
  const body = useMemo(() => getBody(segments), [segments])
  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({ tripName, startDate, endDate }),
    body,
  })
  return { handleShare }
}

function getBody(segments: SegmentPostBookingValueObject[]) {
  const flight = segments.filter(({ type }) => type === SegmentType.Flight)
  const hotel = segments.filter(({ type }) => type === SegmentType.Hotel)
  const carRental = segments.filter(({ type }) => type === SegmentType.CarRental)

  return [...flight, ...hotel, ...carRental]
    .sort((a, b) => {
      const aDate = getSegmentDate(a)
      const bDate = getSegmentDate(b)
      return aDate.getTime() - bDate.getTime()
    })
    .filter((item): item is SegmentPostBookingValueObject => Boolean(item))
    .map((segment) => {
      if (segment.type === SegmentType.Flight) {
        return templates.postBookingFlight({ segments: segment.segments })
      }
      if (segment.type === SegmentType.CarRental) {
        return templates.postBookingCarRental({ carRental: segment })
      }
      if (segment.type === SegmentType.Hotel) {
        return templates.postBookingHotel({ hotel: segment })
      }
    })
    .join(`\n\n${templates.SEPARATOR}\n\n`)
    .concat('\n\n')
}

function getSegmentDate(segment: SegmentPostBookingValueObject): Date {
  let segmentDate
  if (segment.type === SegmentType.Flight) {
    segmentDate =
      segment.departureDateTime && new Date(getDateWithoutTimezoneOffset(segment.departureDateTime))
  }
  if (segment.type === SegmentType.CarRental) {
    segmentDate = segment.pickUpTime && new Date(getDateWithoutTimezoneOffset(segment.pickUpTime))
  }
  if (segment.type === SegmentType.Hotel) {
    segmentDate =
      segment.checkIn &&
      new Date(new Date(getDateWithoutTimezoneOffset(segment.checkIn)).setHours(23, 59, 59))
  }
  return segmentDate || new Date(MAX_FUTURE_DATE)
}
