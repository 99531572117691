import type { KeyboardEvent, MutableRefObject } from 'react'
import { TextField } from '@etta/ui/text-field'
import { useDescendantsWrapper } from '@etta/components/descendant'
import type { TextFieldSize } from '@etta/ui/text-field/types'

type Props = {
  inputRef: MutableRefObject<HTMLInputElement>
  size?: TextFieldSize
  inputValue: string
  onChangeInput: (val: string) => void
  onClear?: () => void
  placeholder?: string
  isLoading?: boolean
}

export function TextInput({
  inputRef,
  size,
  onChangeInput,
  inputValue,
  onClear,
  isLoading,
  placeholder,
}: Props) {
  const { shouldFocusFirstElement, handleFocusFirstListElement } = useDescendantsWrapper()

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' && !shouldFocusFirstElement) {
      handleFocusFirstListElement()
    }
  }

  return (
    <TextField
      inputRef={inputRef}
      size={size}
      value={inputValue}
      onChange={onChangeInput}
      onKeyDown={handleKeyDown}
      onClear={onClear}
      placeholder={placeholder}
      type="search"
      skin="default"
      isLoading={isLoading}
    />
  )
}
