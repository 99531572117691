import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { HotelDetailsModalMobile } from './mobile'
import { HotelDetailsModalDesktop } from './desktop'

export function HotelDetailsModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<HotelDetailsModalMobile {...props} />}
      desktopSlot={<HotelDetailsModalDesktop {...props} />}
    />
  )
}
