import { useTranslation } from 'react-i18next'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import type {
  CalculateEmissionsBatchResultEquivalence,
  CalculateEmissionsResultHotelCertification,
} from '@fiji/graphql/types'
import {
  EquivalencesContainer,
  EmisisonDetails,
  EmisisonDetailsHeader,
  EmisisonDetailsDescription,
  EcoCertification,
  EcoCertificationTitle,
  EcoCertificationContainer,
  EcoCertificationName,
  DeemNote,
  LearnMoreLinkWrapper,
} from '../hotel-details-eco-check-modal-desktop-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
  hotelCertifications: CalculateEmissionsResultHotelCertification[]
  equivalences: CalculateEmissionsBatchResultEquivalence[]
  kgOfEmissions: number
}
export function HotelDetailsEcoCheckModalDesktopLowerBody({
  kgOfEmissions,
  hotelCertifications,
  equivalences,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  return (
    <>
      {hotelCertifications.length > 0 && (
        <EcoCertificationContainer>
          <EcoCertificationTitle aria-label={t(i18nBase + '.EcoCertification')}>
            {t(i18nBase + '.EcoCertification')}
          </EcoCertificationTitle>
          <DeemNote>{t(i18nBase + '.DeemNote')}</DeemNote>
          {hotelCertifications.map((certification, index) => {
            return (
              <EcoCertification key={index} aria-label={certification.name}>
                <Icon name="newTickSharpPWA" color="ecoCheck" size={20} />
                <EcoCertificationName>{certification.name}</EcoCertificationName>
              </EcoCertification>
            )
          })}
        </EcoCertificationContainer>
      )}
      <EquivalencesContainer>
        <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={kgOfEmissions} />
      </EquivalencesContainer>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.WhatSustainabilityScoreMeanQuestion')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.WhatSustainabilityScoreMeanAnswer')}
        </EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.HowDoWeEstimateHotelQuestion')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.HowDoWeEstimateHotelAnswer')}
          <LearnMoreLinkWrapper>
            <Link
              href="https://www.thrustcarbon.com/policies/methodology"
              target="_blank"
              size="small">
              {t('Emissions.DetailsModal.LearnMore')}
            </Link>
          </LearnMoreLinkWrapper>
        </EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHeader')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHotelDescription')}
        </EmisisonDetailsDescription>
      </EmisisonDetails>
    </>
  )
}
