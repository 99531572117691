import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { InputButtonMobile } from './mobile'
import { InputButtonDesktop } from './desktop'

export function InputButton(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<InputButtonMobile {...props} />}
      desktopSlot={<InputButtonDesktop {...props} />}
    />
  )
}
