import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import type { TripTabsProps } from './types'

const i18nBase = 'Trips'

export function useTripTabs({ onChange, activeTab }: TripTabsProps) {
  const { t } = useTranslation()
  const {
    featureFlags: { isDesktopHeldTripsFlowEnabled },
  } = useFeatureFlags()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isHoldTripAllowed } = displayConfigurationStore

  const tabs = useMemo(() => {
    return [
      { value: TripType.Upcoming, title: t(i18nBase + '.Upcoming') },
      { value: TripType.Past, title: t(i18nBase + '.Past') },
      { value: TripType.Cancelled, title: t(i18nBase + '.Canceled') },
      {
        value: TripType.OnHold,
        title: t(i18nBase + '.Hold'),
        isHiddenMobile: true,
        isHiddenDesktop: !isDesktopHeldTripsFlowEnabled || !isHoldTripAllowed,
      },
    ]
  }, [isDesktopHeldTripsFlowEnabled, isHoldTripAllowed, t])

  const [usedTab, setActiveTab] = useState(activeTab || TripType.Upcoming)
  const handleChange = useCallback(
    (nextTab) => {
      setActiveTab(nextTab)
      onChange(nextTab)
    },
    [onChange],
  )

  return {
    usedTab,
    handleChange,
    tabs,
  }
}
