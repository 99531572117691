import type { ReactNode } from 'react'
import { useRef, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useEscPressed } from '@fiji/hooks/use-esc-pressed'
import { useFocusLock } from '@fiji/hooks/use-focus-lock'
import type { Option, MenuPosition } from './types'
import { TRANSITION_NAME, TRANSITION_TIMEOUT } from './constants'
import { useVisibility, useGetPosition } from './hooks'
import { MenuOption } from './menu-option'
import { Container, MenuWrapper, Title, TriggerElementWrapper } from './menu-components'

type Props<T> = {
  openByClickOnEl: ReactNode
  shouldCloseOnClickOutside?: boolean
  title?: string
  options: Option<T>[]
  activeOptionId?: T
  activeOptionIcon?: ReactNode
  onOptionSelect: (optionId: T) => void
  position?: MenuPosition
  tabIndex?: number
  'data-tracking-id'?: string
  fullWidth?: boolean
}

export function Menu<T>({
  openByClickOnEl,
  shouldCloseOnClickOutside,
  title,
  options,
  activeOptionId,
  activeOptionIcon,
  onOptionSelect,
  position,
  tabIndex = 0,
  fullWidth,
  'data-tracking-id': dataTrackingId,
}: Props<T>) {
  const triggerElementWrapper = useRef<HTMLButtonElement | null>(null)
  const { isVisible, toggleVisibility, hide } = useVisibility()
  useEscPressed(hide)
  const { position: positionCalculated } = useGetPosition(triggerElementWrapper)
  const { focusLockContainerRef, catchFocus } = useFocusLock({
    disabled: !isVisible,
  })
  const controlsId = `menu-${(title || 'controls').replace(/\s/g, '')}`

  const { ref } = useClickOutside(() => {
    if (shouldCloseOnClickOutside) {
      hide()
    }
  })

  const handleOptionSelect = useCallback(
    (optionId: T) => () => {
      onOptionSelect(optionId)
      hide()
    },
    [hide, onOptionSelect],
  )

  return (
    <Container ref={ref} data-tracking-id={dataTrackingId} fullWidth={fullWidth}>
      <TriggerElementWrapper
        ref={triggerElementWrapper}
        aria-controls={controlsId}
        aria-haspopup="menu"
        aria-expanded={isVisible}
        onClick={toggleVisibility}>
        {openByClickOnEl}
      </TriggerElementWrapper>

      <CSSTransition
        in={isVisible}
        exit
        unmountOnExit
        timeout={TRANSITION_TIMEOUT}
        classNames={TRANSITION_NAME}
        onEntered={() => {
          catchFocus()
        }}>
        <MenuWrapper
          role="menu"
          tabIndex={0}
          id={controlsId}
          aria-orientation="vertical"
          aria-label={title}
          ref={focusLockContainerRef}
          position={position || positionCalculated}>
          {title && <Title aria-hidden="true">{title}</Title>}

          {options.map((option) => (
            <MenuOption<T>
              tabIndex={tabIndex}
              key={String(option.id)}
              isActive={option.id === activeOptionId}
              activeOptionIcon={activeOptionIcon}
              option={option}
              onOptionSelect={handleOptionSelect(option.id)}
              data-tracking-id={`${dataTrackingId}_option_${option.name}`}
            />
          ))}
        </MenuWrapper>
      </CSSTransition>
    </Container>
  )
}
