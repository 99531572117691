import styled from 'styled-components'

export const IconWrap = styled.div`
  margin-right: 5px;
`

export const ControlLink = styled.div`
  padding: 14px 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`
