import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { BrandsShowMore } from '../../brands-show-more'
import type { LayoutProps } from '../../types'
import { SearchFilter } from './search-filter'
import { SearchBrandsModal } from './search-brands-modal'

export function BrandsBlockMobile({
  brands,
  superBrands,
  selectedBrands,
  sortedBrands,
  onChange,
}: LayoutProps) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const i18Base = 'HotelFilters.Hotel'
  return (
    <>
      <SearchFilter onClick={handleOpen} placeholder={t(i18Base + '.SearchBrand')} />
      <SearchBrandsModal
        isOpen={isOpen}
        selectedBrands={selectedBrands}
        onChange={onChange}
        onClose={handleClose}
        brands={brands}
        superBrands={superBrands}
      />
      <BrandsShowMore onChange={onChange} selected={selectedBrands} sortedBrands={sortedBrands} />
    </>
  )
}
