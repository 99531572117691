import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'

export function getSegmentsExisting(segments: TripSegmentValueObject[]) {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  return {
    isFlightExist: segments.some(typeCheck.isFlightSegment),
    isHotelExist: segments.some(typeCheck.isHotelSegment),
    isCarRentalExist: segments.some(typeCheck.isCarRentalSegment),
    isRailExist: segments.some(typeCheck.isTrainSegment),
  }
}
