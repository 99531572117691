import { observer } from 'mobx-react-lite'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { EnterCustomExpenseCodeLayout } from './layout'

export const EnterCustomExpenseCode = observer(function EnterCustomExpenseCode() {
  const { enterCustomExpenseCodeActions, enterCustomExpenseCodeStore } = useRideHailSearchContext()

  return (
    <EnterCustomExpenseCodeLayout
      onBack={enterCustomExpenseCodeActions.handleBack}
      onContinue={enterCustomExpenseCodeActions.handleContinue}
      onCustomCodeValueChanged={enterCustomExpenseCodeActions.handleCustomExpenseCodeValueChanged}
      customCodeValue={enterCustomExpenseCodeStore.customExpenseCodeValue}
      isContinueButtonDisabled={enterCustomExpenseCodeStore.isContinueButtonDisabled}
      maxCustomCodeLength={enterCustomExpenseCodeStore.maxCustomCodeLength}
    />
  )
})
