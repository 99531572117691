import { useMemo } from 'react'
import type { RideHailBookingValueObject } from '@etta/modules/ride-hail/core'
import type { SearchRideHailForm } from '@etta/modules/ride-hail/interface/types'

export function useUberConfirmationDetails(
  booking: RideHailBookingValueObject | null,
  searchForm: SearchRideHailForm,
) {
  const bookingInfo = useMemo(() => {
    return {
      pickUpDate: booking?.pickUpDate ?? searchForm.pickUpDate,
      pickUpTime: booking?.pickUpTime ?? searchForm.pickUpTime,
      pickUpAddress:
        booking?.pickUpAddress ?? (searchForm.pickUpAddress || searchForm.pickUpPlace?.name),
      dropOffAddress:
        booking?.dropOffAddress ?? (searchForm.dropOffAddress || searchForm.dropOffPlace?.name),
    }
  }, [searchForm, booking])

  return {
    bookingInfo,
  }
}
