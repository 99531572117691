import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Place, PlainTime } from '@fiji/types'
import { Swap } from '@etta/ui/swap/swap'
import {
  LocationIconContainer,
  LocationIcon,
  VerticalDelimiter,
  FixedVerticalDelimiter,
  Element,
  VerticalBlock,
  DateTimeContainer,
} from './mobility-ride-details-styled'
import { useMobilityRidesDetail } from './hooks/use-mobility-rides-detail'

type Props = {
  dropOffPlace?: Place
  pickUpDate?: Date
  dropOffAddress?: string
  pickUpPlace?: Place
  pickUpAddress?: string
  pickUpTime?: PlainTime
  pickUpTimezoneOffset?: number
  isShowFlightDetails?: boolean
  airlineName?: string
  flightDesignator?: string
  estimatedWaitingTime?: number | null
  isOnDemandRide?: boolean
  isBooked?: boolean
  isCustomFlight?: boolean
}

export function MobilityRideDetails({
  dropOffPlace,
  pickUpDate,
  dropOffAddress,
  pickUpPlace,
  pickUpAddress,
  pickUpTime,
  airlineName = '',
  flightDesignator = '',
  isShowFlightDetails = false,
  estimatedWaitingTime,
  pickUpTimezoneOffset,
  isOnDemandRide,
  isBooked = false,
  isCustomFlight = false,
}: Props) {
  const { t } = useTranslation()
  const basePath = 'Mobility.ConfirmationPage.'
  const { reservedForDate, bookedOnDate, estimatedPickUpTime } = useMobilityRidesDetail({
    pickUpDate,
    pickUpTime,
    pickUpTimezoneOffset,
    estimatedWaitingTime,
    isBooked,
    isOnDemandRide,
  })

  return (
    <>
      {isShowFlightDetails && (
        <DateTimeContainer>
          <VerticalBlock>
            <Icon name="flightPWA" />
          </VerticalBlock>
          <VerticalBlock>
            <Swap
              is={isCustomFlight}
              isSlot={
                <>
                  <Text variant="footnoteMedium" color="bodyText">
                    {t(basePath + 'LinkedFlight')}
                  </Text>
                  <Text variant="footnoteStrong" color="mainText">
                    {flightDesignator}
                  </Text>
                </>
              }>
              <Text variant="footnoteStrong" color="mainText">
                {airlineName + ' · ' + flightDesignator}
              </Text>
            </Swap>
          </VerticalBlock>
        </DateTimeContainer>
      )}
      {isOnDemandRide && isBooked && (
        <DateTimeContainer>
          <VerticalBlock>
            <Icon name="timerPWA" />
          </VerticalBlock>
          <VerticalBlock>
            <Text variant="footnoteMedium" color="bodyText">
              {t(basePath + 'RideStatus')}
            </Text>
            <Text variant="footnoteStrong" color="mainText">
              {estimatedPickUpTime}
            </Text>
          </VerticalBlock>
        </DateTimeContainer>
      )}
      <Swap
        is={isOnDemandRide}
        isSlot={
          <DateTimeContainer>
            <VerticalBlock>
              <Icon name="calendarPWA" />
            </VerticalBlock>
            <VerticalBlock>
              <Text variant="footnoteMedium" color="bodyText">
                {isBooked ? t(basePath + 'BookedOn') : t(basePath + 'ReservedFor')}
              </Text>
              <Text variant="footnoteStrong" color="mainText">
                {bookedOnDate}
              </Text>
            </VerticalBlock>
          </DateTimeContainer>
        }>
        <DateTimeContainer>
          <VerticalBlock>
            <Icon name="calendarPWA" />
          </VerticalBlock>
          <VerticalBlock>
            <Text variant="footnoteMedium" color="bodyText">
              {t(basePath + 'ReservedFor')}
            </Text>
            <Text variant="footnoteStrong" color="mainText">
              {reservedForDate}
            </Text>
          </VerticalBlock>
        </DateTimeContainer>
      </Swap>
      <Element>
        <LocationIconContainer>
          <LocationIcon isPickUp />
          <VerticalDelimiter />
        </LocationIconContainer>
        <VerticalBlock>
          <Text variant="footnoteMedium" color="bodyText">
            {t(basePath + 'PickUp')}
          </Text>
          <Text variant="footnoteStrong" color="mainText">
            {pickUpAddress ?? pickUpPlace?.name}
          </Text>
        </VerticalBlock>
      </Element>
      <FixedVerticalDelimiter />
      <Element>
        <LocationIconContainer>
          <LocationIcon isPickUp={false} />
        </LocationIconContainer>
        <VerticalBlock>
          <Text variant="footnoteMedium" color="bodyText">
            {t(basePath + 'DropOff')}
          </Text>
          <Text variant="footnoteStrong" color="mainText">
            {dropOffAddress ?? dropOffPlace?.name}
          </Text>
        </VerticalBlock>
      </Element>
    </>
  )
}
