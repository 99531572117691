import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../../types'
import { FlightDetailsEcoCheckMobileComponent } from './flight-details-eco-check-mobile-component'
import { FlightDetailsEcoCheckMobileComponentNew } from './flight-details-eco-check-mobile-component-new'
import { FlightDetailsEcoCheckMobileSkeleton } from './flight-details-eco-check-mobile-skeleton'

export function FlightDetailsEcoCheckMobile({
  loading,
  averageRouteEmissionTonnes,
  tonnesOfEmissions,
  equivalences,
}: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()

  if (loading) {
    return <FlightDetailsEcoCheckMobileSkeleton />
  }

  if (!tonnesOfEmissions || !averageRouteEmissionTonnes) {
    return null
  }
  if (isNewEmissionsBrandingEnabled) {
    return (
      <FlightDetailsEcoCheckMobileComponentNew
        equivalences={equivalences || []}
        tonnesOfEmissions={tonnesOfEmissions}
        averageRouteEmissionTonnes={averageRouteEmissionTonnes}
      />
    )
  }

  return (
    <FlightDetailsEcoCheckMobileComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageRouteEmissionTonnes={averageRouteEmissionTonnes}
    />
  )
}
