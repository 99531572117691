import styled, { keyframes } from 'styled-components'
import { SkeletonContainer } from './deem-header-components'

const skeletonGlow = keyframes`
  from {
    border-color: rgba(206, 217, 224, 0.2);
    background: rgba(206, 217, 224, 0.2);
  }

  to {
    border-color: rgba(92, 112, 128, 0.2);
    background: rgba(92, 112, 128, 0.2);
  }
`

const GlowingDeemHeader = styled(SkeletonContainer)`
  border-color: rgba(206, 217, 224, 0.2);
  box-shadow: none;
  background: rgba(206, 217, 224, 0.2);
  background-clip: padding-box;
  cursor: default;
  color: transparent;
  animation: 500ms linear infinite alternate ${skeletonGlow};
  pointer-events: none;
  user-select: none;
  &::before,
  &::after,
  * {
    visibility: hidden;
  }
`

const DeemHeaderSkeleton: React.SFC = () => <GlowingDeemHeader />

export default DeemHeaderSkeleton
