import styled, { keyframes } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { IconButton } from '@etta/ui/icon-button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const DecoratedLine = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.plum};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 960px;
  width: 80%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
`

export const BackButton = styled(IconButton)`
  position: absolute;
  right: 140px;
`

export const NavigationLink = styled(NavLink)`
  text-decoration: none;

  color: ${({ theme }) => theme.colors.bodyText};

  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  > div {
    width: 82px;
    height: 32px;
  }
`

export const DeemLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    max-height: 26px;
  }

  > div {
    width: 93px;
    height: 26px;
  }
`

export const LogoGoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 126px;
    height: 37px;
  }
`

const guestUserNudgeAnimationKeyframes = keyframes`
  0% {
    transform: translateY(calc(-100% + -4px));
  }
  100% {
    transform: translateY(4px);
  }
`

export const GuestUserNudge = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.plum};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5.5px 16px;
  animation: ${guestUserNudgeAnimationKeyframes} 0.6s ease-in-out;
`

export const TopWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`
