import { Inject } from 'typedi'
import { Service } from '@etta/di'
import { CreateCustomerUberAccountError } from '../../core/errors/create-customer-uber-account.error'
import { GetCustomerUberAccountStatusStore } from '../store/get-customer-uber-account-status.store'
import { CustomerUberAccountManagementAdapter } from '../../infra/customer-uber-account-management/customer-uber-account-management.adapter'
import { CreateCustomerUberAccountStore } from '../store/create-customer-uber-account.store'
import { UberAccountStatusEnum } from '../../core/enum/uber-account-status.enum'

@Service()
export class CreateCustomerUberAccountService {
  constructor(
    @Inject() private customerUberAccountManagementAdapter: CustomerUberAccountManagementAdapter,
    @Inject() private createCustomerUberAccountStore: CreateCustomerUberAccountStore,
    @Inject() private getCustomerUberAccountStatusStore: GetCustomerUberAccountStatusStore,
  ) {}

  async createCustomerUberAccount() {
    if (this.createCustomerUberAccountStore.isCreateCustomerUberAccountExecuting) {
      return
    }
    this.createCustomerUberAccountStore.setIsCreateCustomerUberAccountExecuting(true)

    const result = await this.customerUberAccountManagementAdapter.createCustomerUberAccount()

    // if the account already exists we treat it as a success and get the customer's current status
    const accountAlreadyExists =
      result.isErr() &&
      result.err().isValue() &&
      result.err().getValue() instanceof
        CreateCustomerUberAccountError.AccountAlreadyExistsCreateCustomerUberAccountError

    if (result.isOk() || accountAlreadyExists) {
      this.createCustomerUberAccountStore.setIsError(false)
      this.createCustomerUberAccountStore.setErrorMessage('')
      await this.getCustomerUberAccountStatus()
    } else {
      this.createCustomerUberAccountStore.setIsError(true)
      this.createCustomerUberAccountStore.setErrorMessage(result.getError().error)
    }

    this.createCustomerUberAccountStore.setIsCreateCustomerUberAccountExecuting(false)
  }

  abortCreateCustomerUberAccount() {
    this.customerUberAccountManagementAdapter.abortCreateCustomerUberAccount()
    this.customerUberAccountManagementAdapter.abortGetCustomerUberAccountStatus()
    this.createCustomerUberAccountStore.setIsCreateCustomerUberAccountExecuting(false)
  }

  private async getCustomerUberAccountStatus() {
    const result = await this.customerUberAccountManagementAdapter.getCustomerUberAccountStatus()
    result.match({
      Ok: (accountStatusResult) => {
        this.getCustomerUberAccountStatusStore.setIsError(false)
        this.getCustomerUberAccountStatusStore.setErrorMessage('')
        this.getCustomerUberAccountStatusStore.setLinkingUrl(accountStatusResult.linkingUrl)
        this.getCustomerUberAccountStatusStore.setStatus(accountStatusResult.status)
        this.getCustomerUberAccountStatusStore.setIsUserAccountSelfCreationEnabled(
          accountStatusResult.isUserAccountSelfCreationEnabled,
        )
      },
      Err: (err) => {
        this.getCustomerUberAccountStatusStore.setLinkingUrl('')
        this.getCustomerUberAccountStatusStore.setStatus(UberAccountStatusEnum.Unspecified)
        this.getCustomerUberAccountStatusStore.setIsUserAccountSelfCreationEnabled(false)
        this.getCustomerUberAccountStatusStore.setIsError(true)
        this.getCustomerUberAccountStatusStore.setErrorMessage(err.error)
      },
    })
  }
}
