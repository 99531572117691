import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import { Container, Header, HeaderText } from './hotel-search-page-mobile-recent-searches-styled'
import { HotelSearchFormMobilePageRecentSearchItems } from './hotel-search-page-mobile-recent-search-items'

export const HotelSearchFormMobilePageRecentSearches = observer(
  function HotelSearchFormMobilePageRecentSearches() {
    const { recentSearchesStore } = useRecentSearchesContext()
    const { t } = useTranslation()
    const i18nBase = 'RecentSearches'

    if (
      !recentSearchesStore.isShowHotelRecentSearch &&
      !recentSearchesStore.isShowHotelRecentSearchWithoutDates
    ) {
      return null
    }

    return (
      <Container>
        <Block paddingHorizontal={16}>
          <Header>
            <Icon name="timePWA" color="bodyText" />
            <HeaderText>{t(`${i18nBase}.Title`)}</HeaderText>
          </Header>
        </Block>
        <HotelSearchFormMobilePageRecentSearchItems />
      </Container>
    )
  },
)
