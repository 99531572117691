import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { useCarRentalServiceFee } from './use-car-rental-service-fee'
import { Container } from './car-rental-service-fee-styled'

type Props = {
  serviceFeeLabel?: string
  vendorCode?: string
}

export function CarRentalServiceFee({ vendorCode }: Props) {
  const { serviceFeeLabel } = useCarRentalServiceFee({ vendorCode })

  if (!serviceFeeLabel) {
    return null
  }

  return (
    <Container data-tracking-id="car-rental-service-fee">
      <Block marginRight={5}>
        <Icon name="serviceFeePWA" color="error" size={24} />
      </Block>
      <Block paddingTop={2}>
        <Text variant="subHeadMedium" color="bodyText">
          {serviceFeeLabel}
        </Text>
      </Block>
    </Container>
  )
}
