import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import { LocationSearchField } from '@etta/components/location-search-field/location-search-field'
import { PlaceAutocompleteSort } from '@fiji/graphql/types'
import { StyledTextVariant } from '@fiji/enums'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { LayoutProps } from '../../types'
import { ErrorBlock, ErrorText } from '../../../air-search-form-styled'
import { Container } from './location-section-mobile-styles'

const destinationPlacesSortOrder = [
  PlaceAutocompleteSort.Airports,
  PlaceAutocompleteSort.HomeAirport,
  PlaceAutocompleteSort.ReferencePoint,
  PlaceAutocompleteSort.GooglePlaces,
]

const i18Base = 'TripPlanner.BaseSearch'

export function LocationSectionMobile({
  originPlace,
  destinationPlace,
  isErrorInput,
  onSwitch,
  onOriginPlaceChange,
  onDestinationPlaceChange,
  placesHistory,
  flightType,
  isDisabled,
  validationErrors,
}: LayoutProps) {
  const { t } = useTranslation()
  const fromLabel = t(i18Base + '.Input.From')
  const toLabel = t(i18Base + '.Input.To')

  const isMultiCity = flightType === FlightType.MultiCity
  const errorMessage = validationErrors.originPlace ?? validationErrors.destinationPlace

  const hasDestinationError = useMemo(() => {
    if (isMultiCity || validationErrors.originPlace) {
      return undefined
    }

    return !!validationErrors.destinationPlace
  }, [isMultiCity, validationErrors.destinationPlace, validationErrors.originPlace])

  return (
    <>
      <Container>
        <InputButtonGroup
          aria-label={t(i18Base + '.Input.Depart')}
          direction={
            isMultiCity ? InputButtonGroupDirection.Horizontal : InputButtonGroupDirection.Vertical
          }
          isToggle={!isMultiCity && !isDisabled}
          errorMessage={errorMessage}
          hasError={isMultiCity && !!errorMessage}
          onToggle={onSwitch}>
          <LocationSearchField
            onChange={onOriginPlaceChange}
            value={originPlace}
            label={fromLabel}
            latestPlaces={placesHistory}
            aria-label={
              originPlace
                ? t(i18Base + '.Input.PlaceSelectedAriaLabel', {
                    label: fromLabel,
                    value: originPlace.name,
                  })
                : t(i18Base + '.Input.PlaceAriaLabel', { label: fromLabel })
            }
            inputAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', { label: fromLabel })}
            isOutboundTrip
            useNewLocationSearch
            hasCurrentLocationSearch
            searchType="air"
            recentSearchesType="flight"
            disabled={isDisabled}
            hasError={isMultiCity ? undefined : !!validationErrors.originPlace}
          />
          <LocationSearchField
            onChange={onDestinationPlaceChange}
            value={destinationPlace}
            label={toLabel}
            aria-label={
              destinationPlace
                ? t(i18Base + '.Input.PlaceSelectedAriaLabel', {
                    label: toLabel,
                    value: destinationPlace.name,
                  })
                : t(i18Base + '.Input.PlaceAriaLabel', { label: toLabel })
            }
            inputAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', { label: toLabel })}
            latestPlaces={placesHistory}
            locationsSortOrder={destinationPlacesSortOrder}
            useNewLocationSearch
            searchType="air"
            disabled={isDisabled}
            recentSearchesType="flight"
            hasError={hasDestinationError}
          />
        </InputButtonGroup>
      </Container>

      {isErrorInput && (
        <ErrorBlock>
          <Icon name="errorFilledPWA" size="normal" color="error" />
          <ErrorText textVariant={StyledTextVariant.captionStrong}>
            {t(i18Base + '.Input.Error')}
          </ErrorText>
        </ErrorBlock>
      )}
    </>
  )
}
