export enum OutOfPolicyEnum {
  AcNotAllowed = 'AC_NOT_ALLOWED',
  AcRequired = 'AC_REQUIRED',
  AdvancePurchaseFareBlocked = 'ADVANCE_PURCHASE_FARE_BLOCKED',
  AdvancePurchaseRequired = 'ADVANCE_PURCHASE_REQUIRED',
  AirportChangeBlocked = 'AIRPORT_CHANGE_BLOCKED',
  AirportChangeRequired = 'AIRPORT_CHANGE_REQUIRED',
  AirRestrictedVendor = 'AIR_RESTRICTED_VENDOR',
  AllPreferred = 'ALL_PREFERRED',
  AllPreferredLeg = 'ALL_PREFERRED_LEG',
  AllPreferredSavingsRequired = 'ALL_PREFERRED_SAVINGS_REQUIRED',
  AllPreferredSavingsRequiredNoAmount = 'ALL_PREFERRED_SAVINGS_REQUIRED_NO_AMOUNT',
  AlternateAirport = 'ALTERNATE_AIRPORT',
  AlternateAirports = 'ALTERNATE_AIRPORTS',
  AlternateAirportWithSavings = 'ALTERNATE_AIRPORT_WITH_SAVINGS',
  AutoTransRequired = 'AUTO_TRANS_REQUIRED',
  BizClass = 'BIZ_CLASS',
  CancelFee = 'CANCEL_FEE',
  CarPreferredNotAllowed = 'CAR_PREFERRED_NOT_ALLOWED',
  CarRentalRequired = 'CAR_RENTAL_REQUIRED',
  CarRentalRequiredViolation = 'CAR_RENTAL_REQUIRED_VIOLATION',
  CarRestrictedVendor = 'CAR_RESTRICTED_VENDOR',
  ChangeFee = 'CHANGE_FEE',
  CompanyPerDiem = 'COMPANY_PER_DIEM',
  ConnectionsBlocked = 'CONNECTIONS_BLOCKED',
  ConnectionsNotAllowed = 'CONNECTIONS_NOT_ALLOWED',
  ConnectionsSavings = 'CONNECTIONS_SAVINGS',
  DefaultPolicyReason = 'DEFAULT_POLICY_REASON',
  DiffInFeeCap = 'DIFF_IN_FEE_CAP',
  ExceedsMaxRate = 'EXCEEDS_MAX_RATE',
  ExceedsMaxSize = 'EXCEEDS_MAX_SIZE',
  ExceedsNegotiatedRate = 'EXCEEDS_NEGOTIATED_RATE',
  FirstClass = 'FIRST_CLASS',
  FourDoorNotAllowed = 'FOUR_DOOR_NOT_ALLOWED',
  FuelTypeNotAllowed = 'FUEL_TYPE_NOT_ALLOWED',
  HighlyPreferred = 'HIGHLY_PREFERRED',
  HighlyPreferredLeg = 'HIGHLY_PREFERRED_LEG',
  HotelRequiredForOvernightTrip = 'HOTEL_REQUIRED_FOR_OVERNIGHT_TRIP',
  HotelRestrictedVendor = 'HOTEL_RESTRICTED_VENDOR',
  ManualTransNotAllowed = 'MANUAL_TRANS_NOT_ALLOWED',
  ManualTransRequired = 'MANUAL_TRANS_REQUIRED',
  MaxRate = 'MAX_RATE',
  MinAdvancePurchaseDays = 'MIN_ADVANCE_PURCHASE_DAYS',
  MostPreferred = 'MOST_PREFERRED',
  MostPreferredLeg = 'MOST_PREFERRED_LEG',
  NonRefundableFareBlocked = 'NON_REFUNDABLE_FARE_BLOCKED',
  NonRefundableFareRequired = 'NON_REFUNDABLE_FARE_REQUIRED',
  PerDiemBlocked = 'PER_DIEM_BLOCKED',
  Preferred = 'PREFERRED',
  PreferredChainRequired = 'PREFERRED_CHAIN_REQUIRED',
  PreferredChainRestricted = 'PREFERRED_CHAIN_RESTRICTED',
  PreferredHotelRequired = 'PREFERRED_HOTEL_REQUIRED',
  PreferredLeg = 'PREFERRED_LEG',
  PreferredNotAllowed = 'PREFERRED_NOT_ALLOWED',
  PreferredRequired = 'PREFERRED_REQUIRED',
  PremiumCoachClass = 'PREMIUM_COACH_CLASS',
  PrivateRateBlocked = 'PRIVATE_RATE_BLOCKED',
  PrivateRateRequired = 'PRIVATE_RATE_REQUIRED',
  RateCapViolation = 'RATE_CAP_VIOLATION',
  RateMoreThanCompanyPerDiem = 'RATE_MORE_THAN_COMPANY_PER_DIEM',
  RestrictedCar = 'RESTRICTED_CAR',
  RestrictedFareBlocked = 'RESTRICTED_FARE_BLOCKED',
  RestrictedFareRequired = 'RESTRICTED_FARE_REQUIRED',
  RestrictedHotel = 'RESTRICTED_HOTEL',
  RestrictedRoomType = 'RESTRICTED_ROOM_TYPE',
  RoomPvtRateLtdBlocked = 'ROOM_PVT_RATE_LTD_BLOCKED',
  RoomPvtRateLtdReqd = 'ROOM_PVT_RATE_LTD_REQD',
  RoomTypeRestricted = 'ROOM_TYPE_RESTRICTED',
  RuleRequireCompanyPreferredVendors = 'RULE_REQUIRE_COMPANY_PREFERRED_VENDORS',
  RuleTypeRequireRateBelowPrivateCost = 'RULE_TYPE_REQUIRE_RATE_BELOW_PRIVATE_COST',
  TrainBizClass = 'TRAIN_BIZ_CLASS',
  TrainFirstClass = 'TRAIN_FIRST_CLASS',
  TwoDoorNotAllowed = 'TWO_DOOR_NOT_ALLOWED',
  TwoDoorRequired = 'TWO_DOOR_REQUIRED',
  TypeNotAllowed = 'TYPE_NOT_ALLOWED',
  TypeRestricted = 'TYPE_RESTRICTED',
  UnusedTicket = 'UNUSED_TICKET',
  UnusedTicketNotAllowed = 'UNUSED_TICKET_NOT_ALLOWED',
  WebFareBlocked = 'WEB_FARE_BLOCKED',
  WebFareDiffInFeeCap = 'WEB_FARE_DIFF_IN_FEE_CAP',
  WebFareRequired = 'WEB_FARE_REQUIRED',
}
