import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import type { ValidatedTicket } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { useUnusedTicketOptContext } from '@etta/modules/unused-ticket-opt'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { UnusedTicketRatesSkeleton } from './unused-ticket-rates-skeleton'
import {
  Wrapper,
  Cost,
  RemoveButton,
  ApplyUnusedTicketsWrapper,
  Description,
} from './unused-ticket-rates-styled'

type Props = {
  unusedTicket?: ValidatedTicket | null
  allowUnusedTicketOptout?: boolean
  hasOptOutUnusedTickets?: boolean
}

const i18nBase = 'PaymentSummary'

export const UnusedTicketRates = observer(function UnusedTicketRates({
  allowUnusedTicketOptout,
  hasOptOutUnusedTickets,
}: Props) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { featureFlags } = useFeatureFlags()
  const { unusedTicketOptStore, unusedTicketOptAction } = useUnusedTicketOptContext()
  const { reviewTripStore } = useReviewTripContext()

  const { t } = useTranslation()

  const isUnusedTicketsOptOutEnabled = useMemo(() => {
    return isMobile
      ? featureFlags.isUnusedTicketsOptOutEnabled
      : featureFlags.isDesktopUnusedTicketsOptOutEnabled
  }, [featureFlags, isMobile])

  if (unusedTicketOptStore.isLoading) {
    return <UnusedTicketRatesSkeleton />
  }

  if (hasOptOutUnusedTickets && allowUnusedTicketOptout) {
    return (
      <ApplyUnusedTicketsWrapper
        mobileView={isMobile}
        onClick={unusedTicketOptAction.applyUnusedTicket}>
        <Text variant="linkSmall" color="primary" aria-label={t(i18nBase + '.ApplyUnusedCredit')}>
          {t(i18nBase + '.ApplyUnusedCredit')}
        </Text>
        <Icon name="addPWA" color="primary" size="medium" />
      </ApplyUnusedTicketsWrapper>
    )
  }

  if (!reviewTripStore.isUnusedTicketApplied) {
    return null
  }

  const unvalidatedUnusedTicket = reviewTripStore.unvalidatedUnusedTickets

  if (unvalidatedUnusedTicket) {
    const { mainCost: unvalidatedUnusedTicketCost } = formatRate(unvalidatedUnusedTicket?.total, {
      morpheme: 'none',
    })
    return (
      <Wrapper data-tracking-id="unused-ticket-rates">
        <Cost>
          <Text
            aria-label={t(i18nBase + '.UnusedTicketsApplied')}
            variant="footnoteMedium"
            color="mainText">
            {t(i18nBase + '.UnusedTicketsApplied')}
          </Text>
          <Text aria-label={unvalidatedUnusedTicketCost} variant="footnoteMedium" color="mainText">
            - {unvalidatedUnusedTicketCost}
          </Text>
        </Cost>
        {allowUnusedTicketOptout && isUnusedTicketsOptOutEnabled && (
          <Block marginTop={8}>
            <RemoveButton
              onClick={unusedTicketOptAction.removeUnusedTicket}
              variant="text"
              size="small">
              {t(i18nBase + '.Remove')}
            </RemoveButton>
          </Block>
        )}
      </Wrapper>
    )
  }

  const validatedUnusedTickets = reviewTripStore.validatedUnusedTickets

  const { mainCost: unusedTicketCost } = formatRate(validatedUnusedTickets?.original, {
    morpheme: 'none',
  })

  const { mainCost: unusedTicketNewTicket } = formatRate(validatedUnusedTickets?.newTicket, {
    morpheme: 'none',
  })

  const { mainCost: unusedTicketPenaltyCost } = formatRate(
    validatedUnusedTickets?.penalty && validatedUnusedTickets.penalty.primary.amount > 0
      ? validatedUnusedTickets?.penalty
      : undefined,
    {
      morpheme: 'none',
    },
  )

  const { mainCost: unusedTicketFareForfeiture } = formatRate(validatedUnusedTickets?.forfeiture, {
    morpheme: 'none',
  })

  const { mainCost: unusedTicketResidual } = formatRate(validatedUnusedTickets?.residual, {
    morpheme: 'none',
  })

  return (
    <Wrapper data-tracking-id="unused-ticket-rates">
      <Description>
        <Icon name="unusedTicketPWA" color="success" size="small" />
        <Text
          aria-label={t(i18nBase + '.UnusedTicketDescription')}
          variant="footnoteStrong"
          color={'bodyText1'}>
          {t(i18nBase + '.UnusedTicketDescription')}
        </Text>
      </Description>
      <Cost>
        <Text aria-label={t(i18nBase + '.NewFareAmount')} variant="footnoteMedium" color="mainText">
          {t(`${i18nBase}.NewFareAmount`, {
            newFareAmount: unusedTicketNewTicket,
          })}
        </Text>
      </Cost>
      <Cost>
        <Text
          aria-label={t(i18nBase + '.AmountAppliedToNewTicket')}
          variant="footnoteMedium"
          color="mainText">
          {t(i18nBase + '.AmountAppliedToNewTicket')}
        </Text>
        <Text aria-label={unusedTicketCost} variant="footnoteMedium" color="mainText">
          - {unusedTicketCost}
        </Text>
      </Cost>
      {unusedTicketPenaltyCost && (
        <Cost>
          <Text aria-label={t(i18nBase + '.ChangeFees')} variant="footnoteMedium" color="mainText">
            {t(i18nBase + '.ChangeFees')}
          </Text>
          <Text aria-label={unusedTicketPenaltyCost} variant="footnoteMedium" color="mainText">
            {unusedTicketPenaltyCost}
          </Text>
        </Cost>
      )}
      {unusedTicketFareForfeiture && (
        <Cost>
          <Text aria-label={t(i18nBase + '.Forfeiture')} variant="footnoteMedium" color="mainText">
            {t(i18nBase + '.Forfeiture')}
          </Text>
          <Text aria-label={unusedTicketFareForfeiture} variant="footnoteMedium" color="mainText">
            {unusedTicketFareForfeiture}
          </Text>
        </Cost>
      )}
      {unusedTicketResidual && (
        <Cost>
          <Text
            aria-label={t(i18nBase + '.FareForfeiture')}
            variant="footnoteMedium"
            color="mainText">
            {t(i18nBase + '.FareForfeiture')}
          </Text>
          <Text aria-label={unusedTicketResidual} variant="footnoteMedium" color="mainText">
            {unusedTicketResidual}
          </Text>
        </Cost>
      )}
      {allowUnusedTicketOptout && isUnusedTicketsOptOutEnabled && (
        <Block marginTop={8}>
          <RemoveButton
            onClick={unusedTicketOptAction.removeUnusedTicket}
            variant="text"
            size="small">
            {t(i18nBase + '.Remove')}
          </RemoveButton>
        </Block>
      )}
    </Wrapper>
  )
})
