import { Toggle } from '@etta/interface/services/toggle'
import { Inject, Store } from '@etta/di'
import { Validator } from '@etta/interface/services/validator'
import i18n from '@fiji/i18n'
import { UserStore } from '@etta/modules/user'

const i18nBase = `Settings.ChangePassword`
const i18BaseErrors = `${i18nBase}.Errors`

export type PasswordInput = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

@Store()
export class PasswordStore {
  constructor(
    @Inject()
    private readonly userStore: UserStore,
  ) {}
  isSaveButtonDisabled = true

  toggle = new Toggle()

  passwordValidator = new Validator({
    currentPassword: Validator.scheme
      .string()
      .required(i18n.t(i18BaseErrors + '.CurrentPasswordIsRequired'))
      .default(''),
    newPassword: Validator.scheme
      .string()
      .test(
        'does-match-requirements',
        i18n.t(i18BaseErrors + '.NewPasswordWeak'),
        (value) =>
          !!value?.length &&
          value?.length >= 8 &&
          // eslint-disable-next-line no-control-regex
          /^[\x00-\x7F]/gim.test(value) &&
          !new RegExp(
            `${this.userStore.profile?.firstName}|${this.userStore.profile?.lastName}`,
            'gim',
          ).test(value) &&
          !new RegExp(`${this.userStore.profile?.username}`, 'gim').test(value),
      )
      .required(i18n.t(i18BaseErrors + '.NewPasswordWeak'))
      .default(''),
    newPasswordConfirmation: Validator.scheme
      .string()
      .oneOf([Validator.scheme.ref('newPassword')], i18n.t(i18BaseErrors + '.NewPasswordsMismatch'))
      .required(i18n.t(i18BaseErrors + '.NewPasswordsMismatch'))
      .default(''),
  })

  get newPasswordErrorMessage() {
    if (this.passwordValidator.errors.newPassword) {
      return this.passwordValidator.errors.newPassword
    }

    if (this.passwordValidator.errors.newPasswordConfirmation) {
      return this.passwordValidator.errors.newPasswordConfirmation
    }

    return undefined
  }

  setPasswordValue<T extends keyof PasswordInput>(field: T, value: PasswordInput[T]) {
    this.passwordValidator.onFieldChange(field, value)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
