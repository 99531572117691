import { useMemo } from 'react'
import type { CreditCardFormData } from '@etta/components/credit-card-modal/types'
import type { CreditCard } from '@fiji/graphql/types'
import { CreditCardBrandType } from '@fiji/graphql/types'
import { formatExpirationDate } from './format-expiration-date'

type Args = {
  editableCreditCard: Partial<CreditCard> | null
  isCvvRequired?: boolean
}

export function useEditCreditCardInitialData({ editableCreditCard, isCvvRequired }: Args) {
  return useMemo<CreditCardFormData>(() => {
    return {
      isCvvRequired,
      creditCardData: {
        cardNumber: editableCreditCard?.cardNumber || '',
        expirationDate: formatExpirationDate(editableCreditCard?.expirationDate) || '',
        label: editableCreditCard?.label || '',
        name: editableCreditCard?.name || '',
        brandType: editableCreditCard?.brandType || CreditCardBrandType.Other,
      },
      billingAddress: {
        city: editableCreditCard?.billingAddress?.city || '',
        countryCode: editableCreditCard?.billingAddress?.countryCode || '',
        postalCode: editableCreditCard?.billingAddress?.postalCode || '',
        stateCode: editableCreditCard?.billingAddress?.stateCode || '',
        street1: editableCreditCard?.billingAddress?.street1 || '',
        street2: editableCreditCard?.billingAddress?.street2 || '',
      },
    }
  }, [editableCreditCard, isCvvRequired])
}
