import type {
  UpdateProfileTravelPreferencesInput,
  UpdateProfileBusinessAddressInput,
  UpdateProfileContactInformationInput,
  UpdateAdditionalInformationInput,
} from '@fiji/graphql/types'
import { UserProfileCustomFieldType } from '@fiji/graphql/types'
import type { UserProfileAdditionalInfoInputValueObject } from '../../../core/value-objects/user-profile-additional-info-input.value-object'
import { UserProfileFieldType } from '../../../core/enums/user-profile-field-type.enum'

type UpdateUserProfileInput =
  | UpdateProfileTravelPreferencesInput
  | UpdateProfileContactInformationInput
  | UpdateProfileBusinessAddressInput

export class UserProfileInputMapper {
  static mapToUpdateUserProfileInput(
    input: Array<UserProfileAdditionalInfoInputValueObject>,
  ): UpdateUserProfileInput {
    return {
      additionalInformation: input.map(this.mapToAdditionalInformation),
    }
  }

  private static mapToAdditionalInformation(
    input: UserProfileAdditionalInfoInputValueObject,
  ): UpdateAdditionalInformationInput {
    switch (input.fieldType) {
      case UserProfileFieldType.CheckBox: {
        return {
          id: input.fieldId,
          checkBoxProperties: { checkBoxValue: Boolean(input.value) },
          fieldType: UserProfileCustomFieldType.CheckBox,
        }
      }
      case UserProfileFieldType.Number: {
        return {
          id: input.fieldId,
          numberProperties: { numberValue: Number(input.value) },
          fieldType: UserProfileCustomFieldType.Number,
        }
      }
      case UserProfileFieldType.Text: {
        return {
          id: input.fieldId,
          textProperties: { textValue: String(input.value) },
          fieldType: UserProfileCustomFieldType.Text,
        }
      }
      case UserProfileFieldType.List: {
        return {
          id: input.fieldId,
          listProperties: { listValue: String(input.value) },

          fieldType: UserProfileCustomFieldType.List,
        }
      }
      case UserProfileFieldType.DateTime: {
        return {
          id: input.fieldId,
          dateTimeProperties: { dateTimeValue: String(input.value) },
          fieldType: UserProfileCustomFieldType.DateTime,
        }
      }
    }

    return {
      id: input.fieldId,
      fieldType: UserProfileCustomFieldType.Unspecified,
    }
  }
}
