import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { title2, subHeadMedium, linkSmall } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};

  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }
`

export const EcoCheckBackground = styled.img`
  width: 100%;
  border-radius: 0 0 14px 14px;
  margin-bottom: -4px;
`

export const Title = styled.div`
  padding: 16px 16px 0 16px;
`

export const Body = styled.div`
  padding: 0 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const BodyLeft = styled.div<{
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}>`
  border-radius: 10px;
  height: 58px;
  color: ${(props) => {
    switch (props.deviationLevel) {
      case EmissionsDeviationLevel.Less:
        return props.theme.colors.success
      case EmissionsDeviationLevel.More:
        return props.theme.colors.mainText
      case EmissionsDeviationLevel.Average:
        return props.theme.colors.bodyText1
    }
  }};
  background: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.ecoCheck1
      : props.theme.colors.background};
  padding: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Average ? '7px 12px' : '11px 8px'};
  ${title2};
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const RailEmissionDescriptionWrapper = styled.div`
  ${subHeadMedium}
`

export const BodyLeftIcon = styled(Icon)`
  height: 100%;
`

export const BodyPercent = styled.div`
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
`

export const LearnMore = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  ${linkSmall};
`

export const PercentNumber = styled.p`
  margin: 0;
  display: inline;
`

export const RailEmissionDescription = styled(Text)`
  margin: 9px 0;
`

export const EmissionsSummary = styled.p`
  margin: 0;
  ${title2};
  margin-top: 5px;
`
