import type { Money } from '@fiji/graphql/types'
import type { MoneyValueObject } from '@etta/core/value-objects'
import type { CurrencyMorpheme } from './insert-morpheme'
import { insertMorpheme } from './insert-morpheme'

const DEFAULT_PRECISION = 2

type PriceFormatOptions = {
  isInteger?: boolean
  morpheme?: CurrencyMorpheme
}

export function formatPrice(price: Money | MoneyValueObject, options?: PriceFormatOptions): string {
  const { isInteger, morpheme } = options || {}
  const params = {
    minimumFractionDigits: isInteger ? 0 : DEFAULT_PRECISION,
    maximumFractionDigits: isInteger ? 0 : DEFAULT_PRECISION,
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency,
    ...params,
  })

  const value = formatter.format(price.amount)

  const usedValue = value.split(/\u00a0/g).join(' ')

  return insertMorpheme(usedValue, price.currency, morpheme)
}
