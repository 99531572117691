import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { SeatmapCabinClass } from '@fiji/graphql/types'
import type {
  FlightSegmentEntity,
  TripCostSummaryValueObject,
} from '@etta/modules/review-trip/core'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { featureFlagsSelector } from '@fiji/store/feature-flags'
import { useAppSelector } from '@fiji/store'
import { useDeleteFlight } from '@etta/screens/review-trip-page/hooks/use-delete-flight'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { useReviewTripPageContextSelector } from '@etta/screens/review-trip-page/review-trip-page-provider'
import { useLuggageOptionsContext } from '@etta/modules/booking'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import type { LuggageOptionValueObject } from '@etta/modules/booking/core/value-objects'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useTripContent } from '../../../use-trip-content'
import { useChangeFlight } from '../../../../flight-details-modal/use-flight-details-modal/use-change-flight'
import { useFareAttributesData } from './use-fare-attributes-data'
import { useModifyFlight } from './use-modify-flight'

type Args = {
  segment: FlightSegmentEntity
  isChangeAllowed: boolean
  isChangeCarrierAllowed?: boolean
  isChangeDestinationAllowed?: boolean
  isRemoveAllowed?: boolean
  flightSegments: FlightSegmentEntity[]
  flightId: string
  itineraryId: string
  isTripExist: boolean
  tripCost: TripCostSummaryValueObject
  flightIndex: number
  isMultiCity?: boolean
}

type CardAction = {
  label: string
  onClick?: () => void
}

const i18nPathAccessability = 'Accessibility.ReviewTripPage'
const i18BaseFaresDetails = 'FlightDetailsModal.FaresDetails.'

export function useAirSegmentRtp({
  segment: flightLeg,
  isChangeAllowed,
  isChangeCarrierAllowed,
  isChangeDestinationAllowed,
  isRemoveAllowed,
  flightSegments,
  flightId,
  itineraryId,
  isTripExist,
  flightIndex,
  isMultiCity,
}: Args) {
  const { t } = useTranslation()
  const { postBookingTripStore } = usePostBookingContext()
  const { values } = useAppSelector(featureFlagsSelector)
  const isFlightSafetyCheckEnabled = !!values.isFlightSafetyCheckEnabled
  const isServiceFeeEnabled = !!values.isServiceFeeEnabled
  const isChangingReturnFlightEnabled = !!values.isChangingReturnFlightEnabled
  const { airSearchStore, airSearchActions } = useAirSearchContext()
  const { searchId } = airSearchStore
  const { rtpSeatMapModalToggle } = useReviewTripPageContextSelector((state) => state)

  const flightDetailsToggle = useTogglePopup()
  const changeFlightsModalToggle = useTogglePopup()
  const returnFlightModal = useTogglePopup()
  const removalDialogToggle = useTogglePopup()
  const addBaggageModalToggle = useTogglePopup()

  const { luggageOptionsStore, luggageOptionsActions } = useLuggageOptionsContext()
  const { seatMapPaginationActions } = useSeatMapContext()

  useEffect(() => {
    // when failed, it tries to fetch each time go back to RTP
    airSearchActions.getFareAttributesForItineraryFlights(flightSegments)
  }, [airSearchActions, flightSegments])

  const handleAddBaggageModalClose = () => {
    addBaggageModalToggle.handleClose()
  }

  const handleSelectBaggageOption = (luggageOption?: LuggageOptionValueObject) => {
    luggageOptionsActions.setLuggageOption(luggageOption)
    addBaggageModalToggle.handleClose()
  }

  const showLuggageOptions =
    luggageOptionsStore.isLuggageOptionsApplicable &&
    flightIndex === 0 &&
    !!flightLeg.luggageOptions?.length

  const shouldShowServiceFeeLabel =
    isServiceFeeEnabled && flightLeg.segments.some((segment) => Boolean(segment.serviceFee))

  const handleOpenReturnFlightModal = returnFlightModal.handleOpen
  const updateChangeModalOpen = changeFlightsModalToggle.handleOpen
  const handleCloseModal = () => {
    flightDetailsToggle.handleClose()
    changeFlightsModalToggle.handleClose()
    returnFlightModal.handleClose()
  }

  const flightSegment = flightLeg.segments[0]
  const flightLastSegment =
    flightLeg.segments.length > 1 && flightLeg.segments[flightLeg.segments.length - 1]
  const formatTime = (time: string) =>
    tripReviewFormatDate(TripReviewDateType.Time, time)?.replace(/\s/g, '').toLowerCase()

  const { getCabinClassLabel } = useCabinClassLabels()
  const cabinClassLabel = getCabinClassLabel(flightSegment.serviceClass as SeatmapCabinClass)

  const handleDeleteFlight = () => {
    if (!isRemoveAllowed) {
      return
    }

    return removalDialogToggle.handleOpen
  }

  const isChangingReturnFlight = useMemo(() => {
    if (!isChangingReturnFlightEnabled) {
      return false
    }
    const hasTwoFlights = flightSegments.length === 2

    if (!hasTwoFlights) {
      return false
    }

    return postBookingTripStore.isReturnFlightChangeable
  }, [isChangingReturnFlightEnabled, flightSegments, postBookingTripStore.isReturnFlightChangeable])

  const isChangingEntireFlight = useMemo(() => postBookingTripStore.isEntireFlightChangeable, [
    postBookingTripStore.isEntireFlightChangeable,
  ])

  let handleChangeAirLink
  if (isTripExist && isChangeAllowed && !isMultiCity) {
    handleChangeAirLink = isChangingReturnFlight
      ? handleOpenReturnFlightModal
      : updateChangeModalOpen
  }
  const { handleChangeFlights } = useChangeFlight({
    flightSegments,
    flightId,
    searchId,
    itineraryId,
    isChangeCarrierAllowed,
    isChangeDestinationAllowed,
    handleCloseChangeModal: handleCloseModal,
  })

  const { handleModifyFlight } = useModifyFlight({
    itineraryId,
    flightId,
  })

  const {
    handleRemoveFlight,
    viewStateParams: { viewState: removalViewState, title: removalTitle },
  } = useDeleteFlight({ flightId })

  const handleConfirmRemoveFlight = useCallback(() => {
    handleRemoveFlight()
    removalDialogToggle.handleClose()
  }, [handleRemoveFlight, removalDialogToggle])

  const { cardStatus } = useTripContent({ segmentState: flightLeg.segmentState })

  const {
    flightSafetyChecks,
    flightSafetyCheckDetails,
    flightSafetyCheckIcons,
    isFlightFareAttributesLoading,
    isFlightFareAttributesError,
    refetchFlightFareAttributes,
  } = useFareAttributesData({ segment: flightLeg, isFlightSafetyCheckEnabled, itineraryId })

  const flightSeatsExist = flightLeg.segments.some((segment) => Boolean(segment.travelerSeat))

  const handleSeatChange = useCallback(() => {
    rtpSeatMapModalToggle.handleOpen()
    seatMapPaginationActions.handleSelectFlightCard({
      legIndex: flightLeg.position - 1,
      segmentIndex: 0,
    })
  }, [rtpSeatMapModalToggle, seatMapPaginationActions, flightLeg])

  const travelerSeats = flightLeg.segments
    .map((segment) => segment.travelerSeat)
    .filter(Boolean)
    .join(', ')

  const isSeatMapAvailable = flightLeg.segments.some((segment) => segment.isSeatAvailable)

  const cardActions: CardAction[] = [
    {
      label: travelerSeats,
    },
  ]

  if (isSeatMapAvailable) {
    cardActions.push({
      label: t(
        i18BaseFaresDetails + (flightSeatsExist ? 'ChangeSeatsButton' : 'SelectSeatsButton'),
      ),
      onClick: handleSeatChange,
    })
  }

  if (!handleChangeAirLink && !isTripExist && isChangeAllowed) {
    cardActions.push({
      label: t('FlightDetailsModal.ChangeFlights.Title'),
      onClick: updateChangeModalOpen,
    })
  }

  const bodyAriaLabel = t(`${i18nPathAccessability}.FlightCard`, {
    origin: flightSegment.departure.airportCode,
    destination: flightLastSegment
      ? flightLastSegment.arrival.airportCode!
      : flightSegment.arrival.airportCode!,
  })

  const onCloseFlightDetailsModal = useCallback(() => {
    flightDetailsToggle.handleClose()
  }, [flightDetailsToggle])

  const operatedBy = useMemo(() => {
    const operatedBy = flightLeg.segments
      ?.map((segment) => segment.operatedBy)
      .filter(Boolean)
      .join(', ')

    return operatedBy
      ? t('FlightDetails.OperatedBy', {
          operatedBy,
        })
      : undefined
  }, [t, flightLeg])

  const shouldShowAirportChangeBadge: boolean = useMemo(() => {
    return flightLeg.segments.some((segment) => segment.isDepartAirportDifferentFromConnecting)
  }, [flightLeg.segments])

  const isWebFare = useMemo(() => {
    let isWebFare: boolean = false
    flightLeg.segments?.forEach((segment) => {
      if (segment.bookingProvider === 'TRAVEL_FUSION') {
        isWebFare = true
      }
    })

    return isWebFare
  }, [flightLeg])

  const isNDCFare = !!flightLeg?.isNDCFare

  return {
    isWebFare,
    isNDCFare,
    flightLeg,
    flightLastSegment,
    flightSegment,
    formatTime,
    cabinClassLabel,
    flightDetailsToggle,
    isChangeModalOpen: changeFlightsModalToggle.isOpen,
    handleChangeAirLink,
    handleCloseModal,
    cardStatus,
    handleChangeFlights,
    handleSubmitModifyFlight: handleModifyFlight,
    handleConfirmRemoveFlight,
    flightSafetyChecks,
    flightSafetyCheckDetails,
    flightSafetyCheckIcons,
    isFlightFareAttributesLoading,
    isFlightSafetyCheckEnabled,
    isFlightFareAttributesError,
    refetchFlightFareAttributes,
    cardActions,
    removalDialogToggle,
    removalViewState,
    removalTitle,
    bodyAriaLabel,
    handleDeleteFlight,
    onCloseFlightDetailsModal,
    travelerSeats,
    operatedBy,
    isSeatMapAvailable,
    showLuggageOptions,
    addBaggageModalToggle,
    handleAddBaggageModalClose,
    handleSelectBaggageOption,
    selectedBaggageOption: luggageOptionsStore.departureLuggageOption,
    shouldShowAirportChangeBadge,
    shouldShowServiceFeeLabel,
    returnFlightModal,
    isChangingReturnFlight,
    isChangingEntireFlight,
  }
}
