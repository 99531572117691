import styled, { css } from 'styled-components'
import { Separator } from '@etta/ui/separator'

const rowCSS = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.div`
  padding: 14px 16px 0 16px;
  text-transform: uppercase;
  margin-bottom: -4px;
`

export const SeparatorBlock = styled(Separator)`
  margin: 16px 0 12px 0;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;

  > * + * {
    margin-top: 8px;
  }
`

export const ListItem = styled.li`
  ${rowCSS};
`

export const Total = styled.div`
  ${rowCSS};
`

export const CancelInfo = styled.div`
  padding-top: 12px;
  display: flex;
`
