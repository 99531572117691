import { ApolloClient, ApolloLink } from '@apollo/client'
import { Service } from '@etta/di'
import { createTimeoutHttpLink } from '@fiji/providers/apollo-client/timeout-http-link'
import { tokenProcessLink } from '@fiji/providers/apollo-client/token-process-link'
import { authLink } from '@fiji/providers/apollo-client/auth-link'
import { errorLink } from '@fiji/providers/apollo-client/error-link'
import { inMemoryCache } from '@etta/infra/services/apollo.service/apollo-cache-default'
import type { ApolloServiceType } from './types'

@Service()
export class ApolloService implements ApolloServiceType {
  createClient(url?: string) {
    const usedUrl = url || process.env.REACT_APP_GRAPHQL_SERVER_URL || '/app/graphql'

    const timeoutHttpLink = createTimeoutHttpLink({ url: usedUrl })

    return new ApolloClient({
      link: ApolloLink.from([tokenProcessLink, authLink, errorLink, timeoutHttpLink]),
      cache: inMemoryCache,
      connectToDevTools: process.env.NODE_ENV === 'development',
    })
  }
}
