import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { PaymentBlock } from '../payment-block'
import type { RentalCar as RentalCarType } from '../../../../types'

type Props = {
  rentalCar: RentalCarType
}

const i18nBase = 'PostBooking.TripDetails'

export function RentalCar({ rentalCar }: Props) {
  const { t } = useTranslation()
  const { title, vendorName, dropOff, pickUp, confirmation, payment } = rentalCar

  return (
    <>
      <Block marginBottom={24}>
        <Block marginBottom={4}>
          <Text variant="footnoteStrong" color="mainText">
            {[title, vendorName].filter(Boolean).join(' • ')}
          </Text>
        </Block>
        {pickUp && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(`${i18nBase}.PickUpLocation`, { location: pickUp })}
            </Text>
          </Block>
        )}
        {dropOff && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(`${i18nBase}.DropOffLocation`, { location: dropOff })}
            </Text>
          </Block>
        )}
        {confirmation && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {confirmation}
            </Text>
          </Block>
        )}
      </Block>
      <PaymentBlock payment={payment} />
    </>
  )
}
