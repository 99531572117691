import 'intersection-observer'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import { Spinner } from '../spinner'
import type { SpinnerVariant } from './types'
import { Container, SpinnerWrapper } from './infinite-scroll-styled'
import { List } from './list'

type Props = PropsWithChildren<{
  className?: string
  isLoading: boolean
  hasNextPage: boolean
  onFetchMore: () => void
  rootMargin?: string
  disabled?: boolean
  delayInMs?: number
  spinnerVariant?: SpinnerVariant
}>

type CompoundComponents = {
  List: typeof List
}

const InfiniteScrollForwardRef = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      isLoading,
      hasNextPage,
      onFetchMore,
      delayInMs,
      rootMargin,
      disabled,
      children,
      spinnerVariant = 'default',
    },
    ref,
  ) => {
    const [loaderRef] = useInfiniteScroll({
      loading: isLoading,
      hasNextPage,
      onLoadMore: onFetchMore,
      delayInMs,
      rootMargin,
      disabled,
    })

    return (
      <Container className={className} ref={ref}>
        {children}
        {hasNextPage && (
          <SpinnerWrapper ref={loaderRef} spinnerVariant={spinnerVariant}>
            <Spinner type="spinout" />
          </SpinnerWrapper>
        )}
      </Container>
    )
  },
)

export const InfiniteScroll = InfiniteScrollForwardRef as typeof InfiniteScrollForwardRef &
  CompoundComponents

InfiniteScroll.List = List
