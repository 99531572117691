import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentStatus } from '@etta/components/segment-status/segment-status'
import { Block } from '@etta/ui/block'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { TripType, Container } from '../../../../payment-summary-styled'
import { FeesAndTaxes } from '../../../fees-and-taxes'
import { ServiceFee } from '../../../service-fee'
import { DueTodayCost } from '../../../due-today-cost'
import { UnusedTicketRates } from '../../unused-ticket-rates'
import { FlightSegment } from '../../flight-segment'
import type { LayoutProps } from '../../types'
import { isOpenJawFlight } from '../shared'

const i18nBase = 'PaymentSummary.'
const MARGIN_MOBILE_DEFAULT = 28
const MARGIN_MOBILE_LEADING_NEW_SEGMENT = 24

export function FlightsMobileLayout({
  totalWithUnusedTicketRate,
  contentSlot,
  flightTripCost,
  isDesktopTripCostSummary,
  isFromPreviousBooking,
  segmentState,
  segments = [],
  unusedTicket,
  isLeadingNewSegment,
  allowUnusedTicketOptout,
  hasOptOutUnusedTickets,
  isHoldFlow,
}: LayoutProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const mobileDefaultMargin = isLeadingNewSegment
    ? MARGIN_MOBILE_LEADING_NEW_SEGMENT
    : MARGIN_MOBILE_DEFAULT
  const areRoundTripSegments = segments?.some((segment) => segment.isRoundTripLeg)
  const isOpenJaw = useMemo(() => isOpenJawFlight(segments), [segments])

  const shouldDisplayUnusedTicketBreakdown =
    !isFromPreviousBooking && featureFlags.isDesktopUnusedTicketsEnabled

  return (
    <Block marginTop={mobileDefaultMargin}>
      <Container>
        <TripType>
          <span data-tracking-id="flight-text">{t(i18nBase + 'Flight')}</span>
          <SegmentStatus
            segmentState={segmentState}
            isFromPreviousBooking={isFromPreviousBooking}
          />
        </TripType>

        {segments?.map((segment, index) => {
          if (!segment.netRate && areRoundTripSegments && !isOpenJaw) {
            return null
          }

          return <FlightSegment key={index} segment={segment} isMultiCityFlight={isOpenJaw} />
        })}
        <FeesAndTaxes feesAndTaxes={flightTripCost?.feesAndTaxes} />
        <ServiceFee serviceFee={flightTripCost?.serviceFee} />
        {shouldDisplayUnusedTicketBreakdown && (
          <UnusedTicketRates
            allowUnusedTicketOptout={allowUnusedTicketOptout}
            hasOptOutUnusedTickets={hasOptOutUnusedTickets}
            unusedTicket={unusedTicket}
          />
        )}
      </Container>

      <DueTodayCost
        isBorderVisible={!unusedTicket}
        rate={totalWithUnusedTicketRate}
        segmentState={segmentState}
        isPayingNow={!isHoldFlow}
        isDesktopTripCostSummary={isDesktopTripCostSummary}
      />

      {contentSlot}
    </Block>
  )
}
