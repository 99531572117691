import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { DeviationDescriptionContainer } from './flight-details-eco-check-modal-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}

export function FlightDetailsModalFullPercentDeviationDescription({
  deviationLevel,
  percentDeviation,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal.EmissionsNew'

  const description = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return '~' + t(i18nBase + '.AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return '-' + t(i18nBase + '.LessEmissions', { percentDeviation })
      case EmissionsDeviationLevel.More:
        return '+' + t(i18nBase + '.MoreEmissions', { percentDeviation })
    }
  }, [t, deviationLevel, percentDeviation])

  return (
    <DeviationDescriptionContainer aria-label={description}>
      <span dangerouslySetInnerHTML={{ __html: description }} />
    </DeviationDescriptionContainer>
  )
}
