import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import { CreditCardItem } from '@etta/components/credit-card-item/credit-card-item'
import type { CreditCard, VirtualPay } from '@fiji/graphql/types'
import type { ColorPalette } from '@fiji/types'
import type { CreditCardType } from '../types'
import { getCardValidator } from './get-card-validator'

type Props = {
  creditCard: CreditCard
  onEdit: (type: CreditCardType) => void
  mainValidator: any
  type: CreditCardType
  isCvvRequired?: boolean
  isCreditCardChangeable: boolean
  virtualPay?: VirtualPay
  cardBackgroundColor?: keyof ColorPalette
}

export function PaymentCreditCard({
  isCvvRequired,
  creditCard,
  onEdit,
  type,
  mainValidator,
  isCreditCardChangeable,
  virtualPay,
  cardBackgroundColor,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PaymentSummary.'

  const creditCardValidator = getCardValidator({
    type,
    mainValidator,
  })

  return (
    <CreditCardItem
      virtualPay={virtualPay}
      cardName={creditCard?.label || ''}
      cardNumber={creditCard?.cardNumber || ''}
      expirationDate={
        (creditCard?.expirationDate &&
          dateFormat(isoToDate(creditCard?.expirationDate), 'MM/yyyy')) ||
        ''
      }
      cardVendor={creditCard?.brandType}
      onChange={() => {}}
      onEdit={() => onEdit(type)}
      editLabel={t(i18nBase + 'ChangeCard')}
      layout="summary"
      cardInformationColor="bodyText1"
      cardBackgroundColor={cardBackgroundColor}
      validator={creditCardValidator}
      isEditable={isCreditCardChangeable}
      isCvvRequired={isCvvRequired}
      withPadding={false}
    />
  )
}
