import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapProvider } from '@etta/modules/display-configuration/core/enums'
import { useThemeConfigurationContext } from '@etta/modules/theme'
import type { EttaMapProps } from '../types'
import { useEttaMapContext } from '../../interface/use-etta-map.context'
import { libraries } from './const'

export const GoogleMap: FC<EttaMapProps> = observer(function GoogleMap({
  defaultZoom = 16,
  defaultCenter,
  center,
  onMapLoaded,
  unregisterOnUnmount,
  options = {},
  children,
}) {
  const { ettaMapStore, ettaMapActions } = useEttaMapContext()
  const { themeConfigurationStore } = useThemeConfigurationContext()
  const isDarkTheme = themeConfigurationStore.isDarkTheme

  const onGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    const ettaMap = ettaMapActions.createEttaMap({
      map,
      provider: MapProvider.GoogleMaps,
    })
    onMapLoaded?.(ettaMap)
  }

  if (!ettaMapStore.mapToken) {
    return null
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: ettaMapStore.mapToken,
        libraries,
      }}
      data-chromatic="ignore"
      yesIWantToUseGoogleMapApiInternals
      zoom={defaultZoom}
      defaultZoom={defaultZoom}
      center={center}
      defaultCenter={defaultCenter}
      shouldUnregisterMapOnUnmount={unregisterOnUnmount}
      onGoogleApiLoaded={onGoogleApiLoaded}
      options={ettaMapActions.buildGoogleMapOptions(options, isDarkTheme)}>
      {children}
    </GoogleMapReact>
  )
})
