import type { UserPreset } from '../types'

export const rcSustain: UserPreset[] = [
  {
    site: 'fijiweb',
    user: 'fijiweb',
    rcSession:
      '15497-F1D7155E60B3EB89A07FF55EB90E93A2-5D732741E3B3F6624A4984488448E63D79C22AA1EF0F96BE1626604167A79083CA1A9E0A2986B6235AF61231A981DCF7FA100EDEEDA6B6A219FEE55B48C9781A41F6F955A92C630CBAC613DD4E73D6F77C94A6252C1945A9E67972F5DC3BE65C0C230C57A45B78D3EC99A90DE38347BD037FA4009D66C104A4BD20E17B421DF4D96BA33DF8AEDF97F5D34E00E6A948E3D43CD7B2162E16FB0E925BE51414DF58C00706671A4648F499043C4EDCAD01124F14BB7BE97322462B49A103BBE4DFE7',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoxNzA0MzMxLCJkZWxlZ2F0ZWRJZCI6MCwiaW1wZXJzb25hdGVkSWQiOjAsImNvbXBhbnlJZCI6MTU2NTQwLCJwYXJ0bmVySWQiOjEsImlzQWRtaW5Vc2VyIjpmYWxzZSwidXNlcm5hbWUiOiJmaWppd2ViIiwiY3JlYXRlZFRpbWUiOjE2NjE3NjM3NDI5MTksInNlc3Npb25JZCI6ImVkZGI1N2Q4LTE2ZTYtNGZkMS1hZmQwLTU0OTA0Y2E2ZTU2MyIsInNlc3Npb25UaW1lb3V0IjoxODAwLCJjbHVzdGVyIjoiY2x1c3RlcmEiLCJsb2NhbGUiOiJlbi1VUyIsImdkc0lkIjoxMDEwMSwic2l0ZU5hbWUiOiJGaWppIFdlYiBUZXN0IFNpdGUiLCJpYXQiOjE2NjE3ODE3NjYsImV4cCI6MTk3NzM1Nzc2Nn0.2evcKvhN1iof5zdovWxoi1CEIe9eoYgPtzU6I-3TlYE',
  },
  {
    site: 'fijiwebmobi',
    user: 'fijiweb',
    rcSession:
      '15497-F9124EDBE78B674D8917359F41A0BD25-19772D38C4CA5353054D245DCAFDE7222CC8E6768A4699E56B8F1F9C2AD69DF38D5D18145D2395D7EA71FD73C4039D4C45C203991AB99E6EB245605E79AEFD1142A93E6443AD98AA04347AD6E45C1CBE4DFF6915998FF9743C17B4CE06BFDDE8DE99FFDD1FE9D755F10CB7FD97C40CB6E41F7B1B3BA954E60527FB282AA37856832A6EBBFA5C158E6A50C8FD41E83D805A5045230DD506A33F75F7ABAA4F27E03A2E0F19CA45DD0FAACA2E6F5E991BDC21B4CBFD56D0E9192026361B2465F2BE',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoxNzMxMjI2LCJkZWxlZ2F0ZWRJZCI6MCwiaW1wZXJzb25hdGVkSWQiOjAsImNvbXBhbnlJZCI6MTY3MDEwLCJwYXJ0bmVySWQiOjEsImlzQWRtaW5Vc2VyIjpmYWxzZSwidXNlcm5hbWUiOiJmaWppd2ViIiwiY3JlYXRlZFRpbWUiOjE2NjE3NDU0MjE1MDEsInNlc3Npb25JZCI6ImM4MjM3ZmE5LWJmZjktNGJjYS05ZTA2LWRjMjZjMDhiZjY0MiIsInNlc3Npb25UaW1lb3V0IjoxODAwLCJjbHVzdGVyIjoiY2x1c3RlcmEiLCJsb2NhbGUiOiJlbi1VUyIsImdkc0lkIjoxMDEwMSwic2l0ZU5hbWUiOiJBcnR1ciAtIE1PQkkiLCJpYXQiOjE2NjE3ODE2ODQsImV4cCI6MTk3NzM1NzY4NH0.eBpsQHL3MnkJCXxw0SLhKIohxUA69ntOKqlf-BtcoQg',
  },
  {
    site: 'fijiphx',
    user: 'fijiweb',
    rcSession:
      '15497-2EF3C3B56789361ABDAC98211B27D8F3-BA2442736CACE6557103DE3238DFA1529A56B8FD8D1179114D535C2380AD24AF93ED1DA1AD6D6035CDA4BA8B4A559F70989BD1C0661F9469F39AB1A3F19DBC20109C4BEFC27DCE647A18365E2F42367B46BB9BC2097F393E5864A78E3FD706D8119691F89C65F92CE32EA19C31EC6591DE8D83CE73DD9E5E79A4BE660E04AB5FCF51AE938A7A43EB5EB50C003453BB16FC03D48ECFFFE97DF232045ABA0A9FA4C49BED582278EE64492CE3E3C689F9A478D7E820E158EA0DA3FD345B806ED71D',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoxNzI0NzAxLCJkZWxlZ2F0ZWRJZCI6MCwiaW1wZXJzb25hdGVkSWQiOjAsImNvbXBhbnlJZCI6MTY0MjA1LCJwYXJ0bmVySWQiOjEsImlzQWRtaW5Vc2VyIjpmYWxzZSwidXNlcm5hbWUiOiJmaWppd2ViIiwiY3JlYXRlZFRpbWUiOjE2NjE3NTg5Mjk1NzMsInNlc3Npb25JZCI6ImExYTFjNjlmLTVmMDctNGJjYS04M2Y1LTg4ZGUwNzhjZDc3YyIsInNlc3Npb25UaW1lb3V0IjoxODAwLCJjbHVzdGVyIjoiY2x1c3RlcmEiLCJsb2NhbGUiOiJlbi1VUyIsImdkc0lkIjoxMDEwMSwic2l0ZU5hbWUiOiJwaG9lbml4IGZpamkgdGVzdCIsImlhdCI6MTY2MTc4MTcyMywiZXhwIjoxOTc3MzU3NzIzfQ.LXmciJFMW3jL8YI9nlJbACW7YQ4Xfti5rtHARsxIkDo',
  },
]
