import type { CreditCardType } from '../types'

type Args = {
  mainValidator: any
  type: CreditCardType
}

function getSingleValidator(mainValidator: any, fieldName: string) {
  return {
    errors: {
      securityCode: mainValidator.errors[fieldName],
    },
    values: {
      securityCode: mainValidator.values[fieldName],
    },
    onFieldChange: (_: string) => (value: string) => mainValidator.onFieldChange(fieldName)(value),
  }
}

export function getCardValidator({ type, mainValidator }: Args) {
  switch (type) {
    case 'flight':
      return getSingleValidator(mainValidator, 'securityCodeFlight')
    case 'car':
      return getSingleValidator(mainValidator, 'securityCodeCar')
    case 'hotel':
      return getSingleValidator(mainValidator, 'securityCodeHotel')
  }
}
