import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { Container } from './rules-modal-mobile-styled'

export function RulesModalMobile({ carRules }: LayoutProps) {
  return (
    <Container>
      {carRules.map((rule, index: number) => {
        if (!rule || !rule.value) {
          return null
        }

        return (
          <Block key={index} marginTop={24}>
            <Text textTransform="uppercase" variant="body" color="mainText">
              {rule.header}
            </Text>
            <Block marginTop={8}>
              <div>{rule.value}</div>
            </Block>
          </Block>
        )
      })}
    </Container>
  )
}
