import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { MediaLayout } from '@etta/ui/media-layout'
import { CovidInformationStatisticLayoutMobile } from './mobile'
import { CovidInformationStatisticLayoutDesktop } from './desktop'
import type { LayoutProps } from './types'

export function CovidInformationStatisticLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<CovidInformationStatisticLayoutMobile {...props} />}
      desktopSlot={<CovidInformationStatisticLayoutDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
