import { useTranslation } from 'react-i18next'
import { StyledTextVariant, TimeRangeOption } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { Slider } from '@etta/ui/slider'
import { Button } from '@etta/ui/button'
import type { Props } from '../types'
import {
  AirportTitle,
  Container,
  TabLabel,
  TimeSelectorContainer,
  InputContainer,
  SliderContainer,
  Radio,
} from './time-tab-mobile-styled'

const i18Base = 'FlightSearch.TimePicker'

export function TimeTabMobile({
  actionLabel,
  selectedTime,
  onSubmit,
  convertTimeLabel,
  customTimeValue,
  onCustomTimeChange,
  onRadioButtonClick,
  timeOptions,
  title,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <TabLabel textVariant={StyledTextVariant.body} aria-label={actionLabel}>
        {actionLabel}
      </TabLabel>

      <AirportTitle textVariant={StyledTextVariant.headline} aria-label={title}>
        {title}
      </AirportTitle>

      <TimeSelectorContainer>
        {timeOptions.map((time) => {
          const timeLabel = convertTimeLabel(time)

          return (
            <InputContainer
              key={time.id}
              isCustomTimeSelected={
                selectedTime.id === TimeRangeOption.CustomTime &&
                time.id === TimeRangeOption.CustomTime
              }>
              <Radio
                key={time.id}
                aria-label={timeLabel}
                isChecked={time.id === selectedTime.id}
                onChange={() => onRadioButtonClick(time)}>
                {timeLabel}
              </Radio>
              {selectedTime.id === TimeRangeOption.CustomTime &&
                time.id === TimeRangeOption.CustomTime && (
                  <SliderContainer>
                    <Slider
                      onChange={onCustomTimeChange}
                      value={customTimeValue}
                      min={0}
                      max={23}
                      marks={[0, 23]}
                      markTitleType="time"
                    />
                  </SliderContainer>
                )}
            </InputContainer>
          )
        })}
      </TimeSelectorContainer>

      <Block marginTop="auto" marginBottom={16} marginHorizontal={16}>
        <Button onClick={() => onSubmit(selectedTime)} fullWidth>
          {t(i18Base + '.ConfirmButton')}
        </Button>
      </Block>
    </Container>
  )
}
