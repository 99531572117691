/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { cleanupWithRedirectToLogin } from '@fiji/providers/auth-provider/cleanup-with-redirect-to-login'
import { useUserLogin } from '../use-user-login'

export function useFijiAuth() {
  const { isAuthorized, isLoading } = useUserLogin({
    isForceLoad: true,
    isCleanTokenBeforeLogin: true,
  })

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isAuthorized) {
      cleanupWithRedirectToLogin()
    }
  }, [isLoading, isAuthorized])

  return {
    isLoading,
    isAuthorized,
  }
}
