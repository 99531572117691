import type { HotelFragment, SegmentsFragment } from '@fiji/graphql/hooks'
import { SegmentType } from '@etta/core/enums'
import { PhotoSize } from '@fiji/graphql/types'
import type {
  CarRentalPostBookingValueObject,
  CarServicePostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
} from '../../../core/value-objects'
import { TripStatus } from '../../../core/enums'
import type { TripSegmentMapResult } from './types'

const HOTEL_SOURCE_GDS_NAMES = ['AMADEUS', 'APOLLO', 'GALILEO', 'SABRE', 'WORLDSPAN'] // get this from gazoo code

export class SegmentMapper {
  static toTripSegments(segments: SegmentsFragment[]): TripSegmentMapResult {
    return segments.reduce<TripSegmentMapResult>(
      (acc, segment) => {
        const { carRental, carService, flightLeg, hotel, rideHail, trainLeg, position } = segment

        if (carRental) {
          const mappedCarRental: CarRentalPostBookingValueObject = {
            ...carRental,
            position,
            type: SegmentType.CarRental,
          }
          if (carRental.status === TripStatus.Unconfirmed || !carRental.recordLocator) {
            acc.failedSegments.push(mappedCarRental)
            return acc
          }
          acc.segments.push(mappedCarRental)
          return acc
        }

        if (carService) {
          const mappedCarService: CarServicePostBookingValueObject = {
            ...carService,
            position,
            type: SegmentType.CarService,
          }
          if (!carService.recordLocator) {
            acc.failedSegments.push(mappedCarService)
            return acc
          }
          acc.segments.push(mappedCarService)
          return acc
        }

        if (flightLeg) {
          const mappedFlight: FlightPostBookingValueObject = {
            ...flightLeg,
            position,
            type: SegmentType.Flight,
          }
          if (!mappedFlight.segments?.length) {
            acc.failedSegments.push(mappedFlight)
            return acc
          }
          for (const subSegemnt of mappedFlight.segments) {
            if (
              subSegemnt.status === TripStatus.Unconfirmed ||
              (subSegemnt.bookingProvider?.includes('GDS_') && !mappedFlight.recordLocator)
            ) {
              acc.failedSegments.push(mappedFlight)
              return acc
            }
          }
          acc.segments.push(mappedFlight)
          return acc
        }

        if (hotel) {
          const isHotelValid = this.isHotelValid(hotel)
          const mappedHotel: HotelPostBookingValueObject = {
            ...hotel,
            position,
            type: SegmentType.Hotel,
            photos: hotel.photos?.filter((item) => item.size === PhotoSize.Large),
          }
          if (isHotelValid) {
            acc.segments.push(mappedHotel)
            return acc
          }
          acc.failedSegments.push(mappedHotel)
          return acc
        }
        if (rideHail) {
          acc.segments.push({ ...rideHail, position, type: SegmentType.RideHail })
          return acc
        }
        if (trainLeg) {
          acc.segments.push({ ...trainLeg, position, type: SegmentType.Train })
          return acc
        }

        return acc
      },
      {
        segments: [],
        failedSegments: [],
      },
    )
  }

  private static isHotelValid(segment: HotelFragment): boolean {
    if (segment.status === TripStatus.Unconfirmed) {
      return false
    }

    // if booked through GDS, record locator and status are required
    if (
      HOTEL_SOURCE_GDS_NAMES.includes(segment.source ?? '') &&
      (!segment.recordLocator || !segment.status)
    ) {
      return false
    }

    if (segment.status === TripStatus.ActionRequired) {
      return !!segment.confirmationNumber
    }

    return true
  }
}
