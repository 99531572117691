import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { TripStateStatus } from './types'
import { useTripStatus } from './use-trip-status'
import { TripStateDetails } from './trip-state-details'

type Props = {
  tripStateStatus: TripStateStatus
  onClick?: () => void
  soldOutDescription?: string
}

export function TripStatusModal({ tripStateStatus, onClick, soldOutDescription }: Props) {
  const { title, viewState } = useTripStatus(tripStateStatus)

  return (
    <ViewStateModal viewState={viewState} onDone={onClick} title={title}>
      <TripStateDetails
        tripStateStatus={tripStateStatus}
        onClick={onClick}
        soldOutDescription={soldOutDescription}
      />
    </ViewStateModal>
  )
}
