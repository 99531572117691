import { lazy } from 'react'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

const AirSearchPage = lazy(() => import('@etta/screens/air-search-page/air-search-page'))
const AirSearchResultPage = lazy(() => import('@etta/screens/air-search-result-page'))

const DECIMAL = 0.1

const getLegRoutes = ({
  startTransitionIndex,
  maxFlightLegs,
}: {
  startTransitionIndex: number
  maxFlightLegs: number
}) => {
  const legRoutes: RouteItem[] = []
  for (let legNumber = 0; legNumber < maxFlightLegs; legNumber++) {
    const transitionIndex = startTransitionIndex + legNumber * DECIMAL
    legRoutes.push({
      route: {
        path: ROUTES.air.results({ legNumber }),
        exact: true,
      },
      documentTitle: getDocumentTitle('Air Search Results'),
      transitionIndex,
      transitionGroup: 'slide',
      component: () => <AirSearchResultPage legNumber={legNumber} />,
    })
  }

  return legRoutes
}

export const airRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.air.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Air Search'),
    transitionIndex: 3,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <AirSearchPage />,
  },
  ...getLegRoutes({
    startTransitionIndex: 3.1,
    maxFlightLegs: 6,
  }),
]
