import type { CSSProperties } from 'react'
import { useRef, useState, useCallback, useEffect } from 'react'

type Props = {
  isOpen: boolean
}

const HEIGHT_CLOSED = '0px'
export const ANIMATION_DURATION = 300

export function useScoreList({ isOpen }: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const animationFrameRef = useRef<number | null>(null)
  const isOpenRef = useRef(isOpen)
  const [style, setStyle] = useState<CSSProperties>({
    height: isOpen ? 'auto' : HEIGHT_CLOSED,
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
    transition: `margin-top ${ANIMATION_DURATION}ms linear, height ${ANIMATION_DURATION}ms linear, opacity ${ANIMATION_DURATION}ms linear, visibility ${ANIMATION_DURATION}ms linear`,
    visibility: isOpen ? 'visible' : 'hidden',
  })
  const showAnimation = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }

    setStyle((state) => ({
      ...state,
      height: `${container.scrollHeight}px`,
      opacity: 1,
      visibility: 'visible',
    }))
  }, [])
  const hideAnimation = useCallback(() => {
    const container = containerRef.current
    if (!container) {
      return
    }

    animationFrameRef.current = window.requestAnimationFrame(() => {
      setStyle((state) => ({
        ...state,
        height: `${container.scrollHeight}px`,
      }))

      animationFrameRef.current = window.requestAnimationFrame(() => {
        setStyle((state) => ({ ...state, height: HEIGHT_CLOSED, opacity: 0, visibility: 'hidden' }))

        animationFrameRef.current = null
      })
    })
  }, [])

  useEffect(() => {
    isOpenRef.current = isOpen

    if (isOpen) {
      showAnimation()
    } else {
      hideAnimation()
    }

    return () => {
      const animationFrame = animationFrameRef.current

      if (animationFrame) {
        window.cancelAnimationFrame(animationFrame)

        animationFrameRef.current = null
      }
    }
  }, [isOpen, showAnimation, hideAnimation])

  return { contentAnimation: { ref: containerRef, style } }
}
