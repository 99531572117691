import { Action, Inject } from '@etta/di'
import { NavigationService } from '@etta/modules/navigation/interface/services/navigation.service'
import { UserStore } from '@etta/modules/user'

@Action()
export class EttaGoAction {
  constructor(
    @Inject()
    private navigationService: NavigationService,
    @Inject()
    private userStore: UserStore,
  ) {}

  async handleSetRcSessionCookieForAdminPanel() {
    const profile = this.userStore.profile
    const url = profile?.site?.url || ''

    if (!url) {
      throw new Error('Site url does not exist')
    }

    await this.navigationService.handleSetRcSessionCookie(url, true)
  }
}
