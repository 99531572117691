import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '@etta/ui/error-container'
import { LazyContent } from '@etta/ui/lazy-content'
import { Swap } from '@etta/ui/swap'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import { useFareRules } from './use-fare-rules'
import { FareRulesLayout } from './layout'

type Props = {
  onClose: () => void
  segmentId?: string
  itineraryId: string
  airlineLogo?: string
  airlineName?: string
  fares?: Array<string>
  bookingCodes?: Array<string>
}

export function FareRulesContent({
  onClose,
  segmentId,
  itineraryId,
  airlineLogo,
  airlineName,
  fares,
  bookingCodes,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FareRules'

  const segmentsBasis = fares?.join(', ')
  const codes = bookingCodes?.join(', ')

  const { flightFareRules, isLoading, isError, onReload } = useFareRules({
    itineraryId,
    flightSegmentId: segmentId!,
  })

  return (
    <>
      <InfoModal.Header title={t(`${i18nBase}.Title`)} onClose={onClose} />
      <Swap is={!!isError} isSlot={<ErrorContainer onReload={onReload} />}>
        <LazyContent isLoading={isLoading}>
          <InfoModal.Content>
            <FareRulesLayout
              flightFareRules={flightFareRules}
              airlineLogo={airlineLogo}
              airlineName={airlineName}
              segmentsBasis={segmentsBasis}
              bookingCodes={codes}
            />
          </InfoModal.Content>
        </LazyContent>
      </Swap>
    </>
  )
}
