import { useMemo } from 'react'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { PlainTime, ColorPalette } from '@fiji/types'
import { TripStatus } from '@fiji/graphql/types'
import { useTraveler } from '../traveler/use-traveler'
import { useActionButtons } from './use-action-buttons'

type Props = {
  pickUpDateTime: string | null
  pickUpAddress?: string | null
  productName?: string | null
  bookingId: string | null
  description?: string | null
  tripStatus?: TripStatus | null
}

export function useTripRideHailContent({
  pickUpDateTime,
  productName,
  bookingId,
  description,
  pickUpAddress,
  tripStatus,
}: Props) {
  const { travelerFullName } = useTraveler({ tripStatus })
  const actionButtons = useActionButtons({
    travelerFullName,
    rideHail: {
      description,
      productName,
      bookingId: bookingId ?? '',
      pickUpTime: pickUpDateTime,
      pickUpAddress,
    },
  })

  const { pickUpDate, pickUpTime } = useMemo(() => {
    const pickUpDate = pickUpDateTime ? new Date(deleteTimezone(pickUpDateTime)) : new Date()
    const pickUpTime: PlainTime = {
      hours: pickUpDate.getHours() ?? 0,
      minutes: pickUpDate.getMinutes() ?? 0,
    }
    return {
      pickUpDate,
      pickUpTime,
    }
  }, [pickUpDateTime])

  const headerBackgroundColor = useMemo<keyof ColorPalette>(() => {
    switch (tripStatus) {
      case TripStatus.Cancelled:
        return 'background2'
      default:
        return 'plum'
    }
  }, [tripStatus])

  return { pickUpDate, pickUpTime, headerBackgroundColor, actionButtons }
}
