import type { GraphQLError } from 'graphql'
import type { InvalidTravelErrorDetailInfo } from '@fiji/types'

type LastPermittedDateErrorData = {
  isLastPermittedDateError: boolean
  lastPermittedDateErrorDate?: string
}

type ErrorParameterName = 'lastPermittedDate'

export function getLastPermittedDateErrorData(
  errors: Readonly<GraphQLError[]>,
  errorParameterName: ErrorParameterName,
): LastPermittedDateErrorData {
  const isLastPermittedDateError = !!errors.some((err) => err?.extensions?.isLastPermittedDateError)

  if (!errors.length) {
    return {
      isLastPermittedDateError,
      lastPermittedDateErrorDate: '',
    }
  }

  const errorDetails = errors[0].extensions?.details as InvalidTravelErrorDetailInfo
  const lastPermittedDateErrorDate =
    isLastPermittedDateError && errorDetails && errorDetails.errorDetail
      ? errorDetails.errorDetail[0].parameter?.find((item) => item.name === errorParameterName)
          ?.value
      : ''

  return {
    isLastPermittedDateError,
    lastPermittedDateErrorDate,
  }
}
