import type { AnyAction } from 'redux'
import { Service } from '@etta/di'
import { store } from '@fiji/store'

@Service()
export class FijiStoreProxyService {
  dispatch(middleWare: AnyAction) {
    store.dispatch(middleWare)
  }
}
