import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import type { PriceSkin } from './types'

export const PriceWrapper = styled.div<{ skin: PriceSkin }>`
  text-align: right;
  display: flex;
  flex-direction: ${(p) => (p.skin === 'row' ? 'row' : 'column')};
  align-items: ${(p) => (p.skin === 'row' ? 'center' : 'end')};

  > span:last-child {
    margin-left: 2px;
  }
`

const styledText = styled(Text)<{ skin: PriceSkin }>``

export const SoldOutText = styled(styledText).attrs<{ skin: PriceSkin }>((p) => {
  switch (p.skin) {
    case 'column':
      return {
        variant: 'captionStrong',
        color: 'bodyText1',
      }
    case 'row':
    default:
      return {
        variant: 'buttonSmall',
        color: 'bodyText',
      }
  }
})`
  ${(p) => {
    switch (p.skin) {
      case 'column':
        return css`
          background-color: ${(p) => p.theme.colors.background4};
          padding: 4px 12px;
          border-radius: 18px;
          text-transform: uppercase;
        `
    }
  }}
`
