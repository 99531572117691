import styled, { css } from 'styled-components'

export const ListHeader = styled.div<{ isBorder: boolean; isInlineView: boolean }>`
  height: 50px;
  display: flex;
  justify-content: ${(props) => (props.isInlineView ? 'flex-start' : 'space-between')};
  align-items: center;
  padding: 0 16px;
  border-bottom: ${(p) => (p.isBorder ? `1px solid ${p.theme.colors.borderLight}` : '')};

  ${(props) =>
    props.isInlineView &&
    css`
      gap: 10px;
    `}
`

export const ListHeaderRightSide = styled.div`
  margin-left: 0;
  display: flex;
  align-items: baseline;
  gap: 10px;
`
