import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useUserContext } from '@etta/modules/user'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { SegmentType } from '@fiji/graphql/types'
import { HeaderBlockLayout } from './layout'
import { getTitle } from './get-title'
import type { Props } from './types'

const i18nBase = 'PurchaseReservationSuccess.'

export function HeaderBlock({
  isPostBooking,
  onShareTripsDetail,
  isRailEticket,
  isTripLoading,
  pnrNumber,
  isTripOnHold,
  autoCancellationTime,
}: Props) {
  const { reviewTripStore } = useReviewTripContext()
  const { isShowDelegateName: isShowTravelerName } = useShowDelegateName()
  const { userStore } = useUserContext()
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const { isGuest } = userStore
  const { trip, segments } = reviewTripStore
  const tripName = trip?.tripInfo.name
  const { postBookingTripStore } = usePostBookingContext()

  const hasRailSegment = segments.some((segment) => segment.type === SegmentType.Train)

  const title = getTitle({
    tripName,
    travelerFullName: postBookingTripStore.travelerNames?.travelerFullName ?? '',
    isGuest,
    isPostBooking,
    isShowTravelerName,
    isTripOnHold,
  })

  const railSubtitleText = isRailEticket
    ? t(i18nBase + 'RailEticketSubtitle')
    : t(i18nBase + 'CollectSubtitle')

  const regularSubtitleText = isTripOnHold
    ? t(i18nBase + 'HoldTripSubtitle')
    : t(i18nBase + 'Subtitle')

  const subtitleText =
    hasRailSegment && featureFlags.isRailAccreditationEnabled
      ? railSubtitleText
      : regularSubtitleText

  return (
    <HeaderBlockLayout
      tripName={tripName}
      isTripLoading={isTripLoading}
      pnrNumber={pnrNumber}
      title={title}
      subtitleText={subtitleText}
      isTripOnHold={isTripOnHold}
      autoCancellationTime={autoCancellationTime}
      onShareTripsDetail={onShareTripsDetail}
    />
  )
}
