import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { Block } from '@etta/ui/block'
import type { SelectedDateTitleProps } from '../../../selected-date-title'
import { SelectedDateTitleContainerDesktop, Title } from './selected-date-title-desktop-styled'

export function SelectedDateTitleDesktop({ dateTitle }: SelectedDateTitleProps) {
  return (
    <SelectedDateTitleContainerDesktop>
      <Block marginRight={4}>
        <PWAIcon name="calendarPWA" />
      </Block>
      <Title>{dateTitle}</Title>
    </SelectedDateTitleContainerDesktop>
  )
}
