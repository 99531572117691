import type {
  ClearDomainRcSessionCookieMutation,
  ClearMeetingInfoMutation,
  ClearSiteRcSessionCookieMutation,
  LogoutMutation,
  UnregisterClientFromNotificationMutation,
} from '@fiji/graphql/hooks'
import {
  ClearSiteRcSessionCookieDocument,
  UnregisterClientFromNotificationDocument,
  ClearDomainRcSessionCookieDocument,
  LogoutDocument,
  ClearMeetingInfoDocument,
} from '@fiji/graphql/hooks'
import { NotificationClientIdStore } from '@etta/worker/firebase'
import { Container } from '@etta/di'
import { ApolloService } from '@etta/infra/services/apollo.service/apollo.service'
import { cleanupWithRedirectToLogin } from './cleanup-with-redirect-to-login'

type Props = {
  apolloClientUrl: string
  isCustomLogoutRedirectEnabled?: boolean
  logoutSsoUrl?: string
  companyId?: number
}

const GAZOO_LOGOUT_ENDPOINT = '/rc/login/logout.do'

async function handleLogoutFromGazoo() {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  const url = window.location.origin + GAZOO_LOGOUT_ENDPOINT

  try {
    await fetch(url, {
      method: 'GET',
      credentials: 'include',
      redirect: 'manual',
    })
  } catch (e) {}
}

export async function logOut({
  apolloClientUrl,
  isCustomLogoutRedirectEnabled = false,
  companyId,
  logoutSsoUrl,
}: Props) {
  const instance = Container.get(ApolloService)
  const client = instance.createClient(apolloClientUrl)

  const clientId = NotificationClientIdStore.get()

  try {
    await handleLogoutFromGazoo()

    await Promise.all([
      // GQL layer nukes the cookies from the server and on the client
      client.mutate<LogoutMutation>({
        mutation: LogoutDocument,
      }),
      // TODO: Remove upon new deem migration complete
      client.mutate<ClearSiteRcSessionCookieMutation>({
        mutation: ClearSiteRcSessionCookieDocument,
      }),
      // TODO: Remove upon new deem migration complete
      client.mutate<ClearDomainRcSessionCookieMutation>({
        mutation: ClearDomainRcSessionCookieDocument,
      }),
      client.mutate<ClearMeetingInfoMutation>({
        mutation: ClearMeetingInfoDocument,
      }),
    ])
  } catch {
  } finally {
    if (clientId) {
      await client.mutate<UnregisterClientFromNotificationMutation>({
        mutation: UnregisterClientFromNotificationDocument,
        variables: { clientId },
      })
    }

    cleanupWithRedirectToLogin({
      isCustomLogoutRedirectEnabled,
      companyId,
      logoutSsoUrl,
    })
  }
}
