import type { SearchRideHailRequest } from '@fiji/graphql/hooks'
import type { SearchRideHailArgs } from '../types'
import { buildRideHailFlightDetails } from './ride-hail-flight-details.builder'

export function buildRideHailSearchRequest(args: SearchRideHailArgs): SearchRideHailRequest {
  return {
    pickUp: args.pickUp,
    dropOff: args.dropOff,
    pickUpDate: args.pickUpDate,
    pickUpTime: args.pickUpTime,
    arrivalFlightDetails: args.flight && buildRideHailFlightDetails(args.flight),
  }
}
