import i18n from 'i18next'
import type { ViewState } from '../types'

type Args = {
  title?: string
  viewState: ViewState
}

export function getTitle({ viewState, title }: Args) {
  if (title) {
    return title
  }
  switch (viewState) {
    case 'loading':
      return i18n.t('ViewStateModal.Loading')
    case 'saving':
      return i18n.t('ViewStateModal.Saving')
    case 'check-availability':
      return i18n.t('ViewStateModal.CheckingAvailability')
    case 'downloading':
      return i18n.t('ViewStateModal.Downloading')
    default:
      return undefined
  }
}
