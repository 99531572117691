import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { ActionType } from '../../core/constants'

@Store()
export class MultipleHotelProvidersStore {
  actionType: null | ActionType = null
  providerName: null | string = null
  multipleHotelProvidersRTPDialog = new Toggle()
  multipleHotelProvidersTripDialog = new Toggle()
  multipleHotelProvidersSearchDialog = new Toggle()

  setActionType(actionType: ActionType) {
    this.actionType = actionType
  }

  setProviderName(providerName: string) {
    this.providerName = providerName
  }

  dropStore() {
    this.actionType = null
    this.providerName = null
    this.multipleHotelProvidersRTPDialog = new Toggle()
    this.multipleHotelProvidersTripDialog = new Toggle()
    this.multipleHotelProvidersSearchDialog = new Toggle()
  }
}
