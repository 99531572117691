import { useTranslation } from 'react-i18next'
import type { MeetingInfoSummaryValueObject } from '@etta/modules/meeting/core/value-objects/meeting-info-summary.value-object'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { dateFormat, isoToDate, deleteTimezone } from '@fiji/utils/dates'

export type MeetingInformationRemarkProps = {
  meeting: MeetingInfoSummaryValueObject
}

const i18nBase = 'Meeting.MeetingInformation'

export const MeetingInformationRemark = ({ meeting }: MeetingInformationRemarkProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Block marginBottom={4}>
        <Text variant="subHeadStrong" color="mainText">
          {meeting.location}
        </Text>
        <Text variant="subHeadMedium" color="bodyText">
          &nbsp;{t(i18nBase + '.Between')}&nbsp;
        </Text>
        <Text variant="subHeadStrong" color="mainText">
          {t(i18nBase + '.Dates', {
            start: dateFormat(isoToDate(deleteTimezone(meeting.dates.startDate)), 'MMM d'),
            end: dateFormat(isoToDate(deleteTimezone(meeting.dates.endDate)), 'MMM d'),
          })}
        </Text>
      </Block>
      <Text variant="subHeadMedium" color="bodyText">
        {t(i18nBase + '.PolicyNote')}
      </Text>
    </>
  )
}
