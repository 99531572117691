import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { ROUTES } from '@fiji/routes'
import { useLoadDelegates } from '@fiji/hooks/delegates/use-load-delegates'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useResetDelegateActiveBookingFlow } from '@fiji/hooks/delegates/use-reset-delegate-active-booking-flow'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import { useSettingsContext } from '@etta/modules/settings/interface/use-settings-context'
import { useUserContext } from '@etta/modules/user'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { StepName } from '@etta/screens/checkout-page/types'
import { getDelegateGuestTitle } from './get-delegate-guest-title'

const ORIGINAL_CUSTOMER_ID = 0

// TODO: place everything on the new architecture.
export function useDelegateSwitch() {
  const { delegateService: delegateSettingsService } = useSettingsContext()

  const {
    delegatedUsers,
    delegatedUsersWithoutCustomer,
    isDelegatedUsersLoading,
    currentDelegatedUser,
  } = useLoadDelegates(
    delegateSettingsService.authorizers.map((authorizer) => ({
      userId: String(authorizer.id),
      firstName: authorizer.firstName,
      lastName: authorizer.lastName,
      email: authorizer.email,
    })),
  )

  const { pathname } = useLocation()
  const { userStore } = useUserContext()
  const { onReset, handleRedirect } = useResetDelegateActiveBookingFlow()
  const { customerId, delegatedId, impersonatedId, profile } = userStore
  const { onChangeViewState, viewState } = useViewState()
  const { delegateStore, delegateActions } = useDelegateContext()
  const { postBookingTripActions } = usePostBookingContext()
  const {
    exitModalToggle,
    delegateModalToggle,
    isDelegateGuestUser,
    isUserInfoUpdating,
  } = delegateStore
  const { dropDelegateState } = delegateActions
  const { guestUserNames } = userStore

  useEffect(() => {
    return dropDelegateState
  }, [dropDelegateState])

  const getIdForDelegateUpdate = (id: number) => {
    if (impersonatedId !== 0 && id === impersonatedId) {
      // a delegater is impersonated
      return ORIGINAL_CUSTOMER_ID
    }

    if (id !== customerId) {
      return id
    }
    return ORIGINAL_CUSTOMER_ID
  }

  const handleExitDelegateMode = () => delegateActions.handleSelectDelegate(ORIGINAL_CUSTOMER_ID)

  const handleSelectDelegate = async (id: number) => {
    const isCheckoutFlow = Object.values(StepName).some((stepName) => pathname.includes(stepName))
    const newId = getIdForDelegateUpdate(id)
    if (isCheckoutFlow) {
      delegateActions.setDelegateId(newId)
    } else {
      await delegateActions.handleSelectDelegate(newId)
      if (pathname.includes(ROUTES.postBooking.trip({ id: '' }))) {
        postBookingTripActions.dropTripStore()
        handleRedirect()
      }
      onReset()
    }
  }

  const handleSelectDelegateMobile = async (id: number) => {
    if (id === delegatedId) {
      return
    }
    delegateModalToggle.handleClose()
    try {
      await handleSelectDelegate(id)
    } catch {
      onChangeViewState('custom')
    }
  }

  const handleSelectDelegateDesktop = async (id: number) => {
    if (id === delegatedId) {
      return
    }
    try {
      await handleSelectDelegate(id)
      delegateModalToggle.handleClose()
    } catch {}
  }

  const handleDelegateItemClick = () => {
    if (!isDelegatesLoading) {
      delegateModalToggle.handleOpen()
    }
  }

  const handleExitFromDelegateMode = async () => {
    delegateActions.setDelegateId(ORIGINAL_CUSTOMER_ID)
    await handleExitDelegateMode()
    onReset()
    handleRedirect()
    exitModalToggle.handleClose()
  }

  const handleCloseSwitchErrorModal = () => {
    onChangeViewState('hidden')
  }

  const isDelegatesLoading = isDelegatedUsersLoading || isUserInfoUpdating
  const isCustomerSelected = delegatedId === ORIGINAL_CUSTOMER_ID
  const isDelegatesExist = !!customerId && !!delegatedUsers.length
  const isUserInDelegatedMode = !!delegatedId

  const firstName = isCustomerSelected ? profile?.firstName : currentDelegatedUser?.firstName
  const lastName = isCustomerSelected ? profile?.lastName : currentDelegatedUser?.lastName
  const email = isCustomerSelected ? profile?.personalContact?.email : currentDelegatedUser?.email
  const userId = isCustomerSelected ? customerId?.toString() : currentDelegatedUser?.userId
  const fullName = [firstName, lastName].join(' ')
  const delegateGuestTitle = getDelegateGuestTitle({
    delegateName: fullName,
    guestFullName: guestUserNames.fullName,
    isDelegateGuestUser,
  })

  const delegate = { firstName, lastName, email, userId }

  return {
    delegatedUsers,
    delegatedUsersWithoutCustomer,
    customerId,
    selectedDelegate: delegate,
    delegateModalToggle,
    exitModalToggle,

    isDelegatesLoading,
    isUserInDelegatedMode,
    isUserInfoUpdating,

    fullName,
    delegateGuestTitle,
    handleCloseSwitchErrorModal,
    handleDelegateItemClick,
    handleSelectDelegateMobile,
    handleSelectDelegateDesktop,
    handleExitFromDelegateMode,

    isDelegatesExist,
    viewState,
  }
}
