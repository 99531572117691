import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate, TripCostSummary } from '@fiji/graphql/types'
import { useTripCost } from '@etta/screens/payment-summary/use-payment-summary/use-trip-cost'
import { hasTripCost } from './has-trip-cost'

type Args = {
  tripCost: TripCostSummary
}

export function useTripCostSummary({ tripCost }: Args) {
  const {
    carRentalTripCost,
    flightTripCost,
    railTripCost,
    isHotelPayingNow,
    isHotelPayingLater,
    isCarRentalPayingNow,
    isCarRentalPayingLater,
    payNowHotelTripCost,
    payLaterHotelTripCost,
    payNowCarRentalTripCost,
    payLaterCarRentalTripCost,
  } = useTripCost(tripCost)

  const hasFlightTripCost = hasTripCost({ tripCost: flightTripCost })
  const hasCarRentalTripCost = hasTripCost({ tripCost: carRentalTripCost })
  const hasNowHotelTripCost = hasTripCost({ tripCost: payNowHotelTripCost })
  const hasLaterHotelTripCost = hasTripCost({ tripCost: payLaterHotelTripCost })
  const hasNowCarRentalTripCost = hasTripCost({ tripCost: payNowCarRentalTripCost })
  const hasLaterCarRentalTripCost = hasTripCost({ tripCost: payLaterCarRentalTripCost })
  const hasRailTripCost = hasTripCost({ tripCost: railTripCost })

  const isPayNow = [
    hasFlightTripCost,
    hasRailTripCost,
    isHotelPayingNow,
    isCarRentalPayingNow,
  ].some(Boolean)
  const isPayLater = [isHotelPayingLater, isCarRentalPayingLater].some(Boolean)

  const totalFlightTripCosts = {
    total: configuredFormatRate(flightTripCost.total),
    base: configuredFormatRate(flightTripCost.base),
    feesAndTaxes: configuredFormatRate(flightTripCost.feesAndTaxes),
  }
  const totalNowHotelTripCost = configuredFormatRate(payNowHotelTripCost.total)
  const totalLaterHotelTripCost = configuredFormatRate(payLaterHotelTripCost.total)
  const totalNowCarRentalTripCost = configuredFormatRate(payNowCarRentalTripCost.total)
  const totalLaterCarRentalTripCost = configuredFormatRate(payLaterCarRentalTripCost.total)
  const totalRailTripCost = configuredFormatRate(railTripCost.total)

  return {
    hasCarRentalTripCost,
    hasFlightTripCost,
    hasNowHotelTripCost,
    hasLaterHotelTripCost,
    hasNowCarRentalTripCost,
    hasLaterCarRentalTripCost,
    isPayNow,
    isPayLater,
    totalFlightTripCosts,
    totalRailTripCost,
    totalNowHotelTripCost,
    totalLaterHotelTripCost,
    totalNowCarRentalTripCost,
    totalLaterCarRentalTripCost,
    hasRailTripCost,
  }
}

function configuredFormatRate(rate?: Rate | null) {
  const { mainCost } = formatRate(rate, {
    morpheme: 'none',
  })

  return mainCost
}
