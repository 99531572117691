import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { FlightImage } from '@etta/components/flight-image'
import { useScreenType } from '@fiji/modes'
import { Section, TitleSection, Status, RowCenter, Logo } from './header-styled'

const i18nBase = 'PostBooking.FlightDetails'

type Props = {
  airlineTitle: string
  aircraftNumber: string
  isDelayed: boolean
  logo?: string | null
  isTripCancelled: boolean
  flightStatus: string
}

export function Header({
  airlineTitle,
  aircraftNumber,
  isDelayed,
  logo,
  isTripCancelled,
  flightStatus,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const logoAlt = t(i18nBase + '.Logo', { airlineName: airlineTitle })
  const ariaLabelBase = `${airlineTitle}. ${aircraftNumber} `
  const ariaLabel = flightStatus ? ariaLabelBase + flightStatus : ariaLabelBase

  return (
    <Section screenType={screenType} aria-label={ariaLabel}>
      <TitleSection>
        <Text variant="title3" color="mainText" data-tracking-id="airline-information-text">
          {airlineTitle}
        </Text>
        <RowCenter>
          <Text variant="footnoteStrong" color="bodyText" data-tracking-id="flight-number-text">
            {aircraftNumber}
          </Text>
          {!isTripCancelled && flightStatus && (
            <Status isDelayed={isDelayed}> • {flightStatus}</Status>
          )}
        </RowCenter>
      </TitleSection>
      <Logo screenType={screenType}>
        <FlightImage image={logo} alt={logoAlt} />
      </Logo>
    </Section>
  )
}
