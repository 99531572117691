import { useTranslation } from 'react-i18next'
import { HorizontalDelimiter } from '@etta/components/hotel-details-modal/hotel-details-styled'
import { Badge } from '@etta/ui/badge'
import { Text } from '@etta/ui/text'
import { HotelDetailsDescription } from '../../hotel-details-description'
import { HotelOutOfPolicy } from '../../hotel-out-of-policy/hotel-out-of-policy'
import { CallEHI } from '../../call-ehi'
import type { HotelAboutLayoutProps } from '../../types'
import {
  HeaderContainer,
  HeaderWrapper,
  HotelDetailsDescriptionTitle,
  HotelOptions,
  VerticalDelimiter,
} from './hotel-about-mobile-styled'

export function HotelAboutMobile({
  hotelDetails,
  dateRangeTitle,
  areDatesDisplayed,
  isHotelDescriptionDisplayed,
}: HotelAboutLayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  const { hotelDescriptions, notes, isEcoFriendly, policy, isPreferred } = hotelDetails
  const showOutOfPolicyReasons = policy && !policy.isInPolicy && !isPreferred

  return (
    <HeaderWrapper>
      <HeaderContainer>
        {isEcoFriendly && (
          <HotelOptions hasExtendedMargin>
            <Badge variant="ecoFriendly" color={'success'} iconSize={20} />
            <VerticalDelimiter />
          </HotelOptions>
        )}
        {areDatesDisplayed && (
          <HotelOptions aria-label={`${t(i18Base + '.DatesFrom')} ${dateRangeTitle}`}>
            <Text variant="linkSmall" color="mainText">
              {t(i18Base + '.Dates')}
            </Text>
            <Text variant="footnoteMedium" color="bodyText">
              {dateRangeTitle}
            </Text>
          </HotelOptions>
        )}
        {showOutOfPolicyReasons && (
          <HotelOutOfPolicy
            outOfPolicyReasons={policy.outOfPolicyReasons}
            outOfPolicyData={policy.outOfPolicyData}
          />
        )}
      </HeaderContainer>
      <HorizontalDelimiter />
      <HeaderContainer>
        {isHotelDescriptionDisplayed && !!hotelDescriptions?.length && (
          <>
            <HotelDetailsDescriptionTitle variant="headline">
              {t(`${i18Base}.AboutThisHotelTitle`)}
            </HotelDetailsDescriptionTitle>
            <HotelDetailsDescription hotelDescriptions={hotelDescriptions} />
          </>
        )}
        {notes && <CallEHI notes={notes} />}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
