import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import type { PlacesSearchType, AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import { useGraphqlAutocomplete } from './use-graphql-autocomplete'
import { useGooglePlacesAutocomplete } from './use-google-places-autocomplete'

type Props = {
  setIsLoading?: (value: boolean) => void
  setErrorMessage?: (value: string) => void
  onChange?: (value: Place, address?: AddressInput) => void
  onModalClose?: (e: boolean) => void
  useNewService?: boolean
  onComplete?: (value: Place[]) => void
  setIsFetchStarted?: (value: boolean) => void
}

export const useLocationAutocomplete = ({
  setIsLoading,
  setErrorMessage,
  onChange,
  onModalClose,
  useNewService = false,
  onComplete,
  setIsFetchStarted,
}: Props) => {
  const { t } = useTranslation()
  const onError = () => setErrorMessage?.(t('TripPlanner.BaseSearch.Errors.ErrorFetchingPlaces'))
  const graphqlAutocomplete = useGraphqlAutocomplete({
    setIsLoading,
    onChange,
    onModalClose,
    onError,
    onComplete,
  })
  const googleAutocomplete = useGooglePlacesAutocomplete({
    setIsLoading,
    onChange,
    onModalClose,
    onError,
    onComplete,
  })
  const handleSelectedItemChange = ({ selectedItem }: { selectedItem?: Place | null }) => {
    if (!selectedItem) {
      return
    }

    if (useNewService) {
      graphqlAutocomplete.handleSelectedItemChange({ selectedItem })
      return
    }

    googleAutocomplete.handleSelectedItemChange({ selectedItem })
  }
  const handleFindPlaces = useDebouncedCallback(
    ({
      place,
      sortOrder,
      searchType,
    }: {
      place?: string
      sortOrder?: PlaceAutocompleteSort[]
      searchType?: PlacesSearchType
    }) => {
      setErrorMessage?.('')

      if (!place) {
        onComplete?.([])
        setIsFetchStarted?.(false)
        return
      }

      if (useNewService) {
        graphqlAutocomplete.handleFindPlaces({ query: place, sortOrder, searchType })
        setIsFetchStarted?.(true)
        return
      }
      googleAutocomplete.handleFindPlaces({ place })
    },
    500,
  )

  return { handleSelectedItemChange, handleFindPlaces }
}
