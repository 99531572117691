import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  GetCreditCardsDocument,
  AddCreditCardDocument,
  EditCreditCardDocument,
  DeleteCreditCardDocument,
  GetSuggestedHomeAirportsDocument,
  GetAvailableVendorsDocument,
  UpdateVisaDocument,
  UpdatePassportDocument,
  UpdateNationalIdCardDocument,
  RemoveVisaDocument,
  RemoveNationalIdCardDocument,
  GetProfileFieldsSettingsDocument,
  GetTimeZonesDocument,
  GetCurrenciesDocument,
  GetLanguagesDocument,
  GetSiteCardsDocument,
  GetDelegatedUsersDocument,
  AddPaymentCardDocument,
  ChangePasswordDocument,
  GetAvailableSpecialRequestsDocument,
  GetAvailableDelegatesDocument,
  UpdateDelegatesDocument,
  UpdateAuthorizersDocument,
} from '@fiji/graphql/hooks'
import type {
  GetAvailableSpecialRequestsQueryVariables,
  GetCreditCardsQuery,
  GetCreditCardsQueryVariables,
  AddCreditCardMutationVariables,
  AddCreditCardMutation,
  EditCreditCardMutationVariables,
  EditCreditCardMutation,
  DeleteCreditCardMutationVariables,
  DeleteCreditCardMutation,
  GetSuggestedHomeAirportsQuery,
  GetSuggestedHomeAirportsQueryVariables,
  GetAvailableVendorsQuery,
  GetAvailableVendorsQueryVariables,
  UpdateVisaMutationVariables,
  UpdateVisaMutation,
  UpdatePassportMutationVariables,
  UpdatePassportMutation,
  UpdateNationalIdCardMutationVariables,
  UpdateNationalIdCardMutation,
  RemoveVisaMutationVariables,
  RemoveVisaMutation,
  RemoveNationalIdCardMutationVariables,
  RemoveNationalIdCardMutation,
  GetProfileFieldsSettingsQuery,
  GetTimeZonesQueryVariables,
  GetTimeZonesQuery,
  GetCurrenciesQuery,
  GetCurrenciesQueryVariables,
  GetLanguagesQuery,
  GetLanguagesQueryVariables,
  GetSiteCardsQuery,
  GetSiteCardsQueryVariables,
  GetDelegatedUsersQuery,
  GetDelegatedUsersQueryVariables,
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  GetAvailableSpecialRequestsQuery,
  GetAvailableDelegatesQueryVariables,
  GetAvailableDelegatesQuery,
  UpdateDelegatesMutationVariables,
  UpdateDelegatesMutation,
  UpdateAuthorizersMutationVariables,
  UpdateAuthorizersMutation,
} from '@fiji/graphql/hooks'

@DataProvider()
export class SettingsDataProvider extends GraphqlDataProvider {
  private abortController = new AbortController()

  private abortPreviousRequest() {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  getCreditCards() {
    return this.client.query<GetCreditCardsQuery, GetCreditCardsQueryVariables>({
      query: GetCreditCardsDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })
  }

  addCreditCard(input: AddCreditCardMutationVariables['input']) {
    return this.client.mutate<AddCreditCardMutation, AddCreditCardMutationVariables>({
      mutation: AddCreditCardDocument,
      variables: {
        input,
      },
    })
  }

  addPaymentCard(input: AddPaymentCardMutationVariables['input']) {
    return this.client.mutate<AddPaymentCardMutation, AddPaymentCardMutationVariables>({
      mutation: AddPaymentCardDocument,
      variables: {
        input,
      },
    })
  }

  editCreditCard(input: EditCreditCardMutationVariables['input']) {
    return this.client.mutate<EditCreditCardMutation, EditCreditCardMutationVariables>({
      mutation: EditCreditCardDocument,
      variables: {
        input,
      },
    })
  }

  deleteCreditCard(input: DeleteCreditCardMutationVariables['input']) {
    return this.client.mutate<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>({
      mutation: DeleteCreditCardDocument,
      variables: {
        input,
      },
    })
  }

  getSuggestedHomeAirports(input: GetSuggestedHomeAirportsQueryVariables['input']) {
    this.abortPreviousRequest()

    return this.client.query<GetSuggestedHomeAirportsQuery, GetSuggestedHomeAirportsQueryVariables>(
      {
        query: GetSuggestedHomeAirportsDocument,
        variables: { input },
        context: {
          fetchOptions: {
            controller: this.abortController,
          },
        },
      },
    )
  }

  updateVisa(input: UpdateVisaMutationVariables['input']) {
    return this.client.mutate<UpdateVisaMutation, UpdateVisaMutationVariables>({
      mutation: UpdateVisaDocument,
      variables: {
        input,
      },
    })
  }

  removeVisa(input: RemoveVisaMutationVariables['input']) {
    return this.client.mutate<RemoveVisaMutation, RemoveVisaMutationVariables>({
      mutation: RemoveVisaDocument,
      variables: {
        input,
      },
    })
  }

  updatePassport(input: UpdatePassportMutationVariables['input']) {
    return this.client.mutate<UpdatePassportMutation, UpdatePassportMutationVariables>({
      mutation: UpdatePassportDocument,
      variables: {
        input,
      },
    })
  }

  updateNationalIdCard(input: UpdateNationalIdCardMutationVariables['input']) {
    return this.client.mutate<UpdateNationalIdCardMutation, UpdateNationalIdCardMutationVariables>({
      mutation: UpdateNationalIdCardDocument,
      variables: {
        input,
      },
    })
  }

  removeNationalIdCard(input: RemoveNationalIdCardMutationVariables['input']) {
    return this.client.mutate<RemoveNationalIdCardMutation, RemoveNationalIdCardMutationVariables>({
      mutation: RemoveNationalIdCardDocument,
      variables: {
        input,
      },
    })
  }

  getAvailableVendors(input: GetAvailableVendorsQueryVariables['input']) {
    return this.client.query<GetAvailableVendorsQuery, GetAvailableVendorsQueryVariables>({
      query: GetAvailableVendorsDocument,
      variables: { input },
    })
  }

  getAvailableSpecialRequests(input: GetAvailableSpecialRequestsQueryVariables['input']) {
    return this.client.query<
      GetAvailableSpecialRequestsQuery,
      GetAvailableSpecialRequestsQueryVariables
    >({
      query: GetAvailableSpecialRequestsDocument,
      variables: { input },
    })
  }

  getTimeZones() {
    return this.client.query<GetTimeZonesQuery, GetTimeZonesQueryVariables>({
      query: GetTimeZonesDocument,
    })
  }

  getCurrencies() {
    return this.client.query<GetCurrenciesQuery, GetCurrenciesQueryVariables>({
      query: GetCurrenciesDocument,
    })
  }

  getLanguages() {
    return this.client.query<GetLanguagesQuery, GetLanguagesQueryVariables>({
      query: GetLanguagesDocument,
    })
  }

  getProfileFieldsSettings() {
    return this.client.query<GetProfileFieldsSettingsQuery>({
      query: GetProfileFieldsSettingsDocument,
    })
  }

  getSiteCards() {
    return this.client.query<GetSiteCardsQuery, GetSiteCardsQueryVariables>({
      query: GetSiteCardsDocument,
    })
  }

  getDelegates(input: GetDelegatedUsersQueryVariables['input']) {
    return this.client.query<GetDelegatedUsersQuery, GetDelegatedUsersQueryVariables>({
      query: GetDelegatedUsersDocument,
      variables: { input },
    })
  }

  getAvailableDelegates(query: GetAvailableDelegatesQueryVariables['query']) {
    return this.client.query<GetAvailableDelegatesQuery, GetAvailableDelegatesQueryVariables>({
      query: GetAvailableDelegatesDocument,
      variables: { query },
    })
  }

  updateDelegates(input: UpdateDelegatesMutationVariables['input']) {
    return this.client.mutate<UpdateDelegatesMutation, UpdateDelegatesMutationVariables>({
      mutation: UpdateDelegatesDocument,
      variables: { input },
    })
  }

  updateAuthorizer(input: UpdateAuthorizersMutationVariables['input']) {
    return this.client.mutate<UpdateAuthorizersMutation, UpdateAuthorizersMutationVariables>({
      mutation: UpdateAuthorizersDocument,
      variables: { input },
    })
  }

  changePassword(input: ChangePasswordMutationVariables['input']) {
    return this.client.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
      mutation: ChangePasswordDocument,
      variables: {
        input,
      },
    })
  }
}
