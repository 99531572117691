import * as R from 'ramda'
import type { FlightLegSubSegment, FlightLegSubSegmentImages } from '@fiji/graphql/types'

export function getAirlinesLogos(
  segments: FlightLegSubSegment[],
  imagePath: keyof FlightLegSubSegmentImages = 'square',
): string[] {
  return R.pipe(
    R.map(R.path(['images', imagePath])),
    R.filter(Boolean),
    R.uniq,
  )(segments) as string[]
}
