import styled, { css, createGlobalStyle } from 'styled-components'
import { subHeadMedium } from '@fiji/style'
import { Link } from '@etta/ui/link'

const truncateStyles = css`
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const imageBannerStyles = css`
  img {
    // We are overwriting the original height and width to make the image stretch nicely
    width: 100% !important;
    height: auto !important;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

export const Message = styled.div<{
  shouldTruncateMessage?: boolean
  hasDesktopBannerImage?: boolean
}>`
  word-wrap: break-word;
  color: ${(p) => p.theme.colors.bodyText};
  ${subHeadMedium};
  ${({ shouldTruncateMessage }) => shouldTruncateMessage && truncateStyles}
  ${({ hasDesktopBannerImage }) => hasDesktopBannerImage && imageBannerStyles}

  p {
    margin: unset;
  }

  // We overwrite the text to make sure it is the same color, font-family, and weight in every theme
  * {
    color: ${(p) => p.theme.colors.bodyText} !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
  }
  a {
    color: ${(p) => p.theme.colors.primary} !important;
  }
`

export const GlobalSafaryStyle = createGlobalStyle`
  /* Safari 7.1+ */
  _::-webkit-full-page-media, _:future, :root ${Message} li {
    display: inline;
    position: relative;
    margin-left: 16px;
  }

  _::-webkit-full-page-media, _:future, :root ${Message} li:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.bodyText};;
    position: absolute;
    left: -14px;
    top: 8px;
  }
`

export const ToggleLink = styled(Link).attrs({ size: 'small' })`
  display: inline-block;
  margin-top: 16px;
`
