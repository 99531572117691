import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import type { ItineraryRules } from '../rules-and-restriction.types'
import {
  ContentWrapper,
  HeaderWrapper,
  ListItem,
  List,
} from './rules-and-restrictions-modal.styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  itineraryRules: ItineraryRules
}

export function RulesAndRestrictionsModal({ isOpen, onClose, itineraryRules }: Props) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Header withBorderBottom minHeight={62}>
        <HeaderWrapper>
          <Header.Title
            title={t(`TripReview.RulesAndRestrictions.title`)}
            color="mainText"
            align="left"
          />
          <Header.CloseButton color="mainText" onClick={onClose} />
        </HeaderWrapper>
      </Header>
      <Modal.Body>
        <ContentWrapper>
          {Object.keys(itineraryRules).map((key) => {
            return itineraryRules[key as keyof typeof itineraryRules].map((ruleSet) => (
              <>
                <Text as={'h3'} variant="subHeadStrong" color="mainText1">
                  {ruleSet.label}
                </Text>
                <List>
                  {ruleSet.rules.map((rule) => (
                    <ListItem dangerouslySetInnerHTML={{ __html: rule }} />
                  ))}
                </List>
              </>
            ))
          })}
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  )
}
