import { useState, useCallback } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { mapAirSeatMapSegments } from './mappers/map-air-seat-map-segments'

export function useAirSeatMapData() {
  const { postBookingTripStore } = usePostBookingContext()
  const flightSegments = postBookingTripStore.flightSegments
  const [chosenAirSeatMapLegId, setChosenAirSeatMapLegId] = useState<string>('')
  const { isOpen, handleOpen, handleClose } = useTogglePopup()

  const airSeatMapSegments = mapAirSeatMapSegments({ segments: flightSegments })
  const isSeatMapDataAvailable =
    !!flightSegments.length && !!airSeatMapSegments.length && !!chosenAirSeatMapLegId

  const onOpen = useCallback(
    (segmentLegId = '') => {
      setChosenAirSeatMapLegId(segmentLegId)
      handleOpen()
    },
    [handleOpen],
  )

  const onClose = useCallback(() => {
    setChosenAirSeatMapLegId('')
    handleClose()
  }, [handleClose])

  return {
    airSeatMapToggle: {
      isOpen,
      onOpen,
      onClose,
    },
    isSeatMapDataAvailable,
    airSeatMapSegments,
    chosenAirSeatMapLegId,
  }
}
