import { CarRentalVendorInformationLayout } from './layout'
import type { CarRentalVendorInformationProps } from './types'

export function CarRentalVendorInformation({
  vendorInformation,
  safetyCheck,
}: CarRentalVendorInformationProps) {
  return (
    <CarRentalVendorInformationLayout
      vendorInformation={vendorInformation}
      safetyCheck={safetyCheck}
    />
  )
}
