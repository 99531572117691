import { useHistory } from 'react-router'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import type { Room } from '@fiji/graphql/types'
import { ROUTES } from '@fiji/routes'
import { useAppDispatch } from '@fiji/store'
import { rollbar } from '@fiji/rollbar'
import { updateHotelId } from '@fiji/store/segment-ids'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
// TODO: should import only from module level
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useReactivatedItinerary } from '@fiji/hooks/use-reactivated-itinerary'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'

type HandleProcessArgs = {
  handler: () => Promise<void>
  itineraryId: string
  bookingId?: string
}

type HandleSubmitHotelRoomArgs = {
  itineraryId?: string
  bookingId?: string
  hotelDetails: HotelDetailsType | null
  checkIn: Date
  checkOut: Date
  hotelResultId?: string | null
  room: Room | null
  hotelId?: string | null
}

type HandleAddRoomArgs = HandleSubmitHotelRoomArgs & {
  itineraryId: string
  hotelDetails: HotelDetailsType
  room: Room
}

type HandleReplaceHotelRoomArgs = HandleSubmitHotelRoomArgs & {
  hotelId: string
  itineraryId: string
  hotelDetails: HotelDetailsType
  room: Room
}

type Args = {
  onUpdateViewStatus: (value: TripStateStatus) => void
}

export function useSelectHotel({ onUpdateViewStatus }: Args) {
  const { tripActions, tripHotelActions, reviewTripStore } = useReviewTripContext()

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { reactivateItinerary } = useReactivatedItinerary()

  const handleProcess = async ({ handler, itineraryId, bookingId }: HandleProcessArgs) => {
    onUpdateViewStatus('loading')

    try {
      await handler()
      if (!reviewTripStore.tripId) {
        tripActions.setTripId(itineraryId)
      }
      await tripActions.updateTrip()
      onUpdateViewStatus('success')
      await delayForSuccess()
      dispatch(updateHotelId({ hotelId: null }))
      history.push(ROUTES.reviewTrip.main({ itineraryId, bookingId }))
    } catch (e) {
      onUpdateViewStatus('error')

      if (e instanceof Error) {
        rollbar.error(e)
      } else {
        rollbar.error(new Error('Unknown error'))
      }
    }
  }

  async function handleAddRoom({
    itineraryId,
    bookingId,
    hotelDetails,
    checkIn,
    checkOut,
    hotelResultId,
    room,
  }: HandleAddRoomArgs) {
    await handleProcess({
      itineraryId,
      bookingId,
      handler: async () => {
        await reactivateItinerary({ action: 'add room', itineraryId })
        const res = await tripHotelActions.handleSelectRoom({
          hotelId: hotelDetails.hotelId,
          hotelResultId,
          checkIn: checkIn.toJSON(),
          checkOut: checkOut.toJSON(),
          roomKey: room.id,
          inventoryId: room.inventoryId,
          itineraryId,
          ...(hotelDetails?.address.geocode || {}),
        })
        if (res.isErr()) {
          throw new Error(res.getError().message)
        }
      },
    })
  }

  async function handleReplaceHotelRoom({
    itineraryId,
    bookingId,
    hotelDetails,
    checkIn,
    checkOut,
    room,
    hotelResultId,
    hotelId,
  }: HandleReplaceHotelRoomArgs) {
    await handleProcess({
      itineraryId,
      bookingId,
      handler: async () => {
        await reactivateItinerary({ action: 'replace room', itineraryId })
        const res = await tripHotelActions.handleReplaceRoom({
          hotelId: hotelDetails.hotelId,
          hotelResultId,
          checkIn: checkIn.toJSON(),
          checkOut: checkOut.toJSON(),
          oldRoomKey: hotelId,
          newRoomKey: room.id,
          itineraryId,
          ...(hotelDetails?.address.geocode || {}),
        })
        if (res.isErr()) {
          throw new Error(res.getError().message)
        }
      },
    })
  }

  function handleSubmitHotelRoom({
    itineraryId,
    bookingId,
    hotelDetails,
    checkIn,
    checkOut,
    room,
    hotelResultId,
    hotelId,
  }: HandleSubmitHotelRoomArgs) {
    if (!itineraryId || !hotelDetails || !room) {
      return
    }
    if (hotelId) {
      handleReplaceHotelRoom({
        itineraryId,
        bookingId,
        hotelDetails,
        checkIn,
        checkOut,
        room,
        hotelResultId,
        hotelId,
      })
      return
    }
    handleAddRoom({
      itineraryId,
      bookingId,
      hotelDetails,
      checkIn,
      checkOut,
      room,
      hotelResultId,
    })
  }

  return { handleSubmitHotelRoom }
}
