import styled from 'styled-components'
import { title2 } from '@fiji/style'

export const Container = styled.div`
  padding: 16px 16px 12px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const Image = styled.img`
  width: 100%;
`

export const Title = styled.h4`
  margin: 0;
  ${title2};
  color: ${({ theme }) => theme.colors.mainText};
`
