import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'
import type { Props } from '../../layout'
import { TravelerDocumentsList } from '../../traveler-document-list'
import { TitleWrapper } from './traveler-documents-desktop.styled'

export function TravelerDocumentsDesktop({ isOpen, onClose, title, ...props }: Props) {
  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="right">
      <Modal.Header withBorder isMobile>
        <TitleWrapper
          minHeight={72}
          align="center"
          rightSlot={
            <IconButton size="medium" icon="closeFilledPWA" color="bodyText" onClick={onClose} />
          }>
          <Header.Title title={title} align="left" />
        </TitleWrapper>
      </Modal.Header>
      <Modal.Body>
        <TravelerDocumentsList {...props} />
      </Modal.Body>
    </Modal>
  )
}
