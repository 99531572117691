import type { PropsWithChildren } from 'react'
import { StyledLinkButton } from './text-button-styles'
import type { TextButtonColorVariants } from './types'

type Props = PropsWithChildren<{
  className?: string
  colorVariant?: TextButtonColorVariants
  onClick: () => void
}>

export function TextButton({ className, colorVariant = 'primary', onClick, children }: Props) {
  return (
    <StyledLinkButton className={className} colorVariant={colorVariant} onClick={() => onClick()}>
      {children}
    </StyledLinkButton>
  )
}
