import styled from 'styled-components'
import { DatePicker } from '@etta/ui/date-picker'
import { Modal } from '@etta/ui/modal'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 100;
`

export const StyledDatePicker = styled(DatePicker)`
  max-width: 100%;
`

export const Footer = styled(Modal.Footer)`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.colors.borderDark};
`
