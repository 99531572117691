import { Inject, Service } from '@etta/di'
import type { Place } from '@fiji/types'
import { dateFormat, getDifferenceInDays, isBeforeDate } from '@fiji/utils/dates/'
import { ComplexPickUpType } from '@etta/modules/ride-hail/core/enum/complex-pickup-type.enum'
import { PostBookingTripService } from '@etta/modules/post-booking'
import { GazooPostBookingAdapter } from '@etta/modules/post-booking/infra/gazoo/gazoo-post-booking.adapter'
import { PostBookingSegmentTypeCheckService } from '@etta/modules/post-booking/interface/services/post-booking-segment-type-check.service'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import type { RideHailAirportPickupFlightValueObject } from '../../core/value-objects/ride-hail-airport-pickup.value-object'
import { SearchRideHailStore } from '../store/search-ride-hail.store'
import { RideHailAirportPickupStore } from '../store/ride-hail-airport-pickup-store'
import { RideHailSearchAdapter } from '../../infra/ride-hail-search.adapter'
import { ConfigurationStore } from '../store/configuration.store'

@Service()
export class RideHailAirportPickupService {
  constructor(
    @Inject() private readonly rideHailAirportPickupStore: RideHailAirportPickupStore,
    @Inject() private readonly rideHailSearchAdapter: RideHailSearchAdapter,
    @Inject() private readonly postBookingTripService: PostBookingTripService,
    @Inject() private readonly segmentTypeCheckService: PostBookingSegmentTypeCheckService,
    @Inject() private readonly gazooPostBookingAdapter: GazooPostBookingAdapter,
    @Inject() private readonly searchRideHailStore: SearchRideHailStore,
    @Inject() private readonly configurationStore: ConfigurationStore,
  ) {}

  async loadFlightAirportPickup() {
    const { processId, transactionGroupId } = this.searchRideHailStore.searchRideHailTripInfo
    this.rideHailAirportPickupStore.setIsLoading(true)

    const tripResult = await this.postBookingTripService.fetchTripDetails(
      {
        id: String(processId),
        transactionGroupId: String(transactionGroupId),
      },
      true,
    )

    if (tripResult.isErr()) {
      this.setStatusError()
      this.rideHailAirportPickupStore.setArrivalFlight(null)
      return
    }

    if (tripResult.isOk()) {
      const flight = this.getFlightFromUserTrip(tripResult.getValue())

      if (flight) {
        this.setStatusOk()
        this.rideHailAirportPickupStore.setArrivalFlight(flight)
        return
      }
    }

    const upcomingFlightResult = await this.rideHailSearchAdapter.rideHailUpcomingFlights()

    if (upcomingFlightResult.isErr()) {
      this.setStatusError()
      this.rideHailAirportPickupStore.setArrivalFlight(null)
      return
    }

    if (upcomingFlightResult.isOk()) {
      const upcomingFlight = this.getUpcomingFlight(upcomingFlightResult.getValue())
      this.setStatusOk()
      this.rideHailAirportPickupStore.setArrivalFlight(upcomingFlight)
    }
  }

  async getUpcomingFlightsList() {
    this.rideHailAirportPickupStore.setIsLoading(true)
    this.rideHailAirportPickupStore.setIsError(false)
    this.rideHailAirportPickupStore.setArrivalFlightsList(null)

    const upcomingFlightResult = await this.rideHailSearchAdapter.rideHailUpcomingFlights()

    if (upcomingFlightResult.isErr()) {
      this.setStatusError()
      this.rideHailAirportPickupStore.setArrivalFlightsList(null)
      return
    }

    if (upcomingFlightResult.isOk()) {
      const upcomingFlight = upcomingFlightResult.getValue().map(this.mapUpcomingFlightDetails)
      this.setStatusOk()
      this.rideHailAirportPickupStore.setArrivalFlightsList(upcomingFlight)
    }
  }

  cancelFlightAirportPickupRequest() {
    this.rideHailSearchAdapter.cancelRequests()
    this.gazooPostBookingAdapter.cancelGetTripRequest()
  }

  checkComplexPickUp(pickUpPlace?: Place, dropOffPlace?: Place): ComplexPickUpType {
    if (!pickUpPlace || !dropOffPlace) {
      return ComplexPickUpType.Unspecified
    }

    const isPickUpAirport = pickUpPlace.airportCode !== undefined
    const isPickUpCountryCodeDefined = !!pickUpPlace.countryCode
    const isDropOffCountryCodeDefined = !!dropOffPlace.countryCode
    const isPickUpInUS = pickUpPlace.countryCode === 'US'
    const isDropOffInUS = dropOffPlace.countryCode === 'US'

    if (!isPickUpCountryCodeDefined && !isDropOffCountryCodeDefined) {
      return ComplexPickUpType.Unspecified
    }

    if (isPickUpAirport && isPickUpInUS) {
      return ComplexPickUpType.Airport
    }

    if (isPickUpCountryCodeDefined && isDropOffCountryCodeDefined) {
      if (!isPickUpInUS || !isDropOffInUS) {
        return ComplexPickUpType.OutsideUS
      }
      return ComplexPickUpType.Unspecified
    }

    if (!isPickUpCountryCodeDefined && !isDropOffInUS) {
      return ComplexPickUpType.OutsideUS
    }

    if (!isDropOffCountryCodeDefined && !isPickUpInUS) {
      return ComplexPickUpType.OutsideUS
    }

    return ComplexPickUpType.Unspecified
  }

  selectFlight(flightDate: Date, flightDesignator: string, arrivalAirportCode: string = '') {
    const flightArrivalDate = dateFormat(flightDate, 'yyyy-MM-dd')
    const matchedFlight = this.rideHailAirportPickupStore.arrivalFlightsList?.find(
      (flight) =>
        this.isSameDate(flightArrivalDate, flight.arrivalDate) &&
        this.isSameFlightCode(flightDesignator, flight.flightDesignator),
    )

    if (matchedFlight) {
      this.rideHailAirportPickupStore.setIsCustomFlight(false)
      this.rideHailAirportPickupStore.setArrivalFlight(matchedFlight)
      return
    }

    this.rideHailAirportPickupStore.setIsCustomFlight(true)
    this.rideHailAirportPickupStore.setArrivalFlight({
      arrivalDate: flightArrivalDate,
      flightDesignator,
      arrivalAirportCode,
      airlineName: '',
      imageUrl: '',
    })
  }

  isEligibleFlightArrivalDateTime(arrivalDateTime: string) {
    return (
      this.isLaterThanTwoHoursFromNow(arrivalDateTime) &&
      this.isNotExceedAdvanceBookingDateLimit(
        arrivalDateTime,
        this.configurationStore.advanceBookingInDays,
      )
    )
  }

  private setStatusOk() {
    this.rideHailAirportPickupStore.setIsLoading(false)
    this.rideHailAirportPickupStore.setIsError(false)
  }

  private setStatusError() {
    this.rideHailAirportPickupStore.setIsError(true)
    this.rideHailAirportPickupStore.setIsLoading(false)
  }

  private getFlightFromUserTrip(trip: TripEntity): RideHailAirportPickupFlightValueObject | null {
    const flights = trip.segments.filter(this.segmentTypeCheckService.isFlightSegment)

    if (flights.length === 0) {
      return null
    }

    const upcomingFlights = flights.filter((flight) => {
      if (!flight.arrivalDateTime) {
        return false
      }
      return this.isEligibleFlightArrivalDateTime(flight.arrivalDateTime)
    })

    const { pickUpPlace } = this.searchRideHailStore.searchRideHailForm

    let matchedFlight = upcomingFlights.find((flight) => {
      if (!pickUpPlace?.airportCode) {
        return false
      }
      return flight.segments?.at(-1)?.arrival?.airportCode === pickUpPlace.airportCode
    })

    if (!matchedFlight && upcomingFlights.length > 0) {
      matchedFlight = upcomingFlights[0]
    }

    const upcomingFlight = matchedFlight?.segments?.at(-1)

    if (!upcomingFlight) {
      return null
    }

    const flightNumber = upcomingFlight.flightNumber
    const carrierCode = upcomingFlight.carrierCode
    const isFlightDesignatorAvailable = flightNumber && carrierCode

    return {
      airlineName: upcomingFlight.name ?? '-',
      flightDesignator: isFlightDesignatorAvailable ? carrierCode + flightNumber : '-',
      arrivalAirportCode: upcomingFlight.arrival?.airportCode ?? '-',
      arrivalDate: upcomingFlight.arrivalDate ?? '',
      imageUrl: upcomingFlight.images.square ?? '',
    }
  }

  private getUpcomingFlight(
    flights: RideHailAirportPickupFlightValueObject[],
  ): RideHailAirportPickupFlightValueObject | null {
    if (flights.length === 0) {
      return null
    }

    return this.mapUpcomingFlightDetails(flights[0])
  }

  private mapUpcomingFlightDetails(
    flight: RideHailAirportPickupFlightValueObject,
  ): RideHailAirportPickupFlightValueObject {
    return {
      airlineName: flight.airlineName,
      flightDesignator: flight.flightDesignator.replace('-', ''),
      arrivalAirportCode: flight.arrivalAirportCode,
      arrivalDate: [flight.arrivalDate, flight.arrivalTime].join('T'),
      imageUrl: flight.imageUrl,
    }
  }

  private isLaterThanTwoHoursFromNow(dateTime: string): boolean {
    return new Date(dateTime).getTime() > new Date().setHours(new Date().getHours() + 2)
  }

  private isNotExceedAdvanceBookingDateLimit(
    dateTime: string,
    advanceBookingDateLimit: number,
  ): boolean {
    const now = new Date()
    if (isBeforeDate(now, new Date(dateTime))) {
      return getDifferenceInDays(new Date(dateTime), now) <= advanceBookingDateLimit
    }
    return false
  }

  private isSameDate(flightDate1: string, flightDate2: string): boolean {
    return (
      dateFormat(new Date(flightDate1), 'yyyy-MM-dd') ===
      dateFormat(new Date(flightDate2), 'yyyy-MM-dd')
    )
  }

  private isSameFlightCode(flightDesignator1: string, flightDesignator2: string): boolean {
    if (
      flightDesignator1.slice(0, 2).toLocaleUpperCase() !==
      flightDesignator2.slice(0, 2).toLocaleUpperCase()
    ) {
      return false
    }

    return (
      flightDesignator1.slice(2).replace(new RegExp('^[(\\s-)]{1}'), '') ===
      flightDesignator2.slice(2).replace(new RegExp('^[(\\s-)]{1}'), '')
    )
  }
}
