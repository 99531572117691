import { useTranslation } from 'react-i18next'
import { SegmentState } from '@fiji/graphql/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { CardStatusBlock } from './segment-status-styled'
import type { SegmentSkinType } from './types'

type Props = {
  segmentState?: SegmentState | null
  isFromPreviousBooking?: boolean
}

export function SegmentStatus({ segmentState, isFromPreviousBooking }: Props) {
  const { t } = useTranslation()
  const isDesktop = screenMatcher.getScreenType() === ScreenType.Desktop
  const cardStatusSkin: SegmentSkinType = isDesktop ? 'gray-background' : 'transparent'
  if (isFromPreviousBooking) {
    return <></>
  }

  switch (segmentState) {
    case SegmentState.Changed: {
      return (
        <CardStatusBlock skin={cardStatusSkin}>{t('ReviewTripPWA.Card.Changed')}</CardStatusBlock>
      )
    }
    case SegmentState.New: {
      return (
        <CardStatusBlock skin={cardStatusSkin}>{t('ReviewTripPWA.Card.Added')}</CardStatusBlock>
      )
    }
    default:
      return <></>
  }
}
