import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { SegmentType } from '@fiji/graphql/types'

export function checkIsDisclamerMessageVisible(segments?: SegmentPostBookingValueObject[]) {
  if (!segments) {
    return false
  }

  return (
    segments &&
    segments.some((segment) => {
      if (segment.type === SegmentType.CarService || segment.type === SegmentType.RideHail) {
        return true
      }
      if (segment.type === SegmentType.Train) {
        return (
          segment.segments &&
          segment.segments.some(
            (trainSegment) =>
              trainSegment.stations?.arrival?.location?.countryCode === 'US' ||
              trainSegment.stations?.arrival?.location?.countryCode === 'CA',
          )
        )
      }
    })
  )
}
