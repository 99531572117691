import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace ItineraryErrors {
  export const ItineraryAddCarRentalResponse = createErrorClass('Add car rental failed')
  export const UnexpectedErrorItineraryAddCarRental = createErrorClass('Add car rental failed')
  export const ItineraryReplaceCarRentalResponse = createErrorClass('Replace car rental failed')
  export const UnexpectedErrorItineraryReplaceCarRental = createErrorClass(
    'Replace car rental failed',
  )
  export const ItineraryRemoveCarRentalResponse = createErrorClass('Remove car rental failed')
  export const UnexpectedErrorItineraryRemoveCarRental = createErrorClass(
    'Remove car rental failed',
  )
  export const ItinerarySelectRoomResponse = createErrorClass('Select room failed')
  export const UnexpectedErrorItinerarySelectRoomResponse = createErrorClass('Select room failed')
  export const ItineraryReplaceRoomResponse = createErrorClass('Replace room failed')
  export const UnexpectedErrorItineraryReplaceRoom = createErrorClass('Replace room failed')
  export const ItineraryRemoveRoomResponse = createErrorClass('Remove room failed')
  export const UnexpectedErrorItineraryRemoveRoom = createErrorClass('Remove room failed')
  export const ItineraryAddFlightResponse = createErrorClass('Add flight failed')
  export const UnexpectedErrorItineraryAddFlight = createErrorClass('Add flight failed')
  export const ItineraryReplaceFlightResponse = createErrorClass('Replace flight failed')
  export const UnexpectedErrorItineraryReplaceFlight = createErrorClass('Replace flight failed')
  export const ItineraryRemoveFlightResponse = createErrorClass('Remove flight failed')
  export const UnexpectedErrorItineraryRemoveFlight = createErrorClass('Remove flight failed')
  export const ItineraryChangeFlightSeatsAssignmentResponse = createErrorClass(
    'Change flight seats failed',
  )
  export const UnexpectedErrorItineraryChangeFlightSeatsAssignment = createErrorClass(
    'Change flight seats failed',
  )

  export const ItineraryRemoveResponse = createErrorClass('Remove Itinerary failed')
  export const UnexpectedErrorItineraryRemove = createErrorClass(
    'Unexpected Remove Itinerary failed',
  )
  export const ItineraryReactivateResponse = createErrorClass('Reactivate Itinerary failed')
  export const UnexpectedErrorItineraryReactivate = createErrorClass(
    'Unexpected Reactivate Itinerary failed',
  )

  export const GetItineraryError = createErrorClass('Get Itinerary error')
  export const GetItineraryUnexpectedError = createErrorClass('Get Itinerary  unexpected error')

  export const GetActiveItineraryError = createErrorClass('Get Active Itinerary error')
  export const GetActiveItineraryUnexpectedError = createErrorClass(
    'Get Active Itinerary  unexpected error',
  )

  export const CreateItineraryError = createErrorClass('Create Itinerary error')
  export const NoItineraryError = createErrorClass('No Itinerary error')
  export const CreateItineraryUnexpectedError = createErrorClass(
    'Create Itinerary  unexpected error',
  )
  export const ItineraryUpdateTripNameResponse = createErrorClass('Failed to update trip name')
  export const UnexpectedErrorItineraryUpdateTripName = createErrorClass(
    'Unexpected error updating trip name',
  )
  export const ItineraryAddRailResponse = createErrorClass('Add rail failed')
  export const UnexpectedErrorItineraryAddRail = createErrorClass('Add rail failed')
  export const ItineraryRemoveRailResponse = createErrorClass('Remove rail failed')
  export const UnexpectedErrorItineraryRemoveRail = createErrorClass('Remove rail failed')
  export const AlignTripError = createErrorClass('Error aligning trip')
  export const UnexpectedAlignTripError = createErrorClass('Unexpected error aligning trip')
}

export type ItineraryErrorsInstances = InstanceType<
  typeof ItineraryErrors[keyof typeof ItineraryErrors]
>
