/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

export function useToggleClassName(element: HTMLElement, className: string, isApply?: boolean) {
  useEffect(() => {
    if (!isApply) {
      return
    }
    if (isApply) {
      element.classList.add(className)
    }
    return () => {
      element.classList.remove(className)
    }
  }, [isApply])
}
