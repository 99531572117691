import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '@fiji/utils/money'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import type { Money, RailPolicies } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import {
  RailSearchSegmentRates,
  RailSearchSegmentFirstClassRate,
  RailSearchSegmentStandartRate,
  RailSearchRateLabelContainer,
} from '../layout/mobile/rail-search-segment-mobile-styled'

type Props = {
  rateId?: string
  standartPrice: Money | null
  firstClassPrice: Money | null
  railPolicies?: RailPolicies
}

const i18Base = 'RailDetails'

export default function RailSearchRateLabel({
  rateId,
  standartPrice,
  firstClassPrice,
  railPolicies,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()

  const formattedStandartPrice = standartPrice?.currency
    ? formatPrice(standartPrice, { isInteger: false })
    : null
  const formattedFirstClassPrice = firstClassPrice?.currency
    ? formatPrice(firstClassPrice, { isInteger: false })
    : null
  const standartLabel = t(i18Base + '.AriaLabels.StandartLabel', { price: formattedStandartPrice })
  const firstClassLabel = t(i18Base + '.AriaLabels.FirstClassLabel', {
    price: formattedFirstClassPrice,
  })
  const showPrice = formattedStandartPrice !== null || formattedFirstClassPrice !== null

  const isFirstClassOOPShown =
    featureFlags.isRailOutofPolicyEnabled && !railPolicies?.isRailFirstClassAllowed

  return (
    <RailSearchRateLabelContainer id={rateId}>
      {showPrice && (
        <RailSearchSegmentRates data-tracking-id="amount">
          {formattedStandartPrice && (
            <RailSearchSegmentStandartRate aria-label={standartLabel}>
              {formattedStandartPrice}
            </RailSearchSegmentStandartRate>
          )}
          {formattedFirstClassPrice && (
            <RailSearchSegmentFirstClassRate aria-label={firstClassLabel}>
              {isFirstClassOOPShown && <Icon name="outOfPolicyPWA" size="normal" />}
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18Base + '.FirstClassPrefix')} {formattedFirstClassPrice}
              </Text>
            </RailSearchSegmentFirstClassRate>
          )}
        </RailSearchSegmentRates>
      )}
    </RailSearchRateLabelContainer>
  )
}
