import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { useSettingsContext } from '@etta/modules/settings/interface/use-settings-context'

export const useRedressNumber = () => {
  const { accountInformationActions } = useSettingsContext()
  const { isDelegatedOrImpersonated } = accountInformationActions.getAccountInformation()

  const { checkoutInfoDocumentAction, checkoutInfoDocumentStore } = useCheckoutInfoContext()

  const {
    removeToggle,
    redressNumberValidator,
    isDocumentTouched,
    oldDocument,
  } = checkoutInfoDocumentStore

  const {
    handleTravelerDocumentFieldChange,
    handleRemoveTravelerDocument,
    getDisabledCountries,
    handleDocumentFieldFocusClear,
  } = checkoutInfoDocumentAction

  return {
    handleTravelerDocumentFieldChange,
    handleRemoveTravelerDocument,
    handleDocumentFieldFocusClear,
    getDisabledCountries,
    removeToggle,
    redressNumberValidator,
    isDocumentTouched,
    oldDocument,
    isDelegatedOrImpersonated,
  }
}
