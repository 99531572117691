import { Result } from 'fnscript'
import { Adapter } from '@etta/di'
import { DelegateErrors } from '../core/errors'
import { DelegateDataProvider } from './data-provider/delegate.data-provider'
import type {
  DelegateAdapterResult,
  UpdateDelegatedIdTokenInput,
  UpdateDelegatedIdCookieInput,
} from './types'

@Adapter()
export class DelegateAdapter {
  constructor(private readonly delegateDataProvider: DelegateDataProvider) {}

  async updateDelegatedIdToken(
    input: UpdateDelegatedIdTokenInput,
  ): DelegateAdapterResult<{ token: string }> {
    try {
      const { data, errors } = await this.delegateDataProvider.updateDelegatedIdToken(input)
      if (errors) {
        return Result.Err(new DelegateErrors.UpdateDelegateTokenResponse(errors))
      }

      if (!data) {
        return Result.Err(
          new DelegateErrors.UpdateDelegateTokenResponse('no reponse body provided'),
        )
      }

      return Result.Ok({ token: data.updateDelegatedIdToken.token })
    } catch (e) {
      return Result.Err(new DelegateErrors.UnexpectedErrorUpdateDelegateToken(e))
    }
  }

  async updateDelegatedIdCookie(
    input: UpdateDelegatedIdCookieInput,
  ): DelegateAdapterResult<{ success: boolean }> {
    try {
      const { data, errors } = await this.delegateDataProvider.updateDelegatedIdCookie(input)

      if (errors) {
        return Result.Err(new DelegateErrors.UpdateDelegateCookieResponse(errors))
      }

      if (!data) {
        return Result.Err(
          new DelegateErrors.UpdateDelegateCookieResponse(
            'no response body provided for cookie token update',
          ),
        )
      }

      return Result.Ok({ success: data.updateDelegatedIdCookie.success })
    } catch (e) {
      return Result.Err(new DelegateErrors.UnexpectedErrorUpdateDelegateToken(e))
    }
  }
}
