import type { ReactNode } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { TravelPolicyDialog } from '@etta/components/travel-policy-dialog/travel-policy-dialog'
import { TaxesAndFeesModal } from '@etta/components/taxes-and-fees-modal/taxes-and-fees-modal'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { ServiceFeeConfigurationValueObject } from '@etta/modules/display-configuration/core/value-objects/service-fee-configuration.value-object'
import { HeaderSkeleton } from './header-skeleton'
import { ListResultHeaderLayout } from './layout'

type Props = {
  title: string
  dataTrackingId?: string
  sortSlot?: string | ReactNode
  rightSlot?: string | ReactNode
  isLoading?: boolean
  flightDate?: string
  flightDateLabel?: string
  isAppliedFiltersVisible?: boolean
  numberOfAppliedFilters?: number
  serviceFeeConfiguration?: ServiceFeeConfigurationValueObject[]
  onAppliedFiltersClick?: () => void
}

export function ListResultHeader({
  title,
  dataTrackingId,
  sortSlot,
  rightSlot,
  isLoading,
  flightDate,
  flightDateLabel,
  serviceFeeConfiguration = [],
  isAppliedFiltersVisible,
  numberOfAppliedFilters,
  onAppliedFiltersClick,
}: Props) {
  const { isShowDelegateName } = useShowDelegateName()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const policyToggle = useTogglePopup()
  const taxesToggle = useTogglePopup()

  const { isServiceFeeEnabled } = featureFlagsStore.flags
  const hasServiceFeeConfiguration = Boolean(serviceFeeConfiguration.length) && isServiceFeeEnabled
  const isTravelPolicyVisible = false

  if (isLoading) {
    return <HeaderSkeleton />
  }

  return (
    <>
      <ListResultHeaderLayout
        title={title}
        dataTrackingId={dataTrackingId}
        sortSlot={sortSlot}
        rightSlot={rightSlot}
        isLoading={isLoading}
        flightDate={flightDate}
        flightDateLabel={flightDateLabel}
        isAppliedFiltersVisible={isAppliedFiltersVisible}
        isShowDelegateName={isShowDelegateName}
        hasServiceFeeConfiguration={hasServiceFeeConfiguration}
        numberOfAppliedFilters={numberOfAppliedFilters}
        onAppliedFiltersClick={onAppliedFiltersClick}
        policyDialogOnOpen={policyToggle.handleOpen}
        taxesDialogOnOpen={taxesToggle.handleOpen}
        isTravelPolicyVisible={isTravelPolicyVisible}
      />

      <TravelPolicyDialog
        isVisible={policyToggle.isOpen}
        handleVisibility={policyToggle.handleClose}
      />

      <TaxesAndFeesModal
        serviceFeeConfiguration={serviceFeeConfiguration}
        isOpen={taxesToggle.isOpen}
        onClose={taxesToggle.handleClose}
      />
    </>
  )
}
