import { Container } from '@etta/di'
import { SafeLogger } from '@fiji/utils/safe-logger'
import type { Constructable } from '@etta/core/type-utils'
import { transport } from './transport'

export class Logger extends SafeLogger {}

export function InjectLogger(loggerName: string) {
  return function (object: Constructable<unknown>, propertyName: string, index?: number) {
    const logger = new Logger(loggerName, { transport })
    Container.registerHandler({ object, index, propertyName, value: (_) => logger })
  }
}
