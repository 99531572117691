import { Service, Inject } from '@etta/di'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import type { User as FijiUser } from '@fiji/store/user'
import { updateUser } from '@fiji/store/user'
import { CookieStore } from '@etta/modules/cookie'
import type { UserEntity } from '@etta/modules/user/core/entities'
import { BaseSettingsStore } from '@etta/modules/settings/interface/stores/base.store'
import { AuthStore } from '@etta/modules/auth/interface/stores/auth.store'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { ScreenType, screenMatcher } from '@fiji/modes'
import { UserStore } from '../stores'
import { CreatePasswordStore } from '../stores/create-password.store'
import { UserAdapter } from '../../infra/user.adapter'
import { UserTrackingActionsService } from './user-tracking-actions.service'
import { UserLoggerService } from './user-logger.service'

@Service()
export class UserService {
  constructor(
    @Inject() private userAdapter: UserAdapter,
    @Inject() private userTrackingActionsService: UserTrackingActionsService,
    @Inject() private userLoggerService: UserLoggerService,
    @Inject() private userStore: UserStore,
    @Inject() private cookieStore: CookieStore,
    @Inject() private fijiStoreProxyService: FijiStoreProxyService,
    @Inject() private baseSettingsStore: BaseSettingsStore,
    @Inject() private authStore: AuthStore,
    @Inject() private historyService: HistoryService,
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
    @Inject() private readonly createPasswordStore: CreatePasswordStore,
  ) {}

  async getUser() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.userStore.setIsLoading(true)
    this.userStore.setIsError(false)
    this.userStore.setUser(null)

    const result = await this.userAdapter.getUser()
    result.match({
      Ok: (user) => {
        this.updateUser(user)
        this.userStore.setIsLoading(false)
      },
      Err: () => {
        this.userStore.setIsError(true)
        this.userStore.setIsLoading(false)
      },
    })
  }

  async reloadUser(customActionsAfterReload?: () => void) {
    this.userStore.setIsError(false)
    this.baseSettingsStore.setIsPartialUserLoading(true)

    const result = await this.userAdapter.getUser()

    result.match({
      Ok: (user) => {
        this.updateUser(user)
        customActionsAfterReload?.()
        this.baseSettingsStore.setIsPartialUserLoading(false)
      },
      Err: () => {
        this.userStore.setIsError(true)
        this.baseSettingsStore.setIsPartialUserLoading(false)
      },
    })
  }

  updateUser(user: UserEntity) {
    const { isUserActivationEnabled } = this.featureFlagsStore.flags
    const isDesktop = screenMatcher.getScreenType() !== ScreenType.Mobile
    this.userStore.setUser(user)
    this.fijiStoreProxyService.dispatch(updateUser(user as FijiUser))
    this.userLoggerService.configureUserLogger(user)
    this.userTrackingActionsService.initUserActions(user)
    // TODO: FRI-493 - remove isUserActivationEnabled for the conditional when the user activation flow implementation is finished

    const isActivationRequired = isUserActivationEnabled && this.userStore.isActivationRequired
    const isPasswordResetRequired =
      this.userStore.isResetPassword && !this.createPasswordStore.isSSOOnlySite
    if (this.authStore.isJustLoggedIn) {
      if (isActivationRequired || isPasswordResetRequired) {
        isDesktop
          ? this.historyService.replace(ROUTES.userActivation)
          : this.userStore.toggleActivateAccountModal.handleOpen()
      } else {
        this.historyService.push(ROUTES.explore)
      }
      this.authStore.setIsJustLogged(false)
      return
    }
    // FRI-1398 open modal to block mobile users with inactive accounts (on page refresh)
    if (!isDesktop && (this.userStore.isActivationRequired || isPasswordResetRequired)) {
      this.userStore.toggleActivateAccountModal.handleOpen()
    }
  }
}
