import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { MediaLayout } from '@etta/ui/media-layout'
import type { SafetyCheckFlight } from '@fiji/types'
import { ExpandedViewItem } from '../expanded-view-item'
import { ModelBlock } from './expanded-view-list-styled'

type Props = {
  safetyCheck: SafetyCheckFlight
  multipleFlights: boolean
}

export function ExpandedViewList({ safetyCheck, multipleFlights }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'
  const { headLines } = safetyCheck

  return (
    <MediaLayout
      mobileSlot={
        <Block data-tracking-id={`safety-check-modal_${safetyCheck.airline}`} marginTop={20}>
          {multipleFlights && (
            <Block marginBottom={11}>
              <Text
                variant="subHeadStrong"
                aria-label={t(i18nBase + '.AirlineNameLabel', {
                  label: safetyCheck.airline,
                })}>
                {safetyCheck.airline}
              </Text>
            </Block>
          )}
          {headLines.map((headLine, index) => (
            <ExpandedViewItem key={index} headLine={headLine} />
          ))}
        </Block>
      }
      desktopSlot={
        <ModelBlock marginTop={16} isFullWidth={!multipleFlights}>
          {multipleFlights && (
            <Block marginBottom={11}>
              <Text
                variant="subHeadStrong"
                aria-label={t(i18nBase + '.AirlineNameLabel', {
                  label: safetyCheck.airline,
                })}>
                {safetyCheck.airline}
              </Text>
            </Block>
          )}
          {headLines.map((headLine, index) => (
            <ExpandedViewItem key={index} headLine={headLine} />
          ))}
        </ModelBlock>
      }
    />
  )
}
