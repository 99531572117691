import styled from 'styled-components'

export const FlightSegmentContainer = styled.div`
  &:first-child {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  }
  &:last-child {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  padding: 12px;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  border-top: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`

export const FlightSegmentContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const MistmatchedSegmentContainer = styled.div`
  background-color: ${(p) => p.theme.colors.background4};
  border-radius: 10px;
  padding: 12px;
`
export const MismatchedSegmentContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const MismatchedSegmentCheckboxWrapper = styled.div`
  margin-left: 4px;
  margin-top: -4px;
  gap: 8px;
  display: flex;
  align-items: flex-start;
`
