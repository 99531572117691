import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type {
  SubmitNewRailSearchInput,
  SubmitNewFlightSearchInput,
  SubmitNewHotelSearchInput,
  SubmitNewCarRentalSearchInput,
} from '@fiji/graphql/hooks'
import { RecentSearchErrors } from '../core/errors'
import { RecentSearchDataProvider } from './data-providers/recent-searches.data-provider'

@Adapter()
export class SubmitRecentSearchAdapter {
  constructor(
    @Inject()
    private recentSearchDataProvider: RecentSearchDataProvider,
  ) {}

  async submitNewCarRentalSearch({ searchParams }: SubmitNewCarRentalSearchInput) {
    try {
      const { data, errors } = await this.recentSearchDataProvider.submitNewCarRentalSearch({
        searchParams,
      })
      if (errors) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errors))
      }

      if (data?.submitNewCarRentalSearch.errorMessages) {
        const errorMessages = data?.submitNewCarRentalSearch.errorMessages
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errorMessages))
      }

      if (!data?.submitNewCarRentalSearch.isOk) {
        return Result.Err(
          new RecentSearchErrors.SubmitNewCarRentalSearchFail('car rental search was not submited'),
        )
      }

      return Result.Ok(data.submitNewCarRentalSearch.isOk)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async submitNewFlightSearch({ searchParams }: SubmitNewFlightSearchInput) {
    try {
      const { data, errors } = await this.recentSearchDataProvider.submitNewFlightSearch({
        searchParams,
      })
      if (errors) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errors))
      }

      if (data?.submitNewFlightSearch.errorMessages) {
        const errorMessages = data?.submitNewFlightSearch.errorMessages
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errorMessages))
      }

      if (!data?.submitNewFlightSearch.isOk) {
        return Result.Err(
          new RecentSearchErrors.SubmitNewCarRentalSearchFail('car rental search was not submited'),
        )
      }

      return Result.Ok(data.submitNewFlightSearch.isOk)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async submitNewHotelSearch({ searchParams }: SubmitNewHotelSearchInput) {
    try {
      const { data, errors } = await this.recentSearchDataProvider.submitNewHotelSearch({
        searchParams,
      })
      if (errors) {
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errors))
      }
      if (data?.submitNewHotelSearch.errorMessages) {
        const errorMessages = data?.submitNewHotelSearch.errorMessages
        return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(errorMessages))
      }

      if (!data?.submitNewHotelSearch.isOk) {
        return Result.Err(
          new RecentSearchErrors.SubmitNewCarRentalSearchFail('car rental search was not submited'),
        )
      }

      return Result.Ok(data.submitNewHotelSearch.isOk)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewCarRentalSearchFail(e))
    }
  }

  async submitNewRailSearch({ searchParams }: SubmitNewRailSearchInput) {
    try {
      const { data, errors } = await this.recentSearchDataProvider.submitNewRailSearch({
        searchParams,
      })
      if (errors) {
        return Result.Err(new RecentSearchErrors.SubmitNewRailSearchFail(errors))
      }
      if (data?.submitNewRailSearch.errorMessages) {
        const errorMessages = data?.submitNewRailSearch.errorMessages
        return Result.Err(new RecentSearchErrors.SubmitNewRailSearchFail(errorMessages))
      }

      if (!data?.submitNewRailSearch.isOk) {
        return Result.Err(
          new RecentSearchErrors.SubmitNewRailSearchFail('rail search was not submited'),
        )
      }

      return Result.Ok(data.submitNewRailSearch.isOk)
    } catch (e) {
      return Result.Err(new RecentSearchErrors.SubmitNewRailSearchFail(e))
    }
  }
}
