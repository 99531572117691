import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import type { TripStatus } from '@fiji/graphql/types'
import { useDetailsHeader } from '../use-details-header'
import {
  TitleSection,
  TitleDate,
  Status,
  HeadingSection,
  Heading,
  IconWrapper,
} from './details-section-styled'

type Props = {
  tripName: string
  tripStatus: TripStatus
  startTime: string
  endTime: string
  onEditClick: () => void
  isTripNameChangable: boolean
  isOpenReturn?: boolean
}

export const DetailsSection = observer(function DetailsSection({
  tripName,
  tripStatus,
  startTime,
  endTime,
  onEditClick,
  isTripNameChangable,
  isOpenReturn,
}: Props) {
  const {
    ariaLabel,
    titleDate,
    tripTitle,
    statusTitle,
    statusColor,
    editIconAriaLabel,
  } = useDetailsHeader({
    startTime,
    endTime,
    tripName,
    tripStatus,
    isOpenReturn,
  })

  return (
    <TitleSection role="heading" aria-level={1} aria-label={ariaLabel}>
      <HeadingSection variant="title2" color="mainText">
        <Block>
          <TitleDate variant="footnoteStrong" color="bodyText1" data-tracking-id="date-booked-text">
            {titleDate}
          </TitleDate>
          {statusTitle && (
            <Status variant="captionStrongCaps" color={statusColor}>
              • {statusTitle}
            </Status>
          )}
        </Block>
        <Heading data-tracking-id="trip-name-text">{tripTitle}</Heading>
      </HeadingSection>
      {isTripNameChangable && (
        <IconWrapper
          role="button"
          tabIndex={0}
          onClick={onEditClick}
          data-tracking-id="trip-review-edit-trip-name-button"
          aria-label={editIconAriaLabel}>
          <Icon name="editPWA" size="medium" />
        </IconWrapper>
      )}
    </TitleSection>
  )
})
