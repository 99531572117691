import type { OptionType } from '@etta/ui/select'

type Args = {
  options: OptionType<string | undefined>[]
  value?: string | null
  isCaseInSensitive?: boolean
}

export function resolveInitialValue({ options, value, isCaseInSensitive }: Args) {
  if (!value) {
    return undefined
  }

  if (isCaseInSensitive) {
    return options.find((el) => el.value?.toLowerCase() === value.toLowerCase())?.value
  }

  return options.find((el) => el.value === value)?.value
}
