import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Container, CardIconContainer, Text } from './add-credit-card-styled'

type Props = {
  onClick: () => void
}

export function AddCreditCard({ onClick }: Props) {
  const { t } = useTranslation()

  return (
    <Container
      variant="solid"
      btnType="tertiary"
      fullWidth
      onClick={onClick}
      data-tracking-id="purchase-payment-add-credit-card-button"
      leftIcon={
        <CardIconContainer>
          <Icon name="cardPWA" size="medium" />
        </CardIconContainer>
      }
      rightIcon={
        <Block marginLeft="auto">
          <Icon name="addPWA" />
        </Block>
      }>
      <Text>{t('CreditCardItem.AddNewCard')}</Text>
    </Container>
  )
}
