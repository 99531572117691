import type { Rate, RateFragment, Maybe } from '@fiji/graphql/types'

type Params = {
  transactionFees?: Maybe<RateFragment>
  taxes?: Maybe<RateFragment>
}

export function mapFeesAndTaxesRate({ transactionFees, taxes }: Params): Maybe<Rate> {
  if (!taxes || !transactionFees) {
    return null
  }

  if (!taxes.secondary || !transactionFees.secondary) {
    return null
  }

  return {
    primary: {
      amount: taxes.primary.amount + transactionFees.primary.amount,
      currency: taxes.primary.currency,
    },
    secondary: {
      amount: taxes.secondary.amount + transactionFees.secondary.amount,
      currency: taxes.secondary.currency,
    },
  }
}
