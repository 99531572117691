import { makeAutoObservable } from 'mobx'

export class Toggle {
  isOpen: boolean

  handleOpen = () => {
    this.isOpen = true
  }

  handleClose = () => {
    this.isOpen = false
  }

  handleToggle = () => {
    if (this.isOpen) {
      this.handleClose()
      return
    }
    this.handleOpen()
  }

  constructor(isOpen: boolean = false) {
    this.isOpen = isOpen
    makeAutoObservable(this)
  }
}
