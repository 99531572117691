import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { templates } from '@fiji/hooks/use-share-via-email'
import type { CarServicePostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Props = {
  tripName?: string | null
  carService: CarServicePostBookingValueObject
}

export function useActionButtons({ tripName, carService }: Props) {
  const { pickUp, dropOff } = carService

  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({
      tripName,
      startDate: pickUp?.time || '',
      endDate: dropOff?.time || '',
    }),
    body: templates.postBookingCarService({ carService }),
  })

  return { handleShare }
}
