import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@etta/ui/checkbox'
import { Text } from '@etta/ui/text'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { Block } from '@etta/ui/block'
import { useUnusedTicketOptContext } from '@etta/modules/unused-ticket-opt'
import { usePaymentSummaryContext } from '@etta/modules/booking/interface/payment-summary/use-payment-summary'
import { TicketExchangeAcknowledgementSkeleton } from '../ticket-exchange-acknowledge-skeleton'
import { Container } from './ticket-exchange-acknowledge-mobile.styled'

const i18nBase = 'PaymentSummary'

export const TicketExchangeAcknowledgementMobile = observer(
  function TicketExchangeAcknowledgmentDesktop() {
    const { t } = useTranslation()
    const {
      checkoutInfoStore: { isRulesAcknowledgementRequired },
    } = useCheckoutInfoContext()

    const {
      paymentSummaryActions,
      paymentSummaryStore: { isAcceptAirTicketTerms, paymentSummaryValidator },
    } = usePaymentSummaryContext()

    const { unusedTicketOptStore } = useUnusedTicketOptContext()

    if (!isRulesAcknowledgementRequired) {
      return null
    }

    if (unusedTicketOptStore.isLoading) {
      return <TicketExchangeAcknowledgementSkeleton />
    }

    return (
      <>
        <Container
          data-tracking-id="ticket-exchange-acknowledgement"
          isError={!!paymentSummaryValidator.errors.acceptAirTicketTerms}>
          <Checkbox
            value={'userAcknowledge'}
            label={
              <Text variant="footnoteStrong" color="mainText">
                {t(`${i18nBase}.UnusedTicketAcknowledgement`)}
              </Text>
            }
            checked={isAcceptAirTicketTerms}
            onChange={paymentSummaryActions.handleAcceptAirTermsToggle}
          />
        </Container>
        {paymentSummaryValidator.errors.acceptAirTicketTerms && (
          <Block paddingTop={6}>
            <Text variant="footnoteMedium" color="error" isBlock>
              {t(paymentSummaryValidator.errors.acceptAirTicketTerms)}
            </Text>
          </Block>
        )}
      </>
    )
  },
)
