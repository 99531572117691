import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripCostSummary } from '@fiji/graphql/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Icon } from '@etta/ui/icon'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { Section, PreviousBookingContentSkin } from '../types'
import {
  PreviousBookingContent,
  PreviousBookingToggle,
  PreviousBookingCosts,
} from '../payment-summary-styled'
import { Flights } from '../sections/flights'
import { Hotels } from '../sections/hotels'
import { CarRentals } from '../sections/car-rentals'

type Props = {
  tripCost?: TripCostSummary | null
  flights?: Section
  hotels?: Section
  carRentals?: Section
  isExtendedByDefault?: boolean
}

export function PreviousBooking({
  flights,
  hotels,
  carRentals,
  tripCost,
  isExtendedByDefault = false,
}: Props) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { isOpen, handleToggle } = useTogglePopup(isExtendedByDefault)
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const isDesktop = screenMatcher.getScreenType() === ScreenType.Desktop
  const previousBookingContentSkin: PreviousBookingContentSkin = isDesktop
    ? 'narrow-desktop'
    : 'wide-mobile'

  const mappedFlight = flights?.segments.filter(segmentTypeCheckActions.isFlightSegment)
  const mappedHotel = hotels?.segments.filter(segmentTypeCheckActions.isHotelSegment)
  const mappedCarRental = carRentals?.segments.filter(segmentTypeCheckActions.isCarRentalSegment)

  return (
    <PreviousBookingContent skin={previousBookingContentSkin}>
      <PreviousBookingToggle
        skin={previousBookingContentSkin}
        onClick={handleToggle}
        isActive={isOpen}>
        <span>{t('PaymentSummary.PreviousPaymentInformation')}</span>
        <Icon size="small" name="chevronDownPWA" />
      </PreviousBookingToggle>
      <PreviousBookingCosts
        isActive={isOpen}
        heightRef={ref.current?.offsetHeight || 0}
        childCount={ref.current?.childElementCount || 0}>
        <div ref={ref}>
          <Flights
            segments={mappedFlight}
            segmentState={flights?.segmentState}
            flightTripCost={flights?.tripCost}
            tripCostSummary={tripCost}
            isFromPreviousBooking
          />
          <Hotels
            segments={mappedHotel}
            segmentState={hotels?.segmentState}
            hotelTripCost={hotels?.tripCost}
            previousBookingRate={hotels?.previousBookingRate}
            isPayingNow={hotels?.isPayNow}
            isFromPreviousBooking
          />
          <CarRentals
            segments={mappedCarRental}
            segmentState={carRentals?.segmentState}
            carTripCost={carRentals?.tripCost}
            previousBookingRate={carRentals?.previousBookingRate}
            isPayingNow={carRentals?.isPayNow}
            isFromPreviousBooking
          />
        </div>
      </PreviousBookingCosts>
    </PreviousBookingContent>
  )
}
