import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'
import { SegmentState, RoomType } from '@fiji/graphql/types'
import { useDeleteHotel } from '../hooks/use-delete-hotel'

type Args = {
  hotelDetails: HotelDetailsType
  itineraryId: string
  roomKey: string
  isTripExist: boolean
  onClose: () => void
}

export function useHotelDetails({
  hotelDetails,
  itineraryId,
  roomKey,
  isTripExist,
  onClose,
}: Args) {
  const { t } = useTranslation()
  const i18nBase = 'HotelDetails'
  const removeDialogToggle = useTogglePopup()
  const changeDialogToggle = useTogglePopup()
  const {
    feesAndTaxesRate,
    totalCostRate,
    netRate,
    serviceFeeRate,
    segmentState,
    rooms,
  } = hotelDetails
  const roomType = rooms[0]?.type || RoomType.Unknown
  const isCancelable = !isTripExist || segmentState === SegmentState.New

  const totalCostTitle = useMemo(() => {
    const { mainCost: totalCostTitle } = formatRate(totalCostRate, {
      morpheme: 'none',
    })
    return totalCostTitle
  }, [totalCostRate])

  const priceDetailsList = useMemo(() => {
    return [
      netRate
        ? {
            title: t(`${i18nBase}.Room`, { roomType }),
            value: formatRate(netRate, { morpheme: 'none' }).mainCost,
          }
        : null,
      feesAndTaxesRate
        ? {
            title: t(`${i18nBase}.Taxes`),
            value: formatRate(feesAndTaxesRate, { morpheme: 'none' }).mainCost,
          }
        : null,
      serviceFeeRate
        ? {
            title: t(`${i18nBase}.ServiceFee`),
            value: formatRate(serviceFeeRate, { morpheme: 'none' }).mainCost,
          }
        : null,
    ]
  }, [netRate, feesAndTaxesRate, serviceFeeRate, roomType, t])

  const { handleRemoveHotel, viewStateParams } = useDeleteHotel({
    itineraryId,
    roomKey,
    onCloseAfterward: onClose,
  })

  const onViewStateDone = useCallback(() => {
    changeDialogToggle.handleClose()
    removeDialogToggle.handleClose()
    viewStateParams.onClose()
  }, [changeDialogToggle, removeDialogToggle, viewStateParams])

  return {
    onViewStateDone,
    priceDetailsList,
    totalCostTitle,
    removeDialogToggle,
    changeDialogToggle,
    handleRemoveHotel,
    viewStateParams,
    isCancelable,
  }
}
