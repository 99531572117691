import { useCallback } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import Calendar from '@etta/ui/calendar'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { getFocusedInput } from '@fiji/hooks/use-date-picker'
import { WeekHeader, WeekHeaderContainer } from './controls/week-header'
import { DatePickerContainer } from './date-picker-componets'
import Controls from './controls'
import ControlsFooter from './controls/controls-footer/controls-footer'

type Props = {
  date?: Date | null
  dateEnd?: Date | null
  previousDate?: Date
  minDate?: Date
  maxDate?: Date
  startDateLegend?: Date | null
  endDateLegend?: Date | null
  isEndDateSame?: boolean
  isRange?: boolean
  onSelect: (result: Dates) => void
  startTitle?: string
  endTitle?: string
  highlightDates?: (Date | undefined)[]
  numberOfMonths?: number
  isOutsideRange?: () => boolean
  isOpen: boolean
  tabIndex?: number
  isReturnDate: boolean
  isDepartureDatePickerDisabled?: boolean
  onClose?: () => void
}

export function DatePicker({
  date,
  dateEnd,
  previousDate,
  onSelect,
  isRange,
  minDate,
  maxDate,
  startDateLegend,
  endDateLegend,
  startTitle,
  endTitle,
  isEndDateSame,
  highlightDates,
  numberOfMonths = 2,
  isOutsideRange,
  isOpen,
  tabIndex = 0,
  isReturnDate,
  isDepartureDatePickerDisabled,
  onClose,
}: Props) {
  const focusedInput = getFocusedInput({
    date,
    dateEnd,
    isRange,
    isReturnDate,
    isDepartureDatePickerDisabled,
  })
  const handleDaySelect = useCallback(
    (dates: Dates) => {
      const areEndDatesSame =
        !!dateEnd && !!dates.dateEnd && dateEnd.getTime() === dates.dateEnd.getTime()
      const areStartDatesSame = !!date && !!dates.date && date.getTime() === dates.date.getTime()
      const shouldStartDateChange = !areStartDatesSame && focusedInput === 'startDate'

      if (!isRange || !areEndDatesSame) {
        onSelect(dates)
        return
      }

      if (shouldStartDateChange || !isReturnDate) {
        onSelect({ date: dates.date })
        return
      }

      onSelect(dates)
    },
    [date, dateEnd, focusedInput, isRange, isReturnDate, onSelect],
  )

  return (
    <DatePickerContainer tabIndex={tabIndex}>
      <Controls
        focusedInput={focusedInput}
        date={date}
        dateEnd={dateEnd}
        isRange={isRange}
        startTitle={startTitle}
        endTitle={endTitle}
        isOpen={isOpen}
      />
      <Calendar
        isEndDateSame={isEndDateSame}
        date={date}
        previousDate={previousDate}
        dateEnd={isRange ? dateEnd : null}
        minDate={minDate}
        maxDate={maxDate}
        onDaySelect={handleDaySelect}
        focusedInput={focusedInput}
        highlightDates={highlightDates}
        numberOfMonths={numberOfMonths}
        isOutsideRange={isOutsideRange}
      />
      <MediaLayout
        mobileSlot={null}
        desktopSlot={
          <>
            <WeekHeaderContainer weekHeaderOfFirstMonth={true}>
              <WeekHeader />
            </WeekHeaderContainer>
            {(isRange || (numberOfMonths && numberOfMonths > 1)) && (
              <WeekHeaderContainer weekHeaderOfFirstMonth={false}>
                <WeekHeader />
              </WeekHeaderContainer>
            )}
          </>
        }
      />
      <ControlsFooter date={startDateLegend} dateEnd={endDateLegend} onClose={onClose} />
    </DatePickerContainer>
  )
}
