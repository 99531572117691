import { Text } from '@etta/ui/text'
import { HeaderContentWrapper } from './desktop-styled'
import type { LayoutProps } from './types'

export function TripCarRentalHeadlineDesktop({ carRentalTitle }: LayoutProps) {
  return (
    <HeaderContentWrapper>
      <Text variant="title3" color="mainText2">
        {carRentalTitle}
      </Text>
    </HeaderContentWrapper>
  )
}
