import styled from 'styled-components'
import { Button as UIButton } from '@etta/ui/button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 32px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const Button = styled(UIButton)`
  padding: 0;
`
