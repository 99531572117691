import { observer } from 'mobx-react-lite'
import { EcoCheckSummary } from '@etta/components/eco-check-summary'
import type { Props } from './types'
import { useTripDetailsEcoCheckSummary } from './use-trip-details-eco-check-summary'

export const TripDetailsEcoCheckSummary = observer(function TripDetailsEcoCheckSummary({
  tripEmissionsResult,
  segments,
}: Props) {
  const result = useTripDetailsEcoCheckSummary({ tripEmissionsResult })

  return (
    <EcoCheckSummary
      tapHighlightColor="background1"
      page="trip-details"
      {...result}
      segments={segments}
    />
  )
})
