import { Inject, Store } from '@etta/di'
import i18n from '@fiji/i18n'
import type { Gender } from '@etta/core/enums'
import { OptionViewTypes } from '@etta/ui/drop-down-list'
import type { UserSuffix, UserTitle } from '@etta/modules/user'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import type { TimeZoneEntity } from '@etta/modules/settings/core/entities/time-zone.entity'
import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'
import type { HomeAddressFieldsSettings } from '@etta/modules/settings/interface/stores/profile-field-settings/account-information-fields'
import { Validator } from '@etta/interface/services/validator'
import { AccountActivationValidatorMaker } from '../validator-maker'
import { UserProfileStore } from './user-profile.store'

type ValidatorType = ReturnType<
  typeof AccountActivationValidatorMaker.prototype.makeUserInfoValidator
>

@Store()
export class UserInformationStore {
  private readonly _userInfoValidator: ValidatorType

  private _isDobTouched: boolean = false
  private _timeZoneOptions: TimeZoneEntity[] | null = null

  private _additionalInformationValidator: Validator = new Validator({})

  constructor(
    @Inject() private readonly fieldSettingsStore: FieldSettingsStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {
    const validatorMaker = new AccountActivationValidatorMaker(this.fieldSettingsStore)
    this._userInfoValidator = validatorMaker.makeUserInfoValidator()
  }

  setFormValue(
    key: Parameters<typeof this._userInfoValidator.onFieldChange>[0],
    value: string | boolean | UserTitle | UserSuffix | Gender | null | Date,
  ) {
    this._userInfoValidator.onFieldChange(key, value)
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setIsDobTouched(value: boolean) {
    this._isDobTouched = value
  }

  setTimeZoneOptions(options: TimeZoneEntity[]) {
    this._timeZoneOptions = options
  }

  setIsConfEmailInputVisible(value: boolean) {
    this.setFormValue('showConfirmationEmail', value)

    if (!value) {
      this.setFormValue('confirmationEmail', '')
    }
  }

  get fieldSettings() {
    return this.fieldSettingsStore.fieldsSettings
  }

  get isConfEmailInputVisible(): boolean {
    return this._userInfoValidator.values.showConfirmationEmail
  }

  get emergencyRelationships() {
    const Relationships = {
      SpousePartner: 'Spouse/domestic partner',
      Parent: 'Parent',
      Child: 'Child',
      Relative: 'Relative',
      Business: 'Business',
      Neighbor: 'Neighbor',
      Friend: 'Friend',
      Other: 'Other',
    }

    return Object.keys(Relationships).map((key) => ({
      label: i18n.t(`Settings.EmergencyContact.Modal.RelationshipOptions.` + key),
      value: key,
      viewType: OptionViewTypes.Option,
    }))
  }

  get isDelegatedOrImpersonated() {
    return this.userStore.isDelegatedOrImpersonated
  }

  get profileOptions() {
    const profile = this.userStore.profile
    return {
      genders: profile?.genderOptions || [],
      suffixes: profile?.suffixOptions || [],
      titles: profile?.titleOptions || [],
    }
  }

  get timeZoneOptions() {
    return this._timeZoneOptions
  }

  get isDobTouched(): boolean {
    return this._isDobTouched
  }

  get validators() {
    return {
      userInfoValidator: this._userInfoValidator,
      additionalInformationValidator: this._additionalInformationValidator,
    }
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  get personalContactEmail() {
    return this.userStore.profile?.personalContact?.email
  }

  get shouldRenderedHomeAddressFields() {
    const displayedHomeAddressKeys: Array<keyof typeof HomeAddressFieldsSettings> = [
      'addressBlock',
      AddressFields.Country,
      AddressFields.Mailstop,
    ]

    return displayedHomeAddressKeys.some(
      (key) =>
        this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.HomeAddress][key]
          .isRenderedOnActivationPage,
    )
  }

  get shouldRenderedBusinessEmailFields() {
    return this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.BusinessContact][
      'businessEmail'
    ].isRenderedOnActivationPage
  }

  get shouldRenderedPersonalInformationFields() {
    const personalInformationFields = Object.keys(
      this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.PersonalInformation],
    )
    return personalInformationFields.some(
      (key) =>
        this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.PersonalInformation][key]
          .isRenderedOnActivationPage,
    )
  }

  get shouldRenderedEmergencyContactFields() {
    const emergencyContactFields = Object.keys(
      this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.EmergencyContact],
    )
    return emergencyContactFields
      .filter((item) => Object.values(AddressFields).every((field) => item !== field))
      .some(
        (key) =>
          this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.EmergencyContact][key]
            .isRenderedOnActivationPage,
      )
  }

  get shouldRenderedEmergencyContactAddress() {
    const addressFieldKeys = [AddressFields.Country, AddressFields.Mailstop, 'addressBlock']
    return addressFieldKeys.some(
      (key) =>
        this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.EmergencyContact][key]
          .isRenderedOnActivationPage,
    )
  }

  get shouldRenderedAdditionalPersonalInformationFields() {
    return [
      this.userProfileStore.userProfile?.personalInformation.additionalInformation.length,
      this.userProfileStore.userProfile?.emergencyContact.additionalInformation.length,
      this.userProfileStore.userProfile?.homeAddress.additionalInformation.length,
    ].some(Boolean)
  }
}
