import type { AnchorsMap } from '../types'

export function getCurrentAnchorName(anchors: AnchorsMap, zeroValue: number) {
  let usedName = ''
  let closestValue = Number.MIN_SAFE_INTEGER
  for (const key in anchors) {
    if (!anchors[key].current) {
      continue
    }

    const top = anchors[key].current?.getBoundingClientRect().top || Number.MIN_SAFE_INTEGER
    const checkValue = top - zeroValue

    if (checkValue <= 0 && top > closestValue) {
      closestValue = top
      usedName = key
    }
  }
  return usedName
}
