import { Action, Inject } from '@etta/di'
import { CreateTripService } from '@etta/modules/review-trip/interface/services'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { ROUTES } from '@fiji/routes'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import { CarRentalService } from '../services/car-rental.service'
import { CarRentalStore } from '../stores/car-rental.store'
import { CarRentalSearchService } from '../services/car-rental-search.service'
import { CarRentalSearchStore } from '../stores/car-rental-search.store'
import { CarRentalInputService } from '../services/car-rental-input.service'
import { CarRentalQueryService } from '../services/car-rental-query.service'
import { CarFiltersStore } from '../stores/car-filters.store'
import type { CarRentalInputValueObject } from '../../core/value-objects'
import { CarRentalFilterMapper } from '../mappers'
import { CarRentalFilterActions } from './car-rental-filters.actions'

@Action()
export class CarRentalSearchActions {
  constructor(
    @Inject()
    private readonly carRentalService: CarRentalService,

    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,

    @Inject()
    private readonly carRentalStore: CarRentalStore,

    @Inject()
    private readonly carRentalSearchService: CarRentalSearchService,

    @Inject()
    private readonly createTripService: CreateTripService,

    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,

    @Inject()
    private readonly carRentalInputService: CarRentalInputService,

    @Inject()
    private readonly tripStore: ReviewTripStore,

    @Inject()
    private readonly carRentalQueryService: CarRentalQueryService,

    @Inject()
    private readonly historyStore: HistoryStore,

    @Inject()
    private readonly carRentalFiltersAction: CarRentalFilterActions,

    @Inject()
    private readonly carFiltersStore: CarFiltersStore,
  ) {}

  handleSetViewState(value: TripStateStatus) {
    this.carRentalSearchStore.setViewState(value)
  }

  handleResetViewState() {
    this.carRentalSearchStore.setViewState(null)
  }

  handleLoadSelectedCarRentalKey(carRentalKey: string | null) {
    this.carRentalSearchStore.setSelectedCarRentalKey(carRentalKey)
  }

  handleClearSelectedCarRentalKey() {
    this.carRentalSearchStore.setSelectedCarRentalKey(null)
  }

  async handleLoadCarRentals({ withDefaultCompanyFilter }: { withDefaultCompanyFilter: boolean }) {
    const {
      isPhoenixCarRentalSearchEnabled,
      isMod2FlowEnabled,
      isMod2DoubleWriteEnabled,
    } = this.displayConfigurationStore
    if (isMod2FlowEnabled || isMod2DoubleWriteEnabled) {
      this.createTrip()
    }
    this.carRentalSearchStore.dropOffset()
    this.carRentalSearchStore.dropFilters()
    let input = this.carRentalInputService.getCarRentalInput()

    if (withDefaultCompanyFilter) {
      input = this.applyCompanySettingFilterToCarRentalInput(input)
    }

    if (isPhoenixCarRentalSearchEnabled) {
      await this.createCarRentalSearch(input)
      return
    }

    await this.carRentalService.getCarRentals(input)
  }

  private applyCompanySettingFilterToCarRentalInput(
    input: CarRentalInputValueObject,
  ): CarRentalInputValueObject {
    this.carRentalFiltersAction.handleResetToDefaultFilters()
    const filters = this.carFiltersStore.getDefaultFiltersFromConfiguration()
    const inputCarRentalFilters = CarRentalFilterMapper.toCarRentalInputValueObjectFilters(filters)

    return { ...input, ...inputCarRentalFilters }
  }

  handleLoadMoreCarRentals() {
    const input = this.carRentalInputService.getCarRentalInput()

    this.carRentalService.loadMoreCarRentals(input)
  }

  async handleRefetchCarRentals(forceRefetch?: boolean) {
    this.carRentalSearchStore.dropOffset()
    const input = this.carRentalInputService.getCarRentalInput()

    const { isPhoenixCarRentalSearchEnabled } = this.displayConfigurationStore

    if (this.tripStore.isTripCreatingError) {
      await this.createTrip()
    }

    if (isPhoenixCarRentalSearchEnabled && this.carRentalSearchStore.isErrorCreatingSearch) {
      await this.createCarRentalSearch(input)
    }

    if (this.carRentalSearchStore.isErrorCarRentals || forceRefetch) {
      await this.carRentalService.refetchGetCarRentals(input)
    }
  }

  async createTrip() {
    const tripId = this.carRentalStore.queryParams.itineraryId

    if (tripId) {
      this.tripStore.setTripId(tripId)
      return tripId
    }

    await this.createTripService.createTrip()

    if (this.historyStore.matchPathname(ROUTES.carRental.results)) {
      this.carRentalQueryService.appendQueryParams({ itineraryId: this.tripStore.tripId })
    }
    return this.tripStore.tripId
  }

  private async createCarRentalSearch(input: CarRentalInputValueObject) {
    await this.carRentalSearchService.createCarRentalSearch(input)

    const searchId = this.carRentalSearchStore.searchId

    if (!searchId) {
      return
    }

    this.carRentalQueryService.appendQueryParams({ searchId })
  }
}
