import styled from 'styled-components'

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`

export const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`
