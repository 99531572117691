import type { SafeLogger } from '@fiji/utils/safe-logger'
import type { GraphQLOperation, CacheEntry } from './types'
import { getKey } from './get-hash'
import { getResponseEntry } from './get-response-entry'
import { checkAbilityToProcess } from './check-ability-to-process'
import { flushByTimeout } from './flush-by-timeout'
import type { Store } from './store'

const DAY_MS = 1000 * 60 * 60 * 24
const TTL = DAY_MS * 7 // 1 week

type Args = {
  logger: SafeLogger
  store: Store
  withFlush?: boolean
}

export class GraphqlCache {
  private readonly store: Store
  private readonly logger: SafeLogger

  constructor(props: Args) {
    const { logger, store, withFlush = false } = props
    this.logger = logger
    this.store = store
    if (withFlush) {
      flushByTimeout({
        store,
        ttl: TTL,
        logger,
      })
    }
  }

  async get(request: Request) {
    try {
      const requestParams = await request.json()
      const isAbleToRead = checkAbilityToProcess(requestParams)
      if (!isAbleToRead) {
        return null
      }

      const key = getKey(requestParams)
      const data = await this.store.get<CacheEntry>(key)

      if (!data) {
        return null
      }

      return data
    } catch (e) {
      this.logger.error('get request', e)

      return null
    }
  }

  async set(request: Request, response: Response) {
    const requestParams: GraphQLOperation = await request.json()

    const isAbleToSave = checkAbilityToProcess(requestParams)
    if (!isAbleToSave) {
      return
    }

    const storingValue = await getResponseEntry(requestParams, response)

    if (storingValue.response.body.errors?.length) {
      return
    }

    const key = getKey(requestParams)

    this.logger.log('save request', {
      key,
      requestParams,
    })

    await this.store.set(key, storingValue)
  }

  async clearCache() {
    await this.store.clear()
  }
}
