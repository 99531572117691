import { Profile } from '@etta/screens/profile'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const profileRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.profile.main,
      exact: true,
    },
    documentTitle: getDocumentTitle('Profile'),
    transitionIndex: 7,
    transitionGroup: 'fade-through',
    component: () => <Profile />,
  },
]
