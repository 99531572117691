import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { Container, CancelIcon } from './trip-cancellation-date-desktop-styled'

export function TripCancellationDateDesktop({ label }: LayoutProps) {
  return (
    <Container>
      <CancelIcon name="cancelledTripPWA" size="large" color="error" />
      <Block marginTop={2}>
        <Text variant="title3" color="error">
          {label}
        </Text>
      </Block>
    </Container>
  )
}
