import { Store } from '@etta/di'
import type { PriceChangeValueObject } from '@etta/modules/air-search/core/value-objects/price-change.value-object'

@Store()
export class AirCheckFarePolicyStore {
  private _airPriceChange: PriceChangeValueObject | null = null

  setAirPriceChange(value: PriceChangeValueObject | null) {
    this._airPriceChange = value
  }

  get airPriceChange(): PriceChangeValueObject | null {
    return this._airPriceChange
  }

  dropStore() {
    this.setAirPriceChange(null)
  }
}
