const DEFAULT_DISTANCE_VALUE = 5

type Args = {
  groupId?: string
  initialDistance?: number
}

export function getInitialFilters(filters: Args) {
  const { groupId = '', initialDistance = DEFAULT_DISTANCE_VALUE } = filters
  return {
    initialDistance,
    distance: initialDistance,
    amenityIds: [],
    brandIds: [],
    hotelName: '',
    starRatings: [],
    groupId,
  }
}
