import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getAirportChangeData } from './get-airport-change-data'
import type { AirportChangeTextProps } from './types'

export function useAirportChangeText({
  flightLegSegments,
  size = 'large',
}: AirportChangeTextProps) {
  const { t } = useTranslation()
  const i18nBase = 'Badge.'

  const airportChangeData = useMemo(() => getAirportChangeData(flightLegSegments), [
    flightLegSegments,
  ])

  const changeText = useMemo(() => {
    if (!airportChangeData?.arrivalAirport || !airportChangeData?.departureAirport) {
      return
    }
    const badgeParams = {
      arrival: airportChangeData?.arrivalAirport,
      departure: airportChangeData?.departureAirport,
    }

    if (size === 'large') {
      return t(`${i18nBase}ThisFlightRequiresYouToChangeAirports`, badgeParams)
    }
    if (size === 'small') {
      return t(`${i18nBase}ChangeAirportFrom`, badgeParams)
    }
    if (size === 'xsmall') {
      return t(`${i18nBase}ChangeAirport`, badgeParams)
    }
  }, [size, t, airportChangeData])

  return {
    changeText,
  }
}
