import { DayPickerRangeController } from 'react-dates'
import '@fiji/utils/init-react-dates-vendor'
import { useCalendar } from '@fiji/hooks/use-date-picker'
import type { CalendarProps } from '../types'
import { CalendarContainer } from './calendar-components'
import { CalendarLayout } from './layout'

export default function Calendar({
  date,
  dateEnd,
  focusedInput,
  minDate,
  maxDate,
  isEndDateSame,
  onDaySelect,
  highlightDates,
  numberOfMonths = 2,
  isOutsideRange,
  handlerRenderMonthElement,
  forceScreenType,
}: CalendarProps) {
  const {
    container,
    numberOfMonthsValue,
    startDateValue,
    endDateValue,
    shouldHighlightDay,
    isDayBlockedHandler,
    handleDatesChange,
    handleFocusChange,
    isOutsideRangeHandler,
  } = useCalendar({ date, dateEnd, maxDate, minDate, onDaySelect, highlightDates, numberOfMonths })

  return (
    <CalendarContainer ref={container}>
      <CalendarLayout
        forceScreenType={forceScreenType}
        calendarSlot={
          <DayPickerRangeController
            initialVisibleMonth={null}
            minimumNights={isEndDateSame ? 0 : 1}
            startDate={startDateValue}
            endDate={endDateValue}
            numberOfMonths={numberOfMonthsValue}
            keepOpenOnDateSelect
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            isDayHighlighted={shouldHighlightDay}
            isDayBlocked={isDayBlockedHandler}
            onDatesChange={handleDatesChange}
            onFocusChange={handleFocusChange}
            weekDayFormat={'dd'}
            isOutsideRange={isOutsideRange || isOutsideRangeHandler}
            renderMonthElement={handlerRenderMonthElement}
            firstDayOfWeek={0}
          />
        }
      />
    </CalendarContainer>
  )
}
