import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { AddNewCardContainer, AddNewCardTitle, CardIconWrapper } from './add-new-card-button-styled'

type Props = {
  title: string
}

export const AddNewCardButton = ({ title }: Props) => {
  return (
    <AddNewCardContainer type="button">
      <CardIconWrapper>
        <Icon name="cardPWA" size="medium" />
      </CardIconWrapper>
      <AddNewCardTitle>{title}</AddNewCardTitle>
      <Block marginLeft="auto">
        <Icon name="chevronRightPWA" color="primary" />
      </Block>
    </AddNewCardContainer>
  )
}
