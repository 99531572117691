import styled, { css } from 'styled-components'
import { UnstyledButton, buttonSmall, getKeyboardNavigationStyle } from '@fiji/style'

export const Text = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.colors.mainText1};
`

export const Container = styled(UnstyledButton)<{ isSingle?: boolean }>`
  width: 100%;
  padding: 12px 0;
  margin: 0;
  border: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  height: 62px;
  cursor: pointer;
  outline: none;
  flex: 1 0 105px;
  position: relative;

  ${({ isSingle }) =>
    isSingle &&
    css`
      flex-direction: row;
      justify-content: flex-start;
      padding: 12px 20px;

      ${Text} {
        margin-left: 10px;
      }
    `}

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }

  &:disabled {
    cursor: default;

    ${Text} {
      color: ${(props) => props.theme.colors.bodyText};
    }
  }

  ${getKeyboardNavigationStyle({ offset: -2 })}
`
