import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { Text } from '@etta/ui/text'

type Params = {
  date: string
}

export function CancellationDate({ date }: Params) {
  const { t } = useTranslation()
  const label = t('PostBooking.TripDetails.CanceledHeader', {
    date: dateFormat(new Date(deleteTimezone(date)), 'eeee, MMM d'),
  })

  return (
    <Text tabIndex={0} variant="subHeadStrong" color="error">
      {label}
    </Text>
  )
}
