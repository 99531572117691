import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { SegmentType } from '@fiji/graphql/types'
import { ListItemContainer, ListItem, ListItemIcon } from './action-buttons-styled'
import { useGetInfoText } from './hooks'

type Props = {
  type: SegmentType
  isPostBooking?: boolean
  onShareClick?: () => void
  onDetailsClick?: () => void
  isSeparatorDisabled?: boolean
}

const i18nBase = 'ActionButtons'

export function CustomActions({
  onDetailsClick,
  onShareClick,
  type,
  isPostBooking,
  isSeparatorDisabled,
}: Props) {
  const { t } = useTranslation()
  const infoText = useGetInfoText(type)

  const shareContent = useMemo(() => {
    if (!isPostBooking || !onShareClick) {
      return null
    }

    return (
      <ListItemContainer>
        <ListItem
          onClick={onShareClick}
          aria-label={t(`${i18nBase}.Share`)}
          data-tracking-id="share-trip-segment-button">
          <Text variant="subHeadStrong" color="mainText">
            {t(`${i18nBase}.Share`)}
          </Text>
          <ListItemIcon>
            <Icon name="sharePWA" size="medium" />
          </ListItemIcon>
        </ListItem>
      </ListItemContainer>
    )
  }, [isPostBooking, onShareClick, t])

  const detailsContent = useMemo(() => {
    if (!infoText || !onDetailsClick) {
      return null
    }

    return (
      <ListItemContainer>
        <ListItem
          isSeparatorDisabled={isSeparatorDisabled}
          onClick={onDetailsClick}
          aria-label={t(i18nBase + '.Label', { button: infoText })}
          data-tracking-id={`custom-action-button_${type}`}>
          <span>{infoText}</span>
          <ListItemIcon>
            <Icon name="chevronRightPWA" size="small" />
          </ListItemIcon>
        </ListItem>
      </ListItemContainer>
    )
  }, [onDetailsClick, infoText, type, t, isSeparatorDisabled])

  if (!infoText) {
    return null
  }

  return (
    <>
      {detailsContent}
      {shareContent}
    </>
  )
}
