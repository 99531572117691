import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { glowingFragment } from '@fiji/style/animations'

export const Container = styled.div``

export const EmissionData = styled.div`
  width: 100%;
`

export const LeafIcon = styled(Icon)``

export const RailEmissionContainer = styled.div``

export const Loader = styled.div`
  height: 24px;
  width: 168px;
  ${glowingFragment};
`
