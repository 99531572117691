import type { SpinnerSizes } from '@etta/ui/spinner/types'
import type { IconSizes } from '@etta/ui/icon'

const SPINNER_SIZE_TO_ICON_SIZE: Record<SpinnerSizes, IconSizes> = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  normal: 'normal',
}

export function getSize(size: SpinnerSizes): IconSizes {
  return SPINNER_SIZE_TO_ICON_SIZE[size]
}
