import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { useAppSelector } from '@fiji/store'
import { featureFlagsSelector } from '@fiji/store/feature-flags'
import { Tabs } from '@etta/ui/tabs'

type Props = {
  isDisabled?: boolean
}

const i18Base = 'TripPlanner.BaseSearch'

export const TabsSectionMobile = observer(function TabsSectionMobile({ isDisabled }: Props) {
  const { airSearchFormInputsActions, airSearchFormStore } = useAirSearchFormContext()
  const { t } = useTranslation()
  const oneWayTitle = t(i18Base + '.Input.OneWay')
  const roundTripTitle = t(i18Base + '.Input.RoundTrip')
  const multiCityTitle = t(i18Base + '.Input.MultiCity')
  const { values } = useAppSelector(featureFlagsSelector)

  const isMultiCityEnabled = values.isMultiCityEnabled

  return (
    <Block marginBottom={24}>
      <Tabs
        activeTab={airSearchFormStore.searchFlightType}
        onChangeTab={airSearchFormInputsActions.changeType}>
        <Tabs.Header>
          <Tabs.Tab
            value={FlightType.OneWay}
            aria-label={oneWayTitle}
            data-tracking-id="one-way-tab"
            isDisabled={isDisabled}>
            {oneWayTitle}
          </Tabs.Tab>
          <Tabs.Tab
            value={FlightType.Round}
            aria-label={roundTripTitle}
            data-tracking-id="round-trip-tab"
            isDisabled={isDisabled}>
            {roundTripTitle}
          </Tabs.Tab>
          {isMultiCityEnabled && (
            <Tabs.Tab
              value={FlightType.MultiCity}
              aria-label={multiCityTitle}
              data-tracking-id="multi-city-tab"
              isDisabled={isDisabled}>
              {multiCityTitle}
            </Tabs.Tab>
          )}
        </Tabs.Header>
      </Tabs>
    </Block>
  )
})
