import { useTranslation } from 'react-i18next'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { Rate, TrainCostSummary } from '@fiji/graphql/types'
import { RouteType } from '@fiji/graphql/types'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { ItineraryItem } from '../itinerary-item'
import { StyledFlightArrowIcon } from '../cost-summary-styled'
import { InfoLabel } from './train-section.styled'

type Props = {
  segments: TrainPostBookingValueObject[]
  train: TrainCostSummary
}

export function TrainSection({ segments, train }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.CostSummary'

  return (
    <>
      {segments.map((segment, index) => {
        const originAddress = segment.segments?.[0].stations?.departure?.stationName
        const destinationAddress = segment.segments?.at(-1)?.stations?.arrival?.stationName
        const zeroRate: Rate | null = segment.rate
          ? {
              primary: {
                amount: 0,
                currency: segment.rate.primary.currency,
              },
            }
          : null

        return (
          <>
            {index === 1 ? (
              <ItineraryItem.BillListItem
                price={segment.rate}
                priceAriaLabel={`${originAddress} - ${destinationAddress}`}>
                {destinationAddress}
                &nbsp;
                <StyledFlightArrowIcon name="flightReturnArrowPWA" />
                &nbsp;
                {originAddress}
              </ItineraryItem.BillListItem>
            ) : (
              <ItineraryItem.BillListItem
                price={segment.rate}
                priceAriaLabel={`${originAddress} - ${destinationAddress}`}>
                {originAddress}
                &nbsp;
                <StyledFlightArrowIcon name="flightArrowPWA" />
                &nbsp;
                {destinationAddress}
              </ItineraryItem.BillListItem>
            )}
            <InfoLabel>
              {getFormattedTime(
                new Date(deleteTimezone(String(segment.departureDateTime))),
                'EEE, MMM d, h:mm a',
              )}
            </InfoLabel>
            {segment.routeType === RouteType.OpenReturn && (
              <>
                <ItineraryItem.BillListItem
                  price={zeroRate}
                  priceAriaLabel={`${destinationAddress} - ${originAddress}`}>
                  {originAddress}
                  &nbsp;
                  <StyledFlightArrowIcon name="flightReturnArrowPWA" />
                  &nbsp;
                  {destinationAddress}
                </ItineraryItem.BillListItem>
                <InfoLabel>{t('PaymentSummary.Rail.OpenReturnText')}</InfoLabel>
              </>
            )}
          </>
        )
      })}
      <ItineraryItem.BillListItem price={train.fare?.transactionFee}>
        {t(`${i18nBase}.BookingFees`)}
      </ItineraryItem.BillListItem>
      <ItineraryItem.BillListItem price={train.fare?.serviceFee}>
        {t(`${i18nBase}.DeliveryFee`)}
      </ItineraryItem.BillListItem>
      <ItineraryItem.BillListItem price={train.fare?.paymentFee}>
        {t(`${i18nBase}.PaymentFee`)}
      </ItineraryItem.BillListItem>
      <ItineraryItem.BillListItemTax price={train.fare?.tax} />
    </>
  )
}
