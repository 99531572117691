import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ReduxHotel } from '@fiji/types'

const initialState: ReduxHotel[] = []

const slice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {
    updateHotelPhoto(
      state,
      action: PayloadAction<{
        id: string
        url: string
      }>,
    ) {
      return [...state, action.payload]
    },
  },
})

export const hotelsReducer = slice.reducer
export const { updateHotelPhoto } = slice.actions
