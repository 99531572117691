import { Service, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { QualtricsStore } from '../store/qualtrics.store'

@Service()
export class QualtricsService {
  constructor(
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
    @Inject() private readonly qualtricsStore: QualtricsStore,
  ) {}

  private _boundStart = this.start.bind(this)

  private loadIntercept() {
    return new Promise((resolve) => window.QSI?.API.load().done(resolve))
  }

  private unloadIntercept() {
    window.QSI?.API.unload()
  }

  private interceptHasPassed(): Promise<boolean> {
    const interceptId: string = this.qualtricsStore.interceptId

    return new Promise((resolve) => {
      window.QSI?.API.getIntercept(interceptId)
        .then((interceptApi): void => {
          const evaluation = interceptApi?.getEvaluationResult() ?? { passed: false }

          resolve(evaluation.passed)
        })
        .catch(() => resolve(false))
    })
  }

  private runIntercept() {
    window.QSI?.API.run()
  }

  private async start() {
    const { isQualtricsEnabled } = this.featureFlagsStore.flags

    if (isQualtricsEnabled) {
      let passed: boolean = false

      this.unloadIntercept()
      await this.loadIntercept()
      passed = await this.interceptHasPassed()

      if (passed) {
        this.qualtricsStore.setModalVisible(true)
        this.runIntercept()
      } else {
        this.unloadIntercept()
        this.close()
      }
    }
  }

  init() {
    window.loadQualtricsScript()
    window.addEventListener('qsi_js_loaded', this._boundStart)
  }

  close() {
    this.qualtricsStore.dropStore()
    window.removeEventListener('qsi_js_loaded', this._boundStart)
  }
}
