import { observer } from 'mobx-react-lite'
import { isFuture } from 'date-fns'
import { SegmentType } from '@etta/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { CarRentalDetailsEcoCheck } from '@etta/components/car-rental-details-eco-check/car-rental-details-eco-check'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { TripCarTraveler } from '@etta/components/trip-car-traveler/trip-car-traveler'
import { Block } from '@etta/ui/block'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { templates } from '@fiji/hooks/use-share-via-email'
import { useTripModifyModal } from '@fiji/hooks/use-trip-modify-modal'
import { TripCarRentalHeadline } from '../headline/trip-car-rental-headline'
import { Price } from '../price/price'
import { TripCarRentalContentLayout } from './layout'
import type { TripCarRentalContentProps } from './types'

export const TripCarRentalContent = observer(function TripCarRentalContent({
  tripName,
  startDate,
  endDate,
  carRental,
  carRentalEmission,
  onCancel,
  onClose,
}: TripCarRentalContentProps) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { postBookingTripStore, postBookingTripModifyActions } = usePostBookingContext()
  const { trip, travelerNames } = postBookingTripStore
  const { type, cancellationTime, supportedActions } = trip
  const travelerName = travelerNames?.travelerFullName || '-'

  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({
      tripName: tripName,
      startDate: startDate,
      endDate: endDate,
    }),
    body: templates.postBookingCarRental({ carRental: carRental }),
  })

  const isCancelled = type === TripStatus.Cancelled
  const isCompleted = type === TripStatus.Cancelled
  const handleChangeClick = postBookingTripModifyActions.handleModifyCarRental.bind(null, carRental)

  const { handleViewSupport, modalAction, toggleModifyModal } = useTripModifyModal({
    isAllowed: supportedActions.isCarRentalChangeAllowed,
    modifyAction: handleChangeClick,
  })

  const isCarRentalNotStarted = startDate ? isFuture(new Date(startDate)) : false

  return (
    <TripCarRentalContentLayout
      headlineSlot={<TripCarRentalHeadline carRental={carRental} />}
      priceSlot={<Price carRental={carRental} isTripCancelled={isCancelled} />}
      actionButtonsSlot={
        <ActionButtons
          type={SegmentType.CarRental}
          isPostBooking
          onShareClick={handleShare}
          onCancelClick={onCancel}
          onChangeClick={modalAction}
          isCancel={!isCompleted && !isCancelled && isCarRentalNotStarted}
          isChange={!isCompleted && !isCancelled && isCarRentalNotStarted}
        />
      }
      tripCarTravelerSlot={
        <TripCarTraveler
          confirmationNumber={carRental.confirmationNumber}
          recordLocator={carRental.recordLocator}
          isCancelled={type === TripStatus.Cancelled}
          isCompleted={type === TripStatus.Completed}
          cancellationDate={cancellationTime}
          rentalVendorName={carRental.vendor?.name || ''}
          travelerName={travelerName}
        />
      }
      ecoCheckSlot={
        featureFlagsStore.flags.isSustainabilityEnabled && (
          <Block marginTop={16}>
            <CarRentalDetailsEcoCheck {...carRentalEmission} />
          </Block>
        )
      }
      onClose={onClose}
      carRental={carRental}
      tripType={type}
      handleViewSupport={handleViewSupport}
      toggleModifyModal={toggleModifyModal}
    />
  )
})
