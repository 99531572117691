import type { GetTravelPreferencesQuery } from '@fiji/graphql/hooks'
import { omitDeep } from '@fiji/utils/omit-deep'
import type { TravelPreferencesValueObject } from '../core/value-objects/travel-preferences.value-object'

export class TravelPreferencesMapper {
  static toTravelPreferencesValueObject(
    travelPreferences: NonNullable<GetTravelPreferencesQuery['travelPreferences']>,
  ): TravelPreferencesValueObject {
    const { carrental, flight, hotel, rail } = omitDeep(travelPreferences, ['__typename'])

    return {
      flight: {
        specialRequest: flight.specialRequest,
        specialRequestOptions: flight.specialRequestOptions,
        loyaltyProgram: flight.loyaltyProgram,
        homeAirport: flight.homeAirport,
        knownTravelerIssuingCountry: flight.knownTravelerIssuingCountry,
        knownTravelerNumber: flight.knownTravelerNumber,
        meal: flight.meal,
        noteToArranger: flight.noteToArranger,
        redressNumber: flight.redressNumber,
        redressNumberIssuingCountry: flight.redressNumberIssuingCountry,
        resultsView: flight.resultsView,
        searchBy: flight.searchBy,
        seatPreference: flight.seatPreference,
      },
      carRental: {
        carClass: carrental.carClass,
        loyaltyProgram: carrental.loyaltyProgram,
        specialRequest: carrental.specialRequest,
        specialRequestOptions: carrental.specialRequestOptions,
      },
      hotel: {
        hotelAmenity: hotel.hotelAmenity,
        loyaltyProgram: hotel.loyaltyProgram,
        specialRequest: hotel.specialRequest,
        specialRequestOptions: hotel.specialRequestOptions,
      },
      rail: {
        loyaltyProgram: rail.loyaltyProgram,
        specialRequest: rail.specialRequest,
      },
    }
  }
}
