import { useCallback, useState } from 'react'
import type { AvailablePreferences } from '@fiji/graphql/hooks'
import {
  useGetRailJourneyPreferencesQuery,
  useUpdateRailJourneyPreferencesMutation,
} from '@fiji/graphql/hooks'
import type { DeliveryOptions } from '@fiji/graphql/types'
import { Operation, SegmentType, TriStateBoolean } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { ViewState } from '@etta/ui/view-state-modal'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { OnSubmitProps } from '@etta/components/travel-preferences/rail-preferences-modal/types'
import { TrainDeliveryOptionCodeRTP } from '@fiji/enums'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import type { TravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import { useUserContext } from '@etta/modules/user'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { getExistingMemberships, useLoyaltyProgram } from '../fields/use-loyalty-program'
import { useSpecialRequest } from '../fields/use-special-request'

type Args = {
  segments: TripSegmentValueObject[]
  preferenceData: TravelPreferencesValueObject
  availablePreferences?: AvailablePreferences
  onChangeViewState: (viewState: ViewState) => void
}

export function useRailPreference({
  segments,
  preferenceData,
  availablePreferences,
  onChangeViewState,
}: Args) {
  const { userStore } = useUserContext()
  const { travelPreferencesStore } = useTravelPreferencesContext()
  const { reviewTripStore } = useReviewTripContext()
  const { trip } = reviewTripStore

  const typeCheck = SegmentTypeCheckActions.getInstance()

  const railPreferencesModalToggle = useTogglePopup()

  const railSegments = segments.filter(typeCheck.isTrainSegment)

  const departureStation = railSegments[0]?.segments[0]
  const isStationTicketMachineDisabled =
    departureStation?.departure.ticketMachineAvailable === TriStateBoolean.TriStateBooleanFalse
  const legParts = railSegments?.[0]?.legId.split('__')
  const searchId = legParts?.[0] || ''
  const journeyId = legParts?.[1] || ''
  const fareId = legParts?.[2] || ''

  const legPartsInward = railSegments?.[1]?.legId.split('__')
  const journeyIdInward = legPartsInward?.[1] || ''
  const fareIdInward = legPartsInward?.[2] || ''

  const railLoyaltyProgram = useLoyaltyProgram({
    type: SegmentType.Train,
    preferenceData,
    segments,
    availablePreferences,
  })

  const [loyaltyProgramLocal, setLoyaltyProgramLocal] = useState(railLoyaltyProgram.value)
  const [currentDeliveryOptions, setCurrentDeliveryOptions] = useState<DeliveryOptions[]>([])

  const [isEticketSelected, setEticketSelected] = useState(true)
  const railSpecialRequest = useSpecialRequest({
    type: SegmentType.Train,
    preferenceData,
    availablePreferences,
  })

  const existingMembership = getExistingMemberships(preferenceData?.rail.loyaltyProgram)

  const [updateRailJourneyPreferencesMutation] = useUpdateRailJourneyPreferencesMutation()
  const isETicketOptionAvailable =
    currentDeliveryOptions.findIndex((item) => item.name === TrainDeliveryOptionCodeRTP.Eticket) !==
    -1

  useGetRailJourneyPreferencesQuery({
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        searchId,
        outwardJourney: {
          journeyId,
          fareId,
        },
        inwardJourney:
          journeyIdInward && fareIdInward
            ? {
                journeyId: journeyIdInward,
                fareId: fareIdInward,
              }
            : undefined,
      },
    },
    skip: !railSegments?.[0]?.legId,

    onCompleted: (data) => {
      setCurrentDeliveryOptions(data.railJourneyPreferences.deliveryOptions)
      setEticketSelected(
        data.railJourneyPreferences.deliveryOptions.findIndex(
          (item) => item.name === TrainDeliveryOptionCodeRTP.Eticket,
        ) !== -1,
      )
    },
  })

  const handleSubmitPreferences = useCallback(
    async (isEticket: boolean) => {
      if (userStore.isGuest) {
        await delayForSuccess()
        const newRailPreferences = {
          loyaltyProgram: loyaltyProgramLocal,
          specialRequest: [],
        }
        travelPreferencesStore.setSingleTravelPreference(newRailPreferences, 'rail')
        return
      }

      const etiketName = currentDeliveryOptions.find(
        (option) => option.name === TrainDeliveryOptionCodeRTP.Eticket,
      )?.name
      const collectName = currentDeliveryOptions.find(
        (option) => option.name === TrainDeliveryOptionCodeRTP.Collect,
      )?.name

      const res = await updateRailJourneyPreferencesMutation({
        variables: {
          input: {
            itineraryId: reviewTripStore.itineraryId,
            journeyId,
            searchId,
            operation: Operation.Update,
            deliveryOption: isEticket ? etiketName : collectName,
          },
        },
      })

      if (!res?.data?.updateRailJourneyPreferences.success) {
        throw new Error(res.data?.updateRailJourneyPreferences.message)
      }
    },
    [
      currentDeliveryOptions,
      journeyId,
      loyaltyProgramLocal,
      reviewTripStore.itineraryId,
      searchId,
      travelPreferencesStore,
      updateRailJourneyPreferencesMutation,
      userStore.isGuest,
    ],
  )

  const onRailPreferencesSubmit = async ({ isEticket, membership }: OnSubmitProps) => {
    try {
      onChangeViewState('saving')
      setEticketSelected(isEticket)
      setLoyaltyProgramLocal(
        membership.map((item) => ({
          number: item.number,
          vendorCode: item.vendorCode,
          label: railLoyaltyProgram.options.find((card) => card.value === item.vendorCode)?.label,
        })),
      )

      await handleSubmitPreferences(isEticket)

      onChangeViewState('saved')
      await delayForSuccess()
      onChangeViewState('hidden')
      railPreferencesModalToggle.handleClose()
    } catch {
      onChangeViewState('error')
    }
  }

  const railLoyaltyProgramLocal = {
    value: loyaltyProgramLocal,
    options: railLoyaltyProgram.options,
  }

  return {
    value: {
      loyaltyProgram: loyaltyProgramLocal,
      specialRequest: railSpecialRequest.value,
      isETicket: isEticketSelected,
      railCard: trip?.railCards?.length ? trip?.railCards?.[0].name : '',
      isStationTicketMachineDisabled,
      isEticketDisabled: !isETicketOptionAvailable,
    },
    departureStationName: departureStation?.departure.stationName,
    railLoyaltyProgram: railLoyaltyProgramLocal.value.length
      ? railLoyaltyProgramLocal
      : railLoyaltyProgram,
    railSpecialRequest,
    existingMembership,
    railPreferencesModalToggle,
    onRailPreferencesSubmit,
  }
}
