import type { HotelDetailsType } from '@etta/components/hotel-details-modal/types'
import { HotelInfoList } from '@etta/components/hotel-info-list/hotel-info-list'
import { useHotelBadges } from './use-hotel-badges'

type Props = {
  hotelDetails: HotelDetailsType
  isFullInfo?: boolean
}

export function HotelBadges({ hotelDetails, isFullInfo }: Props) {
  const {
    starRating,
    medianReviewRating,
    isPreferred,
    distance,
    privateRate,
    preference,
    isEcoFriendly,
  } = hotelDetails
  const {
    isTripAdvisorReviewsEnabled,
    isOutOfPolicy,
    isAllRoomsOutOfPolicy,
    outOfPolicyTexts,
  } = useHotelBadges(hotelDetails)

  return (
    <HotelInfoList
      starRating={starRating}
      isTripAdvisorReviewsEnabled={isTripAdvisorReviewsEnabled}
      medianReviewRating={medianReviewRating}
      distance={distance}
      isPreferred={isPreferred}
      preference={preference}
      privateRate={privateRate}
      isOutOfPolicy={isOutOfPolicy}
      isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
      outOfPolicyTexts={outOfPolicyTexts}
      isEcoFriendly={isFullInfo && !!isEcoFriendly}
      tooltipPlace="bottom"
    />
  )
}
