import { Store } from '@etta/di'
import type { UnvalidatedTicket, ValidatedTicketValueObject } from '@etta/modules/review-trip/core'

@Store()
export class UnusedTicketCostBreakdownStore {
  private _isOpen = false
  private _unsuedTicket?: ValidatedTicketValueObject | UnvalidatedTicket | null = null

  setIsOpen(isOpen: boolean) {
    this._isOpen = isOpen
  }

  setUnusedTicket(value: ValidatedTicketValueObject | UnvalidatedTicket | null | undefined) {
    this._unsuedTicket = value
  }

  get isOpen() {
    return this._isOpen
  }

  get unsuedTicket() {
    return this._unsuedTicket
  }
}
