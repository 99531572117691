import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { RailDetailsContentDesktop } from './desktop'
import { RailDetailsContentMobile } from './mobile'

export function RailDetailsContentLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<RailDetailsContentMobile {...props} />}
      desktopSlot={<RailDetailsContentDesktop {...props} />}
    />
  )
}
