import { useOutOfPolicyMessages } from '@fiji/hooks/use-out-of-policy-messages'
import type { Policy } from '@fiji/graphql/types'

export function useCarRentalPolicyReasons(carPolicy?: Policy) {
  const { messages } = useOutOfPolicyMessages({ outOfPolicyData: carPolicy?.outOfPolicyData })
  const outOfPolicyCodes = carPolicy?.outOfPolicyReasons
  const outOfPolicyTexts = outOfPolicyCodes?.map((code) => messages[code])

  const isOnePolicyReason = outOfPolicyTexts?.length === 1

  return {
    isOnePolicyReason,
    outOfPolicyTexts,
  }
}
