import { clone } from 'ramda'
import type { HotelMap } from './types'

const initialState: HotelMap = {
  highlightedHotel: {
    id: null,
    isTooltipVisible: false,
  },
  centerMarkerLocation: {
    latitude: 0,
    longitude: 0,
    query: '',
  },
  changedMapData: {
    latitude: 0,
    longitude: 0,
    radius: 0,
  },
  defaultRadius: 10,
}

export function getInitialState() {
  return clone(initialState)
}
