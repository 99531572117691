import type { UserPreset } from '../types'

export const rcqa8: UserPreset[] = [
  {
    site: 'non-hag1-fss',
    user: 'jdeem',
    rcSession:
      '15484-BE42C5861570CDD1ACCF74BC8FA3D3DC-E3BBB2C2E9CE06010298EBDAC284DE5323BD51FF2FBA4F5AA48B92987B0CF14C1157F66E9E3254CB0A8F4E02240C572FE78AEF0A8767E777089AF2751C3152012BA9E68D4B210482A23DE5B1764AAD0528A422493695D93E160AF8A6112A975E49C3057A224C38159B4F6CE6072397BA11841975A8788693DC2F2E6770B23D0AFDFE1863E330C5ED346E5173881EC133E0CE7AFF1155B13A0B80B9BE9DA84B3054663996D4B41066A93EB632B8A76A8A',
  },
]
