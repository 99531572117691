import type { RefObject } from 'react'
import { useEffect } from 'react'
import { getCurrentAnchorName } from './get-current-anchor-name'
import { useScrollContextValue } from './use-scroll-context-value'

type Args = {
  scrollContainerRef: RefObject<HTMLDivElement>
  onAnchorAppear: (name: string) => void
}

export function useScrollByContent({ scrollContainerRef, onAnchorAppear }: Args) {
  const {
    calculateZero,
    topAnchorRef,
    contextValue,
    marginFromZeroValueRef,
    anchorsRef,
  } = useScrollContextValue({
    scrollContainerRef,
  })

  useEffect(() => {
    const target = scrollContainerRef.current
    if (!target) {
      return
    }

    const onScroll = () => {
      const name = getCurrentAnchorName(anchorsRef.current, marginFromZeroValueRef.current)

      name && onAnchorAppear(name)
    }

    target.addEventListener('scroll', onScroll)
    calculateZero()

    return () => {
      target.removeEventListener('scroll', onScroll)
    }
  }, [anchorsRef, calculateZero, marginFromZeroValueRef, onAnchorAppear, scrollContainerRef])

  return {
    contextValue,
    topAnchorRef,
  }
}
