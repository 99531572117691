import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  CalculateRailEmissionsBatchDocument,
  CreateRailSearchDocument,
  GetRailDocument,
  GetRailETicketsDocument,
} from '@fiji/graphql/hooks'
import type { GetRailQuery, CalculateRailEmissionsBatchQuery } from '@fiji/graphql/hooks'
import type {
  CalculateRailEmissionsBatchQueryVariables,
  CreateRailSearchMutation,
  CreateRailSearchMutationVariables,
  GetRailETicketsQuery,
  GetRailETicketsQueryVariables,
  GetRailQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class RailDataProvider extends GraphqlDataProvider {
  getRailSearchResults(variables: GetRailQueryVariables, forceRefetch = false) {
    return this.client.query<GetRailQuery, GetRailQueryVariables>({
      query: GetRailDocument,
      variables,
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  getRailEmissions(variables: CalculateRailEmissionsBatchQueryVariables, forceRefetch = false) {
    return this.client.query<
      CalculateRailEmissionsBatchQuery,
      CalculateRailEmissionsBatchQueryVariables
    >({
      query: CalculateRailEmissionsBatchDocument,
      variables,
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  createRailSearch(variables: CreateRailSearchMutationVariables) {
    return this.client.mutate<CreateRailSearchMutation, CreateRailSearchMutationVariables>({
      mutation: CreateRailSearchDocument,
      variables,
    })
  }

  getRailETickets(variables: GetRailETicketsQueryVariables, forceRefetch = true) {
    return this.client.query<GetRailETicketsQuery, GetRailETicketsQueryVariables>({
      query: GetRailETicketsDocument,
      variables,
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }
}
