import type { RideHailExpenseConfigArgs } from '@etta/modules/ride-hail/infra/types'
import type { RideHailExpenseConfigInput } from '@fiji/graphql/hooks'
import { buildRideHailLocation } from '@etta/modules/ride-hail/infra/builders/ride-hail-location.builder'
import { buildRideHailDateString } from '@etta/modules/ride-hail/infra/builders/ride-hail-date-string.builder'
import { buildRideHailTimeOfDayString } from '@etta/modules/ride-hail/infra/builders/ride-hail-time-of-day-string.builder'
import { buildRideHailProvider } from '@etta/modules/ride-hail/infra/builders/ride-hail-provider.builder'

export function buildRideHailExpenseConfigRequest(
  args: RideHailExpenseConfigArgs,
): RideHailExpenseConfigInput {
  return {
    dropOffLocation: buildRideHailLocation(args.dropOff),
    pickUpDate: args.pickUpDate ? buildRideHailDateString(args.pickUpDate) : undefined,
    pickUpLocation: buildRideHailLocation(args.pickUp),
    pickUpTime: args.pickUpTime ? buildRideHailTimeOfDayString(args.pickUpTime) : undefined,
    processID: args.processID,
    provider: buildRideHailProvider(args.provider),
    searchResultID: args.searchResultID,
  }
}
