import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { SafetyCheckHeaderMobile } from './mobile'
import { SafetyCheckHeaderDesktop } from './desktop'

export function SafetyCheckHeaderLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SafetyCheckHeaderMobile {...props} />}
      desktopSlot={<SafetyCheckHeaderDesktop {...props} />}
    />
  )
}
