export default function TimeIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 6C13.1613 6 6 13.1613 6 22C6 30.8387 13.1613 38 22 38C30.8387 38 38 30.8387 38 22C38 13.1613 30.8387 6 22 6ZM22 34.9032C14.8387 34.9032 9.09677 29.1613 9.09677 22C9.09677 14.9032 14.8387 9.09677 22 9.09677C29.0968 9.09677 34.9032 14.9032 34.9032 22C34.9032 29.1613 29.0968 34.9032 22 34.9032ZM25.9355 28.1935C26.3226 28.4516 26.7742 28.3871 27.0323 28L28.2581 26.3871C28.5161 26 28.4516 25.5484 28.0645 25.2903L23.8065 22.129V12.9677C23.8065 12.5806 23.4194 12.1935 23.0323 12.1935H20.9677C20.5161 12.1935 20.1935 12.5806 20.1935 12.9677V23.6129C20.1935 23.8065 20.2581 24.0645 20.4516 24.1935L25.9355 28.1935Z"
        fill="currentColor"
      />
    </svg>
  )
}
