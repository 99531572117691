import type { LayoutControlsProps } from '@etta/ui/date-picker-temp/controls/types'
import { MediaLayout } from '@etta/ui/media-layout'

import { ControlsLayoutMobile } from './mobile/controls-layout-mobile'
import { ControlsLayoutDesktop } from './desktop/controls-layout-desktop'

export function ControlsLayout(props: LayoutControlsProps) {
  return (
    <MediaLayout
      mobileSlot={<ControlsLayoutMobile {...props} />}
      desktopSlot={<ControlsLayoutDesktop {...props} />}
    />
  )
}
