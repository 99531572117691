import { useTranslation } from 'react-i18next'
import { MultiLayerCostAllocationContent } from '@etta/components/multi-layer-cost-allocation/multi-layer-cost-allocation-content'
import { useCheckoutMultiLayerCostAllocation } from './use-checkout-multi-layer-cost-allocation'

type Props = {
  costSegmentId: number
  isSplitCostAllocation: boolean
  onChange(field: string): (value: string) => void
  errorAllocation: string
  errorSplitAllocation: string
  isCostAllocationRequired: boolean
  isSplitCostAllocationRequired: boolean
  title: string
}

const i18nBase = 'AdditionalInfoForm.'

export function CheckoutMultiLayerCostAllocation({
  costSegmentId,
  errorAllocation,
  errorSplitAllocation,
  onChange,
  isSplitCostAllocation,
  isCostAllocationRequired,
  isSplitCostAllocationRequired,
  title,
}: Props) {
  const {
    costAllocationModal,
    splitCostAllocationModal,
    activeCostAllocationDisplayName,
    activeSplitCostAllocationDisplayName,
    onCostAllocationSubmit,
    onSplitCostAllocationSubmit,
  } = useCheckoutMultiLayerCostAllocation({ onChange, segmentId: costSegmentId })

  const { t } = useTranslation()
  const allocationText = `${title} ${
    isCostAllocationRequired ? t(i18nBase + 'Primary') : t(i18nBase + 'PrimaryOptional')
  }`
  const splitAllocationText = `${title} ${
    isSplitCostAllocationRequired ? t(i18nBase + 'Secondary') : t(i18nBase + 'SecondaryOptional')
  }`

  return (
    <>
      <MultiLayerCostAllocationContent
        segmentId={costSegmentId}
        title={allocationText}
        type="additional-info"
        error={errorAllocation}
        variant={'default'}
        modal={costAllocationModal}
        label={activeCostAllocationDisplayName}
        onSubmit={onCostAllocationSubmit}
      />

      {isSplitCostAllocation && (
        <MultiLayerCostAllocationContent
          segmentId={costSegmentId}
          title={splitAllocationText}
          type="additional-info"
          error={errorSplitAllocation}
          variant={'default'}
          modal={splitCostAllocationModal}
          label={activeSplitCostAllocationDisplayName}
          onSubmit={onSplitCostAllocationSubmit}
        />
      )}
    </>
  )
}
