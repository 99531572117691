import type { GetItineraryQuery, Maybe } from '@fiji/graphql/types'
import { RouteType, JourneyDirection } from '@fiji/graphql/types'
import type { ReviewTripValueObject, TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { RouteTypeEnum } from '@etta/modules/review-trip/core'
import { RailJourneyDirectionEnum } from '@etta/modules/review-trip/core/enum/rail-journey-direction.enum'
import type {
  CarRentalSegmentEntity,
  HotelSegmentEntity,
  FlightSegmentEntity,
  TrainSegmentEntity,
} from '../../../core/entity'

type ItinerarySegments = GetItineraryQuery['itinerary']['segments']

type ItinerarySegment = ItinerarySegments extends Maybe<(infer U)[]> | undefined ? U : never

export class ItineraryMapper {
  static toTripValueObject(itinerary: GetItineraryQuery['itinerary']): ReviewTripValueObject {
    const {
      availableSegments,
      complianceMessage,
      continuityMessage,
      expiredAt,
      flightId,
      holdRules,
      hotelLocationInformation,
      isMultiCity,
      isMultiDestination,
      isOnHold,
      itineraryId,
      itineraryItemResponse,
      messages,
      overlappingTrips,
      priceChanges,
      railCards,
      segments,
      tripLevelPolicy,
      tripStatus,
      virtualPay,
      tripCost,
      trip,
      isTripAligned,
    } = itinerary

    return {
      availableSegments: availableSegments ? availableSegments : undefined,
      complianceMessage: complianceMessage,
      continuityMessage: continuityMessage,
      expiredAt: expiredAt,
      flightId: flightId,
      holdRules: holdRules,
      hotelLocationInformation: hotelLocationInformation,
      isMultiCity: isMultiCity,
      isMultiDestination: isMultiDestination,
      isOnHold: isOnHold,
      itineraryId: itineraryId,
      itineraryItemResponse: itineraryItemResponse,
      messages: messages,
      overlappingTrips: overlappingTrips ? overlappingTrips : undefined,
      priceChanges: priceChanges,
      railCards: railCards,
      segments: this.toSegmentsValueObject(segments),

      tripInfo: trip,
      tripLevelPolicy: tripLevelPolicy?.map((policy) => {
        return {
          isInPolicy: policy.isInPolicy,
          outOfPolicyData: policy.outOfPolicyData?.map((item) => {
            return {
              currency: this.arrayFilter(item.currency),
              data: this.arrayFilter(item.data),
              type: item.type,
            }
          }),
          outOfPolicyReasons: policy.outOfPolicyReasons,
        }
      }),
      tripStatus: tripStatus,
      virtualPay: virtualPay,
      tripCost: tripCost,
      isTripAligned,
    }
  }

  static toSegmentsValueObject(segments: ItinerarySegments) {
    return (
      segments?.reduce((acc: TripSegmentValueObject[], segment) => {
        const flightSegment = this.toFlightSegmentEntity(segment)
        const carRentalSegment = this.toCarRentalSegmentEntity(segment)
        const hotelSegment = this.toHotelSegmentEntity(segment)
        const trainSegment = this.toTrainSegmentEntity(segment)

        const tripSegment = flightSegment || carRentalSegment || hotelSegment || trainSegment

        if (tripSegment) {
          acc.push(tripSegment)
        }
        return acc
      }, []) || []
    )
  }

  static toFlightSegmentEntity(segment: ItinerarySegment): FlightSegmentEntity | undefined {
    if (segment.flightLeg) {
      return {
        position: segment.position,
        type: segment.type,
        uuid: segment.uuid,
        ...segment.flightLeg,
      }
    }
  }
  static toCarRentalSegmentEntity(segment: ItinerarySegment): CarRentalSegmentEntity | undefined {
    if (segment.carRental) {
      return {
        position: segment.position,
        type: segment.type,
        uuid: segment.uuid,
        ...segment.carRental,
      }
    }
  }
  static toHotelSegmentEntity(segment: ItinerarySegment): HotelSegmentEntity | undefined {
    if (segment.hotel) {
      return {
        position: segment.position,
        type: segment.type,
        uuid: segment.uuid,
        ...segment.hotel,
      }
    }
  }
  static toTrainSegmentEntity(segment: ItinerarySegment): TrainSegmentEntity | undefined {
    if (segment.trainLeg) {
      return {
        position: segment.position,
        type: segment.type,
        uuid: segment.uuid,
        ...segment.trainLeg,
      }
    }
  }

  static arrayFilter<T>(arr?: unknown[] | null) {
    return arr?.filter((item): item is T => !!item)
  }

  static toJourneyDirectionEnum = (
    direction?: RailJourneyDirectionEnum | null,
  ): JourneyDirection => {
    switch (direction) {
      case RailJourneyDirectionEnum.Inward:
        return JourneyDirection.Inward
      case RailJourneyDirectionEnum.Outward:
      default:
        return JourneyDirection.Outward
    }
  }

  static toRouteType(routeType?: RouteTypeEnum | null): RouteType {
    switch (routeType) {
      case RouteTypeEnum.OpenReturn:
        return RouteType.OpenReturn
      case RouteTypeEnum.Single:
        return RouteType.Single
      case RouteTypeEnum.Return:
        return RouteType.Return
      default:
        return RouteType.Unspecified
    }
  }
}
