import styled, { css } from 'styled-components'

const slideStyle = css<{ isVisible: boolean }>`
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '110')}%);
  transition: transform 0.6s ease-in-out;
`

export const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  width: 50%;
  right: 20px;
  bottom: 0;
  padding: 6px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 14px 14px 0px 0px;
  z-index: 1;
  box-shadow: 0px -7px 12px rgba(0, 0, 0, 0.18);

  ${slideStyle}
`

export const LegendsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px 0;
  padding: 18px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`
