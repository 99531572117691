import { useTranslation } from 'react-i18next'
import type { Section, ConfirmationNumberData } from '@etta/screens/payment-summary/types'
import { SectionBlock } from '../section-block'
import { PaymentCard } from '../payment-card'
import { useHotelDetails } from './use-hotel-details'
import { HotelRow } from './hotel-row'

type Props = {
  hotels: Section
  confirmationNumbersData: ConfirmationNumberData[]
  isTripLoading?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function HotelBlock({ hotels, confirmationNumbersData, isTripLoading }: Props) {
  const { t } = useTranslation()

  const { rate, creditCard, rows, cardDetails, isPayNow, mainCost, secondCost } = useHotelDetails(
    hotels,
  )

  const paymentText =
    creditCard && isPayNow ? t(i18nBase + 'Payment') : t(i18nBase + 'PaymentDueAtHotel')

  const paymentLabel = t(i18nBase + 'PaymentLabel', {
    card: cardDetails,
    amount: [mainCost, secondCost].filter(Boolean).join(', '),
  })

  if (rows.length === 0) {
    return null
  }
  return (
    <SectionBlock
      paymentText={paymentText}
      paymentLabel={paymentLabel}
      title={t(i18nBase + 'HotelsTitle')}
      cardSlot={<PaymentCard creditCard={creditCard} isPayNow={isPayNow} />}
      rate={rate}>
      {rows.map(
        (
          {
            checkInDate,
            checkOutDate,
            hotelName,
            reservationNumber,
            selectedRoom,
            roomCount,
            segmentId,
          },
          index,
        ) => {
          const confirmationData = confirmationNumbersData.find(({ id }) => id === segmentId)

          return (
            <HotelRow
              key={index}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              hotelName={hotelName}
              reservationNumber={reservationNumber}
              selectedRoom={selectedRoom}
              roomCount={roomCount}
              confirmationData={confirmationData}
              isTripLoading={isTripLoading}
            />
          )
        },
      )}
    </SectionBlock>
  )
}
