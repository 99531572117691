import type {
  TripSegmentValueObject,
  TripCostSummaryValueObject,
} from '@etta/modules/review-trip/core'
import { SegmentType } from '@fiji/graphql/types'
import { useSegments } from '@etta/screens/payment-summary/use-payment-summary/use-segments'
import { useTripCost } from '@etta/screens/payment-summary/use-payment-summary/use-trip-cost'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { getSegmentPrice } from './get-segment-price'

type Props = {
  tripCost: TripCostSummaryValueObject
  segments: TripSegmentValueObject[]
}

export function useTripCostSummary({ tripCost, segments }: Props) {
  const carServicesCost = getSegmentPrice({
    payLater: tripCost.payLater?.carServices,
    segmentType: SegmentType.CarService,
  })

  const {
    carRentalSegments,
    hotelSegments,
    flightSegments,
    hotelSegmentState,
    carRentalSegmentState,
    flightSegmentState,
    railSegmentState,
    railSegments,
    isTrainOnlySegments,
  } = useSegments(segments)

  const {
    isCarRentalPayingNow,
    isHotelPayingNow,
    carRentalTripCost,
    flightTripCost,
    hotelTripCost,
    railTripCost,
  } = useTripCost(tripCost)

  const { featureFlags } = useFeatureFlags()
  const isRailAccreditationEnabled = isTrainOnlySegments && featureFlags.isRailAccreditationEnabled

  return {
    carServicesCost,

    carRentalSegments,
    hotelSegments,
    flightSegments,
    railSegments,
    hotelSegmentState,
    carRentalSegmentState,
    flightSegmentState,
    railSegmentState,
    isCarRentalPayingNow,
    isHotelPayingNow,
    carRentalTripCost,
    flightTripCost,
    hotelTripCost,
    railTripCost,
    isRailAccreditationEnabled,
  }
}
