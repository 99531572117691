import { observer } from 'mobx-react-lite'
import { TripList } from '@etta/components/trip-list/trip-list'
import { AppleBanner } from '@etta/components/apple-banner'
import { TripsPageLayout } from './layout'
import { useTripsPage } from './use-trips-page'
import { BannerWrapper } from './trips-page.styled'

export const TripsPage = observer(function TripsPage() {
  const {
    handleChangeTab,
    handleHeldTripsRedirect,
    activeTab,
    isDelegatedFlowEnabled,
    isAppleBannerShown,
  } = useTripsPage()

  const bannerSlot = isAppleBannerShown ? (
    <BannerWrapper>
      <AppleBanner orientation="horizontal" />
    </BannerWrapper>
  ) : null

  return (
    <TripsPageLayout
      tripListSlot={<TripList tripType={activeTab} bannerSlot={bannerSlot} />}
      isDelegatedFlowEnabled={isDelegatedFlowEnabled}
      handleChangeTab={handleChangeTab}
      handleHeldTripsRedirect={handleHeldTripsRedirect}
      activeTab={activeTab}
    />
  )
})
