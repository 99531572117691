import { StyledTextVariant } from '@fiji/enums'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { Container, SearchWrapper, Label, InputWrapper } from './search-mechanism-desktop-styled'

export function SearchMechanismDesktop({
  searchFieldPlaceholder,
  searchInputSlot,
  listSlot,
}: LayoutProps) {
  return (
    <Container>
      <SearchWrapper>
        <Label>
          <Text variant={StyledTextVariant.captionMedium} color="bodyText">
            {searchFieldPlaceholder}
          </Text>
        </Label>

        <InputWrapper>{searchInputSlot}</InputWrapper>
      </SearchWrapper>

      {listSlot}
    </Container>
  )
}
