import styled from 'styled-components'
import { title1 } from '@fiji/style'
import { Link } from '@etta/ui/link'

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mainText};
  padding: 0 16px;
  line-height: 34px;
  ${title1};
`

export const Document360Container = styled.div`
  display: flex;
  height: 100%;
  align-items: baseline;
  flex-direction: column;
`

export const ArticleWrapper = styled.div`
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  padding: 32px 16px 24px;
  margin: 24px 0;
`

export const PageContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`

export const PageColumn = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 992px;
`

export const BackLink = styled(Link)`
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 24px 0;
`
