import { Inject, Service } from '@etta/di'
// TODO: remove fiji import
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import i18n from '@fiji/i18n'
import { AirSearchFormStore } from '../../stores/air-search-form.store'
import { AirSearchQueryStore } from '../../stores/air-search-query.store'
import { AirSearchFormInputsService } from './air-search-form-inputs.service'
import { AirSearchFormService } from './air-search-form.service'
import { flightKeysWithErrorData, roundTripFlightKeysWithErrorData } from './constants'
import type { FlightKey, KeyWithErrorText } from './types'

@Service()
export class CheckSubmitButtonService {
  constructor(
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly airSearchQueryStore: AirSearchQueryStore,
    @Inject()
    private readonly airSearchFormInputsService: AirSearchFormInputsService,
    @Inject()
    private readonly airSearchFormService: AirSearchFormService,
  ) {}

  get canSubmitSearch() {
    if (this.airSearchFormStore.isEditMode) {
      return this.editButton
    }
    return this.searchButton
  }

  get searchButton() {
    const areFieldsFilled = this.checkAreFieldsFilled()
    if (!areFieldsFilled) {
      return false
    }

    if (this.checkIsLocationInputError()) {
      return false
    }

    return this.airSearchFormService.checkIsDateRangeValid()
  }

  get editButton() {
    if (!this.checkAreFieldsFilled()) {
      return false
    }
    if (this.checkIsLocationInputError()) {
      return false
    }
    if (this.checkIsMultiSearchLengthChanged()) {
      return true
    }

    return (
      (this.checkIsSearchChanged() && this.airSearchFormService.checkIsDateRangeValid()) ||
      this.checkIsTypeChanged()
    )
  }

  private checkIsLocationInputError() {
    return this.airSearchFormStore.searchFlights.some((_, index) =>
      this.airSearchFormInputsService.checkIsLocationInputError(index),
    )
  }

  private checkIsTypeChanged() {
    return (
      this.airSearchQueryStore.searchQueryFlightType !==
      this.airSearchFormStore.searchFlightsEditCache.flightType
    )
  }

  private checkIsMultiSearchLengthChanged() {
    if (this.airSearchFormStore.searchFlightsEditCache.flightType !== FlightType.MultiCity) {
      return false
    }
    const editedFlightsLength = this.airSearchFormStore.searchFlightsEditCache.flights.length
    const originFlightsLength = this.airSearchQueryStore.searchQueryFlights.length
    return editedFlightsLength !== originFlightsLength
  }

  private checkIsSearchChanged() {
    const originalSearch = this.airSearchQueryStore.searchQueryFlights
    const editedSearch = this.airSearchFormStore.searchFlightsEditCache.flights
    const editedFlightType = this.airSearchFormStore.searchFlightsEditCache.flightType

    if (!editedSearch.length || !originalSearch.length) {
      return false
    }

    switch (editedFlightType) {
      case FlightType.MultiCity:
        return editedSearch.some((flight, index) => {
          if (!originalSearch[index]) {
            return true
          }
          return !checkIsEqual(
            {
              cabinClass: flight.cabinClass,
              timeRange: flight.timeRange,
              departureDate: flight.departureDate,
              destinationPlace: flight.destinationPlace,
              originPlace: flight.originPlace,
            },
            {
              cabinClass: originalSearch[index].cabinClass,
              timeRange: originalSearch[index].timeRange,
              departureDate: originalSearch[index].departureDate,
              destinationPlace: originalSearch[index].destinationPlace,
              originPlace: originalSearch[index].originPlace,
            },
          )
        })

      case FlightType.OneWay:
        return !checkIsEqual(
          {
            cabinClass: editedSearch[0]?.cabinClass,
            timeRange: editedSearch[0]?.timeRange,
            departureDate: editedSearch[0]?.departureDate,
            destinationPlace: editedSearch[0]?.destinationPlace,
            originPlace: editedSearch[0]?.originPlace,
          },
          {
            cabinClass: originalSearch[0]?.cabinClass,
            timeRange: originalSearch[0]?.timeRange,
            departureDate: originalSearch[0]?.departureDate,
            destinationPlace: originalSearch[0]?.destinationPlace,
            originPlace: originalSearch[0]?.originPlace,
          },
        )

      case FlightType.Round:
        return !checkIsEqual(
          {
            cabinClass: editedSearch[0]?.cabinClass,
            timeRange: editedSearch[0]?.timeRange,
            departureDate: editedSearch[0]?.departureDate,
            destinationPlace: editedSearch[0]?.destinationPlace,
            originPlace: editedSearch[0]?.originPlace,
            returnTimeRange: editedSearch[0]?.returnTimeRange,
            returnDate: editedSearch[0]?.returnDate,
          },
          {
            cabinClass: originalSearch[0]?.cabinClass,
            timeRange: originalSearch[0]?.timeRange,
            departureDate: originalSearch[0]?.departureDate,
            destinationPlace: originalSearch[0]?.destinationPlace,
            originPlace: originalSearch[0]?.originPlace,
            returnTimeRange: originalSearch[0].returnTimeRange,
            returnDate: originalSearch[0].returnDate,
          },
        )
      default:
        return false
    }
  }

  private isKeyWithErrorText(item: FlightKey): item is KeyWithErrorText {
    return 'errorTexti18nKey' in item
  }

  private checkAreFieldsFilled() {
    const { searchFlightType: flightType, searchFlights } = this.airSearchFormStore
    const firstFlight = searchFlights[0]
    if (!firstFlight) {
      return false
    }

    switch (flightType) {
      case FlightType.MultiCity:
        return searchFlights.reduce((_, flight, index) => {
          return flightKeysWithErrorData.reduce((result, item) => {
            if (flight[item.key]) {
              return result
            }

            if (this.isKeyWithErrorText(item)) {
              this.airSearchFormStore.setSingleValidationError(
                index,
                item.key,
                i18n.t(item.errorTexti18nKey),
              )
            }

            return false
          }, true)
        }, true)

      case FlightType.OneWay:
        return flightKeysWithErrorData.reduce((result, item) => {
          if (firstFlight[item.key]) {
            return result
          }

          if (this.isKeyWithErrorText(item)) {
            this.airSearchFormStore.setSingleValidationError(
              0,
              item.key,
              i18n.t(item.errorTexti18nKey),
            )
          }

          return false
        }, true)

      case FlightType.Round:
        return roundTripFlightKeysWithErrorData.reduce((result, item) => {
          if (firstFlight[item.key]) {
            return result
          }

          if (this.isKeyWithErrorText(item)) {
            this.airSearchFormStore.setSingleValidationError(
              0,
              item.key,
              i18n.t(item.errorTexti18nKey),
            )
          }

          return false
        }, true)

      default:
        return false
    }
  }
}
