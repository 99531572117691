import { useEffect, useRef } from 'react'
import type { HotelEmission } from '@fiji/types'
import { Block } from '@etta/ui/block'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import type { HotelEntity } from '@etta/modules/hotel-search/core/entities/hotel.entity'
import { useHotelCardAriaLabel } from '@fiji/hooks/use-hotel-card-aria-label'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { HotelCardLayout } from './layout'
import { Container } from './hotel-card-styled'

export type HotelCardProps = {
  hotel: HotelEntity
  as?: keyof JSX.IntrinsicElements
  onClick: (hotelId: string) => void
  isSelected?: boolean
  hotelEmission: HotelEmission
}

export function HotelCard({
  hotel,
  onClick,
  as = 'div',
  hotelEmission,
  isSelected,
}: HotelCardProps) {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const {
    displayConfiguration: { isTripAdvisorReviewsEnabled },
  } = displayConfigurationStore

  const { handleKeyDown } = useA11yKeydown(() => {
    onClick(hotel.id)
  })
  const ref = useRef<HTMLDivElement>(null)

  const hotelCardAriaLabel = useHotelCardAriaLabel({ hotel })

  useEffect(() => {
    if (!isSelected || !ref.current) {
      return
    }

    ref?.current?.scrollIntoView()
  }, [isSelected])

  return (
    <Block ref={ref}>
      <Container
        tabIndex={0}
        role="button"
        aria-label={hotelCardAriaLabel}
        data-tracking-id="hotel-card"
        isSelected={isSelected}
        onClick={() => {
          onClick(hotel.id)
        }}
        onKeyDown={handleKeyDown}
        as={as}>
        <HotelCardLayout
          isTripAdvisorReviewsEnabled={isTripAdvisorReviewsEnabled}
          isHotelLoading={!!hotel.isLoading}
          hotel={hotel}
          hotelEmission={hotelEmission}
        />
      </Container>
    </Block>
  )
}
