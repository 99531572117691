import type { HotelSearchQueryType } from '@fiji/hooks/search-queries/use-hotel-search-query/types'
import type { HotelFiltersType } from '@fiji/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { SearchLocationType } from '@fiji/enums'

type Props = {
  queryParams: HotelSearchQueryType
  hotelResultId?: string
  filters?: HotelFiltersType
  hotelName: string
}

export function getCreateSearchInput({ queryParams, filters, hotelName }: Props) {
  return {
    itineraryId: queryParams?.itineraryId || '',
    searchBy: SearchLocationType.HOTEL_PROPERTY,
    latitude: queryParams?.location?.latitude || 0,
    longitude: queryParams?.location?.longitude || 0,
    distance: filters?.distance || queryParams.hotelFilters?.initialDistance || 0,
    checkIn: dateFormat(queryParams.checkInDate!, 'y-MM-dd') || '',
    checkOut: dateFormat(queryParams.checkOutDate!, 'y-MM-dd') || '',
    guests: 1,
    locale: 'en',
    hotelName: hotelName || '',
    airportCode: '',
  }
}
