import { useTranslation } from 'react-i18next'
import { ModalDetails } from '@etta/components/modal-details'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { SeatMapContent } from '@etta/modules/seat-map'
import { Header, DoneLink } from './seat-map-rtp-modal-styled'

type Props = {
  isVisible: boolean
  onClose: () => void
}

export function SeatMapRTPModal({ isVisible, onClose }: Props) {
  const { t } = useTranslation()

  return (
    <ModalDetails
      isVisible={isVisible}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <Header>
        <IconButton icon="arrowBackLargePWA" size="medium" onClick={onClose} />
        <Text variant="title3">{t('AirSeatMap.SelectSeats')}</Text>
        <DoneLink onClick={onClose}>{t('AirSeatMap.Done')}</DoneLink>
      </Header>
      <Modal.Body>
        <SeatMapContent onClose={onClose} isReadonly={false} />
      </Modal.Body>
    </ModalDetails>
  )
}
