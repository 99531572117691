import type { Place } from '@fiji/types'
import type { Geocode } from '@fiji/graphql/types'

type Props = {
  isPickUp: boolean
  pickUpPlace: Place
  dropOffPlace: Place
}

export function getGeocode({ isPickUp, pickUpPlace, dropOffPlace }: Props): Geocode {
  const pickUpLatitude = pickUpPlace?.latitude
  const pickUpLongitude = pickUpPlace?.longitude
  const dropOffLatitude = dropOffPlace?.latitude
  const dropOffLongitude = dropOffPlace?.longitude
  const lat = isPickUp ? pickUpLatitude : dropOffLatitude
  const long = isPickUp ? pickUpLongitude : dropOffLongitude

  return {
    lat: parseFloat(lat?.toString() || ''),
    long: parseFloat(long?.toString() || ''),
  }
}
