import { useTranslation } from 'react-i18next'
import {
  EmisisonDetailsHeader,
  EmisisonDetailsDescription,
  EmisisonDetails,
} from '../rail-details-eco-check-modal-desktop-styled'

export function RailDetailsEcoCheckModalDesktopLowerBody() {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  return (
    <EmisisonDetails>
      <EmisisonDetailsHeader variant="headline">
        {t(i18nBase + '.HowDoWeEstimateRailQuestion')}
      </EmisisonDetailsHeader>
      <EmisisonDetailsDescription color="bodyText" variant="footnoteMedium">
        {t(i18nBase + '.HowDoWeEstimateRailAnswer')}
      </EmisisonDetailsDescription>
    </EmisisonDetails>
  )
}
