import { Service, Inject } from '@etta/di'
import { MapProvider } from '@etta/modules/display-configuration/core/enums'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { EttaMapAdapter } from '../infra/etta-map.adapter'
import { AppleMapService } from './apple/apple-map.service'
import { EttaMapStore } from './etta-map.store'

@Service()
export class EttaMapService {
  constructor(
    @Inject() private displayConfigurationStore: DisplayConfigurationStore,
    @Inject() private ettaMapStore: EttaMapStore,
    @Inject() private ettaMapAdapter: EttaMapAdapter,
    @Inject() private appleMapService: AppleMapService,
  ) {}

  public async loadMap() {
    const mapProvider = this.displayConfigurationStore.mapProvider
    switch (mapProvider) {
      case MapProvider.GoogleMaps: {
        this.loadGoogleMap()
        break
      }
      case MapProvider.AppleMapkit: {
        await this.loadAppleMap()
        break
      }
      default: {
        this.loadGoogleMap()
        break
      }
    }
  }

  private async loadAppleMap() {
    this.ettaMapStore.setIsLoading(true)
    this.ettaMapStore.setIsError(false)

    const tokenResult = await this.ettaMapAdapter.createAppleMapToken()

    if (tokenResult.isErr()) {
      this.ettaMapStore.setIsLoading(false)
      this.ettaMapStore.setIsError(true)
      return
    }
    const token = tokenResult.getValue()
    const mapkitResult = await this.appleMapService.loadAppleMap(token)

    if (mapkitResult.isErr()) {
      this.ettaMapStore.setIsLoading(false)
      this.ettaMapStore.setIsError(true)
      return
    }

    this.ettaMapStore.setMapToken(token)
    this.ettaMapStore.setIsLoading(false)
    this.ettaMapStore.setIsLoaded(true)
  }

  private loadGoogleMap() {
    this.ettaMapStore.setIsLoaded(true)
    this.ettaMapStore.setMapToken(String(process.env.REACT_APP_GOOGLE_API_KEY))
  }
}
