import { Block } from '@etta/ui/block'
import { BodyLoaderContainer, Loader, BodyLoaderRightContainer } from './duplicate-list-styled'

export function DuplicateSegmentLoader() {
  return (
    <BodyLoaderContainer>
      <Block marginRight={13}>
        <Loader width={24} height={24} />
      </Block>
      <BodyLoaderRightContainer>
        <Block marginBottom={14}>
          <Loader width={178} height={12} />
        </Block>
        <Block marginBottom={5}>
          <Loader width={178} height={12} />
        </Block>
        <Block paddingBottom={16}>
          <Loader width={82} height={12} />
        </Block>
      </BodyLoaderRightContainer>
    </BodyLoaderContainer>
  )
}
