import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CarRentalAmenities } from '../../../types'
import { AmenitiesList, AmenitiesItem, AmenitiesIcon } from './car-rental-details-amenities-styled'

type Props = {
  amenities?: CarRentalAmenities[]
}

export function CarRentalDetailsAmenities({ amenities = [] }: Props) {
  if (!amenities.length) {
    return null
  }

  return (
    <AmenitiesList>
      {amenities.map(({ name, value }) => {
        return (
          <AmenitiesItem key={name} aria-label={value} role="listitem">
            <AmenitiesIcon>
              <Icon name={name} color="bodyText" />
            </AmenitiesIcon>
            <Text textTransform="capitalize" variant="footnoteMedium" color="bodyText">
              {value}
            </Text>
          </AmenitiesItem>
        )
      })}
    </AmenitiesList>
  )
}
