import styled, { css } from 'styled-components'

export const Container = styled.div<{ isDesktop: boolean }>`
  ${({ isDesktop }) =>
    !isDesktop &&
    css`
      padding: 16px 0;
      border-bottom: 2px dashed ${(p) => p.theme.colors.borderLight};
    `}
`
