import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import {
  CarClassFilter,
  FuelFilter,
  SupplierFilter,
  TransmissionFilter,
} from '@etta/components/car-rental-filters'
import {
  ApplyButton,
  ClearButton,
  FooterContainer,
  ModalHeader,
  StyledBlock,
  StyledLink,
  StyledText,
  StyledTitle,
  ResetFilterBlockMobile,
} from './car-rental-filters-modal-content-styled'
import { useCarRentalFiltersModalContent } from './use-car-rental-filters-modal-content'

export const CarRentalFiltersModalContent = observer(function CarRentalFiltersModalContent() {
  const {
    modalTitle,
    handleResetToDefaultFilters,
    resetToDefaultFiltersLabel,
    resetToDefaultFiltersAriaLabel,
    clearAllLabel,
    applyFiltersLabel,
    handleClearAllFilters,
    handleApplyFilters,
    isApplyDisabled,
    isShowResetToDefaultFiltersButton,
    isResetToDefaultFiltersButtonEnabled,
    isMobile,
  } = useCarRentalFiltersModalContent()

  const resetLink = (
    <StyledLink
      aria-label={resetToDefaultFiltersAriaLabel}
      data-tracking-id="all-filters-modal-reset-to-default"
      onClick={handleResetToDefaultFilters}
      isDisabled={!isResetToDefaultFiltersButtonEnabled}>
      <StyledText
        variant="linkNormal"
        color={!isResetToDefaultFiltersButtonEnabled ? 'borderDark' : 'primary'}>
        {resetToDefaultFiltersLabel}
      </StyledText>
    </StyledLink>
  )

  return (
    <>
      <ModalHeader>
        <StyledBlock isFlexBlock isFullWidth>
          <StyledTitle variant="title3">{modalTitle}</StyledTitle>
          {!isMobile && isShowResetToDefaultFiltersButton && (
            <Block marginLeft={8} isFlexBlock isFullWidth>
              {resetLink}
            </Block>
          )}
        </StyledBlock>
      </ModalHeader>
      <Modal.Body>
        {isMobile && isShowResetToDefaultFiltersButton && (
          <ResetFilterBlockMobile>{resetLink}</ResetFilterBlockMobile>
        )}
        <TransmissionFilter isDropdownFilter={false} />
        <CarClassFilter isDropdownFilter={false} />
        <SupplierFilter isDropdownFilter={false} />
        <FuelFilter isDropdownFilter={false} withBottomBorder={false} />
      </Modal.Body>
      <Modal.Footer>
        <FooterContainer>
          <ClearButton
            variant="link"
            onClick={handleClearAllFilters}
            data-tracking-id="all-filters-modal-clear-all">
            {clearAllLabel}
          </ClearButton>
          <ApplyButton
            onClick={handleApplyFilters}
            disabled={isApplyDisabled}
            data-tracking-id="all-filters-modal-apply-filters">
            {applyFiltersLabel}
          </ApplyButton>
        </FooterContainer>
      </Modal.Footer>
    </>
  )
})
