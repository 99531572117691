import type {
  CarRentalPostBookingValueObject,
  CarServicePostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
  RideHailPostBookingValueObject,
  SegmentPostBookingValueObject,
  TrainPostBookingValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { SegmentType } from '@etta/core/enums'
import type {
  FlightSegmentFragment,
  TrainSegmentFragment,
  TrainStationFragment,
} from '@fiji/graphql/types'
import type {
  CarRentalItinerarySegment,
  CarServiceItinerarySegment,
  FlightItinerarySegment,
  HotelItinerarySegment,
  ItinerarySegment,
  RideHailItinerarySegment,
  TrainItinerarySegment,
} from '../../core'

export function toItinerarySegments(
  segments: SegmentPostBookingValueObject[] | null,
): ItinerarySegment[] {
  if (segments === null) {
    return []
  }

  const itinerarySegments: ItinerarySegment[] = []

  segments.forEach((segment) => {
    switch (segment.type) {
      case SegmentType.Flight:
        itinerarySegments.push(toFlightItinerarySegment(segment))
        return
      case SegmentType.Hotel:
        itinerarySegments.push(toHotelItinerarySegment(segment))
        return
      case SegmentType.RideHail:
        itinerarySegments.push(toRideHailItinerarySegment(segment))
        return
      case SegmentType.Train:
        itinerarySegments.push(toTrainItinerarySegment(segment))
        return
      case SegmentType.CarRental:
        itinerarySegments.push(toCarRentalItinerarySegment(segment))
        return
      case SegmentType.CarService:
        itinerarySegments.push(toCarServiceItinerarySegment(segment))
    }
  })

  return itinerarySegments.sort(sortSegments)
}

function toFlightItinerarySegment(segment: FlightPostBookingValueObject): FlightItinerarySegment {
  let firstSegment: FlightSegmentFragment | null = null
  let lastSegment: FlightSegmentFragment | null = null
  if (segment.segments) {
    firstSegment = segment.segments[0]
    lastSegment = segment.segments[segment.segments.length - 1]
  }

  return {
    type: SegmentType.Flight,
    originAirportCode: firstSegment?.departure?.airportCode ?? '',
    destinationAirportCode: lastSegment?.arrival?.airportCode ?? '',
    departureDateTime: segment.departureDateTime || null,
    arrivalDateTime: segment.arrivalDateTime || null,
  }
}

function toHotelItinerarySegment(segment: HotelPostBookingValueObject): HotelItinerarySegment {
  return {
    type: SegmentType.Hotel,
    name: segment.name || '',
    checkInDate: segment.checkIn || null,
    checkOutDate: segment.checkOut || null,
    duration: segment.numberOfNights,
  }
}

function toRideHailItinerarySegment(
  segment: RideHailPostBookingValueObject,
): RideHailItinerarySegment {
  return {
    type: SegmentType.RideHail,
    description: segment.description || '',
    pickUpDateTime: segment.pickUpTime || '',
  }
}

function toTrainItinerarySegment(segment: TrainPostBookingValueObject): TrainItinerarySegment {
  let firstSegment: TrainSegmentFragment | null = null
  let lastSegment: TrainSegmentFragment | null = null

  if (segment.segments) {
    firstSegment = segment.segments[0]
    lastSegment = segment.segments[segment.segments.length - 1]
  }

  const departureStation = firstSegment?.stations?.departure
  const arrivalStation = lastSegment?.stations?.arrival

  return {
    type: SegmentType.Train,
    originName: getTrainLocationName(departureStation),
    destinationName: getTrainLocationName(arrivalStation),
    departureDateTime: segment.departureDateTime,
    arrivalDateTime: segment.arrivalDateTime,
  }
}

function getTrainLocationName(trainStation: TrainStationFragment | null | undefined) {
  const city = trainStation?.location?.city
  const station = trainStation?.stationName

  return station || city || ''
}

function toCarRentalItinerarySegment(
  segment: CarRentalPostBookingValueObject,
): CarRentalItinerarySegment {
  return {
    type: SegmentType.CarRental,
    vendorName: segment.vendor?.name || '',
    pickUpDateTime: segment.pickUpTime || '',
    dropOffDateTime: segment.dropOffTime || '',
  }
}

function toCarServiceItinerarySegment(
  segment: CarServicePostBookingValueObject,
): CarServiceItinerarySegment {
  return {
    type: SegmentType.CarService,
    city: segment.dropOff?.address?.city || '',
    pickUpDateTime: segment.pickUp?.time || '',
    dropOffDateTime: segment.dropOff?.time || '',
  }
}

function sortSegments(a: ItinerarySegment, b: ItinerarySegment): number {
  // NOTE: We sort all segments based off date, except the hotel
  const aDate = getDateOfSegment(a)
  const bDate = getDateOfSegment(b)

  // In case of hotel, compare date first and place it at the end of the day
  // Compare using strings since hotels do not have time/tz
  if (a.type === SegmentType.Hotel || b.type === SegmentType.Hotel) {
    if (aDate === null) {
      return -1
    }
    if (bDate === null) {
      return 1
    }

    if (aDate.split('T')[0] === bDate.split('T')[0]) {
      if (a.type === SegmentType.Hotel) {
        return 1
      }
      if (b.type === SegmentType.Hotel) {
        return -1
      }
      return 0
    }
  }

  const aTime = aDate ? new Date(aDate).getTime() : undefined
  const bTime = bDate ? new Date(bDate).getTime() : undefined

  if (aTime && bTime) {
    return sortByTime(aTime, bTime)
  }

  return -1
}

function getDateOfSegment(segment: ItinerarySegment): string | null {
  switch (segment.type) {
    case SegmentType.Hotel:
      return segment.checkInDate
    case SegmentType.Flight:
    case SegmentType.Train:
      return segment.departureDateTime
    case SegmentType.RideHail:
    case SegmentType.CarRental:
    case SegmentType.CarService:
      return segment.pickUpDateTime
  }
}

function sortByTime(d1: number, d2: number) {
  if (d1 === d2) {
    return 0
  }
  return d1 > d2 ? 1 : -1
}
