import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AirSearchRestrictions } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { PostBookingAction } from '@fiji/enums'

const i18Base = 'TripPlanner.BaseSearch.'

type Args = {
  type: AirSearchRestrictions
  postBookingAction?: PostBookingAction
}

export function useSearchRestrictions({ type, postBookingAction }: Args) {
  const { t } = useTranslation()
  const staticLocationWarning = useMemo(() => {
    switch (type) {
      case AirSearchRestrictions.NoCarrierNoDestinationChange:
        return postBookingAction === PostBookingAction.Modify
          ? t(i18Base + 'AllFlightsNoCarrierNoDestinationChange')
          : t(i18Base + 'ReturnFlightNoCarrierNoDestinationChange')
      case AirSearchRestrictions.NoCarrierChange:
        return t(i18Base + 'NoCarrierChange')
      case AirSearchRestrictions.NoDestinationChange:
        return t(i18Base + 'NoDestinationChange')
    }
  }, [t, type, postBookingAction])
  return {
    staticLocationWarning,
  }
}
