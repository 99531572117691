import * as yup from 'yup'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import type { AirPreferenceExcerpt, ProfileOptions } from '../types'
import { mapDefaultPersonalInfo } from './map-default-personal-info'

type Args = {
  personalInfo: PersonalInfo
  guestPersonalInfo?: PersonalInfo | null
  isGuest: boolean
  travelerOptions?: TravelerOptions | null
  preferenceExcerpt?: AirPreferenceExcerpt
  primaryTraveler?: PurchaseInfoTravelerValueObject
  profileOptions: ProfileOptions
}

const i18nBase = 'UserInfo.'
const errorsBase = `${i18nBase}Errors.`

export function useUserScheme({
  personalInfo,
  travelerOptions,
  preferenceExcerpt,
  primaryTraveler,
  profileOptions,
  isGuest,
  guestPersonalInfo,
}: Args) {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams<{ bookingId?: string }>()
  const { bookingId } = queryParams

  const {
    firstName,
    middleName,
    lastName,
    address,
    phone,
    personalMobile,
    suffix,
    title,
    gender,
    dateOfBirth,
    email,
    redressNumber,
    knownTravelerNumber,
    knownTravelerIssuingCountry,
  } = mapDefaultPersonalInfo({
    personalInfo,
    travelerOptions,
    preferenceExcerpt,
    primaryTraveler,
    profileOptions,
    guestPersonalInfo,
    bookingId,
    isGuest,
  })

  const scheme = useScheme(() => {
    const {
      isAddressRequired,
      isDateOfBirthRequired,
      isGenderRequired,
      isPhoneNumberRequired,
      isEMailRequired,
      isFirstNameRequired,
      isMiddleNameRequired,
      isLastNameRequired,
      isSuffixRequired,
    } = travelerOptions || {}
    return R.pipe(
      () => {
        return {
          title: yup.string().default(title),
          redressNumber: yup.string().nullable().default(redressNumber),
          knownTravelerNumber: yup.string().nullable().default(knownTravelerNumber),
          knownTravelerIssuingCountry: yup.string().nullable().default(knownTravelerIssuingCountry),
        }
      },
      (currentScheme) => {
        const firstNameScheme = yup.string().default(firstName)
        if (isFirstNameRequired) {
          return {
            ...currentScheme,
            firstName: firstNameScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'FirstName') }),
            ),
          }
        }

        return {
          ...currentScheme,
          firstName: firstNameScheme,
        }
      },
      (currentScheme) => {
        const lastNameScheme = yup.string().default(lastName)
        if (isLastNameRequired) {
          return {
            ...currentScheme,
            lastName: lastNameScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'LastName') }),
            ),
          }
        }

        return {
          ...currentScheme,
          lastName: lastNameScheme,
        }
      },
      (currentScheme) => {
        const middleNameScheme = yup.string().nullable().default(middleName)
        if (isMiddleNameRequired) {
          return {
            ...currentScheme,
            middleName: middleNameScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'MiddleName') }),
            ),
          }
        }

        return {
          ...currentScheme,
          middleName: middleNameScheme,
        }
      },
      (currentScheme) => {
        const suffixScheme = yup.string().nullable().default(suffix)
        if (isSuffixRequired) {
          return {
            ...currentScheme,
            suffix: suffixScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'Suffix') }),
            ),
          }
        }

        return {
          ...currentScheme,
          suffix: suffixScheme,
        }
      },
      (currentScheme) => {
        const emailScheme = yup.string().required().default(email)
        if (isEMailRequired) {
          return {
            ...currentScheme,
            email: emailScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'Email') }),
            ),
          }
        }

        return {
          ...currentScheme,
          email: emailScheme,
        }
      },
      (currentScheme) => {
        const genderScheme = yup.string().default(gender)
        if (isGenderRequired) {
          return {
            ...currentScheme,
            gender: genderScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'Gender') }),
            ),
          }
        }

        return {
          ...currentScheme,
          gender: genderScheme,
        }
      },
      (currentScheme) => {
        const dateOfBirthScheme = yup
          .date()
          .nullable()
          .transform((curr, orig) => (!orig ? null : curr))
          .default(dateOfBirth)
        if (isDateOfBirthRequired) {
          return {
            ...currentScheme,
            dateOfBirth: dateOfBirthScheme.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'DOB') }),
            ),
          }
        }

        return {
          ...currentScheme,
          dateOfBirth: dateOfBirthScheme,
        }
      },
      (currentScheme) => {
        const phoneScheme = yup
          .string()
          .matches(/^[\d \-() \-+]*$/, t(i18nBase + 'InvalidPhone'))
          .when([], {
            is: () => isPhoneNumberRequired,
            then: (schema) =>
              schema.test('oneContact', t(errorsBase + 'OneContact'), function (_value, context) {
                const {
                  phone,
                  countryCode,
                  personalMobile,
                  personalMobileCountryCode,
                } = context.parent
                return phone || countryCode || personalMobile || personalMobileCountryCode
              }),
          })
          .test(
            'phoneRequired',
            t(i18nBase + 'Required', { field: t(errorsBase + 'Phone') }),
            function (_value, context) {
              const { phone, phoneCountryCode } = context.parent
              return phone || !!phone === !!phoneCountryCode
            },
          )
          .default(phone?.number)
        const phoneCountryCodeScheme = yup
          .string()
          .test(
            'phoneCountryCodeRequired',
            t(i18nBase + 'Required', { field: t(errorsBase + 'Country') }),
            function (_value, context) {
              const { phone, phoneCountryCode } = context.parent
              return phoneCountryCode || !!phone === !!phoneCountryCode
            },
          )
          .default(phone?.countryCode)

        const personalMobileScheme = yup
          .string()
          .matches(/^[\d \-() \-+]*$/, t(i18nBase + 'InvalidMobile'))
          .test(
            'personalMobileRequired',
            t(i18nBase + 'Required', { field: t(errorsBase + 'Mobile') }),
            function (_value, context) {
              const { personalMobile, personalMobileCountryCode } = context.parent
              return personalMobile || !!personalMobile === !!personalMobileCountryCode
            },
          )
          .default(personalMobile?.number)
        const personalMobileCountryCodeScheme = yup
          .string()
          .test(
            'personalMobileCountryCodeRequired',
            t(i18nBase + 'Required', { field: t(errorsBase + 'Country') }),
            function (_value, context) {
              const { personalMobile, personalMobileCountryCode } = context.parent
              return personalMobileCountryCode || !!personalMobile === !!personalMobileCountryCode
            },
          )
          .default(personalMobile?.countryCode)

        return {
          ...currentScheme,
          phone: phoneScheme,
          phoneCountryCode: phoneCountryCodeScheme,
          personalMobile: personalMobileScheme,
          personalMobileCountryCode: personalMobileCountryCodeScheme,
        }
      },
      (currentScheme) => {
        const addressScheme = () => {
          const optionalScheme = {
            country: yup.string().nullable().default(address?.countryCode),
            zip: yup.string().nullable().default(address?.postalCode),
            state: yup.string().nullable().default(address?.stateCode),
            city: yup
              .string()
              .nullable()
              .default(address?.city)
              .test(
                'no-numbers',
                t(errorsBase + 'InvalidCityName'),
                (value) => !/\d/.test(value || ''),
              ),
            street1: yup.string().nullable().default(address?.street1),
            street2: yup.string().nullable().default(address?.street2),
          }

          if (!isAddressRequired) {
            return optionalScheme
          }
          return {
            country: optionalScheme.country.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'CountryAddress') }),
            ),
            zip: optionalScheme.zip.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'Zip') }),
            ),
            state: optionalScheme.state,
            city: optionalScheme.city.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'City') }),
            ),
            street1: optionalScheme.street1.required(
              t(i18nBase + 'Required', { field: t(errorsBase + 'Street1') }),
            ),
            street2: optionalScheme.street2,
          }
        }

        return {
          ...currentScheme,
          ...addressScheme(),
        }
      },
    )()
  }, [travelerOptions])

  return {
    scheme,
  }
}
