import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'
import { useAppSelector } from '@fiji/store'
import { mobilitySelector } from '@fiji/store/mobility'
import { appMode, ScreenType, useScreenType } from '@fiji/modes'
import {
  generateMobilityURLForIOS,
  MobilityIOSAction,
  MobilityPWAEvent,
} from '@fiji/hooks/mobility/mobility-ios-scheme'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMobilityViewClose } from '@fiji/hooks/mobility/use-mobility-view-close'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { ROUTES } from '@fiji/routes'
import { useUberUniversalLink } from './use-uber-universal-link'

export function useMobilityBookingDone() {
  const { isOpenInIOS } = appMode
  const history = useHistory()
  const mobility = useAppSelector(mobilitySelector)
  const { tripsListActions } = useTripsListContext()
  const {
    searchRideHailStore,
    rideHailBookStore,
    rideHailSearchItineraryActions,
    rideHailAirportPickupActions,
    searchRideHailActions,
  } = useRideHailSearchContext()
  const { openUberAppWithoutParams } = useUberUniversalLink()
  const { handleClose: handleMobilityViewClose } = useMobilityViewClose()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const { processId, transactionGroupId } = searchRideHailStore.searchRideHailTripInfo

  const handleClose = useCallback(() => {
    rideHailSearchItineraryActions.dropItineraryStore()
    if (isOpenInIOS) {
      document.location.href = generateMobilityURLForIOS(
        MobilityIOSAction.RefreshItinerary,
        MobilityPWAEvent.BookingDoneTapped,
      )
      return
    }

    if (isDesktop) {
      if (searchRideHailStore.renderState === RenderState.confirm && !rideHailBookStore.isError) {
        searchRideHailActions.refreshTrips()
      }
      searchRideHailActions.dropSearchRideHail()
    }

    return handleMobilityViewClose()
  }, [
    handleMobilityViewClose,
    isDesktop,
    isOpenInIOS,
    rideHailSearchItineraryActions,
    searchRideHailActions,
    searchRideHailStore,
    rideHailBookStore,
  ])

  const handleDone = useCallback(() => {
    rideHailAirportPickupActions.dropStore()

    if (isDesktop) {
      searchRideHailActions.refreshTrips()
      searchRideHailActions.closeMobilitySearchDesktopModal()
      return
    }

    if (searchRideHailStore.isOnDemandRide) {
      openUberAppWithoutParams()
      return
    }

    rideHailSearchItineraryActions.dropItineraryStore()
    if (isOpenInIOS) {
      document.location.href = generateMobilityURLForIOS(
        MobilityIOSAction.RefreshItinerary,
        MobilityPWAEvent.BookingDoneTapped,
      )
      return
    }

    tripsListActions.dropAndReloadAllTrips()

    if (mobility.callbackPath) {
      return history.replace({
        pathname: mobility.callbackPath,
        search: mobility.callbackParams ?? '',
      })
    }

    return history.replace(
      ROUTES.postBooking.trip({
        id: processId,
        transactionGroupId: transactionGroupId?.toString(),
        isForceRefetch: true,
      }),
    )
  }, [
    searchRideHailStore.isOnDemandRide,
    rideHailSearchItineraryActions,
    isOpenInIOS,
    tripsListActions,
    mobility.callbackPath,
    mobility.callbackParams,
    history,
    openUberAppWithoutParams,
    rideHailAirportPickupActions,
    isDesktop,
    searchRideHailActions,
    processId,
    transactionGroupId,
  ])

  return {
    handleClose,
    handleDone,
  }
}
