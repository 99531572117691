import type { LayoutProps } from '../../types'
import { Footer } from './traveler-info-desktop-styled'

export function TravelerInfoDesktop({ contentSlot, footerSlot }: LayoutProps) {
  return (
    <>
      {contentSlot}
      <Footer>{footerSlot}</Footer>
    </>
  )
}
