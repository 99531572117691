import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { TripCarRentalContentProps } from './trip-car-rental-content'
import { TripCarRentalContent } from './trip-car-rental-content'

type Props = TripCarRentalContentProps & {
  isVisible: boolean
  onClose: () => void
  isAdaptivePosition?: boolean
}

export function TripCarRental({ isVisible, onClose, isAdaptivePosition, ...props }: Props) {
  const type = useScreenType()

  const modalPosition = isAdaptivePosition && type !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      backgroundColor="background"
      position={modalPosition}
      horizontalDimension="content-760">
      <TripCarRentalContent {...props} onClose={onClose} />
    </Modal>
  )
}
