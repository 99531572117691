import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FlightLegSegment } from '@fiji/graphql/types'
import {
  getAirPenalty,
  getAirRate,
  shouldShowRefundableLabel,
} from '@fiji/utils/trip-review/air-data'
import { Badge } from './badge'

const labelTypes = {
  WebFare: 'TRAVEL_FUSION',
  Penalty: 'PENALTY',
  Prepaid: 'PREPAID',
  Reserve: 'RESERVE',
}

const penaltyType = {
  NonRefundable: 'NON_REFUNDABLE',
  Change: 'CHANGE',
  AdvancedPurchase: 'ADVANCED_PURCHASE',
  Cancel: 'CANCEL',
}

type Props = {
  flight: FlightLegSegment
}

export function AirBadges({ flight }: Props) {
  const { t } = useTranslation()
  const i18Base = 'SearchSegments.Badges'
  const showOutOfPolicy = !flight.policy.isInPolicy
  const [isPreferred, setIsPreferred] = useState(false)
  const [isNonRefundable, setIsNonRefundable] = useState(false)
  const [isFlightPenaltyCancel, setIsFlightPenaltyCancel] = useState(false)
  const [isFlightPenaltyChange, setIsFlightPenaltyChange] = useState(false)
  const [isFlightWebFare, setIsFlightWebFare] = useState(false)

  useMemo(() => {
    flight.segments.map(({ preferredDescription, penaltyFees, bookingProvider }) => {
      if (!isPreferred && preferredDescription) {
        setIsPreferred(true)
      }

      if (!isNonRefundable && getAirPenalty(penaltyType.NonRefundable, penaltyFees)) {
        setIsNonRefundable(true)
      }

      if (!isFlightPenaltyCancel && getAirPenalty(penaltyType.Cancel, penaltyFees)) {
        setIsFlightPenaltyCancel(true)
      }

      if (!isFlightPenaltyChange && getAirRate(penaltyType.Change, penaltyFees)) {
        setIsFlightPenaltyChange(true)
      }

      if (bookingProvider && bookingProvider === labelTypes.WebFare) {
        setIsFlightWebFare(true)
      }
    })
  }, [flight.segments, isFlightPenaltyCancel, isFlightPenaltyChange, isNonRefundable, isPreferred])

  const badges = useMemo(() => {
    const showRefundableLabel = shouldShowRefundableLabel(flight.segments)

    const refundableBadge = isNonRefundable ? (
      <Badge key="non-refundable" title={t(i18Base + '.NonRefundable')} icon="nonRefundablePWA" />
    ) : (
      <Badge key="refundable" title={t(i18Base + '.Refundable')} icon="refundablePWA" />
    )

    return [
      showOutOfPolicy && (
        <Badge key="out-of-policy" title={t(i18Base + '.OutOfPolicy')} icon="outOfPolicyPWA" />
      ),
      isPreferred && (
        <Badge key="preferred" title={t(i18Base + '.Preferred')} icon="preferredPWA" />
      ),
      isFlightPenaltyCancel && (
        <Badge key="cancel-penalty" title={t(i18Base + '.Penalty')} icon="penaltyAppliesPWA" />
      ),
      showRefundableLabel && refundableBadge,
      isFlightPenaltyChange && (
        <Badge key="change-penalty" title={t(i18Base + '.Penalty')} icon="penaltyAppliesPWA" />
      ),
      isFlightWebFare && <Badge key="web-fare" title={t(i18Base + '.WebFare')} icon="webFarePWA" />,
    ].filter(Boolean)
  }, [
    t,
    showOutOfPolicy,
    isPreferred,
    isFlightPenaltyCancel,
    isNonRefundable,
    isFlightPenaltyChange,
    isFlightWebFare,
    flight.segments,
  ])

  return <>{badges.map((badge) => badge)}</>
}
