import { SegmentType } from '@etta/core/enums'
import type { TripDetailsCostSummary } from '@fiji/graphql/types'
import { TripCost } from './trip-cost'

type Props = {
  costSummary?: TripDetailsCostSummary
  segmentType?: SegmentType
}

export function TripTotalCost({ costSummary, segmentType }: Props) {
  if (!costSummary) {
    return null
  }

  switch (segmentType) {
    case SegmentType.Flight:
      return <TripCost rate={costSummary.flight?.total} />
    case SegmentType.Hotel:
      return <TripCost rate={costSummary.hotel?.total} />
    case SegmentType.CarRental:
      return <TripCost rate={costSummary.carRental?.total} />
    case SegmentType.CarService:
      return <TripCost rate={costSummary.carService?.total} />
    case SegmentType.Train:
      return <TripCost rate={costSummary.train?.total} />
    default:
      return <TripCost rate={costSummary.totalCost} />
  }
}
