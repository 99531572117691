import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { TabsContext } from '../tabs-context'
import type { TabName } from '../types'

type Args = {
  value: TabName
  ariaLabel?: string
}

const i18Base = 'Accessibility'

export function useTab({ value, ariaLabel }: Args) {
  const { t } = useTranslation()
  const context = useContext(TabsContext)
  const indexRef = useRef(0)
  const tabBasis = context.tabBasis

  const isActive = context.activeTab === value
  const onClick = useCallback(() => {
    context.changeActiveTab(value)
  }, [context, value])

  useEffect(() => {
    indexRef.current = context.totalTabsCount
    context.addTab(value)

    return () => {
      context.removeTab(value)
    }
    // WARN: This effect only for mount/unmount logic. Do not add any deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabLabel = `${ariaLabel || ''}. ${
    isActive ? t(i18Base + '.Selected') : t(i18Base + '.TapToSelect')
  }`

  return {
    isActive,
    tabBasis,
    tabLabel,
    onClick,
  }
}
