import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { SegmentType } from '@etta/core/enums'
import type { ConfirmationNumberData } from '../types'

type Args = {
  bookedSegments: SegmentPostBookingValueObject[]
}

export function useBookedSegments({ bookedSegments }: Args) {
  return bookedSegments.reduce<ConfirmationNumberData[]>((acc, segment) => {
    switch (segment.type) {
      case SegmentType.Flight:
        if (!segment.segments) {
          return acc
        }
        return [
          ...acc,
          ...segment.segments?.map((subsegment) => ({
            id: subsegment.segmentId,
            companyName: subsegment.name,
            confirmationNumber: subsegment.confirmationNumber,
          })),
        ]
      case SegmentType.Hotel:
        return [
          ...acc,
          {
            id: segment.id,
            companyName: segment.name,
            confirmationNumber: segment.confirmationNumber,
          },
        ]
      case SegmentType.CarRental:
        return [
          ...acc,
          {
            id: segment.carId,
            companyName: segment.vendor?.name,
            confirmationNumber: segment.confirmationNumber,
          },
        ]
      default:
        return acc
    }
  }, [])
}
