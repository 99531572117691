import { Service, Inject } from '@etta/di'
import i18n from '@fiji/i18n'
import { dateFormat, getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { addressFormatter } from '@etta/modules/settings/interface/services/utils/address-formatter'
import { phoneFormatter } from '@etta/modules/settings/interface/services/utils/phone-formatter'
import { FieldSettingsCategory } from '../../core/enums/field-settings-category'
import type { MisValueObject } from '../../../user/core/value-objects'
import { FieldSettingsStore } from '../stores/field-settings'
import { BaseSettingsService } from './base.service'

@Service()
export class AccountInformationService {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
  ) {}

  get profile() {
    return this.baseSettingsService.getProfileFromUser()
  }

  getStructuredAccountInformation() {
    return {
      personalInformation: {
        isSectionDisplayed: Boolean(
          !!this.fullName ||
            !!this.profile?.username ||
            !!this.profile?.profilePin ||
            !!this.profile?.personalContact?.email ||
            !!this.profile?.dateOfBirth ||
            !!this.profile?.gender ||
            !!this.profile?.personalContact?.phone?.number,
        ),
        name: this.fullName,
        DOB: this.formattedDOB,
        gender: this.formattedGender,
        username: this.getStrValueOr(this.profile?.username, '-'),
        email: this.getStrValueOr(this.profile?.personalContact?.email, '-'),
        profilePin: this.getStrValueOr(this.profile?.profilePin, '-'),
        phone: this.personalContactPhone,
      },

      employee: {
        isSectionDisplayed:
          !!this.profile?.employee?.isVip ||
          !!this.profile?.employee?.employeeId ||
          !!this.profile?.employee?.isActiveInCompany ||
          !!this.profile?.employee?.jobTitle ||
          !!this.profile?.employee?.jobLevel ||
          !!this.profile?.employee?.manager ||
          !!this.profile?.employee?.defaultApproverId ||
          !!this.profile?.employee?.costCenter ||
          !!this.profile?.employee?.departmentName ||
          !!this.profile?.employee?.departmentCode ||
          !!this.profile?.employee?.division ||
          !!this.profile?.employee?.businessUnit ||
          !!this.profile?.employee?.departmentName ||
          !!this.profile?.mis,
        VIP: this.vipIndicator,
        employeeType: this.getStrValueOr(this.profile?.employee?.employeeType, '-'),
        employeeId: this.getStrValueOr(this.profile?.employee?.employeeId, '-'),
        employeeStatus: this.employeeStatus,
        jobTitle: this.getStrValueOr(this.profile?.employee?.jobTitle, '-'),
        jobLevel: this.getStrValueOr(this.profile?.employee?.jobLevel, '-'),
        managerId: this.getStrValueOr(this.profile?.employee?.manager?.externalId, '-'),
        expenseApproverId: this.getStrValueOr(this.profile?.approvers?.expenseApproverId, '-'),
        purchaseApproverId: this.getStrValueOr(this.profile?.approvers?.purchaseApproverId, '-'),
        travelApproverId: this.getStrValueOr(this.profile?.approvers?.travelApproverId, '-'),
        costCenter: this.getStrValueOr(this.profile?.employee?.costCenter, '-'),
        departmentName: this.getStrValueOr(this.profile?.employee?.departmentName, '-'),
        departmentCode: this.getStrValueOr(this.profile?.employee?.departmentCode, '-'),
        division: this.getStrValueOr(this.profile?.employee?.division, '-'),
        businessUnit: this.getStrValueOr(this.profile?.employee?.businessUnit, '-'),
        companyName: this.getStrValueOr(this.profile?.employee?.companyName, '-'),
        misFields: this.misFields,
      },

      business: {
        isSectionDisplayed: Boolean(
          !!this.profile?.businessContact?.address ||
            !!this.profile?.businessContact?.officePhone ||
            !!this.profile?.businessContact?.mobilePhone ||
            !!this.profile?.groupware?.groupwareId ||
            !!this.profile?.confEmail,
        ),
        address: this.businessAddress,
        phone: this.businessContactOfficePhone,
        fax: this.businessContactFaxPhone,
        mobile: this.businessContactMobilePhone,
        email: this.getStrValueOr(this.profile?.personalContact?.email, '-'),
        groupwareId: this.getStrValueOr(this.profile?.groupware?.groupwareId, '-'),
        confirmationEmail: this.getStrValueOr(this.profile?.confEmail, '-'),
      },

      home: {
        isSectionDisplayed: Boolean(
          !!this.profile?.personalContact?.address ||
            !!this.profile?.personalContact?.phone ||
            !!this.profile?.personalContact?.homeEmail,
        ),
        address: this.homeAddress,
        phone: this.personalContactPhone,
        email: this.getStrValueOr(this.profile?.personalContact?.homeEmail, '-'),
      },
      isDelegatedOrImpersonated: !!(
        this.baseSettingsService.getUser()?.delegatedId ||
        this.baseSettingsService.getUser()?.impersonatedId
      ),
    }
  }

  get misFields() {
    return [...Array(7).keys()].map((num) => ({
      label: i18n.t('Settings.AccountInformation.Modal.MISField', { num: num + 1 }),
      value: this.profile?.mis?.[`mis${num + 1}` as keyof MisValueObject] || '-',
    }))
  }

  get fullName() {
    const settings = this.fieldSettingsStore.fieldsSettings[
      FieldSettingsCategory.PersonalInformation
    ]

    return [
      settings.title.isRendered && this.formattedTitle,
      settings.firstName.isRendered && this.profile?.firstName,
      settings.middleName.isRendered && this.profile?.middleName,
      settings.lastName.isRendered && this.profile?.lastName,
      settings.suffix.isRendered && this.profile?.suffix,
    ]
      .filter(Boolean)
      .join(' ')
  }

  get formattedTitle() {
    if (!this.profile?.title) {
      return ''
    }

    return i18n.t('UserInfo.Labels.' + this.profile.title)
  }

  get formattedDOB() {
    if (!this.profile?.dateOfBirth) {
      return '-'
    }

    return dateFormat(getDateWithoutTimezoneOffset(this.profile?.dateOfBirth), 'MMM, d yyyy')
  }

  get formattedGender() {
    if (!this.profile?.gender) {
      return '-'
    }

    return i18n.t(`UserInfo.Genders.${this.profile?.gender}`)
  }

  get vipIndicator() {
    const isVip = this.profile?.employee?.isVip
    if (isVip !== false && isVip !== true) {
      return '-'
    }
    return isVip ? 'Yes' : 'No'
  }

  get employeeStatus() {
    const isActiveInCompany = this.profile?.employee?.isActiveInCompany
    if (isActiveInCompany !== false && isActiveInCompany !== true) {
      return '-'
    }
    return isActiveInCompany ? 'Active' : 'Inactive'
  }

  get businessAddress() {
    if (!this.profile?.businessContact?.address) {
      return '-'
    }
    return addressFormatter(this.profile.businessContact.address)
  }

  get homeAddress() {
    if (!this.profile?.personalContact?.address) {
      return '-'
    }
    return addressFormatter(this.profile.personalContact.address)
  }

  get businessContactOfficePhone() {
    if (!this.profile?.businessContact?.officePhone?.number) {
      return '-'
    }

    return phoneFormatter(this.profile.businessContact.officePhone, true)
  }

  get businessContactFaxPhone() {
    if (!this.profile?.businessContact?.faxPhone?.number) {
      return '-'
    }

    return phoneFormatter(this.profile.businessContact.faxPhone)
  }

  get businessContactMobilePhone() {
    if (!this.profile?.businessContact?.mobilePhone?.number) {
      return '-'
    }

    return phoneFormatter(this.profile.businessContact.mobilePhone)
  }

  get personalContactPhone() {
    if (!this.profile?.personalContact?.phone?.number) {
      return '-'
    }

    return phoneFormatter(this.profile.personalContact.phone)
  }

  private getStrValueOr(value?: string | null, defaultValue: string = '') {
    if (value) {
      return value
    }
    return defaultValue
  }
}
