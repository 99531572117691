import styled from 'styled-components'

export const Container = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding-bottom: 20px;
`

export const LinkContainer = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
`

export const DBAContainer = styled.div`
  padding-top: 21px;
  display: flex;
  justify-content: start;
  flex-direction: row;
`
