import { useTranslation } from 'react-i18next'
import type { Hotel } from '@fiji/graphql/types'
import { HotelImage } from '@etta/components/hotel-image/hotel-image'
import { HotelInfoList } from '@etta/components/hotel-info-list/hotel-info-list'
import { useOutOfPolicyHotelData } from '@fiji/hooks/use-out-of-policy-hotel-data'
import { useHotelCardAriaLabel } from '@fiji/hooks/use-hotel-card-aria-label'
import {
  HotelCardWrapper,
  HotelCardDescriptionWrapper,
  HotelCardDescription,
  HotelTitle,
  HotelDetails,
  StarRatingPlaceholder,
  HotelImageWrapper,
} from './hotel-carousel-card-styled'

type Props = {
  hotel: Hotel
  onClick: (hotelId: string) => void
}
const i18nBase = 'SearchResults.Hotel'

export function HotelCarouselCard({ hotel, onClick }: Props) {
  const { t } = useTranslation()
  const hotelCardAriaLabel = useHotelCardAriaLabel({ hotel })
  const {
    distance,
    logoPhotoUrl,
    name,
    starRating,
    chainCode,
    isPreferred,
    photos,
    preference,
  } = hotel

  const { isOutOfPolicy, isAllRoomsOutOfPolicy } = useOutOfPolicyHotelData(hotel)
  const handleClick = () => {
    // to check hotel modal data go to src/etta-components/hotel-card/use-hotel-card.ts
    onClick(hotel.id)
  }

  return (
    <HotelCardWrapper
      aria-label={hotelCardAriaLabel}
      onClickCapture={handleClick}
      data-tracking-id={hotel.id + '-card'}>
      <HotelImageWrapper>
        <HotelImage photos={photos} logoPhotoUrl={logoPhotoUrl} chainCode={chainCode} />
      </HotelImageWrapper>
      <HotelCardDescriptionWrapper>
        {starRating ? <HotelInfoList starRating={starRating} /> : <StarRatingPlaceholder />}
        <HotelCardDescription>
          <HotelTitle aria-label={t(`${i18nBase}.HotelNameAriaLabel`, { hotelName: name })}>
            {name}
          </HotelTitle>
          <HotelDetails>
            <HotelInfoList
              isSmallIcons
              distance={distance}
              isPreferred={isPreferred}
              preference={preference}
              isOutOfPolicy={isOutOfPolicy}
              isAllRoomsOutOfPolicy={isAllRoomsOutOfPolicy}
            />
          </HotelDetails>
        </HotelCardDescription>
      </HotelCardDescriptionWrapper>
    </HotelCardWrapper>
  )
}
