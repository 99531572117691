import styled from 'styled-components'
import { TextField } from '@etta/ui/text-field'
import {
  HelperContainer,
  Container,
  Input,
} from '@etta/ui/text-field/base-text-field/base-text-field-styles'
import { headline } from '@fiji/style'

export const RootContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const SelectTextField = styled(TextField)`
  flex: 1;

  ${HelperContainer} {
    display: none;
  }

  ${Container} {
    background-color: ${(p) => p.theme.colors.background};
    border: none;
    border-radius: 6px;
  }

  ${Input} {
    ${headline}

    &::placeholder {
      color: ${(p) => p.theme.colors.bodyText};
      ${headline};
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 0;
  width: 100%;
`

export const OptionContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
`
