import { useState } from 'react'
import type { PreferenceOptions } from '@fiji/graphql/types'

export function useSpecialRequest(value: PreferenceOptions[]) {
  const [specialRequestsValues, setSpecialRequestValues] = useState(value.map((el) => el.value))
  const onChangeSpecialRequest = (requests: string[]) => {
    setSpecialRequestValues(requests)
  }

  return {
    specialRequestsValues,
    onChangeSpecialRequest,
  }
}
