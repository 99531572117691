import { useCallback } from 'react'

import { useAppSelector } from '@fiji/store'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { getDistanceUnit } from '@fiji/utils/get-distance-unit'
import { useUserContext } from '@etta/modules/user'
import { getSlider } from './get-slider'
import { getMarks } from './get-marks'

type Props = {
  distance?: number
  onChange: (value: number) => void
}

const MIN_INDEX_VALUE = 0
const DEFAULT_DISTANCE_VALUE = 5
const DEFAULT_MAX_VALUE = 20

export function useDistance({ distance, onChange }: Props) {
  const { userStore } = useUserContext()
  const { hotelSearchRadius } = useAppSelector(displayConfigurationSelector)
  const profile = userStore.profile

  const maxValue = hotelSearchRadius.max || DEFAULT_MAX_VALUE
  const distanceSliderArray = getSlider(maxValue)
  const distanceRange = distanceSliderArray.indexOf(
    distance || hotelSearchRadius.default || DEFAULT_DISTANCE_VALUE,
  )
  const measurementUnit = profile?.measurementUnit || undefined
  const distanceUnit = `${getDistanceUnit(measurementUnit)}.`

  const handleDistanceChange = useCallback(
    (value: number) => {
      const distance = distanceSliderArray[value]
      onChange(distance)
    },
    [onChange, distanceSliderArray],
  )

  const maxDistance = distanceSliderArray.length - 1
  const marks = getMarks(maxDistance)

  return {
    minDistance: MIN_INDEX_VALUE,
    maxDistance,
    marks,
    distanceRange,
    distanceUnit,
    handleDistanceChange,
    maxValue,
  }
}
