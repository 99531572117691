import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { Place, TimeRange } from '@fiji/types'
import type { SegmentType } from '@fiji/graphql/types'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { StyledTextVariant, TimeRangeOption } from '@fiji/enums'
import type { TimeRangeDirection } from '@fiji/enums'
import { ModalAnimation } from '@etta/ui/modal-animation'
import { StyledSlider } from '../shared'
import { useTimeRangePicker } from './use-time-range-picker'
import {
  AirportTitle,
  Container,
  TabLabel,
  TimeSelectorContainer,
  InputContainer,
  SliderContainer,
  Radio,
  ConfirmButton,
  TimeLabel,
  Footer,
} from './time-range-picker-styled'

type Props = {
  originPlace?: Place | null
  destinationPlace?: Place | null
  actionLabel: string
  selectedTime: TimeRange
  onSubmit?: (time: TimeRange) => void
  date?: Date | null
  initialCustomTime: TimeRange
  timeOptions: TimeRange[]
  isVisible: boolean
  onClose: () => void
  timeRangeDirection: TimeRangeDirection
  forSegment?: SegmentType
}

export function TimeRangePicker({
  actionLabel,
  onSubmit,
  selectedTime,
  date,
  originPlace,
  destinationPlace,
  initialCustomTime,
  timeOptions,
  isVisible,
  forSegment,
  onClose,
}: Props) {
  const {
    customTimeValue,
    title,
    convertTimeLabel,
    onCustomTimeChange,
    onRadioButtonClick,
    handleSubmit,
    pickedTime,
    handleClose,
  } = useTimeRangePicker({
    originPlace,
    destinationPlace,
    selectedTime,
    date,
    onSubmit,
    onClose,
    initialCustomTime,
    forSegment,
  })
  const { ref } = useClickOutside(() => {
    if (isVisible) {
      handleClose()
    }
  })
  useShouldOpenInViewport({ isVisible, ref })

  const { t } = useTranslation()
  const i18BasePath = 'FlightSearch.TimePicker'

  const isShowingConfirmButton = pickedTime.id === TimeRangeOption.CustomTime

  const handleRadioButtonClick = useCallback(
    (time: TimeRange) => () => {
      onRadioButtonClick(time)
      if (time.id !== TimeRangeOption.CustomTime) {
        handleSubmit(time)
      }
    },
    [onRadioButtonClick, handleSubmit],
  )

  return (
    <ModalAnimation isOpen={isVisible}>
      <Container ref={ref}>
        <TabLabel aria-label={actionLabel}>{actionLabel}</TabLabel>

        <AirportTitle aria-label={title}>{title}</AirportTitle>

        <TimeSelectorContainer>
          {timeOptions.map((time) => {
            const timeLabel = convertTimeLabel(time)

            return (
              <InputContainer
                key={time.id}
                aria-label={
                  time.id === pickedTime.id
                    ? t(i18BasePath + '.SelectedTimeRange', { label: timeLabel })
                    : t(i18BasePath + '.SelectTimeRange', { label: timeLabel })
                }
                isCustomTimeSelected={
                  pickedTime.id === TimeRangeOption.CustomTime &&
                  time.id === TimeRangeOption.CustomTime
                }>
                <Radio
                  key={time.id}
                  aria-label={timeLabel}
                  isChecked={time.id === pickedTime.id}
                  onChange={handleRadioButtonClick(time)}>
                  <TimeLabel variant={StyledTextVariant.subHeadMedium} color="mainText">
                    {timeLabel}
                  </TimeLabel>
                </Radio>
                {time.id === pickedTime.id && time.id === TimeRangeOption.CustomTime && (
                  <SliderContainer>
                    <StyledSlider
                      onChange={(result) => onCustomTimeChange(result as number)}
                      value={customTimeValue}
                      min={0}
                      max={23}
                      marks={[0, 23]}
                      markTitleType="time"
                    />
                  </SliderContainer>
                )}
              </InputContainer>
            )
          })}
        </TimeSelectorContainer>
        {isShowingConfirmButton && (
          <Footer>
            <ConfirmButton onClick={() => handleSubmit(pickedTime)}>
              {t(i18BasePath + '.ConfirmButton')}
            </ConfirmButton>
          </Footer>
        )}
      </Container>
    </ModalAnimation>
  )
}
