import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  min-width: 480px;
  width: 480px;
  margin-inline: 15px;
  padding: 14px 24px;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.background4};
`
