import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'
import { formatLocation } from './format-location'

type Args = {
  locations: CarRentalMultiVendorLocation[]
  searchValue: string
}

export function getFilteredLocations({ locations, searchValue }: Args) {
  return locations.filter((location) => {
    if (searchValue.trim().length === 0) {
      return true
    }
    const formattedLocation = formatLocation(location)
    return formattedLocation.toLowerCase().includes(searchValue.toLowerCase())
  })
}
