import { useSelector } from 'react-redux'
import type { AppState } from '@fiji/store'
import { useSearchParams } from '@fiji/hooks/use-search-params'
import { useGetHotelReviewsQuery } from '@fiji/graphql/hooks'
import { buildMetadataLogs } from '@fiji/utils/build-metadata-logs'

export function useHotelReviews(hotelId: string, shouldForceReviews?: boolean) {
  const { isPhoenixHotelSearchEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )
  const { searchParams } = useSearchParams()
  const { data, loading, error } = useGetHotelReviewsQuery({
    variables: {
      hotelId: hotelId,
      logsMetadata: buildMetadataLogs({
        isPhoenixHotelSearchEnabled,
        itineraryId: searchParams.itineraryId,
      }),
    },
    skip: !isPhoenixHotelSearchEnabled && !shouldForceReviews,
  })

  return { data, loading, error }
}
