import { Action, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import type { FlightsSortBy } from '../../core/enums/flights-sort-by'
import { AirFiltersService } from '../services/air-filters/air-filters.service'
import { AirSearchQueryService } from '../services/air-search-query/air-search-query.service'
import { AirSearchService } from '../services/air-search/air-search.service'
import { AirSearchStore } from '../stores/air-search/air-search.store'
import { AirFiltersStore } from '../stores/air-search/air-filters.store'
import type {
  FilterTime,
  AirFilterStop,
  AirFilterAirline,
  AirFilterFareType,
  AirFilterAirport,
} from '../../core/value-objects/air-filters'
import { AirSearchQueryStore } from '../stores/air-search-query.store'

@Action()
export class AirSearchFilterActions {
  constructor(
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private airSearchQueryService: AirSearchQueryService,
    @Inject() private airSearchService: AirSearchService,
    @Inject() private airFiltersService: AirFiltersService,
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airFiltersStore: AirFiltersStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  async handleSortBy(sortBy: FlightsSortBy) {
    this.airSearchStore.setSortBy(sortBy)
    await this.airSearchService.getFlights()
  }

  async handleFiltersApply() {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags

    if (!isNewAirExposedFiltersEnabled && this.airFiltersStore.isSelectedFareTypesEmpty) {
      this.airFiltersStore.fareDialog.handleOpen()
      return
    }
    const filters = this.airFiltersStore.newFilters
    this.airSearchQueryService.appendQueryParams({ filters })
    this.airFiltersStore.filterModal.handleClose()
    await this.airSearchService.getFlights({ filters })
  }

  handleOpenFilters() {
    if (!this.airSearchStore.flightResult) {
      return
    }
    const filters = this.airSearchQueryStore.additionalQueries.filters
    if (!filters) {
      return
    }
    this.airFiltersStore.setFilters(filters)
    this.airFiltersStore.filterModal.handleOpen()
  }

  handleCloseFilters() {
    this.airFiltersStore.filterModal.handleClose()
  }

  handleCloseDialog() {
    this.airFiltersStore.fareDialog.handleClose()
  }

  handleDropStops() {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags
    this.airFiltersService.handleDropStops()
    if (this.airFiltersStore.stopsModal.isOpen && !isNewAirExposedFiltersEnabled) {
      this.handleFiltersApply()
      this.airFiltersStore.stopsModal.handleClose()
    }
  }
  handleDropAirlines() {
    this.airFiltersService.handleDropAirlines()
  }
  handleDropFareTypes() {
    this.airFiltersService.handleDropFareTypes()
  }
  handleDropOriginAirport() {
    this.airFiltersService.handleDropOriginAirport()
  }
  handleDropDestinationAirport() {
    this.airFiltersService.handleDropDestinationAirport()
  }

  handleOriginTimeChange(time: FilterTime) {
    this.airFiltersService.handleOriginTimeChange(time)
  }

  handleDestinationTimeChange(time: FilterTime) {
    this.airFiltersService.handleDestinationTimeChange(time)
  }

  handleSelectStops(value: AirFilterStop[]) {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags

    this.airFiltersService.handleSelectStops(value)
    if (this.airFiltersStore.stopsModal.isOpen && !isNewAirExposedFiltersEnabled) {
      this.handleFiltersApply()
      this.airFiltersStore.stopsModal.handleClose()
    }
  }
  handleSelectAirlines(value: AirFilterAirline) {
    this.airFiltersService.handleSelectAirlines(value)
  }
  handleSelectFareTypes(value: AirFilterFareType) {
    this.airFiltersService.handleSelectFareTypes(value)
  }
  handleSelectOriginAirport(value: AirFilterAirport) {
    this.airFiltersService.handleSelectOriginAirport(value)
  }
  handleSelectDestinationAirports(value: AirFilterAirport) {
    this.airFiltersService.handleSelectDestinationAirports(value)
  }

  handleSetMatchedWithPolicy(value: boolean) {
    this.airFiltersService.handleSetMatchedWithPolicy(value)
  }

  handleResetFilters() {
    this.airFiltersService.handleResetFilters()
  }
}
