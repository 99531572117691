import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import { RulesModalContent } from './rules-modal-content'

type Props = {
  transactionId: string
  carSegmentId: string
  isOpen: boolean
  onClose: () => void
}

export function RulesModal({ isOpen, onClose, ...props }: Props) {
  return (
    <InfoModal isVisible={isOpen} onClose={onClose}>
      <RulesModalContent onClose={onClose} {...props} />
    </InfoModal>
  )
}
