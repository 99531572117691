import { Screen } from '@etta/ui/screen'
import type { LayoutProps } from '../../types'

export function SupportTravelPageMobile({ headerSlot, contentSlot }: LayoutProps) {
  return (
    <Screen>
      <Screen.Container>
        <Screen.Header isStatic>{headerSlot}</Screen.Header>
        {contentSlot}
      </Screen.Container>
    </Screen>
  )
}
