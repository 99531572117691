import type { Moment } from 'moment'
import type { FocusEvent } from 'react'
import { DayPickerSingleDateController as Picker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { Button } from '@etta/ui/button'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import type { DateOfBirthPickerProps } from './types'
import { useDateOfBirthPicker } from './use-date-of-birth-picker'
import { YearPicker } from './year-picker'
import { initializeMonthElementRenderer } from './initialize-month-element-renderer'
import {
  Container,
  DayPickerWrapper,
  Footer,
  LeftNavIcon,
  RightNavIcon,
  DayContent,
} from './date-of-birth-picker-styled'

export function DateOfBirthPicker({
  onChange = () => {},
  value,
  isOpen = true,
  isAllowClearValue = false,
  onClose,
  areFutureYears,
  yearsBefore,
  yearsAfter,
  withClickOutsideHandle = false,
}: DateOfBirthPickerProps) {
  const { t } = useTranslation()
  const {
    buttonDisabled,
    onDoneClick,
    onClearClick,
    onYearClick,
    onDaySelect,
    choosenYear,
    currentState,
    date,
    yearsArray,
    futureYearsArray,
    customYearsArray,
    changeState,
    setMonthText,
    yearHeaderText,
  } = useDateOfBirthPicker({
    onChange,
    onClose,
    value,
    yearsBefore,
    yearsAfter,
  })

  const { handleKeyDown: handleDoneKeyDown } = useA11yKeydown(onDoneClick)

  const RenderMonthElement = initializeMonthElementRenderer({
    setMonthText,
    changeState,
    choosenYear,
  })

  const { ref } = useClickOutside(() => {
    if (onClose && withClickOutsideHandle) {
      onClose()
    }
  })

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null

    if (relatedTarget && ref.current && !ref.current.contains(relatedTarget)) {
      onClose?.()
    }
  }

  useShouldOpenInViewport({ elementHeight: 383, isVisible: isOpen, ref })

  let years = areFutureYears ? futureYearsArray : yearsArray
  if (yearsBefore || yearsAfter) {
    years = customYearsArray
  }

  if (!isOpen) {
    return null
  }

  return (
    <Container role="dialog" onBlur={handleBlur} ref={ref}>
      <YearPicker
        isHide={currentState !== 'year-picker'}
        headerText={yearHeaderText}
        onHeaderClick={changeState}
        onYearsItemClick={onYearClick}
        years={years}
        currentYear={choosenYear}
      />
      {currentState === 'day-picker' && (
        <DayPickerWrapper>
          <Picker
            initialVisibleMonth={null}
            date={date}
            onDateChange={onDaySelect}
            focused={true}
            onFocusChange={() => {}}
            noBorder
            orientation="horizontal"
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            renderMonthElement={RenderMonthElement}
            renderDayContents={(day: Moment) => <DayContent>{day.format('DD')}</DayContent>}
            navPrev={<LeftNavIcon name="chevronLeftPWA" size="small" color="primary1" />}
            navNext={<RightNavIcon name="chevronRightPWA" size="small" color="primary1" />}
            transitionDuration={0}
          />
        </DayPickerWrapper>
      )}
      <Footer justifyContent={isAllowClearValue ? 'space-between' : 'flex-end'}>
        {isAllowClearValue && (
          <Button onClick={onClearClick} variant="link" disabled={value === undefined}>
            {t('DatePicker.Clear')}
          </Button>
        )}
        <Button
          onKeyPress={handleDoneKeyDown}
          onClick={onDoneClick}
          minWidth={14.8}
          disabled={buttonDisabled}>
          {t('DatePicker.Done')}
        </Button>
      </Footer>
    </Container>
  )
}
