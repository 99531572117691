import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  padding: 12px 24px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`

export const Details = styled.div<{ alightRight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: ${(props) => (props.alightRight ? 'flex-end' : 'flex-start')};
`
