import { useMemo } from 'react'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { BillingParameter, CheckoutInfoSiteCard, CreditCard } from '@fiji/graphql/types'
import { CreditCardTypes, TravelVertical } from '@fiji/graphql/types'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { useSettingsContext } from '@etta/modules/settings/interface/use-settings-context'
import type { CheckoutInfoCreditCardValueObject, CheckoutBillingParams } from './type'

function mapCheckoutCreditCard(
  checkoutInfoCreditCard: CheckoutInfoCreditCardValueObject,
  isBillingAddressRequired: boolean,
  isCreditCardEditable: boolean,
): CreditCard {
  return {
    billingAddress: checkoutInfoCreditCard.address,
    billingId: checkoutInfoCreditCard.id,
    brandType: checkoutInfoCreditCard.data.type,
    cardNumber: checkoutInfoCreditCard.data.number,
    expirationDate: checkoutInfoCreditCard.data.expirationDate,
    id: checkoutInfoCreditCard.data.id,
    isCardOutOfPolicy: checkoutInfoCreditCard.isCardOutOfPolicy,
    isSiteCard: false,
    label: checkoutInfoCreditCard.data.label,
    metadata: {
      isBillingAddressRequired: isBillingAddressRequired,
      isRestricted: false,
      type: checkoutInfoCreditCard.isCorporateCard
        ? CreditCardTypes.PersonalCorporateCreditCard
        : CreditCardTypes.PersonalCreditCard,
      isEditable: isCreditCardEditable,
    },
    name: checkoutInfoCreditCard.data.label,
    securityCode: undefined,
  }
}

function mapCheckoutSiteCard(
  checkoutInfoSiteCard: CheckoutInfoSiteCard,
  isBillingAddressRequired: boolean,
): CreditCard {
  return {
    billingAddress: checkoutInfoSiteCard.address,
    billingId: checkoutInfoSiteCard.data.id,
    brandType: undefined,
    cardNumber: undefined,
    expirationDate: undefined,
    id: checkoutInfoSiteCard.data.id,
    isCardOutOfPolicy: checkoutInfoSiteCard.isCardOutOfPolicy,
    isSiteCard: true,
    label: checkoutInfoSiteCard.data.label,
    metadata: {
      isBillingAddressRequired: isBillingAddressRequired,
      isRestricted: checkoutInfoSiteCard.isRestricted,
      type: CreditCardTypes.SiteCard,
      isEditable: false,
    },
    name: checkoutInfoSiteCard.data.label,
    securityCode: undefined,
  }
}

function toCheckoutBilling(params: CheckoutBillingParams) {
  const {
    billing,
    isCorporateCardEditable,
    isPersonalCardEditable,
    type,
    isSingleUseCreditCardAllowed,
  } = params

  if (!billing) {
    return undefined
  }
  const creditCards: CreditCard[] = billing.creditCards.map(
    (card): CreditCard => {
      const isCreditCardEditable = card.isCorporateCard
        ? isCorporateCardEditable
        : isPersonalCardEditable
      return mapCheckoutCreditCard(
        card,
        !!billing?.metadata.isBillingAddressRequired,
        Boolean(isCreditCardEditable),
      )
    },
  )

  const siteCards: CreditCard[] = billing.siteCards.map(
    (siteCard): CreditCard =>
      mapCheckoutSiteCard(siteCard, !!billing?.metadata.isBillingAddressRequired),
  )

  return {
    metadata: {
      ...billing.metadata,
      isSingleUseCreditCardAllowed: isSingleUseCreditCardAllowed ?? false,
    },
    travelVertical: toTravelVertical(type),
    defaultCardId: billing.defaultCard?.data.id ?? billing.defaultSiteCard?.data.id,
    creditCards: type === 'rail' ? [...creditCards, ...siteCards] : [...siteCards, ...creditCards],
  }
}

function toTravelVertical(type: CheckoutBillingParams['type']) {
  switch (type) {
    case 'air':
      return TravelVertical.Air
    case 'hotel':
      return TravelVertical.Hotel
    case 'rail':
      return TravelVertical.Rail
    case 'car':
      return TravelVertical.Carrental
  }
}

export function useCheckoutCards() {
  const {
    checkoutInfoStore: { billing },
  } = useCheckoutInfoContext()
  const {
    fieldSettingsStore: { fieldsSettings },
  } = useSettingsContext()
  const { personalCards, corporateCards } = fieldsSettings[FieldSettingsCategory.PaymentCards]

  const checkoutFlightBilling: BillingParameter | undefined = useMemo(() => {
    return toCheckoutBilling({
      isPersonalCardEditable: personalCards.isEditable,
      isCorporateCardEditable: corporateCards.isEditable,
      billing: billing.air,
      isSingleUseCreditCardAllowed: billing.isSingleUseCreditCardAllowed,
      type: 'air',
    })
  }, [
    billing.air,
    billing.isSingleUseCreditCardAllowed,
    personalCards.isEditable,
    corporateCards.isEditable,
  ])

  const checkoutHotelBilling: BillingParameter | undefined = useMemo(() => {
    return toCheckoutBilling({
      isPersonalCardEditable: personalCards.isEditable,
      isCorporateCardEditable: corporateCards.isEditable,
      billing: billing.hotel,
      isSingleUseCreditCardAllowed: billing.isSingleUseCreditCardAllowed,
      type: 'hotel',
    })
  }, [
    billing.hotel,
    billing.isSingleUseCreditCardAllowed,
    personalCards.isEditable,
    corporateCards.isEditable,
  ])

  const checkoutCarRentalBilling: BillingParameter | undefined = useMemo(() => {
    return toCheckoutBilling({
      isPersonalCardEditable: personalCards.isEditable,
      isCorporateCardEditable: corporateCards.isEditable,
      billing: billing.carRental,
      isSingleUseCreditCardAllowed: billing.isSingleUseCreditCardAllowed,
      type: 'car',
    })
  }, [
    billing.carRental,
    billing.isSingleUseCreditCardAllowed,
    personalCards.isEditable,
    corporateCards.isEditable,
  ])

  const checkoutRailBilling: BillingParameter | undefined = useMemo(() => {
    return toCheckoutBilling({
      isPersonalCardEditable: personalCards.isEditable,
      isCorporateCardEditable: corporateCards.isEditable,
      billing: billing.rail,
      isSingleUseCreditCardAllowed: billing.isSingleUseCreditCardAllowed,
      type: 'rail',
    })
  }, [
    billing.rail,
    billing.isSingleUseCreditCardAllowed,
    personalCards.isEditable,
    corporateCards.isEditable,
  ])

  return {
    checkoutFlightBilling,
    checkoutHotelBilling,
    checkoutCarRentalBilling,
    checkoutRailBilling,
  }
}
