import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import type { CarRentalMultiVendorLocation } from '@fiji/graphql/types'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen'
import { formatLocation } from '@etta/modules/car-rental-search/interface/stores/format-location'
import { getDistance } from './get-distance'
import { Place } from './mobile-place'
import { LocationsLoadingMobileSkeleton } from './locations-loading-mobile-skeleton'
import { Container } from './car-rental-mobile-content-styled'

type Props = {
  isLoading: boolean
  locations?: CarRentalMultiVendorLocation[]
  shouldUseKm?: boolean
  isRestrictedCountryError: boolean
  restrictedErrorMessage: string
  onLocationClick: (location: CarRentalMultiVendorLocation) => void
}

export function CarRentalMobileContent({
  isLoading,
  locations,
  onLocationClick,
  shouldUseKm,
  isRestrictedCountryError,
  restrictedErrorMessage,
}: Props) {
  const { t } = useTranslation()

  if (isRestrictedCountryError) {
    return (
      <NoResultSearchScreen
        type="car-rental-alert"
        title={t('SearchSegments.NoResultErrorScreen.RestrictedCountryErrorTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.ErrorCarRentalRestrictedCountrySubTitle', {
          customMessage: restrictedErrorMessage?.length ? `(${restrictedErrorMessage})` : '',
        })}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
            onClick: () => {},
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (!isLoading && locations?.length === 0) {
    return (
      <NoResultSearchScreen
        type="car-rental-office"
        title={t('SearchSegments.NoResultErrorScreen.NoResultTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.NoPickUpPointsCarRentalSubTitle')}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.EditSearch'),
            onClick: () => {},
          },
        ]}
      />
    )
  }

  const distanceTitle = shouldUseKm
    ? t('TripPlanner.CarRentalOfficeSearch.km')
    : t('TripPlanner.CarRentalOfficeSearch.mi')

  return (
    <Container isLoading={isLoading}>
      <Swap is={isLoading} isSlot={<LocationsLoadingMobileSkeleton />}>
        {locations?.map((place, index) => (
          <Place
            key={`${index}-${place.address.countryCode}`}
            formattedLocation={formatLocation(place)}
            vendors={place.vendors}
            isAirport={place.airportLocation}
            isOpen={place.isOpen}
            distance={getDistance(place, { shouldUseKm, title: distanceTitle })}
            onClick={() => {
              onLocationClick(place)
            }}
          />
        ))}
      </Swap>
    </Container>
  )
}
