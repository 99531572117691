import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { GetDeemHeaderQuery } from '@fiji/graphql/types'
import type { DeemHeaderState } from './types'

const initialState: DeemHeaderState = {
  isDeemHeaderLoading: true,
  deemHeaderData: undefined,
  areStylesheetsLoaded: false,
  deemHeaderError: false,
}

const slice = createSlice({
  name: 'deem-header',
  initialState,
  reducers: {
    updateDeemHeaderData(state, action: PayloadAction<GetDeemHeaderQuery>) {
      state.deemHeaderData = action.payload
    },

    updateDeemHeaderLoading(state, action: PayloadAction<boolean>) {
      state.isDeemHeaderLoading = action.payload
    },

    updateDeemHeaderStyleSheet(state, action: PayloadAction<boolean>) {
      state.areStylesheetsLoaded = action.payload
    },

    updateDeemHeaderError(state, action: PayloadAction<boolean>) {
      state.deemHeaderError = action.payload
    },
  },
})

export const deemHeaderReducer = slice.reducer
export const {
  updateDeemHeaderData,
  updateDeemHeaderError,
  updateDeemHeaderLoading,
  updateDeemHeaderStyleSheet,
} = slice.actions
