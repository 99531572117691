import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { SegmentType } from '@etta/core/enums'
import { Header, PartialFailedMessageWrapper, SegmentMessageCard } from './failed-segment-styled'

export const FailedSegments = observer(() => {
  const i18nBase = 'PostBooking.TripDetails'
  const { t } = useTranslation()

  const { postBookingTripStore } = usePostBookingContext()

  return (
    <PartialFailedMessageWrapper>
      <Block paddingVertical={16} paddingHorizontal={24}>
        <Text variant="footnoteMedium" color="mainText1">
          {t(`${i18nBase}.PartialConfirmationFailingInfo`)}
        </Text>
      </Block>
      {postBookingTripStore.failedSegments.map((segmentInfo) => {
        const segmentTypeName =
          segmentInfo.type === SegmentType.Flight
            ? 'Flight'
            : segmentInfo.type === SegmentType.Hotel
            ? 'Hotel'
            : segmentInfo.type === SegmentType.CarRental
            ? 'Car rental'
            : 'Unhandled'

        return (
          <SegmentMessageCard key={segmentInfo.id}>
            <Header>
              <Icon name="errorPWA" color="error" />
              <Text variant="headline" color="error">
                {t(`${i18nBase}.ErrorTitleForFailedSegment`, { segmentTypeName: segmentTypeName })}
              </Text>
            </Header>
            {segmentInfo.subSegments.map((subSegment) => {
              return (
                <Fragment key={subSegment.id}>
                  <Block marginTop={12}>
                    <Text variant="subHeadStrong" color="mainText">
                      {subSegment.title}
                    </Text>
                  </Block>
                  <Block marginTop={2}>
                    <Text variant="footnoteMedium" color="bodyText1">
                      {subSegment.infoItems.map((infoItem) => {
                        return <Block key={infoItem.id}>{infoItem.info}</Block>
                      })}
                    </Text>
                  </Block>
                </Fragment>
              )
            })}
          </SegmentMessageCard>
        )
      })}
    </PartialFailedMessageWrapper>
  )
})
