import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetTripQueryVariables,
  GetTripQuery,
  CancelRideHailMutation,
  CancelRideHailMutationVariables,
  CancelTripMutation,
  CancelTripMutationVariables,
  CancelTripSegmentMutation,
  CancelTripSegmentMutationVariables,
  ChangeFlightSeatsMutationVariables,
  ChangeFlightSeatsMutation,
} from '@fiji/graphql/hooks'
import {
  GetTripDocument,
  CancelTripDocument,
  CancelTripSegmentDocument,
  CancelRideHailDocument,
  ChangeFlightSeatsDocument,
} from '@fiji/graphql/hooks'

@DataProvider()
export class GazooPostBookingDataProvider extends GraphqlDataProvider {
  getTripAbortController: AbortController | null = null

  cancelGetTripRequest() {
    this.getTripAbortController?.abort()
  }

  getTrip(input: GetTripQueryVariables['input'], forceUpdate?: boolean) {
    const controller = new AbortController()
    this.getTripAbortController?.abort()
    this.getTripAbortController = controller
    return this.client.query<GetTripQuery, GetTripQueryVariables>({
      query: GetTripDocument,
      variables: {
        input,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  cancelTrip({ tripId }: CancelTripMutationVariables) {
    return this.client.mutate<CancelTripMutation, CancelTripMutationVariables>({
      mutation: CancelTripDocument,
      variables: {
        tripId,
      },
    })
  }

  cancelRideHail(input: CancelRideHailMutationVariables['input']) {
    return this.client.mutate<CancelRideHailMutation, CancelRideHailMutationVariables>({
      mutation: CancelRideHailDocument,
      variables: {
        input,
      },
    })
  }

  cancelTripSegment(input: CancelTripSegmentMutationVariables['input']) {
    return this.client.mutate<CancelTripSegmentMutation, CancelTripSegmentMutationVariables>({
      mutation: CancelTripSegmentDocument,
      variables: {
        input,
      },
    })
  }

  changeFlightSeats(input: ChangeFlightSeatsMutationVariables['input']) {
    return this.client.mutate<ChangeFlightSeatsMutation, ChangeFlightSeatsMutationVariables>({
      mutation: ChangeFlightSeatsDocument,
      variables: { input },
    })
  }
}
