import styled from 'styled-components'
import { footnoteMedium, headline, subHeadStrong } from '@fiji/style'
import { Button } from '@etta/ui/button'

export const IconWrapper = styled.span`
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.seatMap.charcoal11};
  border-radius: 4px;
  margin-right: 9px;
`

export const StartIconSmall = styled.div`
  width: 12px;
  height: 16px;
`

export const StartIconMedium = styled.div`
  min-width: 16px;
  height: 20px;
`

export const Title = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.mainText};
  margin-right: 4px;
  ${subHeadStrong};
`

export const ContinueTitle = styled(Title)`
  && {
    ${headline};
  }
`

export const SubTitle = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  margin-bottom: 10px;
  margin-top: 2px;
  line-height: 1.3;

  ${footnoteMedium};
`

export const EndIcon = styled.span`
  margin-left: 4px;
  vertical-align: middle;
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 18px;
  color: ${(props) => props.theme.colors.warning};
`

export const ContainerContinue = styled.div`
  display: flex;
`

export const ContainerDefault = styled.div`
  display: flex;
`

export const InnerContainerDefault = styled.div`
  margin-top: 6px;
  & > :last-child {
    display: block;
    margin-top: 10px;
  }
`

export const ContinueButton = styled(Button)`
  && {
    border-width: 1px;
    min-width: 126px;
  }
`
