import styled, { css } from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'
import { Text } from '@etta/ui/text'
import { getKeyboardNavigationStyle } from '@fiji/style'
import type { ImageContentSkin } from './types'

const IMG_SPACE = `4px`

const imageStyle = `
  border-radius: 10px;
  cursor: pointer;
  width: 50%;
  padding: ${IMG_SPACE};
`

export const ShowAllPhotos = styled(Text)`
  position: absolute;
  z-index: 1;
  text-align: center;
  padding: 15px;
  left: 0;
  top: 24px;
`

export const Container = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
`

export const SecondaryImages = styled.div<{ layoutToFit?: number }>`
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  > * {
    ${(p) => {
      switch (p.layoutToFit) {
        case 1:
          return `
        width: 100%;
      `
        case 2:
          return
        case 3:
          return `
          height: 50%;
          :nth-of-type(1) {
            flex: 0 0 100%;
          }
        `
        case 4:
          return `
          height: 50%;
        `
      }
    }}
  }
`

export const Image = styled.img<{ isLoaded?: boolean }>`
  ${imageStyle};
  object-fit: cover;
  :only-child {
    width: 100%;
  }
  ${getKeyboardNavigationStyle({ offset: -1 })}
  ${(p) => !p.isLoaded && 'display: none'}
`

export const GalleryOpener = styled.div`
  ${imageStyle};
  padding: 0;
  border: 0;
  position: relative;
  width: calc(50% - 2 * ${IMG_SPACE});
  height: calc(50% - 2 * ${IMG_SPACE});
  margin: ${IMG_SPACE};
  > img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

export const DarkLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  overflow: hidden;
  border-radius: 10px;
  line-height: 50%;
`

export const NoPhotosPlaceholder = styled.div`
  max-width: 76px;
`

export const NoPhotosContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 250px;
  background: ${(p) => p.theme.colors.background4};
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: ${(p) => p.theme.colors.background1};
  }
`

export const LoadingContent = styled.div<{ skin?: ImageContentSkin }>`
  ${imageStyle};
  object-fit: cover;
  :only-of-type {
    width: 100%;
  }
  ${(p) =>
    p.skin === 'gallery-link' &&
    css`
      height: 100%;
      width: 100%;
      padding: 0;
    `}
`

export const LoadingImage = styled.div`
  ${glowingFragment};
  border-radius: 10px;
  width: 100%;
  height: 100%;
`

export const ErrorCentered = styled.div`
  width: 40%;
  height: 40%;
`

export const ErrorContent = styled.div`
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.secondary};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
