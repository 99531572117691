import styled, { css } from 'styled-components'
import type { ColorPalette } from '@fiji/types'
import { LocationTypes } from '../location-types'

const getColour = (type: LocationTypes, colors: ColorPalette) => {
  switch (type) {
    case LocationTypes.PickUp:
      return colors.success
    case LocationTypes.Stop:
      return colors.bodyText2
    case LocationTypes.DropOff:
      return colors.error
  }
}

const getDotSize = (type: LocationTypes) => {
  switch (type) {
    case LocationTypes.Stop:
      return '7px'
    default:
      return '10px'
  }
}

const getLineHeight = (type: LocationTypes) => {
  if (type === LocationTypes.DropOff) {
    return 200
  }
  return 100
}

export const Container = styled.div<{ type: LocationTypes }>`
  position: relative;
  padding-left: 15px;
  min-height: 60px;

  ${(props) =>
    props.type !== LocationTypes.PickUp &&
    css`
      &:before {
        content: '';
        height: calc(${getLineHeight(props.type)}% + 5px);
        width: 2px;
        position: absolute;
        left: 5px;
        top: calc(${-getLineHeight(props.type)}% + 5px);
        background-color: ${props.theme.colors.borderDark};
      }
    `}

  &:after {
    content: '';
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    top: ${(props) => (props.type === LocationTypes.Stop ? 7 : 5)}px;
    left: ${(props) => (props.type === LocationTypes.Stop ? 2 : 1)}px;
    width: ${(props) => getDotSize(props.type)};
    height: ${(props) => getDotSize(props.type)};
    background-color: ${(props) => getColour(props.type, props.theme.colors)};
  }
`
