import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { formatRate } from '@fiji/utils/money/format-rate'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { Text, CostContainer } from '@etta/screens/payment-summary/payment-summary-styled'
import { CarRentalClassType } from '@etta/modules/car-rental-search/core/enums'

type Props = {
  segment: CarRentalSegmentEntity
}

const i18nBase = 'PaymentSummary.'

export function CarRentalSegment({ segment }: Props) {
  const { t } = useTranslation()

  const carItem = segment

  if (!carItem) {
    return null
  }

  const rentalPeriod = `${tripReviewFormatDate(
    TripReviewDateType.DayMonthDateAndTime,
    carItem.pickUpTime,
  )} - ${tripReviewFormatDate(TripReviewDateType.DayMonthDateAndTime, carItem.dropOffTime)}`

  const rentalPrice = formatRate(carItem.baseRate, {
    morpheme: 'none',
  }).mainCost

  const carClass = t(i18nBase + 'CarClass', {
    carClass: (() => {
      switch (carItem.carDetails.class) {
        case CarRentalClassType.Mini:
          return t('TripReview.CarRentalSegment.Classes.Mini')
        case CarRentalClassType.MiniElite:
          return t('TripReview.CarRentalSegment.Classes.MiniElite')
        case CarRentalClassType.Economy:
          return t('TripReview.CarRentalSegment.Classes.Economy')
        case CarRentalClassType.EconomyElite:
          return t('TripReview.CarRentalSegment.Classes.EconomyElite')
        case CarRentalClassType.Compact:
          return t('TripReview.CarRentalSegment.Classes.Compact')
        case CarRentalClassType.CompactElite:
          return t('TripReview.CarRentalSegment.Classes.CompactElite')
        case CarRentalClassType.Intermediate:
          return t('TripReview.CarRentalSegment.Classes.Intermediate')
        case CarRentalClassType.IntermediateElite:
          return t('TripReview.CarRentalSegment.Classes.IntermediateElite')
        case CarRentalClassType.Standard:
          return t('TripReview.CarRentalSegment.Classes.Standard')
        case CarRentalClassType.StandardElite:
          return t('TripReview.CarRentalSegment.Classes.StandardElite')
        case CarRentalClassType.Fullsize:
          return t('TripReview.CarRentalSegment.Classes.FullSize')
        case CarRentalClassType.FullsizeElite:
          return t('TripReview.CarRentalSegment.Classes.FullSizeElite')
        case CarRentalClassType.Premium:
          return t('TripReview.CarRentalSegment.Classes.Premium')
        case CarRentalClassType.PremiumElite:
          return t('TripReview.CarRentalSegment.Classes.PremiumElite')
        case CarRentalClassType.Luxury:
          return t('TripReview.CarRentalSegment.Classes.Luxury')
        case CarRentalClassType.LuxuryElite:
          return t('TripReview.CarRentalSegment.Classes.LuxuryElite')
        case CarRentalClassType.Special:
          return t('TripReview.CarRentalSegment.Classes.Special')
        case CarRentalClassType.Oversize:
          return t('TripReview.CarRentalSegment.Classes.Oversize')

        default:
          return carItem.carDetails.class?.replaceAll('_', ' ').toLowerCase()
      }
    })(),
  })

  return (
    <Fragment>
      <CostContainer
        aria-label={t(i18nBase + 'SegmentAccessibilityLabel', {
          name: carItem.vendorDetails.name,
          amount: rentalPrice,
        })}>
        <Text>
          {carItem.vendorDetails.name} • <span>{carClass}</span>
        </Text>
        <Text>{rentalPrice}</Text>
      </CostContainer>
      <Text>{rentalPeriod}</Text>
    </Fragment>
  )
}
