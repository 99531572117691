import styled from 'styled-components'
import { calloutMedium, screenSize } from '@fiji/style'
import { ReactDatesOverridesCommon } from './react-dates-overrides'

export const CalendarContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  @media (${screenSize.minTablet}) {
    height: auto;
  }

  ${ReactDatesOverridesCommon}

  @media (${screenSize.minMobile}) {
    .CalendarDay {
      ${calloutMedium}
    }
  }
`

export const Month = styled.div`
  font-weight: 600;
`
