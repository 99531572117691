import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Currency, User } from './types'
import { getInitialState } from './initial-state'

const slice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    updateUser(state, action: PayloadAction<User>) {
      const newUser = {
        ...state,
        ...action.payload,
        locale: action.payload.locale,
        currency: action.payload.profile?.currency,
      }
      return newUser
    },

    updateSelectedCurrency(state, action: PayloadAction<Currency>) {
      return { ...state, selectedCurrency: action.payload }
    },
    updateCurrencyList(state, action: PayloadAction<Currency[]>) {
      return { ...state, currencyList: action.payload }
    },
    updateCurrencySelection(state, action: PayloadAction<boolean>) {
      return { ...state, shouldUpdateCurrency: action.payload }
    },
    updateDelegatedId(state, action: PayloadAction<number>) {
      return { ...state, delegatedId: action.payload }
    },
  },
})

export const userReducer = slice.reducer
export const {
  updateUser,
  updateSelectedCurrency,
  updateCurrencyList,
  updateCurrencySelection,
  updateDelegatedId,
} = slice.actions
