import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { LinkWrapper } from './room-rate-link-styled'

const i18Base = 'HotelInfo.Rooms'

type Props = {
  onClick: () => void
}

export function RoomRateLink({ onClick }: Props) {
  const { t } = useTranslation()
  return (
    <LinkWrapper>
      <Link
        aria-label={t(i18Base + '.SeeDetails', {
          label: t(`${i18Base}.RoomRateDetailsLink`),
        })}
        size="small"
        onClick={onClick}
        data-tracking-id="hotel-room-room-rate-link">
        {t(i18Base + '.RoomRateDetailsLink')}
      </Link>
    </LinkWrapper>
  )
}
