import styled, { css } from 'styled-components'
import { LottieAnimation } from '@etta/ui/lottie-animation'
import { IconButton } from '@etta/ui/icon-button'

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
`

export const StyledLottieAnimation = styled(LottieAnimation)`
  width: 100px;
  height: 100px;
`

const ContainerStyles = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const AnimationContainer = styled.div<{ withBackground: boolean }>`
  ${ContainerStyles}
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.withBackground &&
    css`
      background: rgba(255, 255, 255, 0.9);
      box-shadow: -12px 12px 12px rgba(0, 0, 0, 0.06);
    `}
`

export const ConfirmationContainer = styled.div`
  ${ContainerStyles}
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ConfirmationTextContainer = styled.div`
  max-width: 450px;
`

export const ConfirmationButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const DelegateModalHeader = styled.div`
  position: relative;
  padding: 0 0 12px 32px;
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 22px;
  top: 3px;
`
