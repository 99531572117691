import i18next from 'i18next'
import { SegmentType } from '@etta/core/enums'
import {
  MAXIMUM_HOTEL_QUANTITY,
  MAXIMUM_CAR_RENTAL_QUANTITY,
} from '@etta/modules/review-trip/core/constants'

type Args = {
  segmentType: SegmentType
}

const i18nBase = 'BookingLimitDialog'

export function getDescription({ segmentType }: Args) {
  switch (segmentType) {
    case SegmentType.Hotel:
      return i18next.t(`${i18nBase}.HotelLimitDescription`, { quantity: MAXIMUM_HOTEL_QUANTITY })

    case SegmentType.CarRental:
      return i18next.t(`${i18nBase}.CarRentalLimitDescription`, {
        quantity: MAXIMUM_CAR_RENTAL_QUANTITY,
      })

    default:
      return ''
  }
}
