import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { TimeRange } from '@fiji/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useTimeUtils } from '@fiji/hooks/use-time-utils'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import type { CalendarTimeModal } from './types'

type HookProps = {
  railTripType: RailTripType
  originDate: Date
  originTime: TimeRange
  destinationDate?: Date
  destinationTime?: TimeRange
  isShortCustomTime?: boolean
}

const i18Base = 'TripPlanner.BaseSearch'

export function useCalendarTimeSection({
  railTripType,
  originDate,
  destinationDate,
  originTime,
  destinationTime,
  isShortCustomTime,
}: HookProps) {
  const { t } = useTranslation()

  const { railSearchFormActions } = useRailSearchFormContext()
  const { onOriginTimeChange, onDestinationTimeChange, onDatesSelect } = railSearchFormActions

  const { getTimeLabel, getTimeValue, convertDateToString } = useTimeUtils()

  const departDateToggle = useTogglePopup()
  const returnDateToggle = useTogglePopup()
  const departTimeToggle = useTogglePopup()
  const returnTimeToggle = useTogglePopup()

  const calendarTimeModals: CalendarTimeModal[] = [
    departDateToggle,
    returnDateToggle,
    departTimeToggle,
    returnTimeToggle,
  ]

  const isAnyModalVisible = calendarTimeModals.find(({ isOpen }) => isOpen)

  const originDateString = convertDateToString(originDate)
  const originDateLabel = t(i18Base + '.Input.DepartOn')
  const destinationDateString = convertDateToString(destinationDate)
  const destinationDateLabel = t(i18Base + '.Input.ReturnOn')
  const originTimeString = getTimeValue(originTime, isShortCustomTime)
  const originTimeLabel = t(i18Base + getTimeLabel(originTime))
  const destinationTimeString = destinationTime
    ? getTimeValue(destinationTime, isShortCustomTime)
    : ''
  const destinationTimeLabel = destinationTime ? t(i18Base + getTimeLabel(destinationTime)) : ''

  const isRoundTrip = railTripType === RailTripType.Round

  const handleModalOpen = useCallback(
    (modal: CalendarTimeModal) => {
      if (isAnyModalVisible) {
        isAnyModalVisible.handleClose()
      }

      modal.handleOpen()
    },
    [isAnyModalVisible],
  )

  return {
    departDateToggle,
    returnDateToggle,
    departTimeToggle,
    returnTimeToggle,
    isRoundTrip,
    originDateString,
    originDateLabel,
    destinationDateString,
    destinationDateLabel,
    originTimeString,
    originTimeLabel,
    destinationTimeString,
    destinationTimeLabel,
    handleModalOpen,
    onOriginTimeChange,
    onDestinationTimeChange,
    onDatesSelect,
  }
}
