/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import type { SupportedActionsV2Fragment } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import type {
  AvailableSegmentsValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
import type { AddButtonSettings } from '../../etta-screens/review-trip-page/trip-review-add-buttons-row/types'
import { useButtonStates } from './use-button-states'
import { useFlightPlaceRTP } from './use-flight-place-rtp'
import { useCarPlaceRTP } from './use-car-place-rtp'
import { useHotelPlaceRTP } from './use-hotel-place-rtp'
import { useTrainPlaceRTP } from './use-train-place-rtp'

type Args = {
  itineraryId: string
  availableSegments?: AvailableSegmentsValueObject | null
  supportedActions?: SupportedActionsV2Fragment | null
  segments: TripSegmentValueObject[]
  bookingId?: string
  isMultiCity?: boolean
}

export function useAddButtonSettings({
  itineraryId,
  availableSegments,
  supportedActions,
  bookingId,
  isMultiCity,
  segments = [],
}: Args) {
  const { enableStates, visibleStates } = useButtonStates({
    availableSegments,
    supportedActions,
    segments,
    isPostPurchase: !!bookingId,
    isMultiCity,
  })

  const { onAddFlight } = useFlightPlaceRTP({
    itineraryId,
    bookingId,
    segments,
  })

  const { onAddHotel } = useHotelPlaceRTP({
    itineraryId,
    segments,
    bookingId,
  })

  const { onAddCar } = useCarPlaceRTP({
    itineraryId,
    segments,
    bookingId,
  })

  const { onAddTrain } = useTrainPlaceRTP({
    itineraryId,
    segments,
    bookingId,
  })

  const addButtonSettings: AddButtonSettings[] = useMemo(
    () => [
      {
        type: SegmentType.Flight,
        isVisible: visibleStates.isFlight,
        isDisabled: !enableStates.isFlight,
        onClick: onAddFlight,
      },
      {
        type: SegmentType.Hotel,
        isVisible: visibleStates.isHotel,
        isDisabled: !enableStates.isHotel,
        onClick: onAddHotel,
      },
      {
        type: SegmentType.CarRental,
        isVisible: visibleStates.isCarRental,
        isDisabled: !enableStates.isCarRental,
        onClick: onAddCar,
      },
      {
        type: SegmentType.Train,
        isVisible: visibleStates.isTrain,
        isDisabled: !enableStates.isTrain,
        onClick: onAddTrain,
      },
    ],
    [visibleStates, enableStates, segments],
  )

  return { addButtonSettings }
}
