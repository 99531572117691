import { CabinClass } from '@etta/components/cabin-class/cabin-class'
import { SeatmapCabinClass } from '@fiji/graphql/types'
import type { Props } from '../../types'
import { useCabinClass } from '../../use-cabin-class'

export function CabinClassSectionDesktop({ cabinClass, onChange, isInputButton }: Props) {
  const { cabinClassModal } = useCabinClass({ cabinClass })

  return (
    <CabinClass
      // WARNING: making the id requered will fail explore-page.a11y-test.ts
      pickedCabinClass={{ id: cabinClass?.id ?? SeatmapCabinClass.Coach }}
      isVisible={cabinClassModal.isOpen}
      onChange={onChange}
      onClose={cabinClassModal.handleClose}
      isInputButton={isInputButton}
    />
  )
}
