import { Action, Inject } from '@etta/di'
import type { RailFilterValueObject } from '../../core/value-objects/rail-search-result.value-object'
import type { StationFilterStationValueObject } from '../../core/value-objects/station-filter-station.value-object'
import type { StopsFilterEntity } from '../../core/entities/stops-filter.entity'
import { FiltersStore } from '../stores/filters.store'
import { RailService } from '../services/rail.service'
import { RailStore } from '../stores/rail.store'

@Action()
export class FiltersActions {
  constructor(
    @Inject()
    private railFiltersStore: FiltersStore,
    @Inject()
    private railService: RailService,
    @Inject()
    private railStore: RailStore,
  ) {}

  onChangeTimeFilters(
    newFilters: Partial<Pick<RailFilterValueObject, 'destinationTimeRange' | 'originTimeRange'>>,
  ) {
    this.railFiltersStore.setChangedFilters({
      ...this.railFiltersStore.changedFilters,
      ...newFilters,
    })
  }

  onChangeOriginStations(selectedStation: StationFilterStationValueObject) {
    const prevStations = this.railFiltersStore.changedFilters.origin || []
    const isSelected = prevStations.find(({ code }) => code === selectedStation.code)
    if (isSelected) {
      this.railFiltersStore.setChangedFilters({
        origin: prevStations.filter(({ code }) => code !== selectedStation.code),
      })
      return
    }
    this.railFiltersStore.setChangedFilters({ origin: [selectedStation, ...prevStations] })
  }

  onChangeDestinationStations(selectedStation: StationFilterStationValueObject) {
    const prevStations = this.railFiltersStore.changedFilters.destination || []
    const isSelected = prevStations.find(({ code }) => code === selectedStation.code)
    if (isSelected) {
      this.railFiltersStore.setChangedFilters({
        destination: prevStations.filter(({ code }) => code !== selectedStation.code),
      })
      return
    }
    this.railFiltersStore.setChangedFilters({
      destination: [selectedStation, ...prevStations],
    })
  }

  onChangeStops(stopFilters: StopsFilterEntity[]) {
    this.railFiltersStore.setChangedFilters({
      stops: stopFilters,
    })
  }

  onPolicyToggle() {
    this.railFiltersStore.setChangedFilters({
      excludeOutOfPolicy: !this.railFiltersStore.changedFilters.excludeOutOfPolicy,
    })
  }

  async onApplyChangedFilters() {
    this.railFiltersStore.setAppliedFilters(this.railFiltersStore.changedFilters)
    await this.railService.getRailSearchResults()
    this.railStore.setFiltersModalClose()
  }

  onClearAll() {
    this.railFiltersStore.setChangedFilters(this.railFiltersStore.getInitialFilters())
  }
}
