import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { TripCart } from '@etta/components/trip-cart/trip-cart'
import { AlertModal, AlertNotificationButton } from '@etta/modules/alert'
import { useAlertContext } from '@etta/modules/alert/interface'
import { DelegateSwitch } from '@etta/modules/delegate'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration/interface/use-display-configuration-context'
import { MeetingSwitchDesktop } from '@etta/modules/meeting/ui/meeting-switch/layout/desktop/meeting-switch-desktop'
import { useCustomLogoURL } from '@fiji/utils/use-get-custom-logo-url'
import { useMeetingModeContext } from '@etta/modules/meeting/use-meeting-mode.context'
import { useNavigationContext } from '../../interface/use-navigation-context'
import {
  ProfileContainerWrapper,
  ProfileContainer,
  ProfileButton,
  SwapText,
  SwapContainer,
  FeedbackContainer,
  FeedbackText,
  CustomProfileLogo,
} from './desktop-navigation-styled'
import { DesktopNavigationModal } from './desktop-navigation-modal/desktop-navigation-modal'
import { DesktopNavigationCompanyResourses } from './desktop-navigation-company-resorses/desktop-navigation-company-resources'

type Props = {
  isInDelegateMode?: boolean
  isDelegateSwitchEnabled?: boolean
  isTripCartDisplayed?: boolean
  isTripCartEnabled?: boolean
  isSelfServiceSite: boolean
  isModalOpen: boolean
  currentUser: {
    fullName: string
    email?: string | null
  }
  currentUserEmail?: string | null
  unusedTicketsCount?: number
  handleSiteSwitch: () => void
  handleNavigateProfile: () => void
  handleLogout: () => void
  handleOpenUnusedModal: () => void
  handleOpenDelegateModal: () => void
}

const i18Base = 'TripPlanner.MainNavigation'

export const DesktopNavigationProfileContent = observer(function DesktopNavigationProfileContent({
  currentUser,
  unusedTicketsCount,
  handleSiteSwitch,
  handleLogout,
  handleOpenUnusedModal,
  handleOpenDelegateModal,
  handleNavigateProfile,
  isInDelegateMode,
  isTripCartDisplayed,
  isModalOpen,
  isDelegateSwitchEnabled,
  isTripCartEnabled,
  isSelfServiceSite,
}: Props) {
  const { t } = useTranslation()
  const { navigationStore } = useNavigationContext()
  const { navigationModalToggle } = navigationStore
  const [swapLinkPromtWidth, setSwapLinkPromtWidth] = useState(0)
  const swapLinkPromtRef = useRef<HTMLSpanElement | null>(null)
  const { featureFlags } = useFeatureFlags()
  const { isFeedbackEnabled } = featureFlags

  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const hideUnusedTickets = displayConfigurationStore.displayConfiguration.hideUnusedTickets
  const { alertStore } = useAlertContext()
  const { alertsCount } = alertStore

  const customLogoURL = useCustomLogoURL()
  const {
    meetingModeStore: { isInMeetingMode },
  } = useMeetingModeContext()

  useEffect(() => {
    if (swapLinkPromtRef.current?.offsetWidth) {
      setSwapLinkPromtWidth(swapLinkPromtRef.current.offsetWidth)
    }
  }, [swapLinkPromtRef])

  const { ref } = useClickOutside(() => {
    if (isModalOpen) {
      navigationModalToggle.handleClose()
    }
  })

  const isTripCardShown = isTripCartDisplayed && isTripCartEnabled
  const profileLinkText = t(`${i18Base}.Profile`)
  const swapLinkText = t(`${i18Base}.SwapPromt`)
  const feedbackLinkText = t(`${i18Base}.Feedback`)
  const CustomLogoAlt = t(`${i18Base}.CustomLogo`)

  const showSwitchToClassic = !(isInMeetingMode || featureFlags.isNewEttaOnlyEnabled)

  const { profileContainerFirstSlot, profileContainerSecondSlot } =
    isInDelegateMode && !isInMeetingMode
      ? {
          profileContainerFirstSlot: <DelegateSwitch as={null} />,
          profileContainerSecondSlot: isTripCardShown ? <TripCart /> : null,
        }
      : {
          profileContainerFirstSlot: isTripCardShown ? <TripCart /> : null,
          profileContainerSecondSlot:
            isDelegateSwitchEnabled && !isInMeetingMode ? <DelegateSwitch as={null} /> : null,
        }

  return (
    <ProfileContainerWrapper>
      <MeetingSwitchDesktop afterExit={handleLogout} />
      {profileContainerFirstSlot}
      {profileContainerSecondSlot}
      <DesktopNavigationCompanyResourses />

      {showSwitchToClassic && (
        <SwapContainer
          data-tracking-id="header-toggle-to-classic-link"
          as={'a'}
          onClick={handleSiteSwitch}
          href="#"
          swapLinkPromtWidth={swapLinkPromtWidth}>
          <SwapText>
            <Icon name="swapPWA" color={'mainText'} />
            <span ref={swapLinkPromtRef}>{swapLinkText}</span>
          </SwapText>
        </SwapContainer>
      )}

      {Boolean(alertsCount) && <AlertNotificationButton />}
      <AlertModal />

      {!isFeedbackEnabled && (
        <FeedbackContainer as={'a'} href="#">
          <FeedbackText>
            <Icon name="feedbackPWA" color={'mainText'} />
            <span>{feedbackLinkText}</span>
          </FeedbackText>
        </FeedbackContainer>
      )}
      <ProfileContainer ref={ref}>
        {customLogoURL && <CustomProfileLogo src={customLogoURL} alt={CustomLogoAlt} />}
        <ProfileButton
          onClick={navigationModalToggle.handleToggle}
          aria-label={profileLinkText}
          link-name={profileLinkText}>
          <Icon name="profilePWA" color={'mainText'} size="medium" />
          <Icon name="listPWA" color={'mainText'} size={24} />
        </ProfileButton>
        <DesktopNavigationModal
          isModalOpen={isModalOpen}
          currentUser={currentUser}
          unusedTicketsCount={unusedTicketsCount}
          handleLogout={handleLogout}
          isDelegateSwitchEnabled={isDelegateSwitchEnabled}
          isSelfServiceSite={isSelfServiceSite}
          hideUnusedTickets={hideUnusedTickets}
          handleOpenUnusedModal={handleOpenUnusedModal}
          handleNavigateProfile={handleNavigateProfile}
          handleOpenDelegateModal={handleOpenDelegateModal}
        />
      </ProfileContainer>
    </ProfileContainerWrapper>
  )
})
