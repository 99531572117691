import type { RailLayoutType } from '@fiji/enums'
import type { GazooMembership, LoyaltyProgram, PreferenceOptions } from '@fiji/graphql/types'

export type OnSubmitProps = {
  membership: GazooMembership[]
  specialRequestCodes: string[]
  isEticket: boolean
}

type LoyaltyProgramObject = {
  value: LoyaltyProgram[]
  options: PreferenceOptions[]
}

type SpecialRequestObject = {
  value: PreferenceOptions[]
  options: PreferenceOptions[]
}

export type RailPreferencesType = {
  loyaltyProgram: LoyaltyProgramObject
  specialRequest?: SpecialRequestObject
  onClose: () => void
  onSubmit: ({ membership, specialRequestCodes, isEticket }: OnSubmitProps) => void
  existingMembership?: GazooMembership[]
  railLayout: RailLayoutType
  isEticket: boolean
  isEticketDisabled: boolean
  isStationTicketMachineDisabled: boolean
  departureStationName: string
}

export type UseRailPreferencesProps = {
  loyaltyProgram: LoyaltyProgramObject
  onSubmit: ({ membership, specialRequestCodes, isEticket }: OnSubmitProps) => void
  existingMembership?: GazooMembership[]
  isEticket: boolean
}

export enum TicketMethod {
  ETicket = 'eTicket',
  Collect = 'collect',
}
