import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { BlockWrapper } from './block-wrapper'
import { HotelDetailsBody } from './hotel-details-layout-mobile-styled'
import { HotelDetailsHeaderMobile } from './hotel-details-header'

export function HotelDetailsLayoutMobile({
  children,
  aboutSlot,
  scrollContainerRef,
  hotelDetails,
  onClose,
  amenitiesSlot,
  locationSlot,
  reviewsSlot,
  roomsSlot,
  safetyCheckSlot,
  areHotelReviewsDisplayed,
  ecoCheckSlot,
}: LayoutProps) {
  return (
    <>
      <HotelDetailsHeaderMobile
        scrollContainerRef={scrollContainerRef}
        hotelDetails={hotelDetails}
        onClose={onClose}
      />
      {aboutSlot}
      <HotelDetailsBody>
        <Block marginBottom={16}>{roomsSlot}</Block>
        <BlockWrapper slot={locationSlot} />
        <BlockWrapper slot={ecoCheckSlot} />
        <BlockWrapper slot={safetyCheckSlot} />
        <BlockWrapper slot={amenitiesSlot} />
        {areHotelReviewsDisplayed && <BlockWrapper slot={reviewsSlot} />}
        {children}
      </HotelDetailsBody>
    </>
  )
}
