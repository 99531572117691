import type { ColorPalette } from '@fiji/types'
import { TripStatus } from '@fiji/graphql/types'

type Args = {
  tripStatus: TripStatus
  segmentStatus?: TripStatus | null
}

export function getTripDetailsSegmentBackground({
  tripStatus,
  segmentStatus,
}: Args): keyof ColorPalette {
  if (tripStatus === TripStatus.Cancelled || segmentStatus === TripStatus.Cancelled) {
    return 'background2'
  }

  if (tripStatus === TripStatus.OnHold) {
    return 'duskBlue'
  }

  return 'plum'
}
