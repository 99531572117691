import type { SearchFlightLegSubSegment } from '@fiji/graphql/types'
import type { SubSegment } from './types'

export const getAirportChangeData = (flightLegSegments?: SubSegment[]) => {
  if (flightLegSegments) {
    const airportChangeIndex = flightLegSegments.findIndex(
      (segment) => segment.isDepartAirportDifferentFromConnecting,
    )

    if (airportChangeIndex >= 1) {
      const arrivalSegment = flightLegSegments[airportChangeIndex - 1]
      const departureSegment = flightLegSegments[airportChangeIndex]
      return getAirportCodes(arrivalSegment, departureSegment)
    }
  }
  return null
}

function getAirportCodes(arrivalSegment: SubSegment, departureSegment: SubSegment) {
  return {
    arrivalAirport: isSearchFlightSubSegment(arrivalSegment)
      ? arrivalSegment.destination.code
      : arrivalSegment.arrival?.airportCode,
    departureAirport: isSearchFlightSubSegment(departureSegment)
      ? departureSegment.origin.code
      : departureSegment.departure?.airportCode,
  }
}

function isSearchFlightSubSegment(obj: SubSegment): obj is SearchFlightLegSubSegment {
  return 'destination' in obj && 'origin' in obj
}
