import { Inject, Action } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryService } from '@etta/interface/services/history.service'
import { PostBookingReviewTripPageStore } from '../stores/post-booking-review-trip-page.store'

@Action()
export class PostBookingReviewTripPageActions {
  constructor(
    @Inject()
    private readonly postBookingReviewTripPageStore: PostBookingReviewTripPageStore,
    @Inject()
    private readonly historyStore: HistoryStore,
    @Inject()
    private readonly historyService: HistoryService,
  ) {}

  public setBookingIdFromQueryParams() {
    const searchFormQueryParams = this.getFlightSearchFormQueryParams()

    const { bookingId: bookingIdSearchForm } = searchFormQueryParams.getQueryParams(
      this.historyStore.search,
    )

    const rtpQueryParams = this.getRTPQueryParams()

    const { bookingId = bookingIdSearchForm } = rtpQueryParams.getQueryParams(
      this.historyStore.search,
    )

    if (bookingId) {
      // string casting is required here, booking id can be number at runtime.
      this.postBookingReviewTripPageStore.setBookingId(String(bookingId))
    } else {
      this.postBookingReviewTripPageStore.setBookingId('')
    }
  }

  public handleChangeFlightOpen() {
    this.postBookingReviewTripPageStore.flightSearchFormModal.handleOpen()
  }

  public handleChangeFlightClose() {
    this.postBookingReviewTripPageStore.flightSearchFormModal.handleClose()
  }

  public handleCloseModal() {
    this.postBookingReviewTripPageStore.flightSearchFormModal.handleClose()
    const rtpQueryParams = this.getRTPQueryParams()

    // Cleared out flight search criteria
    const nextPath = rtpQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: '',
      newQueryParams: { bookingId: this.postBookingReviewTripPageStore.bookingId },
    })
    this.historyService.replace(nextPath)
  }

  public clearPostBookingReviewTripStore() {
    this.postBookingReviewTripPageStore.setBookingId('')
  }

  private getRTPQueryParams() {
    return new QueryParams<{ bookingId?: string }>(
      {},
      {
        caseStyle: 'camelCase',
      },
    )
  }

  private getFlightSearchFormQueryParams() {
    return new QueryParams<{ bookingId?: string }>(
      {},
      {
        caseStyle: 'kebab-case',
      },
    )
  }
}
