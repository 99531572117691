import styled, { css } from 'styled-components'

export type TrackStyledProps = {
  index: number
  isValueArray: boolean
  isMarksExists?: boolean
  isFilled?: boolean
  style: object
  className: string
  key: number
}

export const TrackStyled = styled.div<TrackStyledProps>`
  top: 50%;
  margin-top: -1px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.borderDark};
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  border-radius: 3px;

  ${(props) => {
    return (
      props.isFilled &&
      css`
         {
          background-color: ${(props) => props.theme.colors.primary};
          border-color: ${(props) => props.theme.colors.primary};
        }
      `
    )
  }}
`
