import styled from 'styled-components'
import { captionMedium, title3, largeTitle, timePickerMedium, title1 } from '@fiji/style'

const pickerTitle = `
  ${title1};
  line-height: 6rem;
`

export const Header = styled.div`
  margin-left: 2rem;
`

export const Title = styled.div`
  ${captionMedium}
`

export const Momentum = styled.div`
  ${title3};
  margin-top: 5px;
`

export const TimeStamp = styled.div<{ height: number; focused: number; isDate?: boolean }>`
  ${timePickerMedium}
  height: ${(p) => p.height}rem;
  width: 100%;
  line-height: ${(p) => p.height}rem;
  text-align: center;
  user-select: none;
  transition: all 0.3s 0.1s;
  color: ${(p) => p.theme.colors.borderDark};
  :nth-child(${(p) => p.focused + 1}) {
    ${(p) => (p.isDate ? pickerTitle : largeTitle)};
    color: ${(p) => p.theme.colors.mainText};
  }
`
