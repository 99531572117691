export function prepareFilters(
  filters: Array<{ title: string; id: string }>,
  selectedFilter: string[],
) {
  const options = filters.map((n, i) => ({ label: n.title, value: i, code: n.id }))
  const selectedOptions = options.filter((v) => selectedFilter.includes(v.code)).map((n) => n.value)

  return {
    options,
    selectedOptions,
  }
}
