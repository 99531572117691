import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ServiceCategory } from '@fiji/graphql/types'
import { useDeleteSegments } from './use-delete-segments'

type Props = {
  flightId: string
  onCloseAfterward?: () => void
}

export function useDeleteFlight({ flightId, onCloseAfterward }: Props) {
  const { tripFlightActions } = useReviewTripContext()

  const handleRequestRemoveFlight = async () => {
    const res = await tripFlightActions.handleRemoveFlight({ flightId })
    if (res.isErr()) {
      throw new Error(res.getError().message)
    }
  }

  const { viewStateParams, handleDeleteSegment } = useDeleteSegments({
    serviceCategory: ServiceCategory.Flight,
    onCloseAfterward,
    onRemoveSegment: handleRequestRemoveFlight,
  })

  return {
    handleRemoveFlight: handleDeleteSegment,
    viewStateParams,
  }
}
