import type { ReportHandler, Metric } from 'web-vitals'
import { isFunction } from '../type'

function sendMetricToRaygun(metric: Metric) {
  window.rg4js('trackEvent', {
    type: 'customTiming',
    name: metric.name,
    duration: metric.value,
  })
}

export function reportWebVitalsToRaygun(onPerfEntry?: ReportHandler) {
  if (!onPerfEntry || !isFunction(onPerfEntry)) {
    return
  }

  import('web-vitals').then(function ({ getCLS, getFID, getFCP, getLCP, getTTFB }) {
    getCLS(sendMetricToRaygun)
    getFID(sendMetricToRaygun)
    getFCP(sendMetricToRaygun)
    getLCP(sendMetricToRaygun)
    getTTFB(sendMetricToRaygun)
  })
}
