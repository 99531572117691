import { v4 as uuid } from 'uuid'
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client'

export const inMemoryCache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Room':
        const generatedID = uuid()
        return `Room:${generatedID}`
      case 'TripDetailsTrainLeg':
        const trainLegID = uuid()
        return `TripDetailsTrainLeg:${trainLegID}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Hotel: {
      fields: {
        privateRate: {
          merge(existing, incoming, { mergeObjects }) {
            if (existing && !incoming) {
              return existing
            }

            return mergeObjects(existing, incoming)
          },
        },

        isPreferred: {
          merge(existing, incoming, { mergeObjects }) {
            if (typeof existing !== 'undefined' && existing !== incoming) {
              return existing
            }

            return mergeObjects(existing, incoming)
          },
        },
      },
    },
    Query: {
      fields: {
        hotel(_, { args, toReference }) {
          if (args) {
            return toReference({
              __typename: 'Hotel',
              id: args.id,
            })
          }
        },
      },
    },
  },
})
