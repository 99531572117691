import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { CostWithCtaMobile } from './mobile'
import { CostWithCtaDesktop } from './desktop'

export function CostWithCtaLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CostWithCtaMobile {...props} />}
      desktopSlot={<CostWithCtaDesktop {...props} />}
    />
  )
}
