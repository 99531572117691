import styled, { css } from 'styled-components'

type IsMobileProps = { isMobile: boolean }

const pageContainerMobile = css`
  display: flex;
  flex-direction: column;
`
const pageContainerDesktop = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
`

export const PageContainer = styled.div<IsMobileProps>`
  width: 100%;
  height: 100%;
  ${(p) => (p.isMobile ? pageContainerMobile : pageContainerDesktop)};
`

const contentContainerMobile = css`
  padding-bottom: 24px;
  flex: 1;
`
const contentContainerDesktop = css`
  flex: 0 1 368px;
  border-radius: 14px;
`
export const ContentContainer = styled.div<IsMobileProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  ${(p) => (p.isMobile ? contentContainerMobile : contentContainerDesktop)};
`

export const Content = styled.div<{ isMobile?: boolean }>`
  margin-left: auto;
  margin-right: auto;

  ${(p) =>
    p.isMobile
      ? css`
          margin-top: -32px;
        `
      : css`
          margin-top: -40px;
        `};
`
