import { SegmentType } from '@etta/core/enums'
import { RideHailSuggestionAssociatedSegmentType } from '@fiji/graphql/hooks'

export function mapSegmentTypeEnum(type: RideHailSuggestionAssociatedSegmentType): SegmentType {
  switch (type) {
    case RideHailSuggestionAssociatedSegmentType.CarRental:
      return SegmentType.CarRental
    case RideHailSuggestionAssociatedSegmentType.Flight:
      return SegmentType.Flight
    case RideHailSuggestionAssociatedSegmentType.Hotel:
      return SegmentType.Hotel
    case RideHailSuggestionAssociatedSegmentType.Unspecified:
      return SegmentType.Unspecified
  }
}
