import { RailSearchSegmentDetails } from '@etta/components/rail-search-segment-details/rail-search-segment-details'
import { RailBadges } from '@etta/components/rail-badges/rail-badges'
import { Text } from '@etta/ui/text'
import { RailSegmentEmission } from '@etta/components/rail-segment-emission/rail-segment-emission'
import { TrainServiceActiveTab } from '@fiji/enums'
import { RailSearchRateLabel } from '../../components'
import type { LayoutProps } from '../../types'
import { Container, Footer, PriceRow } from './rail-search-segment-mobile-styled'

export function RailSearchSegmentMobile({
  segment,
  origin,
  destination,
  onSelect,
  isLimited,
  railPolicies,
  useRailSearchSegmentValue,
}: LayoutProps) {
  if (!useRailSearchSegmentValue) {
    return null
  }
  const {
    start,
    end,
    tripTime,
    travelChangesNumber,
    travelChangesLabel,
    daysInBetween,
    railwayCarriersInfo,
    fare,
    isPreferred,
    isOutOfPolicy,
    isSmallMobile,
    rateId,
    segmentPrice,
    segmentLabel,
    firstClassPrice,
    soldOutLabel,
    isSoldOut,
    uniqueTransportIconNames,
    actualRailEmission,
    isCheapest,
    isFastest,
    applicableRailCard,
  } = useRailSearchSegmentValue

  const handleClick = () => {
    if (!isSoldOut) {
      onSelect?.(TrainServiceActiveTab.Standard)
    }
  }

  // TODO: isHighlightFromLocation - Awaiting on Karthik to advise when to apply highlight
  return (
    <Container aria-label={segmentLabel} onClick={handleClick} tabIndex={0}>
      <RailSearchSegmentDetails
        isHighlightFromLocation={!!origin?.airportCode && segment.origin !== origin?.airportCode}
        isHighlightToLocation={
          !!destination?.airportCode && segment.destination !== destination?.airportCode
        }
        fromLocation={segment.origin}
        toLocation={segment.destination}
        startTime={start}
        endTime={end}
        travelTime={tripTime}
        travelChanges={travelChangesLabel}
        daysInBetween={daysInBetween}
        carriersInfo={railwayCarriersInfo}
        isCheapest={isCheapest}
        isFastest={isFastest}
        isLimited={isLimited}
        changesNumber={travelChangesNumber}
        iconNames={uniqueTransportIconNames}
        applicableRailCard={applicableRailCard}
      />
      <Footer aria-labelledby={rateId}>
        {fare && (
          <RailBadges
            isPreferred={isPreferred}
            isOutOfPolicy={isOutOfPolicy}
            maxBadges={1}
            fare={fare}
          />
        )}
        <PriceRow
          isSmallMobile={isSmallMobile}
          isSpaceBetween={!!actualRailEmission.kgOfActualEmission}>
          {actualRailEmission.kgOfActualEmission && <RailSegmentEmission {...actualRailEmission} />}
          {isSoldOut ? (
            <Text variant="linkSmall" color="bodyText" aria-label={soldOutLabel}>
              {soldOutLabel}
            </Text>
          ) : (
            <RailSearchRateLabel
              rateId={rateId}
              firstClassPrice={firstClassPrice}
              standartPrice={segmentPrice}
              railPolicies={railPolicies}
            />
          )}
        </PriceRow>
      </Footer>
    </Container>
  )
}
