import { Action, Inject } from '@etta/di'
import { HeapAnalyticsIdentityService } from '@etta/modules/admin/interface/heap-analytics-identity/services/heap-analytics-identity.service'
import { HeapAnalyticsIdentityStore } from '@etta/modules/admin/interface/heap-analytics-identity/stores/heap-analytics-identity.store'
import type { HeapAnalyticsIdentityValueObject } from '@etta/modules/admin/core/value-objects/heap-analytics-identity.value-object'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'

@Action()
export class HeapAnalyticsIdentityActions {
  constructor(
    @Inject()
    private heapAnalyticsIdentityService: HeapAnalyticsIdentityService,
    @Inject()
    private heapAnalyticsIdentityStore: HeapAnalyticsIdentityStore,
    @Inject()
    private historyService: HistoryService,
  ) {}

  handleBack() {
    this.historyService.push(ROUTES.admin.main)
  }

  async fetchIdentity(email: string) {
    await this.heapAnalyticsIdentityService.fetchIdentity(email)
  }

  copyToClipboard(identity: HeapAnalyticsIdentityValueObject) {
    this.heapAnalyticsIdentityService.copyIdentityToClipboard(identity)
  }

  setEmail(value: string) {
    this.heapAnalyticsIdentityStore.setEmail(value.trim())
  }
}
