import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import {
  Container,
  ItemWrapper,
  HeaderWrapper,
  Left,
  Right,
  FooterWrapper,
  ButtonWrapper,
} from '../../rail-result-skeleton-styled'

function SkeletonItem() {
  return (
    <>
      <ItemWrapper>
        <HeaderWrapper>
          <Left>
            <Skeleton width="100%" height="72px">
              <rect width="56px" height="16px" />
              <rect width="80px" height="10px" y={20} />
              <rect width="136px" height="10px" y={55} />
            </Skeleton>
          </Left>
          <Right>
            <Skeleton width="56px" height="16px">
              <rect width="100%" height="100%" />
            </Skeleton>
            <Block marginTop={4} marginBottom={18}>
              <Skeleton width="80px" height="10px">
                <rect width="100%" height="100%" />
              </Skeleton>
            </Block>
            <Skeleton width="24px" height="24px">
              <rect width="100%" height="100%" />
            </Skeleton>
          </Right>
        </HeaderWrapper>
        <Block>
          <Separator />
        </Block>
        <FooterWrapper>
          <Left>
            <Skeleton width="100%" height="30px">
              <rect width="80px" height="16px" />
            </Skeleton>
          </Left>
          <Right>
            <Skeleton width="45px" height="16px">
              <rect width="100%" height="100%" />
            </Skeleton>
            <Block marginTop={4}>
              <Skeleton width="72px" height="10px">
                <rect width="100%" height="100%" />
              </Skeleton>
            </Block>
          </Right>
        </FooterWrapper>
      </ItemWrapper>
    </>
  )
}

function SkeletonButton() {
  return (
    <ButtonWrapper>
      <Block isFlexBlock paddingVertical={12}>
        <Skeleton width="83px" height="17px">
          <rect width="100%" height="100%" />
        </Skeleton>
      </Block>
    </ButtonWrapper>
  )
}

export function RailResultSkeletonMobile() {
  return (
    <Container>
      <SkeletonButton />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonButton />
    </Container>
  )
}
