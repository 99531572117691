import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import { CarRentalFiltersContent } from './car-rental-filters-content'

export const CarRentalFiltersModal = observer(function CarRentalFiltersModal() {
  const { carFiltersModalStore, carRentalFilterActions } = useCarRentalSearchContext()

  return (
    <Modal
      isVisible={carFiltersModalStore.carFiltersToggle.isOpen}
      handleModalVisibility={() => carRentalFilterActions.handleCloseFiltersModal()}>
      <CarRentalFiltersContent />
    </Modal>
  )
})
