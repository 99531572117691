import styled from 'styled-components'
import { captionMedium } from '@fiji/style'

export const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  background: ${({ theme }) => theme.colors.white};
  padding: 14px 20px;
`

export const Main = styled.div`
  display: flex;
`

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.colors.mainText};
  gap: 4px;
`

export const RightBlock = styled.div<{ withAlligContentToBottom?: boolean }>`
  margin-left: auto;
  padding: 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.withAlligContentToBottom ? 'flex-end' : 'unset')};
  align-items: flex-end;
  gap: 13px;
`

export const Extras = styled.div`
  color: ${({ theme }) => theme.colors.bodyText1};
  margin-bottom: 6px;

  ${captionMedium}

  line-height: 19px;
`
