import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'

export const EmergencyContactIdentifiers = {
  53: 'name',
  56: 'relationship',
  54: 'primaryPhone',
  55: 'alternatePhone',
  57: 'email',
  159: 'addressBlock',
  64: AddressFields.Mailstop,
  63: AddressFields.Country,
  62: AddressFields.Zip,
  61: AddressFields.State,
  60: AddressFields.City,
  58: AddressFields.Street1,
  59: AddressFields.Street2,
}
