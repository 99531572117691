import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { HotelsSection, CarRentalSection } from '../blocks'

type Props = {
  totalLaterHotelTripCost: string
  totalLaterCarRentalTripCost: string
  hasLaterHotelTripCost: boolean
  hasLaterCarRentalTripCost: boolean
  isPayLater: boolean
}

export function PayLaterSection({
  totalLaterCarRentalTripCost,
  totalLaterHotelTripCost,
  hasLaterCarRentalTripCost,
  hasLaterHotelTripCost,
  isPayLater,
}: Props) {
  const { t } = useTranslation()

  if (!isPayLater) {
    return null
  }

  return (
    <div>
      <Text variant="captionStrongCaps" color="mainText" isBlock>
        {t('ReviewTrip.PriceSummary.PayableLater')}
      </Text>
      <HotelsSection
        totalHotelTripCost={totalLaterHotelTripCost}
        hasHotelTripCost={hasLaterHotelTripCost}
      />
      <CarRentalSection
        totalCarRentalTripCost={totalLaterCarRentalTripCost}
        hasCarRentalTripCost={hasLaterCarRentalTripCost}
      />
    </div>
  )
}
