import { css } from 'styled-components'

export const TRANSITION_NAME = 'BLOCK_SLIDE'
export const TRANSITION_DURATION = 300
export const TRANSITION_FUNCTION = 'cubic-bezier(0.4, 0, 0.2, 1)'

export const slideUp = css<{ isAbsolutePosition?: boolean }>`
  position: ${({ isAbsolutePosition }) => (isAbsolutePosition ? 'absolute' : 'relative')};
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  transition: transform ${TRANSITION_DURATION}ms ${TRANSITION_FUNCTION};

  &,
  &.${TRANSITION_NAME}-enter,
    &.${TRANSITION_NAME}-appear,
    &.${TRANSITION_NAME}-exit-done,
    &.${TRANSITION_NAME}-exit,
    &.${TRANSITION_NAME}-exit-active {
    transform: translateY(130%);
  }

  &.${TRANSITION_NAME}-appear-done,
    &.${TRANSITION_NAME}-enter-done,
    &.${TRANSITION_NAME}-appear-active,
    &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0%);
  }
`
