import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import type { LayoutProps } from '../../types'
import { Container, Menu, List } from './location-search-field-desktop-styled'

export function LocationSearchFieldDesktop({
  isSearchWindowOpen,
  onModalClose,
  inputSlot,
  listSlot,
  isForSearchHeader = false,
}: LayoutProps) {
  const { ref } = useClickOutside(() => {
    if (isSearchWindowOpen) {
      onModalClose()
    }
  })
  useShouldOpenInViewport({
    elementHeight: 370,
    isVisible: isSearchWindowOpen,
    ref,
  })

  return (
    <Container>
      {inputSlot}
      {isSearchWindowOpen && (
        <Menu ref={ref} isForSearchHeader={isForSearchHeader}>
          <List>{listSlot}</List>
        </Menu>
      )}
    </Container>
  )
}
