import { useCallback } from 'react'
import { firebase } from '../firebase'
import { getFirebaseMessagingToken } from './get-firebase-messaging-token'
import { getFirebaseMessagingLogger } from './loggers'
import { mapNotificationFromFirebaseMessage } from './map-notification-from-firebase-message'
import { useNotificationServer } from './use-notification-server'
import { NotificationClientIdStore } from './notification-client-id-store'

type Args = {
  locale: string
}

export function useFirebaseMessaging({ locale }: Args) {
  const { ackNotification, registerDevice, toggleNotification } = useNotificationServer({ locale })

  return useCallback(async () => {
    const logger = getFirebaseMessagingLogger()

    if (!firebase.messaging.isSupported()) {
      logger.error('Message', `Browser doesn't support messaging`)

      return
    }

    const serviceWorkerRegistration = await navigator.serviceWorker.ready
    const token = await getFirebaseMessagingToken(serviceWorkerRegistration)

    if (!token) {
      return
    }

    const newNotificationClientId = await registerDevice(token)

    if (newNotificationClientId) {
      NotificationClientIdStore.set(newNotificationClientId)
    }

    toggleNotification()

    logger.log('Message', 'Register', token)

    firebase.messaging().onMessage((message) => {
      if (!message) {
        logger.error('Message', 'Received', message)

        return
      }

      logger.log('Message', 'Received', message)

      try {
        const notification = mapNotificationFromFirebaseMessage(message)
        const { title, body, icon, actions, id, data } = notification

        serviceWorkerRegistration.showNotification(title, {
          body,
          icon,
          actions,
          data,
        })

        ackNotification(id)

        logger.log('Message', 'Show', {
          message,
          notification,
        })
      } catch (e) {
        logger.error('Message', 'Show', e)
      }
    })
  }, [ackNotification, registerDevice, toggleNotification])
}
