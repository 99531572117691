import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { BackgroundSection, IconWrapper, BgImage } from './trip-background-styled'
import DefaultBgImage from './assets/default.svg?url'
import DefaultPhoto from './assets/default-photo.webp'

type Props = {
  url?: string | null
  onClose: () => void
  withIcon?: boolean
}

export function TripBackground({ url, onClose, withIcon }: Props) {
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const { t } = useTranslation()

  const defaultBgImage = brandConfigurationStore.isAppleTheme ? DefaultPhoto : DefaultBgImage

  return (
    <>
      <BackgroundSection>
        {withIcon && (
          <IconWrapper>
            <IconButton
              onClick={onClose}
              icon="closeFilledPWA"
              size="medium"
              aria-label={t('Accessibility.Header.Close')}
            />
          </IconWrapper>
        )}
        <BgImage url={url || defaultBgImage} />
      </BackgroundSection>
    </>
  )
}
