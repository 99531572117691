import styled from 'styled-components'

export const ItineraryBlockContent = styled.div`
  padding: 16px 10px;
`

export const Separator = styled.div`
  display: flex;
`

export const ActionsBlock = styled.div`
  margin-top: 54px;
`
export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const ActionsBody = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`
