import type { TripDetailsFlightSearchDateRangeValueObject } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-search-date-range-value-object'

export type TripDetailsFlightActionsValueObject = {
  allowedSearchDateRange?: TripDetailsFlightSearchDateRangeValueObject | null
  isChangeCarrierAllowed?: boolean
  isChangeFareTypeAllowed?: boolean
  isChangeAirportAllowed?: boolean
  isChangeCabinAllowed?: boolean
  changeType?: FlightActionChangeType
}

export enum FlightActionChangeType {
  EntireFlight = 'ENTIRE_FLIGHT',
  ReturnLeg = 'RETURN_LEG',
}
