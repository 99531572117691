import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { footnoteMedium } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const Container = styled.div``

export const EmissionData = styled.div`
  width: 100%;
`

export const LeafIcon = styled(Icon)<{
  deviationLevel: EmissionsDeviationLevel
}>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.ecoCheck
      : props.theme.colors.bodyText};
`

export const EmissionDescription = styled.span<{
  deviationLevel: EmissionsDeviationLevel
}>`
  padding-left: 5px;
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.ecoCheck
      : props.theme.colors.bodyText};
  ${footnoteMedium};
`

export const Loader = styled.div`
  height: 24px;
  width: 168px;
  ${glowingFragment};
`
