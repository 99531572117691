import { useCallback } from 'react'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'

export function useCarClassOptions() {
  const {
    carRentalSearchStore,
    carFiltersStore,
    carRentalFilterActions,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const options = filterOptions.carClasses

  const isChecked = carFiltersStore.selectionHandlers.isCarClassSelected

  const handleSelectCarClass = useCallback(
    (id: string) => () => {
      carRentalFilterActions.handlers.setCarClass(id)
    },
    [carRentalFilterActions.handlers],
  )

  return {
    options,
    isChecked,
    handleSelectCarClass,
  }
}
