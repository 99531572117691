import { observer } from 'mobx-react-lite'
import { useCarRentalSearchResults } from './use-car-rental-search-results'
import { CarRentalSearchResultsProvider } from './car-rental-search-results-provider'
import { CarRentalSearchResultsContent } from './car-rental-search-results-content'

export const CarRentalSearchResults = observer(function CarRentalSearchResults() {
  const value = useCarRentalSearchResults()

  return (
    <CarRentalSearchResultsProvider value={value}>
      <CarRentalSearchResultsContent />
    </CarRentalSearchResultsProvider>
  )
})
