import { useAppSelector } from '@fiji/store'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { useUserContext } from '@etta/modules/user'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { insertWhatFix, isWhatFixInserted } from './insert-whatfix'

export function useInsertWhatFix() {
  const { siteName: deemSiteName } = useAppSelector(displayConfigurationSelector)
  const { featureFlags } = useFeatureFlags()
  const { userStore } = useUserContext()
  const { companyId, profile } = userStore

  const siteName = deemSiteName ?? profile?.site?.name ?? ''

  const email = profile?.personalContact?.email
  const isDataInvalid = !siteName || !companyId || !email

  if (isDataInvalid || isWhatFixInserted()) {
    return
  }

  if (!featureFlags.isWhatFixPWAEnabled) {
    return
  }

  window.whtFix_CompanyName = siteName
  window.whtFix_CustomerID = profile?.personalContact?.email || undefined
  window.whtFix_SiteID = `${companyId}`
  insertWhatFix()
}
