import { Swap } from '@etta/ui/swap'
import { OutsideContainer } from '@etta/ui/outside-container'
import { ListTypes } from '../types'
import type { ValueType, OptionType } from '../types'
import { ListOption } from './list-option'
import { useList } from './use-list'

type Props<T = ValueType> = {
  label: string
  type: ListTypes
  value?: T[]
  options: OptionType<T>[]
  onChange: (value: T[]) => void
  withListBodyPadding?: boolean
  withListHeader?: boolean
  withFullHeight?: boolean
  excludeHeight?: number
  listHeight?: number
}

export function List<T extends ValueType = ValueType>({
  label,
  type,
  options,
  value,
  onChange,
  withListBodyPadding = true,
  withListHeader = true,
  withFullHeight,
  excludeHeight,
  listHeight,
}: Props<T>) {
  const { isValuesEqual, chosenValues, handleClear, handleClickItem, handleApply } = useList<T>({
    options,
    value,
    type,
    onChange,
  })

  return (
    <>
      {withListHeader && type === ListTypes.Select && (
        <OutsideContainer.HeaderSelected label={label} selectedNumber={chosenValues.length} />
      )}
      {withListHeader && type === ListTypes.Radio && <OutsideContainer.HeaderTitle label={label} />}
      <Swap
        is={withFullHeight}
        isSlot={
          <OutsideContainer.FullHeightBody
            withoutPadding={!withListBodyPadding}
            excludeHeight={excludeHeight}
            tabIndex={-1}>
            <ListOption
              type={type}
              options={options}
              chosenValues={chosenValues}
              handleClickItem={handleClickItem}
            />
          </OutsideContainer.FullHeightBody>
        }>
        <OutsideContainer.Body
          tabIndex={-1}
          withoutPadding={!withListBodyPadding}
          height={listHeight}>
          <ListOption
            type={type}
            options={options}
            chosenValues={chosenValues}
            handleClickItem={handleClickItem}
          />
        </OutsideContainer.Body>
      </Swap>
      {type === ListTypes.Select && (
        <OutsideContainer.Footer
          onClear={handleClear}
          isDisabled={isValuesEqual}
          onApply={handleApply}
        />
      )}
    </>
  )
}
