import { Skeleton } from '@etta/ui/skeleton'
import { CardContainer } from './hotel-cards-carousel-placeholder-styled'

export function HotelCardsCarouselPlaceholder() {
  return (
    <CardContainer>
      <Skeleton width="100%" height="96px" variant="secondary1">
        <defs>
          <clipPath id="round-corner">
            <rect x="0" y="0" width="30%" height="100%" rx="10" ry="10" />
          </clipPath>
        </defs>
        <rect width="27%" height="100%" clipPath="url(#round-corner)" />
        <rect y={12} x="31.25%" width="65%" height="16px" rx="2" ry="2" />
        <rect y={66} x="31.25%" width="19%" height="16px" rx="2" ry="2" />
        <rect y={66} x="54.7%" width="19%" height="16px" rx="2" ry="2" />
      </Skeleton>
    </CardContainer>
  )
}
