import type { BaggageInfoSegment } from '@fiji/types'
import { BaggageInformationLayout } from './layout'
import { useBaggageInformation } from './use-baggage-information'

type Props = {
  onBaggageModalOpen: () => void
  baggageButtonLabel: string
  baggageInfo?: BaggageInfoSegment[]
}

export function BaggageInformation({ baggageInfo, onBaggageModalOpen, baggageButtonLabel }: Props) {
  const layoutProps = useBaggageInformation({
    baggageInfo,
    baggageButtonLabel,
    onBaggageModalOpen,
  })

  return <BaggageInformationLayout {...layoutProps} />
}
