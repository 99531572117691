import { useTranslation } from 'react-i18next'
import type { PreferenceContent } from '@fiji/types'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { Chevron } from '../../traveler-info-styled'
import { getPreferenceBoxData } from '../get-preference-box-data'
import type { AvailablePreferencesToSelect } from '../../types'
import type { PreferencesType } from '../types'
import { DetailedPreferences, PreferenceLine } from './expanded-preferences-styled'
import { preferredContentToString } from './preferred-content-to-string'
import { Preference } from './preference'

type Props = {
  onClick: () => void
  preferences?: PreferenceContent
  type: PreferencesType
  resolvedNotifications?: string[]
  availableToSelect?: AvailablePreferencesToSelect
  areAllPreferencesUnavailable?: Boolean
  withChevron?: boolean
  isPreferredInOneLine?: boolean
}

const i18Base = 'TravelerInfo.'

export function ExpandedPreferences({
  onClick,
  type,
  preferences,
  availableToSelect,
  withChevron = true,
  isPreferredInOneLine,
  areAllPreferencesUnavailable,
}: Props) {
  // FLIGHT_PREFENRECE_TODO: all the code is commented here as we cannot implement flight notifications yet
  // All places that need to be uncommented mentioned as FLIGHT_PREFENRECE_TODO throughout whole project
  // so you can easily find these places
  const { t } = useTranslation()
  const {
    loyaltyStatus,
    specialRequestStatus,
    mealStatus,
    eTicketStatus,
    railCardStatus,
  } = getPreferenceBoxData({ preference: preferences, availability: availableToSelect || null })
  const { reviewTripStore } = useReviewTripContext()
  const { appliedRailCardNames } = reviewTripStore
  const appliedRailCardName = appliedRailCardNames.length > 0 ? appliedRailCardNames[0] : ''
  const { isMeal, isMembership, isSpecialRequest } = availableToSelect || {}

  const isAir = type === 'flight'
  const isRail = type === 'rail'

  if (isAir && areAllPreferencesUnavailable) {
    return (
      <Block paddingRight={32} paddingLeft={20} paddingTop={13}>
        <Text variant="footnoteMedium" color="mainText">
          {t(i18Base + 'PreferencesUnavailable')}
        </Text>
      </Block>
    )
  }

  return (
    <DetailedPreferences onClick={onClick}>
      {!isRail && (
        <PreferenceLine>
          <Icon {...loyaltyStatus} />
          <Preference
            description={preferredContentToString(
              isMembership && preferences?.loyaltyProgram,
              isPreferredInOneLine,
            )}
            nonFilledLabel={t(i18Base + 'AddLoyalty')}
          />
        </PreferenceLine>
      )}
      {isAir && (
        <PreferenceLine>
          <Icon {...mealStatus} />
          <Preference
            description={isMeal ? preferences?.meal?.label : null}
            nonFilledLabel={t(i18Base + 'AddMeal')}
          />
        </PreferenceLine>
      )}
      {!isRail && (
        <PreferenceLine>
          <Icon {...specialRequestStatus} />
          <Preference
            description={preferredContentToString(
              isSpecialRequest && preferences?.specialRequest.map((el) => el.label || el.value),
              isPreferredInOneLine,
            )}
            nonFilledLabel={t(i18Base + 'AddSpecial')}
          />
        </PreferenceLine>
      )}
      {isRail && (
        <>
          <PreferenceLine>
            <Icon {...railCardStatus} />
            <Preference
              nonFilledLabel={
                appliedRailCardName ? appliedRailCardName : t(i18Base + 'NoRailcardSelected')
              }
            />
          </PreferenceLine>
          <PreferenceLine>
            <Icon {...eTicketStatus} />
            <Preference
              nonFilledLabel={
                preferences?.isETicket ? t(i18Base + 'Eticket') : t(i18Base + 'CollectFromStation')
              }
            />
          </PreferenceLine>
        </>
      )}
      {withChevron && <Chevron name="expandLessPWA" />}
    </DetailedPreferences>
  )
}
