import { useTranslation } from 'react-i18next'
import type { Maybe, Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Container, Text, Cost } from './total-cost-styled'

type Props = {
  rate?: Maybe<Rate>
}

const i18nBase = 'PostBooking.TripDetails.CostSummary'

export function TotalCost({ rate }: Props) {
  const { t } = useTranslation()
  const { mainCost } = formatRate(rate)
  const text = t(`${i18nBase}.TripTotal`)

  return (
    <Container aria-label={t(`${i18nBase}.ItemCost`, { item: text, price: mainCost })}>
      <Text>{text}</Text>
      <Cost>{mainCost}</Cost>
    </Container>
  )
}
