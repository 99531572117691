import { DeemUberLogoPWA } from '@etta/ui/icon/pwa-icon/assets'
import KanUberSVG from '@etta/ui/icon/pwa-icon/assets/kan-uber.svg?url'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { AppleLogo } from './brand-logo-styled'

type Prop = {
  height?: string
}

export function BrandLogo({ height }: Prop) {
  const { brandConfigurationStore } = useBrandConfigurationContext()

  if (brandConfigurationStore.isAppleTheme) {
    return <AppleLogo src={KanUberSVG} height={height ?? '40px'} data-tracking-id="kan-logo" />
  }

  return <DeemUberLogoPWA height={height} data-tracking-id="deem-uber-logo" />
}
