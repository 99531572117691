export default function TerminalIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2133 11.3517H14.3467V9.48966C14.5333 8.55862 13.7867 8 13.04 8C12.2933 8 11.7333 8.55862 11.7333 9.48966V11.3517H9.86667C9.30667 11.3517 8.74667 11.5379 8.37333 12.0966C8 12.469 8 13.0276 8 13.5862L8.93333 17.3103C9.12 18.0552 9.86667 18.8 10.8 18.8H11.7333V26.6207H9.86667C8.83574 26.6207 8 27.4544 8 28.4828V33.138C8 34.1664 8.83574 35 9.86667 35H34.1333C35.1643 35 36 34.1664 36 33.138V28.4828C36 27.4544 35.1643 26.6207 34.1333 26.6207H14.5333V18.8H15.4667C16.2133 18.8 17.1467 18.2414 17.3333 17.3103L18.2667 13.5862C18.4533 13.0276 18.2667 12.469 17.8933 11.9103C17.3333 11.5379 16.96 11.3517 16.2133 11.3517ZM14.72 15.8207H11.7333H11.5467L11.1733 13.9586H11.7333H14.5333H15.0933L14.72 15.8207ZM10.8 29.4138V32.2069H33.2V29.4138H10.8ZM33.9466 15.6345C34.6933 15.4483 35.44 15.6345 35.8133 16.3793C36.1866 16.9379 35.8133 17.869 35.2533 18.0552L26.8533 22.1517L22.56 23.0828L20.1333 19.1724L20.88 18.8L23.8666 20.4759L26.8533 19.1724L23.3066 14.5172L24.4266 13.9586L30.2133 17.4965L33.9466 15.6345Z"
        fill="currentColor"
      />
    </svg>
  )
}
