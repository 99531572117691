import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { AirTimeRangePicker } from '@etta/components/air-time-range-picker/air-time-range-picker'
import { Search } from '@etta/ui/search'
import { DatePickerModal as DatePickerModalNew } from '@etta/ui/date-picker-modal-temp'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { LayoutProps } from '../../types'
import { useCalendarTimeSection } from '../../use-calendar-time-section'
import { SectionWrapper } from '../../../flight/layout/desktop/air-search-flight-desktop-styled.new'

const i18Base = 'TripPlanner.BaseSearch'

export const CalendarTimeSectionDesktop = observer(function CalendarTimeSectionDesktop({
  flightType,
  departureDate,
  returnDate,
  previousDate,
  dateLegend,
  timeRange,
  originPlace,
  destinationPlace,
  returnTimeRange,
  onDateChange,
  isDateRangeValid,
  onTimeChange,
  onReturnTimeRangeChange,
  isDepartureDatePickerDisabled,
  isDepartureTimeRangeDisabled,
  isReturnDatePickerDisabled,
  isReturnTimeRangeDisabled,
  highlightDates,
  validationErrors,
  startDate,
  endDate,
  isLoading,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    isRoundTrip,
    convertedOriginDate,
    originTimeString,
    originTimeLabel,
    convertedReturnDate,
    returnTimeString,
    returnTimeLabel,
  } = useCalendarTimeSection({
    flightType,
    departureDate,
    returnDate,
    timeRange,
    returnTimeRange,
    isShortCustomTime: true,
  })

  const departDateToggle = useTogglePopup()
  const returnDateToggle = useTogglePopup()
  const departTimeToggle = useTogglePopup()
  const returnTimeToggle = useTogglePopup()

  let minDate = startDate
  if (!minDate) {
    minDate = new Date() // min date shouldn't pass current date
  }

  return (
    <>
      <SectionWrapper>
        <Search.Group isTwoInputs>
          <Search.Element
            wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
              label: t(`${i18Base}.Input.DepartOnDate`),
              value: convertedOriginDate,
            })}
            isDisabled={isDepartureDatePickerDisabled}
            isShimmerLoading={isLoading}
            labelTitle={t(`${i18Base}.Input.DepartOnDate`)}
            placeholder={t(i18Base + '.Input.Select')}
            value={convertedOriginDate}
            onClick={departDateToggle.handleOpen}
            errorMessage={validationErrors.departureDate}
            shouldErrorMessageRightBorderBeFlat
            contentSlot={
              <DatePickerModalNew
                date={departureDate}
                dateEnd={isDateRangeValid() && isRoundTrip ? returnDate : null}
                startDateLegend={dateLegend?.date}
                endDateLegend={dateLegend?.dateEnd}
                previousDate={previousDate}
                isRange={isRoundTrip}
                isEndDateSame
                isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
                minDate={minDate}
                maxDate={endDate} // is date end acts as max date? does this matter for departure flight?
                highlightDates={highlightDates}
                startTitle={t(`${i18Base}.Input.Start`)}
                endTitle={t(`${i18Base}.Input.End`)}
                onSelect={onDateChange}
                isOpen={departDateToggle.isOpen}
                onClose={departDateToggle.handleClose}
              />
            }
          />
          <Search.Element
            isDisabled={isDepartureTimeRangeDisabled}
            isShimmerLoading={isLoading}
            wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
              label: originTimeLabel,
              value: originTimeString,
            })}
            isTextAlignRight
            labelTitle={originTimeLabel}
            value={originTimeString}
            onClick={departTimeToggle.handleOpen}
            contentSlot={
              <AirTimeRangePicker
                isVisible={departTimeToggle.isOpen}
                onClose={departTimeToggle.handleClose}
                onSubmit={onTimeChange}
                originPlace={originPlace}
                destinationPlace={destinationPlace}
                selectedTime={timeRange}
                date={departureDate}
              />
            }
          />
        </Search.Group>
      </SectionWrapper>

      {isRoundTrip && (
        <SectionWrapper>
          <Search.Group isTwoInputs>
            <Search.Element
              isDisabled={isReturnDatePickerDisabled}
              isShimmerLoading={isLoading}
              wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
                label: t(`${i18Base}.Input.ReturnOn`),
                value: convertedReturnDate,
              })}
              labelTitle={t(`${i18Base}.Input.ReturnOn`)}
              placeholder={t(i18Base + '.Input.Select')}
              value={convertedReturnDate}
              onClick={returnDateToggle.handleOpen}
              errorMessage={
                validationErrors.departureDate ? undefined : validationErrors.returnDate
              }
              shouldErrorMessageRightBorderBeFlat
              contentSlot={
                <DatePickerModalNew
                  isReturnDate
                  date={departureDate}
                  dateEnd={isDateRangeValid() && isRoundTrip ? returnDate : null}
                  startDateLegend={dateLegend?.date}
                  endDateLegend={dateLegend?.dateEnd}
                  isRange={isRoundTrip}
                  isEndDateSame
                  isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
                  minDate={minDate}
                  maxDate={endDate} // is date end acts as max date?
                  highlightDates={highlightDates}
                  startTitle={t(`${i18Base}.Input.Start`)}
                  endTitle={t(`${i18Base}.Input.End`)}
                  onSelect={onDateChange}
                  isOpen={returnDateToggle.isOpen}
                  onClose={returnDateToggle.handleClose}
                />
              }
            />
            <Search.Element
              isDisabled={isReturnTimeRangeDisabled}
              isShimmerLoading={isLoading}
              wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
                label: returnTimeLabel,
                value: returnTimeString,
              })}
              isTextAlignRight
              labelTitle={returnTimeLabel}
              value={returnTimeString}
              onClick={returnTimeToggle.handleOpen}
              contentSlot={
                <AirTimeRangePicker
                  isVisible={returnTimeToggle.isOpen}
                  onClose={returnTimeToggle.handleClose}
                  onSubmit={onReturnTimeRangeChange}
                  originPlace={destinationPlace}
                  destinationPlace={originPlace}
                  selectedTime={returnTimeRange}
                  date={returnDate}
                />
              }
            />
          </Search.Group>
        </SectionWrapper>
      )}
    </>
  )
})
