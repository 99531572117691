import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Info, Initials } from './delegate-name-initials-styled'

type Props = {
  initials: string
  name: string
  email: string
  showEmail?: boolean
  isListItem?: boolean
}

const i18nPath = 'Delegate.'

export function DelegateNameWithInitials({ initials, name, email, showEmail, isListItem }: Props) {
  const { t } = useTranslation()
  const travelerText = t(i18nPath + 'Traveler')

  return (
    <>
      <Initials>
        <Text variant="headline" color="mainText2">
          {initials}
        </Text>
      </Initials>
      <Info>
        {!isListItem && <Text variant="captionStrongCaps">{travelerText}</Text>}
        <Text variant="headline" noWrap textOverflow="ellipsis">
          {name}
        </Text>
        {showEmail && (
          <Text variant="footnoteMedium" noWrap textOverflow="ellipsis" color="bodyText">
            {email}
          </Text>
        )}
      </Info>
    </>
  )
}
