import { Skeleton } from '@etta/ui/skeleton'

export function GeoSafeScaleSkeleton() {
  return (
    <Skeleton width="100%" height="125">
      <rect width="50%" height="16" y="34" />
      <rect width="16%" height="16" y="58" />
    </Skeleton>
  )
}
