import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const ConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WarningIcon = styled(Icon).attrs(() => ({
  name: 'warningFilledPWA',
  color: 'warning',
  size: 'large',
}))`
  margin: 0 auto;
`
