import { Icon } from '@etta/ui/icon'
import { Spinner } from '@etta/ui/spinner'
import { Block } from '@etta/ui/block'
import { DelegateSwitchLayout } from '@fiji/enums'

type Props = {
  isListItem?: boolean
  isLoading?: boolean
  isSelected?: boolean
  layout?: DelegateSwitchLayout
}

export function IconBlock({ isListItem, isLoading, isSelected, layout }: Props) {
  if (!isListItem && isLoading && layout !== DelegateSwitchLayout.PostBooking) {
    return (
      <Block>
        <Spinner type="spinout" size="medium" />
      </Block>
    )
  }

  if (isListItem && isSelected) {
    return <Icon name="tickPWA" color="primary" />
  }

  if (layout === DelegateSwitchLayout.Explore && !isListItem) {
    return <Icon name="chevronRightPWA" color="primary" />
  }

  if (layout === DelegateSwitchLayout.PostBooking) {
    return <Icon name="chevronDownPWA" size="small" color="mainText" />
  }

  return null
}
