import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Spinner } from '@etta/ui/spinner'
import { Block } from '@etta/ui/block'
import { UnusedTicketsModal } from '@etta/components/unused-tickets-modal'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useGetUnusedTickets } from '@fiji/hooks/use-get-unused-tickets'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Container, Details, CountTickets } from './unused-tickets-styled'

const i18nBase = 'Profile'

export function UnusedTickets() {
  const { t } = useTranslation()
  const { handleClose, handleOpen, isOpen } = useTogglePopup()
  const { unusedTickets, hasError, isLoading } = useGetUnusedTickets()
  const { handleKeyDown } = useA11yKeydown(handleOpen)

  const Counter = useMemo(() => {
    if (!unusedTickets?.length) {
      return null
    }

    return (
      <CountTickets>
        <Text variant="captionMedium" color="mainText2">
          {unusedTickets?.length || 0}
        </Text>
      </CountTickets>
    )
  }, [unusedTickets])

  const renderSpinner = useCallback(() => {
    if (!isLoading) {
      return Counter
    }

    return <Spinner type="spinout" size="small" />
  }, [isLoading, Counter])

  const handleOpenModal = useCallback(() => {
    if (hasError) {
      return
    }

    handleOpen()
  }, [handleOpen, hasError])

  return (
    <>
      <Container
        tabIndex={0}
        aria-label={t(`Accessibility.ButtonTapToSelect`, { label: t(`${i18nBase}.UnusedTickets`) })}
        onKeyDown={handleKeyDown}
        onClick={handleOpenModal}>
        <Text variant="body" color="mainText">
          {t(`${i18nBase}.UnusedTickets`)}
        </Text>
        <Details>
          <Block marginRight={10}>{renderSpinner()}</Block>
          <Icon name="chevronRightPWA" />
        </Details>
      </Container>
      <UnusedTicketsModal
        isLoading={isLoading}
        unusedTickets={unusedTickets}
        onClose={handleClose}
        isVisible={isOpen}
      />
    </>
  )
}
