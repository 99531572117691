import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { dateFormat, deleteTimezone, isoToDate } from '@fiji/utils/dates'
import type { MeetingInfoSummaryValueObject } from '@etta/modules/meeting/core/value-objects/meeting-info-summary.value-object'
import { Container, Body, Main, LeftSide, RightSide } from './meeting-information-card.styled'

export type MeetingInformationCardProps = {
  meeting: MeetingInfoSummaryValueObject
}

const i18nBase = 'Meeting.MeetingInformation'

export const MeetingInformationCard = ({ meeting }: MeetingInformationCardProps) => {
  const { t } = useTranslation()
  const {
    brandConfigurationStore: { isAppleTheme },
  } = useBrandConfigurationContext()
  return (
    <Container>
      <Body>
        <Main>
          <LeftSide>
            <Icon size={32} name="calendarPWA" color={isAppleTheme ? 'duskBlue' : 'plum'} />
          </LeftSide>
          <RightSide>
            <Text variant="title2" color={isAppleTheme ? 'duskBlue' : 'plum'}>
              {meeting.name}
            </Text>
            <Block marginTop={2} marginBottom={4}>
              <Text variant="subHeadStrong" color="mainText">
                {meeting.location}
              </Text>
              <Text variant="subHeadMedium" color="bodyText">
                &nbsp;{t(i18nBase + '.Between')}&nbsp;
              </Text>
              <Text variant="subHeadStrong" color="mainText">
                {t(i18nBase + '.Dates', {
                  start: dateFormat(isoToDate(deleteTimezone(meeting.dates.startDate)), 'MMM d'),
                  end: dateFormat(isoToDate(deleteTimezone(meeting.dates.endDate)), 'MMM d'),
                })}
              </Text>
            </Block>
            <Text variant="subHeadMedium" color="bodyText">
              {t(i18nBase + '.PolicyNote')}
            </Text>
          </RightSide>
        </Main>
      </Body>
    </Container>
  )
}
