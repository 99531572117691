import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import type { Props } from '../../types'
import { FooterContainer, ClearButton, ApplyButton } from './footer-mobile-styled'

export function FooterMobile({ onClearClick, onApplyClick, isApplyDisabled }: Props) {
  const { t } = useTranslation()

  return (
    <Modal.Footer>
      <FooterContainer>
        <ClearButton btnType="tertiary" onClick={onClearClick}>
          {t('CarRentalFilters.Buttons.ClearAll')}
        </ClearButton>
        <ApplyButton onClick={onApplyClick} fullWidth disabled={isApplyDisabled}>
          {t('CarRentalFilters.Buttons.ApplyFilters')}
        </ApplyButton>
      </FooterContainer>
    </Modal.Footer>
  )
}
