import styled from 'styled-components'

export const StopsLine = styled.div<{ alignSelf: string }>`
  flex: 1;
  position: relative;
  margin: 10px 8px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  align-self: ${(p) => p.alignSelf};
  display: flex;
  align-items: center;
`

export const StopsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  gap: 10px;
`

export const StopsDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.borderDark};
`
