import styled from 'styled-components'

export const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
`

export const CollectHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 12px;
`

export const WarningWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.error1};
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`
