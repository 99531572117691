import { useMemo } from 'react'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import type { ComplianceMessage, ContinuityMessage, OverlappingTrip } from '@fiji/graphql/types'
import type { Alert } from '@etta/components/dynamic-site-messages/types'
import { generateId } from '@fiji/utils/generate-id'

type Props = {
  complianceMessages?: ComplianceMessage | null
  continuityMessages?: ContinuityMessage[] | null
  overlappingTrips?: OverlappingTrip[] | null
}

export function useWarningAlerts({ complianceMessages }: Props) {
  const { t } = useTranslation()

  const warns = useMemo(() => {
    return complianceMessages && R.pathOr([], ['warns'])(complianceMessages)
  }, [complianceMessages])

  const warnings: Alert[] = []

  warns?.forEach((warning: string) => {
    if (warning) {
      warnings.push({
        id: generateId('alert-for-warning', warning),
        header: '',
        text: `<p>${t(`ComplianceMessages.${warning}`, warning)}</p>`,
      })
    }
  })

  return {
    warningAlerts: warnings,
  }
}
