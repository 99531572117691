import * as R from 'ramda'
import { MAXIMUM_RAIL_SEARCH_SEGMENT_CARRIERS } from '@fiji/constants'
import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { getNameAndTrainNumber } from './get-name-and-train-number'

export function mapTwoNameAndTrainNumber(
  segments: SearchRailLegSubSegmentEntity[],
): [string, string][] {
  return R.pipe(
    R.take(MAXIMUM_RAIL_SEARCH_SEGMENT_CARRIERS),
    R.map(getNameAndTrainNumber),
  )(segments)
}
