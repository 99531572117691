import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'

type Props = {
  slot?: ReactNode
}
export function BlockWrapper({ slot }: Props) {
  if (!slot) {
    return null
  }

  return (
    <Block marginHorizontal={16} marginBottom={16}>
      {slot}
    </Block>
  )
}
