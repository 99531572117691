import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FeatureFlags } from '@fiji/graphql/types'

type FeatureFlagsState = {
  values: Partial<FeatureFlags>
}

export function getFeatureFlagsInitialState(): FeatureFlagsState {
  return {
    values: {},
  }
}

const slice = createSlice({
  name: 'feature-flags',
  initialState: getFeatureFlagsInitialState(),
  reducers: {
    updateFeatureFlagsValues(
      state,
      { payload }: PayloadAction<{ values: FeatureFlagsState['values'] }>,
    ) {
      state.values = payload.values
    },
  },
})

export const {
  reducer: featureFlagsReducer,
  actions: { updateFeatureFlagsValues },
} = slice
