import { useTranslation } from 'react-i18next'
import type { Rate, Segment, TrainLegFragment } from '@fiji/graphql/types'
import { RouteType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { SegmentSpecificUnit } from '../segment-specific-unit'
import { useRailCostLabelAndName } from './use-rail-cost-label-and-name'

type Props = {
  railFragment?: TrainLegFragment | null
  railSegment?: Segment | null
  isReturnSegment: boolean
}

export function RailCostLabelAndName({ railFragment, railSegment, isReturnSegment }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PaymentSummary.'

  const { cost, originAddress, destinationAddress, departureDateTime } =
    useRailCostLabelAndName({
      segment: railFragment || railSegment?.trainLeg,
    }) || {}

  const isOpenReturn = railFragment?.routeType === RouteType.OpenReturn
  const zeroRate: Rate | null = cost
    ? {
        primary: {
          amount: 0,
          currency: cost.primary.currency,
        },
      }
    : null

  if (isReturnSegment) {
    return (
      <>
        <SegmentSpecificUnit
          voiceLabel={`${originAddress} - ${destinationAddress}`}
          cost={cost}
          labelSlot={
            <>
              {destinationAddress} <Icon name="flightReturnArrowPWA" size="small" /> {originAddress}
            </>
          }
        />
        <SegmentSpecificUnit labelSlot={departureDateTime} />
      </>
    )
  }

  return (
    <>
      <SegmentSpecificUnit
        voiceLabel={`${originAddress} - ${destinationAddress}`}
        cost={cost}
        labelSlot={
          <>
            {originAddress} <Icon name="flightArrowPWA" size="small" /> {destinationAddress}
          </>
        }
      />
      <SegmentSpecificUnit labelSlot={departureDateTime} />
      {isOpenReturn && (
        <>
          <SegmentSpecificUnit
            voiceLabel={`${destinationAddress} - ${originAddress}`}
            cost={zeroRate}
            labelSlot={
              <>
                {originAddress} <Icon name="flightReturnArrowPWA" size="small" />
                &nbsp;
                {destinationAddress}
              </>
            }
          />
          <SegmentSpecificUnit labelSlot={t(i18nBase + 'Rail.OpenReturnText')} />
        </>
      )}
    </>
  )
}
