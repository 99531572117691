import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

export const ErrorDetailsText = styled(Text)`
  text-align: center;
  width: 300px;
`

export const RetryButton = styled(Button)`
  padding: 6px 66px;
`

export const ReloadErrorBlock = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: auto;
`
