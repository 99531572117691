import styled from 'styled-components'

export const Container = styled.div<{ hasRail?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 576px;
  margin: 0 auto;
  ${({ hasRail }) =>
    hasRail
      ? `
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  `
      : `border-radius: 14px;`}
  padding: 24px 24px 21px;
  background-color: ${(p) => p.theme.colors.white};
`
