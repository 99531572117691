import styled, { css } from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Wrapper = styled.main`
  flex: 1;
  padding-bottom: 24px;
  background-color: ${(props) => props.theme.colors.background};
`

export const Content = styled.div<{ isMobile?: boolean }>`
  margin-left: auto;
  margin-right: auto;

  ${(p) =>
    p.isMobile
      ? css`
          padding: 0 16px;
          margin-top: -32px;
        `
      : css`
          width: 720px;
          margin-top: -40px;
        `};
`

export const MobilityCallToActionWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`
