import { ButtonContainer, ButtonWrapper, Line } from './close-button-styled'

type Props = {
  onClose?: (e: boolean) => void
  className?: string
}

export function CloseButton({ className, onClose }: Props) {
  return (
    <ButtonContainer className={className}>
      <ButtonWrapper
        data-tracking-id="close-button"
        onClick={() => {
          onClose && onClose(false)
        }}>
        <Line />
        <Line />
      </ButtonWrapper>
    </ButtonContainer>
  )
}
