import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { useHotelDetailsEcoCheckModal } from '../../use-hotel-details-eco-check-modal'
import type { LayoutProps } from '../../types'
import {
  FlightDetailsEcoCheckModalDesktopHeader,
  HotelDetailsEcoCheckModalDesktopImage,
  HotelDetailsEcoCheckModalDesktopUpperBody,
  HotelDetailsEcoCheckModalDesktopLowerBody,
} from './components'

export function HotelDetailsEcoCheckModalDesktop({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageSustainabilityScore,
  sustainabilityScore,
  equivalences,
  certifications,
}: LayoutProps) {
  const { deviationLevel, kgOfEmissions, hotelCertifications } = useHotelDetailsEcoCheckModal({
    averageSustainabilityScore,
    sustainabilityScore,
    tonnesOfEmissions,
    certifications,
  })

  return (
    <Modal
      position="right"
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<FlightDetailsEcoCheckModalDesktopHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <HotelDetailsEcoCheckModalDesktopUpperBody
          sustainabilityScore={sustainabilityScore}
          averageSustainabilityScore={averageSustainabilityScore}
          deviationLevel={deviationLevel}
        />
        <HotelDetailsEcoCheckModalDesktopImage deviationLevel={deviationLevel} />
        <HotelDetailsEcoCheckModalDesktopLowerBody
          hotelCertifications={hotelCertifications}
          equivalences={equivalences}
          kgOfEmissions={kgOfEmissions}
          deviationLevel={deviationLevel}
        />
      </Modal.Body>
    </Modal>
  )
}
