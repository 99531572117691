import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { Button } from '@etta/ui/button'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import type { LayoutProps } from '../../types'

const i18nPath = 'Delegate.'

export const DelegateModalMobile = ({
  scrollContainerRef,
  anchorSlot,
  isModalVisible,
  handleClose,
  handleConfirm,
  isConfirmButtonDisabled,
  delegatesSlot,
  searchLabel,
  searchSlot,
  confirmationDialog,
}: LayoutProps) => {
  const { t } = useTranslation()

  const title = t(i18nPath + 'SelectTraveler')

  return (
    <>
      <Modal isVisible={isModalVisible} handleModalVisibility={handleClose}>
        <Modal.Title aria-label={title}>{title}</Modal.Title>
        <Modal.Body ref={scrollContainerRef}>
          {anchorSlot}
          <Block aria-label={searchLabel} paddingHorizontal={16} paddingBottom={16}>
            {searchSlot}
          </Block>
          <Separator />
          <Block>{delegatesSlot}</Block>
        </Modal.Body>
        <Modal.Footer padding="normal" withBorder>
          <Button
            fullWidth
            onClick={handleConfirm}
            disabled={isConfirmButtonDisabled}
            aria-label={t(i18nPath + 'SwitchButtonLabel')}>
            {t(i18nPath + 'SwitchButton')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={t(i18nPath + 'SwitchConfirmation.Title')}
        content={t(i18nPath + 'SwitchConfirmation.Description')}
        confirmButtonText={t(i18nPath + 'SwitchConfirmation.SwitchButton')}
        declineButtonText={t(i18nPath + 'SwitchConfirmation.CancelButton')}
        onConfirm={confirmationDialog.handleConfirmMobile}
        onDecline={confirmationDialog.handleClose}
      />
    </>
  )
}
