import type { MutableRefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { mergeRefs } from '@fiji/utils/merge-refs'
import type { Hotel } from '@fiji/graphql/types'
import { useEttaMapContext } from '@etta/modules/etta-map/interface/use-etta-map.context'
import { useAppleMapContext } from '@etta/modules/etta-map/ui/apple-map/apple-map.context'
import type { EdgeVertical } from './types'

type Args = {
  parentRef: MutableRefObject<HTMLDivElement | null>
  hotel: Hotel
  onClose: () => void
}

export function useHotelCardBlock({ hotel, parentRef, onClose }: Args) {
  const hotelRef = useRef(hotel)
  const {
    ettaMapStore: { isAppleMap },
  } = useEttaMapContext()
  const { mapInstance } = useAppleMapContext()

  const isHotelChangedRef = useRef(true)
  useEffect(() => {
    isHotelChangedRef.current = hotelRef.current.id === hotel.id
  }, [hotel.id])

  hotelRef.current = hotel
  const { ref: clickableRef } = useClickOutside(() => {
    if (hotel.id !== hotelRef.current.id) {
      return
    }
    if (isAppleMap && mapInstance) {
      mapInstance.selectedAnnotation = null
    }
    onClose()
  })

  const [edgeHorizontal, setEdgeHorizontal] = useState<number>(0)
  const [edgeVertical, setEdgeVertical] = useState<EdgeVertical>('top')

  const positionRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isAppleMap) {
        return
      }
      if (!isHotelChangedRef.current) {
        return
      }
      isHotelChangedRef.current = false
      if (!node) {
        return
      }
      const parentRect = parentRef.current?.getBoundingClientRect()
      if (!parentRect) {
        return
      }
      const elementRect = node.getBoundingClientRect()
      const topDiff = elementRect.top - parentRect.top
      setEdgeVertical(topDiff < 0 ? 'bottom' : 'top')

      const leftDiff = elementRect.left - parentRect.left
      if (leftDiff < 0) {
        setEdgeHorizontal(-leftDiff)
        return
      }
      const rightDiff = elementRect.right - parentRect.right
      if (rightDiff > 0) {
        setEdgeHorizontal(-rightDiff)
        return
      }
      setEdgeHorizontal(0)
    },
    [parentRef, isAppleMap],
  )

  const mergedRef = mergeRefs([clickableRef, positionRef])

  return {
    mergedRef,
    edgeVertical,
    edgeHorizontal,
  }
}
