import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { Rate } from '@fiji/graphql/types'
import { TripStatus } from '@fiji/graphql/types'
import { TripCancellationDate } from '@etta/components/trip-cancellation-date/trip-cancellation-date'
import {
  Cost,
  IconText,
  Segment,
  Text as StyledText,
  TripCostSection,
  CanceledMessageContainer,
} from './trip-cost-styled'

type Props = {
  type?: TripStatus | null
  cost?: Rate | null
  onClick: () => void
  cancellationTime?: string | null
}

export function TripCost({ type, onClick, cost, cancellationTime }: Props) {
  const { t } = useTranslation()
  const i18Base = 'PostBooking.TripDetails'
  const { mainCost } = formatRate(cost)

  return (
    <TripCostSection
      tabIndex={0}
      onClick={onClick}
      aria-label={t(i18Base + 'TripCostAriaLabel', { cost: mainCost })}>
      <Block isFlexBlock marginHorizontal={10} marginBottom={12} aria-hidden>
        {type === TripStatus.Cancelled && cancellationTime && (
          <CanceledMessageContainer>
            <TripCancellationDate date={cancellationTime} />
          </CanceledMessageContainer>
        )}
        {type === TripStatus.Completed && (
          <Text variant="subHeadStrong" color="bodyText">
            {t(i18Base + '.WishHeader')}
          </Text>
        )}
      </Block>
      <Segment aria-hidden>
        <IconText>
          <Icon name="infoFilledPWA" data-tracking-id="trip-cost-icon" />
          <StyledText data-tracking-id="trip-cost-text">{t(i18Base + '.TripCost')}</StyledText>
        </IconText>
        <Cost data-tracking-id="amount-text">{mainCost}</Cost>
      </Segment>
    </TripCostSection>
  )
}
