import type { HotelReview } from '@fiji/graphql/types'
import { Row } from './hotel-reviews-block-styled'
import { HotelReviewItem } from './hotel-review-item'
import { HotelReviewsHeader } from './hotel-reviews-header'

type Props = {
  rating?: number
  totalReviewCount?: number
  reviewList?: HotelReview[]
}

export function HotelReviewsBlock({ rating, totalReviewCount, reviewList }: Props) {
  return (
    <>
      <Row>
        <HotelReviewsHeader
          totalReviews={reviewList?.length}
          totalGuestsReviews={totalReviewCount}
          rating={rating}
        />
      </Row>
      {reviewList?.map(({ date, title, userRating, content }) => (
        <Row key={date + title}>
          <HotelReviewItem date={date} rating={userRating} title={title} description={content} />
        </Row>
      ))}
    </>
  )
}
