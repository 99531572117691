import styled from 'styled-components'

export const Background = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.bodyText};
  color: ${(props) => props.theme.colors.borderDark};
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
