import { useTranslation } from 'react-i18next'
import type { TripCostSummary } from '@fiji/graphql/types'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import type { SafetyCheckFlight, FlightSafetyCheckDetails, FlightEmission } from '@fiji/types'
import { FareRules } from '@etta/components/fare-rules/fare-rules'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { useFlightDetailsModal } from './use-flight-details-modal'
import { FareSeatMaps as MobileFareSeatMaps } from './fare-seat-maps'
import { BaggageInformationModal } from './baggage-information-modal'
import { FlightDetailsModalLayout } from './layout'

type Props = {
  onClose: () => void
  flight: FlightSegmentEntity
  tripCost: TripCostSummary
  isTripExist: boolean
  isChangeAllowed?: boolean
  itineraryId: string
  flightId: string
  flightSegments: FlightSegmentEntity[]
  flightIndex: number
  isFlightFareAttributesLoading?: boolean
  isFlightFareAttributesError?: boolean
  flightSafetyChecks?: SafetyCheckFlight[]
  flightSafetyCheckDetails?: FlightSafetyCheckDetails[]
  refetchFlightFareAttributes?: () => void
  isFlightSafetyCheckEnabled?: boolean
  flightEmission: FlightEmission
  isMultiCity?: boolean
  shouldShowAirportChangeBadge?: boolean
  isWebFare?: boolean
  isNDCFare?: boolean
}

export function FlightDetailsModalContent({
  tripCost,
  flightSegments,
  flightId,
  isTripExist,
  isChangeAllowed,
  itineraryId,
  flight,
  onClose,
  flightIndex,
  isFlightFareAttributesLoading,
  isFlightFareAttributesError,
  flightSafetyChecks,
  flightSafetyCheckDetails,
  isFlightSafetyCheckEnabled,
  isMultiCity,
  refetchFlightFareAttributes,
  flightEmission,
  shouldShowAirportChangeBadge,
  isWebFare,
  isNDCFare,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'FlightDetailsModal.'
  const {
    adaptiveFlightData,
    seatMapToggle,
    baggageModalToggle,
    changeModalToggle,
    fareRulesModalToggle,
    removeDialogToggle,
    handleChangeFlights,
    flightCostSegment,
    handleRemoveFlight,
    viewStateParams,
    baggageInfo,
    bookingCodes,
    fares,
    unusedTicketRate,
    handleSeatMapOpen,
  } = useFlightDetailsModal({
    tripCost,
    flightSegments,
    flightId,
    itineraryId,
    flight,
    onClose,
    flightIndex,
  })

  const { flight: adaptiveFlight, airlinesNames } = adaptiveFlightData

  return (
    <>
      <FlightDetailsModalLayout
        flight={flight}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        flightSafetyCheckDetails={flightSafetyCheckDetails}
        adaptiveFlight={adaptiveFlight}
        airlinesNames={airlinesNames}
        flightSafetyChecks={flightSafetyChecks}
        flightCostSegment={flightCostSegment}
        unusedTicket={unusedTicketRate}
        isTripExist={isTripExist}
        isFlightFareAttributesLoading={isFlightFareAttributesLoading}
        isFlightFareAttributesError={isFlightFareAttributesError}
        isFlightSafetyCheckEnabled={isFlightSafetyCheckEnabled}
        isChangeAllowed={isChangeAllowed}
        onClose={onClose}
        onBaggageOpenClick={baggageModalToggle.handleOpen}
        onSeatMapOpenClick={handleSeatMapOpen}
        onDetailsClick={fareRulesModalToggle.handleOpen}
        onChangeClick={changeModalToggle.handleOpen}
        onCancelClick={removeDialogToggle.handleOpen}
        refetchFlightFareAttributes={refetchFlightFareAttributes}
        flightEmission={flightEmission}
        itineraryId={itineraryId}
        isMultiCity={isMultiCity}
        flightLegPosition={flight.position}
        isWebFare={isWebFare}
        isNDCFare={isNDCFare}
        baggageInfo={baggageInfo}
      />
      <MobileFareSeatMaps
        itineraryId={itineraryId}
        isOpen={seatMapToggle.isOpen}
        onClose={seatMapToggle.handleClose}
        segments={flight.segments}
        adaptiveFlight={adaptiveFlight}
      />
      <BaggageInformationModal
        baggageInfo={baggageInfo}
        onClose={baggageModalToggle.handleClose}
        isOpen={baggageModalToggle.isOpen}
      />
      <FareRules
        isOpen={fareRulesModalToggle.isOpen}
        onClose={fareRulesModalToggle.handleClose}
        segmentId={adaptiveFlight.segmentId}
        itineraryId={itineraryId}
        airlineLogo={adaptiveFlight.airlineLogos.rectangle?.[0]}
        airlineName={airlinesNames[0]}
        bookingCodes={bookingCodes}
        fares={fares}
      />
      <ConfirmationDialog
        title={t(i18Base + 'ChangeFlights.Title')}
        content={t(i18Base + 'ChangeFlights.InfoText', { count: flightSegments.length })}
        isOpen={changeModalToggle.isOpen}
        onDecline={changeModalToggle.handleClose}
        onConfirm={handleChangeFlights}
        confirmButtonText={t(i18Base + 'ChangeFlights.ConfirmButtonText')}
        declineButtonText={t(i18Base + 'ChangeFlights.DeclineButtonText')}
      />
      <ConfirmationDialog
        isOpen={removeDialogToggle.isOpen}
        onDecline={removeDialogToggle.handleClose}
        onConfirm={() => {
          handleRemoveFlight()
          removeDialogToggle.handleClose()
        }}
        title={t(i18Base + 'RemoveFlights')}
        content={t(i18Base + 'RemoveFlightsExplanation')}
        confirmButtonText={t(i18Base + 'RemoveConfirmButton')}
        declineButtonText={t(i18Base + 'RemoveDeclineButton')}
      />
      <ViewStateModal
        viewState={viewStateParams.viewState}
        onDone={() => {
          removeDialogToggle.handleClose()
          viewStateParams.onClose()
        }}
        title={viewStateParams.title}
      />
    </>
  )
}
