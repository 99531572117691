import styled from 'styled-components'
import { Dialog } from '@etta/ui/dialog'

export const DialogWrapper = styled(Dialog)`
  border-radius: 1.6rem;
`

export const Container = styled.div`
  padding: 2rem 0;
  height: 36.2rem;
  display: flex;
  flex-direction: column;
`
