import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CarPriceBlockDesktop, CarPriceBlockMobile } from './layout'

export function CarPriceBlock(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarPriceBlockMobile {...props} />}
      desktopSlot={<CarPriceBlockDesktop {...props} />}
    />
  )
}
