import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 279px;
  max-height: 426px;
  padding-bottom: 10px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 3;
`

export const Header = styled.div`
  padding: 12px 16px;
`

export const Label = styled.div`
  margin-bottom: 2px;
`

export const List = styled.div`
  overflow-y: auto;
`

export const Option = styled.button`
  display: block;
  background: none;
  border: none;
  margin: 0;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  text-align: left;
`
