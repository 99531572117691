import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import type { Maybe } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type {
  FlightGroupedSegmentValueObject,
  TripDetailsAirTicketValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { TripStatus } from '@etta/modules/post-booking-list/core/value-object/trips-list.value-object'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ROUTES } from '@fiji/routes'
import { useShareSegment } from '../../../use-trip-details/use-share-segment'
import { useGetFlightInfoLabels } from './use-get-flight-info-labels'

type HandleModifyFlightArgs = {
  isReturnChangeAction: boolean
}

type Params = {
  segment: FlightGroupedSegmentValueObject
  dateToShow: string
  isUpcoming: boolean
  isOnHold: boolean
  isTripInProgress?: boolean
  onCancel?: () => void
  onModify?: (args?: HandleModifyFlightArgs) => void
  onSeatMapOpen?: (segmentLegId?: Maybe<string>) => void
  airTicket?: TripDetailsAirTicketValueObject
}

const i18Base = 'PostBooking.TripDetails'
const CHANGE_SEAT_TRANSLATION = 'FlightCard.ChangeSeats'
const SELECT_SEAT_TRANSLATION = 'FlightCard.SelectSeats'
const CANCEL_TRANSLATION = 'PostBooking.TripDetails.Cancel'
const MODIFY_TRANSLATION = 'PostBooking.TripDetails.Modify'
export function useFlightSegment({
  segment,
  dateToShow,
  isUpcoming,
  isOnHold,
  isTripInProgress,
  onCancel,
  onSeatMapOpen,
  onModify,
  airTicket,
}: Params) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isChangingReturnFlightEnabled } = featureFlagsStore.flags
  const history = useHistory()
  const { handleShare } = useShareSegment({ segment })
  const { postBookingTripStore, postBookingCancellationStore } = usePostBookingContext()
  const { trip } = postBookingTripStore
  const { canceledTripSegmentToggle } = postBookingCancellationStore
  const { t } = useTranslation()
  const typeTitle = t('PostBooking.FlightDetails.CancelType')

  const flightStatus = segment.segments?.[0]?.status
  const isFlightCancelled = flightStatus === TripStatus.Cancelled

  const onViewSupport = useCallback(() => {
    history.push(ROUTES.support.main)
  }, [history])

  const {
    departureTime,
    arrivalTime,
    airlineLabel,
    operatedByText,
    seat,
    infoText,
    flightItemTitle,
    flightTime,
    flightInfo,
    segmentPreview,
    headerDate,
  } = useGetFlightInfoLabels({ segment, airTicket })

  const segmentsCount = segment.segments?.length || 0

  const airportCity =
    (segment.segments && segment.segments[segmentsCount - 1]?.arrival?.airportCity) ||
    segment.destination

  const titleCity = [t(i18Base + '.FlightTo'), airportCity].join(' ')

  const titleAirportCode = [t(i18Base + '.FlightTo'), segment.destination].join(' ')

  const ariaLabel = t(`${i18Base}.SegmentAriaLabel`, {
    label: `${titleAirportCode}. ${dateToShow}`,
  })

  const dataTexts = [operatedByText, infoText].filter(Boolean)

  const returnFlightModal = useTogglePopup()
  const notAllowedFlightModal = useTogglePopup()

  const isOneWayFlight = postBookingTripStore.flightSegments.length === 1

  const isChangingReturnFlight = useMemo(() => {
    if (!isChangingReturnFlightEnabled) {
      return false
    }

    return postBookingTripStore.isReturnFlightChangeable
  }, [isChangingReturnFlightEnabled, postBookingTripStore.isReturnFlightChangeable])

  const isChangingEntireFlight = postBookingTripStore.isEntireFlightChangeable

  const handlerModify = useCallback(() => {
    if (isFlightCancelled) {
      return canceledTripSegmentToggle.handleOpen
    }

    const checkIsReturnFlightChangeable =
      !isOneWayFlight && postBookingTripStore.isReturnFlightChangeable && segment.departureDateTime
        ? isFuture(new Date(segment.departureDateTime))
        : false

    const isFlightChangeable = !isTripInProgress || checkIsReturnFlightChangeable
    const isFlightChangeAllowed = trip.supportedActions.isFlightChangeAllowed && !trip.isMultiCity

    switch (true) {
      case !isFlightChangeable:
      case !isFlightChangeAllowed:
        return notAllowedFlightModal.handleOpen
    }

    if (isOneWayFlight) {
      return onModify
    }

    return returnFlightModal.handleOpen
  }, [
    isOneWayFlight,
    isTripInProgress,
    isFlightCancelled,
    notAllowedFlightModal.handleOpen,
    canceledTripSegmentToggle.handleOpen,
    onModify,
    returnFlightModal.handleOpen,
    segment.departureDateTime,
    postBookingTripStore.isReturnFlightChangeable,
    trip.supportedActions.isFlightChangeAllowed,
    trip.isMultiCity,
  ])

  const handleSeatMapOpen = useCallback(() => {
    if (trip.supportedActions.isFlightChangeAllowed && !isTripInProgress) {
      return onSeatMapOpen?.(segment.legId)
    }

    return notAllowedFlightModal.handleOpen()
  }, [
    isTripInProgress,
    notAllowedFlightModal,
    onSeatMapOpen,
    segment.legId,
    trip.supportedActions.isFlightChangeAllowed,
  ])

  const handleCancel = useCallback(() => {
    if (isFlightCancelled) {
      return canceledTripSegmentToggle.handleOpen
    }

    if (trip.supportedActions.isFlightCancelAllowed) {
      return onCancel
    }

    return notAllowedFlightModal.handleOpen
  }, [
    isFlightCancelled,
    notAllowedFlightModal.handleOpen,
    canceledTripSegmentToggle.handleOpen,
    onCancel,
    trip.supportedActions.isFlightCancelAllowed,
  ])

  const changeSeatButtonLabel = seat ? t(CHANGE_SEAT_TRANSLATION) : t(SELECT_SEAT_TRANSLATION)

  const buttons = [
    {
      visible: isUpcoming && !isOnHold,
      title: changeSeatButtonLabel,
      isDisabled: isFlightCancelled,
      onClick: handleSeatMapOpen,
    },
    {
      visible: isUpcoming && !isOnHold,
      title: t(MODIFY_TRANSLATION),
      onClick: handlerModify(),
    },
    {
      visible: isUpcoming,
      title: t(CANCEL_TRANSLATION),
      onClick: handleCancel(),
    },
  ]

  const shouldShowAirportChangeBadge: boolean = !!segment?.segments?.some(
    (segment) => segment.isDepartAirportDifferentFromConnecting,
  )

  return {
    titleCity,
    titleAirportCode,
    ariaLabel,
    airlineLabel,
    flightStatus,
    departureTime,
    arrivalTime,
    dataTexts,
    buttons,
    typeTitle,
    handleShare,
    isReturnFlightModalOpen: returnFlightModal.isOpen,
    handleReturnFlightModalClose: returnFlightModal.handleClose,
    isChangingReturnFlight,
    isChangingEntireFlight,
    isAllowedFlightModalOpen: notAllowedFlightModal.isOpen,
    handleNotAllowedFlightModalClose: notAllowedFlightModal.handleClose,
    onViewSupport,
    operatedByText,
    shouldShowAirportChangeBadge,
    headerDate,
    flightItemTitle,
    flightTime,
    flightInfo,
    segmentPreview,
  }
}
