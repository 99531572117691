import styled from 'styled-components'

export const SeatRowContainerWrapper = styled.div`
  padding: 8px 14px;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 100%;
`

export const OverWing = styled.div<{ isRight?: boolean }>`
  position: absolute;
  top: 1px;
  bottom: -1px;
  background-color: ${(props) => props.theme.colors.seatMap.charcoal7};
  transform: skewY(${(props) => (props.isRight ? 15 : -195)}deg);
  width: 10px;
  ${(props) => (props.isRight ? 'left: 12px' : 'right: 12px')};
`

export const NearWindow = styled.div<{ isEconomyClass: boolean }>`
  width: 2px;
  height: ${(props) => (props.isEconomyClass ? 20 : 30)}px;
  background-color: ${(props) => props.theme.colors.seatMap.charcoal5};
  border: 1px solid ${(props) => props.theme.colors.seatMap.charcoal5};
  border-radius: 2px;
  position: absolute;
  top: 28%;
  left: 50%;
  margin-left: -1px;
`

type WallProps = {
  isRight?: boolean
  isOverWing: boolean
}

export const Wall = styled.div<WallProps>`
  width: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.seatMap.charcoal7};

  ${(props) => (props.isRight ? 'right: -12px' : 'left: -12px')};
`

export const ExitSeat = styled.div<{ isRight?: boolean }>`
  position: absolute;
  top: ${(props) => (props.isRight ? -4 : 0)}px;
  width: 10px;
  ${(props) => (props.isRight ? 'right: 10px' : 'left: 10px;')};
  ${(props) => (props.isRight ? 'transform: rotate(180deg);' : '')};
`
