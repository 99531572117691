import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '@fiji/store'
import { updateMainNavigationOngoingBookingFlow } from '@fiji/store/main-navigation'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

type Args = {
  exploreLink: string
}
// matches any string that starts with /explore/ and has more chars. ex - /explore/air-search
const regex = new RegExp(/^\/explore\/.*$/)

export function useGlobalExploreRouteFlowTracker({ exploreLink }: Args) {
  const { activeTripStore } = useReviewTripContext()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const isOnGoingBookingFlow =
      regex.test(exploreLink) || regex.test(pathname) || !!activeTripStore.activeTripId

    dispatch(updateMainNavigationOngoingBookingFlow(isOnGoingBookingFlow))
  }, [dispatch, exploreLink, pathname, activeTripStore.activeTripId])
}
