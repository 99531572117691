import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { Text } from '@etta/ui/text'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { useAppSelector } from '@fiji/store'
import { useUserContext } from '@etta/modules/user'
import GooglePlayLogo from '../assets/google-play.png'
import AppStoreLogo from '../assets/app-store.png'
import LinkedInLogo from '../assets/linkedin-logo.png'
import YoutubeLogo from '../assets/youtube-logo.png'
import TwitterLogo from '../assets/twitter-logo.png'
import BlogLogo from '../assets/blog-logo.png'
import {
  Container,
  IconContainer,
  Wrapper,
  TopSection,
  DownloadLinksContainer,
  GooglePlayLink,
  AppStoreLink,
  Line,
  SocialNetworkLinksContainer,
  SocialNetworkBlock,
  EttaLogo,
  CopyrightSection,
  PrivacyLink,
  SocialNetworkLinks,
  Image,
  EttaGoLogo,
  DeemLogo,
} from './desktop-footer-simple-styles'

export function DesktopFooterSimple() {
  const { t } = useTranslation()
  const { privacyPolicy } = useAppSelector(displayConfigurationSelector)
  const currentYear = new Date().getFullYear()
  const { userStore } = useUserContext()

  const { brandConfigurationStore } = useBrandConfigurationContext()
  const { isAppleTheme } = brandConfigurationStore

  const renderLogo = useMemo(() => {
    if (isAppleTheme) {
      return <EttaLogo name="appleLogoIconPWA" size="large" color="mainText2" />
    }

    if (userStore.isSelfServiceSite) {
      return <EttaGoLogo name="ettaLogoGoPWA" color="mainText2" size="large" />
    }

    return <DeemLogo color="white" name="deemLogoPWA" size="large" />
  }, [isAppleTheme, userStore.isSelfServiceSite])

  return (
    <Container data-tracking-id="desktop-footer">
      <Wrapper>
        <TopSection>
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Deem"
            href="https://www.deem.com/">
            <IconContainer>{renderLogo}</IconContainer>
          </a>

          {!isAppleTheme && (
            <>
              <DownloadLinksContainer>
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link to Google Play"
                  data-tracking-id="link-google-play"
                  href="https://play.google.com/store/apps/details?id=com.deem.app.twa">
                  <GooglePlayLink>
                    <Image src={GooglePlayLogo} alt="Google Play" />
                  </GooglePlayLink>
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Link to App Store"
                  data-tracking-id="link-app-store"
                  href="https://apps.apple.com/us/app/etta-for-business-travel/id1477594097">
                  <AppStoreLink>
                    <Image src={AppStoreLogo} alt="App Store" />
                  </AppStoreLink>
                </a>
              </DownloadLinksContainer>
              <SocialNetworkLinksContainer>
                <SocialNetworkLinks>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link to Deem Blog"
                    data-tracking-id="link-deem-blog"
                    href="https://www.deem.com/resources/blog">
                    <SocialNetworkBlock>
                      <Image src={BlogLogo} alt="Blog" />
                    </SocialNetworkBlock>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link to Deem Twitter"
                    data-tracking-id="link-deem-twitter"
                    href="https://twitter.com/deem">
                    <SocialNetworkBlock>
                      <Image src={TwitterLogo} alt="Twitter" />
                    </SocialNetworkBlock>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link to Deem Youtube"
                    data-tracking-id="link-deem-youtube"
                    href="https://www.youtube.com/user/deeminc">
                    <SocialNetworkBlock>
                      <Image src={YoutubeLogo} alt="Youtube" />
                    </SocialNetworkBlock>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link to Deem Linkedin"
                    data-tracking-id="link-deem-linkedin"
                    href="https://www.linkedin.com/company/deeminc/">
                    <SocialNetworkBlock>
                      <Image src={LinkedInLogo} alt="LinkedIn" />
                    </SocialNetworkBlock>
                  </a>
                </SocialNetworkLinks>
              </SocialNetworkLinksContainer>
            </>
          )}
        </TopSection>

        <Line />

        <CopyrightSection>
          <Text variant="captionStrongCaps" color="bodyText1">
            COPYRIGHT © {currentYear} DEEM INC. ALL RIGHTS RESERVED |{' '}
            <PrivacyLink
              target="_blank"
              rel="noreferrer"
              aria-label="Link to Terms"
              data-tracking-id="link-terms-of-use"
              href="https://www.deem.com/privacy#Terms-of-Use">
              <Text variant="captionStrongCaps" color="bodyText1">
                {t('Profile.TermsOfUse')}
              </Text>
            </PrivacyLink>{' '}
            |{' '}
            <PrivacyLink
              target="_blank"
              rel="noreferrer"
              aria-label="Link to Privacy Policy"
              data-tracking-id="link-privacy-policy"
              href={privacyPolicy.url}>
              <Text variant="captionStrongCaps" color="bodyText1">
                {privacyPolicy.label || t('Profile.PrivacyPolicy')}
              </Text>
            </PrivacyLink>
          </Text>
        </CopyrightSection>
      </Wrapper>
    </Container>
  )
}
