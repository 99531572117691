/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import type { ValidationScheme } from './types'

const defaultDeps: unknown[] = []

export function useScheme<T = ValidationScheme>(cb: () => T, deps = defaultDeps): T {
  return useMemo(() => {
    return cb()
  }, deps)
}
