import styled, { css } from 'styled-components'
import { headline } from '@fiji/style'
import { Button } from '@etta/ui/button'

const SectionWithBorder = css`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Section = styled.div<{ withBorder?: boolean }>`
  padding: 24px 16px;
  ${({ withBorder }) => withBorder && SectionWithBorder}
`
export const SectionTitle = styled.h2`
  margin-top: 0;
  ${headline}
  margin-bottom:12px;
`

export const ButtonContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`
export const ClearButton = styled(Button)`
  width: 35%;
`

export const ApplyButton = styled(Button)`
  width: 60%;
`
