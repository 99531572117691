import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace TravelPreferencesErrors {
  export const GetTravelPreferencesErrorResponse = createErrorClass(
    'Travel Preferences  Response error',
  )
  export const GetTravelPreferencesUnexpectedError = createErrorClass(
    'Travel Preferences Unexpected error',
  )
}

export type TravelPreferencesErrorsInstances = InstanceType<
  typeof TravelPreferencesErrors[keyof typeof TravelPreferencesErrors]
>
