import { useTranslation } from 'react-i18next'

import { Menu } from '@etta/ui/menu'
import { Icon } from '@etta/ui/icon'
import { RailSortByEnum } from '@etta/modules/rail/infra/types'
import { Container, HiddenSpan } from './rail-sort-styled'
import { SORTING_OPTION_TRANSLATION_MAPPER } from './constants'

type Props = {
  activeOption: RailSortByEnum
  onOptionChange: (option: RailSortByEnum) => void
}

const i18nBase = 'SearchResults.'

export function RailSort({ activeOption, onOptionChange }: Props) {
  const { t } = useTranslation()

  const sortingOptions = [
    { id: RailSortByEnum.Cost, name: t(SORTING_OPTION_TRANSLATION_MAPPER[RailSortByEnum.Cost]) },
    {
      id: RailSortByEnum.Departure,
      name: t(SORTING_OPTION_TRANSLATION_MAPPER[RailSortByEnum.Departure]),
    },
    {
      id: RailSortByEnum.Arrival,
      name: t(SORTING_OPTION_TRANSLATION_MAPPER[RailSortByEnum.Arrival]),
    },
  ]

  return (
    <Menu<RailSortByEnum>
      openByClickOnEl={
        <Container>
          <HiddenSpan>{t(i18nBase + 'Header.SortBy')}</HiddenSpan>
          <Icon name="sortPWA" size="normal" color="mainText" />
        </Container>
      }
      shouldCloseOnClickOutside
      title={t(i18nBase + 'Header.SortBy')}
      options={sortingOptions}
      activeOptionId={activeOption}
      activeOptionIcon={<Icon name="tickPWA" size="small" color="primary" />}
      onOptionSelect={onOptionChange}
    />
  )
}
