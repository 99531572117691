import { useMemo } from 'react'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { TripCardMobile } from './mobile'
import { TripCardDesktop } from './desktop'

export function TripCardLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  const forcedScreenType = useMemo(() => {
    switch (props.view) {
      case 'slim':
        return ScreenType.Mobile
      case 'wide':
        return ScreenType.Desktop
      default:
        return null
    }
  }, [props.view])

  return (
    <MediaLayout
      mobileSlot={<TripCardMobile {...props} />}
      desktopSlot={<TripCardDesktop {...props} />}
      forceScreenType={
        forcedScreenType ||
        (featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile)
      }
    />
  )
}
