import { Inject, Store } from '@etta/di'
import i18n from '@fiji/i18n'
import { Validator } from '@etta/interface/services/validator'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import type { EmployeeInformationFieldsSettings } from '@etta/modules/settings/interface/stores/profile-field-settings/account-information-fields'
import { AccountActivationValidatorMaker } from '../validator-maker'
import { UserProfileStore } from './user-profile.store'

const i18nBase = 'Settings.AccountInformation.Modal'

export type Mis = {
  mis1: string
  mis2: string
  mis3: string
  mis4: string
  mis5: string
  mis6: string
  mis7: string
}

@Store()
export class EmployeeInfoStore {
  private readonly _employeeInformationValidator: ReturnType<
    typeof AccountActivationValidatorMaker.prototype.makeEmployeeInformationValidator
  >

  private _additionalInformationValidator: Validator = new Validator({})

  constructor(
    @Inject() private readonly fieldSettingsStore: FieldSettingsStore,
    @Inject() private readonly userProfileStore: UserProfileStore,
  ) {
    const validatorMaker = new AccountActivationValidatorMaker(this.fieldSettingsStore)
    this._employeeInformationValidator = validatorMaker.makeEmployeeInformationValidator()
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setField(
    key: Parameters<typeof this._employeeInformationValidator.onFieldChange>[0],
    value: string | boolean | null,
  ) {
    this._employeeInformationValidator.onFieldChange(key, value)
  }

  getDefaultValue(field: keyof typeof EmployeeInformationFieldsSettings) {
    return this.fieldSettingsStore.getEmployeeInfoDefaultValue(field)
  }

  get fieldSettings() {
    return this.fieldSettingsStore.fieldsSettings
  }

  get misFields() {
    return [...Array(7).keys()].map((num) => ({
      name: `mis${num + 1}`,
      label: this.fieldSettingsStore.getFieldLabel(
        `mis${num + 1}` as keyof Mis,
        FieldSettingsCategory.EmployeeInformation,
        i18n.t(`${i18nBase}.MISField${num + 1}`),
      ),
      value: this._employeeInformationValidator.values[`mis${num + 1}` as keyof Mis] || '',
    }))
  }

  get employeeStatusValue() {
    const {
      values: { isActiveInCompany },
    } = this._employeeInformationValidator

    return (
      i18n.t(
        `Settings.AccountInformation.Modal.Statuses.${isActiveInCompany ? 'Active' : 'Inactive'}`,
      ) || '-'
    )
  }

  get validators() {
    return {
      employeeInfoValidator: this._employeeInformationValidator,
      additionalInformationValidator: this._additionalInformationValidator,
    }
  }

  get shouldRenderedEmployeeInfoFields() {
    const employeeInfoSettings = this.fieldSettingsStore.fieldsSettings[
      FieldSettingsCategory.EmployeeInformation
    ]

    const employeeInfoSettingsFields = Object.keys(employeeInfoSettings)

    return employeeInfoSettingsFields.some(
      (key) => employeeInfoSettings[key].isRenderedOnActivationPage,
    )
  }
  get shouldRenderedAdditionalEmployeeInformationFields() {
    return Boolean(
      this.userProfileStore.userProfile?.employeeInformation.additionalInformation.length,
    )
  }
}
