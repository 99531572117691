import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import { getCountryDecoded } from '@fiji/utils/country'
import { Button } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap'
import { ListItem } from '@etta/ui/list-item'
import type { Props } from '../../layout'
import { useTravelerDocuments } from '../../../use-traveler-documents'
import { getFormattedDate } from '../../../get-formatted-date'

export function TravelerDocumentsListDesktop({ documents }: Props) {
  const { t } = useTranslation()
  const blurredText = '123456789, Exp: 00/2022'

  const {
    i18Base,
    handleTravelerDocumentOpen,
    handleTravelerDocumentEdit,
    isAppleLockSettingsEnabled: isDisabled,
    isDelegatedOrImpersonated: isBlurred,
  } = useTravelerDocuments()

  return (
    <Block paddingHorizontal={32}>
      <Block paddingLeft={20} paddingVertical={24}>
        <Text variant="subHeadMedium" color="bodyText">
          {`${t(i18Base + 'SubHeading')}`}
        </Text>
      </Block>
      <Separator color="borderLight" margin="0px 20px" isAutoWidth />
      <div>
        {documents &&
          documents.map((document) => (
            <>
              <ListItem
                title={
                  <Text variant="subHeadStrong" color="mainText" isBlock>
                    {getCountryDecoded(document.countryCode)}
                  </Text>
                }
                footNote={
                  'expirationDate' in document && document.expirationDate ? (
                    <Swap
                      is={isBlurred || isFuture(new Date(document.expirationDate))}
                      isSlot={
                        <Text variant="footnoteMedium" color="bodyText" isBlurred={isBlurred}>
                          {isBlurred
                            ? blurredText
                            : `${document.number}, ${t(i18Base + 'Exp')}: ${getFormattedDate(
                                document.expirationDate,
                              )}`}
                        </Text>
                      }>
                      <Text variant="footnoteMedium" color="bodyText" isBlurred={isBlurred}>
                        {`${document.number},`}
                      </Text>{' '}
                      <Text variant="footnoteMedium" color="error" isBlurred={isBlurred}>
                        {`${t(i18Base + 'ExpiredOn')} ${getFormattedDate(document.expirationDate)}`}
                      </Text>
                    </Swap>
                  ) : (
                    <Text variant="footnoteMedium" color="bodyText" isBlurred={isBlurred}>
                      {`${document.number}`}
                    </Text>
                  )
                }
                icon={!isDisabled ? 'editPWA' : undefined}
                iconColor="primary"
                iconSize="small"
                onIconClick={() => handleTravelerDocumentEdit(document)}
              />
              <Separator color="borderLight" margin="0px 20px" isAutoWidth />
            </>
          ))}
      </div>
      {!isDisabled && documents.length < 5 && (
        <Block paddingVertical={16} paddingHorizontal={20}>
          <Button
            variant="link"
            size="small"
            paddings={false}
            onClick={() => handleTravelerDocumentOpen(null)}>
            {`+ ${t(i18Base + 'AddNumber')}`}
          </Button>
        </Block>
      )}
    </Block>
  )
}
