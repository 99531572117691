import { Inject, Service } from '@etta/di'
import { SearchRideHailStore } from '@etta/modules/ride-hail/interface/store/search-ride-hail.store'
import { SelectExpenseCodeStore } from '@etta/modules/ride-hail/interface/store/select-expense-code.store'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import { ExpenseCodePage, RenderState } from '@etta/modules/ride-hail/interface/types'

@Service()
export class SelectExpenseCodeService {
  constructor(
    @Inject()
    private readonly rideHailSearchStore: SearchRideHailStore,
    @Inject()
    private readonly selectExpenseCodeStore: SelectExpenseCodeStore,
    @Inject()
    private readonly expenseFlowStore: ExpenseFlowStore,
  ) {}

  goBack() {
    this.rideHailSearchStore.setIsFetchingRideHailConfig(false)
    this.rideHailSearchStore.setRenderState(this.expenseFlowStore.previousRenderState)
    this.selectExpenseCodeStore.dropStore()

    this.expenseFlowStore.setShouldCloseExpenseCodeModal(true)
  }

  handleContinue() {
    if (!this.expenseFlowStore.expenseConfig.memoEnabled) {
      this.rideHailSearchStore.setRenderState(RenderState.review)
      this.expenseFlowStore.setShouldCloseExpenseCodeModal(true)
      return
    }

    this.expenseFlowStore.setCurrentPage(ExpenseCodePage.AddExpenseCode)
  }

  goToEnterCustomExpenseCode() {
    if (!this.expenseFlowStore.expenseConfig.customExpenseEnabled) {
      return
    }

    this.expenseFlowStore.setCurrentPage(ExpenseCodePage.EnterCustomExpenseCode)
  }

  handleSearchTextValueChanged(newValue: string) {
    this.selectExpenseCodeStore.setSearchExpenseCodeValue(newValue)

    if (newValue === '') {
      this.selectExpenseCodeStore.setExpenseCodes(this.expenseFlowStore.expenseConfig.expenseCodes)
      return
    }

    this.selectExpenseCodeStore.setExpenseCodes(
      this.expenseFlowStore.expenseConfig.expenseCodes.filter((expenseCode) => {
        return expenseCode.id.toLowerCase().includes(newValue.toLowerCase())
      }),
    )
  }

  handleExpenseCodeSelected(expenseCodeId: string) {
    this.selectExpenseCodeStore.setSelectedExpenseCodeId(expenseCodeId)
    this.selectExpenseCodeStore.setIsContinueButtonDisabled(expenseCodeId === '')
  }
}
