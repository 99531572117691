import { useTheme } from '@fiji/hooks/use-theme'

export default function PolicyIcon() {
  const theme = useTheme()
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0077 38C22.3009 38 22.7175 37.8728 23.1187 37.6662C32.0065 33.0243 35 30.4809 35 24.7899V13.1058C35 11.0551 34.2902 10.2444 32.5774 9.49727C30.9727 8.81371 25.4178 6.82663 23.8902 6.31793C23.3039 6.12717 22.5941 6 22.0077 6C21.4059 6 20.7116 6.14307 20.1252 6.31793C18.5822 6.81073 13.0273 8.82961 11.4226 9.49727C9.70979 10.2285 9 11.0551 9 13.1058V24.7899C9 30.4809 12.1015 32.8336 20.8967 37.6662C21.2979 37.8887 21.7145 38 22.0077 38ZM20.295 29.1297C19.6469 29.1297 19.1531 28.8276 18.6902 28.2394L14.9869 23.6453C14.6629 23.2479 14.524 22.8664 14.524 22.4213C14.524 21.531 15.2184 20.7998 16.0979 20.7998C16.6071 20.7998 17.0083 21.0065 17.4095 21.531L20.2487 25.1555L26.4516 14.9975C26.822 14.3775 27.2849 14.0755 27.8249 14.0755C28.689 14.0755 29.4605 14.6955 29.4605 15.6016C29.4605 15.9831 29.2908 16.4123 29.0439 16.7938L21.838 28.2235C21.4677 28.8117 20.9122 29.1297 20.295 29.1297Z"
        fill={theme.colors.success}
      />
    </svg>
  )
}
