import { useTranslation } from 'react-i18next'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { useScreenType } from '@fiji/modes'
import type { NoResultsReason } from '@fiji/types'
import { WrapperNoResults } from './hotel-list-no-results-styled'

type Props = {
  isFiltersApplied?: boolean
  onButtonClick?: () => void
  noResultsReason: NoResultsReason | null
}

const getSubTitlei18n = (
  areFiltersApplied?: boolean,
  noResultsReason?: NoResultsReason | null,
): string => {
  if (noResultsReason === 'too-many-filters' || areFiltersApplied) {
    return '.TooManyHotelFiltersSubTitle'
  }

  if (noResultsReason === 'restricted-country') {
    return '.ErrorHotelRestrictedCountrySubTitle'
  }

  return '.NoResultHotelSubTitle'
}

export function HotelListNoResults({ isFiltersApplied, noResultsReason, onButtonClick }: Props) {
  const { t } = useTranslation()
  const type = useScreenType()
  const subTitle = t(
    `SearchSegments.NoResultErrorScreen${getSubTitlei18n(isFiltersApplied, noResultsReason)}`,
  )

  return (
    <WrapperNoResults screenType={type}>
      <NoResultSearchScreen
        type="hotel"
        title={t('SearchSegments.NoResultErrorScreen.NoHotelsResultTitle')}
        subTitle={subTitle}
        buttons={[
          {
            title: t(
              `SearchSegments.NoResultErrorScreen${
                noResultsReason === 'too-many-filters' ? '.ResetAllFilters' : '.ChangeSearch'
              }`,
            ),
            onClick: onButtonClick,
            buttonVariant: 'solid',
          },
        ]}
      />
    </WrapperNoResults>
  )
}
