import { Inject, Service } from '@etta/di'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import i18n from '@fiji/i18n'
import { UserService } from '@etta/modules/user'
import type { ListItemValueObject } from '@etta/modules/settings/ui/components/icon-items-list/types'
import { PassportAdapter } from '@etta/modules/booking/infra/passport/passport.adapter'
import type { UpdateVisaInputValueObject } from '../../core/value-objects/update-visa-input.value-object'
import type { RemoveVisaInputValueObject } from '../../core/value-objects/remove-visa-input.value-object'
import type { UpdatePassportInputValueObject } from '../../core/value-objects/update-passport-input.value-object'
import type { RemovePassportInputValueObject } from '../../core/value-objects/remove-passport-input.value-object'
import type { UpdateNationalIdCardInputValueObject } from '../../core/value-objects/update-national-id-card-input.value-object'
import type { RemoveNationalIdCardInputValueObject } from '../../core/value-objects/remove-national-id-card-input.value-object'
import { getFullCountry } from '../../ui/emergency-contact/get-address-from-profile'
import { BaseSettingsService } from '../services/base.service'
import { BaseSettingsStore } from '../stores/base.store'
import { SettingsAdapter } from '../../infra/settings.adapter'

@Service()
export class TravelDocumentsService {
  constructor(
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
    @Inject()
    private readonly userService: UserService,
    @Inject()
    private readonly passportAdapter: PassportAdapter,
  ) {}

  getTravelDocuments() {
    return {
      passports: this.getPassports(),
      visas: this.getVisas(),
      nationalIdCards: this.getNationalIdCards(),
      redressNumbers: this.getRedressNumbers(),
      knownTravelerNumbers: this.getKnownTravelerNumbers(),
    }
  }

  getTravelDocumentsViewMode() {
    const i18nBase = 'Settings.TravelDocuments.Modal.'
    const {
      passports,
      visas,
      nationalIdCards,
      redressNumbers,
      knownTravelerNumbers,
    } = this.getTravelDocuments()

    return {
      passports: passports.map(
        ({ number, country, expirationDate, id }): ListItemValueObject => ({
          number: number,
          label: country.label,
          value: `${number}, Exp: ${expirationDate.label}`,
          id: id,
          warningText: this.checkExpiredStatus(expirationDate.value)
            ? i18n.t(i18nBase + 'PassportExpirationWarning')
            : undefined,
        }),
      ),
      visas: visas.map(
        ({ number, country, visaType, expirationDate, id }): ListItemValueObject => ({
          number: number,
          label: country.label,
          id: id,
          value: `${number}, ${visaType && visaType + ', '}Exp: ${expirationDate.label}`,
          warningText: this.checkExpiredStatus(expirationDate.value)
            ? i18n.t(i18nBase + 'VisaExpirationWarning')
            : undefined,
        }),
      ),
      nationalIdCards: nationalIdCards.map(
        ({ number, country, expirationDate, id }): ListItemValueObject => ({
          number: number,
          label: country.label,
          id: id,
          value: `${number}, Exp: ${expirationDate.label}`,
          warningText: this.checkExpiredStatus(expirationDate.value)
            ? i18n.t(i18nBase + 'NationalIdCardExpirationWarning')
            : undefined,
        }),
      ),
      knownTravelerNumbers: knownTravelerNumbers.map(
        ({ number, country, id, expirationDate }): ListItemValueObject => ({
          number: number,
          label: country.label,
          id: id,
          value: `${number}, Exp: ${expirationDate.label}`,
        }),
      ),
      redressNumbers: redressNumbers.map(
        ({ number, country }): ListItemValueObject => ({
          number: number,
          label: country.label,
          id: number,
          value: `${number}`,
        }),
      ),
    }
  }

  getPassports() {
    const { passports } = this.baseSettingsService.getStructuredProfile()

    if (!passports) {
      return []
    }

    return passports.map((passport) => ({
      id: passport.id || null,
      number: passport.unmaskedNumber || passport.number || '',
      country: {
        code: passport.country || '',
        label: getFullCountry(passport.country) || '',
      },
      expirationDate: {
        value: passport.expirationDate,
        label: this.getDateLabel(passport.expirationDate),
      },
      issueDate: {
        value: passport.issueDate,
        label: this.getDateLabel(passport.issueDate),
      },
      issuingCountry: {
        code: passport.issuingCountry || '',
        label: getFullCountry(passport.issuingCountry) || '',
      },
      preExpirationAlert: passport.preExpirationAlert || '',
    }))
  }

  getVisas() {
    const { visas } = this.baseSettingsService.getStructuredProfile()

    if (!visas) {
      return []
    }

    return visas.map((visa) => ({
      id: visa.id || null,
      number: visa.number || '',
      visaType: visa.visaType || '',
      country: {
        code: visa.country || '',
        label: getFullCountry(visa.country) || '',
      },
      expirationDate: {
        value: visa.expirationDate,
        label: this.getDateLabel(visa.expirationDate),
      },
      preExpirationAlert: visa.preExpirationAlert || '',
    }))
  }

  getNationalIdCards() {
    const { nationalIdCards } = this.baseSettingsService.getStructuredProfile()

    if (!nationalIdCards) {
      return []
    }

    return nationalIdCards.map((card) => ({
      id: card.id || '',
      number: card.number || '',
      country: {
        code: card.country || '',
        label: getFullCountry(card.country) || '',
      },
      issueDate: {
        value: card.issueDate,
        label: this.getDateLabel(card.issueDate),
      },
      expirationDate: {
        value: card.expirationDate,
        label: this.getDateLabel(card.expirationDate),
      },
      preExpirationAlert: card.preExpirationAlert || '',
    }))
  }

  getRedressNumbers() {
    const { additionalDocumentsPreferences } = this.baseSettingsService.getStructuredProfile()
    if (!additionalDocumentsPreferences?.redressNumbers) {
      return []
    }

    const { redressNumbers } = additionalDocumentsPreferences

    return redressNumbers.map((redressNumber) => ({
      id: redressNumber.number || '',
      number: redressNumber.number || '',
      country: {
        code: redressNumber.country || '',
        label: getFullCountry(redressNumber.country) || '',
      },
    }))
  }

  getKnownTravelerNumbers() {
    const { additionalDocumentsPreferences } = this.baseSettingsService.getStructuredProfile()
    if (!additionalDocumentsPreferences?.knownTravelerNumbers) {
      return []
    }

    const { knownTravelerNumbers } = additionalDocumentsPreferences

    return knownTravelerNumbers.map((knownTravelerNumber) => ({
      id: knownTravelerNumber.id,
      number: knownTravelerNumber.number || '',
      country: {
        code: knownTravelerNumber.country || '',
        label: getFullCountry(knownTravelerNumber.country) || '',
      },
      expirationDate: {
        value: knownTravelerNumber.expirationDate || '',
        label: this.getDateLabel(knownTravelerNumber.expirationDate) || '',
      },
      preExpirationAlert: knownTravelerNumber.preExpirationAlert || '',
    }))
  }

  getKTNDisabledCountries() {
    const { additionalDocumentsPreferences } = this.baseSettingsService.getStructuredProfile()

    if (!additionalDocumentsPreferences?.knownTravelerNumbers) {
      return
    }

    return additionalDocumentsPreferences.knownTravelerNumbers.map((ktn) => ktn.country || '')
  }

  getRedressDisabledCountries() {
    const { additionalDocumentsPreferences } = this.baseSettingsService.getStructuredProfile()

    if (!additionalDocumentsPreferences?.redressNumbers) {
      return
    }

    return additionalDocumentsPreferences.redressNumbers.map((redress) => redress.country || '')
  }

  getDateLabel(date?: string | null): string {
    if (!date) {
      return ''
    }

    return date.slice(5, 7) + '/' + date.slice(0, 4)
  }

  checkExpiredStatus(date?: string | null): boolean {
    if (!date) {
      return false
    }
    const currentDate = new Date()
    const expirationDate = new Date(date)

    return expirationDate < currentDate
  }

  async updateVisa({
    input,
    handleClose,
  }: {
    input: UpdateVisaInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.updateVisa({ visa: input })

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }

  async removeVisa({
    input,
    handleClose,
  }: {
    input: RemoveVisaInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.removeVisa(input)

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }

  async updatePassport({
    input,
    handleClose,
  }: {
    input: UpdatePassportInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.updatePassport({ passport: input })

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }

  async removePassport({
    input,
    handleClose,
  }: {
    input: RemovePassportInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.passportAdapter.removePassport(input)

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }

  async updateNationalIdCard({
    input,
    handleClose,
  }: {
    input: UpdateNationalIdCardInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.updateNationalIdCard({ nationalIdCard: input })

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()
      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }

  async removeNationalIdCard({
    input,
    handleClose,
  }: {
    input: RemoveNationalIdCardInputValueObject
    handleClose: () => void
  }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.removeNationalIdCard(input)

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.userService.reloadUser()
  }
}
