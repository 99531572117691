import styled from 'styled-components'

export const SelectedDateTitleContainerDesktop = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.span`
  width: 6ch;
`
