import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { ScreenType } from '@fiji/modes'

export const Container = styled.div<{ screenType?: ScreenType }>`
  ${(p) =>
    p.screenType === ScreenType.Desktop
      ? css`
          margin: 24px 168px 0 54px;
        `
      : ''}
`

export const Section = styled.div<{ screenType?: ScreenType }>`
  padding: 24px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  ${(p) =>
    p.screenType === ScreenType.Desktop
      ? css`
          padding-top: 0;
        `
      : ''}

  &:last-child {
    border: 0;
  }
`

export const SectionLogo = styled.div<{ screenType?: ScreenType }>`
  padding: 0 16px;
  ${(p) =>
    p.screenType === ScreenType.Desktop
      ? css`
          margin-bottom: 24px;
        `
      : ''}
  img {
    display: block;
    height: 24px;
  }
`

export const BaggageFeeInfo = styled.div`
  padding: 10px 0 6px;
  margin: 0 16px;
  ${footnoteMedium}
`

export const BaggageSeparator = styled.div`
  margin: 5px 16px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`
