import type { HotelFragment, Segment } from '@fiji/graphql/types'
import { SegmentSpecificUnit } from '../segment-specific-unit'
import { useHotelCostLabelAndCost } from './use-hotel-cost-label-and-name'

type Props = {
  hotelFragment?: HotelFragment | null
  hotelSegment?: Segment | null
}

export function HotelCostLabelAndName({ hotelFragment }: Props) {
  const { cost, label } =
    useHotelCostLabelAndCost({
      hotelFragment,
    }) || {}

  if (!cost || !label) {
    return <></>
  }

  return <SegmentSpecificUnit cost={cost} labelSlot={label} />
}
