import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { InputButton } from '@etta/ui/input-button'
import { CabinClass } from '@etta/components/cabin-class/cabin-class'
import type { Props } from '../../types'
import { useCabinClass } from '../../use-cabin-class'

const i18Base = 'TripPlanner.BaseSearch'

export function CabinClassSectionMobile({ cabinClass, onChange }: Props) {
  const { t } = useTranslation()
  const { label, value, cabinClassModal } = useCabinClass({ cabinClass })

  return (
    <>
      <Block paddingHorizontal={16} paddingVertical={6} marginBottom={6}>
        <InputButton
          value={value}
          label={label}
          aria-label={t(i18Base + '.Input.CabinClassAriaLabel', { value, label })}
          onClick={cabinClassModal.handleOpen}
        />
      </Block>

      <CabinClass
        pickedCabinClass={cabinClass}
        onChange={onChange}
        isVisible={cabinClassModal.isOpen}
        onClose={cabinClassModal.handleClose}
      />
    </>
  )
}
