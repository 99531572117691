import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CancelTripDataValueObject } from '@etta/modules/post-booking/core/value-objects'
import { InfoWrapper, SegmentContainer, TextWrapper } from '../cancel-trip-modal-styled'
import { SidebarRideHailSegment as RideHailSegment } from '../ride-hail-segment'

type Props = {
  rideHailSegmentData: CancelTripDataValueObject[]
}
const i18nBase = 'PostBooking.RideHailDetails.'

export function SidebarRideHailCancelSection({ rideHailSegmentData }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Text variant="headline" color="mainText">
        {t(i18nBase + 'CancelTripTitle')}
      </Text>
      <Block marginTop={10}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'CancelTripContent')}
        </Text>
      </Block>
      <Block marginTop={16}>
        <SegmentContainer>
          {rideHailSegmentData.map((rideHailSegment) => (
            <RideHailSegment
              key={rideHailSegment.id}
              value={rideHailSegment.id.toString()}
              name={rideHailSegment.name}
              cost={rideHailSegment.cost}
              dropOffName={rideHailSegment.dropOffAddress}
              pickUpDateTime={rideHailSegment.startDate}
            />
          ))}
        </SegmentContainer>
      </Block>
      <Block marginTop={10} marginBottom={16}>
        <InfoWrapper>
          <Icon name="infoOutlinePWA" color="bodyText" />
          <TextWrapper>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'CancelTripInfo')}
            </Text>
          </TextWrapper>
        </InfoWrapper>
      </Block>
    </>
  )
}
