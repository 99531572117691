import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled(Block)<{
  isRtp: boolean
  isTrainSegment: boolean
  isNextSegmentTrain: boolean
}>`
  padding: 16px;
  margin: 0;
  background: ${(p) => (p.isRtp ? p.theme.colors.white : p.theme.colors.background4)};
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;

  ${(p) =>
    !p.isTrainSegment &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${(p) =>
    !p.isNextSegmentTrain &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`

export const DurationWrapper = styled(Block)`
  margin-left: auto;
`
