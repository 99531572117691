import * as yup from 'yup'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import type { RequiredStringSchema } from 'yup/lib/string'
import type { RequiredNumberSchema } from 'yup/lib/number'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import type {
  CarRentalSegmentEntity,
  HotelSegmentEntity,
  FlightSegmentEntity,
  TrainSegmentEntity,
} from '@etta/modules/review-trip/core'
import type { OutOfPolicyReason } from '@fiji/graphql/types'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import type { OopSegmentSelection } from '../types'

type Props = {
  flights: FlightSegmentEntity[]
  hotels: HotelSegmentEntity[]
  carRentals: CarRentalSegmentEntity[]
  rails: TrainSegmentEntity[]
  isPolicyExplanationRequired: boolean
  oopSelections: OopSegmentSelection
  reasons: OutOfPolicyReason[]
  isHotelRequiredForOvernightTrip?: boolean
  isCarRentalRequiredViolation?: boolean
}

export function useOopSchema({
  flights,
  hotels,
  carRentals,
  rails,
  isPolicyExplanationRequired,
  oopSelections,
  reasons,
  isHotelRequiredForOvernightTrip,
  isCarRentalRequiredViolation,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'OutOfPolicyModal.'
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isMod2FlowEnabled } = displayConfigurationStore

  const scheme = useScheme(() => {
    return {
      ...R.pipe(
        () => {
          if (isPolicyExplanationRequired) {
            return {
              policyExplanation: yup
                .string()
                .trim()
                .required(t(i18nBase + 'Errors.NoPolicyExplanationError'))
                .default(oopSelections.description?.trim() || ''),
            }
          }

          return {}
        },
        (scheme) => {
          if (flights.length) {
            const defaultValue = oopSelections.flight
              ? reasons.find((el) => el.reason === oopSelections.flight && el.segment === 'FLIGHT')
                  ?.id
              : null

            const startSchema = isMod2FlowEnabled
              ? yup.string().default((defaultValue as unknown) as string)
              : yup.number().default(defaultValue)

            return {
              ...scheme,
              flightReason: startSchema
                .typeError(t(i18nBase + 'Errors.NoReasonError'))
                .required(t(i18nBase + 'Errors.NoReasonError')),
            }
          }

          return scheme
        },
        (scheme) => {
          if (hotels.length || isHotelRequiredForOvernightTrip) {
            const defaultValue = oopSelections.hotel
              ? reasons.find((el) => el.reason === oopSelections.hotel && el.segment === 'HOTEL')
                  ?.id
              : null

            const startSchema = isMod2FlowEnabled
              ? yup.string().default((defaultValue as unknown) as string)
              : yup.number().default(defaultValue)
            return {
              ...scheme,
              hotelReason: startSchema
                .typeError(t(i18nBase + 'Errors.NoReasonError'))
                .required(t(i18nBase + 'Errors.NoReasonError')),
            }
          }

          return scheme
        },
        (scheme) => {
          if (carRentals.length || isCarRentalRequiredViolation) {
            const defaultValue = oopSelections.carRentals
              ? reasons.find(
                  (el) => el.reason === oopSelections.carRentals && el.segment === 'CAR_RENTAL',
                )?.id
              : null
            const startSchema = isMod2FlowEnabled
              ? yup.string().default((defaultValue as unknown) as string)
              : yup.number().default(defaultValue)
            return {
              ...scheme,
              carRentalReason: startSchema
                .typeError(t(i18nBase + 'Errors.NoReasonError'))
                .required(t(i18nBase + 'Errors.NoReasonError')),
            }
          }

          return scheme
        },
        (scheme) => {
          if (rails.length) {
            const defaultValue = oopSelections.rail
              ? reasons.find((el) => el.reason === oopSelections.rail)?.id
              : null
            const startSchema = isMod2FlowEnabled
              ? yup.string().default((defaultValue as unknown) as string)
              : yup.number().default(defaultValue)
            return {
              ...scheme,
              railReason: startSchema
                .typeError(t(i18nBase + 'Errors.NoReasonError'))
                .required(t(i18nBase + 'Errors.NoReasonError')),
            }
          }

          return scheme
        },
      )(),
    }
  }, [
    isPolicyExplanationRequired,
    flights,
    hotels,
    carRentals,
    rails,
    oopSelections,
    isHotelRequiredForOvernightTrip,
    isMod2FlowEnabled,
  ]) as {
    policyExplanation: RequiredStringSchema<string | undefined>
    flightReason: RequiredNumberSchema<number | undefined>
    hotelReason: RequiredNumberSchema<number | undefined>
    carRentalReason: RequiredNumberSchema<number | undefined>
    railReason: RequiredNumberSchema<number | undefined>
  }

  const { submit, values, onFieldChange, errors } = useValidation(scheme)

  return {
    submit,
    values,
    onFieldChange,
    errors,
  }
}
