import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { LONDON_UNDERGROUND_TUBE } from '@fiji/constants'

export function getRailLabels(segments: SearchRailLegSubSegmentEntity[]): string[] {
  return segments.map((segment) => {
    if (
      segment.transportationMode &&
      (segment.transportationMode === RailTransportationModeEnum.Unspecified ||
        segment.transportationMode === RailTransportationModeEnum.Metro)
    ) {
      return LONDON_UNDERGROUND_TUBE
    }

    if (
      segment.transportationMode &&
      segment.transportationMode !== RailTransportationModeEnum.Train
    ) {
      return (
        segment.transportationMode.toString().slice(0, 1) +
        segment.transportationMode.slice(1).toLowerCase()
      )
    }

    return `${segment.carrier?.name} ${segment.trainId}`
  })
}
