import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 32px 0 0 0;
  padding: 0;
`

export const ListItem = styled.li`
  width: 50%;
  list-style: none;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.mainText2};
  ${footnoteMedium}
`
