import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProps } from './types'
import { HotelAddressCardLayoutMobile } from './mobile'
import { HotelAddressCardLayoutDesktop } from './desktop'

export function HotelAddressCardLayout({ ...props }: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<HotelAddressCardLayoutMobile {...props} />}
      desktopSlot={<HotelAddressCardLayoutDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
