import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { DesktopScreenByFeatureFlag } from '@etta/components/desktop-screen-by-feature-flag/desktop-screen-by-feature-flag'
import type { LayoutProps } from '../types'
import { HotelResultPageDesktop } from './desktop'
import { HotelResultPageMobile } from './mobile'

export function HotelResultPageLayout({ children }: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={
        <DesktopScreenByFeatureFlag
          isDesktopLayoutEnabled={featureFlags.isDesktopLayoutHotelFlowEnabled}>
          <HotelResultPageMobile>{children}</HotelResultPageMobile>
        </DesktopScreenByFeatureFlag>
      }
      desktopSlot={<HotelResultPageDesktop>{children}</HotelResultPageDesktop>}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
