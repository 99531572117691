import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { TripListReloadErrorMobile } from './mobile'
import { TripListLayoutErrorDesktop } from './desktop'

export function TripListReloadErrorLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<TripListReloadErrorMobile {...props} />}
      desktopSlot={<TripListLayoutErrorDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
