import { useTranslation } from 'react-i18next'
import { Tooltip } from '@etta/ui/tooltip'
import { Text } from '@etta/ui/text'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Badge } from '../badge'
import { TooltipOuterContainer } from '../../air-badges-components'
import { TooltipTextContainer } from './webfare-styled'

const i18Base = 'SearchSegments.Badges'

export function WebFareTooltipContainer() {
  const { t } = useTranslation()
  return (
    <TooltipTextContainer>
      <Text variant="footnoteStrong" isBlock>
        {t('WebFareDescription.DirectBooking')}:
      </Text>
      <Text variant="footnoteMedium">{t('WebFareDescription.Description')}</Text>
    </TooltipTextContainer>
  )
}

export function WebFareBadge() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (isMobile) {
    return (
      <Badge
        tabIndex={0}
        key="web-fare"
        title={t(i18Base + '.DirectBooking')}
        color="bodyText"
        icon="directBookingIcon"
        data-tracking-id="web-fare-badge"
      />
    )
  }

  return (
    <TooltipOuterContainer>
      <Tooltip slot={<WebFareTooltipContainer />} place="top">
        <Badge
          tabIndex={0}
          key="web-fare"
          title={t(i18Base + '.DirectBooking')}
          color="bodyText"
          icon="directBookingIcon"
          data-tracking-id="web-fare-badge"
        />
      </Tooltip>
    </TooltipOuterContainer>
  )
}
