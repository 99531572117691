import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { RailSort } from '@etta/components/rail-sort/rail-sort'
import { ListResultHeader } from '@etta/components/list-result-header/list-result-header'
import { RailLegType } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { RailSearchResultsContainer, InfoMessage, Container } from './rail-results-styled'
import { RailResultList } from './rail-result-list/rail-result-list'
import { RailPaginationButton } from './rail-pagination-button/rail-pagination-button'
import { RailResultSkeleton } from './rail-result-skeleton'

type Props = {
  railLegType: RailLegType
}

const i18nBase = 'SearchSegments.'

export const RailResults = observer(function RenderRailResult({ railLegType }: Props) {
  const { t } = useTranslation()
  const { railActions, railStore } = useRailContext()
  const {
    featureFlagsStore: { flags },
  } = useFeatureFlagsContext()
  const { sortBy, isError, isCustomTimeReplaced, isLoading, railLegs } = railStore

  if (isError) {
    return (
      <NoResultSearchScreen
        type="train"
        title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.ErrorSubTitle')}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
            onClick: railActions.handleRefetchRequest,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (railLegs && railLegs.length === 0) {
    return (
      <NoResultSearchScreen
        type="train"
        title={t('SearchSegments.NoResultErrorScreen.NoResultTrainTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.NoResultTrainSubTitle')}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
            onClick: railActions.handleRefetchRequest,
          },
        ]}
      />
    )
  }

  if (isLoading) {
    return (
      <RailSearchResultsContainer>
        <ListResultHeader title="" isLoading />
        <RailResultSkeleton />
      </RailSearchResultsContainer>
    )
  }

  return (
    <RailSearchResultsContainer>
      <ListResultHeader
        title={
          railLegType === RailLegType.Departure
            ? t(i18nBase + 'Trains.Title.Departure')
            : t(i18nBase + 'Trains.Title.Return')
        }
        sortSlot={
          flags.isRailSearchSortEnabled ? (
            <RailSort activeOption={sortBy} onOptionChange={railActions.onSortByChange} />
          ) : null
        }
      />
      <>
        {isCustomTimeReplaced && (
          <Block marginVertical={16}>
            <InfoMessage>
              <Block marginRight={4}>
                <Icon name="infoFilledPWA" color="mainText" />
              </Block>
              <Text variant="footnoteMedium" color="mainText">
                {t(i18nBase + 'Info.TimeChanged')}
              </Text>
            </InfoMessage>
          </Block>
        )}
        <Container>
          <RailPaginationButton direction="earlier" railLegType={railLegType} />
          <RailResultList railLegType={railLegType} />
          <RailPaginationButton direction="later" railLegType={railLegType} />
        </Container>
      </>
    </RailSearchResultsContainer>
  )
})
