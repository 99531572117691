import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  BookRideHailWithErrorMutation,
  BookRideHailWithErrorMutationVariables,
} from '@fiji/graphql/hooks'
import { BookRideHailWithErrorDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class RideHailBookDataProvider extends GraphqlDataProvider {
  bookRideHail(input: BookRideHailWithErrorMutationVariables['input']) {
    return this.client.mutate<
      BookRideHailWithErrorMutation,
      BookRideHailWithErrorMutationVariables
    >({
      mutation: BookRideHailWithErrorDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input,
      },
    })
  }
}
