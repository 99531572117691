import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import type { TermsAndConditions } from '@fiji/graphql/types'
import type { FormatTypes } from '../types'
import { Container, LinkContent, LinkContainer } from './travel-fusion-links-content-styled'
import { DirectBookingAcknowledgment } from './direct-booking-acknowledgment'

type Props = {
  termsAndConditions?: Pick<TermsAndConditions, 'title' | 'format' | 'content'>[]
  hasSubTitle?: boolean
  handleGetTermsFormatType: (terms: TermsAndConditions) => FormatTypes | null
}

export function TravelFusionLinksContent({
  termsAndConditions,
  hasSubTitle = true,
  handleGetTermsFormatType,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'TravelFusionLinks.'
  const i18nBaseAccessibility = 'Accessibility.'
  const subTitleText = t(i18nBase + 'SubTitle')
  const tapToViewText = t(i18nBaseAccessibility + 'TapToView')

  return (
    <>
      <Container>
        {hasSubTitle && (
          <Block marginBottom={26}>
            <Text variant="title2" color="mainText" aria-label={subTitleText}>
              {subTitleText}
            </Text>
          </Block>
        )}
        {termsAndConditions?.map((info) => {
          switch (true) {
            case handleGetTermsFormatType(info) === 'URL':
              return (
                <LinkContainer key={info.content}>
                  <Link
                    key={info.content}
                    size="small"
                    href={info.content || '#'}
                    target="_blank"
                    aria-label={`${info.title}, ${tapToViewText}`}>
                    <LinkContent>
                      <Icon name="hyperlinkPWA" />
                      <Block marginLeft={4}>
                        <Text variant="subHeadMedium">{info.title}</Text>
                      </Block>
                    </LinkContent>
                  </Link>
                </LinkContainer>
              )
            case handleGetTermsFormatType(info) === 'TEXT':
              return (
                <Block marginBottom={32} key={info.content} aria-label={info.title || ''}>
                  <Block marginBottom={8}>
                    <Text variant="subHeadStrong" color="mainText">
                      {info.title}
                    </Text>
                  </Block>
                  <Text variant="footnoteMedium" color="bodyText">
                    {info.content}
                  </Text>
                </Block>
              )
            default:
              return null
          }
        })}
      </Container>
      <DirectBookingAcknowledgment />
    </>
  )
}
