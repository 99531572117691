import { useCallback, useMemo } from 'react'
import type { UserSuffix } from '@etta/modules/user'
import { useUserContext } from '@etta/modules/user'
import { useProfileOptions } from '@fiji/hooks/use-profile-options/use-profile-options'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions, UserTitle } from '@fiji/graphql/types'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { dateToIso } from '@fiji/utils/dates/date-to-iso'
import type { ViewState } from '@etta/ui/view-state-modal'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { isoToDate } from '@fiji/utils/dates'
import type { AddressValueObject } from '@etta/core/value-objects'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import { useProfileIncompleteMsg } from './use-profile-incomplete'

type Args = {
  options: TravelerOptions
  selectedPassportId: number | null
  onChangeViewState: (viewState: ViewState) => void
}

const mapAddressForSave = (address: PersonalInfo['address']): AddressValueObject | undefined => {
  if (!address) {
    return
  }

  return {
    ...address,
    city: address.city || '',
  }
}

export function useProfile({ options, selectedPassportId, onChangeViewState }: Args) {
  const { checkoutInfoStore, checkoutInfoTravelerAction } = useCheckoutInfoContext()

  const { userStore } = useUserContext()

  const { purchaseInfoActions } = usePurchaseInfoContext()
  const {
    isUserLoading,
    isUserUpdatingError,
    isUserUpdating,
    profile,
    user,
    isUserError,
  } = userStore

  const personalInfo = useMemo<PersonalInfo | null>(() => {
    return {
      ...checkoutInfoStore.primaryTraveler.travelerInfo,
      dateOfBirth: checkoutInfoStore.primaryTraveler.travelerInfo?.dateOfBirth
        ? isoToDate(checkoutInfoStore.primaryTraveler.travelerInfo?.dateOfBirth)
        : undefined,
      suffix: checkoutInfoStore.primaryTraveler.travelerInfo.suffix as UserSuffix,
      title: checkoutInfoStore.primaryTraveler.travelerInfo.title as UserTitle,
      customerId: user?.delegatedId || user?.customerId || 0,
      companyId: user?.companyId || 0,
    }
  }, [checkoutInfoStore.primaryTraveler.travelerInfo, user])

  const errorMessage = useProfileIncompleteMsg()

  const profileOptions = useProfileOptions({
    genders: profile?.genderOptions,
    suffixes: profile?.suffixOptions,
    titles: profile?.titleOptions,
  })

  const isProfileValid = errorMessage === ''
  const isPassportValid = !!(options.isPassportRequired ? selectedPassportId : true)

  const handleUpdateCheckoutPersonalInfo = useCallback(
    async (data: PersonalInfo) => {
      await delayForSuccess()
      checkoutInfoTravelerAction.setTravelerInfo({
        id: checkoutInfoStore.primaryTraveler.travelerInfo?.id,
        email: data.email || '',
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        office: mapAddressForSave(data.office),
        personalMobile: data.personalMobile,
        phone: data.phone,
        address: mapAddressForSave(data.address),
        dateOfBirth: data.dateOfBirth ? dateToIso(data.dateOfBirth) : undefined,
        gender: data.gender,
        suffix: data.suffix,
        title: data.title,
        knownTravelerNumbers: data.knownTravelerNumbers,
        redressNumbers: data.redressNumbers,
      })
    },
    [checkoutInfoTravelerAction, checkoutInfoStore.primaryTraveler.travelerInfo?.id],
  )

  const onSaveProfile = async (input: PersonalInfo) => {
    purchaseInfoActions.setIsDOBTouched(false)
    purchaseInfoActions.setIsGenderTouched(false)

    const { companyId, customerId } = personalInfo || {}

    if (!companyId || !customerId) {
      return
    }

    try {
      onChangeViewState('saving')
      await handleUpdateCheckoutPersonalInfo(input)
      onChangeViewState('saved')
      await delayForSuccess()
      onChangeViewState('hidden')
      purchaseInfoActions.closeProfileModal()
    } catch {
      onChangeViewState('error')
    }
  }

  const isProfileLoading = isUserLoading

  return {
    onSaveProfile,
    isUserUpdating,
    isUserUpdateError: isUserUpdatingError,
    profileErrorMessage: errorMessage,
    isProfileValid,
    isPassportValid,
    personalInfo,
    isProfileLoading,
    profileError: isUserError,
    passports: profile?.passports,
    profileOptions,
  }
}
