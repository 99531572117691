import { Action, Inject } from '@etta/di'
import { TravelPolicyService } from '../services'

@Action()
export class TravelPolicyAction {
  constructor(
    @Inject()
    private readonly travelPolicyService: TravelPolicyService,
  ) {}

  async loadTravelPolicy() {
    await this.travelPolicyService.loadDisplayConfiguration()
  }
}
