import { useTranslation } from 'react-i18next'
import type { PlainTime } from '@fiji/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates'

export type Props = {
  pickUpDate?: Date
  pickUpTime?: PlainTime
  pickUpTimezoneOffset?: number
  estimatedWaitingTime?: number | null
  isOnDemandRide?: boolean
  isBooked?: boolean
}

const basePath = 'Mobility.ConfirmationPage.'
const DATE_TIME_FORMAT = 'MMM d, h:mm aa'
const DATE_FORMAT = 'MMM d'
const TIME_FORMAT = 'h:mm aa'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60

export function useMobilityRidesDetail({
  pickUpDate,
  pickUpTime,
  pickUpTimezoneOffset,
  estimatedWaitingTime,
  isBooked,
  isOnDemandRide,
}: Props) {
  const { t } = useTranslation()

  const reservedForDate =
    pickUpDate && pickUpTime && !isOnDemandRide
      ? dateFormat(
          new Date(pickUpDate).setHours(pickUpTime.hours, pickUpTime.minutes),
          DATE_TIME_FORMAT,
        )
      : ''

  let bookedOnDate = ''
  if (isOnDemandRide && !isBooked) {
    bookedOnDate = dateFormat(new Date(), DATE_FORMAT) + `, ${t(basePath + 'Now')}`
  } else if (isOnDemandRide) {
    const now = getNowWithTimezoneAdjusted(pickUpTimezoneOffset ?? 0)
    bookedOnDate = dateFormat(now, DATE_TIME_FORMAT)
  }

  let estimatedPickUpTime: string | undefined

  if (pickUpTime) {
    const formattedTime = getFormattedTime(
      { hours: pickUpTime.hours, minutes: pickUpTime.minutes },
      TIME_FORMAT,
    )
    const estWaitingTimeInMin = (estimatedWaitingTime ?? 0) / 60
    estimatedPickUpTime = isOnDemandRide
      ? `${t(basePath + 'MinAway', { minutes: estWaitingTimeInMin })} (${formattedTime})`
      : undefined
  }

  return {
    reservedForDate,
    bookedOnDate,
    estimatedPickUpTime,
  }
}

function getNowWithTimezoneAdjusted(pickUpTimezoneOffsetInMs: number) {
  const now = new Date()
  const localOffset = now.getTimezoneOffset() * MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE
  now.setTime(now.getTime() + localOffset + pickUpTimezoneOffsetInMs)
  return now
}
