import { MediaLayout } from '@etta/ui/media-layout'
import { TripCancellationDate } from '@etta/components/trip-cancellation-date/trip-cancellation-date'
import { TripCancellationDateDesktop } from './trip-cancellation-date-desktop'

type Props = {
  cancellationDate: string
}

export const TripCancellationDateLayout = ({ cancellationDate }: Props) => {
  return (
    <MediaLayout
      mobileSlot={<TripCancellationDate date={cancellationDate} />}
      desktopSlot={<TripCancellationDateDesktop date={cancellationDate} />}
    />
  )
}
