import { observer } from 'mobx-react-lite'
import { starRatingOptions } from '@fiji/constants'
import { Checkbox } from '@etta/ui/checkbox'
import { StarRating } from '@etta/ui/star-rating'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { ScreenType, useScreenType } from '@fiji/modes'
import { FilterSelectionRow } from '../filters-styled'

type Props = {
  isModal?: boolean
}

export const StarFilter = observer(function StarFilter({ isModal = false }: Props) {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()

  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  const withBorder = !isModal
  const withSpace = isNewHotelExposedFiltersEnabled && !isMobile

  return (
    <>
      {starRatingOptions.map((option) => {
        const checked = hotelFiltersStore.isStarRatingSelected(option.value)
        return (
          <FilterSelectionRow key={option.value} withBorder={withBorder} withSpace={withSpace}>
            <Checkbox
              value={option.name}
              label={<StarRating variant="withBackground" rating={option.count} isLarge />}
              checked={checked}
              onChange={() => hotelFiltersAction.handleSetFilter('starRatings', option.value)}
            />
          </FilterSelectionRow>
        )
      })}
    </>
  )
})
