import { useTranslation } from 'react-i18next'
import { ImageContainer } from '@etta/components/image-container/image-container'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import type { RailSearchSegmentDetailsProps } from '../../types'
import {
  LabelsContainer,
  Label,
  DurationInfo,
  DurationTime,
  DurationLocation,
  Nights,
  TotalDurationTimeContainer,
  ImageRow,
  ErrorContainer,
} from '../../rail-search-segment-details-styled'
import {
  Container,
  DurationContainer,
  FooterContainer,
  ChangesLine,
  ChangesWrapper,
  ChangesDot,
  RailCardContainer,
} from './rail-search-segment-details-mobile.styled'

const i18Base = 'RailDetails'

const TUBE_ICON_NAME = 'tubePWA'

export function RailSearchSegmentDetailsMobile({
  isHighlightFromLocation,
  fromLocation,
  startTime,
  isHighlightToLocation,
  toLocation,
  daysInBetween,
  endTime,
  travelChanges,
  travelTime,
  isFastest,
  isCheapest,
  isLimited,
  carriersInfo,
  changesNumber,
  iconNames,
  applicableRailCard,
}: RailSearchSegmentDetailsProps) {
  const { t } = useTranslation()
  const fastestText = t(i18Base + '.Labels.Fastest')
  const cheapestText = t(i18Base + '.Labels.Cheapest')
  const departingLabel = t(i18Base + '.AriaLabels.DepartingLabel', {
    name: fromLocation,
    time: startTime,
  })
  const arivingLabel = t(i18Base + '.AriaLabels.ArivingLabel', { name: toLocation, time: endTime })
  const durationLabel = t(i18Base + '.AriaLabels.DurationLabel', {
    time: travelTime.trim(),
    changes: travelChanges,
  })
  const carrierLabel = (name: string) =>
    t(i18Base + '.AriaLabels.CarrierLabel', {
      name,
    })
  const isLabelsShown = isFastest || isCheapest

  return (
    <Container>
      {isLabelsShown && (
        <LabelsContainer>
          {isFastest && (
            <Label aria-label={fastestText} type="fastest">
              {fastestText}
            </Label>
          )}
          {isCheapest && (
            <Label aria-label={cheapestText} type="cheapest">
              {cheapestText}
            </Label>
          )}
        </LabelsContainer>
      )}
      <DurationContainer>
        <DurationInfo aria-label={departingLabel}>
          <DurationTime data-tracking-id="departure-time">{startTime}</DurationTime>
          <DurationLocation
            isSmallMobile
            isHighlighted={isHighlightFromLocation}
            data-tracking-id="departure-location">
            {fromLocation}
          </DurationLocation>
        </DurationInfo>
        <ChangesLine>
          <ChangesWrapper>
            {iconNames?.length
              ? iconNames.map((name) => (
                  <Icon key={name} name={name} size="small" color="mainText" />
                ))
              : Array.from(Array(changesNumber).keys()).map((_, index) => (
                  <ChangesDot key={index} />
                ))}
          </ChangesWrapper>
        </ChangesLine>
        <DurationInfo isAlignRight aria-label={arivingLabel}>
          <DurationTime data-tracking-id="arrival-time">{endTime}</DurationTime>
          <DurationLocation
            isSmallMobile
            isHighlighted={isHighlightToLocation}
            isAlignRight
            data-tracking-id="arrival-location">
            {toLocation}
            {daysInBetween > 0 && (
              <Nights>{t(i18Base + '.Nights', { count: daysInBetween })}</Nights>
            )}
          </DurationLocation>
        </DurationInfo>
      </DurationContainer>
      <FooterContainer>
        <TotalDurationTimeContainer isSmallMobile aria-label={durationLabel}>
          <Block>{travelTime}</Block>
          <Block>{travelChanges}</Block>
        </TotalDurationTimeContainer>
        <ImageRow>
          {carriersInfo.map((carrier, index) => (
            <ImageContainer
              aria-label={carrierLabel(carrier.name)}
              key={index}
              url={carrier.logoUrl}
              errorIconName="railPWA"
            />
          ))}
          {!!iconNames?.length &&
            iconNames
              .filter((name) => name !== TUBE_ICON_NAME)
              .map((name) => <Icon key={name} name={name} size="small" color="mainText" />)}
        </ImageRow>
      </FooterContainer>
      {applicableRailCard && (
        <>
          <Separator lineType="dashed" margin="8px 0px" />
          <RailCardContainer>
            <Icon name="loyaltyCardPWA" size="small" color="success" />
            <Text color="mainText1" variant="footnoteMedium">
              {applicableRailCard} {t(i18Base + '.Fare.Applicable')}
            </Text>
          </RailCardContainer>
        </>
      )}
      {isLimited && <ErrorContainer>{t(i18Base + '.Errors.LimitedAvailability')}</ErrorContainer>}
    </Container>
  )
}
