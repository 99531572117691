import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import type { HeaderColors } from '../types'

type CloseButtonProps = {
  onClick: () => void
  color?: HeaderColors
  tabIndex?: number
}

export function CloseButton({ onClick, color = 'mainText', tabIndex }: CloseButtonProps) {
  const { t } = useTranslation()

  return (
    <IconButton
      tabIndex={tabIndex}
      onClick={onClick}
      icon="closePWA"
      size="medium"
      color={color}
      aria-label={t('Accessibility.Header.CloseLabel')}
    />
  )
}
