import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../../types'
import { ButtonContainer, CloseButton, DelegateModalHeader } from './delegate-modal-desktop-styled'
import { DelegateModalDesktopDetailsBody } from './delegate-modal-desktop-details-body'

type Props = Omit<LayoutProps, 'isModalVisible'>

const i18Base = 'Delegate'

export function DelegateModalDesktopDetails({
  searchLabel,
  searchSlot,
  isConfirmButtonDisabled,
  isLoading,
  handleClose,
  handleConfirm,
  delegatesSlot,
  confirmationDialog,
}: Props) {
  const { t } = useTranslation()

  const title = t(i18Base + '.SelectTraveler')
  const titleDescription = t(i18Base + '.SelectTravelerDescription')

  const isFooterShown = !isConfirmButtonDisabled && !confirmationDialog.isOpen

  return (
    <>
      <Modal.Header withBorder>
        <DelegateModalHeader>
          <Text aria-label={title} isBlock variant="title3" color="mainText">
            {title}
          </Text>
          <Text aria-label={titleDescription} isBlock variant="footnoteMedium" color="bodyText">
            {titleDescription}
          </Text>
          <CloseButton
            icon="closeFilledPWA"
            size="medium"
            color="bodyText"
            onClick={handleClose}
            aria-label={t('Accessibility.Header.Close')}
          />
        </DelegateModalHeader>
      </Modal.Header>
      <DelegateModalDesktopDetailsBody
        confirmationDialog={confirmationDialog}
        delegatesSlot={delegatesSlot}
        isLoading={isLoading}
        searchLabel={searchLabel}
        searchSlot={searchSlot}
      />
      {isFooterShown && (
        <Modal.Footer padding="normal" withBorder>
          <ButtonContainer>
            <Button variant="link" onClick={handleClose} aria-label={t(i18Base + '.CancelButton')}>
              {t(i18Base + '.CancelButton')}
            </Button>
            <Button onClick={handleConfirm} aria-label={t(i18Base + '.SwitchButtonLabel')}>
              {t(i18Base + '.SelectTraveler')}
            </Button>
          </ButtonContainer>
        </Modal.Footer>
      )}
    </>
  )
}
