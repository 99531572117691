import { useCallback } from 'react'
import { useAppDispatch } from '@fiji/store'
import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { updateFlightId } from '@fiji/store/segment-ids'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { FlightDirection, TimeRangeDirection } from '@fiji/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { getReturnChangeQueryParams } from '@etta/screens/trip-flight/use-trip-flight/get-return-change-query-params'
import { getChangeQueryParams } from '@etta/screens/trip-flight/use-trip-flight/get-change-query-params'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { FlightActionChangeType } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-actions-value-object'

type Props = {
  itineraryId: string
  flightId?: string | null
}

type HandleModifyFlightArgs = {
  isReturnChangeAction: boolean
}

type HandleModifyFlight = (args?: HandleModifyFlightArgs) => void

export function useModifyFlight({ itineraryId, flightId }: Props) {
  const { postBookingTripStore } = usePostBookingContext()
  const { postBookingReviewTripPageActions } = useReviewTripContext()
  const { airSearchFormInputsActions } = useAirSearchFormContext()
  const { flightSegments, trip } = postBookingTripStore
  const { supportedActions } = trip
  const dispatch = useAppDispatch()
  const {
    appendQueryParams,
    navigateTo,
    queryParams: { bookingId: processId = '' },
  } = useAirSearchQuery()
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const { cabinClassesOptions } = useSeatmapCabinClasss()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()

  const handleModifyFlight: HandleModifyFlight = useCallback(
    (args) => {
      const { isReturnChangeAction } = args || {}
      const departureFlight = flightSegments[0]
      const returnFlight = flightSegments[1]

      dispatch(updateFlightId({ flightId }))
      dispatch(updatePostPurchaseEntrance('fromRTP'))
      const { isFlightChangeCarrierAllowed, isFlightChangeDestinationAllowed } = supportedActions

      const leg0Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Origin)
      const leg1Time = getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Return)

      const changeRules = trip.supportedActions.flightChangeRules.find(
        (r) => r.changeType === FlightActionChangeType.ReturnLeg,
      )
      const queryParams = isReturnChangeAction
        ? getReturnChangeQueryParams({
            cabinClassesOptions,
            departureFlight,
            returnFlight,
            itineraryId,
            leg0Time,
            leg1Time,
            processId,
            returnFlightSearchStartDate: changeRules?.allowedSearchDateRange?.start,
            returnFlightSearchEndDate: changeRules?.allowedSearchDateRange?.end,
          })
        : getChangeQueryParams({
            cabinClassesOptions,
            departureFlight,
            flightSegments,
            isFlightChangeCarrierAllowed,
            isFlightChangeDestinationAllowed,
            itineraryId,
            leg0Time,
            leg1Time,
            processId,
            returnFlight,
          })

      if (isMobile) {
        navigateTo(ROUTES.air.search, queryParams)
        return
      }
      appendQueryParams(queryParams)
      airSearchFormInputsActions.handleSetFlights(queryParams.flights!, queryParams.flightType!)
      postBookingReviewTripPageActions.handleChangeFlightOpen()
    },
    [
      flightSegments,
      dispatch,
      flightId,
      supportedActions,
      getTimeForInitialFlight,
      cabinClassesOptions,
      itineraryId,
      processId,
      isMobile,
      postBookingReviewTripPageActions,
      navigateTo,
      appendQueryParams,
      airSearchFormInputsActions,
      trip.supportedActions,
    ],
  )
  return {
    handleModifyFlight,
  }
}
