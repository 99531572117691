import styled from 'styled-components'
import { withStyledText } from '@fiji/style'

export const ErrorBlock = styled.div`
  padding: 0 23px 10px;
  display: flex;
  align-items: center;
`

export const ErrorText = styled.span`
  color: ${(p) => p.theme.colors.error};
  ${withStyledText};
`
