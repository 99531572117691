import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import type { RailPreferencesType } from './types'
import { RailPreferencesContent } from './rail-preferences-content'

type Props = RailPreferencesType & {
  isOpen: boolean
}

export function RailPreferencesModal({ isOpen, onClose, ...props }: Props) {
  return (
    <CheckoutPageModal isVisible={isOpen} onClose={onClose}>
      <RailPreferencesContent onClose={onClose} {...props} />
    </CheckoutPageModal>
  )
}
