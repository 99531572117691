import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import type { MobilitySearchQueryType } from './types'

export function useMobilitySearchQuery() {
  const {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  } = useQueryParams<MobilitySearchQueryType>(
    {},
    {
      caseStyle: 'kebab-case',
    },
  )

  return {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  }
}
