import { ControlsContainerLayout } from '@etta/ui/date-picker-temp/controls/controls-container'
import {
  ButtonsContainer,
  SelectedDateStatus,
  Separator,
} from '@etta/ui/date-picker-temp/controls/controls-styled'
import { SelectedDateTitle } from '@etta/ui/date-picker-temp/controls/selected-date-title'
import { Block } from '@etta/ui/block'
import { MediaLayout } from '@etta/ui/media-layout'
import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import type { LayoutControlsProps } from '@etta/ui/date-picker-temp/controls/types'
import { WeekHeader } from '@etta/ui/date-picker-temp/controls/week-header'

export function ControlsLayoutMobile({
  date,
  dateEnd,
  isRange,
  focusedInput,
  startDateAriaLabel,
  startDateTitle,
  endDateTitle,
  endDateAriaLabel,
}: LayoutControlsProps) {
  return (
    <ControlsContainerLayout data-name="date-picker-controls">
      <ButtonsContainer>
        <SelectedDateStatus
          startDate={date}
          endDate={dateEnd}
          isMobile
          isSelected={focusedInput === 'startDate'}
          isRange={isRange}
          aria-label={startDateAriaLabel}>
          <SelectedDateTitle isMobile dateTitle={startDateTitle} />
        </SelectedDateStatus>
        {isRange && (
          <>
            <MediaLayout
              mobileSlot={<Separator />}
              desktopSlot={
                <Block marginHorizontal={16}>
                  <PWAIcon color="bodyText2" size={32} name="arrowForwardPWA" />
                </Block>
              }
            />

            <SelectedDateStatus
              startDate={date}
              endDate={dateEnd}
              isMobile
              isSelected={focusedInput === 'endDate'}
              isStickToRight
              isRange={isRange}
              aria-label={endDateAriaLabel}>
              <SelectedDateTitle isMobile dateTitle={endDateTitle} />
            </SelectedDateStatus>
          </>
        )}
      </ButtonsContainer>
      <WeekHeader />
    </ControlsContainerLayout>
  )
}
