import type { FlightLegSubSegment } from '@fiji/graphql/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { mapAirArrivalDeparture } from './map-air-arrival-departure'

type SubSegments = FlightPostBookingValueObject['segments']

type Params = {
  segments: SubSegments
}

export function mapAirSubSegments({ segments }: Params): FlightLegSubSegment[] | null {
  if (!segments) {
    return null
  }
  const result = segments.map(
    ({
      segmentId,
      flightTime,
      name,
      flightNumber,
      travelerSeat,
      travelerId,
      carrierCode,
      bookingCode,
      departureDate,
      arrivalDate,
      imageUrl,
      images,
      serviceFee,
      penaltyFees,
      preferredDescription,
      preferenceAttributes,
      baggageFees,
      operatedBy,
      amenities,
      isSeatAvailable,
      isSeatSelectable,
      serviceClass,
      mealService,
      bookingProvider,
      originTerminal,
      destinationTerminal,
      percentageOnTime,
      codeshareCarrier,
      plane,
      fare,
      departure,
      arrival,
    }) => {
      return {
        segmentId,
        flightTime: {
          hours: flightTime?.hours || 0,
          minutes: flightTime?.minutes || 0,
        },
        name: name || '',
        flightNumber: flightNumber || 0,
        travelerSeat: travelerSeat || '',
        travelerId: travelerId || '',
        carrierCode: carrierCode || '',
        bookingCode,
        departureDate,
        arrivalDate,
        imageUrl,
        images,
        serviceFee: {
          id: serviceFee?.id || '',
          rate: serviceFee?.rate || null,
          description: serviceFee?.description || '',
        },
        penaltyFees,
        preferredDescription,
        preferenceAttributes,
        baggageFees,
        operatedBy,
        amenities: {
          codes: amenities?.codes || [],
          values: amenities?.values || [],
        },
        isSeatAvailable: isSeatAvailable || false,
        isSeatSelectable: isSeatSelectable || false,
        serviceClass: serviceClass || '',
        mealService,
        bookingProvider,
        originTerminal,
        destinationTerminal,
        percentageOnTime,
        codeshareCarrier: codeshareCarrier || {
          name: '',
          code: null,
        },
        plane: {
          type: plane?.type || '',
          code: plane?.code || '',
        },
        aircraft: plane,
        fare,
        departure: mapAirArrivalDeparture({ airportAddress: departure }),
        arrival: mapAirArrivalDeparture({ airportAddress: arrival }),
      }
    },
  )

  return result
}
