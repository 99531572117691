import { useCallback } from 'react'
import { useSwitchModal } from '@etta/components/switch-site-modal'
import { useUserContext } from '@etta/modules/user'
import { useNavigationContext } from '../../interface/use-navigation-context'

export function useSiteSwitcher() {
  const { userStore } = useUserContext()
  const profile = userStore.profile
  const { navigationAction } = useNavigationContext()

  const switchSite = useCallback(() => {
    const url = profile?.site?.url || ''
    navigationAction.handleSetRcSessionCookie(url)
  }, [navigationAction, profile?.site?.url])

  const { isModalEnabled, switchModalSlot, showSwitchModal } = useSwitchModal({
    onTripDeleted: switchSite,
  })

  const handleSiteSwitch = () => {
    if (!isModalEnabled) {
      switchSite()
      return
    }

    showSwitchModal()
  }

  return { handleSiteSwitch, switchModalSlot }
}
