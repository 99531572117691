import { useEffect, useState } from 'react'
import type { AnimationItem } from '@etta/ui/lottie-animation'

type AnimationState = {
  isEnterPlayed: boolean
  isIdlePlayed: boolean
  isIdlePlaying: boolean
  isExitPlayed: boolean
}

const initialAnimationState: AnimationState = {
  isEnterPlayed: false,
  isIdlePlayed: false,
  isIdlePlaying: false,
  isExitPlayed: false,
}

let isExitAnimationRunning = false

export function useLottieAnimation() {
  const [transitionAnimation, setTransitionAnimation] = useState<AnimationItem | null>(null)
  const [idleAnimation, setIdleAnimation] = useState<AnimationItem | null>(null)
  const [animationState, setAnimationState] = useState<AnimationState>(initialAnimationState)
  const exitAnimationPromise = () =>
    new Promise<void>((resolve) => {
      if (!transitionAnimation || isExitAnimationRunning) {
        resolve()

        return
      }

      isExitAnimationRunning = true

      const onComplete = transitionAnimation.addEventListener('complete', () => {
        const onEnterFrame = transitionAnimation.addEventListener('enterFrame', () => {
          isExitAnimationRunning = false

          transitionAnimation.removeEventListener('enterFrame', onEnterFrame)
          transitionAnimation.removeEventListener('complete', onComplete)
        })

        resolve()
      })

      setAnimationState((state) => ({
        ...state,
        isIdlePlaying: false,
        isIdlePlayed: true,
      }))
    })

  useEffect(() => {
    if (!transitionAnimation || animationState.isEnterPlayed) {
      return
    }

    transitionAnimation.playSegments(
      [0, Math.floor(transitionAnimation.getDuration(true) / 2)],
      true,
    )

    setAnimationState((state) => ({ ...state, isEnterPlayed: true }))
  }, [transitionAnimation, animationState])

  useEffect(() => {
    if (!idleAnimation || animationState.isIdlePlaying || animationState.isIdlePlayed) {
      return
    }

    idleAnimation.goToAndPlay(0)

    setAnimationState((state) => ({ ...state, isIdlePlaying: true }))
  }, [idleAnimation, animationState])

  useEffect(() => {
    if (
      !transitionAnimation ||
      !animationState.isIdlePlayed ||
      animationState.isIdlePlaying ||
      animationState.isExitPlayed
    ) {
      return
    }

    const onComplete = () => {
      transitionAnimation.resetSegments(true)
      transitionAnimation.removeEventListener('complete', onComplete)
    }

    transitionAnimation.addEventListener('complete', onComplete)
    transitionAnimation.resetSegments(true)
    transitionAnimation.playSegments(
      [Math.floor(transitionAnimation.getDuration(true) / 2), transitionAnimation.totalFrames],
      true,
    )

    setAnimationState((state) => ({ ...state, isExitPlayed: true }))
  }, [transitionAnimation, animationState])

  return {
    animationState,
    setTransitionAnimation,
    setIdleAnimation,
    startTransitionAnimation: exitAnimationPromise,
  }
}
