import { useTranslation } from 'react-i18next'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useActualRailEmissions } from '@fiji/hooks/sustainability'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates'
import { useRailContext } from '@etta/modules/rail/use-rail.context'

const i18RailDetails = 'RailDetails'

// FIXME: This is temporary solution until we implement new arch to rail search results.
export function useSelectedDepartingRail() {
  const { t } = useTranslation()

  const { railEmissionStore } = useRailContext()
  const { reviewTripStore } = useReviewTripContext()
  const { rail } = reviewTripStore

  const formatTime = (time: string) =>
    tripReviewFormatDate(TripReviewDateType.TimePWA, time)?.toLowerCase()
  const formatDate = (date?: string) => tripReviewFormatDate(TripReviewDateType.FullDate, date)
  const getDuration = useRailDuration()

  const railSegments = reviewTripStore.railSegments
  const travelTime = getDuration(rail?.travelTime) || ''
  const travelChanges =
    railSegments.length > 1
      ? t(i18RailDetails + '.Change', { count: railSegments.length - 1 })
      : t(i18RailDetails + '.Stops.Nonstop')
  const actualRailEmission = useActualRailEmissions(
    rail?.legId,
    railEmissionStore.railEmissions ?? undefined,
  )
  const changeStations = railSegments
    .map((segment, index) => {
      if (index === reviewTripStore.segments.length - 1) {
        return ''
      }
      return segment.departure.stationName
    })
    .filter(Boolean)

  const durationLabel = t(i18RailDetails + '.AriaLabels.TrainDurationLabel', {
    time: travelTime.trim(),
    changes: travelChanges,
    coNumber: actualRailEmission && actualRailEmission.kgOfActualEmission,
  })

  return {
    originTime: formatTime(rail?.departureDateTime) || '',
    departure: reviewTripStore.firstRailSegment?.departure,
    travelTime,
    destinationTime: formatTime(rail?.arrivalDateTime) || '',
    arrival: reviewTripStore.lastRailLegSegment?.arrival,
    travelDate: formatDate(reviewTripStore.firstRailSegment?.departureDate) || '',
    carriersInfo: railSegments.map((segment) => segment.carrierLogoUrl),
    travelChanges,
    changeStations,
    actualRailEmission,
    service: reviewTripStore.firstRailSegment?.service,
    serviceClassName: rail?.fareConditions?.tier.name,
    durationLabel,
  }
}
