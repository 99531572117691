import i18n from 'i18next'
import type { FlightLegSubSegment } from '@fiji/graphql/types'
import type { AdaptiveFlight } from '@etta/components/flight-details/types'

export function getPercentageOnTimeLabels(
  segments: FlightLegSubSegment[],
): AdaptiveFlight['percentageOnTimeLabels'] {
  return segments.map((segment) =>
    segment.plane?.onTime ? `${segment.plane?.onTime}%` : i18n.t('FlightDetails.N/A'),
  )
}
