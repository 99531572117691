import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useDelegateSwitch } from '@etta/modules/delegate/ui/delegate-switch/hooks'
import { SelectSearch } from '@etta/ui/select-search'
import type { SelectSearchOptionType } from '@etta/ui/select-search/types'
import { Block } from '@etta/ui/block'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { DelegateModalDesktopDetailsLoader } from '@etta/components/delegate-modal/layout/desktop/delegate-modal-desktop-details-loader'
import { Text } from '@etta/ui/text'
import type { Delegate } from '../../types'
import { Initials, StyledBlock } from './book-trip-again-delegate-picker.styled'
import { useTripAgainDelegatePicker } from './use-book-trip-again-delegate-picker'

type Props = {
  regularUser?: Delegate
  currentUserId: number
  onUserSelect: (id?: number) => void
}

export const BookTripAgainDelegatePicker = observer(function BookTripAgainDelegatePicker({
  regularUser,
  onUserSelect,
  currentUserId,
}: Props) {
  const { bookTripAgainStore } = usePostBookingContext()
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(`${currentUserId}`)
  const modalToggle = useTogglePopup(false)
  const { delegatedUsersWithoutCustomer } = useDelegateSwitch()
  const { searchOptions } = useTripAgainDelegatePicker({
    regularUser,
    delegatedUsersWithoutCustomer,
  })

  const onHandleSelect = (userId?: string | null) => {
    const parsedUserId = parseInt(userId || '')
    setSelectedUserId(userId || undefined)
    onUserSelect(parsedUserId || undefined)
    modalToggle.handleClose()
  }

  const renderOption = useCallback(
    (option: SelectSearchOptionType<typeof searchOptions[number]['metadata']>) => (
      <Block paddingHorizontal={20} paddingTop={15}>
        <StyledBlock isFlexBlock paddingBottom={13}>
          <Initials>
            <Text variant="headline" color="mainText2">
              {option.metadata?.initials}
            </Text>
          </Initials>
          <Text variant="footnoteStrong">{option.label}</Text>
        </StyledBlock>
      </Block>
    ),
    [],
  )

  return (
    <>
      <SelectSearch
        label={'Book Trip for'}
        value={selectedUserId}
        placeholder={'Search for Traveler'}
        options={searchOptions}
        onOptionSelect={onHandleSelect}
        renderOption={renderOption}
        renderOptionMobile={renderOption}
        skipInputRequirement={true}
      />
      {bookTripAgainStore.isSwitchDelegateInProgress && (
        <ViewStateModal viewState={'custom'}>
          <DelegateModalDesktopDetailsLoader
            isLoading={bookTripAgainStore.isSwitchDelegateInProgress}
            withBackground={false}
          />
        </ViewStateModal>
      )}
    </>
  )
})
