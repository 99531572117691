import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { PersonalContactPhoneValueObject } from '@etta/modules/user'
import { ValidatorMaker } from '@etta/modules/settings/validators/validator-maker'
import { Validator } from '@etta/interface/services/validator'
import { AddressFields } from '../../core/enums/address-fields'
import { FieldSettingsStore } from './field-settings'

export type HomeAddressInput = {
  [AddressFields.Country]: string
  [AddressFields.Zip]: string
  [AddressFields.State]: string
  [AddressFields.Mailstop]: string
  [AddressFields.City]: string
  [AddressFields.Street1]: string
  [AddressFields.Street2]: string
  homeEmail: string
  homePhone: PersonalContactPhoneValueObject
}

type PhoneFields = 'homePhoneCountryCode' | 'homePhoneNumber'

@Store()
export class HomeAddressStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}
  private _additionalInformationValidator: Validator = new Validator({})
  isSaveButtonDisabled = true

  toggle = new Toggle()

  homeAddressValidator = new ValidatorMaker(this.fieldSettingsStore).makeHomeAddressValidator()

  setHomeAddressValue<T extends keyof Omit<HomeAddressInput, 'homePhone'>>(
    field: T,
    value: HomeAddressInput[T],
  ) {
    this.homeAddressValidator.onFieldChange(field, value)
  }

  setHomePhoneValue(field: 'CountryCode' | 'Number', value: string) {
    const phoneField = `homePhone${field}` as PhoneFields
    this.homeAddressValidator.onFieldChange(phoneField, value)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }

  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }
  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setHomeAddress(input: HomeAddressInput) {
    this.setHomeAddressValue(AddressFields.Country, input[AddressFields.Country])
    this.setHomeAddressValue(AddressFields.Zip, input[AddressFields.Zip])
    this.setHomeAddressValue(AddressFields.State, input[AddressFields.State])
    this.setHomeAddressValue(AddressFields.Mailstop, input[AddressFields.Mailstop])
    this.setHomeAddressValue(AddressFields.City, input[AddressFields.City])
    this.setHomeAddressValue(AddressFields.Street1, input[AddressFields.Street1])
    this.setHomeAddressValue(AddressFields.Street2, input[AddressFields.Street2])

    this.setHomeAddressValue('homeEmail', input.homeEmail)

    this.setHomePhoneValue('CountryCode', input.homePhone?.countryCode || '')
    this.setHomePhoneValue('Number', input.homePhone?.number || '')
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }
}
