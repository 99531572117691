export const WHATFIX_SCRIPT_ID = 'whatfix-script'
export const WHATFIX_SCRIPT_URLS =
  'https://eucdn.whatfix.com/prod/e87c021b-956f-4ebb-b0b0-21c8145b7242/initiator/initiator.nocache.js'

export function isWhatFixInserted() {
  return !!document.getElementById(WHATFIX_SCRIPT_ID)
}

export function insertWhatFix() {
  const whatFix = document.createElement('script')

  whatFix.id = WHATFIX_SCRIPT_ID
  whatFix.type = 'text/javascript'
  whatFix.async = true
  whatFix.src = WHATFIX_SCRIPT_URLS
  whatFix.innerHTML = `var whtFix_CustomerID="${window.whtFix_CustomerID}"
     var whtFix_CompanyName="${window.whtFix_CompanyName}"
     var whtFix_SiteID="${window.whtFix_SiteID}"`
  document.head.appendChild(whatFix)
}
