import { MediaLayout } from '@etta/ui/media-layout'
import { PreferenceMealOptionsMobile } from './mobile'
import { PreferenceMealOptionsDesktop } from './desktop'
import type { LayoutProps } from './types'

export function PreferenceMealOptionsLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<PreferenceMealOptionsMobile {...props} />}
      desktopSlot={<PreferenceMealOptionsDesktop {...props} />}
    />
  )
}
