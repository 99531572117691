import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { useMultiModalSearchContext } from '@etta/modules/multi-modal-search'
import { MultiModalBlock } from '@etta/modules/multi-modal-search/ui/multi-modal-search/multi-modal-block'
import { AlternativeRoutesContainer } from './multi-modal-search-results-styled'

const i18nBase = 'Mobility.SearchResultPage.MultiModal'

export const MultiModalSearchResults = observer(function SearchResults() {
  const { t } = useTranslation()
  const { multiModalSearchStore, multiModalSearchActions } = useMultiModalSearchContext()

  const { sortRoutesList: multiModalRouteList } = multiModalSearchStore

  return (
    <>
      {multiModalRouteList.map((route, index) => {
        return (
          <MultiModalBlock
            key={index}
            route={route}
            openGoogleMaps={() => {
              multiModalSearchActions.handlePunchToGoogleMap(route)
            }}
          />
        )
      })}
      <AlternativeRoutesContainer
        onClick={multiModalSearchActions.handlePunchOutWithSearchData}
        data-tracking-id="search-result-alternative-routes-button">
        <Text variant="linkSmall" color="mainText1" textOverflow="ellipsis">
          {t(`${i18nBase}.ViewMoreAlternativeRoutesButton`)}
        </Text>
        <Block marginLeft={4} isFlexBlock>
          <Icon name={'openWindowPWA'} color={'mainText1'} size={18} />
        </Block>
      </AlternativeRoutesContainer>
    </>
  )
})
