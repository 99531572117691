import styled from 'styled-components'
import { ScreenDesktop } from '@etta/ui/screen/desktop'

export const ContentWrapper = styled(ScreenDesktop.Content)`
  margin: 40px auto;
  display: flex;
`

export const SubheaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  max-height: 64px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  border-top: 1px solid ${(p) => p.theme.colors.background};
`

export const SubheaderContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const TabsWrapper = styled.div<{ hasExtraTabs: boolean }>`
  flex: 0 0 ${(p) => (p.hasExtraTabs ? '480px' : '360px')};
  position: relative;
  margin: 0 auto;
`
