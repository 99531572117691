import { useEffect } from 'react'
import { useUserContext } from '@etta/modules/user'

export function useUser() {
  const { userActions } = useUserContext()

  useEffect(() => {
    userActions.getUser()
  }, [userActions])
}
