import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Button } from '@etta/ui/button'

export const ModalHeader = styled(Modal.Title).attrs((p) => ({
  ...p,
  withBorder: true,
  leftSeparatorWidth: 24,
  minHeight: 64,
}))``

export const StyledTitle = styled(Text)`
  line-height: 1.8rem;
`

export const StyledText = styled(Text)<{ paddingHorizontal?: number }>`
  line-height: 100%;
  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `
    padding-left: ${paddingHorizontal}px;
    padding-right: ${paddingHorizontal}px;
  `}
`

export const StyledLink = styled(Link)`
  line-height: 100%;
`

export const StyledBlock = styled(Block)`
  align-items: flex-end;
`

export const FooterContainer = styled(Modal.Footer)`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClearButton = styled(Button)`
  margin-right: 16px;
  padding-left: 0;
`

export const ApplyButton = styled(Button)`
  min-width: 22rem;
`

export const ResetFilterBlockMobile = styled.div<{ isMobile?: boolean }>`
  padding: 24px 20px 22px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-left: 0;
  border-right: 0;
`
