import styled from 'styled-components'

export const LogoImage = styled.img`
  max-width: 100%;
  max-height: 24px;

  & + & {
    margin-left: 6px;
  }
`
