import { useTranslation } from 'react-i18next'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

export function FlightSafetyCheckNotAvailable() {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'

  return (
    <>
      <SafetyCheckTitle />
      <Block marginTop={6}>
        <Text variant="footnoteMedium">{`${t(i18nBase + '.NotAvailable')}`}</Text>
      </Block>
    </>
  )
}
