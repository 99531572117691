import { useTranslation } from 'react-i18next'
import type { Section, ConfirmationNumberData } from '@etta/screens/payment-summary/types'
import { SectionBlock } from '../section-block'
import { PaymentCard } from '../payment-card'
import { CarRow } from './car-row'
import { useCarRentalDetails } from './use-car-rental-details'

type Props = {
  carRentals: Section
  confirmationNumbersData: ConfirmationNumberData[]
  isTripLoading?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function CarBlock({ carRentals, confirmationNumbersData, isTripLoading }: Props) {
  const { t } = useTranslation()

  const { rate, rows, creditCard, cardDetails, mainCost, secondCost } = useCarRentalDetails(
    carRentals,
  )

  const paymentText = creditCard ? t(i18nBase + 'Payment') : t(i18nBase + 'PaymentDueAtCar')

  const paymentLabel = t(i18nBase + 'PaymentLabel', {
    card: cardDetails,
    amount: `${mainCost} ${secondCost ? `, ${secondCost}` : ''}`,
  })

  if (rows.length === 0) {
    return null
  }

  return (
    <SectionBlock
      paymentText={paymentText}
      paymentLabel={paymentLabel}
      title={t(i18nBase + 'CarRentalsTitle')}
      cardSlot={<PaymentCard creditCard={creditCard} />}
      rate={rate}>
      {rows.map(
        ({ reservationNumber, pickUpDate, dropOffDate, supplier, carClass, segmentId }, index) => {
          const confirmationData = confirmationNumbersData.find(({ id }) => id === segmentId)

          return (
            <CarRow
              key={index}
              reservationNumber={reservationNumber}
              pickUpDate={pickUpDate}
              dropOffDate={dropOffDate}
              supplier={supplier}
              carClass={carClass}
              confirmationData={confirmationData}
              isTripLoading={isTripLoading}
            />
          )
        },
      )}
    </SectionBlock>
  )
}
