import styled, { css } from 'styled-components'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'

export const TooltipTextContainer = styled.div`
  max-width: 270px;
`

export const TooltipTextItem = styled.div<{ showTitle: boolean }>`
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => hexToRgba(props.theme.colors.borderLight, 0.3)};

  &:last-child {
    padding-bottom: 4px;
    border-bottom: none;
  }

  ${(props) =>
    !props.showTitle &&
    css`
      margin: 0;

      &:last-child {
        padding: 0;
      }
    `}
`
