import { useTranslation } from 'react-i18next'
import type { SelectSearchOptionType } from '@etta/ui/select-search/types'
import type { Delegate } from '../../types'

type SelectSearchOptionTypeWithMetadata = SelectSearchOptionType & {
  metadata: {
    initials: string
  }
}

type Args = {
  regularUser?: Delegate
  delegatedUsersWithoutCustomer: Delegate[]
}

const i18nBase = 'BookAgainModal'

export function useTripAgainDelegatePicker({ regularUser, delegatedUsersWithoutCustomer }: Args) {
  const { t } = useTranslation()
  const users = [regularUser, ...delegatedUsersWithoutCustomer]

  const searchOptions = users
    .map((user) => {
      if (!user || !user.firstName || !user.lastName || !user.userId) {
        return
      }

      const { firstName, lastName, userId } = user

      const firstNameLetter = firstName.charAt(0)
      const lastNameLetter = lastName.charAt(0)
      const initials = [firstNameLetter, lastNameLetter].join('')
      const regularUserTag = regularUser?.userId === userId ? t(`${i18nBase}.RegularUserTag`) : ''
      const label = [firstName, lastName, regularUserTag].join(' ')

      return {
        label,
        value: userId,
        metadata: {
          initials,
        },
      }
    })
    .filter((option): option is SelectSearchOptionTypeWithMetadata => option !== undefined)

  return { searchOptions }
}
