import type { Address, TripCost } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates/get-date-without-timezone-offset'
import { formatAddress } from '@fiji/utils/address/format-addess'

type TripData = {
  name?: string | null
  address?: Address | null
  checkIn?: string | null
  checkOut?: string | null
  tripCost?: TripCost | null
}

type Props = {
  hotel?: TripData | null
}

function formatDate(date: string) {
  return dateFormat(getDateWithoutTimezoneOffset(date), 'EEE, MMM d')
}

export function postBookingHotel({ hotel }: Props) {
  if (!hotel) {
    return undefined
  }

  const { name, address, checkIn, checkOut, tripCost } = hotel

  return `
${name}
${address ? formatAddress(address) : ''}
${checkIn ? `Check-in: ${formatDate(checkIn)}` : ''}
${checkOut ? `Check-out: ${formatDate(checkOut)}` : ''}
${
  tripCost
    ? `Cost: ${tripCost?.total?.primary.amount || ''} ${tripCost?.total?.primary?.currency || ''}`
    : ''
}
  `.trim()
}
