import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { default as TravelerSVG } from './assets/traveler.svg'
import { default as RainbowSVG } from './assets/rainbow.svg'
import {
  PastTripHeaderContainer,
  TextWrapper,
  Title,
  DataItem,
  RainbowSVGWrapper,
} from './past-trip-header-styled'

type Props = {
  milesCount?: number
  nightsCount?: number
}

export function PastTripHeader({ milesCount, nightsCount }: Props) {
  const { t } = useTranslation()

  const i18Base = 'PostBooking.TripDetails'

  return (
    <PastTripHeaderContainer>
      <TravelerSVG />
      <TextWrapper>
        <Title>
          <Text variant="title2">{t(i18Base + '.WishHeader')}</Text>
        </Title>
        {milesCount && (
          <DataItem>
            <Text variant="body">• {t(i18Base + '.MilesTravelled', { count: milesCount })}</Text>
          </DataItem>
        )}
        {nightsCount && (
          <DataItem>
            <Text variant="body">• {t(i18Base + '.NightsStayed', { count: nightsCount })}</Text>
          </DataItem>
        )}
      </TextWrapper>
      <RainbowSVGWrapper>
        <RainbowSVG />
      </RainbowSVGWrapper>
    </PastTripHeaderContainer>
  )
}
