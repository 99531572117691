import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { TravelTime } from '@fiji/graphql/types'
import type { TravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { Container, DurationWrapper } from './rail-change-duration-styled'
import { useRailChangeDuration } from './use-rail-change-duration'

type Props = {
  arrival: TravelRailLocation
  changeDuration: TravelTime | null | undefined
  isRtp: boolean
  isTrainSegment: boolean
  isNextSegmentTrain: boolean
}

export function RailChangeDuration({
  arrival,
  changeDuration,
  isRtp,
  isTrainSegment,
  isNextSegmentTrain,
}: Props) {
  const { changeDurationAriaLabel, changeDurationText, changeStationText } = useRailChangeDuration({
    arrival,
    changeDuration,
  })

  return (
    <Container
      aria-label={changeDurationAriaLabel}
      data-tracking-id="changeInfo"
      isFlexBlock
      isRtp={isRtp}
      isTrainSegment={isTrainSegment}
      isNextSegmentTrain={isNextSegmentTrain}>
      <Icon name="changePWA" size="small" color="bodyText" />
      <Block marginLeft={18} marginRight={40}>
        <Text variant="footnoteMedium" color="bodyText">
          {changeStationText}
        </Text>
      </Block>
      {changeDuration && (
        <DurationWrapper>
          <Text variant="footnoteMedium" color="bodyText">
            {changeDurationText || '-'}
          </Text>
        </DurationWrapper>
      )}
    </Container>
  )
}
