import styled from 'styled-components'

import { Icon as EttaIcon } from '@etta/ui/icon'
import { TextField as EttaTextField } from '@etta/ui/text-field'

export const Icon = styled((props) => <EttaIcon {...props} />)`
  margin-right: 12px;
`

export const TextField = styled((props) => <EttaTextField {...props} />)`
  pointer-events: none;
`
