import type { Rate, CarCostRates } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { getCarRentalDetailsPrice } from '@fiji/utils/get-car-rental-details-price'

type Props = {
  carCostRates?: CarCostRates
  totalCostRate?: Rate | null
  baseRate?: Rate | null
  serviceFeeRate?: Rate | null
  feesAndTaxesRate?: Rate | null
}

export function CarRentalDetailsPrice({
  carCostRates,
  totalCostRate,
  baseRate,
  feesAndTaxesRate,
  serviceFeeRate,
}: Props) {
  const { priceDetailsList, estTotalCost } = getCarRentalDetailsPrice({
    carCostRates,
    totalCostRate,
    baseRate,
    feesAndTaxesRate,
    serviceFeeRate,
  })

  return <PriceDetails priceDetailsList={priceDetailsList} totalCost={estTotalCost} />
}
