import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { MediaLayout } from '@etta/ui/media-layout'
import { Icon } from '@etta/ui/icon'
import { withStyledText } from '@fiji/style/text-styles'
import { mediumWeightText } from '@fiji/style'

const ArrowRightIconMobile = <Icon name="chevronRightPWA" size="small" color="bodyText" />
const ArrowRightIconDesktop = <Icon name="chevronRightPWA" size="normal" color="bodyText" />

const BodyMobile = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex: 1;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
`

const ArticlesListItemMobile = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 20px 28px 20px 0;
`

const ArticlesListItemDesktop = styled(ArticlesListItemMobile)`
  padding: 24px 0;
`

const BodyDesktop = styled(BodyMobile)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 32px;
  border-radius: 14px;
  margin-bottom: 56px;
  li:last-child ${ArticlesListItemDesktop} {
    border-bottom: 0;
  }
`

export function ArrowRightIcon() {
  return <MediaLayout desktopSlot={ArrowRightIconDesktop} mobileSlot={ArrowRightIconMobile} />
}

export function Body(props: any) {
  return (
    <MediaLayout desktopSlot={<BodyDesktop {...props} />} mobileSlot={<BodyMobile {...props} />} />
  )
}

export function ArticlesListItem(props: any) {
  return (
    <MediaLayout
      desktopSlot={<ArticlesListItemDesktop {...props} />}
      mobileSlot={<ArticlesListItemMobile {...props} />}
    />
  )
}

export const ArticleTitle = styled.span`
  ${mediumWeightText};
  margin-right: 18px;
  color: ${({ theme }) => theme.colors.mainText};
  ${withStyledText};
  line-height: 26px;
`

export const ArrowRightIconContainer = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
`

export const NavLink = styled(ReactRouterNavLink)<{ isSelected: boolean }>`
  text-decoration: none;
  display: block;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${(props) => props.theme.colors.background};
    `}
`

export const ErrorContainerWrapper = styled.div<{ isPreview: boolean }>`
  margin-top: 30px;
  ${({ isPreview }) =>
    !isPreview &&
    css`
      display: flex;
      margin-top: 0;
      height: 100%;
      padding-bottom: 103px;
    `}
`
