import { ModalDetails } from '@etta/components/modal-details'
import { RailDetailsContent } from './rail-details-content/rail-details-content'
import type { Props } from './types'

export function RailDetails({ isVisible, onClose, railLegType }: Props) {
  return (
    <>
      <ModalDetails
        isVisible={isVisible}
        onClose={onClose}
        isAdaptivePosition
        horizontalDimension="content-760">
        <RailDetailsContent handleModalClose={onClose} railLegType={railLegType} />
      </ModalDetails>
    </>
  )
}
