import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { Header as HeaderComponent } from '@etta/ui/header'
import { ScreenHeader } from './header-styled'
import { TRANSITION_CLASS, TRANSITION_DURATION } from './transition'

type Props = {
  isVisible: boolean
  handleBack?: () => void
  handleSkip?: () => void
}

export function Header({ isVisible, handleBack, handleSkip }: Props) {
  const { t } = useTranslation()

  return (
    <CSSTransition
      appear
      in={isVisible}
      classNames={TRANSITION_CLASS}
      timeout={TRANSITION_DURATION}>
      <ScreenHeader>
        <HeaderComponent
          backgroundColor="transparent"
          leftSlot={handleBack ? <HeaderComponent.BackButton onClick={handleBack} /> : null}
          rightSlot={
            handleSkip ? (
              <HeaderComponent.TextButton colorVariant="secondary" onClick={handleSkip}>
                {t('Permissions.Skip')}
              </HeaderComponent.TextButton>
            ) : null
          }
        />
      </ScreenHeader>
    </CSSTransition>
  )
}
