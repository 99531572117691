import { observer } from 'mobx-react-lite'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { MobilityWhereToDialog } from '@etta/components/mobility-where-to-dialog/mobility-where-to-dialog'
import { MobilityPickupConfirmationDialog } from '@etta/components/mobility-pickup-confirmation-dialog/mobility-pickup-confirmation-dialog'
import { MobilitySearchResultDialog } from '@etta/components/mobility-search-result-dialog/mobility-search-result-dialog'
import { MobilityRideReviewDialog } from '@etta/components/mobility-ride-review-dialog/mobility-ride-review-dialog'
import { MobilityZoneSelectionDialog } from '@etta/components/mobility-zone-selection-dialog'
import { Icon } from '@etta/ui/icon'
import { Modal } from '@etta/ui/modal'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { ExpenseCodeFlowModal } from '@etta/modules/ride-hail/ui/expense-code-flow-modal/expense-code-flow-modal'
import { ReviewFlightDetailsModal } from '@etta/modules/ride-hail/ui/review-flight-details/review-flight-details-modal'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { MobilityConfirmationPage } from '../mobility-confirmation-page/mobility-confirmation-page'
import { Container, MapContainer, IconWrapper } from './mobility-search-page-styled'
import { useMobilitySearchPageContextSelector } from './mobility-search-page-provider'
import { MobilityMapPinLabel } from './mobility-map-pinlabel'
import { MobilityMapCurrentLocationPinLabel } from './mobility-map-current-location-pin-label'
import { MobilityPickupConfirmationMarker } from './mobility-pickup-confirmation-marker'

export const MobilitySearchPageContent = observer(function MobilitySearchPageContent() {
  const {
    handleEditSearchClick,
    handleStartSearch,
    handleConfirmPickUpButtonClick,
    defaultCenterCoordinate,
    handleBack,
    handleOnEntered,
    onMapLoaded,
    pickUpAddress,
    pickUpConfirmCoordinate,
    currentCoordinate,
    loadingCoordinate,
    directionCoordinates,
    handleSearchResultCardClick,
    renderState,
    dialogWhereToContainerRef,
    dialogReviewContainerRef,
    dialogResultContainerRef,
    animatingRenderState,
    map,
    isFailureOnFetchingRideHailConfig,
    isFetchingRideHailConfig,
    viewState,
  } = useMobilitySearchPageContextSelector((state) => state)

  const { searchRideHailActions } = useRideHailSearchContext()

  return (
    <Container>
      <div id="mobility-modals-container"></div>
      <IconWrapper onClick={handleBack}>
        <Icon name="arrowBackPWA" color="mainText2" />
      </IconWrapper>
      <MapContainer aria-hidden data-tracking-id="map">
        <EttaMap
          unregisterOnUnmount
          options={{
            draggable: false,
            scaleControl: false,
            keyboardShortcuts: false,
            maxZoom: 18,
          }}
          defaultZoom={13}
          defaultCenter={defaultCenterCoordinate}
          onMapLoaded={onMapLoaded}>
          {renderState === RenderState.whereTo && (
            <MobilityMapCurrentLocationPinLabel isAnimate={false} {...currentCoordinate} />
          )}
          {(renderState === RenderState.searchResult || renderState === RenderState.review) && (
            <MobilityMapPinLabel isPickUp {...directionCoordinates?.start} />
          )}
          {(renderState === RenderState.searchResult || renderState === RenderState.review) && (
            <MobilityMapPinLabel isPickUp={false} {...directionCoordinates?.end} />
          )}
          {renderState === RenderState.loading && (
            <MobilityMapPinLabel isPickUp loading {...loadingCoordinate} />
          )}
          {renderState === RenderState.pickUpConfirm && (
            <MobilityPickupConfirmationMarker {...pickUpConfirmCoordinate} />
          )}
        </EttaMap>
      </MapContainer>
      {(animatingRenderState === RenderState.whereTo || renderState === RenderState.whereTo) && (
        <MobilityWhereToDialog
          isOpen={renderState === RenderState.whereTo}
          containerRef={dialogWhereToContainerRef}
          handleOnEntered={handleOnEntered}
          handleStartSearch={handleStartSearch}
        />
      )}
      {(animatingRenderState === RenderState.reserveAirportPickUp ||
        renderState === RenderState.reserveAirportPickUp) && (
        <ReviewFlightDetailsModal
          isOpen={renderState === RenderState.reserveAirportPickUp}
          handleClose={searchRideHailActions.openLeavingBookingFlowDialog}
        />
      )}
      {(animatingRenderState === RenderState.searchResult ||
        renderState === RenderState.searchResult) && (
        <MobilitySearchResultDialog
          onCardClick={handleSearchResultCardClick}
          isOpen={renderState === RenderState.searchResult}
          handleEditSearchClick={handleEditSearchClick}
          containerRef={dialogResultContainerRef}
          handleOnEntered={handleOnEntered}
        />
      )}
      {(animatingRenderState === RenderState.expenseCode ||
        renderState === RenderState.expenseCode) && (
        <ExpenseCodeFlowModal
          isOpen={renderState === RenderState.expenseCode}
          handleClose={searchRideHailActions.openLeavingBookingFlowDialog}
        />
      )}
      {(animatingRenderState === RenderState.pickUpConfirm ||
        renderState === RenderState.pickUpConfirm) && (
        <MobilityPickupConfirmationDialog
          pickUpAddress={pickUpAddress}
          isOpen={renderState === RenderState.pickUpConfirm}
          handleOnEntered={handleOnEntered}
          onConfirmButtonClick={handleConfirmPickUpButtonClick}
          isFailureOnFetchingRideHailConfig={isFailureOnFetchingRideHailConfig}
          isFetchingRideHailConfig={isFetchingRideHailConfig}
        />
      )}
      {(animatingRenderState === RenderState.review || renderState === RenderState.review) && (
        <MobilityRideReviewDialog
          containerRef={dialogReviewContainerRef}
          isOpenDialog={renderState === RenderState.review}
          handleOnEntered={handleOnEntered}
        />
      )}
      {(animatingRenderState === RenderState.confirm || renderState === RenderState.confirm) && (
        <Modal
          position="right"
          isVisible={renderState === RenderState.confirm}
          isAnimated={false}
          handleModalVisibility={searchRideHailActions.openLeavingBookingFlowDialog}
          withMaxHeight>
          <MobilityConfirmationPage />
        </Modal>
      )}
      {(animatingRenderState === RenderState.zoning || renderState === RenderState.zoning) && (
        <MobilityZoneSelectionDialog
          map={map}
          isOpen={renderState === RenderState.zoning}
          handleOnEntered={handleOnEntered}
          isFetchingRideHailConfig={isFetchingRideHailConfig}
        />
      )}
      {(animatingRenderState === RenderState.loading || renderState === RenderState.loading) && (
        <ViewStateModal viewState={viewState} onDone={handleBack} />
      )}
    </Container>
  )
})
