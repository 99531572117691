import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useScreenType, ScreenType } from '@fiji/modes'
import { SuccessDialog } from './success-dialog'
import type { Skin } from './types'
import { CancelTripModalContent } from './cancel-trip-modal-content'
import { CancelTripNotAllowedModal } from './cancel-trip-not-allowed-modal'

type Props = {
  tripTitle?: string
  trainCancelTitle?: string
  trainErrorDescription?: string
  isTripInProgress?: boolean
}

export const CancelTripModal = observer(function CancelTripModal({
  tripTitle,
  trainCancelTitle,
  trainErrorDescription,
  isTripInProgress,
}: Props) {
  const screen = useScreenType()
  const skin: Skin = screen === ScreenType.Desktop ? 'sidebar' : 'full-screen'
  const modalPosition = skin === 'sidebar' ? 'right' : undefined

  const { postBookingCancellationStore, postBookingCancellationActions } = usePostBookingContext()
  const { isCancelAllowed, cancelTripToggle, viewState } = postBookingCancellationStore

  if (!isCancelAllowed || isTripInProgress) {
    return (
      <CancelTripNotAllowedModal
        skin={skin}
        trainCancelTitle={trainCancelTitle}
        trainErrorDescription={trainErrorDescription}
      />
    )
  }

  return (
    <Modal
      isVisible={cancelTripToggle.isOpen}
      handleModalVisibility={cancelTripToggle.handleClose}
      position={modalPosition}
      horizontalDimension="content-760">
      <CancelTripModalContent
        onClose={cancelTripToggle.handleClose}
        tripTitle={tripTitle}
        skin={skin}
      />
      <SuccessDialog />
      <ViewStateModal
        viewState={viewState}
        onDone={postBookingCancellationActions.onCloseViewState}
      />
    </Modal>
  )
})
