import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { TravelerInfoContentMobile } from './mobile'
import { TravelerInfoContentDesktop } from './desktop'

export function TravelerInfoLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TravelerInfoContentMobile {...props} />}
      desktopSlot={<TravelerInfoContentDesktop {...props} />}
    />
  )
}
