import type { PropsWithChildren } from 'react'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { NDCFareBadge } from '@etta/components/air-badges/components'
import { PaymentBlock } from '../payment-block'
import type { SegmentDetails, Payment } from '../../../../types'
import { WebfareNote } from '../../webfare-note'
import { Context, Wrapper } from './segment-layout-styled'

type Props = {
  details: SegmentDetails
  payment?: Payment
  isWebFare?: boolean
  isNDCTrip?: boolean
  isRailPayment?: boolean
}

export function SegmentLayout({
  details,
  children,
  payment,
  isWebFare,
  isNDCTrip,
  isRailPayment,
}: PropsWithChildren<Props>) {
  const { title } = details
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const textVariant = isMobile ? 'captionStrong' : 'headline'
  const textColor = isMobile ? 'bodyText' : 'mainText'
  const isUpperCase = isMobile ? 'uppercase' : 'capitalize'
  return (
    <Wrapper>
      <Block>
        <Text variant={textVariant} color={textColor} textTransform={isUpperCase}>
          {title}
        </Text>
        <Context>
          {children}
          {payment && <PaymentBlock payment={payment} isRailPayment={isRailPayment} />}
          {isWebFare && <WebfareNote />}
          {isNDCTrip && <NDCFareBadge />}
        </Context>
      </Block>
    </Wrapper>
  )
}
