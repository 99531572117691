import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Spinner } from '@etta/ui/spinner'
import { Swap } from '@etta/ui/swap'
import { ModalDetails } from '@etta/components/modal-details/modal-details'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface'
import type { LayoutProps } from '../types'
import { ErrorMessage } from './mobile-components'
import { useAddFlightModal } from './use-add-flight-modal'
import { FlightDatePickerModal } from './flight-date-picker'

const i18nBase = 'Mobility.AddFlightModal'

export const AddFlightModalMobile = observer(function AddFlightModalMobile({
  onSubmit,
  onClose,
  isOpen,
}: LayoutProps) {
  const { t } = useTranslation()
  const datePickerModalToggle = useTogglePopup()

  const {
    flightNumber,
    handleFlightNumberChange,
    date,
    handleDateChange,
    dateString,
    onDiscard,
    shouldShowLoadingState,
    shouldShowCheckmark,
    shouldShowWarning,
    shouldDisableButton,
    handleSubmit,
  } = useAddFlightModal({ onClose, onSubmit })

  const { searchRideHailActions } = useRideHailSearchContext()

  return (
    <>
      <ModalDetails
        isVisible={isOpen}
        onClose={searchRideHailActions.openLeavingBookingFlowDialog}
        isAdaptivePosition>
        <Modal.Header isMobile withBorder>
          <Header
            leftSlot={
              <IconButton
                icon="arrowBackPWA"
                size="medium"
                color="mainText1"
                onClick={onDiscard}
                aria-label="back"
              />
            }
            backgroundColor="white">
            <Text variant="headline" color="mainText">
              {t(`${i18nBase}.Header`)}
            </Text>
          </Header>
        </Modal.Header>
        <Modal.Body>
          <Block paddingHorizontal={20} paddingTop={24}>
            <Block marginBottom={12}>
              <Text variant="subHeadMedium" color="bodyText">
                {t(`${i18nBase}.Title`)}
              </Text>
            </Block>
            <Block marginBottom={10}>
              <TextField
                label={t(`${i18nBase}.DatePickerLabel`)}
                onClick={datePickerModalToggle.handleOpen}
                isLoading={shouldShowLoadingState}
                value={dateString}
                startSlot={
                  <Block paddingHorizontal={16}>
                    <Icon name="calendarPWA" size={'medium'} />
                  </Block>
                }
              />
            </Block>
            <Block>
              <TextField
                label={t(`${i18nBase}.FlightInputLabel`)}
                value={flightNumber}
                onChange={(e) => handleFlightNumberChange(e.toUpperCase())}
                isLoading={shouldShowLoadingState}
                skin="default"
                startSlot={
                  <Block paddingHorizontal={16}>
                    <Icon name="flightPWA" size={'medium'} />
                  </Block>
                }
                endSlot={
                  <Swap
                    is={shouldShowLoadingState}
                    isSlot={
                      <Block paddingHorizontal={10}>
                        <Spinner size="medium" type="spinout" />
                      </Block>
                    }>
                    {shouldShowCheckmark && (
                      <Block paddingHorizontal={16}>
                        <Icon name="checkFilledSmoothPWA" color="ecoCheck" />
                      </Block>
                    )}
                  </Swap>
                }
              />
              {shouldShowWarning && (
                <ErrorMessage>
                  <Icon name={'errorFilledPWA'} color={'error'} />
                  <Block paddingLeft={12}>
                    <Text variant={'footnoteMedium'} color={'error'}>
                      {t(`${i18nBase}.InvalidFlightInfo`)}
                    </Text>
                  </Block>
                </ErrorMessage>
              )}
            </Block>
          </Block>
        </Modal.Body>
        <Modal.Footer withBorder padding="normal">
          <Button btnType="primary" onClick={handleSubmit} fullWidth disabled={shouldDisableButton}>
            {t(`${i18nBase}.Button`)}
          </Button>
        </Modal.Footer>
      </ModalDetails>
      <FlightDatePickerModal
        isOpen={datePickerModalToggle.isOpen}
        onClose={datePickerModalToggle.handleClose}
        onDateSelect={handleDateChange}
        date={date}
      />
    </>
  )
})
