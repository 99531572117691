import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import type { LoyaltyProgram, SegmentType } from '@fiji/graphql/types'
import { getUsedVendorCodes } from './get-used-vendor-codes'

type Args = {
  type: SegmentType
  segments: TripSegmentValueObject[]
  loyaltyProgram?: LoyaltyProgram[]
}

export function getLoyaltyProgram({ type, segments, loyaltyProgram = [] }: Args) {
  const vendorCodes = getUsedVendorCodes(type, segments)

  const loyaltyProgramMap = loyaltyProgram?.reduce((acc, program) => {
    return Object.assign(acc, {
      [program.vendorCode]: program,
    })
  }, {} as { [key: string]: LoyaltyProgram })

  return vendorCodes.map((vendorCode) => {
    const { number, label } = loyaltyProgramMap[vendorCode] || {}
    return {
      number,
      vendorCode,
      label,
    }
  })
}
