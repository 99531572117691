import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { LoyaltyPrograms } from '../loyalty-programs'
import { PreferenceSpecialRequests } from '../preference-special-requests'
import { PreferenceMeal } from '../preference-meal'
import { useFlightPreferences } from './use-flight-preferences'
import type { FlightPreferencesProps } from './types'

const i18nBase = 'FlightPreferences.'

export const FlightPreferences = observer(function FlightPreferences({
  onClose,
  mealOptions,
  specialRequestsOptions,
  loyaltyProgramOptions,
  loyaltyFlightValue,
  notification,
  preference,
  onChangeLoyaltyFlight,
  onChangeLoyaltyFlightToRender,
  onSubmit,
  isDisabled,
  flightLoyaltyErrors,
}: FlightPreferencesProps) {
  const { t } = useTranslation()

  const { loyaltyProgram, meal, specialRequest } = preference

  // FLIGHT_PREFENRECE_TODO: all the code is commented here as we cannot implement flight notifications yet
  // All places that need to be uncommented mentioned as FLIGHT_PREFENRECE_TODO throughout whole project
  // so you can easily find these places

  const {
    specialRequestsValues,
    onUpdateSpecialRequests,
    mealValue,
    onUpdateMeal,
    onSave,
  } = useFlightPreferences({
    mealInitial: meal?.value,
    specialRequestsInitial: specialRequest?.map((el) => el.value),
    notificationInitial: notification,
    loyaltyFlightValue,
    preference,
    onSubmit,
    onClose,
    onChangeLoyaltyFlightToRender,
  })

  return (
    <>
      <CheckoutPageModal.Header title={t(i18nBase + 'Title')} onClose={onClose} />
      <CheckoutPageModal.Body>
        {loyaltyProgramOptions && loyaltyFlightValue && (
          <LoyaltyPrograms
            label={t(i18nBase + 'LoyaltyProgram')}
            selectLabel={t(i18nBase + 'Airlines')}
            memberLabel={t(i18nBase + 'MembershipNumber')}
            options={loyaltyProgramOptions.slice().sort((a, b) => a.label.localeCompare(b.label))}
            fromProfile={loyaltyProgram}
            value={loyaltyFlightValue}
            onUpdate={onChangeLoyaltyFlight}
            placeholder={t(i18nBase + 'SearchForProgram')}
            errorMap={flightLoyaltyErrors}
          />
        )}
        <PreferenceMeal
          mealOptions={mealOptions}
          mealValue={mealValue}
          onUpdateMeal={onUpdateMeal}
        />
        <PreferenceSpecialRequests
          values={specialRequestsValues}
          options={specialRequestsOptions}
          onChange={onUpdateSpecialRequests}
        />
      </CheckoutPageModal.Body>
      <CheckoutPageModal.Footer
        isDisabled={isDisabled}
        submitLabel={t(i18nBase + 'Save')}
        onSubmit={onSave}
        onClose={onClose}
      />
    </>
  )
})
