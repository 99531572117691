import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../types'
import { Container, Text, Title } from './error-state-mobile-styled'

export function ErrorStateMobile({ onSubmit }: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TravelFusionLinks.Error'

  return (
    <Container>
      <div>
        <Title>{t(`${i18nBase}.Title`)}</Title>
        <Text>{t(`${i18nBase}.Information`)}</Text>
        <Button size="small" minWidth={17} onClick={onSubmit}>
          {t(`${i18nBase}.Button`)}
        </Button>
      </div>
    </Container>
  )
}
