import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { ClockIconWrapper, LayoverWrapper } from './expanded-view-styled'

export function TechnicalStop() {
  const { t } = useTranslation()
  const i18nBase = 'SearchSegments.Stops.'
  return (
    <LayoverWrapper>
      <Block marginBottom={2}>
        <Text color="mainText1" variant="footnoteStrong">
          {t(i18nBase + 'TechnicalStop')}
        </Text>
      </Block>
      <Text variant="footnoteMedium">{t(i18nBase + 'TechnicalStopLayover')}</Text>
      <ClockIconWrapper>
        <Icon name="timerPWA" size="normal" color="bodyText" />
      </ClockIconWrapper>
    </LayoverWrapper>
  )
}
