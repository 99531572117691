import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'

export const CardIconWrapper = styled.div<{
  backgroundColor?: keyof ColorPalette
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.secondary};
  overflow: hidden;
  min-width: 38px;
`
