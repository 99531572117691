import styled, { css } from 'styled-components'

const newSeatMapStyle = css`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Container = styled.div<{ isNewSeatMap?: boolean }>`
  ${({ isNewSeatMap }) => isNewSeatMap && newSeatMapStyle}
`
