import { dateToStringWithoutTZ } from '@fiji/utils/dates/date-to-string-without-tz'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { setUtcDate } from '@fiji/utils/dates/set-utc-date'

export function changeHourInDate({ value, hour }: { value: string; hour: number }) {
  if (!value) {
    return ''
  }

  const checkInDate = setUtcDate(new Date(deleteTimezone(value)))

  checkInDate.setHours(hour, 0, 0, 0)
  return dateToStringWithoutTZ(checkInDate)
}
