import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { CarServicePostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { Container, Separator, TitleWrapper } from './headline-styled'

type Props = {
  carService: CarServicePostBookingValueObject
  headline: string
}

const i18Base = 'CarServiceDetails.Headline'

export function Headline({ carService, headline }: Props) {
  const { t } = useTranslation()
  const { pickUpTime } = carService

  const parsedDate = pickUpTime ? new Date(deleteTimezone(pickUpTime)) : null
  const parsedAndFormattedDate = parsedDate && dateFormat(parsedDate, `'on' eee, LLL dd 'at' p`)
  return (
    <>
      <Container aria-label={headline}>
        <TitleWrapper>
          <Text variant="title2" color="mainText2" isBlock>
            {headline}
          </Text>
          <Text variant="footnoteMedium" color="mainText2">
            {t(`${i18Base}.PickUp`, { date: parsedAndFormattedDate })}
          </Text>
        </TitleWrapper>
      </Container>
      <Separator />
    </>
  )
}
