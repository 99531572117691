import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import type { Section } from '@etta/screens/payment-summary/types'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { RailReservationRow } from '../types'

type Props = {
  rail: Section
}

export function useRailDetails({ rail }: Props) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { tripCost, segments, creditCard, railAllocatedSeatMap, railSeatReservationTypeMap } = rail

  return {
    rate: tripCost.total,
    creditCard,
    rows: segments.reduce<RailReservationRow[]>((acc, segment, index) => {
      if (!segmentTypeCheckActions.isTrainSegment(segment)) {
        return acc
      }
      acc.push({
        directions: segment.segments.map(
          ({ departureDate, arrivalDate, arrival, departure, service, transportationMode }) => {
            return {
              origin: departure.stationName || '',
              destination: arrival.stationName || '',
              departDate: isoToDate(departureDate),
              arrivalDate: isoToDate(arrivalDate),
              service: service || '',
              transportationMode: transportationMode || undefined,
            }
          },
        ),
        allocatedSeat: railAllocatedSeatMap ? railAllocatedSeatMap[index] : [],
        seatReservationType: railSeatReservationTypeMap
          ? railSeatReservationTypeMap[index]
          : undefined,
        appliedRailCardName: segment.appliedRailCards.length ? segment.appliedRailCards[0] : '',
      })
      return acc
    }, []),
  }
}
