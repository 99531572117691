import { Block } from '@etta/ui/block'
import { FilterSkeleton } from '../../filter-skeleton'
import {
  Container,
  CarRentalCardSkeleton,
  FiltersBlock,
} from './car-rental-loading-placeholder-desktop-styled'

export function CarRentalLoadingPlaceholderDesktop() {
  return (
    <Block paddingHorizontal={16} paddingVertical={16}>
      <FiltersBlock>
        <FilterSkeleton />
        <FilterSkeleton />
        <FilterSkeleton />
        <FilterSkeleton />
      </FiltersBlock>
      <Container>
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
      </Container>
    </Block>
  )
}
