import styled from 'styled-components'
import { Slider } from '@etta/ui/slider'

export const StyledSlider = styled(Slider)`
  .track {
    height: 4px;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.background};
    &.is-filled {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
  .mark:after {
    top: -19px;
  }
  .mark.not-filled:after {
    background-color: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.background};
  }
`
