import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Header = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Body = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  height: 124px;
`

export const Left = styled.div``

export const Right = styled.div``
