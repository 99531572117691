import styled from 'styled-components'
import { captionMedium, footnoteMedium, subHeadStrong } from '@fiji/style'

export const Container = styled.div`
  margin: 16px 0;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
`

export const Header = styled.div`
  margin: 0 16px;
  padding: 16px 0 12px;
`

export const ServiceClass = styled.div`
  margin-bottom: 2px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.bodyText};
  ${captionMedium};
`

export const BadgesContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`

export const FaresContent = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.background1};
`

export const Footer = styled.div``

export const Item = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  ${footnoteMedium}
`

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`

export const ControlLink = styled.div`
  padding: 14px 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const SelectSeatsTitle = styled.div`
  margin: 0 0 2px;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium}
`

export const SelectSeatsList = styled.div`
  margin: 0 0 4px;
  ${subHeadStrong}
`
