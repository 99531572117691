import { useMemo } from 'react'
import type { Rate } from '@fiji/graphql/types'
import { formatPrice, formatRate } from '@fiji/utils/money'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { deleteTimezone } from '@fiji/utils/dates'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { getUserNames } from '@fiji/utils/get-user-names'

type Args = {
  totalRate?: Rate | null
  recordLocator?: string
}

export type TripOverview = {
  tripName: string
  cityImageUrl: string
  dateRange: string
  travelerFullName?: string
  travelerName?: string
  reference?: string
  recordLocator?: string
  transactionId?: string
}

export type TripCost = {
  mainTotal: string
  secondaryTotal?: string
}

export function useTripDetails({ totalRate, recordLocator }: Args = {}) {
  const {
    checkoutInfoStore: {
      primaryTraveler: { travelerData },
    },
  } = useCheckoutInfoContext()
  const { reviewTripStore } = useReviewTripContext()
  const { trip } = reviewTripStore
  const { tripInfo, itineraryId } = trip ?? {}
  const { name, imageUrl, startDate, endDate } = tripInfo ?? {}

  const startDateNoTimezone = startDate ? deleteTimezone(startDate) : ''
  const endDateNoTimezone = endDate ? deleteTimezone(endDate) : ''
  const dateRange = useDateRangeTitle(startDateNoTimezone, endDateNoTimezone)

  const tripOverview: TripOverview = useMemo(() => {
    return {
      tripName: name ?? '',
      cityImageUrl: imageUrl ?? '',
      dateRange: dateRange,
      reference: itineraryId,
      recordLocator: recordLocator,
      // TODO
      // transactionId,
      travelerFullName: getUserNames({
        firstName: travelerData?.firstName,
        lastName: travelerData?.lastName,
      }).fullName,
      travelerName: travelerData?.firstName,
    }
  }, [name, imageUrl, dateRange, itineraryId, recordLocator, travelerData])

  const tripCost: TripCost = useMemo(() => {
    if (
      totalRate &&
      totalRate.secondary &&
      totalRate.primary.currency === totalRate.secondary.currency
    ) {
      const mainTotal = formatPrice(totalRate?.secondary, {
        morpheme: 'postfix',
        isInteger: false,
      })
      return {
        mainTotal,
      }
    }
    const { mainCost, secondCost } = formatRate(totalRate)
    return {
      mainTotal: mainCost,
      secondaryTotal: secondCost,
    }
  }, [totalRate])

  return {
    tripOverview,
    tripCost,
  }
}
