import { observer } from 'mobx-react-lite'
import type { Room } from '@fiji/graphql/types'
import { hasDailyRates } from '@fiji/utils/has-daily-rates'
import { useHotelRoom } from './use-hotel-room'
import { SubmitButton } from './submit-button'
import { RoomRateLink } from './room-rate-link'
import { HotelRoomLayout } from './layout'

export type RoomRateProps = {
  room: Room
  isSelectable?: boolean
  onSelect?: (room: Room) => void
  onDetailsClick: (room: Room) => void
  totalNights: number
}

export const HotelRoom = observer(function HotelRoom({
  room,
  onSelect,
  onDetailsClick,
  isSelectable = false,
  totalNights,
}: RoomRateProps) {
  const { handleSelect, handleDetailsClick } = useHotelRoom({
    room,
    onSelect,
    onDetailsClick,
  })
  const { isSelectable: isRoomSelectable, type, dailyRates, source } = room
  const showDifferingRatesWarning =
    totalNights > 1 && (dailyRates.length === 0 || !hasDailyRates(source))

  return (
    <HotelRoomLayout
      data-tracking-id="room-card"
      room={room}
      ratesLinkSlot={<RoomRateLink onClick={handleDetailsClick} />}
      submitButtonSlot={
        isSelectable && (
          <SubmitButton onClick={handleSelect} isDisabled={!isRoomSelectable} roomType={type} />
        )
      }
      isSelectable={isSelectable}
      showDifferingRatesWarning={showDifferingRatesWarning}
    />
  )
})
