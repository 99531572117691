import { MediaLayout } from '@etta/ui/media-layout'
import { RtpItineraryCardSkeletonDesktop } from './desktop'
import { RtpItineraryCardSkeletonMobile } from './mobile'

export function RtpItineraryCardSkeletonLayout() {
  return (
    <MediaLayout
      mobileSlot={<RtpItineraryCardSkeletonMobile />}
      desktopSlot={<RtpItineraryCardSkeletonDesktop />}
    />
  )
}
