import type {
  CarServiceTripCostValueObject,
  RateValueObject,
  CarServiceChargesValueObject,
} from '@etta/core/value-objects'

export type UnvalidatedTicket = {
  forfeiture?: RateValueObject | null
  newTicket?: RateValueObject | null
  original?: RateValueObject | null
  penalty?: RateValueObject | null
  addCollect?: RateValueObject | null
  exchangedTicket?: RateValueObject | null
  residual?: RateValueObject | null
  total: RateValueObject
}

export type FareTaxInfoValueObject = {
  code: string
  name: string
  amount: RateValueObject
}

export type TripCostValueObject = {
  base?: RateValueObject | null
  carServiceCharges?: CarServiceChargesValueObject | null
  feesAndTaxes?: RateValueObject | null
  passengerNumber?: number | null
  serviceFee?: RateValueObject | null
  ticketNumber?: number | null
  total: RateValueObject
  transactionFee?: RateValueObject | null
  fareTaxInfo?: FareTaxInfoValueObject[] | null
}

export enum PriceChangeReason {
  AgencyConfig = 'AGENCY_CONFIG',
  Standard = 'STANDARD',
}

export enum PriceChangeType {
  Decrease = 'DECREASE',
  Increase = 'INCREASE',
}

export type SegmentPriceChangeDetails = {
  rate?: RateValueObject | null
  reason?: PriceChangeReason | null
  type?: PriceChangeType | null
}

export type ValidatedTicketValueObject = {
  forfeiture?: RateValueObject | null
  newTicket?: RateValueObject | null
  original?: RateValueObject | null
  penalty?: RateValueObject | null
  addCollect?: RateValueObject | null
  exchangedTicket: RateValueObject
  residual?: RateValueObject | null
  total: RateValueObject
}

export type UnusedTicketValueObject = {
  unvalidated?: UnvalidatedTicket | null
  validated?: ValidatedTicketValueObject | null
  status?: string | null
}

export type CarServiceTripCostValueObjectOrNull = CarServiceTripCostValueObject | null

export type PayLaterValueObject = {
  car?: TripCostValueObject | null
  carServices?: CarServiceTripCostValueObjectOrNull[] | null
  hotel?: TripCostValueObject | null
  subTotal: RateValueObject
}

export type TripCostOrNull = TripCostValueObject | null

export type PayNowValueObject = {
  flight?: TripCostOrNull[] | null
  hotel?: TripCostOrNull
  car?: TripCostOrNull
  subTotal: RateValueObject
  train?: TripCostOrNull[] | null
}

export type TripCostSummaryValueObject = {
  allowUnusedTicketOptout?: boolean | null
  hasOptOutUnusedTickets?: boolean | null
  payLater?: PayLaterValueObject | null
  payNow?: PayNowValueObject | null
  total: RateValueObject
  totalWithUnusedTicket?: RateValueObject | null
  unusedTicket?: UnusedTicketValueObject | null
  fareTaxInfo?: FareTaxInfoValueObject[] | null
}
