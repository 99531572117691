import { PickerDialog } from '../../picker-dialog'
import type { TimePickerProps } from '../types'
import { useSwipingTimePickerMobile } from './use-swiping-time-picker-mobile'
import { TimePicker } from './time-picker'

export function SwipingTimePickerMobile({
  isVisible,
  onClose,
  onApply,
  ...props
}: TimePickerProps) {
  const { handleTimeSelect } = useSwipingTimePickerMobile({ onApply, onClose })

  return (
    <PickerDialog isVisible={isVisible} onClose={onClose}>
      <TimePicker {...props} onApply={handleTimeSelect} />
    </PickerDialog>
  )
}
