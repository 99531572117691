import { MediaLayout } from '@etta/ui/media-layout'
import { TripCancellationDate } from '@etta/components/trip-cancellation-date/trip-cancellation-date'
import { CancellationDateDesktop } from './cancellation-date-desktop'
import type { LayoutProps } from './types'

export function CancellationDateLayout({ date }: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<TripCancellationDate date={date} />}
      desktopSlot={<CancellationDateDesktop date={date} />}
    />
  )
}
