import styled from 'styled-components'

export const TooltipTextContainer = styled.div`
  max-width: 270px;
`

export const Container = styled.div<{ isMobile?: boolean }>`
  background: #fff;
  padding: 16px 12px;
  margin: 20px 16px 0;
  border-radius: 8px;
  flex-grow: 1;
  margin-bottom: ${(p) => (p.isMobile ? '12px' : '7px')};
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const IconRow = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => (p.isMobile ? '7px' : '2px')};
`
