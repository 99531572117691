export const LoyaltyProgramsFieldsSettings = {
  airlineMemberships: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  hotelMemberships: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  carMemberships: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}
