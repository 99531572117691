import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { OutOfPolicyData } from '@fiji/graphql/types'
import { OutOfPolicyEnum } from '@fiji/graphql/types'
import type { AppState } from '@fiji/store'
import { useAppSelector } from '@fiji/store'
import { userSelector } from '@fiji/store/user'
import { addPolicyData } from './add-policy-data'

type Args = {
  outOfPolicyData?: OutOfPolicyData[] | null
}

export function useOutOfPolicyMessages({ outOfPolicyData }: Args) {
  const { profile } = useAppSelector(userSelector)
  const companyName = profile?.site?.name || ''
  const locale = useAppSelector((state: AppState) => state.user.locale)
  const t = (({ t }) =>
    function (key: string, data0?: string, data1?: string) {
      return t('SearchResults.Hotel.OutOfPolicyMessages.' + key, {
        0: data0,
        1: data1,
      })
    })(useTranslation())

  const messages: { [key in OutOfPolicyEnum]: string } = useMemo(
    () => ({
      [OutOfPolicyEnum.RoomPvtRateLtdBlocked]: t('RoomPvtRateLtdBlocked'),
      [OutOfPolicyEnum.RoomPvtRateLtdReqd]: t('RoomPvtRateLtdReqd'),
      [OutOfPolicyEnum.RoomTypeRestricted]: t('RoomTypeRestricted'),
      [OutOfPolicyEnum.RateMoreThanCompanyPerDiem]: t('RateMoreThanCompanyPerDiem'),
      [OutOfPolicyEnum.CompanyPerDiem]: t('CompanyPerDiem'),
      [OutOfPolicyEnum.PerDiemBlocked]: t('PerDiemBlocked'),
      [OutOfPolicyEnum.PreferredNotAllowed]: t('PreferredNotAllowed'),
      [OutOfPolicyEnum.PreferredChainRestricted]: t('PreferredChainRestricted'),
      [OutOfPolicyEnum.PreferredChainRequired]: t('PreferredChainRequired'),
      [OutOfPolicyEnum.PreferredHotelRequired]: t('PreferredHotelRequired'),
      [OutOfPolicyEnum.PrivateRateBlocked]: t('PrivateRateBlocked'),
      [OutOfPolicyEnum.PrivateRateRequired]: t('PrivateRateRequired'),
      [OutOfPolicyEnum.HotelRestrictedVendor]: t('HotelRestrictedVendor'),
      [OutOfPolicyEnum.RateCapViolation]: t('RateCapViolation'),
      [OutOfPolicyEnum.MaxRate]: t('MaxRate'),
      [OutOfPolicyEnum.RestrictedHotel]: t('RestrictedHotel'),
      [OutOfPolicyEnum.ExceedsNegotiatedRate]: t('ExceedsNegotiatedRate'),
      [OutOfPolicyEnum.HotelRequiredForOvernightTrip]: t(
        'HotelRequiredForOvernightTrip',
        companyName,
      ),
      [OutOfPolicyEnum.RestrictedRoomType]: t('RestrictedRoomType'),
      [OutOfPolicyEnum.ExceedsMaxSize]: t(
        'ExceedsMaxSize',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'ExceedsMaxSize',
        }),
      ),
      [OutOfPolicyEnum.TypeNotAllowed]: t('TypeNotAllowed'),
      [OutOfPolicyEnum.AdvancePurchaseFareBlocked]: t('AdvancePurchaseFareBlocked'),
      [OutOfPolicyEnum.AdvancePurchaseRequired]: t('AdvancePurchaseRequired'),
      [OutOfPolicyEnum.AirportChangeBlocked]: t('AirportChangeBlocked'),
      [OutOfPolicyEnum.AirportChangeRequired]: t('AirportChangeRequired'),
      [OutOfPolicyEnum.AlternateAirports]: t('AlternateAirports'),
      [OutOfPolicyEnum.BizClass]: t('BizClass'),
      [OutOfPolicyEnum.CancelFee]: t(
        'CancelFee',
        addPolicyData({ outOfPolicyData, policyEnum: 'CancelFee', locale }),
      ),
      [OutOfPolicyEnum.ChangeFee]: t(
        'ChangeFee',
        addPolicyData({ outOfPolicyData, policyEnum: 'ChangeFee', locale }),
      ),
      [OutOfPolicyEnum.ConnectionsBlocked]: t('ConnectionsBlocked'),
      [OutOfPolicyEnum.ConnectionsSavings]: t('ConnectionsSavings'),
      [OutOfPolicyEnum.ConnectionsNotAllowed]: t('ConnectionsNotAllowed'),
      [OutOfPolicyEnum.DiffInFeeCap]: t('DiffInFeeCap'),
      [OutOfPolicyEnum.FirstClass]: t('FirstClass'),
      [OutOfPolicyEnum.MinAdvancePurchaseDays]: t(
        'MinAdvancePurchaseDays',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'MinAdvancePurchaseDays',
        }),
      ),
      [OutOfPolicyEnum.NonRefundableFareBlocked]: t('NonRefundableFareBlocked'),
      [OutOfPolicyEnum.NonRefundableFareRequired]: t('NonRefundableFareRequired'),
      [OutOfPolicyEnum.AllPreferred]: t('AllPreferred'),
      [OutOfPolicyEnum.Preferred]: t(
        'Preferred',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'Preferred',
        }),
      ),
      [OutOfPolicyEnum.MostPreferred]: t(
        'MostPreferred',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'MostPreferred',
        }),
      ),
      [OutOfPolicyEnum.HighlyPreferred]: t(
        'HighlyPreferred',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'HighlyPreferred',
        }),
      ),
      [OutOfPolicyEnum.AllPreferredLeg]: t('AllPreferredLeg'),
      [OutOfPolicyEnum.PreferredLeg]: t(
        'PreferredLeg',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'PreferredLeg',
        }),
      ),
      [OutOfPolicyEnum.MostPreferredLeg]: t(
        'PreferredLeg',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'MostPreferredLeg',
        }),
      ),
      [OutOfPolicyEnum.HighlyPreferredLeg]: t(
        'PreferredLeg',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'HighlyPreferredLeg',
        }),
      ),
      [OutOfPolicyEnum.PremiumCoachClass]: t('PremiumCoachClass'),
      [OutOfPolicyEnum.RestrictedFareBlocked]: t('RestrictedFareBlocked'),
      [OutOfPolicyEnum.RestrictedFareRequired]: t('RestrictedFareRequired'),
      [OutOfPolicyEnum.WebFareBlocked]: t('WebFareBlocked'),
      [OutOfPolicyEnum.WebFareRequired]: t('WebFareRequired'),
      [OutOfPolicyEnum.WebFareDiffInFeeCap]: t('WebFareDiffInFeeCap'),
      [OutOfPolicyEnum.UnusedTicket]: t('UnusedTicket'),
      [OutOfPolicyEnum.AirRestrictedVendor]: t('AirRestrictedVendor'),
      [OutOfPolicyEnum.UnusedTicketNotAllowed]: t('UnusedTicketNotAllowed'),
      [OutOfPolicyEnum.AllPreferredSavingsRequired]: t(
        'AllPreferredSavingsRequired',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'AllPreferredSavingsRequired',
          locale,
        }),
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'AllPreferredSavingsRequired',
          i: 1,
        }),
      ),
      [OutOfPolicyEnum.AllPreferredSavingsRequiredNoAmount]: t(
        'AllPreferredSavingsRequiredNoAmount',
      ),
      [OutOfPolicyEnum.AlternateAirport]: t('AlternateAirport'),
      [OutOfPolicyEnum.AlternateAirportWithSavings]: t(
        'AlternateAirportWithSavings',
        addPolicyData({
          outOfPolicyData,
          policyEnum: 'AlternateAirportWithSavings',
        }),
      ),
      [OutOfPolicyEnum.ManualTransNotAllowed]: t('ManualTransNotAllowed'),
      [OutOfPolicyEnum.ManualTransRequired]: t('ManualTransRequired'),
      [OutOfPolicyEnum.AcRequired]: t('AcRequired'),
      [OutOfPolicyEnum.AcNotAllowed]: t('AcNotAllowed'),
      [OutOfPolicyEnum.CarPreferredNotAllowed]: t('CarPreferredNotAllowed'),
      [OutOfPolicyEnum.PreferredRequired]: t('PreferredRequired'),
      [OutOfPolicyEnum.FuelTypeNotAllowed]: t('FuelTypeNotAllowed'),
      [OutOfPolicyEnum.TwoDoorNotAllowed]: t('TwoDoorNotAllowed'),
      [OutOfPolicyEnum.TwoDoorRequired]: t('TwoDoorRequired'),
      [OutOfPolicyEnum.CarRestrictedVendor]: t('CarRestrictedVendor'),
      [OutOfPolicyEnum.ExceedsMaxRate]: t(
        'ExceedsMaxRate',
        addPolicyData({ outOfPolicyData, policyEnum: 'ExceedsMaxRate', locale }),
      ),
      [OutOfPolicyEnum.TypeRestricted]: t(
        'TypeRestricted',
        addPolicyData({ outOfPolicyData, policyEnum: 'TypeRestricted' }),
      ),
      [OutOfPolicyEnum.RestrictedCar]: t(
        'RestrictedCar',
        addPolicyData({ outOfPolicyData, policyEnum: 'RestrictedCar' }),
      ),
      [OutOfPolicyEnum.AutoTransRequired]: t('AutoTransRequired'),
      [OutOfPolicyEnum.FourDoorNotAllowed]: t('FourDoorNotAllowed'),
      [OutOfPolicyEnum.CarRentalRequired]: t('CarRentalRequired'),
      [OutOfPolicyEnum.CarRentalRequiredViolation]: t('CarRentalRequiredViolation'),
      [OutOfPolicyEnum.TrainBizClass]: t('TrainBizClass'),
      [OutOfPolicyEnum.TrainFirstClass]: t('TrainBizClass'),
      [OutOfPolicyEnum.DefaultPolicyReason]: t('DefaultPolicyReason'),
      [OutOfPolicyEnum.RuleRequireCompanyPreferredVendors]: t('DefaultPolicyReason'),
      [OutOfPolicyEnum.RuleTypeRequireRateBelowPrivateCost]: t('DefaultPolicyReason'),
    }),
    [locale, outOfPolicyData, companyName, t],
  )

  return {
    messages,
  }
}
