import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'

type TripData = {
  productName?: string | null
  pickUpTime?: string | null
  pickUpAddress?: string | null
}

type Props = {
  travelerFullName?: string | null
  rideHail?: TripData | null
}

function formatDate(date?: string | null) {
  if (!date) {
    return ''
  }

  return dateFormat(new Date(deleteTimezone(date)), `eee, LLL dd 'at' p`)
}

export function postBookingRideHail({ rideHail, travelerFullName }: Props) {
  if (!rideHail) {
    return undefined
  }
  const { productName, pickUpTime, pickUpAddress } = rideHail

  return `
Ride: ${productName ?? '-'}
Name: ${travelerFullName ?? '-'}
Pick-up: ${formatDate(pickUpTime)}
${pickUpAddress ?? ''}
`.trim()
}
