import { searchIdReducer } from './search-id'
import { tripReviewParamsReducer } from './trip-review-params'
import { userReducer } from './user'
import { displayConfigurationReducer } from './display-configuration'
import { brandConfigurationReducer } from './brand-configuration'
import { hotelMarkersReducer } from './hotel-markers'
import { shortlistReducer } from './shortlist'
import { hotelMapReducer } from './hotel-map'
import { hotelsReducer } from './hotels'
import { supportInformationReducer } from './support-information'
import { deemHeaderReducer } from './deem-header'
import { segmentIdsReducer } from './segment-ids'
import { postPurchaseEntranceReducer } from './post-purchase-entrance'
import { featureFlagsReducer } from './feature-flags'
import { mainNavigationReducer } from './main-navigation'
import { mobilityReducer } from './mobility'
import { zooKeeperConfigurationReducer } from './zookeeper-configuration'

export const rootReducer = {
  searchId: searchIdReducer,
  tripReviewParams: tripReviewParamsReducer,
  user: userReducer,
  displayConfiguration: displayConfigurationReducer,
  brandConfiguration: brandConfigurationReducer,
  hotelMarkers: hotelMarkersReducer,
  shortlist: shortlistReducer,
  hotelMap: hotelMapReducer,
  hotels: hotelsReducer,
  supportInformation: supportInformationReducer,
  deemHeader: deemHeaderReducer,
  segmentIds: segmentIdsReducer,
  postPurchaseEntrances: postPurchaseEntranceReducer,
  featureFlags: featureFlagsReducer,
  mainNavigation: mainNavigationReducer,
  mobility: mobilityReducer,
  zooKeeperConfiguration: zooKeeperConfigurationReducer,
}
