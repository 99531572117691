import styled from 'styled-components'
import { Env } from './types'

type WithEnv = {
  env: Env
}

const envColors: Record<Env, string> = {
  [Env.Stage2]: '#00bdbd',
  [Env.Rcqa8]: '#707070',
  [Env.Rcqa9]: '#707070',
  [Env.Rcqa10]: '#1eb600',
  [Env.Rcqa11]: '#1eb600',
  [Env.Rcqa14]: '#1eb600',
  [Env.Stage3]: '#d37800',
  [Env.Prod]: '#ce1b00',
  [Env.RcSustain]: '#745410',
}

export const Container = styled.div`
  position: absolute;
  left: 40%;
  top: 8px;
  z-index: 100;
  font-size: 1.3rem;
  // custom-font-variant
`

export const SettingsContainer = styled.div`
  padding: 0 10px;
  border-bottom: 1px solid #969595;
`

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
`

export const PopupContainer = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: relative;
  top: -8px;
  height: 70vh;
  border-radius: 7px;
  margin-top: 8px;
  background-color: #fff;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12),
    0px 7px 8px rgba(0, 0, 0, 0.2);
`

export const MenuButton = styled.div<WithEnv>`
  background-color: #fff;
  transition: background-color 150ms ease-in-out;
  opacity: 0.7;
  padding: 2px;
  border-radius: 2px;
  &:hover {
    opacity: 1;
    background-color: #f0f0f0;
  }
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => envColors[p.env] || p.theme.btnBackground};
`

export const SelectedTitle = styled.span`
  font-weight: 500;
`

export const DevEnvsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  outline: 0;
`

export const EnvIcon = styled.span<WithEnv>`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: ${(p) => envColors[p.env] || p.theme.btnBackground};
  margin-right: 12px;
`

export const DevEnvListItem = styled.li<{
  isSelected?: boolean
}>`
  height: 32px;
  padding: 0 16px 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  border-bottom: 1px solid #e2e2e2;

  &:last-child {
    border-bottom-color: transparent;
  }

  border-left: 4px solid transparent;
  border-left-color: ${(props) => (props.isSelected ? '#b5b5b5' : 'transparent')};

  background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
`
