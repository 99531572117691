import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import type { LayoutProps } from '../../types'
import { HotelResultHeaderDesktop } from './hotel-result-header-desktop'

export function HotelResultPageDesktop({ children }: LayoutProps) {
  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <HotelResultHeaderDesktop />
      <ScreenDesktop.Content as={'main'} backgroundColor="background" isFullWidth>
        {children}
      </ScreenDesktop.Content>
    </ScreenDesktop>
  )
}
