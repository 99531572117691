import { Modal } from '@etta/ui/modal'
import type { CarRentalSafetyCheckContentProps } from './types'
import { CarRentalSafetyCheckContent } from './car-rental-safety-check-content'

type Props = CarRentalSafetyCheckContentProps & {
  isOpen: boolean
  onClose: () => void
}

export function CarRentalSafetyCheckModal({ isOpen, onClose, ...props }: Props) {
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <CarRentalSafetyCheckContent {...props} />
    </Modal>
  )
}
