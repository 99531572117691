import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { CostContainer, Text } from '@etta/screens/payment-summary/payment-summary-styled'
import { Icon } from '@etta/ui/icon'
import { Tooltip } from '@etta/ui/tooltip'
import { WebFareTooltipContainer } from '@etta/components/air-badges/components/webfare/webfare'
import { Block } from '@etta/ui/block'
import { NDCFareTooltip } from '@etta/components/air-badges/components'
import { useFlightSegment } from './use-flight-segment'
import type { Props } from './types'

const i18nBase = 'PaymentSummary.'

export function FlightSegment({ segment, isMultiCityFlight }: Props) {
  const { t } = useTranslation()
  const {
    baseRateCost,
    destinationAddress,
    iconName,
    originAddress,
    cabinClassLabel,
    departureDateTime,
    isWebFare,
    isNDCFare,
  } = useFlightSegment({
    segment: segment,
    isMultiCityFlight,
  })

  if (!segment) {
    return null
  }

  return (
    <Fragment>
      <CostContainer
        aria-label={t(i18nBase + 'FlightAccessibilityLabel', {
          origin: originAddress,
          destination: destinationAddress,
          amount: baseRateCost,
        })}>
        <Text data-tracking-id="from-to-text">
          {originAddress} <Icon name={iconName} size="small" /> {destinationAddress} •{' '}
          {cabinClassLabel}
        </Text>
        {baseRateCost && (
          <Block isFlexBlock={true}>
            {isWebFare && (
              <Block marginRight={8}>
                <Tooltip slot={<WebFareTooltipContainer />} place="top" isIndependentOfParentWidth>
                  <Icon name="directBookingIcon" size={25} />
                </Tooltip>
              </Block>
            )}
            {isNDCFare && (
              <Block marginLeft="auto" paddingRight={6}>
                <NDCFareTooltip>
                  <Icon name="NDCFarePWA" size="normal" />
                </NDCFareTooltip>
              </Block>
            )}

            <Text data-tracking-id="base-rate-cost-text">{baseRateCost}</Text>
          </Block>
        )}
      </CostContainer>
      {departureDateTime && <Text data-tracking-id="departure-date-text">{departureDateTime}</Text>}
    </Fragment>
  )
}
