import type { Place } from '@fiji/types'
import type { CarLocationType, Address } from '@fiji/graphql/types'

type Props = {
  location?: CarLocationType | null
  address?: Address | null
  isDropoff?: boolean
}

export function convertToPlace({ address, location, isDropoff }: Props): Place {
  if (!location) {
    return address
      ? {
          placeId: '',
          name: address.city,
        }
      : {
          placeId: '',
          name: '',
        }
  }
  const source = isDropoff && location.dropoff ? location.dropoff : location.pickup
  const getNameFromAddress = () => {
    if (!address?.city) {
      return ''
    }
    return [address.city, address.stateCode, address.countryCode].filter(Boolean).join(', ')
  }

  return {
    airportCode: source.code || undefined,
    placeId: '',
    name: source.name || getNameFromAddress() || '',
    latitude: source.code ? address?.geocode?.lat : undefined,
    longitude: source.code ? address?.geocode?.long : undefined,
  }
}
