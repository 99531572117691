export enum Env {
  Stage2 = 'stage2',
  Rcqa8 = 'rcqa8',
  Rcqa9 = 'rcqa9',
  Rcqa10 = 'rcqa10',
  Rcqa11 = 'rcqa11',
  Rcqa14 = 'rcqa14',
  Stage3 = 'stage3',
  Prod = 'prod',
  RcSustain = 'rcsustain',
}

export type UserPreset = {
  site: string
  user: string
  rcSession: string
  token?: string
}

export type DevEnv = UserPreset & {
  env: Env
}

export type DevSettings = {
  withAutoLogin?: boolean
  usedIndex?: number
}
