import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '@etta/ui/error-container'
import { Spinner } from '@etta/ui/spinner'
import { Container } from './hotel-safety-check-error-styled'

type Props = {
  i18nBase: string
  isRefetching: boolean
  onRefetch: () => void
}

export function HotelSafetyCheckError({ i18nBase, onRefetch, isRefetching }: Props) {
  const { t } = useTranslation()

  if (isRefetching) {
    return (
      <Container>
        <Spinner type="spinout" />
      </Container>
    )
  }

  return (
    <Container>
      <ErrorContainer
        title={t(`${i18nBase}.Title`)}
        reloadTitle={t(`${i18nBase}.Button`)}
        onReload={onRefetch}
      />
    </Container>
  )
}
