import { ROUTES } from '@fiji/routes'
import { Time, Wrapper } from '../../trip-card-styles'
import { TripCardServiceIcon } from '../../trip-card-service-icon'
import defaultBackground from '../../background.svg?url'
import type { LayoutProps } from '../types'
import { TravelerNameBadgeMobile } from '../traveler-name-badge'
import { Row, Image, Title, Container } from './trip-card-mobile-styled'

export function TripCardMobile({
  tripCardAriaText,
  onCardClick,
  transactionGroupId,
  transactionId,
  isFirst,
  tripDateText,
  cityImageUrl,
  cityImageText,
  name,
  services,
  travelerName,
}: LayoutProps) {
  return (
    <Wrapper
      data-tracking-id="trip-card"
      aria-label={tripCardAriaText}
      $isFirst={isFirst}
      isMobile
      to={ROUTES.postBooking.trip({
        id: transactionId || '',
        transactionGroupId: transactionGroupId || undefined,
        isForceRefetch: true,
      })}
      onClick={onCardClick}>
      <Container>
        <Row>
          <Time>{tripDateText}</Time>
          <div aria-hidden>
            {services.map((service) => (
              <TripCardServiceIcon key={service} name={service} />
            ))}
          </div>
        </Row>
        <Title data-tracking-id="booking-name-text">{name}</Title>
      </Container>
      <TravelerNameBadgeMobile name={travelerName} />
      <Image src={cityImageUrl || defaultBackground} alt={cityImageText} />
    </Wrapper>
  )
}
