import type { ChangeEvent } from 'react'
import type { SeatSelectedArgs } from '@etta/modules/seat-map/core/types'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { SeatIcon } from '../../../../../seat-icon'
import { DummySeatItem, Input, SeatItemLabel } from './seat-item-styled'
import { useSeatItem } from './use-seat-item'

type SeatItemProps = {
  seatItem: SeatMapRowSeatValueObject
  cabinClass: string
  isSelected: boolean
  onChange: (args: SeatSelectedArgs) => void
  isEconomyClass: boolean
  isReadonly: boolean
  isBooked: boolean
  isNewSeatMap?: boolean
  onOpenPopover?: (event: ChangeEvent<HTMLInputElement>) => void
}

export function SeatItem({
  isEconomyClass,
  seatItem,
  cabinClass,
  isSelected,
  isReadonly,
  isBooked,
  isNewSeatMap,
  onChange,
  onOpenPopover,
}: SeatItemProps) {
  const { inputRef, number, isDisabled, dataTrackId, seatIcon } = useSeatItem({
    isSelected,
    isBooked,
    isNewSeatMap,
    isEconomyClass,
    isReadonly,
    seatItem,
  })

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (isDisabled) {
      return
    }

    onChange({ seat: seatItem, isSelected: !isSelected, cabinClass })
    onOpenPopover?.(event)
  }

  // BUG: it is possible for seatItem to be empty
  if (!seatItem) {
    return <DummySeatItem />
  }

  return (
    <SeatItemLabel aria-label={number} isAvailable={!isDisabled} data-tracking-id={dataTrackId}>
      <SeatIcon icon={seatIcon} />
      <Input
        ref={inputRef}
        disabled={isDisabled}
        type="checkbox"
        checked={isSelected}
        onChange={handleChange}
      />
    </SeatItemLabel>
  )
}
