import { observer } from 'mobx-react-lite'
import { Screen } from '@etta/ui/screen'
import { Block } from '@etta/ui/block'
import { HotelLocationSection } from '@etta/components/hotel-search-form/hotel-location-section'
import { HotelDateSection } from '@etta/components/segment-date-time/hotel-date-section'
import { HotelDistanceSection } from '@etta/components/hotel-search-form/hotel-distance-section'
import { SubmitSection } from '@etta/components/hotel-search-form/submit-section'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import { HotelSearchFormMobilePageRecentSearches } from '@etta/modules/recent-searches/ui/hotel-search-form-list/mobile'
import type { LayoutProps } from '../../types'
import { Line } from './hotel-search-form-mobile-styled'

export const HotelSearchFormMobile = observer(function HotelSearchFormMobile({
  handleLocationChange,
  location,
  placesHistory,
  checkOutDate,
  checkInDate,
  onDateChange,
  handleSearch,
  costAllocationSlot,
}: LayoutProps) {
  const { hotelSearchFormStore, hotelSearchFormActions } = useHotelSearchFormContext()
  const { distance } = hotelSearchFormStore.hotelForm.filters || {}

  return (
    <Screen>
      <Screen.Container>
        <Block paddingTop={30} paddingBottom={16} paddingHorizontal={16}>
          <HotelLocationSection
            location={location}
            onChange={handleLocationChange}
            placesHistory={placesHistory}
          />

          <Block marginVertical={12}>
            <HotelDateSection
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              onDateChange={onDateChange}
            />
          </Block>

          <Block marginTop={25}>
            <HotelDistanceSection
              distance={distance}
              onDistanceChange={hotelSearchFormActions.onDistanceChange}
            />
          </Block>

          <Block marginTop={25}>{costAllocationSlot}</Block>
        </Block>

        <Line />
        <HotelSearchFormMobilePageRecentSearches />

        <Block marginTop="auto" marginBottom={16} marginHorizontal={16}>
          <SubmitSection isEnabledToSubmit={true} handleSearch={handleSearch} />
        </Block>
      </Screen.Container>
    </Screen>
  )
})
