import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { RailTrain as RailTrainType } from '../../../../types'

type Props = {
  train: RailTrainType
}

const i18nBase = 'PostBooking.TripDetails'

export function RailTrain({ train }: Props) {
  const { t } = useTranslation()

  const { carriage, time, seat, direction, isOpenReturn, fareDescription } = train

  const carriageNumber = t(`${i18nBase}.TrainCarriage`, { carriageNumber: carriage })
  const seatNumber = t(`${i18nBase}.TrainSeat`, { seatNumber: seat })

  const carriageLabel = carriage ? carriageNumber : ''
  const seatNumberLabel = seat ? seatNumber : ''

  const carriageWithSeat = [carriageLabel, seatNumberLabel].filter(Boolean).join(', ')
  return (
    <>
      <Block marginBottom={24}>
        <Block marginBottom={4}>
          <Text variant="footnoteStrong" color="mainText">
            {direction}
          </Text>
        </Block>
        {time && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {time}
            </Text>
          </Block>
        )}
        {carriageWithSeat && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {carriageWithSeat}
            </Text>
          </Block>
        )}
        {isOpenReturn && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(`${i18nBase}.OpenReturn.Legend`)}
            </Text>
          </Block>
        )}
        {isOpenReturn && (
          <Block marginBottom={2}>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(`${i18nBase}.OpenReturn.FareText`, { fareDescription })}
            </Text>
          </Block>
        )}
      </Block>
    </>
  )
}
