import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace RecentSearchErrors {
  export const SubmitNewCarRentalSearchFail = createErrorClass(
    'Submit car rental recent search fail',
  )
  export const SubmitNewRailSearchFail = createErrorClass('Submit rail recent search fail')

  export const SubmitNewCarRentalSearchWithoutDatesFail = createErrorClass(
    'Submit car rental recent search without dates fail',
  )
  export const SubmitNewFlightSearchWithoutDatesFail = createErrorClass(
    'Submit flight recent search without dates fail',
  )
  export const SubmitNewHotelSearchWithoutDatesFail = createErrorClass(
    'Submit hotel recent search without dates fail',
  )
}

export type RecentSearchInstances = InstanceType<
  typeof RecentSearchErrors[keyof typeof RecentSearchErrors]
>
