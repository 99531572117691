import type { TripDetailsCarRentalSegment } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import {
  SegmentContainer,
  SegmentDescriptionContainer,
  ListItem,
  List,
} from '../duplicate-list-styled'
import { useCarRentalFormattedDate } from '../../../../use-formatted-date'

type Props = {
  car?: TripDetailsCarRentalSegment | null
}

export function DuplicateTripCarRentalSegment({ car }: Props) {
  const formattedDate = useCarRentalFormattedDate({
    start: car?.pickUpTime,
    end: car?.dropOffTime,
  })

  if (!car?.pickUpLocation?.address) {
    return null
  }

  return (
    <SegmentContainer>
      <Block marginRight={13}>
        <Icon name={'carRentalPWA'} size={32} />
      </Block>
      <div>
        <Text variant="headline" color="mainText">
          {car?.vendor?.name}
        </Text>
        <SegmentDescriptionContainer>
          <List>
            <ListItem>{formattedDate}</ListItem>
            <ListItem>{car?.vendor?.name}</ListItem>
          </List>
          <List>
            <ListItem>{formatPickUpDropOffAddress(car?.pickUpLocation?.address)}</ListItem>
          </List>
        </SegmentDescriptionContainer>
      </div>
    </SegmentContainer>
  )
}
