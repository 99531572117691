import type { OptionsType } from './types'
import { getPath } from './params/get-path'
import { getParsedData } from './params/get-parsed-data'

export class QueryParams<T> {
  constructor(
    private readonly initialData?: Partial<T>,
    private readonly options?: OptionsType<T>,
  ) {}

  getQueryParams(search: string) {
    return getParsedData<T>({
      str: search,
      initialData: this.initialData,
      options: this.options,
    })
  }

  getNextPath({
    route,
    search,
    newQueryParams = {},
  }: {
    route: string
    search: string
    newQueryParams?: Partial<T>
  }) {
    const parsedParams = this.getQueryParams(search)

    // TODO: deep merge maybe needed here?
    const newParams = Object.assign(parsedParams, newQueryParams)

    return getPath<T>(route, newParams, this.options?.caseStyle)
  }
}
