import type { Address } from '@fiji/graphql/types'
import { getDifferenceInFullDays } from '@fiji/utils/dates/get-difference-in-full-days'
import { PurchaseErrorList } from '@fiji/enums'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { CreditCardBrandType } from '@etta/core/enums'
import { ROOM_PROVIDERS } from '@fiji/constants'

type Props = {
  mustHaveAddress: boolean
  address?: Address | null
  cardExpiration?: string | null
  segments?: TripSegmentValueObject[]
  isCardOop?: boolean | null
  isExpiringCreditCardForHotelAllowed?: boolean
  isSiteCard: boolean
  isCardExist: boolean
  brandType?: CreditCardBrandType | null
}

const MIN_EXPIRATION_DAYS = 0

export function getCardCheck({
  mustHaveAddress,
  address,
  cardExpiration,
  isCardOop,
  segments,
  isSiteCard,
  isCardExist,
  isExpiringCreditCardForHotelAllowed,
  brandType,
}: Props) {
  const segmentTypeCheck = SegmentTypeCheckActions.getInstance()

  const isCardAddressValid = () => {
    if (!mustHaveAddress || isSiteCard) {
      return true
    }
    if (
      address?.city &&
      address.countryCode &&
      address.street1 &&
      address.stateCode &&
      address.postalCode
    ) {
      return true
    }

    return false
  }

  const isCardExpiringEarlierThanHotel = () => {
    if (isExpiringCreditCardForHotelAllowed === false && cardExpiration && segments) {
      return segments.some((segment) => {
        if (!segmentTypeCheck.isHotelSegment(segment)) {
          return false
        }
        const hotelCheckoutDate = new Date(segment.checkOut)
        const cardExpirationDate = new Date(cardExpiration)
        const isCardExpiringEarlierThanCheckout =
          getDifferenceInFullDays(cardExpirationDate, hotelCheckoutDate) < MIN_EXPIRATION_DAYS

        return isCardExpiringEarlierThanCheckout
      })
    }
    return false
  }

  const isBrandTypeValid = () => {
    if (!isCardExist || isSiteCard) {
      return true
    }
    if (!brandType || brandType === CreditCardBrandType.Other) {
      return false
    }
    return true
  }

  const isBookingComBrandTypeValid = () => {
    const bookingComSegment = segments?.find(
      (segment) =>
        segmentTypeCheck.isHotelSegment(segment) && segment.source === ROOM_PROVIDERS.BOOKINGCOM,
    )
    if (brandType && isBrandTypeValid() && bookingComSegment) {
      return (
        brandType === CreditCardBrandType.Ax ||
        brandType === CreditCardBrandType.Vi ||
        brandType === CreditCardBrandType.Ca
      )
    }
    return true
  }

  return {
    billingError: {
      address: isCardAddressValid() ? null : PurchaseErrorList.IncompleteAddress,
      oop: isCardOop ? PurchaseErrorList.OopCard : null,
      brandType: isBrandTypeValid() ? null : PurchaseErrorList.BrandType,
      brandTypeBookingCom: isBookingComBrandTypeValid()
        ? null
        : PurchaseErrorList.BookingComBrandType,
    },
    isCardExpiringEarlierThanHotel: isCardExpiringEarlierThanHotel(),
  }
}
