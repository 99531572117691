import * as yup from 'yup'
import cardValidator from 'card-validator'
import { useTranslation } from 'react-i18next'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'

const i18BaseErrors = 'TripPlanner.BaseSearch.RailCard.Errors'

type Props = {
  onClose: () => void
  handleAddSavedCard: (cardNumber: string) => void
}

export function useAddRailCardModal({ onClose, handleAddSavedCard }: Props) {
  const { t } = useTranslation()
  const scheme = useScheme(() => {
    return {
      cardNumber: yup
        .string()
        .test(
          'is-credit-card-number',
          t(i18BaseErrors + '.CardNumber.Invalid'),
          (value) => value?.length === 14,
        )
        .required(t(i18BaseErrors + '.CardNumber.Required')),
      expiry: yup
        .string()
        .test(
          'is-expiration-date',
          t(i18BaseErrors + '.ExpirationDate.Invalid'),
          (value) => cardValidator.expirationDate(value).isValid,
        )
        .required(t(i18BaseErrors + '.ExpirationDate.Required')),
    }
  }, [])
  const { errors, submit, values, onFieldChange } = useValidation(scheme)

  const handleSubmit = async () => {
    const submitData = await submit()
    if (!submitData.isValid) {
      return
    }
    handleAddSavedCard(values.cardNumber)
    onClose()
  }

  return {
    values,
    errors,
    onFieldChange,
    handleSubmit,
  }
}
