import { useSelector } from 'react-redux'
import { userSelector } from '@fiji/store/user'
import { getDistanceUnit } from '@fiji/utils/get-distance-unit'
import { getDistanceKey } from './get-distance-key'

export function useDistanceKey(withUppercase?: boolean) {
  const { profile } = useSelector(userSelector)

  const distanceUnit = getDistanceUnit(profile?.measurementUnit)
  const distanceKey = getDistanceKey(distanceUnit, withUppercase)

  return {
    distanceKey,
  }
}
