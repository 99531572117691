import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { UnusedTicketBreakdownTooltipLayout } from '@etta/components/unused-ticket-breakdown-tooltip'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { UnusedTicket, UnusedTicketAppliedLabel } from './unused-ticket-tooltip.styled'

const i18nBase = 'ReviewTrip'

export function UnusedTicketTooltip() {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const { reviewTripStore } = useReviewTripContext()
  const { postBookingTripStore } = usePostBookingContext()
  const { unusedTicketOriginalPrice, validatedUnusedTickets } = reviewTripStore

  const isUnusedTicketApplicable =
    featureFlags.isDesktopUnusedTicketsEnabled && !!unusedTicketOriginalPrice

  if (!isUnusedTicketApplicable) {
    return null
  }

  return (
    <UnusedTicket>
      <UnusedTicketBreakdownTooltipLayout
        isUnusedTicketValidated={!!validatedUnusedTickets}
        isRTP
        isPostBooking={!!postBookingTripStore.queryParams.bookingId}
        addCollect={validatedUnusedTickets?.addCollect}
        forfeiture={validatedUnusedTickets?.forfeiture}
        unusedTicketPrice={validatedUnusedTickets?.original}
        newTicketPrice={validatedUnusedTickets?.newTicket}
        penalty={validatedUnusedTickets?.penalty}
        unusedTicketRate={unusedTicketOriginalPrice}
        labelSlot={
          <UnusedTicketAppliedLabel aria-label={t(`${i18nBase}.UnusedTicketsApplied`)}>
            {t(`${i18nBase}.UnusedTicketsApplied`)}
          </UnusedTicketAppliedLabel>
        }
      />
    </UnusedTicket>
  )
}
