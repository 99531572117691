import { screenMatcher, ScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import type { HotelSafetyCheckProps } from './types'
import { Container } from './hotel-safety-check-styled'
import { HORIZONTAL_PADDING } from './constants'
import { HotelSafetyCheckEmpty } from './hotel-safety-check-empty'
import { HotelSafetyCheckError } from './hotel-safety-check-error'
import { useHotelSafetyCheck } from './use-hotel-safety-check'
import { HotelSafetyCheckLayout } from './layout'

const i18nBase = 'HotelSafetyCheck'

export function HotelSafetyCheck({ hotelName, lat, lng }: HotelSafetyCheckProps) {
  const { isLoading, hasError, data, isRefetching, onRefetch } = useHotelSafetyCheck({
    lat,
    lng,
  })
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const { location, scores, concernList, covidStatistic } = data || {}
  const isEmpty = !isLoading && !hasError && (!location || !scores || !concernList)

  if (hasError || isRefetching) {
    return (
      <Container isMobile={isMobile}>
        <Block paddingHorizontal={HORIZONTAL_PADDING}>
          <HotelSafetyCheckError
            i18nBase={`${i18nBase}.HotelSafetyCheckError`}
            isRefetching={isRefetching}
            onRefetch={onRefetch}
          />
        </Block>
      </Container>
    )
  }

  if (isEmpty) {
    return (
      <Container isMobile={isMobile}>
        <Block paddingHorizontal={HORIZONTAL_PADDING}>
          <HotelSafetyCheckEmpty i18nBase={`${i18nBase}.HotelSafetyCheckEmpty`} />
        </Block>
      </Container>
    )
  }

  return (
    <HotelSafetyCheckLayout
      hotelName={hotelName}
      location={location}
      scores={scores}
      concernList={concernList}
      covidStatistic={covidStatistic}
      isLoading={isLoading}
    />
  )
}
