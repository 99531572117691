import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { EditTripNameDialogDesktop } from './desktop'
import { EditTripNameDialogMobile } from './mobile'

export function EditTripNameDialogLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<EditTripNameDialogMobile {...props} />}
      desktopSlot={<EditTripNameDialogDesktop {...props} />}
    />
  )
}
