import type React from 'react'
import { useEffect } from 'react'

export function useList(
  ref: React.RefObject<HTMLElement>,
  forwardRef: React.ForwardedRef<HTMLElement>,
) {
  const currentRef = ref.current

  useEffect(() => {
    if (!forwardRef) {
      return
    }
    if (typeof forwardRef !== 'function') {
      forwardRef.current = currentRef
      return
    }

    forwardRef(currentRef)
  }, [forwardRef, currentRef])
}
