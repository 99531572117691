import { MediaLayout } from '@etta/ui/media-layout/media-layout'
import type { LayoutProps } from '../seat-map.types'
import { SeatMapDesktop } from './desktop'
import { SeatMapMobile } from './mobile'

export function SeatMapLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SeatMapMobile {...props} />}
      desktopSlot={<SeatMapDesktop {...props} />}
    />
  )
}
