import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../types'
import { MessageContainer } from '../../message-container'
import { MessageSection } from '../../message-section'

export function RowMessageItemDesktop({
  title,
  message,
  messageSlot,
  sectionSlot,
  iconName,
  iconColor,
  hasMultipleItems,
  hasIcon,
  hasDesktopBannerImage,
  shouldExpandMessages,
}: LayoutProps) {
  return (
    <MessageContainer>
      {hasIcon && (
        <Block marginRight={14}>
          <Icon name={iconName} color={iconColor} size="medium" />
        </Block>
      )}
      <MessageSection
        title={title}
        message={message}
        hasMultipleItems={hasMultipleItems}
        hasDesktopBannerImage={hasDesktopBannerImage}
        shouldExpandMessages={shouldExpandMessages}
        messageSlot={messageSlot}
        sectionSlot={sectionSlot}
      />
    </MessageContainer>
  )
}
