import { Store } from '@etta/di'
import { Themes } from '../../core/enums'

@Store()
export class ThemeConfigurationStore {
  theme: Themes = Themes.Etta
  get isDarkTheme() {
    return this.theme === Themes.EttaDark
  }

  setTheme(theme: Themes) {
    this.theme = theme
  }
}
