import styled from 'styled-components'

export const DepartureAndArrival = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChangesLine = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 8px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  align-self: flex-end;
  display: flex;
  align-items: center;
`
