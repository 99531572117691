import { Action, Inject } from '@etta/di'
import type { PreferenceOptionsValueObject } from '@etta/modules/travel-preferences/core/value-objects/preference-options.value-object'
import { TravelPreferencesService } from '../services/travel-preferences.service'
import { TravelPreferencesStore } from '../stores/travel-preferences.store'

@Action()
export class TravelPreferencesActions {
  constructor(
    @Inject()
    private readonly travelPreferencesService: TravelPreferencesService,
    @Inject()
    private readonly travelPreferencesStore: TravelPreferencesStore,
  ) {}

  getPreferences() {
    return this.travelPreferencesService.getPreferences()
  }

  getMappedLoyaltyPrograms() {
    return this.travelPreferencesService.getMappedLoyaltyPrograms()
  }

  getSpecialRequestValues(specialRequests: PreferenceOptionsValueObject[]) {
    return this.travelPreferencesService.getSpecialRequestValues(specialRequests)
  }

  handleTravelPreferencesModalOpen() {
    this.travelPreferencesStore.travelPreferencesToggle.handleOpen()
  }

  handleTravelPreferencesModalClose() {
    this.travelPreferencesStore.travelPreferencesToggle.handleClose()
  }

  handleLoyaltyProgramsModalOpen() {
    this.travelPreferencesStore.loyaltyProgramsToggle.handleOpen()
  }

  handleLoyaltyProgramsModalClose() {
    this.travelPreferencesStore.loyaltyProgramsToggle.handleClose()
  }
}
