import { IconButton } from '@etta/ui/icon-button'
import { LogoContainer, HeaderContainer } from '../hotel-details-eco-check-modal-desktop-styled'
import EcoCheckLogoSVG from '../assets/eco-check-logo.svg?url'

type Props = {
  onClose: () => void
}

export function FlightDetailsEcoCheckModalDesktopHeader({ onClose }: Props) {
  return (
    <HeaderContainer>
      <IconButton icon="arrowBackLargePWA" size="medium" color="mainText" onClick={onClose} />
      <LogoContainer>
        <img src={EcoCheckLogoSVG} width="121" height="20" alt="eco check logo" />
      </LogoContainer>
    </HeaderContainer>
  )
}
