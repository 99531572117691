import { Action, Inject } from '@etta/di'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import type { BusinessAddressInput } from '../stores/business-address.store'
import { BusinessAddressStore } from '../stores/business-address.store'
import { BaseSettingsService } from '../services/base.service'
import { BaseSettingsStore } from '../stores/base.store'

@Action()
export class BusinessAddressActions {
  constructor(
    @Inject()
    private readonly businessAddressStore: BusinessAddressStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  handleFieldChange<
    T extends keyof Omit<BusinessAddressInput, 'officePhone' | 'mobilePhone' | 'faxPhone'>
  >(field: T) {
    return (value: BusinessAddressInput[T]) => {
      this.businessAddressStore.setIsSaveButtonDisabled(false)
      return this.businessAddressStore.setBusinessAddressValue(field, value)
    }
  }

  handlePhoneChange(
    type: 'officePhone' | 'mobilePhone' | 'faxPhone',
    field: 'CountryCode' | 'Number' | 'Extension',
    value: string,
  ) {
    this.businessAddressStore.setIsSaveButtonDisabled(false)
    return this.businessAddressStore.setBusinessPhoneValue(type, field, value)
  }

  handleConfEmailInputVisible(value: boolean) {
    this.businessAddressStore.setIsSaveButtonDisabled(false)
    this.businessAddressStore.setIsConfEmailInputVisible(value)
  }

  async handleBusinessAddressSave() {
    const validatorCheck = await this.businessAddressStore.businessAddressValidator.submit()
    const additionalValidatorCheck = await this.businessAddressStore.additionalInformationValidator.submit()
    if (!validatorCheck.isValid || !additionalValidatorCheck.isValid) {
      return
    }

    const {
      values: {
        businessEmail,
        officePhoneCountryCode,
        officePhoneNumber,
        officePhoneExtension,
        faxPhoneCountryCode,
        faxPhoneNumber,
        mobilePhoneCountryCode,
        mobilePhoneNumber,
        groupwareId,
        confirmationEmail,
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
    } = this.businessAddressStore.businessAddressValidator

    const input = {
      businessAddress: {
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
      mobilePhone: {
        countryCode: mobilePhoneCountryCode,
        number: mobilePhoneNumber,
      },
      officePhone: {
        countryCode: officePhoneCountryCode,
        number: officePhoneNumber,
        officeExtension: officePhoneExtension,
      },
      faxPhone: {
        countryCode: faxPhoneCountryCode,
        number: faxPhoneNumber,
      },
      email: businessEmail,
      groupware: {
        groupwareId,
      },
      confEmail: confirmationEmail,
    }

    const updateAdditionalInformationSuccess: boolean = await this.userProfileService.saveUserProfileBusinessAddress(
      this.businessAddressStore.additionalInformationValidator.values,
    )

    if (updateAdditionalInformationSuccess) {
      this.baseSettingsService.saveUser({
        mode: 'personal-information',
        input,
        handleClose: () => {
          this.businessAddressStore.setIsSaveButtonDisabled(true)
          this.businessAddressStore.toggle.handleClose()
        },
      })
    } else {
      // TODO: do we need to provide a more accurate error message with field failure details??
      this.baseSettingsStore.setErrorState()
      return
    }

    this.userProfileService.fetchUserProfile()
  }

  setBusinessInformation() {
    const { businessAddress } = this.baseSettingsService.getStructuredProfile()
    this.businessAddressStore.setBusinessAddress(businessAddress)
    const additionalInformation =
      this.userProfileStore.userProfile?.businessAddress.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.businessAddressStore.setAdditionalInfoValidator(validator)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.businessAddressStore.setIsSaveButtonDisabled(false)
      return this.businessAddressStore.setAdditionalInfoValue(key, value)
    }
  }

  handleEditModalOpening() {
    this.businessAddressStore.setIsSaveButtonDisabled(true)
    this.setBusinessInformation()

    if (this.businessAddressStore.isConfirmationEmailAvailable) {
      this.businessAddressStore.setIsConfEmailInputVisible(true)
    }

    this.businessAddressStore.toggle.handleOpen()
  }
}
