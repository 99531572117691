import styled from 'styled-components'
import {
  subHeadMedium,
  captionStrongCaps,
  title2,
  linkSmall,
  getKeyboardNavigationStyle,
  KeyboardNavigationStyle,
} from '@fiji/style'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
  ${getKeyboardNavigationStyle({ borderRadius: 14, offset: 2 })}
`

export const EcoCheckBackground = styled.img`
  width: 370px;
  border-radius: 0 0 14px 14px;
  margin-bottom: -5px;
  height: 160px;
`

export const Title = styled.div`
  padding: 20px 0 16px 20px;
`

export const Body = styled.div`
  padding-left: 20px;
  display: flex;
  width: 100%;
`

export const BodyRight = styled.div`
  margin-left: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${subHeadMedium}
`

export const EcoCerfiedLabel = styled.div`
  ${captionStrongCaps}
  color: ${(props) => props.theme.colors.success};
  text-trasform: uppercase;
  margin-bottom: 3px;
`

export const LearnMore = styled.a`
  display: block;
  width: fit-content;
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  ${linkSmall};
  ${KeyboardNavigationStyle}
`

// Circle progress
export const CircleWrap = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  background: ${(props) => props.theme.colors.background};
  border-radius: 50%;
`

export const Mask = styled.div`
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 50%;
  clip: rect(0px, 56px, 56px, 28px);
`

export const MaskFull = styled(Mask)<{ sustainabilityScore: number }>`
  transform: rotate(${(props) => (180 * props.sustainabilityScore) / 100}deg);
`

export const Fill = styled.div<{
  deviationLevel: EmissionsDeviationLevel
  sustainabilityScore: number
}>`
  clip: rect(0px, 28px, 56px, 0px);
  background-color: ${(props) => {
    switch (props.deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return props.theme.colors.background2
      case EmissionsDeviationLevel.Less:
        return props.theme.colors.success
      default:
        return props.theme.colors.bodyText1
    }
  }};
  width: 56px;
  height: 56px;
  position: absolute;
  border-radius: 50%;
  transform: rotate(${(props) => (180 * props.sustainabilityScore) / 100}deg);
`

export const MaskHalf = styled(Mask)``

export const InsideCircle = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.ecoCheck1
      : props.theme.colors.borderLight};

  text-align: center;
  margin-top: 2px;
  margin-left: 2px;
  color: ${(props) => {
    switch (props.deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return props.theme.colors.background2
      case EmissionsDeviationLevel.Less:
        return props.theme.colors.success
      case EmissionsDeviationLevel.More:
        return props.theme.colors.bodyText1
    }
  }};

  position: absolute;
  padding-top: 9px;
  z-index: 1;
  ${title2}
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const BottomLine = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  height: 8px;
  width: 100%;
  background-color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.mainText1};
  border-radius: 0 0 14px 14px;
  position: relative;
`

export const EmissionsDescription = styled.span`
  ${subHeadMedium};
`
