import { useEffect, useMemo, useCallback, useState } from 'react'
import { useTheme } from '@fiji/hooks/use-theme'
import { useMobilityDialogRef } from '@etta/screens/mobility-search-page/hooks/use-mobility-dialog-ref'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useEttaMapContext } from '@etta/modules/etta-map/interface/use-etta-map.context'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { Props, AccessPoint, Zone } from './types'
import { getGoogleMapCoordWithOffset, createCoordsFromGoogleMapPolygon } from './utils'

export function isZone(selected: Zone | AccessPoint): selected is Zone {
  return (selected as Zone).name !== undefined
}

export function isAccessPoint(selected: Zone | AccessPoint): selected is AccessPoint {
  return (selected as AccessPoint).label !== undefined
}

const accesspointMarkerOption = {
  className: 'mapkit-mobility-marker',
  path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
  strokeWeight: 4,
  scale: 0.3,
}

export function useMobilityZoneSelectionDialog({ map }: Props) {
  const {
    searchRideHailActions,
    searchRideHailStore,
    rideHailSearchLocationRefinementActions,
    rideHailSearchLocationRefinementStore,
  } = useRideHailSearchContext()
  const { setRenderState } = searchRideHailActions
  const { isFetchingRideHailConfig } = searchRideHailStore
  const {
    ettaMapStore: { isAppleMap },
  } = useEttaMapContext()

  const { dialogHeight, ref: dialogContainerRef } = useMobilityDialogRef()
  const {
    colors: { mainText1, primary },
  } = useTheme()
  const [shouldReDrawPolygon, setShouldReDrawPolygon] = useState(true)

  const {
    zoneName,
    childZones,
    selectedAccessPoint,
    selectedZone,
    isLoading,
    refinementOptions: locationOptions,
    refinementOptionsAction,
    renderType,
    accessPoints,
  } = rideHailSearchLocationRefinementStore
  const polygon = selectedZone?.polygon || locationOptions?.rootZone?.polygon

  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile

  useEffect(() => {
    if (map && accessPoints && rideHailSearchLocationRefinementStore.renderType === 'AccessPoint') {
      const accessPointMarkers = accessPoints
        .filter((ap) => ap.geocode)
        .map(({ geocode }) => {
          return { lat: geocode!.lat, lng: geocode!.long }
        })
      map.setMarkers(accessPointMarkers, accesspointMarkerOption)
    }
    return () => map?.clearMarkers()
  }, [map, accessPoints, rideHailSearchLocationRefinementStore.renderType])

  const handleDrawPolygon = useCallback(
    (polygon?: string) => {
      if (map && polygon) {
        map.clearPolygon()
        const polygonCoords = createCoordsFromGoogleMapPolygon(polygon)

        map.setPolygon(polygonCoords, {
          strokeColor: mainText1,
          fillColor: mainText1,
          fillOpacity: 0.2,
        })
      }
    },
    [map, mainText1],
  )

  useEffect(() => {
    // AppleMapKit has an issue when executing setBounds and setPolygon simultaneously.
    // The animation of those calls will hang and cause unwanted results.
    // setTimeout is required to prevent this issue.
    if (isAppleMap && shouldReDrawPolygon) {
      setTimeout(() => {
        handleDrawPolygon(polygon)
      }, 500)
      setShouldReDrawPolygon(false)
      return
    }

    handleDrawPolygon(polygon)
  }, [handleDrawPolygon, polygon, isAppleMap, shouldReDrawPolygon])

  // control map boundary and padding
  useEffect(() => {
    if (!map) {
      return
    }

    if (dialogHeight === 0) {
      return
    }

    const bottom = isDesktop ? dialogHeight * 1.25 : dialogHeight + 20
    const padding = { bottom, left: 20, right: 20, top: 80 }
    if (accessPoints && rideHailSearchLocationRefinementStore.renderType === 'AccessPoint') {
      const coords = accessPoints
        .filter((ap) => ap.geocode)
        .map(({ geocode }) => {
          return { lat: geocode!.lat, lng: geocode!.long }
        })
      const coordsWithOffset = getGoogleMapCoordWithOffset(coords)
      const bounds = map.createBoundsByCoordinates(coordsWithOffset)
      map.setBounds(bounds, padding)
      return
    }

    if (polygon) {
      const polygonCoords = createCoordsFromGoogleMapPolygon(polygon)

      const bounds = map.createBoundsByCoordinates(polygonCoords)
      map.setBounds(bounds, padding)
    }
  }, [
    rideHailSearchLocationRefinementStore.renderType,
    polygon,
    dialogHeight,
    accessPoints,
    map,
    isDesktop,
  ])

  const handleSelect = (selected: Zone | AccessPoint) => {
    if (isZone(selected)) {
      if (selected.name === selectedZone?.name) {
        rideHailSearchLocationRefinementActions.handleUpdateSelectedZone(null)
        handleDrawPolygon(locationOptions?.rootZone?.polygon)
        return
      }
      rideHailSearchLocationRefinementActions.handleUpdateSelectedZone(selected)
      handleDrawPolygon(selected?.polygon)
    }
    if (isAccessPoint(selected)) {
      if (selected.id === rideHailSearchLocationRefinementStore.selectedAccessPoint?.id) {
        rideHailSearchLocationRefinementActions.handleUpdateSelectedAccessPoint(null)
        if (selected.geocode) {
          map?.setMarkers(
            [{ lat: selected.geocode.lat, lng: selected.geocode.long }],
            accesspointMarkerOption,
          )
        }
        return
      }
      if (selected.geocode) {
        const accessPointMarkers = accessPoints
          ?.filter((ap) => ap.geocode)
          .map(({ geocode }) => {
            return { lat: geocode!.lat, lng: geocode!.long }
          })
        accessPointMarkers && map?.setMarkers(accessPointMarkers, accesspointMarkerOption)

        map?.setMarkers([{ lat: selected.geocode.lat, lng: selected.geocode.long }], {
          ...accesspointMarkerOption,
          className: 'mapkit-mobility-marker-selected',
          strokeColor: primary,
        })
      }
      rideHailSearchLocationRefinementActions.handleUpdateSelectedAccessPoint(selected)
    }
  }

  const handleNextButton = async () => {
    if (!selectedAccessPoint) {
      rideHailSearchLocationRefinementActions.handleUpdateRenderType('AccessPoint')
      return
    }

    if (isFetchingRideHailConfig) {
      return
    }
    await rideHailSearchLocationRefinementActions.handleContinue(() => {
      map?.clearPolygon()
      setRenderState(RenderState.review)
    })
  }

  const shouldDisableNextButton = useMemo(() => {
    if (isLoading) {
      return true
    }
    switch (rideHailSearchLocationRefinementStore.renderType) {
      case 'ChildZone':
        return !selectedZone
      case 'AccessPoint':
        return !selectedAccessPoint
    }
  }, [
    isLoading,
    selectedAccessPoint,
    selectedZone,
    rideHailSearchLocationRefinementStore.renderType,
  ])

  return {
    shouldRenderChildZone: renderType === 'ChildZone',
    zoneName:
      zoneName ??
      searchRideHailStore.searchRideHailForm.pickUpAddress ??
      searchRideHailStore.searchRideHailForm.pickUpPlace?.name ??
      '',
    childZones,
    accessPoints,
    handleNextButton,
    selectedZone,
    selectedAccessPoint,
    shouldDisableNextButton,
    isLoading,
    dialogContainerRef,
    handleSelect,
    refinementOptionsAction,
  }
}
