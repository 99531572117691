import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
`

const Body = styled.div`
  padding: 0 16px;
  display: flex;
  width: 100%;
`
const Title = styled.div`
  width: 90px;
  height: 20px;
  margin: 12px 16px 16px 16px;
  ${glowingFragment}
`

const BodyLeft = styled.div`
  width: 143px;
  height: 50px;
  margin-right: 24px;
  ${glowingFragment}
`

const LongText = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: 11px;
  ${glowingFragment};
`

const ShortText = styled.div`
  width: 60%;
  height: 16px;
  ${glowingFragment};
`

const BodyRightContainer = styled.div`
  width: 100%;
`

const Background = styled.div`
  height: 60px;
  width: 100%;
  margin-top: 50px;
  border-radius: 0 0 14px 14px;
  ${glowingFragment};
`

export function CarRentalDetailsEcoCheckMobileSkeleton() {
  return (
    <Container>
      <Title />
      <Body>
        <BodyLeft />
        <BodyRightContainer>
          <LongText />
          <ShortText />
        </BodyRightContainer>
      </Body>
      <Background />
    </Container>
  )
}
