import { ModalDetails } from '@etta/components/modal-details'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import type { CarEmission } from '@fiji/types'
import { CarRentalDetailsContent } from './car-rental-details-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  carRental: CarRentalSegmentEntity
  isTripExist: boolean
  itineraryId: string
  carRentalId: string
  carRentalEmission: CarEmission
}

export function CarRentalDetailsModal({
  isOpen,
  onClose,
  carRental,
  carRentalId,
  isTripExist,
  itineraryId,
  carRentalEmission,
}: Props) {
  const rulesModal = useTogglePopup()

  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <CarRentalDetailsContent
        onClose={onClose}
        carRental={carRental}
        carRentalId={carRentalId}
        itineraryId={itineraryId}
        isTripExist={isTripExist}
        isRulesModalOpen={rulesModal.isOpen}
        onOpenRulesModal={rulesModal.handleOpen}
        onCloseRulesModal={rulesModal.handleClose}
        carRentalEmission={carRentalEmission}
      />
    </ModalDetails>
  )
}
