import styled from 'styled-components'
import { headline, footnoteMedium } from '@fiji/style'

export const Wrapper = styled.div`
  padding: 18px 14px 14px;
`

export const Content = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.div`
  margin-left: 4px;
  margin-bottom: -1px;
`

export const LogoContainer = styled.div`
  height: 22px;
  width: 55px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const Title = styled.div`
  ${headline};
  color: ${(p) => p.theme.colors.mainText};
`

export const Capacity = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0px 0px 0px;
  align-items: center;
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
  gap: 12px;
  padding: 0px;
  list-style-type: none;
`

export const Unit = styled.li`
  color: ${(p) => p.theme.colors.bodyText};
  margin-bottom: -2px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Image = styled.img`
  height: 100%;
  width: auto;
`

export const CarRental = styled.div`
  white-space: nowrap;
`

export const CarRentalEmissionDeviationContainer = styled.div`
  white-space: nowrap;
`

export const PreferedLabelContainer = styled.div`
  display: flex;
  color: ${(p) => p.theme.colors.mainText};
  align-items: center;
  ${footnoteMedium}
`
