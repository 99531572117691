import { Skeleton } from '@etta/ui/skeleton'
import { Wrapper } from './trip-card-skeleton-desktop-styles'

export function TripCardSkeletonDesktop() {
  return (
    <Wrapper>
      <Skeleton width="800px" height="162px">
        <rect width="262" height="120" x="20" y="20" rx="10" ry="10" />
        <rect width="302" height="16" x="305" y="20" />
        <rect width="70" height="12" x="305" y="44" />
        <rect width="36" height="16" x="748" y="20" />
      </Skeleton>
    </Wrapper>
  )
}
