import { Action, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import i18n from '@fiji/i18n'
import { appMode } from '@fiji/modes'
// eslint-disable-next-line import/no-restricted-paths
import { SubmitSearchService } from '@etta/modules/recent-searches/interface/services/submit-search.service'
import type { CarSearchQueryType } from '@fiji/hooks/search-queries'
import { CostAllocationFormStore } from '@etta/modules/cost-allocation/interface/stores/cost-allocation-form.store'
import { PreSearchStore } from '@etta/modules/pre-search'
import { CarSearchFormStore } from '../stores/car-search-form.store'
import { CarSearchFormQueryService } from '../services/car-search-form-query.service'
import type { ValidationErrors } from './../stores/car-search-form.store'

const i18nBase = 'TripPlanner.BaseSearch.Input'

@Action()
export class CarSearchButtonActions {
  constructor(
    @Inject()
    private readonly carSearchFormQueryService: CarSearchFormQueryService,
    @Inject()
    private readonly carSearchFormStore: CarSearchFormStore,
    @Inject()
    private readonly submitSearchService: SubmitSearchService,
    @Inject()
    private readonly costAllocationFormStore: CostAllocationFormStore,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
  ) {}

  isTimeValid({
    pickUpDate,
    pickUpTime,
    dropOffDate,
    dropOffTime,
  }: Pick<CarSearchQueryType, 'pickUpDate' | 'pickUpTime' | 'dropOffDate' | 'dropOffTime'>) {
    const areDaysSame =
      pickUpDate &&
      dropOffDate &&
      new Date(pickUpDate.toDateString()).getTime() ===
        new Date(dropOffDate.toDateString()).getTime()
    const areHoursSame = dropOffTime && pickUpTime && pickUpTime.hours === dropOffTime.hours
    const arePickUpHoursCorrect = dropOffTime && pickUpTime && pickUpTime.hours <= dropOffTime.hours
    const areMinutesCorrect = dropOffTime && pickUpTime && pickUpTime.minutes <= dropOffTime.minutes

    if (!areDaysSame) {
      return true
    }

    if (!arePickUpHoursCorrect) {
      return false
    }

    if (areHoursSame) {
      return areMinutesCorrect
    }

    return true
  }

  getValidationErrors() {
    const validationErrors: ValidationErrors = {}
    const {
      pickUpPlace,
      dropOffPlace,
      pickUpDate,
      dropOffDate,
      pickUpTime,
      dropOffTime,
    } = this.carSearchFormStore.carForm

    if (!pickUpPlace) {
      validationErrors.pickUpPlace = i18n.t(`${i18nBase}.EnterPickupLocation`)
    }

    if (!dropOffPlace) {
      validationErrors.dropOffPlace = i18n.t(`${i18nBase}.EnterDropoffLocation`)
    }

    if (!pickUpDate) {
      validationErrors.pickUpDate = i18n.t(`${i18nBase}.SelectDates`)
    }

    if (!dropOffDate) {
      validationErrors.dropOffDate = i18n.t(`${i18nBase}.SelectDates`)
    }

    if (!pickUpTime) {
      validationErrors.pickUpTime = i18n.t(`${i18nBase}.SelectTime`)
    }

    if (!dropOffTime) {
      validationErrors.dropOffTime = i18n.t(`${i18nBase}.SelectTime`)
    }

    if (
      pickUpDate &&
      pickUpTime &&
      dropOffDate &&
      dropOffTime &&
      !this.isTimeValid({ pickUpDate, pickUpTime, dropOffDate, dropOffTime })
    ) {
      validationErrors.dropOffTime = i18n.t(`${i18nBase}.InvalidTime`)
    }

    return validationErrors
  }

  isInputValid() {
    const validationErrors = this.getValidationErrors()

    return !Object.keys(validationErrors).length
  }

  setValidationErrors() {
    const validationErrors = this.getValidationErrors()
    this.carSearchFormStore.setValidationErrors(validationErrors)
  }

  clearValidationErrors() {
    this.carSearchFormStore.setValidationErrors({})
  }

  handleSubmit() {
    const params = {
      ...this.carSearchFormStore.carForm,
      customFields: this.preSearchStore.customFields,
    }
    const {
      pickUpPlace,
      dropOffPlace,
      pickUpDate,
      pickUpTime,
      dropOffDate,
      dropOffTime,
      bookingId,
    } = params

    const isInputValid = this.isInputValid()
    const isMobile = !appMode.isEttaDesktop

    const isCostAllocationValid = this.costAllocationFormStore.checkCostAllocation()
    const shouldCostValidationBeChecked = bookingId && !isMobile ? false : !isCostAllocationValid

    // The check is located outside isInputValid to force to not add assertions in the recent searches input
    if (
      !isInputValid ||
      shouldCostValidationBeChecked ||
      !pickUpPlace ||
      !pickUpDate ||
      !pickUpTime ||
      !dropOffPlace ||
      !dropOffDate ||
      !dropOffTime
    ) {
      this.setValidationErrors()
      return
    }

    this.clearValidationErrors()

    this.submitSearchService.submitNewCarRentalSearch({
      pickUpDate,
      pickUpPlace,
      pickUpTime,
      dropOffDate,
      dropOffPlace,
      dropOffTime,
    })

    if (!pickUpPlace?.airportCode) {
      this.carSearchFormQueryService.navigateTo(ROUTES.carRental.pickUp, params)
      return
    }

    if (!dropOffPlace?.airportCode) {
      this.carSearchFormQueryService.navigateTo(ROUTES.carRental.dropOff, params)
      return
    }

    const { bookingId: _, ...otherParams } = params
    this.carSearchFormQueryService.navigateTo(ROUTES.carRental.results, {
      ...otherParams,
      ...(bookingId && { bookingId }),
    })
  }
}
