import type { StationFilterEntity } from '@etta/modules/rail/core/entities/station-filter.entity'
import type { StationFilterStationValueObject } from '@etta/modules/rail/core/value-objects/station-filter-station.value-object'

export function getRailFilterStations(stations: StationFilterEntity[]) {
  const originStations: StationFilterStationValueObject[] = []
  const originCodes: string[] = []
  const destinationStations: StationFilterStationValueObject[] = []
  const destinationCodes: string[] = []

  stations.forEach((station) => {
    if (!originCodes.includes(station.origin.code)) {
      originStations.push(station.origin)
      originCodes.push(station.origin.code)
    }
    if (!destinationCodes.includes(station.destination.code)) {
      destinationStations.push(station.destination)
      destinationCodes.push(station.destination.code)
    }
  })

  const sortedOriginStations = originStations.sort((a, b) => a.code.localeCompare(b.code))
  const sortedDestinationStations = destinationStations.sort((a, b) => a.code.localeCompare(b.code))

  return { originStations: sortedOriginStations, destinationStations: sortedDestinationStations }
}

export function getStationLabel({ code, name }: StationFilterStationValueObject) {
  const station = `(${code})`
  const label = [station]
  if (name) {
    label.push(name)
  }
  return label.join(' ')
}
