import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { RailSearchFormMobile } from './mobile'
import { RailSearchFormDesktop } from './desktop'

export function RailSearchFormLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailSearchFormMobile {...props} />}
      desktopSlot={<RailSearchFormDesktop {...props} />}
    />
  )
}
