import { Result } from 'fnscript'
import { Inject } from 'typedi'
import { Adapter } from '@etta/di'
import { CustomerUberAccountManagementDataProvider } from '@etta/modules/ride-hail/infra/customer-uber-account-management/customer-uber-account-management.data-provider'
import type {
  CreateCustomerUberAccountAdapterResult,
  GetCustomerUberAccountStatusAdapterResult,
} from '@etta/modules/ride-hail/infra/types'
import type { UberAccountCreationValueObject } from '@etta/modules/ride-hail/core/value-objects/uber-account-creation.value-object'
import { CreateCustomerUberAccountError } from '@etta/modules/ride-hail/core/errors/create-customer-uber-account.error'
import { CreateCustomerUberAccountErrorReason } from '@fiji/graphql/hooks'
import type { UberAccountStatusValueObject } from '@etta/modules/ride-hail/core/value-objects/uber-account-status.value-object'
import { mapToUberAccountStatusEnum } from '@etta/modules/ride-hail/infra/mappers/customer-uber-account-status.mapper'
import { GetCustomerUberAccountStatusError } from '@etta/modules/ride-hail/core/errors/get-customer-uber-account-status.error'

@Adapter()
export class CustomerUberAccountManagementAdapter {
  constructor(
    @Inject()
    private createCustomerUberAccountDataProvider: CustomerUberAccountManagementDataProvider,
  ) {}

  async createCustomerUberAccount(): CreateCustomerUberAccountAdapterResult<UberAccountCreationValueObject> {
    try {
      const { data } = await this.createCustomerUberAccountDataProvider.createCustomerUberAccount()

      if (!data || data.createCustomerUberAccount.__typename === 'UnexpectedError') {
        return Result.Err(
          new CreateCustomerUberAccountError.UnexpectedCreateCustomerUberAccountError(''),
        )
      }

      if (data?.createCustomerUberAccount.__typename === 'CreateCustomerUberAccountErrorResponse') {
        switch (data.createCustomerUberAccount.reason) {
          case CreateCustomerUberAccountErrorReason.NotEnabled:
            return Result.Err(
              new CreateCustomerUberAccountError.NotEnabledCreateCustomerUberAccountError(
                data.createCustomerUberAccount.reason,
              ),
            )
          case CreateCustomerUberAccountErrorReason.AccountAlreadyExists:
            return Result.Err(
              new CreateCustomerUberAccountError.AccountAlreadyExistsCreateCustomerUberAccountError(
                data.createCustomerUberAccount.reason,
              ),
            )
          case CreateCustomerUberAccountErrorReason.Unspecified:
            return Result.Err(
              new CreateCustomerUberAccountError.UnexpectedCreateCustomerUberAccountError(
                data.createCustomerUberAccount.reason,
              ),
            )
        }
      }

      return Result.Ok({
        linkingUrl: data.createCustomerUberAccount.linkingUrl,
      })
    } catch (e) {
      return Result.Err(
        new CreateCustomerUberAccountError.UnexpectedCreateCustomerUberAccountError(e),
      )
    }
  }

  abortCreateCustomerUberAccount() {
    this.createCustomerUberAccountDataProvider.abortCreateCustomerUberAccount()
  }

  async getCustomerUberAccountStatus(): GetCustomerUberAccountStatusAdapterResult<UberAccountStatusValueObject> {
    try {
      const {
        data,
      } = await this.createCustomerUberAccountDataProvider.getCustomerUberAccountStatus()

      return Result.Ok({
        linkingUrl: data.customerUberAccountStatus.linkingUrl,
        status: mapToUberAccountStatusEnum(data.customerUberAccountStatus.status),
        isUserAccountSelfCreationEnabled:
          data.customerUberAccountStatus.userAccountSelfCreationEnabled,
      })
    } catch (e) {
      return Result.Err(
        new GetCustomerUberAccountStatusError.UnexpectedGetCustomerUberAccountStatusError(e),
      )
    }
  }

  abortGetCustomerUberAccountStatus() {
    this.createCustomerUberAccountDataProvider.abortGetCustomerUberAccountStatus()
  }
}
