import type { Maybe } from '@fiji/graphql/types'
import { CreditCardBrandType } from '@fiji/graphql/types'
import { VIRTUAL_PAY } from '@fiji/constants'
import { Icon as EttaIcon } from '@etta/ui/icon'
import { Icon } from './icon'

type Props = {
  cardVendor?: Maybe<CreditCardBrandType> | typeof VIRTUAL_PAY
}

export function IconByVendorType({ cardVendor }: Props) {
  switch (cardVendor) {
    case VIRTUAL_PAY:
      return <EttaIcon name="virtualCardPWA" />
    case CreditCardBrandType.Ax:
      return <Icon name="amex" />
    case CreditCardBrandType.Vi:
      return <Icon name="visa" />
    case CreditCardBrandType.Ca:
      return <Icon name="masterCard" />
    default:
      return <Icon name="generic" />
  }
}
