import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { PaymentSummaryDesktop } from './desktop'
import { PaymentSummaryMobile } from './mobile'

export const PaymentSummaryLayout = (props: LayoutProps) => {
  return (
    <MediaLayout
      mobileSlot={<PaymentSummaryMobile {...props} />}
      desktopSlot={<PaymentSummaryDesktop {...props} />}
    />
  )
}
