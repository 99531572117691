import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { Wrapper, ButtonWrapper } from '../element/element-styled'

const wrapperErrorBorder = css`
  border-radius: 8px;
  outline: 1px solid ${(p) => p.theme.colors.error};
`

export const SearchElementGroup = styled.div<{
  percentageWidth?: number
  gap?: number
  hasError: boolean
  isTwoInputs?: boolean
}>`
  display: flex;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 100%;
  ${({ percentageWidth }) =>
    percentageWidth &&
    css`
      min-width: ${percentageWidth}%;
      width: ${percentageWidth}%;
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
      background-color: ${({ theme }) => theme.colors.white};
    `}
  ${(p) => p.hasError && wrapperErrorBorder}
  ${({ isTwoInputs }) =>
    isTwoInputs &&
    css`
      & ${Wrapper}:first-of-type {
        width: 50%;
        ${ButtonWrapper} {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      & ${Wrapper}:last-of-type {
        width: 50%;
        ${ButtonWrapper} {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    `}
`

export const ErrorMessage = styled(Text).attrs({ variant: 'captionMedium' })`
  position: absolute;
  color: ${(p) => p.theme.colors.error};
  bottom: -20px;
`
