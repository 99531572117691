import styled, { css } from 'styled-components'

const BORDER_RADIUS = 10

export const Container = styled.div<{
  isTopRounded?: boolean
  isBottomRounded?: boolean
  fullHeight?: boolean
}>`
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '180px')};
  overflow: hidden;

  ${({ isTopRounded }) =>
    isTopRounded &&
    css`
      border-top-left-radius: ${BORDER_RADIUS}px;
      border-top-right-radius: ${BORDER_RADIUS}px;
    `};

  ${({ isBottomRounded }) =>
    isBottomRounded &&
    css`
      border-bottom-left-radius: ${BORDER_RADIUS}px;
      border-bottom-right-radius: ${BORDER_RADIUS}px;
    `};
`
