import styled from 'styled-components'

export const CardBlock = styled.div<{ isError: boolean; noCreditCard: boolean }>`
  text-align: left;
  padding: 2px 16px;
  width: 100%;
  margin-top: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isError, noCreditCard, theme }) => {
    if (isError || noCreditCard) {
      return theme.colors.error
    }

    return theme.colors.borderDark
  }};
  border-radius: 10px;
`
