import styled from 'styled-components'

export const Container = styled.div<{ withBorderBottom?: boolean }>`
  display: flex;
  align-items: self-start;
  padding: 24px 0;
  margin: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  overflow: hidden;
`
