import { BookTripAgainModalLayout } from './book-trip-again-modal-layout'
import type { Props } from './types'
import { useBookTripAgainModal } from './use-book-trip-again.modal'

export function BookTripAgainModal(props: Props) {
  const { regularUser, currentUserId } = useBookTripAgainModal({
    carRentalSegment: props.carRentalSegment,
    flightSegment: props.flightSegment,
    hotelSegment: props.hotelSegment,
  })

  const componentProps = {
    regularUser,
    currentUserId,
    ...props,
  }

  return <BookTripAgainModalLayout {...componentProps} />
}
