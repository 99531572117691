import type { ScreenType } from '@fiji/modes'
import type { PlainTime as Time } from '@fiji/types'
import { getFormattedTime } from '@fiji/utils/dates'
import { ControlsLayout } from './layout'
import type { CustomFocusedInput } from './types'
import { useDateTimeControlsLabels } from './use-date-time-controls-labels'

type ControlsProps = {
  isOpen: boolean
  focusedInput: CustomFocusedInput
  date?: Date | null
  time?: Time | null
  dateTitle?: string
  timeTitle?: string
  handleClickTabDate: () => void
  handleClickTabTime: () => void
  isNowSelected?: boolean
  forceScreenType?: null | ScreenType
}

function formatDate(date: Date) {
  return getFormattedTime(date, 'MMM dd')
}

function formatTime({ hours, minutes }: Time) {
  return getFormattedTime({ hours, minutes }, 'h:mm a')
}

export default function Controls({
  focusedInput,
  date,
  time,
  dateTitle,
  timeTitle,
  isOpen,
  handleClickTabDate,
  handleClickTabTime,
  isNowSelected,
  forceScreenType,
}: ControlsProps) {
  const {
    dateTitleValue,
    timeTitleValue,
    dateAriaLabel,
    timeAriaLabel,
  } = useDateTimeControlsLabels({
    date,
    time,
    timeTitle,
    dateTitle,
    dateFormatter: formatDate,
    timeFormatter: formatTime,
    isNowSelected,
  })

  return (
    <ControlsLayout
      forceScreenType={forceScreenType}
      isOpen={isOpen}
      focusedInput={focusedInput}
      date={date}
      time={time}
      dateAriaLabel={dateAriaLabel}
      dateTitle={dateTitleValue}
      timeTitle={timeTitleValue}
      timeAriaLabel={timeAriaLabel}
      handleClickTabDate={handleClickTabDate}
      handleClickTabTime={handleClickTabTime}
      isNowSelected={isNowSelected}
    />
  )
}
