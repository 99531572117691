import { Action, Inject } from '@etta/di'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import {
  isCarSegment,
  isFlightSegment,
  isHotelSegment,
} from '@etta/modules/review-trip/core/guards/identify-exact-segment'
import { CheckoutInfoAdapter } from '../../infra/checkout-info/checkout-info.adapter'
import type {
  AirMembershipValue,
  CarRentalMembershipValue,
  CheckoutInfoResult,
  HotelMembershipValue,
} from '../../core/value-objects/checkout-info.value-object'
import { CheckoutInfoStore } from './checkout-info.store'

@Action()
export class CheckoutInfoActions {
  constructor(
    @Inject() private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject() private readonly checkoutInfoAdapter: CheckoutInfoAdapter,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
  ) {}

  async loadCheckoutInfo(bookingId?: string) {
    if (this.checkoutInfoStore.isLoaded) {
      return
    }

    this.checkoutInfoStore.setIsLoading(true)
    this.checkoutInfoStore.setIsError(false)

    await this.handleLoad(
      (checkoutInfo: CheckoutInfoResult) => {
        this.handleSetCheckoutInfo(checkoutInfo)
        this.checkoutInfoStore.setIsLoaded(true)
      },
      () => {
        this.checkoutInfoStore.setIsError(true)
        this.checkoutInfoStore.setIsLoaded(false)
      },
      bookingId,
    )

    this.checkoutInfoStore.setIsLoading(false)
  }

  // fetch traveler data acts as reload checkout info, since it's called when fails to load checkout info
  async fetchTravelerDataFromCheckoutInfo(bookingId?: string) {
    this.checkoutInfoStore.dropCheckoutInfo()
    this.checkoutInfoStore.setIsTravelerDataLoading(true)
    await this.loadCheckoutInfo(bookingId)
    this.checkoutInfoStore.setIsTravelerDataLoading(false)
  }

  async fetchPaymentDataFromCheckoutInfo(bookingId?: string) {
    if (this.checkoutInfoStore.isError) {
      this.checkoutInfoStore.dropCheckoutInfo()
      await this.loadCheckoutInfo(bookingId)
      return
    }

    this.checkoutInfoStore.setIsBillingDataLoading(true)
    this.checkoutInfoStore.setIsBillingDataError(false)

    await this.handleLoad(
      (checkoutInfo: CheckoutInfoResult) => {
        this.checkoutInfoStore.setBillingData(checkoutInfo.billing)
      },
      () => {
        this.checkoutInfoStore.setIsBillingDataError(true)
      },
      bookingId,
    )

    this.checkoutInfoStore.setIsBillingDataLoading(false)
  }

  async fetchAdditionalDataFromCheckoutInfo(bookingId?: string) {
    if (this.checkoutInfoStore.isError) {
      this.checkoutInfoStore.dropCheckoutInfo()
      await this.loadCheckoutInfo(bookingId)
      return
    }

    this.checkoutInfoStore.setIsAdditionalDataLoading(true)
    this.checkoutInfoStore.setIsAdditionalDataError(false)

    await this.handleLoad(
      (checkoutInfo: CheckoutInfoResult) => {
        this.checkoutInfoStore.setAdditionalData(checkoutInfo.customFieldsConfiguration)
      },
      () => {
        this.checkoutInfoStore.setIsAdditionalDataError(true)
      },
      bookingId,
    )

    this.checkoutInfoStore.setIsAdditionalDataLoading(false)
  }

  dropCheckoutInfo() {
    this.checkoutInfoStore.dropCheckoutInfo()
  }

  private async handleLoad(
    onDone: (data: CheckoutInfoResult) => void,
    onError: () => void,
    bookingId?: string,
  ) {
    const result = await this.checkoutInfoAdapter.handleCheckoutInfo({
      itineraryId: this.reviewTripStore.tripId,
      bookingId,
    })

    result.match({
      Ok: (data: CheckoutInfoResult) => {
        const sanitizedCheckoutInfo = this.sanitizeResponse(data)
        onDone(sanitizedCheckoutInfo)
      },
      Err: onError,
    })
  }

  private handleSetCheckoutInfo(checkoutInfo: CheckoutInfoResult) {
    this.persistTravelerData(checkoutInfo)
    this.checkoutInfoStore.setTravelerData(checkoutInfo)
    this.checkoutInfoStore.setBillingData(checkoutInfo.billing)
    this.checkoutInfoStore.setAdditionalData(checkoutInfo.customFieldsConfiguration)
  }

  private persistTravelerData(checkoutInfo: CheckoutInfoResult) {
    if (this.checkoutInfoStore.primaryTraveler.isPersistEmpty()) {
      this.checkoutInfoStore.primaryTraveler.setTravelerValue(
        this.checkoutInfoStore.primaryTraveler.mapTravelerToPersist(
          checkoutInfo.primaryTraveler.data,
        ),
      )
    }

    if (this.checkoutInfoStore.meal.isPersistEmpty()) {
      checkoutInfo.mealRequest?.data &&
        this.checkoutInfoStore.meal.appendMealValue('air', checkoutInfo.mealRequest?.data)
    }

    if (this.checkoutInfoStore.memberships.isMembershipEmpty('air')) {
      this.setAirMembershipIfNeeded(checkoutInfo.memberships)
    }
    if (this.checkoutInfoStore.memberships.isMembershipEmpty('hotel')) {
      this.setHotelMembershipIfNeeded(checkoutInfo.memberships)
    }
    if (this.checkoutInfoStore.memberships.isMembershipEmpty('carRental')) {
      this.setCarRentalMembershipIfNeeded(checkoutInfo.memberships)
    }

    this.checkoutInfoStore.specialRequests.setMembershipIfEmpty(
      'air',
      checkoutInfo.specialRequests?.air?.data ?? null,
    )
    this.checkoutInfoStore.specialRequests.setMembershipIfEmpty(
      'hotel',
      checkoutInfo.specialRequests?.hotel?.data ?? null,
    )
    this.checkoutInfoStore.specialRequests.setMembershipIfEmpty(
      'carRental',
      checkoutInfo.specialRequests?.carRental?.data ?? null,
    )
  }

  private setAirMembershipIfNeeded(checkoutInfoMembership: CheckoutInfoResult['memberships']) {
    const flightSegments = this.reviewTripStore.segments.filter(isFlightSegment)
    const foundMemberships: AirMembershipValue[] = []
    if (flightSegments.length) {
      flightSegments.forEach((segment) => {
        segment.segments.forEach((flightSegment) => {
          const matchedMembership = checkoutInfoMembership?.air?.data.find(
            (membership) => membership.carrierCode === flightSegment.carrierCode,
          )
          if (
            matchedMembership &&
            !foundMemberships.some((membership) => membership.id === matchedMembership.id)
          ) {
            foundMemberships.push(matchedMembership)
          }
        })

        if (foundMemberships.length) {
          this.checkoutInfoStore.memberships.appendMembershipValue('air', foundMemberships)
        }
      })
    }
  }

  private setHotelMembershipIfNeeded(checkoutInfoMembership: CheckoutInfoResult['memberships']) {
    const hotelSegments = this.reviewTripStore.segments.filter(isHotelSegment)
    const foundMemberships: HotelMembershipValue[] = []
    if (hotelSegments.length) {
      hotelSegments.forEach((segment) => {
        const matchedMembership = checkoutInfoMembership?.hotel?.data.find((membership) =>
          membership.appliesToChain.some((code) => code === segment.vendorCode),
        )
        if (matchedMembership) {
          foundMemberships.push(matchedMembership)
        }
      })
    }
    if (foundMemberships.length) {
      this.checkoutInfoStore.memberships.appendMembershipValue('hotel', foundMemberships)
    }
  }

  private setCarRentalMembershipIfNeeded(
    checkoutInfoMembership: CheckoutInfoResult['memberships'],
  ) {
    const carRentalSegments = this.reviewTripStore.segments.filter(isCarSegment)
    if (carRentalSegments.length) {
      carRentalSegments.forEach((segment) => {
        const foundMemberships: CarRentalMembershipValue[] = []
        const matchedMembership = checkoutInfoMembership?.carRental?.data.find(
          (membership) => membership.vendorCode === segment.vendorDetails.code,
        )
        if (matchedMembership) {
          foundMemberships.push(matchedMembership)
        }

        if (foundMemberships.length) {
          this.checkoutInfoStore.memberships.appendMembershipValue('carRental', foundMemberships)
        }
      })
    }
  }

  private sanitizeResponse(checkoutInfo: CheckoutInfoResult): CheckoutInfoResult {
    try {
      const sanitizedString = JSON.stringify(checkoutInfo, (key, value) => {
        if (key === '__typename') {
          return undefined
        }
        return value
      })

      return JSON.parse(sanitizedString)
    } catch (e) {
      throw new Error('Error sanitizing checkout info response')
    }
  }
}
