import i18n from 'i18next'

type Args = {
  tripName?: string
  travelerFullName: string
  isGuest: boolean
  isPostBooking?: boolean
  isShowTravelerName?: boolean
  isTripOnHold?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function getTitle({
  tripName,
  travelerFullName,
  isGuest,
  isPostBooking,
  isShowTravelerName,
  isTripOnHold,
}: Args) {
  if (isTripOnHold) {
    return i18n.t(i18nBase + 'HoldTripTitle')
  }

  if (!(isGuest && travelerFullName) && !isShowTravelerName) {
    const defaultTitle = i18n.t(i18nBase + (isPostBooking ? 'Update' : 'Title'))
    return defaultTitle
  }

  return isPostBooking
    ? createUpdateTitle({ tripName, userName: travelerFullName })
    : createConfirmationTitle({ tripName, userName: travelerFullName })
}

function createConfirmationTitle({ tripName, userName }: { tripName?: string; userName: string }) {
  if (tripName) {
    return i18n.t(i18nBase + 'UserTitleWithTripName', {
      tripName,
      userName,
    })
  }

  return i18n.t(i18nBase + 'UserTitle', { userName })
}

function createUpdateTitle({ tripName, userName }: { tripName?: string; userName: string }) {
  if (tripName) {
    return i18n.t(i18nBase + 'UserUpdateTitleWithTripName', {
      tripName,
      userName,
    })
  }

  return i18n.t(i18nBase + 'UserUpdateTitle', { userName })
}
