import { useCarSearchQuery } from '@fiji/hooks/search-queries/use-car-search-query/use-car-search-query'
import { useStoragePlace } from '@fiji/hooks/use-storage-place'
import { useBackSearch } from '@fiji/hooks/search-queries/use-back-search'
import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface'

export function useCarRentalSearch() {
  const {
    carSearchFormActions,
    carSearchButtonActions,
    carSearchFormStore,
  } = useCarRentalSearchFormContext()
  const { queryParams } = useCarSearchQuery()

  const navigateToResults = carSearchButtonActions.handleSubmit

  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    shouldShowPreSearchModal,
    handleOpenPreSearchCustomFields,
  } = usePreSearch({ onSubmit: navigateToResults })

  const { places } = useStoragePlace('carRental')

  const {
    pickUpPlace,
    pickUpAddress,
    pickUpDate,
    pickUpTime,
    dropOffDate,
    dropOffPlace,
    dropOffAddress,
    dropOffTime,
  } = carSearchFormStore.carForm
  const { postBookingAction } = queryParams

  const {
    onDatesSelect,
    onDropOffPlaceChange,
    onDropOffTimeChange,
    onPickUpPlaceChange,
    onPickUpTimeChange,
    onSwitchLocations,
  } = carSearchFormActions

  function onSearchClick() {
    if (shouldShowPreSearchModal && carSearchButtonActions.isInputValid()) {
      handleOpenPreSearchCustomFields()
    } else {
      navigateToResults()
    }
  }

  const { onBack } = useBackSearch()

  return {
    queryParams,
    pickUpPlace,
    pickUpAddress,
    dropOffPlace,
    dropOffAddress,
    onPickUpPlaceChange,
    onDropOffPlaceChange,
    pickUpDate,
    dropOffDate,
    onDatesSelect,
    pickUpTime,
    dropOffTime,
    onDropOffTimeChange,
    onPickUpTimeChange,
    onSearchClick,
    onBack,
    placesHistory: places,
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    postBookingAction,
    onSwitchLocations,
  }
}
