import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { ErrorContainer } from '@etta/ui/error-container'
import { Swap } from '@etta/ui/swap'
import { ShareTravelerContact } from '@etta/screens/traveler-info/share-traveler-contact/share-traveler-contact'
import { ProfileBox } from '../../../profile-box'
import { PreferenceBox } from '../../../preference-box'
import { ContentSkeleton } from '../../../skeletons'
import { PassportBox } from '../../../passport-box'
import type { Props } from '../../types'
import {
  Content,
  PreferenceList,
  ShareTravelerContactBlock,
} from './traveler-info-content-desktop-styled'

export function TravelerInfoContentDesktop({
  isError,
  onReload,
  isLoading,
  profileErrorMessage,
  displayedPersonalInfo,
  primaryTraveler,
  primaryTravelerOptions,
  isProfileValid,
  isPassportRequired,
  profileModalOnOpen,
  passports,
  newPassportToggle,
  updateEditPassport,
  isPassportSelected,
  segmentsToRender,
  segmentsState,
  hotelPreferences,
  hotelPreferencesOnEdit,
  membershipState,
  availableToSelectHotel,
  carPreferences,
  carPreferencesOnEdit,
  availableToSelectCar,
  availableToSelectFlight,
  flightPreferences,
  flightPreferencesOnEdit,
  onPassportError,
  railPreferencesOnEdit,
  railPreferences,
  availableToSelectRail,
  showShareTravelerContact,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'TravelerInfo.'

  return (
    <Content>
      <Swap
        is={isError}
        isSlot={
          <Block marginVertical={61}>
            <ErrorContainer
              onReload={onReload}
              title={t(`${i18nBase}UnableToLoadInfo`)}
              align="central"
            />
          </Block>
        }>
        <Swap is={isLoading} isSlot={<ContentSkeleton />}>
          <ProfileBox
            errorMessage={profileErrorMessage}
            personalInfo={displayedPersonalInfo}
            primaryTraveler={primaryTraveler}
            travelerOptions={primaryTravelerOptions}
            isValid={isProfileValid}
            onLinkClick={profileModalOnOpen}
          />
          <PassportBox
            passports={passports}
            onAddNewPassport={newPassportToggle.handleOpen}
            onOpenExistingPassport={updateEditPassport}
            isPassportSelected={isPassportSelected}
            onError={onPassportError}
            isPassportRequired={isPassportRequired}
          />
          <PreferenceList>
            {segmentsToRender.isAir && (
              <PreferenceBox
                type="flight"
                segmentState={segmentsState.flightState}
                preferences={flightPreferences}
                onEdit={flightPreferencesOnEdit}
                availableToSelect={availableToSelectFlight}
              />
            )}
            {segmentsToRender.isHotel && (
              <PreferenceBox
                type="hotel"
                segmentState={segmentsState.hotelState}
                preferences={hotelPreferences}
                onEdit={hotelPreferencesOnEdit}
                disabledMemberships={membershipState.hotelMembershipsDisabled}
                availableToSelect={availableToSelectHotel}
              />
            )}
            {segmentsToRender.isCar && (
              <PreferenceBox
                type="car"
                segmentState={segmentsState.carRentalState}
                preferences={carPreferences}
                onEdit={carPreferencesOnEdit}
                availableToSelect={availableToSelectCar}
              />
            )}
            {segmentsToRender.isRail && (
              <PreferenceBox
                type="rail"
                segmentState={segmentsState.railState}
                preferences={railPreferences}
                onEdit={railPreferencesOnEdit}
                availableToSelect={availableToSelectRail}
              />
            )}
          </PreferenceList>
          {showShareTravelerContact && (
            <ShareTravelerContactBlock>
              <ShareTravelerContact />
            </ShareTravelerContactBlock>
          )}
        </Swap>
      </Swap>
    </Content>
  )
}
