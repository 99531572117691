import { useTranslation } from 'react-i18next'
import type { TOptions } from 'i18next'
import { useCallback } from 'react'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { UberInfoActionCard } from '@etta/components/uber-info-action-card/uber-info-action-card'
import { UberInfoHeader } from '@etta/components/uber-info-header/uber-info-header'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { BrandLogo } from '../brand-logo'
import { BrandTitle } from '../brand-title'
import {
  LogoIconContainer,
  ListItem,
  FeatureText,
  UberTripManagement,
  DashLine,
  IconWrapper,
  HeaderIconWrapper,
  ScreenContainer,
} from './uber-new-info-styled'
import { Background } from './background'

const manageList: { description: string; icon: IconNames }[] = [
  {
    description: 'UberManagement.Itinerary',
    icon: 'yogaPWA',
  },
  {
    description: 'UberManagement.SyncProfile',
    icon: 'syncPWA',
  },
  {
    description: 'UberManagement.Search',
    icon: 'clickPWA',
  },
  {
    description: 'UberManagement.Reserve',
    icon: 'carMarkerPWA',
  },
]

const howtoList: { header: string; description: string }[] = [
  {
    header: 'EnableProfile.ActivateAccount.Title',
    description: 'EnableProfile.ActivateAccount.Description',
  },
  {
    header: 'EnableProfile.BookRides.Title',
    description: 'EnableProfile.BookRides.Description',
  },
  {
    header: 'EnableProfile.Success.Title',
    description: 'EnableProfile.Success.Description',
  },
]

type Props = {
  isCloseModalOnlyOnExit?: boolean
  onClose?: () => void
}

export function UberNewInfo({ isCloseModalOnlyOnExit, onClose }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Mobility.UberNewInformationPage.'
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const { configurationStore } = useRideHailSearchContext()
  const brandLabel = brandConfigurationStore.isAppleTheme
    ? t(i18nBase + 'Brand.Kan')
    : t(i18nBase + 'Brand.Deem')
  const { createCustomerUberAccountActions } = useRideHailSearchContext()

  const handleClose = useCallback(() => {
    createCustomerUberAccountActions.abortCreateCustomerUberAccount()
    if (onClose) {
      onClose()
    }
  }, [createCustomerUberAccountActions, onClose])

  const reservationList: { description: string; icon: IconNames; options?: TOptions }[] = [
    {
      description: 'UberReservation.AdvancedBooking',
      icon: 'calendarPWA',
      options: { advancedBookingInDays: configurationStore.advanceBookingInDays },
    },
    {
      description: 'UberReservation.AirportPickUp',
      icon: 'sixtyMinutesPWA',
    },
    {
      description: 'UberReservation.UberRidesWaitUpTo15Mins',
      icon: 'timerNewPWA',
    },
    {
      description: 'UberReservation.Cancelation',
      icon: 'policyNewPWA',
    },
  ]

  return (
    <ScreenContainer backgroundColor="background">
      <Screen.Container ref={scrollContainerRef}>
        <Screen.Header>
          <Header
            animation="opacity"
            headerRef={headerRef}
            backgroundColor="white"
            leftSlot={
              <UberInfoHeader
                isCloseModalOnlyOnExit={isCloseModalOnlyOnExit}
                onClose={handleClose}
              />
            }
            withBorderBottom>
            <Header.Title
              title={
                <HeaderIconWrapper>
                  <BrandTitle />
                </HeaderIconWrapper>
              }
            />
          </Header>
          <div ref={anchorRef} />
        </Screen.Header>
        <LogoIconContainer>
          <BrandLogo />
        </LogoIconContainer>
        <Background />
        <UberTripManagement paddingVertical={22} paddingHorizontal={20}>
          <UberInfoActionCard />
          <Text variant="title2" color="secondary">
            {t(i18nBase + 'Title', { label: brandLabel })}
          </Text>
          <Block paddingTop={24}>
            <Text variant="headline" color="secondary">
              {t(i18nBase + 'UberManagement.Title')}
            </Text>
          </Block>
          <DashLine color="secondary" />
          {manageList.map((fl, index) => (
            <ListItem key={index}>
              <Icon name={fl.icon} color="mainText2" size={44} />
              <FeatureText variant="subHeadMedium" color="secondary">
                {t(i18nBase + fl.description)}
              </FeatureText>
            </ListItem>
          ))}
        </UberTripManagement>
        <Block paddingHorizontal={20} paddingTop={40}>
          <Text variant="title3" color="mainText1">
            {t(i18nBase + 'UberReservation.Title')}
          </Text>
          <DashLine color="bodyText1" />
          {reservationList.map((fl, index) => (
            <ListItem key={index}>
              <IconWrapper color="mainText2">
                <Icon name={fl.icon} color="thulianPink" size={26} />
              </IconWrapper>
              <FeatureText variant="subHeadMedium" color="mainText1">
                {t(i18nBase + fl.description, fl.options)}
              </FeatureText>
            </ListItem>
          ))}
        </Block>
        <Block paddingHorizontal={20} paddingVertical={20}>
          <Text variant="title3" color="mainText1">
            {t(i18nBase + 'EnableProfile.Title')}
          </Text>
          <DashLine color="bodyText1" />
          {howtoList.map((fl, index) => (
            <ListItem key={index}>
              <IconWrapper color="plum">
                <Text variant="title3" color="mainText2">
                  {index + 1}
                </Text>
              </IconWrapper>
              <Block>
                <FeatureText variant="headline" color="mainText1">
                  {t(i18nBase + fl.header, { label: brandLabel })}
                </FeatureText>
                <Block paddingTop={8}>
                  <FeatureText variant="bodyValue" color="bodyText">
                    {t(i18nBase + fl.description, { label: brandLabel })}
                  </FeatureText>
                </Block>
              </Block>
            </ListItem>
          ))}
        </Block>
      </Screen.Container>
    </ScreenContainer>
  )
}
