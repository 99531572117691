import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FareRulesMobile } from './mobile'
import { FareRulesDesktop } from './desktop'

export function FareRulesLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FareRulesMobile {...props} />}
      desktopSlot={<FareRulesDesktop {...props} />}
    />
  )
}
