import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { AutoCancellationMessage } from '@etta/components/auto-cancellation-message'
import { Text } from '@etta/ui/text'
import { Skeleton } from '@etta/ui/skeleton'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import type { LayoutProps } from '../../types'
import {
  HeaderTitle,
  Container,
  HeaderTripName,
  HeaderIconBox,
  HeldTripIcon,
} from './header-block-desktop-styled'

const i18nBase = 'PurchaseReservationSuccess.'

export function HeaderBlockDesktop({
  title,
  tripName,
  subtitleText,
  onShareTripsDetail,
  isTripLoading,
  isTripOnHold,
  autoCancellationTime,
  pnrNumber,
}: LayoutProps) {
  const { t } = useTranslation()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const recordLocator = brandConfigurationStore.isAppleTheme
    ? t(`${i18nBase}AppleTravelReference`)
    : t(`${i18nBase}RecordLocator`)

  const iconSlot = useMemo(() => {
    if (isTripOnHold) {
      return <HeldTripIcon name="heldTripPWA" color="white" size={64} />
    }
    return <Icon name="checkFilledSmoothPWA" color="success" size={64} />
  }, [isTripOnHold])

  const isAutoCancellationTimeShown = isTripOnHold && autoCancellationTime

  return (
    <Fragment>
      <Container aria-label={`${title} ${subtitleText}`}>
        <Block marginBottom={8}>{iconSlot}</Block>
        <HeaderTitle
          role="heading"
          aria-level={3}
          aria-label={title}
          data-tracking-id="purchase-reservation-success-title-text">
          {title}
        </HeaderTitle>
        <Block marginTop={8} marginBottom={isTripLoading || pnrNumber ? 8 : 19}>
          <Text
            variant="subHeadMedium"
            color="bodyText"
            data-tracking-id="purchase-reservation-success-sub-title-text">
            {subtitleText}
          </Text>
        </Block>
        {isTripLoading && (
          <Block marginBottom={19}>
            <Skeleton width="216px" height="20px">
              <rect width="216px" height="20px" />
            </Skeleton>
          </Block>
        )}
        {!isTripLoading && pnrNumber && (
          <Block marginBottom={19}>
            <Text variant="subHeadStrong">
              {recordLocator}: {pnrNumber}
            </Text>
          </Block>
        )}

        {isAutoCancellationTimeShown && (
          <AutoCancellationMessage isHolded time={autoCancellationTime} />
        )}
      </Container>
      <HeaderTripName withBorder={!isAutoCancellationTimeShown}>
        {tripName && (
          <Text variant="title3" color="mainText">
            {tripName}
          </Text>
        )}
        <HeaderIconBox onClick={onShareTripsDetail}>
          <Icon name="sharePWA" size="medium" />
        </HeaderIconBox>
      </HeaderTripName>
    </Fragment>
  )
}
