import { firebase } from '../firebase'
import { getFirebaseMessagingLogger } from './loggers'
import { VAPID_KEY } from './constants'

export const getFirebaseMessagingToken = async (
  serviceWorkerRegistration: ServiceWorkerRegistration,
) => {
  const firebaseMessagingLogger = getFirebaseMessagingLogger()

  if (!firebase.messaging.isSupported()) {
    const message = `Browser doesn't support messaging`
    firebaseMessagingLogger.error('Get token:', message)
    throw new Error(message)
  }

  try {
    firebaseMessagingLogger.log('Get token:', 'start request')

    const token = await firebase.messaging().getToken({
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration,
    })

    firebaseMessagingLogger.log('Get token:', 'received', token)

    return token
  } catch (e) {
    const error = new Error(e instanceof Error ? e.message : `Can't get token`)
    firebaseMessagingLogger.error('Get token:', error)

    throw error
  }
}
