import { Action, Inject } from '@etta/di'
import { ReviewTripRailService } from '../services/review-trip-rail.service'
import type { AddRailArgs, RemoveRailArgs } from '../types'
import { GetTripService } from '../services/get-trip.service'

@Action()
export class TripRailActions {
  constructor(
    @Inject()
    private readonly reviewTripRailService: ReviewTripRailService,

    @Inject()
    private readonly getTripService: GetTripService,
  ) {}

  handleUpdateTrip() {
    return this.getTripService.updateTrip()
  }

  handleAddRail(args: AddRailArgs) {
    return this.reviewTripRailService.addRail(args)
  }

  handleRemoveRail(args: RemoveRailArgs) {
    return this.reviewTripRailService.removeRail(args)
  }
}
