import { Inject, Service } from '@etta/di'
import i18n from '@fiji/i18n'
import { SettingsAdapter } from '@etta/modules/settings/infra/settings.adapter'
import { BaseSettingsService } from './base.service'

@Service()
export class SettingsService {
  constructor(
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
  ) {}

  get profile() {
    return this.baseSettingsService.getProfileFromUser()
  }

  get user() {
    return this.baseSettingsService.getUser()
  }

  getStructuredSettings() {
    return {
      dateFormat: this.formattedDateFormat || '-',
      hourlyTimeFormat: this.formattedTimeFormat || '-',
      timeZone: this.profile?.timeZone || '-',
      measurementUnit: this.formattedMeasurementUnit || '-',
      currency: this.user?.currency || '-',
      country: this.profile?.country || '-',
      locale: this.user?.locale || '-',
    }
  }

  getSettings() {
    return {
      dateFormat: this.profile?.dateFormat,
      hourlyTimeFormat: this.profile?.hourlyTimeFormat,
      timeZone: this.profile?.timeZone,
      measurementUnit: this.profile?.measurementUnit,
      currency: this.profile?.currency,
      country: this.profile?.country,
      locale: this.user?.locale,
    }
  }

  get formattedDateFormat() {
    if (!this.profile?.dateFormat) {
      return ''
    }

    return i18n.t('Settings.Settings.Modal.DateFormat.' + this.profile.dateFormat)
  }

  get formattedTimeFormat() {
    if (!this.profile?.hourlyTimeFormat) {
      return ''
    }

    return i18n.t('Settings.Settings.Modal.TimeFormat.' + this.profile.hourlyTimeFormat)
  }

  get formattedMeasurementUnit() {
    if (!this.profile?.measurementUnit) {
      return ''
    }

    return i18n.t('Settings.Settings.Modal.MeasurementFormat.' + this.profile.measurementUnit)
  }

  async fetchTimeZones() {
    const result = await this.settingsAdapter.getTimeZones()

    if (result.isErr()) {
      throw new Error(result.getError().message)
    }

    return result.getValue()
  }

  async fetchCurrencies() {
    const result = await this.settingsAdapter.getCurrencies()

    if (result.isErr()) {
      throw new Error(result.getError().message)
    }

    return result.getValue()
  }

  async fetchLanguages() {
    const result = await this.settingsAdapter.getLanguages()

    if (result.isErr()) {
      throw new Error(result.getError().message)
    }

    return result.getValue()
  }
}
