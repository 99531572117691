import styled from 'styled-components'
import { ellipsis } from 'polished'
import { footnoteMedium } from '@fiji/style'

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
  line-height: 1.2;
`

export const ListItemLabel = styled.span`
  ${ellipsis(191, 1)};
`
