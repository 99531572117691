import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ hasNewLayout: boolean }>`
  ${(props) =>
    props.hasNewLayout &&
    css`
      padding: 10px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
    `}
`
