import type { PersonalContactPhoneValueObject } from '@etta/modules/user'

export function phoneFormatter(phone: PersonalContactPhoneValueObject, withExt?: boolean) {
  return (
    '+' +
    [phone.countryCode, phone.number, withExt ? phone.officeExtension : '']
      .filter(Boolean)
      .join(' ')
  )
}
