import type { CreditCard, Maybe } from '@fiji/graphql/types'
import { formatCardNumber, getCardVendor } from '@fiji/utils/credit-card'

export function getCardLabelString({
  formattedCardNumber,
  vendor,
  label,
}: {
  formattedCardNumber: string
  vendor: string
  label?: Maybe<string>
}) {
  return [formattedCardNumber, vendor, label].filter(Boolean).join(' ')
}

export function getCreditCardLabel<TCreditCard extends Partial<CreditCard>>(
  creditCard: TCreditCard,
  getTemplate = getCardLabelString,
): string {
  const { label, brandType, cardNumber } = creditCard

  const vendor = getCardVendor(brandType).toUpperCase()

  return getTemplate({
    formattedCardNumber: formatCardNumber(cardNumber),
    vendor,
    label,
  })
}
