import styled from 'styled-components'

export const PastTripHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-left: 48px;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.duskBlue};
  overflow: hidden;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 0 28px;
`

export const Title = styled.div`
  padding-right: 10px;
  width: 100%;
  color: ${(props) => props.theme.colors.mainText2};
`

export const DataItem = styled.div`
  margin: 10px 15px 0 0;
  color: ${(props) => props.theme.colors.mainText2};

  &:last-child {
    margin-right: 0;
  }
`

export const RainbowSVGWrapper = styled.div`
  flex-shrink: 0;
`
