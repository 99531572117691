import { ScreenType, useScreenType } from '@fiji/modes'
import { Skeleton } from '@etta/ui/skeleton'
import { SkeletonContainer } from './covid-information-content-styled'

const defaultSkeleton = (
  <Skeleton width="100%" height="150">
    <rect width="100" height="16" y="2" />
    <rect width="50" height="12" y="39" />
    <rect width="30%" height="13" y="68" />
    <rect width="17%" height="13" x="83%" y="68" />
    <rect width="30%" height="13" y="102" />
    <rect width="17%" height="13" x="83%" y="102" />
    <rect width="30%" height="13" y="137" />
    <rect width="17%" height="13" x="83%" y="137" />
  </Skeleton>
)

export function CovidInformationContentSkeleton() {
  const screenType = useScreenType()
  const isMobileSkeleton = screenType === ScreenType.Mobile
  if (isMobileSkeleton) {
    return <SkeletonContainer>{defaultSkeleton}</SkeletonContainer>
  }
  return (
    <SkeletonContainer>
      {defaultSkeleton}
      {defaultSkeleton}
    </SkeletonContainer>
  )
}
