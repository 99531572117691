import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { Container, EmissionData, LeafIcon, EmissionDescription } from './emission-deviation-styled'

type EmissionDeviationProps = {
  tonnesOfEmissions: number
  averageEmissionsTonnes: number
}

export function EmissionDeviationComponent({
  tonnesOfEmissions,
  averageEmissionsTonnes,
}: EmissionDeviationProps) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.'

  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  const emissionsDescription = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + 'AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + 'LessEmissions', {
          percentDeviation,
        })
      case EmissionsDeviationLevel.More:
        return t(i18nBase + 'MoreEmissions', {
          percentDeviation,
        })
    }
  }, [deviationLevel, percentDeviation, t])

  return (
    <Container aria-label={emissionsDescription}>
      <EmissionData>
        <LeafIcon name="leafPWA" size={'small'} deviationLevel={deviationLevel} />
        <EmissionDescription
          deviationLevel={deviationLevel}
          dangerouslySetInnerHTML={{ __html: emissionsDescription }}
        />
      </EmissionData>
    </Container>
  )
}
