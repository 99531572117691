import { useCallback, useEffect, useMemo, useState } from 'react'
import type { FareSegmentSeatRows } from '@fiji/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { SeatFeatures } from '@etta/modules/seat-map/core/enums'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import type { SeatSelectedArgs } from '@etta/modules/seat-map/core/types'
import type { SegmentSeatNumber } from '../../air-seat-map.types'
import { addSeat } from './add-seat'
import { removeSeat } from './remove-seat'
import { getNextSegmentId } from './get-next-segment-id'

type Props = {
  initialSelectedSegmentSeats: SegmentSeatNumber[]
  segmentsSeatRows: FareSegmentSeatRows[]
  onApprove: (approvedSegmentsSeatNumbers: SegmentSeatNumber[]) => void
  selectedSegmentId: string
  onSelectedSegmentIdChange?: (selectedSegmentId: string) => void
}

const unavailableForSelectionFeatures = [SeatFeatures.Paid, SeatFeatures.Premium]

const canSeatBeApproved = (seat: SegmentSeatNumber) => {
  if (!seat.seatFeature) {
    return true
  }
  return !unavailableForSelectionFeatures.includes(seat.seatFeature)
}

export function useSegmentSeats({
  initialSelectedSegmentSeats,
  segmentsSeatRows,
  onApprove,
  selectedSegmentId,
  onSelectedSegmentIdChange,
}: Props) {
  const [selectedSegmentsSeatNumbers, setSelectedSegmentsSeatNumbers] = useState<
    SegmentSeatNumber[]
  >(initialSelectedSegmentSeats)
  const [approvedSegmentsSeatNumbers, setApprovedSegmentsSeatNumbers] = useState<
    SegmentSeatNumber[]
  >([])

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const isSeatSelectionAvailable = useMemo(() => {
    const { readOnly } =
      segmentsSeatRows.find(({ segmentId }) => segmentId === selectedSegmentId) || {}

    return !readOnly
  }, [segmentsSeatRows, selectedSegmentId])

  const hasNextSegment = useMemo(() => !!getNextSegmentId(segmentsSeatRows, selectedSegmentId), [
    segmentsSeatRows,
    selectedSegmentId,
  ])

  const handleNextSegment = useCallback(
    (seatNumbers: SegmentSeatNumber[]) => {
      const nextSegmentId = getNextSegmentId(segmentsSeatRows, selectedSegmentId)

      if (nextSegmentId) {
        onSelectedSegmentIdChange?.(nextSegmentId)
      } else {
        onApprove(seatNumbers)
      }
    },
    [segmentsSeatRows, selectedSegmentId, onApprove, onSelectedSegmentIdChange],
  )

  const skipSeatSelection = () => {
    handleNextSegment(selectedSegmentsSeatNumbers)
  }

  const moveToNextSeatSelection = () => {
    const seat = selectedSegmentsSeatNumbers.find((n) => n.segmentId === selectedSegmentId)

    if (seat && !canSeatBeApproved(seat)) {
      setSelectedSegmentsSeatNumbers((seats) => {
        return seats.filter((n) => n.segmentId !== seat.segmentId)
      })
    }

    handleNextSegment(selectedSegmentsSeatNumbers)
  }

  const finishAllSeatsSelection = () => {
    onApprove(approvedSegmentsSeatNumbers)
  }

  const getNextCheckedSeats = (
    isChecked: boolean,
    seat: SeatMapRowSeatValueObject,
    segmentId: string,
    segmentsSeatNumbers: SegmentSeatNumber[],
  ) => {
    return isChecked
      ? addSeat({ seat, selectedSegmentId: segmentId, segmentsSeatNumbers })
      : removeSeat({ seat, selectedSegmentId: segmentId, segmentsSeatNumbers })
  }

  const onSeatChecked = useCallback(
    ({ seat, isSelected, shouldGoNextSegment }: SeatSelectedArgs) => {
      const nextCheckedSeats = getNextCheckedSeats(
        isSelected,
        seat,
        selectedSegmentId,
        selectedSegmentsSeatNumbers,
      )

      setSelectedSegmentsSeatNumbers(nextCheckedSeats)

      if (shouldGoNextSegment) {
        handleNextSegment(nextCheckedSeats)
      }
    },
    [selectedSegmentId, selectedSegmentsSeatNumbers, handleNextSegment],
  )

  const onSeatApprove = useCallback(
    (seat: SeatMapRowSeatValueObject) => {
      const canBeApproved = isSeatSelectionAvailable && !seat.isPaid

      if (!canBeApproved) {
        onSeatChecked({ seat, isSelected: false, shouldGoNextSegment: !isMobile })
        return
      }

      setApprovedSegmentsSeatNumbers(selectedSegmentsSeatNumbers)
      handleNextSegment(selectedSegmentsSeatNumbers)
    },
    [
      isSeatSelectionAvailable,
      selectedSegmentsSeatNumbers,
      handleNextSegment,
      onSeatChecked,
      isMobile,
    ],
  )

  const selectAndApproveSeat = ({ seat, isSelected }: SeatSelectedArgs) => {
    const newSeats = getNextCheckedSeats(
      isSelected,
      seat,
      selectedSegmentId,
      selectedSegmentsSeatNumbers,
    )

    setSelectedSegmentsSeatNumbers(newSeats)

    const canBeApproved = isSeatSelectionAvailable && !seat.isPremium && !seat.isPaid

    if (canBeApproved) {
      setApprovedSegmentsSeatNumbers(newSeats)
    }
  }

  const onSeatAssignFailedContinue = useCallback(
    (seat: SeatMapRowSeatValueObject) => {
      onSeatChecked({ seat, isSelected: false, shouldGoNextSegment: !isMobile })
    },
    [isMobile, onSeatChecked],
  )

  const onNextSegment = useCallback(() => {
    handleNextSegment(approvedSegmentsSeatNumbers)
  }, [handleNextSegment, approvedSegmentsSeatNumbers])

  useEffect(() => {
    if (initialSelectedSegmentSeats.length) {
      setSelectedSegmentsSeatNumbers(initialSelectedSegmentSeats)
    }
  }, [initialSelectedSegmentSeats])

  return {
    selectedSegmentsSeatNumbers,
    approvedSegmentsSeatNumbers,
    onSeatChecked,
    onSeatApprove,
    onNextSegment,
    isSeatSelectionAvailable,
    isSeatAssignLoading: false,
    isSeatAssignFailed: false,
    onSeatAssignFailedContinue,
    skipSeatSelection,
    finishAllSeatsSelection,
    hasNextSegment,
    selectAndApproveSeat,
    moveToNextSeatSelection,
  }
}
