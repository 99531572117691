import { useEffect, useRef } from 'react'

export function useClickOutside(onClick: () => void) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = (event: Event) => {
      if (!ref?.current?.contains(event.target as Node)) {
        onClick()
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [onClick])

  return { ref }
}
