import { hookContextFactory } from '@etta/interface/services/hook-context-factory'

import { AlertStore } from './stores/alert.store'

import { AlertActions } from './actions/alert.actions'

export const { useModuleContext: useAlertContext } = hookContextFactory({
  alertStore: AlertStore,
  alertActions: AlertActions,
})
