import { Inject, Service } from '@etta/di'
import { SearchRideHailStore } from '@etta/modules/ride-hail/interface/store/search-ride-hail.store'
import { ExpenseCodePage, RenderState } from '@etta/modules/ride-hail/interface/types'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import { AddExpenseCodeMemoStore } from '@etta/modules/ride-hail/interface/store/add-expense-code-memo.store'
import { EnterCustomExpenseCodeStore } from '@etta/modules/ride-hail/interface/store/enter-custom-expense-code.store'

@Service()
export class AddExpenseCodeMemoService {
  constructor(
    @Inject()
    private rideHailSearchStore: SearchRideHailStore,
    @Inject()
    private expenseFlowStore: ExpenseFlowStore,
    @Inject()
    private addExpenseCodeMemoStore: AddExpenseCodeMemoStore,
    @Inject()
    private enterCustomExpenseCodeStore: EnterCustomExpenseCodeStore,
  ) {}

  goBack() {
    this.addExpenseCodeMemoStore.dropStore()
    if (this.enterCustomExpenseCodeStore.customExpenseCodeValue) {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.EnterCustomExpenseCode)
    } else {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.SelectExpenseCode)
    }
  }

  navigateToReview() {
    this.rideHailSearchStore.setRenderState(RenderState.review)
    this.expenseFlowStore.setShouldCloseExpenseCodeModal(true)
  }

  handleMemoValueChanged(value: string | null) {
    const unwrappedValue = value ?? ''

    if (unwrappedValue.length > this.addExpenseCodeMemoStore.maxMemoLength) {
      return
    }

    this.addExpenseCodeMemoStore.setMemoValue(unwrappedValue)
    this.addExpenseCodeMemoStore.setIsContinueButtonDisabled(
      this.expenseFlowStore.expenseConfig.memoRequired ? unwrappedValue.length === 0 : false,
    )
  }
}
