import { Skeleton } from '@etta/ui/skeleton'

export function CarRentalSafetyCheckSkeleton() {
  return (
    <Skeleton width="100%" height="104">
      <rect x="0" y="0" width="96" height="16" />
      <rect x="0" y="24" width="220" height="14" />
      <rect x="0" y="46" width="180" height="12" />
      <rect x="0" y="64" width="220" height="12" />
      <rect x="0" y="86" width="75" height="16" />
    </Skeleton>
  )
}
