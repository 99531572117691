import { TripCard } from '@etta/components/trip-card/trip-card'
import { InfiniteScroll } from '@etta/ui/infinite-scroll'
import type { LayoutProps } from '../../types'
import { getMonthTitle } from './get-month-title'
import {
  Container,
  MonthTitle,
  CardsList,
  ContentContainer,
} from './trip-list-content-desktop-styled'

export function TripListContentDesktop({
  withAnimation,
  tripType,
  groups,
  view,
  handleFetchMore,
  hasNextPage,
  isFetchMoreLoading,
  bannerSlot,
}: LayoutProps) {
  return (
    <>
      {bannerSlot}
      <Container role="list">
        <InfiniteScroll
          isLoading={isFetchMoreLoading}
          hasNextPage={hasNextPage}
          onFetchMore={handleFetchMore}>
          {groups?.map(({ month, trips, year }) => {
            if (!trips.length) {
              return null
            }

            const title = getMonthTitle({ month, trips, year })

            return (
              <ContentContainer key={title} data-tracking-id={`trip-month_${month}`} view={view}>
                <MonthTitle as={'h3'} variant="headline" color="bodyText">
                  {title}
                </MonthTitle>
                <CardsList view={view}>
                  <InfiniteScroll.List withAnimation={withAnimation}>
                    {trips.map(
                      (
                        {
                          name,
                          rollUpStartTime,
                          rollUpEndTime,
                          serviceCategoryList,
                          transactionId,
                          transactionGroupId,
                          cityImageUrl,
                          travelerName,
                          meetingInfo,
                        },
                        index,
                      ) => (
                        <TripCard
                          key={transactionId}
                          tripType={tripType}
                          transactionId={transactionId}
                          transactionGroupId={transactionGroupId}
                          name={name}
                          startDate={rollUpStartTime}
                          endDate={rollUpEndTime}
                          services={serviceCategoryList}
                          cityImageUrl={cityImageUrl}
                          isFirst={!index}
                          view={view === 'grid' ? 'slim' : undefined}
                          travelerName={travelerName}
                          meetingInfo={meetingInfo}
                        />
                      ),
                    )}
                  </InfiniteScroll.List>
                </CardsList>
              </ContentContainer>
            )
          })}
        </InfiniteScroll>
      </Container>
    </>
  )
}
