import { useEffect } from 'react'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useUserContext } from '@etta/modules/user'
import { useAlertContext } from '@etta/modules/alert/interface'

export function useAlerts() {
  const { userStore } = useUserContext()
  const { alertActions } = useAlertContext()
  const { profile } = userStore
  const {
    featureFlags: { isAlertsEnabled },
  } = useFeatureFlags()

  useEffect(() => {
    if (isAlertsEnabled) {
      void alertActions.handleLoadAlert()
    }
  }, [alertActions, profile, isAlertsEnabled])
}
