import type { Theme as ThemeInterface } from '@fiji/types'
import * as themes from '@fiji/themes'

type ThemeList = keyof typeof themes

const themeMap: Record<ThemeList, ThemeInterface> = {
  deemcorp: themes.deemcorp,
  ehismb: themes.ehismb,
  kan: themes.kan,
  amex: themes.amex,
  cwt2: themes.cwt2,
  hagroupbusiness: themes.hagroupbusiness,
  etta: themes.etta,
  ettaDark: themes.ettaDark,
  mobility: themes.mobility,
  deemcorp2: themes.deemcorp2,
}

export function getUsedTheme(brandTheme?: string): ThemeInterface {
  const theme = brandTheme ? themeMap[brandTheme as ThemeList] : themeMap.etta
  return theme || themeMap.etta
}
