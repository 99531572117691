import { Fragment, useMemo } from 'react'
import { AddButtonItem } from '../add-button-item'
import { AddButtonWrapper } from '../../../details-skeleton-styled'

type Props = {
  buttonCount: number
}

export const AddButtonBlockSkeleton = ({ buttonCount }: Props) => {
  const addButtonBlockSkeleton = useMemo(() => {
    return new Array(buttonCount)
      .fill(<AddButtonItem />)
      .map((item, index) => <Fragment key={index}>{item}</Fragment>)
  }, [buttonCount])

  return <AddButtonWrapper>{addButtonBlockSkeleton}</AddButtonWrapper>
}
