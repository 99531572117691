export enum UserTitle {
  None = 'NONE',
  Dr = 'DR',
  Master = 'MASTER',
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Mx = 'MX',
  Prof = 'PROF',
  Sir = 'SIR',
}
