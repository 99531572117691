import { useTranslation } from 'react-i18next'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { useScreenType, ScreenType } from '@fiji/modes'
import type { ConfirmationNumberData } from '@etta/screens/payment-summary/types'
import { Text } from '@etta/ui/text'
import { Skeleton } from '@etta/ui/skeleton'
import { SubTitle, VendorTitle } from '../../titles'
import { ReservationNumber } from '../../reservation-number'
import { HotelRowContainer } from './hotel-row-styled'

type Props = {
  checkInDate: string
  checkOutDate: string
  selectedRoom: string
  hotelName: string
  reservationNumber?: string
  roomCount?: number | null
  confirmationData?: ConfirmationNumberData
  isTripLoading?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function HotelRow({
  checkInDate,
  checkOutDate,
  selectedRoom,
  hotelName,
  reservationNumber,
  roomCount,
  confirmationData,
  isTripLoading,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const checkInLabel = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkInDate)
  const checkOutLabel = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkOutDate)

  const nightsInfo = t(i18nBase + 'Night', {
    count: getDifferenceInFullDaysBetweenStartAndEndIsoStrings(checkOutDate, checkInDate),
  })
  const roomInfo = t(i18nBase + 'RoomInfo', {
    roomCount: roomCount || '',
    roomType: selectedRoom,
  })

  const hotelRowLabel = t(i18nBase + 'HotelRowLabel', {
    title: hotelName,
    checkIn: checkInLabel,
    checkOut: checkOutLabel,
    room: selectedRoom,
    number: reservationNumber,
  })

  const showConfirmationData =
    !isTripLoading && confirmationData?.confirmationNumber && confirmationData?.companyName

  return (
    <HotelRowContainer aria-label={hotelRowLabel}>
      <VendorTitle>{hotelName}</VendorTitle>
      <ReservationNumber number={reservationNumber} />
      <SubTitle marginBottom={isMobile ? 6 : 4}>
        <Text variant="footnoteMedium" color="bodyText">
          {checkInLabel} - {checkOutLabel}, {roomInfo}, {nightsInfo}
        </Text>
      </SubTitle>
      {isTripLoading && (
        <Skeleton width="188px" height="19px">
          <rect width="188px" height="19px" />
        </Skeleton>
      )}
      {showConfirmationData && (
        <SubTitle>
          {confirmationData.companyName} {t(i18nBase + 'Confirmation')}{' '}
          {confirmationData.confirmationNumber}
        </SubTitle>
      )}
    </HotelRowContainer>
  )
}
