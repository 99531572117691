import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Section, SectionTitle } from '../rail-filters-styled'
import { useRailStationsFilter } from './use-rail-stations-filter'
import { RailStationsList } from './rail-stations-list'
import { SubTitle } from './rail-stations-filter-styled'

const i18Base = 'RailFilters.Rail'

export const RailStationsFilter = observer(function RailStationsFilter() {
  const { t } = useTranslation()
  const {
    origin,
    destination,
    originStations,
    destinationStations,
    onIsDestinationStationSelected,
    onIsOriginStationSelected,
    onSelectDestination,
    onSelectOrigin,
  } = useRailStationsFilter()
  return (
    <Section withBorder>
      <SectionTitle>{t(i18Base + '.TrainStations')}</SectionTitle>
      <SubTitle>{origin}</SubTitle>
      <RailStationsList
        stations={originStations}
        onIsChecked={onIsOriginStationSelected}
        onSelect={onSelectOrigin}
      />
      <SubTitle>{destination}</SubTitle>
      <RailStationsList
        stations={destinationStations}
        onIsChecked={onIsDestinationStationSelected}
        onSelect={onSelectDestination}
      />
    </Section>
  )
})
