import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace UnusedTicketOptErrors {
  export const ApplyUnusedTicketErrorResponse = createErrorClass(
    'Apply Unused Ticket Response error',
  )
  export const ApplyUnusedTicketUnexpectedError = createErrorClass(
    'Apply Unused Ticket Unexpected error',
  )

  export const RemoveUnusedTicketErrorResponse = createErrorClass(
    'Remove Unused Ticket Response error',
  )
  export const RemoveUnusedTicketUnexpectedError = createErrorClass(
    'Remove Unused Ticket Unexpected error',
  )
}

export type UnusedTicketOptErrorsInstances = InstanceType<
  typeof UnusedTicketOptErrors[keyof typeof UnusedTicketOptErrors]
>
