import { MediaLayout } from '@etta/ui/media-layout'
import { CarRentalCardSkeletonDesktop, CarRentalCardSkeletonMobile } from './layout'
import type { Props } from './types'

export function CarRentalCardSkeleton(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalCardSkeletonMobile {...props} />}
      desktopSlot={<CarRentalCardSkeletonDesktop {...props} />}
    />
  )
}
