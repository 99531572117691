import type { PlainTime } from '@fiji/types'

const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = 3600

export function isBeforeTime(time: PlainTime, timeToCompare: PlainTime) {
  return getTimeSeconds(time) < getTimeSeconds(timeToCompare)
}

function getTimeSeconds(time: PlainTime) {
  const { hours, minutes, seconds } = time
  let resultSeconds = hours * SECONDS_PER_HOUR + minutes * SECONDS_PER_MINUTE
  if (seconds) {
    resultSeconds += seconds
  }

  return resultSeconds
}
