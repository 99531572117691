import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { FlightDetailsEcoCheckModal } from '@etta/components/flight-details-eco-check-modal'
import { KgOfCo2 } from '@etta/components/kg-of-co2'
import { tonnesToKg } from '@etta/components/rtp-eco-check-summary/use-rtp-eco-check-summary/utils'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { estimatedEmissionsLinks } from '../../constants'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  LearnMore,
  MainContainer,
  BottomLine,
  EmissionsDescription,
  EmissionsLinks,
  // ViewSource,
  EmissionInformationValue,
  EstimatedEmissions,
  EmissionInformationContainer,
  EstimatedEmissionsContainer,
} from './flight-details-eco-check-desktop-new-styled'
import EcoCheckAverage from './assets/eco-check-average.svg?url'
import EcoCheckPoluted from './assets/eco-check-poluted.svg?url'
import EcoCheckGreen from './assets/eco-check-green.svg?url'

export type Props = {
  tonnesOfEmissions: number
  averageRouteEmissionTonnes: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function FlightDetailsEcoCheckDesktopComponentNew({
  equivalences,
  tonnesOfEmissions,
  averageRouteEmissionTonnes,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.FlightDetailsNew'
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions: tonnesOfEmissions,
    averageEmissionsTonnes: averageRouteEmissionTonnes,
  })
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const emissionsDescriptionContain = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.LowerEmissions', {
          percentDeviation,
        })
      case EmissionsDeviationLevel.More:
        return t(i18nBase + '.HigherEmissions', {
          percentDeviation,
        })
    }
  }, [deviationLevel, percentDeviation, t])

  const ecoCheckBackground = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return EcoCheckAverage
      case EmissionsDeviationLevel.Less:
        return EcoCheckGreen
      case EmissionsDeviationLevel.More:
        return EcoCheckPoluted
    }
  }, [deviationLevel])

  const emissionsCloudIcon = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
      case EmissionsDeviationLevel.More:
        return 'AverageEmissionsCloudsPWA'
      case EmissionsDeviationLevel.Less:
        return 'lowerEmissionsCloudsPWA'
    }
  }, [deviationLevel])

  const { handleKeyDown } = useA11yKeydown(handleOpen)

  return (
    <>
      <Container data-tracking-id="flight-details-eco-check-action">
        <MainContainer>
          <EstimatedEmissionsContainer>
            <EstimatedEmissions>
              <Title aria-label={t('Accessibility.EcoCheck.EstimatedEmissions')}>
                {t('Emissions.DetailsModal.EstimatedEmissions')}
              </Title>
            </EstimatedEmissions>
            <Body aria-label={emissionsDescriptionContain}>
              <EmissionInformationContainer>
                <EmissionInformationValue>
                  <KgOfCo2 kgOfEmissions={tonnesToKg(tonnesOfEmissions)} />
                </EmissionInformationValue>
                <Icon name={emissionsCloudIcon} />
              </EmissionInformationContainer>
              <EmissionsDescription
                dangerouslySetInnerHTML={{ __html: emissionsDescriptionContain }}
              />

              <EmissionsLinks>
                <LearnMore
                  tabIndex={0}
                  onClick={handleOpen}
                  onKeyDown={handleKeyDown}
                  aria-label={t('Accessibility.ButtonTapToLearnMore', {
                    label: t('Emissions.DetailsModal.LearnMore'),
                  })}>
                  {t('Emissions.DetailsModal.LearnMore')}
                </LearnMore>
                <Link
                  tabIndex={0}
                  aria-label={`${t('Emissions.DetailsModal.ViewSource')} - ${t(
                    'Accessibility.OpenLink',
                  )}`}
                  target="_blank"
                  size="small"
                  href={estimatedEmissionsLinks.viewSourceLink}>
                  {t('Emissions.DetailsModal.ViewSource')}
                  <Icon name={'openWindowPWA'} size={16} color="primary" />
                </Link>
              </EmissionsLinks>
            </Body>
          </EstimatedEmissionsContainer>
          <EcoCheckBackground src={ecoCheckBackground} />
        </MainContainer>
        <BottomLine deviationLevel={deviationLevel} />
      </Container>
      <FlightDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageRouteEmissionTonnes}
      />
    </>
  )
}
