import { useCallback, useMemo, useState, useEffect } from 'react'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { getDifferenceInDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-days-between-start-and-end-Iso-strings'
import { getCarRentalDetailsPrice } from '@fiji/utils/get-car-rental-details-price'
import { getCarRentalLocations } from '@fiji/utils/get-car-rental-locations'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import type { CarRentalClassType } from '@fiji/graphql/types'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import { GOOGLE_MAPS_URL } from '@fiji/constants'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { getAmenities } from './get-amenities'
import { getVendorInformation } from './get-vendor-information'

type Args = {
  carId: string
  onAddCar: (carRentalKey: string) => void
}

export function useCarRentalDetailsModal({ carId, onAddCar }: Args) {
  const {
    carRentalCarDetailsActions,
    carRentalCarDetailsStore,
    carRentalSearchStore,
  } = useCarRentalSearchContext()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const [viewState, setViewState] = useState<TripStateStatus>(null)
  const { handleLoadCarDetails, handleRefetchCarDetails } = carRentalCarDetailsActions
  const { isServiceFeeEnabled } = featureFlagsStore.flags

  const {
    isLoadingCarDetails,
    isErrorCarDetails,
    carDetails,
    isInPolicy,
    dropOff,
    pickUp,
    carType,
    carClass,
    carImage,
    vendorCode,
    vendorName,
    vendorImage,
    isPreferred,
    safetyCheckMessageCode,
    safetyCheckLink,
    carCostRates,
    totalCostRate,
    feesAndTaxesRate,
  } = carRentalCarDetailsStore
  const { carPreferenceAttribute } = carRentalSearchStore

  useEffect(() => {
    if (!carId) {
      return
    }

    handleLoadCarDetails(carId)
  }, [handleLoadCarDetails, carId])

  const { handleRefetchRequest } = useRefetchRequest({
    loading: isLoadingCarDetails,
    refetch: () => handleRefetchCarDetails(carId),
  })

  const handleCloseTripStatus = () => setViewState(null)
  const handleAddCarRental = useCallback(() => {
    onAddCar(carId)
  }, [onAddCar, carId])

  const { carRentalTitle } = translateCarNameDetails({
    carClass: carClass as CarRentalClassType,
    carType,
  })
  const totalDays = getDifferenceInDaysBetweenStartAndEndIsoStrings(pickUp?.time, dropOff?.time)
  const isOutOfPolicy = isInPolicy === false
  const amenities = getAmenities({ carDetails })
  const locations = getCarRentalLocations({ carDetails })
  const vendorInformation = getVendorInformation({ carDetails })
  const baseCost = isServiceFeeEnabled ? carDetails?.netRate : carDetails?.baseRate
  const serviceFeeCost = isServiceFeeEnabled ? carDetails?.serviceFeeRate : undefined
  const { priceDetailsList, estTotalCost } = getCarRentalDetailsPrice({
    carCostRates,
    totalCostRate,
    baseRate: baseCost,
    feesAndTaxesRate,
    serviceFeeRate: serviceFeeCost,
  })
  const handleAddressClick = useCallback(() => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${locations?.geocode?.lat},${locations?.geocode?.lng}`)
  }, [locations])
  const customBadge = useMemo(() => {
    return carPreferenceAttribute?.find((attr) => attr.vendor.code === vendorCode)?.preference
      ?.customBadge?.name
  }, [carPreferenceAttribute, vendorCode])

  return {
    carRentalTitle,
    totalDays,
    isOutOfPolicy,
    isLoading: isLoadingCarDetails,
    isError: isErrorCarDetails,
    carDetails,
    carImage,
    vendorCode,
    vendorName,
    vendorImage,
    isPreferred,
    safetyCheckMessageCode,
    safetyCheckLink,
    onReload: handleRefetchRequest,
    viewState,
    handleCloseTripStatus,
    handleAddCarRental,
    amenities,
    locations,
    vendorInformation,
    priceDetailsList,
    totalCostRate,
    estTotalCost,
    handleAddressClick,
    customBadge,
  }
}
