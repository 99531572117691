import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  margin: 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;

  &:not(:first-child) {
    margin-top: 0;
  }
`

export const Header = styled.div`
  padding: 20px 0 10px;
  margin: 0 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.background1};
  display: flex;
  flex-direction: column;
`

export const TopHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 10px;
`

export const Footer = styled.div<{ isSelectable: boolean }>`
  padding: ${(props) => (props.isSelectable ? '8px 20px' : '15px 20px')};
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  > div:first-child {
    flex-grow: 2;
  }

  > button {
    flex-grow: 1;
  }
`

export const RoomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Note = styled(Text)`
  margin-top: 8px;
`
