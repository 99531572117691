import { FareCardAttributesLayout } from './fare-card-attributes-layout'
import type { Props } from './types'

export function FareCardAttributes({ ticketAttributes, isFullView, fareInfoSlot }: Props) {
  return (
    <FareCardAttributesLayout
      ticketAttributes={ticketAttributes}
      isFullView={isFullView}
      fareInfoSlot={fareInfoSlot}
    />
  )
}
