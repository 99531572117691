import { observer } from 'mobx-react-lite'
import type { DynamicSiteMessage } from '@fiji/graphql/hooks'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import type { ContinuityMessageSegmentKeys } from '@fiji/types'
import type { ComplianceMessage, ContinuityMessage, OverlappingTrip } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { useTripReviewDynamicSiteMessages } from './use-trip-review-dynamic-site-messages'

type Props = {
  dynamicSiteMessage?: DynamicSiteMessage[] | null
  complianceMessages?: ComplianceMessage | null
  continuityMessages?: ContinuityMessage[] | null
  overlappingTrips?: OverlappingTrip[] | null
  itineraryId: string
  firstHotelId?: string
  segmentKeys?: ContinuityMessageSegmentKeys
}

export const TripReviewDynamicSiteMessages = observer(function TripReviewDynamicSiteMessages({
  dynamicSiteMessage,
  complianceMessages,
  continuityMessages,
  overlappingTrips,
  itineraryId,
  firstHotelId,
  segmentKeys,
}: Props) {
  const {
    alerts,
    dynamicSiteMessages,
    reviewTripDSMLightboxToggler,
  } = useTripReviewDynamicSiteMessages({
    dynamicSiteMessage,
    complianceMessages,
    continuityMessages,
    overlappingTrips,
    itineraryId,
    firstHotelId,
    segmentKeys,
  })

  if (!alerts.length && !dynamicSiteMessages.length) {
    return null
  }

  return (
    <>
      <Block paddingBottom={22}>
        <DynamicSiteMessages messages={dynamicSiteMessages} alerts={alerts} />
      </Block>
      <DynamicSiteMessagesLightboxModal
        messages={dynamicSiteMessage}
        isVisible={reviewTripDSMLightboxToggler.isOpen}
        onClose={reviewTripDSMLightboxToggler.handleClose}
      />
    </>
  )
})
