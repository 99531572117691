import type { GraphQLOperation } from './types'

const INCLUDED_OPERATIONS = [
  'getUser',
  'getDisplayConfiguration',
  'getBrandConfiguration',
  'getDeemHeader',

  'getAutocompletePlaces',
  'getTrip',
  'getOrderDetails',
  'getOrders',
  'getSupportArticleById',
  'getSupportArticles',
  'getSupportInformation',
  'getTravelPolicyList',
  'getTravelPolicy',
  'getTravelPreferences',
]

export function checkAbilityToProcess(body: GraphQLOperation): boolean {
  if (INCLUDED_OPERATIONS.includes(body?.operationName)) {
    return true
  }

  return false
}
