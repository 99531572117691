import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { RailPermittedStationsModalProps } from './types'
import { RailPermittedStationsContent } from './rail-permitted-stations-content'

const i18nBase = 'RailPermittedStations.'

export function RailPermittedStationsModal({
  isVisible,
  onClose,
  isPostBooking,
}: RailPermittedStationsModalProps) {
  const { t } = useTranslation()
  const { railStore } = useRailContext()
  const { postBookingTripDetailsStore } = usePostBookingContext()
  const permittedStations = isPostBooking
    ? postBookingTripDetailsStore.permittedStationsInfo
    : railStore.permittedStationsInfo

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      isHalfModal={true}
      withOverlayForMobile={true}>
      <Modal.Title withBorder>{t(i18nBase + 'modalTitle')}</Modal.Title>
      <Modal.Body>
        <RailPermittedStationsContent
          permittedOriginStations={permittedStations?.permittedOriginStations || []}
          permittedDestinationStations={permittedStations?.permittedDestinationStations || []}
          originStation={permittedStations?.originStation}
          destinationStation={permittedStations?.destinationStation}
          onClick={permittedStations?.onClick}
          mainStationsTitle={true}
          fromModal={true}
        />
      </Modal.Body>
    </Modal>
  )
}
