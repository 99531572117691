import { Action, Inject } from '@etta/di'
import { FlightLegMapper } from '@etta/modules/seat-map/infra/mappers'
import type {
  SearchFlightLegValueObject,
  SelectedSeatValueObject,
  ItineraryFlightLegValueObject,
} from '@etta/modules/seat-map/core/value-objects'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { TripFlightActions } from '@etta/modules/review-trip'
import type { SeatSelectedArgs } from '@etta/modules/seat-map/core/types'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { SeatMapStore } from '../store/seat-map.store'
import { SeatMapPaginationStore } from '../store/seat-map-pagination.store'
import { SeatMapService } from '../services/seat-map.service'

const DEFAULT_TRAVELER_ID = '1'

@Action()
export class SeatMapActions {
  constructor(
    @Inject() private readonly seatMapStore: SeatMapStore,
    @Inject() private readonly seatMapService: SeatMapService,
    @Inject() private readonly tripFlightActions: TripFlightActions,
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly seatMapPaginationStore: SeatMapPaginationStore,
  ) {}

  setFlightLegsFromAirResults(searchFlightLegs: SearchFlightLegValueObject[]) {
    const flightLegs = FlightLegMapper.toFlightLegEntities(searchFlightLegs)
    this.seatMapStore.setFlightLegs(flightLegs)
    this.seatMapStore.modalToggle.handleOpen()
  }

  setFlightLegsFromItinerary(itinararyFlightLegs: ItineraryFlightLegValueObject[]) {
    const flightLegs = FlightLegMapper.toFlightLegEntitiesFromItinerary(itinararyFlightLegs)
    this.seatMapStore.setFlightLegs(flightLegs)
    this.seatMapStore.modalToggle.handleOpen()
  }

  handleClosePopover() {
    this.seatMapStore.seatPopup.handleClose()
    this.seatMapStore.dropSeat()
  }

  handleSetIsReadonly(value: boolean) {
    this.seatMapStore.setIsReadonly(value)
  }

  handleSelectSeat({ seat: selectedSeat, cabinClass = '' }: SeatSelectedArgs) {
    this.seatMapStore.setSeatRow(selectedSeat)
    this.seatMapStore.setSelectedSeat(selectedSeat, cabinClass)
  }

  async handleSubmitSeat() {
    const selectedSeat = this.seatMapStore.seatMap.selectedSeat

    if (!selectedSeat) {
      return
    }

    const seats = this.seatMapStore.bookedSeats.filter(
      ({ segmentId }) => segmentId !== selectedSeat.segmentId,
    )

    if (this.seatMapStore.isSeatBooked) {
      await this.handleUnbookSeat(seats)
      return
    }

    await this.handleBookSeat([...seats, selectedSeat])
  }

  async handleBookSeat(seats: SelectedSeatValueObject[]) {
    this.seatMapStore.seatPopup.handleClose()

    this.seatMapStore.setViewSateModalStatus('saving')

    this.seatMapStore.setBookedSeats(seats)
    this.seatMapStore.dropSeat()

    const selectedSeatsToAdd = seats.map((seat) => ({
      seatNumber: seat.number,
      segmentId: seat.segmentId,
      travelerId: DEFAULT_TRAVELER_ID,
    }))

    const argsForAddSeats = {
      addSeats: selectedSeatsToAdd,
      itineraryId: this.reviewTripStore.itineraryId,
    }

    const response = await this.tripFlightActions.handleChangeFlightSeatsAssignment(argsForAddSeats)

    const viewStateModalNextStatus = response.isErr() ? 'error' : 'saved'

    this.seatMapStore.setViewSateModalStatus(viewStateModalNextStatus)

    await delayForSuccess()
    if (!response.isErr()) {
      const { selectedLegIndex, selectedSegmentIndex } = this.seatMapPaginationStore
      seats.forEach((seat) => {
        this.tripFlightActions.handleFlightSetSeat({
          legIndex: selectedLegIndex,
          segmentIndex: selectedSegmentIndex,
          seatNumber: seat.number,
          travelerId: DEFAULT_TRAVELER_ID,
        })
      })
    }

    this.seatMapStore.setViewSateModalStatus('hidden')
  }

  async handleUnbookSeat(seats: SelectedSeatValueObject[]) {
    this.seatMapStore.seatPopup.handleClose()

    this.seatMapStore.setViewSateModalStatus('removing')

    const newSeatsNumber = seats.map((s) => s.number)

    const seatsToRemove = this.seatMapStore.bookedSeats.filter(
      (bookedSeat) => !newSeatsNumber.includes(bookedSeat.number),
    )

    this.seatMapStore.setBookedSeats(seats)
    this.seatMapStore.dropSeat()

    const selectedSeatsToRemove = seatsToRemove.map((seat) => ({
      seatNumber: seat.number,
      segmentId: seat.segmentId,
      travelerId: DEFAULT_TRAVELER_ID,
    }))

    const argsForAddSeats = {
      addSeats: [],
      removeSeats: selectedSeatsToRemove,
      itineraryId: this.reviewTripStore.itineraryId,
    }

    const response = await this.tripFlightActions.handleChangeFlightSeatsAssignment(argsForAddSeats)

    const viewStateModalNextStatus = response.isErr() ? 'error' : 'removed'
    this.seatMapStore.setViewSateModalStatus(viewStateModalNextStatus)

    await delayForSuccess()
    if (!response.isErr()) {
      const { selectedLegIndex, selectedSegmentIndex } = this.seatMapPaginationStore
      this.tripFlightActions.handleFlightRemoveSeat({
        legIndex: selectedLegIndex,
        segmentIndex: selectedSegmentIndex,
      })
    }

    this.seatMapStore.setViewSateModalStatus('hidden')
  }

  async loadSeatMap(forceUpdate?: boolean) {
    await this.seatMapService.loadSelectedSeatMap(forceUpdate)
  }

  async handleReloadSeatMap() {
    await this.loadSeatMap(true)
  }

  handleDropStore() {
    this.seatMapStore.dropStore()
  }
}
