import type { ExpenseCodeEntity } from '@etta/modules/ride-hail/ui/select-expense-code/layout/types'
import {
  ExpenseCodeRowContainer,
  ExpenseCodeRowContent,
  ExpenseCodeRowContentContainer,
  ExpenseReceiptIcon,
  RowContentCheckedIcon,
  RowContentCode,
  RowContentDescription,
} from './expense-code-row-components'

export type LayoutProps = {
  expenseCode: ExpenseCodeEntity
  onClick: () => void
  isSelected: boolean
}

export const ExpenseCodeRow = ({ expenseCode, onClick, isSelected }: LayoutProps) => {
  return (
    <ExpenseCodeRowContainer onClick={onClick}>
      <ExpenseReceiptIcon name="receiptPWA" color="primary" />
      <ExpenseCodeRowContentContainer>
        <ExpenseCodeRowContent>
          <RowContentCode>{expenseCode.id}</RowContentCode>
          <RowContentDescription>{expenseCode.description}</RowContentDescription>
        </ExpenseCodeRowContent>
        {isSelected ? <RowContentCheckedIcon name="newTickSharpPWA" color="primary" /> : <></>}
      </ExpenseCodeRowContentContainer>
    </ExpenseCodeRowContainer>
  )
}
