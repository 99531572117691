import styled from 'styled-components'
import { footnoteStrong } from '@fiji/style'

export const Container = styled.div``

export const ShowDetails = styled.div`
  margin-top: 13px;
  color: ${(props) => props.theme.colors.primary1};
  cursor: pointer;
  ${footnoteStrong};
`
