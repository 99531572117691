import { useTranslation } from 'react-i18next'
import type { CarRentalTypeFilter, CarRentalType } from '@fiji/graphql/types'
import { FILTER_I18N_KEYS } from '../../constants'
import type { Props } from '../../types'
import { Container, FilterButton, Title } from './components'

export function CarRentalTypeFilterBarMobile({
  onOptionClick,
  activeFilterOptions,
  availableFilters,
}: Props) {
  const { t } = useTranslation()

  if (!availableFilters || availableFilters.length === 0) {
    return null
  }

  const handleOptionClick = (filter: CarRentalType, isFilterActive: boolean) => {
    if (isFilterActive) {
      onOptionClick(activeFilterOptions?.filter((option) => option !== filter) || [])
    } else {
      onOptionClick([...(activeFilterOptions || []), filter])
    }
  }

  return (
    <Container aria-label={t('CarRentalTypeFilterBar.Title')}>
      {availableFilters.map((filter: CarRentalTypeFilter & { customName?: string }) => {
        const isFilterActive = !!activeFilterOptions?.includes(filter.code)
        const filterName = filter.customName ? filter.customName : t(FILTER_I18N_KEYS[filter.code])

        return (
          <FilterButton
            key={filter.code}
            isActive={isFilterActive}
            aria-pressed={isFilterActive}
            onClick={() => handleOptionClick(filter.code, isFilterActive)}
            data-tracking-id={`car-rental-filter-type-button_${filterName}`}>
            <Title>{filterName}</Title>
          </FilterButton>
        )
      })}
    </Container>
  )
}
