import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Tooltip } from '@etta/ui/tooltip'
import { TooltipMultilineContent } from '@etta/ui/tooltip/tooltip-multiline-content/tooltip-multiline-content'

type Props = {
  outOfPolicyTexts: string[]
}

export function PriceOutOfPolicy({ outOfPolicyTexts }: Props) {
  const { t } = useTranslation()
  const tooltipTitle = t('TooltipText.OutOfPolicyReasons')

  if (!outOfPolicyTexts.length) {
    return (
      <Block marginRight={3}>
        <Icon name="outOfPolicyPWA" color="error" size={24} />
      </Block>
    )
  }

  return (
    <Tooltip
      slot={<TooltipMultilineContent title={tooltipTitle} textItems={outOfPolicyTexts} />}
      place="top"
      isIndependentOfParentWidth>
      <Block marginRight={3}>
        <Icon name="outOfPolicyPWA" color="error" size={24} />
      </Block>
    </Tooltip>
  )
}
