import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Badge } from '@etta/ui/badge'
import type { Preference } from '@fiji/graphql/types'
import type { IconSizes } from '@etta/ui/icon'
import type { TooltipPlace } from '@etta/ui/tooltip'
import { Tooltip } from '@etta/ui/tooltip'
import { Text } from '@etta/ui/text'

type Props = {
  preference?: Preference | null
  badgeSize: IconSizes
  tooltipPlace?: TooltipPlace
}

export function PreferredBadge({ badgeSize, preference, tooltipPlace }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const translatedCode = useMemo(() => {
    switch (preference?.code) {
      case 'MOST_PREFERRED':
        return t('SearchResults.Hotel.PreferenceCode.MostPreferred')
      case 'HIGHLY_PREFERRED':
        return t('SearchResults.Hotel.PreferenceCode.HighlyPreferred')
      default:
        return t('SearchResults.Hotel.PreferenceCode.Preferred')
    }
  }, [preference?.code, t])

  if (screenType === ScreenType.Mobile) {
    return <Badge variant="preferred" color="bodyText" iconSize={badgeSize} />
  }

  return (
    <Tooltip
      slot={
        <Text variant="footnoteMedium">
          {t('SearchResults.Hotel.PreferredStatus')}
          <br />
          {translatedCode}
        </Text>
      }
      place={tooltipPlace}>
      <Badge variant="preferred" color="bodyText" iconSize={badgeSize} />
    </Tooltip>
  )
}
