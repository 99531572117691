import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'

export const ApplyButton = styled(Button)``

export const ClearButton = styled(Button)`
  margin-right: 16px;
`

export const FooterContainer = styled(Modal.Footer)`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  margin: 0;
  padding: 16px 20px;
  width: 100%;
`
