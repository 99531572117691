import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  margin: 0;
  padding: 16px 0;
  overflow: hidden;

  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }
`
