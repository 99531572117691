import { useHistory } from 'react-router'
import { useCallback, useState } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

function getInitialTripType(storeType: TripType): TripType {
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  if (storeType === TripType.OnHold && isMobile) {
    return TripType.Upcoming
  }
  return storeType
}

export function useTripsPage() {
  const {
    featureFlagsStore: {
      flags: { isDelegatedFlowEnabled, isAppleBannerEnabled },
    },
  } = useFeatureFlagsContext()
  const { postBookingTripPreviewStore } = usePostBookingContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const isAppleBannerShown = brandConfigurationStore.isAppleTheme && isAppleBannerEnabled
  const tripType = postBookingTripPreviewStore.tripPreview.tripType
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(getInitialTripType(tripType))

  const handleChangeTab = useCallback(
    (value: TripType) => {
      if (value === activeTab) {
        return
      }

      setActiveTab(value)
    },
    [activeTab],
  )

  const handleHeldTripsRedirect = useCallback(() => {
    history.push(ROUTES.postBooking.heldTrips)
  }, [history])

  return {
    handleChangeTab,
    handleHeldTripsRedirect,
    activeTab,
    isAppleBannerShown,
    isDelegatedFlowEnabled: isDelegatedFlowEnabled,
  }
}
