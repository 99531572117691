import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.nav`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(249, 249, 249, 0.94);
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
  border-top: 1px solid ${({ theme }) => theme.colors.borderDark};
  backdrop-filter: blur(20px);
`

export const NavItem = styled(NavLink)`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  flex: 1;

  &.active {
    color: ${(props) => props.theme.colors.primary};
    svg path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
  &,
  &:active {
    color: ${(props) => props.theme.colors.bodyText};
    svg path {
      fill: ${(props) => props.theme.colors.bodyText};
    }
  }
  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const NavText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.001em;
`
