import { MediaLayout } from '@etta/ui/media-layout'
import type { TripCostSummaryLayoutProps } from '../types'
import { TripCostSummaryMobile } from './mobile'
import { TripCostSummaryDesktop } from './desktop'

export function TripCostSummaryLayout(props: TripCostSummaryLayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<TripCostSummaryMobile {...props} />}
      desktopSlot={<TripCostSummaryDesktop {...props} />}
    />
  )
}
