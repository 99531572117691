const tmp = document.createElement('div')

export const stripTags = (str?: string | null): string => {
  if (!str) {
    return ''
  }

  tmp.innerHTML = str
  return tmp.textContent || tmp.innerText || ''
}
