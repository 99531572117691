import { BrandsBlockLayout } from './layout'
import type { LayoutProps } from './types'

export const BrandsSelect = ({
  onChange,
  brands,
  superBrands,
  selectedBrands,
  sortedBrands,
  isModal,
  isNewLayout,
}: LayoutProps) => {
  return (
    <BrandsBlockLayout
      isNewLayout={isNewLayout}
      isModal={isModal}
      onChange={onChange}
      selectedBrands={selectedBrands}
      brands={brands}
      superBrands={superBrands}
      sortedBrands={sortedBrands}
    />
  )
}
