import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { RailSearchFormActions } from './actions/rail-search-form.action'
import { RailSearchButtonActions } from './actions/rail-search-button.action'
import { RailSearchFormStore } from './stores/rail-search-form.store'

export const { useModuleContext: useRailSearchFormContext } = hookContextFactory({
  railSearchFormStore: RailSearchFormStore,
  railSearchFormActions: RailSearchFormActions,
  railSearchButtonActions: RailSearchButtonActions,
})
