import { observer } from 'mobx-react-lite'
import { Checkbox } from '@etta/ui/checkbox'
import type { FilterOptionsProps } from '../../types'
import { StyledBlock } from '../../car-rental-filters-styled'
import { useSuppliersOptions } from './use-suppliers-options'
import { SupplierImg } from './supplier-options-styled'

export const SuppliersOptions = observer(function SuppliersOptions({
  optionsPaddingVertical = 10,
  withBottomBorder = true,
}: FilterOptionsProps) {
  const { options, isChecked, handleSelectSupplier } = useSuppliersOptions()

  return (
    <>
      {options.map((option) => {
        return (
          <StyledBlock
            paddingVertical={optionsPaddingVertical}
            paddingRight={16}
            key={option.id}
            withBottomBorder={withBottomBorder}>
            <Checkbox
              data-tracking-id="supplier-filter"
              value={option.id}
              label={option.title}
              checked={isChecked(option.id)}
              onChange={handleSelectSupplier(option.id)}
            />
            {option.imageUrl && <SupplierImg src={option.imageUrl} alt={option.title} />}
          </StyledBlock>
        )
      })}
    </>
  )
})
