import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { HotelDistanceTitle } from '@etta/components/hotel-distance/hotel-distance-title'
import { HotelDistanceSlider } from '@etta/components/hotel-distance/hotel-distance-slider'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

export const HotelDistance = observer(function HotelDistance() {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()

  return (
    <>
      <HotelDistanceTitle distance={hotelFiltersStore.selectedFilters.distance} />
      <Block paddingHorizontal={14}>
        <HotelDistanceSlider
          distance={hotelFiltersStore.selectedFilters.distance}
          onChange={(value) => hotelFiltersAction.handleSetFilter('distance', value)}
        />
      </Block>
    </>
  )
})
