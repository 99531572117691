import type { Geocode } from '@fiji/graphql/types'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import type { CarLocations } from '@fiji/types'

type Props = {
  carRental: CarRentalSegmentEntity
}

function getGeocodes(geocode?: Geocode | null) {
  if (geocode) {
    return {
      lat: geocode?.lat,
      lng: geocode?.long,
    }
  }
  return null
}

export function getLocations({ carRental }: Props): CarLocations {
  const geocode = getGeocodes(carRental.pickUpLocation.address?.geocode)
  const pickUp = {
    date: tripReviewFormatDate(TripReviewDateType.FullDateAndTime, carRental.pickUpTime) || '',
    location: formatPickUpDropOffAddress(carRental.pickUpLocation.address),
  }
  const dropOff = {
    date: tripReviewFormatDate(TripReviewDateType.FullDateAndTime, carRental.dropOffTime) || '',
    location: formatPickUpDropOffAddress(carRental.dropOffLocation.address),
  }

  return {
    geocode,
    pickUp,
    dropOff,
  }
}
