import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { HotelRoomSkeletonDesktop } from './desktop'
import { HotelRoomSkeletonMobile } from './mobile'

export function HotelRoomSkeleton() {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={HotelRoomSkeletonMobile}
      desktopSlot={HotelRoomSkeletonDesktop}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
