import type { PrimaryTravelerPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/primary-traveler-persist.value-object'
import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'
// eslint-disable-next-line import/no-restricted-paths
import { PhoneType } from '@fiji/graphql/types'
import { addressMapper } from './address.mapper'

export class PrimaryTravelerMapper {
  static toPrimaryTravelerInput(
    traveler: PrimaryTravelerPersistValueObject,
  ): CheckoutItineraryInputValueObject['primaryTraveler']['data'] {
    const primaryTravelerInput: CheckoutItineraryInputValueObject['primaryTraveler']['data'] = {
      id: traveler.id ?? '',
      email: traveler.email ?? '',
      primary: true,
      middleInitial: traveler.middleName,
      //   What is passport?
      //   passport: traveler.passport,
      phoneNumber: [],
      dateOfBirth: traveler.dateOfBirth,
      firstName: traveler.firstName,
      gender: traveler.gender,
      lastName: traveler.lastName,
      suffix: traveler.suffix,
      title: traveler.title,
      address: addressMapper(traveler.address),
      knownTravelerNumbers: traveler.knownTravelerNumbers ?? [],
      redressNumbers: traveler.redressNumbers ?? [],
    }

    if (traveler.phone) {
      primaryTravelerInput.phoneNumber?.push({
        countryCode: traveler.phone.countryCode,
        number: traveler.phone.number,
        type: PhoneType.Business,
      })
    }

    if (traveler.personalMobile) {
      primaryTravelerInput.phoneNumber?.push({
        countryCode: traveler.personalMobile.countryCode,
        number: traveler.personalMobile.number,
        type: PhoneType.Mobile,
      })
    }

    return primaryTravelerInput
  }
}
