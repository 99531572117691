import { useCallback, useState } from 'react'
import { SeatReservationType } from '@fiji/graphql/types'
import type { RailLayoutType } from '@fiji/enums'
import type { SeatPreferences } from '@fiji/graphql/hooks'
import type { Preferences } from '../types'

type Args = {
  layout: RailLayoutType
  onPreferencesApply: (newFilters: Preferences) => void
  appliedPreferences: Preferences
  preferenceOptions: SeatPreferences
  seatReservationTypesArray?: SeatReservationType[]
}

export function useTripPreviewSeatPreferences({
  appliedPreferences,
  preferenceOptions,
  onPreferencesApply,
  seatReservationTypesArray,
}: Args) {
  const [changedPreferences, setChangedPreferences] = useState(appliedPreferences)

  const onPreferencesChange = useCallback((newPreferences: Partial<Preferences>) => {
    setChangedPreferences((prev) => ({ ...prev, ...newPreferences }))
  }, [])

  const onApply = () => {
    onPreferencesApply(changedPreferences)
  }

  const isSubmitOptional =
    !!seatReservationTypesArray?.includes(SeatReservationType.Optional) &&
    !seatReservationTypesArray?.includes(SeatReservationType.Mandatory)

  const isSubmitEnabled =
    !isSubmitOptional ||
    !!changedPreferences.isSeatRequestedOnDepart ||
    !!changedPreferences.isSeatRequestedOnReturn

  return {
    preferenceOptions,
    onPreferencesChange,
    changedPreferences,
    onApply,
    isSubmitOptional,
    isSubmitEnabled,
  }
}
