import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { Paper } from '../../../trip-flight-styled'
import { LayoverRow } from './layover-styled'
import { useLayoverTexts } from './use-layover-texts'

type Props = {
  flightLegSegments: TripDetailsFlightLegSegment[]
  layoverTime: string
  airportName: string
  airportCode: string
}

export function Layover({ layoverTime, airportName, airportCode, flightLegSegments }: Props) {
  const { finalText, layoverLabel } = useLayoverTexts({
    flightLegSegments,
    layoverTime,
    airportName,
    airportCode,
  })

  return (
    <Block aria-label={layoverLabel} data-tracking-id="flight-layover" marginVertical={24}>
      <Paper>
        <Block paddingHorizontal={16} paddingVertical={12}>
          <LayoverRow>
            <Icon name="layoverTimePWA" size="large" />
            <Block marginLeft={10}>
              <Text variant="footnoteStrong" color="bodyText">
                {finalText}
              </Text>
            </Block>
          </LayoverRow>
        </Block>
      </Paper>
    </Block>
  )
}
