import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripDetailsFlightLeg, SeatmapCabinClass } from '@fiji/graphql/types'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'

type Props = {
  flightLeg: TripDetailsFlightLeg
}

export function useDuplicateFlightSegment({ flightLeg }: Props) {
  const { t } = useTranslation()

  const formatTime = (time?: string | null) =>
    tripReviewFormatDate(TripReviewDateType.Time, time)?.replace(/\s/g, '').toLowerCase()

  const operatedBy = useMemo(() => {
    const operatedBy = flightLeg.segments
      ?.map((segment) => segment.operatedBy)
      .filter(Boolean)
      .join(', ')

    return operatedBy
      ? t('FlightDetails.OperatedBy', {
          operatedBy,
        })
      : undefined
  }, [t, flightLeg])

  const flightSegment = (flightLeg.segments || [])[0]

  const { getCabinClassLabel } = useCabinClassLabels()
  const cabinClassLabel = getCabinClassLabel(flightSegment.serviceClass as SeatmapCabinClass)

  const showRefundableLabel = flightLeg.segments?.every((segment) =>
    Boolean(segment.penaltyFees?.length),
  )

  return {
    formatTime,
    operatedBy,
    cabinClassLabel,
    showRefundableLabel,
  }
}
