import type { TripCost } from '@fiji/graphql/types'

type Args = {
  tripCost?: TripCost
}

export function hasTripCost({ tripCost }: Args) {
  if (tripCost?.total.primary.amount) {
    return true
  }

  return false
}
