import { useCallback } from 'react'
import type { BrandFilter } from '@fiji/graphql/types'

type Args = {
  selectedIdsMap: Set<string>
  onSelect: (idsMap: Set<string>) => void
}

export function useBrandChange({ selectedIdsMap, onSelect }: Args) {
  return useCallback(
    (isChecked: boolean, brand: BrandFilter) => {
      if (isChecked) {
        const mergedSet = new Set([...Array.from(selectedIdsMap), brand.brandId])
        onSelect(mergedSet)
        return
      }
      const extractedSet = new Set([...Array.from(selectedIdsMap)])
      extractedSet.delete(brand.brandId)
      onSelect(extractedSet)
    },
    [onSelect, selectedIdsMap],
  )
}
