import { useEffect, useState } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { trackAction } from '@fiji/utils/tracking/track-action'

const ACTION_NAME_SUCCESS = 'mobility-confirmation-success-page'
const ACTION_NAME_ERROR = 'mobility-confirmation-error-page'

export type Props = {
  showAnimation: boolean
}

export function useTrackMobilityConfirmation({ showAnimation }: Props) {
  const [isTracked, setIsTracked] = useState(false)
  const { rideHailBookStore } = useRideHailSearchContext()

  useEffect(() => {
    const isBookingLoading = rideHailBookStore.isLoading || showAnimation
    const isBookingSuccess = !rideHailBookStore.isError && !isTracked
    const isBookingError =
      (rideHailBookStore.isError || rideHailBookStore.errorMessage) && !isTracked

    if (isBookingLoading) {
      return
    }

    if (isBookingSuccess) {
      trackAction(ACTION_NAME_SUCCESS)
      setIsTracked(true)
      return
    }

    if (isBookingError) {
      trackAction(ACTION_NAME_ERROR, { error: rideHailBookStore.errorMessage })
      setIsTracked(true)
    }
  }, [
    isTracked,
    rideHailBookStore.isLoading,
    rideHailBookStore.isError,
    rideHailBookStore.errorMessage,
    showAnimation,
  ])
}
