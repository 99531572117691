import type { GetOrdersQuery } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type {
  TripsListValueObject,
  GroupedTripsByMonth,
} from '../../../core/value-object/trips-list.value-object'

type OrdersResponse = GetOrdersQuery['orders']

export class OrdersMapper {
  static toOrdersValueObject(response: OrdersResponse): TripsListValueObject {
    const groups = response.transactionGroups.reduce((acc: GroupedTripsByMonth[], trip) => {
      const year = dateFormat(new Date(trip.rollUpStartTime), 'yyyy')
      const month = dateFormat(new Date(trip.rollUpStartTime), 'MMMM')

      const dateIndex = acc.findIndex((item) => item.year === year && item.month === month)

      if (dateIndex === -1) {
        acc.push({ year, month, trips: [trip] })
        return acc
      }

      acc[dateIndex].trips.push(trip)

      return acc
    }, [])

    return {
      hasNextPage: response.hasNextPage,
      pageSize: response.pageSize,
      startIndex: response.startIndex,
      totalResults: response.totalResults,
      groups,
    }
  }
}
