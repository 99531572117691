import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalSegmentDesktopLayoutProps } from './desktop'
import { CarRentalSearchSegmentDesktop } from './desktop'
import type { CarRentalSegmentMobileLayoutProps } from './mobile'
import { CarRentalSearchSegmentMobile } from './mobile'

type CarRentalSearchSegmentLayoutProps = CarRentalSegmentDesktopLayoutProps &
  CarRentalSegmentMobileLayoutProps

export function CarRentalSearchSegmentLayout(props: CarRentalSearchSegmentLayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalSearchSegmentMobile {...props} />}
      desktopSlot={<CarRentalSearchSegmentDesktop {...props} />}
    />
  )
}
