import type { RefCallback } from 'react'
import { useLayoutEffect, useRef, useState } from 'react'
import { cast, mergeRefs } from './helpers'
import type { DescendantOptions } from './type'
import { useDescendantsContext } from './descendant-provider'

export function useDescendant<
  T extends HTMLElement = HTMLElement,
  K extends Record<string, any> = {}
>(options?: DescendantOptions<K>) {
  const descendants = useDescendantsContext()
  const [index, setIndex] = useState(-1)
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    return () => {
      if (!ref.current) {
        return
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      descendants.unregister(ref.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const dataIndex = Number(ref.current.dataset['index'])
    if (index !== dataIndex && !Number.isNaN(dataIndex)) {
      setIndex(dataIndex)
    }
  })

  const refCallback = options
    ? cast<RefCallback<T>>(descendants.register(options))
    : cast<RefCallback<T>>(descendants.register)

  return {
    descendants,
    index,
    enabledIndex: descendants.enabledIndexOf(ref.current),
    register: mergeRefs(refCallback, ref),
  }
}
