import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Link } from '@etta/ui/link'

const i18nBase = 'RailDeliveryOption.'

type Props = {
  email?: string
}

export function EticketViewPurchaseSuccess({ email }: Props) {
  const { t } = useTranslation()

  if (!email) {
    return null
  }

  return (
    <Block paddingHorizontal={24} paddingVertical={16}>
      <Text variant="footnoteMedium" color="bodyText">
        {t(i18nBase + 'DeliveryEticket')}
        <Link size="small" href={`mailto:${email}`}>
          {email}
        </Link>
      </Text>
    </Block>
  )
}
