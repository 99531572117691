import { Inject, Service } from '@etta/di'
import { UserAdapter } from '@etta/modules/user/infra/user.adapter'
import type { Validator } from '@etta/modules/user/interface/stores/create-password.store'
import {
  CreatePasswordStore,
  ValidationStateEnum,
} from '@etta/modules/user/interface/stores/create-password.store'
import { UserActivationStore } from '@etta/modules/user/interface/stores/user-activation.store'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import { isChangePasswordBusinessError } from '../../core/errors'

@Service()
export class CreatePasswordService {
  constructor(
    @Inject() private readonly userAdapter: UserAdapter,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly createPasswordStore: CreatePasswordStore,
    @Inject() private readonly userActivationStore: UserActivationStore,
  ) {}

  async handleCheckSSO() {
    const email = this.userStore.profile?.personalContact?.email
    if (!email) {
      return
    }
    const result = await this.userAdapter.getSitesByEmail(email)

    if (result.isErr()) {
      this.userActivationStore.setDisplayStatus('error')
      return
    }

    if (result.isOk()) {
      this.userActivationStore.setDisplayStatus(null)
      return result.getValue()
    }
  }

  async getUserPasswordSecurityConfigure() {
    const result = await this.userAdapter.getUserPasswordSecurityConfigure()

    if (result.isErr()) {
      this.userActivationStore.setDisplayStatus('error')
      return
    }

    if (result.isOk()) {
      this.userActivationStore.setDisplayStatus(null)
      return result.getValue()
    }
  }

  async savePassword() {
    const password = this.createPasswordStore.password
    const confirmPassword = this.createPasswordStore.confirmPassword

    const isPasswordMatch = this.isPasswordMatch(password, confirmPassword)
    const isPasswordNotEmpty = this.isPasswordNotEmpty(password)

    this.createPasswordStore.setValidationWith({ isPasswordNotEmpty: isPasswordNotEmpty })
    this.createPasswordStore.setConfirmValidationWith({ isPasswordMatch: isPasswordMatch })

    if (
      isPasswordMatch === ValidationStateEnum.error ||
      isPasswordNotEmpty === ValidationStateEnum.error
    ) {
      return
    }

    const result = await this.userAdapter.changePassword({
      newPassword: password,
      newPasswordConfirmation: confirmPassword,
    })

    const errors: string[] = []
    if (result.isErr()) {
      const error = result.getError()
      if (isChangePasswordBusinessError(error)) {
        errors.push(...error.error.errorCodes)
      } else {
        this.userActivationStore.setDisplayStatus('error')
        return
      }
    }

    const validator = Object.keys(this.createPasswordStore.validationErrors).reduce(
      (validator, key) => {
        validator[key as keyof Validator] = errors.includes(key)
          ? ValidationStateEnum.error
          : ValidationStateEnum.success
        return validator
      },
      {} as Validator,
    )

    this.userActivationStore.setDisplayStatus(null)
    this.createPasswordStore.setValidationWith(validator)
  }

  private isPasswordMatch(password: string, confirmPassword: string) {
    if (password && password === '' && confirmPassword && confirmPassword === '') {
      return ValidationStateEnum.empty
    }
    return password === confirmPassword ? ValidationStateEnum.success : ValidationStateEnum.error
  }

  private isPasswordNotEmpty(password: string) {
    return password && password !== '' ? ValidationStateEnum.success : ValidationStateEnum.error
  }
}
