import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { ReserveRideButtonProps } from '@etta/components/mobility-ride-review-dialog/types'

export function MobilityRideReserveButton({
  onReserveRideClick,
  isLoading,
}: ReserveRideButtonProps) {
  const { t } = useTranslation()
  const reviewPageBasePath = 'Mobility.ReviewPage.'

  return (
    <Button
      data-tracking-id="mobility-book-ride-button"
      fullWidth
      variant="solid"
      size="normal"
      onClick={onReserveRideClick}
      disabled={isLoading}>
      {t(reviewPageBasePath + 'ButtonText')}
    </Button>
  )
}
