import styled from 'styled-components'
import { rgba } from 'polished'
import { getKeyboardNavigationStyle } from '@fiji/style'
import { THUMB_TOTAL_HEIGHT, GALLERY_BOTTOM } from './constants'

type SideImagesArgs = {
  top: number
  length: number
  thumbsInView: number
  remainThumbs: number
}

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.colors.background2};
  opacity: 0.9;
  z-index: 15;
`

export const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
`

export const EnlargedImage = styled.div`
  height: calc(100vh - ${GALLERY_BOTTOM}px);
  overflow: hidden;
  margin: 30px 30px 0;
  display: flex;
`

export const Close = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;

  ${getKeyboardNavigationStyle({ offset: -5, borderRadius: 20 })}
`

export const MainFocus = styled.div`
  position: relative;
  width: calc(100% - 201px);
  padding: 23px 30px 0 30px;
  border-right: 1px solid ${(p) => rgba(p.theme.colors.borderDark, 0.5)};
`

export const ArrowControl = styled.button`
  background: ${(p) => p.theme.colors.white};
  opacity: 0.9;
  border: none;
  position: absolute;
  top: calc(50% - 43px);
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.12);
  width: 30px;
  height: 86px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  &:disabled {
    pointer-events: none;
  }
`

export const LeftArrow = styled(ArrowControl)`
  border-radius: 0 4px 4px 0;
  left: -1px;
  svg {
    margin-left: -6px;
  }
`

export const RightArrow = styled(ArrowControl)`
  border-radius: 4px 0 0 4px;
  right: -1px;
  svg {
    margin-right: -6px;
  }
`

export const Sidebar = styled.div`
  margin-top: 81px;
  width: 201px;
  height: calc(100vh - ${GALLERY_BOTTOM}px + 4px);
  overflow: hidden;
`

export const SideImages = styled.div<{ positionData: SideImagesArgs }>`
  margin-left: auto;
  margin-right: auto;
  width: 156px;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;
  margin-top: ${(p) => {
    const { top, length, thumbsInView, remainThumbs } = p.positionData
    if (length === 1) {
      return 0
    }
    if (top < length - thumbsInView) {
      return -top * THUMB_TOTAL_HEIGHT
    }
    return -(length - thumbsInView) * THUMB_TOTAL_HEIGHT + remainThumbs
  }}px;
`
