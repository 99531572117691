import styled from 'styled-components'
import { rgba } from 'polished'

export const PinContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  transform: translate(-50%, -50%);
`

export const MarkerBorder = styled.div<{ diameter: string; opacity: number; isVisible: boolean }>`
  background: ${(p) => rgba(p.theme.colors.background2, p.isVisible ? p.opacity : 0)};
  width: ${(p) => p.diameter}px;
  height: ${(p) => p.diameter}px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Marker = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 4px solid ${(p) => p.theme.colors.mainText1};
`
