import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { BookTripErrors } from '../../core/errors'
import { CheckoutCartDataProvider } from './checkout-cart.data-provider'
import type { CheckoutCartArgs, CheckoutCartResults } from './types'

@Adapter()
export class CheckoutCartAdapter {
  constructor(@Inject() private readonly checkoutCartDataProvider: CheckoutCartDataProvider) {}

  async handleCheckoutCart({
    cartId,
    emailAddress,
    gender,
    givenName,
    mobilePhoneNumber,
    surname,
    middle,
    suffix,
    tripName,
    title,
  }: CheckoutCartArgs): CheckoutCartResults<{ code: string; message: string }> {
    try {
      const { data, errors } = await this.checkoutCartDataProvider.handleCheckoutCart({
        cartId,
        passenger: {
          emailAddress,
          gender,
          mobilePhoneNumber,
          name: {
            givenName,
            surname,
            middle,
            suffix,
            title,
          },
        },
        reservationTitle: tripName,
      })

      if (errors) {
        return Result.Err(new BookTripErrors.BookTripResponse(errors))
      }
      if (!data) {
        return Result.Err(new BookTripErrors.BookTripResponse('No data returned'))
      }

      if (data.checkoutCart.__typename === 'CartNotFoundResponse') {
        return Result.Err(new BookTripErrors.BookTripNotFoundResponse(data.checkoutCart.message))
      }

      if (data.checkoutCart.__typename === 'UnexpectedError') {
        return Result.Err(new BookTripErrors.UnexpectedErrorBookTrip(data.checkoutCart.message))
      }

      const { code, message } = data.checkoutCart

      return Result.Ok({
        code,
        message,
      })
    } catch (e) {
      return Result.Err(new BookTripErrors.UnexpectedErrorBookTrip(e))
    }
  }
}
