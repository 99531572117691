import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace FeatureFlagsErrors {
  export const FlagsResponse = createErrorClass('Failed flags')
  export const UnexpectedErrorFlags = createErrorClass('Processed flags')
}

export type FeatureFlagsErrorsInstances = InstanceType<
  typeof FeatureFlagsErrors[keyof typeof FeatureFlagsErrors]
>
