import { Store } from '@etta/di'

@Store()
export class HistoryStore<T = unknown> {
  search: string = ''
  pathname: string = ''
  currentPath: string = ''
  private params: unknown
  private _state: T | null = null

  updateCurrentPath(path?: string) {
    if (path) {
      this.currentPath = path
    }
  }

  updateState(search: string, pathname: string, state: T) {
    if (search !== this.search) {
      this.search = search
    }
    if (this.pathname !== pathname) {
      this.pathname = pathname
    }
    if (state !== this.state) {
      this._state = state
    }
  }

  updateParams(params: unknown) {
    if (this.params !== params && params) {
      this.params = params
    }
  }

  matchPathname(pathname: string) {
    return this.pathname.includes(pathname)
  }

  get state() {
    return this._state
  }

  getParams<T>(): T {
    return this.params as T
  }
}
