import styled from 'styled-components'
import {
  regularListItem,
  subHeadMedium,
  captionStrongCaps,
  getKeyboardNavigationStyle,
} from '@fiji/style'

export const Title = styled.span`
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.borderLight};
  padding: 4px 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${captionStrongCaps};
`

export const Test = styled.li`
  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const ListItem = styled.li<{
  active: boolean
}>`
  cursor: pointer;
  position: relative;
  padding: 2px 0;
  margin: 0;
  ${subHeadMedium};
  ${regularListItem};
  color: ${(p) => p.theme.primaryColor};
  box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.borderLight};

  &:hover {
    background-color: ${(p) => p.theme.inputBorderColor};
  }

  &:last-child {
    box-shadow: none;
  }
`
