import { Action, Inject } from '@etta/di'
import { PostBookingTripStore } from '@etta/modules/post-booking/interface/stores/trip/post-booking-trip.store'
import { MultipleHotelProvidersStore } from '../stores/multiple-hotel-providers.store'
import type { ActionType } from '../../core/constants'

@Action()
export class MultipleHotelProvidersActions {
  constructor(
    @Inject() private readonly multipleHotelProvidersStore: MultipleHotelProvidersStore,
    @Inject() private readonly postBookingTripStore: PostBookingTripStore,
  ) {}

  handleSetActionType(actionType: ActionType) {
    this.multipleHotelProvidersStore.setActionType(actionType)
  }

  handleSetProviderName(providerName?: string | null) {
    if (providerName) {
      this.multipleHotelProvidersStore.setProviderName(providerName)
    }
  }

  areMultipleProvidersExist(providerName?: string | null) {
    if (!providerName) {
      return new Set(this.postBookingTripStore.hotelSources).size > 1
    }

    const hotelSourcesInLowerCase = [
      ...this.postBookingTripStore.hotelSources,
      providerName,
    ].map((s) => s.toLowerCase())

    return new Set(hotelSourcesInLowerCase).size > 1
  }

  dropStore() {
    this.multipleHotelProvidersStore.dropStore()
  }
}
