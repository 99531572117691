import styled from 'styled-components'
import { headline, footnoteStrong, linkSmall } from '@fiji/style'

export const Container = styled.div``

export const CardContainer = styled.div`
  align-items: center;
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.background};
  border-radius: 14px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const LeftBlock = styled.div``
export const RightBlock = styled.div``

export const EmissionKg = styled.p`
  color: ${(props) => props.theme.colors.mainText2};
  margin: 0;
  ${headline};
`

export const DescriptionBlock = styled.p`
  margin: 5px 0;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteStrong};
`

export const AmountBlock = styled.p`
  margin: 5px 0;
  font-size: 22px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.mainText};
`

export const LearnMore = styled.a`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: none;
  ${linkSmall};
`
