import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { linkSmall } from '@fiji/style'

const ellipsisOverflow = css`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AboutHotelText = styled(Text)``

export const LinkWrapper = styled.div`
  margin-top: 8px;
  align-self: flex-start;
  ${linkSmall}
`

export const DescriptionTitle = styled(Text)<{ isEmptyTitle?: boolean }>`
  display: block;
  margin: ${({ isEmptyTitle }) => (isEmptyTitle ? 'unset' : '8px 0 12px')};
`
export const DescriptionBody = styled(Text)``

export const ShortDetailsText = styled(Text)``

export const HotelAboutContainer = styled.div<{ hasEllipsisOverflow?: boolean }>`
  ${({ hasEllipsisOverflow }) => hasEllipsisOverflow && ellipsisOverflow};
`
