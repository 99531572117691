import styled from 'styled-components'
import { UnstyledButton, subHeadStrong } from '@fiji/style'

export const Container = styled.div``

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`

export const ListItemContainer = styled.div`
  padding: 0 20px;
  &:active {
    background-color: ${({ theme }) => theme.colors.background1};
  }

  &:last-child {
    border: 0;
  }
`

export const ListItem = styled(UnstyledButton)<{
  isWarning?: boolean
  isDisabled?: boolean
  isSeparatorDisabled?: boolean
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: ${(p) => (p.isSeparatorDisabled ? '0px' : '1px')} solid
    ${(p) => p.theme.colors.borderDark};
  padding: 8px 0;
  margin: 0;
  min-height: 60px;
  color: ${({ isWarning, theme }) => (isWarning ? theme.colors.error : theme.colors.mainText)};
  background-color: transparent;
  text-transform: capitalize;

  ${(p) => {
    if (p.isDisabled) {
      return `
        opacity: 0.4;
        pointer-events: none;
      `
    }

    return 'cursor: pointer;'
  }}
  ${subHeadStrong};
`

export const ListItemIcon = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`
