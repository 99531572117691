import { useCallback, useEffect, useRef, useState } from 'react'
import { useNetworkStatus } from '@fiji/hooks/use-network-status'
import { usePrevious } from '@fiji/hooks/use-previous'
import { useUserContext } from '@etta/modules/user'

const DEFAULT_TIMEOUT = 5000

export function useConnectionStatus() {
  const { userStore } = useUserContext()
  const { isOnline, isOffline } = useNetworkStatus({
    onOnline: handleOnline,
    onOffline: handleOffline,
  })
  const [isStatusVisible, setIsStatusVisible] = useState(isOffline)
  const timerIdRef = useRef<number | null>(null)
  const previousIsStatusVisible = usePrevious(isStatusVisible)
  const { customerId } = userStore
  const clearTimer = useCallback(() => {
    const timerId = timerIdRef.current

    if (timerId) {
      window.clearTimeout(timerId)
    }
  }, [])
  function handleOnline() {
    clearTimer()

    setIsStatusVisible(true)

    timerIdRef.current = window.setTimeout(() => {
      setIsStatusVisible(false)
    }, DEFAULT_TIMEOUT)
  }
  function handleOffline() {
    clearTimer()
    setIsStatusVisible(true)
  }

  useEffect(
    () => () => {
      clearTimer()
    },
    [clearTimer],
  )

  return { isStatusVisible, previousIsStatusVisible, isOnline, isAuth: !!customerId }
}
