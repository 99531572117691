import { useTranslation } from 'react-i18next'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import type { TravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import type { TravelTime } from '@fiji/graphql/types'
import { HOUR_IN_MINUTES, QUICK_CHANGE_IN_MINUTES } from '@fiji/constants'

const i18nBase = 'RailDetails'

type Props = {
  arrival: TravelRailLocation
  changeDuration: TravelTime | null | undefined
}

export function useRailChangeDuration({ arrival, changeDuration }: Props) {
  const { t } = useTranslation()
  const getDuration = useRailDuration()

  const changeDurationText = getDuration(changeDuration)

  const changeDurationAriaLabel = t(`${i18nBase}.AriaLabels.ChangeDurationLabel`, {
    time: changeDurationText,
    name: arrival.stationName,
  })

  const isQuickChange = () => {
    if (!changeDuration) {
      return false
    }

    const { hours = 0, minutes = 0 } = changeDuration
    return hours * HOUR_IN_MINUTES + minutes <= QUICK_CHANGE_IN_MINUTES
  }

  const changeStationText = t(i18nBase + (isQuickChange() ? '.QuickChangeAt' : '.NormalChangeAt'), {
    station: arrival.stationName,
  })

  return {
    changeDurationAriaLabel,
    changeDurationText,
    changeStationText,
  }
}
