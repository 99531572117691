import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { SegmentCardMobile } from './mobile'
import { NewSegmentCardMobile } from './new-mobile'
import { SegmentCardDesktop } from './desktop'
import { NewSegmentCardDesktop } from './new-desktop'

const SEGMENT_TYPES_FOR_NEW_LAYOUT = ['flight', 'car-rental', 'hotel', 'train']

export function SegmentCardLayout(props: LayoutProps) {
  const isSegmentWithNewLayout = SEGMENT_TYPES_FOR_NEW_LAYOUT.includes(props.segmentType)
  const mobileSlot = isSegmentWithNewLayout ? (
    <NewSegmentCardMobile {...props} />
  ) : (
    <SegmentCardMobile {...props} />
  )

  const desktopSlot = isSegmentWithNewLayout ? (
    <NewSegmentCardDesktop {...props} />
  ) : (
    <SegmentCardDesktop {...props} />
  )

  return <MediaLayout mobileSlot={mobileSlot} desktopSlot={desktopSlot} />
}
