import { useTranslation } from 'react-i18next'
import { StarRating } from '@etta/ui/star-rating'
import { Text } from '@etta/ui/text'
import {
  HeaderTitle,
  OverallRating,
  RatingContainer,
  RatingValue,
  Container,
} from './hotel-reviews-header-styled'

type Props = {
  totalReviews?: number
  totalGuestsReviews?: number
  rating?: number
}

const i18nPath = 'HotelInfo.Reviews.'

export function HotelReviewsHeader({ totalReviews, rating, totalGuestsReviews }: Props) {
  const { t } = useTranslation()

  const ratingValue = rating ? Number(rating).toFixed(1) : 0

  const headerTitle = totalReviews
    ? t(`${i18nPath}ReviewsWithTotal`, { count: totalReviews })
    : t(`${i18nPath}Reviews`)

  return (
    <Container>
      <HeaderTitle aria-label={headerTitle} variant="title3" color="mainText">
        {headerTitle}
      </HeaderTitle>
      {totalReviews && (
        <OverallRating
          aria-label={t(i18nPath + 'TripAdvisorOverallRating')}
          variant="footnoteMedium"
          color="mainText">
          {t(i18nPath + 'TripAdvisorOverallRating')}
        </OverallRating>
      )}
      <RatingContainer>
        {rating && <StarRating rating={rating} />}
        {rating && (
          <RatingValue variant="footnoteMedium" color="bodyText">
            {ratingValue}
          </RatingValue>
        )}
        {totalGuestsReviews && (
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nPath + 'BasedOnGuestReviewsCount', {
              count: totalGuestsReviews,
            })}
          </Text>
        )}
      </RatingContainer>
    </Container>
  )
}
