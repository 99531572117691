import type { FlightCardStatus } from './types'

export function getFlightCardStatus(
  isSeatMapAvailable: boolean,
  isReadonly: boolean,
  seatSelected: boolean,
): FlightCardStatus {
  switch (true) {
    case !isSeatMapAvailable:
      return 'notAvailable'

    case isReadonly:
      return 'view'

    case seatSelected:
      return 'seatSelected'

    default:
      return 'idle'
  }
}
