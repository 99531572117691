import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { convertCarRentalToRangeRTP } from './convert-car-rental-to-rage/convert-car-rental-to-range-rtp'
import { convertFlightToRangeRTP } from './convert-flight-to-range'
import { convertTrainToRangeRTP } from './convert-train-to-range'
import {
  convertHotelForCarRTP,
  convertHotelForFlightRTP,
  convertHotelForTrainRTP,
} from './convert-hotel-to-range'
import type { RangeResult } from './types'

function getSegmentsRTP(segments: TripSegmentValueObject[]) {
  const typeCheck = SegmentTypeCheckActions.getInstance()
  const carRentalSegments = segments.filter(typeCheck.isCarRentalSegment)
  const flightSegments = segments.filter(typeCheck.isFlightSegment)
  const hotelSegments = segments.filter(typeCheck.isHotelSegment)
  const trainSegments = segments.filter(typeCheck.isTrainSegment)

  return {
    carRentalSegments,
    flightSegments,
    hotelSegments,
    trainSegments,
  }
}

export function getFlightRangeRTP(segments: TripSegmentValueObject[]): RangeResult | undefined {
  const { hotelSegments, carRentalSegments } = getSegmentsRTP(segments)

  if (hotelSegments.length) {
    return convertHotelForFlightRTP(hotelSegments[0])
  }

  if (carRentalSegments.length) {
    return convertCarRentalToRangeRTP(carRentalSegments[0])
  }
}

export function getCarRangeRTP(segments: TripSegmentValueObject[]): RangeResult | undefined {
  const { hotelSegments, carRentalSegments, flightSegments, trainSegments } = getSegmentsRTP(
    segments,
  )

  if (carRentalSegments.length || flightSegments.length > 2) {
    return
  }

  if (flightSegments.length) {
    return convertFlightToRangeRTP(flightSegments)
  }

  if (hotelSegments.length) {
    return convertHotelForCarRTP(hotelSegments[0])
  }

  if (trainSegments.length) {
    return convertTrainToRangeRTP(trainSegments)
  }
}

export function getHotelRangeRTP(segments: TripSegmentValueObject[]): RangeResult | undefined {
  const { hotelSegments, carRentalSegments, flightSegments, trainSegments } = getSegmentsRTP(
    segments,
  )

  if (hotelSegments.length || flightSegments.length > 2) {
    return
  }
  if (flightSegments.length) {
    return convertFlightToRangeRTP(flightSegments)
  }
  if (carRentalSegments.length) {
    return convertCarRentalToRangeRTP(carRentalSegments[0])
  }
  if (trainSegments.length) {
    return convertTrainToRangeRTP(trainSegments)
  }
}

export function getTrainRangeRTP(segments: TripSegmentValueObject[]): RangeResult | undefined {
  const { hotelSegments, carRentalSegments } = getSegmentsRTP(segments)
  if (hotelSegments.length) {
    return convertHotelForTrainRTP(hotelSegments[0])
  }
  if (carRentalSegments.length) {
    return convertCarRentalToRangeRTP(carRentalSegments[0])
  }
}
