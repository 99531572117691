// TODO: Temporary, this function returns only first notification item,
// and it's the first one that contains phone number. Fix this fn when required.
import type { Notification } from '@fiji/graphql/types'

type PhoneEssentials = {
  id: number
  phone?: string
  country?: string | null
}

export function findPhoneNotification(
  notification?: Notification[] | null,
): PhoneEssentials | null {
  if (!notification) {
    return null
  }
  const filtered = notification.find((item) => item.phone)
  return filtered
    ? {
        phone: filtered.phone?.number,
        country: filtered.phone?.isoCountryCode,
        id: filtered.id,
      }
    : null
}
