import { Inject, Service } from '@etta/di'
import { TripType } from '@etta/core/enums/trip-type.enum'
// import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { OrdersAdapter } from '../../infra/orders/orders.adapter'
// import { UserTripsAdapter } from '../../infra/user-trips/user-trips.adapter'
import { TripsListStore } from '../stores/orders.store'
import { MergeGroupsService } from './get-groups.service'

const TRIP_LIST_RELOAD_DELAY = 5000

@Service()
export class GetTripsListService {
  constructor(
    @Inject()
    private readonly ordersAdapter: OrdersAdapter,

    // @Inject()
    // private readonly userTripsAdapter: UserTripsAdapter,

    @Inject()
    private readonly tripsListStore: TripsListStore,

    @Inject()
    private readonly mergeGroupsService: MergeGroupsService, // @Inject() // private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  async loadTrips(type: TripType, forceUpdate?: boolean) {
    // Disabled for now
    // if (this.displayConfigurationStore.isMod2FlowEnabled) {
    //   const result = await this.userTripsAdapter.getUserTrips({
    //     pageSize: 10,
    //     startIndex: 0,
    //     tripType: type,
    //     forceUpdate,
    //   })
    //   return result
    // }
    const result = await this.ordersAdapter.getOrders({ offset: 0, tripType: type, forceUpdate })
    return result
  }

  async load(type: TripType) {
    if (this.tripsListStore.isLoading[type] || this.tripsListStore.isError[type]) {
      return
    }

    this.tripsListStore.setIsLoading(true, type)
    this.tripsListStore.setIsError(false, type)

    const result = await this.loadTrips(type)

    result.match({
      Err: () => {
        this.tripsListStore.setIsLoading(false, type)
        this.tripsListStore.setIsError(true, type)
      },
      Ok: (data) => {
        this.tripsListStore.setIsLoading(false, type)
        this.tripsListStore.setTripsList(data, type)
      },
    })
  }

  async fetchMoreTrips(type: TripType, offset: number) {
    // Disabled for now
    // if (this.displayConfigurationStore.isMod2FlowEnabled) {
    //   const startIndex = this.tripsListStore.orders[type]?.startIndex
    //   const result = await this.userTripsAdapter.getUserTrips({
    //     pageSize: 10,
    //     startIndex: startIndex || 0,
    //     tripType: type,
    //   })
    //   return result
    // }
    const result = await this.ordersAdapter.getOrders({ offset, tripType: type, forceUpdate: true })
    return result
  }

  async fetchMore(type: TripType) {
    const ordersType = this.tripsListStore.orders[type]
    if (this.tripsListStore.isFetchMoreLoading[type] || !ordersType) {
      return
    }
    const offset = ordersType.startIndex + ordersType.pageSize
    if (!offset) {
      return
    }

    this.tripsListStore.setIsFetchMoreLoading(true, type)

    const result = await this.fetchMoreTrips(type, offset)

    result.match({
      Err: () => {
        this.tripsListStore.setIsFetchMoreLoading(false, type)
      },
      Ok: (data) => {
        this.tripsListStore.setIsFetchMoreLoading(false, type)
        const existingGroups = this.tripsListStore.orders[type]?.groups
        const mergedGroups = this.mergeGroupsService.merge({
          existing: existingGroups,
          incoming: data.groups,
          tripType: type,
        })
        this.tripsListStore.setTripsList(
          {
            groups: mergedGroups,
            hasNextPage: data.hasNextPage,
            pageSize: data.pageSize,
            startIndex: data.startIndex,
            totalResults: data.totalResults,
          },
          type,
        )
      },
    })
  }

  async reload(type: TripType) {
    if (this.tripsListStore.isLoading[type]) {
      return
    }

    this.tripsListStore.setTripsList(null, type)
    this.tripsListStore.setIsLoading(true, type)
    this.tripsListStore.setIsError(false, type)

    const result = await this.loadTrips(type, true)

    result.match({
      Err: () => {
        this.tripsListStore.setIsLoading(false, type)
        this.tripsListStore.setIsError(true, type)
      },
      Ok: (data) => {
        this.tripsListStore.setIsLoading(false, type)
        this.tripsListStore.setTripsList(data, type)
      },
    })
  }

  async update(type: TripType) {
    if (this.tripsListStore.isLoading[type]) {
      return
    }

    const result = await this.loadTrips(type, true)

    result.match({
      Err: () => {},
      Ok: (data) => {
        this.tripsListStore.setTripsList(data, type)
      },
    })
  }

  dropAndReloadAllOrders() {
    this.tripsListStore.dropAllTrips()
    const listsToReload = [TripType.Upcoming, TripType.Past, TripType.Cancelled, TripType.OnHold]
    Promise.all(
      listsToReload.map(async (type) => {
        this.tripsListStore.setTripsList(null, type)
        this.tripsListStore.setIsLoading(true, type)
        this.tripsListStore.setIsError(false, type)

        await delayForSuccess(TRIP_LIST_RELOAD_DELAY)
        const result = await this.loadTrips(type, true)

        result.match({
          Err: () => {
            this.tripsListStore.setIsLoading(false, type)
            this.tripsListStore.setIsError(true, type)
          },
          Ok: (data) => {
            this.tripsListStore.setIsLoading(false, type)
            this.tripsListStore.setTripsList(data, type)
          },
        })
      }),
    )
  }
}
