import { Store, Inject } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { UserStore } from '@etta/modules/user'

@Store()
export class DelegateStore {
  constructor(
    @Inject()
    private readonly userStore: UserStore,
    @Inject()
    private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  delegateModalToggle = new Toggle()

  exitModalToggle = new Toggle()

  private _isUserInfoUpdating: boolean = false

  private _delegateId: number | null = null

  setDelegateId(value: number) {
    this._delegateId = value
  }

  setIsUserInfoUpdating(val: boolean) {
    this._isUserInfoUpdating = val
  }

  get isShowDelegateName() {
    return this.featureFlagsStore.flags.isDelegatedFlowEnabled && !!this.userStore.delegatedId
  }

  get isDelegateGuestUser() {
    return this.isShowDelegateName && this.userStore.isGuest
  }

  get onExitDelegateMode() {
    return this._delegateId !== null && this._delegateId === 0
  }

  get delegateId() {
    return this._delegateId
  }

  get isUserInfoUpdating() {
    return this._isUserInfoUpdating
  }

  dropDelegateId() {
    this._delegateId = null
  }

  dropDelegateState() {
    this.delegateModalToggle.handleClose()
    this.exitModalToggle.handleClose()
  }
}
