import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { TitleMobile, TitleDesktop } from './layout'

export function Title(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TitleMobile {...props} />}
      desktopSlot={<TitleDesktop {...props} />}
    />
  )
}
