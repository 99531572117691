import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import type { Hotel } from '@fiji/graphql/types'

type Props = {
  hotels: Hotel[]
  longitude: number
  latitude: number
  hotelsCount: number
  hotelsCountPerPage: number
  fetchMore: (offset: number) => void
  isLoading: boolean
}

export const useHotelResultsMap = ({
  hotels,
  longitude,
  latitude,
  hotelsCount,
  hotelsCountPerPage,
  fetchMore,
  isLoading,
}: Props) => {
  const [localOffset, setLocalOffset] = useState(0)

  useEffect(() => {
    if (isLoading) {
      setLocalOffset(0)
    }
  }, [isLoading])

  const centerPoint = useMemo(() => {
    const defaultCenter = {
      lat: latitude,
      lng: longitude,
    }
    const hotelsCenters = hotels.map((hotel) => ({
      lng: hotel.address.geocode?.long,
      lat: hotel.address.geocode?.lat,
    }))
    const nextHotel = hotelsCenters[localOffset]
    if (!nextHotel?.lng) {
      return defaultCenter
    }
    if (!nextHotel?.lat) {
      return defaultCenter
    }

    return {
      lat: nextHotel.lat,
      lng: nextHotel.lng,
    }
  }, [hotels, latitude, localOffset, longitude])

  const [selected, setSelected] = useState<string>('')

  const handleSelected = useDebouncedCallback((selectedId: string) => {
    setSelected(selectedId)
  }, 200)

  const paginatedHotels = useMemo(
    () => hotels.slice(localOffset, localOffset + hotelsCountPerPage),
    [localOffset, hotels, hotelsCountPerPage],
  )

  const onSlideChange = useDebouncedCallback((slideIdx: number) => {
    const hotel = paginatedHotels[slideIdx]
    if (hotel) {
      handleSelected(hotel.id)
    }
  }, 500)

  const goToPrevious = useCallback(() => {
    setLocalOffset((prevOffset) =>
      prevOffset < hotelsCountPerPage ? 0 : prevOffset - hotelsCountPerPage,
    )
  }, [hotelsCountPerPage])

  const goToNext = useCallback(() => {
    if (!fetchMore) {
      return
    }

    const nextOffset =
      localOffset + hotelsCountPerPage > hotelsCount
        ? hotelsCount
        : localOffset + hotelsCountPerPage

    if (localOffset !== nextOffset) {
      setLocalOffset(nextOffset)
    }

    if (nextOffset > hotels.length - hotelsCountPerPage) {
      fetchMore(nextOffset)
    }
  }, [hotelsCount, hotelsCountPerPage, localOffset, fetchMore, hotels])

  const updateHotelsRound = useCallback(({ hotelsLength, hotelsCountPerPage }) => {
    const value = hotelsLength - hotelsCountPerPage

    const newLocalOffsetValue = Math.round(Math.ceil(value / 10)) * 10

    setLocalOffset(newLocalOffsetValue > 0 ? newLocalOffsetValue : 0)
  }, [])

  useEffect(() => {
    if (!hotels.length) {
      return
    }
    updateHotelsRound({ hotelsLength: hotels.length, hotelsCountPerPage })
  }, [hotels.length, hotelsCountPerPage, updateHotelsRound])

  useEffect(() => {
    if (paginatedHotels.length) {
      const [firstInListHotel] = paginatedHotels
      setSelected(firstInListHotel.id)
    }
  }, [paginatedHotels, localOffset])

  const getSelected = useCallback(() => {
    const index = paginatedHotels.findIndex(({ id }) => selected === id)
    if (index < 0) {
      return 0
    }

    return index
  }, [selected, paginatedHotels])

  const slideIndex = useMemo(() => (paginatedHotels.length > 1 && selected ? getSelected() : 0), [
    paginatedHotels,
    selected,
    getSelected,
  ])

  return {
    centerPoint,
    slideIndex,
    onSlideChange,
    handleSelected,
    selected,
    goToPrevious,
    goToNext,
    paginatedHotels,
  }
}
