import { getFirebaseMessagingLogger } from './loggers'
import type { Notification, Message, CustomFieldParsed, NotificationData } from './types'

const ICON_URL = '/app/icons/icon512.png'

function getNotificationData(customFields: CustomFieldParsed): NotificationData | undefined {
  const processId = customFields.metadata?.processId
  if (!processId || !customFields) {
    return undefined
  }

  switch (customFields.eventType) {
    case 'flightAlert':
      const fltkey = customFields.metadata?.fltkey
      const [airlineCode, airlineNumber, date, departureAirport] = (fltkey || '').split(':')

      const searchParams = new URLSearchParams({
        airlineCode,
        airlineNumber,
        date,
        departureAirport,
      })

      return {
        type: 'redirect',
        meta: { url: `trips/${processId}?${searchParams.toString()}` },
      }
    default:
      return {
        type: 'redirect',
        meta: { url: `trips/${processId}` },
      }
  }
}

export function mapNotificationFromFirebaseMessage(message: Message): Notification {
  const logger = getFirebaseMessagingLogger()
  let customFields: CustomFieldParsed = {}
  try {
    customFields = message.data?.customFields ? JSON.parse(message.data.customFields) : {}
  } catch (error: unknown) {
    logger.error('Unable to parse message customField', error)
  }

  const data = getNotificationData(customFields)

  return {
    title: customFields?.title || '',
    body: message.data?.message || '',
    icon: ICON_URL,
    id: customFields?.id || '',
    data,
    actions: [],
  }
}
