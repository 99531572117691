import { useMemo } from 'react'
import { useAppSelector } from '@fiji/store'
import { flightDisplayConfigurationSelector } from '@fiji/store/display-configuration/selectors'
import type { TimeRange } from '@fiji/types'
import { airTimeRangeOptions } from '@fiji/hooks/time-configuration/use-flight/constants'
import { TimeRangeOption } from '@fiji/enums'

export function useTimeOptions() {
  const { isAnytimeEnabled, isMorningAfternoonEveningEnabled } = useAppSelector(
    flightDisplayConfigurationSelector,
  )

  return useMemo(() => {
    const timeOptionsConfig = {
      [TimeRangeOption.AnyTime]: isAnytimeEnabled,
      [TimeRangeOption.Morning]: isMorningAfternoonEveningEnabled,
      [TimeRangeOption.Afternoon]: isMorningAfternoonEveningEnabled,
      [TimeRangeOption.Evening]: isMorningAfternoonEveningEnabled,
      [TimeRangeOption.CustomTime]: true,
    }

    const timeOptionsKeys = Object.keys(airTimeRangeOptions) as Array<TimeRangeOption>

    const timeOptions = timeOptionsKeys.reduce((acc: Array<TimeRange>, key: TimeRangeOption) => {
      const el = airTimeRangeOptions[key]
      if (!timeOptionsConfig[key]) {
        return acc
      }
      return [...acc, el]
    }, [])
    return timeOptions
  }, [isAnytimeEnabled, isMorningAfternoonEveningEnabled])
}
