import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { TravelerAcknowledgeConfirmationDesktop } from './layout/desktop/traveler-acknowlege-confirmation-modal-desktop'
import { TravelerAcknowledgeConfirmationMobile } from './layout/mobile/traveler-acknowlege-confirmation-modal-mobile'

export const TravelerAcknowledgeConfirmationModal = observer(
  function TravelerAcknowledgeConfirmationModal(props: Props) {
    return (
      <MediaLayout
        mobileSlot={<TravelerAcknowledgeConfirmationMobile {...props} />}
        desktopSlot={<TravelerAcknowledgeConfirmationDesktop {...props} />}
      />
    )
  },
)
