import { useMemo } from 'react'
import { SegmentType } from '@fiji/graphql/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import type {
  AvailableSegmentsValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'

type Args = {
  availableSegments?: AvailableSegmentsValueObject | null
  segments: TripSegmentValueObject[]
}

export function useButtonVisibleStates({ availableSegments, segments }: Args) {
  const {
    featureFlags: { isAddTrainToItineraryEnabled, isRailServiceEnabled },
  } = useFeatureFlags()

  const visibleStates = useMemo(() => {
    const {
      canAddFlight: isFlightVisible,
      canAddHotel: isHotelVisible,
      canAddCarRental: isHotelCarRentalVisible,
    } = availableSegments || {}

    const isTrainVisible = !!isAddTrainToItineraryEnabled

    const hasSegments = segments.reduce(
      (acc, segment) => {
        switch (segment.type) {
          case SegmentType.Flight:
          case SegmentType.Hotel:
          case SegmentType.CarRental:
          case SegmentType.Train:
            acc[segment.type] = true
        }
        return acc
      },
      {
        [SegmentType.Flight]: false,
        [SegmentType.Hotel]: false,
        [SegmentType.CarRental]: false,
        [SegmentType.Train]: false,
      },
    )

    const isFlight = isFlightVisible && !hasSegments[SegmentType.Train]
    const isHotel = isHotelVisible
    const isCarRental = isHotelCarRentalVisible
    const isTrain = isTrainVisible && !hasSegments[SegmentType.Flight] && isRailServiceEnabled
    return {
      isFlight,
      isHotel,
      isCarRental,
      isTrain,
    }
  }, [segments, availableSegments, isRailServiceEnabled, isAddTrainToItineraryEnabled])

  return visibleStates
}
