import styled from 'styled-components'

export const TimeStampSet = styled.div<{
  top: number
  height: number
  range: number
  swiping: number
  flipping: number
}>`
  position: absolute;
  top: ${(p) => p.top}rem;
  height: ${(p) => p.height * p.range}rem;
  width: 100%;
  user-select: none;
  transition: top ${(p) => (p.flipping ? '0s' : '0.4s')}, margin-top 0.6s;
  margin-top: ${(p) => p.swiping || 0}rem;
`
