import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Header } from '@etta/components/trip-train-modal/components/ticket-delivery/ticket-delivery-styled'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'

type Props = {
  email?: string | null
}

const i18nBase = 'RailDeliveryOption'

export function Eticket({ email }: Props) {
  const { t } = useTranslation()

  if (!email) {
    return null
  }

  return (
    <>
      <Header>
        <Icon name="eticketPWA" size="medium" />
        <Text variant="subHeadStrong" color="mainText1">
          {t(i18nBase + '.Eticket')}
        </Text>
      </Header>
      <Block marginTop={12}>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + '.DeliveryEticketPostBooking')}
          <Link size="small" href={`mailto:${email}`}>
            {email}
          </Link>
        </Text>
      </Block>
    </>
  )
}
