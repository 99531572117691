import styled from 'styled-components'
import { footnoteStrong } from '@fiji/style'

const SCALE_HEIGHT = '3px'

export const Container = styled.div`
  display: flex;
`

export const Score = styled.div`
  ${footnoteStrong};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  line-height: 1;
  color: ${(p) => p.theme.colors.mainText2};
  background-color: ${(p) => p.theme.colors.bodyText};
  flex-shrink: 0;
`

export const ConcernContainer = styled.div`
  width: 100%;
  margin: 4px 0 0 16px;
`

export const ConcernTitle = styled.div`
  ${footnoteStrong};
  color: ${(p) => p.theme.colors.mainText};
  margin-bottom: 10px;
`

export const ConcernScoreScaleContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: ${SCALE_HEIGHT};

  > * {
    margin: auto;
  }
`

export const ConcernScoreScaleBackground = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.borderLight};
`

export const ConcernScoreScaleFillContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

type ConcernScoreScaleFillProps = {
  value: number
}

const getScaleColorByValue = (value: number) => {
  if (value > 80) {
    return '#337415'
  }
  if (value > 60) {
    return '#3DA00F'
  }
  if (value > 30) {
    return '#FF9500'
  }

  return '#CA0F1C'
}

export const ConcernScoreScaleFill = styled.div<ConcernScoreScaleFillProps>`
  height: 100%;
  width: ${(p) => p.value}%;
  background-color: ${(p) => getScaleColorByValue(p.value)};
  border-radius: ${SCALE_HEIGHT};
`
