import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import { Button } from '@etta/ui/button'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { useResetDelegateActiveBookingFlow } from '@fiji/hooks/delegates/use-reset-delegate-active-booking-flow'
import { BookAgainTravelVerticalEnum } from '@etta/modules/post-booking/core/enums'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Icon } from '@etta/ui/icon'
import { formatAddress } from '@fiji/utils/address'

import type { LayoutProps } from '../types'
import { BookTripAgainModalSegment } from '../components/book-trip-again-modal-segment'
import { BookTripAgainModalDataPicker } from '../components'
import { BookTripAgainDelegatePicker } from '../components/book-trip-again-delegate-picker'
import { ModalBody, BodyContainer, ButtonContainer } from './book-trip-again-modal-desktop-styled'

export const BookTripAgainModalDesktop = observer(function BookTripAgainModalDesktop({
  isOpen,
  onClose,
  tripName,
  flightSegment,
  hotelSegment,
  carRentalSegment,
  regularUser,
  currentUserId,
}: LayoutProps) {
  const { bookTripAgainStore, bookTripAgainActions } = usePostBookingContext()
  const {
    delegateActions: { handleSelectDelegate },
  } = useDelegateContext()

  const { onReset } = useResetDelegateActiveBookingFlow()
  const handleContinue = async () => {
    await bookTripAgainActions.handleContinue(handleSelectDelegate)
    onReset()
  }

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="right"
      disableOverflow={false}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={
            <Block paddingLeft={13} paddingTop={3}>
              <Text variant="title3" noWrap={true}>
                Book Again
              </Text>
            </Block>
          }
          rightSlot={
            <Block paddingRight={13}>
              <IconButton icon="closeFilledPWA" color="bodyText" size="medium" onClick={onClose} />
            </Block>
          }></Header>
      </Modal.Header>
      <ModalBody>
        <BodyContainer>
          <Block paddingHorizontal={32}>
            <Block paddingTop={25}>
              <Text variant="title3">{tripName}</Text>
            </Block>
            <Block paddingTop={22}>
              {flightSegment && (
                <BookTripAgainModalSegment
                  title={
                    <Text variant="footnoteStrong" color="mainText">
                      {flightSegment.originAirportCode}{' '}
                      <Icon name={'arrowForwardPWA'} size={'small'} />{' '}
                      {flightSegment.destinationAirportCode} (
                      <Text variant="footnoteStrong" textTransform="capitalize">
                        {flightSegment.serviceClass.toLowerCase()}
                      </Text>
                      )
                    </Text>
                  }
                  description={flightSegment.flightNumbers.join(', ')}
                  segmentType={BookAgainTravelVerticalEnum.Air}
                  isChecked={bookTripAgainStore.selectedSegments.has(
                    BookAgainTravelVerticalEnum.Air,
                  )}
                  onChange={(value, segmentType) =>
                    bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                  }
                />
              )}
              {hotelSegment && (
                <Block paddingTop={8}>
                  <BookTripAgainModalSegment
                    title={
                      <Text variant="footnoteStrong" color="mainText">
                        {hotelSegment.name}
                      </Text>
                    }
                    description={`${hotelSegment.roomType}, ${
                      hotelSegment.numberOfNights
                    } nights, ${formatAddress(hotelSegment.address)}`}
                    segmentType={BookAgainTravelVerticalEnum.Hotel}
                    isChecked={bookTripAgainStore.selectedSegments.has(
                      BookAgainTravelVerticalEnum.Hotel,
                    )}
                    onChange={(value, segmentType) =>
                      bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                    }
                  />
                </Block>
              )}
              {carRentalSegment && (
                <Block paddingTop={8}>
                  <BookTripAgainModalSegment
                    title={
                      <>
                        <Text variant="footnoteStrong" color="mainText">
                          {carRentalSegment.vendor}
                        </Text>{' '}
                        •{' '}
                        <Text variant="footnoteStrong" textTransform="capitalize">
                          {carRentalSegment.carClass.toLowerCase()}
                        </Text>
                      </>
                    }
                    description={`${formatAddress(carRentalSegment.pickUpAddress)}`}
                    segmentType={BookAgainTravelVerticalEnum.Carrental}
                    isChecked={bookTripAgainStore.selectedSegments.has(
                      BookAgainTravelVerticalEnum.Carrental,
                    )}
                    onChange={(value, segmentType) =>
                      bookTripAgainStore.onSelectedSegmentsChange(value, segmentType)
                    }
                  />
                </Block>
              )}
            </Block>
            <Block paddingTop={24}>
              <BookTripAgainDelegatePicker
                regularUser={regularUser}
                currentUserId={currentUserId || 0}
                onUserSelect={(id) => bookTripAgainStore.setDelegatedId(id)}
              />
            </Block>
            <Block paddingTop={24}>
              <BookTripAgainModalDataPicker
                startDate={bookTripAgainStore.startDate}
                endDate={bookTripAgainStore.endDate}
                onDateChange={(result: Dates) => bookTripAgainStore.onDatesChange(result)}
                startDateErrorMessage={bookTripAgainStore.startDateErrorMessage}
                endDateErrorMessage={bookTripAgainStore.endDateErrorMessage}
              />
            </Block>
          </Block>
        </BodyContainer>
      </ModalBody>
      <Modal.Footer>
        <ButtonContainer>
          <Button onClick={onClose} variant="link">
            <Text variant="buttonNormal" color="primary">
              Cancel
            </Text>
          </Button>
          <Button
            onClick={handleContinue}
            minWidth={21}
            disabled={!bookTripAgainStore.selectedSegments.size}>
            <Text variant="buttonNormal" color="mainText2">
              Continue
            </Text>
          </Button>
        </ButtonContainer>
      </Modal.Footer>
      <ViewStateModal
        viewState={bookTripAgainStore.viewState}
        onDone={bookTripAgainActions.onCloseViewState}
      />
    </Modal>
  )
})
