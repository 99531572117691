export type MainNavigation = {
  trips: string
  support: string
  profile: string
  explore: string
  isOngoingBookingFlowActive: boolean
  isTripsForceUpdateEnabled: boolean
}

export enum MAINNAVIGATIONVALUE {
  SUPPORT = '/support',
  TRIPS = '/trips',
  PROFILE = '/profile',
  EXPLORE = '/explore',
}
