import type { CalculateTripEmissionsResultHotel } from '@fiji/graphql/types'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { HotelEmission } from './types'

export function getHotelsEmissionsSummary(
  segments: HotelPostBookingValueObject[],
  rawHotelEmissions?: CalculateTripEmissionsResultHotel[] | null,
): {
  hotelEmissions: HotelEmission[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const hotelEmissions: HotelEmission[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    const hotelEmission = rawHotelEmissions?.find((hotel) => hotel.customRef === segment.hotelId)

    tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, hotelEmission?.tonnesOfEmissions)
    averageTonnesOfEmissionsSummary = sum(
      averageTonnesOfEmissionsSummary,
      hotelEmission?.averageLocationEmissionsTonnes,
    )

    const {
      plasticBagEquivalenceAmount,
      treeSeedlingEquivalenceAmount,
      smartphoneChargeEquivalenceAmount,
    } = getEquivalencesCount(hotelEmission?.equivalences)

    treeSeedlingsEquivalenceTotalCount = sum(
      treeSeedlingsEquivalenceTotalCount,
      treeSeedlingEquivalenceAmount,
    )

    plasticBagsEquivalenceTotalCount = sum(
      plasticBagsEquivalenceTotalCount,
      plasticBagEquivalenceAmount,
    )

    smartphoneChargeEquivalenceTotalCount = sum(
      smartphoneChargeEquivalenceTotalCount,
      smartphoneChargeEquivalenceAmount,
    )

    const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
      averageEmissionsTonnes: hotelEmission?.averageLocationSustainabilityScore || 0,
      tonnesOfEmissions: hotelEmission?.sustainabilityScore || 0,
    })

    hotelEmissions.push({
      name: segment.name,
      deviationLevel,
      percentDeviation,
      emissionsKg: tonnesToKg(hotelEmission?.tonnesOfEmissions || 0),
      sustainabilityScore: hotelEmission?.sustainabilityScore || 0,
    })
  })

  return {
    hotelEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
