import * as yup from 'yup'
import type { Props } from '../types'
import { getBaseScheme } from './get-base-scheme'

export const getEnumerationScheme = ({ customField }: Props) => {
  return getBaseScheme({
    scheme: yup.string(),
    customField,
  })
}
