import type { UserPreset } from '../types'

export const rcqa9: UserPreset[] = [
  {
    site: 'fijiweb',
    user: 'fijiweb',
    rcSession:
      '13139-B1C02997E03E457E2A74CDDEE4DBD6D5-00FCB9B5F98B33E6AE29CEE5EE9CFCA0B7C12A2FFA1BE2DD9FE97699BD074B00FC95ACA28D8A23B5AC84E279F667098E34F6947ABD9A308C9227D245355B37B5A32ED22018C66FD86EE4FB85CBE2F9FB32995094AFB53AA8311E9461C7321E04D4466988AA72F8AE46481602402E9D2EA0270606969C703BA27F4B065075543ADC2D93BD865B7DC33A45CE2DE58EB7D8',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluVXNlciI6ZmFsc2UsImN1c3RvbWVySWQiOjI3NzgzMDEsImxvY2FsZSI6ImVuLVVTIiwiaW1wZXJzb25hdGVkSWQiOjAsImRlbGVnYXRlZElkIjowLCJjb21wYW55SWQiOjE2MTAxMCwicGFydG5lcklkIjoxLCJ1c2VybmFtZSI6ImZpaml3ZWJAZGVlbS5jb20iLCJjcmVhdGVkVGltZSI6MTY0MzcyMjA5MTc2MSwiY2x1c3RlciI6ImNsdXN0ZXJhIiwic2Vzc2lvbklkIjoiMzNmMzRjZTUtY2M0NC00ZTY4LTg3YmUtOWQ5ZGUzZDllNDNjIiwic2Vzc2lvblRpbWVvdXQiOjE4MDAsImdkc0lkIjoxMDEwMSwicmNTZXNzaW9uIjoiMTYyODQtMUEyOTZERkQxMjc3MTI5QUQ3NEQ1QTJEMzI2QzI2NjYtRTdDMzg0MUYxRkMwOTVGNkMyOTA0NTBFRkRCQjM3RkFCNERFMUEyNEQ3NEE1NDEyNjc3ODI5NkI3NUQ2QkRCRTAwMEJDNTFCRDI5M0JCQjZEMzlDNDE2Qjk1RTc2RUEwQTYwNDlENjBERDA1RTRDRTE5MDAyM0I1RDk4OEU1RUJEOTU5Mzc3RkIyQ0M0RkE0QkMwQ0IzNkE4NzJDNThFRkY5RjFCM0U2NzM5RjlCNDdFMERCRTc4RjdDM0M5NUY5MTlGMEREOTU2NjEwOTZFM0MzMzlGMTIyMTgyQTVDNzNEQUNCQTE2OUJEQTc5MTdCQzVFNTY3REM0RTlBOEFGMTUyNDEwQTA3RDM3MEY5QkNBRjZCRUZEMDM4MjM3OTFBNkNBQjlFOUUwRDE2QzQyNEZBMDc2OEE0NDk4NDVFNjciLCJpYXQiOjE2NDM3MjIwOTEsImV4cCI6MTk1OTI5ODA5MX0.YUl1sotHQi8LdFyIJrfdkLnusAnM_JIcPaUtsJgQ5k8',
  },
  {
    site: 'fijiwebapple',
    user: 'fijiweb',
    rcSession:
      '13139-68BD3BEA184760F837FB1EFDE624909B-F3A80FDCBF4F7CE7989DBB4252C59252B0333808946633964891D1FF3FED720CC58ACFE07CC4C4D18985684BF43CF47E73A5577429D17DD192E5888D5C22C6C99B1496D18C985DCC2F8321512A85145F1046517333F2BE9353ACB5C8833B8C468667263805CDFED93125EED03FAE8609686B024325B4B6C3D719505EAB2DF0F6767B9EEC35D4509882B1B5F240A39A0B',
  },
]
