import { Icon } from '@etta/ui/icon'
import type { PWAIconNames } from '@etta/ui/icon'
import { SegmentType } from '@fiji/graphql/types'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import { useGetChangeText, useGetRemoveText } from './hooks'
import { Container, List, ListItemContainer, ListItem, ListItemIcon } from './action-buttons-styled'
import { CustomActions } from './custom-actions'

type Props = {
  type: SegmentType
  isPostBooking?: boolean
  onShareClick?: () => void
  onDetailsClick?: () => void
  onChangeClick: () => void
  onCancelClick: () => void
  isChange?: boolean | null
  isCancel?: boolean | null
  isChangeAllowed?: boolean | null
  isTrainTicketRefundable?: boolean
}

const makeDataTrackingId = (string: string) => string.split(' ').join('-').toLowerCase()

export function ActionButtons({
  type,
  onShareClick,
  onDetailsClick,
  onChangeClick,
  onCancelClick,
  isChange = true,
  isCancel = true,
  isPostBooking = false,
  isChangeAllowed = true,
  isTrainTicketRefundable = false,
}: Props) {
  const changeText = useGetChangeText(type, isPostBooking)
  const removeText = useGetRemoveText(type, isPostBooking, isTrainTicketRefundable)
  const iconName: PWAIconNames =
    type === SegmentType.RideHail ? 'cancelRideHailPWA' : 'cancelTripV2PWA'

  return (
    <Container>
      <List>
        <CustomActions
          type={type}
          isPostBooking={isPostBooking}
          onDetailsClick={onDetailsClick}
          onShareClick={onShareClick}
        />
        {isChange && (
          <ListItemContainer>
            <ListItem
              onClick={onChangeClick}
              isDisabled={!isChangeAllowed}
              aria-label={changeText}
              data-tracking-id={`${makeDataTrackingId(changeText)}-trip-segment-button`}>
              <span>{changeText}</span>
              <ListItemIcon aria-hidden>
                <Icon name={getIconNameBySegment(type)} size="medium" />
              </ListItemIcon>
            </ListItem>
          </ListItemContainer>
        )}
        {isCancel && (
          <ListItemContainer>
            <ListItem
              onClick={onCancelClick}
              isWarning
              aria-label={removeText}
              data-tracking-id={`${makeDataTrackingId(removeText)}-trip-segment-button`}>
              <span>{removeText}</span>
              <ListItemIcon aria-hidden>
                <Icon name={iconName} size="medium" />
              </ListItemIcon>
            </ListItem>
          </ListItemContainer>
        )}
      </List>
    </Container>
  )
}
