import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useCustomLogoURL } from '@fiji/utils/use-get-custom-logo-url'

export function useProfile() {
  const { userStore } = useUserContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const customLogoURL = useCustomLogoURL()
  const { profile } = userStore
  const { privacyPolicy } = displayConfigurationStore
  const { t } = useTranslation()
  const { firstName, lastName } = profile || {}
  const email = profile?.personalContact?.email

  return {
    firstName,
    lastName,
    email: email || t('Profile.ErrorEmail'),
    privacyPolicy,
    customLogoURL,
  }
}
