import { css } from 'styled-components'

export const TRANSITION_NAME = 'ZONE_DIALOG'
export const TRANSITION_TIMEOUT = 700

const ANIMATION = 'cubic-bezier(0.4, 0, 0.2, 1)'

export const slideUp = css`
  transition: transform ${TRANSITION_TIMEOUT}ms ${ANIMATION}, opacity ${TRANSITION_TIMEOUT}ms;

  &.${TRANSITION_NAME}-enter, &.${TRANSITION_NAME}-appear {
    transform: translateY(25%);
    opacity: 0;
  }

  &.${TRANSITION_NAME}-appear-done,
    &.${TRANSITION_NAME}-enter-done,
    &.${TRANSITION_NAME}-appear-active,
    &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.${TRANSITION_NAME}-exit-done, &.${TRANSITION_NAME}-exit {
    transform: translateY(80%);
    opacity: 0;
  }
`
