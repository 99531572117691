import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace AirSearchErrors {
  export const CreateSearchError = createErrorClass('Create search error')
  export const UnexpectedErrorCreateSearch = createErrorClass(
    'Unexpected error while creating search',
  )
  export const LastPermittedDateError = createErrorClass<{ message: string }>(
    'Last permitted date error',
  )
  export const RestrictedCountryError = createErrorClass<{ message: string }>(
    'Restricted country error',
  )
  export const GetFlightsError = createErrorClass('Get flights error')
  export const UnexpectedErrorGetFlights = createErrorClass(
    'Unexpected error while getting flights',
  )

  export const GetFareAttributesError = createErrorClass('Get flight fare attributes error')
}

export type AirSearchErrorsInstances = InstanceType<
  typeof AirSearchErrors[keyof typeof AirSearchErrors]
>

export function isRestrictedCountryError(
  err: AirSearchErrorsInstances,
): err is InstanceType<typeof AirSearchErrors.RestrictedCountryError> {
  return err instanceof AirSearchErrors.RestrictedCountryError
}
export function isLastPermittedDateError(
  err: AirSearchErrorsInstances,
): err is InstanceType<typeof AirSearchErrors.LastPermittedDateError> {
  return err instanceof AirSearchErrors.LastPermittedDateError
}
