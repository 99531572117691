import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'
import { Link } from '@etta/ui/link'
import { headline } from '@fiji/style'

export const Container = styled.div`
  padding: 24px 32px;
`
export const Title = styled.h2`
  ${headline};
  margin: 0 0 16px;
`

export const FieldsStack = styled.div`
  max-width: 528px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`

export const BillingAddressSection = styled.div`
  margin: 32px 0 56px;
`

export const CheckBoxWrapper = styled.div`
  margin-top: 24px;
`

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
`
