import type { AddressInput, Address } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

export function getLocationValue(place?: Place | null, address?: AddressInput | Address | null) {
  if (!place) {
    return ''
  }
  const placeAirport = place?.airportCode
  const placeName = place.shortName ?? place.name
  const placeLabel = placeAirport || placeName
  if (!address) {
    return placeLabel
  }

  const placeAirportText = placeAirport ? `(${placeAirport})` : ''
  const placeCountryText = address.stateCode
    ? `${address.stateCode}, ${address.countryCode}`
    : address.countryCode
  const placeLocationLabel = `${address.city}, ${placeCountryText} ${placeAirportText}`

  return placeLocationLabel
}
