import { Skeleton } from '@etta/ui/skeleton'

export function GeoSafeLocationSkeleton() {
  return (
    <Skeleton width="100%" height="38">
      <rect height="11" width="65%" y="3.5" />
      <rect height="11" width="80%" y="24" />
    </Skeleton>
  )
}
