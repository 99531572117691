import type { IconColorPalette } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Container, StartsContainer, StarItem } from './star-rating-styled'

type StarVariant = 'clear' | 'withBackground'

type StarRatingProps = {
  rating: number
  color?: IconColorPalette
  variant?: StarVariant
  isLarge?: boolean
  'aria-label'?: string
}

function generateStars({ rating, variant, isLarge, color }: StarRatingProps) {
  const isClear = variant === 'clear'
  const starsCount = isClear ? rating : 5
  const iconSize = isLarge ? 16 : 12
  return Array.from(Array(starsCount).keys()).map((item: number) => {
    const isItemHighlighted = item + 1 <= rating
    const isHighlighted = isClear ? true : isItemHighlighted
    return (
      <StarItem key={item} isHighlighted={isHighlighted}>
        <Icon name="starPWA" color={isHighlighted ? color : undefined} size={iconSize} />
      </StarItem>
    )
  })
}

export function StarRating({
  rating,
  color,
  variant = 'withBackground',
  isLarge = false,
  'aria-label': ariaLabel,
}: StarRatingProps) {
  if (rating <= 0) {
    return null
  }

  return (
    <Container role="img" aria-label={ariaLabel}>
      <StartsContainer data-tracking-id={`star-rating-${rating}`}>
        {generateStars({ rating, variant, isLarge, color })}
      </StartsContainer>
    </Container>
  )
}
