import styled from 'styled-components'

export const ModalContainer = styled.div`
  padding: 2px;
  display: flex;
  width: 100%;
  max-width: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.17);
`
