import styled from 'styled-components'

const AVATAR_SIZE = 42

export const TravelerContainer = styled.div<{ isDelegateMode?: boolean; hasBorderTop?: boolean }>`
  background-color: unset;
  padding: 12px 0px;
  border-bottom: ${({ theme }) => '2px dashed ' + theme?.colors.borderDark};
  border-top: ${({ theme, hasBorderTop }) =>
    hasBorderTop ? '2px dashed ' + theme?.colors.borderDark : 'none'};

  ${({ theme, isDelegateMode }) =>
    isDelegateMode &&
    `
      background-color: ${theme.colors.error1};
      padding: 10px 15% 12px 15%;
      margin: 0 -15%;
      border: none;
    `}
`
export const AvatarContainer = styled.div<{ isDelegateMode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isDelegateMode
      ? ({ theme }) => theme.colors.plum
      : ({ theme }) => theme.colors.bodyText1};
  width: ${AVATAR_SIZE}px;
  min-width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  border-radius: 50%;
  overflow: hidden;
`
