import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { HotelDistanceMobile } from './mobile'
import { HotelDistanceDesktop } from './desktop'

export function HotelDistance(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<HotelDistanceMobile {...props} />}
      desktopSlot={<HotelDistanceDesktop {...props} />}
    />
  )
}
