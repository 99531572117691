import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import type { PlainTime as Time, TimeRange } from '@fiji/types'
import type { FlightLegType, MobilityLegType } from '@fiji/enums'
import { CarLegType, TimeRangeDirection } from '@fiji/enums'
import { placeFormatTime } from '../format-dates'
import { convertTimeRange } from './convert-time-range'

type Args = {
  time?: Time
  type: CarLegType | FlightLegType | MobilityLegType
  segmentType: SegmentType
  timeRange: TimeRange
}

const i18Base = 'TripPlanner.BaseSearch'
const i18Input = 'TripPlanner.BaseSearch.Input'

export function useTime({ time, type, segmentType, timeRange }: Args) {
  const { t } = useTranslation()
  const selectedTime = useMemo(() => {
    if (segmentType === SegmentType.CarRental) {
      return placeFormatTime(time)
    }

    return convertTimeRange(timeRange)
  }, [segmentType, time, timeRange])

  const selectedTimeLabel = useMemo(() => {
    if (segmentType === SegmentType.CarRental) {
      const isPickUp = type === CarLegType.PickUp
      return t(i18Input + (isPickUp ? '.PickUpTime' : '.DropoffTime'))
    }

    if (segmentType === SegmentType.Mobility) {
      return t(i18Input + '.PickUpTime')
    }

    if (timeRange.timeRangeBy === TimeRangeDirection.Departure) {
      return t(i18Input + '.DepartOnTime')
    }
    return t(i18Input + '.Arriving')
  }, [segmentType, t, timeRange.timeRangeBy, type])

  const ariaLabelTime = selectedTime
    ? t(i18Base + '.Input.TimeSelectedAriaLabel', {
        label: selectedTimeLabel,
        value: selectedTime,
      })
    : t(i18Base + '.Input.TimeAriaLabel', { label: selectedTimeLabel })

  return {
    ariaLabelTime,
    selectedTime,
    selectedTimeLabel,
  }
}
