import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { IconWrapper, StartIconMedium, SelectedDateText } from './selected-date-title-styled'

type Props = {
  isMobile: boolean
  dateTitle?: string
  withIcon?: boolean
}

export function SelectedDateTitle({ isMobile, dateTitle, withIcon }: Props) {
  return (
    <>
      {(!isMobile || withIcon) && (
        <IconWrapper>
          <StartIconMedium>
            <PWAIcon name="calendarPWA" />
          </StartIconMedium>
        </IconWrapper>
      )}
      <SelectedDateText>{dateTitle}</SelectedDateText>
    </>
  )
}
