import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { LayoutProps } from '../../types'
import { Content, DefaultContent, ColorContainer } from './rail-delivery-option-mobile-styled'
import { CollectViewPurchaseSuccess, CollectViewTravelerInformation } from './collect-view'
import { EticketViewPostBooking, EticketViewPurchaseSuccess } from './eticket-view'

export function RailDeliveryOptionMobile({
  isEticket,
  email,
  stationName,
  isPurchaseSuccessScreen,
  isStationTicketMachineDisabled,
  isPostBooking,
  onModalOpen,
}: LayoutProps) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { reviewTripStore } = useReviewTripContext()
  const { trip } = reviewTripStore
  const railCardName = trip?.railCards?.length ? trip?.railCards[0].name : ''

  if (isEticket && !isPurchaseSuccessScreen && !isPostBooking) {
    return null
  }

  if (isPurchaseSuccessScreen) {
    return (
      <ColorContainer isMobile={isMobile}>
        <Content isMobile={isMobile} marginHorizontal={16} marginVertical={isMobile ? 16 : 1}>
          {isEticket ? (
            <EticketViewPurchaseSuccess email={email} />
          ) : (
            <CollectViewPurchaseSuccess
              isStationTicketMachineDisabled={!!isStationTicketMachineDisabled}
              stationName={stationName}
              railCardName={railCardName}
              onModalOpen={onModalOpen}
            />
          )}
        </Content>
      </ColorContainer>
    )
  }

  if (isPostBooking) {
    return (
      <Content isMobile={isMobile} marginTop={16}>
        {isEticket ? (
          <EticketViewPostBooking email={email} onModalOpen={onModalOpen} />
        ) : (
          <CollectViewPurchaseSuccess
            isStationTicketMachineDisabled={!!isStationTicketMachineDisabled}
            stationName={stationName}
            railCardName={railCardName}
            onModalOpen={onModalOpen}
          />
        )}
      </Content>
    )
  }

  return (
    <DefaultContent isMobile={isMobile} marginVertical={16}>
      <CollectViewTravelerInformation
        stationName={stationName}
        isStationTicketMachineDisabled={!!isStationTicketMachineDisabled}
        onModalOpen={onModalOpen}
      />
    </DefaultContent>
  )
}
