import { Screen } from '@etta/ui/screen'
import type { LayoutProps } from '../../types'
import { RailResultsHeaderMobile } from './rail-results-header-mobile'

export function RailSearchResultPageMobile({ contentSlot }: LayoutProps) {
  return (
    <>
      <Screen backgroundColor="background" statusBarColor="white">
        <Screen.Header>
          <RailResultsHeaderMobile />
        </Screen.Header>

        <Screen.Container as={'main'}>{contentSlot}</Screen.Container>
      </Screen>
    </>
  )
}
