import styled, { css } from 'styled-components'
import { ScreenType } from '@fiji/modes'

export const WrapperNoResults = styled.div<{ screenType: ScreenType }>`
  ${(props) => {
    switch (props.screenType) {
      case ScreenType.Desktop:
        return css`
          display: flex;
          justify-content: center;
          width: 100%;
        `
      case ScreenType.Mobile:
        return css`
          height: 100%;
        `
    }
  }}
`
