import type { SeatList } from '../seat-map-segment.types'
import { checkShowRowNumber } from './check-row-number-visible'

export function getActualRowLength(
  largestRow: SeatList,
  mostFrequentAislesColumns: string[],
): number {
  let actualRowLength = 0

  largestRow.forEach((_, i, array) => {
    const shouldShowNumber = checkShowRowNumber(i, array, mostFrequentAislesColumns)
    actualRowLength += shouldShowNumber ? 2 : 1
  })

  return actualRowLength
}
