import type { PWAIconNames, IconColorPalette } from '@etta/ui/icon'

type IconProps = {
  isAllRoomsOutOfPolicy: boolean
}

export function getIconName({ isAllRoomsOutOfPolicy }: IconProps): PWAIconNames | null {
  if (isAllRoomsOutOfPolicy) {
    return 'outOfPolicyPWA'
  }
  return null
}

type IconColorsProps = IconProps & {
  isActive: boolean
}

export function getIconColor({
  isActive,
  isAllRoomsOutOfPolicy,
}: IconColorsProps): IconColorPalette | undefined {
  if (isActive) {
    return 'white'
  }
  if (isAllRoomsOutOfPolicy) {
    return undefined
  }
  return undefined
}
