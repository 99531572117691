import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetAlertsDocument } from '@fiji/graphql/hooks'
import type { GetAlertsQuery, GetAlertsQueryVariables } from '@fiji/graphql/hooks'

@DataProvider()
export class AlertsDataProvider extends GraphqlDataProvider {
  getAlertsAbortController: AbortController | null = null

  getAlerts(input: GetAlertsQueryVariables['input']) {
    const controller = new AbortController()
    this.getAlertsAbortController?.abort()
    this.getAlertsAbortController = controller

    return this.client.query<GetAlertsQuery, GetAlertsQueryVariables>({
      query: GetAlertsDocument,
      fetchPolicy: 'network-only',
      variables: {
        input,
      },
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }
}
