import styled from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const BookingConditionsTitle = styled.h6`
  margin: 19px 0 18px;
  margin-left: 24px;
  color: ${(p) => p.theme.colors.mainText};
  ${headline};
  font-size: 20px;
  line-height: 25px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 44px;
  min-height: 258px;
  margin: 0;
`

export const Item = styled.li`
  line-height: 19px;
  color: ${(p) => p.theme.colors.bodyText};
  & + & {
    margin-top: 20px;
  }

  ${footnoteMedium}
  font-size: 15px;
`

export const CloseButton = styled.div`
  margin-right: 8px;
`
