import type { ReactNode } from 'react'
import { RowMessageItem } from '../row-message-item'

type Props = {
  title: string
  message: string
  messageSlot: ReactNode
  hasMultipleItems: boolean
  shouldExpandMessages: boolean
  onClose: () => void
}

export function AlertItem({
  title,
  message,
  messageSlot,
  hasMultipleItems,
  shouldExpandMessages,
  onClose,
}: Props) {
  return (
    <RowMessageItem
      iconName="warningFilledPWA"
      iconColor="warning"
      title={title}
      message={message}
      messageSlot={messageSlot}
      hasMultipleItems={hasMultipleItems}
      shouldExpandMessages={shouldExpandMessages}
      isClosable
      hasIcon
      onClose={onClose}
    />
  )
}
