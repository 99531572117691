import type { TextFieldSize } from '../types'

export function getTextFieldSize(size?: TextFieldSize) {
  switch (size) {
    case 'small':
      return 40
    case 'small-font':
      return 48
    case 'normal':
    default:
      return 56
  }
}
