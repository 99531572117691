import styled from 'styled-components'
import { EmissionsDeviationLevel } from '@fiji/enums'
import {
  linkSmall,
  footnoteMedium,
  getKeyboardNavigationStyle,
  headline,
  title3,
} from '@fiji/style'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`
export const EstimatedEmissionsContainer = styled.div``

export const EcoCheckBackground = styled.img`
  width: 370px;
  border-radius: 0 0 14px 14px;
  margin-bottom: -5px;
  height: 160px;
`

export const EstimatedEmissions = styled.div`
  padding: 20px 20px 16px 20px;
`

export const Title = styled.p`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
  margin: 0px;
`

export const Body = styled.div`
  padding-left: 20px;
  width: 100%;
  padding-bottom: 24px;
`

export const EmissionsDescription = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.mainText1};
  margin: 0px;
`

export const LearnMore = styled.div`
  display: block;
  width: fit-content;
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  ${linkSmall};
  ${getKeyboardNavigationStyle({})}
`

export const BottomLine = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  height: 8px;
  width: 100%;
  background-color: ${(props) =>
    props.deviationLevel !== EmissionsDeviationLevel.More
      ? props.theme.colors.success
      : props.theme.colors.mainText1};
  border-radius: 0 0 14px 14px;
  position: relative;
`
export const EmissionsLinks = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 8px;
`

export const EmissionInformationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 2px;
`

export const EmissionInformationValue = styled.p`
  ${title3};
  margin: 0;
  color: ${(props) => props.theme.colors.mainText};
`
