import { useTranslation } from 'react-i18next'

type Props = {
  legNumber: number
  legs: number
}

const i18nBase = 'PostBooking.FlightDetails'

export function useFlightPosition({ legNumber, legs }: Props) {
  const { t } = useTranslation()

  const isMultipleFlights = legs > 1
  const isLastSegment = legNumber === legs

  const flightNumber = isMultipleFlights
    ? t(i18nBase + '.FlightNumber', { number: legNumber, legs: legs })
    : ''
  return {
    isMultipleFlights,
    isLastSegment,
    flightNumber,
  }
}
