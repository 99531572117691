import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ActiveDotStyles = css`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.mainText1};
`

export const DotContainer = styled.div`
  &:not(:last-child) {
    padding-right: 4.5px;
    padding-left: 4.5px;
  }
`

export const Dot = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.borderDark};

  ${({ isActive }) => isActive && ActiveDotStyles};
`
