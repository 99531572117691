import i18n from '@fiji/i18n'
import { Toggle } from '@etta/interface/services/toggle'
import { OptionViewTypes } from '@etta/ui/drop-down-list'
import { Inject, Store } from '@etta/di'
import type { EmergencyContactValueObject } from '@etta/modules/user/core/value-objects/emergency-contact.value-object'
import { ValidatorMaker } from '@etta/modules/settings/validators/validator-maker'
import { Validator } from '@etta/interface/services/validator'
import { AddressFields } from '../../core/enums/address-fields'
import { FieldSettingsStore } from './field-settings'

const Relationships = {
  SpousePartner: 'Spouse/domestic partner',
  Parent: 'Parent',
  Child: 'Child',
  Relative: 'Relative',
  Business: 'Business',
  Neighbor: 'Neighbor',
  Friend: 'Friend',
  Other: 'Other',
}

const i18nBase = 'Settings.EmergencyContact.Modal'

type PhoneFields =
  | 'primaryPhoneExt'
  | 'primaryPhoneNumber'
  | 'alternatePhoneExt'
  | 'alternatePhoneNumber'

@Store()
export class EmergencyContactStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  private _additionalInformationValidator: Validator = new Validator({})

  isSaveButtonDisabled = true

  editToggle = new Toggle()

  emergencyContactValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeEmergencyContactValidator()

  setEmergencyContactValue<
    T extends keyof Omit<EmergencyContactValueObject, 'primaryPhone' | 'alternatePhone' | 'address'>
  >(field: T, value: EmergencyContactValueObject[T]) {
    this.emergencyContactValidator.onFieldChange(field, value)
  }

  setEmergencyContactPhoneInputValue(
    type: 'primaryPhone' | 'alternatePhone',
    field: 'CountryCode' | 'Number',
    value: string,
  ) {
    const phoneField = (type + field) as PhoneFields
    this.emergencyContactValidator.onFieldChange(phoneField, value)
  }

  setEmergencyContactAddressInputValue(field: AddressFields, value: string) {
    this.emergencyContactValidator.onFieldChange(field, value)
  }

  setEmergencyContact(input?: EmergencyContactValueObject) {
    if (input) {
      this.setEmergencyContactValue('name', input.name)
      this.setEmergencyContactValue('relationship', input.relationship)
      this.setEmergencyContactValue('email', input.email)

      this.setEmergencyContactPhoneInputValue(
        'primaryPhone',
        'CountryCode',
        input.primaryPhone?.countryCode || '',
      )
      this.setEmergencyContactPhoneInputValue(
        'primaryPhone',
        'Number',
        input.primaryPhone?.number || '',
      )
      this.setEmergencyContactPhoneInputValue(
        'alternatePhone',
        'CountryCode',
        input.alternatePhone?.countryCode || '',
      )
      this.setEmergencyContactPhoneInputValue(
        'alternatePhone',
        'Number',
        input.alternatePhone?.number || '',
      )

      this.setEmergencyContactAddressInputValue(AddressFields.City, input.address?.city || '')
      this.setEmergencyContactAddressInputValue(
        AddressFields.Country,
        input.address?.countryCode || '',
      )
      this.setEmergencyContactAddressInputValue(AddressFields.Zip, input.address?.postalCode || '')
      this.setEmergencyContactAddressInputValue(
        AddressFields.Mailstop,
        input.address?.mailStop || '',
      )
      this.setEmergencyContactAddressInputValue(AddressFields.State, input.address?.stateCode || '')
      this.setEmergencyContactAddressInputValue(AddressFields.Street1, input.address?.street1 || '')
      this.setEmergencyContactAddressInputValue(AddressFields.Street2, input.address?.street2 || '')
    }
  }

  getEmergencyRelationships() {
    const relationships = Object.keys(Relationships)
    return relationships.map((value) => ({
      label: i18n.t(`${i18nBase}.RelationshipOptions.` + value),
      value,
      viewType: OptionViewTypes.OptionWithCheckboxIcon,
    }))
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }

  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }
}
