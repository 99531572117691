import { PlaceAutocompleteType } from '@fiji/graphql/types'
import { SearchByType, AlgoliaLocationType, RideHailSuggestedLocationType } from '@fiji/enums'
import type { Props } from './types'

export function getIconName(place: Props['list'][0]) {
  if (
    place.placeType === PlaceAutocompleteType.AlgoliaPlaces &&
    place.locationType === SearchByType.Station
  ) {
    return 'railPWA'
  }

  if (place.airportCode || place.locationType === RideHailSuggestedLocationType.AIRPORT) {
    return 'flightPWA'
  }

  if (place.placeType === PlaceAutocompleteType.ReferencePoint) {
    return 'organisationPWA'
  }

  if (place.locationType === AlgoliaLocationType.StationGroup) {
    return 'organisationPWA'
  }

  if (place.locationType === RideHailSuggestedLocationType.HOTEL) {
    return 'hotelPWA'
  }

  if (place.locationType === RideHailSuggestedLocationType.HOME) {
    return 'homePWA'
  }

  return 'searchPinPWA'
}
