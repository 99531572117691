import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import type { LayoutProps } from '../../types'
import { useSegmentCardStatus } from '../../use-segment-card-status'
import { getIsButtonsAvailable } from './get-is-buttons-available'
import {
  CardWrapper,
  CardContainer,
  CardContent,
  CardHeader,
  CardHeaderRight,
  CardFooter,
  CardActions,
  CardAction,
  TimeTitle,
  SegmentStatus,
} from './segment-card-desktop-styled'

const i18nBase = 'ActionButtons'

export function SegmentCardDesktop({
  isPast,
  segmentIcon,
  titleText,
  titleSlot,
  departureTime,
  dataTexts = [],
  buttons = [],
  isShareButtonAvailable = true,
  isUpcoming,
  onClick,
  onShare,
  flightLegSegments,
  segmentType,
  segmentStatus,
  label,
}: LayoutProps) {
  const { t } = useTranslation()
  const isButtonsAvailable = getIsButtonsAvailable(buttons, !isPast)
  const { handleCardActionClick, displayLabel, displayStatus, statusColor } = useSegmentCardStatus({
    label,
    segmentStatus,
  })

  return (
    <CardWrapper
      tabIndex={0}
      data-tracking-id={`trip-${segmentType}-segment-card`}
      onClick={onClick}
      aria-label={`${departureTime || ''} ${titleText || titleSlot}`}>
      <Icon color="mainText" name={segmentIcon} size="medium" />
      <CardContainer>
        <CardHeader>
          <Text color="mainText" variant="title3">
            {titleText || titleSlot}
          </Text>
          <CardHeaderRight>
            {departureTime && (
              <TimeTitle variant="captionStrong" color="bodyText">
                {departureTime}
              </TimeTitle>
            )}
            <Icon name="chevronRightPWA" size="small" color="bodyText" />
          </CardHeaderRight>
        </CardHeader>
        <CardContent>
          <SegmentStatus>
            {displayLabel && (
              <Text variant="footnoteMedium" color="bodyText">
                {displayLabel}
              </Text>
            )}
            {displayStatus && (
              <Text variant="captionStrongCaps" color={statusColor}>
                {displayStatus}
              </Text>
            )}
          </SegmentStatus>
          {!!dataTexts.length && (
            <>
              {dataTexts.map((text) => (
                <Text variant="footnoteMedium" color="bodyText" key={text}>
                  {text}
                </Text>
              ))}
            </>
          )}
        </CardContent>
        {!!flightLegSegments?.length && (
          <AirportChangeBadge flightLegSegments={flightLegSegments} size="large" />
        )}
        {isButtonsAvailable && (
          <CardFooter>
            <CardActions>
              {buttons.map(
                ({ visible, title, isDisabled, onClick }) =>
                  visible && (
                    <CardAction
                      aria-label={t(`${i18nBase}.Label`, { button: title })}
                      variant="link"
                      key={title}
                      disabled={isDisabled}
                      data-tracking-id={`${title.toLowerCase()}-${segmentType}-trip-segment-button`}
                      onClick={(ev) => handleCardActionClick(ev, onClick)}>
                      <Text variant="linkSmall">{title}</Text>
                    </CardAction>
                  ),
              )}
            </CardActions>
            {isUpcoming && isShareButtonAvailable && (
              <Button
                aria-label={t(`${i18nBase}.Label`, { button: `${t(`${i18nBase}.Share`)}` })}
                variant="link"
                onClick={(ev) => handleCardActionClick(ev, onShare)}
                minWidth={0}
                data-tracking-id={`share-${segmentType}-trip-segment-button`}
                paddings={false}>
                <Icon name="sharePWA" size="medium" color="bodyText" />
              </Button>
            )}
          </CardFooter>
        )}
      </CardContainer>
    </CardWrapper>
  )
}
