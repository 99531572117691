import { MINUTES_UNTIL_SHOW_MODAL } from './constants'

export function getTimeBeforeShowModal(tokenExpireTime: number) {
  if (!tokenExpireTime) {
    return
  }

  const timeBeforeShowModal = tokenExpireTime - Date.now() - MINUTES_UNTIL_SHOW_MODAL
  return timeBeforeShowModal
}
