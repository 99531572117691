import { useTranslation } from 'react-i18next'
import { RatePrice } from '@etta/components/rate-price/rate-price'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'
import { Container } from './trip-total-block-mobile-styled'

export function TripTotalBlockMobile({ tripTotal }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <Text variant="subHeadStrong" color="mainText">
          {t('PurchaseReservationSuccess.TripTotal')}
        </Text>
        <RatePrice rate={tripTotal} textVariant="subHeadStrong" />
      </Container>
    </>
  )
}
