import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Portal } from '@etta/ui/portal'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { WheelDateTimePicker } from '@etta/ui/wheel-date-time-picker/wheel-date-time-picker'
import { dateFormat } from '@fiji/utils/dates'
import { TRANSITION_NAME } from './transition'
import {
  SheetContainer,
  SheetContent,
  MiniPill,
  HeaderContent,
  Separator,
  BodyContent,
  NextButtonWrapper,
  VerticalBlock,
  CalendarContent,
  IconButton,
  BackDrop,
  SheetContainerRoot,
} from './wheel-picker-dialog-styled'

type Props = {
  portalTarget?:
    | '#modals-container'
    | '#mobility-modals-container'
    | '#mobility-select-location-modals-container'
  minDate?: Date
  maxDate?: Date
  isOpenDialog: boolean
  value: Date
  onSubmit: (value: Date) => void
  onClose: () => void
}

const i18nBase = 'Mobility.WheelPickerDialog.'
const DATE_FORMAT = "EEE d MMM, yyyy 'at' h:mm aa"

export const WheelPickerDialog = ({
  portalTarget = '#modals-container',
  isOpenDialog,
  value,
  onSubmit,
  onClose,
  minDate,
  maxDate,
}: Props) => {
  const { t } = useTranslation()
  const [dateValue, setDateValue] = useState(value)
  const [isShownDialog, setIsShownDialog] = useState(false)

  const isShown = useMemo(() => isShownDialog || isOpenDialog, [isShownDialog, isOpenDialog])

  const handleValueChange = useCallback((date) => {
    setDateValue(date)
  }, [])

  const handleConfirm = useCallback(() => {
    if (isValid(dateValue)) {
      onSubmit(dateValue)
      onClose()
    }
  }, [dateValue, onClose, onSubmit])

  const handleClose = useCallback(() => {
    setDateValue(value)
    onClose()
  }, [onClose, value])

  const formattedDate = useMemo(() => {
    if (!isValid(dateValue)) {
      return ''
    }
    return dateFormat(dateValue, DATE_FORMAT)
  }, [dateValue])

  return (
    <Portal target={portalTarget}>
      <SheetContainer isShown={isShown} role={'dialog'}>
        <BackDrop aria-hidden onClick={handleClose} />
        <DialogAnimation
          isOpen={isOpenDialog}
          onEntered={() => setIsShownDialog(true)}
          onExited={() => setIsShownDialog(false)}
          isAnimated
          transitionName={TRANSITION_NAME}>
          <SheetContainerRoot>
            <SheetContent>
              <MiniPill />
              <HeaderContent>
                <CalendarContent>
                  <Block paddingRight={16}>
                    <Icon name="calendarPWA" color="mainText" size="large" />
                  </Block>
                  <VerticalBlock>
                    <Text variant="headline" color="mainText">
                      {t(i18nBase + 'ReserveRide')}
                    </Text>
                    <Text variant="footnoteMedium" color="bodyText">
                      {formattedDate}
                    </Text>
                  </VerticalBlock>
                </CalendarContent>
                <Block>
                  <IconButton onClick={handleClose}>
                    <Icon name="closeFilledPWA" color="mainText" size="medium" />
                  </IconButton>
                </Block>
              </HeaderContent>
              <Separator />
              <BodyContent>
                <Block isFullWidth={true} paddingLeft={16} paddingRight={16}>
                  {isShown && (
                    <WheelDateTimePicker
                      minDate={minDate}
                      maxDate={maxDate}
                      selectedDate={dateValue}
                      onSelectedDateChange={handleValueChange}
                    />
                  )}
                </Block>
              </BodyContent>
              <Separator />
              <NextButtonWrapper btnType="primary" onClick={handleConfirm}>
                {t(i18nBase + 'Confirm')}
              </NextButtonWrapper>
            </SheetContent>
          </SheetContainerRoot>
        </DialogAnimation>
      </SheetContainer>
    </Portal>
  )
}
