import i18n from 'i18next'

type Args = {
  messagesCount: number
  alertsCount: number
}

function getViewMoreKey({ messagesCount, alertsCount }: Args) {
  const isWithAlerts = alertsCount > 0
  const isWithMessages = messagesCount > 0
  if (isWithAlerts && isWithMessages) {
    return 'PluralViewMoreAlertsAndMessages'
  }
  if (!isWithAlerts) {
    return messagesCount > 1 ? 'PluralViewMoreMessages' : 'SingularViewMoreMessage'
  }
  if (!isWithMessages) {
    return alertsCount > 1 ? 'PluralViewMoreAlerts' : 'SingularViewMoreAlert'
  }
}

export function getViewMoreTitle({ messagesCount, alertsCount }: Args) {
  return i18n.t('DynamicSiteMessage.' + getViewMoreKey({ messagesCount, alertsCount }))
}
