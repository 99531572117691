import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 16px;
`

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 18px 24px;
  border: 1px solid ${(p) => p.theme.colors.bodyText1};
  background: ${(p) => p.theme.colors.white};
  border-radius: 14px;
`

export const DurationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const DurationInfo = styled.div`
  flex-shrink: 0;
  z-index: 1;
  &:first-child {
    padding-right: 10px;
  }
  &:last-child {
    padding-left: 40px;
  }
`

export const TotalDurationTimeContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    position: relative;
    margin-left: 5px;
    padding-right: 8px;
    &:after {
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      right: 0;
      top: calc(50% - 1.5px);
      background: ${(props) => props.theme.colors.bodyText1};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

export const IconsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
  svg,
  div,
  img {
    width: 24px;
    height: 24px;
  }
`
