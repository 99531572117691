import { Skeleton } from '@etta/ui/skeleton'
import { Container, Item } from './mobility-suggested-drop-off-skeleton-styled'

export function MobilitySuggestedDropOffSkeleton() {
  return (
    <Container>
      <Item>
        <Skeleton width={335} height={70} variant="secondary1" skin="default">
          <rect x={0} y={17} width={28} height={28} />
          <rect x={36} y={17} width={150} height={15} />
          <rect x={36} y={38} width={271} height={15} />
        </Skeleton>
      </Item>
      <Item>
        <Skeleton width={335} height={70} variant="secondary1" skin="default">
          <rect x={0} y={17} width={28} height={28} />
          <rect x={36} y={17} width={150} height={15} />
          <rect x={36} y={38} width={271} height={15} />
        </Skeleton>
      </Item>
      <Item>
        <Skeleton width={335} height={70} variant="secondary1" skin="default">
          <rect x={0} y={17} width={28} height={28} />
          <rect x={36} y={17} width={150} height={15} />
          <rect x={36} y={38} width={271} height={15} />
        </Skeleton>
      </Item>
    </Container>
  )
}
