import styled from 'styled-components'

export const RailSearchResultsContainer = styled.div`
  padding: 16px;
  width: 100%;
`

export const InfoMessage = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.white};
`

export const Container = styled.div`
  margin: 20px 0px;
`
