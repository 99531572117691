import { MediaLayout } from '@etta/ui/media-layout'
import type { RailOpenReturnDetailsProps } from '../types'
import { RailOpenReturnDetailsMobile } from './mobile/rail-open-return-details-mobile'
import { RailOpenReturnDetailsDesktop } from './desktop/rail-open-return-details-desktop'

export function RailOpenReturnDetailsLayout(props: RailOpenReturnDetailsProps) {
  return (
    <MediaLayout
      mobileSlot={<RailOpenReturnDetailsMobile {...props} />}
      desktopSlot={<RailOpenReturnDetailsDesktop {...props} />}
    />
  )
}
