import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../../types'

const i18Base = 'HoldTrip.HoldTripConfirmationModal.'
export function HoldTripConfirmationModalMobile({
  isOpen,
  onCancel,
  onConfirm,
  translationSlot,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onCancel}>
      <Modal.Title aria-label={t(i18Base + 'Title')}>{t(i18Base + 'Title')}</Modal.Title>
      <Modal.Body padding="normal">
        <Block>
          <Text variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'Description')}
          </Text>
        </Block>
        {translationSlot && (
          <>
            <Block marginTop={22}>
              <Text variant={'subHeadStrong'} color={'mainText1'}>
                {t(i18Base + 'CancellationInformation')}
              </Text>
            </Block>
            <Block marginTop={8}>
              <Text variant={'subHeadMedium'} color={'bodyText'}>
                {translationSlot}
              </Text>
            </Block>
          </>
        )}
      </Modal.Body>
      <Modal.Footer padding="normal" withBorder>
        <Button
          fullWidth
          variant={'solid'}
          onClick={onConfirm}
          data-tracking-id={'continue-button'}>
          {t(i18Base + 'ContinueButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
