import styled from 'styled-components'
import { withStyledText } from '@fiji/style'

export const Reasons = styled.ul`
  font-size: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`

export const Reason = styled.li`
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`

export const InlineReason = styled.div`
  display: flex;
  margin-top: 2px;
  margin-bottom: 8px;
  font-size: 1.4rem;
  line-height: 1.25;
  & > span:first-child {
    margin-right: 4px;
    padding-top: 1px;
  }
  ${withStyledText};
`
