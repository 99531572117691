import type {
  GetRailQuery,
  FareTierFilter,
  StationFilter,
  StationFilterStation,
  StopsFilter,
  TimeFilter,
  TrainCompanyFilter,
  Preference,
  RailCarrier,
  RailLegSubSegmentStation,
  TrainStop,
  OutOfPolicyData as GQLOutOfPolicyData,
  RailFareRulesAndRestrictions,
  RailFareSegment,
  RailFarePermittedStation,
} from '@fiji/graphql/types'
import { RailTransportationMode, FareTier } from '@fiji/graphql/types'
import { FareTierEnum } from '@etta/modules/rail/core/enums/fare-tier.enum'
import type { StationFilterEntity } from '@etta/modules/rail/core/entities/station-filter.entity'
import type { StationFilterStationValueObject } from '@etta/modules/rail/core/value-objects/station-filter-station.value-object'
import type { StopsFilterEntity } from '@etta/modules/rail/core/entities/stops-filter.entity'
import type { TrainCompanyFilterEntity } from '@etta/modules/rail/core/entities/train-company-filter.entity'
import type { OutOfPolicyData } from '@etta/core/value-objects'
import type { RailFareSegmentEntity } from '@etta/modules/rail/core/entities/rail-fare-segment.entity'
import type { RailFareRulesAndRestrictionEntity } from '@etta/modules/rail/core/entities/rail-fare-rules-and-restriction.entity'
import type {
  FareTierFilterValueObject,
  FareConditionValueObject,
  RailFilterValueObject,
  RailSearchResultValueObject,
  TimeFilterValueObject,
} from '@etta/modules/rail/core/value-objects/rail-search-result.value-object'
import type { SearchRailLegEntity } from '@etta/modules/rail/core/entities/search-rail-leg.entity'
import type {
  PreferenceEntity,
  RailCarrierEntity,
  RailLegSubSegmentStationEntity,
  SearchRailLegSubSegmentEntity,
} from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import type { TrainStopValueObject } from '@etta/modules/rail/core/value-objects/train-stop.value-object'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import type { RailFareValueObject } from '@etta/modules/rail/core/value-objects/rail-fare.value-object'
import { deleteTimezone } from '@fiji/utils/dates'
import type { PermittedStationEntity } from '../../core/entities/permitted-station.entity'

export function toRailSearchValueObject(getRailQuery: GetRailQuery): RailSearchResultValueObject {
  const {
    fareConditions,
    filters,
    isCustomTimeReplaced,
    maxResultsSize,
    messages,
    pageStart,
    pageEnd,
    railLegs,
    railPolicies,
    totalCount,
  } = getRailQuery.rail

  return {
    fareConditions: fareConditions.map(mapGQLFareConditionsToFareConditionsValueObject),
    filters: mapGQLFiltersToFiltersValueObject(filters),
    isCustomTimeReplaced: isCustomTimeReplaced ?? undefined,
    maxResultsSize: maxResultsSize ?? undefined,
    messages: messages ?? undefined,
    pageStart: pageStart ?? undefined,
    pageEnd: pageEnd ?? undefined,
    railLegs: railLegs.map((leg) => mapGQLSearchRailLegToSearchRailLegEntity(leg)),
    railPolicies: railPolicies,
    totalCount: totalCount,
  }
}

function mapGQLFareConditionsToFareConditionsValueObject(
  input: GetRailQuery['rail']['fareConditions'][0],
): FareConditionValueObject {
  return {
    localizedFareConditions: input.localizedFareConditions,
    tier: {
      id: input.tier.id,
      name: input.tier.name ?? undefined,
    },
  }
}

function mapGQLFiltersToFiltersValueObject(
  input: GetRailQuery['rail']['filters'],
): RailFilterValueObject {
  return {
    trainCompany: input.trainCompany.map(mapTrainCompanyFilterToTrainCompanyFilterEntity),
    originTimeRange: mapGQLTimeFilterToTimeFilterValueObject(input.originTimeRange),
    destinationTimeRange: mapGQLTimeFilterToTimeFilterValueObject(input.destinationTime),
    fareTier: input.fareTier?.map(mapGQLFareTierToFareTierFilterValueObject),
    station: input.station.map(mapGQLStationFilterToStationFilterEntity),
    stops: input.stops.map(mapGQLStopsFilterToStopsFilterEntity),
  }
}

function mapGQLTimeFilterToTimeFilterValueObject(input: TimeFilter): TimeFilterValueObject {
  return {
    start: input.start ? new Date(deleteTimezone(input.start)) : undefined,
    end: input.end ? new Date(deleteTimezone(input.end)) : undefined,
  }
}

function mapGQLFareTierToFareTierFilterValueObject(
  input: FareTierFilter,
): FareTierFilterValueObject {
  return {
    displayName: input.displayName,
    id: input.id,
    value: mapGQLFareTierToFareTierEnum(input.value),
  }
}

function mapGQLFareTierToFareTierEnum(input: FareTier): FareTierEnum {
  switch (input) {
    case FareTier.Restricted:
      return FareTierEnum.Restricted
    case FareTier.Restricted_2:
      return FareTierEnum.Restricted_2
    case FareTier.RestrictedExpectFees:
      return FareTierEnum.RestrictedExpectFees
    case FareTier.RestrictedExpectFees_2:
      return FareTierEnum.RestrictedExpectFees_2
    case FareTier.RestrictedExpectFees_3:
      return FareTierEnum.RestrictedExpectFees_3
    case FareTier.RestrictedExpectFees_4:
      return FareTierEnum.RestrictedExpectFees_4
    case FareTier.Unmodifiable:
      return FareTierEnum.Unmodifiable
    case FareTier.Unrestricted:
      return FareTierEnum.Unrestricted
  }
}

function mapGQLStationFilterToStationFilterEntity(input: StationFilter): StationFilterEntity {
  return {
    destination: mapGQLStationFilterStationToStationFilterStationValueObject(input.destination),
    id: input.id,
    origin: mapGQLStationFilterStationToStationFilterStationValueObject(input.origin),
  }
}

function mapGQLStationFilterStationToStationFilterStationValueObject(
  input: StationFilterStation,
): StationFilterStationValueObject {
  return {
    city: input.city ?? undefined,
    code: input.code,
    name: input.name ?? undefined,
  }
}

function mapGQLStopsFilterToStopsFilterEntity(input: StopsFilter): StopsFilterEntity {
  return {
    id: input.id,
    isSelected: input.isSelected ?? undefined,
    value: input.value,
  }
}

function mapTrainCompanyFilterToTrainCompanyFilterEntity(
  input: TrainCompanyFilter,
): TrainCompanyFilterEntity {
  return {
    id: input.id,
    name: input.name,
    value: input.value,
    imageUrl: input.imageUrl ?? undefined,
  }
}

function mapGQLSearchRailLegToSearchRailLegEntity(
  input: GetRailQuery['rail']['railLegs'][0],
): SearchRailLegEntity {
  return {
    railTime: input.railTime,
    policy: {
      isInPolicy: input.policy.isInPolicy,
      outOfPolicyData: input.policy.outOfPolicyData
        ? input.policy.outOfPolicyData.map(mapGQLOutOfPolicyDataToOutOfPolicyData)
        : undefined,
      // outOfPolicyReasons: input.policy.outOfPolicyReasons ?? undefined,
    },
    rate: input.rate ?? undefined,
    distance: input.distance
      ? { units: input.distance.units ?? undefined, value: input.distance.value }
      : undefined,
    connectionInfo: input.connectionInfo,
    totalTime: input.totalTime,
    legId: input.legId,
    labels: input.labels ? input.labels.map((label) => label ?? '') : undefined,
    daysInBetween: input.daysInBetween,
    railStops: input.railStops,
    origin: input.origin,
    originCode: input.originCode,
    destination: input.destination,
    destinationCode: input.destinationCode,
    departureDate: input.departureDate,
    arrivalDate: input.arrivalDate,
    isSelectable: input.isSelectable,
    ecoInfo: input.ecoInfo ?? undefined,
    platform: input.platform ?? undefined,
    isUnusedTicketApplicable: input.isUnusedTicketApplicable,
    segments: input.segments.map(mapGQLSearchRailLegSubSegmentToSearchRailLegSubSegmentEntity),
    standardClassFares: input.fares
      .filter((fare) => fare.segments?.[0].displayServiceClass === 'Standard')
      .map(mapGQLRailFareToRailFareValueObject),
    firstClassFares: input.fares
      .filter((fare) => fare.segments?.[0].displayServiceClass === 'FirstClass')
      .map(mapGQLRailFareToRailFareValueObject),
  }
}

function mapGQLSearchRailLegSubSegmentToSearchRailLegSubSegmentEntity(
  input: GetRailQuery['rail']['railLegs'][0]['segments'][0], // SearchRailLegSubSegment
): SearchRailLegSubSegmentEntity {
  return {
    amenities: input.amenities ?? undefined,
    arrivalDate: new Date(input.arrivalDate),
    carrier: input.carrier ? mapGQLRailCarrierToRailCarrierEntity(input.carrier) : undefined,
    changeDuration: input.changeDuration ?? undefined,
    codeshareCarrier: {
      code: input.codeshareCarrier.code ?? undefined,
      name: input.codeshareCarrier.name ?? undefined,
    },
    departureDate: new Date(input.departureDate),
    destination: mapGQLRailLegSubSegmentStationToRailLegSubSegmentStationEntity(input.destination),
    id: input.id,
    isPreferredAny: input.isPreferredAny,
    isSeatMapAvailable: input.isSeatMapAvailable,
    isSeatSelectable: input.isSeatSelectable,
    origin: mapGQLRailLegSubSegmentStationToRailLegSubSegmentStationEntity(input.origin),
    percentageOnTime: input.percentageOnTime ?? undefined,
    preference: input.preference ? mapGQLPreferenceToPreferenceEntity(input.preference) : undefined,
    preferredBadgeText: input.preferredBadgeText ?? undefined,
    railTime: input.railTime,
    stops: input.stops?.map(mapGQLTrainStopToPreferenceEntity),
    train: input.train
      ? {
          code: input.train.code,
          name: input.train.name ?? undefined,
        }
      : undefined,
    trainId: input.trainId ?? undefined,
    trainNumber: input.trainNumber,
    transportationMode: input.transportationMode
      ? mapGQLRailTransportationModeToRailTransportationModeEnum(input.transportationMode)
      : undefined,
  }
}

function mapGQLRailLegSubSegmentStationToRailLegSubSegmentStationEntity(
  input: RailLegSubSegmentStation,
): RailLegSubSegmentStationEntity {
  return {
    city: input.city,
    code: input.code,
    name: input.name,
    terminal: input.terminal ?? undefined,
  }
}

function mapGQLRailCarrierToRailCarrierEntity(input: RailCarrier): RailCarrierEntity {
  return {
    code: input.code ?? undefined,
    id: input.id,
    imageUrl: input.imageUrl ?? undefined,
    logoImageUrl: input.logoImageUrl ?? undefined,
    name: input.name,
  }
}

function mapGQLPreferenceToPreferenceEntity(input: Preference): PreferenceEntity {
  return {
    code: input.code ?? undefined,
    customBadge: {
      color: input.customBadge.color ?? undefined,
      name: input.customBadge.name ?? undefined,
    },
    highlight: input.highlight ?? undefined,
    id: input.id ?? undefined,
    ownerName: input.ownerName ?? undefined,
    ownerType: input.ownerType ?? undefined,
    shouldShowDefaultPreference: input.shouldShowDefaultPreference ?? undefined,
    tooltipText: input.tooltipText ?? undefined,
  }
}

function mapGQLTrainStopToPreferenceEntity(
  input: Pick<TrainStop, 'departingTime' | 'stationName' | 'time'>,
): TrainStopValueObject {
  return {
    departingTime: input.departingTime ? new Date(input.departingTime) : undefined,
    stationName: input.stationName,
    time: input.time,
  }
}

function mapGQLRailTransportationModeToRailTransportationModeEnum(
  input: RailTransportationMode,
): RailTransportationModeEnum {
  switch (input) {
    case RailTransportationMode.Bus:
      return RailTransportationModeEnum.Bus
    case RailTransportationMode.Ferry:
      return RailTransportationModeEnum.Ferry
    case RailTransportationMode.Hovercraft:
      return RailTransportationModeEnum.Hovercraft
    case RailTransportationMode.Metro:
      return RailTransportationModeEnum.Metro
    case RailTransportationMode.PublicTransit:
      return RailTransportationModeEnum.PublicTransit
    case RailTransportationMode.Taxi:
      return RailTransportationModeEnum.Taxi
    case RailTransportationMode.Train:
      return RailTransportationModeEnum.Train
    case RailTransportationMode.Tram:
      return RailTransportationModeEnum.Tram
    case RailTransportationMode.Tramlink:
      return RailTransportationModeEnum.Tramlink
    case RailTransportationMode.Transfer:
      return RailTransportationModeEnum.Transfer
    case RailTransportationMode.Unspecified:
      return RailTransportationModeEnum.Unspecified
    case RailTransportationMode.Walk:
      return RailTransportationModeEnum.Walk
  }
}

function mapGQLOutOfPolicyDataToOutOfPolicyData(input: GQLOutOfPolicyData): OutOfPolicyData {
  return {
    currency: input.currency?.map((c) => c ?? ''),
    data: input.data?.map((d) => d ?? ''),
    type: input.type,
  }
}

function mapGQLRailFareToRailFareValueObject(
  input: GetRailQuery['rail']['railLegs'][0]['fares'][0],
): RailFareValueObject {
  return {
    isRefundable: input.isRefundable ?? undefined,
    isSelectable: input.isSelectable ?? undefined,
    isWebFare: input.isWebFare ?? undefined,
    restriction: input.restriction,
    rulesAndRestrictions: input.rulesAndRestrictions
      ? input.rulesAndRestrictions.map(
          mapGQLRailFareRulesAndRestrictionsToRailFareRulesAndRestrictionEntity,
        )
      : undefined,
    segments: input.segments
      ? input.segments.map(mapGQLRailFareSegmentToRailFareSegmentEntity)
      : undefined,
    showOutOfPolicy: input.showOutOfPolicy ?? undefined,
    tier: {
      id: input.tier.id,
      name: input.tier.name ?? undefined,
    },
    appliedRailCards: input.appliedRailCards,
    total: {
      primary: input.total.primary,
      secondary: input.total.secondary,
    },
    tripId: input.tripId ?? undefined,
    permittedOriginStations: mapGQLRailFarePermittedStationListToRailPermittedStationEntityList(
      input.permittedOriginStations,
    ),
    permittedDestinationStations: mapGQLRailFarePermittedStationListToRailPermittedStationEntityList(
      input.permittedDestinationStations,
    ),
  }
}

function mapGQLRailFarePermittedStationListToRailPermittedStationEntityList(
  input: RailFarePermittedStation[],
): PermittedStationEntity[] {
  return input.map((permittedStation) => {
    return {
      code: permittedStation.code,
      description: permittedStation.description,
    }
  })
}

function mapGQLRailFareRulesAndRestrictionsToRailFareRulesAndRestrictionEntity(
  input: RailFareRulesAndRestrictions,
): RailFareRulesAndRestrictionEntity {
  return {
    id: input.id,
    penaltyFee: input.penaltyFee
      ? input.penaltyFee
          .filter((p) => !!p)
          .map((p) => {
            return {
              primary: p!.primary,
              secondary: p!.secondary,
            }
          })
      : undefined,
    rulesAndRestriction: input.rulesAndRestriction,
    type: input.type,
  }
}

function mapGQLRailFareSegmentToRailFareSegmentEntity(
  input: RailFareSegment,
): RailFareSegmentEntity {
  return {
    basisCode: input.basisCode ?? undefined,
    bookingCode: input.bookingCode,
    displayServiceClass: input.displayServiceClass ?? undefined,
    segmentId: input.segmentId,
    serviceClass: input.serviceClass,
  }
}
