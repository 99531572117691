import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { RowMobile } from './mobile'
import { RowDesktop } from './desktop'

export function RowLayout(props: LayoutProps) {
  return (
    <MediaLayout mobileSlot={<RowMobile {...props} />} desktopSlot={<RowDesktop {...props} />} />
  )
}
