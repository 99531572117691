import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { ScoreListMobile } from './mobile'
import { ScoreListDesktop } from './desktop'

export function ScoreListLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ScoreListMobile {...props} />}
      desktopSlot={<ScoreListDesktop {...props} />}
    />
  )
}
