import type { MutableRefObject } from 'react'
import { useState } from 'react'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import type { IconNames } from '@etta/ui/icon/types'
import {
  Container,
  Header,
  HeaderContent,
  Body,
  MiniPill,
  DragableAreaWrapper,
} from './draggable-bottom-sheet-styled'

import { useDraggableBottomSheet } from './use-draggable-bottom-sheet'

type Props = {
  iconName?: IconNames
  headerCenterSlot?: React.ReactNode
  contentSlot: React.ReactNode
  onSheetHeightChange?: (transitionProgressInPercent: number) => void
  onHeaderCenterSlotClick?: () => void
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>
}

export function DraggableBottomSheet({
  contentSlot,
  iconName,
  headerCenterSlot,
  onSheetHeightChange,
  onHeaderCenterSlotClick,
  scrollContainerRef,
}: Props) {
  const [scrollValue, setScrollValue] = useState<number>(0)

  scrollContainerRef?.current?.addEventListener('scroll', () => {
    setScrollValue(scrollContainerRef.current?.scrollTop ?? 0)
  })

  const {
    sheetRef,
    headerRef,
    handleTouchStart,
    handleOnDrag,
    handleTouchEnd,
    handleCloseExpand,
    isExpanded,
    handleTouchStartCollapse,
    handleOnDragCollapse,
    handleTouchEndCollapse,
  } = useDraggableBottomSheet({ onSheetHeightChange, scrollValue, scrollContainerRef })

  return (
    <Container>
      <Header ref={headerRef} isExpanded={isExpanded}>
        <Block marginLeft={21} marginRight={16} onClick={handleCloseExpand}>
          <Icon name={iconName ?? 'chevronDownPWA'} size={'normal'} />
        </Block>
        <HeaderContent onClick={onHeaderCenterSlotClick}>{headerCenterSlot}</HeaderContent>
      </Header>
      <Body
        ref={sheetRef}
        isExpanded={isExpanded}
        onTouchStart={handleTouchStartCollapse}
        onTouchMove={handleOnDragCollapse}
        onTouchEnd={handleTouchEndCollapse}>
        {!isExpanded && (
          <DragableAreaWrapper
            onTouchStart={handleTouchStart}
            onTouchMove={handleOnDrag}
            onTouchEnd={handleTouchEnd}>
            <MiniPill />
          </DragableAreaWrapper>
        )}
        {contentSlot}
      </Body>
    </Container>
  )
}
