import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const GetHelpNowWrapper = styled.div`
  margin: 16px 0 37px;
  background: #ffffff;
  border: 1px solid #e1e6ea;
  border-radius: 14px;
  overflow: hidden;
`
export const InformationContainer = styled.div`
  padding: 16px;
`
export const ButtonLink = styled.a`
  text-decoration: none;
`

export const ButtonWrapper = styled(Button)`
  margin-top: 12px;
`

export const ImageContainer = styled.div`
  position: relative;
  top: 3px;
`
