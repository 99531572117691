import type { Coords } from '@etta/modules/etta-map/core/coords'

// This is a workaround utility function to solve these issues
// - fitBounds function does not work as expected when the given boundary exceed the max zoom level.
// - Google Maps API's fitBounds with one coordinate does not accounting padding
// https://issuetracker.google.com/issues/139464498

// The idea behind this workaround is to add an additional coordinate to given coordinates
// to extend overall boundary sufficiently. So when the Google Maps does calculation for fitBounds
// the boundary will be within the maxZoom level.

// This value represents the minimum range of latitudes needed for Google Maps
// to determine map boundary. Different value should be used based on
// maximum zoom level of the map
const LATITUDE_RANGE = 0.0005

export function getGoogleMapCoordWithOffset(
  coords: Coords[],
  minimum_range: number = LATITUDE_RANGE,
): Coords[] {
  if (coords.length === 0) {
    return coords
  }
  const sortedCoord = coords.sort((a, b) => a.lat - b.lat)
  const firstCoord = sortedCoord.at(0)!
  const lastCoord = sortedCoord.at(-1)!
  const diffLat = lastCoord.lat - firstCoord.lat

  if (diffLat >= minimum_range) {
    return coords
  }

  const offset = minimum_range - diffLat

  return [...sortedCoord, { lat: firstCoord.lat - offset, lng: firstCoord.lng }]
}
