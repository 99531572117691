import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'

const i18Base = 'Trips'

export function useHeldTripsMobile() {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const { anchorRef, scrollContainerRef, headerRef } = useHeaderScroll({})
  const { t } = useTranslation()
  const updateRef = useCallback(
    (node: HTMLDivElement | null) => {
      scrollContainerRef(node)
      containerRef.current = node
    },
    [scrollContainerRef],
  )

  const headerTitle = t(i18Base + '.HeldTripsTitle')

  return {
    headerTitle,
    anchorRef,
    headerRef,
    updateRef,
  }
}
