import { useTranslation } from 'react-i18next'

import { observer } from 'mobx-react-lite'
import { DropDownList, ListTypes } from '@etta/ui/drop-down-list'
import { StarRating } from '@etta/ui/star-rating'
import { starRatingOptions } from '@fiji/constants'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { StarFilter } from '@etta/screens/hotel-result-page/hotel-filters/star-filter'

const i18nBase = 'HotelFilters.Hotel'
const options = starRatingOptions.map(({ value, name, count }) => {
  return {
    label: <StarRating variant="withBackground" rating={count} isLarge />,
    shortLabel: name,
    value: value,
  }
})

export const StarRatingFilter = observer(function StarRatingFilter() {
  const {
    hotelFiltersAction,
    hotelFiltersStore,
    hotelSearchResultsStore,
    hotelQuickFiltersStore,
    hotelQuickFiltersAction,
  } = useHotelSearchResultsContext()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()

  if (isNewHotelExposedFiltersEnabled) {
    return (
      <QuickFilterLayout
        data-tracking-id="star-rating-filter-dropdown"
        isOpen={hotelQuickFiltersStore.starRatingModal.isOpen}
        onOpen={hotelQuickFiltersStore.starRatingModal.handleOpen}
        onClose={hotelQuickFiltersStore.starRatingModal.handleClose}
        onClear={hotelFiltersAction.clearStarRatingFilter}
        onApply={hotelQuickFiltersAction.handleApplyStarRating}
        label={t(i18nBase + '.StarRating')}
        value={hotelQuickFiltersStore.starRatingValues}
        isShimmerLoading={
          hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.hotelResults
        }
        isLoading={hotelSearchResultsStore.isLoading}
        selectedNumber={hotelFiltersStore.selectedFilters.starRatings.length}
        isApplyButtonDisabled={hotelQuickFiltersStore.isStarRatingApplyButtonDisable}
        isClearButtonDisabled={hotelQuickFiltersStore.isStarRatingClearButtonDisable}>
        <StarFilter />
      </QuickFilterLayout>
    )
  }

  return (
    <DropDownList
      label={t(i18nBase + '.StarRating')}
      options={options}
      value={hotelFiltersStore.selectedFilters.starRatings}
      onChange={(newValue) => {
        if (hotelFiltersStore.selectedFilters.starRatings.length === 0 && newValue.length === 0) {
          return
        }
        hotelFiltersAction.handleSetStarRating(newValue as number[])
        hotelFiltersAction.handleApplyFilters()
      }}
      type={ListTypes.Select}
      defaultLabel={t(i18nBase + '.Any')}
      data-tracking-id="star-rating-filter-dropdown"
      withNoneOption={false}
    />
  )
})
