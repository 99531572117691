import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { TripCarServiceContentProps } from './types'
import { TripCarServiceContent } from './trip-car-service-content'

type Props = TripCarServiceContentProps & {
  isVisible: boolean
  onClose: () => void
}

export const TripCarService = observer(function TripCarService({
  isVisible,
  onClose,
  ...props
}: Props) {
  const { postBookingTripStore } = usePostBookingContext()

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose} backgroundColor="background">
      <TripCarServiceContent
        tripType={postBookingTripStore.trip.type}
        travelerName={postBookingTripStore.travelerNames?.travelerFullName}
        {...props}
        onClose={onClose}
      />
    </Modal>
  )
})
