import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useUserContext } from '@etta/modules/user'
import { Block } from '@etta/ui/block'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action'
import { AutoCancellationMessage } from '@etta/components/auto-cancellation-message'
import type { LayoutProps } from '../../types'
import { DetailsSkeleton } from '../../details-skeleton'
import { DetailsError } from '../../details-error'
import { TripCost } from '../../trip-cost'
import { DateSegment } from '../../date-segment'
import { ButtonGroup } from '../../button-group'
import { CostSummary } from '../../cost-summary'
import { EcoCheckSummary, HotelBookingFailed } from '../common'
import { UserBlock } from './user-block'
import { EcoCheckSummaryContainer } from './details-layout-mobile-styled'
import { useDetailsLayoutMobile } from './use-details-layout-mobile'
import { BookingRecord } from './booking-record'

export function DetailsLayoutMobile({
  isLoading,
  isError,
  trip,
  groupedSegments,
  failedHotelSegments,
  costSummary,
  bookingRefSlot,
  onRetry,
  onShare,
  onModify,
  onETicketsDownloading,
  tripEmissionsResult,
  areTripSegmentsEmissionsLoading,
  flightSegments,
  railSegments,
  dynamicSiteMessagesSlot,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
  printETicketReceipt,
  printItineraryContent,
  travelerName,
}: LayoutProps) {
  const { featureFlags } = useFeatureFlags()
  const {
    isUpcoming,
    isOnHold,
    handleRefetchRequest,
    handleCostSummaryModalOpen,
    isCostSummaryModalOpen,
    handleCostSummaryModalClose,
  } = useDetailsLayoutMobile({ onRetry, isLoading, trip })
  const { userStore } = useUserContext()
  const { isGuest } = userStore
  const { postBookingTripStore } = usePostBookingContext()
  const { isOnHold: isTripOnHold } = postBookingTripStore

  if (isLoading) {
    return <DetailsSkeleton />
  }

  if (isError || !trip) {
    return <DetailsError onButtonClick={handleRefetchRequest} />
  }

  return (
    <>
      <BookingRecord
        travelerName={travelerName}
        recordLocator={trip.recordLocator}
        creationTime={trip.creationTime}
        bookingRef={trip.bookingRef}
        airTicketNumbers={trip.airTickets?.map((airTicket) =>
          airTicket.ticketSegmentInfo &&
          airTicket.ticketSegmentInfo?.length > 0 &&
          airTicket.ticketNumber
            ? airTicket.ticketNumber
            : '',
        )}
        printETicketReceipt={printETicketReceipt}
      />
      <Block paddingHorizontal={16} marginTop={20}>
        <MobilityCallToAction processId={trip.processId} tripType={trip.type} />
      </Block>
      <Block marginHorizontal={20}>{dynamicSiteMessagesSlot}</Block>
      <HotelBookingFailed segments={failedHotelSegments} />
      {isTripOnHold && trip.holdRules?.autoCancellationTime && (
        <Block marginHorizontal={16}>
          <AutoCancellationMessage isHolded time={trip.holdRules.autoCancellationTime} />
        </Block>
      )}
      {isGuest && (
        <Block>
          <UserBlock
            travelerFullName={postBookingTripStore.travelerNames?.travelerFullName}
            travelerInitials={postBookingTripStore.travelerNames?.travelerInitials}
          />
        </Block>
      )}

      <TripCost
        type={trip.type}
        cost={trip.tripCost?.totalWithUnusedTicket || trip?.costSummary?.totalCost}
        onClick={handleCostSummaryModalOpen}
        cancellationTime={trip.cancellationTime}
      />
      {groupedSegments.map((group, index) => (
        <DateSegment
          key={index}
          day={group.day}
          date={group.day}
          segments={group.segments}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          allSegments={trip.segments}
          supportedActions={trip.supportedActions}
        />
      ))}
      {featureFlags.isSustainabilityEnabled && (
        <EcoCheckSummaryContainer>
          <EcoCheckSummary
            tripEmissionsResult={tripEmissionsResult}
            loading={areTripSegmentsEmissionsLoading}
            segments={trip.segments}
          />
        </EcoCheckSummaryContainer>
      )}
      <Block marginTop={13} marginBottom={50}>
        <ButtonGroup
          onShare={onShare}
          onModify={onModify}
          onETicketsDownloading={onETicketsDownloading}
          supportedActions={trip.supportedActions}
          tripStartDate={trip.rollUpStartTime}
          isUpcoming={isUpcoming}
          isMultiCityTrip={trip.isMultiCity}
          tripName={trip.name}
          isOnHold={isOnHold}
          onOpenRulesAndRestrictions={onOpenRulesAndRestrictions}
          showRulesAndRestrictions={showRulesAndRestrictions}
          printItineraryContent={printItineraryContent}
        />
      </Block>
      <CostSummary
        flightSegments={flightSegments}
        railSegments={railSegments}
        startSlot={bookingRefSlot}
        isVisible={isCostSummaryModalOpen}
        onClose={handleCostSummaryModalClose}
        costSummaryData={costSummary}
        totalWithUnusedTicket={trip.tripCost?.totalWithUnusedTicket}
      />
    </>
  )
}
