import { useRef, useState, useEffect } from 'react'

type Props = {
  isExpanded: boolean
}
export function useShowMoreWithAnimation({ isExpanded }: Props) {
  const [minHeight, setMinHeight] = useState(0)
  const expandedRef = useRef<HTMLDivElement | null>(null)
  const collapsedRef = useRef<HTMLDivElement | null>(null)
  const collapsedHeight = collapsedRef.current?.scrollHeight || 0
  const expandedHeight = expandedRef.current?.clientHeight || 0

  useEffect(() => {
    setMinHeight(isExpanded ? expandedHeight : collapsedHeight)
  }, [isExpanded, expandedHeight, collapsedHeight])

  return { minHeight, isExpanded, expandedRef, collapsedRef }
}
