import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import type { LayoutProps } from '../../types'
import { DynamicMessageContainer } from './payment-dynamic-site-messages-desktop-styled'

export function PaymentDynamicSiteMessagesDesktop({ dynamicSiteMessages }: LayoutProps) {
  return (
    <DynamicMessageContainer>
      <DynamicSiteMessages messages={dynamicSiteMessages} />
    </DynamicMessageContainer>
  )
}
