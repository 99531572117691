import { useTranslation } from 'react-i18next'
// import { TimeSlider } from '@etta/components/time-slider/time-slider'
import { Section, SectionTitle } from '../rail-filters-styled'
// import { useRailTimeFilter } from './use-rail-time-filter'

const i18Base = 'RailFilters.Rail'

// Commenting this one, because filters are not supported in train search flow for now
export function RailTimeFilter() {
  const { t } = useTranslation()

  // const {
  //   originTimeRange,
  //   destinationTimeRange,
  //   originValue,
  //   destinationValue,
  //   onDestinationChange,
  //   onOriginChange,
  // } = useRailTimeFilter()

  return (
    <Section withBorder>
      <SectionTitle>{t(i18Base + '.TimeofDay')}</SectionTitle>
      <div data-tracking-id="depart-slider">
        {/* <TimeSlider
          label={t(i18Base + '.Depart') + ':'}
          timeRange={originTimeRange}
          filterValue={originValue}
          onChange={onOriginChange}
        /> */}
      </div>
      <div data-tracking-id="arrive-slider">
        {/* <TimeSlider
          label={t(i18Base + '.Arrive') + ':'}
          timeRange={destinationTimeRange}
          filterValue={destinationValue}
          onChange={onDestinationChange}
        /> */}
      </div>
    </Section>
  )
}
