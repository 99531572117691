import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import type { AirPreferenceExcerpt, ProfileOptions } from '../../types'
import { UserInfoForm } from '../../user-info-form'

export type Props = {
  onClose: () => void
  onSubmit: (data: PersonalInfo) => void
  personalInfo: PersonalInfo
  primaryTraveler?: PurchaseInfoTravelerValueObject
  isOpen: boolean
  isAir?: boolean
  travelerOptions?: TravelerOptions | null
  isPostPurchase?: boolean
  isKtnHidden?: boolean
  isRedressNumber?: boolean
  preferenceExcerpt?: AirPreferenceExcerpt
  profileOptions: ProfileOptions
  isRail?: boolean
}

const i18nBase = 'UserInfo.'

export function PurchaseUserInfoMobile({ isOpen, onClose, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Body>
        <Modal.Header isMobile>
          <Header leftSlot={<Header.CloseButton onClick={onClose} />} withBorderBottom>
            <Header.Title title={t(i18nBase + 'EditTravelerInfo')} align="left" />
          </Header>
        </Modal.Header>
        <UserInfoForm {...props} />
      </Modal.Body>
    </Modal>
  )
}
