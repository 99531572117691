import { useTranslation } from 'react-i18next'
import type { CarLocations } from '@fiji/types'
import { Text } from '@etta/ui/text'
import { CarRentalDetailsMap } from '@etta/components/car-rental-details-map/car-rental-details-map'
import {
  Container,
  Content,
  MapWrapper,
  Location,
  LocationItem,
} from './car-rental-details-location-styled'

type Props = {
  locations: CarLocations | null
  onAddressClick: () => void
}

const i18Base = 'CarRentalModal.'

export function CarRentalDetailsLocation({ locations, onAddressClick }: Props) {
  const { t } = useTranslation()

  if (!locations) {
    return null
  }

  const ariaLabel = t(i18Base + 'AriaLabel', {
    pickupDate: locations.pickUp.date,
    from: locations.pickUp.location,
    dropDate: locations.dropOff.date,
    to: locations.dropOff.location,
  })

  return (
    <Container aria-label={ariaLabel}>
      <Content>
        <Location>
          <LocationItem>
            <Text
              variant="captionStrongCaps"
              color="bodyText1"
              role="heading"
              aria-level={5}
              aria-label={t(i18Base + 'PickUpTitle')}>
              {t(i18Base + 'PickUpTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text aria-label={locations.pickUp.date} variant="subHeadStrong" color="mainText">
              {locations.pickUp.date}
            </Text>
          </LocationItem>
          <Text aria-label={locations.pickUp.location} variant="footnoteMedium" color="bodyText">
            {locations.pickUp.location}
          </Text>
        </Location>
        <Location>
          <LocationItem>
            <Text
              aria-label={t(i18Base + 'DropOffTitle')}
              variant="captionStrongCaps"
              color="bodyText1"
              role="heading"
              aria-level={5}>
              {t(i18Base + 'DropOffTitle')}
            </Text>
          </LocationItem>
          <LocationItem>
            <Text aria-label={locations.dropOff.date} variant="subHeadStrong" color="mainText">
              {locations.dropOff.date}
            </Text>
          </LocationItem>
          <Text aria-label={locations.dropOff.location} variant="footnoteMedium" color="bodyText">
            {locations.dropOff.location}
          </Text>
        </Location>
      </Content>
      {locations.geocode && (
        <MapWrapper
          role="button"
          aria-label={t('HotelResultPage.ViewMapButtonAreaLabel')}
          data-tracking-id="car-rental-trip-segment-map-component"
          onClick={onAddressClick}>
          <CarRentalDetailsMap
            fullHeight
            isTopRounded={false}
            isBottomRounded={false}
            carCoords={locations.geocode}
          />
        </MapWrapper>
      )}
    </Container>
  )
}
