import { Action, Inject } from '@etta/di'
import type { EmergencyContactValueObject } from '@etta/modules/user/core/value-objects/emergency-contact.value-object'
import { ProfileActions } from '@etta/modules/settings/interface/actions/profile.actions'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { BaseSettingsStore } from '@etta/modules/settings/interface/stores/base.store'
import { EmergencyContactStore } from '../stores/emergency-contact.store'
import { BaseSettingsService } from '../services/base.service'
import type { AddressFields } from '../../core/enums/address-fields'

@Action()
export class EmergencySettingsActions {
  constructor(
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly emergencyContactStore: EmergencyContactStore,
    @Inject()
    private readonly profileActions: ProfileActions,
    @Inject()
    readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  setEmergencyContact(data: EmergencyContactValueObject) {
    this.emergencyContactStore.setEmergencyContact(data)
  }

  handleOpenModal() {
    const { emergencyContact } = this.profileActions.getProfile()
    this.emergencyContactStore.setEmergencyContact(emergencyContact)
    this.emergencyContactStore.editToggle.isOpen = true

    this.emergencyContactStore.setIsSaveButtonDisabled(true)

    const additionalInformation =
      this.userProfileStore.userProfile?.emergencyContact.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.emergencyContactStore.setAdditionalInfoValidator(validator)
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.emergencyContactStore.setIsSaveButtonDisabled(false)
      return this.emergencyContactStore.setAdditionalInfoValue(key, value)
    }
  }

  handleFieldChange<
    T extends keyof Omit<EmergencyContactValueObject, 'primaryPhone' | 'alternatePhone' | 'address'>
  >(field: T) {
    return (value: EmergencyContactValueObject[T]) => {
      this.emergencyContactStore.setIsSaveButtonDisabled(false)
      return this.emergencyContactStore.setEmergencyContactValue(field, value)
    }
  }

  handleDataLoad() {
    const { emergencyContact } = this.profileActions.getProfile()
    this.setEmergencyContact({
      ...emergencyContact,
    })
  }

  handlePhoneChange(
    type: 'primaryPhone' | 'alternatePhone',
    field: 'CountryCode' | 'Number',
    value: string,
  ) {
    this.emergencyContactStore.setIsSaveButtonDisabled(false)
    return this.emergencyContactStore.setEmergencyContactPhoneInputValue(type, field, value)
  }

  handleAddressChange(field: AddressFields, value: string) {
    this.emergencyContactStore.setIsSaveButtonDisabled(false)
    return this.emergencyContactStore.setEmergencyContactAddressInputValue(field, value)
  }

  async handleEmergencyContactSave() {
    const validatorCheck = await this.emergencyContactStore.emergencyContactValidator.submit()
    const additionalValidatorCheck = await this.emergencyContactStore.additionalInformationValidator.submit()
    if (!validatorCheck.isValid || !additionalValidatorCheck.isValid) {
      return
    }

    const {
      values: {
        name,
        relationship,
        email,
        primaryPhoneCountryCode,
        primaryPhoneNumber,
        alternatePhoneCountryCode,
        alternatePhoneNumber,
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
    } = this.emergencyContactStore.emergencyContactValidator

    const input = {
      email,
      name,
      address: {
        city,
        countryCode,
        stateCode,
        mailStop,
        postalCode,
        street1,
        street2,
      },
      relationship,
      primaryPhone: {
        countryCode: primaryPhoneCountryCode,
        number: primaryPhoneNumber,
      },
      alternatePhone: {
        countryCode: alternatePhoneCountryCode,
        number: alternatePhoneNumber,
      },
    }

    const updateAdditionalInformationSuccess: boolean = await this.userProfileService.saveUserProfileEmergencyContact(
      this.emergencyContactStore.additionalInformationValidator.values,
    )
    this.userProfileService.fetchUserProfile()

    if (updateAdditionalInformationSuccess) {
      this.baseSettingsService.saveUser({
        mode: 'emergency-contact',
        input,
        handleClose: () => {
          this.emergencyContactStore.setIsSaveButtonDisabled(true)
          this.emergencyContactStore.editToggle.handleClose()
        },
      })
    } else {
      this.baseSettingsStore.setErrorState()
    }
  }
}
