import { Service, Inject } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { deleteTimezone, parse } from '@fiji/utils/dates'
import { UserProfileStore } from '../stores/user-profile.store'
import { UserProfileAdapter } from '../../infra/user-profile/user-profile.adapter'
import { UserProfileFieldType } from '../../core/enums/user-profile-field-type.enum'
import type { UserProfileUnionEntity } from '../../core/entities/user-profile.entity'
import type { UserProfileAdditionalInfoInputValueObject } from '../../core/value-objects/user-profile-additional-info-input.value-object'

@Service()
export class UserProfileService {
  constructor(
    @Inject() private userProfileAdapter: UserProfileAdapter,
    @Inject() private userProfileStore: UserProfileStore,
    @Inject() private cookieStore: CookieStore,
  ) {}

  async getUserProfileWithAdditionalInfo() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.userProfileStore.setIsLoading(true)
    this.userProfileStore.setIsError(false)
    this.userProfileStore.setUserProfile(null)

    const result = await this.userProfileAdapter.getUserProfile()

    result.match({
      Ok: (userProfile) => {
        this.userProfileStore.setUserProfile(userProfile)
        this.userProfileStore.setIsLoading(false)
      },
      Err: () => {
        this.userProfileStore.setIsError(true)
        this.userProfileStore.setIsLoading(false)
      },
    })
  }

  async fetchUserProfile(shouldIncludeOpenEnrollmentRegistrationCustomFields?: boolean) {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    const result = await this.userProfileAdapter.getUserProfile(
      shouldIncludeOpenEnrollmentRegistrationCustomFields,
    )

    result.match({
      Ok: (userProfile) => {
        this.userProfileStore.setUserProfile(userProfile)
      },
      Err: () => {},
    })
  }

  async saveUserProfilePersonalInfo(value: Record<string, any>): Promise<boolean> {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.personalInformation.additionalInformation || [],
    )
    if (!input.length) {
      return true
    }
    const result = await this.userProfileAdapter.updateUserProfilePersonalInformation(input)
    return result.isOk()
  }

  async saveUserProfileHomeAddress(value: Record<string, any>): Promise<boolean> {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.homeAddress.additionalInformation || [],
    )
    if (!input.length) {
      return true
    }
    const result = await this.userProfileAdapter.updateUserProfileHomeAddress(input)
    return result.isOk()
  }

  async saveUserProfileEmergencyContact(value: Record<string, any>): Promise<boolean> {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.emergencyContact.additionalInformation || [],
    )
    if (!input.length) {
      return true
    }
    const result = await this.userProfileAdapter.updateUserProfileEmergencyContact(input)
    return result.isOk()
  }

  async saveUserProfileBusinessAddress(value: Record<string, any>): Promise<boolean> {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.businessAddress.additionalInformation || [],
    )
    if (!input.length) {
      return true
    }
    const result = await this.userProfileAdapter.updateUserProfileBusinessAddress(input)
    return result.isOk()
  }

  async saveUserProfileTravelPreferences(value: Record<string, any>) {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.travelPreferences.additionalInformation || [],
    )

    return this.userProfileAdapter.updateUserProfileTravelPreferences(input)
  }

  async saveUserProfileEmployeeInformation(value: Record<string, any>) {
    const input = this.getUserProfileInputByFieldSettings(
      value,
      this.userProfileStore.userProfile?.employeeInformation.additionalInformation || [],
    )
    if (!input.length) {
      return true
    }
    const result = await this.userProfileAdapter.updateUserProfileEmployeeInformation(input)
    return result.isOk()
  }

  private getAdditionalInformationFieldById(id: number): UserProfileUnionEntity | undefined {
    const additionalInformations = this.userProfileStore.userProfile
      ? [
          ...this.userProfileStore.userProfile.businessAddress.additionalInformation,
          ...this.userProfileStore.userProfile.emergencyContact.additionalInformation,
          ...this.userProfileStore.userProfile.employeeInformation.additionalInformation,
          ...this.userProfileStore.userProfile.homeAddress.additionalInformation,
          ...this.userProfileStore.userProfile.personalInformation.additionalInformation,
          ...this.userProfileStore.userProfile.travelPreferences.additionalInformation,
        ]
      : []

    return additionalInformations.find((info) => info.id === id)
  }

  private getUserProfileInputByFieldSettings(
    value: Record<string, any>,
    items: UserProfileUnionEntity[],
  ) {
    return Object.entries(value)
      .filter(([_, val]) => {
        return val !== null && val !== undefined && !Number.isNaN(val)
      })
      .filter(([key, valueToUpdate]) => {
        const item = items.find(({ id }) => id === Number(key))
        // Manage empty string value only for text field type
        return (
          item &&
          (valueToUpdate !== '' ||
            (valueToUpdate === '' && item.type === UserProfileFieldType.Text))
        )
      })
      .map<UserProfileAdditionalInfoInputValueObject>(([key, value]) =>
        this.mapToInputByFieldType(key, value),
      )
  }

  private mapToInputByFieldType(
    key: string,
    value: any,
  ): UserProfileAdditionalInfoInputValueObject {
    const fieldId = Number(key)
    const fieldType =
      this.getAdditionalInformationFieldById(fieldId)?.type || UserProfileFieldType.Unspecified

    switch (fieldType) {
      case UserProfileFieldType.CheckBox:
        return {
          fieldId,
          fieldType,
          value: Boolean(value),
        }
      case UserProfileFieldType.Number:
        return {
          fieldId,
          fieldType,
          value: Number(value),
        }
      case UserProfileFieldType.DateTime:
        return {
          fieldId,
          fieldType,
          value: this.getDateFieldInputValue(value),
        }
      case UserProfileFieldType.List:
      case UserProfileFieldType.Text:
      case UserProfileFieldType.Unspecified:
        return {
          fieldId,
          fieldType,
          value: String(value),
        }
    }
  }

  private getDateFieldInputValue(value: Date | string): string {
    function getUTCDateFromLocalDate(date: Date) {
      const MILLISECONDS_IN_SECOND = 1000
      const SECONDS_IN_MINUTE = 60

      const localOffset = date.getTimezoneOffset() * MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE
      date.setTime(date.getTime() - localOffset)
      return date
    }

    if (value instanceof Date) {
      return deleteTimezone(getUTCDateFromLocalDate(value).toISOString())
    }

    return deleteTimezone(
      getUTCDateFromLocalDate(parse(value, 'hh:mm b', new Date())).toISOString(),
    )
  }
}
