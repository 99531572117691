import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { AmenitiesMobile } from './mobile'
import { AmenitiesDesktop } from './desktop'

export function AmenitiesLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()
  return (
    <MediaLayout
      mobileSlot={<AmenitiesMobile {...props} />}
      desktopSlot={<AmenitiesDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
