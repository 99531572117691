import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { Header } from '@etta/ui/header'
import { Screen } from '@etta/ui/screen'
import { CustomFocusedInput, DateTimePicker } from '@etta/ui/date-time-picker'
import { Button } from '@etta/ui/button'
import { FloatingActionButton } from '@etta/ui/floating-action-button'
import { addDaysToDate } from '@fiji/utils/dates/dates-calculations'
import type { PlainTime as Time } from '@fiji/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface'
import { MobilityItineraryDialog } from '../mobility-itinerary-dialog'
import { Border, Footer } from './mobility-select-date-time-styled'

type Props = {
  date?: Date
  time?: Time
  focusedInput: CustomFocusedInput
  changeFocusedInput: (value: CustomFocusedInput) => void
  onBack: () => void
  onContinue: () => void
  onDateSelect: (date?: Date) => void
  onTimeSelect: (date?: Time) => void
}
const i18Base = 'Mobility.SelectLocationModal'

export function MobilitySelectDateTime({
  date,
  time,
  onBack,
  onContinue,
  focusedInput,
  changeFocusedInput,
  onDateSelect,
  onTimeSelect,
}: Props) {
  const { configurationStore } = useRideHailSearchContext()
  const { t } = useTranslation()
  const defaultTime: Time = useMemo(() => ({ hours: 12, minutes: 0 }), [])
  const [isItineraryOpen, setIsItineraryOpen] = useState<boolean>(false)
  const [isShowItineraryButton, setIsShowItineraryButton] = useState<boolean>(true)
  const isDateFocus = focusedInput === CustomFocusedInput.date
  const dataTrackingIdSubmitButton = useMemo(
    () => (isDateFocus ? 'mobility-continue-button' : 'mobility-search-button'),
    [isDateFocus],
  )
  const {
    featureFlags: { isMobilityOnDemandRidesEnabled },
  } = useFeatureFlags()

  const handleClickContinue = useCallback(() => {
    onTimeSelect(defaultTime)
    if (focusedInput === CustomFocusedInput.time) {
      setIsShowItineraryButton(false)
    }
    onContinue()
  }, [defaultTime, onContinue, onTimeSelect, focusedInput])

  const handleItineraryButtonClick = useCallback(() => {
    setIsItineraryOpen(!isItineraryOpen)
  }, [isItineraryOpen])

  const handleOnBack = useCallback(() => {
    setIsShowItineraryButton(false)
    onBack()
  }, [onBack])

  const continueButtonEnabled = useMemo(() => {
    if (CustomFocusedInput.date === focusedInput) {
      return true
    }

    if (time === undefined) {
      return true
    }

    return !!date
  }, [focusedInput, time, date])

  return (
    <Screen>
      <Screen.Header>
        <Header
          layout="sub-title"
          backgroundColor="white"
          leftSlot={<Header.BackButton color="mainText" onClick={handleOnBack} />}>
          <Header.Title title={t(`${i18Base}.SelectDateAndTime`)} align="left" />
        </Header>
        <Border />
      </Screen.Header>
      <Screen.Container>
        <DateTimePicker
          isOpen
          forceScreenType={ScreenType.Mobile}
          isReturnDate
          numberOfMonths={3}
          date={date}
          time={time}
          minDate={new Date()}
          maxDate={addDaysToDate(new Date(), configurationStore.advanceBookingInDays)}
          dateTitle={t(`${i18Base}.SelectDate`)}
          timeTitle={t(`${i18Base}.SelectTime`)}
          focusedInput={focusedInput}
          changeFocusedInput={changeFocusedInput}
          onDateSelect={onDateSelect}
          onTimeSelect={onTimeSelect}
          defaultTime={defaultTime}
          shouldShowNowToggle={isMobilityOnDemandRidesEnabled}
        />
        {isShowItineraryButton && (
          <FloatingActionButton
            iconName={isItineraryOpen ? 'closePWA' : 'navTripsPWA'}
            iconColor={'white'}
            onClick={handleItineraryButtonClick}
            style={{
              position: 'absolute',
              right: '20px',
              bottom: '100px',
              zIndex: 101,
            }}
          />
        )}
        <MobilityItineraryDialog
          isOpen={isItineraryOpen}
          onClose={() => setIsItineraryOpen(false)}
        />
      </Screen.Container>
      <Screen.Footer withBorder backgroundColor="white">
        <Footer>
          <Button
            fullWidth
            onClick={handleClickContinue}
            btnType="primary"
            disabled={!continueButtonEnabled}
            data-tracking-id={dataTrackingIdSubmitButton}>
            {isDateFocus ? t(`${i18Base}.Continue`) : t(`${i18Base}.Search`)}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
