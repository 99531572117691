import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { screenSize } from '@fiji/style/screen-sizes'
import { DialogWrapper, Title, Divider, DialogText } from './disabled-geo-dialog-styled'

export type DisabledGeoDialogProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export function DisabledGeoDialog(props: DisabledGeoDialogProps) {
  const { isOpen, setIsOpen } = props

  const { t } = useTranslation()

  const i18nPath = 'SearchResults.CurrentLocation.LocationDisabledDialog.'

  return (
    <DialogWrapper
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      customSize={[320, screenSize.maxMobile]}>
      <Icon name="locationDisabledPWA" size="large" />
      <Title>{t(i18nPath + 'Title')}</Title>
      <Divider />
      <DialogText>{t(i18nPath + 'Description')}</DialogText>
      <Button
        onClick={() => {
          setIsOpen(false)
        }}>
        {t(i18nPath + 'ButtonTitle')}
      </Button>
    </DialogWrapper>
  )
}
