import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { CarSegment } from '../../../../types'
import type { SegmentDetails } from '../../types'
import { getCarRentals } from './get-car-rentals'

type Args = {
  carDetails: SegmentDetails['carDetails']
}

const i18nBase = 'PostBooking.TripDetails'

export function useCar({ carDetails }: Args) {
  const { t } = useTranslation()
  const { segments, creditCard } = carDetails

  const isPayment = Boolean(creditCard)

  const getTranslation = useCallback(
    (label: string) => {
      return t(`${i18nBase}.${label}`)
    },
    [t],
  )

  const rentals = useMemo(() => {
    return getCarRentals(segments, getTranslation, isPayment)
  }, [segments, isPayment, getTranslation])

  if (!carDetails.segments?.length) {
    return
  }

  const car: CarSegment = {
    details: {
      title: t(`${i18nBase}.CarRental`),
    },
    rentals,
  }

  return car
}
