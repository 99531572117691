import { ExpandedPreferences } from '../../expanded-preferences'
import { Header } from '../../header'
import type { Props } from '../types'
import { PreferenceBoxContainer } from './preference-block-desktop-styled'

export function PreferenceBlockDesktop({
  segmentState,
  isPreferencesEmpty,
  preferenceType,
  onEditPreference,
  areAllPreferencesUnavailable,
  handleClose,
  filteredPreferences,
  availableToSelect,
}: Props) {
  return (
    <PreferenceBoxContainer isError={false}>
      <Header
        segmentState={segmentState}
        isEmpty={isPreferencesEmpty}
        type={preferenceType}
        onEdit={onEditPreference}
        areAllPreferencesUnavailable={areAllPreferencesUnavailable}
      />
      <ExpandedPreferences
        onClick={handleClose}
        preferences={filteredPreferences}
        type={preferenceType}
        areAllPreferencesUnavailable={areAllPreferencesUnavailable}
        availableToSelect={availableToSelect}
        withChevron={false}
        isPreferredInOneLine
      />
    </PreferenceBoxContainer>
  )
}
