import * as R from 'ramda'
import { format } from 'date-fns'
import { parseCustomUuid } from '@fiji/hooks/use-custom-fields/use-computed-custom-fields/parse-custom-uuid'
import { generateCustomUuid } from '@fiji/hooks/use-custom-fields/use-computed-custom-fields/generate-custom-uuid'
import { CustomFieldPrefix } from '@fiji/hooks/use-custom-fields/use-computed-custom-fields/types'
import type { StoredCustomFieldBooking } from '@fiji/hooks/use-custom-fields/get-stored-custom-fields-booking-list'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'

function omitCustomUuid(customField: StoredCustomFieldBooking) {
  return R.omit(['customUuid'], customField)
}

export function formatCustomFields(fields: StoredCustomFieldBooking[]) {
  const result: CustomFieldBookingValueObject[] = []
  const dateTimeFieldMap: Record<string, Record<CustomFieldPrefix, StoredCustomFieldBooking>> = {}

  fields.forEach((field) => {
    const { prefix, itemIndex, name, parentIndex } = parseCustomUuid(field.customUuid)

    if (!prefix) {
      result.push({
        ...omitCustomUuid(field),
        value: String(field.value),
      })
      return
    }

    const key = generateCustomUuid({ itemIndex, name, parentIndex })

    dateTimeFieldMap[key] = dateTimeFieldMap[key] || {}
    dateTimeFieldMap[key][prefix] = field
  })

  Object.keys(dateTimeFieldMap).forEach((customUuid) => {
    const {
      [CustomFieldPrefix.DayYearMonth]: rawDayYearMonth,
      [CustomFieldPrefix.HourMinute]: rawHourminute,
      [CustomFieldPrefix.Timezone]: rawTimezone,
    } = dateTimeFieldMap[customUuid]
    const field = rawDayYearMonth || rawHourminute || rawTimezone
    const hourMinute = rawHourminute?.value || '00:00 AM'
    let dayYearMonth = '01 01 1970'
    let timezone: string | number = 0

    if (rawDayYearMonth) {
      dayYearMonth = format(new Date(rawDayYearMonth.value), 'MM d y')
    }

    if (rawTimezone) {
      timezone = parseInt(String(rawTimezone.value).split(' ')[1])
    }

    timezone =
      timezone >= 0
        ? `+${String(timezone).padStart(2, '0')}:00`
        : `-${String(Math.abs(timezone)).padStart(2, '0')}:00`

    result.push({
      ...omitCustomUuid(field),
      value: new Date(`${dayYearMonth} ${hourMinute} ${timezone}`).toISOString(),
    })
  })

  return result
}
