import { Inject, Service } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { RailQueryParamsStore } from '../stores/rail-query-param.store'

@Service()
export class RailQueryParamsService {
  constructor(
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly historyStore: HistoryStore,
    @Inject()
    private readonly railQueryParamsStore: RailQueryParamsStore,
  ) {}

  navigateTo(route: string, params?: Partial<RailSearchQueryType>) {
    const nextPath = this.railQueryParamsStore.railQueryParams.getNextPath({
      route,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  appendQueryParams(params: Partial<RailSearchQueryType>) {
    const nextPath = this.railQueryParamsStore.railQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: this.historyStore.search,
      newQueryParams: params,
    })

    this.historyService.replace(nextPath)
  }
}
