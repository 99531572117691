import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { Icon } from '@etta/ui/icon'
import { LazyContent } from '@etta/ui/lazy-content'
import { ErrorContainer } from '@etta/ui/error-container'
import { Button } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Breadcrumbs } from '../breadcrumbs'
import type { MultiLayerCostAllocationProps } from '../../types'
import { Option } from '../../option'
import {
  TextField,
  Footer,
  HeaderContainer,
  OptionContainer,
} from './select-allocation-content-styled'

export const SelectAllocationContent = observer(function ({
  data,
  isDesktop,
}: {
  data: MultiLayerCostAllocationProps
  isDesktop: boolean
}) {
  const { t } = useTranslation()
  const i18nBase = 'PreSearchCostAllocation'
  const {
    isError,
    isLoading,
    costAllocationData,
    selectedCostAllocation,
    searchLabel,
    parentPath,
    handleCancel,
    handleAllocationSelect,
    handleBack,
    handleSubmit,
    handleLayerSearch,
    handleRefetch,
    handleClearSearch,
  } = data

  return (
    <>
      <Modal.Header isMobile>
        <HeaderContainer>
          <div onClick={handleBack}>
            <Icon name="arrowBackLargePWA" size="medium" />
          </div>
          <Block marginLeft={10}>
            <Text variant={isDesktop ? 'title3' : 'headline'}>{t(i18nBase + '.Title')}</Text>
          </Block>
        </HeaderContainer>
        <TextField
          type="search"
          size="small-font"
          value={searchLabel}
          onChange={handleLayerSearch}
          onClear={handleClearSearch}
          placeholder={t(i18nBase + '.SearchPlaceholder')}
        />
      </Modal.Header>

      <Modal.Body>
        <Swap
          is={!!(costAllocationData && costAllocationData.length === 0 && !isError && !isLoading)}
          isSlot={
            <Block marginVertical={20} marginHorizontal={isDesktop ? 36 : 15}>
              <Text variant="subHeadMedium">{t(i18nBase + '.NoResults')}</Text>
            </Block>
          }>
          <Swap
            is={isError}
            isSlot={
              <ErrorContainer onReload={handleRefetch} title={t(i18nBase + '.ErrorMessage')} />
            }>
            <LazyContent isLoading={isLoading}>
              <OptionContainer>
                <Breadcrumbs allocationsList={parentPath} />
                {costAllocationData
                  ?.filter((allocation) => allocation.code !== '-1')
                  .map((allocation) => (
                    <Option
                      allocation={allocation}
                      key={allocation.allocationId}
                      onChange={() => handleAllocationSelect(allocation)}
                      selectedAllocationId={selectedCostAllocation?.allocationId}
                    />
                  ))}
              </OptionContainer>
            </LazyContent>
          </Swap>
        </Swap>
      </Modal.Body>
      <Modal.Footer>
        <Footer>
          <Button onClick={handleCancel} variant="text">
            {t(i18nBase + '.Cancel')}
          </Button>
          <Button onClick={handleSubmit} disabled={!selectedCostAllocation}>
            {t(i18nBase + '.Continue')}
          </Button>
        </Footer>
      </Modal.Footer>
    </>
  )
})
