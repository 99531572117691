import type { ReactNode } from 'react'
import { useCallback } from 'react'
import type { TextColor } from '@etta/ui/text/types'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { LinkWrapper } from './link-styled'
import type { MobileLinkSize } from './types'

type Props = {
  className?: string
  href?: string
  size?: MobileLinkSize
  target?: string
  onClick?: () => void
  isDisabled?: boolean
  disabledLinkColor?: TextColor
  children?: ReactNode
  'data-tracking-id'?: string
  'aria-label'?: string
  tabIndex?: number
  rel?: string
}

export function Link({
  className,
  size = 'normal',
  // eslint-disable-next-line no-script-url
  href = 'javascript:;',
  target,
  children,
  isDisabled,
  disabledLinkColor = 'borderDark',
  onClick,
  'data-tracking-id': dataTrackingId,
  'aria-label': ariaLabel,
  tabIndex = 0,
  rel,
}: Props) {
  const handleClick = useCallback(() => {
    if (!onClick || isDisabled) {
      return
    }
    onClick()

    return false
  }, [onClick, isDisabled])

  const { handleKeyDown } = useA11yKeydown(handleClick)

  return (
    <LinkWrapper
      tabIndex={isDisabled ? -1 : tabIndex}
      className={className}
      isDisabled={isDisabled}
      disabledLinkColor={disabledLinkColor}
      size={size}
      href={href}
      target={target}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      aria-label={ariaLabel}
      data-tracking-id={dataTrackingId}
      rel={rel}>
      {children}
    </LinkWrapper>
  )
}
