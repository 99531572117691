import { Inject, Service } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { HotelSearchQueryType } from '@fiji/hooks/search-queries/use-hotel-search-query/types'
import { HotelQueryStore } from '../stores/hotel-search-query.store'

@Service()
export class HotelSearchFormQueryService {
  constructor(
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  navigateTo(route: string, params?: Partial<HotelSearchQueryType>) {
    const nextPath = this.hotelQueryStore.hotelSearchQueryParams.getNextPath({
      route,
      search: this.historyStore.search,
      newQueryParams: { ...params, isSearchByNewArea: false },
    })
    this.historyService.push(nextPath)
  }

  appendQueryParams(params: Partial<HotelSearchQueryType>) {
    const nextPath = this.hotelQueryStore.hotelSearchQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: this.historyStore.search,
      newQueryParams: params,
    })

    this.historyService.replace(nextPath)
  }
}
