import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type {
  FlightLegValueObject,
  SeatMapValueObject,
  SeatMapRowSeatValueObject,
  SelectedSeatValueObject,
  SeatMapRowValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import type { ViewStateStatus } from '@etta/modules/seat-map/core/types'
import { SeatMapPaginationStore } from './seat-map-pagination.store'
import { getSelectedSeatFeature } from './get-selected-seat-feature'
import { getBookedSeatsFromFlightLegs } from './get-booked-seats-from-flight-legs'

@Store()
export class SeatMapStore {
  constructor(@Inject() private readonly seatMapPaginationStore: SeatMapPaginationStore) {}

  viewSateModalStatus: ViewStateStatus = 'hidden'

  isLoading = false

  isError = false

  isReadonly = false

  flightLegs: FlightLegValueObject[] = []

  legendsToggle = new Toggle()

  seatPopup = new Toggle()

  modalToggle = new Toggle()

  bookedSeats: SelectedSeatValueObject[] = []

  setFlightLegs(legs: FlightLegValueObject[]) {
    this.flightLegs = legs

    const paginationLength = legs.map(({ segments }) => ({ segmentSize: segments.length }))

    this.setBookedSeatsFromLegs(legs)

    this.seatMapPaginationStore.setPaginationLength(paginationLength)
  }

  private setBookedSeatsFromLegs(legs: FlightLegValueObject[]) {
    const bookedSeats = getBookedSeatsFromFlightLegs(legs)
    this.setBookedSeats(bookedSeats)
  }

  setSeatMap(seatMap: SeatMapValueObject) {
    this.selectedSegment.seatMap = seatMap
  }

  setIsSeatMapLoading(value: boolean) {
    this.seatMap.isLoading = value
  }

  setIsSeatMapError(value: boolean) {
    this.seatMap.isError = value
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setViewSateModalStatus(value: ViewStateStatus) {
    this.viewSateModalStatus = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setSeatRow(seatRow: SeatMapRowSeatValueObject) {
    this.seatMap.seatRow = seatRow
  }

  setSeatRows(rows: SeatMapRowValueObject[]) {
    this.seatMap.rows = rows
  }

  setSelectedSeat(seatRow: SeatMapRowSeatValueObject, cabinClass: string) {
    this.seatMap.selectedSeat = getSelectedSeatFeature({
      seat: seatRow,
      segmentId: this.selectedSegment.id,
      cabinClass,
      isReadonly: this.isReadonly,
    })
  }

  setBookedSeats(bookedSeats: SelectedSeatValueObject[]) {
    this.bookedSeats = bookedSeats
  }

  setIsReadonly(value: boolean) {
    this.isReadonly = value
  }

  get seatMap() {
    return this.selectedSegment.seatMap
  }

  get selectedSegment() {
    const flightLeg = this.selectedFlightLeg
    return flightLeg.segments[this.seatMapPaginationStore.selectedSegmentIndex]
  }

  get selectedFlightLeg() {
    return this.flightLegs[this.seatMapPaginationStore.selectedLegIndex]
  }

  get isSeatBooked() {
    const bookedSeat = this.bookedSeat
    const selectedSeat = this.seatMap.selectedSeat

    if (bookedSeat?.segmentId !== selectedSeat?.segmentId) {
      return false
    }

    if (bookedSeat?.number === selectedSeat?.number) {
      return true
    }

    return false
  }

  get bookedSeat() {
    return this.bookedSeats.find(({ segmentId }) => segmentId === this.selectedSegment.id)
  }

  dropSeat() {
    this.seatMap.seatRow = null
    this.seatMap.selectedSeat = null
  }

  dropStore() {
    this.flightLegs = []
    this.bookedSeats = []
    this.isLoading = false
    this.isError = false
    this.isReadonly = false
  }
}
