import { Action, Container, Inject } from '@etta/di'
import { PostBookingSegmentTypeCheckService } from '../services/post-booking-segment-type-check.service'
import type {
  SegmentPostBookingValueObject,
  GroupedSegmentValueObject,
  HotelGroupedSegmentValueObject,
  HotelPostBookingValueObject,
  CarRentalGroupedSegmentValueObject,
  CarRentalPostBookingValueObject,
  FlightGroupedSegmentValueObject,
  FlightPostBookingValueObject,
  TrainGroupedSegmentValueObject,
  TrainPostBookingValueObject,
} from '../../core/value-objects'

@Action()
export class SegmentTypeCheckActions {
  constructor(
    @Inject()
    private readonly segmentTypeCheckService: PostBookingSegmentTypeCheckService,
  ) {}

  isHotelSegment(segment: SegmentPostBookingValueObject): segment is HotelPostBookingValueObject {
    return this.segmentTypeCheckService.isHotelSegment(segment)
  }

  isGroupedHotel(segment: GroupedSegmentValueObject): segment is HotelGroupedSegmentValueObject {
    return this.segmentTypeCheckService.isGroupedHotel(segment)
  }

  isCarRentalSegment(
    segment: SegmentPostBookingValueObject,
  ): segment is CarRentalPostBookingValueObject {
    return this.segmentTypeCheckService.isCarRentalSegment(segment)
  }

  isGroupedCarRental(
    segment: GroupedSegmentValueObject,
  ): segment is CarRentalGroupedSegmentValueObject {
    return this.segmentTypeCheckService.isGroupedCarRental(segment)
  }

  isFlightSegment(segment: SegmentPostBookingValueObject): segment is FlightPostBookingValueObject {
    return this.segmentTypeCheckService.isFlightSegment(segment)
  }

  isGroupedFlight(segment: GroupedSegmentValueObject): segment is FlightGroupedSegmentValueObject {
    return this.segmentTypeCheckService.isGroupedFlight(segment)
  }

  isTrainSegment(segment: SegmentPostBookingValueObject): segment is TrainPostBookingValueObject {
    return this.segmentTypeCheckService.isTrainSegment(segment)
  }

  isGroupedTrain(segment: GroupedSegmentValueObject): segment is TrainGroupedSegmentValueObject {
    return this.segmentTypeCheckService.isGroupedTrain(segment)
  }

  static getInstance() {
    return Container.get(SegmentTypeCheckActions)
  }
}
