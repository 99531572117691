import { IconButton } from '@etta/ui/icon-button'
import { Block } from '@etta/ui/block'
import type { IconNames } from '@etta/ui/icon/types'

type Props = {
  iconName: IconNames
  isDisabled: boolean
  onClick: () => void
  'aria-label': string
  dataTrackingId: string
}

export function ArrowButton({
  iconName,
  isDisabled,
  onClick,
  'aria-label': ariaLabel,
  dataTrackingId,
}: Props) {
  return (
    <Block paddingHorizontal={4} data-tracking-id={dataTrackingId}>
      <IconButton
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        onClick={onClick}
        icon={iconName}
        color={isDisabled ? 'borderDark' : 'primary1'}
        size="normal"
      />
    </Block>
  )
}
