import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import type { Props } from '../../types'
import {
  Footer,
  Body,
  ConfirmButton,
  CancelButton,
  DescriptionSecondPartWrapper,
  LinkWrapper,
} from './traveler-acknowlege-confirmation-modal-desktop-styled'

const i18Base = 'TravelerAcknowledgeModal.'
export function TravelerAcknowledgeConfirmationDesktop({ isOpen, handleClose, type }: Props) {
  const { t } = useTranslation()
  const { onSubmit } = useCheckoutContextSelector((state) => state.paymentSummary)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)

  const handleContinueClick = async () => {
    if (confirmButtonRef.current) {
      confirmButtonRef.current.disabled = true
      handleClose()
      await onSubmit(type)
      confirmButtonRef.current.disabled = false
    }
  }

  return (
    <Dialog title={t(i18Base + 'Title')} isOpen={isOpen} onClose={handleClose}>
      <Body>
        <Block>
          <Text variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'DescriptionFirstPart')}
          </Text>
          <DescriptionSecondPartWrapper variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'DescriptionSecondPart')}
            <LinkWrapper
              href="https://www.tsa.gov/travel/security-screening/whatcanibring/all"
              target="_blank">
              {t(i18Base + 'Link')}
            </LinkWrapper>
          </DescriptionSecondPartWrapper>
        </Block>
      </Body>
      <Footer>
        <CancelButton
          variant={'outline'}
          onClick={handleClose}
          data-test-id="cancel-travel-acknowledgment-modal-button">
          {t(i18Base + 'CancelButton')}
        </CancelButton>
        <ConfirmButton
          ref={confirmButtonRef}
          fullWidth
          variant={'solid'}
          onClick={handleContinueClick}
          data-tracking-id="continue-travel-acknowledgment-modal-button"
          data-test-id="continue-travel-acknowledgment-modal-button">
          {t(i18Base + 'ContinueButton')}
        </ConfirmButton>
      </Footer>
    </Dialog>
  )
}
