import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { LayoutProps } from '../types'
import { Container, Price } from './trip-cost-price-mobile-styled'

export function TripCostPriceMobile({ onSubmit, totalRate, isDisabled }: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TripCostSummary.'
  const { mainCost } = formatRate(totalRate, {
    morpheme: 'postfix',
  })

  return (
    <Container>
      <Price>
        <div>{t(i18nBase + 'TripTotalText')}</div>
        <div data-tracking-id="trip-cost-total-value-text">{mainCost}</div>
      </Price>
      <Block paddingTop={14}>
        <Button
          fullWidth
          disabled={isDisabled}
          variant="solid"
          onClick={onSubmit}
          data-tracking-id="trip-cost-total-checkout-button">
          {t(i18nBase + 'CheckoutButton')}
        </Button>
      </Block>
    </Container>
  )
}
