import type { ReactNode } from 'react'
import { InfoModalHeaderLayout } from './layout'

type Props = {
  title: string | ReactNode
  onClose: () => void
}

export function InfoModalHeader({ title, onClose }: Props) {
  return <InfoModalHeaderLayout title={title} onClose={onClose} />
}
