import { useDesktopNavigation } from '@etta/modules/navigation/ui/desktop-navigation/use-desktop-navigation'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { Icon } from '@etta/ui/icon'
import { LogoContainer, LogoGoContainer, DeemLogoContainer } from './purchase-header-styled'

export function PurchaseHeaderLogo() {
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const { isAppleTheme } = brandConfigurationStore

  const { isSelfServiceSite } = useDesktopNavigation()

  if (isAppleTheme) {
    return (
      <LogoContainer>
        <Icon name="appleLogoIconPWA" size="large" color="mainText" />
      </LogoContainer>
    )
  }

  if (isSelfServiceSite) {
    return (
      <LogoGoContainer>
        <Icon name="ettaLogoGoPWA" size="medium" color="primary" />
      </LogoGoContainer>
    )
  }

  return (
    <DeemLogoContainer>
      <Icon name="deemLogoPWA" size="large" color="mainText" />
    </DeemLogoContainer>
  )
}
