import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { MultiLayerCostAllocationProps } from '../types'
import { SelectAllocationContent } from './select-allocation-content'

type Props = {
  data: MultiLayerCostAllocationProps
  isOpen: boolean
  isAdaptivePosition?: boolean
}

export const SelectAllocationModal = observer(function SelectAllocationModal({
  isAdaptivePosition,
  isOpen,
  data,
}: Props) {
  const type = useScreenType()
  const isNotMobile = type !== ScreenType.Mobile
  const modalPosition = isAdaptivePosition && isNotMobile ? 'right' : 'center'

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={data.handleModalClose}
      position={modalPosition}
      horizontalDimension="content-760">
      <SelectAllocationContent data={data} isDesktop={isNotMobile} />
    </Modal>
  )
})
