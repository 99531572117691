import type { FocusedInputShape } from 'react-dates'
import { useCallback } from 'react'
import { Swap } from '@etta/ui/swap'
import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { useControlsLabels } from '@fiji/hooks/use-date-picker'
import { dateFormat } from '@fiji/utils/dates'
import {
  SelectedDateStatus,
  Separator,
  ControlContainer,
  StartIconSmall,
  IconWrapperAbsolute,
  ButtonsContainer,
} from './controls-styled'
import { WeekHeader } from './week-header'
import { SelectedDateTitle } from './selected-date-title'

type ControlsProps = Dates & {
  focusedInput: FocusedInputShape
  isRange?: boolean
  startTitle?: string
  endTitle?: string
  forceScreenType?: ScreenType | null
  dateTitleFormat?: string
  isShowCalendarIcon?: boolean
}

export default function Controls({
  focusedInput,
  date,
  dateEnd,
  isRange,
  startTitle,
  endTitle,
  forceScreenType,
  dateTitleFormat,
  isShowCalendarIcon,
}: ControlsProps) {
  const type = useScreenType()
  const isMobile = forceScreenType === ScreenType.Mobile || type === ScreenType.Mobile

  const formatDisplayDate = useCallback(
    (date: Date): string => {
      return dateFormat(date, dateTitleFormat ? dateTitleFormat : 'MMM dd')
    },
    [dateTitleFormat],
  )

  const { startDateAriaLabel, endDateTitle, endDateAriaLabel, startDateTitle } = useControlsLabels({
    date,
    dateEnd,
    isRange,
    endTitle,
    startTitle,
    titleFormatter: formatDisplayDate,
    focusedInput,
  })

  return (
    <ControlContainer isMobile={isMobile}>
      <ButtonsContainer isMobile={isMobile}>
        <SelectedDateStatus
          startDate={date}
          endDate={dateEnd}
          isMobile={isMobile}
          isSelected={focusedInput === 'startDate'}
          isRange={isRange}
          aria-label={startDateAriaLabel}>
          <SelectedDateTitle
            isMobile={isMobile}
            withIcon={isShowCalendarIcon}
            dateTitle={startDateTitle}
          />
        </SelectedDateStatus>
        {isRange && (
          <>
            <Swap is={isMobile} isSlot={<Separator />}>
              <IconWrapperAbsolute>
                <StartIconSmall>
                  <PWAIcon name="arrowForwardPWA" />
                </StartIconSmall>
              </IconWrapperAbsolute>
            </Swap>

            <SelectedDateStatus
              startDate={date}
              endDate={dateEnd}
              isMobile={isMobile}
              isSelected={focusedInput === 'endDate'}
              isStickToRight
              isRange={isRange}
              aria-label={endDateAriaLabel}>
              <SelectedDateTitle
                isMobile={isMobile}
                withIcon={isShowCalendarIcon}
                dateTitle={endDateTitle}
              />
            </SelectedDateStatus>
          </>
        )}
      </ButtonsContainer>
      {isMobile && <WeekHeader />}
    </ControlContainer>
  )
}
