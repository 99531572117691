import { useTranslation } from 'react-i18next'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import { LocationSearchField } from '@etta/components/location-search-field/location-search-field'
import type { Props } from '../types'

const i18Base = 'TripPlanner.BaseSearch'

export function CarRentalPlaceMobile({
  onPickUpPlaceChange,
  pickUpPlace,
  onDropOffPlaceChange,
  dropOffPlace,
  placesHistory,
  validationErrors,
}: Props) {
  const { t } = useTranslation()

  return (
    <InputButtonGroup
      aria-label={t(i18Base + '.PickUpDropOffPlaces')}
      direction={InputButtonGroupDirection.Vertical}
      errorMessage={validationErrors.pickUpPlace ?? validationErrors.dropOffPlace}>
      <LocationSearchField
        searchType="car-rental"
        onChange={onPickUpPlaceChange}
        value={pickUpPlace}
        label={t(i18Base + '.Input.PickupLocation')}
        data-tracking-id="pick-up-location-search"
        isOutboundTrip
        hasCurrentLocationSearch
        useNewLocationSearch
        latestPlaces={placesHistory}
        recentSearchesType="carRental"
        hasError={!!validationErrors.pickUpPlace}
      />
      {!!pickUpPlace && (
        <LocationSearchField
          searchType="car-rental"
          onChange={onDropOffPlaceChange}
          value={dropOffPlace}
          label={t(i18Base + '.Input.DropoffLocation')}
          data-tracking-id="drop-off-location-search"
          hasCurrentLocationSearch
          useNewLocationSearch
          latestPlaces={placesHistory}
          recentSearchesType="carRental"
          hasError={!!validationErrors.dropOffPlace}
        />
      )}
    </InputButtonGroup>
  )
}
