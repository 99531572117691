import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { buildRideHailExpenseConfigRequest } from '@etta/modules/ride-hail/infra/builders/ride-hail-expense-config.request-builder'
import type { PromisedResult } from '@etta/core/type-utils'
import { mapToRideHailExpenseConfigResponseValueObject } from '@etta/modules/ride-hail/infra/mappers/ride-hail-expense-config-response.mapper'
import type {
  TopLevelOptionsValueObject,
  SearchRideHailResultsValueObject,
  RideHailExpenseConfigErrorsInstances,
  RideHailExpenseConfigResponseValueObject,
} from '../core'
import {
  LocationRefinementErrors,
  RideHailExpenseConfigErrors,
  SearchRideHailResultErrors,
} from '../core'
import { GetUpcomingFlightsErrors } from '../core/errors/get-upcoming-flights.error'
import type { RideHailAirportPickupFlightValueObject } from '../core/value-objects/ride-hail-airport-pickup.value-object'
import { RideHailSearchDataProvider } from './ride-hail-search.data-provider/ride-hail-search.data-provider'
import { LocationRefinementMapper } from './mappers/ride-hail-location-refinement.mapper'
import { SearchRideHailMapper } from './mappers/search-ride-hail.mapper'
import type {
  GetLocationRefinementArgs,
  SearchRideHailArgs,
  RideHailExpenseConfigArgs,
  RideHailSearchAdapterResult,
  RideHailUpComingFlightsResult,
} from './types'
import { buildRideHailSearchRequest } from './builders/ride-hail-search.request-builder'
import { mapToRideHailAirportPickupFlightValueObject } from './mappers/ride-hail-upcoming-flight-response.mapper'

@Adapter()
export class RideHailSearchAdapter {
  constructor(
    @Inject()
    private rideHailSearchDataProvider: RideHailSearchDataProvider,
  ) {}

  cancelLocationRefinementRequest() {
    this.rideHailSearchDataProvider.cancelLocationRefinementOptionsRequest()
  }

  cancelRequests() {
    this.rideHailSearchDataProvider.cancelRequests()
  }

  async getLocationRefinementOptions({
    location,
    locationRefinementType,
    provider,
    searchResultID,
  }: GetLocationRefinementArgs): RideHailSearchAdapterResult<TopLevelOptionsValueObject> {
    try {
      const { data, error } = await this.rideHailSearchDataProvider.locationRefinementOptions({
        location,
        locationRefinementType,
        provider: provider,
        searchResultID,
      })

      if (error) {
        return Result.Err(new LocationRefinementErrors.LocationRefinementGetOptionsError(error))
      }

      if (data.getLocationRefinementOptions.__typename === 'LocationRefinementErrorResponse') {
        return Result.Err(
          new LocationRefinementErrors.LocationRefinementGetOptionsError(
            data.getLocationRefinementOptions.reason,
          ),
        )
      }

      if (data.getLocationRefinementOptions.__typename === 'UnexpectedError') {
        return Result.Err(
          new LocationRefinementErrors.UnexpectedLocationRefinementGetOptionsError(
            data.getLocationRefinementOptions.message,
          ),
        )
      }

      return Result.Ok(
        LocationRefinementMapper.toTopLevelValueObject(data.getLocationRefinementOptions),
      )
    } catch (e) {
      return Result.Err(new LocationRefinementErrors.UnexpectedLocationRefinementGetOptionsError(e))
    }
  }

  async getSearchRideHail(
    args: SearchRideHailArgs,
  ): RideHailSearchAdapterResult<SearchRideHailResultsValueObject> {
    const request = buildRideHailSearchRequest(args)

    try {
      const { data, error } = await this.rideHailSearchDataProvider.getSearchRideHail(request)

      if (data.searchRideHailWithError.__typename === 'SearchRideHailErrorResponse') {
        return Result.Err(
          new SearchRideHailResultErrors.SearchRideHailResultError(
            data.searchRideHailWithError.reason,
          ),
        )
      }

      if (error) {
        return Result.Err(new SearchRideHailResultErrors.SearchRideHailResultError(error))
      }

      return Result.Ok(
        SearchRideHailMapper.toSearchRideHailResultsValueObject(data.searchRideHailWithError),
      )
    } catch (e) {
      return Result.Err(new SearchRideHailResultErrors.UnexpectedSearchRideHailResultError(e))
    }
  }

  async rideHailExpenseConfig(
    args: RideHailExpenseConfigArgs,
  ): PromisedResult<
    RideHailExpenseConfigResponseValueObject,
    RideHailExpenseConfigErrorsInstances
  > {
    const request = buildRideHailExpenseConfigRequest(args)

    try {
      const { data, error } = await this.rideHailSearchDataProvider.rideHailExpenseConfig(request)

      if (error) {
        return Result.Err(
          new RideHailExpenseConfigErrors.UnexpectedRideHailExpenseConfigError(error),
        )
      }

      if (data.rideHailExpenseConfig.__typename === 'RideHailExpenseConfigErrorResponse') {
        return Result.Err(
          new RideHailExpenseConfigErrors.UnexpectedRideHailExpenseConfigError(
            data.rideHailExpenseConfig.reason,
          ),
        )
      }

      return Result.Ok<
        RideHailExpenseConfigResponseValueObject,
        RideHailExpenseConfigErrorsInstances
      >(mapToRideHailExpenseConfigResponseValueObject(data.rideHailExpenseConfig))
    } catch (error: any) {
      return Result.Err(new RideHailExpenseConfigErrors.UnexpectedRideHailExpenseConfigError(error))
    }
  }

  async rideHailUpcomingFlights(): RideHailUpComingFlightsResult<
    RideHailAirportPickupFlightValueObject[]
  > {
    try {
      const { data, error } = await this.rideHailSearchDataProvider.getRideHailUpComingFlights()

      if (error || data.rideHailUpcomingFlights.__typename === 'UnexpectedError') {
        return Result.Err(new GetUpcomingFlightsErrors.UnexpectedGetUpcomingFlightsError(error))
      }

      if (data.rideHailUpcomingFlights.__typename === 'RideHailUpcomingFlightsErrorResponse') {
        return Result.Err(
          new GetUpcomingFlightsErrors.UnexpectedGetUpcomingFlightsError(
            data.rideHailUpcomingFlights.reason,
          ),
        )
      }

      return Result.Ok(
        data.rideHailUpcomingFlights.rideHailUpcomingFlights.map(
          mapToRideHailAirportPickupFlightValueObject,
        ),
      )
    } catch (error: any) {
      return Result.Err(new GetUpcomingFlightsErrors.UnexpectedGetUpcomingFlightsError(error))
    }
  }
}
