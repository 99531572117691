import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'

export const EmergencyContactFieldsSettings = {
  name: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  relationship: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  primaryPhone: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  alternatePhone: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  email: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  addressBlock: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.Country]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.Zip]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.State]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.Mailstop]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.City]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.Street1]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  [AddressFields.Street2]: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}
