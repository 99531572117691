import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
// eslint-disable-next-line import/no-restricted-paths
import { CostSegmentType } from '@fiji/graphql/types'
import type { CostSegmentValueObject } from '../../core/value-objects/cost-segment.value-object'
import type { CostAllocationResultValueObject } from '../../core/value-objects/cost-allocation-result.value-object'

@Store()
export class CostAllocationStore {
  constructor(
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  private _activeCostAllocationDisplayName: Map<number, string> = new Map()
  private _activeSplitCostAllocationDisplayName: Map<number, string> = new Map()

  costAllocationModal = new Toggle()
  splitCostAllocationModal = new Toggle()

  private _costAllocationModalToggle: Map<number, Toggle> = new Map()
  private _splitCostAllocationModalToggle: Map<number, Toggle> = new Map()

  private _isTopLevelCostSegmentLoading: boolean = false
  private _isTopLevelCostSegmentError: boolean = false
  private _topLevelCostSegments: CostSegmentValueObject[] = []

  private _isCostAllocationLoading: boolean = false
  private _isCostAllocationError: boolean = false
  private _costAllocationData: Map<string, CostAllocationResultValueObject> = new Map()

  private _submittedCostAllocations: Map<number, CostAllocationSubmitValueObject> = new Map()
  private _submittedSplitCostAllocations: Map<number, CostAllocationSubmitValueObject> = new Map()

  get isCostAllocationsValid() {
    return (
      !this.displayConfigurationStore.isPreBookCostAllocationEnabled ||
      this._submittedCostAllocations.size === this._topLevelCostSegments.length
    )
  }

  get costAllocationForBooking() {
    const result: CostAllocationSubmitValueObject[] = []
    this._submittedCostAllocations.forEach((allocation, key) => {
      const splitCostAllocation = this._submittedSplitCostAllocations.get(key)

      result.push({
        segmentId: allocation.segmentId,
        allocationId: allocation.allocationId,
        costSegmentType: CostSegmentType.Primary,
        splitCostAllocation: !!splitCostAllocation,
      })

      if (splitCostAllocation) {
        result.push({
          segmentId: splitCostAllocation.segmentId,
          allocationId: splitCostAllocation.allocationId,
          costSegmentType: CostSegmentType.Secondary,
          splitCostAllocation: true,
        })
      }
    })

    return result
  }

  get isTopLevelCostSegmentLoading() {
    return this._isTopLevelCostSegmentLoading
  }

  get isTopLevelCostSegmentError() {
    return this._isTopLevelCostSegmentError
  }

  get isCostAllocationLoading() {
    return this._isCostAllocationLoading
  }

  get isCostAllocationError() {
    return this._isCostAllocationError
  }

  get topLevelCostSegments() {
    return this._topLevelCostSegments
  }

  get submittedCostAllocations() {
    return this._submittedCostAllocations
  }

  get submittedSplitCostAllocations() {
    return this._submittedSplitCostAllocations
  }

  getCostAllocationModalToggleById(id: number): Toggle | undefined {
    return this._costAllocationModalToggle.get(id)
  }

  getSplitCostAllocationModalToggleById(id: number): Toggle | undefined {
    return this._splitCostAllocationModalToggle.get(id)
  }

  setIsTopLevelCostSegmentLoadError(val: boolean) {
    this._isTopLevelCostSegmentError = val
  }

  setIsTopLevelCostSegmentLoading(val: boolean) {
    this._isTopLevelCostSegmentLoading = val
  }

  setTopLevelCostSegments(val: CostSegmentValueObject[]) {
    this._topLevelCostSegments = val
    val.forEach((segment) => {
      this._costAllocationModalToggle.set(segment.id, new Toggle())
      this._splitCostAllocationModalToggle.set(segment.id, new Toggle())
    })
  }

  setIsCostAllocationLoading(val: boolean) {
    this._isCostAllocationLoading = val
  }

  setIsCostAllocationLoadError(val: boolean) {
    this._isCostAllocationError = val
  }

  getCostAllocationById(id: string): CostAllocationResultValueObject | undefined {
    return this._costAllocationData.get(id)
  }

  getCostAllocationDisplayNameById(id: number): string | undefined {
    return this._activeCostAllocationDisplayName.get(id)
  }

  getSplitCostAllocationDisplayNameById(id: number): string | undefined {
    return this._activeSplitCostAllocationDisplayName.get(id)
  }

  setCostAllocation(id: string, allocation: CostAllocationResultValueObject) {
    this._costAllocationData.set(id, allocation)
  }

  setSubmittedCostAllocation(id: number, val: CostAllocationSubmitValueObject) {
    this._submittedCostAllocations.set(id, val)
  }

  setSubmittedSplitCostAllocation(id: number, val: CostAllocationSubmitValueObject) {
    this._submittedSplitCostAllocations.set(id, val)
  }

  setActiveCostAllocationDisplayName(segmentId: number, val: string) {
    this._activeCostAllocationDisplayName.set(segmentId, val)
  }

  setActiveSplitCostAllocationDisplayName(segmentId: number, val: string) {
    this._activeSplitCostAllocationDisplayName.set(segmentId, val)
  }

  resetCostAllocations() {
    this._topLevelCostSegments = []
    this._isTopLevelCostSegmentLoading = false
    this._isTopLevelCostSegmentError = false
    this._isCostAllocationLoading = false
    this._isCostAllocationError = false
    this._activeCostAllocationDisplayName = new Map()
    this._activeSplitCostAllocationDisplayName = new Map()
    this._costAllocationData = new Map()
    this._submittedCostAllocations = new Map()
    this._submittedSplitCostAllocations = new Map()
  }
}
