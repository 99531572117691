import type { IconColorPalette } from '@etta/ui/icon'
import type { IconBadgeVariant, BadgeIconProps } from './types'
import { getIconPWAName } from './get-icon-pwa-name'

export function getIconProps(
  variant: IconBadgeVariant,
  color?: IconColorPalette,
): BadgeIconProps | null {
  const name = getIconPWAName(variant)

  if (!name) {
    return null
  }

  switch (variant) {
    case 'outOfPolicy':
      return { name, color: 'error' }
    case 'applePreferred':
    case 'preferred':
    case 'preferredCaption':
    case 'preferredVendor':
      return { name, color: 'success' }
    case 'ecoFriendly':
      return { name, color: 'success' }
    case 'alternatePreferred':
      return { name, color: 'warning' }
    case 'customPreferred':
      return { name, color: 'success' }
    case 'seatMapNotAvailable':
      return { name, color: 'bodyText' }
    default:
      return { name, color: color || 'mainText1' }
  }
}
