import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { TimeTabMobile } from './mobile'
import { TimeTabDesktop } from './desktop'

export function TimeTabLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TimeTabMobile {...props} />}
      desktopSlot={<TimeTabDesktop {...props} />}
    />
  )
}
