import { Tabs } from '@etta/ui/tabs'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'

export function TripTabsDesktop({ tabs, usedTab, onChange }: LayoutProps) {
  return (
    <Tabs activeTab={usedTab} onChangeTab={onChange}>
      <Tabs.Header tabBasis={120} withBottomBorder={false}>
        {tabs.map(
          (tab) =>
            !tab.isHiddenDesktop && (
              <Tabs.Tab
                key={tab.title}
                value={tab.value}
                data-tracking-id={`${tab.title.toLowerCase()}-button`}
                aria-label={tab.title}>
                <Block paddingVertical={16} marginBottom={-7}>
                  <Text variant={'subHeadStrong'}>{tab.title}</Text>
                </Block>
              </Tabs.Tab>
            ),
        )}
      </Tabs.Header>
    </Tabs>
  )
}
