import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

export const Container = styled.div``

export const EmissionData = styled.div`
  width: 100%;

  > span {
    padding-left: 5px;
  }
`

export const Loader = styled.div`
  height: 24px;
  width: 168px;
  ${glowingFragment};
`
