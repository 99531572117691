import styled from 'styled-components'
import { footnoteMedium, boldWeightText } from '@fiji/style'

export const MembershipWarning = styled.div`
  ${footnoteMedium};
  margin-top: 12px;
  color: ${(p) => p.theme.colors.mainText};
`

export const MembershipWarningUnits = styled.span`
  ${boldWeightText}
`
