import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { SelectedSearchParametersBlock as OldSelectedSearchParametersBlock } from './selected-search-parameters-block'
import { SelectedSearchParametersBlock as NewSelectedSearchParametersBlock } from './retail-view/selected-search-parameters-block'
import type { Props } from './types'

export function SelectedSearchParametersBlock(props: Props) {
  const { featureFlags } = useFeatureFlags()

  if (featureFlags.isRetailShoppingUIEnabled) {
    return <NewSelectedSearchParametersBlock {...props} />
  }

  return <OldSelectedSearchParametersBlock {...props} />
}
