import styled, { css } from 'styled-components'

import { Container } from '@etta/ui/text-field/base-text-field/base-text-field-styles'
import type { Props } from './types'

export const Root = styled.div<Props>`
  ${({ variant = 'vertical', hasTopBorder }) => {
    if (variant === 'vertical') {
      return css`
        & > *:first-child ${Container} {
          border-radius: 8px 8px 0 0;
        }

        & > *:last-child ${Container} {
          border-radius: 0 0 8px 8px;
        }

        ${!hasTopBorder &&
        css`
          & > * + * ${Container} {
            border-top: 0;
          }
        `}

        & > *:not(:first-child):not(:last-child) ${Container} {
          border-radius: 0;
        }
      `
    }

    if (variant === 'horizontal') {
      return css`
        display: flex;

        & > *:first-child ${Container} {
          border-radius: 8px 0 0 8px;
        }

        & > *:last-child {
          flex: 1;
          margin-left: -1px;

          ${Container} {
            border-radius: 0 8px 8px 0;
          }
        }
      `
    }
  }};
`
