import { useTranslation } from 'react-i18next'

import type { IconNames } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { Container, IconContainer, StyledChevronIcon, SafetyHeader } from './collapses-view-styled'

type Props = {
  isExpanded: boolean
  handleExpand: () => void
  iconNames: IconNames[]
}

export function CollapsedView({ isExpanded, handleExpand, iconNames }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'

  return (
    <Container tabIndex={0} data-tracking-id="flight-details-modal-safety-check-collapsed">
      <SafetyHeader onClick={handleExpand}>
        <SafetyCheckTitle />
        <StyledChevronIcon
          name="chevronDownPWA"
          rotate={isExpanded}
          color="mainText"
          size="small"
          aria-label={
            isExpanded
              ? t(i18nBase + '.TapToCollapseDetailsLabel')
              : t(i18nBase + '.TapToViewDetailsLabel')
          }
        />
      </SafetyHeader>
      <Block marginTop={6}>
        <Text variant="footnoteMedium">{`${t(i18nBase + '.CollapsedTitle')}`}</Text>
      </Block>
      {iconNames && (
        <Block marginTop={14}>
          {iconNames.map((iconName, index) => (
            <IconContainer key={index}>
              <Icon name={iconName} color="bodyText" />
            </IconContainer>
          ))}
        </Block>
      )}
    </Container>
  )
}
