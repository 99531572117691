import type { SafeLogger } from '@fiji/utils/safe-logger'
import type { CacheEntry, CacheKey } from './types'
import type { Store } from './store'

type Args = {
  store: Store
  ttl: number
  delayTimeout?: number
  logger: SafeLogger
}

const NEXT_CHECK_TIMEOUT = 1000 * 60 * 60 // every hour

const INITIAL_TIMEOUT = 1000 * 60 * 5 // 5 min

async function flushEntries({ ttl, store, logger }: Args) {
  const checkTime = Date.now() - ttl
  const cachedEntries = await store.entries<CacheKey, CacheEntry>()
  const entriesForDelete = cachedEntries.filter(([_, entry]) => entry.timestamp < checkTime)
  if (entriesForDelete.length) {
    const keysList = entriesForDelete.map(([key]) => key)
    logger.log('clearing expired values', keysList.join(', '))
    await Promise.all(
      keysList.map((key) => {
        return store.del(key)
      }),
    )
  } else {
    logger.log('no entries for flush')
  }
}

export function flushByTimeout({ store, ttl, logger, delayTimeout = INITIAL_TIMEOUT }: Args) {
  setTimeout(async () => {
    await flushEntries({ store, logger, ttl })

    flushByTimeout({ store, ttl, logger, delayTimeout: NEXT_CHECK_TIMEOUT })
  }, delayTimeout)
}
