import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Menu } from '@etta/ui/menu'
import { Text } from '@etta/ui/text'
import { CarRentalSortBy } from '@etta/modules/car-rental-search/core/enums'

import { SortText } from './car-rental-sort-styled'
import type { Props } from './types'

const i18nBase = 'CarRentalResults'

const getSortI18nId = (sortBy: CarRentalSortBy) => {
  switch (sortBy) {
    case CarRentalSortBy.Price:
      return 'Price'
    case CarRentalSortBy.Policy:
      return 'Policy'
    case CarRentalSortBy.Distance:
      return 'Distance'
    case CarRentalSortBy.Preference:
    default:
      return 'Preferred'
  }
}

export function CarRentalSort({ sortBy, onChangeSortBy }: Props) {
  const { t } = useTranslation()
  const SORT_OPTIONS = useMemo(
    () => [
      {
        id: CarRentalSortBy.Preference,
        name: t(`${i18nBase}.Sort.Preferred`),
      },
      {
        id: CarRentalSortBy.Price,
        name: t(`${i18nBase}.Sort.Price`),
      },
    ],
    [t],
  )

  return (
    <Menu<CarRentalSortBy>
      openByClickOnEl={
        <SortText
          aria-label={t(i18nBase + '.Sort.SortByLabel', {
            label: `${t(i18nBase + '.Sort.SortBy')} ${t(
              `${i18nBase}.Sort.${getSortI18nId(sortBy)}`,
            )}`,
          })}>
          <Icon name="sortPWA" color="mainText1" />
          <Text variant="linkNormal">
            {t(`${i18nBase}.Sort.SortBy`)} {t(`${i18nBase}.Sort.${getSortI18nId(sortBy)}`)}
          </Text>
        </SortText>
      }
      shouldCloseOnClickOutside
      title={t(`${i18nBase}.Sort.SortBy`)}
      options={SORT_OPTIONS}
      activeOptionId={sortBy}
      activeOptionIcon={<Icon name="tickPWA" size="small" color="primary" />}
      onOptionSelect={onChangeSortBy}
    />
  )
}
