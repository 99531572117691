import { useRef, useState } from 'react'

export function useFooter() {
  const [showPreviousFooter, setShowPreviousFooter] = useState(false)
  const [showNextFooter, setShowNextFooter] = useState(false)
  const footerRef = useRef<HTMLDivElement | null>(null)
  const previousFooterRef = useRef<HTMLDivElement | null>(null)
  const nextFooterRef = useRef<HTMLDivElement | null>(null)

  return {
    showPreviousFooter,
    setShowPreviousFooter,
    showNextFooter,
    setShowNextFooter,
    footerRef,
    previousFooterRef,
    nextFooterRef,
    footerHeight: showNextFooter
      ? nextFooterRef.current?.offsetHeight
      : showPreviousFooter
      ? previousFooterRef.current?.offsetHeight
      : footerRef.current?.offsetHeight,
  }
}
