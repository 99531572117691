import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'

const i18BaseBaggageInfo = 'BaggageInformation.'

type Props = {
  carrierUrl: string
  carrierName: string
}

export function RTPItineraryCardBaggageInformationDesktop({ carrierName, carrierUrl }: Props) {
  const { t } = useTranslation()

  return (
    <Block marginTop={8}>
      <Block isFlexBlock alignItems="flex-start">
        <Icon name="noCheckedBagInfoPWA" color="bodyText" />
        <Block paddingLeft={4} paddingTop={4} isFlexBlock>
          <Text variant="footnoteMedium" color="bodyText">
            <Swap
              is={!carrierUrl}
              isSlot={
                <>{t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailableVisitAirlineWebsite')}</>
              }>
              {t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailableFull')}{' '}
              <Link size="small" href={carrierUrl} target="_blank">
                {carrierName}&nbsp;
                <Icon name="externalLinkPWA" color="primary" size={14} />
              </Link>
            </Swap>
          </Text>
        </Block>
      </Block>
    </Block>
  )
}
