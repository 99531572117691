import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'
import { Label } from './operated-by-styled'

type Props = {
  flight: AdaptiveFlight
}

export const OperatedBy: FC<Props> = ({ flight }) => {
  const { t } = useTranslation()

  const operatedBy = flight.adaptedSegments
    .map((value) => value.operatedBy)
    .filter((value) => !!value)
    .join(', ')

  if (!operatedBy) {
    return null
  }

  return (
    <Label>
      {t('FlightDetails.OperatedBy', {
        operatedBy,
      })}
    </Label>
  )
}
