import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { CarSafetyCheck } from '@etta/components/car-safety-check/car-safety-check'
import { CarRentalDetailsEcoCheck } from '@etta/components/car-rental-details-eco-check/car-rental-details-eco-check'
import { Modal } from '@etta/ui/modal'
import { Icon } from '@etta/ui/icon'
import { CarRentalDetailsHeader } from '../../car-rental-details-header'
import { CarRentalDetailsPrice } from '../../car-rental-details-price'
import { CarRentalVendorInformation } from '../../car-rental-vendor-information'
import { CarRentalLocation } from '../../car-rental-location'
import type { CarRentalDetailsModalProps } from '../types'
import { Container, Section, DetailsButton } from './car-rental-details-modal-desktop-styled'

export function CarRentalDetailsModalDesktop({
  onClose,
  carRental,
  imageUrl,
  carRentalTitle,
  isOutOfPolicy,
  isPreferred,
  amenities,
  locations,
  vendorInformation,
  carImage,
  onDetailsClick,
  carRentalEmission,
}: CarRentalDetailsModalProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()

  return (
    <Modal.Body>
      <Container>
        <CarRentalDetailsHeader
          imageUrl={imageUrl}
          carRentalTitle={carRentalTitle}
          isOutOfPolicy={isOutOfPolicy}
          isPreferred={isPreferred}
          amenities={amenities}
          carImage={carImage}
          onClose={onClose}
        />
        <Section>
          <CarRentalLocation locations={locations} />
        </Section>
        <Section>
          <CarRentalVendorInformation
            vendorInformation={vendorInformation}
            safetyCheck={carRental.safetyCheck}
          />
        </Section>
        <Section>
          <CarSafetyCheck
            type={carRental.safetyCheck.messageCode}
            viewType="info-rtp-car-details"
            vendorCode={carRental.vendorDetails.code}
            readMoreLink={carRental.safetyCheck.url}
            vendorName={carRental.vendorDetails.name}
          />
        </Section>
        {featureFlags.isSustainabilityEnabled && (
          <Section>
            <CarRentalDetailsEcoCheck {...carRentalEmission} />
          </Section>
        )}
        <Section>
          <CarRentalDetailsPrice
            carCostRates={carRental.carCostRates}
            totalCostRate={carRental.totalCostRate}
            baseRate={featureFlags.isServiceFeeEnabled ? carRental.netRate : carRental.baseRate}
            feesAndTaxesRate={carRental.feesAndTaxesRate}
            serviceFeeRate={featureFlags.isServiceFeeEnabled ? carRental.serviceFeeRate : undefined}
          />
        </Section>
        <Section>
          <DetailsButton
            fullWidth
            variant="outline"
            leftIcon={t('ActionButtons.InfoCarRentalButton')}
            onClick={onDetailsClick}
            rightIcon={<Icon name="chevronRightPWA" size="small" />}
          />
        </Section>
      </Container>
    </Modal.Body>
  )
}
