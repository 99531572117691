import styled from 'styled-components'
import { Text as SwitchLabel, Label as SwitchContainer } from '@etta/ui/switch/switch-components'
import { subHeadMedium } from '@fiji/style'
import { Section } from '../rail-filters-styled'

export const SwitchSection = styled(Section)`
  padding: 24px 22px;

  ${SwitchContainer} {
    justify-content: space-between;
    width: 100%;
  }

  ${SwitchLabel} {
    max-width: 73%;
    ${subHeadMedium};
    color: ${(props) => props.theme.colors.bodyText};
  }
`
