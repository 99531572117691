import styled, { css } from 'styled-components'
import { headline, normalWeightText, withStyledText } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'

const inputStyles = css`
  flex: 1;
  appearance: none;
  font-size: 1.4rem;
  line-height: 20px;
  letter-spacing: normal;
  color: ${(p) => p.theme.inputColor};
  outline: none;
  font-family: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  background: transparent;
  border: none;
  border-radius: 7px;
  ${normalWeightText};
  ${withStyledText};
`

export const SearchField = styled.input`
  ${inputStyles};
  ${headline};

  height: 22px;
  padding: 0;
  background-color: ${(p) => p.theme.colors.white};
  &::placeholder {
    color: transparent;
  }

  font-weight: 600;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;
    color: ${(p) => p.theme.inputColor};
  }
`

export const SearchFieldContainer = styled.div<{ isOpen: boolean }>`
  height: 22px;
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  position: relative;

  ${(p) =>
    p.isOpen &&
    css`
      border-color: ${p.theme.inputColor};
    `}
`

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`
