import styled, { css } from 'styled-components'
import { TimeStamp } from '../swiping-time-picker-components'

export const TimeSet = styled.div`
  position: relative;
`

export const AmPmUnit = styled(TimeStamp)`
  text-transform: uppercase;
  transition: all 0.4s;
  user-select: none;
  padding-top: ${(p) => (!p.focused ? p.height + 'rem' : '0')};
`

export const TimeColumn = styled.div<{ height: number }>`
  height: ${(p) => p.height}rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  flex: 1;
  position: relative;

  :not(:last-child) {
    border-right: 1px solid ${(p) => p.theme.colors.borderDark};
  }
`

export const Container = styled.div<{ height: number; withLabel?: boolean }>`
  min-width: 80px;
  flex: 1;
  margin: -17px 0;

  ${(p) =>
    p.withLabel &&
    css`
      margin: 0;
      height: calc(${p.height}rem + 20px);
    `}

  :not(:last-child) {
    ${TimeColumn} {
      border-right: 1px solid ${(p) => p.theme.colors.borderDark};
    }
  }
`

export const TimeLabel = styled.div`
  text-align: center;
  height: 20px;
`

export const Slider = styled.div<{ withLabel?: boolean }>`
  display: flex;
  position: relative;
  margin-top: 40px;
  overflow: hidden;
  justify-content: center;

  ${(p) =>
    p.withLabel &&
    css`
      margin-top: 20px;
    `}
`

export const DayTime = styled.div<{ swiping: number }>`
  margin-top: ${(p) => p.swiping || 0}rem;
  transition: all 0.4s;
`
