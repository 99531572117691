import styled from 'styled-components'

export const ContainerBg = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
  padding: 0 25px;
  background-color: rgba(16, 24, 32, 0.5);
`

export const ModalContainer = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  max-width: 310px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 184px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.17);
`
