import { firebase } from '../firebase'
import type { OnMessageResult } from './types'
import { getFirebaseMessagingLogger } from './loggers'
import { mapNotificationFromFirebaseMessage } from './map-notification-from-firebase-message'

export const onFirebaseBackgroundMessage = () =>
  new Promise<OnMessageResult>((resolve, reject) => {
    if (!firebase.messaging.isSupported()) {
      reject(`Browser doesn't support messaging`)

      return
    }

    const firebaseMessaging = firebase.messaging()
    const firebaseMessagingLoggerSw = getFirebaseMessagingLogger()

    firebaseMessaging.onBackgroundMessage((message) => {
      if (!message) {
        firebaseMessagingLoggerSw.error('Background message', 'Received', {
          message,
        })

        reject('No message')

        return
      }

      const notification = mapNotificationFromFirebaseMessage(message)

      firebaseMessagingLoggerSw.log('Background message', 'Received', {
        message,
        notification,
      })

      resolve({
        notification,
        logger: firebaseMessagingLoggerSw,
      })
    })
  })
