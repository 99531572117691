import i18n from 'i18next'
import type { BadgeVariant } from './types'

const i18nBase = 'Badge'

const textsMap: Record<BadgeVariant, string> = {
  alternatePreferred: 'AlternatePreferred',
  customPreferred: 'CustomPreferred',
  applePreferred: 'ApplePreferred',
  cancellable: 'Cancellable',
  corporateRates: 'CorporateRates',
  deposit: 'Deposit',
  ecoFriendly: 'EcoFriendly',
  GBTDirect: 'GBTDirect',
  guarantee: 'Guarantee',
  hybridCar: 'HybridCar',
  locationHotel: 'LocationHotel',
  nonRefundable: 'NonRefundable',
  outOfPolicy: 'OutOfPolicy',
  distance: 'Distance',
  penalityApplies: 'PenalityApplies',
  preferred: 'Preferred',
  preferredVendor: 'PreferredVendor',
  preferredCaption: 'Preferred',
  prepaid: 'Prepaid',
  privateRate: 'PrivateRate',
  privateRates: 'PrivateRates',
  refundable: 'Refundable',
  refundableGray: 'Refundable',
  reserve: 'Reserve',
  tripAdvisor: 'TripAdvisorRating',
  tripSource: 'TripSource',
  unRestricted: 'UnRestricted',
  webFare: 'WebFare',
  seatMapNotAvailable: 'SeatMapNotAvailable',
  creditCard: 'CreditCard',
  eticket: 'ETicket',
}

export function getTitle(variant: BadgeVariant) {
  return i18n.t(`${i18nBase}.${textsMap[variant]}`)
}
