import type { StopInfo } from '@etta/components/air-search-segment/types'
import { Tooltip } from '@etta/ui/tooltip'
import { Text } from '@etta/ui/text'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useStopsLine } from './use-stops-line'
import {
  DurationLineContainer,
  StopsContaier,
  StopsLineContainer,
  Stop,
  HiddenStopsContainer,
  HiddenStop,
  StopsBlock,
  StopsAligner,
  StopCard,
  StopCode,
} from './style'

export function StopsLine({
  stopsInfo,
  topPosition,
  maxStopsInView = 2,
}: {
  stopsInfo: StopInfo[]
  topPosition?: number
  maxStopsInView?: number
}) {
  const { firstStopInfo, lastStopInfo, hiddenStopsInfo } = useStopsLine({ stopsInfo })
  const { featureFlags } = useFeatureFlags()

  return (
    <StopsLineContainer topPosition={topPosition}>
      <DurationLineContainer />
      <StopsContaier>
        {featureFlags.isRetailShoppingUIEnabled ? (
          <StopsAligner>
            {stopsInfo.slice(0, maxStopsInView).map((stop) => (
              <StopCard key={stop.code}>
                <StopCode
                  variant="captionStrongCaps"
                  color="bodyText"
                  align="center"
                  noWrap
                  isBlock>
                  {stop.code}
                </StopCode>
                <Text variant="captionMedium" color="bodyText" align="center" noWrap isBlock>
                  {stop.flightTime}
                </Text>
              </StopCard>
            ))}
            {stopsInfo.length > maxStopsInView && (
              <StopCode variant="captionStrongCaps" color="bodyText" align="center" noWrap isBlock>
                +{stopsInfo.length - maxStopsInView}
              </StopCode>
            )}
          </StopsAligner>
        ) : (
          <Tooltip
            slot={
              <>
                {stopsInfo.map((stopInfo) => (
                  <Text variant="footnoteMedium" color="mainText2" key={stopInfo.code} isBlock>
                    {stopInfo.code} - {stopInfo.name}
                  </Text>
                ))}
              </>
            }
            place="top"
            isIndependentOfParentWidth>
            <StopsBlock>
              {firstStopInfo && (
                <Stop key={firstStopInfo.code} hasRightMargin={!!lastStopInfo}>
                  {firstStopInfo.code}
                </Stop>
              )}
              {hiddenStopsInfo && (
                <HiddenStopsContainer>
                  {hiddenStopsInfo.map((stopInfo) => (
                    <HiddenStop key={stopInfo.code} />
                  ))}
                </HiddenStopsContainer>
              )}
              {lastStopInfo && (
                <Stop key={lastStopInfo.code} hasLineSeparator>
                  {lastStopInfo.code}
                </Stop>
              )}
            </StopsBlock>
          </Tooltip>
        )}
      </StopsContaier>
    </StopsLineContainer>
  )
}
