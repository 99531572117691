import { Store } from '@etta/di'
import type { FailedBookingValueObject } from '@etta/modules/booking/core/value-objects'
import type { TripConfirmationType } from '@etta/modules/booking/core/value-objects/trip-confirmation-type.value-object'

type RetryBooking = {
  numberOfRetries: number
  onHandle: () => void
}

@Store()
export class BookTripStore {
  isLoading = false

  isError = false

  isBooked = false

  errorMessages: string[] = []

  failedBookings: FailedBookingValueObject[] = []

  tripConfirmationType: TripConfirmationType = 'regular'

  bookedProcessId: string | null = null

  retryBookingData: RetryBooking | null = null

  private _shouldKeepBookTripState: boolean = false

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setFailedBookings(failedBookings: FailedBookingValueObject[]) {
    this.failedBookings = failedBookings
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setIsBooked(isBooked: boolean) {
    this.isBooked = isBooked
  }

  setTripConfirmationType(tripConfirmationType: TripConfirmationType) {
    this.tripConfirmationType = tripConfirmationType
  }

  setBookedProcessId(bookedProcessId: string | null) {
    this.bookedProcessId = bookedProcessId
  }

  setErrorMessages(errorMessages: string[]) {
    this.errorMessages = errorMessages
  }

  setRetryBooking = (data: RetryBooking | null) => {
    this.retryBookingData = data
  }

  setShouldKeepBookTripState(shouldKeepBookTripState: boolean) {
    this._shouldKeepBookTripState = shouldKeepBookTripState
  }

  get shouldKeepBookTripState() {
    return this._shouldKeepBookTripState
  }

  dropState() {
    this.isLoading = false
    this.isError = false
    this.isBooked = false
    this.errorMessages = []
    this.failedBookings = []
    this.tripConfirmationType = 'regular'
    this.retryBookingData = null
    this.bookedProcessId = null
    this._shouldKeepBookTripState = false
  }
}
