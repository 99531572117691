import styled from 'styled-components'

export const Container = styled.li`
  padding: 20px;
  display: flex;
`

export const IconWrapper = styled.div`
  margin-left: auto;
`
