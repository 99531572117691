import styled from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const ExpirationContainer = styled.div`
  position: relative;
  & > :nth-child(2) {
    width: 360px;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  ${getKeyboardNavigationStyle({ borderRadius: 10, offset: 0 })}
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 513px;
`

export const Banner = styled.div`
  padding: 12px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.secondary};
`
