import { hookContextFactory } from '@etta/interface/services/hook-context-factory'

import { EttaGoAction } from './actions/etta-go.action'

import { NavigationAction } from './actions/navigation.action'

import { NavigationStore } from './stores/navigation.store'

export const { useModuleContext: useNavigationContext } = hookContextFactory({
  navigationAction: NavigationAction,
  ettaGoAction: EttaGoAction,
  navigationStore: NavigationStore,
})
