import { useCallback, useState, useEffect, useRef } from 'react'
import type { TimeRange, Place } from '@fiji/types'
import { TimeRangeDirection } from '@fiji/enums'
import type { SegmentType } from '@fiji/graphql/types'
import { getFlightCustomTimeRange } from '@fiji/utils/dates/get-flight-custom-time-range'
import { useAppSelector } from '@fiji/store'
import type { AppState } from '@fiji/store'

type Props = {
  selectedTime: TimeRange
  forSegment?: SegmentType
  originPlace?: Place | null
  destinationPlace?: Place | null
  onSubmit?: (time: TimeRange) => void
  onClose: () => void
}

export function useTimeRangePicker({
  selectedTime,
  forSegment,
  originPlace,
  destinationPlace,
  onSubmit,
  onClose,
}: Props) {
  const { flightSearchTimeRange, flightSearchTimeRangeNonUS } = useAppSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const [activeTab, setActiveTab] = useState(
    selectedTime.timeRangeBy || TimeRangeDirection.Departure,
  )

  const [pickedLeaveTime, setPickedLeaveTime] = useState(selectedTime)
  const [pickedArrivalTime, setPickedArrivalTime] = useState(selectedTime)

  const handleClose = () => {
    if (selectedTime.timeRangeBy === TimeRangeDirection.Departure) {
      setPickedLeaveTime(selectedTime)
    }
    if (selectedTime.timeRangeBy === TimeRangeDirection.Arrival) {
      setPickedArrivalTime(selectedTime)
    }
    if (selectedTime.timeRangeBy) {
      setActiveTab(selectedTime.timeRangeBy)
    }
    onClose()
  }

  const handleSubmit = (time: TimeRange) => {
    onSubmit?.({ ...time, timeRangeBy: activeTab })
    onClose()
  }

  const handleChangeTab = useCallback((newTab) => {
    setActiveTab(newTab)
  }, [])

  const prevPickedTime = useRef<number | undefined>(pickedLeaveTime?.customTimeHours)
  const prevOriginAirport = useRef<string | undefined>(originPlace?.airportCode)
  const prevDestinationAirport = useRef<string | undefined>(destinationPlace?.airportCode)

  useEffect(() => {
    if (!pickedLeaveTime?.customTimeHours) {
      return
    }

    const pickedCustomTime = getFlightCustomTimeRange({
      value: pickedLeaveTime.customTimeHours,
      originPlace,
      destinationPlace,
      flightSearchTimeRange,
      flightSearchTimeRangeNonUS,
      forSegment,
    })

    setPickedLeaveTime((time) => ({
      ...time,
      ...pickedCustomTime,
    }))

    prevPickedTime.current = pickedLeaveTime?.customTimeHours
    prevOriginAirport.current = originPlace?.airportCode
    prevDestinationAirport.current = destinationPlace?.airportCode

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedLeaveTime?.customTimeHours, originPlace?.airportCode, destinationPlace?.airportCode])

  return {
    pickedLeaveTime,
    pickedArrivalTime,
    activeTab,
    handleClose,
    handleSubmit,
    handleChangeTab,
    setPickedLeaveTime,
    setPickedArrivalTime,
  }
}
