import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { useHotelDetailsAmenities } from '../../use-hotel-details-amenities'
import { Container, Title } from './hotel-details-amenities-mobile-styled'
import { AmenitiesBlock } from './amenities-block'

export function HotelDetailsAmenitiesLayoutMobile({ amenities, exclusiveAmenities }: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  const {
    openAmenitiesIcon,
    handleAmenitiesToggle,
    isOpenAmenities,
    hotelAmenities,
    roomAmenities,
    negotiatedAmenities,
  } = useHotelDetailsAmenities({ amenities, exclusiveAmenities })

  return (
    <Container>
      <Title onClick={handleAmenitiesToggle}>
        <Text variant="headline" color="mainText">
          {t(i18Base + '.HotelAmenities')}
        </Text>
        <Icon name={openAmenitiesIcon} color="bodyText" size="large" />
      </Title>
      {isOpenAmenities && (
        <div>
          {!!hotelAmenities.length && <AmenitiesBlock amenities={hotelAmenities} />}
          {!!roomAmenities.length && (
            <AmenitiesBlock amenities={roomAmenities} title={t(i18Base + '.InroomAmenities')} />
          )}
          {!!negotiatedAmenities.length && (
            <AmenitiesBlock
              amenities={negotiatedAmenities}
              title={t(i18Base + '.NegotiatedAmenities')}
            />
          )}
        </div>
      )}
    </Container>
  )
}
