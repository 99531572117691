import { useMemo } from 'react'
import type { Hotel } from '@fiji/graphql/types'

type Args = {
  hotels: Hotel[]
}

export function useHotelLocations({ hotels }: Args) {
  const locations = useMemo(() => {
    return hotels.reduce<{ lat: number; lng: number }[]>((acc, hotel) => {
      const lat = hotel.address.geocode?.lat
      const lng = hotel.address.geocode?.long

      if (hotel && lat && lng) {
        acc.push({
          lat,
          lng,
        })
      }

      return acc
    }, [])
  }, [hotels])

  return { locations }
}
