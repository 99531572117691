import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Container } from './headline-styled'

type Props = {
  segmentNumber: number
}

const i18nBase = 'PostBooking.TrainDetails'

export function Headline({ segmentNumber }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Text variant="captionStrongCaps" color="mainText1">
        {t(i18nBase + '.TrainNumber', {
          trainNumber: segmentNumber,
        })}
      </Text>
      <Text variant="captionMedium" color="mainText1">
        {t(i18nBase + '.OnTime')}
      </Text>
    </Container>
  )
}
