import type { Rate } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { useHotelRateCap } from './use-hotel-rate-cap'
import { Container } from './hotel-rate-cap-styled'

type Props = {
  perDiemRateCap?: Rate | null
  skin?: 'hotel-results' | 'hotel-details'
}

export function HotelRateCap({ perDiemRateCap, skin = 'hotel-results' }: Props) {
  const { hotelRateCapLabel, isHotelRateCapVisible, isMobile } = useHotelRateCap({ perDiemRateCap })

  if (!isHotelRateCapVisible) {
    return null
  }

  return (
    <Container isMobile={isMobile} skin={skin}>
      <Icon name="refundableFilledPWA" color="bodyText" size={24} />
      <Block marginLeft={5} paddingTop={2}>
        <Text variant="subHeadMedium" color="bodyText">
          {hotelRateCapLabel}
        </Text>
      </Block>
    </Container>
  )
}
