/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { pushPageView } from '@fiji/utils/analytics'
import type { User } from '@fiji/store/user'

type Args = {
  user: User
  itineraryId: string
}

export function useSyncItinerary({ user, itineraryId }: Args) {
  useEffect(() => {
    if (user.sessionTimeout > 0 && itineraryId) {
      window.setInterval(() => {
        fetch(`/rc/api/syncItinerarySessionAction.do?_action=session&itineraryId=${itineraryId}`, {
          method: 'GET',
        })
      }, 7 * 60 * 1000)
    }

    if (user.companyId && user.partnerId) {
      pushPageView({ companyId: user.companyId, partnerId: user.partnerId })
    }
  }, [user])
}
