import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import type { DailyRate, Money } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { useUserContext } from '@etta/modules/user'
import type { RateValueObject } from '@etta/core/value-objects'
import { Callout } from '@etta/ui/callout'
import { hasDailyRates } from '@fiji/utils/has-daily-rates'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates'
import { roomRateDateFormat } from './room-rate-date-format'
import { DailyRatesRow, Summary, Row } from './room-rates-table-styled'

type Props = {
  dailyRates: DailyRate[]
  totalCost?: Money
  totalCostRate?: RateValueObject
  roomsSubtotal?: Money
  feesAndTaxes?: Money | null
  feesAndTaxesRate?: RateValueObject
  hotelName: string
  roomAverage?: Money
  checkIn: string
  checkOut: string
  source?: string | null
}

function getTotalCost(totalCost?: Money, totalCostRate?: RateValueObject) {
  if (totalCostRate) {
    return totalCostRate?.secondary ? totalCostRate.secondary : totalCostRate?.primary
  }
  return totalCost
}

function getFeesAndTaxes(feesAndTaxes?: Money | null, feesAndTaxesRate?: RateValueObject) {
  if (feesAndTaxesRate) {
    return feesAndTaxesRate?.secondary ? feesAndTaxesRate.secondary : feesAndTaxesRate?.primary
  }
  return feesAndTaxes
}

export const RoomRatesTable = observer(function RoomRatesTable({
  hotelName,
  dailyRates,
  feesAndTaxes,
  feesAndTaxesRate,
  totalCost,
  totalCostRate,
  roomsSubtotal,
  roomAverage,
  checkIn,
  checkOut,
  source,
}: Props) {
  const { userStore } = useUserContext()
  const { t } = useTranslation()
  const i18Base = 'HotelInfo.Rooms'
  const locale = userStore.locale

  const totalCostAmount = useMemo(() => getTotalCost(totalCost, totalCostRate), [
    totalCost,
    totalCostRate,
  ])

  const feesAndTaxesAmount = useMemo(() => getFeesAndTaxes(feesAndTaxes, feesAndTaxesRate), [
    feesAndTaxes,
    feesAndTaxesRate,
  ])

  const totalNights = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )

  const showAverageRate = dailyRates.length === 0 || !hasDailyRates(source)

  return (
    <Block marginTop={showAverageRate ? 0 : 15}>
      <Swap
        is={showAverageRate}
        isSlot={
          !roomAverage && (
            <Block marginVertical={10}>{t(i18Base + '.RoomDetails.DailyRatesNotAvailable')}</Block>
          )
        }>
        {dailyRates.map((dayRate, i) => (
          <DailyRatesRow
            data-tracking-id="hotel-room-daily-rate"
            key={`date-day` + i}
            aria-label={t(i18Base + '.Price', {
              title: roomRateDateFormat(dayRate.date),
              amount: formatMoney(dayRate.rate.secondary ?? dayRate.rate.primary, {
                locale,
                isRounded: true,
              }),
            })}>
            <Text variant="footnoteMedium" color="mainText">
              {roomRateDateFormat(dayRate.date)}
            </Text>
            <Text variant="footnoteMedium" color="mainText">
              {formatMoney(dayRate.rate.secondary ?? dayRate.rate.primary, {
                locale,
                isRounded: true,
              })}
            </Text>
          </DailyRatesRow>
        ))}
      </Swap>
      <Summary isAverageSummary={showAverageRate}>
        {!showAverageRate && roomsSubtotal && (
          <Row
            aria-label={t(i18Base + '.Price', {
              title: t(i18Base + '.Night', { count: dailyRates.length }),
              amount: formatMoney(roomsSubtotal, { locale, isRounded: true }),
            })}>
            <Text variant="footnoteMedium" textOverflow="ellipsis" color="bodyText1">
              {t(i18Base + '.Night', { count: dailyRates.length })} @ {hotelName}
            </Text>
            <Text variant="footnoteMedium" color="bodyText1">
              {formatMoney(roomsSubtotal, { locale, isRounded: true })}
            </Text>
          </Row>
        )}
        {showAverageRate && !!roomAverage && (
          <DailyRatesRow data-tracking-id="hotel-room-daily-rate">
            <span>
              <Text variant="footnoteMedium" color="bodyText1">
                {t(i18Base + '.RoomDetails.AverageRate', { hotel: hotelName })}&nbsp;
              </Text>
              <Text variant="footnoteMedium" color="bodyText1">
                {`@ ${hotelName}`}
              </Text>
            </span>
            <Text variant="footnoteMedium" color="bodyText1">
              {formatMoney(roomAverage, {
                locale,
                isRounded: true,
              })}
            </Text>
          </DailyRatesRow>
        )}
        {!showAverageRate && feesAndTaxesAmount && (
          <Row
            aria-label={
              feesAndTaxesAmount
                ? t(i18Base + '.Price', {
                    title: t(i18Base + '.RoomDetails.TaxesAndFees'),
                    amount: formatMoney(feesAndTaxesAmount, { locale, isRounded: true }),
                  })
                : t(i18Base + '.RoomDetails.TaxesAndFeesIncluded')
            }>
            <Text variant="footnoteMedium" color="bodyText1">
              {t(i18Base + '.RoomDetails.TaxesAndFees')}
            </Text>
            <Text variant="footnoteMedium" color="bodyText1">
              {feesAndTaxesAmount
                ? formatMoney(feesAndTaxesAmount, { locale, isRounded: true })
                : t(i18Base + '.RoomDetails.TaxesAndFeesIncluded')}
            </Text>
          </Row>
        )}
        {totalCostAmount && (
          <Block marginTop={20}>
            <Row
              aria-label={t(i18Base + '.Price', {
                title: `${t(i18Base + '.RoomDetails.EstimatedCost')} ${t('HotelDetails.Nights', {
                  count: totalNights,
                })}`,
                amount: formatMoney(totalCostAmount, { locale, isRounded: true }),
              })}>
              <Text variant="headline" color="mainText">
                {`${t(i18Base + '.RoomDetails.EstimatedCost')} ${t('HotelDetails.Nights', {
                  count: totalNights,
                })}`}
              </Text>
              <Text variant="headline" color="mainText">
                {formatMoney(totalCostAmount, { locale, isRounded: true })}
              </Text>
            </Row>
          </Block>
        )}
        {totalNights > 1 && showAverageRate && (
          <Block marginVertical={10}>
            <Callout message={t(i18Base + '.RoomDetails.RatesDiffer')} />
          </Block>
        )}
      </Summary>
    </Block>
  )
})
