import { Block } from '@etta/ui/block'
import type { DynamicSiteMessage } from '@fiji/graphql/types'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'

type Props = {
  messages: DynamicSiteMessage[]
}

export function CarRentalDynamicSiteMessages({ messages }: Props) {
  if (!messages.length) {
    return null
  }

  return (
    <Block marginTop={24} marginHorizontal={32}>
      <DynamicSiteMessages messages={messages} />
    </Block>
  )
}
