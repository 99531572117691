import type { SeatReservationType } from '@fiji/graphql/types'
import { ModalDetails } from '@etta/components/modal-details'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { RailEmission } from '@fiji/types'
import { RailDetailsModalContent } from './rail-details-modal-content'

type Props = {
  itineraryId: string
  isTripExist: boolean
  isChangeAllowed: boolean
  isOpen: boolean
  onClose: () => void
  railLeg: TrainSegmentEntity
  railSegmentsLength: number
  seatReservationType: SeatReservationType
  isRailEticket: boolean
  railEmission: RailEmission
  isSeatRequested: boolean
  appliedRailCard: string
}

export function RailDetailsModal({ isOpen, onClose, ...props }: Props) {
  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <RailDetailsModalContent {...props} onClose={onClose} />
    </ModalDetails>
  )
}
