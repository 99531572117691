import { DataProvider } from '@etta/di'
import type {
  GetDisplayConfigurationQuery,
  GetDisplayConfigurationQueryVariables,
} from '@fiji/graphql/hooks'
import { GetDisplayConfigurationDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class DisplayConfigurationDataProvider extends GraphqlDataProvider {
  getDisplayConfiguration(input: GetDisplayConfigurationQueryVariables['input']) {
    return this.client.query<GetDisplayConfigurationQuery, GetDisplayConfigurationQueryVariables>({
      query: GetDisplayConfigurationDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
}
