import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AIR_PROVIDERS } from '@fiji/constants'
import type { LoyaltyProgram } from '@fiji/graphql/hooks'

type Args = {
  loyaltyFlightValue?: LoyaltyProgram[] | null
}

type AirProviderKey = keyof typeof AIR_PROVIDERS

type ValidatorMap = {
  [key in AirProviderKey]?: (value?: string | null) => string | undefined
}

const southwestRegexp = /^\d{0,14}$/

const validatorsMap: ValidatorMap = {
  SOUTHWEST: (value?: string | null) => {
    if (!value) {
      return
    }
    if (!southwestRegexp.test(value)) {
      return 'PaymentSummary.FlightMembershipErrors.InvalidMembershipNumber'
    }
  },
}

export function useFlightLoyaltyError({ loyaltyFlightValue }: Args) {
  const { t } = useTranslation()
  const flightLoyaltyErrors = useMemo(() => {
    if (!loyaltyFlightValue) {
      return {}
    }
    return loyaltyFlightValue.reduce<Record<string, string>>((acc, item) => {
      if (item.vendorCode === AIR_PROVIDERS.SOUTHWEST) {
        const validator = validatorsMap.SOUTHWEST
        const error = validator && validator(item.number)

        if (error) {
          acc[item.vendorCode] = t(error)
        }
      }
      return acc
    }, {})
  }, [loyaltyFlightValue, t])

  return {
    flightLoyaltyErrors,
    hasErrors: Object.keys(flightLoyaltyErrors).length > 0,
  }
}
