import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type {
  CreateRailSearchInput as GQLCreateRailSearchInput,
  CalculateRailEmissionsBatchInput,
  CalculateRailEmissionsBatchRailSegmentInput,
  RailInput,
  RailETicketInput as GQLRailETicketInput,
} from '@fiji/graphql/types'
import { ETicketType as GQLETicketType } from '@fiji/graphql/types'
import {
  FareTier,
  JourneyDirection,
  RailSortBy,
  CalculateEmissionsRailSegmentTravelClassDto,
  RouteType,
} from '@fiji/graphql/types'
import type { RailSearchResultValueObject } from '../core/value-objects/rail-search-result.value-object'
import type { RailSearchErrorsInstances } from '../core/errors/rail-search.errors'
import { RailSearchErrors } from '../core/errors/rail-search.errors'
import { FareTierEnum } from '../core/enums/fare-tier.enum'
import type { RailEmissionsResultValueObject } from '../core/value-objects/rail-emissions-result.value-object'
import type { RailEmissionsErrorsInstances } from '../core/errors/rail-emissions.errors'
import { RailEmissionsErrors } from '../core/errors/rail-emissions.errors'
import { RailSegmentTravelClassEnum } from '../core/enums/rail-segment-travel-class.enum'
import type { CreateRailSearchResultEntity } from '../core/entities/create-rail-search-result.entity'
import type { RailETicketsErrorsInstances } from '../core/errors/rail-e-tickets.errors'
import { RailETicketsErrors } from '../core/errors/rail-e-tickets.errors'
import type { RailEticketsValueObject } from '../core/value-objects/rail-e-tickets.value-object'
import { RailDataProvider } from './rail.data-provider/rail.data-provider'
import { toRailSearchValueObject } from './mappers/rail-search-result.mapper'
import type {
  CreateRailSearchInput,
  RailEmissionsBatchRailSegmentInput,
  RailEmissionsInput,
  RailETicketInput,
  RailSearchInput,
} from './types'
import { RailETicketTypeEnum } from './types'
import { RailRouteTypeEnum, RailJourneyDirectionEnum, RailSortByEnum } from './types'
import { toRailEmissionValueObject } from './mappers/rail-emissions.mapper'
import { toRailETicketsValueObject } from './mappers/rail-e-tickets.mapper'

@Adapter()
export class RailAdapter {
  constructor(
    @Inject()
    private railDataProvider: RailDataProvider,
  ) {}

  async getRailSearchResults(
    param: RailSearchInput,
    shouldForceRefetch: boolean = false,
  ): PromisedResult<RailSearchResultValueObject, RailSearchErrorsInstances> {
    const input = buildGetRailSearchResultInput(param)
    try {
      const { data, error } = await this.railDataProvider.getRailSearchResults(
        {
          input,
        },
        shouldForceRefetch,
      )

      if (error) {
        return Result.Err(new RailSearchErrors.CreateSearchError(error))
      }

      return Result.Ok(toRailSearchValueObject(data))
    } catch (e: unknown) {
      return Result.Err(new RailSearchErrors.UnexpectedErrorCreateSearch(e))
    }
  }

  async getEmissions(
    param: RailEmissionsInput,
  ): PromisedResult<RailEmissionsResultValueObject, RailEmissionsErrorsInstances> {
    const input = buildGetRailEmissionsInput(param)

    try {
      const { data, error } = await this.railDataProvider.getRailEmissions({ input })

      if (error) {
        return Result.Err(new RailEmissionsErrors.GetEmissionsError(error))
      }

      return Result.Ok(toRailEmissionValueObject(data))
    } catch (e: unknown) {
      return Result.Err(new RailEmissionsErrors.UnexpectedErrorGetEmissions(e))
    }
  }

  async createRailSearch(
    param: CreateRailSearchInput,
  ): PromisedResult<CreateRailSearchResultEntity, RailSearchErrorsInstances> {
    try {
      const input = buildCreateRailSearchInput(param)

      const { data, errors } = await this.railDataProvider.createRailSearch({ input })

      if (errors) {
        return Result.Err(new RailSearchErrors.CreateSearchError(errors))
      }

      if (!data?.createRailSearch?.id) {
        return Result.Err(new RailSearchErrors.CreateSearchError('missing create rail search id'))
      }

      return Result.Ok(data.createRailSearch)
    } catch (e: unknown) {
      return Result.Err(new RailSearchErrors.UnexpectedErrorCreateSearch(e))
    }
  }

  async getETickets(
    param: RailETicketInput,
  ): PromisedResult<RailEticketsValueObject, RailETicketsErrorsInstances> {
    const input = buildGetRailETicketsInput(param)

    try {
      const { data, error } = await this.railDataProvider.getRailETickets({ input })

      if (error) {
        return Result.Err(new RailETicketsErrors.GetETicketsError(error))
      }

      return Result.Ok(toRailETicketsValueObject(data, param.type))
    } catch (e: unknown) {
      return Result.Err(new RailETicketsErrors.UnexpectedErrorGetETickets(e))
    }
  }
}

export function buildGetRailEmissionsInput(
  param: RailEmissionsInput,
): CalculateRailEmissionsBatchInput {
  return {
    compare: param.compare,
    rails: param.rails.map((rail) => {
      return {
        customRef: rail.customRef,
        segments: rail.segments.map(mapRailEmissionsBatchRailSegmentInput),
      }
    }),
  }
}

export function buildGetRailSearchResultInput(param: RailSearchInput): RailInput {
  return {
    after: param.after,
    changesFilters: param.changesFilters,
    departArriveRanges: param.departArriveRanges,
    destinationStationFilters: param.destinationStationFilters,
    deviceClass: param.deviceClass,
    direction: param.direction ? mapJourneyDirection(param.direction) : undefined,
    excludeOutOfPolicy: param.excludeOutOfPolicy,
    filterFareTiers: param.filterFareTiers
      ? param.filterFareTiers.map(mapFilterFareTier)
      : undefined,
    first: param.first,
    includeNearbyStations: param.includeNearbyStations,
    isCustomTimeReplaced: param.isCustomTimeReplaced,
    isCustomTimeSearch: param.isCustomTimeSearch,
    legPosition: param.legPosition,
    originStationFilters: param.originStationFilters,
    searchId: param.searchId,
    selectedFareTiers: param.selectedFareTiers
      ? param.selectedFareTiers.map(mapFilterFareTier)
      : undefined,
    selectedLegs: param.selectedLegs,
    selectedServiceClasses: param.selectedServiceClasses,
    sortBy: param.sortBy ? mapRailSortByEnum(param.sortBy) : undefined,
    stopFilters: param.stopFilters,
    takeoffLandingRanges: param.takeoffLandingRanges,
    trainCompanyFilters: param.trainCompanyFilters,
    unusedTicket: param.unusedTicket,
    webfareSupported: param.webfareSupported,
    page: param.page,
  }
}

export function buildGetRailETicketsInput(param: RailETicketInput): GQLRailETicketInput {
  return {
    itineraryId: param.itineraryId,
    ticketType: mapRailEticketsTypeEnum(param.type),
  }
}

function buildCreateRailSearchInput(param: CreateRailSearchInput): GQLCreateRailSearchInput {
  return {
    numberOfPassengers: param.numberOfPassengers,
    railLegSearch: param.railLegSearch,
    routeType: mapRailRouteTypeEnum(param.routeType),
  }
}

function mapRailEmissionsBatchRailSegmentInput(
  segment: RailEmissionsBatchRailSegmentInput,
): CalculateRailEmissionsBatchRailSegmentInput {
  return {
    carrierCode: segment.carrierCode,
    class: mapRailEmissionsSegmentTravelClassEnum(segment.class),
    customRef: segment.customRef,
    departureDate: segment.departureDate,
    destinationStationCode: segment.destinationStationCode,
    originStationCode: segment.originStationCode,
  }
}

function mapRailEmissionsSegmentTravelClassEnum(
  param: RailSegmentTravelClassEnum,
): CalculateEmissionsRailSegmentTravelClassDto {
  switch (param) {
    case RailSegmentTravelClassEnum.Business:
      return CalculateEmissionsRailSegmentTravelClassDto.Business
    case RailSegmentTravelClassEnum.Economy:
      return CalculateEmissionsRailSegmentTravelClassDto.Economy
    case RailSegmentTravelClassEnum.First:
      return CalculateEmissionsRailSegmentTravelClassDto.First
  }
}

function mapRailEticketsTypeEnum(param: RailETicketTypeEnum): GQLETicketType {
  switch (param) {
    case RailETicketTypeEnum.AppleWallet:
      return GQLETicketType.AppleWallet
    case RailETicketTypeEnum.QrCode:
      return GQLETicketType.QrCode
    case RailETicketTypeEnum.Pdf:
      return GQLETicketType.Pdf
    default:
      return GQLETicketType.Unspecified
  }
}

function mapJourneyDirection(param: RailJourneyDirectionEnum): JourneyDirection {
  switch (param) {
    case RailJourneyDirectionEnum.Inward:
      return JourneyDirection.Inward
    case RailJourneyDirectionEnum.Outward:
      return JourneyDirection.Outward
    default:
      return JourneyDirection.Unspecified
  }
}

function mapFilterFareTier(param: FareTierEnum): FareTier {
  switch (param) {
    case FareTierEnum.Restricted:
      return FareTier.Restricted
    case FareTierEnum.Restricted_2:
      return FareTier.Restricted_2
    case FareTierEnum.RestrictedExpectFees:
      return FareTier.RestrictedExpectFees
    case FareTierEnum.RestrictedExpectFees_2:
      return FareTier.RestrictedExpectFees_2
    case FareTierEnum.RestrictedExpectFees_3:
      return FareTier.RestrictedExpectFees_3
    case FareTierEnum.RestrictedExpectFees_4:
      return FareTier.RestrictedExpectFees_4
    case FareTierEnum.Unmodifiable:
      return FareTier.Unmodifiable
    case FareTierEnum.Unrestricted:
      return FareTier.Unrestricted
  }
}

function mapRailSortByEnum(param: RailSortByEnum): RailSortBy {
  switch (param) {
    case RailSortByEnum.Arrival:
      return RailSortBy.Arrival
    case RailSortByEnum.Cost:
      return RailSortBy.Cost
    case RailSortByEnum.Departure:
      return RailSortBy.Departure
    case RailSortByEnum.Duration:
      return RailSortBy.Duration
    case RailSortByEnum.Policy:
      return RailSortBy.Policy
    case RailSortByEnum.Preference:
      return RailSortBy.Preference
  }
}

function mapRailRouteTypeEnum(param: RailRouteTypeEnum): RouteType {
  switch (param) {
    case RailRouteTypeEnum.OpenReturn:
      return RouteType.OpenReturn
    case RailRouteTypeEnum.Single:
      return RouteType.Single
    case RailRouteTypeEnum.Return:
      return RouteType.Return
    case RailRouteTypeEnum.Unspecified:
      return RouteType.Unspecified
  }
}
