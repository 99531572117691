import styled from 'styled-components'
import { bodyLarge, headline } from '@fiji/style'
import type { PlainTime as Time } from '@fiji/types'

type SelectedDateStatusProps = {
  isSelected?: boolean
  isStickToRight?: boolean
  isTimePicker?: boolean
  date?: Date | null
  time?: Time | null
  disabled?: boolean
  isNowSelected?: boolean
}

export const SelectedDateStatusMobile = styled.div<SelectedDateStatusProps>`
  border: none;
  background: none;
  justify-content: center;
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => {
    if (props.isNowSelected) {
      return props.theme.colors.borderDark
    }
    return props.theme.colors.primary
  }};
  position: relative;
  &::after {
    content: '';
    height: 6px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${(props) => (props.isSelected ? props.theme.colors.primary : 'transparent')};
  }

  ${headline} ${bodyLarge}
`
export const BlankWeekHeader = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  min-height: 24px;
  z-index: 1;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`
