import type { ReactNode } from 'react'
import { Container, Header, Content, Footer } from './screen-desktop-styled'

type Props = {
  children: ReactNode
  className?: string
}

function ScreenDesktop({ children, className }: Props) {
  return <Container className={className}>{children}</Container>
}

const ScreenDesktopNamespace = Object.assign(ScreenDesktop, {
  Header,
  Content,
  Footer,
})

export { ScreenDesktopNamespace as ScreenDesktop }
