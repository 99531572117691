// eslint-disable-next-line import/no-mutable-exports
let localConfig = {}

if (process.env.NODE_ENV === 'development') {
  try {
    /** local.config.ts example:
     *
     * export default {
     *   isProgressiveWebAppEnabled: true,
     * }
     */
    const files = require.context('./', false, /config.local/)

    files.keys().forEach((fileName) => {
      const data = files(fileName).default
      if (typeof data !== 'string') {
        localConfig = data
      }
    })
  } catch {}
}

export { localConfig }
