import type { Photo } from '@fiji/graphql/types'
import { Skeleton } from '@etta/ui/skeleton'
import { HeaderImagePlaceholder } from './header-image-placeholder'
import { useHotelImage } from './use-hotel-image'
import { Container, LoaderWrapper, ImageWrapper, Image } from './hotel-image-styled'
import type { HotelImagePlaceholderSkin } from './types'

type Props = {
  photos: Photo[]
  chainCode?: string | null
  isHotelLoading?: boolean
  logoPhotoUrl?: string | null
  placeholderSkin?: HotelImagePlaceholderSkin
}

export function HotelImage({
  photos,
  chainCode,
  isHotelLoading,
  logoPhotoUrl,
  placeholderSkin,
}: Props) {
  const {
    headerImage,
    hasImage,
    isImageLoaded,
    handleImageError,
    onLoad,
    onLoadStart,
  } = useHotelImage({
    photos,
    chainCode,
    isHotelLoading,
    logoPhotoUrl,
  })

  if (!hasImage) {
    return <HeaderImagePlaceholder skin={placeholderSkin} />
  }

  return (
    <Container>
      {!isImageLoaded && (
        <LoaderWrapper>
          <Skeleton width="100%" height="100%">
            <rect width="100%" height="100%" clipPath="url(#round-corner)" />
          </Skeleton>
        </LoaderWrapper>
      )}

      <ImageWrapper isImageVisible={isImageLoaded} headerImage={headerImage}>
        <Image
          src={headerImage}
          alt=""
          onError={handleImageError}
          onLoad={onLoad}
          onLoadStart={onLoadStart}
        />
      </ImageWrapper>
    </Container>
  )
}
