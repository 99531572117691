import { StyledTextVariant } from '@fiji/enums'
import { Row } from '../../row'
import type { Props } from '../types'
import { getIconName } from '../get-icon-name'
import { TitleContainer } from '../../title-container'
import { SeparatorWrapper, ListItem } from './list-desktop-styled'

export function ListDesktop({
  list,
  getItemProps,
  highlightedIndex,
  title,
  startIndex = 0,
  iconSize = 'large',
}: Props) {
  if (!list.length) {
    return null
  }

  return (
    <>
      <SeparatorWrapper>
        <TitleContainer aria-label={title}>{title}</TitleContainer>
      </SeparatorWrapper>

      {list.map((item, i) => {
        const index = startIndex + i

        return (
          <ListItem
            {...getItemProps({ item: item, index: index })}
            key={item.placeId || item.name}
            textVariant={StyledTextVariant.regularText}
            active={highlightedIndex === index}>
            <Row icon={getIconName(item)} iconSize={iconSize}>
              {item.name}
            </Row>
          </ListItem>
        )
      })}
    </>
  )
}
