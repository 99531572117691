import { useCallback, useEffect, useState } from 'react'

import type { IconNames } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Icon } from '@etta/ui/icon'
import type { ColorPalette } from '@fiji/types'
import { Text } from '../text'
import { copyToClipboard } from './use-copy-to-clipboard'
import { Row, Container, Content, ContactIconContainer, ContactText } from './copyable-value-styled'
import { SuccessNotification } from './success-notification'

type Props = {
  title: string
  value: string
  bodyIconName?: IconNames | null
  hrefContent?: string
  isCopyBtnHidden?: boolean
  iconColor?: keyof ColorPalette
}

const DEFAULT_DELAY = 1000

let isShowSuccessAllInstances = false

export function CopyableValue({
  title,
  value,
  bodyIconName,
  hrefContent,
  isCopyBtnHidden,
  iconColor,
}: Props) {
  const [isShowSuccess, setShowSuccess] = useState(false)
  const handleCopy = useCallback(async () => {
    try {
      const isCopied = await copyToClipboard(value)

      if (isCopied && !isShowSuccessAllInstances) {
        setShowSuccess(true)

        setTimeout(() => setShowSuccess(false), DEFAULT_DELAY)
      }
    } catch {}
  }, [value])

  useEffect(() => {
    isShowSuccessAllInstances = isShowSuccess
  }, [isShowSuccess])

  useEffect(() => () => setShowSuccess(false), [])

  return (
    <>
      <SuccessNotification isVisible={isShowSuccess} />
      <Container>
        <Text variant="calloutMedium" color="bodyText">
          {title}
        </Text>
        <Row>
          <Content href={hrefContent}>
            {bodyIconName && (
              <ContactIconContainer>
                <Icon name={bodyIconName} color="primary" />
              </ContactIconContainer>
            )}
            <ContactText variant="body" color="primary1">
              {value}
            </ContactText>
          </Content>
          {!isCopyBtnHidden && (
            <IconButton onClick={handleCopy} icon="copyPWA" size="medium" color={iconColor} />
          )}
        </Row>
      </Container>
    </>
  )
}
