import { useTranslation } from 'react-i18next'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { WarningCodesText } from '@etta/components/warning-codes-text/warning-codes-text'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useHotelResultsContextSelector } from '../hotel-results-provider'
import { useNoResultReason } from './use-hotel-list/use-no-results-reason'
import { HotelListContent } from './hotel-list-content'

const i18Base = 'SearchSegments.NoResultErrorScreen.'

export function HotelList() {
  const { hotelSearchResultsStore } = useHotelSearchResultsContext()

  const { t } = useTranslation()
  const isDesktop = screenMatcher.getScreenType() === ScreenType.Desktop
  const { hotelsResult, isFiltersApplied } = useHotelResultsContextSelector((state) => state)
  const { handleRefetchRequest } = hotelsResult

  const { noResultsReason, handleHotelSearchExtraAction } = useNoResultReason({
    isFiltersApplied,
    isDesktop,
    onDefaultAction: handleRefetchRequest,
    isRestrictedCountryError: !!hotelSearchResultsStore.isRestrictedCountryError,
  })

  if (
    hotelSearchResultsStore.isRestrictedCountryError ||
    noResultsReason === 'restricted-country'
  ) {
    return (
      <NoResultSearchScreen
        type="hotel-alert"
        title={t(i18Base + 'RestrictedCountryErrorTitle')}
        subTitle={t(i18Base + 'ErrorHotelRestrictedCountrySubTitle', {
          customMessage: hotelSearchResultsStore.restrictedErrorMessage?.length
            ? `(${hotelSearchResultsStore.restrictedErrorMessage})`
            : '',
        })}
        buttons={[
          {
            title: t(i18Base + 'RetryButton'),
            onClick: handleHotelSearchExtraAction,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (!hotelSearchResultsStore.isLoading && hotelSearchResultsStore.isError) {
    return (
      <NoResultSearchScreen
        type="hotel"
        title={t(i18Base + 'ErrorTitle')}
        subTitle={t(i18Base + 'ErrorHotelSubTitle')}
        buttons={[
          {
            title: t(i18Base + 'RetryButton'),
            onClick: handleHotelSearchExtraAction,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (
    !hotelSearchResultsStore.isLoading &&
    hotelSearchResultsStore.hotelResults?.warningCodes.length &&
    !hotelSearchResultsStore.hotels.length
  ) {
    return (
      <NoResultSearchScreen
        type="hotel"
        subTitle={
          <WarningCodesText
            isResultEmpty
            warningCodes={hotelSearchResultsStore.hotelResults.warningCodes}
          />
        }
        title={t('SearchResults.Hotels.WarningMessage.NoResults.Title')}
        buttons={[
          {
            title: t(i18Base + 'SearchButton'),
            onClick: handleHotelSearchExtraAction,
          },
        ]}
      />
    )
  }

  return <HotelListContent />
}
