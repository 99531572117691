import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { PostBookingAction } from '@fiji/enums'
import { HotelSearchPageMobile } from './mobile/hotel-search-page-mobile'
import { HotelSearchPageDesktop } from './desktop/hotel-search-page-desktop'

type Props = PropsWithChildren<{
  onBack: () => void
  postBookingAction?: PostBookingAction
}>

export function HotelSearchPageLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<HotelSearchPageMobile {...props} />}
      desktopSlot={<HotelSearchPageDesktop {...props} />}
    />
  )
}
