import type { PropsWithChildren } from 'react'
import { Children } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { TRANSITION_CLASS, TRANSITION_DURATION } from './transition'
import { ItemContainer } from './list-item-styled'

type Props = PropsWithChildren<{
  withAnimation?: boolean
  itemRole?: string
}>

export function List({ children, withAnimation = true, itemRole }: Props) {
  return (
    <TransitionGroup component={null}>
      {Children.map(children, (child, index) => {
        return (
          <CSSTransition
            key={index}
            classNames={TRANSITION_CLASS}
            timeout={TRANSITION_DURATION}
            in={withAnimation}>
            <ItemContainer role={itemRole}>{child}</ItemContainer>
          </CSSTransition>
        )
      })}
    </TransitionGroup>
  )
}
