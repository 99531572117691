import { useTranslation } from 'react-i18next'
import { ShowMore } from '@etta/ui/show-more'
import type { Props } from './types'
import { CollapsedView } from './collapsed-view'
import { ExpandedView } from './expanded-view'
import { ShowDetails } from './rail-details-legend-styled'

const i18Base = 'ReviewTripPWA'

export function RailDetailsLegend({ rail }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <ShowMore
        expandedContent={<ExpandedView rail={rail} />}
        notExpandedContent={<CollapsedView rail={rail} />}
        renderButton={(isExpanded, toggleExpansion) => {
          const buttonText = t(i18Base + (isExpanded ? '.HideDetailsButton' : '.ShowDetailsButton'))
          const buttonAriaLabel = t(
            i18Base + (isExpanded ? '.HideDetailsButtonAria' : '.ShowDetailsButtonAria'),
            { label: buttonText },
          )

          return (
            <ShowDetails
              role="button"
              tabIndex={0}
              onClick={toggleExpansion}
              aria-label={buttonAriaLabel}
              data-tracking-id="train-details-show-details-button">
              {buttonText}
            </ShowDetails>
          )
        }}
      />
    </>
  )
}
