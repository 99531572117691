import { toJS } from 'mobx'
import { Result } from 'fnscript'
import { Service, Inject } from '@etta/di'
import type { AirSearchErrorsInstances } from '@etta/modules/air-search/core/errors/air-search.errors'
import type { FlightLegSearchInputValueObject } from '@etta/modules/air-search/core/value-objects/flight-leg-search.input'
import {
  isRestrictedCountryError,
  isLastPermittedDateError,
} from '@etta/modules/air-search/core/errors/air-search.errors'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type { AirFiltersType } from '@etta/modules/air-search/core/value-objects/air-filters'
import type { SelectedFlight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { ROUTES } from '@fiji/routes'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { FlightLegValueObject } from '@etta/core/value-objects/search-flight-leg.value-object'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity/flight-segment.entity'
import {
  getFlightFareAttributesInput,
  getFlightFareAttributesInputForOneFlight,
} from '@etta/modules/air-search/infra/air-search.data-provider/to-fare-attributes.mapper'
import { AirSearchAdapter } from '../../../infra/air-search.adapter'
import { AirSearchStore } from '../../stores/air-search/air-search.store'
import type { GetFlightsInputArgs } from '../../types'
import { AirSearchQueryStore } from '../../stores/air-search-query.store'
import { AirSearchQueryService } from '../air-search-query/air-search-query.service'
import { AirSearchInputService } from './air-search-input.service'
import { mapToSelectedFlight } from './air-search-selected-flight.mapper'

@Service()
export class AirSearchService {
  constructor(
    @Inject() private airSearchAdapter: AirSearchAdapter,
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airSearchInputService: AirSearchInputService,
    @Inject() private airSearchQueryService: AirSearchQueryService,
    @Inject() private historyStore: HistoryStore,
  ) {}

  async createAirSearch(flightLegSearch: FlightLegSearchInputValueObject[] | null = null) {
    this.airSearchStore.dropStore()
    this.airSearchStore.setIsSearchLoading(true)

    const input = this.airSearchInputService.createAirSearchInput(flightLegSearch)
    const searchResult = await this.airSearchAdapter.createAirSearch(input)

    searchResult.match({
      Ok: ({ searchId }: { searchId: string }) => {
        this.airSearchStore.setSearchId(searchId)
        this.airSearchStore.setIsSearchLoading(false)
      },
      Err: (error: AirSearchErrorsInstances) => {
        this.handleCreateSearchError(error)
        this.airSearchStore.setIsSearchLoading(false)
      },
    })
  }

  async getFlightsForModifyReturn(
    params: GetFlightsInputArgs = {},
    getFlightsParams: GetFlightsInputArgs = {},
    forceRefetch = false,
  ): Promise<SelectedFlight[]> {
    this.airSearchStore.setIsLoading(true)
    this.airSearchStore.dropOffset()
    this.airSearchStore.setIsErrorFlights(false)
    this.airSearchStore.setIsErrorFetchMore(false)

    const previouslySelectedFlights = await this.getPreviouslySelectedFlight(params, forceRefetch)

    if (previouslySelectedFlights.isErr() || !previouslySelectedFlights.getValue().length) {
      this.airSearchStore.setIsErrorFlights(true)
      this.airSearchStore.setIsLoading(false)
      return []
    }

    this.airSearchQueryService.navigateTo(
      ROUTES.air.results({ legNumber: 1 }),
      this.airSearchQueryStore.airSearchQueryParams.getQueryParams(this.historyStore.search),
    )

    const selectedFlights = previouslySelectedFlights.getValue()

    const flightsInput = this.airSearchInputService.getFlightsInput({
      ...getFlightsParams,
      selectedFlights,
    })
    const flightResult = await this.airSearchAdapter.getFlights(flightsInput, forceRefetch)

    flightResult.match({
      Ok: (result) => {
        this.airSearchStore.increaseOffset()
        this.airSearchStore.setFlights(result)
        this.airSearchStore.setIsLoading(false)
        // get fare attributes for flights
        this.getFareAttributes(result.flightLegs)
      },
      Err: () => {
        this.airSearchStore.setIsErrorFlights(true)
        this.airSearchStore.setIsLoading(false)
      },
    })

    return selectedFlights
  }

  private async getPreviouslySelectedFlight(
    params: GetFlightsInputArgs = {},
    forceRefetch?: boolean,
  ): Promise<Result<SelectedFlight[], unknown>> {
    if (this.airSearchQueryStore.selectedFlights?.length) {
      return Result.Ok(this.airSearchQueryStore.selectedFlights)
    }
    const previouslySelectedFlightInput = this.airSearchInputService.getFlightsInput(params)
    const previouslySelectedFlight = await this.airSearchAdapter.getFlights(
      previouslySelectedFlightInput,
      forceRefetch,
    )
    if (previouslySelectedFlight.isErr()) {
      return Result.Err(previouslySelectedFlight.getError())
    }

    return Result.Ok([mapToSelectedFlight(previouslySelectedFlight.getValue().flightLegs)])
  }

  async getFlights(params: GetFlightsInputArgs = {}, forceRefetch = false) {
    const isFiltersApplied = this.checkIsNewFiltersApplied(params.filters)
    this.airSearchStore.setIsLoading(true)
    this.airSearchStore.setIsFiltersAppliedAfterSearch(isFiltersApplied)
    this.airSearchStore.dropOffset()
    this.airSearchStore.setIsErrorFlights(false)
    this.airSearchStore.setIsErrorFetchMore(false)

    const flightsInput = this.airSearchInputService.getFlightsInput(params)
    const flightResult = await this.airSearchAdapter.getFlights(flightsInput, forceRefetch)

    flightResult.match({
      Ok: (result) => {
        this.airSearchStore.increaseOffset()
        this.airSearchStore.setFlights(result)
        this.airSearchStore.setIsLoading(false)
        // get fare attributes for flights
        this.getFareAttributes(result.flightLegs)
      },
      Err: () => {
        this.airSearchStore.setIsErrorFlights(true)
        this.airSearchStore.setIsLoading(false)
      },
    })
  }

  async getMoreFlights(params: GetFlightsInputArgs = {}) {
    const existingFlights = this.airSearchStore.flightResult
    if (!existingFlights) {
      return
    }

    this.airSearchStore.setIsLoadingMore(true)
    this.airSearchStore.setIsErrorFetchMore(false)

    const flightsInput = this.airSearchInputService.getFlightsInput(params)
    const flightResult = await this.airSearchAdapter.getFlights(flightsInput)

    flightResult.match({
      Ok: (result) => {
        this.airSearchStore.increaseOffset()
        this.airSearchStore.setMoreFlights({
          flightLegs: result.flightLegs,
          pageStart: result.pageStart,
          pageEnd: result.pageEnd,
          maxResultsSize: result.maxResultsSize,
        })
        this.airSearchStore.setIsLoadingMore(false)
        // get fare attributes for flights
        this.getFareAttributes(result.flightLegs)
      },
      Err: () => {
        this.airSearchStore.setIsErrorFetchMore(true)
        this.airSearchStore.setIsLoadingMore(false)
      },
    })
  }

  async getFareAttributes(flightLegs: FlightLegValueObject[], itinerary?: string) {
    const unCacahedFlights = flightLegs.filter(
      (leg) => !this.airSearchStore.fareAttributes.has(leg.legId),
    )
    if (unCacahedFlights.length === 0) {
      return
    }

    const input = getFlightFareAttributesInput(unCacahedFlights, itinerary)

    // this.airSearchStore.setIsFareAttributesLoading(true) // it affects all flight result
    this.airSearchStore.setIsFareAttributesError(false)
    const fareAttributesResult = await this.airSearchAdapter.getFareAttributes(input)
    // this.airSearchStore.setIsFareAttributesLoading(false)

    if (fareAttributesResult.isOk()) {
      this.airSearchStore.setFareAttributes(fareAttributesResult.getValue())
    } else {
      this.airSearchStore.setIsFareAttributesError(true)
    }
  }

  // there is duplication with getFareAttributes, might be able to merge after use value object for itinerary data
  async getFareAttributesForItineraryFlights(
    flightLegs: Pick<FlightSegmentEntity, 'segments' | 'legId'>[],
    itinerary?: string,
    forceRefetch: boolean = false,
  ) {
    const unCacahedFlights = flightLegs.filter(
      (leg) => !this.airSearchStore.fareAttributes.has(leg.legId),
    )
    if (unCacahedFlights.length === 0 && !forceRefetch) {
      return
    }

    const input = getFlightFareAttributesInputForOneFlight(unCacahedFlights, itinerary)

    const fareAttributesResult = await this.airSearchAdapter.getFareAttributes(input)
    if (fareAttributesResult.isOk()) {
      this.airSearchStore.setFareAttributes(fareAttributesResult.getValue())
    }
  }

  private handleCreateSearchError(error: AirSearchErrorsInstances) {
    if (isRestrictedCountryError(error)) {
      this.airSearchStore.setIsRestrictedCountryError(true)
      this.airSearchStore.setRestrictedErrorMessage(error.error.message)
      return
    }
    if (isLastPermittedDateError(error)) {
      this.airSearchStore.setIsLastPermittedDateError(true)
      this.airSearchStore.setLastPermittedDateErrorDate(error.error.message)
      return
    }
    this.airSearchStore.setIsErrorCreatingSearch(true)
  }

  private checkIsNewFiltersApplied(filters?: AirFiltersType) {
    if (!this.airSearchStore.flightResult) {
      return false
    }

    const filtersToCheck = filters ?? this.airSearchQueryStore.additionalQueries.filters
    return !checkIsEqual(filtersToCheck, toJS(this.airSearchStore.afterSearchFilters))
  }
}
