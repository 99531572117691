import { StopsLine, StopsDot, StopsWrapper } from './segment-line-seperator-styled'

type Props = {
  stops: number
  maxStopsToShow?: number
  alignSelf?: string
}

export function SegmentLineSeperator({ stops, maxStopsToShow = 2, alignSelf = 'center' }: Props) {
  return (
    <StopsLine alignSelf={alignSelf}>
      <StopsWrapper>
        {Array.from(Array(stops))
          .slice(0, maxStopsToShow)
          .map((_, index) => (
            <StopsDot key={index} />
          ))}
      </StopsWrapper>
    </StopsLine>
  )
}
