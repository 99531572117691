import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate } from '@fiji/graphql/types'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { CostContainer, Text } from '../payment-summary-styled'

type Props = {
  serviceFee?: Rate | null
}

const i18nBase = 'PaymentSummary.'

export function ServiceFee({ serviceFee }: Props) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isServiceFeeEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()

  if (!serviceFee || !isServiceFeeEnabled) {
    return null
  }

  const cost = formatRate(serviceFee, { morpheme: 'none' }).mainCost

  return (
    <CostContainer>
      <Text data-tracking-id="service-fee-text">{t(i18nBase + 'ServiceFee')}</Text>
      <Text data-tracking-id="service-fee-price-text">{cost}</Text>
    </CostContainer>
  )
}
