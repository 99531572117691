import type { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'
import { TRANSITION_NAME, TIMEOUT, Container } from './footer-styles'

type Props = {
  isVisible: boolean
  children: ReactNode
}

export function Footer({ isVisible, children }: Props) {
  return (
    <CSSTransition in={isVisible} timeout={TIMEOUT} classNames={TRANSITION_NAME} unmountOnExit>
      <Container>{children}</Container>
    </CSSTransition>
  )
}
