import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Search } from '@etta/ui/search'
import { DatePickerModal } from '@etta/ui/date-picker-modal-temp'
import { SwipingTimePicker } from '@etta/components/sliders'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { DropOffPickerProps } from '../../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const DropOffPicker = observer(function DropOffPicker({
  dropOffDate,
  pickUpDate,
  dropOffTime,
  dropOffDateToggle,
  dropOffTimeToggle,
  onDatesSelect,
  onDropOffTimeChange,
  validationErrors,
}: DropOffPickerProps) {
  const { t } = useTranslation()

  return (
    <Search.Group percentageWidth={23.5} isTwoInputs>
      <Search.Element
        wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
          label: t(i18Base + '.Input.DropoffDate'),
          value: dropOffDate ? dateFormat(dropOffDate, 'MMM dd') : undefined,
        })}
        labelTitle={t(`${i18Base}.Input.DropoffDate`)}
        value={dropOffDate ? dateFormat(dropOffDate, 'MMM dd') : undefined}
        onClick={dropOffDateToggle.handleOpen}
        placeholder={t(`${i18Base}.Input.Select`)}
        errorMessage={validationErrors.dropOffDate}
        shouldErrorMessageRightBorderBeFlat
        contentSlot={
          <DatePickerModal
            isRange
            isReturnDate
            isEndDateSame
            isOpen={dropOffDateToggle.isOpen}
            onSelect={onDatesSelect}
            onClose={dropOffDateToggle.handleClose}
            startTitle={t(`${i18Base}.Input.PickUp`)}
            endTitle={t(`${i18Base}.Input.DropOff`)}
            date={pickUpDate}
            dateEnd={dropOffDate}
            minDate={new Date()}
          />
        }
      />
      <Search.Element
        wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
          label: t(i18Base + '.Input.DropoffTime'),
          value: dropOffTime ? getFormattedTime(dropOffTime, 'p').toLowerCase() : undefined,
        })}
        isTextAlignRight
        labelTitle={t(`${i18Base}.Input.DropoffTime`)}
        value={dropOffTime ? getFormattedTime(dropOffTime, 'p').toLowerCase() : undefined}
        onClick={dropOffTimeToggle.handleOpen}
        shouldErrorMessageLeftBorderBeFlat
        shouldAlignCenterLabelIfValueNotExist
        errorMessage={validationErrors.dropOffTime}
        contentSlot={
          <SwipingTimePicker
            value={dropOffTime}
            label={t(`${i18Base}.Input.DropoffTime`)}
            isVisible={dropOffTimeToggle.isOpen}
            onClose={dropOffTimeToggle.handleClose}
            onApply={onDropOffTimeChange}
          />
        }
      />
    </Search.Group>
  )
})
