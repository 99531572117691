import { useTranslation } from 'react-i18next'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Checkbox } from '@etta/ui/checkbox'
import { Text } from '@etta/ui/text'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import {
  MistmatchedSegmentContainer,
  MismatchedSegmentContent,
  MismatchedSegmentCheckboxWrapper,
} from './segments.styled'

type Props = {
  isSelected: boolean
  onSelect: (type: AlignTripTravelVerticalValueObject) => void
  type: AlignTripTravelVerticalValueObject
  startDateTime: string
  endDateTime: string
}

const iconNameMap: Record<AlignTripTravelVerticalValueObject, IconNames> = {
  Carrental: 'carRentalPWA',
  Hotel: 'hotelPWA',
  Air: 'flightPWA',
}

const titleMap: Record<AlignTripTravelVerticalValueObject, string> = {
  Carrental: 'CarRentalTitle',
  Hotel: 'HotelTitle',
  Air: 'FlightTitle',
}

const DATE_FORMAT_VALUE = 'EEE, MMM d'

export const MismatchedSegment = ({
  type,
  isSelected,
  onSelect,
  startDateTime,
  endDateTime,
}: Props) => {
  const { t } = useTranslation()
  const iconName = iconNameMap[type]
  const title = titleMap[type]
  const dates = new Set([
    dateFormat(new Date(deleteTimezone(startDateTime)), DATE_FORMAT_VALUE),
    dateFormat(new Date(deleteTimezone(endDateTime)), DATE_FORMAT_VALUE),
  ])

  const CheckboxContent = (
    <MismatchedSegmentCheckboxWrapper>
      {iconName && <Icon size={30} name={iconName} />}
      <MismatchedSegmentContent>
        <Text variant="headline" color="mainText">
          {t('AlignDatesModal.' + title)}
        </Text>
        <Text variant="subHeadStrong" color="bodyText">
          {Array.from(dates).join(' - ')}
        </Text>
      </MismatchedSegmentContent>
    </MismatchedSegmentCheckboxWrapper>
  )

  return (
    <MistmatchedSegmentContainer>
      <Checkbox
        label={CheckboxContent}
        value={type}
        checked={isSelected}
        onChange={() => onSelect(type)}
      />
    </MistmatchedSegmentContainer>
  )
}
