import { useTranslation } from 'react-i18next'
import { Separator } from '@etta/ui/separator'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { TripCancellationDate } from '../trip-cancellation-date'
import { Row } from './traveler-styled'

type Props = {
  isTripCancelled: boolean
  isFlightCancelled?: boolean
  cancellationDate?: string | null
}

const i18nBase = 'PostBooking.FlightDetails'

export function CancellationLabel({ cancellationDate, isTripCancelled, isFlightCancelled }: Props) {
  const { t } = useTranslation()
  const label = isFlightCancelled ? t(`${i18nBase}.CanceledByAirline`) : ''

  if (!isTripCancelled && !isFlightCancelled) {
    return null
  }

  if (isTripCancelled && cancellationDate) {
    return (
      <>
        <Row>
          <TripCancellationDate cancellationDate={cancellationDate} />
        </Row>
        <Block marginVertical={16}>
          <Separator lineType="dashed" />
        </Block>
      </>
    )
  }

  return (
    <>
      <Row>
        <Text variant="subHeadStrong" color="error">
          {label}
        </Text>
      </Row>
      <Block marginVertical={16}>
        <Separator lineType="dashed" />
      </Block>
    </>
  )
}
