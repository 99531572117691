import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { RangeResult } from '../types'
import { changeHourInDate } from './change-hour-in-date'

const DEFAULT_CHECKIN_HOUR = 9
const DEFAULT_CHECKOUT_HOUR = 17

function convertHotelToRangePostBooking(hotel: HotelPostBookingValueObject): RangeResult {
  const { checkOut, checkIn, address } = hotel
  return {
    to: address,
    from: address,
    startDate: checkIn
      ? changeHourInDate({ value: checkIn, hour: DEFAULT_CHECKIN_HOUR })
      : undefined,
    endDate: checkOut
      ? changeHourInDate({ value: checkOut, hour: DEFAULT_CHECKOUT_HOUR })
      : undefined,
  }
}

export function convertHotelForFlightPostBooking(hotel: HotelPostBookingValueObject): RangeResult {
  const result = convertHotelToRangePostBooking(hotel)

  return {
    ...result,
    from: undefined,
  }
}

export function convertHotelForCarPostBooking(hotel: HotelPostBookingValueObject): RangeResult {
  return convertHotelToRangePostBooking(hotel)
}

export function convertHotelForTrainPostBooking(hotel: HotelPostBookingValueObject): RangeResult {
  return convertHotelToRangePostBooking(hotel)
}
