import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { OfflineBlock } from '@etta/components/offline-block/offline-block'
import type { LayoutProps } from '../../types'
import { Title, ReloadErrorBlock } from './use-trip-list-reload-error-mobile-styled'
import { useTripListReloadErrorMobile } from './use-trip-list-reload-error-mobile'

export function TripListReloadErrorMobile({ onReload }: LayoutProps) {
  const { translations } = useTripListReloadErrorMobile()

  return (
    <ReloadErrorBlock>
      <OfflineBlock onButtonClick={onReload}>
        <Icon size="medium" name="warningFilledPWA" />
        <Title tabIndex={0} aria-label={translations.errorText}>
          {translations.errorText}
        </Title>
        <Link size="small" onClick={onReload} aria-label={translations.retryButtonText}>
          {translations.retryButtonText}
        </Link>
      </OfflineBlock>
    </ReloadErrorBlock>
  )
}
