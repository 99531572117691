import type { UserProfileFieldType } from '../enums/user-profile-field-type.enum'

export type CommonFields = {
  id: number
  label: string
  name: string
  mandatory: boolean
  tooltip?: string | null
  displayValue: string | null | undefined
  type: UserProfileFieldType
}

export type UserProfileCheckBoxEntity = CommonFields & {
  defaultChecked?: boolean | null
  checkedRequired?: boolean | null
  value?: boolean | null
}

export type UserProfileTextEntity = CommonFields & {
  singleLine?: boolean | null
  numbersOfLine?: number | null
  minLength?: number | null
  maxLength?: number | null
  defaultText?: string | null
  value?: string | null
}

export type UserProfileNumberEntity = CommonFields & {
  defaultNumber?: number | null
  minNumber?: number | null
  maxNumber?: number | null
  numberType?: NumberFieldType
  numberDecimal?: number | null
  value?: number | null
}

export enum NumberFieldType {
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type ListOptionValueObject = {
  key: string
  value: string
}

export type UserProfileListEntity = CommonFields & {
  fieldType: 'SELECT_BOX' | 'RADIO_BUTTONS'
  alignment: 'VERTICAL' | 'HORIZONTAL'
  defaultValue: string
  isAlphabeticallySorted: boolean
  value?: string | null
  options: ListOptionValueObject[]
}

export type UserProfileDateTimeEntity = CommonFields & {
  isAllowedPastDateTime?: boolean | null
  displayYear?: boolean | null
  displayMonth?: boolean | null
  displayDay?: boolean | null
  displayHour?: boolean | null
  displayMinute?: boolean | null
  displayTimeZone?: boolean | null
  startYear?: number | null
  endYear?: number | null
  startMonth?: number | null
  endMonth?: number | null
  startDay?: number | null
  endDay?: number | null
  value?: string | null
}

export type UserProfileUnionEntity =
  | UserProfileDateTimeEntity
  | UserProfileTextEntity
  | UserProfileCheckBoxEntity
  | UserProfileNumberEntity
  | UserProfileListEntity

export type UserProfileEntity = {
  businessAddress: {
    additionalInformation: UserProfileUnionEntity[]
  }
  employeeInformation: {
    additionalInformation: UserProfileUnionEntity[]
  }
  homeAddress: {
    additionalInformation: UserProfileUnionEntity[]
  }
  personalInformation: {
    additionalInformation: UserProfileUnionEntity[]
  }
  emergencyContact: {
    additionalInformation: UserProfileUnionEntity[]
  }
  travelPreferences: {
    additionalInformation: UserProfileUnionEntity[]
  }
}
