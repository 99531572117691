import type { TripEntity } from '../../../core/entities/trip.entity'
import { TripStatus } from '../../../core/enums'

export const INITIAL_TRIP: TripEntity = {
  type: TripStatus.Unknown,
  isMultiCity: false,
  costSummary: undefined,
  cityImageUrl: null,
  tripId: 0,
  name: '',
  bookingRef: '',
  failedSegments: [],
  supportedActions: {
    isAddCarRentalAllowed: false,
    isAddFlightAllowed: false,
    isAddHotelAllowed: false,
    isBookAgainTripAllowed: false,
    isCancelTripAllowed: false,
    isCarRentalCancelAllowed: false,
    isCarRentalChangeAllowed: false,
    isChangeTripAllowed: false,
    isFlightCancelAllowed: false,
    isFlightChangeAllowed: false,
    isFlightChangeCarrierAllowed: false,
    isFlightChangeDestinationAllowed: false,
    isHotelCancelAllowed: false,
    isHotelChangeAllowed: false,
    isTrainCancelAllowed: false,
    isTrainChangeAllowed: false,
    flightChangeRules: [],
  },
  segments: [],
  rollUpStartTime: '',
  rollUpEndTime: '',
  tripCost: null,
  cancellationTime: null,
  processId: '',
  transactionRegistryServiceItemId: null,
  flightId: null,
  holdRules: null,
  messages: [],
  appliedRailCards: [],
  airTickets: [],
}
