import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { RtpEcoCheckModal } from '@etta/components/rtp-eco-check-modal/rtp-eco-check-modal'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import { Icon } from '@etta/ui/icon'
import EcoCheckBgSVG from '../../assets/eco-check-bg-mobile.svg?url'
import type { LayoutProps } from '../../types'
import { checkIsDisclamerMessageVisible } from '../../helpers'
import {
  Container,
  BackgroundImage,
  LeftBlock,
  Title,
  EmissionsSummary,
  ModalButton,
  ModalButtonText,
  IconRelativeContainer,
  IconAbsoluteContainer,
  DisclaimerMessageContainer,
  EcoCheckWrapper,
  DisclaimerMessageText,
} from './eco-check-summary-mobile-styled'

export function EcoCheckSummaryMobile({
  hotelEmissions,
  carRentalEmissions,
  flightEmissions,
  railEmissions,
  equivalences,
  tripEmissions,
  page,
  segments,
  tapHighlightColor = 'background',
}: LayoutProps) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, isOpen } = useTogglePopup()
  const isDisclamerMessageVisible = checkIsDisclamerMessageVisible(segments)

  return (
    <>
      <Container
        tapHighlightColor={tapHighlightColor}
        onClick={handleOpen}
        data-tracking-id={`${page}-eco-check-summary-action`}>
        <EcoCheckWrapper>
          <LeftBlock>
            <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>EcoCheck</Title>
            <Icon name="leafPWA" color="success" size={16} />
            <EmissionsSummary>
              <KgOfCo2 kgOfEmissions={tripEmissions.summaryKg} />
            </EmissionsSummary>
            <ModalButton>
              <ModalButtonText
                aria-label={
                  t('Emissions.Rtp.SummaryModalOpenButton') + ' ' + t('Accessibility.TapToDetails')
                }>
                {t('Emissions.Rtp.SummaryModalOpenButton')}
                <IconRelativeContainer>
                  <IconAbsoluteContainer>
                    <Icon name="chevronRightPWA" size={12} />
                  </IconAbsoluteContainer>
                </IconRelativeContainer>
              </ModalButtonText>
            </ModalButton>
          </LeftBlock>
          <BackgroundImage
            src={EcoCheckBgSVG}
            alt="eco check background"
            isWithDisclaimer={isDisclamerMessageVisible}
          />
        </EcoCheckWrapper>
        {isDisclamerMessageVisible && (
          <DisclaimerMessageContainer>
            <Icon name="infoOutlinePWA" size="small" />
            <DisclaimerMessageText color="mainText" variant="captionMedium">
              {t('Accessibility.EcoCheck.EcoCheckDisclaimerMessage')}
            </DisclaimerMessageText>
          </DisclaimerMessageContainer>
        )}
      </Container>
      <RtpEcoCheckModal
        isVisible={isOpen}
        onClose={handleClose}
        hotelEmissions={hotelEmissions}
        carRentalEmissions={carRentalEmissions}
        flightEmissions={flightEmissions}
        railEmissions={railEmissions}
        equivalences={equivalences}
        tripEmissions={tripEmissions}
        isDisclamerMessageVisible={isDisclamerMessageVisible}
      />
    </>
  )
}
