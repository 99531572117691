import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { TabsSectionMobile } from './layout/mobile'
import { TabsSectionDesktopNew } from './layout/desktop/tabs-section-desktop.new'

export const TabsSection = observer(function TabsSection({ isDisabled }: Props) {
  return (
    <MediaLayout
      mobileSlot={<TabsSectionMobile isDisabled={isDisabled} />}
      desktopSlot={<TabsSectionDesktopNew isDisabled={isDisabled} />}
    />
  )
})
