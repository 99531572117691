import { useCallback, useEffect, useRef } from 'react'
import { stringify } from 'query-string'

type Props = {
  to?: string
  cc?: string
  subject?: string
  body?: string
}

export function useShareViaEmail({ to, cc, subject, body }: Props) {
  const linkRef = useRef<HTMLAnchorElement | null>(document.createElement('a'))

  useEffect(
    () => () => {
      const link = linkRef.current

      if (link) {
        link.remove()

        linkRef.current = null
      }
    },
    [],
  )

  return useCallback(() => {
    const link = linkRef.current

    if (link) {
      const params = stringify({ cc, subject, body })
      link.href = `mailto:${to || ''}${params ? `?${params}` : ''}`
      link.target = '_blank'

      link.click()
    }
  }, [body, cc, subject, to])
}
