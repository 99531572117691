import styled from 'styled-components'

export const DatePickerContainer = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`
