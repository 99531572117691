import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

export const Inline = styled(Block)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  > div:first-child,
  a {
    margin-top: -2px;
  }
`

export const Label = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  isBlock: true,
}))`
  text-transform: uppercase;
  margin-left: 6px;
  color: ${(p) => p.theme.colors.bodyText};
  flex: 1;
`
