import styled from 'styled-components'
import { linkSmall, KeyboardNavigationStyle } from '@fiji/style'
import type { ButtonProps } from '@etta/ui/button'
import { Button } from '@etta/ui/button'
import { transition } from './transition'

export const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 8px 8px;
  background: ${(p) => p.theme.colors.background2};
  padding: 5px 18px;
  display: ${(p) => (p.isVisible ? 'flex' : 'none')};
  align-items: center;
  z-index: 10;
  ${transition};
`

export const NotchButton = styled(Button).attrs<ButtonProps, ButtonProps>(() => ({
  variant: 'text',
  btnType: 'warning',
  paddings: false,
}))`
  border-radius: 0;
  transition: none;
  margin-left: 16px;
  &:first-of-type {
    min-width: 50px;
  }
  &:last-of-type {
    min-width: 24px;
  }
  ${KeyboardNavigationStyle}
  ${linkSmall}
`

export const NotchNameWrapper = styled.div`
  margin-left: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
`
