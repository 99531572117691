import styled from 'styled-components'
import { headline } from '@fiji/style'
import type { PlainTime as Time } from '@fiji/types'

type SelectedDateStatusProps = {
  isSelected?: boolean
  isStickToRight?: boolean
  isTimePicker?: boolean
  date?: Date | null
  time?: Time | null
}

export const SelectedDateStatusDesktop = styled.div<SelectedDateStatusProps>`
  font-weight: bold;
  height: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;

  color: ${(props) =>
    props.isSelected || props.date || props.time
      ? props.theme.colors.primary
      : props.theme.colors.bodyText};

  position: relative;
  &::after {
    content: '';
    height: 3px;
    position: absolute;
    bottom: 0;
    right: -8px;
    left: 0;
    background-color: 'transparent';
  }

  ${headline} ${headline}
`
