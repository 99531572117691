import { Maybe } from 'fnscript'
import type {
  CalculateTripEmissionsInput,
  CalculateTripEmissionsFlightInput,
  CalculateTripEmissionsCarInput,
  CalculateTripEmissionsHotelInput,
} from '@fiji/graphql/hooks'
import type {
  CarRentalSegmentEntity,
  HotelSegmentEntity,
  FlightSegmentEntity,
  TripSegmentValueObject,
  TrainSegmentEntity,
} from '@etta/modules/review-trip/core'
import { getDifferenceInDays } from '@fiji/utils/dates/get-difference-in-days'
import { mapVehicleCode } from '@fiji/utils/thrust-carbon/map-vehicle-code'
import { mapToThrustCarbonCabinClass } from '@fiji/utils/thrust-carbon/map-to-thrust-carbon-cabin-class'
import type { CalculateTripEmissionsRailInput } from '@fiji/graphql/types'
import { mapToThrustCarbonRailClass } from '@fiji/hooks/sustainability'
import type { TrainServiceClass } from '@fiji/enums'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'

export function toCalculateTripEmissionsInput(
  segments?: TripSegmentValueObject[] | null,
): CalculateTripEmissionsInput {
  const segmentCheck = SegmentTypeCheckActions.getInstance()

  const flights: CalculateTripEmissionsFlightInput[] = []
  const hotels: CalculateTripEmissionsHotelInput[] = []
  const cars: CalculateTripEmissionsCarInput[] = []
  const rails: CalculateTripEmissionsRailInput[] = []

  segments?.forEach((segment) => {
    if (segmentCheck.isHotelSegment(segment)) {
      const tcHotelSegment = mapItineraryHotelToThrustCarbonHotel(segment)
      hotels.push(tcHotelSegment)
      return
    }

    if (segmentCheck.isCarRentalSegment(segment)) {
      const tcCarRentalSegment = mapItineraryCarRetalToThrustCarbonCarRental(segment)
      cars.push(tcCarRentalSegment)
      return
    }

    if (segmentCheck.isFlightSegment(segment)) {
      const tcFlightSegment = mapItineraryFlightToThrustCarbonFlight(segment)
      flights.push(tcFlightSegment)
    }

    if (segmentCheck.isTrainSegment(segment)) {
      const filteredSegments = segment.segments?.filter((trainSegment) => {
        return !(
          trainSegment.arrival.address.countryCode === 'US' ||
          trainSegment.arrival.address.countryCode === 'CA'
        )
      })

      const trainSegmentWithFilteredSegments = {
        ...segment,
        segments: filteredSegments,
      }

      if (filteredSegments && filteredSegments.length) {
        const tcRailSegment = mapItineraryRailToThrustCarbonRail(trainSegmentWithFilteredSegments)
        rails.push(tcRailSegment)
      }
    }
  })

  return {
    flights,
    hotels,
    cars,
    rails,
  }
}

export function mapItineraryHotelToThrustCarbonHotel(
  hotel: HotelSegmentEntity,
): CalculateTripEmissionsHotelInput {
  // We expect the values to always be present.
  // Any issues related to this query are tracked via Rollbar
  return {
    customRef: hotel.hotelId,
    name: hotel.name,
    rating: Maybe.from(hotel.starRating).withDefault(0),
    location: {
      lat: hotel.address.geocode?.lat as number,
      long: hotel.address.geocode?.long as number,
    },
    checkIn: hotel.checkIn,
    nights: getDifferenceInDays(new Date(hotel.checkOut), new Date(hotel.checkIn)),
    hotelId: hotel.hotelId,
  }
}

export function mapItineraryCarRetalToThrustCarbonCarRental(
  carRental: CarRentalSegmentEntity,
): CalculateTripEmissionsCarInput {
  // We expect the values to always be present.
  // Any issues related to this query are tracked via Rollbar
  return {
    customRef: carRental.carId,
    origin: {
      lat: carRental.pickUpLocation.address.geocode?.lat as number,
      long: carRental.pickUpLocation.address.geocode?.long as number,
    },
    destination: {
      lat: carRental.dropOffLocation.address.geocode?.lat as number,
      long: carRental.dropOffLocation.address.geocode?.long as number,
    },
    pickUpDateTime: carRental.pickUpTime,
    vehicleCode: mapVehicleCode(carRental.carId),
    days: getDifferenceInDays(new Date(carRental.dropOffTime), new Date(carRental.pickUpTime)),
  }
}

export function mapItineraryFlightToThrustCarbonFlight(
  flight: FlightSegmentEntity,
): CalculateTripEmissionsFlightInput {
  const segments = flight.segments.map((segment) => {
    // We expect the values to always be present.
    // Any issues related to this query are tracked via Rollbar
    return {
      aircraftType: segment.codeshareCarrier.code || segment.aircraft?.code,
      carrier: segment.carrierCode,
      class: mapToThrustCarbonCabinClass(segment.serviceClass),
      departureDateTime: segment.departureDate,
      flightNumber: segment.flightNumber,
      originAirportCode: segment.departure.airportCode as string,
      destinationAirportCode: segment.arrival.airportCode as string,
    }
  })

  return {
    customRef: flight.legId,
    segments,
  }
}

export function mapItineraryRailToThrustCarbonRail(
  rail: TrainSegmentEntity,
): CalculateTripEmissionsRailInput {
  const segments = rail.segments.map((segment) => {
    // We expect the values to always be present.
    // Any issues related to this query are tracked via Rollbar
    return {
      class: mapToThrustCarbonRailClass(segment.service as TrainServiceClass),
      carrierCode: segment.carrierCode,
      departureDate: segment.departureDate,
      destinationStationCode: segment.departure.stationCode,
      originStationCode: segment.arrival.stationCode,
    }
  })

  return {
    customRef: rail.legId,
    segments,
  }
}
