import type { FlightLeg } from '@etta/modules/air-search/core/value-objects/get-flights.result'
import type { SelectedFlight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { deleteTimezone } from '@fiji/utils/dates'
import { getFlightStops } from '@fiji/hooks/use-adaptive-flight/use-search-adaptive-flight/getters/get-flight-stops'
import { getHeaderTitle } from '@fiji/hooks/use-adaptive-flight/use-search-adaptive-flight/getters/get-header-title'
import { getAirlinesLogos } from '@fiji/hooks/use-adaptive-flight/use-search-adaptive-flight/getters/get-airlines-logos'

export function mapToSelectedFlight(flightLeg: FlightLeg[]): SelectedFlight {
  const selectedFlightLeg = flightLeg[0]
  const {
    destination,
    origin,
    flightTime,
    totalTime,
    segments,
    connectionInfo,
    arrivalDate,
    departureDate,
    legId,
    fares,
  } = selectedFlightLeg
  const selectedFare = fares[0]

  const selectedFlight: SelectedFlight = {
    id: legId,
    selectedFareTier: selectedFare?.tier.id || '',
    selectedFareTierName: selectedFare?.tier.name || '',
    selectedFareId: selectedFare?.fareId || '',
    selectedServiceClasses: selectedFare?.segments?.map((segment) => segment.serviceClass) || [''],
    selectedBrandedFare: '',
    selectedSeats: [],
    stops: getFlightStops(connectionInfo, segments),
    departurePlaceCode: origin,
    returnPlaceCode: destination,
    flightTime,
    flightLogos: getAirlinesLogos(segments),
    headerTitle: getHeaderTitle(segments),
    arrivalDate: deleteTimezone(arrivalDate),
    departureDate: deleteTimezone(departureDate),
    totalTime,
    totalPrice: selectedFare.total,
    segments: segments.map(mapToFlightSegmentInfo),
  }

  return selectedFlight
}

export function mapToFlightSegmentInfo(
  segment: FlightLeg['segments'][number],
): SelectedFlight['segments'][number] {
  return {
    carrierLogoUrl: segment.carrier?.logoImageUrl || '',
    carrierName: segment.carrier?.name || '',
    flightNumber: segment.flightNumber,
    preferredBadgeText: segment.preferredBadgeText || '',
    isPreferredAny: segment.isPreferredAny,
    codeshareCarrierName: segment.codeshareCarrier.name || '',
  }
}
