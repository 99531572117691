import type { GetAlertsQuery } from '@fiji/graphql/hooks'
import { AlertKind } from '@etta/modules/alert/core/enums/alert-kind.enum'
import type {
  AlertValueObject,
  AlertMessageValueObject,
} from '@etta/modules/alert/core/value-objects/alert.value-object'

export class AlertsMapper {
  static toAlertsValueObject(alerts: GetAlertsQuery['alerts']): AlertValueObject {
    const messages = AlertsMapper.toAlertMessages(alerts.alerts)
    return {
      totalAlerts: alerts.totalAlerts,
      messages,
    }
  }

  private static toAlertMessages(
    alerts: GetAlertsQuery['alerts']['alerts'],
  ): AlertMessageValueObject[] {
    return alerts.map(({ message, title, docId, kind }) => ({
      title,
      message,
      kind: AlertsMapper.parseAlertKind(kind),
      docId: docId || undefined,
    }))
  }

  private static parseAlertKind(value: string) {
    switch (value) {
      case 'PASSPORT':
        return AlertKind.Passport

      case 'CREDIT_CARD':
        return AlertKind.CreditCard

      case 'NATIONAL_ID':
        return AlertKind.NationalId

      case 'VISA':
        return AlertKind.Visa

      case 'KTN':
        return AlertKind.KTN

      default:
        return AlertKind.Unspecified
    }
  }
}
