import { RailTravelRouteDetails } from '@etta/components/rail-travel-route-details/rail-travel-route-details'
import { formatTravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { Block } from '@etta/ui/block'
import { RailTransportationMode } from '@fiji/graphql/types'
import { mapRailTransportationModeToEnum } from '@etta/modules/rail/infra/mappers/utils'
import type { Props } from './types'
import { CollapsedView } from './collapsed-view'

export function ExpandedView({ rail }: Props) {
  const { segments } = rail

  return (
    <>
      <CollapsedView rail={rail} />
      <Block marginTop={30}>
        {segments.map((segment, idx) => {
          const {
            stops,
            travelTime,
            carrierName,
            carrierLogoUrl,
            trainId,
            transportationMode,
          } = segment

          const departure = formatTravelRailLocation(segment.departure, segment.departureDate)
          const arrival = formatTravelRailLocation(segment.arrival, segment.arrivalDate)
          const isNextSegmentTrain =
            segments[idx + 1] &&
            segments[idx + 1].transportationMode === RailTransportationMode.Train

          return (
            <RailTravelRouteDetails
              key={idx}
              stops={stops}
              travelTime={travelTime}
              changeDuration={segment.changeDuration}
              carrierName={carrierName}
              railLogo={carrierLogoUrl}
              trainNumber={String(trainId)}
              departure={departure}
              arrival={arrival}
              transportationMode={mapRailTransportationModeToEnum(transportationMode)}
              isFirstSegment={idx === 0}
              isLastSegment={idx === segments.length - 1}
              isNextSegmentTrain={isNextSegmentTrain}
            />
          )
        })}
      </Block>
    </>
  )
}
