import { useState, useEffect, useMemo } from 'react'
import { usePassportContext } from '@etta/modules/booking'

import type { PassportEntity } from '@etta/core/entities'
import { getIsExpiredPassport } from './get-is-expired-passport'

type Args = {
  passports?: PassportEntity[] | null
  isPassportSelected?: boolean
  onError?: (isError: boolean) => void
  isPassportRequired: boolean
}

export function usePassportBox({
  passports,
  isPassportSelected,
  onError = () => {},
  isPassportRequired,
}: Args) {
  const { passportStore, passportActions } = usePassportContext()
  const { selectedPassportId, selectedPassportCountry } = passportStore
  const { updateSelectedPassportId } = passportActions

  const getInitialPassport = useMemo(() => {
    if (!passports || !passports.length) {
      return null
    }
    if (selectedPassportId) {
      return selectedPassportId
    }
    if (passports.length === 1) {
      updateSelectedPassportId(passports[0].id || null)
      return passports[0].id || null
    }
    if (selectedPassportCountry) {
      return (
        passports.find((passport) => passport.issuingCountry === selectedPassportCountry)?.id ||
        null
      )
    }
    return null
  }, [updateSelectedPassportId, passports, selectedPassportId, selectedPassportCountry])

  const [boxValue, setBoxValue] = useState<number | null>(getInitialPassport)
  const updateBoxValue = (id: number | null) => {
    setBoxValue(id)
    updateSelectedPassportId(id)
  }

  useEffect(() => {
    setBoxValue(getInitialPassport)
    updateSelectedPassportId(getInitialPassport)
  }, [updateSelectedPassportId, getInitialPassport])

  const areNoPassport = !passports?.length
  const areAllPassportExpired = !!passports?.every((passport) => getIsExpiredPassport(passport))
  const selectedPassport = passports?.find((passport) => passport.id === boxValue)
  const isSelectedPassportExpired = !!selectedPassport && getIsExpiredPassport(selectedPassport)
  const isError =
    (areNoPassport || areAllPassportExpired || !isPassportSelected || isSelectedPassportExpired) &&
    isPassportRequired
  useEffect(() => onError(isError), [isError, onError])

  return {
    boxValue,
    updateBoxValue,
    areNoPassport,
    isError,
  }
}
