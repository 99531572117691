import { useCallback } from 'react'
import { useAppSelector } from '@fiji/store'
import { flightDisplayConfigurationSelector } from '@fiji/store/display-configuration/selectors'
import type { TimeRangeDirection } from '@fiji/enums'
import { TimeRangeOption, FlightDirection } from '@fiji/enums'
import { customOriginTime, customReturnTime, anyTimeOption, airTimeRangeOptions } from './constants'

export function useTimeForInitialFlight() {
  const { isAnytimeEnabled, isDefaultAnyTime, isMorningAfternoonEveningEnabled } = useAppSelector(
    flightDisplayConfigurationSelector,
  )
  const getTimeForInitialFlight = useCallback(
    (timeRangeBy: TimeRangeDirection, flightDirection: FlightDirection) => {
      if (
        !isDefaultAnyTime &&
        isMorningAfternoonEveningEnabled &&
        flightDirection === FlightDirection.Origin
      ) {
        return { ...airTimeRangeOptions[TimeRangeOption.Morning], timeRangeBy }
      }
      if (
        !isDefaultAnyTime &&
        isMorningAfternoonEveningEnabled &&
        flightDirection === FlightDirection.Return
      ) {
        return { ...airTimeRangeOptions[TimeRangeOption.Evening], timeRangeBy }
      }
      if (!isAnytimeEnabled && flightDirection === FlightDirection.Origin) {
        return { ...customOriginTime, timeRangeBy }
      }

      if (!isAnytimeEnabled && flightDirection === FlightDirection.Return) {
        return { ...customReturnTime, timeRangeBy }
      }

      if (isAnytimeEnabled && !isDefaultAnyTime && flightDirection === FlightDirection.Origin) {
        return { ...customOriginTime, timeRangeBy }
      }

      if (isAnytimeEnabled && !isDefaultAnyTime && flightDirection === FlightDirection.Return) {
        return { ...customReturnTime, timeRangeBy }
      }

      return {
        ...anyTimeOption,
        timeRangeBy,
      }
    },
    [isAnytimeEnabled, isDefaultAnyTime, isMorningAfternoonEveningEnabled],
  )
  return { getTimeForInitialFlight }
}
