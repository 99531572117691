import { setFocusOwnChild } from './set-focus-own-child'
import { setFocusInContainer } from './set-focus-in-container'
import { isNodeInContainer } from './is-node-in-container'
import type { FocusElement, FocusItem, FocusNode, FocusNodeId } from './types'

class FocusLock {
  activeId: FocusNodeId | null = null
  items: FocusItem[] = []
  currentElement: HTMLElement | null = null
  prevElement: HTMLElement | null = null

  constructor() {
    document.addEventListener('focusin', this.onFocusDocument, true)
  }

  public removeFocusItem(itemId: FocusNodeId) {
    const index = this.items.findIndex(({ id }) => id === itemId)
    if (index === -1) {
      return
    }

    this.items.splice(index, 1)
  }

  public addFocusItem(id: FocusNodeId, node?: FocusNode) {
    this.items.push({
      id,
      node,
      isAvailable: false,
    })
  }

  public updateItem(
    itemId: FocusNodeId,
    props: { disabled?: boolean; node?: FocusElement | null },
  ) {
    const item = this.items.find(({ id }) => id === itemId)
    if (!item) {
      return
    }

    const { node, disabled } = props
    item.node = node
    item.isAvailable = !!disabled && !!node
  }

  public catchFocus(itemId?: FocusNodeId, autoFocus = false) {
    if (!itemId) {
      return
    }
    if (this.activeId === itemId && !autoFocus) {
      return
    }
    const releaseItem = this.items.find(({ id }) => id === itemId)
    if (!releaseItem || !releaseItem.node) {
      return
    }

    releaseItem.isAvailable = true
    this.activeId = itemId

    setFocusOwnChild(releaseItem.node)
  }

  public releaseFocus(itemId: FocusNodeId) {
    const releaseItem = this.items.find(({ id }) => id === itemId)
    if (releaseItem) {
      releaseItem.isAvailable = false
    }
    if (this.activeId === itemId) {
      this.activeId = null
    }
    this.prevElement = null
    this.currentElement = null

    const availableElements = this.items.filter(({ isAvailable }) => isAvailable).reverse()
    const nextElement = availableElements[0]
    this.catchFocus(nextElement?.id)
  }

  private focusOnActiveNode(activeNode: FocusElement, target: HTMLElement) {
    if (!isNodeInContainer(activeNode, target)) {
      setFocusInContainer(activeNode, this.prevElement, this.currentElement)
      return
    }

    this.prevElement = this.currentElement
    this.currentElement = target
  }

  private focusOnClosestItem(target: HTMLElement) {
    const usedParent = this.items.find(({ node }) => {
      return isNodeInContainer(node, target)
    })
    if (!usedParent) {
      return
    }

    this.catchFocus(usedParent.id)
  }

  private onFocusDocument = (ev: Event) => {
    const target = ev.target as HTMLElement

    const activeNode = this.activeId
      ? this.items.find(({ id }) => id === this.activeId)?.node
      : null

    if (activeNode) {
      this.focusOnActiveNode(activeNode, target)
      return
    }

    this.focusOnClosestItem(target)
  }

  public destroyActiveId() {
    this.activeId = null
  }
}

export const focusLock = new FocusLock()
