import { Modal } from '@etta/ui/modal'
import type { UnusedTicketsArgs } from '@etta/components/unused-tickets-modal/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { HeaderLayout, UnusedTicketsLayout } from './components'

type Props = UnusedTicketsArgs & {
  onClose: () => void
  isVisible: boolean
  isLoading?: boolean
}

export function UnusedTicketsModal({ isVisible, onClose, unusedTickets, isLoading, page }: Props) {
  const screenType = useScreenType()
  const modalPosition = screenType !== ScreenType.Mobile ? 'right' : 'center'
  const settingsPageModal = page === 'settings' ? 'content-760' : undefined

  return (
    <Modal
      horizontalDimension={settingsPageModal}
      isVisible={isVisible}
      handleModalVisibility={onClose}
      position={modalPosition}>
      <HeaderLayout onClose={onClose} isWideSettingsModal={!!settingsPageModal} />
      <Modal.Body>
        <UnusedTicketsLayout
          isLoading={isLoading}
          unusedTickets={unusedTickets}
          isWideSettingsModal={!!settingsPageModal}
        />
      </Modal.Body>
    </Modal>
  )
}
