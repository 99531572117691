import { Button } from '@etta/ui/button'
import { OfflineBlock } from '@etta/components/offline-block/offline-block'
import { DetailsNoContent, ErrorMessage, ErrorDescription } from './details-error-styled'
import { useDetailsError } from './use-details-error'

type Props = {
  onButtonClick: () => void
}

export function DetailsError({ onButtonClick }: Props) {
  const { translations, retryButtonText } = useDetailsError()

  return (
    <DetailsNoContent>
      <OfflineBlock onButtonClick={onButtonClick}>
        <ErrorMessage role="heading" aria-level={3}>
          {translations.heading}
        </ErrorMessage>
        <ErrorDescription>{translations.description}</ErrorDescription>
        <Button
          size="small"
          minWidth={17}
          onClick={onButtonClick}
          aria-label={translations.retryButtonAriaLabel}>
          {retryButtonText}
        </Button>
      </OfflineBlock>
    </DetailsNoContent>
  )
}
