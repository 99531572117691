import styled from 'styled-components'
import { title2 } from '@fiji/style'

export const Content = styled.div`
  padding: 0 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const SubTitle = styled.h2`
  ${title2};
  color: ${(p) => p.theme.colors.mainText};
  margin: 24px 0 16px;
  padding-left: 4px;
`
