import { useMemo } from 'react'
import { templates } from '@fiji/hooks/use-share-via-email'
import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import type {
  TripCostSummaryValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
import type { TripCost } from '@fiji/graphql/types'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'

export type UseShareProps = {
  tripName: string | undefined
  totalTripCost?: TripCostSummaryValueObject | null
  segments: TripSegmentValueObject[]
  carRentalTripCost: TripCost
  flightTripCost: TripCost
  hotelTripCost: TripCost
  startDate: string | undefined
  endDate: string | undefined | null
}

type getBodyProps = {
  totalTripCost?: TripCostSummaryValueObject | undefined | null
  segments: TripSegmentValueObject[]
  carRentalTripCost: TripCost
  flightTripCost: TripCost
  hotelTripCost: TripCost
}

export function useShareTripsDetail({
  tripName,
  totalTripCost,
  segments,
  carRentalTripCost,
  hotelTripCost,
  flightTripCost,
  startDate,
  endDate,
}: UseShareProps) {
  const body = useMemo(
    () =>
      getBody({
        totalTripCost,
        segments,
        carRentalTripCost,
        hotelTripCost,
        flightTripCost,
      }),
    [carRentalTripCost, flightTripCost, hotelTripCost, segments, totalTripCost],
  )
  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({
      tripName,
      startDate: startDate ?? '',
      endDate: endDate ?? '',
    }),
    body,
  })

  return { handleShare }
}

function getBody({
  totalTripCost,
  segments,
  flightTripCost,
  hotelTripCost,
  carRentalTripCost,
}: getBodyProps) {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  return segments
    .map((segment) => {
      if (typeCheck.isFlightSegment(segment)) {
        return templates.postBookingFlight({
          segments: segment.segments.map((item) => ({ ...item, tripCost: flightTripCost })),
        })
      }
      if (typeCheck.isCarRentalSegment(segment)) {
        return templates.postBookingCarRental({
          carRental: { ...segment, tripCost: carRentalTripCost },
        })
      }
      if (typeCheck.isHotelSegment(segment)) {
        return templates.postBookingHotel({ hotel: { ...segment, tripCost: hotelTripCost } })
      }
      if (totalTripCost) {
        return `Total Trip Cost: ${totalTripCost.total.primary.amount} ${totalTripCost.total.primary.currency}`
      }
    })
    .join(`\n\n${templates.SEPARATOR}\n\n`)
    .concat('\n\n')
}
