import { Swap } from '@etta/ui/swap'
import type { LayoutProps } from '../types'
import { MessageSection, MessageTitle, MessageText } from './message-section-mobile-styled'

export function MessageSectionMobile({
  title,
  message = '',
  messageSlot,
  sectionSlot,
}: LayoutProps) {
  return (
    <MessageSection>
      <MessageTitle>{title}</MessageTitle>

      <Swap is={!!messageSlot} isSlot={<MessageText>{messageSlot}</MessageText>}>
        <MessageText dangerouslySetInnerHTML={{ __html: message }} />
      </Swap>

      {sectionSlot}
    </MessageSection>
  )
}
