import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
`

export const Label = styled.span`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.mainText};
  opacity: 0.65;
`

export const OnTimeContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 2px;
`
