import { Store } from '@etta/di'
import type { StoredCustomFieldBooking } from '@fiji/hooks/use-custom-fields/get-stored-custom-fields-booking-list'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { PaymentStructure } from '@etta/screens/payment-summary/use-payment-summary/types'
import { TravelVertical } from '@etta/modules/booking/core/enums'
import { Toggle } from '@etta/interface/services/toggle'
import { Validator } from '@etta/interface/services/validator'

@Store()
export class PaymentSummaryStore {
  customFieldsPersist = new ItineraryStorage<StoredCustomFieldBooking[]>('customFields', [])

  paymentStructurePersist = new ItineraryStorage<PaymentStructure>('paymentStructure', {
    [TravelVertical.Air]: null,
    [TravelVertical.Hotel]: null,
    [TravelVertical.Carrental]: null,
    [TravelVertical.Rail]: null,
  })

  travelerAcknowledgeModal = new Toggle()
  ndcFareAcknowledgeModal = new Toggle()
  bookType?: 'hold' | 'regular' = undefined
  isTravelerAcknowledgeModalToggled = false
  isNDCFareAcknowledgeModalToggled = false

  paymentSummaryValidator = new Validator({
    acceptAirTicketTerms: Validator.scheme
      .boolean()
      .test('validate-truthy', `PaymentSummary.AgreeAirTicketExchange`, (value) => {
        return value === true
      })
      .default(false),
  })

  get customFields() {
    return this.customFieldsPersist.values
  }

  get paymentStructure() {
    return this.paymentStructurePersist.values
  }

  get isAcceptAirTicketTerms(): boolean {
    return Boolean(this.paymentSummaryValidator.values.acceptAirTicketTerms)
  }

  setBookType(bookType?: 'hold' | 'regular') {
    this.bookType = bookType
  }

  updateCustomFields(customFields: StoredCustomFieldBooking[]) {
    this.customFieldsPersist.set(customFields)
  }

  updatePaymentStructure(paymentStructure: PaymentStructure) {
    this.paymentStructurePersist.set(paymentStructure)
  }

  setIsTravelerAcknowledgeModalToggled(value: boolean) {
    this.isTravelerAcknowledgeModalToggled = value
  }

  setNDCFareAcknowledgeModalToggled(value: boolean) {
    this.isNDCFareAcknowledgeModalToggled = value
  }
}
