import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CarRentalGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { formatNational } from '@fiji/utils/phone/format-national'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { LocationMap } from '@etta/components/location-map/location-map'
import { CarRentalSafetyCheck } from '@etta/components/car-rental-safety-check/car-rental-safety-check'
import { Tabs } from '@etta/ui/tabs'
import { GOOGLE_MAPS_URL } from '@fiji/constants'
import { Paper, Separator } from '../trip-car-rental-styled'
import { MapWrapper, VendorLogo, Address, PhoneLink, WorkingHours } from './location-styled'
import { Marker } from './marker'

type Props = {
  carRental: CarRentalGroupedSegmentValueObject
}

enum TabType {
  PickUp = 'PickUp',
  DropOff = 'DropOff',
}

const i18nBase = 'PostBooking.CarRentalDetails'

export function Location({
  carRental: {
    pickUpTime,
    dropOffTime,
    pickUpLocation,
    dropOffLocation,
    vendor,
    safetyCheck,
    isPickUp,
  },
}: Props) {
  const [tabType, setTabType] = useState(isPickUp ? TabType.PickUp : TabType.DropOff)
  const { t } = useTranslation()
  const handleChangeTab = useCallback((newTabType) => {
    setTabType(newTabType)
  }, [])

  const carRentalDate = tabType === TabType.PickUp ? pickUpTime : dropOffTime
  const { address, workingHours, phone } =
    tabType === TabType.PickUp ? pickUpLocation || {} : dropOffLocation || {}
  const parsedDate = carRentalDate ? new Date(deleteTimezone(carRentalDate)) : null
  const handleAddressClick = useCallback(() => {
    if (!address?.geocode) {
      return
    }

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${address.geocode?.lat},${address.geocode?.long}`)
  }, [address])

  const tabs = useMemo(() => {
    return [
      { value: TabType.PickUp, title: t(i18nBase + '.PickUp') },
      { value: TabType.DropOff, title: t(i18nBase + '.DropOff') },
    ]
  }, [t])

  const parsedAndFormattedDate = parsedDate && dateFormat(parsedDate, `eee, LLL dd`)
  const location =
    address && `${vendor?.name ? `${vendor.name}, ` : ''}${formatPickUpDropOffAddress(address)}`
  const workingHoursText = workingHours?.range?.[0] || '00:00 AM - 11:59 PM'

  return (
    <Paper>
      <Tabs activeTab={tabType} onChangeTab={handleChangeTab}>
        <Tabs.Header>
          {tabs.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value} aria-label={tab.title}>
              {tab.title}
            </Tabs.Tab>
          ))}
        </Tabs.Header>
      </Tabs>
      {address?.geocode && (
        <MapWrapper
          onClick={handleAddressClick}
          aria-hidden
          data-tracking-id="car-rental-trip-segment-map-container">
          <LocationMap
            location={{
              lat: address.geocode?.lat,
              lng: address.geocode?.long,
            }}>
            <Marker />
          </LocationMap>
        </MapWrapper>
      )}
      <Block marginTop={24} paddingHorizontal={16} paddingBottom={16}>
        {parsedAndFormattedDate && (
          <Text
            variant="headline"
            aria-label={`${t(
              i18nBase + (tabType === TabType.PickUp ? '.PickUpOn' : '.DropOffOn'),
            )}  ${parsedAndFormattedDate}`}>
            {parsedAndFormattedDate}
          </Text>
        )}
        {vendor?.imageUrl && (
          <VendorLogo
            src={vendor.imageUrl}
            alt={vendor.name || t('Accessibility.CarRental.VendorLogoAlt')}
            aria-label={vendor.name || t('Accessibility.CarRental.VendorLogoAlt')}
          />
        )}
        {address && (
          <Address onClick={handleAddressClick}>
            <Text
              variant="subHeadMedium"
              color="bodyText"
              aria-label={t(
                i18nBase + (tabType === TabType.PickUp ? '.PickUpFrom' : '.DropOffFrom'),
                { location },
              )}>
              {location}
            </Text>
            <Icon name="directionsPWA" color="primary" />
          </Address>
        )}
        <Separator />
        {workingHours && (
          <WorkingHours aria-label={workingHoursText}>
            <Text variant="subHeadMedium" color="bodyText">
              {workingHoursText}
            </Text>
            <Icon name="timePWA" color="bodyText" />
          </WorkingHours>
        )}
        {phone?.number && (
          <>
            <Separator />
            <PhoneLink
              data-tracking-id="car-rental-trip-segment-phone-container"
              href={`tel:${phone.number}`}
              aria-label={`${phone.number}. ${t(i18nBase + '.TapToCall')}`}>
              <span>{formatNational(phone.number)}</span>
              <Icon name="phonePWA" color="primary" />
            </PhoneLink>
          </>
        )}
        <>
          <Separator />
          <Block marginTop={24} paddingBottom={16}>
            <CarRentalSafetyCheck
              vendorName={vendor?.name}
              messageCode={safetyCheck?.messageCode}
              badgeUrl={safetyCheck?.badgeUrl}
              url={safetyCheck?.url}
            />
          </Block>
        </>
      </Block>
    </Paper>
  )
}
