import { Action, Inject } from '@etta/di'
import { EnterCustomExpenseCodeService } from '@etta/modules/ride-hail/interface/services/enter-custom-expense-code.service'

@Action()
export class EnterCustomExpenseCodeActions {
  constructor(
    @Inject()
    private readonly enterCustomExpenseCodeService: EnterCustomExpenseCodeService,
  ) {}

  handleBack() {
    this.enterCustomExpenseCodeService.goBack()
  }

  handleContinue() {
    this.enterCustomExpenseCodeService.handleContinue()
  }

  handleCustomExpenseCodeValueChanged(value: string) {
    this.enterCustomExpenseCodeService.handleCustomExpenseCodeValueChanged(value)
  }
}
