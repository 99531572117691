import { useState, useCallback } from 'react'
import { useClickOutside } from '@fiji/hooks/use-click-outside'

type Args = {
  distance?: number
  onChange: (value: number) => void
  onClose?: () => void
}

export function useHotelDistance({ distance, onChange, onClose = () => {} }: Args) {
  const { ref } = useClickOutside(onClose)
  const [newDistance, setNewDistance] = useState(distance)
  const handleChangeDistance = useCallback((distance: number) => setNewDistance(distance), [])
  const handleApplyChanges = useCallback(() => {
    onChange(newDistance || 0)
    onClose()
  }, [onChange, onClose, newDistance])
  const handleApplyButton = useCallback(() => newDistance !== undefined && handleApplyChanges(), [
    newDistance,
    handleApplyChanges,
  ])
  const showFooter = distance !== newDistance

  return {
    newDistance,
    handleChangeDistance,
    handleApplyButton,
    showFooter,
    ref,
  }
}
