import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Dialog } from '@etta/ui/dialog'
import type { ActionType } from '../../core/constants'
import { Container } from './multiple-hotel-providers-dialog.styled'
import { getLabels } from './get-labels'

type Props = {
  isOpen: boolean
  actionType?: ActionType | null
  providerName?: string | null
  onClick: () => void
  onBack: () => void
  onClose: () => void
}

const i18nBase = 'PostBooking.MultipleHotelProvidersDialog'

export const MultipleHotelProvidersDialog = observer(function MultipleHotelProvidersDialog({
  isOpen,
  actionType,
  providerName,
  onClick,
  onBack,
  onClose,
}: Props) {
  const { t } = useTranslation()
  const { title, description, buttonLabel } = getLabels({ actionType, providerName })

  return (
    <Dialog isOpen={isOpen} onClose={onClose} horizontalDimension="narrow-desktop">
      <Dialog.Body>
        <Container>
          <Block marginBottom={24}>
            <Text variant="title3" color="mainText">
              {title}
            </Text>
          </Block>
          <Block marginBottom={32}>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {description}
            </Text>
          </Block>
          <Block isFullWidth>
            <Block marginBottom={28}>
              <Button variant="solid" color="primary" fullWidth onClick={onClick}>
                {buttonLabel}
              </Button>
            </Block>
            <Button variant="text" color="primary" fullWidth onClick={onBack}>
              {t(`${i18nBase}.BackToTrip`)}
            </Button>
          </Block>
        </Container>
      </Dialog.Body>
    </Dialog>
  )
})
