import { Skeleton } from '@etta/ui/skeleton'
import { AddButtonBlock } from '../../../details-skeleton-styled'

export const AddButtonItem = () => (
  <AddButtonBlock borderRadius={10}>
    <Skeleton width="100%" height="64px">
      <rect x="60.5" y="22" width="20" height="20" />
      <rect x="90.5" y="25.5" width="86" height="13" />
    </Skeleton>
  </AddButtonBlock>
)
