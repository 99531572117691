import { css } from 'styled-components'

export const TRANSITION_CLASS = 'permission-header'
export const TRANSITION_DURATION = 300

export const transition = css`
  transition: opacity ${TRANSITION_DURATION}ms linear;

  &.${TRANSITION_CLASS}-enter, &.${TRANSITION_CLASS}-appear {
    opacity: 0;
  }

  &.${TRANSITION_CLASS}-appear-done,
    &.${TRANSITION_CLASS}-enter-done,
    &.${TRANSITION_CLASS}-appear-active,
    &.${TRANSITION_CLASS}-enter-active {
    opacity: 1;
  }

  &.${TRANSITION_CLASS}-exit-done, &.${TRANSITION_CLASS}-exit {
    opacity: 0;
  }

  &.${TRANSITION_CLASS}-exit-active {
    opacity: 0;
  }
`
