import { useMemo } from 'react'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import { useDynamicSiteMessagesContext } from '../../interface/use-dynamic-site-messages.context'
import { DynamicSiteMessagesLightboxModalLayout } from './layout'

type Props = {
  messages?: DynamicSiteMessageValueObject[] | null
  isVisible: boolean
  onClose: () => void
}

// TODO: Migrate Lightbox Modal to updated design per SPEC-1445 (https://deem.atlassian.net/browse/SPEC-1445) in the next sprint.
export function DynamicSiteMessagesLightboxModal({ messages = [], isVisible, onClose }: Props) {
  const { dynamicSiteMessagesAction } = useDynamicSiteMessagesContext()

  const messagesToShow = useMemo(() => {
    if (!messages) {
      return []
    }

    return dynamicSiteMessagesAction.mapMessages(messages).filter(({ isDialog }) => isDialog)
  }, [messages, dynamicSiteMessagesAction])

  if (!messagesToShow.length) {
    return null
  }

  return (
    <DynamicSiteMessagesLightboxModalLayout
      messages={messagesToShow}
      isVisible={isVisible}
      onClose={onClose}
    />
  )
}
