import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  isResultEmpty?: boolean
  warningCodes: string[]
}

export function WarningCodesText({ warningCodes, isResultEmpty }: Props) {
  const { t } = useTranslation()
  const i18nBase = isResultEmpty
    ? 'SearchResults.Hotels.WarningMessage.NoResults'
    : 'SearchResults.Hotels.WarningMessage'

  const getWarningMessageText = useCallback(
    (value: string) => {
      switch (value) {
        case 'GET_HOTELS_WARNING_CODE_MAX_NIGHTS_EXCEEDED':
          return t(i18nBase + '.MaxNightsExceeded')
        default:
          return ''
      }
    },
    [i18nBase, t],
  )

  return <>{warningCodes.map(getWarningMessageText).filter(Boolean).join('\n')}</>
}
