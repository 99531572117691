import type { MutableRefObject } from 'react'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { UseComboboxGetInputPropsOptions } from 'downshift'
import { useCombobox } from 'downshift'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { useLocationAutocomplete } from '@fiji/hooks/use-location-autocomplete/use-location-autocomplete'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { getNoResultMessage } from '@fiji/utils/search-mechanism'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { getPlaceSearchType } from './get-place-search-type'

type Props = {
  searchType?: SearchType
  isModalOpen: boolean
  isOutboundTrip?: boolean
  latestPlaces?: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  onChange: (value: Place, address?: AddressInput) => void
  onModalClose: () => void
  inputProps?: UseComboboxGetInputPropsOptions
}

const i18Base = 'Mobility.SearchPage'
const i18PlaceholderBase = 'TripPlanner.BaseSearch.Input'

export function useSearchMechanism({
  onModalClose,
  onChange,
  searchType,
  locationsSortOrder,
  latestPlaces,
  isModalOpen,
  isOutboundTrip,
  inputProps,
}: Props) {
  const { t } = useTranslation()
  const { anchorRef, scrollContainerRef } = useHeaderScroll()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isFetchStarted, setIsFetchStarted] = useState(false)
  const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [places, setPlaces] = useState<Place[]>([])
  const { autocompleteStore } = useAutocompleteContext()
  const modifiedPlaces = places.filter(
    (item) => item.placeType !== PlaceAutocompleteType.ReferencePoint,
  )
  const referencePoints = places.filter(
    (item) => item.placeType === PlaceAutocompleteType.ReferencePoint,
  )

  const isHotelSearch = searchType === 'hotel'

  const handleChange = useCallback(
    (place: Place, address?: AddressInput) => {
      onChange(place, address)
    },
    [onChange],
  )

  const { handleFindPlaces, handleSelectedItemChange } = useLocationAutocomplete({
    onModalClose,
    onChange: handleChange,
    onComplete: setPlaces,
    setErrorMessage,
    setIsLoading,
    useNewService: true,
    setIsFetchStarted,
  })

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    reset,
  } = useCombobox({
    items: isHotelSearch
      ? [...modifiedPlaces, ...referencePoints, ...(latestPlaces ?? [])]
      : [...places, ...(latestPlaces ?? [])],
    selectedItem: null,
    itemToString: (item) => item?.name || '',
    initialHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem }) => handleSelectedItemChange({ selectedItem }),
    onInputValueChange: ({ inputValue }) => {
      autocompleteStore.setQuery(inputValue)
      handleFindPlaces({
        place: inputValue,
        sortOrder: locationsSortOrder,
        searchType: getPlaceSearchType(searchType),
      })
    },
  })

  const inputValueRef = useRef(inputValue)
  const hasAnyPlaces = Boolean(places.length > 0 || (latestPlaces ?? [])?.length > 0)
  const hasSearchValue = searchInputRef.current?.defaultValue.length > 0

  const noResultMessage = getNoResultMessage({
    searchType,
    hasAnyPlaces,
    hasSearchValue,
    isFetchStarted,
    isLoading,
  })

  const isTrainNoResultVisible =
    (places.length > 0 ? false : hasSearchValue) &&
    searchType === 'train' &&
    isFetchStarted &&
    !isLoading

  const isResultsListOpen = isOpen || hasAnyPlaces || !!errorMessage

  const searchFieldPlaceholder = useMemo(() => {
    if (searchType === 'car-rental') {
      return t(`${i18PlaceholderBase}.${isOutboundTrip ? 'PickupLocation' : 'DropoffLocation'}`)
    }

    if (isHotelSearch) {
      return t(`${i18PlaceholderBase}.Location`)
    }

    if (searchType === 'mobility') {
      return t(`${i18Base}.${isOutboundTrip ? 'PickUp' : 'DropOff'}`)
    }

    return t(`${i18PlaceholderBase}.${isOutboundTrip ? 'From' : 'To'}`)
  }, [isHotelSearch, isOutboundTrip, searchType, t])

  const handleCurrentLocation = useCallback(
    (place: Place, address?: AddressInput) => {
      onChange(place, address)
      onModalClose()
    },
    [onChange, onModalClose],
  )

  useEffect(() => {
    if (isModalOpen && searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [isModalOpen, searchInputRef])

  const getSearchInputProps = useCallback(() => {
    const props = getInputProps({ ...inputProps, ref: searchInputRef })

    const isSelected =
      latestPlaces?.some((place) => place.name === props.value) ||
      places.some((place) => place.name === props.value)

    if (!isSelected) {
      inputValueRef.current = props.value
    }

    return {
      ...props,
      value: inputValueRef.current,
    }
  }, [getInputProps, inputProps, latestPlaces, places])

  return {
    menuProps: getMenuProps(),
    searchInputProps: getSearchInputProps(),
    searchInputContainerProps: getComboboxProps(),
    highlightedIndex,
    getItemProps,
    inputValue,
    noResultMessage,
    reset,
    isTrainNoResultVisible,
    isResultsListOpen,
    handleCurrentLocation,
    searchFieldPlaceholder,
    anchorRef,
    scrollContainerRef,
    searchInputRef,
    setIsLoading,
    hasAnyPlaces,
    isLoading,
    errorMessage,
    isHotelSearch,
    referencePoints,
    modifiedPlaces,
    places,
  }
}
