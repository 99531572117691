import type { HorizontalDimension } from '../../types'

export const getContentWidth = (widthDimension?: HorizontalDimension) => {
  switch (widthDimension) {
    case 'content-940':
      return 940
    case 'content-760':
      return 760
    case 'regular':
    default:
      return 576
  }
}
