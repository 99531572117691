import { useTranslation } from 'react-i18next'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { TotalDeviationDescription } from '../mobile/rtp-eco-check-modal-mobile-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
  isHotelOnly: boolean
  sustainabilityScore: number
}

export function RtpEcoCheckModalHotelDeviationDescription({
  deviationLevel,
  isHotelOnly,
  sustainabilityScore,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const description = isHotelOnly
    ? t(i18nBase + '.YourHotelRating', {
        sustainabilityScore,
      })
    : t(i18nBase + '.SustainabilityScoreOutOfHundred', {
        sustainabilityScore,
      })

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average: {
      return (
        <TotalDeviationDescription color="bodyText" aria-label={description}>
          {description}
        </TotalDeviationDescription>
      )
    }
    case EmissionsDeviationLevel.Less: {
      return (
        <TotalDeviationDescription
          color="error"
          aria-label={description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )
    }

    case EmissionsDeviationLevel.More: {
      return (
        <TotalDeviationDescription
          color="success"
          aria-label={description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )
    }
    default:
      return null
  }
}
