import { useTranslation } from 'react-i18next'
import { TravelPolicyButton } from './travel-policy-button-styled'
import type { Props } from './types'

export function SearchTravelPolicyButton({
  onClick,
  isDelegateMode,
  dataTrackingId,
  isMobile,
}: Props) {
  const { t } = useTranslation()
  return (
    <>
      <TravelPolicyButton
        aria-label={t('Accessibility.TapToViewWithLabel', { label: t('TravelPolicy') })}
        variant="text"
        size={isMobile ? 'small' : 'normal'}
        onClick={onClick}
        data-tracking-id={dataTrackingId}
        isDelegateMode={isDelegateMode}>
        {t('TravelPolicy')}
      </TravelPolicyButton>
    </>
  )
}
