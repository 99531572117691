import { Swap } from '@etta/ui/swap'
import { ExpandedPreferences } from '../../expanded-preferences'
import { SelectedPreferences } from '../../selected-preferences'
import { Header } from '../../header'
import type { Props } from '../types'
import { PreferenceBoxContainer } from './preference-block-mobile-styled'

export function PreferenceBlockMobile({
  segmentState,
  isPreferencesEmpty,
  preferenceType,
  onEditPreference,
  areAllPreferencesUnavailable,
  isOpen,
  handleClose,
  handleOpen,
  filteredPreferences,
  availableToSelect,
}: Props) {
  return (
    <PreferenceBoxContainer isError={false}>
      <Header
        segmentState={segmentState}
        isEmpty={isPreferencesEmpty}
        type={preferenceType}
        onEdit={onEditPreference}
        areAllPreferencesUnavailable={areAllPreferencesUnavailable}
      />
      <Swap
        is={isOpen}
        isSlot={
          <ExpandedPreferences
            onClick={handleClose}
            preferences={filteredPreferences}
            type={preferenceType}
            availableToSelect={availableToSelect}
          />
        }>
        <SelectedPreferences
          onClick={handleOpen}
          preferences={filteredPreferences}
          type={preferenceType}
          availableToSelect={availableToSelect}
        />
      </Swap>
    </PreferenceBoxContainer>
  )
}
