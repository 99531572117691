import styled, { css } from 'styled-components'
import {
  captionStrongCaps,
  footnoteMedium,
  footnoteStrong,
  headline,
  title2,
  linkSmall,
  UnstyledButton,
} from '@fiji/style'

import type { ButtonProps } from '@etta/ui/button'
import { Button } from '@etta/ui/button'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'

const BORDER_RADIUS = '14px'

const background = css`
  background-color: ${({ theme }) => theme.colors.white};
`

const separator = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Container = styled.div<{ hasBorder: boolean }>`
  border-radius: ${BORDER_RADIUS};
  ${({ hasBorder, theme }) =>
    hasBorder &&
    css`
      border: 2px solid ${theme.colors.error};
    `}
`

export const Header = styled.div<{ hasBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${(props) => {
    if (props.hasBorder) {
      return `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`
    }

    return '0'
  }};
  ${separator};
  ${background};
`

export const HeaderDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const StyledDate = styled.span`
  color: ${({ theme }) => theme.colors.bodyText};
  ${captionStrongCaps};
`

export const Cost = styled.span<{ isCrossedOut?: boolean }>`
  color: ${({ theme, isCrossedOut }) => theme.colors[isCrossedOut ? 'bodyText2' : 'mainText']};
  ${headline};

  ${({ isCrossedOut }) =>
    isCrossedOut &&
    css`
      text-decoration: line-through;
    `}}

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const ViewDetailsTarget = styled.div`
  cursor: pointer;
`

export const Body = styled.div<{ hasBorderRadius: boolean }>`
  padding: 16px 24px 20px;
  ${({ hasBorderRadius }) =>
    css`
      ${hasBorderRadius &&
      css`
        border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
      `}
      ${!hasBorderRadius && separator};
    `};
  ${background};
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.mainText};
  ${title2};
`

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const Location = styled.span`
  ${textCSS};
`

export const ListItem = styled.li`
  display: flex;
  flex-shrink: 0;
  text-transform: capitalize;
  max-width: 100%;
  ${textCSS};
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`

export const BaggageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 11px 0 20px 0;
  padding: 0;
`

export const BaggageLink = styled(Text).attrs({
  variant: 'footnoteStrong',
  textOverflow: 'ellipsis-two-line',
  color: 'primary',
})<{ margin?: string }>`
  cursor: pointer;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`

export const Footer = styled.div<{ isInvalid: boolean; isOutOfPolicyExp: boolean }>`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px;
  min-height: 56px;
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  ${({ isInvalid, theme }) =>
    isInvalid
      ? css`
          background-color: ${theme.colors.error1};
        `
      : background};
  ${({ isOutOfPolicyExp }) =>
    isOutOfPolicyExp &&
    css`
      justify-content: flex-start;
      gap: 8px;
    `}
`

export const FooterBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${footnoteStrong};
  color: ${({ theme }) => theme.colors.mainText};
`

export const SelectReasonButton = styled(Button).attrs<ButtonProps, ButtonProps>(() => ({
  variant: 'outline',
  size: 'small',
}))`
  max-height: 30px;
  background: transparent;
`

export const OopDesc = styled.div`
  display: flex;
  padding-right: 7px;
  align-items: center;
  gap: 6px;
`

export const OopLine = styled.span`
  color: ${(p) => p.theme.colors.mainText};
  ${linkSmall};
  width: 100%;
`

export const ActionContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`

export const DeleteLink = styled(Link)`
  margin-left: auto;
`

export const TrashIcon = styled(Icon).attrs(() => ({
  color: 'bodyText2',
  size: 'small',
  name: 'deletePWA',
}))`
  &:hover svg {
    color: ${({ theme }) => theme.colors.error};
  }
`

export const CardStatusBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 6px 2px 5px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.borderDark};
`

export const CardStatus = styled.div<{ isHidingBeforeStatus?: boolean }>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.mainText};
  ${captionStrongCaps}

  ${(p) => {
    if (!p.isHidingBeforeStatus) {
      return `&::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 7.5px;
        background-color: ${p.theme.colors.primary};
        margin-right: 4px;
      }`
    }

    return ''
  }}
`

export const ChevronIconContainer = styled(UnstyledButton)`
  display: flex;
  gap: 8px;
  align-items: center;
  max-height: 31px;
  position: relative;
  right: -4px;
  white-space: nowrap;
  &:hover * {
    color: ${({ theme }) => theme.colors.primary1};
  }
`

export const PrimaryLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary1};
`

export const CostWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ChangePriceInfo = styled.div`
  display: flex;
  margin-left: auto;
  padding-right: 8px;
`

export const PriceIcon = styled(Icon)<{ withRotate?: boolean }>`
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 1px;
  ${({ withRotate }) =>
    withRotate &&
    css`
      transform: rotate(-180deg);
    `}
`

export const PriceText = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
`

export const StaticActionText = styled(Text).attrs({
  variant: 'captionStrong',
  color: 'mainText',
})`
  line-height: 1.5;
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 100px;
  margin-top: auto;

  & img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
`
