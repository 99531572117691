import type { Rate, RateRange, TripStatus } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { usePrice } from './use-price'

type Props = {
  estimatedPriceRange?: RateRange | null
  price?: Rate | null
  tripStatus?: TripStatus | null
}

export function Price({ price, estimatedPriceRange, tripStatus }: Props) {
  const { priceDetailsList, formattedTotal, rideHailPriceTitle } = usePrice({
    price,
    estimatedPriceRange,
    tripStatus,
  })

  return (
    <PriceDetails
      priceDetailsList={priceDetailsList}
      totalCost={formattedTotal}
      isRideHailPriceDetails
      rideHailPriceTitle={rideHailPriceTitle}
    />
  )
}
