import { Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import type { RideHailAirportPickupFlightValueObject } from '../../core/value-objects/ride-hail-airport-pickup.value-object'
import type { RideHailAirPortPickupQueryParamsType } from '../types'

@Store()
export class RideHailAirportPickupStore {
  private _isError: boolean = false
  private _isLoading: boolean = false
  private _arrivalFlight: RideHailAirportPickupFlightValueObject | null = null
  private _arrivalFlightsList: RideHailAirportPickupFlightValueObject[] | null = null
  private _isCustomFlight: boolean = false

  mobilitySearchQueryParams = new QueryParams<RideHailAirPortPickupQueryParamsType>(
    {},
    { caseStyle: 'kebab-case' },
  )

  setArrivalFlight(flight: RideHailAirportPickupFlightValueObject | null) {
    this._arrivalFlight = flight
  }

  setArrivalFlightsList(flights: RideHailAirportPickupFlightValueObject[] | null) {
    this._arrivalFlightsList = flights
  }

  setIsError(isError: boolean) {
    this._isError = isError
  }

  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  setIsCustomFlight(isCustomFlight: boolean) {
    this._isCustomFlight = isCustomFlight
  }

  get arrivalFlight() {
    return this._arrivalFlight
  }

  get arrivalFlightsList() {
    return this._arrivalFlightsList
  }

  get isError() {
    return this._isError
  }

  get isLoading() {
    return this._isLoading
  }

  get isReserveAirportPickup() {
    return this._arrivalFlight != null
  }

  get isCustomFlight() {
    return this._isCustomFlight
  }

  dropStore() {
    this._isLoading = false
    this._isError = false
    this._arrivalFlight = null
    this._arrivalFlightsList = null
    this._isCustomFlight = false
  }
}
