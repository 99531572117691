import { useEffect } from 'react'
import { usePostBookingContext } from '@etta/modules/post-booking'

type Args = {
  bookingId?: string
}

export function useLoadPostBooking({ bookingId }: Args) {
  const { postBookingTripActions, postBookingTripStore } = usePostBookingContext()
  useEffect(() => {
    if (!bookingId) {
      return
    }
    postBookingTripActions.getTripDetails({ id: bookingId })
  }, [bookingId, postBookingTripActions])
  const { isOnHold, isLoading, segments, pnrNumber } = postBookingTripStore

  return {
    bookedSegments: segments,
    isTripLoading: isLoading,
    isTripOnHold: isOnHold,
    pnrNumber,
  }
}
