import styled, { css } from 'styled-components'
import type { HotelImagePlaceholderSkin } from './types'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
`

export const Image = styled.img`
  width: 100%;
  max-width: 200%;
`

export const ImageWrapper = styled.div<{ isImageVisible: boolean; headerImage: string }>`
  display: ${(props) => (props.isImageVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${(p) => p.headerImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const HeaderImageBg = styled.div<{ skin?: HotelImagePlaceholderSkin }>`
  ${(p) => {
    switch (p.skin) {
      case 'light':
        return css`
          background: ${(props) => props.theme.colors.borderDark};
          color: ${(props) => props.theme.colors.mainText2};
        `
      default:
      case 'dark':
        return css`
          background: ${(props) => props.theme.colors.bodyText};
          color: ${(props) => props.theme.colors.borderDark};
        `
    }
  }}
  position: relative;
  width: 100%;
  min-height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 75px;
    height: auto;
  }
`
