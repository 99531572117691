import { CreditCardBrandType } from '@fiji/graphql/types'
import { VIRTUAL_PAY } from '@fiji/constants'

export function getCardVendor(cardVendor?: CreditCardBrandType | typeof VIRTUAL_PAY | null) {
  switch (cardVendor) {
    case CreditCardBrandType.Ax:
      return 'Amex'
    case CreditCardBrandType.Vi:
      return 'Visa'
    case CreditCardBrandType.Ca:
      return 'Master Card'
    case VIRTUAL_PAY:
      return ''
    default:
      return 'Generic'
  }
}
