import { useCallback, useState } from 'react'
import type { CreditCard } from '@fiji/graphql/types'

export function useSingleTimeUseCard() {
  const [card, setCard] = useState<Partial<CreditCard> | undefined>(undefined)

  const handleAddCard = useCallback((card: Partial<CreditCard>) => {
    setCard(card)
  }, [])

  return {
    card,
    handleAddCard,
  }
}
