import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useCalculateTripEmissionsQuery } from '@fiji/graphql/hooks'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import {
  logValidationErrorToRollbar,
  SKIP_VALIDATION_ERROR,
  tripEmissionsInputValidation,
} from '../common'
import { toCalculateTripEmissionsInput } from './mappers'

export function useCalculateTripSegmentsEmissions(segments: SegmentPostBookingValueObject[]) {
  const {
    featureFlags: { isSustainabilityEnabled },
  } = useFeatureFlags()

  const calculateTripEmissionsInput = toCalculateTripEmissionsInput(segments)

  const maybeTripEmissionsInputValidationError = tripEmissionsInputValidation({
    input: calculateTripEmissionsInput,
  })
  const isSkipValidationError =
    !maybeTripEmissionsInputValidationError.isNothing() &&
    maybeTripEmissionsInputValidationError.getValue().text() === SKIP_VALIDATION_ERROR

  if (!isSkipValidationError && !maybeTripEmissionsInputValidationError.isNothing()) {
    logValidationErrorToRollbar(maybeTripEmissionsInputValidationError, {
      input: calculateTripEmissionsInput,
    })
  }
  const { data, loading } = useCalculateTripEmissionsQuery({
    variables: {
      input: calculateTripEmissionsInput,
    },
    skip:
      !isSustainabilityEnabled ||
      isSkipValidationError ||
      !maybeTripEmissionsInputValidationError.isNothing(),
  })

  return {
    tripEmissionsResult: data?.calculateTripEmissions,
    loading,
  }
}
