import { useTranslation } from 'react-i18next'
import { UberConfirmationDetails } from '@etta/components/uber-confirmation-details/uber-confirmation-details'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap/swap'
import { IconButton } from '@etta/ui/icon-button'
import { useMobilityBookingDone } from '@fiji/hooks/mobility'
import { ScreenType, useScreenType } from '@fiji/modes'
import { UberTrackingRideViaQRCode } from '@etta/components/uber-tracking-ride-via-qr-code/uber-tracking-ride-via-qr-code'
import {
  ConfirmationDetails,
  Container,
  Content,
  CloseButtonWrapper,
  Footer,
  Header,
  HeaderDescription,
  HeaderTitle,
  IconWrapper,
  UberQrCodeWrapper,
} from './mobility-booking-success-styled'

type Props = {
  handleDone: () => void
  isOnDemandRide?: boolean
}

export function MobilityBookingSuccess({ handleDone, isOnDemandRide }: Props) {
  const { t } = useTranslation()
  const { handleClose } = useMobilityBookingDone()
  const basePath = 'Mobility.ConfirmationPage.'
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  return (
    <Container>
      <Content>
        <Header>
          <div>
            {isOnDemandRide && (
              <CloseButtonWrapper>
                <IconButton icon="closePWA" size="large" color="mainText2" onClick={handleClose} />
              </CloseButtonWrapper>
            )}
            <Icon name="thickCheckMarkPWA" color="mainText2" size={40} />
            <HeaderTitle>{t(basePath + 'RideBooked')}</HeaderTitle>
            <HeaderDescription>{t(basePath + 'HeaderDescription')}</HeaderDescription>
          </div>
        </Header>
        <ConfirmationDetails>
          <UberConfirmationDetails />
        </ConfirmationDetails>
        {isOnDemandRide && isDesktop && (
          <UberQrCodeWrapper>
            <UberTrackingRideViaQRCode
              isUberWithParam={false}
              title={t('Mobility.ConfirmationPage.ScanToTrackingRide')}
            />
          </UberQrCodeWrapper>
        )}
      </Content>
      <Footer>
        <Swap
          is={isOnDemandRide && !isDesktop}
          isSlot={
            <Button color="primary" size="normal" fullWidth onClick={handleDone}>
              {t(basePath + 'TrackYourRide')}
              <IconWrapper name="arrowForwardPWA" size={20} />
            </Button>
          }>
          <Button color="primary" size="normal" fullWidth onClick={handleDone}>
            {t(basePath + 'Done')}
          </Button>
        </Swap>
      </Footer>
    </Container>
  )
}
