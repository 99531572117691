import { observer } from 'mobx-react-lite'
import { CarRentalOfficeSelectorLayout } from '@etta/components/car-rental-office-selector'
import { useCarRentalLocations } from './use-car-rental-locations'

export const CarRentalLocations = observer(function CarRentalLocations() {
  const {
    handleLocationClick,
    setSearchValue,
    locations,
    messages,
    searchDateTime,
    isDropOff,
    hasLocations,
    initialLocationAddress,
    initialLocationName,
    searchValue,
    isDropOffSameAsPickUp,
    geocode: center,
    selectedPickUpLocation,
    selectedDropOffLocation,
    handleConfirmationLocationChange,
    handleConfirmationLocationConfirm,
    handleSameDropOffToggle,
    handleEditSearch,
  } = useCarRentalLocations()

  return (
    <CarRentalOfficeSelectorLayout
      searchPoint={{
        address: initialLocationAddress,
        datetime: searchDateTime,
        name: initialLocationName,
      }}
      locations={locations}
      messages={messages}
      onLocationClick={handleLocationClick}
      isDropoff={isDropOff}
      hasLocations={hasLocations}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      isDropOffSameAsPickUp={isDropOffSameAsPickUp}
      center={center}
      selectedPickUpLocation={selectedPickUpLocation}
      selectedDropOffLocation={selectedDropOffLocation}
      handleConfirmationLocationChange={handleConfirmationLocationChange}
      handleConfirmationLocationConfirm={handleConfirmationLocationConfirm}
      handleSameDropOffToggle={handleSameDropOffToggle}
      handleEditSearch={handleEditSearch}
    />
  )
})
