import { MediaLayout } from '@etta/ui/media-layout'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import type { AirPreferenceExcerpt, ProfileOptions } from '../types'
import { PurchaseUserInfoMobile } from './mobile'
import { PurchaseUserInfoDesktop } from './desktop'

export type Props = {
  onClose: () => void
  onSubmit: (data: PersonalInfo) => void
  personalInfo: PersonalInfo
  primaryTraveler?: PurchaseInfoTravelerValueObject
  isOpen: boolean
  isAir?: boolean
  travelerOptions?: TravelerOptions | null
  isPostPurchase?: boolean
  isKtnHidden?: boolean
  isRedressNumber?: boolean
  preferenceExcerpt?: AirPreferenceExcerpt
  profileOptions: ProfileOptions
  isRail?: boolean
}

export function PurchaseUserInfoLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<PurchaseUserInfoMobile {...props} />}
      desktopSlot={<PurchaseUserInfoDesktop {...props} />}
    />
  )
}
