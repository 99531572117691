import styled, { css } from 'styled-components'

const FOOTER_HEIGHT = '108px'

export const Wrapper = styled.div`
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Body = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1 0px;
`

export const RightColumn = styled.div<{ showGrayBackground?: boolean }>`
  flex-basis: 50%;
  overflow: auto;
  display: grid;
  place-items: center;
  ${(p) =>
    p.showGrayBackground &&
    css`
      background-color: ${p.theme.colors.background};
    `}
`
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 32px ${FOOTER_HEIGHT};
  flex-basis: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 50%;
`
