import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  min-width: 100%;
  padding: 20px;
`

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
`
