import type { RoomRatesModalLayout, RoomRatesTypes } from './types'
import { RoomRatesModalContent } from './room-rates-modal-content'
import { RoomRatesModalWrapper } from './room-rates-modal-wrapper'

type Props = {
  isOpen: boolean
  onClose: () => void
  roomRates?: RoomRatesTypes | null
  layout?: RoomRatesModalLayout
  processId?: string
}

export function RoomRatesModal({ isOpen, onClose, roomRates, layout, processId }: Props) {
  if (!roomRates) {
    return null
  }

  return (
    <RoomRatesModalWrapper isOpen={isOpen} onClose={onClose} layout={layout}>
      <RoomRatesModalContent {...roomRates} processId={processId} />
    </RoomRatesModalWrapper>
  )
}
