// @ts-ignore
import { Expiration } from 'react-credit-card-primitives'
import { TextField } from '@etta/ui/text-field'

type Props = {
  label: string
  value: string
  onChange: (value: string) => void
  error: string
}

type InputProps = {
  value: string
  onChange: (e: { target: { value: string } }) => void
}

export function ExpirationDateField({ label, value, error, onChange }: Props) {
  const [expirationMonth, expirationYear] = value.split('/')

  return (
    <Expiration
      month={parseInt(expirationMonth)}
      year={parseInt(expirationYear)}
      onChange={(args: { rawValue: string }) => {
        onChange(args.rawValue)
      }}
      render={(renderProps: { getInputProps: () => InputProps }) => {
        const { getInputProps } = renderProps
        const inputProps = getInputProps()
        return (
          <TextField
            {...inputProps}
            onChange={(value) => {
              inputProps.onChange({
                target: { value },
              })
            }}
            helperText={error}
            label={label}
            placeholder=""
          />
        )
      }}
    />
  )
}
