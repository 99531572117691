import type { SeatReservationType } from '@fiji/graphql/types'
import { ModalDetails } from '@etta/components/modal-details'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'
import { RailDetailsTicketRestrictionsModalContent } from './rail-details-ticket-restrictions-modal-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  railLeg: TrainSegmentEntity
  railSegmentsLength: number
  seatReservationType: SeatReservationType
  isRailEticket: boolean
  isSeatRequested: boolean
  appliedRailCard: string
  ticketRestrictions?: TicketRestrictionsInfo
}

export function RailDetailsTicketRestrictionsModal({ isOpen, onClose, ...props }: Props) {
  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <RailDetailsTicketRestrictionsModalContent {...props} />
    </ModalDetails>
  )
}
