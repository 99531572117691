import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useTripReviewPage } from './hooks'
import { ReviewTripPageContent } from './review-trip-page-content'
import { ReviewTripPageProvider } from './review-trip-page-provider'
import { HiddenTimer } from './review-trip-hidden-timer'

export const ReviewTripPage = observer(function ReviewTripPage() {
  const { tripActions } = useReviewTripContext()

  useEffect(() => {
    tripActions.setTripIdUrlParams()
    tripActions.loadTrip()
  }, [tripActions])

  const value = useTripReviewPage()
  return (
    <ReviewTripPageProvider value={value}>
      <ReviewTripPageContent />
      <HiddenTimer onExpire={value.onExpire} />
    </ReviewTripPageProvider>
  )
})
