import { useEffect } from 'react'
import { useUserContext } from '@etta/modules/user'

export function useUserProfile() {
  const { userProfileActions } = useUserContext()

  useEffect(() => {
    userProfileActions.getUserProfileWithAdditionalInfo()
  }, [userProfileActions])
}
