import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const HalfBlockInline = styled(Block)`
  display: flex;
  gap: 8px;
`

export const HalfBlock = styled.div`
  flex-grow: 1;
`
