import { Skeleton } from '@etta/ui/skeleton'
import type { Props } from '../../types'
import {
  Container,
  CenterBlock,
  Footer,
  RightBlock,
  LeftBlock,
} from './car-rental-card-skeleton-desktop-styled'

export function CarRentalCardSkeletonDesktop({ className }: Props) {
  return (
    <Container className={className}>
      <LeftBlock>
        <Skeleton width="100%" height="100%">
          <rect width="34px" height="16px" />
          <rect y="19" width="100%" height="120px" />
        </Skeleton>
      </LeftBlock>
      <CenterBlock>
        <Skeleton width="100%" height="100px">
          <rect width="226px" height="26px" />
          <rect y="36" width="96px" height="13px" />
          <rect y="69" width="36px" height="13px" />
        </Skeleton>
        <Footer>
          <Skeleton width="100%" height="13px">
            <rect width="86px" height="13px" />
          </Skeleton>
        </Footer>
      </CenterBlock>
      <RightBlock>
        <Skeleton width="100%" height="100%">
          <rect width="158px" height="26px" />
          <rect y="38" width="85px" height="11px" />
        </Skeleton>
      </RightBlock>
    </Container>
  )
}
