import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { tripReviewFormatDate, TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { getGeocodes } from '@fiji/utils/get-geocodes'
import type { CarLocations } from '@fiji/types'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'

type Args = {
  carDetails: CarRentalCarDetailsValueObject | null
}

export function getCarRentalLocations({ carDetails }: Args): CarLocations | null {
  if (!carDetails) {
    return null
  }

  const geocode = getGeocodes(carDetails.pickUp.address?.geocode)
  const pickUp = {
    date: tripReviewFormatDate(TripReviewDateType.FullDateAndTime, carDetails.pickUp.time) || '',
    location: formatPickUpDropOffAddress(carDetails.pickUp.address),
  }
  const dropOff = {
    date: tripReviewFormatDate(TripReviewDateType.FullDateAndTime, carDetails.dropOff.time) || '',
    location: formatPickUpDropOffAddress(carDetails.dropOff.address),
  }

  return {
    geocode,
    pickUp,
    dropOff,
  }
}
