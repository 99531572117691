import { useEffect } from 'react'
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom'
import type { AppState } from '@fiji/store'
import { useAppDispatch, useAppSelector } from '@fiji/store'

type Args = {
  detectPath: string
  action: ActionCreatorWithPayload<string>
  selector: (state: AppState) => string
}

export function useGlobalLatestRoute({ detectPath, action, selector }: Args) {
  const prevPath = useAppSelector(selector)
  const dispatch = useAppDispatch()
  const { pathname, search } = useLocation()
  const currentUrl = pathname + search

  const isSamePath = currentUrl === detectPath
  const isPathInside = currentUrl.indexOf(detectPath) === 0 && !isSamePath

  useEffect(() => {
    if (isPathInside) {
      dispatch(action(currentUrl))
    }
    if (isSamePath) {
      dispatch(action(detectPath))
    }
  }, [isPathInside, isSamePath, currentUrl, detectPath, dispatch, action])

  if (isPathInside) {
    return { usedPath: detectPath, currentUrl }
  }

  return { usedPath: prevPath, currentUrl }
}
