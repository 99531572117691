import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
// eslint-disable-next-line import/no-restricted-paths
import { RecentSearchesFlightSearchParamsFlightType } from '@fiji/graphql/types'
import { DescendantItem } from '@etta/components/descendant'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { TitleContainer } from '../../recent-searches-list-mobile-styled'
import { useRecentSearchesContext } from '../../../../../interface/use-recent-searches.context'
import {
  ArrowIconWrapper,
  LeftBlock,
  ListItem,
  Location,
  RightBlock,
  TopLineWithoutDates,
} from '../recent-search-items-styled'

type Props = {
  onModalClose?: () => void
}

export const RecentFlightSearchListWithoutDates = observer(
  function RecentFlightSearchListWithoutDates({ onModalClose }: Props) {
    const { t } = useTranslation()
    const title = t('RecentSearches.Title')
    const { featureFlags } = useFeatureFlags()
    const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
    const recentSearchesListWithoutDates =
      recentSearchesStore.recentSearchesWithoutDates.flight.items
    const [recentSearches, setRecentSearches] = useState(recentSearchesListWithoutDates)
    const { autocompleteStore } = useAutocompleteContext()

    useEffect(() => {
      if (!featureFlags.isRecentSearchesFilteringEnabled) {
        return
      }

      const filteredSearhes = recentSearchesListWithoutDates.filter((rs) => {
        const {
          originLocationName,
          destinationLocationName,
        } = recentSearchDataAction.getRecentFlightSearchesWithoutDates(rs.flightSearchParams)
        const query = autocompleteStore.query?.toLowerCase() || ''
        const origin = originLocationName.toLowerCase()
        const destination = destinationLocationName.toLowerCase()

        return origin.includes(query) || destination.includes(query)
      })

      setRecentSearches(filteredSearhes)
    }, [
      autocompleteStore.query,
      recentSearchesListWithoutDates,
      featureFlags,
      recentSearchDataAction,
    ])

    if (
      recentSearchesStore.isShowFlightRecentSearch ||
      !recentSearchesStore.isShowFlightRecentSearchWithoutDates
    ) {
      return null
    }

    return (
      <>
        <TitleContainer aria-label={title}>{title}</TitleContainer>
        {recentSearches.map((item) => {
          const {
            destinationLocationName,
            fillSearchForm,
            originLocationName,
          } = recentSearchDataAction.getRecentFlightSearchesWithoutDates(item.flightSearchParams)
          return (
            <DescendantItem
              key={item.id}
              onSelect={() => {
                onModalClose && onModalClose()
                fillSearchForm()
              }}>
              <ListItem
                onClick={() => {
                  onModalClose && onModalClose()
                  fillSearchForm()
                }}
                data-tracking-id="flight-search-mechanism-recent-searches-list">
                <LeftBlock>
                  <Icon name="flightPWA" size={35} />
                </LeftBlock>
                <RightBlock>
                  <TopLineWithoutDates>
                    <Location>{originLocationName}</Location>
                    <ArrowIconWrapper>
                      {item.flightSearchParams.flightType ===
                      RecentSearchesFlightSearchParamsFlightType.OneWay ? (
                        <Icon name="flightArrowPWA" color="bodyText" />
                      ) : (
                        <Icon name="returnFlightArrowPWA" color="bodyText" />
                      )}
                    </ArrowIconWrapper>
                    <Location>{destinationLocationName}</Location>
                  </TopLineWithoutDates>
                </RightBlock>
              </ListItem>
            </DescendantItem>
          )
        })}
      </>
    )
  },
)
