import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service/history.service'
import { MainPageService } from '../services/main-page.service'

@Action()
export class MainPageActions {
  constructor(
    @Inject() private mainPageService: MainPageService,
    @Inject() private historyService: HistoryService,
  ) {}

  handleNavigate() {
    this.historyService.replace(this.mainPageService.getMainPage())
  }
}
