import type * as yup from 'yup'
import * as R from 'ramda'
import i18n from 'i18next'
import type { Props } from '../types'

type SchemeProps<T extends yup.BaseSchema = yup.BaseSchema> = {
  defaultValue?: any
  scheme: T
} & Props

const i18nBase = 'AdditionalInfoForm.'

export const getBaseScheme = <T extends yup.BaseSchema = yup.BaseSchema>({
  customField,
  scheme,
  defaultValue,
}: SchemeProps<T>) => {
  return R.pipe(
    () => {
      if (!customField.optional) {
        return scheme.required(i18n.t(`${i18nBase}Errors.Required`))
      }

      return scheme
    },
    (scheme) => {
      if (defaultValue !== undefined) {
        return scheme.default(defaultValue)
      }

      if (customField.defaultValue) {
        return scheme.default(customField.defaultValue)
      }

      return scheme
    },
  )() as T
}
