import type { AirSearchQueryType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import {
  AirSearchRestrictions,
  FlightType,
} from '@fiji/hooks/search-queries/use-air-search-query/types'
import { deleteTimezone } from '@fiji/utils/dates'
import { PostBookingAction } from '@fiji/enums'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { CabinClassType, TimeRange } from '@fiji/types'
import { resolveCabinClass } from '../helpers/resolve-cabin-class'
import { resolvePlaces } from '../helpers/resolve-prefill-airports'

export type Args = {
  itineraryId: string
  processId: string
  departureFlight?: FlightPostBookingValueObject
  returnFlight?: FlightPostBookingValueObject
  leg0Time: TimeRange
  leg1Time: TimeRange
  cabinClassesOptions: CabinClassType[]
  returnFlightSearchStartDate?: string // allowedSearchDateRange in trip return flight rule from post booking (trip detail)
  returnFlightSearchEndDate?: string
}

export function getReturnChangeQueryParams({
  itineraryId,
  processId,
  returnFlight,
  departureFlight,
  leg0Time,
  leg1Time,
  cabinClassesOptions,
  returnFlightSearchStartDate,
  returnFlightSearchEndDate,
}: Args): Partial<AirSearchQueryType> {
  return {
    itineraryId,
    bookingId: processId,
    flights: [
      {
        id: 1,
        originPlace: resolvePlaces(departureFlight?.segments)?.originPlace,
        destinationPlace: resolvePlaces(departureFlight?.segments)?.destinationPlace,
        departureDate: departureFlight?.departureDateTime
          ? new Date(deleteTimezone(departureFlight.departureDateTime))
          : undefined,
        returnDate: returnFlight?.arrivalDateTime
          ? new Date(deleteTimezone(returnFlight.arrivalDateTime))
          : undefined,
        cabinClass: resolveCabinClass({
          segments: departureFlight?.segments,
          options: cabinClassesOptions,
        }),
        timeRange: leg0Time,
        returnTimeRange: leg1Time,
      },
      {
        id: 2,
        originPlace: resolvePlaces(returnFlight?.segments)?.destinationPlace,
        destinationPlace: resolvePlaces(returnFlight?.segments)?.originPlace,
        departureDate: returnFlight?.departureDateTime
          ? new Date(deleteTimezone(returnFlight.departureDateTime))
          : undefined,
        returnDate: returnFlight?.arrivalDateTime
          ? new Date(deleteTimezone(returnFlight.arrivalDateTime))
          : undefined,
        cabinClass: resolveCabinClass({
          segments: returnFlight?.segments,
          options: cabinClassesOptions,
        }),
        timeRange: leg1Time,
        returnTimeRange: leg0Time,
      },
    ],
    flightType: FlightType.Round,
    searchRestrictions: AirSearchRestrictions.NoCarrierNoDestinationChange,
    filters: {
      airlines: [],
      destinationAirports: [],
      originTime: null,
      destinationTime: null,
      fareTypes: [],
      originAirports: [],
      stops: [],
      isMatchedWithPolicy: false,
    },
    postBookingAction: PostBookingAction.ModifyReturn,
    returnFlightSearchStartDate,
    returnFlightSearchEndDate,
    isReturnFlightChangeOnly: true, // TODO ATE-70: is it overlapped with postBookingAction
  }
}
