import { useEffect } from 'react'
import { useLoadDelegates } from '@fiji/hooks/delegates/use-load-delegates'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useCalculateTripSegmentsEmissions } from '@fiji/hooks/sustainability/use-calculate-trip-segments-emissions'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { useActionButtons } from './use-action-buttons'

export function useTripDetails() {
  const { isShowDelegateName } = useShowDelegateName()
  const { currentDelegatedUser } = useLoadDelegates()
  const {
    postBookingTripStore,
    postBookingTripActions,
    postBookingTripDetailsActions,
    postBookingCancellationActions,
  } = usePostBookingContext()
  const {
    getCustomerUberAccountStatusActions,
    getCustomerUberAccountStatusStore,
  } = useRideHailSearchContext()
  const {
    trip,
    segments,
    flightSegments,
    groupedSegments,
    params,
    queryParams,
  } = postBookingTripStore

  useEffect(() => {
    postBookingTripActions.loadTrip()
    getCustomerUberAccountStatusActions.getCustomerUberAccountStatus()
  }, [postBookingTripActions, getCustomerUberAccountStatusActions])

  useEffect(() => {
    const onVisibilityChange = () => {
      // if the user's Uber account is in the Ready status we can assume it will not change to another status
      // no need to request the status from the server again
      if (
        document.visibilityState === 'visible' &&
        getCustomerUberAccountStatusStore.status !== UberAccountStatusEnum.Ready
      ) {
        getCustomerUberAccountStatusActions.getCustomerUberAccountStatus()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [getCustomerUberAccountStatusStore, getCustomerUberAccountStatusActions])

  useEffect(() => {
    return () => {
      postBookingTripDetailsActions.dropTripDetailsStore()
      postBookingCancellationActions.dropCancellationStore()
    }
  }, [postBookingTripDetailsActions, postBookingCancellationActions, postBookingTripActions])

  const { handleShare, handleModify, viewState, onCloseViewState } = useActionButtons({
    bookingId: params.id,
    transactionGroupId: queryParams.transactionGroupId,
  })

  const { handleRefetchRequest } = useRefetchRequest({
    loading: postBookingTripStore.isLoading,
    refetch: postBookingTripActions.reloadTrip,
  })

  const {
    tripEmissionsResult,
    loading: areTripSegmentsEmissionsLoading,
  } = useCalculateTripSegmentsEmissions(segments)

  return {
    handleRefetchRequest,
    groupedSegments,
    flightSegments,
    segments,
    trip,
    handleShare,
    handleModify,
    viewState,
    onCloseViewState,
    isShowDelegateName,
    currentDelegatedUser,
    tripEmissionsResult,
    areTripSegmentsEmissionsLoading,
  }
}
