const TOKEN_KEY = '@notification-client-id'

export class NotificationClientIdStore {
  static set(clientId: string) {
    if (!window) {
      return
    }
    window.localStorage.setItem(TOKEN_KEY, clientId)
  }
  static get() {
    if (!window) {
      return
    }
    return window.localStorage.getItem(TOKEN_KEY)
  }
}
