import { useCallback, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import type {
  GetAirEnhancedSeatMapQueryVariables,
  GetAirEnhancedSeatMapQuery,
  SeatMapRow,
} from '@fiji/graphql/types'
import { EnhancedSeatMapCabinClass, SeatmapCabinClass } from '@fiji/graphql/types'
import type { FareSegmentSeatRows } from '@fiji/types'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { GetAirEnhancedSeatMapDocument } from '@fiji/graphql/hooks'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import type { FetchSeatMapSegmentArgs } from './types'

type GqlEnhancedSeatMap = Extract<
  GetAirEnhancedSeatMapQuery['enhancedSeatMap'],
  { __typename: 'EnhancedSeatMap' }
>

function mapToFareSegmentSeatRows(
  {
    segmentId,
    departureAirportCode,
    arrivalAirportCode,
    isSeatMapAvailable,
  }: FetchSeatMapSegmentArgs,
  error = false,
): FareSegmentSeatRows {
  return {
    segmentId: segmentId,
    origin: departureAirportCode,
    destination: arrivalAirportCode,
    seatRows: [],
    readOnly: false,
    isSeatMapAvailable,
    ...(error && { meta: { errorOccurred: true } }),
  }
}

export function useFetchSeatMapSegment() {
  const fetchSegmentsMapControllersRef = useRef<AbortController[]>([])

  const { airSearchStore } = useAirSearchContext()

  const abortAllFetches = useCallback(() => {
    fetchSegmentsMapControllersRef.current.forEach((controller) => controller.abort())
    fetchSegmentsMapControllersRef.current = []
  }, [])

  const client = useApolloClient()

  const getAirSeatMapQuery = useCallback(
    (input: GetAirEnhancedSeatMapQueryVariables['input']) => {
      const controller = new AbortController()

      fetchSegmentsMapControllersRef.current.push(controller)

      return client.query<GetAirEnhancedSeatMapQuery, GetAirEnhancedSeatMapQueryVariables>({
        query: GetAirEnhancedSeatMapDocument,
        variables: { input },
        context: {
          queryDeduplication: false,
          fetchOptions: { controller },
        },
      })
    },
    [client],
  )

  const fetchAirSeatMap = useCallback(
    async (dto: FetchSeatMapSegmentArgs): Promise<FareSegmentSeatRows> => {
      const {
        itineraryId,
        segmentId,
        isSeatMapAvailable,
        departureFullDate,
        departureAirportCode,
        arrivalAirportCode,
        carrierCode,
        rawFlightNumber,
        fareBookingCode,
        fareServiceClass,
        isInstantPurchaseCarrier,
        fareBasisCode,
      } = dto

      try {
        const departureDate = getFormattedTime(
          new Date(deleteTimezone(departureFullDate)),
          'yyyy-MM-dd',
        )

        if (!isSeatMapAvailable || !!isInstantPurchaseCarrier) {
          return mapToFareSegmentSeatRows(dto)
        }

        const flightInfo = airSearchStore.flightLegsFromSearchResult.find(({ segments }) =>
          segments[0]?.id.includes(segmentId),
        )
        // use segment id to find fare, as tripId can be empty
        const fareInfo = flightInfo?.fares.find(({ segments }) =>
          segments?.find(({ segmentId: sid }) => sid.endsWith(segmentId)),
        )
        const isNDCFare = Boolean(fareInfo?.isNDCFare)
        const providerAttributesStr = fareInfo?.providerAttributesStr
        const {
          data: { enhancedSeatMap: seatMap },
          error,
          errors,
        } = await getAirSeatMapQuery({
          origin: departureAirportCode,
          destination: arrivalAirportCode,
          carrierCode: carrierCode,
          flightNumber: rawFlightNumber,
          departureDate,
          bookingCode: fareBookingCode,
          equipmentCode: fareServiceClass,
          fareBasisCode,
          logMetadata: { itineraryId },
          ...(isNDCFare && providerAttributesStr && { isNdc: isNDCFare, providerAttributesStr }),
        })

        if (error || errors?.length || seatMap.__typename !== 'EnhancedSeatMap') {
          return mapToFareSegmentSeatRows(dto, true)
        }

        return mapEnhancedSeatMapRowsToFareSegmentSeatRows(seatMap, isSeatMapAvailable, segmentId)
      } catch {
        return mapToFareSegmentSeatRows(dto, true)
      }
    },
    [getAirSeatMapQuery, airSearchStore.flightLegsFromSearchResult],
  )

  return {
    fetchAirSeatMap,
    abortAllFetches,
  }
}

function mapEnhancedSeatMapRowsToFareSegmentSeatRows(
  seatMap: GqlEnhancedSeatMap,
  isSeatMapAvailable: boolean,
  segmentId: string,
): FareSegmentSeatRows {
  return {
    origin: seatMap.origin,
    destination: seatMap.destination,
    seatRows: mapGQLEnhancedSeatMapToSeatRows(seatMap),
    readOnly: seatMap.readOnly,
    isSeatMapAvailable,
    segmentId,
  }
}

function mapGQLCabinClassToCabinClass(
  input: GqlEnhancedSeatMap['cabins'][number]['cabinClass'],
): SeatmapCabinClass {
  switch (input) {
    case EnhancedSeatMapCabinClass.Coach:
      return SeatmapCabinClass.Coach
    case EnhancedSeatMapCabinClass.PremiumCoach:
      return SeatmapCabinClass.PremiumCoach
    case EnhancedSeatMapCabinClass.Business:
      return SeatmapCabinClass.Business
    case EnhancedSeatMapCabinClass.First:
      return SeatmapCabinClass.First
  }
}

function mapGQLEnhancedSeatMapRowToSeatMapRow(
  row: GqlEnhancedSeatMap['cabins'][number]['rows'][number],
  gqlCabinClass: GqlEnhancedSeatMap['cabins'][number]['cabinClass'],
): SeatMapRow {
  return {
    cabinClass: mapGQLCabinClassToCabinClass(gqlCabinClass),
    number: row.number,
    seat: row.seat.map((seat) => {
      return {
        available: seat.available,
        column: seat.column,
        hasBulkhead: seat.hasBulkhead,
        hasGalley: seat.hasGalley,
        hasLavatory: seat.hasLavatory,
        isExitRowSeat: seat.isExitRowSeat,
        isNearAisle: seat.isNearAisle,
        isNearWindow: seat.isNearWindow,
        isOverWing: seat.isOverWing,
        isPaid: seat.isPaid,
        isPremium: seat.isPremium,
        isSmokingAllowed: seat.isSmokingAllowed,
        isSuitableForHandicapped: seat.isSuitableForHandicapped,
        isUpperDeck: seat.isUpperDeck,
        number: seat.number,
      }
    }),
  }
}

function mapGQLEnhancedSeatMapToSeatRows(seatMap: GqlEnhancedSeatMap): SeatMapRow[] {
  return seatMap.cabins.reduce((prev, curr) => {
    const rows = curr.rows.map((row) => mapGQLEnhancedSeatMapRowToSeatMapRow(row, curr.cabinClass))
    prev.push(...rows)
    return prev
  }, [] as SeatMapRow[])
}
