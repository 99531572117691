import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 24px 16px;
`

export const ImageWrapper = styled.div`
  width: 110px;
  height: 110px;
  margin-left: 12px;

  img {
    height: 100%;
    border-radius: 4px;
  }
`

export const LinkWrapper = styled.div`
  margin-top: 8px;
`
