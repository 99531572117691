import type { PropsWithChildren } from 'react'
import { MessageContainerLayout } from './layout'

export type Props = PropsWithChildren<{
  isClosable?: boolean
}>

export function MessageContainer({ isClosable, children }: Props) {
  return <MessageContainerLayout isClosable={isClosable}>{children}</MessageContainerLayout>
}
