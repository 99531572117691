import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type {
  AirFilterAirport,
  AirFilterOptions,
  AirFiltersType,
} from '@etta/modules/air-search/core/value-objects/air-filters'
import { anyStopsOption } from '@etta/modules/air-search/core/constants/stops'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { takeoffLandingRanges } from './get-take-off-landing-ranges'

type Args = {
  isCustomTimeReplaced?: boolean | null
  filters?: AirFilterOptions
  legNumber: number | null
  flights?: Flight[]
  areNearbyAirportsIncluded: boolean
  initialTimeRange?: {
    takeoff: AirFilterOptions['originTime']
    landing: AirFilterOptions['destinationTime']
  }
}

const EMPTY_FILTERS: AirFiltersType = {
  airlines: [],
  stops: [],
  originAirports: [],
  destinationAirports: [],
  destinationTime: null,
  originTime: null,
  fareTypes: [],
  isMatchedWithPolicy: false,
}

function getDefaultAirFilters(flight: Flight, areNearbyAirportsIncluded: boolean): AirFiltersType {
  if (areNearbyAirportsIncluded) {
    return {
      airlines: [],
      stops: [],
      originAirports: [],
      destinationAirports: [],
      destinationTime: null,
      originTime: null,
      fareTypes: [],
      isMatchedWithPolicy: false,
    }
  }
  const origin: AirFilterAirport[] = flight.originPlace?.airportCode
    ? [{ code: flight.originPlace?.airportCode }]
    : []
  const destination: AirFilterAirport[] = flight.destinationPlace?.airportCode
    ? [{ code: flight.destinationPlace?.airportCode }]
    : []

  return {
    airlines: [],
    stops: [],
    originAirports: origin,
    destinationAirports: destination,
    isMatchedWithPolicy: false,
    destinationTime: null,
    originTime: null,
    fareTypes: [],
  }
}

export function getDefaultFilters({
  isCustomTimeReplaced,
  filters,
  legNumber,
  flights,
  areNearbyAirportsIncluded,
}: Args): AirFiltersType {
  if (legNumber === null || !flights?.length) {
    return EMPTY_FILTERS
  }

  const flightLeg = flights[legNumber]

  const defaultFilters = getDefaultAirFilters(flightLeg, areNearbyAirportsIncluded)

  const { isNewAirExposedFiltersEnabled } = FeatureFlagsStore.getInstance().flags

  const filterFareTiers = isNewAirExposedFiltersEnabled
    ? []
    : filters?.fareTypes.sort((a, b) => a.id - b.id)?.filter(Boolean)

  if (!filters) {
    return defaultFilters
  }

  let takeoff = null
  let landing = null

  if (
    filters.originTime?.start &&
    filters.originTime?.end &&
    filters.destinationTime?.start &&
    filters.destinationTime?.end
  ) {
    const timeRanges = takeoffLandingRanges({
      flight: flightLeg,
      destinationTime: filters.destinationTime,
      originTimeRange: filters.originTime,
      isCustomTimeReplaced,
    })
    takeoff = timeRanges.takeoff
    landing = timeRanges.landing
  }

  return {
    ...defaultFilters,
    stops: [anyStopsOption],
    fareTypes: filterFareTiers ?? [],
    originTime: takeoff,
    destinationTime: landing,
  }
}
