import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    right: 0;
    top: calc(50% - 16px);
    background: ${({ theme }) => theme.colors.borderLight};
  }
`

export const Container = styled.div<{ expired?: boolean }>`
  width: fit-content;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 12px 10px;
  ${(p) =>
    p.expired &&
    css`
      padding-right: 34px;
    `}
`

export const ExpiredIconContainer = styled.div`
  position: absolute;
  background: ${(p) => p.theme.colors.warning};
  border-radius: 50%;
  border: 3px solid ${(p) => p.theme.colors.white};
  padding: 2px;
  top: -15%;
  right: -27%;

  & > div {
    position: relative;
    top: -1px;
  }
`

export const InfoBlock = styled.div`
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
