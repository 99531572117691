import type { TripType } from '@etta/core/enums/trip-type.enum'
import { useTripNoContent } from './use-trip-no-content'
import { TripNoContentLayout } from './layout'

type Props = {
  tripType: TripType
}

export function TripNoContent({ tripType }: Props) {
  const {
    descriptionAriaLabel,
    handleRedirect,
    buttonLabel,
    buttonAriaLabelCaption,
    tripTypeTranslation,
    tripsTranslation,
    noContentInfoTranslation,
  } = useTripNoContent({ tripType })

  return (
    <TripNoContentLayout
      descriptionAriaLabel={descriptionAriaLabel}
      onButtonClick={handleRedirect}
      buttonLabel={buttonLabel}
      buttonAriaLabelCaption={buttonAriaLabelCaption}
      noContentInfoTranslation={noContentInfoTranslation}
      tripsTranslation={tripsTranslation}
      tripTypeTranslation={tripTypeTranslation}
    />
  )
}
