import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { Container } from './readonly-banner-styled'

const i18nBase = 'AirSeatMap.ReadonlyBanner'

export const ReadonlyBanner = observer(function ReadonlyBanner() {
  const { t } = useTranslation()
  const { seatMapStore } = useSeatMapContext()

  if (!seatMapStore.isReadonly) {
    return null
  }

  return (
    <Container>
      <Block marginRight={8}>
        <Icon name="infoFilledPWA" size="normal" color="secondary" />
      </Block>
      <Text variant="footnoteMedium" color="secondary">
        {t(`${i18nBase}.Title`)}
      </Text>
    </Container>
  )
})
