import { useTranslation } from 'react-i18next'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { ImageContainer, Image } from './city-background-styled'
import DefaultBgImage from './assets/default.svg?url'
import DefaultPhoto from './assets/default-photo.webp'

type Props = {
  cityImageUrl?: string | null
  isFullWidth?: boolean
}

export function CityBackground({ cityImageUrl, isFullWidth = true }: Props) {
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const i18nBasePath = 'ReviewTrip.PriceSummary'
  const { t } = useTranslation()

  const defaultBgImage = brandConfigurationStore.isAppleTheme ? DefaultPhoto : DefaultBgImage

  return (
    <ImageContainer>
      <Image
        src={cityImageUrl || defaultBgImage}
        alt={t(i18nBasePath + '.CityImage')}
        isFullWidth={isFullWidth}
      />
    </ImageContainer>
  )
}
