import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate, RateRange } from '@fiji/graphql/types'
import { TripStatus } from '@fiji/graphql/types'

type Args = {
  price?: Rate | null
  estimatedPriceRange?: RateRange | null
  tripStatus?: TripStatus | null
}

const i18nBase = 'PostBooking.RideHailDetails.Price'

export function usePrice({ price, estimatedPriceRange, tripStatus }: Args) {
  const { t } = useTranslation()

  const getRatePrice = (
    tripStatus?: TripStatus | null,
    price?: Rate | null,
    estimatedPriceRange?: RateRange | null,
  ) => {
    if (tripStatus === TripStatus.Completed || price) {
      return price
    }

    return {
      primary: {
        amount: estimatedPriceRange?.primary.amountMax ?? 0,
        currency: estimatedPriceRange?.primary.currency ?? '-',
      },
      secondary: null,
    }
  }

  const { priceDetailsList, formattedTotal } = useMemo(() => {
    const ratePrice = getRatePrice(tripStatus, price, estimatedPriceRange)
    const { mainCost: formattedTotal } = formatRate(ratePrice, { morpheme: 'none' })

    return {
      formattedTotal,
      priceDetailsList: [
        {
          title: t(`${i18nBase}.RideHailCost`),
          value: formattedTotal,
          titleTestId: 'ride-cost-text',
          valueTestId: 'ride-cost-amount-text',
        },
      ],
    }
  }, [estimatedPriceRange, price, t, tripStatus])

  const rideHailPriceTitle = useMemo(() => {
    return tripStatus === TripStatus.Completed
      ? t(`PriceDetails.TotalCost`)
      : t(`PriceDetails.EstimatedTotal`)
  }, [tripStatus, t])

  return {
    priceDetailsList,
    formattedTotal,
    rideHailPriceTitle,
  }
}
