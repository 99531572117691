import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace RailSearchErrors {
  export const CreateSearchError = createErrorClass('Create search error')
  export const UnexpectedErrorCreateSearch = createErrorClass(
    'Unexpected error while creating search',
  )
}

export type RailSearchErrorsInstances = InstanceType<
  typeof RailSearchErrors[keyof typeof RailSearchErrors]
>
