import { PinContainer, Marker, PulseBase } from './mobility-map-pinlabel-styled'

type PinLabelProps = {
  isPickUp: boolean
  loading?: boolean
}

export function MobilityMapPinLabel({ isPickUp, loading = false }: PinLabelProps) {
  return (
    <PinContainer>
      {!loading && <Marker isPickUp={isPickUp} />}
      {loading && (
        <Marker isPickUp={isPickUp}>
          <PulseBase delay={'0s'} />
          <PulseBase delay={'0.6s'} />
        </Marker>
      )}
    </PinContainer>
  )
}
