import { Service, Inject } from '@etta/di'
import { routeItems } from '@fiji/routes/route-items/route-items'
import { RoutesStore } from '@etta/interface/stores/routes.store'
import { routes } from './routes'

@Service()
export class ScreensService {
  constructor(
    @Inject()
    private routesStore: RoutesStore,
  ) {}

  handlerRegisterRoutes() {
    // module routes registration
    this.routesStore.addRoutes(routes)

    // old routes registration
    // if (process.env.REACT_APP_DISABLE_LEGACY_ROUTES_REGISTER) return
    this.routesStore.addRoutes(routeItems)
  }
}
