import { observer } from 'mobx-react-lite'
import { SearchResultsHeaderCompact } from '@etta/components/search-results-header/header-compact/search-results-header-compact'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useHotelResultsContextSelector } from '../../hotel-results-provider'

export const HotelResultHeaderMobile = observer(function HotelResultHeaderMobile() {
  const {
    hotelSearchResultsStore,
    hotelFiltersAction,
    hotelFiltersStore,
  } = useHotelSearchResultsContext()
  const state = useHotelResultsContextSelector((state) => {
    const {
      handleBackClick,
      handleOpenDefault,
      locationsLabel,
      datesLabel,
      isFiltersApplied,
    } = state
    return {
      handleBackClick,
      handleOpenDefault,
      locationsLabel,
      datesLabel,
      isFiltersApplied,
    }
  })

  return (
    <SearchResultsHeaderCompact
      onBackClick={state.handleBackClick}
      onLabelClick={state.handleOpenDefault}
      onFilterClick={hotelFiltersAction.handleOpenFiltersModal}
      locationsLabel={state.locationsLabel}
      datesLabel={state.datesLabel}
      isFilterActive={hotelFiltersStore.isFiltersActive}
      isDisabled={hotelSearchResultsStore.isLoading}
    />
  )
})
