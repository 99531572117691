import { MediaLayout } from '@etta/ui/media-layout'
import type { ScreenType } from '@fiji/modes'
import { SelectedDateTitleDesktop } from './desktop'
import { SelectedDateTitleMobile } from './mobile'

export type SelectedDateTitleProps = {
  dateTitle?: string
  forceScreenType?: null | ScreenType
}

export function SelectedDateTitle(props: SelectedDateTitleProps) {
  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<SelectedDateTitleMobile {...props} />}
      desktopSlot={<SelectedDateTitleDesktop {...props} />}
    />
  )
}
