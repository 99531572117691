import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { ButtonIconWrap, ButtonWrap, IconWrap } from './baggage-information-desktop.styled'

const i18Base = 'FlightDetailsModal.FaresDetails.'
const i18BaseBaggageInfo = 'BaggageInformation.'

export function BaggageInformationDesktop({
  onBaggageModalOpen,
  baggageButtonLabel,
  isBaggageDetailsAvailable,
  carrierUrl,
  carrierName,
}: LayoutProps) {
  const { t } = useTranslation()

  if (isBaggageDetailsAvailable) {
    return (
      <>
        <IconWrap>
          <Icon name="checkedBagAllowancePWA" size="medium" />
        </IconWrap>
        <ButtonWrap>
          <Button
            aria-label={t(i18Base + 'BaggageInformation', { button: baggageButtonLabel })}
            variant="text"
            size="small"
            paddings={false}
            onClick={onBaggageModalOpen}
            data-tracking-id="flight-details-fares-baggage-information-button"
            rightIcon={
              <ButtonIconWrap>
                <Icon name="chevronRightPWA" size="small" color="primary" />
              </ButtonIconWrap>
            }>
            {baggageButtonLabel}
          </Button>
        </ButtonWrap>
      </>
    )
  }

  return (
    <Block isFlexBlock alignItems="flex-start">
      <IconWrap>
        <Icon name="noCheckedBagInfoPWA" size="medium" />
      </IconWrap>
      <div>
        <Text variant="footnoteStrong">
          {t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailable')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18BaseBaggageInfo + 'VisitSite')}&nbsp;
          {carrierUrl ? (
            <Link size="small" href={carrierUrl} target="_blank">
              {carrierName}
              <Icon name="externalLinkPWA" color="primary" size={16} />
            </Link>
          ) : (
            <>{t(i18BaseBaggageInfo + 'CheckAirlineWebsite')}</>
          )}
        </Text>
      </div>
    </Block>
  )
}
