import { useCallback } from 'react'
import { RideHailLocationType } from '@etta/modules/ride-hail/core/enum/ride-hail-location-type.enum'
import type { Place } from '@fiji/types'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { getAirportCodeFromPlace } from './get-airport-code'

export function useMobilityGetSuggestedDate() {
  const { suggestionStore, searchRideHailStore } = useRideHailSearchContext()
  const getSuggestedDate = useCallback(
    (pickUpPlace?: Place, dropOffPlace?: Place) => {
      const pickUpDate = suggestionStore.suggestedDates.find((item) => {
        const airportCode = getAirportCodeFromPlace(pickUpPlace)
        if (!airportCode) {
          return false
        }
        return (
          item.type === RideHailLocationType.PickUp &&
          item.airportCode === airportCode &&
          String(item.processId) === String(searchRideHailStore.searchRideHailTripInfo.processId)
        )
      })

      const dropOffDate = suggestionStore.suggestedDates.find((item) => {
        const airportCode = getAirportCodeFromPlace(dropOffPlace)
        if (!airportCode) {
          return false
        }
        return (
          item.type === RideHailLocationType.DropOff &&
          item.airportCode === airportCode &&
          String(item.processId) === String(searchRideHailStore.searchRideHailTripInfo.processId)
        )
      })
      return pickUpDate?.date ?? dropOffDate?.date
    },
    [suggestionStore.suggestedDates, searchRideHailStore.searchRideHailTripInfo.processId],
  )

  return { getSuggestedDate }
}
