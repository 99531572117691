import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { CreditCard } from '@fiji/graphql/types'
import { PaymentCard } from '../../payment-card'

type Props = {
  creditCard?: CreditCard
}

export function RailPaymentCard({ creditCard }: Props) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()

  // FIXME: Minus margin is used here not to brake other segments layout. Only for trains. Can be fixed in the future
  return featureFlags.isRailAccreditationEnabled ? (
    <Block marginTop={-6}>
      <Text variant="footnoteMedium" color="bodyText">
        {t('PaymentSummary.DueTodayRailAccreditation')}
      </Text>
    </Block>
  ) : (
    <PaymentCard creditCard={creditCard} />
  )
}
