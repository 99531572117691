import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'

export const Wrapper = styled.div<{ customBackground?: keyof ColorPalette }>`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
  padding: 16px;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  background: ${({ customBackground, theme }) =>
    customBackground ? theme.colors[customBackground] : theme.colors.white};
  border-radius: 12px;
`
