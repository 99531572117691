import { useTranslation } from 'react-i18next'

import type { OptionType } from '@etta/ui/select'
import { Select } from '@etta/ui/select'
import { StyledTextVariant } from '@fiji/enums'
import { OutOfPolicy } from '../../out-of-policy'
import type { PolicyReasonProps } from '../../types'
import { Container, Heading, ReasonMessage, Label, Error } from './policy-reason-mobile-styled'

export function PolicyReasonMobile({
  reasons,
  segment,
  description,
  policyData,
  onChangeValue,
  value,
  heading,
  error,
  dataTrackingId,
}: PolicyReasonProps) {
  const { t } = useTranslation()
  const i18nBase = 'OutOfPolicyModal.'
  const dataPerSegment = reasons.filter((el) => el.segment === segment)

  if (!dataPerSegment.length) {
    return null
  }

  return (
    <Container>
      <div data-tracking-id={`${dataTrackingId}_oop-section`}>
        <Heading>{heading}</Heading>
        <ReasonMessage>
          <OutOfPolicy
            textStyle={StyledTextVariant.body}
            outOfPolicyReasons={description}
            outOfPolicyData={policyData}
          />
        </ReasonMessage>
        <Label>{t(i18nBase + 'PolicyReasonLabel')}</Label>
        <Select
          label={t(i18nBase + 'PolicyReasonPlaceholder')}
          value={value}
          options={dataPerSegment.map(
            (policy) => ({ label: policy.reason, value: policy.id } as OptionType<number>),
          )}
          onChange={onChangeValue}
          withNoneOption={false}
        />
        {error && <Error>{error}</Error>}
      </div>
    </Container>
  )
}
