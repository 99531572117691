import { Action, Inject } from '@etta/di'
import { CheckoutInfoStore } from '@etta/modules/booking/interface/checkout-info/checkout-info.store'
import { ReviewTripCarRentalService } from '@etta/modules/review-trip/interface/services/review-trip-car-rental.service'
import type { AddCarRentalArgs, RemoveCarRentalArgs, ReplaceCarRentalArgs } from '../types'
import { OutOfPolicyStore } from '../out-of-policy/stores'

@Action()
export class TripCarRentalActions {
  constructor(
    @Inject()
    private readonly reviewTripCarRentalService: ReviewTripCarRentalService,
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,
  ) {}

  handleAddCarRental({ carRentalKey, itineraryId }: AddCarRentalArgs) {
    this.outOfPolicyStore.dropCarRentalOopState()
    return this.reviewTripCarRentalService.addCarRental({
      carRentalKey,
      itineraryId,
    })
  }

  handleRemoveCarRental(args: RemoveCarRentalArgs) {
    this.checkoutInfoStore.memberships.dropMembershipDataByKey('carRental')
    return this.reviewTripCarRentalService.removeCarRental(args)
  }

  handleReplaceCarRental(args: ReplaceCarRentalArgs) {
    this.checkoutInfoStore.specialRequests.dropSpecialRequestDataByKey('carRental')
    this.outOfPolicyStore.dropCarRentalOopState()
    return this.reviewTripCarRentalService.replaceCarRental(args)
  }
}
