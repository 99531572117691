import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const TitleWrapper = styled.div`
  width: 64%;
  margin: 0 8px 9px 0;
`

export const Image = styled.img`
  display: flex;
  width: 100px;
  height: auto;
  align-self: flex-start;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.white};
  opacity: 0.2;
`
