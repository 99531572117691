import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { appMode } from '@fiji/modes'
import { useUpdateTokensWithCookieMutation } from '@fiji/graphql/hooks'
import { useUpdateSessionModalTimer } from './use-update-session-modal-timer'
import { useRemoveSessionModalTimer } from './use-remove-session-modal-timer'
import { TOKEN_UPDATE_TIMER } from './constants'

type Props = {
  isLoading: boolean
}

export function useEttaSessions({ isLoading }: Props) {
  const timerRef = useRef<number | null>(null)
  const [updateTokenWithCookie] = useUpdateTokensWithCookieMutation()
  const { modalTimerId, isOpen, handleClose } = useUpdateSessionModalTimer()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const [canModalOpen, setCanModalOpen] = useState(false)
  const areLoginAndDisplayConfigurationLoaded = !isLoading && !displayConfigurationStore.isLoading

  useDebounce(
    () => {
      setCanModalOpen(areLoginAndDisplayConfigurationLoaded)
    },
    1000,
    [areLoginAndDisplayConfigurationLoaded],
  )

  useRemoveSessionModalTimer({
    modalTimerId,
    isOpen,
    handleClose,
  })

  const handleUpdateTokenWithCookie = useCallback(async () => {
    await updateTokenWithCookie()
  }, [updateTokenWithCookie])

  function handleStayConnect() {
    handleUpdateTokenWithCookie()
    handleClose()
  }

  useEffect(() => {
    if (appMode.isFijiAll || appMode.isEttaMobile || isOpen) {
      return
    }
    const events = ['mousedown', 'keydown']

    const handleEvent = () => {
      if (timerRef.current) {
        return
      }
      handleUpdateTokenWithCookie()
      timerRef.current = window.setTimeout(() => {
        timerRef.current = null
      }, TOKEN_UPDATE_TIMER)
    }

    events.map((event) => window.addEventListener(event, handleEvent))

    return () => {
      events.map((event) => window.removeEventListener(event, handleEvent))
    }
  }, [isOpen, handleUpdateTokenWithCookie])

  return {
    isOpen: isOpen && canModalOpen && areLoginAndDisplayConfigurationLoaded,
    handleStayConnect,
    handleClose,
  }
}
