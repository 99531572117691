import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { BannerContainer, Text, IconWrapper } from './readonly-banner-styled'

export function ReadonlyBanner() {
  const { t } = useTranslation()

  return (
    <BannerContainer>
      <IconWrapper>
        <Icon size={21} color="mainText2" name="seatSelectionPWA" />
      </IconWrapper>
      <Text>{t('AirSeatMap.ReadonlyBanner.Text')}</Text>
    </BannerContainer>
  )
}
