import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Rate } from '@fiji/graphql/types'
import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { formatRate } from '@fiji/utils/money/format-rate'
import { useAppSelector } from '@fiji/store'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType, useScreenType } from '@fiji/modes'

const i18Base = 'SearchResults.Hotels.'

type Args = {
  perDiemRateCap?: Rate | null
}

export function useHotelRateCap({ perDiemRateCap }: Args) {
  const {
    featureFlags: { isHotelRateCapEnabled },
  } = useFeatureFlags()
  const { isPerDiemDisplayEnabled } = useAppSelector(displayConfigurationSelector)
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const isHotelRateCapVisible = useMemo(() => {
    return !!perDiemRateCap && isHotelRateCapEnabled && isPerDiemDisplayEnabled
  }, [perDiemRateCap, isHotelRateCapEnabled, isPerDiemDisplayEnabled])

  const hotelRateCapLabel = useMemo(() => {
    const { mainCost } = formatRate(perDiemRateCap, { morpheme: 'none', isInteger: true })

    return t(i18Base + 'RateCap', { mainCost })
  }, [t, perDiemRateCap])

  return {
    hotelRateCapLabel,
    isHotelRateCapVisible,
    isMobile,
  }
}
