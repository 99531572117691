import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'
import { usePartnerCoBrandingConfigDSMModal } from '@fiji/hooks/partner-cobranding-configuration'
import { DynamicSiteMessagesModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { PartnerCoBrandingConfigModalType } from '@fiji/hooks/partner-cobranding-configuration/types'
import GooglePlayOutlineLogo from '../assets/google-play-outline.svg'
import AppStoreOutlineLogo from '../assets/app-store-outline.svg'
import BlogLogo from '../assets/blog-logo.svg'
import TwitterLogo from '../assets/twitter-logo.svg'
import YoutubeLogo from '../assets/youtube-logo.svg'
import LinkedinLogo from '../assets/linkedin-logo.svg'
import {
  Container,
  Copyright,
  CustomFooter,
  DownloadLinksContainer,
  FooterContent,
  FooterContentContainer,
  IconLinksContainer,
  LinksContainer,
  LogoContainer,
  PartnerLogo,
  SocialNetworkLink,
  SocialNetworkLinksContainer,
} from './desktop-footer-cobranding-styles'
import LinkWithSeparator from './link-with-separator'

function DesktopFooterCobrandingComponent() {
  const { t } = useTranslation()
  const { partnerCoBrandingConfigurationsStore } = usePartnerCoBrandingConfigurationsContext()
  const {
    customMobilePartnerLogoUrl,
    customFooterEnabled,
    customFooter,
    copyright,
    termsOfUseLabel,
    termsOfUseLinkEnabled,
    termsOfUseLinkUrl,
    privacyPolicyLabel,
    privacyPolicyLinkUrl,
    faqLinkEnabled,
    faqLabel,
    faqLinkUrl,
  } = partnerCoBrandingConfigurationsStore.partnerCoBrandingConfigurations
  const {
    messages,
    modalContent,
    enablePrivacyPolicyModal,
    handleOnClick,
    closeModal,
  } = usePartnerCoBrandingConfigDSMModal()

  return (
    <Container data-tracking-id="desktop-footer-cobranding">
      <FooterContentContainer>
        {customMobilePartnerLogoUrl && (
          <LogoContainer>
            <PartnerLogo src={customMobilePartnerLogoUrl} alt="partner logo" />
          </LogoContainer>
        )}
        <FooterContent>
          {customFooterEnabled && customFooter ? (
            <CustomFooter dangerouslySetInnerHTML={{ __html: customFooter }} />
          ) : null}
          <Copyright dangerouslySetInnerHTML={{ __html: copyright }} />
          <LinksContainer>
            <LinkWithSeparator
              label={termsOfUseLabel || t('Profile.TermsOfUse')}
              url={termsOfUseLinkEnabled ? termsOfUseLinkUrl : undefined}
              dataTrackingId="link-terms-of-use"
              ariaLabel="Link to Terms of Use"
              size="small"
              showSeparatorBefore={false}
              onClick={() => handleOnClick(PartnerCoBrandingConfigModalType.Terms)}
            />
            <LinkWithSeparator
              label={privacyPolicyLabel || t('Profile.PrivacyPolicy')}
              url={enablePrivacyPolicyModal ? undefined : privacyPolicyLinkUrl}
              dataTrackingId="link-privacy-policy"
              ariaLabel="Link to Privacy Policy"
              size="small"
              showSeparatorBefore={false}
              showSeparatorEnd={faqLinkEnabled} // Show separator end only if FAQ link is present
              onClick={() => handleOnClick(PartnerCoBrandingConfigModalType.Privacy)}
            />
            {faqLinkEnabled && (
              <LinkWithSeparator
                label={faqLabel}
                url={faqLinkUrl}
                dataTrackingId="link-faq"
                ariaLabel="Link to FAQ"
                size="small"
                showSeparatorBefore={false}
                showSeparatorEnd={false}
              />
            )}
          </LinksContainer>
        </FooterContent>
      </FooterContentContainer>
      <IconLinksContainer>
        <DownloadLinksContainer>
          <a
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Google Play"
            data-tracking-id="link-google-play"
            href="https://play.google.com/store/apps/details?id=com.deem.app.twa">
            <GooglePlayOutlineLogo />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            aria-label="Link to App Store"
            data-tracking-id="link-app-store"
            href="https://apps.apple.com/us/app/etta-for-business-travel/id1477594097">
            <AppStoreOutlineLogo />
          </a>
        </DownloadLinksContainer>
        <SocialNetworkLinksContainer>
          <SocialNetworkLink
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Deem Blog"
            data-tracking-id="link-deem-blog"
            href="https://www.deem.com/resources/blog">
            <BlogLogo />
          </SocialNetworkLink>
          <SocialNetworkLink
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Deem Twitter"
            data-tracking-id="link-deem-twitter"
            href="https://twitter.com/deem">
            <TwitterLogo />
          </SocialNetworkLink>
          <SocialNetworkLink
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Deem Youtube"
            data-tracking-id="link-deem-youtube"
            href="https://www.youtube.com/user/deeminc">
            <YoutubeLogo />
          </SocialNetworkLink>
          <SocialNetworkLink
            target="_blank"
            rel="noreferrer"
            aria-label="Link to Deem Linkedin"
            data-tracking-id="link-deem-linkedin"
            href="https://www.linkedin.com/company/deeminc/">
            <LinkedinLogo />
          </SocialNetworkLink>
        </SocialNetworkLinksContainer>
      </IconLinksContainer>
      <DynamicSiteMessagesModal
        messages={messages}
        isVisible={modalContent.isVisible}
        onClose={closeModal}
      />
    </Container>
  )
}

export const DesktopFooterCobranding = memo(DesktopFooterCobrandingComponent)
