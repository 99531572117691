import { useTranslation } from 'react-i18next'
import type { ListFields } from '../types'
import { Checkbox } from './checkbox-list-styled'

type Props<T extends ListFields> = {
  classesList?: T[]
  values: Set<string>
  onChange: (value: T['code']) => void
  type?: string
}

export function CheckboxList<T extends ListFields>({
  classesList,
  values,
  onChange,
  type,
}: Props<T>) {
  const { t } = useTranslation()

  if (!classesList) {
    return null
  }
  return (
    <>
      {classesList.map((classItem) => {
        const { code, name } = classItem
        const checked = values.has(code)
        const label = type ? t(`${type}.${name}`) : name

        return (
          <Checkbox
            key={code}
            value={name}
            label={label}
            checked={checked}
            onChange={() => onChange(classItem.code)}
          />
        )
      })}
    </>
  )
}
