import type { ChangeEvent, KeyboardEvent } from 'react'
import { useCallback } from 'react'
import { KeyType } from '@fiji/enums'

type Props<T> = {
  onPress: (event: KeyboardEvent<T> & ChangeEvent<T>) => void
  keyType: KeyType
}

export const useKeyboardControl = <T,>({ onPress, keyType }: Props<T>) => {
  const getKeyType = useCallback((e) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      return KeyType.Click
    }

    return KeyType.Other
  }, [])

  const onKeyUp = (event: KeyboardEvent<T> & ChangeEvent<T>) => {
    if (getKeyType(event) === keyType) {
      onPress(event)
    }
  }

  return {
    onKeyUp,
  }
}
