import { Inject, Service } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { UserActivationPaginationStore } from '../stores/user-activation-pagination.store'
import type { LocationState } from '../types'

@Service()
export class UserActivationPaginationService {
  constructor(
    @Inject() private readonly historyService: HistoryService<LocationState>,
    @Inject() private readonly historyStore: HistoryStore<LocationState>,
    @Inject() private readonly userActivationPaginationStore: UserActivationPaginationStore,
  ) {}

  next() {
    const keys = Array.from(this.userActivationPaginationStore.steps.keys())
    const currentIndex = keys.indexOf(this.userActivationPaginationStore.currentStepType)

    if (currentIndex === -1) {
      return
    }

    if (currentIndex > keys.length) {
      return
    }

    for (let i = currentIndex + 1; i < keys.length; i++) {
      const key = keys[i]
      if (this.userActivationPaginationStore.steps.get(key) === true) {
        if (key === 'user-information') {
          this.historyService.replace(this.historyStore.pathname, { currentStepType: key })
        } else {
          this.historyService.push(this.historyStore.pathname, { currentStepType: key })
        }
        return
      }
    }
  }

  previous() {
    const keys = Array.from(this.userActivationPaginationStore.steps.keys())
    const currentIndex = keys.indexOf(this.userActivationPaginationStore.currentStepType)

    if (currentIndex === -1) {
      return
    }

    if (currentIndex < 1) {
      return
    }

    for (let i = currentIndex - 1; i >= 0; i--) {
      const key = keys[i]
      if (this.userActivationPaginationStore.steps.get(key) === true) {
        this.historyService?.goBack()
        return
      }
    }
  }
}
