import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { Action, Inject } from '@etta/di'
import { dateToIso } from '@fiji/utils/dates/date-to-iso'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import type { PersonalInformationInput } from '../stores/personal-information.store'
import { PersonalInformationStore } from '../stores/personal-information.store'
import { BaseSettingsService } from '../services/base.service'
import { BaseSettingsStore } from '../stores/base.store'
import { ProfileActions } from './profile.actions'

@Action()
export class PersonalInformationActions {
  constructor(
    @Inject()
    private readonly personalInformationStore: PersonalInformationStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    readonly profileActions: ProfileActions,
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  handleOpenPersonlaInformationEditModal() {
    this.personalInformationStore.setIsSaveButtonDisabled(true)
    this.personalInformationStore.setIsDobTouched(false)
    this.personalInformationStore.setIsGenderTouched(false)
    this.setPersonalInformation()
    this.personalInformationStore.toggle.handleOpen()
  }

  handleClosePersonlaInformationEditModal() {
    this.personalInformationStore.setIsSaveButtonDisabled(true)
    this.personalInformationStore.setIsDobTouched(false)
    this.baseSettingsStore.dropState(this.personalInformationStore.toggle.handleClose)
  }

  setPersonalInformation() {
    const { personalInformation, email } = this.profileActions.getProfile()
    const newPersonalInformation = {
      ...personalInformation,
      dateOfBirth: personalInformation.dateOfBirth
        ? getDateWithoutTimezoneOffset(personalInformation.dateOfBirth)
        : null,
      email,
      homePhoneCountryCode: personalInformation.phone?.countryCode || '',
      homePhoneNumber: personalInformation.phone?.number || '',
    }
    this.personalInformationStore.setPersonalInformation(newPersonalInformation)
    const additionalInformation =
      this.userProfileStore.userProfile?.personalInformation.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.personalInformationStore.setAdditionalInfoValidator(validator)
  }

  handleFieldChange<T extends keyof PersonalInformationInput>(field: T) {
    return (value: PersonalInformationInput[T]) => {
      this.personalInformationStore.setIsSaveButtonDisabled(false)
      return this.personalInformationStore.setPersonalInformationValue(field, value)
    }
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.personalInformationStore.setIsSaveButtonDisabled(false)
      return this.personalInformationStore.setAdditionalInfoValue(key, value)
    }
  }

  async handlePersonalInfomationSave() {
    const validatorCheck = await this.personalInformationStore.personalInformationValidator.submit()
    const additionalValidatorCheck = await this.personalInformationStore.additionalInformationValidator.submit()
    if (!validatorCheck.isValid || !additionalValidatorCheck.isValid) {
      return
    }
    this.personalInformationStore.isGenderTouched = false
    this.personalInformationStore.isDobTouched = false

    const {
      personalInformationValidator: {
        values: {
          suffix,
          dateOfBirth,
          middleName,
          lastName,
          firstName,
          gender,
          email,
          title,
          username,
          profilePin,
          homePhoneCountryCode,
          homePhoneNumber,
        },
      },
    } = this.personalInformationStore

    const input = {
      dateOfBirth: dateOfBirth ? dateToIso(dateOfBirth) : undefined,
      email,
      firstName,
      genderWithNone: gender || 'NONE',
      lastName,
      middleName,
      mobilePhoneNumber: '',
      suffix: suffix || 'NONE',
      title: title || 'NONE',
      username,
      profilePin,
      homePhone: {
        countryCode: homePhoneCountryCode,
        number: homePhoneNumber,
      },
    }

    let updateAdditionalInformationSuccess: boolean = true

    updateAdditionalInformationSuccess = await this.userProfileService.saveUserProfilePersonalInfo(
      this.personalInformationStore.additionalInformationValidator.values,
    )

    if (updateAdditionalInformationSuccess) {
      this.baseSettingsService.saveUser({
        mode: 'personal-information',
        input,
        handleClose: this.handleClosePersonlaInformationEditModal,
      })
    } else {
      // TODO: do we need to provide a more accurate error message with field failure details??
      this.baseSettingsStore.setErrorState()
      return
    }

    this.userProfileService.fetchUserProfile()
  }
}
