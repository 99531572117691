import type { LayoutProps } from '../../types'
import { HotelDetailsEcoCheckMobileComponent } from './hotel-details-eco-check-mobile-component'
import { HotelDetailsDetailsEcoCheckMobileSkeleton } from './hotel-details-eco-check-skeleton'

export function HotelDetailsEcoCheckMobile({
  loading,
  tonnesOfEmissions,
  averageLocationSustainabilityScore,
  equivalences,
  sustainabilityScore,
  certifications,
}: LayoutProps) {
  if (loading) {
    return <HotelDetailsDetailsEcoCheckMobileSkeleton />
  }

  if (!tonnesOfEmissions || !averageLocationSustainabilityScore || !sustainabilityScore) {
    return null
  }

  return (
    <HotelDetailsEcoCheckMobileComponent
      certifications={certifications || []}
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      sustainabilityScore={sustainabilityScore}
      averageSustainabilityScore={averageLocationSustainabilityScore}
    />
  )
}
