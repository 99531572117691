import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { SegmentStatus } from '@etta/components/segment-status'
import { Swap } from '@etta/ui/swap'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { TaxBreakdownList } from '@etta/components/tax-breakdown-list'
import { Container, TripType } from '../../../../payment-summary-styled'
import { FeesAndTaxes } from '../../../fees-and-taxes'
import { DueTodayCost } from '../../../due-today-cost'
import { UnusedTicketRates } from '../../unused-ticket-rates'
import { FlightSegment } from '../../flight-segment'
import type { LayoutProps } from '../../types'
import { isOpenJawFlight } from '../shared'
import { ServiceFee } from '../../../service-fee'

const i18nBase = 'PaymentSummary.'

export function FlightsDesktopLayout({
  totalWithUnusedTicketRate,
  contentSlot,
  flightTripCost,
  isDesktopTripCostSummary,
  isFromPreviousBooking,
  allowUnusedTicketOptout,
  hasOptOutUnusedTickets,
  segmentState,
  segments = [],
  unusedTicket,
  isHoldFlow,
  showTaxBreakdown,
}: LayoutProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const isOpenJaw = useMemo(() => isOpenJawFlight(segments), [segments])
  const { reviewTripStore } = useReviewTripContext()

  const isDisplayAsRoundTrip =
    !isOpenJaw &&
    segments?.some((s) => {
      return s.isSameCarrierName && s.isRoundTripLeg && s.isRoundTripCost
    })

  const shouldDisplayUnusedTicketBreakdown =
    !isFromPreviousBooking && featureFlags.isDesktopUnusedTicketsEnabled

  const { isGST, taxes } = reviewTripStore.airTaxes
  const shouldDisplayTaxBreakdown =
    showTaxBreakdown && featureFlags.isRetailShoppingUIEnabled && isGST
  const isGSTIncluded = isGST && !showTaxBreakdown
  return (
    <Block>
      <Container>
        <TripType>
          <span aria-label={t(i18nBase + 'Flight')} data-tracking-id="flight-text">
            {t(i18nBase + 'Flight')}
          </span>

          <SegmentStatus
            segmentState={segmentState}
            isFromPreviousBooking={isFromPreviousBooking}
          />
        </TripType>

        <Swap
          is={isDisplayAsRoundTrip}
          isSlot={<FlightSegment segment={segments[0]} isMultiCityFlight={false} />}>
          {segments?.map((segment) => {
            return (
              <FlightSegment key={segment.legId} segment={segment} isMultiCityFlight={isOpenJaw} />
            )
          })}
        </Swap>

        <FeesAndTaxes feesAndTaxes={flightTripCost?.feesAndTaxes} showGSTIncluded={isGSTIncluded} />
        <ServiceFee serviceFee={flightTripCost?.serviceFee} />
        {shouldDisplayTaxBreakdown && <TaxBreakdownList taxes={taxes} />}
        {shouldDisplayUnusedTicketBreakdown && (
          <UnusedTicketRates
            allowUnusedTicketOptout={allowUnusedTicketOptout}
            hasOptOutUnusedTickets={hasOptOutUnusedTickets}
            unusedTicket={unusedTicket}
          />
        )}
      </Container>

      <DueTodayCost
        isBorderVisible={!reviewTripStore.isUnusedTicketApplied}
        rate={totalWithUnusedTicketRate}
        segmentState={segmentState}
        isPayingNow={!isHoldFlow}
        isDesktopTripCostSummary={isDesktopTripCostSummary}
      />

      {contentSlot}
    </Block>
  )
}
