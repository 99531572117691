export enum RailTransportationModeEnum {
  Bus = 'BUS',
  Ferry = 'FERRY',
  Hovercraft = 'HOVERCRAFT',
  Metro = 'METRO',
  PublicTransit = 'PUBLIC_TRANSIT',
  Taxi = 'TAXI',
  Train = 'TRAIN',
  Tram = 'TRAM',
  Tramlink = 'TRAMLINK',
  Transfer = 'TRANSFER',
  Unspecified = 'UNSPECIFIED',
  Walk = 'WALK',
}
