import { useTranslation } from 'react-i18next'
import type { Maybe, Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'

type Props = {
  totalCost?: Maybe<Rate>
  isPaid: boolean
}

const i18nBase = 'PostBooking.TripDetails.CostSummary'

export function useItineraryItem({ totalCost, isPaid }: Props) {
  const { t } = useTranslation()
  const taxText = t(`${i18nBase}.Tax`)
  const totalCostText = isPaid ? t(`${i18nBase}.Paid`) : t(`${i18nBase}.ReservationCost`)
  const { mainCost: formattedPrimarySubtotal, secondCost: formattedSecondarySubtotal } = formatRate(
    totalCost,
  )

  return {
    formattedPrimarySubtotal,
    formattedSecondarySubtotal,
    taxText,
    totalCostText,
  }
}
