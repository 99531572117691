type Args = {
  newPathname: string
  restrictedRoutes?: string[]
  isMatched?: boolean
}

export function checkPathnameRestricted({
  newPathname,
  restrictedRoutes,
  isMatched,
}: Args): boolean {
  if (!restrictedRoutes) {
    return false
  }
  return !restrictedRoutes.some((restrictRoute) =>
    isMatched ? newPathname === restrictRoute : newPathname.indexOf(restrictRoute) === 0,
  )
}
