import { PriceDetails } from '@etta/components/price-details'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from './types'

export function PriceLayoutMobile({ priceDetailsList, totalCost }: LayoutProps) {
  return (
    <Block marginTop={16}>
      <PriceDetails priceDetailsList={priceDetailsList} totalCost={totalCost} />
    </Block>
  )
}
