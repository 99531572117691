import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { Props } from '../types'

const i18Base = 'TripPlanner.BaseSearch.Buttons.'

export function CarRentalSearchButtonMobile({ onSearchClick }: Props) {
  const { t } = useTranslation()

  return (
    <Button onClick={onSearchClick} fullWidth size="large" data-tracking-id="header-search-button">
      {t(i18Base + 'SearchCarRentals')}
    </Button>
  )
}
