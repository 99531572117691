import type { TooltipPlace } from './types'

type Args = {
  place: TooltipPlace
  x: number
  y: number
  width: number
  height: number
}

type Result = {
  top: number
  left: number
}

export function getPosition({ place, x, y, width, height }: Args): Result {
  const halfWidth = width / 2
  const halfHeight = height / 2
  switch (place) {
    default:
    case 'top':
      return {
        top: y,
        left: x + halfWidth,
      }
    case 'bottom':
      return {
        top: y + height,
        left: x + halfWidth,
      }
    case 'left':
      return {
        top: y + halfHeight,
        left: x,
      }
    case 'right':
      return {
        top: y + halfHeight,
        left: x + width,
      }
  }
}
