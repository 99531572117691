import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { formatRate } from '@fiji/utils/money'
import { Row, PaymentLabel, CostSegment } from './trip-costs-sidebar-styled'
import type { SummarySegments } from './types'

type Props = {
  label: string
  segments?: SummarySegments[]
  isChecked?: boolean
}

export function CostSection({ label, segments, isChecked }: Props) {
  const { t } = useTranslation()
  if (!segments?.length) {
    return <></>
  }

  return (
    <>
      {segments.map((segment) => (
        <CostSegment key={label + segment.type}>
          <Block aria-label={label}>
            <PaymentLabel variant="captionStrongCaps">{label}</PaymentLabel>
            {isChecked && <Icon name="checkFilledPWA" size={19} color="success" />}
          </Block>
          <Row
            marginTop={8}
            key={label + segment.type}
            aria-label={t(`PriceDetails.ItemAriaLabel`, {
              title: segment.type,
              amount: formatRate(segment.cost, { morpheme: 'none' }).mainCost,
            })}>
            <Text variant="footnoteMedium">
              {'Train' === segment.type ? t(`PriceDetails.TrainsTotalCost`) : segment.type}
            </Text>
            <Text variant="footnoteMedium">
              {formatRate(segment.cost, { morpheme: 'none' }).mainCost}
            </Text>
          </Row>
        </CostSegment>
      ))}
    </>
  )
}
