import type { Rate } from '@fiji/graphql/types'
import type { ColorPalette } from '@fiji/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { CurrencyMorpheme } from '@fiji/utils/money/insert-morpheme'
import type { TextVariant } from '@etta/ui/text/types'
import { Text } from '@etta/ui/text'
import { PaymentValue, Price } from './rate-price-styled'

type Options = {
  morpheme?: CurrencyMorpheme
  isInteger?: boolean
}

type Props = {
  rate: Rate
  textVariant?: TextVariant
  textColor?: keyof ColorPalette
  secondTextVariant?: TextVariant
  formatOptions?: Options
}

export function RatePrice({
  rate,
  textVariant = 'footnoteStrong',
  textColor,
  secondTextVariant = 'footnoteStrong',
  formatOptions,
}: Props) {
  const { mainCost, secondCost } = formatRate(rate, formatOptions)
  return (
    <PaymentValue data-tracking-id="rate-price-block">
      <Price>
        <Text data-tracking-id="rate-price-main-text" color={textColor} variant={textVariant}>
          {mainCost}
        </Text>
      </Price>
      {secondCost && (
        <Price isSecondary>
          <Text data-tracking-id="rate-price-second-text" variant={secondTextVariant}>
            {secondCost}
          </Text>
        </Price>
      )}
    </PaymentValue>
  )
}
