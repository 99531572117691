import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  cursor: default;
  width: 100%;
  padding: 8px 18px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Etta-Colors-Background-default, #f2f2f2);
`

export const Header = styled.div`
  display: flex;
  cursor: default;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
`

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const ListItem = styled.li<{ textTransform?: 'capitalize' | 'none' }>`
  display: flex;
  flex-shrink: 0;
  text-transform: ${(p) => p.textTransform || 'capitalize'};
  max-width: 100%;
  ${textCSS};
  &:not(:first-of-type):before {
    content: '\\2022';
    margin: 0 5px;
  }
  line-height: 1;
  padding-bottom: 5px;
`
export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`
