import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { HotelSegment } from '../../../../types'
import type { SegmentDetails } from '../../types'
import { getHotelRooms } from './get-hotel-rooms'

type Args = {
  hotelDetails: SegmentDetails['hotelDetails']
}

const i18nBase = 'PostBooking.TripDetails'

export function useHotel({ hotelDetails }: Args) {
  const { t } = useTranslation()
  const { segments, creditCard, isPayNow } = hotelDetails

  const isPayment = creditCard && isPayNow

  const getTranslation = useCallback(
    (label: string) => {
      return t(`${i18nBase}.${label}`)
    },
    [t],
  )

  const rooms = useMemo(() => {
    return getHotelRooms(segments, getTranslation, isPayment)
  }, [segments, getTranslation, isPayment])

  if (!hotelDetails.segments?.length) {
    return
  }

  const hotel: HotelSegment = {
    details: {
      title: t(`${i18nBase}.SegmentsHeadline.Hotels`),
    },
    rooms,
  }

  return hotel
}
