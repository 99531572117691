import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  totalHotels: number
  slideSize: number
  currentHotels?: number
  onNextSlide: () => void
  onPrevSlide: () => void
}

export function useSlider({
  totalHotels,
  slideSize,
  onNextSlide,
  onPrevSlide,
  currentHotels,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const { t } = useTranslation()

  const maxSlides = Math.ceil(totalHotels / slideSize) - 1

  const mainText = t('SearchResults.Hotels.Map.NumberOfHotels', {
    fromHotel: currentSlide * slideSize + 1,
    toHotel: currentSlide === maxSlides ? totalHotels : (currentSlide + 1) * slideSize,
    totalHotels,
  })

  function handlePrevSlide() {
    onPrevSlide()
    setCurrentSlide(currentSlide - 1)
  }

  function handleNextSlide() {
    onNextSlide()
    setCurrentSlide(currentSlide + 1)
  }

  useEffect(() => {
    if (currentHotels) {
      const currentSlide = Math.ceil(currentHotels / slideSize - 1)
      setCurrentSlide(currentSlide)
    }
  }, [currentHotels, slideSize])

  useEffect(() => {
    setCurrentSlide(0)
  }, [totalHotels])

  return {
    mainText,
    currentSlide,
    maxSlides,
    handlePrevSlide,
    handleNextSlide,
  }
}
