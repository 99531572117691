import { useMemo } from 'react'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import { usePreSearchContext } from '@etta/modules/pre-search'
import { useHistoryContext } from '@etta/modules/app/interface/use-history-context'
import { ROUTES } from '@fiji/routes'
import { useComputedCustomFields } from './use-computed-custom-fields'
import { useCustomFields } from './use-custom-fields'

type Props = {
  onSubmit: () => void
}

export function usePreSearch({ onSubmit }: Props) {
  const { historyStore } = useHistoryContext()
  const { preSearchActions, preSearchStore } = usePreSearchContext()

  const fields =
    preSearchStore.searchInitiateInfo?.searchInitiateInfo?.customFieldsConfiguration
      ?.customFieldCollection

  const customFields = useComputedCustomFields(fields)

  const onSubmitFields = (fields: CustomFieldBookingValueObject[]) => {
    preSearchActions.setCustomFields(fields)
    onSubmit()
    preSearchActions.handleClosePreSearchCustomFields()
  }

  const { errors, onFieldChange, values, onSubmitHandler } = useCustomFields({
    customFields,
    onSubmitFields,
  })

  const onBack = () => {
    preSearchActions.handleClosePreSearchCustomFields()
  }

  const shouldShowPreSearchModal = useMemo(() => {
    return (
      preSearchStore.isShowingPreSearchModal &&
      historyStore.matchPathname(ROUTES.explore) &&
      !historyStore.matchPathname('-result') // Pre search modal should never display on any search result page
    )
  }, [historyStore, preSearchStore.isShowingPreSearchModal])

  return {
    customFields,
    handleClosePreSearchCustomFields: preSearchActions.handleClosePreSearchCustomFields,
    handleOpenPreSearchCustomFields: preSearchActions.handleOpenPreSearchCustomFields,
    isOpenPreSearchCustomFields: preSearchStore.isOpenPreSearchCustomFields,
    errors,
    onFieldChange,
    values,
    onSubmitCustomFieldsHandler: onSubmitHandler,
    onPreSearchBack: onBack,
    shouldShowPreSearchModal,
  }
}
