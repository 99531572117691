import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { MonthSkeletonLayoutMobile } from './mobile'
import { MonthSkeletonLayoutDesktop } from './desktop'

export function MonthSkeletonLayout() {
  const { featureFlags } = useFeatureFlags()
  return (
    <MediaLayout
      mobileSlot={<MonthSkeletonLayoutMobile />}
      desktopSlot={<MonthSkeletonLayoutDesktop />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
