import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type { MeetingModeInstances } from '../core/error/meeting-mode.errors'
import { MeetingModeError } from '../core/error/meeting-mode.errors'
import type {
  UpsertMeetingInfoInput,
  MeetingInfoValueObject,
  UpdateMeetingIdCookieInput,
  MeetingIdValueObject,
} from '../core/value-objects/meeting-mode.value-object'
import { MeetingModeDataProvider } from './data-providers/meeting-mode.dataprovider'
import { MeetingInfoMapper } from './mappers/meeting-Info.mapper'

@Adapter()
export class MeetingModeAdapter {
  constructor(
    @Inject()
    private meetingModeDataProvider: MeetingModeDataProvider,
  ) {}

  async upsertMeetingInfo({
    meetingId,
  }: UpsertMeetingInfoInput): PromisedResult<MeetingInfoValueObject, MeetingModeInstances> {
    try {
      const { data, errors } = await this.meetingModeDataProvider.upsertMeetingInfo({
        meetingId,
      })
      if (errors) {
        return Result.Err(new MeetingModeError.UpsertMeetingModeFail(errors))
      }
      if (!data?.upsertMeetingInfo) {
        return Result.Err(new MeetingModeError.UpsertMeetingModeFail(errors))
      }
      if (data.upsertMeetingInfo.__typename === 'UpsertMeetingInfoResponse') {
        return Result.Ok(MeetingInfoMapper.toMeetingInfoValueObject(data.upsertMeetingInfo))
      }
      return Result.Err(new MeetingModeError.UpsertMeetingModeFail(errors))
    } catch (e) {
      return Result.Err(new MeetingModeError.UpsertMeetingModeFail(e))
    }
  }

  async updateMeetingIdInCookie({
    meetingId,
  }: UpdateMeetingIdCookieInput): PromisedResult<MeetingIdValueObject, MeetingModeInstances> {
    try {
      const { data, errors } = await this.meetingModeDataProvider.updateMeetingIdInCookie({
        id: meetingId,
      })
      if (errors) {
        return Result.Err(new MeetingModeError.UpdateMeetingIdFail(errors))
      }
      if (data?.updateMeetingIdCookie.success) {
        return Result.Ok({ success: true })
      }
      return Result.Err(new MeetingModeError.UpdateMeetingIdFail(errors))
    } catch (e) {
      return Result.Err(new MeetingModeError.UpdateMeetingIdFail(e))
    }
  }

  async getMeetingInfo(): PromisedResult<MeetingInfoValueObject, MeetingModeInstances> {
    try {
      const { data, errors } = await this.meetingModeDataProvider.getMeetingInfo()
      if (errors) {
        return Result.Err(new MeetingModeError.GetMeetingInfoFail(errors))
      }
      if (!data?.getMeetingInfo) {
        return Result.Err(new MeetingModeError.GetMeetingInfoFail(errors))
      }
      if (data.getMeetingInfo.__typename === 'UpsertMeetingInfoResponse') {
        return Result.Ok(MeetingInfoMapper.toMeetingInfoValueObject(data.getMeetingInfo))
      }
      return Result.Err(new MeetingModeError.GetMeetingInfoFail(errors))
    } catch (e) {
      return Result.Err(new MeetingModeError.GetMeetingInfoFail(e))
    }
  }
}
