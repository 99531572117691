import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import type { PlainTime as Time } from '@fiji/types'
import { asembleBarValues, getConstants, getEnvironment } from '../../assets'
import { useTimeHandlers } from '../../handlers'

import type { SwipingTimePickerBars, SwipingPickerSubtype } from '../../types'
import { TimeLayout } from './time-layout'

type Props = {
  value?: Time
  defaultValue?: Time
  label: string
  variant?: SwipingPickerSubtype
  shouldShowWheelsOnly?: boolean
  onApply?: (time: Time) => void
  onChange?: (time: Time) => void
}

export function TimePicker({
  value,
  defaultValue,
  label,
  variant,
  onApply,
  shouldShowWheelsOnly,
  onChange,
}: Props) {
  const { t } = useTranslation()
  const basePath = 'SwipingDateTimePicker.'

  // To make circle effect works, each hour and min columns consist of
  // four scrollable smaller sub-columns or bars with time stamps on them.
  // Once one of them is scrolled out, it is flipped back on top (or bottom).

  const usingValue = value || defaultValue

  const { UNIT, MINUTE_GROUP, INIT_BARS } = getConstants(variant)
  const { getInitBarTop } = getEnvironment(variant)

  const _initialMinute = Math.ceil((usingValue?.minutes || 0) / 15) * 15
  const initialMinute = _initialMinute === 60 ? 0 : _initialMinute
  const [minute, setMinute] = useState(initialMinute / MINUTE_GROUP)
  const [minuteFlipping, setMinuteFlipping] = useState<SwipingTimePickerBars>(INIT_BARS)
  const [minuteBarTop, setMinuteBarTop] = useState<SwipingTimePickerBars>(
    getInitBarTop(minute, 'min'),
  )
  const [minuteSwiping, setMinuteSwiping] = useState(0)

  const _hour = (usingValue?.hours || 0) + Math.floor(_initialMinute / 60)
  const [hour, setHour] = useState(_hour % 12)
  const [hourFlipping, setHourFlipping] = useState<SwipingTimePickerBars>(INIT_BARS)
  const [hourBarTop, setHourBarTop] = useState<SwipingTimePickerBars>(getInitBarTop(hour, 'hour'))
  const [hourSwiping, setHourSwiping] = useState(0)

  const [ampm, setAmpm] = useState((usingValue?.hours || 0) >= 12 ? 1 : 0)
  const [ampmSwiping, setAmpmSwiping] = useState(0)

  const { hourHandlers, minuteHandlers, ampmHandlers } = useTimeHandlers({
    variant,
    hour,
    minute,
    minuteBarTop,
    hourBarTop,
    setMinute,
    setHour,
    setAmpm,
    setMinuteSwiping,
    setHourSwiping,
    setAmpmSwiping,
    setMinuteFlipping,
    setHourFlipping,
    setMinuteBarTop,
    setHourBarTop,
  })

  const timePickerValue = useMemo(() => {
    return {
      hours: variant === 'only-min' ? 0 : hour + (ampm ? 12 : 0),
      minutes: variant === 'only-hour' ? 0 : minute * MINUTE_GROUP,
    }
  }, [MINUTE_GROUP, ampm, hour, minute, variant])

  const handleApply = useCallback(() => {
    if (onApply) {
      onApply(timePickerValue)
    }
  }, [onApply, timePickerValue])

  const handleTimeChange = useCallback(() => {
    if (onChange) {
      onChange(timePickerValue)
    }
  }, [onChange, timePickerValue])

  useEffect(() => {
    handleTimeChange()
  }, [handleTimeChange])

  return (
    <>
      <TimeLayout
        shouldShowWheelsOnly={shouldShowWheelsOnly}
        label={label}
        hour={hour}
        minute={minute}
        ampm={ampm}
        minuteGroup={MINUTE_GROUP}
        handleSwipe={[hourHandlers, minuteHandlers, ampmHandlers]}
        variant={variant}
        data={asembleBarValues({
          height: UNIT.height,
          types: ['hour', 'min', 'am'],
          values: [hour, minute, ampm],
          ranges: [UNIT.hourRange, UNIT.minuteRange, UNIT.ampmRange],
          swipes: [hourSwiping, minuteSwiping, ampmSwiping],
          flips: [hourFlipping, minuteFlipping, null],
          tops: [hourBarTop, minuteBarTop, null],
          unitGroup: [0, UNIT.minuteGroup, 0],
        })}
      />
      {!shouldShowWheelsOnly && (
        <Block marginTop="auto" marginHorizontal={16}>
          <Button
            onClick={handleApply}
            btnType="primary"
            fullWidth
            aria-label={`${t(basePath + 'SetTime')} ${t('Accessibility.Button')}. ${t(
              'Accessibility.TapToSelect',
            )}`}>
            {t(basePath + 'SetTime')}
          </Button>
        </Block>
      )}
    </>
  )
}
