import { MediaLayout } from '@etta/ui/media-layout'
import type { ComponentProps } from '../types'
import { UserInfoFormMobile } from './mobile'
import { UserInfoFormDesktop } from './desktop'

export function UserInfoFormLayout(props: ComponentProps) {
  return (
    <MediaLayout
      mobileSlot={<UserInfoFormMobile {...props} />}
      desktopSlot={<UserInfoFormDesktop {...props} />}
    />
  )
}
