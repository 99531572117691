import { Inject, Service } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import { GetTripsListService } from '@etta/modules/post-booking-list'
import { InjectLogger, Logger } from '@etta/infra/services/logger'
// import { Domain } from '@etta/core/enums'
// import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { PostBookingCancellationStore } from '../stores/trip-cancellation/post-booking-cancellation.store'
import { GazooPostBookingAdapter } from '../../infra/gazoo/gazoo-post-booking.adapter'
// import { CartPostBookingAdapter } from '../../infra/cart/cart-post-booking.adapter'
import type { PostBookingErrorsInstances } from '../../core/errors'
import { PostBookingErrors } from '../../core/errors'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'

@Service()
export class PostBookingCancellationService {
  constructor(
    @Inject() private postBookingCancellationStore: PostBookingCancellationStore,
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private postBookingTripListService: GetTripsListService,
    @Inject() private gazooPostBookingAdapter: GazooPostBookingAdapter,
    // @Inject() private cartPostBookingAdapter: CartPostBookingAdapter,
    // @Inject() private displayConfigurationStore: DisplayConfigurationStore,
    @InjectLogger('cancellation-service')
    private logger: Logger,
  ) {}

  async cancelTrip() {
    await this.cancelAction(() =>
      this.gazooPostBookingAdapter.cancelTrip(this.postBookingTripStore.trip.tripId),
    )
  }

  async cancelHotel(id: string) {
    await this.cancelAction(() =>
      this.gazooPostBookingAdapter.cancelTripSegment({
        transactionId: this.postBookingTripStore.trip.processId,
        hotelKeys: [id],
      }),
    )
  }

  async cancelCarRental(id: string) {
    // Disabled for now
    // if (this.displayConfigurationStore.isMod2FlowEnabled) {
    //   await this.cancelAction(() =>
    //     this.cartPostBookingAdapter.cancelTripReservation({
    //       domain: Domain.CarRental,
    //       reservationId: id,
    //     }),
    //   )
    //   return
    // }
    await this.cancelAction(() =>
      this.gazooPostBookingAdapter.cancelTripSegment({
        transactionId: this.postBookingTripStore.trip.processId,
        carRentalKeys: [id],
      }),
    )
  }

  async cancelRideHail(id: string) {
    await this.cancelAction(() =>
      this.gazooPostBookingAdapter.cancelRideHail({
        bookings: [{ bookingID: id }],
      }),
    )
  }

  async cancelFlight() {
    await this.cancelAction(() =>
      this.gazooPostBookingAdapter.cancelTripSegment({
        transactionId: this.postBookingTripStore.trip.processId,
        flightKeys: [this.postBookingTripStore.trip.flightId || ''],
      }),
    )
  }

  private async cancelAction(
    action: () => PromisedResult<{ success: boolean }, PostBookingErrorsInstances>,
  ) {
    this.postBookingCancellationStore.setIsSuccess(false)
    this.postBookingCancellationStore.setViewState('loading')
    this.postBookingCancellationStore.setIsRideHailError(false)

    const result = await action()

    result.match({
      Ok: () => {
        this.postBookingCancellationStore.setIsSuccess(true)
        this.postBookingCancellationStore.setViewState('hidden')
        this.postBookingTripListService.dropAndReloadAllOrders()
      },
      Err: (err) => {
        switch (true) {
          case err instanceof PostBookingErrors.CancelRideHailErrorInWholeTripCancel:
            this.postBookingCancellationStore.setIsSuccess(true)
            this.postBookingCancellationStore.setIsRideHailError(true)
            this.postBookingCancellationStore.setViewState('hidden')
            return
          case err instanceof PostBookingErrors.CancelRideHailError:
            this.postBookingCancellationStore.setIsRideHailError(true)
            this.postBookingCancellationStore.setViewState('ride-hail-error')
            break
          case err instanceof PostBookingErrors.CancelRideHailUnexpectedError:
            this.postBookingCancellationStore.setIsRideHailError(true)
            this.postBookingCancellationStore.setViewState('ride-hail-error')
            break
          case err instanceof PostBookingErrors.CancelRideHailAlreadyCompletedError:
            this.postBookingCancellationStore.setIsRideHailError(true)
            this.postBookingCancellationStore.setViewState(
              'ride-hail-cancel-already-complete-error',
            )
            break
          default:
            this.postBookingCancellationStore.setViewState('error')
            break
        }
        this.logger.error(err)
      },
    })
  }
}
