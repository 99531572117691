import { useState, useEffect, useMemo, useCallback } from 'react'
import type { LoyaltyProgram } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import type {
  AirMembershipValue,
  AirMembershipOption,
} from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import { useFlightLoyaltyError } from '../../use-flight-loyalty-errors'
import { composeFlightLoyalty } from './compose-flight-loyalty'

type StateProps = {
  segments: TripSegmentValueObject[]
  airMemberships: AirMembershipValue[] | null
  loyaltyProgramOptions: AirMembershipOption[] | null
}

export function usePreferenceStates({
  segments,
  airMemberships,
  loyaltyProgramOptions,
}: StateProps) {
  const initialLoyalty = useMemo(() => composeFlightLoyalty({ segments, airMemberships }), [
    segments,
    airMemberships,
  ])

  const [loyaltyFlightValue, setLoyaltyFlight] = useState<LoyaltyProgram[]>([])
  const [loyaltyFlightToRender, setLoyaltyFlightToRender] = useState<LoyaltyProgram[] | null>(null)

  const { flightLoyaltyErrors, hasErrors } = useFlightLoyaltyError({ loyaltyFlightValue })

  const getVendorLabel = useCallback(
    (vendorCode: string) => {
      const loyalty = loyaltyProgramOptions?.find(({ carrierCode }) => carrierCode === vendorCode)

      return loyalty?.carrierName
    },
    [loyaltyProgramOptions],
  )

  const filterLoyaltyFlights = useCallback(
    (data: LoyaltyProgram[]) => {
      return data.map((loyalty) =>
        loyalty.label ? loyalty : { ...loyalty, label: getVendorLabel(loyalty.vendorCode) },
      )
    },
    [getVendorLabel],
  )

  const initialLoyaltyFiltered = useMemo(
    () =>
      initialLoyalty?.map((loyalty) =>
        loyalty.label ? loyalty : { ...loyalty, label: getVendorLabel(loyalty.vendorCode) },
      ) || null,
    [getVendorLabel, initialLoyalty],
  )

  const onChangeLoyaltyFlight = useCallback(
    (data: LoyaltyProgram[]) => {
      setLoyaltyFlight(filterLoyaltyFlights(data))
    },
    [filterLoyaltyFlights],
  )

  const onChangeLoyaltyFlightToRender = useCallback(
    (data: LoyaltyProgram[]) => {
      setLoyaltyFlightToRender(filterLoyaltyFlights(data))
    },
    [filterLoyaltyFlights],
  )

  useEffect(() => {
    if (!loyaltyProgramOptions) {
      return
    }

    if (!initialLoyalty) {
      return
    }

    if (loyaltyFlightValue && loyaltyFlightValue.length !== 0) {
      return
    }

    setLoyaltyFlight(initialLoyaltyFiltered ?? [])
  }, [initialLoyaltyFiltered, initialLoyalty, loyaltyFlightValue, loyaltyProgramOptions])

  useEffect(() => {
    if (!loyaltyProgramOptions) {
      return
    }

    if (!initialLoyalty) {
      return
    }

    if (loyaltyFlightToRender && loyaltyFlightToRender.length !== 0) {
      return
    }

    setLoyaltyFlightToRender(initialLoyaltyFiltered)
  }, [
    onChangeLoyaltyFlightToRender,
    initialLoyalty,
    initialLoyaltyFiltered,
    loyaltyFlightToRender,
    loyaltyProgramOptions,
  ])

  return {
    loyaltyFlightValue,
    onChangeLoyaltyFlight,
    hasErrors,
    flightLoyaltyErrors,
    loyaltyFlightToRender,
    onChangeLoyaltyFlightToRender,
  }
}
