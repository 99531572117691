import { MediaLayout } from '@etta/ui/media-layout'
import { LocationSelectorMobile } from './mobile'
import type { Props } from './types'

export function LocationSelector(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<LocationSelectorMobile {...props} />}
      desktopSlot={<LocationSelectorMobile {...props} />}
    />
  )
}
