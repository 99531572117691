import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'

type Props = {
  'data-tracking-id': string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export function RegularModal({
  isOpen,
  onClose,
  children,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelInfo.Rooms'

  return (
    <Modal data-tracking-id={dataTrackingId} isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Title aria-label={t(i18Base + '.RoomRateDetailsLink')} withBorder>
        {t(i18Base + '.RoomRateDetailsLink')}
      </Modal.Title>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}
