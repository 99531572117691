import type { Maybe } from 'fnscript'
import { Error as NestedError, Just, Nothing } from 'fnscript'
import type { CalculateHotelEmissionsBatchHotelInput } from '@fiji/graphql/types'
import {
  HOTEL_ERROR_MESSAGES,
  isCoordinate,
  isDate,
  isEmptyString,
  isZeroNumber,
  SKIP_VALIDATION_ERROR,
} from '../common'
import type { OptionalGeocode } from './types'

type HotelCompareSegment = {
  geocode: OptionalGeocode
  checkInDate?: Date | null
  checkOutDate?: Date | null
}

type ValidationArgs = HotelCompareSegment & {
  hotels: CalculateHotelEmissionsBatchHotelInput[]
}

const ERROR_MESSAGE = 'CalculateHotelsEmissionsBatch Error'

export function hotelsEmissionsQueryExecuteValidation({
  hotels,
  checkInDate,
  checkOutDate,
  geocode,
}: ValidationArgs): Maybe<NestedError> {
  if (!hotels || hotels.length === 0) {
    return Just(NestedError.new(SKIP_VALIDATION_ERROR))
  }

  const maybeHotelsErrors = validateTcHotels(hotels)
  const maybeHotelCompareSegmentsErrors = validateHotelCompareSegments({
    checkInDate,
    checkOutDate,
    geocode,
  })

  if (maybeHotelsErrors.isValue() || maybeHotelCompareSegmentsErrors.isValue()) {
    return Just(
      NestedError.new(
        `${ERROR_MESSAGE}: ${!maybeHotelsErrors.isNothing() && maybeHotelsErrors.getValue()} ${
          !maybeHotelCompareSegmentsErrors.isNothing() && maybeHotelCompareSegmentsErrors.getValue()
        }`,
      ),
    )
  }

  return Nothing()
}

function validateHotelCompareSegments({ checkInDate, checkOutDate, geocode }: HotelCompareSegment) {
  const compareSegmentErrors: NestedError[] = []

  if (!isCoordinate(geocode.lat)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.GEOCODE_LATITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isCoordinate(geocode.long)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.GEOCODE_LONGITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isDate(checkInDate)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.CHECK_IN_DATE)
    compareSegmentErrors.push(error)
  }

  if (!isDate(checkOutDate)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.CHECK_OUT_DATE)
    compareSegmentErrors.push(error)
  }

  if (compareSegmentErrors.length > 0) {
    const validationError = NestedError.new(compareSegmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate hotel compare object;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}

export function validateTcHotels(hotels: CalculateHotelEmissionsBatchHotelInput[]) {
  const hotelErrors: NestedError[] = []

  hotels.forEach((hotel, idx) => {
    const maybeHotelErrors = validateTcHotel(hotel)

    if (maybeHotelErrors.isValue()) {
      hotelErrors.push(
        NestedError.new(
          `failed to validate hotel with index: ${idx};${maybeHotelErrors.getValue()}`,
        ),
      )
    }
  })

  if (hotelErrors.length > 0) {
    const validationError = NestedError.new(hotelErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}

function validateTcHotel(hotel: CalculateHotelEmissionsBatchHotelInput) {
  const segmentErrors: NestedError[] = []

  if (!hotel.nights || isZeroNumber(hotel.nights)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.NIGHTS)
    segmentErrors.push(error)
  }

  if (!isCoordinate(hotel.location.lat)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.LOCATION_LATITUDE)
    segmentErrors.push(error)
  }

  if (!isCoordinate(hotel.location.long)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.LOCATION_LONGITUDE)
    segmentErrors.push(error)
  }

  if (isEmptyString(hotel.checkIn)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.CHECK_IN)
    segmentErrors.push(error)
  }

  if (isEmptyString(hotel.hotelId)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.HOTEL_ID)
    segmentErrors.push(error)
  }

  if (isEmptyString(hotel.name)) {
    const error = NestedError.new(HOTEL_ERROR_MESSAGES.NAME)
    segmentErrors.push(error)
  }

  if (segmentErrors.length > 0) {
    const validationError = NestedError.new(segmentErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}
