import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { SpaceBetween } from '../../../rtp-price-summary-styled'
import { SectionDescription } from '../../payable-sections-styled'

type Props = {
  totalRailTripCost: string
  hasRailTripCost: boolean
}

export function RailSection({ totalRailTripCost, hasRailTripCost }: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'ReviewTrip.PriceSummary'

  if (!hasRailTripCost) {
    return null
  }

  return (
    <>
      <Block marginTop={8} />
      <SpaceBetween>
        <SectionDescription aria-label={t(i18nBasePath + '.Trains')}>
          {t(i18nBasePath + '.Trains')}
        </SectionDescription>
        <SectionDescription aria-label={totalRailTripCost}>{totalRailTripCost}</SectionDescription>
      </SpaceBetween>
    </>
  )
}
