import { Icon } from '@etta/ui/icon'
import { ExpiredIconContainer } from '../trip-cart-styled'
import { BagContainer, AmountContainer } from './bag-styled'

type Props = {
  itemsAmount: number
  isExpiredItinerary?: boolean
  isDisabled?: boolean
  onMouseEnter?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onReview: () => void
  forwardRef?: React.ForwardedRef<HTMLDivElement>
}

export function Bag({
  itemsAmount,
  isDisabled = false,
  forwardRef,
  onMouseEnter,
  onReview,
  onKeyDown,
  isExpiredItinerary = false,
}: Props) {
  const tabIndex = isDisabled ? -1 : 0
  return (
    <BagContainer
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onClick={onReview}
      tabIndex={tabIndex}
      ref={forwardRef}
      disabled={isDisabled}>
      <Icon name="tripPWA" color="mainText2" size="medium" />
      <AmountContainer color="mainText2" variant="headline">
        {itemsAmount}
      </AmountContainer>
      {isExpiredItinerary && (
        <ExpiredIconContainer>
          <Icon size="small" color="mainText2" name="warningFilledPWA" />
        </ExpiredIconContainer>
      )}
    </BagContainer>
  )
}
