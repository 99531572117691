import { useMedia } from 'react-use'
import { Block } from '@etta/ui/block'
import { screenSize } from '@fiji/style'
import type { LayoutProps } from '../../types'
import {
  Header,
  AirSearchDesktopWrapper,
  Form,
  Fields,
  Buttons,
} from './air-search-form-desktop-styled.new'

export function AirSearchFormDesktopNew({
  addNewFlightButtonSlot,
  tabsSlot,
  submitButtonSlot,
  preSearchCustomFieldsModalSlot,
  children,
  isOneRow,
}: LayoutProps) {
  const isMaxLaptop = useMedia(`only screen and (${screenSize.maxLaptop})`)

  return (
    <AirSearchDesktopWrapper isMaxLaptop={isMaxLaptop}>
      <Header>{tabsSlot}</Header>
      <Form isOneRow={isOneRow}>
        <Fields isOneRow={isOneRow}>{children}</Fields>

        <Buttons>
          <Block>{addNewFlightButtonSlot}</Block>
          {submitButtonSlot}
        </Buttons>
      </Form>

      {preSearchCustomFieldsModalSlot}
    </AirSearchDesktopWrapper>
  )
}
