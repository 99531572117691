import styled, { css } from 'styled-components'
import {
  captionMedium,
  footnoteMedium,
  footnoteStrong,
  getKeyboardNavigationStyle,
  subHeadMedium,
  subHeadStrong,
} from '@fiji/style'
import type { TextFieldHelperType, TextFieldSize, TextAlignTypes } from '../types'

import { getTextFieldSize } from './get-size'

export type SkinType = 'default' | 'search' | 'default-small'

export const FieldContainer = styled.div<{ isFocusedByTab: boolean }>`
  display: flex;

  ${({ isFocusedByTab }) =>
    isFocusedByTab &&
    css`
      outline: 2px solid ${({ theme }) => theme.colors.primary} !important;
      outline-offset: 1px;
      z-index: 10;
      border-radius: 10px;
    `}
`

export const StartSlot = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.mainText};
`

export const CenterSlot = styled.div<{
  isStartSlotExist: boolean
  isEndSlotExist: boolean
}>`
  flex: 1;
  overflow: hidden;
  padding-left: ${(props) => (props.isStartSlotExist ? 0 : '12px')};
  padding-right: ${(props) => (props.isEndSlotExist ? 0 : '12px')};
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const EndSlot = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.mainText};
`

export const Label = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
`

export const Input = styled.input<{
  isLabelExists: boolean
  isClickable: boolean
  textAlign?: TextAlignTypes
  isBlurred?: boolean
  fontSize?: TextFieldSize
}>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ${(p) => p.isClickable && `pointer-events: none;`}
  text-align: ${(props) => props.textAlign && props.textAlign};

  width: 100%;
  padding: 0;
  padding-top: ${(props) => (props.isLabelExists ? '4px' : 0)};
  outline: none;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.mainText};
  ${(p) => (p.fontSize === 'small-font' ? footnoteMedium : subHeadMedium)};
  ${(p) => p.isBlurred && 'filter: blur(4px); padding-left: 3px;'}
`

export const HelperContainer = styled.div<{
  helperType?: TextFieldHelperType
  isHelperTextSpaceReserved: boolean
}>`
  margin-top: ${({ isHelperTextSpaceReserved }) => (isHelperTextSpaceReserved ? '2px' : 0)};
  min-height: ${({ isHelperTextSpaceReserved }) => (isHelperTextSpaceReserved ? '20px' : 0)};
  color: ${(props) => {
    switch (props.helperType) {
      case 'error':
        return props.theme.colors.error
      case 'success':
        return props.theme.colors.success
      case 'warning':
        return props.theme.colors.warning
      case 'description':
      default:
        return props.theme.colors.bodyText
    }
  }};
  display: flex;
  align-items: center;
  ${captionMedium};
`

const fieldWithValueStyle = css`
  border-color: ${(p) => p.theme.colors.borderDark};
  background: ${(p) => p.theme.colors.background};
  input {
    color: ${(p) => p.theme.colors.mainText};
    ${subHeadStrong};
  }
`

const loadingStyle = css`
  input {
    color: ${(p) => p.theme.colors.bodyText1};
    ${subHeadMedium};
  }
`

export const Container = styled.div<{
  hasValue: boolean
  size?: TextFieldSize
  isError: boolean
  hasNoBorder: boolean
  skin: SkinType
  isDisabled?: boolean
  isFieldWithValue?: boolean
  isLoading?: boolean
}>`
  display: flex;
  line-height: 1;
  height: ${(props) => getTextFieldSize(props.size)}px;
  border: ${(props) => {
    if (props.hasNoBorder) {
      return 'none;'
    }
    if (props.isError) {
      return `1px solid ${props.theme.colors.error};`
    }
    if (props.isDisabled) {
      return `1px solid transparent;`
    }
    return `1px solid ${props.theme.colors.borderLight};`
  }}
  ${(p) => p.isDisabled && `pointer-events: none;`}
  border-radius: 8px;
  background-color: ${(p) => {
    if (p.skin === 'search') {
      return p.theme.colors.secondary
    }

    return p.theme.colors.white
  }};

  &:focus-within {
    ${(props) => {
      if (props.hasValue) {
        return
      }
      return css`
        ${Label} {
          transform: translateY(0px);
          ${footnoteMedium};
        }
      `
    }};
  }

  ${(props) => {
    if (props.hasValue) {
      return
    }
    return css`
      ${Label} {
        transform: translateY(1.4rem);
        ${subHeadMedium};
      }
    `
  }};

  ${(p) => {
    if (p.size !== 'small' && p.size !== 'small-font') {
      return
    }
    return css`
      ${Label} {
        ${footnoteMedium};
      }
    `
  }}

  ${(p) =>
    p.skin === 'default-small' &&
    css`
      input {
        ${footnoteStrong};
      }
    `}

  ${(p) => {
    if (p.isFieldWithValue) {
      return fieldWithValueStyle
    }
  }}

  ${(p) => {
    if (p.isLoading) {
      return loadingStyle
    }
  }}

`

export const LabelContainer = styled.label`
  width: 100%;
  ${getKeyboardNavigationStyle({ borderRadius: 8, offset: 2 })}
`
