import { hookContextFactory } from '@etta/interface/services/hook-context-factory'

import { SeatMapStore } from './store/seat-map.store'
import { SeatMapPaginationStore } from './store/seat-map-pagination.store'

import { SeatMapActions } from './actions/seat-map.actions'
import { SeatMapPaginationActions } from './actions/seat-map-pagination.actions'

export const { useModuleContext: useSeatMapContext } = hookContextFactory({
  seatMapStore: SeatMapStore,
  seatMapPaginationStore: SeatMapPaginationStore,
  seatMapActions: SeatMapActions,
  seatMapPaginationActions: SeatMapPaginationActions,
})
