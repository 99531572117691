import type { SearchParams } from '@fiji/hooks/use-search-params/types'
import { SeatmapCabinClass, SystemBehaviorType } from '@fiji/graphql/types'
import type { CabinClasses } from '@fiji/types'
import { fromLocationQuery, toLocationQuery } from '@fiji/utils/search-param-conversions'

export const DEFAULT_LOCALE = 'en-US'
export const GAZOO_ENV = 'gazooEnv'
export const APPLE_MAP_ZOOM_COEFFICIENT = 0.045
export const MAP_DISTANCE_CHANGE_COEFFICIENT = 0.0001
export const TABLET_SCREEN_SIZE = 1024
export const FILTERS_ANIMATION_TIME = 300
export const MAXIMUM_AIR_SEARCH_SEGMENT_AIRLINES = 2
export const MAXIMUM_AIR_SEARCH_SEGMENTS_PER_AIRLINE = 3
export const MAXIMUM_RAIL_SEARCH_SEGMENT_CARRIERS = 2
export const OUT_OF_POLICY_REASON_DEFAULT_VALUE = -99
export const KG_IN_TONNE = 1000
export const MAXIMUM_SEGMENTS_COUNT = 8
export const RAIL_CHECK_STATIONS_LINK =
  'https://support.thetrainline.com/en/support/solutions/articles/78000000556'

export const RAIL_SEE_VIDEO_LINK =
  'https://res.cloudinary.com/dm0zqj0n2/video/upload/v1695733459/demo/TicketCollectionGuide.mp4'

export const initialSearchParams: SearchParams = fromLocationQuery(
  toLocationQuery({
    itineraryId: '728061589442859717',
    checkIn: '2033-01-15',
    checkOut: '2033-01-25',
    latitude: 37.7749295,
    longitude: -122.4194155,
    guests: 1,
    query: 'San Francisco',
    distance: '5',
    distanceUnit: 'km',
    nextPageLink: '',
    referencePointId: '',
    isSkipLinkVisible: true,
    syncUrl: '',
    hotelRemoveKey: '',
  }),
)

export const initialTripReviewParams = {
  checkInDate: '2020-11-15',
  checkOutDate: '2020-11-25',
  hotelKey: '',
  roomId: '',
}

export const initialSearchId = 'b92e1f6d-a233-4033-a840-63ab60f0ea22'

const initialQueryString = toLocationQuery(initialSearchParams)
export const initialHistoryEntry = `/trip-planner/hotel-search?${initialQueryString}`

export const starRatingOptions = [
  { value: 5, name: '5 Stars', count: 5 },
  { value: 4, name: '4 Stars', count: 4 },
  { value: 3, name: '3 Stars', count: 3 },
  { value: 2, name: '2 Stars', count: 2 },
  { value: 1, name: '1 Star', count: 1 },
]

export const distanceRecalculationRatio = 1.609344

export const PolicyExplanationNegativeValues = [
  SystemBehaviorType.CollectCompInfo,
  SystemBehaviorType.ReselectCompTrip,
]

export const APP_BASE_PATH = '/app'

export const cabinClasses: CabinClasses = {
  [SeatmapCabinClass.Coach]: {
    id: SeatmapCabinClass.Coach,
  },
  [SeatmapCabinClass.PremiumCoach]: {
    id: SeatmapCabinClass.PremiumCoach,
  },
  [SeatmapCabinClass.Business]: {
    id: SeatmapCabinClass.Business,
  },
  [SeatmapCabinClass.First]: {
    id: SeatmapCabinClass.First,
  },
}

export const ROOM_PROVIDERS = {
  TRIPSOURCE: 'Tripsource',
  BOOKINGCOM: 'booking.com',
  HOTELHUB: 'ean',
}

export const AIR_PROVIDERS = {
  SOUTHWEST: 'WN',
} as const

export const RENDERING_START_PERFORMANCE_MARK = 'RENDERING_START'

export const PRIVACY_POLICY_DOCUMENT_URL = 'https://www.deem.com/privacy'

export const NATIONAL_RAIL_CONDITIONS_URL =
  'https://static.trainlinecontent.com/content/WEB/documents/NationalRail_ConditionsOfTravel.pdf'

export const SINGLE_USE_CREDIT_CARD_ID = 'single-use-card'

export const ABOUT_GEO_SAFE_SCORES_URL = 'https://deem.app.geosureglobal.com/about'

export const VIRTUAL_PAY = 'virtual-pay'

export const LONDON_UNDERGROUND_OPERATOR = 'Unknown Operator'
export const LONDON_UNDERGROUND_TUBE = 'The Tube'
export const LONDON_UNDERGROUND_TUBE_URL =
  'https://res.cloudinary.com/dm0zqj0n2/image/upload/v1649763553/Logos/Rail-Providers/Square/London-Overground.svg'

export const RAIL_LOGO_GENERIC =
  'https://res.cloudinary.com/dm0zqj0n2/image/upload/v1/Logos/Rail-Providers/Square/Generic.svg'

export const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/?api=1&query='

export const HOUR_IN_MINUTES = 60
export const DAY_IN_MINUTES = 24 * 60
export const QUICK_CHANGE_IN_MINUTES = 15
