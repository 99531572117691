import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { ErrorStateMobile } from './mobile'
import { ErrorStateDesktop } from './desktop'

export function ErrorStateLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ErrorStateMobile {...props} />}
      desktopSlot={<ErrorStateDesktop {...props} />}
    />
  )
}
