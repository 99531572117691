import type { ReactNode } from 'react'
import { useCallback, useMemo } from 'react'
import type { Place } from '@fiji/types'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import { Modal } from '@etta/ui/modal'
import { Swap } from '@etta/ui/swap'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import type { RecentSearchesType } from '@etta/modules/recent-searches/core/value-objects/recent-searches-type.value-object'
import { SearchMechanism } from './search-mechanism'
import { LocationPickerLayout } from './layout'
import { Input } from './input'
import { InputSlotWrapper } from './location-picker-styled'

type Props = {
  'aria-label'?: string
  'data-tracking-id'?: string
  disabled?: boolean
  hasCurrentLocationSearch?: boolean
  inputAriaLabel?: string
  searchType?: SearchType
  isOutboundTrip?: boolean
  label?: string
  latestPlaces?: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  onChange: (value: Place, address?: AddressInput) => void
  value?: Place | null
  inputSlot?: ReactNode
  isForSearchHeader?: boolean
  isModalAnimated?: boolean
  isModalVisible?: boolean
  recentSearchesType?: RecentSearchesType
  customModalDescription?: string
  className?: string
  placeholder?: string
}

export function LocationPicker({
  'aria-label': ariaLabel,
  'data-tracking-id': dataTrackingId,
  disabled,
  hasCurrentLocationSearch,
  inputAriaLabel,
  searchType,
  isOutboundTrip,
  label,
  latestPlaces,
  locationsSortOrder,
  onChange,
  value,
  inputSlot,
  isForSearchHeader = false,
  isModalAnimated = true,
  isModalVisible = false,
  customModalDescription,
  placeholder: inputPlaceholder,
  recentSearchesType,
}: Props) {
  const modalToggle = useTogglePopup(isModalVisible)
  const inputProps = useMemo(
    () => ({
      'aria-label': inputAriaLabel,
      placeholder: inputPlaceholder,
    }),
    [inputAriaLabel, inputPlaceholder],
  )
  const displayedText = value?.airportCode || value?.name
  const { autocompleteStore } = useAutocompleteContext()
  const onChangeModalOpen = useCallback(() => {
    if (disabled) {
      return
    }

    modalToggle.handleOpen()
  }, [disabled, modalToggle])

  const modalClose = () => {
    autocompleteStore.clearQuery()
    modalToggle.handleClose()
  }

  return (
    <LocationPickerLayout
      isSearchWindowOpen={modalToggle.isOpen}
      onModalClose={modalClose}
      isForSearchHeader={isForSearchHeader}
      inputSlot={
        <Swap
          isSlot={<InputSlotWrapper onClick={onChangeModalOpen}>{inputSlot}</InputSlotWrapper>}
          is={!!inputSlot}>
          <Input
            onClick={modalToggle.handleOpen}
            displayedText={displayedText}
            label={label}
            ariaLabel={ariaLabel}
            data-tracking-id={dataTrackingId}
            isDisabled={disabled}
          />
        </Swap>
      }
      modalSlot={
        <Modal
          isAnimated={isModalAnimated}
          isVisible={modalToggle.isOpen}
          handleModalVisibility={modalToggle.handleClose}
        />
      }
      listSlot={
        <SearchMechanism
          recentSearchesType={recentSearchesType}
          searchType={searchType}
          disabled={disabled}
          onChange={onChange}
          isModalOpen={modalToggle.isOpen}
          onModalClose={modalToggle.handleClose}
          inputProps={inputProps}
          locationsSortOrder={locationsSortOrder}
          isOutboundTrip={isOutboundTrip}
          latestPlaces={latestPlaces}
          hasCurrentLocationSearch={hasCurrentLocationSearch}
          customSearchDescription={customModalDescription}
        />
      }
    />
  )
}
