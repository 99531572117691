import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useNoResultReason } from '../use-hotel-list/use-no-results-reason'
import { Header } from './header'
import { HotelListNoResults } from './hotel-list-no-results'
import { useHotelListResults } from './use-hotel-list-results'
import { HotelListResultsLayout } from './layout'

type Props = {
  selectedHotelId?: string
  handleSelected: (selectedId: string | null) => void
}

export const HotelListResults = observer(function HotelListResults({ selectedHotelId }: Props) {
  const { hotelSearchResultsStore } = useHotelSearchResultsContext()
  const { hotels, isLoading } = hotelSearchResultsStore
  const isDesktop = screenMatcher.getScreenType() === ScreenType.Desktop

  const {
    onButtonClickIsNoResult,
    isFiltersApplied,
    handleOpen,
    actualHotelEmission,
    existingHotelEmissions,
    selectedDetailsHotelId,
    hotelSearchDSMLightboxToggler,
  } = useHotelListResults()

  const onHotelClick = useCallback(
    (hotelId: string) => {
      handleOpen(hotelId)
    },
    [handleOpen],
  )

  const { noResultsReason, handleHotelSearchExtraAction } = useNoResultReason({
    isFiltersApplied,
    isDesktop,
    onDefaultAction: onButtonClickIsNoResult,
    isRestrictedCountryError: false,
  })

  if (!isLoading && !hotels.length) {
    return (
      <HotelListNoResults
        isFiltersApplied={isFiltersApplied}
        onButtonClick={handleHotelSearchExtraAction}
        noResultsReason={noResultsReason}
      />
    )
  }

  const hotelEmissions = {
    existingHotelEmissions,
    averageSustainabilityScore: actualHotelEmission.averageLocationSustainabilityScore,
    loading: actualHotelEmission.loading,
  }

  return (
    <>
      <HotelListResultsLayout
        headerSlot={<Header />}
        handleOpen={onHotelClick}
        hotelsEmissions={hotelEmissions}
        selectedHotelId={selectedDetailsHotelId || selectedHotelId}
      />
      <DynamicSiteMessagesLightboxModal
        messages={hotelSearchResultsStore.hotelResults?.messages}
        isVisible={hotelSearchDSMLightboxToggler.isOpen}
        onClose={hotelSearchDSMLightboxToggler.handleClose}
      />
    </>
  )
})
