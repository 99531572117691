import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import { TextAreaDescription } from '@etta/ui/textarea-description'
import type { LayoutProps } from '../types'
import { Title, Border, Footer, Container, Description } from './mobile-components'

const i18nBase = 'Mobility.AddExpenseCodeMemoPage.'

export const AddExpenseCodeMemoMobile = ({
  onBack,
  onContinue,
  onMemoValueChanged,
  isMemoRequired,
  maxMemoLength,
  memoValue,
  isContinueButtonDisabled,
}: LayoutProps) => {
  const { t } = useTranslation()

  return (
    <Screen>
      <Screen.Header>
        <Header
          layout="sub-title"
          backgroundColor="white"
          leftSlot={<Header.BackButton color="mainText" onClick={onBack} />}>
          <Title>{t(i18nBase + 'Title')}</Title>
        </Header>
        <Border />
      </Screen.Header>
      <Screen.Container>
        <Container>
          <Description>
            {isMemoRequired
              ? t(i18nBase + 'DescriptionRequired')
              : t(i18nBase + 'DescriptionOptional')}
          </Description>
          <TextAreaDescription
            value={memoValue}
            onChange={(value) => {
              onMemoValueChanged(value)
            }}
            maxChar={maxMemoLength}
            withCharRemain={true}
          />
        </Container>
      </Screen.Container>
      <Screen.Footer withBorder backgroundColor="white">
        <Footer>
          <Button
            fullWidth
            minWidth={14}
            onClick={onContinue}
            disabled={isMemoRequired ? isContinueButtonDisabled : false}>
            {t(i18nBase + 'ContinueButton')}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
