import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { SegmentType } from '@etta/core/enums'
import { TripStatus } from '@fiji/graphql/types'
import type { CancelTripDataValueObject } from '@etta/modules/post-booking/core/value-objects'
import {
  ExpansiveBody,
  HeaderContainer,
  RightIndentContent,
  SegmentContainer,
} from '../cancel-trip-modal-styled'
import { TripSegment } from '../trip-segment'
import { SidebarDescriptiveActions as DescriptiveActions } from '../descriptive-actions'
import { SidebarHeader as Header } from '../header'
import { SidebarFooter as Footer } from '../footer'
import { TripTotalCost } from '../trip-total-cost'
import { SidebarRideHailCancelSection as RideHailCancelSection } from '../ride-hail-cancel-section/sidebar'
import { AdditionalInformation } from '../additional-information/additional-information'
import type { VariantProps } from './types'

export const CancelTripModalSidebar = observer(function CancelTripModalSidebar({
  isChecked,
  titleDate,
  tripTitle,
  statement,
  onChange,
  onClose,
}: VariantProps) {
  const { postBookingCancellationStore, postBookingTripStore } = usePostBookingContext()
  const { trip } = postBookingTripStore
  const { cancellationSegment, cancellationSegments } = postBookingCancellationStore

  const tripSegments = cancellationSegments.filter((segment) => {
    if (cancellationSegment?.type === SegmentType.RideHail) {
      return true
    }
    return segment.type !== SegmentType.RideHail
  })

  const isCancelRideHailSegment = cancellationSegment?.type === SegmentType.RideHail

  const rideHailSegments = cancellationSegments.filter(
    (segment) => segment.type === SegmentType.RideHail && segment.status === TripStatus.Confirmed,
  )

  const { filteredSegments } = useMemo(
    () =>
      tripSegments.reduce(
        (acc, currSegment) => {
          if (currSegment.isRoundTripLeg && currSegment.isRoundTripCost && !Object.keys(acc.roundSegment).length) {
            acc.roundSegment = { ...currSegment }

            return acc
          }

          if (currSegment.isRoundTripLeg && currSegment.isRoundTripCost && Object.keys(acc.roundSegment).length) {
            acc.filteredSegments.push(acc.roundSegment)
            return acc
          }

          acc.filteredSegments.push(currSegment)
          return acc
        },
        {
          roundSegment: {} as CancelTripDataValueObject,
          filteredSegments: [] as CancelTripDataValueObject[],
        },
      ),
    [tripSegments],
  )

  return (
    <>
      <Header statement={statement} onClose={onClose} />
      <Modal.Body>
        <ExpansiveBody>
          <RightIndentContent>
            <HeaderContainer>
              {tripTitle && <Text variant="headline">{tripTitle}</Text>}
              <Text variant="captionStrongCaps" data-tracking-id="travel-dates-text">
                {titleDate}
              </Text>
            </HeaderContainer>
            <Block paddingVertical={10}>
              <SegmentContainer>
                {filteredSegments.map((item) => {
                  return <TripSegment key={item.id} {...item} />
                })}
              </SegmentContainer>
            </Block>
            <DescriptiveActions
              rideHailSegment={
                !isCancelRideHailSegment &&
                rideHailSegments.length !== 0 && (
                  <Block marginTop={34}>
                    <RideHailCancelSection rideHailSegmentData={rideHailSegments} />
                  </Block>
                )
              }
            />
            <TripTotalCost
              costSummary={trip.costSummary}
              segmentType={postBookingCancellationStore.cancellationSegment?.type}
            />
            <AdditionalInformation />
          </RightIndentContent>
        </ExpansiveBody>
        <Footer statement={statement} isChecked={isChecked} onChange={onChange} />
      </Modal.Body>
    </>
  )
})
