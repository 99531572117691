import * as R from 'ramda'
import type { SearchFlightLegSubSegment } from '@fiji/graphql/types'

export function getAirlinesLogos(segments: SearchFlightLegSubSegment[]): string[] {
  return R.pipe(
    R.map(R.path(['carrier', 'logoImageUrl'])),
    R.filter(Boolean),
    R.uniq,
  )(segments) as string[]
}
