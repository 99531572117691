import type { IconNames } from '@etta/ui/icon'
import type { SafetyCheckFlight } from '@fiji/types'

export function getFlightSafetyCheckIconNames(name: string): IconNames {
  switch (name) {
    case 'fas fa-pump-soap':
      return 'sanitizerPWA'
    case 'fas fa-head-side-mask':
      return 'maskPWA'
    case 'fas fa-clipboard':
      return 'clipboardPWA'
    case 'fas fa-virus-slash':
      return 'negativeReportPWA'
    default:
      return 'sanitizerPWA'
  }
}

/*
  getUniqFlightSafetyCheckIconNames - filter icons from all legs of one segment id, to get unique icons and show them in collapse view
*/
export function getUniqFlightSafetyCheckIconNames(
  flightSafetyChecks: SafetyCheckFlight[],
): IconNames[] {
  const iconNames: IconNames[] = []

  flightSafetyChecks.forEach((item) =>
    item.headLines.forEach((headLine) => {
      if (!iconNames.includes(headLine.iconName)) {
        iconNames.push(headLine.iconName)
      }
    }),
  )

  return iconNames
}
