import type { Place } from '@fiji/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type SubSegments = FlightPostBookingValueObject['segments']

export function resolvePlaces(segments: SubSegments) {
  if (!segments || segments.length === 0) {
    return
  }
  return {
    originPlace: {
      placeId: '1',
      name: segments[0].departure?.airportName,
      airportCode: segments[0].departure?.airportCode,
    } as Place,
    destinationPlace: {
      placeId: '2',
      name: segments[segments.length - 1].arrival?.airportName,
      airportCode: segments[segments.length - 1].arrival?.airportCode,
    } as Place,
  }
}
