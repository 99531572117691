import { ScreenType, useScreenType } from '@fiji/modes'
import type { TripUnconfirmedProps } from './types'
import { Header, FailedSegment } from './components'
import { Content, ContentContainer, PageContainer } from './trip-unconfirmed.styled'

export function TripUnconfirmed({ tripState, failedBooks, viewStateSlot }: TripUnconfirmedProps) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  return (
    <PageContainer isMobile={isMobile}>
      <ContentContainer isMobile={isMobile}>
        <Header tripState={tripState} />
        <Content isMobile={isMobile}>
          {failedBooks?.map((failedBook, idx) => (
            <FailedSegment variant="unconfirmed" key={idx} failedBooks={failedBook} />
          ))}
        </Content>
        {viewStateSlot}
      </ContentContainer>
    </PageContainer>
  )
}
