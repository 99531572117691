import { Component } from 'react'
import type { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router'
import { AppErrorBoundaryFallback } from './app-error-boundary-fallback'

export class ErrorBoundary extends Component<RouteComponentProps> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    })
  }

  onErrorClear = () => {
    this.props.history.goBack()
    this.setState({ hasError: false })
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return <AppErrorBoundaryFallback onErrorClear={this.onErrorClear} />
    }

    return this.props.children
  }
}

export const AppErrorBoundary = withRouter(ErrorBoundary)
