import { useCallback } from 'react'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { GOOGLE_MAPS_URL } from '@fiji/constants'

type Props = {
  segment: TripDetailsFlightLegSegment
}

export function useAirports({ segment }: Props) {
  const originCity = segment.departure?.airportCity
  const destinationCity = segment.arrival?.airportCity
  const originAirportCode = segment.departure?.airportCode || '-'
  const destinationAirportCode = segment.arrival?.airportCode || '-'

  const hoursDuration = segment.flightTime?.hours || 0
  const minutesDuration = segment.flightTime?.minutes || 0
  const flightDuration = getFormattedTime(
    { hours: hoursDuration, minutes: minutesDuration },
    "H'h' mm'm'",
  )
  const originTerminal = segment.originTerminal
  const destinationTerminal = segment.destinationTerminal

  const originGate = segment.departureGate
  const destinationGate = segment.arrivalGate

  const departureDate = segment.departureDate
    ? new Date(deleteTimezone(segment.departureDate))
    : null
  const arrivalDate = segment.arrivalDate ? new Date(deleteTimezone(segment.arrivalDate)) : null

  const originTime = departureDate ? dateFormat(departureDate, 'h:mm a') : ''
  const originDate = departureDate ? dateFormat(departureDate, 'EEE, MMM dd') : ''
  const destinationTime = arrivalDate ? dateFormat(arrivalDate, 'h:mm a') : ''
  const destinationDate = arrivalDate ? dateFormat(arrivalDate, 'EEE, MMM dd') : ''
  const originAirportName = segment.departure?.airportName || ''
  const destinationAirportName = segment.arrival?.airportName || ''

  const originGeocode = segment.departure?.address?.geocode
  const destinationGeocode = segment.arrival?.address?.geocode

  const handleOriginLocationClick = useCallback(() => {
    if (!originGeocode) {
      return
    }

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${originGeocode.lat},${originGeocode.long}`)
  }, [originGeocode])

  const handleDestinationLocationClick = useCallback(() => {
    if (!destinationGeocode) {
      return
    }

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${destinationGeocode.lat},${destinationGeocode.long}`)
  }, [destinationGeocode])

  return {
    destinationAirportCode,
    destinationAirportName,
    destinationCity,
    destinationDate,
    destinationTime,
    destinationGate,
    destinationTerminal,
    flightDuration,
    originAirportCode,
    originAirportName,
    originCity,
    originDate,
    originTime,
    originGate,
    originTerminal,
    handleOriginLocationClick,
    handleDestinationLocationClick,
  }
}
