import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate } from '@fiji/graphql/types'

type Args = {
  originAirport: string
  destinationAirport: string
  rate?: Rate | null
  taxes?: Rate | null
  total?: Rate | null
  transactionFees?: Rate | null
  unusedTicketRate?: Rate | null
  totalWithUnusedTicket?: Rate | null
}

const i18nBase = 'PostBooking.FlightDetails'

export function usePrice({
  originAirport,
  destinationAirport,
  rate,
  taxes,
  total,
  transactionFees,
  unusedTicketRate,
  totalWithUnusedTicket,
}: Args) {
  const { t } = useTranslation()

  const direction = [originAirport, destinationAirport].join(' - ')

  const { mainCost: formattedWithUnusedTotal } = formatRate(totalWithUnusedTicket, {
    morpheme: 'none',
  })
  const { mainCost: formattedTotal } = formatRate(total, { morpheme: 'none' })

  const priceDetailsList = useMemo(() => {
    const priceDetails = [
      {
        title: direction,
        value: formatRate(rate, { morpheme: 'none' }).mainCost,
        titleTestId: 'city-pair-text',
        valueTestId: 'cost-text',
      },
      {
        title: t(`${i18nBase}.Taxes`),
        value: formatRate(taxes, { morpheme: 'none' }).mainCost,
        titleTestId: 'taxes-text',
        valueTestId: 'tax-amount-text',
      },
      {
        title: t(`${i18nBase}.TransactionFee`),
        value: formatRate(transactionFees, { morpheme: 'none' }).mainCost,
        titleTestId: 'transaction-fee-text',
        valueTestId: 'transaction-fee-amount-text',
      },
    ]

    if (unusedTicketRate) {
      priceDetails.push({
        title: t(`${i18nBase}.UnusedTicketPrice`),
        value: `- ${formatRate(unusedTicketRate, { morpheme: 'none' }).mainCost}`,
        titleTestId: 'unused-ticket-price',
        valueTestId: 'unused-ticket-amount-price',
      })
    }

    return priceDetails
  }, [direction, rate, t, taxes, transactionFees, unusedTicketRate])

  return {
    priceDetailsList,
    formattedTotal: totalWithUnusedTicket ? formattedWithUnusedTotal : formattedTotal,
  }
}
