import styled from 'styled-components'
import { UnstyledButton, footnoteStrong } from '@fiji/style'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  padding: 0 20px;
`

export const ShowDetails = styled(UnstyledButton)`
  margin-top: 13px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary1};

  ${footnoteStrong};
`

export const StyledBlock = styled(Block)`
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.white};
`
