import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Modal } from '@etta/ui/modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { UberSupportInformation } from '@etta/components/uber-support-information/uber-support-information'
import {
  Container,
  DescriptionLink,
  ExtraInfo,
  ExtraInfoDescription,
  HorizontalDelimiter,
} from './uber-ride-support-styled'

export function UberRideSupport() {
  const uberRideSupportModal = useTogglePopup()
  const { t } = useTranslation()
  const basePath = 'Mobility.ConfirmationPage.'

  return (
    <>
      <Container>
        <ExtraInfo>
          <Icon name="faceMaskPWA" size={24} />
          <ExtraInfoDescription>
            <HorizontalDelimiter />
            {t(basePath + 'RememberToWearMask')} <br />
            {t(basePath + 'See')}{' '}
            <DescriptionLink
              href="https://uber.com/safety"
              target="_blank"
              aria-label={'Link to Uber safety'}>
              Uber.com/safety
            </DescriptionLink>
            .
          </ExtraInfoDescription>
        </ExtraInfo>
        <ExtraInfo>
          <Icon name="questionPWA" size={24} />
          <ExtraInfoDescription>
            <HorizontalDelimiter />
            {t(basePath + 'UberSupport')}
            <br />
            <Text
              variant="footnoteMedium"
              color="primary"
              cursor="pointer"
              onClick={uberRideSupportModal.handleOpen}>
              {t(basePath + 'WeCanHelp')}
            </Text>
            {'.'}
          </ExtraInfoDescription>
        </ExtraInfo>
      </Container>
      <Modal
        isVisible={uberRideSupportModal.isOpen}
        handleModalVisibility={uberRideSupportModal.handleClose}
        data-tracking-id={'uber-ride-support'}>
        <UberSupportInformation onClose={uberRideSupportModal.handleClose} />
      </Modal>
    </>
  )
}
