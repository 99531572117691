export default function SeatSelectionIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1652 6C14.2165 6 14.3244 8.06359 14.3301 8.28083L14.3304 13.7458L14.3372 13.7635L16.176 19.1273H26.8488C28.1961 19.1273 28.2859 20.4627 28.2919 20.6407L28.2923 20.6602C28.2923 22.1072 27.0349 22.2037 26.8672 22.2101L17.2328 22.2106L18.3188 25.3789L24.5217 24.8029C24.6025 24.7951 24.6778 24.7944 24.7509 24.7971C24.8433 24.7818 24.914 24.7772 24.9436 24.7759L24.9587 24.7753H32.3813C32.4621 24.7753 32.5221 24.7961 32.5942 24.805C32.7866 24.8142 32.9184 24.8513 32.9184 24.8513C34.2404 25.2272 33.995 26.5517 33.9591 26.7158L33.9553 26.7325L33.0053 30.4956C32.7669 31.4411 32.1126 31.6203 31.6705 31.6266L31.5557 31.6244L25.8695 31.6255V35.5646H31.7976C32.7968 35.5646 32.8737 36.5441 32.8796 36.6948L32.8801 36.7142V36.8507C32.8801 37.9116 31.9578 37.9932 31.8159 37.9995L17.0829 38C16.0835 38 16.0066 37.0207 16.0007 36.8701L16.0002 36.7142C16.0002 35.653 16.9228 35.5714 17.0647 35.5651L23.0111 35.5646V31.6255H17.4556C16.5386 31.6255 16.0128 31.2125 15.7079 30.7373C15.4624 30.4166 15.3341 30.1049 15.3024 30.0224L15.2946 30.0013L10.2677 15.337C10.2123 15.1756 10.1767 15.0244 10.1504 14.8781C10.0237 14.4885 10.0037 14.1495 10.0006 14.0606L10 8.29926C10 6 12.1652 6 12.1652 6Z"
        fill="currentColor"
      />
    </svg>
  )
}
