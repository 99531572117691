import { useTranslation } from 'react-i18next'
import type { TripDetailsCarServiceCar } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Container, Info } from './car-info-styled'

type Props = {
  car?: TripDetailsCarServiceCar | null
}

const i18Base = 'CarServiceDetails.CarInfo.'

export function CarInfo({ car }: Props) {
  const { t } = useTranslation()
  return (
    <Block marginVertical={10}>
      <Container>
        <Info>
          <Icon name="peoplePWA" size="small" />
          <Text variant="footnoteMedium" color="mainText2">
            {t(`${i18Base}People`, { people: car?.capacity })}
          </Text>
        </Info>
        <Block marginLeft={15}>
          <Info>
            <Icon name="carRentalPWA" size="small" />
            <Text variant="footnoteMedium" color="mainText2">
              {car?.type}
            </Text>
          </Info>
        </Block>
      </Container>
    </Block>
  )
}
