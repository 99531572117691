import { useTranslation } from 'react-i18next'

import type { IconSizes } from '@etta/ui/icon'
import { Badge } from '@etta/ui/badge'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Tooltip } from '@etta/ui/tooltip'
import type { Maybe, PrivateRate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'

type Props = {
  privateRate?: Maybe<PrivateRate>
  badgeSize: IconSizes
}

export function PrivateRateBadge({ privateRate, badgeSize }: Props) {
  const { t } = useTranslation()

  if (!privateRate) {
    return null
  }

  const { averageRate, corporate: isCorporate } = privateRate

  if (!averageRate?.primary?.amount) {
    return <Badge variant="privateRate" color="bodyText" iconSize={badgeSize} />
  }

  return (
    <Tooltip
      slot={
        <Block isFlexBlock>
          {!!isCorporate && (
            <Block marginRight={5} marginTop={-1}>
              <Text variant="footnoteMedium" noWrap>
                {t('SearchResults.Hotel.CorporateRate')}
              </Text>
            </Block>
          )}
          <Text variant="footnoteMedium" noWrap>
            {formatRate(averageRate, { morpheme: 'none' }).mainCost}
          </Text>
        </Block>
      }
      place="bottom">
      <Badge variant="privateRate" color="bodyText" iconSize={badgeSize} />
    </Tooltip>
  )
}
