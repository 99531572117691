import { useTripExpiredTimer } from './hooks'

type Props = {
  onExpire?: () => void
}

export const HiddenTimer = ({ onExpire }: Props) => {
  useTripExpiredTimer({ onExpire: onExpire })

  return <></>
}
