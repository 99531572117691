export default function NonSmokingIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 6C31.3736 6 39 13.6264 39 23C39 32.3736 31.3736 40 22 40C12.6264 40 5 32.3736 5 23C5 13.6264 12.6264 6 22 6ZM11.0374 14.0406C9.03697 16.4838 7.83336 19.6033 7.83336 23C7.83336 30.8117 14.1883 37.1666 22 37.1666C25.3967 37.1666 28.5162 35.963 30.9593 33.9627L25.6634 28.6667H12.7916C12.4001 28.6667 12.0833 28.3499 12.0833 27.9584V25.125C12.0833 24.7335 12.4001 24.4166 12.7916 24.4166H21.4134L11.0374 14.0406ZM22 8.83336C18.6033 8.83336 15.4838 10.037 13.0406 12.0373L25.4199 24.4166H31.2084C31.5999 24.4166 31.9167 24.7335 31.9167 25.125V27.9583C31.9167 28.3498 31.5999 28.6666 31.2084 28.6666H29.6699L32.9627 31.9594C34.963 29.5162 36.1666 26.3967 36.1666 23C36.1666 15.1883 29.8117 8.83336 22 8.83336ZM30.5 25.8334H29.0834V27.25H30.5V25.8334ZM24.8334 13.0834C26.396 13.0834 27.6667 14.3541 27.6667 15.9167C27.6667 16.6976 28.3024 17.3334 29.0834 17.3334C30.646 17.3334 31.9167 18.604 31.9167 20.1666V22.2916C31.9167 22.6832 31.5999 23 31.2084 23C30.8168 23 30.5 22.6832 30.5 22.2916V20.1666C30.5 19.3857 29.8643 18.75 29.0834 18.75C27.5207 18.75 26.25 17.4793 26.25 15.9167C26.25 15.1358 25.6143 14.5001 24.8334 14.5001H22.7084C22.3168 14.5001 22 14.1832 22 13.7917C22 13.4002 22.3168 13.0834 22.7084 13.0834H24.8334ZM22.7083 15.9166C23.0998 15.9166 23.4166 16.2335 23.4166 16.625V18.0416C23.4166 18.4325 23.7342 18.75 24.125 18.75C25.2968 18.75 26.25 19.7032 26.25 20.875C26.25 21.2659 26.5676 21.5834 26.9584 21.5834H28.375C28.7665 21.5834 29.0834 21.9002 29.0834 22.2916C29.0834 22.6832 28.7665 23 28.375 23H26.9583C25.7865 23 24.8333 22.0468 24.8333 20.875C24.8333 20.4841 24.5157 20.1666 24.1249 20.1666C22.9531 20.1666 21.9999 19.2134 21.9999 18.0416V16.625C21.9999 16.2335 22.3168 15.9166 22.7083 15.9166Z"
        fill="currentColor"
      />
    </svg>
  )
}
