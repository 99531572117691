import styled from 'styled-components'
import { HORIZONTAL_PADDING } from '../../../constants'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background4};
  padding: ${HORIZONTAL_PADDING}px;
  border-radius: 8px;
`

export const SkeletonContainer = styled.div`
  padding: ${HORIZONTAL_PADDING}px;
`
