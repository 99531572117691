import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Icon } from '@etta/ui/icon'
import { ScoreList } from '../../score-list'
import type { LayoutProps } from '../types'
import { ConcernScoreListSkeleton } from './concern-score-list-mobile-skeleton'
import { Container, Title } from './concern-score-list-mobile-styled'

const i18nBase = 'HotelSafetyCheck.ConcernScoresList'

export function ConcernScoreListMobile({ concernList, isLoading }: LayoutProps) {
  const { t } = useTranslation()
  const { isOpen, handleToggle } = useTogglePopup()

  return (
    <Container>
      <Title
        aria-label={t(`Accessibility.${isOpen ? 'ButtonTapToExpand' : 'ButtonTapToCollapse'}`, {
          label: t(`${i18nBase}.Title`),
        })}
        onClick={handleToggle}
        disabled={isLoading}
        aria-expanded={isOpen}>
        <span>{t(`${i18nBase}.Title`)}</span>
        {isLoading ? (
          <ConcernScoreListSkeleton />
        ) : (
          <Icon name={isOpen ? 'chevronUpPWA' : 'chevronDownPWA'} size="small" color="mainText" />
        )}
      </Title>
      {concernList && <ScoreList isOpen={isOpen} concernList={concernList} />}
    </Container>
  )
}
