import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import { useUserContext } from '@etta/modules/user'
import { ProfileBoxLayout } from './layout'
import { useProfileBox } from './use-profile-box'

type Props = {
  personalInfo: PersonalInfo | null
  primaryTraveler?: PurchaseInfoTravelerValueObject
  travelerOptions?: TravelerOptions | null
  errorMessage?: string
  isValid?: boolean
  onLinkClick: () => void
}

export function ProfileBox({
  personalInfo,
  primaryTraveler,
  travelerOptions,
  isValid = true,
  errorMessage,
  onLinkClick,
}: Props) {
  const { userStore } = useUserContext()
  const isGuest = userStore.isGuest
  const { fullName, email, initials } = useProfileBox({
    personalInfo,
    primaryTraveler,
    travelerOptions,
    isGuest,
  })

  return (
    <ProfileBoxLayout
      isGuest={isGuest}
      isValid={isValid}
      initials={initials}
      fullName={fullName}
      email={email}
      errorMessage={errorMessage}
      onLinkClick={onLinkClick}
    />
  )
}
