import { MediaLayout } from '@etta/ui/media-layout'
import type { Props, SliderValues } from './types'
import { SliderDesktop, SliderMobile } from './layout'

export function Slider<T extends SliderValues>(props: Props<T>) {
  return (
    <MediaLayout
      mobileSlot={<SliderMobile {...props} />}
      desktopSlot={<SliderDesktop {...props} />}
    />
  )
}
