import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useMobilitySearchQuery } from '@fiji/hooks/search-queries/use-mobility-search-query/use-mobility-search-query'
import { useTheme } from '@fiji/hooks/use-theme'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMultiModalSearchContext } from '@etta/modules/multi-modal-search/interface/use-multi-modal-search-context'
import type { RideHailLocation } from '@fiji/graphql/types'
import type { SearchRideHailResultValueObject } from '@etta/modules/ride-hail/core'
import type { EttaMapEntity } from '@etta/modules/etta-map/core/etta-map.entity'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import { RefinementOptionsEnum } from '@etta/modules/ride-hail/core'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { useMobilityViewClose } from '@fiji/hooks/mobility'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useViewState } from '@fiji/hooks/use-view-state'
import {
  useMobilitySuggestedRides,
  useMobilitySearchCurrentLocation,
  useMobilitySearchMapControl,
} from './hooks'
import { mapToDirectionSearch, mapToRideHailLocation, getCoordinate } from './utils'

const defaultCenterCoordinate: Coords = { lat: 0, lng: 0 }

export function useMobilitySearch() {
  const {
    searchRideHailActions,
    searchRideHailStore,
    rideHailSearchLocationRefinementStore,
    rideHailSearchLocationRefinementActions,
    expenseFlowStore,
    rideHailAirportPickupActions,
    rideHailAirportPickupStore,
  } = useRideHailSearchContext()

  const { multiModalSearchActions } = useMultiModalSearchContext()

  const { setRenderState, setSelectedRideHail, dropSearchResults } = searchRideHailActions
  const { renderState, isFetchingRideHailConfig, searchRideHailForm } = searchRideHailStore
  const { isFailureOnFetchingRideHailConfig } = expenseFlowStore
  const {
    colors: { mainText },
  } = useTheme()
  const viewStateParams = useViewState()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isMobilityZoningEnabled } = featureFlagsStore.flags
  const { firstSuggestedRide } = useMobilitySuggestedRides()
  const { queryParams } = useMobilitySearchQuery()
  const { pickUpPlace, pickUpAddress, dropOffPlace, dropOffAddress } = searchRideHailForm
  const [directionCoordinates, setDirectionCoordinates] = useState<{
    start?: Coords
    end?: Coords
  }>()

  const [animatingRenderState, setAnimatingRenderState] = useState<RenderState>(renderState)

  useEffect(() => {
    setAnimatingRenderState(renderState)
  }, [renderState])

  const map = useRef<EttaMapEntity | null>(null)

  const handleEditSearchClick = useCallback(() => {
    map.current?.removeRoute()
    setDirectionCoordinates(undefined)
    searchRideHailActions.dropSearchRideHailForm()
    rideHailAirportPickupActions.dropStore()
    setRenderState(RenderState.whereTo)
  }, [searchRideHailActions, setRenderState, rideHailAirportPickupActions])

  const shouldShowLocationRefinement =
    isMobilityZoningEnabled && !rideHailAirportPickupStore.isReserveAirportPickup

  const setDirection = useCallback(async () => {
    if (!pickUpPlace || !dropOffPlace || !map.current) {
      return
    }

    map.current.removeRoute()
    const result = await map.current.createRoute({
      origin: mapToDirectionSearch(pickUpPlace, pickUpAddress),
      destination: mapToDirectionSearch(dropOffPlace, dropOffAddress),
      options: {
        markerOptions: { visible: false },
        polylineOptions: {
          strokeColor: mainText,
          strokeOpacity: 0.8,
          strokeWeight: 3,
        },
      },
    })

    if (result.isErr()) {
      return
    }

    if (result.getValue().length === 0) {
      return
    }

    const { start_location, end_location } = result.getValue()[0].legs[0]
    setDirectionCoordinates({
      start: { lat: start_location.lat, lng: start_location.lng },
      end: { lat: end_location.lat, lng: end_location.lng },
    })
  }, [dropOffAddress, dropOffPlace, mainText, pickUpAddress, pickUpPlace])

  const onMapLoaded = (ettaMap: EttaMapEntity) => {
    map.current = ettaMap
    setDirection()
  }

  const { currentCoordinate } = useMobilitySearchCurrentLocation({
    defaultLocation: firstSuggestedRide?.dropOffLocation,
  })

  useEffect(() => {
    if (rideHailAirportPickupStore.isError || rideHailSearchLocationRefinementStore.isError) {
      viewStateParams.onChangeViewState('error')
    } else {
      viewStateParams.onChangeViewState('hidden')
    }
  }, [
    rideHailAirportPickupStore.isError,
    viewStateParams,
    rideHailSearchLocationRefinementStore.isError,
  ])

  const pickUp: RideHailLocation = mapToRideHailLocation(
    pickUpPlace ?? { latitude: 0, longitude: 0, name: '', placeId: '' },
    pickUpAddress,
  )

  useEffect(() => {
    if (renderState === RenderState.searchResult) {
      setDirection()
    }
  }, [setDirection, renderState])

  const pickUpCoordinate = getCoordinate(directionCoordinates?.start, pickUpPlace)
  const dropOffCoordinate = getCoordinate(directionCoordinates?.end, dropOffPlace)

  const loadingCoordinate = useMemo<Coords>(() => {
    if (
      dropOffCoordinate &&
      rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.DropOffZoning
    ) {
      return dropOffCoordinate
    }
    if (pickUpCoordinate) {
      return pickUpCoordinate
    }
    if (map.current) {
      return map.current.getCenter()
    }

    return currentCoordinate
  }, [
    rideHailSearchLocationRefinementStore.refinementOptionsAction,
    pickUpCoordinate,
    dropOffCoordinate,
    map,
    currentCoordinate,
  ])

  const {
    dialogWhereToContainerRef,
    dialogResultContainerRef,
    dialogReviewContainerRef,
    handlePanAndZoom,
  } = useMobilitySearchMapControl({
    map: map.current,
    pickUpCoordinate,
    dropOffCoordinate,
    currentCoordinate,
    loadingCoordinate,
  })

  const handleSearchResultCardClick = async (rideHailResult: SearchRideHailResultValueObject) => {
    setSelectedRideHail(rideHailResult)

    searchRideHailActions.setSearchRideHailForm({
      reserveItemId: rideHailResult.id,
      estimatedPrice: rideHailResult.estimatedPrice ?? undefined,
      displayName: rideHailResult.displayName,
    })
    if (shouldShowLocationRefinement) {
      setRenderState(RenderState.loading)
      rideHailSearchLocationRefinementActions.dropLocationRefinementOptions()
      await rideHailSearchLocationRefinementActions.loadLocationRefinementOptions()
      await handlePanAndZoom(() => {
        if (
          rideHailSearchLocationRefinementStore.isError &&
          rideHailSearchLocationRefinementStore.refinementOptionsAction ===
            RefinementOptionsEnum.Unspecified
        ) {
          return
        }

        if (
          isMobilityZoningEnabled &&
          rideHailSearchLocationRefinementStore.refinementOptionsAction !==
            RefinementOptionsEnum.NoRefinement
        ) {
          setRenderState(RenderState.zoning)
          return
        }
        setRenderState(RenderState.pickUpConfirm)
      })
    } else {
      setRenderState(RenderState.pickUpConfirm)
    }
  }

  const { handleClose } = useMobilityViewClose()

  const handleBack = useCallback(() => {
    if (renderState === RenderState.loading) {
      if (
        rideHailSearchLocationRefinementStore.isError ||
        rideHailSearchLocationRefinementStore.isLoading
      ) {
        rideHailSearchLocationRefinementActions.handleCancelLocationRefinementRequest()
        rideHailSearchLocationRefinementActions.dropLocationRefinementOptions()
        viewStateParams.onClose()
        return
      }
      if (rideHailAirportPickupStore.isError || rideHailAirportPickupStore.isLoading) {
        rideHailAirportPickupActions.handleCancelFlightAirportPickupRequest()
        rideHailAirportPickupActions.dropStore()
        viewStateParams.onClose()
        return
      }
    }

    if (renderState === RenderState.whereTo) {
      handleClose()
      return
    }

    if (renderState === RenderState.searchResult) {
      map.current?.removeRoute()
      setDirectionCoordinates(undefined)
      if (rideHailAirportPickupStore.arrivalFlight) {
        setRenderState(RenderState.reserveAirportPickUp)
        return
      }

      rideHailAirportPickupActions.dropStore()
      searchRideHailActions.dropSearchRideHailForm()
      multiModalSearchActions.handleDropStore()
      setRenderState(RenderState.whereTo)
      return
    }

    if (renderState === RenderState.zoning) {
      rideHailSearchLocationRefinementActions.cancelRequests()
      rideHailSearchLocationRefinementActions.handleBack()
      return
    }

    if (renderState === RenderState.pickUpConfirm) {
      dropSearchResults()
      rideHailSearchLocationRefinementActions.cancelRequests()
      rideHailSearchLocationRefinementActions.dropLocationRefinementOptions()
      setRenderState(RenderState.searchResult)
      return
    }

    if (renderState === RenderState.review) {
      setRenderState(RenderState.loading)
      rideHailSearchLocationRefinementActions.goBack(() => {
        handlePanAndZoom(() => {
          setRenderState(
            shouldShowLocationRefinement &&
              rideHailSearchLocationRefinementStore.refinementOptionsAction !==
                RefinementOptionsEnum.NoRefinement
              ? RenderState.zoning
              : RenderState.pickUpConfirm,
          )
        })
      })
    }

    if (renderState === RenderState.searchForm) {
      setRenderState(RenderState.whereTo)
    }
  }, [
    dropSearchResults,
    setRenderState,
    setDirectionCoordinates,
    handleClose,
    handlePanAndZoom,
    renderState,
    shouldShowLocationRefinement,
    rideHailSearchLocationRefinementStore.isError,
    rideHailSearchLocationRefinementStore.isLoading,
    rideHailAirportPickupStore.isError,
    rideHailAirportPickupStore.isLoading,
    rideHailAirportPickupStore.arrivalFlight,
    rideHailSearchLocationRefinementActions,
    rideHailSearchLocationRefinementStore.refinementOptionsAction,
    viewStateParams,
    rideHailAirportPickupActions,
    searchRideHailActions,
    multiModalSearchActions,
  ])

  const handleOnEntered = useCallback(() => {
    setAnimatingRenderState(renderState)
  }, [renderState])

  const handleConfirmPickUpButtonClick = () => {
    if (isFetchingRideHailConfig) {
      return
    }
    rideHailSearchLocationRefinementActions.handleContinue(() => setRenderState(RenderState.review))
  }

  useEffect(() => {
    const { processId, dropOffSegmentId, transactionGroupId } = queryParams
    if (!processId) {
      return
    }

    searchRideHailActions.setSearchRideHailTripInfo({
      processId,
      dropOffSegmentId,
      transactionGroupId,
    })
  }, [queryParams, searchRideHailActions])

  return {
    handleEditSearchClick,
    handleStartSearch: rideHailAirportPickupActions.handleStartSearch,
    handleSearchResultCardClick,
    handleConfirmPickUpButtonClick,
    handleBack,
    handleOnEntered,
    defaultCenterCoordinate,
    onMapLoaded,
    pickUpAddress: pickUp.address,
    pickUpConfirmCoordinate: pickUpCoordinate ?? defaultCenterCoordinate,
    currentCoordinate,
    loadingCoordinate,
    directionCoordinates,
    renderState,
    animatingRenderState,
    dialogWhereToContainerRef,
    dialogReviewContainerRef,
    dialogResultContainerRef,
    map: map.current,
    isFailureOnFetchingRideHailConfig,
    isFetchingRideHailConfig,
    viewState: viewStateParams.viewState,
  }
}
