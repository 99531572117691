import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { HotelCardDesktopSkeleton } from './desktop'
import { HotelCardMobileSkeleton } from './mobile'

export function HotelCardSkeleton() {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={HotelCardMobileSkeleton}
      desktopSlot={HotelCardDesktopSkeleton}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
