import styled from 'styled-components'
import { withStyledText } from '@fiji/style/text-styles'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  position: relative;
  height: 100%;
  max-height: 600px;
`

export const SpinnerContainer = styled.div`
  margin: auto;
  height: 600px;
  max-height: 600px;
  display: grid;
  place-items: center;
`

export const PolicyContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  padding: 24px 17px;
`

export const SectionContainer = styled.div`
  margin: 0px auto 20px;
  display: flex;
  align-items: baseline;

  &:last-child {
    margin: 0px auto;
  }
`

export const Dot = styled.span`
  margin-right: 15px;
  min-width: 6px;
  min-height: 6px;
  height: 6px;
  width: 6px;
  background-color: ${(p) => p.theme.colors.bodyText};
  border-radius: 50%;
  display: block;
`

export const PolicyText = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${withStyledText}
`
