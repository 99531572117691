import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'

@Store()
export class DynamicSiteMessagesTogglersStore {
  exploreDSMLightboxToggler = new Toggle(true)

  airSearchDSMLightboxToggler = new Toggle(true)

  hotelSearchDSMLightboxToggler = new Toggle(true)

  carRentalSearchDSMLightboxToggler = new Toggle(true)

  carRentalDSMLightboxToggler = new Toggle(true)

  railSearchDSMLightboxToggler = new Toggle(true)

  reviewTripDSMLightboxToggler = new Toggle(true)

  tripDetailsDSMLightboxToggler = new Toggle(true)

  purchaseReservationDSMLightboxToggler = new Toggle(true)

  checkoutDSMLightboxToggler = new Toggle(true)
}
