import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import type { HeaderColors } from '../types'

type BackButtonProps = {
  onClick: () => void
  color?: HeaderColors
  isDisabled?: boolean
}

export function BackButton({ onClick, color = 'mainText', isDisabled }: BackButtonProps) {
  const { t } = useTranslation()
  return (
    <IconButton
      onClick={onClick}
      icon="arrowBackLargePWA"
      size="medium"
      color={color}
      aria-label={t('Accessibility.Header.Back')}
      isDisabled={isDisabled}
    />
  )
}
