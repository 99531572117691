import { useRef } from 'react'
import type { PageTransitionGroups } from '@fiji/routes/types'

const DEFAULT_TRANSITION_GROUP: PageTransitionGroups = 'slide'

function getUsedTransitionGroup(
  nextTransitionGroup: PageTransitionGroups,
  oldGroup: PageTransitionGroups,
  isBack: boolean,
) {
  const nextGroup = nextTransitionGroup || DEFAULT_TRANSITION_GROUP
  const isGroupChanged = oldGroup !== nextGroup

  if (isGroupChanged && isBack) {
    return oldGroup
  }

  return nextGroup
}

type UseGroupsArgs = {
  transitionGroup?: PageTransitionGroups
  isBack: boolean
  isPageChanged: boolean
}

export default function useGroups({
  transitionGroup = DEFAULT_TRANSITION_GROUP,
  isBack,
  isPageChanged,
}: UseGroupsArgs) {
  const lastGroupRef = useRef<PageTransitionGroups>(DEFAULT_TRANSITION_GROUP)
  const usedGroupRef = useRef<PageTransitionGroups>(DEFAULT_TRANSITION_GROUP)
  if (!isPageChanged) {
    return {
      usedTransitionGroup: usedGroupRef.current,
    }
  }

  const nextTransitionGroup = transitionGroup
  const usedTransitionGroup = getUsedTransitionGroup(
    nextTransitionGroup,
    lastGroupRef.current,
    isBack,
  )

  lastGroupRef.current = nextTransitionGroup
  usedGroupRef.current = usedTransitionGroup

  return { usedTransitionGroup }
}
