import styled from 'styled-components'

export const Container = styled.div`
  padding: 4px 16px;
`

export const Section = styled.div`
  margin-top: 20px;

  &:last-child {
    margin-bottom: 20px;
  }
`

export const SkeletonWrapper = styled.div`
  padding: 32px 18px;
  width: 100%;
`
