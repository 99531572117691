import styled from 'styled-components'

export const Tickets = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Ticket = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 26px 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 205px;
`
