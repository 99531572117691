import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Checkbox } from '@etta/ui/checkbox'
import { Button as ConfirmationButton } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap'
import { formatLocation } from '@etta/modules/car-rental-search/interface/stores/format-location'
import { LocationsLoadingDesktopSkeleton } from '../locations-loading-desktop-skeleton'
import { Place } from '../desktop-place'
import { Container, Flex, Button } from './confirmation-styled'
import type { ConfirmationProps } from './types'

const i18nBase = 'CarRentalOfficeSearch.Confirmation'

export const Confirmation = observer(function Confirmation({
  isDropOffSameAsPickUp,
  selectedPickUpLocation,
  selectedDropOffLocation,
  handleConfirmationLocationChange,
  handleConfirmationLocationConfirm,
  handleSameDropOffToggle,
}: ConfirmationProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <Text variant="title2">{t(`${i18nBase}.Title`)}</Text>
      <Swap
        is={!selectedPickUpLocation || !selectedDropOffLocation}
        isSlot={<LocationsLoadingDesktopSkeleton />}>
        <Block marginTop={30}>
          <Flex>
            <Text variant="subHeadStrong">{t(`${i18nBase}.PickUp`)}</Text>
            <Button
              variant="link"
              onClick={() => handleConfirmationLocationChange(true)}
              data-tracking-id="car-rental-change-pick-up-button">
              {t(`${i18nBase}.Change`)}
            </Button>
          </Flex>
          {!!selectedPickUpLocation && (
            <Place
              formattedLocation={formatLocation(selectedPickUpLocation)}
              vendors={selectedPickUpLocation.vendors}
              isOpen={selectedPickUpLocation.isOpen}
              isAirport={false}
            />
          )}
        </Block>
        <Block marginTop={20}>
          <Flex>
            <Text variant="subHeadStrong">{t(`${i18nBase}.DropOff`)}</Text>
            {isDropOffSameAsPickUp ? (
              <div />
            ) : (
              <Button variant="link" onClick={() => handleConfirmationLocationChange(false)}>
                {t(`${i18nBase}.Change`)}
              </Button>
            )}
          </Flex>
          {!!selectedDropOffLocation && (
            <Place
              formattedLocation={formatLocation({
                ...selectedDropOffLocation,
                airportLocation: false,
              })}
              vendors={selectedDropOffLocation.vendors}
              isOpen={selectedDropOffLocation.isOpen}
              isAirport={false}
            />
          )}

          <Checkbox
            data-tracking-id="same-as-pickup-point"
            value={t(`${i18nBase}.Checkbox.Value`)}
            checked={isDropOffSameAsPickUp}
            label={t(`${i18nBase}.Checkbox.Label`)}
            onChange={handleSameDropOffToggle}
          />
        </Block>
        <Block marginTop={24}>
          <ConfirmationButton
            fullWidth
            color="primary"
            onClick={handleConfirmationLocationConfirm}
            data-tracking-id="car-rental-confirm-button"
            disabled={!selectedDropOffLocation?.isOpen || !selectedPickUpLocation?.isOpen}>
            {t(`${i18nBase}.ConfirmAndContinue`)}
          </ConfirmationButton>
        </Block>
      </Swap>
    </Container>
  )
})
