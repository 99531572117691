import { TimeStamp } from '../../swiping-time-picker-components'
import type { SwipingTimePickerUnits, SwipingPickerSubtype } from '../../types'
import { useTimeUnits } from './use-time-units'

type Props = {
  pilar: number
  type: SwipingTimePickerUnits
  variant?: SwipingPickerSubtype
  unitGroup?: number
  hourlyRange?: number
  range: number[]
  height: number
  activeUnit: number
  startYear: number
}

export function TimeUnits({
  pilar,
  variant,
  type,
  unitGroup,
  hourlyRange,
  range,
  height,
  activeUnit,
  startYear,
}: Props) {
  const { getTimeUnit } = useTimeUnits({
    variant,
    type,
    unitGroup,
    hourlyRange,
    range,
    startYear,
  })

  const rangeMap = range.map((_el, i: number) => (
    <TimeStamp
      key={type + '-' + pilar + i}
      height={height}
      focused={activeUnit}
      isDate={['day', 'month', 'year'].includes(type)}
      aria-label={`${i}`}>
      {getTimeUnit(i)}
    </TimeStamp>
  ))

  return <>{rangeMap}</>
}
