import { RailTicketRestrictions } from '@etta/components/rail-ticket-restrictions/rail-ticket-restrictions'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { SeatReservationType } from '@fiji/graphql/types'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { RailEmission } from '@fiji/types'
import { RailDetailsModalLayout } from './layout'
import { useRailDetailsModal } from './use-rail-details-modal'

type Props = {
  itineraryId: string
  isTripExist: boolean
  isChangeAllowed: boolean
  railSegmentsLength: number
  railLeg: TrainSegmentEntity
  railEmission: RailEmission
  onClose: () => void
  seatReservationType: SeatReservationType
  isRailEticket: boolean
  isSeatRequested: boolean
  appliedRailCard: string
}

export function RailDetailsModalContent({
  itineraryId,
  isTripExist,
  isChangeAllowed,
  railLeg,
  railSegmentsLength,
  seatReservationType,
  onClose,
  isRailEticket,
  railEmission,
  isSeatRequested,
  appliedRailCard,
}: Props) {
  const {
    changeModalConfirmLabel,
    changeModalContentLabel,
    changeModalDeclineLabel,
    changeModalTitleLabel,
    changeModalToggle,
    handleChangeRail,
    headerTitle,
    carrierLogoUrl,
    informationText,
    handleRemoveRail,
    viewStateParams,
    removeDialogToggle,
    removeModalTitleLabel,
    removeModalContentLabel,
    removeModalConfirmLabel,
    removeModalDeclineLabel,
    onOpenModalClick,
    ticketRestrictionModalToggle,
    squareRailLogos,
    ticketRestrictions,
  } = useRailDetailsModal({
    itineraryId,
    railLeg,
    railSegmentsLength,
    onClose,
  })

  return (
    <>
      <RailDetailsModalLayout
        isTripExist={isTripExist}
        isChangeAllowed={isChangeAllowed}
        railLeg={railLeg}
        carrierLogoUrl={carrierLogoUrl}
        informationText={informationText}
        headerTitle={headerTitle}
        onDetailsClick={onOpenModalClick}
        onChangeClick={changeModalToggle.handleOpen}
        onCancelClick={removeDialogToggle.handleOpen}
        seatReservationType={seatReservationType}
        isRailEticket={isRailEticket}
        squareRailLogos={squareRailLogos}
        railEmission={railEmission}
        isSeatRequested={isSeatRequested}
        appliedRailCard={appliedRailCard}
      />
      <RailTicketRestrictions
        isVisible={ticketRestrictionModalToggle.isOpen}
        onClose={ticketRestrictionModalToggle.handleClose}
        ticketRestrictionsInfo={ticketRestrictions}
      />
      <ConfirmationDialog
        title={changeModalTitleLabel}
        content={changeModalContentLabel}
        isOpen={changeModalToggle.isOpen}
        onDecline={changeModalToggle.handleClose}
        onConfirm={handleChangeRail}
        confirmButtonText={changeModalConfirmLabel}
        declineButtonText={changeModalDeclineLabel}
      />
      <ConfirmationDialog
        title={removeModalTitleLabel}
        content={removeModalContentLabel}
        isOpen={removeDialogToggle.isOpen}
        onDecline={removeDialogToggle.handleClose}
        onConfirm={handleRemoveRail}
        confirmButtonText={removeModalConfirmLabel}
        declineButtonText={removeModalDeclineLabel}
      />
      <ViewStateModal
        viewState={viewStateParams.viewState}
        onDone={() => {
          removeDialogToggle.handleClose()
          viewStateParams.onClose()
        }}
        title={viewStateParams.title}
      />
    </>
  )
}
