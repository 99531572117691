import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useUserContext } from '@etta/modules/user'
import { formatCancellationDate } from '@fiji/utils/dates/cancellation-date-format'
import { MediaLayout } from '@etta/ui/media-layout'
import { AutoCancellationMessageDesktop } from './desktop/autocancellation-message-desktop'
import { AutoCancellationMessageMobile } from './mobile/autocancellation-message-mobile'
import type { Props } from './types'

const i18Base = 'HoldTrip'

export const AutoCancellationMessage = observer(function AutoCancellationMessage({
  time,
  customBackground,
  isHolded,
}: Props) {
  const { userStore } = useUserContext()
  const timeZone = userStore.profile?.timeZone
  const translationSlot = useMemo(() => {
    const i18nKey = isHolded ? `${i18Base}.AutoCancellationHolded` : `${i18Base}.AutoCancellation`
    return (
      <Trans
        i18nKey={i18nKey}
        values={{
          time: formatCancellationDate(time, timeZone),
        }}
        components={{ bold: <strong /> }}
      />
    )
  }, [time, timeZone, isHolded])

  return (
    <MediaLayout
      desktopSlot={<AutoCancellationMessageDesktop translationSlot={translationSlot} />}
      mobileSlot={
        <AutoCancellationMessageMobile
          customBackground={customBackground}
          translationSlot={translationSlot}
        />
      }
    />
  )
})
