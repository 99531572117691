import { useCallback, useMemo } from 'react'
import { formatMoney } from '@fiji/utils/money/format-money/format-money'
import { useRoomsAverageMap } from '@fiji/utils/room-price'
import { useOutOfPolicyHotelData } from '@fiji/hooks/use-out-of-policy-hotel-data'
import type { Hotel } from '@fiji/graphql/types'
import { useUserContext } from '@etta/modules/user'
import { getIconName, getIconColor } from './get-icon'

type Props = {
  hotel: Hotel
  selectedId?: string
  onSelect: (id: string) => void
}

export function useMapMarker({ hotel, selectedId, onSelect }: Props) {
  const { userStore } = useUserContext()
  const { locale } = userStore

  const roomsAverage = useRoomsAverageMap(hotel.rooms)
  const averageRoomRatePrice =
    hotel.rooms?.[0]?.averageRate.secondary ?? hotel.rooms?.[0]?.averageRate.primary
  const handleSelect = useCallback(() => {
    onSelect(hotel.id)
  }, [hotel.id, onSelect])
  const isSoldOut = !hotel?.rooms?.length
  const rate = averageRoomRatePrice
    ? formatMoney(averageRoomRatePrice, {
        hideDecimal: true,
        locale: locale,
      })
    : roomsAverage &&
      formatMoney(roomsAverage, {
        hideDecimal: true,
        locale: locale,
      })

  const { isAllRoomsOutOfPolicy } = useOutOfPolicyHotelData(hotel)

  const { isActive, iconName, iconColor } = useMemo(() => {
    const isActive = hotel.id === selectedId

    return {
      isActive,
      iconName: getIconName({ isAllRoomsOutOfPolicy }),
      iconColor: getIconColor({ isActive, isAllRoomsOutOfPolicy }),
    }
  }, [hotel, selectedId, isAllRoomsOutOfPolicy])

  return {
    isActive,
    isSoldOut,
    rate,
    iconName,
    iconColor,
    handleSelect,
  }
}
