import styled from 'styled-components'
import { Link } from '@etta/ui/link'

export const DeviationDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const LearnMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary1};
`

export const LearnMoreLinkWrapper = styled.div`
  margin: 0;
  margin-top: 8px;
`
