import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'
import { DesktopFooterSimple } from './desktop-footer-simple/desktop-footer-simple'
import { DesktopFooterCobranding } from './desktop-footer-cobranding/desktop-footer-cobranding'

export function DesktopFooter() {
  const { partnerCoBrandingConfigurationsStore } = usePartnerCoBrandingConfigurationsContext()
  const { isPartnerCoBrandingEnabledAndSiteIsNotApple } = partnerCoBrandingConfigurationsStore

  return isPartnerCoBrandingEnabledAndSiteIsNotApple ? (
    <DesktopFooterCobranding />
  ) : (
    <DesktopFooterSimple />
  )
}
