import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import { Content, Title } from '../../pre-search-custom-fields-styled'
import { PreSearchCustomFieldsForm } from '../../pre-search-custom-fields-form'
import type { Props } from '../../types'

export function PreSearchCustomFieldsMobile({
  customFields,
  errors,
  onFieldChange,
  values,
  onBack,
  isVisible,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PreSearch.'

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onBack} backgroundColor="background">
      <Modal.Header isMobile withBorder>
        <Header
          leftSlot={<Header.BackButton color="mainText1" onClick={onBack} />}
          backgroundColor="white">
          <Title>{t(i18nBase + 'AdditionalInformation')}</Title>
        </Header>
      </Modal.Header>

      <Modal.Body>
        <Content>
          <PreSearchCustomFieldsForm
            errors={errors}
            values={values}
            customFields={customFields}
            onFieldChange={onFieldChange}
            onSubmit={onSubmit}
          />
        </Content>
      </Modal.Body>

      <Modal.Footer padding="normal">
        <Button fullWidth onClick={onSubmit}>
          {t(i18nBase + 'Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
