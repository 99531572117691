import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import type { RailTicketRestrictionsModalProps } from './types'
import { RailTicketRestrictionsContent } from './rail-ticket-restrictions-content'

const i18nBase = 'RailDetails.RestrictionTicket.'

export function RailTicketRestrictionsModal({
  onClose,
  ticketRestrictionsInfo,
  isPostBooking,
}: RailTicketRestrictionsModalProps) {
  const { t } = useTranslation()

  return (
    <>
      <InfoModal.Header title={t(i18nBase + 'Title')} onClose={onClose} />
      <Modal.Body>
        <RailTicketRestrictionsContent
          ticketRestrictionsInfo={ticketRestrictionsInfo}
          needTicketRestrictionTitle={false}
          isPostBooking={isPostBooking}
        />
      </Modal.Body>
    </>
  )
}
