import { useCallback } from 'react'
import { useAppDispatch } from '@fiji/store'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query/use-hotel-search-query'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { updateHotelId } from '@fiji/store/segment-ids'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { PostBookingAction } from '@fiji/enums'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { ROUTES } from '@fiji/routes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { resolveLocation } from '../helpers'

type Props = {
  hotel: HotelPostBookingValueObject
  processId: string
  itineraryId: string
}

export function useModifyHotel({ hotel, processId, itineraryId }: Props) {
  const dispatch = useAppDispatch()
  const { appendQueryParams, navigateTo } = useHotelSearchQuery()
  const { postBookingAddSegmentAction } = usePostBookingContext()
  const {
    multipleHotelProvidersStore,
    multipleHotelProvidersActions,
  } = useMultipleHotelProvidersContext()
  const { areMultipleProvidersExist, handleSetActionType } = multipleHotelProvidersActions
  const { multipleHotelProvidersTripDialog } = multipleHotelProvidersStore

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const handleModifyHotel = useCallback(() => {
    if (areMultipleProvidersExist()) {
      handleSetActionType('modify')
      multipleHotelProvidersTripDialog.handleOpen()
      return
    }

    dispatch(updateHotelId({ hotelId: hotel.id }))
    dispatch(updatePostPurchaseEntrance('fromTripDetails'))

    const queryParams = {
      itineraryId,
      checkInDate: hotel.checkIn ? new Date(getDateWithoutTimezoneOffset(hotel.checkIn)) : null,
      checkOutDate: hotel.checkOut ? new Date(getDateWithoutTimezoneOffset(hotel.checkOut)) : null,
      location: resolveLocation(hotel.address),
      bookingId: processId,
      postBookingAction: PostBookingAction.Modify,
    }
    if (isMobile) {
      navigateTo(ROUTES.hotel.search, queryParams)
    } else {
      appendQueryParams(queryParams)
    }

    postBookingAddSegmentAction.handleAddHotel()
  }, [
    postBookingAddSegmentAction,
    appendQueryParams,
    dispatch,
    hotel.address,
    hotel.checkIn,
    hotel.checkOut,
    hotel.id,
    isMobile,
    itineraryId,
    navigateTo,
    processId,
    handleSetActionType,
    areMultipleProvidersExist,
    multipleHotelProvidersTripDialog,
  ])

  return {
    handleModifyHotel,
  }
}
