import { mapEmissionsEquivalenceToValueObject } from '@etta/modules/rail/infra/mappers/utils'
import type { CalculateTripEmissionsResult } from '@fiji/graphql/types'
import type { RailEmission } from '@fiji/types'

type GetCarRentalEmissionInput = {
  carId?: string | null
  tripEmissionsResult?: CalculateTripEmissionsResult
  loading: boolean
}

type GetFlightEmissionInput = {
  legId?: string | null
  tripEmissionsResult?: CalculateTripEmissionsResult
  loading: boolean
}

type GetRailEmissionInput = {
  legId?: string | null
  tripEmissionsResult?: CalculateTripEmissionsResult
  loading: boolean
}

type GetHotelEmissionInput = {
  hotelId?: string | null
  tripEmissionsResult?: CalculateTripEmissionsResult
  loading: boolean
}

export function getTripSegmentsEmission() {
  const getFlightEmission = ({ legId, tripEmissionsResult, loading }: GetFlightEmissionInput) => {
    const flightEmissions = tripEmissionsResult?.flights?.find(
      (flight) => legId === flight.customRef,
    )

    return {
      loading,
      averageRouteEmissionTonnes: flightEmissions?.averageRouteEmissionTonnes,
      tonnesOfEmissions: flightEmissions?.tonnesOfEmissions,
      equivalences: flightEmissions?.equivalences,
      customRef: flightEmissions?.customRef,
    }
  }

  const getRailEmission = ({
    legId,
    tripEmissionsResult,
    loading,
  }: GetRailEmissionInput): RailEmission => {
    const railEmissions = tripEmissionsResult?.rails?.find((rail) => legId === rail.customRef)
    const kgOfActualEmission =
      railEmissions?.tonnesOfEmissions &&
      Number((railEmissions?.tonnesOfEmissions * 1000).toFixed(2))

    return {
      isLoading: loading,
      averageRouteEmissionTonnes: railEmissions?.averageRouteEmissionTonnes,
      tonnesOfEmissions: railEmissions?.tonnesOfEmissions,
      equivalences: railEmissions?.equivalences?.map(mapEmissionsEquivalenceToValueObject), // TODO: remove this once uses value object type for railEmissions
      customRef: railEmissions?.customRef,
      kgOfActualEmission,
    }
  }

  const getCarRentalEmission = ({
    carId,
    tripEmissionsResult,
    loading,
  }: GetCarRentalEmissionInput) => {
    const carEmission = tripEmissionsResult?.cars?.find((car) => carId === car.customRef)

    return {
      loading,
      averageEmissionsTonnes: carEmission?.averageEmissionTonnes,
      customRef: carEmission?.customRef,
      equivalences: carEmission?.equivalences,
      tonnesOfEmissions: carEmission?.tonnesOfEmissions,
    }
  }

  const getHotelEmission = ({ hotelId, tripEmissionsResult, loading }: GetHotelEmissionInput) => {
    const hotelEmission = tripEmissionsResult?.hotels?.find((hotel) => hotelId === hotel.customRef)

    return {
      loading,
      averageLocationEmissionsTonnes: hotelEmission?.averageLocationEmissionsTonnes,
      averageLocationSustainabilityScore: hotelEmission?.averageLocationSustainabilityScore,
      sustainabilityScore: hotelEmission?.sustainabilityScore,
      equivalences: hotelEmission?.equivalences,
      certifications: hotelEmission?.certifications,
      customRef: hotelEmission?.customRef,
      tonnesOfEmissions: hotelEmission?.tonnesOfEmissions,
    }
  }

  return {
    getFlightEmission,
    getHotelEmission,
    getCarRentalEmission,
    getRailEmission,
  }
}
