import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { CovidInformationStatisticRows } from '../../covid-information-statistic-rows'
import type { LayoutProps } from '../types'
import { Container, Label, List } from '../covid-information-statistic-styled'
import {
  Title,
  LineWrapper,
  InfoBlock,
  Header,
} from './covid-information-statistic-layout-desktop-styled'

export function CovidInformationStatisticLayoutDesktop({
  covidStatistic,
  i18nBase,
  shouldColumnNameRender = true,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        {shouldColumnNameRender && (
          <Title aria-label={covidStatistic.name}>{covidStatistic.name}</Title>
        )}
        <Text variant="footnoteMedium" color="bodyText">
          {t(`${i18nBase}.LatestInformation`)}
        </Text>
      </Header>
      <LineWrapper>
        <InfoBlock>
          <Label aria-label={t(`${i18nBase}.Cases`)}>{t(`${i18nBase}.Cases`)}</Label>
          <List data-tracking-id="covid-list_cases">
            <CovidInformationStatisticRows list={covidStatistic.cases} i18nBase={i18nBase} />
          </List>
        </InfoBlock>
        <InfoBlock>
          <Label aria-label={t(`${i18nBase}.Deaths`)}>{t(`${i18nBase}.Deaths`)}</Label>
          <List data-tracking-id="covid-list_deaths">
            <CovidInformationStatisticRows list={covidStatistic.deaths} i18nBase={i18nBase} />
          </List>
        </InfoBlock>
      </LineWrapper>
    </Container>
  )
}
