import styled from 'styled-components'
import { subHeadMedium, headline, captionMedium } from '@fiji/style'
import { TextField } from '@etta/ui/text-field'

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${headline}
`

export const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`

export const Container = styled.div`
  padding: 22px 20px;
`

export const Description = styled.span`
  ${subHeadMedium}
  color: ${(p) => p.theme.colors.bodyText};
`

export const CustomExpenseCodeTextField = styled(TextField)`
  margin-top: 10px;
`

export const Message = styled.div`
  display: flex;
  ${captionMedium};
  margin-top: 2px;
  color: ${(p) => p.theme.colors.bodyText};
  height: 20px;
  transition: height 0.3s;
  line-height: 20px;
  overflow: hidden;
`
