import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { CarRentalFuelType, CarRentalTransmissionType } from '@fiji/graphql/types'
import type { PWAIconNames } from '@etta/ui/icon'

type Params = {
  carRental: CarRentalPostBookingValueObject
}

type AmenityItem = {
  icon: PWAIconNames
  title: string
}

const i18Base = 'CarRentalDetails.Amenities.'

export function useAmenities({ carRental }: Params) {
  const { isOpen: isExpanded, handleToggle } = useTogglePopup(false)
  const { t } = useTranslation()

  const {
    carCapacity: passengers,
    carLuggage: luggage,
    isCarAirConditioned: isAirConditioned,
    isUnlimitedMileage,
  } = carRental
  const isAutomaticTransmission = carRental.carTransmission === CarRentalTransmissionType.Automatic
  const isInTerminal =
    carRental.pickUpLocation?.isAirportLocation && carRental.dropOffLocation?.isAirportLocation
  const isHybridFuel = carRental.fuelType === CarRentalFuelType.Hybrid

  const seatsText = luggage + ' ' + t(`${i18Base}Bags`)
  const passengersText = `${passengers} ${t(`${i18Base}Seats`)}`
  const automaticTransmissionText = t(`${i18Base}AutomaticTransmission`)
  const terminalText = t(`${i18Base}InTerminal`)
  const unlimitedMileageText = t(`${i18Base}UnlimitedMileage`)
  const airConditionerText = t(`${i18Base}AirConditioning`)
  const ariaLabel = t(`${i18Base}AriaLabel`)
  const hybridFuelText = t(`${i18Base}HybridFuel`)

  const amenities = useMemo(
    () =>
      [
        isHybridFuel && {
          icon: 'carHybridPWA',
          title: hybridFuelText,
        },
        {
          icon: 'peoplePWA',
          title: passengersText,
        },
        isAutomaticTransmission && {
          icon: 'transmissionPWA',
          title: automaticTransmissionText,
        },
        {
          icon: 'bagPWA',
          title: seatsText,
        },
        isInTerminal && {
          icon: 'terminalPWA',
          title: terminalText,
        },
        isUnlimitedMileage && {
          icon: 'odometerPWA',
          title: unlimitedMileageText,
        },
        isAirConditioned && {
          icon: 'snowflakePWA',
          title: airConditionerText,
        },
      ].filter((record) => !!record) as AmenityItem[],
    [
      isHybridFuel,
      hybridFuelText,
      passengersText,
      isAutomaticTransmission,
      automaticTransmissionText,
      seatsText,
      isInTerminal,
      terminalText,
      unlimitedMileageText,
      isUnlimitedMileage,
      isAirConditioned,
      airConditionerText,
    ],
  )

  return {
    isExpanded,
    handleToggle,
    amenitiesCount: amenities.length,
    passengers,
    isAutomaticTransmission,
    isUnlimitedMileage,
    isAirConditioned,
    isInTerminal,
    isHybridFuel,
    seatsText,
    automaticTransmissionText,
    terminalText,
    unlimitedMileageText,
    airConditionerText,
    ariaLabel,
    hybridFuelText,
    passengersText,
    amenities,
  }
}
