import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 24px;
  width: 100%;
`

export const DurationContainer = styled.div`
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
`

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
  gap: 8px;
  svg,
  div,
  img {
    width: 24px;
    height: 24px;
  }
`

export const Disruptions = styled(Block)`
  display: flex;
  align-items: center;
`
