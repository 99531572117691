import type { Coords } from '../../core/coords'
import type { TravelMode } from '../../core/travel-mode'
import type { RoutePlace, RouteResult } from '../../core/route'
import type { MarkerOptions } from '../../core/marker.options'

const MAX_DISTANCE_IN_METERS = 1000

export class GoogleMapHelpers {
  static convertGoogleMapRadiusToZoom(radius: number): number {
    return Math.round(13.5 - Math.log(radius) / Math.LN2) + 1
  }

  static checkIsCoordinatesEqual(a: Coords, b: Coords): boolean {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
  }

  static checkOutOfRange({
    boundsCenter,
    mapCenter,
    boundsDistanceMeters = MAX_DISTANCE_IN_METERS,
  }: {
    boundsCenter: Coords
    mapCenter: Coords
    boundsDistanceMeters?: number
  }) {
    const bounds = new google.maps.LatLng(boundsCenter.lat, boundsCenter.lng)
    const center = new google.maps.LatLng(mapCenter.lat, mapCenter.lng)
    const distanceInMeters = Math.round(
      google.maps.geometry.spherical.computeDistanceBetween(bounds, center),
    )

    return distanceInMeters > boundsDistanceMeters
  }

  static createBoundsByCoordinates(coords: Coords[]) {
    const bounds = coords.reduce((acc, location) => {
      acc.extend(new google.maps.LatLng(location.lat, location.lng))
      return acc
    }, new google.maps.LatLngBounds())

    return bounds
  }

  static getPlaceFromRoute(
    place: RoutePlace,
  ): string | google.maps.LatLng | google.maps.LatLngLiteral | google.maps.Place {
    if (GoogleMapHelpers.isPlaceCoordinate(place)) {
      return new google.maps.LatLng(place.lat, place.lng)
    }
    return place
  }

  static getTravelMode(travelMode?: TravelMode): google.maps.TravelMode {
    switch (travelMode) {
      case 'BICYCLING':
        return google.maps.TravelMode.BICYCLING
      case 'DRIVING':
        return google.maps.TravelMode.DRIVING
      case 'TRANSIT':
        return google.maps.TravelMode.TRANSIT
      case 'WALKING':
        return google.maps.TravelMode.WALKING
      case 'TWO_WHEELER':
        return google.maps.TravelMode.TWO_WHEELER
      default:
        return google.maps.TravelMode.DRIVING
    }
  }
  static getRouteResultFromGoogleDirection(direction: google.maps.DirectionsResult): RouteResult[] {
    return direction.routes.map((route) => {
      const legs = route.legs.map((leg) => {
        return {
          start_location: leg.start_location.toJSON(),
          end_location: leg.end_location.toJSON(),
        }
      })

      return { legs }
    })
  }

  static isPlaceCoordinate(place: RoutePlace): place is Coords {
    return typeof place !== 'string' && 'lat' in place
  }

  static getDirectionBoundFromGoogleDirection(direction: google.maps.DirectionsResult) {
    if (direction.routes.length > 0) {
      return direction.routes[0].bounds
    }
  }

  static createMarkerIcon(option?: MarkerOptions): google.maps.ReadonlySymbol {
    return {
      path: option?.path ?? '',
      fillColor: option?.fillColor ?? '#ffffff',
      fillOpacity: option?.fillOpacity ?? 1,
      strokeColor: option?.strokeColor ?? '#000000',
      strokeWeight: option?.strokeWeight ?? 1,
      scale: option?.scale ?? 1,
    }
  }

  static createMarker(location: Coords, option?: MarkerOptions): google.maps.Marker {
    return new google.maps.Marker({
      position: location,
      icon: GoogleMapHelpers.createMarkerIcon(option),
    })
  }
}
