import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { IconButton } from '@etta/ui/icon-button'
import { Separator } from '@etta/ui/separator'
import { ModalDetails } from '@etta/components/modal-details'
import { PresearchMultiLayerCostAllocation } from '@etta/components/multi-layer-cost-allocation'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { PreSearchCustomFieldsForm } from '../../pre-search-custom-fields-form'
import type { Props } from '../../types'
import { Content, ConfirmButtonWrapper } from './pre-search-custom-fields-desktop-styled'

export function PreSearchCustomFieldsDesktop({
  customFields,
  errors,
  onFieldChange,
  values,
  onBack,
  isVisible,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PreSearch.'
  const { costAllocationStore } = useCostAllocationContext()
  const { isCostAllocationsValid } = costAllocationStore

  return (
    <ModalDetails
      horizontalDimension={'content-760'}
      isAdaptivePosition
      backgroundColor="background"
      isVisible={isVisible}
      onClose={onBack}>
      <Modal.Header isMobile withBorder>
        <Block paddingHorizontal={32}>
          <Header
            minHeight={72}
            backgroundColor="white"
            rightSlot={
              <IconButton size="medium" icon="closeFilledPWA" color="bodyText" onClick={onBack} />
            }>
            <Text color="mainText" variant="title3">
              {t(i18nBase + 'AdditionalInformation')}
            </Text>
          </Header>
        </Block>
      </Modal.Header>

      <Modal.Body>
        <Content>
          <PresearchMultiLayerCostAllocation />
          <PreSearchCustomFieldsForm
            errors={errors}
            values={values}
            customFields={customFields}
            onFieldChange={onFieldChange}
            onSubmit={onSubmit}
          />
        </Content>
      </Modal.Body>
      <Separator />
      <Block paddingHorizontal={32} paddingVertical={14}>
        <Modal.Footer>
          <ConfirmButtonWrapper>
            <Button fullWidth onClick={onSubmit} disabled={!isCostAllocationsValid}>
              {t(i18nBase + 'Confirm')}
            </Button>
          </ConfirmButtonWrapper>
        </Modal.Footer>
      </Block>
    </ModalDetails>
  )
}
