import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { Location } from '../types'
import { GeoSafeScale } from './geo-safe-scale'
import { GeoSafeLocation } from './geo-safe-location'
import { getAriaLabel } from './get-aria-label'

type Props = {
  isLoading: boolean
  location: Location | undefined
  scores: number | undefined
  hotelName?: string
}

const i18nBase = 'HotelSafetyCheck.GeoSafeScores'

export function GeoSafeScores({ hotelName, location, scores, isLoading }: Props) {
  const { t } = useTranslation()

  return (
    <div
      aria-label={getAriaLabel({ i18nBase, scores })}
      data-tracking-id="scores-by-concerns-scale">
      <Block marginBottom={2}>
        <Text aria-label={t(`${i18nBase}.Title`)} variant="headline" color="mainText">
          {t(`${i18nBase}.Title`)}
        </Text>
      </Block>
      <Block marginBottom={16}>
        <GeoSafeScale scores={scores} isLoading={isLoading} />
      </Block>
      <GeoSafeLocation hotelName={hotelName} location={location} isLoading={isLoading} />
    </div>
  )
}
