import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { PurchaseInfoValueObject } from '../../../core/value-objects'

@Store()
export class PurchaseInfoStore {
  isLoading = true

  isError = false

  purchaseInfo: PurchaseInfoValueObject | null = null

  isDobTouched = false
  isGenderTouched = false

  private editModeToggle = new Toggle()

  setIsLoading(value: boolean) {
    this.isLoading = value
  }
  setIsDobTouched(value: boolean) {
    this.isDobTouched = value
  }
  setIsGenderTouched(value: boolean) {
    this.isGenderTouched = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setPurchaseInfo(purchaseInfo: PurchaseInfoValueObject | null) {
    this.purchaseInfo = purchaseInfo
  }
  handleToggleOpen() {
    this.editModeToggle.handleOpen()
  }
  handleToggleClose() {
    this.editModeToggle.handleClose()
  }
  isToggleOpen() {
    return this.editModeToggle.isOpen
  }
}
