import styled from 'styled-components'

export const Container = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 6px;
  width: ${(props) => (props.isFullWidth ? '100%' : '50%')};
  padding: 10px;
  color: ${(props) => props.theme.colors.bodyText};
  align-items: center;
`
