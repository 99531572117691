import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import {
  IdentityDataProvider,
  toHeapAnalyticsIdentityHash,
} from '@etta/modules/admin/infra/identity.data-provider'
import type { HeapAnalyticsIdentityValueObject } from '@etta/modules/admin/core/value-objects/heap-analytics-identity.value-object'
import type { PromisedResult } from '@etta/core/type-utils'
import type { HeapAnalyticsIdentityErrorsInstance } from '../core/errors/heap-analytics-identity.errors'
import { HeapAnalyticsIdentityErrors } from '../core/errors/heap-analytics-identity.errors'

@Adapter()
export class IdentityAdapter {
  constructor(
    @Inject()
    private identityDataProvider: IdentityDataProvider,
  ) {}

  async getIdentityHash(
    email: string,
  ): PromisedResult<HeapAnalyticsIdentityValueObject, HeapAnalyticsIdentityErrorsInstance> {
    try {
      const { data, error } = await this.identityDataProvider.getIdentityHash(email)

      if (error) {
        return Result.Err(new HeapAnalyticsIdentityErrors.UnexpectedError(error))
      }

      const hash = toHeapAnalyticsIdentityHash(data.identityHash)

      return Result.Ok({
        email,
        hashValue: hash,
      })
    } catch (e) {
      return Result.Err(new HeapAnalyticsIdentityErrors.UnexpectedError(e))
    }
  }
}
