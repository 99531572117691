import { useTranslation } from 'react-i18next'
import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { templates } from '@fiji/hooks/use-share-via-email'
import type { TripDetailsRideHailSegment } from '@fiji/graphql/types'

type Props = {
  travelerFullName: string
  rideHail: TripDetailsRideHailSegment
}
const i18nBase = 'PostBooking.RideHailDetails'

export function useActionButtons({ rideHail, travelerFullName }: Props) {
  const { t } = useTranslation()
  const handleShare = useShareViaEmail({
    subject: t(`${i18nBase}.ShareEmailSubject`, { description: rideHail.description }),
    body: templates.postBookingRideHail({ rideHail, travelerFullName }),
  })

  return { handleShare }
}
