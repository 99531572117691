import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useUserContext } from '@etta/modules/user'
import type { CreditCardModalProps } from './types'
import { CreditCardModalLayout } from './layout'
import { useCreditCard } from './use-credit-card'

const i18Base = 'CreditCardForm.'

export const CreditCardModal = observer(function CreditCardModal({
  isOpen,
  onClose,
  onSubmit,
  isEditForm,
  isBillingAddressRequired,
  creditCardFormData,
  isSingleUseCreditCardAllowed,
  isEditableCreditCardSingleUse,
}: CreditCardModalProps) {
  const { t } = useTranslation()
  const modalTitle = isEditForm ? t(i18Base + 'Title.Edit') : t(i18Base + 'Title.Add')
  const { userStore } = useUserContext()
  const {
    handleSubmit,
    isSubmitLoading,
    isCvvRequired,
    isOneTimeUseCard,
    handleChangeOneTimeUseCard,
    handleChangeShouldSaveGuestCard,
    shouldSaveGuestCard,
    cardNumber,
    cardValues,
    addressValues,
    cardErrors,
    addressErrors,
    onCardFieldChange,
    onAddressFieldChange,
    isCardNumberFieldEdited,
    handleSetCardNumberFieldEdited,
    isSavingCreditCardEnabled,
  } = useCreditCard({
    onSubmit,
    creditCardFormData,
    isBillingAddressRequired,
    isOpen,
    isEditableCreditCardSingleUse,
  })

  const componentsProps = {
    isOpen,
    onClose,
    isBillingAddressRequired,
    isEditForm,
    isSingleUseCreditCardAllowed,
    handleSubmit,
    isSubmitLoading,
    isCvvRequired,
    isGuest: userStore.isGuest,
    guestPersonalInfo: userStore.guestPersonalInfo,
    handleChangeShouldSaveGuestCard,
    shouldSaveGuestCard,
    isOneTimeUseCard,
    handleChangeOneTimeUseCard,
    onAddressFieldChange,
    addressErrors,
    addressValues,
    onCardFieldChange,
    cardValues,
    cardErrors,
    modalTitle,
    cardNumber,
    isCardNumberFieldEdited,
    handleSetCardNumberFieldEdited,
    isSavingCreditCardEnabled,
  }

  return <CreditCardModalLayout {...componentsProps} />
})
