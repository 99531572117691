import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePreventPullRefresh } from '@fiji/hooks/body/use-prevent-pull-refresh'
import type { RoomType } from '@fiji/graphql/types'
import { useRoomDetails } from '../room-rates-details'

type Params = {
  hotelId: string
  roomId: string
  checkIn: string
  checkOut: string
  searchId: string | null
  roomType?: RoomType | null
  processId?: string
}

const i18Base = 'HotelInfo.Rooms'

export function useRoomRatesContent({
  roomId,
  hotelId,
  searchId,
  checkOut,
  checkIn,
  processId,
  roomType,
}: Params) {
  usePreventPullRefresh()
  const { t } = useTranslation()
  const { isLoading, error, details, refetch } = useRoomDetails({
    searchId,
    hotelId,
    roomId,
    checkIn,
    checkOut,
    processId,
  })

  const roomTypeText = useMemo(() => (roomType && t(`${i18Base}.Room`, { roomType })) || '', [
    roomType,
    t,
  ])
  const translations = useMemo(
    () => ({
      roomRates: t(i18Base + '.RoomDetails.RoomRates'),
      roomDetails: t(i18Base + '.RoomDetails.RoomDetails'),
    }),
    [t],
  )

  return {
    isLoading,
    error,
    details,
    refetch,
    roomTypeText,
    translations,
  }
}
