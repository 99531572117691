import { Result } from 'fnscript'
import { Adapter } from '@etta/di'
import { AlertErrors } from '@etta/modules/alert/core/errors/alert.errors'
import type { AlertValueObject } from '@etta/modules/alert/core/value-objects/alert.value-object'
import { AlertsDataProvider } from './data-provider'
import { AlertsMapper } from './mapper/alerts.mapper'
import type { AlertsAdapterResult, GetAlertArgs } from './types'

@Adapter()
export class AlertAdapter {
  constructor(private readonly alertsDataProvider: AlertsDataProvider) {}
  async getAlerts({ pageSize }: GetAlertArgs): AlertsAdapterResult<AlertValueObject> {
    try {
      const { data, error } = await this.alertsDataProvider.getAlerts({ pageSize })
      if (error) {
        return Result.Err(new AlertErrors.GetAlertsResponse(error))
      }

      return Result.Ok(AlertsMapper.toAlertsValueObject(data.alerts))
    } catch (e) {
      return Result.Err(new AlertErrors.GetAlertUnexpectedError(e))
    }
  }
}
