import * as IconPack from './assets'

const Icons = {
  available: IconPack.Available,
  closet: IconPack.Closet,
  galley: IconPack.Galley,
  lavatory1: IconPack.Lavatory1,
  lavatory2: IconPack.Lavatory2,
  paid: IconPack.Paid,
  premium: IconPack.Premium,
  unavailable: IconPack.Unavailable,
}

export type LegendIconName = keyof typeof Icons

type IconProps = {
  icon: LegendIconName
}

export function LegendIcon({ icon }: IconProps) {
  const IconFromPack = Icons[icon]

  return <IconFromPack aria-hidden />
}
