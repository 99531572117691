import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace LocationRefinementErrors {
  export const UnexpectedLocationRefinementGetOptionsError = createErrorClass(
    'UnexpectedLocationRefinementGetOptionsError',
  )

  export const LocationRefinementGetOptionsError = createErrorClass(
    'LocationRefinementGetOptionsError',
  )
}

export type LocationRefinementErrorsIntances = InstanceType<
  typeof LocationRefinementErrors[keyof typeof LocationRefinementErrors]
>
