import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { MediaLayout } from '@etta/ui/media-layout'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { Wrapper } from './add-new-flight-label-styled'

const i18Base = 'TripPlanner.BaseSearch'

type Props = {
  onClick: () => void
  isAbleToAddFlight: boolean
}

export function AddNewFlightLabel({ isAbleToAddFlight, onClick }: Props) {
  const { handleKeyDown } = useA11yKeydown(onClick)
  const { t } = useTranslation()

  return (
    <MediaLayout
      mobileSlot={
        <Wrapper>
          <Text
            role="button"
            tabIndex={0}
            variant="subHeadMedium"
            cursor={isAbleToAddFlight ? 'pointer' : 'auto'}
            color={isAbleToAddFlight ? 'primary1' : 'primary2'}
            aria-label={t(i18Base + '.Buttons.AddNewFlightLabel')}
            onKeyDown={handleKeyDown}
            onClick={onClick}>
            {t(i18Base + '.Buttons.AddNewFlight')}
          </Text>
        </Wrapper>
      }
      desktopSlot={
        <Wrapper
          role="button"
          tabIndex={0}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          aria-label={t(i18Base + '.Buttons.AddNewFlightDesktopLabel')}>
          <Text
            variant="subHeadStrong"
            cursor={isAbleToAddFlight ? 'pointer' : 'auto'}
            color={isAbleToAddFlight ? 'primary1' : 'primary2'}>
            {t(i18Base + '.Buttons.AddNewFlightDesktop')}
          </Text>
        </Wrapper>
      }
    />
  )
}
