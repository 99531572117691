import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { Segment } from '@fiji/graphql/types'

export const getFlightType = (flightSegmentsList: Segment[]): FlightType => {
  const legsCount = flightSegmentsList.length
  const firstLeg = flightSegmentsList[0]
  const lastLeg = flightSegmentsList[legsCount - 1]

  if (!firstLeg || !lastLeg) {
    return FlightType.Round
  }

  const firstLegFlight = flightSegmentsList[0].flightLeg
  const lastLegFlight = flightSegmentsList[legsCount - 1].flightLeg

  const lastLegSegmetsCount = lastLegFlight?.segments.length || 0
  const originName =
    firstLegFlight?.segments[0].departure.address?.city ||
    firstLegFlight?.segments[0].departure.airportCode
  const destinationName =
    lastLegFlight?.segments[lastLegSegmetsCount - 1].arrival.address?.city ||
    lastLegFlight?.segments[lastLegSegmetsCount - 1].arrival.airportCode

  if (legsCount === 1) {
    return FlightType.OneWay
  }

  if (legsCount === 2 && originName === destinationName) {
    return FlightType.Round
  }

  return FlightType.MultiCity
}
