import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Title } from './no-stations-styled'

type Props = {
  title: string
}

const i18nBase = 'SearchResults.Trains'

export function NoStations({ title }: Props) {
  const { t } = useTranslation()
  return (
    <>
      <Title>{title}</Title>
      <Block paddingHorizontal={18} paddingVertical={16}>
        <Text isBlock variant="body" color="error">
          {t(`${i18nBase}.NoMatchingStationsFound`)}
        </Text>
        <Text variant="body" color="bodyText">
          {t(`${i18nBase}.PleaseCheckStationCity`)}
        </Text>
      </Block>
    </>
  )
}
