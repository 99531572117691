import { DataProvider } from '@etta/di'
import type { GetTravelPolicyQuery, GetTravelPolicyQueryVariables } from '@fiji/graphql/hooks'
import { GetTravelPolicyDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class TravelPolicyDataProvider extends GraphqlDataProvider {
  getTravelPolicy() {
    return this.client.query<GetTravelPolicyQuery, GetTravelPolicyQueryVariables>({
      query: GetTravelPolicyDocument,
    })
  }
}
