import styled, { css } from 'styled-components'
import type { StyledTextVariant } from '@fiji/enums'
import { normalWeightText, tripPlannerInputBck, withStyledText } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'

export const LeadingElement = styled.div<{
  isTextValue?: boolean
  textVariant?: StyledTextVariant
}>`
  width: 50px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(p) => p.theme.secondaryColor};
  margin-right: 10px;
  pointer-events: none;
  ${withStyledText};

  ${(p) =>
    p.isTextValue &&
    css`
      width: 73px;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        top: 10px;
        right: 0;
        bottom: 10px;
        width: 1px;
        opacity: 0.1;
        background: #48526d;
      }
    `}
`

const inputStyles = css`
  flex: 1;
  appearance: none;
  font-size: 1.4rem;
  line-height: 20px;
  letter-spacing: normal;
  color: ${(p) => p.theme.inputColor};
  outline: none;
  font-family: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  background: transparent;
  border: none;
  border-radius: 7px;
  ${normalWeightText};
  ${withStyledText};
`

export const SearchField = styled.input`
  ${inputStyles};
  padding-right: 40px;

  background: ${tripPlannerInputBck};
  font-weight: 600;
  height: 40px;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;
    color: ${(p) => p.theme.inputColor};
  }

  padding-left: 10px;
`

export const SearchFieldContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 40px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${(p) => p.theme.inputBackgroundColor};

  ${(p) =>
    p.isOpen &&
    css`
      border-color: ${p.theme.inputColor};
    `}
  ${LeadingElement} {
    display: none;
  }
`

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`
