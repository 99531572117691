import type { CarRentalMapProps } from './types'
import { CarRentalMapLayout } from './car-rental-map-layout'

export function CarRentalMap({
  locations,
  center,
  onLocationClick,
  selectedDropOffLocation,
  selectedPickUpLocation,
}: CarRentalMapProps) {
  return (
    <CarRentalMapLayout
      locations={locations}
      center={center}
      onLocationClick={onLocationClick}
      selectedDropOffLocation={selectedDropOffLocation}
      selectedPickUpLocation={selectedPickUpLocation}
    />
  )
}
