import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle, headline } from '@fiji/style'
import type { Theme } from '@fiji/types'
import type { TextButtonColorVariants } from './types'

function getColorsByVariant(
  theme: Theme,
  colorVariant: TextButtonColorVariants,
): { color: string; hoverColor: string } {
  switch (colorVariant) {
    case 'secondary':
      return {
        color: theme.colors.mainText,
        hoverColor: theme.colors.mainText,
      }
    case 'primary':
    default:
      return {
        color: theme.btnBackground,
        hoverColor: theme.btnBackgroundHover,
      }
  }
}

export const StyledLinkButton = styled.button<{ colorVariant: TextButtonColorVariants }>`
  ${({ theme, colorVariant }) => {
    const styles = getColorsByVariant(theme, colorVariant)

    return css`
      color: ${styles.color};
      border: none;
      padding: 0;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: ${styles.hoverColor};
        text-decoration: none;
      }

      ${headline};
      ${KeyboardNavigationStyle}
    `
  }}
`
