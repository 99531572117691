import { ReservationStatus } from '@fiji/graphql/hooks'
import { SegmentType } from '@etta/core/enums'
import type { CarRentalPostBookingValueObject } from '../../../core/value-objects'
import type { CarRentalReservation } from './types'

export class CarRentalReservationMapper {
  static toCarRentalValueObject(
    carRentalReservation: CarRentalReservation,
  ): CarRentalPostBookingValueObject {
    const { confirmationNumber, reservedCarRental, status } = carRentalReservation
    const isMileageUnlimited = reservedCarRental.rate.distanceUnlimited

    const pickUpWorkingHours = {
      isAllDay: false,
      range: [
        `${reservedCarRental.pickupLocation.openingHours[0]?.times[0]?.openingTime} - ${reservedCarRental.pickupLocation.openingHours[0]?.times[0]?.closingTime}`,
      ],
    }
    const dropOffWorkingHours = {
      isAllDay: false,
      range: [
        `${reservedCarRental.dropOffLocation.openingHours[0]?.times[0]?.openingTime} - ${reservedCarRental.dropOffLocation.openingHours[0]?.times[0]?.closingTime}`,
      ],
    }
    const carRental: CarRentalPostBookingValueObject = {
      position: 0,
      type: SegmentType.CarRental,
      safetyCheck: reservedCarRental.safetyCheck,
      vendor: reservedCarRental.vendor,
      carCapacity: reservedCarRental.vehicle.passengerCapacity,
      isCarAirConditioned: reservedCarRental.vehicle.isAirCondition,
      carLuggage: reservedCarRental.vehicle.baggageCapacity,
      carClass: reservedCarRental.vehicle.vehicleClass,
      carType: reservedCarRental.vehicle.category,
      carTransmission: reservedCarRental.vehicle.transmissionType,
      carImageUrl: reservedCarRental.vehicle.imageUrl,
      carId: carRentalReservation.id,
      isUnlimitedMileage: isMileageUnlimited,
      fuelType: reservedCarRental.vehicle.fuelType,
      dropOffTime: reservedCarRental.dropOffTime,
      traveler: {
        email: 'Test',
        firstName: 'Test',
        lastName: 'Test',
      },
      confirmationNumber,
      pickUpTime: reservedCarRental.pickupTime,
      pickUpLocation: {
        isAirportLocation: reservedCarRental.pickupLocation.isAirport,
        address: reservedCarRental.pickupLocation.locationAddress,
        date: reservedCarRental.pickupDate,
        phone: {
          number: reservedCarRental.pickupLocation.phone,
          type: '',
        },
        workingHours: pickUpWorkingHours,
      },
      dropOffLocation: {
        isAirportLocation: reservedCarRental.dropOffLocation.isAirport,
        address: reservedCarRental.dropOffLocation.locationAddress,
        phone: {
          number: reservedCarRental.dropOffLocation.phone,
          type: '',
        },
        date: reservedCarRental.dropOffDate,
        workingHours: dropOffWorkingHours,
      },
      dailyRate: reservedCarRental.rate.dailyRate,
      daysCount: reservedCarRental.rate.daysCharged,
      isReserved: status === ReservationStatus.Confirmed,

      totalCost: reservedCarRental.rate.totalCost,
      totalBaseCost: reservedCarRental.rate.totalRate,
      taxesAndFees: reservedCarRental.rate.totalTaxes,
    }

    return carRental
  }
}
