import { Skeleton } from '@etta/ui/skeleton'

export function GeoSafeLocationSkeleton() {
  return (
    <Skeleton width="100%" height="82">
      <rect height="16" width="65%" />
      <rect height="10" width="60%" y="24" />
      <rect height="10" width="222" y="44" />
      <rect height="10" width="222" y="68" />
    </Skeleton>
  )
}
