import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Container, Wrapper, Initials, NameBlock } from './user-block-styled'

type Props = {
  travelerFullName?: string
  travelerInitials?: string
}

const i18nTraveler = 'Delegate.Traveler'

export function UserBlock({ travelerFullName, travelerInitials }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Initials>{travelerInitials}</Initials>
        <NameBlock>
          <Text variant="captionStrong" color="bodyText" textTransform="uppercase">
            {t(i18nTraveler)}
          </Text>
          <Text variant="headline" color="mainText">
            {travelerFullName}
          </Text>
        </NameBlock>
      </Wrapper>
    </Container>
  )
}
