import { Inject, Service } from '@etta/di'
import { ItineraryAdapter } from '../../infra'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import { GetTripService } from './get-trip.service'

@Service()
export class UpdateTripNameService {
  constructor(
    @Inject()
    private readonly getTripService: GetTripService,
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
  ) {}

  async updateTripName(tripName: string) {
    const result = await this.itineraryAdapter.updateTripName({
      name: tripName,
      itineraryId: this.reviewTripStore.tripId,
    })

    if (result.isOk()) {
      await this.getTripService.updateTrip()
    }

    return result
  }
}
