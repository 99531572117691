import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { useUberApp } from '@fiji/hooks/mobility'
import { ScreenType, useScreenType } from '@fiji/modes'
import { BrandLogo } from '../brand-logo'
import { BrandTitle } from '../brand-title'
import { UberTrackingRideViaQRCode } from '../uber-tracking-ride-via-qr-code/uber-tracking-ride-via-qr-code'
import {
  IntroText,
  FeatureContainer,
  StepContainer,
  StepItem,
  Item,
  ScreenContainer,
  UberQrCodeWrapper,
} from './uber-support-information-styled'

type Props = {
  onClose: () => void
}

const stepList = [
  'UberSupportActions.OpenApp',
  'UberSupportActions.TapProfileButton',
  'UberSupportActions.TapHelpButton',
  'UberSupportActions.SelectTrip',
]

export function UberSupportInformation({ onClose }: Props) {
  const { openUberAppWithoutParams } = useUberApp()
  const { t } = useTranslation()
  const i18nBase = 'Mobility.UberRideSupportPage.'
  const { scrollContainerRef } = useHeaderScroll()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile

  return (
    <ScreenContainer backgroundColor="background">
      <Screen.Container ref={scrollContainerRef}>
        <Screen.Header>
          <Header
            backgroundColor="background"
            animation="opacity"
            leftSlot={
              <Block paddingLeft={6}>
                <Header.CloseButton color="mainText" onClick={onClose} />
              </Block>
            }>
            <BrandTitle />
          </Header>
        </Screen.Header>
        <Block marginBottom={16}>
          <Block marginBottom={28} marginTop={14} marginLeft={20} marginRight={20}>
            <BrandLogo />
          </Block>
          <IntroText variant="title3">{t(i18nBase + 'Description')}</IntroText>
          <FeatureContainer>
            <Text variant="headline" color="mainText">
              {t(i18nBase + 'UberSupportTitle')}
            </Text>
            <Block marginTop={12}>
              <Text variant="footnoteMedium" color="mainText">
                {t(i18nBase + 'UberSupportDetail')}
              </Text>
            </Block>
            <Block marginTop={23}>
              <StepContainer>
                {stepList?.map((step, index) => (
                  <StepItem key={index}>
                    <Item variant="footnoteMedium">{t(i18nBase + step)}</Item>
                  </StepItem>
                ))}
              </StepContainer>
            </Block>
            {!isDesktop && (
              <Block marginTop={16}>
                <Text
                  variant="linkSmall"
                  color="primary1"
                  onClick={openUberAppWithoutParams}
                  aria-label={t(i18nBase + 'ActionOpenUberApp')}>
                  {t(i18nBase + 'ActionOpenUberApp')}
                </Text>
              </Block>
            )}
          </FeatureContainer>
          {isDesktop && (
            <UberQrCodeWrapper>
              <UberTrackingRideViaQRCode
                isUberWithParam={false}
                title={t(i18nBase + 'ScanToGetSupport')}
              />
            </UberQrCodeWrapper>
          )}
        </Block>
      </Screen.Container>
    </ScreenContainer>
  )
}
