import { Block } from '@etta/ui/block'
import { Footer } from '../footer'
import { Body } from '../price-summary-card-styled'
import { PayableSection } from '../payable-section'

type Props = {
  buttonLabel: string
  isDueTodayCostDisplayed: boolean
}

export function ErrorContainer({ buttonLabel, isDueTodayCostDisplayed }: Props) {
  const errorMessage = '--'

  return (
    <>
      <Body>
        <PayableSection
          isPayableNowExist
          isPayableLaterExist
          flightNowPrice={errorMessage}
          hotelLaterPrice={errorMessage}
          carLaterPrice={errorMessage}
        />
        <Block marginBottom={14} />
      </Body>
      <Footer
        priceString={errorMessage}
        dueTodayCost={errorMessage}
        buttonLabel={buttonLabel}
        isDueTodayCostDisplayed={isDueTodayCostDisplayed}
        disabledButton
      />
    </>
  )
}
