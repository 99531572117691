import styled from 'styled-components'

export const Container = styled.div`
  padding: 8px 30px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Price = styled.div`
  padding: 24px 0 40px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
