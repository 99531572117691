import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { FlightFareRulesByTransactionId } from '../../core/flight-fare-rules-modal/value-objects/get-flight-fare-rules.value-object'
import { FlightFareRulesErrors } from '../../core/flight-fare-rules-modal/errors'
import { FlightFareRulesDataProvider } from './data-provider/fare-rules-by-transaction-id.data-provider'
import type { FlightFareRulesAdapterResult, GetFlightFareRulesByTransactionIdArgs } from './types'

@Adapter()
export class FlightFareRulesAdapter {
  constructor(
    @Inject()
    private flightFareRulesDataProvider: FlightFareRulesDataProvider,
  ) {}

  async getFlightFareRulesByTransactionId({
    flightSegmentId,
    transactionId,
  }: GetFlightFareRulesByTransactionIdArgs): FlightFareRulesAdapterResult<
    FlightFareRulesByTransactionId[]
  > {
    try {
      const {
        data,
        error,
      } = await this.flightFareRulesDataProvider.handleLoadFlightFareRulesByTransactionId({
        flightSegmentId,
        transactionId,
      })
      if (error) {
        return Result.Err(new FlightFareRulesErrors.LoadFlightFareRulesFail(error))
      }

      return Result.Ok(data.flightFareRulesByTransactionId.rules)
    } catch (e) {
      return Result.Err(new FlightFareRulesErrors.LoadFlightFareRulesFail(e))
    }
  }
}
