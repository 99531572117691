import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import { TripStatus, SegmentType } from '@fiji/graphql/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useFlightPlacePostBooking } from '@fiji/hooks/use-add-button-settings/use-flight-place-postbooking'
import { useHotelPlacePostBooking } from '@fiji/hooks/use-add-button-settings/use-hotel-place-postbooking'
import { useCarPlacePostBooking } from '@fiji/hooks/use-add-button-settings/use-car-place-postbooking'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { MAXIMUM_SEGMENTS_COUNT } from '@fiji/constants'
import type { AddButtonSettings } from '../../trip-details-add-buttons-row/types'

type Params = {
  trip: TripEntity
  onRetry: () => void
  isLoading: boolean
}

export function useDetailsLayoutDesktop({ trip, onRetry, isLoading }: Params) {
  const screenType = useScreenType()
  const { handleRefetchRequest } = useRefetchRequest({
    refetch: onRetry,
    redirectRouteOnFail: '/trips',
    loading: isLoading,
    totalAttempts: 3,
  })

  const { onAddFlight } = useFlightPlacePostBooking({
    itineraryId: trip.bookingRef,
    bookingId: trip.processId,
    segments: trip.segments,
  })

  const { onAddHotel } = useHotelPlacePostBooking({
    itineraryId: trip.bookingRef,
    bookingId: trip.processId,
    segments: trip.segments,
  })

  const { onAddCar } = useCarPlacePostBooking({
    itineraryId: trip.bookingRef,
    bookingId: trip.processId,
    segments: trip.segments,
  })

  const isUpcoming = trip?.type !== TripStatus.Completed && trip?.type !== TripStatus.Cancelled
  const isCompleted = trip?.type === TripStatus.Completed
  const isCanceled = trip?.type === TripStatus.Cancelled || trip?.type === TripStatus.AutoCancelled
  const isOnHold = trip?.type === TripStatus.OnHold

  const isPastTripHeaderVisible = isCompleted && screenType === ScreenType.Desktop

  const addButtonSettings: AddButtonSettings[] = [
    {
      type: SegmentType.Flight,
      isDisabled: !trip.supportedActions?.isAddFlightAllowed || !!trip.isMultiCity,
      onClick: onAddFlight,
    },
    {
      type: SegmentType.Hotel,
      isDisabled: !trip.supportedActions?.isAddHotelAllowed,
      onClick: onAddHotel,
    },
    {
      type: SegmentType.CarRental,
      isDisabled: !trip.supportedActions?.isAddCarRentalAllowed,
      onClick: onAddCar,
    },
  ]

  const areTooManySegments = (trip.segments.length || 0) >= MAXIMUM_SEGMENTS_COUNT

  return {
    isUpcoming,
    isCanceled,
    isOnHold,
    isPastTripHeaderVisible,
    addButtonSettings,
    areTooManySegments,
    handleRefetchRequest,
  }
}
