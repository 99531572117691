/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Place } from '@fiji/types'
import type { AddressInput } from '@fiji/graphql/types'
import { rollbar } from '@fiji/rollbar'
import type { GOOGLE_LOCATION_TYPES } from './utils/get-place-by-lat-long'
import { getPlaceByLatLong } from './utils/get-place-by-lat-long'
import { CurrentLocationContainer, CurrentLocationText } from './current-location-search-styled'
import { DisabledGeoDialog } from './components/disabled-geo-dialog'
import { getAddressByLatLong } from './utils/get-address-by-lat-long'

export type CurrentLocationSearchPrecision = 'place' | 'address'

export type CurrentLocationSearchProps = {
  customIcon?: JSX.Element
  customTitle?: string
  setIsLoading?: (v: boolean) => void
  searchTypes?: GOOGLE_LOCATION_TYPES[]
  precision?: CurrentLocationSearchPrecision
  onClick: (value: Place, address?: AddressInput) => void
}

export function CurrentLocationSearch(props: CurrentLocationSearchProps) {
  const { customIcon, customTitle, onClick, setIsLoading, searchTypes, precision = 'place' } = props
  const [active, setActive] = useState(false)
  const [deniedVisible, setDeniedVisible] = useState(false)
  const { t } = useTranslation()
  const i18nPath = 'SearchResults.'

  const resultHandler = useCallback(
    (value?: Place, address?: AddressInput) => {
      setActive(false)
      if (setIsLoading) {
        setIsLoading(false)
      }
      if (value) {
        onClick(value, address)
      }
    },
    [setActive, setIsLoading, onClick],
  )

  const clickHandler = useCallback(() => {
    navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      if (['granted', 'prompt'].includes(result.state)) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude
            const long = position.coords.longitude
            setActive(true)
            if (setIsLoading) {
              setIsLoading(true)
            }
            if (precision === 'address') {
              getAddressByLatLong({ lat, long, resultHandler })
              return
            }
            getPlaceByLatLong({ lat, long, resultHandler, searchTypes })
          },
          (err) => {
            if (err.code === 1) {
              // PERMISSION_DENIED
              setDeniedVisible(true)
            } else {
              rollbar.error(
                new Error(`Get current position error: ${err?.message || 'Unknown error'}`),
                {
                  err,
                },
              )
            }
          },
        )
      } else if (result.state === 'denied') {
        setDeniedVisible(true)
      }
    })
  }, [
    navigator,
    searchTypes,
    resultHandler,
    setActive,
    setDeniedVisible,
    setIsLoading,
    getPlaceByLatLong,
  ])

  return (
    <>
      <CurrentLocationContainer onClick={clickHandler}>
        {customIcon || <Icon name="searchPinPWA" size="medium" />}
        <span>
          <CurrentLocationText active={active}>
            {customTitle || t(i18nPath + 'CurrentLocation.CurrentLocation')}
          </CurrentLocationText>
        </span>
      </CurrentLocationContainer>
      <DisabledGeoDialog
        isOpen={deniedVisible}
        setIsOpen={(value) => {
          setDeniedVisible(value)
        }}
      />
    </>
  )
}
