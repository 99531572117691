import { useCallback } from 'react'
import { TripStatus } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { HotelAddressCard } from '@etta/components/hotel-address-card/hotel-address-card'
import { HotelDetailsPhotos } from '@etta/components/hotel-details-photos/hotel-details-photos'
import { RoomRatesModal } from '@etta/components/room-rates-modal/room-rates-modal'
import { HotelDetailsEcoCheck } from '@etta/components/hotel-details-eco-check/hotel-details-eco-check'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { MobilitySearchFlowModal } from '@etta/screens/trip-details/details/mobility-search-flow-modal/mobility-search-flow-modal'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useRoomDetails } from '../../../hooks'
import { RoomDetails } from '../../../room-details'
import { Price } from '../../../price'
import { Traveler } from '../../../traveler'
import type { TripHotelLayoutProps } from '../../types'
import { useTripHotelContentDesktop } from './use-trip-hotel-content-desktop'
import { ModalHeaderContent } from './modal-header-content'
import {
  ContentWrapper,
  HotelPhotosContainer,
  ModalHeader,
  TravelerWrapper,
} from './trip-hotel-content-desktop-styled'

export function TripHotelContentDesktop({
  onClose,
  tripType,
  hotel,
  cancellationDate,
  processId = '',
  hotelEmission,
  travelerName,
  transactionRegistryServiceItemId,
}: TripHotelLayoutProps) {
  const { featureFlags } = useFeatureFlags()
  const {
    photos,
    name: hotelName,
    confirmationNumber,
    recordLocator,
    checkIn,
    checkOut,
    address,
    phone,
    cancellationPolicyDescription,
    cancellationPolicy,
    status,
  } = hotel
  const {
    scrollContainerRef,
    headerRef,
    anchorRef,
    isCancelled,
    isCompleted,
    isUpcoming,
    headerColor,
    dateText,
    closeIconAriaLabel,
  } = useTripHotelContentDesktop({
    tripType: tripType || TripStatus.Unknown,
    hotelStatus: status,
    checkIn: checkIn || '',
    checkOut: checkOut || '',
  })
  const { roomDetails, isOpen, handleOpen, handleClose } = useRoomDetails(hotel)
  const { searchRideHailStore, searchRideHailActions } = useRideHailSearchContext()

  const handleStartMobilitySearchFlow = useCallback(() => {
    onClose()
    searchRideHailActions.openMobilitySearchDesktopModal()
  }, [onClose, searchRideHailActions])

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent" style={{ top: 0 }}>
          <ModalHeader ref={headerRef} color={headerColor}>
            <ModalHeaderContent name={hotelName || ''} dateText={dateText} />
            <IconButton
              aria-label={closeIconAriaLabel}
              icon="CloseFilledDarkPWA"
              size="medium"
              onClick={onClose}
            />
          </ModalHeader>
        </Modal.Header>
        <HotelPhotosContainer hasPhotos={!!photos?.length} color={headerColor}>
          <HotelDetailsPhotos hotelName={hotelName || ''} photos={photos || []} />
        </HotelPhotosContainer>
        <div ref={anchorRef} />
        <ContentWrapper>
          <TravelerWrapper>
            <Traveler
              isCancelled={isCancelled}
              isCompleted={isCompleted}
              cancellationDate={cancellationDate}
              confirmationNumber={confirmationNumber}
              recordLocator={recordLocator}
              hotelName={hotelName || ''}
              travelerName={travelerName}
            />
          </TravelerWrapper>
          {featureFlags.isMobilityDesktopEnabled && (
            <Block marginTop={16}>
              <MobilityCallToAction
                processId={processId}
                dropOffSegmentId={transactionRegistryServiceItemId}
                tripType={tripType}
                segmentType={hotel.type}
                onStartMobilitySearchFlow={handleStartMobilitySearchFlow}
              />
            </Block>
          )}
          {featureFlags.isMobilityDesktopEnabled && (
            <MobilitySearchFlowModal
              isOpen={searchRideHailStore.isOpenMobilitySearchDesktopModal}
              onClose={searchRideHailActions.closeMobilitySearchDesktopModal}
            />
          )}
          <Block marginTop={16}>
            <RoomDetails
              checkIn={checkIn}
              checkOut={checkOut}
              handleOpenDetails={handleOpen}
              roomDetails={roomDetails}
              isUpcoming={isUpcoming}
            />
          </Block>
          <Block marginTop={16}>
            {address && (
              <HotelAddressCard
                hotelName={hotelName}
                phone={phone}
                address={address}
                isTitleVisible={false}
              />
            )}
          </Block>
          {featureFlags.isSustainabilityEnabled && (
            <Block marginTop={16}>
              <HotelDetailsEcoCheck
                {...hotelEmission}
                averageLocationSustainabilityScore={
                  hotelEmission.averageLocationSustainabilityScore
                }
              />
            </Block>
          )}
          <Block marginTop={14} marginBottom={54}>
            {!isCancelled && (
              <Price
                cancellationPolicy={cancellationPolicy}
                cancellationPolicyDescription={cancellationPolicyDescription}
                roomDetails={roomDetails}
                isCancelled={isCancelled}
                handleOpenDetails={handleOpen}
              />
            )}
          </Block>
        </ContentWrapper>
      </Modal.Body>
      <RoomRatesModal
        isOpen={isOpen}
        onClose={handleClose}
        processId={processId}
        roomRates={roomDetails}
        layout="wide-left-slide"
      />
    </>
  )
}
