/* eslint-disable react-hooks/exhaustive-deps */
import i18n from 'i18next'
import type { ContinuityMessage } from '@fiji/graphql/types'

type Props = {
  message: ContinuityMessage
  itineraryId: string
  firstHotelId?: string
  segmentKeys?: { carRental: string[]; hotel: string[] }
}

export function continuityWarningAlert({ message, segmentKeys }: Props) {
  const { data, code, followUpMessages, segmentId } = message
  const i18nBasePath = 'ContinuityMessages.'
  const carChangeCodes = ['kI18nContinuityFlightCarLocationWarningFollowUpChangeSearch']
  const hotelChangeCodes = ['kI18nContinuityFlightHotelLodgingWarningFollowUpChangeSearch']

  const carRentalId = segmentKeys?.carRental
    .map((id) => {
      if (segmentId?.includes(id)) {
        return id
      }
    })
    .filter(Boolean) as string[] | undefined

  const filteredFollowUp =
    followUpMessages && followUpMessages.filter(Boolean).length > 0
      ? (followUpMessages as string[])
      : null
  const translated = data
    ? i18n.t(`${i18nBasePath}${code}`, { ...data })
    : i18n.t(`${i18nBasePath}${code}`)

  if (translated === `${i18nBasePath}${code}`) {
    return null
  }

  return (
    <div>
      {translated}

      {filteredFollowUp?.map((message: string) => {
        if (i18n.t(`${i18nBasePath}${message}`) === `${i18nBasePath}${message}`) {
          return
        }
        if (carChangeCodes.includes(message as string) && carRentalId) {
          return <span key={message}>{i18n.t(i18nBasePath + 'ModifySearchCarRental')}</span>
        }
        if (hotelChangeCodes.includes(message as string)) {
        }
      })}
    </div>
  )
}
