import { EmissionsDeviationLevel } from '@fiji/enums'

type Props = {
  tonnesOfEmissions: number
  averageEmissionsTonnes: number
}

const AVERAGE_DEVIATION_VALUE = 4

export function getCalculateEmissionsDeviation({
  tonnesOfEmissions,
  averageEmissionsTonnes,
}: Props) {
  const emissionsDeviation = (tonnesOfEmissions * 100) / averageEmissionsTonnes - 100
  const emissionsDeviationAbs = Math.abs(emissionsDeviation)

  if (emissionsDeviationAbs <= AVERAGE_DEVIATION_VALUE) {
    return {
      deviationLevel: EmissionsDeviationLevel.Average,
      percentDeviation: emissionsDeviationAbs,
    }
  }

  if (emissionsDeviation > AVERAGE_DEVIATION_VALUE) {
    return {
      deviationLevel: EmissionsDeviationLevel.More,
      percentDeviation: Math.ceil(emissionsDeviation),
    }
  }

  return {
    deviationLevel: EmissionsDeviationLevel.Less,
    percentDeviation: Math.ceil(emissionsDeviationAbs),
  }
}
