import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  SubmitNewCarRentalSearchMutationVariables,
  SubmitNewFlightSearchMutationVariables,
  SubmitNewHotelSearchMutationVariables,
  SubmitNewRailSearchMutationVariables,
  GetRecentCarRentalSearchesQueryVariables,
  GetRecentFlightSearchesQueryVariables,
  GetRecentHotelSearchesQueryVariables,
  GetRecentRailSearchesQueryVariables,
  SubmitNewCarRentalSearchMutation,
  SubmitNewFlightSearchMutation,
  SubmitNewHotelSearchMutation,
  SubmitNewRailSearchMutation,
  GetRecentCarRentalSearchesQuery,
  GetRecentFlightSearchesQuery,
  GetRecentHotelSearchesQuery,
  GetRecentRailSearchesQuery,
  RecentCarRentalSearchesWithoutDatesQuery,
  RecentCarRentalSearchesWithoutDatesQueryVariables,
  RecentFlightSearchesWithoutDatesQuery,
  RecentFlightSearchesWithoutDatesQueryVariables,
} from '@fiji/graphql/hooks'
import { RecentHotelSearchesWithoutDatesDocument } from '@fiji/graphql/hooks'
import {
  RecentCarRentalSearchesWithoutDatesDocument,
  RecentFlightSearchesWithoutDatesDocument,
} from '@fiji/graphql/hooks'
import {
  SubmitNewCarRentalSearchDocument,
  SubmitNewFlightSearchDocument,
  SubmitNewHotelSearchDocument,
  SubmitNewRailSearchDocument,
  GetRecentCarRentalSearchesDocument,
  GetRecentFlightSearchesDocument,
  GetRecentHotelSearchesDocument,
  GetRecentRailSearchesDocument,
} from '@fiji/graphql/hooks'
import type {
  RecentHotelSearchesWithoutDatesQuery,
  RecentHotelSearchesWithoutDatesQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class RecentSearchDataProvider extends GraphqlDataProvider {
  submitNewCarRentalSearch(input: SubmitNewCarRentalSearchMutationVariables['input']) {
    return this.client.mutate<
      SubmitNewCarRentalSearchMutation,
      SubmitNewCarRentalSearchMutationVariables
    >({
      mutation: SubmitNewCarRentalSearchDocument,
      variables: {
        input,
      },
    })
  }
  submitNewFlightSearch(input: SubmitNewFlightSearchMutationVariables['input']) {
    return this.client.mutate<
      SubmitNewFlightSearchMutation,
      SubmitNewFlightSearchMutationVariables
    >({
      mutation: SubmitNewFlightSearchDocument,
      variables: {
        input,
      },
    })
  }
  submitNewHotelSearch(input: SubmitNewHotelSearchMutationVariables['input']) {
    return this.client.mutate<SubmitNewHotelSearchMutation, SubmitNewHotelSearchMutationVariables>({
      mutation: SubmitNewHotelSearchDocument,
      variables: {
        input,
      },
    })
  }
  submitNewRailSearch(input: SubmitNewRailSearchMutationVariables['input']) {
    return this.client.mutate<SubmitNewRailSearchMutation, SubmitNewRailSearchMutationVariables>({
      mutation: SubmitNewRailSearchDocument,
      variables: {
        input,
      },
    })
  }

  getRecentCarRentalSearches(input: GetRecentCarRentalSearchesQueryVariables['input']) {
    return this.client.query<
      GetRecentCarRentalSearchesQuery,
      GetRecentCarRentalSearchesQueryVariables
    >({
      query: GetRecentCarRentalSearchesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
  getRecentFlightSearches(input: GetRecentFlightSearchesQueryVariables['input']) {
    return this.client.query<GetRecentFlightSearchesQuery, GetRecentFlightSearchesQueryVariables>({
      query: GetRecentFlightSearchesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
  getRecentHotelSearches(input: GetRecentHotelSearchesQueryVariables['input']) {
    return this.client.query<GetRecentHotelSearchesQuery, GetRecentHotelSearchesQueryVariables>({
      query: GetRecentHotelSearchesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
  getRecentRailSearches(input: GetRecentRailSearchesQueryVariables['input']) {
    return this.client.query<GetRecentRailSearchesQuery, GetRecentRailSearchesQueryVariables>({
      query: GetRecentRailSearchesDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }

  getRecentCarRentalSearchesWithoutDates() {
    return this.client.query<
      RecentCarRentalSearchesWithoutDatesQuery,
      RecentCarRentalSearchesWithoutDatesQueryVariables
    >({
      query: RecentCarRentalSearchesWithoutDatesDocument,
      fetchPolicy: 'no-cache',
    })
  }
  getRecentFlightSearchesWithoutDates() {
    return this.client.query<
      RecentFlightSearchesWithoutDatesQuery,
      RecentFlightSearchesWithoutDatesQueryVariables
    >({
      query: RecentFlightSearchesWithoutDatesDocument,
      fetchPolicy: 'no-cache',
    })
  }
  getRecentHotelSearchesWithoutDates() {
    return this.client.query<
      RecentHotelSearchesWithoutDatesQuery,
      RecentHotelSearchesWithoutDatesQueryVariables
    >({
      query: RecentHotelSearchesWithoutDatesDocument,
      fetchPolicy: 'no-cache',
    })
  }
}
