import { Block } from '@etta/ui/block'
import { Spinner } from '@etta/ui/spinner'
import { Text } from '@etta/ui/text'
import type { ViewState } from '../types'
import { getTitle } from './get-title'

type Props = {
  title?: string
  viewState: ViewState
}

export function StateLoading({ title, viewState }: Props) {
  const usedTitle = getTitle({ title, viewState })
  return (
    <>
      <Spinner type="spinout" />
      <Text variant="subHeadStrong" color="mainText">
        {usedTitle}
      </Text>
      {usedTitle && <Block marginBottom={18} />}
    </>
  )
}
