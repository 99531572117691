import styled, { keyframes } from 'styled-components'

const enteringAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(.8, .8);
  };
  top {
    opacity: 1;
    transform: scale(1, 1);
  };
`

const exitingAnimation = keyframes`
  from {
    opacity: 1;
    transform: scale(1, 1);
    transform: scale(1, 1);
  };
  to {
    opacity: 0;
    transform: scale(.8, .8);
    transform: scale(.8, .8);
  };
`

export const AnimatedWrapper = styled.div<{ transitionDuration: number }>`
  z-index: 100;
  top: 0;
  position: absolute;
  width: 100%;
  height: max-content;
  &.entering {
    transform: scaleX(1);
    animation: ${enteringAnimation};
    animation-duration: ${({ transitionDuration }) => transitionDuration}ms;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
  }
  &.entered {
    opacity: 1;
  }
  &.exiting {
    animation: ${exitingAnimation};
    animation-duration: ${({ transitionDuration }) => transitionDuration + 50}ms;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
  }
  &.exited {
    opacity: 0;
  }
`
