import { Action, Inject } from '@etta/di'
import { SuggestionService } from '../services/suggestion.service'

@Action()
export class SuggestionActions {
  constructor(
    @Inject()
    private readonly suggestionService: SuggestionService,
  ) {}

  async getSuggestions() {
    await this.suggestionService.getSuggestions()
  }
}
