import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetLocationRefinementOptionsQuery,
  GetLocationRefinementOptionsQueryVariables,
  GetSearchRideHailWithErrorQuery,
  GetSearchRideHailWithErrorQueryVariables,
  RideHailExpenseConfigQuery,
  RideHailExpenseConfigQueryVariables,
  RideHailUpcomingFlightsQuery,
  RideHailUpcomingFlightsQueryVariables,
} from '@fiji/graphql/hooks'
import {
  GetLocationRefinementOptionsDocument,
  GetSearchRideHailWithErrorDocument,
  RideHailExpenseConfigDocument,
  RideHailUpcomingFlightsDocument,
} from '@fiji/graphql/hooks'

@DataProvider()
export class RideHailSearchDataProvider extends GraphqlDataProvider {
  locationRefinementAbortController: AbortController | null = null
  searchRideHailAbortController: AbortController | null = null
  expenseConfigAbortController: AbortController | null = null
  upcomingAbortController: AbortController | null = null

  cancelLocationRefinementOptionsRequest() {
    this.locationRefinementAbortController?.abort()
  }

  cancelRequests() {
    this.locationRefinementAbortController?.abort()
    this.searchRideHailAbortController?.abort()
    this.expenseConfigAbortController?.abort()
    this.upcomingAbortController?.abort()
  }

  locationRefinementOptions(input: GetLocationRefinementOptionsQueryVariables['input']) {
    const controller = new AbortController()
    this.locationRefinementAbortController?.abort()
    this.locationRefinementAbortController = controller
    return this.client.query<
      GetLocationRefinementOptionsQuery,
      GetLocationRefinementOptionsQueryVariables
    >({
      query: GetLocationRefinementOptionsDocument,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  getSearchRideHail(input: GetSearchRideHailWithErrorQueryVariables['input']) {
    const controller = new AbortController()
    this.searchRideHailAbortController?.abort()
    this.searchRideHailAbortController = controller
    return this.client.query<
      GetSearchRideHailWithErrorQuery,
      GetSearchRideHailWithErrorQueryVariables
    >({
      query: GetSearchRideHailWithErrorDocument,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  rideHailExpenseConfig(input: RideHailExpenseConfigQueryVariables['input']) {
    const controller = new AbortController()
    this.expenseConfigAbortController?.abort()
    this.expenseConfigAbortController = controller

    return this.client.query<RideHailExpenseConfigQuery, RideHailExpenseConfigQueryVariables>({
      query: RideHailExpenseConfigDocument,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  getRideHailUpComingFlights() {
    const controller = new AbortController()
    this.upcomingAbortController?.abort()
    this.upcomingAbortController = controller

    return this.client.query<RideHailUpcomingFlightsQuery, RideHailUpcomingFlightsQueryVariables>({
      query: RideHailUpcomingFlightsDocument,
      fetchPolicy: 'network-only',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }
}
