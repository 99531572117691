import type { CarLegType, FlightLegType, MobilityLegType } from '@fiji/enums'
import { SegmentType } from '@fiji/graphql/types'
import { useFlightDateTime } from './use-flight-date-time'
import { useCarRentalDateTime } from './use-car-rental-date-time'
import { useMobilityDateTime } from './use-mobility-date-time'
import type { UseDateTime } from './types'

type Args = {
  date?: Date
  type: CarLegType | FlightLegType | MobilityLegType
  segmentType: SegmentType
}

const hooksBySegmentType: { [key: string]: UseDateTime } = {
  [SegmentType.Flight]: useFlightDateTime,
  [SegmentType.CarRental]: useCarRentalDateTime,
  [SegmentType.Mobility]: useMobilityDateTime,
}

export function usePlaceDates({ date, type, segmentType }: Args) {
  const {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  } = hooksBySegmentType[segmentType]({ date, type })

  return {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  }
}
