import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 14px;
  padding: 16px;
  background: ${(props) => props.theme.colors.white};
`

export const Image = styled.img`
  height: 22px;
`
