import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'
import type { PersonalInfo } from '@fiji/types'
import type { TravelerOptions } from '@fiji/graphql/types'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import { UserInfoForm } from '../../user-info-form'
import type { AirPreferenceExcerpt, ProfileOptions } from '../../types'
import { HeaderContainer } from './purchase-user-info-desktop-styled'

export type Props = {
  onClose: () => void
  onSubmit: (data: PersonalInfo) => void
  personalInfo: PersonalInfo
  primaryTraveler?: PurchaseInfoTravelerValueObject
  isOpen: boolean
  isAir?: boolean
  travelerOptions?: TravelerOptions | null
  isPostPurchase?: boolean
  isKtnHidden?: boolean
  isRedressNumber?: boolean
  preferenceExcerpt?: AirPreferenceExcerpt
  profileOptions: ProfileOptions
  isRail?: boolean
}

const i18nBase = 'UserInfo.'

export function PurchaseUserInfoDesktop({ isOpen, onClose, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="right">
      <Modal.Body>
        <Modal.Header isMobile>
          <HeaderContainer
            minHeight={72}
            rightSlot={
              <IconButton size="medium" icon="closeFilledPWA" color="bodyText" onClick={onClose} />
            }
            withBorderBottom>
            <Header.Title title={t(i18nBase + 'EditTravelerInfo')} align="left" />
          </HeaderContainer>
        </Modal.Header>
        <UserInfoForm {...props} onClose={onClose} />
      </Modal.Body>
    </Modal>
  )
}
