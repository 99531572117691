import { Service, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
import { buildMetadataLogs } from '@fiji/utils/build-metadata-logs'
import { CarRentalAdapter } from '@etta/modules/car-rental-search/infra/adapters'
import { CarRentalCarDetailsStore } from '../stores/car-rental-car-details.store'
import { CarRentalSearchStore } from '../stores/car-rental-search.store'
import { CarRentalStore } from '../stores/car-rental.store'
import type { GetCarDetailsArgs, UpdateCarRentalsWithNewCarDetailsArgs } from '../types'

@Service()
export class CarRentalDetailsService {
  constructor(
    @Inject()
    private readonly carRentalCarDetailsStore: CarRentalCarDetailsStore,

    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,

    @Inject()
    private readonly carRentalStore: CarRentalStore,

    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,

    @Inject()
    private readonly carRentalAdapter: CarRentalAdapter,
  ) {}

  async loadCarDetails(args: GetCarDetailsArgs) {
    const { isMod2FlowEnabled } = this.displayConfigurationStore

    if (isMod2FlowEnabled) {
      this.getMod2FlowCarDetails(args)
      return
    }

    await this.getCarDetails(args)
  }

  private async getCarDetails({ carId, forceUpdate }: GetCarDetailsArgs) {
    this.carRentalCarDetailsStore.setIsLoadingCarDetails(true)
    this.carRentalCarDetailsStore.setCarDetails(null)
    this.carRentalCarDetailsStore.setIsErrorCarDetails(false)

    const carDetailsFromStore = this.carRentalSearchStore.cars.find((car) => car.key === carId)

    if (!carDetailsFromStore) {
      this.carRentalCarDetailsStore.setIsErrorCarDetails(true)
      return
    }

    this.carRentalCarDetailsStore.setCarDetails(carDetailsFromStore)

    const { searchId } = this.carRentalSearchStore
    const { isPhoenixCarRentalSearchEnabled } = this.displayConfigurationStore

    const result = await this.carRentalAdapter.getCarDetails(
      {
        logsMetadata: buildMetadataLogs({
          itineraryId: this.carRentalStore.queryParams.itineraryId,
          isPhoenixCarRentalSearchEnabled,
        }),
        id: carId,
        searchId,
      },
      forceUpdate,
    )

    result.match({
      Ok: (carDetails) => {
        const {
          baseRate,
          carCostRates,
          rate,
          carCosts,
          taxes,
          totalCost,
          netRate,
          serviceFeeRate,
        } = carDetails
        const carDetailsWithUpdatedRates = {
          ...carDetailsFromStore,
          baseRate,
          carCostRates,
          rate,
          carCosts,
          taxes,
          totalCost,
          netRate,
          serviceFeeRate,
        }
        this.carRentalCarDetailsStore.setCarDetails(carDetailsWithUpdatedRates)
        this.updateCarRentalsWithNewCarDetails({ carDetails: carDetailsWithUpdatedRates, carId })
        this.carRentalCarDetailsStore.setIsLoadingCarDetails(false)
      },
      Err: () => {
        this.carRentalCarDetailsStore.setIsErrorCarDetails(true)
        this.carRentalCarDetailsStore.setIsLoadingCarDetails(false)
      },
    })
  }

  private updateCarRentalsWithNewCarDetails({
    carDetails,
    carId,
  }: UpdateCarRentalsWithNewCarDetailsArgs) {
    const carRentals = this.carRentalSearchStore.carRentals
    const carDetailsWithUpdatedRate = carRentals?.car.map((car) => {
      if (car.key === carId) {
        return carDetails
      }

      return car
    })

    const carRentalsWithUpdatedCarDetails = carRentals
      ? {
          ...carRentals,
          car: carDetailsWithUpdatedRate || [],
        }
      : null

    this.carRentalSearchStore.setCarRentals(carRentalsWithUpdatedCarDetails)
  }

  private getMod2FlowCarDetails({ carId }: GetCarDetailsArgs) {
    this.carRentalCarDetailsStore.setCarDetails(null)
    this.carRentalCarDetailsStore.setIsErrorCarDetails(false)

    const carDetails = this.carRentalSearchStore.cars.find((car) => car.key === carId)

    if (!carDetails) {
      this.carRentalCarDetailsStore.setIsErrorCarDetails(true)
      return
    }

    this.carRentalCarDetailsStore.setCarDetails(carDetails)
  }
}
