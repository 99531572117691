import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import type { ButtonProps } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { rowCSS } from '../../flight-details-styled'

export const Cell = styled.div`
  display: flex;
  width: 50%;
`

export const FullWidthCell = styled.div`
  display: flex;
  width: 100%;
`

export const CellIcon = styled(Icon)`
  margin-left: 10px;
  margin-right: 15px;
`

export const CellName = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
  isBlock: true,
}))``

export const CellValue = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'subHeadStrong',
  color: 'mainText',
}))`
  display: flex;
  align-items: center;
`

export const ChangeSeatButton = styled(Button).attrs<ButtonProps>(() => ({
  variant: 'link',
}))`
  padding: 0;
  margin-left: 8px;
`

export const SectionColumn = styled.div`
  ${rowCSS};
  padding: 16px;
  flex-direction: column;
`
