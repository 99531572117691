import type { DefaultTheme } from 'styled-components'
import type { LayoutBackgroundColor } from './types'

export function getLayoutBackgroundColor(
  theme: DefaultTheme,
  bgColor?: LayoutBackgroundColor,
): string {
  if (!bgColor || bgColor === 'seatMap') {
    return theme.colors.white
  }
  if (bgColor === 'transparent') {
    return 'transparent'
  }

  return theme.colors[bgColor] || theme.colors.white
}
