import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { CityImageUrlStore } from '@fiji/utils/city-image-url-store'
import { formatRate } from '@fiji/utils/money'
import { CityBackground } from '@etta/components/city-background/city-background'
import { TravelerExcerpt } from '@etta/components/traveler-excerpt/traveler'
import type { AirTicket, TripDetailsCostSummary, TripStatus } from '@fiji/graphql/types'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { AppleBanner } from '@etta/components/apple-banner'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { Icon } from '@etta/ui/icon'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { TripCostsSidebarSkeleton } from './trip-costs-sidebar-skeleton'
import {
  TotalRow,
  Container,
  Content,
  MainCostText,
  IconBreakdown,
  ReferencesList,
  Wrapper,
} from './trip-costs-sidebar-styled'
import { useTripCostsSidebar } from './use-trip-costs-sidebar'
import { CancelButton } from './cancel-button'
import { CostSection } from './cost-section'
import { Reference } from './reference'
import { DownloadETicketButton } from './download-e-ticket-button'
import { RulesAndRestrictionsButton } from './rules-and-restrictions-button'

type Props = {
  isLoading: boolean
  bookingRef: string
  type: TripStatus
  costs?: TripDetailsCostSummary | null
  segments: SegmentPostBookingValueObject[]
  cityImageUrl?: string | null
  onOpenBreakdown: () => void
  onCancelModalOpen?: () => void
  onContinueBooking: () => void
  onETicketsDownloading: () => void
  isUUTApplied?: boolean
  onOpenRulesAndRestrictions: () => void
  showRulesAndRestrictions: boolean
  onOpenETicketReceipt: (index: number) => void
  airTickets?: AirTicket[]
  recordLocator?: string | null
  creationTime?: string | null
}

const i18Base = 'PostBooking.TripDetails.'

export const TripCostsSidebar = observer(function TripCostsSidebar({
  bookingRef,
  type,
  costs,
  segments,
  isLoading,
  cityImageUrl,
  onOpenBreakdown,
  onCancelModalOpen,
  onContinueBooking,
  onETicketsDownloading,
  isUUTApplied,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
  onOpenETicketReceipt,
  airTickets,
  recordLocator,
  creationTime,
}: Props) {
  const { t } = useTranslation()

  const {
    paidNowSegments,
    payLaterSegments,
    isCancelAvailable,
    isContentAvailable,
    isPayedLaterLabeled,
    isTripOnHold,
    isAppleSite,
    isDesktopHeldTripsFlowEnabled,
    isAppleBannerShown,
  } = useTripCostsSidebar({
    costs,
    type,
    segments,
  })
  const { mainCost } = formatRate(costs?.totalCost, { morpheme: 'none' })
  const cachedCityImageUrl = CityImageUrlStore.get()
  const {
    hasTrainSegmentsWithETicket,
    trainConfirmationNumber: railConfirmationNumber,
    appliedRailCard,
  } = usePostBookingContext().postBookingTripStore

  if (isLoading) {
    return (
      <Wrapper>
        <TripCostsSidebarSkeleton cityImageUrl={cachedCityImageUrl} />{' '}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Container role="region" aria-label={t(i18Base + 'TotalCost')}>
        <CityBackground cityImageUrl={cityImageUrl || cachedCityImageUrl} />
        <Block marginHorizontal={24}>
          <TravelerExcerpt />
        </Block>
        <ReferencesList>
          <Reference>
            {t(i18Base + 'ReferenceNo')}&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {bookingRef}
            </Text>
          </Reference>
          <Reference>
            {t(i18Base + (isAppleSite ? 'AppleTravelReference' : 'RecordLocator'))}
            <Text variant="footnoteMedium" color="mainText">
              {recordLocator}
            </Text>
          </Reference>
          {railConfirmationNumber && (
            <Reference>
              {t(`${i18Base}RailConfirmationNumber`, { railConfirmationNumber })}
            </Reference>
          )}
          {appliedRailCard && (
            <Reference>{t(`${i18Base}AppliedRailCard`, { appliedRailCard })}</Reference>
          )}
          <Reference>
            {t(i18Base + 'BookedOn')}:&nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {creationTime
                ? dateFormat(new Date(deleteTimezone(creationTime)), 'ccc, LLL d, yyyy')
                : '--'}
            </Text>
          </Reference>
          {airTickets?.map((airTicket, index) =>
            airTicket.ticketSegmentInfo && airTicket.ticketSegmentInfo?.length > 0 ? (
              <Reference key={airTicket.ticketNumber}>
                {t(i18Base + 'ETicketReceipt')}:&nbsp;
                <Button
                  variant={'text'}
                  size={'small'}
                  paddings={false}
                  aria-label={t(i18Base + 'ETicketReceiptVoice')}
                  onClick={() => onOpenETicketReceipt(index)}>
                  {airTicket.ticketNumber}
                </Button>
              </Reference>
            ) : null,
          )}
        </ReferencesList>
        {isContentAvailable && (
          <Content>
            <CostSection
              label={isTripOnHold ? t(i18Base + 'PayableNow') : t(i18Base + 'Paid')}
              segments={paidNowSegments}
              isChecked={!isTripOnHold}
            />
            <CostSection
              label={t(i18Base + (isPayedLaterLabeled ? 'PayableLater' : 'Paid'))}
              segments={payLaterSegments}
              isChecked={!isPayedLaterLabeled}
            />
          </Content>
        )}
        {isContentAvailable && (
          <TotalRow
            marginVertical={11}
            marginHorizontal={24}
            aria-label={t(i18Base + 'TotalCost') + ' ' + mainCost}>
            <Block>
              <Text variant="headline">{t(i18Base + 'TotalCost')}</Text>
              <IconBreakdown
                color="mainText"
                icon="infoOutlinePWA"
                onClick={onOpenBreakdown}
                data-tracking-id="trip-total-cost-info-icon"
              />
            </Block>
            <MainCostText variant="headline">{mainCost}</MainCostText>
          </TotalRow>
        )}
        {isContentAvailable && isUUTApplied && (
          <Block isFlexBlock flexGap={5} marginLeft={25}>
            <Icon name="unusedTicketPWA" color="success" size="small" />
            <Text variant="footnoteMedium" color="ecoCheck">
              {t(`PaymentSummary.UnusedTicketsApplied`)}
            </Text>
          </Block>
        )}
        {isDesktopHeldTripsFlowEnabled && isTripOnHold && (
          <Block paddingHorizontal={16} paddingVertical={16}>
            <Button
              fullWidth
              variant="solid"
              onClick={onContinueBooking}
              aria-label={t('ActionButtons.Label', { button: t(i18Base + 'ContinueBooking') })}>
              {t(i18Base + 'ContinueBooking')}
            </Button>
          </Block>
        )}
        {hasTrainSegmentsWithETicket && (
          <DownloadETicketButton
            label={t(i18Base + 'DownloadETickets')}
            ariaLabel={t('ActionButtons.Label', { button: t(i18Base + 'DownloadETickets') })}
            onOpen={onETicketsDownloading}
          />
        )}
        {showRulesAndRestrictions && (
          <RulesAndRestrictionsButton onOpenRulesAndRestrictions={onOpenRulesAndRestrictions} />
        )}
        {isCancelAvailable && onCancelModalOpen && (
          <CancelButton
            label={t(i18Base + 'CancelTrip')}
            ariaLabel={t('ActionButtons.Label', { button: t(i18Base + 'CancelTrip') })}
            onOpen={onCancelModalOpen}
          />
        )}
      </Container>
      {isAppleBannerShown && (
        <Block marginTop={32}>
          <AppleBanner orientation="vertical" />
        </Block>
      )}
    </Wrapper>
  )
})
