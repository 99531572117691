import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { KeyboardNavigationStyle } from '@fiji/style'

export const DesktopHeaderWrapper = styled.div<{
  withBorderBottom?: boolean
  isTripOnHold?: boolean
}>`
  width: 100%;
  margin: 0 auto;
  background-color: ${(p) => (p.isTripOnHold ? p.theme.colors.duskBlue : p.theme.colors.white)};
  ${(p) => p.withBorderBottom && `border-bottom: 1px solid ${p.theme.colors.background}`}
`

export const TripDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`

export const HeadingSection = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 12px 0;
`

export const HeadingData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

export const Status = styled(Text)`
  margin-left: 4px;
`

export const TripTitle = styled(Text)`
  position: relative;
  padding-left: 7px;
  &:before {
    position: absolute;
    content: '';
    background-color: ${({ theme }) => theme.colors.borderLight};
    width: 1px;
    height: 80%;
    bottom: 3px;
    left: -3px;
  }
`

export const ShareButton = styled(Button)`
  padding: 10px 20px;
  margin-left: 8px;
`
export const IconWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  vertical-align: bottom;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary1};
  ${KeyboardNavigationStyle}
`

export const ActionButton = styled(Button)`
  & div:first-child {
    margin-right: 4px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`
