import type { PropsWithChildren, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import { NoConnectionIcon } from './no-connection-icon'
import { Container, Icon, ButtonWrapper } from './offline-block-styled'
import { useOfflineBlock } from './use-offline-block'

type Props = PropsWithChildren<{
  onButtonClick?: (event: MouseEvent) => void
}>

const i18nBase = 'OfflineBlock'

export function OfflineBlock({ onButtonClick, children }: Props) {
  const { t } = useTranslation()
  const { isShowChildren, isShowButton, handleButtonClick } = useOfflineBlock({ onButtonClick })

  return (
    <Swap is={isShowChildren} isSlot={children}>
      <Container>
        <Icon>
          <NoConnectionIcon />
        </Icon>
        <Block marginBottom={8}>
          <Text variant="headline" color="mainText">
            {t(`${i18nBase}.Title`)}
          </Text>
        </Block>
        <Text variant="footnoteMedium" color="bodyText" align="center">
          {t(`${i18nBase}.DescriptionFirstLine`)}
          <br />
          {t(`${i18nBase}.DescriptionSecondLine`)}
        </Text>
        {isShowButton && (
          <ButtonWrapper>
            <Button size="small" onClick={handleButtonClick} fullWidth>
              {t(`${i18nBase}.Button`)}
            </Button>
          </ButtonWrapper>
        )}
      </Container>
    </Swap>
  )
}
