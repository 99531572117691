import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { Rate, SegmentState } from '@fiji/graphql/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { RatePrice } from '@etta/components/rate-price'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Skeleton } from '@etta/ui/skeleton'
import { useUnusedTicketOptContext } from '@etta/modules/unused-ticket-opt'
import { DueToday, Text } from '../payment-summary-styled'
import { getPayLabel } from './get-pay-label'

type Props = {
  rate?: Rate | null
  isPayingNow?: boolean
  segmentState?: SegmentState | null
  isDesktopTripCostSummary?: boolean
  isBorderVisible?: boolean
}

export const DueTodayCost = observer(function DueTodayCost({
  rate,
  isPayingNow,
  segmentState,
  isBorderVisible = true,
}: Props) {
  const { unusedTicketOptStore } = useUnusedTicketOptContext()
  const { t } = useTranslation()
  const i18nBase = 'PaymentSummary.'
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (!rate) {
    return null
  }

  const label = getPayLabel({ segmentState, isPayingNow })
  const priceLabel = formatRate(rate)

  return (
    <Block marginBottom={isMobile ? 0 : 16}>
      <DueToday
        aria-label={t(i18nBase + 'PaymentAccessibilityLabel', {
          type: t(i18nBase + label),
          amount: [priceLabel.mainCost, priceLabel.secondCost].filter(Boolean).join(', '),
        })}
        isBorderVisible={isBorderVisible}>
        <Text data-tracking-id="due-today-title-text">{t(i18nBase + label)}</Text>
        {unusedTicketOptStore.isLoading ? (
          <Skeleton width={100} height={20}>
            <rect width="100px" height="20px" />
          </Skeleton>
        ) : (
          <RatePrice rate={rate} textVariant="subHeadStrong" secondTextVariant="footnoteStrong" />
        )}
      </DueToday>
    </Block>
  )
})
