import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { Container, HeaderContainer, ErrorContainer } from './car-rental-details-error-state-styled'

type Props = {
  onClose: () => void
  onRetry?: () => void
}

export function CarRentalDetailsErrorState({ onClose, onRetry }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <HeaderContainer>
        <Header
          minHeight={72}
          rightSlot={
            <IconButton
              icon="closeFilledPWA"
              size="medium"
              color="bodyText"
              onClick={onClose}
              aria-label={t('Accessibility.Header.Close')}
            />
          }
        />
      </HeaderContainer>
      <ErrorContainer>
        <NoResultSearchScreen
          type="car-rental"
          title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
          subTitle={t('SearchSegments.NoResultErrorScreen.ErrorCarRentalSubTitle')}
          buttons={[
            {
              title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
              onClick: onRetry,
              buttonVariant: 'solid',
            },
          ]}
        />
      </ErrorContainer>
    </Container>
  )
}
