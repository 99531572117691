import styled from 'styled-components'
import {
  UnstyledButton,
  getKeyboardNavigationStyle,
  regularListItem,
  subHeadMedium,
} from '@fiji/style'

export const ListItem = styled(UnstyledButton)<{
  active: boolean
}>`
  position: relative;
  padding: 2px 6px;
  margin: 0;
  ${subHeadMedium};
  color: ${(p) => p.theme.primaryColor};
  text-align: left;
  width: 100%;

  &:active {
    background-color: ${(p) => p.theme.colors.background};
  }

  ${regularListItem};
  ${getKeyboardNavigationStyle({ offset: -2 })}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    left: 46px;
    border-bottom: 1px solid ${(p) => p.theme.colors.background1};
  }
`
