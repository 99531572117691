import styled, { css } from 'styled-components'
import type { ModalPosition } from '@fiji/types'
import type { HorizontalDimension } from '@etta/ui/modal/types'
import { getContentWidth } from './get-content-width'

export const Layer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 20;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
  [data-test='portal']:first-child & {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export const OverlayLayoutBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Container = styled.div<{ position: ModalPosition }>`
  display: flex;
  align-items: ${(p) => {
    switch (p.position) {
      case 'top':
        return 'flex-start'
      default:
        return 'center'
    }
  }};
  justify-content: ${(p) => {
    switch (p.position) {
      default:
      case 'center':
        return 'center'
      case 'right':
        return 'flex-end'
    }
  }};
  width: 100%;
  height: 100%;
  min-height: 0;
  margin: ${(p) => {
    switch (p.position) {
      default:
      case 'center':
        return '48px auto'
      case 'top':
      case 'right':
        return '0'
    }
  }};
`

export const Content = styled.div<{
  position: ModalPosition
  horizontalDimension?: HorizontalDimension
  borderRadius?: string | number
  withMaxHeight?: boolean
  withShadow?: boolean
  disableOverflow?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  ${({ withMaxHeight }) =>
    withMaxHeight &&
    css`
      height: 100%;
    `}
  width: ${({ horizontalDimension }) => getContentWidth(horizontalDimension)}px;
  box-shadow: ${({ withShadow }) => (withShadow ? '0 16px 50px rgba(0, 0, 0, 0.17)' : 'none')};
  ${(p) => {
    switch (p.position) {
      default:
      case 'center':
        let borderRadius = '15px'

        if (p.borderRadius) {
          borderRadius = typeof p.borderRadius === 'number' ? `${p.borderRadius}px` : p.borderRadius
        }

        return css`
          border-radius: ${borderRadius};
        `
      case 'right':
        return css`
          border-radius: 0;
          min-height: 100%;
        `
      case 'top':
        return css`
          border-radius: 0;
          min-width: 100%;
        `
    }
  }};
  outline: none;
  overflow: ${(p) => (p.disableOverflow ? 'hidden' : 'unset')};
  background-color: ${(p) => p.theme.colors.white};
`
