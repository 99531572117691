import { CSSTransition, TransitionGroup } from 'react-transition-group'
import type {
  AccessPointValueObject,
  ZoneValueObject,
} from '@etta/modules/ride-hail/core/value-objects'

import { MobilityZoneSelectionItem } from './mobility-zone-selection-item'
import {
  ContainerWrapper,
  TRANSITION_TIMEOUT,
  SLIDE_LEFT_IN,
  SLIDE_RIGHT_IN,
} from './mobility-zone-selection-content-styled'

type Props = {
  onSelect: (selected: ZoneValueObject | AccessPointValueObject) => void
  zones?: ZoneValueObject[]
  accessPoints?: AccessPointValueObject[]
  selectedZone?: ZoneValueObject
  selectedAccessPoint?: AccessPointValueObject | null
  shouldRenderChildZones: boolean
}

export function MobilityZoneSelectionContent({
  onSelect,
  zones,
  accessPoints,
  selectedZone,
  selectedAccessPoint,
  shouldRenderChildZones,
}: Props) {
  return (
    <TransitionGroup component={null}>
      {shouldRenderChildZones && (
        <CSSTransition key={'zones'} timeout={TRANSITION_TIMEOUT} classNames={SLIDE_LEFT_IN}>
          <ContainerWrapper>
            {zones?.map((childZone) => {
              return (
                <MobilityZoneSelectionItem
                  key={childZone.name}
                  locationName={childZone.name}
                  onSelect={() => onSelect(childZone)}
                  isSelected={childZone.name === selectedZone?.name}
                />
              )
            })}
          </ContainerWrapper>
        </CSSTransition>
      )}
      {!shouldRenderChildZones && (
        <CSSTransition
          key={'access-points'}
          timeout={TRANSITION_TIMEOUT}
          classNames={SLIDE_RIGHT_IN}>
          <ContainerWrapper>
            {accessPoints?.map((accessPoint) => {
              return (
                <MobilityZoneSelectionItem
                  key={accessPoint.id}
                  locationName={accessPoint.label}
                  onSelect={() => onSelect(accessPoint)}
                  isSelected={accessPoint.id === selectedAccessPoint?.id}
                  isAccessPoint
                />
              )
            })}
          </ContainerWrapper>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
