import { useEffect } from 'react'
import { BookAgainTravelVerticalEnum } from '@etta/modules/post-booking/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useUserContext } from '@etta/modules/user'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import type { Props, Delegate } from './types'

type Args = Pick<Props, 'carRentalSegment' | 'flightSegment' | 'hotelSegment'>

export function useBookTripAgainModal(props: Args) {
  const { bookTripAgainStore } = usePostBookingContext()
  const { userStore } = useUserContext()
  const { isShowDelegateName } = useShowDelegateName()
  const regularUser: Delegate | undefined = !isShowDelegateName
    ? {
        firstName: userStore.user?.profile?.firstName,
        lastName: userStore.user?.profile?.lastName,
        userId: String(userStore.user?.customerId),
      }
    : undefined

  useEffect(() => {
    const initialSegments: BookAgainTravelVerticalEnum[] = []
    if (props.carRentalSegment) {
      initialSegments.push(BookAgainTravelVerticalEnum.Carrental)
    }
    if (props.flightSegment) {
      initialSegments.push(BookAgainTravelVerticalEnum.Air)
    }
    if (props.hotelSegment) {
      initialSegments.push(BookAgainTravelVerticalEnum.Hotel)
    }

    bookTripAgainStore.onInitializeSelectedSegments(initialSegments)
  }, [props.carRentalSegment, props.flightSegment, props.hotelSegment, bookTripAgainStore])

  return {
    regularUser,
    currentUserId: userStore.user?.delegatedId || userStore.user?.customerId,
  }
}
