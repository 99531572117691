import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/hooks'
import { PostBookingAction } from '@fiji/enums'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'

type Args = {
  type: SegmentType.Flight | SegmentType.Hotel | SegmentType.CarRental
  postBookingAction?: PostBookingAction
  isAbleToSubmit?: boolean
}

const typeToTranslateString = {
  [SegmentType.CarRental]: 'CarRental',
  [SegmentType.Flight]: 'Flight',
  [SegmentType.Hotel]: 'Hotel',
}

export function useServiceSearchLayout({ type, postBookingAction, isAbleToSubmit }: Args) {
  const { t } = useTranslation()
  const i18nPath = 'TripReview.AddButtonsPWA.'
  const postBookingTitleModificator = postBookingAction === PostBookingAction.Modify ? 'Modify' : ''
  const isDisabled = typeof isAbleToSubmit == 'undefined' ? false : !isAbleToSubmit

  return {
    headerText: t(i18nPath + postBookingTitleModificator + typeToTranslateString[type]),
    iconName: getIconNameBySegment(type),
    isDisabled,
  }
}
