import { useCallback, useMemo } from 'react'
import { useAppDispatch } from '@fiji/store'

import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { getFlightType } from '@fiji/utils/get-flight-type'
import { updateFlightId } from '@fiji/store/segment-ids'
import { ROUTES } from '@fiji/routes'
import { FlightDirection, PostBookingAction, TimeRangeDirection } from '@fiji/enums'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { getRestrictedAirCarrierFilters } from '@fiji/utils/get-restricted-air-filters'
import { getFlightSearchRestrictions } from '@fiji/utils/get-flight-search-restrictions'
import { getServiceClass } from './get-service-class'

type Props = {
  itineraryId: string
  handleCloseChangeModal: () => void
  flightSegments: FlightSegmentEntity[]
  flightId: string
  searchId?: string | null
  isChangeCarrierAllowed?: boolean
  isChangeDestinationAllowed?: boolean
}

export function useChangeFlight({
  itineraryId,
  handleCloseChangeModal,
  isChangeCarrierAllowed,
  isChangeDestinationAllowed,
  flightSegments: flightSegmentsList,
  flightId,
  searchId,
}: Props) {
  const flightType = getFlightType(flightSegmentsList)
  const dispatch = useAppDispatch()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()
  const { cabinClassesOptions } = useSeatmapCabinClasss()

  const { navigateTo, queryParams } = useAirSearchQuery()
  const { bookingId } = queryParams

  const cabinClass = getServiceClass({
    cabinClasses: cabinClassesOptions,
    classFromSegment: flightSegmentsList?.[0]?.segments[0]?.serviceClass,
  })

  const searchRestrictions = getFlightSearchRestrictions({
    isChangeCarrierAllowed,
    isChangeDestinationAllowed,
    flightSegments: flightSegmentsList,
    isPostBooking: !!bookingId,
  })

  const returnTime = useMemo(
    () =>
      flightType === FlightType.Round && flightSegmentsList[1]
        ? {
            returnTimeRange: getTimeForInitialFlight(
              TimeRangeDirection.Departure,
              FlightDirection.Return,
            ),
            returnDate: new Date(deleteTimezone(flightSegmentsList[1].departureDateTime)),
          }
        : {
            returnTimeRange: getTimeForInitialFlight(
              TimeRangeDirection.Departure,
              FlightDirection.Return,
            ),
            returnDate: undefined,
          },
    [flightSegmentsList, flightType, getTimeForInitialFlight],
  )

  const flights: Flight[] = flightSegmentsList.map((leg, index) => {
    const flightSegments = leg?.segments || []
    const departureFlightLeg = flightSegments[0]
    const destinationFlightLeg = flightSegments[flightSegments.length - 1]
    const departureLocation = departureFlightLeg ? departureFlightLeg.departure : undefined
    const arrivalLocation = destinationFlightLeg ? destinationFlightLeg.arrival : undefined

    return {
      id: index,
      originPlace: {
        placeId: '1',
        latitude: departureLocation?.address?.geocode?.lat,
        longitude: departureLocation?.address?.geocode?.long,
        airportCode: departureLocation?.airportCode || '',
        name: departureLocation?.address
          ? `${departureLocation?.address?.city}, ${departureLocation?.address?.stateCode}, ${departureLocation?.address?.countryCode}`
          : '',
      },
      timeRange: getTimeForInitialFlight(TimeRangeDirection.Departure, FlightDirection.Origin),
      cabinClass,
      departureDate: new Date(deleteTimezone(leg?.departureDateTime)),
      returnTimeRange: returnTime.returnTimeRange,
      returnDate: index === 0 ? returnTime.returnDate : undefined,
      destinationPlace: {
        placeId: '2',
        latitude: arrivalLocation?.address?.geocode?.lat,
        longitude: arrivalLocation?.address?.geocode?.long,
        airportCode: arrivalLocation?.airportCode || '',
        name: arrivalLocation?.address
          ? `${arrivalLocation?.address?.city}, ${arrivalLocation?.address?.stateCode}, ${arrivalLocation?.address?.countryCode}`
          : '',
      },
    }
  })

  const handleChangeFlights = useCallback(() => {
    const { filtersForFirstLeg, filtersForReturnLeg } = getRestrictedAirCarrierFilters({
      searchRestrictions,
      legs: flightSegmentsList,
    })
    dispatch(updateFlightId({ flightId }))
    handleCloseChangeModal()
    navigateTo(ROUTES.air.results({ legNumber: 0 }), {
      itineraryId,
      bookingId,
      flightType,
      flights,
      searchId,
      areNearbyAirportsIncluded: true,
      filters: {
        airlines: filtersForFirstLeg,
        destinationAirports: [],
        originTime: null,
        destinationTime: null,
        fareTypes: [],
        originAirports: [],
        stops: [],
        isMatchedWithPolicy: false,
      },
      nextLegFilters: {
        airlines: filtersForReturnLeg,
        destinationAirports: [],
        originTime: null,
        destinationTime: null,
        fareTypes: [],
        originAirports: [],
        stops: [],
        isMatchedWithPolicy: false,
      },
      searchRestrictions,
      postBookingAction: bookingId ? PostBookingAction.Modify : undefined,
    })
  }, [
    searchRestrictions,
    flightSegmentsList,
    dispatch,
    flightId,
    searchId,
    handleCloseChangeModal,
    navigateTo,
    itineraryId,
    bookingId,
    flightType,
    flights,
  ])

  return {
    handleChangeFlights,
  }
}
