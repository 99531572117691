import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { ROUTES } from '@fiji/routes'
import { DynamicSiteMessagesSkeleton } from '@etta/components/dynamic-site-messages-skeleton'
import { RtpItineraryCardSkeleton } from '@etta/components/rtp-itinerary-card-skeleton'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { TripHeader } from '@etta/components/trip-header/trip-header'
import { FooterCheckout } from '@etta/screens/review-trip-page/footer-checkout'
import { TripReviewAddButtonsRow } from '@etta/screens/review-trip-page/trip-review-add-buttons-row'
import { TripBackground } from '@etta/components/trip-background'
import { Screen } from '@etta/ui/screen'
import { Container, DsmWrapper, List } from './rtp-book-trip-again-fail-state-mobile-styled'

export function RtpBookTripAgainFailStateMobile() {
  const { t } = useTranslation()
  const i18nBase = 'TripReview.BookAgain.'
  const history = useHistory()
  const navigateToEditSearch = useCallback(() => history.push(ROUTES.explore), [history])

  return (
    <Screen backgroundColor="background">
      <Screen.Container>
        <TripBackground onClose={() => {}} withIcon />
        <TripHeader
          tripName={''}
          titleDates={''}
          variant="topHeading"
          onEdit={() => {}}
          isEditable={false}
          isShowDelegateName={false}
          currentDelegatedUser={undefined}
          bookingRef={''}
        />
        <div>
          <Block paddingLeft={16} paddingBottom={24}>
            <Text variant="title2">{t('TripReview.Header.ReviewYourTrip')}</Text>
          </Block>
          <div>
            <Container>
              <TripReviewAddButtonsRow
                settings={[
                  {
                    type: SegmentType.Flight,
                    onClick: () => {},
                    isDisabled: true,
                    isVisible: true,
                  },
                  { type: SegmentType.Hotel, onClick: () => {}, isDisabled: true, isVisible: true },
                  {
                    type: SegmentType.CarRental,
                    onClick: () => {},
                    isDisabled: true,
                    isVisible: true,
                  },
                ]}
              />
              <DsmWrapper>
                <DynamicSiteMessagesSkeleton />
              </DsmWrapper>
              <List>
                <>
                  <RtpItineraryCardSkeleton />
                  <RtpItineraryCardSkeleton />
                </>
              </List>
            </Container>
          </div>
        </div>
      </Screen.Container>
      <Screen.Footer>
        <FooterCheckout
          isHoldTripAllowed={false}
          isDisabled={true}
          onOpenTripCostSummary={() => {}}
          onHold={() => {}}
          onCheckout={() => {}}
          totalCost={{
            primary: { amount: 0, currency: 'USD' },
          }}
        />
      </Screen.Footer>

      <ConfirmationDialog
        isOpen={true}
        title={t(i18nBase + 'SameServicesNotAvailable')}
        content={t(i18nBase + 'PreviousItineraryNotAvailable')}
        confirmButtonText={t(i18nBase + 'StartWithNewSearch')}
        onConfirm={navigateToEditSearch}
        onDecline={() => {}}
      />
    </Screen>
  )
}
