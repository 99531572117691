import styled, { css } from 'styled-components'
import { Icon as EttaIcon } from '@etta/ui/icon'
import { OutsideContainer as EttaOutsideContainer } from '@etta/ui/outside-container'
import { TextField as EttaTextField } from '@etta/ui/text-field'
import { getKeyboardNavigationStyle } from '@fiji/style'
import type { ListConfig } from './types'

export const Wrapper = styled.div<{ isOutsideAlignRight?: boolean }>`
  position: relative;
  ${({ isOutsideAlignRight }) =>
    isOutsideAlignRight &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`

export const SkeletonWrapper = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
`

export const SkeletonContent = styled.div`
  padding: 0 12px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`

export const TextFieldContainer = styled.div<{ isOpen: boolean; withVisibleLabel?: boolean }>`
  cursor: pointer;
  ${({ isOpen, withVisibleLabel }) =>
    isOpen &&
    !withVisibleLabel &&
    css`
      max-height: 0;
      opacity: 0;
    `}

  ${getKeyboardNavigationStyle({ borderRadius: 10, offset: 0 })}
`

export const ListWrapper = styled.div``

export const OutsideContainer = styled(EttaOutsideContainer)<{
  withTopPadding?: boolean
  isFixedPositioned?: boolean
  listConfig?: ListConfig | null
  overflowContentHeight?: number
}>`
  ${({ withTopPadding, overflowContentHeight }) =>
    withTopPadding &&
    css`
      top: ${overflowContentHeight ? -1 * overflowContentHeight + 'px' : '60px'};
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.colors.borderLight};
    `}
  ${({ isFixedPositioned, listConfig }) =>
    isFixedPositioned &&
    listConfig &&
    css`
      position: fixed;
      top: ${listConfig.y}px;
      left: ${listConfig.x}px;
      width: ${listConfig.width}px;
    `}
`

export const Icon = styled(EttaIcon)`
  margin-right: 12px;
`

const ellipsisOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TextField = styled(EttaTextField)<{ withThinLabel?: boolean }>`
  pointer-events: none;
  label input {
    ${ellipsisOverflow}

    ${({ withThinLabel }) =>
      !withThinLabel &&
      css`
        font-weight: 700;
      `}
  }
`
