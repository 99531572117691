import { useRef } from 'react'
import { ControlsContainerLayout } from '@etta/ui/date-time-picker/controls/controls-container'
import { ButtonsContainer } from '@etta/ui/date-time-picker/controls/controls-styled'
import { SelectedDateTitle } from '@etta/ui/date-time-picker/controls/selected-date-title'
import { Block } from '@etta/ui/block'
import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { SlidingLine } from '@etta/ui/date-time-picker/controls/sliding-line'
import type { LayoutControlsProps } from '@etta/ui/date-time-picker/controls/types'
import { CustomFocusedInput } from '@etta/ui/date-time-picker/controls/types'
import { SelectedDateStatusDesktop } from './controls-layout-desktop-styled'

export function ControlsLayoutDesktop({
  date,
  time,
  focusedInput,
  dateAriaLabel,
  dateTitle,
  timeTitle,
  timeAriaLabel,
  isOpen,
  handleClickTabDate,
  handleClickTabTime,
}: LayoutControlsProps) {
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)
  const activeRef = focusedInput === CustomFocusedInput.date ? startRef : endRef
  const activeDateStrLength =
    focusedInput === CustomFocusedInput.date ? dateTitle?.length : timeTitle?.length

  return (
    <ControlsContainerLayout data-name="date-picker-controls">
      <ButtonsContainer shouldCenterItems>
        <SelectedDateStatusDesktop
          onClick={handleClickTabDate}
          data-tracking-id="date-tab"
          ref={startRef}
          date={date}
          time={time}
          isSelected={focusedInput === CustomFocusedInput.date}
          aria-label={dateAriaLabel}>
          <SelectedDateTitle dateTitle={dateTitle} />
        </SelectedDateStatusDesktop>
        <Block marginHorizontal={16}>
          <PWAIcon color="bodyText2" size={32} name="arrowForwardPWA" />
        </Block>
        <SelectedDateStatusDesktop
          onClick={handleClickTabTime}
          data-tracking-id="time-tab"
          ref={endRef}
          date={date}
          time={time}
          isSelected={focusedInput === CustomFocusedInput.time}
          isStickToRight
          aria-label={timeAriaLabel}>
          <SelectedDateTitle dateTitle={timeTitle} />
        </SelectedDateStatusDesktop>
      </ButtonsContainer>
      <SlidingLine
        element={activeRef}
        elementLength={activeDateStrLength || 0}
        isVisible={isOpen}
      />
    </ControlsContainerLayout>
  )
}
