import { Inject, Service } from '@etta/di'
import type { GetPlacesDTO } from '@etta/modules/autocomplete/infra/types'

import { SettingsAdapter } from '../../infra/settings.adapter'

@Service()
export class HomeAirportService {
  constructor(
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
  ) {}

  async getSuggestedHomeAirports(input: GetPlacesDTO) {
    const result = await this.settingsAdapter.getSuggestedHomeAirports(input)

    if (result.isErr()) {
      return
    }

    return result.getValue()
  }
}
