import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.mainText2};
`
