import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import type { SegmentSeatNumber } from '../../air-seat-map.types'

type RemoveSeatArgs = {
  seat: SeatMapRowSeatValueObject
  selectedSegmentId: string
  segmentsSeatNumbers: SegmentSeatNumber[]
}

export function removeSeat({
  seat,
  selectedSegmentId,
  segmentsSeatNumbers,
}: RemoveSeatArgs): SegmentSeatNumber[] {
  return segmentsSeatNumbers.filter((seatItem) => {
    const isCurrentSeat =
      seatItem.seatNumber === seat.number && seatItem.segmentId === selectedSegmentId
    return !isCurrentSeat
  })
}
