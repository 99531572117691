import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { Props } from '../types'
import useDatePickerModal from '../use-date-picker-modal'
import { StyledDatePicker, Footer } from './date-picker-modal-mobile-styled'

export function DatePickerModalMobile({
  minDate,
  maxDate,
  date,
  dateEnd,
  isRange,
  isOpen,
  onSelect,
  onClose,
  startTitle,
  endTitle,
  withConfirmOneWayButton = true,
  withConfirmButton = false,
  isEndDateSame,
  highlightDates,
  dateTitleFormat,
  isShowCalendarIcon,
  withNextButton = false,
  forceScreenType,
  isDepartureDatePickerDisabled,
}: Props) {
  const {
    selectHandler,
    closeHandler,
    footerTitle,
    isFooterVisible,
    dates,
    handleDatesSelect,
  } = useDatePickerModal({
    date,
    dateEnd,
    onSelect,
    onClose,
    isOpen,
    withConfirmOneWayButton,
    withConfirmButton,
    withNextButton,
    isDepartureDatePickerDisabled,
  })
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={closeHandler}
      position={isDesktop ? 'right' : 'center'}>
      <Modal.Header isMobile>
        <Header leftSlot={<Header.CloseButton onClick={closeHandler} />} withBorderBottom>
          <Header.Title title={t('DatePickerModal.ChooseDates')} align="left" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <StyledDatePicker
          forceScreenType={forceScreenType}
          isEndDateSame={isEndDateSame}
          minDate={minDate}
          maxDate={maxDate}
          date={dates.date}
          dateEnd={dates.dateEnd}
          isRange={isRange}
          highlightDates={highlightDates}
          onSelect={handleDatesSelect}
          startTitle={startTitle}
          endTitle={endTitle}
          dateTitleFormat={dateTitleFormat}
          isShowCalendarIcon={isShowCalendarIcon}
          isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
        />
      </Modal.Body>
      {isFooterVisible && footerTitle && (
        <Footer>
          <Button fullWidth size="normal" onClick={selectHandler}>
            {footerTitle}
          </Button>
        </Footer>
      )}
    </Modal>
  )
}
