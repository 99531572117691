import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money'
import { Separator } from '@etta/ui/separator'
import type { Rate } from '@fiji/graphql/types'
import { PaymentCardText, Row } from './print-itinerary-modal.styled'

type Props = {
  segmentLabel: string
  cost?: Rate | null
  tax?: Rate | null
  total?: Rate | null
  isEstimated?: boolean
  paymentCards?: string[] | null
}

export function SegmentCostSummary({
  segmentLabel,
  cost,
  tax,
  total,
  isEstimated,
  paymentCards,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  return (
    <>
      <div>
        <Row>
          <Text variant="footnoteMedium">
            {segmentLabel} ({t(i18nBase + 'EstCost')})
          </Text>
          <Text variant="footnoteMedium">{formatRate(cost, { morpheme: 'prefix' }).mainCost}</Text>
        </Row>
        {tax && (
          <Row>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'TaxesAndFees')}
            </Text>
            <Text variant="footnoteMedium" color="bodyText">
              {formatRate(tax, { morpheme: 'prefix' }).mainCost}
            </Text>
          </Row>
        )}
      </div>
      <Separator color={'borderLight'} />
      <Row>
        <Text variant="captionStrong">
          {t(i18nBase + 'SubTotal')}&nbsp;
          {isEstimated && (
            <Text variant="footnoteMedium" color="bodyText">
              ({t(i18nBase + 'EstimatedReservation')} {segmentLabel})
            </Text>
          )}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {formatRate(total, { morpheme: 'prefix' }).mainCost}
        </Text>
      </Row>
      {paymentCards?.map(
        (paymentCard) =>
          paymentCard && (
            <PaymentCardText variant="footnoteMedium" color="bodyText">
              {segmentLabel} {t(i18nBase + 'PaymentCard')}: {paymentCard}
            </PaymentCardText>
          ),
      )}
      <Separator color={'borderLight'} />
    </>
  )
}
