import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
`

export const TitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
`

export const BadgeSeparator = styled.div`
  margin-right: 8px;
  width: 1px;
  height: 10px;
  background: ${({ theme }) => theme.colors.borderDark};
`

export const BadgeContainer = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const IconContainer = styled.div`
  margin-right: 5px;
`
