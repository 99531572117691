import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useTripDetailsContextSelector } from '../trip-details-provider'
import { useShare } from './use-action-buttons/use-share'

type Params = {
  segment: SegmentPostBookingValueObject
}

export function useShareSegment({ segment }: Params) {
  const { tripName, tripDetails } = useTripDetailsContextSelector((state) => state)
  const { handleShare } = useShare({
    segments: [segment],
    tripName,
    startDate: tripDetails.trip.rollUpStartTime,
    endDate: tripDetails.trip.rollUpEndTime,
  })

  return {
    handleShare,
  }
}
