import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { Icon } from '@etta/ui/icon'
import type { HotelReview, Maybe } from '@fiji/graphql/types'
import { ButtonContainer, Content, StyledButton } from './hotel-reviews-styled'
import { HotelReviewsBlock } from './hotel-reviews-block'
import { useHotelRating } from './use-hotel-rating'

type Props = {
  hotelId: string
  medianReviewRating?: number | null
  totalReviewCount?: number | null
  reviews?: Maybe<HotelReview>[]
  hotelReviewsDetailsUrl?: string
}

export function HotelReviews({
  hotelId,
  medianReviewRating,
  totalReviewCount,
  reviews,
  hotelReviewsDetailsUrl,
}: Props) {
  const { t } = useTranslation()

  const { medianRating, reviewCount, reviewsList, isLoading, error } = useHotelRating({
    hotelId,
    medianReviewRating,
    totalReviewCount,
    reviews,
  })

  const onSeeMoreClick = useCallback(() => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(hotelReviewsDetailsUrl, '_blank', 'noopener')
  }, [hotelReviewsDetailsUrl])

  const i18nPath = 'HotelInfo.Reviews.'

  if (isLoading) {
    return <Content>{t(i18nPath + 'Loading')}</Content>
  }

  if (error) {
    return <Content>{error.message}</Content>
  }

  if (reviewsList.length < 1) {
    return (
      <>
        <HotelReviewsBlock />
        <ButtonContainer>
          <StyledButton btnType="tertiary">{t(i18nPath + 'NoReviews')}</StyledButton>
        </ButtonContainer>
      </>
    )
  }

  return (
    <>
      <HotelReviewsBlock
        rating={medianRating}
        totalReviewCount={reviewCount}
        reviewList={reviewsList}
      />
      {hotelReviewsDetailsUrl && (
        <ButtonContainer>
          <StyledButton
            aria-label={t('ReviewTrip.ButtonsLabel', { label: t(i18nPath + 'SeeMoreReviews') })}
            variant="solid"
            btnType="tertiary"
            onClick={onSeeMoreClick}
            rightIcon={<Icon name="openWindowPWA" />}>
            {t(i18nPath + 'SeeMoreReviews')}
          </StyledButton>
        </ButtonContainer>
      )}
    </>
  )
}
