import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
`

export const MapContainer = styled.div`
  display: flex;
  width: 40%;
  position: relative;
`

export const HotelListContainer = styled.div`
  display: flex;
  width: 60%;
  position: relative;
  overflow: auto;
`
