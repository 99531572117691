import { MediaLayout } from '@etta/ui/media-layout'
import type { RtpItineraryCardProps } from './types'
import { RTPItineraryCardDesktop, RTPItineraryCardMobile } from './layout'
import { ListItem } from './rtp-itinerary-card-styled'
import { AirportTitle } from './airport-title'
import { SafetyCheckIcons } from './safety-check-icons'
import { RailTitle } from './rail-title'
import { UnusedTicketTooltip } from './unused-ticket-tooltip/unused-ticket-tooltip'

export function RTPItineraryCard({ ...props }: RtpItineraryCardProps) {
  return (
    <MediaLayout
      mobileSlot={<RTPItineraryCardMobile {...props} />}
      desktopSlot={<RTPItineraryCardDesktop {...props} />}
    />
  )
}

RTPItineraryCard.Text = ListItem
RTPItineraryCard.AirportTitle = AirportTitle
RTPItineraryCard.RailTitle = RailTitle
RTPItineraryCard.SafetyCheckIcons = SafetyCheckIcons
RTPItineraryCard.UnusedTicketTooltip = UnusedTicketTooltip
