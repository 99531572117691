import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Rate, Money } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { UnitVariant } from '../../types'

type Args = {
  type: SegmentType
  variant: UnitVariant
  totalAmount?: Rate | null
  taxesCost?: Rate | null
  totalAmountMoney?: Money | null
  taxesCostMoney?: Money | null
}

const i18nBase = 'PaymentSummary.'

export function useSegmentSection({
  type,
  variant,
  taxesCost,
  totalAmount,
  taxesCostMoney,
  totalAmountMoney,
}: Args) {
  const { t } = useTranslation()

  const title = useMemo(() => {
    switch (type) {
      case SegmentType.Flight:
        return t(i18nBase + 'Flight')
      case SegmentType.Hotel:
        return t(i18nBase + 'Hotel')
      case SegmentType.CarRental:
        return t(i18nBase + 'CarRental')
      case SegmentType.CarService:
        return t(i18nBase + 'CarService')
      case SegmentType.Train:
        return t(i18nBase + 'Train')
      default:
        return '-'
    }
  }, [type, t])

  const totalLabel = useMemo(() => {
    switch (variant) {
      case 'due-today':
        return t(i18nBase + 'DueToday')
      case 'payable-later':
        return t(i18nBase + 'PayableLater')
      case 'paid':
        return t(i18nBase + 'Paid')
      default:
        return ''
    }
  }, [variant, t])

  const taxes = formatRate(taxesCost || (taxesCostMoney ? { primary: taxesCostMoney } : null), {
    morpheme: 'none',
  }).mainCost

  const mainTotal = formatRate(
    totalAmount || (totalAmountMoney ? { primary: totalAmountMoney } : null),
    {
      morpheme: 'postfix',
    },
  ).mainCost
  const secondaryTotal = formatRate(
    totalAmount || (totalAmountMoney ? { primary: totalAmountMoney } : null),
    {
      morpheme: 'postfix',
    },
  ).secondCost

  return {
    title,
    totalLabel,
    taxes,
    mainTotal,
    secondaryTotal,
  }
}
