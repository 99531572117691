import { useTranslation } from 'react-i18next'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { useScreenType, ScreenType } from '@fiji/modes'
import type { ConfirmationNumberData } from '@etta/screens/payment-summary/types'
import { Skeleton } from '@etta/ui/skeleton'
import { ReservationNumber } from '../../reservation-number'
import { VendorTitle, SubTitle } from '../../titles'
import { CarRowContainer, CarRowSubtitle } from './car-row-styled'

type Props = {
  supplier: string
  reservationNumber?: string
  pickUpDate: string
  dropOffDate: string
  carClass: string
  confirmationData?: ConfirmationNumberData
  isTripLoading?: boolean
}

const i18nBase = 'PurchaseReservationSuccess.'

export function CarRow({
  supplier,
  carClass,
  reservationNumber,
  pickUpDate,
  dropOffDate,
  confirmationData,
  isTripLoading,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const pickUpLabel = tripReviewFormatDate(TripReviewDateType.DayMonthDateAndTime, pickUpDate)
  const dropOffLabel = tripReviewFormatDate(TripReviewDateType.DayMonthDateAndTime, dropOffDate)

  const carRowLabel = t(i18nBase + 'CarRowLabel', {
    title: supplier,
    pickUp: pickUpLabel,
    dropOff: dropOffLabel,
    number: reservationNumber,
  })

  const vendorTitle = [supplier, carClass.replaceAll('_', ' ').toLowerCase()]
    .filter(Boolean)
    .join(' • ')

  const showConfirmationData =
    !isTripLoading && confirmationData?.confirmationNumber && confirmationData?.companyName

  return (
    <CarRowContainer aria-label={carRowLabel}>
      {vendorTitle && <VendorTitle marginBottom={isMobile ? 2 : 4}>{vendorTitle} </VendorTitle>}
      <ReservationNumber number={reservationNumber} />
      <CarRowSubtitle marginBottom={2}>
        {t(i18nBase + 'PickUpDate')} {pickUpLabel}
      </CarRowSubtitle>
      <CarRowSubtitle marginBottom={isMobile ? 6 : 4}>
        {t(i18nBase + 'DropOffDate')} {dropOffLabel}
      </CarRowSubtitle>
      {isTripLoading && (
        <Skeleton width="188px" height="19px">
          <rect width="188px" height="19px" />
        </Skeleton>
      )}
      {showConfirmationData && (
        <SubTitle>
          {confirmationData.companyName} {t(i18nBase + 'Confirmation')}{' '}
          {confirmationData.confirmationNumber}
        </SubTitle>
      )}
    </CarRowContainer>
  )
}
