import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { PaymentDynamicSiteMessagesDesktop } from './desktop'
import { PaymentDynamicSiteMessagesMobile } from './mobile'

export function PaymentDynamicSiteMessagesLayout(props: LayoutProps) {
  return (
    <MediaLayout
      desktopSlot={<PaymentDynamicSiteMessagesDesktop {...props} />}
      mobileSlot={<PaymentDynamicSiteMessagesMobile {...props} />}
    />
  )
}
