import type { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ROUTES } from '@fiji/routes'
import { SessionModal } from '@etta/components/session-modal'
import { useUserLogin } from '../use-user-login'

type Props = {
  loaderSlot: ReactNode
  children: ReactNode
  previousPath: string
}

export const EttaAuth = observer(function EttaAuth({ children, loaderSlot, previousPath }: Props) {
  const isPrevPageLogin = previousPath.includes('/login')

  const {
    isLoading,
    isAuthorized,
    isSessionModalOpen,
    handleStayConnect,
    handleClose,
    isCurrentRouteExist,
  } = useUserLogin({
    isForceLoad: !isPrevPageLogin,
  })

  if (isLoading) {
    return <>{loaderSlot}</>
  }
  if (!isAuthorized) {
    return <Redirect to={ROUTES.login.email} />
  }

  if (!isCurrentRouteExist) {
    return <Redirect to={ROUTES.explore} />
  }

  return (
    <>
      {children}
      <SessionModal
        isOpen={isSessionModalOpen}
        handleStayConnect={handleStayConnect}
        handleClose={handleClose}
      />
    </>
  )
})
