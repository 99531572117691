import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { isSameMonth } from '@fiji/utils/dates/is-same-month'
import { isSameYear } from '@fiji/utils/dates/is-same-year'

type Props = {
  start: string
  end: string
}

const fullDateFormat = 'MMM dd, yyyy'

export function useFormattedDate({ start, end }: Props) {
  const startDateParsed = new Date(start)
  const endDateParsed = new Date(end)

  if (
    isSameYear(startDateParsed, endDateParsed) &&
    isSameMonth(startDateParsed, endDateParsed) &&
    startDateParsed.getDate() === endDateParsed.getDate()
  ) {
    return getFormattedTime(startDateParsed, 'MMM dd, yyyy')
  }

  const startDateFormatted = getFormattedTime(
    startDateParsed,
    isSameYear(startDateParsed, endDateParsed) ? 'MMM dd' : fullDateFormat,
  )
  const endDateFormatted = getFormattedTime(
    endDateParsed,
    isSameMonth(startDateParsed, endDateParsed) ? 'dd, yyyy' : fullDateFormat,
  )

  return `${startDateFormatted} - ${endDateFormatted}`
}
