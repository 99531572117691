/* eslint import/no-restricted-paths: 0 */
import type {
  CarRentalTravelPreferenceInput,
  UpdateCarRentalTravelPreferenceMutationHookResult,
  GazooMembership,
} from '@fiji/graphql/hooks'
import { VendorTravelVertical } from '@fiji/graphql/hooks'
import { Action, Inject } from '@etta/di'
import { CarRentalPreferencesStore } from '../stores/car-rental-preferences.store'
import { TravelPreferencesService } from '../services/travel-preferences.service'
import { SettingsStore } from '../stores/settings.store'

@Action()
export class CarRentalPreferencesActions {
  constructor(
    @Inject()
    private readonly carRentalPreferencesStore: CarRentalPreferencesStore,
    @Inject()
    private readonly travelPreferencesService: TravelPreferencesService,
    @Inject()
    private readonly settingsStore: SettingsStore,
  ) {}

  handleFieldChange<T extends keyof Omit<CarRentalTravelPreferenceInput, 'carClassWithNone'>>(
    field: T,
  ) {
    return (value: CarRentalTravelPreferenceInput[T]) => {
      this.carRentalPreferencesStore.setIsSaveButtonDisabled(false)
      return this.carRentalPreferencesStore.setCarRentalPreferencesValue(field, value)
    }
  }

  async handleCarRentalPreferencesSave(
    handleUpdate: UpdateCarRentalTravelPreferenceMutationHookResult,
  ) {
    const validatorCheck = await this.carRentalPreferencesStore.carRentalPreferencesValidator.submit()
    const hasValidationErrors = !!validatorCheck.errors

    if (hasValidationErrors) {
      return
    }

    const carRentalPreferencesValidator = this.carRentalPreferencesStore
      .carRentalPreferencesValidator

    const input = {
      carClassWithNone: carRentalPreferencesValidator.values.carClass || 'NONE',
      membership: carRentalPreferencesValidator.values.membership,
      specialRequest: carRentalPreferencesValidator.values.specialRequest,
    }

    this.travelPreferencesService.saveCarRentalPreferences({
      input,
      handleUpdate,
      handleClose: () => {
        this.carRentalPreferencesStore.setIsSaveButtonDisabled(true)
        this.carRentalPreferencesStore.toggle.handleClose()
      },
    })
  }

  handleEditModalOpening() {
    this.carRentalPreferencesStore.setIsSaveButtonDisabled(true)
    const carRentalPreferencesInput = this.travelPreferencesService.getCarRentalPreferencesInput()
    this.carRentalPreferencesStore.setCarRentalPreferencesInput(carRentalPreferencesInput)
    if (!this.carRentalPreferencesStore.carRentalAvailableSpecialRequests) {
      this.fetchCarRentalSpecialRequests()
    }

    this.carRentalPreferencesStore.toggle.handleOpen()
  }

  handleCarMembershipOpen() {
    this.carRentalPreferencesStore.setIsSaveButtonDisabled(true)
    this.carRentalPreferencesStore.carMembershipToggle.handleOpen()
    const carRentalPreferencesInput = this.travelPreferencesService.getCarRentalPreferencesInput()
    this.carRentalPreferencesStore.setCarRentalPreferencesInput(carRentalPreferencesInput)
    this.fetchAvailableCarVendors()
  }

  handleEditExistingCarMembershipProgram(vendorCode?: string | number | null) {
    this.carRentalPreferencesStore.setIsEditExistingProgramMode(true)
    this.handleCarMembershipOpen()

    const memberships = this.carRentalPreferencesStore.carRentalPreferencesValidator.values
      .membership as GazooMembership[]
    const existingMembership = memberships.find((item) => item.vendorCode === vendorCode)

    if (existingMembership) {
      this.carRentalPreferencesStore.setCarMembership(existingMembership)
    }
  }

  handleCarMembershipClose() {
    this.carRentalPreferencesStore.setIsSaveButtonDisabled(true)
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.carRentalPreferencesStore.carMembershipToggle.handleClose()
    this.carRentalPreferencesStore.resetCarMembership()
    this.carRentalPreferencesStore.setIsEditExistingProgramMode(false)
  }

  handleCarMembershipSelection(input: { vendorCode: string; number: string }) {
    this.carRentalPreferencesStore.setIsSaveButtonDisabled(false)
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.carRentalPreferencesStore.setCarMembership(input)
  }

  handleCarMembershipVendorSelection(vendorCode: string) {
    const carMemberships = this.carRentalPreferencesStore.carRentalPreferencesValidator.values
      .membership as GazooMembership[]
    const existingMembership = carMemberships.find((item) => item.vendorCode === vendorCode)

    this.handleCarMembershipSelection({
      vendorCode,
      number: existingMembership?.number || '',
    })

    this.carRentalPreferencesStore.setIsEditExistingProgramMode(!!existingMembership)
  }

  async handleCarMembershipSave(handleUpdate: UpdateCarRentalTravelPreferenceMutationHookResult) {
    const {
      carMembership,
      carMembershipToggle,
      carRentalPreferencesValidator,
    } = this.carRentalPreferencesStore
    const { vendorCode, number } = carMembership

    this.settingsStore.setLoyaltyProgramsValidation({ vendorCode, number })

    if (!vendorCode || !number) {
      return
    }

    const carMemberships = this.carRentalPreferencesStore.carRentalPreferencesValidator.values
      .membership as GazooMembership[]

    const memberships = carMemberships.filter(
      (membership) => vendorCode && membership.vendorCode !== vendorCode,
    )

    const input = {
      carClassWithNone: carRentalPreferencesValidator.values.carClass || 'NONE',
      membership: [...memberships, carMembership],
      specialRequest: carRentalPreferencesValidator.values.specialRequest,
    }

    const result = await this.travelPreferencesService.saveCarRentalPreferences({
      input,
      handleUpdate,
      handleClose: carMembershipToggle.handleClose,
    })

    if (result?.isErr()) {
      return
    }

    this.carRentalPreferencesStore.resetCarMembership()
    this.settingsStore.resetLoyaltyProgramsValidation()
  }

  async handleRemoveExistingMembership(
    handleUpdate: UpdateCarRentalTravelPreferenceMutationHookResult,
  ) {
    if (!this.carRentalPreferencesStore.isViewingExistingMembership) {
      return
    }
    const {
      carMembership,
      carRentalPreferencesValidator,
      carMembershipToggle,
      carMembershipRemoveToggle,
    } = this.carRentalPreferencesStore
    const membership = carRentalPreferencesValidator.values.membership as GazooMembership[]
    const filteredMemberships = membership.filter(
      (item) => item.vendorCode !== carMembership.vendorCode,
    )

    this.handleFieldChange('membership')(filteredMemberships)
    carMembershipRemoveToggle.handleClose()

    const input = {
      carClassWithNone: carRentalPreferencesValidator.values.carClass || 'NONE',
      membership: carRentalPreferencesValidator.values.membership,
      specialRequest: carRentalPreferencesValidator.values.specialRequest,
    }

    await this.travelPreferencesService.saveCarRentalPreferences({
      input,
      handleUpdate,
      handleClose: carMembershipToggle.handleClose,
    })
    this.carRentalPreferencesStore.resetCarMembership()
    this.carRentalPreferencesStore.setIsEditExistingProgramMode(false)
  }

  async fetchAvailableCarVendors() {
    this.carRentalPreferencesStore.setIsCarAvailableVendorsLoading(true)
    const availableVendors = await this.travelPreferencesService.fetchAvailableVendors(
      VendorTravelVertical.Carrental,
    )

    this.carRentalPreferencesStore.setAvailableCarVendors(
      availableVendors?.availableCarRentalVendors || null,
    )
    this.carRentalPreferencesStore.setIsCarAvailableVendorsLoading(false)
  }

  async fetchCarRentalSpecialRequests() {
    this.carRentalPreferencesStore.setIsAvailableCarRentalSpecialRequestsLoading(true)
    const specialRequests = await this.travelPreferencesService.fetchAvailableSpecialRequets(
      VendorTravelVertical.Carrental,
    )

    this.carRentalPreferencesStore.setCarRentalAvailableSpecialRequests(
      specialRequests?.carRentalSpecialRequests || null,
    )
    this.carRentalPreferencesStore.setIsAvailableCarRentalSpecialRequestsLoading(false)
  }
}
