import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { UnusedTicketsModalHeaderDesktop } from './desktop'
import { UnusedTicketsModalHeaderMobile } from './mobile'

export function HeaderLayout({ isWideSettingsModal, onClose }: Props) {
  return (
    <MediaLayout
      mobileSlot={<UnusedTicketsModalHeaderMobile />}
      desktopSlot={
        <UnusedTicketsModalHeaderDesktop
          onClose={onClose}
          isWideSettingsModal={isWideSettingsModal}
        />
      }
    />
  )
}
