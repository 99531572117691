import type { GetAlternatePolicyTripsQuery } from '@fiji/graphql/types'
import type { MoneyValueObject, PolicyValueObject, RateValueObject } from '@etta/core/value-objects'
import type { AlternateFlightTripValueObject } from '../../core/value-objects/alternate-flight-trip.value-object'

export function mapFlightTripsToValueObject(
  input: GQLFlightTrips,
): AlternateFlightTripValueObject[] {
  return input.map(mapFlightTripToValueObject)
}

function mapFlightTripToValueObject(input: GQLFlightTrips[number]): AlternateFlightTripValueObject {
  return {
    tripId: input.tripId,
    flightLegs: input.flightLegs.map(mapFlightLegToValueObject),
  }
}

function mapFlightLegToValueObject(input: GQLFlightLeg): FlightLeg {
  return {
    legId: input.legId,
    daysInBetween: input.daysInBetween,
    stops: input.stops,
    origin: input.origin,
    destination: input.destination,
    departureDate: input.departureDate,
    arrivalDate: input.arrivalDate,
    hasTechnicalStop: input.hasTechnicalStop,
    isSelectable: input.isSelectable,
    isUnusedTicketApplicable: input.isUnusedTicketApplicable,
    flightTime: input.flightTime,
    totalTime: input.totalTime,
    policy: mapToPolicyValueObject(input.policy),
    rate: input.rate ? mapToRateValueObject(input.rate) : undefined,
    distance: input.distance
      ? { units: input.distance.units || undefined, value: input.distance.value }
      : undefined,
    segments: input.segments.map(mapToSearchFlightLegValueObject),
    fares: input.fares.map(mapToFlightFareValueObject),
    connectionInfo: input.connectionInfo,
  }
}

function mapToSearchFlightLegValueObject(input: GQLSegment): Segment {
  return {
    id: input.id,
    arrivalDate: input.arrivalDate,
    departureDate: input.departureDate,
    percentageOnTime:
      typeof input.percentageOnTime === 'number' ? input.percentageOnTime : undefined,
    flightNumber: input.flightNumber,
    isSeatMapAvailable: input.isSeatMapAvailable,
    isSeatSelectable: input.isSeatSelectable,
    preferredBadgeText: input.preferredBadgeText || undefined,
    isPreferredAny: input.isPreferredAny,
    isDepartAirportDifferentFromConnecting: !!input.isDepartAirportDifferentFromConnecting,
    isInstantPurchaseCarrier: !!input.isInstantPurchaseCarrier,
    flightTime: input.flightTime,
    origin: mapToFlightLegSubSegmentAirportValueObject(input.origin),
    destination: mapToFlightLegSubSegmentAirportValueObject(input.destination),
    carrier: input.carrier ? mapToFlightCarrierValueObject(input.carrier) : undefined,
    amenities: mapToAmenityValueObjects(input.amenities),
    preference: mapToPreferenceValueObject(input.preference),
    aircraft: input.aircraft
      ? {
          code: input.aircraft.code,
          name: input.aircraft.name || undefined,
        }
      : undefined,
    codeshareCarrier: {
      code: input.codeshareCarrier.code || undefined,
      name: input.codeshareCarrier.name || undefined,
    },
  }
}

function mapToFlightFareValueObject(input: GQLFare): Fare {
  return {
    tripId: input.tripId || undefined,
    restriction: input.restriction,
    isRefundable: !!input.isRefundable,
    isWebFare: !!input.isWebFare,
    showOutOfPolicy: !!input.showOutOfPolicy,
    isNDCFare: !!input.isNDCFare,
    providerAttributesStr: input.providerAttributesStr || undefined,
    isSelectable: !!input.isSelectable,
    isUnusedTicketApplicable: !!input.isUnusedTicketApplicable,
    fareLabel: input.fareLabel || undefined,
    tier: { id: input.tier.id, name: input.tier.name || undefined },
    total: mapToRateValueObject(input.total),
    segments: input.segments ? input.segments.map(mapToFlightFareSegmentValueObject) : undefined,
    policy: input.policy ? mapToPolicyValueObject(input.policy) : undefined,
    rulesAndRestrictions: input.rulesAndRestrictions
      ? input.rulesAndRestrictions.map(mapToRuleAndRestrictionValueObject)
      : undefined,
    unusedTickets: mapToUnusedTicketsValueObject(input.unusedTickets),
  }
}

function mapToPolicyValueObject(input: GQLPolicy): PolicyValueObject {
  return input.isInPolicy
    ? { isInPolicy: true }
    : {
        isInPolicy: false,
        outOfPolicyData: input.outOfPolicyData,
        outOfPolicyReasons: input.outOfPolicyReasons,
      }
}

function mapToRateValueObject(rate: NonNullable<GQLRate>): RateValueObject {
  const mapMoney = ({
    amount,
    currency,
  }: {
    amount: number
    currency: string
  }): MoneyValueObject => ({
    amount: amount,
    currency: currency || 'USD',
  })

  return {
    primary: mapMoney(rate.primary),
    secondary: rate.secondary ? mapMoney(rate.secondary) : undefined,
  }
}

function mapToFlightLegSubSegmentAirportValueObject(
  input: GQLSegmentAirport,
): FightLegSubSegmentAirport {
  return {
    ...input,
    terminal: input.terminal || undefined,
  }
}

function mapToFlightCarrierValueObject(input: GQLSegmentCarrier): FlightLegSubSegmentCarrier {
  return {
    id: input.id,
    name: input.name,
    logoImageUrl: input?.logoImageUrl || undefined,
    logoRectangleImageUrl: input?.logoRectangleImageUrl || undefined,
    imageUrl: input?.imageUrl || undefined,
  }
}

function mapToAmenityValueObjects(input: GQLSegmentAmenity): FlightLegSubSegmentAmenity {
  if (!input) {
    return undefined
  }

  if (input.length === 0) {
    return undefined
  }
  return input
}

function mapToPreferenceValueObject(input: GQLSegmentPreference): FlightLegSubSegmentPreference {
  if (!input) {
    return undefined
  }
  return {
    code: input.code || undefined,
    highlight: input.highlight || undefined,
    ownerType: input.ownerType || undefined,
    ownerName: input.ownerName || undefined,
    tooltipText: input.tooltipText || undefined,
    customBadge: {
      name: input.customBadge.color || undefined,
      color: input.customBadge.color || undefined,
    },
  }
}

function mapToFlightFareSegmentValueObject(input: GQLFareSegment): FareSegment {
  return {
    segmentId: input.segmentId,
    serviceClass: input.serviceClass,
    bookingCode: input.bookingCode,
    basisCode: input.basisCode || undefined,
    displayServiceClass: input.displayServiceClass || undefined,
    privateCode: input.privateCode || undefined,
  }
}

function mapToRuleAndRestrictionValueObject(
  input: GQLFareRulesAndRestriction,
): FareRulesAndRestriction {
  return {
    id: input.id,
    type: input.type,
    rulesAndRestriction: input.rulesAndRestriction,
    penaltyFee: input.penaltyFee
      ? input.penaltyFee.map((pf) => mapToRateValueObject(pf!))
      : undefined,
  }
}

function mapToUnusedTicketsValueObject(input: GQLFareUnusedTickets): FareUnusedTickets {
  if (!input) {
    return undefined
  }

  const max = input.max ? mapToRateValueObject(input.max) : undefined
  const min = input.min ? mapToRateValueObject(input.min) : undefined
  const unusedTicket = input.unusedTicket
    ? {
        validated: mapToValidatedUnusedTicketsValueObject(input.unusedTicket.validated),
        unvalidated: mapToUnvalidatedUnusedTicketsValueObject(input.unusedTicket.unvalidated),
      }
    : undefined

  if (!max && !min && !unusedTicket) {
    return undefined
  }

  return {
    max,
    min,
    unusedTicket,
  }
}

function mapToUnusedTicket(
  input:
    | NonNullable<GQLFareUnusedTicket['validated']>
    | NonNullable<GQLFareUnusedTicket['unvalidated']>,
) {
  return {
    original: input.original ? mapToRateValueObject(input.original) : undefined,
    penalty: input.penalty ? mapToRateValueObject(input.penalty) : undefined,
    newTicket: input.newTicket ? mapToRateValueObject(input.newTicket) : undefined,
    residual: input.residual ? mapToRateValueObject(input.residual) : undefined,
    addCollect: input.addCollect ? mapToRateValueObject(input.addCollect) : undefined,
    forfeiture: input.forfeiture ? mapToRateValueObject(input.forfeiture) : undefined,
    total: mapToRateValueObject(input.total),
  }
}

function mapToValidatedUnusedTicketsValueObject(input: GQLFareUnusedTicket['validated']) {
  if (!input) {
    return undefined
  }

  return {
    ...mapToUnusedTicket(input),
    exchangedTicket: mapToRateValueObject(input.exchangedTicket),
  }
}

function mapToUnvalidatedUnusedTicketsValueObject(input: GQLFareUnusedTicket['unvalidated']) {
  if (!input) {
    return undefined
  }

  return {
    ...mapToUnusedTicket(input),
    exchangedTicket: input.exchangedTicket
      ? mapToRateValueObject(input.exchangedTicket)
      : undefined,
  }
}

type FlightLeg = AlternateFlightTripValueObject['flightLegs'][number]
type Segment = FlightLeg['segments'][number]
type FightLegSubSegmentAirport = Segment['origin']
type FlightLegSubSegmentCarrier = Segment['carrier']
type FlightLegSubSegmentAmenity = Segment['amenities']
type FlightLegSubSegmentPreference = Segment['preference']

type Fare = FlightLeg['fares'][number]
type FareUnusedTickets = Fare['unusedTickets']
type FareSegment = NonNullable<Fare['segments']>[number]
type FareRulesAndRestriction = NonNullable<Fare['rulesAndRestrictions']>[number]

type GQLFlightTrips = Extract<
  GetAlternatePolicyTripsQuery['getAlternatePolicyTrips'],
  { __typename: 'GetAlternatePolicyTripsResponse' }
>['policyTrips']

type GQLFlightLeg = GQLFlightTrips[number]['flightLegs'][number]
type GQLSegment = GQLFlightLeg['segments'][number]
type GQLSegmentAirport = GQLSegment['origin']
type GQLSegmentCarrier = NonNullable<GQLSegment['carrier']>
type GQLSegmentAmenity = GQLSegment['amenities']
type GQLSegmentPreference = GQLSegment['preference']

type GQLFare = GQLFlightLeg['fares'][number]
type GQLFareSegment = NonNullable<GQLFare['segments']>[number]
type GQLFareRulesAndRestriction = NonNullable<GQLFare['rulesAndRestrictions']>[number]
type GQLFareUnusedTickets = GQLFare['unusedTickets']

type GQLFareUnusedTicket = NonNullable<NonNullable<GQLFareUnusedTickets>['unusedTicket']>
type GQLPolicy = GQLFlightLeg['policy']
type GQLRate = GQLFlightLeg['rate']
