import styled from 'styled-components'

import type { HeaderColors } from '@etta/ui/header'
import { Separator } from '@etta/ui/separator'
import { Modal } from '@etta/ui/modal'

export const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const ModalHeader = styled.div<{ color: HeaderColors }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 32px;
  background-color: ${(props) => props.theme.colors[props.color]};
`

export const HeadingWrapper = styled.div<{ color: HeaderColors }>`
  background-color: ${(props) => props.theme.colors[props.color]};
  padding-left: 32px;
  padding-right: 32px;
`

export const HeadingSeparator = styled(Separator)`
  opacity: 0.3;
`

export const Image = styled.img`
  height: 180px;
  width: auto;
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 62px 0;
`

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 0 32px 50px 32px;
  background-color: ${(props) => props.theme.colors.background};
`

export const TravelerWrapper = styled.div`
  margin-top: -22px;
`

export const ModalHeaderWrapper = styled(Modal.Header)`
  top: 0;
`
