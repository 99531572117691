import type { GetHotelsQueryVariables } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { SearchBy } from '@fiji/types'
import type { GetHotelNamesProps } from '../types'

export function getHotelNamesSearchInput({
  queryParams,
  hotelResultId,
  sortBy,
  offset = 0,
  pageSize,
  deviceClass,
  searchBy = SearchBy.Address,
  filters,
  hotelName,
}: GetHotelNamesProps): GetHotelsQueryVariables {
  return {
    latitude: queryParams.location?.latitude!,
    longitude: queryParams.location?.longitude!,
    distance: filters?.distance || queryParams.hotelFilters?.initialDistance || 0,
    checkIn: dateFormat(queryParams.checkInDate!, 'y-MM-dd'),
    checkOut: dateFormat(queryParams.checkOutDate!, 'y-MM-dd'),
    guests: 1,
    airportCode: queryParams.location?.airportCode || '',
    offset,
    locationName: queryParams.location?.name,
    first: pageSize,
    groupId: filters?.groupId,
    hideSoldOut: false,
    starRatings: filters?.starRatings,
    brandIds: filters?.brandIds,
    amenityIds: filters?.amenityIds,
    hotelName,
    hotelResultId,
    logsMetadata: null,
    nameSearch: false,
    stationCode: '',
    orderBy: sortBy || '',
    deviceClass: deviceClass,
    searchBy,
  }
}
