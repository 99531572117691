import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'

function makeDefaultSetting(isRendered: boolean | undefined = true) {
  return {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: isRendered,
    isRenderedOnActivationPage: false,
  }
}
export const PersonalInformationFieldsSettings = {
  title: makeDefaultSetting(),
  firstName: makeDefaultSetting(),
  middleName: makeDefaultSetting(),
  lastName: makeDefaultSetting(),
  suffix: makeDefaultSetting(),
  gender: makeDefaultSetting(),
  dateOfBirth: makeDefaultSetting(),
  email: makeDefaultSetting(),
  username: makeDefaultSetting(),
  profilePin: makeDefaultSetting(),
  homePhone: makeDefaultSetting(),
  homeEmail: makeDefaultSetting(undefined),
  vipIndicator: makeDefaultSetting(),
}

export const EmployeeInformationFieldsSettings = {
  employeeType: makeDefaultSetting(),
  employeeID: makeDefaultSetting(),
  employeeStatus: makeDefaultSetting(),
  jobTitle: makeDefaultSetting(),
  jobLevel: makeDefaultSetting(),
  managerID: makeDefaultSetting(),
  expenseApproverName: makeDefaultSetting(),
  travelApproverID: makeDefaultSetting(),
  costCenter: makeDefaultSetting(),
  departmentName: makeDefaultSetting(),
  departmentCode: makeDefaultSetting(),
  divisionCode: makeDefaultSetting(),
  businessUnit: makeDefaultSetting(),
  companyName: makeDefaultSetting(),
  mis1: makeDefaultSetting(),
  mis2: makeDefaultSetting(),
  mis3: makeDefaultSetting(),
  mis4: makeDefaultSetting(),
  mis5: makeDefaultSetting(),
  mis6: makeDefaultSetting(),
  mis7: makeDefaultSetting(),
}

export const HomeAddressFieldsSettings = {
  addressBlock: makeDefaultSetting(),
  [AddressFields.Country]: makeDefaultSetting(),
  [AddressFields.Zip]: makeDefaultSetting(),
  [AddressFields.State]: makeDefaultSetting(),
  [AddressFields.Mailstop]: makeDefaultSetting(),
  [AddressFields.City]: makeDefaultSetting(),
  [AddressFields.Street1]: makeDefaultSetting(),
  [AddressFields.Street2]: makeDefaultSetting(),
}

export const BusinessAddressFieldsSettings = {
  addressBlock: makeDefaultSetting(),
  [AddressFields.Country]: makeDefaultSetting(),
  [AddressFields.Zip]: makeDefaultSetting(),
  [AddressFields.State]: makeDefaultSetting(),
  [AddressFields.Mailstop]: makeDefaultSetting(),
  [AddressFields.City]: makeDefaultSetting(),
  [AddressFields.Street1]: makeDefaultSetting(),
  [AddressFields.Street2]: makeDefaultSetting(),
}

export const BusinessContactFieldsSettings = {
  groupwareId: makeDefaultSetting(),
  officePhone: makeDefaultSetting(),
  mobilePhone: makeDefaultSetting(),
  faxPhone: makeDefaultSetting(),
  businessEmail: makeDefaultSetting(),
}
