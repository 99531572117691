import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const FooterCheckoutContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 14px;
`

export const FooterCheckoutWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoButton = styled(Button)`
  padding: 0 2px;
  min-width: unset;

  > div {
    margin: 0;
  }
`
