import { useEffect, useMemo } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { dateFormat, addSecondsToDate } from '@fiji/utils/dates'

export function useMobilityRideReviewDialog() {
  const {
    searchRideHailActions,
    searchRideHailStore,
    rideHailSearchLocationRefinementStore,
  } = useRideHailSearchContext()
  const {
    featureFlags: { isMobilityZoningEnabled },
  } = useFeatureFlags()

  useEffect(() => {
    if (isMobilityZoningEnabled && rideHailSearchLocationRefinementStore.selectedAccessPoint) {
      searchRideHailActions.loadSearchRideHailWithLocationOption()
    }
  }, [
    searchRideHailActions,
    rideHailSearchLocationRefinementStore.selectedAccessPoint,
    isMobilityZoningEnabled,
  ])

  const dropOffTime = useMemo(() => {
    const estimatedWaitingTime =
      searchRideHailStore.selectedSearchRideHail?.estimatedWaitingTime ?? 0
    const estimatedRideDuration =
      searchRideHailStore.selectedSearchRideHail?.estimatedRideDuration ?? 0

    return dateFormat(
      addSecondsToDate(new Date(), estimatedWaitingTime + estimatedRideDuration),
      'h:mm a',
    )
  }, [
    searchRideHailStore.selectedSearchRideHail?.estimatedWaitingTime,
    searchRideHailStore.selectedSearchRideHail?.estimatedRideDuration,
  ])

  return {
    dropOffTime,
    isLoading: searchRideHailStore.isLoading,
  }
}
