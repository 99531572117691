import styled from 'styled-components'

export const Content = styled.div``

export const PreferenceList = styled.div`
  padding: 0 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ShareTravelerContactBlock = styled.div`
  padding: 24px 24px 32px;
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`
