import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  border-radius: 14px;
  border: 1px solid ${(p) => p.theme.colors.error};
  background-color: ${(p) => p.theme.colors.white};
  padding: 16px 24px 16px 16px;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
`
export const BottomRow = styled.div<{ hasSearchButton: boolean }>`
  text-align: left;
  ${(p) =>
    p.hasSearchButton &&
    css`
      max-width: calc(100% - 192px);
    `}
`

export const SearchButton = styled(Button)`
  padding: 6px 0;
  min-width: 192px;
`
