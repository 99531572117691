import { useEffect, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'react-use'
import { useTranslation } from 'react-i18next'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { useSeatTitle } from './use-seat-title'
import { useSubTitle } from './use-sub-title'
import { BlockUnavailable, BlockContinue, BlockDefault } from './blocks'

const unavailableBlockTitleKey = 'AirSeatMap.SeatTitle.Unavailable'
const unavailableBlockSubTitleKey = 'AirSeatMap.Details.ContactAgency'

type Props = {
  selectedSegmentId: string
  isSeatAssignFailed: boolean
  seatsForApprove: Array<SeatMapRowSeatValueObject>
  isSeatSelectionAvailable: boolean
  isSeatMapLoading?: boolean
  onChangeFooterHeight: (state: number) => void
  onSelect: (seat: SeatMapRowSeatValueObject) => void
  isLoading: boolean
  onSeatAssignFailedContinue: (seat: SeatMapRowSeatValueObject) => void
  onSeatSelectionUnavailableContinue: () => void
  finishAllSeatsSelection?: () => void
}

export function useSeatFooter({
  selectedSegmentId,
  isSeatAssignFailed,
  seatsForApprove,
  isSeatSelectionAvailable,
  isSeatMapLoading,
  onChangeFooterHeight,
  onSelect,
  isLoading,
  onSeatAssignFailedContinue,
  onSeatSelectionUnavailableContinue,
  finishAllSeatsSelection,
}: Props) {
  const previousSelectedSegmentId = usePrevious(selectedSegmentId)
  const lastSeatRef = useRef<null | SeatMapRowSeatValueObject>(null)
  const [lastIsSeatAssignFailed, setLastIsSeatAssignFailed] = useState(isSeatAssignFailed)
  const seat = useMemo(() => seatsForApprove[0] || lastSeatRef.current || {}, [seatsForApprove])
  const { isPaid } = seat
  const { t } = useTranslation()
  const seatTitle = useSeatTitle(seat)
  const seatSubTitle = useSubTitle(seat)
  const isContinueBlock = isPaid
  const [containerHeight, setContainerHeight] = useState<undefined | number>()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [animateFooter, setAnimateFooter] = useState(true)

  const isMobileFooterVisible = isSeatAssignFailed || seatsForApprove.length !== 0
  const isDesktopFooterVisible = isSeatSelectionAvailable && !isSeatMapLoading
  const isSeatSelected = useMemo(() => !!seatsForApprove.length, [seatsForApprove])
  const desktopFooterHeight = isDesktopFooterVisible ? containerRef.current?.offsetHeight || 0 : 0

  useEffect(() => {
    if (!(lastSeatRef.current && !seatsForApprove[0])) {
      lastSeatRef.current = seatsForApprove[0]
    }
    if (isSeatAssignFailed) {
      setLastIsSeatAssignFailed(true)
    }
    if (seatsForApprove[0] && !isSeatAssignFailed) {
      setLastIsSeatAssignFailed(false)
    }
    if (selectedSegmentId !== previousSelectedSegmentId) {
      setAnimateFooter(true)
    }
  }, [seatsForApprove, selectedSegmentId, previousSelectedSegmentId, isSeatAssignFailed])

  useEffect(() => {
    const container = containerRef.current

    if (!container) {
      return
    }

    if (!seatsForApprove.length) {
      onChangeFooterHeight(container.offsetHeight)
      setContainerHeight(0)
      return
    }
    onChangeFooterHeight(0)
    setContainerHeight(container.offsetHeight)
  }, [seatsForApprove, selectedSegmentId, previousSelectedSegmentId, onChangeFooterHeight])

  const footerBlockSlot = useMemo(() => {
    const onButtonClick = () => {
      onSelect(seat)
    }

    if (!isSeatSelectionAvailable) {
      return (
        <BlockUnavailable
          title={t(unavailableBlockTitleKey)}
          subTitle={t(unavailableBlockSubTitleKey)}
          onContinue={onSeatSelectionUnavailableContinue}
          isLoading={isLoading}
        />
      )
    }
    if (isSeatAssignFailed || lastIsSeatAssignFailed) {
      return (
        <BlockUnavailable
          title={t(unavailableBlockTitleKey)}
          subTitle={t(unavailableBlockSubTitleKey)}
          onContinue={() => onSeatAssignFailedContinue(seat)}
          isLoading={isLoading}
        />
      )
    }
    if (isContinueBlock) {
      return (
        <BlockContinue
          finishAllSeatsSelection={finishAllSeatsSelection}
          title={seatTitle}
          subTitle={seatSubTitle}
          onContinue={onButtonClick}
          isLoading={isLoading}
        />
      )
    }
    return (
      <BlockDefault
        finishAllSeatsSelection={finishAllSeatsSelection}
        title={seatTitle}
        isSeatSelected={isSeatSelected}
        seatNumber={seat.number}
        onSelect={onButtonClick}
        isLoading={isLoading}
      />
    )
  }, [
    onSelect,
    seat,
    isSeatSelectionAvailable,
    onSeatSelectionUnavailableContinue,
    onSeatAssignFailedContinue,
    isSeatAssignFailed,
    seatTitle,
    seatSubTitle,
    lastIsSeatAssignFailed,
    isContinueBlock,
    finishAllSeatsSelection,
    isLoading,
    t,
    isSeatSelected,
  ])

  return {
    containerHeight,
    animateFooter,
    isDesktopFooterVisible,
    isMobileFooterVisible,
    setAnimateFooter,
    containerRef,
    footerBlockSlot,
    desktopFooterHeight,
  }
}
