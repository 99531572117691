import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { initialBrandConfiguration } from '@fiji/store/user/initial-state'
import type { BrandConfiguration } from '@fiji/graphql/types'
import type { BrandConfigurationWithLoading } from './types'

const initialState: BrandConfigurationWithLoading = initialBrandConfiguration

const slice = createSlice({
  name: 'brand-configuration',
  initialState: {
    ...initialState,
  },
  reducers: {
    updateBrandConfiguration(state, action: PayloadAction<BrandConfiguration>) {
      return { ...state, ...action.payload }
    },
    updateBrandConfigurationLoading(state, action: PayloadAction<{ isLoading: boolean }>) {
      state.isLoading = action.payload.isLoading
    },
  },
})

export const brandConfigurationReducer = slice.reducer
export const { updateBrandConfiguration, updateBrandConfigurationLoading } = slice.actions
