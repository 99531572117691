import styled from 'styled-components'
import { buttonSmall, getKeyboardNavigationStyle } from '@fiji/style'
import { Icon as EttaIcon } from '@etta/ui/icon'

export const Text = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.colors.mainText};
`

export const Icon = styled(EttaIcon)``

export const Container = styled.button`
  width: 100%;
  padding: 12px 0;
  margin: 0;
  border: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  height: 64px;
  cursor: pointer;
  outline: none;
  position: relative;
  gap: 4px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:disabled {
    cursor: default;

    ${Text} {
      color: ${(props) => props.theme.colors.bodyText};
      opacity: 0.6;
    }
    ${Icon} {
      opacity: 0.6;
    }
  }
  ${getKeyboardNavigationStyle({ offset: -3, borderRadius: 10 })}
`
