import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import {
  LONDON_UNDERGROUND_OPERATOR,
  LONDON_UNDERGROUND_TUBE_URL,
  LONDON_UNDERGROUND_TUBE,
} from '@fiji/constants'
import type { CarrierInfo } from '../types'

export function useRailwayImages(segments: SearchRailLegSubSegmentEntity[]): CarrierInfo[] {
  return segments
    .reduce<CarrierInfo[]>((acc, segment) => {
      if (
        segment.transportationMode === RailTransportationModeEnum.Train ||
        segment.transportationMode === RailTransportationModeEnum.Unspecified
      ) {
        if (!acc.find((item) => item.name === segment.carrier?.name)) {
          if (segment.carrier?.name === LONDON_UNDERGROUND_OPERATOR) {
            acc.push({
              logoUrl: LONDON_UNDERGROUND_TUBE_URL,
              name: LONDON_UNDERGROUND_TUBE,
            })

            return acc
          }

          acc.push({
            logoUrl: segment.carrier?.logoImageUrl || '',
            name: segment.carrier?.name || '',
          })

          return acc
        }
      }

      return acc
    }, [])
    .slice(0, 3)
}
