import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

type Props = {
  isVisible: boolean
}

export function DisabledMembershipWarning({ isVisible }: Props) {
  const { t } = useTranslation()

  if (!isVisible) {
    return <></>
  }
  return (
    <Block marginTop={12}>
      <Text variant="footnoteMedium" color="mainText">
        {t('RailtMembershipUnavailable')}
      </Text>
    </Block>
  )
}
