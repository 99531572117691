import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SupportInformation } from '@fiji/graphql/types'

const initialState: SupportInformation = {
  phoneNumbers: [],
}

const slice = createSlice({
  name: 'support-information-id',
  initialState,
  reducers: {
    updateSupportInformation(state, action: PayloadAction<SupportInformation>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const supportInformationReducer = slice.reducer
export const { updateSupportInformation } = slice.actions
