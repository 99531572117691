import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { formatRate } from '@fiji/utils/money/format-rate'
import { UnusedTicketBreakdownTooltipLayout } from '@etta/components/unused-ticket-breakdown-tooltip'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { SpaceBetween } from '../../../rtp-price-summary-styled'
import { SectionDescription } from '../../payable-sections-styled'

type Props = {
  totalFlightTripCosts: {
    total: string
    base: string
    feesAndTaxes: string
  }
  hasFlightTripCost: boolean
}

export function FlightsSection({ totalFlightTripCosts, hasFlightTripCost }: Props) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const { reviewTripStore } = useReviewTripContext()
  const { postBookingTripStore } = usePostBookingContext()
  const { validatedUnusedTickets } = reviewTripStore
  const i18nBasePath = 'ReviewTrip.PriceSummary'

  if (!hasFlightTripCost) {
    return null
  }

  const {
    airTaxes: { isGST },
  } = reviewTripStore
  const taxBreakDownAvailable = featureFlags.isRetailShoppingUIEnabled && isGST

  const { mainCost: unusedTicketCost } = formatRate(reviewTripStore.unusedTicketsRate, {
    morpheme: 'none',
  })

  const totalFlightTripCost = taxBreakDownAvailable
    ? totalFlightTripCosts.base
    : totalFlightTripCosts.total

  return (
    <>
      <Block marginTop={8} />
      <SpaceBetween>
        <SectionDescription aria-label={t(i18nBasePath + '.Flights')}>
          {t(i18nBasePath + '.Flights')}
        </SectionDescription>
        <SectionDescription aria-label={totalFlightTripCost}>
          {totalFlightTripCost}
        </SectionDescription>
      </SpaceBetween>
      {taxBreakDownAvailable && (
        <SpaceBetween>
          <SectionDescription aria-label={t(i18nBasePath + '.TaxesAndFeesGST')}>
            {t(i18nBasePath + '.TaxesAndFeesGST')}
          </SectionDescription>
          <SectionDescription aria-label={totalFlightTripCosts.feesAndTaxes}>
            {totalFlightTripCosts.feesAndTaxes}
          </SectionDescription>
        </SpaceBetween>
      )}
      {reviewTripStore.isUnusedTicketApplied && (
        <SpaceBetween>
          <Block isFlexBlock>
            <Text
              aria-label={t(i18nBasePath + '.UnusedTickets')}
              variant="footnoteStrong"
              color="mainText">
              {t(i18nBasePath + '.UnusedTickets')}
            </Text>
            &nbsp;
            {!!validatedUnusedTickets && (
              <UnusedTicketBreakdownTooltipLayout
                isUnusedTicketValidated={!!validatedUnusedTickets}
                isRTP
                isPostBooking={!!postBookingTripStore.queryParams.bookingId}
                addCollect={validatedUnusedTickets.addCollect}
                forfeiture={validatedUnusedTickets.forfeiture}
                unusedTicketPrice={validatedUnusedTickets.original}
                newTicketPrice={validatedUnusedTickets.newTicket}
                penalty={validatedUnusedTickets.penalty}
                unusedTicketRate={reviewTripStore.unusedTicketOriginalPrice}
                toolTipIconSlot={<Icon name="infoPWA" size={'small'} />}
              />
            )}
          </Block>

          <Text aria-label={unusedTicketCost} variant="footnoteStrong" color="mainText">
            {' '}
            - {unusedTicketCost}
          </Text>
        </SpaceBetween>
      )}
    </>
  )
}
