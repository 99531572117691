import { MobileLoadingPlace } from '../mobile-place'

const PREFILL = Array.from(Array(10).fill(0))

export function LocationsLoadingMobileSkeleton() {
  return (
    <div data-tracking-id="car-rental-locations-loading">
      {PREFILL.flatMap((_, i) => (
        <MobileLoadingPlace key={'loading-state-' + i} isOpen isLoading />
      ))}
    </div>
  )
}
