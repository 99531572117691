import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  margin: 0 8px 9px 0;
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export const Image = styled.img`
  width: 111px;
  height: 58px;
`

export const SecondImage = styled.img`
  position: absolute;
  width: 69%;
  height: auto;
  transform: translateY(-50px);
`
