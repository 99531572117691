import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { Loader } from './emission-deviation-styled'
import { EmissionDeviationComponent } from './emission-deviation-component'
import { EmissionDeviationComponentNew } from './emission-deviation-component-new'

type EmissionDeviationProps = {
  tonnesOfEmissions?: number
  averageEmissionsTonnes?: number
  loading: boolean
}

export function EmissionDeviation({
  tonnesOfEmissions,
  averageEmissionsTonnes,
  loading,
}: EmissionDeviationProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()
  if (loading) {
    return <Loader />
  }

  if (!tonnesOfEmissions || !averageEmissionsTonnes) {
    return null
  }

  if (isNewEmissionsBrandingEnabled) {
    return (
      <EmissionDeviationComponentNew
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageEmissionsTonnes}
      />
    )
  }

  return (
    <EmissionDeviationComponent
      tonnesOfEmissions={tonnesOfEmissions}
      averageEmissionsTonnes={averageEmissionsTonnes}
    />
  )
}
