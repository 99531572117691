export default function OutletsAndUSBIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4743 40.3334V24.8205H12.8333L24.1153 3.66669V19.1795H29.7563L18.4743 40.3334Z"
        fill="currentColor"
      />
    </svg>
  )
}
