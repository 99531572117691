import { useTranslation } from 'react-i18next'
import type { HotelFragment, Segment, HotelSegment } from '@fiji/graphql/types'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'

type Props = {
  hotelFragment?: HotelFragment | null
  hotelSegment?: Segment | null
}

export function useHotelCostLabelAndCost({ hotelFragment, hotelSegment }: Props) {
  const { t } = useTranslation()

  const segment = hotelFragment || hotelSegment?.hotel
  if (!segment) {
    return
  }

  const totalCost = (segment as HotelFragment).totalCost
  const baseRate = (segment as HotelSegment).baseRate

  const noOfNights = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    segment.checkOut || '',
    segment.checkIn || '',
  )

  const label = `${noOfNights} ${t('PaymentSummary.Night')} @ ${segment.name}`

  return {
    label,
    cost: baseRate || (totalCost ? { primary: totalCost } : null),
  }
}
