import styled from 'styled-components'
import { captionStrongCaps, regularListItem, subHeadMedium } from '@fiji/style'

export const Title = styled.span`
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 4px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${captionStrongCaps};
`

export const ListItem = styled.li<{
  active: boolean
}>`
  cursor: pointer;
  position: relative;
  padding: 2px 0;
  margin: 0;
  ${subHeadMedium};
  color: ${(p) => p.theme.primaryColor};

  &:hover {
    background-color: ${(p) => p.theme.inputBorderColor};
  }

  ${regularListItem};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    left: 46px;
    border-bottom: 1px solid ${(p) => p.theme.colors.background1};
  }
`
