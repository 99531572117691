import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace TravelPolicyErrors {
  export const TravelPolicyResponse = createErrorClass('Failed travel policy')
  export const TravelPolicyUnexpectedError = createErrorClass('Processed travel policy')
}

export type TravelPolicyErrorsInstances = InstanceType<
  typeof TravelPolicyErrors[keyof typeof TravelPolicyErrors]
>
