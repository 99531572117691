import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { footnoteMedium } from '@fiji/style'
import { Screen } from '@etta/ui/screen'

export const IntroText = styled(Text)`
  color: ${(props) => props.theme.colors.mainText};
  margin-bottom: 16px;
  margin-left: 20px;
  margin-right: 20px;
`

export const FeatureContainer = styled.div`
  border-radius: 14px;
  background: ${(props) => props.theme.colors.white};
  margin-left: 20px;
  margin-right: 20px;
  padding: 22px 16px 32px 16px;
`

export const StepContainer = styled.ul`
  padding-inline-start: 16px;
  display: content;
  align-items: center;
  &:last-child {
    margin-bottom: 16px;
  }
`

export const StepItem = styled.li`
  list-style: decimal;
  ${footnoteMedium};
`

export const Item = styled(Text)`
  display: flex;
`
export const ScreenContainer = styled(Screen)`
  overflow-y: auto;
  overflow-x: hidden;
`

export const UberQrCodeWrapper = styled.div`
  margin: 32px 20px 10px 20px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  border-radius: 14px;
`
