import type { Coords } from '@etta/modules/etta-map/core/coords'

export function createCoordsFromGoogleMapPolygon(encodedPolygon: string): Coords[] {
  return google.maps.geometry.encoding.decodePath(encodedPolygon).map(({ lat, lng }) => {
    return {
      lat: lat(),
      lng: lng(),
    }
  })
}
