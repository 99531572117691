import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  &:first-child {
    margin-top: 16px;
  }
`

const TicketCircle = css`
  position: absolute;
  content: '';
  left: -4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.background};
`

export const FareSeparator = styled.div`
  position: relative;
  width: 1px;
  height: auto;
  border-right: 1px solid ${(p) => p.theme.colors.borderLight};

  &::before {
    ${TicketCircle}
    top: -5px;
  }

  &::after {
    ${TicketCircle}
    bottom: -5px;
  }
`
