import type { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { observer } from 'mobx-react-lite'
import * as themes from '@fiji/themes'
import { brandConfigurationSelector } from '@fiji/store/brand-configuration'
import { appMode } from '@fiji/modes'
import { useThemeConfigurationContext } from '@etta/modules/theme'
import { getUsedTheme } from './get-used-theme'

type Props = {
  children: ReactNode
}

type ThemeItem = keyof typeof themes
const ettaThemeList: (ThemeItem | string)[] = ['kan', 'ehismb']

export const Theme = observer(function Theme({ children }: Props) {
  const { themeConfigurationStore } = useThemeConfigurationContext()
  const { isThemeEnabled, theme: brandTheme } = useSelector(brandConfigurationSelector)

  const isFijiThemeEnabled = isThemeEnabled && appMode.isFijiAll
  const isEttaThemeEnabled =
    isThemeEnabled &&
    (appMode.isEttaDesktop || appMode.isEttaTablet) &&
    ettaThemeList.includes(brandTheme)
  const isMobilityThemeEnabled = appMode.isMobility

  const theme =
    isFijiThemeEnabled || isEttaThemeEnabled || isMobilityThemeEnabled
      ? getUsedTheme(brandTheme)
      : themes[themeConfigurationStore.theme]

  // Set theme color start
  const meta = document.createElement('meta')
  meta.setAttribute('name', 'theme-color')
  meta.content = theme.primaryColor
  document.getElementsByTagName('head')[0].appendChild(meta)
  // Set theme color end

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
})
