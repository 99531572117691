import { DatePickerModal } from '@etta/ui/date-picker-modal'
import { InputButton } from '@etta/ui/input-button'
import { InputButtonGroup } from '@etta/ui/input-button-group'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { LayoutProps } from '../../types'
import { useDateSectionMobile } from './use-date-section-mobile'

export function DateSectionMobile({
  checkInDate,
  checkOutDate,
  onDateChange,
  validationErrors,
}: LayoutProps) {
  const {
    handleClose: handleCloseDatePicker,
    handleOpen: handleOpenDatePicker,
    isOpen: isDatePickerOpen,
  } = useTogglePopup()

  const {
    checkIn,
    checkOut,
    checkInDateLabel,
    checkOutDateLabel,
    checkInAreaLabel,
    checkOutAreaLabel,
  } = useDateSectionMobile({
    checkInDate,
    checkOutDate,
  })

  const errorMessage = validationErrors.checkinDate ?? validationErrors.checkoutDate

  return (
    <>
      <InputButtonGroup hasError={!!errorMessage} errorMessage={errorMessage}>
        <InputButton
          icon="calendarPWA"
          onClick={handleOpenDatePicker}
          aria-label={checkInAreaLabel}
          value={checkIn}
          label={checkInDateLabel}
          placeholder={'Select'}
        />
        <InputButton
          icon="calendarPWA"
          onClick={handleOpenDatePicker}
          aria-label={checkOutAreaLabel}
          value={checkOut}
          label={checkOutDateLabel}
          placeholder={'Select'}
        />
      </InputButtonGroup>

      <DatePickerModal
        date={checkInDate}
        dateEnd={checkOutDate}
        isRange
        onSelect={onDateChange}
        isOpen={isDatePickerOpen}
        onClose={handleCloseDatePicker}
        withConfirmOneWayButton={false}
        minDate={new Date()}
      />
    </>
  )
}
