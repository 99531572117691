import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useUserContext } from '@etta/modules/user'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import type { ItineraryPoint } from '@fiji/hooks/itinerary/use-itinerary-points'
import { useItineraryPoints } from '@fiji/hooks/itinerary/use-itinerary-points'
import { useAutoclosePopup } from '@fiji/hooks/use-autoclose-popup'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ROUTES } from '@fiji/routes'
import { TripItemTitle } from './trip-item-title'
import type { TripCartListItemProps } from './types'
import { getCarItems, getFlightItem, getHotelItems, getTrainItem } from './utils'

const getTripName = ({ airportCode, city, countryCode }: ItineraryPoint) =>
  airportCode ? `${city}, ${countryCode} (${airportCode})` : `${city}, ${countryCode}`

export function useTripCart() {
  const { activeTripStore, activeTripActions } = useReviewTripContext()
  const { handleSetIsTripExpired } = activeTripActions
  const { userStore } = useUserContext()
  const typeCheck = SegmentTypeCheckActions.getInstance()
  const { airSearchActions } = useAirSearchContext()
  const history = useHistory()
  const { isOpen, handleOpen, handleClose } = useTogglePopup()
  const { locale } = userStore
  const {
    activeTripId,
    tripName,
    segments,
    startDate,
    endDate,
    expiredAt,
    currentlyReactivatedID,
  } = activeTripStore

  const { popupRef, toggleRef } = useAutoclosePopup({
    handleClose,
  })

  const lastItineraryPoint = useItineraryPoints(segments)
  const isCustomTripName = lastItineraryPoint && tripName !== getTripName(lastItineraryPoint)
  const listCityLabel = tripName
  const datesLabel = useDateRangeTitle(startDate, endDate)
  const listItems = useMemo<TripCartListItemProps[]>(() => {
    const cars = segments.filter(typeCheck.isCarRentalSegment)
    const flightSegments = segments.filter(typeCheck.isFlightSegment)
    const hotels = segments.filter(typeCheck.isHotelSegment)
    const trainSegments = segments.filter(typeCheck.isTrainSegment)
    const items: TripCartListItemProps[] = []
    if (flightSegments.length && flightSegments[0]) {
      const isMultiCity = !flightSegments[0].isRoundTripLeg && flightSegments.length > 1
      flightSegments.every((flightLeg, index) => {
        if (!isMultiCity && index > 0) {
          return false
        }
        const { type, infoLabel, price, departureCode, destinationCode } = getFlightItem(
          flightLeg,
          locale,
        )

        items.push({
          type,
          infoLabel,
          price,
          titleLabel: (
            <TripItemTitle departureCode={departureCode} destinationCode={destinationCode} />
          ),
        })
        return true
      })
    }

    if (trainSegments.length && trainSegments[0]) {
      const trainLeg = trainSegments[0]
      const startDate = trainLeg.arrivalDateTime
      const endDate = trainSegments[trainSegments.length - 1].departureDateTime
      const { type, infoLabel, price, departureCode, destinationCode } = getTrainItem(
        trainLeg,
        locale,
        startDate,
        endDate,
      )

      const isRoundTrip = trainSegments.length > 1

      items.push({
        type,
        infoLabel,
        price,
        titleLabel: (
          <TripItemTitle
            isRoundTrip={isRoundTrip}
            departureCode={departureCode}
            destinationCode={destinationCode}
          />
        ),
      })
    }

    if (cars.length) {
      const carItems: TripCartListItemProps[] = getCarItems(cars, locale)
      items.push(...carItems)
    }
    if (hotels.length) {
      const hotelsItems: TripCartListItemProps[] = getHotelItems(hotels, locale)
      items.push(...hotelsItems)
    }

    return items
  }, [segments, typeCheck, locale])

  const isEmptyItinerary = !listItems.length

  const handleReview = () => {
    if (activeTripId) {
      airSearchActions.handleClearSearchId(activeTripId)
      handleClose()
      history.push(
        ROUTES.reviewTrip.main({
          itineraryId: activeTripId,
          bookingId: currentlyReactivatedID || undefined,
        }),
      )
    }
  }

  const handleOpenList = () => {
    if (!activeTripId) {
      return
    }
    handleOpen()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleReview()
    }
  }

  const onTripExpired = () => {
    handleSetIsTripExpired(true)
  }

  return {
    listItems,
    itemsAmount: listItems.length,
    isEmptyItinerary,
    expiredAt,
    itineraryId: activeTripId,
    isDisabled: !activeTripId,
    isListOpen: isOpen,
    handleOpenList,
    handleKeyDown,
    handleCloseList: handleClose,
    listCityLabel,
    handleReview,
    datesLabel,
    isCustomTripName,
    popupRef,
    toggleRef,
    onTripExpired,
  }
}
