import type { PropsWithChildren, FunctionComponent } from 'react'
import { Portal } from '@etta/ui/portal'
import {
  Container,
  Body,
  Footer,
  DialogContainerRoot,
  DialogContent,
  BackDrop,
} from './dialog-styles'
import { useDialog } from './use-dialog'
import type { DialogCustomSizeField, HorizontalDimensionStyle } from './types'
import { DialogHeader } from './dialog-header'
import { DialogAnimation } from './dialog-animation'

type Props = PropsWithChildren<{
  onClose: () => void
  title?: React.ReactNode
  isScrollable?: boolean
  isStayInDOM?: boolean
  'data-tracking-id'?: string
  customSize?: DialogCustomSizeField
  horizontalDimension?: HorizontalDimensionStyle
  isOpen?: boolean
  isAnimated?: boolean
  className?: string
  closeIcon?: React.ReactNode
}>

type CompoundComponents = {
  Header: typeof DialogHeader
  Body: typeof Body
  Footer: typeof Footer
}

export const Dialog: FunctionComponent<Props> & CompoundComponents = ({
  className,
  title,
  onClose,
  children,
  isScrollable = false,
  isStayInDOM = false,
  'data-tracking-id': dataTrackingId,
  customSize,
  horizontalDimension,
  isOpen = true,
  isAnimated = true,
  closeIcon,
}) => {
  const { focusLockContainerRef, isShown, onEntered, onExited } = useDialog({ isOpen, onClose })

  if (!isShown && !isStayInDOM) {
    return null
  }

  return (
    <Portal target="#modals-container">
      <Container ref={focusLockContainerRef} isShown={isShown} role={isShown ? 'dialog' : 'none'}>
        {isShown && <BackDrop onClick={onClose} aria-hidden />}
        <DialogAnimation
          isOpen={isOpen}
          onEntered={onEntered}
          onExited={onExited}
          isStayInDOM={isStayInDOM}
          isAnimated={isAnimated}>
          <DialogContainerRoot onClick={onClose} isScrollable={isScrollable}>
            <DialogContent
              className={className}
              data-tracking-id={dataTrackingId}
              customSize={customSize}
              horizontalDimension={horizontalDimension}
              onClick={(e) => e.stopPropagation()}>
              {title && (
                <DialogHeader
                  onClose={onClose}
                  title={title}
                  withTextVariant={isScrollable}
                  closeIcon={closeIcon}
                />
              )}
              {children}
            </DialogContent>
          </DialogContainerRoot>
        </DialogAnimation>
      </Container>
    </Portal>
  )
}

Dialog.Header = DialogHeader
Dialog.Body = Body
Dialog.Footer = Footer
