import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const SegmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledBlock = styled(Block)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 40px;
    height: 44px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.borderDark};
  }
  &:last-of-type {
    &:after {
      content: none;
    }
  }
`
