import { useTranslation } from 'react-i18next'
import type { LayoutProps } from '../types'
import { Container, Image, Score } from './geo-safe-scale-desktop-styled'
import { GeoSafeScaleSkeleton } from './geo-safe-scale-desktop-skeleton'
import { getScaleImage } from './get-scale-image'

const skeleton = <GeoSafeScaleSkeleton />

export function GeoSafeScaleDesktop({ scores, isLoading }: LayoutProps) {
  const { t } = useTranslation()

  if (isLoading || !scores) {
    return skeleton
  }
  const scaleImage = getScaleImage(scores)

  return (
    <Container>
      <Image
        src={scaleImage}
        alt={t('HotelSafetyCheck.GeoSafeScores.AriaLabel', { value: scores })}
        aria-hidden
      />
      <Score>{scores}</Score>
    </Container>
  )
}
