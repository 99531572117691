import styled from 'styled-components'
import type { IconColorPalette, IconSizes } from '../types'

import { getDimensionSize } from './get-dimension-size'

export const Container = styled.div<{
  size?: IconSizes
  iconColor?: IconColorPalette
}>`
  display: inline-flex;
  vertical-align: bottom;
  justify-content: center;
  align-items: center;

  width: ${(props) => getDimensionSize(props.size)}px;
  height: ${(props) => getDimensionSize(props.size)}px;
  min-width: ${(props) => getDimensionSize(props.size)}px;
  min-height: ${(props) => getDimensionSize(props.size)}px;
  color: ${(p) => (p.iconColor ? p.theme.colors[p.iconColor] : 'inherit')};
`
