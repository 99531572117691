import i18n from 'i18next'
import { CarRentalClassType } from '@fiji/graphql/types'

const i18nBasePathClass = 'TripReview.CarRentalSegment.Classes.'

const carClassesTranslations = {
  [CarRentalClassType.Mini]: i18nBasePathClass + 'Mini',
  [CarRentalClassType.MiniElite]: i18nBasePathClass + 'MiniElite',
  [CarRentalClassType.Economy]: i18nBasePathClass + 'Economy',
  [CarRentalClassType.EconomyElite]: i18nBasePathClass + 'EconomyElite',
  [CarRentalClassType.Compact]: i18nBasePathClass + 'Compact',
  [CarRentalClassType.CompactElite]: i18nBasePathClass + 'CompactElite',
  [CarRentalClassType.Intermediate]: i18nBasePathClass + 'Intermediate',
  [CarRentalClassType.IntermediateElite]: i18nBasePathClass + 'IntermediateElite',
  [CarRentalClassType.Standard]: i18nBasePathClass + 'Standard',
  [CarRentalClassType.StandardElite]: i18nBasePathClass + 'StandardElite',
  [CarRentalClassType.Fullsize]: i18nBasePathClass + 'FullSize',
  [CarRentalClassType.FullsizeElite]: i18nBasePathClass + 'FullSizeElite',
  [CarRentalClassType.Premium]: i18nBasePathClass + 'Premium',
  [CarRentalClassType.PremiumElite]: i18nBasePathClass + 'PremiumElite',
  [CarRentalClassType.Luxury]: i18nBasePathClass + 'Luxury',
  [CarRentalClassType.LuxuryElite]: i18nBasePathClass + 'LuxuryElite',
  [CarRentalClassType.Special]: i18nBasePathClass + 'Special',
  [CarRentalClassType.Oversize]: i18nBasePathClass + 'Oversize',
}

export function translateCarClass(carClass?: CarRentalClassType | null): string {
  if (!carClass) {
    return ''
  }
  const key = carClassesTranslations[carClass] || carClass
  return i18n.t(key) || carClass
}
