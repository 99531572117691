import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { TrainServiceActiveTab } from '@fiji/enums'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { Link } from '@etta/ui/link'
import type { LayoutProps } from '../../types'
import { useService } from '../../use-service'
import {
  Container,
  Header,
  Heading,
  Title,
  Footer,
  Price,
  InfoIconWrapper,
  FareAttributes,
  OutOfPolicy,
  RailCardContainer,
} from './rail-fare-card-mobile.styled'

const i18Base = 'RailDetails.Fare'

export function RailFareCardMobile({
  fare,
  fareConditions,
  railPolicies,
  activeTab,
  onSelect,
  fareClassLabel,
  onInfoIconClick,
  handleOpenPermittedStations,
}: LayoutProps) {
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const {
    price,
    fareName,
    fareAttributes,
    hasPermittedStations,
    permittedOriginStations,
    permittedDestinationStations,
  } = useService({ fare, fareConditions })

  const buttonText = t(i18Base + '.Buttons.Select')
  const buttonAriaLabel = t(i18Base + '.Buttons.SelectFareAria', { label: buttonText })

  const onPermittedStationsClick = handleOpenPermittedStations
    ? handleOpenPermittedStations
    : () => {}

  const isFirstClassOOPShown =
    activeTab === TrainServiceActiveTab.FirstClass &&
    featureFlags.isRailOutofPolicyEnabled &&
    !railPolicies?.isRailFirstClassAllowed
  const railCardName = fare?.appliedRailCards?.[0]

  return (
    <Container>
      <Header>
        <div role="region">
          <Heading>
            <Title>
              <Text variant="subHeadStrong">{fareName}</Text>
              {fareName && (
                <InfoIconWrapper onClick={() => onInfoIconClick(fareName)}>
                  <Icon size={16} name="infoPWA" />
                </InfoIconWrapper>
              )}
            </Title>
            <Price variant="headline" data-tracking-id="fare-price">
              {price}
            </Price>
          </Heading>
        </div>
      </Header>
      {isFirstClassOOPShown && (
        <OutOfPolicy>
          <Icon name="outOfPolicyPWA" />
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18Base + '.OutOfPolicy')}
          </Text>
        </OutOfPolicy>
      )}
      {railCardName && (
        <RailCardContainer>
          <Icon name="loyaltyCardPWA" color="success" />
          <Text color="bodyText" variant="footnoteMedium">
            {railCardName} {t(i18Base + '.Applied')}
          </Text>
        </RailCardContainer>
      )}
      {(!!fareAttributes?.length || hasPermittedStations) && (
        <FareAttributes>
          {fareAttributes?.map((item, index) => (
            <Block key={index} marginTop={4}>
              <Text variant="footnoteMedium" color="bodyText">
                {item}
              </Text>
            </Block>
          ))}
          {hasPermittedStations && (
            <Block marginTop={4}>
              <Text variant="footnoteMedium" color="bodyText" isInline={true}>
                {t(`${i18Base}.PermittedStations.Legend`)}
              </Text>
              <Link
                onClick={() =>
                  onPermittedStationsClick({
                    permittedOriginStations,
                    permittedDestinationStations,
                  })
                }
                size="small"
                data-tracking-id="view-permitted-stations"
              >
                {t(`${i18Base}.PermittedStations.Link`)}
              </Link>
            </Block>
          )}
        </FareAttributes>
      )}
      <Footer>
        <Text isBlock variant="footnoteMedium" color="bodyText">
          {fareClassLabel}
        </Text>
        <Button
          minWidth={10.8}
          onClick={onSelect}
          size="small"
          disabled={false}
          aria-label={buttonAriaLabel}
          data-tracking-id="add-rail-button"
        >
          {buttonText}
        </Button>
      </Footer>
    </Container>
  )
}
