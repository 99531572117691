import { getYearBounds } from '@etta/ui/swiping-time-date-picker/assets/get-year-bounds'
import type { SwipingTimePickerBars, SwipingPickerSubtype, YearBounds } from '../types'

export function getConstants(
  variant?: SwipingPickerSubtype,
  hourlyRange?: number,
  defaultYearBounds?: YearBounds,
) {
  const YEAR_BOUNDS = getYearBounds(new Date(), defaultYearBounds, variant)
  const START_YEAR = YEAR_BOUNDS.minYear
  const totalYears = YEAR_BOUNDS.maxYear - YEAR_BOUNDS.minYear + 1

  const range = hourlyRange ? hourlyRange : 12

  const MINUTE_GROUP = variant === 'only-min' ? 1 : 15
  const UNIT = {
    height: 6, // in rems
    hourRange: Array.from(Array(range), (_el, i) => i),
    minuteGroup: MINUTE_GROUP,
    minuteRange: Array.from(Array(variant === 'only-min' ? 60 : 4), (_el, i) => i),
    ampmRange: [0, 1],
    dayRange: Array.from(Array(31), (_el, i) => i),
    monthRange: Array.from(Array(12), (_el, i) => i),
    yearRange: Array.from(Array(totalYears), (_el, i) => i),
  }
  const BAR_VIEWPORT = UNIT.height * 3
  const INIT_BARS = [0, 0, 0, 0] as SwipingTimePickerBars

  return {
    MINUTE_GROUP,
    UNIT,
    BAR_VIEWPORT,
    INIT_BARS,
    START_YEAR,
    YEAR_BOUNDS,
  }
}
