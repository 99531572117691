import styled, { css } from 'styled-components'
import { captionStrongCaps, footnoteMedium, title3 } from '@fiji/style'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'
import type { LabelValue } from './types'

const FastestLabelStyles = css`
  color: ${(props) => props.theme.colors.plum};
  background: ${(props) => hexToRgba(props.theme.colors.plum, 0.1)};
`

const CheapestLabelStyles = css`
  color: ${(props) => props.theme.colors.success};
  background: ${(props) => hexToRgba(props.theme.colors.success, 0.1)};
`

const HighlightedStyles = css`
  &:before {
    content: '';
    position: absolute;
    left: -3px;
    top: -2px;
    width: 100%;
    height: 100%;
    padding: 2px 3px;
    background: ${(props) => props.theme.colors.error};
    opacity: 0.2;
    border-radius: 2px;
  }
`

const EllipsisStyles = css`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DurationInfoAlignRightStyles = css`
  text-align: right;
`

const DurationLocationAlignRightStyles = css`
  margin-left: auto;
`

const TotalDurationTimeContainerSmallMobileStyles = css`
  line-height: 22px;
  margin-bottom: 0;
`

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`
export const Label = styled.div<{ type: LabelValue }>`
  ${captionStrongCaps}
  padding: 0 4px;
  text-transform: uppercase;
  border-radius: 4px;
  &:not(:first-child) {
    margin-left: 8px;
  }
  ${(props) => {
    switch (props.type) {
      case 'cheapest':
        return CheapestLabelStyles
      case 'fastest':
        return FastestLabelStyles
      default:
        return ''
    }
  }}
`
export const DurationInfo = styled.div<{ isAlignRight?: boolean }>`
  flex-shrink: 0;
  z-index: 1;
  background: ${(props) => props.theme.colors.white};
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
  ${(props) => props.isAlignRight && DurationInfoAlignRightStyles}
`

export const DurationLocation = styled.div<{
  isAlignRight?: boolean
  isHighlighted: boolean
  isSmallMobile?: boolean
}>`
  line-height: 19px;
  display: inline-block;
  position: relative;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  ${(props) => props.isHighlighted && HighlightedStyles}
  ${(props) => props.isAlignRight && DurationLocationAlignRightStyles}
  ${(props) => props.isSmallMobile && EllipsisStyles}
`

export const DurationTime = styled.div`
  ${title3}
  line-height: 25px;
`

export const DurationLineContainer = styled.div`
  margin: 8px 5px 0;
`

export const Nights = styled.div`
  color: ${(props) => props.theme.btnBackgroundHover};
  margin-left: 3px;
`

export const TotalDurationTimeContainer = styled.div<{ isSmallMobile?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  ${(props) => props.isSmallMobile && TotalDurationTimeContainerSmallMobileStyles}

  & > div {
    position: relative;
    margin-left: 5px;
    padding-right: 8px;
    &:after {
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      right: 0;
      top: calc(50% - 1.5px);
      background: ${(props) => props.theme.colors.bodyText1};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

export const ImageRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg,
  img {
    width: 24px;
    height: 24px;
  }
`

export const ErrorContainer = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.error};
  ${footnoteMedium}
  text-align:left;
`
