import { observer } from 'mobx-react-lite'
import { useBackSearch } from '@fiji/hooks/search-queries/use-back-search'
import { HotelSearchForm } from '@etta/components/hotel-search-form'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import { HotelSearchPageLayout } from './layout/hotel-search-page-layout'

export const HotelSearchPage = observer(function HotelSearchPage() {
  const { onBack } = useBackSearch()
  const { hotelSearchFormStore } = useHotelSearchFormContext()
  const { postBookingAction } = hotelSearchFormStore.hotelForm

  return (
    <HotelSearchPageLayout onBack={onBack} postBookingAction={postBookingAction}>
      <HotelSearchForm />
    </HotelSearchPageLayout>
  )
})
