import type { Props } from './types'
import { PreferenceBlockLayout } from './layout'
import { usePreferenceBox } from './use-preference-box'

export function PreferenceBox({
  type,
  preferences,
  onEdit,
  segmentState,
  disabledMemberships,
  availableToSelect,
}: Props) {
  const {
    isPreferencesEmpty,
    handleClose,
    isOpen,
    handleOpen,
    filteredPreferences,
    areAllPreferencesUnavailable,
  } = usePreferenceBox({
    preferences,
    availableToSelect,
    disabledMemberships,
    type,
  })

  return (
    <PreferenceBlockLayout
      segmentState={segmentState}
      isPreferencesEmpty={isPreferencesEmpty}
      preferenceType={type}
      onEditPreference={onEdit}
      areAllPreferencesUnavailable={areAllPreferencesUnavailable}
      isOpen={isOpen}
      handleClose={handleClose}
      handleOpen={handleOpen}
      filteredPreferences={filteredPreferences}
      availableToSelect={availableToSelect}
    />
  )
}
