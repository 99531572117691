import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { FeatureFlagsStore } from '@etta/modules/feature-flags/interface/stores/feature-flags.store'
import { TravelPreferencesService } from '@etta/modules/travel-preferences/interface/services/travel-preferences.service'
import { ActivateUserService } from '../services/activate-user.service'
import { CreatePasswordService } from '../services/create-password.service'
import { UserService } from '../services/get-user.service'
import { BusinessContactStore } from '../stores/business-contact.store'
import { CreatePasswordStore } from '../stores/create-password.store'
import { EmployeeInfoStore } from '../stores/employee-info.store'
import { PaymentInfoStore } from '../stores/payment-info.store'
import { TravelPreferencesStore } from '../stores/travel-preferences.store'
import { UserActivationPaginationStore } from '../stores/user-activation-pagination.store'
import { UserActivationStore } from '../stores/user-activation.store'
import { UserInformationStore } from '../stores/user-information.store'
import type { Step } from '../types'
import { UserActivationPaginationService } from '../services/user-activation-pagination.service'
import { UserStore } from '../stores/user.store'

@Action()
export class UserActivationActions {
  constructor(
    @Inject() private readonly userActivationStore: UserActivationStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly businessContactStore: BusinessContactStore,
    @Inject() private readonly userInformationStore: UserInformationStore,
    @Inject() private readonly employeeInfoStore: EmployeeInfoStore,
    @Inject() private readonly activateUserService: ActivateUserService,
    @Inject() private readonly userService: UserService,
    @Inject() private readonly userActivationPaginationStore: UserActivationPaginationStore,
    @Inject() private readonly userActivationPaginationService: UserActivationPaginationService,
    @Inject() private readonly paymentInfoStore: PaymentInfoStore,
    @Inject() private readonly createPasswordService: CreatePasswordService,
    @Inject() private readonly createPasswordStore: CreatePasswordStore,
    @Inject() private readonly travelPreferencesStore: TravelPreferencesStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
    @Inject() private readonly travelPreferencesService: TravelPreferencesService,
  ) {}

  async handleBrowserAction(step: Step) {
    const isValid = await this.validateInput(step)
    if (!isValid) {
      return
    }
    this.userActivationPaginationStore.setIsLoading(true)
    await this.activateUserService.saveActivationDataByStep(step, false)
    this.userActivationPaginationStore.setIsLoading(false)
  }

  async handleNext() {
    const currentStep = this.userActivationPaginationStore.currentStepType
    const isValid = await this.validateInput(currentStep)
    if (!isValid) {
      return
    }
    if (this.userActivationPaginationStore.isLastStep) {
      const isChecked = this.handleCheckUserAcknowledge()
      if (!isChecked) {
        return
      }
      await this.handleSubmitForm()
    } else {
      await this.handleSaveActivationData()
    }
  }

  private handleCheckUserAcknowledge(): boolean {
    if (this.userActivationStore.userAcknowledge) {
      this.userActivationStore.setUserAcknowledgeError(false)
    } else {
      this.userActivationStore.setUserAcknowledgeError(true)
    }
    return this.userActivationStore.userAcknowledge
  }

  private async handleSubmitForm() {
    this.userActivationStore.setDisplayStatus('loading')

    const isSuccess = await this.activateUserService.saveActivationDataByStep(
      this.userActivationPaginationStore.currentStepType,
      true,
    )

    if (isSuccess) {
      this.userActivationStore.setDisplayStatus('success')
      await this.userService.reloadUser()
      await this.travelPreferencesService.loadTravelPreferences()
      await delayForSuccess()
      this.historyService.replace(ROUTES.explore)
      this.userActivationStore.dropStore()
    } else {
      this.userActivationStore.setDisplayStatus('error')
    }
  }

  private async handleSaveActivationData() {
    this.userActivationPaginationStore.setIsLoading(true)

    if (
      (this.userActivationPaginationStore.currentStepType === 'create-password' &&
        !this.userStore.isActivationRequired) ||
      !this.featureFlagsStore.flags.isUserActivationEnabled
    ) {
      await this.userService.reloadUser()
      await this.travelPreferencesService.loadTravelPreferences()
      this.historyService.replace(ROUTES.explore)
      this.userActivationPaginationStore.setIsLoading(false)
      return
    }

    const isSuccess = await this.activateUserService.saveActivationDataByStep(
      this.userActivationPaginationStore.currentStepType,
      false,
    )
    this.userActivationPaginationStore.setIsLoading(false)

    if (isSuccess) {
      this.userActivationPaginationService.next()
    } else {
      this.userActivationStore.setDisplayStatus('error')
    }
  }

  handleBack() {
    this.userActivationPaginationService.previous()
  }

  handleUserAcknowledge() {
    const acknowledge = !this.userActivationStore.userAcknowledge
    this.userActivationStore.setUserAcknowledgeError(!acknowledge)
    this.userActivationStore.setUserAcknowledge(acknowledge)
  }

  private async validateInput(step: Step) {
    const defaultIsValid = { isValid: true }
    switch (step) {
      case 'create-password':
        return this.savePassword()
      case 'user-information':
        return (
          await Promise.all([
            this.userInformationStore.validators.userInfoValidator.submit(),
            this.userInformationStore.validators.additionalInformationValidator.submit(),
          ])
        ).every((r) => r.isValid)
      case 'company-information':
        return (
          await Promise.all([
            this.businessContactStore.validators.businessContactValidator.submit(),
            this.businessContactStore.validators.additionalInformationValidator?.submit() ||
              defaultIsValid,
          ])
        ).every((r) => r.isValid)
      case 'employee-information':
        return (
          await Promise.all([
            this.employeeInfoStore.validators.employeeInfoValidator.submit(),
            this.employeeInfoStore.validators.additionalInformationValidator.submit(),
            this.paymentInfoStore.validator.submit(),
          ])
        ).every((r) => r.isValid)
      case 'travel-preferences':
        return (
          await Promise.all([
            this.travelPreferencesStore.validators.flightPreferenceValidator.submit(),
            this.travelPreferencesStore.validators.hotelPreferencesValidator.submit(),
            this.travelPreferencesStore.validators.carrentalPreferencesValidator.submit(),
            this.travelPreferencesStore.validators.additionalInformationValidator?.submit() ||
              defaultIsValid,
          ])
        ).every((r) => r.isValid)
    }
  }

  private async savePassword() {
    this.userActivationPaginationStore.setIsLoading(true)
    await this.createPasswordService.savePassword()
    this.userActivationPaginationStore.setIsLoading(false)
    return (
      this.createPasswordStore.isPasswordValid &&
      !(this.userActivationStore.displayStatus === 'error')
    )
  }
}
