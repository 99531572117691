import styled from 'styled-components'
import { CarRentalCardSkeleton as CarRentalCardSkeletonUnstyled } from '@etta/components/car-rental-card-skeleton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const CarRentalCardSkeleton = styled(CarRentalCardSkeletonUnstyled)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const FiltersBlock = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 18px;
`
