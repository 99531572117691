import type { TextVariant } from '@etta/ui/text/types'
import { ScreenType, useScreenType } from '@fiji/modes'

export function useCovidInformationHeader() {
  const screenType = useScreenType()
  const isUpdatedAtShouldRender = screenType === ScreenType.Mobile
  const learnMoreVariant: TextVariant = isUpdatedAtShouldRender
    ? 'footnoteMedium'
    : 'footnoteStrong'
  return {
    isUpdatedAtShouldRender,
    learnMoreVariant,
  }
}
