import { useState, useEffect, useMemo, useCallback } from 'react'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type { ValueType, OptionType } from '../types'
import { ListTypes } from '../types'

type Args<T = ValueType> = {
  value?: T[]
  options: OptionType<T>[]
  type?: ListTypes
  onChange: (value: T[]) => void
}

export function useList<T extends ValueType = ValueType>({
  options,
  value,
  type,
  onChange,
}: Args<T>) {
  const [defaultValues, setDefaultValues] = useState<T[]>([])
  const [chosenValues, setChosenValues] = useState<T[]>(value || defaultValues)
  const [previousValues, setPreviousValues] = useState<T[]>(value || defaultValues)

  useEffect(() => {
    const values = options.filter((o) => o.isDefaultValue).map((o) => o.value)
    setDefaultValues(values)
    setChosenValues(value || values)
    setPreviousValues(value || values)
  }, [options, value])

  const isValuesEqual = useMemo(() => checkIsEqual(previousValues, chosenValues.sort()), [
    previousValues,
    chosenValues,
  ])

  const handleClickItem = useCallback(
    (value: T) => () => {
      if (type === ListTypes.Radio) {
        setChosenValues([value])
        onChange([value])
        return
      }
      if (type !== ListTypes.Select) {
        return
      }
      if (chosenValues.includes(value)) {
        setChosenValues(chosenValues.filter((v) => v !== value))
      } else {
        setChosenValues([...chosenValues, value])
      }
    },
    [type, chosenValues, setChosenValues, onChange],
  )

  const handleClear = useCallback(() => {
    setChosenValues(defaultValues)
    setPreviousValues(defaultValues)
    onChange(defaultValues)
  }, [defaultValues, setChosenValues, onChange])

  const handleApply = useCallback(() => {
    onChange(chosenValues)
  }, [chosenValues, onChange])

  return {
    handleApply,
    isValuesEqual,
    chosenValues,
    previousValues,
    handleClear,
    handleClickItem,
  }
}
