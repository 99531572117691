import { Action, Inject } from '@etta/di'
import { CheckoutInfoStore } from '../checkout-info.store'
import type { PrimaryTravelerPersistValueObject } from '../../../core/value-objects/checkout-info/primary-traveler-persist.value-object'

@Action()
export class CheckoutInfoTravelerAction {
  constructor(
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
  ) {}

  setTravelerInfo(traveler: PrimaryTravelerPersistValueObject) {
    this.checkoutInfoStore.primaryTraveler.setTravelerValue(traveler)
  }
}
