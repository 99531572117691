import { eachDayOfInterval, startOfDay, endOfDay, parse, isValid } from 'date-fns'
import { useCallback } from 'react'
import { addDaysToDate, dateFormat } from '@fiji/utils/dates'
import type { WheelPickerItemValue } from './wheel-picker-item'

type Props = {
  minDate?: Date
  maxDate?: Date
}

export const useWheelDateTimePicker = ({
  minDate = new Date(),
  maxDate = addDaysToDate(new Date(), 30),
}: Props) => {
  const dateItems: WheelPickerItemValue[] = eachDayOfInterval({
    start: startOfDay(minDate),
    end: endOfDay(maxDate),
  }).map((date) => ({
    value: dateFormat(date, 'yyyy-MM-dd'),
    label:
      dateFormat(date, 'yyyy-MM-dd') === dateFormat(new Date(), 'yyyy-MM-dd')
        ? 'Today'
        : dateFormat(date, 'eee dd MMM'),
  }))

  const hourItems: WheelPickerItemValue[] = Array.from({ length: 12 }, (_, index) => ({
    value: `${index + 1}`,
    label: `${index + 1}`,
  }))

  const minuteItems: WheelPickerItemValue[] = Array.from({ length: 12 }, (_, index) => ({
    value: `${(index * 5).toString().padStart(2, '0')}`,
    label: `${(index * 5).toString().padStart(2, '0')}`,
  }))

  const timePeriodItems: WheelPickerItemValue[] = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ]

  const parseDate = useCallback(
    (date: string, hour: string, minute: string, timePeriod: string) => {
      const dateString = `${date} ${hour}:${minute} ${timePeriod}`
      const dateValue = parse(dateString, 'yyyy-MM-dd h:mm a', new Date())
      if (isValid(dateValue)) {
        return dateValue
      }
      return new Date()
    },
    [],
  )

  return {
    dateItems,
    hourItems,
    minuteItems,
    timePeriodItems,
    parseDate,
  }
}
