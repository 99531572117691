import { useTheme } from '@fiji/hooks/use-theme'

type Props = {
  color?: string
}

export default function PreferredIcon({ color }: Props) {
  const theme = useTheme()

  const usedColor = (color ? color : theme.colors.success) as string
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7538 37.5801L27.0587 35.2902C27.2981 35.0657 27.5076 34.9759 27.8219 34.9759H31.0547C33.7337 34.9759 34.9759 33.7486 34.9759 31.0547V27.8219C34.9759 27.5076 35.0657 27.2981 35.2902 27.0587L37.5801 24.7538C39.4808 22.8681 39.4658 21.117 37.5801 19.2312L35.2902 16.9264C35.0657 16.7019 34.9759 16.4774 34.9759 16.1781V12.9303C34.9759 10.2663 33.7486 9.00914 31.0547 9.00914H27.8219C27.5076 9.00914 27.2981 8.9343 27.0587 8.70981L24.7538 6.41994C22.8681 4.51921 21.117 4.53418 19.2312 6.41994L16.9264 8.70981C16.7019 8.9343 16.4774 9.00914 16.1781 9.00914H12.9303C10.2513 9.00914 9.00914 10.2364 9.00914 12.9303V16.1781C9.00914 16.4774 8.9343 16.7019 8.70981 16.9264L6.41994 19.2312C4.51921 21.117 4.53418 22.8681 6.41994 24.7538L8.70981 27.0587C8.9343 27.2981 9.00914 27.5076 9.00914 27.8219V31.0547C9.00914 33.7337 10.2513 34.9759 12.9303 34.9759H16.1781C16.4774 34.9759 16.7019 35.0657 16.9264 35.2902L19.2312 37.5801C21.117 39.4808 22.8681 39.4658 24.7538 37.5801ZM19.4707 29.5281C19.0067 29.5281 18.7074 29.3784 18.4829 29.124L13.4841 23.5715C13.2746 23.332 13.1548 23.0327 13.1548 22.7184C13.1548 22.015 13.6936 21.4911 14.4569 21.4911C14.861 21.4911 15.1603 21.6258 15.4297 21.9102L19.3958 26.2954L27.1036 15.4447C27.4029 15.0107 27.7022 14.8311 28.226 14.8311C28.9594 14.8311 29.4832 15.3699 29.4832 16.0733C29.4832 16.3277 29.3785 16.6271 29.2138 16.8665L20.4884 29.0642C20.2639 29.3485 19.9197 29.5281 19.4707 29.5281Z"
        fill={usedColor}
      />
    </svg>
  )
}
