import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import type { HotelSegmentEntity } from '@etta/modules/review-trip/core'
import { formatRate } from '@fiji/utils/money/format-rate'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { Text, CostContainer } from '@etta/screens/payment-summary/payment-summary-styled'
import { HotelTitleBox } from './hotel-segment-styled'

type Props = {
  segment: HotelSegmentEntity
}
const i18nBase = 'PaymentSummary.'

export function HotelSegment({ segment }: Props) {
  const { t } = useTranslation()

  if (!segment) {
    return null
  }

  const { mainCost: hotelCost } = formatRate(segment.baseRate, { morpheme: 'none' })

  const checkIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, segment.checkIn)
  const checkOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, segment.checkOut)

  return (
    <Fragment>
      <CostContainer
        aria-label={t(i18nBase + 'SegmentAccessibilityLabel', {
          name: segment.name,
          amount: hotelCost,
        })}>
        <HotelTitleBox>
          <Text>{segment.name}</Text>
        </HotelTitleBox>
        {hotelCost && <Text>{hotelCost}</Text>}
      </CostContainer>
      <Text>
        {checkIn} - {checkOut}
      </Text>
      <Text withTextOverflow>
        {t(i18nBase + 'Nights', {
          count: getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
            segment.checkOut,
            segment.checkIn,
          ),
        })}
      </Text>
    </Fragment>
  )
}
