import type { LuggageOptionValueObject } from '@etta/modules/booking/core/value-objects'

export const mapLuggageOption = (luggageOption: LuggageOptionValueObject | null | undefined) => {
  if (!luggageOption) {
    return undefined
  }

  return {
    code: luggageOption.code,
    value: luggageOption.value,
  }
}
