import { Action, Inject } from '@etta/di'
import { TravelPreferencesService } from '../services/travel-preferences.service'
import type { Preference } from '../types'

@Action()
export class TravelPreferencesActions {
  constructor(@Inject() private travelPreferencesService: TravelPreferencesService) {}

  async loadTravelPreferences() {
    await this.travelPreferencesService.loadTravelPreferences()
  }

  async refetchTravelPreferences(preference?: Preference) {
    await this.travelPreferencesService.refetchTravelPreferences(preference)
  }
}
