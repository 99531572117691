import { useTranslation } from 'react-i18next'
import { CountrySearch } from '@etta/components/country-search/country-search'
import { Button } from '@etta/ui/button'
import { Checkbox } from '@etta/ui/checkbox'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Swap } from '@etta/ui/swap'
import { TextField } from '@etta/ui/text-field'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { nameOnCardParser } from '@fiji/utils/credit-card'
import type { LayoutProps } from '../../types'
import { CreditCardNumber } from '../../credit-card-number'
import { ExpirationDate } from '../../expiration-date'
import {
  Container,
  Title,
  ModalFooter,
  FieldsStack,
  BillingAddressSection,
  FieldGroup,
  CheckBoxWrapper,
  BackLink,
} from './credit-card-modal-desktop-styled'

export function CreditCardModalDesktop({
  isOpen,
  isEditForm,
  isBillingAddressRequired,
  onClose,
  isSingleUseCreditCardAllowed,
  handleSubmit,
  isSubmitLoading,
  isCvvRequired,
  isGuest,
  guestPersonalInfo,
  isOneTimeUseCard,
  handleChangeShouldSaveGuestCard,
  shouldSaveGuestCard,
  handleChangeOneTimeUseCard,
  onAddressFieldChange,
  addressErrors,
  addressValues,
  onCardFieldChange,
  cardValues,
  cardErrors,
  modalTitle,
  cardNumber,
  isCardNumberFieldEdited,
  handleSetCardNumberFieldEdited,
  isSavingCreditCardEnabled,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CreditCardForm.'
  const modalSaveTitle = isEditForm ? t(i18Base + 'Button.Save') : t(i18Base + 'Button.AddCard')
  const modalFooterBackTitle = isEditForm
    ? t(i18Base + 'Button.Back')
    : t(i18Base + 'Button.Cancel')
  const headerTextLeftPadding = isEditForm ? 8 : 32

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={onClose}
      horizontalDimension="content-760"
      position="right"
      data-tracking-id="modal-credit-card">
      <Header
        minHeight={72}
        withBorderBottom
        rightSlot={
          !isEditForm ? (
            <Block paddingRight={24}>
              <IconButton onClick={onClose} icon="closeFilledPWA" size="medium" color="bodyText" />
            </Block>
          ) : null
        }>
        <Swap
          is={isEditForm}
          isSlot={
            <Block paddingLeft={32}>
              <IconButton
                onClick={onClose}
                icon="arrowBackLargePWA"
                size="medium"
                color="mainText"
              />
            </Block>
          }
        />
        <Block paddingLeft={headerTextLeftPadding}>
          <Header.Title title={modalTitle} align="left" />
        </Block>
      </Header>
      <Modal.Body>
        <Container>
          <Title>{t(i18Base + 'SectionTitle.CardInformation')}</Title>
          <FieldsStack>
            <TextField
              value={cardValues.name}
              onChange={onCardFieldChange('name')}
              helperText={cardErrors.name}
              label={t(i18Base + 'InputLabels.Name')}
              valueParser={nameOnCardParser}
            />
            <CreditCardNumber
              value={cardNumber}
              error={cardErrors.cardNumber}
              isCardNumberFieldEdited={isCardNumberFieldEdited}
              onChange={onCardFieldChange('cardNumber')}
              handleSetCardNumberFieldEdited={handleSetCardNumberFieldEdited}
              creditIconSize="medium"
              isDisabled={!!isEditForm}
            />
            <Swap
              is={!isCvvRequired}
              isSlot={
                <ExpirationDate
                  value={cardValues.expirationDate}
                  error={cardErrors.expirationDate}
                  onChange={onCardFieldChange('expirationDate')}
                />
              }>
              <FieldGroup>
                <ExpirationDate
                  value={cardValues.expirationDate}
                  error={cardErrors.expirationDate}
                  onChange={onCardFieldChange('expirationDate')}
                />
                <TextField
                  type="cvv"
                  value={cardValues.securityCode}
                  helperText={cardErrors.securityCode}
                  onChange={(value) => {
                    onCardFieldChange('securityCode')(value)
                  }}
                />
              </FieldGroup>
            </Swap>
            <TextField
              value={cardValues.label}
              helperText={cardErrors.label}
              onChange={onCardFieldChange('label')}
              label={t(i18Base + 'InputLabels.Label')}
            />
          </FieldsStack>

          {isBillingAddressRequired && (
            <BillingAddressSection>
              <Title>{t(i18Base + 'SectionTitle.BillingAddress')}</Title>
              <FieldsStack>
                <CountrySearch
                  value={addressValues.countryCode}
                  setValue={onAddressFieldChange('countryCode')}
                  helperText={addressErrors.countryCode}
                />

                <FieldGroup>
                  <TextField
                    value={addressValues.postalCode}
                    onChange={onAddressFieldChange('postalCode')}
                    helperText={addressErrors.postalCode}
                    label={t(i18Base + 'InputLabels.PostalCode')}
                  />
                  <TextField
                    value={addressValues.stateCode}
                    onChange={onAddressFieldChange('stateCode')}
                    helperText={addressErrors.stateCode}
                    label={t(i18Base + 'InputLabels.State')}
                  />
                </FieldGroup>
                <TextField
                  value={addressValues.city}
                  onChange={onAddressFieldChange('city')}
                  helperText={addressErrors.city}
                  label={t(i18Base + 'InputLabels.City')}
                />
                <TextField
                  value={addressValues.street1}
                  onChange={onAddressFieldChange('street1')}
                  helperText={addressErrors.street1}
                  label={t(i18Base + 'InputLabels.Street1')}
                />
                <TextField
                  value={addressValues.street2}
                  onChange={onAddressFieldChange('street2')}
                  helperText={addressErrors.street2}
                  label={t(i18Base + 'InputLabels.Street2')}
                />
              </FieldsStack>
            </BillingAddressSection>
          )}

          {!isGuest && !isEditForm && isSingleUseCreditCardAllowed && (
            <CheckBoxWrapper>
              <Checkbox
                label={t(`${i18Base}InputLabels.isOneTimeUseCard`)}
                value="isOneTimeUseCard"
                checked={isOneTimeUseCard || !isSavingCreditCardEnabled}
                isDisabled={!isSavingCreditCardEnabled}
                onChange={handleChangeOneTimeUseCard}
              />
            </CheckBoxWrapper>
          )}
          {isGuest && guestPersonalInfo && !isEditForm && (
            <CheckBoxWrapper>
              <Checkbox
                label={t(`${i18Base}InputLabels.GuestSaveToProfile`)}
                value="shouldSaveGuestCard"
                checked={shouldSaveGuestCard}
                onChange={handleChangeShouldSaveGuestCard}
              />
            </CheckBoxWrapper>
          )}
        </Container>
      </Modal.Body>
      <ModalFooter>
        <Swap
          is={isEditForm}
          isSlot={
            <BackLink onClick={onClose}>
              <Icon name="chevronLeftPWA" color="primary" />
              {modalFooterBackTitle}
            </BackLink>
          }>
          <Link onClick={onClose}>{modalFooterBackTitle}</Link>
        </Swap>
        <Button minWidth={21} onClick={handleSubmit} disabled={isSubmitLoading}>
          {modalSaveTitle}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
