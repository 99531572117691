import { Action, Inject } from '@etta/di'
import type { CarRentalSortBy, CarRentalType } from '@etta/modules/car-rental-search/core/enums'
import { ROUTES } from '@fiji/routes'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CarRentalService } from '../services/car-rental.service'
import { CarRentalSearchStore } from '../stores/car-rental-search.store'
import { CarFiltersStore } from '../stores/car-filters.store'
import { CarRentalInputService } from '../services/car-rental-input.service'
import { CarRentalQueryService } from '../services/car-rental-query.service'
import { CarRentalSearchService } from '../services/car-rental-search.service'
import { CarFiltersModalStore } from '../stores/car-filters-modal.store'
import { CarRentalFilterMapper } from '../mappers'
import type { CarRentalInputArgs, CarRentalLocationFilters, CarRentalFilters } from '../types'

@Action()
export class CarRentalFilterActions {
  constructor(
    @Inject()
    private readonly carFiltersStore: CarFiltersStore,

    @Inject()
    private readonly carFiltersModalStore: CarFiltersModalStore,

    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,

    @Inject()
    private readonly carRentalService: CarRentalService,

    @Inject()
    private readonly carRentalSearchService: CarRentalSearchService,

    @Inject()
    private readonly carRentalQueryService: CarRentalQueryService,

    @Inject()
    private readonly carRentalSearchStore: CarRentalSearchStore,

    @Inject()
    private readonly carRentalInputService: CarRentalInputService,
  ) {}

  handlers = this.carFiltersStore.handlers

  clearHandlers = this.carFiltersStore.clearHandlers

  lastCarQuickFiltersApplied: CarRentalType[] = []

  handleNotUseCompanyFiltersInRequest() {
    this.carRentalSearchStore.setIsSomeFiltersAppliedByCompanySettings(false)
  }

  handleResetToDefaultFilters() {
    const carFilters = this.carFiltersStore.getDefaultFiltersFromConfiguration()
    this.carFiltersStore.setSelectedFilters(
      CarRentalFilterMapper.toSelectedCarFiltersValueObject(carFilters),
    )
    this.carRentalSearchStore.setIsSomeFiltersAppliedByCompanySettings(true)
    this.handleApplyCarFilters({ carFilters, carTypes: [] })
  }

  handleApplyCarRentalFilters(carFilters: CarRentalFilters) {
    this.carRentalQueryService.appendQueryParams({
      ...carFilters,
    })

    this.handleApplyFilters({ carFilters })
  }

  handleResetCarCompanyFilters() {
    this.handleNotUseCompanyFiltersInRequest()
    this.handleResetCarFilters()
  }

  handleResetCarFilters() {
    this.handleApplyEmptyCarRentalFilters()
    this.carFiltersStore.clearAll()
  }

  private handleApplyEmptyCarRentalFilters() {
    this.handleApplyCarRentalFilters({
      carTransmission: [],
      carClass: [],
      carVendor: [],
      carFuel: [],
    })
  }

  handleApplyTransmissionFilters() {
    this.carFiltersStore.transmissionModal.handleClose()
    this.handleApplyCarRentalFilters({
      carTransmission: Array.from(this.carFiltersStore.selectedFilters.transmission),
    })
  }

  handleApplyCarClassFilters() {
    this.carFiltersStore.carClassModal.handleClose()
    this.handleApplyCarRentalFilters({
      carClass: Array.from(this.carFiltersStore.selectedFilters.carClass),
    })
  }

  handleApplySuppliersFilters() {
    this.carFiltersStore.supplierModal.handleClose()
    this.handleApplyCarRentalFilters({
      carVendor: Array.from(this.carFiltersStore.selectedFilters.vendors),
    })
  }

  handleApplyFuelFilters() {
    this.carFiltersStore.fuelModal.handleClose()
    this.handleApplyCarRentalFilters({
      carFuel: Array.from(this.carFiltersStore.selectedFilters.fuelType),
    })
  }

  handleApplySelectedFilters() {
    this.carFiltersModalStore.carFiltersToggle.handleClose()
    this.handleNotUseCompanyFiltersInRequest()
    this.handleApplyCarRentalFilters(
      CarRentalFilterMapper.toCarRentalFilters(this.carFiltersStore.selectedFilters),
    )
  }

  handleApplyLocationFilters(
    locationFilters: CarRentalLocationFilters,
    withDefaultFilters: boolean = false,
  ) {
    const queryParams = {
      dropOffAddress: locationFilters.dropOffAddress,
      pickUpAddress: locationFilters.pickUpAddress,
      dropOffDate: locationFilters.dropOffDate,
      pickUpDate: locationFilters.pickUpDate,
      pickUpTime: locationFilters.pickUpTime,
      dropOffTime: locationFilters.dropOffTime,
      pickUpPlace: locationFilters.pickUpPlace,
      dropOffPlace: locationFilters.dropOffPlace,
    }

    if (!locationFilters.pickUpPlace?.airportCode) {
      this.carRentalQueryService.navigateTo(ROUTES.carRental.pickUp, queryParams)
      return
    }

    if (!locationFilters.dropOffPlace?.airportCode) {
      this.carRentalQueryService.navigateTo(ROUTES.carRental.dropOff, queryParams)
      return
    }

    let filters: CarRentalFilters = {}
    if (withDefaultFilters) {
      filters = this.carFiltersStore.getDefaultFiltersFromConfiguration()
      this.carFiltersStore.setSelectedFilters(
        CarRentalFilterMapper.toSelectedCarFiltersValueObject(filters),
      )
    }

    this.carRentalQueryService.appendQueryParams({ ...queryParams, ...filters })
    this.handleApplySearchFilters({ locationFilters, carFilters: filters })
  }

  handleApplyCarTypesFilter(carTypes: CarRentalType[]) {
    this.carRentalQueryService.appendQueryParams({
      carTypes,
    })
    this.lastCarQuickFiltersApplied = carTypes

    this.handleApplyFilters({ typeFilters: carTypes })
  }

  handleApplySortFilter(value: CarRentalSortBy) {
    this.carRentalSearchStore.setCarRentalSortBy(value)

    this.handleApplyFilters({ sortBy: value })
  }

  handleApplyCarFilters({
    carFilters,
    carTypes = [],
  }: {
    carFilters?: CarRentalFilters
    carTypes?: CarRentalType[]
  }) {
    this.carRentalQueryService.appendQueryParams({
      ...carFilters,
      carTypes,
    })

    this.handleApplyFilters({ carFilters, typeFilters: carTypes })
  }

  handleCloseFiltersModal() {
    this.carFiltersModalStore.carFiltersToggle.handleClose()
    this.carFiltersStore.setSelectedFilters(this.carFiltersStore.appliedFilters)
  }

  private handleApplySearchFilters(args: CarRentalInputArgs) {
    this.carRentalSearchStore.dropOffset()
    this.carRentalSearchStore.dropCarRentalSearch()

    const input = this.carRentalInputService.getCarRentalInput(args)

    const { isMod2FlowEnabled, isPhoenixCarRentalSearchEnabled } = this.displayConfigurationStore

    if (isMod2FlowEnabled || isPhoenixCarRentalSearchEnabled) {
      this.carRentalSearchService.createCarRentalSearch(input)
      return
    }

    this.carRentalService.getCarRentals(input)
  }

  private handleApplyFilters(args: CarRentalInputArgs) {
    this.carRentalSearchStore.dropOffset()

    const input = this.carRentalInputService.getCarRentalInput(args)

    this.carRentalService.getCarRentals(input)
  }
}
