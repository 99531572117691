import { Checkbox } from '@etta/ui/checkbox'
import type { StationFilterStationValueObject } from '@etta/modules/rail/core/value-objects/station-filter-station.value-object'
import { getStationLabel } from './rail-stations-filter-utils'
import { Station, ListOfStations } from './rail-stations-filter-styled'

type Props = {
  stations: StationFilterStationValueObject[]
  onIsChecked: (station: StationFilterStationValueObject) => boolean
  onSelect: (station: StationFilterStationValueObject) => void
}

export function RailStationsList({ stations, onIsChecked, onSelect }: Props) {
  return (
    <ListOfStations>
      {stations.map((station, index) => {
        const label = getStationLabel(station)
        return (
          <Station key={index}>
            <Checkbox
              value={label}
              label={label}
              checked={onIsChecked(station)}
              onChange={() => onSelect(station)}
            />
          </Station>
        )
      })}
    </ListOfStations>
  )
}
