import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'

export function RailSearchFormMobile({
  tabsSlot,
  locationSlot,
  calendarSlot,
  railCardsSlot,
  submitButtonSlot,
  preSearchCustomFieldsModalSlot,
  costAllocationSlot,
  recentSearchesSlot,
}: LayoutProps) {
  return (
    <>
      {tabsSlot}

      {locationSlot}

      {calendarSlot}

      {railCardsSlot}

      {costAllocationSlot && (
        <Block marginTop={6} marginHorizontal={16}>
          {costAllocationSlot}
        </Block>
      )}

      {recentSearchesSlot && (
        <Block marginTop={44} marginBottom={16}>
          {recentSearchesSlot}
        </Block>
      )}

      <Block marginTop="auto" marginBottom={16} marginHorizontal={16}>
        {submitButtonSlot}
      </Block>

      {preSearchCustomFieldsModalSlot}
    </>
  )
}
