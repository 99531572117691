import { useEffect } from 'react'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'

export function useDisplayConfiguration() {
  const { displayConfigurationAction } = useDisplayConfigurationContext()

  useEffect(() => {
    displayConfigurationAction.loadDisplayConfiguration()
  }, [displayConfigurationAction])
}
