import { Service, Inject } from '@etta/di'
import type { SubmitCarRentalSearchInput } from '../../core/value-objects/submit-car-rental-search-input.value-object'
import type { SubmitFlightSearchInput } from '../../core/value-objects/submit-flight-search-input.value-object'
import type { SubmitHotelSearchInput } from '../../core/value-objects/submit-hotel-search-input.value-object'
import type { SubmitRailSearchInput } from '../../core/value-objects/submit-rail-search-input.value-object'
import { SubmitSearchService } from '../services/submit-search.service'

@Service()
export class SubmitRecentSearchActions {
  constructor(@Inject() private submitSearchService: SubmitSearchService) {}

  submitNewCarRentalSearch(input: SubmitCarRentalSearchInput) {
    this.submitSearchService.submitNewCarRentalSearch(input)
  }

  submitNewFlightSearch(input: SubmitFlightSearchInput) {
    this.submitSearchService.submitNewFlightSearch(input)
  }

  submitNewHotelSearch(input: SubmitHotelSearchInput) {
    this.submitSearchService.submitNewHotelSearch(input)
  }

  submitNewRailSearch(input: SubmitRailSearchInput) {
    this.submitSearchService.submitNewRailSearch(input)
  }
}
