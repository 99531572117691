import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  & > :first-child {
    margin-top: unset;
  }

  & > :nth-child(2) {
    margin-top: 5px;
  }
`

export const Title = styled(Text).attrs(() => ({
  color: 'mainText',
  variant: 'headline',
  isBlock: true,
}))`
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`
