import { useEffect } from 'react'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { insertFavicon } from './insert-favicon'

export function useFaviconConfiguration() {
  const { brandConfigurationStore } = useBrandConfigurationContext()

  useEffect(() => {
    if (!brandConfigurationStore.isLoaded) {
      return
    }

    insertFavicon({
      theme: brandConfigurationStore.theme,
    })
  }, [brandConfigurationStore.isLoaded, brandConfigurationStore.theme])
}
