import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import { LocationSearchField } from '@etta/components/location-search-field/location-search-field'
import type { LayoutProps } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationSectionMobile = observer(function LocationSectionMobile({
  location,
  onChange,
  placesHistory,
  validationErrors,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <InputButtonGroup
      direction={InputButtonGroupDirection.Vertical}
      hasError={!!validationErrors.location}
      errorMessage={validationErrors.location}>
      <LocationSearchField
        searchType="hotel"
        onChange={onChange}
        value={location}
        aria-label={
          location
            ? t(i18Base + '.Input.PlaceSelectedLocationAriaLabel', {
                label: t(`${i18Base}.Input.To`),
                value: location.name,
              })
            : t(i18Base + '.Input.TapToSelectLocationAriaLabel')
        }
        inputAriaLabel={t(i18Base + '.Input.TapToSelectLocationAriaLabel')}
        label={t(i18Base + '.Input.Location')}
        isOutboundTrip
        hasCurrentLocationSearch
        useNewLocationSearch
        latestPlaces={placesHistory}
        data-tracking-id="hotel-location-search"
        recentSearchesType="hotel"
      />
    </InputButtonGroup>
  )
})
