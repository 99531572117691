import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  ContainerDefault,
  StartIconSmall,
  IconWrapper,
  InnerContainerDefault,
  Title,
} from '../../../blocks-mobile-styles'

export function BlockDefaultMobile({ title, seatNumber, isLoading, onSelect }: LayoutProps) {
  const { t } = useTranslation()

  return (
    <ContainerDefault>
      <IconWrapper>
        <StartIconSmall>
          <SeatIcon icon="seat" />
        </StartIconSmall>
      </IconWrapper>
      <InnerContainerDefault>
        <Title>{title}</Title>
        <Button
          size="small"
          onClick={onSelect}
          disabled={isLoading}
          data-tracking-id={`select-seat-${seatNumber}-button`}>
          {seatNumber &&
            t('AirSeatMap.Select', {
              number: seatNumber,
            })}
        </Button>
      </InnerContainerDefault>
    </ContainerDefault>
  )
}
