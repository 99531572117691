import { useMemo } from 'react'
import type { Room, RoomType } from '@fiji/graphql/types'
import { getRoomRate } from './get-room-rate'

type Args = {
  rooms?: Room[]
  roomTypes: RoomType[]
}

export function useRoomsContentLayoutDesktop({ rooms, roomTypes }: Args) {
  const groupedRooms = useMemo(() => {
    if (!rooms) {
      return {}
    }

    return rooms?.reduce<{ [key in RoomType]?: Room[] }>((acc, room) => {
      if (acc[room.type]) {
        acc[room.type]!.push(room)

        return acc
      }

      acc[room.type] = [room]
      return acc
    }, {})
  }, [rooms])

  const filteredSortedRoomTypes = useMemo(() => {
    if (!groupedRooms) {
      return roomTypes
    }

    return roomTypes
      .filter((roomType) => !!groupedRooms[roomType])
      .sort((firstType, secondType) => {
        const firstRoom = groupedRooms[firstType]
        const secondRoom = groupedRooms[secondType]

        if (!firstRoom || !secondRoom) {
          return 0
        }

        return getRoomRate(firstRoom[0]) - getRoomRate(secondRoom[0])
      })
  }, [groupedRooms, roomTypes])

  return {
    groupedRooms,
    filteredSortedRoomTypes,
  }
}
