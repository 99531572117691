import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { Container, Title, Content } from './confirmation-dialog-mobile-styled'

const i18Base = 'Accessibility.ConfirmationDialog'

export function ConfirmationDialogMobile({
  title,
  content,
  onConfirm,
  confirmButtonText,
  onDecline,
  declineButtonText,
}: LayoutProps) {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
      <Block aria-label={t(`${i18Base}.Accept`, { button: confirmButtonText })} paddingBottom={16}>
        <Button fullWidth onClick={onConfirm} data-tracking-id="change-car-rental-button">
          {confirmButtonText}
        </Button>
      </Block>
      {declineButtonText && (
        <Block aria-label={t(`${i18Base}.Decline`)} paddingBottom={16}>
          <Button
            fullWidth
            variant="outline"
            onClick={onDecline}
            data-tracking-id="decline-car-rental-button">
            {declineButtonText}
          </Button>
        </Block>
      )}
    </Container>
  )
}
