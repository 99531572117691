import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import type { RailTicketRestrictionsProps } from './types'
import { RailTicketRestrictionsModal } from './rail-ticket-restrictions-modal'

export function RailTicketRestrictions({
  isVisible,
  onClose,
  ticketRestrictionsInfo,
  isPostBooking,
}: RailTicketRestrictionsProps) {
  return (
    <>
      <InfoModal isVisible={isVisible} onClose={onClose}>
        <RailTicketRestrictionsModal
          onClose={onClose}
          ticketRestrictionsInfo={ticketRestrictionsInfo}
          isPostBooking={isPostBooking}
        />
      </InfoModal>
    </>
  )
}
