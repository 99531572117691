import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { InputButton } from '@etta/ui/input-button'
import { Link } from '@etta/ui/link'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import { TextField } from '@etta/ui/text-field'
import { ScreenType, useScreenType } from '@fiji/modes'
import { InputButtonGroup, InputButtonGroupDirection } from '@etta/ui/input-button-group'
import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'
import type { Toggle } from '@etta/interface/services/toggle'
import { SelectAllocationModal } from './select-allocation-modal'
import { InputWrapper, OneLineWrapper } from './multi-layer-cost-allocation-content-styled'
import { useMultiLayerCostAllocationContent } from './use-multi-layer-cost-allocation-content'

type Props = {
  segmentId: number
  title?: string
  type?: 'additional-info' | 'presearch'
  error?: string
  variant?: 'one-line' | 'default'
  label?: string | null
  modal?: Toggle
  onSubmit?: (val: CostAllocationSubmitValueObject, displayName: string) => void
}

export const MultiLayerCostAllocationContent = observer(function MultiLayerCostAllocationContent({
  segmentId,
  title,
  type = 'presearch',
  error = '',
  variant = 'default',
  label,
  modal,
  onSubmit,
}: Props) {
  const costAllocationData = useMultiLayerCostAllocationContent({ segmentId, modal, onSubmit })

  const { t } = useTranslation()
  const i18nBase = 'PreSearchCostAllocation'

  const screenType = useScreenType()
  const isDesktop = screenType === ScreenType.Desktop

  if (!isDesktop && variant === 'one-line') {
    return (
      <>
        <OneLineWrapper>
          <Block marginRight={4}>
            <Text variant="headline" color="mainText">
              {title}
            </Text>
          </Block>
          <Link onClick={modal?.handleOpen}>
            <Text variant="linkNormal">{label || t(i18nBase + '.Select')}</Text>
            <Icon name="chevronRightPWA" size="small" />
          </Link>
        </OneLineWrapper>

        <SelectAllocationModal
          isOpen={modal?.isOpen ?? false}
          isAdaptivePosition
          data={costAllocationData}
        />
      </>
    )
  }

  return (
    <Block>
      <Block marginTop={32} marginBottom={12}>
        <Text variant="headline" color="mainText">
          {title}
        </Text>
      </Block>
      <InputWrapper>
        <Swap
          is={type === 'presearch'}
          isSlot={
            <InputButtonGroup
              direction={InputButtonGroupDirection.Vertical}
              hasError={!!error}
              errorMessage={error}>
              <InputButton
                value={label}
                label={t(i18nBase + '.Title')}
                aria-label={t(i18nBase + '.Title')}
                onClick={() => {
                  modal?.handleOpen()
                }}
              />
            </InputButtonGroup>
          }>
          <div onClick={costAllocationData.handleModalOpen}>
            <TextField
              label={t(i18nBase + '.Title')}
              value={label || ''}
              helperText={error}
              helperType="error"
              isReadonly
            />
          </div>
        </Swap>
      </InputWrapper>
      <SelectAllocationModal
        isOpen={modal?.isOpen ?? false}
        isAdaptivePosition
        data={costAllocationData}
      />
    </Block>
  )
})
