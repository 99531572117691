import type { CalculateHotelEmissionsBatchResultHotel } from '@fiji/graphql/types'

type GetHotelEmissionInput = {
  hotelId?: string
  hotelsEmissions: {
    averageSustainabilityScore: number
    existingHotelEmissions: CalculateHotelEmissionsBatchResultHotel[]
    loading: boolean
  }
}

export function getHotelEmission({ hotelId, hotelsEmissions }: GetHotelEmissionInput) {
  const hotelSustainability = hotelsEmissions.existingHotelEmissions.find(
    (hotelEmission) => hotelEmission.customRef === hotelId,
  )

  return {
    loading: hotelsEmissions.loading,
    averageLocationSustainabilityScore: hotelsEmissions.averageSustainabilityScore,
    averageLocationEmissionsTonnes: hotelSustainability?.tonnesOfEmissions,
    sustainabilityScore: hotelSustainability?.sustainabilityScore,
    certifications: hotelSustainability?.certifications,
    customRef: hotelSustainability?.customRef,
    equivalences: hotelSustainability?.equivalences,
  }
}
