import type { ReactNode } from 'react'
import { KeyType } from '@fiji/enums'
import { useKeyboardControl } from '@fiji/hooks/use-keyboard-control'
import type { SeatList } from '../../seat-map-segment.types'
import { checkShowRowNumber } from '../check-row-number-visible'
import { Wrapper, Container, SeatRowNumber } from './seat-item-container-styled'

type SeatItemContainerProps = {
  children: ReactNode
  seatList: SeatList
  isEconomyClass: boolean
  role: string
  seatIndex: number
  rowNumber?: number
  mostFrequentAislesColumns: string[]
  actualRowLength: number
  onPress?: () => void
}

export function SeatItemContainer({
  children,
  seatList,
  isEconomyClass,
  role,
  seatIndex,
  rowNumber,
  mostFrequentAislesColumns,
  onPress,
  actualRowLength,
}: SeatItemContainerProps) {
  const shouldShowNumber = checkShowRowNumber(seatIndex, seatList, mostFrequentAislesColumns)
  const { onKeyUp } = useKeyboardControl<HTMLDivElement>({
    onPress: onPress ?? (() => {}),
    keyType: KeyType.Click,
  })

  return (
    <>
      {shouldShowNumber && (
        <Wrapper>
          <SeatRowNumber actualRowLength={actualRowLength} isEconomyClass={isEconomyClass}>
            {rowNumber}
          </SeatRowNumber>
        </Wrapper>
      )}
      <Wrapper>
        <Container
          actualRowLength={actualRowLength}
          data-tracking-id={`seat-${seatIndex}`}
          isEconomyClass={isEconomyClass}
          role={role}
          onKeyUp={onKeyUp}>
          {children}
        </Container>
      </Wrapper>
    </>
  )
}
