import styled, { css } from 'styled-components'

const BORDER_RADIUS = '14px'

const background = css`
  background-color: ${({ theme }) => theme.colors.white};
`

export const Container = styled.div`
  border-radius: ${BORDER_RADIUS};
  margin-bottom: 36px;
`

export const Body = styled.div`
  border-radius: ${BORDER_RADIUS};
  ${background}
  padding: 20px 24px 20px 20px;
`

export const Main = styled.div`
  display: flex;
  gap: 8px;
`

export const LeftSide = styled.div``

export const RightSide = styled.div`
  width: 100%;
`
