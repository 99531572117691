import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace DisplayConfigurationErrors {
  export const DisplayConfigurationResponse = createErrorClass('Failed display configuration')
  export const DisplayConfigurationUnexpectedError = createErrorClass(
    'Processed display configuration',
  )
}

export type DisplayConfigurationErrorsInstances = InstanceType<
  typeof DisplayConfigurationErrors[keyof typeof DisplayConfigurationErrors]
>
