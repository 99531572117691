import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Separator } from '@etta/ui/separator'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { ShowMoreContainer } from '@etta/ui/show-more-container'
import { useExpand } from '@fiji/hooks/use-expand'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { List, Column, TwoColumns, ShowMoreButton } from './details-layout-mobile-styled'

type Props = {
  travelerName: string
  recordLocator?: string | null
  creationTime?: string | null
  bookingRef: string
  airTicketNumbers?: string[]
  printETicketReceipt?: (index: number) => void
}

export function BookingRecord({
  travelerName,
  recordLocator,
  creationTime,
  bookingRef,
  airTicketNumbers,
  printETicketReceipt,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'
  const { isExpanded, toggleExpansion } = useExpand()

  return (
    <List>
      <Column>
        <Text variant="captionMedium" color="bodyText">
          {t(i18nBase + 'Traveler')}
        </Text>
        <Text variant="subHeadStrong">{travelerName}</Text>
      </Column>
      <Separator color={'borderDark'} lineType="dashed" />
      <TwoColumns>
        <div>
          <Text variant="captionMedium" color="bodyText" isBlock>
            {t(i18nBase + 'PNRRecordLocator')}
          </Text>
          <Text variant="subHeadStrong" isBlock>
            {recordLocator}
          </Text>
        </div>
        <div>
          <Text variant="captionMedium" color="bodyText" isBlock>
            {t(i18nBase + 'BookedOn')}
          </Text>
          <Text variant="subHeadStrong" isBlock>
            {creationTime && dateFormat(new Date(deleteTimezone(creationTime)), 'ccc, LLL d, yyyy')}
          </Text>
        </div>
      </TwoColumns>
      <Separator color={'borderDark'} lineType="dashed" />
      <ShowMoreContainer
        collapsedSlot={<></>}
        expandedSlot={
          <>
            <Column>
              <Text variant="captionMedium" color="bodyText">
                {t(i18nBase + 'BookingRef')}
              </Text>
              <Text variant="subHeadStrong">{bookingRef}</Text>
            </Column>
            <Separator color={'borderDark'} lineType="dashed" />
            {airTicketNumbers && airTicketNumbers.length > 0 && (
              <>
                <Column>
                  <Text variant="captionMedium" color="bodyText">
                    {t(i18nBase + 'ETicketReceipt')}
                  </Text>
                  {airTicketNumbers.map(
                    (number, index) =>
                      number &&
                      printETicketReceipt && (
                        <Block key={number} isSpaceBetween>
                          <Text variant="subHeadStrong">{number}</Text>
                          <Button
                            variant="text"
                            size="small"
                            paddings={false}
                            onClick={() => printETicketReceipt(index)}>
                            {t(i18nBase + 'Download')}
                          </Button>
                        </Block>
                      ),
                  )}
                </Column>
                <Separator color={'borderDark'} lineType="dashed" />
              </>
            )}
          </>
        }
        isExpanded={isExpanded}
        isAnimated
      />
      <Column>
        <ShowMoreButton onClick={toggleExpansion}>
          {isExpanded ? t(i18nBase + 'ShowLess') : t(i18nBase + 'ShowMore')}
          <Icon
            name={isExpanded ? 'chevronUpPWA' : 'chevronDownPWA'}
            size="small"
            color="primary"
          />
        </ShowMoreButton>
      </Column>
    </List>
  )
}
