import { useCallback } from 'react'
import type { ValueType, OptionType } from '@etta/ui/drop-down-list'
import { DropDownList, ListTypes } from '@etta/ui/drop-down-list'
import type { CarRentalFilterType } from '@fiji/types'
import { CarRentalFiltersType } from '@fiji/enums'

type Props = {
  label: string
  isDisabled?: boolean
  onChange(value: ValueType[]): void
  value?: ValueType[]
  options: OptionType[]
  type?: ListTypes
  defaultLabel?: string
  filtersType: CarRentalFiltersType
  filters: CarRentalFilterType[]
  'data-tracking-id'?: string
}

export function CarRentalOfficeDropdown({
  label,
  options,
  value,
  onChange,
  isDisabled,
  type = ListTypes.Radio,
  defaultLabel,
  filtersType,
  filters,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const onChangeFilters = useCallback(
    (chosenValues: ValueType[]) => {
      const mappedValues = []

      switch (filtersType) {
        case CarRentalFiltersType.Class:
        case CarRentalFiltersType.Supplier:
        case CarRentalFiltersType.Transmission:
        case CarRentalFiltersType.Fuel:
          const values = chosenValues
            .map((i) => filters.find((item) => item.value === i))
            .map((i) => i?.code)
          mappedValues.push(...values)
          break
      }

      onChange(mappedValues)
    },
    [filtersType, onChange, filters],
  )

  return (
    <DropDownList
      data-tracking-id={dataTrackingId}
      label={label}
      options={options}
      value={value}
      onChange={onChangeFilters}
      isDisabled={isDisabled}
      type={type}
      defaultLabel={defaultLabel}
      isOutsideAlignRight={CarRentalFiltersType.Fuel === filtersType}
      withNoneOption={false}
    />
  )
}
