import type { FlightSafetyCheckDetails, SafetyCheckFlight } from '@fiji/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { FlightSafetyCheckNotAvailable } from './flight-safety-check-not-available'
import { FlightSafetyCheckSkeleton } from './flight-safety-check-skeleton'
import { FlightSafetyCheckLayout } from './layout'
import { useFlightSafetyCheck } from './use-flight-safety-check'
import { Container } from './flight-safety-check-styled'
import { FlightSafetyCheckModal } from './flight-safety-check-modal'

type Props = {
  flightSafetyChecks?: SafetyCheckFlight[]
  flightSafetyCheckDetails?: FlightSafetyCheckDetails[]
  isSafetyChecksLoading?: boolean
  isSafetyChecksError?: boolean
  refetchSafetyChecks?: () => void
}

export function FlightSafetyCheck({
  isSafetyChecksLoading,
  isSafetyChecksError,
  flightSafetyChecks,
  flightSafetyCheckDetails,
  refetchSafetyChecks,
}: Props) {
  const {
    isSafetyChecksExpanded,
    isSafetyChecksHeadlines,
    handleSafetyChecksExpand,
    uniqSafetyCheckIconNames,
  } = useFlightSafetyCheck({
    flightSafetyChecks,
    refetchSafetyChecks,
  })
  const { isOpen, handleOpen, handleClose } = useTogglePopup()

  if (isSafetyChecksLoading) {
    return (
      <Container>
        <FlightSafetyCheckSkeleton />
      </Container>
    )
  }

  if (isSafetyChecksError) {
    return null
  }

  if (!isSafetyChecksHeadlines) {
    return (
      <Container>
        <FlightSafetyCheckNotAvailable />
      </Container>
    )
  }

  return (
    <>
      <FlightSafetyCheckLayout
        safetyChecks={flightSafetyChecks}
        safetyCheckDetails={flightSafetyCheckDetails}
        isExpanded={isSafetyChecksExpanded}
        onModalOpen={handleOpen}
        handleExpand={handleSafetyChecksExpand}
        uniqIconNames={uniqSafetyCheckIconNames}
      />
      <FlightSafetyCheckModal
        isVisible={isOpen}
        onClose={handleClose}
        flightSafetyCheckDetails={flightSafetyCheckDetails}
      />
    </>
  )
}
