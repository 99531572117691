import styled from 'styled-components'
import { title2, subHeadMedium } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`

export const Content = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  flex-grow: 1;
  overflow: auto;
`

export const Header = styled.div`
  height: 270px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.plum};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const HeaderTitle = styled.p`
  ${title2}
  margin: 0;
  padding: 0;
  padding-top: 8px;
  color: ${(props) => props.theme.colors.mainText2};
`

export const HeaderDescription = styled.p`
  ${subHeadMedium}
  margin: 0;
  padding: 8px 20px;
  color: ${(props) => props.theme.colors.mainText2};
`

export const ConfirmationDetails = styled.div`
  padding: 0 16px;
  margin-top: -40px;
`

export const Footer = styled.div`
  padding: 24px 16px;
  background-color: ${(props) => props.theme.colors.background};
`

export const IconWrapper = styled(Icon)`
  transform: rotate(-45deg);
`

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`
export const UberQrCodeWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin: 16px;
`
