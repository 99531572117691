import { useEffect } from 'react'

export function InitialSpinnerRemoval() {
  useEffect(() => {
    const loader = document.querySelector('#initial-loader')
    loader?.parentNode?.removeChild(loader)
    const style = document.querySelector('[data-id=initial-loader-styles]')
    style?.parentNode?.removeChild(style)
  }, [])

  return null
}
