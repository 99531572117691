import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { Skin } from '../types'
import { ContentWrapper } from './cancel-trip-not-allowed-modal-styled'

type Props = {
  skin?: Skin
  trainCancelTitle?: string
  trainErrorDescription?: string
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'
const i18nBaseAccessibility = 'Accessibility.'

export const CancelTripNotAllowedModal = observer(function CancelTripNotAllowedModal({
  skin = 'full-screen',
  trainCancelTitle,
  trainErrorDescription,
}: Props) {
  const { postBookingCancellationStore, postBookingCancellationActions } = usePostBookingContext()
  const { onViewSupport } = postBookingCancellationActions
  const { cancelTripToggle, cancellationTypeTitle } = postBookingCancellationStore
  const { t } = useTranslation()
  const ctaSpacingTop = skin === 'sidebar' ? 14 : 20
  const isTrainTrip = cancellationTypeTitle === 'Train'
  const titleLabel = isTrainTrip
    ? trainCancelTitle
    : `${t(`${i18nBase}.Title`)} ${cancellationTypeTitle}`
  const descriptionLabel = isTrainTrip
    ? trainErrorDescription
    : t(`${i18nBase}.ErrorDescription`, {
        type: cancellationTypeTitle.toLowerCase(),
      })
  const supportButtonText = t(`${i18nBase}.Support`)
  const backButtonText = t(`${i18nBase}.Back`)

  return (
    <Dialog
      onClose={cancelTripToggle.handleClose}
      isOpen={cancelTripToggle.isOpen}
      horizontalDimension={skin === 'sidebar' ? 'narrow-desktop' : undefined}>
      <ContentWrapper>
        <Text variant="title3" color="mainText" aria-label={titleLabel}>
          {titleLabel}
        </Text>
        <Block marginTop={24}>
          <Text
            variant="footnoteMedium"
            color="bodyText"
            align="center"
            aria-label={descriptionLabel}>
            {descriptionLabel}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          <Button
            variant="solid"
            onClick={onViewSupport}
            fullWidth
            aria-label={t(i18nBaseAccessibility + 'ButtonTapTo', {
              button: supportButtonText,
            })}>
            {supportButtonText}
          </Button>
        </Block>
        <Block isFullWidth marginTop={ctaSpacingTop}>
          <Button
            variant="outline"
            onClick={cancelTripToggle.handleClose}
            fullWidth
            aria-label={t(i18nBaseAccessibility + 'ButtonTapTo', {
              button: backButtonText,
            })}>
            {backButtonText}
          </Button>
        </Block>
      </ContentWrapper>
    </Dialog>
  )
})
