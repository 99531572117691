import { useEffect, useState } from 'react'
import type { DynamicSiteMessage } from '@fiji/graphql/types'
import { useBookTripContext } from '@etta/modules/booking'
import { useGetTripDetailsQuery } from './use-get-trip-details-query'

export function useBookTrip() {
  const { bookTripActions, bookTripStore } = useBookTripContext()
  const { handleDropBookTripState } = bookTripActions
  const { errorMessages, isBooked, isError, isLoading, tripConfirmationType } = bookTripStore
  const [bookDynamicSiteMessages, setBookDynamicSiteMessages] = useState<DynamicSiteMessage[]>([])

  const {
    isLoadingAllocatedSeat,
    allocatedSeatMap,
    isRailEticketDeliveryOption,
  } = useGetTripDetailsQuery()

  useEffect(() => {
    bookTripActions.handleSetShouldKeepBookTripState(false)
    return () => {
      if (bookTripStore.shouldKeepBookTripState) {
        return
      }
      handleDropBookTripState()
    }
  }, [handleDropBookTripState, bookTripStore, bookTripActions])

  const handleBook = async ({
    bookingId,
    payment,
    putOnHold,
  }: Parameters<typeof bookTripActions.handleBookTrip>[0]) => {
    const res = await bookTripActions.handleBookTrip({
      bookingId,
      payment,
      putOnHold,
    })

    setBookDynamicSiteMessages(res?.messages ?? [])
  }

  return {
    isRailEticketDeliveryOption,
    isBooked,
    tripConfirmationType,
    handleBookTrip: handleBook,
    isBookTripLoading: isLoadingAllocatedSeat || isLoading,
    isBookTripError: isError,
    errorMsg: errorMessages,
    railAllocatedSeatMap: allocatedSeatMap,
    tripDynamicSiteMessages: bookDynamicSiteMessages,
  }
}
