import type { ReactNode, RefObject } from 'react'
import { Anchor } from './anchor'
import { useScrollByContent } from './use-scroll-by-content/use-scroll-by-content'
import { ScrollByContentContext } from './scroll-by-content-context'

type Props = {
  scrollContainerRef: RefObject<HTMLDivElement>
  onAnchorAppear: (name: string) => void
  children: ReactNode
}

function ScrollByContent({ children, scrollContainerRef, onAnchorAppear }: Props) {
  const { contextValue, topAnchorRef } = useScrollByContent({
    scrollContainerRef,
    onAnchorAppear,
  })

  return (
    <>
      <ScrollByContentContext.Provider value={contextValue}>
        <div ref={topAnchorRef} />
        {children}
      </ScrollByContentContext.Provider>
    </>
  )
}

const ScrollByNamespace = Object.assign(ScrollByContent, {
  Anchor,
})

export { ScrollByNamespace as ScrollByContent }
