import styled from 'styled-components'
import { footnoteStrong, subHeadMedium } from '@fiji/style'
import type { CardLayoutType } from '../../../types'

export const RowContainer = styled.div<{ withPaddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding-bottom: ${(props) => props.withPaddingBottom && '16px;'};
`

export const CardContainer = styled.div<{ withPadding?: boolean }>`
  width: 100%;
  padding: ${({ withPadding }) => withPadding && '0 8px;'};
`

export const CVVFieldWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding-top: 16px;
  line-height: 16px;
`

export const CVVField = styled.div`
  width: 156px;
`

export const CardInfoContainer = styled.div<{ layout?: CardLayoutType }>`
  display: flex;
  flex-direction: column;
  ${(p) => {
    switch (p.layout) {
      case 'summary':
        return 'margin-left: 9px;'

      default:
        return 'margin-left: 19px;'
    }
  }}
  padding-right: 5px;
`

export const CardName = styled.div<{ layout?: CardLayoutType }>`
  color: ${({ theme }) => theme.colors.mainText};
  margin-bottom: 3px;
  ${(p) => (p.layout === 'summary' ? subHeadMedium : footnoteStrong)};
`
