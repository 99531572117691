import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import type { PlainTime as Time } from '@fiji/types'
import type { MobilityLegType } from '@fiji/enums'
import { CarLegType, FlightLegType } from '@fiji/enums'
import { placeFormatTime } from '../format-dates'

type Args = {
  time?: Time
  type: CarLegType | FlightLegType | MobilityLegType
  segmentType: SegmentType
}

const i18Base = 'TripPlanner.BaseSearch'
const i18Input = 'TripPlanner.BaseSearch.Input'

export function useTime({ time, type, segmentType }: Args) {
  const { t } = useTranslation()
  const selectedTime = useMemo(() => {
    return segmentType === SegmentType.Mobility
      ? placeFormatTime(time).toLocaleLowerCase()
      : placeFormatTime(time)
  }, [segmentType, time])

  const selectedTimeLabel = useMemo(() => {
    if (segmentType === SegmentType.CarRental) {
      const isPickUp = type === CarLegType.PickUp
      return t(i18Input + (isPickUp ? '.PickUpTime' : '.DropoffTime'))
    }

    if (segmentType === SegmentType.Mobility) {
      return t(i18Input + '.PickUpTime')
    }

    const isDepartOn = type === FlightLegType.Departure
    return t(i18Input + (isDepartOn ? '.Leaving' : '.Arriving'))
  }, [segmentType, t, type])

  const timePlaceholder = t(i18Input + '.SelectTime')

  const ariaLabelTime = selectedTime
    ? t(i18Base + '.Input.TimeSelectedAriaLabel', {
        label: selectedTimeLabel,
        value: selectedTime,
      })
    : t(i18Base + '.Input.TimeAriaLabel', { label: selectedTimeLabel })

  return {
    ariaLabelTime,
    selectedTime,
    selectedTimeLabel,
    timePlaceholder,
  }
}
