import { useTranslation } from 'react-i18next'
import { RoomOutOfPolicy } from '@etta/components/hotel-room/room-out-of-policy'
import { RoomBadges } from '@etta/components/hotel-room/room-badges'
import { Price } from '@etta/components/hotel-room/price'
import { Description } from '@etta/components/hotel-room/description'
import { Cancellation } from '@etta/components/hotel-room/cancellation'
import { RoomTypeHeader } from '@etta/components/hotel-room/room-type-header'
import type { LayoutProp } from '../types'
import {
  Container,
  Footer,
  Header,
  RoomWrapper,
  Note,
  TopHeaderWrapper,
} from './hotel-room-mobile-styled'

export function HotelRoomMobile({
  submitButtonSlot,
  room,
  ratesLinkSlot,
  'data-tracking-id': dataTrackingId,
  isSelectable,
  showDifferingRatesWarning,
}: LayoutProp) {
  const {
    policy,
    cancellationPolicy,
    preferredTag,
    rateQualifier,
    type,
    source,
    averageRate,
    dailyRates,
    description,
    cancellationPolicyDescription,
  } = room
  const { isInPolicy, outOfPolicyReasons, outOfPolicyData } = policy
  const { t } = useTranslation()
  const i18Base = 'HotelInfo.Rooms'

  return (
    <Container data-tracking-id={dataTrackingId}>
      <Header>
        <TopHeaderWrapper>
          <RoomWrapper>
            <RoomTypeHeader rateQualifier={rateQualifier} roomType={type} source={source} />
            <RoomBadges
              rateQualifier={rateQualifier}
              preferredTag={preferredTag}
              cancellationPolicy={cancellationPolicy}
            />
          </RoomWrapper>
          <Price averageRate={averageRate} dailyRates={dailyRates} skin="two-rows" />
        </TopHeaderWrapper>
        {!isInPolicy && (
          <RoomOutOfPolicy
            outOfPolicyReasons={outOfPolicyReasons}
            outOfPolicyData={outOfPolicyData}
            mainTextColor="error"
          />
        )}
        <Description description={description} isCut />
        <Cancellation
          cancellationPolicy={cancellationPolicy}
          cancellationPolicyDescription={cancellationPolicyDescription}
          isWrapped
        />
        {showDifferingRatesWarning && (
          <Note variant="footnoteStrong" color="mainText">
            {t(i18Base + '.RoomDetails.DifferentDailyRates')}
          </Note>
        )}
      </Header>
      <Footer isSelectable={isSelectable}>
        {ratesLinkSlot}
        {submitButtonSlot}
      </Footer>
    </Container>
  )
}
