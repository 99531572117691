import i18next from 'i18next'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type {
  CarRentalWorkingHoursValueObject,
  CarRentalCarDetailsValueObject,
} from '@etta/modules/car-rental-search/core/value-objects'
import type { CarVendorInformation } from '../types'

type Args = {
  carDetails: CarRentalCarDetailsValueObject | null
}

export function getVendorInformation({ carDetails }: Args): CarVendorInformation | null {
  if (!carDetails) {
    return null
  }

  const carRentalWorkingHours = carDetails.pickUp.workingHours
  const imageUrl = carDetails.vendor.imageUrl
  const title = `${carDetails.vendor.name}, ${formatPickUpDropOffAddress(
    carDetails.pickUp.address,
  )}`
  const address = formatPickUpDropOffAddress(carDetails.pickUp.address)
  const workingHours = carRentalWorkingHours?.isAllDay
    ? i18next.t('CarRentalModal.OpenAllDay')
    : getWorkingHours(carRentalWorkingHours)
  const phone = carDetails.pickUp.phone?.number

  return { imageUrl, title, workingHours, phone, name: carDetails.vendor.name, address }
}

function getWorkingHours(workingHours: CarRentalWorkingHoursValueObject) {
  function formatWorkingHours(time: string) {
    const [hours, minutes] = time.split(':')
    return getFormattedTime({ hours: parseInt(hours), minutes: parseInt(minutes) }, 'h:mm aa')
  }

  const startHourFormatted = workingHours ? formatWorkingHours(workingHours.start) : ''
  const endHourFormatted = workingHours ? formatWorkingHours(workingHours.end) : ''

  return `${startHourFormatted} - ${endHourFormatted}`
}
