import type { PropsWithChildren } from 'react'

import { Root } from './text-field-group-components'
import type { Props } from './types'

export function TextFieldGroup({ children, variant, hasTopBorder }: PropsWithChildren<Props>) {
  return (
    <Root hasTopBorder={hasTopBorder} variant={variant} role="group">
      {children}
    </Root>
  )
}
