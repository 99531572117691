import { Store } from '@etta/di'
import type { PasswordSecurityConfigureEntity } from '@etta/modules/user/core/entities/password-security-configure.entity'
import { SsoTypeVariantEnum } from '@etta/modules/auth/core/enums/sso-type-variant.enum'

export enum ValidationStateEnum {
  empty,
  success,
  error,
}

export type Validator = {
  isMinLength?: ValidationStateEnum
  isContainName?: ValidationStateEnum
  isPasswordNotEmpty?: ValidationStateEnum
  isAlphanumeric?: ValidationStateEnum
  isDictionary?: ValidationStateEnum
  isRecently?: ValidationStateEnum
  isPasswordMatch?: ValidationStateEnum
}

@Store()
export class CreatePasswordStore {
  private _password: string | null = null
  private _confirmPassword: string | null = null
  private _passwordSecurityConfigureEntity: PasswordSecurityConfigureEntity | null = null
  private _ssoType: SsoTypeVariantEnum | null = null

  private _passwordValidator: Validator = {
    isMinLength: ValidationStateEnum.empty,
    isContainName: ValidationStateEnum.empty,
    isPasswordNotEmpty: ValidationStateEnum.empty,
    isAlphanumeric: ValidationStateEnum.empty,
    isDictionary: ValidationStateEnum.empty,
    isRecently: ValidationStateEnum.empty,
  }

  private _confirmValidator: Validator = {
    isPasswordMatch: ValidationStateEnum.empty,
  }

  setPasswordSecurityConfigureEntity(entity: PasswordSecurityConfigureEntity) {
    this._passwordSecurityConfigureEntity = entity
  }

  setPassword(value: string | null) {
    this._password = value
  }

  setValidationWith(validator: Validator) {
    this._passwordValidator = validator
  }

  setConfirmValidationWith(validator: Validator) {
    this._confirmValidator = validator
  }

  setConfirmPassword(value: string | null) {
    this._confirmPassword = value
  }

  setSsoType(value: SsoTypeVariantEnum) {
    this._ssoType = value
  }

  get password(): string {
    return this._password || ''
  }

  get confirmPassword(): string {
    return this._confirmPassword || ''
  }

  get passwordMinLength() {
    return this._passwordSecurityConfigureEntity?.passwordMinLength
  }

  get isForceAlphaNumericPassword() {
    return this._passwordSecurityConfigureEntity?.forceAlphaNumericPassword
  }

  get isDictionaryWordsNotAllowed() {
    return this._passwordSecurityConfigureEntity?.dictionaryWordsNotAllowed
  }

  get isPasswordReusability() {
    return this._passwordSecurityConfigureEntity?.passwordReusability
  }

  get errors() {
    return {
      password: this.getKeysWithValidationErrors(this._passwordValidator),
      confirmPassword: this.getKeysWithValidationErrors(this._confirmValidator),
    }
  }

  get validationErrors(): Validator {
    const password = this._passwordValidator
    const confirmPassword = this._confirmValidator
    return {
      isMinLength: this.getValidationResult(password.isMinLength),
      isContainName: this.getValidationResult(password.isContainName),
      isPasswordNotEmpty: this.getValidationResult(password.isPasswordNotEmpty),
      isPasswordMatch: this.getValidationResult(confirmPassword.isPasswordMatch),
      isAlphanumeric: this.getValidationResult(password.isAlphanumeric),
      isRecently: this.getValidationResult(password.isRecently),
      isDictionary: this.getValidationResult(password.isDictionary),
    }
  }

  get isPasswordValid() {
    const isPasswordValid = Object.keys(this._passwordValidator).every(
      (key) => this._passwordValidator[key as keyof Validator] !== ValidationStateEnum.error,
    )
    const isConfirmPasswordValid = Object.keys(this._confirmValidator).every(
      (key) => this._confirmValidator[key as keyof Validator] !== ValidationStateEnum.error,
    )
    return isPasswordValid && isConfirmPasswordValid
  }

  get ssoType() {
    return this._ssoType
  }

  get isCheckingSSO() {
    return this._ssoType === null
  }

  get isSSOOnlySite() {
    return this._ssoType === SsoTypeVariantEnum.Sso
  }

  get isHybrid() {
    return this._ssoType === SsoTypeVariantEnum.Both
  }

  dropStore() {
    this.resetValidator(this._passwordValidator)
    this.resetValidator(this._confirmValidator)
    this._password = null
    this._confirmPassword = null
  }

  private resetValidator(validator: Record<string, ValidationStateEnum>) {
    Object.keys(validator).forEach((key) => {
      validator[key] = ValidationStateEnum.empty
    })
  }

  private getKeysWithValidationErrors(validator: Record<string, ValidationStateEnum>) {
    return Object.keys(validator).filter((key) => validator[key] === ValidationStateEnum.error)
  }

  private getValidationResult(validationStates?: ValidationStateEnum): ValidationStateEnum {
    return validationStates ? validationStates : ValidationStateEnum.empty
  }
}
