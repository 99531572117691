import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { CheckoutInfoActions } from './checkout-info.actions'
import { CheckoutInfoStore } from './checkout-info.store'
import { CheckoutInfoPreferencesAction } from './checkout-info.preferences.actions'
import { CheckoutInfoBillingAction } from './billing/checkout-info-billing.action'
import { CheckoutInfoTravelerAction } from './traveler/checkout-info-traveler.action'
import { CheckoutInfoDocumentAction } from './documents/checkout-info-documents.action'
import { CheckoutInfoDocumentStore } from './documents/checkout-info-documents.store'

export const { useModuleContext: useCheckoutInfoContext } = hookContextFactory({
  checkoutInfoActions: CheckoutInfoActions,
  checkoutInfoPreferencesActions: CheckoutInfoPreferencesAction,
  checkoutInfoStore: CheckoutInfoStore,
  checkoutInfoBillingAction: CheckoutInfoBillingAction,
  checkoutInfoTravelerAction: CheckoutInfoTravelerAction,
  checkoutInfoDocumentStore: CheckoutInfoDocumentStore,
  checkoutInfoDocumentAction: CheckoutInfoDocumentAction,
})
