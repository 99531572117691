import { useLayoutEffect, useRef } from 'react'
import { Checkbox } from '@etta/ui/checkbox'
import type { BaseTextFieldProps } from '@etta/ui/text-field/base-text-field'
import { FieldDescription } from '@etta/ui/field-description'
import { CheckboxContainer, Wrapper } from '../custom-field-styled'

type Props = {
  onChangeHandler: () => void
  label: string
  helperText?: string
  checked: boolean
  description?: string | null
  firstErrorUUID?: string
  fieldUUID?: string
} & Omit<BaseTextFieldProps, 'value'>

export function CheckboxField({
  onChangeHandler,
  label,
  helperText,
  checked,
  description,
  firstErrorUUID,
  fieldUUID,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (helperText && firstErrorUUID === fieldUUID) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      ref.current?.focus()
    }
  }, [firstErrorUUID, fieldUUID, helperText])

  return (
    <div ref={ref}>
      <Wrapper>
        {description && <FieldDescription description={description} />}
        <CheckboxContainer>
          <Checkbox
            // TODO: form typings only support strings but actual logic supports any primitive
            // so we need to fix typings for form
            // @ts-ignore
            onChange={onChangeHandler}
            label={label}
            // @ts-ignore
            checked={checked}
            value=""
            helperText={helperText}
            helperType="error"
          />
        </CheckboxContainer>
      </Wrapper>
    </div>
  )
}
