import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'

export function TripDetailsDynamicSiteMessagesDesktop({ messages }: LayoutProps) {
  return (
    <Block marginBottom={32}>
      <DynamicSiteMessages messages={messages} />
    </Block>
  )
}
