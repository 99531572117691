import type { MouseEvent } from 'react'
import type { TripStatus } from '@fiji/graphql/types'
import { getSegmentStatusColor } from './layout/desktop/get-segment-status-color'

type Args = {
  segmentStatus?: TripStatus | null
  label?: string
}

export function useSegmentCardStatus({ label, segmentStatus }: Args) {
  const handleCardActionClick = (ev: MouseEvent<HTMLButtonElement>, onClick?: () => void) => {
    onClick?.()
    ev.stopPropagation()
  }

  const displayLabel = label && segmentStatus ? `${label} • ` : label
  const displayStatus = segmentStatus ? segmentStatus.split('_').join('') : segmentStatus
  const statusColor = getSegmentStatusColor(segmentStatus)

  return {
    handleCardActionClick,
    displayLabel,
    displayStatus,
    statusColor,
  }
}
