import type {
  TripSegmentValueObject,
  CarRentalSegmentEntity,
  HotelSegmentEntity,
  FlightSegmentEntity,
  TrainSegmentEntity,
} from '@etta/modules/review-trip/core'
import { SegmentState } from '@fiji/graphql/types'

type Args = {
  oopSegments: {
    flights: FlightSegmentEntity[]
    hotels: HotelSegmentEntity[]
    carRentals: CarRentalSegmentEntity[]
    rails: TrainSegmentEntity[]
  }
  bookingId?: string
  isOnHold?: boolean
}

export function getBookingOopSegments({ oopSegments, bookingId, isOnHold }: Args) {
  if (!bookingId) {
    return oopSegments
  }

  const filterOutNotChangedOrNewSegment = (segment: TripSegmentValueObject) => {
    if (isOnHold) {
      return segment
    }

    return (
      !!segment.segmentState &&
      [SegmentState.Changed, SegmentState.New].includes(segment.segmentState)
    )
  }

  return {
    flights: oopSegments.flights.filter(filterOutNotChangedOrNewSegment),
    hotels: oopSegments.hotels.filter(filterOutNotChangedOrNewSegment),
    carRentals: oopSegments.carRentals.filter(filterOutNotChangedOrNewSegment),
    rails: oopSegments.rails.filter(filterOutNotChangedOrNewSegment),
  }
}
