import { Action, Inject } from '@etta/di'
import type { SelectedFiltersValueObject } from '../../core/value-objects/selected-filters.value-object'
import { HotelSearchFormQueryService } from '../services/hotel-search-form-query.service'
import { LoadHotelsService } from '../services/load-hotels.service'
import { HotelFiltersStore } from '../stores/hotel-filters.store'
import { HotelQueryStore } from '../stores/hotel-search-query.store'
import { HotelSearchResultsStore } from '../stores/hotel-search-results.store'
import { HotelQuickFiltersStore } from '../stores/quick-filters.store'

@Action()
export class HotelQuickFiltersAction {
  constructor(
    @Inject()
    private readonly hotelFiltersStore: HotelFiltersStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly loadHotelsService: LoadHotelsService,
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly hotelQuickFiltersStore: HotelQuickFiltersStore,
  ) {}

  async handleApplyCategories() {
    this.hotelQuickFiltersStore.categoriesModal.handleClose()
    await this.handleApplySpecificFilter('groupId')
  }

  async handleApplyAmenities() {
    this.hotelQuickFiltersStore.amenitiesModal.handleClose()
    await this.handleApplySpecificFilter('amenityIds')
  }

  async handleApplyStarRating() {
    this.hotelQuickFiltersStore.starRatingModal.handleClose()
    await this.handleApplySpecificFilter('starRatings')
  }

  async handleApplyBrands() {
    this.hotelQuickFiltersStore.brandsModal.handleClose()
    await this.handleApplySpecificFilter('brandIds')
  }

  private async handleApplySpecificFilter(key: keyof SelectedFiltersValueObject) {
    const existingFilters = this.hotelQueryStore.hotelSearchQuery.hotelFilters

    if (!existingFilters) {
      return
    }
    this.hotelSearchResultsStore.setIsLoading(true)
    this.hotelSearchResultsStore.setOffset(0)

    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.appendQueryParams({
          hotelFilters: {
            ...existingFilters,
            [key]: this.hotelFiltersStore.selectedFilters[key],
          },
        }),
      )
    })

    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }
}
