import type { DomainError } from './domain.error'

export function createErrorClass<ErrorType = any | undefined>(message: string) {
  return class ErrorClass implements DomainError {
    message: string
    error: ErrorType
    stack?: string

    constructor(error: ErrorType) {
      this.stack = new Error().stack
      this.message = message
      this.error = error
    }
  }
}
