import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { StyledBlock, StyledText, StyledButton, Container } from './filter-container-styled'

type Props = PropsWithChildren<{
  title: string
  selectedNumber: number
  isClearButtonDisabled: boolean
  onClear: () => void
  withBottomBorder?: boolean
}>

const i18Base = 'QuickFilters.'

export const FilterContainer = observer(function FilterContainer({
  title,
  children,
  selectedNumber,
  isClearButtonDisabled,
  onClear,
  withBottomBorder = true,
}: Props) {
  const { t } = useTranslation()
  return (
    <Block paddingHorizontal={24} paddingTop={35}>
      <Container withBottomBorder={withBottomBorder} paddingBottom={24}>
        <StyledBlock isFlexBlock isFullWidth paddingBottom={13}>
          <StyledText variant="headline" paddingRight={10}>
            {title}
          </StyledText>
          {!!selectedNumber && (
            <>
              {!!selectedNumber && (
                <StyledText variant="subHeadStrong" paddingRight={10}>
                  {t(`${i18Base}Selected`, { selectedNumber })}
                </StyledText>
              )}
              <StyledButton
                variant="text"
                paddings={false}
                onClick={onClear}
                fullWidth={false}
                disabled={isClearButtonDisabled}
                minWidth={0}
                data-tracking-id="all-filters-modal-clear-button">
                <StyledText variant="linkNormal" color="primary">
                  {t(`${i18Base}Clear`)}
                </StyledText>
              </StyledButton>
            </>
          )}
        </StyledBlock>
        {children}
      </Container>
    </Block>
  )
})
