import { checkTrackFilled } from '../../../check-filled'
import type { TrackStyledProps } from './slider-track-components'
import { TrackStyled } from './slider-track-components'

type Props = {
  isDisabled: boolean
  isFilledValue: boolean
} & TrackStyledProps

export function SliderTrack({
  isValueArray,
  isMarksExists,
  isFilledValue,
  isDisabled,
  index,
  ...props
}: Props) {
  const isFilledTrack = !isDisabled && checkTrackFilled(index, isFilledValue, isValueArray)

  return (
    <TrackStyled
      {...(props as TrackStyledProps)}
      className={[props.className, isFilledTrack ? 'is-filled' : ''].join(' ')}
      isValueArray={isValueArray}
      isMarksExists={isMarksExists}
      isFilled={isFilledTrack}
    />
  )
}
