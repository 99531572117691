import { DataProvider } from '@etta/di'
import type { GetIdentityHashQuery, GetIdentityHashQueryVariables } from '@fiji/graphql/hooks'
import { GetIdentityHashDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class IdentityDataProvider extends GraphqlDataProvider {
  getIdentityHash(email: string) {
    return this.client.query<GetIdentityHashQuery, GetIdentityHashQueryVariables>({
      query: GetIdentityHashDocument,
      variables: {
        email,
      },
    })
  }
}
