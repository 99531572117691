import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 0;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.background}; ;
`

export const Section = styled.div`
  margin: 16px 0;
  position: relative;
`

export const RailDetailsSection = styled.div`
  padding: 22px 32px 0px 32px;
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
`

export const RailDetailsEcoCheckContainer = styled.div`
  padding-bottom: 16px;
`

export const TicketRestrictionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.mainText2};
  cursor: pointer;
  border-radius: 10px;
`

export const IconWrapper = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`
