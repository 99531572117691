import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import type { BadgeSkinType } from './types'

export const Item = styled.div<{ skin?: BadgeSkinType }>`
  color: ${(props) => props.theme.colors.bodyText};
  display: flex;
  align-items: center;
  line-height: 19px;
  margin-bottom: ${(p) => {
    switch (p.skin) {
      case 'big-space':
        return '9px'
      default:
        return '5px'
    }
  }};
  &:last-child {
    margin-bottom: 0;
  }
  ${footnoteMedium}
`

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
