import { Store } from '@etta/di'
// TODO: remove fiji type
import type { Place } from '@fiji/types'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import type { PlacesStorageType } from '../../core/value-objects/places-storage.value-object'

@Store()
export class PlacesStorageStore {
  private storage = {
    air: new LocalStorageItem<Place[]>('@air/flight-places', {
      initialValue: [],
    }),
    hotel: new LocalStorageItem<Place[]>('@hotel/hotel-places', {
      initialValue: [],
    }),
    carRental: new LocalStorageItem<Place[]>('@car/car-rentals-places', {
      initialValue: [],
    }),
    rail: new LocalStorageItem<Place[]>('@rail/rail-places', {
      initialValue: [],
    }),
    mobility: new LocalStorageItem<Place[]>('@mobility/mobility-places', {
      initialValue: [],
    }),
  }

  getPlaces(type: PlacesStorageType) {
    const storage = this.storage[type]

    return storage.value ?? []
  }

  addNewPlace(type: PlacesStorageType, place: Place) {
    const storage = this.storage[type]
    const places = this.getPlaces(type)

    const updatedPlaces = [place, ...places]
      .filter(
        (item, index, array) =>
          array.findIndex((item1) => item1.placeId === item.placeId) === index,
      )
      .slice(0, 3)

    storage.set(updatedPlaces)
  }
}
