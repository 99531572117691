import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { TravelPolicyList } from '@etta/components/travel-policy-list/travel-policy-list'
import type { Props } from './types'

export function TravelPolicyDialog({ isVisible, handleVisibility }: Props) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isVisible} handleModalVisibility={handleVisibility}>
      <Modal.Title withBorder>{t('TravelPolicy')}</Modal.Title>
      <Modal.Body>
        <TravelPolicyList />
      </Modal.Body>
    </Modal>
  )
}
