import type { PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import { useDescendantsWrapper } from '@etta/components/descendant'
import { createContext } from '../create-context'
import { useDescendantsKeyDown } from './use-descendants-key-down'
import { DescendantsProvider, useDescendants } from './descendant-context'
import { List } from './descendant-styled'

type UseListReturn = {
  focusIndex: number
  setFocusIndex: (index: number) => void
}

export const [ListProvider, useListContext] = createContext<UseListReturn>()

export function DescendantList({ children, ...rest }: PropsWithChildren<{}>) {
  const descendants = useDescendants()
  const [focusIndex, setFocusIndex] = useState(0)
  const { shouldFocusFirstElement, handleResetFocusState } = useDescendantsWrapper()
  const { handleKeyDown } = useDescendantsKeyDown(descendants, focusIndex)

  useEffect(() => {
    if (shouldFocusFirstElement && descendants.count() > 0) {
      const first = descendants.firstEnabled()
      if (first) {
        first.node?.focus()
        handleResetFocusState()
      }
    }
  }, [descendants, shouldFocusFirstElement, handleResetFocusState])

  return (
    <DescendantsProvider value={descendants}>
      <ListProvider value={{ focusIndex, setFocusIndex }}>
        <List onKeyDown={handleKeyDown} {...rest}>
          {children}
        </List>
      </ListProvider>
    </DescendantsProvider>
  )
}
