import styled from 'styled-components'

export const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  background-color: ${(p) => p.theme.colors.white};

  border-radius: 0 0 14px 14px;
  overflow: hidden;
`
