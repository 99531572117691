import styled, { css } from 'styled-components'
import { slideUp, TRANSITION_DURATION, TRANSITION_FUNCTION } from './blocks'

export const FooterWrapper = styled.div<{
  height?: number
  animate?: boolean
  isAbsolutePosition?: boolean
}>`
  position: ${({ isAbsolutePosition, height }) =>
    css`
      ${isAbsolutePosition && !height ? 'absolute' : 'relative'}
    `};
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ height = 0 }) => height}px;
  ${({ animate = false }) =>
    animate &&
    css`
      transition: height ${TRANSITION_DURATION}ms ${TRANSITION_FUNCTION};
    `};
`

export const FooterContainer = styled.div<{ isAbsolutePosition?: boolean }>`
  padding: 16px;
  box-shadow: 0px -24px 24px rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  display: flex;
  ${slideUp}
`
