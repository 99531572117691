import styled, { css } from 'styled-components'
import { footnoteMedium, getKeyboardNavigationStyle, subHeadMedium } from '@fiji/style'

const disabledStyle = css`
  cursor: default;
  filter: opacity(0.5);
`

export const Wrapper = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 12px;
  border: 1px solid ${(p) => p.theme.colors.borderDark};
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.background};
  padding: 6.5px 12px;
  cursor: pointer;
  width: 100%;
  height: 56px;
  overflow-x: auto;

  &:hover:not(:disabled) {
    border-color: ${(p) => p.theme.colors.primary};
  }

  &:hover:not(:disabled) .searchIcon {
    top: -36px;
  }

  &:hover:not(:disabled) .editIcon {
    top: 4px;
  }

  &:disabled {
    ${disabledStyle}
  }

  ${getKeyboardNavigationStyle({ offset: 0 })}
`

export const IconContainer = styled.div`
  position: relative;
  min-width: 32px;
  min-height: 32px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  overflow: hidden;

  & .searchIcon {
    position: absolute;
    top: 4px;
    left: 4px;
    transition: top 300ms ease-in-out;
  }

  & .editIcon {
    position: absolute;
    top: 36px;
    left: 4px;
    transition: top 300ms ease-in-out;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const LocationsParamsBlock = styled.div<{ isDisabled: boolean }>`
  ${subHeadMedium};
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText1 : p.theme.colors.mainText)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const DatesParamsBlock = styled.div<{ isDisabled: boolean }>`
  ${footnoteMedium};
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText1 : p.theme.colors.bodyText)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
