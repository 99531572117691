import { RailLayoutType } from '@fiji/enums'
import type { SeatPreferences, SeatReservationType } from '@fiji/graphql/types'
import type { Preferences, PreferencesEU, PreferencesUK } from '../types'
import { TripReviewSeatPreferencesEU } from './eu'
import { TripReviewSeatPreferencesUK } from './uk'

type Props = {
  preferenceOptions: SeatPreferences
  layout: RailLayoutType
  seatReservationTypesArray?: SeatReservationType[]
  onPreferencesChange: (newFilters: Partial<Preferences>) => void
  changedPreferences: Preferences
  isRoundTrip: boolean
  isFirstClass: boolean
}

export function TripReviewSeatPreferencesRegion({
  layout,
  preferenceOptions,
  seatReservationTypesArray,
  changedPreferences,
  onPreferencesChange,
  isRoundTrip,
  isFirstClass,
}: Props) {
  switch (layout) {
    case RailLayoutType.EU: {
      return (
        <TripReviewSeatPreferencesEU
          preferences={changedPreferences as PreferencesEU}
          preferenceOptions={preferenceOptions}
          onPreferencesChange={onPreferencesChange}
        />
      )
    }
    case RailLayoutType.UK: {
      return (
        <TripReviewSeatPreferencesUK
          preferences={changedPreferences as PreferencesUK}
          preferenceOptions={preferenceOptions}
          seatReservationTypesArray={seatReservationTypesArray}
          onPreferencesChange={onPreferencesChange}
          isRoundTrip={isRoundTrip}
          isFirstClass={isFirstClass}
        />
      )
    }
    default:
      return null
  }
}
