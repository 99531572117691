import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { AirportChangeTextProps } from '@fiji/hooks/use-airport-change-text/types'
import type {
  FlightLegSubSegment,
  SearchFlightLegSubSegment,
  TripDetailsFlightLegSegment,
} from '@fiji/graphql/types'
import type { TextColor } from '@etta/ui/text/types'
import { useAirportChangeBadge } from './use-airport-change-badge'
import { Container, StyledBlock, StyledText, TextBlock } from './airport-change-badge-styled'

type Props = {
  flightLegSegments?: (
    | FlightLegSubSegment
    | TripDetailsFlightLegSegment
    | SearchFlightLegSubSegment
  )[]
  size?: AirportChangeTextProps['size']
  isTransparent?: boolean
  isMaxWidth?: boolean
  additionalText?: string
  verticalPadding?: number
  horizontalPadding?: number
  withLineBreak?: boolean
  withBadgeText?: boolean
  textColor?: TextColor
}

export function AirportChangeBadge({
  flightLegSegments,
  size = 'large',
  isTransparent = false,
  isMaxWidth,
  additionalText,
  verticalPadding = 8,
  horizontalPadding = 10,
  withLineBreak = false,
  withBadgeText = true,
  textColor = 'mainText1',
}: Props) {
  const { badgeText } = useAirportChangeBadge({
    flightLegSegments,
    size,
  })

  if (!badgeText) {
    return null
  }

  return (
    <Container
      isTransparent={isTransparent}
      isMaxWidth={isMaxWidth}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}>
      <StyledBlock marginRight={7}>
        <Icon name="warningFilledPWA" size="small" color="warning" />
      </StyledBlock>
      <TextBlock withLineBreak={withLineBreak}>
        {withBadgeText && (
          <Text variant="footnoteMedium" color={textColor} key={badgeText}>
            {badgeText}
          </Text>
        )}
        {additionalText && (
          <StyledText variant="footnoteMedium" color={textColor} key={badgeText}>
            {additionalText}
          </StyledText>
        )}
      </TextBlock>
    </Container>
  )
}
