import type { SwipingPickerSubtype, YearBounds } from '@etta/ui/swiping-time-date-picker/types'

const DEFAULT_MIN_YEAR = 1900

export function getYearBounds(
  pivotDate: Date,
  yearBounds?: YearBounds,
  variant?: SwipingPickerSubtype,
) {
  const fallbackYearBound = {
    minYear: DEFAULT_MIN_YEAR,
    maxYear: pivotDate.getFullYear(),
  }

  if (variant && !['date', 'future-date', 'only-year'].includes(variant)) {
    return fallbackYearBound
  }

  if (variant === 'future-date') {
    const maxYear = yearBounds?.maxYear ?? pivotDate.getFullYear() + 30
    return { minYear: pivotDate.getFullYear(), maxYear }
  }

  if (yearBounds) {
    return yearBounds
  }

  return fallbackYearBound
}
