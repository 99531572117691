import { useTranslation } from 'react-i18next'
import { Checkbox } from '@etta/ui/checkbox'
import type { Props } from './types'

const i18nBaseAria = 'Accessibility.Settings'

export function SpecialRequestItem({ options, values = [], onChange }: Props) {
  const { t } = useTranslation()

  const valuesSet = new Set(values)

  const handleSelectRequest = (value: string) => {
    const newSet = new Set(values)

    if (newSet.has(value)) {
      newSet.delete(value)
    } else {
      if (newSet.size === 3) {
        return
      }
      newSet.add(value)
    }

    onChange(Array.from(newSet))
  }

  return (
    <>
      {options.map((request) => {
        const checked = valuesSet.has(request.value)
        return (
          <Checkbox
            key={request.value}
            value={request.value}
            label={request.label}
            checked={checked}
            onChange={() => handleSelectRequest(request.value)}
            aria-label={t(
              `${i18nBaseAria}.${checked ? 'SelectedCheckbox' : 'UnselectedCheckbox'}`,
              {
                label: request.label,
              },
            )}
          />
        )
      })}
    </>
  )
}
