import { Action, Inject } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { LoadHotelsAction } from '@etta/modules/hotel-search/interface/actions/load-hotels.action'
import { ROUTES } from '@fiji/routes'
import { RailLegType } from '@fiji/enums'
import { CarRentalSearchActions } from '@etta/modules/car-rental-search/interface/actions/car-rental-search.actions'
import { AirSearchActions } from '@etta/modules/air-search/interface/actions'
import { SegmentType } from '@etta/core/enums'

@Action()
export class ReloadSegmentSearchActions {
  constructor(
    @Inject() private readonly historyStore: HistoryStore,
    @Inject() private readonly loadHotelsAction: LoadHotelsAction,
    @Inject() private readonly airSearchActions: AirSearchActions,
    @Inject() private readonly carRentalSearchActions: CarRentalSearchActions,
  ) {}

  public async reloadCurrentSegmentSearch() {
    const currentSegment = this.currentSegmentSearch

    switch (currentSegment) {
      case SegmentType.Flight: {
        await this.airSearchActions.handleReloadSearchResults()
        break
      }
      case SegmentType.Hotel: {
        await this.loadHotelsAction.loadInitialHotels()
        break
      }
      case SegmentType.CarRental: {
        await this.carRentalSearchActions.handleRefetchCarRentals(true)
        break
      }
      case SegmentType.Train:
      default: {
        break
      }
    }
  }

  public get currentSegmentSearch() {
    if (this.isAirSearch()) {
      return SegmentType.Flight
    }

    if (this.isHotelSearch()) {
      return SegmentType.Hotel
    }

    if (this.isCarRentalSearch()) {
      return SegmentType.CarRental
    }

    if (this.isRailSearch()) {
      return SegmentType.Train
    }

    return undefined
  }

  private isAirSearch() {
    const pathWithoutLegNumber = ROUTES.air.results({ legNumber: 0 }).slice(0, 25)
    return this.currentPath.includes(pathWithoutLegNumber)
  }

  private isHotelSearch() {
    return this.currentPath.includes(ROUTES.hotel.results)
  }

  private isCarRentalSearch() {
    return this.currentPath.includes(ROUTES.carRental.results)
  }

  private isRailSearch() {
    const pathWithoutLegType = ROUTES.rail
      .results({ railLegType: RailLegType.Departure })
      .slice(0, 22)
    return this.currentPath.includes(pathWithoutLegType)
  }

  private get currentPath() {
    return this.historyStore.currentPath
  }
}
