import { mergeDeepRight } from 'ramda'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getInitialState } from './initial-state'

const slice = createSlice({
  name: 'hotel-map',
  initialState: getInitialState(),
  reducers: {
    updateHotelMapHighlightedHotel(
      state,
      action: PayloadAction<{
        id: string | null
        isTooltipVisible: boolean
      }>,
    ) {
      state.highlightedHotel = mergeDeepRight(state.highlightedHotel, action.payload)
    },

    updateHotelMapMarkerLocation(
      state,
      action: PayloadAction<{
        latitude: number
        longitude: number
        query: string
      }>,
    ) {
      state.centerMarkerLocation = mergeDeepRight(state.centerMarkerLocation, action.payload)
    },

    updateHotelMapData(
      state,
      action: PayloadAction<{
        latitude: number
        longitude: number
        radius: number
      }>,
    ) {
      state.changedMapData = mergeDeepRight(state.changedMapData, action.payload)
    },

    updateHotelMapDataDefaultRadius(state, action: PayloadAction<number>) {
      state.defaultRadius = action.payload
    },
  },
})

export const hotelMapReducer = slice.reducer
export const {
  updateHotelMapHighlightedHotel,
  updateHotelMapMarkerLocation,
  updateHotelMapData,
  updateHotelMapDataDefaultRadius,
} = slice.actions
