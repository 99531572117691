import type { BillingParameter } from '@fiji/graphql/types'
import { TravelVertical } from '@fiji/graphql/types'

type Args = {
  billing?: BillingParameter
  stashedCardId?: string | null
}

function findDefaultCard(billing?: BillingParameter) {
  if (!billing || billing.travelVertical === TravelVertical.Rail) {
    return null
  }

  const { defaultCardId, creditCards } = billing

  if (creditCards.length === 1) {
    return creditCards[0]?.id
  }

  const { siteCardCount, restrictedCardCount } = creditCards.reduce(
    (counts, creditCard) => {
      if (creditCard.isSiteCard) {
        counts.siteCardCount++
      }
      if (creditCard.metadata.isRestricted) {
        counts.restrictedCardCount++
      }
      return counts
    },
    { siteCardCount: 0, restrictedCardCount: 0 },
  )

  if (restrictedCardCount === 1) {
    return creditCards.find((card) => card.metadata.isRestricted)?.id
  }

  if (defaultCardId && siteCardCount === 0) {
    return creditCards.find((card) => card.id === defaultCardId)?.id
  }

  return null
}

export function getDefaultCardId({ billing, stashedCardId }: Args) {
  if (stashedCardId) {
    return stashedCardId
  }

  const card = findDefaultCard(billing)

  if (card) {
    return card
  }
  return null
}
