import { Block } from '@etta/ui/block'
import { Skeleton } from '@etta/ui/skeleton'
import { Body, Footer, Container } from './content-skeleton-desktop-styled'

const ListItem = () => (
  <Container>
    <Body>
      <Block>
        <Skeleton width="180px" height="16px">
          <rect width="100%" height="16" />
        </Skeleton>
      </Block>
      <Block>
        <Skeleton width="40px" height="16px">
          <rect width="100%" height="14" />
        </Skeleton>
      </Block>
    </Body>
    <Footer>
      <Skeleton width="241px" height="68px">
        <rect y="16px" width="100%" height="14" />
        <rect y="42px" width="80%" height="14" />
      </Skeleton>
    </Footer>
  </Container>
)

export function ContentSkeletonDesktop() {
  return (
    <>
      <Skeleton width={328} height={72} variant="secondary1">
        <rect x="16" y="16" rx="42" ry="42" width="42" height="42" />
        <rect x="72" y="20" width="100" height="17" />
        <rect x="72" y="42" width="150" height="11" />
      </Skeleton>
      <Block marginTop={16} />
      <ListItem />
      <Block marginTop={16} />
      <ListItem />
      <Block marginTop={16} />
      <ListItem />
      <Block marginTop={16} marginBottom={40} />
    </>
  )
}
