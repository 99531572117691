import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { IconButton } from '@etta/ui/icon-button'
import { Spinner } from '@etta/ui/spinner'
import { Swap } from '@etta/ui/swap'
import { useAlertContext } from '@etta/modules/alert/interface'
import { AlertCardDesktop } from '../../../alert-card/desktop'
import { AlertError } from '../../../alert-error'
import { Header } from './alert-modal-desktop-styled'

const i18nBase = 'Alerts'

export const AlertModalDesktop = observer(function AlertModalDesktop() {
  const { t } = useTranslation()
  const { alertStore, alertActions } = useAlertContext()
  const { alerts, alertModalToggle, isError, isLoading } = alertStore

  return (
    <>
      <Modal.Header isMobile withBorder>
        <Header
          rightSlot={
            <IconButton
              aria-label={t(`${i18nBase}.Ally.CloseModalButton`)}
              icon="closeFilledPWA"
              color="bodyText"
              size="medium"
              onClick={alertModalToggle.handleClose}
            />
          }>
          <Header.Title
            aria-label={t(`${i18nBase}.Title`)}
            title={t(`${i18nBase}.Title`)}
            align="left"
          />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <Swap is={isError} isSlot={<AlertError />}>
          <Swap is={isLoading} isSlot={<Spinner type="spinout" />}>
            {alerts.map((alert, id) => {
              const { title, message } = alert
              return (
                <AlertCardDesktop
                  key={`${title}-${id}`}
                  title={title}
                  message={message}
                  onRedirect={() => alertActions.handleAlertClick(alert)}
                />
              )
            })}
          </Swap>
        </Swap>
      </Modal.Body>
    </>
  )
})
