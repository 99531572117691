import { Inject, Service } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { PostBookingCancellationStore } from '../stores/trip-cancellation/post-booking-cancellation.store'
import type {
  CarRentalPostBookingValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
} from '../../core/value-objects'

@Service()
export class PostBookingCancellationPreparePropsService {
  constructor(@Inject() private postBookingCancellationStore: PostBookingCancellationStore) {}

  handleHotelRulesProps(hotelSegment: HotelPostBookingValueObject) {
    this.postBookingCancellationStore.setHotelRulesProps({
      roomId: hotelSegment.id || '',
      name: hotelSegment.name || '',
      hotelId: hotelSegment.hotelId || '',
      checkIn: hotelSegment.checkIn || '',
      checkOut: hotelSegment.checkOut || '',
      dailyRates: hotelSegment.dailyRates,
      totalCost: hotelSegment.totalCost,
      id: hotelSegment.id,
      type: hotelSegment.type,
      averageRate: hotelSegment.averageRate,
      feesAndTaxes: hotelSegment.feesAndTaxes,
      roomType: hotelSegment.roomType,
    })
  }

  handleFlightFareRulesProps(flightSegment: FlightPostBookingValueObject) {
    const segmentPath = flightSegment.segments

    this.postBookingCancellationStore.setFlightFareRulesProps({
      flightSegmentId: (segmentPath && segmentPath[0]?.segmentId) || '',
      airlineName: (segmentPath && segmentPath[0].name) || '',
      airlineLogo: (segmentPath && segmentPath[0].images.rectangle) || '',
      bookingCodes: (segmentPath && segmentPath.map((segment) => segment.bookingCode || '')) || [],
      fares: flightSegment?.segments?.map((segment) => segment.fare || ''),
      toggle: new Toggle(),
    })
  }

  handleCarRentalRulesProps(carRentalSegment: CarRentalPostBookingValueObject) {
    this.postBookingCancellationStore.setCarRentalRulesProps({
      carId: carRentalSegment.carId || '',
      toggle: new Toggle(),
    })
  }
}
