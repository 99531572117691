import { useTranslation } from 'react-i18next'
import type { TicketSegment, TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { SegmentWrapper, SegmentLogo } from './e-ticket-receipt-modal.styled'

type Props = {
  segment: TripDetailsFlightLegSegment
  ticketSegment?: TicketSegment
}

export function FlightSegment({ segment, ticketSegment }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const { name, images, departure, arrival } = segment

  const { carrierCode, flightNumber, departTime, serviceClass, fareBasis, status } =
    ticketSegment ?? {}

  const flightLine = [name, carrierCode, flightNumber].filter(Boolean).join(' ')

  const departureAddress = [departure?.airportCity, departure?.address?.stateCode]
    .filter(Boolean)
    .join(', ')
  const arrivalAddress = [arrival?.airportCity, arrival?.address?.stateCode]
    .filter(Boolean)
    .join(', ')

  return (
    <SegmentWrapper>
      {images.rectangle && <SegmentLogo src={images.rectangle} alt={name + 'logo'} />}
      <Text variant="subHeadStrong" color="mainText">
        {departureAddress} ({departure?.airportCode})
        <Icon name="arrowForwardPWA" size="small" />
        {arrivalAddress} ({arrival?.airportCode})
      </Text>
      <Text variant="footnoteStrong" color="mainText">
        {flightLine}
      </Text>
      {departTime && (
        <Text variant="footnoteMedium" color="mainText">
          {t(i18nBase + 'DepartOn')}&nbsp;
          <Text variant="footnoteMedium" color="mainText">
            {dateFormat(new Date(deleteTimezone(departTime)), 'ccc LLL d, yyyy h:mm a')}
          </Text>
        </Text>
      )}
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'serviceClass')}: {serviceClass ? capitalizeFirstLetter(serviceClass) : '--'}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'FareBasis')}: {fareBasis}
      </Text>
      <Text variant="footnoteMedium" color="mainText">
        {t(i18nBase + 'Status')}: {status ? capitalizeFirstLetter(status) : '--'}
      </Text>
    </SegmentWrapper>
  )
}
