import type { PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import {
  ButtonsContainer,
  ChildrenWrapper,
  ContainerWrapper,
  EditHeader,
  SearchButton,
  CloseButtonWrapper,
} from './header-full-edit-mode-styled'

const i18nBase = 'AirSearchResults.Header.'

type Props = PropsWithChildren<{
  controlSlot?: ReactNode
  onClose: () => void
  onSearch?: () => void
}>

export function HeaderFullEditMode({ controlSlot, children, onClose, onSearch }: Props) {
  const { t } = useTranslation()

  return (
    <ContainerWrapper>
      <CloseButtonWrapper aria-label={t('Accessibility.Header.CloseLabel')}>
        <IconButton icon="closePWA" size="medium" color="mainText" onClick={onClose} />
      </CloseButtonWrapper>
      <EditHeader aria-label={t(i18nBase + 'EditSearch')}>
        <Text variant="title3">{t(i18nBase + 'EditSearch')}</Text>
      </EditHeader>

      {controlSlot && <ButtonsContainer>{controlSlot}</ButtonsContainer>}
      <ButtonsContainer>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {onSearch && (
          <SearchButton
            onClick={onSearch}
            aria-label={t(i18nBase + 'Filters')}
            data-tracking-id="header-search-button">
            <Icon name="searchPWA" size="medium" color="mainText2" />
          </SearchButton>
        )}
      </ButtonsContainer>
    </ContainerWrapper>
  )
}
