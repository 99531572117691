import type { LayoutProps } from '../types'
import { Container, MapContainer, HotelListContainer } from './hotel-list-desktop-styled'

export function HotelListDesktop({ mapSlot, hotelListSlot }: LayoutProps) {
  return (
    <Container>
      <HotelListContainer>{hotelListSlot}</HotelListContainer>
      <MapContainer>{mapSlot}</MapContainer>
    </Container>
  )
}
