import * as R from 'ramda'
import { DeviceClass, useGetFlightFareRulesQuery } from '@fiji/graphql/hooks'
import type { FlightFareRule } from '@fiji/graphql/types'

type Props = {
  itineraryId: string
  flightSegmentId: string
}

export function useFareRules({ itineraryId, flightSegmentId }: Props) {
  const { data, loading: isLoading, error: isError, refetch } = useGetFlightFareRulesQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        itineraryId: itineraryId,
        flightSegmentId: flightSegmentId,
        deviceClass: DeviceClass.Mobile,
      },
    },
  })

  const flightFareRules = R.path<FlightFareRule[]>(['flightFareRules', 'rules'], data)

  const onReload = () => {
    refetch && refetch()
  }

  return { flightFareRules, isLoading, isError, onReload }
}
