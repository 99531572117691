import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  flex-wrap: wrap;
`

export const Content = styled.div`
  display: flex;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const MapWrapper = styled.div`
  width: 50%;
  > :first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    height: 184px;
  }
`

export const IconWrapper = styled(Icon)``

export const Information = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 22px;

  ${Text} {
    max-width: 250px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  ${IconWrapper} {
    margin-left: 10px;
  }

  ${getKeyboardNavigationStyle({ offset: -5, borderRadius: 10 })}
`

export const Details = styled.div`
  flex-grow: 2;
`

export const Title = styled.div`
  flex: 0 0 100%;
`
