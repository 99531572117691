import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { StyledTextVariant } from '@fiji/enums'
import { TextField } from '@etta/ui/text-field'
import { Text } from '@etta/ui/text'
import type { LayoutProps, ValueType } from '../../types'
import { Container, Header, Label, List, Option, Wrapper } from './select-desktop-styled'

export function SelectDesktop<T = ValueType>({
  isOptionsDialogVisible,
  onChangeHandler,
  onOptionsDialogOpen,
  onOptionsDialogClose,
  options,
  label,
  helperText,
  helperType,
  isDisabled,
  optionLabel,
  description,
}: LayoutProps<T>) {
  const { ref } = useClickOutside(() => {
    if (isOptionsDialogVisible) {
      onOptionsDialogClose()
    }
  })
  useShouldOpenInViewport({ isVisible: isOptionsDialogVisible, ref })

  return (
    <Wrapper>
      {isOptionsDialogVisible && (
        <Container ref={ref}>
          <Header>
            <Label>
              <Text variant={StyledTextVariant.captionMedium} color="bodyText">
                {label}
              </Text>
            </Label>

            <Text variant={StyledTextVariant.headline} color="mainText">
              {optionLabel}
            </Text>
          </Header>

          <List>
            {options.map((option) => (
              <Option
                key={`${option.label}-${option.value}`}
                onClick={() => onChangeHandler(option)}>
                <Text variant={StyledTextVariant.subHeadMedium} color="mainText">
                  {option.label}
                </Text>
              </Option>
            ))}
          </List>
        </Container>
      )}
      <TextField
        label={label}
        isDisabled={isDisabled}
        value={(optionLabel as string) || ''}
        onClick={onOptionsDialogOpen}
        helperText={helperText}
        helperType={helperType}
        description={description}
      />
    </Wrapper>
  )
}
