import type { FunctionComponent, ReactNode } from 'react'
import { Container, Footer, Header, Root } from './layout-styles'
import type { LayoutBackgroundColor } from './types'

type LayoutProps = {
  children: ReactNode
  className?: string
  backgroundColor?: LayoutBackgroundColor
}

type CompoundComponents = {
  Root: typeof Root
  Header: typeof Header
  Footer: typeof Footer
  Container: typeof Container
}

export const Layout: FunctionComponent<LayoutProps> & CompoundComponents = function ({
  children,
  className,
  backgroundColor,
}) {
  return (
    <Root className={className} backgroundColor={backgroundColor}>
      {children}
    </Root>
  )
}

Layout.Root = Root
Layout.Header = Header
Layout.Footer = Footer
Layout.Container = Container
