import { Trans, useTranslation } from 'react-i18next'
import { RailCollectTicketDisabled } from '@etta/components/rail-collect-ticket-disabled'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Separator } from '@etta/ui/separator'
import { Icon } from '@etta/ui/icon'

const i18nBase = 'RailDeliveryOption.'

type Props = {
  stationName?: string
  isStationTicketMachineDisabled: boolean
  railCardName?: string
  onModalOpen: () => void
}

export function CollectViewPurchaseSuccess({
  stationName,
  railCardName,
  onModalOpen,
  isStationTicketMachineDisabled,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Block paddingHorizontal={24} paddingVertical={12}>
        <Block marginBottom={18} isFlexBlock>
          <Block marginRight={14}>
            <Icon name="kioskPWA" size="medium" />
          </Block>
          <Text variant="subHeadStrong" color="mainText">
            {t(i18nBase + 'CollectTickets')}
          </Text>
        </Block>
        <Separator />
        <Block marginTop={12} marginBottom={16}>
          <Text variant="footnoteMedium" color="bodyText">
            <Trans i18nKey={`${i18nBase}CollectFromAny`} />
          </Text>
          <Block marginTop={4}>
            <Link size="small" onClick={onModalOpen}>
              {t(i18nBase + 'LearnHow')}
            </Link>
          </Block>
        </Block>
        <Separator lineType="dashed" />
        <Block marginTop={12}>
          <Text variant="footnoteMedium" color="bodyText">
            {railCardName
              ? t(i18nBase + 'RememberToTakeCard', { cardName: railCardName })
              : t(i18nBase + 'RememberToTake')}
          </Text>
        </Block>
      </Block>
      {isStationTicketMachineDisabled && <RailCollectTicketDisabled stationName={stationName} />}
    </>
  )
}
