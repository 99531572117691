import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import type { BaggageAllowance, BaggageAllowanceCount, Rate } from '@fiji/graphql/types'
import { BaggageType } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import {
  resolveBaggageCount,
  resolveBaggageDimensions,
} from '@fiji/utils/baggage-resolvers-and-filters'
import {
  BaggageFeeItem,
  BaggageFeeTitle,
  BaggageFeeContent,
  BaggageFeeHeading,
} from './baggage-fee-styled'

type BaggageFeeProps = {
  type: BaggageType
  rate: Rate
  baggageCount?: BaggageAllowanceCount | null
  baggageWeight?: BaggageAllowance | null
  baggageSize?: BaggageAllowance | null
  iterator: number
  title: string
}

export function BaggageFee({
  type,
  rate,
  baggageCount,
  baggageWeight,
  baggageSize,
  iterator,
  title,
}: BaggageFeeProps) {
  const { t } = useTranslation()
  const i18nBasePath = 'BaggageInformation.'
  const { mainCost } = formatRate(rate, { morpheme: 'none' })

  const baggageData = () => {
    switch (type) {
      case BaggageType.NumberOfBags:
        return (
          <BaggageFeeContent
            aria-label={t(i18nBasePath + 'ItemAriaLabel', {
              title: resolveBaggageCount(i18nBasePath, baggageCount!!),
              amount: mainCost,
            })}>
            <div>{resolveBaggageCount(i18nBasePath, baggageCount!!)}</div>
            <div>{mainCost}</div>
          </BaggageFeeContent>
        )
      case BaggageType.Size:
      case BaggageType.Weight:
        return (
          <BaggageFeeContent
            aria-label={t(i18nBasePath + 'ItemAriaLabel', {
              title: resolveBaggageDimensions((baggageWeight || baggageSize)!!),
              amount: mainCost,
            })}>
            <div>{resolveBaggageDimensions((baggageWeight || baggageSize)!!)}</div>
            <div>{mainCost}</div>
          </BaggageFeeContent>
        )
      default:
        return null
    }
  }

  return (
    <BaggageFeeItem>
      {iterator === 0 && (
        <BaggageFeeHeading>
          <BaggageFeeTitle aria-label={i18n.t(i18nBasePath + title)}>
            {i18n.t(i18nBasePath + title)}
          </BaggageFeeTitle>
        </BaggageFeeHeading>
      )}
      {baggageData()}
    </BaggageFeeItem>
  )
}
