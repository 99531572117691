export default function FlightIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 11.15V8H32.675L25.325 15.35L12.2 11.675C10.975 11.325 9.575 11.675 8.7 12.55L8 13.25L20.425 20.25L15.35 26.725L13.075 25.5C12.2 24.975 11.15 25.15 10.45 25.85L9.4 26.9L13.95 29.7L16.75 34.25L17.8 33.2C18.5 32.5 18.675 31.45 18.325 30.575L17.275 28.3L23.75 23.575L30.575 36.175L31.45 35.3C32.5 34.25 32.85 32.85 32.325 31.625L28.65 18.85L36 11.15Z"
        fill="currentColor"
      />
    </svg>
  )
}
