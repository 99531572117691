import type { FlightAmenity, TransportAmenities } from '@fiji/graphql/types'

export function getAmenities(amenities?: TransportAmenities | null): FlightAmenity[] {
  const amenitiesCodes: string[] = amenities?.codes.map((code) => code || '') || []
  const amenitiesList: FlightAmenity[] = amenitiesCodes?.map((code, index) => {
    return {
      id: code,
      name: amenities?.values[index] || '',
    }
  })
  return amenitiesList || []
}
