import { useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMobilitySuggestedRides } from '@etta/screens/mobility-search-page/hooks'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { useAppDispatch } from '@fiji/store'
import { updateForceFetchSuggestions } from '@fiji/store/mobility'
import type { RideHailSuggestionValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-suggestion.value-object'
import { SegmentType } from '@etta/core/enums'
import { RideHailSuggestionLocationEnum } from '@etta/modules/ride-hail/core/enum/suggestion-location-type.enum'

const i18nBasePath = 'Mobility.WhereToPage.'

export function useMobilitySuggestedDropOff() {
  const { t } = useTranslation()
  const {
    rides,
    isLoading,
    error,
    firstSuggestedRide,
    handleRefetchRequest,
  } = useMobilitySuggestedRides()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateForceFetchSuggestions({ forceFetchSuggestions: true }))
  }, [dispatch])

  const isFlightInTrip = useMemo(() => {
    const withMatchedFlight = (ride: RideHailSuggestionValueObject): boolean => {
      return (
        ride.pickUpSegmentType === SegmentType.Flight ||
        ride.dropOffSegmentType === SegmentType.Flight
      )
    }
    return rides.filter(withMatchedFlight).length > 0
  }, [rides])

  const dropOffs = useMemo((): RideHailSuggestionValueObject[] => {
    const withMatchDropOff = (ride: RideHailSuggestionValueObject): boolean => {
      return ride.dropOffName !== ''
    }
    return rides.filter(withMatchDropOff)
  }, [rides])

  const getDateTimeFormat = (date: Date) => {
    const dateFormat = date ? getFormattedTime(date, 'MMM d') : ''
    const timeFormat = date ? getFormattedTime(date, 'h:mm a') : ''
    return {
      dateFormat,
      timeFormat,
    }
  }

  const getDescription = useCallback(
    (ride: RideHailSuggestionValueObject) => {
      if (ride.dropOffLocationType === RideHailSuggestionLocationEnum.Airport) {
        const { dateFormat, timeFormat } = getDateTimeFormat(
          new Date(ride.dropOffSegmentStartDate + 'T' + ride.dropOffSegmentStartTime),
        )
        return t(i18nBasePath + 'DescriptionFlightDepart', { date: dateFormat, time: timeFormat })
      }

      if (ride.pickUpLocationType === RideHailSuggestionLocationEnum.Airport) {
        const { dateFormat, timeFormat } = getDateTimeFormat(
          new Date(ride.pickUpSegmentEndDate + 'T' + ride.pickUpSegmentEndTime),
        )
        return t(i18nBasePath + 'DescriptionFlightArrive', { date: dateFormat, time: timeFormat })
      }

      if (ride.dropOffLocationType === RideHailSuggestionLocationEnum.Hotel) {
        const date = new Date(ride.dropOffSegmentStartDate + 'T00:00:00.000')
        const { dateFormat } = getDateTimeFormat(date)
        return t(i18nBasePath + 'DescriptionHotelCheckIn', { date: dateFormat })
      }

      if (
        ride.dropOffLocationType === RideHailSuggestionLocationEnum.Home &&
        ride.pickUpLocationType === RideHailSuggestionLocationEnum.Hotel
      ) {
        const date = new Date(ride.pickUpSegmentEndDate + 'T00:00:00.000')
        const { dateFormat } = getDateTimeFormat(date)
        return t(i18nBasePath + 'DescriptionHotelCheckOut', { date: dateFormat })
      }

      return ''
    },
    [t],
  )

  return {
    dropOffs,
    firstSuggestedRide,
    isLoading,
    error,
    handleRefetchRequest,
    isFlightInTrip,
    getDescription,
  }
}
