import { DescendantItem } from '@etta/components/descendant'
import { StyledTextVariant } from '@fiji/enums'
import { Row } from '../../row'
import type { Props } from '../types'
import { getIconName } from '../get-icon-name'
import { ListItem, Title } from './list-desktop-styled'

export function ListDesktop({
  list,
  getItemProps,
  highlightedIndex,
  title,
  startIndex = 0,
  iconSize = 'large',
  dataTrackingId,
}: Props) {
  if (!list.length) {
    return null
  }

  return (
    <div data-tracking-id={dataTrackingId}>
      {title && <Title aria-label={title}>{title}</Title>}
      {list.map((item, i) => {
        const index = startIndex + i
        const { onClick, onMouseMove, ...restProps } = getItemProps({ item, index })
        return (
          <DescendantItem aria-label={item.name} onSelect={onClick} key={item.placeId}>
            <ListItem
              {...restProps}
              onClick={onClick}
              aria-label={item.name}
              role="option"
              textVariant={StyledTextVariant.regularText}
              active={highlightedIndex === index}
              data-tracking-id={dataTrackingId + '-option'}>
              <Row aria-label={item.name} icon={getIconName(item)} iconSize={iconSize}>
                {item.name}
              </Row>
            </ListItem>
          </DescendantItem>
        )
      })}
    </div>
  )
}
