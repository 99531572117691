import { isoToDate } from './iso-to-date'
import { deleteTimezone } from './delete-timezone'
import { getDifferenceInCalendarDays } from './get-difference-in-calendar-days'

export function getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
  startDate: string,
  endDate: string,
): number {
  if (!startDate || !endDate) {
    return 0
  }

  const start = isoToDate(deleteTimezone(startDate))
  const end = isoToDate(deleteTimezone(endDate))

  return Math.abs(getDifferenceInCalendarDays(end, start))
}
