import type {
  DateRange,
  FlightPolicies,
  MeetingCustomField,
  UpsertMeetingInfoResponse,
} from '@fiji/graphql/types'
import { MeetingProvider } from '@fiji/graphql/hooks'
import type {
  DateRangeValueObject,
  FlightPoliciesValueObject,
  MeetingInfoValueObject,
} from '../../core/value-objects/meeting-mode.value-object'
import { MeetingProviderEnum } from '../../core/enum/meeting-mode.enum'

export class MeetingInfoMapper {
  static toMeetingInfoValueObject({
    meeting,
    participant,
    isMeetingModeDelegate,
  }: UpsertMeetingInfoResponse): MeetingInfoValueObject {
    const { policies } = meeting
    return {
      meeting: {
        id: meeting.id,
        externalId: meeting.externalId ?? undefined,
        name: meeting.name,
        location: meeting.location,
        policies: {
          arrival: policies.arrival ? this.toDateValue(policies.arrival) : undefined,
          departure: policies.departure ? this.toDateValue(policies.departure) : undefined,
          isFlightEnabled: policies.isFlightEnabled,
          isHotelEnabled: policies.isHotelEnabled,
          isCarEnabled: policies.isCarEnabled,
          flightPolicies: policies.flightPolicies
            ? this.toFlightPoliciesValueObject(policies.flightPolicies)
            : undefined,
        },
        dates: this.toDateValue(meeting.dates),
        provider: this.toMeetingProviderEnum(meeting.provider),
        confirmationNumber: meeting.confirmationNumber,
        message: meeting.message ?? undefined,
        customFields: meeting.customFields.map(this.toCustomFieldValueObject),
      },
      participant: {
        email: participant.email,
        firstName: participant.firstName,
        lastName: participant.lastName,
        customFields: participant.customFields.map(this.toCustomFieldValueObject),
      },
      isMeetingModeDelegate: isMeetingModeDelegate,
    }
  }

  private static toDateValue(dateRange: DateRange): DateRangeValueObject {
    return {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }
  }

  private static toFlightPoliciesValueObject(dateRange: FlightPolicies): FlightPoliciesValueObject {
    return {
      destinationAirport: dateRange.destinationAirport ?? undefined,
      alternateDestinationAirportAllowed: dateRange.alternateDestinationAirportAllowed ?? undefined,
    }
  }

  private static toMeetingProviderEnum(provider: MeetingProvider): MeetingProviderEnum {
    switch (provider) {
      case MeetingProvider.CventProvider:
        return MeetingProviderEnum.CventProvider
      case MeetingProvider.UnknownProvider:
        return MeetingProviderEnum.UnknownProvider
    }
  }

  private static toCustomFieldValueObject(meetingCustomeField: MeetingCustomField) {
    return {
      name: meetingCustomeField.name,
      value: meetingCustomeField.value,
    }
  }
}
