import type { ValidationError } from 'yup'
import type BaseSchema from 'yup/lib/schema'
import type { ErrorsMap, FieldsMap, MapUnion } from './types'

type Args<T> = {
  yupScheme: BaseSchema
  values: T
}

export async function handleSubmit<T extends MapUnion = MapUnion>({ yupScheme, values }: Args<T>) {
  try {
    await yupScheme.validate(values, { abortEarly: false })
    return {
      values,
      isValid: true,
    }
  } catch (e: any) {
    const errorsMap =
      e?.inner?.reduce((acc: FieldsMap, errorItem: ValidationError) => {
        acc[errorItem.path || ''] = errorItem.message
        return acc
      }, {} as ErrorsMap) || {}
    return {
      values,
      errors: errorsMap,
      isValid: false,
    }
  }
}
