import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import type { MapProvider as MapProviderType } from '@fiji/graphql/types'
import { PRIVACY_POLICY_DOCUMENT_URL } from '@fiji/constants'
import type { DisplayConfiguration } from './types'
import { getInitialState } from './initial-state'

const slice = createSlice({
  name: 'display-configuration',
  initialState: getInitialState(),
  reducers: {
    updateDisplayConfiguration(state, action: PayloadAction<DisplayConfiguration>) {
      // +
      const displayConfiguration = mergeDeepRight(state, action.payload) as DisplayConfiguration

      displayConfiguration.privacyPolicy.url =
        displayConfiguration.privacyPolicy.url || PRIVACY_POLICY_DOCUMENT_URL

      return displayConfiguration
    },

    updateSiteSpinner(state, action: PayloadAction<{ isSiteSpinnerVisible: boolean }>) {
      state.isSiteSpinnerVisible = action.payload.isSiteSpinnerVisible
    },

    updateSiteMessages(state, action: PayloadAction<{ isSiteMessagesVisible: boolean }>) {
      state.isSiteMessagesVisible = action.payload.isSiteMessagesVisible
    },

    updateMapVisible(state, action: PayloadAction<{ isMapVisible: boolean }>) {
      state.isMapVisible = action.payload.isMapVisible
    },

    updateMobileMapVisible(state, action: PayloadAction<{ isMobileMapVisible: boolean }>) {
      state.isMobileMapVisible = action.payload.isMobileMapVisible
    },

    updateIsPhoenixHotelSearchEnabled(
      // +
      state,
      action: PayloadAction<{ isPhoenixHotelSearchEnabled?: boolean }>,
    ) {
      return mergeDeepRight(state, action.payload)
    },

    updateMapProvider(state, action: PayloadAction<{ mapProvider: MapProviderType }>) {
      // +
      state.mapProvider = action.payload.mapProvider
    },

    updateDisplayConfigLoading(state, action: PayloadAction<{ displayConfigLoading: boolean }>) {
      // +
      state.displayConfigLoading = action.payload.displayConfigLoading
    },

    setIsFooterVisible(state, action: PayloadAction<{ isFooterVisible: boolean }>) {
      state.isFooterVisible = action.payload.isFooterVisible
    },

    setSiteName(state, action: PayloadAction<{ siteName: string }>) {
      // +
      state.siteName = action.payload.siteName
    },

    setIsMeetingsMode(state, action: PayloadAction<{ isMeetingsMode: boolean }>) {
      // +
      state.isMeetingsMode = action.payload.isMeetingsMode
    },

    setPreventRedirectURL(state, action: PayloadAction<{ preventRedirectURL?: string }>) {
      return mergeDeepRight(state, action.payload)
    },

    resetPreventRedirectURL(state) {
      state.preventRedirectURL = ''
    },
  },
})

export const displayConfigurationReducer = slice.reducer
export const {
  updateDisplayConfiguration,
  resetPreventRedirectURL,
  setIsFooterVisible,
  setPreventRedirectURL,
  setSiteName,
  setIsMeetingsMode,
  updateDisplayConfigLoading,
  updateIsPhoenixHotelSearchEnabled,
  updateMapProvider,
  updateMapVisible,
  updateMobileMapVisible,
  updateSiteMessages,
  updateSiteSpinner,
} = slice.actions
