import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'
import { SearchFieldContent } from './search-mechanism-mobile-styled'

export function SearchMechanismMobile({
  scrollContainerRef,
  anchorSlot,
  backButtonSlot,
  searchInputSlot,
  listSlot,
}: LayoutProps) {
  return (
    <Modal.Body ref={scrollContainerRef}>
      {anchorSlot}

      <Modal.Header isMobile>
        <SearchFieldContent>
          {backButtonSlot}

          {searchInputSlot}
        </SearchFieldContent>
      </Modal.Header>

      {listSlot}
    </Modal.Body>
  )
}
