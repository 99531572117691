import { SegmentState } from '@fiji/graphql/types'
import type { CardStatusType } from './types'

type Args = {
  segmentState?: SegmentState | null
}

const cardStatuses: { [key: string]: CardStatusType } = {
  [SegmentState.Changed]: 'changed',
  [SegmentState.New]: 'added',
}

export function useTripContent({ segmentState }: Args) {
  const cardStatus: CardStatusType = segmentState ? cardStatuses[segmentState] : 'default'

  return {
    cardStatus,
  }
}
