import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Checkbox } from '@etta/ui/checkbox'
import { Text } from '@etta/ui/text'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useThemeConfigurationContext } from '@etta/modules/theme'
import { ScreenType, useScreenType } from '@fiji/modes'
import { ThemeContainer } from './dark-theme-toggle-styled'

const i18nBase = 'Profile'
export const DarkThemeToggle = observer(function DarkThemeToggle() {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { featureFlags } = useFeatureFlags()
  const { t } = useTranslation()
  const { themeConfigurationStore, themeConfigurationAction } = useThemeConfigurationContext()

  if (!isMobile) {
    return null
  }

  if (!featureFlags.isToggleEttaThemeEnabled) {
    return null
  }

  return (
    <ThemeContainer>
      <Text variant="body" color="mainText">
        {t(`${i18nBase}.UseTaskTheme`)}
      </Text>
      <Checkbox
        label=""
        value="use-dark-theme"
        checked={themeConfigurationStore.isDarkTheme}
        onChange={themeConfigurationAction.toggleDarkTheme}
      />
    </ThemeContainer>
  )
})
