import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ValueType, Arguments, OptionType } from './types'

export function useSelect<T = ValueType>({
  onChange,
  options,
  value,
  isDisabled,
  withNoneOption,
}: Arguments<T>) {
  const { t } = useTranslation()

  const optionLabel = useMemo(() => {
    if (typeof value === 'string' && !value) {
      return ''
    }
    return options.find((item) => item.value === value)?.label || value
  }, [options, value])

  const [isOptionsDialogVisible, setOptionsDialogVisible] = useState(false)

  const onOptionsDialogClose = useCallback(() => {
    setOptionsDialogVisible(false)
  }, [])

  const onOptionsDialogOpen = useCallback(() => {
    if (options.length && !isDisabled) {
      setOptionsDialogVisible(true)
    }
  }, [options, isDisabled])

  const onChangeHandler = useCallback<(option: OptionType<T>) => void>(
    (option) => {
      onOptionsDialogClose()
      onChange(option.value)
    },
    [onChange, onOptionsDialogClose],
  )

  const displayOptions = useMemo(() => {
    if (withNoneOption && options.some((option) => typeof option.value === 'string')) {
      return [{ label: t(`DropdownList.None`), value: ('' as unknown) as T }, ...options]
    }
    return options
  }, [withNoneOption, options, t])

  return {
    onChangeHandler,
    optionLabel,
    isOptionsDialogVisible,
    onOptionsDialogOpen,
    onOptionsDialogClose,
    displayOptions,
  }
}
