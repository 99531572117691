import type { RefObject } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import { PrintItineraryContent } from './print-itinerary-content'
import { PrintItineraryModal } from './print-itinerary-modal'
import { DisplayNone } from './print-itinerary-modal.styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  trip: TripEntity
  travelerName: string
  handlePrint?: () => void
  printableContentRef?: RefObject<HTMLDivElement>
}

export function PrintItinerary(props: Props) {
  return (
    <MediaLayout
      mobileSlot={
        <DisplayNone>
          <PrintItineraryContent {...props} />
        </DisplayNone>
      }
      desktopSlot={<PrintItineraryModal {...props} />}
    />
  )
}
