import { useCallback, useEffect, useRef } from 'react'
import { FOCUS_SELECTOR, focusable, tabbable } from './tabbable'
import { scopeTab } from './scope-tab'
import { createAriaHider } from './create-aria-hider'

type Args = {
  isActive?: boolean
}

export function useFocusTrap({ isActive }: Args): (instance: HTMLElement | null) => void {
  const ref = useRef<HTMLElement | null>()
  const restoreAria = useRef<Function | null>(null)

  const focusNode = (node: HTMLElement) => {
    let focusElement: HTMLElement | null = node.querySelector('[data-autofocus]')

    if (!focusElement) {
      const children = Array.from<HTMLElement>(node.querySelectorAll(FOCUS_SELECTOR))
      focusElement = children.find(tabbable) || children.find(focusable) || null
      if (!focusElement && focusable(node)) {
        focusElement = node
      }
    }

    if (focusElement) {
      focusElement.focus({ preventScroll: true })
    }
  }

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (!isActive) {
        return
      }

      if (node === null) {
        if (restoreAria.current) {
          restoreAria.current()
          restoreAria.current = null
        }
        return
      }

      restoreAria.current = createAriaHider(node)
      if (ref.current === node) {
        return
      }

      if (node) {
        setTimeout(() => {
          if (node.getRootNode()) {
            focusNode(node)
          }
        })

        ref.current = node
      } else {
        ref.current = null
      }
    },
    [isActive],
  )

  useEffect(() => {
    if (!isActive) {
      return undefined
    }

    // @ts-ignore
    ref.current && setTimeout(() => focusNode(ref.current))

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && ref.current) {
        scopeTab(ref.current, event)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)

      if (restoreAria.current) {
        restoreAria.current()
      }
    }
  }, [isActive])

  return setRef
}
