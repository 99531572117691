import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { Room } from '@fiji/graphql/types'
import { Skeleton } from '@etta/ui/skeleton'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { PriceOutOfPolicy } from './price-out-of-policy'
import { usePrice } from './use-price'
import { PriceWrapper, SoldOutText } from './price-styled'
import type { PriceSkin } from './types'

type PriceProps = {
  firstRoom?: Room
  isHotelLoading: boolean
  isPreferred?: boolean
  isAllRoomsOutOfPolicy?: boolean
  skin?: PriceSkin
}

export const Price = observer(function Price({
  firstRoom,
  isHotelLoading,
  isPreferred,
  isAllRoomsOutOfPolicy,
  skin = 'row',
}: PriceProps) {
  const { t } = useTranslation()
  const {
    finalPrice,
    soldOutText,
    averageText,
    outOfPolicyTexts,
    showAllRoomsOutOfPolicy,
    fromText,
  } = usePrice({
    firstRoom,
    skin,
    isPreferred,
    isAllRoomsOutOfPolicy,
  })

  if (isHotelLoading) {
    return (
      <Skeleton width="76px" height="16px">
        <rect width="76px" height="16px" />
      </Skeleton>
    )
  }

  if (!firstRoom) {
    return (
      <SoldOutText aria-label={soldOutText} skin={skin} variant="buttonSmall">
        {soldOutText}
      </SoldOutText>
    )
  }

  return (
    <PriceWrapper skin={skin}>
      <Text variant="captionMedium" color="bodyText" aria-label={fromText}>
        {fromText}
      </Text>

      <Block isFlexBlock paddingLeft={2}>
        {showAllRoomsOutOfPolicy && <PriceOutOfPolicy outOfPolicyTexts={outOfPolicyTexts} />}

        <Text
          variant={skin === 'row' ? 'headline' : 'title3'}
          color="mainText"
          aria-label={t(`SearchResults.Hotel.PriceAriaLabel`, { price: finalPrice })}>
          {finalPrice}
        </Text>
      </Block>
      <Text variant="captionMedium" color="bodyText" aria-label={averageText}>
        {averageText}
      </Text>
    </PriceWrapper>
  )
})
