import type { RideHailUpcomingFlight } from '@fiji/graphql/types'
import type { RideHailAirportPickupFlightValueObject } from '../../core/value-objects/ride-hail-airport-pickup.value-object'

export function mapToRideHailAirportPickupFlightValueObject(
  flightDetails: RideHailUpcomingFlight,
): RideHailAirportPickupFlightValueObject {
  return {
    airlineName: flightDetails.airlineName ?? '-',
    flightDesignator: flightDetails.designatorCode ?? '-',
    arrivalAirportCode: flightDetails.airportCode ?? '-',
    arrivalDate: flightDetails.arrivalDate,
    arrivalTime: flightDetails.arrivalTime,
    imageUrl: flightDetails.airlineImage ?? '',
  }
}
