import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { TripType } from '@fiji/graphql/types'
import { ROUTES } from '@fiji/routes'
import { OrdersAdapter } from '@etta/modules/post-booking-list/infra/orders/orders.adapter'
import type { TripsList } from '@etta/modules/post-booking-list/core/value-object/trips-list.value-object'
import { trackAction } from '@fiji/utils/tracking/track-action'
import { RideHailSearchOnBookTabStore } from '../store/ride-hail-search-on-book-tab.store'
import { GetCustomerUberAccountStatusStore } from '../store/get-customer-uber-account-status.store'
import { UberAccountStatusEnum } from '../../core/enum/uber-account-status.enum'
import { SearchRideHailStore } from '../store/search-ride-hail.store'

@Action()
export class RideHailSearchOnBookTabActions {
  constructor(
    @Inject()
    private historyService: HistoryService,
    @Inject()
    private rideHailSearchOnBookTabStore: RideHailSearchOnBookTabStore,
    @Inject()
    private searchRideHailStore: SearchRideHailStore,
    @Inject()
    private readonly ordersAdapter: OrdersAdapter,
    @Inject()
    private getCustomerUberAccountStatusStore: GetCustomerUberAccountStatusStore,
  ) {}

  private ACTION_NAME = 'mobility-book-ride-hail_search-page'

  async onTabRideHail() {
    const uberAccountStatusNotAllow = [
      UberAccountStatusEnum.NotFound,
      UberAccountStatusEnum.NotLinked,
    ]
    const status = this.getCustomerUberAccountStatusStore.status

    if (uberAccountStatusNotAllow.includes(status)) {
      const trips = await this.getTrips()
      this.sendTrackAction(this.ACTION_NAME, status, trips.length > 0)
      this.navigateToLearnMore()
      return
    }

    await this.processUpcomingTrips()
  }

  private navigateToLearnMore() {
    this.historyService.push(ROUTES.mobility.learnMore)
  }

  private async processUpcomingTrips() {
    this.rideHailSearchOnBookTabStore.setIsLoading(true)
    this.rideHailSearchOnBookTabStore.setIsError(false)

    const status = this.getCustomerUberAccountStatusStore.status

    const trips = await this.getTrips()
    this.sendTrackAction(this.ACTION_NAME, status, trips.length > 0)
    switch (trips.length) {
      case 0:
        return this.navigateToLearnMore()
      case 1:
        return this.navigateToSearchFlow(trips[0])
      default:
        return this.openTripSelectionModal()
    }
  }

  private async getTrips(): Promise<TripsList[]> {
    let trips: TripsList[] = []

    const result = await this.ordersAdapter.getOrders({
      offset: 0,
      tripType: TripType.Upcoming,
      forceUpdate: true,
    })

    result.match({
      Err: () => {
        this.rideHailSearchOnBookTabStore.setIsLoading(false)
        this.rideHailSearchOnBookTabStore.setIsError(true)
      },
      Ok: (data) => {
        trips = data.groups.flatMap((g) => g.trips)
        this.rideHailSearchOnBookTabStore.setIsLoading(false)
        this.rideHailSearchOnBookTabStore.setTripsList(trips)
      },
    })

    return trips
  }

  private openTripSelectionModal() {
    this.rideHailSearchOnBookTabStore.setIsOpenTripSelectionModal(true)
  }

  closeTripSelectionModal() {
    this.rideHailSearchOnBookTabStore.setIsOpenTripSelectionModal(false)
  }

  navigateToSearchFlow(trip: TripsList) {
    const processId = trip.transactionId || ''
    const transactionGroupId = Number(trip.transactionGroupId) || 0
    const params = { processId, transactionGroupId }

    this.searchRideHailStore.setSearchRideHailTripInfo(params)
    this.historyService.push(ROUTES.mobility.searchWithParams(params))
  }

  private sendTrackAction(actionName: string, status: string, isHasComingTrip: boolean) {
    trackAction(actionName, {
      status,
      isHasComingTrip,
    })
  }
}
