import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace SearchRideHailResultErrors {
  export const UnexpectedSearchRideHailResultError = createErrorClass(
    'UnexpectedSearchRideHailResultError',
  )

  export const SearchRideHailResultError = createErrorClass('SearchRideHailResultError')
}

export type SearchRideHailResultErrorsIntances = InstanceType<
  typeof SearchRideHailResultErrors[keyof typeof SearchRideHailResultErrors]
>
