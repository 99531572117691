import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type Props = {
  flightId?: string | null
  carRentalId?: string | null
  hotelId?: string | null
}

const tripSegmentIdsInitialState: Props = {
  flightId: null,
  carRentalId: null,
  hotelId: null,
}

const slice = createSlice({
  name: 'segment-ids',
  initialState: { ...tripSegmentIdsInitialState },
  reducers: {
    updateFlightId: (state, { payload }: PayloadAction<{ flightId?: string | null }>) => {
      state.flightId = payload.flightId
    },
    updateCarRentalId: (state, { payload }: PayloadAction<{ carRentalId?: string | null }>) => {
      state.carRentalId = payload.carRentalId
    },
    updateHotelId: (state, { payload }: PayloadAction<{ hotelId?: string | null }>) => {
      state.hotelId = payload.hotelId
    },
  },
})

export const segmentIdsReducer = slice.reducer
export const { updateFlightId, updateCarRentalId, updateHotelId } = slice.actions
