import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const SlideCounter = styled.div`
  ${footnoteMedium};
  min-width: 45px;
  padding: 2px 4px;
  background: ${(props) => props.theme.colors.mainText1};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.mainText2};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 52px;
  right: 16px;
`
