import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { InfiniteScroll } from '@etta/ui/infinite-scroll'
import { TripCard } from '@etta/components/trip-card/trip-card'
import type { LayoutProps } from '../../types'
import { CardList } from './trip-list-content-mobile-styled'

export function TripListContentMobile({
  withAnimation,
  tripType,
  groups,
  isFetchMoreLoading,
  hasNextPage,
  handleFetchMore,
}: LayoutProps) {
  return (
    <InfiniteScroll
      isLoading={isFetchMoreLoading}
      hasNextPage={hasNextPage}
      onFetchMore={handleFetchMore}>
      {groups?.map(({ month, trips }, index) => (
        <div key={index} data-tracking-id={`trip-month_${month}`}>
          <Block marginTop={index ? 30 : 0} marginLeft={10}>
            <Text as={'h3'} variant="subHeadStrong" color="bodyText" aria-label={month}>
              {month}
            </Text>
          </Block>

          <CardList>
            <InfiniteScroll.List withAnimation={withAnimation} itemRole="listitem">
              {trips.map(
                (
                  {
                    name,
                    rollUpStartTime,
                    rollUpEndTime,
                    serviceCategoryList,
                    transactionId,
                    transactionGroupId,
                    cityImageUrl,
                    travelerName,
                  },
                  index,
                ) => (
                  <TripCard
                    key={index}
                    tripType={tripType}
                    transactionId={transactionId}
                    transactionGroupId={transactionGroupId}
                    name={name}
                    startDate={rollUpStartTime}
                    endDate={rollUpEndTime}
                    services={serviceCategoryList}
                    cityImageUrl={cityImageUrl}
                    isFirst={!index}
                    travelerName={travelerName}
                  />
                ),
              )}
            </InfiniteScroll.List>
          </CardList>
        </div>
      ))}
    </InfiniteScroll>
  )
}
