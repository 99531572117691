import { CalculateEmissionsEquivalenceTypeEnum } from '@etta/core/enums/calculate-emissions-equivalence-type.enum'
import type { CalculateEmissionsEquivalenceValueObject } from '@etta/core/value-objects/calculate-emissions-equivalence.object-value'
import {
  CalculateEmissionsBatchResultEquivalenceType,
  RailTransportationMode,
} from '@fiji/graphql/types'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'

export function mapEmissionsEquivalenceToGqlType(
  input: CalculateEmissionsEquivalenceValueObject,
): CalculateEmissionsBatchResultEquivalence {
  return {
    amount: input.amount,
    unit: input.unit,
    type: mapEquivalenceEnumToGqlType(input.type),
  }
}

function mapEquivalenceEnumToGqlType(
  input: CalculateEmissionsEquivalenceTypeEnum,
): CalculateEmissionsBatchResultEquivalenceType {
  switch (input) {
    case CalculateEmissionsEquivalenceTypeEnum.PlasticBag:
      return CalculateEmissionsBatchResultEquivalenceType.PlasticBag
    case CalculateEmissionsEquivalenceTypeEnum.Seedling:
      return CalculateEmissionsBatchResultEquivalenceType.Seedling
    case CalculateEmissionsEquivalenceTypeEnum.SmartphoneCharge:
      return CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge
  }
}

export function mapEmissionsEquivalenceToValueObject(
  input: CalculateEmissionsBatchResultEquivalence,
): CalculateEmissionsEquivalenceValueObject {
  return {
    amount: input.amount,
    unit: input.unit,
    type: mapEquivalenceEnumToValueObject(input.type),
  }
}

function mapEquivalenceEnumToValueObject(
  input: CalculateEmissionsBatchResultEquivalenceType,
): CalculateEmissionsEquivalenceTypeEnum {
  switch (input) {
    case CalculateEmissionsBatchResultEquivalenceType.PlasticBag:
      return CalculateEmissionsEquivalenceTypeEnum.PlasticBag
    case CalculateEmissionsBatchResultEquivalenceType.Seedling:
      return CalculateEmissionsEquivalenceTypeEnum.Seedling
    case CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge:
      return CalculateEmissionsEquivalenceTypeEnum.SmartphoneCharge
  }
}

export function mapRailTransportationModeToEnum(
  input: RailTransportationMode | undefined | null,
): RailTransportationModeEnum {
  switch (input) {
    case RailTransportationMode.Bus:
      return RailTransportationModeEnum.Bus
    case RailTransportationMode.Ferry:
      return RailTransportationModeEnum.Ferry
    case RailTransportationMode.Metro:
      return RailTransportationModeEnum.Metro
    case RailTransportationMode.Hovercraft:
      return RailTransportationModeEnum.Hovercraft
    case RailTransportationMode.PublicTransit:
      return RailTransportationModeEnum.PublicTransit
    case RailTransportationMode.Taxi:
      return RailTransportationModeEnum.Taxi
    case RailTransportationMode.Train:
      return RailTransportationModeEnum.Train
    case RailTransportationMode.Tramlink:
      return RailTransportationModeEnum.Tramlink
    case RailTransportationMode.Transfer:
      return RailTransportationModeEnum.Transfer
    case RailTransportationMode.Walk:
      return RailTransportationModeEnum.Walk
    case RailTransportationMode.Unspecified:
    default:
      return RailTransportationModeEnum.Unspecified
  }
}
