import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import type { LayoutProps } from '../../types'
import { useRoomDetails } from '../../use-room-details'
import {
  Wrapper,
  Heading,
  TitleAndPrices,
  IconWrapper,
  PricesAndLink,
  PriceWrapper,
  DatesWrapper,
  DateBlock,
  RoomRatesLinkWrapper,
} from './room-details-desktop-styled'

export function RoomDetailsDesktop({
  roomDetails,
  checkOut,
  checkIn,
  isUpcoming,
  handleOpenDetails,
}: LayoutProps) {
  const {
    title,
    price,
    titleAriaLabel,
    avgPerNightTranslation,
    roomRateDetails,
    roomRateDetailsAriaLabel,
    roomDateCheckIn,
    roomDateCheckOut,
    checkInTranslation,
    checkOutTranslation,
    showDifferingRatesLabel,
    differingRatesLabel,
  } = useRoomDetails({ roomDetails, checkOut, checkIn, isUpcoming })
  return (
    <Wrapper aria-label={titleAriaLabel}>
      <Heading>
        <IconWrapper name="hotelPWA" size="medium" color="mainText1" />
        <TitleAndPrices>
          <Text variant="headline">{title}</Text>
          <div>
            <PricesAndLink>
              <PriceWrapper>
                <Text variant="headline">{price}</Text>
                <Text variant="linkSmall" color="bodyText">
                  {avgPerNightTranslation}
                </Text>
              </PriceWrapper>
              <RoomRatesLinkWrapper>
                <Link
                  size="small"
                  onClick={handleOpenDetails}
                  aria-label={roomRateDetailsAriaLabel}
                  data-tracking-id="hotel-trip-segment-room-rate-details">
                  {roomRateDetails}
                </Link>
                {showDifferingRatesLabel && (
                  <Text variant="captionMedium" color="bodyText1" isBlock>
                    {differingRatesLabel}
                  </Text>
                )}
              </RoomRatesLinkWrapper>
            </PricesAndLink>
          </div>
        </TitleAndPrices>
      </Heading>
      <Block marginVertical={16}>
        <Separator lineType="dashed" color="borderDark" />
      </Block>
      <DatesWrapper>
        <DateBlock aria-label={`${checkInTranslation} ${roomDateCheckIn}`}>
          <Text variant="captionStrong" color="bodyText1" textTransform="uppercase">
            {checkInTranslation}
          </Text>
          <Text variant="subHeadStrong" color="mainText">
            {roomDateCheckIn}
          </Text>
        </DateBlock>
        <DateBlock aria-label={`${checkOutTranslation} ${roomDateCheckOut}`}>
          <Text variant="captionStrong" color="bodyText1" textTransform="uppercase">
            {checkOutTranslation}
          </Text>
          <Text variant="subHeadStrong" color="mainText">
            {roomDateCheckOut}
          </Text>
        </DateBlock>
      </DatesWrapper>
    </Wrapper>
  )
}
