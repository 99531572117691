import type { CalculateTripEmissionsResultFlight } from '@fiji/graphql/types'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects/review-trip.value-object'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { FlightEmission } from './types'

export function getFlightEmissionsSummary(
  segments: TripSegmentValueObject[],
  rawFlightEmissions?: CalculateTripEmissionsResultFlight[] | null,
): {
  flightEmissions: FlightEmission[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  const flightEmissions: FlightEmission[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    if (typeCheck.isFlightSegment(segment)) {
      const flightEmission = rawFlightEmissions?.find(
        (flight) => flight.customRef === segment?.legId,
      )

      tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, flightEmission?.tonnesOfEmissions)
      averageTonnesOfEmissionsSummary = sum(
        averageTonnesOfEmissionsSummary,
        flightEmission?.averageRouteEmissionTonnes,
      )

      const {
        plasticBagEquivalenceAmount,
        treeSeedlingEquivalenceAmount,
        smartphoneChargeEquivalenceAmount,
      } = getEquivalencesCount(flightEmission?.equivalences)

      treeSeedlingsEquivalenceTotalCount = sum(
        treeSeedlingsEquivalenceTotalCount,
        treeSeedlingEquivalenceAmount,
      )

      plasticBagsEquivalenceTotalCount = sum(
        plasticBagsEquivalenceTotalCount,
        plasticBagEquivalenceAmount,
      )

      smartphoneChargeEquivalenceTotalCount = sum(
        smartphoneChargeEquivalenceTotalCount,
        smartphoneChargeEquivalenceAmount,
      )

      const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
        averageEmissionsTonnes: flightEmission?.averageRouteEmissionTonnes || 0,
        tonnesOfEmissions: flightEmission?.tonnesOfEmissions || 0,
      })

      flightEmissions.push({
        originAirportCode: segment?.segments[0].departure.airportCode,
        destiationAirportCode: segment?.segments[segment?.segments.length - 1].arrival.airportCode,
        emissionsKg: tonnesToKg(flightEmission?.tonnesOfEmissions || 0),
        deviationLevel,
        percentDeviation,
      })
    }
  })

  return {
    flightEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
