/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect } from 'react'
import i18n from '@fiji/i18n'
import { useAppSelector } from '@fiji/store'
import { appMode } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'

export function LanguagePreference() {
  const { featureFlags } = useFeatureFlags()

  const languagesCommon: { [index: string]: string } = {
    da: 'dk',
    de: 'de',
    es: 'es',
    fi: 'fi',
    it: 'it',
    ja: 'ja',
    nb: 'no',
    nl: 'nl',
    sv: 'se',
    zh: 'zh',
  }

  const languages: { [index: string]: string } = {
    'da-DK': 'dk',
    'de-DE': 'de',
    'en-GB': 'gb',
    'en-US': 'en',
    'es-ES': 'es',
    'es-MX': 'mx',
    'fi-FI': 'fi',
    'fr-CA': 'ca',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'ja-JP': 'ja',
    'nb-NO': 'no',
    'nl-NL': 'nl',
    'pt-BR': 'br',
    'pt-PT': 'pt',
    'sv-SE': 'se',
    'zh-Hans-CN': 'zh',
  }

  const getLangCode = (key: string) => {
    if (languages[key]) {
      return languages[key]
    }

    const languageCode = key.split('-')[0]

    if (languagesCommon[languageCode]) {
      return languagesCommon[languageCode]
    }

    return 'en'
  }

  const momentJSLanguages: { [index: string]: string } = {
    'da-DK': 'da',
    'de-DE': 'de',
    'en-GB': 'en-gb',
    'en-US': 'en',
    'es-ES': 'es',
    'es-MX': 'es-do',
    'fi-FI': 'fi',
    'fr-CA': 'fr-ca',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'ja-JP': 'ja',
    'nb-NO': 'nb',
    'nl-NL': 'nl',
    'pt-BR': 'pt-br',
    'pt-PT': 'pt',
    'sv-SE': 'sv',
    'zh-Hans-CN': 'zh',
  }

  const locale = useAppSelector((state) => state.user.trustedLocale || state.user.locale)

  const ettaLocale = window.navigator.language
  const isAutoTranslate =
    featureFlags.isEttaAutoTranslationEnabled && appMode.isEttaStandaloneMobile
  const currentLocale = isAutoTranslate && ettaLocale ? ettaLocale : locale

  useEffect(() => {
    if (currentLocale) {
      const lng = getLangCode(currentLocale)
      if (lng) {
        i18n.changeLanguage(lng).then()
      }
      if (currentLocale !== 'en-US') {
        const momentLng = momentJSLanguages[currentLocale]
        import('moment/locale/' + momentLng)
          .then(() => {
            moment.locale(momentLng)
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.error(`${momentLng} locale hasn't found`)
          })
      }
    }
  }, [languages, currentLocale])

  return <></>
}
