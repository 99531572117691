import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { PostBookingErrors } from '../../core/errors'
import type { PostBookingAdapterResult } from '../types'
import type { TripEntity } from '../../core/entities/trip.entity'
import { CartPostBookingDataProvider } from './cart-post-booking.data-provider/cart-post-booking.data-provider'
import type { GetTripDetailsArgs, CancelReservationArgs } from './types'
import { TripMapper } from './mappers/trip-mapper'

@Adapter()
export class CartPostBookingAdapter {
  constructor(
    @Inject()
    private cartPostBookingDataProvider: CartPostBookingDataProvider,
  ) {}

  async getTripDetails(
    { tripId }: GetTripDetailsArgs,
    forceUpdate?: boolean,
  ): PostBookingAdapterResult<TripEntity> {
    try {
      const { data, error } = await this.cartPostBookingDataProvider.getTripDetails(
        {
          tripId,
        },
        forceUpdate,
      )
      if (error) {
        return Result.Err(new PostBookingErrors.GetTripError(error))
      }

      // Updating to TypeScript 4.9 should allow us to combine the identical if statements
      if (data.tripDetails.__typename === 'UnexpectedError') {
        return Result.Err(new PostBookingErrors.GetTripNotFoundError(data.tripDetails.message))
      }

      if (data.tripDetails.__typename === 'TripNotFoundResponse') {
        return Result.Err(new PostBookingErrors.GetTripNotFoundError(data.tripDetails.message))
      }

      return Result.Ok(TripMapper.toTripEntity(data.tripDetails))
    } catch (e) {
      return Result.Err(new PostBookingErrors.GetTripUnexpectedError(e))
    }
  }

  async cancelTripReservation({
    domain,
    reservationId,
  }: CancelReservationArgs): PostBookingAdapterResult<{ success: boolean }> {
    if (!reservationId) {
      return Result.Err(new PostBookingErrors.NoReservationIdError('Reservation id is empty'))
    }
    try {
      const { data } = await this.cartPostBookingDataProvider.cancelReservation({
        domain,
        reservationId,
      })

      if (data?.cancelReservation.__typename === 'UnexpectedError') {
        return Result.Err(
          new PostBookingErrors.CancelTripUnexpectedError(data.cancelReservation.message),
        )
      }

      if (!data?.cancelReservation.success) {
        return Result.Err(
          new PostBookingErrors.CancelTripSegmentError(`Failed to cancel ${domain} domain`),
        )
      }

      return Result.Ok({
        success: data.cancelReservation.success,
      })
    } catch (e) {
      return Result.Err(new PostBookingErrors.CancelTripSegmentUnexpectedError(e))
    }
  }
}
