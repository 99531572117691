import { useTranslation } from 'react-i18next'
import { LegendNode } from './legend-node'
import { LegendContainer } from './legend-styled'

type Props = {
  isPreferredInOneLine?: boolean
}

export function Legend({ isPreferredInOneLine }: Props) {
  const { t } = useTranslation()

  return (
    <LegendContainer isPreferredInOneLine={isPreferredInOneLine}>
      <LegendNode icon="economyAvailable">{t('AirSeatMap.Legend.Available')}</LegendNode>
      <LegendNode icon="economyPaid">{t('AirSeatMap.Legend.PaidSeat')}</LegendNode>
      <LegendNode icon="economyPremium">{t('AirSeatMap.Legend.Premium')}</LegendNode>
      <LegendNode icon="economyNotAvailable">{t('AirSeatMap.Legend.Unavailable')}</LegendNode>
      <LegendNode icon="economySelected">{t('AirSeatMap.Legend.Selected')}</LegendNode>
    </LegendContainer>
  )
}
