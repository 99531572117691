import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-block: 16px;
  padding-block: 16px;
  padding-inline: 12px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
`
