import type { CarReservationRow } from '@etta/components/purchase-reservation-success/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { CreditCard, TripCost } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { getCardLabel } from '../payment-card/get-card-label'

type Args = {
  tripCost: TripCost
  segments: TripSegmentValueObject[]
  creditCard?: CreditCard
}

export function useCarRentalDetails({ tripCost, segments, creditCard }: Args) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { mainCost, secondCost } = formatRate(tripCost.total)
  const cardDetails = creditCard && getCardLabel(creditCard)

  return {
    cardDetails,
    mainCost,
    secondCost,
    rate: tripCost.total,
    creditCard,
    rows: segments.reduce<CarReservationRow[]>((acc, segment) => {
      if (!segmentTypeCheckActions.isCarRentalSegment(segment)) {
        return acc
      }
      acc.push({
        segmentId: segment.carId,
        pickUpDate: segment.pickUpTime,
        dropOffDate: segment.dropOffTime,
        supplier: segment.vendor,
        carClass: segment.carClass,
      })
      return acc
    }, []),
  }
}
