import { MediaLayout } from '@etta/ui/media-layout'
import type { PriceDetailsHeaderProps } from '../types'
import { PriceDetailsHeaderMobile } from './mobile'
import { PriceDetailsHeaderDesktop } from './desktop'

export function PriceDetailsHeaderLayout(props: PriceDetailsHeaderProps) {
  return (
    <MediaLayout
      mobileSlot={<PriceDetailsHeaderMobile {...props} />}
      desktopSlot={<PriceDetailsHeaderDesktop {...props} />}
    />
  )
}
