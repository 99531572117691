import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripDetailsCostSummary } from '@fiji/graphql/types'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { TripStatus } from '@fiji/graphql/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

type Args = {
  costs?: TripDetailsCostSummary | null
  segments: SegmentPostBookingValueObject[]
  type: TripStatus
}

const i18Base = 'PostBooking.TripDetails.'

export function useTripCostsSidebar({ costs, type }: Args) {
  const { t } = useTranslation()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const {
    featureFlags: { isDesktopHeldTripsFlowEnabled, isAppleBannerEnabled },
  } = useFeatureFlags()
  const isAppleBannerShown = isAppleBannerEnabled && brandConfigurationStore.isAppleTheme

  const paidNowSegments = useMemo(() => {
    const paidNowSegments = []
    if (!costs) {
      return []
    }

    if (costs?.carRental?.isPaid && costs.carRental.total) {
      paidNowSegments.push({ type: t(i18Base + 'CarRental'), cost: costs.carRental.total })
    }

    if (costs?.carService?.total && !costs.carService.isPaid) {
      paidNowSegments.push({ type: t(i18Base + 'CarService'), cost: costs.carService.total })
    }

    if (costs?.hotel?.isPaid && costs.hotel.total) {
      paidNowSegments.push({ type: t(i18Base + 'Hotel'), cost: costs.hotel.total })
    }

    if (costs?.flight?.total) {
      paidNowSegments.push({ type: t(i18Base + 'Flights'), cost: costs.flight.total })
    }

    if (costs?.train?.fare?.total) {
      paidNowSegments.push({ type: t(i18Base + 'Train'), cost: costs.train.fare?.total })
    }

    return paidNowSegments
  }, [costs, t])

  const payLaterSegments = useMemo(() => {
    const payLaterSegments = []
    if (!costs) {
      return []
    }

    if (costs?.carRental?.total && !costs.carRental.isPaid) {
      payLaterSegments.push({ type: t(i18Base + 'CarRental'), cost: costs.carRental.total })
    }

    if (costs?.carService?.total && !costs.carService.isPaid) {
      payLaterSegments.push({ type: t(i18Base + 'CarService'), cost: costs.carService.total })
    }

    if (costs?.hotel?.total && !costs.hotel.isPaid) {
      payLaterSegments.push({ type: t(i18Base + 'Hotel'), cost: costs.hotel.total })
    }

    return payLaterSegments
  }, [costs, t])

  const isCancelAvailable =
    type !== TripStatus.AutoCancelled &&
    type !== TripStatus.Completed &&
    type !== TripStatus.Cancelled
  const isContentAvailable = !!(
    type !== TripStatus.Cancelled &&
    type !== TripStatus.AutoCancelled &&
    (paidNowSegments.length || payLaterSegments.length)
  )

  const isPayedLaterLabeled = type !== TripStatus.Completed

  const isTripOnHold = type === TripStatus.OnHold

  return {
    paidNowSegments,
    payLaterSegments,
    isCancelAvailable,
    isContentAvailable,
    isPayedLaterLabeled,
    isTripOnHold,
    isAppleSite: brandConfigurationStore.isAppleTheme,
    isAppleBannerShown,
    isDesktopHeldTripsFlowEnabled,
  }
}
