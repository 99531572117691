import { usePostBookingContext } from '@etta/modules/post-booking'
import type { PermittedStation } from '@etta/modules/post-booking/core/value-objects'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'

type Args = {
  ticketRestrictions: TicketRestrictionsInfo
  originStation: string
  destinationStation: string
  permittedOriginStations: PermittedStation[]
  permittedDestinationStations: PermittedStation[]
}

export function useRailDetailsPermittedStations({
  ticketRestrictions,
  originStation,
  destinationStation,
  permittedOriginStations,
  permittedDestinationStations,
}: Args) {
  const { postBookingTripDetailsStore } = usePostBookingContext()
  const { ticketRestrictionsToggle, permittedStationsToggle } = postBookingTripDetailsStore

  const handleTicketRestrictionsOpen = () => {
    postBookingTripDetailsStore.setTicketRestrictionsInfo(ticketRestrictions)
    ticketRestrictionsToggle.handleOpen()
    permittedStationsToggle.handleClose()
  }

  const handlePermittedStationsOpen = () => {
    postBookingTripDetailsStore.setPermittedStationsInfo({
      originStation,
      destinationStation,
      permittedOriginStations,
      permittedDestinationStations,
      onClick: handleTicketRestrictionsOpen,
    })
    permittedStationsToggle.handleOpen()
  }

  return {
    handleTicketRestrictionsOpen,
    handlePermittedStationsOpen,
  }
}
