import { Icon } from '@etta/ui/icon'
import type { TripConfirmationType } from '@etta/modules/booking/core/value-objects/trip-confirmation-type.value-object'
import { TripIconHeld } from './header-styled'

type Props = {
  confirmationType: TripConfirmationType
}
export function TripIcon({ confirmationType }: Props) {
  switch (confirmationType) {
    case 'unconfirmed': {
      return <Icon name="errorPWA" color="error" size="medium" />
    }
    case 'hold':
      return (
        <TripIconHeld>
          <Icon name="heldTripPWA" color="white" />
        </TripIconHeld>
      )
    case 'partial':
      return <Icon name="tickBoldPWA" color="mainText" size="medium" />
    case 'regular':
    default:
      return <Icon name="tickBoldPWA" color="white" size="medium" />
  }
}
