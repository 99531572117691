import type { AdaptiveFlight } from '@etta/components/flight-details/types'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import type { FlightLegSubSegment, SearchFlightLegSubSegment } from '@fiji/graphql/types'
import { ExpandedView } from './expanded-view'
import { Container } from './flight-details-legend-styled'
import { FlightDetailsLegendWithToggle } from './flight-details-legend-with-toggle'

type Props = {
  withToggle?: boolean
  flight: AdaptiveFlight
  flightLegSegments?: FlightLegSubSegment[] | SearchFlightLegSubSegment[]
  backgroundColor?: LayoutBackgroundColor
  segmentDaysInBetween?: number
  shouldShowAirportChangeBadge?: boolean
  onToggle?: () => void
}

export function FlightDetailsLegend({
  withToggle,
  flight,
  backgroundColor,
  segmentDaysInBetween,
  flightLegSegments,
  shouldShowAirportChangeBadge,
  onToggle,
}: Props) {
  if (withToggle) {
    return (
      <FlightDetailsLegendWithToggle
        flight={flight}
        flightLegSegments={flightLegSegments}
        backgroundColor={backgroundColor}
        segmentDaysInBetween={segmentDaysInBetween}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        onToggle={onToggle}
      />
    )
  }

  return (
    <Container>
      <ExpandedView
        backgroundColor={backgroundColor}
        flight={flight}
        daysInBetween={segmentDaysInBetween}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
      />
    </Container>
  )
}
