import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style/typography'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'

export const Container = styled.div`
  padding: 12px 12px 12px 13px;
  border-radius: 8px;
  flex-grow: 1;
  background-color: ${(p) => hexToRgba(p.theme.colors.error, 0.1)};
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ReasonItem = styled.div`
  position: relative;
  margin-top: 6px;
  padding-left: 22px;

  &:before {
    position: absolute;
    content: '';
    top: 7px;
    left: 12px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.mainText};
  }

  &:first-child {
    margin-top: 7px;
  }
`

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`

export const OutOfPolicyText = styled.span`
  color: ${(props) => props.theme.colors.mainText};
  ${footnoteMedium};
  line-height: 20px;
`
