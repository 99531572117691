import type { FeatureFlagsValueObject } from '@etta/modules/feature-flags/core/value-object/feature-flags.value-object'
import type { GetFeatureFlagsQuery } from '@fiji/graphql/hooks'

export class FeatureFlagsMapper {
  static toFeatureFlagsValueObject(
    featureFlags: GetFeatureFlagsQuery['featureFlags'],
  ): FeatureFlagsValueObject {
    return {
      isAddTrainToItineraryEnabled: featureFlags.isAddTrainToItineraryEnabled,
      isHotelSafetyCheckEnabled: featureFlags.isHotelSafetyCheckEnabled,
      isHotelRateCapEnabled: featureFlags.isHotelRateCapEnabled,
      isFlightSafetyCheckEnabled: featureFlags.isFlightSafetyCheckEnabled,
      isDelegatedFlowEnabled: featureFlags.isDelegatedFlowEnabled,
      isDesktopDelegatedFlowEnabled: featureFlags.isDesktopDelegatedFlowEnabled,
      isDesktopLayoutFlightFlowEnabled: featureFlags.isDesktopLayoutFlightFlowEnabled,
      isDesktopLayoutHotelFlowEnabled: featureFlags.isDesktopLayoutHotelFlowEnabled,
      isDesktopLayoutPurchaseFlowEnabled: featureFlags.isDesktopLayoutPurchaseFlowEnabled,
      isDesktopLayoutRTPFlowEnabled: featureFlags.isDesktopLayoutRTPFlowEnabled,
      isDesktopLayoutTripsFlowEnabled: featureFlags.isDesktopLayoutTripsFlowEnabled,
      isDesktopTripCartEnabled: featureFlags.isDesktopTripCartEnabled,
      isEttaAutoTranslationEnabled: featureFlags.isEttaAutoTranslationEnabled,
      isFijiPurchaseFixEnabled: featureFlags.isFijiPurchaseFixEnabled,
      isMultiCityEnabled: featureFlags.isMultiCityEnabled,
      isPasswordExpirationFlowEnabled: featureFlags.isPasswordExpirationFlowEnabled,
      isRailServiceEnabled: featureFlags.isRailServiceEnabled,
      isSSOEnabled: featureFlags.isSSOEnabled,
      isSplitCostAllocationEnabled: featureFlags.isSplitCostAllocationEnabled,
      isSustainabilityEnabled: featureFlags.isSustainabilityEnabled,
      isUberEnabled: featureFlags.isUberEnabled,
      isReserveAirportPickupEnabled: featureFlags.isReserveAirportPickupEnabled,
      isMobilityExpenseEnabled: featureFlags.isMobilityExpenseEnabled,
      isMobilityZoningEnabled: featureFlags.isMobilityZoningEnabled,
      isMobilityDesktopEnabled: featureFlags.isMobilityDesktopEnabled,
      isMobilityMultiModalEnabled: featureFlags.isMobilityMultiModalEnabled,
      isMobilitySearchFormV3Enabled: featureFlags.isMobilitySearchFormV3Enabled,
      isMobilityBookTabEnabled: featureFlags.isMobilityBookTabEnabled,
      isUnusedTicketsEnabled: featureFlags.isUnusedTicketsEnabled,
      isUnusedTicketsOptOutEnabled: featureFlags.isUnusedTicketsOptOutEnabled,
      isDesktopUnusedTicketsOptOutEnabled: featureFlags.isDesktopUnusedTicketsOptOutEnabled,
      isDesktopUnusedTicketsEnabled: featureFlags.isDesktopUnusedTicketsEnabled,
      isDesktopLayoutTrainTabEnabled: featureFlags.isDesktopLayoutTrainTabEnabled,
      isDesktopLayoutMultiCityEnabled: featureFlags.isDesktopLayoutMultiCityEnabled,
      isRailAccreditationEnabled: featureFlags.isRailAccreditationEnabled,
      isRailSearchFiltersEnabled: featureFlags.isRailSearchFiltersEnabled,
      isRailSearchSortEnabled: featureFlags.isRailSearchSortEnabled,
      isRailCardSelectionEnabled: featureFlags.isRailCardSelectionEnabled,
      isRailOutofPolicyEnabled: featureFlags.isRailOutofPolicyEnabled,
      isMobileHeldTripsFlowEnabled: featureFlags.isMobileHeldTripsFlowEnabled,
      isKeyboardNavigationStylesEnabled: featureFlags.isKeyboardNavigationStylesEnabled,
      isDesktopHeldTripsFlowEnabled: featureFlags.isDesktopHeldTripsFlowEnabled,
      isWhatFixPWAEnabled: featureFlags.isWhatFixPWAEnabled,
      isToggleEttaThemeEnabled: featureFlags.isToggleEttaThemeEnabled,
      isFeedbackEnabled: featureFlags.isFeedbackEnabled,
      isTripExpiredDateGeneratedByUTCEnable: featureFlags.isTripExpiredDateGeneratedByUTCEnable,
      isRecentSearchesEnabled: featureFlags.isRecentSearchesEnabled,
      isAppleMapProviderEnabled: featureFlags.isAppleMapProviderEnabled,
      isCustomLogoutRedirectEnabled: featureFlags.isCustomLogoutRedirectEnabled,
      isOnHoldEnabled: featureFlags.isOnHoldEnabled,
      isSettingsPageInformationSectionEnabled: featureFlags.isSettingsPageInformationSectionEnabled,
      isSettingsPageSettingsSectionEnabled: featureFlags.isSettingsPageSettingsSectionEnabled,
      isSettingsPageOrganizationSectionEnabled:
        featureFlags.isSettingsPageOrganizationSectionEnabled,
      isRedirectOnSiteDomainEnabled: featureFlags.isRedirectOnSiteDomainEnabled,
      isNewAirFiltersModalEnabled: featureFlags.isNewAirFiltersModalEnabled,
      isNewAirExposedFiltersEnabled: featureFlags.isNewAirExposedFiltersEnabled,
      isAppleBannerEnabled: featureFlags.isAppleBannerEnabled,
      isMobilityOnDemandRidesEnabled: featureFlags.isMobilityOnDemandRidesEnabled,
      isBookItineraryTravelPreferencesEnabled: featureFlags.isBookItineraryTravelPreferencesEnabled,
      isSsoSettingsEnabled: featureFlags.isSsoSettingsEnabled,
      isNewHotelExposedFiltersEnabled: featureFlags.isNewHotelExposedFiltersEnabled,
      isNewHotelFiltersModalEnabled: featureFlags.isNewHotelFiltersModalEnabled,
      isAlertsEnabled: featureFlags.isAlertsEnabled,
      isCarRentalsNewFiltersEnabled: featureFlags.isCarRentalsNewFiltersEnabled,
      isRecentSearchesFilteringEnabled: featureFlags.isRecentSearchesFilteringEnabled,
      isExternalLinksEnabled: featureFlags.isExternalLinksEnabled,
      isActiveItineraryPurchaseFlowEnabled: featureFlags.isActiveItineraryPurchaseFlowEnabled,
      isHotelRetryWorkflowEnabled: featureFlags.isHotelRetryWorkflowEnabled,
      isNewCheckoutPageLayoutEnabled: featureFlags.isNewCheckoutPageLayoutEnabled,
      isTripAlignmentWorkflowEnabled: featureFlags.isTripAlignmentWorkflowEnabled,
      isChangingReturnFlightEnabled: featureFlags.isChangingReturnFlightEnabled,
      isBookAgainAllowed: featureFlags.isBookAgainEnabled,
      isActivateAccountFlowEnabled: featureFlags.isActivateAccountFlowEnabled,
      isNewEttaOnlyEnabled: featureFlags.isNewEttaOnlyEnabled,
      isNewHelpCenterLinkEnabled: featureFlags.isNewHelpCenterLinkEnabled,
      isBookAgainDelegateFlowEnabled: featureFlags.isBookAgainDelegateFlowEnabled,
      isNewTripDetailsPageEnabled: featureFlags.isNewTripDetailsPageEnabled,
      isNewLoadingConfirmationEnabled: featureFlags.isNewLoadingConfirmationEnabled,
      isOOPModalOnSearchResultFlightPageEnabled:
        featureFlags.isOOPModalOnSearchResultFlightPageEnabled,
      isServiceFeeEnabled: featureFlags.isServiceFeeEnabled,
      isUserActivationEnabled: featureFlags.isUserActivationEnabled,
      isQualtricsEnabled: featureFlags.isQualtricsEnabled,
      isOOPModalEnabled: featureFlags.isOOPModalEnabled,
      isPWACookieConsentEnabled: featureFlags.isPWACookieConsentEnabled,
      isNewEmissionsBrandingEnabled: featureFlags.isNewEmissionsBrandingEnabled,
      isRetailShoppingUIEnabled: featureFlags.isRetailShoppingUIEnabled,
    }
  }
}
