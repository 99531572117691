import type { ReactNode } from 'react'
import { checkMarkFilled } from '../../../check-filled'
import type { SliderValues } from '../../../types'
import type { MarkStyledProps } from './slider-mark-components'
import { MarkStyled, MarksContent } from './slider-mark-components'

type Props<T extends SliderValues> = {
  props: React.HTMLProps<HTMLSpanElement>
  isDisabled: boolean
  getMarkTitle: (markValue: number) => ReactNode
  min: number
  max: number
  value: T
  isFilledValue: boolean
  withoutMarksAligment: boolean
}

export function SliderMark<T extends SliderValues>({
  props,
  isDisabled,
  getMarkTitle,
  min,
  max,
  value,
  isFilledValue,
  withoutMarksAligment = false,
}: Props<T>) {
  const markValue = props.key as number
  const isFilled = !isDisabled && checkMarkFilled(markValue, value, isFilledValue)
  const markTitle = getMarkTitle ? getMarkTitle(markValue) : ''
  const isFirstMarkAligment = markValue === min
  const isLastMarkAligment = markValue === max

  return (
    <MarkStyled
      {...(props as MarkStyledProps)}
      className={[props.className, isFilled ? 'is-filled' : 'not-filled'].join(' ')}
      isFilled={isFilled}
      isDisabled={isDisabled}>
      <MarksContent
        isFirstMark={isFirstMarkAligment}
        isLastMark={isLastMarkAligment}
        isMarksAligment={!withoutMarksAligment}>
        {markTitle}
      </MarksContent>
    </MarkStyled>
  )
}
