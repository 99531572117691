import { useMemo } from 'react'
import type { RideHailSuggestedLocation } from '@fiji/graphql/hooks'
import { useGetRideHailSuggestedLocationsQuery } from '@fiji/graphql/hooks'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMobilitySearchQuery } from '@fiji/hooks/search-queries/use-mobility-search-query/use-mobility-search-query'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import type { PlaceWithAddress } from '../types'

export function useMobilitySuggestedLocations() {
  const { queryParams } = useMobilitySearchQuery()
  const { searchRideHailStore } = useRideHailSearchContext()
  const { processId } = searchRideHailStore.searchRideHailTripInfo

  const { data, loading: isLoading, error, refetch } = useGetRideHailSuggestedLocationsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        processId: processId || queryParams.processId,
      },
    },
  })

  const suggestedLocations = useMemo((): RideHailSuggestedLocation[] => {
    if (data?.rideHailSuggestedLocations.__typename === 'RideHailSuggestedLocationsResponse') {
      return data?.rideHailSuggestedLocations.rideHailSuggestedLocations ?? []
    }

    return []
  }, [data])

  const suggestedPlaces: PlaceWithAddress[] = useMemo(() => {
    return suggestedLocations.map(
      (suggestedLocation): PlaceWithAddress => {
        return {
          placeId: '',
          address: suggestedLocation.location.address || undefined,
          name: suggestedLocation.name,
          locationType: suggestedLocation.suggestedLocationType,
          airportCode: suggestedLocation.airportCode || undefined,
          countryCode: suggestedLocation.countryCode || undefined,
          latitude: suggestedLocation.location.geocode?.lat,
          longitude: suggestedLocation.location.geocode?.long,
        }
      },
    )
  }, [suggestedLocations])

  const { handleRefetchRequest } = useRefetchRequest({
    refetch,
    loading: isLoading,
  })

  return {
    suggestedLocations,
    suggestedPlaces,
    isLoading,
    error,
    handleRefetchRequest,
  }
}
