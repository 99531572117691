import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import type { Props } from '../types'
import {
  UserInfoWrapper,
  UserInfo,
  UserAvatar,
  UserDetails,
  UserInfoBlock,
  ErrorIcon,
  ErrorSection,
  ErrorMessageSection,
} from './profile-box-desktop-styled'

const i18Base = 'TravelerInfo'
export function ProfileBoxDesktop({
  isGuest,
  isValid,
  initials,
  fullName,
  email,
  errorMessage,
  onLinkClick,
}: Props) {
  const { t } = useTranslation()

  return (
    <UserInfoWrapper isError={!isValid}>
      <UserInfo>
        <UserAvatar aria-label={initials}>{initials}</UserAvatar>
        <UserInfoBlock>
          <UserDetails>
            <Text aria-label={fullName} variant="headline" color="mainText">
              {fullName}
            </Text>
            {!errorMessage && (
              <Link aria-label={t(i18Base + '.EditLabel')} size="small" onClick={onLinkClick}>
                {t(`${i18Base}.${isGuest ? 'EditTraveler' : 'Edit'}`)}
              </Link>
            )}
          </UserDetails>
          <Text aria-label={email} variant="footnoteMedium" color="bodyText">
            {email}
          </Text>
          {errorMessage && (
            <ErrorSection>
              <ErrorMessageSection>
                <ErrorIcon name="warningFilledPWA" size="small" color="error" />
                <Text variant="footnoteMedium" color="mainText">
                  {errorMessage}
                </Text>
              </ErrorMessageSection>
              <Link size="small" onClick={onLinkClick}>
                {t(`${i18Base}.UpdateYourProfile`)}
              </Link>
            </ErrorSection>
          )}
        </UserInfoBlock>
      </UserInfo>
    </UserInfoWrapper>
  )
}
