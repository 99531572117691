import styled from 'styled-components'
import { buttonSmall, withStyledText } from '@fiji/style'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  text-align: center;
`

export const InfoImage = styled.img`
  display: block;
  height: auto;
  margin: auto;
  height: ${(props) => props.height ?? 84}px;
`

export const WarningText = styled.p`
  ${withStyledText};
  margin: 20px 0 10px 0;
`

export const Description = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
  align: 'center',
}))`
  ${withStyledText};
  margin: 0;
`

export const RetryButton = styled(Button)`
  margin-top: 24px;
  padding: 4px 16px;
  ${buttonSmall}
`
export const UberQrCodeWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin: 16px;
`
