import { useRef } from 'react'

const INITIAL_VALUE = false

type UseBackDetectArgs = {
  transitionIndex?: number
  isPageChanged: boolean
}

export default function useBackDetect({ transitionIndex, isPageChanged }: UseBackDetectArgs) {
  const lastIndexRef = useRef(0)
  const lastValueRef = useRef(INITIAL_VALUE)

  if (!isPageChanged) {
    return { isBack: lastValueRef.current }
  }

  const lastIndexValue = lastIndexRef.current
  const currentIndex = transitionIndex || lastIndexValue
  const isBack = lastIndexValue ? lastIndexValue >= currentIndex : INITIAL_VALUE

  lastIndexRef.current = currentIndex
  lastValueRef.current = isBack

  return { isBack }
}
