import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { SelectSearch } from '@etta/ui/select-search'
import { TextField } from '@etta/ui/text-field'
import { TextFieldGroup } from '@etta/ui/text-field-group'
import { Swap } from '@etta/ui/swap'
import type { OptionType } from '@etta/ui/select'
import { PreferencesLabel } from '@etta/components/preferences-label/preferences-label'
import type { DisabledMembership } from '@fiji/types'
import type { LoyaltyProgram } from '@fiji/graphql/types'
import { UnavailableMessage } from '../unavailable-message'
import { DisabledMembershipWarning } from './disabled-membership-warning'
import { useLoyalty } from './use-loyalty'

type Props = {
  label: string
  selectLabel: string
  memberLabel: string
  options: OptionType<string>[]
  value: LoyaltyProgram[] | null
  fromProfile?: LoyaltyProgram[] | null
  onUpdate: (data: LoyaltyProgram[]) => void
  placeholder: string
  disabledMemberships?: DisabledMembership[]
  errorMap?: Record<string, string>
}

export function LoyaltyPrograms({
  label,
  selectLabel,
  memberLabel,
  options,
  value,
  fromProfile,
  onUpdate,
  placeholder,
  disabledMemberships,
  errorMap,
}: Props) {
  const { t } = useTranslation()
  const { onVendorUpdate, onNumberUpdate } = useLoyalty({
    options,
    value,
    fromProfile,
    onUpdate,
  })

  return (
    <Block marginBottom={40}>
      <Block marginBottom={12}>
        <PreferencesLabel icon="loyaltyPWA">{label}</PreferencesLabel>
      </Block>
      <Swap
        is={!options.length}
        isSlot={<UnavailableMessage>{t('FlightMembershipUnavailable')}</UnavailableMessage>}>
        {value?.map((membership, index) => {
          if (disabledMemberships?.map((el) => el.code)?.includes(membership.vendorCode)) {
            return ''
          }

          const error = errorMap && errorMap[membership.vendorCode]
          return (
            <Block marginTop={index ? 20 : 0} key={'frequent-flyer-' + index}>
              <TextFieldGroup hasTopBorder={!!error} variant="vertical">
                <SelectSearch
                  label={selectLabel}
                  value={membership.vendorCode}
                  options={options}
                  onOptionSelect={(vendorCode: string) => {
                    onVendorUpdate(vendorCode, index)
                  }}
                  placeholder={placeholder}
                  enablePaginationForOptions
                />
                <TextField
                  label={memberLabel}
                  maxLength={20}
                  value={membership.number || ''}
                  onChange={(number: string) => {
                    onNumberUpdate(number, index, membership)
                  }}
                  helperText={error}
                  helperType="error"
                  data-tracking-id={`loyalty-programs-membership-input_${membership.label}`}
                />
              </TextFieldGroup>
            </Block>
          )
        })}
      </Swap>
      <DisabledMembershipWarning
        isVisible={!!disabledMemberships?.length}
        units={disabledMemberships || []}
      />
    </Block>
  )
}
