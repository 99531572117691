import { useHistory } from 'react-router-dom'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { HistoryBlockContext } from './history-block-context'
import type { HistoryBlockHandler } from './types'

type Props = {
  children: ReactNode
}

export function HistoryBlock({ children }: Props) {
  const history = useHistory()
  const callbacksRef = useRef<HistoryBlockHandler[]>([])

  const contextValue = {
    block: (handler: HistoryBlockHandler) => {
      callbacksRef.current.push(handler)
      return () => {
        const index = callbacksRef.current.indexOf(handler)
        if (index >= 0) {
          callbacksRef.current.splice(index, 1)
        }
      }
    },
  }

  useEffect(() => {
    const unblock = history.block((location, action) => {
      const isPrevented = callbacksRef.current.some((handler) => {
        return handler(location, action) === false
      })

      if (isPrevented) {
        return false
      }
    })

    return () => unblock()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HistoryBlockContext.Provider value={contextValue}>{children}</HistoryBlockContext.Provider>
  )
}
