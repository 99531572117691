import { Service, Inject } from '@etta/di'
import { rollbar } from '@fiji/rollbar'
import { SubmitRecentSearchAdapter } from '../../infra/submit-search.adapter'
import type { SubmitFlightSearchInput } from '../../core/value-objects/submit-flight-search-input.value-object'
import type { SubmitHotelSearchInput } from '../../core/value-objects/submit-hotel-search-input.value-object'
import type { SubmitRailSearchInput } from '../../core/value-objects/submit-rail-search-input.value-object'
import type { SubmitCarRentalSearchInput } from '../../core/value-objects/submit-car-rental-search-input.value-object'
import { RecentSearchesStore } from '../stores/recent-searches.store'
import { FlightMapService } from './flight-map.service'
import { CarRentalMapService } from './car-rental-map.service'
import { HotelMapService } from './hotel-map.service'
import { RailMapService } from './rail-map.service'

@Service()
export class SubmitSearchService {
  constructor(
    @Inject() private submitRecentSearchAdapter: SubmitRecentSearchAdapter,
    @Inject() private flightMapService: FlightMapService,
    @Inject() private carRentalMapService: CarRentalMapService,
    @Inject() private hotelMapService: HotelMapService,
    @Inject() private railMapService: RailMapService,
    @Inject() private recentSearchesStore: RecentSearchesStore,
  ) {}

  submitNewCarRentalSearch(input: SubmitCarRentalSearchInput) {
    if (this.recentSearchesStore.isRecentSearchDisabled) {
      return
    }

    const submitSearch = this.carRentalMapService.getCarRental(input)
    if (submitSearch.isErr()) {
      rollbar.error(submitSearch.getError().getValue().toString())
      return
    }

    this.submitRecentSearchAdapter.submitNewCarRentalSearch(submitSearch.getValue())
  }

  submitNewFlightSearch(input: SubmitFlightSearchInput) {
    if (this.recentSearchesStore.isRecentSearchDisabled) {
      return
    }

    const mappedSegments = this.flightMapService.getFlights(input)
    const mappedType = this.flightMapService.getType(input.type)

    if (mappedSegments.isErr()) {
      rollbar.error(mappedSegments.getError().getMessage())
      return
    }

    this.submitRecentSearchAdapter.submitNewFlightSearch({
      searchParams: {
        flightType: mappedType,
        segments: mappedSegments.getValue(),
      },
    })
  }

  submitNewHotelSearch(input: SubmitHotelSearchInput) {
    if (this.recentSearchesStore.isRecentSearchDisabled) {
      return
    }

    const submitSearch = this.hotelMapService.getHotel(input)
    if (submitSearch.isErr()) {
      rollbar.error(submitSearch.getError().getValue().toString())
      return
    }
    this.submitRecentSearchAdapter.submitNewHotelSearch(submitSearch.getValue())
  }

  submitNewRailSearch(input: SubmitRailSearchInput) {
    if (this.recentSearchesStore.isRecentSearchDisabled) {
      return
    }

    const segments = this.railMapService.getRail(input)
    if (segments.isErr()) {
      rollbar.error(segments.getError().getMessage())
      return
    }
    this.submitRecentSearchAdapter.submitNewRailSearch({
      searchParams: {
        segments: segments.getValue(),
        railCards: this.railMapService.toRailCards(input.railCards),
        railType: this.railMapService.toRailType(input.railTripType),
      },
    })
  }
}
