import { ControlsContainerLayout } from '@etta/ui/date-time-picker/controls/controls-container'
import { ButtonsContainer, Separator } from '@etta/ui/date-time-picker/controls/controls-styled'
import { SelectedDateTitle } from '@etta/ui/date-time-picker/controls/selected-date-title'
import type { LayoutControlsProps } from '@etta/ui/date-time-picker/controls/types'
import { CustomFocusedInput } from '@etta/ui/date-time-picker/controls/types'
import { WeekHeader } from '@etta/ui/date-time-picker/controls/week-header'
import { SelectedDateStatusMobile } from './controls-layout-mobile-styled'

export function ControlsLayoutMobile({
  date,
  time,
  focusedInput,
  dateAriaLabel,
  dateTitle,
  timeTitle,
  timeAriaLabel,
  handleClickTabDate,
  handleClickTabTime,
  isNowSelected,
  forceScreenType,
}: LayoutControlsProps) {
  return (
    <ControlsContainerLayout data-name="date-picker-controls" forceScreenType={forceScreenType}>
      <ButtonsContainer>
        <SelectedDateStatusMobile
          isSelected={focusedInput === CustomFocusedInput.date}
          onClick={handleClickTabDate}
          data-tracking-id="date-tab"
          date={date}
          time={time}
          aria-label={dateAriaLabel}
          isNowSelected={isNowSelected}>
          <SelectedDateTitle dateTitle={dateTitle} forceScreenType={forceScreenType} />
        </SelectedDateStatusMobile>
        <Separator />
        <SelectedDateStatusMobile
          isSelected={focusedInput === CustomFocusedInput.time}
          onClick={handleClickTabTime}
          data-tracking-id="time-tab"
          date={date}
          time={time}
          isStickToRight
          aria-label={timeAriaLabel}>
          <SelectedDateTitle dateTitle={timeTitle} forceScreenType={forceScreenType} />
        </SelectedDateStatusMobile>
      </ButtonsContainer>
      {focusedInput === CustomFocusedInput.date && <WeekHeader />}
    </ControlsContainerLayout>
  )
}
