import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { ReviewTripValueObject } from '@etta/modules/review-trip/core'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass, ImageFormat } from '@fiji/graphql/types'
import { AlignTripTravelVertical } from '@fiji/graphql/hooks'
import { ItineraryErrors } from '../../core/errors'
import type { TripMetadataValueObject } from '../../core/value-objects/trip-metadata.value-object'
import { ItineraryDataProvider } from './itinerary.data-provider'
import type {
  CreateItineraryArgs,
  GetItineraryArgs,
  ItineraryAddCarRentalArgs,
  ItineraryAddFlightArgs,
  ItineraryRemoveCarRentalArgs,
  ItineraryRemoveFlightArgs,
  ItineraryReplaceCarRentalArgs,
  ItineraryReplaceFlightArgs,
  ItineraryResults,
  ItineraryFlightResult,
  ItineraryUpdateTripNameArgs,
  RemoveItineraryArgs,
  ItinerarySelectRoomArgs,
  ItineraryReplaceRoomArgs,
  ItineraryRemoveRoomArgs,
  ItineraryAddRailArgs,
  ItineraryRailResult,
  ItineraryRemoveRailArgs,
  ItineraryChangeFlightSeatsAssignmentArgs,
  ItineraryAlignArgs,
  ReactivateItineraryArgs,
} from './types'
import { ItineraryMapper } from './mappers'

@Adapter()
export class ItineraryAdapter {
  constructor(
    @Inject()
    private itineraryDataProvider: ItineraryDataProvider,
  ) {}

  async getItinerary({
    itineraryId,
    forceUpdate,
  }: GetItineraryArgs): ItineraryResults<ReviewTripValueObject> {
    try {
      const deviceClass =
        screenMatcher.getScreenType() === ScreenType.Mobile
          ? DeviceClass.Mobile
          : DeviceClass.Desktop
      const { data, error } = await this.itineraryDataProvider.getItinerary(
        {
          itineraryId,
          deviceClass,
          imageOptions: {
            format: ImageFormat.Svg,
          },
        },
        forceUpdate,
      )

      if (error) {
        return Result.Err(new ItineraryErrors.GetItineraryError(error))
      }

      return Result.Ok(ItineraryMapper.toTripValueObject(data.itinerary))
    } catch (e) {
      return Result.Err(new ItineraryErrors.GetItineraryUnexpectedError(e))
    }
  }

  async alignItineraryDates({
    alignSegmentTypes,
    endDate,
    itineraryId,
    startDate,
  }: ItineraryAlignArgs): ItineraryResults<{
    trip: ReviewTripValueObject
    metadata: TripMetadataValueObject
  }> {
    try {
      const { data, errors } = await this.itineraryDataProvider.alignTrip({
        input: {
          alignSegmentTypes: alignSegmentTypes.map(
            (segmentType) => AlignTripTravelVertical[segmentType],
          ),
          endDate,
          itineraryId,
          startDate,
        },
      })

      if (errors || !data) {
        return Result.Err(new ItineraryErrors.AlignTripError(errors))
      }

      return Result.Ok({
        trip: ItineraryMapper.toTripValueObject(data.alignTrip.itinerary),
        metadata: data.alignTrip.itineraryMetadata,
      })
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedAlignTripError(e))
    }
  }

  async removeItinerary({
    itineraryId,
  }: RemoveItineraryArgs): ItineraryResults<{ success: boolean; message: string; code: string }> {
    try {
      const { data, errors } = await this.itineraryDataProvider.removeItinerary({ itineraryId })

      if (errors) {
        return Result.Err(new ItineraryErrors.ItineraryRemoveResponse(errors))
      }

      if (!data?.removeItinerary.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryRemoveResponse(data?.removeItinerary.message),
        )
      }

      return Result.Ok(data.removeItinerary)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryRemove(e))
    }
  }

  async reactivateItinerary({
    processId,
  }: ReactivateItineraryArgs): ItineraryResults<{
    success: boolean
    message: string
    code: string
  }> {
    try {
      const { data, errors } = await this.itineraryDataProvider.reactivateItinerary({
        input: { processId: String(processId) },
      })

      if (errors) {
        return Result.Err(new ItineraryErrors.ItineraryReactivateResponse(errors))
      }

      if (!data?.reactivateItinerary.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryReactivateResponse(data?.reactivateItinerary.message),
        )
      }

      return Result.Ok(data.reactivateItinerary)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryReactivate(e))
    }
  }

  async createItinerary(input?: CreateItineraryArgs): ItineraryResults<{ id: string }> {
    try {
      const { data, errors } = await this.itineraryDataProvider.createItinerary(input)

      if (!data?.createItinerary.id) {
        return Result.Err(new ItineraryErrors.NoItineraryError('Itinerary was not created'))
      }

      if (errors) {
        return Result.Err(new ItineraryErrors.CreateItineraryError(errors))
      }

      return Result.Ok({ id: data.createItinerary.id })
    } catch (e) {
      return Result.Err(new ItineraryErrors.CreateItineraryUnexpectedError(e))
    }
  }

  async updateTripName(input: ItineraryUpdateTripNameArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.updateTripName(input)

      if (!data?.updateTripName.success) {
        return Result.Err(new ItineraryErrors.ItineraryUpdateTripNameResponse('Failed to update'))
      }

      return Result.Ok(data.updateTripName)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryUpdateTripName(e))
    }
  }

  async addCarRental(input: ItineraryAddCarRentalArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.addCarRental(input)

      if (!data?.addCarRental.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryAddCarRentalResponse("Couldn't add car rental"),
        )
      }

      return Result.Ok(data.addCarRental)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryAddCarRental(e))
    }
  }

  async replaceCarRental(
    input: ItineraryReplaceCarRentalArgs,
  ): ItineraryResults<{ success: boolean; message: string; code: string }> {
    try {
      const { data } = await this.itineraryDataProvider.replaceCarRental(input)

      if (!data?.replaceCarRental.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryReplaceCarRentalResponse(data?.replaceCarRental.message),
        )
      }

      return Result.Ok(data.replaceCarRental)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryReplaceCarRental(e))
    }
  }

  async removeCarRental(
    input: ItineraryRemoveCarRentalArgs,
  ): ItineraryResults<{ success: boolean; message: string; code: string }> {
    try {
      const { data } = await this.itineraryDataProvider.removeCarRental(input)

      if (!data?.removeCarRental.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryRemoveCarRentalResponse(data?.removeCarRental.message),
        )
      }

      return Result.Ok(data.removeCarRental)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryRemoveCarRental(e))
    }
  }

  async addFlight(input: ItineraryAddFlightArgs): ItineraryFlightResult {
    try {
      const { data } = await this.itineraryDataProvider.addFlight(input)

      return Result.Ok({
        isSoldOut: data?.addFlight.data?.isSoldOut,
        isRepriceFailed: data?.addFlight.data?.isRepriceFailed,
        isSuccess: !!data?.addFlight.success,
        priceChange: data?.addFlight.data?.airPriceChange,
      })
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryAddFlight(e))
    }
  }

  async changeFlight(input: ItineraryReplaceFlightArgs): ItineraryFlightResult {
    try {
      const { data } = await this.itineraryDataProvider.changeFlight(input)

      return Result.Ok({
        isSoldOut: data?.changeFlight?.data?.isSoldOut,
        isSuccess: !!data?.changeFlight.success,
        priceChange: data?.changeFlight.data?.airPriceChange,
        isChangeReturnFlightNotAllow:
          data?.changeFlight.message === 'travel.reprice.return.flight.ticket.exchange.not.allowed',
      })
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryReplaceFlight(e))
    }
  }

  async removeFlight(input: ItineraryRemoveFlightArgs): ItineraryResults<{ message: string }> {
    try {
      const { data } = await this.itineraryDataProvider.removeFlight(input)

      if (!data?.removeFlight.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryRemoveFlightResponse(data?.removeFlight.message),
        )
      }

      return Result.Ok({
        message: data.removeFlight.message,
      })
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryRemoveFlight(e))
    }
  }

  async changeFlightSeatsAssignment(
    input: ItineraryChangeFlightSeatsAssignmentArgs,
  ): ItineraryResults<{ message: string }> {
    try {
      const { data } = await this.itineraryDataProvider.changeFlightSeatsAssignment(input)

      if (!data?.changeSeatsAssignment.success) {
        return Result.Err(
          new ItineraryErrors.ItineraryChangeFlightSeatsAssignmentResponse(
            'Failed to change seats',
          ),
        )
      }

      return Result.Ok(data?.changeSeatsAssignment)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryChangeFlightSeatsAssignment(e))
    }
  }

  async selectRoom(input: ItinerarySelectRoomArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.selectRoom(input)

      if (!data?.selectRoom.success) {
        return Result.Err(new ItineraryErrors.ItinerarySelectRoomResponse("Couldn't select room"))
      }

      return Result.Ok(data.selectRoom)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItinerarySelectRoomResponse(e))
    }
  }

  async replaceRoom(input: ItineraryReplaceRoomArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.replaceRoom(input)

      if (!data?.replaceRoom.success) {
        return Result.Err(new ItineraryErrors.ItineraryReplaceRoomResponse("Couldn't replace room"))
      }

      return Result.Ok(data.replaceRoom)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryReplaceRoom(e))
    }
  }

  async removeRoom(input: ItineraryRemoveRoomArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.removeRoom(input)

      if (!data?.removeRoom.success) {
        return Result.Err(new ItineraryErrors.ItineraryRemoveRoomResponse("Couldn't remove room"))
      }

      return Result.Ok(data.removeRoom)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryRemoveRoom(e))
    }
  }

  async addRail(input: ItineraryAddRailArgs): ItineraryRailResult {
    try {
      const { data } = await this.itineraryDataProvider.addRail({
        ...input,
        direction: ItineraryMapper.toJourneyDirectionEnum(input.direction),
        routeType: ItineraryMapper.toRouteType(input.routeType),
      })

      if (!data?.addRail.success) {
        return Result.Err(new ItineraryErrors.ItineraryAddRailResponse("Couldn't add rail"))
      }

      return Result.Ok({
        isSuccess: data.addRail.success,
        isSoldOut: data.addRail.data?.isSoldOut,
      })
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryAddRail(e))
    }
  }

  async removeRail(input: ItineraryRemoveRailArgs): ItineraryResults<{ success: boolean }> {
    try {
      const { data } = await this.itineraryDataProvider.removeRail(input)

      if (!data?.removeRail.success) {
        return Result.Err(new ItineraryErrors.ItineraryRemoveRailResponse("Couldn't remove rail"))
      }

      return Result.Ok(data.removeRail)
    } catch (e) {
      return Result.Err(new ItineraryErrors.UnexpectedErrorItineraryRemoveRail(e))
    }
  }
}
