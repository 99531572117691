import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useNavigationContext } from '../../../interface/use-navigation-context'
import { AnnouncementWrapper } from '../desktop-navigation-styled'
import { DesktopNavigationCompanyResoursesModal } from './desktop-navigation-company-resourses-modal'

export const DesktopNavigationCompanyResourses = observer(() => {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.MainNavigation'

  const { navigationStore } = useNavigationContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()

  const { companyResourceToggle } = navigationStore

  const { ref } = useClickOutside(() => {
    if (companyResourceToggle.isOpen) {
      companyResourceToggle.handleClose()
    }
  })

  if (!displayConfigurationStore.isShowCompanyResourses) {
    return null
  }

  return (
    <div ref={ref}>
      <AnnouncementWrapper
        onClick={companyResourceToggle.handleToggle}
        aria-label={t(`${i18Base}.Announcement`)}>
        <Icon name="announcementPWA" color="mainText1" />
      </AnnouncementWrapper>
      <DesktopNavigationCompanyResoursesModal
        isModalOpen={companyResourceToggle.isOpen}
        resoursesLinks={displayConfigurationStore.companyResoursesConfigurationLinks}
        descriptionMessage={
          displayConfigurationStore.companyResoursesConfigurationDescriptionMessage
        }
      />
    </div>
  )
})
