import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

export const ActionBlock = styled(Block)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 48px;
  padding: 8px 0px;
`

export const RightBlock = styled.div`
  margin-left: auto;
`
