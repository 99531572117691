import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useBookTripContext } from '@etta/modules/booking'
import { tripReviewFormatDate, TripReviewDateType } from '@fiji/utils/dates'
import type { HotelFailedBookingValueObject } from '@etta/modules/booking/core/value-objects'
import { HotelFailedBookingError } from '@fiji/graphql/hooks'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { Block } from '@etta/ui/block'
import type { TripConfirmationType } from '@etta/modules/booking/core/value-objects/trip-confirmation-type.value-object'
import type { FailedBookingButton, FailedBookings } from '../../types'
import type { FailedBookingActions, FailedBookingReasons, HotelData } from './types'
import { useFailedBookingButtons } from './use-failed-bookings-buttons'

const i18nBase = 'PostBooking.TripDetails'

type GetHotelProps = (hotel?: HotelFailedBookingValueObject) => FailedBookings | void

const IS_BLOCKED = true // REMOVE THIS VARIABLE WHEN USER WILL BE ABLE TO MODIFY FAILED TRIP

export const HOTEL_CONFIRMATION_ERRORS: HotelFailedBookingError[] = [
  HotelFailedBookingError.HotelRoomReservationNoteUnspecified,
  HotelFailedBookingError.HotelRoomReservationNoteInventoryFailure,
  HotelFailedBookingError.HotelRoomReservationNoteRoomOrRateNotFound,
  HotelFailedBookingError.HotelRoomReservationNoteSoldoutError,
  HotelFailedBookingError.HotelRoomReservationNoteSoapExceptionInPriceCheck,
]
export const CREDIT_CARD_ERRORS: HotelFailedBookingError[] = [
  HotelFailedBookingError.HotelRoomReservationNoteCreditCardFailure,
]
export const HOTEL_DIRECT_CONNECT_ERRORS: HotelFailedBookingError[] = [
  HotelFailedBookingError.HotelRoomReservationNoteDirectConnectError,
  HotelFailedBookingError.HotelRoomReservationNoteDirectConnectErrorcc,
]

type Args = {
  failedBookingActions: FailedBookingActions
  confirmationType: TripConfirmationType
}

export function useFailedBookings({ failedBookingActions, confirmationType }: Args) {
  const { bookTripStore } = useBookTripContext()
  const { failedBookings } = bookTripStore
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isHotelRetryWorkflowEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()
  const {
    getChangeCreditCardButton,
    getChangeHotelButton,
    getRetryButton,
    getStartOverButton,
    getStartNewHotelSearchButton,
  } = useFailedBookingButtons({ failedBookingActions })

  const failedBookingReasons = useMemo<FailedBookingReasons>(() => {
    if (!failedBookings.length || !isHotelRetryWorkflowEnabled) {
      return {
        hotel: {
          confirmationFails: [],
          creditCardFails: [],
          directConnectFails: [],
        },
      }
    }

    return failedBookings.reduce<FailedBookingReasons>(
      (acc, booking) => {
        if (!booking.hotelFailedBooking?.error) {
          return acc
        }

        const error = booking.hotelFailedBooking.error

        if (HOTEL_DIRECT_CONNECT_ERRORS.includes(error)) {
          acc.hotel.directConnectFails.push(booking.hotelFailedBooking)
        }
        if (HOTEL_CONFIRMATION_ERRORS.includes(error)) {
          acc.hotel.confirmationFails.push(booking.hotelFailedBooking)
        }
        if (CREDIT_CARD_ERRORS.includes(error)) {
          acc.hotel.creditCardFails.push(booking.hotelFailedBooking)
        }

        return acc
      },
      {
        hotel: {
          confirmationFails: [],
          creditCardFails: [],
          directConnectFails: [],
        },
      },
    )
  }, [failedBookings, isHotelRetryWorkflowEnabled])

  const getFailedHotelConfirmationButtons = useCallback(
    (hotelData: HotelData): FailedBookingButton[] => {
      const unconfirmedHotelButtons = [
        getStartNewHotelSearchButton(hotelData),
        getStartOverButton(),
      ]
      const partiallyConfirmedHotelButtons = [getChangeHotelButton(hotelData)]

      if (confirmationType === 'unconfirmed') {
        return isHotelRetryWorkflowEnabled ? unconfirmedHotelButtons : [getStartOverButton()]
      }
      const isBlocked = IS_BLOCKED || !isHotelRetryWorkflowEnabled
      return isBlocked ? [] : partiallyConfirmedHotelButtons
    },
    [
      confirmationType,
      getChangeHotelButton,
      getStartNewHotelSearchButton,
      getStartOverButton,
      isHotelRetryWorkflowEnabled,
    ],
  )
  const getFailedCreditCardButtons = useCallback((): FailedBookingButton[] => {
    const creditCardButtons = [getChangeCreditCardButton(), getRetryButton()]
    const isBlocked = IS_BLOCKED || !isHotelRetryWorkflowEnabled

    if (confirmationType === 'unconfirmed') {
      return isBlocked ? [getStartOverButton()] : creditCardButtons
    }

    return isBlocked ? [] : creditCardButtons
  }, [
    confirmationType,
    getChangeCreditCardButton,
    getRetryButton,
    getStartOverButton,
    isHotelRetryWorkflowEnabled,
  ])

  const getHotelProps: GetHotelProps = useCallback(
    (hotel) => {
      if (!hotel) {
        return
      }

      const { hotelData } = hotel
      const { numberOfNights, checkIn, checkOut, name, roomType } = hotelData
      const roomNights = t(`${i18nBase}.HotelNights`, { count: numberOfNights })

      const formattedCheckIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkIn)
      const formattedCheckOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkOut)
      const dates = [formattedCheckIn, formattedCheckOut].join(' - ')
      const segmentDescriptionSlot = (
        <Fragment>
          {dates && <Block>{dates}</Block>}
          <Block>{[roomNights, roomType].filter(Boolean).join(' • ')}</Block>
        </Fragment>
      )

      if (failedBookingReasons.hotel.confirmationFails.length) {
        return {
          errorTitle: t(`${i18nBase}.HotelBookingFailed`),
          errorDescription: t(`${i18nBase}.HotelBookingFailedDescription`),
          buttons: getFailedHotelConfirmationButtons(hotelData),
          segmentTitle: name || '',
          segmentDescription: segmentDescriptionSlot,
        }
      }

      if (failedBookingReasons.hotel.directConnectFails.length) {
        return {
          errorTitle: t(`${i18nBase}.HotelBookingFailed`),
          errorDescription: t(`${i18nBase}.HotelBookingFailedDescription`),
          buttons: getFailedHotelConfirmationButtons(hotelData),
          segmentTitle: name || '',
          segmentDescription: segmentDescriptionSlot,
        }
      }

      if (failedBookingReasons.hotel.creditCardFails.length) {
        return {
          errorTitle: t(`${i18nBase}.CreditCardFailed`),
          errorDescription: t(`${i18nBase}.CreditCardFailedDescription`),
          buttons: getFailedCreditCardButtons(),
          segmentTitle: name || '',
          segmentDescription: segmentDescriptionSlot,
        }
      }
    },
    [
      t,
      failedBookingReasons.hotel.confirmationFails.length,
      failedBookingReasons.hotel.directConnectFails.length,
      failedBookingReasons.hotel.creditCardFails.length,
      getFailedCreditCardButtons,
      getFailedHotelConfirmationButtons,
    ],
  )

  const failedSegments = useMemo<FailedBookings[]>(() => {
    const result: FailedBookings[] = []
    if (isHotelRetryWorkflowEnabled) {
      for (const booking of failedBookings) {
        const hotelProps = getHotelProps(booking.hotelFailedBooking)
        if (hotelProps) {
          result.push(hotelProps)
        }
      }
    }

    return result
  }, [failedBookings, getHotelProps, isHotelRetryWorkflowEnabled])

  return {
    failedSegments,
    failedBookingReasons,
  }
}
