import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useEttaMapContext } from '../interface/use-etta-map.context'
import type { EttaMapProps } from './types'
import { GoogleMap } from './google-map/google-map'
import { AppleMap } from './apple-map/apple-map'
import { EttaMapDialog } from './etta-map.dialog'
import { AppleMapContextProvider } from './apple-map/apple-map.context'
import { EttaMapMarker } from './etta-map-marker'

const Map = observer<EttaMapProps>(function EttaMap(props) {
  const { ettaMapActions, ettaMapStore } = useEttaMapContext()
  const isAppleMap = ettaMapStore.isAppleMap

  useEffect(() => {
    ettaMapActions.loadMap()
  }, [ettaMapActions, isAppleMap])

  if (!ettaMapStore.isLoaded) {
    return null
  }

  if (isAppleMap) {
    return (
      <AppleMapContextProvider>
        <AppleMap {...props} />
      </AppleMapContextProvider>
    )
  }

  return <GoogleMap {...props} />
})

export const EttaMap = Object.assign(Map, {
  Dialog: EttaMapDialog,
  Marker: EttaMapMarker,
})
