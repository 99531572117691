import { Service, Inject } from '@etta/di'
import { BrandConfigurationAdapter } from '../../infra/brand-configuration.adapter'
import { BrandConfigurationStore } from '../store/brand-config.store'

@Service()
export class GetBrandConfigurationService {
  constructor(
    @Inject()
    private brandConfigurationAdapter: BrandConfigurationAdapter,
    private brandConfigurationStore: BrandConfigurationStore,
  ) {}

  async get() {
    this.brandConfigurationStore.setIsLoading(true)
    this.brandConfigurationStore.setIsError(false)

    const result = await this.brandConfigurationAdapter.getBrandConfiguration()

    result.match({
      Ok: (data) => {
        this.brandConfigurationStore.setConfiguration(data)
        this.brandConfigurationStore.setIsLoading(false)
        this.brandConfigurationStore.setIsLoaded(true)
      },
      Err: () => {
        this.brandConfigurationStore.setIsError(true)
        this.brandConfigurationStore.setIsLoading(false)
      },
    })
  }

  async reload() {
    const result = await this.brandConfigurationAdapter.getBrandConfiguration()

    result.match({
      Ok: (data) => {
        this.brandConfigurationStore.setConfiguration(data)
      },
      Err: () => {},
    })
  }
}
