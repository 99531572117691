import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  margin: 0 16px;
  max-height: 145px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`

export const ContainerShort = styled.div`
  width: 100%;
  max-width: 234px;
  padding: 20px;
  border-radius: 14px;
  background: radial-gradient(
    68.75% 74.86% at 0% 50%,
    rgba(236, 237, 238, 0.3) 0%,
    rgba(236, 237, 238, 0) 100%
  );

  &:hover {
    box-shadow: -1px 0px 8px ${(props) => props.theme.colors.borderLight},
      1px 0px 8px ${(props) => props.theme.colors.borderLight};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 16px 0 16px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.mainText};
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
`

export const InfoIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Price = styled(Text)`
  text-align: right;
  margin-right: 20px;
`

export const FareAttributes = styled.ul`
  list-style-type: none;
  padding: 0 16px 12px 16px;
  margin: 0;
`

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const FareRules = styled(Text)`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`

export const RailCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const RailIconContainer = styled.div`
  margin-left: auto;
`
