import { copyToClipboardAsync } from './copy-to-clipboard-async'
import { copyToClipboardFallback } from './copy-to-clipboard-fallback'

export async function copyToClipboard(text: string) {
  let isCopied = false

  try {
    isCopied = await copyToClipboardAsync(text)

    if (!isCopied) {
      isCopied = copyToClipboardFallback(text)
    }

    return isCopied
  } finally {
    if (!isCopied) {
      throw new Error()
    }
  }
}
