import { Text } from '@etta/ui/text'
import type { RateValueObject } from '@etta/core/value-objects'
import { formatPrice } from '@fiji/utils/money'
import { Container, TotalPriceContainer } from './trip-total-cost-styled'

type Props = {
  rate?: RateValueObject | null
}

export function TripCost({ rate }: Props) {
  if (!rate) {
    return null
  }

  return (
    <Container>
      <Text variant="headline" isBlock>
        Total Cost
      </Text>
      <TotalPriceContainer>
        <Text variant="headline" isBlock>
          {formatPrice(rate?.primary, { morpheme: 'postfix' })}
        </Text>
        {rate?.secondary && (
          <Text variant="subHeadMedium" isBlock>
            {formatPrice(rate?.secondary, { morpheme: 'postfix' })}
          </Text>
        )}
      </TotalPriceContainer>
    </Container>
  )
}
