export default function CheckedBagAllowanceIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.25 7C27.4236 7 28.375 7.92742 28.375 9.07143V13.2143H35.8125C37.5729 13.2143 39 14.6054 39 16.3214V32.8929C39 34.6089 37.5729 36 35.8125 36H8.1875C6.42709 36 5 34.6089 5 32.8929V16.3214C5 14.6054 6.42709 13.2143 8.1875 13.2143H15.625V9.07143C15.625 7.92742 16.5764 7 17.75 7H26.25ZM11.375 15.2857H8.1875C7.6659 15.2857 7.23208 15.6521 7.14212 16.1352L7.125 16.3214V32.8929C7.125 33.4013 7.50086 33.8242 7.99651 33.9119L8.1875 33.9286H11.375V15.2857ZM28.375 15.2857H15.625V33.9286H28.375V15.2857ZM35.8125 15.2857H32.625V33.9286H35.8125C36.3341 33.9286 36.7679 33.5622 36.8579 33.079L36.875 32.8929V16.3214C36.875 15.813 36.4991 15.3901 36.0035 15.3024L35.8125 15.2857ZM26.25 9.07143H17.75V13.2143H26.25V9.07143Z"
        fill="currentColor"
      />
    </svg>
  )
}
