import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { ShareTravelerContactContainer } from '@etta/screens/traveler-info/share-traveler-contact/layout/mobile/share-traveler-contact-mobile-styled'
import { ShareTravelerContactForm } from '../../share-traveler-contact-form/share-traveler-contact-form'

const i18nBase = 'TravelerInfo.ShareTravelerContact.'

export function ShareTravelerContactMobile() {
  const { t } = useTranslation()
  return (
    <ShareTravelerContactContainer>
      <Block marginBottom={12}>
        <Text variant="subHeadStrong" color="mainText">
          {t(`${i18nBase}Title`)}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {t(`${i18nBase}Description`)}
        </Text>
      </Block>
      <ShareTravelerContactForm />
    </ShareTravelerContactContainer>
  )
}
