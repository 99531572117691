import styled from 'styled-components'
import { Screen } from '@etta/ui/screen'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'

export const SheetContainer = styled.div`
  width: 100%;
  height: 100%;
  top: -1;
  bottom: 0;
  position: absolute;
  background-color: ${(p) => p.theme.colors.white};
  z-index: 100;
`
export const Content = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`

export const ScreenHeader = styled(Screen.Header)`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
`

export const ItineraryButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 100px;
  z-index: 21;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.primary};
  border: 6px solid ${(p) => hexToRgba(p.theme.colors.primary, 0.1)};
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
  }
`

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
`
