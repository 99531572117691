import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { DatePickerModalMobile } from './mobile'
import { DatePickerModalDesktop } from './desktop'
import useDatePickerModal from './use-date-picker-modal'

export function DatePickerModal({
  date,
  dateEnd,
  previousDate,
  minDate,
  maxDate,
  startDateLegend,
  endDateLegend,
  isEndDateSame,
  isRange,
  isOpen,
  onClose,
  onSelect,
  startTitle,
  endTitle,
  withConfirmOneWayButton,
  withConfirmButton,
  highlightDates,
  numberOfMonths,
  isOutsideRange,
  customElementHeight,
  isReturnDate = false,
  isDepartureDatePickerDisabled,
}: Props) {
  const {
    handleSelect,
    handleClose,
    footerTitle,
    isFooterVisible,
    dates,
    handleDatesSelect,
  } = useDatePickerModal({
    date,
    dateEnd,
    onSelect,
    onClose,
    isOpen,
    withConfirmOneWayButton,
    withConfirmButton,
    isRange,
    isDepartureDatePickerDisabled,
  })

  const layoutProps = {
    onSelect: handleSelect,
    onClose: handleClose,
    onDatesSelect: handleDatesSelect,
    footerTitle,
    isFooterVisible,
    dates,
    previousDate,
    minDate,
    maxDate,
    startDateLegend,
    endDateLegend,
    isEndDateSame,
    isRange,
    isOpen,
    startTitle,
    endTitle,
    highlightDates,
    numberOfMonths,
    isOutsideRange,
    customElementHeight,
    isReturnDate,
    isDepartureDatePickerDisabled,
  }

  return (
    <MediaLayout
      mobileSlot={<DatePickerModalMobile {...layoutProps} />}
      desktopSlot={<DatePickerModalDesktop {...layoutProps} />}
    />
  )
}
