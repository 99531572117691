import { observer } from 'mobx-react-lite'
import { useScreenType, ScreenType } from '@fiji/modes'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Block } from '@etta/ui/block'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { MobilitySearchFlowModal } from '@etta/screens/trip-details/details/mobility-search-flow-modal'
import { MobilityLeavingBookingFlowWarningDialog } from '@etta/components/mobility-leaving-booking-flow-warning-dialog'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { BookTripAgainModal } from '@etta/modules/post-booking/ui/book-trip-again/book-trip-again-modal/book-trip-again-modal'
import { PreSearchCustomFields } from '@etta/components/pre-search-custom-fields/pre-search-custom-fields'
import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { Header, TripDetails, HoldTrip, Actions } from './components'
import {
  Content,
  Wrapper,
  PageContainer,
  MobilityCallToActionWrapper,
} from './trip-confirmation-styled'
import type { Props } from './types'
import { TripUnconfirmed } from './trip-unconfirmed'
import { FailedSegments } from './components/failed-segment/failed-segments'
import { useTripConfirmation } from './use-trip-confirmation'

export const TripConfirmation = observer(function TripConfirmation({
  tripState,
  tripDetails,
  actions,
  segments,
  failedBooks,
  viewState,
  bookedProcessId,
}: Props) {
  const {
    searchRideHailStore,
    searchRideHailActions,
    getCustomerUberAccountStatusStore,
  } = useRideHailSearchContext()
  const { bookTripAgainStore, bookTripAgainService, postBookingTripStore } = usePostBookingContext()
  const {
    flightSegment,
    hotelSegment,
    carRentalSegment,
  } = bookTripAgainService.tripToBookAgainSegments
  const screenType = useScreenType()
  const isTripHold = tripState.confirmationType === 'hold'
  const isMobile = screenType === ScreenType.Mobile

  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  } = usePreSearch({ onSubmit: () => bookTripAgainService.bookTripAgain() })

  useTripConfirmation()

  const viewStateSlot = viewState ? <ViewStateModal viewState={viewState} /> : null
  if (tripState.confirmationType === 'unconfirmed') {
    return (
      <TripUnconfirmed
        tripState={tripState}
        failedBooks={failedBooks}
        viewStateSlot={viewStateSlot}
      />
    )
  }

  const isNotContainCarSegment = !segments.car
  const { isUserAccountStatusReady } = getCustomerUberAccountStatusStore
  const shouldShowMobilityCallToAction = isNotContainCarSegment && isUserAccountStatusReady

  return (
    <>
      <PageContainer>
        <Wrapper>
          <Header tripState={tripState} />
          <Content isMobile={isMobile}>
            {!isMobile && shouldShowMobilityCallToAction && (
              <Block marginBottom={12}>
                <MobilityCallToAction
                  processId={bookedProcessId}
                  onStartMobilitySearchFlow={searchRideHailActions.openMobilitySearchDesktopModal}
                />
              </Block>
            )}
            <HoldTrip isHoldTrip={isTripHold} holdTripDate={tripState.autoCancelTripDate} />
            {
              // failedSegments is for partial confirmation, failedBooks is for hotel retry
              postBookingTripStore.failedSegments.length !== 0 && <FailedSegments />
            }
            <TripDetails
              tripDetails={tripDetails}
              onShareClick={actions.onShareClick}
              segments={segments}
              isNDCTrip={postBookingTripStore.trip.isNDCTrip ?? false}
              mobilityCallToActionSlot={
                isMobile &&
                shouldShowMobilityCallToAction && (
                  <MobilityCallToActionWrapper>
                    <MobilityCallToAction processId={bookedProcessId} />
                  </MobilityCallToActionWrapper>
                )
              }
            />
            <Actions
              isTripHold={isTripHold}
              isBookTripAgainAllowed={bookTripAgainStore.isPostBookingBookAgainAllowed}
              onBookAnotherTripClick={actions.onBookAnotherTripClick}
              onSeeUpcomingTrips={actions.onSeeUpcomingTrips}
              onBookAgainClick={bookTripAgainStore.bookTripAgainToggle.handleOpen}
              onSeeHeldTrips={actions.onSeeHeldTrips}
            />
          </Content>
          {viewStateSlot}
        </Wrapper>
        <MobilitySearchFlowModal
          isOpen={searchRideHailStore.isOpenMobilitySearchDesktopModal}
          onClose={searchRideHailActions.openLeavingBookingFlowDialog}
        />
        <MobilityLeavingBookingFlowWarningDialog
          isOpen={searchRideHailStore.isOpenLeavingBookingFlowDialog}
        />
      </PageContainer>

      <BookTripAgainModal
        isOpen={bookTripAgainStore.bookTripAgainToggle.isOpen}
        onClose={bookTripAgainStore.bookTripAgainToggle.handleClose}
        tripName={postBookingTripStore.trip.name}
        flightSegment={flightSegment}
        hotelSegment={hotelSegment}
        carRentalSegment={carRentalSegment}
      />

      <PreSearchCustomFields
        customFields={customFields}
        values={values}
        errors={errors}
        onFieldChange={onFieldChange}
        onBack={onPreSearchBack}
        isVisible={isOpenPreSearchCustomFields}
        onSubmit={onSubmitCustomFieldsHandler}
      />
    </>
  )
})
