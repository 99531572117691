import { useTranslation } from 'react-i18next'
import type { CancellationPolicy } from '@fiji/graphql/types'
import { RateQualifier } from '@fiji/graphql/types'
import { Badge as RoomBadge } from '../badge'
import { useRoomBadges } from './use-room-badges'
import { Container } from './room-badges-styled'

const i18Base = 'HotelInfo.Rooms'

type Props = {
  preferredTag?: string | null
  rateQualifier?: RateQualifier | null
  cancellationPolicy: CancellationPolicy
  isFilled?: boolean
}

export function RoomBadges({ cancellationPolicy, isFilled, preferredTag, rateQualifier }: Props) {
  const { t } = useTranslation()
  const { cancellationTitle, cancellationTitleColor } = useRoomBadges({
    cancellationPolicy,
  })

  return (
    <Container isRow={isFilled}>
      {!!preferredTag && (
        <RoomBadge
          isRow={isFilled}
          aria-label={t('Badge.CustomBadge', { badge: preferredTag })}
          title={preferredTag}
          icon="appleMostPreferredFilledPwa"
          iconColor="bodyText"
          textColor="bodyText"
        />
      )}
      {rateQualifier === RateQualifier.Prepaid && (
        <RoomBadge
          isRow={isFilled}
          aria-label={t(`${i18Base}.Prepaid`)}
          title={t(`${i18Base}.Prepaid`)}
          icon={isFilled ? 'refundableFilledPWA' : 'refundablePWA'}
          iconColor="bodyText1"
          textColor={isFilled ? 'bodyText1' : 'warning'}
        />
      )}
      {cancellationTitle && (
        <RoomBadge
          isRow={isFilled}
          aria-label={t(`${i18Base}.FreeCancellableAriaLabel`)}
          title={cancellationTitle}
          icon={isFilled ? 'refundableFilledPWA' : 'refundablePWA'}
          iconColor="bodyText1"
          textColor={cancellationTitleColor}
        />
      )}
    </Container>
  )
}
