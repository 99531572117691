import { useTranslation } from 'react-i18next'
import type { LuggageOptionValueObject } from '@etta/modules/booking/core/value-objects'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money/format-rate'
import {
  Footer,
  FooterRow,
  FooterBadge,
  OopDesc,
  OopLine,
  SelectReasonButton,
} from './rtp-itinerary-card-mobile-styled'

type Props = {
  showLuggageOptions?: boolean | null
  isOutOfPolicy: boolean
  isComponentInvalid: boolean
  outOfPolicyExp?: string | null
  selectedBaggageOption?: LuggageOptionValueObject
  showAddBaggageModal?: () => void
  onSelectReason: () => void
  hasComplianceCode: boolean
}

const i18nBase = 'ReviewTrip'

export function RtpItineraryCardMobileFooter({
  showLuggageOptions,
  isComponentInvalid,
  isOutOfPolicy,
  outOfPolicyExp,
  selectedBaggageOption,
  showAddBaggageModal,
  onSelectReason,
  hasComplianceCode,
}: Props) {
  const { t } = useTranslation()

  const { mainCost: selectedBaggageCost } = formatRate(selectedBaggageOption?.cost, {
    morpheme: 'none',
  })

  if (!isOutOfPolicy && !showLuggageOptions) {
    return null
  }

  return (
    <Footer data-tracking-id={'segment-footer-block'}>
      {showLuggageOptions && (
        <FooterRow hasBorder={!isOutOfPolicy} onClick={showAddBaggageModal}>
          <FooterBadge>
            <Icon name="baggageIconPWA" />
            <Text variant="footnoteMedium" textOverflow="ellipsis-two-line">
              {selectedBaggageOption
                ? `${selectedBaggageOption.value} ${
                    selectedBaggageCost ? `(${selectedBaggageCost})` : ''
                  }`
                : t(i18nBase + '.Baggage')}
            </Text>
          </FooterBadge>
          <Link>{selectedBaggageOption ? t(i18nBase + '.Change') : t(i18nBase + '.Add')}</Link>
        </FooterRow>
      )}
      {isOutOfPolicy && (
        <FooterRow
          isInvalid={isComponentInvalid}
          hasBorder={isOutOfPolicy}
          data-tracking-id={'segment-policy-block'}>
          <FooterBadge>
            <Icon name="outOfPolicyPWA" />
            <span>{t(`${i18nBase}.OutOfPolicy`)}</span>
          </FooterBadge>
          {outOfPolicyExp ? (
            <OopDesc>
              <OopLine>{outOfPolicyExp}</OopLine>
              <IconButton
                icon="editPWA"
                onClick={onSelectReason}
                size="small"
                color="primary"
                width={20}
                height={20}
              />
            </OopDesc>
          ) : (
            !hasComplianceCode && (
              <SelectReasonButton onClick={onSelectReason}>
                {t(`${i18nBase}.SelectReason`)}
              </SelectReasonButton>
            )
          )}
        </FooterRow>
      )}
    </Footer>
  )
}
