import { useTranslation } from 'react-i18next'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'

type Args = {
  checkIn: string
  checkOut: string
}

export function useHotelAbout({ checkIn, checkOut }: Args) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  const titleDate = useDateRangeTitle(checkIn, checkOut)

  const totalDays = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )
  const dateRangeTitle = `${titleDate} (${
    totalDays && t(`${i18Base}.Nights`, { count: totalDays })
  })`

  return {
    dateRangeTitle,
  }
}
