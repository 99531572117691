import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

export function useGetUberInfoActionText(isSubmitted: boolean, isCreateAttempted: boolean) {
  const {
    getCustomerUberAccountStatusStore,
    createCustomerUberAccountStore,
    configurationStore,
  } = useRideHailSearchContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const { t } = useTranslation()
  const i18nBase = 'Mobility.UberInformationPage.Actions.'
  const i18nBaseForBrand = 'Mobility.UberNewInformationPage.Brand.'
  const {
    status,
    linkingUrl,
    isUserAccountSelfCreationEnabled,
    isError: isGetCustomerUberAccountStatusError,
  } = getCustomerUberAccountStatusStore
  const { isError: isCreateCustomerUberAccountError } = createCustomerUberAccountStore

  const isErrorCurrent = useMemo(() => {
    return (
      isCreateAttempted && (isGetCustomerUberAccountStatusError || isCreateCustomerUberAccountError)
    )
  }, [isCreateAttempted, isGetCustomerUberAccountStatusError, isCreateCustomerUberAccountError])

  const title = useMemo(() => {
    if (isSubmitted || isErrorCurrent) {
      return ''
    }

    switch (status) {
      case UberAccountStatusEnum.NotFound:
        const ACC_NOT_FOUND = i18nBase + 'AccountNotFound.'
        return t(ACC_NOT_FOUND + 'Title')

      case UberAccountStatusEnum.NotLinked:
        const ACC_NOT_LINKED = i18nBase + 'AccountNotLinked.'
        return t(ACC_NOT_LINKED + 'Title')

      default:
        return ''
    }
  }, [status, isSubmitted, isErrorCurrent, t])

  const description = useMemo(() => {
    if (isErrorCurrent) {
      const ACC_CREATION_FAILED_PATH = i18nBase + 'AccountCreationFailed.'
      const text_path = 'Description'
      return t(ACC_CREATION_FAILED_PATH + text_path)
    }

    const brandLabel = brandConfigurationStore.isAppleTheme
      ? t(i18nBaseForBrand + 'Kan')
      : t(i18nBaseForBrand + 'Deem')

    switch (status) {
      case UberAccountStatusEnum.SiteEmptyMapping:
      case UberAccountStatusEnum.SiteNotEnabled:
        const ACC_SITE_NOT_ENABLE_PATH = i18nBase + 'AccountSiteNotMappingAndEnabled.'
        const text_path = isSubmitted ? 'SubmittedDescription' : 'DefaultDescription'
        return t(ACC_SITE_NOT_ENABLE_PATH + text_path, { brand: brandLabel })

      case UberAccountStatusEnum.NotFound:
        const ACC_NOT_FOUND = i18nBase + 'AccountNotFound.'
        const ACC_NOT_FOUND_PATH = isSubmitted ? 'SubmittedDescription' : 'DefaultDescription'
        return t(ACC_NOT_FOUND + ACC_NOT_FOUND_PATH)

      case UberAccountStatusEnum.NotLinked:
        const ACC_NOT_LINKED = i18nBase + 'AccountNotLinked.'
        const ACC_NOT_LINKED_PATH = isSubmitted ? 'SubmittedDescription' : 'DefaultDescription'
        return t(ACC_NOT_LINKED + ACC_NOT_LINKED_PATH)

      case UberAccountStatusEnum.Ready:
        const ACC_READY = i18nBase + 'AccountReady.'
        return isSubmitted
          ? t(ACC_READY + 'SubmittedDescription')
          : t(ACC_READY + 'DefaultDescription', {
              advancedBookingInDays: configurationStore.advanceBookingInDays,
            })

      case UberAccountStatusEnum.Unspecified:
        const ERROR = i18nBase + 'Error.'
        return t(ERROR + 'DefaultDescription')

      default:
        return ''
    }
  }, [
    status,
    isSubmitted,
    isErrorCurrent,
    configurationStore.advanceBookingInDays,
    t,
    brandConfigurationStore.isAppleTheme,
  ])

  const buttonText = useMemo(() => {
    if (isErrorCurrent) {
      const ACC_CREATION_FAILED_PATH = i18nBase + 'AccountCreationFailed.'
      const text_path = 'Button'
      return t(ACC_CREATION_FAILED_PATH + text_path)
    }

    switch (status) {
      case UberAccountStatusEnum.SiteEmptyMapping:
      case UberAccountStatusEnum.SiteNotEnabled:
        const ACC_SITE_NOT_ENABLE_PATH = i18nBase + 'AccountSiteNotMappingAndEnabled.'
        const text_path = isSubmitted ? 'SubmittedButton' : 'DefaultButton'
        return t(ACC_SITE_NOT_ENABLE_PATH + text_path)

      case UberAccountStatusEnum.NotFound:
        const ACC_NOT_FOUND = i18nBase + 'AccountNotFound.'
        if (isUserAccountSelfCreationEnabled) {
          return t(ACC_NOT_FOUND + 'CreateAccountButton')
        }
        const ACC_NOT_FOUND_PATH = isSubmitted ? 'SubmittedButton' : 'DefaultButton'

        return t(ACC_NOT_FOUND + ACC_NOT_FOUND_PATH)

      case UberAccountStatusEnum.NotLinked:
        const ACC_NOT_LINKED = i18nBase + 'AccountNotLinked.'
        let ACC_NOT_LINKED_PATH = isSubmitted ? 'SubmittedButton' : 'DefaultButton'
        if (linkingUrl) {
          ACC_NOT_LINKED_PATH = 'ActivationButton'
        }
        return t(ACC_NOT_LINKED + ACC_NOT_LINKED_PATH)

      case UberAccountStatusEnum.Ready:
        const ACC_READY = i18nBase + 'AccountReady.'
        const ACC_READY_PATH = isSubmitted ? 'SubmittedButton' : 'DefaultButton'
        return t(ACC_READY + ACC_READY_PATH)

      case UberAccountStatusEnum.Unspecified:
        const ERROR = i18nBase + 'Error.'
        return t(ERROR + 'DefaultButton')

      default:
        return ''
    }
  }, [status, isSubmitted, isErrorCurrent, t, linkingUrl, isUserAccountSelfCreationEnabled])

  return {
    title,
    description,
    buttonText,
  }
}
