import { useTranslation } from 'react-i18next'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'

export function useSubTitle(seat: SeatMapRowSeatValueObject) {
  const { t } = useTranslation()
  const { isPaid, isPremium } = seat

  switch (true) {
    case isPaid:
      return t('AirSeatMap.Details.Paid')
    case isPremium:
      return t('AirSeatMap.Details.Premium')
    default:
      return ''
  }
}
