import type { Props } from '../types'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  RecentHotelSearchList,
  RecentCarRentalSearchList,
  RecentRailSearchList,
  RecentFlightSearchList,
  RecentFlightSearchListWithoutDates,
  RecentHotelSearchListWithoutDates,
  RecentCarRentalSearchListWithoutDates,
} from './recent-search-items'

export function RecentSearchesListMobile({ type, onModalClose }: Props) {
  const { recentSearchesStore } = useRecentSearchesContext()

  if (recentSearchesStore.isRecentSearchDisabled) {
    return null
  }

  if (type === 'carRental') {
    return (
      <>
        <RecentCarRentalSearchList onModalClose={onModalClose} />
        <RecentCarRentalSearchListWithoutDates onModalClose={onModalClose} />
      </>
    )
  }
  if (type === 'flight') {
    return (
      <>
        <RecentFlightSearchList onModalClose={onModalClose} />
        <RecentFlightSearchListWithoutDates onModalClose={onModalClose} />
      </>
    )
  }
  if (type === 'hotel') {
    return (
      <>
        <RecentHotelSearchList onModalClose={onModalClose} />
        <RecentHotelSearchListWithoutDates onModalClose={onModalClose} />
      </>
    )
  }
  if (type === 'rail') {
    return <RecentRailSearchList onModalClose={onModalClose} />
  }
  return null
}
