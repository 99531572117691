import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'

import { HotelSearchFormModalDesktop } from './desktop'

export function HotelSearchFormModalLayout() {
  return (
    <MediaLayout
      mobileSlot={null}
      desktopSlot={<HotelSearchFormModalDesktop />}
      forceScreenType={ScreenType.Desktop}
    />
  )
}
