/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import type { CenterMarkerSearchParams, SearchParams } from '@fiji/hooks/use-search-params/types'
import { fromLocationQuery } from '@fiji/utils/search-param-conversions'
import { toLocationQuery } from '@fiji/utils/search-param-conversions'

export function useSearchParams() {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const searchParams = useMemo(() => fromLocationQuery(search), [search, pathname])

  const setSearchParams = (params: SearchParams | CenterMarkerSearchParams) => {
    const searchString = toLocationQuery({
      ...fromLocationQuery(search),
      ...params,
    })
    const newPath = `${pathname}?${searchString}`

    history.replace(newPath)
  }

  return {
    searchParams,
    setSearchParams,
  }
}
