import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { DeviceClass } from '@fiji/graphql/types'
import { mapToSupportArticleValueObject } from '@etta/modules/support-travel/infra/mappers/support-article.mapper'
import type { SupportArticle } from '../core/support-article.value-object'
import { SupportTravelDataProvider } from './support-travel.data-provider/support-travel.data-provider'
import type { SupportArticleResult } from './types'

type GetSupportArticlesInput = {
  offset: number
  deviceClass: 'Desktop' | 'Mobile'
}

@Adapter()
export class SupportTravelAdapter {
  constructor(
    @Inject()
    private travelInfoDataProvider: SupportTravelDataProvider,
  ) {}

  async getSupportArticles({
    offset,
    deviceClass,
  }: GetSupportArticlesInput): SupportArticleResult<SupportArticle[]> {
    try {
      const deviceClassInput = deviceClass === 'Desktop' ? DeviceClass.Desktop : DeviceClass.Mobile

      const { data, error } = await this.travelInfoDataProvider.getSupportArticles({
        offset,
        deviceClass: deviceClassInput,
      })

      if (error) {
        return Result.Err(new Error('error: ' + error.message))
      }

      return Result.Ok(mapToSupportArticleValueObject(data.supportArticles))
    } catch (e) {
      return Result.Err(new Error('unexpected error'))
    }
  }
}
