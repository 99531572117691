import styled, { css } from 'styled-components'
import { screenSize } from '@fiji/style'
import { slideUp } from './dialog-animation/dialog-transition'
import type { HorizontalDimensionStyle } from './types'

export const DialogContent = styled.div<{
  customSize?: [number, string]
  horizontalDimension?: HorizontalDimensionStyle
}>`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.4rem;
  margin: 50px auto;
  min-height: 0;
  flex-shrink: 0;

  &.iframe {
    width: calc(100% - 30px);
    max-width: 1400px;
    height: 80vh;
  }

  @media (${screenSize.maxMobile}) {
    max-width: none;
    width: 100%;
  }

  ${(props) =>
    props.customSize &&
    css`
      @media (${props.customSize[1]}) {
        max-width: none;
        width: ${props.customSize[0]}px;
      }
    `}

  ${(props) => {
    switch (props.horizontalDimension) {
      case 'narrow-desktop':
        return 'max-width: 310px;'
      default:
        return ''
    }
  }}
`

export const DialogContainerRoot = styled.div<{
  isScrollable: boolean
}>`
  z-index: 21;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 101;

  ${(props) => {
    if (props.isScrollable) {
      return
    }

    return css`
      align-items: center;
      justify-content: center;
    `
  }};
  @media (${screenSize.maxMobile}) {
    padding: 0 14px;
  }

  ${slideUp};
`

export const Body = styled.div`
  position: relative;
  flex: 1;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid #dee2e6;

  a,
  button {
    margin-right: 10px;
  }

  *:last-child {
    margin-right: 0;
  }
`

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

export const Container = styled.div<{
  isShown: boolean
}>`
  display: ${(props) => (props.isShown ? 'flex' : 'none')};
`
