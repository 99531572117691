import { useEffect } from 'react'
import { useTravelPolicyContext } from '@etta/modules/travel-policy'

export function useTravelPolicy() {
  const { travelPolicyAction } = useTravelPolicyContext()

  useEffect(() => {
    travelPolicyAction.loadTravelPolicy()
  }, [travelPolicyAction])
}
