import { useTranslation, Trans } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { TravelTime } from '@fiji/graphql/types'
import { getNonRailTransferIconNames } from '@fiji/utils/get-non-rail-transfer-icon-names'
import type { TravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import { Container, DurationWrapper, StationWrapper } from './rail-change-transport-styled'

const i18nBase = 'RailDetails'

type Props = {
  arrival: TravelRailLocation
  changeDuration: TravelTime | null | undefined
  departure: TravelRailLocation
  transportationMode: RailTransportationModeEnum
  travelTime: TravelTime | null | undefined
  isRtp: boolean
  isFirstSegment: boolean
  isLastSegment: boolean
}

export function RailChangeTransport({
  arrival,
  changeDuration,
  departure,
  transportationMode,
  travelTime,
  isRtp,
  isFirstSegment,
  isLastSegment,
}: Props) {
  const { t } = useTranslation()
  const getDuration = useRailDuration()

  return (
    <Container
      data-tracking-id="changeInfo"
      isFlexBlock
      isRtp={isRtp}
      isFirstSegment={isFirstSegment}
      isLastSegment={isLastSegment}
      aria-label={t(`${i18nBase}.AriaLabels.ChangeDurationLabel`, {
        time: getDuration(changeDuration),
        name: arrival.stationName,
      })}>
      <Icon name={getNonRailTransferIconNames(transportationMode)} size="small" color="bodyText" />
      <StationWrapper marginLeft={18} marginRight={40}>
        <Text variant="footnoteMedium" color="bodyText">
          {() => {
            const transportTypeText =
              transportationMode.toString().slice(0, 1) + transportationMode.slice(1).toLowerCase()
            if (transportationMode === RailTransportationModeEnum.Unspecified) {
              return t(i18nBase + '.TakeTheTransport', {
                arrival: arrival.stationName,
                departure: departure.stationName,
                type: 'Tube',
              })
            }

            if (
              transportationMode === RailTransportationModeEnum.Bus ||
              transportationMode === RailTransportationModeEnum.Ferry
            ) {
              return (
                <Trans
                  i18nKey={i18nBase + '.TakeTheTransportAtTime'}
                  values={{
                    arrival: arrival.stationName,
                    departure: departure.stationName,
                    type: transportTypeText,
                    time: departure.time,
                  }}
                  components={{ bold: <strong /> }}
                />
              )
            }

            return t(i18nBase + '.TakeTheTransport', {
              arrival: arrival.stationName,
              departure: departure.stationName,
              type: transportTypeText,
            })
          }}
        </Text>
      </StationWrapper>
      {changeDuration && (
        <DurationWrapper>
          <Text variant="footnoteMedium" color="bodyText">
            {getDuration(travelTime) || '-'}
          </Text>
        </DurationWrapper>
      )}
    </Container>
  )
}
