import type { Props } from '../../types'
import { getFirstLineWithText } from '../../get-first-line-with-text'
import { Container, GlobalSafaryStyle } from './message-desktop-styled'

export function MessageDesktop({ message, messageRef, hasDesktopBannerImage }: Props) {
  return (
    <>
      <GlobalSafaryStyle />
      <Container
        ref={messageRef}
        hasDesktopBannerImage={hasDesktopBannerImage}
        dangerouslySetInnerHTML={{ __html: getFirstLineWithText(message) }}
      />
    </>
  )
}
