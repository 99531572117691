import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  position: fixed;
  top: -100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
  opacity: 0;
  z-index: 2;
  transform: translateY(-100%);
  transition: opacity 1s;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 263px;
  height: 52px;
  margin: 5px 0 8px 0;
  padding: 8px 12px 4px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
`

export const Body = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  overflow: hidden;

  ${(props) =>
    !props.isExpanded &&
    css`
      height: 50vh;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      padding-bottom: 20px;
    `}
`

export const DragableAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const MiniPill = styled.div`
  background: ${(p) => p.theme.colors.borderDark};
  border-radius: 50rem;
  height: 4px;
  width: 42px;
  margin-top: 11px;
  margin-bottom: 15px;
  flex-shrink: 0;
`
