import i18next from 'i18next'
import { CarRentalFuelType } from '@fiji/graphql/types'
import { CarTransmission } from '@fiji/enums'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import type { CarRentalAmenities } from '../types'

const i18nBase = 'CarRentalDetails.Amenities'

type Args = {
  carDetails?: CarRentalCarDetailsValueObject | null
}

export function getAmenities({ carDetails }: Args): CarRentalAmenities[] {
  if (!carDetails) {
    return []
  }

  const amenities: CarRentalAmenities[] = [
    {
      name: 'carHybridPWA' as const,
      value: i18next.t(i18nBase + 'HybridFuel'),
      isVisible: carDetails.details.fuel === CarRentalFuelType.Hybrid,
    },
    {
      name: 'peoplePWA' as const,
      value: `${carDetails.details.passenger} ${i18next.t(i18nBase + '.Seats')}`,
      isVisible: true,
    },
    {
      name: 'bagPWA' as const,
      value: `${carDetails.details.luggage} ${i18next.t(i18nBase + '.Bags')}`,
      isVisible: true,
    },
    {
      name: 'transmissionPWA' as const,
      value: i18next.t(`${i18nBase}.${getTransmissioni18nId(carDetails.details.transmission)}`),
      isVisible: true,
    },
    {
      name: 'unlimitedMileage' as const,
      value: i18next.t(`${i18nBase}.UnlimitedMileage`),
      isVisible: carDetails.carCosts.isMileageUnlimited,
    },
    {
      name: 'snowflakePWA' as const,
      value: i18next.t(`${i18nBase}.AirConditioner`),
      isVisible: carDetails.details.isAirConditioned,
    },
    {
      name: 'electricLinePWA' as const,
      value: i18next.t(`${i18nBase}.ElectricFuel`),
      isVisible: carDetails.details.fuel === CarRentalFuelType.Electric,
    },
  ]
    .filter((amenity) => amenity.isVisible)
    .map(({ name, value }) => ({ name, value }))

  return amenities
}

const getTransmissioni18nId = (transmission: string) => {
  switch (transmission) {
    case CarTransmission.Automatic:
      return 'Automatic'
    case CarTransmission.Manual:
      return 'Manual'
    case CarTransmission.Any:
    default:
      return 'Any'
  }
}
