import { createContext } from 'react'
import type { ScrollByContentContextType } from './types'

const ScrollByContentContext = createContext({
  setAnchor: () => {},
  dropAnchor: () => {},
  openAnchor: () => {},
} as ScrollByContentContextType)

export { ScrollByContentContext }
