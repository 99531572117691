import type { LayoutProps } from '../types'
import { AnimatedContainer, HotelListWrapper, MapWrapper } from './hotel-list-mobile-styled'
import { MapShowButton } from './map-show-button'

export function HotelListMobile({
  isMapVisible,
  mapSlot,
  hotelListSlot,
  onMapOpen,
  isMapToggleAvailable,
}: LayoutProps) {
  return (
    <>
      <HotelListWrapper>
        <MapWrapper isMapVisible={isMapVisible}>{mapSlot}</MapWrapper>
        <AnimatedContainer isHidden={isMapVisible}>{hotelListSlot}</AnimatedContainer>
      </HotelListWrapper>
      <MapShowButton isVisible={!isMapVisible && isMapToggleAvailable} onMapOpen={onMapOpen} />
    </>
  )
}
