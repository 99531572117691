import styled from 'styled-components'

import { subHeadMedium } from '@fiji/style/typography'

export const Container = styled.button`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  align-items: center;
  cursor: pointer;
  border: none;
  text-align: left;

  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const Label = styled.span`
  ${subHeadMedium}
`
