import type { SpecialRequestPersistValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/special-request-persist.value-object'
import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'

export class SpecialRequestMapper {
  static toSpecialRequestInput(
    specialRequests?: SpecialRequestPersistValueObject,
  ): CheckoutItineraryInputValueObject['specialRequests'] {
    return {
      air: specialRequests?.air?.map((item) => ({ code: item.value })),
      carRental: specialRequests?.carRental?.map((item) => ({ code: item.value })),
      hotel: specialRequests?.hotel?.map((item) => ({ code: item.value })),
    }
  }
}
