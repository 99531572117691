import styled, { css, createGlobalStyle } from 'styled-components'
import { footnoteMedium } from '@fiji/style'

const bannerImageStyles = css`
  img {
    height: 100% !important;
    max-width: 100% !important;
    width: auto !important;
    max-height: 160px !important;
    border-radius: 4px;
  }
`

export const Container = styled.div<{ hasDesktopBannerImage?: boolean }>`
  width: 100%;
  word-wrap: break-word;
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ hasDesktopBannerImage }) => hasDesktopBannerImage && bannerImageStyles}

  p {
    margin: unset;
  }

  // We overwrite the text to make sure it is the same color, font-family, and weight in every theme
  * {
    color: ${(p) => p.theme.colors.bodyText} !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
  }
  a {
    color: ${(p) => p.theme.colors.primary} !important;
  }
`

export const GlobalSafaryStyle = createGlobalStyle`
  /* Safari 7.1+ */
  _::-webkit-full-page-media, _:future, :root ${Container} li {
    display: inline;
    position: relative;
    margin-left: 16px;
  }

  _::-webkit-full-page-media, _:future, :root ${Container} li:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.bodyText};;
    position: absolute;
    left: -14px;
    top: 6px;
  }
`
