import { createContext } from 'react'
import type { TabContextType } from './types'

const TabsContext = createContext<TabContextType<any>>({
  changeActiveTab: () => {},
  activeTab: '',
  addTab: () => {},
  removeTab: () => {},
  totalTabsCount: 0,
  currentTabIndex: 0,
  tabBasis: 0,
  changeTabBasis: () => {},
})

export { TabsContext }
