import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import type { SegmentState } from '@fiji/graphql/types'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { getSegmentStateDeterminer } from './get-segment-state-determiner'

export function useSegments(segments: TripSegmentValueObject[]) {
  const { segmentTypeCheckActions } = useReviewTripContext()

  const flightSegments = segments.filter(segmentTypeCheckActions.isFlightSegment)

  const hotelSegments = segments.filter(segmentTypeCheckActions.isHotelSegment)

  const carRentalSegments = segments.filter(segmentTypeCheckActions.isCarRentalSegment)

  const railSegments = segments.filter(segmentTypeCheckActions.isTrainSegment)

  const hotelSegmentState = getSegmentStateDeterminer(
    hotelSegments
      .map((segment) => segment.segmentState)
      .filter((state): state is SegmentState => !!state),
  )

  const carRentalSegmentState = getSegmentStateDeterminer(
    carRentalSegments
      .map((segment) => segment.segmentState)
      .filter((state): state is SegmentState => !!state),
  )

  const flightSegmentState = getSegmentStateDeterminer(
    flightSegments
      .map((segment) => segment.segmentState)
      .filter((state): state is SegmentState => !!state),
  )

  const railSegmentState = getSegmentStateDeterminer(
    railSegments
      .map((segment) => segment.segmentState)
      .filter((state): state is SegmentState => !!state),
  )

  const isTrainOnlySegments =
    !hotelSegments?.length &&
    !carRentalSegments?.length &&
    !flightSegments?.length &&
    !!railSegments?.length

  return {
    flightSegments,
    hotelSegments,
    carRentalSegments,
    railSegments,
    hotelSegmentState,
    carRentalSegmentState,
    flightSegmentState,
    railSegmentState,
    isTrainOnlySegments,
  }
}
