import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.mainText};
  margin: 0 6px;
`

export const IconWrapper = styled(Icon)`
  margin-right: 8px;
`
