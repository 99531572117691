import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { HotelSearchForm } from '@etta/components/hotel-search-form'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { CloseIconWrapper, ModalContent, TitleWrapper } from './styled'

const DATA_TRACKING_ID = 'RTP_HOTEL_SEARCH_FORM_MODAL'

export const HotelSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const { addSegmentModalsStore, addSegmentModalsActions } = useReviewTripContext()
  const onClose = () => {
    addSegmentModalsActions.closeHotelSearchModal()
  }

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={onClose}
      isVisible={addSegmentModalsStore.isHotelSearchModalOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton onClick={onClose} icon={'closePWA'} size={'medium'} />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('TripReview.AddSegments.Hotel')}</Text>
      </TitleWrapper>
      <ModalContent>
        <HotelSearchForm />
      </ModalContent>
    </Modal>
  )
})
