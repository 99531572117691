import { Store } from '@etta/di'
import type { RouteItem } from '@fiji/routes'

@Store()
export class RoutesStore {
  private routeList: RouteItem[] = []

  addRoutes(newRoutes: RouteItem[]) {
    this.routeList = [...this.routeList, ...newRoutes]
  }

  get routes() {
    return this.routeList
  }
}
