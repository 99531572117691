import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Button } from '@etta/ui/button'
import { TripReviewSeatPreferencesRegion } from '../../region'
import type { LayoutProps } from '../../types'
import { useTripPreviewSeatPreferences } from '../../use-trip-review-seat-preferences'
import { ButtonContainer } from './trip-review-seat-preferences-mobile-styled'

const i18Base = 'TripReview.SeatPreferencesModal'

export function TripReviewSeatPreferencesMobile({
  layout,
  appliedPreferences,
  preferenceOptions,
  seatReservationTypesArray,
  onPreferencesApply,
  isRoundTrip,
  isFirstClass,
  onSkip,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    onApply,
    onPreferencesChange,
    changedPreferences,
    isSubmitEnabled,
    isSubmitOptional,
  } = useTripPreviewSeatPreferences({
    layout,
    appliedPreferences,
    onPreferencesApply,
    preferenceOptions,
    seatReservationTypesArray,
  })

  return (
    <>
      <Modal.Title withBorder>{t(i18Base + '.Title')}</Modal.Title>
      <Modal.Body>
        <TripReviewSeatPreferencesRegion
          layout={layout}
          onPreferencesChange={onPreferencesChange}
          preferenceOptions={preferenceOptions}
          seatReservationTypesArray={seatReservationTypesArray}
          changedPreferences={changedPreferences}
          isRoundTrip={isRoundTrip}
          isFirstClass={isFirstClass}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          {isSubmitOptional && (
            <Button onClick={onSkip} minWidth={8} btnType="tertiary">
              {t(i18Base + '.Skip')}
            </Button>
          )}
          <Button onClick={onApply} disabled={!isSubmitEnabled} fullWidth>
            {t(i18Base + '.ConfirmPreferences')}
          </Button>
        </ButtonContainer>
      </Modal.Footer>
    </>
  )
}
