import type { ReactNode } from 'react'
import { GridContainer, GridRowItem, Grids } from './rail-route-grid-styled'

type LayoutProps = {
  originStationSlot: ReactNode
  destinationStationSlot: ReactNode
  viewHideStationsSlot: ReactNode
  isRtp: boolean
}

const GRID_TEMPLATE = `
"${Grids.OriginStation}"
"${Grids.ViewHideStations}"
"${Grids.DestinationStation}"
`

export function RailRouteGrid({
  originStationSlot,
  destinationStationSlot,
  viewHideStationsSlot,
  isRtp,
}: LayoutProps) {
  return (
    <GridContainer gridTemplateAreas={GRID_TEMPLATE}>
      <GridRowItem isRtp={isRtp} isFirstItem gridArea={Grids.OriginStation}>
        {originStationSlot}
      </GridRowItem>

      <GridRowItem isRtp={isRtp} gridArea={Grids.ViewHideStations}>
        {viewHideStationsSlot}
      </GridRowItem>

      <GridRowItem isRtp={isRtp} isLastItem gridArea={Grids.DestinationStation}>
        {destinationStationSlot}
      </GridRowItem>
    </GridContainer>
  )
}
