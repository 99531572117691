import i18n from 'i18next'

type Args = {
  delegateName: string
  guestFullName?: string
  isDelegateGuestUser: boolean
}

const i18Base = 'Delegate.DelegateNotch'

export function getDelegateGuestTitle({ delegateName, guestFullName, isDelegateGuestUser }: Args) {
  if (isDelegateGuestUser && guestFullName) {
    return i18n.t(`${i18Base}.GuestAssisting`, {
      guestName: guestFullName,
      delegateName,
    })
  }

  return undefined
}
