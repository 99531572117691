import { DataProvider } from '@etta/di'

import type { GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables } from '@fiji/graphql/hooks'
import { GetFeatureFlagsDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class FeatureFlagsDataProvider extends GraphqlDataProvider {
  getFlags(input: GetFeatureFlagsQueryVariables['input']) {
    return this.client.query<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>({
      query: GetFeatureFlagsDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
}
