import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import type { AdaptiveFlight } from '../types'
import { getHeaderTitle } from './get-header-title'
import { getAirlinesLogos } from './get-airlines-logos'
import { getFlightLabels } from './get-flight-labels'
import { getFlightStops } from './get-flight-stops'
import { getTotalFlightDuration } from './get-total-flight-duration'
import { getPercentageOnTimeLabels } from './get-percentage-on-time-labels'
import { getGeneralAmenities } from './get-general-amenities'
import { getAdaptedSegments } from './get-adapted-segments'
import { getAirlinesNames } from './get-airlines-names'

export function useResultAdaptiveFlight(
  flightLeg: FlightSegmentEntity,
): {
  flight: AdaptiveFlight
  airlinesNames: string[]
} {
  const { t } = useTranslation()

  const flight = useMemo(() => {
    const { segments, legId } = flightLeg
    const squareAirlineLogos = getAirlinesLogos(segments, 'square')
    const isSeatMapAvailable = segments.some((segment) => segment.isSeatAvailable)
    const operatedBy = segments
      .map((value) => value.operatedBy)
      .filter(Boolean)
      .join(', ')

    return {
      id: legId,
      segmentId: segments[0].segmentId || '',
      headerTitle: getHeaderTitle(segments),
      airlineLogos: {
        square: squareAirlineLogos,
        rectangle: getAirlinesLogos(segments, 'rectangle'),
      },
      flightLabels: getFlightLabels(segments),
      adaptedSegments: getAdaptedSegments(segments),
      flightStops: getFlightStops(segments),
      totalFlightDuration: getTotalFlightDuration(flightLeg?.totalTime),
      flightStopsLabel: flightLeg.stops
        ? t('SearchSegments.Stops.Stop', { count: flightLeg.stops })
        : t('SearchSegments.Stops.Nonstop'),
      percentageOnTimeLabels: getPercentageOnTimeLabels(segments),
      fares: [],
      generalAmenities: getGeneralAmenities(segments),
      isSeatMapAvailable,
      operatedBy,
    } as AdaptiveFlight
  }, [flightLeg, t])

  const airlinesNames = useMemo(() => {
    return getAirlinesNames(flightLeg.segments)
  }, [flightLeg])

  return {
    flight,
    airlinesNames,
  }
}
