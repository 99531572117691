import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { AlignTripTravelVerticalValueObject } from '@etta/modules/review-trip/core/value-objects/align-trip-travel-vertical.value-object'
import { Icon } from '@etta/ui/icon'
import { useScreenType, ScreenType } from '@fiji/modes'
import { getWarningTranslation } from '../warning-translation'
import {
  ConfirmButton,
  Container,
  MobileContainer,
  MobileTop,
  MobileConfirmButton,
} from './align-dates-warning.styled'

type Props = {
  mismatchedSegmentTypes: AlignTripTravelVerticalValueObject[]
  flightStartDateTime: string
  lastFlightStartDateTime: string
  onAlignDates: () => void
}
const i18nBase = 'AlignDatesWarning.'

export function AlignDatesWarning({
  mismatchedSegmentTypes,
  flightStartDateTime,
  lastFlightStartDateTime,
  onAlignDates,
}: Props) {
  const { t } = useTranslation()
  const isMobile = useScreenType() === ScreenType.Mobile
  if (!mismatchedSegmentTypes.length) {
    return null
  }

  const description = getWarningTranslation({
    mismatchedTypes: mismatchedSegmentTypes,
    flightStartDateTime,
    lastFlightStartDateTime,
  })

  if (isMobile) {
    return (
      <MobileContainer>
        <MobileTop>
          <Icon color="error" name="warningFilledPWA" />
          <Text variant="subHeadMedium" color="mainText">
            {description}
          </Text>
        </MobileTop>
        <MobileConfirmButton onClick={onAlignDates} fullWidth variant="solid">
          {t(i18nBase + 'ConfirmButton')}
        </MobileConfirmButton>
      </MobileContainer>
    )
  }

  return (
    <Container>
      <Text variant="footnoteMedium" color="mainText1">
        {description}
      </Text>
      <ConfirmButton
        size="small"
        fullWidth
        onClick={onAlignDates}
        variant="link"
        rightIcon={<Icon name="chevronRightPWA" />}>
        {t(i18nBase + 'ConfirmButton')}
      </ConfirmButton>
    </Container>
  )
}
