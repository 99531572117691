import { Inject, Service } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { UnusedTicketOptAdapter } from '../../infra/unused-ticket-opt.adapter'

@Service()
export class UnusedTicketOptService {
  constructor(
    @Inject()
    private readonly unusedTicketOptAdapter: UnusedTicketOptAdapter,

    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
  ) {}

  async applyUnusedTicket(): Promise<{ success: boolean }> {
    const { itineraryId } = this.reviewTripStore

    if (!itineraryId) {
      return { success: false }
    }

    const result = await this.unusedTicketOptAdapter.applyUnusedTicket({
      itineraryId,
    })

    return result.match({
      Ok: (data) => ({ success: data.success }),
      Err: () => ({ success: false }),
    })
  }

  async removeUnusedTicket(): Promise<{ success: boolean }> {
    const { itineraryId } = this.reviewTripStore

    if (!itineraryId) {
      return { success: false }
    }

    const result = await this.unusedTicketOptAdapter.removeUnusedTicket({
      itineraryId,
    })

    return result.match({
      Ok: (data) => ({ success: data.success }),
      Err: () => ({ success: false }),
    })
  }
}
