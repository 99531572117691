import { Store } from '@etta/di'
import type { SupportArticle } from '../../core/support-article.value-object'

@Store()
export class SupportTravelStore {
  private _isLoading: boolean = false
  private _isError: boolean = false
  private _supportArticles: SupportArticle[] = []
  private _isPreview: boolean = false
  get isLoading(): boolean {
    return this._isLoading
  }

  get isError(): boolean {
    return this._isError
  }

  get supportArticles(): SupportArticle[] {
    return this._supportArticles
  }

  get isPreview(): boolean {
    return this._isPreview
  }

  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this._isError = isError
  }

  setSupportArticles(articles: SupportArticle[]) {
    this._supportArticles = articles
  }

  setIsPreview(isPreview: boolean) {
    this._isPreview = isPreview
  }

  dropStore() {
    this._isLoading = false
    this._isError = false
    this._isPreview = false
    this._supportArticles = []
  }
}
