import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Block } from '@etta/ui/block'
import { Search } from '@etta/ui/search'
import { DatePickerModal as DatePickerModalNew } from '@etta/ui/date-picker-modal-temp'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'

function formatDate(date?: Date | null) {
  if (!date) {
    return ''
  }
  return dateFormat(date, 'LLL d')
}

const i18Base = 'TripReview.BookAgain.Input.'

type Props = {
  onDateChange: (result: Dates) => void
  startDate?: Date | null
  endDate?: Date | null
  startDateErrorMessage?: string
  endDateErrorMessage?: string
}

export const BookTripAgainModalDataPicker = observer(function BookTripAgainModalDataPicker({
  onDateChange,
  startDate,
  endDate,
  startDateErrorMessage,
  endDateErrorMessage,
}: Props) {
  const { t } = useTranslation()
  const { bookTripAgainStore } = usePostBookingContext()

  const startDatePickerModalToggle = useTogglePopup()
  const endDatePickerModalToggle = useTogglePopup()

  return (
    <Block position="relative">
      <Search.Group gap={16}>
        <Search.Element
          onClick={startDatePickerModalToggle.handleOpen}
          labelTitle={t(`${i18Base}Start`)}
          value={formatDate(startDate)}
          placeholder={t(`${i18Base}Select`)}
          errorMessage={startDateErrorMessage}
          isTransparentBackground={true}
        />
        {bookTripAgainStore.hasEndDate && (
          <Search.Element
            onClick={endDatePickerModalToggle.handleOpen}
            labelTitle={t(`${i18Base}End`)}
            value={formatDate(endDate)}
            placeholder={t(`${i18Base}Select`)}
            errorMessage={endDateErrorMessage}
            isTransparentBackground={true}
          />
        )}
      </Search.Group>
      <DatePickerModalNew
        date={startDate}
        dateEnd={endDate}
        isRange={bookTripAgainStore.hasEndDate}
        minDate={new Date()}
        highlightDates={[]}
        startTitle={t(`${i18Base}StartDate`)}
        endTitle={t(`${i18Base}EndDate`)}
        onSelect={onDateChange}
        isOpen={startDatePickerModalToggle.isOpen}
        onClose={startDatePickerModalToggle.handleClose}
      />
      <DatePickerModalNew
        date={startDate}
        dateEnd={endDate}
        isRange
        isReturnDate
        minDate={new Date()}
        highlightDates={[]}
        startTitle={t(`${i18Base}Start`)}
        endTitle={t(`${i18Base}End`)}
        onSelect={onDateChange}
        isOpen={endDatePickerModalToggle.isOpen}
        onClose={endDatePickerModalToggle.handleClose}
      />
    </Block>
  )
})
