import { Block } from '@etta/ui/block'
import { Carousel } from '@etta/ui/carousel'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../../types'
import { EcoCheckEquivalenceMobileCard } from './eco-check-equivalences-mobile-card'
import { EcoCheckEquivalenceMobileCardNew } from './eco-check-equivalences-mobile-card-new'

export function EcoCheckEquivalenceMobile({ equivalences }: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()
  if (isNewEmissionsBrandingEnabled) {
    return (
      <Block marginBottom={10}>
        <Carousel
          isCenterMode
          centerPadding={15}
          currentSlide={0}
          onSlideChange={() => {}}
          infinite={false}>
          {equivalences.map((equivalence) => (
            <EcoCheckEquivalenceMobileCardNew key={equivalence.type} {...equivalence} />
          ))}
        </Carousel>
      </Block>
    )
  }
  return (
    <Block marginBottom={10}>
      <Carousel
        isCenterMode
        centerPadding={15}
        currentSlide={0}
        onSlideChange={() => {}}
        infinite={false}>
        {equivalences.map((equivalence, index) => (
          <EcoCheckEquivalenceMobileCard key={index} {...equivalence} />
        ))}
      </Carousel>
    </Block>
  )
}
