import { useAppSelector } from '@fiji/store'
import { brandConfigurationSelector } from '@fiji/store/brand-configuration'
import { useUserContext } from '@etta/modules/user'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useTravelPolicyContext } from '@etta/modules/travel-policy'
import { useDisplayConfiguration } from './use-display-configuration'
import { useZooKeeperConfiguration } from './use-zookeeper-configuration'
import { useBrandConfiguration } from './use-brand-configuration'
import { useTravelPolicy } from './use-travel-policy'
import { useUser } from './use-user'
import { useTravelPreferences } from './use-travel-preferences'
import { useAlerts } from './use-alerts'
import { useCustomerUberAccountStatus } from './use-customer-uber-account-status'
import { useFaviconConfiguration } from './use-favicon-configuration'
import { useProfileFieldsSettings } from './use-profile-fields-settings'
import { useUserProfile } from './use-user-profile'
import { useMeetingMode } from './use-meeting-mode'

export function useContextLoader() {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { travelPolicyStore } = useTravelPolicyContext()
  const { userStore } = useUserContext()

  useDisplayConfiguration()
  useTravelPolicy()
  useBrandConfiguration()
  useUser()
  useMeetingMode()
  useUserProfile()
  useProfileFieldsSettings()
  useTravelPreferences()
  useAlerts()
  useCustomerUberAccountStatus()
  useFaviconConfiguration()
  useZooKeeperConfiguration()

  const { isLoading: isBrandConfigurationLoading } = useAppSelector(brandConfigurationSelector)

  const isLoading =
    displayConfigurationStore.isLoading ||
    isBrandConfigurationLoading ||
    userStore.isUserLoading ||
    travelPolicyStore.isLoading

  return {
    isLoading,
  }
}
