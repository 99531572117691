import type { AppState } from '@fiji/store'
import { TimeFormat } from '@fiji/graphql/types'
import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'

export function userSelector(state: AppState) {
  return state.user
}

export function userHourlyTimeFormatSelector(state: AppState) {
  const timeFormat =
    state?.user?.profile?.hourlyTimeFormat === TimeFormat.Hr_12
      ? TripReviewDateType.Time
      : TripReviewDateType.TimeMilitary

  return {
    timeFormat,
  }
}

export function useProfileTimeFormatSelector(state: AppState) {
  return { timeFormat: state?.user?.profile?.hourlyTimeFormat }
}

export function userMonthAndDayAndTimeFormatSelector(state: AppState) {
  const monthAndDayAndTimeFormat =
    state?.user?.profile?.hourlyTimeFormat === TimeFormat.Hr_12
      ? TripReviewDateType.MonthAndDayAndTime
      : TripReviewDateType.MonthAndDayAndTimeMilitary

  return {
    monthAndDayAndTimeFormat,
  }
}

export function userRulesAndRestrictionsDateTimeFormatSelector(state: AppState) {
  const rulesAndRestrictionsDateTimeFormat =
    state?.user?.profile?.hourlyTimeFormat === TimeFormat.Hr_12
      ? TripReviewDateType.RulesAndRestrictionsTimeDate
      : TripReviewDateType.RulesAndRestrictionsTimeDateMilitary

  return {
    rulesAndRestrictionsDateTimeFormat,
  }
}
