import { Service } from '@etta/di'
import type { SearchRailLegEntity } from '../../core/entities/search-rail-leg.entity'
import type { RailSegmentTravelClassEnum } from '../../core/enums/rail-segment-travel-class.enum'
import type {
  RailEmissionsBatchRailInput,
  RailEmissionsBatchRailSegmentInput,
} from '../../infra/types'

@Service()
export class RailEmissionsMapperService {
  mapToThrustCarbonRail(
    railLeg: SearchRailLegEntity,
    travelClass: RailSegmentTravelClassEnum,
  ): RailEmissionsBatchRailInput {
    return {
      customRef: railLeg.legId,
      segments: this.mapToThrustCarbonRailSegments(railLeg, travelClass),
    }
  }

  private mapToThrustCarbonRailSegments(
    railLeg: SearchRailLegEntity,
    travelClass: RailSegmentTravelClassEnum,
  ): RailEmissionsBatchRailSegmentInput[] {
    return railLeg.segments.map((segment) => {
      return {
        carrierCode: segment.carrier?.code,
        class: travelClass,
        departureDate: segment.departureDate.toISOString(),
        originStationCode: segment.origin.code,
        destinationStationCode: segment.destination.code,
      }
    })
  }
}
