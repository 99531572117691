import { useTranslation } from 'react-i18next'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { HotelSortMenuViewType } from '@etta/components/hotel-sort-menu/types'

type Args = {
  hotelsCount: number
}

const i18nBase = 'HotelResultPage'

export function useHeader({ hotelsCount }: Args) {
  const { t } = useTranslation()
  const { isShowDelegateName } = useShowDelegateName()
  const type = useScreenType()

  const viewMenuType: HotelSortMenuViewType =
    type === ScreenType.Desktop ? 'with-title' : 'only-icon'

  const hotelsCountLabel = t(`${i18nBase}.TotalHotels`, {
    count: hotelsCount,
  })

  return {
    hotelsCountLabel,
    viewMenuType,
    isShowDelegateName,
  }
}
