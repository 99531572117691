import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { WeekDay, WeekContainer } from './week-header-styled'

export const WeekHeader = memo(function () {
  const { t } = useTranslation()
  return (
    <WeekContainer>
      <WeekDay>{t('DateTimePicker.Sunday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Monday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Tuesday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Wednesday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Thursday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Friday')}</WeekDay>
      <WeekDay>{t('DateTimePicker.Saturday')}</WeekDay>
    </WeekContainer>
  )
})
