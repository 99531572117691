import { Action, Inject } from '@etta/di'
import { RemoveTripService } from '@etta/modules/review-trip/interface/services/remove-trip.service'
import { BookTripService } from '../services'
import { BookTripStore } from '../stores'
import { CheckoutInfoStore } from '../../checkout-info/checkout-info.store'
import { BookTripInputService } from '../services/book-trip-input.service'

@Action()
export class BookTripActions {
  constructor(
    @Inject()
    private readonly bookTripService: BookTripService,
    @Inject()
    private readonly removeTripService: RemoveTripService,
    @Inject()
    private readonly bookTripStore: BookTripStore,
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject()
    private readonly bookTripInputService: BookTripInputService,
  ) {}

  handleRetryBooking() {
    return this.bookTripStore.retryBookingData?.onHandle()
  }

  handleResetBookedStatus() {
    return this.bookTripStore.setIsBooked(false)
  }

  async handleBookTrip(input: Parameters<typeof this.bookTripInputService.buildBookTripInput>[0]) {
    const bookTripInput = this.bookTripInputService.buildBookTripInput(input)

    const result = await this.bookTripService.handleBookTrip(bookTripInput)

    if (result.isErr()) {
      return
    }
    this.removeTripService.removeTrip()
    this.checkoutInfoStore.specialRequests.dropPersistedSpecialRequestData()

    const value = result.getValue()

    const messages = 'trip' in value ? value.trip.messages : []
    return { messages }
  }

  handleDropBookTripState() {
    this.bookTripStore.dropState()
  }

  handleSetShouldKeepBookTripState(shouldKeepBookTripState: boolean) {
    this.bookTripStore.setShouldKeepBookTripState(shouldKeepBookTripState)
  }
}
