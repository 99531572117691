import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle, captionStrongCaps } from '@fiji/style'

const alertsCountStyle = css<{ alertsCount?: number | null }>`
  &:before {
    content: '${({ alertsCount = '' }) => alertsCount}';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 15px;
    width: 16px;
    height: 16px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.error};

    ${captionStrongCaps}
  }
`

export const Button = styled.button<{ alertsCount?: number | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;

  ${({ alertsCount }) => alertsCount && alertsCountStyle}
  ${KeyboardNavigationStyle}
`
