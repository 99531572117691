import { useEffect } from 'react'
import { useViewState } from '@fiji/hooks/use-view-state'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { usePassportContext } from '@etta/modules/booking'
import type { PassportEntity } from '@etta/core/entities'
import { getPassportInputBody } from './get-passport-input-body'

type Args = {
  passport?: PassportEntity
  handleModalClose: () => void
}

export function usePassport({ passport, handleModalClose }: Args) {
  const { passportActions, passportStore } = usePassportContext()
  const { addPassport, removePassport, dropState, setPassportInput } = passportActions
  const { isPassportError, isPassportLoading } = passportStore

  useEffect(() => {
    setPassportInput(passport)

    return dropState
  }, [dropState, passport, setPassportInput])

  const viewStateParams = useViewState()
  const { onChangeViewState } = viewStateParams

  const onSave = async () => {
    onChangeViewState('saving')
    const res = await addPassport({
      passport: getPassportInputBody(passportStore.passportInput),
    })
    if (res.isErr()) {
      onChangeViewState('error')
      return
    }

    onChangeViewState('saved')
    await delayForSuccess()
    onChangeViewState('hidden')
    handleModalClose()
  }

  const onRemove = async () => {
    if (!passport || !passport.id) {
      return
    }
    onChangeViewState('saving')
    const res = await removePassport({
      id: passport.id,
    })
    if (res.isErr()) {
      onChangeViewState('error')
      return
    }
    onChangeViewState('saved')
    await delayForSuccess()
    onChangeViewState('hidden')
    handleModalClose()
  }

  const getFormatDate = (date: Date | null) => {
    if (!date) {
      return ''
    }
    return new Date(date)
      .toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })
      .replace(',', '')
  }

  return {
    isPassportLoading,
    isPassportError,
    viewStateParams,
    getFormatDate,
    onSave,
    onRemove,
  }
}
