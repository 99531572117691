import type { AdaptiveRail } from '@etta/components/rail-details/types'
import { RailDepartureAndArrival } from '@etta/components/rail-departure-and-arrival/rail-departure-and-arrival'
import { RailTravelAdditionalDetails } from '@etta/components/rail-travel-additional-details/rail-travel-additional-details'
import { getNonRailTransferIconNames } from '@fiji/utils/get-non-rail-transfer-icon-names'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { Container } from './collapses-view-styled'

type Props = {
  rail: AdaptiveRail
}

export function CollapsedView({ rail }: Props) {
  const { adaptedSegments, totalRailDuration } = rail
  const firstSegment = adaptedSegments[0]
  const uniqueTransportTypes = [
    ...new Set(adaptedSegments.map((segment) => segment.transportationMode)),
  ].filter(
    (item) =>
      item === RailTransportationModeEnum.Bus ||
      item === RailTransportationModeEnum.Ferry ||
      item === RailTransportationModeEnum.Tram ||
      item === RailTransportationModeEnum.Unspecified,
  )
  const uniqueTransportIconNames = uniqueTransportTypes.map((item) =>
    getNonRailTransferIconNames(item),
  )

  const countStops = rail.adaptedSegments.reduce((acc, segment) => {
    if (segment.stops?.length) {
      return acc + segment.stops.length
    }

    return acc
  }, 0)
  const lastSegment = adaptedSegments[adaptedSegments.length - 1]
  const travelChanges = rail.adaptedSegments.length - 1

  return (
    <Container tabIndex={0}>
      <RailDepartureAndArrival
        departure={firstSegment.departure}
        arrival={lastSegment.arrival}
        travelChanges={travelChanges}
        iconNames={uniqueTransportIconNames}
      />
      <RailTravelAdditionalDetails
        platform={rail.platform}
        ecoInfo={rail.ecoInfo}
        railDuration={totalRailDuration}
        travelChanges={travelChanges}
        countStops={countStops}
      />
    </Container>
  )
}
