import type { EmissionsDeviationLevel } from '@fiji/enums'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import {
  SegmentContainer,
  SegmentLeftBlock,
  SegmentRightBlock,
  SegmentRightBlockTitle,
  SegmentDeviationDescriptionContainer,
  SegmentRightTotalEmissions,
} from '../rtp-eco-check-modal-desktop-styled'
import { RtpEcoCheckModalDeviationDescription } from '../../common'

type Props = {
  title: string | React.ReactNode
  emissionsKg: number
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}

export function RtpEcoCheckModalFlightSegment({
  title,
  emissionsKg,
  deviationLevel,
  percentDeviation,
}: Props) {
  return (
    <SegmentContainer>
      <SegmentLeftBlock>
        <SegmentRightBlockTitle>{title}</SegmentRightBlockTitle>
      </SegmentLeftBlock>
      <SegmentRightBlock>
        <SegmentDeviationDescriptionContainer>
          <RtpEcoCheckModalDeviationDescription
            isTotalDescription={false}
            deviationLevel={deviationLevel}
            percentDeviation={percentDeviation}
          />
        </SegmentDeviationDescriptionContainer>
        <SegmentRightTotalEmissions>
          <KgOfCo2 kgOfEmissions={emissionsKg} />
        </SegmentRightTotalEmissions>
      </SegmentRightBlock>
    </SegmentContainer>
  )
}
