import { useEffect, useRef } from 'react'
import { MODAL_TYPE } from './constants'

export function useCleanupModalHistory() {
  const isReplaceChecked = useRef(false)
  useEffect(() => {
    if (window.history?.state?.state?.type !== MODAL_TYPE) {
      return
    }

    if (isReplaceChecked.current) {
      return
    }
    isReplaceChecked.current = true

    const modalIds = window.history?.state?.state?.modalIds || []
    const stepsBack = modalIds.length

    if (stepsBack) {
      const { pathname, search } = window.location
      const searchQuery = new URLSearchParams(search)
      searchQuery.delete('modal-id')
      const searchParams = searchQuery.toString()
      const newSearch = `${searchParams ? '?' : ''}${searchParams}`
      window.history.replaceState({}, document.title, `${pathname}${newSearch}`)
    }
  }, [])
}
