import { MediaLayout } from '@etta/ui/media-layout'
import { RailCardModalDesktop } from './layout/desktop/rail-card-modal-desktop'
import { RailCardModalMobile } from './layout/mobile/rail-card-modal-mobile'
import type { RailCardModalProps } from './types'

export function RailCardModal(props: RailCardModalProps) {
  return (
    <MediaLayout
      mobileSlot={<RailCardModalMobile {...props} />}
      desktopSlot={<RailCardModalDesktop {...props} />}
    />
  )
}
