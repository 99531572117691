/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect } from 'react'

type Props = {
  prevButton: Element | null
  startDateValue: moment.Moment | null
  handleChangeMonth: (value: number) => void
  onPrevButtonClick: () => void
  scrollToSelected: () => void
}

export function useScrollToSelected({
  prevButton,
  startDateValue,
  onPrevButtonClick,
  handleChangeMonth,
  scrollToSelected,
}: Props) {
  useEffect(() => {
    if (!prevButton) {
      return
    }
    if (!startDateValue) {
      return
    }
    if (startDateValue?.month() === moment().month()) {
      return
    }

    const yearDifference = (startDateValue.year() - moment().year()) * 12
    const monthDifference = startDateValue?.month() - moment().month()

    handleChangeMonth(monthDifference + yearDifference)
    setTimeout(() => {
      onPrevButtonClick()
      scrollToSelected()
    }, 0)
  }, [prevButton])
}
