import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { estimatedEmissionsLinks } from '@etta/components/flight-details-eco-check/constants'
import { Icon } from '@etta/ui/icon'
import {
  HeaderContainer,
  TitleContainer,
  Title,
  ViewSourceLink,
} from '../flight-details-eco-check-modal-mobile-new-styled'

type Props = {
  onClose: () => void
}

export function FlightDetailsEcoCheckHeaderNew({ onClose }: Props) {
  const { t } = useTranslation()

  return (
    <HeaderContainer>
      <IconButton icon="closePWA" size="medium" color="mainText" onClick={onClose} />
      <TitleContainer>
        <Title>{t('Emissions.DetailsModal.EstimatedEmissions')}</Title>
        <ViewSourceLink
          aria-label={`${t('Emissions.DetailsModal.ViewSource')} - ${t('Accessibility.OpenLink')}`}
          target="_blank"
          size="small"
          href={estimatedEmissionsLinks.viewSourceLink}>
          {t('Emissions.DetailsModal.ViewSource')}
          <Icon name={'openWindowPWA'} size={18} color="primary1" />
        </ViewSourceLink>
      </TitleContainer>
    </HeaderContainer>
  )
}
