import { Block } from '@etta/ui/block'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import type { LayoutProps } from '../types'
import { Container, Description } from './safety-check-header-desktop-styled'

export function SafetyCheckHeaderDesktop({ description, poweredByLabel }: LayoutProps) {
  const fullDescription = `${description} ${poweredByLabel}`

  return (
    <Container aria-label={fullDescription}>
      <SafetyCheckTitle size="medium" />
      <Block marginTop={4}>
        <Description>{fullDescription}</Description>
      </Block>
    </Container>
  )
}
