import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'

export function useTicketRestrictionsRtp(
  fareConditions?: TrainSegmentEntity['fareConditions'] | null,
) {
  const ticketRestrictionModalToggle = useTogglePopup()
  const currentTripFare = fareConditions?.localizedFareConditions[0].fareTypeDescription || ''
  const currentTicketRestrictions = fareConditions?.localizedFareConditions[0].conditions || []

  const ticketRestrictions: TicketRestrictionsInfo = {
    tripFare: currentTripFare,
    ticketRestrictions: currentTicketRestrictions,
  }

  const onOpenModalClick = () => {
    ticketRestrictionModalToggle.handleOpen()
  }

  return {
    onOpenModalClick,
    ticketRestrictionModalToggle,
    ticketRestrictions,
  }
}
