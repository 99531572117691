import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import { FareRulesContent } from './fare-rules-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  segmentId?: string
  itineraryId: string
  airlineLogo?: string
  airlineName?: string
  bookingCodes?: Array<string>
  fares?: Array<string>
}

export function FareRules({ isOpen, onClose, ...props }: Props) {
  return (
    <InfoModal isVisible={isOpen} onClose={onClose} data-tracking-id="modal-fare-rules">
      <FareRulesContent onClose={onClose} {...props} />
    </InfoModal>
  )
}
