import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { CloseButton, ButtonWrapper } from './e-ticket-receipt-modal.styled'
import { ETicketReceiptContent } from './e-ticket-receipt-content'
import type { Props } from './types'

export const ETicketReceiptModal = ({
  isOpen,
  onClose,
  trip,
  travelerName,
  handlePrint,
  printableContentRef,
  selectedETicketIndex,
}: Props) => {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Header isMobile>
        <CloseButton icon="closeFilledPWA" size="small" color="mainText1" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <ETicketReceiptContent
          trip={trip}
          travelerName={travelerName}
          printableContentRef={printableContentRef}
          selectedETicketIndex={selectedETicketIndex}
        />
      </Modal.Body>
      <Modal.Footer padding="normal" withBorder>
        <ButtonWrapper>
          <Button
            size="normal-wide"
            minWidth={13}
            onClick={() => handlePrint && handlePrint(selectedETicketIndex)}
            leftIcon={<Icon name="printPWA" />}>
            {t(i18nBase + 'Print')}
          </Button>
        </ButtonWrapper>
      </Modal.Footer>
    </Modal>
  )
}
