import { useTranslation } from 'react-i18next'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { EticketGuideView } from './eticket-guide-view'
import { CollectGuideView } from './collect-guide-view'

const i18nBase = 'RailDeliveryOption.UsageGuideModal.'

export type Props = {
  isEticket: boolean
  onClose: () => void
}

export function RailDeliveryGuideModal({ isEticket, onClose }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <CheckoutPageModal.Header
        title={isEticket ? t(i18nBase + 'EticketGuideTitle') : t(i18nBase + 'CollectGuideTitle')}
        onClose={onClose}
      />
      <CheckoutPageModal.Body>
        {isEticket ? <EticketGuideView /> : <CollectGuideView />}
      </CheckoutPageModal.Body>
    </>
  )
}
