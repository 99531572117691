import type { FareWithFareAttributes } from '@fiji/types'
import { Badge } from './badge'
import type { BadgeSkinType } from './types'
import { Container } from './rail-badges-styled'
import { useRailBadges } from './use-rail-badges'

type Props = {
  isPreferred: boolean
  maxBadges?: number
  fare: FareWithFareAttributes
  isOutOfPolicy: boolean
  skin?: BadgeSkinType
}

export function RailBadges({ isPreferred, fare, skin, maxBadges, isOutOfPolicy }: Props) {
  const { badges } = useRailBadges({ isPreferred, fare, maxBadges, isOutOfPolicy })

  return (
    <Container>
      {badges.map((badge) => (
        <Badge skin={skin} key={badge.key} title={badge.title} icon={badge.icon} />
      ))}
    </Container>
  )
}
