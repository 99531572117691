import type { FC } from 'react'
import { Icon } from '@etta/ui/icon'
import { CarMarkerWrapper } from './car-marker.styles'

export const CarMarker: FC = () => {
  return (
    <CarMarkerWrapper>
      <Icon name="carRentalPWA" color="mainText2" size="medium" />
    </CarMarkerWrapper>
  )
}
