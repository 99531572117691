import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { Icon } from '@etta/ui/icon'
import { DescendantItem } from '@etta/components/descendant'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useRecentSearchesContext } from '../../../../../interface/use-recent-searches.context'
import { TitleContainer } from '../../recent-searches-list-mobile-styled'
import {
  LeftBlock,
  ListItem,
  Location,
  RightBlock,
  TopLineWithoutDates,
} from '../recent-search-items-styled'

type Props = {
  onModalClose?: () => void
}

export function RecentHotelSearchListWithoutDates({ onModalClose }: Props) {
  const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
  const { featureFlags } = useFeatureFlags()
  const { t } = useTranslation()
  const title = t('RecentSearches.Title')
  const recentSearchesListWithoutDates = recentSearchesStore.recentSearchesWithoutDates.hotel.items

  const [recentSearches, setRecentSearches] = useState(recentSearchesListWithoutDates)
  const { autocompleteStore } = useAutocompleteContext()

  useEffect(() => {
    if (!featureFlags.isRecentSearchesFilteringEnabled) {
      return
    }

    const filteredSearhes = recentSearchesListWithoutDates.filter((rs) => {
      const locationName = rs.hotelSearchParams.location.name
      const query = autocompleteStore.query?.toLowerCase() || ''
      const location = locationName.toLowerCase()

      return location.includes(query)
    })

    setRecentSearches(filteredSearhes)
  }, [autocompleteStore.query, recentSearchesListWithoutDates, featureFlags])

  if (
    recentSearchesStore.isShowHotelRecentSearch ||
    !recentSearchesStore.isShowHotelRecentSearchWithoutDates
  ) {
    return null
  }

  return (
    <>
      <TitleContainer aria-label={title}>{title}</TitleContainer>
      {recentSearches.map((item) => {
        const { fillSearchForm } = recentSearchDataAction.getRecentHotelSearchesWithoutDates(
          item.hotelSearchParams,
        )
        return (
          <DescendantItem
            key={item.id}
            onSelect={() => {
              onModalClose && onModalClose()
              fillSearchForm()
            }}>
            <ListItem
              onClick={() => {
                onModalClose && onModalClose()
                fillSearchForm()
              }}
              data-tracking-id="hotel-search-mechanism-recent-searches-list">
              <LeftBlock>
                <Icon name="hotelPWA" size={35} />
              </LeftBlock>
              <RightBlock>
                <TopLineWithoutDates>
                  <Location>{item.hotelSearchParams.location.name}</Location>
                </TopLineWithoutDates>
              </RightBlock>
            </ListItem>
          </DescendantItem>
        )
      })}
    </>
  )
}
