import { useEffect, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from '@fiji/store'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { mobilitySelector, updateForceFetchSuggestions } from '@fiji/store/mobility'
import { useMobilitySearchQuery } from '@fiji/hooks/search-queries/use-mobility-search-query'
import type { RideHailSuggestionValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-suggestion.value-object'

export function useMobilitySuggestedRides() {
  const { queryParams } = useMobilitySearchQuery()
  const { searchRideHailStore, suggestionActions, suggestionStore } = useRideHailSearchContext()
  const processId = searchRideHailStore.searchRideHailTripInfo.processId || queryParams.processId
  const dropOffSegmentId =
    searchRideHailStore.searchRideHailTripInfo.dropOffSegmentId || queryParams.dropOffSegmentId

  const { forceFetchSuggestions } = useAppSelector(mobilitySelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    suggestionActions.getSuggestions()
  }, [suggestionActions])

  useEffect(() => {
    if (forceFetchSuggestions) {
      suggestionActions.getSuggestions()
      dispatch(updateForceFetchSuggestions({ forceFetchSuggestions: false }))
    }
  }, [forceFetchSuggestions, dispatch, suggestionActions])

  const matchRides = useMemo(() => {
    const withMatchProcessId = (ride: RideHailSuggestionValueObject): boolean => {
      if (processId === undefined || processId === '') {
        return true
      }
      return ride.processId === processId.toString()
    }

    const withMatchDropOffSegment = (ride: RideHailSuggestionValueObject): boolean => {
      if (dropOffSegmentId === undefined || dropOffSegmentId === '') {
        return true
      }
      return ride.dropOffSegmentId === dropOffSegmentId?.toString()
    }

    return suggestionStore.suggestions.filter(withMatchProcessId).filter(withMatchDropOffSegment)
  }, [suggestionStore.suggestions, processId, dropOffSegmentId])

  const firstSuggestedRide = matchRides.length > 0 ? matchRides[0] : undefined

  return {
    rides: matchRides,
    firstSuggestedRide,
    isLoading: suggestionStore.isLoading,
    error: suggestionStore.isError,
    handleRefetchRequest: suggestionActions.getSuggestions,
  }
}
