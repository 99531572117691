import { Tabs } from '@etta/ui/tabs'
import { Container } from '../../trip-tabs-styles'
import type { LayoutProps } from '../types'

export function TripTabsMobile({ tabs, usedTab, onChange }: LayoutProps) {
  return (
    <Container>
      <Tabs activeTab={usedTab} onChangeTab={onChange}>
        <Tabs.Header>
          {tabs.map(
            (tab) =>
              !tab.isHiddenMobile && (
                <Tabs.Tab
                  key={tab.title}
                  value={tab.value}
                  data-tracking-id={`${tab.title.toLowerCase()}-button`}
                  aria-label={tab.title}>
                  {tab.title}
                </Tabs.Tab>
              ),
          )}
        </Tabs.Header>
      </Tabs>
    </Container>
  )
}
