import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace UserProfileErrors {
  export const GetUserProfileErrorResponse = createErrorClass('Get UserProfile Error Response')
  export const GetUserProfileUnexpectedError = createErrorClass('Get UserProfile Unexpected Error')
  export const UpdateUserProfileTravelPreferencesErrorResponse = createErrorClass(
    'Update UserProfile TravelPreferences Error Response',
  )
  export const UpdateUserProfileTravelPreferencesUnexpectedError = createErrorClass(
    'Update UserProfile TravelPreferences Unexpected Error',
  )
  export const UpdateUserProfileBusinessAddressErrorResponse = createErrorClass(
    'Update UserProfile BusinessAddress Error Response',
  )
  export const UpdateUserProfileBusinessAddressUnexpectedError = createErrorClass(
    'Update UserProfile BusinessAddress Unexpected Error',
  )
  export const UpdateUserProfileContactInformationErrorResponse = createErrorClass(
    'Update UserProfile ContactInformation Error Response',
  )
  export const UpdateUserProfileContactInformationUnexpectedError = createErrorClass(
    'Update UserProfile ContactInformation Unexpected Error',
  )
  export const UpdateUserProfilePersonalInformationErrorResponse = createErrorClass(
    'Update UserProfile PersonalInformation Error Response',
  )
  export const UpdateUserProfilePersonalInformationUnexpectedError = createErrorClass(
    'Update UserProfile PersonalInformation Unexpected Error',
  )
  export const UpdateUserProfileHomeAddressErrorResponse = createErrorClass(
    'Update UserProfile HomeAddress Error Response',
  )
  export const UpdateUserProfileHomeAddressUnexpectedError = createErrorClass(
    'Update UserProfile HomeAddress Unexpected Error',
  )
  export const UpdateUserProfileEmergencyContactErrorResponse = createErrorClass(
    'Update UserProfile EmergencyContact Error Response',
  )
  export const UpdateUserProfileEmergencyContactUnexpectedError = createErrorClass(
    'Update UserProfile EmergencyContact Unexpected Error',
  )
  export const UpdateUserProfileEmployeeInformationErrorResponse = createErrorClass(
    'Update UserProfile EmployeeInformation Error Response',
  )
  export const UpdateUserProfileEmployeeInformationUnexpectedError = createErrorClass(
    'Update UserProfile EmployeeInformation Unexpected Error',
  )
}

export type UserProfileErrorsInstances = InstanceType<
  typeof UserProfileErrors[keyof typeof UserProfileErrors]
>
