import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { IconButton } from '@etta/ui/icon-button'
import { Header as MobileHeader } from '@etta/ui/header'
import { Screen } from '@etta/ui/screen'
import { UberInfoModal } from '@etta/components/uber-info-modal'
import { Modal } from '@etta/ui/modal'
import { DatePickerModal } from '@etta/ui/date-picker-modal/date-picker-modal'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { WheelPickerDialog } from '@etta/components/wheel-picker-dialog/wheel-picker-dialog'
import { addDaysToDate } from '@fiji/utils/dates'
import { MobilityMaterialTimePickerModal } from '@etta/components/mobility-material-time-picker-modal'
import { Icon } from '@etta/ui/icon'
import { ScreenType } from '@fiji/modes'
import { MobilityItineraryDialog } from '../mobility-select-location-modal/mobility-itinerary-dialog'
import { useMobilitySearchFormDialog } from './use-mobility-search-form-dialog'
import {
  Content,
  Footer,
  ItineraryButton,
  ScreenHeader,
} from './mobility-search-form-dialog-styled'
import { SelectLocationModal } from './select-location-modal/select-location-modal'
import { LocationSelector } from './location-selector'
import { DateTimeSelector } from './date-time-selector'

const i18nBase = 'Mobility.NewSearchForm.'

type Props = {
  containerRef?: (node: HTMLDivElement) => void
  isVisible: boolean
  onClose: () => void
  onExited: () => void
  handleStartSearch: () => void
}

export const MobilitySearchFormDialog = observer(function MobilitySearchFormDialog({
  isVisible,
  onClose,
  onExited,
  handleStartSearch,
}: Props) {
  const { t } = useTranslation()
  const { searchRideHailActions } = useRideHailSearchContext()
  const {
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
    formattedPickUpDate,
    formattedPickUpTime,
    isDisabledSearch,

    // On-demand
    isOnDemand,
    handleToggleOnDemand,

    // Info modal
    isModalInfoOpen,
    handleModalInfoClose,
    handleModalInfoOpen,
    advanceBookingInDays,

    // Select location modal
    isSelectLocationModalVisible,
    handleSelectPickUpDropOff,
    selectLocationState,
    handleClickPickUp,
    handleClickDropOff,
    handleSelectLocationModalClose,

    // Date & time input handlers
    handleTapOnDateInput,
    handleTapOnTimeInput,

    // Date & time modals
    pickUpDateTime,
    isSelectDateIOSModalOpen,
    isSelectDateAndroidModalOpen,
    isSelectTimeAndroidModalOpen,
    handleCloseIOSDateTimeModal,
    handleCloseAndroidDateModal,
    handleCloseAndroidTimeModal,
    handleSubmitDate,
    handleSubmitTime,
    handleSubmitDateTime,

    // Itinerary dialog
    isItineraryOpen,
    handleItineraryButtonClick,
    handleItineraryClose,
  } = useMobilitySearchFormDialog()

  return (
    <Modal
      isAnimated
      position="right"
      isVisible={isVisible}
      handleModalVisibility={searchRideHailActions.openLeavingBookingFlowDialog}
      onModalExited={onExited}>
      <Screen>
        <Screen.Container>
          <ScreenHeader>
            <MobileHeader leftSlot={<MobileHeader.BackButton onClick={onClose} />}>
              <MobileHeader.Title title={t(i18nBase + 'Title')} align="center" />
              <IconButton
                icon="infoPWA"
                size="medium"
                color="mainText1"
                onClick={handleModalInfoOpen}
              />
            </MobileHeader>
          </ScreenHeader>
          <Content>
            <LocationSelector
              pickUp={pickUpPlace?.name}
              dropOff={dropOffPlace?.name}
              onPickUpClick={handleClickPickUp}
              onDropOffClick={handleClickDropOff}
            />
            <DateTimeSelector
              isNow={isOnDemand}
              date={formattedPickUpDate}
              time={formattedPickUpTime}
              onToggleNow={handleToggleOnDemand}
              onDateClick={handleTapOnDateInput}
              onTimeClick={handleTapOnTimeInput}
            />
          </Content>

          <ItineraryButton
            type="button"
            onClick={handleItineraryButtonClick}
            data-tracking-id="mobility-itinerary-floating-button">
            <Icon name={isItineraryOpen ? 'closePWA' : 'navTripsPWA'} color={'white'} size={36} />
          </ItineraryButton>
          <MobilityItineraryDialog isOpen={isItineraryOpen} onClose={handleItineraryClose} />

          <Modal.Footer withBorder backgroundColor="white">
            <Footer>
              <Button
                fullWidth
                onClick={handleStartSearch}
                btnType="primary"
                disabled={isDisabledSearch}
                data-tracking-id={'dataTrackingIdSubmitButton'}>
                {t(i18nBase + 'SearchRide')}
              </Button>
            </Footer>
          </Modal.Footer>
        </Screen.Container>

        <SelectLocationModal
          isVisible={isSelectLocationModalVisible}
          onClose={handleSelectLocationModalClose}
          onChange={handleSelectPickUpDropOff}
          state={selectLocationState}
        />

        <UberInfoModal
          isVisible={isModalInfoOpen}
          isCloseModalOnlyOnExit={true}
          onClose={handleModalInfoClose}
        />
      </Screen>
      <UberInfoModal
        isVisible={isModalInfoOpen}
        isCloseModalOnlyOnExit={true}
        onClose={handleModalInfoClose}
      />
      <DatePickerModal
        isOpen={isSelectDateAndroidModalOpen}
        onClose={handleCloseAndroidDateModal}
        onSelect={handleSubmitDate}
        date={pickUpDate}
        minDate={new Date()}
        maxDate={addDaysToDate(new Date(), advanceBookingInDays)}
        startTitle={t(i18nBase + 'PickUp')}
        dateTitleFormat={'EEE, MMM dd'}
        isShowCalendarIcon
        withConfirmOneWayButton={false}
        withNextButton
        forceScreenType={ScreenType.Mobile}
      />
      <MobilityMaterialTimePickerModal
        portalTarget="#mobility-select-location-modals-container"
        initialTime={pickUpTime}
        isOpen={isSelectTimeAndroidModalOpen}
        onSubmit={handleSubmitTime}
        onClose={handleCloseAndroidTimeModal}
      />
      <WheelPickerDialog
        portalTarget="#mobility-select-location-modals-container"
        isOpenDialog={isSelectDateIOSModalOpen}
        minDate={new Date()}
        maxDate={addDaysToDate(new Date(), advanceBookingInDays)}
        value={pickUpDateTime}
        onSubmit={handleSubmitDateTime}
        onClose={handleCloseIOSDateTimeModal}
      />
      <div id="mobility-select-location-modals-container" />
    </Modal>
  )
})
