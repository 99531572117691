import { format } from 'date-fns'
import { Action, Inject } from '@etta/di'
import { getDateWithoutTimezoneOffset, isoToDate } from '@fiji/utils/dates'
import { TravelDocumentsService } from '../services/travel-documents.service'
import { TravelDocumentsStore } from '../stores/travel-documents.store'
import { VisaTypeOptions } from '../../core/entities/visa.entity'
import { PreExpirationAlertValues } from '../../core/entities/credit-cards.entity'
import type {
  VisaInput,
  PassportInput,
  NationalIdCardInput,
  KnownTravelerNumberInput,
  RedressNumberInput,
} from '../stores/travel-documents.store'
import { SettingsStore } from '../stores/settings.store'
import { BaseSettingsService } from '../services/base.service'
import { AccountInformationActions } from './account-information.actions'

@Action()
export class TravelDocumentsActions {
  constructor(
    @Inject()
    private readonly travelDocumentsService: TravelDocumentsService,
    @Inject()
    private readonly travelDocumentsStore: TravelDocumentsStore,
    @Inject()
    private readonly settingsStore: SettingsStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly accountInformationActions: AccountInformationActions,
  ) {}

  getTravelDocumentsViewMode() {
    return this.travelDocumentsService.getTravelDocumentsViewMode()
  }

  getPassports() {
    return this.travelDocumentsService.getPassports()
  }

  getVisas() {
    return this.travelDocumentsService.getVisas()
  }

  getNationalIdCards() {
    return this.travelDocumentsService.getNationalIdCards()
  }

  getKnownTravelerNumbers() {
    return this.travelDocumentsService.getKnownTravelerNumbers()
  }

  getRedressNumbers() {
    return this.travelDocumentsService.getRedressNumbers()
  }

  getKTNDisabledCountries() {
    return this.travelDocumentsService.getKTNDisabledCountries()
  }

  getRedressDisabledCountries() {
    return this.travelDocumentsService.getRedressDisabledCountries()
  }

  handleModalOpen() {
    this.travelDocumentsStore.toggle.handleOpen()
  }

  handleModalClose() {
    this.settingsStore.dropHighlightedElement()
    this.travelDocumentsStore.toggle.handleClose()
  }

  handleVisaOpen() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.visaActionToggle.handleOpen()
  }

  handleVisaClose() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.visaActionToggle.handleClose()
    this.travelDocumentsStore.setIsVisaNumberTouched(false)
    this.travelDocumentsStore.resetVisa()
  }

  handlePassportOpen() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.passportActionToggle.handleOpen()
  }

  handlePassportClose() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.passportActionToggle.handleClose()
    this.travelDocumentsStore.setIsPassportNumberTouched(false)
    this.travelDocumentsStore.resetPassport()
  }

  handleNationalIdOpen() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.nationalIdActionToggle.handleOpen()
  }

  handleNationalIdCardClose() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.nationalIdActionToggle.handleClose()
    this.travelDocumentsStore.setIsNationalIdNumberTouched(false)
    this.travelDocumentsStore.resetNationalIdCard()
  }

  handleRedressNumberOpen() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.redressNumberActionToggle.handleOpen()
  }

  handleRedressNumberEdit(id?: string | number | null) {
    const redress = this.getRedressNumbers().find((redress) => String(redress.id) === String(id))

    if (!redress || !id) {
      return
    }
    this.travelDocumentsStore.setRedressNumber('id', String(id))
    this.travelDocumentsStore.setRedressNumber('number', String(redress.number))
    this.travelDocumentsStore.setRedressNumber('country', redress.country.code)
    this.handleRedressNumberOpen()
  }

  handleRedressNumberClose() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.resetRedressNumber()
    this.travelDocumentsStore.setIsRedressNumberTouched(false)
    this.travelDocumentsStore.redressNumberActionToggle.handleClose()
  }

  handleKnownTravelerNumberOpen() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.knownTravelerNumberActionToggle.handleOpen()
  }

  handleKnownTravelerNumberEdit(id?: string | number | null) {
    const knownTraveler = this.getKnownTravelerNumbers().find(
      (knownTraveler) => String(knownTraveler.id) === String(id),
    )

    if (!knownTraveler || !id) {
      return
    }
    this.travelDocumentsStore.setKnownTravelerNumber('id', String(id))
    this.travelDocumentsStore.setKnownTravelerNumber('number', String(knownTraveler.number))
    this.travelDocumentsStore.setKnownTravelerNumber('country', knownTraveler.country.code)
    if (knownTraveler.expirationDate.value) {
      this.handleKnownTravelerNumberExpirationDate(isoToDate(knownTraveler.expirationDate.value))
    }

    this.travelDocumentsStore.setKnownTravelerNumber(
      'preExpirationAlert',
      knownTraveler.preExpirationAlert,
    )
    this.handleKnownTravelerNumberOpen()
  }

  handleKnownTravelerNumberClose() {
    this.travelDocumentsStore.setIsSaveButtonDisabled(true)
    this.travelDocumentsStore.resetKnownTravelerNumber()
    this.travelDocumentsStore.setIsKnownTravelerNumberTouched(false)
    this.travelDocumentsStore.knownTravelerNumberActionToggle.handleClose()
  }

  handlePickerOpen(picker: keyof typeof this.travelDocumentsStore.pickerTogglers) {
    this.travelDocumentsStore.pickerTogglers[picker].handleOpen()
  }

  handlePickerClose(picker: keyof typeof this.travelDocumentsStore.pickerTogglers) {
    this.travelDocumentsStore.pickerTogglers[picker].handleClose()
  }

  async handleUpdateVisaSubmit() {
    const { visaValidator } = this.travelDocumentsStore

    const validatorCheck = await visaValidator.submit()

    if (!validatorCheck.isValid) {
      return
    }

    const { values: visa } = visaValidator

    const convertedExpDate = this.convertDate(visa.expirationDate)

    const input = {
      id: visa.id ? visa.id : undefined,
      number: visa.number,
      country: visa.country,
      expirationDate: convertedExpDate,
      visaType: visa.visaType ? visa.visaType : VisaTypeOptions.Other,
      preExpirationAlert: visa.preExpirationAlert
        ? visa.preExpirationAlert
        : PreExpirationAlertValues.Never,
    }

    await this.travelDocumentsService.updateVisa({
      input,
      handleClose: this.handleVisaClose,
    })
  }

  async handleRemoveVisa() {
    const {
      visaValidator: { values: visa },
      removeToggle,
    } = this.travelDocumentsStore

    if (!visa.id) {
      return
    }

    const input = {
      id: Number(visa.id),
    }

    removeToggle.handleClose()

    await this.travelDocumentsService.removeVisa({
      input,
      handleClose: this.handleVisaClose,
    })
  }

  handleVisaEdit(id?: string | number | null) {
    const visa = this.getVisas().find((visa) => String(visa.id) === String(id))
    if (!visa || !id) {
      return
    }
    this.handleVisaExpirationDate(new Date(visa.expirationDate.value || ''))
    this.travelDocumentsStore.setVisa('id', String(id))
    this.travelDocumentsStore.setVisa('number', String(visa.number))
    this.travelDocumentsStore.setVisa('country', visa.country.code)
    this.travelDocumentsStore.setVisa('visaType', visa.visaType)
    this.travelDocumentsStore.setVisa('preExpirationAlert', visa.preExpirationAlert || '')
    this.handleVisaOpen()
  }

  handleVisaFieldChange(field: keyof VisaInput, value: string) {
    this.travelDocumentsStore.setIsSaveButtonDisabled(false)
    this.travelDocumentsStore.setVisa(field, value)
  }

  handleVisaExpirationDate(value?: Date) {
    const date = value ? format(value, 'LLL d y') : ''
    this.handleVisaFieldChange('expirationDate', date)
  }

  handleKnownTravelerNumberExpirationDate(value?: Date) {
    const date = value ? format(value, 'LLL d y') : ''
    this.handleKnownTravelerNumberFieldChange('expirationDate', date)
  }

  async handleUpdatePassportSubmit() {
    const { passportValidator } = this.travelDocumentsStore

    const validatorCheck = await passportValidator.submit()

    if (!validatorCheck.isValid) {
      return
    }

    const { values: passport } = passportValidator

    const convertedExpDate = this.convertDate(passport.expirationDate)
    const convertedIssueDate = this.convertDate(passport.issueDate)

    const input = {
      id: passport.id ? passport.id : undefined,
      number: passport.number,
      country: passport.country,
      issueCountry: passport.issueCountry,
      expirationDate: convertedExpDate,
      issueDate: convertedIssueDate,
      preExpirationAlert: passport.preExpirationAlert
        ? passport.preExpirationAlert
        : PreExpirationAlertValues.Never,
    }

    await this.travelDocumentsService.updatePassport({
      input,
      handleClose: this.handlePassportClose,
    })
  }

  async handleRemovePassport() {
    const {
      passportValidator: { values: passport },
      removeToggle,
    } = this.travelDocumentsStore

    if (!passport.id) {
      return
    }

    const input = {
      id: passport.id,
    }

    removeToggle.handleClose()

    await this.travelDocumentsService.removePassport({
      input,
      handleClose: this.handlePassportClose,
    })
  }

  handlePassportEdit(id?: string | number | null) {
    const passport = this.getPassports().find((passport) => String(passport.id) === String(id))
    if (!passport) {
      return
    }

    this.handlePassportFieldChange('id', Number(id))
    this.handlePassportFieldChange('number', passport.number)
    this.handlePassportFieldChange('country', passport.country.code)
    this.handlePassportFieldChange('issueCountry', passport.issuingCountry.code)
    this.handlePassportFieldChange('preExpirationAlert', passport.preExpirationAlert)
    this.handlePassportDate('expirationDate', new Date(passport.expirationDate.value || ''))
    this.handlePassportDate('issueDate', new Date(passport.issueDate.value || ''))

    this.handlePassportOpen()
  }

  handlePassportFieldChange(field: keyof PassportInput, value: string | number) {
    this.travelDocumentsStore.setIsSaveButtonDisabled(false)
    this.travelDocumentsStore.setPassport(field, value)
  }

  handlePassportDate(field: 'expirationDate' | 'issueDate', value?: Date) {
    if (value?.toString() === 'Invalid Date' || value === undefined) {
      this.handlePassportFieldChange(field, '')
      return
    }

    const date = format(getDateWithoutTimezoneOffset(value), 'LLL d y')
    this.handlePassportFieldChange(field, date)
  }

  async handleUpdateNationalIdCardSubmit() {
    const { nationalIdCardValidator } = this.travelDocumentsStore

    const validatorCheck = await nationalIdCardValidator.submit()

    if (!validatorCheck.isValid) {
      return
    }

    const { values: nationalIdCard } = nationalIdCardValidator

    const convertedExpDate = this.convertDate(nationalIdCard.expirationDate)
    const convertedIssueDate = this.convertDate(nationalIdCard.issueDate)

    const input = {
      id: nationalIdCard.id ? nationalIdCard.id : undefined,
      number: nationalIdCard.number,
      country: nationalIdCard.country,
      expirationDate: convertedExpDate,
      issueDate: convertedIssueDate,
      preExpirationAlert: nationalIdCard.preExpirationAlert
        ? nationalIdCard.preExpirationAlert
        : PreExpirationAlertValues.Never,
    }

    await this.travelDocumentsService.updateNationalIdCard({
      input,
      handleClose: this.handleNationalIdCardClose,
    })
  }

  async handleRemoveNationalIdCard() {
    const {
      nationalIdCardValidator: { values: nationalIdCard },
      removeToggle,
    } = this.travelDocumentsStore

    if (!nationalIdCard.id) {
      return
    }

    const input = {
      id: Number(nationalIdCard.id),
    }

    removeToggle.handleClose()

    await this.travelDocumentsService.removeNationalIdCard({
      input,
      handleClose: this.handleNationalIdCardClose,
    })
  }

  async handleUpdateRedressNumberSubmit() {
    const { redressNumberValidator } = this.travelDocumentsStore
    const redressNumber = redressNumberValidator.values
    const validatorCheck = await redressNumberValidator.submit()

    if (!validatorCheck.isValid) {
      return
    }

    const redressNumbers = this.travelDocumentsService.getRedressNumbers().map((redress) => ({
      ...redress,
      country: redress.country.code,
    }))
    const knownTravelerNumbers = this.travelDocumentsService
      .getKnownTravelerNumbers()
      .map((ktn) => ({
        country: ktn.country.code,
        number: ktn.number,
        preExpirationAlert: ktn.preExpirationAlert,
        expirationDate: ktn.expirationDate.value,
      }))

    const redressNumbersInput = redressNumber.id
      ? redressNumbers.map((redress) => {
          if (redress.id === redressNumber.id) {
            return redressNumber
          }
          return redress
        })
      : [...redressNumbers, redressNumber]

    const additionalDocumentsPreferencesInput = {
      knownTravelerNumbers,
      redressNumbers: redressNumbersInput,
    }

    await this.baseSettingsService.saveUser({
      mode: 'additional-documents-preferences',
      input: additionalDocumentsPreferencesInput,
      handleClose: this.handleRedressNumberClose,
    })
  }

  async handleUpdateKnownTravelerNumberSubmit() {
    const { knownTravelerNumberValidator } = this.travelDocumentsStore
    const knownTravelerNumber = knownTravelerNumberValidator.values
    const convertedExpDate = this.convertDate(knownTravelerNumber.expirationDate)
    const validatorCheck = await knownTravelerNumberValidator.submit()
    if (!validatorCheck.isValid) {
      return
    }

    const redressNumbers = this.travelDocumentsService.getRedressNumbers().map((redress) => ({
      ...redress,
      country: redress.country.code,
    }))
    const knownTravelerNumbers = this.travelDocumentsService
      .getKnownTravelerNumbers()
      .map((ktn) => {
        if (String(ktn.id) === String(knownTravelerNumber.id)) {
          return {
            number: knownTravelerNumber.number,
            country: knownTravelerNumber.country,
            preExpirationAlert: knownTravelerNumber.preExpirationAlert,
            expirationDate: convertedExpDate,
          }
        }
        return {
          country: ktn.country.code,
          number: ktn.number,
          preExpirationAlert: ktn.preExpirationAlert,
          expirationDate: ktn.expirationDate.value,
        }
      })

    const knownTravelerNumbersInput = knownTravelerNumber.id
      ? knownTravelerNumbers
      : [
          ...knownTravelerNumbers,
          {
            number: knownTravelerNumber.number,
            country: knownTravelerNumber.country,
            preExpirationAlert: knownTravelerNumber.preExpirationAlert,
            expirationDate: convertedExpDate,
          },
        ]

    const additionalDocumentsPreferencesInput = {
      knownTravelerNumbers: knownTravelerNumbersInput,
      redressNumbers,
    }
    await this.baseSettingsService.saveUser({
      mode: 'additional-documents-preferences',
      input: additionalDocumentsPreferencesInput,
      handleClose: this.handleKnownTravelerNumberClose,
    })
  }

  handleNationalIdCardEdit(id?: string | number | null) {
    const nationalIdCard = this.getNationalIdCards().find((card) => String(card.id) === String(id))
    if (!nationalIdCard) {
      return
    }
    this.handleNationalIdCardExpirationDate(new Date(nationalIdCard.expirationDate.value || ''))
    this.handleNationalIdCardIssueDate(new Date(nationalIdCard.issueDate.value || ''))
    this.travelDocumentsStore.setNationalIdCard('id', String(id))
    this.travelDocumentsStore.setNationalIdCard('number', String(nationalIdCard.number))
    this.travelDocumentsStore.setNationalIdCard('country', nationalIdCard.country.code)
    this.travelDocumentsStore.setNationalIdCard(
      'preExpirationAlert',
      nationalIdCard.preExpirationAlert || '',
    )
    this.handleNationalIdOpen()
  }

  handleNationalIdCardFieldChange(field: keyof NationalIdCardInput, value: string) {
    this.travelDocumentsStore.setIsSaveButtonDisabled(false)
    this.travelDocumentsStore.setNationalIdCard(field, value)
  }

  handleRedressNumberFieldChange(field: keyof RedressNumberInput, value: string) {
    this.travelDocumentsStore.setIsSaveButtonDisabled(false)
    this.travelDocumentsStore.setRedressNumber(field, value)
  }

  handleKnownTravelerNumberFieldChange(field: keyof KnownTravelerNumberInput, value: string) {
    this.travelDocumentsStore.setIsSaveButtonDisabled(false)
    this.travelDocumentsStore.setKnownTravelerNumber(field, value)
  }

  async handleRemoveRedressNumber() {
    const { redressNumberValidator, removeToggle } = this.travelDocumentsStore

    if (!redressNumberValidator.values.id) {
      return
    }

    removeToggle.handleClose()

    const filteredRedressNumbers = this.travelDocumentsService
      .getRedressNumbers()
      .filter((redress) => String(redress.id) !== String(redressNumberValidator.values.id))
      .map((redress) => ({
        number: redress.number,
        country: redress.country.code,
      }))

    const knownTravelerNumbers = this.travelDocumentsService
      .getKnownTravelerNumbers()
      .map((ktn) => ({
        expirationDate: ktn.expirationDate.value,
        country: ktn.country.code,
        number: ktn.number,
        preExpirationAlert: ktn.preExpirationAlert,
      }))

    const additionalDocumentsPreferencesInput = {
      knownTravelerNumbers,
      redressNumbers: filteredRedressNumbers,
    }

    await this.baseSettingsService.saveUser({
      mode: 'additional-documents-preferences',
      input: additionalDocumentsPreferencesInput,
      handleClose: this.handleRedressNumberClose,
    })
  }

  async handleRemoveKnownTravelerNumber() {
    const { knownTravelerNumberValidator, removeToggle } = this.travelDocumentsStore

    if (!knownTravelerNumberValidator.values.id) {
      return
    }

    removeToggle.handleClose()

    const redressNumbers = this.travelDocumentsService.getRedressNumbers().map((redress) => ({
      number: redress.number,
      country: redress.country.code,
    }))

    const filteredKnownTravelerNumbers = this.travelDocumentsService
      .getKnownTravelerNumbers()
      .filter((ktn) => String(ktn.id) !== String(knownTravelerNumberValidator.values.id))
      .map((ktn) => ({
        expirationDate: ktn.expirationDate.value,
        country: ktn.country.code,
        number: ktn.number,
        preExpirationAlert: ktn.preExpirationAlert,
      }))

    const additionalDocumentsPreferencesInput = {
      knownTravelerNumbers: filteredKnownTravelerNumbers,
      redressNumbers,
    }

    await this.baseSettingsService.saveUser({
      mode: 'additional-documents-preferences',
      input: additionalDocumentsPreferencesInput,
      handleClose: this.handleKnownTravelerNumberClose,
    })
  }

  handlePassportNumberFocusClear() {
    if (
      this.travelDocumentsStore.isPassportNumberTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handlePassportFieldChange('number', '')
    this.travelDocumentsStore.setIsPassportNumberTouched(true)
  }

  handleVisaNumberFocusClear() {
    if (
      this.travelDocumentsStore.isVisaNumberTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handleVisaFieldChange('number', '')
    this.travelDocumentsStore.setIsVisaNumberTouched(true)
  }

  handleNationalIdNumberFocusClear() {
    if (
      this.travelDocumentsStore.isNationalIdNumberTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handleNationalIdCardFieldChange('number', '')
    this.travelDocumentsStore.setIsNationalIdNumberTouched(true)
  }

  handleRedressNumberFocusClear() {
    if (
      this.travelDocumentsStore.isRedressNumberTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handleRedressNumberFieldChange('number', '')
    this.travelDocumentsStore.setIsRedressNumberTouched(true)
  }

  handleKnownTravelerNumberFocusClear() {
    if (
      this.travelDocumentsStore.isKnownTravelerNumberTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handleKnownTravelerNumberFieldChange('number', '')
    this.travelDocumentsStore.setIsKnownTravelerNumberTouched(true)
  }

  handleNationalIdCardExpirationDate(value?: Date) {
    const date = value ? format(value, 'LLL d y') : ''
    this.handleNationalIdCardFieldChange('expirationDate', date)
  }

  handleNationalIdCardIssueDate(value?: Date) {
    const date = value ? format(value, 'LLL d y') : ''
    this.handleNationalIdCardFieldChange('issueDate', date)
  }

  private convertDate(date: string) {
    if (!date) {
      return ''
    }

    const [month, day, year] = date.split(' ')
    return `${year}-${this.getExpirationMonth(month)}-${day}`
  }

  private getExpirationMonth(month?: string) {
    switch (month) {
      case 'Jan':
        return 1
      case 'Feb':
        return 2
      case 'Mar':
        return 3
      case 'Apr':
        return 4
      case 'May':
        return 5
      case 'Jun':
        return 6
      case 'Jul':
        return 7
      case 'Aug':
        return 8
      case 'Sep':
        return 9
      case 'Oct':
        return 10
      case 'Nov':
        return 11
      case 'Dec':
        return 12
      default:
        return 12
    }
  }
}
