import type moment from 'moment'
import { useRef, useState } from 'react'
import { useInfiniteScroll } from './use-infinite-scroll'
import { useInitialRender } from './use-initial-render'
import { useScrollToSelected } from './use-scroll-to-selected'

type Props = {
  startDateValue: moment.Moment | null
}

export function useCalendarRender({ startDateValue }: Props) {
  const [numberOfMonth, setNumberOfMonth] = useState(startDateValue ? 1 : 0)
  const container = useRef<HTMLDivElement>(null)

  const handleChangeMonth = (value: number) => setNumberOfMonth(value)

  const calendarContainer = document.querySelector('.DayPicker_transitionContainer')
  const prevButton = document.querySelector(
    '.DayPickerNavigation_prevButton__verticalDefault_5',
  ) as HTMLDivElement
  const nextButton = document.querySelector(
    '.DayPickerNavigation_nextButton__verticalDefault_5',
  ) as HTMLDivElement

  const selectedDate = document.querySelector<HTMLDivElement>('.CalendarDay__selected_start')

  useInitialRender({ startDateValue, handleChangeMonth })
  const onPrevButtonClick = () => prevButton.click()

  useScrollToSelected({
    prevButton,
    startDateValue,
    handleChangeMonth,
    onPrevButtonClick,
    scrollToSelected: () => selectedDate?.closest('.CalendarMonth')?.scrollIntoView(),
  })

  useInfiniteScroll({
    calendarContainer,
    nextButton,
    container,
    onPrevButtonClick,
    onNextButtonClick: () => nextButton.click(),
  })

  return { numberOfMonth, container }
}
