import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const MobileBackButton = styled.button`
  width: 44px;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  background: transparent;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  margin-right: 8px;

  & > div {
    width: 16px;
    height: 16px;
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${screenSize.minMobile}) {
    position: absolute;
  }
`
