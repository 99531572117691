import type { PropsWithChildren } from 'react'
import { Modal } from '@etta/ui/modal'
import { useCovidInformation } from '../../../use-covid-information'
import type { ModalProps } from '../../types'

export function CovidInformationModalLayoutMobile({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<ModalProps>) {
  const { getTranslation, i18BaseHeader } = useCovidInformation()

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Title aria-label={getTranslation('.Title', i18BaseHeader)} withBorder>
        {getTranslation('.Title', i18BaseHeader)}
      </Modal.Title>
      {children}
    </Modal>
  )
}
