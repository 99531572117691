import { Action, Inject } from '@etta/di'

import { SiteSsoSettingsService } from '../services/site-sso-settings.service'

@Action()
export class SiteSsoSettingsActions {
  constructor(@Inject() private siteSsoSettingsService: SiteSsoSettingsService) {}

  async fetchSiteSsoSettings() {
    await this.siteSsoSettingsService.fetchSsoSettings()
  }
}
