import type { ChangeEvent, ReactNode } from 'react'
import { KeyType } from '@fiji/enums'
import { useKeyboardControl } from '@fiji/hooks/use-keyboard-control'
import {
  Container,
  Label,
  Input,
  IconContainer,
  Text,
  HelperContainer,
  Minus,
} from './checkbox-styled'

import { Mark } from './mark'

import type { CheckboxHelperType } from './types'

export type CheckBoxProps = {
  value: string
  label: ReactNode
  checked: boolean
  onChange: (value: boolean) => void
  mixed?: boolean
  className?: string
  helperText?: string
  helperType?: CheckboxHelperType
  isHelperTextSpaceReserved?: boolean
  'data-tracking-id'?: string
  'aria-label'?: string
  isDisabled?: boolean
}

export function Checkbox({
  label,
  checked,
  onChange,
  value,
  mixed,
  className = '',
  helperText,
  helperType,
  isHelperTextSpaceReserved,
  'data-tracking-id': dataTrackingId,
  'aria-label': ariaLabel,
  isDisabled = false,
}: CheckBoxProps) {
  const mixedClass = mixed ? 'mixed' : ''
  const classNameString = `${className} ${mixedClass}`
  const isError = helperType === 'error' && !!helperText

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  const { onKeyUp } = useKeyboardControl({ onPress: handleChange, keyType: KeyType.Click })

  const isFilled = mixed || checked
  return (
    <Container data-tracking-id={dataTrackingId} className={classNameString}>
      <Label tabIndex={-1}>
        <Input
          tabIndex={-1}
          checked={checked}
          type="checkbox"
          value={value}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          disabled={isDisabled}
        />
        <IconContainer
          tabIndex={isDisabled ? -1 : 0}
          onKeyUp={onKeyUp}
          isFilled={isFilled}
          isDisabled={isDisabled}>
          {mixed && <Minus />}
          {!mixed && checked && <Mark />}
        </IconContainer>
        <Text variant="subHeadMedium" color="mainText" aria-label={ariaLabel}>
          {label}
        </Text>
      </Label>

      <HelperContainer
        helperType={helperType}
        role={isError ? 'alert' : 'none'}
        isHelperTextSpaceReserved={isHelperTextSpaceReserved}>
        {helperText}
      </HelperContainer>
    </Container>
  )
}
