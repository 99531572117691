import type { LoyaltyProgram } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import type { AirMembershipValue } from '@etta/modules/booking/core/value-objects/checkout-info.value-object'

type FlightLoyaltyProps = {
  segments: TripSegmentValueObject[]
  airMemberships: AirMembershipValue[] | null
}

export function composeFlightLoyalty({
  segments,
  airMemberships,
}: FlightLoyaltyProps): LoyaltyProgram[] | null {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  if (!segments) {
    return null
  }

  const flightSegments = segments
    .filter(typeCheck.isFlightSegment)
    .flatMap((segment) => segment.segments)
  const vendorsInTrip = flightSegments
    .map((segment) => segment?.carrierCode)
    .filter(Boolean) as string[]

  const uniqueVendors = Array.from(new Set(vendorsInTrip))

  const loyaltyProgramDecoded = uniqueVendors.flatMap((newVendor) => {
    const existingMembership = airMemberships?.reduce<LoyaltyProgram[]>((acc, member) => {
      if (newVendor === member.carrierCode) {
        acc.push({
          vendorCode: member.carrierCode,
          number: member.number,
          label: member.carrierName,
        })
      }
      return acc
    }, [])

    return existingMembership?.length
      ? existingMembership
      : {
          vendorCode: newVendor,
          number: null,
        }
  })

  return loyaltyProgramDecoded
}
