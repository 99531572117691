import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { FlightPreferences } from './flight-preferences'
import type { FlightPreferencesProps } from './types'

type Props = FlightPreferencesProps & {
  isOpen: boolean
}

export function FlightPreferencesModal({ isOpen, onClose, ...props }: Props) {
  return (
    <CheckoutPageModal isVisible={isOpen} onClose={onClose}>
      <FlightPreferences {...props} onClose={onClose} />
    </CheckoutPageModal>
  )
}
