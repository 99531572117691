import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { slideUp } from './transition'

export const SheetContainer = styled.div<{
  isShown: boolean
}>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  z-index: 22;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
`

export const SheetContainerRoot = styled.div`
  z-index: 50;
  top: -1;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  ${slideUp};
`

export const SheetContent = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
`

export const MiniPill = styled.div`
  border-radius: 50rem;
  border: 2px solid ${(p) => p.theme.colors.borderDark};
  height: 1px;
  width: 36px;
  margin-top: 5px;
  margin-bottom: 10px;
`

export const HeaderContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SubHeaderContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  display: flex;
`

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const NextButtonWrapper = styled(Button)`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 90%;
`

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const CalendarContent = styled.div`
  display: flex;
  flex-direction: row;
`

export const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
export const IconButton = styled.div`
  cursor: pointer;
`

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`
