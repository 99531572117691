import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { RailCards } from '@etta/components/rail-search-form/rail-cards/rail-cards'
import { RailLayoutType } from '@fiji/enums'
import type { Props } from '../../types'
import { RailTypeItem, LeftSide, RightSide, WrapperNew } from './tabs-section-desktop-styled'

const i18Base = 'TripPlanner.BaseSearch'

export const TabsSectionDesktop = observer(function TabsSectionDesktop({ isDisabled }: Props) {
  const { railSearchFormActions, railSearchFormStore } = useRailSearchFormContext()
  const { railTripType, railCards } = railSearchFormStore.railForm
  const { t } = useTranslation()
  const oneWayTitle = t(i18Base + '.Input.OneWay')
  const roundTripTitle = t(i18Base + '.Input.RoundTrip')
  const openReturnTitle = t(i18Base + '.Input.OpenReturn')

  const getSectionTitle = useCallback(
    (railTripType?: RailTripType) => {
      switch (railTripType) {
        case RailTripType.OpenReturn:
          return openReturnTitle
        case RailTripType.OneWay:
          return oneWayTitle
        case RailTripType.Round:
        default:
          return roundTripTitle
      }
    },
    [oneWayTitle, openReturnTitle, roundTripTitle],
  )

  if (isDisabled) {
    return (
      <WrapperNew>
        <RailTypeItem isActive>{getSectionTitle(railTripType)}</RailTypeItem>
      </WrapperNew>
    )
  }

  return (
    <WrapperNew>
      <LeftSide>
        <RailTypeItem
          aria-label={
            railTripType === RailTripType.Round
              ? t(i18Base + '.Input.Selected', { label: roundTripTitle })
              : t(i18Base + '.Input.PlaceAriaLabel', { label: roundTripTitle })
          }
          onClick={() => railSearchFormActions.onTripTypeChange(RailTripType.Round)}
          isActive={railTripType === RailTripType.Round}>
          {roundTripTitle}
        </RailTypeItem>
        <RailTypeItem
          aria-label={
            railTripType === RailTripType.OneWay
              ? t(i18Base + '.Input.Selected', { label: oneWayTitle })
              : t(i18Base + '.Input.PlaceAriaLabel', { label: oneWayTitle })
          }
          onClick={() => railSearchFormActions.onTripTypeChange(RailTripType.OneWay)}
          isActive={railTripType === RailTripType.OneWay}>
          {oneWayTitle}
        </RailTypeItem>
        <RailTypeItem
          aria-label={
            railTripType === RailTripType.OpenReturn
              ? t(i18Base + '.Input.Selected', { label: openReturnTitle })
              : t(i18Base + '.Input.PlaceAriaLabel', { label: openReturnTitle })
          }
          onClick={() => railSearchFormActions.onTripTypeChange(RailTripType.OpenReturn)}
          isActive={railTripType === RailTripType.OpenReturn}>
          {openReturnTitle}
        </RailTypeItem>
      </LeftSide>
      <RightSide>
        <RailCards railCards={railCards} layout={RailLayoutType.UK} />
      </RightSide>
    </WrapperNew>
  )
})
