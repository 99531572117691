import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import type { DynamicSiteMessage } from '@fiji/graphql/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import type { Alert } from '../types'
import { useStateView } from './use-state-view'

type Args = {
  initialMessages: DynamicSiteMessage[]
  initialAlerts: Alert[]
  withMobileTextIfExist: boolean
}

export function useItemsToShow({ initialMessages, initialAlerts, withMobileTextIfExist }: Args) {
  const { dynamicSiteMessagesAction } = useDynamicSiteMessagesContext()
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  // TODO: when the logic of displaying mobile messages in DSM will be needed everywhere,
  // we can remove the props `withMobileTextIfExist` and the line below
  const isUseMobileText = isMobile && withMobileTextIfExist
  const initialMessagesState = dynamicSiteMessagesAction.mapMessages(
    initialMessages,
    isUseMobileText,
  )
  const initialAlertsState = dynamicSiteMessagesAction.mapAlerts(initialAlerts)

  const initialMessagesIdList = initialMessagesState.map(({ id }) => id)
  const initialAlertsIdList = initialAlertsState.map(({ id }) => id)

  const { idList, handleRemoveItem } = useStateView({
    initialState: [...initialMessagesIdList, ...initialAlertsIdList],
  })

  const messagesToShow = initialMessagesState.filter(
    ({ id, isDialog }) => idList.has(id) && !isDialog,
  )
  const alertsToShow = initialAlertsState.filter(({ id }) => idList.has(id))

  return {
    messagesToShow,
    alertsToShow,
    handleRemoveItem,
  }
}
