import { useTranslation } from 'react-i18next'
import type { ViewState } from '@etta/ui/view-state-modal'

export const useDeleteHotelTitle = (removeHotelStateStatus: ViewState): string => {
  const { t } = useTranslation()

  const i18Base = 'HotelDetailsModal'

  if (removeHotelStateStatus === 'saving') {
    return t(i18Base + '.RemovingHotel')
  }

  return t(i18Base + '.RemovedHotel')
}
