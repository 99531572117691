import styled from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Wrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  padding: ${(p) => (p.isLoading ? '24px' : '40px')} 0 0px 24px;
`

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
`
export const CarImage = styled.img`
  width: 90%;
  height: 90%;
`
export const CarVendorImageWrapper = styled.div`
  position: absolute;
  top: 24px;
  width: 74px;
  height: 30px;
  display: flex;
  align-items: center;
`
export const CarVendorImage = styled.img`
  width: 100%;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const InfoBody = styled.div<{ isLoading: boolean }>`
  padding: ${({ isLoading }) => (isLoading ? '27px' : '24px')} 0 21px;
`

export const InfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin: 11px 0 4px;
`

export const MoreLink = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  ${KeyboardNavigationStyle}
`

export const SkeletonWrapper = styled.div`
  margin-top: 16px;
`

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
