import { Inject } from 'typedi'
import { Service } from '@etta/di'
import { GetCustomerUberAccountStatusStore } from '@etta/modules/ride-hail/interface/store/get-customer-uber-account-status.store'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { CustomerUberAccountManagementAdapter } from '../../infra/customer-uber-account-management/customer-uber-account-management.adapter'

@Service()
export class GetCustomerUberAccountStatusService {
  constructor(
    @Inject() private customerUberAccountManagementAdapter: CustomerUberAccountManagementAdapter,
    @Inject() private getCustomerUberAccountStatusStore: GetCustomerUberAccountStatusStore,
  ) {}

  async getCustomerUberAccountStatus() {
    const result = await this.customerUberAccountManagementAdapter.getCustomerUberAccountStatus()
    result.match({
      Ok: (accountStatusResult) => {
        this.getCustomerUberAccountStatusStore.setIsError(false)
        this.getCustomerUberAccountStatusStore.setErrorMessage('')
        this.getCustomerUberAccountStatusStore.setLinkingUrl(accountStatusResult.linkingUrl)
        this.getCustomerUberAccountStatusStore.setStatus(accountStatusResult.status)
        this.getCustomerUberAccountStatusStore.setIsUserAccountSelfCreationEnabled(
          accountStatusResult.isUserAccountSelfCreationEnabled,
        )
      },
      Err: (err) => {
        this.getCustomerUberAccountStatusStore.setLinkingUrl('')
        this.getCustomerUberAccountStatusStore.setStatus(UberAccountStatusEnum.Unspecified)
        this.getCustomerUberAccountStatusStore.setIsUserAccountSelfCreationEnabled(false)

        this.getCustomerUberAccountStatusStore.setIsError(true)
        this.getCustomerUberAccountStatusStore.setErrorMessage(err.error)
      },
    })
  }
}
