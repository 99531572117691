import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { DraggableBottomSheet } from '@etta/ui/draggable-bottom-sheet'
import type { SearchRideHailResult } from '@fiji/graphql/hooks'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { TRANSITION_NAME } from '@etta/components/mobility-search-result-dialog/transition'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { useMultiModalSearchContext } from '@etta/modules/multi-modal-search'
import { MobilitySearchResultErrorType } from '@etta/components/mobility-search-result-dialog/mobility-search-result-error'
import { MultiModalSearchResults } from '@etta/modules/multi-modal-search/ui/multi-modal-search/multi-modal-search-results'
import { Expand } from './transition/expand'
import {
  SheetContainer,
  SheetContent,
  SearchLocationCard,
  IconWrapper,
  VerticalDivider,
  SearchCriteriaContainer,
  SearchTitleSection,
  TitleText,
  LocationSection,
  PickUpText,
  DropOffText,
  ArrowIconWrapper,
  CardList,
  ScrollContainer,
  ScrollContent,
  ArrivalFlightCard,
  ReserveAirportPickupInfoModule,
  ViewMoreRideContainer,
  HeaderLocation,
} from './mobility-search-result-dialog-styled'
import { MobilitySearchResultCard } from './mobility-search-result-card'
import { MobilitySearchResultSkeleton } from './mobility-search-result-skeleton'
import { MobilitySearchResultError } from './mobility-search-result-error/mobility-search-result-error'
import { MobilitySearchResultReserveAirportPickupInfoDialog } from './mobility-search-result-reserve-airport-pickup-info-dialog/mobility-search-result-reserve-airport-pickup-info-dialog'
import { useTrackMobilitySearchResult } from './hooks'
import { useMobilitySearchResultDialog } from './use-mobility-search-result-dialog'

type Props = {
  onCardClick: (searchRideHail: SearchRideHailResult) => void
  isOpen: boolean
  containerRef?: (node: HTMLDivElement) => void
  handleOnEntered: () => void
  handleEditSearchClick: () => void
}

const i18nBase = 'Mobility.SearchResultPage'

export const MobilitySearchResultDialog = observer(function MobilitySearchResultDialog({
  onCardClick,
  isOpen,
  containerRef,
  handleOnEntered,
  handleEditSearchClick,
}: Props) {
  const { t } = useTranslation()
  const {
    refetch,
    pickUpName,
    formatedPickUpTime,
    dropOffName,
    shouldShowArrivalFlight,
    complexPickUpType,
    isOnDemand,
    arrivalFlightLabel,
    isExpandedListRides,
    handleToggleViewMoreRides,
  } = useMobilitySearchResultDialog()

  const { searchRideHailStore } = useRideHailSearchContext()
  const {
    sortedSearchRideHailResults: searchResults,
    isLoading,
    searchRideHailError,
  } = searchRideHailStore

  const { multiModalSearchStore } = useMultiModalSearchContext()
  const { isLoading: multiModalIsLoading } = multiModalSearchStore

  const { featureFlags } = useFeatureFlags()

  useTrackMobilitySearchResult({
    isOpen,
    complexPickUpType,
    searchResultError: searchRideHailError,
  })

  const {
    handleOpen: handleOpenReserveAirportPickupInfoDialog,
    handleClose: handleCloseReserveAirportPickupInfoDialog,
    isOpen: isReserveAirportPickupInfoDialogOpen,
  } = useTogglePopup()

  const toShownRides = isExpandedListRides ? searchResults : searchResults.slice(0, 1)

  const expandedRef = useRef<HTMLDivElement | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const handleSheetHeightChange = (transitionProgressInPercent: number) => {
    if (expandedRef.current) {
      if (transitionProgressInPercent === 100) {
        expandedRef.current.style.height = `0px`
      }
      if (transitionProgressInPercent === 0) {
        expandedRef.current.style.height = `100%`
      }
      expandedRef.current.style.opacity = `${1 - transitionProgressInPercent / 100}`
    }
  }

  return (
    <SheetContainer ref={containerRef}>
      <DialogAnimation
        isOpen={isOpen}
        onEntered={handleOnEntered}
        onExited={() => {}}
        isAnimated
        transitionName={TRANSITION_NAME}>
        <SheetContent>
          <Swap
            is={!R.isNil(searchRideHailError) && !isLoading}
            isSlot={
              <ScrollContainer>
                <ScrollContent>
                  <MobilitySearchResultError
                    type={searchRideHailError ?? MobilitySearchResultErrorType.Undefined}
                    onRetry={refetch}
                    onEditSearch={handleEditSearchClick}
                    uberQrcodeTitle={t(`${i18nBase}.ScanQRWhenLocationUnavailable`)}
                  />
                </ScrollContent>
              </ScrollContainer>
            }>
            <DraggableBottomSheet
              scrollContainerRef={scrollContainerRef}
              onHeaderCenterSlotClick={handleEditSearchClick}
              onSheetHeightChange={handleSheetHeightChange}
              headerCenterSlot={
                <>
                  <HeaderLocation>
                    <PickUpText isHeader>{pickUpName}</PickUpText>
                    <ArrowIconWrapper>
                      <Icon name="flightArrowPWA" size={'small'} color={'mainText1'} />
                    </ArrowIconWrapper>
                    <DropOffText isHeader>{dropOffName}</DropOffText>
                  </HeaderLocation>
                  <Text variant="footnoteMedium" color="bodyText">
                    {formatedPickUpTime}
                  </Text>
                </>
              }
              contentSlot={
                <ScrollContainer ref={scrollContainerRef}>
                  <ScrollContent>
                    <Expand
                      expandedRef={expandedRef}
                      collapsedSlot={<></>}
                      expandedSlot={
                        <>
                          <SearchLocationCard onClick={handleEditSearchClick}>
                            <IconWrapper>
                              <Icon name="locationPWA" size="medium" color="primary" />
                            </IconWrapper>
                            <VerticalDivider />
                            <SearchCriteriaContainer>
                              <LocationSection>
                                <PickUpText>{pickUpName}</PickUpText>
                                <ArrowIconWrapper>
                                  <Icon name="flightArrowPWA" color="primary1" size="small" />
                                </ArrowIconWrapper>
                                <DropOffText>{dropOffName}</DropOffText>
                              </LocationSection>
                              <Text
                                variant="footnoteMedium"
                                color="bodyText"
                                textOverflow="ellipsis">
                                {t(`${i18nBase}.PickUpLabel`)}: {formatedPickUpTime}
                              </Text>
                            </SearchCriteriaContainer>
                          </SearchLocationCard>
                          {shouldShowArrivalFlight && (
                            <>
                              <ArrivalFlightCard>
                                <Icon name="flightPWA" size="normal" color="mainText" />
                                <Block marginLeft={8}>
                                  <Text variant="footnoteStrong" color="mainText1">
                                    {arrivalFlightLabel}
                                  </Text>
                                </Block>
                              </ArrivalFlightCard>
                              <ReserveAirportPickupInfoModule
                                onClick={handleOpenReserveAirportPickupInfoDialog}>
                                <Icon name="infoOutlinePWA" color="mainText" size="small" />
                                <Block paddingLeft={6} paddingRight={16}>
                                  <Text variant="footnoteMedium" color="mainText">
                                    {t(`${i18nBase}.ReserveAirportPickupInfo`)}
                                    <Text variant="footnoteStrong" color="primary">
                                      {t(`${i18nBase}.Why`)}
                                    </Text>
                                  </Text>
                                </Block>
                              </ReserveAirportPickupInfoModule>
                            </>
                          )}
                          <MobilitySearchResultReserveAirportPickupInfoDialog
                            onClose={handleCloseReserveAirportPickupInfoDialog}
                            isOpen={isReserveAirportPickupInfoDialogOpen}
                          />
                        </>
                      }
                    />
                    <SearchTitleSection>
                      <TitleText>
                        {t(
                          `${i18nBase}${
                            featureFlags.isMobilityMultiModalEnabled && !isExpandedListRides
                              ? `.MultiModal.Options`
                              : `.AvailableRides`
                          }`,
                        )}
                      </TitleText>
                    </SearchTitleSection>
                    <CardList>
                      <Swap
                        is={isLoading || multiModalIsLoading}
                        isSlot={
                          <>
                            <MobilitySearchResultSkeleton />
                            <MobilitySearchResultSkeleton />
                          </>
                        }>
                        {featureFlags.isMobilityMultiModalEnabled && (
                          <>
                            {toShownRides.map((searchResult) => (
                              <MobilitySearchResultCard
                                key={searchResult.id}
                                isOnDemand={isOnDemand}
                                searchResult={searchResult}
                                onCardClick={onCardClick}
                              />
                            ))}
                            <ViewMoreRideContainer onClick={handleToggleViewMoreRides}>
                              <Text variant="linkSmall" color="mainText1" textOverflow="ellipsis">
                                {t(
                                  `${i18nBase}${
                                    isExpandedListRides
                                      ? `.MultiModal.ViewLessRidesButton`
                                      : `.MultiModal.ViewMoreRidesButton`
                                  }`,
                                )}
                              </Text>
                              <Block marginLeft={4} isFlexBlock>
                                <Icon
                                  name={isExpandedListRides ? 'chevronUpPWA' : 'chevronDownPWA'}
                                  color="mainText1"
                                  size={18}
                                />
                              </Block>
                            </ViewMoreRideContainer>
                            <MultiModalSearchResults />
                          </>
                        )}
                        {!featureFlags.isMobilityMultiModalEnabled && (
                          <>
                            {searchResults?.map((searchResult) => {
                              return (
                                <MobilitySearchResultCard
                                  key={searchResult.id}
                                  isOnDemand={isOnDemand}
                                  searchResult={searchResult}
                                  onCardClick={onCardClick}
                                />
                              )
                            })}
                          </>
                        )}
                      </Swap>
                    </CardList>
                  </ScrollContent>
                </ScrollContainer>
              }
            />
          </Swap>
        </SheetContent>
      </DialogAnimation>
    </SheetContainer>
  )
})
