import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background: ${({ theme }) => theme.colors.error1};
  }
`

export const Wrapper = styled.div`
  display: flex;
  padding: 16px 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
