import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { useNavigationContext } from '@etta/modules/navigation/interface/use-navigation-context'
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UserInfoText,
  MenuItem,
  UnusedBadge,
} from './desktop-navigation-modal-styled'

type Props = {
  isModalOpen: boolean
  isDelegateSwitchEnabled?: boolean
  isSelfServiceSite: boolean
  currentUser: {
    fullName: string
    email?: string | null
  }
  unusedTicketsCount?: number
  hideUnusedTickets: boolean
  handleLogout: () => void
  handleOpenUnusedModal: () => void
  handleNavigateProfile: () => void
  handleOpenDelegateModal: () => void
}

const i18nBase = 'TripPlanner.MainNavigation.'

export function DesktopNavigationModal({
  isModalOpen,
  currentUser,
  isDelegateSwitchEnabled,
  isSelfServiceSite,
  hideUnusedTickets,
  unusedTicketsCount,
  handleLogout,
  handleOpenUnusedModal,
  handleNavigateProfile,
  handleOpenDelegateModal,
}: Props) {
  const { t } = useTranslation()
  const { ettaGoAction } = useNavigationContext()
  const { handleSetRcSessionCookieForAdminPanel } = ettaGoAction

  if (!isModalOpen) {
    return null
  }

  return (
    <ModalContainer>
      <ModalHeader>
        <UserInfoText variant="headline" color="mainText">
          {currentUser.fullName}
        </UserInfoText>
        <Text
          variant="subHeadMedium"
          color="bodyText"
          title={currentUser.email || undefined}
          textOverflow="ellipsis"
          noWrap>
          {currentUser.email}
        </Text>
      </ModalHeader>
      <ModalBody>
        {isSelfServiceSite && (
          <MenuItem onClick={handleSetRcSessionCookieForAdminPanel}>
            <Text variant="subHeadMedium" color="mainText">
              {t(i18nBase + 'AdminAccess')}
            </Text>
          </MenuItem>
        )}
        <MenuItem onClick={handleNavigateProfile}>
          <Text variant="subHeadMedium" color="mainText">
            {t(i18nBase + 'Profile')}
          </Text>
        </MenuItem>
        {hideUnusedTickets ? null : (
          <MenuItem onClick={handleOpenUnusedModal}>
            <Text variant="subHeadMedium" color="mainText">
              {t('Profile.UnusedTickets')}
            </Text>
            <UnusedBadge>{unusedTicketsCount}</UnusedBadge>
          </MenuItem>
        )}
        {isDelegateSwitchEnabled && (
          <MenuItem onClick={handleOpenDelegateModal}>
            <Text variant="subHeadMedium" color="mainText">
              {t(i18nBase + 'DelegateSwitch')}
            </Text>
          </MenuItem>
        )}
      </ModalBody>
      <ModalFooter>
        <MenuItem onClick={handleLogout}>
          <Text variant="subHeadMedium" color="error">
            {t('Profile.SignOut')}
          </Text>
        </MenuItem>
      </ModalFooter>
    </ModalContainer>
  )
}
