import { useCallback, useState } from 'react'

export function useMobilityDialogRef() {
  const [dialogHeight, setDialogHeight] = useState(0)
  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setDialogHeight(node.clientHeight)
    }
  }, [])

  return {
    dialogHeight,
    ref,
  }
}
