import styled from 'styled-components'

export const Card = styled.div``

export const Container = styled.div`
  margin: 0 0 16px;
  border-radius: 14px;
  background: ${(props) => props.theme.colors.white};
`

export const Body = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  display: flex;
`

export const Footer = styled.div`
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ImageContainer = styled.div`
  margin: 0 12px 0 0;
  width: 64px;
  min-width: 64px;
  height: 64px;
  overflow: hidden;
`

export const Image = styled.img`
  width: 64px;
  height: 64px;
`

export const Content = styled.div``

export const CarName = styled.div``

export const CarInfo = styled.div``

export const RideDetail = styled.div`
  display: flex;
`

export const CarPassengers = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`
export const PickUpTime = styled.div`
  display: flex;
  align-items: center;
`

export const Price = styled.div`
  padding-left: 6px;
`
