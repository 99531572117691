export const size = {
  minMobileSmall: '320px',
  minMobile: '480px',
  minTablet: '959px',
  minTabletMd: '960px',
  minLaptop: '1024px',
  minDesktop: '1200px',
  minDesktopSm: '1367px',
  minDesktopMd: '1440px',
  minDesktopLg: '1600px',

  maxMobileSmall: '479px',
  maxMobile: '958px',
  maxTablet: '1023px',
  maxLaptop: '1199px',
  maxDesktop: '1439px',
  maxDesktopSm: '1366px',
  maxDesktopMd: '1599px',
}

export const screenSize = {
  minMobileSmall: `min-width: ${size.minMobileSmall}`,
  minMobile: `min-width: ${size.minMobile}`,
  minTablet: `min-width: ${size.minTablet}`,
  minLaptop: `min-width: ${size.minLaptop}`,
  minDesktop: `min-width: ${size.minDesktop}`,
  maxMobileSmall: `max-width: ${size.maxMobileSmall}`,
  maxMobile: `max-width: ${size.maxMobile}`,
  maxTablet: `max-width: ${size.maxTablet}`,
  maxLaptop: `max-width: ${size.maxLaptop}`,
  minDesktopSm: `max-width: ${size.minDesktopSm}`,
  isTablet: `(min-width: ${size.minTablet}) and (max-width: ${size.maxTablet})`,
  isDesktopSm: `(min-width: ${size.minLaptop}) and (max-width: ${size.maxDesktop})`,
  isDesktopMd: `(min-width: ${size.minDesktopMd}) and (max-width: ${size.maxDesktopMd})`,
  isDesktopLg: `(min-width: ${size.minDesktopLg})`,
}
