import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { RailDetailsEcoCheckModalDesktop } from './desktop'
import { RailDetailsEcoCheckModalMobile } from './mobile'

export function RailDetailsEcoCheckModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      desktopSlot={<RailDetailsEcoCheckModalDesktop {...props} />}
      mobileSlot={<RailDetailsEcoCheckModalMobile {...props} />}
    />
  )
}
