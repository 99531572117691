import * as yup from 'yup'
import i18n from 'i18next'
import type { Props } from '../types'
import { getBaseScheme } from './get-base-scheme'

const i18nBase = 'AdditionalInfoForm.'

export const getCheckboxScheme = ({ customField }: Props) => {
  let yupScheme = yup.string()
  if (customField.checkBoxDisplay?.isCheckedValueRequired) {
    yupScheme = yupScheme.test('proceedBlock', i18n.t(`${i18nBase}Errors.Proceed`), (value) => {
      return value !== 'false'
    })
  }

  return getBaseScheme({
    scheme: yupScheme,
    customField,
    defaultValue: customField.defaultValue === 'true' || customField.checkBoxDisplay?.isChecked,
  })
}
