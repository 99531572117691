import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { PriceWrapperTwoRows } from './price-styled'

type Props = {
  price: string
  hasAverage: boolean
}

const i18Base = 'HotelInfo.Rooms'

export function TwoRowSkin({ price, hasAverage }: Props) {
  const { t } = useTranslation()

  return (
    <PriceWrapperTwoRows>
      <Text variant="headline" color="mainText" aria-label={price} align="right">
        {price}
      </Text>
      {hasAverage && (
        <Text variant="captionMedium" color="mainText" aria-label={t(i18Base + '.AveragePerNight')}>
          {t(i18Base + '.AveragePerNight')}
        </Text>
      )}
    </PriceWrapperTwoRows>
  )
}
