import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  UpdateAirTravelPreferenceDocument,
  UpdateCarRentalTravelPreferenceDocument,
  UpdateHotelTravelPreferenceDocument,
} from '@fiji/graphql/hooks'
import type {
  UpdateAirTravelPreferenceMutation,
  UpdateAirTravelPreferenceMutationVariables,
  UpdateCarRentalTravelPreferenceMutation,
  UpdateCarRentalTravelPreferenceMutationVariables,
  UpdateHotelTravelPreferenceMutation,
  UpdateHotelTravelPreferenceMutationVariables,
} from '@fiji/graphql/hooks'

@DataProvider()
export class TravelPreferencesDataProvider extends GraphqlDataProvider {
  updateAirTravelPreferences(input: UpdateAirTravelPreferenceMutationVariables['input']) {
    return this.client.mutate<
      UpdateAirTravelPreferenceMutation,
      UpdateAirTravelPreferenceMutationVariables
    >({
      mutation: UpdateAirTravelPreferenceDocument,
      variables: {
        input,
      },
    })
  }

  updateCarRentalTravelPreferences(
    input: UpdateCarRentalTravelPreferenceMutationVariables['input'],
  ) {
    return this.client.mutate<
      UpdateCarRentalTravelPreferenceMutation,
      UpdateCarRentalTravelPreferenceMutationVariables
    >({
      mutation: UpdateCarRentalTravelPreferenceDocument,
      variables: {
        input,
      },
    })
  }

  updateHotelTravelPreferences(input: UpdateHotelTravelPreferenceMutationVariables['input']) {
    return this.client.mutate<
      UpdateHotelTravelPreferenceMutation,
      UpdateHotelTravelPreferenceMutationVariables
    >({
      mutation: UpdateHotelTravelPreferenceDocument,
      variables: {
        input,
      },
    })
  }
}
