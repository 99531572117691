const links = {
  covidMap: 'https://coronavirus.jhu.edu/map.html',
  getHelp: 'https://covid19.geosureglobal.com/#info',
  donate: 'https://covid19.geosureglobal.com/#donate',
}

export function useCovidInformationDetails() {
  return {
    links,
  }
}
