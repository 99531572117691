import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { PersonalContactPhoneValueObject, GroupwareValueObject } from '@etta/modules/user'
import { ValidatorMaker } from '@etta/modules/settings/validators/validator-maker'
import { Validator } from '@etta/interface/services/validator'
import { AddressFields } from '../../core/enums/address-fields'
import { FieldSettingsStore } from './field-settings'

export type BusinessAddressInput = {
  [AddressFields.Country]: string
  [AddressFields.Zip]: string
  [AddressFields.Mailstop]: string
  [AddressFields.State]: string
  [AddressFields.City]: string
  [AddressFields.Street1]: string
  [AddressFields.Street2]: string
  businessEmail: string
  confirmationEmail: string
  groupwareId: GroupwareValueObject['groupwareId']
  officePhone: PersonalContactPhoneValueObject
  faxPhone: PersonalContactPhoneValueObject
  mobilePhone: PersonalContactPhoneValueObject
}

type PhoneFields =
  | 'mobilePhoneCountryCode'
  | 'mobilePhoneNumber'
  | 'faxPhoneCountryCode'
  | 'faxPhoneNumber'
  | 'officePhoneCountryCode'
  | 'officePhoneNumber'
  | 'officePhoneExtension'

@Store()
export class BusinessAddressStore {
  constructor(
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  private _additionalInformationValidator: Validator = new Validator({})

  isSaveButtonDisabled = true

  toggle = new Toggle()

  isConfEmailInputVisible = false

  businessAddressValidator = new ValidatorMaker(
    this.fieldSettingsStore,
  ).makeBusinessAddressValidator()

  setBusinessAddressValue<
    T extends keyof Omit<BusinessAddressInput, 'officePhone' | 'mobilePhone' | 'faxPhone'>,
  >(field: T, value: BusinessAddressInput[T]) {
    this.businessAddressValidator.onFieldChange(field, value)
  }

  get additionalInformationValidator() {
    return this._additionalInformationValidator
  }

  get isConfirmationEmailAvailable() {
    return !!this.businessAddressValidator.values.confirmationEmail
  }

  setBusinessPhoneValue(
    type: 'officePhone' | 'mobilePhone' | 'faxPhone',
    field: 'CountryCode' | 'Number' | 'Extension',
    value: string,
  ) {
    const phoneField = (type + field) as PhoneFields
    this.businessAddressValidator.onFieldChange(phoneField, value)
  }

  setBusinessAddress(input: BusinessAddressInput) {
    this.setBusinessAddressValue(AddressFields.Country, input[AddressFields.Country])
    this.setBusinessAddressValue(AddressFields.Zip, input[AddressFields.Zip])
    this.setBusinessAddressValue(AddressFields.State, input[AddressFields.State])
    this.setBusinessAddressValue(AddressFields.Mailstop, input[AddressFields.Mailstop])
    this.setBusinessAddressValue(AddressFields.City, input[AddressFields.City])
    this.setBusinessAddressValue(AddressFields.Street1, input[AddressFields.Street1])
    this.setBusinessAddressValue(AddressFields.Street2, input[AddressFields.Street2])

    this.setBusinessAddressValue('businessEmail', input.businessEmail)
    this.setBusinessAddressValue('confirmationEmail', input.confirmationEmail)

    this.setBusinessPhoneValue('officePhone', 'CountryCode', input.officePhone?.countryCode || '')
    this.setBusinessPhoneValue('officePhone', 'Number', input.officePhone?.number || '')
    this.setBusinessPhoneValue('officePhone', 'Extension', input.officePhone?.officeExtension || '')
    this.setBusinessPhoneValue('mobilePhone', 'CountryCode', input.mobilePhone?.countryCode || '')
    this.setBusinessPhoneValue('mobilePhone', 'Number', input.mobilePhone?.number || '')
    this.setBusinessPhoneValue('faxPhone', 'CountryCode', input.faxPhone?.countryCode || '')
    this.setBusinessPhoneValue('faxPhone', 'Number', input.faxPhone?.number || '')

    this.setBusinessAddressValue('groupwareId', input.groupwareId)
  }

  setAdditionalInfoValidator(val: Validator) {
    this._additionalInformationValidator = val
  }

  setIsConfEmailInputVisible(value: boolean) {
    this.isConfEmailInputVisible = value

    if (!value) {
      this.setBusinessAddressValue('confirmationEmail', '')
    }
  }

  setAdditionalInfoValue(fieldName: string, fieldValue: any) {
    this._additionalInformationValidator.onFieldChange(fieldName, fieldValue)
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
