import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import animation from './animation.json'
import { StyledLottieAnimation, AnimationContainer } from './delegate-modal-desktop-styled'

const i18Base = 'Delegate'

type Props = {
  isLoading: boolean
  withBackground?: boolean
}

export function DelegateModalDesktopDetailsLoader({ isLoading, withBackground = true }: Props) {
  const { t } = useTranslation()

  if (!isLoading) {
    return null
  }

  return (
    <AnimationContainer withBackground={withBackground}>
      <StyledLottieAnimation src={animation} autoplay loop speed={1} />
      <Text color="mainText" variant="subHeadStrong">
        {t(i18Base + '.SwitchingText')}
      </Text>
    </AnimationContainer>
  )
}
