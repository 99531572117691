import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import type { TripStatus } from '@etta/modules/post-booking/core/enums'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { HeaderColors } from '@etta/ui/header'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { GOOGLE_MAPS_URL } from '@fiji/constants'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import missingCarImageReplacement from '../../../car.png'
import { useLocations } from './use-locations'

type Params = {
  carRental: CarRentalPostBookingValueObject
  tripType: TripStatus
}

export function useTripCarRentalDesktop({ carRental, tripType }: Params) {
  const { t } = useTranslation()
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const locations = useLocations({ carRental })

  const headerColor: HeaderColors = getTripDetailsSegmentBackground({
    tripStatus: tripType,
    segmentStatus: carRental.status,
  })

  const carImageUrl = carRental?.carImageUrl || missingCarImageReplacement

  const { carRentalTitle } = translateCarNameDetails({
    carType: carRental.carType,
    carClass: carRental.carClass,
  })

  const onAddressClick = useCallback(() => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${locations?.geocode?.lat},${locations?.geocode?.lng}`)
  }, [locations])

  const closeIconAriaLabel = t('Accessibility.Header.CloseLabel')

  return {
    scrollContainerRef,
    headerRef,
    anchorRef,
    headerColor,
    carRentalTitle,
    carImageUrl,
    locations,
    onAddressClick,
    closeIconAriaLabel,
  }
}
