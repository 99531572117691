import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { ScreenType } from '@fiji/modes'
import { ControlsContainerDesktopStyled } from './dekstop'
import { ControlsContainerMobileStyled } from './mobile'

export function ControlsContainerLayout(
  props: PropsWithChildren<{ forceScreenType?: null | ScreenType }>,
) {
  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<ControlsContainerMobileStyled {...props} />}
      desktopSlot={<ControlsContainerDesktopStyled {...props} />}
    />
  )
}
