import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { FailedBookings } from '../../types'
import { Header, Wrapper } from './failed-segment-styled'
import { FailedSegmentBody } from './failed-segment-body'
import { UnconfirmedFailedSegment } from './unconfirmed-failed-segment'

type Variant = 'partial' | 'unconfirmed'

type Props = {
  failedBooks: FailedBookings
  variant: Variant
}

export function FailedSegment({ failedBooks, variant }: Props) {
  const { errorDescription, errorTitle, segmentTitle, segmentDescription, buttons } = failedBooks

  if (variant === 'unconfirmed') {
    return (
      <UnconfirmedFailedSegment
        errorDescription={errorDescription}
        segmentTitle={segmentTitle}
        segmentDescription={segmentDescription}
        buttons={buttons}
      />
    )
  }

  return (
    <Wrapper>
      <Header>
        <Icon name="errorPWA" color="error" />
        <Text variant="headline" color="error">
          {errorTitle}
        </Text>
      </Header>
      <FailedSegmentBody
        variant={'partial'}
        errorDescription={errorDescription}
        segmentTitle={segmentTitle}
        segmentDescription={segmentDescription}
        buttons={buttons}
      />
    </Wrapper>
  )
}
