import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import {
  BodyLeftIcon,
  BodyPercent,
  PercentNumber,
  BodyLeftStyles,
} from './car-rental-details-eco-check-desktop-styled'

export function BodyLeftBlock({
  deviationLevel,
  percentDeviation,
}: {
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}) {
  const Container = BodyLeftStyles[deviationLevel]

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average:
      return (
        <Container>
          <Icon name="averagePWA" size={40} />
        </Container>
      )
    case EmissionsDeviationLevel.Less:
      return (
        <Container>
          <BodyLeftIcon name="arrowDownwardPWA" size={28} />
          <PercentNumber>{percentDeviation}</PercentNumber>
          <BodyPercent>%</BodyPercent>
        </Container>
      )
    case EmissionsDeviationLevel.More:
      return (
        <Container>
          <BodyLeftIcon name="arrowUpwardPWA" size={28} />
          <PercentNumber>{percentDeviation}</PercentNumber>
          <BodyPercent>%</BodyPercent>
        </Container>
      )
  }
}
