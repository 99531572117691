import { Store } from '@etta/di'
import { TripType } from '@etta/core/enums/trip-type.enum'
import type { TripsListValueObject } from '../../core/value-object/trips-list.value-object'

type Errors = Record<TripType, boolean>
type Loadings = Record<TripType, boolean>
type FetchMoreLoadings = Record<TripType, boolean>
type Orders = Record<TripType, TripsListValueObject | null>

@Store()
export class TripsListStore {
  isLoading: Loadings = {
    [TripType.Upcoming]: false,
    [TripType.Past]: false,
    [TripType.Cancelled]: false,
    [TripType.OnHold]: false,
  }

  isFetchMoreLoading: FetchMoreLoadings = {
    [TripType.Upcoming]: false,
    [TripType.Past]: false,
    [TripType.Cancelled]: false,
    [TripType.OnHold]: false,
  }

  isError: Errors = {
    [TripType.Upcoming]: false,
    [TripType.Past]: false,
    [TripType.Cancelled]: false,
    [TripType.OnHold]: false,
  }

  orders: Orders = {
    [TripType.Upcoming]: null,
    [TripType.Past]: null,
    [TripType.Cancelled]: null,
    [TripType.OnHold]: null,
  }

  setTripsList(orders: TripsListValueObject | null, tripType: TripType) {
    this.orders[tripType] = orders
  }

  dropAllTrips() {
    this.orders = {
      [TripType.Upcoming]: null,
      [TripType.Past]: null,
      [TripType.Cancelled]: null,
      [TripType.OnHold]: null,
    }
  }

  setIsError(isError: boolean, tripType: TripType) {
    this.isError[tripType] = isError
  }

  setIsLoading(isLoading: boolean, tripType: TripType) {
    this.isLoading[tripType] = isLoading
  }

  setIsFetchMoreLoading(isLoading: boolean, tripType: TripType) {
    this.isFetchMoreLoading[tripType] = isLoading
  }
}
