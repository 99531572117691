import type { ReactNode } from 'react'
import { Container, Indicator, List } from './tab-header-styled'
import { useTabHeader } from './use-tab-header'

type Props = {
  children: ReactNode
  withBottomBorder?: boolean
  tabBasis?: number
}

export function TabHeader({ children, tabBasis, withBottomBorder = true }: Props) {
  const { width, currentTabIndex } = useTabHeader({ tabBasis })
  const isIndicatorVisible = currentTabIndex !== -1
  return (
    <Container data-tracking-id="tabs-container" withBottomBorder={withBottomBorder}>
      <List role="tablist">{children}</List>
      {isIndicatorVisible && <Indicator width={width} currentIndex={currentTabIndex} />}
    </Container>
  )
}
