import type { CalculateCarEmissionsBatchResultCar } from '@fiji/graphql/types'

type GetCarRentalEmissionInput = {
  carId?: string
  carEmissions: {
    averageEmissionsTonnes: number
    existingCarRentalEmissions: CalculateCarEmissionsBatchResultCar[]
    loading: boolean
  }
}

export function getCarRentalEmission({ carId, carEmissions }: GetCarRentalEmissionInput) {
  const actualCarEmission = carEmissions.existingCarRentalEmissions.find(
    (carEmission) => carEmission.customRef === carId,
  )

  return {
    loading: carEmissions.loading,
    averageEmissionsTonnes: carEmissions.averageEmissionsTonnes,
    customRef: actualCarEmission?.customRef,
    equivalences: actualCarEmission?.equivalences,
    tonnesOfEmissions: actualCarEmission?.tonnesOfEmissions,
  }
}
