import { DayPickerRangeController } from 'react-dates'
import '@fiji/utils/init-react-dates-vendor'
import type { CalendarProps } from '@etta/ui/date-picker/types'
import { useCalendar } from '@fiji/hooks/use-date-picker'
import { CalendarContainer } from './calendar-components'
import { CalendarLayout } from './layout'

export default function Calendar({
  date,
  dateEnd,
  previousDate,
  focusedInput,
  minDate,
  maxDate,
  isEndDateSame,
  onDaySelect,
  highlightDates,
  numberOfMonths = 2,
  isOutsideRange,
  handlerRenderMonthElement,
  forceScreenType,
}: CalendarProps) {
  const {
    startDateValue,
    endDateValue,
    container,
    isDayBlockedHandler,
    handleDatesChange,
    handleFocusChange,
    shouldHighlightDay,
    isOutsideRangeHandler,
    isLocaleNormalized,
    initialVisibleMonth,
    isMultiCity,
  } = useCalendar({
    date,
    dateEnd,
    highlightDates,
    isEndDateSame,
    maxDate,
    minDate,
    previousDate,
    onDaySelect,
  })

  return (
    <CalendarContainer ref={container} forceScreenType={forceScreenType}>
      {isLocaleNormalized && (
        <CalendarLayout
          forceScreenType={forceScreenType}
          calendarSlot={
            <DayPickerRangeController
              horizontalMonthPadding={15}
              minimumNights={isEndDateSame ? 0 : 1}
              startDate={startDateValue}
              endDate={endDateValue}
              numberOfMonths={numberOfMonths}
              keepOpenOnDateSelect
              focusedInput={focusedInput}
              hideKeyboardShortcutsPanel
              isDayHighlighted={shouldHighlightDay}
              isDayBlocked={isDayBlockedHandler}
              onDatesChange={handleDatesChange}
              onFocusChange={handleFocusChange}
              weekDayFormat={'dd'}
              isOutsideRange={isOutsideRange || isOutsideRangeHandler}
              renderMonthElement={handlerRenderMonthElement}
              firstDayOfWeek={0}
              initialVisibleMonth={isMultiCity ? initialVisibleMonth : null}
            />
          }
        />
      )}
    </CalendarContainer>
  )
}
