import { Action, Inject } from '@etta/di'
import type { AirTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/air-travel-preference-input.value-object'
import type { HotelTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/hotel-travel-preference-input.value-object'
import type { CarRentalTravelPreferenceInputValueObject } from '@etta/modules/settings/core/value-objects/car-rental-preference-input.value-object'
import { UserProfileStore } from '../stores/user-profile.store'
import { TravelPreferencesStore } from '../stores/travel-preferences.store'
import { UserProfileValidatorBuilder } from '../services/user-profile-validator-builder'

@Action()
export class TravelPreferencesActions {
  constructor(
    @Inject() private readonly userProfileStore: UserProfileStore,
    @Inject() private readonly travelPreferencesStore: TravelPreferencesStore,
  ) {}

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) =>
      this.travelPreferencesStore.setAdditionalInfoValue(key, value)
  }

  initTravelPreferencesAdditionalForm() {
    const validator = UserProfileValidatorBuilder.build(
      this.userProfileStore.userProfile?.travelPreferences.additionalInformation ?? [],
    )
    this.travelPreferencesStore.setAdditionalInfoValidator(validator)
  }

  handleFlightPreferenceChange<T extends keyof AirTravelPreferenceInputValueObject>(field: T) {
    return (value: AirTravelPreferenceInputValueObject[T]) => {
      this.travelPreferencesStore.setFlightPreferencesValue(field, value)
    }
  }

  handleHomeAirportSelect(value: AirTravelPreferenceInputValueObject['homeAirport']) {
    this.handleFlightPreferenceChange('homeAirport')(value)
  }

  handleHotelPreferenceChange<T extends keyof HotelTravelPreferenceInputValueObject>(field: T) {
    return (value: HotelTravelPreferenceInputValueObject[T]) => {
      this.travelPreferencesStore.setHotelPreferencesValue(field, value)
    }
  }

  handleCarrentalPreferenceChange<T extends keyof CarRentalTravelPreferenceInputValueObject>(
    field: T,
  ) {
    return (value: CarRentalTravelPreferenceInputValueObject[T]) => {
      this.travelPreferencesStore.setCarrentalPreferencesValue(field, value)
    }
  }
}
