export function formatDateWithoutTimezone(dateStr: string) {
  // input: '2020-10-06'
  // output: '2020-10-06T00:00:00.000'
  // input: '2011-10-05T14:48:00.000Z'
  // output: '2011-10-05T14:48:00.000'
  // input: '2011-10-05T14:48:00Z'
  // output: '2011-10-05T14:48:00'
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)) {
    return dateStr.substring(0, dateStr.indexOf('Z'))
  }

  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(dateStr)) {
    return dateStr.substring(0, dateStr.indexOf('Z'))
  }

  const regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateStr.match(regEx)) {
    return dateStr
  }

  return `${dateStr}T00:00:00.000`
}
