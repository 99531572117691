import { Icon } from '@etta/ui/icon'
import { Skeleton } from '@etta/ui/skeleton'
import { FirstLine, SecondLine } from './header-skeleton-styled'

export function HeaderSkeleton() {
  return (
    <>
      <FirstLine>
        <Skeleton width="100%" height="16px">
          <rect width="55%" height="16px" rx="2" ry="2" />
        </Skeleton>
        <Icon name="sortPWA" size="normal" />
      </FirstLine>
      <SecondLine>
        <Skeleton width="100%" height="16px">
          <rect width="36%" height="16px" rx="2" ry="2" />
        </Skeleton>
      </SecondLine>
    </>
  )
}
