export default function SwitchIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8903 8.51427L4.48488 16.096C4.19101 16.3899 4 16.8454 4 17.2421C4 18.1678 4.64651 18.7996 5.55749 18.7996C5.99829 18.7996 6.35093 18.6527 6.6448 18.3441L9.67163 15.1997L11.6111 12.9663L11.4936 16.0813V34.4039C11.4936 35.3296 12.1548 35.9908 13.0658 35.9908C13.9768 35.9908 14.6233 35.3296 14.6233 34.4039V16.0813L14.5204 12.9663L16.4453 15.1997L19.4721 18.3441C19.7659 18.6527 20.1333 18.7996 20.5741 18.7996C21.4704 18.7996 22.1169 18.1678 22.1169 17.2421C22.1169 16.8454 21.9259 16.3899 21.632 16.096L14.2266 8.51427C13.5801 7.83837 12.5515 7.82368 11.8903 8.51427ZM31.2561 35.4765L38.6616 27.8948C38.9554 27.5862 39.1465 27.1307 39.1465 26.734C39.1465 25.823 38.5 25.1912 37.589 25.1912C37.1482 25.1912 36.7955 25.3381 36.5017 25.632L33.4748 28.7764L31.5353 31.0098L31.6529 27.8948V9.57219C31.6529 8.67589 30.9917 8 30.0807 8C29.1844 8 28.5232 8.67589 28.5232 9.57219V27.8948L28.626 31.0098L26.7012 28.7764L23.6744 25.632C23.3805 25.3381 23.0132 25.1912 22.5871 25.1912C21.6761 25.1912 21.0296 25.823 21.0296 26.734C21.0296 27.1307 21.2206 27.5862 21.5145 27.8948L28.9199 35.4765C29.5664 36.1671 30.5949 36.1818 31.2561 35.4765Z"
        fill="currentColor"
      />
    </svg>
  )
}
