import { Store } from '@etta/di'

import type { SiteSsoSettingsErrorsInstances } from '@etta/modules/site-sso-settings/core/errors/site-sso-settings.errors'
import type { SiteSsoSettingsValueObject } from '../../core/value-objects/site-sso-settings.value-object'

@Store()
export class SiteSsoSettingsStore {
  siteSsoSettings: SiteSsoSettingsValueObject | null = null

  error: SiteSsoSettingsErrorsInstances | null = null

  loading = false

  setSiteSsoSettings(value: SiteSsoSettingsValueObject) {
    this.siteSsoSettings = value
  }

  setLoading(value: boolean) {
    this.loading = value
  }

  setError(error: SiteSsoSettingsErrorsInstances) {
    this.error = error
  }
}
