import { Store, Inject } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { getFilterOption } from '@etta/modules/car-rental-search/interface/stores/get-filter-option'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import { CarRentalSortBy } from '../../core/enums'
import { CarFiltersStore } from '../stores/car-filters.store'
import { CarFiltersModalStore } from '../stores/car-filters-modal.store'
import type { CarRentalsValueObject } from '../../core/value-objects'

const OFFSET_START = 0

@Store()
export class CarRentalSearchStore {
  activeItineraryToggle = new Toggle()

  travelPolicyToggle = new Toggle()

  tripExpiredToggle = new Toggle()

  searchPageEnd = OFFSET_START

  carRentals: CarRentalsValueObject | null = null

  isLoading = false

  isSomeFiltersAppliedByCompanySettings = false

  isSecondCarRequestWithoutFilters = false

  isLoadingMore = false

  searchId: string | null = null

  isErrorCarRentals = false

  isErrorCreatingSearch = false

  isRestrictedCountryError = false

  restrictedErrorMessage: string | null = null

  carRentalSortBy = CarRentalSortBy.Preference

  selectedCarRentalKey: string | null = null

  viewState: TripStateStatus = null

  constructor(
    @Inject() private carFiltersStore: CarFiltersStore,
    @Inject() private carFiltersModalStore: CarFiltersModalStore,
  ) {}

  get hasNextPage() {
    if (this.isLoading) {
      return false
    }
    const maxResults = this.carRentals?.maxResults || 0

    return this.searchPageEnd < maxResults
  }

  get cars() {
    return this.carRentals?.car || []
  }

  get carMessages() {
    return this.carRentals?.message
  }

  get isResultsEmpty() {
    return this.cars.length === 0
  }

  get availableCarFilters() {
    return this.carRentals?.filters
  }

  get carPreferenceAttribute() {
    return this.carRentals?.preferenceAttribute
  }

  get hasError() {
    return this.isErrorCarRentals || this.isErrorCreatingSearch
  }

  setViewState(value: TripStateStatus) {
    this.viewState = value
  }

  setSelectedCarRentalKey(carRentalKey: string | null) {
    this.selectedCarRentalKey = carRentalKey
  }

  setCarRentalSortBy(value: CarRentalSortBy) {
    this.carRentalSortBy = value
  }

  setPageEnd(value: number) {
    this.searchPageEnd = value
  }

  setSearchId(value: string | null) {
    this.searchId = value
  }

  setCarRentals(value: CarRentalsValueObject | null) {
    this.carRentals = value
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsLoadingMore(value: boolean) {
    this.isLoadingMore = value
  }

  setIsErrorCarRentals(value: boolean) {
    this.isErrorCarRentals = value
  }

  setIsErrorCreatingSearch(value: boolean) {
    this.isErrorCreatingSearch = value
  }

  setIsSomeFiltersAppliedByCompanySettings(value: boolean) {
    this.isSomeFiltersAppliedByCompanySettings = value
  }

  setIsSecondCarRequestWithoutFilters(value: boolean) {
    this.isSecondCarRequestWithoutFilters = value
  }

  dropFilters() {
    this.carRentalSortBy = CarRentalSortBy.Preference
  }

  dropOffset() {
    this.searchPageEnd = OFFSET_START
  }

  setIsRestrictedCountryError(value: boolean) {
    this.isRestrictedCountryError = value
  }

  setRestrictedErrorMessage(value: string | null) {
    this.restrictedErrorMessage = value
  }

  dropCarRentalSearch() {
    this.travelPolicyToggle.handleClose()
    this.carFiltersModalStore.carFiltersToggle.handleClose()
    this.searchPageEnd = OFFSET_START
    this.carRentalSortBy = CarRentalSortBy.Preference
    this.isErrorCarRentals = false
    this.isErrorCreatingSearch = false
    this.isRestrictedCountryError = false
    this.restrictedErrorMessage = null
    this.isLoading = false
    this.isLoadingMore = false
    this.carRentals = null
    this.isSomeFiltersAppliedByCompanySettings = false
    this.isSecondCarRequestWithoutFilters = false
  }

  get filterOptions() {
    return getFilterOption({
      filters: this.carRentals?.filters,
      selected: this.carFiltersStore.selectedFilters,
    })
  }
}
