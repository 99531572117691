import { useEffect } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'

export function useCustomerUberAccountStatus() {
  const { getCustomerUberAccountStatusActions } = useRideHailSearchContext()

  useEffect(() => {
    getCustomerUberAccountStatusActions.getCustomerUberAccountStatus()
  }, [getCustomerUberAccountStatusActions])
}
