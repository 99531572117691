import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '@fiji/store'
import type { ViewState } from '@etta/ui/view-state-modal'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { ROUTES } from '@fiji/routes'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { updateTripReviewTransactionGroupId } from '@fiji/store/trip-review-params'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useShare } from './use-share'

type Props = {
  bookingId: string
  transactionGroupId?: string
}

export function useActionButtons({ bookingId, transactionGroupId }: Props) {
  const { postBookingTripStore } = usePostBookingContext()
  const {
    reactivateTripActions: { handleReactivateTripById },
    tripActions: { updateTrip, setTripId },
  } = useReviewTripContext()
  const { segments, trip } = postBookingTripStore
  const { rollUpStartTime, rollUpEndTime, bookingRef, name } = trip
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [viewState, setViewState] = useState<ViewState>('hidden')

  const onCloseViewState = () => {
    setViewState('hidden')
  }

  const { handleShare } = useShare({
    tripName: name,
    segments,
    startDate: rollUpStartTime,
    endDate: rollUpEndTime,
  })
  const handleModify = useCallback(async () => {
    try {
      setViewState('loading')
      const response = await handleReactivateTripById(bookingId)
      if (!response) {
        throw new Error('reactivate trip error')
      }
      setTripId(bookingRef)
      await updateTrip()
      dispatch(updateTripReviewTransactionGroupId(transactionGroupId))

      await delayForSuccess()

      setViewState('hidden')
      dispatch(updatePostPurchaseEntrance('fromRTP'))

      history.push(`${ROUTES.reviewTrip.main({ itineraryId: bookingRef, bookingId })}`)
    } catch {
      setViewState('error')
    }

    history.push(`${ROUTES.reviewTrip.main({ itineraryId: bookingRef, bookingId })}`)
  }, [
    bookingId,
    transactionGroupId,
    bookingRef,
    history,
    handleReactivateTripById,
    dispatch,
    updateTrip,
    setTripId,
  ])

  return { handleShare, handleModify, viewState, onCloseViewState }
}
