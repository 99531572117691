import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { ScreenType, useScreenType } from '@fiji/modes'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { setLinkTagsForContent } from '@fiji/utils/set-link-tags-for-content'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'
import { Container, ItemWrapper } from './rail-ticket-restrictions-content-styled'
import type { RailTicketRestrictionsContentProps } from './types'

const i18nBase = 'RailDetails.RestrictionTicket.'

export function RailTicketRestrictionsContent({
  ticketRestrictionsInfo,
  needTicketRestrictionTitle,
  isPostBooking,
}: RailTicketRestrictionsContentProps) {
  const { t } = useTranslation()

  const screenType = useScreenType()

  const isMobile = screenType === ScreenType.Mobile

  const { railStore } = useRailContext()
  const { postBookingTripDetailsStore } = usePostBookingContext()

  const getTicketRestrictionsFromStore = (isPostBooking?: boolean): TicketRestrictionsInfo => {
    if (isPostBooking) {
      return postBookingTripDetailsStore.ticketRestrictionsInfo
    }
    return railStore.ticketRestrictionsInfo
  }

  let { tripDate, tripRoute, tripFare, ticketRestrictions } = getTicketRestrictionsFromStore(
    isPostBooking,
  )
  if (ticketRestrictionsInfo) {
    tripDate = ticketRestrictionsInfo.tripDate
    tripRoute = ticketRestrictionsInfo.tripRoute
    tripFare = ticketRestrictionsInfo.tripFare
    ticketRestrictions = ticketRestrictionsInfo.ticketRestrictions
  }

  return (
    <>
      <Swap
        is={!ticketRestrictions.length}
        isSlot={
          <NoResultSearchScreen
            type="train"
            title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
            subTitle={t(`${i18nBase}ErrorMessage`)}
            buttons={[]}
          />
        }>
        <Container isMobile={isMobile}>
          {tripDate && (
            <Text variant="calloutMedium" color="mainText">
              {tripDate}
            </Text>
          )}
          {tripRoute && (
            <Block marginTop={4}>
              <Text variant="headline" color="mainText">
                {tripRoute}
              </Text>
            </Block>
          )}
          {needTicketRestrictionTitle && (
            <Block>
              <Text variant="headline" color="mainText">
                {t(`${i18nBase}Title`)}
              </Text>
            </Block>
          )}
          {tripFare && (
            <Block marginTop={32}>
              <Text variant="headline" color="mainText">
                {tripFare}
              </Text>
            </Block>
          )}
          {!!ticketRestrictions.length && (
            <Block marginTop={32} data-tracking-id="ticket-restrictions-wrapper">
              {ticketRestrictions.map((restrinction, index) => (
                <ItemWrapper key={index} marginTop={24}>
                  <Text variant="subHeadStrong" color="mainText" textTransform="capitalize">
                    {restrinction.name.toLowerCase()}
                  </Text>
                  <Block marginTop={4}>
                    <Text
                      variant="captionMedium"
                      color="bodyText1"
                      dangerouslySetInnerHTML={{
                        __html: setLinkTagsForContent(restrinction.description),
                      }}
                    />
                  </Block>
                </ItemWrapper>
              ))}
            </Block>
          )}
        </Container>
      </Swap>
    </>
  )
}
