import styled, { css, keyframes } from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { footnoteMedium } from '@fiji/style'
import type { PWAIconProps } from '@etta/ui/icon/pwa-icon'

const AMENITY_ITEM_HEIGHT = 27

const expandedOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const collapsedOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 12px;
  user-select: none;
  cursor: pointer;
`

export const StyledIcon = styled(Icon).attrs<PWAIconProps>(() => ({
  size: 'small',
}))``

export const StyledChevronIcon = styled(Icon)<{ rotate?: boolean }>`
  transition: transform 0.2s ease;

  ${({ rotate }) => css`
    transform: ${rotate ? 'rotate(-180deg)' : ''};
  `}
`

export const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.colors.mainText2};
  ${footnoteMedium};
`

export const List = styled.ul<{ isColumn: boolean; amenitiesCount: number }>`
  transition: height 0.4s ease;
  ${({ isColumn, amenitiesCount }) => css`
    display: flex;
    flex-direction: ${isColumn ? 'column' : 'row'};
    list-style: none;
    margin: ${isColumn ? 2 : 0}px 0 0 0;
    padding: 0;
    width: 100%;

    ${!isColumn
      ? css`
          height: ${AMENITY_ITEM_HEIGHT}px;
          animation: ${expandedOpacity} 1s;
          overflow: hidden;
        `
      : css`
          height: ${amenitiesCount * AMENITY_ITEM_HEIGHT}px;
          animation: ${collapsedOpacity} 1s;
          overflow: hidden;

          > * + * {
            margin-top: 7px;
          }
        `}
    > ${ListItem} > ${StyledIcon} {
      margin-right: ${isColumn ? 10 : 0}px;
    }
  `};
`
