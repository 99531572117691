import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { useAlertContext } from '@etta/modules/alert/interface'
import { AlertModal, AlertNotificationButton } from '@etta/modules/alert'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { SupportContacts } from '../../components/support-contacts'
import { NotificationButtonWrapper } from './support-page-mobile-styled'

const i18Base = 'TripPlanner.Support.MainPage'

export const SupportPageMobile = observer(function SupportPageMobile() {
  const { t } = useTranslation()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { phoneNumbers, email } = displayConfigurationStore.displayConfiguration.support.mobile
  const { alertStore } = useAlertContext()
  const { alertsCount } = alertStore

  return (
    <Screen backgroundColor="background">
      <Screen.Header>
        <Header backgroundColor="background" layout="content-view">
          <Header.Title layout="content-view" align="left" title={t(i18Base + '.Title')} />
          {Boolean(alertsCount) && (
            <NotificationButtonWrapper>
              <AlertNotificationButton />
            </NotificationButtonWrapper>
          )}
        </Header>
      </Screen.Header>
      <Screen.Container as={'main'}>
        <SupportContacts
          isLoading={displayConfigurationStore.isLoading}
          phoneNumbers={phoneNumbers}
          email={email}
        />

        <AlertModal />
      </Screen.Container>
    </Screen>
  )
})
