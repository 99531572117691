/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { focusLock } from './focus-lock/focus-lock'
import type { FocusElement } from './focus-lock/types'

type Args = {
  disabled?: boolean
  autoFocus?: boolean
}

export function useFocusLock({ disabled, autoFocus }: Args) {
  const currentId = useRef(uuid()).current
  const focusLockContainerRef = useRef<FocusElement | null>(null)

  const catchFocus = useCallback(() => {
    if (disabled) {
      return
    }

    focusLock.catchFocus(currentId)
  }, [disabled])

  useEffect(() => {
    focusLock.addFocusItem(currentId, focusLockContainerRef.current)
    return () => {
      focusLock.removeFocusItem(currentId)
      focusLock.releaseFocus(currentId)
      focusLock.destroyActiveId()
    }
  }, [])

  useEffect(() => {
    focusLock.updateItem(currentId, {
      node: focusLockContainerRef.current,
      disabled,
    })
  }, [disabled])

  useEffect(() => {
    if (!autoFocus || disabled) {
      return
    }

    focusLock.catchFocus(currentId, autoFocus)
  }, [disabled])

  return {
    catchFocus,
    focusLockContainerRef,
  }
}
