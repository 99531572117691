import type { Props } from '../types'
import { Label, Value, InputContainer } from './input-mobile-styled'

export function InputMobile({
  displayedText,
  label,
  ariaLabel,
  'data-tracking-id': dataTrackingId,
  isDisabled,
  onClick,
}: Props) {
  return (
    <InputContainer
      aria-label={ariaLabel}
      disabled={isDisabled}
      onClick={onClick}
      data-tracking-id={dataTrackingId}>
      {displayedText && <Label>{label}</Label>}
      <Value isLabel={!displayedText}>{displayedText || label}</Value>
    </InputContainer>
  )
}
