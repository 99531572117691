import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetOrdersDocument } from '@fiji/graphql/hooks'
import type { GetOrdersQuery, GetOrdersQueryVariables } from '@fiji/graphql/types'

@DataProvider()
export class OrdersDataProvider extends GraphqlDataProvider {
  getOrders(input: GetOrdersQueryVariables, forceUpdate?: boolean) {
    return this.client.query<GetOrdersQuery, GetOrdersQueryVariables>({
      query: GetOrdersDocument,
      variables: {
        input: {
          offset: input.input.offset,
          tripType: input.input.tripType,
          format: input.input.format,
          size: input.input.size,
        },
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
