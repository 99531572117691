/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { useGetDeemHeaderQuery } from '@fiji/graphql/hooks'
import { useSearchParams } from '@fiji/hooks/use-search-params/use-search-params'
import { useCookieContext } from '@etta/modules/cookie'
import type { AppState } from '@fiji/store'
import { useAppDispatch, useAppSelector } from '@fiji/store'
import { setIsMeetingsMode, setSiteName } from '@fiji/store/display-configuration'
import {
  updateDeemHeaderData,
  updateDeemHeaderError,
  updateDeemHeaderLoading,
  updateDeemHeaderStyleSheet,
} from '@fiji/store/deem-header'
import { appMode } from '@fiji/modes'
import { insertFavicon } from '../../use-context-loader/insert-favicon'
import { insertStyles } from './insert-styles'
import { insertChat } from './insert-chat'
import { insertChrome } from './insert-chrome'
import { useSyncItinerary } from './use-sync-itinerary'
import { useMeetingsModeElements } from './use-meeting-mode-elements'

export function useDeemHeader() {
  const { cookieStore } = useCookieContext()
  const dispatch = useAppDispatch()
  const [areStylesheetsLoaded, setAreStylesheetsLoaded] = useState(false)
  const locale = useAppSelector(
    (state) =>
      state.user?.trustedLocale?.replace('-', '_') || state.user?.locale?.replace('-', '_'),
  )
  const {
    isMeetingsMode,
    meetingsModeElements,
    isFetchMeetingsModeElementsFinished,
  } = useMeetingsModeElements()

  const { loading, data, error } = useGetDeemHeaderQuery({
    variables: {
      locale,
      ...(meetingsModeElements && { meetingsModeElements: meetingsModeElements }),
    },
    skip: !cookieStore.isLoggedIn() || !isFetchMeetingsModeElementsFinished,
    fetchPolicy: 'cache-first',
  })
  const { searchParams } = useSearchParams()
  const { itineraryId } = searchParams

  const user = useAppSelector((state: AppState) => state.user)

  useSyncItinerary({
    user,
    itineraryId,
  })

  const isLoadPassed = !loading && !error && data

  useEffect(() => {
    if (!isLoadPassed) {
      return
    }

    const siteName = data?.deemHeader?.metadata?.brandingMetadata.siteName
    siteName && dispatch(setSiteName({ siteName }))
    dispatch(setIsMeetingsMode({ isMeetingsMode }))
  }, [isLoadPassed, data, isMeetingsMode])

  useEffect(() => {
    if (!isLoadPassed) {
      return
    }

    if (appMode.isEttaAll) {
      setAreStylesheetsLoaded(true)
      return
    }

    const assets = parse(data!.deemHeader.headerAssets)

    if (!areStylesheetsLoaded) {
      insertStyles({
        assets,
        onDone: () => {
          setAreStylesheetsLoaded(true)
        },
      })
      return
    }

    insertFavicon({ assets })
    insertChrome(assets)
    insertChat(assets)
  }, [isLoadPassed, data, areStylesheetsLoaded])

  useEffect(() => {
    const isDeemHeaderLoading = isFetchMeetingsModeElementsFinished ? loading : true
    if (data) {
      dispatch(updateDeemHeaderData(data))
    }
    if (error) {
      dispatch(updateDeemHeaderError(!!error))
    }
    dispatch(updateDeemHeaderLoading(isDeemHeaderLoading))
    dispatch(updateDeemHeaderStyleSheet(areStylesheetsLoaded))
  }, [data, error, loading, areStylesheetsLoaded, isFetchMeetingsModeElementsFinished])
}
