import { v4 as uuid } from 'uuid'
import type { Place, PlainTime } from '@fiji/types'
import { dateFormat, getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { Service } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import type { CarRentalRecentSearch } from '../../../core/value-objects/get-car-rental-searches.value-object'

const formatString = "iii', 'MMM dd"

@Service()
export class CarRentalRecentSearchDataService {
  get(input: CarRentalRecentSearch['carRentalSearchParams']) {
    const queryParams = this.getQueryParams(input)
    const bottomLine = this.getBottomLine(input)
    const locationName = this.getName(input)
    const route = this.getRoute({
      pickUpAirport: queryParams.pickUpPlace.airportCode,
      dropOffAirport: queryParams.dropOffPlace.airportCode,
    })
    return { queryParams, bottomLine, locationName, route }
  }

  private getRoute({
    pickUpAirport,
    dropOffAirport,
  }: {
    pickUpAirport?: string
    dropOffAirport?: string
  }) {
    if (!pickUpAirport) {
      return ROUTES.carRental.pickUp
    }
    if (!dropOffAirport) {
      return ROUTES.carRental.dropOff
    }
    return ROUTES.carRental.results
  }

  private getName({ dropOff, pickUp }: CarRentalRecentSearch['carRentalSearchParams']) {
    const originLocationName = pickUp.location.name
    const destinationLocationName = dropOff.location.name

    return originLocationName !== destinationLocationName
      ? [originLocationName, destinationLocationName].join(' - ')
      : originLocationName
  }

  private getBottomLine({ dropOff, pickUp }: CarRentalRecentSearch['carRentalSearchParams']) {
    const pickUpDate = new Date(pickUp.date)
    const pickUpDateWithoutTimezone = getDateWithoutTimezoneOffset(pickUpDate)
    const formattedPickUpDate = dateFormat(pickUpDateWithoutTimezone, formatString)

    const dropOffDate = new Date(dropOff.date)
    const dropOffDateWithoutTimezone = getDateWithoutTimezoneOffset(dropOffDate)
    const formattedDropOffDate = dateFormat(dropOffDateWithoutTimezone, formatString)

    return [formattedPickUpDate, formattedDropOffDate].join(' - ')
  }

  private getQueryParams({ dropOff, pickUp }: CarRentalRecentSearch['carRentalSearchParams']) {
    const pickUpPlace = {
      placeId: uuid(),
      name: pickUp.location.name,
      airportCode: pickUp.location.airportCode || undefined,
      latitude: pickUp.location.geocode.lat,
      longitude: pickUp.location.geocode.long,
    }

    const pickUpDate = new Date(pickUp.date)
    const pickUpDateWithoutTimezone = getDateWithoutTimezoneOffset(pickUpDate)
    const dropOffDate = new Date(dropOff.date)
    const dropOffDateWithoutTimezone = getDateWithoutTimezoneOffset(dropOffDate)
    const pickUpTime = this.toPlainTime(pickUp.time)
    const dropOffTime = this.toPlainTime(dropOff.time)

    const dropOffPlace: Place = {
      placeId: uuid(),
      name: dropOff.location.name,
      airportCode: dropOff.location.airportCode || undefined,
      latitude: dropOff.location.geocode.lat,
      longitude: dropOff.location.geocode.long,
    }

    return {
      pickUpDate: pickUpDateWithoutTimezone,
      dropOffDate: dropOffDateWithoutTimezone,
      pickUpTime,
      dropOffTime,
      dropOffPlace,
      pickUpPlace,
    }
  }

  private toPlainTime(time: string): PlainTime {
    const date = new Date('1970-01-01T' + time)

    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    }
  }
}
