import { lazy } from 'react'
import { ROUTES } from '../routes'
import type { RouteItem } from '../types'
import { getDocumentTitle } from '../get-document-title'

const TripFlightPage = lazy(() => import('@etta/screens/trip-flight-page'))

export const deepLinkRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.deepLink.flightSegment({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Flight details'),
    transitionIndex: 5.2,
    transitionGroup: 'slide',
    isNavBarHidden: true,
    component: () => <TripFlightPage />,
  },
]
