import type { HotelSegmentEntity } from '@etta/modules/review-trip/core'

export function getHotelRules(segment: HotelSegmentEntity) {
  if (segment.rulesAndRestrictions && segment.rulesAndRestrictions.length > 0) {
    return {
      rules: segment.rulesAndRestrictions,
      label: segment.name,
    }
  }
}
