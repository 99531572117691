import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CustomFieldDateTimeDisplay } from '@fiji/graphql/types'
import { dateToTime, parse } from '@fiji/utils/dates'
import type { PlainTime } from '@fiji/types'
import type { YearBounds } from '@etta/ui/swiping-time-date-picker/types'
import { formatDateField, formatTimeField } from '../../helpers'
import { PickerType } from '../../types'
import type { Arguments } from './types'

const GMT_ARRAY = new Array(25).fill(null).map((_, index) => {
  const gmtTime = index - 12
  if (gmtTime > 0) {
    return { label: 'GMT +' + gmtTime, value: 'GMT +' + gmtTime }
  }
  return { label: 'GMT ' + (index - 12), value: 'GMT ' + (index - 12) }
})

const datePickerTypes = [
  PickerType.DATE,
  PickerType.ONLY_YEAR,
  PickerType.ONLY_MONTH,
  PickerType.ONLY_DAY,
]

const datePickerPlaceholderDict: Record<PickerType, string> = {
  'only-year': 'Year',
  'only-month': 'Month',
  'only-day': 'Day',
  'only-hour': 'Hour',
  'only-min': 'Minutes',
  timezone: 'Timezone',
  date: 'Date',
  time: 'Time',
}

const i18nBase = 'AdditionalInfoForm'

export function useDateField({ field, onChangeHandler }: Arguments) {
  const { t } = useTranslation()
  const { handleClose, handleOpen, isOpen } = useTogglePopup()
  const dateTimeDisplayFields = R.pick(
    [
      'displayYear',
      'displayMonth',
      'displayDay',
      'displayHour',
      'displayMinute',
      'displayTimeZone',
    ],
    R.pathOr<CustomFieldDateTimeDisplay>(
      {} as CustomFieldDateTimeDisplay,
      ['dateTimeDisplay'],
      field,
    ),
  )

  const isAllowedPastDateTime = R.path<boolean>(['dateTimeDisplay', 'isAllowedPastDateTime'], field)

  const {
    displayYear,
    displayMonth,
    displayDay,
    displayHour,
    displayMinute,
    displayTimeZone,
  } = dateTimeDisplayFields

  const getPickerType = (): PickerType => {
    if (displayTimeZone) {
      return PickerType.TIMEZONE
    }

    if (displayYear && displayMonth && displayDay) {
      return PickerType.DATE
    }

    if (displayYear) {
      return PickerType.ONLY_YEAR
    }

    if (displayMonth) {
      return PickerType.ONLY_MONTH
    }
    if (displayDay) {
      return PickerType.ONLY_DAY
    }

    if (displayHour && displayMinute) {
      return PickerType.TIME
    }

    if (displayHour) {
      return PickerType.ONLY_HOUR
    }

    if (displayMinute) {
      return PickerType.ONLY_MIN
    }

    return PickerType.DATE
  }

  const yearBounds: YearBounds | undefined = useMemo(() => {
    if (
      !(field.dateTimeDisplay && field.dateTimeDisplay.minYear && field.dateTimeDisplay?.maxYear)
    ) {
      return
    }

    return {
      minYear: field.dateTimeDisplay.minYear,
      maxYear: field.dateTimeDisplay.maxYear,
    }
  }, [field])

  const pickerType = getPickerType()

  const formatValue = (preformattedValue?: Date | string) => {
    if (!preformattedValue) {
      return ''
    }

    if (datePickerTypes.includes(pickerType)) {
      return formatDateField({ date: new Date(preformattedValue), ...dateTimeDisplayFields })
    }

    return preformattedValue as string
  }

  const getFormattedTime = useCallback(
    (time: PlainTime) => {
      return formatTimeField({
        time: new Date(0, 0, 0, time.hours, time.minutes),
        ...dateTimeDisplayFields,
      })
    },
    [dateTimeDisplayFields],
  )

  const handleWrapperClick = useCallback(() => !isOpen && handleOpen(), [isOpen, handleOpen])

  const handleSelectDate = useCallback(
    ({ date }) => {
      onChangeHandler(date)
      handleClose()
    },
    [handleClose, onChangeHandler],
  )

  const handleApplyTime = useCallback((result) => onChangeHandler(getFormattedTime(result)), [
    getFormattedTime,
    onChangeHandler,
  ])

  const handleOnApplyDate = useCallback((result) => onChangeHandler(result), [onChangeHandler])

  const isOutsideRange = useCallback(() => false, [])

  const getPlainTime = (value?: Date | string): PlainTime | undefined => {
    if (!value) {
      return
    }
    const date = value instanceof Date ? value : parse(value, 'hh:mm b', new Date())

    return dateToTime(date)
  }

  const datePickerPlaceholder = datePickerPlaceholderDict[pickerType]
  const optionalLabel = field.optional ? t('FormFields.OptionalFieldLabelSuffix') : undefined
  const datePickerLabel = t(`${i18nBase}.Dates.${datePickerPlaceholder}`, { label: optionalLabel })

  return {
    isAllowedPastDateTime,
    isOpen,
    handleClose,
    handleOpen: handleWrapperClick,
    pickerType,
    formatValue,
    yearBounds,
    getFormattedTime,
    GMT_ARRAY,
    label: datePickerLabel,
    handleSelectDate,
    handleApplyTime,
    handleOnApplyDate,
    isOutsideRange,
    datePickerTypes,
    getPlainTime,
  }
}
