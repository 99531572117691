import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'

type Props = {
  onSubmit?: () => void
}

export function useSearch({ onSubmit }: Props) {
  const { submitButtonActions } = useAirSearchFormContext()
  const { addSegmentModalsActions } = useReviewTripContext()
  const { costAllocationFormStore } = useCostAllocationContext()
  const { airDynamicSiteMessagesStore, airSearchActions } = useAirSearchContext()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    handleOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
    shouldShowPreSearchModal,
  } = usePreSearch({ onSubmit: submitButtonActions.canSubmitForm })

  function handleSubmit() {
    onSubmit?.()
    addSegmentModalsActions.closeAirSearchModal()
  }

  const handleSearchClick = () => {
    if (isMobile) {
      costAllocationFormStore.checkCostAllocation()
    }

    if (!submitButtonActions.canSubmitSearch) {
      return
    }
    if (isMobile && !costAllocationFormStore.checkCostAllocation()) {
      return
    }

    if (shouldShowPreSearchModal) {
      airSearchActions.searchInBackground()
      handleOpenPreSearchCustomFields()
      return
    }

    airDynamicSiteMessagesStore.dropStore()
    submitButtonActions.canSubmitForm({ onSubmit: handleSubmit })
  }

  return {
    handleSearchClick,
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  }
}
