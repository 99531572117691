import { useTranslation } from 'react-i18next'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { SearchBox } from '../../../../../search-box'
import { ApplyButton, ClearButton, Container } from '../../../../../filters-styled'
import { BrandsMultiSelect } from '../../../../../brands-multi-select'
import { useSearchBrands } from '../../../../../brands-multi-select/use-search-brands'

type Props = {
  brands: BrandFilter[]
  superBrands: SuperBrandFilter[]
  selectedBrands: string[]
  onChange: (values: string[]) => void
  onClose: () => void
}

export const SearchBrandsModalContent = ({
  onClose,
  selectedBrands,
  brands,
  superBrands,
  onChange,
}: Props) => {
  const { t } = useTranslation()
  const i18Base = 'HotelFilters'
  const {
    brandsCache,
    filteredSuperBrands,
    filteredBrands,
    searchText,
    handleSearch,
    handleClear,
    handleApply,
    handleUpdateSelectionModal,
  } = useSearchBrands({
    brands,
    superBrands,
    selectedBrands,
    onApply: (values: string[]) => {
      onChange(values)
      onClose()
    },
    onSelect: (values: string[]) => {
      onChange(values)
    },
  })

  return (
    <>
      <Header
        withBorderBottom
        leftSlot={<Header.CloseButton tabIndex={2} onClick={onClose} />}
        layout="search-input">
        <SearchBox
          tabIndex={1}
          placeholder={`${t(i18Base + '.Hotel.SearchBrand')} ${t(i18Base + '.Hotel.Name')}`}
          searchText={searchText}
          onChange={handleSearch}
        />
      </Header>
      <Modal.Body>
        <Block marginVertical={20} marginHorizontal={16}>
          <Text variant="headline" color="mainText">
            {t(i18Base + '.Hotel.HotelBrands')}
          </Text>
          <Block marginTop={15}>
            <BrandsMultiSelect
              isSuperBrandExpanded={!!searchText}
              superBrands={filteredSuperBrands}
              brands={filteredBrands}
              selectedIdsMap={brandsCache}
              onSelect={handleUpdateSelectionModal}
            />
          </Block>
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <ClearButton onClick={handleClear} btnType="tertiary">
            {t(i18Base + '.ClearAll')}
          </ClearButton>
          <ApplyButton onClick={handleApply}>{t(i18Base + '.ApplyFilters')}</ApplyButton>
        </Container>
      </Modal.Footer>
    </>
  )
}
