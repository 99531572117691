import { useMemo } from 'react'
import type { Segments } from '../../types'
import type { SegmentDetails } from './types'
import { useFlight, useCar, useHotel, useRail } from './use-segment'

export function useTripSegments({
  flightDetails,
  hotelDetails,
  railDetails,
  carDetails,
}: SegmentDetails) {
  const flight = useFlight({ flightDetails })
  const hotel = useHotel({ hotelDetails })
  const car = useCar({ carDetails })
  const rail = useRail({ railDetails })

  const segments: Segments = useMemo(() => {
    return {
      flight,
      hotel,
      car,
      rail,
    }
  }, [flight, hotel, car, rail])

  return segments
}
