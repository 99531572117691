import type { PropsWithChildren } from 'react'

type Props = {
  onSubmit: () => void
  className?: string
  id?: string
}

export function Form({ children, onSubmit, id, className }: PropsWithChildren<Props>) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
        return false
      }}
      id={id}
      className={className}>
      {children}
    </form>
  )
}
