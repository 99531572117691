import { Inject, Service } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { PlacesStorageAction } from '@etta/modules/places-storage/interface/actions/places-storage.action'
import type { Place, Dates } from '@fiji/types'
import { HotelSearchFormStore } from '../stores/hotel-search-form.store'
import { HotelSearchFormQueryService } from './hotel-search-form-query.service'

@Service()
export class HotelSearchFormService {
  constructor(
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly placesStorageAction: PlacesStorageAction,
  ) {}

  setInitialLocation(initialLocation: { lat: number; lng: number }) {
    this.hotelSearchFormStore.setInitialLocation(initialLocation)
  }

  changeLocation(place: Place) {
    this.placesStorageAction.addNewPlace('hotel')(place)

    if (this.hotelSearchFormStore.isEditMode) {
      this.hotelSearchFormStore.updateEditCache({ location: place })
      return
    }

    this.hotelSearchFormQueryService.appendQueryParams({ location: place })
  }

  changeDate({ date, dateEnd }: Dates) {
    if (this.hotelSearchFormStore.isEditMode) {
      this.hotelSearchFormStore.updateEditCache({ checkInDate: date, checkOutDate: dateEnd })
      return
    }
    this.hotelSearchFormQueryService.appendQueryParams({
      checkInDate: date,
      checkOutDate: dateEnd,
    })
  }

  updateSearchHotelsEditCache() {
    const params = this.hotelSearchFormStore.hotelSearchQuery
    this.hotelSearchFormStore.updateEditCache({
      checkInDate: params.checkInDate,
      checkOutDate: params.checkOutDate,
      distance: params.filters?.distance,
      itineraryId: params.itineraryId,
      location: params.location,
      bookingId: params.bookingId,
    })
  }

  onDistanceChange(distance: number) {
    if (this.hotelSearchFormStore.isEditMode) {
      this.hotelSearchFormStore.updateEditCache({ distance })
      return
    }

    const { filters } = this.hotelSearchFormStore.hotelForm

    if (!filters) {
      return
    }

    this.hotelSearchFormQueryService.appendQueryParams({
      hotelFilters: {
        ...filters,
        distance,
      },
    })
  }
}
