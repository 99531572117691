import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { RailActions } from './interface/actions/rail.actions'
import { RailStore } from './interface/stores/rail.store'
import { FiltersStore } from './interface/stores/filters.store'
import { FiltersActions } from './interface/actions/filters.actions'
import { RailQueryParamsStore } from './interface/stores/rail-query-param.store'
import { RailEmissionsStore } from './interface/stores/rail-emissions.store'
import { RailSearchActions } from './interface/actions/rail-search.actions'

export const { useModuleContext: useRailContext } = hookContextFactory({
  railQueryParamStore: RailQueryParamsStore,
  railStore: RailStore,
  railEmissionStore: RailEmissionsStore,
  railActions: RailActions,
  railFiltersStore: FiltersStore,
  railFilterAction: FiltersActions,
  railSearchActions: RailSearchActions,
})
