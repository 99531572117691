import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 30px 10px 24px;
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 12px 0 24px;
`
