import { Action, Inject } from '@etta/di'
import { CheckSubmitButtonService } from '../services/air-search-form/check-submit-button.service'
import { SubmitFormService } from '../services/air-search-form/submit-form.service'
import { AirSearchFormStore } from '../stores/air-search-form.store'
// eslint-disable-next-line import/no-restricted-paths
import { SubmitSearchService } from './../../../recent-searches/interface/services/submit-search.service'

type SubmitFormInput = {
  onSubmit?: () => void
}

@Action()
export class SubmitButtonActions {
  constructor(
    @Inject()
    private readonly checkSubmitButtonService: CheckSubmitButtonService,
    @Inject()
    private readonly submitFormService: SubmitFormService,
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly submitSearchService: SubmitSearchService,
  ) {}

  canSubmitForm(input?: SubmitFormInput) {
    const { onSubmit } = input ?? {}

    if (!this.canSubmitSearch) {
      return
    }

    onSubmit?.()

    if (!this.airSearchFormStore.isEditMode) {
      this.submitSearchService.submitNewFlightSearch({
        flights: this.airSearchFormStore.searchFlights,
        type: this.airSearchFormStore.searchFlightType,
      })
    }

    this.submitFormService.handleSubmitForm()
  }

  get canSubmitSearch() {
    return this.checkSubmitButtonService.canSubmitSearch
  }
}
