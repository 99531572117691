import styled, { css } from 'styled-components'

export const ListFooter = styled.div<{ isBorder: boolean; isInlineView: boolean }>`
  height: 80px;
  padding: 16px 16px;
  box-shadow: ${(p) => (p.isBorder ? `0px -1px 0px ${p.theme.colors.background4}` : '')};

  ${(props) =>
    props.isInlineView &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`

export const ButtonWrapper = styled.div`
  width: 168px;
  height: 44px;
  display: flex;
`
