import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { ContentProps } from '../types'
import { default as BlankLinkIcon } from '../assets/blank-link-icon.svg'
import {
  ContentWrapper,
  LogoWrapper,
  ReadMoreLink,
  BodyText,
  Title,
  ToggleLink,
} from './content-styled'

export function Content({
  viewType,
  title,
  logo,
  mainText,
  readMoreLink,
  shortMainText,
}: ContentProps) {
  const { t } = useTranslation()
  const translatePath = 'SearchSegments.CarRental.SafetyCheck.'
  const isInfoRtpCarDetails = viewType === 'info-rtp-car-details'
  const isInfo = viewType === 'info' || isInfoRtpCarDetails
  const titleVariant = isInfo ? 'footnoteMedium' : 'headline'
  const toggleText = useTogglePopup(false)
  const isModalViewOrOpenInfoText = !isInfo || (isInfo && toggleText.isOpen)
  const textToRender = isModalViewOrOpenInfoText ? mainText : shortMainText
  const showReadMoreLink = readMoreLink && isModalViewOrOpenInfoText

  return (
    <ContentWrapper isInfo={isInfo}>
      {logo && <LogoWrapper isInfo={isInfo} src={logo} alt="" />}
      <Title
        aria-label={title}
        isBlock
        variant={isInfoRtpCarDetails ? 'subHeadStrong' : titleVariant}
        isInfo={isInfo}>
        {title}
      </Title>
      <BodyText
        aria-label={textToRender}
        isInfo={isInfo}
        isBlock
        variant="footnoteMedium"
        color="bodyText"
        dangerouslySetInnerHTML={{ __html: textToRender }}
      />
      {!toggleText.isOpen && isInfo && (
        <ToggleLink
          aria-label={t('Accessibility.TapToViewWithLabel', {
            label: t(translatePath + 'ShowMore'),
          })}
          onClick={toggleText.handleOpen}
          size="small">
          {t(translatePath + 'ShowMore')}
        </ToggleLink>
      )}
      {showReadMoreLink && (
        <ReadMoreLink
          target="_blank"
          href={readMoreLink ?? '#'}
          size="small"
          isInfo={isInfo}
          data-tracking-id="read-more-link">
          {t(translatePath + 'ReadMore')}
          <BlankLinkIcon />
        </ReadMoreLink>
      )}
      {toggleText.isOpen && isInfo && (
        <ToggleLink onClick={toggleText.handleClose} size="small">
          {t(translatePath + 'ShowLess')}
        </ToggleLink>
      )}
    </ContentWrapper>
  )
}
