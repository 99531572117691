import type {
  AirTravelPreferenceInput,
  CarRentalTravelPreferenceInput,
  HotelTravelPreferenceInput,
} from '@fiji/graphql/types'
import type { UpdateAirInput, UpdateCarRentalInput, UpdateHotelInput } from '../types'

export class TravelPreferenceInputMapper {
  static mapToUpdateAirTravelPreferencesInput(input: UpdateAirInput): AirTravelPreferenceInput {
    return {
      airMembership: input.airMembership,
      airSpecialRequest: input.airSpecialRequest,
      homeAirport: input.homeAirport,
      mealPreference: input.mealPreference,
      noteToArranger: input.noteToArranger,
      seatPreference: input.seatPreference,
    } as AirTravelPreferenceInput
  }

  static mapToUpdateHotelTravelPreferencesInput(
    input: UpdateHotelInput,
  ): HotelTravelPreferenceInput {
    return {
      amenity: [], // remove after GQL update schema
      membership: input.hotelMembership,
      specialRequest: input.hotelSpecialRequest,
    }
  }

  static mapToUpdateCarRentalTravelPreferencesInput(
    input: UpdateCarRentalInput,
  ): CarRentalTravelPreferenceInput {
    return {
      carClassWithNone: input.carClassWithNone,
      membership: input.carRentalMembership,
      specialRequest: input.carRentalSpecialRequest,
    }
  }
}
