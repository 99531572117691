import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { title2, linkSmall } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  height: 165px;
`

export const RailEcoCheckImage = styled.img`
  width: 310px;
  height: 160px;
  border-radius: 0px 0px 6px 14px;
  margin-bottom: -5px;
`

export const Title = styled.div`
  padding: 16px 16px 0 0;
`

export const Body = styled.div`
  padding-left: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const RailEmissionDescriptionWrapper = styled.div``

export const BodyLeftIcon = styled(Icon)`
  height: 100%;
`

export const BodyPercent = styled.div`
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
`

export const LearnMore = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  ${linkSmall};
`

export const PercentNumber = styled.p`
  margin: 0;
  display: inline;
`

export const RailEmissionDescription = styled(Text)`
  margin: 9px 0;
`

export const EmissionsSummary = styled.p`
  margin: 0;
  ${title2};
  margin-top: 5px;
`

export const LeftBlock = styled.div``

export const BottomLine = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.success};
  border-radius: 0 0 14px 14px;
  position: relative;
`
