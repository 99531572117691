import type { PropsWithChildren } from 'react'
import { CSSTransition } from 'react-transition-group'
import { appMode } from '@fiji/modes/app-mode'
import { TRANSITION_NAME, TRANSITION_TIMEOUT } from './dialog-transition'

type Props = PropsWithChildren<{
  isOpen: boolean
  isAnimated?: boolean
  isStayInDOM?: boolean
  onEntered: () => void
  onExited: () => void
  transitionName?: string
}>

export function DialogAnimation({
  children,
  isAnimated = true,
  isOpen,
  onEntered,
  onExited,
  isStayInDOM = false,
  transitionName = TRANSITION_NAME,
}: Props) {
  if (appMode.isEttaAll && isAnimated) {
    return (
      <CSSTransition
        appear
        in={isOpen}
        timeout={TRANSITION_TIMEOUT}
        classNames={transitionName}
        onEntered={onEntered}
        onExited={onExited}>
        {children}
      </CSSTransition>
    )
  }

  return (
    <>
      <CSSTransition
        appear
        unmountOnExit={!isStayInDOM}
        in={isOpen}
        timeout={0}
        classNames={''}
        onEntered={onEntered}
        onExited={onExited}>
        <>{children}</>
      </CSSTransition>
    </>
  )
}
