export function getAirportCode(label: string): string | undefined {
  const matches = label.match(/\((.*)\)/)

  if (!matches) {
    return
  }

  const airportCode = matches.pop()

  if (!airportCode || airportCode.length !== 3) {
    return
  }

  return airportCode
}
