import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  List,
  ItemContainer,
  ListItem,
  Location,
  LeftBlock,
  RightBlock,
  BottomLine,
  CentralBlock,
  TopLine,
} from './car-rental-search-form-mobile-recent-searches-styled'

export const CarRentalSearchFormMobileRecentSearchItems = observer(
  function CarRentalSearchFormMobileRecentSearchItems() {
    const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()

    const recentSearchesList = recentSearchesStore.recentSearches.carRental.items

    if (!recentSearchesStore.isShowCarRentaltRecentSearch) {
      return null
    }

    return (
      <List>
        {recentSearchesList?.map((item) => {
          const {
            bottomLine,
            locationName,
            recreateSearch,
          } = recentSearchDataAction.getRecentCarRentalSearches(item.carRentalSearchParams)
          return (
            <li>
              <ItemContainer
                onClick={recreateSearch}
                data-tracking-id="car-rental-recent-search-card">
                <ListItem>
                  <LeftBlock>
                    <Icon name="carRentalPWA" />
                  </LeftBlock>
                  <CentralBlock>
                    <TopLine>
                      <Location>{locationName}</Location>
                    </TopLine>
                    <BottomLine>{bottomLine}</BottomLine>
                  </CentralBlock>
                  <RightBlock>
                    <Icon name="chevronRightPWA" color="borderLight" size={22} />
                  </RightBlock>
                </ListItem>
              </ItemContainer>
            </li>
          )
        })}
      </List>
    )
  },
)
