import { EmissionsDeviationLevel } from '@fiji/enums'

const AVERAGE_DEVIATION_VALUE = 3

type Props = {
  averageSustainabilityScore: number
  sustainabilityScore: number
}

export function getHotelSustainabilityDeviation({
  averageSustainabilityScore,
  sustainabilityScore,
}: Props) {
  if (sustainabilityScore - averageSustainabilityScore > AVERAGE_DEVIATION_VALUE) {
    return {
      deviationLevel: EmissionsDeviationLevel.Less,
    }
  }

  if (sustainabilityScore - averageSustainabilityScore < -AVERAGE_DEVIATION_VALUE) {
    return {
      deviationLevel: EmissionsDeviationLevel.More,
    }
  }

  return {
    deviationLevel: EmissionsDeviationLevel.Average,
  }
}
