import type { FareSegmentSeatRows } from '@fiji/types'

export function getNextSegmentId(
  segmentsSeatRows: FareSegmentSeatRows[],
  selectedSegmentId: string,
): string | undefined {
  const currentSegmentIndex = segmentsSeatRows.findIndex(
    ({ segmentId }) => segmentId === selectedSegmentId,
  )
  const nextSegment = segmentsSeatRows[currentSegmentIndex + 1]

  return nextSegment?.segmentId
}
