import type { FC, PropsWithChildren } from 'react'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'

export const Reference: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Block marginBottom={4} aria-label={`${children}`}>
      <Text variant="footnoteMedium" color="bodyText">
        {children}
      </Text>
    </Block>
  )
}
