import { Block } from '@etta/ui/block'
import { PreferencesLabel } from '@etta/components/preferences-label/preferences-label'
import { SelectBlock } from './select-block'
import type { RailEticketSelectorProps } from './types'
import { useRailEticketSelector } from './use-rail-eticket-selector'

export function RailEticketSelector({
  selectedMethod,
  selectMethodHandler,
  isEticketDisabled,
  isStationTicketMachineDisabled,
  departureStationName,
}: RailEticketSelectorProps) {
  const { preferencesLabel, sortedSelectedBlocks } = useRailEticketSelector({
    selectedMethod,
    selectMethodHandler,
    isEticketDisabled,
    isStationTicketMachineDisabled,
    departureStationName,
  })

  return (
    <>
      <PreferencesLabel icon="eticketPWA">{preferencesLabel}</PreferencesLabel>
      <Block paddingVertical={17}>
        {sortedSelectedBlocks.map(
          ({ id, imageUrl, title, footerSlot, descriptions, isSelected, isDisabled, onClick }) => (
            <SelectBlock
              key={id}
              imageUrl={imageUrl}
              title={title}
              descriptions={descriptions}
              isSelected={isSelected}
              onClick={onClick}
              isDisabled={isDisabled}
              footerSlot={footerSlot}
            />
          ),
        )}
      </Block>
    </>
  )
}
