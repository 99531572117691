import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass } from '@fiji/graphql/types'
import type { PromisedResult } from '@etta/core/type-utils'
import type { DisplayConfigurationValueObject } from '../core/value-objects'
import type { DisplayConfigurationErrorsInstances } from '../core/errors'
import { DisplayConfigurationErrors } from '../core/errors'
import { DisplayConfigurationMapper } from './mapper'
import { DisplayConfigurationDataProvider } from './display-configuration.data-provider'

@Adapter()
export class DisplayConfigurationAdapter {
  constructor(
    @Inject()
    private displayConfigurationDataProvider: DisplayConfigurationDataProvider,
  ) {}

  async getDisplayConfiguration(): PromisedResult<
    DisplayConfigurationValueObject,
    DisplayConfigurationErrorsInstances
  > {
    try {
      const deviceClass =
        screenMatcher.getScreenType() === ScreenType.Desktop
          ? DeviceClass.Desktop
          : DeviceClass.Mobile

      const { data, error } = await this.displayConfigurationDataProvider.getDisplayConfiguration({
        deviceClass,
      })

      if (error) {
        return Result.Err(new DisplayConfigurationErrors.DisplayConfigurationResponse(error))
      }
      const displayConfiguration = DisplayConfigurationMapper.toValueObject(
        data.displayConfiguration,
      )

      return Result.Ok(displayConfiguration)
    } catch (e) {
      return Result.Err(new DisplayConfigurationErrors.DisplayConfigurationUnexpectedError(e))
    }
  }
}
