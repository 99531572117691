import styled from 'styled-components'
import { footnoteStrong, KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  padding: 0 22px;
`

export const ShowDetails = styled.a`
  margin: 16px 10px 16px 0;
  display: inline-block;
  padding: 0 20px 0 0;
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;
  text-decoration: none;

  ${footnoteStrong};
  ${KeyboardNavigationStyle}
`
