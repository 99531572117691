import { useTranslation } from 'react-i18next'
import type { RailCardsListUkProps } from '../../types'
import { RailCardGroup } from '../rail-card-group'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

export function RailCardsListUk({
  savedCards,
  renderGroups,
  handleCardClick,
}: RailCardsListUkProps) {
  const { t } = useTranslation()

  return (
    <>
      {!!savedCards?.length && (
        <RailCardGroup
          cards={savedCards}
          name={t(i18Base + '.SavedCards')}
          handleCardClick={handleCardClick}
          isSavedCards
        />
      )}
      {renderGroups?.map((group, index) => (
        <RailCardGroup
          key={index}
          cards={group.cards}
          name={group.name}
          handleCardClick={handleCardClick}
        />
      ))}
    </>
  )
}
