import type { Room } from '@fiji/graphql/types'

type Args = {
  room: Room
  onSelect?: (room: Room) => void
  onDetailsClick: (room: Room) => void
}

export function useHotelRoom({ room, onSelect, onDetailsClick }: Args) {
  const handleSelect = () => {
    onSelect && onSelect(room)
  }

  const handleDetailsClick = () => {
    onDetailsClick(room)
  }

  return {
    handleSelect,
    handleDetailsClick,
  }
}
