import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { ControlLink, IconWrap } from './baggage-information-mobile.styled'

const i18Base = 'FlightDetailsModal.FaresDetails.'
const i18BaseBaggageInfo = 'BaggageInformation.'

export function BaggageInformationMobile({
  onBaggageModalOpen,
  baggageButtonLabel,
  isBaggageDetailsAvailable,
  carrierUrl,
  carrierName,
}: LayoutProps) {
  const { t } = useTranslation()

  if (isBaggageDetailsAvailable) {
    return (
      <ControlLink
        data-tracking-id="flight-details-fares-baggage-information-button"
        aria-label={baggageButtonLabel}>
        <Link
          aria-label={t(i18Base + 'BaggageInformation', { button: baggageButtonLabel })}
          size="small"
          onClick={onBaggageModalOpen}>
          {baggageButtonLabel}
        </Link>
      </ControlLink>
    )
  }

  return (
    <ControlLink>
      <Block isFlexBlock alignItems="flex-start">
        <IconWrap>
          <Icon name="noCheckedBagInfoPWA" color="bodyText" />
        </IconWrap>
        <div>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18BaseBaggageInfo + 'CheckedBaggageInfoUnavailableFull')}{' '}
            {carrierUrl ? (
              <Link size="small" href={carrierUrl} target="_blank">
                {carrierName}
                <Icon name="externalLinkPWA" color="primary" size={16} />
              </Link>
            ) : (
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18BaseBaggageInfo + 'CheckAirlineWebsite')}
              </Text>
            )}
          </Text>
        </div>
      </Block>
    </ControlLink>
  )
}
