import { RailLayoutType } from '@fiji/enums'
import type { RailCardsListProps } from '../types'
import { RailCardsListUk } from './rail-cards-list-uk'
import { RailCardsListEu } from './rail-cards-list-eu'

export function RailCardList({
  checkSavedCard,
  layout,
  renderGroups,
  savedCards,
  handleEuCardClick,
  handleUkCardClick,
}: RailCardsListProps) {
  if (layout === RailLayoutType.UK) {
    return (
      <RailCardsListUk
        savedCards={savedCards}
        renderGroups={renderGroups}
        handleCardClick={handleUkCardClick}
      />
    )
  }

  if (layout === RailLayoutType.EU) {
    return (
      <RailCardsListEu
        savedCards={savedCards}
        renderGroups={renderGroups}
        handleCardClick={handleEuCardClick}
        checkSavedCard={checkSavedCard}
      />
    )
  }

  return null
}
