import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  TripReviewStatus,
  ItinerarySessionIds,
  TripReviewParams,
  TripReviewParamsState,
} from './types'

const initialState: TripReviewParamsState = {
  tripReviewParams: {},
  itinerarySessionIds: {},
  tripReviewStatus: 'none',
}

const slice = createSlice({
  name: 'trip-review-params',
  initialState,
  reducers: {
    resetTripReviewParams() {
      return initialState
    },
    updateTripReviewItinenaryId(state, action: PayloadAction<string>) {
      state.tripReviewItineraryId = action.payload
    },

    updateTripReviewBookingId(state, action: PayloadAction<string | undefined>) {
      state.tripReviewBookingId = action.payload
    },

    updateTripReviewStatus(state, action: PayloadAction<TripReviewStatus>) {
      state.tripReviewStatus = action.payload

      if (action.payload === 'none') {
        state.tripReviewItineraryId = undefined
      }
    },

    updateTripReviewParams(state, action: PayloadAction<TripReviewParams>) {
      state.tripReviewParams = {
        ...state.tripReviewParams,
        ...action.payload,
      }
    },

    updateItinerarySessionIds(state, action: PayloadAction<ItinerarySessionIds>) {
      state.itinerarySessionIds = {
        ...state.itinerarySessionIds,
        ...action.payload,
      }
    },

    updateTripReviewTransactionGroupId(state, action: PayloadAction<string | undefined>) {
      state.tripReviewTransactionGroupId = action.payload
    },
  },
})

export const tripReviewParamsReducer = slice.reducer
export const {
  updateTripReviewParams,
  updateItinerarySessionIds,
  updateTripReviewStatus,
  updateTripReviewItinenaryId,
  updateTripReviewBookingId,
  updateTripReviewTransactionGroupId,
  resetTripReviewParams,
} = slice.actions
