import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { subHeadMedium, KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
`

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '80%')};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      padding: 0 32px;
    `}
  background-color: ${(props) => props.theme.colors.white};
  height: 100%;
`

export const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 82px;
    height: 32px;
  }
`

export const LogoGoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 126px;
    height: 37px;
  }
`

export const NavigationItemsContainer = styled.nav`
  display: flex;
  justify-content: start;
`

export const NavigationLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 42px;
`

export const NavigationLink = styled(NavLink)`
  text-decoration: none;

  color: ${(props) => props.theme.colors.bodyText};

  &.active {
    color: ${(props) => props.theme.colors.primary};
  }
  ${KeyboardNavigationStyle}
`

export const ProfileContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;
`

export const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;

  border-radius: 10px;
  height: 44px;
  min-width: 70px;

  padding: 5px;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
`

export const CustomProfileLogo = styled.img`
  max-width: 130px;
  max-height: 32px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const ProfileLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${KeyboardNavigationStyle}
`

export const ProfileButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  height: 32px;
  padding: 0;
  gap: 2px;
  ${KeyboardNavigationStyle}
`

export const SwapContainer = styled.div<{ swapLinkPromtWidth: number }>`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  height: 44px;
  width: 44px;
  border-radius: 30px;

  overflow: hidden;

  transition: width 0.3s ease-in-out;

  transition-delay: 0.5s;

  &:hover,
  &:focus-visible {
    transition-delay: 0s;
    width: ${({ swapLinkPromtWidth }) => 44 + (swapLinkPromtWidth | 0) + 'px'};

    span {
      transition-delay: 0s;
      opacity: 1;
    }
  }
  ${KeyboardNavigationStyle}

  span {
    ${subHeadMedium};
    transition: all 0.3s ease-in-out;
    transition-delay: 0.5s;

    opacity: 0;
    white-space: nowrap;
  }
`

export const SwapText = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.mainText};

  > div {
    height: auto;
    width: auto;

    > svg {
      height: 15px;
      width: 15px;
    }
  }
`

export const FeedbackContainer = styled.div`
  text-decoration: none;
  border: 1px solid;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  height: 46px;
  min-width: 128px;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
`
export const FeedbackText = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.mainText};
  font-weight: 700;

  > span {
    margin-left: 7px;
  }
`

export const AnnouncementWrapper = styled.button`
  padding: 13px;
  height: 44px;
  width: 44px;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;

  ${KeyboardNavigationStyle}
`
