import styled from 'styled-components'
import { headline, subHeadMedium } from '@fiji/style/typography'
import { Block } from '@etta/ui/block'

export const Body = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Headline = styled.div`
  ${headline};
  line-height: 2.2rem;
  padding-bottom: 0.8rem;
`

export const Subheadline = styled.div`
  ${subHeadMedium};
  line-height: 2rem;
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
`

export const HalfBlock = styled(Block)`
  min-width: 160px;
  width: 50%;
`

export const HalfBlockInline = styled(Block)`
  display: flex;
  > :first-child {
    margin-right: 4px;
  }
  > :last-child {
    margin-left: 4px;
  }
`
