import { toJS } from 'mobx'
import { Inject, Store } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type {
  AirFilterOptions,
  AirFiltersType,
} from '@etta/modules/air-search/core/value-objects/air-filters'
import type { TripStateStatus } from '@etta/core/entities'
import { TravelPolicyStore } from '@etta/modules/travel-policy'
import { Toggle } from '@etta/interface/services/toggle'
import type { FareData } from '@fiji/types'
import type { SelectedFlight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import type { RateValueObject } from '@etta/core/value-objects'
import type {
  FareAttributesResponseValueObject,
  FareAttributesValueObject,
} from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'
import type { FlightsSortBy } from '../../../core/enums/flights-sort-by'
import type { FlightLeg, GetFlightsResult } from '../../../core/value-objects/get-flights.result'
import { AirSearchDefaultValuesStore } from '../air-search-default-values.store'
import { AirSearchQueryStore } from '../air-search-query.store'
import { getFilterAirports } from '../../services/air-filters/get-filter-airports'
import type { SelectedFareDetails, SelectedFlightDetails } from '../../types'
import { getDefaultFilters } from './get-default-filters'

const OFFSET_START = 0
const OFFSET_STEP = 10
@Store()
export class AirSearchStore {
  private _sortBy: FlightsSortBy | null = null

  private _flightResult: GetFlightsResult | null = null

  searchPageEnd = OFFSET_START

  private isLoadingSearch = false

  private isLoadingFlights = false

  get isLoading() {
    return this.isLoadingSearch || this.isLoadingFlights
  }

  selectedFlights: SelectedFlight[] = []

  isLoadingMore = false

  legNumber: number | null = null

  searchId: string | null = null

  isErrorCreatingSearch = false

  isRestrictedCountryError = false

  isFiltersAppliedAfterSearch = false

  restrictedErrorMessage: string | null = null

  isLastPermittedDateError = false

  lastPermittedDateErrorDate?: string = undefined

  isErrorFlights = false

  isErrorFetchMore = false

  selectedFareDetails: { [key: string]: SelectedFareDetails } | null = null

  activeItineraryToggle = new Toggle()

  existingFlightToggle = new Toggle()

  tripExpiredToggle = new Toggle()

  flightDetailsToggle = new Toggle()

  searchResultsExpiredToggle = new Toggle()

  outOfPolicyModalToggle = new Toggle()

  showActiveItineraryModal = false

  selectedFlightDetails: SelectedFlightDetails | null = null

  selectFlightStatus: TripStateStatus | null = null

  selectedFareData: FareData | null = null

  selectedBrandedFareLabel: string = ''

  isPartialSearchResults = false

  isBackgroundSearchDataReady = false

  private _isFareAttributesLoading = false // no actual use for now

  private _isFareAttributesError = false // no actual use for now

  private _flightLegsFromSearchResult = new LocalStorageItem<FlightLeg[]>(
    '@air/flight-legs-from-search-result',
    {
      initialValue: [],
    },
  )

  private _flightFareAttributesCache = new Map<string, FareAttributesValueObject[]>()

  constructor(
    @Inject() private defaultValuesStore: AirSearchDefaultValuesStore,
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private displayConfigurationStore: DisplayConfigurationStore,
    @Inject() private travelPolicyStore: TravelPolicyStore,
  ) {}

  get isSearchAllowed() {
    const departureDate = this.airSearchQueryStore.searchQueryFlights[0]?.departureDate

    if (!departureDate) {
      return true
    }

    if (!this.isPurchaseAllowed(departureDate)) {
      return this.displayConfigurationStore.flightConfigurations.isOutOfPolicyFlightsSelectable
    }

    return true
  }

  get flightResult() {
    if (!this._flightResult) {
      return null
    }
    const { filters, ...flightResults } = this._flightResult
    return flightResults
  }

  get filterOptions(): AirFilterOptions | undefined {
    if (!this._flightResult?.filters) {
      return undefined
    }
    const {
      airlines,
      airport,
      destinationTime,
      originTimeRange,
      stops,
      fareTier,
    } = this._flightResult.filters

    const { originAirports, destinationAirports } = getFilterAirports(airport)

    return {
      airlines: [...airlines].sort((a, b) => a.name.localeCompare(b.name)),
      originAirports,
      destinationAirports,
      destinationTime: destinationTime?.start && destinationTime?.end ? destinationTime : null,
      originTime: originTimeRange?.start && originTimeRange?.end ? originTimeRange : null,
      stops: [...stops].sort((a, b) => +a.value - +b.value),
      fareTypes: fareTier
        ? [...fareTier].sort((a, b) => a.displayName.localeCompare(b.displayName))
        : [],
    }
  }

  get sortBy() {
    return this._sortBy ?? this.defaultValuesStore.defaultSortBy
  }

  get numberOfAppliedFilters() {
    const isStopsApplied = !checkIsEqual(
      this.afterSearchFilters.stops,
      this.airSearchQueryStore.additionalQueries.filters?.stops,
    )
    const isAirportApplied =
      !checkIsEqual(
        this.afterSearchFilters.originAirports,
        this.airSearchQueryStore.additionalQueries.filters?.originAirports,
      ) &&
      !checkIsEqual(
        this.afterSearchFilters.destinationAirports,
        this.airSearchQueryStore.additionalQueries.filters?.destinationAirports,
      )

    const isAirlinesApplied = !checkIsEqual(
      this.afterSearchFilters.airlines,
      this.airSearchQueryStore.additionalQueries.filters?.airlines,
    )
    const isFareTypeApplied = !checkIsEqual(
      this.afterSearchFilters.fareTypes,
      this.airSearchQueryStore.additionalQueries.filters?.fareTypes,
    )
    const isPolicyApplied = !checkIsEqual(
      this.afterSearchFilters.isMatchedWithPolicy,
      this.airSearchQueryStore.additionalQueries.filters?.isMatchedWithPolicy,
    )

    return [
      isStopsApplied,
      isAirportApplied,
      isAirlinesApplied,
      isFareTypeApplied,
      isPolicyApplied,
    ].filter(Boolean).length
  }

  get isFiltersApplied() {
    if (!this.searchId) {
      return false
    }
    if (!this.airSearchQueryStore.additionalQueries.filters) {
      return false
    }
    if (!this.filterOptions) {
      return false
    }

    return !checkIsEqual(
      toJS(this.afterSearchFilters),
      this.airSearchQueryStore.additionalQueries.filters,
    )
  }

  get afterSearchFilters(): AirFiltersType {
    return getDefaultFilters({
      flights: this.airSearchQueryStore.searchQueryFlights,
      legNumber: this.legNumber,
      filters: this.filterOptions,
      isCustomTimeReplaced: this.flightResult?.isCustomTimeReplaced,
      areNearbyAirportsIncluded: this.displayConfigurationStore.displayConfiguration.flights
        .areNearbyAirportsIncluded,
    })
  }
  get preSearchFilters(): AirFiltersType {
    return getDefaultFilters({
      flights: this.airSearchQueryStore.searchQueryFlights,
      legNumber: this.legNumber,
      areNearbyAirportsIncluded: this.displayConfigurationStore.displayConfiguration.flights
        .areNearbyAirportsIncluded,
    })
  }

  get isFetchedMore() {
    return this.searchPageEnd > OFFSET_START + OFFSET_STEP
  }

  get hasNextPage() {
    if (this.isErrorFlights || this.isLoading || this.isErrorFetchMore) {
      return false
    }

    if (!this.flightResult?.maxResultsSize || !this.flightResult?.pageEnd) {
      return false
    }

    return this.flightResult.pageEnd + 1 < this.flightResult.maxResultsSize
  }

  get flightLegsFromSearchResult(): FlightLeg[] {
    return this._flightLegsFromSearchResult.value ?? []
  }

  get isDsmEnabled(): boolean {
    return this._flightResult?.isDsmEnabled || false
  }

  get fareAttributes() {
    return this._flightFareAttributesCache
  }

  get isFareAttributesLoading() {
    return this._isFareAttributesLoading
  }

  get isFareAttributesError() {
    return this._isFareAttributesError
  }

  isFareOutOfPolicy(id?: string | null) {
    if (!id) {
      return undefined
    }
    const fareDetails = this.selectedFareDetails?.[id]
    return fareDetails?.isInPolicy !== undefined ? !fareDetails.isInPolicy : undefined
  }

  isPurchaseAllowed(startDate: Date): boolean {
    const minAdvancePurchaseDays = this.travelPolicyStore.travelPolicy.minAdvancePurchaseDays

    const localDepartureTimezone = this.airSearchQueryStore.searchQueryFlights[0].originPlace
      ?.timezone

    if (!minAdvancePurchaseDays || !localDepartureTimezone) {
      this.isPartialSearchResults = false
      return true
    }

    const currentDate = new Date()
    const options = { timeZone: localDepartureTimezone }
    const localTime = new Date(currentDate.toLocaleString('en-US', options))

    const minAllowedDate = new Date()
    minAllowedDate.setDate(localTime.getDate() + minAdvancePurchaseDays)

    if (startDate > minAllowedDate) {
      this.isPartialSearchResults = false

      return true
    } else if (startDate.getDate() === minAllowedDate.getDate()) {
      this.isPartialSearchResults = true

      return true
    }

    this.isPartialSearchResults = false
    return false
  }

  setSelectedFlights(flights: SelectedFlight[]) {
    this.selectedFlights = flights
  }

  resetSelectedFlights() {
    this.selectedFlights = []
  }

  setSelectedBrandedFareLabel(value: string) {
    this.selectedBrandedFareLabel = value
  }

  setSelectedFareData(value: FareData | null) {
    this.selectedFareData = value
  }

  setSelectFlightStatus(value: TripStateStatus) {
    this.selectFlightStatus = value
  }

  setShowActiveItineraryModal(value: boolean) {
    this.setShowActiveItineraryModal(value)
  }

  setSelectedFlightDetails(flightDetails: SelectedFlightDetails | null) {
    this.selectedFlightDetails = flightDetails
  }

  setLegNumber(legNumber: number) {
    this.legNumber = legNumber
  }

  setSortBy(sortBy: FlightsSortBy) {
    this._sortBy = sortBy
  }

  setIsFiltersAppliedAfterSearch(isFiltersAppliedAfterSearch: boolean) {
    this.isFiltersAppliedAfterSearch = isFiltersAppliedAfterSearch
  }

  setIsLastPermittedDateError(isLastPermittedDateError: boolean) {
    this.isLastPermittedDateError = isLastPermittedDateError
  }

  setLastPermittedDateErrorDate(lastPermittedDateErrorDate?: string) {
    this.lastPermittedDateErrorDate = lastPermittedDateErrorDate
  }

  setIsRestrictedCountryError(isRestrictedCountryError: boolean) {
    this.isRestrictedCountryError = isRestrictedCountryError
  }

  setRestrictedErrorMessage(restrictedErrorMessage: string | null) {
    this.restrictedErrorMessage = restrictedErrorMessage
  }

  setIsErrorFetchMore(isErrorFetchMore: boolean) {
    this.isErrorFetchMore = isErrorFetchMore
  }

  setOffset(offset: number) {
    this.searchPageEnd = offset
  }

  setIsBackgroundSearchDataReady(val: boolean) {
    this.isBackgroundSearchDataReady = val
  }

  setFlights(flightResult: GetFlightsResult | null) {
    this._flightResult = flightResult

    // FRI-442 save search result to local storage for RTP usage, currently to get NDC info from fares
    if (flightResult?.flightLegs.length) {
      // TODO: FRI-442 should consider deduplicate, as user can do serveral searches, like changing date, airports
      this._flightLegsFromSearchResult.set([
        ...flightResult.flightLegs,
        ...this.flightLegsFromSearchResult,
      ])
    }
  }

  setMoreFlights({
    flightLegs,
    pageStart,
    pageEnd,
    maxResultsSize,
  }: {
    flightLegs: GetFlightsResult['flightLegs']
    pageStart: GetFlightsResult['pageStart']
    pageEnd: GetFlightsResult['pageEnd']
    maxResultsSize: GetFlightsResult['maxResultsSize']
  }) {
    if (!this._flightResult) {
      return
    }

    this._flightResult = {
      ...this._flightResult,
      flightLegs: [...this._flightResult?.flightLegs, ...flightLegs],
      pageEnd,
      pageStart,
      maxResultsSize,
    }
    // FRI-442 save search result to local storage for RTP usage, currently to get NDC info from fares
    this._flightLegsFromSearchResult.set([...flightLegs, ...this.flightLegsFromSearchResult])
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoadingFlights = isLoading
  }

  setIsSearchLoading = (isSearchLoading: boolean) => {
    this.isLoadingSearch = isSearchLoading
  }

  dropOffset() {
    this.searchPageEnd = OFFSET_START
  }

  increaseOffset() {
    this.searchPageEnd += OFFSET_STEP
  }

  setIsLoadingMore = (isLoadingMore: boolean) => {
    this.isLoadingMore = isLoadingMore
  }

  setSearchId = (searchId: string | null) => {
    this.searchId = searchId
  }

  setIsErrorCreatingSearch = (isErrorCreatingSearch: boolean) => {
    this.isErrorCreatingSearch = isErrorCreatingSearch
  }

  setIsErrorFlights = (isErrorFlights: boolean) => {
    this.isErrorFlights = isErrorFlights
  }

  addSelectedFareDetails(
    fareId: string,
    status: TripStateStatus,
    updatedPrice?: RateValueObject | null,
    isInpolicy?: boolean,
  ) {
    const selectedFareDetails = this.selectedFareDetails?.[fareId]
    this.selectedFareDetails = {
      ...this.selectedFareDetails,
      [fareId]: {
        status,
        updatedPrice: updatedPrice ?? selectedFareDetails?.updatedPrice,
        isInPolicy: isInpolicy ?? selectedFareDetails?.isInPolicy,
      },
    }
  }

  setIsFareAttributesLoading(isLoading: boolean) {
    this._isFareAttributesLoading = isLoading
  }

  setIsFareAttributesError(isError: boolean) {
    this._isFareAttributesError = isError
  }

  setFareAttributes(fareAttributesResp: FareAttributesResponseValueObject[]) {
    fareAttributesResp.forEach((attr) => {
      this._flightFareAttributesCache.set(attr.legId, attr.fareAttributes)
    })
  }

  dropStore() {
    this.setIsSearchLoading(false)
    this.setIsFiltersAppliedAfterSearch(false)
    this.dropOffset()
    this.setSearchId(null)
    this.setIsErrorFetchMore(false)
    this.setIsRestrictedCountryError(false)
    this.setRestrictedErrorMessage(null)
    this.setIsLastPermittedDateError(false)
    this.setLastPermittedDateErrorDate(undefined)
    this.setIsErrorCreatingSearch(false)
    this.setIsErrorFlights(false)
    this.setFlights(null)

    this._flightLegsFromSearchResult.remove()
    this._isFareAttributesLoading = false
    this._isFareAttributesError = false
    this._flightFareAttributesCache.clear()
  }
}
