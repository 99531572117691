/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { SwipeableHandlers } from 'react-swipeable'
import { Text } from '@etta/ui/text'
import type { SwipingTimePickerData, SwipingPickerSubtype } from '../types'
import {
  Container,
  DayTime,
  Slider,
  TimeColumn,
  TimeSet,
  AmPmUnit,
  TimeLabel,
} from './picker-styled'
import { TimePoints } from './time-points'

type Props = {
  variant: SwipingPickerSubtype
  data: SwipingTimePickerData
  handleSwipe: [
    firstColumn: SwipeableHandlers,
    secondColum: SwipeableHandlers,
    thirdColumn: SwipeableHandlers,
  ]
  startYear: number
  hourlyRange?: number
}

export function Picker({ variant, data, handleSwipe, hourlyRange, startYear }: Props): JSX.Element {
  const { t } = useTranslation()
  const basePath = 'SwipingDateTimePicker.'

  const { firstBar, secondBar, thirdBar } = data
  const barHeight = 3 * firstBar.height

  const shouldRenderBars = useMemo(() => {
    if (!variant || variant === 'date' || variant === 'future-date' || variant === 'time') {
      return {
        first: true,
        second: true,
        third: true,
      }
    }
    return {
      first: ['only-month', 'only-hour', 'time-range'].includes(variant),
      second: ['only-day', 'only-min', 'time-range'].includes(variant),
      third: ['only-year', 'only-hour'].includes(variant),
    }
  }, [variant])

  const firstBarContent = useMemo(() => {
    return (
      <Container height={barHeight} withLabel={!!firstBar.label}>
        {firstBar.label && (
          <TimeLabel>
            <Text variant="captionStrongCaps" color="bodyText">
              {firstBar.label}
            </Text>
          </TimeLabel>
        )}
        <TimeColumn height={barHeight}>
          <TimeSet {...handleSwipe[0]}>
            <TimePoints
              startYear={startYear}
              type={firstBar.type}
              range={firstBar.range}
              height={firstBar.height}
              activeUnit={Math.abs(firstBar.value)}
              topValues={firstBar.top!}
              swiping={firstBar.swipe}
              flipping={firstBar.flip!}
              variant={variant}
              hourlyRange={hourlyRange}
            />
          </TimeSet>
        </TimeColumn>
      </Container>
    )
  }, [firstBar.value, firstBar.top, firstBar.swipe])

  const secondBarContent = useMemo(
    () => (
      <Container height={barHeight} withLabel={!!secondBar.label}>
        {secondBar.label && (
          <TimeLabel>
            <Text variant="captionStrongCaps" color="bodyText">
              {secondBar.label}
            </Text>
          </TimeLabel>
        )}
        <TimeColumn height={barHeight}>
          <TimeSet {...handleSwipe[1]}>
            <TimePoints
              startYear={startYear}
              type={secondBar.type}
              range={secondBar.range}
              height={secondBar.height}
              activeUnit={Math.abs(secondBar.value)}
              topValues={secondBar.top!}
              swiping={secondBar.swipe}
              flipping={secondBar.flip!}
              unitGroup={secondBar.unitGroup}
              variant={variant}
            />
          </TimeSet>
        </TimeColumn>
      </Container>
    ),
    [secondBar.value, secondBar.top, secondBar.swipe],
  )

  const thirdBarContent = useMemo(
    () => (
      <Container height={barHeight}>
        <TimeColumn height={barHeight}>
          <TimeSet {...handleSwipe[2]}>
            <TimePoints
              startYear={startYear}
              type={thirdBar.type}
              range={thirdBar.range}
              height={thirdBar.height}
              activeUnit={Math.abs(thirdBar.value)}
              topValues={thirdBar.top!}
              swiping={thirdBar.swipe}
              flipping={thirdBar.flip!}
              variant={variant}
            />
          </TimeSet>
        </TimeColumn>
      </Container>
    ),
    [thirdBar.value, thirdBar.top, thirdBar.swipe],
  )

  const amPmSwitcher = useMemo(() => {
    return (
      <Container height={barHeight}>
        <TimeColumn height={barHeight}>
          <DayTime {...handleSwipe[2]} swiping={thirdBar.swipe}>
            <AmPmUnit
              height={thirdBar.height}
              focused={thirdBar.value}
              aria-label={t(basePath + 'Am')}>
              {t(basePath + 'Am')}
            </AmPmUnit>
            <AmPmUnit
              height={thirdBar.height}
              focused={thirdBar.value}
              aria-label={t(basePath + 'Pm')}>
              {t(basePath + 'Pm')}
            </AmPmUnit>
          </DayTime>
        </TimeColumn>
      </Container>
    )
  }, [thirdBar.value, thirdBar.swipe])

  return (
    <Slider withLabel={!!firstBar.label}>
      {shouldRenderBars.first && firstBarContent}
      {shouldRenderBars.second && secondBarContent}
      {shouldRenderBars.third &&
        (variant === 'time' || variant === 'only-hour' ? amPmSwitcher : thirdBarContent)}
    </Slider>
  )
}
