import { appMode } from '@fiji/modes/app-mode'
import { ClientIdentifier } from '@fiji/enums'

export function getClientIdentifier(): ClientIdentifier | undefined {
  switch (true) {
    case appMode.isFijiAll:
      return ClientIdentifier.Fiji

    case appMode.isEttaStandaloneDesktop:
      return ClientIdentifier.PWADesktop

    case appMode.isEttaStandaloneTablet:
      return ClientIdentifier.PWATablet

    case appMode.isEttaStandaloneMobile:
      return ClientIdentifier.PWAMobile

    case appMode.isEttaWebDesktop:
      return ClientIdentifier.WebDesktop

    case appMode.isEttaWebTablet:
      return ClientIdentifier.WebTablet

    case appMode.isEttaWebMobile:
      return ClientIdentifier.WebMobile

    default:
      return ClientIdentifier.Unspecified
  }
}
