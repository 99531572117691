export default function CarMarkerIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3C29.7303 3 36 9.42413 36 17.3443C36 19.6068 35.4731 21.7327 34.5638 23.6328H34.5833C33.5954 26.1415 30.4526 30.4799 27.5564 34.1931C24.984 37.4858 22.6051 40.287 22.0988 40.8831C22.0341 40.9593 22 40.9994 22 41C21.9895 40.9898 11.3881 28.6462 9.41174 23.6328H9.43113C8.5218 21.7327 8 19.6068 8 17.3443C8 9.42413 14.2648 3 22 3ZM10.7245 17.5364C10.7245 23.9147 15.7745 29.089 22 29.089C28.2255 29.089 33.2705 23.9147 33.2705 17.5364C33.2705 11.1581 28.2255 5.98783 22 5.98783C15.7745 5.98783 10.7245 11.1576 10.7245 17.5364ZM28.1192 14.0199H29.5099C29.5099 14.6849 28.9535 15.3498 28.2118 15.2549H28.0264L28.7682 16.9649V21.2399V22.3797H27.0993L26.543 21.2399L17.6422 21.1449L17.1786 22.3797H15.417V21.1449V16.9649L16.1587 15.3498H15.9733C15.2315 15.3498 14.6752 14.7799 14.6752 14.0199H16.066L16.5296 14.4949L17.8276 11.8349C18.1057 11.2649 18.5693 10.9799 19.1256 10.9799H24.8741C25.4303 10.9799 25.894 11.2649 26.0794 11.7399L27.5629 14.5898L28.1192 14.0199ZM19.6819 11.9299C19.3111 11.9299 19.0329 12.1199 18.8475 12.4049L17.6422 15.2549H26.543L25.1523 12.4049C24.9667 12.1199 24.6888 11.9299 24.4106 11.9299H19.6819Z"
        fill="currentColor"
      />
    </svg>
  )
}
