import { Service, Inject } from '@etta/di'
import { FlightFareRulesAdapter } from '@etta/modules/rules-modals/infra/flight-fare-rules-modal/fare-rules-by-transaction-id.adapter'
import { FlightFareRulesStore } from '../store/flight-fare-rules.store'

@Service()
export class FlightFareRulesService {
  constructor(
    @Inject() private flightFareRulesStore: FlightFareRulesStore,
    @Inject() private flightFareRulesAdapter: FlightFareRulesAdapter,
  ) {}

  async getFlightFareRulesByTransactionId(flightSegmentId: string, transactionId: string) {
    this.flightFareRulesStore.setIsLoading(true)
    this.flightFareRulesStore.setFlightFareRules([])
    const result = await this.flightFareRulesAdapter.getFlightFareRulesByTransactionId({
      flightSegmentId,
      transactionId,
    })

    result.match({
      Ok: (flightRules) => {
        this.flightFareRulesStore.setFlightFareRules(flightRules)
        this.flightFareRulesStore.setIsLoading(false)
        this.flightFareRulesStore.setIsError(false)
      },
      Err: () => {
        this.flightFareRulesStore.setIsLoading(false)
        this.flightFareRulesStore.setIsError(true)
      },
    })
  }
}
