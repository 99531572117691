import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { CreateCarRentalSearchDocument } from '@fiji/graphql/hooks'
import type {
  CreateCarRentalSearchMutationVariables,
  CreateCarRentalSearchMutation,
} from '@fiji/graphql/hooks'

@DataProvider()
export class CarRentalSearchServiceDataProvider extends GraphqlDataProvider {
  createCarRentalSearchAbortController: AbortController | null = null

  createCarRentalSearchWithAbortController(input: CreateCarRentalSearchMutationVariables['input']) {
    const controller = new AbortController()
    this.createCarRentalSearchAbortController?.abort()
    this.createCarRentalSearchAbortController = controller

    return this.client.mutate<
      CreateCarRentalSearchMutation,
      CreateCarRentalSearchMutationVariables
    >({
      mutation: CreateCarRentalSearchDocument,
      variables: { input },
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }
}
