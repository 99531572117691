import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { RoomRatesDetails } from '../room-rates-details'
import { RoomRatesTable } from '../room-rates-table'
import type { RoomRatesTypes } from '../types'
import { RoomRatesModalContentLayout } from './layout'
import { useRoomRatesContent } from './use-room-rates-content'

type Props = RoomRatesTypes & {
  processId?: string
}

export const RoomRatesModalContent = observer(function RoomRatesModalContent({
  roomId,
  dailyRates,
  roomType,
  roomsSubtotal,
  processId,
  hotelId,
  hotelName,
  checkIn,
  checkOut,
  feesAndTaxes,
  feesAndTaxesRate,
  totalCost,
  totalCostRate,
  searchId,
  isUpcoming = true,
  roomAverage,
  source,
}: Props) {
  const { isLoading, error, details, refetch, roomTypeText, translations } = useRoomRatesContent({
    searchId,
    hotelId,
    roomId,
    checkIn,
    checkOut,
    processId,
    roomType,
  })

  return (
    <RoomRatesModalContentLayout
      hotelNameTextSlot={
        <Text variant="headline" color="mainText" aria-label={hotelName}>
          {hotelName}
        </Text>
      }
      roomTypeTextSlot={
        <Text variant="footnoteMedium" color="bodyText" aria-label={roomTypeText}>
          {roomTypeText}
        </Text>
      }
      roomDetailsSlot={
        <>
          <Text
            aria-label={translations.roomDetails}
            variant="headline"
            color="mainText"
            role="headline"
            aria-level={2}>
            {translations.roomDetails}
          </Text>
          <Block marginTop={15}>
            <RoomRatesDetails
              isLoading={isLoading}
              details={details}
              isError={!!error}
              onRefetch={refetch}
            />
          </Block>
        </>
      }
      roomRatesSlot={
        <>
          <Text variant="headline" color="mainText" aria-label={translations.roomRates}>
            {translations.roomRates}
          </Text>
          <RoomRatesTable
            roomsSubtotal={roomsSubtotal}
            hotelName={hotelName}
            dailyRates={dailyRates}
            totalCostRate={totalCostRate}
            totalCost={totalCost}
            feesAndTaxes={feesAndTaxes}
            feesAndTaxesRate={feesAndTaxesRate}
            roomAverage={roomAverage}
            checkIn={checkIn}
            checkOut={checkOut}
            source={source}
          />
        </>
      }
      isUpcoming={isUpcoming}
      isDetailsLoading={isLoading}
    />
  )
})
