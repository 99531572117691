export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M100 22.8789C100 33.2101 91.6234 41.5853 81.2938 41.5853C70.9623 41.5853 62.5874 33.2101 62.5874 22.8789C62.5874 12.5477 70.9623 4.17249 81.2938 4.17249C91.6234 4.17249 100 12.5477 100 22.8789Z"
        fill="#EFEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1555 23.0436C96.1555 31.1602 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1602 66.7598 23.0436C66.7598 14.9253 73.3397 8.34491 81.4568 8.34491C89.5755 8.34491 96.1555 14.9253 96.1555 23.0436Z"
        fill="currentColor"
      />
      <path
        d="M87.9615 29.0146L87.9627 29.0158C88.0186 29.0712 88.0566 29.153 88.0566 29.2693C88.0566 29.4983 87.9053 29.6415 87.6969 29.6415C87.5714 29.6415 87.4768 29.6021 87.4023 29.5326L84.0327 26.1868L83.6014 25.7586L83.0931 26.0916C82.2554 26.6404 81.2491 26.9616 80.1746 26.9616C77.2449 26.9616 74.8591 24.5879 74.8591 21.7028C74.8591 18.8177 77.2449 16.444 80.1746 16.444C83.1042 16.444 85.4901 18.8177 85.4901 21.7028C85.4901 22.8241 85.1333 23.8635 84.5279 24.7098L84.157 25.2283L84.6088 25.678L87.9615 29.0146ZM74.9772 21.7028C74.9772 24.5441 77.3237 26.8585 80.1746 26.8585C83.0254 26.8585 85.3719 24.5441 85.3719 21.7028C85.3719 18.8699 83.0263 16.5471 80.1746 16.5471C77.3228 16.5471 74.9772 18.8699 74.9772 21.7028Z"
        fill="white"
        stroke="white"
        strokeWidth="1.50037"
      />
      <path
        d="M22.642 35.7699H26.3999L27.9031 37.2156L31.9116 29.9872C32.4127 29.0234 33.6654 28.0596 35.1685 28.0596H50.7015C52.2047 28.0596 53.4574 29.0234 54.209 30.2281L57.7164 37.2156L58.9691 35.7699H62.7271C62.7271 37.6975 61.2239 39.1432 59.2196 39.1432H58.7185L60.9733 43.4803V53.8411V57.4553H56.2132L54.71 53.8411L30.659 54.082L29.1558 57.4553H24.3957V54.082V43.4803L26.6505 39.1432H26.1494C24.1451 39.1432 22.642 37.6975 22.642 35.7699ZM51.4531 31.9147C50.9521 31.1919 50.2005 30.4691 49.1983 30.4691H36.4212C35.6696 30.4691 34.918 30.951 34.417 31.6738L30.659 38.9023H54.71L51.4531 31.9147ZM52.4552 47.0945L55.7122 45.8898C57.4659 45.4079 57.7164 44.2031 56.9648 42.9984L50.2005 45.4079L52.4552 47.0945ZM29.9074 45.6488L33.4148 47.0945L35.9201 45.6488L28.6547 42.7574C27.6526 43.9622 27.9031 44.926 29.9074 45.6488Z"
        fill="white"
      />
    </svg>
  )
}
