import { useTranslation } from 'react-i18next'
import { RailLayoutType } from '@fiji/enums'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Button } from '@etta/ui/button'
import { RailCardList } from '../rail-card-list'
import { AddRailCardModal } from '../add-rail-card-modal'
import type { RailCardModalProps } from '../types'
import { useRailCardModal } from '../use-rail-card-modal'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

type Props = Pick<
  RailCardModalProps,
  'savedCards' | 'cardGroups' | 'onClose' | 'onSelectUKCard' | 'onSelectEUCard' | 'layout'
> & { anchorRef: React.MutableRefObject<HTMLDivElement | null> }

export function RailCardModalContent({
  savedCards,
  cardGroups,
  onClose,
  onSelectUKCard,
  onSelectEUCard,
  layout,
  anchorRef,
}: Props) {
  const { t } = useTranslation()

  const searchText =
    layout === RailLayoutType.UK ? t(i18Base + '.SearchTextUk') : t(i18Base + '.SearchTextEu')
  const searchLabel = t(i18Base + '.TapToSearch', { label: searchText })

  const {
    addRailCardModal,
    cardsFilter,
    currentClickedEuCard,
    handleAddEuCardsClick,
    handleCardsFilter,
    handleEuCardClick,
    handleUkCardClick,
    checkSavedCard,
    renderGroups,
    selectedSavedCards,
    localSavedCards,
    handleAddSavedCard,
  } = useRailCardModal({ savedCards, cardGroups, onClose, onSelectUKCard, onSelectEUCard })

  return (
    <>
      <Block aria-label={searchLabel} paddingHorizontal={16} paddingBottom={16}>
        <TextField
          type="search"
          value={cardsFilter}
          size="small"
          onChange={handleCardsFilter}
          placeholder={searchText}
        />
      </Block>
      <div ref={anchorRef} />
      <RailCardList
        layout={layout}
        savedCards={localSavedCards}
        renderGroups={renderGroups}
        handleUkCardClick={handleUkCardClick}
        handleEuCardClick={handleEuCardClick}
        checkSavedCard={checkSavedCard}
      />
      {layout === RailLayoutType.EU && (
        <Modal.Footer withBorder>
          <Block paddingHorizontal={16} paddingVertical={16}>
            <Button
              fullWidth
              onClick={handleAddEuCardsClick}
              disabled={selectedSavedCards.length === 0}>
              {t(i18Base + '.AddCards')}
            </Button>
          </Block>
        </Modal.Footer>
      )}

      <AddRailCardModal
        isOpen={addRailCardModal.isOpen}
        handleAddSavedCard={handleAddSavedCard}
        onClose={addRailCardModal.handleClose}
        currentClickedEuCard={currentClickedEuCard}
      />
    </>
  )
}
