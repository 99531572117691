import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Block } from '@etta/ui/block'
import {
  profileInitialsBackground,
  boldWeightText,
  headline,
  withStyledText,
  body,
  getKeyboardNavigationStyle,
} from '@fiji/style'

export const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
`

export const CustomLogo = styled.img`
  border-radius: 6px;
  max-width: 32px;
  max-height: 32px;
`

export const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 10px;
  font-size: 1.7rem;
  text-align: center;
  line-height: 56px;
  background: ${profileInitialsBackground};
  color: ${(props) => props.theme.colors.bodyText1};
  ${boldWeightText};
  ${withStyledText};
  text-transform: uppercase;
`

export const Info = styled.div`
  flex: 1;
`

export const Name = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
  margin-top: 7px;
  margin-bottom: 3px;
`

export const ProfileContainer = styled(Block).attrs(() => ({
  paddingHorizontal: 0,
  paddingLeft: 0,
}))`
  background-color: ${(props) => props.theme.colors.white};
`

export const LinksContainer = styled.div``

const LinkBase = css`
  display: flex;
  position: relative;
  color: ${(props) => props.theme.colors.mainText};
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 15px 0;
  line-height: 26px;
  align-items: center;
  ${body};
  ${withStyledText};

  *:last-child {
    color: ${(props) => props.theme.colors.bodyText};
    margin-left: auto;
  }
`

export const RouterLink = styled(NavLink)`
  ${LinkBase}
`

export const ExternalLink = styled.a`
  ${LinkBase}

  padding: 15px 16px 15px 20px;

  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }
  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const ButtonLink = styled.button`
  border: 0;
  outline: 0;
  width: 100%;
  cursor: pointer;
  ${LinkBase};
`

export const LogoutLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.error};
  border-bottom: none;
  padding: 15px 16px 15px 20px;

  &:active {
    background-color: ${(props) => props.theme.colors.background};
  }
  ${getKeyboardNavigationStyle({ offset: -2 })}
`
