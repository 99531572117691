import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Tooltip } from '@etta/ui/tooltip'
import type { TooltipProps } from '@etta/components/unused-ticket-breakdown-tooltip/types'
import { Block } from '@etta/ui/block'
import { formatRate } from '@fiji/utils/money'
import { UnusedTicketBreakdownContent } from '@etta/components/unused-ticket-breakdown-tooltip/layout/unused-ticket-breakdown-content'
import { TooltipTextContainer } from './unused-ticket-breakdown-tooltip-styled-desktop'

const i18nBase = 'AirSearchResults.UnusedTicketTooltip'

export function UnusedTicketBreakdownTooltipDesktop({
  toolTipIconSlot,
  labelSlot,
  toolTipPlace,
  unusedTicketRate,
  ...rest
}: TooltipProps) {
  const { t } = useTranslation()
  const { mainCost: unusedTicketCost } = formatRate(unusedTicketRate, {
    morpheme: 'prefix',
  })

  const toolTipSlot = (
    <TooltipTextContainer>
      <Block isFlexBlock flexGap={6} paddingBottom={6}>
        <Icon name="unusedTicketPWA" color="success" />
        <Text variant="footnoteStrong" color="mainText1">
          {t(`${i18nBase}.Header`)}
        </Text>
      </Block>
      <UnusedTicketBreakdownContent {...{ ...rest, unusedTicketRate: unusedTicketRate }} />
    </TooltipTextContainer>
  )

  return (
    <Tooltip
      aria-label={t('AirSearchResults.ViewUnusedTicketsTooltip', {
        unusedTicketCost: unusedTicketCost,
      })}
      slot={toolTipSlot}
      isIndependentOfParentWidth
      isWhiteTooltip={true}
      displayOn={rest.isRTP ? 'click' : 'hover'}
      place={toolTipPlace ? toolTipPlace : 'top'}
      placementStyle={{ cursor: 'pointer' }}>
      {toolTipIconSlot ? toolTipIconSlot : <Icon name="unusedTicketPWA" color="success" />}
      {labelSlot}
    </Tooltip>
  )
}
