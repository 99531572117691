import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { UnusedTicketInput } from '@fiji/graphql/hooks'
import { UnusedTicketOptErrors } from '../core/errors/unused-ticket-opt.errors'
import type { UnusedTicketAdapterResult } from '../infra/types'
import { UnusedTicketOptProvider } from './unused-ticket-opt.data-provider/unused-ticket-opt.data-provider'

@Adapter()
export class UnusedTicketOptAdapter {
  constructor(
    @Inject()
    private unusedTicketOptProvider: UnusedTicketOptProvider,
  ) {}

  async applyUnusedTicket(
    input: UnusedTicketInput,
  ): UnusedTicketAdapterResult<{ code: string; message: string; success: boolean }> {
    try {
      const { data, errors } = await this.unusedTicketOptProvider.applyUnusedTicket(input)

      if (errors) {
        return Result.Err(new UnusedTicketOptErrors.ApplyUnusedTicketErrorResponse(errors))
      }

      if (!data?.applyUnusedTicket) {
        return Result.Err(new UnusedTicketOptErrors.ApplyUnusedTicketErrorResponse('No data'))
      }

      return Result.Ok({
        code: data.applyUnusedTicket.code,
        message: data.applyUnusedTicket.message,
        success: true,
      })
    } catch (e) {
      return Result.Err(new UnusedTicketOptErrors.ApplyUnusedTicketUnexpectedError(e))
    }
  }

  async removeUnusedTicket(
    input: UnusedTicketInput,
  ): UnusedTicketAdapterResult<{ code: string; message: string; success: boolean }> {
    try {
      const { data, errors } = await this.unusedTicketOptProvider.removeUnusedTicket(input)

      if (errors) {
        return Result.Err(new UnusedTicketOptErrors.RemoveUnusedTicketErrorResponse(errors))
      }

      if (!data?.removeUnusedTicket) {
        return Result.Err(new UnusedTicketOptErrors.RemoveUnusedTicketErrorResponse('No data'))
      }

      return Result.Ok({
        code: data.removeUnusedTicket.code,
        message: data.removeUnusedTicket.message,
        success: true,
      })
    } catch (e) {
      return Result.Err(new UnusedTicketOptErrors.RemoveUnusedTicketUnexpectedError(e))
    }
  }
}
