import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ContainerWrapper, EditSlotContainer } from './header-full-styled'

type Props = {
  viewSlot: ReactNode
  editSlot: ReactNode
  isEditOpen: boolean
}

export function SearchResultsHeaderBlock({ isEditOpen, editSlot, viewSlot }: Props) {
  const { t } = useTranslation()

  return (
    <ContainerWrapper aria-label={t('TripPlanner.BaseSearch.Header.Search')}>
      {!isEditOpen && viewSlot}
      {isEditOpen && <EditSlotContainer>{editSlot}</EditSlotContainer>}
    </ContainerWrapper>
  )
}
