import { useCallback, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { copyToClipboard } from './use-copy-to-clipboard'
import { SuccessNotification } from './success-notification'

type Props = {
  value: string
  children: ReactNode
}

const DEFAULT_DELAY = 1000

let isShowSuccessAllInstances = false

export function CopyableWrapper({ value, children }: Props) {
  const [isShowSuccess, setShowSuccess] = useState(false)
  const handleCopy = useCallback(async () => {
    try {
      const isCopied = await copyToClipboard(value)

      if (isCopied && !isShowSuccessAllInstances) {
        setShowSuccess(true)

        setTimeout(() => setShowSuccess(false), DEFAULT_DELAY)
      }
    } catch {}
  }, [value])

  useEffect(() => {
    isShowSuccessAllInstances = isShowSuccess
  }, [isShowSuccess])

  useEffect(() => () => setShowSuccess(false), [])

  return (
    <>
      <SuccessNotification isVisible={isShowSuccess} />
      <div onClick={handleCopy}>{children}</div>
    </>
  )
}
