import type { ReactNode } from 'react'
import { Spinner } from '../spinner'
import type { ViewState } from './types'
import { ViewStateModalContent } from './view-state-modal-content'
import { ContainerWithoutText } from './container/container-without-text'

type Props = {
  'data-tracking-id'?: string

  viewState: ViewState
  title?: string
  text?: string
  error?: string
  onDone?: () => void
  children?: ReactNode
}

export function ViewStateModal({ children, ...props }: Props) {
  if (props.viewState === 'hidden') {
    return null
  }

  if (props.viewState === 'loading-without-messages') {
    return (
      <ContainerWithoutText data-tracking-id={'loading-without-messages'}>
        {<Spinner type="spinout" />}
      </ContainerWithoutText>
    )
  }

  return <ViewStateModalContent {...props}>{children}</ViewStateModalContent>
}
