import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace NavigationErrors {
  export const UnexpectedErrorRCSessionSet = createErrorClass('RC Session failed')
  export const SetRCSessionResponse = createErrorClass('RC Session response')
}

export type NavigationErrorsInstances = InstanceType<
  typeof NavigationErrors[keyof typeof NavigationErrors]
>
