export function getUserName({
  firstName,
  middleName,
  lastName,
}: {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
}) {
  const firstNameChar = firstName ? firstName.charAt(0) : ''
  const lastNameChar = lastName ? lastName.charAt(0) : ''
  const initials = [firstNameChar, lastNameChar].join('')
  const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ')

  return {
    initials,
    fullName,
  }
}
