import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { HotelAddressCard } from '@etta/components/hotel-address-card/hotel-address-card'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { HotelDetailsEcoCheck } from '@etta/components/hotel-details-eco-check/hotel-details-eco-check'
import { RoomRatesModal } from '@etta/components/room-rates-modal/room-rates-modal'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { SegmentType, TripStatus } from '@fiji/graphql/types'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { ChangeTripNotAllowedModal } from '@etta/components/change-trip-not-allowed-modal'
import { useTripModifyModal } from '@fiji/hooks/use-trip-modify-modal'
import { useActionButtons, useModifyHotel, useRoomDetails } from '../../../hooks'
import { RoomDetails } from '../../../room-details'
import { Price } from '../../../price'
import { Headline } from '../../../headline'
import { Traveler } from '../../../traveler'
import { DetailsContainer } from '../../../trip-hotel-styled'
import { CONTENT_MARGIN_TOP, CONTENT_WITHOUT_PHOTOS_MARGIN_TOP } from '../../../constants'
import type { TripHotelLayoutProps } from '../../types'

const i18nBase = 'PostBooking.HotelDetails'

export function TripHotelLayoutMobile({
  hotel,
  onClose,
  onCancel,
  tripName,
  tripType,
  travelerName,
  cancellationDate,
  startDate,
  endDate,
  actions,
  itineraryId,
  processId = '',
  hotelEmission,
  transactionRegistryServiceItemId,
}: TripHotelLayoutProps) {
  const {
    address,
    checkIn,
    checkOut,
    name,
    confirmationNumber,
    recordLocator,
    phone,
    photos,
    cancellationPolicy,
    cancellationPolicyDescription,
    status,
  } = hotel
  const { t } = useTranslation()
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const { featureFlags } = useFeatureFlags()
  const { roomDetails, isOpen, handleOpen, handleClose } = useRoomDetails(hotel)
  const actionButtons = useActionButtons({ tripName, hotel, startDate, endDate })
  const isUpcoming = tripType === TripStatus.Reserved
  const isCancelled = tripType === TripStatus.Cancelled
  const isCompleted = tripType === TripStatus.Completed
  const hotelNotStarted = hotel.checkIn ? isFuture(new Date(hotel.checkIn)) : false
  const { handleModifyHotel } = useModifyHotel({
    hotel,
    itineraryId,
    processId,
  })

  const { modalAction, handleViewSupport, toggleModifyModal } = useTripModifyModal({
    isAllowed: actions.isHotelChangeAllowed,
    modifyAction: handleModifyHotel,
  })

  const headerBackgroundColor = getTripDetailsSegmentBackground({
    tripStatus: tripType || TripStatus.Unknown,
    segmentStatus: status,
  })
  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor={headerBackgroundColor}
            animation="opacity"
            leftSlot={<Header.BackButton color="mainText2" onClick={onClose} />}>
            <Header.Title title={name} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <DetailsContainer color={headerBackgroundColor} hasPhotos={!!photos?.length}>
          <Headline name={name || ''} checkIn={checkIn || ''} checkOut={checkOut || ''} />
        </DetailsContainer>
        <div ref={anchorRef} />
        <Block
          paddingHorizontal={16}
          marginTop={photos?.length ? CONTENT_MARGIN_TOP : CONTENT_WITHOUT_PHOTOS_MARGIN_TOP}>
          <Traveler
            confirmationNumber={confirmationNumber}
            recordLocator={recordLocator}
            isCancelled={isCancelled}
            isCompleted={isCompleted}
            cancellationDate={cancellationDate}
            photos={photos}
            hotelName={name || ''}
            travelerName={travelerName}
          />
          <Block marginTop={16}>
            <MobilityCallToAction
              processId={processId}
              dropOffSegmentId={transactionRegistryServiceItemId}
              tripType={tripType}
              segmentType={hotel.type}
            />
          </Block>
          <Block marginTop={16}>
            <RoomDetails
              checkIn={checkIn}
              checkOut={checkOut}
              handleOpenDetails={handleOpen}
              roomDetails={roomDetails}
              isUpcoming={isUpcoming}
            />
          </Block>
          <Block marginTop={16}>
            {address && <HotelAddressCard hotelName={name} phone={phone} address={address} />}
          </Block>
          {featureFlags.isSustainabilityEnabled && (
            <Block marginTop={16}>
              <HotelDetailsEcoCheck
                {...hotelEmission}
                averageLocationSustainabilityScore={
                  hotelEmission.averageLocationSustainabilityScore
                }
              />
            </Block>
          )}
          <Block marginTop={16}>
            <Price
              cancellationPolicy={cancellationPolicy}
              cancellationPolicyDescription={cancellationPolicyDescription}
              roomDetails={roomDetails}
              isCancelled={isCancelled}
              handleOpenDetails={handleOpen}
            />
          </Block>
        </Block>
        <Block marginTop={28} marginBottom={32}>
          <ActionButtons
            type={SegmentType.Hotel}
            isPostBooking
            onShareClick={actionButtons.handleShare}
            onChangeClick={modalAction}
            onCancelClick={onCancel}
            isCancel={!isCompleted && !isCancelled && hotelNotStarted}
            isChange={!isCompleted && !isCancelled && hotelNotStarted}
          />
        </Block>
      </Modal.Body>

      <ChangeTripNotAllowedModal
        isOpen={toggleModifyModal.isOpen}
        onClose={toggleModifyModal.handleClose}
        onViewSupport={handleViewSupport}
        modalTitle={t(`${i18nBase}.Modify`)}
      />
      <RoomRatesModal
        isOpen={isOpen}
        onClose={handleClose}
        processId={processId}
        roomRates={roomDetails}
      />
    </>
  )
}
