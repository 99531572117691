import { useCallback } from 'react'
import { getParsedData } from '@etta/interface/services/query-params/params/get-parsed-data'
import type { OptionsType } from './types'

type Args<T> = {
  initialData?: Partial<T>
  options?: OptionsType<T>
}

export function useParsedData<T>({ options, initialData }: Args<T>) {
  const getParsedDataHandler = useCallback((str) => {
    return getParsedData<T>({ str, options, initialData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    getParsedData: getParsedDataHandler,
  }
}
