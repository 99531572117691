import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace CartErrors {
  export const UnexpectedCartCreateError = createErrorClass('UnexpectedCartCreateError')
  export const UnexpectedCartAddToCartError = createErrorClass('UnexpectedCartAddToCartError')
  export const UnexpectedCartGetCartError = createErrorClass('UnexpectedCartGetCartError')
  export const UnexpectedCartRemoveFromCartError = createErrorClass(
    'UnexpectedCartRemoveFromCartError',
  )
  export const UnexpectedModifyCartError = createErrorClass('UnexpectedModifyCartError')
  export const CartCreateError = createErrorClass('CartCreateError')
  export const CartRemoveError = createErrorClass('CartRemoveError')
  export const CartModifyError = createErrorClass('CartModifyError')
  export const CartAddToCartError = createErrorClass('CartAddToCartError')
  export const CartGetCartError = createErrorClass('CartGetCartError')
  export const CartNotFoundError = createErrorClass('CartNotFoundError')
  export const CartNoSearchIdError = createErrorClass('CartNoSearchIdError')
  export const CartSetOOPJustificationError = createErrorClass('CartSetOOPJustificationError')
  export const CartSetOOPJustificationUnexpectedError = createErrorClass(
    'CartSetOOPJustificationUnexpectedError',
  )
  export const CartGetOOPInfoError = createErrorClass('CartGetOOPInfoError')
  export const CartGetOOPInfoUnexpectedError = createErrorClass('CartGetOOPInfoUnexpectedError')
  export const CartGetCostAllocationError = createErrorClass('CartGetCostAllocationError')
  export const CartGetCostAllocationUnexpectedError = createErrorClass(
    'CartGetCostAllocationUnexpectedError',
  )
  export const CartSetCostAllocationError = createErrorClass('CartSetCostAllocationError')
  export const CartSetCostAllocationUnexpectedError = createErrorClass(
    'CartSetCostAllocationUnexpectedError',
  )
}

export type CartErrorsInstances = InstanceType<typeof CartErrors[keyof typeof CartErrors]>
