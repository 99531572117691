import { EditTripNameDialogLayout } from './layout'
import type { Props } from './types'
import { useEditTripNameDialog } from './use-edit-trip-name-dialog'

export function EditTripNameDialog({ value, onClose, isOpen, onTripNameUpdate }: Props) {
  const {
    inputValue,
    onChange,
    onCloseViewStateModal,
    onSubmit,
    isLoading,
    viewState,
    isDone,
    isDisabled,
  } = useEditTripNameDialog({
    isOpen,
    value,
    onClose,
    onTripNameUpdate,
  })

  return (
    <EditTripNameDialogLayout
      value={inputValue}
      viewState={viewState}
      onClose={onClose}
      onCloseViewStateModal={onCloseViewStateModal}
      onChange={onChange}
      onSubmit={onSubmit}
      isOpen={isOpen}
      isDone={isDone}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  )
}
