import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  padding: 20px 25px;
  margin-bottom: 31px;
  align-items: center;
  display: flex;
`

export const CancelIcon = styled(Icon)`
  margin-right: 15px;
`
