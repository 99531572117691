import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CabinClassMobile } from './mobile'
import { CabinClassDesktop } from './desktop'
import { CabinClassDesktopNew } from './desktop/cabin-class-desktop.new'

export function CabinClass(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CabinClassMobile {...props} />}
      desktopSlot={
        props.isInputButton ? <CabinClassDesktopNew {...props} /> : <CabinClassDesktop {...props} />
      }
    />
  )
}
