import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { useEttaMapContext } from '@etta/modules/etta-map/interface/use-etta-map.context'
import type { Tile } from '../../../types'
import { Container, MapButton, SatelliteButton } from './tile-switcher-styled'

type Props = {
  handleTileChange: (tile: Tile) => void
  selectedTile: Tile
}

const i18nBase = 'CarRentalOfficeSearch.Map'

export function TileSwitcher({ selectedTile, handleTileChange }: Props) {
  const { t } = useTranslation()
  const { ettaMapStore } = useEttaMapContext()
  const { isAppleMap } = ettaMapStore

  if (isAppleMap) {
    return null
  }

  return (
    <Container>
      <MapButton onClick={() => handleTileChange('roadmap')}>
        <Text
          variant={selectedTile === 'roadmap' ? 'subHeadStrong' : 'subHeadMedium'}
          color={selectedTile === 'roadmap' ? 'mainText' : 'bodyText'}>
          {t(`${i18nBase}.Map`)}
        </Text>
      </MapButton>
      <SatelliteButton onClick={() => handleTileChange('satellite')}>
        <Text
          variant={selectedTile === 'satellite' ? 'subHeadStrong' : 'subHeadMedium'}
          color={selectedTile === 'satellite' ? 'mainText' : 'bodyText'}>
          {t(`${i18nBase}.Satellite`)}
        </Text>
      </SatelliteButton>
    </Container>
  )
}
