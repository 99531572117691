import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { UseComboboxGetItemPropsOptions } from 'downshift'
import type { Place } from '@fiji/types'
import { Spinner } from '@etta/ui/spinner'
import { Swap } from '@etta/ui/swap'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { RecentSearchesList } from '@etta/modules/recent-searches/ui/search-form-list/recent-searches-list'
import type { RecentSearchesType } from '@etta/modules/recent-searches/core/value-objects/recent-searches-type.value-object'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { List } from '../../list'
import { PwaErrorMsg, PwaLoader } from './search-mechanism-desktop-styled'

type Props = {
  isLoading: boolean
  errorMessage: string
  searchType?: SearchType
  referencePoints: Place[]
  isHotelSearch: boolean
  getItemProps: (options: UseComboboxGetItemPropsOptions<Place>) => any
  latestPlaces: Place[]
  recentSearchesType?: RecentSearchesType
  highlightedIndex: number
  places: Place[]
  modifiedPlaces: Place[]
  shouldShowYourDestinationsText: boolean
  onModalClose: () => void
}

const i18nPath = 'SearchResults.'

export const SearchMechanismDesktopList = observer(function SearchMechanismDesktopList({
  isLoading,
  errorMessage,
  searchType,
  referencePoints,
  isHotelSearch,
  getItemProps,
  latestPlaces,
  recentSearchesType,
  highlightedIndex,
  places,
  modifiedPlaces,
  shouldShowYourDestinationsText,
  onModalClose,
}: Props) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <PwaLoader>
        <Spinner type="spinout" />
      </PwaLoader>
    )
  }

  if (errorMessage && !isLoading) {
    return (
      <PwaErrorMsg>
        {t(`${i18nPath}PwaNoResults`)}
        <br />
        {t(`${i18nPath}PwaSearchOtherKeywords`)}
      </PwaErrorMsg>
    )
  }

  return (
    <>
      <Swap is={!!errorMessage} isSlot={<em>{errorMessage}</em>}>
        <List
          searchType={searchType}
          title={
            shouldShowYourDestinationsText
              ? t(`${i18nPath}YourDestinations`)
              : t(`${i18nPath}SearchResults`)
          }
          list={isHotelSearch ? modifiedPlaces : places}
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          iconSize="medium"
          dataTrackingId={searchType + '-search-mechanism-your-destinations-list'}
        />
        {isHotelSearch && (
          <List
            searchType={searchType}
            title={t(`${i18nPath}ReferencePoints`)}
            list={referencePoints}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            startIndex={modifiedPlaces.length}
            iconSize="medium"
            dataTrackingId={searchType + '-search-mechanism-reference-points-list'}
          />
        )}
      </Swap>
      {featureFlagsStore.flags.isRecentSearchesEnabled && recentSearchesType ? (
        <RecentSearchesList type={recentSearchesType} onModalClose={onModalClose} />
      ) : (
        <List
          searchType={searchType}
          title={t(`${i18nPath}RecentSearches`)}
          list={latestPlaces}
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          startIndex={places.length}
          iconSize="medium"
          dataTrackingId={searchType + '-search-mechanism-recent-searches-list'}
        />
      )}
    </>
  )
})
