import type { UserPreset, DevEnv } from '../types'
import { Env } from '../types'
import { stage2 } from './stage2'
import { stage3 } from './stage3'
import { rcqa8 } from './rcqa8'
import { rcqa9 } from './rcqa9'
import { rcqa10 } from './rcqa10'
import { rcqa11 } from './rcqa11'
import { rcqa14 } from './rcqa14'
import { prod } from './prod'
import { rcSustain } from './rc-sustain'

function assignEnvToUserPresets(userPresets: UserPreset[], env: Env): DevEnv[] {
  return userPresets.map((preset) => ({
    env,
    ...preset,
  }))
}

export const devEnvPresets: DevEnv[] = [
  ...assignEnvToUserPresets(stage2, Env.Stage2),
  ...assignEnvToUserPresets(stage3, Env.Stage3),
  ...assignEnvToUserPresets(rcqa8, Env.Rcqa8),
  ...assignEnvToUserPresets(rcqa9, Env.Rcqa9),
  ...assignEnvToUserPresets(rcqa10, Env.Rcqa10),
  ...assignEnvToUserPresets(rcqa11, Env.Rcqa11),
  ...assignEnvToUserPresets(rcqa14, Env.Rcqa14),
  ...assignEnvToUserPresets(prod, Env.Prod),
  ...assignEnvToUserPresets(rcSustain, Env.RcSustain),
]
