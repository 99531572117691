import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const HeaderWrapper = styled.div`
  margin: 0 32px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
`

export const ExpansiveBody = styled(Body)`
  flex-grow: 1;
`

export const SqareContent = styled.div`
  padding: 40px 16px;
`

export const RightIndentContent = styled.div`
  overflow-y: auto;
  padding: 22px 80px 32px 32px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

export const LeftIndentTitleDate = styled(Text)`
  text-transform: uppercase;
  margin-left: 8px;
`

export const TitleDate = styled(Text)`
  text-transform: uppercase;
  margin-bottom: 5px;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${(p) => p.theme.colors.borderLight};
  margin: 14px 0;
`

export const InfoWrapper = styled.div`
  display: flex;
`

export const InfoWrapperLeftIndent = styled.div`
  display: flex;
  margin-left: 4px;
`

export const TextWrapper = styled.div`
  margin: 1px 0 0 10px;
`

export const TextWrapperNoSeparator = styled.div`
  margin: 1px 0 0 8px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FullWidthButtonWrapper = styled.div`
  padding: 0 16px 14px 16px;
`

export const NarrowLeftButtonWrapper = styled.div`
  padding: 0 32px 14px 32px;
  margin-right: auto;
  display: block;
  min-width: 260px;
`

export const SegmentContainer = styled.div`
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > * {
    min-height: 74px;
  }
`

export const ConfirmCheckboxWrapper = styled.div`
  padding: 0 32px 17px;
`
