import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Tooltip } from '@etta/ui/tooltip'
import type { Policy } from '@fiji/graphql/types'
import { CarRentalDetailsDesktopTooltipOopReasons } from '../car-rental-details-desktop-tooltip-oop-reasons'
import {
  Container,
  TitlesContainer,
  Row,
  BadgeSeparator,
  BadgeContainer,
  TooltipContent,
  IconContainer,
} from './car-rental-details-desktop-header-styled'

type Props = {
  onClose: () => void
  isOutOfPolicy: boolean
  carRentalTitle: string
  isPreferred: boolean
  carPolicy?: Policy
  customBadge?: string | null
}

export function CarDetailsDesktopHeader({
  onClose,
  isOutOfPolicy,
  isPreferred,
  carRentalTitle,
  carPolicy,
  customBadge,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Header
        minHeight={72}
        rightSlot={
          <IconButton
            icon="closeFilledPWA"
            size="medium"
            color="bodyText"
            onClick={onClose}
            aria-label={t('Accessibility.Header.CloseLabel')}
          />
        }
        withBorderBottom>
        <Block marginVertical={12} marginLeft={32}>
          <TitlesContainer>
            <Text variant="title3" color="mainText">
              {carRentalTitle}
            </Text>
            <Row>
              {isPreferred && !customBadge && (
                <BadgeContainer>
                  <BadgeSeparator />
                  <IconContainer>
                    <Icon name="preferredPWA" />
                  </IconContainer>
                  <Text variant="footnoteMedium" color="bodyText">
                    {t('Badge.PreferredVendor')}
                  </Text>
                </BadgeContainer>
              )}
              {customBadge && (
                <BadgeContainer>
                  <BadgeSeparator />
                  <IconContainer>
                    <Icon name="customPreferredPWA" color="success" />
                  </IconContainer>
                  <Text variant="footnoteMedium" color="bodyText">
                    {customBadge}
                  </Text>
                </BadgeContainer>
              )}
              {isOutOfPolicy && !isPreferred && (
                <BadgeContainer>
                  <Tooltip
                    slot={<CarRentalDetailsDesktopTooltipOopReasons carPolicy={carPolicy} />}>
                    <TooltipContent>
                      <BadgeSeparator />
                      <IconContainer>
                        <Icon name="outOfPolicyPWA" />
                      </IconContainer>
                      <Text variant="footnoteMedium" color="bodyText">
                        {t('CarRentalDetails.OutOfPolicy')}
                      </Text>
                    </TooltipContent>
                  </Tooltip>
                </BadgeContainer>
              )}
            </Row>
          </TitlesContainer>
        </Block>
      </Header>
    </Container>
  )
}
