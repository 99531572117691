import { Service, Inject } from '@etta/di'

import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { HistoryService } from '@etta/interface/services/history.service'
import { AddExpenseCodeMemoStore } from '../store/add-expense-code-memo.store'
import { SelectExpenseCodeStore } from '../store/select-expense-code.store'
import { EnterCustomExpenseCodeStore } from '../store/enter-custom-expense-code.store'
import { RideHailBookStore } from '../store/ride-hail-book.store'
import { SearchRideHailStore } from '../store/search-ride-hail.store'
import { RideHailSearchLocationRefinementStore } from '../store/ride-hail-search-location-refinement.store'
import { RideHailAirportPickupStore } from '../store/ride-hail-airport-pickup-store'
import type { BookRideHailArgs } from '../../infra/types'
import { RideHailBookAdapter } from '../../infra/book/ride-hail-book.adapter'
import { RideHailProvider, RefinementOptionsEnum } from '../../core/enum'
import { BookRideHailErrors } from '../../core/errors/book-ride-hail.error'
import type { BookRideHailQueryParamsType } from '../types'
import { mapToRideHailLocation } from './map-to-ride-hail-location'

@Service()
export class RideHailBookService {
  constructor(
    @Inject() private rideHailBookAdapter: RideHailBookAdapter,
    @Inject() private rideHailBookStore: RideHailBookStore,
    @Inject() private rideHailSearchStore: SearchRideHailStore,
    @Inject() private rideHailSearchLocationRefinementStore: RideHailSearchLocationRefinementStore,
    @Inject() private historyStore: HistoryStore,
    @Inject() private historyService: HistoryService,
    @Inject() private selectExpenseCodeStore: SelectExpenseCodeStore,
    @Inject() private addExpenseCodeMemoStore: AddExpenseCodeMemoStore,
    @Inject() private enterCustomExpenseCodeStore: EnterCustomExpenseCodeStore,
    @Inject() private rideHailAirportPickupStore: RideHailAirportPickupStore,
  ) {}

  appendQueryParams(params: Partial<BookRideHailQueryParamsType>) {
    const nextPath = this.rideHailBookStore.bookQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.replace(nextPath)
  }

  async bookRideHail() {
    this.rideHailBookStore.setErrorMessage(null)
    this.rideHailBookStore.setIsLoading(true)
    this.rideHailBookStore.setIsError(false)
    this.rideHailBookStore.setIsShowUberExternalLink(null)

    const {
      pickUpPlace,
      pickUpAddress,
      dropOffPlace,
      dropOffAddress,
      pickUpDate,
      pickUpTime,
    } = this.rideHailSearchStore.searchRideHailForm
    const { processId } = this.rideHailSearchStore.searchRideHailTripInfo

    const pickUpAccessPoint =
      this.rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.PickUpZoning &&
      this.rideHailSearchLocationRefinementStore.selectedAccessPoint
        ? this.rideHailSearchLocationRefinementStore.selectedAccessPoint
        : undefined

    const dropOffAccessPoint =
      (this.rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.DropOffZoning ||
        this.rideHailSearchLocationRefinementStore.refinementOptionsAction ===
          RefinementOptionsEnum.NoRefinement) &&
      this.rideHailSearchLocationRefinementStore.selectedAccessPoint
        ? this.rideHailSearchLocationRefinementStore.selectedAccessPoint
        : undefined

    const bookRideHailArgs: BookRideHailArgs = {
      provider: RideHailProvider.Uber,
      searchResultID: this.rideHailSearchStore.selectedSearchRideHail?.id || '',
      pickUpLocation: mapToRideHailLocation(pickUpPlace, pickUpAddress, pickUpAccessPoint),
      dropOffLocation: mapToRideHailLocation(dropOffPlace, dropOffAddress, dropOffAccessPoint),
      pickUpDate: pickUpDate ? getFormattedTime(pickUpDate, 'yyyy-MM-dd') : undefined,
      pickUpTime: pickUpTime ? getFormattedTime(pickUpTime) : undefined,
      processID: processId || '',
      flight: this.rideHailAirportPickupStore.arrivalFlight || undefined,
    }

    if (this.enterCustomExpenseCodeStore.customExpenseCodeValue) {
      bookRideHailArgs.expenseCodeID = this.enterCustomExpenseCodeStore.customExpenseCodeValue
    } else if (this.selectExpenseCodeStore.selectedExpenseCodeId) {
      bookRideHailArgs.expenseCodeID = this.selectExpenseCodeStore.selectedExpenseCodeId
    }

    if (this.addExpenseCodeMemoStore.memoValue) {
      bookRideHailArgs.expenseMemo = this.addExpenseCodeMemoStore.memoValue
    }
    const result = await this.rideHailBookAdapter.book(bookRideHailArgs)

    result.match({
      Ok: (resp) => {
        this.rideHailBookStore.setBooking(resp)
        this.rideHailBookStore.setIsLoading(false)
      },
      Err: (err) => {
        this.rideHailBookStore.setIsError(true)
        this.rideHailBookStore.setIsLoading(false)
        if (err instanceof BookRideHailErrors.BookRideHailError) {
          this.rideHailBookStore.setErrorMessage(err.error)
          this.rideHailBookStore.setIsShowUberExternalLink(err.error)
        }
      },
    })
  }

  resetStoresAfterBook() {
    this.rideHailBookStore.dropStore()
    this.rideHailSearchLocationRefinementStore.dropStore()
    this.rideHailSearchStore.dropStore()
    this.selectExpenseCodeStore.dropStore()
    this.enterCustomExpenseCodeStore.dropStore()
    this.addExpenseCodeMemoStore.dropStore()
  }
}
