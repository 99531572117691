/* eslint-disable no-console */
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import { isLocalhost } from '@fiji/utils/is-localhost'
import type { Config } from './types'
import { registerValidSW } from './register-valid-sw'
import { checkValidServiceWorker } from './check-valid-service-worker'

const isServiceWorkerAvailable = 'serviceWorker' in navigator
function register(config?: Config) {
  if (!isServiceWorkerAvailable) {
    return
  }

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL as string, window.location.href)
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return
  }

  window.addEventListener('load', () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    if (!isLocalhost) {
      registerValidSW(swUrl, config)
      return
    }

    // This is running on localhost. Let's check if a service worker still exists or not.
    checkValidServiceWorker(swUrl, config)

    // Add some additional logging to localhost, pointing developers to the
    // service worker/PWA documentation.
    navigator.serviceWorker.ready.then(() => {
      console.log(
        '[sw] This web app is being served network-first by a service ' +
          'worker. To learn more, visit https://cra.link/PWA',
      )
    })
  })
}

function unregister() {
  if (!isServiceWorkerAvailable) {
    return
  }

  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister()
    })
    .catch((error) => {
      console.error(error.message)
    })
}

export const serviceWorkerRegistration = {
  register,
  unregister,
}
