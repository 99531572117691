import { Action, Inject } from '@etta/di'
import { BaseSettingsService } from '@etta/modules/settings/interface/services/base.service'
import { ROUTES } from '@fiji/routes'
import { HistoryService } from '@etta/interface/services/history.service'
import type { HighlightElementValueObject } from '@etta/modules/settings/core/value-objects/highlight-element.value-object'
import { SettingsService } from '../services/settings.service'
import { SettingsStore } from '../stores/settings.store'

@Action()
export class SettingsActions {
  constructor(
    @Inject()
    private readonly settingsService: SettingsService,
    @Inject()
    private readonly settingsStore: SettingsStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly historyService: HistoryService,
  ) {}

  getSettings() {
    return this.settingsService.getStructuredSettings()
  }

  handleSettingsEditOpen() {
    const settings = this.settingsService.getSettings()

    if (!this.settingsStore.timeZoneOptions?.length) {
      this.fetchTimeZones()
    }

    if (!this.settingsStore.currencyOptions?.length) {
      this.fetchCurrencies()
    }

    if (!this.settingsStore.languageOptions?.length) {
      this.fetchLanguages()
    }

    this.settingsStore.setSettingsCardInputValue('country', settings.country)
    this.settingsStore.setSettingsCardInputValue('currency', settings.currency)
    this.settingsStore.setSettingsCardInputValue('locale', settings.locale?.replace('-', '_'))
    this.settingsStore.setSettingsCardInputValue('measurementUnit', settings.measurementUnit)
    this.settingsStore.setSettingsCardInputValue('hourlyTimeFormat', settings.hourlyTimeFormat)
    this.settingsStore.setSettingsCardInputValue('dateFormat', settings.dateFormat)
    this.settingsStore.setSettingsCardInputValue('timeZone', settings.timeZone)

    this.settingsStore.setIsSaveButtonDisabled(true)

    this.settingsStore.toggle.handleOpen()
  }

  handleCurrencyChange(currency: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('currency', currency)
  }

  handleCountryChange(country: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('country', country)
  }

  handleLocaleChange(locale: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('locale', locale)
  }

  handleMeasurementUnitChange(measurementUnit: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('measurementUnit', measurementUnit)
  }

  handleHourlyTimeFormatChange(hourlyTimeFormat: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('hourlyTimeFormat', hourlyTimeFormat)
  }

  handleDateFormatChange(dateFormat: string) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('dateFormat', dateFormat)
  }

  handleTimeZoneChange(timeZone?: string | null) {
    this.settingsStore.setIsSaveButtonDisabled(false)
    this.settingsStore.setSettingsCardInputValue('timeZone', timeZone)
  }

  async fetchTimeZones() {
    this.settingsStore.setIsTimeZoneOptionsLoading(true)
    const timeZones = await this.settingsService.fetchTimeZones()

    this.settingsStore.setTimeZoneOptions(timeZones || null)
    this.settingsStore.setIsTimeZoneOptionsLoading(false)
  }

  async fetchCurrencies() {
    this.settingsStore.setIsCurrencyOptionsLoading(true)
    const currencies = await this.settingsService.fetchCurrencies()

    this.settingsStore.setCurrencyOptions(currencies || null)
    this.settingsStore.setIsCurrencyOptionsLoading(false)
  }

  async fetchLanguages() {
    this.settingsStore.setIsLanguageOptionsLoading(true)
    const languages = await this.settingsService.fetchLanguages()

    this.settingsStore.setLanguageOptions(languages || null)
    this.settingsStore.setIsLanguageOptionsLoading(false)
  }

  handleSettingsEditClose() {
    this.settingsStore.setIsSaveButtonDisabled(true)
    this.settingsStore.toggle.handleClose()
  }

  handleSettingsCardSave() {
    const {
      country,
      currency,
      locale,
      measurementUnit,
      hourlyTimeFormat,
      dateFormat,
      timeZone,
    } = this.settingsStore.settingsCardInput

    const input = {
      country,
      currency,
      locale,
      measurementUnit,
      hourlyTimeFormat,
      dateFormat,
      timeZone,
    }

    this.baseSettingsService.saveUser({
      mode: 'settings-card',
      input,
      handleClose: this.settingsStore.toggle.handleClose,
    })
  }

  navigateToSettingsPage() {
    this.historyService.push(ROUTES.profile.main)
  }

  handleSetHighlightedElement(element: HighlightElementValueObject) {
    this.settingsStore.setHighlightedElement(element)
  }
}
