import type { FunctionComponent, PropsWithChildren } from 'react'
import { appMode } from '@fiji/modes'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { Portal } from '@etta/ui/portal'
import type { ModalPosition } from '@fiji/types'
import { useEscPressed } from '@fiji/hooks/use-esc-pressed'
import { noop } from '@fiji/utils/noop'
import { Container, ModalContainer, ModalFooter, ModalHeaderWrapper } from './modal-styles'
import { CloseButton } from './modal-title/close-button'
import { ModalAnimation } from './modal-animation'
import { ModalTitle } from './modal-title/modal-title'
import { ModalContext } from './modal-context'
import { useModal } from './use-modal'
import { ModalLayout } from './layout'
import type { HorizontalDimension } from './types'

type Props = PropsWithChildren<{
  position?: ModalPosition
  horizontalDimension?: HorizontalDimension
  isVisible: boolean
  handleModalVisibility: (e: boolean) => void
  withOverlay?: boolean
  withShadow?: boolean
  isAnimated?: boolean
  backgroundColor?: LayoutBackgroundColor
  borderRadius?: string | number
  withMaxHeight?: boolean
  disableOverflow?: boolean
  'data-tracking-id'?: string
  onModalExited?: () => void
  className?: string
  withOverlayForMobile?: boolean
  isHalfModal?: boolean
}>

const Modal: FunctionComponent<Props> = ({
  position = 'center',
  horizontalDimension = 'regular',
  children,
  isVisible,
  withOverlay = true,
  withOverlayForMobile = false,
  withShadow = false,
  isAnimated = true,
  withMaxHeight,
  handleModalVisibility,
  backgroundColor,
  borderRadius,
  disableOverflow = true,
  'data-tracking-id': dataTrackingId,
  onModalExited,
  className,
  isHalfModal,
}) => {
  const { onExited, isShown, contextValue, focusTrapRef, handleCloseOverlay } = useModal({
    isVisible,
    onClose: handleModalVisibility,
    onModalExited,
  })

  useEscPressed(handleModalVisibility)

  if (!isShown) {
    return null
  }

  const isWebTripPlanner = appMode.isFijiAll
  const hasAnimateAbility = !isWebTripPlanner && isAnimated

  return (
    <Portal target="#modals-container">
      <ModalContext.Provider value={contextValue}>
        <ModalLayout
          disableOverflow={disableOverflow}
          position={position}
          horizontalDimension={horizontalDimension}
          isVisible={isVisible}
          withOverlay={withOverlay}
          withOverlayForMobile={withOverlayForMobile}
          withShadow={withShadow}
          onClose={handleModalVisibility}
          onCloseOverlay={handleCloseOverlay}
          borderRadius={borderRadius}
          withMaxHeight={withMaxHeight}
          animationSlot={
            <ModalAnimation
              position={position}
              isVisible={isVisible}
              isAnimated={hasAnimateAbility}
              onEntered={noop}
              onExited={onExited}
            />
          }
          containerSlot={
            <Container
              className={className}
              position={position}
              tabIndex={-1}
              isAnimated={hasAnimateAbility}
              ref={focusTrapRef}
              role="dialog"
              data-tracking-id={dataTrackingId}
              backgroundColor={backgroundColor}
              isHalfModal={isHalfModal}
            />
          }>
          {children}
        </ModalLayout>
      </ModalContext.Provider>
    </Portal>
  )
}

// The advantage of this approach is that it works with forwardRef too, preserving the typesafety
const ModalNamespace = Object.assign(Modal, {
  Header: ModalHeaderWrapper,
  Title: ModalTitle,
  Body: ModalContainer,
  Footer: ModalFooter,
  CloseButton,
})

export { ModalNamespace as Modal }
