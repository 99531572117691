import i18next from 'i18next'
import { Inject, Store } from '@etta/di'
import type { DynamicSiteMessageValueObject, RateValueObject } from '@etta/core/value-objects'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { getUserNames } from '@fiji/utils/get-user-names'
import { Toggle } from '@etta/interface/services/toggle'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { SegmentType } from '@etta/core/enums'
import type { PartialSuccessFailedSegmentInfoEntity } from '@etta/modules/post-booking/core/entities/partial-success-failed-segment-info.entity'
import type {
  FlightLeg as FlightLegConfirmationInfo,
  FlightSegment as FlightSegmentConfirmationInfo,
  HotelSegment as HotelSegmentConfirmationInfo,
  CarSegment as CarRentalSegmentConfirmationInfo,
  RailSegment as RailSegmentConfirmationInfo,
  HotelRoom as HotelRoomConfirmationInfo,
  RentalCar as RentalCarConfirmationInfo,
  RailTrain as RailTrainConfirmationInfo,
  Segments as TripSegmentsConfirmationInfo,
} from '@etta/screens/trip-confirmation/types'
import { formatRate } from '@fiji/utils/money'
// eslint-disable-next-line import/no-restricted-paths
import { RouteType } from '@fiji/graphql/types'
import { FlightActionChangeType } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-actions-value-object'
import type { TripEntity } from '../../../core/entities/trip.entity'
import type {
  SegmentPostBookingValueObject,
  GroupedSegmentsGroupValueObject,
  FlightPostBookingValueObject,
  HotelPostBookingValueObject,
  CarRentalPostBookingValueObject,
  CarServicePostBookingValueObject,
  TrainPostBookingValueObject,
  TripDetailsCostSummaryValueObject,
} from '../../../core/value-objects'
import { TripStatus } from '../../../core/enums'
import type { MultipleSegmentsResult, TripQueryParams, TripParams } from '../../types'
import { PostBookingSegmentTypeCheckService } from '../../services/post-booking-segment-type-check.service'
import { getIsMultipleSegments } from './get-is-multiple-segments'
import { INITIAL_TRIP } from './initial-data'
import { toGroupSegmentsGroup } from './get-grouped-segments'
import {
  toCarRentalConfirmationInfo,
  toFlightLegConfirmationInfo,
  toHotelRoomConfirmationInfo,
  toRailLegConfirmationInfo,
} from './utils'

const i18nBase = 'PostBooking.TripDetails'
const GST_TAX_CODES = Array.of('UO', 'NZ', 'WG', 'QR', 'XG')
@Store()
export class PostBookingTripStore {
  airSearchFormModal = new Toggle()
  hotelSearchFormModal = new Toggle()
  carSearchFormModal = new Toggle()

  tripQueryParams = new QueryParams<TripQueryParams>({}, { caseStyle: 'kebab-case' })

  trip: TripEntity = { ...INITIAL_TRIP }

  isLoading: boolean = false

  isError: boolean = false

  constructor(
    @Inject() private segmentTypeCheckService: PostBookingSegmentTypeCheckService,
    @Inject() private historyStore: HistoryStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  get params(): TripParams {
    return this.historyStore.getParams<TripParams>()
  }

  get queryParams() {
    return this.tripQueryParams.getQueryParams(this.historyStore.search)
  }

  get bookingId() {
    return this.tripQueryParams.getQueryParams(this.historyStore.search).bookingId
  }

  get isUpcoming(): boolean {
    return this.trip.type !== TripStatus.Completed && this.trip.type !== TripStatus.Cancelled
  }

  get isTripExist(): boolean {
    return this.trip.tripId !== INITIAL_TRIP.tripId
  }

  get isNdc(): boolean | undefined {
    return this.trip.isNDCTrip
  }

  get isOnHold(): boolean {
    return this.trip.type === TripStatus.OnHold
  }

  get segments(): SegmentPostBookingValueObject[] {
    return this.trip.segments
  }

  get isReturnFlightChangeable(): boolean {
    return !!this.trip.supportedActions.flightChangeRules.find(
      (r) => r.changeType === FlightActionChangeType.ReturnLeg,
    )
  }
  get isEntireFlightChangeable(): boolean {
    return !!this.trip.supportedActions.flightChangeRules.find(
      (r) => r.changeType === FlightActionChangeType.EntireFlight,
    )
  }

  get airTaxes() {
    const taxes = (this.trip.tripCost?.prepaid?.flight || []).flatMap(
      (segment) => segment.fareTaxInfo || [],
    )
    return {
      taxes: taxes,
      isGST: taxes.filter((tax) => GST_TAX_CODES.includes(tax.code)).length > 0,
    }
  }

  // there are duplicated code in preparing segment info, like getting flight direction, time, etc
  // see if can reduce the code after more familiar with related codebase
  get failedSegments(): PartialSuccessFailedSegmentInfoEntity[] {
    const result: PartialSuccessFailedSegmentInfoEntity[] = []

    const flightInfo: PartialSuccessFailedSegmentInfoEntity = {
      id: '',
      type: SegmentType.Flight,
      subSegments: [],
    }

    for (const segment of this.trip.failedSegments) {
      if (segment.type === SegmentType.Flight) {
        const info = toFlightLegConfirmationInfo(segment)
        if (!info) {
          continue
        }

        flightInfo.subSegments.push({
          id: info.id,
          title: info.title,
          infoItems: [
            {
              id: 'aircraftinfo',
              info: info.aircraftWithClass,
            },
            {
              id: 'timeinfo',
              info: info.time,
            },
          ],
        })
        flightInfo.id += segment.legId ?? 'legId'
        continue
      }

      if (segment.type === SegmentType.Hotel) {
        const info = toHotelRoomConfirmationInfo(segment)
        result.push({
          id: segment.id ?? 'hotelId',
          type: SegmentType.Hotel,
          subSegments: [
            {
              id: info.id,
              title: info.title,
              infoItems: [{ id: 'hotelInfo', info: info.info }],
            },
          ],
        })
        continue
      }

      if (segment.type === SegmentType.CarRental) {
        const info = toCarRentalConfirmationInfo(segment)
        result.push({
          id: segment.carId ?? 'carId',
          type: SegmentType.CarRental,
          subSegments: [
            {
              id: info.id,
              title: info.title,
              infoItems: [
                { id: 'pickUpInfo', info: `Pick-up Location: ${info.pickUpLabel}` },
                { id: 'dropOffInfo', info: `Drop-off Location: ${info.dropOffLabel}` },
              ],
            },
          ],
        })
      }
    }

    // putting flight failing in one, as it's in one purchase even
    if (flightInfo.subSegments.length) {
      result.push(flightInfo)
    }

    return result
  }

  get segmentsConfirmationInfo(): TripSegmentsConfirmationInfo {
    const isHotelPaid = !!this.trip.costSummary?.hotel?.isPaid
    const isCarRentalPaid = !!this.trip.costSummary?.carRental?.isPaid

    // flight legs info
    const flightSegments: FlightLegConfirmationInfo[] = []
    const flightCost: RateValueObject = {
      primary: {
        amount: 0,
        currency: 'USD',
      },
    }

    // hotel rooms info
    const hotelSegments: HotelRoomConfirmationInfo[] = []
    // car rentals info
    const carRentalSegments: RentalCarConfirmationInfo[] = []
    // rail legs info
    const railSegments: RailTrainConfirmationInfo[] = []

    for (const segment of this.trip.segments) {
      if (segment.type === SegmentType.Flight) {
        const info = toFlightLegConfirmationInfo(segment)
        if (!info) {
          continue
        }
        const { direction, time, aircraft, cabinClass, confirmation, isWebFare } = info
        flightSegments.push({
          direction,
          time,
          aircraft,
          cabinClass,
          confirmation,
          isWebFare,
        })

        // calculate overall cost of flight
        if (segment.totalCost?.primary.amount) {
          flightCost.primary.amount += segment.totalCost.primary.amount
          flightCost.primary.currency = segment.totalCost.primary.currency
        }

        if (segment.totalCost?.secondary?.amount) {
          if (!flightCost.secondary) {
            flightCost.secondary = {
              amount: 0,
              currency: '',
            }
          }
          flightCost.secondary.amount += segment.totalCost.secondary.amount
          flightCost.secondary.currency = segment.totalCost.secondary.currency
        }

        continue
      }

      if (segment.type === SegmentType.Hotel) {
        const {
          hotelName,
          dates,
          nightsCount,
          roomType,
          confirmation,
          payment,
        } = toHotelRoomConfirmationInfo(segment, isHotelPaid)
        hotelSegments.push({
          hotelName,
          dates,
          nightsCount,
          roomType,
          confirmation,
          payment,
        })
        continue
      }

      if (segment.type === SegmentType.CarRental) {
        const {
          title,
          vendorName,
          pickUp,
          dropOff,
          confirmation,
          payment,
        } = toCarRentalConfirmationInfo(segment, isCarRentalPaid)
        carRentalSegments.push({
          title,
          vendorName,
          pickUp,
          dropOff,
          confirmation,
          payment,
        })
        continue
      }

      if (segment.type === SegmentType.Train) {
        if (!segment.segments) {
          continue
        }

        for (const railLeg of segment.segments) {
          const { direction, time, carriage, seat } = toRailLegConfirmationInfo(railLeg)
          railSegments.push({
            direction,
            time,
            carriage,
            seat,
            isOpenReturn: false,
          })
        }
        if (segment.routeType === RouteType.OpenReturn) {
          const direction = [
            segment.segments[segment.segments.length - 1].stations?.arrival?.stationName,
            segment.segments[0].stations?.departure?.stationName,
          ]
            .filter(Boolean)
            .join('-')
          const fareDescription = segment.fareConditions?.[0]?.tier.name || ''
          railSegments.push({
            direction,
            fareDescription,
            isOpenReturn: true,
          })
        }
      }
    }

    let flightsConfirmationInfo: FlightSegmentConfirmationInfo | undefined
    if (flightSegments.length) {
      const { mainCost, secondCost } = formatRate(flightCost)
      flightsConfirmationInfo = {
        details: { title: i18next.t(`${i18nBase}.SegmentsHeadline.Flights`) },
        payment: {
          title: i18next.t(`${i18nBase}.SegmentsHeadline.Payment`),
          mainTotal: mainCost,
          secondaryTotal: secondCost,
          // card: '', // use creditcard from purchase info for now
        },
        legs: flightSegments,
      }
    }

    let hotelsConfirmationInfo: HotelSegmentConfirmationInfo | undefined
    if (hotelSegments.length) {
      hotelsConfirmationInfo = {
        details: { title: i18next.t(`${i18nBase}.SegmentsHeadline.Hotels`) },
        rooms: hotelSegments,
      }
    }

    let carRentalsConfirmationInfo: CarRentalSegmentConfirmationInfo | undefined
    if (carRentalSegments.length) {
      carRentalsConfirmationInfo = {
        details: { title: i18next.t(`${i18nBase}.CarRental`) },
        rentals: carRentalSegments,
      }
    }

    let railsConfirmationInfo: RailSegmentConfirmationInfo | undefined
    if (railSegments.length) {
      const { mainCost, secondCost } = formatRate({
        primary: this.trip.costSummary?.train?.total?.primary || {
          amount: 0,
          currency: 'USD',
        },
        secondary: this.trip.costSummary?.train?.total?.secondary,
      } as RateValueObject)
      railsConfirmationInfo = {
        details: { title: i18next.t(`${i18nBase}.SegmentsHeadline.Trains`) },
        trains: railSegments,
        payment: {
          title: i18next.t(`${i18nBase}.SegmentsHeadline.Payment`),
          mainTotal: mainCost,
          secondaryTotal: secondCost,
        },
        // TODO: manage the delivery info if necessary as part of the ticket
        // delivery: undefined // use this from old data for now
      }
    }

    return {
      flight: flightsConfirmationInfo,
      hotel: hotelsConfirmationInfo,
      car: carRentalsConfirmationInfo,
      rail: railsConfirmationInfo,
    }
  }

  get messages(): DynamicSiteMessageValueObject[] {
    return this.trip.messages
  }

  get flightSegments(): FlightPostBookingValueObject[] {
    return this.trip.segments.filter(this.segmentTypeCheckService.isFlightSegment)
  }

  get hotelSegments(): HotelPostBookingValueObject[] {
    return this.trip.segments.filter(this.segmentTypeCheckService.isHotelSegment)
  }

  get carSegments(): CarRentalPostBookingValueObject[] {
    return this.trip.segments.filter(this.segmentTypeCheckService.isCarRentalSegment)
  }

  get railSegments(): TrainPostBookingValueObject[] {
    return this.trip.segments.filter(this.segmentTypeCheckService.isTrainSegment)
  }

  get carServiceSegments(): CarServicePostBookingValueObject[] {
    return this.trip.segments.filter(this.segmentTypeCheckService.isCarServiceSegment)
  }

  get groupedSegments(): GroupedSegmentsGroupValueObject[] {
    const { isNewTripDetailsPageEnabled } = this.featureFlagsStore.flags
    return toGroupSegmentsGroup(this.trip.segments, !isNewTripDetailsPageEnabled)
  }

  get failedHotelSegments(): HotelPostBookingValueObject[] {
    return this.trip.failedSegments.filter(this.segmentTypeCheckService.isHotelSegment)
  }

  get isMultipleSegments(): MultipleSegmentsResult {
    return getIsMultipleSegments(this.trip.segments, this.trip.isMultiCity)
  }

  get pnrNumber(): string {
    for (const segment of this.segments) {
      if ('pnrNumber' in segment && segment.pnrNumber?.length) {
        return segment.pnrNumber
      }
      if ('recordLocator' in segment && segment.recordLocator?.length) {
        return segment.recordLocator
      }
    }
    return ''
  }

  get tripCostSummary(): TripDetailsCostSummaryValueObject | undefined {
    return this.segments.length ? this.trip.costSummary : undefined
  }

  get trainConfirmationNumber() {
    const trainSegment = this.railSegments

    if (trainSegment.length > 0) {
      return trainSegment[0].segments?.[0]?.confirmationNumber || undefined
    }

    return undefined
  }

  get appliedRailCard() {
    return this.trip.appliedRailCards.length ? this.trip.appliedRailCards[0] : ''
  }

  get isTripNameChangable(): boolean {
    return this.isUpcoming
  }

  get hasTrainOpenReturnSegment(): boolean {
    const trainSegment = this.railSegments

    return trainSegment.filter((segment) => RouteType.OpenReturn === segment.routeType).length > 0
  }

  get travelerNames(): { travelerFullName: string; travelerInitials: string } | undefined {
    for (const segment of this.segments) {
      if ('traveler' in segment && segment.traveler) {
        const { fullName: travelerFullName, initials: travelerInitials } = getUserNames({
          firstName: segment.traveler.firstName,
          middleName: segment.traveler.middleName,
          lastName: segment.traveler.lastName,
        })
        return { travelerFullName, travelerInitials }
      }
    }
    return undefined
  }

  get hotelSources() {
    return this.hotelSegments.map(({ source }) => source).filter(Boolean) as string[]
  }

  get hasTrainSegmentsWithETicket() {
    return !!this.railSegments.filter(
      (segment) => 'ETicket' === segment.selectedDeliveryOption?.name,
    ).length
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setTrip(trip: TripEntity) {
    this.trip = trip
  }

  dropTrip() {
    this.trip = { ...INITIAL_TRIP }
  }
  dropStore() {
    this.isError = false
    this.isLoading = false
    this.trip = { ...INITIAL_TRIP }
  }
}
