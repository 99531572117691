import type { PropsWithChildren } from 'react'
import type { TextFieldHelperType } from '@etta/ui/text-field/types'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { Skeleton } from '@etta/ui/skeleton'
import { Icon, TextField, TextFieldContainer, SkeletonWrapper } from './drop-down-list-styled'
import type { RenderMode } from './types'

type Props = {
  isOpen: boolean
  onOpen: () => void
  label: string
  value: string
  isDisabled?: boolean
  withThinLabel?: boolean
  withVisibleLabel?: boolean
  helperText?: string
  helperType?: TextFieldHelperType
  'data-tracking-id'?: string
  isLoading?: boolean
  isQuickFilterDesign?: boolean
  isShimmerLoading?: boolean
  renderMode?: RenderMode
  ariaLabel?: string
  isBlurred?: boolean
}

const noOp = () => {}
const IconExpandMore = <Icon name="expandMorePWA" size="medium" color="mainText" />

export function DropDownListLabel({
  children,
  isOpen,
  onOpen,
  value,
  label,
  isDisabled,
  withThinLabel,
  withVisibleLabel,
  helperText,
  helperType,
  isLoading,
  isQuickFilterDesign,
  isShimmerLoading,
  ariaLabel,
  'data-tracking-id': dataTrackingId,
  renderMode = 'simple',
  isBlurred,
}: PropsWithChildren<Props>) {
  const { handleKeyDown } = useA11yKeydown(onOpen)

  if (isShimmerLoading) {
    return (
      <SkeletonWrapper>
        <Skeleton width="100%" height="56px">
          <rect width="100%" height="56px" />
        </Skeleton>
      </SkeletonWrapper>
    )
  }

  if (isQuickFilterDesign) {
    return (
      <TextFieldContainer
        tabIndex={0}
        withVisibleLabel={withVisibleLabel}
        isOpen={isOpen}
        onClick={onOpen}
        onKeyDown={handleKeyDown}
        data-tracking-id={dataTrackingId}
        aria-label={ariaLabel}>
        <TextField
          tabIndex={-1}
          label={label}
          value={value}
          onChange={noOp}
          endSlot={!isDisabled && IconExpandMore}
          isDisabled={isDisabled}
          withThinLabel={withThinLabel}
          helperText={helperText}
          helperType={helperType}
          isLabelEnabled={!value}
          isFieldWithValue={!!value}
          isLoading={isLoading}
          isBlurred={isBlurred}
        />
      </TextFieldContainer>
    )
  }

  if (renderMode === 'component') {
    return (
      <TextFieldContainer
        tabIndex={0}
        withVisibleLabel={withVisibleLabel}
        isOpen={isOpen}
        onClick={onOpen}
        onKeyDown={handleKeyDown}
        data-tracking-id={dataTrackingId}>
        <TextField
          tabIndex={-1}
          label={label}
          value={children ? ' ' : ''}
          onChange={noOp}
          endSlot={!isDisabled && IconExpandMore}
          isDisabled={isDisabled}
          withThinLabel={withThinLabel}
          helperText={helperText}
          helperType={helperType}
          type={children ? 'hidden' : 'text'}
          isBlurred={isBlurred}>
          {children}
        </TextField>
      </TextFieldContainer>
    )
  }

  return (
    <TextFieldContainer
      tabIndex={isDisabled ? -1 : 0}
      withVisibleLabel={withVisibleLabel}
      isOpen={isOpen}
      onClick={onOpen}
      onKeyDown={handleKeyDown}
      data-tracking-id={dataTrackingId}
      aria-label={ariaLabel}>
      <TextField
        tabIndex={-1}
        label={label}
        value={value}
        onChange={noOp}
        endSlot={!isDisabled && IconExpandMore}
        isDisabled={isDisabled}
        withThinLabel={withThinLabel}
        helperText={helperText}
        helperType={helperType}
        isBlurred={isBlurred}
      />
    </TextFieldContainer>
  )
}
