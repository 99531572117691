import { STAGE_NAME } from '@fiji/enums'

export const NAME_BY_DOMAIN = {
  'deem.com': STAGE_NAME.PROD,
  'deemstage2.com': STAGE_NAME.STAGE2,
  'rcqa10.com': STAGE_NAME.QA10,
  'rcqa8.com': STAGE_NAME.QA8,
  'rcqa9.com': STAGE_NAME.QA9,
  'deemstage3.com': STAGE_NAME.STAGE3,
  'rcsustain.com': STAGE_NAME.RCSUSTAIN,
  localhost: STAGE_NAME.DEV,
}

function getStageByDomain(): STAGE_NAME {
  // eslint-disable-next-line no-restricted-globals
  const context = typeof window === 'undefined' ? self : window
  const { hostname } = context.location
  const usedKey = Object.keys(NAME_BY_DOMAIN).find((key) => hostname?.endsWith(key)) as
    | keyof typeof NAME_BY_DOMAIN
    | null
  if (!usedKey) {
    return STAGE_NAME.PROD
  }
  return NAME_BY_DOMAIN[usedKey]
}

export function stageNameEnv(): { stageName: STAGE_NAME } {
  const stageName = getStageByDomain()
  return {
    stageName,
  }
}
