import type { BaggageInfoSegment } from '@fiji/types'
import { InfoModal } from '../../info-modal'
import { BaggageInformationModalContent } from './baggage-information-modal-content'

type Props = {
  baggageInfo: BaggageInfoSegment[]
  isOpen: boolean
  onClose: () => void
}

export function BaggageInformationModal({ baggageInfo, isOpen, onClose }: Props) {
  return (
    <InfoModal isVisible={isOpen} onClose={onClose}>
      <BaggageInformationModalContent onClose={onClose} baggageInfo={baggageInfo} />
    </InfoModal>
  )
}
