import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { GroupedPhotos } from '../types'
import { ThumbnailImage } from '../assets/thumbnail-image'
import { MainImage } from '../assets/main-image'
import {
  Background,
  Container,
  Content,
  EnlargedImage,
  Close,
  SideImages,
  MainFocus,
  LeftArrow,
  RightArrow,
  Sidebar,
} from './lightbox-styled'
import { useLightbox } from './use-lightbox'

type Props = {
  title: string
  photos: GroupedPhotos[]
  index: number
  onClose: () => void
  onChange: (i: number) => void
  isDisabled?: boolean
}

export function Lightbox({ title, photos, index, onClose, onChange, isDisabled }: Props) {
  const {
    visibleIndex,
    thumbsInView,
    remainThumbs,
    getMainImage,
    updateActiveIndex,
    scrollHandler,
    handleKeyboardNavigation,
    ref,
  } = useLightbox({
    maxPhotos: photos.length,
    index,
    onChange,
  })

  return (
    <Container>
      <Background />
      <Content>
        <Close tabIndex={0} onClick={onClose}>
          <Icon name="closePWA" size="large" color="mainText2" />
        </Close>
        <MainFocus>
          <Text variant="title2" color="mainText2">
            {title}
          </Text>
          <EnlargedImage>
            <LeftArrow onClick={(e) => updateActiveIndex(index - 1, e)} disabled={isDisabled}>
              <Icon name="chevronLeftPWA" size="medium" />
            </LeftArrow>
            <MainImage
              onClick={(e) => e.stopPropagation()}
              src={getMainImage(photos.find((photo) => photo.order === index))}
            />
            <RightArrow
              ref={ref}
              onKeyUp={handleKeyboardNavigation}
              onClick={(e) => updateActiveIndex(index + 1, e)}
              disabled={isDisabled}>
              <Icon name="chevronRightPWA" size="medium" />
            </RightArrow>
          </EnlargedImage>
        </MainFocus>
        <Sidebar>
          <SideImages
            onWheel={scrollHandler}
            positionData={{
              top: visibleIndex,
              length: photos.length,
              thumbsInView,
              remainThumbs,
            }}>
            {photos.map((photo, i) => (
              <ThumbnailImage
                key={i}
                onClick={(e) => updateActiveIndex(i, e)}
                src={photo.thumb || photo.large}
                isActive={i === index}
              />
            ))}
          </SideImages>
        </Sidebar>
      </Content>
    </Container>
  )
}
