import {
  Icon,
  SkeletonContent,
  SkeletonWrapper,
} from '@etta/ui/drop-down-list/drop-down-list-styled'
import { Skeleton } from '@etta/ui/skeleton'
import { TextField, TextWrapper } from '@etta/ui/quick-filter-layout/quick-filter-layout-styled'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'

type Props = {
  isOpen: boolean
  onOpen: () => void
  label: string
  value: string
  isShimmerLoading?: boolean
  isDisabled?: boolean
  isLoading?: boolean
}

export function TextLayout({
  isOpen,
  onOpen,
  label,
  value,
  isShimmerLoading,
  isLoading,
  isDisabled,
}: Props) {
  const { handleKeyDown } = useA11yKeydown(onOpen)

  if (isShimmerLoading) {
    return (
      <SkeletonWrapper>
        <SkeletonContent>
          <Skeleton width="100%" height="15px">
            <rect width="77px" height="100%" />
          </Skeleton>
          <Icon name="expandMorePWA" size="medium" color="bodyText2" />
        </SkeletonContent>
      </SkeletonWrapper>
    )
  }

  const endSlot = !isDisabled && (
    <Icon name="expandMorePWA" size="medium" color={isLoading ? 'bodyText2' : 'mainText'} />
  )

  return (
    <TextWrapper tabIndex={0} isOpen={isOpen} onClick={onOpen} onKeyDown={handleKeyDown}>
      <TextField
        aria-hidden
        tabIndex={-1}
        label={label}
        value={value}
        endSlot={endSlot}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isFieldWithValue={!!value}
      />
    </TextWrapper>
  )
}
