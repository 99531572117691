import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import type { TripDetailsCarServiceDetailsRules } from '@fiji/graphql/types'
import { DetailsAndRules } from './details-and-rules'

export type Props = {
  onClose: () => void
  detailsRules?: TripDetailsCarServiceDetailsRules | null
  isVisible: boolean
}

const i18nBase = 'CarServiceDetails.DetailsAndRules'

export function DetailsAndRulesModal({ onClose, isVisible, detailsRules }: Props) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Title withBorder>{t(`${i18nBase}.Headline`)}</Modal.Title>
      <Modal.Body>
        <DetailsAndRules detailsRules={detailsRules} />
      </Modal.Body>
    </Modal>
  )
}
