import { useHistory } from 'react-router-dom'
import { useRef, useState } from 'react'
import { usePassportContext } from '@etta/modules/booking'
import { useItinerary } from '@fiji/hooks/itinerary/use-itinerary'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useStartOverModal } from '@fiji/hooks/use-start-over-modal/use-start-over-modal'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import { useLoadPostBooking } from '@fiji/hooks/use-load-post-booking'
import { ROUTES } from '@fiji/routes'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { SegmentState } from '@fiji/graphql/types'
import { StepName } from '@etta/screens/checkout-page/types'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import type { AvailablePreferences } from '../types'
import { usePreferences } from './use-preferences'
import { useProfile } from './use-profile'
import { useLoadTravelerInfo } from './use-load-traveler-info'

export function useTravelerInfo() {
  const history = useHistory()
  const { queryParams } = useCheckoutQuery()
  const { bookingId, isHoldFlow } = queryParams
  const { itineraryId } = useItinerary()
  const { isShowDelegateName } = useShowDelegateName()
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const isCostDisplayed = isMobile
  const isTravelerInfoPage = useRouteMatch(StepName.TravelerInfo)
  const { passportStore } = usePassportContext()
  const { selectedPassportId } = passportStore

  const [editPassportId, setEditPassportId] = useState<number | null | undefined>(null)

  const contentRef = useRef<HTMLDivElement>(null)
  const viewStateParams = useViewState()

  const {
    tripRate,
    isTravelerInfoLoading,
    isTravelerInfoError,
    segmentsExisting,
    segmentsState,
    onReloadTravelerInfo,
    primaryTravelerOptions,
    areCustomFieldsExist,
    membershipState,
    isPassportRequired,
    primaryTraveler,
    isCostAllocationExist,
  } = useLoadTravelerInfo()

  const isFlightsExist = segmentsExisting.isFlightExist
  const isRailExist = segmentsExisting.isRailExist

  const {
    isPreferenceLoading,
    preferenceError,
    carRentalPreferences,
    hotelPreferences,
    flightPreferences,
    railPreferences,
  } = usePreferences({
    onChangeViewState: viewStateParams.onChangeViewState,
  })

  const {
    onSaveProfile,
    personalInfo,
    isProfileLoading,
    profileError,
    isProfileValid,
    isPassportValid,
    profileErrorMessage,
    passports,
    profileOptions,
  } = useProfile({
    onChangeViewState: viewStateParams.onChangeViewState,
    options: primaryTravelerOptions,
    selectedPassportId,
  })

  const { isTripOnHold } = useLoadPostBooking({ bookingId })

  const onError = () => {
    if (!contentRef?.current) {
      return
    }
    contentRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onSubmit = () => {
    const isOnHold = isHoldFlow || false
    if (!isProfileValid) {
      onError()
      return
    }
    if (isPassportRequired && (!isPassportValid || !selectedPassportId)) {
      onError()
      return
    }
    if (!isOnHold && (areCustomFieldsExist || isCostAllocationExist)) {
      history.push(
        ROUTES.checkout({
          itineraryId,
          bookingId,
          checkoutStep: 'additional-info',
          isHoldFlow,
        }),
      )
      return
    }
    history.push(
      ROUTES.checkout({
        itineraryId,
        bookingId,
        checkoutStep: 'payment-info',
        isHoldFlow,
      }),
    )
  }

  const onBack = () => {
    history.push(ROUTES.reviewTrip.main({ itineraryId, bookingId }))
  }

  const isFirstRender = useRef(true)

  const isLoading =
    isFirstRender.current || isTravelerInfoLoading || isProfileLoading || isPreferenceLoading
  const isError = profileError || isTravelerInfoError || preferenceError

  if (isFirstRender.current) {
    isFirstRender.current = false
  }

  const segmentsToRender = getSegmentsToRender()

  const availableToSelect: AvailablePreferences = getAvailablePreferencesToSelect()

  const newPassportToggle = useTogglePopup()
  const existingPassportToggle = useTogglePopup()

  const updateEditPassport = (id?: number | null) => {
    existingPassportToggle.handleOpen()
    setEditPassportId(id)
  }

  const { startOverModalSlot } = useStartOverModal({
    isLoading,
    bookingId,
    isPreventForward: isTravelerInfoPage,
    isPreventBack: false,
  })

  return {
    isFlightsExist,
    isRailExist,
    viewStateParams,
    onSaveProfile,
    flightPreferences,
    tripRate,
    carRentalPreferences,
    hotelPreferences,
    isProfileValid,
    personalInfo,
    profileErrorMessage,
    segmentsState,
    isLoading,
    isError: !!isError,
    contentRef,
    onBack,
    onSubmit,
    onReload: onReloadTravelerInfo,
    primaryTravelerOptions,
    bookingId,
    segmentsToRender,
    membershipState,
    availableToSelect,
    passports,
    isPassportRequired,
    isPassportSelected: !!selectedPassportId,
    newPassportToggle,
    existingPassportToggle,
    updateEditPassport,
    editPassportId,
    primaryTraveler,
    startOverModalSlot,
    isShowDelegateName,
    profileOptions,
    railPreferences,
    isCostDisplayed,
    areCustomFieldsExist,
    isCostAllocationExist,
    isHoldFlow,
  }

  function getAvailablePreferencesToSelect() {
    const availableToSelect: AvailablePreferences = {}
    if (segmentsToRender.isAir) {
      availableToSelect.air = {
        isMembership: !!flightPreferences.airMemberships?.length,
        isMeal: !!flightPreferences.mealOptions?.length,
        isSpecialRequest: !!flightPreferences.airSpecialRequests?.length,
      }
    }
    if (segmentsToRender.isHotel) {
      availableToSelect.hotel = {
        isMembership: !!hotelPreferences.hotelLoyaltyProgram.options.length,
        isSpecialRequest: !!hotelPreferences.hotelSpecialRequest.options,
      }
    }
    if (segmentsToRender.isCar) {
      availableToSelect.car = {
        isMembership: !!carRentalPreferences.carRentalLoyaltyProgram.options.length,
        isSpecialRequest: !!carRentalPreferences.carRentalSpecialRequest.options.length,
      }
    }
    if (segmentsToRender.isRail) {
      availableToSelect.rail = {
        isMembership: !!railPreferences.railLoyaltyProgram.options.length,
        isSpecialRequest: !!railPreferences.railSpecialRequest.options.length,
        isRailPreferences: true,
      }
    }
    return availableToSelect
  }

  function getSegmentsToRender() {
    return {
      isAir: bookingId
        ? !!(
            segmentsState.flightState === SegmentState.Changed ||
            segmentsState.flightState === SegmentState.New ||
            (segmentsExisting.isFlightExist && isTripOnHold)
          )
        : segmentsExisting.isFlightExist,
      isCar: bookingId
        ? !!(
            segmentsState.carRentalState === SegmentState.Changed ||
            segmentsState.carRentalState === SegmentState.New ||
            (segmentsExisting.isCarRentalExist && isTripOnHold)
          )
        : segmentsExisting.isCarRentalExist,
      isHotel: bookingId
        ? !!(
            segmentsState.hotelState === SegmentState.Changed ||
            segmentsState.hotelState === SegmentState.New ||
            (segmentsExisting.isHotelExist && isTripOnHold)
          )
        : segmentsExisting.isHotelExist,
      isRail: bookingId
        ? !!(
            segmentsState.railState === SegmentState.Changed ||
            segmentsState.railState === SegmentState.New ||
            (segmentsExisting.isRailExist && isTripOnHold)
          )
        : segmentsExisting.isRailExist,
    }
  }
}
