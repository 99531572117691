import type { AddButtonSettings, AddButtonComponentProps } from '../types'
import { TripReviewAddButtonLayout } from './layout'
import { useTripReviewAddButton } from './use-trip-review-add-button'

export function TripReviewAddButton(props: AddButtonSettings) {
  const { type, isDisabled, onClick, isSingle } = props
  const { label, ariaLabel, iconName, iconColor } = useTripReviewAddButton({ type, isDisabled })
  const componentProps: AddButtonComponentProps = {
    label,
    ariaLabel,
    iconName,
    iconColor,
    isDisabled,
    onClick,
    isSingle,
  }

  return <TripReviewAddButtonLayout {...componentProps} />
}
