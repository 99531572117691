import * as R from 'ramda'
import { useEffect } from 'react'
import { useUnusedTicketsContext } from '@etta/modules/unused-tickets/interface/use-unused-tickets.context'
import type { UnusedTicket } from '@fiji/graphql/types'

export function useGetUnusedTickets() {
  const { unusedTicketsAction, unusedTicketsStore } = useUnusedTicketsContext()

  useEffect(() => {
    unusedTicketsAction.getUnusedTickets()
  }, [unusedTicketsAction])

  const unusedTickets = R.path<UnusedTicket[]>(['unusedTickets'], unusedTicketsStore)

  return {
    unusedTickets,
    isLoading: unusedTicketsStore.isLoading,
    hasError: unusedTicketsStore.isError,
  }
}
