import { useCallback, useMemo, useState } from 'react'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'

type Args = {
  brands: BrandFilter[]
  superBrands: SuperBrandFilter[]
  selectedBrands: string[]
  onApply?: (values: string[]) => void
  onSelect?: (values: string[]) => void
}

function isTextSearched(searchText: string, checkText: string) {
  return checkText.toLowerCase().includes(searchText)
}

function getBrandsByDisplayName(searchText: string, brands: BrandFilter[]) {
  return brands.filter((brand) => isTextSearched(searchText, brand.brandDisplayName))
}

export function useSearchBrands({ selectedBrands, onApply, brands, superBrands, onSelect }: Args) {
  const [searchText, setSearchText] = useState('')
  const handleSearch = useCallback((search: string) => setSearchText(search.toLowerCase()), [])
  const selectedIdsMap = useMemo(() => {
    return new Set(selectedBrands)
  }, [selectedBrands])

  const [brandsCache, setBrandsCache] = useState(selectedIdsMap)

  const filteredSuperBrands = useMemo(() => {
    return superBrands.reduce<SuperBrandFilter[]>((acc, superBrand) => {
      if (isTextSearched(searchText, superBrand.brandDisplayName)) {
        acc.push(superBrand)
        return acc
      }
      const foundedBrands = getBrandsByDisplayName(searchText, superBrand.brands)
      if (foundedBrands.length === 0) {
        return acc
      }

      const node = {
        ...superBrand,
        brands: foundedBrands,
      }
      acc.push(node)

      return acc
    }, [])
  }, [searchText, superBrands])

  const filteredBrands = useMemo(() => {
    return getBrandsByDisplayName(searchText, brands)
  }, [searchText, brands])

  const handleClear = useCallback(() => {
    onApply?.([])
    setBrandsCache(new Set([]))
  }, [onApply])

  const handleApply = useCallback(() => {
    onApply?.(Array.from(brandsCache))
  }, [brandsCache, onApply])

  const handleUpdateSelection = useCallback(
    (idsMap: Set<string>) => {
      onSelect?.(Array.from(idsMap))
      setBrandsCache(idsMap)
    },
    [onSelect],
  )

  const handleUpdateSelectionModal = useCallback((idsMap: Set<string>) => {
    setBrandsCache(idsMap)
  }, [])

  return {
    brandsCache,
    selectedIdsMap,
    filteredSuperBrands,
    filteredBrands,
    searchText,
    handleSearch,
    handleClear,
    handleApply,
    handleUpdateSelection,
    handleUpdateSelectionModal,
  }
}
