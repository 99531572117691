export default function DrinksIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.1639 8.69858L28.4912 8.3231L28.1639 8.69858C28.4902 8.98297 28.5748 9.488 28.3621 9.87853L28.2692 10.0179L25.764 13.2443L25.1378 14.0508L26.1588 14.051L31.9977 14.052H31.9978C32.6243 14.052 33.0855 14.8391 32.6909 15.4717L32.6253 15.5647L22.6039 28.4776L22.4989 28.6129V28.7842V33.1743V33.6743L22.9988 33.6743L24.7593 33.6744H24.7593C25.1924 33.6744 25.5923 34.0553 25.5923 34.5872C25.5923 35.066 25.2644 35.4269 24.8845 35.4898L24.7415 35.5H18.5746C18.1415 35.5 17.7416 35.1191 17.7416 34.5872C17.7416 34.1084 18.0694 33.7475 18.4494 33.6846L18.5924 33.6744L20.3334 33.6743L20.8333 33.6743V33.1743V28.7842V28.6129L20.7283 28.4775L16.8912 23.5351L16.8572 23.4914L16.8145 23.4561C16.8088 23.4514 16.8029 23.4464 16.7964 23.4408C16.7022 23.3586 16.6281 23.2588 16.5753 23.1476L16.552 23.0984L16.5187 23.0554L10.7011 15.5557L10.701 15.5557C10.2067 14.9187 10.6796 14.052 11.3356 14.052H11.3356L22.7078 14.051L22.9527 14.051L23.1028 13.8576L27.0111 8.822L27.0112 8.82196C27.3062 8.44177 27.8162 8.39553 28.1639 8.69858ZM29.5716 16.6832L30.1975 15.8768L29.1767 15.8766L23.9679 15.8755L23.7229 15.8754L23.5728 16.0689L18.6661 22.3911L18.4282 22.6977L18.6662 23.0042L21.2705 26.3598L21.6654 26.8687L22.0604 26.3598L29.5716 16.6832ZM20.9106 16.682L21.5365 15.8753L20.5155 15.8755L14.156 15.8766L13.1354 15.8768L13.7611 16.6831L16.9405 20.7805L17.3356 21.2896L17.7306 20.7805L20.9106 16.682Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
