import { ShowMoreContainer } from '@etta/ui/show-more-container'
import { ExpandedView } from '../../expanded-view'
import { CollapsedView } from '../../collapsed-view'
import type { LayoutProps } from '../../types'
import { Container } from './flight-safety-check-mobile-styled'

export function FlightSafetyCheckMobile({
  safetyChecks,
  safetyCheckDetails,
  isExpanded,
  onModalOpen,
  handleExpand,
  uniqIconNames,
}: LayoutProps) {
  return (
    <Container>
      <ShowMoreContainer
        collapsedSlot={
          <CollapsedView
            isExpanded={isExpanded}
            handleExpand={handleExpand}
            iconNames={uniqIconNames}
          />
        }
        expandedSlot={
          safetyChecks && (
            <ExpandedView
              isExpanded={isExpanded}
              handleExpand={handleExpand}
              flightSafetyChecks={safetyChecks}
              onModalOpenClick={onModalOpen}
              hasDetails={!!safetyCheckDetails?.length}
            />
          )
        }
        isExpanded={isExpanded}
        isAnimated
      />
    </Container>
  )
}
