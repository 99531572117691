import { Action, Container, Inject } from '@etta/di'
import { SegmentTypeCheckService } from '../services/segment-type-check.service'
import type {
  TrainSegmentEntity,
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  HotelSegmentEntity,
} from '../../core/entity'
import type { TripSegmentValueObject } from '../../core/value-objects'

@Action()
export class SegmentTypeCheckActions {
  constructor(
    @Inject()
    private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  isHotelSegment(segment: TripSegmentValueObject): segment is HotelSegmentEntity {
    return this.segmentTypeCheckService.isHotelSegment(segment)
  }

  isCarRentalSegment(segment: TripSegmentValueObject): segment is CarRentalSegmentEntity {
    return this.segmentTypeCheckService.isCarRentalSegment(segment)
  }

  isFlightSegment(segment: TripSegmentValueObject): segment is FlightSegmentEntity {
    return this.segmentTypeCheckService.isFlightSegment(segment)
  }

  isTrainSegment(segment: TripSegmentValueObject): segment is TrainSegmentEntity {
    return this.segmentTypeCheckService.isTrainSegment(segment)
  }

  static getInstance() {
    return Container.get(SegmentTypeCheckActions)
  }
}
