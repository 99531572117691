import { useState } from 'react'
import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import type { CardActionButton } from '../../../types'
import { Wrapper, ManageButton, ChevronIcon, ButtonList, ButtonItem } from './manage-buttons-styled'

type Props = {
  buttons?: CardActionButton[]
}

const i18BaseManageBooking = 'PostBooking.TripDetails.ManageBooking'

export function ManageButtons({ buttons = [] }: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpenButtonList = (ev: MouseEvent) => {
    ev.stopPropagation()
    setIsOpen((isOpen) => !isOpen)
  }
  const handleCloseButtonList = () => setIsOpen(false)
  const handleClickButton = (ev: MouseEvent, onClick?: () => void) => {
    ev.stopPropagation()
    onClick?.()
    setIsOpen(false)
  }

  const { ref } = useClickOutside(() => {
    if (isOpen) {
      handleCloseButtonList()
    }
  })

  if (buttons.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <ManageButton
        onClick={toggleOpenButtonList}
        role="button"
        tabIndex={0}
        data-tracking-id="manage-booking-label">
        {t(i18BaseManageBooking)}
        <ChevronIcon />
      </ManageButton>
      {isOpen && (
        <ButtonList ref={ref} onMouseLeave={handleCloseButtonList}>
          {buttons?.map(({ title, onClick }) => (
            <ButtonItem key={title} tabIndex={0} onClick={(ev) => handleClickButton(ev, onClick)}>
              {title}
            </ButtonItem>
          ))}
        </ButtonList>
      )}
    </Wrapper>
  )
}
