import type { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import type { ScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { RecentSearchesList } from '@etta/modules/recent-searches/ui/search-form-list'
import type { RecentSearchesType } from '@etta/modules/recent-searches/core/value-objects/recent-searches-type.value-object'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { DescendantList, DescendantsWrapper } from '@etta/components/descendant'
import type { CurrentLocationSearchPrecision } from './current-location-search'
import { CurrentLocationSearch } from './current-location-search'
import { List } from './list'
import { Messages, PwaErrorMsg } from './messages'
import { MobileBackButton, ResultsListContainer, Separator } from './search-mechanism-styled'
import { SearchMechanismLayout } from './layout'
import { SearchInput } from './search-input/search-input'
import { NoStations } from './no-stations'
import { useSearchMechanism } from './use-search-mechanism'

type Props = Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  disabled?: boolean
  hasCurrentLocationSearch?: boolean
  currentLocationSearchPrecision?: CurrentLocationSearchPrecision
  searchType?: SearchType
  inputProps?: object
  isModalOpen: boolean
  isOutboundTrip?: boolean
  latestPlaces?: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  onChange: (value: Place, address?: AddressInput) => void
  onModalClose: () => void
  useNewLocationSearch?: boolean
  customSearchDescription?: string
  suggestionPlaces?: Place[]
  placeholder?: string
  customBackButtonSlot?: React.ReactNode
  recentSearchesType?: RecentSearchesType
  isExternalLoading?: boolean
  forceScreenType?: null | ScreenType
}

export function SearchMechanism({
  disabled,
  hasCurrentLocationSearch,
  currentLocationSearchPrecision = 'place',
  inputProps,
  searchType,
  isModalOpen,
  isOutboundTrip,
  latestPlaces = [],
  locationsSortOrder,
  onChange,
  onModalClose,
  useNewLocationSearch,
  customSearchDescription,
  suggestionPlaces = [],
  placeholder,
  customBackButtonSlot,
  recentSearchesType,
  isExternalLoading,
  forceScreenType,
}: Props) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { t } = useTranslation()
  const i18nPath = 'SearchResults.'
  const { recentSearchesStore } = useRecentSearchesContext()
  const isRecentSearchesListEmpty = recentSearchesStore.isSegmentListEmpty(recentSearchesType)
  const isRecentSearchesEnabled = featureFlagsStore.flags.isRecentSearchesEnabled

  const {
    anchorRef,
    scrollContainerRef,
    searchInputRef,
    places,
    modifiedPlaces,
    referencePoints,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    searchFieldPlaceholder,
    notResultMessage,
    errorMessage,
    hasAnyPlaces,
    isResultsListOpen,
    isTrainNoResultVisible,
    isLoading,
    isHotelSearch,
    setIsLoading,
    handleCurrentLocation,
    reset,
  } = useSearchMechanism({
    searchType,
    latestPlaces,
    suggestionPlaces,
    locationsSortOrder,
    useNewLocationSearch,
    isModalOpen,
    isOutboundTrip,
    onChange,
    onModalClose,
  })

  return (
    <DescendantsWrapper>
      <SearchMechanismLayout
        forceScreenType={forceScreenType}
        scrollContainerRef={scrollContainerRef}
        searchFieldPlaceholder={searchFieldPlaceholder}
        anchorSlot={<div ref={anchorRef} />}
        backButtonSlot={
          customBackButtonSlot ? (
            customBackButtonSlot
          ) : (
            <MobileBackButton
              onClick={onModalClose}
              aria-label={t('TripPlanner.BaseSearch.Header.Close')}>
              <Icon name={'closePWA'} />
            </MobileBackButton>
          )
        }
        searchInputSlot={
          <SearchInput
            isResultsListOpen={!!isResultsListOpen}
            searchInputRef={searchInputRef}
            containerProps={getComboboxProps()}
            inputProps={getInputProps({ ...inputProps, ref: searchInputRef })}
            isDisabled={!!disabled}
            placeholder={placeholder ?? searchFieldPlaceholder}
            value={inputValue}
            onReset={reset}
          />
        }
        listSlot={
          <Block position="relative">
            <Separator />
            {hasCurrentLocationSearch ? (
              <CurrentLocationSearch
                setIsLoading={setIsLoading}
                precision={currentLocationSearchPrecision}
                onClick={handleCurrentLocation}
              />
            ) : null}
            {isRecentSearchesListEmpty &&
              (hasAnyPlaces ? false : searchInputRef.current?.defaultValue.length === 0) && (
                <PwaErrorMsg>
                  {customSearchDescription || t(i18nPath + 'PwaFirstScreen')}
                </PwaErrorMsg>
              )}
            {isRecentSearchesListEmpty && notResultMessage && (
              <PwaErrorMsg>{notResultMessage}</PwaErrorMsg>
            )}

            {isTrainNoResultVisible && <NoStations title={t(i18nPath + 'SearchResults')} />}
            <DescendantList>
              <ResultsListContainer
                {...getMenuProps()}
                isOpen={isRecentSearchesEnabled || isResultsListOpen}>
                <Messages
                  isLoading={isLoading || (isExternalLoading ?? false)}
                  errorMessage={errorMessage}
                />
                <Swap is={!!errorMessage} isSlot={<em>{errorMessage}</em>}>
                  <>
                    <List
                      searchType={searchType}
                      title={t(i18nPath + 'SearchResults')}
                      list={isHotelSearch ? modifiedPlaces : places}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      iconSize="medium"
                    />
                    {isHotelSearch && referencePoints && (
                      <List
                        searchType={searchType}
                        title={t(i18nPath + 'ReferencePoints')}
                        list={referencePoints}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        startIndex={modifiedPlaces.length}
                        iconSize="medium"
                      />
                    )}
                  </>
                </Swap>
                <List
                  searchType={searchType}
                  title={t(i18nPath + 'SuggestionPlaces')}
                  list={suggestionPlaces}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  startIndex={places.length}
                  iconSize="medium"
                />
                {featureFlagsStore.flags.isRecentSearchesEnabled && recentSearchesType ? (
                  <RecentSearchesList type={recentSearchesType} />
                ) : (
                  <List
                    searchType={searchType}
                    title={t(i18nPath + 'RecentSearches')}
                    list={latestPlaces}
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                    startIndex={places.length + suggestionPlaces.length}
                    iconSize="medium"
                  />
                )}
              </ResultsListContainer>
            </DescendantList>
          </Block>
        }
      />
    </DescendantsWrapper>
  )
}
