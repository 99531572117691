import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const BodyText = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'footnoteStrong',
  color: 'mainText',
}))``

export const Description = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'footnoteMedium',
  color: 'bodyText',
}))``

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Separator = styled.div`
  display: inline-flex;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.bodyText};
  margin: 1px 4px 0 4px;
`
