import { DeemContainer } from './deem-container'

export function PageLayout({
  children,
  isDeemWrapperUsed,
}: {
  children: any
  isDeemWrapperUsed?: boolean
}) {
  if (isDeemWrapperUsed) {
    return <DeemContainer>{children}</DeemContainer>
  }

  return <>{children}</>
}
