import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

type Args = {
  status?: UberAccountStatusEnum
}

export function useCallToActionInfo({ status }: Args) {
  const { t } = useTranslation()
  const i18nBase = 'Mobility.UberReservedPage.Actions.'
  const i18nBaseForBrand = 'Mobility.UberNewInformationPage.Brand.'
  const { brandConfigurationStore } = useBrandConfigurationContext()
  return useMemo(() => {
    switch (status) {
      case UberAccountStatusEnum.NotLinked:
      case UberAccountStatusEnum.SiteEmptyMapping:
      case UberAccountStatusEnum.NotFound:
        const ACC_SHOW_DID_YOU_KNOW = i18nBase + 'AccountForGetARide.'
        const brandLabel = brandConfigurationStore.isAppleTheme
          ? t(i18nBaseForBrand + 'Kan')
          : t(i18nBaseForBrand + 'Deem')
        return {
          title: t(ACC_SHOW_DID_YOU_KNOW + 'Title'),
          description: t(ACC_SHOW_DID_YOU_KNOW + `Description`, { brand: brandLabel }),
          actionText: t(ACC_SHOW_DID_YOU_KNOW + 'ActionText'),
        }
      case UberAccountStatusEnum.Ready:
        const ACC_SHOW_RESERVE_RIDE = i18nBase + 'AccountForReserveRide.'
        return {
          title: t(ACC_SHOW_RESERVE_RIDE + 'Title'),
        }
      default:
        return {
          title: '',
          description: '',
          actionText: '',
        }
    }
  }, [status, t, brandConfigurationStore.isAppleTheme])
}
