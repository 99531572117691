import { SeatItemContainer } from '../seat-item-container'
import type { SeatList } from '../../seat-map-segment.types'
import { getActualRowLength } from '../get-actual-row-length'
import { SeatColumn } from './seat-row-header-styled'

type SeatRowHeaderProps = {
  seatList: SeatList
  isEconomyClass: boolean
  mostFrequentAislesColumns: string[]
}

export function SeatRowHeader({
  seatList,
  isEconomyClass,
  mostFrequentAislesColumns,
}: SeatRowHeaderProps) {
  const actualRowLength = getActualRowLength(seatList, mostFrequentAislesColumns)

  return (
    <>
      {seatList.map((seatItem, seatIndex) => {
        const seatColumn = seatItem.column
        return (
          <SeatItemContainer
            actualRowLength={actualRowLength}
            key={seatColumn}
            role="columnheader"
            isEconomyClass={isEconomyClass}
            mostFrequentAislesColumns={mostFrequentAislesColumns}
            seatIndex={seatIndex}
            seatList={seatList}>
            <SeatColumn>{seatColumn}</SeatColumn>
          </SeatItemContainer>
        )
      })}
    </>
  )
}
