import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const IconWrapper = styled(Icon)``

export const Information = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 14px 16px 22px;

  ${Text} {
    max-width: 250px;
  }

  ${IconWrapper} {
    margin-left: 10px;
  }
`
