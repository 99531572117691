import { observer } from 'mobx-react-lite'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import type { Props } from './types'
import { LocationSectionLayout } from './layout'

export const HotelLocationSection = observer(function HotelLocationSection(props: Props) {
  const { hotelSearchFormStore } = useHotelSearchFormContext()

  return (
    <LocationSectionLayout {...props} validationErrors={hotelSearchFormStore.validationErrors} />
  )
})
