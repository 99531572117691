import { useCallback } from 'react'
import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field/types'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import { getCustomFieldsBookingList } from './get-custom-fields-booking-list'
import { useForm } from './use-form'

type Props = {
  customFields: CustomFieldCollectionItemExtension[]
  onSubmitFields(fields: CustomFieldBookingValueObject[]): void
}

export function useCustomFields({ customFields, onSubmitFields }: Props) {
  const onSubmit = useCallback(
    (values: Record<string, string>) => {
      const customFieldsBookingList = getCustomFieldsBookingList(customFields, values)

      onSubmitFields(customFieldsBookingList)
    },
    [customFields, onSubmitFields],
  )

  const { values, errors, onFieldChange, onSubmitHandler } = useForm({
    fields: customFields,
    onSubmit,
  })

  return {
    values,
    errors,
    onFieldChange,
    onSubmitHandler,
  }
}
