import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import { useRailDetailsPermittedStations } from '../../use-rail-details-permitted-stations'
import { Container, ViewStationsWrapper } from './rail-details-permitted-stations-mobile-styled'

const i18nBase = 'PostBooking.TripDetails.'

export function RailDetailsPermittedStationsMobile({
  originStation,
  destinationStation,
  permittedOriginStations,
  permittedDestinationStations,
  ticketRestrictions,
}: LayoutProps) {
  const { t } = useTranslation()
  const { handlePermittedStationsOpen } = useRailDetailsPermittedStations({
    originStation,
    destinationStation,
    permittedOriginStations,
    permittedDestinationStations,
    ticketRestrictions,
  })

  return (
    <Container>
      <ViewStationsWrapper onClick={handlePermittedStationsOpen}>
        <Text variant="subHeadStrong" color="mainText1">
          {t(`${i18nBase}ViewStations`)}
        </Text>
        <Icon color="mainText1" name="chevronRightPWA" size={22} />
      </ViewStationsWrapper>
    </Container>
  )
}
