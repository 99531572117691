import type { SeatIconName } from '../../../../../seat-icon'

type GetSeatIconArgs = {
  isEconomyClass: boolean
  isSelected: boolean
  isBooked: boolean
  isNewSeatMap?: boolean
  isAvailable: boolean
  isPremium: boolean
  isPaid: boolean
}

export const getIconName = ({
  isEconomyClass,
  isSelected,
  isBooked,
  isNewSeatMap,
  isAvailable,
  isPremium,
  isPaid,
}: GetSeatIconArgs): SeatIconName => {
  const prefix = isEconomyClass ? 'economy' : 'business'

  if (!isAvailable && !isSelected) {
    return `${prefix}NotAvailable`
  }
  if (isNewSeatMap && isSelected) {
    return `${prefix}NewSelected`
  }
  if (isPremium && isPaid) {
    return `${prefix}Premium`
  }
  if (isPremium && isSelected) {
    return `${prefix}Selected`
  }
  if (isPremium) {
    return `${prefix}Premium`
  }
  if (isPaid) {
    return `${prefix}Paid`
  }

  return isSelected || isBooked ? `${prefix}Selected` : `${prefix}Available`
}
