import { Action, Inject } from '@etta/di'
import { PartnerCoBrandingConfigurationsService } from '../../interface/services/partner-cobranding-configurations.service'

@Action()
export class PartnerCoBrandingConfigurationsAction {
  constructor(
    @Inject()
    private readonly partnerCoBrandingConfigurationService: PartnerCoBrandingConfigurationsService,
  ) {}

  async loadPartnerCoBrandingConfigurationsBySiteName(siteName?: string) {
    const isLocalHost = window.location.host.includes('localhost')

    if (siteName && isLocalHost) {
      return this.partnerCoBrandingConfigurationService.loadConfigurationsBySiteName({ siteName })
    }

    if (!isLocalHost) {
      await this.partnerCoBrandingConfigurationService.loadConfigurationsBySiteName({
        siteName: window.location.hostname,
      })
    }
  }

  async loadPartnerCoBrandingConfigurationsBySiteId(siteId?: number) {
    await this.partnerCoBrandingConfigurationService.loadConfigurationsBySiteId({ siteId })
  }
}
