import type { CreditCardBrandType } from '@etta/core/enums'
// eslint-disable-next-line import/no-restricted-paths
import type { CreditCardBillingAddressValueObject } from '@etta/modules/booking/core/value-objects/credit-card-billing-details.value-object'
// eslint-disable-next-line import/no-restricted-paths
import type { PreferredCreditCardSegment } from '@etta/modules/user/core/enums/preferred-credit-card-segment.enum'

export type UserCreditCardEntity = {
  name?: string | null
  label?: string | null
  cardNumberLast4?: string | null
  expirationDate?: string | null
  legacyId?: string | null
  fullLegacyId?: string | null
  brandType?: CreditCardBrandType | null
  cardNumber?: string | null
  securityCode?: string | null
  billingAddress?: CreditCardBillingAddressValueObject | null
}

export type PreferredCreditCardsForSegments = {
  flight?: UserCreditCardEntity | null
  car?: UserCreditCardEntity | null
  hotel?: UserCreditCardEntity | null
  rail?: UserCreditCardEntity | null
}

export type PreferredSiteCardsForSegments = {
  flight?: boolean
  car?: boolean
  hotel?: boolean
  rail?: boolean
}

export type SelectedCreditCard = PreferredCreditCardsForSegments & {
  preExpirationAlert?: string
  segments: PreferredCreditCardSegment[]
}

export const PreExpirationAlertValues = {
  Month1: 'P30D',
  Month3: 'P90D',
  Month6: 'P180D',
  Month9: 'P270D',
  Year1: 'P365D',
  Never: 'PT0S',
} as const
