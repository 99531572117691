import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  GetMeetingInfoDocument,
  UpdateMeetingIdCookieDocument,
  UpsertMeetingInfoDocument,
} from '@fiji/graphql/hooks'
import type {
  GetMeetingInfoQuery,
  GetMeetingInfoQueryVariables,
  UpdateMeetingIdCookieMutation,
  UpdateMeetingIdCookieMutationVariables,
  UpsertMeetingInfoMutation,
  UpsertMeetingInfoMutationVariables,
} from '@fiji/graphql/hooks'
import type { UpdateMeetingIdCookieInput, UpsertMeetingInfoInput } from '@fiji/graphql/types'

@DataProvider()
export class MeetingModeDataProvider extends GraphqlDataProvider {
  upsertMeetingInfo(input: UpsertMeetingInfoInput) {
    return this.client.mutate<UpsertMeetingInfoMutation, UpsertMeetingInfoMutationVariables>({
      mutation: UpsertMeetingInfoDocument,
      variables: {
        input,
      },
    })
  }

  updateMeetingIdInCookie(input: UpdateMeetingIdCookieInput) {
    return this.client.mutate<
      UpdateMeetingIdCookieMutation,
      UpdateMeetingIdCookieMutationVariables
    >({
      mutation: UpdateMeetingIdCookieDocument,
      variables: {
        input,
      },
    })
  }

  getMeetingInfo() {
    return this.client.query<GetMeetingInfoQuery, GetMeetingInfoQueryVariables>({
      query: GetMeetingInfoDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })
  }
}
