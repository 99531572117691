import type { PropsWithChildren } from 'react'
import { cloneElement } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import useNextTransition from './use-next-transition'

const childFactoryCreator = (classNames: string) => (child: any) =>
  cloneElement(child, {
    classNames,
  })

export function ChildWithAnimation({ children }: PropsWithChildren<{}>) {
  const { nextTransition, timeout } = useNextTransition()
  const location = useLocation()

  return (
    <TransitionGroup childFactory={childFactoryCreator(nextTransition)}>
      <CSSTransition key={location.pathname} timeout={timeout} classNames={nextTransition}>
        <>{children}</>
      </CSSTransition>
    </TransitionGroup>
  )
}
