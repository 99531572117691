import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { LayoutProps } from '../types'
import { Container, Price } from './trip-cost-price-desktop-styled'

export function TripCostPriceDesktop({ totalRate }: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'TripCostSummary.'
  const { mainCost } = formatRate(totalRate, {
    morpheme: 'postfix',
  })

  return (
    <Container>
      <Price>
        <Text color="mainText" variant="subHeadStrong">
          {t(i18nBase + 'TripTotalText')}
        </Text>
        <Text color="mainText" variant="title3" data-tracking-id="trip-cost-total-value-text">
          {mainCost}
        </Text>
      </Price>
    </Container>
  )
}
