/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import type { ChosenCardsMap } from '@etta/screens/payment-summary/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { BillingParameter, CreditCard } from '@fiji/graphql/types'
import { TravelVertical } from '@fiji/graphql/types'
import type { SubmitType } from '../type'
import { useEditCreditCardInitialData } from './use-edit-credit-card-initial-data'
import { useSelectedCard } from './use-selected-card'
import { useCreditCards } from './use-credit-cards'
import { useCardMutations } from './use-card-mutations'

type Args = {
  billingParameter: BillingParameter
  onSubmit: (data: SubmitType) => void
  chosenCards: ChosenCardsMap
  onAddSingleTimeUseCard: (card: Partial<CreditCard>) => void
  singleTimeUseCard?: Partial<CreditCard>
}

export function usePurchasePaymentInformationForm({
  billingParameter,
  onSubmit,
  chosenCards,
  onAddSingleTimeUseCard,
  singleTimeUseCard,
}: Args) {
  const addCreditCardToggle = useTogglePopup()
  const editCreditCardToggle = useTogglePopup()
  const {
    isSingleUseCreditCardAllowed,
    isBillingAddressRequired,
    isCvvRequired,
  } = billingParameter.metadata

  const {
    isSearchResultEmpty,
    isFilterAvailable,
    filterValue,
    handleChangeFilterValue,
    isAddNewCardAvailable,
    creditCardsToUse,
  } = useCreditCards({
    singleTimeUseCard,
    billingCreditCards: billingParameter.creditCards,
    isSingleUseCreditCardAllowed,
  })

  const { selectedCard, handleSelectCard } = useSelectedCard({
    chosenCards,
    travelVertical: billingParameter.travelVertical,
  })

  const [editableCreditCard, setEditableCreditCard] = useState<Partial<CreditCard> | null>(null)
  const [isEditableCreditCardSingleUse, setIsEditableCreditCardSingleUse] = useState(false)
  const editCreditCardInitialData = useEditCreditCardInitialData({
    editableCreditCard,
    isCvvRequired,
  })

  const onAddOneTimeCard = useCallback((creditCardData) => {
    onAddSingleTimeUseCard(creditCardData)
    handleSelectCard(creditCardData.id)
    addCreditCardToggle.handleClose()
  }, [])

  const { viewState, handleAddCreditCard, handleEditCreditCard } = useCardMutations({
    editCreditCardId: editableCreditCard?.id,
    onAddOneTimeCard,
    onAddSuccess: addCreditCardToggle.handleClose,
    onEditSuccess: editCreditCardToggle.handleClose,
  })

  const handleSubmit = useCallback(() => {
    onSubmit({ travelVertical: billingParameter.travelVertical, id: selectedCard! })
  }, [selectedCard, billingParameter.travelVertical, onSubmit])

  const handleEditCreditCardClick = useCallback((card: Partial<CreditCard>) => {
    editCreditCardToggle.handleOpen()
    setIsEditableCreditCardSingleUse(card.id === 'single-use-card')
    setEditableCreditCard(card)
  }, [])

  const isHotelBooking = billingParameter.travelVertical === TravelVertical.Hotel

  return {
    isSearchResultEmpty,
    creditCards: creditCardsToUse,
    isFilterAvailable,
    filterValue,
    handleChangeFilterValue,
    isAddNewCardAvailable,
    selectedCard,
    handleSelectCard,
    addCreditCardToggle,
    editCreditCardToggle,
    handleAddCreditCard,
    handleEditCreditCard,
    handleEditCreditCardClick,
    handleSubmit,
    editCreditCardInitialData,
    isSingleUseCreditCardAllowed,
    isBillingAddressRequired,
    isCvvRequired,
    viewState,
    isEditableCreditCardSingleUse,
    isHotelBooking,
  }
}
