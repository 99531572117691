import { useTranslation } from 'react-i18next'
import { ROUTES } from '@fiji/routes'
import type { Props } from '../../types'
import { SeeMoreSection } from '../shared/see-more-section-styled'
import { ArticleSeeMore } from './see-more-section-mobile-styled'

const i18Base = 'TripPlanner.Support.TravelSupport'

export function SeeMoreSectionMobile({ isPreview, dataTrackingId }: Props) {
  const { t } = useTranslation()
  if (!isPreview) {
    return null
  }

  return (
    <li>
      <SeeMoreSection to={ROUTES.support.travel} data-tracking-id={dataTrackingId}>
        <ArticleSeeMore>{t(i18Base + '.SeeMore')}</ArticleSeeMore>
      </SeeMoreSection>
    </li>
  )
}
