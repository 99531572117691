import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 16px;
`

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 6px;
`

export const DsmWrapper = styled.div`
  margin-bottom: 24px;
`

export const List = styled.div`
  & > div {
    margin-bottom: 42px;
    position: relative;
    &:not(:last-child):after {
      content: '';
      display: flex;
      position: absolute;
      left: 40px;
      bottom: -42px;
      width: 1px;
      height: 42px;
      background-color: ${({ theme }) => theme.colors.borderDark};
    }
  }
`
