import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  // Google Maps applyes styles directly to the element
  & .gm-fullscreen-control {
    top: 30px !important;
    right: 30px !important;
    border-radius: 5px !important;
    margin: 0 !important;
  }

  & .gm-bundled-control {
    margin: 30px !important;
  }

  & .gm-bundled-control .gmnoprint > div {
    border-radius: 12px !important;
  }
`
