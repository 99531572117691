import { useTranslation } from 'react-i18next'
import { ABOUT_GEO_SAFE_SCORES_URL } from '@fiji/constants'
import { Block } from '@etta/ui/block'
import { Link } from '@etta/ui/link'
import type { LayoutProps } from '../types'
import { Title, AwayText, Description } from './geo-safe-location-desktop-styled'
import { GeoSafeLocationSkeleton } from './geo-safe-location-desktop-skeleton'

const skeleton = <GeoSafeLocationSkeleton />

const i18nBase = `HotelSafetyCheck.GeoSafeScores.GeoSafeLocation`

export function GeoSafeLocationDesktop({ hotelName, location, isLoading }: LayoutProps) {
  const { t } = useTranslation()

  if (isLoading || !location) {
    return skeleton
  }

  const locationAway = t(`${i18nBase}.LocationAway`, { distance: location.distance })
  const learnMoreLabel = t(`HotelSafetyCheck.ConcernScoresList.ScoreList.LearnMore`)
  const linkLabel = `${t('Accessibility.TapTo')} ${learnMoreLabel}`

  return (
    <div aria-label={t(`${i18nBase}.AriaLabel`, { location: location.name, locationAway })}>
      <Title aria-label={hotelName} isBlock>
        {hotelName}
      </Title>
      <Description isBlock>{location.name}</Description>
      <Block marginTop={2}>
        <Description>
          {t(`${i18nBase}.NearestScoreFound`)} <AwayText>{locationAway}</AwayText>
        </Description>
      </Block>
      <Block marginTop={4}>
        <Link size="small" href={ABOUT_GEO_SAFE_SCORES_URL} target="_blank" aria-label={linkLabel}>
          {learnMoreLabel}
        </Link>
      </Block>
    </div>
  )
}
