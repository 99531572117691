import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { CancellationPolicy } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import type { RoomDetailsType } from '../types'
import { CancelInfo } from './price-styled'
import { usePrice } from './use-price'

type Props = {
  cancellationPolicyDescription?: string[] | null
  cancellationPolicy: CancellationPolicy
  roomDetails: RoomDetailsType
  isCancelled: boolean
  handleOpenDetails: () => void
}

export const Price = observer(function Price({
  cancellationPolicyDescription,
  roomDetails,
  isCancelled,
  cancellationPolicy,
  handleOpenDetails,
}: Props) {
  const { checkIn, checkOut, roomType: room, feesAndTaxes, totalCost } = roomDetails

  const { priceDetailsList, cancellationPolicyLabel, totalPriceValue } = usePrice({
    cancellationPolicyDescription,
    checkIn,
    checkOut,
    room,
    roomDetails,
    feesAndTaxes,
    cancellationPolicy,
    totalCost,
  })

  if (isCancelled) {
    return null
  }

  return (
    <PriceDetails
      headerLevel={3}
      priceDetailsList={priceDetailsList}
      totalCost={totalPriceValue}
      footerSlot={
        cancellationPolicyLabel && (
          <CancelInfo onClick={handleOpenDetails}>
            <Icon name="infoFilledPWA" color="warning" size="small" />
            <Block marginLeft={8}>
              <Text variant="footnoteMedium" color="bodyText">
                {cancellationPolicyLabel}
              </Text>
            </Block>
          </CancelInfo>
        )
      }
    />
  )
})
