import { useEffect } from 'react'
import { useUserContext } from '@etta/modules/user'
import { useMeetingModeContext } from '@etta/modules/meeting/use-meeting-mode.context'

export function useMeetingMode() {
  const { userStore } = useUserContext()
  const { meetingModeActions } = useMeetingModeContext()

  useEffect(() => {
    if (userStore.meetingId) {
      meetingModeActions.getMeetingInfo()
    }
  }, [meetingModeActions, userStore.meetingId])
}
