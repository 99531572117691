import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { useRoomDetails } from '../../use-room-details'
import {
  CheckBlock,
  Container,
  DateBlock,
  LinkWrapper,
  PriceWrapper,
  TitleIconWrapper,
  TitleWrapper,
} from './room-details-mobile-styled'

export function RoomDetailsMobile({
  checkIn,
  checkOut,
  handleOpenDetails,
  roomDetails,
  isUpcoming,
}: LayoutProps) {
  const {
    roomDateCheckIn,
    roomDateCheckOut,
    titleAriaLabel,
    roomRateDetails,
    title,
    price,
    avgPerNightTranslation,
    roomRateDetailsAriaLabel,
    checkInTranslation,
    checkOutTranslation,
    showDifferingRatesLabel,
    differingRatesLabel,
  } = useRoomDetails({ roomDetails, checkIn, checkOut, isUpcoming })

  return (
    <Container aria-label={titleAriaLabel}>
      <TitleWrapper>
        <Text variant="headline" color="mainText">
          {title}
        </Text>
        <TitleIconWrapper>
          <Icon name="hotelPWA" size="large" color="bodyText" />
        </TitleIconWrapper>
      </TitleWrapper>
      <PriceWrapper>
        <Text variant="headline" color="mainText">
          {price}
        </Text>
        <Text variant="linkSmall" color="bodyText">
          {avgPerNightTranslation}
        </Text>
      </PriceWrapper>
      <LinkWrapper>
        <Link
          size="small"
          onClick={handleOpenDetails}
          aria-label={roomRateDetailsAriaLabel}
          data-tracking-id="hotel-trip-segment-room-rate-details">
          {roomRateDetails}
        </Link>
        {showDifferingRatesLabel && (
          <Text variant="captionMedium" color="bodyText1" isBlock>
            {differingRatesLabel}
          </Text>
        )}
      </LinkWrapper>
      <Block marginVertical={16}>
        <Separator lineType="dashed" color="borderDark" />
      </Block>
      <DateBlock>
        <CheckBlock marginBottom={26} aria-label={roomDateCheckIn}>
          <Text variant="captionStrongCaps" color="bodyText">
            {checkInTranslation}
          </Text>
          <Text variant="subHeadStrong" color="mainText">
            {roomDateCheckIn}
          </Text>
        </CheckBlock>
        <CheckBlock aria-label={roomDateCheckOut}>
          <Text variant="captionStrongCaps" color="bodyText">
            {checkOutTranslation}
          </Text>
          <Text variant="subHeadStrong" color="mainText">
            {roomDateCheckOut}
          </Text>
        </CheckBlock>
      </DateBlock>
    </Container>
  )
}
