import { useHistory } from 'react-router-dom'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { usePreventBack } from '@fiji/hooks/use-prevent-navigation'
import { ROUTES } from '@fiji/routes'

export function usePurchaseReservationSuccess() {
  const { tripActions } = useReviewTripContext()
  const { postBookingTripActions } = usePostBookingContext()
  const history = useHistory()

  // Adjust drop trip logic
  usePreventBack({
    isBlocking: true,
    onPrevent: () => {
      tripActions.dropTripStore()
      postBookingTripActions.dropTripStore()
      history.replace(ROUTES.explore)
    },
  })
}
