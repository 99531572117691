import { useTranslation } from 'react-i18next'
import type { PermissionTranslation } from '../shared'

export function useLocationEnabledTranslation(): PermissionTranslation {
  const { t } = useTranslation()

  return {
    title: t('Permissions.LocationEnabled.Title'),
    text: t('Permissions.LocationEnabled.Text'),
    buttonText: t('Permissions.LocationEnabled.ButtonText'),
  }
}
