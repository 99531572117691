import styled from 'styled-components'
import { subHeadMedium } from '@fiji/style'

export const Title = styled.div`
  ${subHeadMedium};
  margin-top: 6px;
  margin-bottom: 8px;
`

export const ReloadErrorBlock = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: auto;
`
