import { Service, Inject } from '@etta/di'
import { CarRentalRulesService } from '../services/car-rental-rules.service'

@Service()
export class CarRentalRulesActions {
  constructor(@Inject() private carRentalRulesService: CarRentalRulesService) {}

  getCarRentalRules(carSegmentId: string, transactionId: string) {
    this.carRentalRulesService.getCarRentalRules(carSegmentId, transactionId)
  }
}
