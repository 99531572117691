import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Amenity } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { ShowMore } from '@etta/ui/show-more'
import { Swap } from '@etta/ui/swap'
import { Button } from '@etta/ui/button'
import { HorizontalDelimiter } from '../../../../hotel-details-styled'
import { renderAmenity } from './render-amenity'

type Props = {
  amenities: Amenity[]
  title?: string
}

const i18nBase = 'HotelDetails'
const AMENITIES_LENGTH = 5

export function AmenitiesBlock({ amenities, title }: Props) {
  const { t } = useTranslation()

  const notExpandedContent = useMemo(() => {
    return amenities.slice(0, AMENITIES_LENGTH).map(renderAmenity)
  }, [amenities])

  const expandedContent = useMemo(() => {
    return amenities.map(renderAmenity)
  }, [amenities])

  return (
    <>
      <HorizontalDelimiter />
      <Block paddingHorizontal={20} paddingVertical={16}>
        {title && (
          <Block marginBottom={20}>
            <Text variant="subHeadStrong" color="mainText">
              {title}
            </Text>
          </Block>
        )}

        <Swap is={amenities.length <= AMENITIES_LENGTH} isSlot={<>{expandedContent}</>}>
          <ShowMore
            notExpandedContent={<>{notExpandedContent}</>}
            expandedContent={<>{expandedContent}</>}
            renderButton={(isExpanded, handleButtonClick) => (
              <Block marginTop={8}>
                <Button paddings={false} variant="text" onClick={handleButtonClick}>
                  <Text variant="linkSmall" color="primary1" textTransform="capitalize">
                    {t(`Callout.${isExpanded ? 'ShowLess' : 'ShowMore'}`)}{' '}
                    {t(i18nBase + '.Amenities')}
                  </Text>
                </Button>
              </Block>
            )}
          />
        </Swap>
      </Block>
    </>
  )
}
