import { Block } from '@etta/ui/block'
import { Search } from '@etta/ui/search'
import type { DesktopContentProps } from '../../../types'
import { Wrapper } from './hotel-search-form-desktop-content-styled'
import { DatePicker } from './date-picker'
import { DistancePicker } from './distance-picker'
import { LocationPicker } from './location-picker'

export function HotelSearchFormDesktopContent({
  onSearchClick,
  handleLocationChange,
  location,
  placesHistory,
  checkOutDate,
  checkInDate,
  onDateChange,
}: DesktopContentProps) {
  return (
    <Search dataTrackingId="header-search-button" onSearchClick={onSearchClick}>
      <Wrapper>
        <LocationPicker
          location={location}
          placesHistory={placesHistory}
          handleLocationChange={handleLocationChange}
        />
        <Block position="relative">
          <DatePicker
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            onDateChange={onDateChange}
          />
        </Block>
        <DistancePicker />
      </Wrapper>
    </Search>
  )
}
