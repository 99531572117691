import styled from 'styled-components'

export const RootContainer = styled.div`
  & + & {
    margin-top: 30px;
  }
`

export const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.span`
  margin-left: 4px;
`
