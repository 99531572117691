import { useCallback } from 'react'
import SimplePullToRefresh from 'react-simple-pull-to-refresh'

type Props = {
  onReload: () => void
  children: JSX.Element
}

const defaultConfig = {
  pullDownThreshold: 100,
  maxPullDownDistance: 150,
}

export function PullToRefresh({ children, onReload }: Props) {
  const handleRefresh = useCallback(() => {
    return new Promise(() => {
      onReload()
    })
  }, [onReload])

  return (
    <SimplePullToRefresh {...defaultConfig} pullingContent={''} onRefresh={handleRefresh}>
      {children}
    </SimplePullToRefresh>
  )
}
