import { Text } from '@etta/ui/text'
import type { LayoutProps } from './types'
import { Container, Image, Separator, TitleWrapper } from './mobile-styled'

export function TripCarRentalHeadlineMobile({ carImageUrl, carRentalTitle }: LayoutProps) {
  return (
    <>
      <Container>
        <TitleWrapper>
          <Text variant="title2" color="mainText2" isBlock>
            {carRentalTitle}
          </Text>
        </TitleWrapper>
        <Image src={carImageUrl} alt={carRentalTitle} />
      </Container>
      <Separator />
    </>
  )
}
