import { ScrollableBar } from '@etta/ui/scrollable-bar'
import { HotelCardsCarousel } from '@etta/components/hotel-cards-carousel/hotel-cards-carousel'
import { HotelMap } from '@etta/components/hotel-map/hotel-map'
import type { LayoutProps } from '../types'
import { HotelExtras, MapAnimationWrapper, ButtonWrapper } from './hotel-results-map-styled'
import { useHotelResultsMap } from './use-hotel-results-map'

const HOTELS_COUNT_PER_PAGE = 10

export const HotelResultsMapMobile = ({
  onMapHide,
  hotels,
  longitude,
  latitude,
  distance,
  hotelsCount,
  onCardClick,
  referencePoints,
  onBoundsLeave,
  onBoundsReturn,
  searchAreaSlot,
  fetchMore,
  isLoading,
  isFetchMoreLoading,
}: LayoutProps) => {
  const {
    centerPoint,
    slideIndex,
    onSlideChange,
    handleSelected,
    selected,
    goToPrevious,
    goToNext,
    paginatedHotels,
  } = useHotelResultsMap({
    hotels,
    longitude,
    latitude,
    hotelsCount,
    hotelsCountPerPage: HOTELS_COUNT_PER_PAGE,
    fetchMore,
    isLoading,
  })

  return (
    <MapAnimationWrapper>
      {!!searchAreaSlot && <ButtonWrapper>{searchAreaSlot}</ButtonWrapper>}
      <HotelMap
        onBoundsLeave={onBoundsLeave}
        onBoundsReturn={onBoundsReturn}
        center={centerPoint}
        hotels={paginatedHotels}
        onSelect={handleSelected}
        selectedId={selected}
        distance={distance}
        referencePoints={referencePoints}
      />
      <HotelExtras>
        <HotelCardsCarousel
          onSlideChange={onSlideChange}
          slide={slideIndex}
          hotels={paginatedHotels!}
          isLoading={isLoading || isFetchMoreLoading}
          onCardClick={onCardClick}
        />
        <ScrollableBar
          isLoading={isLoading || isFetchMoreLoading}
          currentHotels={hotels.length}
          totalHotels={hotelsCount}
          slideSize={10}
          onNextSlide={goToNext}
          onPrevSlide={goToPrevious}
          onViewListClick={onMapHide}
        />
      </HotelExtras>
    </MapAnimationWrapper>
  )
}
