import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { CreditCardModalDesktop } from './desktop'
import { CreditCardModalMobile } from './mobile'

export function CreditCardModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CreditCardModalMobile {...props} />}
      desktopSlot={<CreditCardModalDesktop {...props} />}
    />
  )
}
