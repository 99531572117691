import { Action, Inject } from '@etta/di'
import { RideHailBookService } from '../services/ride-hail-book.service'

@Action()
export class RideHailBookActions {
  constructor(@Inject() private rideHailBookService: RideHailBookService) {}

  async handleBook() {
    await this.rideHailBookService.bookRideHail()
  }

  handleDone() {
    this.rideHailBookService.resetStoresAfterBook()
  }
}
