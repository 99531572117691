import { useHotelAndRoomPrice } from '@fiji/hooks/use-hotel-and-room-price'
import type { DailyRate, Rate } from '@fiji/graphql/types'
import { OneRowSkin } from './one-row-skin'
import { TwoRowSkin } from './two-row-skin'

type Props = {
  averageRate: Rate
  dailyRates: DailyRate[]
  skin: 'one-row' | 'two-rows'
}

export function Price({ averageRate, dailyRates, skin }: Props) {
  const hasAverage = !!(averageRate?.primary.amount || averageRate?.secondary?.amount)
  const usedRate = hasAverage ? averageRate : dailyRates[0].rate

  const { price } = useHotelAndRoomPrice(usedRate)

  if (skin === 'one-row') {
    return <OneRowSkin price={price} hasAverage={hasAverage} />
  }

  return <TwoRowSkin price={price} hasAverage={hasAverage} />
}
