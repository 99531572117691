import type { OutOfPolicyEnum, OutOfPolicyData } from '@fiji/graphql/types'
import { StyledTextVariant } from '@fiji/enums'
import { useOutOfPolicyMessages } from '@fiji/hooks/use-out-of-policy-messages'
import { Reasons, InlineReason } from './out-of-policy-components'

type OutOfPolicyProps = {
  outOfPolicyReasons: OutOfPolicyEnum[]
  container?: string
  isOOPIconHidden?: boolean
  outOfPolicyData?: OutOfPolicyData[]
  textStyle?: StyledTextVariant
}

export function OutOfPolicy({ outOfPolicyReasons, outOfPolicyData, textStyle }: OutOfPolicyProps) {
  const { messages } = useOutOfPolicyMessages({ outOfPolicyData })

  return (
    <Reasons>
      {outOfPolicyReasons.map((reason: OutOfPolicyEnum) => (
        <InlineReason key={reason} textVariant={textStyle || StyledTextVariant.regularText}>
          <span>{messages[reason]}</span>
        </InlineReason>
      ))}
    </Reasons>
  )
}
