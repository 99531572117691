import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import { AirBadges } from '../../air-badges'
import { FareBrand, FareAmenities } from '../../branded-fare-details'
import { BaggageInformation } from '../../baggage-information'
import {
  Container,
  LeftSection,
  RightSection,
  ServiceClass,
  BadgesContainer,
  ActionContainer,
  IconWrap,
  ButtonWrap,
  ButtonIconWrap,
} from './flight-details-fares-desktop-styled'

const i18Base = 'FlightDetailsModal.FaresDetails.'

export function FlightDetailsFaresDesktop({
  flight,
  fareAttributes,
  classType,
  cabinClassLabel,
  flightSeatsTitle,
  seatButtonLabel,
  baggageButtonLabel,
  isSeatMapAvailable,
  onSeatMapOpen,
  onBaggageModalOpen,
  baggageInfo,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <LeftSection>
        <ServiceClass aria-label={t(i18Base + 'CabinClass', { class: cabinClassLabel })}>
          {cabinClassLabel}
        </ServiceClass>
        <FareBrand classType={classType} />
        <BadgesContainer>
          <AirBadges flight={flight} />
          <FareAmenities amenities={fareAttributes} />
        </BadgesContainer>
      </LeftSection>
      <RightSection hasClassType={!!classType}>
        {isSeatMapAvailable && (
          <ActionContainer aria-label={t(i18Base + 'SelectSeatsTitle')}>
            <IconWrap hasValue={!!flightSeatsTitle}>
              <Icon name="seatSelectionPWA" size="medium" />
            </IconWrap>
            <ButtonWrap>
              {flightSeatsTitle && (
                <Text data-tracking-id="selected-seats-list" variant="headline" isBlock>
                  {flightSeatsTitle}
                </Text>
              )}
              <Button
                aria-label={t(i18Base + 'ChangeSeats', { button: seatButtonLabel })}
                variant="text"
                size="small"
                paddings={false}
                onClick={onSeatMapOpen}
                data-tracking-id="flight-details-fares-change-seats-button"
                rightIcon={
                  <ButtonIconWrap>
                    <Icon name="chevronRightPWA" size="small" color="primary" />
                  </ButtonIconWrap>
                }>
                {seatButtonLabel}
              </Button>
            </ButtonWrap>
          </ActionContainer>
        )}

        <ActionContainer aria-label={baggageButtonLabel}>
          <BaggageInformation
            onBaggageModalOpen={onBaggageModalOpen}
            baggageButtonLabel={baggageButtonLabel}
            baggageInfo={baggageInfo}
          />
        </ActionContainer>
      </RightSection>
    </Container>
  )
}
