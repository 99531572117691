import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { default as NoHotelIcon } from '@etta/components/hotel-details-photos/assets/no-hotel-preview-icon.svg'
import { RailOpenReturnDetails } from '@etta/components/rail-open-return-details'
import { NDCFareBadge } from '@etta/components/air-badges/components'
import type { LayoutProps } from '../../types'
import { useSegmentCardStatus } from '../../use-segment-card-status'
import {
  CardWrapper,
  CardHeader,
  CardHeaderTitleWrapper,
  CardHeaderTitleDate,
  CardBody,
  ShareButton,
  CardTitleRow,
  CardTitleText,
  ViewDetailsLink,
  SubTitleText,
  Status,
  CardRow,
  CardInfoWrapper,
  CardInfoBlock,
  CardImageWrapper,
  CardImage,
  Footer,
  CancelationBlock,
  CancelationIcon,
  CanceleationText,
} from './new-segment-card-desktop-styled'
import { ManageButtons } from './manage-buttons'

const i18nBaseActionButtons = 'ActionButtons'
const i18nBaseTripDetails = 'PostBooking.TripDetails'

export function NewSegmentCardDesktop({
  isPast,
  segmentIcon,
  isUpcoming,
  isShareButtonAvailable = true,
  label,
  segmentStatus,
  segmentType,
  onShare,
  onClick,
  departureTime,
  titleText,
  titleSlot,
  headerDate,
  cardTitle,
  cardSubTitle,
  cardInfo,
  segmentPreview,
  buttons,
  cancellationPolicyLabel,
  handleOpenRateAndCancellationDetails = () => {},
  isOpenReturn,
  isNDCFare,
  openReturnProperties,
  isOnHold,
}: LayoutProps) {
  const { t } = useTranslation()
  const { handleCardActionClick, displayStatus, statusColor } = useSegmentCardStatus({
    label,
    segmentStatus,
  })
  const cardStatus = cardSubTitle ? `• ${displayStatus}` : displayStatus
  const [isImageError, setIsImageError] = useState(false)

  const onErrorImage = useCallback(() => {
    if (segmentType === 'hotel') {
      setIsImageError(true)
    }
  }, [segmentType])

  return (
    <CardWrapper
      tabIndex={0}
      data-tracking-id={`trip-${segmentType}-segment-card`}
      onClick={onClick}
      aria-label={`${departureTime || ''} ${titleText || titleSlot}`}>
      <CardHeader>
        <CardHeaderTitleWrapper>
          <Icon name={segmentIcon} size="medium" color="bodyText" />
          <CardHeaderTitleDate>{headerDate}</CardHeaderTitleDate>
        </CardHeaderTitleWrapper>

        {isUpcoming && isShareButtonAvailable && (
          <ShareButton
            aria-label={t(`${i18nBaseActionButtons}.Label`, {
              button: `${t(`${i18nBaseActionButtons}.Share`)}`,
            })}
            onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
              handleCardActionClick(ev, onShare)
            }
            data-tracking-id={`share-${segmentType}-trip-segment-button`}>
            <Icon name="sharePWA" size="medium" color="bodyText" />
          </ShareButton>
        )}
      </CardHeader>
      <CardBody>
        <CardTitleRow>
          <CardTitleText>{cardTitle}</CardTitleText>
          <ViewDetailsLink>
            <Block isFlexBlock paddingTop={4}>
              {t(`${i18nBaseTripDetails}.ViewDetails`)}
              <Icon name="chevronRightPWA" size="normal" color="bodyText" />
            </Block>
          </ViewDetailsLink>
        </CardTitleRow>

        <CardInfoBlock>
          <CardInfoWrapper>
            <SubTitleText>
              {cardSubTitle} {displayStatus && <Status color={statusColor}>{cardStatus}</Status>}
            </SubTitleText>
            {cardInfo?.map((info) => (
              <CardRow key={info?.toString()}>{info}</CardRow>
            ))}
            {cancellationPolicyLabel && (
              <CancelationBlock
                onClick={(e) => {
                  e.preventDefault()
                  handleOpenRateAndCancellationDetails()
                }}>
                <CancelationIcon />
                <CanceleationText>{cancellationPolicyLabel}</CanceleationText>
              </CancelationBlock>
            )}

            {isOpenReturn && <RailOpenReturnDetails {...openReturnProperties} />}
          </CardInfoWrapper>
          <CardImageWrapper>
            {segmentPreview && !isImageError ? (
              <CardImage
                src={segmentPreview}
                onError={onErrorImage}
                isRailSegment={segmentType === 'train'}
              />
            ) : (
              <NoHotelIcon />
            )}
          </CardImageWrapper>
        </CardInfoBlock>
        {isNDCFare && <NDCFareBadge />}
      </CardBody>
      <Footer>{!isPast && !isOnHold && <ManageButtons buttons={buttons} />}</Footer>
    </CardWrapper>
  )
}
