import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { SegmentType } from '@etta/core/enums'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Content } from './canceled-trip-segment-dialog-styled'
import { getSegmentInfo } from './get-segment-info'

type Props = {
  type: SegmentType
}

export const CanceledTripSegmentDialog = observer(function CanceledTripSegmentDialog({
  type,
}: Props) {
  const { postBookingCancellationActions, postBookingCancellationStore } = usePostBookingContext()
  const { onViewSupport } = postBookingCancellationActions
  const { canceledTripSegmentToggle } = postBookingCancellationStore
  const { t } = useTranslation()
  const { title, description } = getSegmentInfo(type)

  return (
    <Dialog
      isOpen={canceledTripSegmentToggle.isOpen}
      onClose={canceledTripSegmentToggle.handleClose}
      horizontalDimension="narrow-desktop">
      <Dialog.Body>
        <Content>
          <Block marginBottom={20}>
            <Icon name="errorPWA" color="error" size="large" />
          </Block>
          <Block marginBottom={16}>
            <Text variant="title3" color="mainText">
              {title}
            </Text>
          </Block>
          {description && (
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {description}
            </Text>
          )}
          <Block isFullWidth marginTop={32}>
            <Button variant="solid" onClick={onViewSupport} fullWidth>
              {t('PostBooking.TripDetails.CancelTripModal.Support')}
            </Button>
          </Block>
          <Block isFullWidth marginTop={14}>
            <Button variant="outline" onClick={canceledTripSegmentToggle.handleClose} fullWidth>
              {t('PostBooking.TripDetails.CancelTripModal.Back')}
            </Button>
          </Block>
        </Content>
      </Dialog.Body>
    </Dialog>
  )
})
