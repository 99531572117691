export enum FieldSettingsCategory {
  PersonalInformation = 1,
  BusinessContact = 2,
  EmployeeInformation = 3,
  BusinessAddress = 4,
  HomeAddress = 5,
  PassportInfo = 6,
  VisaInfo = 7,
  EmergencyContact = 8,
  PaymentCards = 9,
  TravelPreferences = 34,
  NationalIdInfo = 39,
  DisplaySettings = 41,
}
