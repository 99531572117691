import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  ${footnoteMedium};
`
