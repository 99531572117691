import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { useNewCarRentalFiltersModal } from './use-new-car-rental-filters-modal'
import { CarRentalFiltersModalContent } from './car-rental-filters-modal-content'

export const NewCarRentalFiltersModal = observer(function NewCarRentalFiltersModal() {
  const { carRentalFilterActions, carFiltersModalStore } = useNewCarRentalFiltersModal()

  return (
    <Modal
      isVisible={carFiltersModalStore.carFiltersToggle.isOpen}
      position="right"
      handleModalVisibility={() => carRentalFilterActions.handleCloseFiltersModal()}>
      <CarRentalFiltersModalContent />
    </Modal>
  )
})
