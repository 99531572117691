import { Result } from 'fnscript'
import type { Coords } from '../../core/coords'
import type { Bounds } from '../../core/bounds'
import type { MapPadding } from '../../core/padding'
import type { PolygonOptions } from '../../core/polygon'
import type { MarkerOptions } from '../../core/marker.options'
import type { CreateRouteArgs, CreateRouteResult, CreateRouteOptions } from '../../core/route'
import type { EttaMapEntity } from '../../core/etta-map.entity'
import { AppleMapHelpers } from './apple-map.helpers'

export class EttaAppleMap implements EttaMapEntity {
  private directionsService?: mapkit.Directions
  private instance: mapkit.Map
  private polygon?: mapkit.PolygonOverlay
  private annotations: mapkit.Annotation[] = []
  private routes: mapkit.Overlay[] = []

  constructor(instance: mapkit.Map) {
    this.instance = instance
  }

  public setPolygon(coords: Coords[], options?: PolygonOptions): void {
    const appleMapCoords: mapkit.Coordinate[] = coords.map(AppleMapHelpers.createCoordinate)

    const style = AppleMapHelpers.getMapkitStyle(options)

    this.polygon = new mapkit.PolygonOverlay(appleMapCoords, { style })
    this.instance.showItems(this.polygon, { animate: true })
  }

  public clearPolygon(): void {
    if (this.polygon) {
      this.instance.removeItems([this.polygon])
      this.polygon = undefined
    }
  }

  public setMarkers(coordinates: Coords[], options?: MarkerOptions): void {
    const { tobeUpdateAnnotations, newAnnotationsCoord } = this.getMarkers(coordinates)

    this.annotations = this.annotations.filter(
      (annotation) => !tobeUpdateAnnotations.includes(annotation),
    )

    tobeUpdateAnnotations.forEach((annotation) => {
      annotation.element.className = options?.className || ''
      this.annotations.push(annotation)
    })

    newAnnotationsCoord.forEach((coord) => {
      const annotation = AppleMapHelpers.createAnnotationByCoordinate(coord, options)
      const newAnnotation = this.instance.addAnnotation(annotation)
      this.annotations.push(newAnnotation)
    })
  }

  public clearMarkers(): void {
    if (this.annotations) {
      this.instance.removeAnnotations(this.annotations)
      this.annotations = []
    }
  }

  public createRoute({
    origin,
    destination,
    travelMode,
    options,
  }: CreateRouteArgs): Promise<CreateRouteResult> {
    const directions = this.getAppleDirections()
    return new Promise((resolve) => {
      directions.route(
        {
          origin: AppleMapHelpers.getPlaceFromRoute(origin),
          destination: AppleMapHelpers.getPlaceFromRoute(destination),
          transportType: AppleMapHelpers.getTravelMode(travelMode),
        },
        (error, result) => {
          if (error) {
            resolve(Result.Err('Directions request failed due to ' + error))
          }
          this.createRoutes(result, options)
          const response = AppleMapHelpers.getRouteResultFromAppleDirection(result)
          resolve(Result.Ok(response))
        },
      )
    })
  }

  public removeRoute() {
    if (this.instance.overlays.length > 0) {
      this.instance.removeOverlays(this.instance.overlays)
    }
    this.routes = []
  }

  public get hasRoutes(): boolean {
    return Boolean(this.routes.length)
  }

  public setRoutingBounds(padding?: MapPadding): void {
    if (padding) {
      this.setAppleMapPadding(padding)
    }
  }

  public panBy(_x: number, _y: number): void {}
  public setZoom(distance: number): void {
    this.instance.setCameraDistanceAnimated(distance, true)
  }

  public getZoom(): number | undefined {
    return undefined
  }

  public zoomOn(zoom: number, _delay: number): Promise<void> {
    const targetCameraDistance = AppleMapHelpers.convertZoomLevelToMeters(
      zoom,
      this.instance.center.copy().latitude,
    )
    return Promise.resolve(this.setZoom(targetCameraDistance))
  }

  public panTo(lat: number, lng: number): void {
    this.instance.setCenterAnimated(new mapkit.Coordinate(lat, lng), true)
  }

  public stopRouting() {
    if (this.hasRoutes) {
      this.instance.overlays.forEach((ov) => (ov.visible = false))
    }
  }

  public startRouting() {
    if (this.routes.length === 0) {
      return
    }

    if (this.instance.overlays.length > 0) {
      this.instance.overlays.forEach((ov) => (ov.visible = true))
      this.instance.showItems(this.instance.overlays)
    } else {
      this.instance.showItems(this.routes)
    }
  }

  public onBoundsChange(cb: () => void) {
    this.instance.addEventListener('region-change-end', cb)
  }

  public getBounds(): Bounds | undefined {
    const bounds = this.instance.region.toBoundingRegion()

    return this.getBoundsFromAppleBounds(bounds)
  }

  public createBoundsByCoordinates(coordinates: Coords[]): Bounds {
    const bounds = AppleMapHelpers.createBoundsByCoordinates(coordinates)
    return this.getBoundsFromAppleBounds(bounds)
  }

  public setBounds(
    bounds: Bounds,
    padding?: { top: number; left: number; bottom: number; right: number },
  ): void {
    if (padding) {
      this.setAppleMapPadding(padding)
    }
    const { sw, ne } = bounds

    const boundingRegion = new mapkit.BoundingRegion(
      ne.lat,
      ne.lng,
      sw.lat,
      sw.lng,
    ).toCoordinateRegion()

    this.instance.setRegionAnimated(boundingRegion, true)
  }

  public checkIsCoordinatesEqual(v1: Coords, v2: Coords): boolean {
    return AppleMapHelpers.checkIsCoordinatesEqual(v1, v2)
  }

  public checkOutOfRange(args: {
    boundsCenter: Coords
    mapCenter: Coords
    boundsDistanceMeters?: number | undefined
  }): boolean {
    const { boundsCenter, mapCenter, boundsDistanceMeters } = args

    return AppleMapHelpers.checkOutOfRange({
      boundsCenter,
      mapCenter,
      boundsDistanceMeters,
    })
  }

  public getCenter(): Coords {
    const center = this.instance.center
    return this.getCoordsFromAppleCoords(center)
  }

  private getBoundsFromAppleBounds(appleBounds: mapkit.BoundingRegion): Bounds {
    const { eastLongitude, northLatitude, westLongitude, southLatitude } = appleBounds
    const sw = new mapkit.Coordinate(southLatitude, westLongitude)
    const ne = new mapkit.Coordinate(northLatitude, eastLongitude)
    const center = appleBounds.copy().toCoordinateRegion().center

    return {
      sw: this.getCoordsFromAppleCoords(sw),
      ne: this.getCoordsFromAppleCoords(ne),
      center: this.getCoordsFromAppleCoords(center),
    }
  }

  private getCoordsFromAppleCoords(appleCoords: mapkit.Coordinate): Coords {
    return {
      lat: appleCoords.latitude,
      lng: appleCoords.longitude,
    }
  }

  private getAppleDirections(): mapkit.Directions {
    if (!this.directionsService) {
      this.directionsService = new mapkit.Directions()
    }
    return this.directionsService
  }

  private createRoutes(
    directionResponse: mapkit.DirectionsResponse,
    options?: CreateRouteOptions,
  ): void {
    const routes: mapkit.Overlay[] = []
    directionResponse.routes.forEach((route) => {
      if (options?.polylineOptions?.strokeColor) {
        route.polyline.style.strokeColor = options.polylineOptions.strokeColor
      }
      if (options?.polylineOptions?.strokeOpacity) {
        route.polyline.style.strokeOpacity = options.polylineOptions.strokeOpacity
      }
      if (options?.polylineOptions?.strokeWeight) {
        route.polyline.style.lineWidth = options.polylineOptions.strokeWeight
      }
      routes.push(route.polyline)
    })
    this.routes = routes
  }

  private setAppleMapPadding(padding: MapPadding) {
    this.instance.padding = new mapkit.Padding({ ...padding })
  }

  private getMarkers(coordinates: Coords[]) {
    const tobeUpdateAnnotations: mapkit.Annotation[] = []
    const newAnnotationsCoord: Coords[] = []
    coordinates.forEach((coord) => {
      const matched = this.annotations.find((annotation) => {
        return AppleMapHelpers.checkIsCoordinatesEqual(
          coord,
          this.getCoordsFromAppleCoords(annotation.coordinate),
        )
      })

      if (matched) {
        tobeUpdateAnnotations.push(matched)
      } else {
        newAnnotationsCoord.push(coord)
      }
    })

    return { tobeUpdateAnnotations, newAnnotationsCoord }
  }
}
