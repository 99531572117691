import { TripStatus } from '@fiji/graphql/types'
import type { ColorPalette } from '@fiji/types'

export function getSegmentStatusColor(segmentStatus?: TripStatus | null): keyof ColorPalette {
  switch (segmentStatus) {
    case TripStatus.Cancelled:
    case TripStatus.Unconfirmed:
      return 'error'
    case TripStatus.Confirmed:
      return 'success'
    case TripStatus.WaitListed:
      return 'warning'
    default:
      return 'bodyText'
  }
}
