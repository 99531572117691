import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { Container, IconWrapper, TitleWrapper, Title } from './row-mobile-styled'

export function RowMobile({ iconSlot, children, isGrouped, countryName, searchType }: LayoutProps) {
  if (searchType === 'train') {
    return (
      <Container isGrouped={isGrouped}>
        <IconWrapper>{iconSlot}</IconWrapper>
        <TitleWrapper>
          <Title>{children}</Title>
          {countryName && (
            <Text color="bodyText" variant="footnoteMedium" noWrap isBlock textOverflow="ellipsis">
              {countryName}
            </Text>
          )}
        </TitleWrapper>
      </Container>
    )
  }

  return (
    <Container>
      <IconWrapper>{iconSlot}</IconWrapper>
      <TitleWrapper>
        <Title>{children}</Title>
      </TitleWrapper>
    </Container>
  )
}
