import styled from 'styled-components'
import { UnstyledButton, getKeyboardNavigationStyle, subHeadMedium } from '@fiji/style'

export const CurrentLocationContainer = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  height: 54px;
  color: ${(props) => props.theme.colors.primary};
  ${subHeadMedium};
  padding-left: 6px;

  > div {
    margin: 5px 4px;
  }

  > span {
    display: flex;
    padding: 13px 18px 13px 5px;
    flex-grow: 1;
  }

  ${getKeyboardNavigationStyle({ offset: -2 })}
`

export const CurrentLocationText = styled.span<{
  active: boolean
}>`
  display: inline-block;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => (props.active ? props.theme.colors.primary : 'transparent')};
`
