import styled from 'styled-components'
import { KeyboardNavigationStyle, captionMedium, footnoteMedium, subHeadStrong } from '@fiji/style'
import type { SvgIconProps } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'

export const InformationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const InformationListItem = styled.li`
  display: flex;
  flex-direction: column;
`

export const VendorLogoContainer = styled.div`
  height: 29px;
  margin-bottom: 4px;
`

export const VendorLogo = styled.img`
  height: 100%;
`

export const Address = styled.span`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.mainText};
`

export const InformationListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InformationListItemTitle = styled.span`
  ${captionMedium};
  display: block;
  color: ${(p) => p.theme.colors.bodyText};
  margin-bottom: 2px;
`

export const InformationListItemValue = styled.span`
  ${subHeadStrong};
  display: block;
  color: ${(p) => p.theme.colors.mainText};
  text-decoration: none;
  ${KeyboardNavigationStyle}
`

export const InformationListItemIcon = styled(Icon).attrs<SvgIconProps, SvgIconProps>((props) => ({
  ...props,
  size: 'medium',
}))``
