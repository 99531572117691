import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 0 16px;
`

export const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 12px 20px 24px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.borderLight};
  padding-top: 14px;
  padding-bottom: 18px;
  border-radius: 12px;
`
