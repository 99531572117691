import type { RailCard } from '@fiji/graphql/types'

export function getUniqueGroupNames(railCardsData?: RailCard[]) {
  if (!railCardsData?.length) {
    return []
  }

  return railCardsData.reduce<string[]>((acc, item) => {
    if (!item) {
      return acc
    }
    const itemGroupName = item.groupName

    if (!acc.includes(itemGroupName)) {
      return [...acc, item.groupName]
    }

    return acc
  }, [])
}
