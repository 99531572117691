import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { CopyableWrapper } from '@etta/ui/copyable-value'
import { Text } from '@etta/ui/text'
import type { Photo } from '@fiji/graphql/types'
import { HotelPhotosCarousel } from '@etta/components/hotel-photos-carousel/hotel-photos-carousel'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { CancellationDate } from './cancellation-date'
import { Container, Row, Cell, SeparatorBlock } from './traveler-styled'

const i18nBase = 'PostBooking.HotelDetails'

type Props = {
  travelerName: string
  confirmationNumber?: string | null
  recordLocator?: string | null
  photos?: Photo[] | null
  isCancelled: boolean
  isCompleted: boolean
  cancellationDate?: string | null
  hotelName?: string | null
}

export function Traveler({
  travelerName,
  cancellationDate,
  confirmationNumber,
  isCancelled,
  isCompleted,
  photos,
  recordLocator,
  hotelName,
}: Props) {
  const { t } = useTranslation()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const pnrNumberTranslation = brandConfigurationStore.isAppleTheme
    ? t(`${i18nBase}.AppleTravelReference`)
    : t(`${i18nBase}.RecordLocator`)

  return (
    <Container isPhotoExist={!!photos?.length} isCancelled={isCancelled}>
      {!!photos?.length && (
        <>
          <HotelPhotosCarousel photos={photos} />
          <Block marginBottom={16} />
        </>
      )}
      {!!(isCancelled && cancellationDate) && (
        <>
          <Row>
            <CancellationDate date={cancellationDate} />
          </Row>
          <Row>
            <SeparatorBlock lineType="dashed" color="borderDark" />
          </Row>
        </>
      )}
      <Row>
        <Cell aria-label={`${t(`${i18nBase}.Traveler`)}: ${travelerName || '-'}`}>
          <Block>
            <Text variant="captionMedium" color="bodyText">
              {t(`${i18nBase}.Traveler`)}
            </Text>
          </Block>
          <Block marginTop={4}>
            <Text variant="subHeadStrong" color="mainText">
              {travelerName}
            </Text>
          </Block>
        </Cell>
        <Cell aria-label={`${pnrNumberTranslation}: ${recordLocator || '-'}`}>
          <Block>
            <Text variant="captionMedium" color="bodyText">
              {pnrNumberTranslation}
            </Text>
          </Block>
          <Block marginTop={4}>
            <Text variant="subHeadStrong" color="mainText">
              <CopyableWrapper value={recordLocator || '-'}>{recordLocator || '-'}</CopyableWrapper>
            </Text>
          </Block>
        </Cell>
      </Row>
      {!isCancelled && !isCompleted && (
        <>
          <Row>
            <SeparatorBlock lineType="dashed" color="borderDark" />
          </Row>
          <Row>
            <Cell
              aria-label={`${hotelName} ${t(`${i18nBase}.Confirmation`)}: ${
                confirmationNumber || '-'
              }`}>
              <Block>
                <Text variant="captionMedium" color="bodyText">
                  {hotelName} {t(`${i18nBase}.Confirmation`)}
                </Text>
              </Block>
              <Block marginTop={4}>
                <Text variant="subHeadStrong" color="mainText">
                  <CopyableWrapper value={confirmationNumber || '-'}>
                    {confirmationNumber || '-'}
                  </CopyableWrapper>
                </Text>
              </Block>
            </Cell>
          </Row>
        </>
      )}
    </Container>
  )
}
