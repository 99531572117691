import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { headline, subHeadMedium } from '@fiji/style/typography'
import { Block } from '@etta/ui/block'

export const Body = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Headline = styled.div`
  ${headline};
  line-height: 2.2rem;
  padding-bottom: 0.8rem;
`

export const Subheadline = styled.div`
  ${subHeadMedium};
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.bodyText};
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 14px 32px 14px 16px;
  display: flex;
  justify-content: space-between;
`

export const HalfBlock = styled(Block)`
  min-width: 160px;
  width: 50%;
`

export const HalfBlockInline = styled(Block)`
  display: flex;
  gap: 16px;
  > :first-child {
    flex-basis: 50%;
  }
  > :last-child {
    flex-basis: 50%;
  }
`

export const SaveButton = styled(Button)`
  height: 44px;
`
