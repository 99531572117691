import type { HTMLAttributes, ReactNode } from 'react'
import type { Place } from '@fiji/types'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { Modal } from '@etta/ui/modal'
import { Swap } from '@etta/ui/swap'
import type { RecentSearchesType } from '@etta/modules/recent-searches/core/value-objects/recent-searches-type.value-object'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { SearchMechanism } from '@etta/components/search-mechanism'
import { LocationSearchFieldLayout } from './layout'
import { Input } from './input'

type Props = Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  'data-tracking-id'?: string
  disabled?: boolean
  hasCurrentLocationSearch?: boolean
  inputAriaLabel?: string
  searchType?: SearchType
  isOutboundTrip?: boolean
  label?: string
  latestPlaces?: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  onChange: (value: Place, address?: AddressInput) => void
  useNewLocationSearch?: boolean
  value?: Place | null
  inputSlot?: ReactNode
  isForSearchHeader?: boolean
  isModalAnimated?: boolean
  isModalVisible?: boolean
  customModalDescription?: string
  recentSearchesType?: RecentSearchesType
  hasError?: boolean
}

export function LocationSearchField({
  'aria-label': ariaLabel,
  'data-tracking-id': dataTrackingId,
  disabled,
  hasCurrentLocationSearch,
  inputAriaLabel,
  searchType,
  isOutboundTrip,
  label,
  latestPlaces,
  locationsSortOrder,
  onChange,
  useNewLocationSearch,
  value,
  inputSlot,
  isForSearchHeader = false,
  isModalAnimated = true,
  isModalVisible = false,
  customModalDescription,
  recentSearchesType,
  hasError = false,
  ...inputProps
}: Props) {
  const modalToggle = useTogglePopup(isModalVisible)
  const isLabelExist = value?.airportCode || value?.name

  return (
    <LocationSearchFieldLayout
      isSearchWindowOpen={modalToggle.isOpen}
      onModalClose={modalToggle.handleClose}
      isForSearchHeader={isForSearchHeader}
      inputSlot={
        <Swap isSlot={<div onClick={modalToggle.handleOpen}>{inputSlot}</div>} is={!!inputSlot}>
          <Input
            onClick={modalToggle.handleOpen}
            isLabelExist={isLabelExist}
            label={label}
            ariaLabel={ariaLabel}
            data-tracking-id={dataTrackingId}
            isDisabled={disabled}
            hasError={hasError}
          />
        </Swap>
      }
      modalSlot={
        <Modal
          isAnimated={isModalAnimated}
          isVisible={modalToggle.isOpen}
          handleModalVisibility={modalToggle.handleClose}
        />
      }
      listSlot={
        <SearchMechanism
          searchType={searchType}
          disabled={disabled}
          onChange={onChange}
          isModalOpen={modalToggle.isOpen}
          onModalClose={modalToggle.handleClose}
          inputProps={{ ...inputProps, 'aria-label': inputAriaLabel }}
          useNewLocationSearch={useNewLocationSearch}
          locationsSortOrder={locationsSortOrder}
          isOutboundTrip={isOutboundTrip}
          latestPlaces={latestPlaces}
          hasCurrentLocationSearch={hasCurrentLocationSearch}
          customSearchDescription={customModalDescription}
          recentSearchesType={recentSearchesType}
        />
      }
    />
  )
}
