import { memo } from 'react'
import type { ChangeEvent } from 'react'
import { groupBy } from 'ramda'
import { useTranslation } from 'react-i18next'
import type {
  SeatMapRowValueObject,
  SelectedSeatValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import { SeatmapCabinClass } from '@etta/modules/seat-map/core/enums'
import type { SeatSelectedArgs } from '@etta/modules/seat-map/core/types'
import type { SegmentSeatNumber } from '../../../air-seat-map.types'
import { getCabinClassTitle } from './get-cabin-class-title'
import { checkEconomyClass } from './check-economy-class'
import { SeatRowContainer } from './seat-row-container'
import { SeatRowHeader } from './seat-row-header'
import { SeatRow } from './seat-row'
import { getSelectedSeatsMap } from './get-selected-seats-map'
import { getMostFrequentAisle } from './get-most-frequent-aisle'
import { getLargestRow } from './get-largest-row'
import { Container, SeatCabinClass, ZoomAnchor } from './base-seat-map-styled'

type Props = {
  usedCabinClass?: SeatmapCabinClass
  onSeatChecked: (args: SeatSelectedArgs) => void
  bookedSeat?: SelectedSeatValueObject | null
  selectedSeat?: SelectedSeatValueObject | null
  seatRows: SeatMapRowValueObject[]
  seatsNumberList: SegmentSeatNumber[]
  isReadonly: boolean
  isNewSeatMap?: boolean
  onOpenPopover?: (event: ChangeEvent<HTMLInputElement>) => void
}

const CabinClassOrder = [
  SeatmapCabinClass.First,
  SeatmapCabinClass.Business,
  SeatmapCabinClass.PremiumCoach,
  SeatmapCabinClass.Coach,
]

function BaseSeatMapRef({
  seatRows,
  seatsNumberList,
  bookedSeat,
  selectedSeat,
  usedCabinClass,
  onSeatChecked,
  isReadonly,
  isNewSeatMap,
  onOpenPopover,
}: Props) {
  const seatGroups = groupBy((seat) => seat.cabinClass, seatRows)
  const selectedSeatsMap = getSelectedSeatsMap(seatsNumberList)
  const { t } = useTranslation()

  return (
    <Container role="table" aria-label={t('AirSeatMap.TableName')}>
      <ZoomAnchor id="zoomToNode" />
      {CabinClassOrder.map((cabinClass) => {
        const seatsByCabinClass = seatGroups[cabinClass]
        const isFilteredCabinClass = usedCabinClass && usedCabinClass !== cabinClass

        if (!seatsByCabinClass || isFilteredCabinClass) {
          return null
        }

        const isEconomyClass = checkEconomyClass(cabinClass)

        const cabinClassTitle = getCabinClassTitle(cabinClass)
        const largestRow = getLargestRow(seatsByCabinClass)
        const mostFrequentAislesColumns = getMostFrequentAisle(seatsByCabinClass)
        return (
          <SeatCabinClass
            data-tracking-id="seat-map"
            key={cabinClass}
            aria-label={cabinClassTitle}
            isNewSeatMap={isNewSeatMap}
            role="rowgroup">
            {seatsByCabinClass.map(({ number: rowNumber, seat: seatList }, rowIndex) => {
              const isFirstRow = rowIndex === 0

              return (
                <>
                  {isFirstRow && (
                    <SeatRowContainer
                      key={rowNumber}
                      rowIndex={rowIndex - 1}
                      seatList={largestRow?.seat}
                      isEconomyClass={isEconomyClass}>
                      <SeatRowHeader
                        mostFrequentAislesColumns={mostFrequentAislesColumns}
                        seatList={largestRow!.seat}
                        isEconomyClass={isEconomyClass}
                      />
                    </SeatRowContainer>
                  )}
                  <SeatRowContainer
                    key={rowNumber}
                    seatList={seatList}
                    rowIndex={rowIndex}
                    areIconsAvailable
                    isEconomyClass={isEconomyClass}>
                    <SeatRow
                      mostFrequentAislesColumns={mostFrequentAislesColumns}
                      largestRow={largestRow!.seat}
                      seatList={seatList}
                      isEconomyClass={isEconomyClass}
                      rowNumber={rowNumber}
                      selectedSeatsMap={selectedSeatsMap}
                      onSeatChecked={onSeatChecked}
                      bookedSeat={bookedSeat}
                      selectedSeat={selectedSeat}
                      isReadonly={isReadonly}
                      isNewSeatMap={isNewSeatMap}
                      onOpenPopover={onOpenPopover}
                      cabinClass={cabinClassTitle}
                    />
                  </SeatRowContainer>
                </>
              )
            })}
          </SeatCabinClass>
        )
      })}
    </Container>
  )
}

export const BaseSeatMap = memo(BaseSeatMapRef)
