import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { BrandsMultiSelect } from '../../../brands-multi-select'
import type { SortedBrands } from './types'
import { Container } from './brands-show-more-styled'
import { useBrandsShowMore } from './use-brands-show-more'

type Props = {
  onChange: (selected: string[]) => void
  selected: string[]
  sortedBrands: SortedBrands
}

const i18Base = 'HotelFilters.Hotel'

export const BrandsShowMore = ({ selected, onChange, sortedBrands }: Props) => {
  const { t } = useTranslation()
  const {
    collapseToggle,
    selectedIdsMap,
    onSelect,
    isCollapsed,
    visibleRowsSelected,
    visibleRowsNotSelected,
  } = useBrandsShowMore({
    selected,
    onChange,
    superBrands: sortedBrands.selectedSuperBrands,
    brands: sortedBrands.selectedBrands,
  })

  return (
    <>
      <Container>
        <BrandsMultiSelect
          isEmptyBlockHidden
          visibleRows={isCollapsed ? visibleRowsSelected : undefined}
          key={'selected'}
          superBrands={sortedBrands.selectedSuperBrands}
          brands={sortedBrands.selectedBrands}
          selectedIdsMap={selectedIdsMap}
          onSelect={onSelect}
        />
        <BrandsMultiSelect
          visibleRows={isCollapsed ? visibleRowsNotSelected : undefined}
          key={'not-selected'}
          superBrands={sortedBrands.notSelectedSuperBrands}
          brands={sortedBrands.notSelectedBrands}
          selectedIdsMap={selectedIdsMap}
          onSelect={onSelect}
        />
      </Container>
      <Block marginTop={8}>
        <Button variant="text" onClick={collapseToggle.handleToggle}>
          <Text variant="buttonNormal" color="primary1" textTransform="capitalize">
            {t(`Callout.${isCollapsed ? 'ShowMore' : 'ShowLess'}`)} {t(i18Base + '.Brands')}
          </Text>
        </Button>
      </Block>
    </>
  )
}
