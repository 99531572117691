import { Action, Inject } from '@etta/di'
import type { RailCardValueObject } from '@etta/modules/review-trip/core/value-objects/rail-card.value-object'
import type { RailTripType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import type { Dates, Place, TimeRange } from '@fiji/types'
import { RailSearchFormService } from '../services/rail-search-form.service'

@Action()
export class RailSearchFormActions {
  constructor(
    @Inject()
    private readonly railSearchFormService: RailSearchFormService,
  ) {}

  onOriginPlaceChange(place: Place) {
    this.railSearchFormService.changeOriginPlace(place)
  }

  onDestinationPlaceChange(place: Place) {
    this.railSearchFormService.changeDestinationPlace(place)
  }

  onDatesSelect({ date, dateEnd }: Dates) {
    this.railSearchFormService.changeDates({ date, dateEnd })
  }

  onOriginTimeChange(time: TimeRange) {
    this.railSearchFormService.changeOriginTime(time)
  }

  onDestinationTimeChange(time: TimeRange) {
    this.railSearchFormService.changeDestinationTime(time)
  }

  onTripTypeChange(tripType: RailTripType) {
    this.railSearchFormService.changeTripType(tripType)
  }

  onSwitchLocations() {
    this.railSearchFormService.switchLocations()
  }

  onRailCardSelect(railCard: RailCardValueObject) {
    this.railSearchFormService.changeRailCard(railCard)
  }

  onRailCardDeselect() {
    this.railSearchFormService.changeRailCard()
  }

  dropEditCache() {
    this.railSearchFormService.dropEditCache()
  }
}
