import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Container, Heading } from './info-card-styled'
import { Card } from './card'

type Props = {
  travelerName: string
  transactionId: string
  collectionRefNumber: string | null
  isCollect: boolean
}

const i18nBase = 'PostBooking.TrainDetails'

export function InfoCard({ travelerName, transactionId, collectionRefNumber, isCollect }: Props) {
  const { t } = useTranslation()
  return (
    <Container>
      <Heading>
        <Card headline={t(`${i18nBase}.Traveler`)} value={travelerName} />
        <Card headline={t(`${i18nBase}.TransactionId`)} value={transactionId} />
      </Heading>
      {collectionRefNumber && isCollect && (
        <Block marginTop={16}>
          <Card headline={t(`${i18nBase}.CollectionRefNo`)} value={transactionId} />
        </Block>
      )}
    </Container>
  )
}
