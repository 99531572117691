import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { AirSearchForm } from '@etta/components/air-search-form'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { CloseIconWrapper, ModalContent, TitleWrapper } from './styled'

const DATA_TRACKING_ID = 'RTP_AIR_SEARCH_FORM_MODAL'

export const AirSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const {
    postBookingReviewTripPageStore,
    postBookingReviewTripPageActions,
  } = useReviewTripContext()

  useEffect(() => {
    return () => {
      postBookingReviewTripPageActions.handleChangeFlightClose()
    }
  }, [postBookingReviewTripPageActions])

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={postBookingReviewTripPageActions.handleCloseModal}
      isVisible={postBookingReviewTripPageStore.flightSearchFormModal.isOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton
          onClick={postBookingReviewTripPageActions.handleCloseModal}
          icon={'closePWA'}
          size={'medium'}
        />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('PostBooking.FlightDetails.Modify')}</Text>
      </TitleWrapper>
      <ModalContent>
        <AirSearchForm />
      </ModalContent>
    </Modal>
  )
})
