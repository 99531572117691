import { useEffect, useRef } from 'react'

type Args = {
  message: string
}

export function useRecalculateTruncate({ message }: Args) {
  const previousMessageRef = useRef(message)
  const shouldRecalculateTruncate = previousMessageRef.current !== message

  useEffect(() => {
    if (shouldRecalculateTruncate) {
      previousMessageRef.current = message
    }
  }, [message, shouldRecalculateTruncate])

  return {
    shouldRecalculateTruncate,
  }
}
