import type { ValueType, OptionType } from '../types'

type Args = {
  values?: ValueType[]
  options: OptionType[]
}

export const findLabelsByValues = ({ values, options }: Args) => {
  if (!values) {
    return []
  }

  return options
    .filter(({ value }) => values.includes(value))
    .map(({ shortLabel, label }) => shortLabel || label)
}
