import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { StationFilterStation } from '@fiji/graphql/types'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { getRailFilterStations } from './rail-stations-filter-utils'

const i18Base = 'RailFilters.Rail.Place'

export function useRailStationsFilter() {
  const { t } = useTranslation()

  const { railFiltersStore, railFilterAction, railStore, railQueryParamStore } = useRailContext()

  const { originStations, destinationStations } = useMemo(
    () => getRailFilterStations(railStore.railFilters?.station ?? []),
    [railStore.railFilters?.station],
  )

  const onIsOriginStationSelected = (station: StationFilterStation) => {
    return !!railFiltersStore.changedFilters.origin?.find(({ code }) => code === station.code)
  }

  const onIsDestinationStationSelected = (station: StationFilterStation) => {
    return !!railFiltersStore.changedFilters.destination?.find(({ code }) => code === station.code)
  }

  return {
    origin: t(i18Base + '.Origin', { place: railQueryParamStore.originPlace.name }),
    destination: t(i18Base + '.Destination', { place: railQueryParamStore.destinationPlace.name }),
    onSelectOrigin: railFilterAction.onChangeOriginStations,
    onSelectDestination: railFilterAction.onChangeDestinationStations,
    originStations,
    destinationStations,
    onIsDestinationStationSelected,
    onIsOriginStationSelected,
  }
}
