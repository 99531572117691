import type { TicketAttributes } from '@fiji/types'
import type { FlightLegSegment } from '@fiji/graphql/types'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import { toGQLOnTheFlyContentAssessment } from '@etta/modules/air-search/infra/air-search.data-provider/to-fare-attributes.mapper'

type Props = {
  flight: FlightLegSegment
}

export function useFareAttributes({ flight }: Props) {
  const { airSearchStore } = useAirSearchContext()

  const fareAttributes = airSearchStore.fareAttributes.get(flight.legId)

  const fareAttributesComposed: TicketAttributes[] | undefined = fareAttributes?.flatMap((fare) =>
    fare.comparison.utas.map((subfare) => ({
      id: subfare.category,
      name: subfare.description,
      assessment: toGQLOnTheFlyContentAssessment(subfare.assessment),
    })),
  )

  const filteredFareAttributes = fareAttributesComposed?.filter((el, i, arr) => {
    return arr.findIndex((curr) => curr.id === el.id && curr.name === el.name) === i
  })

  const classType = fareAttributes && fareAttributes[0]?.comparison.brand.name

  return {
    filteredFareAttributes,
    classType,
  }
}
