import {
  PlaceContainer,
  TypeIndicator,
  Description,
  LoadingImage,
  LoadingName,
  LoadingLogos,
  LogoContainer,
} from './mobile-place-styled'

type Props = {
  isOpen?: boolean
  isLoading?: boolean
}

export function MobileLoadingPlace({ isOpen, isLoading }: Props) {
  return (
    <PlaceContainer isOpen={isOpen} isLoading={isLoading}>
      <TypeIndicator>
        <LoadingImage />
      </TypeIndicator>
      <Description>
        <LoadingName />
        <LogoContainer>
          <LoadingLogos />
        </LogoContainer>
      </Description>
    </PlaceContainer>
  )
}
