import i18n from 'i18next'
import type { ViewState } from '@etta/ui/view-state-modal'

type Args = {
  title?: string
  viewState?: ViewState
}

export function getErrorText({ viewState, title }: Args) {
  switch (viewState) {
    case 'error':
      return {
        title: title || i18n.t('ViewStateModal.Error'),
        button: i18n.t('ViewStateModal.Ok'),
      }
    case 'ride-hail-error':
      return {
        title: i18n.t('ViewStateModal.RideHailError.FailedToCancel'),
        button: i18n.t('ViewStateModal.OK'),
      }
    case 'ride-hail-cancel-already-complete-error':
      return {
        title: i18n.t('ViewStateModal.RideHailError.AlreadyCanceled'),
        button: i18n.t('ViewStateModal.OK'),
      }
    default:
      return {
        title: i18n.t('ViewStateModal.Error'),
        button: i18n.t('ViewStateModal.Ok'),
      }
  }
}
