import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { TimeRangePickerMobile } from './mobile'
import { TimeRangePickerDesktop } from './desktop'

export function AirTimePickerLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<TimeRangePickerMobile {...props} />}
      desktopSlot={<TimeRangePickerDesktop {...props} />}
    />
  )
}
