import styled from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  flex: 1;
`

export const Section = styled.div`
  margin: 24px 32px;
`

export const AmenitiesContainer = styled.div`
  display: flex;
  min-height: 185px;
  background: ${(props) => props.theme.colors.white};
`

export const AmenitiesWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 24px 0 24px 32px;
`

export const CarImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin-right: 32px;
`

export const CarImage = styled.img`
  width: 100%;
`
