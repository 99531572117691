import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity'
import { SeatMapsModal } from '@etta/screens/review-trip-page/flight-details-modal/seat-maps-modal'
import { Modal } from '@etta/ui/modal'
import { ModalDetails } from '@etta/components/modal-details'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { AirSeatMap } from '@etta/modules/seat-map'
import { useAirSeatMapModal } from './use-air-seat-map-modal'
import { Header, HeaderTitle, TripTitle } from './air-seat-map-modal-styled'

export type Props = {
  segments: FlightSegmentEntity[]
  chosenAirSeatMapLegId: string
  onClose: () => void
  isOpen: boolean
  tripName?: string | null
}

export const AirSeatMapModal = observer(function AirSeatMapModal({
  segments,
  chosenAirSeatMapLegId,
  onClose,
  isOpen,
  tripName = '',
}: Props) {
  const { t } = useTranslation()
  const { postBookingTripStore, postBookingTripActions } = usePostBookingContext()
  const { bookingRef: itineraryId, processId: bookingId } = postBookingTripStore.trip

  const {
    displayServiceClass,
    isLoading,
    fareSegmentSeatRows,
    selectedSegmentId,
    onSelectedSegmentIdChange,
    onSeatsApprove,
    initialSegmentSeatNumberList,
    adaptiveFlight,
    seatStateStatus,
    resetSeatStateStatus,
  } = useAirSeatMapModal({
    itineraryId,
    bookingId,
    segments,
    chosenAirSeatMapLegId,
    onClose,
    onSeatsChanged: postBookingTripActions.loadTrip,
  })

  return (
    <>
      <SeatMapsModal closeModal={resetSeatStateStatus} status={seatStateStatus} />
      <ModalDetails
        isVisible={isOpen}
        onClose={onClose}
        isAdaptivePosition={true}
        horizontalDimension="content-760">
        <Modal.Title withBorder>
          <Header>
            <HeaderTitle>{t('AirSeatMap.SelectSeats')}</HeaderTitle>
            <TripTitle>{tripName}</TripTitle>
          </Header>
        </Modal.Title>
        <AirSeatMap
          mode="edit"
          skin="no-mobile-header"
          segmentsSeatRows={fareSegmentSeatRows}
          initialSegmentSeatNumberList={initialSegmentSeatNumberList}
          selectedSegmentId={selectedSegmentId}
          subTitle={displayServiceClass}
          adaptedFlightSegments={adaptiveFlight?.flight?.adaptedSegments}
          onClose={onClose}
          onApprove={onSeatsApprove}
          onSelectedSegmentIdChange={onSelectedSegmentIdChange}
          isLoading={isLoading}
        />
      </ModalDetails>
    </>
  )
})
