import { observer } from 'mobx-react-lite'
import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { TripFlightContentProps } from './types'
import { TripFlightContent } from './trip-flight-content'

type Props = TripFlightContentProps & {
  isVisible: boolean
  isAdaptivePosition?: boolean
}

export const TripFlight = observer(function TripFlight({
  isVisible,
  onClose,
  isAdaptivePosition,
  ...props
}: Props) {
  const screenType = useScreenType()
  const { postBookingTripStore } = usePostBookingContext()
  const { processId, bookingRef, segments, flightId, type, isMultiCity } = postBookingTripStore.trip
  const { travelerNames } = postBookingTripStore
  const travelerName = travelerNames?.travelerFullName

  const modalPosition = isAdaptivePosition && screenType !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      position={modalPosition}
      backgroundColor="background"
      horizontalDimension="content-760">
      <TripFlightContent
        processId={processId}
        itineraryId={bookingRef}
        allSegments={segments}
        flightId={flightId}
        tripType={type}
        isMultiCity={isMultiCity}
        {...props}
        travelerName={travelerName}
        onClose={onClose}
      />
    </Modal>
  )
})
