import { Block } from '@etta/ui/block'
import { CopyableWrapper } from '@etta/ui/copyable-value'
import { Text } from '@etta/ui/text'
import { CancellationDate } from './cancellation-date'
import { useTripCarTraveler } from './use-trip-car-traveler'
import { Container, Row, Cell, CellName, CellValue, Separator } from './trip-car-traveler-styled'

type Props = {
  confirmationNumber?: string | null
  recordLocator?: string | null
  isCancelled: boolean
  isCompleted: boolean
  cancellationDate?: string | null
  rentalVendorName?: string | null
  travelerName: string
}

export function TripCarTraveler({
  confirmationNumber,
  recordLocator,
  isCancelled,
  isCompleted,
  cancellationDate,
  rentalVendorName,
  travelerName,
}: Props) {
  const {
    topAriaLabel,
    bottomAriaLabel,
    translations,
    horizontalPadding,
    verticalPadding,
  } = useTripCarTraveler({
    confirmationNumber,
    recordLocator,
    isCancelled,
    rentalVendorName,
    travelerName,
  })
  return (
    <Container horizontalPadding={horizontalPadding} verticalPadding={verticalPadding}>
      {isCancelled &&
        (cancellationDate ? (
          <>
            <Row>
              <CancellationDate date={cancellationDate} />
            </Row>
            <Separator />
          </>
        ) : (
          <Block marginBottom={10}>
            <Text tabIndex={0} variant="subHeadStrong" color="error">
              {translations.cancelledBookTranslation}
            </Text>
          </Block>
        ))}
      <Row aria-label={topAriaLabel}>
        <Cell>
          <CellName>{translations.travelerTranslation}</CellName>
          <CellValue>{travelerName}</CellValue>
        </Cell>
        <Cell>
          <CellName>{translations.recordLocatorTranslation}</CellName>
          <CellValue>
            <CopyableWrapper value={recordLocator || ''}>{recordLocator}</CopyableWrapper>
          </CellValue>
        </Cell>
      </Row>
      {!isCancelled && !isCompleted && (
        <>
          <Separator />
          <Row aria-label={bottomAriaLabel}>
            <Cell>
              <CellName>{translations.confirmationNumberTranslation}</CellName>
              <CellValue>
                <CopyableWrapper value={confirmationNumber || ''}>
                  {confirmationNumber}
                </CopyableWrapper>
              </CellValue>
            </Cell>
          </Row>
        </>
      )}
    </Container>
  )
}
