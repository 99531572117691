import styled from 'styled-components'
import { regularListItem, subHeadMedium } from '@fiji/style'

export const SeparatorWrapper = styled.div`
  > div {
    padding: 4px 16px;
  }
`

export const ListItem = styled.li<{
  active: boolean
}>`
  cursor: pointer;
  position: relative;
  padding: 2px 0;
  margin: 0;
  ${subHeadMedium};
  ${regularListItem};
  color: ${(p) => p.theme.primaryColor};
  box-shadow: inset 0px -1px 0px ${(p) => p.theme.colors.borderLight};

  &:hover {
    background-color: ${(p) => p.theme.inputBorderColor};
  }

  &:last-child {
    box-shadow: none;
  }
`
