import { Service } from '@etta/di'
import { TripType } from '@etta/core/enums/trip-type.enum'
import type {
  GroupedTripsByMonth,
  TripsList,
} from '../../core/value-object/trips-list.value-object'

type Args = {
  existing?: GroupedTripsByMonth[]
  incoming: GroupedTripsByMonth[]
  tripType: TripType
}

type SortArgs = {
  trips: TripsList[]
  tripType: TripType
}

@Service()
export class MergeGroupsService {
  merge({ existing, incoming, tripType }: Args): GroupedTripsByMonth[] {
    if (!existing) {
      return incoming
    }
    incoming.forEach((incomingGroup) => {
      const existingMonthIndex = existing?.findIndex((existingGroup) => {
        return (
          existingGroup.year === incomingGroup.year && existingGroup.month === incomingGroup.month
        )
      })

      if (existingMonthIndex === -1) {
        existing.push(incomingGroup)
        return
      }

      const trips = [...existing[existingMonthIndex].trips, ...incomingGroup.trips]
      const sortedTrips = this.sort({ trips, tripType })

      existing[existingMonthIndex].trips = sortedTrips
    })

    return existing
  }

  sort({ tripType, trips }: SortArgs) {
    if (tripType === TripType.Past) {
      return trips
    }
    return trips.sort((a, b) => {
      const aTime = new Date(a.rollUpStartTime).getTime()
      const bTime = new Date(b.rollUpStartTime).getTime()
      return aTime - bTime
    })
  }
}
