import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetPurchaseInfoDocument, GetPostPurchaseInfoDocument } from '@fiji/graphql/hooks'
import type {
  GetPurchaseInfoQuery,
  GetPostPurchaseInfoQuery,
  GetPurchaseInfoQueryVariables,
  GetPostPurchaseInfoQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class PurchaseInfoDataProvider extends GraphqlDataProvider {
  getPurchaseInfo(input: GetPurchaseInfoQueryVariables['input'], forceUpdate?: boolean) {
    return this.client.query<GetPurchaseInfoQuery, GetPurchaseInfoQueryVariables>({
      query: GetPurchaseInfoDocument,
      variables: {
        input: {
          itineraryId: input.itineraryId,
          deviceClass: input.deviceClass,
          isCCNumberMasked: input.isCCNumberMasked,
        },
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }

  getPostPurchaseInfo(input: GetPostPurchaseInfoQueryVariables['input'], forceUpdate?: boolean) {
    return this.client.query<GetPostPurchaseInfoQuery, GetPostPurchaseInfoQueryVariables>({
      query: GetPostPurchaseInfoDocument,
      variables: {
        input: {
          itineraryId: input.itineraryId,
          deviceClass: input.deviceClass,
          bookingId: input.bookingId,
          isCCNumberMasked: input.isCCNumberMasked,
        },
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
