import type { PropsWithChildren } from 'react'
import type { ModalProps } from './types'
import { CovidInformationModalLayout } from './layout'

export function CovidInformationModal({
  isOpen,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) {
  return (
    <CovidInformationModalLayout isOpen={isOpen} onClose={onClose}>
      {children}
    </CovidInformationModalLayout>
  )
}
