import { Action, Inject } from '@etta/di'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { UserProfileStore } from '@etta/modules/user/interface/stores/user-profile.store'
import { UserProfileValidatorBuilder } from '@etta/modules/user/interface/services/user-profile-validator-builder'
import type { EmployeeInformationInput } from '../stores/employee-information.store'
import { EmployeeInformationStore } from '../stores/employee-information.store'
import { BaseSettingsService } from '../services/base.service'
import { BaseSettingsStore } from '../stores/base.store'
import { ProfileActions } from './profile.actions'

@Action()
export class EmployeeInformationActions {
  constructor(
    @Inject()
    private readonly employeeInformationStore: EmployeeInformationStore,
    @Inject()
    private readonly baseSettingsService: BaseSettingsService,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly profileActions: ProfileActions,
    @Inject()
    private readonly userProfileStore: UserProfileStore,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  handleOpenEmployeeInformationModal() {
    this.employeeInformationStore.setIsSaveButtonDisabled(true)
    this.setEmployeeInformation()
    this.employeeInformationStore.toggle.handleOpen()
  }

  handleCloseEmployeeInformationModal() {
    this.employeeInformationStore.setIsSaveButtonDisabled(true)
    this.baseSettingsStore.dropState(this.employeeInformationStore.toggle.handleClose)
  }

  private setEmployeeInformation() {
    const { employee } = this.profileActions.getProfile()
    this.employeeInformationStore.setEmployeeInformation(employee)

    const additionalInformation =
      this.userProfileStore.userProfile?.employeeInformation.additionalInformation ?? []

    const validator = UserProfileValidatorBuilder.build(additionalInformation)
    this.employeeInformationStore.setAdditionalInfoValidator(validator)
  }

  handleFieldChange<T extends keyof Omit<EmployeeInformationInput, 'approvers' | 'mis'>>(field: T) {
    return (value: EmployeeInformationInput[T]) => {
      this.employeeInformationStore.setIsSaveButtonDisabled(false)
      return this.employeeInformationStore.setEmployeeInformationValue(field, value)
    }
  }

  handleAdditionalFieldChange(key: string) {
    return (value: string | boolean | Date | null | undefined) => {
      this.employeeInformationStore.setIsSaveButtonDisabled(false)
      return this.employeeInformationStore.setAdditionalInfoValue(key, value)
    }
  }

  handleApproversChange<T extends keyof EmployeeInformationInput['approvers']>(field: T) {
    return (value: EmployeeInformationInput['approvers'][T]) => {
      this.employeeInformationStore.setIsSaveButtonDisabled(false)
      return this.employeeInformationStore.setEmployeeInformationApprover(field, value)
    }
  }

  handleMisChange<T extends keyof EmployeeInformationInput['mis']>(field: T) {
    return (value: EmployeeInformationInput['mis'][T]) => {
      this.employeeInformationStore.setIsSaveButtonDisabled(false)
      return this.employeeInformationStore.setEmployeeInformationMis(field, value)
    }
  }

  async handleEmployeeInformationSave() {
    const validatorCheck = await this.employeeInformationStore.employeeInformationValidator.submit()

    const additionalValidatorCheck = await this.employeeInformationStore.additionalInformationValidator.submit()
    if (!validatorCheck.isValid || !additionalValidatorCheck.isValid) {
      return
    }

    if (!validatorCheck.isValid) {
      return
    }

    const {
      values: {
        isVip,
        isActiveInCompany,
        employeeType,
        employeeId,
        jobTitle,
        jobLevel,
        managerId,
        costCenter,
        departmentCode,
        departmentName,
        division,
        businessUnit,
        companyName,
        expenseApproverId,
        purchaseApproverId,
        travelApproverId,
        mis1,
        mis2,
        mis3,
        mis4,
        mis5,
        mis6,
        mis7,
      },
    } = this.employeeInformationStore.employeeInformationValidator

    const input = {
      isVip,
      isActiveInCompany,
      employeeType,
      employeeId,
      jobTitle,
      jobLevel,
      managerId,
      costCenter,
      departmentCode,
      departmentName,
      division,
      businessUnit,
      companyName,
      approvers: {
        expenseApproverId,
        purchaseApproverId,
        travelApproverId,
      },
      mis: {
        mis1,
        mis2,
        mis3,
        mis4,
        mis5,
        mis6,
        mis7,
      },
    }

    const updateAdditionalInformationSuccess: boolean = await this.userProfileService.saveUserProfileEmployeeInformation(
      this.employeeInformationStore.additionalInformationValidator.values,
    )

    if (!updateAdditionalInformationSuccess) {
      this.baseSettingsStore.setErrorState()
      return
    }

    this.baseSettingsService.saveUser({
      mode: 'employee-information',
      input,
      handleClose: () => {
        this.employeeInformationStore.setIsSaveButtonDisabled(true)
        this.employeeInformationStore.toggle.handleClose()
      },
    })

    // refetch saved additional info
    this.userProfileService.fetchUserProfile()
  }
}
