import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { ValueType } from '@etta/ui/drop-down-list'
import { prepareFilters } from '../prepare-filters'

const i18nBase = 'CarRentalFilters.SectionTitles'

export function useTransmissionFilter() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isCarRentalsNewFiltersEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()

  const {
    carRentalFilterActions,
    carRentalSearchStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const handleChangeTransmission = (values: ValueType[]) => {
    carRentalFilterActions.handlers.setTransmission(
      values?.map((v: ValueType) => v?.toString() || '').filter(Boolean),
    )
    carRentalFilterActions.handleApplyTransmissionFilters()
  }

  const transmission = prepareFilters(
    filterOptions.transmission,
    carFiltersStore.selectedFilters.transmission,
  )

  const label = t(`${i18nBase}.Transmission`)

  const transmissionValue = useMemo(() => {
    const filterValues = []
    for (const filterOption of carRentalSearchStore.filterOptions.transmission) {
      if (carFiltersStore.appliedFilters.transmission.includes(filterOption.id)) {
        filterValues.push(filterOption.title)
      }
    }
    if (!filterValues.length) {
      return ''
    }
    return filterValues.join(', ')
  }, [carFiltersStore.appliedFilters.transmission, carRentalSearchStore.filterOptions.transmission])

  const isClearDisabled = useMemo(() => {
    return carFiltersStore.isEqual && !carFiltersStore.selectedFilters.transmission.length
  }, [carFiltersStore.isEqual, carFiltersStore.selectedFilters.transmission])

  return {
    isCarRentalsNewFiltersEnabled,
    handleChangeTransmission,
    transmission,
    carFiltersStore,
    carRentalFilterActions,
    label,
    carRentalSearchStore,
    transmissionValue,
    isClearDisabled,
  }
}
