import { Screen } from '@etta/ui/screen'
import type { LayoutProps } from '../../types'

export function ProfileMobile({ headerSlot, contentSlot }: LayoutProps) {
  return (
    <Screen backgroundColor="background">
      <Screen.Header as={'header'} backgroundColor="background">
        {headerSlot}
      </Screen.Header>
      <Screen.Container as={'main'}>{contentSlot}</Screen.Container>
    </Screen>
  )
}
