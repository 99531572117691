import styled from 'styled-components'
import { title1, subHeadMedium, captionMedium, screenSize } from '@fiji/style'

export const Title = styled.div`
  padding: 30px 20px 13px;
  color: ${(p) => p.theme.colors.white};
  ${title1}
`

export const SubTitle = styled.div`
  padding: 0px 20px 16px;
  color: ${(p) => p.theme.colors.borderDark};
  ${captionMedium}
`

export const DescriptionContainer = styled.div`
  padding-bottom: 50px;
`

export const DescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 18px 20px 0 30px;
`

export const DescriptionText = styled.div<{ showSeparator: boolean }>`
  border-bottom: ${(props) =>
    props.showSeparator ? `1px solid rgba(225, 230, 234, 0.1)` : 'none'};
  padding-bottom: ${({ showSeparator }) => (showSeparator ? `12px` : `0`)};
  color: ${(p) => p.theme.colors.borderLight};
  ${subHeadMedium}
`

export const IconContainer = styled.div`
  margin-right: 12px;
`

export const IconWrapper = styled.span`
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 100%;
  position: fixed;
  z-index: 20;
  margin: 13px;
`

export const Footer = styled.div`
  bottom: 0px;
  position: absolute;
  width: 100%;
`

export const BackgroundImage = styled.div<{ name: string | null }>`
  min-height: 157px;
  @media ${screenSize.isDesktopSm}, ${screenSize.isDesktopMd}, ${screenSize.isDesktopLg} {
    min-height: 230px;
  }
  background-image: url(${(props) => props.name});
  background-position: center center;
  background-size: cover;
`

export const ContinueButtonWrapper = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.mainText1};
  padding: 16px 20px 40px 20px;
`

export const TextIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ScrollContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 44%;
  width: 100%;
  z-index: 1;
`

export const RideHailNoFlightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 65%;
`
