import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { CarSearchFormStore } from './stores/car-search-form.store'
import { CarSearchFormActions } from './actions/car-search-form.action'
import { CarSearchButtonActions } from './actions/car-search-button.action'

export const { useModuleContext: useCarRentalSearchFormContext } = hookContextFactory({
  carSearchFormStore: CarSearchFormStore,
  carSearchFormActions: CarSearchFormActions,
  carSearchButtonActions: CarSearchButtonActions,
})
