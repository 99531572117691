import type { EmissionsDeviationLevel } from '@fiji/enums'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import {
  SegmentContainer,
  SegmentLeftBlock,
  SegmentRightBlock,
  SegmentRightBlockTitle,
  SegmentDeviationDescriptionContainer,
} from '../rtp-eco-check-modal-mobile-styled'
import { RtpEcoCheckModalHotelDeviationDescription } from '../../common/rtp-eco-check-modal-hotel-deviation-description'

type Props = {
  title: string | React.ReactNode
  emissionsKg: number
  deviationLevel: EmissionsDeviationLevel
  withDeviationLevel?: boolean
  isHotelOnly: boolean
  sustainabilityScore: number
}

export function RtpEcoCheckModalHotelSegment({
  title,
  emissionsKg,
  deviationLevel,
  sustainabilityScore,
  isHotelOnly,
}: Props) {
  return (
    <SegmentContainer>
      <SegmentLeftBlock>
        <SegmentRightBlockTitle>{title}</SegmentRightBlockTitle>
        <SegmentDeviationDescriptionContainer>
          <RtpEcoCheckModalHotelDeviationDescription
            deviationLevel={deviationLevel}
            isHotelOnly={isHotelOnly}
            sustainabilityScore={sustainabilityScore}
          />
        </SegmentDeviationDescriptionContainer>
      </SegmentLeftBlock>
      <SegmentRightBlock aria-label={emissionsKg + ' Kg CO2'}>
        <KgOfCo2 kgOfEmissions={emissionsKg} />
      </SegmentRightBlock>
    </SegmentContainer>
  )
}
