import { Skeleton } from '@etta/ui/skeleton'
import { Container, LeftBlock, RightBlock, Body, Footer } from './hotel-room-skeleton-mobile-styled'

export const HotelRoomSkeletonMobile = (
  <Container>
    <Body>
      <LeftBlock>
        <Skeleton width="146px" height="122px">
          <rect width="100%" height="22px" />
          <rect width="109px" y="32px" height="11px" />
        </Skeleton>
      </LeftBlock>
      <RightBlock>
        <Skeleton width="57px" height="122px">
          <rect width="100%" height="22px" />
        </Skeleton>
      </RightBlock>
    </Body>
    <Footer>
      <Skeleton width="97px" height="22px">
        <rect width="100%" height="22px" />
      </Skeleton>
    </Footer>
  </Container>
)
