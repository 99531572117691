import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const ItemWrapper = styled(Block)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${(p) => p.theme.colors.background};
  padding: 20px;
  margin-bottom: 1px;

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }
`

export const IconWrapper = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  background: ${(p) => p.theme.colors.white};
  border-radius: 20px;
`

export const TextBlock = styled(Block)`
  align-self: center;
`
