import type { RefObject } from 'react'
import { useEffect, useRef, useState, useCallback } from 'react'
import { SlidingLineStyled } from './sliding-line-styled'

type Props = {
  rect?: DOMRect
  isVisible: boolean
  element: RefObject<HTMLElement>
  elementLength: number
}

const LINE_PADDING = 8
const TITLE_HEIGHT_WITHOUT_LINE_HEIGHT = 55 - 3
const LINE_BOTTOM_PADDING = 2

let lastStyleChangeTime = 0
const minThrottleDelay = 10

function shouldStylesChange() {
  const now = new Date().getTime()
  if (lastStyleChangeTime + minThrottleDelay > now) {
    return false
  }

  lastStyleChangeTime = now

  return true
}

function computeSlidingPosition(rect: DOMRect, initialAnchorRect: DOMRect | null) {
  const { top, height, left, right } = rect
  const { top: initialAnchorTop } = initialAnchorRect ?? { top: 0 }

  const res = top - initialAnchorTop + height - LINE_BOTTOM_PADDING
  return {
    top: res,
    left,
    width: right - left + LINE_PADDING,
  }
}

export function SlidingLine({ element, isVisible, elementLength }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [isInitialized, setInitialized] = useState(false)
  const [initialAnchorRect, setInitialAnchorRect] = useState<DOMRect | null>(null)

  useEffect(() => {
    setTimeout(() => {
      setInitialized(true)
    }, 350)
  })

  const handleSlideCoordsChange = useCallback(() => {
    if (!shouldStylesChange() || !isVisible || !elementLength || !ref.current) {
      return
    }

    const rect = element.current?.getBoundingClientRect()

    if (!rect) {
      return
    }

    if (!initialAnchorRect) {
      const anchor = document.querySelector('[data-role="page-container"] > div > div')
      const anchorRect = anchor?.getBoundingClientRect()
      setInitialAnchorRect(anchorRect ?? null)
    }

    const { top, left, width } = computeSlidingPosition(rect, initialAnchorRect)

    ref.current.style.width = `${width}px`

    if (isInitialized) {
      ref.current.style.position = 'fixed'
      ref.current.style.top = `${top}px`
      ref.current.style.left = `${left}px`
    } else {
      ref.current.style.position = 'absolute'
      ref.current.style.top = `${TITLE_HEIGHT_WITHOUT_LINE_HEIGHT}px`
    }
  }, [element, elementLength, isVisible, initialAnchorRect, isInitialized])

  useEffect(() => {
    const scrollableParent = document.querySelector('[data-role="page-container"] > div')
    handleSlideCoordsChange()

    window.addEventListener('resize', handleSlideCoordsChange)
    scrollableParent?.addEventListener('scroll', handleSlideCoordsChange)
    return () => {
      window.removeEventListener('resize', handleSlideCoordsChange)
      scrollableParent?.removeEventListener('scroll', handleSlideCoordsChange)
    }
  }, [handleSlideCoordsChange])

  if (!isVisible) {
    return null
  }

  return <SlidingLineStyled ref={ref} isVisible={isInitialized} />
}
