import { useTranslation } from 'react-i18next'
import type { HotelEmission } from '@fiji/types'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { RTPItineraryCard } from '@etta/components/rtp-itinerary-card/rtp-itinerary-card'
import type { HotelSegmentEntity } from '@etta/modules/review-trip/core'
import { HotelDetailsModal } from '../../../hotel-details-modal'
import { useHotelSegmentRtp } from './use-hotel-segment-rtp'

type Props = {
  itineraryId: string
  roomKey: string
  segment: HotelSegmentEntity
  onOpenOOP: () => void
  outOfPolicyExp?: string | null
  isCheckoutActivated?: boolean
  isChangeAllowed: boolean
  isRemoveAllowed: boolean
  isTripExist: boolean
  hotelEmission: HotelEmission
  isMismatched: boolean
}

const i18nPathAccessability = 'Accessibility.ReviewTripPage'

export const HotelSegmentRTP = ({
  segment,
  onOpenOOP,
  outOfPolicyExp,
  isCheckoutActivated,
  isChangeAllowed,
  itineraryId,
  roomKey,
  isTripExist,
  isRemoveAllowed,
  hotelEmission,
  isMismatched,
}: Props) => {
  const { t } = useTranslation()
  const i18Base = 'HotelDetailsModal.'
  const {
    hotelDetails,
    hotelDetailsToggle,
    hotel,
    checkIn,
    checkOut,
    londDateLabel,
    numberOfNights,
    changeHotelModalToggle,
    handleChangeHotelLink,
    handleChangeRoom,
    handleConfirmRemoveHotel,
    handleCloseModal,
    cardStatus,
    removalDialogToggle,
    removalViewState,
    removalTitle,
    cardActions,
    shouldShowServiceFeeLabel,
  } = useHotelSegmentRtp({
    segment,
    isChangeAllowed,
    itineraryId,
    isTripExist,
  })

  return (
    <>
      <RTPItineraryCard
        isInvalid={isMismatched}
        isWarningIcon={isMismatched}
        actions={cardActions}
        key={segment.uuid}
        data-tracking-id={`hotel-rtp-card_${segment.type}-${segment.position}`}
        cardStatus={cardStatus}
        serviceType={segment.type}
        isOutOfPolicy={!hotel.policy.isInPolicy}
        hasComplianceCode={!!hotel.policy.outOfPolicyComplianceCodes?.length}
        startDate={checkIn}
        fullStartDate={new Date(segment.checkIn)}
        endDate={checkOut}
        longDateLabel={londDateLabel}
        rate={hotel.totalCostRate}
        titleSlot={hotel.name}
        informationSlot={
          <RTPItineraryCard.Text aria-label={hotel.address.city}>
            {hotel.address.city}
          </RTPItineraryCard.Text>
        }
        onClick={hotelDetailsToggle.handleOpen}
        onSelectReason={onOpenOOP}
        isCheckoutActivated={isCheckoutActivated}
        outOfPolicyExp={outOfPolicyExp}
        bodyAriaLabel={t(`${i18nPathAccessability}.HotelCard`, { hotel: hotel.name })}
        changeLinkText={t('ReviewTripPWA.HotelCard.ChangeLink')}
        onDelete={isRemoveAllowed ? removalDialogToggle.handleOpen : undefined}
        onChange={handleChangeHotelLink}>
        <RTPItineraryCard.Text aria-label={hotel.roomType}>{hotel.roomType}</RTPItineraryCard.Text>
        <RTPItineraryCard.Text
          aria-label={t('ReviewTripPWA.Details.NightsLabel', { count: numberOfNights })}>
          {t('ReviewTripPWA.Details.Nights', { count: numberOfNights })}
        </RTPItineraryCard.Text>
        {shouldShowServiceFeeLabel && (
          <RTPItineraryCard.Text aria-label={t('ReviewTripPWA.Details.ServiceFee')}>
            {t('ReviewTripPWA.Details.ServiceFee')}
          </RTPItineraryCard.Text>
        )}
      </RTPItineraryCard>
      <HotelDetailsModal
        itineraryId={itineraryId}
        roomKey={roomKey}
        hotelDetails={hotelDetails}
        isVisible={hotelDetailsToggle.isOpen}
        onClose={hotelDetailsToggle.handleClose}
        isTripExist={isTripExist}
        hotelEmission={hotelEmission}
      />
      <ConfirmationDialog
        isOpen={removalDialogToggle.isOpen}
        onDecline={removalDialogToggle.handleClose}
        onConfirm={handleConfirmRemoveHotel}
        title={t(`${i18Base}RemoveHotel`)}
        content={t(`${i18Base}RemoveHotelExplanation`)}
        confirmButtonText={t(`${i18Base}RemoveConfirmButton`)}
        declineButtonText={t(`${i18Base}RemoveDeclineButton`)}
      />
      <ConfirmationDialog
        title={t(`${i18Base}ChangeHotel.Title`)}
        content={t(`${i18Base}ChangeHotel.InfoText`)}
        isOpen={changeHotelModalToggle.isOpen}
        onDecline={handleCloseModal}
        onConfirm={handleChangeRoom}
        confirmButtonText={t(`${i18Base}ChangeHotel.ConfirmButtonText`)}
        declineButtonText={t(`${i18Base}ChangeHotel.DeclineButtonText`)}
      />
      <ViewStateModal viewState={removalViewState} title={removalTitle} />
    </>
  )
}
