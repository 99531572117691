import styled from 'styled-components'
import { headline } from '@fiji/style'

export const Container = styled.div`
  min-height: 196px;
  display: flex;
  background-color: ${(p) => p.theme.colors.white};
`

export const Content = styled.div`
  padding: 15px 15px 15px 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex: 0.6;
  position: relative;
  width: 263px;
  height: 181px;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  margin: 10px;
`

export const HotelTitle = styled.div`
  ${headline};
  color: ${(p) => p.theme.colors.mainText};
  margin-bottom: 8px;
  margin-top: 4px;
`

export const AmenityContainer = styled.div`
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: end;
  -webkit-box-align: end; /* align-items */
`

export const PriceWrapper = styled.div`
  margin-left: auto;
`

export const SustainabilityContainer = styled.div`
  margin-top: 8px;
`
