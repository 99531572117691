import { observer } from 'mobx-react-lite'
import { RailSearchSegment } from '@etta/components/rail-search-segment/rail-search-segment'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { RailLegType } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { RailLegDateHeader } from '../rail-leg-date-header/rail-leg-date-header'

export type Props = {
  railLegType: RailLegType
}

export const RailResultList = observer(function RailResultList({ railLegType }: Props) {
  const { railActions, railQueryParamStore, railStore } = useRailContext()
  const { railPolicies, railLegs } = railStore
  const { originPlace, destinationPlace } = railQueryParamStore

  return (
    <>
      {railLegs?.map((leg) => (
        <Block>
          {leg.showDepartureDate && (
            <RailLegDateHeader railLegType={railLegType} legDepartureDate={leg.departureDate} />
          )}
          <RailSearchSegment
            key={leg.legId}
            origin={railLegType === RailLegType.Departure ? originPlace : destinationPlace}
            destination={railLegType === RailLegType.Departure ? destinationPlace : originPlace}
            segment={leg}
            onSelect={(activeTab) => {
              railActions.appendQueryParams({
                activeLegId: leg.legId,
              })
              railActions.handleRailDetailsOpen(leg.legId)
              railActions.handleTrainServiceActiveTab(activeTab)
            }}
            railPolicies={railPolicies}
            railLegType={railLegType}
          />
        </Block>
      ))}
    </>
  )
})
