import styled from 'styled-components'
import { Radio } from '@etta/ui/radio'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'
import { UnstyledButton, captionStrongCaps, footnoteMedium } from '@fiji/style'

export const Container = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 14px 0 0;
  position: relative;
  background: ${(p) => (p.isError ? p.theme.colors.error : p.theme.colors.white)};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 24px;
  background: ${(p) => (p.isError ? hexToRgba(p.theme.colors.error, 0.04) : p.theme.colors.white)};
  border: 2px solid ${(p) => (p.isError ? p.theme.colors.error : p.theme.colors.white)};
`

export const Header = styled.div<{ isError?: boolean }>`
  ${captionStrongCaps};
  text-align: left;
  padding: 0 0 12px 14px;
  color: ${(p) => p.theme.colors.bodyText};
  border-bottom: 1px solid
    ${(p) => (p.isError ? p.theme.colors.borderDark : p.theme.colors.borderLight)};
`

export const DataContent = styled.div`
  padding: 16px 10px 3px;
`

export const ContentRadio = styled(Radio)<{ isError?: boolean }>`
  align-items: flex-start;
  :not(:first-child) {
    padding-top: 14px;
    border-top: 1px solid
      ${(p) => (p.isError ? p.theme.colors.borderDark : p.theme.colors.borderLight)};
  }
`

export const ErrorMessage = styled.div`
  ${footnoteMedium};
  display: flex;
  text-align: left;
  padding-bottom: 3px;
`
export const ErrorContainer = styled.div`
  padding: 12px 15px;
`

export const AddNewPassport = styled(UnstyledButton)`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 16px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
`

export const NumberBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 24px;
  height: 24px;
  border-radius: 6px;
`
