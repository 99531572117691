import { useTranslation } from 'react-i18next'
import { RailLayoutType } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { Spinner } from '@etta/ui/spinner'
import { Text } from '@etta/ui/text'
import { ModalAnimation } from '@etta/ui/modal-animation'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import type { RailCardModalProps } from '../../types'
import { RailCardModalContent } from '../../rail-card-modal-content/rail-card-modal-content'
import { Container, Header } from './rail-card-modal-desktop-styled'

const i18Base = 'TripPlanner.BaseSearch.RailCard'

export function RailCardModalDesktop({
  savedCards,
  cardGroups,
  onClose,
  onSelectUKCard,
  onSelectEUCard,
  layout,
  cardsLoading,
  errorText,
  isOpen,
}: RailCardModalProps) {
  const { t } = useTranslation()
  const { anchorRef, scrollContainerRef } = useHeaderScroll()
  const { ref } = useClickOutside(onClose)
  useShouldOpenInViewport({ isVisible: isOpen, ref })

  const title =
    layout === RailLayoutType.UK ? t(i18Base + '.RailcardsModalTitle') : t(i18Base + '.Cards')

  return (
    <ModalAnimation isOpen={isOpen}>
      <Container ref={ref} data-tracking-id={'rail-cards-modal-desktop'}>
        <Header>
          <Text variant="headline" color="mainText">
            {title}
          </Text>
        </Header>
        <Block ref={scrollContainerRef}>
          {cardsLoading && (
            <Block marginHorizontal={20} marginVertical={20}>
              <Spinner type="spinout" />
            </Block>
          )}
          {!cardsLoading && errorText && (
            <Block marginHorizontal={20} marginVertical={20}>
              <Text variant="captionStrong" color="primary">
                {errorText}
              </Text>
            </Block>
          )}
          {!cardsLoading && !errorText && (
            <RailCardModalContent
              savedCards={savedCards}
              cardGroups={cardGroups}
              onClose={onClose}
              onSelectUKCard={onSelectUKCard}
              onSelectEUCard={onSelectEUCard}
              layout={layout}
              anchorRef={anchorRef}
            />
          )}
        </Block>
      </Container>
    </ModalAnimation>
  )
}
