import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { InputMobile } from './mobile'
import { InputDesktop } from './desktop'

export function Input(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<InputMobile {...props} />}
      desktopSlot={<InputDesktop {...props} />}
    />
  )
}
