import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { DBAContainer } from './travel-fusion-links-content-styled'

export function DirectBookingAcknowledgment() {
  const { t } = useTranslation()
  const i18nBase = 'TravelFusionLinks.DirectBookingAcknowledgment.'

  return (
    <DBAContainer>
      <Block marginRight={8}>
        <Icon name="directBookingIcon" size={20} />
      </Block>
      <div>
        <Text variant="footnoteStrong" color="mainText">
          {t(i18nBase + 'Title')}
        </Text>
        <Block marginTop={6}>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'Description1')}
          </Text>
        </Block>
        <Block marginTop={15}>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'Description2')}
          </Text>
        </Block>
        <Block marginTop={16} isFlexBlock={true} flexGap={10}>
          <Icon name="creditCardPWA" color="bodyText" size={20} />
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'ReceiveCreditCardInfo')}
          </Text>
        </Block>
        <Block marginTop={5} isFlexBlock={true} flexGap={10}>
          <Icon name="refundablePWA" color="bodyText" size={20} />
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'ChargeCreditCardInstantly')}
          </Text>
        </Block>
        <Block marginTop={5} isFlexBlock={true} flexGap={10}>
          <Icon name="eticketPWA" color="bodyText" size={20} />
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'IssueTicket')}
          </Text>
        </Block>
      </div>
    </DBAContainer>
  )
}
