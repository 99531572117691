import { MediaLayout } from '@etta/ui/media-layout'
import type { Document } from '@etta/modules/booking/interface/checkout-info/documents/checkout-info-documents.store'
import { TravelerDocumentsDesktop } from './desktop'
import { TravelerDocumentsMobile } from './mobile'

export type Props = {
  isOpen: boolean
  title: string
  onClose: () => void
  documents: Document[]
}

export function TravelerDocumentsLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TravelerDocumentsMobile {...props} />}
      desktopSlot={<TravelerDocumentsDesktop {...props} />}
    />
  )
}
