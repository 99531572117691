import styled, { css } from 'styled-components'
import { captionMedium } from '@fiji/style'

type MarksContentProps = {
  isFirstMark?: boolean
  isLastMark?: boolean
  isMarksAligment?: boolean
}

export type MarkStyledProps = {
  isFilled?: boolean
  isDisabled?: boolean
  style: object
  className: string
  key: number
}

export const MarksContent = styled.div<MarksContentProps>`
  position: relative;
  min-width: 55px;
  text-align: center;
  ${(props) =>
    props.isMarksAligment &&
    props.isFirstMark &&
    css`
      left: 20px;
      text-align: left;
    `}
  ${(props) =>
    props.isMarksAligment &&
    props.isLastMark &&
    css`
      right: 20px;
      text-align: right;
    `}
`

export const MarkStyled = styled.div<MarkStyledProps>`
  position: absolute;
  width: 1px;
  height: 8px;
  top: 50%;
  margin-left: 0;
  margin-top: 15px;
  display: flex;
  justify-content: center;

  color: ${(props) =>
    props.isDisabled ? props.theme.colors.bodyText2 : props.theme.colors.bodyText};

  ${captionMedium};

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 0;
    height: 8px;
    background-color: ${(props) => props.theme.colors.borderDark};
    border: 1px solid ${(props) => props.theme.colors.borderDark};
    border-radius: 3px;

    ${(props) => {
      return (
        props.isFilled &&
        css`
          background-color: ${(props) => props.theme.colors.primary};
          border-color: ${(props) => props.theme.colors.primary};
        `
      )
    }}
  }
`
