import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const Description = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'footnoteMedium',
  color: 'bodyText',
  isBlock: true,
}))``
