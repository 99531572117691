import type {
  CalculateFlightEmissionsBatchFlightSegmentInput,
  CalculateFlightEmissionsBatchFlightInput,
  SearchFlightLeg,
} from '@fiji/graphql/hooks'
import { SeatmapCabinClass, CalculateEmissionsFlightSegmentTravelClass } from '@fiji/graphql/hooks'
import type { CabinClassType } from '@fiji/types'

export function mapToThrustCarbonFlight(
  flight: SearchFlightLeg,
  cabinClass?: CabinClassType,
): CalculateFlightEmissionsBatchFlightInput {
  return {
    customRef: flight.legId,
    segments: mapToThrustCarbonFlightSegments(flight, cabinClass),
  }
}

export function mapToThrustCarbonFlightSegments(
  flight: SearchFlightLeg,
  cabinClass?: CabinClassType,
): CalculateFlightEmissionsBatchFlightSegmentInput[] {
  return flight.segments.map((segment) => {
    return {
      aircraftType: segment.aircraft?.code,
      carrier: segment.codeshareCarrier.code || segment.carrier?.id,
      class: mapToThrustCarbonCabinClass(cabinClass?.id),
      departureDateTime: segment.departureDate,
      flightNumber: segment.flightNumber,
      originAirportCode: segment.origin.code,
      destinationAirportCode: segment.destination.code,
    }
  })
}

export function mapToThrustCarbonCabinClass(
  cabinClass?: SeatmapCabinClass,
): CalculateEmissionsFlightSegmentTravelClass {
  switch (cabinClass) {
    case SeatmapCabinClass.Coach:
      return CalculateEmissionsFlightSegmentTravelClass.Economy
    case SeatmapCabinClass.PremiumCoach:
      return CalculateEmissionsFlightSegmentTravelClass.Premium
    case SeatmapCabinClass.Business:
      return CalculateEmissionsFlightSegmentTravelClass.Business
    case SeatmapCabinClass.First:
      return CalculateEmissionsFlightSegmentTravelClass.First
    default:
      return CalculateEmissionsFlightSegmentTravelClass.Economy
  }
}
