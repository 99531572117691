import { useTranslation } from 'react-i18next'
import { RTPItineraryCard } from '@etta/components/rtp-itinerary-card/rtp-itinerary-card'
import { RailDetailsModal } from '@etta/screens/review-trip-page/rail-details-modal'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { RailEmission } from '@fiji/types'
import { TripReviewSeatPreferences } from '@etta/screens/trip-review-seat-preferences/trip-review-seat-preferences'
import { RouteType } from '@fiji/graphql/types'
import { RailDetailsTicketRestrictionsModal } from '@etta/screens/review-trip-page/rail-details-ticket-restrictions-modal'
import type { RailOpenReturnDetailsProps } from '@etta/components/rail-open-return-details/types'
import { useRailSegmentRtp } from './use-rail-segment-rtp'

type Props = {
  itineraryId: string
  isTripExist: boolean
  isChangeAllowed: boolean
  segment: TrainSegmentEntity
  onOpenOOP: () => void
  railEmission: RailEmission
  isRoundTrip: boolean
}

const i18nBase = 'RailDetailsModal.'

export const RailSegmentRTP = ({
  isTripExist,
  isChangeAllowed,
  segment,
  onOpenOOP,
  itineraryId,
  railEmission,
  isRoundTrip,
}: Props) => {
  const { t } = useTranslation()
  const {
    arrival,
    cardActions,
    seatReservationType,
    departure,
    destinationTime,
    handleChangeRail,
    handleChangeRailLink,
    handleCloseModal,
    handleRemoveRail,
    informationText,
    isChangeModalOpen,
    originTime,
    railDetailsToggle,
    ticketRestrictionsDetailsToggle,
    railLeg,
    rate,
    refundableText,
    removalDialogToggle,
    viewStateParams,
    serviceClassName,
    ticketInformationText,
    travelDate,
    updatePreferencesViewState,
    railSegmentsLength,
    isSeatRequested,
    appliedPreferences,
    onPreferencesApply,
    seatPreferencesToggle,
    temporaryCurrentLayout,
    preferenceOptions,
    isRailPreferenceOptionsExist,
    isFirstClass,
    seatReservationTypesArray,
    changesTitle,
    selectedSeatsLabel,
    onSkip,
  } = useRailSegmentRtp({
    isTripExist,
    isChangeAllowed,
    segment,
    itineraryId,
  })

  const isOpenReturn = RouteType.OpenReturn === railLeg.routeType
  const appliedRailCard = segment.appliedRailCards.length ? segment.appliedRailCards[0] : ''
  return (
    <>
      <RTPItineraryCard
        key={segment.uuid}
        data-tracking-id={`rail-rtp-card_${segment.type}-${segment.position}`}
        serviceType={segment.type}
        isOutOfPolicy={!railLeg.policy.isInPolicy}
        hasComplianceCode={!!railLeg.policy.outOfPolicyComplianceCodes?.length}
        startDate={travelDate}
        fullStartDate={new Date(segment.departureDateTime)}
        rate={rate}
        openReturnProperties={
          isOpenReturn
            ? ({
                serviceClassName,
                ticketInformationText,
                refundableText,
                onViewRestrictionsClick: ticketRestrictionsDetailsToggle.handleOpen,
              } as RailOpenReturnDetailsProps)
            : null
        }
        titleSlot={
          <RTPItineraryCard.RailTitle originTime={originTime} destinationTime={destinationTime} />
        }
        strongTextSlot={t(i18nBase + 'FaresDetails.FromToStations', {
          from: departure.stationName,
          to: arrival.stationName,
        })}
        informationSlot={
          <>
            {informationText && (
              <RTPItineraryCard.Text textTransform="none">{informationText}</RTPItineraryCard.Text>
            )}
            {changesTitle && (
              <RTPItineraryCard.Text textTransform="none">{changesTitle}</RTPItineraryCard.Text>
            )}
            <RTPItineraryCard.Text textTransform="none">
              {isFirstClass
                ? t(i18nBase + 'FaresDetails.FirstClass')
                : t(i18nBase + 'FaresDetails.Standard')}
            </RTPItineraryCard.Text>
          </>
        }
        seatPreferencesSlot={
          <RTPItineraryCard.Text textTransform="none">{selectedSeatsLabel}</RTPItineraryCard.Text>
        }
        children={
          <>
            {serviceClassName && <RTPItineraryCard.Text>{serviceClassName}</RTPItineraryCard.Text>}
            {ticketInformationText && (
              <RTPItineraryCard.Text textTransform="none">
                {ticketInformationText}
              </RTPItineraryCard.Text>
            )}
            <RTPItineraryCard.Text>
              {t(`ReviewTripPWA.Details.PenaltyType.${refundableText}`)}
            </RTPItineraryCard.Text>
          </>
        }
        onClick={railDetailsToggle.handleOpen}
        onSelectReason={onOpenOOP}
        changeLinkText={t('ReviewTripPWA.RailCard.ChangeLink')}
        onChange={handleChangeRailLink}
        onDelete={removalDialogToggle.handleOpen}
        railActions={cardActions}
        appliedRailCard={appliedRailCard}
        isRailOpenReturn={isOpenReturn}
      />
      <RailDetailsModal
        itineraryId={itineraryId}
        isTripExist={isTripExist}
        isChangeAllowed={isChangeAllowed}
        isOpen={railDetailsToggle.isOpen}
        onClose={railDetailsToggle.handleClose}
        railLeg={railLeg}
        railSegmentsLength={railSegmentsLength}
        seatReservationType={seatReservationType}
        isRailEticket={!!railLeg.isEticket}
        railEmission={railEmission}
        isSeatRequested={isSeatRequested}
        appliedRailCard={appliedRailCard}
      />
      <RailDetailsTicketRestrictionsModal
        isOpen={ticketRestrictionsDetailsToggle.isOpen}
        onClose={ticketRestrictionsDetailsToggle.handleClose}
        railLeg={railLeg}
        railSegmentsLength={railSegmentsLength}
        seatReservationType={seatReservationType}
        isRailEticket={!!railLeg.isEticket}
        isSeatRequested={isSeatRequested}
        appliedRailCard={appliedRailCard}
      />
      {isRailPreferenceOptionsExist && preferenceOptions && (
        <TripReviewSeatPreferences
          onPreferencesApply={onPreferencesApply}
          appliedPreferences={appliedPreferences}
          isVisible={seatPreferencesToggle.isOpen}
          onClose={seatPreferencesToggle.handleClose}
          layout={temporaryCurrentLayout}
          preferenceOptions={preferenceOptions}
          seatReservationTypesArray={seatReservationTypesArray}
          isRoundTrip={isRoundTrip}
          isFirstClass={isFirstClass}
          onSkip={onSkip}
        />
      )}
      <ConfirmationDialog
        title={t(i18nBase + 'ChangeTrain.Title')}
        content={t(i18nBase + 'ChangeTrain.InfoText', { count: railSegmentsLength })}
        isOpen={isChangeModalOpen}
        onDecline={handleCloseModal}
        onConfirm={handleChangeRail}
        confirmButtonText={t(i18nBase + 'ChangeTrain.ConfirmButtonText')}
        declineButtonText={t(i18nBase + 'ChangeTrain.DeclineButtonText')}
      />
      <ConfirmationDialog
        title={t(i18nBase + 'RemoveTrain')}
        content={t(i18nBase + 'RemoveTrainExplanation')}
        isOpen={removalDialogToggle.isOpen}
        onDecline={removalDialogToggle.handleClose}
        onConfirm={handleRemoveRail}
        confirmButtonText={t(i18nBase + 'RemoveConfirmButton')}
        declineButtonText={t(i18nBase + 'RemoveDeclineButton')}
      />
      <ViewStateModal
        viewState={updatePreferencesViewState.viewState}
        onDone={updatePreferencesViewState.onClose}
      />
      <ViewStateModal
        viewState={viewStateParams.viewState}
        title={viewStateParams.title}
        onDone={viewStateParams.onClose}
      />
    </>
  )
}
