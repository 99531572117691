import type { VariantValue } from './types'

const DEFAULT_VARIANT: VariantValue = {
  interval: 0,
  speed: 0.7,
  backgroundColor: '#e8e8e8',
  backgroundOpacity: 0.95,
  foregroundColor: '#d3d6da',
  foregroundOpacity: 0.65,
}

export const variants: Record<string, VariantValue> = {
  primary1: DEFAULT_VARIANT,
  secondary1: {
    ...DEFAULT_VARIANT,
    backgroundColor: '#dddfe0',
    backgroundOpacity: 0.8,
    foregroundOpacity: 0.75,
  },
}

export type SkeletonVariantKey = keyof typeof variants
