import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'

type Props = {
  hasAlertIcon: boolean
  hasDesktopBannerImage: boolean
}

export function DsmDesktopIcon({ hasAlertIcon, hasDesktopBannerImage }: Props) {
  if (hasAlertIcon) {
    return (
      <Block marginRight={16}>
        <Icon name="warningFilledPWA" color="warning" size="large" />
      </Block>
    )
  }

  if (!hasDesktopBannerImage) {
    return (
      <Block marginRight={16}>
        <Icon name="dynamicSiteMessagesPWA" color="mainText" size="large" />
      </Block>
    )
  }

  return null
}
