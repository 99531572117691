import { useEffect } from 'react'
import { usePrevious } from '@fiji/hooks/use-previous'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useCurrentRoute } from '@etta/modules/app/ui/use-current-route'
import { heapInit } from '@fiji/utils/tracking'
import { useCookieContext } from '@etta/modules/cookie'
import { usePartnerCoBrandingConfigurationsContext } from '@etta/modules/partner-cobranding-configuration'
import { useGoogleMapLoader } from './use-google-map-loader'

export function useRouteAuthorize() {
  const { cookieStore } = useCookieContext()
  const { featureFlagsAction } = useFeatureFlagsContext()
  const { partnerCoBrandingConfigurationAction } = usePartnerCoBrandingConfigurationsContext()

  const { isLoading: isGoogleMapLoading } = useGoogleMapLoader()

  const { currentRouteItem } = useCurrentRoute()
  const currentRoutePath = currentRouteItem?.route?.path

  const isRouteNotExist = !currentRoutePath
  const isLoggedIn = cookieStore.isLoggedIn()
  const isRouteShouldUseAuth = !currentRouteItem?.isSkipAuth

  const previousPath = usePrevious<string>(currentRouteItem?.route.path || '') || ''

  useEffect(() => {
    const handleLoad = () => {
      if (isLoggedIn) {
        heapInit()
        return
      }

      Promise.all([
        featureFlagsAction.loadDefaultFlags(),
        partnerCoBrandingConfigurationAction.loadPartnerCoBrandingConfigurationsBySiteName(),
      ])
    }

    handleLoad()
  }, [featureFlagsAction, isLoggedIn, partnerCoBrandingConfigurationAction])

  return {
    previousPath,
    isRouteShouldUseAuth,
    isLoggedIn,
    isRouteNotExist,
    isLoading: isGoogleMapLoading,
  }
}
