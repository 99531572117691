import { useEffect, useRef, useState } from 'react'
import { delayForSuccess } from '@fiji/utils/delay-for-success'

type Args = {
  isOpen: boolean
}

export function useScrollModalMessage({ isOpen }: Args) {
  const [anchorId, setAnchorId] = useState<string | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  function handleSetAnchor(node: HTMLDivElement | null) {
    anchorRef.current = node
  }

  function handleSetAnchorId(messageId: string | null) {
    setAnchorId(messageId)
  }

  useEffect(() => {
    async function scroll() {
      if (isOpen && scrollContainerRef.current && anchorRef.current) {
        await delayForSuccess(100)
        const anchorRect = anchorRef.current.getBoundingClientRect().top
        const containerRect = scrollContainerRef.current.getBoundingClientRect().top
        const top = anchorRect - containerRect

        scrollContainerRef.current.scroll({ top, behavior: 'smooth' })
      }
    }
    scroll()
  }, [isOpen])

  return {
    anchorId,
    scrollContainerRef,
    handleSetAnchor,
    handleSetAnchorId,
  }
}
