import type { ReactNode } from 'react'
import { getTrackIdFromTitle } from '@fiji/utils/get-tack-id-from-title'
import { RatePrice } from '@etta/components/rate-price/rate-price'
import type { Rate } from '@fiji/graphql/types'
import { Title } from '../../../titles'
import {
  Section,
  PaymentContainer,
  PaymentTitle,
  PaymentDetails,
  Rows,
} from './section-block-mobile-styled'

type Props = {
  title: string
  subTitle?: string
  children: ReactNode
  rate: Rate
  cardSlot: ReactNode
  paymentText: string
  paymentLabel?: string
}

export function SectionBlockMobile({
  title,
  subTitle,
  children,
  cardSlot,
  rate,
  paymentText,
  paymentLabel,
}: Props) {
  return (
    <Section aria-label={title}>
      <Title data-tracking-id={`purchase-success_${getTrackIdFromTitle(title.toLowerCase())}-text`}>
        {title}
      </Title>
      {subTitle && <Title data-tracking-id="block-sub-title-text">{subTitle}</Title>}
      <Rows>{children}</Rows>
      <PaymentContainer aria-label={paymentLabel}>
        <PaymentTitle data-tracking-id="payment-title-block">
          <span data-tracking-id="payment-title-text">{paymentText}</span>
          <PaymentDetails>{cardSlot}</PaymentDetails>
        </PaymentTitle>
        <RatePrice rate={rate} />
      </PaymentContainer>
    </Section>
  )
}
