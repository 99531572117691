import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/'
import type { OopSegmentSelection } from '@etta/components/out-of-policy-modal/types'
import { useOopSegments } from '@fiji/hooks/use-oop-segments'
import { getBookingOopSegments } from './get-booking-oop-segments'

type Props = {
  oopSelections: OopSegmentSelection
  segments?: TripSegmentValueObject[]
  isDescriptionRequired?: boolean
  bookingId?: string
  isOnHold?: boolean
  isHotelRequiredForOvernightTrip?: boolean
  isCarRentalRequiredViolation?: boolean
}

export function useOopValidation({
  oopSelections,
  segments = [],
  isDescriptionRequired,
  bookingId,
  isOnHold,
  isHotelRequiredForOvernightTrip,
  isCarRentalRequiredViolation,
}: Props) {
  const oopSegments = useOopSegments(segments)
  const { flights, hotels, carRentals, rails } = getBookingOopSegments({
    bookingId,
    oopSegments,
    isOnHold,
  })

  if (isHotelRequiredForOvernightTrip && !oopSelections?.hotel) {
    return { isOopResolved: false }
  }

  if (isCarRentalRequiredViolation && !oopSelections?.carRentals) {
    return { isOopResolved: false }
  }

  if (!segments?.length) {
    return { isOopResolved: true }
  }

  if (!flights.length && !hotels.length && !carRentals.length && !rails.length) {
    return { isOopResolved: true }
  }

  if (isDescriptionRequired && !oopSelections.description?.length) {
    return { isOopResolved: false }
  }

  if (
    (flights.length && !oopSelections.flight) ||
    (carRentals.length && !oopSelections.carRentals) ||
    (hotels.length && !oopSelections.hotel) ||
    (rails.length && !oopSelections.rail)
  ) {
    return { isOopResolved: false }
  }

  return { isOopResolved: true }
}
