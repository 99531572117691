import { Store } from '@etta/di'
import { TripType } from '@etta/core/enums/trip-type.enum'

type tripPreviewState = {
  title: string
  startTime: string
  endTime: string
  tripType: TripType
  cityImageUrl: string
}

const tripPreviewInitialState: tripPreviewState = {
  title: '',
  startTime: '',
  endTime: '',
  tripType: TripType.Upcoming,
  cityImageUrl: '',
}

@Store()
export class PostBookingTripPreviewStore {
  tripPreview: tripPreviewState = { ...tripPreviewInitialState }

  updateTitle(title: string) {
    this.tripPreview.title = title
  }

  updateTimes({ startTime, endTime }: { startTime: string; endTime: string }) {
    this.tripPreview.startTime = startTime
    this.tripPreview.endTime = endTime
  }

  updateTripType(tripType: TripType) {
    this.tripPreview.tripType = tripType
  }

  updateCityImageUrl(cityImageUrl: string) {
    this.tripPreview.cityImageUrl = cityImageUrl
  }

  resetTrip() {
    this.tripPreview = { ...tripPreviewInitialState }
  }
}
