import styled from 'styled-components'
import { footnoteStrong } from '@fiji/style'

export const ShowDetails = styled.div`
  margin-top: 13px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;

  ${footnoteStrong};
`
