import styled from 'styled-components'
import { footnoteStrong, title2 } from '@fiji/style'

export const TitleSection = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin: 16px 0;
`

export const TitleDate = styled.div`
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText1};
  text-transform: uppercase;
  ${footnoteStrong};
`

export const HeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Heading = styled.h2`
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  line-height: 32px;
  color: ${(props) => props.theme.colors.mainText};
  ${title2}
`
