import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { LazyContent } from '@etta/ui/lazy-content'
import { Form } from '@etta/ui/form'
import { CustomField } from '@etta/ui/custom-field'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { PurchaseAdditionalInfoFormLayout } from './layout'
import { CheckoutMultiLayerCostAllocation } from './cost-allocation'
import { BlockTitle } from './cost-allocation/cost-allocation-styled'

export const PurchaseAdditionalInfoForm = observer(function PurchaseAdditionalInfoForm() {
  const {
    values,
    errors,
    onFieldChange,
    onSubmitHandler,
    isError,
    isLoading,
    hasFormErrors,
    customFields,
    onBack,
    onReload,
    startOverModalSlot,
    isShowDelegateName,
    isHoldFlow,
    isCostAllocationExist,
  } = useCheckoutContextSelector((state) => state.additionalInfo)

  const { costAllocationStore, costAllocationActions } = useCostAllocationContext()

  useEffect(() => {
    if (isCostAllocationExist) {
      costAllocationActions.getTopLevelCostSegments()
    }
  }, [isCostAllocationExist, costAllocationActions])

  const { t } = useTranslation()

  const isButtonDisabled = isLoading || isError || hasFormErrors

  return (
    <>
      <PurchaseAdditionalInfoFormLayout
        isHoldFlow={isHoldFlow}
        onBack={onBack}
        isLoading={isLoading}
        isShowDelegateName={isShowDelegateName}
        onSubmitHandler={onSubmitHandler}
        isButtonDisabled={isButtonDisabled}
        isError={isError}
        onReload={onReload}
        contentSlot={
          <LazyContent isLoading={isLoading}>
            <Form onSubmit={onSubmitHandler}>
              {isCostAllocationExist && (
                <>
                  <BlockTitle>{t('AdditionalInfoForm.CompanyReporting')}</BlockTitle>

                  {costAllocationStore.topLevelCostSegments.map((costSegment) => (
                    <CheckoutMultiLayerCostAllocation
                      key={costSegment.id.toString()}
                      costSegmentId={costSegment.id}
                      title={costSegment.name}
                      isSplitCostAllocation={costSegment.isSplitCostAllocation}
                      isCostAllocationRequired={costSegment.isRequired}
                      isSplitCostAllocationRequired={costSegment.isSplitCostRequired}
                      onChange={onFieldChange}
                      errorAllocation={errors[`${costSegment.id}_allocation`]}
                      errorSplitAllocation={errors[`${costSegment.id}_splitCostAllocation`]}
                    />
                  ))}
                </>
              )}

              {customFields.map((field) => (
                <CustomField
                  field={field}
                  onChange={onFieldChange}
                  values={values}
                  key={field.collectionId}
                  errors={errors}
                />
              ))}
            </Form>
          </LazyContent>
        }
      />

      {startOverModalSlot}
    </>
  )
})
