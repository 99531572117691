import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { mergeDeepRight } from 'ramda'
import type { ZooKeeperConfiguration } from './types'
import { getInitialState } from './initial-state'

const slice = createSlice({
  name: 'zookeeper-configuration',
  initialState: getInitialState(),
  reducers: {
    updateZooKeeperConfiguration(
      state: ZooKeeperConfiguration,
      action: PayloadAction<ZooKeeperConfiguration>,
    ) {
      const zooKeeperConfiguration = mergeDeepRight(state, action.payload) as ZooKeeperConfiguration

      return zooKeeperConfiguration
    },
    updateZooKeeperConfigLoading(
      state: ZooKeeperConfiguration,
      action: PayloadAction<{ zooKeeperConfigLoading: boolean }>,
    ) {
      state.zooKeeperConfigLoading = action.payload.zooKeeperConfigLoading
    },
    resetZooKeeperConfiguration() {
      return getInitialState()
    },
  },
})

export const zooKeeperConfigurationReducer = slice.reducer
export const {
  updateZooKeeperConfigLoading,
  updateZooKeeperConfiguration,
  resetZooKeeperConfiguration,
} = slice.actions
