import { MediaLayout } from '@etta/ui/media-layout'
import { TravelerDocumentActionDesktop } from './desktop'
import { TravelerDocumentActionMobile } from './mobile'

export type Props = {
  isOpen: boolean
  title: string
  onClose: () => void
  onSave: () => void
  isSaveDisabled: boolean
}

export function TravelerDocumentActionLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TravelerDocumentActionMobile {...props} />}
      desktopSlot={<TravelerDocumentActionDesktop {...props} />}
    />
  )
}
