import { Action, Inject } from '@etta/di'
import { AccountInformationActions } from '@etta/modules/settings/interface/actions/account-information.actions'
import { dateFormat, isoToDate, parse } from '@fiji/utils/dates'
import { CheckoutInfoStore } from '../checkout-info.store'
import type {
  DocumentField,
  KnownTravelerNumber,
  RedressNumber,
  Document,
} from './checkout-info-documents.store'
import { DocumentType, CheckoutInfoDocumentStore } from './checkout-info-documents.store'

const apiDateFormat = 'yyyy-MM-dd'
const formDateFormat = 'MMM d y'

@Action()
export class CheckoutInfoDocumentAction {
  constructor(
    @Inject()
    private readonly checkoutInfoDocumentStore: CheckoutInfoDocumentStore,
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject()
    private readonly accountInformationActions: AccountInformationActions,
  ) {}

  getDocuments() {
    switch (this.checkoutInfoDocumentStore.documentType) {
      case DocumentType.KTN:
        return this.getKnownTravelerNumbers()
      case DocumentType.Redress:
        return this.getRedressNumbers()
      default:
        return []
    }
  }

  getKnownTravelerNumbers() {
    return this.checkoutInfoStore.primaryTraveler.travelerData?.knownTravelerNumbers ?? []
  }

  getRedressNumbers() {
    return this.checkoutInfoStore.primaryTraveler.travelerData?.redressNumbers ?? []
  }

  getDisabledCountries() {
    const documents = this.getDocuments()

    if (!documents) {
      return
    }

    return documents.map((document) => document.countryCode || '')
  }

  handleModalOpen() {
    this.checkoutInfoDocumentStore.toggle.handleOpen()
  }

  handleModalClose() {
    this.checkoutInfoDocumentStore.toggle.handleClose()
  }

  handleTravelerDocumentOpen(documentType: DocumentType | null) {
    if (documentType) {
      this.checkoutInfoDocumentStore.setDocumentType(documentType)
    }
    if (!this.checkoutInfoDocumentStore.documentType) {
      return
    }
    if (
      this.getDocuments().length &&
      !this.checkoutInfoDocumentStore.travelerDocumentsToggle.isOpen
    ) {
      this.checkoutInfoDocumentStore.travelerDocumentsToggle.handleOpen()
    } else {
      this.checkoutInfoDocumentStore.setIsSaveButtonDisabled(true)
      this.checkoutInfoDocumentStore.travelerDocumentActionToggle.handleOpen()
    }
  }

  handleTravelerDocumentSubmit() {
    switch (this.checkoutInfoDocumentStore.documentType) {
      case DocumentType.KTN:
        this.handleUpdateKnownTravelerNumberSubmit()
        break
      case DocumentType.Redress:
        this.handleUpdateRedressNumberSubmit()
        break
    }
  }

  private async handleUpdateKnownTravelerNumberSubmit() {
    const { knownTravelerNumberValidator, oldDocument } = this.checkoutInfoDocumentStore
    const knownTravelerNumber = knownTravelerNumberValidator.values
    const convertedExpDate = this.convertDate(knownTravelerNumber.expirationDate)
    const validatorCheck = await knownTravelerNumberValidator.submit()
    if (!validatorCheck.isValid) {
      return
    }

    const knownTravelerNumbers = this.getKnownTravelerNumbers()
    if (oldDocument) {
      const knownTravelerNumberIndex = knownTravelerNumbers.findIndex(
        (ktn) => ktn.countryCode === String(oldDocument.countryCode),
      )
      if (knownTravelerNumberIndex > -1) {
        knownTravelerNumbers[knownTravelerNumberIndex] = {
          number: knownTravelerNumber.number,
          countryCode: knownTravelerNumber.countryCode,
          expirationDate: convertedExpDate,
        }

        this.checkoutInfoStore.primaryTraveler.setKnownTravelerNumbers(knownTravelerNumbers)
      }
    } else {
      this.checkoutInfoStore.primaryTraveler.setKnownTravelerNumbers([
        ...knownTravelerNumbers,
        {
          number: knownTravelerNumber.number,
          countryCode: knownTravelerNumber.countryCode,
          expirationDate: convertedExpDate,
        },
      ])
    }
    this.handleTravelerDocumentClose()
  }

  private async handleUpdateRedressNumberSubmit() {
    const { redressNumberValidator, oldDocument } = this.checkoutInfoDocumentStore
    const redressNumber = redressNumberValidator.values
    const validatorCheck = await redressNumberValidator.submit()
    if (!validatorCheck.isValid) {
      return
    }

    const redressNumbers = this.getRedressNumbers()
    if (oldDocument) {
      const redressNumberIndex = redressNumbers.findIndex(
        (redress) => redress.countryCode === String(oldDocument.countryCode),
      )
      if (redressNumberIndex > -1) {
        redressNumbers[redressNumberIndex] = {
          number: redressNumber.number,
          countryCode: redressNumber.countryCode,
        }

        this.checkoutInfoStore.primaryTraveler.setRedressNumbers(redressNumbers)
      }
    } else {
      this.checkoutInfoStore.primaryTraveler.setRedressNumbers([
        ...redressNumbers,
        {
          number: redressNumber.number,
          countryCode: redressNumber.countryCode,
        },
      ])
    }
    this.handleTravelerDocumentClose()
  }

  handleTravelerDocumentEdit(document: Document) {
    switch (this.checkoutInfoDocumentStore.documentType) {
      case DocumentType.KTN:
        this.handleKnownTravelerNumberEdit(document as KnownTravelerNumber)
        break
      case DocumentType.Redress:
        this.handleRedressNumberEdit(document as RedressNumber)
        break
    }
  }

  private handleKnownTravelerNumberEdit(document: KnownTravelerNumber) {
    this.checkoutInfoDocumentStore.setOldDocument(document)
    this.checkoutInfoDocumentStore.setDocumentField('number', String(document.number))
    this.checkoutInfoDocumentStore.setDocumentField('countryCode', document.countryCode)
    if (document.expirationDate) {
      this.handleKnownTravelerNumberExpirationDate(isoToDate(document.expirationDate))
    }
    this.handleTravelerDocumentOpen(this.checkoutInfoDocumentStore.documentType)
  }

  private handleRedressNumberEdit(document: RedressNumber) {
    this.checkoutInfoDocumentStore.setOldDocument(document)
    this.checkoutInfoDocumentStore.setDocumentField('number', String(document.number))
    this.checkoutInfoDocumentStore.setDocumentField('countryCode', document.countryCode)
    this.handleTravelerDocumentOpen(this.checkoutInfoDocumentStore.documentType)
  }

  handleTravelerDocumentClose() {
    if (this.checkoutInfoDocumentStore.travelerDocumentActionToggle.isOpen) {
      this.checkoutInfoDocumentStore.setIsSaveButtonDisabled(true)
      this.checkoutInfoDocumentStore.resetDocument()
      this.checkoutInfoDocumentStore.setIsDocumentTouched(false)
      this.checkoutInfoDocumentStore.setOldDocument(null)
      this.checkoutInfoDocumentStore.travelerDocumentActionToggle.handleClose()
    } else {
      this.checkoutInfoDocumentStore.travelerDocumentsToggle.handleClose()
      this.checkoutInfoDocumentStore.setDocumentType(null)
    }
  }

  handlePickerOpen(picker: keyof typeof this.checkoutInfoDocumentStore.pickerTogglers) {
    this.checkoutInfoDocumentStore.pickerTogglers[picker].handleOpen()
  }

  handlePickerClose(picker: keyof typeof this.checkoutInfoDocumentStore.pickerTogglers) {
    this.checkoutInfoDocumentStore.pickerTogglers[picker].handleClose()
  }

  handleKnownTravelerNumberExpirationDate(value?: Date) {
    const date = value ? dateFormat(value, formDateFormat) : ''
    this.handleTravelerDocumentFieldChange('expirationDate', date)
  }

  handleTravelerDocumentFieldChange(field: DocumentField, value: string) {
    this.checkoutInfoDocumentStore.setIsSaveButtonDisabled(false)
    this.checkoutInfoDocumentStore.setDocumentField(field, value)
  }

  handleRemoveTravelerDocument() {
    switch (this.checkoutInfoDocumentStore.documentType) {
      case DocumentType.KTN:
        this.handleRemoveKnownTravelerNumber()
        break
      case DocumentType.Redress:
        this.handleRemoveRedressNumber()
        break
    }
  }

  private handleRemoveKnownTravelerNumber() {
    const { knownTravelerNumberValidator } = this.checkoutInfoDocumentStore
    if (!knownTravelerNumberValidator.values.countryCode) {
      return
    }

    const filteredKnownTravelerNumbers = this.getKnownTravelerNumbers().filter(
      (ktn) => String(ktn.countryCode) !== String(knownTravelerNumberValidator.values.countryCode),
    )

    this.checkoutInfoStore.primaryTraveler.setKnownTravelerNumbers(filteredKnownTravelerNumbers)
    this.checkoutInfoDocumentStore.removeToggle.handleClose()
    this.handleTravelerDocumentClose()
  }

  private handleRemoveRedressNumber() {
    const { redressNumberValidator } = this.checkoutInfoDocumentStore
    if (!redressNumberValidator.values.countryCode) {
      return
    }

    const filteredRedressNumbers = this.getRedressNumbers().filter(
      (redress) =>
        String(redress.countryCode) !== String(redressNumberValidator.values.countryCode),
    )

    this.checkoutInfoStore.primaryTraveler.setRedressNumbers(filteredRedressNumbers)
    this.checkoutInfoDocumentStore.removeToggle.handleClose()
    this.handleTravelerDocumentClose()
  }

  handleDocumentFieldFocusClear() {
    if (
      this.checkoutInfoDocumentStore.isDocumentTouched ||
      !this.accountInformationActions.getAccountInformation().isDelegatedOrImpersonated
    ) {
      return
    }
    this.handleTravelerDocumentFieldChange('number', '')
    this.checkoutInfoDocumentStore.setIsDocumentTouched(true)
  }

  private convertDate(date: string) {
    try {
      const parsedDate = parse(date, formDateFormat, new Date())
      return dateFormat(parsedDate, apiDateFormat)
    } catch (error) {
      return ''
    }
  }
}
