/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import type { ValidationError } from 'yup'
import type BaseSchema from 'yup/lib/schema'
import type { AnyObject } from 'yup/lib/types'

import type { ErrorsMap, FieldsMap, SetFields, MapUnion } from './types'

type Args<T extends MapUnion = MapUnion> = {
  yupScheme: BaseSchema
  setErrors: SetFields<T>
  values: T
  context?: AnyObject
}

export function useSubmit<T extends MapUnion = MapUnion>({
  yupScheme,
  setErrors,
  values,
  context,
}: Args<T>) {
  const submit = useCallback(async () => {
    setErrors(() => ({} as T))

    try {
      await yupScheme.validate(values, { abortEarly: false, context })
      return {
        values,
        isValid: true,
      }
    } catch (e: any) {
      const errorsMap =
        e?.inner?.reduce((acc: FieldsMap, errorItem: ValidationError) => {
          acc[errorItem.path || ''] = errorItem.message
          return acc
        }, {} as ErrorsMap) || {}
      setErrors(errorsMap)
      return {
        values,
        errors: errorsMap,
        isValid: false,
      }
    }
  }, [values])

  return submit
}
