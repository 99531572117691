export const Mark = () => {
  return (
    <svg
      aria-hidden
      width="11"
      height="10"
      viewBox="1 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70918 9.0265C3.99388 9.35711 4.37959 9.5867 4.84796 9.5867C5.29796 9.5867 5.72041 9.33875 5.98673 8.95303L10.6245 2.01017C10.799 1.73466 10.8908 1.45915 10.8908 1.21119C10.8908 0.549967 10.3031 0.0632324 9.65102 0.0632324C9.2102 0.0632324 8.87041 0.292824 8.57653 0.752008L4.83878 6.64793L2.86428 4.36119C2.60714 4.0765 2.31326 3.91119 1.93673 3.91119C1.28469 3.91119 0.724489 4.43466 0.724489 5.10507C0.724489 5.41732 0.816326 5.67446 1.07347 5.96833L3.70918 9.0265Z"
        fill="currentColor"
      />
    </svg>
  )
}
