import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  padding: 12px 24px 15px 24px;
  background-color: ${(p) => p.theme.colors.error1};
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ConfirmButton = styled(Button)`
  text-align: left;
  padding: 0;
  display: flex;
  justify-content: space-between;
`

export const MobileConfirmButton = styled(Button)`
  height: 32px;
`

export const MobileContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  padding: 16px;
  margin-bottom: 24px;
  border: 2px solid ${(p) => p.theme.colors.error};
`
export const MobileTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 16px;
`
