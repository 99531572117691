import { useTranslation } from 'react-i18next'
import { TimeRangePicker } from '@etta/components/time-range-picker/time-range-picker'
import { TimeRangePicker as TimeRangePickerNew } from '@etta/components/sliders/time-range-picker'
import { SegmentType } from '@fiji/graphql/types'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import type { Place, TimeRange } from '@fiji/types'
import { TimeRangeDirection } from '@fiji/enums'
import { useScreenType, ScreenType } from '@fiji/modes'

const i18Base = 'TripPlanner.BaseSearch'

type Props = {
  originPlace?: Place | null
  destinationPlace?: Place | null
  onSubmit?: (time: TimeRange) => void
  selectedTime: TimeRange
  isVisible: boolean
  onClose: () => void
  date?: Date | null
}

export function AirTimeRangePicker({
  originPlace,
  destinationPlace,
  onSubmit,
  selectedTime,
  isVisible,
  onClose,
  date,
}: Props) {
  const { t } = useTranslation()

  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { initialCustomTime, timeOptions } = useFlightTimeConfiguration()

  if (!isMobile) {
    return (
      <TimeRangePickerNew
        actionLabel={t(`${i18Base}.Input.Leaving`)}
        selectedTime={selectedTime}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
        onSubmit={onSubmit}
        initialCustomTime={initialCustomTime}
        timeRangeDirection={TimeRangeDirection.Departure}
        isVisible={isVisible}
        timeOptions={timeOptions}
        onClose={onClose}
        forSegment={SegmentType.Flight}
        date={date}
      />
    )
  }

  return (
    <TimeRangePicker
      originPlace={originPlace}
      destinationPlace={destinationPlace}
      onSubmit={onSubmit}
      selectedTime={selectedTime}
      isVisible={isVisible}
      onClose={onClose}
      date={date}
      initialCustomTime={initialCustomTime}
      forSegment={SegmentType.Flight}
      timeOptions={timeOptions}
    />
  )
}
