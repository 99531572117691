import i18next from 'i18next'

type Args = {
  i18nBase: string
  scores?: number
}

export function getAriaLabel({ i18nBase, scores }: Args) {
  if (typeof scores !== 'undefined') {
    return i18next.t(`${i18nBase}.AriaLabel`, { value: scores })
  }
}
