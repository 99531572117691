import { useTranslation } from 'react-i18next'
import { RatePrice } from '@etta/components/rate-price/rate-price'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'
import { Container } from './trip-total-block-desktop-styled'

export function TripTotalBlockDesktop({ tripTotal, hasRail }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Container hasRail={hasRail}>
        <Text variant="title3" color="mainText">
          {t('PurchaseReservationSuccess.TripTotal')}
        </Text>
        <RatePrice rate={tripTotal} textVariant="title3" />
      </Container>
    </>
  )
}
