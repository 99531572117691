import styled, { css } from 'styled-components'

export const RailLegDateWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 140% */
  letter-spacing: -0.018px;

  ${(props) =>
    props.isMobile &&
    css`
      width: 245px;
      align-self: stretch;
      margin-top: 16px;
      margin-bottom: 16px;
    `}

  ${(props) =>
    !props.isMobile &&
    css`
      height: 36px;
      flex: 1 0 0;
      margin-top: 8px;
      margin-bottom: 8px;
    `}
`
