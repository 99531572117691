import { useRef } from 'react'
import { useTimeUtils } from '@fiji/hooks/use-time-utils'
import { ControlsContainerLayout } from '@etta/ui/date-picker-temp/controls/controls-container'
import {
  ButtonsContainer,
  SelectedDateStatus,
  Separator,
} from '@etta/ui/date-picker-temp/controls/controls-styled'
import { SelectedDateTitle } from '@etta/ui/date-picker-temp/controls/selected-date-title'
import { Block } from '@etta/ui/block'
import { MediaLayout } from '@etta/ui/media-layout'
import { PWAIcon } from '@etta/ui/icon/pwa-icon'
import { SlidingLine } from '@etta/ui/date-picker-temp/controls/sliding-line'
import type { LayoutControlsProps } from '@etta/ui/date-picker-temp/controls/types'

export function ControlsLayoutDesktop({
  date,
  dateEnd,
  isRange,
  focusedInput,
  startDateAriaLabel,
  startDateTitle,
  endDateTitle,
  endDateAriaLabel,
  isOpen,
}: LayoutControlsProps) {
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)
  const { convertDateToString } = useTimeUtils()

  const activeRef = focusedInput === 'startDate' ? startRef : endRef
  const activeDateStrLength =
    focusedInput === 'startDate' ? startDateTitle?.length : endDateTitle?.length

  const containerLabel = `${date ? convertDateToString(date) : ''} ${
    dateEnd ? convertDateToString(dateEnd) : ''
  }`

  return (
    <ControlsContainerLayout data-name="date-picker-controls" aria-label={containerLabel}>
      <ButtonsContainer shouldCenterItems>
        <SelectedDateStatus
          ref={startRef}
          startDate={date}
          endDate={dateEnd}
          isMobile={false}
          isSelected={focusedInput === 'startDate'}
          isRange={isRange}
          aria-label={startDateAriaLabel}>
          <SelectedDateTitle isMobile={false} dateTitle={startDateTitle} />
        </SelectedDateStatus>
        {isRange && (
          <>
            <MediaLayout
              mobileSlot={<Separator />}
              desktopSlot={
                <Block marginHorizontal={16}>
                  <PWAIcon color="bodyText2" size={32} name="arrowForwardPWA" />
                </Block>
              }
            />

            <SelectedDateStatus
              ref={endRef}
              startDate={date}
              endDate={dateEnd}
              isMobile={false}
              isSelected={focusedInput === 'endDate'}
              isStickToRight
              isRange={isRange}
              aria-label={endDateAriaLabel}>
              <SelectedDateTitle isMobile={false} dateTitle={endDateTitle} />
            </SelectedDateStatus>
          </>
        )}
      </ButtonsContainer>
      <SlidingLine
        element={activeRef}
        elementLength={activeDateStrLength || 0}
        isVisible={isOpen}
      />
    </ControlsContainerLayout>
  )
}
