/* eslint import/no-restricted-paths: 0 */
import type {
  HotelTravelPreferenceInput,
  UpdateHotelTravelPreferenceMutationHookResult,
  GazooMembership,
} from '@fiji/graphql/hooks'
import { VendorTravelVertical } from '@fiji/graphql/hooks'
import { Action, Inject } from '@etta/di'
import { HotelPreferencesStore } from '../stores/hotel-preferences.store'
import { TravelPreferencesService } from '../services/travel-preferences.service'
import { SettingsStore } from '../stores/settings.store'

@Action()
export class HotelPreferencesActions {
  constructor(
    @Inject()
    private readonly hotelPreferencesStore: HotelPreferencesStore,
    @Inject()
    private readonly travelPreferencesService: TravelPreferencesService,
    @Inject()
    private readonly settingsStore: SettingsStore,
  ) {}

  handleFieldChange<T extends keyof HotelTravelPreferenceInput>(field: T) {
    return (value: HotelTravelPreferenceInput[T]) => {
      this.hotelPreferencesStore.setIsSaveButtonDisabled(false)
      return this.hotelPreferencesStore.setHotelPreferencesValue(field, value)
    }
  }

  async handleHotelPreferencesSave(handleUpdate: UpdateHotelTravelPreferenceMutationHookResult) {
    const validatorCheck = await this.hotelPreferencesStore.hotelPreferencesValidator.submit()
    const hasValidationErrors = !!validatorCheck.errors

    if (hasValidationErrors) {
      return
    }

    this.travelPreferencesService.saveHotelPreferences({
      input: this.hotelPreferencesStore.hotelPreferencesValidator.values,
      handleUpdate,
      handleClose: () => {
        this.hotelPreferencesStore.setIsSaveButtonDisabled(true)
        this.hotelPreferencesStore.toggle.handleClose()
      },
    })
  }

  handleEditModalOpening() {
    this.hotelPreferencesStore.setIsSaveButtonDisabled(true)
    const hotelPreferencesInput = this.travelPreferencesService.getHotelPreferencesInput()
    this.hotelPreferencesStore.setHotelPreferencesInput(hotelPreferencesInput)

    if (!this.hotelPreferencesStore.hotelAvailableSpecialRequests) {
      this.fetchHotelSpecialRequests()
    }

    this.hotelPreferencesStore.toggle.handleOpen()
  }

  handleEditHotelLoyaltyProgramModalOpen() {
    this.hotelPreferencesStore.setIsSaveButtonDisabled(true)
    const hotelPreferencesInput = this.travelPreferencesService.getHotelPreferencesInput()
    this.hotelPreferencesStore.setHotelPreferencesInput(hotelPreferencesInput)

    if (!this.hotelPreferencesStore.availableHotelVendors) {
      this.fetchAvailableHotelVendors()
    }

    this.hotelPreferencesStore.hotelLoyaltyProgramActionToggle.handleOpen()
  }

  handleEditExistingHotelLoyaltyProgram(vendorCode?: string | number | null) {
    this.hotelPreferencesStore.setIsEditExistingProgramMode(true)
    this.handleEditHotelLoyaltyProgramModalOpen()

    const hotelMemberships = this.hotelPreferencesStore.hotelPreferencesValidator.values
      .membership as GazooMembership[]
    const existingLoyaltyProgram = hotelMemberships.find((item) => item.vendorCode === vendorCode)

    if (existingLoyaltyProgram) {
      this.hotelPreferencesStore.setHotelLoyaltyProgram(existingLoyaltyProgram)
    }
  }

  handleEditHotelLoyaltyProgramModalClose() {
    this.hotelPreferencesStore.setIsSaveButtonDisabled(true)
    this.hotelPreferencesStore.hotelLoyaltyProgramActionToggle.handleClose()
    this.hotelPreferencesStore.setIsEditExistingProgramMode(false)
    this.hotelPreferencesStore.resetHotelLoyaltyProgram()
    this.settingsStore.resetLoyaltyProgramsValidation()
  }

  handleLoyaltyProgramChange(loyaltyProgram: GazooMembership) {
    this.hotelPreferencesStore.setIsSaveButtonDisabled(false)
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.hotelPreferencesStore.setHotelLoyaltyProgram(loyaltyProgram)
  }

  handleLoyaltyProgramVendorChange(vendorCode: string) {
    const hotelMemberships = this.hotelPreferencesStore.hotelPreferencesValidator.values
      .membership as GazooMembership[]
    const existingMembership = hotelMemberships.find((item) => item.vendorCode === vendorCode)

    this.handleLoyaltyProgramChange({
      vendorCode,
      number: existingMembership?.number || '',
    })
    this.settingsStore.resetLoyaltyProgramsValidation()

    this.hotelPreferencesStore.setIsEditExistingProgramMode(!!existingMembership)
  }

  async handleHotelLoyaltyProgramSave(handleUpdate: UpdateHotelTravelPreferenceMutationHookResult) {
    const { hotelLoyaltyProgram, hotelLoyaltyProgramActionToggle } = this.hotelPreferencesStore
    const { vendorCode, number } = hotelLoyaltyProgram
    const hotelMemberships = this.hotelPreferencesStore.hotelPreferencesValidator.values
      .membership as GazooMembership[]
    const isExistingMembership = this.hotelPreferencesStore.isViewingExistingMembership

    this.settingsStore.setLoyaltyProgramsValidation({ vendorCode, number })

    if (!vendorCode || !number) {
      return
    }

    if (isExistingMembership) {
      const filteredMemberships = hotelMemberships.filter((item) => item.vendorCode !== vendorCode)
      this.handleFieldChange('membership')([...filteredMemberships, hotelLoyaltyProgram])
    } else {
      this.handleFieldChange('membership')([...hotelMemberships, hotelLoyaltyProgram])
    }

    const result = await this.travelPreferencesService.saveHotelPreferences({
      input: this.hotelPreferencesStore.hotelPreferencesValidator.values,
      handleUpdate,
      handleClose: hotelLoyaltyProgramActionToggle.handleClose,
    })

    if (result?.isErr()) {
      return
    }

    this.hotelPreferencesStore.resetHotelLoyaltyProgram()
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.hotelPreferencesStore.setIsEditExistingProgramMode(false)
  }

  async handleRemoveExistingLoyaltyProgram(
    handleUpdate: UpdateHotelTravelPreferenceMutationHookResult,
  ) {
    if (!this.hotelPreferencesStore.isViewingExistingMembership) {
      return
    }
    const {
      hotelLoyaltyProgram,
      hotelPreferencesValidator,
      hotelLoyaltyProgramActionToggle,
      hotelLoyaltyProgramRemoveToggle,
    } = this.hotelPreferencesStore
    const membership = hotelPreferencesValidator.values.membership as GazooMembership[]
    const filteredMemberships = membership.filter(
      (item) => item.vendorCode !== hotelLoyaltyProgram.vendorCode,
    )

    this.handleFieldChange('membership')(filteredMemberships)
    hotelLoyaltyProgramRemoveToggle.handleClose()

    await this.travelPreferencesService.saveHotelPreferences({
      input: this.hotelPreferencesStore.hotelPreferencesValidator.values,
      handleUpdate,
      handleClose: hotelLoyaltyProgramActionToggle.handleClose,
    })
    this.hotelPreferencesStore.resetHotelLoyaltyProgram()
    this.hotelPreferencesStore.setIsEditExistingProgramMode(false)
  }

  async fetchAvailableHotelVendors() {
    this.hotelPreferencesStore.setIsAvailableHotelVendorsLoading(true)
    const availableVendors = await this.travelPreferencesService.fetchAvailableVendors(
      VendorTravelVertical.Hotel,
    )

    this.hotelPreferencesStore.setAvailableHotelVendors(
      availableVendors?.availableHotelVendors || null,
    )
    this.hotelPreferencesStore.setIsAvailableHotelVendorsLoading(false)
  }

  async fetchHotelSpecialRequests() {
    this.hotelPreferencesStore.setIsAvailableHotelSpecialRequestsLoading(true)
    const specialRequests = await this.travelPreferencesService.fetchAvailableSpecialRequets(
      VendorTravelVertical.Hotel,
    )

    this.hotelPreferencesStore.setHotelAvailableSpecialRequests(
      specialRequests?.hotelSpecialRequests || null,
    )
    this.hotelPreferencesStore.setIsAvailableHotelSpecialRequestsLoading(false)
  }
}
