export default function () {
  return (
    <svg width="94" height="90" viewBox="0 0 94 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="47.4999" r="42.4999" fill="currentColor" />
      <path
        d="M94 19C94 29.4934 85.4917 38 75 38C64.5063 38 56 29.4934 56 19C56 8.50664 64.5063 0 75 0C85.4917 0 94 8.50664 94 19Z"
        fill="#EFEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0949 19.1675C90.0949 27.4115 83.4117 34.0952 75.1655 34.0952C66.921 34.0952 60.2378 27.4115 60.2378 19.1675C60.2378 10.9218 66.921 4.23804 75.1655 4.23804C83.4117 4.23804 90.0949 10.9218 90.0949 19.1675Z"
        fill="currentColor"
      />
      <path
        d="M81.7805 25.2238L81.7817 25.225C81.841 25.2838 81.8805 25.37 81.8805 25.4908C81.8805 25.7296 81.7219 25.8807 81.5034 25.8807C81.3731 25.8807 81.2741 25.8396 81.1961 25.7667L77.7735 22.3683L77.3422 21.9401L76.8338 22.2731C75.9811 22.8317 74.9568 23.1586 73.863 23.1586C70.881 23.1586 68.4523 20.7425 68.4523 17.8055C68.4523 14.8685 70.881 12.4525 73.863 12.4525C76.845 12.4525 79.2737 14.8685 79.2737 17.8055C79.2737 18.947 78.9106 20.005 78.2942 20.8666L77.9233 21.3851L78.3752 21.8348L81.7805 25.2238ZM68.5959 17.8055C68.5959 20.6848 70.9738 23.0304 73.863 23.0304C76.7523 23.0304 79.1302 20.6848 79.1302 17.8055C79.1302 14.9348 76.7531 12.5807 73.863 12.5807C70.973 12.5807 68.5959 14.9348 68.5959 17.8055Z"
        fill="white"
        stroke="white"
        strokeWidth="1.50037"
      />
      <path
        d="M42.3959 65.2727C42.3429 65.202 42.2851 65.1246 42.2227 65.0408C41.8588 64.5526 41.3387 63.8467 40.7143 62.9771C39.4651 61.2374 37.8005 58.845 36.1368 56.2318C34.4719 53.617 32.8138 50.7902 31.5735 48.1804C30.3237 45.5504 29.5342 43.2125 29.5342 41.5505C29.5342 34.7113 35.2453 29.1893 42.3959 29.1893C49.5464 29.1893 55.2576 34.7113 55.2576 41.5505C55.2576 43.2125 54.4681 45.5504 53.2182 48.1804C51.978 50.7902 50.3198 53.617 48.655 56.2318C46.9912 58.845 45.3266 61.2374 44.0775 62.9771C43.4531 63.8467 42.9329 64.5526 42.5691 65.0408C42.5067 65.1246 42.4488 65.202 42.3959 65.2727ZM35.8219 41.5482C35.8219 45.1174 38.7378 47.8946 42.3959 47.8946C46.054 47.8946 48.9698 45.1173 48.9698 41.5482C48.9698 37.9791 46.0539 35.2018 42.3959 35.2018C38.7353 35.2018 35.8219 37.9816 35.8219 41.5482Z"
        fill="white"
      />
    </svg>
  )
}
