import { useCallback } from 'react'
import type { Place, PlainTime as Time } from '@fiji/types'
import { appMode } from '@fiji/modes'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates/get-date-without-timezone-offset'

export function useUberApp(
  pickUpPlace?: Place,
  dropOffPlace?: Place,
  pickUpDate?: Date,
  pickUpTime?: Time,
) {
  const openUberApp = useCallback(() => {
    const { isOpenInIOS } = appMode
    const clientId = window._config.uberClientId
    const params = new URLSearchParams()

    if (pickUpPlace && dropOffPlace && pickUpDate && pickUpTime) {
      const dateTime = new Date(pickUpDate.setUTCHours(pickUpTime.hours, pickUpTime.minutes))
      const pickUpDateTime = isOpenInIOS ? getDateWithoutTimezoneOffset(dateTime) : dateTime

      params.append('client_id', `${clientId}`)
      params.append('pickup[latitude]', `${pickUpPlace.latitude}`)
      params.append('pickup[longitude]', `${pickUpPlace.longitude}`)
      params.append('pickup[nickname]', `${pickUpPlace.name}`)
      params.append('dropoff[latitude]', `${dropOffPlace.latitude}`)
      params.append('dropoff[longitude]', `${dropOffPlace.longitude}`)
      params.append('dropoff[nickname]', `${dropOffPlace.name}`)
      params.append('datetime[formatted_date]', `${pickUpDateTime.toISOString()}`)
    }

    if (isOpenInIOS) {
      //  Reference to this issue: https://stackoverflow.com/questions/59778280/document-location-href-not-working-on-safari-2020
      setTimeout(() => {
        document.location.href = `uber://scheduledrides/confirm?${params.toString()}`
      })
    } else {
      document.location.href =
        `intent://scheduledrides/confirm?${params.toString()}` +
        `#Intent;scheme=uber;` +
        `package=com.ubercab;` +
        `S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.ubercab;end`
    }
  }, [pickUpPlace, dropOffPlace, pickUpDate, pickUpTime])

  const openUberAppWithoutParams = useCallback(() => {
    const { isOpenInIOS } = appMode
    if (isOpenInIOS) {
      setTimeout(() => {
        document.location.href = `uber://`
      })
    } else {
      document.location.href =
        `intent:` +
        `#Intent;scheme=uber;` +
        `package=com.ubercab;` +
        `S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.ubercab;end`
    }
  }, [])

  return {
    openUberApp,
    openUberAppWithoutParams,
  }
}
