import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CarInfoBlockDesktop, CarInfoBlockMobile } from './layout'

export function CarInfoBlock(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarInfoBlockMobile {...props} />}
      desktopSlot={<CarInfoBlockDesktop {...props} />}
    />
  )
}
