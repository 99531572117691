import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Switch } from '@etta/ui/switch'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { SectionTitle } from '../rail-filters-styled'
import { SwitchSection } from './rail-policy-filter-styled'

const i18Base = 'RailFilters.Rail'

export const RailPolicyFilter = observer(function RailPolicyFilter() {
  const { t } = useTranslation()

  const { railFilterAction, railFiltersStore } = useRailContext()

  return (
    <SwitchSection withBorder>
      <SectionTitle>{t(i18Base + '.CompanyPolicy')}</SectionTitle>
      <Switch
        isPWA
        labelPlacement="left"
        label={t(i18Base + '.CompanyPolicyDescription')}
        checked={!!railFiltersStore.changedFilters.excludeOutOfPolicy}
        onChange={railFilterAction.onPolicyToggle}
      />
    </SwitchSection>
  )
})
