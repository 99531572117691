import { useState } from 'react'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useLoyaltyProgram } from '../use-loyalty-program'
import type { UseRailPreferencesProps } from './types'
import { TicketMethod } from './types'

export function useRailPreferences({
  loyaltyProgram,
  onSubmit,
  isEticket,
}: UseRailPreferencesProps) {
  const {
    featureFlags: { isRailCardSelectionEnabled },
  } = useFeatureFlags()

  const { getLoyaltyWithNumbers, loyaltyProgramValues, onChangeLoyaltyProgram } = useLoyaltyProgram(
    loyaltyProgram.value,
  )
  const currentTicketMethod = isEticket ? TicketMethod.ETicket : TicketMethod.Collect
  const [selectedMethod, setSelectedMethod] = useState(currentTicketMethod)

  const handleSubmit = () => {
    onSubmit({
      membership: getLoyaltyWithNumbers(),
      specialRequestCodes: [],
      isEticket: selectedMethod === TicketMethod.ETicket,
    })
  }

  const selectMethodHandler = (method: TicketMethod) => {
    setSelectedMethod(method)
  }

  return {
    selectedMethod,
    loyaltyProgramValues,
    onChangeLoyaltyProgram,
    handleSubmit,
    selectMethodHandler,
    isRailCardSelectionEnabled,
  }
}
