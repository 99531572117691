import { InputButton } from '@etta/ui/input-button'
import { InputButtonGroup } from '@etta/ui/input-button-group'
import type { Props } from '../types'
import { usePlaceDates } from '../use-date-time'
import { useTime } from './use-time'

export function SegmentDateTimeMobile({
  onOpenDatePicker,
  onOpenTimePicker,
  date,
  time,
  type,
  segmentType,
  dateErrorMessage,
  timeErrorMessage,
  hasError,
}: Props) {
  const {
    groupLabel,
    testIdDatePicker,
    selectedDate,
    selectedDateLabel,
    ariaLabelDate,
    datePlaceholder,
    testIdTimePicker,
  } = usePlaceDates({
    date,
    type,
    segmentType,
  })

  const { selectedTime, selectedTimeLabel, ariaLabelTime, timePlaceholder } = useTime({
    time,
    type,
    segmentType,
  })

  const errorMessage = timeErrorMessage ?? dateErrorMessage

  return (
    <InputButtonGroup
      aria-label={groupLabel}
      hasError={typeof hasError === 'boolean' ? hasError : !!errorMessage}
      errorMessage={errorMessage}>
      <InputButton
        data-tracking-id={testIdDatePicker}
        icon="calendarPWA"
        onClick={onOpenDatePicker}
        value={selectedDate}
        label={selectedDateLabel}
        aria-label={ariaLabelDate}
        placeholder={datePlaceholder}
        isFilled={!!selectedDate}
      />
      <InputButton
        data-tracking-id={testIdTimePicker}
        icon="timePWA"
        onClick={onOpenTimePicker}
        value={selectedTime}
        label={selectedTimeLabel}
        aria-label={ariaLabelTime}
        placeholder={timePlaceholder}
        isFilled={!!selectedTime}
      />
    </InputButtonGroup>
  )
}
