import { Service, Inject } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { PlacesStorageAction } from '@etta/modules/places-storage/interface/actions/places-storage.action'
import type { RailCardValueObject } from '@etta/modules/review-trip/core/value-objects/rail-card.value-object'
import type { Dates, Place, TimeRange } from '@fiji/types'
import { RailSearchFormQueryService } from '../services/rail-search-form-query.service'
import { RailSearchFormStore } from '../stores/rail-search-form.store'
import type { RailTripType } from './../../../../../hooks/search-queries/use-rail-search-query/types'

@Service()
export class RailSearchFormService {
  constructor(
    @Inject()
    private readonly railSearchFormStore: RailSearchFormStore,
    @Inject()
    private readonly railSearchFormQueryService: RailSearchFormQueryService,
    @Inject()
    private readonly placesStorageAction: PlacesStorageAction,
  ) {}

  changeTripType(tripType: RailTripType) {
    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        railTripType: tripType,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      railTripType: tripType,
    })
  }

  changeOriginPlace(place: Place) {
    const { isGrouped, ...originPlace } = place
    this.placesStorageAction.addNewPlace('rail')(originPlace)

    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        originPlace,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      originPlace,
    })
  }

  changeDestinationPlace(place: Place) {
    const { isGrouped, ...destinationPlace } = place
    this.placesStorageAction.addNewPlace('rail')(destinationPlace)

    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        destinationPlace,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      destinationPlace,
    })
  }

  changeDates({ date, dateEnd }: Dates) {
    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        ...(date && { originDate: date }),
        ...(dateEnd && { destinationDate: dateEnd }),
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      ...(date && { originDate: date }),
      ...(dateEnd && { destinationDate: dateEnd }),
    })
  }

  changeOriginTime(time: TimeRange) {
    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        originTime: time,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      originTime: time,
    })
  }

  changeDestinationTime(time: TimeRange) {
    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        destinationTime: time,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      destinationTime: time,
    })
  }

  changeRailCard(railCard?: RailCardValueObject) {
    const newRailCards = railCard ? [railCard] : undefined

    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        railCards: newRailCards,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      railCards: newRailCards,
    })
  }

  switchLocations() {
    const { originPlace, destinationPlace } = this.railSearchFormStore.railForm

    if (this.railSearchFormStore.isEditMode) {
      this.railSearchFormStore.setSearchRailEditCache({
        ...this.railSearchFormStore.searchRailEditCache,
        originPlace: destinationPlace,
        destinationPlace: originPlace,
      })
      return
    }

    this.railSearchFormQueryService.appendQueryParams({
      originPlace: destinationPlace,
      destinationPlace: originPlace,
    })
  }

  dropEditCache() {
    this.railSearchFormStore.setSearchRailEditCache({
      originTime: this.railSearchFormStore.railSearchQuery.originTime,
      destinationTime: this.railSearchFormStore.railSearchQuery.destinationTime,
      railTripType: this.railSearchFormStore.railSearchQuery.railTripType,
      originPlace: this.railSearchFormStore.railSearchQuery.originPlace,
      destinationPlace: this.railSearchFormStore.railSearchQuery.destinationPlace,
      originDate: this.railSearchFormStore.railSearchQuery.originDate,
      destinationDate: this.railSearchFormStore.railSearchQuery.destinationDate,
      railCards: this.railSearchFormStore.railSearchQuery.railCards,
    })
  }
}
