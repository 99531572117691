import type { ReactNode } from 'react'
import { Portal } from '@etta/ui/portal'
import { ContainerBg, ModalContainer } from './container-styled'

type Props = {
  'data-tracking-id'?: string
  children: ReactNode
}

export function Container({
  children,
  'data-tracking-id': dataTrackingId = 'view-state-modal',
}: Props) {
  return (
    <Portal target="#modals-container">
      <ContainerBg data-tracking-id={dataTrackingId}>
        <ModalContainer>{children}</ModalContainer>
      </ContainerBg>
    </Portal>
  )
}
