import { useTranslation } from 'react-i18next'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import type { TripCartListProps } from '../types'
import { ListItem } from '../list-item'
import { PriceInformation } from '../price-information'
import {
  ListWrapper,
  TitleBlock,
  Title,
  CloseIcon,
  ItemsContainer,
  ReviewButton,
} from './list-styled'
import { useList } from './use-list'

export function List({
  isOpen,
  cityLabel,
  dateLabel,
  isDisabled,
  onClose,
  items,
  handleReviewAndCheckout,
  forwardRef,
  isTripExpired,
  onDeleteTripClick,
  deleteTripLoading,
}: TripCartListProps) {
  const { t } = useTranslation()
  const { ref } = useClickOutside(() => {
    if (isOpen) {
      onClose()
    }
  })

  useList(ref, forwardRef)

  if (!isOpen) {
    return null
  }

  return (
    <ListWrapper ref={ref}>
      <TitleBlock>
        <Title>
          <Icon name="tripPWA" color="mainText" size="normal" />
          <Text isBlock variant="headline">
            {cityLabel}
          </Text>
          <CloseIcon size="medium" color="bodyText" onClick={onClose} icon="closePWA" />
        </Title>
        <Block marginLeft={32}>
          <Text isBlock variant="captionStrongCaps" color="bodyText">
            {dateLabel}
          </Text>
        </Block>
      </TitleBlock>
      <ItemsContainer>
        {items.map((item, ind) => (
          <ListItem key={ind} {...item} />
        ))}
      </ItemsContainer>

      <PriceInformation isTripExpired={isTripExpired} />

      {isTripExpired ? (
        <ReviewButton disabled={deleteTripLoading} fullWidth onClick={onDeleteTripClick}>
          {t('ReviewTrip.StartOver')}
        </ReviewButton>
      ) : (
        <>
          <ReviewButton
            disabled={isDisabled}
            fullWidth
            onClick={handleReviewAndCheckout}
            data-tracking-id="review-purchase-trip-button">
            {t('TripPlanner.TripCart.ReviewAndCheckout')}
          </ReviewButton>
          <Block marginTop={16}>
            <Button
              disabled={deleteTripLoading}
              btnType="error"
              onClick={onDeleteTripClick}
              fullWidth
              data-tracking-id="delete-trip-button"
              variant="text">
              {t('ItineraryExpiration.DeleteButton')}
            </Button>
          </Block>
        </>
      )}
    </ListWrapper>
  )
}
