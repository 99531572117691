import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { SeatReservationType } from '@fiji/graphql/types'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { getRailLabelsRtp } from '@fiji/utils/get-rail-labels-rtp'
import { useChangeRail } from '@etta/screens/review-trip-page/rail-details-modal/use-rail-details-modal'
import { useDeleteRail } from '@etta/screens/review-trip-page/hooks'
import { useReviewTripPageContextSelector } from '@etta/screens/review-trip-page/review-trip-page-provider'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { TrainServiceClass } from '@fiji/enums'
import type { IconNames } from '../../../../../../etta-ui/icon/types'

type Args = {
  isTripExist: boolean
  isChangeAllowed: boolean
  itineraryId: string
  segment: TrainSegmentEntity
}

const i18nBase = 'RailDetailsModal.FaresDetails.'
const NO_REFUNDS_FARE = 'No refunds'

function getRate(railLeg: TrainSegmentEntity) {
  return (railLeg.rate.primary.amount || railLeg.rate.secondary?.amount) !== 0
    ? railLeg.rate
    : undefined
}
export function useRailSegmentRtp({
  isTripExist,
  isChangeAllowed,
  segment: railLeg,
  itineraryId,
}: Args) {
  const railDetailsToggle = useTogglePopup()
  const ticketRestrictionsDetailsToggle = useTogglePopup()
  const changeRailModalToggle = useTogglePopup()
  const removalDialogToggle = useTogglePopup()

  const {
    railSegments,
    currentSelectedSeatsLabel,
    seatReservationType,
    seatPreferencesToggle,
    railViewStateParams,
    preferenceOptions,
    appliedPreferences,
    onPreferencesApply,
    temporaryCurrentLayout,
    isRailPreferenceOptionsExist,
    onSkip,
  } = useReviewTripPageContextSelector((state) => state)

  const updateChangeModalOpen = () => {
    changeRailModalToggle.handleOpen()
  }
  const handleCloseModal = useCallback(() => {
    railDetailsToggle.handleClose()
    changeRailModalToggle.handleClose()
    ticketRestrictionsDetailsToggle.handleClose()
  }, [changeRailModalToggle, railDetailsToggle, ticketRestrictionsDetailsToggle])

  const { t } = useTranslation()

  const segmentIndex = railSegments.findIndex((segment) => segment.legId === railLeg.legId)

  const handleChangeRailLink = isTripExist && isChangeAllowed ? updateChangeModalOpen : undefined
  const railSegment = railLeg.segments[0]
  const lastRailLegSegment = railLeg.segments[railLeg.segments.length - 1]
  const { arrival } = lastRailLegSegment
  const { departure, departureDate } = railSegment
  const formatTime = (time: string) =>
    tripReviewFormatDate(TripReviewDateType.TimePWA, time)?.toLowerCase() || ''

  const originTime = formatTime(railLeg.departureDateTime)
  const destinationTime = formatTime(railLeg.arrivalDateTime)
  const travelDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, departureDate) || ''

  const { fareConditions } = railLeg
  const serviceClassName = fareConditions?.tier.name
  const fareAttributes: string[] =
    fareConditions?.localizedFareConditions[0]?.summaryConditions.split('.').filter(Boolean) || []

  const isRefundable =
    railLeg.isRefundable ??
    fareAttributes.findIndex((item) => item.trim() === NO_REFUNDS_FARE) === -1

  const carrierLabels = getRailLabelsRtp(railLeg.segments)

  const informationText =
    carrierLabels.length < 3 ? carrierLabels.join(', ') : t(i18nBase + 'MultipleCarriers')

  const ticketInformationText = railLeg.isEticket
    ? t(i18nBase + 'Eticket')
    : t(i18nBase + 'Collect')

  const refundableText = isRefundable ? 'Refundable' : 'NonRefundable'

  const rate = getRate(railLeg)

  const seatSelectionIcon = 'seatSelectionPWA' as IconNames
  const infoOutlineIcon = 'infoOutlinePWA' as IconNames
  const isMandatory = seatReservationType[segmentIndex] === SeatReservationType.Mandatory

  const cardActions = useMemo(() => {
    return [
      {
        key: 'seatSelection',
        icon: seatSelectionIcon,
        label: currentSelectedSeatsLabel[segmentIndex].length
          ? currentSelectedSeatsLabel[segmentIndex]
          : t(i18nBase + 'SeatPreferences'),
        buttonText: isMandatory
          ? t(i18nBase + 'Preferences.Mandatory.Button')
          : t(i18nBase + 'Preferences.Optional.Button'),
        tooltipText: isMandatory
          ? t(i18nBase + 'Preferences.Mandatory.Tooltip')
          : t(i18nBase + 'Preferences.Optional.Tooltip'),
        onClick: seatPreferencesToggle.handleOpen,
        isSeatSelected: !!currentSelectedSeatsLabel[segmentIndex],
        isActive:
          seatReservationType[segmentIndex] !== SeatReservationType.None && !!preferenceOptions,
      },
      {
        key: 'notAvailableSeatSelection',
        icon: infoOutlineIcon,
        label: t(i18nBase + 'Seat.None'),
        buttonText: t(i18nBase + 'Preferences.NoReservation.Button'),
        tooltipText: t(i18nBase + 'Preferences.NoReservation.Tooltip'),
        onClick: () => {},
        isDisabled: true,
        isSeatSelected: false,
        isActive: seatReservationType[segmentIndex] === SeatReservationType.None,
      },
    ]
  }, [
    currentSelectedSeatsLabel,
    segmentIndex,
    t,
    isMandatory,
    seatPreferencesToggle.handleOpen,
    seatReservationType,
    preferenceOptions,
  ])

  const { handleChangeRail } = useChangeRail({
    itineraryId,
    handleCloseChangeModal: handleCloseModal,
    departure: railLeg.departureDateTime,
    arrival: railLeg.arrivalDateTime,
    departureStationName: railLeg.segments[0].departure.stationName,
    arrivalStationName: railLeg.segments[railLeg.segments.length - 1].arrival.stationName,
  })

  const { handleRemoveRail, viewStateParams } = useDeleteRail({
    itineraryId,
    legId: railLeg.legId,
    handleCloseRemoveModal: removalDialogToggle.handleClose,
  })

  const isFirstClass =
    railLeg.segments.findIndex((s) => s.service === TrainServiceClass.FirstClass) !== -1

  const changesTitle =
    railLeg.segments.length > 1
      ? t(i18nBase + 'Changes', { count: railLeg.segments.length - 1 })
      : t(i18nBase + 'Direct')

  return {
    arrival,
    departure,
    destinationTime,
    informationText,
    originTime,
    rate,
    railLeg,
    refundableText,
    serviceClassName,
    ticketInformationText,
    travelDate,
    cardActions,
    seatReservationType: seatReservationType[segmentIndex],
    seatReservationTypesArray: seatReservationType,
    railDetailsToggle,
    ticketRestrictionsDetailsToggle,
    removalDialogToggle,
    viewStateParams,
    isChangeModalOpen: changeRailModalToggle.isOpen,
    handleCloseModal,
    handleChangeRailLink,
    handleChangeRail,
    handleRemoveRail,
    updatePreferencesViewState: railViewStateParams,
    railSegmentsLength: railSegments.length,
    isSeatRequested:
      segmentIndex === 0
        ? !!appliedPreferences.isSeatRequestedOnDepart
        : !!appliedPreferences.isSeatRequestedOnReturn,
    preferenceOptions,
    appliedPreferences,
    seatPreferencesToggle,
    onPreferencesApply,
    temporaryCurrentLayout,
    isRailPreferenceOptionsExist,
    isFirstClass,
    changesTitle,
    selectedSeatsLabel: currentSelectedSeatsLabel[segmentIndex] ?? '',
    onSkip,
  }
}
