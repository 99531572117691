import { observer } from 'mobx-react-lite'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { ModalDetails } from '@etta/components/modal-details'
import { useAlertContext } from '@etta/modules/alert/interface'
import { AlertModalLayout } from './layout'

export const AlertModal = observer(function AlertModal() {
  const { alertStore } = useAlertContext()
  const { alertModalToggle } = alertStore
  const { featureFlags } = useFeatureFlags()
  const { isAlertsEnabled } = featureFlags

  if (!isAlertsEnabled) {
    return null
  }

  return (
    <ModalDetails
      isVisible={alertModalToggle.isOpen}
      onClose={alertModalToggle.handleClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <AlertModalLayout />
    </ModalDetails>
  )
})
