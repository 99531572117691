import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  margin-bottom: 41px;
  :last-child {
    margin-bottom: 10px;
  }
`
export const Unit = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
`
export const Separator = styled.div`
  margin: 14px 0 5px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const CheckedIcon = styled(Icon)`
  margin-left: 5px;
`

export const DoubleCurrency = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Label = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
