import type { GetSiteSsoSettingsQuery } from '@fiji/graphql/hooks'
import type { SiteSsoSettingsValueObject } from '@etta/modules/site-sso-settings/core/value-objects/site-sso-settings.value-object'

export class SiteSsoSettingsMapper {
  static toSiteSsoSettingsValueObject(query: GetSiteSsoSettingsQuery): SiteSsoSettingsValueObject {
    const { sessionTimeoutRedirectURL, logoutRedirectURL } = query.siteSsoSettings

    return {
      sessionTimeoutRedirectURL: sessionTimeoutRedirectURL ?? undefined,
      logoutRedirectURL: logoutRedirectURL ?? undefined,
    }
  }
}
