import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'

type Params = {
  date: string
}

export function useCancellationDate({ date }: Params) {
  const { t } = useTranslation()

  const label = useMemo(
    () =>
      t('PostBooking.TripDetails.CanceledHeader', {
        date: dateFormat(new Date(deleteTimezone(date)), 'eeee, MMM d'),
      }),
    [t, date],
  )

  return {
    label,
  }
}
