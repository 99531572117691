import i18n from 'i18next'
import { SegmentType } from '@etta/core/enums'

const i18nBase = 'PostBooking.TripDetails.CanceledTripSegmentModal'

export function getSegmentInfo(type: SegmentType): { title: string; description: string | null } {
  const title = getTitle(type)
  const description = getDescription(type)

  return {
    title,
    description,
  }
}

function getTitle(type: SegmentType): string {
  switch (type) {
    case SegmentType.Flight:
      return i18n.t(`${i18nBase}.FlightTitle`)
    default:
      return i18n.t(`${i18nBase}.DefaultTitle`)
  }
}

function getDescription(type: SegmentType): string | null {
  switch (type) {
    case SegmentType.Flight:
      return i18n.t(`${i18nBase}.FlightDescription`)
    default:
      return null
  }
}
