export default function BaggageIconPWA() {
  return (
    <svg viewBox="0 0 44 45" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8477 14.1758V15.3945H27.0352V14.1758C27.0352 13.6953 26.7188 13.3906 26.168 13.3906H18.7148C18.1758 13.3906 17.8477 13.6953 17.8477 14.1758ZM22.4414 22.6016C17.7891 22.6016 13.2422 21.8398 9 20.0586V19.2734C9 16.7305 10.3594 15.3945 12.9141 15.3945H15.1641V14.082C15.1641 12.125 16.4062 11 18.5391 11H26.3438C28.4766 11 29.7188 12.125 29.7188 14.082V15.3945H31.9688C34.5352 15.3945 35.8828 16.7305 35.8828 19.2734V20.0586C31.6406 21.8398 27.0938 22.6016 22.4414 22.6016ZM12.9141 34.6133C10.3594 34.6133 9 33.2773 9 30.7344V22.5195C12.668 23.8555 15.8438 24.4648 19.0898 24.7109V25.625C19.0898 26.5625 19.6289 27.0781 20.6133 27.0781H24.2695C25.2539 27.0781 25.793 26.5625 25.793 25.625V24.7109C29.0273 24.4648 32.2031 23.8555 35.8828 22.5195V30.7344C35.8828 33.2773 34.5352 34.6133 31.9688 34.6133H12.9141Z"
        fill="currentColor"
      />
    </svg>
  )
}
