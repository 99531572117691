import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RefCodeSection } from '@etta/screens/trip-details/trip-details-styled'
import { CopyableWrapper } from '@etta/ui/copyable-value'

type Props = {
  tKey: string
  tVal: string
  reference: string
  showSeparator?: boolean
}

const i18Base = 'PostBooking.TripDetails'

export const RefCode = ({ tKey, tVal, reference, showSeparator = true }: Props) => {
  const { t } = useTranslation()
  const title = useMemo(() => t(`${i18Base}.${tKey}`, { [tVal]: reference }), [
    t,
    tKey,
    tVal,
    reference,
  ])

  return (
    <CopyableWrapper value={reference}>
      <RefCodeSection
        role="heading"
        aria-level={2}
        aria-label={title}
        data-tracking-id="ref-code-text"
        showSeparator={showSeparator}>
        {title}
      </RefCodeSection>
    </CopyableWrapper>
  )
}
