import { Inject, Store } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { MapProvider } from '@etta/modules/display-configuration/core/enums'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'

@Store()
export class EttaMapStore {
  isLoaded = false
  isLoading = false
  isError = false
  mapToken: string | null = null

  constructor(
    @Inject() private displayConfigurationStore: DisplayConfigurationStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  get isAppleMap() {
    return (
      this.displayConfigurationStore.mapProvider === MapProvider.AppleMapkit &&
      this.featureFlagsStore.flags.isAppleMapProviderEnabled
    )
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setMapToken(mapToken: string | null) {
    this.mapToken = mapToken
  }

  setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded
  }
}
