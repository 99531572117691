import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 279px;
  height: 426px;
  padding-bottom: 10px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 3;
`

export const Header = styled.div`
  padding: 12px 16px;
`

export const Label = styled.div`
  margin-bottom: 2px;
`

export const List = styled.div`
  overflow-y: scroll;
`

export const Option = styled.div`
  padding: 12px 16px;
  cursor: pointer;
`
