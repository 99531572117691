import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'
import { getBackgroundColor } from './get-background-color'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const Header = styled.div``

export const Content = styled.div<{
  backgroundColor?: 'transparent' | keyof ColorPalette
  maxWidth?: number
  isFullWidth?: boolean
}>`
  position: relative;
  width: 100%;
  ${({ maxWidth, isFullWidth }) => {
    if (isFullWidth) {
      return 'max-width: 100%'
    }
    const widthValue = maxWidth ? `${maxWidth}px` : '1200px'
    return `max-width: ${widthValue}`
  }};
  flex-grow: 1;
  margin: 0 auto;
  background-color: ${(p) => getBackgroundColor(p.theme, p.backgroundColor)};
`

export const Footer = styled.div``
