import { Store, Inject } from '@etta/di'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import {
  updateDisplayConfiguration,
  updateDisplayConfigLoading,
} from '@fiji/store/display-configuration'
import { PRIVACY_POLICY_DOCUMENT_URL } from '@fiji/constants'
import type { DisplayConfigurationValueObject } from '../../core/value-objects'
import type { ServiceFeeConfigurationValueObject } from '../../core/value-objects/service-fee-configuration.value-object'
import { MapProvider, FlightFareType, FlightSort, ServiceFeeType } from '../../core/enums'

type GetServiceFeesReduceResult = {
  airFares: ServiceFeeConfigurationValueObject[]
  hotelFares: ServiceFeeConfigurationValueObject[]
  carRentalFares: ServiceFeeConfigurationValueObject[]
  airWebFares: ServiceFeeConfigurationValueObject[]
  transactionFee: ServiceFeeConfigurationValueObject[]
  rail: ServiceFeeConfigurationValueObject[]
}

@Store()
export class DisplayConfigurationStore {
  isLoading: boolean = false

  displayConfiguration: DisplayConfigurationValueObject = {
    isAdminRoleEnabled: false,
    isMod2FlowEnabled: false,
    isFlightSearchServiceEnabled: false,
    isPhoenixHotelSearchEnabled: undefined,
    isPhoenixCarRentalSearchEnabled: undefined,
    isTripAdvisorReviewsEnabled: false,
    maxNumberOfGuestsPerRoom: 1,
    mapProvider: MapProvider.GoogleMaps,
    isPerDiemDisplayEnabled: true,
    isStarRatingsFilterEnabled: true,
    isExpiringCreditCardForHotelAllowed: true,
    isCreditCardSavingEnabled: true,
    isAppleLockSettingsEnabled: false,
    appleDsmMessage: undefined,
    enableNearByAirport: false,
    bookAndChangeAdvanceTime: 4,
    geosureReport: {
      enabled: false,
      variant: 'scores',
    },
    optinoutOfUnusedTicket: false,
    hotelSearchRadius: {
      default: 5,
      max: 25,
    },
    isHoldTripAllowed: false,
    carRentals: {
      areDeliveryAndCollectionSupported: false,
      requireCarPaymentForm: false,
    },
    hotels: {
      isStarRatingEnabled: true,
    },
    flights: {
      isOutOfPolicyFlightsSelectable: false,
      areNearbyAirportsIncluded: false,
      defaultSort: FlightSort.PolicyOrPrefered,
      fares: {
        areRefundableEnabled: false,
        areUnrestrictedEnabled: false,
        defaultFareType: FlightFareType.All,
      },
      isAnytimeEnabled: false,
      isBusinessClassEnabled: false,
      isEconomyClassEnabled: false,
      isFirstClassEnabled: false,
      isMorningAfternoonEveningEnabled: false,
      isPremiumEconomyClassEnabled: false,
      businessClassCustomLabel: undefined,
      economyClassCustomLabel: undefined,
      firstClassCustomLabel: undefined,
      premiumEconomyClassCustomLabel: undefined,
      isDefaultAnyTime: false,
      defaultAirTravelType: undefined,
    },
    explore: {
      isCarRentalsSearchEnabled: false,
      isFlightsSearchEnabled: false,
      isHotelsSearchEnabled: false,
      isTrainsSearchEnabled: false,
      searchDefaultsAirEnabled: false,
      searchDefaultsHotelEnabled: false,
      searchDefaultsCarEnabled: false,
      message: undefined,
      messages: [],
    },

    privacyPolicy: {
      url: '',
      label: '',
    },
    isPreBookCostAllocationEnabled: false,
    support: {
      mobile: {
        phoneNumbers: [],
      },
      desktop: {
        email: '',
      },
      technicalSupport: {
        isEnabled: false,
      },
    },
    hideUnusedTickets: false,
    isMod2DoubleWriteEnabled: false,
    isShowTravelerAcknowledgement: false,
    isSouthwestDirectConnectionEnabled: false,
    externalLinks: {
      isExternalLinksEnabled: false,
      links: [],
    },
    companyResourceConfiguration: {
      id: null,
      descriptionMessage: '',
      resourceTexts: [],
    },
    selfDelegation: false,
    userDelegation: false,
    serviceFeeConfiguration: [],
  }

  constructor(
    @Inject()
    private readonly fijiStoreProxy: FijiStoreProxyService,
  ) {}

  get isMod2FlowEnabled() {
    return this.displayConfiguration.isMod2FlowEnabled
  }

  get isMod2DoubleWriteEnabled() {
    return this.displayConfiguration.isMod2DoubleWriteEnabled
  }

  get mapProvider() {
    return this.displayConfiguration.mapProvider
  }

  get isHoldTripAllowed() {
    return this.displayConfiguration.isHoldTripAllowed
  }

  get isPhoenixCarRentalSearchEnabled() {
    return this.displayConfiguration.isPhoenixCarRentalSearchEnabled
  }

  get hotelSearchRadius() {
    return this.displayConfiguration.hotelSearchRadius
  }

  get flightConfigurations() {
    return this.displayConfiguration.flights
  }

  get flightServiceClassCustomLabels() {
    const {
      economyClassCustomLabel,
      premiumEconomyClassCustomLabel,
      businessClassCustomLabel,
      firstClassCustomLabel,
    } = this.displayConfiguration.flights

    return {
      COACH: economyClassCustomLabel,
      PREMIUM_COACH: premiumEconomyClassCustomLabel,
      BUSINESS: businessClassCustomLabel,
      FIRST: firstClassCustomLabel,
    }
  }

  get privacyPolicy() {
    const { url, label } = this.displayConfiguration.privacyPolicy

    return {
      url: url || PRIVACY_POLICY_DOCUMENT_URL,
      label,
    }
  }

  get isHotelSearchServiceEnabled() {
    return this.displayConfiguration.isPhoenixHotelSearchEnabled
  }
  get isPreBookCostAllocationEnabled() {
    return this.displayConfiguration.isPreBookCostAllocationEnabled
  }

  get bookAndChangeAdvanceTime() {
    return this.displayConfiguration.bookAndChangeAdvanceTime
  }

  get companyResoursesConfigurationDescriptionMessage() {
    return this.displayConfiguration.companyResourceConfiguration?.descriptionMessage
  }

  get companyResoursesConfigurationLinks() {
    return this.displayConfiguration.companyResourceConfiguration?.resourceTexts
  }

  get isShowCompanyResourses() {
    const isLinksExists =
      this.companyResoursesConfigurationLinks &&
      Array.isArray(this.companyResoursesConfigurationLinks) &&
      this.companyResoursesConfigurationLinks.length > 0
    const isDescriptionMessageExists =
      this.companyResoursesConfigurationDescriptionMessage &&
      this.companyResoursesConfigurationDescriptionMessage !== ''

    return isLinksExists || isDescriptionMessageExists
  }

  get getServiceFeeConfiguration() {
    const { serviceFeeConfiguration } = this.displayConfiguration

    const {
      airFares,
      hotelFares,
      carRentalFares,
      airWebFares,
      transactionFee,
      rail,
    } = serviceFeeConfiguration.reduce<GetServiceFeesReduceResult>(
      (acc, serviceFee) => {
        switch (serviceFee.type) {
          case ServiceFeeType.Flight:
            acc.airFares.push(serviceFee)
            break

          case ServiceFeeType.Hotel:
            acc.hotelFares.push(serviceFee)
            break

          case ServiceFeeType.CarRental:
            acc.carRentalFares.push(serviceFee)
            break

          case ServiceFeeType.AirWebFare:
            acc.airWebFares.push(serviceFee)
            break

          case ServiceFeeType.TransactionFee:
            acc.transactionFee.push(serviceFee)
            break

          case ServiceFeeType.Rail:
            acc.rail.push(serviceFee)
            break

          default:
            return acc
        }

        return acc
      },
      {
        airFares: [],
        hotelFares: [],
        carRentalFares: [],
        airWebFares: [],
        transactionFee: [],
        rail: [],
      },
    )

    return {
      airFares,
      hotelFares,
      carRentalFares,
      airWebFares,
      transactionFee,
      rail,
    }
  }

  setIsLoading(value: boolean) {
    this.isLoading = value

    // TODO: remove after migration
    this.fijiStoreProxy.dispatch(
      updateDisplayConfigLoading({
        displayConfigLoading: value,
      }),
    )
  }

  setDisplayConfiguration(displayConfiguration: DisplayConfigurationValueObject) {
    this.displayConfiguration = displayConfiguration
    // TODO: remove after migration
    this.fijiStoreProxy.dispatch(
      updateDisplayConfiguration({
        displayConfigLoading: false,
        ...displayConfiguration,
        isSiteSpinnerVisible: false,
        isSiteMessagesVisible: true,
        isMapVisible: true,
        isMobileMapVisible: false,
        isFooterVisible: true,
      }),
    )
  }
}
