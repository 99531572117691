import styled from 'styled-components'

export const IconWrap = styled.div`
  margin-right: 22px;
`
export const ButtonIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 10px;
`

export const ButtonWrap = styled.div`
  &:first-child {
    margin-bottom: 2px;
  }
`
