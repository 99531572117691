import { useMemo } from 'react'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity'
import { useResultAdaptiveFlight } from '@fiji/hooks/use-adaptive-flight'
import { useFareSeatMaps } from '@etta/screens/review-trip-page/flight-details-modal/fare-seat-maps/use-fare-seat-maps'

type Args = {
  itineraryId: string
  bookingId: string
  segments: FlightSegmentEntity[]
  chosenAirSeatMapLegId: string
  onClose: () => void
  onSeatsChanged: () => void
}

export function useAirSeatMapModal({
  itineraryId,
  bookingId,
  segments,
  chosenAirSeatMapLegId,
  onClose,
  onSeatsChanged,
}: Args) {
  const chosenSegment = useMemo(() => {
    return segments.find((segment) => segment.legId === chosenAirSeatMapLegId)
  }, [segments, chosenAirSeatMapLegId])
  const adaptiveFlight = useResultAdaptiveFlight(chosenSegment || segments[0])

  const {
    displayServiceClass,
    isLoading,
    fareSegmentSeatRows,
    retryFetchSeatMap,
    selectedSegmentId,
    onSelectedSegmentIdChange,
    onSeatsApprove,
    initialSegmentSeatNumberList,
    seatStateStatus,
    resetSeatStateStatus,
  } = useFareSeatMaps({
    itineraryId,
    bookingId,
    segments: chosenSegment?.segments,
    adaptiveFlight: adaptiveFlight.flight,
    onClose,
    onSeatsChanged,
  })

  return {
    displayServiceClass,
    isLoading,
    fareSegmentSeatRows,
    retryFetchSeatMap,
    selectedSegmentId,
    onSelectedSegmentIdChange,
    onSeatsApprove,
    initialSegmentSeatNumberList,
    seatStateStatus,
    resetSeatStateStatus,
    adaptiveFlight,
  }
}
