import { useTranslation } from 'react-i18next'
import { Search } from '@etta/ui/search'
import { DatePickerModal as DatePickerModalNew } from '@etta/ui/date-picker-modal-temp'
import { RailTimeRangePicker } from '@etta/components/rail-time-range-picker'
import type { Props } from '../../types'
import { useCalendarTimeSection } from '../../use-calendar-time-section'
import { SectionWrapper } from './calendar-time-section-desktop-styled'

const i18Base = 'TripPlanner.BaseSearch'

export function CalendarTimeSectionDesktop({
  railTripType,
  originPlace,
  destinationPlace,
  originDate,
  destinationDate,
  originTime,
  destinationTime,
  isDateRangeValid,
}: Props) {
  const { t } = useTranslation()
  const {
    departDateToggle,
    returnDateToggle,
    departTimeToggle,
    returnTimeToggle,
    isRoundTrip,
    originDateString,
    destinationDateString,
    originTimeString,
    originTimeLabel,
    destinationTimeString,
    destinationTimeLabel,
    onOriginTimeChange,
    onDestinationTimeChange,
    onDatesSelect,
  } = useCalendarTimeSection({
    railTripType,
    originDate,
    destinationDate,
    originTime,
    destinationTime,
    isShortCustomTime: true,
  })

  return (
    <>
      <SectionWrapper>
        <Search.Group>
          <Search.Element
            wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
              label: t(`${i18Base}.Input.DepartOnDate`),
              value: originDateString,
            })}
            labelTitle={t(`${i18Base}.Input.DepartOnDate`)}
            placeholder={t(i18Base + '.Input.Select')}
            value={originDateString}
            onClick={departDateToggle.handleOpen}
            contentSlot={
              <DatePickerModalNew
                date={originDate}
                dateEnd={isDateRangeValid && isRoundTrip ? destinationDate : null}
                isRange={isRoundTrip}
                isEndDateSame
                minDate={new Date()}
                startTitle={t(`${i18Base}.Input.Start`)}
                endTitle={t(`${i18Base}.Input.End`)}
                onSelect={onDatesSelect}
                isOpen={departDateToggle.isOpen}
                onClose={departDateToggle.handleClose}
              />
            }
          />
          <Search.Element
            wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
              label: originTimeLabel,
              value: originTimeString,
            })}
            isTextAlignRight
            labelTitle={originTimeLabel}
            value={originTimeString}
            onClick={departTimeToggle.handleOpen}
            contentSlot={
              <RailTimeRangePicker
                isVisible={departTimeToggle.isOpen}
                onClose={departTimeToggle.handleClose}
                onSubmit={onOriginTimeChange}
                originPlace={originPlace}
                destinationPlace={destinationPlace}
                selectedTime={originTime}
                date={originDate}
              />
            }
          />
        </Search.Group>
      </SectionWrapper>

      {isRoundTrip && (
        <SectionWrapper>
          <Search.Group>
            <Search.Element
              wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
                label: t(`${i18Base}.Input.ReturnOn`),
                value: destinationDateString,
              })}
              labelTitle={t(`${i18Base}.Input.ReturnOn`)}
              placeholder={t(i18Base + '.Input.Select')}
              value={destinationDateString}
              onClick={returnDateToggle.handleOpen}
              contentSlot={
                <DatePickerModalNew
                  isReturnDate
                  date={originDate}
                  dateEnd={isDateRangeValid && isRoundTrip ? destinationDate : null}
                  isRange={isRoundTrip}
                  isEndDateSame
                  minDate={new Date()}
                  startTitle={t(`${i18Base}.Input.Start`)}
                  endTitle={t(`${i18Base}.Input.End`)}
                  onSelect={onDatesSelect}
                  isOpen={returnDateToggle.isOpen}
                  onClose={returnDateToggle.handleClose}
                />
              }
            />
            <Search.Element
              wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
                label: destinationTimeLabel,
                value: destinationTimeString,
              })}
              isTextAlignRight
              labelTitle={destinationTimeLabel}
              value={destinationTimeString}
              onClick={returnTimeToggle.handleOpen}
              contentSlot={
                <RailTimeRangePicker
                  isVisible={returnTimeToggle.isOpen}
                  onClose={returnTimeToggle.handleClose}
                  onSubmit={onDestinationTimeChange}
                  originPlace={destinationPlace}
                  selectedTime={destinationTime}
                  date={destinationDate}
                />
              }
            />
          </Search.Group>
        </SectionWrapper>
      )}
    </>
  )
}
