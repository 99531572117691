import type { FlightSafetyCheckDetails } from '@fiji/types'
import { InfoModal } from '../../../info-modal'
import { FlightSafetyCheckModalLayout } from './layout'

type Props = {
  isVisible: boolean
  onClose: () => void
  flightSafetyCheckDetails?: FlightSafetyCheckDetails[]
}

export function FlightSafetyCheckModal({ isVisible, onClose, flightSafetyCheckDetails }: Props) {
  return (
    <InfoModal isVisible={isVisible} onClose={onClose}>
      <FlightSafetyCheckModalLayout
        onClose={onClose}
        flightSafetyCheckDetails={flightSafetyCheckDetails}
      />
    </InfoModal>
  )
}
