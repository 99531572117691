import { forwardRef } from 'react'
import { ButtonWrapper, LeftIconWrapper, RightIconWrapper, TextWrapper } from './button-components'
import type { ButtonProps } from './button-props'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function (
  {
    variant = 'solid',
    btnType = 'primary',
    size = 'normal',
    fullWidth = false,
    disabled = false,
    textOverflow = 'ellipsis',
    paddings = true,
    minWidth,
    children,
    leftIcon,
    rightIcon,
    isSubmit,
    ...rest
  },
  ref,
) {
  return (
    <ButtonWrapper
      ref={ref}
      type={isSubmit ? 'submit' : 'button'}
      btnType={btnType}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      minWidth={minWidth}
      disabled={disabled}
      paddings={paddings}
      aria-disabled={disabled}
      {...rest}>
      {leftIcon && <LeftIconWrapper>{leftIcon}</LeftIconWrapper>}
      <TextWrapper textOverflow={textOverflow}>{children}</TextWrapper>
      {rightIcon && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
    </ButtonWrapper>
  )
})
