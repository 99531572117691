import styled, { css } from 'styled-components'
import { UnstyledButton } from '@fiji/style'

const containerStyles = css`
  display: flex;
  align-items: center;
`

const containerStylesWithHoldButton = css`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div<{ isHoldTripAllowed: boolean }>`
  background-color: ${(props) => props.theme.colors.background2};
  padding: 10px 16px;
  ${({ isHoldTripAllowed }) =>
    isHoldTripAllowed ? containerStylesWithHoldButton : containerStyles}
`

export const Label = styled.div`
  display: flex;
  align-items: center;
`

const wrapperStyles = css`
  margin-right: auto;
`

const wrapperStylesWithHoldButton = css`
  display: flex;
  justify-content: space-between;
`

export const Wrapper = styled(UnstyledButton)<{ isHoldTripAllowed: boolean }>`
  ${({ isHoldTripAllowed }) => (isHoldTripAllowed ? wrapperStylesWithHoldButton : wrapperStyles)}
`

export const PriceChangeBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.colors.bodyText1};
  padding: 0 2px 0 6px;
  border-radius: 4px;
  margin-left: 3px;
`

export const PriceChangedIconContainer = styled.div<{ isCostChangePositive?: boolean | null }>`
  transform: ${(props) => {
    if (!props.isCostChangePositive) {
      return 'rotate(180deg)'
    }

    return 'initial'
  }};
`

const buttonContainerStyles = css`
  max-width: 170px;
  margin-left: 10px;
`
const buttonContainerStylesWithHoldButton = css`
  display: flex;
  gap: 12px;
  margin-top: 10px;
`

export const ButtonContainer = styled.div<{ isHoldTripAllowed: boolean }>`
  flex: 1;
  ${({ isHoldTripAllowed }) =>
    isHoldTripAllowed ? buttonContainerStylesWithHoldButton : buttonContainerStyles}
`
