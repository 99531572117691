import { EmissionsDeviationLevel } from '@fiji/enums'
import { EcoCheckBackground } from './hotel-details-eco-check-desktop-styled'
import EcoCheckLess from './assets/eco-check-less.svg?url'
import EcoCheckMore from './assets/eco-check-more.svg?url'
import EcoCheckAverage from './assets/eco-check-average.svg?url'

export function EcoCheckDesktopBackground({
  deviationLevel,
}: {
  deviationLevel: EmissionsDeviationLevel
}) {
  switch (deviationLevel) {
    case EmissionsDeviationLevel.Less:
      return <EcoCheckBackground src={EcoCheckLess} />
    case EmissionsDeviationLevel.More:
      return <EcoCheckBackground src={EcoCheckMore} />
    case EmissionsDeviationLevel.Average:
      return <EcoCheckBackground src={EcoCheckAverage} />
    default:
      return null
  }
}
