import { useAppSelector } from '@fiji/store'
import { featureFlagsSelector } from '@fiji/store/feature-flags'
import { userSelector } from '@fiji/store/user'

export function useShowDelegateName() {
  const {
    values: { isDelegatedFlowEnabled },
  } = useAppSelector(featureFlagsSelector)
  const { delegatedId } = useAppSelector(userSelector)

  return {
    isDelegatedFlowEnabled,
    isShowDelegateName: isDelegatedFlowEnabled && !!delegatedId,
  }
}
