import { useLogOut } from '@fiji/hooks/use-log-out'
import { getTokenExpirationDateFromCookie } from '@fiji/utils/get-cookie-expiration-date'
import { useCountdownTimer } from './use-countdown-timer'

type Args = {
  isOpen: boolean
  handleClose: () => void
}

export function useSessionModal({ isOpen, handleClose }: Args) {
  const tokenExpirationDate = getTokenExpirationDateFromCookie()
  const { countdownTimer, isTimerExpired } = useCountdownTimer({
    date: tokenExpirationDate,
    shouldTimerBeActive: isOpen,
    handleClose,
  })

  const { onLogout } = useLogOut({ isSessionExpired: isTimerExpired })

  return {
    countdownTimer,
    isTimerExpired,
    onLogout,
  }
}
