import { useCallback, useMemo } from 'react'
import { TripType } from '@fiji/graphql/hooks'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useBookTripContext } from '@etta/modules/booking'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries'
import { useAppDispatch } from '@fiji/store'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { ROUTES } from '@fiji/routes'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { PostBookingAction } from '@fiji/enums'
import { updateHotelId } from '@fiji/store/segment-ids'
import type { ViewState } from '@etta/ui/view-state-modal'
import { useQueryParams } from '@fiji/hooks/use-query'
import type { Actions } from '../../types'
import type { FailedBookingActions, OnChangeHotel } from './types'
import { resolveHotelLocation } from './utils/resolve-hotel-address'
import { useTripDropActions } from './use-trip-drop-actions'

type Args = {
  onDone: () => void
  onAnotherTrip: () => void
  onReset: () => void
  onShareTripsDetail: () => void
  onChangeViewState: (viewState: ViewState) => void
}

export function useTripActions({
  onAnotherTrip,
  onDone,
  onReset,
  onShareTripsDetail,
  onChangeViewState,
}: Args) {
  const { reviewTripStore } = useReviewTripContext()
  const { bookTripActions, bookTripStore } = useBookTripContext()
  const {
    reactivateTripActions: { handleReactivateTripById },
  } = useReviewTripContext()
  const { appendQueryParams } = useQueryParams<{ bookingId?: string }>()
  const { bookedProcessId } = bookTripStore
  const { tripId } = reviewTripStore
  const { postBookingTripPreviewActions } = usePostBookingContext()
  const { dropTripAndPostBooking } = useTripDropActions()
  const dispatch = useAppDispatch()
  const { navigateTo } = useHotelSearchQuery()

  const handleReactivateItinerary = useCallback(async () => {
    const bookingId = bookTripStore.bookedProcessId ?? ''
    onChangeViewState('loading')
    const isReactivateSuccess = await handleReactivateTripById(bookingId)
    if (!isReactivateSuccess) {
      onChangeViewState('error')
    }
    appendQueryParams({ bookingId })
    onChangeViewState('hidden')
  }, [
    bookTripStore.bookedProcessId,
    onChangeViewState,
    handleReactivateTripById,
    appendQueryParams,
  ])

  const handleSeeUpcomingTrips = useCallback(() => {
    postBookingTripPreviewActions.updateTripType(TripType.Upcoming)
    onDone()
    dropTripAndPostBooking()
  }, [dropTripAndPostBooking, onDone, postBookingTripPreviewActions])

  const onSeeHeldTrips = useCallback(() => {
    postBookingTripPreviewActions.updateTripType(TripType.OnHold)
    onDone()
    dropTripAndPostBooking()
  }, [dropTripAndPostBooking, onDone, postBookingTripPreviewActions])

  const handleBookAnotherTripClick = useCallback(() => {
    onAnotherTrip()
    dropTripAndPostBooking()
  }, [dropTripAndPostBooking, onAnotherTrip])

  const handleChangeHotel: OnChangeHotel = useCallback(
    (hotel) => {
      dispatch(updateHotelId({ hotelId: hotel.hotelId }))
      dispatch(updatePostPurchaseEntrance('fromTripDetails'))
      navigateTo(ROUTES.hotel.search, {
        itineraryId: tripId,
        checkInDate: hotel.checkIn ? new Date(getDateWithoutTimezoneOffset(hotel.checkIn)) : null,
        checkOutDate: hotel.checkOut
          ? new Date(getDateWithoutTimezoneOffset(hotel.checkOut))
          : null,
        location: resolveHotelLocation(hotel.address),
        bookingId: bookedProcessId || '',
        postBookingAction: PostBookingAction.Modify,
      })
    },
    [bookedProcessId, dispatch, navigateTo, tripId],
  )

  const handleStartNewHotelSearch: OnChangeHotel = useCallback(
    (hotel) => {
      onReset()
      navigateTo(ROUTES.hotel.search, {
        checkInDate: hotel.checkIn ? new Date(getDateWithoutTimezoneOffset(hotel.checkIn)) : null,
        checkOutDate: hotel.checkOut
          ? new Date(getDateWithoutTimezoneOffset(hotel.checkOut))
          : null,
        location: resolveHotelLocation(hotel.address),
      })
    },
    [navigateTo, onReset],
  )

  const handleChangeCreditCard = useCallback(async () => {
    await handleReactivateItinerary()
    bookTripActions.handleResetBookedStatus()
  }, [bookTripActions, handleReactivateItinerary])

  const handleTryAgain = useCallback(async () => {
    await handleReactivateItinerary()
    bookTripActions.handleRetryBooking()
  }, [bookTripActions, handleReactivateItinerary])

  const actions: Actions = useMemo(() => {
    return {
      onBookAnotherTripClick: handleBookAnotherTripClick,
      onSeeUpcomingTrips: handleSeeUpcomingTrips,
      onShareClick: onShareTripsDetail,
      onSeeHeldTrips: onSeeHeldTrips,
    }
  }, [handleBookAnotherTripClick, handleSeeUpcomingTrips, onSeeHeldTrips, onShareTripsDetail])

  const failedBookingActions: FailedBookingActions = useMemo(() => {
    return {
      onStartNewHotelSearch: handleStartNewHotelSearch,
      onStartOver: handleBookAnotherTripClick,
      onChangeHotel: handleChangeHotel,
      onChangeCreditCard: handleChangeCreditCard,
      onTryAgain: handleTryAgain,
    }
  }, [
    handleStartNewHotelSearch,
    handleBookAnotherTripClick,
    handleChangeHotel,
    handleChangeCreditCard,
    handleTryAgain,
  ])

  return { actions, failedBookingActions }
}
