import { Result } from 'fnscript'
import { Inject, Service } from '@etta/di'
import { CartAdapter, ItineraryAdapter } from '@etta/modules/review-trip/infra'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { Domain } from '@etta/core/enums'
import type { AddRoomToCartArgs, RemoveRoomArgs, ReplaceRoomArgs, SelectRoomArgs } from '../types'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import { SegmentTypeCheckService } from './segment-type-check.service'

@Service()
export class ReviewTripHotelService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  selectRoom({
    itineraryId,
    hotelResultId,
    hotelId,
    roomKey,
    checkIn,
    checkOut,
    latitude,
    longitude,
  }: SelectRoomArgs) {
    return this.itineraryAdapter.selectRoom({
      itineraryId,
      hotelResultId,
      hotelId,
      roomKey,
      checkIn,
      checkOut,
      latitude,
      longitude,
    })
  }

  addRoomToCart({ inventoryId, hotelResultId, cartId }: AddRoomToCartArgs) {
    const isAllFieldsValid = hotelResultId && inventoryId

    if (!isAllFieldsValid) {
      return Promise.resolve(Result.Err({ message: 'Invalid arguments' }))
    }

    return this.cartAdapter.addToCart({
      cartId,
      searchId: hotelResultId,
      intentDomain: Domain.Hotel,
      inventoryId: inventoryId,
    })
  }

  replaceRoom({
    itineraryId,
    hotelResultId,
    hotelId,
    newRoomKey,
    oldRoomKey,
    checkIn,
    checkOut,
    latitude,
    longitude,
  }: ReplaceRoomArgs) {
    return this.itineraryAdapter.replaceRoom({
      itineraryId,
      hotelResultId,
      hotelId,
      newRoomKey,
      oldRoomKey,
      checkIn,
      checkOut,
      latitude,
      longitude,
    })
  }

  async removeRoom({ itineraryId, roomKey }: RemoveRoomArgs) {
    const isMod2FlowEnabled = this.displayConfigurationStore.isMod2FlowEnabled

    if (isMod2FlowEnabled) {
      const hotel = this.reviewTripStore.trip?.segments.find((segment) => {
        if (this.segmentTypeCheckService.isHotelSegment(segment)) {
          return segment.id === roomKey
        }
      })

      const intentId = hotel?.uuid
      const tripId = this.reviewTripStore.tripId

      const result = await this.cartAdapter.removeFromCart({ intentId, cartId: tripId })

      return result
    }

    return this.itineraryAdapter.removeRoom({
      itineraryId,
      roomKey,
    })
  }
}
