import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import type { Props } from '../../types'
import { HeaderWrapper } from './unused-tickets-modal-header-desktop-styled'

const i18nBase = 'UnusedTickets'
const i18nBaseAria = 'Accessibility.Settings'

export function UnusedTicketsModalHeaderDesktop({ isWideSettingsModal, onClose }: Props) {
  const { t } = useTranslation()
  return (
    <Modal.Header withBorder>
      <HeaderWrapper isWideSettingsModal={isWideSettingsModal}>
        <Text aria-label={t(`${i18nBase}.Headline`)} variant="title3" color="mainText">
          {t(`${i18nBase}.Headline`)}
        </Text>
        <IconButton
          icon="closeFilledPWA"
          size="medium"
          color="mainText"
          onClick={onClose}
          aria-label={t(`${i18nBaseAria}.CloseButton`)}
        />
      </HeaderWrapper>
    </Modal.Header>
  )
}
