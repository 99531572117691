import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import type { Props } from './types'
import { Container, Details } from './rail-permitted-stations-content.styled'

const i18Base = 'RailPermittedStations.'

export const RailPermittedStationsContent = observer(function RailPermittedStationsContent({
  permittedOriginStations,
  permittedDestinationStations,
  originStation,
  destinationStation,
  mainStationsTitle,
  fromModal,
  onClick,
}: Props) {
  const { t } = useTranslation()
  const hasPermittedOriginStations = permittedOriginStations.length > 0
  const hasPermittedDestinationStations = permittedDestinationStations.length > 0
  const buildTitle = (): string => {
    if (hasPermittedOriginStations && hasPermittedDestinationStations) {
      return t(`${i18Base}originDestinationTitle`, { originStation, destinationStation })
    }
    if (hasPermittedOriginStations) {
      return t(`${i18Base}originOnlyTitle`, { originStation })
    }
    if (hasPermittedDestinationStations) {
      return t(`${i18Base}destinationOnlyTitle`, { destinationStation })
    }
    return ''
  }

  return (
    <Container fromModal={fromModal}>
      {mainStationsTitle && (
        <Block paddingVertical={20} paddingHorizontal={20}>
          <Text variant="footnoteMedium" color="bodyText">
            {buildTitle()}
          </Text>
        </Block>
      )}
      {hasPermittedOriginStations && (
        <Details fromModal={fromModal}>
          <Text
            variant="footnoteStrong"
            color="mainText"
            data-tracking-id="permitted-origin-stations-header">
            {t(`${i18Base}originHeader`)}
          </Text>
          {permittedOriginStations.map((station) => (
            <Text variant="footnoteMedium" color="bodyText" key={station.code}>
              {station.description}
            </Text>
          ))}
        </Details>
      )}
      {hasPermittedDestinationStations && (
        <Details fromModal={fromModal}>
          <Text
            variant="footnoteStrong"
            color="mainText"
            data-tracking-id="permitted-destination-stations-header">
            {t(`${i18Base}destinationHeader`)}
          </Text>
          {permittedDestinationStations.map((station) => (
            <Text variant="footnoteMedium" color="bodyText" key={station.code}>
              {station.description}
            </Text>
          ))}
        </Details>
      )}
      {onClick && (
        <Details fromModal={fromModal}>
          <Link onClick={onClick} size="small" data-tracking-id="show-ticket-restrictions">
            {t(`${i18Base}ticketRestrictions`)}
          </Link>
        </Details>
      )}
    </Container>
  )
})
