import { observer } from 'mobx-react-lite'
import { NoModifySegment } from '@etta/components/no-modify-segment/no-modify-segment'
import { SegmentType } from '@etta/core/enums'
import { SectionDate } from '../date-segments-styled'
import { SegmentCard } from '../segment-card'
import { useHotelSegment } from './use-hotel-segment'
import type { LayoutProps } from './types'

export const HotelSegment = observer(function HotelSegment({
  segment,
  isUpcoming,
  isOnHold,
  itineraryId,
  bookingId,
  isTripInProgress,
  onClick,
  onCancel,
}: LayoutProps) {
  const {
    ariaLabel,
    nights,
    title,
    dataTexts,
    buttons,
    isNoModifyHotelOpen,
    typeTitle,
    handleShare,
    handleNoModifyHotelClose,
    addressLabel,
    hotelStatus,
    cardTitleDates,
    cardTitle,
    checkInAndOutLabel,
    segmentPreview,
    cancellationPolicyLabel,
    handleOpenRateAndCancellationDetails,
    shouldDisplayManageBookingMenu,
  } = useHotelSegment({
    segment,
    onCancel,
    itineraryId,
    bookingId,
    isUpcoming,
    isTripInProgress,
    isOnHold,
    onClick,
  })

  return (
    <>
      <SegmentCard
        // For Hotel Segment, isPast prop is only be used to display/not display the manage booking button
        // isPast = false will display the menus
        isPast={!shouldDisplayManageBookingMenu}
        isUpcoming={isUpcoming}
        ariaLabel={ariaLabel}
        titleSlot={title}
        segmentIcon="hotelPWA"
        onClick={onClick}
        dataTexts={dataTexts}
        buttons={buttons}
        dateSlot={segment.isCheckIn && nights && <SectionDate isDisabled>{nights}</SectionDate>}
        onShare={handleShare}
        label={addressLabel}
        segmentStatus={hotelStatus}
        segmentType={SegmentType.Hotel}
        headerDate={cardTitleDates}
        cardTitle={cardTitle}
        cardSubTitle={nights}
        cardInfo={[addressLabel, checkInAndOutLabel]}
        segmentPreview={segmentPreview}
        cancellationPolicyLabel={cancellationPolicyLabel}
        handleOpenRateAndCancellationDetails={handleOpenRateAndCancellationDetails}
        isOnHold={isOnHold}
      />
      <NoModifySegment
        type={typeTitle}
        isOpen={isNoModifyHotelOpen}
        onClose={handleNoModifyHotelClose}
      />
    </>
  )
})
