import type { PreferenceOptions } from '@fiji/graphql/types'

export function getMockedRailMemberships(): PreferenceOptions[] {
  return [
    {
      label: 'Deutsche Bahn',
      value: '2A',
    },
    {
      label: 'Amtrak',
      value: '2V',
    },
    {
      label: 'Eurostar',
      value: '9F',
    },
    {
      label: 'Annual Gold Card',
      value: '4A',
    },
    {
      label: 'Cambrain Rail Card',
      value: '2B',
    },
    {
      label: 'Senior Railcard',
      value: '2C',
    },
    {
      label: 'Network Railcard',
      value: '3A',
    },
    {
      label: 'Two Together Railcard',
      value: '3B',
    },
    {
      label: 'Veterans Railcard',
      value: '3C',
    },
    {
      value: '7A',
      label: 'Dales Railcard',
    },
    {
      value: '4C',
      label: 'Esk Valley Railcard',
    },
  ]
}
