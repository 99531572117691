import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import type { CreditCardBillingAddressInput } from '@fiji/graphql/types'

type Args = {
  billingAddress?: CreditCardBillingAddressInput
}

export function useBillingAddressValidator({ billingAddress }: Args) {
  const { t } = useTranslation()
  const i18BaseErrors = 'CreditCardForm.Errors.'
  const billingAddressScheme = useScheme(() => {
    return {
      countryCode: yup
        .string()
        .required(t(i18BaseErrors + 'BillingAddress.Country'))
        .default(billingAddress?.countryCode),
      postalCode: yup
        .string()
        .required(t(i18BaseErrors + 'BillingAddress.PostalCode'))
        .default(billingAddress?.postalCode),
      stateCode: yup
        .string()
        .required(t(i18BaseErrors + 'BillingAddress.StateCode'))
        .default(billingAddress?.stateCode),
      city: yup
        .string()
        .required(t(i18BaseErrors + 'BillingAddress.City'))
        .default(billingAddress?.city),
      street1: yup
        .string()
        .required(t(i18BaseErrors + 'BillingAddress.Street1'))
        .default(billingAddress?.street1),
      street2: yup.string().optional().default(billingAddress?.street2),
    }
  }, [billingAddress])

  const billingAddressValidator = useValidation(billingAddressScheme)

  return { billingAddressValidator }
}
