import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import type { ExplorePageQueryType } from './types'

export function useExplorePageQuery() {
  const { queryParams } = useQueryParams<ExplorePageQueryType>({}, { caseStyle: 'kebab-case' })

  return {
    queryParams,
  }
}
