import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DelegateSwitchLayout } from '@fiji/enums'
import { DelegateSwitch } from '@etta/modules/delegate'
import { AlertModal, AlertNotificationButton } from '@etta/modules/alert'
import { useAlertContext } from '@etta/modules/alert/interface'
import { Block } from '@etta/ui/block'
import { Screen } from '@etta/ui/screen'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { LayoutProps } from '../../types'
import { TripTabs } from '../../trip-tabs'
import { useTripsMobile } from './use-trips-mobile'
import {
  HeldTripsButton,
  HeldTripsTitle,
  HeldTripIcon,
  Container,
  ContentContainer,
  NotificationButtonWrapper,
} from './trips-page-mobile-styled'

const i18Base = 'Trips'

export const TripsPageMobile = observer(function TripsPageMobile({
  tripListSlot,
  isDelegatedFlowEnabled,
  activeTab,
  handleChangeTab,
  handleHeldTripsRedirect,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    onChangeTab,
    updateRef,
    anchorRef,
    headerTitle,
    isDelegateSwitchEnabled,
    totalHeldTrips,
    heldTripsAriaLabel,
    isMobileHeldTripsFlowEnabled,
  } = useTripsMobile({
    handleChangeTab,
    isDelegatedFlowEnabled,
  })
  const { alertStore } = useAlertContext()
  const { alertsCount } = alertStore

  return (
    <Screen backgroundColor="background">
      <Container ref={updateRef}>
        <Screen.Header as={'header'} backgroundColor="background" isStatic>
          <Block
            paddingTop={40}
            paddingBottom={3}
            paddingHorizontal={16}
            isFlexBlock={(alertsCount && alertsCount > 0) || isDelegateSwitchEnabled}>
            {/* BUG: When this specific component has role set to heading and aria-level set to 1, the tests will fail */}
            <Text variant="largeTitle" color="mainText">
              {headerTitle}
            </Text>
            {isDelegateSwitchEnabled && (
              <DelegateSwitch layout={DelegateSwitchLayout.PostBooking} />
            )}
            {Boolean(alertsCount) && (
              <NotificationButtonWrapper>
                <AlertNotificationButton />
              </NotificationButtonWrapper>
            )}
          </Block>
        </Screen.Header>
        <TripTabs onChange={onChangeTab} activeTab={activeTab} />
        {isMobileHeldTripsFlowEnabled && !!totalHeldTrips && (
          <HeldTripsButton onClick={handleHeldTripsRedirect} aria-label={heldTripsAriaLabel}>
            <HeldTripsTitle>
              <HeldTripIcon size={32} color="warning" name="heldTripPWA" />
              <Text color="mainText2" variant="subHeadStrong">
                {t(i18Base + '.HeldTrips', { count: totalHeldTrips })}
              </Text>
            </HeldTripsTitle>
            <Icon size={24} color="mainText2" name="chevronRightPWA" />
          </HeldTripsButton>
        )}
        <div ref={anchorRef} />
        <ContentContainer as={'main'}>{tripListSlot}</ContentContainer>
      </Container>
      <AlertModal />
    </Screen>
  )
})
