import type { LoyaltyProgram, GazooMembership } from '@fiji/graphql/types'

export function getExistingMemberships(loyaltyProgram?: LoyaltyProgram[]): GazooMembership[] {
  if (!loyaltyProgram) {
    return []
  }

  return loyaltyProgram
    .map((membership) =>
      membership.number
        ? {
            vendorCode: membership.vendorCode,
            number: membership.number,
          }
        : null,
    )
    .filter(Boolean) as GazooMembership[]
}
