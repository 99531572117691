import styled from 'styled-components'

export const OneRow = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  min-height: 64px;
  align-items: stretch;
`

export const LocationSlot = styled.div<{ isTwoSections?: boolean }>`
  width: ${(p) => (p.isTwoSections ? '47%' : '65%')};
`

export const RightSlot = styled.div<{ isTwoSections?: boolean }>`
  width: ${(p) => (p.isTwoSections ? '53%' : '35%')};
  display: flex;
  gap: 8px;

  & > div {
    width: ${(p) => (p.isTwoSections ? '50%' : '100%')};
  }
`

export const CloseButton = styled.div`
  position: absolute;
  right: -72px;
  padding: 16px;
`

export const SectionWrapper = styled.div`
  width: 100%;
`
