import { Action, Inject } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { HistoryService } from '@etta/interface/services/history.service'
import { PostBookingTripService } from '../services/post-booking-trip.service'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'
import type { ChangeFlightSeatsArgs, LoadTripDetailsArgs, TripQueryParams } from '../types'

@Action()
export class PostBookingTripActions {
  constructor(
    @Inject() private postBookingTripService: PostBookingTripService,
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private historyService: HistoryService,
    @Inject() private historyStore: HistoryStore,
  ) {}

  async loadTrip() {
    await this.postBookingTripService.loadTrip(this.postBookingTripStore.queryParams.isForceRefetch)
  }

  async reloadTrip() {
    await this.postBookingTripService.loadTrip(true)
  }

  async getTripDetails(args: LoadTripDetailsArgs) {
    const result = await this.postBookingTripService.getTripDetails(args)
    return result
  }

  async refetchTripDetails(args: LoadTripDetailsArgs) {
    const result = await this.postBookingTripService.getTripDetails(args, true)
    return result
  }

  clearNotificationQueryParams() {
    const newQueryParams: TripQueryParams = {
      transactionGroupId: this.postBookingTripStore.queryParams.transactionGroupId,
    }
    const nextPath = this.postBookingTripStore.tripQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: '',
      newQueryParams,
    })

    this.historyService.replace(nextPath)
  }

  dropTripStore() {
    this.postBookingTripStore.dropStore()
  }

  changeFlightSeats(args: ChangeFlightSeatsArgs) {
    return this.postBookingTripService.changeFlightSeats(args)
  }
}
