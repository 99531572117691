import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { useCovidInformationHeader } from './use-covid-information-header'
import {
  CovidInformationHeaderLearnMoreSkeleton,
  CovidInformationHeaderUpdatedSkeleton,
} from './covid-information-header-skeleton'
import { Heading } from './heading/'
import { LearnMoreButton } from './covid-information-header-styled'

type Props = {
  isLoading: boolean
  onLearnMoreClick: () => void
}

const covidInformationHeaderUpdatedSkeleton = <CovidInformationHeaderUpdatedSkeleton />
const covidInformationHeaderLearnMoreSkeleton = <CovidInformationHeaderLearnMoreSkeleton />

const i18nBase = `HotelSafetyCheck.CovidInformation.CovidInformationHeader`

export function CovidInformationHeader({ isLoading, onLearnMoreClick }: Props) {
  const { t } = useTranslation()
  const { isUpdatedAtShouldRender } = useCovidInformationHeader()
  return (
    <div>
      <Heading>
        <Text aria-label={t(`${i18nBase}.TitleVoice`)} variant="headline" color="mainText">
          {t(`${i18nBase}.Title`)}
        </Text>
        {isLoading ? (
          covidInformationHeaderLearnMoreSkeleton
        ) : (
          <LearnMoreButton
            variant={'link'}
            aria-label={t('Accessibility.ButtonTapToLearnMore', {
              label: t(`${i18nBase}.LearnMoreVoice`),
            })}
            onClick={onLearnMoreClick}>
            {t(`${i18nBase}.LearnMore`)}
          </LearnMoreButton>
        )}
      </Heading>
      {isUpdatedAtShouldRender && (
        <>
          {isLoading ? (
            covidInformationHeaderUpdatedSkeleton
          ) : (
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.LatestInformation`)}
            </Text>
          )}
        </>
      )}
    </div>
  )
}
