import * as yup from 'yup'
import cardValidator from 'card-validator'
import i18n from '@fiji/i18n'
import { Validator } from '@etta/interface/services/validator'
import type { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { AddressFields } from '@etta/modules/settings/core/enums/address-fields'
import type { EmployeeInformationFieldsSettings } from '@etta/modules/settings/interface/stores/profile-field-settings/account-information-fields'
import { validatePhoneInput } from '@fiji/utils/phone/format-validation'

type Params = Parameters<typeof FieldSettingsStore.prototype.isFieldRequiredBool>

const i18nBase = 'Settings.AccountInformation.Modal'
const i18nBaseErrors = 'Settings.Errors'

const nullableString = Validator.scheme.string().nullable().default('')
const nullableBoolean = Validator.scheme.boolean().nullable().default(null)

export class AccountActivationValidatorMaker {
  constructor(private readonly fieldSettingsStore: FieldSettingsStore) {}

  private isFieldDisplay(field: Params[0], category: Params[1]) {
    return !!this.fieldSettingsStore.fieldsSettings[category][field]?.isRenderedOnActivationPage
  }

  private isFieldRequired(
    field: Params[0],
    category: Params[1],
    defaultLabel: string = '',
    addressField?: Params[0],
  ) {
    return (_: string | string[], schema: yup.AnySchema) => {
      if (
        this.isFieldDisplay(field, category) &&
        this.fieldSettingsStore.isFieldEditable(field, category) &&
        this.fieldSettingsStore.isFieldRequiredBool(field, category)
      ) {
        const i18BaseErrors = 'Settings.Errors'
        return schema.required(
          i18n.t(i18BaseErrors + '.FieldIsRequired', {
            fieldLabel: this.fieldSettingsStore.getFieldLabel(
              addressField ?? field,
              category,
              defaultLabel,
            ),
          }),
        )
      }
      return schema
    }
  }

  private isAddressBlockRequired(field: Params[0], category: Params[1], defaultLabel: string = '') {
    return this.isFieldRequired('addressBlock', category, defaultLabel, field)
  }

  makeUserInfoValidator() {
    return new Validator({
      timeZone: Validator.scheme
        .string()
        .nullable()
        .required(
          i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
            fieldLabel: i18n.t(`${i18nBase}.TimeZone`),
          }),
        )
        .default(null),
      title: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.isFieldRequired(
            'title',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Title`),
          ),
        )
        .default(null),
      firstName: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'firstName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.FirstName`),
          ),
        )
        .default(''),
      middleName: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'middleName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.MiddleName`),
          ),
        )
        .default(''),
      lastName: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'lastName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.LastName`),
          ),
        )
        .default(''),
      suffix: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.isFieldRequired(
            'suffix',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Suffix`),
          ),
        )
        .default(null),
      gender: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.isFieldRequired(
            'gender',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Gender`),
          ),
        )
        .default(null),
      dateOfBirth: Validator.scheme
        .date()
        .nullable()
        .optional()
        .when(
          '$',
          this.isFieldRequired(
            'dateOfBirth',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.DateOfBirth`),
          ),
        )
        .default(null),
      email: Validator.scheme
        .string()
        .email()
        .when(
          '$',
          this.isFieldRequired(
            'email',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Email`),
          ),
        )
        .default(''),
      username: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'username',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Username`),
          ),
        )
        .default(''),
      showConfirmationEmail: Validator.scheme.boolean().default(false),
      confirmationEmail: Validator.scheme
        .string()
        .when('showConfirmationEmail', {
          is: true,
          then: (schema) => {
            return schema
              .required(
                i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
                  fieldLabel: i18n.t(`${i18nBase}.ConfirmationEmail`),
                }),
              )
              .notOneOf(
                [Validator.scheme.ref('email')],
                i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
                  fieldName: i18n.t(`${i18nBase}.ConfirmationEmail`),
                }),
              )
          },
        })
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailFieldWithFormat`, {
            fieldLabel: i18n.t(`${i18nBase}.ConfirmationEmail`),
          }),
        )
        .nullable()
        .default(null),
      homeCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            AddressFields.Country,
            FieldSettingsCategory.HomeAddress,
            i18n.t(`${i18nBase}.Country`),
          ),
        )
        .nullable()
        .default(''),
      homePostalCode: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Zip,
          FieldSettingsCategory.HomeAddress,
          i18n.t(`${i18nBase}.ZipCode`),
        ),
      ),
      homeStateCode: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.State,
          FieldSettingsCategory.HomeAddress,
          i18n.t(`${i18nBase}.State`),
        ),
      ),
      homeMailStop: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'mailStop',
            FieldSettingsCategory.HomeAddress,
            i18n.t(`${i18nBase}.Mailstop`),
          ),
        )
        .nullable()
        .default(''),
      homeCity: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.City,
          FieldSettingsCategory.HomeAddress,
          i18n.t(`${i18nBase}.City`),
        ),
      ),
      homeStreet1: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street1,
          FieldSettingsCategory.HomeAddress,
          i18n.t(`${i18nBase}.Street1`),
        ),
      ),
      homeStreet2: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street2,
          FieldSettingsCategory.HomeAddress,
          i18n.t(`${i18nBase}.Street2`),
        ),
      ),
      emergencyName: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'name',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.FullName`),
          ),
        )
        .nullable()
        .default(''),
      emergencyRelationship: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'relationship',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Relationship`),
          ),
        )
        .nullable()
        .default(''),
      emergencyPrimaryPhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'primaryPhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.CountryCode`),
          ),
        )
        .nullable()
        .default(''),
      emergencyPrimaryPhone: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'primaryPhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.PrimaryPhoneNumber`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) =>
            validatePhoneInput(value, context.parent.emergencyPrimaryPhoneCountryCode),
        )
        .nullable()
        .default(''),
      emergencyAlternatePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'alternatePhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.CountryCode`),
          ),
        )
        .nullable()
        .default(''),
      emergencyAlternatePhone: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'alternatePhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.SecondaryPhoneNumber`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) =>
            validatePhoneInput(value, context.parent.emergencyAlternatePhoneCountryCode),
        )
        .nullable()
        .default(''),
      emergencyEmail: Validator.scheme
        .string()
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailFieldWithFormat`, {
            fieldLabel: i18n.t(`${i18nBase}.Email`),
          }),
        )
        .when(
          '$',
          this.isFieldRequired(
            'email',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Email`),
          ),
        )
        .nullable()
        .default(''),
      emergencyCountryCode: nullableString.when(
        '$',
        this.isFieldRequired(
          AddressFields.Country,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.Country`),
        ),
      ),
      emergencyCity: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.City,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.City`),
        ),
      ),
      emergencyPostalCode: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Zip,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.ZipCode`),
        ),
      ),
      emergencyMailStop: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'mailStop',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Mailstop`),
          ),
        )
        .nullable()
        .default(''),
      emergencyStateCode: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.State,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.State`),
        ),
      ),
      emergencyStreet1: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street1,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.Street1`),
        ),
      ),
      emergencyStreet2: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street2,
          FieldSettingsCategory.EmergencyContact,
          i18n.t(`${i18nBase}.Street2`),
        ),
      ),
    })
  }

  makeBusinessContactValidator() {
    return new Validator({
      profilePin: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'profilePin',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.ProfilePin`),
          ),
        )
        .default(''),
      officePhoneCountryCode: nullableString.when(
        '$',
        this.isFieldRequired(
          'officePhone',
          FieldSettingsCategory.BusinessContact,
          i18n.t(`${i18nBase}.OfficePhone`),
        ),
      ),
      officePhoneNumber: nullableString
        .when(
          '$',
          this.isFieldRequired(
            'officePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.OfficePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.officePhoneCountryCode),
        ),
      officePhoneExtension: nullableString,
      faxPhoneCountryCode: nullableString.when(
        '$',
        this.isFieldRequired(
          'faxPhone',
          FieldSettingsCategory.BusinessContact,
          i18n.t(`${i18nBase}.FaxPhone`),
        ),
      ),
      faxPhoneNumber: nullableString
        .when(
          '$',
          this.isFieldRequired(
            'faxPhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.FaxPhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.faxPhoneCountryCode),
        ),
      mobilePhoneCountryCode: nullableString.when(
        '$',
        this.isFieldRequired(
          'mobilePhone',
          FieldSettingsCategory.BusinessContact,
          i18n.t(`${i18nBase}.MobilePhone`),
        ),
      ),
      mobilePhoneNumber: nullableString
        .when(
          '$',
          this.isFieldRequired(
            'mobilePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.MobilePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.mobilePhoneCountryCode),
        ),
      businessEmail: nullableString
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailFieldWithFormat`, {
            fieldLabel: i18n.t(`${i18nBase}.BusinessEmail`),
          }),
        )
        .when(
          '$',
          this.isFieldRequired(
            'businessEmail',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.BusinessEmail`),
          ),
        ),
      groupwareId: nullableString
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
            fieldName: i18n.t(`${i18nBase}.GroupwareID`),
          }),
        )
        .when(
          '$',
          this.isFieldRequired(
            'groupwareId',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.GroupwareID`),
          ),
        ),
      [AddressFields.Country]: nullableString.when(
        '$',
        this.isFieldRequired(
          AddressFields.Country,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.Country`),
        ),
      ),
      [AddressFields.Zip]: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Zip,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.ZipCode`),
        ),
      ),
      [AddressFields.State]: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.State,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.State`),
        ),
      ),
      [AddressFields.Mailstop]: nullableString.when(
        '$',
        this.isFieldRequired(
          'mailStop',
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.Mailstop`),
        ),
      ),
      [AddressFields.City]: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.City,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.City`),
        ),
      ),
      [AddressFields.Street1]: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street1,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.Street1`),
        ),
      ),
      [AddressFields.Street2]: nullableString.when(
        '$',
        this.isAddressBlockRequired(
          AddressFields.Street2,
          FieldSettingsCategory.BusinessAddress,
          i18n.t(`${i18nBase}.Street2`),
        ),
      ),
      isVip: nullableBoolean
        .when(
          '$',
          this.isFieldRequired(
            'vipIndicator',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.VIPIndicator`),
          ),
        )
        .default(false),
      homePhoneNumber: nullableString
        .when(
          '$',
          this.isFieldRequired(
            'homePhone',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.HomePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.homePhoneCountryCode),
        ),
      homePhoneCountryCode: nullableString.when(
        '$',
        this.isFieldRequired(
          'homePhone',
          FieldSettingsCategory.PersonalInformation,
          i18n.t(`${i18nBase}.HomePhone`),
        ),
      ),
      homeEmail: nullableString
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailFieldWithFormat`, {
            fieldLabel: i18n.t(`${i18nBase}.HomeEmail`),
          }),
        )
        .when(
          '$',
          this.isFieldRequired(
            'homeEmail',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.HomeEmail`),
          ),
        ),
    })
  }

  makeEmployeeInformationValidator() {
    return new Validator({
      isVip: Validator.scheme
        .boolean()
        .when(
          '$',
          this.isFieldRequired(
            'vipIndicator',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.VIPIndicator`),
          ),
        )
        .default(false),
      isActiveInCompany: nullableBoolean.when(
        '$',
        this.isFieldRequired(
          'employeeStatus',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.EmployeeStatus`),
        ),
      ),
      employeeType: this.getEmployeeInfoStringSchema('employeeType').when(
        '$',
        this.isFieldRequired(
          'employeeType',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.EmployeeType`),
        ),
      ),
      employeeId: this.getEmployeeInfoStringSchema('employeeID').when(
        '$',
        this.isFieldRequired(
          'employeeID',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.EmployeeID`),
        ),
      ),
      jobTitle: this.getEmployeeInfoStringSchema('jobTitle').when(
        '$',
        this.isFieldRequired(
          'jobTitle',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.JobTitle`),
        ),
      ),
      jobLevel: this.getEmployeeInfoStringSchema('jobLevel').when(
        '$',
        this.isFieldRequired(
          'jobLevel',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.JobLevel`),
        ),
      ),
      managerId: this.getEmployeeInfoStringSchema('managerID').when(
        '$',
        this.isFieldRequired(
          'managerID',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.ManagerID`),
        ),
      ),
      costCenter: this.getEmployeeInfoStringSchema('costCenter').when(
        '$',
        this.isFieldRequired(
          'costCenter',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.CostCenter`),
        ),
      ),
      departmentCode: this.getEmployeeInfoStringSchema('departmentCode').when(
        '$',
        this.isFieldRequired(
          'departmentCode',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.DepartmentCode`),
        ),
      ),
      departmentName: this.getEmployeeInfoStringSchema('departmentName').when(
        '$',
        this.isFieldRequired(
          'departmentName',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.DepartmentName`),
        ),
      ),
      division: this.getEmployeeInfoStringSchema('divisionCode').when(
        '$',
        this.isFieldRequired(
          'divisionCode',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.Division`),
        ),
      ),
      businessUnit: this.getEmployeeInfoStringSchema('businessUnit').when(
        '$',
        this.isFieldRequired(
          'businessUnit',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.BusinessUnit`),
        ),
      ),
      companyName: this.getEmployeeInfoStringSchema('companyName').when(
        '$',
        this.isFieldRequired(
          'companyName',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.CompanyName`),
        ),
      ),
      expenseApproverId: this.getEmployeeInfoStringSchema('expenseApproverName').when(
        '$',
        this.isFieldRequired(
          'expenseApproverName',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.ExpenseApproverID`),
        ),
      ),
      purchaseApproverId: Validator.scheme.string(),
      travelApproverId: this.getEmployeeInfoStringSchema('travelApproverID').when(
        '$',
        this.isFieldRequired(
          'travelApproverID',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.TravelApproverID`),
        ),
      ),
      mis1: this.getEmployeeInfoStringSchema('mis1').when(
        '$',
        this.isFieldRequired(
          'mis1',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField1`),
        ),
      ),
      mis2: this.getEmployeeInfoStringSchema('mis2').when(
        '$',
        this.isFieldRequired(
          'mis2',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField2`),
        ),
      ),
      mis3: this.getEmployeeInfoStringSchema('mis3').when(
        '$',
        this.isFieldRequired(
          'mis3',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField3`),
        ),
      ),
      mis4: this.getEmployeeInfoStringSchema('mis4').when(
        '$',
        this.isFieldRequired(
          'mis4',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField4`),
        ),
      ),
      mis5: this.getEmployeeInfoStringSchema('mis5').when(
        '$',
        this.isFieldRequired(
          'mis5',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField5`),
        ),
      ),
      mis6: this.getEmployeeInfoStringSchema('mis6').when(
        '$',
        this.isFieldRequired(
          'mis6',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField6`),
        ),
      ),
      mis7: this.getEmployeeInfoStringSchema('mis7').when(
        '$',
        this.isFieldRequired(
          'mis7',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.MISField7`),
        ),
      ),
    })
  }

  private getEmployeeInfoStringSchema(field: keyof typeof EmployeeInformationFieldsSettings) {
    return nullableString
      .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
        this.fieldSettingsStore.getAllowedCharactersCheck({
          field,
          value,
        }),
      )
      .test(
        'are-characters-in-allowed-range',
        () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field }),
        (value) =>
          this.fieldSettingsStore.getTextLengthCheck({
            field,
            value,
          }),
      )
  }

  makePaymentInformationValidator() {
    const i18BaseErrors = 'CreditCardForm.Errors.'

    const getRequiredSchema = (errorMsg: string) => (
      _: string | string[],
      schema: yup.AnySchema,
    ) => {
      return this.isFieldDisplay('personalCards', FieldSettingsCategory.PaymentCards) &&
        this.fieldSettingsStore.isFieldEditable(
          'personalCards',
          FieldSettingsCategory.PaymentCards,
        ) &&
        this.fieldSettingsStore.isFieldRequiredBool(
          'personalCards',
          FieldSettingsCategory.PaymentCards,
        )
        ? schema.required(errorMsg)
        : schema
    }

    return new Validator({
      cardName: Validator.scheme
        .string()
        .when('$', getRequiredSchema(i18n.t(i18BaseErrors + 'Label')))
        .nullable()
        .default(''),
      nameOnCard: Validator.scheme
        .string()
        .when('$', getRequiredSchema(i18n.t(i18BaseErrors + 'Name')))
        .nullable()
        .default(''),
      cardNumber: Validator.scheme
        .string()
        .test('is-credit-card-number', i18n.t(i18BaseErrors + 'CardNumber.Invalid'), (value) => {
          if (!value) {
            return true
          }
          return cardValidator.number(value).isValid
        })
        .when('$', getRequiredSchema(i18n.t(i18BaseErrors + 'CardNumber.Required')))
        .nullable()
        .default(''),
      expirationDate: Validator.scheme
        .string()
        .test('is-expiration-date', i18n.t(i18BaseErrors + 'ExpirationDate.Invalid'), (value) => {
          if (!value) {
            return true
          }
          return cardValidator.expirationDate(value).isValid
        })
        .when('$', getRequiredSchema(i18n.t(i18BaseErrors + 'ExpirationDate.Required')))
        .nullable()
        .default(''),
    })
  }

  makeFlightPreferencesValidator() {
    return new Validator({
      homeAirport: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'homeAirport',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.HomeAirport`),
          ),
        )
        .default(''),
      mealPreference: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.isFieldRequired(
            'airMeal',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.Meal`),
          ),
        )
        .default(null),
      seatPreference: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'airSeatPreference',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.SeatPreference`),
          ),
        )
        .default('WINDOW'),
      airSpecialRequest: Validator.scheme
        .array()
        .nullable()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'airSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            if (
              this.isFieldDisplay('airSpecialRequest', FieldSettingsCategory.TravelPreferences) &&
              this.fieldSettingsStore.isFieldRequiredBool(
                'airSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
              )
            ) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default(null),
      noteToArranger: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'airTravelAgencyNote',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.TravelAgencyNote`),
          ),
        )
        .default(''),
      airMembership: Validator.scheme.array().nullable().default(null),
    })
  }

  makeHotelPreferencesValidator() {
    return new Validator({
      specialRequest: Validator.scheme
        .array()
        .nullable()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'hotelSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            if (
              this.isFieldDisplay('hotelSpecialRequest', FieldSettingsCategory.TravelPreferences) &&
              this.fieldSettingsStore.isFieldRequiredBool(
                'hotelSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
              )
            ) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default(null),
      membership: Validator.scheme.array().nullable().default(null),
      amenity: Validator.scheme.array().nullable().default(null),
    })
  }

  makeCarrentalPreferencesValidator() {
    return new Validator({
      carClass: Validator.scheme
        .string()
        .when(
          '$',
          this.isFieldRequired(
            'carRentalType',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.CarRentalType`),
          ),
        )
        .nullable()
        .default(null),
      specialRequest: Validator.scheme
        .array()
        .nullable()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'carRentalSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            if (
              this.isFieldDisplay(
                'carRentalSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
              ) &&
              this.fieldSettingsStore.isFieldRequiredBool(
                'carRentalSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
              )
            ) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default(null),
      membership: Validator.scheme
        .array()
        .nullable()
        .of(
          yup.object({
            number: yup.string(),
            vendorCode: yup.string(),
          }),
        )
        .default(null),
    })
  }
}
