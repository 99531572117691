import styled from 'styled-components'
import { Dialog } from '@etta/ui/dialog'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'

export const Footer = styled(Dialog.Footer)`
  padding: 14px 32px;
  display: flex;
  justify-content: space-between;
`

export const Body = styled(Dialog.Body)`
  padding: 26px 32px 32px 32px;
`

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.primary1};
  flex: 0 1 180px;
`

export const DescriptionSecondPartWrapper = styled(Text)`
  margin-top: 15px;
`

export const ConfirmButton = styled(Button)``

export const LinkWrapper = styled(Link)`
  margin-left: 5px;
`
