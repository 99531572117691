import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { StyledTextVariant } from '@fiji/enums'
import { Icon } from '@etta/ui/icon'
import { Search } from '@etta/ui/search'
import { LocationPicker } from '@etta/components/location-picker/location-picker'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { RailTripType } from '@fiji/hooks/search-queries'
import { usePlacesStorageContext } from '@etta/modules/places-storage/interface/use-places-storage.context'
import {
  StyledErrorBlock,
  ErrorText,
  LocationPickerWrapper,
  Wrapper,
  ErrorBlockWrapper,
} from './location-section-desktop-styled'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationSectionDesktop = observer(function LocationSectionDesktop() {
  const { placesStorageStore } = usePlacesStorageContext()
  const placesHistory = placesStorageStore.getPlaces('rail')

  const {
    railSearchFormActions,
    railSearchButtonActions,
    railSearchFormStore,
  } = useRailSearchFormContext()
  const { originPlace, destinationPlace, railTripType } = railSearchFormStore.railForm

  const { t } = useTranslation()

  const shouldTruncateLocationPickerInputValueAt = railTripType === RailTripType.Round ? 17 : 25

  return (
    <Wrapper>
      <Search.Group>
        <LocationPickerWrapper>
          <LocationPicker
            recentSearchesType="rail"
            label={t(`${i18Base}.Input.From`)}
            searchType="train"
            value={originPlace}
            latestPlaces={placesHistory}
            isForSearchHeader
            isOutboundTrip
            onChange={railSearchFormActions.onOriginPlaceChange}
            customModalDescription={t(`${i18Base}.CustomRailLocationModalTitle`)}
            inputSlot={
              <Search.Element
                wrapperAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', {
                  label: t(`${i18Base}.Input.From`),
                })}
                labelTitle={t(`${i18Base}.Input.From`)}
                value={originPlace?.airportCode || originPlace?.name}
                shouldTruncateValueAt={shouldTruncateLocationPickerInputValueAt}
              />
            }
          />
        </LocationPickerWrapper>
        <Search.SwapLocationButton isCentered onClick={railSearchFormActions.onSwitchLocations} />
        <LocationPickerWrapper>
          <LocationPicker
            recentSearchesType="rail"
            label={t(`${i18Base}.Input.To`)}
            searchType="train"
            value={destinationPlace}
            latestPlaces={placesHistory}
            isForSearchHeader
            onChange={railSearchFormActions.onDestinationPlaceChange}
            inputSlot={
              <Search.Element
                wrapperAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', {
                  label: t(`${i18Base}.Input.To`),
                })}
                labelTitle={t(`${i18Base}.Input.To`)}
                value={destinationPlace?.airportCode || destinationPlace?.name}
                shouldTruncateValueAt={shouldTruncateLocationPickerInputValueAt}
              />
            }
          />
        </LocationPickerWrapper>
      </Search.Group>
      {railSearchButtonActions.isErrorInput && (
        <ErrorBlockWrapper>
          <StyledErrorBlock>
            <Icon name="errorFilledPWA" size="small" color="error" />
            <ErrorText textVariant={StyledTextVariant.captionStrong}>
              {t(i18Base + '.Input.DestinationError')}
            </ErrorText>
          </StyledErrorBlock>
        </ErrorBlockWrapper>
      )}
    </Wrapper>
  )
})
