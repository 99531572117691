import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px;
  margin-bottom: 24px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  white-space: pre-line;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
`
