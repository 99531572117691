import styled, { css } from 'styled-components'
import chevronLeft from '@etta/ui/icon/pwa-icon/assets/chevron-left.svg?url'
import chevronRight from '@etta/ui/icon/pwa-icon/assets/chevron-right.svg?url'
import { subHeadStrong, captionMedium, captionStrong, KeyboardNavigationStyle } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  filter: drop-shadow(0px 20px 16px rgba(0, 0, 0, 0.1));
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 383px;
  color: ${({ theme }) => theme.colors.mainText};
  position: relative;
`

const MonthCaption = css`
  .CalendarMonth__title {
    font-size: 13px;
    margin: 10px 0 -10px 0;
    ${subHeadStrong};

    text-align: left;
    margin-left: -12px;
    color: ${({ theme }) => theme.colors.primary1};
    width: fit-content;
    cursor: pointer;
  }

  .CalendarMonth_caption {
    text-align: left;
    width: fit-content;
    cursor: pointer;
    margin: 8px 0 -10px -3px;
  }
`

export const Picker = css`
  .DayPicker {
    margin: 0 auto;
  }

  .DayPickerNavigation_button {
    position: absolute;
    right: -10px;
    top: 12px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    background-image: url(${chevronRight});
    margin-right: -10px;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 270px;
    background-image: url(${chevronLeft});
    margin-left: -10px;
  }

  .CalendarMonthGrid_month__horizontal,
  .DayPicker_weekHeader {
    margin-top: -20px;
  }

  .DayPickerNavigation_svg__horizontal {
    display: none;
  }

  .DayPicker_weekHeader {
    ${captionMedium}
  }

  .DayPicker_weekHeader ul li:first-child,
  .DayPicker_weekHeader ul li:last-child {
    color: #757b81;
  }
  .CalendarMonth_table {
    margin-top: 20px;
  }
  .CalendarMonth_table tr {
    border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
    &:first-of-type {
      border-top: none;
    }
    & td {
      border-top: none;
    }
  }
`

export const Footer = styled.div<{ justifyContent: 'space-between' | 'flex-end' }>`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  justify-content: ${({ justifyContent }) => justifyContent};
`

export const LeftNavIcon = styled(Icon)`
  position: relative;
  left: -38px;
`

export const RightNavIcon = styled(Icon)``

export const DayContent = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${captionMedium};
  color: ${({ theme }) => theme.colors.mainText};
`

const SinglePickerCalendar = css`
  .CalendarMonth {
    padding: 0 !important;
  }
  .CalendarMonth_table tr td {
    padding: 0 4.3px;
    border: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      ${DayContent} {
        background-color: ${({ theme }) => theme.colors.primary2};
      }
    }
    &.CalendarDay__firstDayOfWeek {
      ${DayContent} {
        color: ${({ theme }) => theme.colors.bodyText};
      }
    }
    &.CalendarDay__selected {
      background-color: ${({ theme }) => theme.colors.white};
      ${DayContent} {
        background-color: ${({ theme }) => theme.colors.primary1};
        color: ${({ theme }) => theme.colors.white};
      }
    }
    &.CalendarDay {
      ${KeyboardNavigationStyle}
    }
  }

  .DayPicker_weekHeader_ul {
    position: absolute;
    width: 343px;
    left: -24px;
    display: flex;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.mainText};
    align-items: center;
    height: 20px;
    li {
      width: 20px;
      ${captionStrong};
      font-size: 11px;
      small {
        font-size: 100%;
      }
    }
  }

  .DayPicker_transitionContainer__horizontal {
    height: 293px;
    position: relative;
    left: -21px;
    width: 360px !important;
  }
`

export const DayPickerWrapper = styled.div<{ isHide?: boolean }>`
  ${Picker}
  ${MonthCaption}
  ${SinglePickerCalendar}
`
