import styled from 'styled-components'
import { screenSize } from '@fiji/style'
import { Icon as EttaIcon } from '@etta/ui/icon'
import { TextField as EttaTextField } from '@etta/ui/text-field'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 34px;
  margin-bottom: 31px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  @media (${screenSize.maxMobile}) {
    padding: 20px 20px 0;
    border-bottom: none;
    margin-bottom: 15px;
  }
`

export const ChevronValueIcon = styled(EttaIcon)`
  margin: 0 3px;
`

export const TextField = styled(EttaTextField)`
  margin: 0 34px 12px;
  @media (${screenSize.maxMobile}) {
    padding: 0 20px 16px;
    border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  }
`

export const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const ValueText = styled.div`
  line-height: 27px;
`

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -10px 20px 0 73px;
  @media (${screenSize.maxMobile}) {
    padding: 6px 0 16px;
    margin: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`
