import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
`

export const Logo = styled.img`
  display: block;
  width: 90px;
  height: auto;
  margin-bottom: 8px;
`

export const PhoneAndAddressContainer = styled.div`
  display: flex;
`

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 6px 0;
`

export const PhoneAndTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const VerticalSeparator = styled.div`
  height: auto;
  width: 1px;
  margin: 0 20px;
  border-right: 1px solid ${(props) => props.theme.colors.borderLight};
`
