import styled from 'styled-components'
import { captionStrongCaps, footnoteMedium, subHeadStrong } from '@fiji/style'

export const Content = styled.div`
  position: relative;
  padding-left: 28px;
  z-index: 1;

  &::after {
    box-sizing: border-box;
    position: absolute;
    top: 3.2rem;
    bottom: 3.2rem;
    left: 0.6rem;
    content: '';
    width: 2px;
    background-color: ${({ theme }) => theme.colors.borderLight};
    height: 55%;
  }
`

export const Section = styled.div`
  & ~ & {
    margin-top: 24px;
  }
`

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.bodyText1};
  margin-bottom: 4px;
  ${captionStrongCaps};
`

export const Title = styled.address<{ isStart?: boolean }>`
  font-style: normal;
  margin-bottom: 4px;
  ${subHeadStrong};
  position: relative;
  &::after {
    position: absolute;
    top: 60%;
    transform: translateY(-60%);
    left: -2.6rem;
    content: '';
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: ${({ theme, isStart = false }) =>
      isStart ? theme.colors.success : theme.colors.error};
    z-index: 2;
  }
`

export const Description = styled.time`
  color: ${({ theme }) => theme.colors.bodyText1};
  ${footnoteMedium};
`
