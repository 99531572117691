import styled from 'styled-components'
import { KeyboardNavigationStyle, subHeadMedium } from '@fiji/style'

export const Container = styled.button`
  display: flex;
  align-items: center;
  padding: 14px 0;
  outline: 0;
  cursor: pointer;

  width: 100%;
  background: transparent;
  border: none;

  ${KeyboardNavigationStyle}
`

export const CheckmarkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
`

export const Title = styled.div`
  margin-left: 15px;
  ${subHeadMedium}
  line-height: 20px;
  color: ${(props) => props.theme.colors.mainText};
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`
