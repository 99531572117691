import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import type { Props } from '../types'

const i18Base = 'TripPlanner.BaseSearch.Buttons.'

export function CarRentalSearchButtonDesktop({ onSearchClick }: Props) {
  const { t } = useTranslation()

  return (
    <Button
      fullWidth={false}
      variant="solid"
      btnType="primary"
      size="normal"
      onClick={onSearchClick}
      data-tracking-id="header-search-button">
      <Text variant="headline" color="mainText2">
        {t(i18Base + 'SearchCarRentals')}
      </Text>
    </Button>
  )
}
