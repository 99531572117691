import type { OutOfPolicyData } from '@fiji/graphql/types'
import { OutOfPolicyEnum } from '@fiji/graphql/types'
import type { CarRentalClassType } from '@fiji/graphql/hooks'
import { translateCarClass } from '@fiji/utils/translate-car-rental/translate-car-class'
import { formatMoneyRTP } from '@fiji/utils/money/format-money-rtp'

type AdditionalOptions = {
  outOfPolicyData?: OutOfPolicyData[] | null
  policyEnum: string
  locale?: string
  i?: number
}

export function addPolicyData(options: AdditionalOptions) {
  const vehicleTypePolicies = ['ExceedsMaxSize']
  const refIndex = options.i ? options.i : 0
  const policyData = options.outOfPolicyData?.filter(
    (policy) => policy.type === (OutOfPolicyEnum as any)[options.policyEnum],
  )
  if (policyData && policyData.length > 0) {
    const filteredPolicy = policyData[0]
    if (vehicleTypePolicies.includes(options.policyEnum)) {
      return translateCarClass(String(filteredPolicy.data) as CarRentalClassType)
    }
    if (
      filteredPolicy.data &&
      filteredPolicy.data.filter(Boolean).length > 0 &&
      filteredPolicy.currency &&
      filteredPolicy.currency.filter(Boolean).length > 0 &&
      filteredPolicy.currency[refIndex]
    ) {
      return formatMoneyRTP(options.locale || 'en', {
        amount: Number(filteredPolicy.data[refIndex]),
        currency: filteredPolicy.currency[refIndex]!,
      })
    }
    return String(filteredPolicy.data![refIndex])
  }
  return ''
}
