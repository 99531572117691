import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { OutsideContainer } from '@etta/ui/outside-container'
import { DropDownList } from '@etta/ui/drop-down-list'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { SearchBrands } from '@etta/screens/hotel-result-page/hotel-filters/search-brands'
import { BrandsFilterContent } from './brands-filter-content'

const i18nBase = 'HotelFilters.Hotel'

export const BrandsFilter = observer(function BrandsFilter() {
  const {
    hotelFiltersAction,
    hotelFiltersStore,
    hotelSearchResultsStore,
    hotelQuickFiltersStore,
    hotelQuickFiltersAction,
  } = useHotelSearchResultsContext()
  const { t } = useTranslation()
  const listToggle = useTogglePopup()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  const onApply = useCallback(
    (newBrandsIds) => {
      listToggle.handleClose()
      if (hotelFiltersStore.selectedFilters.brandIds?.length === 0 && newBrandsIds.length === 0) {
        return
      }

      hotelFiltersAction.handleSetBrandIds(newBrandsIds)
      hotelFiltersAction.handleApplyFilters()
    },
    [hotelFiltersStore.selectedFilters.brandIds?.length, hotelFiltersAction, listToggle],
  )

  const totalBrands = hotelFiltersStore.selectedFilters.brandIds?.length || 0
  const headerLabel = totalBrands === 0 ? t(i18nBase + '.Any') : totalBrands

  if (isNewHotelExposedFiltersEnabled) {
    return (
      <QuickFilterLayout
        data-tracking-id="brands-filter-dropdown"
        isOpen={hotelQuickFiltersStore.brandsModal.isOpen}
        onOpen={hotelQuickFiltersStore.brandsModal.handleOpen}
        onClose={hotelQuickFiltersStore.brandsModal.handleClose}
        onClear={hotelFiltersAction.clearBrandIds}
        onApply={hotelQuickFiltersAction.handleApplyBrands}
        label={t(i18nBase + '.HotelBrands')}
        value={hotelQuickFiltersStore.hotelBrandsValue}
        isShimmerLoading={
          hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.hotelResults
        }
        isLoading={hotelSearchResultsStore.isLoading}
        selectedNumber={hotelFiltersStore.selectedFilters.brandIds.length}
        isApplyButtonDisabled={hotelQuickFiltersStore.isBrandsApplyButtonDisable}
        isClearButtonDisabled={hotelQuickFiltersStore.isBrandClearButtonDisable}>
        <SearchBrands />
      </QuickFilterLayout>
    )
  }

  return (
    <DropDownList.Container
      data-tracking-id="brands-filter-dropdown"
      aria-expanded={listToggle.isOpen}>
      <DropDownList.Label
        isOpen={listToggle.isOpen}
        onOpen={listToggle.handleOpen}
        label={t(i18nBase + '.HotelBrands')}
        value={`${headerLabel}`}
      />
      <OutsideContainer isOpen={listToggle.isOpen} onClose={listToggle.handleClose}>
        <BrandsFilterContent
          brandIds={hotelFiltersStore.selectedFilters.brandIds}
          superBrands={hotelSearchResultsStore.filterOptions.brands.superBrands}
          brands={hotelSearchResultsStore.filterOptions.brands.brands}
          isApplyButtonDisabled={hotelQuickFiltersStore.isBrandsApplyButtonDisable}
          onSelect={hotelFiltersAction.handleSetBrandIds}
          onApply={onApply}
        />
      </OutsideContainer>
    </DropDownList.Container>
  )
})
