import { BlockDefaultLayout } from './layout'

type Props = {
  title: string
  seatNumber?: string
  isSeatSelected: boolean
  onSelect: () => void
  isLoading: boolean
  finishAllSeatsSelection?: () => void
}

export function BlockDefault({
  title,
  seatNumber,
  onSelect,
  isLoading,
  isSeatSelected,
  finishAllSeatsSelection,
}: Props) {
  return (
    <BlockDefaultLayout
      finishAllSeatsSelection={finishAllSeatsSelection}
      title={title}
      isSeatSelected={isSeatSelected}
      seatNumber={seatNumber}
      onSelect={onSelect}
      isLoading={isLoading}
    />
  )
}
