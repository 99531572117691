import type { LayoutControlsFooterProps } from '@etta/ui/date-picker-temp/controls/types'
import { MediaLayout } from '@etta/ui/media-layout'

import { ControlsFooterLayoutDesktop } from './desktop/controls-footer-layout-desktop'

export function ControlsFooterLayout(props: LayoutControlsFooterProps) {
  return (
    <MediaLayout
      // TODO: Implement display of meeting mode for mobile devices. This feature will be added in a future phase.
      mobileSlot={<div />}
      desktopSlot={<ControlsFooterLayoutDesktop {...props} />}
    />
  )
}
