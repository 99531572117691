import { Service, Inject } from '@etta/di'
import { UnusedTicketAdapter } from '../../infra/unused-tickets.adapter'
import { UnusedTicketsStore } from '../stores/unused-tickets.store'

@Service()
export class UnusedTicketService {
  constructor(
    @Inject() private unusedTicketAdapter: UnusedTicketAdapter,
    @Inject() private unusedTicketsStore: UnusedTicketsStore,
  ) {}

  async forceGetUnusedTickets() {
    const result = await this.unusedTicketAdapter.getUnusedTickets(true)
    if (result.isOk()) {
      this.unusedTicketsStore.setUnusedTickets(result.getValue())
    }
  }

  async getUnusedTickets() {
    this.unusedTicketsStore.setIsLoading(true)

    const result = await this.unusedTicketAdapter.getUnusedTickets()

    result.match({
      Ok: (result) => {
        this.unusedTicketsStore.setUnusedTickets(result)
        this.unusedTicketsStore.setIsError(false)
      },
      Err: () => {
        this.unusedTicketsStore.setIsError(true)
      },
    })

    this.unusedTicketsStore.setIsLoading(false)
  }
}
