import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Search } from '@etta/ui/search'
import { LocationPicker } from '@etta/components/location-picker'
import { getLocationValue } from '@fiji/utils/get-location-value'
import type { LocationPickersProps } from '../../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationPickers = observer(function LocationPickers({
  pickUpPlace,
  pickUpAddress,
  dropOffPlace,
  dropOffAddress,
  placesHistory,
  onPickUpPlaceChange,
  onDropOffPlaceChange,
  onSwitchLocations,
  validationErrors,
}: LocationPickersProps) {
  const { t } = useTranslation()

  return (
    <Search.Group>
      <LocationPicker
        recentSearchesType="carRental"
        value={pickUpPlace}
        onChange={onPickUpPlaceChange}
        placeholder={t(`${i18Base}.Input.SelectLocation`)}
        searchType="car-rental"
        latestPlaces={placesHistory}
        isOutboundTrip
        hasCurrentLocationSearch
        inputSlot={
          <Search.Element
            wrapperAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', {
              label: t(i18Base + '.Input.PickupLocation'),
            })}
            labelTitle={t(`${i18Base}.Input.PickupLocation`)}
            value={getLocationValue(pickUpPlace, pickUpAddress)}
            shouldTruncateValueAt={19}
            errorMessage={validationErrors.pickUpPlace}
          />
        }
      />
      <Search.SwapLocationButton onClick={onSwitchLocations} />
      <LocationPicker
        recentSearchesType="carRental"
        value={dropOffPlace}
        onChange={onDropOffPlaceChange}
        placeholder={t(`${i18Base}.Input.SelectLocation`)}
        searchType="car-rental"
        latestPlaces={placesHistory}
        hasCurrentLocationSearch
        inputSlot={
          <Search.Element
            wrapperAriaLabel={t(i18Base + '.Input.PlaceAriaLabel', {
              label: t(i18Base + '.Input.DropoffLocation'),
            })}
            labelTitle={t(`${i18Base}.Input.DropoffLocation`)}
            value={getLocationValue(dropOffPlace, dropOffAddress)}
            shouldTruncateValueAt={19}
            errorMessage={validationErrors.dropOffPlace}
          />
        }
      />
    </Search.Group>
  )
})
