import { useTranslation } from 'react-i18next'
import { useCarRentalPolicyReasons } from '@etta/components/car-rental-details/use-car-rental-policy-reasons'
import type { Policy } from '@fiji/graphql/types'
import {
  OutOfPolicyReasonContainer,
  OutOfPolicyMultipleReasonsContainer,
  OutOfPolicyReasonTextList,
  TooltipOopReasonsContainer,
  OutOfPolicyReasonText,
  OutOfPolicyReasonTitle,
  OutOfPolicyMultipleReasonsTitle,
} from './car-rental-details-desktop-tooltip-oop-reasons-styled'

type Props = {
  carPolicy?: Policy
}

export function CarRentalDetailsDesktopTooltipOopReasons({ carPolicy }: Props) {
  const { t } = useTranslation()
  const { isOnePolicyReason, outOfPolicyTexts } = useCarRentalPolicyReasons(carPolicy)

  return (
    <TooltipOopReasonsContainer>
      {isOnePolicyReason && outOfPolicyTexts && (
        <OutOfPolicyReasonContainer>
          <OutOfPolicyReasonTitle>{t('CarRentalDetails.OutOfPolicy')}: </OutOfPolicyReasonTitle>
          <OutOfPolicyReasonText>{outOfPolicyTexts[0]}</OutOfPolicyReasonText>
        </OutOfPolicyReasonContainer>
      )}
      {!isOnePolicyReason && outOfPolicyTexts && (
        <OutOfPolicyMultipleReasonsContainer>
          <OutOfPolicyMultipleReasonsTitle>
            {t('CarRentalDetails.OopReasonsTitle')}:
          </OutOfPolicyMultipleReasonsTitle>
          {outOfPolicyTexts.map((text) => (
            <OutOfPolicyReasonTextList key={text}>{text}</OutOfPolicyReasonTextList>
          ))}
        </OutOfPolicyMultipleReasonsContainer>
      )}
    </TooltipOopReasonsContainer>
  )
}
