import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { AllocatedSeat } from '@etta/screens/payment-summary/types'
import type { RailTransportationMode, SeatReservationType } from '@fiji/graphql/types'
import { useRailRow } from './use-rail-row'

type Props = {
  origin: string
  destination: string
  departDate: Date
  arrivalDate: Date
  serviceClass?: string
  allocatedSeat: AllocatedSeat | null
  railSeatReservationType?: SeatReservationType
  transportationMode?: RailTransportationMode
}

export function RailRow({
  origin,
  destination,
  departDate,
  arrivalDate,
  allocatedSeat,
  serviceClass,
  railSeatReservationType,
  transportationMode,
}: Props) {
  const { dateLabel, checkSeatInfo, hasAllocatedSeat, allocatedSeatItem } = useRailRow({
    departDate,
    arrivalDate,
    serviceClass,
    allocatedSeat,
    railSeatReservationType,
    transportationMode,
  })

  return (
    <Block marginBottom={5}>
      <Text variant="footnoteMedium" color="bodyText" isBlock>
        <span data-tracking-id="rail-origin-text">{origin}</span> -{' '}
        <span data-tracking-id="rail-destination-text">{destination}</span>
      </Text>
      <Text variant="footnoteMedium" color="bodyText" isBlock>
        <span data-tracking-id="rail-date-text">{dateLabel}</span>
      </Text>
      {allocatedSeat &&
        allocatedSeat.length > 0 &&
        allocatedSeat.map((item) => (
          <Text key={item.seat} variant="footnoteMedium" color="bodyText" isBlock>
            <span data-tracking-id={`rail-seat-text-${item.seat}-${item.carriage}`}>
              {allocatedSeatItem(item)}
            </span>
          </Text>
        ))}
      {!hasAllocatedSeat && (
        <Text variant="footnoteMedium" color="bodyText" isBlock>
          <span>{checkSeatInfo}</span>
        </Text>
      )}
    </Block>
  )
}
