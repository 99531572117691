import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background4};
  min-height: 80px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Title = styled.div``

export const Description = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.mainText1};
`
