import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CarRentalAmenities } from '../../../types'
import { Amenities } from './amenities'
import {
  Container,
  Header,
  Title,
  CarOutOfPolicy,
  LogoImage,
  LogoContainer,
} from './car-rental-details-header-mobile-styled'

type Props = {
  imageUrl?: string | null
  carRentalTitle?: string
  isOutOfPolicy: boolean
  amenities: CarRentalAmenities[]
}

export function CarRentalDetailsHeaderMobile({
  carRentalTitle,
  imageUrl,
  isOutOfPolicy,
  amenities,
}: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <Title>
          <Text variant="title3">{carRentalTitle}</Text>
          <LogoContainer>
            {imageUrl && <LogoImage src={imageUrl} alt={carRentalTitle} />}
          </LogoContainer>
        </Title>
        {isOutOfPolicy && (
          <CarOutOfPolicy>
            <Icon name="outOfPolicyPWA" size="small" />
            <Text variant="footnoteMedium">{t('CarRentalDetails.OutOfPolicy')}</Text>
          </CarOutOfPolicy>
        )}
      </Header>
      <Amenities amenities={amenities} />
    </Container>
  )
}
