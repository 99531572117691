import styled from 'styled-components'
import type { DelimiterColor, LineType } from './types'

export const Separator = styled.div<{
  lineType?: LineType
  color?: DelimiterColor
  width?: number
  margin?: string
  isAutoWidth?: boolean
}>`
  ${(props) => (props.isAutoWidth ? '' : 'width: 100%;')}
  border-bottom: ${({ theme, width = 1, lineType = 'solid', color = 'borderLight' }) =>
    `${width}px ${lineType} ${theme.colors[color]}`};
  ${(props) => (props.margin ? 'margin: ' + props.margin : '')}
`
