import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { formatLocation } from '@etta/modules/car-rental-search/interface/stores/format-location'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { DescendantItem, DescendantList, DescendantsWrapper } from '@etta/components/descendant'
import type { LayoutProps } from '../../../types'
import { getDistance } from './get-distance'
import { Place } from './desktop-place'
import { LocationsLoadingDesktopSkeleton } from './locations-loading-desktop-skeleton'
import { CarRentalDynamicSiteMessages } from './dynamic-site-messages'
import {
  Container,
  ErrorContainer,
  Header,
  NoResultsContainer,
} from './car-rental-desktop-content-styled'
import { useCarRentalDesktopContent } from './use-car-rental-desktop-content'
import { Confirmation } from './confirmation'
import { TextInput } from './text-input'

export const CarRentalDesktopContent = observer(function CarRentalDesktopContent({
  searchPoint,
  locations,
  messages,
  shouldUseKm,
  isDropoff,
  hasLocations,
  searchValue,
  onLocationClick,
  onSearchChange,
  isDropOffSameAsPickUp,
  selectedPickUpLocation,
  selectedDropOffLocation,
  handleConfirmationLocationChange,
  handleConfirmationLocationConfirm,
  handleSameDropOffToggle,
  handleEditSearch,
}: LayoutProps) {
  const { t } = useTranslation()

  const { carRentalLocationActions, carRentalStore } = useCarRentalSearchContext()
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { isLocationsLoading, isRestrictedCountryError, restrictedErrorMessage } = carRentalStore
  const { carRentalDSMLightboxToggler } = dynamicSiteMessagesTogglersStore

  const {
    isConfirmationPage,
    headerTitle,
    subTitle,
    inputPlaceholder,
    desktopSubtitle,
  } = useCarRentalDesktopContent({
    isDropoff,
    searchPoint,
  })

  const distanceTitle = shouldUseKm
    ? t('TripPlanner.CarRentalOfficeSearch.km')
    : t('TripPlanner.CarRentalOfficeSearch.mi')

  if (isRestrictedCountryError) {
    return (
      <NoResultSearchScreen
        type="car-rental-alert"
        title={t('SearchSegments.NoResultErrorScreen.RestrictedCountryErrorTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.ErrorCarRentalRestrictedCountrySubTitle', {
          customMessage: restrictedErrorMessage?.length ? `(${restrictedErrorMessage})` : '',
        })}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
            onClick: carRentalLocationActions.handleLoadData,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (carRentalStore.isError) {
    return (
      <ErrorContainer>
        <NoResultSearchScreen
          type="car-rental-office-desktop-error"
          isSubTitleBold
          subTitle={t('SearchSegments.NoResultErrorScreen.ErrorCarRentalDesktopSubTitle')}
          buttons={[
            {
              title: t('SearchSegments.NoResultErrorScreen.TryAgain'),
              onClick: carRentalLocationActions.handleLoadData,
            },
            {
              title: t('SearchSegments.NoResultErrorScreen.EditSearch'),
              onClick: handleEditSearch,
            },
          ]}
        />
      </ErrorContainer>
    )
  }

  if (isConfirmationPage) {
    return (
      <Confirmation
        isDropOffSameAsPickUp={isDropOffSameAsPickUp}
        selectedPickUpLocation={selectedPickUpLocation}
        selectedDropOffLocation={selectedDropOffLocation}
        handleConfirmationLocationChange={handleConfirmationLocationChange}
        handleConfirmationLocationConfirm={handleConfirmationLocationConfirm}
        handleSameDropOffToggle={handleSameDropOffToggle}
      />
    )
  }

  if (!isLocationsLoading && !hasLocations) {
    return (
      <NoResultsContainer>
        <NoResultSearchScreen
          type="car-rental-office-desktop"
          title={t('SearchSegments.NoResultErrorScreen.NoCarRentalLocationsResultTitle')}
          subTitle={t('SearchSegments.NoResultErrorScreen.NoPickUpPointsCarRentalDesktopSubTitle')}
          buttons={[
            {
              title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
              onClick: handleEditSearch,
            },
          ]}
        />
      </NoResultsContainer>
    )
  }

  return (
    <>
      <DescendantsWrapper>
        <CarRentalDynamicSiteMessages messages={messages} />
        <Header>
          <Text
            align="left"
            variant="title2"
            aria-label={`${headerTitle} ${t('Accessibility.For')} ${subTitle}`}>
            {headerTitle}
          </Text>
          <Text
            align="left"
            variant="footnoteMedium"
            color="bodyText"
            aria-label={`${headerTitle} ${t('Accessibility.For')} ${subTitle}`}>
            {desktopSubtitle}
          </Text>
          <Block paddingVertical={12} position="relative">
            <TextInput
              placeholder={inputPlaceholder}
              onChange={onSearchChange}
              value={searchValue || ''}
              ariaLabel={`${t('Accessibility.SearchBar')}. ${t(
                'Accessibility.TapTo',
              )} ${inputPlaceholder}`}
            />
          </Block>
        </Header>
        <Container>
          <Swap is={isLocationsLoading} isSlot={<LocationsLoadingDesktopSkeleton />}>
            <DescendantList>
              {locations?.map((place, index) => (
                <Block marginBottom={14} key={`${index}-${place.address.countryCode}`}>
                  <DescendantItem onSelect={() => onLocationClick(place)}>
                    <Place
                      formattedLocation={formatLocation(place)}
                      vendors={place.vendors}
                      isAirport={place.airportLocation}
                      isOpen={place.isOpen}
                      distance={getDistance(place, { shouldUseKm, title: distanceTitle })}
                    />
                  </DescendantItem>
                </Block>
              ))}
            </DescendantList>
          </Swap>
        </Container>
      </DescendantsWrapper>
      <DynamicSiteMessagesLightboxModal
        messages={messages}
        isVisible={carRentalDSMLightboxToggler.isOpen}
        onClose={carRentalDSMLightboxToggler.handleClose}
      />
    </>
  )
})
