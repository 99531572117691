import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { CustomerUberAccountStatus } from '@fiji/graphql/hooks'

export function mapToUberAccountStatusEnum(
  input: CustomerUberAccountStatus,
): UberAccountStatusEnum {
  switch (input) {
    case CustomerUberAccountStatus.NotFound:
      return UberAccountStatusEnum.NotFound
    case CustomerUberAccountStatus.NotLinked:
      return UberAccountStatusEnum.NotLinked
    case CustomerUberAccountStatus.SiteEmptyMapping:
      return UberAccountStatusEnum.SiteEmptyMapping
    case CustomerUberAccountStatus.SiteNotEnabled:
      return UberAccountStatusEnum.SiteNotEnabled
    case CustomerUberAccountStatus.Unspecified:
      return UberAccountStatusEnum.Unspecified
    case CustomerUberAccountStatus.Ready:
      return UberAccountStatusEnum.Ready
  }
}
