import { clone } from 'ramda'
import type { Rate } from '@fiji/graphql/types'

export function subtractRate(total: Rate, used: Rate) {
  const result = clone(total)
  result.primary.amount -= used.primary.amount

  if (result.secondary && used.secondary) {
    result.secondary.amount -= used.secondary.amount
  }

  return result
}
