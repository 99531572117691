import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { SegmentType } from '@fiji/graphql/types'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useAppDispatch } from '@fiji/store'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { ROUTES } from '@fiji/routes'
import { getQueryParamsRTP } from './get-query-params'
import { useGetPlaceByCityName } from './use-get-place-by-city-name'

type Args = {
  itineraryId: string
  segments: TripSegmentValueObject[]
  bookingId?: string
}

export function useHotelPlaceRTP({ itineraryId, segments, bookingId }: Args) {
  const { navigateTo } = useHotelSearchQuery()
  const dispatch = useAppDispatch()
  const params = getQueryParamsRTP({
    segments,
    forType: SegmentType.Hotel,
  })
  const { place } = useGetPlaceByCityName({ cityName: params?.to.name })
  const { addSegmentModalsActions } = useReviewTripContext()
  const {
    multipleHotelProvidersStore,
    multipleHotelProvidersActions,
  } = useMultipleHotelProvidersContext()

  const { areMultipleProvidersExist } = multipleHotelProvidersActions
  const { multipleHotelProvidersRTPDialog } = multipleHotelProvidersStore

  const isDesktop = useScreenType() !== ScreenType.Mobile
  const {
    featureFlags: { isDesktopLayoutRTPFlowEnabled },
  } = useFeatureFlags()

  const onAddHotel = () => {
    if (areMultipleProvidersExist()) {
      multipleHotelProvidersRTPDialog.handleOpen()
      return
    }

    if (isDesktop && isDesktopLayoutRTPFlowEnabled) {
      addSegmentModalsActions.addHotel()
      return
    }

    const url = ROUTES.hotel.search

    dispatch(updateTripReviewStatus('update'))

    navigateTo(url, {
      itineraryId,
      location: place ? place : params?.to,
      checkInDate: params?.startDate,
      checkOutDate: params?.endDate,
      bookingId,
      selectedTab: isDesktop ? undefined : SegmentType.Hotel,
    })
  }

  return {
    onAddHotel,
  }
}
