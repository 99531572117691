import { ROOM_PROVIDERS } from '@fiji/constants'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'

type Props = {
  segments: TripSegmentValueObject[]
}

type Result = {
  code: string
  name: string
}

export function getMembershipAvailability({ segments }: Props) {
  const typeCheck = SegmentTypeCheckActions.getInstance()
  return {
    hotelMembershipsDisabled: segments
      .filter(typeCheck.isHotelSegment)
      .reduce((acc: Result[], hotel) => {
        const source = hotel.source
        if (source === ROOM_PROVIDERS.BOOKINGCOM || source === ROOM_PROVIDERS.TRIPSOURCE) {
          acc.push({ code: hotel.vendorCode || '', name: hotel.name })
        }
        return acc
      }, []),
    // TODO: Once we know more about other segments,
    // implement the same logic from above to car, air and rail
    carRentalMembershipsDisabled: false,
    airMembershipsDisabled: false,
    railMembershipsDisabled: false,
  }
}
