import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { CopyableWrapper } from '@etta/ui/copyable-value'
import type { CheckInData } from '../../use-trip-flight'
import { useUniqCheckInList } from '../../use-trip-flight'
import { Row, Cell, CellName, CellValue } from '../traveler-styled'
import { CheckInButton } from './check-in-button'

type Props = {
  checkInData: CheckInData
}

const i18nBase = 'PostBooking.FlightDetails'

export function CheckInList({ checkInData }: Props) {
  const { t } = useTranslation()
  const onCheckInButtonClick = (airlineUrl: string) => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(airlineUrl, '_blank', 'noopener')
  }
  const uniqCheckInList = useUniqCheckInList(checkInData)

  return (
    <>
      {uniqCheckInList.map(
        ({ isAvailable, airlineName, airlineUrl, confirmationNumber }, index) => (
          <Fragment key={index}>
            <Separator margin="16px 0" />
            <Block marginBottom={16}>
              <Row
                aria-label={`${airlineName} ${t(
                  `${i18nBase}.Confirmation`,
                )}: ${confirmationNumber}`}>
                <Cell>
                  <CellName data-tracking-id="airline-confirmation-text">
                    {airlineName} {t(`${i18nBase}.Confirmation`)}
                  </CellName>
                  <CellValue
                    aria-label={confirmationNumber}
                    data-tracking-id="airline-confirmation-number-text">
                    <Block isFlexBlock>
                      <CopyableWrapper value={confirmationNumber}>
                        {confirmationNumber}
                      </CopyableWrapper>
                      <CheckInButton
                        airlineUrl={airlineUrl}
                        isAvailable={isAvailable}
                        onClick={onCheckInButtonClick}
                      />
                    </Block>
                  </CellValue>
                </Cell>
              </Row>
            </Block>
          </Fragment>
        ),
      )}
    </>
  )
}
