import { Inject, Action } from '@etta/di'
import type { UnvalidatedTicket, ValidatedTicketValueObject } from '@etta/modules/review-trip/core'
import { FeatureFlagsStore } from '@etta/modules/feature-flags/interface/stores/feature-flags.store'
import { UnusedTicketCostBreakdownStore } from '../stores/unused-ticket-cost-breakdown.store'

@Action()
export class UnusedTicketCostBreakdownAction {
  constructor(
    @Inject()
    private readonly unusedTicketCostBreakdownStore: UnusedTicketCostBreakdownStore,
    private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  handleOpen(unsuedTicket: ValidatedTicketValueObject | UnvalidatedTicket | null | undefined) {
    if (!this.featureFlagsStore.flags.isUnusedTicketsEnabled) {
      return
    }
    this.unusedTicketCostBreakdownStore.setIsOpen(true)
    this.unusedTicketCostBreakdownStore.setUnusedTicket(unsuedTicket)
  }
  handleClose() {
    this.unusedTicketCostBreakdownStore.setIsOpen(false)
    this.unusedTicketCostBreakdownStore.setUnusedTicket(null)
  }
}
