import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import { HORIZONTAL_PADDING_MEDIUM, SUBMIT_BUTTON_MIN_WIDTH } from '../../../constants'
import type { LayoutProps } from '../../types'
import { Container } from './checkout-page-modal-footer-desktop-styled'

export function CheckoutPageModalFooterDesktop({
  submitLabel,
  closeLabel,
  onSubmit,
  onClose,
  submitBtnType,
  isDisabled,
}: LayoutProps) {
  return (
    <Modal.Footer withBorder padding="normal">
      <Block paddingHorizontal={HORIZONTAL_PADDING_MEDIUM}>
        <Container>
          <Button onClick={onClose} variant="text" size="normal-wide" paddings={false}>
            {closeLabel}
          </Button>
          <Button
            disabled={isDisabled}
            minWidth={SUBMIT_BUTTON_MIN_WIDTH}
            onClick={onSubmit}
            size="normal-wide"
            btnType={submitBtnType}>
            {submitLabel}
          </Button>
        </Container>
      </Block>
    </Modal.Footer>
  )
}
