import { Store } from '@etta/di'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { ItinerarySegment } from '../../core'
import { toItinerarySegments } from './get-itinerary-segments'

@Store()
export class RideHailSearchItineraryStore {
  isLoading: boolean = false
  isError: boolean = false

  segments: SegmentPostBookingValueObject[] | null = null

  get itinerarySegments(): ItinerarySegment[] {
    return toItinerarySegments(this.segments)
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setSegments(segments: SegmentPostBookingValueObject[]) {
    this.segments = segments
  }

  dropSegments() {
    this.segments = null
  }

  dropStore() {
    this.isError = false
    this.isLoading = false
    this.segments = null
  }
}
