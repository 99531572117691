import styled, { css } from 'styled-components'
import { subHeadMedium } from '@fiji/style'

export const Option = styled.li<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 54px;
  cursor: pointer;
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    left: 46px;
    border-bottom: 1px solid ${(p) => p.theme.colors.background1};
  }
  ${subHeadMedium}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: auto;
      color: ${({ theme }) => theme.colors.bodyText};

      :hover {
        background-color: transparent;
      }
    `}
`

export const IconContainer = styled.div`
  width: 48px;
  margin: 0 4px;
  text-align: center;
`
