import type { HTMLAttributes } from 'react'
import type { IconColorPalette, IconNames } from '../icon'
import { Icon } from '../icon'
import { Portal } from '../portal'
import { Button } from './floating-action-button-styled'

type Props = HTMLAttributes<HTMLButtonElement> & {
  iconName: IconNames
  iconColor: IconColorPalette
}

export function FloatingActionButton({ iconName, iconColor, style, onClick }: Props) {
  return (
    <Portal target="#fabs-container">
      <Button
        type="button"
        style={style}
        onClick={onClick}
        data-tracking-id="mobility-itinerary-floating-button">
        <Icon name={iconName} color={iconColor} size={36}></Icon>
      </Button>
    </Portal>
  )
}
