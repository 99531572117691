import { observer } from 'mobx-react-lite'
import { Radio } from '@etta/ui/radio'
import type { FilterOptionsProps } from '../../types'
import { StyledBlock } from '../../car-rental-filters-styled'
import { useTranmissionOptions } from './use-transmission-options'

export const TranmissionOptions = observer(function TranmissionOptions({
  optionsPaddingVertical = 18,
  withBottomBorder = true,
}: FilterOptionsProps) {
  const { options, isChecked, handleSelectTransmission, anyOptionLabel } = useTranmissionOptions()

  return (
    <>
      <StyledBlock paddingVertical={optionsPaddingVertical} withBottomBorder={withBottomBorder}>
        <Radio
          data-tracking-id="tranmission-option-any"
          value={anyOptionLabel}
          isChecked={isChecked('any')}
          onChange={handleSelectTransmission()}>
          {anyOptionLabel}
        </Radio>
      </StyledBlock>
      {options.map(({ id, title }) => {
        return (
          <StyledBlock
            paddingVertical={optionsPaddingVertical}
            key={id}
            withBottomBorder={withBottomBorder}>
            <Radio
              data-tracking-id={`tranmission-option-${id}`}
              value={title}
              isChecked={isChecked(id)}
              onChange={handleSelectTransmission(id)}>
              {title}
            </Radio>
          </StyledBlock>
        )
      })}
    </>
  )
})
