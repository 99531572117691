import { Maybe } from 'fnscript'
import type {
  SupportArticle as SupportArticleGQL,
  SupportArticleImage as SupportArticleImageGQL,
} from '@fiji/graphql/types'

import type {
  SupportArticle as SupportArticleValueObject,
  SupportArticleImage as SupportArticleImageValueObject,
} from '@etta/modules/support-travel/core/support-article.value-object'

export function mapToSupportArticleValueObject(
  input: SupportArticleGQL[],
): SupportArticleValueObject[] {
  return input.map((article: SupportArticleGQL) => {
    const image = Maybe.from(article.image)
    return {
      id: article.id,
      title: article.title,
      body: article.body,
      image: image.isValue() ? mapToSupportArticleImageValueObject(image.getValue()) : undefined,
      isFeatured: Maybe.from<boolean | undefined>(article.isFeatured).withDefault(undefined),
    }
  })
}

function mapToSupportArticleImageValueObject(
  input: SupportArticleImageGQL,
): SupportArticleImageValueObject {
  return {
    alt: Maybe.from<string | undefined>(input.alt).withDefault(undefined),
    title: Maybe.from<string | undefined>(input.title).withDefault(undefined),
    url: Maybe.from<string | undefined>(input.url).withDefault(undefined),
  }
}
