import { PaymentDynamicSiteMessagesLayout } from './layout'
import type { Props } from './types'

export function PaymentDynamicSiteMessages({ dynamicSiteMessages }: Props) {
  if (!dynamicSiteMessages?.length) {
    return null
  }

  return <PaymentDynamicSiteMessagesLayout dynamicSiteMessages={dynamicSiteMessages} />
}
