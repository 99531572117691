import type { SelectedCarFiltersValueObject } from '@etta/modules/car-rental-search/core/value-objects/selected-car-filters.value-object'
import type { CarRentalsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import type { FilterOptionValueObject } from '@etta/core/value-objects'

type Args = {
  selected: SelectedCarFiltersValueObject
  filters?: CarRentalsValueObject['filters']
}

function orderBySelected(options: Array<FilterOptionValueObject>, selectedValues: string[]) {
  const selected: Array<FilterOptionValueObject> = []
  const unselected: Array<FilterOptionValueObject> = []

  options.forEach((option) => {
    if (selectedValues.includes(option.id)) {
      selected.push(option)
      return
    }

    unselected.push(option)
  })

  return selected.concat(unselected)
}

function mapOption(code: string, title: string, imageUrl?: string) {
  return {
    id: code,
    title,
    imageUrl,
  }
}

export function getFilterOption({ selected, filters }: Args) {
  const transmission = filters?.transmission?.map((c) => mapOption(c.code, c.name)) || []

  const carClass = filters?.class?.map((c) => mapOption(c.code, c.name)) || []

  const vendors = filters?.vendor.map((c) => mapOption(c.code, c.name, c.imageUrl)) || []

  const fuelTypes = filters?.fuel.map((c) => mapOption(c.code, c.name)) || []

  return {
    carClasses: orderBySelected(carClass, selected.carClass),
    vendors: orderBySelected(vendors, selected.vendors),
    fuelTypes: orderBySelected(fuelTypes, selected.fuelType),
    transmission: orderBySelected(transmission, selected.transmission),
  }
}
