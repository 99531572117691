import { CarRentalSearchResults } from '@etta/screens/car-rental-search-results'
import { CarRentalLocations } from '@etta/screens/car-rental-locations'
import { CarRentalSearchPage } from '@etta/screens/car-rental-search-page'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const carRentalRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.carRental.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Car Rental Search'),
    transitionIndex: 3,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <CarRentalSearchPage />,
  },
  {
    route: {
      path: ROUTES.carRental.pickUp,
      exact: true,
    },
    documentTitle: getDocumentTitle('Car Rental Locations'),
    transitionIndex: 3.1,
    transitionGroup: 'slide',
    component: () => <CarRentalLocations />,
  },
  {
    route: {
      path: ROUTES.carRental.dropOff,
      exact: true,
    },
    documentTitle: getDocumentTitle('Car Rental Locations'),
    transitionIndex: 3.2,
    transitionGroup: 'slide',
    component: () => <CarRentalLocations />,
  },
  {
    route: {
      path: ROUTES.carRental.confirmation,
      exact: true,
    },
    documentTitle: getDocumentTitle('Car Rental Locations'),
    transitionIndex: 3.2,
    transitionGroup: 'slide',
    component: () => <CarRentalLocations />,
  },
  {
    route: {
      path: ROUTES.carRental.results,
      exact: true,
    },
    documentTitle: getDocumentTitle('Car Rental Results'),
    transitionIndex: 3.3,
    transitionGroup: 'slide',
    component: () => <CarRentalSearchResults />,
  },
]
