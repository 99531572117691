import type { Provider } from 'react'
import type { DescendantOptions } from './type'
import { useDescendants } from './use-descendants'
import { useDescendant } from './use-descendant'
import { cast } from './helpers'
import { DescendantsContextProvider, useDescendantsContext } from './descendant-provider'
import type { DescendantsManager } from './descendants-manager'

export function createDescendantContext<
  T extends HTMLElement = HTMLElement,
  K extends Record<string, any> = {}
>() {
  type ContextProviderType = Provider<DescendantsManager<T, K>>
  const ContextProvider = cast<ContextProviderType>(DescendantsContextProvider)

  const _useDescendantsContext = () => cast<DescendantsManager<T, K>>(useDescendantsContext())

  const _useDescendant = (options?: DescendantOptions<K>) => useDescendant<T, K>(options)

  const _useDescendants = () => useDescendants<T, K>()

  return [ContextProvider, _useDescendantsContext, _useDescendants, _useDescendant] as const
}
