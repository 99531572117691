import styled from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  position: relative;
  ${getKeyboardNavigationStyle({ borderRadius: 8, offset: 2 })}
`

export const InfiniteScrollPlaceHolder = styled.div`
  height: 1px;
  width: 100%;
`
