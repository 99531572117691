import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import { ErrorContainer } from '@etta/ui/error-container'
import { LazyContent } from '@etta/ui/lazy-content'
import { InfoModal } from '../../info-modal'
import { useRulesModal } from './use-rules-modal'
import { RulesModalLayout } from './layout'

type Props = {
  carSegmentId: string
  itineraryId: string
  onClose: () => void
}

export function RulesModalContent({ carSegmentId, itineraryId, onClose }: Props) {
  const { t } = useTranslation()
  const title = t('CarRentalDetailsModal.RulesModal.Title')

  const { carRules, isLoading, isError, onReload } = useRulesModal({
    carSegmentId,
    itineraryId,
  })

  return (
    <>
      <InfoModal.Header title={title} onClose={onClose} />
      <Swap is={!!isError} isSlot={<ErrorContainer onReload={onReload} />}>
        <LazyContent isLoading={isLoading}>
          <InfoModal.Content>
            <RulesModalLayout carRules={carRules} />
          </InfoModal.Content>
        </LazyContent>
      </Swap>
    </>
  )
}
