import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { RailCardModal } from '@etta/components/rail-card-modal/rail-card-modal'
import { Icon } from '@etta/ui/icon'
import { useRailCard } from '../../use-rail-cards'
import type { Props } from '../../types'
import { Container, StyledBlock } from './rail-cards-desktop-styled'

export const RailCardsDesktop = observer(function RailCard({ railCards, layout }: Props) {
  const {
    railCardModal,
    placeHolderDesktop,
    ariaLabelDesktop,
    currentUKCard,
    handleSelectUKCard,
    handleSelectEUCard,
    handleClearCardSelection,
    cardGroups,
    railCardsLoading,
    errorText,
    isCardSelected,
  } = useRailCard({ railCards, layout })

  return (
    <Container>
      <StyledBlock isFlexBlock={isCardSelected} isCardSelected={isCardSelected}>
        {isCardSelected && (
          <Block marginHorizontal={2} onClick={handleClearCardSelection}>
            <Icon name="closePWA" color="primary" size="small" />
          </Block>
        )}
        <Block aria-label={ariaLabelDesktop} onClick={railCardModal.handleOpen} isFlexBlock={true}>
          <Text variant="subHeadStrong" color="primary">
            {currentUKCard.name.length ? currentUKCard.name : placeHolderDesktop}
          </Text>
          <Block marginLeft={5}>
            <Icon name="chevronDownPWA" color="primary" size="small" />
          </Block>
        </Block>
      </StyledBlock>

      <RailCardModal // TODO: AC of VIVA-2944 not to show saved cards. Add here savedCards from useRailCard hook later.
        isOpen={railCardModal.isOpen}
        onClose={railCardModal.handleClose}
        layout={layout}
        cardGroups={cardGroups}
        onSelectUKCard={handleSelectUKCard}
        onSelectEUCard={handleSelectEUCard}
        cardsLoading={railCardsLoading}
        errorText={errorText}
      />
    </Container>
  )
})
