import { Store, Inject } from '@etta/di'
import i18n from '@fiji/i18n'
import { CostAllocationStore } from './cost-allocation.store'

export type ValidationErrors = {
  costAllocation?: string
}

@Store()
export class CostAllocationFormStore {
  constructor(
    @Inject()
    private readonly costAllocationStore: CostAllocationStore,
  ) {}

  validationErrors: ValidationErrors = {}

  checkCostAllocation() {
    if (!this.costAllocationStore.isCostAllocationsValid) {
      this.setSingleValidationError(
        'costAllocation',
        i18n.t('TripPlanner.BaseSearch.Input.SelectCostAllocation'),
      )
      return false
    }

    this.setSingleValidationError('costAllocation', '')
    return true
  }
  setSingleValidationError(key: keyof ValidationErrors, value: string) {
    if (!this.validationErrors) {
      return
    }

    this.validationErrors = { ...this.validationErrors, [key]: value }
  }

  resetValidationErrors() {
    this.validationErrors = {}
  }
}
