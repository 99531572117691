import type { ReactNode } from 'react'
import { Block } from '@etta/ui/block'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { FailedBookingButton } from '../../types'
import { FailedSegmentBody } from './failed-segment-body'
import { Wrapper } from './unconfirmed-failed-segment.styled'

type Props = {
  errorDescription: string
  segmentTitle: string
  segmentDescription?: string | ReactNode
  buttons: FailedBookingButton[]
}

export function UnconfirmedFailedSegment({
  errorDescription,
  segmentTitle,
  segmentDescription,
  buttons,
}: Props) {
  const screenType = useScreenType()
  return (
    <Block paddingHorizontal={24}>
      <Wrapper isMobile={screenType === ScreenType.Mobile}>
        <FailedSegmentBody
          variant={'unconfirmed'}
          errorDescription={errorDescription}
          segmentTitle={segmentTitle}
          segmentDescription={segmentDescription}
          buttons={buttons}
        />
      </Wrapper>
    </Block>
  )
}
