import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Props } from '../types'
import {
  DatesParamsBlock,
  IconContainer,
  InfoBlock,
  LocationsParamsBlock,
  Wrapper,
} from './selected-search-parameters-block-styled'

const i18nBase = 'SearchSegments.Header'

export function SelectedSearchParametersBlock({
  onLabelClick,
  isDisabled = false,
  locationsLabel,
  locationsSlot,
  datesLabel,
  withIcon,
}: Props) {
  const { t } = useTranslation()

  return (
    <Wrapper
      onClick={isDisabled ? undefined : onLabelClick}
      aria-label={t(`${i18nBase}.EditSearch`)}
      data-tracking-id="result-header-button"
      disabled={isDisabled}>
      <InfoBlock>
        <LocationsParamsBlock
          data-tracking-id="result-header-origin-destination-block"
          isDisabled={isDisabled}>
          {locationsSlot || locationsLabel}
        </LocationsParamsBlock>
        <DatesParamsBlock
          data-tracking-id="result-header-date-block"
          isDisabled={isDisabled}
          aria-label={datesLabel}>
          {datesLabel}
        </DatesParamsBlock>
      </InfoBlock>
      {withIcon && (
        <IconContainer>
          <Icon name="searchPWA" size="normal" className="searchIcon" />
          <Icon name="editPWA" size="normal" className="editIcon" />
        </IconContainer>
      )}
    </Wrapper>
  )
}
