import { useTranslation } from 'react-i18next'
import type { SearchRideHailResult } from '@fiji/graphql/hooks'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { formatPrice } from '@fiji/utils/money'
import type { SearchRideHailResultValueObject } from '@etta/modules/ride-hail/core'
import {
  Container,
  Body,
  Footer,
  ImageContainer,
  Image,
  Content,
  CarName,
  CarPassengers,
  CarInfo,
  Price,
  RideDetail,
  PickUpTime,
} from './mobility-search-result-card-styled'

type Props = {
  searchResult: SearchRideHailResultValueObject
  isOnDemand: boolean
  onCardClick: (rideHailResult: SearchRideHailResult) => void
}

const i18nBase = 'Mobility.SearchResultPage.'

export function MobilitySearchResultCard({ searchResult, isOnDemand, onCardClick }: Props) {
  const { t } = useTranslation()
  const {
    imageUrl,
    displayName,
    passengerCapacity,
    shortDescription,
    estimatedPrice,
    estimatedWaitingTime,
  } = searchResult

  return (
    <Container onClick={() => onCardClick(searchResult)}>
      <Body>
        <ImageContainer>{imageUrl && <Image src={imageUrl} alt={displayName} />}</ImageContainer>
        <Content>
          <CarName>
            <Text variant="headline" color="mainText1">
              {displayName}
            </Text>
          </CarName>
          <RideDetail>
            <CarPassengers>
              <Block paddingRight={2}>
                <Icon name="peopleFilledNewPWA" size="small" />
              </Block>
              <Text variant="footnoteMedium" color="mainText1">
                {passengerCapacity}
              </Text>
            </CarPassengers>
            {isOnDemand && (
              <PickUpTime>
                <Block paddingRight={2}>
                  <Icon name="timerPWA" size="small" />
                </Block>
                <Text variant="footnoteMedium" color="mainText1">
                  {t(`${i18nBase}MinAway`, { minutes: (estimatedWaitingTime ?? 0) / 60 })}
                </Text>
              </PickUpTime>
            )}
          </RideDetail>
          <CarInfo>
            <Text variant="footnoteMedium" color="mainText1">
              {shortDescription}
            </Text>
          </CarInfo>
        </Content>
      </Body>
      <Footer>
        <Text variant="captionMedium" color="bodyText">
          {t(i18nBase + 'PriceText')}
        </Text>
        {estimatedPrice && (
          <Price>
            <Text variant="headline" color="mainText1">
              {formatPrice(estimatedPrice)}
            </Text>
          </Price>
        )}
      </Footer>
    </Container>
  )
}
