import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Swap } from '@etta/ui/swap'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { CountrySearch } from '@etta/components/country-search/country-search'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { usePassportContext } from '@etta/modules/booking'
import type { PassportEntity } from '@etta/core/entities'
import { HalfBlockInline } from './passport-form-styled'
import { usePassport } from './use-passport'
import { PassportFormDatePickerField } from './passport-from-date-picker-field'

type Props = {
  isVisible: boolean
  isPassportDisabled?: boolean
  passport?: PassportEntity
  handleModalVisibility: () => void
}

const i18nBase = 'Passport.'

export const PassportForm = observer(function PassportForm({
  isPassportDisabled,
  isVisible,
  passport,
  handleModalVisibility,
}: Props) {
  const { t } = useTranslation()
  const { passportStore, passportActions } = usePassportContext()
  const { inputErrors: errors, passportInput: values } = passportStore
  const { handleFieldChange } = passportActions

  const { viewStateParams, onSave, onRemove, getFormatDate } = usePassport({
    passport,
    handleModalClose: handleModalVisibility,
  })

  const {
    isOpen: isPassportExpVisible,
    handleOpen: onPassportExpOpen,
    handleClose: onPassportExpClose,
  } = useTogglePopup()

  const {
    isOpen: isPassportIssueVisible,
    handleOpen: onPassportIssueOpen,
    handleClose: onPassportIssueClose,
  } = useTogglePopup()

  return (
    <>
      <CheckoutPageModal isVisible={isVisible} onClose={handleModalVisibility}>
        <CheckoutPageModal.Header
          onClose={handleModalVisibility}
          title={t(i18nBase + (passport ? 'TitleForInfo' : 'TitleForAdd'))}
          withBorderBottom
        />
        <CheckoutPageModal.Body>
          <Block>
            <TextField
              value={values.number}
              onChange={handleFieldChange('number')}
              label={t(`${i18nBase}Number`)}
              helperType="error"
              helperText={errors?.number}
              isDisabled={isPassportDisabled}
            />
            <Block marginTop={12}>
              <CountrySearch
                customLabel={t(i18nBase + 'IssuingCountry')}
                value={values.country}
                setValue={handleFieldChange('country')}
                helperType="error"
                helperText={errors?.country}
                isDisabled={isPassportDisabled}
              />
            </Block>
            <Block marginTop={12}>
              <CountrySearch
                value={values.issuingCountry}
                setValue={handleFieldChange('issuingCountry')}
                helperType="error"
                helperText={errors?.issuingCountry}
                isDisabled={isPassportDisabled}
              />
            </Block>
            <HalfBlockInline marginTop={12} position="relative">
              <PassportFormDatePickerField
                value={getFormatDate(values.expirationDate ?? null)}
                label={t(`${i18nBase}DateExpire`)}
                pickerLabel={t(`${i18nBase}SelectDateExpire`)}
                onFieldClick={onPassportExpOpen}
                helperText={errors?.expirationDate ?? ''}
                isDisabled={!!isPassportDisabled}
                isPickerOpen={isPassportExpVisible}
                date={values.expirationDate ?? new Date()}
                onSelect={handleFieldChange('expirationDate')}
                onPickerClose={onPassportExpClose}
                variant="expiration-date"
              />
              <PassportFormDatePickerField
                value={getFormatDate(values.issuingDate ?? null)}
                label={t(i18nBase + 'DateIssue')}
                pickerLabel={t(i18nBase + 'SelectDateIssue')}
                onFieldClick={onPassportIssueOpen}
                helperText={errors?.issuingDate ?? ''}
                isDisabled={!!isPassportDisabled}
                isPickerOpen={isPassportIssueVisible}
                date={values.issuingDate ?? new Date()}
                onSelect={handleFieldChange('issuingDate')}
                onPickerClose={onPassportIssueClose}
                variant="issue-date"
              />
            </HalfBlockInline>
          </Block>
        </CheckoutPageModal.Body>
        <Swap
          is={isPassportDisabled}
          isSlot={
            <CheckoutPageModal.Footer
              onSubmit={onRemove}
              submitLabel={t(i18nBase + 'Remove')}
              onClose={handleModalVisibility}
              submitBtnType="secondary"
            />
          }>
          <CheckoutPageModal.Footer
            onSubmit={onSave}
            submitLabel={t(i18nBase + 'Save')}
            onClose={handleModalVisibility}
          />
        </Swap>
      </CheckoutPageModal>
      <ViewStateModal viewState={viewStateParams.viewState} onDone={viewStateParams.onClose} />
    </>
  )
})
