import { useTranslation } from 'react-i18next'
import { CheckoutPageModalFooterLayout } from './layout'
import type { Props } from './types'

export function CheckoutPageModalFooter({
  submitLabel,
  closeLabel,
  onSubmit,
  onClose,
  submitBtnType,
  isDisabled,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'ActionButtons'

  return (
    <CheckoutPageModalFooterLayout
      submitLabel={submitLabel}
      closeLabel={closeLabel || t(`${i18Base}.Cancel`)}
      onSubmit={onSubmit}
      onClose={onClose}
      submitBtnType={submitBtnType}
      isDisabled={isDisabled}
    />
  )
}
