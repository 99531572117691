import styled from 'styled-components'
import { headline, title3 } from '@fiji/style/typography'

export const ControlPanel = styled.div`
  display: flex;
  margin: 24px 0 36px 0;
`

export const TimeSelector = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 9px;
  width: 96px;
  height: 80px;
  cursor: pointer;
`

export const TimeLabel = styled.div``

export const TimeSeparator = styled.span`
  &:after {
    color: ${(p) => p.theme.colors.mainText};
    font-size: 56px;
    line-height: 64px;
    height: 100%;
    content: ':';
    position: absolute;
    top: 4px;
    left: -8px;
  }
  margin: 0 12px;
  position: relative;
`

export const AMPMSwtich = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.white};
  width: 52px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  overflow: hidden;
`

export const AMPMButton = styled.button<{ selected: boolean }>`
  ${headline}
  flex-grow: 1;
  color: ${(p) => (p.selected ? p.theme.colors.primary1 : p.theme.colors.mainText)};
  background-color: ${(p) => p.theme.colors.white};
  border: none;
  cursor: pointer;
`

export const AMPMSeparator = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const Clock = styled.div`
  margin: 12px;
`

export const ClockFace = styled.div`
  width: 256px;
  height: 256px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.white};
  position: relative;
`

export const Dial = styled.div`
  position: absolute;
  top: 124px;
  left: 124px;
  width: 8px;
  height: 8px;
  background-color: ${(p) => p.theme.colors.primary1};
  border-radius: 50%;
`

export const MarkSet = styled.div<{ top: string; left: string }>`
  position: absolute;
  top: ${(p) => p.top};
  left: ${(p) => p.left};
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;

  &.selected {
    color: ${(p) => p.theme.colors.white};
  }
`

export const Mark = styled.div<{ active: boolean }>`
  ${title3}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 48px;
  text-align: center;
  opacity: ${(p) => (p.active ? '1' : '0')};

  transition: opacity 0.4s ease-in-out;
`

export const Hand = styled.div<{ degree: number; transition: boolean }>`
  position: absolute;
  left: calc(50% - 24px);
  top: 2px;
  height: calc(50% - 2px);
  width: 48px;
  transform-origin: center bottom;
  transform: rotate(${(p) => p.degree}deg);

  ${(p) => (p.transition ? `transition: transform 0.4s ease-in-out;` : ``)}
  z-index: 0;
`

export const HandLine = styled.div`
  position: absolute;
  left: calc(50% - 1px);
  top: 0;
  height: 100%;
  width: 2px;
  background-color: ${(p) => p.theme.colors.primary1};
`

export const Pendulum = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.colors.primary1};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`
