import { useCallback } from 'react'
import type { TimeRange } from '@fiji/types'
import { TimeRangeDirection } from '@fiji/enums'
import {
  initialCustomTime,
  anyTimeOption,
  customOriginTime,
  customDestinationTime,
  railTimeRangeOptions,
} from './constants'

export function useRailTimeConfiguration() {
  const getInitialTimeRange = useCallback((timeRangeBy: TimeRangeDirection) => {
    return {
      ...anyTimeOption,
      customTimeHours:
        timeRangeBy === TimeRangeDirection.Departure
          ? customOriginTime.customTimeHours
          : customDestinationTime.customTimeHours,
      timeRangeBy,
    }
  }, [])

  const timeOptionsKeys = Object.values(railTimeRangeOptions) as Array<TimeRange>

  return {
    initialCustomTime,
    getInitialTimeRange,
    timeOptions: timeOptionsKeys,
  }
}
