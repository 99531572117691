import { useMemo, useCallback } from 'react'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import { useDynamicSiteMessagesContext } from '../../interface/use-dynamic-site-messages.context'
import { DynamicSiteMessagesModalLayout } from './layout'

type Props = {
  messages?: DynamicSiteMessageValueObject[] | null
  isVisible: boolean
  onClose: () => void
}

export function DynamicSiteMessagesModal({ messages = [], isVisible, onClose }: Props) {
  const { dynamicSiteMessagesAction } = useDynamicSiteMessagesContext()

  const messagesToShow = useMemo(() => {
    return messages
      ? dynamicSiteMessagesAction.mapMessages(messages).filter(({ isDialog }) => isDialog)
      : []
  }, [messages, dynamicSiteMessagesAction])

  const handleOnClose = useCallback(() => onClose(), [onClose])

  if (!messagesToShow.length) {
    return null
  }

  return (
    <DynamicSiteMessagesModalLayout
      messages={messagesToShow}
      isVisible={isVisible}
      onClose={handleOnClose}
    />
  )
}
