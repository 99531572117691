import { Carousel } from '@etta/ui/carousel'
import type { Photo } from '@fiji/graphql/types'
import { CarouselImagePlaceholder } from './carousel-image-placeholder'
import { CarouselImage } from './carousel-image'

type Props = {
  photos: Photo[]
}

export function HotelPhotosCarousel({ photos }: Props) {
  return (
    <Carousel isCenterMode={false} previewImage={<CarouselImagePlaceholder />} withCounter withDots>
      {photos.map((photo) => (
        <CarouselImage key={photo.index} src={photo.url} />
      ))}
    </Carousel>
  )
}
