import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { CarRentalCarDetailsValueObject } from '../../core/value-objects'
import type { SelectedCarDetails } from '../types'

@Store()
export class CarRentalCarDetailsStore {
  carDetailsToggle = new Toggle()

  isLoadingCarDetails = false

  isErrorCarDetails = false

  carDetails: CarRentalCarDetailsValueObject | null = null

  selectedCarDetails: SelectedCarDetails | null = null

  get carClass() {
    return this.carDetails?.details.class || null
  }

  get carType() {
    return this.carDetails?.details.type || null
  }

  get pickUp() {
    return this.carDetails?.pickUp || null
  }

  get dropOff() {
    return this.carDetails?.dropOff || null
  }

  get isInPolicy() {
    return this.carDetails?.policy.isInPolicy
  }

  get vendorCode() {
    return this.carDetails?.vendor.code || null
  }

  get vendorName() {
    return this.carDetails?.vendor.name || null
  }

  get vendorImage() {
    return this.carDetails?.vendor.imageUrl || null
  }

  get isPreferred() {
    return !!this.carDetails?.isPreferred
  }

  get safetyCheckMessageCode() {
    return this.carDetails?.safetyCheck.messageCode || null
  }

  get safetyCheckLink() {
    return this.carDetails?.safetyCheck.url || null
  }

  get carImage() {
    return this.carDetails?.details.carDetailsImageUrl || this.carDetails?.details.imageUrl || null
  }

  get baseRate() {
    return this.carDetails?.baseRate || null
  }

  get carCostRates() {
    return this.carDetails?.carCostRates || null
  }

  get feesAndTaxesRate() {
    return this.carDetails?.taxes || null
  }

  get totalCostRate() {
    return this.carDetails?.totalCost || null
  }

  setCarDetails(carDetails: CarRentalCarDetailsValueObject | null) {
    this.carDetails = carDetails
  }

  setSelectedCarDetails(value: SelectedCarDetails | null) {
    this.selectedCarDetails = value
  }

  setIsLoadingCarDetails(value: boolean) {
    this.isLoadingCarDetails = value
  }

  setIsErrorCarDetails(value: boolean) {
    this.isErrorCarDetails = value
  }

  dropCarDetails() {
    this.carDetailsToggle.handleClose()
    this.setCarDetails(null)
    this.setSelectedCarDetails(null)
    this.setIsLoadingCarDetails(false)
    this.setIsErrorCarDetails(false)
  }
}
