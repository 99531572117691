import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  SubTitle,
  ContinueTitle,
  ContainerContinue,
  EndIcon,
  StartIconMedium,
  IconWrapper,
  ContinueButton,
} from '../../../blocks-mobile-styles'

export function BlockContinueMobile({
  title,
  subTitle,
  buttonTitle,
  isLoading,
  onContinue,
}: LayoutProps) {
  return (
    <ContainerContinue>
      <IconWrapper>
        <StartIconMedium>
          <SeatIcon icon="seat" />
        </StartIconMedium>
      </IconWrapper>
      <div>
        <ContinueTitle>
          {title}
          <EndIcon>
            <SeatIcon icon="alert" />
          </EndIcon>
        </ContinueTitle>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <ContinueButton variant="outline" size="small" onClick={onContinue} disabled={isLoading}>
          {buttonTitle}
        </ContinueButton>
      </div>
    </ContainerContinue>
  )
}
