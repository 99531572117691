import type { PassportEntity } from '@etta/core/entities'
import { getCountryDecoded } from '@fiji/utils/country'

type Args = {
  passport: PassportEntity
  withDots?: boolean
}

export function usePassportExcerpt({ passport, withDots }: Args) {
  const passportNumber = withDots ? passport.number.replaceAll('*', '•') : passport.number
  const passportExpirationDate = new Date(passport.expirationDate || '').toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
  )
  const countryDecoded = getCountryDecoded(passport.issuingCountry)

  return {
    passportNumber,
    passportExpirationDate,
    countryDecoded,
  }
}
