import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { compareAsc } from '@fiji/utils/dates/compare-dates'

export type ItineraryPoint = {
  arrivalDate: string
  city: string
  countryCode: string
  airportCode?: string
}

export function useItineraryPoints(segments: TripSegmentValueObject[]) {
  const points: ItineraryPoint[] = []
  const typeCheck = SegmentTypeCheckActions.getInstance()

  segments?.forEach((segment) => {
    if (typeCheck.isTrainSegment(segment)) {
      segment.segments.map(({ arrivalDate, arrival }) => {
        points.push({
          city: arrival.address.city,
          countryCode: arrival.address.countryCode,
          arrivalDate,
        })
      })
    }
    if (typeCheck.isFlightSegment(segment)) {
      segment.segments.map(({ arrivalDate, arrival }) => {
        points.push({
          city: arrival.address?.city!,
          countryCode: arrival.address?.countryCode!,
          airportCode: arrival.airportCode!,
          arrivalDate,
        })
      })
    }

    if (typeCheck.isCarRentalSegment(segment)) {
      const address = segment.dropOffLocation.address

      points.push({
        city: address.city,
        countryCode: address.countryCode,
        arrivalDate: segment.dropOffTime,
      })
    }

    if (typeCheck.isHotelSegment(segment)) {
      points.push({
        city: segment.address?.city,
        countryCode: segment.address?.countryCode,
        arrivalDate: segment.checkIn,
      })
    }
  })

  return points
    .sort((pointA, pointB) => {
      return compareAsc(new Date(pointA.arrivalDate), new Date(pointB.arrivalDate))
    })
    .pop()
}
