import styled from 'styled-components'
import { body, title3 } from '@fiji/style'
import { Header as EttaHeader } from '@etta/ui/header'

export const CustomHeader = styled(EttaHeader)`
  padding: 0 12px 0 24px;
  min-height: 64px;
`

export const Container = styled.div`
  padding: 0 0 20px;
  display: flex;
  flex: 1;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
  color: ${(props) => props.theme.colors.mainText};
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  padding: 14px 24px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  background: ${(props) => props.theme.colors.white};
`

export const PolicyExplanation = styled.div`
  padding: 32px 20px 25px;
  ${body};
`

export const PolicyExplanationTitle = styled.div`
  margin: 0 0 10px;
  ${title3};
`
