import type { SearchRailLegSubSegment } from '@fiji/graphql/types'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'
import { getDurationBetweenDates } from '@fiji/utils/dates/get-duration-between-dates'
import type { AdaptedRailSegment } from './types'

export function getLayover(
  arrivalDate: SearchRailLegSubSegment['departureDate'],
  nextDepartureDate: SearchRailLegSubSegment['arrivalDate'],
): AdaptedRailSegment['layover'] {
  if (!nextDepartureDate) {
    return null
  }

  const { hours, minutes } = getDurationBetweenDates(
    isoToDate(arrivalDate),
    isoToDate(nextDepartureDate),
  )

  if (!hours) {
    return `${minutes ?? 0}m`
  }

  return `${hours}h ${minutes}m`
}
