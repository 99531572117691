import type {
  SwipingTimePickerBars,
  SwipingTimePickerUnits,
  SwipingPickerSubtype,
} from '../../types'
import { TimeStampSet } from './time-points-styled'
import { TimeUnits } from './time-units'

type Props = {
  type: SwipingTimePickerUnits
  variant?: SwipingPickerSubtype
  range: number[]
  topValues: SwipingTimePickerBars
  height: number
  activeUnit: number
  swiping: number
  flipping: SwipingTimePickerBars
  unitGroup?: number
  hourlyRange?: number
  startYear: number
}

export function TimePoints({
  type,
  variant,
  range,
  topValues,
  height,
  activeUnit,
  swiping,
  flipping,
  unitGroup,
  hourlyRange,
  startYear,
}: Props): JSX.Element {
  return (
    <>
      <TimeStampSet
        top={topValues[0]}
        range={range.length}
        height={height}
        swiping={swiping}
        flipping={flipping[0]}>
        <TimeUnits
          startYear={startYear}
          pilar={0}
          variant={variant}
          type={type}
          unitGroup={unitGroup}
          hourlyRange={hourlyRange}
          range={range}
          height={height}
          activeUnit={activeUnit}
        />
      </TimeStampSet>
      <TimeStampSet
        top={topValues[1]}
        range={range.length}
        height={height}
        swiping={swiping}
        flipping={flipping[1]}>
        <TimeUnits
          startYear={startYear}
          pilar={1}
          variant={variant}
          type={type}
          unitGroup={unitGroup}
          hourlyRange={hourlyRange}
          range={range}
          height={height}
          activeUnit={activeUnit}
        />
      </TimeStampSet>
      {(['hour', 'month'].includes(type) || (type === 'min' && variant !== 'only-min')) && (
        <>
          <TimeStampSet
            top={topValues[2]}
            range={range.length}
            height={height}
            swiping={swiping}
            flipping={flipping[2]}>
            <TimeUnits
              startYear={startYear}
              pilar={2}
              variant={variant}
              type={type}
              unitGroup={unitGroup}
              hourlyRange={hourlyRange}
              range={range}
              height={height}
              activeUnit={activeUnit}
            />
          </TimeStampSet>
          <TimeStampSet
            top={topValues[3]}
            range={range.length}
            height={height}
            swiping={swiping}
            flipping={flipping[3]}>
            <TimeUnits
              startYear={startYear}
              pilar={3}
              variant={variant}
              type={type}
              unitGroup={unitGroup}
              hourlyRange={hourlyRange}
              range={range}
              height={height}
              activeUnit={activeUnit}
            />
          </TimeStampSet>
        </>
      )}
    </>
  )
}
