import { observer } from 'mobx-react-lite'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { Segments } from '../../../types'
import { useTripDetails } from '../use-trip-details'
import { RailDeliveryGuideModal } from './rail-delivery-guide-modal'
import { Wrapper } from './trip-segment-styled'
import { SegmentLayout } from './segment-layout'
import { FlightLeg } from './flight-leg'
import { HotelRoom } from './hotel-room'
import { RentalCar } from './rental-car'
import { RailTrain } from './rail-train'

type Props = {
  segments: Segments
  isOpen: boolean
  isNDCTrip: boolean
  handleClose: () => void
}

export const TripSegment = observer(function ({ segments, isOpen, isNDCTrip, handleClose }: Props) {
  const { postBookingTripStore } = usePostBookingContext()
  const { flight, hotel, car, rail } = postBookingTripStore.segmentsConfirmationInfo

  // data from review trip store (old way, to be removed)
  const { flight: oldFlight } = segments
  const { isWebFare } = useTripDetails({ segments })
  return (
    <Wrapper>
      {flight && (
        <>
          <SegmentLayout
            details={flight.details}
            // payment={{ ...flight.payment, card: oldFlight?.payment.card }} // TODO see if flight cost can be broken down to legs
            payment={oldFlight?.payment}
            isWebFare={isWebFare}
            isNDCTrip={isNDCTrip}>
            {flight.legs?.map((leg, index) => (
              <FlightLeg key={index} leg={leg} isWebFare={leg.isWebFare} />
            ))}
          </SegmentLayout>
        </>
      )}
      {hotel && (
        <SegmentLayout details={hotel.details}>
          {hotel.rooms?.map((room, index) => (
            <HotelRoom key={index} room={room} />
          ))}
        </SegmentLayout>
      )}
      {car && (
        <SegmentLayout details={car.details}>
          {car.rentals?.map((rentalCar, index) => (
            <RentalCar key={index} rentalCar={rentalCar} />
          ))}
        </SegmentLayout>
      )}
      {rail && (
        <SegmentLayout details={rail.details} payment={rail?.payment} isRailPayment>
          <>
            {rail.trains?.map((train, index) => (
              <RailTrain key={index} train={train} />
            ))}
            <CheckoutPageModal isVisible={isOpen} onClose={handleClose}>
              <RailDeliveryGuideModal onClose={handleClose} />
            </CheckoutPageModal>
          </>
        </SegmentLayout>
      )}
    </Wrapper>
  )
})
