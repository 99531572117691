import { Text } from '@etta/ui/text'
import { TooltipTextContainer, TooltipTextItem } from './tooltip-multiline-content-styled'

type Props = {
  title?: string
  textItems: string[]
}

export function TooltipMultilineContent({ title, textItems }: Props) {
  const showTitle = !!title && textItems.length > 1

  return (
    <TooltipTextContainer>
      {showTitle && <Text variant="subHeadStrong">{title}</Text>}
      {textItems.map((text) => (
        <TooltipTextItem showTitle={showTitle}>
          <Text variant="footnoteMedium" key={text}>
            {text}
          </Text>
        </TooltipTextItem>
      ))}
    </TooltipTextContainer>
  )
}
