import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field/types'
import { getYupScheme } from './use-get-yup-scheme'

type Props = {
  fields: CustomFieldCollectionItemExtension[]
  onSubmit(values: Record<string, string>): void
}

export function useForm({ fields, onSubmit }: Props) {
  const scheme = useScheme(() => {
    const customFieldsScheme = fields.reduce<{ [K: string]: any }>((acc, item) => {
      return {
        ...acc,
        ...item.customFields.reduce<typeof acc>((customAcc, customField) => {
          const yupScheme = getYupScheme({ customField })

          customAcc[customField.customUuid] = yupScheme

          return customAcc
        }, {}),
      }
    }, {})
    return {
      ...customFieldsScheme,
    }
  }, [fields])

  const { values, errors, submit, onFieldChange } = useValidation(scheme)

  const onSubmitHandler = async () => {
    const submitData = await submit()

    if (submitData.isValid) {
      return onSubmit(values)
    }
  }

  return {
    values,
    errors,
    onFieldChange,
    onSubmitHandler,
  }
}
