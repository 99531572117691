import type { Place } from '@fiji/types'
import type { Address, FlightSearchTimeRange } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'

type PlaceWithAddress = Place & { address?: Address }

type Props = {
  value?: number
  originPlace?: Place | null
  destinationPlace?: Place | null
  forSegment?: SegmentType
  flightSearchTimeRange?: FlightSearchTimeRange
  flightSearchTimeRangeNonUS?: FlightSearchTimeRange
}

function getCountryCodeFromPlace(place?: PlaceWithAddress | null) {
  return place?.address?.countryCode || place?.countryCode
}

export function getFlightCustomTimeRange({
  value,
  forSegment,
  originPlace,
  destinationPlace,
  flightSearchTimeRange,
  flightSearchTimeRangeNonUS,
}: Props) {
  if (!value) {
    return
  }

  const isOriginLocationUSorCA =
    getCountryCodeFromPlace(originPlace) === 'US' || getCountryCodeFromPlace(originPlace) === 'CA'

  const isDestinationLocationUSorCA =
    getCountryCodeFromPlace(destinationPlace) === 'US' ||
    getCountryCodeFromPlace(destinationPlace) === 'CA'

  const flightSearchRangeSource = {
    before: isOriginLocationUSorCA
      ? flightSearchTimeRange?.before
      : flightSearchTimeRangeNonUS?.before,
    after: (destinationPlace ? isDestinationLocationUSorCA : isOriginLocationUSorCA)
      ? flightSearchTimeRange?.after
      : flightSearchTimeRangeNonUS?.after,
  }

  const MINUTES_IN_HOUR = 60
  const hoursBeforeSearchTime = flightSearchRangeSource?.before
    ? flightSearchRangeSource.before / MINUTES_IN_HOUR
    : null
  const hoursAfterSearchTime = flightSearchRangeSource?.after
    ? flightSearchRangeSource.after / MINUTES_IN_HOUR
    : null

  const beforeSearchRange =
    forSegment === SegmentType.Flight
      ? hoursBeforeSearchTime || 1
      : forSegment === SegmentType.Train
      ? 0
      : 1
  const afterSearchRange = forSegment === SegmentType.Flight ? hoursAfterSearchTime || 1 : 1

  const startTimeValue =
    value - beforeSearchRange < 0 ? { hours: 0 } : { hours: value - beforeSearchRange }
  const endTimeValue =
    value + afterSearchRange >= 24
      ? { hours: 23, minutes: 59 }
      : { hours: value + afterSearchRange }

  return {
    startTimeHours: value - beforeSearchRange < 0 ? 0 : value - beforeSearchRange,
    endTimeHours: value + afterSearchRange >= 24 ? 24 : value + afterSearchRange,
    startTimeRange: getFormattedTime(startTimeValue),
    endTimeRange: getFormattedTime(endTimeValue),
  }
}
