export function numeric(oldValue: string, value: string) {
  const result = parseFloat(value)
  if (!value) {
    return value
  }
  if (isNaN(result)) {
    return String(oldValue)
  }
  return result.toFixed(0)
}
