import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '@fiji/routes'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Content } from './no-modify-segment-styled'

type Props = {
  isOpen: boolean
  type: string
  onClose: () => void
}

const i18nBase = 'PostBooking.TripDetails.NoModifySegment'

export function NoModifySegment({ type, isOpen, onClose }: Props) {
  const history = useHistory()
  const { t } = useTranslation()
  const handleSupportRedirect = () => history.push(ROUTES.support.main)

  return (
    <Dialog onClose={onClose} isOpen={isOpen} horizontalDimension="narrow-desktop">
      <Content>
        <Text variant="title3" color="mainText">
          {`${t(`${i18nBase}.Title`)} ${type}`}
        </Text>
        <Block marginTop={24}>
          <Text variant="footnoteMedium" color="bodyText" align="center">
            {t(`${i18nBase}.ErrorDescription`, {
              type: type.toLowerCase(),
            })}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          <Button variant="solid" onClick={handleSupportRedirect} fullWidth>
            {t(`${i18nBase}.Support`)}
          </Button>
        </Block>
        <Block isFullWidth marginTop={14}>
          <Button variant="outline" onClick={onClose} fullWidth>
            {t(`${i18nBase}.Back`)}
          </Button>
        </Block>
      </Content>
    </Dialog>
  )
}
