import { Row } from '../../row'
import type { Props } from '../types'
import { getIconName } from '../get-icon-name'
import { TitleContainer } from '../../title-container'
import { ListItem } from './list-mobile-styled'

export function ListMobile({
  list,
  getItemProps,
  searchType,
  highlightedIndex,
  title,
  startIndex = 0,
  iconSize = 'large',
}: Props) {
  if (!list.length) {
    return null
  }

  return (
    <>
      <TitleContainer aria-label={title}>{title}</TitleContainer>

      {list.map((item, i) => {
        const index = startIndex + i

        return (
          <li>
            <ListItem
              {...getItemProps({ item, index })}
              key={item.placeId || item.name}
              active={highlightedIndex === index}>
              <Row
                searchType={searchType}
                countryName={item.countryName}
                isGrouped={item.isGrouped}
                icon={getIconName(item)}
                iconSize={iconSize}>
                {item.name}
              </Row>
            </ListItem>
          </li>
        )
      })}
    </>
  )
}
