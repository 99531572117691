import { Inject, Service } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { RailSearchQueryType } from '@fiji/hooks/search-queries/use-rail-search-query/types'
import { RailSearchFormStore } from '../stores/rail-search-form.store'

@Service()
export class RailSearchFormQueryService {
  constructor(
    @Inject()
    private readonly railSearchFormStore: RailSearchFormStore,
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  navigateTo(route: string, params?: Partial<RailSearchQueryType>) {
    const nextPath = this.railSearchFormStore.railSearchQueryParams.getNextPath({
      route,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  appendQueryParams(params: Partial<RailSearchQueryType>) {
    const nextPath = this.railSearchFormStore.railSearchQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: this.historyStore.search,
      newQueryParams: params,
    })

    this.historyService.replace(nextPath)
  }
}
