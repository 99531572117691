import { useTranslation } from 'react-i18next'

export function useErrorMsgComposed(errorMsg?: string[] | null) {
  const { t } = useTranslation()
  const i18nBase = 'PurchaseReservationError.Description'
  if (!errorMsg) {
    return null
  }
  const msg = () =>
    errorMsg.map((msg) => {
      switch (msg) {
        case 'city':
          return t(`${i18nBase}.City`)
        case 'itinerary':
          return t(`${i18nBase}.ItineraryId`)
        case 'phone':
          return t(`${i18nBase}.Phone`)
        case 'creditCard':
          return t(`${i18nBase}.CreditCard`)
        case 'address':
          return t(`${i18nBase}.Address`)
        case 'gender':
          return t(`${i18nBase}.Gender`)
        case 'outOfPolicyTripExplanation':
          return t(`${i18nBase}.OopTripExplanation`)
        case 'airComplianceCode':
          return t(`${i18nBase}.AirComplianceCode`)
        case 'hotelComplianceCode':
          return t(`${i18nBase}.HotelComplianceCode`)
        case 'carComplianceCode':
          return t(`${i18nBase}.CarComplianceCode`)
        case 'billingInfo':
          return t(`${i18nBase}.BillingInfo`)
        case 'passenger':
          return t(`${i18nBase}.Passenger`)
        default:
          return ''
      }
    })

  return msg().join(' ')
}
