import type { TimeRange, RailFiltersType, Place, CabinClassType } from '@fiji/types'
import type { RailCard, SegmentType } from '@fiji/graphql/types'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'

export type RailSearchQueryType = {
  searchId: string
  itineraryId: string
  activeLegId: string
  selectedFareTier: string
  selectedServiceClasses: string[]
  // origin location
  originPlace: Place
  // destination location
  destinationPlace: Place
  // origin date and time
  originDate: Date
  originTime: TimeRange
  // destination date and time
  destinationDate?: Date
  destinationTime: TimeRange
  // cabin class
  cabinClass: CabinClassType // FIXME change to serviceClass
  departureSeats: SegmentSeatNumber[]
  bookingId?: string
  filters: RailFiltersType
  selectedTab?: SegmentType
  searchRestrictions?: RailSearchRestrictions
  railTripType: RailTripType
  railCards?: RailCard[]
}

export type Rail = {
  originPlace: Place
  destinationPlace: Place
  originTime: TimeRange
  destinationTime: TimeRange
  originDate: Date
  destinationDate?: Date
  railTripType: RailTripType
  railCards?: RailCard[]
}

export enum RailSearchRestrictions {
  NoCarrierNoDestinationChange = 'NoCarrierNoDestinationChange',
  NoDestinationChange = 'NoDestinationChange',
  NoCarrierChange = 'NoCarrierChange',
}

export enum RailTripType {
  OneWay = 'Oneway',
  Round = 'Round',
  OpenReturn = 'OpenReturn',
}
