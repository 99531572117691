import { css } from 'styled-components'
import { buttonLarge, buttonNormal, buttonSmall } from '@fiji/style'
import type { MobileButtonSizeProps } from './button-props'

export function getStylesByButtonSize(size: MobileButtonSizeProps) {
  switch (size) {
    case 'large':
      return css`
        ${buttonLarge};
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        border-radius: 1rem;
      `
    case 'normal':
      return css`
        ${buttonNormal};
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        border-radius: 0.8rem;
      `
    case 'small':
      return css`
        ${buttonSmall};
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        border-radius: 0.6rem;
      `
    case 'normal-wide':
      return css`
        ${buttonNormal};
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 0.8rem;
      `
  }
}
