import { useCallback } from 'react'
import { useViewState } from '@fiji/hooks/use-view-state'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { triggerBase64Download } from '@fiji/utils/download/base64-downloader'

export function useDownloadETickets(errorMessage: string) {
  const { railActions } = useRailContext()
  const { trip } = usePostBookingContext().postBookingTripStore
  const viewStateParams = useViewState()
  const onChangeViewState = viewStateParams.onChangeViewState

  const handleDownloadETicket = useCallback(async () => {
    try {
      onChangeViewState('downloading')
      const response = await railActions.getETickets(`${trip.bookingRef}`)
      response.match({
        Ok: (eTickets) => {
          if (eTickets.tickets.length) {
            eTickets.tickets.forEach((eTicket, index) => {
              triggerBase64Download(eTicket, `e-ticket-${trip.bookingRef}-${index}`)
            })
          } else {
            throw new Error(`No eTickets available for itinerary ${trip.bookingRef}`)
          }
        },
        Err: () => {
          throw new Error(`Error retrieving eTickets for itinerary ${trip.bookingRef}`)
        },
      })
      onChangeViewState('downloaded')
      await delayForSuccess(2000)
      onChangeViewState('hidden')
    } catch {
      onChangeViewState('error')
    }
  }, [onChangeViewState, railActions, trip.bookingRef])

  return {
    handleDownloadETicket,
    viewStateParams: {
      ...viewStateParams,
      error: errorMessage,
    },
  }
}
