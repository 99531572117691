export enum FareTierEnum {
  Restricted = 'RESTRICTED',
  Restricted_2 = 'RESTRICTED_2',
  RestrictedExpectFees = 'RESTRICTED_EXPECT_FEES',
  RestrictedExpectFees_2 = 'RESTRICTED_EXPECT_FEES_2',
  RestrictedExpectFees_3 = 'RESTRICTED_EXPECT_FEES_3',
  RestrictedExpectFees_4 = 'RESTRICTED_EXPECT_FEES_4',
  Unmodifiable = 'UNMODIFIABLE',
  Unrestricted = 'UNRESTRICTED',
}
