import { useTranslation } from 'react-i18next'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { ScreenType, useScreenType } from '@fiji/modes'
import { LONDON_UNDERGROUND_OPERATOR } from '@fiji/constants'
import { TrainDeliveryOptionCode } from '@fiji/enums'
import type { ModalPosition } from '@fiji/types'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'

type Args = {
  train: TrainPostBookingValueObject
}

export function useTripTrainModal({ train }: Args) {
  const { scrollContainerRef, headerRef } = useHeaderScroll()
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const modalPosition: ModalPosition = isMobile ? 'center' : 'right'

  const segments = train.segments?.filter(
    (segment) => segment.carrierName !== LONDON_UNDERGROUND_OPERATOR,
  )
  const firstSegment = segments?.[0] || undefined
  const lastSegment = segments?.length ? segments?.[segments?.length - 1] : undefined

  const arrivalCountryName = lastSegment?.stations?.arrival?.countryName
  const arrivalPlace = lastSegment?.stations?.arrival?.stationName + ', ' + arrivalCountryName

  const headerTitle = t('PostBooking.TrainDetails.TrainToHeader', { arrivalPoint: arrivalPlace })

  // Info Card
  const isCollect = train.selectedDeliveryOption?.code === TrainDeliveryOptionCode.Collect
  const travelerName = [train.traveler?.firstName, train.traveler?.lastName].join(' ')
  const transactionId = train?.transactionId ?? '-'
  const collectionRefNumber = train?.collectionReferences?.length
    ? train?.collectionReferences?.join(', ')
    : null

  // Permitted Stations Info
  const permittedOriginStations = train.permittedOriginStations
  const permittedDestinationStations = train.permittedDestinationStations
  const hasPermittedStations =
    permittedOriginStations.length > 0 || permittedDestinationStations.length > 0
  const originStation = firstSegment?.stations?.departure?.stationName || ''
  const destinationStation = lastSegment?.stations?.arrival?.stationName || ''

  // Ticket Restrictions Info
  const fareConditions = train.fareConditions?.[0]
  const ticketRestrictions: TicketRestrictionsInfo = {
    tripFare: fareConditions?.localizedFareConditions[0].fareTypeDescription || '',
    ticketRestrictions: fareConditions?.localizedFareConditions[0].conditions || [],
  }

  return {
    isMobile,
    scrollContainerRef,
    headerRef,
    modalPosition,
    headerTitle,
    isCollect,
    travelerName,
    transactionId,
    collectionRefNumber,
    segments,
    permittedOriginStations,
    permittedDestinationStations,
    hasPermittedStations,
    originStation,
    destinationStation,
    ticketRestrictions,
  }
}
