import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { ReadonlyBanner } from '../readonly-banner'
import { FlightSegmentList } from '../flight-segment-list'
import { Footer } from '../footer'
import { ViewStateModal } from '../view-state-modal'
import { SeatMap } from '../air-seat-map/seat-map'
import {
  Container,
  Content,
  ContentWrapper,
  LeftSide,
  RightSide,
  RightSideContainer,
} from './seat-map-content-styled'

type Props = {
  handleFareDetailsTabChange?: () => void
  onClose?: () => void
  isReadonly: boolean
}

export const SeatMapContent = observer(function SeatMapContent({
  handleFareDetailsTabChange,
  onClose,
  isReadonly,
}: Props) {
  const { seatMapActions, seatMapStore } = useSeatMapContext()

  const { handleSelectSeat } = seatMapActions
  const { seatMap, selectedSegment, bookedSeat } = seatMapStore
  const { isLoading, isError, rows, selectedSeat } = seatMap

  useEffect(() => {
    if (selectedSegment.isInstantPurchaseCarrier) {
      return
    }

    seatMapActions.loadSeatMap()
  }, [seatMapActions, selectedSegment.isInstantPurchaseCarrier])

  useEffect(() => {
    seatMapActions.handleSetIsReadonly(isReadonly)
  }, [seatMapActions, isReadonly])

  return (
    <Container>
      <ReadonlyBanner />
      <Content>
        <ContentWrapper>
          <LeftSide>
            <Block paddingHorizontal={32} paddingVertical={32}>
              <FlightSegmentList />
            </Block>
          </LeftSide>
          <RightSide>
            <RightSideContainer>
              <SeatMap
                seatRows={rows}
                selectedSegmentId={selectedSegment.id}
                bookedSeat={bookedSeat}
                selectedSeat={selectedSeat}
                isLoading={isLoading}
                isReadonly={isReadonly}
                isSeatMapAvailable={selectedSegment.isSeatMapAvailable}
                onSeatChecked={handleSelectSeat}
                seatsNumberList={[]}
                isUnexpectedError={isError}
                onUnavailableSeatMapContinue={() => {}}
                handleFareDetailsTabChange={handleFareDetailsTabChange}
                isNewSeatMap
              />
            </RightSideContainer>
          </RightSide>
          <Footer onClose={onClose} />
        </ContentWrapper>
      </Content>
      <ViewStateModal />
    </Container>
  )
})
