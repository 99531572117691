import { useCallback } from 'react'
import type { ChangeEvent } from 'react'
import type {
  SeatMapRowSeatValueObject,
  SelectedSeatValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import type { SeatSelectedArgs } from '@etta/modules/seat-map/core/types'
import { SeatItemContainer } from '../seat-item-container'
import type { SeatList, SelectedSeatsMap } from '../../seat-map-segment.types'
import { getActualRowLength } from '../get-actual-row-length'
import { SeatItem } from './seat-item'

type SeatRowProps = {
  largestRow: SeatList
  seatList: SeatList
  cabinClass: string
  bookedSeat?: SelectedSeatValueObject | null
  selectedSeat?: SelectedSeatValueObject | null
  isEconomyClass: boolean
  selectedSeatsMap: SelectedSeatsMap
  rowNumber: number
  mostFrequentAislesColumns: string[]
  onSeatChecked: (args: SeatSelectedArgs) => void
  isReadonly: boolean
  isNewSeatMap?: boolean
  onOpenPopover?: (event: ChangeEvent<HTMLInputElement>) => void
}

type SeatSelectionStateArgs = {
  seatItem?: SeatMapRowSeatValueObject
  bookedSeat?: SelectedSeatValueObject | null
  selectedSeat?: SelectedSeatValueObject | null
  selectedSeatsMap: SelectedSeatsMap
  isNewSeatMap?: boolean
}

function getSeatSelectionState({
  seatItem,
  selectedSeat,
  bookedSeat,
  isNewSeatMap,
  selectedSeatsMap,
}: SeatSelectionStateArgs) {
  const isSelected = seatItem ? Boolean(selectedSeatsMap[seatItem.number]) : true
  const isNewSeatMapSeatSelected =
    seatItem && selectedSeat ? seatItem.number === selectedSeat.number : false
  const isSeatBooked = seatItem && bookedSeat ? seatItem.number === bookedSeat.number : false
  const isSeatSelected = isNewSeatMap ? isNewSeatMapSeatSelected : isSelected

  return {
    isSeatBooked,
    isSeatSelected,
  }
}

export function SeatRow({
  largestRow,
  seatList,
  cabinClass,
  selectedSeatsMap,
  rowNumber,
  isEconomyClass,
  onSeatChecked,
  bookedSeat,
  selectedSeat,
  mostFrequentAislesColumns,
  isReadonly,
  isNewSeatMap,
  onOpenPopover,
}: SeatRowProps) {
  const getSeatIndex = useCallback(
    (column: string) => {
      return seatList.findIndex(({ column: seatColumn }) => seatColumn === column)
    },
    [seatList],
  )

  const actualRowLength = getActualRowLength(largestRow, mostFrequentAislesColumns)

  return (
    <>
      {largestRow.map((seat, seatIndex) => {
        const seatItemIndex = getSeatIndex(seat.column)
        const seatItem = seatList[seatItemIndex]
        const { isSeatBooked, isSeatSelected } = getSeatSelectionState({
          seatItem,
          bookedSeat,
          selectedSeat,
          selectedSeatsMap,
          isNewSeatMap,
        })

        const onKeyboardSelect = () => {
          onSeatChecked({ seat: seatItem, isSelected: !isSeatSelected, cabinClass })
        }

        return (
          <SeatItemContainer
            key={seat.number}
            isEconomyClass={isEconomyClass}
            role="cell"
            seatIndex={seatIndex}
            seatList={largestRow}
            mostFrequentAislesColumns={mostFrequentAislesColumns}
            onPress={onKeyboardSelect}
            actualRowLength={actualRowLength}
            rowNumber={rowNumber}>
            <SeatItem
              seatItem={seatItem}
              isEconomyClass={isEconomyClass}
              onChange={onSeatChecked}
              isSelected={isSeatSelected}
              isBooked={isSeatBooked}
              isReadonly={isReadonly}
              isNewSeatMap={isNewSeatMap}
              cabinClass={cabinClass}
              onOpenPopover={onOpenPopover}
            />
          </SeatItemContainer>
        )
      })}
    </>
  )
}
