import { useCallback } from 'react'
import { Tabs } from '@etta/ui/tabs'
import { Block } from '@etta/ui/block'
import type { FareSegmentSeatRows } from '@fiji/types'
import { TabContent, Dash, Container, Separator } from './segment-tabs-styles'

type SegmentTabsProps = {
  selectedSegmentId: string
  segmentSeatRows: Array<FareSegmentSeatRows>
  onChange?: (selectedSegmentId: string) => void
}

export function SegmentTabs({ selectedSegmentId, segmentSeatRows, onChange }: SegmentTabsProps) {
  const handleChangeSegment = useCallback(
    (newSegmentId) => {
      onChange?.(newSegmentId)
    },
    [onChange],
  )

  return (
    <>
      <Container>
        <Tabs activeTab={selectedSegmentId} onChangeTab={handleChangeSegment}>
          <Block marginHorizontal={16}>
            <Tabs.Header withBottomBorder={false} tabBasis={100}>
              {segmentSeatRows.map(({ origin, destination, segmentId }) => (
                <Tabs.Tab key={segmentId} value={segmentId}>
                  <TabContent>
                    {origin} <Dash />
                    {destination}
                  </TabContent>
                </Tabs.Tab>
              ))}
            </Tabs.Header>
          </Block>
        </Tabs>
      </Container>
      <Separator />
    </>
  )
}
