import styled, { css } from 'styled-components'

export const SegmentsWrapper = styled.div<{ isNewTripdetails?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 56px;
  ${({ isNewTripdetails }) =>
    isNewTripdetails &&
    css`
      gap: 0;
    `}
`

export const EcoCheckSummaryContainer = styled.div`
  padding: 40px 0;
`

export const HotelBookingFailedWrapper = styled.div`
  margin: 40px 0 25px;

  &:first-child {
    margin-top: 0;
  }
`
