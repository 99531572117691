import { useMedia } from 'react-use'
import { screenSize } from '@fiji/style'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import {
  Header,
  InputsRow,
  LocationSlot,
  RailSearchDesktopWrapper,
  RightSlot,
  Wrapper,
} from './rail-search-form-desktop-styled'

export function RailSearchFormDesktop({
  tabsSlot,
  locationSlot,
  calendarSlot,
  preSearchCustomFieldsModalSlot,
  costAllocationSlot,
  isTwoSections,
  submitButtonSlot,
}: LayoutProps) {
  const isMaxLaptop = useMedia(`only screen and (${screenSize.maxLaptop})`)

  return (
    <RailSearchDesktopWrapper isMaxLaptop={isMaxLaptop}>
      <Header>{tabsSlot}</Header>
      <Wrapper>
        <InputsRow>
          <LocationSlot isTwoSections={isTwoSections}>{locationSlot}</LocationSlot>
          <RightSlot isTwoSections={isTwoSections}>{calendarSlot}</RightSlot>
        </InputsRow>
        <Block marginLeft={isMaxLaptop ? 8 : 0}>{submitButtonSlot}</Block>
      </Wrapper>
      {costAllocationSlot}
      {preSearchCustomFieldsModalSlot}
    </RailSearchDesktopWrapper>
  )
}
