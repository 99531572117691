import { useTranslation } from 'react-i18next'
import { PurchaseCardError } from '@etta/components/purchase-card-error/purchase-card-error'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import type { BillingError } from '@fiji/types'

type Props = {
  billingError?: BillingError
  isCardExpired?: boolean
  onEdit?: () => void
}

export function RowError({ billingError, onEdit }: Props) {
  const { t } = useTranslation()

  return (
    <PurchaseCardError
      err={billingError}
      iconType="errorFilledPWA"
      iconSize="normal"
      errorMessageColor="mainText"
      errorMessageVariant="footnoteMedium"
      withMargin={false}
      buttonSlot={
        <Button
          variant="text"
          btnType="error"
          size="small"
          onClick={onEdit}
          rightIcon={<Icon name="chevronRightPWA" color="error" size="small" />}>
          {t('PaymentSummary.UpdateCard')}
        </Button>
      }
    />
  )
}
