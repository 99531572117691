import i18n from 'i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { ColorPalette } from '@fiji/types'
import type { Rate, CarCostRates } from '@fiji/graphql/types'

type Args = {
  carCostRates?: CarCostRates | null
  totalCostRate?: Rate | null
  baseRate?: Rate | null
  serviceFeeRate?: Rate | null
  feesAndTaxesRate?: Rate | null
}

export type CarRentalDetailsPrice = {
  title: string
  value: string
  color?: keyof ColorPalette
}

export function getCarRentalDetailsPrice({
  carCostRates,
  totalCostRate,
  baseRate,
  feesAndTaxesRate,
  serviceFeeRate,
}: Args): {
  priceDetailsList: CarRentalDetailsPrice[]
  estTotalCost: string
} {
  const i18Base = 'CarRentalDetails.PriceDetails'

  const { mainCost: estTotalCost } = formatRate(totalCostRate, {
    morpheme: 'none',
  })

  const priceDetailsList: CarRentalDetailsPrice[] = [
    {
      title: i18n.t(`${i18Base}.CostPerDay`),
      value: getMainCost(carCostRates?.perDayRate),
      color: 'bodyText' as keyof ColorPalette,
      isVisible: !!carCostRates?.perDayRate,
    },
    {
      title: i18n.t(`${i18Base}.ExtraDay`),
      value: getMainCost(carCostRates?.extraDayRate),
      color: 'bodyText' as keyof ColorPalette,
      isVisible: !!carCostRates?.extraDayRate,
    },
    {
      title: i18n.t(`${i18Base}.ExtraHour`),
      value: getMainCost(carCostRates?.extraHourRate),
      color: 'bodyText' as keyof ColorPalette,
      isVisible: !!carCostRates?.extraHourRate,
    },
    {
      title: i18n.t(`${i18Base}.TotalRentalCost`),
      value: getMainCost(baseRate),
      isVisible: !!baseRate,
    },
    {
      title: i18n.t(`${i18Base}.Taxes`),
      value: getMainCost(feesAndTaxesRate),
      isVisible: !!feesAndTaxesRate,
    },
    {
      title: i18n.t(`${i18Base}.ServiceFee`),
      value: getMainCost(serviceFeeRate),
      isVisible: !!serviceFeeRate,
    },
  ]
    .filter(({ isVisible }) => isVisible)
    .map(({ title, value, color }) => ({ title, value, color }))

  return {
    priceDetailsList,
    estTotalCost,
  }
}

function getMainCost(rate?: Rate | null) {
  return formatRate(rate, { morpheme: 'none' }).mainCost
}
