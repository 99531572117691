import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { AirSearchRestrictions } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { AIR_PROVIDERS } from '@fiji/constants'

type Props = {
  isChangeCarrierAllowed?: boolean
  isChangeDestinationAllowed?: boolean
  flightSegments: FlightPostBookingValueObject[] | FlightSegmentEntity[]
  isPostBooking?: boolean
}

export function getFlightSearchRestrictions({
  isChangeCarrierAllowed,
  isChangeDestinationAllowed,
  flightSegments,
  isPostBooking,
}: Props): AirSearchRestrictions | undefined {
  const isThereSouthWest = !!flightSegments
    .flatMap((leg) =>
      leg.segments?.map((segment) => segment.carrierCode === AIR_PROVIDERS.SOUTHWEST),
    )
    .filter(Boolean).length

  switch (true) {
    case !isPostBooking:
      return undefined
    case (!isChangeCarrierAllowed || isThereSouthWest) && !isChangeDestinationAllowed:
      return AirSearchRestrictions.NoCarrierNoDestinationChange
    case !isChangeCarrierAllowed || isThereSouthWest:
      return AirSearchRestrictions.NoCarrierChange
    case !isChangeDestinationAllowed:
      return AirSearchRestrictions.NoDestinationChange
    default:
      return undefined
  }
}
