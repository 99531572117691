import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AmenityIcon } from '@etta/ui/amenity-icon'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { ShowMoreContainer } from '@etta/ui/show-more-container'
import { Text } from '@etta/ui/text'
import type { FlightLegSegmentAmenities } from '@fiji/graphql/types'
import { Row } from '../../flight-details-styled'
import { IconWrapper, AmenitiesRow, AmenitiesColumn, AmenityRow } from './amenities-styled'

type Props = {
  amenities?: FlightLegSegmentAmenities | null
}

const i18BaseAmenities = 'TripPlanner.FlightAirFares'

export function Amenities({ amenities }: Props) {
  const { t } = useTranslation()
  const [isAmenitiesExpanded, setAmenitiesExpanded] = useState(false)

  const handleToggle = () => {
    setAmenitiesExpanded((prev) => !prev)
  }
  const codes = amenities?.codes

  if (!codes) {
    return null
  }

  return (
    <>
      <Block
        paddingHorizontal={16}
        paddingTop={13}
        paddingBottom={11}
        aria-expanded={isAmenitiesExpanded}>
        <Row aria-hidden={!isAmenitiesExpanded}>
          <ShowMoreContainer
            collapsedSlot={
              <AmenitiesRow>
                {codes.map((amenity) => (
                  <AmenityIcon amenity={amenity} key={amenity} />
                ))}
              </AmenitiesRow>
            }
            expandedSlot={
              <AmenitiesColumn>
                {codes.map((amenity) => (
                  <AmenityRow key={amenity}>
                    <AmenityIcon amenity={amenity} />
                    <Block marginLeft={5}>
                      <Text variant="footnoteMedium" color="mainText">
                        {t(`${i18BaseAmenities}.Amenities.${amenity}`)}
                      </Text>
                    </Block>
                  </AmenityRow>
                ))}
              </AmenitiesColumn>
            }
            isExpanded={isAmenitiesExpanded}
            isAnimated
          />
          <IconWrapper aria-label={t(i18BaseAmenities + '.AriaLabel')} onClick={handleToggle}>
            <Icon name={isAmenitiesExpanded ? 'chevronUpPWA' : 'chevronDownPWA'} size="small" />
          </IconWrapper>
        </Row>
      </Block>
    </>
  )
}
