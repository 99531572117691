import { Inject, Service } from 'typedi'
import { AirSearchAdapter } from '@etta/modules/air-search/infra/air-search.adapter'
import { AirSearchStore } from '../../stores/air-search/air-search.store'
import { AirDynamicSiteMessagesStore } from '../../stores/air-dynamic-site-messages/air-dynamic-site-messages.store'

@Service()
export class AirDynamicSiteMessagesService {
  constructor(
    @Inject() private airSearchAdapter: AirSearchAdapter,
    @Inject() private dynamicSiteMessageStore: AirDynamicSiteMessagesStore,
    @Inject() private airSearchStore: AirSearchStore,
  ) {}

  async getDynamicSiteMessages() {
    this.dynamicSiteMessageStore.setIsLoading(true)
    const searchId = this.airSearchStore.searchId

    if (this.airSearchStore.isLoading || !searchId || !this.airSearchStore.isDsmEnabled) {
      this.dynamicSiteMessageStore.setIsLoading(false)
      return
    }

    const result = await this.airSearchAdapter.getFlightDynamicSiteMessages({ searchId }, true)

    if (result.isOk()) {
      const response = result.getValue()
      this.dynamicSiteMessageStore.setDynamicSiteMessages(response.messages)
      this.dynamicSiteMessageStore.setIsLoading(false)
    }

    if (result.isErr()) {
      this.dynamicSiteMessageStore.setIsLoading(false)
    }
  }
}
