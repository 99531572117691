import { useCallback, useMemo, useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { RailCard } from '@fiji/graphql/types'
import type { RailCardGroup, HandleCardClickProps } from './types'

type Props = {
  savedCards?: RailCard[]
  cardGroups?: RailCardGroup[]
  onClose: () => void
  onSelectUKCard: (props: HandleCardClickProps) => void
  onSelectEUCard: (numbers: string[], savedCards: RailCard[]) => void
}

export function useRailCardModal({
  savedCards,
  cardGroups,
  onClose,
  onSelectUKCard,
  onSelectEUCard,
}: Props) {
  const addRailCardModal = useTogglePopup()
  const allCardsInGroups = cardGroups?.flatMap((group) => group.cards)

  const [cardsFilter, setCardsFilter] = useState('')
  const [localSavedCards, setLocalSavedCards] = useState<RailCard[] | undefined>(savedCards)
  const [selectedSavedCards, setSelectedSavedCards] = useState<string[]>([])
  const [currentClickedEuCard, setCurrentClickedEuCard] = useState('')
  const selectedSavedCardsSet = useMemo(() => {
    return new Set(selectedSavedCards)
  }, [selectedSavedCards])

  const checkSavedCard = (cardTitle: string) => selectedSavedCardsSet.has(cardTitle)

  const handleCardsFilter = useCallback((query: string) => {
    setCardsFilter(query.toLowerCase())
  }, [])

  const filteredCards: RailCardGroup[] = useMemo(() => {
    const tempFilter: RailCard[] = localSavedCards ?? []

    if (!tempFilter.length && cardGroups) {
      for (const group of cardGroups) {
        tempFilter.push(...group.cards)
      }
    }

    return [
      {
        name: '',
        cards: tempFilter.filter((card) => card.name?.toLowerCase().includes(cardsFilter)),
      },
    ]
  }, [cardGroups, cardsFilter, localSavedCards])

  const renderGroups = useMemo(() => (cardsFilter ? filteredCards : cardGroups), [
    cardGroups,
    cardsFilter,
    filteredCards,
  ])

  const handleUkCardClick = (card: HandleCardClickProps) => {
    if (!card.vendorCode) {
      return
    }

    onSelectUKCard(card)
    onClose()
  }

  const handleEuCardClick = (card: HandleCardClickProps) => {
    const isCheckBoxHandled = card.isChecked !== undefined
    if (isCheckBoxHandled) {
      const isCheckBoxChecked = card.isChecked && !!card.code

      const withOutUncheckedCard = selectedSavedCards.filter(
        (cardItem) => card.code && cardItem !== card.code,
      )

      if (isCheckBoxChecked && card.code) {
        setSelectedSavedCards([...selectedSavedCards, card.code])
        return
      }

      setSelectedSavedCards(withOutUncheckedCard)
      return
    }

    if (card.name) {
      setCurrentClickedEuCard(card.name)
      addRailCardModal.handleOpen()
    }
  }

  const handleAddSavedCard = (cardNumber: string) => {
    const newCard = {
      code: cardNumber,
      name: currentClickedEuCard,
      vendorCode:
        allCardsInGroups?.find((card) => card.name === currentClickedEuCard)?.vendorCode || '',
      vendorName:
        allCardsInGroups?.find((card) => card.name === currentClickedEuCard)?.vendorName || '',
      groupName:
        allCardsInGroups?.find((card) => card.name === currentClickedEuCard)?.groupName || '',
    }
    if (localSavedCards) {
      setLocalSavedCards([...localSavedCards, newCard])
      return
    }
    setLocalSavedCards([newCard])
  }

  const handleAddEuCardsClick = () => {
    onSelectEUCard(selectedSavedCards, localSavedCards || [])
    onClose()
  }

  return {
    addRailCardModal,
    cardsFilter,
    currentClickedEuCard,
    handleAddEuCardsClick,
    handleCardsFilter,
    handleEuCardClick,
    handleUkCardClick,
    checkSavedCard,
    renderGroups,
    selectedSavedCards,
    localSavedCards,
    handleAddSavedCard,
  }
}
