import { Dialog } from '@etta/ui/dialog'
import { ConfirmationDialogLayout } from './layout'
import type { Props } from './types'

export function ConfirmationDialog({ isOpen, onDecline, ...props }: Props) {
  return (
    <Dialog isOpen={isOpen} onClose={onDecline}>
      <ConfirmationDialogLayout onDecline={onDecline} {...props} />
    </Dialog>
  )
}
