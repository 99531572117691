import { Store } from '@etta/di'
import {
  DEFAULT_SELECTED_SEGMENT_INDEX,
  DEFAULT_SELECTED_FLIGHT_LEG_INDEX,
} from '@etta/modules/seat-map/core/constants'

const DEFAULT_SIZE = 0

type SegmentSize = number

type FlightLeg = { segmentSize: SegmentSize }

type PaginationLength = FlightLeg[]

@Store()
export class SeatMapPaginationStore {
  private paginationLength: PaginationLength = []

  selectedLegIndex = DEFAULT_SELECTED_FLIGHT_LEG_INDEX

  selectedSegmentIndex = DEFAULT_SELECTED_SEGMENT_INDEX

  get hasNextFlightLeg() {
    const size = this.paginationLength.length - 1 || DEFAULT_SIZE

    return size > this.selectedLegIndex
  }

  get hasNextSegmentInLeg() {
    const flightLeg = this.paginationLength[this.selectedLegIndex]
    const size = flightLeg?.segmentSize - 1 || DEFAULT_SIZE

    return size > this.selectedSegmentIndex
  }

  get isLastSegmentInFlightLeg() {
    return !this.hasNextFlightLeg && !this.hasNextSegmentInLeg
  }

  moveToNextSeatMap() {
    if (this.hasNextSegmentInLeg) {
      this.setSelectedSegmentIndex((this.selectedSegmentIndex += 1))
      return
    }

    if (this.hasNextFlightLeg) {
      this.setSelectedLegIndex((this.selectedLegIndex += 1))
      this.setSelectedSegmentIndex(DEFAULT_SELECTED_SEGMENT_INDEX)
    }
  }

  setPaginationLength(paginationLength: FlightLeg[]) {
    this.paginationLength = paginationLength
  }

  setSelectedLegIndex(selectedLegIndex: number) {
    this.selectedLegIndex = selectedLegIndex
  }

  setSelectedSegmentIndex(selectedSegmentIndex: number) {
    this.selectedSegmentIndex = selectedSegmentIndex
  }

  dropIndexes() {
    this.selectedLegIndex = DEFAULT_SELECTED_FLIGHT_LEG_INDEX
    this.selectedSegmentIndex = DEFAULT_SELECTED_SEGMENT_INDEX
  }

  dropStore() {
    this.paginationLength = []
    this.selectedLegIndex = DEFAULT_SELECTED_FLIGHT_LEG_INDEX
    this.selectedSegmentIndex = DEFAULT_SELECTED_SEGMENT_INDEX
  }
}
