import { useState } from 'react'
import * as R from 'ramda'
import type { Notification, LoyaltyProgram, AirTravelPreference } from '@fiji/graphql/types'
import type { FlightPreferencesChanges } from '../types'
import { composeLoyaltyProgram } from '../../use-loyalty-program'
import { findPhoneNotification } from './find-phone-notification'

type Props = {
  mealInitial?: string | null
  loyaltyFlightValue?: LoyaltyProgram[] | null
  specialRequestsInitial?: string[] | null
  notificationInitial?: Notification[] | null
  preference?: AirTravelPreference
  onClose: () => void
  onSubmit: (flightPreferencesChanges: FlightPreferencesChanges) => void
  onChangeLoyaltyFlightToRender: (data: LoyaltyProgram[]) => void
}

export function useFlightPreferences({
  mealInitial,
  specialRequestsInitial,
  loyaltyFlightValue,
  notificationInitial,
  preference,
  onClose,
  onSubmit,
  onChangeLoyaltyFlightToRender,
}: Props) {
  const NO_MEAL_OPTION = 'NONE'

  const { country: notificationCountry, phone: notificationPhone } =
    findPhoneNotification(notificationInitial) || {}
  const [phoneNumberValue, setPhoneNumberValue] = useState(notificationPhone || '')

  const [specialRequestsValues, setSpecialRequestsValues] = useState(specialRequestsInitial || [])
  function onUpdateSpecialRequests(data?: string[] | null) {
    setSpecialRequestsValues(data || [])
  }

  const [mealValue, setMealValue] = useState(mealInitial)
  function onUpdateMeal(data?: string | null) {
    setMealValue(data)
  }

  const [country, setCountry] = useState(notificationCountry || undefined)

  const onSave = () => {
    if (!preference) {
      return
    }

    if (
      preference.meal?.value === mealValue &&
      R.equals(
        preference.specialRequest.map((el) => el.value),
        specialRequestsValues,
      ) &&
      !loyaltyFlightValue?.find((el) => el.number)
    ) {
      loyaltyFlightValue && onChangeLoyaltyFlightToRender(loyaltyFlightValue)
      onClose()
      return
    }
    const airMembership = composeLoyaltyProgram({
      loyaltyFromProfile: preference.loyaltyProgram,
      loyaltyFromApp: loyaltyFlightValue,
    })
    onSubmit({
      specialRequestsValues,
      mealValue: mealValue && mealValue !== NO_MEAL_OPTION ? mealValue : undefined,
      airMembership,
      loyaltyFlightValue: loyaltyFlightValue || undefined,
    })
  }

  return {
    phoneNumberValue,
    setPhoneNumberValue,
    specialRequestsValues,
    onUpdateSpecialRequests,
    mealValue,
    onUpdateMeal,
    country,
    setCountry,
    onSave,
  }
}
