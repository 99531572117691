import styled from 'styled-components'

export const Container = styled.div<{ isGrouped?: boolean | null }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0;
  cursor: pointer;
  ${(p) => p.isGrouped && 'padding-left:30px;'}
`

export const IconWrapper = styled.div`
  margin: 0 4px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-left: 5px;
`

export const Title = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`
