import { AirSeatMapModal } from '@etta/modules/seat-map'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'
import type { FlightLegSubSegment } from '@fiji/graphql/types'
import { useFareSeatMaps } from './use-fare-seat-maps'

type Props = {
  itineraryId: string
  segments?: FlightLegSubSegment[]
  adaptiveFlight: AdaptiveFlight
  isOpen: boolean
  onClose: () => void
}

export function FareSeatMaps({ itineraryId, isOpen, onClose, adaptiveFlight, segments }: Props) {
  const {
    displayServiceClass,
    isLoading,
    fareSegmentSeatRows,
    selectedSegmentId,
    onSelectedSegmentIdChange,
    onSeatsApprove,
    initialSegmentSeatNumberList,
    seatStateStatus,
    resetSeatStateStatus,
  } = useFareSeatMaps({ itineraryId, segments, adaptiveFlight, onClose })

  return (
    <AirSeatMapModal
      mode="edit"
      initialSegmentSeatNumberList={initialSegmentSeatNumberList}
      selectedSegmentId={selectedSegmentId}
      isVisible={isOpen}
      subTitle={displayServiceClass}
      onClose={onClose}
      segmentsSeatRows={fareSegmentSeatRows}
      isLoading={isLoading}
      onSelectedSegmentIdChange={onSelectedSegmentIdChange}
      onApprove={onSeatsApprove}
      seatStateStatus={seatStateStatus}
      onResetFlightStatus={resetSeatStateStatus}
    />
  )
}
