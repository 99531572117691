import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { PurchaseReservationErrorMobile } from './mobile'
import { PurchaseReservationErrorDesktop } from './desktop'

export function PurchaseReservationErrorLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<PurchaseReservationErrorMobile {...props} />}
      desktopSlot={<PurchaseReservationErrorDesktop {...props} />}
    />
  )
}
