import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 16px;
`

export const DetailRow = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  gap: 10px;
  margin: 10px 0;
`

export const DetailColumn = styled.div`
  display: flex;
  align-self: center;
`
