import styled from 'styled-components'
import { subHeadMedium, withStyledText } from '@fiji/style'

export const ErrorBlock = styled.div`
  padding: 0 23px 10px;
  display: flex;
  gap: 4px;
  align-items: center;
`

export const ErrorText = styled.span`
  color: ${(p) => p.theme.colors.error};
  ${withStyledText};
`

export const AirSearchListItem = styled.div``

export const WarningMsgContainer = styled.div`
  display: flex;
  margin-top: 12px;
  padding: 16px 40px 16px 16px;
  background: ${(p) => p.theme.colors.error1};
  color: ${(p) => p.theme.colors.mainText};
  border-radius: 14px;
  ${subHeadMedium};
`

export const WarningMsgText = styled.div`
  margin-left: 7px;
`
