import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { AppliedFilters, IconWrapper } from './search-result-filters-applied-styled'

type Props = {
  numberOfAppliedFilters?: number
  onAppliedFiltersClick?: () => void
  isVisible: boolean
}

const i18nBase = 'QuickFilters.'

export function SearchResultFiltersApplied({
  numberOfAppliedFilters,
  onAppliedFiltersClick,
  isVisible,
}: Props) {
  const { t } = useTranslation()
  const { handleKeyDown } = useA11yKeydown(onAppliedFiltersClick)

  if (!isVisible) {
    return null
  }

  return (
    <AppliedFilters
      aria-label={t(`${i18nBase}ClearAppliedFilters`, {
        label: t(i18nBase + 'NumberOfFiltersApplied', {
          count: numberOfAppliedFilters,
        }),
      })}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={onAppliedFiltersClick}>
      <Text variant="linkSmall" color="mainText">
        {t(i18nBase + 'NumberOfFiltersApplied', {
          count: numberOfAppliedFilters,
        })}
      </Text>
      <IconWrapper>
        <Icon name="closePWA" />
      </IconWrapper>
    </AppliedFilters>
  )
}
