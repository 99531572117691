import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { TextColor, TextVariant } from '@etta/ui/text/types'
import type { IconNames, IconSizes } from '@etta/ui/icon/types'
import type { BillingError } from '@fiji/types'
import { PurchaseErrorList } from '@fiji/enums'
import { Container } from './purchase-card-error-styled'

type Props = {
  err?: BillingError
  iconType?: IconNames
  iconSize?: IconSizes
  errorMessageVariant?: TextVariant
  errorMessageColor?: TextColor
  withMargin?: boolean
  buttonSlot?: ReactNode
}

export function PurchaseCardError({
  err,
  iconType,
  iconSize: errorIconSize,
  errorMessageVariant,
  errorMessageColor,
  withMargin,
  buttonSlot,
}: Props) {
  const { t } = useTranslation()
  const translateMap = {
    [PurchaseErrorList.IncompleteAddress]: 'PurchaseCardError.IncompleteAddress',
    [PurchaseErrorList.NoCVV]: 'PurchaseCardError.NoCVV',
    [PurchaseErrorList.OopCard]: 'PurchaseCardError.OopCard',
    [PurchaseErrorList.BrandType]: 'PurchaseCardError.BrandType',
    [PurchaseErrorList.BookingComBrandType]: 'PurchaseCardError.BookingComBrandType',
  }

  if (!err) {
    return <></>
  }
  const isAnyErrorPresent = Object.entries(err).find(([_, v]) => v)

  if (!isAnyErrorPresent) {
    return <></>
  }

  const icon = iconType ?? 'errorFilledPWA'
  const iconSize = errorIconSize ?? 'small'
  const messageVariant = errorMessageVariant ?? 'footnoteMedium'
  const messageColor = errorMessageColor ?? 'error'

  return (
    <>
      {Object.entries(err).map(([key, value]) => {
        if (value) {
          return (
            <Container key={value + key} withMargin={withMargin}>
              <Icon name={icon} size={iconSize} color="error" />
              <Block marginLeft={5}>
                <Text variant={messageVariant} color={messageColor}>
                  {t(translateMap[value])}
                  {buttonSlot}
                </Text>
              </Block>
            </Container>
          )
        }
      })}
    </>
  )
}
