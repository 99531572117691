import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { FeatureFlagsErrorsInstances } from '@etta/modules/feature-flags/core/errors/feature-flags.errors'
import { FeatureFlagsErrors } from '@etta/modules/feature-flags/core/errors/feature-flags.errors'
import { FeatureFlagsMapper } from '@etta/modules/feature-flags/infra/mappers/feature-flags.mapper'
import type { FeatureFlagsValueObject } from '@etta/modules/feature-flags/core/value-object/feature-flags.value-object'
import type { PromisedResult } from '@etta/core/type-utils'
import { FeatureFlagsDataProvider } from './feature-flags.data-provider'

@Adapter()
export class FeatureFlagsAdapter {
  constructor(
    @Inject()
    private featureFlagsDataProvider: FeatureFlagsDataProvider,
  ) {}
  async getFlags(
    companyId?: number,
  ): PromisedResult<FeatureFlagsValueObject, FeatureFlagsErrorsInstances> {
    try {
      const { data, error } = await this.featureFlagsDataProvider.getFlags({
        companyId,
      })

      if (error) {
        return Result.Err(new FeatureFlagsErrors.FlagsResponse(error))
      }

      const flags = FeatureFlagsMapper.toFeatureFlagsValueObject(data.featureFlags)
      return Result.Ok(flags)
    } catch (e) {
      return Result.Err(new FeatureFlagsErrors.UnexpectedErrorFlags(e))
    }
  }
}
