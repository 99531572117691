export enum ScreenType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export enum DesktopScreenType {
  TabletNew = 'tabletNew',
  DesktopSM = 'desktopSM',
  DesktopMD = 'desktopMD',
  DesktopLG = 'desktopLG',
}
