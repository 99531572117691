import { useMemo } from 'react'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { useComputedCustomFields } from '@fiji/hooks/use-custom-fields/use-computed-custom-fields'
import type { PurchaseInfoTravelerValueObject } from '@etta/modules/booking/core/value-objects'
import type { UserSuffix, UserTitle } from '@fiji/graphql/types'
import { Gender } from '@fiji/graphql/types'
import { usePreSearchContext } from '@etta/modules/pre-search/interface/use-pre-search.context'
import { useCheckoutCards } from './use-checkout-cards'

export function useLoadPurchaseInfo() {
  const { checkoutInfoStore } = useCheckoutInfoContext()
  const { preSearchStore } = usePreSearchContext()

  const traveler = useMemo<PurchaseInfoTravelerValueObject | undefined>(() => {
    return {
      ...checkoutInfoStore.primaryTraveler.travelerInfo,
      primary: true,
      middleInitial: checkoutInfoStore.primaryTraveler.travelerInfo.middleName,
      dateOfBirth: checkoutInfoStore.primaryTraveler.travelerData?.dateOfBirth,
      email: checkoutInfoStore.primaryTraveler.travelerInfo.email ?? '',
      gender: checkoutInfoStore.primaryTraveler.travelerInfo.gender ?? Gender.Unspecified,
      phone: checkoutInfoStore.primaryTraveler.travelerInfo.phone
        ? [checkoutInfoStore.primaryTraveler.travelerInfo.phone]
        : [],
      suffix: checkoutInfoStore.primaryTraveler.travelerInfo.suffix as UserSuffix,
      title: checkoutInfoStore.primaryTraveler.travelerInfo.title as UserTitle,
      knownTravelerNumbers: checkoutInfoStore.primaryTraveler.travelerInfo.knownTravelerNumbers,
      redressNumbers: checkoutInfoStore.primaryTraveler.travelerInfo.redressNumbers,
    }
  }, [
    checkoutInfoStore.primaryTraveler.travelerData,
    checkoutInfoStore.primaryTraveler.travelerInfo,
  ])

  const {
    checkoutCarRentalBilling,
    checkoutFlightBilling,
    checkoutHotelBilling,
    checkoutRailBilling,
  } = useCheckoutCards()

  const fields = checkoutInfoStore.customFieldsConfiguration?.customFieldCollection
  const customFields = useComputedCustomFields(fields)

  // add pre search cfs value to same cfs in checkout page
  preSearchStore.customFields.forEach((preSearchCustomField) => {
    const customField = customFields
      .find((customField) => customField.collectionId === preSearchCustomField.collectionId)
      ?.customFields.find((customField) => customField.name === preSearchCustomField.name)
    if (customField) {
      customField.defaultValue = preSearchCustomField.value
    }
  })

  const areCustomFieldsExist = !!customFields.length

  return {
    traveler,
    customFields,
    travelerOptions: checkoutInfoStore.primaryTraveler.travelerFields,
    dynamicSiteMessages: checkoutInfoStore.dynamicSiteMessages,
    carRentalBilling: checkoutCarRentalBilling,
    flightBilling: checkoutFlightBilling,
    hotelBilling: checkoutHotelBilling,
    preBookRequestRequirement: checkoutInfoStore.preBookRequestRequirement,
    railBilling: checkoutRailBilling,
    areCustomFieldsExist,
    isCostAllocationExist: Boolean(checkoutInfoStore.costAllocations.length),
    isPassportRequired: checkoutInfoStore.isPassportRequired,
  }
}
