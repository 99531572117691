import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { useSegmentDetailsEcoCheckModal } from '@fiji/hooks/sustainability/use-segment-details-eco-check-modal'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import type { LayoutProps } from '../../types'
import {
  CarRentalDetailsEcoCheckModalDesktopUpperBody,
  CarRentalDetailsEcoCheckHeader,
  CarRentalDetailsEcoCheckModalImage,
  CarRentalDetailsEcoCheckModalDesktopLowerBody,
} from './components'
import {
  EquivalencesContainer,
  BodyWrapper,
} from './car-rental-details-eco-check-modal-desktop-styled'

export function CarRentalDetailsEcoCheckModalDesktop({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: LayoutProps) {
  const {
    kgOfEmissions,
    averageEmissionsKg,
    deviationLevel,
    percentDeviation,
  } = useSegmentDetailsEcoCheckModal({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  return (
    <Modal
      position="right"
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<CarRentalDetailsEcoCheckHeader onClose={onClose} />}></Header>
      </Modal.Header>
      <BodyWrapper>
        <Modal.Body>
          <CarRentalDetailsEcoCheckModalDesktopUpperBody
            averageEmissionsKg={averageEmissionsKg}
            percentDeviation={percentDeviation}
            kgOfEmissions={kgOfEmissions}
            deviationLevel={deviationLevel}
          />
          <CarRentalDetailsEcoCheckModalImage deviationLevel={deviationLevel} />
          <EquivalencesContainer>
            <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={kgOfEmissions} />
          </EquivalencesContainer>
          <CarRentalDetailsEcoCheckModalDesktopLowerBody deviationLevel={deviationLevel} />
        </Modal.Body>
      </BodyWrapper>
    </Modal>
  )
}
