import { Action, Inject } from '@etta/di'
import type { Place, Dates } from '@fiji/types'
import { HotelSearchFormService } from '../services/hotel-search-form.service'
import { HotelSearchFormStore } from '../stores'
import { HotelFiltersAction } from './hotel-filters.action'

@Action()
export class HotelSearchFormActions {
  constructor(
    @Inject()
    private readonly hotelSearchFormService: HotelSearchFormService,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly hotelFiltersAction: HotelFiltersAction,
  ) {}

  onDistanceChange(distance: number) {
    this.hotelSearchFormService.onDistanceChange(distance)
  }

  async handleChangeLocation(place: Place) {
    if (this.hotelSearchFormStore.isEditMode) {
      await this.hotelFiltersAction.handleDropFiltersWithQueryUpdate()
    }
    this.hotelSearchFormService.changeLocation(place)
  }

  handleDateChange({ date, dateEnd }: Dates) {
    this.hotelSearchFormService.changeDate({ date, dateEnd })
  }

  handleCloseEditModal() {
    this.hotelSearchFormStore.editFormModalToggle.handleClose()
  }

  handleOpenEditModal() {
    this.hotelSearchFormService.updateSearchHotelsEditCache()
    this.hotelSearchFormStore.editFormModalToggle.handleOpen()
  }
}
