import { useTranslation } from 'react-i18next'
import { Switch } from '@etta/ui/switch'
import { Block } from '@etta/ui/block'
import { Radio } from '@etta/ui/radio'
import { Checkbox } from '@etta/ui/checkbox'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { SeatReservationType } from '@fiji/graphql/types'
import type { SeatPreferences } from '@fiji/graphql/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { PreferencesUK } from '../../types'
import {
  Content,
  Section,
  SwitchSection,
  SectionTitle,
} from './trip-review-seat-preferences-uk-styled'
import { useTripReviewSeatPreferencesUK } from './use-trip-review-seat-preferences-uk'

type Props = {
  preferenceOptions: SeatPreferences
  preferences: PreferencesUK
  seatReservationTypesArray?: SeatReservationType[]
  onPreferencesChange: (newPreferences: Partial<PreferencesUK>) => void
  isRoundTrip: boolean
  isFirstClass: boolean
}

const i18Base = 'TripReview.SeatPreferencesModal.Labels'

// FIXME: Needs refactoring here. Will be done in next ticket.
export function TripReviewSeatPreferencesUK({
  preferenceOptions,
  preferences,
  seatReservationTypesArray,
  onPreferencesChange,
  isRoundTrip,
  isFirstClass,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { getReservationLabel, onExtrasChange } = useTripReviewSeatPreferencesUK({
    preferences,
    onPreferencesChange,
  })

  const isBothSegmentsMandatory =
    seatReservationTypesArray &&
    seatReservationTypesArray?.length > 1 &&
    seatReservationTypesArray?.every((item) => item === SeatReservationType.Mandatory)

  return (
    <>
      <Section withBorder isMobile={isMobile}>
        <Text variant="subHeadMedium" color="mainText">
          {t('TripReview.SeatPreferencesModal.StaticText')}
        </Text>
      </Section>

      {isRoundTrip && isBothSegmentsMandatory && (
        <Section withBorder isMobile={isMobile}>
          <SectionTitle variant="subHeadStrong" color="mainText">
            {t(i18Base + '.DepartingReturnTrains')}
          </SectionTitle>
          <Block paddingVertical={6}>
            <Text variant="subHeadMedium" color="mainText">
              {t(i18Base + getReservationLabel(seatReservationTypesArray[0]))}
            </Text>
          </Block>
          <Content>
            <Block marginRight={5}>
              <Icon name="infoPWA" />
            </Block>
            {t(`${i18Base}${'.SeatReservationMandatoryRound'}`)}
          </Content>
        </Section>
      )}

      {!isBothSegmentsMandatory &&
        !!seatReservationTypesArray &&
        seatReservationTypesArray.map((segmentReservationType, index) => (
          <Section withBorder key={index} isMobile={isMobile}>
            {isRoundTrip && (
              <SectionTitle variant="subHeadStrong" color="mainText">
                {index > 0 ? t(i18Base + '.ReturnTrain') : t(i18Base + '.DepartingTrain')}
              </SectionTitle>
            )}
            <Block paddingVertical={6} paddingHorizontal={isMobile ? 0 : 16}>
              <Text variant="subHeadMedium" color="mainText">
                {t(i18Base + getReservationLabel(seatReservationTypesArray[index]))}
              </Text>
            </Block>
            {segmentReservationType === SeatReservationType.Optional && (
              <Block paddingHorizontal={isMobile ? 0 : 16}>
                <Checkbox
                  value={index === 1 ? 'isSeatRequestedOnReturn' : 'isSeatRequestedOnDepart'}
                  label={t(i18Base + '.RequestASeat')}
                  checked={
                    index === 1
                      ? !!preferences.isSeatRequestedOnReturn
                      : !!preferences.isSeatRequestedOnDepart
                  }
                  onChange={(val) =>
                    onPreferencesChange(
                      index === 1
                        ? { isSeatRequestedOnReturn: val }
                        : { isSeatRequestedOnDepart: val },
                    )
                  }
                />
              </Block>
            )}
            {segmentReservationType === SeatReservationType.Mandatory && (
              <Block paddingHorizontal={isMobile ? 0 : 16}>
                <Content>
                  <Block marginRight={5}>
                    <Icon name="infoPWA" />
                  </Block>
                  {t(
                    `${i18Base}${
                      index < 1
                        ? '.SeatReservationMandatoryDepart'
                        : '.SeatReservationMandatoryReturn'
                    }`,
                  )}
                </Content>
              </Block>
            )}
            {segmentReservationType === SeatReservationType.None && (
              <Block paddingHorizontal={isMobile ? 0 : 16}>
                <Content>
                  <Block marginRight={5}>
                    <Icon name="infoPWA" />
                  </Block>
                  {t(
                    `${i18Base}${
                      isFirstClass ? '.SeatReservationNoneFirstClass' : '.SeatReservationNone'
                    }`,
                  )}
                </Content>
              </Block>
            )}
          </Section>
        ))}
      {!!preferenceOptions.seatPositions.length && (
        <Section withBorder isMobile={isMobile}>
          <SectionTitle variant="subHeadStrong" color="mainText">
            {t(i18Base + '.SeatPosition')}
          </SectionTitle>
          <Block paddingHorizontal={isMobile ? 0 : 16}>
            {preferenceOptions.seatPositions.map(({ code, name }, index) => (
              <Block key={index} paddingVertical={18}>
                <Radio
                  onChange={() => onPreferencesChange({ seatPositions: code })}
                  isChecked={preferences.seatPositions === code}>
                  {name}
                </Radio>
              </Block>
            ))}
          </Block>
        </Section>
      )}
      {!!preferenceOptions.seatDirections.length && (
        <Section withBorder isMobile={isMobile}>
          <SectionTitle variant="subHeadStrong" color="mainText">
            {t(i18Base + '.SeatFacing')}
          </SectionTitle>
          <Block paddingHorizontal={isMobile ? 0 : 16}>
            {preferenceOptions.seatDirections.map(({ code, name }, index) => (
              <Block key={index} paddingVertical={18}>
                <Radio
                  onChange={() => onPreferencesChange({ seatDirections: code })}
                  isChecked={preferences.seatDirections === code}>
                  {name}
                </Radio>
              </Block>
            ))}
          </Block>
        </Section>
      )}
      {!!preferenceOptions.carriageTypes.length && (
        <Section withBorder isMobile={isMobile}>
          <SectionTitle variant="subHeadStrong" color="mainText">
            {t(i18Base + '.CoachType')}
          </SectionTitle>
          <Block paddingHorizontal={isMobile ? 0 : 16}>
            {preferenceOptions.carriageTypes.map(({ code, name }, index) => (
              <Block key={index} paddingVertical={18}>
                <Radio
                  onChange={() => onPreferencesChange({ carriageTypes: code })}
                  isChecked={preferences.carriageTypes === code}>
                  {name}
                </Radio>
              </Block>
            ))}
          </Block>
        </Section>
      )}
      {!!preferenceOptions.seatFacilities.length && (
        <SwitchSection withBorder>
          <SectionTitle variant="subHeadStrong" color="mainText">
            {t(i18Base + '.Amenities')}
          </SectionTitle>
          <Block paddingHorizontal={isMobile ? 0 : 16}>
            {preferenceOptions.seatFacilities.map(({ code, name }, index) => (
              <Block key={index} paddingHorizontal={4} paddingVertical={18}>
                <Switch
                  isPWA
                  labelPlacement="left"
                  checked={preferences.seatFacilities.includes(code)}
                  onChange={() => onExtrasChange(code)}
                  label={name}
                />
              </Block>
            ))}
          </Block>
        </SwitchSection>
      )}
    </>
  )
}
