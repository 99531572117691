import { useCallback } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { useUberUniversalLink } from '@fiji/hooks/mobility/use-uber-universal-link'

export function useMobilityBookingError() {
  const { searchRideHailStore, searchRideHailActions } = useRideHailSearchContext()
  const {
    pickUpPlace,
    pickUpDate,
    pickUpTime,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm
  const { openUberApp } = useUberUniversalLink({
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  })

  const handleRedirectToSearch = useCallback(() => {
    searchRideHailActions.dropSearchRideHailForm()
    searchRideHailActions.setRenderState(RenderState.whereTo)
  }, [searchRideHailActions])

  return {
    handleRedirectToSearch,
    handleOpenUber: openUberApp,
  }
}
