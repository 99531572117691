import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'

const i18nBase = 'CarRentalFilters.DefaultValues.'

export function useTranmissionOptions() {
  const { t } = useTranslation()
  const { carRentalSearchStore, carFiltersStore } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const options = filterOptions.transmission

  const isChecked = carFiltersStore.selectionHandlers.isTransmissionSelected

  const handleSelectTransmission = useCallback(
    (id?: string) => () => {
      if (!id) {
        return carFiltersStore.clearHandlers.clearTransmission()
      }
      return carFiltersStore.setTransmission(id)
    },
    [carFiltersStore],
  )

  const anyOptionLabel = t(`${i18nBase}Any`)

  return {
    options,
    isChecked,
    handleSelectTransmission,
    anyOptionLabel,
  }
}
