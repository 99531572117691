import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Link } from '@etta/ui/link'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { AppState } from '@fiji/store'
import type { HotelDescription } from '@fiji/graphql/types'
import {
  AboutHotelText,
  DescriptionBody,
  DescriptionTitle,
  HotelAboutContainer,
  LinkWrapper,
  ShortDetailsText,
} from './hotel-details-description-styled'

const SHORT_ABOUT_TEXT_LENGTH = 350

type Props = {
  hotelDescriptions: HotelDescription[]
}

type ModifiedDescriptions = {
  fullLegnth: number
  fullText: string
  descriptions: HotelDescription[]
}

export function HotelDetailsDescription({ hotelDescriptions }: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  const toggleShowMore = useTogglePopup()

  const { isPhoenixHotelSearchEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const currentYear = new Date().getFullYear()
  const copyRight = `©GIATA 2004-${currentYear}, client ref. 127095`

  const descriptions = useMemo(() => {
    return hotelDescriptions.reduce(
      (acc, currDescription) => {
        if (!currDescription || !currDescription.body) {
          return acc
        }

        const description = { ...currDescription }

        if (description.title?.toLowerCase() === 'location') {
          description.title = ''
        }

        return {
          fullLegnth: acc.fullLegnth + currDescription.body.length,
          fullText: `${acc.fullText} ${currDescription.body}`,
          descriptions: [...acc.descriptions, description],
        }
      },
      {
        fullLegnth: 0,
        fullText: '',
        descriptions: [],
      } as ModifiedDescriptions,
    )
  }, [hotelDescriptions])

  const shortDetails = (descriptions.fullText || '').slice(0, SHORT_ABOUT_TEXT_LENGTH)

  const hasShowMore = !toggleShowMore.isOpen && descriptions.fullLegnth > SHORT_ABOUT_TEXT_LENGTH

  return (
    <>
      <HotelAboutContainer hasEllipsisOverflow={hasShowMore}>
        <Swap
          is={hasShowMore}
          isSlot={
            <ShortDetailsText isBlock variant="subHeadMedium" color="bodyText">
              {shortDetails}
            </ShortDetailsText>
          }>
          {descriptions.descriptions.map((description) => (
            <AboutHotelText
              aria-label={description.body ?? ''}
              variant="footnoteMedium"
              color="bodyText"
              key={description.body}>
              <DescriptionTitle
                variant="subHeadStrong"
                color="mainText"
                isEmptyTitle={description?.title === ''}>
                {description?.title}
              </DescriptionTitle>
              <DescriptionBody variant="subHeadMedium" color="bodyText">
                {description?.body}
              </DescriptionBody>
            </AboutHotelText>
          ))}
          {isPhoenixHotelSearchEnabled && (
            <Block marginVertical={16}>
              <Text aria-label={copyRight} variant="bodyValue" color="bodyText">
                {copyRight}
              </Text>
            </Block>
          )}
        </Swap>
      </HotelAboutContainer>

      <LinkWrapper>
        {hasShowMore && (
          <Link
            aria-label={t(`${i18Base}.ButtonAriaLabel`, { label: t(i18Base + '.ViewMore') })}
            size="small"
            onClick={toggleShowMore.handleOpen}>
            {t(i18Base + '.ViewMore')}
          </Link>
        )}
        {toggleShowMore.isOpen && (
          <Link
            aria-label={t(`${i18Base}.ButtonAriaLabel`, { label: t(i18Base + '.ViewLess') })}
            size="small"
            onClick={toggleShowMore.handleClose}>
            {t(i18Base + '.ViewLess')}
          </Link>
        )}
      </LinkWrapper>
    </>
  )
}
