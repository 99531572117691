import type { CheckoutItineraryInputValueObject } from '@etta/modules/booking/core/value-objects/checkout-itinerary.input.value-object'
import { ShareTravelerContact } from '@etta/modules/travel-preferences/core/enums/share-traveler-contact.enum'
// eslint-disable-next-line import/no-restricted-paths
import { ShareTravelerContactType } from '@fiji/graphql/types'

const shareTravelerContactMap = {
  [ShareTravelerContact.PROVIDE_ALL]: ShareTravelerContactType.ProvideAll,
  [ShareTravelerContact.PROVIDE_EMAIL]: ShareTravelerContactType.ProvideEmail,
  [ShareTravelerContact.PROVIDE_PHONE]: ShareTravelerContactType.ProvidePhone,
  [ShareTravelerContact.PROVIDE_NONE]: ShareTravelerContactType.ProvideNone,
}

export class ShareTravelerContactMapper {
  static toShareTravelerContactInput(
    shareTravelerContact: ShareTravelerContact | null | undefined,
  ): CheckoutItineraryInputValueObject['shareTravelerContact'] {
    if (!shareTravelerContact || shareTravelerContact === null) {
      return null
    }
    return shareTravelerContactMap[shareTravelerContact]
  }
}
