import styled from 'styled-components'

export const Container = styled.div<{ isClosable?: boolean }>`
  display: flex;
  padding: 16px 11px 16px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  }
  overflow: hidden;
  ${(p) => !p.isClosable && 'padding-right: 44px;'}
`
