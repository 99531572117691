import { useTranslation } from 'react-i18next'
import { SafetyCheckHeaderLayout } from './layout'

export function SafetyCheckHeader() {
  const { t } = useTranslation()
  const description = t('HotelSafetyCheck.SafetyCheckHeader.Description')
  const poweredByLabel = t('HotelSafetyCheck.SafetyCheckHeader.PoweredBy')

  return <SafetyCheckHeaderLayout description={description} poweredByLabel={poweredByLabel} />
}
