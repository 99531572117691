import type { Coords } from '../../core/coords'
import type { PolygonOptions } from '../../core/polygon'
import type { TravelMode } from '../../core/travel-mode'
import type { RoutePlace, RouteResult } from '../../core/route'
import type { MarkerOptions } from '../../core/marker.options'

const MAX_DISTANCE_IN_METERS = 1000

export class AppleMapHelpers {
  static checkIsCoordinatesEqual(a: Coords, b: Coords): boolean {
    return this.createCoordinate(a).equals(this.createCoordinate(b))
  }

  static checkOutOfRange({
    boundsCenter,
    mapCenter,
    boundsDistanceMeters = MAX_DISTANCE_IN_METERS,
  }: {
    boundsCenter: Coords
    mapCenter: Coords
    boundsDistanceMeters?: number
  }) {
    const distanceInKm = AppleMapHelpers.calculcateDistanceBetweenPoints(
      boundsCenter,
      mapCenter,
      'km',
    )
    const distanceInMeters = AppleMapHelpers.convertKmToMeters(distanceInKm)

    return distanceInMeters > boundsDistanceMeters
  }

  static createBoundsByCoordinates(coords: Coords[]): mapkit.BoundingRegion {
    const northLat = Math.max(...coords.map((coord) => coord.lat))
    const southLat = Math.min(...coords.map((coord) => coord.lat))
    const eastLng = Math.max(...coords.map((coord) => coord.lng))
    const westLng = Math.min(...coords.map((coord) => coord.lng))

    return new mapkit.BoundingRegion(northLat, eastLng, southLat, westLng)
  }

  static getPlaceFromRoute(place: RoutePlace): string | mapkit.Coordinate {
    if (AppleMapHelpers.isPlaceCoordinate(place)) {
      return new mapkit.Coordinate(place.lat, place.lng)
    }
    if (typeof place === 'string') {
      return place
    }

    return place.name
  }

  static getTravelMode(travelMode?: TravelMode): mapkit.Directions.Transport {
    switch (travelMode) {
      case 'BICYCLING':
        return mapkit.Directions.Transport.Automobile
      case 'DRIVING':
        return mapkit.Directions.Transport.Automobile
      case 'TRANSIT':
        return mapkit.Directions.Transport.Automobile
      case 'WALKING':
        return mapkit.Directions.Transport.Walking
      case 'TWO_WHEELER':
        return mapkit.Directions.Transport.Automobile
      default:
        return mapkit.Directions.Transport.Automobile
    }
  }

  static getRouteResultFromAppleDirection(direction: mapkit.DirectionsResponse): RouteResult[] {
    return direction.routes.map((route) => {
      const start_location: Coords = {
        lat: route.polyline.points.at(0)?.latitude ?? 0,
        lng: route.polyline.points.at(0)?.longitude ?? 0,
      }
      const end_location: Coords = {
        lat: route.polyline.points.at(-1)?.latitude ?? 0,
        lng: route.polyline.points.at(-1)?.longitude ?? 0,
      }

      return { legs: [{ start_location, end_location }] }
    })
  }

  static getMapkitStyle(style?: PolygonOptions): mapkit.Style {
    return new mapkit.Style({ ...style })
  }

  static createCoordinate(coords: Coords): mapkit.Coordinate {
    return new mapkit.Coordinate(coords.lat, coords.lng)
  }

  static convertZoomLevelToMeters(zoom: number, centerLat: number): number {
    const KM = (15000 / Math.pow(2, zoom - 3)) * Math.cos((centerLat * Math.PI) / 180)
    return AppleMapHelpers.convertKmToMeters(KM)
  }

  static convertKmToMeters(km: number): number {
    return parseFloat((km * 1000).toFixed(1))
  }

  private static isPlaceCoordinate(place: RoutePlace): place is Coords {
    return typeof place !== 'string' && 'lat' in place
  }

  private static roundNum(code: any) {
    return Number(code.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0])
  }

  static calculcateDistanceBetweenPoints(point1: Coords, point2: Coords, unit: 'mi' | 'km') {
    if (point1.lat === point2.lat && point1.lng === point2.lng) {
      return 0
    }
    const radlat1 = (Math.PI * point1.lat) / 180
    const radlat2 = (Math.PI * point2.lat) / 180
    const theta = point1.lng - point2.lng
    const radtheta = (Math.PI * theta) / 180
    let distance =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (distance > 1) {
      distance = 1
    }
    distance = ((Math.acos(distance) * 180) / Math.PI) * 60 * 1.1515
    if (unit === 'km') {
      distance = distance * 1.609344
    }

    return AppleMapHelpers.roundNum(distance)
  }

  static createAnnotationByCoordinate(coord: Coords, options?: MarkerOptions): mapkit.Annotation {
    const appcoord = AppleMapHelpers.createCoordinate(coord)
    return new mapkit.Annotation(
      appcoord,
      (): Element => {
        const dom = document.createElement('div')
        dom.className = options?.className || ''
        return dom
      },
    )
  }
}
