import { forwardRef } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Text } from '@etta/ui/text'
import type { TextProps } from '@etta/ui/text'
import type { LottieAnimationProps, AnimationItem } from '@etta/ui/lottie-animation'
import { LottieAnimation } from '@etta/ui/lottie-animation'
import { calloutStrong, footnoteMedium } from '@fiji/style'

export const TRANSITION_NAME = 'typing-animation'
export const ENTER_TRANSITION_DURATION = 700
export const EXIT_TRANSITION_DURATION = 500

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: ${(p) => p.theme.colors.background};
`

export const SpinnerWrapper = styled.div``

export const SpinnerLabel = styled.div`
  max-width: 270px;
  margin-top: 18px;
  text-align: center;
  ${calloutStrong};
  font-size: 15px;
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const AnimationContainer = styled.div`
  position: relative;
  width: 800px;
  height: 430px;
`

export const StyledLottieAnimation = styled(
  forwardRef<AnimationItem, LottieAnimationProps>((props, ref) => (
    <LottieAnimation {...props} ref={ref} />
  )),
)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: 430px;
  z-index: 1;
`

const expandAnimation = keyframes`
  from {
    width: 0;
  };
  to {
    width: 100%;
  };
`

const collapseAnimation = keyframes`
  from {
    width: 100%;
  };
  to {
    width: 0;
  };
`

const typingAnimationStyles = css`
  &,
  &.${TRANSITION_NAME}-enter, &.${TRANSITION_NAME}-enter-active {
    animation: ${expandAnimation} ${ENTER_TRANSITION_DURATION}ms linear forwards;
  }

  &.${TRANSITION_NAME}-exit, &.${TRANSITION_NAME}-exit-active {
    animation: ${collapseAnimation} ${EXIT_TRANSITION_DURATION}ms linear forwards;
  }
`

export const AnimationLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  inset: auto 0 30px;
`

export const AnimationLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const AnimationLabel = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'captionMedium',
  color: 'mainText',
  textOverflow: 'ellipsis',
}))`
  ${typingAnimationStyles};
`

export const TripOverviewLabel = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'body',
  color: 'bodyText',
}))`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Separator = styled.div`
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.bodyText};

  ${footnoteMedium};
`
