import { Service, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import { HotelSearchFormQueryService } from '@etta/modules/hotel-search/interface/services/hotel-search-form-query.service'
import { RailSearchFormQueryService } from '@etta/modules/rail-search-form/interface/services/rail-search-form-query.service'
import { AirSearchActions, AirSearchFormInputsActions } from '@etta/modules/air-search'
import { CarRentalQueryService } from '@etta/modules/car-rental-search/interface/services/car-rental-query.service'
import { AirSearchQueryService } from '@etta/modules/air-search/interface/services/air-search-query/air-search-query.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { HotelSearchFormStore } from '@etta/modules/hotel-search'
import { RailLegType } from '@fiji/enums'
import { PreSearchStore, PreSearchActions } from '@etta/modules/pre-search'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { CostAllocationFormStore } from '@etta/modules/cost-allocation/interface/stores/cost-allocation-form.store'
import { CostAllocationStore } from '@etta/modules/cost-allocation'
import type { CarRentalRecentSearch } from '../../core/value-objects/get-car-rental-searches.value-object'
import type { FlightRecentSearch } from '../../core/value-objects/get-flight-searches.value-object'
import type { HotelRecentSearch } from '../../core/value-objects/get-hotel-searches.value-object'
import type { RailRecentSearch } from '../../core/value-objects/get-rail-searches.value-object'
import { CarRentalRecentSearchDataService } from '../services/recent-search-data/car-rental.service'
import { FlightRecentSearchDataService } from '../services/recent-search-data/flight.service'
import { HotelRecentSearchDataService } from '../services/recent-search-data/hotel.service'
import { RailRecentSearchDataService } from '../services/recent-search-data/rail.service'
import { SubmitSearchService } from '../services/submit-search.service'
import { RecentSearchesStore } from '../stores/recent-searches.store'
import type { FlightRecentSearchWithoutDates } from '../../core/value-objects/get-flight-searches-without-dates.value-object'
import { FlightRecentSearchWithoutDatesDataService } from '../services/recent-search-data/flight-without-dates.service'
import { HotelRecentSearchWithoutDatesDataService } from '../services/recent-search-data/hotel-without-dates.service'
import { CarRentalRecentSearchWithoutDatesDataService } from '../services/recent-search-data/car-rental-without-dates.service'
import type { HotelRecentSearchWithoutDates } from '../../core/value-objects/get-hotel-searches-without-dates.value-object'
import type { CarRentalRecentSearchWithoutDates } from '../../core/value-objects/get-car-rental-searches-without-dates.value-object'

@Service()
export class RecentSearchDataAction {
  constructor(
    @Inject() private carRentalRecentSearchDataService: CarRentalRecentSearchDataService,
    @Inject() private flightRecentSearchDataService: FlightRecentSearchDataService,
    @Inject() private hotelRecentSearchDataService: HotelRecentSearchDataService,
    @Inject() private railRecentSearchDataService: RailRecentSearchDataService,
    @Inject() private carRentalQueryService: CarRentalQueryService,
    @Inject() private airSearchFormQueryService: AirSearchQueryService,
    @Inject() private hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject() private railSearchFormQueryService: RailSearchFormQueryService,
    @Inject() private submitSearchService: SubmitSearchService,
    @Inject() private airSearchFormInputsActions: AirSearchFormInputsActions,
    @Inject() private airSearchActions: AirSearchActions,
    @Inject() private historyStore: HistoryStore,
    @Inject() private hotelSearchFormStore: HotelSearchFormStore,
    @Inject() private preSearchStore: PreSearchStore,
    @Inject() private preSearchActions: PreSearchActions,
    @Inject() private costAllocationStore: CostAllocationStore,
    @Inject() private costAllocationFormStore: CostAllocationFormStore,
    @Inject() private recentSearchesStore: RecentSearchesStore,
    @Inject()
    private flightRecentSearchWithoutDatesDataService: FlightRecentSearchWithoutDatesDataService,
    @Inject()
    private hotelRecentSearchWithoutDatesDataService: HotelRecentSearchWithoutDatesDataService,
    @Inject()
    private carRentalRecentSearchWithoutDatesDataService: CarRentalRecentSearchWithoutDatesDataService,
  ) {}

  isMobile: boolean = screenMatcher.getScreenType() === ScreenType.Mobile

  getRecentCarRentalSearches(input: CarRentalRecentSearch['carRentalSearchParams']) {
    const {
      bottomLine,
      locationName,
      route,
      queryParams,
    } = this.carRentalRecentSearchDataService.get(input)

    const recreateSearch = () => {
      if (this.isMobile && !this.costAllocationFormStore.checkCostAllocation()) {
        this.recentSearchesStore.setRecentSearchCallback(recreateSearch)
        this.costAllocationStore.costAllocationModal.handleOpen()

        return
      }

      if (this.preSearchStore.isShowingPreSearchModal) {
        fillSearchForm()

        this.openPreSearchCustomFieldsModal()
        return
      }

      this.submitSearchService.submitNewCarRentalSearch({
        pickUpDate: queryParams.pickUpDate,
        pickUpPlace: queryParams.pickUpPlace,
        pickUpTime: queryParams.pickUpTime,
        dropOffDate: queryParams.dropOffDate,
        dropOffPlace: queryParams.dropOffPlace,
        dropOffTime: queryParams.dropOffTime,
      })
      this.carRentalQueryService.navigateTo(route, queryParams)
    }

    const fillSearchForm = () => {
      this.carRentalQueryService.appendQueryParams({
        pickUpAddress: undefined,
        dropOffAddress: undefined,
        ...queryParams,
      })
    }

    return { bottomLine, locationName, recreateSearch, fillSearchForm }
  }

  getRecentFlightSearches(
    searchParams: FlightRecentSearch['flightSearchParams'],
    dateFormatString?: string,
  ) {
    const {
      bottomLine,
      queryParams,
      destinationLocationName,
      originLocationName,
      flightType,
    } = this.flightRecentSearchDataService.get(searchParams, dateFormatString)

    const searchQueryParams = {
      flights: queryParams,
      flightType,
      searchId: '',
    }

    const recreateSearch = () => {
      if (this.isMobile && !this.costAllocationFormStore.checkCostAllocation()) {
        this.recentSearchesStore.setRecentSearchCallback(recreateSearch)
        this.costAllocationStore.costAllocationModal.handleOpen()
        return
      }

      if (this.preSearchStore.isShowingPreSearchModal) {
        fillSearchForm()

        this.airSearchActions.searchInBackground(queryParams, flightType)
        this.openPreSearchCustomFieldsModal()
        return
      }

      this.submitSearchService.submitNewFlightSearch({
        type: flightType,
        flights: queryParams,
      })
      this.airSearchFormQueryService.navigateTo(
        ROUTES.air.results({ legNumber: 0 }),
        searchQueryParams,
      )
    }

    const fillSearchForm = () => {
      const { flightType, flights } = searchQueryParams
      this.airSearchFormInputsActions.handleSetFlights(flights, flightType)
    }

    return {
      bottomLine,
      destinationLocationName,
      originLocationName,
      recreateSearch,
      fillSearchForm,
    }
  }

  getRecentHotelSearches(searchParams: HotelRecentSearch['hotelSearchParams']) {
    const {
      bottomLine,
      checkInDate,
      checkOutDate,
      location,
    } = this.hotelRecentSearchDataService.get(searchParams)

    const queryParams = {
      checkInDate,
      checkOutDate,
      location,
      hotelFilters: {
        distance: searchParams.searchRadius,
        hotelName: '',
        starRatings: [],
        brandIds: [],
        amenityIds: [],
        groupId: '',
        initialDistance: searchParams.searchRadius,
      },
    }

    const recreateSearch = () => {
      if (this.isMobile && !this.costAllocationFormStore.checkCostAllocation()) {
        this.recentSearchesStore.setRecentSearchCallback(recreateSearch)
        this.costAllocationStore.costAllocationModal.handleOpen()
        return
      }

      if (this.preSearchStore.isShowingPreSearchModal) {
        fillSearchForm()

        this.openPreSearchCustomFieldsModal()
        return
      }

      this.submitSearchService.submitNewHotelSearch({
        checkInDate: queryParams.checkInDate,
        checkOutDate: queryParams.checkOutDate,
        location: queryParams.location,
        distance: queryParams.hotelFilters.distance,
      })
      this.hotelSearchFormQueryService.navigateTo(ROUTES.hotel.results, {
        ...queryParams,
        isSearchByNewArea: false,
      })
    }

    const fillSearchForm = () => {
      if (this.historyStore.matchPathname(ROUTES.hotel.results)) {
        this.hotelSearchFormStore.updateEditCache({
          ...queryParams,
        })
        return
      }

      this.hotelSearchFormQueryService.appendQueryParams(queryParams)
    }

    return { bottomLine, recreateSearch, fillSearchForm }
  }

  getRecentRailSearches(searchParams: RailRecentSearch['railSearchParams']) {
    const {
      bottomLine,
      destinationLocationName,
      originLocationName,
      searchQueryParams,
    } = this.railRecentSearchDataService.get(searchParams)

    const recreateSearch = () => {
      if (this.isMobile && !this.costAllocationStore.isCostAllocationsValid) {
        this.recentSearchesStore.setRecentSearchCallback(recreateSearch)
        this.costAllocationStore.costAllocationModal.handleOpen()
        return
      }

      if (this.preSearchStore.isShowingPreSearchModal) {
        fillSearchForm()

        this.openPreSearchCustomFieldsModal()
        return
      }

      this.submitSearchService.submitNewRailSearch({
        originDate: searchQueryParams.originDate,
        destinationDate: searchQueryParams.destinationDate,
        destinationTime: searchQueryParams.destinationTime,
        originPlace: searchQueryParams.originPlace,
        destinationPlace: searchQueryParams.destinationPlace,
        originTime: searchQueryParams.originTime,
        railTripType: searchQueryParams.railTripType,
        railCards: searchQueryParams.railCards,
      })
      this.railSearchFormQueryService.navigateTo(
        ROUTES.rail.results({ railLegType: RailLegType.Departure }),
        { ...searchQueryParams, searchId: '' },
      )
    }

    const fillSearchForm = () => {
      this.railSearchFormQueryService.appendQueryParams(searchQueryParams)
    }

    return {
      bottomLine,
      destinationLocationName,
      originLocationName,
      recreateSearch,
      fillSearchForm,
    }
  }

  getRecentFlightSearchesWithoutDates(
    searchParams: FlightRecentSearchWithoutDates['flightSearchParams'],
  ) {
    const {
      queryParams,
      destinationLocationName,
      originLocationName,
      flightType,
    } = this.flightRecentSearchWithoutDatesDataService.get(searchParams)

    const searchQueryParams = {
      flights: queryParams,
      flightType,
      searchId: '',
    }

    const fillSearchForm = () => {
      const { flightType, flights } = searchQueryParams
      this.airSearchFormInputsActions.handleSetFlights(flights, flightType)
    }

    return {
      destinationLocationName,
      originLocationName,
      fillSearchForm,
    }
  }

  getRecentHotelSearchesWithoutDates(
    searchParams: HotelRecentSearchWithoutDates['hotelSearchParams'],
  ) {
    const { location } = this.hotelRecentSearchWithoutDatesDataService.get(searchParams)

    const queryParams = {
      location,
      hotelFilters: {
        distance: searchParams.searchRadius,
        hotelName: '',
        starRatings: [],
        brandIds: [],
        amenityIds: [],
        groupId: '',
        initialDistance: searchParams.searchRadius,
      },
    }

    const fillSearchForm = () => {
      if (this.historyStore.matchPathname(ROUTES.hotel.results)) {
        this.hotelSearchFormStore.updateEditCache({
          ...queryParams,
        })
        return
      }

      this.hotelSearchFormQueryService.appendQueryParams(queryParams)
    }

    return { fillSearchForm }
  }

  getRecentCarRentalSearchesWithoutDates(
    input: CarRentalRecentSearchWithoutDates['carRentalSearchParams'],
  ) {
    const { locationName, queryParams } = this.carRentalRecentSearchWithoutDatesDataService.get(
      input,
    )

    const fillSearchForm = () => {
      this.carRentalQueryService.appendQueryParams({
        pickUpAddress: undefined,
        dropOffAddress: undefined,
        ...queryParams,
      })
    }

    return { locationName, fillSearchForm }
  }

  private openPreSearchCustomFieldsModal() {
    this.preSearchActions.handleOpenPreSearchCustomFields()
  }
}
