import { useMemo } from 'react'
import { TextField } from '@etta/ui/text-field'
import { TextAreaDescription } from '@etta/ui/textarea-description'
import { TitleHelperText } from '../custom-field-styled'
import type { CustomFieldExtension } from '../types'
import { DateField } from './date-field'
import { CheckboxField } from './checkbox-field'
import { SelectField } from './select-field'
import type { CustomFieldRenderType, Props } from './types'

type RenderFC = (field: CustomFieldExtension, label: string) => JSX.Element | null

type RenderCustomFieldsArgs = Pick<Props, 'values' | 'errors' | 'onChange'>

export function useRenderCustomFields({ values, errors, onChange }: RenderCustomFieldsArgs) {
  const firstErrorUUID = Object.keys(errors)[0]
  const renderFunctions: Record<CustomFieldRenderType, RenderFC> = useMemo(() => {
    const renderDate: RenderFC = (field) => {
      return (
        <DateField
          onChangeHandler={onChange(field.customUuid)}
          value={values[field.customUuid]}
          key={field.customUuid}
          helperText={errors[field.customUuid]}
          field={field}
        />
      )
    }

    const renderText: RenderFC = (field, label) => {
      if (field.textDisplay!.numberOfLines > 1) {
        return (
          <TextAreaDescription
            onChange={(e) => onChange(field.customUuid)(e || '')}
            error={errors[field.customUuid]}
            maxChar={field.textDisplay!.maxLength}
            key={field.customUuid}
            placeholder={label}
            value={values[field.customUuid] as string}
            withCharRemain={false}
            withFloatPlaceholder
            description={field.tooltip}
          />
        )
      }

      return (
        <TextField
          onChange={onChange(field.customUuid)}
          label={label}
          value={values[field.customUuid]}
          key={field.customUuid}
          helperText={errors[field.customUuid]}
          helperType="error"
          description={field.tooltip}
          firstErrorUUID={firstErrorUUID}
          fieldUUID={field.customUuid}
        />
      )
    }

    const renderPreTrip: RenderFC = (field, label) => {
      return (
        <>
          <TitleHelperText>{field.preTripApprovalDisplay?.ruleDescription}</TitleHelperText>
          <TextField
            onChange={onChange(field.customUuid)}
            label={label}
            value={values[field.customUuid]}
            key={field.customUuid}
            helperText={errors[field.customUuid]}
            helperType="error"
            description={field.tooltip}
          />
        </>
      )
    }

    const renderEnumeration: RenderFC = (field, label) => {
      return (
        <SelectField
          onChangeHandler={onChange(field.customUuid)}
          label={label}
          value={values[field.customUuid]}
          field={field}
          key={field.customUuid}
          options={field.enumerationDisplay?.option || []}
          helperText={errors[field.customUuid]}
          helperType="error"
          description={field.tooltip}
        />
      )
    }

    const renderCheckbox: RenderFC = (field, label) => {
      return (
        <CheckboxField
          onChangeHandler={() => onChange(field.customUuid)(!values[field.customUuid])}
          label={label}
          checked={!!values[field.customUuid]}
          key={field.customUuid}
          helperText={errors[field.customUuid]}
          description={field.tooltip}
        />
      )
    }

    const renderNumber: RenderFC = (field, label) => {
      return (
        <TextField
          onChange={onChange(field.customUuid)}
          label={label}
          value={values[field.customUuid]}
          key={field.customUuid}
          type="number"
          helperText={errors[field.customUuid]}
          helperType="error"
          description={field.tooltip}
        />
      )
    }

    return {
      Date: renderDate,
      Text: renderText,
      PreTrip: renderPreTrip,
      Enumeration: renderEnumeration,
      Checkbox: renderCheckbox,
      Number: renderNumber,
    }
  }, [errors, onChange, values, firstErrorUUID])

  return {
    renderFunctions,
  }
}
