import { createContext } from './create-context'
import type { UseDescendantsReturn } from './type'

export const [
  DescendantsContextProvider,
  useDescendantsContext,
] = createContext<UseDescendantsReturn>({
  name: 'DescendantsProvider',
  errorMessage: 'useDescendantsContext must be used within DescendantsProvider',
})
