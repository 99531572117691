import styled from 'styled-components'
import type { TextColor } from '@etta/ui/text/types'

export const Container = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 26px 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const AirlineLogoWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-top: 6px;
`

export const AirlineLogo = styled.img`
  width: inherit;
  height: inherit;
`

export const Content = styled.div``

export const SubHeader = styled.div`
  display: flex;
  gap: 5px;
`

export const Status = styled.div<{ color: TextColor }>`
  display: flex;
  align-items: center;
  gap: 7px;

  &:after {
    content: '';
    width: 5px;
    height: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors[props.color]};
  }
`

export const TicketDetails = styled.div`
  display: grid;
  gap: 16px 80px;
  margin-top: 14px;
`

export const TicketDetail = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(2):nth-last-child(3) {
    grid-column: 2;
  }
`
