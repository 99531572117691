import styled, { css } from 'styled-components'
import { Separator } from '@etta/ui/separator'

export const Container = styled.div<{ isPhotoExist?: boolean; isCancelled?: boolean }>`
  ${(props) =>
    props.isPhotoExist
      ? css`
          padding-bottom: 16px;
        `
      : css`
          padding: ${props.isCancelled ? 20 : 16}px 0;
        `}
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px;

  > * + * {
    margin-left: 16px;
  }
`

export const Cell = styled.div`
  width: 100%;
`

export const SeparatorBlock = styled(Separator)`
  margin: 16px 0;
`
