export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M100 22.8789C100 33.2101 91.6234 41.5853 81.2938 41.5853C70.9623 41.5853 62.5874 33.2101 62.5874 22.8789C62.5874 12.5477 70.9623 4.17249 81.2938 4.17249C91.6234 4.17249 100 12.5477 100 22.8789"
        fill="#EFEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1555 23.0437C96.1555 31.1603 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1603 66.7598 23.0437C66.7598 14.9254 73.3397 8.34497 81.4568 8.34497C89.5755 8.34497 96.1555 14.9254 96.1555 23.0437"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.3983 32.3961C90.2124 32.3961 90.8109 31.7644 90.8109 30.9676C90.8109 30.5983 90.6833 30.2388 90.4086 29.9667L86.1415 25.7201C87.0244 24.486 87.5444 22.97 87.5444 21.3374C87.5444 17.1297 84.0719 13.6897 79.8244 13.6897C75.577 13.6897 72.1045 17.1297 72.1045 21.3374C72.1045 25.5452 75.577 28.9852 79.8244 28.9852C81.3841 28.9852 82.8457 28.5188 84.0621 27.7219L88.3585 31.9879C88.643 32.26 89.0059 32.3961 89.3983 32.3961ZM79.8244 26.9445C76.7149 26.9445 74.1644 24.4179 74.1644 21.3374C74.1644 18.2667 76.7149 15.7304 79.8244 15.7304C82.934 15.7304 85.4844 18.2667 85.4844 21.3374C85.4844 24.4179 82.934 26.9445 79.8244 26.9445Z"
        fill="white"
      />
      <path
        d="M49.7395 29.157C50.6982 29.1553 51.6177 29.5171 52.2922 30.1617C52.9667 30.8062 53.3399 31.6796 53.3283 32.5865V50.9676L49.493 54.7604L54.7275 61.0909H50.624L47.666 57.737H35.9284L33.1734 61.0909H29.2729L34.1812 54.7604L30.2662 51.0636V32.5865C30.2544 31.6737 30.6325 30.7949 31.3149 30.1494C31.9973 29.5038 32.9262 29.1461 33.8912 29.157H49.7395ZM39.0821 49.5959H33.9129L34.3117 49.9799C35.5134 51.1535 37.1664 51.8166 38.8936 51.8181H39.0821V49.5959ZM49.9063 49.5959H44.7371V51.8181H45.3533C46.7954 51.812 48.1746 51.2585 49.1813 50.2817L49.9063 49.5959ZM49.812 32.5797H33.8187V41.4959C33.8187 42.7042 34.8541 43.6838 36.1314 43.6838H47.4993C48.7766 43.6838 49.812 42.7042 49.812 41.4959V32.5797ZM49.0798 22.9091L53.3283 27.0105H49.5583L47.9343 25.5565H35.5369L34.0942 27.0105H30.3024L34.3697 22.9091H49.0798Z"
        fill="white"
      />
    </svg>
  )
}
