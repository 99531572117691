import type { Props } from '../../types'
import { MainTitle, SubTitle, TitleContainer } from './title-mobile-styled'

export function TitleMobile({
  title,
  layout = 'aligned',
  toLayout,
  subTitle,
  align = 'center',
  color = 'mainText',
  'aria-label': ariaLabel,
  'data-tracking-id': dataTestId,
}: Props) {
  return (
    <TitleContainer align={align} aria-label={ariaLabel} layout={layout}>
      {title && (
        <MainTitle
          role="heading"
          aria-level={2}
          layout={layout}
          toLayout={toLayout || layout}
          color={color}
          data-tracking-id={dataTestId}>
          {title}
        </MainTitle>
      )}
      {subTitle && (
        <SubTitle layout={layout} toLayout={toLayout || layout}>
          {subTitle}
        </SubTitle>
      )}
    </TitleContainer>
  )
}
