import { Store } from '@etta/di'
import type { CarRulesByTransactionId } from '@etta/modules/rules-modals/core/car-rental-rules-modal/value-objects/car-rental-rules.value-object'

@Store()
export class CarRentalRulesStore {
  carRules: CarRulesByTransactionId[] = []
  isLoading = false
  isError = false

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setCarRules(value: CarRulesByTransactionId[]) {
    this.carRules = value
  }
}
