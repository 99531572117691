import styled from 'styled-components'
import { captionMedium, footnoteMedium } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'
import { Image } from '@etta/ui/image'
import { Text } from '@etta/ui/text'

export const CloseButton = styled(IconButton)`
  margin-left: auto;
`

export const LogoContainer = styled.div`
  margin-bottom: 16px;

  > div {
    width: auto;
    max-height: 24px;
    min-height: 24px;
  }
`

export const Wrapper = styled.div`
  padding: 24px;
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
`

export const SegmentLogo = styled(Image)`
  max-height: 24px;
  width: auto;
  object-fit: contain;
  align-self: start;
`

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SummarySegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Rules = styled.ul`
  margin: 4px 0px 8px 0px;
  padding-left: 20px;
`

export const List = styled.ul`
  print-color-adjust: exact;
  background-color: ${(p) => p.theme.colors.background4};
  padding: 16px 16px 16px 32px;
  margin: 0;
  border-radius: 10px;
`

export const ListItem = styled.li`
  ${footnoteMedium}
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  text-align: left;
  margin-top: 16px;
`

export const Caption = styled.caption`
  text-align: left;
  margin-bottom: 4px;
`

export const TableRow = styled.tr`
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  height: 40px;
`

export const TableHead = styled.thead`
  color: ${(p) => p.theme.colors.bodyText};
  print-color-adjust: exact;
  background-color: ${(p) => p.theme.colors.background4};
`

export const TableBody = styled.tbody`
  color: ${(p) => p.theme.colors.mainText};
`

export const TableH = styled.th`
  padding: 12px;
  ${captionMedium}
`

export const TableD = styled.td`
  padding: 12px;
  ${captionMedium}
`

export const ButtonWrapper = styled.div`
  text-align: right;

  & button div:first-child {
    margin: 0;
  }

  & button div:nth-child(2) {
    flex: none;
  }
`

export const HotelReviewWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PaymentCardText = styled(Text)`
  font-variant-ligatures: none;
`

export const DisplayNone = styled.div`
  display: none;
`
