import { useContext, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { HistoryBlockContext } from '@fiji/providers/history-block-provider'
import type { PreventNavigationArgs } from './types'
import { checkPathnameRestricted } from './check-pathname-restricted'

type Args = PreventNavigationArgs & {
  actionType: 'POP' | 'PUSH'
}

const NEXT_TICK_TIMEOUT = 100
const noOp = (_: string) => {}

export function usePrevent({
  actionType,
  restrictedRoutes,
  restrictedRoutesMatch,
  isBlocking,
  onPrevent,
}: Args) {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const isBlockingRef = useRef(false)
  const onPreventRef = useRef(noOp)

  isBlockingRef.current = !!isBlocking
  onPreventRef.current = onPrevent || noOp

  const historyBlockContext = useContext(HistoryBlockContext)

  useEffect(() => {
    let timerId: number | null = null
    const unblock = historyBlockContext.block((location, action) => {
      if (!isBlockingRef.current) {
        return
      }
      if (action !== actionType) {
        return
      }

      const { pathname: newPathname } = location
      if (newPathname.includes(pathname)) {
        return
      }

      const isRestrictedByPathName =
        checkPathnameRestricted({ newPathname, restrictedRoutes }) ||
        checkPathnameRestricted({
          newPathname,
          restrictedRoutes: restrictedRoutesMatch,
          isMatched: true,
        })
      if (isRestrictedByPathName) {
        return
      }

      timerId = window.setTimeout(() => {
        if (actionType === 'PUSH') {
          onPreventRef.current(newPathname)
          return
        }

        const historyListenerHandler = history.listen(() => {
          onPreventRef.current(newPathname)
          historyListenerHandler()
        })
        history.push(`${pathname}${search}`)
      }, NEXT_TICK_TIMEOUT)

      return false
    })

    return () => {
      unblock()
      timerId && window.clearTimeout(timerId)
    }
    // eslint-disable-next-line
  }, [])
}
