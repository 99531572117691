import type { MutableRefObject} from 'react';
import { useEffect } from 'react'

type Args = {
  ref: MutableRefObject<HTMLElement | null>
  isOpen: boolean
  onClose: () => void
}

export function useCloseOnEscape({ ref, isOpen, onClose }: Args) {
  useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code !== 'Escape') {
        return
      }

      if (ref.current && ref.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown, true)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true)
    }
  }, [ref, isOpen, onClose])
}
