import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { AirSearchForm } from '@etta/components/air-search-form'
import { IconButton } from '@etta/ui/icon-button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { MeetingInformationRemark } from '@etta/modules/meeting/ui/meeting-information/components/meeting-information-remark'
import { CloseIconWrapper, ModalContent, TitleWrapper, TextWrapper } from './styled'

const DATA_TRACKING_ID = 'POST_BOOKING_AIR_SEARCH_FORM_MODAL'

export const AirSearchFormModalDesktop = observer(() => {
  const { t } = useTranslation()
  const { postBookingTripStore } = usePostBookingContext()

  useEffect(() => {
    return () => {
      postBookingTripStore.airSearchFormModal.handleClose()
    }
  }, [postBookingTripStore])

  return (
    <Modal
      disableOverflow={false}
      data-tracking-id={DATA_TRACKING_ID}
      withOverlay
      handleModalVisibility={postBookingTripStore.airSearchFormModal.handleClose}
      isVisible={postBookingTripStore.airSearchFormModal.isOpen}
      position="top">
      <CloseIconWrapper>
        <IconButton
          onClick={postBookingTripStore.airSearchFormModal.handleClose}
          icon={'closePWA'}
          size={'medium'}
        />
      </CloseIconWrapper>
      <TitleWrapper>
        <Text variant="title3">{t('PostBooking.FlightDetails.Modify')}</Text>
      </TitleWrapper>
      <ModalContent>
        <AirSearchForm />
        {postBookingTripStore.trip.meetingInfo && (
          <TextWrapper>
            <MeetingInformationRemark meeting={postBookingTripStore.trip.meetingInfo} />
          </TextWrapper>
        )}
      </ModalContent>
    </Modal>
  )
})
