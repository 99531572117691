import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../types'
import { FlightDetailsEcoCheckModalMobile, FlightDetailsEcoCheckModalMobileNew } from './mobile'
import { FlightDetailsEcoCheckModalDesktop, FlightDetailsEcoCheckModalDesktopNew } from './desktop'

export function FlightDetailsEcoCheckModalLayout(props: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()
  if (isNewEmissionsBrandingEnabled) {
    return (
      <MediaLayout
        mobileSlot={<FlightDetailsEcoCheckModalMobileNew {...props} />}
        desktopSlot={<FlightDetailsEcoCheckModalDesktopNew {...props} />}
      />
    )
  }
  return (
    <MediaLayout
      mobileSlot={<FlightDetailsEcoCheckModalMobile {...props} />}
      desktopSlot={<FlightDetailsEcoCheckModalDesktop {...props} />}
    />
  )
}
