import { useContext, useEffect, useRef } from 'react'
import { ScrollByContentContext } from './scroll-by-content-context'

type Props = {
  name: string
  isZeroPoint?: boolean
}

export function Anchor({ name, isZeroPoint }: Props) {
  const elementRef = useRef(null)
  const scrollContext = useContext(ScrollByContentContext)
  useEffect(() => {
    scrollContext.setAnchor({ name, node: elementRef, isZeroPoint })
    return () => {
      scrollContext.dropAnchor(name)
    }
  }, [elementRef, isZeroPoint, name, scrollContext])

  return <div ref={elementRef} />
}
