import { Inject, Service } from '@etta/di'
import { GetTripsListService } from '@etta/modules/post-booking-list/interface/services/get-trips-list.service'
import { resetMainNavigation } from '@fiji/store/main-navigation'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { OutOfPolicyEnum, SegmentType } from '@etta/core/enums'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { UserStore, UserActions } from '@etta/modules/user'
import { TravelPreferencesStore, TravelPreferencesActions } from '@etta/modules/travel-preferences'
import { CheckoutInfoAdapter } from '@etta/modules/booking/infra/checkout-info/checkout-info.adapter'
import type { PaymentStructureValueObject } from '@etta/modules/booking/core/value-objects'
import { OutOfPolicyStore } from '@etta/modules/review-trip/interface/out-of-policy/stores'
import { CartAdapter } from '@etta/modules/review-trip/infra'
import { PostBookingTripStore } from '@etta/modules/post-booking/interface/stores/trip/post-booking-trip.store'
import type {
  FailedSegmentValueObject as ReviewTripFailedSegmentValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
import type { PolicyValueObject } from '@etta/core/value-objects'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { ShareTravelerContact } from '@etta/modules/travel-preferences/core/enums/share-traveler-contact.enum'
import { PostBookingTripService } from '@etta/modules/post-booking/interface/services/post-booking-trip.service'
import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import { QualtricsService } from '@etta/modules/qualtrics/interface/services/qualtrics.service'
import { PreSearchStore } from '@etta/modules/pre-search'
import { CostAllocationStore } from '@etta/modules/cost-allocation/interface/stores/cost-allocation.store'
import { CostSegmentSplit, CostSegmentType } from '@etta/core/enums/cost-segment-type.enum'
import { BookTripStore } from '../stores'
import { CheckoutCartAdapter } from '../../../infra/checkout-cart'
import { LuggageOptionsStore } from '../../luggage-options/stores/luggage-options.store'
import type { BookTripArgs, SetTripConfirmation } from '../types'
import { ResetBookTripInfoService } from '../../reset-book-trip-info/reset-book-trip-info.service'
import { CheckoutInfoStore } from '../../checkout-info/checkout-info.store'
import { CheckoutItineraryInputMapper } from './checkout-itinerary.mapper'

type CheckoutItineraryArgs = {
  bookingId?: string | null
  putOnHold?: boolean
  payment?: PaymentStructureValueObject
  shareTravelerContact?: ShareTravelerContact | null
  customFields?: CustomFieldBookingValueObject[] | null
}

type shouldOopBeSent = {
  type?: 'hotel'
  tripOop?: PolicyValueObject[] | null
  segmentOop?: TripSegmentValueObject[] | null
  selectedOop?: string | null
}

@Service()
export class BookTripService {
  constructor(
    @Inject()
    private readonly checkoutCartAdapter: CheckoutCartAdapter,
    @Inject()
    private readonly postBookingTripStore: PostBookingTripStore,
    @Inject()
    private readonly bookTripStore: BookTripStore,
    @Inject()
    private readonly travelPreferencesStore: TravelPreferencesStore,
    @Inject()
    private readonly travelPreferencesActions: TravelPreferencesActions,
    @Inject()
    private readonly userActions: UserActions,
    @Inject()
    private readonly costAllocationStore: CostAllocationStore,
    @Inject()
    private readonly userStore: UserStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,
    @Inject()
    private readonly fijiStoreProxyService: FijiStoreProxyService,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly tripsListService: GetTripsListService,
    @Inject()
    private readonly luggageOptionsStore: LuggageOptionsStore,
    @Inject()
    private readonly resetBookTripInfoService: ResetBookTripInfoService,
    @Inject()
    private readonly checkoutInfoAdapter: CheckoutInfoAdapter,
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly postBookingTripService: PostBookingTripService,
    @Inject()
    private readonly qualtricsService: QualtricsService,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
  ) {}

  async handleBookTrip(args: BookTripArgs) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      return this.handleCheckoutCart()
    }

    return this.handleCheckoutItinerary({
      bookingId: args.bookingId,
      putOnHold: args.putOnHold,
      payment: args.payment,
      shareTravelerContact: args.shareTravelerContact,
      customFields: args.customFields,
    })
  }

  getOopToSent({
    type,
    selectedOop,
    segmentOop,
    tripOop,
  }: shouldOopBeSent): string | null | undefined {
    const isHotelRequiredForOvernightTrip = tripOop?.find((policy) =>
      policy.outOfPolicyReasons?.includes(OutOfPolicyEnum.HotelRequiredForOvernightTrip),
    )
    const doesSegmentExist = !!segmentOop?.length
    const isAtLeastOneSegmentOop = !!segmentOop?.find((segment) => !segment.policy.isInPolicy)

    if (type === 'hotel') {
      return (doesSegmentExist && isAtLeastOneSegmentOop) || isHotelRequiredForOvernightTrip
        ? selectedOop
        : undefined
    }
    return doesSegmentExist && isAtLeastOneSegmentOop ? selectedOop : undefined
  }

  private async handleCheckoutItinerary({
    bookingId,
    putOnHold,
    payment,
    shareTravelerContact,
    customFields,
  }: CheckoutItineraryArgs) {
    const { billing, primaryTraveler, meal, memberships, specialRequests } = this.checkoutInfoStore

    if (!primaryTraveler.travelerData) {
      throw new Error('error primart travler is not defined')
    }
    this.bookTripStore.setIsBooked(false)
    this.bookTripStore.setErrorMessages([])
    this.bookTripStore.setIsLoading(true)
    this.bookTripStore.setIsError(false)
    this.bookTripStore.setFailedBookings([])

    const checkoutItineraryInput = CheckoutItineraryInputMapper.toInput({
      itineraryId: this.reviewTripStore.itineraryId,
      bookingId,
      putOnHold,
      billing: billing.billingData,
      hotelBillingFromCheckoutInfo: billing.hotel,
      payment,
      primaryTraveler: primaryTraveler.travelerData,
      meal: { air: meal.mealRequestValue },
      memberships: memberships.memberShipData,
      specialRequests: specialRequests.specialRequestData,
      travelerFields: primaryTraveler.travelerFields,
      segments: this.reviewTripStore.trip?.segments || [],
      costAllocation: this.costAllocationStore.costAllocationForBooking || [],
      outOfPolicyExplanation: {
        air: this.getOopToSent({
          selectedOop: this.outOfPolicyStore.oopSegments.air,
          segmentOop: this.reviewTripStore.trip?.segments.filter(
            (segment) => segment.type === SegmentType.Flight,
          ),
          tripOop: this.reviewTripStore.trip?.tripLevelPolicy,
        }),
        carRental: this.getOopToSent({
          selectedOop: this.outOfPolicyStore.oopSegments.carrental,
          segmentOop: this.reviewTripStore.trip?.segments.filter(
            (segment) => segment.type === SegmentType.CarRental,
          ),
          tripOop: this.reviewTripStore.trip?.tripLevelPolicy,
        }),
        hotel: this.getOopToSent({
          type: 'hotel',
          selectedOop: this.outOfPolicyStore.oopSegments.hotel,
          segmentOop: this.reviewTripStore.trip?.segments.filter(
            (segment) => segment.type === SegmentType.Hotel,
          ),
          tripOop: this.reviewTripStore.trip?.tripLevelPolicy,
        }),
        trip: this.outOfPolicyStore.oopSelections.description,
      },
      customFields: customFields,
      departureLuggageOption: this.luggageOptionsStore.departureLuggageOption,
      returnLuggageOption: this.luggageOptionsStore.returnLuggageOption,
      shareTravelerContact: shareTravelerContact,
    })

    const result = await this.checkoutInfoAdapter.handleCheckoutItinerary(checkoutItineraryInput)

    const retryHandler = this.handleCheckoutItinerary.bind(this, {
      ...checkoutItineraryInput,
      shareTravelerContact,
    })

    result.match({
      Ok: async ({ trip: newTrip, failedBookings }) => {
        this.resetStoresAfterBook()
        this.postBookingTripStore.setTrip(newTrip)
        const isAllBookingsSucceeded = newTrip.failedSegments.length === 0
        const isAllBookingsFailed = newTrip.segments.length === 0
        const isOnlyHotelFailed =
          newTrip.failedSegments.filter((segment) => segment.type !== SegmentType.Hotel).length ===
          0

        // show oops error if all failed
        if (isAllBookingsFailed && !isOnlyHotelFailed) {
          this.bookTripStore.setIsLoading(false)
          this.bookTripStore.setIsBooked(false)
          this.bookTripStore.setIsError(true)
          return
        }

        this.bookTripStore.setIsBooked(true)
        this.reviewTripStore.setFailedSegments(
          // TODO see if this can be removed as confirmation page are using data from post booking trip store
          getReviewTripFailedSegmentsFromFailedBookings(newTrip.failedSegments),
        )

        // set this for hotel retry
        if (isOnlyHotelFailed && failedBookings?.length) {
          this.bookTripStore.setFailedBookings(failedBookings)
        }

        // set this for hotel retry
        if (!isAllBookingsSucceeded) {
          this.bookTripStore.setRetryBooking({
            numberOfRetries: this.bookTripStore.retryBookingData?.numberOfRetries ?? 0,
            onHandle: retryHandler,
          })
        }

        this.setTripConfirmationType({
          isOnHold: putOnHold,
          isOnlyHotelFailed,
          isAllBookingsFailed,
          isAllBookingsSucceeded,
        })

        // load trip detail, try to get latest data from GDS after book
        await this.postBookingTripService.getTripDetails(
          {
            id: newTrip.processId,
            transactionGroupId: newTrip.transactionRegistryServiceItemId ?? undefined,
          },
          true,
        )

        this.bookTripStore.setIsLoading(false)
        this.checkoutInfoStore.dropPersisted()
        this.preSearchStore.dropCustomFields()
        this.qualtricsService.init()
      },
      Err: () => {
        this.bookTripStore.setIsError(true)
        this.bookTripStore.setIsLoading(false)
      },
    })
    return result
  }

  private async handleCheckoutCart() {
    this.bookTripStore.setIsBooked(false)
    this.bookTripStore.setErrorMessages([])
    this.bookTripStore.setIsLoading(true)
    this.bookTripStore.setIsError(false)

    const input = this.buildCheckoutCartInput()

    if (this.costAllocationStore?.costAllocationForBooking?.length) {
      await this.cartAdapter.setCostAllocation({
        costAllocations: this.costAllocationStore.costAllocationForBooking.map(
          (costAllocation) => ({
            allocationId: costAllocation.allocationId,
            costSegmentSplit:
              costAllocation?.costSegmentType === CostSegmentType.Primary
                ? CostSegmentSplit.Primary
                : CostSegmentSplit.Secondary,
            segmentId: costAllocation.segmentId,
          }),
        ),
        cartId: this.reviewTripStore.tripId,
      })
    }

    const result = await this.checkoutCartAdapter.handleCheckoutCart(input)

    result.match({
      Ok: () => {
        this.resetStoresAfterBook()
        this.bookTripStore.setIsBooked(true)
        this.bookTripStore.setIsLoading(false)
      },
      Err: () => {
        this.bookTripStore.setIsError(true)
        this.bookTripStore.setIsLoading(false)
      },
    })

    return result
  }

  private setTripConfirmationType: SetTripConfirmation = ({
    isOnHold,
    isOnlyHotelFailed,
    isAllBookingsFailed,
    isAllBookingsSucceeded,
  }) => {
    if (isAllBookingsFailed) {
      if (isOnlyHotelFailed) {
        this.bookTripStore.setTripConfirmationType('unconfirmed')
        return
      }
      return
    }

    if (isAllBookingsSucceeded) {
      if (isOnHold) {
        this.bookTripStore.setTripConfirmationType('hold')
        return
      }
      this.bookTripStore.setTripConfirmationType('regular')
      return
    }

    // partial success
    this.bookTripStore.setTripConfirmationType('partial')
  }

  private buildCheckoutCartInput() {
    // Temporary solution until we have the store for user scheme.

    const tripId = this.reviewTripStore.tripId
    const tripName = this.reviewTripStore.trip?.tripInfo.name || ''
    const user = this.userStore.user
    return {
      cartId: tripId,
      emailAddress: user?.profile?.personalContact?.email || '',
      gender: user?.profile?.gender || '',
      mobilePhoneNumber: {
        number: user?.profile?.businessContact?.mobilePhone?.number || '',
        countryCode: user?.profile?.businessContact?.mobilePhone?.ext || '',
      },
      givenName: user?.profile?.firstName || '',
      surname: user?.profile?.lastName || '',
      middle: user?.profile?.middleName || undefined,
      suffix: user?.profile?.suffix || undefined,
      title: user?.profile?.title || undefined,
      tripName,
    }
  }

  private resetStoresAfterBook() {
    // TODO: remove dispatch after migration of navigation module
    this.resetBookTripInfoService.resetInfo()
    this.tripsListService.dropAndReloadAllOrders()
    this.fijiStoreProxyService.dispatch(resetMainNavigation())
    this.fijiStoreProxyService.dispatch(updateTripReviewStatus('none'))
    this.userStore.dropGuestInformation()
    this.travelPreferencesStore.dropStore()
    this.travelPreferencesActions.refetchTravelPreferences()
    this.userActions.reloadUser()
  }
}

function getReviewTripFailedSegmentsFromFailedBookings(
  segments: SegmentPostBookingValueObject[],
): ReviewTripFailedSegmentValueObject[] {
  return segments.reduce<ReviewTripFailedSegmentValueObject[]>((acc, segment) => {
    let id: string = ''
    if (segment.type === SegmentType.Flight && segment.legId) {
      id = segment.legId
    }
    if (segment.type === SegmentType.Hotel && segment.id) {
      id = segment.id
    }
    if (segment.type === SegmentType.CarRental && segment.carId) {
      id = segment.carId
    }

    if (id !== '') {
      acc.push({
        id,
        type: segment.type,
      })
    }
    return acc
  }, [])
}
