import { useTranslation } from 'react-i18next'
import type { HotelEmission } from '@fiji/types'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'
import { useChangeRoom } from './use-change-hotel'
import { useHotelDetails } from './use-hotel-details'
import { HotelDetailsModalLayout } from './layout'

type Props = {
  isVisible: boolean
  onClose: () => void
  hotelDetails: HotelDetailsType
  isCallEhi?: boolean // FIXME: replace from Hotel data on integration
  isError?: boolean
  isLoading?: boolean
  onRetry?: () => void
  itineraryId: string
  roomKey: string
  isTripExist: boolean
  hotelEmission: HotelEmission
}

export function HotelDetailsModal({
  isVisible,
  hotelDetails,
  onClose,
  itineraryId,
  roomKey,
  isTripExist,
  isError,
  onRetry,
  hotelEmission,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetailsModal.'
  const {
    totalCostTitle,
    priceDetailsList,
    removeDialogToggle,
    changeDialogToggle,
    handleRemoveHotel,
    viewStateParams,
    isCancelable,
    onViewStateDone,
  } = useHotelDetails({ hotelDetails, itineraryId, roomKey, isTripExist, onClose })

  const { handleChangeRoom } = useChangeRoom({
    itineraryId,
    id: roomKey,
    checkIn: hotelDetails.checkIn,
    checkOut: hotelDetails.checkOut,
    address: hotelDetails.address,
    handleCloseChangeModal: changeDialogToggle.handleClose,
  })

  return (
    <>
      <HotelDetailsModalLayout
        isVisible={isVisible}
        hotelDetails={hotelDetails}
        totalCostTitle={totalCostTitle}
        priceDetailsList={priceDetailsList}
        isError={isError}
        isCancelable={isCancelable}
        onRetry={onRetry}
        onClose={onClose}
        onChange={changeDialogToggle.handleOpen}
        onCancel={removeDialogToggle.handleOpen}
        hotelEmission={hotelEmission}
      />

      <ConfirmationDialog
        isOpen={removeDialogToggle.isOpen}
        onDecline={removeDialogToggle.handleClose}
        onConfirm={() => {
          handleRemoveHotel()
          removeDialogToggle.handleClose()
        }}
        title={t(i18Base + 'RemoveHotel')}
        content={t(i18Base + 'RemoveHotelExplanation')}
        confirmButtonText={t(i18Base + 'RemoveConfirmButton')}
        declineButtonText={t(i18Base + 'RemoveDeclineButton')}
      />
      <ConfirmationDialog
        isOpen={changeDialogToggle.isOpen}
        onDecline={changeDialogToggle.handleClose}
        onConfirm={handleChangeRoom}
        title={t(i18Base + 'ChangeHotel.Title')}
        content={t(i18Base + 'ChangeHotel.InfoText')}
        confirmButtonText={t(i18Base + 'ChangeHotel.ConfirmButtonText')}
        declineButtonText={t(i18Base + 'ChangeHotel.DeclineButtonText')}
      />
      <ViewStateModal
        viewState={viewStateParams.viewState}
        onDone={onViewStateDone}
        title={viewStateParams.title}
      />
    </>
  )
}
