import { gql } from '@apollo/client'
import * as ApolloReactCommon from '@apollo/client'
import * as ApolloReactHooks from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AirportCode: any
  Date: any
  DateTime: any
  LegPosition: any
  TimeOfDay: any
}

/** Indicates what type of account the user has */
export enum AccountType {
  EndUser = 'END_USER',
  SupportUser = 'SUPPORT_USER',
}

export type AckNotificationEventInput = {
  clientType: NotificationClientType
  eventId: Scalars['ID']
}

export type AddCarRentalInput = {
  itineraryId: Scalars['String']
  key: Scalars['String']
  searchId?: InputMaybe<Scalars['String']>
}

export type AddCarServiceInput = {
  itineraryId: Scalars['String']
  key: Scalars['String']
}

export type AddCreditCardInput = {
  billingAddress?: InputMaybe<CreditCardBillingAddressInput>
  creditCardData: CreditCardInput
}

export type AddFlightInput = {
  addSeatAssignment?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  itineraryId: Scalars['String']
  key: Scalars['String']
}

export type AddFlightResponse = CommonResponse & {
  /** @deprecated Use data instead */
  airPriceChange?: Maybe<SegmentPriceChangeDetails>
  code: Scalars['String']
  data?: Maybe<AddFlightResponseData>
  /** @deprecated Use data instead */
  isSoldOut: Scalars['Boolean']
  /** @deprecated Use data instead */
  itineraryId: Scalars['String']
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type AddFlightResponseData = FlightResponseData & {
  airPriceChange?: Maybe<SegmentPriceChangeDetails>
  flightTripCostPerPassenger?: Maybe<Array<Maybe<TripCost>>>
  /** Indicates whether there has been a failure with repricing, and that a desirable fare can't be selected. */
  isRepriceFailed: Scalars['Boolean']
  isSoldOut: Scalars['Boolean']
  itineraryId: Scalars['String']
}

export type AddFlightSeatAssignmentInput = {
  seatNumber: Scalars['String']
  segmentId: Scalars['String']
  travelerId: Scalars['String']
}

/** Provides the input parameters for the addModifyToCart mutation. */
export type AddModifyToCartInput = {
  /** Additional parameters for Air travel intents. */
  airParameters?: InputMaybe<AirParametersInput>
  /** Unique identifier for the cart that was previously created. */
  cartId: Scalars['ID']
  /** Indicates the domain of the reservation. */
  domain: Domain
  /** Unique identifier for the inventory requested in a specific domain. Eg: hotel room, car rental vehicle, etc. */
  inventoryId: Scalars['ID']
  /** Unique identifier for the existing reservation that needs to be canceled. */
  reservationId: Scalars['ID']
  /** Unique identifier for the search created in the domain to which the intent belongs. */
  searchId: Scalars['ID']
}

/** AddModifyToCartResponse is returned when the addModifyToCart mutation succeeds. */
export type AddModifyToCartResponse = {
  /** Unique identifier for the intent that is added to an existing cart. */
  intentId: Scalars['ID']
}

export type AddNewPassportInput = {
  passport: PassportInput
}

export type AddPassportInput = {
  passport: PassportInput
}

export type AddPassportResponse =
  | BaseResponse
  | InvalidInputError
  | NoPassportError
  | UnexpectedError

export type AddPaymentCardInput = {
  billingAddress?: InputMaybe<CreditCardBillingAddressInput>
  creditCardData: CreditCardInput
}

export type AddPaymentCardResponse = PaymentCard | UnexpectedError

export type AddRailInput = {
  addSeatAssignment?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  direction?: InputMaybe<JourneyDirection>
  itineraryId: Scalars['String']
  key: Scalars['String']
  routeType?: InputMaybe<RouteType>
  tripName?: InputMaybe<Scalars['String']>
}

export type AddRailResponse = CommonResponse & {
  code: Scalars['String']
  data?: Maybe<AddRailResponseData>
  /** @deprecated Use data instead */
  isSoldOut: Scalars['Boolean']
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type AddRailResponseData = {
  isSoldOut: Scalars['Boolean']
  itineraryId: Scalars['String']
}

export type AddSeatAssignmentInput = {
  itineraryId: Scalars['String']
  seatNumber: Scalars['String']
  segmentId: Scalars['String']
  travelerId: Scalars['String']
}

/** Provides the input parameters for the addToCart mutation. */
export type AddToCartInput = {
  /** Additional parameters for Air travel intents. */
  airParameters?: InputMaybe<AirParametersInput>
  /** Unique identifier for the cart that was previously created. */
  cartId: Scalars['ID']
  /** Indicates the domain of the intent. */
  intentDomain: Domain
  /** Unique identfier for the inventory requested in a specific domain. Eg: hotel room, car rental vehicle, etc. */
  inventoryId: Scalars['ID']
  /** Unique identifier for the search created in the domain to which the intent belongs. */
  searchId: Scalars['ID']
}

/** AddToCartResponse is returned when the addToCart mutation succeeds. */
export type AddToCartResponse = {
  /** Unique identifier for the intent that is added to an existing cart. */
  intentId: Scalars['ID']
}

/** Union of different response objects that can be returned by addToCart mutation. Returns known/expected errors in a structured manner to the client. */
export type AddToCartResponseUnion =
  | AddToCartResponse
  | CartNotFoundResponse
  | GenericCartErrorResponse
  | UnexpectedError

/** Contains additional documents like known traveler numbers and redress numbers. */
export type AdditionalDocumentsPreferences = {
  knownTravelerNumbers?: Maybe<Array<KnownTravelerNumber>>
  redressNumbers?: Maybe<Array<RedressNumber>>
}

export type AdditionalDocumentsPreferencesInput = {
  /** List of Known traveler numbers. */
  knownTravelerNumbers?: InputMaybe<Array<KnownTravelerNumberInput>>
  /** List of Redress numbers. */
  redressNumbers?: InputMaybe<Array<RedressNumberUnput>>
}

/** Provides details about the additional information field. */
export type AdditionalInformation = {
  /** Provides specific properties depending on the field type. */
  additionalFieldProperties: AdditionalInformationDetailsUnion
  /** Field Type associated with the additional informtion field. */
  fieldType: UserProfileCustomFieldType
  /** Unique identifier for the additional information field. */
  id: Scalars['Float']
  /** Unique identifier for the additional information field. */
  label: Scalars['String']
  /** Unique identifier for the additional information field. */
  mandatory: Scalars['Boolean']
  /** Unique identifier for the additional information field. */
  name: Scalars['String']
  tooltip?: Maybe<Scalars['String']>
}

/** Union of field type specific objects. */
export type AdditionalInformationDetailsUnion =
  | CheckBoxAdditionalInformation
  | DateTimeAdditionalInformation
  | ListAdditionalInformation
  | NumberAdditionalInformation
  | TextAdditionalInformation

export enum AdditionalInformationListAlignmentEnum {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
}

export enum AdditionalInformationListTypeEnum {
  RadioButtons = 'RADIO_BUTTONS',
  SelectBox = 'SELECT_BOX',
}

/** A generic address, used for the input and display of address details. */
export type Address = {
  /** The IATA code of the nearest airport. For example, "SFO" = San Francisco International Airport. */
  airportCode?: Maybe<Scalars['String']>
  city: Scalars['String']
  countryCode: Scalars['String']
  /** Specific the latitude and longitude of the location */
  geocode?: Maybe<Geocode>
  /** This value is populated by Gazoo and used to display information across multiple screens within the application, including the location autocomplete and reservation details screens. For instance, it could display "Golden Gate Bridge". */
  locationName?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
}

export type AddressInput = {
  city: Scalars['String']
  countryCode: Scalars['String']
  lat?: InputMaybe<Scalars['Float']>
  long?: InputMaybe<Scalars['Float']>
  postalCode?: InputMaybe<Scalars['String']>
  stateCode: Scalars['String']
  street1: Scalars['String']
  street2?: InputMaybe<Scalars['String']>
}

export type AddressInputDto = {
  airportCode?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  countryCode: Scalars['String']
  geocode?: InputMaybe<GeocodeInput>
  locationName?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  stateCode?: InputMaybe<Scalars['String']>
  street1?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
}

export type AgencyInfo = {
  agencyAddress?: Maybe<Address>
  agencyMessage?: Maybe<Scalars['String']>
  agencyName?: Maybe<Scalars['String']>
  agencyPhoneNumber?: Maybe<Scalars['String']>
  agencyRemarks?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['ID']>
}

/** Inflight airplane amenity available for a class of service. */
export enum AirAmenity {
  AcPower = 'AC_POWER',
  AdditionalServices = 'ADDITIONAL_SERVICES',
  AudioProgramming = 'AUDIO_PROGRAMMING',
  DutyFreeSales = 'DUTY_FREE_SALES',
  Email = 'EMAIL',
  EntertainmentOnDemand = 'ENTERTAINMENT_ON_DEMAND',
  InternetAccess = 'INTERNET_ACCESS',
  InSeatPowerSource = 'IN_SEAT_POWER_SOURCE',
  InSeatVideoPlayer = 'IN_SEAT_VIDEO_PLAYER',
  LieFlatSeat = 'LIE_FLAT_SEAT',
  Movie = 'MOVIE',
  NonSmoking = 'NON_SMOKING',
  NoDutyFreeSales = 'NO_DUTY_FREE_SALES',
  ReservationBookingService = 'RESERVATION_BOOKING_SERVICE',
  ShortFeatureVideo = 'SHORT_FEATURE_VIDEO',
  Smoking = 'SMOKING',
  Telephone = 'TELEPHONE',
  Television = 'TELEVISION',
  Unspecified = 'UNSPECIFIED',
  UsbPower = 'USB_POWER',
  Wifi = 'WIFI',
}

/** Out of policy reason. */
export type AirOutOfPolicyReason = {
  /** Fallback display message to the user when specific out of policy reason type is not known. */
  fallbackMessage: Scalars['String']
  /** Type of out of policy reason. */
  inPolicy: Scalars['Boolean']
  /** Type of out of policy reason. */
  oopReasonType: Array<OutOfPolicyReasonType>
  /** Data associated with out of policy reason.. */
  outOfPolicyReasonData: OutOfPolicyReasonData
}

/** Parameters for air travel intents. */
export type AirParametersInput = {
  /** Seat selections for specific passengers and flight segments. Specify empty seat_number in SeatSelection to remove a previous selection. */
  seats: Array<SeatSelectionsInput>
}

export type AirPreferenceExcerptInput = {
  knownTravelerIssuingCountry?: InputMaybe<Scalars['String']>
  knownTravelerNumber?: InputMaybe<Scalars['String']>
  redressNumber?: InputMaybe<Scalars['String']>
  redressNumberIssuingCountry?: InputMaybe<Scalars['String']>
}

export enum AirProvider {
  Amadeus = 'AMADEUS',
  Apollo = 'APOLLO',
  Galileo = 'GALILEO',
  Ita = 'ITA',
  Sabre = 'SABRE',
  SouthwestBusiness = 'SOUTHWEST_BUSINESS',
  Travelfusion = 'TRAVELFUSION',
  Travelport = 'TRAVELPORT',
  Unspecified = 'UNSPECIFIED',
  Worldspan = 'WORLDSPAN',
}

export type AirTicket = {
  baseFare?: Maybe<Rate>
  endorsement?: Maybe<Array<Scalars['String']>>
  fareLinear?: Maybe<Scalars['String']>
  fareTaxInfo?: Maybe<Array<FareTaxItemDto>>
  formOfPayment?: Maybe<Scalars['String']>
  iataNumber?: Maybe<Scalars['String']>
  isExchangeForRefundableAllowed?: Maybe<Scalars['Boolean']>
  isPenaltyPayableFromResidual?: Maybe<Scalars['Boolean']>
  isPenaltyValidated?: Maybe<Scalars['Boolean']>
  isTicketRefundable?: Maybe<Scalars['Boolean']>
  issuingAgent?: Maybe<Scalars['String']>
  issuingAirline?: Maybe<Scalars['String']>
  penalty?: Maybe<Rate>
  ticketNumber?: Maybe<Scalars['String']>
  ticketSegmentInfo?: Maybe<Array<TicketSegment>>
  totalFare?: Maybe<Rate>
  travelerName?: Maybe<Scalars['String']>
}

export type AirTravelPreference = CommonTravelPreference & {
  /** Home airport IATA code picked by user */
  homeAirport?: Maybe<Scalars['String']>
  /** Full text label with the name of the airport for home airport code picked by user */
  homeAirportLabel?: Maybe<Scalars['String']>
  knownTravelerIssuingCountry?: Maybe<Scalars['String']>
  knownTravelerNumber?: Maybe<Scalars['String']>
  loyaltyProgram: Array<LoyaltyProgram>
  /** Preferable meal type picked by user. Object contains meal code and label. */
  meal?: Maybe<PreferenceOptions>
  /** Options available for meal preferences to choose from. It is array of objects, each containing request code and label with text description. */
  mealOptions?: Maybe<Array<PreferenceOptions>>
  noteToArranger?: Maybe<Scalars['String']>
  redressNumber?: Maybe<Scalars['String']>
  redressNumberIssuingCountry?: Maybe<Scalars['String']>
  resultsView?: Maybe<Scalars['String']>
  searchBy?: Maybe<Scalars['String']>
  seatPreference?: Maybe<Scalars['String']>
  /** Special requests selected by a user for the specific travel type - air, hotel, car rental, rail. This may contain, for example, accessibility requests or a request for a non-smoking room. */
  specialRequest: Array<PreferenceOptions>
  /** Options available for special requests to choose from. It is array of objects, each containing special request code and label with text description. */
  specialRequestOptions?: Maybe<Array<PreferenceOptions>>
}

export type AirTravelPreferenceInput = {
  airMembership?: InputMaybe<Array<GazooMembership>>
  airSpecialRequest?: InputMaybe<Array<Scalars['String']>>
  homeAirport?: InputMaybe<Scalars['String']>
  knownTravelerIssuingCountry?: InputMaybe<Scalars['String']>
  knownTravelerNumber?: InputMaybe<Scalars['String']>
  mealPreference?: InputMaybe<Scalars['String']>
  noteToArranger?: InputMaybe<Scalars['String']>
  redressNumber?: InputMaybe<Scalars['String']>
  redressNumberIssuingCountry?: InputMaybe<Scalars['String']>
  resultsView?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  seatPreference?: InputMaybe<Scalars['String']>
}

/** Flight preferences */
export type AirTravelPreferenceInputDto = {
  homeAirport?: InputMaybe<Scalars['String']>
  knownTravelerIssuingCountry?: InputMaybe<Scalars['String']>
  knownTravelerNumber?: InputMaybe<Scalars['String']>
  /** Loyalty program preferences */
  loyaltyProgram: Array<LoyaltyProgramInput>
  meal?: InputMaybe<PreferenceOptionsInput>
  noteToArranger?: InputMaybe<Scalars['String']>
  redressNumber?: InputMaybe<Scalars['String']>
  redressNumberIssuingCountry?: InputMaybe<Scalars['String']>
  resultsView?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  seatPreference?: InputMaybe<Scalars['String']>
  /** Special requests for all trave preferences */
  specialRequest: Array<PreferenceOptionsInput>
}

export type AircraftData = {
  code?: Maybe<Scalars['String']>
  onTime?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

/** Details for an airline. */
export type AirlineDetails = {
  /** IATA airline code of marketing airline. */
  airlineCode: Scalars['String']
  /** The proper name of the airline. */
  airlineName: Scalars['String']
  /** Airline code image. */
  imageUrl: Scalars['String']
}

export type AirlineFilter = {
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  value: Scalars['String']
}

/** Specifies the attributes for airlines that are preferred for the user. */
export type AirlinePreference = {
  /** IATA airline code to which the preference applies. */
  airlineCode: Scalars['String']
  /** Background color to use for preferred badge. */
  customBadgeBackgroundColor?: Maybe<Scalars['String']>
  /** Localized text to use to highlight a preferred airline. */
  customBadgeName?: Maybe<Scalars['String']>
  /** The tooltip text to be shown if specified. */
  customTooltipText?: Maybe<Scalars['String']>
  /** Specifies if this airline should be highlighted. */
  highlight: Scalars['Boolean']
  /** Airline preference identifier. */
  id: Scalars['ID']
  /** Name of preference owner. */
  preferenceOwnerName?: Maybe<Scalars['String']>
  /** Type of preference owner. */
  preferenceOwnerType: PreferenceOwnerType
  /** A signed integer value used for sorting by airline preference. */
  preferenceScore: Scalars['Float']
  /** Remove the airline logo if true. */
  removeLogo: Scalars['Boolean']
}

export type AirportAddress = {
  address?: Maybe<Address>
  airportCity?: Maybe<Scalars['String']>
  airportCode?: Maybe<Scalars['String']>
  airportName?: Maybe<Scalars['String']>
}

export type AirportDetails = {
  geocode?: Maybe<Geocode>
}

export type AirportFilter = {
  destination: AirportFilterAirport
  id: Scalars['ID']
  origin: AirportFilterAirport
}

export type AirportFilterAirport = {
  city?: Maybe<Scalars['String']>
  code: Scalars['AirportCode']
  name?: Maybe<Scalars['String']>
}

/** Airport information relevant for display. */
export type AirportInfo = {
  /** Administrative area associated with airport, if any. */
  administrativeArea?: Maybe<Scalars['String']>
  /** IATA airport code. */
  airportCode: Scalars['String']
  /** Airport latitude/longitude. */
  airportLatLng: Geocode
  /** Display name of airport. */
  airportName: Scalars['String']
  /** Name of city associated with airport. */
  cityName: Scalars['String']
  /** ISO 3166-1 alpha-2 country code associated with airport. */
  countryCode: Scalars['String']
}

export type AirportPickUpLocation = {
  flight: AirportPickUpLocationFlight
  geocode: Geocode
}

export type AirportPickUpLocationFlight = {
  airportCode: Scalars['String']
  airportName: Scalars['String']
  carrierCode: Scalars['String']
  dateTime: Scalars['String']
  flightNumber: Scalars['Int']
  isCommercial: Scalars['Boolean']
}

/** Alerts list item */
export type AlertItem = {
  /** Document id of alert, can be used for all kind of alerts. */
  docId?: Maybe<Scalars['Float']>
  /** It is value that refers to the specific type of alert being generated. It helps to handle each alert individually. */
  kind: Scalars['String']
  /** Message of the Alert */
  message: Scalars['String']
  /** Title of the Alert that is displayed at the top of the Alerts widget */
  title: Scalars['String']
}

/** Contains list of alerts and total alerts counter */
export type Alerts = {
  /** Dictionary of individual Alerts */
  alerts: Array<AlertItem>
  /** The number of alerts raised for a specific customer */
  totalAlerts: Scalars['Int']
}

export type AlertsInput = {
  pageSize?: InputMaybe<Scalars['Float']>
}

export enum AlignState {
  Aligned = 'ALIGNED',
  NotApplicable = 'NOT_APPLICABLE',
  OutOfBounds = 'OUT_OF_BOUNDS',
  WithinBounds = 'WITHIN_BOUNDS',
}

/** Supports the alignment of dates for car rental and hotel reservations, in the event of any change to a flight reservation. */
export type AlignTripInput = {
  /** A travel domain, for example Hotel, associated with the flight reservation that was changed. At least one domain must be specified. */
  alignSegmentTypes: Array<AlignTripTravelVertical>
  /** The arrival date for the flight reservation */
  endDate: Scalars['String']
  /** Itinerary associated with a trip that requires segment alignment. */
  itineraryId: Scalars['String']
  /** The departure date of the flight reservation */
  startDate: Scalars['String']
}

/** The result from alignTrip mutation, This is mapping data from the Gazoo's align-trip API. */
export type AlignTripResult = {
  /** Updated itinerary details following alignment of other segments with changes to a flight reservation. It is used for displaying purpose and the structure is almost the same with the getItinerary (except the DSM). */
  itinerary: Itinerary
  /** The metadata provides information about the aligned itinerary, indicating whether a match was found or if there was no exact match with the search parameters. This information is useful for clients who may need to initiate a new search. */
  itineraryMetadata: ItineraryMetadata
}

/** Supports the alignment of dates for car rental, hotel, and flight reservations. */
export enum AlignTripTravelVertical {
  Air = 'AIR',
  Carrental = 'CARRENTAL',
  Hotel = 'HOTEL',
}

export enum AllowedCharacters {
  Alpha = 'ALPHA',
  Numeric = 'NUMERIC',
  Special = 'SPECIAL',
}

export type Amenity = {
  isOfProperty: Scalars['Boolean']
  isOfRoom: Scalars['Boolean']
  name: Scalars['String']
}

/** Filter by hotel amenity */
export type AmenityFilter = {
  amenityDisplayName: Scalars['String']
  amenityId: Scalars['String']
  hotelCount: Scalars['Int']
}

/** Contains the approver Ids for a user for the different app services within Deem@Work */
export type Approvers = {
  /** The id of the user in our system that has permission for expense approvals on behalf of the request user */
  expenseApproverId?: Maybe<Scalars['String']>
  /** The id of the user in our system that has permission for purchase approvals on behalf of the request user */
  purchaseApproverId?: Maybe<Scalars['String']>
  /** The id of the user in our system that has permission for travel approvals on behalf of the request user */
  travelApproverId?: Maybe<Scalars['String']>
}

/** Contains the approver Ids input for a user for the different app services within Deem@Work */
export type ApproversInput = {
  /** The id of the user in our system that has permission for expense approvals on behalf of the request user */
  expenseApproverId?: InputMaybe<Scalars['String']>
  /** The id of the user in our system that has permission for purchase approvals on behalf of the request user */
  purchaseApproverId?: InputMaybe<Scalars['String']>
  /** The id of the user in our system that has permission for travel approvals on behalf of the request user */
  travelApproverId?: InputMaybe<Scalars['String']>
}

/** Contains the authorization settings input for a user */
export type Authorization = {
  /** Indicates what type of account the user has (see enum description), default to END_USER */
  accountType?: InputMaybe<Scalars['String']>
  /** If true, the user has one or more authorizers and the delegate drop-down will be shown. Only applicable for enterprise users */
  delegate?: InputMaybe<Scalars['Boolean']>
  /** A boolean that will allow the user to be dynamically assigned into a named group, currently used only for meeting-specific group overrides */
  dynamicGroup?: InputMaybe<Scalars['Boolean']>
  /** Is this user a guest user (default=false)? Guest users typically have reduced application functionality */
  guest?: InputMaybe<Scalars['Boolean']>
  /** If set, this user is a shadow user and their primary profile should be used to retrieve most profile information. This field points to the #core.userId of primary user, and note that this "shadow user" concept is deprecated and will not be used in the future */
  primaryProfile?: InputMaybe<Scalars['Float']>
  /** Indicates the state of user registration (see enum descriptions), defaults to NOT_STARTED */
  registration?: InputMaybe<Scalars['String']>
  /** The set of ACL roles that have been assigned to the user, updated with standard collection updates */
  roles?: InputMaybe<Array<RoleName>>
}

/** Authorizer input details. */
export type AuthorizersDetailsInput = {
  /** Authorizer email */
  email?: InputMaybe<Scalars['String']>
  /** Authorizer first name */
  firstName?: InputMaybe<Scalars['String']>
  /** Authorizer id */
  id: Scalars['Float']
  /** Authorizer last name */
  lastName?: InputMaybe<Scalars['String']>
}

export type AvailableDelegates = {
  email: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['Float']
  /** If true it marks a delegate; if false it marks authorizer */
  isDelegate: Scalars['Boolean']
  lastName: Scalars['String']
}

export type AvailablePreferences = {
  airMemberships?: Maybe<Array<PreferenceOptions>>
  airSpecialRequests?: Maybe<Array<PreferenceOptions>>
  carRentalMemberships?: Maybe<Array<PreferenceOptions>>
  carRentalSpecialRequests?: Maybe<Array<PreferenceOptions>>
  hotelMemberships?: Maybe<Array<PreferenceOptions>>
  hotelSpecialRequests?: Maybe<Array<PreferenceOptions>>
  meals?: Maybe<Array<PreferenceOptions>>
}

export type AvailableSegments = {
  allowSearchCarRental: Scalars['Boolean']
  allowSearchFlight: Scalars['Boolean']
  allowSearchHotel: Scalars['Boolean']
  allowSearchRail: Scalars['Boolean']
  canAddCarRental: Scalars['Boolean']
  canAddFlight: Scalars['Boolean']
  canAddHotel: Scalars['Boolean']
  canAddRail: Scalars['Boolean']
}

/** Lists of the available air, hotel and car rental vendors */
export type AvailableVendors = {
  /** List of the available air vendors */
  availableAirVendors?: Maybe<Array<PreferenceOptions>>
  /** List of the available car rental vendors */
  availableCarRentalVendors?: Maybe<Array<PreferenceOptions>>
  /** List of the available hotel vendors */
  availableHotelVendors?: Maybe<Array<PreferenceOptions>>
}

/** Input object with travel vertical parameter to request available vendors for this vertical */
export type AvailableVendorsInput = {
  /** Gazoo travel vertical name, used to configure request endpoint. Supported travel vertical strings - AIR, HOTEL, CARRENTAL */
  travelVertical: VendorTravelVertical
}

export type BaggageAllowance = {
  lower: BaggageAllowanceValue
  upper: BaggageAllowanceValue
}

export type BaggageAllowanceCount = {
  lower: Scalars['Int']
  upper: Scalars['Int']
}

export type BaggageAllowanceValue = {
  unit: BaggageUnit
  value: Scalars['Int']
}

/** Permitted range for baggage count with the specified price. */
export type BaggageCount = {
  /** Fee for the defined baggage count. */
  amount: Rate
  /** Lower value of the baggage count. */
  lowerValue: Scalars['Float']
  /** Upper value of the baggage count. */
  upperValue: Scalars['Float']
}

/** Baggage fee(s) rules imposed by airlines. */
export type BaggageFeeRules = {
  /** Collection of baggage fee rules for the specified airline e.g. NUMBER_OF_BAGS, WEIGHT or Size. */
  baggageItem?: Maybe<BaggageItem>
  /** Url to a web page with baggage fee rules. */
  vendorUrl?: Maybe<Scalars['String']>
}

export type BaggageFees = {
  carrierUrl?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<FlightBaggageFee>>>
}

/** Collection of baggage fee rules for the specified airline e.g. NUMBER_OF_BAGS, WEIGHT or Size. */
export type BaggageItem = {
  /** Count of baggage items. */
  baggageCount?: Maybe<Array<BaggageCount>>
  /** Size of the baggage. */
  baggageSize?: Maybe<Array<BaggageSize>>
  /** Weight of the baggage. */
  baggageWeight?: Maybe<Array<BaggageWeight>>
}

/** Permitted range for baggage size with the specified price. */
export type BaggageSize = {
  /** Fee for the defined baggage size. */
  amount: Rate
  /** Lower value of the baggage size(the sum of three dimensions). */
  lowerValue: Scalars['Float']
  /** Measurement system for the baggage dimensions. e.g. METRIC(Size value:the sum of three dimensions, in centimeter)) or IMPERIAL(Size value:the sum of three dimensions, in inches). */
  measurementSystem: MeasurementSystem
  /** Upper value of the baggage size( the sum of three dimensions). */
  upperValue: Scalars['Float']
}

export enum BaggageType {
  NumberOfBags = 'NUMBER_OF_BAGS',
  Size = 'SIZE',
  Weight = 'WEIGHT',
}

export enum BaggageUnit {
  Inch = 'INCH',
  Pound = 'POUND',
}

/** Permitted range for baggage weight with the specified price. */
export type BaggageWeight = {
  /** Fee for the defined weight. */
  amount: Rate
  /** Lower value of the baggage weight. */
  lowerValue: Scalars['Float']
  /** Measurement system for the baggage weight. e.g. METRIC[Weight values (lower_value, upper_value) will be in kilograms] or IMPERIAL[Weight values (lower_value, upper_value) will be in pounds]. */
  measurementSystem: MeasurementSystem
  /** Upper value of the baggage weight. */
  upperValue: Scalars['Float']
}

export type BaseResponse = CommonResponse & {
  code: Scalars['String']
  errorCodes?: Maybe<Array<Scalars['String']>>
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type BillingInfo = {
  id?: Maybe<Scalars['String']>
  payment?: Maybe<Payment>
}

export type BillingInfoInputDto = {
  creditCard?: InputMaybe<CreditCardInputDto>
  siteCard?: InputMaybe<SiteCardInputDto>
}

export type BillingInputDto = {
  air?: InputMaybe<BillingInfoInputDto>
  carRental?: InputMaybe<BillingInfoInputDto>
  hotel?: InputMaybe<BillingInfoInputDto>
  rail?: InputMaybe<BillingInfoInputDto>
}

export type BillingMetadata = {
  /** Indicates if billing address is required. */
  isBillingAddressRequired: Scalars['Boolean']
  /** Indicates if CVV is required. */
  isCvvRequired: Scalars['Boolean']
  /** Indicates if payment is optional. */
  optional: Scalars['Boolean']
}

export type BillingParameter = {
  creditCards: Array<CreditCard>
  defaultCardId?: Maybe<Scalars['ID']>
  metadata: BillingParameterMetadata
  travelVertical: TravelVertical
}

export type BillingParameterMetadata = {
  isBillingAddressRequired: Scalars['Boolean']
  isCvvRequired: Scalars['Boolean']
  isSingleUseCreditCardAllowed: Scalars['Boolean']
}

/** Supports the alignment of dates for car rental and hotel reservations, in the event of any change to a flight reservation. */
export type BookAgainTripInput = {
  /** A travel domain, for example Hotel, associated with the flight reservation that was changed. At least one domain must be specified. */
  alignSegmentTypes: Array<TravelVertical>
  /** The list of custom fields for the trip */
  customFields?: InputMaybe<Array<CustomFieldBooking>>
  /** The arrival date for the flight reservation */
  endDate: Scalars['String']
  /** Itinerary associated with a trip that requires segment alignment. */
  processId: Scalars['String']
  /** The departure date of the flight reservation */
  startDate: Scalars['String']
}

/** The result from bookAgainTrip mutation, This is mapping data from the Gazoo's book-again-trip API. */
export type BookAgainTripResult = {
  /** Updated itinerary details following alignment of other segments with changes to a flight reservation. It is used for displaying purpose and the structure is almost the same with the getItinerary (except the DSM). */
  itinerary: Itinerary
  /** The metadata provides information about the aligned itinerary, indicating whether a match was found or if there was no exact match with the search parameters. This information is useful for clients who may need to initiate a new search. */
  itineraryMetadata: ItineraryMetadata
}

export type BookItineraryCreditCards = {
  AIR?: InputMaybe<Scalars['ID']>
  CARRENTAL?: InputMaybe<Scalars['ID']>
  HOTEL?: InputMaybe<Scalars['ID']>
  RAIL?: InputMaybe<Scalars['ID']>
}

export type BookItineraryInput = {
  bookingId?: InputMaybe<Scalars['String']>
  costAllocation?: InputMaybe<Array<CostAllocationSubmit>>
  /** This field is deprecated: Use payment instead */
  creditCards?: InputMaybe<BookItineraryCreditCards>
  customFields?: InputMaybe<Array<CustomFieldBooking>>
  departureLuggageOption?: InputMaybe<LuggageOptionInput>
  deviceClass?: InputMaybe<DeviceClass>
  /** When guestTraveler is available, it will be used instead of the profile information */
  guestTraveler?: InputMaybe<GuestTravelerInput>
  /** This field is deprecated: Use payment.HOTEL */
  isVirtualPaymentSelected?: InputMaybe<Scalars['Boolean']>
  itineraryId: Scalars['String']
  oopExplanation?: InputMaybe<Scalars['String']>
  oopSegments?: InputMaybe<OopBySegment>
  outOfPolicyPerItem?: InputMaybe<OutOfPolicyPerItem>
  passportId?: InputMaybe<Scalars['Float']>
  payment: BookItineraryPayment
  profileDataSource?: InputMaybe<ProfileDataSource>
  /** This field indicates if the request should put the trip on hold. */
  putOnHold?: InputMaybe<Scalars['Boolean']>
  returnLuggageOption?: InputMaybe<LuggageOptionInput>
  shareTravelerContact?: InputMaybe<ShareTravelerContactType>
  /** This field is deprecated: Put single use card into payment.segment.singleUseCreditCard */
  singleUseCreditCard?: InputMaybe<CreditCardInput>
  /** If this field is provided the request will use these travel preferences instead of the ones saved in the users profile */
  travelPreferences?: InputMaybe<TravelPreferencesInput>
  traveler?: InputMaybe<TravelerInputDto>
}

export type BookItineraryPayment = {
  AIR?: InputMaybe<PaymentDetails>
  CARRENTAL?: InputMaybe<PaymentDetails>
  HOTEL?: InputMaybe<PaymentDetails>
  RAIL?: InputMaybe<PaymentDetails>
}

/** Defines error reasons of book ride hail failures */
export enum BookRideHailErrorReason {
  /** Selected product is currently unavailable with apple pay. */
  ApplePayNotSupported = 'APPLE_PAY_NOT_SUPPORTED',
  /** A ride hail already exists for the selected time. */
  BookingExistedForSelectedTime = 'BOOKING_EXISTED_FOR_SELECTED_TIME',
  /** Provided expense code exceeds character length limit. */
  ExpenseCodeMaxLengthExceeded = 'EXPENSE_CODE_MAX_LENGTH_EXCEEDED',
  /** Customer's selected ride is out of organisation's policy. */
  OutOfPolicy = 'OUT_OF_POLICY',
  /** Invalid Payment Error. Chosen payment method is invalid. */
  PaymentMethodInvalid = 'PAYMENT_METHOD_INVALID',
  /** Provided pick-up time is invalid. */
  PickupTimeInvalid = 'PICKUP_TIME_INVALID',
  /** Provided search result ID was expired, e.g. traveler waiting too long between searching and booking. */
  SearchResultIdExpired = 'SEARCH_RESULT_ID_EXPIRED',
  /** Provided search result ID was invalid for an unknown reason, e.g. ID is gibberish, doesn't exist, etc. */
  SearchResultIdInvalid = 'SEARCH_RESULT_ID_INVALID',
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** BookRideHailErrorResponse is returned from book ride hail request with a well-defined reason */
export type BookRideHailErrorResponse = {
  /** Error reason */
  reason: BookRideHailErrorReason
}

export type BookRideHailRequest = {
  /** Info of an arrival flight, if provided it will be used to support the reserve airport pick up */
  arrivalFlightDetails?: InputMaybe<RideHailFlightDetails>
  /** Drop-off location */
  dropOffLocation: RideHailLocation
  /** Drop-off segment type associated with process ID */
  dropOffRideHailSegmentType?: InputMaybe<RideHailSegmentType>
  /** This field is deprecated: Use dropOffSegmentId instead */
  dropOffSegmentID?: InputMaybe<Scalars['Int']>
  /** Drop-off segment id associated with process ID */
  dropOffSegmentId?: InputMaybe<Scalars['ID']>
  /** This field is deprecated: Use dropOffRideHailSegmentType instead */
  dropOffSegmentType?: InputMaybe<Scalars['ID']>
  /** Expense code id. This value comes from the GetExpenseConfig response */
  expenseCodeID?: InputMaybe<Scalars['ID']>
  /** Expense memo. To provide additional information about the selected expense code */
  expenseMemo?: InputMaybe<Scalars['String']>
  /** User's desired pick-up date. If not provided, ride will be assumed to be on-demand */
  pickUpDate?: InputMaybe<Scalars['Date']>
  /** Pick-up location */
  pickUpLocation: RideHailLocation
  /** Pick-up segment type associated with process ID */
  pickUpRideHailSegmentType?: InputMaybe<RideHailSegmentType>
  /** This field is deprecated: Use pickUpSegmentId instead */
  pickUpSegmentID?: InputMaybe<Scalars['Int']>
  /** Pick-up segment id associated with process ID */
  pickUpSegmentId?: InputMaybe<Scalars['ID']>
  /** This field is deprecated: Use pickUpRideHailSegmentType instead */
  pickUpSegmentType?: InputMaybe<Scalars['ID']>
  /** User's desired pick-up time. If not provided, ride will be assumed to be on-demand */
  pickUpTime?: InputMaybe<Scalars['TimeOfDay']>
  /** Process ID of the associated travel transaction (from legacy travel/Gazoo) */
  processID?: InputMaybe<Scalars['ID']>
  /** Provider name, e.g. Uber, Lyft, etc */
  provider: RideHailProvider
  /** The unique ID of the product being requested which will be determined by provider name at the RHSS */
  searchResultID: Scalars['ID']
}

/** BookRideHailResponse is returned when the bookRideHail query succeeds */
export type BookRideHailResponse = {
  /**
   * The unique ID of the trip request
   * @deprecated Use bookingInfo.bookingId instead.
   */
  bookingID: Scalars['String']
  /** Information for the booked ride hail e.g. booking ID, pick up address, drop off address, status and etc */
  bookingInfo?: Maybe<RideHailBooking>
}

/** BookRideHailResponseUnion is either the success or failure book ride hail response */
export type BookRideHailResponseUnion =
  | BookRideHailErrorResponse
  | BookRideHailResponse
  | UnexpectedError

export type BrandConfiguration = {
  /** Partner custom label that is used in the browser tab. Fiji pages only. */
  brandTitleLabel?: Maybe<Scalars['String']>
  /** Custom company custom logo, configured in the Gazoo console */
  customCompanyLogoUrl?: Maybe<Scalars['String']>
  /** Custom company custom logo for mobile, configured in the Gazoo console */
  customMobileCompanyLogoUrl?: Maybe<Scalars['String']>
  /** Custom partner's custom logo for mobile, configured in the Gazoo console */
  customMobilePartnerLogoUrl?: Maybe<Scalars['String']>
  /** Custom partner's custom logo, configured in the Gazoo console */
  customPartnerLogoUrl?: Maybe<Scalars['String']>
  isThemeEnabled: Scalars['Boolean']
  partnerLogoUrl?: Maybe<Scalars['String']>
  /** Terms of use label, configured in the Gazoo console */
  termsOfUseLabel?: Maybe<Scalars['String']>
  /** Terms of use link enabled, configured in the Gazoo console */
  termsOfUseLinkEnabled: Scalars['Boolean']
  /** Terms of use link url, configured in the Gazoo console */
  termsOfUseLinkUrl?: Maybe<Scalars['String']>
  theme: Scalars['String']
}

/** Filter by hotel brand */
export type BrandFilter = {
  brandDisplayName: Scalars['String']
  brandId: Scalars['String']
  hotelCount: Scalars['Int']
}

export type BusinessContact = {
  address?: Maybe<PersonalContactAddress>
  /** Fax phone number object related to business address */
  faxPhone?: Maybe<PersonalContactPhone>
  mobilePhone?: Maybe<PersonalContactPhone>
  officePhone?: Maybe<PersonalContactPhone>
}

/** Defines the different type of cabin class that can exist in the seat attributes. */
export enum CabinCodeEnum {
  Business = 'BUSINESS',
  Economy = 'ECONOMY',
  First = 'FIRST',
  PremiumEconomy = 'PREMIUM_ECONOMY',
}

/** Explains the flight cabin classes. */
export enum CabinType {
  BusinessClass = 'BUSINESS_CLASS',
  EconomyClass = 'ECONOMY_CLASS',
  FirstClass = 'FIRST_CLASS',
  PremiumEconomyClass = 'PREMIUM_ECONOMY_CLASS',
}

/** Defines car segment for which emissions are to be calculated. */
export type CalculateCarEmissionsBatchCarInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Number of days travelled. */
  days: Scalars['Float']
  /** Destination location information. */
  destination: GeocodeInput
  /** Origin location information. */
  origin: GeocodeInput
  /** Pick-up datetime. Format: ISO string. Example: "2022-12-29T11:00:00.000Z". */
  pickUpDateTime: Scalars['String']
  /** Car SIPP code (4 letters code). Example: "IDAR". */
  vehicleCode: Scalars['String']
}

/** Defines the mandatory fields required for calculation of the average emissions for days travelled. */
export type CalculateCarEmissionsBatchCompareInput = {
  /** Number of days travelled. */
  days: Scalars['Float']
  /** Destination location information. */
  destination: GeocodeInput
  /** Origin location information. */
  origin: GeocodeInput
}

/** Provides the input parameters for the calculateCarEmissionsBatch query. */
export type CalculateCarEmissionsBatchInput = {
  /** Array of car segments, that sustainability information needs to be calculated. */
  cars: Array<CalculateCarEmissionsBatchCarInput>
  /** Defines the mandatory fields required for calculation of the average emissions for days travelled. */
  compare: Array<CalculateCarEmissionsBatchCompareInput>
}

/** CalculateCarEmissionsBatchResult is returned from calculate car emissions batch request. */
export type CalculateCarEmissionsBatchResult = {
  /** Average emission tonnes for days of travel with the calculated car. */
  averageEmissionsTonnes?: Maybe<Scalars['Float']>
  /** Array of cars sustainability information. */
  cars?: Maybe<Array<CalculateCarEmissionsBatchResultCar>>
  /** List of errors the provider returns. */
  errors?: Maybe<Array<CalculateEmissionsResultError>>
}

/** CalculateCarEmissionsBatchResultCar is returned from calculate car emissions batch request. */
export type CalculateCarEmissionsBatchResultCar = {
  /** Custom unique string identifier that helps to tie this item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Calculate trip emissions result error type. */
export enum CalculateEmissionsBatchError {
  /** Provider error. */
  ProviderError = 'ProviderError',
  /** Response mapping error. */
  ResponseMappingError = 'ResponseMappingError',
}

/** CalculateEmissionsBatchResultEquivalence reprents emissions equivalence in a more user friendly way. */
export type CalculateEmissionsBatchResultEquivalence = {
  /** Emissions equivalence amount. */
  amount: Scalars['Float']
  /** Emissions equivalence type. */
  type: CalculateEmissionsBatchResultEquivalenceType
  /** The original name of equivalence type. Example: "plastic bags". */
  unit: Scalars['String']
}

/** Emmissions equivalence type. */
export enum CalculateEmissionsBatchResultEquivalenceType {
  /** Plastic bag equivalence type. */
  PlasticBag = 'PLASTIC_BAG',
  /** Tree seedling equivalence type. */
  Seedling = 'SEEDLING',
  /** Smartphone charge equivalence type. */
  SmartphoneCharge = 'SMARTPHONE_CHARGE',
}

/** Represents flight cabin class type. */
export enum CalculateEmissionsFlightSegmentTravelClass {
  /** Business cabin class. */
  Business = 'Business',
  /** Economy cabin class. */
  Economy = 'Economy',
  /** First cabin class. */
  First = 'First',
  /** Premium cabin class. */
  Premium = 'Premium',
}

/** Rail cabin class. */
export enum CalculateEmissionsRailSegmentTravelClassDto {
  /** Business cabin class. */
  Business = 'Business',
  /** Economy cabin class. */
  Economy = 'Economy',
  /** First cabin class. */
  First = 'First',
}

/** Calculate emissions result error. */
export enum CalculateEmissionsResultError {
  /** Unknown error. */
  Unknown = 'Unknown',
}

/** Hotel sustainability certification. */
export type CalculateEmissionsResultHotelCertification = {
  /** Indicates whether the scheme was approved by the Global Sustainable Tourism Council (GSTC). */
  isGSTCApproved: Scalars['Boolean']
  /** Certification name */
  name: Scalars['String']
  /** Hotel certification type. */
  type: CalculateEmissionsResultHotelCertificationType
}

/** Hotel certification type. */
export enum CalculateEmissionsResultHotelCertificationType {
  /** Building certification. */
  BuildingCertification = 'BuildingCertification',
  /** Hotel certification. */
  HotelCertification = 'HotelCertification',
}

/** Defines the mandatory fields required for calculation of the average sustainability information for a specific route. */
export type CalculateFlightEmissionsBatchCompareInput = {
  /** A three-letter IATA code of the destination airport. Example: "LAX". */
  destinationAirportCode: Scalars['String']
  /** A three-letter IATA code of the origin airport. For example: "SFO". */
  originAirportCode: Scalars['String']
}

/** Defines flight segment that needs to be calculated. */
export type CalculateFlightEmissionsBatchFlightInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** List of a specific flight route segments. */
  segments: Array<CalculateFlightEmissionsBatchFlightSegmentInput>
}

/** Defines specific flight segment information. */
export type CalculateFlightEmissionsBatchFlightSegmentInput = {
  /** IATA aircraft type. Example: "788". */
  aircraftType?: InputMaybe<Scalars['String']>
  /** 2 letter IATA carrier code. Example: "AA". */
  carrier?: InputMaybe<Scalars['String']>
  /** Flight cabin class type. */
  class: CalculateEmissionsFlightSegmentTravelClass
  /** Departure datetime. Format: ISO string. Example: "2022-12-29T11:00:00.000Z". */
  departureDateTime: Scalars['String']
  /** A three-letter IATA code of the destination airport. Example, "LAX". */
  destinationAirportCode: Scalars['String']
  /** Aircraft number. */
  flightNumber: Scalars['Float']
  /** A three-letter IATA code of the origin airport. Example: "SFO". */
  originAirportCode: Scalars['String']
}

/** Provides the input parameters for the calculateFlightEmissionsBatch query. */
export type CalculateFlightEmissionsBatchInput = {
  /** Defines the mandatory fields required for calculation of the average sustainability information for a specific route. */
  compare: Array<CalculateFlightEmissionsBatchCompareInput>
  /** Array of flight segments, that sustainability information needs to be calculated. */
  flights: Array<CalculateFlightEmissionsBatchFlightInput>
}

/** CalculateFlightEmissionsBatchResult returns when the calculateFlightEmissionsBatch query has been executed. */
export type CalculateFlightEmissionsBatchResult = {
  /** Average emission tonnes for a specific route. */
  averageEmissionsTonnes: Scalars['Float']
  /** List of errors the provider returns. */
  errors?: Maybe<Array<CalculateEmissionsResultError>>
  /** Array of flight sustainability information. */
  flights?: Maybe<Array<CalculateFlightEmissionsBatchResultFlight>>
}

/** CalculateFlightEmissionsBatchResultFlight returns all flight segments with emission data. */
export type CalculateFlightEmissionsBatchResultFlight = {
  /** Custom unique string identifier that helps to tie this response item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Defines the mandatory fields required for calculation of the average emissions for days of stay. */
export type CalculateHotelEmissionsBatchCompareInput = {
  /** Hotel property location information. */
  location: GeocodeInput
  /** Number of nights to be spent in the hotel. */
  nights: Scalars['Float']
}

/** Defines the hotel segment to be assessed for emissions calculation. */
export type CalculateHotelEmissionsBatchHotelInput = {
  /** Check-in date. Format: YYYY-MM-DD. */
  checkIn: Scalars['String']
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Hotel property GIATA identifier. */
  hotelId: Scalars['String']
  /** Hotel property location. */
  location: GeocodeInput
  /** Hotel name. */
  name: Scalars['String']
  /** Number of nights to be spent in the hotel. */
  nights: Scalars['Float']
  /** Hotel star rating. Format: integer 1-5. */
  rating: Scalars['Float']
}

/** Provides the input parameters for the calculateHotelEmissionsBatch query. */
export type CalculateHotelEmissionsBatchInput = {
  /** Defines the mandatory fields required for calculation of the average sustainability information for days of stay. */
  compare: Array<CalculateHotelEmissionsBatchCompareInput>
  /** Array of hotel segments, that sustainability information needs to be calculated. */
  hotels: Array<CalculateHotelEmissionsBatchHotelInput>
}

/** CalculateHotelEmissionsBatchResult returns when the calculateHotelEmissionsBatch query has been executed. */
export type CalculateHotelEmissionsBatchResult = {
  /** Average emission tonnes for days of stay in the calculated location. */
  averageEmissionsTonnes?: Maybe<Scalars['Float']>
  /** Average sustainability score for the calculated location. Format: integer 1-100. */
  averageSustainabilityScore?: Maybe<Scalars['Float']>
  /** List of errors the provider returns. */
  errors?: Maybe<Array<CalculateEmissionsResultError>>
  /** Array of hotels sustainability information. */
  hotels?: Maybe<Array<CalculateHotelEmissionsBatchResultHotel>>
}

/** Hotel emissions information. */
export type CalculateHotelEmissionsBatchResultHotel = {
  /** Hotel certifications list. */
  certifications: Array<CalculateEmissionsResultHotelCertification>
  /** Custom unique string identifier that helps to tie this item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Hotel sustainability score. Format: integer 1-100. */
  sustainabilityScore?: Maybe<Scalars['Float']>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Defines the mandatory fields required for calculation of the average sustainability information for a specific route. */
export type CalculateRailEmissionsBatchCompareInput = {
  /** Rail destination location information. */
  destination: CalculateRailEmissionsBatchLocationInput
  /** Rail origin location information. */
  origin: CalculateRailEmissionsBatchLocationInput
}

/** Provides the input parameters for the calculateRailEmissionsBatch query. */
export type CalculateRailEmissionsBatchInput = {
  /** Defines the mandatory fields required for calculation of the average sustainability information for a specific route. */
  compare: Array<CalculateRailEmissionsBatchCompareInput>
  /** Array of rail segments, that sustainability information needs to be calculated. */
  rails: Array<CalculateRailEmissionsBatchRailInput>
}

/** Rail location information. */
export type CalculateRailEmissionsBatchLocationInput = {
  /** Station code. Example: "LVC". */
  stationCode?: InputMaybe<Scalars['String']>
}

/** Defines rail segment that needs to calculate emissions. */
export type CalculateRailEmissionsBatchRailInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Rail segments information. */
  segments: Array<CalculateRailEmissionsBatchRailSegmentInput>
}

/** Content associated with the rail segment to be assessed for emissions calculation. */
export type CalculateRailEmissionsBatchRailSegmentInput = {
  /** 2 letter carrier code. Example: "GX". */
  carrierCode?: InputMaybe<Scalars['String']>
  /** Rail cabin class. */
  class: CalculateEmissionsRailSegmentTravelClassDto
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Departure date. Format: YYYY-MM-DD */
  departureDate: Scalars['String']
  /** Destination station code. Example: "LVC". */
  destinationStationCode: Scalars['String']
  /** Origin station code. Example: "LVC". */
  originStationCode: Scalars['String']
}

/** Calculate rail emissions batch result. */
export type CalculateRailEmissionsBatchResult = {
  /** Average emissions tonnes of a specific route. */
  averageEmissionsTonnes: Scalars['Float']
  /** List of errors the provider returns. */
  errors?: Maybe<Array<CalculateEmissionsResultError>>
  /** Array of rail sustainability information. */
  rails?: Maybe<Array<CalculateRailEmissionsBatchResultRail>>
}

export type CalculateRailEmissionsBatchResultRail = {
  /** Custom unique string identifier that helps to tie this segment with an item from the request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
  /** Rail travel class. */
  travelClass?: Maybe<CalculateEmissionsRailSegmentTravelClassDto>
}

export type CalculateTripEmissionsCarInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Number of days travelled. */
  days: Scalars['Float']
  /** Destination location information. */
  destination: GeocodeInput
  /** Origin location information. */
  origin: GeocodeInput
  /** Pick-up datetime. Format: ISO string. Example: "2022-12-29T11:00:00.000Z". */
  pickUpDateTime: Scalars['String']
  /** Car SIPP code (4 letters code). Example: "IDAR". */
  vehicleCode: Scalars['String']
}

/** Flight input. */
export type CalculateTripEmissionsFlightInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Specific flight information. */
  segments: Array<CalculateTripEmissionsFlightSegmentInput>
}

export type CalculateTripEmissionsFlightSegmentInput = {
  /** IATA aircraft type. Example: "788". */
  aircraftType?: InputMaybe<Scalars['String']>
  /** 2 letter IATA carrier code. Example: "AA". */
  carrier?: InputMaybe<Scalars['String']>
  /** Flight cabin class type. */
  class: CalculateEmissionsFlightSegmentTravelClass
  /** Departure datetime. Format: ISO string. Example: "2022-12-29T11:00:00.000Z". */
  departureDateTime: Scalars['String']
  /** A three-letter IATA code of the destination airport. Example, "LAX". */
  destinationAirportCode: Scalars['String']
  /** Aircraft number. */
  flightNumber: Scalars['Float']
  /** A three-letter IATA code of the origin airport. Example: "SFO". */
  originAirportCode: Scalars['String']
}

export type CalculateTripEmissionsHotelInput = {
  /** Check-in date. Format: YYYY-MM-DD. */
  checkIn: Scalars['String']
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Hotel property GIATA identifier. */
  hotelId: Scalars['String']
  /** Hotel property location. */
  location: GeocodeInput
  /** Hotel name. */
  name: Scalars['String']
  /** Number of nights to be spent in the hotel. */
  nights: Scalars['Float']
  /** Hotel star rating. Format: integer 1-5. */
  rating: Scalars['Float']
}

/** Calculate trip emissions request input. */
export type CalculateTripEmissionsInput = {
  /** Array of car segments for which emissions are to be calculated. */
  cars?: InputMaybe<Array<CalculateTripEmissionsCarInput>>
  /** Array of flight segments for which emissions are to be calculated */
  flights?: InputMaybe<Array<CalculateTripEmissionsFlightInput>>
  /** Array of hotel segments for which emissions are to be calculated */
  hotels?: InputMaybe<Array<CalculateTripEmissionsHotelInput>>
  /** Array of rail segments for which emissions are to be calculated */
  rails?: InputMaybe<Array<CalculateTripEmissionsRailInput>>
}

/** Calculate trip emissions rail input. */
export type CalculateTripEmissionsRailInput = {
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  segments: Array<CalculateTripEmissionsRailSegmentInput>
}

/** Defines rail segment that needs to calculate emissions. */
export type CalculateTripEmissionsRailSegmentInput = {
  /** 2 letter carrier code. Example: "GX". */
  carrierCode?: InputMaybe<Scalars['String']>
  /** Rail cabin class. */
  class: CalculateEmissionsRailSegmentTravelClassDto
  /** Custom unique string identifier that helps to tie this segment with an item from the response. */
  customRef?: InputMaybe<Scalars['String']>
  /** Departure date. Format: YYYY-MM-DD */
  departureDate: Scalars['String']
  /** Destination station code. Example: "LVC". */
  destinationStationCode: Scalars['String']
  /** Origin station code. Example: "LVC". */
  originStationCode: Scalars['String']
}

export type CalculateTripEmissionsResult = {
  /** Car emissions result. */
  cars?: Maybe<Array<CalculateTripEmissionsResultCar>>
  /** Array of error objects the provider returns. */
  errors?: Maybe<Array<CalculateTripEmissionsResultError>>
  /** Flight emissions result. */
  flights?: Maybe<Array<CalculateTripEmissionsResultFlight>>
  /** Hotel emissions result. */
  hotels?: Maybe<Array<CalculateTripEmissionsResultHotel>>
  /** Rail emissions result. */
  rails?: Maybe<Array<CalculateTripEmissionsResultRail>>
}

/** Emissions information of a specific car. */
export type CalculateTripEmissionsResultCar = {
  averageEmissionTonnes: Scalars['Float']
  /** Custom unique string identifier that helps to tie this item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Calculate trip emissions error. */
export type CalculateTripEmissionsResultError = {
  /** Error message. */
  message: Scalars['String']
  /** Error type. */
  type: CalculateEmissionsBatchError
}

/** Emissions information of a specific flight. */
export type CalculateTripEmissionsResultFlight = {
  /** Average route emission tonnes. */
  averageRouteEmissionTonnes: Scalars['Float']
  /** Custom unique string identifier that helps to tie this response item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Emissions information of a specific hotel. */
export type CalculateTripEmissionsResultHotel = {
  /** Average location emissions tonnes. */
  averageLocationEmissionsTonnes: Scalars['Float']
  /** Average location sustainability score. Format: integer 1-100. */
  averageLocationSustainabilityScore: Scalars['Float']
  /** Hotel certifications list. */
  certifications: Array<CalculateEmissionsResultHotelCertification>
  /** Custom unique string identifier that helps to tie this item with an item from request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Hotel sustainability score. Format: integer 1-100. */
  sustainabilityScore?: Maybe<Scalars['Float']>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
}

/** Rail emissions information. */
export type CalculateTripEmissionsResultRail = {
  /** Average emissions tonnes for specific calculated route. */
  averageRouteEmissionTonnes: Scalars['Float']
  /** Custom unique string identifier that helps to tie this segment with an item from the request. */
  customRef?: Maybe<Scalars['String']>
  /** Emissions equivalences presented in a more user friendly way. */
  equivalences: Array<CalculateEmissionsBatchResultEquivalence>
  /** Value of emissions, in tons. */
  tonnesOfEmissions: Scalars['Float']
  /** Rail travel class. */
  travelClass?: Maybe<CalculateEmissionsRailSegmentTravelClassDto>
}

/** Defines fields required to cancel an existing reservation. */
export type CancelReservationInput = {
  /** Indicates the domain of the reservation. */
  domain: Domain
  /** Unique identifier for the existing reservation that needs to be canceled. */
  reservationId: Scalars['ID']
}

/** CancelReservationResponse is returned when the cancelReservation mutation has been executed. */
export type CancelReservationResponse = {
  /** Canceled reservation id. */
  reservationId: Scalars['String']
  /** Defines the status of cancel operation. */
  success: Scalars['Boolean']
}

/** Union of different response objects that can be returned by cancelReservation mutation. Returns known/expected errors in a structured manner to the client. */
export type CancelReservationResponseUnion = CancelReservationResponse | UnexpectedError

/** Defines error reasons of cancel ride hail by process ID failures */
export enum CancelRideHailByProcessIdErrorReason {
  /** You cannot cancel a trip that has already been completed. */
  TripCompleted = 'TRIP_COMPLETED',
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** CancelRideHailByProcessIdErrorResponse is returned when the cancelRideHailByProcessId query responds with an error */
export type CancelRideHailByProcessIdErrorResponse = {
  /** Error reason */
  reason: CancelRideHailByProcessIdErrorReason
}

/** Provides the input parameters to cancel ride hails by process ID */
export type CancelRideHailByProcessIdRequest = {
  /** Process ID required to cancel all ride hails */
  processID: Scalars['ID']
}

/** CancelRideHailByProcessIdResponse is returned when the cancelRideHailByProductId query succeeds */
export type CancelRideHailByProcessIdResponse = {
  /** Optional response message */
  response?: Maybe<Scalars['String']>
}

/** CancelRideHailByProcessIdResponseDtoUnion is either the success or failure cancel ride hail response */
export type CancelRideHailByProcessIdResponseDtoUnion =
  | CancelRideHailByProcessIdErrorResponse
  | CancelRideHailByProcessIdResponse
  | UnexpectedError

/** Defines error reasons of cancel ride hail failures */
export enum CancelRideHailErrorReason {
  /** You cannot cancel a trip that has already been completed. */
  TripCompleted = 'TRIP_COMPLETED',
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** CancelRideHailErrorResponse is returned when the cancelRideHail query responds with an error */
export type CancelRideHailErrorResponse = {
  /** Error reason */
  reason: CancelRideHailErrorReason
}

/** Provides the input parameters to cancel a ride hail */
export type CancelRideHailInput = {
  /** List of bookings to be canceled */
  bookings: Array<InputMaybe<RideHailCancelableBooking>>
}

/** Provides the input parameters to cancel a ride hail */
export type CancelRideHailRequest = {
  /** List of bookings to be canceled */
  bookings: Array<InputMaybe<RideHailCancelableBooking>>
}

/** CancelRideHailResponse is returned when the cancelRideHail query succeeds */
export type CancelRideHailResponse = {
  /** Optional response message */
  response?: Maybe<Scalars['String']>
}

/** CancelRideHailResponseDtoUnion is either the success or failure cancel ride hail response */
export type CancelRideHailResponseDtoUnion =
  | CancelRideHailErrorResponse
  | CancelRideHailResponse
  | UnexpectedError

export type CancelTripInput = {
  tripId: Scalars['Int']
}

export type CancelTripSegmentInput = {
  carRentalKeys?: InputMaybe<Array<Scalars['String']>>
  flightKeys?: InputMaybe<Array<Scalars['String']>>
  hotelKeys?: InputMaybe<Array<Scalars['String']>>
  transactionId: Scalars['String']
}

export enum CancellationPolicy {
  Free = 'Free',
  NotAvailable = 'NotAvailable',
  Penalty = 'Penalty',
}

export type CarCostRates = {
  extraDayRate?: Maybe<Rate>
  extraHourRate?: Maybe<Rate>
  isMileageUnlimited: Scalars['Boolean']
  perDayRate?: Maybe<Rate>
  perWeekRate?: Maybe<Rate>
}

export type CarCostsCarRental = {
  extraDay?: Maybe<Money>
  extraHour?: Maybe<Money>
  isMileageUnlimited: Scalars['Boolean']
  perDay?: Maybe<Money>
}

export type CarCostsCarSegment = {
  extraDay?: Maybe<Money>
  extraHour?: Maybe<Money>
  isMileageUnlimited: Scalars['Boolean']
  perDay?: Maybe<Money>
  perWeek?: Maybe<Money>
}

export type CarDetailsInput = {
  imageInputOptions?: InputMaybe<ImageInputOptions>
}

export type CarLocationDetails = {
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type: Location
}

export type CarLocationType = {
  dropoff: CarLocationDetails
  pickup: CarLocationDetails
}

export type CarRental = {
  /** Customer's car rental loyalty program information */
  carRentalLoyalty?: Maybe<LoyaltyProgram>
  /** Car Rental drop off date. Format: YYYY-MM-DD in local TZ. */
  dropOffDate: Scalars['String']
  /** Information about the specific car rental dropoff location (branch), including vendor. In the use case where pickup and dropoff location are the same the location in this field will be the same as pickup_location. */
  dropOffLocation: CarRentalLocationDetails
  /** Car Rental drop off date/time. Format: YYYY-MM-DDThh:mm:ss in local TZ. */
  dropOffTime: Scalars['String']
  /** Identifier of a particular vehicle availability in the context of a search. */
  key: Scalars['ID']
  /** Car Rental pickup date. Format: YYYY-MM-DD in local TZ. */
  pickupDate: Scalars['String']
  /** Information about the specific car rental pickup location (branch), including vendor. In the use case where pickup and dropoff location are the same the location in this field will be the same as drop_off_location. */
  pickupLocation: CarRentalLocationDetails
  /** Car Rental pickup date/time. Format: YYYY-MM-DDThh:mm:ss in local TZ. */
  pickupTime: Scalars['String']
  /** Car Rental vehicle rate information. */
  rate: CarRentalRate
  /** Safety check details for the car rental. */
  safetyCheck: CarRentalSafetyCheck
  /** Rental related terms and conditions from provider. */
  termsAndConditions: Array<CarRules>
  /** Vehicle information. */
  vehicle: CarRentalVehicle
  /** Indicates the vendor/brand for the car rental */
  vendor: Vendor
}

export type CarRentalCar = {
  baseRate?: Maybe<Rate>
  carCostRates: CarCostRates
  carCosts: CarCostsCarRental
  /** Customer's car rental loyalty program information */
  carRentalLoyalty?: Maybe<LoyaltyProgram>
  daysCharged?: Maybe<Scalars['Int']>
  details: CarRentalCarDetails
  dropOff: CarRentalCarInteraction
  isPreferred?: Maybe<Scalars['Boolean']>
  isSelectable: Scalars['Boolean']
  key: Scalars['String']
  /** Rate without taxes, fees and service charges. */
  netRate?: Maybe<Rate>
  pickUp: CarRentalCarInteraction
  policy: Policy
  rate: Rate
  safetyCheck: CarRentalSafetyCheck
  serviceFee?: Maybe<ServiceFee>
  serviceFeeRate?: Maybe<Rate>
  taxes: Rate
  totalCost: Rate
  unlimitedMileage: Scalars['Boolean']
  vendor: Vendor
}

export type CarRentalCarDetails = {
  /** A car image for the car details modal */
  carDetailsImageUrl: Scalars['String']
  carImageUrl: Scalars['String']
  class: Scalars['String']
  contractName?: Maybe<Scalars['String']>
  fuel: Scalars['String']
  imageUrl: Scalars['String']
  isAirConditioned: Scalars['Boolean']
  luggage: Scalars['Int']
  name?: Maybe<Scalars['String']>
  passenger: Scalars['Int']
  transmission: Scalars['String']
  type: CarRentalType
}

export type CarRentalCarInteraction = {
  address: Address
  date: Scalars['Date']
  isAirportLocation: Scalars['Boolean']
  locationId: Scalars['String']
  phone: CarRentalPhone
  time: Scalars['DateTime']
  workingHours: CarRentalWorkingHours
}

export type CarRentalClassFilter = {
  code: CarRentalClassType
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  name: Scalars['String']
  selected: Scalars['Boolean']
}

export enum CarRentalClassType {
  Compact = 'COMPACT',
  CompactElite = 'COMPACT_ELITE',
  Economy = 'ECONOMY',
  EconomyElite = 'ECONOMY_ELITE',
  Fullsize = 'FULLSIZE',
  FullsizeElite = 'FULLSIZE_ELITE',
  Intermediate = 'INTERMEDIATE',
  IntermediateElite = 'INTERMEDIATE_ELITE',
  Luxury = 'LUXURY',
  LuxuryElite = 'LUXURY_ELITE',
  Mini = 'MINI',
  MiniElite = 'MINI_ELITE',
  Oversize = 'OVERSIZE',
  Premium = 'PREMIUM',
  PremiumElite = 'PREMIUM_ELITE',
  Special = 'SPECIAL',
  Standard = 'STANDARD',
  StandardElite = 'STANDARD_ELITE',
}

export type CarRentalCostSummary = {
  cost?: Maybe<Rate>
  isPaid: Scalars['Boolean']
  itemCount: Scalars['Int']
  tax?: Maybe<Rate>
  total?: Maybe<Rate>
  vendorName?: Maybe<Scalars['String']>
}

export type CarRentalDailyOpeningHours = {
  /** Represents the day of the week (in local TZ) for which the opening/closing hours are provided. */
  dayOfWeek: DayOfWeek
  /** Time intervals (in local TZ) the location is open on a particular day. Can be multiple, e.g.: opened from 9:00 to 14:00 and then again 15:00 to 20:00. Time intervals are ordered from early to late and are not overlapping. */
  times: Array<CarRentalOpeningHoursTimeInterval>
}

export type CarRentalDistanceCharge = {
  /** Amount charged for exceeding the mileage allowance. */
  charge: Rate
  /** Maximum mileage allowed over the duration of the rental. */
  distance: Scalars['Int']
  /** Unit for the mileage allowance. */
  unit: CarRentalDistanceUnit
}

/** Distance unit, miles (mi) or kilometers (km). */
export enum CarRentalDistanceUnit {
  Km = 'KM',
  Mi = 'MI',
}

export type CarRentalFilter = {
  filters: Array<CarRentalFiltersFilter>
  type: Scalars['String']
}

export type CarRentalFilters = {
  class: Array<CarRentalClassFilter>
  fuel: Array<CarRentalFuelTypeFilter>
  transmission: Array<CarRentalTransmissionFilter>
  type: Array<CarRentalTypeFilter>
  vendor: Array<CarRentalVendorFilter>
}

export type CarRentalFiltersFilter = {
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  name: Scalars['String']
  selected: Scalars['Boolean']
}

export enum CarRentalFuelType {
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  GasPowered = 'GAS_POWERED',
  Hybrid = 'HYBRID',
}

export type CarRentalFuelTypeFilter = {
  code: CarRentalFuelType
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  name: Scalars['String']
  selected: Scalars['Boolean']
}

export type CarRentalInteraction = {
  address: Address
  date: Scalars['String']
  isDeliveryCollection?: Maybe<Scalars['Boolean']>
  phone?: Maybe<CarRentalPhone>
  workingHours?: Maybe<CarWorkingHours>
}

export type CarRentalLocation = {
  address: Address
  airportCode?: Maybe<Scalars['String']>
  airportLocation: Scalars['Boolean']
  companyLocationName?: Maybe<Scalars['String']>
  distanceAsKilometers?: Maybe<Scalars['Float']>
  distanceAsMiles?: Maybe<Scalars['Float']>
  hours: Array<CarRentalLocationHours>
  isOpen: Scalars['Boolean']
  locationId: Scalars['String']
  phone: CarRentalPhone
  preferenceAttributeId?: Maybe<Scalars['String']>
  privateLocation: Scalars['Boolean']
  vendor: Vendor
}

export type CarRentalLocationDetails = {
  /** Indicates if the location is an airport location. If true, airport code can be found in locationAddress.airportCode */
  isAirport: Scalars['Boolean']
  locationAddress: Address
  locationId: Scalars['ID']
  openingHours: Array<CarRentalDailyOpeningHours>
  phone: Scalars['String']
}

export type CarRentalLocationHours = {
  allDay: Scalars['Boolean']
  hours: Array<CarRentalLocationHoursItem>
}

export type CarRentalLocationHoursItem = {
  end: Scalars['String']
  start: Scalars['String']
}

export enum CarRentalLocationSortBy {
  Distance = 'DISTANCE',
  Preference = 'PREFERENCE',
}

export type CarRentalLocationsInput = {
  address?: InputMaybe<Scalars['String']>
  airportCode?: InputMaybe<Scalars['AirportCode']>
  allowedVendors?: InputMaybe<Array<Scalars['String']>>
  geocode?: InputMaybe<GeocodeInput>
  /** Used if we want to get DSM for the drop off page */
  isDropOff?: InputMaybe<Scalars['Boolean']>
  logsMetadata?: InputMaybe<LogsMetadata>
  pageSize?: InputMaybe<Scalars['Int']>
  radius?: InputMaybe<Scalars['Int']>
  searchDateTime: Scalars['DateTime']
  sortBy?: InputMaybe<CarRentalLocationSortBy>
  startIndex?: InputMaybe<Scalars['Int']>
}

export type CarRentalLocationsResult = {
  filter: CarRentalFilter
  location: Array<CarRentalLocation>
  maxResults: Scalars['Int']
  message: Array<DynamicSiteMessage>
  page: Page
  preferenceAttribute: Array<CarRentalPreferenceAttribute>
}

export type CarRentalMultiVendorLocation = {
  address: Address
  airportCode?: Maybe<Scalars['String']>
  airportLocation: Scalars['Boolean']
  airportName?: Maybe<Scalars['String']>
  companyLocationName?: Maybe<Scalars['String']>
  distanceAsKilometers?: Maybe<Scalars['Float']>
  distanceAsMiles?: Maybe<Scalars['Float']>
  hours: Array<CarRentalLocationHours>
  isOpen: Scalars['Boolean']
  locationId: Scalars['String']
  phone: CarRentalPhone
  preferenceAttributeId?: Maybe<Scalars['String']>
  privateLocation: Scalars['Boolean']
  vendors: Array<Vendor>
}

export type CarRentalMultiVendorLocationsResult = {
  filter: CarRentalFilter
  location: Array<CarRentalMultiVendorLocation>
  message: Array<DynamicSiteMessage>
  preferenceAttribute: Array<CarRentalPreferenceAttribute>
}

export type CarRentalOpeningHoursTimeInterval = {
  /** Car Rental location closing time. Format: hh:mm in local TZ. */
  closingTime: Scalars['String']
  /** Car Rental location opening time. Format: hh:mm in local TZ. */
  openingTime: Scalars['String']
}

export type CarRentalPhone = {
  number: Scalars['String']
  type: Scalars['String']
}

export type CarRentalPreference = CommonTravelPreference & {
  carClass?: Maybe<CarRentalClassType>
  loyaltyProgram: Array<LoyaltyProgram>
  /** Special requests selected by a user for the specific travel type - air, hotel, car rental, rail. This may contain, for example, accessibility requests or a request for a non-smoking room. */
  specialRequest: Array<PreferenceOptions>
  /** Options available for special requests to choose from. It is array of objects, each containing special request code and label with text description. */
  specialRequestOptions?: Maybe<Array<PreferenceOptions>>
}

export type CarRentalPreferenceAttribute = {
  id: Scalars['String']
  preference?: Maybe<Preference>
  vendor: Vendor
}

/** Car rental preferences */
export type CarRentalPreferenceInput = {
  /** Identifies the type of car for this rental */
  carClass?: InputMaybe<CarRentalClassType>
  /** Loyalty program preferences */
  loyaltyProgram: Array<LoyaltyProgramInput>
  /** Special requests for all trave preferences */
  specialRequest: Array<PreferenceOptionsInput>
}

export type CarRentalRate = {
  /** Daily effective rental rate for vehicle. Calculated in case provider applies weekly or monthly base rates. */
  dailyRate: Rate
  /** Full days charged for requested rental duration. Extra hours are counted as day if cheaper. */
  daysCharged: Scalars['Int']
  /** Charge for exceeding the mileage allowance. */
  distanceCharge?: Maybe<CarRentalDistanceCharge>
  /** Indicates if rates include unlimited distance or there are charges applied for driven distance. */
  distanceUnlimited: Scalars['Boolean']
  /** The amount charged per extra day, in case of weekly or monthly base rate. This is not always specified and might be different to charge applied when extending the rental. */
  extraDayCharge?: Maybe<Rate>
  /** The amount charged per extra hour, in case vehicle is going to be returned later than time picked up. This is not always specified and might be different to charge applied when extending the rental. Might not be specified if adding an extra day is cheaper. */
  extraHourCharge?: Maybe<Rate>
  /** Vendor-specific rate code (qualifier) to be passed back in reservations. */
  rateCode: Scalars['String']
  /** Estimated total amount to be charged, inclusive of taxes and surcharges. */
  totalCost: Rate
  /** Total rental cost w/o taxes and fees, based on actual rate, including extra days or hours. Should match daily_rate multiplied by days_charged. */
  totalRate: Rate
  /** Total amount of taxes and fees to be charged. */
  totalTaxes: Rate
}

export type CarRentalReservation = {
  confirmationNumber: Scalars['ID']
  domain: Domain
  id: Scalars['ID']
  /** Indicates the payment status of the reservation. False indicates pay later. True indicates payment is done. */
  isPaid: Scalars['Boolean']
  reservedCarRental: CarRental
  status: ReservationStatus
}

export type CarRentalReservationsCostSummary = {
  /** Indicates the Domain of the cost summary. */
  domain: Domain
  /** Estimated total amount to be charged, inclusive of taxes and surcharges, across all car rental reservations in the trip. */
  totalCost: Rate
  /** Total cost w/o taxes and fees, across all car rental reservations in the trip. */
  totalRate: Rate
  /** Total amount of taxes and fees to be charged, across all car rental reservations in the trip. */
  totalTaxes: Rate
}

export type CarRentalSafetyCheck = {
  badgeUrl?: Maybe<Scalars['String']>
  messageCode: CarRentalSafetyCheckMessage
  url?: Maybe<Scalars['String']>
}

export enum CarRentalSafetyCheckMessage {
  Basic = 'BASIC',
  Extended = 'EXTENDED',
}

/** The car rental location to initiate a new search. If the airport code and location are blank the search will fallback to address. */
export type CarRentalSearchLocationParams = {
  /** The car rental location */
  address: Address
  /** The IATA code for the airport which is the car rental location. For example, "SFO" = San Francisco International Airport. */
  airportCode?: Maybe<Scalars['String']>
  /** Indicates whether the car rental location is at the airport or not. */
  airportLocation?: Maybe<Scalars['Boolean']>
  /** The car rental date time. Format: ISO-8601 (yyyy-MM-ddThh:mm:ss.sssZ). */
  dateTime: Scalars['String']
  /** Identifier for the car rental location. For example, "ETLAX". This value is populated by Gazoo. */
  locationId: Scalars['String']
}

/** The car rental search parameters enable clients to initiate a new search. */
export type CarRentalSearchParams = {
  /** The car rental drop-off location. This value is populated by Gazoo. */
  dropOff: CarRentalSearchLocationParams
  /** The car rental pick-up location. This value is populated by Gazoo. */
  pickUp: CarRentalSearchLocationParams
}

export type CarRentalSegment = {
  /** Describes how Car rental segment is aligned to flight dates */
  alignState: AlignState
  /**
   * True if the car rental reservation dates are the same as the round-trip flight reservation dates.
   * @deprecated Use alignState instead.
   */
  alignedWithFlightDate: Scalars['Boolean']
  /**
   * Indicates whether the car rental reservation times align with the round-trip flight reservation times,
   *       'Alignment means the car rental either starts after the flight departure and ends before the flight arrival,
   *       'or their times are the same.
   * @deprecated Use alignState instead.
   */
  alignedWithFlightTime: Scalars['Boolean']
  baseRate?: Maybe<Rate>
  /** @deprecated Use carDetails.capacity instead. */
  carCapacity: Scalars['Int']
  /** @deprecated Use carDetails.class instead. */
  carClass: Scalars['String']
  carCostRates: CarCostRates
  carCosts: CarCostsCarSegment
  carDetails: CarRentalSegmentCarDetails
  carId: Scalars['String']
  /** @deprecated Use carDetails.imageUrl instead. */
  carImageUrl?: Maybe<Scalars['String']>
  carLocationType?: Maybe<CarLocationType>
  /** @deprecated Use carDetails.luggage instead. */
  carLuggage: Scalars['Int']
  /** @deprecated Use carDetails.transmission instead. */
  carTransmission: Scalars['String']
  /** @deprecated Use carDetails.type instead. */
  carType: Scalars['String']
  /** @deprecated Use carDetails.contractName instead. */
  contractName?: Maybe<Scalars['String']>
  dropOffLocation: CarRentalInteraction
  dropOffTime: Scalars['String']
  dropoffLocationId?: Maybe<Scalars['String']>
  feesAndTaxesRate?: Maybe<Rate>
  /** @deprecated Use vendorDetails.imageUrl instead. */
  imageUrl?: Maybe<Scalars['String']>
  /** @deprecated Use carDetails.isAirConditioned instead. */
  isCarAirConditioned: Scalars['Boolean']
  isPreferred: Scalars['Boolean']
  isReserved?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  /** Rate without taxes, fees and service charges. */
  netRate?: Maybe<Rate>
  pickUpLocation: CarRentalInteraction
  pickUpTime: Scalars['String']
  pickupLocationId?: Maybe<Scalars['String']>
  policy: Policy
  preferredDescription?: Maybe<PreferredDescription>
  rate: Rate
  safetyCheck: CarRentalSafetyCheck
  segmentState?: Maybe<SegmentState>
  serviceFee?: Maybe<ServiceFee>
  serviceFeeRate?: Maybe<Rate>
  /** @deprecated Use segmentState instead. */
  state?: Maybe<SegmentState>
  totalCostRate?: Maybe<Rate>
  /** @deprecated Use vendorDetails.name instead. */
  vendor: Scalars['String']
  /** @deprecated Use vendorDetails.code instead. */
  vendorCode: Scalars['String']
  vendorDetails: CarRentalSegmentVendorDetails
  /** @deprecated Use vendorDetails.imageUrl instead. */
  vendorImage?: Maybe<Scalars['String']>
}

export type CarRentalSegmentCarDetails = {
  capacity: Scalars['Int']
  class?: Maybe<CarRentalClassType>
  contractName?: Maybe<Scalars['String']>
  fuel?: Maybe<CarRentalFuelType>
  imageUrl?: Maybe<Scalars['String']>
  isAirConditioned: Scalars['Boolean']
  luggage: Scalars['Int']
  name?: Maybe<Scalars['String']>
  transmission?: Maybe<CarRentalTransmissionType>
  type?: Maybe<CarRentalType>
}

export type CarRentalSegmentVendorDetails = {
  code: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export enum CarRentalSortBy {
  Distance = 'DISTANCE',
  Policy = 'POLICY',
  Preference = 'PREFERENCE',
  Price = 'PRICE',
}

export type CarRentalTransmissionFilter = {
  code: CarRentalTransmissionType
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  name: Scalars['String']
  selected: Scalars['Boolean']
}

export enum CarRentalTransmissionType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export type CarRentalTravelPreferenceInput = {
  /** This field is deprecated: use carClassWithNone instead */
  carClass?: InputMaybe<CarRentalClassType>
  carClassWithNone?: InputMaybe<UpdateCarRentalClassType>
  membership?: InputMaybe<Array<GazooMembership>>
  specialRequest?: InputMaybe<Array<Scalars['String']>>
}

export enum CarRentalType {
  Car = 'CAR',
  Pickup = 'PICKUP',
  Suv = 'SUV',
  Van = 'VAN',
}

export type CarRentalTypeFilter = {
  code: CarRentalType
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  lowestCost?: Maybe<Rate>
  selected: Scalars['Boolean']
}

export type CarRentalVehicle = {
  baggageCapacity: Scalars['Int']
  category: CarRentalType
  fuelType: CarRentalFuelType
  imageUrl: Scalars['String']
  isAirCondition: Scalars['Boolean']
  modelName: Scalars['String']
  passengerCapacity: Scalars['Int']
  transmissionType: CarRentalTransmissionType
  vehicleClass: CarRentalClassType
}

export type CarRentalVendorFilter = {
  code: Scalars['String']
  count: Scalars['Int']
  enabled: Scalars['Boolean']
  imageUrl: Scalars['String']
  name: Scalars['String']
  selected: Scalars['Boolean']
}

export type CarRentalWorkingHours = {
  end: Scalars['String']
  isAllDay: Scalars['Boolean']
  start: Scalars['String']
}

export type CarRentalsDisplayConfiguration = {
  areDeliveryAndCollectionSupported: Scalars['Boolean']
  requireCarPaymentForm: Scalars['Boolean']
}

export type CarRentalsInput = {
  /** The input configure a car image for the car details modal */
  carDetailsImageInputOptions?: InputMaybe<ImageInputOptions>
  carTypes?: InputMaybe<Array<CarRentalType>>
  classTypes?: InputMaybe<Array<CarRentalClassType>>
  deviceClass?: InputMaybe<DeviceClass>
  driverAge?: InputMaybe<Scalars['Int']>
  dropOffAirport?: InputMaybe<Scalars['AirportCode']>
  dropOffDateTime: Scalars['String']
  dropOffLocationId?: InputMaybe<Scalars['String']>
  fuelTypes?: InputMaybe<Array<CarRentalFuelType>>
  imageInputOptions?: InputMaybe<ImageInputOptions>
  logsMetadata?: InputMaybe<LogsMetadata>
  pageSize?: InputMaybe<Scalars['Int']>
  pickUpAirport?: InputMaybe<Scalars['AirportCode']>
  pickUpDateTime: Scalars['String']
  pickUpLocationId?: InputMaybe<Scalars['String']>
  /** searchId: id to get car results corresponding to the initial search(carRentalSearch) data. Provider:- car-rental-search-service */
  searchId?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<CarRentalSortBy>
  startIndex?: InputMaybe<Scalars['Int']>
  transmissionTypes?: InputMaybe<Array<CarRentalTransmissionType>>
  vendors?: InputMaybe<Array<Scalars['String']>>
}

export type CarRentalsResult = {
  car: Array<CarRentalCar>
  filters: CarRentalFilters
  includesOutOfPolicy: Scalars['Boolean']
  includesPreferred: Scalars['Boolean']
  location: Array<CarRentalLocation>
  maxResults: Scalars['Int']
  message: Array<DynamicSiteMessage>
  page: Page
  preferenceAttribute: Array<CarRentalPreferenceAttribute>
  primaryVendorsMissing: Scalars['Boolean']
  /** searchId: id to get car results corresponding to the initial search(carRentalSearch) data. Provider:- car-rental-search-service */
  searchId?: Maybe<Scalars['String']>
  secondaryVendorsMissing: Scalars['Boolean']
}

export type CarRules = {
  header?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type CarServiceCharges = {
  accountDiscount?: Maybe<Rate>
  accountServiceCharge?: Maybe<Rate>
  airPortFees?: Maybe<Rate>
  extraServiceCharge?: Maybe<Rate>
  fuelSurcharge?: Maybe<Rate>
  gratuity?: Maybe<Rate>
  offHoursCharge?: Maybe<Rate>
  parkingAmount?: Maybe<Rate>
  stopCharge?: Maybe<Rate>
}

export type CarServiceCostSummary = {
  carServiceCharges?: Maybe<CarServiceCharges>
  cost?: Maybe<Rate>
  isPaid: Scalars['Boolean']
  tax?: Maybe<Rate>
  total?: Maybe<Rate>
}

export type CarServiceDropOff = {
  address?: Maybe<Address>
  asDirected: Scalars['Boolean']
  location?: Maybe<DirectedDropOffLocation>
  time?: Maybe<Scalars['String']>
}

export type CarServicePickUp = {
  address?: Maybe<Address>
  location?: Maybe<AirportPickUpLocation>
  time: Scalars['String']
}

export type CarServiceProvider = {
  logo: Scalars['String']
  name: Scalars['String']
}

export type CarServiceSegment = {
  cancellationPolicy: Scalars['String']
  car: CarServiceSegmentCar
  carId: Scalars['String']
  chargeDisclaimer: Scalars['String']
  dropOff: CarServiceDropOff
  duration?: Maybe<Scalars['Int']>
  isReserved?: Maybe<Scalars['Boolean']>
  key: Scalars['String']
  logo?: Maybe<Scalars['String']>
  name: Scalars['String']
  noRateText?: Maybe<Scalars['String']>
  numberOfPassengers?: Maybe<Scalars['Int']>
  pickUp: CarServicePickUp
  policy: Policy
  polylineGeocodes?: Maybe<Array<Geocode>>
  preferenceAttributes?: Maybe<Array<Preference>>
  preferredDescription?: Maybe<PreferredDescription>
  provider?: Maybe<CarServiceProvider>
  rate: Rate
  rateType: RateTypeEnum
  stops?: Maybe<Array<Maybe<Address>>>
}

export type CarServiceSegmentCar = {
  capacity: Scalars['Int']
  description: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type CarServiceTripCost = {
  accountDiscount?: Maybe<Rate>
  accountService?: Maybe<Rate>
  airportFees?: Maybe<Rate>
  base?: Maybe<Rate>
  days?: Maybe<Scalars['Int']>
  distanceBased?: Maybe<Rate>
  extraService?: Maybe<Rate>
  fuelSurcharge?: Maybe<Rate>
  gratuity?: Maybe<Rate>
  meetAndGreet?: Maybe<Rate>
  minHours?: Maybe<Scalars['Int']>
  offHours?: Maybe<Rate>
  parking?: Maybe<Rate>
  rateQuote?: Maybe<Rate>
  stops?: Maybe<Rate>
  surfaceTransport?: Maybe<Rate>
  taxes: Rate
  timeBase?: Maybe<Rate>
  tolls?: Maybe<Rate>
  total: Rate
  type: RateTypeEnum
}

export type CarWorkingHours = {
  isAllDay: Scalars['Boolean']
  range?: Maybe<Array<Scalars['String']>>
}

/** Provides the cost details for the cart. */
export type CartCost = {
  /** Payment summary of the intents in the cart for which payment is not required at the time of checkout. */
  payLater: PayLaterSummary
  /** Estimated total amount to be charged across all intents in the cart, inclusive of taxes and surcharges. */
  total: Rate
}

/** Associate trip cost with a cost object for purposes of accounting, tax, budget management, client billing and overall corporate performance management. */
export type CartCostAllocation = {
  /** Identifies selected cost allocation value for cost allocation segment. */
  allocationId: Scalars['String']
  /** In the event that split cost allocation is (a) enabled in the site dashboard and (b) two cost allocations are being passed, then it is necessary to define which is the PRIMARY and which is the SECONDARY piece. */
  costSegmentSplit: CostSegmentSplit
  /** Identifies configured cost segment for this allocation value. */
  segmentId: Scalars['String']
}

/** Structured error response to indicate the requested cart was not found. */
export type CartNotFoundResponse = Error & {
  _uselessFieldForNest?: Maybe<Scalars['String']>
  /** Provides additional details about the error response. */
  message: Scalars['String']
}

/** Provides a summary for the cart. */
export type CartSummary = {
  /**
   * Cost details for all the intents in the cart.
   * @deprecated Use getCostSummary query instead.
   */
  cartCost: CartCost
  /** Latest date among all the domain intents in the cart - indicating the end date of the trip. */
  endDate: Scalars['String']
  /** Earliest date among all the domain intents in the cart - indicating the start date of the trip. */
  startDate: Scalars['String']
}

export type ChangeCancelRules = {
  allowCancel?: Maybe<Scalars['Boolean']>
  allowChange?: Maybe<Scalars['Boolean']>
  cancelMessage?: Maybe<Array<MessageDescription>>
  needCancelAcknowledgement?: Maybe<Scalars['Boolean']>
}

export type ChangeFee = {
  amount?: Maybe<Scalars['Float']>
  code?: Maybe<Scalars['String']>
  requestedCurrencyAmount?: Maybe<Scalars['Float']>
  requestedCurrencyUnit?: Maybe<Scalars['String']>
}

export type ChangeFlightInput = {
  addSeatAssignment?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  itineraryId: Scalars['String']
  newKey: Scalars['String']
  oldKey: Scalars['String']
}

export type ChangeFlightResponse = CommonResponse & {
  code: Scalars['String']
  data?: Maybe<ChangeFlightResponseData>
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type ChangeFlightResponseData = FlightResponseData & {
  airPriceChange?: Maybe<SegmentPriceChangeDetails>
  flightTripCostPerPassenger?: Maybe<Array<Maybe<TripCost>>>
  isSoldOut: Scalars['Boolean']
  itineraryId: Scalars['String']
}

export type ChangeFlightSeatsInput = {
  addSeats: Array<AddFlightSeatAssignmentInput>
  removeSeats?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  transactionId: Scalars['String']
}

export type ChangePasswordErrorResponse = BaseResponse | UnexpectedError

/** Provides the input parameters for the changePassword mutation. */
export type ChangePasswordInput = {
  /** The current password of the user */
  currentPassword?: InputMaybe<Scalars['String']>
  /** The new password to update to */
  newPassword: Scalars['String']
  /** The confirmation of the new password to update to */
  newPasswordConfirmation: Scalars['String']
}

export type ChangeSeatsAssignmentInput = {
  addSeats?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  itineraryId: Scalars['String']
  removeSeats?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
}

export type CheckBoxAdditionalInformation = {
  checkBoxValue?: Maybe<Scalars['Boolean']>
  checkedRequired?: Maybe<Scalars['Boolean']>
  defaultChecked?: Maybe<Scalars['Boolean']>
}

export enum CheckFarePolicyErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

export type CheckFarePolicyErrorResponse = {
  /** Error reason */
  reason: CheckFarePolicyErrorReason
}

export type CheckFarePolicyFlightPolicyDto = {
  isInPolicy: Scalars['Boolean']
  legId: Scalars['String']
}

export type CheckFarePolicyInput = {
  flightId: Scalars['String']
}

export type CheckFarePolicyResponseData = {
  airPriceChange?: Maybe<SegmentPriceChangeDetails>
  flightPolicies: Array<CheckFarePolicyFlightPolicyDto>
  newPrice?: Maybe<Rate>
}

export type CheckFarePolicyResponseDto = {
  code: Scalars['String']
  data?: Maybe<CheckFarePolicyResponseData>
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type CheckFarePolicyResponseDtoUnion =
  | CheckFarePolicyErrorResponse
  | CheckFarePolicyResponseDto
  | UnexpectedError

/** Provides the input parameters for the checkoutCart mutation. */
export type CheckoutCartInput = {
  /** Unique identifier for the cart that was previously created and has to be checked out. */
  cartId: Scalars['ID']
  /** Details of the passenger for whom the trip has be reserved. */
  passenger: PassengerInput
  /** Title for the trip */
  reservationTitle: Scalars['String']
}

/** CheckoutCartResponse is returned when the checkout mutation has been executed. */
export type CheckoutCartResponse = {
  /** Code indicating the status of the operation. 200 indicates success. */
  code: Scalars['String']
  /** Provides additional details about the status of the operation. */
  message: Scalars['String']
  /** Unique identifier for the reservation(s) that were created as a result of the checkout. Can be an empty string if success is false. */
  reservationId: Scalars['String']
  /** Indicates whether the checkout operation succeeded. */
  success: Scalars['Boolean']
}

/** Union of different response objects that can be returned by checkout mutation. Returns known/expected errors in a structured manner to the client. */
export type CheckoutCartResponseUnion =
  | CartNotFoundResponse
  | CheckoutCartResponse
  | UnexpectedError

export type CheckoutInfoAirMembership = {
  /** List of air membership data. */
  data: Array<CheckoutInfoAirMembershipData>
  /** List of air membership options. */
  options: Array<CheckoutInfoAirMembershipOptions>
}

export type CheckoutInfoAirMembershipData = {
  /** Carrier code for the air membership. */
  carrierCode: Scalars['String']
  /** Carrier name for the air membership. */
  carrierName?: Maybe<Scalars['String']>
  /** ID for the air membership. */
  id: Scalars['String']
  /** Number for the air membership. */
  number: Scalars['String']
}

export type CheckoutInfoAirMembershipOptions = {
  /** Carrier code for the air membership. */
  carrierCode: Scalars['String']
  /** Carrier name for the air membership. */
  carrierName?: Maybe<Scalars['String']>
}

export type CheckoutInfoBilling = {
  /** Billing information for air reservation. */
  air?: Maybe<CheckoutInfoBillingData>
  /** Billing information for car rental reservation. */
  carRental?: Maybe<CheckoutInfoBillingData>
  /** Billing information for hotel reservation. */
  hotel?: Maybe<CheckoutInfoBillingData>
  /** Indicates if single-use credit card is allowed. */
  isSingleUseCreditCardAllowed?: Maybe<Scalars['Boolean']>
  /** Billing information for rail reservation. */
  rail?: Maybe<CheckoutInfoBillingData>
}

export type CheckoutInfoBillingData = {
  /** List of credit cards associated with the billing information. */
  creditCards: Array<CheckoutInfoCreditCard>
  /** Default credit card for billing. */
  defaultCard?: Maybe<CheckoutInfoCreditCard>
  /** Default site card for billing. */
  defaultSiteCard?: Maybe<CheckoutInfoSiteCard>
  /** Metadata related to billing information. */
  metadata: BillingMetadata
  /** List of site cards associated with the billing information. */
  siteCards: Array<CheckoutInfoSiteCard>
}

export type CheckoutInfoCarMembershipData = {
  /** ID for car membership. */
  id: Scalars['String']
  /** Number for car membership. */
  number: Scalars['String']
  /** Vendor code for car membership. */
  vendorCode: Scalars['String']
  /** Vendor name for car membership. */
  vendorName?: Maybe<Scalars['String']>
}

export type CheckoutInfoCarMembershipOptions = {
  /** Vendor code for car membership. */
  vendorCode: Scalars['String']
  /** Vendor name for car membership. */
  vendorName?: Maybe<Scalars['String']>
}

export type CheckoutInfoCarRentalMembership = {
  /** List of car rental membership data. */
  data: Array<CheckoutInfoCarMembershipData>
  /** List of car rental membership options. */
  options: Array<CheckoutInfoCarMembershipOptions>
}

export type CheckoutInfoCostAllocation = {
  /** The cost allocation segment id associated with the checkout */
  costAllocationSegmentId: Scalars['Float']
}

export type CheckoutInfoCreditCard = {
  /** Address associated with the credit card. */
  address?: Maybe<Address>
  /** Data of the credit card. */
  data: CheckoutInfoCreditCardData
  /** ID of the credit card. */
  id: Scalars['String']
  /** Flag indicating if the credit card is out of policy. */
  isCardOutOfPolicy: Scalars['Boolean']
  /** Flag indicating if the credit card is a corporate card. */
  isCorporateCard: Scalars['Boolean']
}

export type CheckoutInfoCreditCardData = {
  /** CVV of the credit card. */
  cvv?: Maybe<Scalars['String']>
  /** Expiration date of the credit card. */
  expirationDate?: Maybe<Scalars['String']>
  /** ID of the credit card. */
  id: Scalars['String']
  /** Label of the credit card. */
  label?: Maybe<Scalars['String']>
  /** Name on the credit card. */
  nameOnTheCard?: Maybe<Scalars['String']>
  /** Number of the credit card. */
  number?: Maybe<Scalars['String']>
  /** Type of the credit card. */
  type?: Maybe<CreditCardBrandType>
}

export type CheckoutInfoHotelMembership = {
  /** List of hotel membership data. */
  data: Array<CheckoutInfoHotelMembershipData>
  /** List of hotel membership options. */
  options: Array<CheckoutInfoHotelMembershipOptions>
}

export type CheckoutInfoHotelMembershipData = {
  /** List of hotel chains to which the membership applies. */
  appliesToChain: Array<Scalars['String']>
  /** ID of the hotel membership. */
  id: Scalars['String']
  /** Number associated with the hotel membership. */
  number: Scalars['String']
  /** Vendor code of the hotel membership. */
  vendorCode: Scalars['String']
  /** Vendor name of the hotel membership. */
  vendorName?: Maybe<Scalars['String']>
}

export type CheckoutInfoHotelMembershipOptions = {
  /** Vendor code of the hotel membership. */
  vendorCode: Scalars['String']
  /** Vendor name of the hotel membership. */
  vendorName?: Maybe<Scalars['String']>
}

export type CheckoutInfoInput = {
  bookingId?: InputMaybe<Scalars['String']>
  itineraryId: Scalars['String']
}

export type CheckoutInfoMealRequest = {
  /** Data for the meal request. */
  data?: Maybe<CheckoutInfoMealRequestData>
  /** Options for the meal request. */
  options: Array<CheckoutInfoMealRequestOption>
}

export type CheckoutInfoMealRequestData = {
  /** Code of the meal. */
  code: Scalars['String']
  /** ID of the meal request data. */
  id: Scalars['String']
  /** Name of the meal. */
  mealName?: Maybe<Scalars['String']>
}

export type CheckoutInfoMealRequestOption = {
  /** Code of the meal. */
  code: Scalars['String']
  /** Name of the meal. */
  mealName?: Maybe<Scalars['String']>
}

export type CheckoutInfoMemberships = {
  /** Air memberships data and options associated with the checkout */
  air?: Maybe<CheckoutInfoAirMembership>
  /** Car rental memberships data and options associated with the checkout */
  carRental?: Maybe<CheckoutInfoCarRentalMembership>
  /** Hotel memberships data and options associated with the checkout */
  hotel?: Maybe<CheckoutInfoHotelMembership>
}

export type CheckoutInfoResult = {
  /** Billing information for the checkout */
  billing?: Maybe<CheckoutInfoBilling>
  /**
   * Cost allocation information for the checkout (this field is deprecated)
   * @deprecated Use costAllocations instead
   */
  costAllocation?: Maybe<CheckoutInfoCostAllocation>
  /** Cost allocation information for the checkout */
  costAllocations: Array<CheckoutInfoCostAllocation>
  /** Configuration for custom fields associated with the checkout */
  customFieldsConfiguration?: Maybe<CustomFieldConfiguration>
  displaySettings?: Maybe<DisplaySettings>
  /** Dynamic site messages associated with the checkout */
  dynamicSiteMessages: Array<DynamicSiteMessage>
  /** Indicates if passport is required for the checkout */
  isPassportRequired?: Maybe<Scalars['Boolean']>
  /** Indicates user to acknowledge the ticket exchange and its required for the checkout */
  isRulesAcknowledgementRequired?: Maybe<Scalars['Boolean']>
  /** Meal request data and options  for the checkout */
  mealRequest?: Maybe<CheckoutInfoMealRequest>
  /** Memberships data and options associated with the checkout */
  memberships?: Maybe<CheckoutInfoMemberships>
  /** Requirement for pre-book requests for the checkout */
  preBookRequestRequirement: PreBookRequestRequirement
  /** Information about the primary traveler */
  primaryTraveler: CheckoutInfoTraveler
  /** Special requests data and options associated with the checkout */
  specialRequests?: Maybe<CheckoutInfoSpecialRequests>
}

export type CheckoutInfoSiteCard = {
  /** Address of the site card. */
  address?: Maybe<Address>
  /** Data of the site card. */
  data: CheckoutInfoSiteCardData
  /** ID of the credit card. */
  id: Scalars['String']
  /** Flag indicating if the site card is out of policy. */
  isCardOutOfPolicy: Scalars['Boolean']
  /** Flag indicating if the site card is restricted. */
  isRestricted: Scalars['Boolean']
}

export type CheckoutInfoSiteCardData = {
  /** ID of the site card data. */
  id: Scalars['String']
  /** Label of the site card data. */
  label?: Maybe<Scalars['String']>
}

export type CheckoutInfoSpecialRequest = {
  /** Special request data. */
  data: Array<CheckoutInfoSpecialRequestData>
  /** Special request options. */
  options: Array<CheckoutInfoSpecialRequestOption>
}

export type CheckoutInfoSpecialRequestData = {
  /** Name of the special request data. */
  name?: Maybe<Scalars['String']>
  /** Value of the special request data. */
  value: Scalars['String']
}

export type CheckoutInfoSpecialRequestOption = {
  /** Name of the special request option. */
  name?: Maybe<Scalars['String']>
  /** Value of the special request option. */
  value: Scalars['String']
}

export type CheckoutInfoSpecialRequests = {
  /** Special requests for air. */
  air?: Maybe<CheckoutInfoSpecialRequest>
  /** Special requests for car rental. */
  carRental?: Maybe<CheckoutInfoSpecialRequest>
  /** Special requests for hotel. */
  hotel?: Maybe<CheckoutInfoSpecialRequest>
}

export type CheckoutInfoTraveler = {
  /** represents the fields or attributes associated with a traveler in a checkout process. Each field is represented as a boolean property indicating whether it is required, changeable, or enabled in the checkout process. */
  data: CheckoutInfoTravelerData
  /** Fields related to the traveler */
  travelerFields?: Maybe<CheckoutInfoTravelerFields>
}

export type CheckoutInfoTravelerData = {
  /** Address of the traveler */
  address?: Maybe<Address>
  /** Date of birth of the traveler */
  dateOfBirth?: Maybe<Scalars['String']>
  /** Email of the traveler */
  email: Scalars['String']
  /** First name of the traveler */
  firstName: Scalars['String']
  /** Gender of the traveler */
  gender?: Maybe<Gender>
  /** Home address of the traveler */
  homeAddress?: Maybe<Address>
  /** ID of the traveler */
  id: Scalars['String']
  /** Known Traveler Numbers of the traveler */
  knownTravelerNumbers?: Maybe<Array<CheckoutInfoTravelerKnownTravelerNumber>>
  /** Last name of the traveler */
  lastName: Scalars['String']
  /** Middle initial of the traveler */
  middleInitial?: Maybe<Scalars['String']>
  /** Passport information of the traveler */
  passport?: Maybe<Passport>
  /** Passport information of the traveler */
  passports?: Maybe<Array<Passport>>
  /** Phone numbers associated with the traveler */
  phoneNumber: Array<Phone>
  /** Indicates if the traveler is the primary traveler */
  primary: Scalars['Boolean']
  /** Redress Numbers of the traveler */
  redressNumbers?: Maybe<Array<CheckoutInfoTravelerRedressNumber>>
  /** Suffix of the traveler */
  suffix?: Maybe<Scalars['String']>
  /** Title of the traveler */
  title?: Maybe<Scalars['String']>
}

export type CheckoutInfoTravelerFields = {
  /** Indicates whether the address of the traveler is changeable. */
  isAddressChangeable: Scalars['Boolean']
  /** Indicates whether the address of the traveler is required. */
  isAddressRequired: Scalars['Boolean']
  /** Indicates whether the date of birth of the traveler is changeable. */
  isDateOfBirthChangeable: Scalars['Boolean']
  /** Indicates whether the date of birth of the traveler is required. */
  isDateOfBirthRequired: Scalars['Boolean']
  /** Indicates whether the option to change the traveler as default is enabled. */
  isDefaultChangeTravelerOptionEnabled: Scalars['Boolean']
  /** Indicates whether the email of the traveler is changeable. */
  isEMailChangeable: Scalars['Boolean']
  /** Indicates whether the email address of the traveler is required. */
  isEMailRequired: Scalars['Boolean']
  /** Indicates whether the first name of the traveler is required. */
  isFirstNameRequired: Scalars['Boolean']
  /** Indicates whether the gender of the traveler is changeable. */
  isGenderChangeable: Scalars['Boolean']
  /** Indicates whether the gender of the traveler is required. */
  isGenderRequired: Scalars['Boolean']
  /** Indicates whether the last name of the traveler is required. */
  isLastNameRequired: Scalars['Boolean']
  /** Indicates whether the middle name of the traveler is required. */
  isMiddleNameRequired: Scalars['Boolean']
  /** Indicates whether the name of the traveler is changeable. */
  isNameChangeable: Scalars['Boolean']
  /** Indicates whether the phone number of the traveler is changeable. */
  isPhoneNumberChangeable: Scalars['Boolean']
  /** Indicates whether the phone number of the traveler is required. */
  isPhoneNumberRequired: Scalars['Boolean']
  /** Indicates whether the traveler is marked as the primary traveler in the checkout process. */
  isPrimaryTraveler: Scalars['Boolean']
  /** Indicates whether the suffix of the traveler is changeable. */
  isSuffixChangeable: Scalars['Boolean']
  /** Indicates whether the suffix of the traveler is required. */
  isSuffixRequired: Scalars['Boolean']
  /** Indicates whether the title of the traveler is changeable. */
  isTitleChangeable: Scalars['Boolean']
}

/** Contains additional documentation associated with known traveler number. */
export type CheckoutInfoTravelerKnownTravelerNumber = {
  /** Country of issuance for the Known Traveler Number */
  countryCode: Scalars['String']
  /** Expiration date for the Known Traveler Number */
  expirationDate?: Maybe<Scalars['String']>
  /** Unique identifier for the User Known Traveler Number */
  number: Scalars['String']
}

/** Contains fields associated with redress number. */
export type CheckoutInfoTravelerRedressNumber = {
  /** Country of issuance for the Redress Number */
  countryCode: Scalars['String']
  /** Unique identifier for the User Redress Number */
  number: Scalars['String']
}

export type CheckoutItineraryInput = {
  billing?: InputMaybe<BillingInputDto>
  bookingId?: InputMaybe<Scalars['String']>
  costAllocation?: InputMaybe<Array<CostAllocationSubmit>>
  customFields?: InputMaybe<Array<CustomFieldBooking>>
  departureLuggageOption?: InputMaybe<LuggageOptionInput>
  isOnHold?: InputMaybe<Scalars['Boolean']>
  isVirtualPaySelected?: InputMaybe<Scalars['Boolean']>
  itineraryId: Scalars['String']
  mealRequest?: InputMaybe<Scalars['String']>
  memberships?: InputMaybe<MembershipInputDto>
  outOfPolicyExplanation?: InputMaybe<OutOfPolicyExplanationInputDto>
  primaryTraveler: PrimaryTravelerInputDto
  returnLuggageOption?: InputMaybe<LuggageOptionInput>
  segments: ItinerarySegmentsInputDto
  shareTravelerContact?: InputMaybe<ShareTravelerContactType>
  specialRequests?: InputMaybe<SpecialRequestInputDto>
}

export type CheckoutItineraryResponseUnion = OrderDetails | UnexpectedError

export type CodeshareCarrier = {
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type CommonResponse = {
  code: Scalars['String']
  message: Scalars['String']
  success: Scalars['Boolean']
}

/** It is an interface, which contains fields common for all travel preference types - air, hotel, car rental, rail. */
export type CommonTravelPreference = {
  loyaltyProgram: Array<LoyaltyProgram>
  /** Special requests selected by a user for the specific travel type - air, hotel, car rental, rail. This may contain, for example, accessibility requests or a request for a non-smoking room. */
  specialRequest: Array<PreferenceOptions>
  /** Options available for special requests to choose from. It is array of objects, each containing special request code and label with text description. */
  specialRequestOptions?: Maybe<Array<PreferenceOptions>>
}

export type CompanyResourceConfiguration = {
  /** Dynamic text for description. */
  descriptionMessage?: Maybe<Scalars['String']>
  /** Unique id of company resource. */
  id: Scalars['Float']
  /** Array of company resources which contains a url and a label to display. */
  resourceTexts: Array<CompanyResourceText>
}

export type CompanyResourceText = {
  /** Company Resource label. */
  label: Scalars['String']
  /** Company Resource url. */
  url: Scalars['String']
}

/** OOP compliance code option for user to select. */
export type ComplianceCodeOption = {
  /** Valid pre-configured compliance code value. */
  code: Scalars['String']
  /** Brief description for this compliance code to show to the user. */
  label: Scalars['String']
}

/** ComplianceCodeValue captures user selected compliance code to justify reservation of out-of-policy item referenced. */
export type ComplianceCodeValue = {
  /** Valid pre-configured "compliance code" value for this intent's domain. */
  code: Scalars['String']
  /** Required. Reference to out-of-policy intent in cart. */
  intentId: Scalars['String']
}

export type ComplianceMessage = {
  countryRestrictions?: Maybe<Array<Scalars['String']>>
  errors?: Maybe<Array<Scalars['String']>>
  infos?: Maybe<Array<Scalars['String']>>
  warns?: Maybe<Array<Scalars['String']>>
}

export type Conditions = {
  description: Scalars['String']
  name: Scalars['String']
}

/** Connection duration between segments. */
export type Connection = {
  /** Destination flight segment identifier. */
  destinationSegmentId: Scalars['String']
  /** Layover time in minutes between origin and destination airport. */
  duration: Scalars['Float']
  /** Origin flight segment identifier. */
  originSegmentId: Scalars['String']
}

export type ContinuityMessage = {
  code: Scalars['String']
  data?: Maybe<Array<Scalars['String']>>
  followUpMessages?: Maybe<Array<Scalars['String']>>
  intent?: Maybe<Scalars['String']>
  segmentId?: Maybe<Array<Scalars['String']>>
}

/** Corporate rate information (deprecated: "Use private rate") */
export type CorporateRate = {
  averageRate: Rate
  corporate?: Maybe<Scalars['Boolean']>
  realTime: Scalars['Boolean']
}

export type CostAllocation = {
  allocation: Array<CostAllocationUnit>
  totalResults?: Maybe<Scalars['Float']>
}

export type CostAllocationAncestor = {
  allocationId: Scalars['Float']
  name: Scalars['String']
  segmentId: Scalars['Float']
}

/** Associate trip cost with a cost object for purposes of accounting, tax, budget management, client billing and overall corporate performance management. */
export type CostAllocationInput = {
  /** Identifies selected cost allocation value for cost allocation segment. */
  allocationId: Scalars['String']
  /** In the event that split cost allocation is (a) enabled in the site dashboard and (b) two cost allocations are being passed, then it is necessary to define which is the PRIMARY and which is the SECONDARY piece. */
  costSegmentSplit: CostSegmentSplit
  /** Identifies configured cost segment for this allocation value. */
  segmentId: Scalars['String']
}

export type CostAllocationPageInput = {
  pageSize: Scalars['Int']
}

export type CostAllocationSubmit = {
  allocationId: Scalars['String']
  costSegmentType?: InputMaybe<CostSegmentType>
  segmentId: Scalars['String']
  splitCostAllocation?: InputMaybe<Scalars['Boolean']>
}

export type CostAllocationUnit = {
  allocationId: Scalars['Float']
  ancestors?: Maybe<Array<CostAllocationAncestor>>
  code: Scalars['String']
  name: Scalars['String']
  segmentId: Scalars['Float']
  totalResults?: Maybe<Scalars['Float']>
}

export type CostSegment = {
  childNodes?: Maybe<Array<CostSegment>>
  description?: Maybe<Scalars['String']>
  id: Scalars['Float']
  isActive: Scalars['Boolean']
  isRequired: Scalars['Boolean']
  isSplitCostAllocation: Scalars['Boolean']
  isSplitCostRequired: Scalars['Boolean']
  name: Scalars['String']
  splitCostAllocationTooltip?: Maybe<Scalars['String']>
  tooltip?: Maybe<Scalars['String']>
}

/** Defines split on the cost segments. */
export enum CostSegmentSplit {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum CostSegmentType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export type CostSegments = {
  segments: Array<CostSegment>
}

/** Breakdown of costs associated with cart items. */
export type CostSummary = {
  /** A list of individual cost breakdowns per intent in cart. */
  intentCosts: Array<IntentCost>
  /** Total of this part of the cost summary (pay now or later). */
  subTotal: Rate
}

/** GetCostSummaryResponse is returned when the getCostSummary query succeeds. */
export type CostSummaryResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Estimated total cost of all travel intents in the cart. */
  estimatedTotal: Rate
  /** Indicates the amount the traveler must pay in advance when reserving travel intents. */
  prepaidCost: CostSummary
  /** Indicates the amount the traveler is going to pay after a reservation. */
  reservedCost: CostSummary
}

export type Covid19Statistic = {
  confirmedFourteenDayTrend?: Maybe<Scalars['Float']>
  confirmedNewDaily?: Maybe<Scalars['Int']>
  confirmedTotal?: Maybe<Scalars['Int']>
  deathsFourteenDayTrend?: Maybe<Scalars['Float']>
  deathsNewDaily?: Maybe<Scalars['Int']>
  deathsTotal?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type Covid19Summary = {
  country?: Maybe<Covid19Statistic>
  county?: Maybe<Covid19Statistic>
  province?: Maybe<Covid19Statistic>
  state?: Maybe<Covid19Statistic>
}

/** CreateCartResponse is returned when the createCart mutation succeeds. */
export type CreateCartResponse = {
  /** Unique identifier for the empty cart that is created for the user. */
  cartId: Scalars['ID']
}

/** Union of different response objects that can be returned by createCart mutation. Returns known/expected errors in a structured manner to the client. */
export type CreateCartResponseUnion = CreateCartResponse | UnexpectedError

/** Reason why traveller registration failed. */
export enum CreateCustomerUberAccountErrorReason {
  /** Email already exists in the traveller's company's Uber for Business (U4B) org */
  AccountAlreadyExists = 'ACCOUNT_ALREADY_EXISTS',
  /** The traveller's Deem site is not enabled for Uber user account self-creation */
  NotEnabled = 'NOT_ENABLED',
  /** An unknown error has occurred. */
  Unspecified = 'UNSPECIFIED',
}

/** Failed response from the createCustomerUberAccount mutation */
export type CreateCustomerUberAccountErrorResponse = {
  /** Reason why traveller registration failed. */
  reason: CreateCustomerUberAccountErrorReason
}

/** Successful response from the createCustomerUberAccount mutation */
export type CreateCustomerUberAccountResponse = {
  /** The URL required by the traveller to complete activation of their account. The calling client should redirect the traveller to this URL. If the traveller does not visit the URL and complete the activation process, then subsequent calls to the customerUberAccountStatus query will return NOT_LINKED along with the URL. */
  linkingUrl: Scalars['String']
}

/** Union of either a successful/failed create customer Uber account response */
export type CreateCustomerUberAccountResponseUnion =
  | CreateCustomerUberAccountErrorResponse
  | CreateCustomerUberAccountResponse
  | UnexpectedError

export type CreateFlightSearchInput = {
  /** Booking provider. Used in conjunction with Record Locator to support change order flow. */
  bookingProvider?: InputMaybe<Scalars['String']>
  /** Existing outbound flight. Used in FSS search for change return flight only use case */
  existingOutboundFlightSegments?: InputMaybe<Array<ExistingOutboundFlightSegmentInput>>
  flightLegSearch: Array<FlightLegSearchInput>
  includeNearbyAirports?: InputMaybe<Scalars['Boolean']>
  /** NDC flag. Signifies order search is specifically for NDC flights. */
  isNdc?: InputMaybe<Scalars['Boolean']>
  /** ReturnFlightChangeOnly flag. Used to handle change return flight only use case. */
  isReturnFlightChangeOnly?: InputMaybe<Scalars['Boolean']>
  logsMetadata?: InputMaybe<LogsMetadata>
  numberOfPassengers: Scalars['Int']
  /** Trip process ID. When set, this can be used in support of unused ticket functionality. */
  processId?: InputMaybe<Scalars['Int']>
  /** Record Locator. When set, this can be used for change order flow. */
  recordLocator?: InputMaybe<Scalars['String']>
}

export type CreateFlightSearchPayload = {
  id: Scalars['ID']
}

export type CreateItineraryInput = {
  customFields?: InputMaybe<Array<CustomFieldBooking>>
}

export type CreateItineraryResult = {
  id: Scalars['String']
}

export type CreateRailSearchInput = {
  includeNearbyAirports?: InputMaybe<Scalars['Boolean']>
  numberOfPassengers: Scalars['Int']
  railLegSearch: Array<RailLegSearchInput>
  routeType?: InputMaybe<RouteType>
}

export type CreateRailSearchPayload = {
  id: Scalars['ID']
}

export type CreateSearchResult = {
  hotelResultId: Scalars['String']
}

export type CreditCard = {
  billingAddress?: Maybe<Address>
  billingId: Scalars['ID']
  brandType?: Maybe<CreditCardBrandType>
  cardNumber?: Maybe<Scalars['String']>
  expirationDate?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCardOutOfPolicy?: Maybe<Scalars['Boolean']>
  isSiteCard?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
  metadata: CreditCardMetadata
  name?: Maybe<Scalars['String']>
  securityCode?: Maybe<Scalars['String']>
}

export type CreditCardBillingAddressInput = {
  city: Scalars['String']
  countryCode: Scalars['String']
  postalCode: Scalars['String']
  stateCode: Scalars['String']
  street1: Scalars['String']
  street2: Scalars['String']
}

export enum CreditCardBrandType {
  Ax = 'AX',
  Ca = 'CA',
  Cb = 'CB',
  Dc = 'DC',
  Ds = 'DS',
  Ec = 'EC',
  Jc = 'JC',
  Other = 'OTHER',
  Tp = 'TP',
  Vi = 'VI',
}

export type CreditCardDataInputDto = {
  cvv?: InputMaybe<Scalars['String']>
  expirationDate?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  isSingleUseCreditCard?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  nameOnTheCard?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  type?: InputMaybe<CreditCardBrandType>
}

export type CreditCardDetails = {
  cvv?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type CreditCardInput = {
  brandType: CreditCardBrandType
  cardNumber: Scalars['String']
  expirationDate: Scalars['String']
  label?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  securityCode?: InputMaybe<Scalars['String']>
}

export type CreditCardInputDto = {
  address?: InputMaybe<AddressInputDto>
  data: CreditCardDataInputDto
  id: Scalars['String']
  isCorporateCard?: InputMaybe<Scalars['Boolean']>
}

export type CreditCardMetadata = {
  isBillingAddressRequired: Scalars['Boolean']
  isEditable: Scalars['Boolean']
  isRestricted: Scalars['Boolean']
  type: CreditCardTypes
}

export enum CreditCardTypes {
  PersonalCorporateCreditCard = 'PERSONAL_CORPORATE_CREDIT_CARD',
  PersonalCreditCard = 'PERSONAL_CREDIT_CARD',
  SiteCard = 'SITE_CARD',
}

export type CreditCardsInput = {
  /** Legacy alphanumeric id from credit card. */
  id: Scalars['String']
  /** Pre expiration alert set for credit cards. */
  preExpirationAlert: Scalars['String']
}

export type CreditPaymentCard = {
  cvv?: Maybe<Scalars['String']>
  expirationDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  nameOnTheCard?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type Currency = {
  code: Scalars['String']
  value: Scalars['String']
}

export type CustomBadge = {
  color?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** Contains definition of configured custom field, including its current value. */
export type CustomField = {
  /** Configured constraints for fields with type CHECKBOX */
  checkBoxDisplay?: Maybe<CustomFieldCheckBoxDisplay>
  /** Configured constraints for fields with type DATETIME, DATE or TIME */
  dateTimeDisplay?: Maybe<CustomFieldDateTimeDisplay>
  /** Configured default value. */
  defaultValue?: Maybe<Scalars['String']>
  /** Input and display constraints for fields with type ENUMERATION. Contains list of selectable options. */
  enumerationDisplay?: Maybe<CustomFieldEnumerationDisplay>
  /** @deprecated Not used. */
  isHasClientSideJavascriptValidator?: Maybe<Scalars['Boolean']>
  /** @deprecated Not used. */
  isNotAppliedServerSideJavascriptInitializer?: Maybe<Scalars['Boolean']>
  /** Label to display to user. */
  label?: Maybe<Scalars['String']>
  /** Name identifies the custom field in its collection. Not displayed to the end-user. */
  name: Scalars['String']
  /** Configured constraints for fields with type NUMBER, PERCENTAGE or MONETARY */
  numberDisplay?: Maybe<CustomFieldNumberDisplay>
  /** Indicates if user is required to enter value. */
  optional?: Maybe<Scalars['Boolean']>
  preTripApprovalDisplay?: Maybe<PreTripApprovalDisplay>
  /** Configured constraints for fields with type TEXT. */
  textDisplay?: Maybe<CustomFieldTextDisplay>
  /** Tooltip message to show to user. */
  tooltip?: Maybe<Scalars['String']>
  /** Type of custom field. */
  type?: Maybe<CustomFieldType>
  /** User entered value of the custom field. */
  value?: Maybe<Scalars['String']>
}

export type CustomFieldBooking = {
  collectionId: Scalars['String']
  name: Scalars['String']
  value: Scalars['String']
}

export type CustomFieldCheckBoxDisplay = {
  isChecked: Scalars['Boolean']
  isCheckedValueRequired: Scalars['Boolean']
}

/** Collection of custom fields. */
export type CustomFieldCollectionItem = {
  /** Description to display with this collection (group). */
  collectionDescription?: Maybe<Scalars['String']>
  /** Internal identifier of configured collection. */
  collectionId: Scalars['String']
  /** Display label for this collection (group). */
  collectionLabel?: Maybe<Scalars['String']>
  /** Fields in collection in display order. */
  customFields: Array<CustomField>
  /** @deprecated Not used. */
  isNotAppliedServerSideJavascript?: Maybe<Scalars['Boolean']>
}

/** Configuration for custom field collections. */
export type CustomFieldConfiguration = {
  /** Collections of custom fields, as configured by admin. Used to logically and visually group custom fields. */
  customFieldCollection: Array<CustomFieldCollectionItem>
}

/** Input and display constraints for custom fields with type DATETIME, DATE or TIME */
export type CustomFieldDateTimeDisplay = {
  /** Indicates whether the user can specify a particular day of month. */
  displayDay?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular hour. */
  displayHour?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular minute. */
  displayMinute?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular month. */
  displayMonth?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular timezone. */
  displayTimeZone?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular year. */
  displayYear?: Maybe<Scalars['Boolean']>
  /** Indicates whether the end-user can specify a date/time in the past. */
  isAllowedPastDateTime?: Maybe<Scalars['Boolean']>
  /** Latest day of month user can enter (inclusive). */
  maxDay?: Maybe<Scalars['Int']>
  /** Latest month user can enter (inclusive). */
  maxMonth?: Maybe<Scalars['Int']>
  /** Latest year user can enter (inclusive). */
  maxYear?: Maybe<Scalars['Int']>
  /** Earliest day of month user can enter (inclusive). */
  minDay?: Maybe<Scalars['Int']>
  /** Earliest month user can enter (inclusive). */
  minMonth?: Maybe<Scalars['Int']>
  /** Earliest year user can enter (inclusive). */
  minYear?: Maybe<Scalars['Int']>
}

/** Input and display constraints for custom fields with type ENUMERATION. Contains list of selectable options. */
export type CustomFieldEnumerationDisplay = {
  /** Display hint to show radio buttons instead of drop-down. */
  isRadioButtonsStyle: Scalars['Boolean']
  /** Available options for user to select from. */
  option?: Maybe<Array<CustomFieldOption>>
}

export type CustomFieldInput = {
  /** Identifies configured custom field collection this value belongs to. */
  collectionId: Scalars['String']
  /** Name identifies the custom field in its collection. */
  name: Scalars['String']
  /** User entered value of the custom field. */
  value: Scalars['String']
}

/** Input and display constraints for custom fields with type NUMBER, PERCENTAGE or MONETARY */
export type CustomFieldNumberDisplay = {
  /** Unit code, name or symbol. Usually 3-letter currency code (for MONETARY type). */
  currencyCode?: Maybe<Scalars['String']>
  /** Number of digits after the decimal number (precision). */
  decimalPlaces?: Maybe<Scalars['Int']>
  /** Maximal value user can enter (inclusive). */
  maxValue?: Maybe<Scalars['Float']>
  /** Minimal value user can enter (inclusive). */
  minValue?: Maybe<Scalars['Float']>
}

/** Available Option for custom fields with type ENUMERATION. */
export type CustomFieldOption = {
  /** Display label of option. */
  name: Scalars['String']
  /** Unique value of option. */
  value: Scalars['String']
}

/** Input and display constraints for custom fields with type TEXT. */
export type CustomFieldTextDisplay = {
  /** Display hint for width of text field in characters. */
  characterWidth: Scalars['Int']
  /** Maximum amount of characters user can enter (inclusive). */
  maxLength: Scalars['Int']
  /** Minimum amount of characters to enter. */
  minLength: Scalars['Int']
  /** Display hint for height of text field in number of lines. */
  numberOfLines: Scalars['Int']
}

/** Enum defining types (and sub-types) of custom fields. */
export enum CustomFieldType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Enumeration = 'ENUMERATION',
  Monetary = 'MONETARY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  PreTrip = 'PRE_TRIP',
  Text = 'TEXT',
  Time = 'TIME',
}

/** Scope indicates when the user is requested to enter custom field values in the shopping flow. */
export enum CustomFieldsScope {
  Checkout = 'CHECKOUT',
  Search = 'SEARCH',
}

/** Defines the possible status's that a users account can be in */
export enum CustomerUberAccountStatus {
  /** Customer's email was not found in any mapped U4B organization, i.e. they have not been registered/onboarded by  their company. */
  NotFound = 'NOT_FOUND',
  /** Customer's email is registered in mapped U4B organization, but customer has not linked their account.They must complete the linking process before they can execute searches/make bookings. */
  NotLinked = 'NOT_LINKED',
  /** Customer's email is registered in mapped U4B organization, and customer has linked their account. Customers with this status are ready to execute searches and make bookings. */
  Ready = 'READY',
  /** The site the customer belongs to has been mapped to an empty list of U4B organizations: this is typically done to indicate their company does not have a U4B organization but we want the user to see the product we are offering. */
  SiteEmptyMapping = 'SITE_EMPTY_MAPPING',
  /** The site the customer belongs to has not been mapped at all, this indicates we do not want the user to even be aware of the Uber products we are offering. */
  SiteNotEnabled = 'SITE_NOT_ENABLED',
  /** An Unknown Error has occurred. */
  Unspecified = 'UNSPECIFIED',
}

/** Successful response to a customerUberAccountStatus query */
export type CustomerUberAccountStatusResponse = {
  /** Provides URL for the customer to confirm their U4B invitation. */
  linkingUrl: Scalars['String']
  /** Provides information on whether the traveller is fully configured and ready for Uber shopping/reservation */
  status: CustomerUberAccountStatus
  /** If the status is NOT_FOUND, and this field is true, the customer can call createCustomerUberAccount to enroll their email address in their company's Uber for Business (U4B) organization. */
  userAccountSelfCreationEnabled: Scalars['Boolean']
}

export type DailyRate = {
  date: Scalars['String']
  rate: Rate
}

export type DateRange = {
  /** ISO 8601 date format end date range. */
  endDate: Scalars['String']
  /** ISO 8601 date format start date range. */
  startDate: Scalars['String']
}

export type DateTimeAdditionalInformation = {
  /** ISO 8601 string w/o time information, if provided. */
  dateValue?: Maybe<Scalars['String']>
  /** Indicates whether the user can specify a particular day of month. */
  displayDay?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular hour. */
  displayHour?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular minute. */
  displayMinute?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular month. */
  displayMonth?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular timezone. */
  displayTimeZone?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user can specify a particular year. */
  displayYear?: Maybe<Scalars['Boolean']>
  /** Indicates whether the end-user can specify a date/time in the past. */
  isAllowedPastDateTime?: Maybe<Scalars['Boolean']>
  /** Latest day of month user can enter (inclusive). */
  maxDay?: Maybe<Scalars['Int']>
  /** Latest month user can enter (inclusive). */
  maxMonth?: Maybe<Scalars['Int']>
  /** Latest year user can enter (inclusive). */
  maxYear?: Maybe<Scalars['Int']>
  /** Earliest day of month user can enter (inclusive). */
  minDay?: Maybe<Scalars['Int']>
  /** Earliest month user can enter (inclusive). */
  minMonth?: Maybe<Scalars['Int']>
  /** Earliest year user can enter (inclusive). */
  minYear?: Maybe<Scalars['Int']>
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type Decode = {
  code?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type DeemHeader = {
  footer: Scalars['String']
  header: Scalars['String']
  headerAssets: Scalars['String']
  metadata?: Maybe<DeemHeaderMetadata>
}

export type DeemHeaderBrandingMetadata = {
  carServiceEnabled: Scalars['Boolean']
  siteName: Scalars['String']
}

export type DeemHeaderMetadata = {
  brandingMetadata: DeemHeaderBrandingMetadata
}

export type Delegate = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  siteId?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['String']>
}

export type DelegatedUsers = {
  delegatedUsers: Array<Delegate>
}

export type DelegatedUsersInput = {
  locale?: InputMaybe<Scalars['String']>
  meetingsModeElements?: InputMaybe<MeetingsModeSessionElements>
}

/** Delegate input details. */
export type DelegatesDetailsInput = {
  /** Delegate email */
  email?: InputMaybe<Scalars['String']>
  /** Delegate first name */
  firstName?: InputMaybe<Scalars['String']>
  /** Delegate id */
  id: Scalars['Float']
  /** Delegate last name */
  lastName?: InputMaybe<Scalars['String']>
}

/** Update of delegates (or authorizers) on the profile . */
export type DelegatesOrAuthorizersInput = {
  list: Array<DelegatesOrAuthorizersInputDetails>
}

/** Delegate input details. */
export type DelegatesOrAuthorizersInputDetails = {
  /** Delegate email */
  email?: InputMaybe<Scalars['String']>
  /** Delegate first name */
  firstName?: InputMaybe<Scalars['String']>
  /** Delegate id */
  id: Scalars['Float']
  /** Delegate last name */
  lastName?: InputMaybe<Scalars['String']>
}

export type DelegatesResponse = {
  delegate: Array<Delegate>
}

export type DeleteCreditCardInput = {
  /** Credit Card ID */
  creditCardId: Scalars['String']
}

export type DeliveryOptions = {
  code: Scalars['String']
  cost?: Maybe<Money>
  name: Scalars['String']
}

export type DepartArriveRangesInput = {
  arrive?: InputMaybe<TimeRangeInput>
  depart?: InputMaybe<TimeRangeInput>
  legPosition?: InputMaybe<Scalars['LegPosition']>
}

export type DesktopSupportDisplayConfiguration = {
  /** Email address */
  email: Scalars['String']
}

export enum Device {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS',
}

export enum DeviceClass {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export type DirectedDropOffLocation = {
  asDirected: Scalars['Boolean']
  asDirectedMultiDay: Scalars['Boolean']
  flight?: Maybe<AirportPickUpLocationFlight>
  geocode?: Maybe<Geocode>
  requestedDuration: Scalars['Int']
  requestedHours: Scalars['Int']
}

export type DisplayConfiguration = {
  /** Contains Apple dsm text message */
  appleDsmMessage?: Maybe<Scalars['String']>
  areRecommendationsEnabled: Scalars['Boolean']
  /** Represents the minimum hours in advance required to book or change a segment or trip. */
  bookAndChangeAdvanceTime?: Maybe<Scalars['Float']>
  carRentals: CarRentalsDisplayConfiguration
  /** Company Resource configurations with id and resource texts to display. */
  companyResourceConfiguration: CompanyResourceConfiguration
  customerUberAccountStatus: CustomerUberAccountStatus
  /** Identifies the vehicle class (or size) used for car rental. For example, "Mini" or "Luxury" */
  defaultCarSize?: Maybe<Scalars['String']>
  /** Display Nearby Airports. Set in the site configuration console */
  enableNearByAirport?: Maybe<Scalars['Boolean']>
  explore: ExploreDisplayConfiguration
  /** External links used to redirect the user. Configured using the Dashboard console. */
  externalLinks: ExternalLinks
  /** Deviation allowed, in hours, from time range specified in flight search. For example 60(min). */
  flightSearchTimeRange?: Maybe<FlightSearchTimeRange>
  /** Deviation allowed (for non US locations), in hours, from time range specified in flight search. For example 60(min). */
  flightSearchTimeRangeNonUS?: Maybe<FlightSearchTimeRange>
  flights: FlightsDisplayConfiguration
  geosureReport: GeosureReport
  /** Do not display unused tickets to users. Set in the site configuration console */
  hideUnusedTickets: Scalars['Boolean']
  hotelSearchRadius: HotelSearchRadius
  /** Defines whether to display front end components associated with hotel functionality. */
  hotels: HotelDisplayConfiguration
  /** Identifies a user who can use admin page functionality */
  isAdminRoleEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates whether most of the settings should be view mode only */
  isAppleLockSettingsEnabled: Scalars['Boolean']
  /** Indicates whether credit card saving functionality is enabled on site level. */
  isCreditCardSavingEnabled: Scalars['Boolean']
  /** Indicates whether the basic fare is to be excluded from search results. */
  isExcludeBasicFareBlock: Scalars['Boolean']
  isExpiringCreditCardForHotelAllowed: Scalars['Boolean']
  isFijiHotelSearchEnabled?: Maybe<Scalars['Boolean']>
  isFlightSearchServiceEnabled: Scalars['Boolean']
  isHoldTripAllowed: Scalars['Boolean']
  /** Indicates whether or not to make double requests for mod2 graphql endpoints */
  isMod2DoubleWriteEnabled: Scalars['Boolean']
  isMod2FlowEnabled: Scalars['Boolean']
  isNewDesktopEnabled: Scalars['Boolean']
  isPerDiemDisplayEnabled: Scalars['Boolean']
  isPhoenixCarRentalSearchEnabled?: Maybe<Scalars['Boolean']>
  isPhoenixHotelSearchEnabled?: Maybe<Scalars['Boolean']>
  isPreBookCostAllocationEnabled: Scalars['Boolean']
  /** Indicates whether the Traveler Acknowledgement modal displays ahead of the end user making a reservation. */
  isShowTravelerAcknowledgement: Scalars['Boolean']
  isSoldOutFilterAvailable?: Maybe<Scalars['Boolean']>
  /** Indicates whether the direct connection is enabled for Southwest flights (SDA). */
  isSouthwestDirectConnectionEnabled: Scalars['Boolean']
  isStarRatingsFilterEnabled: Scalars['Boolean']
  isTripAdvisorReviewsEnabled: Scalars['Boolean']
  mapProvider: MapProvider
  maxNumberOfGuestsPerRoom: Scalars['Int']
  optinoutOfUnusedTicket: Scalars['Boolean']
  privacyPolicy: PrivacyPolicyConfiguration
  /** When Allow Self Delegation is checked, delegates can assign themselves to a user’s account to book travel on their behalf and update their profile (drives behaviour of Add accounts button) */
  selfDelegation?: Maybe<Scalars['Boolean']>
  /** Company's service fee configuration */
  serviceFeeConfiguration: Array<ServiceFeeConfigurationDto>
  /** Provides information for support page */
  support: SupportDisplayConfiguration
  /** When Allow User Delegation is checked, the traveler can assign a delegate to their account who can then book travel for them and update their profile. (drives behaviour of Add Delegate button) */
  userDelegation?: Maybe<Scalars['Boolean']>
}

export type DisplayConfigurationInput = {
  deviceClass?: InputMaybe<DeviceClass>
}

export type DisplaySettings = {
  shouldShowAirLoyaltyPreferences?: Maybe<Scalars['Boolean']>
  shouldShowAirMealPreferences?: Maybe<Scalars['Boolean']>
  shouldShowAirSpecialRequestPreferences?: Maybe<Scalars['Boolean']>
  shouldShowNdcFareAcknowledgment?: Maybe<Scalars['Boolean']>
  shouldShowShareTravelerContact?: Maybe<Scalars['Boolean']>
}

export type Distance = {
  units?: Maybe<Scalars['String']>
  value: Scalars['Float']
}

export type Document360SupportArticle = {
  /** The list of authors who have contributed to the article */
  authors: Array<Document360SupportArticleAuthor>
  /** The ID of the parent category of the article */
  categoryId: Scalars['String']
  /** If the article editor is Markdown, then the article contents will be present in this property */
  content: Scalars['String']
  /** The ID of the user who created the article */
  createdBy: Scalars['String']
  /** Indicates if Right to Left alignment is enabled for the article language */
  enableRtl: Scalars['Boolean']
  /** Indicates if the article is visible on the site */
  hidden: Scalars['Boolean']
  /** If the article editor is WYSIWYG(HTML), then the contents will be present in this property. Note: Markdown editor will also have HTML content, but the contents are readonly. */
  htmlContent: Scalars['String']
  /** The ID of the article */
  id: Scalars['ID']
  /** The latest version number(revision) of this article */
  latestVersion: Scalars['Float']
  /** The position inside the parent category */
  order: Scalars['Float']
  /** The ID of the project version where the article is located */
  projectVersionId: Scalars['String']
  /** The version number that is currently published */
  publicVersion: Scalars['Float']
  /** The title of the article */
  title: Scalars['String']
  /** The currently fetched version number of the article */
  versionNumber: Scalars['Float']
}

export type Document360SupportArticleAuthor = {
  /** Author unique customer identifier */
  customerId: Scalars['String']
  /** Author email */
  emailId: Scalars['String']
  /** Author first name */
  firstName: Scalars['String']
  /** Author unique identifier */
  id: Scalars['ID']
  /** Boolean variable to identify if Author is exist in this project */
  isUserExistinCurrentProject: Scalars['Boolean']
  /** Author last name */
  lastName: Scalars['String']
  /** Author profile logo cdn url */
  profileLogoCdnUrl: Scalars['String']
  /** Author profile logo url */
  profileLogoUrl: Scalars['String']
  /** Author nickname */
  uniqueUserName: Scalars['String']
}

export type Document360SupportArticleByIdInput = {
  /** Unique identifier for the Article in Document360. */
  id: Scalars['String']
}

export enum Domain {
  Air = 'AIR',
  CarRental = 'CAR_RENTAL',
  Hotel = 'HOTEL',
  Rail = 'RAIL',
  Unspecified = 'UNSPECIFIED',
}

export type DomainCostSummary = {
  /** Indicates the Domain of the cost summary. */
  domain: Domain
  /** Estimated total amount to be charged, inclusive of taxes and surcharges, across all intents for the domain. */
  totalCost: Rate
  /** Total cost w/o taxes and fees, across all intents for the domain. */
  totalRate: Rate
  /** Total amount of taxes and fees to be charged, across all intents for the domain. */
  totalTaxes: Rate
}

export type DomainCostSummaryUnion = CarRentalReservationsCostSummary | HotelReservationsCostSummary

/** Union of domain specific objects. */
export type DomainDetailsUnion = CarRental | FlightDetails | FlightTripResult | HotelRoomDetails

export type Duration = {
  hours?: Maybe<Scalars['Int']>
  minutes?: Maybe<Scalars['Int']>
}

export type DynamicSiteMessage = {
  /** Indicates if the message has a banner image. Based on the width of <img /> tags this value should be more than or equal to 600px. */
  hasDesktopBannerImage?: Maybe<Scalars['Boolean']>
  header: Scalars['String']
  isDialog: Scalars['Boolean']
  mobileText?: Maybe<Scalars['String']>
  text: Scalars['String']
}

/** Provides the input parameters for the DMS query. */
export type DynamicSiteMessagesInput = {
  /** Unique identifier for the cart. */
  cartId: Scalars['ID']
  /** Identifies the page on which dynamic site messages in the context of a reservation are displayed. */
  targetPageType: TargetPageType
}

/** DynamicSiteMessagesResponse is returned when the dynamicSiteMessages query succeeds. */
export type DynamicSiteMessagesResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Contains the message retrieved along with the configuration required to display this message to specific users. */
  dynamicSiteMessages: Array<DynamicSiteMessage>
}

/** Union of different response objects that can be returned by dynamicSiteMessages query. Returns known/expected errors in a structured manner to the client. */
export type DynamicSiteMessagesResponseUnion = CartNotFoundResponse | DynamicSiteMessagesResponse

export enum ETicketType {
  AppleWallet = 'APPLE_WALLET',
  Pdf = 'PDF',
  QrCode = 'QR_CODE',
  Unspecified = 'UNSPECIFIED',
}

export type EditCreditCardInput = {
  billingAddress?: InputMaybe<CreditCardBillingAddressInput>
  creditCardData: CreditCardInput
  creditCardId: Scalars['String']
}

export type EmailSupport = {
  /** Email address */
  emailAddress: Scalars['String']
  /** Label for email address */
  label?: Maybe<Scalars['String']>
}

/** Emergency contact (EC) information, typically provided by the user directly. All of its fields are optional */
export type EmergencyContact = {
  /** Postal address of the EC person */
  address?: Maybe<PersonalContactAddress>
  /** Alternate/backup phone to use when contacting the EC person */
  alternatePhone?: Maybe<PersonalContactPhone>
  /** Email address of the EC person */
  email?: Maybe<Scalars['String']>
  /** Full name of the EC person */
  name?: Maybe<Scalars['String']>
  /** Primary phone to use when contacting the EC person */
  primaryPhone?: Maybe<PersonalContactPhone>
  /** Relationship of the EC person to the user */
  relationship?: Maybe<Scalars['String']>
}

export type EmergencyContactInput = {
  address?: InputMaybe<PersonalAddress>
  alternatePhone?: InputMaybe<PersonalPhone>
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  primaryPhone?: InputMaybe<PersonalPhone>
  relationship?: InputMaybe<Scalars['String']>
}

/** Provides the details of the employee field of the user's profile */
export type Employee = {
  /** Business unit of the user */
  businessUnit?: Maybe<Scalars['String']>
  /** User's company name */
  companyName?: Maybe<Scalars['String']>
  /** Cost center of the user */
  costCenter?: Maybe<Scalars['String']>
  /** ID of the user's default approver */
  defaultApproverId?: Maybe<Scalars['Float']>
  /** Department code of the user */
  departmentCode?: Maybe<Scalars['String']>
  /** Department name of the user */
  departmentName?: Maybe<Scalars['String']>
  /** Division of the user */
  division?: Maybe<Scalars['String']>
  /** Employee ID of the user */
  employeeId?: Maybe<Scalars['String']>
  /** Employee type of the user */
  employeeType?: Maybe<Scalars['String']>
  /** Indicates whether the user is active in company */
  isActiveInCompany?: Maybe<Scalars['Boolean']>
  /** Indicates whether the user is a VIP */
  isVip?: Maybe<Scalars['Boolean']>
  /** Job level of the user */
  jobLevel?: Maybe<Scalars['String']>
  /** Job title of the user */
  jobTitle?: Maybe<Scalars['String']>
  /** Manager of the user */
  manager?: Maybe<Manager>
  /**
   * ID of the user's manager
   * @deprecated Use manager.id instead
   */
  managerId?: Maybe<Scalars['String']>
}

/** Provides the input parameters for the profile employee field */
export type EmployeeInput = {
  /** Business unit of the user */
  businessUnit?: InputMaybe<Scalars['String']>
  /** User's company name */
  companyName?: InputMaybe<Scalars['String']>
  /** Cost center of the user */
  costCenter?: InputMaybe<Scalars['String']>
  /** ID of the user's default approver */
  defaultApproverId?: InputMaybe<Scalars['Float']>
  /** Department code of the user */
  departmentCode?: InputMaybe<Scalars['String']>
  /** Department name of the user */
  departmentName?: InputMaybe<Scalars['String']>
  /** Division of the user */
  division?: InputMaybe<Scalars['String']>
  /** Employee ID of the user */
  employeeId?: InputMaybe<Scalars['String']>
  /** Employee type of the user */
  employeeType?: InputMaybe<Scalars['String']>
  /** Indicates whether the user is active in company */
  isActiveInCompany?: InputMaybe<Scalars['Boolean']>
  /** Indicates whether the user is a VIP */
  isVip?: InputMaybe<Scalars['Boolean']>
  /** Job level of the user */
  jobLevel?: InputMaybe<Scalars['String']>
  /** Job title of the user */
  jobTitle?: InputMaybe<Scalars['String']>
  /** Manager of the user */
  manager?: InputMaybe<ManagerInput>
  /** ID of the user's manager */
  managerId?: InputMaybe<Scalars['String']>
}

/** Provides the response object for the Enhanced Seat Map query which contains information about the aircraft layout, in terms of the cabins, sections, wings, seat rows and columns, and other aircraft amenities */
export type EnhancedSeatMap = {
  /** The array of cabins that are associated with this seat map. */
  cabins: Array<EnhancedSeatMapCabin>
  /** The carrier details associated with this seat map, as provided in the request. */
  carrier: FlightCarrier
  /** The departure date, as provided in the request. */
  departureDate: Scalars['Date']
  /** The destination airport code, as provided in the request. */
  destination: Scalars['AirportCode']
  /** The flight number for which this seat map is being presented, as provided in the request. */
  flightNumber: Scalars['Int']
  /** The origin airport code, as provided in the request. */
  origin: Scalars['AirportCode']
  /** Defines if this seat map is a read-only seat map, or not. */
  readOnly: Scalars['Boolean']
}

/** Defines the aircraft cabin and its related attributes, for example rows, cabin class, etc. */
export type EnhancedSeatMapCabin = {
  /** The cabin class type. E.g. Coach, Business, etc. */
  cabinClass: EnhancedSeatMapCabinClass
  /** When present, it represents the over the wing row offsets for this cabin, which can help depict the wings, accurately, in the seat map. */
  overWingRows?: Maybe<EnhancedSeatMapRowOffset>
  /** The array of rows that are available in this cabin. */
  rows: Array<EnhancedSeatMapRow>
  /** When present, it represents the various sections that apply to this cabin. */
  sections?: Maybe<Array<EnhancedSeatMapRowOffset>>
}

/** Defines the different type of cabin class that can exist in the seat map. */
export enum EnhancedSeatMapCabinClass {
  Business = 'BUSINESS',
  Coach = 'COACH',
  First = 'FIRST',
  PremiumCoach = 'PREMIUM_COACH',
}

/** Provides the input parameters for the Enhanced Seat Map query. */
export type EnhancedSeatMapInput = {
  /** One character, airline defined code, that identifies the reservation booking designator for the class of service. E.g. Y, J, E, X, etc. */
  bookingCode: Scalars['String']
  /** The two character airline code. E.g. AA, DL, LH, BA. */
  carrierCode: Scalars['String']
  /** The departure date of the flight. */
  departureDate: Scalars['Date']
  /** The three character destination airport code. E.g. JFK, BRU, BLR, LHR, DXB, YYZ. */
  destination: Scalars['String']
  /** The code that identifies the type of aircraft in use. E.g. 738, 73C, 388, A58. */
  equipmentCode?: InputMaybe<Scalars['String']>
  /** An alphanumeric code which identifies a fare basis code (FBC) for the fare that interests the user. This is used to request a more focussed seat map response. E.g. A FBC looks something like this "YNA0A1LT". */
  fareBasisCode?: InputMaybe<Scalars['String']>
  /** The flight number for which the seat maps are being requested. */
  flightNumber: Scalars['Int']
  /** True for NDC requests */
  isNdc?: InputMaybe<Scalars['Boolean']>
  /** Identifies the metadata for logging. */
  logMetadata?: InputMaybe<LogsMetadata>
  /** The three character origin airport code. E.g. JFK, BRU, BLR, LHR, DXB, YYZ. */
  origin: Scalars['String']
  /** Opaque String With Provider Attributes */
  providerAttributesStr?: InputMaybe<Scalars['String']>
}

/** Defines the price for a seat. */
export type EnhancedSeatMapPrice = {
  /** When present, identifies a unique identifier by which this price can be identified in the system of source. This, and the name, would mostly go together. */
  id?: Maybe<Scalars['String']>
  /** When present, provides an airline friendly name to this seat price. E.g. Summer Sale, etc. Also, the id attribute would be present when we have a name associated to the price since these two reflect the values that are coming from the system of source. */
  name?: Maybe<Scalars['String']>
  /** The array of passenger ids to which this price applies. */
  passengers: Array<Scalars['String']>
  /** @deprecated use rate instead. */
  price: Money
  /** Identifies the pricing of this seat. */
  rate: Rate
}

/** Defines a row of seats in a cabin. */
export type EnhancedSeatMapRow = {
  /** The row number. E.g. 10, 15, etc. */
  number: Scalars['Int']
  /** The array of seats that apply to this row. */
  seat: Array<EnhancedSeatMapSeat>
}

/** A generic type that provides the row offsets, i.e. first and last row numbers, which can be associated to a seat map attribute. E.g. Cabin Sections. */
export type EnhancedSeatMapRowOffset = {
  /** The first row for this offset. */
  first: Scalars['Int']
  /** The last row for this offset. */
  last: Scalars['Int']
}

/** Defines the seat that applies to a row, and a cabin. This definition may or may not refer to a physical seat, i.e., it  can represent the space around a particular aircraft amenity, like lavatory, or galley, or stairs to the upper deck, or exit door, etc. See the type attribute for more. */
export type EnhancedSeatMapSeat = {
  /** Defines if this seat is available, and not occupied. */
  available: Scalars['Boolean']
  /** The column of the cabin in which this seat is located. E.g. A, B, C, etc. */
  column: Scalars['String']
  /** Identifies whether or not this seat is at the bulkhead, i.e., a partition separating the cabins, or a certain section of the cabin etc. */
  hasBulkhead: Scalars['Boolean']
  /** Identifies if this seat is near, or next to a galley or not. */
  hasGalley: Scalars['Boolean']
  /** Identifies if this seat is near, or next to a lavatory or not. */
  hasLavatory: Scalars['Boolean']
  /** Identifies if this seat is an exit row seat or not. */
  isExitRowSeat: Scalars['Boolean']
  /** Identifies if this seat is entitled for the passenger based on their frequent flyer status or not. It may happen that certain seats may become available for selection based on Frequent Flyer status, and this attribute helps in identifying that. For seats that have this true, the price would be 0 value. */
  isFrequentFlyerEntitled: Scalars['Boolean']
  /** Identifies if this seat is an aisle seat or not. */
  isNearAisle: Scalars['Boolean']
  /** Identifies if this seat is a window seat or not. */
  isNearWindow: Scalars['Boolean']
  /** Identifies if this seat is over the wing or not. */
  isOverWing: Scalars['Boolean']
  /** Identifies if this seat is a paid seat or not. */
  isPaid: Scalars['Boolean']
  /** Identifies if this seat is a preferred seat offering by the carrier, or not., and most often than not, unless covered by the travelers frequent flyer status, etc., would attract a fee. */
  isPreferred: Scalars['Boolean']
  /** Identifies if this seat is a premium seat offering or not. As an example, a premium seat in Coach class can be considered as those that are a "Plus" type of offerings, like "Economy Plus", etc., and would most likely attract a fee for selection, unless covered by the travelers frequent flyer status. */
  isPremium: Scalars['Boolean']
  /** Identifies if this smoking is allowed on this. */
  isSmokingAllowed: Scalars['Boolean']
  /** Identifies if this seat is suitable for handicapped passengers or not. */
  isSuitableForHandicapped: Scalars['Boolean']
  /** Identifies if this seat is on the upper deck or not. */
  isUpperDeck: Scalars['Boolean']
  /** The physical seat number. E.g. 1A, 29C, etc. Its a combination of the row number (numeric), and the column number (alpha) */
  number: Scalars['String']
  /** When present, this gives the array of prices that apply to this seat. */
  price?: Maybe<Array<EnhancedSeatMapPrice>>
  /** Defines if this seat is eligible for selection. A seat which is paid will not be selectable, but a paid seat that has a zero value price, will be eligible. All seats that are not paid, and available, are selectable by default. */
  selectable: Scalars['Boolean']
  /** Identifies the type of seat. E.g. Seat, Galley, Exit Door, etc. */
  type: EnhancedSeatMapSeatType
}

/** Defines all the different types of seats that are possible in a seat map. It would look as a misnomer, i.e. how can the seat have types, but the seat maps are structured in a way where a seat may or may not be a seat, and this enum provides a way to mark a seat of that type. This provides the clients to determine what to be displayed in the UX, a seat or, say, a closet. */
export enum EnhancedSeatMapSeatType {
  AirPhone = 'AIR_PHONE',
  Bar = 'BAR',
  Bulkhead = 'BULKHEAD',
  Closet = 'CLOSET',
  EmergencyExit = 'EMERGENCY_EXIT',
  Exit = 'EXIT',
  Galley = 'GALLEY',
  Lavatory = 'LAVATORY',
  Seat = 'SEAT',
  Stairs = 'STAIRS',
  Storage = 'STORAGE',
  Table = 'TABLE',
}

export type Error = {
  message: Scalars['String']
}

export enum ErrorType {
  Empty = 'Empty',
  InvalidDate = 'InvalidDate',
}

export type ExistingOutboundFlightSegmentInput = {
  airlineCode?: InputMaybe<Scalars['String']>
  arrivalDateTime?: InputMaybe<Scalars['String']>
  departureDateTime?: InputMaybe<Scalars['String']>
  destinationAirportCode?: InputMaybe<Scalars['String']>
  fareBasisCode?: InputMaybe<Scalars['String']>
  flightNumber?: InputMaybe<Scalars['Float']>
  originAirportCode?: InputMaybe<Scalars['String']>
  serviceClass?: InputMaybe<Scalars['String']>
}

export type ExploreDisplayConfiguration = {
  isCarRentalsSearchEnabled: Scalars['Boolean']
  isFlightsSearchEnabled: Scalars['Boolean']
  isHotelsSearchEnabled: Scalars['Boolean']
  isTrainsSearchEnabled: Scalars['Boolean']
  message?: Maybe<SearchMessage>
  messages: Array<DynamicSiteMessage>
  searchDefaultsAirEnabled: Scalars['Boolean']
  searchDefaultsCarEnabled: Scalars['Boolean']
  searchDefaultsHotelEnabled: Scalars['Boolean']
}

export type ExternalLink = {
  /** External link label. */
  label: Scalars['String']
  /** External link url. */
  url: Scalars['String']
}

export type ExternalLinks = {
  /** Indicates whether the external link is active. */
  isExternalLinksEnabled: Scalars['Boolean']
  /** Array of external links which contains a url and a label to display. */
  links: Array<ExternalLink>
}

export type ExtraCharge = {
  perDayCharge?: Maybe<Rate>
  perDayRate?: Maybe<Rate>
  perHourCharge?: Maybe<Rate>
}

export type FailedBooking = {
  hotelIds?: InputMaybe<Array<Scalars['String']>>
  rooms: Array<FailedRooms>
}

export type FailedRooms = {
  hotelId: Scalars['String']
  providerId: Scalars['String']
  rateCategory?: InputMaybe<Scalars['String']>
  rateCode: Scalars['String']
}

/** Lowest priced per-passenger fare for a given rail leg, fare tier and service class combination. */
export type Fare = {
  /** List of rail cards to be applied to fares returned in the current search. */
  appliedRailCards?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Fare description */
  description?: Maybe<Scalars['String']>
  /**
   * Preferred type of rail fare. Search results will focus on the requested fare type when available.
   * The fares (or) total price should be displayed based on the type of fare.
   */
  fareType: FareType
  /** Unique identifier for fare. */
  id: Scalars['String']
  /** Provides the validity of return journey for open return fares. */
  returnValidity?: Maybe<Scalars['DateTime']>
  /** Per passenger fare inclusive of all fees. This value represents the price for a rail leg. */
  totalPrice: RailMoney
  /**
   * Per passenger fare in user preferred currency inclusive of all fees.
   * This value represents the price for a rail leg in user preferred currency.
   */
  totalPriceInPreferredCurrency?: Maybe<RailMoney>
  /** Per passenger total tax amount. This value represents the tax for a rail leg. */
  totalTax: RailMoney
  /**
   * Per passenger total tax amount in user preferred currency.
   * This value represents the tax for a rail leg in user preferred currency.
   */
  totalTaxInPreferredCurrency?: Maybe<RailMoney>
}

export type FareAttributes = {
  comparison: FlightComparison
  content: Array<OnTheFlyContent>
  fareId: Scalars['String']
}

export type FareConditions = {
  /** @deprecated use from LocalizedFareConditions */
  conditions: Array<Conditions>
  localizedFareConditions: Array<LocalizedFareConditions>
  /** @deprecated use from LocalizedFareConditions */
  summaryConditions: Scalars['String']
  tier: RailFareTier
  /** @deprecated use from LocalizedFareConditions */
  validityPeriod: Scalars['String']
}

export type FareConditionsResult = {
  fareConditions: Array<FareConditions>
}

/** The fare information represents an airline fare and its associated fare components for a trip. */
export type FareInfo = {
  /** Surcharge amount, in sale currency, added to total fare if opting for the ability to check in to the flight at the airport instead of online. */
  airportCheckinSurcharge?: Maybe<Rate>
  /** Per passenger airline "base" fare before taxes applied and pricing currency code. */
  baseFare: Rate
  /** The fare cancel fee. Presented when cancel fee is applied. */
  cancelFee?: Maybe<Rate>
  /** The fare change fee. Presented when change fee is applied. */
  changeFee?: Maybe<Rate>
  /** Amount charged, in sale currency, for the number of declared checked bags. */
  checkedBaggageSurcharge?: Maybe<Rate>
  /** Surcharge amount, in sale currency, added to the total fare when paying by a credit card. */
  creditCardSurcharge?: Maybe<Rate>
  /** Surcharge amount, in sale currency, added to total fare if opting for the ability to check in to the flight online. */
  onlineCheckinSurcharge?: Maybe<Rate>
  /** Surcharge amount, in sale currency, for selecting the speedy/early boarding option. */
  speedyBoardingSurcharge?: Maybe<Rate>
  /** Total monetary amount of the fare and sales currency code. */
  totalFare: Rate
  /** Total amount of the taxes. */
  totalTaxes: Rate
}

export type FareLabel = {
  text?: Maybe<Scalars['String']>
  tooltip?: Maybe<Scalars['String']>
}

export enum FareRestrictions {
  FareRestricted = 'FARE_RESTRICTED',
  FareRestrictionUnknown = 'FARE_RESTRICTION_UNKNOWN',
  FareUnrestricted = 'FARE_UNRESTRICTED',
}

export type FareTaxInfo = {
  amount: Rate
  code: Scalars['String']
  name: Scalars['String']
}

export type FareTaxItemDto = {
  taxAmount?: Maybe<Rate>
  taxCode?: Maybe<Scalars['String']>
  taxLabel?: Maybe<Scalars['String']>
}

export enum FareTier {
  Restricted = 'RESTRICTED',
  Restricted_2 = 'RESTRICTED_2',
  RestrictedExpectFees = 'RESTRICTED_EXPECT_FEES',
  RestrictedExpectFees_2 = 'RESTRICTED_EXPECT_FEES_2',
  RestrictedExpectFees_3 = 'RESTRICTED_EXPECT_FEES_3',
  RestrictedExpectFees_4 = 'RESTRICTED_EXPECT_FEES_4',
  Unmodifiable = 'UNMODIFIABLE',
  Unrestricted = 'UNRESTRICTED',
}

export type FareTierFilter = {
  displayName: Scalars['String']
  id: Scalars['Int']
  value: FareTier
}

/** Preferred type of rail fare. */
export enum FareType {
  /** Rail fare type 1st Class. */
  FareTypeFirstClass = 'FARE_TYPE_FIRST_CLASS',
  /** Rail fare type standard. */
  FareTypeStandard = 'FARE_TYPE_STANDARD',
  /** Unspecified. */
  FareTypeUnspecified = 'FARE_TYPE_UNSPECIFIED',
}

export type FaresFlightsDisplayConfiguration = {
  areRefundableEnabled: Scalars['Boolean']
  areUnrestrictedEnabled: Scalars['Boolean']
  defaultFareType: FlightFareType
}

export type FeatureFlags = {
  /** Enable activate account modal */
  isActivateAccountFlowEnabled: Scalars['Boolean']
  /** Defines whether to prompt user to start a new itinerary or keep active one when creating a new search */
  isActiveItineraryPurchaseFlowEnabled: Scalars['Boolean']
  isAddTrainToItineraryEnabled: Scalars['Boolean']
  /** Defines whether or not to show alerts */
  isAlertsEnabled: Scalars['Boolean']
  /** Defines whether or not to show App Store Review alert. */
  isAppStoreReviewAlertEnabled: Scalars['Boolean']
  /** Defines whether or not to show Apple's banner */
  isAppleBannerEnabled: Scalars['Boolean']
  isAppleMapProviderEnabled: Scalars['Boolean']
  /** Enable book again delegate flow */
  isBookAgainDelegateFlowEnabled: Scalars['Boolean']
  /** Indicates if book trip again feature is enabled. */
  isBookAgainEnabled: Scalars['Boolean']
  /** Defines whether or not to send travel preferences on BookItinerary query and use instead of TPs saved in profile */
  isBookItineraryTravelPreferencesEnabled: Scalars['Boolean']
  /** @deprecated No longer required on frontend. Car rental safety should be visible in all instances */
  isCarRentalSafetyCheckEnabled: Scalars['Boolean']
  /** Defines whether to show new filters layout for car rentals or not */
  isCarRentalsNewFiltersEnabled: Scalars['Boolean']
  /** Indicates if changing return flight is enabled */
  isChangingReturnFlightEnabled: Scalars['Boolean']
  /** Defines whether to use checkout flow on checkout page or not */
  isCheckoutFlowEnabled: Scalars['Boolean']
  /** Defines whether or not user is redirected to custom url on logout */
  isCustomLogoutRedirectEnabled: Scalars['Boolean']
  /** Indicates if deem logo enabled. */
  isDeemLogoEnabled: Scalars['Boolean']
  isDelegatedFlowEnabled: Scalars['Boolean']
  isDesktopDelegatedFlowEnabled: Scalars['Boolean']
  isDesktopFlightDetailsSeatMapEnabled: Scalars['Boolean']
  isDesktopHeldTripsFlowEnabled: Scalars['Boolean']
  isDesktopLayoutFlightFlowEnabled: Scalars['Boolean']
  isDesktopLayoutHotelFlowEnabled: Scalars['Boolean']
  isDesktopLayoutMultiCityEnabled: Scalars['Boolean']
  isDesktopLayoutPurchaseFlowEnabled: Scalars['Boolean']
  isDesktopLayoutRTPFlowEnabled: Scalars['Boolean']
  isDesktopLayoutTrainTabEnabled: Scalars['Boolean']
  isDesktopLayoutTripsFlowEnabled: Scalars['Boolean']
  isDesktopPostBookingSeatMapEnabled: Scalars['Boolean']
  isDesktopTripCartEnabled: Scalars['Boolean']
  isDesktopUnusedTicketsEnabled: Scalars['Boolean']
  isDesktopUnusedTicketsOptOutEnabled: Scalars['Boolean']
  isEttaAutoTranslationEnabled: Scalars['Boolean']
  /** Defines wheter to show external links to the user. */
  isExternalLinksEnabled: Scalars['Boolean']
  isFeedbackEnabled: Scalars['Boolean']
  isFijiPurchaseFixEnabled: Scalars['Boolean']
  isFlightSafetyCheckEnabled: Scalars['Boolean']
  /** Enable Google Places API cache services */
  isGooglePlacesAPICacheEnabled: Scalars['Boolean']
  /** @deprecated Will be removed soon */
  isGraphQLBookingForIOSEnabled: Scalars['Boolean']
  isHotelRateCapEnabled: Scalars['Boolean']
  /** Indicates whether the hotel retry flow is enabled */
  isHotelRetryWorkflowEnabled: Scalars['Boolean']
  isHotelSafetyCheckEnabled: Scalars['Boolean']
  /** Enable KTN and Redess feature */
  isKTNRedessEnabled: Scalars['Boolean']
  isKeyboardNavigationStylesEnabled: Scalars['Boolean']
  isMobileHeldTripsFlowEnabled: Scalars['Boolean']
  /** Specifies whether new Mobility book tab is enabled. */
  isMobilityBookTabEnabled: Scalars['Boolean']
  /** Specifies whether Mobility functionality is enabled for those using Etta via PWA desktop. */
  isMobilityDesktopEnabled: Scalars['Boolean']
  /** Specifies whether the expense flow is enabled. Will be removed in MAAS-1014. */
  isMobilityExpenseEnabled: Scalars['Boolean']
  /** Defines whether the Multi-Modal functionality is enabled for the current Mobility ride hail booking flow. By default this is set to false. */
  isMobilityMultiModalEnabled: Scalars['Boolean']
  /** Specifies whether the mobility on demand rides flow is enabled. Will be removed in MAAS-1165. */
  isMobilityOnDemandRidesEnabled: Scalars['Boolean']
  /** Specifies whether new Mobility search form version 3 is enabled. */
  isMobilitySearchFormV3Enabled: Scalars['Boolean']
  /** Identifies whether or not whitelisted users can specify pick-up and drop-off zones in the current ride hail booking. By default this is set to false. */
  isMobilityZoningEnabled: Scalars['Boolean']
  isMultiCityEnabled: Scalars['Boolean']
  /** New layout for air exposed filters */
  isNewAirExposedFiltersEnabled: Scalars['Boolean']
  /** New air fares layout */
  isNewAirFaresLayoutEnabled: Scalars['Boolean']
  /** New layout for air filters */
  isNewAirFiltersModalEnabled: Scalars['Boolean']
  /** Enable new Deem brand */
  isNewBrandEnabled: Scalars['Boolean']
  /** Defines whether to show new checkout page layout or not */
  isNewCheckoutPageLayoutEnabled: Scalars['Boolean']
  /** Enable new emissions branding UI changes for desktop and mobile layouts */
  isNewEmissionsBrandingEnabled: Scalars['Boolean']
  /** Enable only New Etta and disables toggle from New to Classic Etta */
  isNewEttaOnlyEnabled: Scalars['Boolean']
  /** Enable new Help Center link in the support page */
  isNewHelpCenterLinkEnabled: Scalars['Boolean']
  /** New layout for hotel exposed filters */
  isNewHotelExposedFiltersEnabled: Scalars['Boolean']
  /** New layout for hotel filters */
  isNewHotelFiltersModalEnabled: Scalars['Boolean']
  /** Enable new desktop layout for the confirmation loading page */
  isNewLoadingConfirmationEnabled: Scalars['Boolean']
  /** Defines whether to show new seat map for flight details modal or not */
  isNewSeatMapEnabled: Scalars['Boolean']
  /** Enbale new desktop and mobile layout for the trip details page */
  isNewTripDetailsPageEnabled: Scalars['Boolean']
  /** Enable Out Of Policy Modal */
  isOOPModalEnabled: Scalars['Boolean']
  /** Enable Out Of Policy Alert modal on search result flight page */
  isOOPModalOnSearchResultFlightPageEnabled: Scalars['Boolean']
  /** Defines whether or not user can put a trip on hold. */
  isOnHoldEnabled: Scalars['Boolean']
  /** Enable PWA cookie consent */
  isPWACookieConsentEnabled: Scalars['Boolean']
  isPartnerCoBrandingEnabled: Scalars['Boolean']
  isPasswordExpirationFlowEnabled: Scalars['Boolean']
  /** Enable custom fields display and edit in profile */
  isProfileCustomFieldsEnabled: Scalars['Boolean']
  /** Enable Qualtrics */
  isQualtricsEnabled: Scalars['Boolean']
  isRailAccreditationEnabled: Scalars['Boolean']
  isRailCardSelectionEnabled: Scalars['Boolean']
  isRailOutofPolicyEnabled: Scalars['Boolean']
  isRailSearchFiltersEnabled: Scalars['Boolean']
  isRailSearchSortEnabled: Scalars['Boolean']
  isRailServiceEnabled: Scalars['Boolean']
  isRecentSearchesEnabled: Scalars['Boolean']
  /** Defines whether to enable recent searches filtering in search input dropdown */
  isRecentSearchesFilteringEnabled: Scalars['Boolean']
  /** Defines whether or not redirecting user to websites`s domain after choose site in login flow. */
  isRedirectOnSiteDomainEnabled: Scalars['Boolean']
  isReserveAirportPickupEnabled: Scalars['Boolean']
  /** Enable retail shopping ui */
  isRetailShoppingUIEnabled: Scalars['Boolean']
  /** Enable rules of acknowledgement required */
  isRulesAcknowledgementRequired: Scalars['Boolean']
  isSSOEnabled: Scalars['Boolean']
  isSafetyCheckEnabled: Scalars['Boolean']
  /** Defines if user will be able to save credit card to profile or just add it for single use. */
  isSavingCreditCardEnabled: Scalars['Boolean']
  /** Enable service fee UI for both mobile and desktop. */
  isServiceFeeEnabled: Scalars['Boolean']
  /** Defines whether or not Information Section on Settings Page should be rendered. */
  isSettingsPageInformationSectionEnabled: Scalars['Boolean']
  /** Defines whether or not Organization Section on Settings Page should be rendered. */
  isSettingsPageOrganizationSectionEnabled: Scalars['Boolean']
  /** Defines whether or not Settings Section on Settings Page should be rendered. */
  isSettingsPageSettingsSectionEnabled: Scalars['Boolean']
  isSplitCostAllocationEnabled: Scalars['Boolean']
  /** Defines whether or not to use SSO settings from Gazoo */
  isSsoSettingsEnabled: Scalars['Boolean']
  isSustainabilityEnabled: Scalars['Boolean']
  isToggleEttaThemeEnabled: Scalars['Boolean']
  /** Defines whether or not to allow edit traveler information */
  isTravelerEditingEnabled: Scalars['Boolean']
  /** Indicates if trip alignment workflow enabled */
  isTripAlignmentWorkflowEnabled: Scalars['Boolean']
  /**
   * Defines whether or not the Trip Expired date (in UTC format, received from Gazoo) is used. By default this is set to false, and a non-UTC Gazoo date is used.
   * @deprecated The flag no need anymore because the problem was fixed in LUA-120 ticket
   */
  isTripExpiredDateGeneratedByUTCEnable: Scalars['Boolean']
  isUberEnabled: Scalars['Boolean']
  /** Enbale unlimited cost allocation */
  isUnlimitedCostAllocationEnabled: Scalars['Boolean']
  isUnusedTicketsEnabled: Scalars['Boolean']
  isUnusedTicketsOptOutEnabled: Scalars['Boolean']
  /** Defines whether to show unvalidated unused tickets or not */
  isUnusedTicketsUnvalidatedEnabled: Scalars['Boolean']
  /** Enable user activation flow */
  isUserActivationEnabled: Scalars['Boolean']
  /** Enable voice search feature */
  isVoiceSearchEnabled: Scalars['Boolean']
  isWalkMeEttaEnabled: Scalars['Boolean']
  isWhatFixPWAEnabled: Scalars['Boolean']
  /** @deprecated No longer required on frontend */
  partnerName: Scalars['String']
}

export type FeatureFlagsInput = {
  companyId?: InputMaybe<Scalars['Int']>
}

/** Fee contains fee info for journey */
export type Fee = {
  /** Amount being charged, in preferred currency. Rate includes the fee charged, and any currency conversion involved. */
  cost?: Maybe<Rate>
  /** Fee name, for example booking-fee */
  name?: Maybe<Scalars['String']>
}

/** Information about a field that didn't pass the validation */
export type FieldError = {
  /** Error type, e.g. "Input is required" */
  errorType: ErrorType
  /** A message briefly explaining the problem. Only meant for developers */
  message: Scalars['String']
  /** Field type, e.g. "country" */
  type: FieldType
}

export enum FieldSettingsListDefaultValue {
  Asterisk = 'ASTERISK',
  First = 'FIRST',
  NoValue = 'NO_VALUE',
}

export enum FieldSettingsType {
  List = 'LIST',
  Text = 'TEXT',
}

export enum FieldType {
  Country = 'Country',
  ExpirationDate = 'ExpirationDate',
  IssueDate = 'IssueDate',
  IssuingCountry = 'IssuingCountry',
  Number = 'Number',
}

export type FlightAmenity = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type FlightBaggageFee = {
  baggageCount?: Maybe<BaggageAllowanceCount>
  baggageSize?: Maybe<BaggageAllowance>
  baggageWeight?: Maybe<BaggageAllowance>
  rate: Rate
  type: BaggageType
}

/** Flight cabin class parameters used in a flight search. */
export enum FlightCabinClassVoiceSearch {
  /** Business class flight. */
  Business = 'Business',
  /** Economy class flight. */
  Economy = 'Economy',
  /** First class flight. */
  First = 'First',
  /** Premium economy class flight. */
  PremiumEconomy = 'PremiumEconomy',
}

/** Maintains flight cabin data and custom label information. */
export type FlightCabinInfo = {
  /** Flight cabin class information to be displayed in the cart during a flight search. */
  cabinType: CabinType
  /** Text configured in the Dashboard console to be displayed in the cart during a flight search. */
  customLabel: Scalars['String']
}

export type FlightCarrier = {
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  logoImageUrl?: Maybe<Scalars['String']>
  logoRectangleImageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type FlightCarrierInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type FlightChangeBehavior = {
  allowChangeAirports?: Maybe<Scalars['Boolean']>
  allowChangeCabin?: Maybe<Scalars['Boolean']>
  allowChangeCarrier?: Maybe<Scalars['Boolean']>
  allowChangeFareType?: Maybe<Scalars['Boolean']>
  allowedSearchEndDate?: Maybe<Scalars['String']>
  allowedSearchStartDate?: Maybe<Scalars['String']>
}

export type FlightChangeCancelRules = {
  allowCancel?: Maybe<Scalars['Boolean']>
  allowChange?: Maybe<Scalars['Boolean']>
  allowChangeCarrier?: Maybe<Scalars['Boolean']>
  allowChangeDestination?: Maybe<Scalars['Boolean']>
  cancelMessage?: Maybe<Array<MessageDescription>>
  flightChangeRules?: Maybe<Array<FlightChangeRules>>
  needCancelAcknowledgement?: Maybe<Scalars['Boolean']>
}

export type FlightChangeRules = {
  changeType?: Maybe<ChangeType>
  flightChangeBehavior?: Maybe<FlightChangeBehavior>
}

export type FlightComparison = {
  brand: FlightComparisonBrand
  utas: Array<UniversalTicketAttribute>
}

export type FlightComparisonBrand = {
  codes: Array<Scalars['String']>
  name: Scalars['String']
}

export type FlightCostSummary = {
  flightList: Array<FlightCostSummaryItem>
  total?: Maybe<Rate>
}

export type FlightCostSummaryItem = {
  cost?: Maybe<Rate>
  destination?: Maybe<Scalars['String']>
  isPaid: Scalars['Boolean']
  isSameAirline: Scalars['Boolean']
  origin?: Maybe<Scalars['String']>
  tax?: Maybe<Rate>
  total: Rate
  unusedTicketRate?: Maybe<Rate>
}

export type FlightDetail = {
  arrivalGate?: Maybe<Scalars['String']>
  arrivalTerminal?: Maybe<Scalars['String']>
  baggageClaim?: Maybe<Scalars['String']>
  departureGate?: Maybe<Scalars['String']>
  departureTerminal?: Maybe<Scalars['String']>
  estimatedArrivalTime?: Maybe<Scalars['Date']>
  estimatedDepartureTime?: Maybe<Scalars['Date']>
  previousArrivalGate?: Maybe<Scalars['String']>
  previousArrivalTerminal?: Maybe<Scalars['String']>
  previousBaggageClaim?: Maybe<Scalars['String']>
  previousDepartureGate?: Maybe<Scalars['String']>
  previousDepartureTerminal?: Maybe<Scalars['String']>
  status: Scalars['String']
  statusMessage: Scalars['String']
}

export type FlightDetails = {
  /** AirProvider defines type of GDS & Ancillary Provider. */
  airProvider: AirProvider
  /** Information representing fare components for a flight. */
  fareInfo?: Maybe<FareInfo>
  /** Flight legs encompassing flight segments. */
  flightLegs: Array<FlightDetailsLeg>
  /** Number of passengers associated with the flight identified. */
  numberOfPassengers: Scalars['Float']
  /** Policy evaluation status applied to the entire flight trip. */
  policyStatus?: Maybe<PolicyStatus>
  /** Trip identifier that was returned in the `Fare` response from a flight search. */
  tripId: Scalars['ID']
}

/** Flight leg details with related segments. */
export type FlightDetailsLeg = {
  /** The arrival date/time of the last flight segment in the leg. */
  arrivalDateTime: Scalars['String']
  /** layover duration, in minutes, between each flight segment with related segment references. No. of connections = No.of flight segments - 1. */
  connections?: Maybe<Array<Connection>>
  /** The departure date/time of the first flight segment in the leg. */
  departureDateTime: Scalars['String']
  /** IATA code of destination airport; e.g. "DUB". This is the destination airport of the final flight segment in the leg. */
  destinationAirportCode: Scalars['String']
  /** Travel distance measured in miles or kilometers of the flight segment, as the crow flies. */
  distance: Distance
  /** Elapsed time in minutes. Total flight segment duration and layover duration at connection locations. */
  elapsedTime: Scalars['Float']
  /** Flight segments. */
  flightSegments: Array<FlightDetailsSegment>
  /** Travel time in minutes. Total flight segment duration only. */
  flightTime: Scalars['Float']
  /** Flight leg identifier. */
  legId: Scalars['ID']
  /** The total number of stops at locations that are not the destination. */
  numberOfStops: Scalars['Float']
  /** IATA code of origin airport; e.g. "SFO". This is the origin airport of the first flight segment in the leg. */
  originAirportCode: Scalars['String']
  /** Indicates whether the fare is refundable on cancellation. */
  refundable: Scalars['Boolean']
}

/** Flight segment for a flight leg. */
export type FlightDetailsSegment = {
  /** Details for an airline; e.g. code and name etc. */
  airlineDetails: AirlineDetails
  /** Preference elements applicable to a flight segment. */
  airlinePreference?: Maybe<AirlinePreference>
  /** List of airplane amenities available for the class of service, if any. */
  amenities?: Maybe<Array<AirAmenity>>
  /** Flight segment arrival time. The date and time elements are expressed in the timezone of the destination airport. This holds true whether or not time_offset is present. */
  arrivalDateTime: Scalars['String']
  /** Baggage fee rules related to airline. */
  baggageFeeRules: BaggageFeeRules
  /** Class of service/cabin to which the fare applies. */
  cabinType: ServiceClass
  /** Flight segment departure time.The date and time elements are expressed in the timezone of the origin airport. This holds true whether or not time_offset is present. */
  departureDateTime: Scalars['String']
  /** Destination airport information relevant for display. */
  destinationAirportInfo: AirportInfo
  /** If known, the name of the terminal to which the flight arrives. */
  destinationTerminal?: Maybe<Scalars['String']>
  /** Type of equipment (aka airplane) used by the operating carrier; e.g. "Boeing 757-300". */
  equipmentType?: Maybe<Scalars['String']>
  /** Duration, in minutes, between departure and arrival times. */
  flightDuration: Scalars['Float']
  /** Flight number assigned by marketing airline. */
  flightNumber: Scalars['Float']
  /** Class of service/cabin to which the fare applies. */
  mealService?: Maybe<Array<MealService>>
  /** Observed on-time performance for this flight given as an integer between 0 and 100. */
  onTimePercentage?: Maybe<Scalars['Float']>
  /** If the flight is a codeshare, the airline details of the operating airline presented. */
  operatingAirlineDetails?: Maybe<AirlineDetails>
  /** Origin airport information relevant for display. */
  originAirportInfo: AirportInfo
  /** If known, the name of the terminal from which the flight departs. */
  originTerminal?: Maybe<Scalars['String']>
  /** Indicate if a viewable seat map is available for the flight. */
  seatMapAvailable: Scalars['Boolean']
  /** If a seat map is viewable, indicate if seat selection is possible. */
  seatMapSelectable: Scalars['Boolean']
  /** Flight segment identifier. */
  segmentId: Scalars['ID']
}

export type FlightFare = {
  fareId?: Maybe<Scalars['String']>
  /** Custom flight fare label, configured from the dashboard */
  fareLabel?: Maybe<Scalars['String']>
  isNDCFare?: Maybe<Scalars['Boolean']>
  isRefundable?: Maybe<Scalars['Boolean']>
  isSelectable?: Maybe<Scalars['Boolean']>
  isUnusedTicketApplicable?: Maybe<Scalars['Boolean']>
  isWebFare?: Maybe<Scalars['Boolean']>
  /** Policy object on the fare level with out-of-policy reasons and data */
  policy?: Maybe<Policy>
  providerAttributesStr?: Maybe<Scalars['String']>
  restriction: Scalars['String']
  rulesAndRestrictions?: Maybe<Array<FlightFareRulesAndRestrictions>>
  segments?: Maybe<Array<FlightFareSegment>>
  showOutOfPolicy?: Maybe<Scalars['Boolean']>
  tier: FlightFareTier
  total: Rate
  tripId?: Maybe<Scalars['String']>
  unusedTickets?: Maybe<SearchUnusedTickets>
}

export type FlightFareAttributesFareInput = {
  id: Scalars['String']
  segments: Array<FlightFareAttributesFareSegmentInput>
}

export type FlightFareAttributesFareSegmentInput = {
  basisCode: Scalars['String']
  segmentId: Scalars['ID']
  serviceClass: Scalars['String']
}

export type FlightFareAttributesInBatchInput = {
  legs: Array<FlightFareAttributesLegsInput>
  logsMetadata?: InputMaybe<LogsMetadata>
}

export type FlightFareAttributesInBatchResponseDto = {
  fareAttributes: Array<FareAttributes>
  legId: Scalars['String']
}

export type FlightFareAttributesInput = {
  leg: FlightFareAttributesLegInput
  logsMetadata?: InputMaybe<LogsMetadata>
}

export type FlightFareAttributesLegInput = {
  fares: Array<FlightFareAttributesFareInput>
  segments: Array<FlightFareAttributesLegSubSegmentInput>
}

export type FlightFareAttributesLegSubSegmentInput = {
  carrier?: InputMaybe<FlightCarrierInput>
  departureDate: Scalars['DateTime']
  destination: FlightLegSubSegmentAirportInput
  flightNumber: Scalars['Int']
  id: Scalars['ID']
  origin: FlightLegSubSegmentAirportInput
}

export type FlightFareAttributesLegsInput = {
  fares: Array<FlightFareAttributesFareInput>
  id: Scalars['String']
  segments: Array<FlightFareAttributesLegSubSegmentInput>
}

export type FlightFareAttributesResult = {
  fareAttributes: Array<FareAttributes>
}

export type FlightFareRule = {
  /** Heading associated with flight fare rule content. */
  header: Scalars['String']
  /** Value of the flight fare rule, actually the rule */
  value: Scalars['String']
}

export type FlightFareRulesAndRestrictions = {
  id: Scalars['String']
  penaltyFee?: Maybe<Array<Maybe<Rate>>>
  rulesAndRestriction: Array<Scalars['String']>
  type: Scalars['String']
}

/** Defines fields required to query flight fare rules by transactionId. */
export type FlightFareRulesByTransactionIdInput = {
  deviceClass?: InputMaybe<DeviceClass>
  /** The ID of the flight segment for which fare rules are being requested. */
  flightSegmentId: Scalars['String']
  /** The transaction ID of the itinerary associated with the entire trip. */
  transactionId: Scalars['String']
}

/** Result from the query flight fare rules by transactionId. */
export type FlightFareRulesByTransactionIdResult = {
  /** All rules used to evaluate the flight fare. */
  rules: Array<FlightFareRule>
}

/** Defines fields required to query flight fare rules by itineraryId. */
export type FlightFareRulesInput = {
  deviceClass?: InputMaybe<DeviceClass>
  /** The ID of the flight segment for which fare rules are being requested. */
  flightSegmentId: Scalars['String']
  /** The itinerary id of the selected itinerary */
  itineraryId: Scalars['String']
}

/** Result from the query flight fare rules. */
export type FlightFareRulesResult = {
  /** All rules used to evaluate the flight fare. */
  rules: Array<Maybe<FlightFareRule>>
}

export type FlightFareSegment = {
  basisCode?: Maybe<Scalars['String']>
  bookingCode: Scalars['String']
  displayServiceClass?: Maybe<Scalars['String']>
  /** An alphanumeric code which identifies a fare private code. */
  privateCode?: Maybe<Scalars['String']>
  segmentId: Scalars['ID']
  serviceClass: Scalars['String']
}

export type FlightFareTier = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export enum FlightFareType {
  All = 'ALL',
  AllowRefunds = 'ALLOW_REFUNDS',
  NoRestrictions = 'NO_RESTRICTIONS',
}

export type FlightKey = {
  airlineCode?: Maybe<Scalars['String']>
  departureAirportCode?: Maybe<Scalars['String']>
  flightNumber?: Maybe<Scalars['Int']>
  originalDepartureDate?: Maybe<Scalars['Date']>
  stringifiedFlightKey?: Maybe<Scalars['String']>
}

export type FlightLegCommon = {
  flightStops: Scalars['Int']
  flightTime?: Maybe<TravelTime>
  legId: Scalars['String']
  policy: Policy
}

export type FlightLegConnectionInfo = {
  connectionTimeMinutes: Scalars['Int']
  segmentId: Scalars['String']
}

export type FlightLegResult = {
  arrivalDateTime: Scalars['DateTime']
  baseRate?: Maybe<Rate>
  departureDateTime: Scalars['DateTime']
  fareAttributes?: Maybe<Scalars['String']>
  fareLabel?: Maybe<FareLabel>
  fareRules?: Maybe<Array<Scalars['String']>>
  feesAndTaxesRate?: Maybe<Rate>
  flightTime?: Maybe<TravelTime>
  isReserved?: Maybe<Scalars['Boolean']>
  isRoundTripCost?: Maybe<Scalars['Boolean']>
  isRoundTripLeg?: Maybe<Scalars['Boolean']>
  isSameCarrierName?: Maybe<Scalars['Boolean']>
  /** Flight leg identifier. */
  legId: Scalars['ID']
  luggageOptions?: Maybe<Array<LuggageOption>>
  policy: Policy
  rate: Rate
  segments: Array<FlightLegSegmentResult>
  /** The total number of stops at locations that are not the destination. */
  stops: Scalars['Int']
  totalCostRate?: Maybe<Rate>
  totalTime?: Maybe<TravelTime>
  unusedTicketRate?: Maybe<Rate>
}

export type FlightLegSearchInput = {
  departureDate: Scalars['Date']
  destination?: InputMaybe<Scalars['AirportCode']>
  destinationCity?: InputMaybe<Scalars['String']>
  destinationCountryCode?: InputMaybe<Scalars['String']>
  destinationLocation?: InputMaybe<GeocodeInput>
  destinationStateCode?: InputMaybe<Scalars['String']>
  endTimeRange?: InputMaybe<Scalars['String']>
  number: Scalars['Int']
  origin?: InputMaybe<Scalars['AirportCode']>
  originCity?: InputMaybe<Scalars['String']>
  originCountryCode?: InputMaybe<Scalars['String']>
  originLocation?: InputMaybe<GeocodeInput>
  originStateCode?: InputMaybe<Scalars['String']>
  preferredTime: Scalars['String']
  serviceClass: Scalars['String']
  startTimeRange?: InputMaybe<Scalars['String']>
  timeRangeBy: Scalars['String']
}

export type FlightLegSegment = FlightLegCommon & {
  arrivalDateTime: Scalars['DateTime']
  baseRate?: Maybe<Rate>
  departureDateTime: Scalars['DateTime']
  fareAttributes?: Maybe<Scalars['String']>
  fareLabel?: Maybe<FareLabel>
  fareRules?: Maybe<Array<Scalars['String']>>
  feesAndTaxesRate?: Maybe<Rate>
  /** @deprecated Use stops instead. */
  flightStops: Scalars['Int']
  flightTime?: Maybe<TravelTime>
  isNDCFare?: Maybe<Scalars['Boolean']>
  isReserved?: Maybe<Scalars['Boolean']>
  isRoundTripCost?: Maybe<Scalars['Boolean']>
  isRoundTripLeg?: Maybe<Scalars['Boolean']>
  isSameCarrierName?: Maybe<Scalars['Boolean']>
  legId: Scalars['String']
  luggageOptions?: Maybe<Array<LuggageOption>>
  /** Rate without taxes, fees and service charges. */
  netRate?: Maybe<Rate>
  policy: Policy
  rate: Rate
  segmentState?: Maybe<SegmentState>
  segments: Array<FlightLegSubSegment>
  /** Total service fees for each ticket; the information was derived from the passenger cost */
  serviceFeesRate?: Maybe<Rate>
  /** @deprecated Use segmentState instead. */
  state?: Maybe<SegmentState>
  stops: Scalars['Int']
  totalCostRate?: Maybe<Rate>
  totalTime?: Maybe<TravelTime>
  /** Total transaction fees for each ticket; the information was derived from the passenger cost */
  transactionFeesRate?: Maybe<Rate>
  unusedTicketRate?: Maybe<Rate>
}

export type FlightLegSegmentAircraft = {
  code: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type FlightLegSegmentAmenities = {
  codes?: Maybe<Array<Scalars['String']>>
  values?: Maybe<Array<Scalars['String']>>
}

export type FlightLegSegmentResult = {
  aircraft?: Maybe<AircraftData>
  amenities?: Maybe<TransportAmenities>
  /** Destination airport. */
  arrival: AirportAddress
  /** String representation of arrival date. */
  arrivalDate: Scalars['String']
  baggageFees?: Maybe<BaggageFees>
  /** Booking code. */
  bookingCode?: Maybe<Scalars['String']>
  bookingProvider?: Maybe<Scalars['String']>
  /** The two character airline code. */
  carrierCode: Scalars['String']
  codeshareCarrier: CodeshareCarrier
  /** Departure airport. */
  departure: AirportAddress
  /** String representation of departure date. */
  departureDate: Scalars['String']
  /** The name of the terminal to which the flight arrives. */
  destinationTerminal?: Maybe<Scalars['String']>
  fare?: Maybe<Scalars['String']>
  /** Flight number for this segment. */
  flightNumber: Scalars['Int']
  /** Time duration of this segment. */
  flightTime?: Maybe<TravelTime>
  isDepartAirportDifferentFromConnecting?: Maybe<Scalars['Boolean']>
  mealService?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  operatedBy?: Maybe<Scalars['String']>
  /** The name of the terminal from which the flight departs. */
  originTerminal?: Maybe<Scalars['String']>
  penaltyFees?: Maybe<Array<PenaltyFee>>
  /** Observed on-time performance for this flight. */
  percentageOnTime?: Maybe<Scalars['Int']>
  /** The attributes for airlines that are preferred for the user. */
  preferenceAttributes?: Maybe<Preference>
  preferredDescription?: Maybe<Preference>
  /** Unique id for segment. */
  segmentId?: Maybe<Scalars['ID']>
  serviceClass: Scalars['String']
  serviceFee?: Maybe<ServiceFee>
}

export type FlightLegSubSegment = FlightLegSubSegmentCommon & {
  aircraft?: Maybe<AircraftData>
  amenities?: Maybe<TransportAmenities>
  arrival: AirportAddress
  arrivalDate: Scalars['DateTime']
  baggageFees?: Maybe<BaggageFees>
  bookingCode?: Maybe<Scalars['String']>
  bookingProvider?: Maybe<Scalars['String']>
  carrierCode: Scalars['String']
  codeshareCarrier: CodeshareCarrier
  departure: AirportAddress
  departureDate: Scalars['DateTime']
  destinationTerminal?: Maybe<Scalars['String']>
  fare?: Maybe<Scalars['String']>
  flightNumber: Scalars['Int']
  flightTime?: Maybe<TravelTime>
  /** @deprecated Use images.square instead. */
  imageUrl: Scalars['String']
  images: FlightLegSubSegmentImages
  isDepartAirportDifferentFromConnecting?: Maybe<Scalars['Boolean']>
  isInstantPurchaseCarrier?: Maybe<Scalars['Boolean']>
  isSeatAvailable: Scalars['Boolean']
  isSeatSelectable: Scalars['Boolean']
  mealService?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  operatedBy?: Maybe<Scalars['String']>
  originTerminal?: Maybe<Scalars['String']>
  penaltyFees?: Maybe<Array<Maybe<PenaltyFee>>>
  percentageOnTime?: Maybe<Scalars['Int']>
  /** @deprecated Use aircraft instead. */
  plane?: Maybe<PlaneData>
  preferenceAttributes?: Maybe<Preference>
  preferredDescription?: Maybe<Preference>
  segmentId?: Maybe<Scalars['String']>
  serviceClass: Scalars['String']
  serviceFee?: Maybe<ServiceFee>
  travelerId: Scalars['String']
  travelerSeat: Scalars['String']
}

export type FlightLegSubSegmentAirport = {
  city: Scalars['String']
  code: Scalars['AirportCode']
  name: Scalars['String']
  terminal?: Maybe<Scalars['String']>
}

export type FlightLegSubSegmentAirportInput = {
  code: Scalars['AirportCode']
}

export type FlightLegSubSegmentCommon = {
  arrivalDate: Scalars['DateTime']
  departureDate: Scalars['DateTime']
  flightTime?: Maybe<TravelTime>
}

export type FlightLegSubSegmentImages = {
  rectangle?: Maybe<Scalars['String']>
  square?: Maybe<Scalars['String']>
}

/** The result of matching the flight reservation. */
export type FlightMatchDetails = {
  isExactMatch: Scalars['Boolean']
  isFlightAirlineMatch: Scalars['Boolean']
  isFlightCabinMatch: Scalars['Boolean']
  isFlightTimeMatch: Scalars['Boolean']
  isFlightTimeWithinThreshold: Scalars['Boolean']
  matchFound: Scalars['Boolean']
}

export type FlightPolicies = {
  /** Enables or not the option to select/change the destination airport. */
  alternateDestinationAirportAllowed: Scalars['Boolean']
  /** The destination airport code, e.g. LAX, SFO. */
  destinationAirport?: Maybe<Scalars['String']>
}

export type FlightResponseData = {
  airPriceChange?: Maybe<SegmentPriceChangeDetails>
  flightTripCostPerPassenger?: Maybe<Array<Maybe<TripCost>>>
  isSoldOut: Scalars['Boolean']
  itineraryId: Scalars['String']
}

/** The flight search parameters enable clients to initiate a new search. */
export type FlightSearchParams = {
  /** The flight departure date. Format: yyyy-MM-dd. */
  departureDate: Scalars['String']
  /** The IATA code for the arrival airport. For example, "SFO" = San Francisco International Airport. */
  destinationAirportCode: Scalars['String']
  /** The IATA code for the departure airport. For example, "SFO" = San Francisco International Airport. */
  originAirportCode: Scalars['String']
  /** Class of service. For example, "COACH" or "BUSINESS". */
  serviceClass: SeatmapCabinClass
}

export type FlightSearchTimeRange = {
  /** Flight search deviation in minutes after starting time */
  after?: Maybe<Scalars['Int']>
  /** Flight search deviation in minutes before starting time */
  before?: Maybe<Scalars['Int']>
}

/** The actual response object that contains all the information about the requested seat, for each requested flight segment, i.e. flight. */
export type FlightSeatInfo = {
  /** Required. Identifies the flight segment for this seat information. This, in conjunction with the carrier_code, helps identify the seat information with the carrier in a multi-segment response. */
  flightSegment: FlightSegment
  /** This can be an array, depending on the seat information available for this flight. If there is no seat information available, then this would be empty. */
  seat: Array<Seat>
}

/** Defines the flight segment for which the seat attributes are being requested, or responded. */
export type FlightSegment = {
  /** The three-letter IATA code for the arrival airport. Examples: "SFO", "SEA" */
  arrivalAirport: Scalars['String']
  /** The reservation booking designator, or rbd, a.k.a the booking code. E.g. Y, F, X, etc. If available, this can be used to get better response, or a more focussed response from ATPCO. */
  bookingCode: Scalars['String']
  /** The cabin class the traveler is flying in */
  cabinCode: CabinCodeEnum
  /** The two-character IATA carrier code. Examples: "AS", "DL", "VS", "O6" */
  carrierCode: Scalars['String']
  /** Specifies if the carrier is the primary carrier for this segment of the flight. If unspecified it is assumed to be a primary carrier. */
  carrierStatus: SeatAttributesCarrierStatusEnum
  /** The three-letter IATA code for the departure airport. Examples: "BNA", "LAX" */
  departureAirport: Scalars['String']
  /** The fare basis code for the flight */
  fareBasisCode: Scalars['String']
  /** The departure date in the format "YYYY-MM-DD" */
  flightDate: Scalars['String']
  /** The carrier's flight number */
  flightNumber: Scalars['String']
}

export type FlightSegmentCheckIn = {
  airlineUrl?: Maybe<Scalars['String']>
  checkInWindowInHours?: Maybe<Scalars['Int']>
}

export type FlightSegmentDto = {
  /** The three-letter IATA code for the arrival airport. Examples: "SFO", "SEA" */
  arrivalAirport: Scalars['String']
  /** The reservation booking designator, or rbd, a.k.a the booking code. E.g. Y, F, X, etc. If available, this can be used to get better response, or a more focussed response from ATPCO. */
  bookingCode: Scalars['String']
  /** The cabin class the traveler is flying in */
  cabinCode: CabinCodeEnum
  /** The two-character IATA carrier code. Examples: "AS", "DL", "VS", "O6" */
  carrierCode: Scalars['String']
  /** Specifies if the carrier is the primary carrier for this segment of the flight. If unspecified it is assumed to be a primary carrier. */
  carrierStatus: SeatAttributesCarrierStatusEnum
  /** The three-letter IATA code for the departure airport. Examples: "BNA", "LAX" */
  departureAirport: Scalars['String']
  /** The fare basis code for the flight */
  fareBasisCode: Scalars['String']
  /** The departure date in the format "YYYY-MM-DD" */
  flightDate: Scalars['String']
  /** The carrier's flight number */
  flightNumber: Scalars['String']
}

export type FlightSegmentVoiceSearch = {
  cabinClass?: Maybe<FlightCabinClassVoiceSearch>
  departureDate: Scalars['String']
  destinationAirport: Scalars['String']
  originAirport: Scalars['String']
  timeOfDay?: Maybe<FlightTimeOfDayVoiceSearch>
}

export enum FlightSort {
  Arrival = 'ARRIVAL',
  Cost = 'COST',
  Departure = 'DEPARTURE',
  Duration = 'DURATION',
  PolicyOrPrefered = 'POLICY_OR_PREFERED',
}

/** Flight cabin class parameters used in a flight search. */
export enum FlightTimeOfDayTimeVoiceSearch {
  /** Premium economy class flight. */
  Afternoon = 'Afternoon',
  /** First class flight. */
  Anytime = 'Anytime',
  /** First class flight. */
  Custom = 'Custom',
  /** Business class flight. */
  Evening = 'Evening',
  /** Economy class flight. */
  Morning = 'Morning',
}

/** Flight cabin class parameters used in a flight search. */
export enum FlightTimeOfDayTypeVoiceSearch {
  /** Premium economy class flight. */
  Arrive = 'Arrive',
  /** Economy class flight. */
  Leave = 'Leave',
}

export type FlightTimeOfDayVoiceSearch = {
  customHour?: Maybe<Scalars['Int']>
  time: FlightTimeOfDayTimeVoiceSearch
  type: FlightTimeOfDayTypeVoiceSearch
}

/** Deprecated. Use FlightDetails instead. */
export type FlightTripResult = {
  /** Flight legs encompassing flight segments. */
  flightLegs: Array<FlightLegResult>
}

/** Flight type parameters used in a flight search. */
export enum FlightTypeVoiceSearch {
  /** Multi city flight. */
  MultiCity = 'MultiCity',
  /** One way flight. */
  OneWay = 'OneWay',
  /** Round trip flight. */
  RoundTrip = 'RoundTrip',
  Unspecified = 'Unspecified',
}

export type FlightVoiceSearchResponse = FlightVoiceSearchResult | UnexpectedError

export type FlightVoiceSearchResult = {
  flightType: FlightTypeVoiceSearch
  requestId: Scalars['String']
  segments: Array<FlightSegmentVoiceSearch>
}

export type FlightsDisplayConfiguration = {
  areNearbyAirportsIncluded: Scalars['Boolean']
  businessClassCustomLabel?: Maybe<Scalars['String']>
  defaultAirTravelType?: Maybe<Scalars['String']>
  defaultSort: FlightSort
  economyClassCustomLabel?: Maybe<Scalars['String']>
  fares: FaresFlightsDisplayConfiguration
  firstClassCustomLabel?: Maybe<Scalars['String']>
  isAnytimeEnabled: Scalars['Boolean']
  isBusinessClassEnabled: Scalars['Boolean']
  isDefaultAnyTime: Scalars['Boolean']
  isEconomyClassEnabled: Scalars['Boolean']
  isFirstClassEnabled: Scalars['Boolean']
  isMorningAfternoonEveningEnabled: Scalars['Boolean']
  /** Indicates whether an out of policy flight is selectable. */
  isOutOfPolicyFlightsSelectable: Scalars['Boolean']
  isPremiumEconomyClassEnabled: Scalars['Boolean']
  premiumEconomyClassCustomLabel?: Maybe<Scalars['String']>
}

export type FlightsFilters = {
  airlines: Array<AirlineFilter>
  airport: Array<AirportFilter>
  destinationTime: TimeFilter
  fareTier?: Maybe<Array<FareTierFilter>>
  originTimeRange: TimeFilter
  stops: Array<StopsFilter>
}

export type FlightsInput = {
  after?: InputMaybe<Scalars['Int']>
  airlineFilters?: InputMaybe<Array<Scalars['String']>>
  bookingProvider?: InputMaybe<Scalars['String']>
  destinationAirportFilters?: InputMaybe<Array<Scalars['String']>>
  deviceClass?: InputMaybe<DeviceClass>
  excludeOutOfPolicy?: InputMaybe<Scalars['Boolean']>
  /** Existing outbound flight. Used in FSS search for change return flight only use case */
  existingOutboundFlightSegments?: InputMaybe<Array<ExistingOutboundFlightSegmentInput>>
  filterFareTiers?: InputMaybe<Array<FareTier>>
  first?: InputMaybe<Scalars['Int']>
  includeNearbyAirports?: InputMaybe<Scalars['Boolean']>
  isCustomTimeReplaced?: InputMaybe<Scalars['Boolean']>
  isCustomTimeSearch?: InputMaybe<Scalars['Boolean']>
  /** NDC flag. Signifies order search is specifically for NDC flights. */
  isNdc?: InputMaybe<Scalars['Boolean']>
  /** ReturnFlightChangeOnly flag. Used to handle change return flight only use case. */
  isReturnFlightChangeOnly?: InputMaybe<Scalars['Boolean']>
  legPosition?: InputMaybe<Scalars['LegPosition']>
  logsMetadata?: InputMaybe<LogsMetadata>
  originAirportFilters?: InputMaybe<Array<Scalars['String']>>
  searchId: Scalars['ID']
  selectedFareIds?: InputMaybe<Array<Scalars['String']>>
  selectedFareTiers?: InputMaybe<Array<FareTier>>
  selectedLegs?: InputMaybe<Array<LegSelectionInput>>
  selectedServiceClasses?: InputMaybe<Array<Array<Scalars['String']>>>
  sortBy?: InputMaybe<FlightsSortBy>
  stopFilters?: InputMaybe<Array<Scalars['String']>>
  takeoffLandingRanges?: InputMaybe<Array<TakeoffLandingRangesInput>>
  unusedTicket?: InputMaybe<Scalars['Boolean']>
  webfareSupported?: InputMaybe<Scalars['Boolean']>
}

export type FlightsResult = {
  filters: FlightsFilters
  flightLegs: Array<SearchFlightLeg>
  isCustomTimeReplaced?: Maybe<Scalars['Boolean']>
  isDsmEnabled?: Maybe<Scalars['Boolean']>
  maxResultsSize?: Maybe<Scalars['Int']>
  /** @deprecated Use getFlightDynamicSiteMessages query to get the messages instead. */
  messages?: Maybe<Array<DynamicSiteMessage>>
  pageEnd?: Maybe<Scalars['Int']>
  pageStart?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
}

export enum FlightsSortBy {
  Arrival = 'ARRIVAL',
  Cost = 'COST',
  Departure = 'DEPARTURE',
  Duration = 'DURATION',
  Policy = 'POLICY',
  Preference = 'PREFERENCE',
}

export type FoxCostAllocationInput = {
  pageSize?: InputMaybe<Scalars['Int']>
  query?: InputMaybe<Scalars['String']>
}

export type GazooAnytimeSearch = {
  defaultAnyTime?: Maybe<Scalars['Boolean']>
  value?: Maybe<Scalars['String']>
}

export type GazooDynamicSiteMessage = {
  label?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type GazooMembership = {
  number: Scalars['String']
  vendorCode: Scalars['String']
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED',
}

/** Defines error reasons of cart operations failures */
export enum GenericCartErrorReason {
  /** Failed to confirm or update price of intent due to being sold out. */
  RepriceSoldOut = 'REPRICE_SOLD_OUT',
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** GenericCartErrorResponse is returned from cart specific request with a well-defined reason */
export type GenericCartErrorResponse = {
  /** Error reason */
  reason: GenericCartErrorReason
}

export type Geocode = {
  lat: Scalars['Float']
  long: Scalars['Float']
}

export type GeocodeByAddressInput = {
  address: AddressInput
}

/** Location coordinates. */
export type GeocodeInput = {
  /** Location latitude. */
  lat: Scalars['Float']
  /** Location longitude. */
  long: Scalars['Float']
}

export type GeosureReport = {
  enabled: Scalars['Boolean']
  variant?: Maybe<Scalars['String']>
}

export type GeosureScore = {
  label: Scalars['String']
  value: Scalars['Int']
}

export type GeosureScoresResult = {
  city?: Maybe<Scalars['String']>
  compositeScore?: Maybe<Scalars['Int']>
  covid19Summary?: Maybe<Covid19Summary>
  distance?: Maybe<Scalars['Float']>
  district?: Maybe<Scalars['String']>
  scores: Array<GeosureScore>
}

/** Defines error reasons of get alternate policy trips failures */
export enum GetAlternatePolicyTripsErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

export type GetAlternatePolicyTripsErrorResponse = {
  /** Error reason */
  reason: GetAlternatePolicyTripsErrorReason
}

export type GetAlternatePolicyTripsInput = {
  numberOfTrips: Scalars['Float']
  tripId: Scalars['String']
}

export type GetAlternatePolicyTripsResponse = {
  policyTrips: Array<PolicyTripDto>
}

export type GetAlternatePolicyTripsResponseDtoUnion =
  | GetAlternatePolicyTripsErrorResponse
  | GetAlternatePolicyTripsResponse
  | UnexpectedError

/** Retrieves a list of delegates and authorizers from Gazoo for use during editing Profile. */
export type GetAvailableDelegates = {
  /** List of the available delegates. */
  availableDelegates?: Maybe<Array<AvailableDelegates>>
}

/** Defines fields required to query car rental rules by transactionId. */
export type GetCarRentalRulesByTransactionIdInput = {
  /** The ID of the car segment for which rules are being requested. */
  carSegmentId: Scalars['String']
  /** The transaction ID of the itinerary associated with the entire trip. */
  transactionId: Scalars['String']
}

/** GetCartBehavioursResponse is returned when the getCartBehaviours query succeeds. */
export type GetCartBehavioursResponse = {
  /** Lists of intents selected by the end user. */
  addableDomains: Array<Domain>
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Lists of behaviours associated with the cart, available to the end user while on the Search page. */
  searchBehaviours: SearchBehaviors
}

/** Union of different response objects that can be returned by getCartBehaviours query. Returns known/expected errors in a structured manner to the client. */
export type GetCartBehavioursResponseUnion = CartNotFoundResponse | GetCartBehavioursResponse

export type GetCartInput = {
  /** Unique identifier for the cart that was previously created. */
  cartId: Scalars['ID']
}

/** GetCartResponse is returned when the getCart query succeeds. */
export type GetCartResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Name of the cart. It will be used as the trip name once the cart has been checked out successfully. */
  cartName: Scalars['String']
  /** Provides a summary of the intents in the cart. */
  cartSummary: CartSummary
  /** Provides the list of user intents that are active in the cart. */
  intents: Array<Intent>
  /** Indicates whether the cart name can be edited. */
  isCartNameEditable: Scalars['Boolean']
}

/** Union of different response objects that can be returned by getCart query. Returns known/expected errors in a structured manner to the client. */
export type GetCartResponseUnion = CartNotFoundResponse | GetCartResponse | UnexpectedError

/** Union of different response objects that can be returned by getCheckoutInfo query. Returns known/expected errors in a structured manner to the client. */
export type GetCheckoutInfoResponseUnion =
  | CheckoutInfoResult
  | ItineraryNotFoundResponse
  | UnexpectedError

/** GetCostAllocationResponse is returned when the getCostAllocation query succeeds. */
export type GetCostAllocationResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Cost Allocations to which the amount of transaction must be charged. */
  costAllocations: CartCostAllocation
}

/** Union of different response objects that can be returned by getCostAllocation query. Returns known/expected errors in a structured manner to the client. */
export type GetCostAllocationResponseUnion = CartNotFoundResponse | GetCostAllocationResponse

/** Union of different response objects that can be returned by getCostSummary query. Returns known/expected errors in a structured manner to the client. */
export type GetCostSummaryResponseUnion = CartNotFoundResponse | CostSummaryResponse

/** Retrieves a list of currencies from Gazoo for use during editing Profile. */
export type GetCurrencies = {
  /** List of the available currencies */
  currencies?: Maybe<Array<Currency>>
}

export type GetCustomFieldsInput = {
  /** Unique identifier for the cart. */
  cartId: Scalars['ID']
  /** Scope in the shopping flow for which custom fields, and their values, are to be retrieved. */
  scope: CustomFieldsScope
}

/** GetCustomFieldsResponse is returned when the getCustomFields query succeeds. */
export type GetCustomFieldsResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Custom fields configuration. */
  customFieldsConfiguration?: Maybe<CustomFieldConfiguration>
}

/** Union of different response objects that can be returned by getCustomFields mutation. Returns known/expected errors in a structured manner to the client. */
export type GetCustomFieldsResponseUnion = CartNotFoundResponse | GetCustomFieldsResponse

export type GetEnhancedSeatMapResponse = EnhancedSeatMap | UnexpectedError

export type GetFlightDynamicSiteMessagesInputDto = {
  searchId: Scalars['ID']
}

export type GetFlightDynamicSiteMessagesResponse = {
  messages: Array<DynamicSiteMessage>
}

/** Retrieves a list of available languages from Gazoo for use during editing Profile. List contains only languages that are supported by the current tenant. */
export type GetLanguages = {
  /** List of the available languages */
  languages?: Maybe<Array<Language>>
}

export type GetMeetingInfoResponseUnion = UnexpectedError | UpsertMeetingInfoResponse

/** GetOOPInfoResponse is returned when the oopInfo query succeeds. */
export type GetOopInfoResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Overall explanation for reserving out-of-policy items (aka. "Trip Purpose"). */
  explanation?: Maybe<OopExplanation>
  /** Available compliance codes per out-of-policy item in cart. */
  intentComplianceCodes: Array<IntentComplianceCode>
  /** Indicates if cart contains any items which are not in policy.      All other attributes can be ignored if false. */
  isCartOutOfPolicy: Scalars['Boolean']
  /** Indicates if user is allowed to book/reserve a cart which is out of policy.      If true compliance codes and if required an explanation, need to be specified as justification. */
  isOOPAllowed: Scalars['Boolean']
  /** Customer configured message to display to user when cart is out of policy. */
  message?: Maybe<Scalars['String']>
}

/** Union of different response objects that can be returned by oopInfo query.Returns known/expected errors in a structured manner to the client. */
export type GetOopInfoResponseUnion = CartNotFoundResponse | GetOopInfoResponse

/** Defines the response that is returned for the seat information. */
export type GetSeatAttributesResponse = SeatAttributes | UnexpectedError

/** Retrieves a list of time zones from Gazoo for use during editing Profile. */
export type GetTimeZones = {
  /** List of the available time zones */
  timeZones?: Maybe<Array<TimeZone>>
}

/** GetTripDetailsResponse is returned when the tripDetails query succeeds. */
export type GetTripDetailsResponse = {
  /** Provides the reservations (could be different domains) in the trip. */
  reservations: Array<ReservationUnion>
  /** Traveler's full name. */
  traveler: Traveler
  /** Provides the cost summary of the trip. */
  tripCostDetails: TripCostDetails
  /** Unique identifer for the trip whose details are returned in the response. */
  tripId: Scalars['ID']
  /** Provides a summary of the trip. */
  tripSummary: TripSummary
  /** Indicates the type of the trip. */
  tripType: TripStatusType
}

/** Union of different response objects that can be returned by tripDetails query. Returns known/expected errors in a structured manner to the client. */
export type GetTripDetailsResponseUnion =
  | GetTripDetailsResponse
  | TripNotFoundResponse
  | UnexpectedError

/** GetUserTripsResponse is returned when the userTrips query succeeds. */
export type GetUserTripsResponse = {
  /** Provides the details of the page for which the response is returned. */
  page: Page
  /** Indicates the total number trips (with the tripType) associated with the user. */
  totalResults: Scalars['Int']
  /** Summaries of the trips (with the tripType) associated by the user */
  trips: Array<TripSummary>
  /** Indicates the trip type. */
  tripsType: TripStatusType
}

/** Union of different response objects that can be returned by addToCart mutation. Returns known/expected errors in a structured manner to the client. */
export type GetUserTripsResponseUnion = GetUserTripsResponse | UnexpectedError

/** Groupware object containing groupware ID email, which is used for calendar integration */
export type Groupware = {
  /** The user's email address (which supports calendar integration) is used as the groupware ID. This must be a valid email, and be unique to every customer. This is usually the user's business email address. If no email address is provided, this field defaults to the value set for userinfo.email. field value. */
  groupwareId?: Maybe<Scalars['String']>
  /** Indicates whether the user's calendar is to synchronise (This is not currently used in PWA). */
  syncCalendar?: Maybe<Scalars['Boolean']>
}

/** Groupware input object containing groupware ID email, which is used to store updated groupware data */
export type GroupwareInput = {
  /** The user's email address (which supports calendar integration) is used as the groupware ID. This must be a valid email, and be unique to every customer. This is usually the user's business email address. If no email address is provided, this field defaults to the value set for userinfo.email. field value. */
  groupwareId?: InputMaybe<Scalars['String']>
  /** Indicates whether the user's calendar is to synchronise (This is not currently used in PWA). */
  syncCalendar?: InputMaybe<Scalars['Boolean']>
}

/** Guest's address */
export type GuestTravelerAddress = {
  /** City */
  city: Scalars['String']
  /** Country code */
  countryCode: Scalars['String']
  /** Postal code */
  postalCode?: InputMaybe<Scalars['String']>
  /** State code */
  stateCode?: InputMaybe<Scalars['String']>
  /** First street */
  street1?: InputMaybe<Scalars['String']>
  /** Second street */
  street2?: InputMaybe<Scalars['String']>
}

/** Guest traveler information */
export type GuestTravelerInput = {
  /** Guest's address */
  address?: InputMaybe<GuestTravelerAddress>
  /** Guest's date of birth */
  dateOfBirth?: InputMaybe<Scalars['String']>
  /** Guest's email */
  email: Scalars['String']
  /** Guest's first name */
  firstName: Scalars['String']
  /** Guest's gender */
  gender?: InputMaybe<Gender>
  /** Guest's last name */
  lastName: Scalars['String']
  /** Guest's meal preference */
  mealPreference?: InputMaybe<Scalars['String']>
  /** Guest's middle name */
  middleName?: InputMaybe<Scalars['String']>
  /** Guest's passport */
  passport?: InputMaybe<GuestTravelerPassport>
  /** Guest's phone number */
  phoneNumber?: InputMaybe<GuestTravelerPhoneNumber>
  /** Guest's suffix */
  suffix?: InputMaybe<UserSuffix>
  /** Guest's title */
  title?: InputMaybe<UserTitle>
  /** Guest's travel preferences */
  travelPreferences?: InputMaybe<TravelPreferencesInput>
}

/** Guest's passport */
export type GuestTravelerPassport = {
  /** Expiration date of the passport */
  expirationDate?: InputMaybe<Scalars['String']>
  /** Issue date of the passport */
  issueDate?: InputMaybe<Scalars['String']>
  /** The issueing country of the passport */
  issuingCountry?: InputMaybe<Scalars['String']>
  /** The number of the passport */
  passportNumber?: InputMaybe<Scalars['String']>
}

/** Guest's phone number */
export type GuestTravelerPhoneNumber = {
  /** Country code */
  countryCode: Scalars['String']
  /** Phone number without country code */
  number: Scalars['String']
  /** Type of the number */
  type: Scalars['String']
}

export type HeapIdentity = {
  /** Returns hash value of provided email */
  identityHash: Scalars['String']
}

export type HoldRules = {
  /** This field indicates when the trip will be auto-cancelled after placing it on hold. */
  autoCancellationTime?: Maybe<Scalars['String']>
  holdAllowed: Scalars['Boolean']
  reasonsForNotAllowing: Array<Scalars['String']>
}

/** Response from "hotel" request */
export type Hotel = {
  address: Address
  amenities: Array<Amenity>
  chainCode?: Maybe<Scalars['String']>
  chainIconUrl: Scalars['String']
  /** @deprecated Use `privateRate`. */
  corporateRate?: Maybe<CorporateRate>
  descriptions?: Maybe<Array<Maybe<HotelDescription>>>
  distance: Distance
  exclusiveAmenities?: Maybe<Array<Amenity>>
  hotelReviewsDetailsUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCancellable: Scalars['Boolean']
  isEcoFriendly?: Maybe<Scalars['Boolean']>
  isPreferred: Scalars['Boolean']
  isSoldOut?: Maybe<Scalars['Boolean']>
  logoPhotoUrl?: Maybe<Scalars['String']>
  medianReviewRating?: Maybe<Scalars['Float']>
  medianReviewRatingImageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  notes?: Maybe<Scalars['String']>
  perDiemRate?: Maybe<Rate>
  phoneNumber?: Maybe<Scalars['String']>
  photos: Array<Photo>
  policy?: Maybe<Policy>
  preference?: Maybe<Preference>
  privateRate?: Maybe<PrivateRate>
  rate?: Maybe<Rate>
  resultKey?: Maybe<Scalars['String']>
  reviews: Array<Maybe<HotelReview>>
  reviewsProvider: Scalars['String']
  rooms?: Maybe<Array<Room>>
  starRating: Scalars['Int']
  totalReviewCount: Scalars['Int']
}

export type HotelCostSummary = {
  cost?: Maybe<Rate>
  isPaid: Scalars['Boolean']
  itemCount: Scalars['Int']
  name?: Maybe<Scalars['String']>
  tax?: Maybe<Rate>
  total?: Maybe<Rate>
}

/** Hotel description information */
export type HotelDescription = {
  body?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HotelDisplayConfiguration = {
  /** Indicates whether hotel star rating functionality is enabled. This flag is set based on a combination of site and partner configuration elsewhere; if the star rating configuration is disabled on either level, this value is set to false. */
  isStarRatingEnabled: Scalars['Boolean']
}

/** Describe the error that occurred when booking a hotel */
export enum HotelFailedBookingError {
  /** Credit card failure, expected action is to retry the same booking with change in credit card or return to the searh result page. */
  HotelRoomReservationNoteCreditCardFailure = 'HOTEL_ROOM_RESERVATION_NOTE_CREDIT_CARD_FAILURE',
  /** Direct connect error, expected action is to redirect user to the search result page and disable room. */
  HotelRoomReservationNoteDirectConnectError = 'HOTEL_ROOM_RESERVATION_NOTE_DIRECT_CONNECT_ERROR',
  /** Direct connect error with credit card */
  HotelRoomReservationNoteDirectConnectErrorcc = 'HOTEL_ROOM_RESERVATION_NOTE_DIRECT_CONNECT_ERRORCC',
  /** Inventory failure, Sold out error, expected action is to redirect user to the search result page and disable hotel. */
  HotelRoomReservationNoteInventoryFailure = 'HOTEL_ROOM_RESERVATION_NOTE_INVENTORY_FAILURE',
  /** Loyalty info dropped, expected action is to retry the same booking with change in loyalty info or return to the searh result page. */
  HotelRoomReservationNoteLoyaltyInfoDropped = 'HOTEL_ROOM_RESERVATION_NOTE_LOYALTY_INFO_DROPPED',
  /** Price change detected, expected action is to update the selected room price as per change */
  HotelRoomReservationNotePriceChangeDetected = 'HOTEL_ROOM_RESERVATION_NOTE_PRICE_CHANGE_DETECTED',
  /** Room or  room`s rate not found, expected action is to redirect user to the search result page and disable room. */
  HotelRoomReservationNoteRoomOrRateNotFound = 'HOTEL_ROOM_RESERVATION_NOTE_ROOM_OR_RATE_NOT_FOUND',
  /** Skipped, not a failure, but informational. No expected action. */
  HotelRoomReservationNoteSkipped = 'HOTEL_ROOM_RESERVATION_NOTE_SKIPPED',
  /** SOAP exception during price check, expected action is to redirect user to the search result page and disable room. */
  HotelRoomReservationNoteSoapExceptionInPriceCheck = 'HOTEL_ROOM_RESERVATION_NOTE_SOAP_EXCEPTION_IN_PRICE_CHECK',
  /** Sold out error, expected action is to redirect user to the search result page and disable room. */
  HotelRoomReservationNoteSoldoutError = 'HOTEL_ROOM_RESERVATION_NOTE_SOLDOUT_ERROR',
  /** Sold passive, not a failure, but informational. No expected action. */
  HotelRoomReservationNoteSoldPassive = 'HOTEL_ROOM_RESERVATION_NOTE_SOLD_PASSIVE',
  /** Special request dropped, expected action is to retry the same booking with change in special request or return to the searh result page. */
  HotelRoomReservationNoteSpecialRequestDropped = 'HOTEL_ROOM_RESERVATION_NOTE_SPECIAL_REQUEST_DROPPED',
  /** Unspecified error, expected action is to redirect user to the search result page. */
  HotelRoomReservationNoteUnspecified = 'HOTEL_ROOM_RESERVATION_NOTE_UNSPECIFIED',
  /** Fail due to inventory failure, expected action is to redirect user to the search result page and disable hotel. */
  ReservationErrorActionFailHotelRoomReservationNoteInventoryFailure = 'RESERVATION_ERROR_ACTION_FAIL_HOTEL_ROOM_RESERVATION_NOTE_INVENTORY_FAILURE',
  /** Sell passive, expected action is to redirect user to the search result page and disable hotel. */
  ReservationErrorActionSellPassive = 'RESERVATION_ERROR_ACTION_SELL_PASSIVE',
  /** Skip due to skipped error */
  ReservationErrorActionSkipHotelRoomReservationNoteSkipped = 'RESERVATION_ERROR_ACTION_SKIP_HOTEL_ROOM_RESERVATION_NOTE_SKIPPED',
}

/** Filter by hotel group */
export type HotelGroup = {
  count: Scalars['Int']
  id: Scalars['String']
  name: Scalars['String']
}

export type HotelLocationInformation = {
  additionalInformation?: Maybe<Scalars['String']>
  chainCode?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  faxNumber?: Maybe<PhoneNumber>
  id?: Maybe<Scalars['String']>
  location?: Maybe<Address>
  name?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<PhoneNumber>
}

/** The result of matching the hotel reservation with the dates of the flight reservation. */
export type HotelMatchDetails = {
  /** Indicates whether the reserved item matches the pre-aligned reservation item precisely, such as the same hotel, room type, and room rate. */
  isExactMatch: Scalars['Boolean']
  /** True if the room rate differs from the room rate specified in the pre-aligned reservation. */
  isRoomRateDifferent: Scalars['Boolean']
  /** True if the room type differs from the room type specified in the pre-aligned reservation. */
  isRoomTypeDifferent: Scalars['Boolean']
  /** Indicates whether the reservation item matches the dates of the flight reservation. */
  matchFound: Scalars['Boolean']
}

/** Response from "hotelNames" request */
export type HotelNamesResult = {
  /** An array of hotel names */
  hotelNames: Array<Scalars['String']>
}

export type HotelPreference = CommonTravelPreference & {
  hotelAmenity?: Maybe<Array<Scalars['String']>>
  loyaltyProgram: Array<LoyaltyProgram>
  /** Special requests selected by a user for the specific travel type - air, hotel, car rental, rail. This may contain, for example, accessibility requests or a request for a non-smoking room. */
  specialRequest: Array<PreferenceOptions>
  /** Options available for special requests to choose from. It is array of objects, each containing special request code and label with text description. */
  specialRequestOptions?: Maybe<Array<PreferenceOptions>>
}

/** Hotel preferences */
export type HotelPreferenceInput = {
  /** Identifies amenities for hotel preferences */
  hotelAmenity?: InputMaybe<Array<Scalars['String']>>
  /** Loyalty program preferences */
  loyaltyProgram: Array<LoyaltyProgramInput>
  /** Special requests for all trave preferences */
  specialRequest: Array<PreferenceOptionsInput>
}

/** Provides details for reserved rooms and associated hotels details */
export type HotelReservation = {
  /** Represents a reservation confirmation number. */
  confirmationNumber: Scalars['ID']
  /** Indicates the Domain of the reservation. */
  domain: Domain
  /** Provides a list of details about reserved hotel rooms. */
  hotelRoomDetails: Array<ReservedHotelRoomDetails>
  /** Unique ID representing hotel reservation. */
  id: Scalars['ID']
  /** Indicates the status of the reservation. */
  status: ReservationStatus
}

/** Indicates the cost summary for the hotel reservation. */
export type HotelReservationsCostSummary = {
  /** Indicates the Domain of the cost summary. */
  domain: Domain
}

/** Hotel review information */
export type HotelReview = {
  content: Scalars['String']
  date: Scalars['String']
  ratingImageUrl: Scalars['String']
  summary: Scalars['String']
  title: Scalars['String']
  userRating?: Maybe<Scalars['Int']>
}

/** Response from "hotelReviews" request */
export type HotelReviewsResult = {
  hotelReviewsDetailsUrl?: Maybe<Scalars['String']>
  medianReviewRating?: Maybe<Scalars['Float']>
  medianReviewRatingImageUrl?: Maybe<Scalars['String']>
  reviews: Array<Maybe<HotelReview>>
  reviewsProvider: Scalars['String']
  totalReviewCount: Scalars['Int']
}

/** Room information associated with a specified hotel room. */
export type HotelRoom = {
  /** The description of the hotel room */
  description: Scalars['String']
  /** The id of the hotel room */
  hotelId: Scalars['ID']
  /** The rate of the hotel room */
  rate: RoomRate
}

export type HotelRoomDetails = {
  /** Primary address. */
  address: Address
  /** Contains property and room amenities. */
  amenities: Array<Amenity>
  /** Chain code used to retrieve room details. For example, "AL" for Aloft Hotels. */
  chainCode?: Maybe<Scalars['String']>
  /** Reservation check-in date. */
  checkIn: Scalars['String']
  /** Reservation checkout date. */
  checkOut: Scalars['String']
  /** Distance, from the geolocation used for the search, to the hotel. */
  distance: Distance
  /** Customer's negotiated amenities for the related property for a private rate hotel. */
  exclusiveAmenities?: Maybe<Array<Amenity>>
  /** Indicates whether a deposit has been placed on the room. */
  hasDeposit?: Maybe<Scalars['Boolean']>
  /** Unique id for hotel property. */
  hotelId: Scalars['ID']
  /** Indicates whether it is possible to cancel the reservation. */
  isCancellable?: Maybe<Scalars['Boolean']>
  /** Indicates whether the hotel is regarded as being eco-friendly. */
  isEcoFriendly?: Maybe<Scalars['Boolean']>
  /** Mark preferred hotel. */
  isPreferred: Scalars['Boolean']
  logoPhotoUrl?: Maybe<Scalars['String']>
  /** The median rating, returned by TripAdvisor, for the hotel specified. */
  medianReviewRating?: Maybe<Scalars['Float']>
  /** Hotel name. */
  name: Scalars['String']
  /** Customer's notes to display along with hotel for private rate hotel. */
  notes?: Maybe<Scalars['String']>
  /** Phone number of the property. */
  phoneNumber?: Maybe<Scalars['String']>
  /** Hotel photos. */
  photos: Array<Photo>
  /** Contains policy compliance information for a hotel. */
  policy?: Maybe<Policy>
  /** Preference info for a hotel. */
  preference?: Maybe<Preference>
  /** User selected room. */
  selectedRoom: Room
  /** Hotel star rating. */
  starRating: Scalars['Int']
}

export type HotelRoomDetailsInput = {
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  guests?: InputMaybe<Scalars['Int']>
  hotelId: Scalars['ID']
  hotelResultId?: InputMaybe<Scalars['String']>
  processId?: InputMaybe<Scalars['Int']>
  roomId: Scalars['ID']
}

/** The hotel search parameters enable clients to initiate a new search. */
export type HotelSearchParams = {
  /** The hotel location */
  address?: Maybe<Address>
  /** The hotel check-in date. Format: yyyy-MM-dd */
  checkIn?: Maybe<Scalars['String']>
  /** The hotel check-out date. Format: yyyy-MM-dd */
  checkOut?: Maybe<Scalars['String']>
  /** The hotel key as UUID formatted */
  hotelKey: Scalars['String']
  /** The hotel name */
  hotelName: Scalars['String']
}

export type HotelSearchRadius = {
  default: Scalars['Float']
  max: Scalars['Float']
}

export type HotelSegment = {
  address: Address
  /** Describes how Hotel segment is aligned to flight dates */
  alignState: AlignState
  /**
   * True if the hotel reservation dates are the same as the round-trip flight reservation dates.
   * @deprecated Use alignState instead.
   */
  alignedWithFlightDate: Scalars['Boolean']
  amenities: Array<Amenity>
  averageRate?: Maybe<Rate>
  baseRate?: Maybe<Rate>
  cancellationPolicy: CancellationPolicy
  cancellationPolicyDescription?: Maybe<Array<Scalars['String']>>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  dailyRates: Array<DailyRate>
  distance?: Maybe<Distance>
  exclusiveAmenities: Array<Amenity>
  feesAndTaxesRate?: Maybe<Rate>
  hasDeposit?: Maybe<Scalars['Boolean']>
  hotelId: Scalars['String']
  id: Scalars['String']
  imageUrl: Scalars['String']
  isCancellable: Scalars['Boolean']
  isEcoFriendly?: Maybe<Scalars['Boolean']>
  isPreferred?: Maybe<Scalars['Boolean']>
  isReserved?: Maybe<Scalars['Boolean']>
  medianReviewRating?: Maybe<Scalars['Float']>
  name: Scalars['String']
  /** Rate without taxes, fees and service charges. */
  netRate?: Maybe<Rate>
  numberOfGuests: NumberOfGuests
  phone?: Maybe<Scalars['String']>
  photos: Array<Photo>
  policy: Policy
  preferredDescription?: Maybe<Preference>
  rate: Rate
  rateQualifier?: Maybe<Scalars['String']>
  roomCount?: Maybe<Scalars['Int']>
  roomDescription?: Maybe<Scalars['String']>
  roomType: RoomType
  rulesAndRestrictions?: Maybe<Array<Scalars['String']>>
  segmentState?: Maybe<SegmentState>
  serviceFeeRate?: Maybe<Rate>
  source?: Maybe<Scalars['String']>
  starRating?: Maybe<Scalars['Int']>
  /** @deprecated Use segmentState instead. */
  state?: Maybe<SegmentState>
  totalCost: Money
  totalCostRate?: Maybe<Rate>
  vendorCode?: Maybe<Scalars['String']>
}

export type HotelTravelPreferenceInput = {
  amenity: Array<Scalars['String']>
  membership?: InputMaybe<Array<GazooMembership>>
  specialRequest?: InputMaybe<Array<Scalars['String']>>
}

/** Response from "hotels" request */
export type HotelsResult = {
  amenityFilters: Array<AmenityFilter>
  brandFilters: Array<BrandFilter>
  defaultGroup?: Maybe<HotelGroup>
  groups?: Maybe<Array<HotelGroup>>
  hotelResultId?: Maybe<Scalars['String']>
  hotels: Array<Hotel>
  messages?: Maybe<Array<DynamicSiteMessage>>
  perDiemRate?: Maybe<Rate>
  superBrandFilters: Array<SuperBrandFilter>
  totalCount: Scalars['Int']
  warningCodes: Array<Scalars['String']>
}

export enum ImageCropMode {
  Crop = 'CROP',
  Fill = 'FILL',
  Fit = 'FIT',
  Limit = 'LIMIT',
  Pad = 'PAD',
  Scale = 'SCALE',
}

export enum ImageFormat {
  Png = 'png',
  Svg = 'svg',
}

export type ImageInputOptions = {
  format?: InputMaybe<ImageFormat>
  size?: InputMaybe<ImageSize>
}

export type ImageSize = {
  cropMode?: InputMaybe<ImageCropMode>
  height?: InputMaybe<Scalars['Int']>
  pixelRatio?: InputMaybe<Scalars['Float']>
  width?: InputMaybe<Scalars['Int']>
}

/** Provides details about the intent. */
export type Intent = {
  /** Domain associated with the intent. */
  domain: Domain
  /** Provides details of the domain object associated with the intent. */
  domainObject: DomainDetailsUnion
  /** Unique identifier for the intent. */
  id: Scalars['ID']
  /** Provide state of the intent. */
  state: IntentState
}

/** Available compliance codes per out-of-policy item in cart. */
export type IntentComplianceCode = {
  /** Currently selected code value. Empty in case none was selected yet. */
  code?: Maybe<Scalars['String']>
  /** Indicates the Domain of intent in cart. */
  domain: Domain
  /** Reference to out-of-policy intent in cart. */
  intentId: Scalars['ID']
  /** Compliance code option for user to select for this intent. */
  options: Array<ComplianceCodeOption>
}

/** Includes parameters required to return cost details for a specific domain. */
export type IntentCost = {
  /** Base rate of the travel intent. */
  baseCost: Rate
  /** Segment associated with the trip (air, hotel, car rental, etc.). */
  domain: Domain
  /** Number of travelers associated with this travel intent. */
  passengerCount?: Maybe<Scalars['Int']>
  /** Service fee associated with this travel intent. */
  serviceFee?: Maybe<Rate>
  /** Taxes associated with this travel intent. */
  taxes?: Maybe<Rate>
  /** Total cost of the travel intent. */
  totalCost: Rate
  /** Transaction fee associated with this travel intent. */
  transactionFee?: Maybe<Rate>
}

/** State of intent */
export enum IntentState {
  Added = 'ADDED',
  Modified = 'MODIFIED',
  Unspecified = 'UNSPECIFIED',
}

export enum IntentType {
  Cancel = 'CANCEL',
  Modify = 'MODIFY',
  Reserve = 'RESERVE',
  Unspecified = 'UNSPECIFIED',
}

/** Error indicating an issue with the passport input */
export type InvalidInputError = Error & {
  /** Array or affected fields */
  fields: Array<FieldError>
  /** A message briefly explaining the problem. Only meant for developers */
  message: Scalars['String']
}

export type Itinerary = {
  /** @deprecated Use priceChanges.air instead. */
  airPriceChangeInfo?: Maybe<PriceChangeInfo>
  availableSegments?: Maybe<AvailableSegments>
  /** @deprecated Use priceChanges.car instead. */
  carPriceChangeInfo?: Maybe<PriceChangeInfo>
  complianceMessage?: Maybe<ComplianceMessage>
  continuityMessage?: Maybe<Array<ContinuityMessage>>
  expiredAt: Scalars['String']
  flightId?: Maybe<Scalars['String']>
  holdRules?: Maybe<HoldRules>
  hotelLocationInformation?: Maybe<Array<HotelLocationInformation>>
  isMultiCity: Scalars['Boolean']
  isMultiDestination: Scalars['Boolean']
  isOnHold?: Maybe<Scalars['Boolean']>
  /** True if the hotel and car rental reservation dates are the same as the round-trip flight reservation dates. */
  isTripAligned: Scalars['Boolean']
  itineraryId: Scalars['String']
  itineraryItemResponse: Array<ItineraryItem>
  messages?: Maybe<Array<DynamicSiteMessage>>
  overlappingTrips?: Maybe<Array<OverlappingTrip>>
  priceChanges: ItineraryPriceChanges
  railCards?: Maybe<Array<RailCard>>
  /** @deprecated Use segments.[X].trainLeg.routeType instead */
  routeType?: Maybe<RouteType>
  segments?: Maybe<Array<Segment>>
  trip: ItineraryTrip
  tripCost?: Maybe<TripCostSummary>
  tripLevelPolicy?: Maybe<Array<Policy>>
  tripStatus?: Maybe<TripPostReservation>
  virtualPay?: Maybe<VirtualPay>
}

export type ItineraryItem = {
  code: Scalars['String']
  fieldName?: Maybe<Scalars['String']>
  severity: Scalars['String']
}

/** The metadata provides information about the aligned itinerary, indicating whether a match was found or if there was no exact match with the search parameters. This information is useful for clients who may need to initiate a new search. */
export type ItineraryMetadata = {
  /** Segment types that were aligned with the flight reservation. */
  alignAttemptedSegmentTypes: Array<TravelVertical>
  /** Indicates that car rental is match or not. */
  carRentalMatchDetails?: Maybe<MatchDetails>
  /** The car rental search parameters enable clients to initiate a new search. */
  carRentalSearch?: Maybe<CarRentalSearchParams>
  /** Indicates that flight is match or not. */
  flightMatchDetails?: Maybe<FlightMatchDetails>
  /** The flight search parameters enable clients to initiate a new search. */
  flightSearch?: Maybe<Array<FlightSearchParams>>
  /** Indicates that hotel is match or not. */
  hotelMatchDetails?: Maybe<HotelMatchDetails>
  /** The hotel search parameters enable clients to initiate a new search. */
  hotelSearch?: Maybe<HotelSearchParams>
}

/** Structured error response to indicate the requested itinerary was not found. */
export type ItineraryNotFoundResponse = Error & {
  /** Provides requested itinerary id */
  itineraryId: Scalars['String']
  /** Provides additional details about the error response. */
  message: Scalars['String']
}

export type ItineraryPriceChanges = {
  air?: Maybe<SegmentPriceChangeDetails>
  car?: Maybe<SegmentPriceChangeDetails>
}

export type ItinerarySegmentsInputDto = {
  air?: InputMaybe<SegmentInputDto>
  carRental?: InputMaybe<Array<SegmentInputDto>>
  hotel?: InputMaybe<Array<SegmentInputDto>>
  rail?: InputMaybe<SegmentInputDto>
}

export type ItineraryTrainSegmentSeatInfo = {
  carriage: Scalars['String']
  seat: Scalars['String']
}

export type ItineraryTrip = {
  endDate?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  isNameEditable: Scalars['Boolean']
  name: Scalars['String']
  startDate: Scalars['String']
}

/** The journey direction of the trip */
export enum JourneyDirection {
  Inward = 'inward',
  Outward = 'outward',
  Unspecified = 'unspecified',
}

/** A combination of searchId and JourneyId, this identifies a journey during the shopping flow. */
export type JourneyIdentifier = {
  fareId: Scalars['String']
  journeyId: Scalars['String']
}

/** Contains additional documentation associated with known traveler number. */
export type KnownTravelerNumber = {
  /** Country of issuance for the Known Traveler Number */
  country: Scalars['String']
  /** Expiration date for the Known Traveler Number */
  expirationDate: Scalars['String']
  /** Our Unique identifier for the Known Traveler Number Document */
  id: Scalars['Float']
  /** Unique identifier for the User Known Traveler Number */
  number: Scalars['String']
  /** Pre-expiration alert for the Known Traveler Number */
  preExpirationAlert: Scalars['String']
}

/** Contains additional documentation associated with known traveler number. */
export type KnownTravelerNumberInput = {
  /** Country of issuance for the Known Traveler Number */
  country: Scalars['String']
  /** Expiration date for the Known Traveler Number */
  expirationDate: Scalars['String']
  /** Unique identifier for the Known Traveler Number, it matches with number */
  id?: InputMaybe<Scalars['String']>
  /** Unique identifier for the User Known Traveler Number */
  number: Scalars['String']
  /** Pre-expiration alert for the Known Traveler Number */
  preExpirationAlert: Scalars['String']
}

export type Language = {
  code: Scalars['String']
  value: Scalars['String']
}

export type LegSelectionInput = {
  id: Scalars['String']
  position: Scalars['LegPosition']
}

export type ListAdditionalInformation = {
  defaultValue: Scalars['String']
  isAlphabeticallySorted: Scalars['Boolean']
  listAlignment: AdditionalInformationListAlignmentEnum
  listType: AdditionalInformationListTypeEnum
  options?: Maybe<Array<CustomFieldOption>>
  value?: Maybe<Scalars['String']>
}

export type LocalizedFareConditions = {
  conditions: Array<Conditions>
  fareTypeDescription: Scalars['String']
  language: Scalars['String']
  summaryConditions: Scalars['String']
  validityPeriod: Scalars['String']
}

export enum Location {
  Airport = 'AIRPORT',
  Private = 'PRIVATE',
}

/** Location Refinement Access Point is an object containing the access point information. */
export type LocationRefinementAccessPoint = {
  /** The unique ID of the access point */
  id: Scalars['String']
  /** The name of the access point, e.g. "Electioneer Rd" */
  label: Scalars['String']
  /** The latitude/longitude or address of the access point */
  location: LocationRefinementLocation
  /** A note that helps the traveller find the access point */
  note: Scalars['String']
}

/** LocationRefinementErrorResponse is returned from location refinement options request with a well-defined reason */
export type LocationRefinementErrorResponse = {
  /** Error reason */
  reason: LocationRefinementOptionsErrorReason
}

/** Location information for pickup/dropoff refinement */
export type LocationRefinementLocation = {
  /** Full street address, e.g. 3332 Clover Drive, Kent, Oregon, US 97033 */
  address?: Maybe<Scalars['String']>
  /** Geographical location (latitude/longitude) */
  geocode?: Maybe<Geocode>
}

/** Defines error reasons of getting location refinement failures */
export enum LocationRefinementOptionsErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** Provides the input parameters to get location refinement options */
export type LocationRefinementOptionsRequest = {
  /** Specific location for refinement */
  location: RideHailLocation
  /** Location refinement type e.g. Pick up and Drop off */
  locationRefinementType: RideHailLocationRefinementType
  /** Ride hail provider, for example, Uber */
  provider: RideHailProvider
  /** The unique ID of the produce being requested which will be determined by provider name at the RHSS */
  searchResultID: Scalars['ID']
}

/** Get Location Refinement Response */
export type LocationRefinementOptionsResponse = {
  /** Refinement information specific to Uber which can be passed during a Search/Book request to ensure an accurate pick-up/drop-off */
  zoneInformation: LocationRefinementZoneInformation
}

/** LocationRefinementResponseUnion is either the success or failure getting location refinement options response */
export type LocationRefinementResponseUnion =
  | LocationRefinementErrorResponse
  | LocationRefinementOptionsResponse
  | UnexpectedError

/** Location Refinement Zone is a Root object containing zone information. */
export type LocationRefinementZone = {
  /** The actual array of access point locations. */
  accessPoints: Array<LocationRefinementAccessPoint>
  /** Array of location refinement child zones that are children of the location refinement root zone */
  childZones: Array<LocationRefinementZone>
  /** The latitude/longitude of the zone */
  location: LocationRefinementLocation
  /** The name of the zone, e.g. “John F. Kennedy International Airport" */
  name: Scalars['String']
  /** A string representing a list of coordinates that enclose the zone, encoded in Encoded Polyline Algorithm Format. e.g "orqdFhw~iVy@}@p@wD]}CuAuBcBaAJUGCb@qAlBlA~AtBr@tDUtDs@fC" */
  polygon: Scalars['String']
}

/** Refinement information specific to Uber which can be passed during a Search/Book request to ensure an accurate pick-up/drop-off */
export type LocationRefinementZoneInformation = {
  /** Top level refinement of access points for a pick-up or drop-off. */
  accessPoints: Array<LocationRefinementAccessPoint>
  /** Root object containing zone information. */
  rootZone?: Maybe<LocationRefinementZone>
}

export type LogEventInput = {
  payload: Scalars['String']
  type: LogEventType
}

export enum LogEventType {
  AddCarRental = 'ADD_CAR_RENTAL',
  AddCarService = 'ADD_CAR_SERVICE',
  HotelSearch = 'HOTEL_SEARCH',
  HotelSearchFilter = 'HOTEL_SEARCH_FILTER',
  MobilityEntrypointSubmitted = 'MOBILITY_ENTRYPOINT_SUBMITTED',
  RecommendationsLoaded = 'RECOMMENDATIONS_LOADED',
  RecommendationsShown = 'RECOMMENDATIONS_SHOWN',
  TripReviewSessionEnd = 'TRIP_REVIEW_SESSION_END',
  UberProductSelected = 'UBER_PRODUCT_SELECTED',
}

export type Login = {
  passwordExpirationDate?: Maybe<Scalars['Date']>
  passwordExpirationDaysRemaining: Scalars['Int']
  token: Scalars['String']
}

export type LoginByCredentialsInput = {
  password: Scalars['String']
  siteId?: InputMaybe<Scalars['String']>
  username: Scalars['String']
}

export type LoginByKeyInput = {
  key: Scalars['String']
}

export type LoginBySsoTokenInput = {
  ssoToken: Scalars['String']
}

export type LogsMetadata = {
  itineraryId?: InputMaybe<Scalars['String']>
}

export type LoyaltyProgram = {
  /** Optional icon related to the provider of the loyalty program. */
  imgUrl?: Maybe<Scalars['String']>
  /** Name of brand loyalty program. */
  label?: Maybe<Scalars['String']>
  /** Customer's membership ID. */
  number?: Maybe<Scalars['String']>
  /** Identifies provider of the loyalty program. */
  vendorCode: Scalars['String']
}

/** Loyalty program preferences */
export type LoyaltyProgramInput = {
  /** Name of brand loyalty program. */
  label?: InputMaybe<Scalars['String']>
  /** Customer's membership ID. */
  number?: InputMaybe<Scalars['String']>
  /** Identifies provider of the loyalty program. */
  vendorCode: Scalars['String']
}

export type LuggageOption = {
  code: Scalars['String']
  cost?: Maybe<Rate>
  value: Scalars['String']
}

export type LuggageOptionInput = {
  code: Scalars['String']
  value: Scalars['String']
}

/** Provides the details of the manager of the employee field in the user's profile */
export type Manager = {
  /** Manager external ID of the user */
  externalId?: Maybe<Scalars['String']>
  /** Manager ID of the user */
  id: Scalars['String']
}

/** Provides the input parameters for the manager in profile employee field */
export type ManagerInput = {
  /** Manager external ID of the user */
  externalId?: InputMaybe<Scalars['String']>
  /** Manager ID of the user */
  id: Scalars['String']
}

export type MapKitToken = {
  token: Scalars['String']
}

export enum MapProvider {
  AppleMapkit = 'AppleMapkit',
  GoogleMaps = 'GoogleMaps',
}

/** The result of matching the reservation with the dates of the flight reservation. */
export type MatchDetails = {
  /** Indicates whether the reserved item matches the pre-aligned reservation item precisely, such as the same hotel, room type, and room rate. */
  isExactMatch: Scalars['Boolean']
  /** Indicates whether the reservation item matches the dates of the flight reservation. */
  matchFound: Scalars['Boolean']
}

/** Type(s) of meal available for the class of service, if any. */
export enum MealService {
  AlcoholicBeveragesForPurchase = 'ALCOHOLIC_BEVERAGES_FOR_PURCHASE',
  Breakfast = 'BREAKFAST',
  ColdMeal = 'COLD_MEAL',
  ComplimentaryAlcoholicBeverages = 'COMPLIMENTARY_ALCOHOLIC_BEVERAGES',
  ContinentalBreakfast = 'CONTINENTAL_BREAKFAST',
  Dinner = 'DINNER',
  FoodAndBeverageForPurchase = 'FOOD_AND_BEVERAGE_FOR_PURCHASE',
  FoodForPurchase = 'FOOD_FOR_PURCHASE',
  HotMeal = 'HOT_MEAL',
  Lunch = 'LUNCH',
  Meal = 'MEAL',
  NoMealService = 'NO_MEAL_SERVICE',
  Refreshments = 'REFRESHMENTS',
  RefreshmentsForPurchase = 'REFRESHMENTS_FOR_PURCHASE',
  Snack = 'SNACK',
  Unspecified = 'UNSPECIFIED',
}

export enum MealType {
  AlcoholicBeverageForPurchase = 'ALCOHOLIC_BEVERAGE_FOR_PURCHASE',
  Breakfast = 'BREAKFAST',
  ColdMeal = 'COLD_MEAL',
  ComplimentaryAlcoholicBeverages = 'COMPLIMENTARY_ALCOHOLIC_BEVERAGES',
  ContinentalBreakfast = 'CONTINENTAL_BREAKFAST',
  Dinner = 'DINNER',
  FoodAndBeverageForPurchase = 'FOOD_AND_BEVERAGE_FOR_PURCHASE',
  FoodForPurchase = 'FOOD_FOR_PURCHASE',
  HotMeal = 'HOT_MEAL',
  Lunch = 'LUNCH',
  Meal = 'MEAL',
  NoMealService = 'NO_MEAL_SERVICE',
  Refreshments = 'REFRESHMENTS',
  RefreshmentsForPurchase = 'REFRESHMENTS_FOR_PURCHASE',
  Snack = 'SNACK',
}

/** Measurement system with one specific set of units in the metric and imperial unit systems. */
export enum MeasurementSystem {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
  Unspecified = 'UNSPECIFIED',
}

export type MeetingCustomField = {
  /** Meeting custom field name. */
  name: Scalars['String']
  /** Meeting custom field value. */
  value: Scalars['String']
}

export type MeetingInfo = {
  /** Confirmation number of the meeting. */
  confirmationNumber: Scalars['String']
  /** Custom fields of the meeting. */
  customFields: Array<MeetingCustomField>
  /** The travel date range defined for the meeting. */
  dates: DateRange
  /** External identifier of the meeting. */
  externalId?: Maybe<Scalars['String']>
  /** Identifier of the meeting. */
  id: Scalars['String']
  /** Location of the meeting { address, city, ... }. */
  location: Scalars['String']
  /** Message of the meeting. */
  message?: Maybe<Scalars['String']>
  /** Name of the meeting. */
  name: Scalars['String']
  /** Policies to apply when booking for the meeting. */
  policies: MeetingPolicies
  /** Provider of the meeting. */
  provider: MeetingProvider
}

export type MeetingPolicies = {
  /** Range of dates that can be booked to attend the meeting. */
  arrival?: Maybe<DateRange>
  /** Range of dates that can be booked after attending the meeting. */
  departure?: Maybe<DateRange>
  /** Policies to be applied for flights. Must exist if isFlightEnabled = true. */
  flightPolicies?: Maybe<FlightPolicies>
  /** Indicates if the rental car booking is enabled. */
  isCarEnabled: Scalars['Boolean']
  /** Indicates if the flight booking is enabled. */
  isFlightEnabled: Scalars['Boolean']
  /** Indicates if the hotel booking is enabled. */
  isHotelEnabled: Scalars['Boolean']
}

export enum MeetingProvider {
  CventProvider = 'CVENT_PROVIDER',
  UnknownProvider = 'UNKNOWN_PROVIDER',
}

export type MeetingsModeSessionElements = {
  flags?: InputMaybe<Scalars['String']>
  meetingName?: InputMaybe<Scalars['String']>
  meetingUserName?: InputMaybe<Scalars['String']>
}

export type MembershipInputDto = {
  air?: InputMaybe<Array<MembershipSegmentInputDto>>
  carRental?: InputMaybe<Array<MembershipSegmentInputDto>>
  hotel?: InputMaybe<Array<MembershipSegmentInputDto>>
}

export type MembershipSegmentInputDto = {
  appliesToChains?: InputMaybe<Array<Scalars['String']>>
  code: Scalars['String']
  number: Scalars['String']
}

export type MessageDescription = {
  code?: Maybe<Scalars['String']>
  severity?: Maybe<MessageSeverity>
}

export enum MessageSeverity {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

/** Contains generic management information system (MIS) reporting fields */
export type Mis = {
  mis1?: Maybe<Scalars['String']>
  mis2?: Maybe<Scalars['String']>
  mis3?: Maybe<Scalars['String']>
  mis4?: Maybe<Scalars['String']>
  mis5?: Maybe<Scalars['String']>
  mis6?: Maybe<Scalars['String']>
  mis7?: Maybe<Scalars['String']>
}

/** Contains input for generic management information system (MIS) reporting fields */
export type MisInput = {
  mis1?: InputMaybe<Scalars['String']>
  mis2?: InputMaybe<Scalars['String']>
  mis3?: InputMaybe<Scalars['String']>
  mis4?: InputMaybe<Scalars['String']>
  mis5?: InputMaybe<Scalars['String']>
  mis6?: InputMaybe<Scalars['String']>
  mis7?: InputMaybe<Scalars['String']>
}

/** Deprecated. Use mis fields 1 to 7 instead. */
export type MiscellaneousFields = {
  /** Miscellaneous profile attribute that can be configured at the domain level. */
  field3?: InputMaybe<Scalars['String']>
  /** Miscellaneous profile attribute that can be configured at the domain level. */
  field4?: InputMaybe<Scalars['String']>
}

export type MobileSupportDisplayConfiguration = {
  email?: Maybe<EmailSupport>
  phoneNumbers: Array<PhoneSupport>
}

/** Location information, required when available routes are requested from the MultiModalService Phoenix API. */
export type MobilityMultiModalLocation = {
  /** Required if location_type is address or place. Full street address, e.g. 3332 Clover Drive, Kent, Oregon, US 97033 */
  address?: Maybe<Scalars['String']>
  /** Required if location_type is geocode or place. Geographical location (latitude/longitude). */
  geocode?: Maybe<Geocode>
  /** Required if location_type is place. */
  placeId?: Maybe<Scalars['String']>
}

export type MobilityMultiModalLocationInput = {
  /** Required if location_type is address or place. Full street address, e.g. 3332 Clover Drive, Kent, Oregon, US 97033 */
  address?: InputMaybe<Scalars['String']>
  /** Required if location_type is geocode or place. Geographical location (latitude/longitude). */
  geocode?: InputMaybe<GeocodeInput>
  /** Required if location_type is place. */
  placeId?: InputMaybe<Scalars['String']>
}

/** Details of a route result. */
export type MobilityMultiModalRoutes = {
  /** the destination of the route */
  endLocation: MobilityMultiModalLocation
  /** Estimated price. Can be null when route has no cost, e.g. Walking route, */
  estimatePrice: Money
  /** Estimate duration of traveling time in second. */
  estimatedDuration: Scalars['Float']
  /** Route description e.g. "Via mission street". */
  routeDescription: Scalars['String']
  /** Length of route from origin to destination in meters. */
  routeDistance: Scalars['Float']
  /** Route name */
  routeName: Scalars['String']
  /** The form of travel taken for a part of the trip. */
  routeType: MultiModalRouteType
  /** The origin of the route */
  startLocation: MobilityMultiModalLocation
}

/** Defines error reasons of search multi modal failures */
export enum MobilityMultiModalSearchErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

export type MobilityMultiModalSearchErrorResponse = {
  /** Error reason */
  reason: MobilityMultiModalSearchErrorReason
}

export type MobilityMultiModalSearchRequest = {
  /** Arrival date */
  arrivalDate?: InputMaybe<Scalars['Date']>
  /** Arrival time */
  arrivalTime?: InputMaybe<Scalars['TimeOfDay']>
  /** Departure date */
  departureDate?: InputMaybe<Scalars['Date']>
  /** Departure time */
  departureTime?: InputMaybe<Scalars['TimeOfDay']>
  /** Destination location. */
  destination: MobilityMultiModalLocationInput
  /** Origin location. */
  origin: MobilityMultiModalLocationInput
}

/** MobilityMultiModalSearchResponse is returned when the searchMultiModal query succeeds */
export type MobilityMultiModalSearchResponse = {
  /** List of route results. */
  routes: Array<MobilityMultiModalRoutes>
}

/** MobilityMultiModalSearchResponseDtoUnion is either success or failure search multi modal response */
export type MobilityMultiModalSearchResponseDtoUnion =
  | MobilityMultiModalSearchErrorResponse
  | MobilityMultiModalSearchResponse
  | UnexpectedError

export type Money = {
  amount: Scalars['Float']
  currency: Scalars['String']
}

export type MoneyRange = {
  amountMax: Scalars['Float']
  amountMin: Scalars['Float']
  currency: Scalars['String']
}

export type MultiLayerCostAllocation = {
  allocation: Array<MultiLayerCostAllocationUnit>
  totalResults?: Maybe<Scalars['Float']>
}

export type MultiLayerCostAllocationInput = {
  pageSize?: InputMaybe<Scalars['Float']>
  parentId?: InputMaybe<Scalars['Float']>
  query?: InputMaybe<Scalars['String']>
  segmentId?: InputMaybe<Scalars['Float']>
  url?: InputMaybe<Scalars['String']>
}

export type MultiLayerCostAllocationStats = {
  activeChildren?: Maybe<Scalars['Float']>
  inactiveChildren?: Maybe<Scalars['Float']>
  totalChildren?: Maybe<Scalars['Float']>
}

export type MultiLayerCostAllocationUnit = {
  allocationId: Scalars['Float']
  childrenRef?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name: Scalars['String']
  parentRef?: Maybe<Scalars['String']>
  segmentId: Scalars['Float']
  stats?: Maybe<MultiLayerCostAllocationStats>
}

export type MultiLayerCostSegments = {
  bottomLayer: Array<Scalars['Float']>
  firstChildLayer?: Maybe<Array<Scalars['Float']>>
  secondChildLayer?: Maybe<Array<Scalars['Float']>>
  topLayer: Array<Scalars['Float']>
}

/** The form of travel taken for a part of the trip. */
export enum MultiModalRouteType {
  Driving = 'DRIVING',
  Transit = 'TRANSIT',
  Unspecified = 'UNSPECIFIED',
}

export type MultiTierCostAllocationInput = {
  pageSize?: InputMaybe<Scalars['Int']>
  query?: InputMaybe<Scalars['String']>
}

export type Mutation = {
  ackNotificationEvent: BaseResponse
  addCarRental: BaseResponse
  addCarService: BaseResponse
  addCreditCard: BaseResponse
  addFlight: AddFlightResponse
  /** addModifyToCart mutation is used to add a new modify intent to an existing cart. */
  addModifyToCart: AddModifyToCartResponseUnion
  /** @deprecated Use addPassport instead */
  addNewPassport: BaseResponse
  addPassport: AddPassportResponse
  addPaymentCard: AddPaymentCardResponse
  addRail: AddRailResponse
  addSeatAssignment: BaseResponse
  /** addToCart mutation is used to add a new reservation intent to an existing cart. */
  addToCart: AddToCartResponseUnion
  airTravelPreference: BaseResponse
  /** alignTrip mutation is used for the alignment of dates for car rental and hotel to the flight reservation. */
  alignTrip: AlignTripResult
  applyUnusedTicket: BaseResponse
  /** bookAgainTrip mutation is used for the rebook trip. */
  bookAgainTrip: BookAgainTripResult
  /** @deprecated Use `purchaseItinerary` query instead. */
  bookItinerary: BaseResponse
  /** @deprecated 'bookRailItinerary' Not in use. */
  bookRailItinerary: BaseResponse
  bookRideHailWithError: BookRideHailResponseUnion
  /** cancelReservation mutation cancels an existing reservation. */
  cancelReservation: CancelReservationResponseUnion
  cancelRideHail: CancelRideHailResponse
  cancelRideHailByProcessIdWithError: CancelRideHailByProcessIdResponseDtoUnion
  cancelRideHailWithError: CancelRideHailResponseDtoUnion
  cancelTrip: BaseResponse
  cancelTripSegment: BaseResponse
  carRentalTravelPreference: BaseResponse
  changeFlight: ChangeFlightResponse
  changeFlightSeats: BaseResponse
  changePassword: ChangePasswordErrorResponse
  changeSeatsAssignment: BaseResponse
  /** @deprecated We no longer use this, as it doesn't meet the requirements and has introduced an issue. */
  checkFarePolicy: CheckFarePolicyResponseDtoUnion
  /** checkoutCart mutation is used to checkout/fulfill the intents in an existing cart. */
  checkoutCart: CheckoutCartResponseUnion
  checkoutItinerary: CheckoutItineraryResponseUnion
  clearDomainRcSessionCookie: Scalars['Boolean']
  clearMeetingInfo: BaseResponse
  clearSiteRcSessionCookie: Scalars['Boolean']
  createCarRentalSearch: CarRentalsResult
  /** createCart mutation creates an empty cart for the user. */
  createCart: CreateCartResponseUnion
  /** createCustomerUberAccount registers a traveller within their company's Uber for Business (U4B) organization using their email address from the user profile service. This method may only be called if the response from the customerUberAccountStatus query indicated it is available, otherwise an error will be returned. */
  createCustomerUberAccount: CreateCustomerUberAccountResponseUnion
  createFlightSearch?: Maybe<CreateFlightSearchPayload>
  createItinerary: CreateItineraryResult
  createMapKitToken: MapKitToken
  /** @deprecated 'createRailItinerary' Not in use. */
  createRailItinerary: CreateItineraryResult
  createRailSearch?: Maybe<CreateRailSearchPayload>
  createSearch: CreateSearchResult
  deleteCreditCard: BaseResponse
  editCreditCard: BaseResponse
  hotelTravelPreference: BaseResponse
  logEvent: BaseResponse
  login: Login
  loginByCredentials: Login
  loginByKey: Login
  loginBySSOToken: Login
  loginBySessionId: Login
  logout: Scalars['Boolean']
  /** @deprecated Use `preBookInfo` query instead. */
  preBookInfo: PreBookInfoPayload
  purchaseItinerary: PurchaseItineraryResponse
  reactivateItinerary: BaseResponse
  registerClientForNotification: RegisterNotificationResponse
  registerPushNotificationClient: BaseResponse
  removeCarRental: BaseResponse
  removeCarService: BaseResponse
  removeFlight: BaseResponse
  /** removeFromCart mutation is used to remove an intent from an existing cart. */
  removeFromCart: RemoveFromCartResponseUnion
  removeItinerary: BaseResponse
  /** removeNationalIdCard mutation is used to remove an irrelevant national id card (e.g. expired or added by mistake) from the list of user documents. */
  removeNationalIdCard: RemoveNationalIdCardResponse
  removePassport: BaseResponse
  removeRail: BaseResponse
  removeRoom: BaseResponse
  removeSeatAssignment: BaseResponse
  removeUnusedTicket: BaseResponse
  /** removeVisa mutation is used to remove an irrelevant visa (e.g. expired or added by mistake) from the list of user documents. */
  removeVisa: RemoveVisaResponse
  replaceCarRental: BaseResponse
  replaceFlight: BaseResponse
  /** removeFromCart mutation is used to remove an intent from an existing cart. */
  replaceIntent: ReplaceIntentResponseUnion
  replaceRoom: BaseResponse
  resetPassword: ResetPasswordResponse
  resetPasswordWithError: ResetPasswordErrorResponseUnion
  selectRoom: BaseResponse
  sendOopCodes: BaseResponse
  sendPushNotification: BaseResponse
  /** Set cost allocation for cart. */
  setCostAllocation: SetCostAllocationResponseUnion
  /** Set custom fields for cart. */
  setCustomFields: SetCustomFieldsResponseUnion
  /** setOutOfPolicyJustification sets compliance codes and explanation to justify reserving out-of-policy items. Replaces any previously set values */
  setOutOfPolicyJustification: SetOutOfPolicyJustificationResponseUnion
  setPassword: SetPasswordErrorResponse
  setRcSessionCookie: Scalars['Boolean']
  setRcSessionCookieWithCookie: Scalars['Boolean']
  submitNewCarRentalSearch: SubmitNewCarRentalSearchUnion
  submitNewFlightSearch: SubmitNewFlightSearchUnion
  submitNewHotelSearch: SubmitNewHotelSearchUnion
  submitNewRailSearch: SubmitNewRailSearchResultUnion
  toggleNotificationPreference: BaseResponse
  uberEstimate: UberEstimateResult
  unregisterClientFromNotification: BaseResponse
  updateAirPreferenceExcerpt: BaseResponse
  updateAuthorizers: BaseResponse
  updateDelegatedIdCookie: UpdateDelegatedIdCookieResponse
  updateDelegatedIdToken: UpdateDelegatedIdTokenResponse
  updateDelegates: BaseResponse
  updateMeetingIdCookie: UpdateMeetingIdCookieResponse
  updateNationalIdCard: UpdateNationalIdCardResponse
  updatePassport: UpdatePassportResponse
  updateRailJourneyPreferences: BaseResponse
  updateTokens: UpdateTokensResponse
  updateTokensWithCookie: Scalars['Boolean']
  updateTripName: BaseResponse
  updateTripNameByProcessId: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfileBusinessAddress: BaseResponse
  /**
   * Contact information info managed in `updateUserProfileBusinessAddress` mutation
   * @deprecated Contact information info managed in updateUserProfileBusinessAddress mutation
   */
  updateUserProfileContactInformation: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfileEmergencyContact: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfileEmployeeInformation: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfileHomeAddress: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfilePersonalInformation: BaseResponse
  /** Currently only additional information is handled */
  updateUserProfileTravelPreferences: BaseResponse
  updateVisa: UpdateVisaResponse
  upsertMeetingInfo: UpsertMeetingInfoResponseUnion
  user: BaseResponse
}

export type MutationAckNotificationEventArgs = {
  input: AckNotificationEventInput
}

export type MutationAddCarRentalArgs = {
  input: AddCarRentalInput
}

export type MutationAddCarServiceArgs = {
  input: AddCarServiceInput
}

export type MutationAddCreditCardArgs = {
  input: AddCreditCardInput
}

export type MutationAddFlightArgs = {
  input: AddFlightInput
}

export type MutationAddModifyToCartArgs = {
  input: AddModifyToCartInput
}

export type MutationAddNewPassportArgs = {
  input?: InputMaybe<AddNewPassportInput>
}

export type MutationAddPassportArgs = {
  input?: InputMaybe<AddPassportInput>
}

export type MutationAddPaymentCardArgs = {
  input: AddPaymentCardInput
}

export type MutationAddRailArgs = {
  input: AddRailInput
}

export type MutationAddSeatAssignmentArgs = {
  input?: InputMaybe<AddSeatAssignmentInput>
}

export type MutationAddToCartArgs = {
  input: AddToCartInput
}

export type MutationAirTravelPreferenceArgs = {
  input?: InputMaybe<AirTravelPreferenceInput>
}

export type MutationAlignTripArgs = {
  input: AlignTripInput
}

export type MutationApplyUnusedTicketArgs = {
  input: UnusedTicketInput
}

export type MutationBookAgainTripArgs = {
  input: BookAgainTripInput
}

export type MutationBookItineraryArgs = {
  input: BookItineraryInput
}

export type MutationBookRailItineraryArgs = {
  input: BookItineraryInput
}

export type MutationBookRideHailWithErrorArgs = {
  input: BookRideHailRequest
}

export type MutationCancelReservationArgs = {
  input: CancelReservationInput
}

export type MutationCancelRideHailArgs = {
  input: CancelRideHailInput
}

export type MutationCancelRideHailByProcessIdWithErrorArgs = {
  input: CancelRideHailByProcessIdRequest
}

export type MutationCancelRideHailWithErrorArgs = {
  input: CancelRideHailInput
}

export type MutationCancelTripArgs = {
  input?: InputMaybe<CancelTripInput>
}

export type MutationCancelTripSegmentArgs = {
  input: CancelTripSegmentInput
}

export type MutationCarRentalTravelPreferenceArgs = {
  input?: InputMaybe<CarRentalTravelPreferenceInput>
}

export type MutationChangeFlightArgs = {
  input: ChangeFlightInput
}

export type MutationChangeFlightSeatsArgs = {
  input: ChangeFlightSeatsInput
}

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MutationChangeSeatsAssignmentArgs = {
  input?: InputMaybe<ChangeSeatsAssignmentInput>
}

export type MutationCheckFarePolicyArgs = {
  input: CheckFarePolicyInput
}

export type MutationCheckoutCartArgs = {
  input: CheckoutCartInput
}

export type MutationCheckoutItineraryArgs = {
  input: CheckoutItineraryInput
}

export type MutationCreateCarRentalSearchArgs = {
  input: CarRentalsInput
}

export type MutationCreateFlightSearchArgs = {
  input: CreateFlightSearchInput
}

export type MutationCreateItineraryArgs = {
  input?: InputMaybe<CreateItineraryInput>
}

export type MutationCreateRailItineraryArgs = {
  input?: InputMaybe<CreateItineraryInput>
}

export type MutationCreateRailSearchArgs = {
  input: CreateRailSearchInput
}

export type MutationCreateSearchArgs = {
  airportCode?: InputMaybe<Scalars['String']>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  distance?: InputMaybe<Scalars['Float']>
  distanceUnit?: InputMaybe<Scalars['String']>
  guests: Scalars['Int']
  hotelName?: InputMaybe<Scalars['String']>
  itineraryId: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  searchBy: Scalars['Int']
}

export type MutationDeleteCreditCardArgs = {
  input: DeleteCreditCardInput
}

export type MutationEditCreditCardArgs = {
  input: EditCreditCardInput
}

export type MutationHotelTravelPreferenceArgs = {
  input?: InputMaybe<HotelTravelPreferenceInput>
}

export type MutationLogEventArgs = {
  input: LogEventInput
}

export type MutationLoginByCredentialsArgs = {
  input: LoginByCredentialsInput
}

export type MutationLoginByKeyArgs = {
  input: LoginByKeyInput
}

export type MutationLoginBySsoTokenArgs = {
  input: LoginBySsoTokenInput
}

export type MutationLoginBySessionIdArgs = {
  sessionId: Scalars['String']
}

export type MutationPreBookInfoArgs = {
  input: BookItineraryInput
}

export type MutationPurchaseItineraryArgs = {
  input: BookItineraryInput
}

export type MutationReactivateItineraryArgs = {
  input: ReactivateItineraryInput
}

export type MutationRegisterClientForNotificationArgs = {
  input: RegisterClientForNotificationInput
}

export type MutationRegisterPushNotificationClientArgs = {
  input: RegisterPushNotificationClientInput
}

export type MutationRemoveCarRentalArgs = {
  input?: InputMaybe<RemoveCarRentalInput>
}

export type MutationRemoveCarServiceArgs = {
  input?: InputMaybe<RemoveCarServiceInput>
}

export type MutationRemoveFlightArgs = {
  input?: InputMaybe<RemoveFlightInput>
}

export type MutationRemoveFromCartArgs = {
  input: RemoveFromCartInput
}

export type MutationRemoveItineraryArgs = {
  itineraryId?: InputMaybe<Scalars['String']>
}

export type MutationRemoveNationalIdCardArgs = {
  input: RemoveNationalIdCardInput
}

export type MutationRemovePassportArgs = {
  input?: InputMaybe<RemovePassportInput>
}

export type MutationRemoveRailArgs = {
  input?: InputMaybe<RemoveRailInput>
}

export type MutationRemoveRoomArgs = {
  itineraryId: Scalars['String']
  roomKey: Scalars['String']
}

export type MutationRemoveSeatAssignmentArgs = {
  input?: InputMaybe<RemoveSeatAssignmentInput>
}

export type MutationRemoveUnusedTicketArgs = {
  input: UnusedTicketInput
}

export type MutationRemoveVisaArgs = {
  input: RemoveVisaInput
}

export type MutationReplaceCarRentalArgs = {
  input: ReplaceCarRentalInput
}

export type MutationReplaceFlightArgs = {
  input: ReplaceFlightInput
}

export type MutationReplaceIntentArgs = {
  input: ReplaceIntentInput
}

export type MutationReplaceRoomArgs = {
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  customFields?: InputMaybe<Array<CustomFieldInput>>
  hotelId: Scalars['String']
  hotelResultId?: InputMaybe<Scalars['String']>
  itineraryId: Scalars['String']
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  newRoomKey: Scalars['String']
  oldRoomKey: Scalars['String']
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationResetPasswordWithErrorArgs = {
  input: ResetPasswordInput
}

export type MutationSelectRoomArgs = {
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  customFields?: InputMaybe<Array<CustomFieldInput>>
  hotelId: Scalars['String']
  hotelResultId?: InputMaybe<Scalars['String']>
  itineraryId?: InputMaybe<Scalars['String']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  roomKey: Scalars['String']
}

export type MutationSendOopCodesArgs = {
  input: SendOopCodesInput
}

export type MutationSendPushNotificationArgs = {
  input: SendPushNotificationInput
}

export type MutationSetCostAllocationArgs = {
  input: SetCostAllocationInput
}

export type MutationSetCustomFieldsArgs = {
  input: SetCustomFieldsInput
}

export type MutationSetOutOfPolicyJustificationArgs = {
  input: SetOutOfPolicyJustificationInput
}

export type MutationSetPasswordArgs = {
  input: SetPasswordInput
}

export type MutationSetRcSessionCookieArgs = {
  input: SetRcSessionCookieInput
}

export type MutationSubmitNewCarRentalSearchArgs = {
  input: SubmitNewCarRentalSearchInput
}

export type MutationSubmitNewFlightSearchArgs = {
  input: SubmitNewFlightSearchInput
}

export type MutationSubmitNewHotelSearchArgs = {
  input: SubmitNewHotelSearchInput
}

export type MutationSubmitNewRailSearchArgs = {
  input: SubmitNewRailSearchInput
}

export type MutationToggleNotificationPreferenceArgs = {
  input: ToggleNotificationPreferenceInput
}

export type MutationUberEstimateArgs = {
  input: UberEstimateInput
}

export type MutationUnregisterClientFromNotificationArgs = {
  clientId: Scalars['ID']
}

export type MutationUpdateAirPreferenceExcerptArgs = {
  input?: InputMaybe<AirPreferenceExcerptInput>
}

export type MutationUpdateAuthorizersArgs = {
  input: UpdateAuthorizersInput
}

export type MutationUpdateDelegatedIdCookieArgs = {
  input: UpdateDelegatedIdCookieInput
}

export type MutationUpdateDelegatedIdTokenArgs = {
  input: UpdateDelegatedIdTokenInput
}

export type MutationUpdateDelegatesArgs = {
  input: UpdateDelegatesInput
}

export type MutationUpdateMeetingIdCookieArgs = {
  input: UpdateMeetingIdCookieInput
}

export type MutationUpdateNationalIdCardArgs = {
  input?: InputMaybe<UpdateNationalIdCardInput>
}

export type MutationUpdatePassportArgs = {
  input?: InputMaybe<UpdatePassportInput>
}

export type MutationUpdateRailJourneyPreferencesArgs = {
  input: RailPreferencesInput
}

export type MutationUpdateTokensArgs = {
  input: UpdateTokensInput
}

export type MutationUpdateTripNameArgs = {
  input: UpdateTripNameInput
}

export type MutationUpdateTripNameByProcessIdArgs = {
  input: UpdateTripNameByProcessIdInput
}

export type MutationUpdateUserProfileBusinessAddressArgs = {
  input: UpdateProfileBusinessAddressInput
}

export type MutationUpdateUserProfileContactInformationArgs = {
  input: UpdateProfileContactInformationInput
}

export type MutationUpdateUserProfileEmergencyContactArgs = {
  input: UpdateProfileEmergencyContactInput
}

export type MutationUpdateUserProfileEmployeeInformationArgs = {
  input: UpdateProfileEmployeeInformationInput
}

export type MutationUpdateUserProfileHomeAddressArgs = {
  input: UpdateProfileHomeAddressInput
}

export type MutationUpdateUserProfilePersonalInformationArgs = {
  input: UpdateProfilePersonalInformationInput
}

export type MutationUpdateUserProfileTravelPreferencesArgs = {
  input: UpdateProfileTravelPreferencesInput
}

export type MutationUpdateVisaArgs = {
  input: UpdateVisaInput
}

export type MutationUpsertMeetingInfoArgs = {
  input: UpsertMeetingInfoInput
}

export type MutationUserArgs = {
  input?: InputMaybe<UserInput>
}

/** A national ID card that is held by a user. A user can have multiple national ID cards, and national ID cards are compared using the tuple of "country" and "number", both of which are required. */
export type NationalIdCard = {
  /** ISO 3166-1 alpha-2 country code */
  country?: Maybe<Scalars['String']>
  /** ISO 8601 string w/o time information */
  expirationDate?: Maybe<Scalars['String']>
  /** Server-provisioned ID, can be used to delete a single national ID card directly */
  id?: Maybe<Scalars['Float']>
  /** ISO 8601 string w/o time information, if provided */
  issueDate?: Maybe<Scalars['String']>
  /** Identifier number of the national ID card (max length = 50) */
  number?: Maybe<Scalars['String']>
  /** Period prior to expiration date that the user will be alerted, ISO 8601 period format and specified in days only – if unset no alert will be generated */
  preExpirationAlert?: Maybe<Scalars['String']>
}

export type NationalIdCardInput = {
  /** The country in which the National ID card was issued. */
  country: Scalars['String']
  /** National ID card's expiry date. */
  expirationDate: Scalars['String']
  /** National ID card identifier. */
  id?: InputMaybe<Scalars['String']>
  /** National ID card's issue date. */
  issueDate: Scalars['String']
  /** National ID card number. */
  number: Scalars['String']
  /** Expiration Alert of National ID card. */
  preExpirationAlert?: InputMaybe<Scalars['String']>
}

/** Returned when there is no passport provided */
export type NoPassportError = Error & {
  _uselessField?: Maybe<Scalars['String']>
  /** A message briefly explaining the problem. Only meant for developers */
  message: Scalars['String']
}

export type Notification = {
  device: Device
  email?: Maybe<Scalars['String']>
  id: Scalars['Float']
  phone?: Maybe<NotificationPhone>
  topic: Scalars['String']
}

export enum NotificationClientType {
  HybridAndroid = 'HYBRID_ANDROID',
  HybridIphone = 'HYBRID_IPHONE',
}

/** Array of notification objects used to enable SMS and Email notifications for user */
export type NotificationInput = {
  /** Device type where to send notifications. May have SMS or EMAIL value. */
  device: Device
  /** Email address where to send notifications. Value is defined when device field has EMAIL value. */
  email?: InputMaybe<Scalars['String']>
  /** Id number of already existing notification. */
  id?: InputMaybe<Scalars['Float']>
  /** Phone number object, where to send notifications. Value is defined when device field has SMS value. */
  phone?: InputMaybe<NotificationPhoneInput>
  /** String value, describing type of notification - itinerary updates, flight changes or Southwest flight changes. */
  topic: Scalars['String']
}

export type NotificationPhone = {
  country?: Maybe<Scalars['Float']>
  isoCountryCode: Scalars['String']
  number: Scalars['String']
}

/** Phone input object, used to add new notification channel. */
export type NotificationPhoneInput = {
  /** Country code number, used to identify country in addition to isoCountryCode */
  country?: InputMaybe<Scalars['Float']>
  /** Country ISO code (examples: US, CA, UK). */
  isoCountryCode: Scalars['String']
  /** Phone number to use as a notification channel. */
  number: Scalars['String']
}

export type NotificationPreferenceInput = {
  eventKey: PnsEventKey
  turnedOn?: InputMaybe<Scalars['Boolean']>
}

export type NumberAdditionalInformation = {
  defaultNumber?: Maybe<Scalars['Float']>
  maxNumber?: Maybe<Scalars['Float']>
  minNumber?: Maybe<Scalars['Float']>
  numberDecimal?: Maybe<Scalars['Int']>
  numberType?: Maybe<NumberCustomFieldType>
  numberValue?: Maybe<Scalars['Float']>
}

export enum NumberCustomFieldType {
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
}

export type NumberOfGuests = {
  adults: Scalars['Int']
}

/** Overall explanation for reserving out-of-policy items (aka. "Trip Purpose"). */
export type OopExplanation = {
  /** Label to be displayed to the end user to guide them in providing explanation text. */
  label?: Maybe<Scalars['String']>
  /**  Indicates if user is required to provide an explanation for booking out-of-policy items. */
  required: Scalars['Boolean']
  /** Current explanation text as provided by the end user. */
  value?: Maybe<Scalars['String']>
}

export type OnTheFlyContent = {
  display?: Maybe<OnTheFlyContentDisplay>
  items: Array<OnTheFlyContentItem>
  linkIconClass?: Maybe<Scalars['String']>
  linkTitle?: Maybe<Scalars['String']>
  location?: Maybe<OnTheFlyContentLocation>
  segmentIndex?: Maybe<Scalars['Int']>
  severity?: Maybe<OnTheFlyContentContentSeverity>
}

export enum OnTheFlyContentAssessment {
  Benefit = 'BENEFIT',
  Fee = 'FEE',
  Neutral = 'NEUTRAL',
  Restriction = 'RESTRICTION',
  Unspecified = 'UNSPECIFIED',
}

export enum OnTheFlyContentContentSeverity {
  Danger = 'DANGER',
  Low = 'LOW',
  Moderate = 'MODERATE',
  Primary = 'PRIMARY',
  Unspecified = 'UNSPECIFIED',
  Warning = 'WARNING',
}

export enum OnTheFlyContentDisplay {
  Hover = 'HOVER',
  Link = 'LINK',
  Modal = 'MODAL',
  ModalIframe = 'MODAL_IFRAME',
  Unspecified = 'UNSPECIFIED',
}

export type OnTheFlyContentItem = {
  body: Scalars['String']
  callToAction?: Maybe<OnTheFlyContentItemCallToAction>
  categories: Array<Scalars['String']>
  details: Array<OnTheFlyContentItemDetail>
  headline: Scalars['String']
}

export type OnTheFlyContentItemCallToAction = {
  text?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type OnTheFlyContentItemDetail = {
  body: Scalars['String']
  image: OnTheFlyContentItemDetailImage
}

export type OnTheFlyContentItemDetailImage = {
  large: Scalars['String']
  medium: Scalars['String']
  thumbnail: Scalars['String']
}

export enum OnTheFlyContentLocation {
  Carrier = 'CARRIER',
  Destination = 'DESTINATION',
  Details = 'DETAILS',
  Features = 'FEATURES',
  Header = 'HEADER',
  Origin = 'ORIGIN',
  Unspecified = 'UNSPECIFIED',
}

export type OnTheRailContent = {
  display?: Maybe<OnTheRailContentDisplay>
  items: Array<OnTheRailContentItem>
  linkIconClass?: Maybe<Scalars['String']>
  linkTitle?: Maybe<Scalars['String']>
  location?: Maybe<OnTheRailContentLocation>
  segmentIndex?: Maybe<Scalars['Int']>
  severity?: Maybe<OnTheRailContentContentSeverity>
}

export enum OnTheRailContentContentSeverity {
  Danger = 'DANGER',
  Low = 'LOW',
  Moderate = 'MODERATE',
  Primary = 'PRIMARY',
  Unspecified = 'UNSPECIFIED',
  Warning = 'WARNING',
}

export enum OnTheRailContentDisplay {
  Hover = 'HOVER',
  Link = 'LINK',
  Modal = 'MODAL',
  ModalIframe = 'MODAL_IFRAME',
  Unspecified = 'UNSPECIFIED',
}

export type OnTheRailContentItem = {
  body: Scalars['String']
  callToAction?: Maybe<OnTheRailContentItemCallToAction>
  categories: Array<Scalars['String']>
  details: Array<OnTheRailContentItemDetail>
  headline: Scalars['String']
}

export type OnTheRailContentItemCallToAction = {
  text?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type OnTheRailContentItemDetail = {
  body: Scalars['String']
  image: OnTheRailContentItemDetailImage
}

export type OnTheRailContentItemDetailImage = {
  large: Scalars['String']
  medium: Scalars['String']
  thumbnail: Scalars['String']
}

export enum OnTheRailContentLocation {
  Carrier = 'CARRIER',
  Destination = 'DESTINATION',
  Details = 'DETAILS',
  Features = 'FEATURES',
  Header = 'HEADER',
  Origin = 'ORIGIN',
  Unspecified = 'UNSPECIFIED',
}

export type OopBySegment = {
  air?: InputMaybe<Scalars['String']>
  carrental?: InputMaybe<Scalars['String']>
  hotel?: InputMaybe<Scalars['String']>
  rail?: InputMaybe<Scalars['String']>
}

export enum Operation {
  Remove = 'REMOVE',
  Update = 'UPDATE',
}

export type OrderDetails = {
  agencyInfo?: Maybe<AgencyInfo>
  /** @deprecated Use agencyInfo.agencyName */
  agencyName?: Maybe<Scalars['String']>
  airTickets?: Maybe<Array<AirTicket>>
  /** List of rail cards applied to the reservation. */
  appliedRailCards?: Maybe<Array<Scalars['String']>>
  billingInfo?: Maybe<Array<BillingInfo>>
  cancellationTime?: Maybe<Scalars['String']>
  cityImageUrl?: Maybe<Scalars['String']>
  /** Summary of costs in the trip by segment type */
  costSummary: TripDetailsCostSummary
  creationTime?: Maybe<Scalars['String']>
  /** @deprecated Use creationTime */
  creationTimeS?: Maybe<Scalars['String']>
  /** List of failed booking associated with the reservation. */
  failedBookings?: Maybe<Array<PurchaseItineraryFailedBookings>>
  flightId?: Maybe<Scalars['String']>
  holdRules?: Maybe<TripHoldRules>
  isMultiCity: Scalars['Boolean']
  isNDCTrip: Scalars['Boolean']
  meetingInfo?: Maybe<MeetingInfo>
  messages?: Maybe<Array<DynamicSiteMessage>>
  processId?: Maybe<Scalars['Int']>
  recordLocator?: Maybe<Scalars['String']>
  rollUpEndTime?: Maybe<Scalars['DateTime']>
  rollUpStartTime?: Maybe<Scalars['DateTime']>
  segments?: Maybe<Array<TripDetailsSegment>>
  startDate?: Maybe<Scalars['String']>
  supportedActions?: Maybe<TripDetailsPostReservation>
  supportedActionsV2?: Maybe<TripDetailsPostReservationV2>
  tripCost?: Maybe<TripDetailsTripCostSummary>
  tripId?: Maybe<Scalars['ID']>
  tripName?: Maybe<Scalars['String']>
  type?: Maybe<TripStatus>
  /** @deprecated Upcoming Details are deprecated. */
  upcomingDetails?: Maybe<UpcomingDetails>
}

export type OrdersInput = {
  format?: InputMaybe<ImageFormat>
  offset: Scalars['Int']
  size?: InputMaybe<Scalars['Int']>
  tripType: TripType
}

export type OrdersResult = {
  hasNextPage: Scalars['Boolean']
  pageSize: Scalars['Int']
  startIndex: Scalars['Int']
  totalResults: Scalars['Int']
  transactionGroups: Array<TransactionGroup>
}

export type OriginalCost = {
  amount?: Maybe<Scalars['Float']>
  code?: Maybe<Scalars['String']>
  requestedCurrencyAmount?: Maybe<Scalars['Float']>
  requestedCurrencyUnit?: Maybe<Scalars['String']>
}

export type OutOfPolicyComplianceCode = {
  code: Scalars['String']
  description: Scalars['String']
  val: Scalars['Int']
}

export type OutOfPolicyData = {
  currency?: Maybe<Array<Maybe<Scalars['String']>>>
  data?: Maybe<Array<Maybe<Scalars['String']>>>
  type: OutOfPolicyEnum
}

export enum OutOfPolicyEnum {
  AcNotAllowed = 'AC_NOT_ALLOWED',
  AcRequired = 'AC_REQUIRED',
  AdvancePurchaseFareBlocked = 'ADVANCE_PURCHASE_FARE_BLOCKED',
  AdvancePurchaseRequired = 'ADVANCE_PURCHASE_REQUIRED',
  AirportChangeBlocked = 'AIRPORT_CHANGE_BLOCKED',
  AirportChangeRequired = 'AIRPORT_CHANGE_REQUIRED',
  AirRestrictedVendor = 'AIR_RESTRICTED_VENDOR',
  AllPreferred = 'ALL_PREFERRED',
  AllPreferredLeg = 'ALL_PREFERRED_LEG',
  AllPreferredSavingsRequired = 'ALL_PREFERRED_SAVINGS_REQUIRED',
  AllPreferredSavingsRequiredNoAmount = 'ALL_PREFERRED_SAVINGS_REQUIRED_NO_AMOUNT',
  AlternateAirport = 'ALTERNATE_AIRPORT',
  AlternateAirports = 'ALTERNATE_AIRPORTS',
  AlternateAirportWithSavings = 'ALTERNATE_AIRPORT_WITH_SAVINGS',
  AutoTransRequired = 'AUTO_TRANS_REQUIRED',
  BizClass = 'BIZ_CLASS',
  CancelFee = 'CANCEL_FEE',
  CarPreferredNotAllowed = 'CAR_PREFERRED_NOT_ALLOWED',
  CarRentalRequired = 'CAR_RENTAL_REQUIRED',
  CarRentalRequiredViolation = 'CAR_RENTAL_REQUIRED_VIOLATION',
  CarRestrictedVendor = 'CAR_RESTRICTED_VENDOR',
  ChangeFee = 'CHANGE_FEE',
  CompanyPerDiem = 'COMPANY_PER_DIEM',
  ConnectionsBlocked = 'CONNECTIONS_BLOCKED',
  ConnectionsNotAllowed = 'CONNECTIONS_NOT_ALLOWED',
  ConnectionsSavings = 'CONNECTIONS_SAVINGS',
  DefaultPolicyReason = 'DEFAULT_POLICY_REASON',
  DiffInFeeCap = 'DIFF_IN_FEE_CAP',
  ExceedsMaxRate = 'EXCEEDS_MAX_RATE',
  ExceedsMaxSize = 'EXCEEDS_MAX_SIZE',
  ExceedsNegotiatedRate = 'EXCEEDS_NEGOTIATED_RATE',
  FirstClass = 'FIRST_CLASS',
  FourDoorNotAllowed = 'FOUR_DOOR_NOT_ALLOWED',
  FuelTypeNotAllowed = 'FUEL_TYPE_NOT_ALLOWED',
  HighlyPreferred = 'HIGHLY_PREFERRED',
  HighlyPreferredLeg = 'HIGHLY_PREFERRED_LEG',
  HotelRequiredForOvernightTrip = 'HOTEL_REQUIRED_FOR_OVERNIGHT_TRIP',
  HotelRestrictedVendor = 'HOTEL_RESTRICTED_VENDOR',
  ManualTransNotAllowed = 'MANUAL_TRANS_NOT_ALLOWED',
  ManualTransRequired = 'MANUAL_TRANS_REQUIRED',
  MaxRate = 'MAX_RATE',
  MinAdvancePurchaseDays = 'MIN_ADVANCE_PURCHASE_DAYS',
  MostPreferred = 'MOST_PREFERRED',
  MostPreferredLeg = 'MOST_PREFERRED_LEG',
  NonRefundableFareBlocked = 'NON_REFUNDABLE_FARE_BLOCKED',
  NonRefundableFareRequired = 'NON_REFUNDABLE_FARE_REQUIRED',
  PerDiemBlocked = 'PER_DIEM_BLOCKED',
  Preferred = 'PREFERRED',
  PreferredChainRequired = 'PREFERRED_CHAIN_REQUIRED',
  PreferredChainRestricted = 'PREFERRED_CHAIN_RESTRICTED',
  PreferredHotelRequired = 'PREFERRED_HOTEL_REQUIRED',
  PreferredLeg = 'PREFERRED_LEG',
  PreferredNotAllowed = 'PREFERRED_NOT_ALLOWED',
  PreferredRequired = 'PREFERRED_REQUIRED',
  PremiumCoachClass = 'PREMIUM_COACH_CLASS',
  PrivateRateBlocked = 'PRIVATE_RATE_BLOCKED',
  PrivateRateRequired = 'PRIVATE_RATE_REQUIRED',
  RateCapViolation = 'RATE_CAP_VIOLATION',
  RateMoreThanCompanyPerDiem = 'RATE_MORE_THAN_COMPANY_PER_DIEM',
  RestrictedCar = 'RESTRICTED_CAR',
  RestrictedFareBlocked = 'RESTRICTED_FARE_BLOCKED',
  RestrictedFareRequired = 'RESTRICTED_FARE_REQUIRED',
  RestrictedHotel = 'RESTRICTED_HOTEL',
  RestrictedRoomType = 'RESTRICTED_ROOM_TYPE',
  RoomPvtRateLtdBlocked = 'ROOM_PVT_RATE_LTD_BLOCKED',
  RoomPvtRateLtdReqd = 'ROOM_PVT_RATE_LTD_REQD',
  RoomTypeRestricted = 'ROOM_TYPE_RESTRICTED',
  RuleRequireCompanyPreferredVendors = 'RULE_REQUIRE_COMPANY_PREFERRED_VENDORS',
  RuleTypeRequireRateBelowPrivateCost = 'RULE_TYPE_REQUIRE_RATE_BELOW_PRIVATE_COST',
  TrainBizClass = 'TRAIN_BIZ_CLASS',
  TrainFirstClass = 'TRAIN_FIRST_CLASS',
  TwoDoorNotAllowed = 'TWO_DOOR_NOT_ALLOWED',
  TwoDoorRequired = 'TWO_DOOR_REQUIRED',
  TypeNotAllowed = 'TYPE_NOT_ALLOWED',
  TypeRestricted = 'TYPE_RESTRICTED',
  UnusedTicket = 'UNUSED_TICKET',
  UnusedTicketNotAllowed = 'UNUSED_TICKET_NOT_ALLOWED',
  WebFareBlocked = 'WEB_FARE_BLOCKED',
  WebFareDiffInFeeCap = 'WEB_FARE_DIFF_IN_FEE_CAP',
  WebFareRequired = 'WEB_FARE_REQUIRED',
}

export type OutOfPolicyExplanationInputDto = {
  air?: InputMaybe<Scalars['String']>
  carRental?: InputMaybe<Scalars['String']>
  hotel?: InputMaybe<Scalars['String']>
  rail?: InputMaybe<Scalars['String']>
  trip?: InputMaybe<Scalars['String']>
}

export type OutOfPolicyPerBookingItem = {
  key: Scalars['String']
  oopReasonCode: Scalars['String']
}

export type OutOfPolicyPerItem = {
  carRental?: InputMaybe<Array<OutOfPolicyPerBookingItem>>
  flight?: InputMaybe<Array<OutOfPolicyPerBookingItem>>
  hotel?: InputMaybe<Array<OutOfPolicyPerBookingItem>>
}

export type OutOfPolicyReason = {
  codeForBooking?: Maybe<Scalars['String']>
  id: Scalars['Float']
  reason?: Maybe<Scalars['String']>
  segment: Scalars['String']
}

/** Monetary and textual data that enhance a reason why flight leg and fare are flagged as out of policy. */
export type OutOfPolicyReasonData = {
  /** Required if out of policy reason type is a monetary amount. */
  monetaryValue?: Maybe<Money>
  /** Required if out of policy reason type is not a monetary amount and is accompanied by text value. */
  textValue1?: Maybe<Scalars['String']>
  /** Required if out of policy reason type is not a monetary amount and is accompanied by text value        information in addition to textValue1 */
  textValue2?: Maybe<Scalars['String']>
  /** Required if out of policy reason type is not a monetary amount and is accompanied by text value        information in addition to textValue1 and textValue2 */
  textValue3?: Maybe<Scalars['String']>
}

/** Out of policy reason type. The consuming client should provide localized descriptions of the policy reasons.     In the event that the client gets a reason type that it does not have a translation for,     the `fallback_message` of the `OutOfPolicyReasonData` which contains US English should be presented to the user. */
export enum OutOfPolicyReasonType {
  AlternateAirport = 'ALTERNATE_AIRPORT',
  Amount = 'AMOUNT',
  CabinClassDistance = 'CABIN_CLASS_DISTANCE',
  CabinClassDuration = 'CABIN_CLASS_DURATION',
  CancellationFee = 'CANCELLATION_FEE',
  ChangeFee = 'CHANGE_FEE',
  ConnectingFlightAvailable = 'CONNECTING_FLIGHT_AVAILABLE',
  ConnectingFlightNotAllowed = 'CONNECTING_FLIGHT_NOT_ALLOWED',
  Days = 'DAYS',
  EarlyDepartureTime = 'EARLY_DEPARTURE_TIME',
  FareTier = 'FARE_TIER',
  FareTypeProhibited = 'FARE_TYPE_PROHIBITED',
  FareTypeSearchProhibited = 'FARE_TYPE_SEARCH_PROHIBITED',
  InternationalTravel = 'INTERNATIONAL_TRAVEL',
  MustUseUnusedTicket = 'MUST_USE_UNUSED_TICKET',
  NonPreferredAirline = 'NON_PREFERRED_AIRLINE',
  Preference = 'PREFERENCE',
  RestrictedAirline = 'RESTRICTED_AIRLINE',
  RestrictedCountryAirport = 'RESTRICTED_COUNTRY_AIRPORT',
  ServiceClassProhibited = 'SERVICE_CLASS_PROHIBITED',
  ServiceClassSearchProhibited = 'SERVICE_CLASS_SEARCH_PROHIBITED',
  Unspecified = 'UNSPECIFIED',
  WebFareDisallowed = 'WEB_FARE_DISALLOWED',
  WebFareTooExpensive = 'WEB_FARE_TOO_EXPENSIVE',
}

export type OverlappingTrip = {
  id: Scalars['Int']
  name: Scalars['String']
}

export enum PnsEventKey {
  ExpenseApprover = 'ExpenseApprover',
  ExpenseSubmitter = 'ExpenseSubmitter',
  TravelDsmOptional = 'TravelDSMOptional',
  TravelDsmRequired = 'TravelDSMRequired',
  TravelFlightAlert = 'TravelFlightAlert',
}

export type PwaWelcomeMessage = {
  shouldShowWelcomeMessage?: Maybe<Scalars['Boolean']>
}

export type Page = {
  end: Scalars['Int']
  size: Scalars['Int']
  start: Scalars['Int']
}

export type ParticipantCustomField = {
  /** Participant custom field name. */
  name: Scalars['String']
  /** Participant custom field value. */
  value: Scalars['String']
}

export type ParticipantInfo = {
  /** Custom fields of the meeting participant. */
  customFields: Array<ParticipantCustomField>
  /** Email of the meeting participant. */
  email: Scalars['String']
  /** First name of the meeting participant. */
  firstName: Scalars['String']
  /** Last name of the meeting participant. */
  lastName: Scalars['String']
}

export type PartnerCoBrandingCofiguration = {
  /** Copyright, configured in the Gazoo console */
  copyright: Scalars['String']
  /** Custom company custom logo, configured in the Gazoo console */
  customCompanyLogoUrl?: Maybe<Scalars['String']>
  /** Custom footer, configured in the Gazoo console */
  customFooter?: Maybe<Scalars['String']>
  /** Custom footer enabled, configured in the Gazoo console */
  customFooterEnabled: Scalars['Boolean']
  /** Custom company custom logo for mobile, configured in the Gazoo console */
  customMobileCompanyLogoUrl?: Maybe<Scalars['String']>
  /** Custom partner's custom logo for mobile, configured in the Gazoo console */
  customMobilePartnerLogoUrl?: Maybe<Scalars['String']>
  /** Custom partner's custom logo, configured in the Gazoo console */
  customPartnerLogoUrl?: Maybe<Scalars['String']>
  /** FAQ label, configured in the Gazoo console */
  faqLabel?: Maybe<Scalars['String']>
  /** FAQ link url enabled, configured in the Gazoo console */
  faqLinkEnabled: Scalars['Boolean']
  /** FAQ link url, configured in the Gazoo console */
  faqLinkUrl?: Maybe<Scalars['String']>
  /** Feature partner co-branding enabled, configured in the LD */
  partnerCoBrandingEnabled: Scalars['Boolean']
  /** Partner's logo, config in Gazoo console */
  partnerLogoUrl?: Maybe<Scalars['String']>
  /** Privacy policy enabled, configured in the Gazoo console */
  privacyPolicyEnabled: Scalars['Boolean']
  /** Privacy policy label, configured in the Gazoo console */
  privacyPolicyLabel?: Maybe<Scalars['String']>
  /** Privacy policy link url enabled, configured in the Gazoo console */
  privacyPolicyLinkEnabled: Scalars['Boolean']
  /** Privacy policy link url, configured in the Gazoo console */
  privacyPolicyLinkUrl?: Maybe<Scalars['String']>
  /** Privacy policy text, configured in the Gazoo console */
  privacyPolicyText?: Maybe<Scalars['String']>
  /** Support text, configured in the Gazoo console */
  supportText?: Maybe<Scalars['String']>
  /** Support text enabled, configured in the Gazoo console */
  supportTextEnabled: Scalars['Boolean']
  /** Terms of use, configured in the Gazoo console */
  termsOfUse?: Maybe<Scalars['String']>
  /** Terms of use label, configured in the Gazoo console */
  termsOfUseLabel?: Maybe<Scalars['String']>
  /** Terms of use link enabled, configured in the Gazoo console */
  termsOfUseLinkEnabled: Scalars['Boolean']
  /** Terms of use link url, configured in the Gazoo console */
  termsOfUseLinkUrl?: Maybe<Scalars['String']>
  /** Theme configure in the Gazoo console */
  theme: Scalars['String']
}

export type PartnerCoBrandingConfigurationInput = {
  /** The site ID is provided after the post-login process. */
  siteId?: InputMaybe<Scalars['Int']>
  /** The site name is used in the pre-login process when the site ID is not available. */
  siteName?: InputMaybe<Scalars['String']>
}

/** Provides the input parameters for the passenger. */
export type PassengerInput = {
  /** Passenger email address. */
  emailAddress: Scalars['String']
  /** Passenger gender. */
  gender: Scalars['String']
  /** Passenger mobile phone number. Deprecated, use phoneNumbers instead */
  mobilePhoneNumber?: InputMaybe<PhoneInput>
  /** Passenger name. */
  name: PersonNameInput
  /** Passenger phone numbers. At least one of home, business or mobile (cell) phone numbers is required for GDS reservations */
  phoneNumbers?: InputMaybe<Array<PhoneInput>>
}

/** A passport that is held by a user. A user can have multiple passports, and passports are compared using the tuple of "country" and "number", both of which are required. Note that these can be issued by a different country's consulate. */
export type Passport = {
  /** ISO 3166-1 alpha-2 country code */
  country?: Maybe<Scalars['String']>
  /** ISO 8601 string w/o time information */
  expirationDate?: Maybe<Scalars['String']>
  /** Server-provisioned ID, can be used to delete a single passport directly */
  id?: Maybe<Scalars['Float']>
  /** ISO 8601 string w/o time information, if provided */
  issueDate?: Maybe<Scalars['String']>
  /** ISO 3166-1 alpha-2 country code, if provide */
  issuingCountry?: Maybe<Scalars['String']>
  /**
   * Identifier number of the passport (max length = 50) with masked symbols (except last 4 symbols)
   * @deprecated Presentation logic should be done client-side. Use passportNumber instead
   */
  number: Scalars['String']
  /** Identifier number of the passport (max length = 50) */
  passportNumber: Scalars['String']
  /** Period prior to expiration date that the user will be alerted, ISO 8601 period format and specified in days only – if unset no alert will be generated */
  preExpirationAlert?: Maybe<Scalars['String']>
  /**
   * Identifier number of the passport (max length = 50)
   * @deprecated Use passportNumber instead
   */
  unmaskedNumber?: Maybe<Scalars['String']>
}

export type PassportInput = {
  /** Country code in ISO 3166-1 alpha-2 format. */
  country: Scalars['String']
  /** ISO 8601 string w/o time information. */
  expirationDate: Scalars['String']
  /** Server-provisioned identifier for a passport. This may be used for swift removal of a passport from a user profile. */
  id?: InputMaybe<Scalars['Float']>
  /** Country code in ISO 3166-1 alpha-2 format, if provided. */
  issueCountry: Scalars['String']
  /** ISO 8601 string w/o time information, if provided. */
  issueDate: Scalars['String']
  /** Passport number (as printed on the passport). (max length = 50) */
  number: Scalars['String']
  /** Period prior to expiration date that the user will be alerted, ISO 8601 period format and specified in days only – if unset no alert will be generated. */
  preExpirationAlert?: InputMaybe<Scalars['String']>
}

export type PasswordSecurityConfigure = {
  /** Determines if the password not allow to contains any word in dictionary. */
  dictionaryWordsNotAllowed?: Maybe<Scalars['Boolean']>
  /** Determines to display password hint. */
  displayPasswordHint?: Maybe<Scalars['Boolean']>
  /** Determines if the secret question is required for password reset. */
  enablePwdResetSecretQuestion?: Maybe<Scalars['Boolean']>
  /** Determines to force the password si required to contain alpha numeric. */
  forceAlphaNumericPassword?: Maybe<Scalars['Boolean']>
  /** Maximum number of attempts to sign-in. */
  maxSignInAttempts?: Maybe<Scalars['Float']>
  /** Number of days that password will be expired. */
  passwordExpirationDays?: Maybe<Scalars['Float']>
  /** Password hint text. */
  passwordHintText?: Maybe<Scalars['String']>
  /** Minimum characters length of password. */
  passwordMinLength?: Maybe<Scalars['Float']>
  /** Limited of password re-usability. */
  passwordReusability?: Maybe<Scalars['Float']>
  /** List of password security secure question. */
  passwordSecuritySecretQuestionList?: Maybe<Array<Scalars['String']>>
  /** Determines to allow remember the password */
  rememberMeActivated?: Maybe<Scalars['Boolean']>
  /** Determines the number of minutes that a locked-out account remains locked out */
  userLockoutTime?: Maybe<Scalars['Float']>
}

export type PayLater = {
  car?: Maybe<TripCost>
  carServices?: Maybe<Array<Maybe<CarServiceTripCost>>>
  hotel?: Maybe<TripCost>
  subTotal: Rate
}

/** Provides the details of all the domain costs in the cart that can be paid later. */
export type PayLaterSummary = {
  /** Provides the cost summary for different intents belonging to the same Domain. */
  domainCosts: Array<DomainCostSummary>
  /** Provides the total w/o taxes and fees, across all intents in the cart. */
  subTotal: Rate
}

export type PayNow = {
  /** Prepaid cost for the car rental */
  car?: Maybe<TripCost>
  flight?: Maybe<Array<Maybe<TripCost>>>
  hotel?: Maybe<TripCost>
  subTotal: Rate
  train?: Maybe<Array<Maybe<TripCost>>>
}

export type Payment = {
  creditCard?: Maybe<CreditPaymentCard>
  points?: Maybe<Points>
  siteCard?: Maybe<SitePaymentCard>
}

export type PaymentCard = {
  cardId: Scalars['ID']
  /** Legacy identifier, populated by Gazoo. */
  legacyId?: Maybe<Scalars['ID']>
}

export type PaymentDetails = {
  billingAddress?: InputMaybe<CreditCardBillingAddressInput>
  creditCard?: InputMaybe<CreditCardDetails>
  creditCardData?: InputMaybe<CreditCardInput>
  isVirtualPaymentSelected?: InputMaybe<Scalars['Boolean']>
}

export type PenaltyFee = {
  days?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  penaltyFee?: Maybe<Array<Maybe<TrainPenaltyFee>>>
  rates?: Maybe<Array<Maybe<Rate>>>
  type?: Maybe<Scalars['String']>
}

/** Provides the input parameters for the person name. */
export type PersonNameInput = {
  /** Given name for the person. */
  givenName: Scalars['String']
  /** Middle name for the person. */
  middle?: InputMaybe<Scalars['String']>
  /** Suffix for the person name. */
  suffix?: InputMaybe<Scalars['String']>
  /** Surname for the person. */
  surname: Scalars['String']
  /** Title of the person. */
  title?: InputMaybe<Scalars['String']>
}

export type PersonalAddress = {
  /** Country/province-dependent locality: city, town, suburb, district, etc */
  city?: InputMaybe<Scalars['String']>
  /** ISO 3166-1 alpha-2 country code */
  countryCode?: InputMaybe<Scalars['String']>
  /** Destination within a premise, facility, or firm */
  mailStop?: InputMaybe<Scalars['String']>
  /** Postal or zip code */
  postalCode?: InputMaybe<Scalars['String']>
  /** State, province, etc */
  stateCode?: InputMaybe<Scalars['String']>
  /** Free-form address line */
  street1?: InputMaybe<Scalars['String']>
  /** Free-from address line, typically apt, suite, building, etc */
  street2?: InputMaybe<Scalars['String']>
}

export type PersonalContact = {
  address?: Maybe<PersonalContactAddress>
  email?: Maybe<Scalars['String']>
  homeEmail?: Maybe<Scalars['String']>
  phone?: Maybe<PersonalContactPhone>
}

export type PersonalContactAddress = {
  city?: Maybe<Scalars['String']>
  countryCode: Scalars['String']
  mailStop?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
}

export type PersonalContactPhone = {
  /** Phone country code. */
  countryCode?: Maybe<Scalars['String']>
  ext?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  /** Extension for office-like numbers. */
  officeExtension?: Maybe<Scalars['String']>
}

export type PersonalPhone = {
  /** Phone country code. */
  countryCode?: InputMaybe<Scalars['String']>
  ext?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
  /** Extension for office-like numbers. */
  officeExtension?: InputMaybe<Scalars['String']>
}

export type Phone = {
  countryCode: Scalars['String']
  number: Scalars['String']
  type: PhoneType
}

/** Provides the input parameters for the phone number. */
export type PhoneInput = {
  /** Area code of the phone number. */
  areaCode?: InputMaybe<Scalars['String']>
  /** Country code of the phone number. */
  countryCode: Scalars['String']
  /** Phone number. */
  number: Scalars['String']
}

export type PhoneInputDto = {
  countryCode: Scalars['String']
  number: Scalars['String']
  type: PhoneType
}

export type PhoneNumber = {
  areaCode?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  formattedNumber?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  type?: Maybe<PhoneType>
}

export type PhoneSupport = {
  label?: Maybe<Scalars['String']>
  phoneNumber: Scalars['String']
}

export enum PhoneType {
  Business = 'BUSINESS',
  Home = 'HOME',
  Mobile = 'MOBILE',
}

export type Photo = {
  index: Scalars['Int']
  size: PhotoSize
  url: Scalars['String']
}

export enum PhotoSize {
  Large = 'LARGE',
  Thumb = 'THUMB',
}

export type PlaceAutocomplete = {
  address?: Maybe<PlaceAutocompleteAddress>
  airportCode?: Maybe<Scalars['String']>
  /** Airport name (if provided) displayed to the user as part of autocomplete functionality */
  airportName?: Maybe<Scalars['String']>
  isGrouped?: Maybe<Scalars['Boolean']>
  isHotelAddress?: Maybe<Scalars['Boolean']>
  latitude?: Maybe<Scalars['Float']>
  locationType?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['Float']>
  name: Scalars['String']
  placeId: Scalars['String']
  placeType?: Maybe<PlaceAutocompleteType>
  shortName?: Maybe<Scalars['String']>
  subPlaces?: Maybe<Array<PlaceAutocomplete>>
  timezone?: Maybe<Scalars['String']>
  uniqueCode?: Maybe<Scalars['String']>
}

export type PlaceAutocompleteAddress = {
  city?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  countryName?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
  postalCode?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
}

export enum PlaceAutocompleteSort {
  Airports = 'Airports',
  AlgoliaPlaces = 'AlgoliaPlaces',
  GooglePlaces = 'GooglePlaces',
  HomeAirport = 'HomeAirport',
  ReferencePoint = 'ReferencePoint',
}

export enum PlaceAutocompleteType {
  Airports = 'Airports',
  AlgoliaPlaces = 'AlgoliaPlaces',
  GooglePlaces = 'GooglePlaces',
  HomeAirport = 'HomeAirport',
  ReferencePoint = 'ReferencePoint',
}

export type PlacesAutocompleteInput = {
  includeTimezone?: InputMaybe<Scalars['Boolean']>
  query: Scalars['String']
  searchType?: InputMaybe<PlacesSearchType>
  size?: InputMaybe<Scalars['Int']>
  sortOrder?: InputMaybe<Array<PlaceAutocompleteSort>>
}

export enum PlacesSearchType {
  AirSearch = 'AirSearch',
  CarRentalSearch = 'CarRentalSearch',
  HotelSearch = 'HotelSearch',
  MobilitySearch = 'MobilitySearch',
  TrainSearch = 'TrainSearch',
}

export type Plane = {
  code?: Maybe<Scalars['String']>
  onType?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type PlaneData = {
  code: Scalars['String']
  onTime?: Maybe<Scalars['Int']>
  type: Scalars['String']
}

export type Points = {
  pointsToUse?: Maybe<Scalars['Int']>
}

export type Policy = {
  isInPolicy: Scalars['Boolean']
  outOfPolicyComplianceCodes?: Maybe<Array<OutOfPolicyComplianceCode>>
  outOfPolicyData?: Maybe<Array<OutOfPolicyData>>
  outOfPolicyReasons?: Maybe<Array<OutOfPolicyEnum>>
}

/** Status of air policy evaluation.. */
export type PolicyStatus = {
  /** Indicate if flight leg is in or out of policy. */
  inPolicy: Scalars['Boolean']
  /** If out of policy, list of reasons why. */
  outOfPolicyReason?: Maybe<Array<AirOutOfPolicyReason>>
  /** Indicate if a user is allowed to select an out of policy trip for purchase. */
  outOfPolicySelectable?: Maybe<Scalars['Boolean']>
}

export type PolicyTripDto = {
  flightLegs: Array<SearchFlightLeg>
  tripId: Scalars['String']
}

export type PostPurchaseInfoInput = {
  bookingId: Scalars['String']
  deviceClass?: InputMaybe<DeviceClass>
  /** Whether or not a credit card number is encrypted. If set to "TRUE", only the last 4 digits of the credit card number are returned. */
  isCCNumberMasked?: InputMaybe<Scalars['Boolean']>
  itineraryId: Scalars['String']
}

export type PreBookInfoPayload = {
  additionalCustomFields?: Maybe<CustomFieldConfiguration>
  errors?: Maybe<Array<TravelMessage>>
  isBookingAllowed: Scalars['Boolean']
  isTermsUserInteractionAllowed: Scalars['Boolean']
  termsAndConditions?: Maybe<Array<TermsAndConditions>>
}

export enum PreBookRequestRequirement {
  Mandatory = 'MANDATORY',
  None = 'NONE',
  Validation = 'VALIDATION',
}

export type PreTripApprovalDisplay = {
  ruleDescription?: Maybe<Scalars['String']>
}

export type Preference = {
  code?: Maybe<Scalars['String']>
  customBadge: CustomBadge
  highlight?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  ownerName?: Maybe<Scalars['String']>
  ownerType?: Maybe<Scalars['String']>
  shouldShowDefaultPreference?: Maybe<Scalars['Boolean']>
  tooltipText?: Maybe<Scalars['String']>
}

export type PreferenceOptions = {
  label: Scalars['String']
  value: Scalars['String']
}

/** Travel preference Options */
export type PreferenceOptionsInput = {
  label: Scalars['String']
  value: Scalars['String']
}

/** The type of owner for a preference setting. */
export enum PreferenceOwnerType {
  Agency = 'AGENCY',
  Company = 'COMPANY',
  Unspecified = 'UNSPECIFIED',
}

export type PreferredCreditCard = {
  /** Numeric string extracted from Gazoo's full alpha-numeric string ID. */
  id: Scalars['String']
  /** List of segments for which this credit card is specified as being the preferred card to use for reserving inventory. For example, "Hotel", "Car Rental". */
  segments: Array<PreferredCreditCardSegment>
}

/** Segments for which preferred credit card might be set. */
export enum PreferredCreditCardSegment {
  CarRental = 'CAR_RENTAL',
  CarService = 'CAR_SERVICE',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  Rail = 'RAIL',
}

export type PreferredCreditCardsInput = {
  /** Legacy alphanumeric id from credit card. */
  id: Scalars['String']
  /** List of segments for which this credit card is specified as being the preferred card to use for reserving inventory. For example, "Hotel", "Car Rental". */
  segments: Array<PreferredCreditCardSegment>
}

export type PreferredDescription = {
  bold?: Maybe<Scalars['Boolean']>
  code?: Maybe<Scalars['String']>
  customColor?: Maybe<Scalars['String']>
  customName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type PriceChangeInfo = {
  amount?: Maybe<Money>
  reason?: Maybe<PriceChangeReason>
  type?: Maybe<PriceChangeType>
}

export enum PriceChangeReason {
  AgencyConfig = 'AGENCY_CONFIG',
  Standard = 'STANDARD',
}

export enum PriceChangeType {
  Decrease = 'DECREASE',
  Increase = 'INCREASE',
}

export type PrimaryTravelerDataInputDto = {
  address?: InputMaybe<AddressInputDto>
  dateOfBirth?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  gender?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  knownTravelerNumbers?: InputMaybe<Array<PrimaryTravelerKnownTravelerNumber>>
  lastName: Scalars['String']
  middleInitial?: InputMaybe<Scalars['String']>
  passport?: InputMaybe<PrimaryTravelerPassportInputDto>
  phoneNumber?: InputMaybe<Array<PhoneInputDto>>
  primary: Scalars['Boolean']
  redressNumbers?: InputMaybe<Array<PrimaryTravelerRedressNumberDto>>
  suffix?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type PrimaryTravelerInputDto = {
  data: PrimaryTravelerDataInputDto
  travelerFields?: InputMaybe<TravelerFieldsInputDto>
}

export type PrimaryTravelerKnownTravelerNumber = {
  countryCode: Scalars['String']
  expirationDate?: InputMaybe<Scalars['String']>
  number: Scalars['String']
}

export type PrimaryTravelerPassportInputDto = {
  expirationDate: Scalars['String']
  issueDate: Scalars['String']
  issuingCountry: Scalars['String']
  passportNumber: Scalars['String']
}

export type PrimaryTravelerRedressNumberDto = {
  countryCode: Scalars['String']
  number: Scalars['String']
}

export type PrivacyPolicyConfiguration = {
  label: Scalars['String']
  url: Scalars['String']
}

/** Hotel private rate. */
export type PrivateRate = {
  averageRate?: Maybe<Rate>
  corporate?: Maybe<Scalars['Boolean']>
  realTime: Scalars['Boolean']
}

export type Profile = {
  accountType?: Maybe<AccountType>
  /** Additional documents of user like KTN and redress number */
  additionalDocumentsPreferences: AdditionalDocumentsPreferences
  /** Contains the approver Ids for a user for the different app services within Deem@Work. The expense approver Id, for example, is the userId for whom can make approvals on behalf of this user for the Deem@Work Expense app. Same principles apply for Purchase and Travel */
  approvers?: Maybe<Approvers>
  /** Users that delegate users are authorized to reserve itinerary items on behalf of. */
  authorizers?: Maybe<Array<UserDelegate>>
  businessContact?: Maybe<BusinessContact>
  cluster?: Maybe<Scalars['String']>
  /** The email recipient that trip confirmation is sent to, if 'send to another email' has been configured in the Dashboard */
  confEmail?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  creationSource?: Maybe<UserCreationSource>
  /** A list of credit cards, fetched from user's profile. */
  creditCards?: Maybe<Array<ProfileCreditCard>>
  currency?: Maybe<Scalars['String']>
  dateFormat?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['String']>
  /** Users who can reserve itinerary items on behalf of other users. */
  delegates?: Maybe<Array<UserDelegate>>
  emergencyContact?: Maybe<EmergencyContact>
  employee?: Maybe<Employee>
  firstName?: Maybe<Scalars['String']>
  /** Is this user required to create new password when login */
  forceNewPassword?: Maybe<Scalars['Boolean']>
  gender?: Maybe<Gender>
  genderOptions: Array<Gender>
  /** Groupware object containing groupware ID email, which is used for calendar integration */
  groupware?: Maybe<Groupware>
  hourlyTimeFormat?: Maybe<TimeFormat>
  identityHash: Scalars['String']
  /** If true, the user has one or more authorizers and the delegate drop-down will be shown. Only applicable for enterprise users */
  isDelegate?: Maybe<Scalars['Boolean']>
  /** A string that will allow the user to be dynamically assigned into a named group, currently used only for meeting-specific group overrides */
  isDynamicGroup?: Maybe<Scalars['Boolean']>
  /** Is this user a guest user? Guest users typically have reduced application functionality */
  isGuest?: Maybe<Scalars['Boolean']>
  itineraryMonths?: Maybe<Scalars['Float']>
  lastName?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  measurementUnit?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  mis?: Maybe<Mis>
  /** A national ID card that is held by a user. A user can have multiple national ID cards, and national ID cards are compared using the tuple of "country" and "number", both of which are required. */
  nationalIdCards?: Maybe<Array<NationalIdCard>>
  notification?: Maybe<Array<Notification>>
  /** A passport that is held by a user. A user can have multiple passports, and passports are compared using the tuple of "country" and "number", both of which are required. Note that these can be issued by a different country's consulate. */
  passports?: Maybe<Array<Passport>>
  personalContact?: Maybe<PersonalContact>
  preferredCreditCards?: Maybe<Array<PreferredCreditCard>>
  /** Unique identifier that is used in partner contexts and whose meaning is partner-specific. Examples: Axiom pin number, often a phone number, used for navigational support and/or offline assistance */
  profilePin?: Maybe<Scalars['String']>
  pwaWelcomeMessage?: Maybe<PwaWelcomeMessage>
  rawSuffix?: Maybe<Scalars['String']>
  registrationStatus?: Maybe<RegistrationStatus>
  roles?: Maybe<Array<Role>>
  site?: Maybe<UserSite>
  /** @deprecated Use site.name instead */
  siteName?: Maybe<Scalars['String']>
  suffix?: Maybe<UserSuffix>
  suffixOptions: Array<UserSuffix>
  /** @deprecated Use hourlyTimeFormat instead. */
  timeFormat?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
  title?: Maybe<UserTitle>
  titleOptions: Array<UserTitle>
  /** Used in enterprise login – if not needed due to SSO, has the same value as externalId */
  username?: Maybe<Scalars['String']>
  /** A visa (of any type) that is held by a user. A user can have multiple visas, and visas are compared using the tuple of "country" and "number", both of which are required. */
  visas?: Maybe<Array<Visa>>
}

/** Parameters to be passed from apple connect to the authentication endpoint. */
export type ProfileAttribute = {
  /** Identifier for the Apple user, as populated by Apple. */
  customerId?: InputMaybe<Scalars['Int']>
  /** Identifier for the Apple user/site, as generated by Apple. */
  externalId?: InputMaybe<Scalars['Int']>
  /** First name of an Apple user */
  firstName?: InputMaybe<Scalars['String']>
  /** Last name of an Apple user */
  lastName?: InputMaybe<Scalars['String']>
  /** Identifier for the Apple site, as populated by Apple. */
  siteId?: InputMaybe<Scalars['Int']>
}

export type ProfileCreditCard = {
  /** Gazoo's full alpha-numeric string ID. */
  id: Scalars['String']
  /** ISO 8601 date format card expiration deadline. */
  preExpirationAlert?: Maybe<Scalars['String']>
}

export enum ProfileDataSource {
  Profile = 'PROFILE',
  Purchase = 'PURCHASE',
}

export type ProfileFieldListOptions = {
  /** List option name for fields */
  name: Scalars['String']
  /** List option value for fields */
  value: Scalars['String']
}

export type ProfileFieldMetadata = {
  allowCharacters?: Maybe<Array<AllowedCharacters>>
  defaultListValue?: Maybe<FieldSettingsListDefaultValue>
  defaultValue?: Maybe<Scalars['String']>
  fieldType?: Maybe<FieldSettingsType>
  maxLength?: Maybe<Scalars['Float']>
  minLength?: Maybe<Scalars['Float']>
  options?: Maybe<Array<ProfileFieldListOptions>>
  sortAlphabetically?: Maybe<Scalars['Boolean']>
}

export type ProfileFieldSettings = {
  /** Category ID for a custom label defined for use in the user profile. */
  categoryId?: Maybe<Scalars['Float']>
  /** Metadata describing text/list nature of the field. */
  fieldMetadata?: Maybe<ProfileFieldMetadata>
  /** Determines if the field is editable. */
  isEditable: Scalars['Boolean']
  /** Indicator that the field is required to be updated. */
  isRequired: Scalars['Boolean']
  /** Default label for this field. */
  labelDefault?: Maybe<Scalars['String']>
  /** Custom label for this field, set within console settings. */
  labelToDisplay?: Maybe<Scalars['String']>
  /** Custom label profile metadata ID. */
  profileMetadataId?: Maybe<Scalars['Float']>
  /** Determines weather or not this field should be presented in the registration page. */
  shouldDisplayInRegistration?: Maybe<Scalars['Boolean']>
  /** Determines weather or not this field should be presented in the settings page. */
  shouldDisplayInSettings?: Maybe<Scalars['Boolean']>
}

export type ProfileFieldsSettings = {
  /** Profile Settings per field. */
  fields: Array<ProfileFieldSettings>
}

export type ProviderDetails = {
  providerName?: Maybe<Scalars['String']>
  providerPhone?: Maybe<Scalars['String']>
}

export type PurchaseInfoDetails = {
  agency: PurchaseInfoDetailsAgency
  availablePreferences?: Maybe<AvailablePreferences>
  billing: Array<PurchaseInfoDetailsBilling>
  costAllocationName?: Maybe<Scalars['String']>
  costAllocationSegment?: Maybe<Scalars['Float']>
  displaySettings?: Maybe<DisplaySettings>
  emergencyContact: PurchaseInfoDetailsEmergencyContact
  isCostAllocationRequired?: Maybe<Scalars['Boolean']>
  isNumberOfBagsRequired?: Maybe<Scalars['Boolean']>
  preBookRequestRequirement?: Maybe<PreBookRequestRequirement>
  travelerPreferences?: Maybe<TravelerPreferences>
}

export type PurchaseInfoDetailsAgency = {
  address: Address
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  name: Scalars['String']
  phoneNumber: Scalars['String']
}

export type PurchaseInfoDetailsBilling = {
  id: Scalars['ID']
  info: Array<PurchaseInfoDetailsBillingInfo>
}

export type PurchaseInfoDetailsBillingInfo = {
  address?: Maybe<Address>
  id?: Maybe<Scalars['ID']>
  isCardOutOfPolicy?: Maybe<Scalars['Boolean']>
  payment?: Maybe<PurchaseInfoDetailsBillingInfoPayment>
}

export type PurchaseInfoDetailsBillingInfoPayment = {
  creditCard?: Maybe<PurchaseInfoDetailsBillingInfoPaymentCreditCard>
  siteCard?: Maybe<PurchaseInfoDetailsBillingInfoPaymentSiteCard>
}

export type PurchaseInfoDetailsBillingInfoPaymentCreditCard = {
  expirationDate: Scalars['String']
  id: Scalars['ID']
  label: Scalars['String']
  nameOnTheCard: Scalars['String']
  number: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type PurchaseInfoDetailsBillingInfoPaymentSiteCard = {
  id: Scalars['ID']
  isRestricted?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
}

export type PurchaseInfoDetailsEmergencyContact = {
  address?: Maybe<Address>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  phone?: Maybe<PurchaseInfoDetailsEmergencyContactPhone>
  relationship?: Maybe<Scalars['String']>
}

export type PurchaseInfoDetailsEmergencyContactPhone = {
  primary?: Maybe<Phone>
  secondary?: Maybe<Phone>
}

export type PurchaseInfoInput = {
  deviceClass?: InputMaybe<DeviceClass>
  /** Whether or not a credit card number is encrypted. If set to "TRUE", only the last 4 digits of the credit card number are returned. */
  isCCNumberMasked?: InputMaybe<Scalars['Boolean']>
  itineraryId: Scalars['String']
}

export type PurchaseInfoResult = {
  additionalTravelers: Array<PurchaseInfoTraveler>
  billingParameters?: Maybe<Array<Maybe<BillingParameter>>>
  customFieldsConfiguration?: Maybe<CustomFieldConfiguration>
  details: PurchaseInfoDetails
  dynamicSiteMessages: Array<DynamicSiteMessage>
  primaryTraveler: PurchaseInfoTraveler
}

export type PurchaseInfoTraveler = {
  address: Address
  dateBirth?: Maybe<Scalars['String']>
  /** @deprecated Use `dateBirth`. */
  dateOfBirth: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  /** @deprecated Use `sex`. */
  gender: Gender
  id: Scalars['String']
  lastName: Scalars['String']
  middleInitial?: Maybe<Scalars['String']>
  options?: Maybe<TravelerOptions>
  passports?: Maybe<Array<Passport>>
  phone: Array<Phone>
  primary: Scalars['Boolean']
  sex?: Maybe<Gender>
  suffix?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PurchaseItineraryFailedBookings = {
  /** The associated hotel failed booking if any. */
  hotelFailedBooking?: Maybe<PurchaseItineraryHotelFailedBooking>
}

export type PurchaseItineraryHotelFailedBooking = {
  /** Describe the error that occurred when booking a hotel. */
  error?: Maybe<HotelFailedBookingError>
  /** The hotel data associated with the failed booking, intended to be used for displaying failed hotel content on the UI. */
  hotelData: PurchaseItineraryHotelFailedBookingData
}

export type PurchaseItineraryHotelFailedBookingData = {
  /** The address of the hotel associated with the failed booking. */
  address?: Maybe<Address>
  /** The check in date associated with the failed booking. */
  checkIn?: Maybe<Scalars['String']>
  /** The check out date associated with the failed booking. */
  checkOut?: Maybe<Scalars['String']>
  /** The id associated with the failed hotel. */
  hotelId?: Maybe<Scalars['String']>
  /** The name of the hotel associated with the failed booking. */
  name?: Maybe<Scalars['String']>
  /** The number of nights associated with the failed booking. */
  numberOfNights: Scalars['Float']
  /** The room type associated with the failed booking. */
  roomType?: Maybe<RoomType>
}

export type PurchaseItineraryResponse = CommonResponse & {
  bookId?: Maybe<Scalars['String']>
  code: Scalars['String']
  errorCodes?: Maybe<Array<Scalars['String']>>
  /** List of failed booking associated with the reservation. */
  failedBookings?: Maybe<Array<PurchaseItineraryFailedBookings>>
  message: Scalars['String']
  messages?: Maybe<Array<DynamicSiteMessage>>
  success: Scalars['Boolean']
}

export type Query = {
  activeItinerary: Itinerary
  airportDetails: AirportDetails
  alerts: Alerts
  autocompletePlaces: Array<PlaceAutocomplete>
  availableSpecialRequests?: Maybe<SpecialRequests>
  availableVendors?: Maybe<AvailableVendors>
  bookRideHail: BookRideHailResponse
  brandConfiguration: BrandConfiguration
  /** A standalone endpoint to calculate car emissions batch. */
  calculateCarEmissionsBatch: CalculateCarEmissionsBatchResult
  /** A standalone endpoint to calculate flight emissions batch. */
  calculateFlightEmissionsBatch: CalculateFlightEmissionsBatchResult
  /** A standalone endpoint to calculate hotel emissions batch. */
  calculateHotelEmissionsBatch: CalculateHotelEmissionsBatchResult
  /** A standalone endpoint to calculate rail emissions batch. */
  calculateRailEmissionsBatch: CalculateRailEmissionsBatchResult
  /** A standalone endpoint to calculate trip emissions. Trip emissions can include rails, flights, hotels and car-rental */
  calculateTripEmissions: CalculateTripEmissionsResult
  /** @deprecated Use cancelRideHail Mutation. */
  cancelRideHail?: Maybe<CancelRideHailResponse>
  cancelRideHailByProcessId?: Maybe<CancelRideHailByProcessIdResponse>
  carDetails: CarRentalCar
  /** @deprecated carRentalMultiVendorLocations */
  carRentalLocations: CarRentalLocationsResult
  carRentalMultiVendorLocations: CarRentalMultiVendorLocationsResult
  carRentals: CarRentalsResult
  carRules: Array<CarRules>
  carRulesByTransactionId: Array<CarRules>
  /** cart query is used to retrieve all the intents in an existing cart. */
  cart: GetCartResponseUnion
  /** Returns cart behaviours. */
  cartBehaviours: GetCartBehavioursResponseUnion
  /** Get Cost Allocation query. */
  cartCostAllocation: GetCostAllocationResponseUnion
  costAllocation?: Maybe<CostAllocation>
  costSegment?: Maybe<CostSegment>
  costSegments?: Maybe<CostSegments>
  /** Get cost summary by specific cart. */
  costSummary: GetCostSummaryResponseUnion
  /** Credit cards retrieved from the legacy system. */
  creditCards: Array<UserCreditCard>
  customerUberAccountStatus: CustomerUberAccountStatusResponse
  deemHeader: DeemHeader
  /** Returns a list of users that are delegated to the currently logged in user. */
  delegatedUsers?: Maybe<DelegatedUsers>
  /**
   * Returns a list of users that are delegated to the currently logged in user.
   * @deprecated Use `delegatedUsers`.
   */
  delegates: DelegatesResponse
  displayConfiguration: DisplayConfiguration
  /** Get Support Article by ID from Document360 */
  document360SupportArticleById: Document360SupportArticle
  enhancedSeatMap: GetEnhancedSeatMapResponse
  /** Retrieves fares from the rail provider */
  fares: RailFareResult
  featureFlags: FeatureFlags
  /**
   * Use getFlightFareAttributesInBatch insetead
   * @deprecated Cannot support get attributes for all flights in on call
   */
  flightFareAttributes: FlightFareAttributesResult
  flightFareAttributesInBatch: Array<FlightFareAttributesInBatchResponseDto>
  flightFareRules: FlightFareRulesResult
  flightFareRulesByTransactionId: FlightFareRulesByTransactionIdResult
  flightVoiceSearch: FlightVoiceSearchResponse
  flights: FlightsResult
  /** @deprecated Use `multiTierCostAllocation`. */
  foxCostAllocation: CostAllocation
  geocodeByAddress: Geocode
  geosureScores: GeosureScoresResult
  getAlternatePolicyTrips: GetAlternatePolicyTripsResponseDtoUnion
  getAvailableDelegates?: Maybe<GetAvailableDelegates>
  /** Returns all needed data to review and book a trip. */
  getCheckoutInfo: GetCheckoutInfoResponseUnion
  getCurrencies?: Maybe<GetCurrencies>
  /** Get custom fields from cart. */
  getCustomFields: GetCustomFieldsResponseUnion
  /** This query depends on getFlights query, it must be called after the previous one has finished. */
  getFlightDynamicSiteMessages: GetFlightDynamicSiteMessagesResponse
  getLanguages?: Maybe<GetLanguages>
  getLocationRefinementOptions: LocationRefinementResponseUnion
  getMeetingInfo: GetMeetingInfoResponseUnion
  getRailETickets: RailETicketResult
  getTimeZones?: Maybe<GetTimeZones>
  getUserProfile: UserProfile
  homeAirport: Array<PlaceAutocomplete>
  hotel: Hotel
  hotelNames?: Maybe<HotelNamesResult>
  hotelReviews: HotelReviewsResult
  hotelRoomDetails: RoomDetails
  hotels: HotelsResult
  identityHash: HeapIdentity
  itinerary: Itinerary
  multiLayerCostAllocation?: Maybe<MultiLayerCostAllocation>
  multiLayerCostSegments?: Maybe<MultiLayerCostSegments>
  multiTierCostAllocation: CostAllocation
  /** Returns OOP Information. */
  oopInfo: GetOopInfoResponseUnion
  /** @deprecated use trip */
  orderDetails: OrderDetails
  orders: OrdersResult
  outOfPolicyReason?: Maybe<Array<Maybe<OutOfPolicyReason>>>
  partnerCoBrandingConfiguration: PartnerCoBrandingCofiguration
  passwordSecurityConfigure: PasswordSecurityConfigure
  postPurchaseInfo: PurchaseInfoResult
  preBookInfo: PreBookInfoPayload
  profileFieldsSettings: ProfileFieldsSettings
  purchaseInfo: PurchaseInfoResult
  rail: RailResult
  railCards: RailCardsResult
  /** @deprecated railFareAttributes' Not in use */
  railFareAttributes: RailFareAttributesResult
  railFareConditions: FareConditionsResult
  /** @deprecated 'railFareRules' Not in use */
  railFareRules: RailFareRulesResult
  /** @deprecated 'railItinerary' Not in use. */
  railItinerary: Itinerary
  /**
   * Retrieves, from a rail provider such as Trainline, rail journey preferences like:
   * 1. Seat preferences
   * 2. Type of Seat reservation
   */
  railJourneyPreferences: RailPreferencesResult
  /** @deprecated Use `railJourneyPreferences`. */
  railPreferences: RailPreferencesResult
  recentCarRentalSearches: RecentCarRentalSearchesUnionResult
  recentCarRentalSearchesWithoutDates: RecentCarRentalSearchesWithoutDatesUnionResult
  recentFlightSearches: RecentFlightSearchesResultUnion
  recentFlightSearchesWithoutDates: RecentFlightSearchesWithoutDatesResultUnion
  recentHotelSearches: RecentHotelSearchesResultUnion
  recentHotelSearchesWithoutDates: RecentHotelSearchesWithoutDatesResultUnion
  recentRailSearches: RecentRailSearchesResultUnion
  recentRailSearchesWithoutDates: RecentRailSearchesWithoutDatesResultUnion
  referencePoints: ReferencePoints
  /** Get Dynamic Site Messages from cart. */
  reservationDSM: DynamicSiteMessagesResponseUnion
  rideHailExpenseConfig: RideHailExpenseConfigResponseUnion
  rideHailSuggestedLocations: RideHailSuggestedLocationsResponseUnion
  rideHailSuggestions: RideHailSuggestionsResponse
  rideHailUpcomingFlights: RideHailUpcomingFlightsResponseUnion
  /** @deprecated Use `hotelRoomDetails`. */
  roomDetails: RoomDetails
  searchInitiateInfo: SearchInitiateInfoResult
  searchMobilityMultiModal: MobilityMultiModalSearchResponseDtoUnion
  searchRideHail: SearchRideHailResponse
  searchRideHailWithError: SearchRideHailResponseUnion
  seatAttributes: GetSeatAttributesResponse
  seatMap: SeatMapResult
  seatMapsByProcessId: Array<Maybe<SeatMapResult>>
  siteCards: SiteCards
  siteSsoSettings: SiteSsoSettings
  sitesByApiKey: SitesByApiKeyResponse
  sitesByUserEmail: Array<SiteAccount>
  suggestedHomeAirports: Array<PlaceAutocomplete>
  supportArticleById?: Maybe<SupportArticle>
  supportArticles: Array<SupportArticle>
  supportInformation: SupportInformation
  travelPolicy: TravelPolicy
  travelPolicyList?: Maybe<TravelPolicyList>
  travelPreferences?: Maybe<TravelPreferences>
  trip: OrderDetails
  /** tripDetails mutation provides the details of the reservations associated with the trip. */
  tripDetails: GetTripDetailsResponseUnion
  unusedTickets: UnusedTicketResponse
  user: User
  /** userTrips query is used to retrieve the trips associated with the user based on the tripType filter. */
  userTrips: GetUserTripsResponseUnion
  zooKeeperFlags: ZooKeeperFlags
}

export type QueryActiveItineraryArgs = {
  deviceClass?: InputMaybe<DeviceClass>
  imageOptions?: InputMaybe<ImageInputOptions>
}

export type QueryAirportDetailsArgs = {
  airportCode: Scalars['String']
}

export type QueryAlertsArgs = {
  input?: InputMaybe<AlertsInput>
}

export type QueryAutocompletePlacesArgs = {
  input: PlacesAutocompleteInput
}

export type QueryAvailableSpecialRequestsArgs = {
  input: SpecialRequestsInput
}

export type QueryAvailableVendorsArgs = {
  input: AvailableVendorsInput
}

export type QueryBookRideHailArgs = {
  input: BookRideHailRequest
}

export type QueryCalculateCarEmissionsBatchArgs = {
  input: CalculateCarEmissionsBatchInput
}

export type QueryCalculateFlightEmissionsBatchArgs = {
  input: CalculateFlightEmissionsBatchInput
}

export type QueryCalculateHotelEmissionsBatchArgs = {
  input: CalculateHotelEmissionsBatchInput
}

export type QueryCalculateRailEmissionsBatchArgs = {
  input: CalculateRailEmissionsBatchInput
}

export type QueryCalculateTripEmissionsArgs = {
  input: CalculateTripEmissionsInput
}

export type QueryCancelRideHailArgs = {
  input: CancelRideHailRequest
}

export type QueryCancelRideHailByProcessIdArgs = {
  input: CancelRideHailByProcessIdRequest
}

export type QueryCarDetailsArgs = {
  id: Scalars['String']
  input?: InputMaybe<CarDetailsInput>
  logsMetadata?: InputMaybe<LogsMetadata>
  searchId?: InputMaybe<Scalars['String']>
}

export type QueryCarRentalLocationsArgs = {
  input: CarRentalLocationsInput
}

export type QueryCarRentalMultiVendorLocationsArgs = {
  input: CarRentalLocationsInput
}

export type QueryCarRentalsArgs = {
  input: CarRentalsInput
}

export type QueryCarRulesArgs = {
  carSegmentId: Scalars['String']
  itineraryId: Scalars['String']
}

export type QueryCarRulesByTransactionIdArgs = {
  input: GetCarRentalRulesByTransactionIdInput
}

export type QueryCartArgs = {
  input: GetCartInput
}

export type QueryCartBehavioursArgs = {
  cartId: Scalars['String']
}

export type QueryCartCostAllocationArgs = {
  cartId: Scalars['String']
}

export type QueryCostAllocationArgs = {
  id: Scalars['Float']
  input?: InputMaybe<CostAllocationPageInput>
  query?: InputMaybe<Scalars['String']>
}

export type QueryCostSegmentArgs = {
  id: Scalars['Float']
}

export type QueryCostSummaryArgs = {
  cartId: Scalars['ID']
}

export type QueryCreditCardsArgs = {
  isCCNumberMasked?: InputMaybe<Scalars['Boolean']>
}

export type QueryDeemHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
  meetingsModeElements?: InputMaybe<MeetingsModeSessionElements>
}

export type QueryDelegatedUsersArgs = {
  input: DelegatedUsersInput
}

export type QueryDelegatesArgs = {
  customerId: Scalars['Int']
  locale?: InputMaybe<Scalars['String']>
  meetingsModeElements?: InputMaybe<MeetingsModeSessionElements>
}

export type QueryDisplayConfigurationArgs = {
  input?: InputMaybe<DisplayConfigurationInput>
}

export type QueryDocument360SupportArticleByIdArgs = {
  input: Document360SupportArticleByIdInput
}

export type QueryEnhancedSeatMapArgs = {
  input: EnhancedSeatMapInput
}

export type QueryFaresArgs = {
  input: RailFareInput
}

export type QueryFeatureFlagsArgs = {
  input: FeatureFlagsInput
}

export type QueryFlightFareAttributesArgs = {
  input: FlightFareAttributesInput
}

export type QueryFlightFareAttributesInBatchArgs = {
  input: FlightFareAttributesInBatchInput
}

export type QueryFlightFareRulesArgs = {
  input: FlightFareRulesInput
}

export type QueryFlightFareRulesByTransactionIdArgs = {
  input: FlightFareRulesByTransactionIdInput
}

export type QueryFlightVoiceSearchArgs = {
  input: VoiceSearchInput
}

export type QueryFlightsArgs = {
  input: FlightsInput
}

export type QueryFoxCostAllocationArgs = {
  input?: InputMaybe<FoxCostAllocationInput>
}

export type QueryGeocodeByAddressArgs = {
  input: GeocodeByAddressInput
}

export type QueryGeosureScoresArgs = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type QueryGetAlternatePolicyTripsArgs = {
  input: GetAlternatePolicyTripsInput
}

export type QueryGetAvailableDelegatesArgs = {
  query: Scalars['String']
}

export type QueryGetCheckoutInfoArgs = {
  input: CheckoutInfoInput
}

export type QueryGetCustomFieldsArgs = {
  input: GetCustomFieldsInput
}

export type QueryGetFlightDynamicSiteMessagesArgs = {
  GetFlightDynamicSiteMessagesInputDto: GetFlightDynamicSiteMessagesInputDto
}

export type QueryGetLocationRefinementOptionsArgs = {
  input: LocationRefinementOptionsRequest
}

export type QueryGetRailETicketsArgs = {
  input: RailETicketInput
}

export type QueryGetUserProfileArgs = {
  includeOpenEnrollmentRegistrationCustomFields?: InputMaybe<Scalars['Boolean']>
}

export type QueryHotelArgs = {
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  groupId?: InputMaybe<Scalars['String']>
  guests?: InputMaybe<Scalars['Int']>
  hotelResultId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  latitude: Scalars['Float']
  logsMetadata?: InputMaybe<LogsMetadata>
  longitude: Scalars['Float']
}

export type QueryHotelNamesArgs = {
  airportCode?: InputMaybe<Scalars['String']>
  amenityIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  deviceClass?: InputMaybe<DeviceClass>
  distance?: InputMaybe<Scalars['Float']>
  distanceUnit?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  groupId?: InputMaybe<Scalars['String']>
  guests: Scalars['Int']
  hideSoldOut?: InputMaybe<Scalars['Boolean']>
  hotelName?: InputMaybe<Scalars['String']>
  hotelResultId?: InputMaybe<Scalars['String']>
  latitude: Scalars['Float']
  locationName?: InputMaybe<Scalars['String']>
  logsMetadata?: InputMaybe<LogsMetadata>
  longitude: Scalars['Float']
  nameSearch?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  starRatings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  stationCode?: InputMaybe<Scalars['String']>
}

export type QueryHotelReviewsArgs = {
  hotelId: Scalars['ID']
  logsMetadata?: InputMaybe<LogsMetadata>
}

export type QueryHotelRoomDetailsArgs = {
  input: HotelRoomDetailsInput
  logsMetadata?: InputMaybe<LogsMetadata>
}

export type QueryHotelsArgs = {
  airportCode?: InputMaybe<Scalars['String']>
  amenityIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  deviceClass?: InputMaybe<DeviceClass>
  distance?: InputMaybe<Scalars['Float']>
  distanceUnit?: InputMaybe<Scalars['String']>
  failedBooking?: InputMaybe<FailedBooking>
  first?: InputMaybe<Scalars['Int']>
  groupId?: InputMaybe<Scalars['String']>
  guests: Scalars['Int']
  hideSoldOut?: InputMaybe<Scalars['Boolean']>
  hotelName?: InputMaybe<Scalars['String']>
  hotelResultId?: InputMaybe<Scalars['String']>
  latitude: Scalars['Float']
  locationName?: InputMaybe<Scalars['String']>
  logsMetadata?: InputMaybe<LogsMetadata>
  longitude: Scalars['Float']
  nameSearch?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  starRatings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  stationCode?: InputMaybe<Scalars['String']>
}

export type QueryIdentityHashArgs = {
  email: Scalars['String']
}

export type QueryItineraryArgs = {
  deviceClass?: InputMaybe<DeviceClass>
  imageOptions?: InputMaybe<ImageInputOptions>
  itineraryId: Scalars['String']
}

export type QueryMultiLayerCostAllocationArgs = {
  input: MultiLayerCostAllocationInput
}

export type QueryMultiTierCostAllocationArgs = {
  input?: InputMaybe<MultiTierCostAllocationInput>
}

export type QueryOopInfoArgs = {
  cartId: Scalars['ID']
}

export type QueryOrderDetailsArgs = {
  transactionId: Scalars['ID']
}

export type QueryOrdersArgs = {
  input: OrdersInput
}

export type QueryOutOfPolicyReasonArgs = {
  segments: Array<InputMaybe<Scalars['String']>>
}

export type QueryPartnerCoBrandingConfigurationArgs = {
  input: PartnerCoBrandingConfigurationInput
}

export type QueryPostPurchaseInfoArgs = {
  input: PostPurchaseInfoInput
}

export type QueryPreBookInfoArgs = {
  input: BookItineraryInput
}

export type QueryPurchaseInfoArgs = {
  input: PurchaseInfoInput
}

export type QueryRailArgs = {
  input: RailInput
}

export type QueryRailFareAttributesArgs = {
  input: RailFareAttributesInput
}

export type QueryRailFareConditionsArgs = {
  input: RailFareConditionsInput
}

export type QueryRailFareRulesArgs = {
  input: RailFareRulesInput
}

export type QueryRailItineraryArgs = {
  deviceClass?: InputMaybe<DeviceClass>
  imageOptions?: InputMaybe<ImageInputOptions>
  itineraryId: Scalars['String']
}

export type QueryRailJourneyPreferencesArgs = {
  input: RailJourneyPreferencesInput
}

export type QueryRecentCarRentalSearchesArgs = {
  input: RecentCarRentalSearchesInput
}

export type QueryRecentFlightSearchesArgs = {
  input: RecentFlightSearchesInput
}

export type QueryRecentHotelSearchesArgs = {
  input: RecentHotelSearchesInput
}

export type QueryRecentRailSearchesArgs = {
  input: RecentRailSearchesInput
}

export type QueryReferencePointsArgs = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  pageSize?: InputMaybe<Scalars['Int']>
}

export type QueryReservationDsmArgs = {
  input: DynamicSiteMessagesInput
}

export type QueryRideHailExpenseConfigArgs = {
  input: RideHailExpenseConfigInput
}

export type QueryRideHailSuggestedLocationsArgs = {
  input: RideHailSuggestedLocationsRequest
}

export type QueryRoomDetailsArgs = {
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  hotelId: Scalars['ID']
  hotelResultId?: InputMaybe<Scalars['String']>
  logsMetadata?: InputMaybe<LogsMetadata>
  processId?: InputMaybe<Scalars['Int']>
  roomId: Scalars['ID']
}

export type QuerySearchInitiateInfoArgs = {
  input?: InputMaybe<SearchInitiateInfoInput>
}

export type QuerySearchMobilityMultiModalArgs = {
  input: MobilityMultiModalSearchRequest
}

export type QuerySearchRideHailArgs = {
  input: SearchRideHailRequest
}

export type QuerySearchRideHailWithErrorArgs = {
  input: SearchRideHailRequest
}

export type QuerySeatAttributesArgs = {
  input: SeatAttributesInput
}

export type QuerySeatMapArgs = {
  input: SeatMapInput
}

export type QuerySeatMapsByProcessIdArgs = {
  processId: Scalars['String']
}

export type QuerySitesByApiKeyArgs = {
  input: SitesByApiKeyInput
}

export type QuerySitesByUserEmailArgs = {
  input: SitesByUserEmailInput
}

export type QuerySuggestedHomeAirportsArgs = {
  input: SuggestedHomeAirportsInput
}

export type QuerySupportArticleByIdArgs = {
  input: SupportArticleByIdInput
}

export type QuerySupportArticlesArgs = {
  input: SupportArticlesInput
}

export type QueryTripArgs = {
  input: TripInput
}

export type QueryTripDetailsArgs = {
  input: TripDetailsInput
}

export type QueryUserTripsArgs = {
  input: UserTripsInput
}

export type RailAmenity = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type RailCard = {
  code: Scalars['String']
  groupName: Scalars['String']
  name: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
}

export type RailCardInput = {
  code: Scalars['String']
  groupName: Scalars['String']
  name: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
}

export type RailCardsInput = {
  cards: Array<RailCardInput>
}

export type RailCardsResult = {
  cards: Array<RailCard>
}

export type RailCarrier = {
  code?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  logoImageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type RailCarrierInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type RailComparison = {
  brand: RailComparisonBrand
  utas: Array<UniversalTicketAttribute>
}

export type RailComparisonBrand = {
  codes: Array<Scalars['String']>
  name: Scalars['String']
}

export type RailETicketInput = {
  itineraryId: Scalars['String']
  ticketType: ETicketType
}

export type RailETicketResult = {
  /** Array of base64-encoded string rail eTickets. */
  tickets: Array<Scalars['String']>
}

export type RailFare = {
  /** @deprecated Not used anymore. */
  allowedReturnFares?: Maybe<Array<Scalars['String']>>
  appliedRailCards: Array<Scalars['String']>
  /** @deprecated Use `RailResult/FareConditions`. */
  fareAttributes: Array<RailFareAttributes>
  isRefundable?: Maybe<Scalars['Boolean']>
  isSelectable?: Maybe<Scalars['Boolean']>
  isWebFare?: Maybe<Scalars['Boolean']>
  permittedDestinationStations: Array<RailFarePermittedStation>
  permittedOriginStations: Array<RailFarePermittedStation>
  restriction: Scalars['String']
  returnValidity?: Maybe<Scalars['DateTime']>
  rulesAndRestrictions?: Maybe<Array<RailFareRulesAndRestrictions>>
  segments?: Maybe<Array<RailFareSegment>>
  showOutOfPolicy?: Maybe<Scalars['Boolean']>
  tier: RailFareTier
  total: Rate
  tripId?: Maybe<Scalars['String']>
  unusedTickets?: Maybe<SearchUnusedTickets>
}

export type RailFareAttributes = {
  /** @deprecated Not in use */
  comparison: RailComparison
  /** @deprecated Not in use */
  content: Array<OnTheRailContent>
  fareId: Scalars['String']
  label: Scalars['String']
}

export type RailFareAttributesFareInput = {
  id: Scalars['String']
  segments: Array<RailFareAttributesFareSegmentInput>
}

export type RailFareAttributesFareSegmentInput = {
  basisCode: Scalars['String']
  segmentId: Scalars['ID']
  serviceClass: Scalars['String']
}

export type RailFareAttributesInput = {
  leg: RailFareAttributesLegInput
}

export type RailFareAttributesLegInput = {
  fares: Array<RailFareAttributesFareInput>
  segments: Array<RailFareAttributesLegSubSegmentInput>
}

export type RailFareAttributesLegSubSegmentInput = {
  carrier?: InputMaybe<RailCarrierInput>
  departureDate: Scalars['DateTime']
  destination: RailLegSubSegmentAirportInput
  id: Scalars['ID']
  origin: RailLegSubSegmentAirportInput
  trainNumber: Scalars['Int']
}

export type RailFareAttributesResult = {
  fareAttributes: Array<FareAttributes>
}

export type RailFareConditionsInput = {
  searchId: Scalars['String']
}

/** Request input to retrieve rail fares */
export type RailFareInput = {
  /** Journey direction to retrieve the fares for. */
  direction?: InputMaybe<JourneyDirection>
  /** Page number of results. Starting at 0, minus values indicate earlier fares. */
  page?: InputMaybe<Scalars['Int']>
  /** Required. Search id is the unique identifier for a search session. */
  searchId: Scalars['String']
}

export type RailFarePermittedStation = {
  code: Scalars['String']
  description: Scalars['String']
}

export type RailFareResult = {
  /** Fare conditions for all the associated fare types. */
  fareConditions: Array<FareConditions>
  filters: RailFilters
  isCustomTimeReplaced?: Maybe<Scalars['Boolean']>
  maxResultsSize?: Maybe<Scalars['Int']>
  messages?: Maybe<Array<DynamicSiteMessage>>
  pageEnd?: Maybe<Scalars['Int']>
  pageStart?: Maybe<Scalars['Int']>
  /** List of rail legs */
  railLegs: Array<RailLeg>
  /** Policies for all fares. */
  railPolicies: RailPolicies
  totalCount: Scalars['Int']
}

export type RailFareRule = {
  header: Scalars['String']
  value: Scalars['String']
}

export type RailFareRulesAndRestrictions = {
  id: Scalars['String']
  penaltyFee?: Maybe<Array<Maybe<Rate>>>
  rulesAndRestriction: Array<Scalars['String']>
  type: Scalars['String']
}

export type RailFareRulesInput = {
  deviceClass?: InputMaybe<DeviceClass>
  itineraryId: Scalars['String']
  railSegmentId: Scalars['String']
}

export type RailFareRulesResult = {
  rules: Array<Maybe<RailFareRule>>
}

export type RailFareSegment = {
  basisCode?: Maybe<Scalars['String']>
  bookingCode: Scalars['String']
  displayServiceClass?: Maybe<Scalars['String']>
  segmentId: Scalars['ID']
  serviceClass: Scalars['String']
}

export type RailFareTier = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type RailFilters = {
  destinationTime: TimeFilter
  fareTier?: Maybe<Array<FareTierFilter>>
  originTimeRange: TimeFilter
  station: Array<StationFilter>
  stops: Array<StopsFilter>
  trainCompany: Array<TrainCompanyFilter>
}

export type RailInput = {
  after?: InputMaybe<Scalars['Int']>
  changesFilters?: InputMaybe<Scalars['Int']>
  departArriveRanges?: InputMaybe<Array<DepartArriveRangesInput>>
  destinationStationFilters?: InputMaybe<Array<Scalars['String']>>
  deviceClass?: InputMaybe<DeviceClass>
  direction?: InputMaybe<JourneyDirection>
  excludeOutOfPolicy?: InputMaybe<Scalars['Boolean']>
  filterFareTiers?: InputMaybe<Array<FareTier>>
  first?: InputMaybe<Scalars['Int']>
  includeNearbyStations?: InputMaybe<Scalars['Boolean']>
  isCustomTimeReplaced?: InputMaybe<Scalars['Boolean']>
  isCustomTimeSearch?: InputMaybe<Scalars['Boolean']>
  legPosition?: InputMaybe<Scalars['LegPosition']>
  originStationFilters?: InputMaybe<Array<Scalars['String']>>
  page?: InputMaybe<Scalars['Int']>
  searchId: Scalars['ID']
  selectedFareTiers?: InputMaybe<Array<FareTier>>
  selectedLegs?: InputMaybe<Array<LegSelectionInput>>
  selectedServiceClasses?: InputMaybe<Array<Array<Scalars['String']>>>
  sortBy?: InputMaybe<RailSortBy>
  stopFilters?: InputMaybe<Array<Scalars['String']>>
  takeoffLandingRanges?: InputMaybe<Array<TakeoffLandingRangesInput>>
  trainCompanyFilters?: InputMaybe<Array<Scalars['String']>>
  unusedTicket?: InputMaybe<Scalars['Boolean']>
  webfareSupported?: InputMaybe<Scalars['Boolean']>
}

export type RailJourneyPreferencesInput = {
  inwardJourney?: InputMaybe<JourneyIdentifier>
  outwardJourney?: InputMaybe<JourneyIdentifier>
  searchId: Scalars['String']
}

/** Inbound or outbound rail leg details. */
export type RailLeg = {
  /** Arrival station. */
  arrivalStation: RailStation
  /** Arrival time. */
  arrivalTime: Scalars['DateTime']
  /** Displays the availability of the rail service. Display the fares only if the service is available. */
  availability: TriStateBoolean
  /** Carbon footprint of the journey, calculated in grams per passenger. */
  carbonEmissionsPerPassenger?: Maybe<Scalars['Int']>
  /** Duration of travel in days. */
  daysInBetween?: Maybe<Scalars['Int']>
  /** Departure station name. For example, "London Waterloo". */
  departureStation: RailStation
  /** Train departure time. For example, "14:30". */
  departureTime: Scalars['DateTime']
  /** Direction of travel. For example, "Outbound", "Inbound". */
  direction: JourneyDirection
  /** Carbon footprint of the journey, calculated in kilograms per passenger. */
  ecoInfo?: Maybe<Scalars['String']>
  /** List of fares, by leg. The Fares will be for the sum of the segments involved in a rail leg. */
  fares?: Maybe<Array<Fare>>
  /** Sorted list of fares. */
  faresList: Array<RailFare>
  /** Leg ID. */
  id: Scalars['String']
  /** Duration of travel. Example: 1h 26m */
  journeyDuration?: Maybe<TravelTime>
  /** List of labels which could be applied to rail legs like cheapest, fastest etc. */
  labels?: Maybe<Array<Scalars['String']>>
  /** Origin station platform. */
  platform?: Maybe<Scalars['String']>
  /** Rate with currency. */
  rate?: Maybe<Rate>
  /** List of rail segments in the leg. */
  segments?: Maybe<Array<RailSegment>>
}

export type RailLegCommon = {
  legId: Scalars['String']
  policy: Policy
  railStops: Scalars['Int']
  railTime?: Maybe<TravelTime>
}

export type RailLegConnectionInfo = {
  connectionTimeMinutes: Scalars['Int']
  segmentId: Scalars['String']
}

export type RailLegSearchInput = {
  departureDate: Scalars['Date']
  destination?: InputMaybe<Scalars['AirportCode']>
  destinationLocation?: InputMaybe<GeocodeInput>
  destinationUniqueCode?: InputMaybe<Scalars['String']>
  endTimeRange?: InputMaybe<Scalars['String']>
  number: Scalars['Int']
  origin?: InputMaybe<Scalars['AirportCode']>
  originLocation?: InputMaybe<GeocodeInput>
  originUniqueCode?: InputMaybe<Scalars['String']>
  preferredTime: Scalars['String']
  railCards?: InputMaybe<RailCardsInput>
  serviceClass: Scalars['String']
  startTimeRange?: InputMaybe<Scalars['String']>
  timeRangeBy: Scalars['String']
}

export type RailLegSegmentTrain = {
  code: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type RailLegSubSegmentAirportInput = {
  code: Scalars['AirportCode']
}

export type RailLegSubSegmentCommon = {
  arrivalDate: Scalars['DateTime']
  departureDate: Scalars['DateTime']
  railTime?: Maybe<TravelTime>
}

export type RailLegSubSegmentStation = {
  city: Scalars['String']
  code: Scalars['String']
  name: Scalars['String']
  terminal?: Maybe<Scalars['String']>
}

/** Represents an amount of money with its currency type. */
export type RailMoney = {
  /** The 3-letter currency code defined in ISO 4217. */
  currencyCode?: Maybe<Scalars['String']>
  /**
   * Number of nano (10^-9) units of the amount.
   * The value must be between -999,999,999 and +999,999,999 inclusive.
   * If `units` is positive, `nanos` must be positive or zero.
   * If `units` is zero, `nanos` can be positive, zero, or negative.
   * If `units` is negative, `nanos` must be negative or zero.
   * For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
   */
  nanos?: Maybe<Scalars['Int']>
  /** The whole units of the amount. For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar. */
  units?: Maybe<Scalars['Int']>
}

/** Policies info based on Customer identifiers. */
export type RailPolicies = {
  /**
   * Indicates whether the reservation of out-of-policy fares is allowed or not.
   * If True, the user is prompted to provide a reason.
   */
  isOutOfPolicyFaresBookable: Scalars['Boolean']
  /** Indicates whether the reservation of first class fares is allowed or not. */
  isRailFirstClassAllowed: Scalars['Boolean']
}

export type RailPreference = CommonTravelPreference & {
  loyaltyProgram: Array<LoyaltyProgram>
  /** Special requests selected by a user for the specific travel type - air, hotel, car rental, rail. This may contain, for example, accessibility requests or a request for a non-smoking room. */
  specialRequest: Array<PreferenceOptions>
  /** Options available for special requests to choose from. It is array of objects, each containing special request code and label with text description. */
  specialRequestOptions?: Maybe<Array<PreferenceOptions>>
}

/** Rail preferences */
export type RailPreferenceInput = {
  /** Loyalty program preferences */
  loyaltyProgram: Array<LoyaltyProgramInput>
  /** Special requests for all trave preferences */
  specialRequest: Array<PreferenceOptionsInput>
}

export type RailPreferencesInput = {
  allocateSeat?: InputMaybe<Scalars['Boolean']>
  deliveryOption?: InputMaybe<Scalars['String']>
  itineraryId?: InputMaybe<Scalars['String']>
  journeyId: Scalars['String']
  operation: Operation
  searchId: Scalars['String']
  seatPreferences?: InputMaybe<UserSeatPreferences>
}

/** Seat preference options, and seat reservation type, retrieved from the rail provider. */
export type RailPreferencesResult = {
  /** Booking fee for the journey. */
  bookingFee?: Maybe<Rate>
  /** Delivery option of the rail ticket */
  deliveryOptions: Array<DeliveryOptions>
  /** Inward seat reservation type from the rail provider. */
  inwardSeatReservationType: SeatReservationType
  /** Outward seat reservation type from the rail provider. */
  outwardSeatReservationType: SeatReservationType
  /** Seat preferences from the rail provider. */
  seatPreferences: SeatPreferences
  /** Seat reservation type from the rail provider. */
  seatReservationType: SeatReservationType
}

export type RailResult = {
  fareConditions: Array<FareConditions>
  filters: RailFilters
  /** @deprecated use journeys instead */
  inwardJourneys?: Maybe<Array<SearchRailLeg>>
  isCustomTimeReplaced?: Maybe<Scalars['Boolean']>
  maxResultsSize?: Maybe<Scalars['Int']>
  messages?: Maybe<Array<DynamicSiteMessage>>
  /** @deprecated use journeys instead */
  outwardJourneys: Array<SearchRailLeg>
  pageEnd?: Maybe<Scalars['Int']>
  pageStart?: Maybe<Scalars['Int']>
  railLegs: Array<SearchRailLeg>
  railPolicies: RailPolicies
  totalCount: Scalars['Int']
}

/** One or more segments that make up the rail leg. */
export type RailSegment = {
  /** Arrival platform number. */
  arrivalPlatform?: Maybe<Scalars['String']>
  /** Arrival station. For example, "Birmingham New Street". */
  arrivalStation: RailStation
  /** Arrival time. */
  arrivalTime: Scalars['DateTime']
  /** The carrier code of the transportation. */
  carrierCode?: Maybe<Scalars['String']>
  /** The carrier name of the transportation. */
  carrierName?: Maybe<Scalars['String']>
  /** Departure platform number. */
  departurePlatform?: Maybe<Scalars['String']>
  /** Departure station. For example, "Birmingham New Street". */
  departureStation: RailStation
  /** Departure time. */
  departureTime: Scalars['DateTime']
  /** Duration of travel time for the segment. For example, "1h 20m". */
  duration?: Maybe<TravelTime>
  /** The length of time between connecting trains. For example "1h 10m" */
  durationsDuringChangeOfLeg?: Maybe<TravelTime>
  /** Segment ID. */
  id: Scalars['Int']
  /** Scheduled stops between departure and destination stations. */
  stops?: Maybe<Array<RailStation>>
  /** Train identification code. */
  trainIdentifier?: Maybe<Scalars['String']>
  /**
   * The Transportation mode of the rail segment.
   * Example: train, rapidTransit, ferry, bus, transfer, walk, taxi, metro, tramlink, tram, hovercraft
   */
  transportationMode: RailTransportationMode
}

export enum RailSortBy {
  Arrival = 'ARRIVAL',
  Cost = 'COST',
  Departure = 'DEPARTURE',
  Duration = 'DURATION',
  Policy = 'POLICY',
  Preference = 'PREFERENCE',
}

/** Information about the stations where the service stops between departure and destination stations. */
export type RailStation = {
  /**
   * Indicates whether a ticket machine is available at that station.
   * "TRUE" indicates that the ticket machine is available at that station.
   */
  TicketMachineAvailable: TriStateBoolean
  /** The address information for the respective train station. */
  address: RailStationAddress
  /**
   * Indicates whether a bus stop is available outside/near the station.
   * "TRUE" indicates that a bus stop is available outside/near the station.
   */
  busStop: Scalars['Boolean']
  /** Station code. */
  code: Scalars['String']
  /**
   * Key identifier that is used in itinerary and reservation flow.
   * For example, "GBHYW" Deem internal five letter code to equate to the Gazoo trainStationCode5
   */
  deemStationId: Scalars['String']
  /** Departure time. */
  departureTime?: Maybe<Scalars['DateTime']>
  /** Identifies the type of location searched by the user. For example "Station". */
  locationType?: Maybe<Scalars['String']>
  /**
   * Indicates the metro information.
   * For example, "Tyne and Wear metro station close to the station. 0191 203 3333"
   */
  metroInformation?: Maybe<Scalars['String']>
  /** Name of a station. */
  name: Scalars['String']
  /**
   * Indicates self-service ticket machines information.
   * For example, "Self-service ticket machines available (Outside the ticket office entrance)"
   */
  ticketInformation?: Maybe<Scalars['String']>
  /** Timezone in which the rail service operates. For example, "Europe/London". */
  timeZone?: Maybe<Scalars['String']>
}

/** The address information for the respective train station. */
export type RailStationAddress = {
  /** Name of the city where the train station is located. */
  city?: Maybe<Scalars['String']>
  /** ISO 3166-1 alpha-2 country code associated with the station. For example, "GB". */
  countryCode?: Maybe<Scalars['String']>
  /** Postal or ZIP Code. */
  postalCode?: Maybe<Scalars['String']>
  /** State, province, or territory code (or other region) inside a country. */
  regionCode?: Maybe<Scalars['String']>
  /** First street line. */
  street1?: Maybe<Scalars['String']>
  /** Second street line. */
  street2?: Maybe<Scalars['String']>
}

export enum RailTransportationMode {
  Bus = 'BUS',
  Ferry = 'FERRY',
  Hovercraft = 'HOVERCRAFT',
  Metro = 'METRO',
  PublicTransit = 'PUBLIC_TRANSIT',
  Taxi = 'TAXI',
  Train = 'TRAIN',
  Tram = 'TRAM',
  Tramlink = 'TRAMLINK',
  Transfer = 'TRANSFER',
  Unspecified = 'UNSPECIFIED',
  Walk = 'WALK',
}

export type Rate = {
  /** Primary money represents the rate in default currency. Currency is configured in dashboard provider configuration. */
  primary: Money
  /** Secondary money represents the rate in user preferred display currency. This value is present only if the amount is not already quoted in the display currency. */
  secondary?: Maybe<Money>
}

export enum RateQualifier {
  Deposit = 'DEPOSIT',
  Guarantee = 'GUARANTEE',
  Prepaid = 'PREPAID',
  Reserve = 'RESERVE',
}

export type RateRange = {
  primary: MoneyRange
  secondary?: Maybe<MoneyRange>
}

export enum RateTypeEnum {
  Daily = 'DAILY',
  Flat = 'FLAT',
  Hourly = 'HOURLY',
  RateOnConfirmation = 'RATE_ON_CONFIRMATION',
  Unspecified = 'UNSPECIFIED',
}

export type ReactivateItineraryInput = {
  processId: Scalars['String']
}

/** Defines fields required to query recent car rental searches. */
export type RecentCarRentalSearchesInput = {
  /** Start date of returning results. Format YYYY-MM-DD in local TZ. */
  startDate: Scalars['String']
}

/** Provides information about car rental pick-up or drop-off. */
export type RecentCarRentalSearchesInteractionItem = {
  /** Car rental search pick-up or drop-off date. Format YYYY-MM-DD in local TZ. */
  date: Scalars['String']
  /** Car rental search pick-up or drop-off location. */
  location: RecentSearchesLocation
  /** Car rental search pick-up or drop-off time. Format hh:mm:ss in local TZ. */
  time: Scalars['String']
}

/** RecentCarRentalSearchesResult returns when the recentCarRentalSearches query has been executed. */
export type RecentCarRentalSearchesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent car rental searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent car rental search items. */
  items: Array<RecentCarRentalSearchesResultItem>
}

/** Provides unique car rental search. */
export type RecentCarRentalSearchesResultItem = {
  /** Car rental search params. */
  carRentalSearchParams: RecentCarRentalSearchesResultItemCarRentalSearchParams
  /** Unique car rental search identifier. */
  id: Scalars['Float']
}

/** Provides car rental search parameters. */
export type RecentCarRentalSearchesResultItemCarRentalSearchParams = {
  /** Car rental search drop-off information. */
  dropOff: RecentCarRentalSearchesInteractionItem
  /** Car rental search pick-up information. */
  pickUp: RecentCarRentalSearchesInteractionItem
}

/** Union of different response objects that can be returned by recentCarRentalSearches query. Returns known/expected errors in a structured manner to the client. */
export type RecentCarRentalSearchesUnionResult = RecentCarRentalSearchesResult

/** Provides information about car rental pick-up or drop-off. */
export type RecentCarRentalSearchesWihtouDatesInteractionItem = {
  /** Car rental search pick-up or drop-off location. */
  location: RecentSearchesLocation
}

/** RecentCarRentalSearchesWithoutDatesResult returns when the recentCarRentalSearchesWithoutDates query has been executed. */
export type RecentCarRentalSearchesWithoutDatesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent car rental searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent car rental search items. */
  items: Array<RecentCarRentalSearchesWithoutDatesResultItem>
}

/** Provides unique car rental search. */
export type RecentCarRentalSearchesWithoutDatesResultItem = {
  /** Car rental search params. */
  carRentalSearchParams: RecentCarRentalSearchesWithoutDatesResultItemCarRentalSearchParams
  /** Unique car rental search identifier. */
  id: Scalars['Float']
}

/** Provides car rental search parameters. */
export type RecentCarRentalSearchesWithoutDatesResultItemCarRentalSearchParams = {
  /** Car rental search drop-off information. */
  dropOff: RecentCarRentalSearchesWihtouDatesInteractionItem
  /** Car rental search pick-up information. */
  pickUp: RecentCarRentalSearchesWihtouDatesInteractionItem
}

/** Union of different response objects that can be returned by recentCarRentalSearchesWithoutDates query. Returns known/expected errors in a structured manner to the client. */
export type RecentCarRentalSearchesWithoutDatesUnionResult = RecentCarRentalSearchesWithoutDatesResult

/** Defines fields required to query recent flight searches. */
export type RecentFlightSearchesInput = {
  /** Start date of returning results. Format YYYY-MM-DD in local TZ. */
  startDate: Scalars['String']
}

/** RecentFlightSearchesResult returns when the recentFlightSearches query has been executed. */
export type RecentFlightSearchesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent flight search successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent flight search items. */
  items: Array<RecentFlightSearchesResultItem>
}

/** Provides unique flight search. */
export type RecentFlightSearchesResultItem = {
  /** Flight search params. */
  flightSearchParams: RecentFlightSearchesResultItemFlightSearchParams
  /** Unique flight search identifier. */
  id: Scalars['Float']
}

/** Provide flight search params. */
export type RecentFlightSearchesResultItemFlightSearchParams = {
  /** Flight search flight type. */
  flightType: RecentSearchesFlightSearchParamsFlightType
  /** Flight search segments. */
  segments: Array<RecentFlightSearchesResultItemFlightSearchSegment>
}

/** Provides flight search segment information. */
export type RecentFlightSearchesResultItemFlightSearchSegment = {
  /** Cabin class, as specified in the flight search. */
  cabinClass: RecentSearchesFlightSearchParamsCabinClass
  /** Flight departure date. Format YYYY-MM-DD in local TZ. */
  departureDate: Scalars['String']
  /** Destination airport location information used in a flight search. */
  destination: RecentSearchesLocation
  /** Origin airport location information used in a flight search. */
  origin: RecentSearchesLocation
  /** Flight search time range filter. */
  searchTimeRange: RecentSearchesResultSearchTimeRange
}

/** Union of different response objects that can be returned by recentFlightSearches query. Returns known/expected errors in a structured manner to the client. */
export type RecentFlightSearchesResultUnion = RecentFlightSearchesResult

/** RecentFlightSearchesWithoutDatesResult returns when the recentFlightSearchesWithoutDates query has been executed. */
export type RecentFlightSearchesWithoutDatesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent flight search successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent flight search items. */
  items: Array<RecentFlightSearchesWithoutDatesResultItem>
}

/** Provides unique flight search. */
export type RecentFlightSearchesWithoutDatesResultItem = {
  /** Flight search params. */
  flightSearchParams: RecentFlightSearchesWithoutDatesResultItemFlightSearchParams
  /** Unique flight search identifier. */
  id: Scalars['Float']
}

/** Provide flight search params. */
export type RecentFlightSearchesWithoutDatesResultItemFlightSearchParams = {
  /** Flight search flight type. */
  flightType: RecentSearchesFlightSearchParamsFlightType
  /** Flight search segments. */
  segments: Array<RecentFlightSearchesWithoutDatesResultItemFlightSearchSegment>
}

/** Provides flight search segment information. */
export type RecentFlightSearchesWithoutDatesResultItemFlightSearchSegment = {
  /** Cabin class, as specified in the flight search. */
  cabinClass: RecentSearchesFlightSearchParamsCabinClass
  /** Destination airport location information used in a flight search. */
  destination: RecentSearchesLocation
  /** Origin airport location information used in a flight search. */
  origin: RecentSearchesLocation
  /** Flight search time range filter. */
  searchTimeRange: RecentSearchesResultSearchTimeRange
}

/** Union of different response objects that can be returned by recentFlightSearchesWithoutDates query. Returns known/expected errors in a structured manner to the client. */
export type RecentFlightSearchesWithoutDatesResultUnion = RecentFlightSearchesWithoutDatesResult

/** Defines fields required to query recent hotel searches. */
export type RecentHotelSearchesInput = {
  /** Start date of returning results. Format YYYY-MM-DD in local TZ. */
  startDate: Scalars['String']
}

/** RecentHotelSearchesResult returns when the recentHotelSearches query has been executed. */
export type RecentHotelSearchesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent hotel searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent hotel search items. */
  items: Array<RecentHotelSearchesResultItem>
}

/** Provides unique hotel search. */
export type RecentHotelSearchesResultItem = {
  /** Hotel search params. */
  hotelSearchParams: RecentHotelSearchesResultItemHotelSearchParams
  /** Unique hotel search identifier. */
  id: Scalars['Float']
}

/** Provides hotel saerch params. */
export type RecentHotelSearchesResultItemHotelSearchParams = {
  /** Hotel search check-in date. Format YYYY-MM-DD in local TZ. */
  checkIn: Scalars['String']
  /** Hotel search check-out date. Format YYYY-MM-DD in local TZ. */
  checkOut: Scalars['String']
  /** Hotel search location information. */
  location: RecentHotelSearchesResultLocation
  /** Hotel search radius in miles. */
  searchRadius: Scalars['Float']
}

/** Provides hotel location. */
export type RecentHotelSearchesResultLocation = {
  /** Location coordinates. */
  geocode: Geocode
  /** Location name. */
  name: Scalars['String']
}

/** Union of different response objects that can be returned by recentHotelSearches query. Returns known/expected errors in a structured manner to the client. */
export type RecentHotelSearchesResultUnion = RecentHotelSearchesResult

/** RecentHotelSearchesWithoutDatesResult returns when the recentHotelSearchesWithoutDates query has been executed. */
export type RecentHotelSearchesWithoutDatesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent hotel searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent hotel search items. */
  items: Array<RecentHotelSearchesWithoutDatesResultItem>
}

/** Provides unique hotel search. */
export type RecentHotelSearchesWithoutDatesResultItem = {
  /** Hotel search params. */
  hotelSearchParams: RecentHotelSearchesWithoutDatesResultItemHotelSearchParams
  /** Unique hotel search identifier. */
  id: Scalars['Float']
}

/** Provides hotel saerch params. */
export type RecentHotelSearchesWithoutDatesResultItemHotelSearchParams = {
  /** Hotel search location information. */
  location: RecentHotelSearchesResultLocation
  /** Hotel search radius in miles. */
  searchRadius: Scalars['Float']
}

/** Union of different response objects that can be returned by recentHotelSearchesWithoutDates query. Returns known/expected errors in a structured manner to the client. */
export type RecentHotelSearchesWithoutDatesResultUnion = RecentHotelSearchesWithoutDatesResult

/** Defines fields required to query recent rail searches. */
export type RecentRailSearchesInput = {
  /** Start date of returning results. Format YYYY-MM-DD in local TZ. */
  startDate: Scalars['String']
}

/** Provides recent rail search location information. */
export type RecentRailSearchesLocation = {
  /** Location coordinates. */
  geocode?: Maybe<Geocode>
  /** IATA 3 letter airport code. */
  locationCode?: Maybe<Scalars['String']>
  /** Location name. */
  name: Scalars['String']
}

/** Rail search rail type. */
export enum RecentRailSearchesRailType {
  /** One way rail trip. */
  OneWay = 'OneWay',
  /** Open return rail trip. */
  OpenReturn = 'OpenReturn',
  /** Round rail trip. */
  RoundTrip = 'RoundTrip',
  /** Unspecified rail trip. */
  Unspecified = 'Unspecified',
}

/** RecentRailSearchesResult returns when the recentRailSearches query has been executed. */
export type RecentRailSearchesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent rail searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent rail search items. */
  items: Array<RecentRailSearchesResultItem>
}

/** Provides unique rail search. */
export type RecentRailSearchesResultItem = {
  /** Unique rail search identifier. */
  id: Scalars['Float']
  railSearchParams: RecentRailSearchesResultItemRailSearchParams
}

/** Provides information on the rail card used in a rail search. */
export type RecentRailSearchesResultItemRailSearchCard = {
  /** Rail card code. */
  code: Scalars['String']
  /** Rail card group name. */
  groupName: Scalars['String']
  /** Rail card name. */
  name: Scalars['String']
  /** Rail card vendor code. */
  vendorCode: Scalars['String']
  /** Rail card vendor name. */
  vendorName: Scalars['String']
}

/** Provides rail search params. */
export type RecentRailSearchesResultItemRailSearchParams = {
  /** Rail search rail cards. */
  railCards: Array<RecentRailSearchesResultItemRailSearchCard>
  /** Rail search rail type. */
  railType: RecentRailSearchesRailType
  /** Rail search segments. */
  segments: Array<RecentRailSearchesResultItemRailSearchSegment>
}

/** Provides rail search segment information. */
export type RecentRailSearchesResultItemRailSearchSegment = {
  /** Rail search departure date. Format YYYY-MM-DD in local TZ. */
  departureDate: Scalars['String']
  /** Rail search destination(arrival) location information. */
  destination: RecentRailSearchesLocation
  /** Rail search origin(departure) location information. */
  origin: RecentRailSearchesLocation
  /** Rail search time range filter. */
  searchTimeRange: RecentSearchesResultSearchTimeRange
}

/** Union of different response objects that can be returned by recentRailSearches query. Returns known/expected errors in a structured manner to the client. */
export type RecentRailSearchesResultUnion = RecentRailSearchesResult

/** RecentRailSearchesResultWithoutDates returns when the recentRailSearchesWithoutDates query has been executed. */
export type RecentRailSearchesWithoutDatesResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Whether or not the recent rail searches successfully returned results. */
  isOk: Scalars['Boolean']
  /** Recent rail search items. */
  items: Array<RecentRailSearchesWithoutDatesResultItem>
}

/** Provides unique rail search. */
export type RecentRailSearchesWithoutDatesResultItem = {
  /** Unique rail search identifier. */
  id: Scalars['Float']
  railSearchParams: RecentRailSearchesWithoutDatesResultItemRailSearchParams
}

/** Provides rail search params. */
export type RecentRailSearchesWithoutDatesResultItemRailSearchParams = {
  /** Rail search rail cards. */
  railCards: Array<RecentRailSearchesResultItemRailSearchCard>
  /** Rail search rail type. */
  railType: RecentRailSearchesRailType
  /** Rail search segments. */
  segments: Array<RecentRailSearchesWithoutDatesResultItemRailSearchSegment>
}

/** Provides rail search segment information. */
export type RecentRailSearchesWithoutDatesResultItemRailSearchSegment = {
  /** Rail search destination(arrival) location information. */
  destination: RecentRailSearchesLocation
  /** Rail search origin(departure) location information. */
  origin: RecentRailSearchesLocation
  /** Rail search time range filter. */
  searchTimeRange: RecentSearchesResultSearchTimeRange
}

/** Union of different response objects that can be returned by recentRailSearchesWithoutDates query. Returns known/expected errors in a structured manner to the client. */
export type RecentRailSearchesWithoutDatesResultUnion = RecentRailSearchesWithoutDatesResult

/** Cabin class parameters used in a flight search. */
export enum RecentSearchesFlightSearchParamsCabinClass {
  /** Business cabin class. */
  Business = 'Business',
  /** Coach cabin class. */
  Coach = 'Coach',
  /** First cabin class. */
  First = 'First',
  /** Premium cabin class. */
  Premium = 'Premium',
}

/** Flight type parameters used in a flight search. */
export enum RecentSearchesFlightSearchParamsFlightType {
  /** Multi destination flight. */
  MultiDestination = 'MultiDestination',
  /** One way flight. */
  OneWay = 'OneWay',
  /** Round trip flight. */
  RoundTrip = 'RoundTrip',
}

/** Provides recent search location information. */
export type RecentSearchesLocation = {
  /** IATA 3 letter airport code. */
  airportCode?: Maybe<Scalars['String']>
  /** 2 letter country code. Example: "US". */
  countryCode?: Maybe<Scalars['String']>
  /** Location coordinates */
  geocode: Geocode
  /** Location name. */
  name: Scalars['String']
}

/** Provides search time range information. */
export type RecentSearchesResultSearchTimeRange = {
  /** Time range custom hours. Format: integer 1-24. */
  customHours?: Maybe<Scalars['Int']>
  /** Time range filter end time. Format hh:mm:ss in local TZ. */
  end: Scalars['String']
  /** Time range filter start time. Format hh:mm:ss in local TZ. */
  start: Scalars['String']
  /** Time range filter type. */
  type: RecentSearchesSearchTimeRangeType
}

/** Time range filter type. */
export enum RecentSearchesSearchTimeRangeType {
  /** Arrival time range. */
  Arrival = 'Arrival',
  /** Departure time range. */
  Departure = 'Departure',
}

/** Contains additional documentation associated with redress number. */
export type RedressNumber = {
  /** Country of issuance for the Redress Number */
  country: Scalars['String']
  /** Unique identifier for the Redress Number */
  number: Scalars['String']
}

/** Contains additional documentation associated with redress number. */
export type RedressNumberUnput = {
  /** Country of issuance for the Redress Number */
  country: Scalars['String']
  /** Unique identifier for the Redress Number, it matches with number */
  id?: InputMaybe<Scalars['String']>
  /** Unique identifier for the Redress Number */
  number: Scalars['String']
}

export type ReferencePoint = {
  address: ReferencePointAddress
  label: Scalars['String']
}

export type ReferencePointAddress = {
  city: Scalars['String']
  countryCode: Scalars['String']
  geocode: Geocode
  postalCode?: Maybe<Scalars['String']>
  stateCode?: Maybe<Scalars['String']>
  street1: Scalars['String']
}

export type ReferencePoints = {
  actualPageSize?: Maybe<Scalars['Int']>
  referencePoints: Array<ReferencePoint>
}

export type RegisterClientForNotificationInput = {
  brand?: InputMaybe<Scalars['String']>
  clientType: NotificationClientType
  clientVersion?: InputMaybe<Scalars['String']>
  locale: Scalars['String']
  messageVersion?: InputMaybe<Scalars['Int']>
  recipientId: Scalars['String']
}

export type RegisterNotificationResponse = {
  clientId: Scalars['ID']
}

export type RegisterPushNotificationClientInput = {
  token: Scalars['String']
}

/** Indicates the state of user registration status */
export enum RegistrationStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

export type RemoveCarRentalInput = {
  carId: Scalars['String']
  itineraryId: Scalars['String']
}

export type RemoveCarServiceInput = {
  carKey: Scalars['String']
  itineraryId: Scalars['String']
}

export type RemoveFlightInput = {
  flightId: Scalars['String']
  itineraryId: Scalars['String']
}

/** Provides the input parameters for the removeFromCart mutation. */
export type RemoveFromCartInput = {
  /** Unique identifier for the cart from which the intent has to be removed. */
  cartId: Scalars['ID']
  /** Unique identifier for the intent that has to be removed. */
  intentId: Scalars['ID']
}

/** RemoveFromCartResponse is returned when the removeFromCart mutation succeeds. */
export type RemoveFromCartResponse = {
  /** Code indicating the status of the operation. 200 indicates success. */
  code: Scalars['String']
  /** Used to return a message to the client regarding the status of the operation. */
  message: Scalars['String']
}

/** Union of different response objects that can be returned by removeFromCart mutation. Returns known/expected errors in a structured manner to the client. */
export type RemoveFromCartResponseUnion =
  | CartNotFoundResponse
  | RemoveFromCartResponse
  | UnexpectedError

/** Provides the input parameters for the removeNationalIdCard mutation. */
export type RemoveNationalIdCardInput = {
  /** Contains the server-provisioned identifier for a national id card */
  id: Scalars['Float']
}

export type RemoveNationalIdCardResponse = BaseResponse | UnexpectedError

export type RemovePassportInput = {
  id: Scalars['Float']
}

export type RemoveRailInput = {
  itineraryId: Scalars['String']
  railId: Scalars['String']
}

export type RemoveSeatAssignmentInput = {
  itineraryId: Scalars['String']
  seatNumber: Scalars['String']
  segmentId: Scalars['String']
  travelerId: Scalars['String']
}

/** Provides the input parameters for the removeVisa mutation. */
export type RemoveVisaInput = {
  /** Contains the server-provisioned identifier for a visa */
  id: Scalars['Float']
}

export type RemoveVisaResponse = BaseResponse | UnexpectedError

export type ReplaceCarRentalInput = {
  itineraryId: Scalars['String']
  newKey: Scalars['String']
  oldKey: Scalars['String']
}

export type ReplaceFlightInput = {
  addSeatAssignment?: InputMaybe<Array<AddFlightSeatAssignmentInput>>
  itineraryId: Scalars['String']
  newKey: Scalars['String']
  oldKey: Scalars['String']
}

/** Provides the input parameters for the replaceIntent mutation. */
export type ReplaceIntentInput = {
  /** Additional parameters for Air travel intents. */
  airParameters?: InputMaybe<AirParametersInput>
  /** Unique identifier for the cart. */
  cartId: Scalars['String']
  /** Indicates the domain of the reservation. */
  domain: Domain
  /** Unique identifier for the intent. */
  intentId: Scalars['String']
  /** Unique identifier for the inventory requested in a specific domain. Eg: hotel room, car rental vehicle, etc. */
  inventoryId: Scalars['ID']
  /** Unique identifier for the existing reservation. */
  reservationId?: InputMaybe<Scalars['ID']>
  /** Unique identifier for the search created in the domain to which the intent belongs. */
  searchId: Scalars['ID']
  /** Provide type of the intent. */
  type: IntentType
}

/** ReplaceIntentResponse is returned when the replaceIntent mutation succeeds. */
export type ReplaceIntentResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Updated intent id. */
  intentId: Scalars['String']
}

/** Union of different response objects that can be returned by replaceIntent mutation. Returns known/expected errors in a structured manner to the client. */
export type ReplaceIntentResponseUnion =
  | CartNotFoundResponse
  | GenericCartErrorResponse
  | ReplaceIntentResponse

export enum ReservationDomainType {
  DomainAir = 'DOMAIN_AIR',
  DomainCarRental = 'DOMAIN_CAR_RENTAL',
  DomainHotel = 'DOMAIN_HOTEL',
  DomainRail = 'DOMAIN_RAIL',
  DomainUnspecified = 'DOMAIN_UNSPECIFIED',
}

export enum ReservationStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Unspecified = 'UNSPECIFIED',
}

export type ReservationUnion = CarRentalReservation | HotelReservation

/** Provides a list of details about reserved hotel rooms. */
export type ReservedHotelRoomDetails = {
  /** Represents a reservation confirmation number. */
  confirmationNumber: Scalars['String']
  /** Room information. */
  hotelRoom: HotelRoom
  /** Indicates the status of reserved segment */
  providerSegmentStatus: Scalars['String']
}

export type ResetPasswordError = {
  code: Scalars['String']
  message: Scalars['String']
}

export type ResetPasswordErrorResponse = {
  securityQuestions: Array<SecurityQuestion>
}

export type ResetPasswordErrorResponseUnion =
  | ResetPasswordError
  | ResetPasswordErrorResponse
  | UnexpectedError

export type ResetPasswordInput = {
  identifier: Scalars['String']
  securityAnswers?: InputMaybe<Array<InputMaybe<SecurityAnswer>>>
  siteId?: InputMaybe<Scalars['String']>
}

export type ResetPasswordResponse = {
  questions: Array<SecurityQuestion>
}

/** Information of booked ride hail */
export type RideHailBooking = {
  /** Identifier of the Ride Hail Booking */
  bookingId: Scalars['String']
  /** Booking type (Reserve, On-demand) */
  bookingType?: Maybe<RideHailBookingType>
  /** Description contains short summary of the ride hail, e.g. UberX to San Francisco International Airport, San Francisco, CA */
  description?: Maybe<Scalars['String']>
  /** Drop-off address */
  dropOffAddress?: Maybe<Scalars['String']>
  /** Estimated price range before trip completion */
  estimatedPriceRange?: Maybe<RateRange>
  /** Pick-up address */
  pickUpAddress?: Maybe<Scalars['String']>
  /** Pick-up date time */
  pickUpTime?: Maybe<Scalars['String']>
  /** Price for the ride. As Uber provides only an estimated price before the completion of the ride, this value is subject to change during the course of the ride. The final price is provided after completion of the ride. */
  price?: Maybe<Rate>
  /** Product Name (Uber, Uber X, etc.) */
  productName?: Maybe<Scalars['String']>
  /** Status (Completed, Canceled, etc.) */
  status?: Maybe<TripStatus>
}

export enum RideHailBookingType {
  OnDemand = 'ON_DEMAND',
  Reserve = 'RESERVE',
  Unspecified = 'UNSPECIFIED',
}

/** The ride hail booking to be canceled */
export type RideHailCancelableBooking = {
  /** The booking ID for this ride hail booking */
  bookingID: Scalars['String']
  /** Provider name, e.g. Uber, Lyft, etc */
  provider?: InputMaybe<RideHailProvider>
}

/** RideHailExpenseCode provides audit information around the reasoning and categorisation for the trip's cost as a business expense */
export type RideHailExpenseCode = {
  /** Human readable description of the expense code */
  description?: Maybe<Scalars['String']>
  /** Unique identifier of the expense code */
  id: Scalars['ID']
}

/** Defines error reasons of getting ride hail expense configuration */
export enum RideHailExpenseConfigErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** RideHailExpenseConfigErrorResponse is returned from ride hail get expense code request with a well-defined reason */
export type RideHailExpenseConfigErrorResponse = {
  /** Domain-specific error reason */
  reason: RideHailExpenseConfigErrorReason
}

export type RideHailExpenseConfigInput = {
  /** Drop-off location */
  dropOffLocation: RideHailLocation
  /** User's desired pick-up date. If not provided, ride will be assumed to be on-demand */
  pickUpDate?: InputMaybe<Scalars['Date']>
  /** Pick-up location */
  pickUpLocation: RideHailLocation
  /** User's desired pick-up time. If not provided, ride will be assumed to be on-demand */
  pickUpTime?: InputMaybe<Scalars['TimeOfDay']>
  /** Process ID of the associated travel transaction (from legacy travel/Gazoo) */
  processID?: InputMaybe<Scalars['ID']>
  /** Provider name, e.g. Uber, Lyft, etc */
  provider: RideHailProvider
  /** The unique ID of the produce being requested which will be determined by provider name at the RHSS */
  searchResultID: Scalars['ID']
}

export type RideHailExpenseConfigResponse = {
  /** Indicates whether the user may submit a custom expense code. */
  customExpenseEnabled: Scalars['Boolean']
  /** List of selectable expense codes (can be empty). */
  expenseCodes: Array<RideHailExpenseCode>
  /** Indicates whether expense information must be submitted with the booking. Expense information must include an expense code and may include a memo. */
  expenseEnabled: Scalars['Boolean']
  /** Indicates whether the user may submit a memo. A memo is free-form text that provides context about the expense code selected. */
  memoEnabled: Scalars['Boolean']
  /** Indicates whether the user must submit a memo. If memoEnabled is false this value is guaranteed to be false. */
  memoRequired: Scalars['Boolean']
}

/** RideHailExpenseConfigResponseUnion is either the expense config for the given request parameters or an error */
export type RideHailExpenseConfigResponseUnion =
  | RideHailExpenseConfigErrorResponse
  | RideHailExpenseConfigResponse

export type RideHailFlightDetails = {
  /** The airline name. */
  airlineName?: InputMaybe<Scalars['String']>
  /** IATA 3 letter airport code. */
  airportCode?: InputMaybe<Scalars['String']>
  /** The flight arrival date. */
  arrivalDate?: InputMaybe<Scalars['Date']>
  /** The flight arrival time. */
  arrivalTime?: InputMaybe<Scalars['TimeOfDay']>
  /** The flight designator, e.g. UA-1655 */
  designatorCode?: InputMaybe<Scalars['String']>
}

export type RideHailLocation = {
  address?: InputMaybe<Scalars['String']>
  geocode?: InputMaybe<GeocodeInput>
  place?: InputMaybe<RideHailPlace>
}

export enum RideHailLocationRefinementType {
  DropOff = 'DROP_OFF',
  PickUp = 'PICK_UP',
  Unspecified = 'UNSPECIFIED',
}

export type RideHailPlace = {
  /** Place ID for PUDO - give a specify Pickup & Drop Off point */
  placeId: Scalars['String']
  /** Place provider necessary for PUDO. */
  provider: RideHailPlaceProvider
  /** Zone ID for PUDO - give a specific zone of root zone or subzone */
  zoneId?: InputMaybe<Scalars['String']>
}

/** Place provider use for ePUDO, e.g. "uber_places" or "google_places". */
export enum RideHailPlaceProvider {
  GooglePlaces = 'GOOGLE_PLACES',
  UberPlaces = 'UBER_PLACES',
  Unspecified = 'UNSPECIFIED',
}

export enum RideHailProvider {
  Uber = 'UBER',
  Unspecified = 'UNSPECIFIED',
}

/** RideHailSegmentType defines segments originating from legacy travel/Gazoo */
export enum RideHailSegmentType {
  CarRental = 'CAR_RENTAL',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  Unspecified = 'UNSPECIFIED',
}

/** Suggested locations in the trip */
export type RideHailSuggestedLocation = {
  /** Three-character IATA airport code. For example, "SFO" */
  airportCode?: Maybe<Scalars['String']>
  /** ISO 3166-1 two-character country code, e.g. "US" */
  countryCode?: Maybe<Scalars['String']>
  /** Suggested location containing full address and latitude:longitude. */
  location: RideHailSuggestionLocation
  /** Suggested location name */
  name: Scalars['String']
  /** Suggested location type. For example, HOTEL, AIRPORT. */
  suggestedLocationType: RideHailSuggestedLocationType
}

/** Defines suggested location type of user's place in a trip. For example, HOTEL, AIRPORT. */
export enum RideHailSuggestedLocationType {
  Airport = 'AIRPORT',
  Home = 'HOME',
  Hotel = 'HOTEL',
  Unspecified = 'UNSPECIFIED',
}

/** RideHailSuggestedLocationsError is returned from get suggested locations request with a well-defined reason */
export type RideHailSuggestedLocationsError = {
  /** Error reason */
  reason: RideHailSuggestedLocationsErrorReason
}

/** Defines error reasons of get suggested locations failures */
export enum RideHailSuggestedLocationsErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** Provides the input parameters for the process ID of the associated travel transaction. */
export type RideHailSuggestedLocationsRequest = {
  /** Process ID of the associated travel transaction (from legacy travel/Gazoo) */
  processId: Scalars['ID']
}

/** RideHailSuggestedLocationsResponse is returned when the rideHailSuggestedLocations query succeeds. */
export type RideHailSuggestedLocationsResponse = {
  /** Provides the list of suggested locations */
  rideHailSuggestedLocations: Array<RideHailSuggestedLocation>
}

/** RideHailSuggestedLocationsResponseUnion is either the success or failure get suggested locations response */
export type RideHailSuggestedLocationsResponseUnion =
  | RideHailSuggestedLocationsError
  | RideHailSuggestedLocationsResponse

/** Suggested ride hails provided for those travelers with an upcoming trip */
export type RideHailSuggestion = {
  /** If this is false, user must be redirected to the providers own application/UI to book */
  canBeBookedWithinEtta: Scalars['Boolean']
  /** Predicted drop-off date */
  dropOffDate?: Maybe<Scalars['Date']>
  /** Drop-off location */
  dropOffLocation: RideHailSuggestionLocation
  /** Drop-off location type */
  dropOffLocationType: RideHailSuggestionLocationType
  /** Drop-off name to display to user, e.g. "Home", "SFO", "160 Spear Street" */
  dropOffName: Scalars['String']
  /** Associated drop-off segment ID (from TVL_* table primary keys in legacy travel/Gazoo) */
  dropOffSegmentId?: Maybe<Scalars['ID']>
  /** The start date of the segment before which the ride hail is being suggested */
  dropOffSegmentStartDate?: Maybe<Scalars['Date']>
  /** The start time of the segment before which the ride hail is being suggested */
  dropOffSegmentStartTime?: Maybe<Scalars['TimeOfDay']>
  /** UTC offset in seconds of the drop-off segment start date/time. It has value when the drop-off segment start date/time are returned. Must be whole seconds, between -18 hours and +18 hours, e.g. a UTC offset of -4:00 would be represented as -14400 */
  dropOffSegmentStartTimeZoneUtcOffset?: Maybe<Scalars['Int']>
  /** Associated drop-off segment type */
  dropOffSegmentType: RideHailSuggestionAssociatedSegmentType
  /** Predicted drop-off time */
  dropOffTime?: Maybe<Scalars['TimeOfDay']>
  /** UTC offset in seconds of the drop-off date/time. It has value when drop-off date/time are returned. Must be whole seconds, between -18 hours and +18 hours, e.g. a UTC offset of -4:00 would be represented as -14400 */
  dropOffTimeZoneUtcOffset?: Maybe<Scalars['Int']>
  /** Pick-up date */
  pickUpDate: Scalars['Date']
  /** Pick-up location */
  pickUpLocation: RideHailSuggestionLocation
  /** Pick-up location type */
  pickUpLocationType: RideHailSuggestionLocationType
  /** Pick-up name to display to user, e.g. "Home", "SFO", "160 Spear Street" */
  pickUpName: Scalars['String']
  /** The end date of the segment for which, upon completion, a ride hail is being suggested */
  pickUpSegmentEndDate?: Maybe<Scalars['Date']>
  /** The end time of the segment for which, upon completion, a ride hail is being suggested */
  pickUpSegmentEndTime?: Maybe<Scalars['TimeOfDay']>
  /** UTC offset in seconds of the pick-up segment end date/time. It has value when pick-up segment end date/time are returned. Must be whole seconds, between -18 hours and +18 hours, e.g. a UTC offset of -4:00 would be represented as -14400 */
  pickUpSegmentEndTimeZoneUtcOffset?: Maybe<Scalars['Int']>
  /** Associated pick-up segment ID (from TVL_* table primary keys in legacy travel/Gazoo) */
  pickUpSegmentId?: Maybe<Scalars['ID']>
  /** Associated pick-up segment type */
  pickUpSegmentType: RideHailSuggestionAssociatedSegmentType
  /** Pick-up time, will not be provided if traveller needs to select the time manually */
  pickUpTime?: Maybe<Scalars['TimeOfDay']>
  /** UTC offset in seconds of the pick-up date/time. Must be whole seconds, between -18 hours and +18 hours, e.g. a UTC offset of -4:00 would be represented as -14400 */
  pickUpTimeZoneUtcOffset: Scalars['Int']
  /** Process ID of the associated travel transaction (from legacy travel/Gazoo) */
  processId?: Maybe<Scalars['ID']>
}

export enum RideHailSuggestionAssociatedSegmentType {
  CarRental = 'CAR_RENTAL',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  Unspecified = 'UNSPECIFIED',
}

export type RideHailSuggestionLocation = {
  /** Full street address, e.g. 3332 Clover Drive, Kent, Oregon, US 97033 */
  address?: Maybe<Scalars['String']>
  /** Geographical location (latitude/longitude) */
  geocode?: Maybe<Geocode>
}

/** Defines the ride hail suggestions location type. */
export enum RideHailSuggestionLocationType {
  /** An airport. */
  Airport = 'AIRPORT',
  /** The home address of the traveler. */
  Home = 'HOME',
  /** A hotel. */
  Hotel = 'HOTEL',
  /** An unspecified location. */
  Unspecified = 'UNSPECIFIED',
}

/** RideHailSuggestionsResponse is returned when the rideHailSuggestions query succeeds. */
export type RideHailSuggestionsResponse = {
  /** Provides the list of ride hail suggestions */
  rideHailSuggestions: Array<RideHailSuggestion>
}

/** Details of a single flight provided in the chronological list of upcoming flights. */
export type RideHailUpcomingFlight = {
  /** The airline image URL */
  airlineImage: Scalars['String']
  /** The airline name */
  airlineName: Scalars['String']
  /** The arrival airport code, e.g. LAX, SFO */
  airportCode: Scalars['String']
  /** The arrival date of flight */
  arrivalDate?: Maybe<Scalars['Date']>
  /** The arrival time of flight */
  arrivalTime?: Maybe<Scalars['TimeOfDay']>
  /** The flight designator, e.g. UA-1655 */
  designatorCode: Scalars['String']
}

/** Defines reasons for failure to retrieve a list of upcoming flights. */
export enum RideHailUpcomingFlightsErrorReason {
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** RideHailUpcomingFlightsErrorResponse is returned from get upcoming flights request with a well-defined reason */
export type RideHailUpcomingFlightsErrorResponse = {
  /** Error reason */
  reason: RideHailUpcomingFlightsErrorReason
}

/** RideHailUpcomingFlightsResponse is returned when the rideHailUpcomingFlights query succeeds. */
export type RideHailUpcomingFlightsResponse = {
  /** Provides the list of upcoming flights in support of a ride hail. */
  rideHailUpcomingFlights: Array<RideHailUpcomingFlight>
}

/** RideHailUpcomingFlightsResponseUnion is either the success or failure get upcoming flights response */
export type RideHailUpcomingFlightsResponseUnion =
  | RideHailUpcomingFlightsErrorResponse
  | RideHailUpcomingFlightsResponse
  | UnexpectedError

/** The set of ACL roles that have been assigned to the user */
export type Role = {
  /** Server-provisioned ID, can be used to operate on a single role directly */
  id?: Maybe<Scalars['Int']>
  /** A string-based name that can be interpreted and used for role based authorization on an per-app basis. This property can be any string */
  name: Scalars['String']
}

/** Role name of the authorization */
export type RoleName = {
  name: Scalars['String']
}

/** Hotel room information */
export type Room = {
  /** Room average rate. */
  averageRate: Rate
  /** Nightly room rate, before any taxes or fees are applied, in the local currency associated with the hotel's location. */
  baseRate?: Maybe<Rate>
  /** Cancellation policy status. */
  cancellationPolicy: CancellationPolicy
  /** Hotel cancellation policy information. */
  cancellationPolicyDescription?: Maybe<Array<Scalars['String']>>
  /** Room daily rate. */
  dailyRates: Array<DailyRate>
  /** Room description. */
  description: Scalars['String']
  /** Total tax amount for the period of stay in the hotel's local currency. */
  feesAndTaxes: Money
  /** Total tax amount for the period of stay. */
  feesAndTaxesRate?: Maybe<Rate>
  /** Unique room key representing room and rate. */
  id: Scalars['ID']
  /** Required for modernized flows using HSS and ShoppingCartService. Unique ID to identify a specific room associated with a hotel. */
  inventoryId?: Maybe<Scalars['String']>
  isCorporateRate: Scalars['Boolean']
  isFreeCancellable: Scalars['Boolean']
  isSelectable?: Maybe<Scalars['Boolean']>
  /** Number of guests staying in each room. */
  numberOfGuests?: Maybe<Scalars['Int']>
  /** Number of rooms associated with the rate. */
  numberOfRooms?: Maybe<Scalars['Int']>
  policy: Policy
  preferredTag?: Maybe<Scalars['String']>
  /** Nightly room rate, in the local currency associated with the hotel's location. */
  rate?: Maybe<Rate>
  rateQualifier?: Maybe<RateQualifier>
  source?: Maybe<Scalars['String']>
  /** Total room cost for the period of stay in the hotel's local currency. */
  totalCost: Money
  /** Total room cost for the period of stay. */
  totalCostRate?: Maybe<Rate>
  /** Room type. */
  type: RoomType
}

/** Response from "roomDetails" request */
export type RoomDetails = {
  id: Scalars['ID']
  rateDetails: Scalars['String']
}

export type RoomRate = {
  /** Estimated total amount, inclusive of taxes and surcharges. */
  costTotal: Scalars['String']
  /** Room rate description. */
  description: Scalars['String']
  /** Total amount of taxes. */
  taxTotal: Scalars['String']
}

export enum RoomType {
  Double = 'Double',
  King = 'King',
  Other = 'Other',
  Queen = 'Queen',
  Suite = 'Suite',
  Superior = 'Superior',
  Twin = 'Twin',
  Unknown = 'Unknown',
}

export enum RouteType {
  OpenReturn = 'OPEN_RETURN',
  Return = 'RETURN',
  Single = 'SINGLE',
  Unspecified = 'UNSPECIFIED',
}

export enum SsoVariant {
  Both = 'BOTH',
  Sso = 'SSO',
  Standard = 'STANDARD',
}

/** Represents trip actions that can be performed against to the search/trip planner page. */
export type SearchBehaviors = {
  /** Lists of intents are available from the Dashboard console to be displayed in the cart during a search. */
  allowableDomains: Array<Domain>
  /** Type of flight fare to highlight during a Price Search. For business logic on Price Search, see https://deem.atlassian.net/wiki/spaces/PM/pages/1618182791/Schedule+Search+vs.+Price+Search. */
  defaultFlightFareType: FlightFareType
  /** Identifies the selected string value for default flight sort(like Price/Duration/Policy) */
  defaultFlightSort: Scalars['String']
  /** List of cabin classes displayed in the cart during a flight search. */
  flightCabins: Array<FlightCabinInfo>
  /** This allows "Morning, Afternoon, or Evening" to be displayed in the list of times that may be selected when shopping for flights. */
  isAllowFullDayFlightSearch: Scalars['Boolean']
  /** Display nearby airports by default in search page. */
  isIncludeNearbyAirports: Scalars['Boolean']
}

export type SearchFlightLeg = FlightLegCommon & {
  arrivalDate: Scalars['DateTime']
  connectionInfo: Array<FlightLegConnectionInfo>
  daysInBetween: Scalars['Int']
  departureDate: Scalars['DateTime']
  destination: Scalars['AirportCode']
  distance?: Maybe<Distance>
  fares: Array<FlightFare>
  /** @deprecated Use stops instead. */
  flightStops: Scalars['Int']
  flightTime: TravelTime
  /** Identifies whether the flight has a technical stop. */
  hasTechnicalStop: Scalars['Boolean']
  isSelectable: Scalars['Boolean']
  isUnusedTicketApplicable: Scalars['Boolean']
  legId: Scalars['String']
  origin: Scalars['AirportCode']
  policy: Policy
  rate?: Maybe<Rate>
  segments: Array<SearchFlightLegSubSegment>
  stops: Scalars['Int']
  totalTime: TravelTime
}

export type SearchFlightLegSubSegment = FlightLegSubSegmentCommon & {
  aircraft?: Maybe<FlightLegSegmentAircraft>
  amenities?: Maybe<Array<FlightAmenity>>
  arrivalDate: Scalars['DateTime']
  carrier?: Maybe<FlightCarrier>
  codeshareCarrier: CodeshareCarrier
  departureDate: Scalars['DateTime']
  destination: FlightLegSubSegmentAirport
  destinationTerminal?: Maybe<Scalars['String']>
  flightNumber: Scalars['Int']
  flightTime: TravelTime
  id: Scalars['ID']
  isDepartAirportDifferentFromConnecting?: Maybe<Scalars['Boolean']>
  isInstantPurchaseCarrier?: Maybe<Scalars['Boolean']>
  isPreferredAny: Scalars['Boolean']
  isSeatMapAvailable: Scalars['Boolean']
  isSeatSelectable: Scalars['Boolean']
  origin: FlightLegSubSegmentAirport
  originTerminal?: Maybe<Scalars['String']>
  percentageOnTime?: Maybe<Scalars['Int']>
  preference?: Maybe<Preference>
  preferredBadgeText?: Maybe<Scalars['String']>
}

export type SearchInitiateInfoInput = {
  deviceClass?: InputMaybe<DeviceClass>
}

export type SearchInitiateInfoResult = {
  allowAirSearch: Scalars['Boolean']
  allowAnytimeSearch?: Maybe<GazooAnytimeSearch>
  allowBusinessClassAirSearch: Scalars['Boolean']
  allowCarRentalSearch: Scalars['Boolean']
  allowEconomyClassAirSearch: Scalars['Boolean']
  allowFirstClassAirSearch: Scalars['Boolean']
  allowHotelSearch: Scalars['Boolean']
  allowMorningAfternoonEveningFlightSearch: Scalars['Boolean']
  allowPremiumEconomyClassAirSearch: Scalars['Boolean']
  allowRefundableFares: Scalars['Boolean']
  allowTrainSearch: Scalars['Boolean']
  allowUnrestrictedFares: Scalars['Boolean']
  customFieldsConfiguration?: Maybe<CustomFieldConfiguration>
  customLabelBusinessClass: Scalars['String']
  customLabelCoachClass: Scalars['String']
  customLabelFirstClass: Scalars['String']
  customLabelPremiumCoachClass: Scalars['String']
  defaultAirFareType?: Maybe<FlightFareType>
  defaultFlightSort?: Maybe<FlightSort>
  deliveryAndCollectionSupported: Scalars['Boolean']
  dynamicSiteMessage?: Maybe<GazooDynamicSiteMessage>
  includeNearbyAirports: Scalars['Boolean']
}

export type SearchMessage = {
  label?: Maybe<Scalars['String']>
  message: Scalars['String']
}

export type SearchRailLeg = RailLegCommon & {
  arrivalDate: Scalars['DateTime']
  connectionInfo: Array<RailLegConnectionInfo>
  daysInBetween: Scalars['Int']
  departureDate: Scalars['DateTime']
  destination: Scalars['String']
  destinationCode: Scalars['String']
  distance?: Maybe<Distance>
  ecoInfo?: Maybe<Scalars['String']>
  fares: Array<RailFare>
  isSelectable: Scalars['Boolean']
  isUnusedTicketApplicable: Scalars['Boolean']
  /** A list of labels applicable for the leg. For example, "fastest", "cheapest" etc. */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>
  legId: Scalars['String']
  origin: Scalars['String']
  originCode: Scalars['String']
  platform?: Maybe<Scalars['String']>
  policy: Policy
  railStops: Scalars['Int']
  railTime: TravelTime
  rate?: Maybe<Rate>
  segments: Array<SearchRailLegSubSegment>
  totalTime: TravelTime
}

export type SearchRailLegSubSegment = RailLegSubSegmentCommon & {
  amenities?: Maybe<Array<RailAmenity>>
  arrivalDate: Scalars['DateTime']
  carrier?: Maybe<RailCarrier>
  changeDuration?: Maybe<TravelTime>
  codeshareCarrier: CodeshareCarrier
  departureDate: Scalars['DateTime']
  destination: RailLegSubSegmentStation
  id: Scalars['ID']
  isPreferredAny: Scalars['Boolean']
  isSeatMapAvailable: Scalars['Boolean']
  isSeatSelectable: Scalars['Boolean']
  origin: RailLegSubSegmentStation
  percentageOnTime?: Maybe<Scalars['Int']>
  preference?: Maybe<Preference>
  preferredBadgeText?: Maybe<Scalars['String']>
  railTime: TravelTime
  stops?: Maybe<Array<TrainStop>>
  train?: Maybe<RailLegSegmentTrain>
  /** trainId: Identifier of the train from train provider. */
  trainId?: Maybe<Scalars['String']>
  /** @deprecated Use trainId instead. */
  trainIdentifier: Scalars['String']
  trainNumber: Scalars['Int']
  /** transportationMode: represents the transportationMode between the rail legs. */
  transportationMode?: Maybe<RailTransportationMode>
}

/** Defines error reasons of search ride hail failures. */
export enum SearchRideHailErrorReason {
  /** Airport Pick-up is not available for this pick-up location. */
  AirportPickupNotAvailable = 'AIRPORT_PICKUP_NOT_AVAILABLE',
  /** The arrival flight number provided for this mobility booking is not recognized. */
  ArrivalFlightNotFound = 'ARRIVAL_FLIGHT_NOT_FOUND',
  /** The drop-off location is too far from the pickup location. */
  MaxDistanceExceeded = 'MAX_DISTANCE_EXCEEDED',
  /** Payment Method is not been configured for this user. */
  PaymentMethodNotConfigured = 'PAYMENT_METHOD_NOT_CONFIGURED',
  /** An Unknown Error has occurred. */
  Unknown = 'UNKNOWN',
}

/** SearchRideHailErrorResponse is returned from search ride hail request with a well-defined reason */
export type SearchRideHailErrorResponse = {
  /** Domain-specific error reason */
  reason: SearchRideHailErrorReason
}

/** Provides the input parameters to search ride hails */
export type SearchRideHailRequest = {
  /** Info of user in an arrival flight, if provided it will be used to support the ride hail */
  arrivalFlightDetails?: InputMaybe<RideHailFlightDetails>
  /** Desired drop-off point for ride hail */
  dropOff: RideHailLocation
  /** Desired pick-up point for ride hail */
  pickUp: RideHailLocation
  /** User's desired pick-up date. If not provided, ride will be assumed to be on-demand */
  pickUpDate?: InputMaybe<Scalars['Date']>
  /** User's desired pick-up time. The time will be assumed to be within the timezone of the pick-up location. If not provided, ride will be assumed to be on-demand */
  pickUpTime?: InputMaybe<Scalars['TimeOfDay']>
  /** Trip itinerary associated with this search */
  processId?: InputMaybe<Scalars['ID']>
}

export type SearchRideHailResponse = {
  /** List of recommended ride option results. If provided, these are options which the system has determined have the best fit with the traveller's preferences as compared to the options provided in the results field */
  recommendedResults: Array<SearchRideHailResult>
  /** List of ride option results */
  results: Array<SearchRideHailResult>
}

/** SearchRideHailResponseUnion is either the success or failure search ride hail response */
export type SearchRideHailResponseUnion = SearchRideHailErrorResponse | SearchRideHailResponse

export type SearchRideHailResult = {
  /** Human-readable format for the display of the ride option, e.g. Select, Comfort, Uber X */
  displayName: Scalars['String']
  /** Estimated price. Can be null when estimates are temporarily unavailable */
  estimatedPrice?: Maybe<Money>
  /** Estimated duration (in seconds) between pick-up and drop-off. Can be null if price is not available */
  estimatedRideDuration?: Maybe<Scalars['Int']>
  /** Estimated waiting time of the ride option in seconds */
  estimatedWaitingTime?: Maybe<Scalars['Int']>
  id: Scalars['String']
  /** Image URL for the ride option */
  imageUrl?: Maybe<Scalars['String']>
  /** Number of passengers */
  passengerCapacity?: Maybe<Scalars['Int']>
  /** Ride hail provider, for example, Uber */
  provider: RideHailProvider
  /** Short description of the ride option, e.g. Newer cars with extra legroom */
  shortDescription: Scalars['String']
}

export type SearchUnusedTickets = {
  max?: Maybe<Rate>
  min?: Maybe<Rate>
  unusedTicket?: Maybe<UnusedTicket>
}

/** The Seat DTO object. This can be an array, depending on the seat information available for this flight. If there is no seat information available, then this would be empty. */
export type Seat = {
  /** Text up to 30-character string (English). E.g.: "Above average legroom (32")" */
  displayText: Scalars['String']
  /** Identifies whether the seat reclines a full 180°, at an angle or doesn't lie flat (premium cabins only) E.g.: angle flat, full flat, not flat, n/a */
  flatness: Scalars['String']
  /** An assessment of the legroom available. E.g: less, more, standard, n/a */
  legRoom: Scalars['String']
  /** The number of inches of seat pitch (distance from any point on one seat to the same point on the seat in front of or behind it, e.g. 32) */
  pitchInch: Scalars['Int']
  /** The seat type available. */
  type: Scalars['String']
  /** An assessment of the seat width (economy only). E.g.: standard, wider, n/a */
  width: Scalars['String']
}

/** Provides the response object for the Seat Attributes query. This contains information about the requested flight segments, and also a unique identifier provided by RouteHappy, the content provider, for traceability purposes. */
export type SeatAttributes = {
  /** Required. The actual response object that contains all the information about the requested seat, for each requested flight segment, i.e. flight. */
  flightSeatInfo: Array<FlightSeatInfo>
  /** Required. Unique identifier for this response, as returned by ATPCO. Helps in identifying, or tracing this response back to ATPCO, if needed. */
  uuid: Scalars['String']
}

/** Defines the status of the carrier of this flight. */
export enum SeatAttributesCarrierStatusEnum {
  NonPrimary = 'NON_PRIMARY',
  Primary = 'PRIMARY',
}

export type SeatAttributesInput = {
  /** Required. Specifies the segment information for this seat attributes request. A request can be for either 1, or more than 1 flight segment that the user is looking at, for the seats. */
  flightSegment: Array<FlightSegmentDto>
  /** The RFC 5646 language identifier specifying both language and location. Examples: "en-US" or "de-DE". This will accept both a "-" or "_" as a separator character. */
  language: Scalars['String']
  /** Identifies the metadata for logging. */
  logsMetaData?: InputMaybe<LogsMetadata>
  /** An optional pseudo city code that can be used to get private rate. */
  pseudoCityCode: Scalars['String']
}

export type SeatMapInput = {
  bookingCode: Scalars['String']
  carrierCode: Scalars['String']
  departureDate: Scalars['Date']
  destination: Scalars['String']
  equipmentCode?: InputMaybe<Scalars['String']>
  flightNumber: Scalars['Int']
  /** True for NDC requests */
  isNdc?: InputMaybe<Scalars['Boolean']>
  logsMetadata?: InputMaybe<LogsMetadata>
  origin: Scalars['String']
  /** Opaque String With Provider Attributes */
  providerAttributesStr?: InputMaybe<Scalars['String']>
}

export type SeatMapResult = {
  carrier: FlightCarrier
  departureDate: Scalars['Date']
  destination: Scalars['AirportCode']
  flightNumber: Scalars['Int']
  origin: Scalars['AirportCode']
  readOnly: Scalars['Boolean']
  rows: Array<SeatMapRow>
}

export type SeatMapRow = {
  cabinClass: SeatmapCabinClass
  number: Scalars['Int']
  seat: Array<SeatMapRowSeat>
}

export type SeatMapRowSeat = {
  available: Scalars['Boolean']
  column: Scalars['String']
  hasBulkhead: Scalars['Boolean']
  hasGalley: Scalars['Boolean']
  hasLavatory: Scalars['Boolean']
  isExitRowSeat: Scalars['Boolean']
  isNearAisle: Scalars['Boolean']
  isNearWindow: Scalars['Boolean']
  isOverWing: Scalars['Boolean']
  isPaid: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  isSmokingAllowed: Scalars['Boolean']
  isSuitableForHandicapped: Scalars['Boolean']
  isUpperDeck: Scalars['Boolean']
  number: Scalars['String']
}

/** Provides the code and the name of each SeatPreference option. */
export type SeatPreference = {
  code: Scalars['String']
  name: Scalars['String']
}

/** Available seat preference options, and seat reservation type, retrieved from the rail provider. */
export type SeatPreferences = {
  carriageTypes: Array<SeatPreference>
  seatDirections: Array<SeatPreference>
  seatFacilities: Array<SeatPreference>
  seatPositions: Array<SeatPreference>
}

/** SeatReservationType: Represents type of seat reservation. */
export enum SeatReservationType {
  Mandatory = 'MANDATORY',
  None = 'NONE',
  Optional = 'OPTIONAL',
}

/** Captures selected seat for a specific passenger and flight segment. */
export type SeatSelectionsInput = {
  /** FlightSegment identifier for this seat selection. */
  flightSegmentId: Scalars['String']
  /** Sequential number of the passenger to whom this seat is assigned, starting with 1 for the primary passenger. */
  passengerNumber: Scalars['Int']
  /** Selected seat number, usually indicating row and column in cabin, like "42G". */
  seatNumber?: InputMaybe<Scalars['String']>
}

export enum SeatmapCabinClass {
  Business = 'BUSINESS',
  Coach = 'COACH',
  First = 'FIRST',
  PremiumCoach = 'PREMIUM_COACH',
}

export type SecurityAnswer = {
  answer: Scalars['String']
  id: Scalars['ID']
}

export type SecurityQuestion = {
  id: Scalars['ID']
  question: Scalars['String']
}

export type Segment = SegmentCommon & {
  carRental?: Maybe<CarRentalSegment>
  carService?: Maybe<CarServiceSegment>
  flightLeg?: Maybe<FlightLegSegment>
  hotel?: Maybe<HotelSegment>
  position: Scalars['Int']
  trainLeg?: Maybe<TrainLegSegment>
  type: SegmentType
  uuid: Scalars['String']
}

export type SegmentChangeActions = {
  carBooking?: Maybe<ChangeCancelRules>
  flightBooking?: Maybe<FlightChangeCancelRules>
  hotelBooking?: Maybe<ChangeCancelRules>
  trainBooking?: Maybe<ChangeCancelRules>
}

export type SegmentCommon = {
  type: SegmentType
  uuid: Scalars['String']
}

export type SegmentInputDto = {
  id: Scalars['String']
}

export type SegmentLocation = {
  airportCode?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  displayAddress?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  locationKey?: Maybe<Scalars['String']>
  locationName?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['Float']>
  stateCode?: Maybe<Scalars['String']>
  streetLevelGeocode?: Maybe<Scalars['Boolean']>
}

export type SegmentPriceChangeDetails = {
  rate?: Maybe<Rate>
  reason?: Maybe<PriceChangeReason>
  type?: Maybe<PriceChangeType>
}

export type SegmentServiceFee = {
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Money>
}

export enum SegmentState {
  Changed = 'CHANGED',
  New = 'NEW',
}

export enum SegmentType {
  CarRental = 'CAR_RENTAL',
  CarService = 'CAR_SERVICE',
  Drive = 'DRIVE',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  /** @deprecated Use RIDE_HAIL instead */
  Mobility = 'MOBILITY',
  PublicTransit = 'PUBLIC_TRANSIT',
  RideHail = 'RIDE_HAIL',
  Train = 'TRAIN',
  Unspecified = 'UNSPECIFIED',
  Walk = 'WALK',
}

export type SendOopCodesInput = {
  carRentalOutOfPolicyComplianceCode?: InputMaybe<Scalars['String']>
  flightOutOfPolicyComplianceCode?: InputMaybe<Scalars['String']>
  hotelOutOfPolicyComplianceCode?: InputMaybe<Scalars['String']>
  itineraryId: Scalars['String']
  trainOutOfPolicyComplianceCode?: InputMaybe<Scalars['String']>
}

export type SendPushNotificationInput = {
  body: Scalars['String']
  imageUrl?: InputMaybe<Scalars['String']>
  title: Scalars['String']
}

export enum ServiceCategory {
  CarService = 'CAR_SERVICE',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  RentalCar = 'RENTAL_CAR',
  RideHail = 'RIDE_HAIL',
  Train = 'TRAIN',
}

/** Class of service aka cabin class. */
export enum ServiceClass {
  Business = 'BUSINESS',
  Economy = 'ECONOMY',
  First = 'FIRST',
  PremiumEconomy = 'PREMIUM_ECONOMY',
  Unspecified = 'UNSPECIFIED',
}

export type ServiceFee = {
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  rate?: Maybe<Money>
}

export type ServiceFeeConfigurationDto = {
  description: Scalars['String']
  id: Scalars['Float']
  label: Scalars['String']
  name: Scalars['String']
  serviceFeesAmount: Scalars['Float']
  serviceFeesAmountCurrency: Scalars['String']
  serviceId: Scalars['Float']
  transactionFeesChangeMode: Scalars['Boolean']
  transactionFeesChangeText: Scalars['String']
  type: Scalars['String']
  vendorCodes: Scalars['String']
  vendorCodesList: Array<Scalars['String']>
  vendorNamesList: Array<Scalars['String']>
}

export type SetCostAllocationInput = {
  /** Unique identifier for the cart. */
  cartId: Scalars['ID']
  /** Cost Allocations to which the trip cost is associated. */
  costAllocations: Array<CostAllocationInput>
}

/** SetCostAllocationResponse is returned when the setCostAllocation mutation succeeds. */
export type SetCostAllocationResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
}

/** Union of different response objects that can be returned by setCostAllocation mutation. Returns known/expected errors in a structured manner to the client. */
export type SetCostAllocationResponseUnion = CartNotFoundResponse | SetCostAllocationResponse

export type SetCustomFieldsInput = {
  /** Unique identifier for the cart. */
  cartId: Scalars['ID']
  /** Custom field values to set. */
  customFields: Array<CustomFieldInput>
  /** Scope in the shopping flow for which custom fields values, are to be set. */
  scope: CustomFieldsScope
}

/** SetCustomFieldsResponse is returned when the setCustomFields mutation succeeds. */
export type SetCustomFieldsResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
  /** Custom fields configuration. */
  customFieldsConfiguration?: Maybe<CustomFieldConfiguration>
}

/** Union of different response objects that can be returned by setCustomFields mutation. Returns known/expected errors in a structured manner to the client. */
export type SetCustomFieldsResponseUnion = CartNotFoundResponse | SetCustomFieldsResponse

export type SetOutOfPolicyJustificationInput = {
  /** Unique identifier for the cart. */
  cartId: Scalars['ID']
  /** Compliance code values per item/intent in cart. */
  codes: Array<ComplianceCodeValue>
  /** Overall explanation for reserving out-of-policy items - aka. "Trip Purpose". */
  explanation: Scalars['String']
}

/** SetJustificationResponse is returned when the setJustification mutation succeeds. */
export type SetOutOfPolicyJustificationResponse = {
  /** Cart ID of the requested cart. */
  cartId: Scalars['ID']
}

/** Union of different response objects that can be returned by setJustification mutation. Returns known/expected errors in a structured manner to the client. */
export type SetOutOfPolicyJustificationResponseUnion =
  | CartNotFoundResponse
  | SetOutOfPolicyJustificationResponse

export type SetPasswordErrorResponse = BaseResponse | UnexpectedError

/** Provides the input parameters for the setPassword mutation. */
export type SetPasswordInput = {
  /** The authentication key to set the password */
  key: Scalars['String']
  /** The new password to update to */
  newPassword: Scalars['String']
  /** The confirmation of the new password to update to */
  newPasswordConfirmation: Scalars['String']
}

export type SetRcSessionCookieInput = {
  token: Scalars['String']
}

export enum ShareTravelerContactType {
  ProvideAll = 'PROVIDE_ALL',
  ProvideEmail = 'PROVIDE_EMAIL',
  ProvideNone = 'PROVIDE_NONE',
  ProvidePhone = 'PROVIDE_PHONE',
}

export type SiteAccount = {
  cluster: Scalars['String']
  companySsoEndpointUrl?: Maybe<Scalars['String']>
  mobileSsoEnabled: Scalars['Boolean']
  partnerId: Scalars['Int']
  siteId: Scalars['Int']
  siteName: Scalars['String']
  siteType: Scalars['Int']
  siteUrlName: Scalars['String']
  ssoType: SsoVariant
  state: Scalars['String']
  url: Scalars['String']
}

/** The response which returns the sites to which an Apple user belongs. */
export type SiteByApiKey = {
  /** Site identifier associated with Apple user */
  siteId: Scalars['Int']
  /** Site name associated with Apple user */
  siteName: Scalars['String']
}

export type SiteCardDataInputDto = {
  id: Scalars['String']
}

export type SiteCardInputDto = {
  address?: InputMaybe<AddressInputDto>
  data: SiteCardDataInputDto
  id: Scalars['String']
}

export type SiteCardPersonalData = {
  /** Brand type of corporate credit card. */
  brandType?: Maybe<CreditCardBrandType>
}

export type SiteCards = {
  /** Site (corporate) Cards. */
  cards: Array<SiteCardsDetails>
}

export type SiteCardsDetails = {
  /** Credit card details of a site card. */
  card: SiteCardPersonalData
  /** Site Card ID. */
  id: Scalars['Float']
  /** Indicates if the site card is active. */
  isActive: Scalars['Boolean']
  /** Indicates if the site card is available for use on flight bookings. */
  isEnabledForAir: Scalars['Boolean']
  /** Indicates if the site card is available for use on car rental bookings. */
  isEnabledForCarRental: Scalars['Boolean']
  /** Indicates if the site card is available for use on car service bookings. */
  isEnabledForCarService: Scalars['Boolean']
  /** Indicates if the site card is available for use on hotel bookings. */
  isEnabledForHotel: Scalars['Boolean']
  /** Indicates if the site card is required for use on flight bookings. */
  isRequiredForAir: Scalars['Boolean']
  /** Indicates if the site card is required for use on hotel bookings. */
  isRequiredForHotel: Scalars['Boolean']
  /** Indicates whether use of the site card is disabled. */
  isRestricted: Scalars['Boolean']
}

export type SitePaymentCard = {
  id?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
}

export type SiteSsoSettings = {
  /** Defines logout message for SSO user */
  logOutMessage?: Maybe<Scalars['String']>
  /** Defines specific URL to logout for SSO user */
  logoutRedirectURL?: Maybe<Scalars['String']>
  redirectOnLogOut?: Maybe<Scalars['Boolean']>
  redirectOnSessionTimeout?: Maybe<Scalars['Boolean']>
  /** Defines session timeout message for SSO user */
  sessionTimeoutMessage?: Maybe<Scalars['String']>
  /** Defines session timeout message for SSO user */
  sessionTimeoutRedirectURL?: Maybe<Scalars['String']>
}

/** Provides the input parameters for the sitesByApiKey query */
export type SitesByApiKeyInput = {
  /** Used to log in an Apple user */
  apiKey: Scalars['String']
  /** Apple user email */
  email: Scalars['String']
  /** Parameters passed from Apple Connect to the authentication endpoint. */
  profileAttribute?: InputMaybe<ProfileAttribute>
  /** Site identifier associated with Apple user */
  siteId?: InputMaybe<Scalars['Int']>
}

/** The response returned when login is being processed for Apple users */
export type SitesByApiKeyResponse = {
  /** Customer/User identifier associated with Apple user */
  customerId?: Maybe<Scalars['Int']>
  /** Token be used to log in an Apple user. */
  encryptedSessionToken: Scalars['String']
  /** Unique identifier for the login session. */
  sessionId: Scalars['String']
  /** Sites to which an Apple user belongs */
  sites: Array<SiteByApiKey>
}

export type SitesByUserEmailInput = {
  deviceClass?: InputMaybe<DeviceClass>
  email: Scalars['String']
}

export type SpecialRequestInputDto = {
  air?: InputMaybe<Array<SpecialRequestSegmentInputDto>>
  carRental?: InputMaybe<Array<SpecialRequestSegmentInputDto>>
  hotel?: InputMaybe<Array<SpecialRequestSegmentInputDto>>
}

export type SpecialRequestOption = {
  label: Scalars['String']
  value: Scalars['String']
}

export type SpecialRequestSegmentInputDto = {
  code: Scalars['String']
}

/** Lists of the available air, hotel and car rental vendors */
export type SpecialRequests = {
  /** List of the available air special requests */
  airSpecialRequests?: Maybe<Array<SpecialRequestOption>>
  /** List of the available car rental special requests */
  carRentalSpecialRequests?: Maybe<Array<SpecialRequestOption>>
  /** List of the available hotel rspecial requests */
  hotelSpecialRequests?: Maybe<Array<SpecialRequestOption>>
}

/** Input object with travel vertical parameter to request available special requests for this vertical */
export type SpecialRequestsInput = {
  /** Gazoo travel vertical name, used to configure request endpoint. Supported travel vertical strings - AIR, HOTEL, CARRENTAL */
  travelVertical: VendorTravelVertical
}

export type StationFilter = {
  destination: StationFilterStation
  id: Scalars['ID']
  origin: StationFilterStation
}

export type StationFilterStation = {
  city?: Maybe<Scalars['String']>
  code: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type StopsFilter = {
  id: Scalars['ID']
  isSelected?: Maybe<Scalars['Boolean']>
  value: Scalars['String']
}

/** Defines fields required to submit new car rental search. */
export type SubmitNewCarRentalSearchInput = {
  /** Car rental search params. */
  searchParams: SubmitNewCarRentalSearchParamsInput
}

/** Defines car rental search params required fields. */
export type SubmitNewCarRentalSearchParamsInput = {
  /** Car rental search drop-off information. */
  dropOff: SubmitNewCarRentalSearchParamsInteractionInputDto
  /** Car rental search pick-up information. */
  pickUp: SubmitNewCarRentalSearchParamsInteractionInputDto
}

/** Defines car rental pick-up or drop-off required fields. */
export type SubmitNewCarRentalSearchParamsInteractionInputDto = {
  /** Car rental search pick-up or drop-off date. Format YYYY-MM-DD in local TZ. */
  date: Scalars['String']
  /** Car rental search pick-up or drop-off location. */
  location: SubmitNewCarRentalSearchParamsInteractionLocationInput
  /** Car rental search pick-up or drop-off time. Format hh:mm:ss in local TZ. */
  time: Scalars['String']
}

/** Defines car rental pick-up or drop-off location. */
export type SubmitNewCarRentalSearchParamsInteractionLocationInput = {
  /** IATA 3 letter airport code. */
  airportCode?: InputMaybe<Scalars['String']>
  /** Location coordinates. */
  geocode: GeocodeInput
  /** Location name. */
  name: Scalars['String']
}

/** SubmitNewCarRentalSearchResult returns when the submitNewCarRentalSearch mutation has been executed. */
export type SubmitNewCarRentalSearchResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Boolean flag representing the success of submitted recent car rental search. */
  isOk: Scalars['Boolean']
}

/** Union of different response objects that can be returned by submitNewCarRentalSearch mutation. Returns known/expected errors in a structured manner to the client. */
export type SubmitNewCarRentalSearchUnion = SubmitNewCarRentalSearchResult

/** Defines fields required to submit new flight search. */
export type SubmitNewFlightSearchInput = {
  /** Flight search params. */
  searchParams: SubmitNewFlightSearchParamsInput
}

/** Defines flight search params required fields.  */
export type SubmitNewFlightSearchParamsInput = {
  /** Flight search flight type. */
  flightType: RecentSearchesFlightSearchParamsFlightType
  /** Flight search segments. */
  segments: Array<SubmitNewFlightSearchParamsSegmentInput>
}

/** Defines flight segment required fields. */
export type SubmitNewFlightSearchParamsSegmentInput = {
  /** Cabin class, as specified in the flight search. */
  cabinClass: RecentSearchesFlightSearchParamsCabinClass
  /** Flight departure date. Format YYYY-MM-DD in local TZ. */
  departureDate: Scalars['String']
  /** Destination airport location information used in a flight search. */
  destination: SubmitNewFlightSearchParamsSegmentLocationInput
  /** Origin airport location information used in a flight search. */
  origin: SubmitNewFlightSearchParamsSegmentLocationInput
  /** Flight search time range filter. */
  searchTimeRange: SubmitNewSearchTimeRange
}

/** Defines flight segment location required fields. */
export type SubmitNewFlightSearchParamsSegmentLocationInput = {
  /** IATA 3 letter airport code. */
  airportCode?: InputMaybe<Scalars['String']>
  /** 2 letter country code. Example: "US". */
  countryCode?: InputMaybe<Scalars['String']>
  /** Location coordinates. */
  geocode: GeocodeInput
  /** Location name. */
  name: Scalars['String']
}

/** SubmitNewFlightSearchResult returns when the submitNewFlightSearch mutation has been executed. */
export type SubmitNewFlightSearchResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Boolean flag representing the success of submitted recent flight search. */
  isOk: Scalars['Boolean']
}

/** Union of different response objects that can be returned by submitNewFlightSearch mutation. Returns known/expected errors in a structured manner to the client. */
export type SubmitNewFlightSearchUnion = SubmitNewFlightSearchResult

/** Defines fields required to submit new hotel search. */
export type SubmitNewHotelSearchInput = {
  /** Hotel search params. */
  searchParams: SubmitNewHotelSearchParamsInput
}

/** Defines hotel search params required fields. */
export type SubmitNewHotelSearchParamsInput = {
  /** Hotel search check-in date. Format YYYY-MM-DD in local TZ. */
  checkIn: Scalars['String']
  /** Hotel search check-out date. Format YYYY-MM-DD in local TZ. */
  checkOut: Scalars['String']
  /** Hotel search location information. */
  location: SubmitNewHotelSearchParamsLocationInput
  /** Hotel search radius in miles. */
  searchRadius: Scalars['Float']
}

/** Defines hotel location required fields. */
export type SubmitNewHotelSearchParamsLocationInput = {
  /** Location coordinates. */
  geocode: GeocodeInput
  /** Location name. */
  name: Scalars['String']
}

/** SubmitNewHotelSearchResult returns when the submitNewHotelSearch mutation has been executed. */
export type SubmitNewHotelSearchResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Boolean flag representing the success of submitted recent hotel search. */
  isOk: Scalars['Boolean']
}

/** Union of different response objects that can be returned by submitNewHotelSearch mutation. Returns known/expected errors in a structured manner to the client. */
export type SubmitNewHotelSearchUnion = SubmitNewHotelSearchResult

/** Defines fields required to submit new rail search. */
export type SubmitNewRailSearchInput = {
  /** Rail search params. */
  searchParams: SubmitNewRailSearchParamsInput
}

/** Defines rai search params required fields. */
export type SubmitNewRailSearchParamsInput = {
  /** Rail search rail cards. */
  railCards: Array<SubmitNewRailSearchParamsRailCardInput>
  /** Rail search rail type. */
  railType: SubmitNewRailSearchParamsRailType
  /** Rail search segments. */
  segments: Array<SubmitNewRailSearchParamsSegmentInput>
}

/** Defines rail card required fields. */
export type SubmitNewRailSearchParamsRailCardInput = {
  /** Rail card code. */
  code: Scalars['String']
  /** Rail card group name. */
  groupName: Scalars['String']
  /** Rail card name. */
  name: Scalars['String']
  /** Rail card vendor code. */
  vendorCode: Scalars['String']
  /** Rail card vendor name. */
  vendorName: Scalars['String']
}

/** Rail search rail type. */
export enum SubmitNewRailSearchParamsRailType {
  OneWay = 'OneWay',
  OpenReturn = 'OpenReturn',
  RoundTrip = 'RoundTrip',
}

/** Defines rail segment required fields. */
export type SubmitNewRailSearchParamsSegmentInput = {
  /** Rail search departure date. Format YYYY-MM-DD in local TZ. */
  departureDate: Scalars['String']
  /** Rail search destination(arrival) location information. */
  destination: SubmitNewRailSearchParamsSegmentLocationInput
  /** Rail search origin(departure) location information. */
  origin: SubmitNewRailSearchParamsSegmentLocationInput
  /** Rail search time range filter. */
  searchTimeRange: SubmitNewSearchTimeRange
}

/** Defines rail location required fields. */
export type SubmitNewRailSearchParamsSegmentLocationInput = {
  /** Location coordinates. */
  geocode?: InputMaybe<GeocodeInput>
  /** Rail search location code. */
  locationCode?: InputMaybe<Scalars['String']>
  /** Location name. */
  name: Scalars['String']
}

/** SubmitNewRailSearchResult returns when the submitNewRailSearch mutation has been executed. */
export type SubmitNewRailSearchResult = {
  /** Error messages list. */
  errorMessages: Array<Scalars['String']>
  /** Boolean flag representing the success of submitted recent rail search. */
  isOk: Scalars['Boolean']
}

/** Union of different response objects that can be returned by submitNewHotelSearch mutation. Returns known/expected errors in a structured manner to the client. */
export type SubmitNewRailSearchResultUnion = SubmitNewRailSearchResult

/** Defines search time range required fields. */
export type SubmitNewSearchTimeRange = {
  /** Time range custom hours. Format: integer 1-24. */
  customHours?: InputMaybe<Scalars['Int']>
  /** Time range filter end time. Format hh:mm:ss in local TZ. */
  end: Scalars['String']
  /** Time range filter start time. Format hh:mm:ss in local TZ. */
  start: Scalars['String']
  /** Time range filter type. */
  type: SubmitNewSearchTimeRangeType
}

/** Time range filter type. */
export enum SubmitNewSearchTimeRangeType {
  /** Arrival time range. */
  Arrival = 'Arrival',
  /** Departure time range. */
  Departure = 'Departure',
}

/** Provides the input parameters for the suggestedHomeAirports query */
export type SuggestedHomeAirportsInput = {
  /** Airport query text for which airport suggestions are required, e.g. airport code or city name */
  query: Scalars['String']
}

/** Filter by hotel super brand */
export type SuperBrandFilter = {
  brandDisplayName: Scalars['String']
  brandId: Scalars['String']
  brands: Array<BrandFilter>
  hotelCount: Scalars['Int']
}

export type SupportArticle = {
  body: Scalars['String']
  id: Scalars['ID']
  image?: Maybe<SupportArticleImage>
  isFeatured?: Maybe<Scalars['Boolean']>
  title: Scalars['String']
}

export type SupportArticleImageArgs = {
  input?: InputMaybe<SupportArticleImageInput>
}

export type SupportArticleByIdInput = {
  id: Scalars['String']
  imageOptions?: InputMaybe<SupportArticleImageOptions>
}

export type SupportArticleImage = {
  alt?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum SupportArticleImageFormatEnum {
  Jpg = 'jpg',
  Pjpg = 'pjpg',
  Png = 'png',
  Webp = 'webp',
}

export type SupportArticleImageInput = {
  format?: InputMaybe<Scalars['String']>
  height?: InputMaybe<Scalars['Int']>
  pixelRatio?: InputMaybe<Scalars['Int']>
  quality?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

export type SupportArticleImageOptions = {
  /** Image format. Could be "jpg", "pjpg", "png", "webp" */
  imageFormat?: InputMaybe<SupportArticleImageFormatEnum>
}

export type SupportArticlesInput = {
  deviceClass?: InputMaybe<DeviceClass>
  first?: InputMaybe<Scalars['Int']>
  imageOptions?: InputMaybe<SupportArticleImageOptions>
  offset?: InputMaybe<Scalars['Int']>
}

export type SupportDisplayConfiguration = {
  /** Provide information for desktop layout on support page */
  desktop: DesktopSupportDisplayConfiguration
  /** Provide information for mobile layout on support page */
  mobile: MobileSupportDisplayConfiguration
  /** Provide information for technical support block on support page */
  technicalSupport: TechnicalSupportDisplayConfiguration
}

export type SupportInformation = {
  email?: Maybe<EmailSupport>
  phoneNumbers: Array<PhoneSupport>
}

export enum SystemBehaviorType {
  CollectCompInfo = 'COLLECT_COMP_INFO',
  PermForNonComp = 'PERM_FOR_NON_COMP',
  ReselectCompTrip = 'RESELECT_COMP_TRIP',
  UserCompExplain = 'USER_COMP_EXPLAIN',
  ValidatePreApproval = 'VALIDATE_PRE_APPROVAL',
}

export type TakeoffLandingRangesInput = {
  landing?: InputMaybe<TimeRangeInput>
  legPosition?: InputMaybe<Scalars['LegPosition']>
  takeoff?: InputMaybe<TimeRangeInput>
}

/** Represents the pages where the corresponding DSM should be used */
export enum TargetPageType {
  HoldTrip = 'HOLD_TRIP',
  TripPurchase = 'TRIP_PURCHASE',
  TripReview = 'TRIP_REVIEW',
}

export type TechnicalSupportDisplayConfiguration = {
  /** Defines if technical support block enabled on support page */
  isEnabled: Scalars['Boolean']
  /** Html content for technical support block */
  text?: Maybe<Scalars['String']>
}

export type TermsAndConditions = {
  content?: Maybe<Scalars['String']>
  format?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type TextAdditionalInformation = {
  defaultText?: Maybe<Scalars['String']>
  maxLength?: Maybe<Scalars['Float']>
  minLength?: Maybe<Scalars['Int']>
  numbersOfLine?: Maybe<Scalars['Int']>
  singleLine?: Maybe<Scalars['Boolean']>
  textValue?: Maybe<Scalars['String']>
}

export type TicketSegment = {
  carrierCode?: Maybe<Scalars['String']>
  departTime?: Maybe<Scalars['String']>
  destination?: Maybe<Scalars['String']>
  fareBasis?: Maybe<Scalars['String']>
  flightNumber?: Maybe<Scalars['Int']>
  origin?: Maybe<Scalars['String']>
  serviceClass?: Maybe<SeatmapCabinClass>
  status?: Maybe<TicketStatusEnum>
}

export enum TicketStatusEnum {
  Open = 'OPEN',
  Refund = 'REFUND',
  Used = 'USED',
  Void = 'VOID',
}

export type TimeFilter = {
  end?: Maybe<Scalars['DateTime']>
  start?: Maybe<Scalars['DateTime']>
}

export enum TimeFormat {
  Hr_12 = 'HR_12',
  Hr_24 = 'HR_24',
}

export type TimeRangeInput = {
  end?: InputMaybe<Scalars['DateTime']>
  start?: InputMaybe<Scalars['DateTime']>
}

export type TimeZone = {
  code: Scalars['String']
  value: Scalars['String']
}

export type ToggleNotificationPreferenceInput = {
  clientType: NotificationClientType
  preferences: Array<NotificationPreferenceInput>
}

export type TrainCompanyFilter = {
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  value: Scalars['String']
}

export type TrainCostSummary = {
  /** Represents the total amounts for the train */
  fare?: Maybe<TrainCostSummaryFare>
  /** @deprecated Use fare.total instead */
  total?: Maybe<Rate>
  trainList: Array<TrainCostSummaryItem>
}

export type TrainCostSummaryFare = {
  /** Base rate before to apply fees and taxes */
  base: Rate
  paymentFee: Rate
  serviceFee: Rate
  tax: Rate
  /** Total amount fees and taxes included */
  total: Rate
  transactionFee: Rate
}

export type TrainCostSummaryItem = {
  cost?: Maybe<Rate>
  destination?: Maybe<Scalars['String']>
  isPaid: Scalars['Boolean']
  origin?: Maybe<Scalars['String']>
  tax?: Maybe<Rate>
  total: Rate
}

export type TrainLegSegment = {
  appliedRailCards: Array<Scalars['String']>
  arrivalDateTime: Scalars['DateTime']
  arrivalStationCode: Scalars['String']
  bookingFees?: Maybe<Rate>
  deliveryFees?: Maybe<Rate>
  /** Selected delivery option of the rail ticket */
  deliveryOption?: Maybe<DeliveryOptions>
  departureDateTime: Scalars['DateTime']
  departureStationCode: Scalars['String']
  ecoInfo?: Maybe<Scalars['String']>
  /** Fare conditions for each journey fare type */
  fareConditions?: Maybe<FareConditions>
  imageUrl?: Maybe<Scalars['String']>
  isEticket?: Maybe<Scalars['Boolean']>
  isRefundable?: Maybe<Scalars['Boolean']>
  isReserved?: Maybe<Scalars['Boolean']>
  /** Train leg id */
  legId: Scalars['String']
  name: Scalars['String']
  paymentFees?: Maybe<Rate>
  penaltyFees?: Maybe<Array<Maybe<PenaltyFee>>>
  platform?: Maybe<Scalars['String']>
  policy: Policy
  rate: Rate
  returnValidities?: Maybe<Array<Maybe<Scalars['String']>>>
  routeType?: Maybe<RouteType>
  rulesAndRestrictions?: Maybe<Array<Scalars['String']>>
  seatsRequested?: Maybe<Array<Scalars['String']>>
  segmentState?: Maybe<SegmentState>
  segments: Array<TrainOrBusSegment>
  taxes?: Maybe<Rate>
  tier?: Maybe<TrainTier>
  totalCost?: Maybe<Rate>
  transactionFees?: Maybe<Rate>
  travelStops: Scalars['Int']
  travelTime: TravelTime
}

export enum TrainOrBus {
  Bus = 'BUS',
  Train = 'TRAIN',
}

export type TrainOrBusSegment = {
  allocatedSeats?: Maybe<Array<ItineraryTrainSegmentSeatInfo>>
  amenities?: Maybe<TransportAmenities>
  arrival: TrainStationInfo
  arrivalDate: Scalars['String']
  carrierCode: Scalars['String']
  carrierLogoUrl?: Maybe<Scalars['String']>
  carrierName?: Maybe<Scalars['String']>
  changeDuration?: Maybe<TravelTime>
  departure: TrainStationInfo
  departureDate: Scalars['String']
  imageUrl: Scalars['String']
  marketingServiceName?: Maybe<Scalars['String']>
  segmentComment?: Maybe<Scalars['String']>
  segmentId: Scalars['ID']
  service: Scalars['String']
  serviceClassName?: Maybe<Scalars['String']>
  stops?: Maybe<Array<TrainStop>>
  trainData?: Maybe<Scalars['String']>
  trainId?: Maybe<Scalars['String']>
  /** trainId: Identifier of the train from train provider. */
  transportNumber: Scalars['Int']
  /** transportationMode: represents the transportation mode between the rail legs. */
  transportationMode?: Maybe<RailTransportationMode>
  travelTime: TravelTime
  type: TrainOrBus
}

export type TrainPenaltyFee = {
  amount?: Maybe<Money>
  effectiveEndDate?: Maybe<Scalars['String']>
  effectiveStartDate?: Maybe<Scalars['String']>
  isRuleAllowed?: Maybe<Scalars['Boolean']>
  state?: Maybe<TrainPenaltyFeeStateEnum>
}

export enum TrainPenaltyFeeStateEnum {
  Confirmed = 'CONFIRMED',
  Ticketed = 'TICKETED',
}

export type TrainSegmentAmenities = {
  code: Scalars['String']
  value: Scalars['String']
}

export type TrainSegmentSeatInfo = {
  carriage: Scalars['String']
  seat: Scalars['String']
}

export type TrainStationInfo = {
  address: Address
  phoneNumber?: Maybe<Scalars['String']>
  stationCode: Scalars['String']
  stationName: Scalars['String']
  /** Indicates whether the ticket machine is available at this station: TRUE ,FALSE ,UNSPECIFIED */
  ticketMachineAvailable?: Maybe<TriStateBoolean>
}

export type TrainStop = {
  departingTime?: Maybe<Scalars['DateTime']>
  /** @deprecated Use departingTime instead. */
  departureTime: Scalars['DateTime']
  stationName: Scalars['String']
  time: Scalars['String']
}

export type TrainTier = {
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TransactionDetails = {
  currency: Scalars['String']
  rate: Scalars['String']
  rateType: Scalars['String']
  totalCost: Scalars['String']
}

export type TransactionGroup = {
  cityImageUrl: Scalars['String']
  lastModifiedTime?: Maybe<Scalars['String']>
  meetingInfo?: Maybe<TripMeetingInfoDto>
  name?: Maybe<Scalars['String']>
  /** @deprecated Not in use. */
  orders?: Maybe<Array<Maybe<TransactionGroupsOrder>>>
  rollUpEndTime: Scalars['DateTime']
  rollUpStartTime: Scalars['DateTime']
  serviceCategoryList: Array<ServiceCategory>
  transactionGroupId?: Maybe<Scalars['String']>
  transactionId?: Maybe<Scalars['String']>
  travelerId?: Maybe<Scalars['String']>
  travelerName: Scalars['String']
}

export type TransactionGroupsOrder = {
  bookingStatus?: Maybe<TripStatus>
  description?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['DateTime']>
  serviceCategory?: Maybe<ServiceCategory>
  serviceName?: Maybe<Scalars['String']>
  serviceOrderId?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['DateTime']>
}

export type TransportAmenities = {
  codes: Array<Maybe<Scalars['String']>>
  values: Array<Maybe<Scalars['String']>>
}

export type TravelMembership = {
  number: Scalars['String']
  vendorCode: Scalars['String']
}

export type TravelMessage = {
  descriptions?: Maybe<Array<TravelMessageError>>
  fieldName?: Maybe<Scalars['String']>
  parameters?: Maybe<Array<TravelMessageError>>
  severity: TravelMessageSeverity
}

export type TravelMessageError = {
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export enum TravelMessageSeverity {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

export type TravelPolicy = {
  isPurposeRequired?: Maybe<Scalars['Boolean']>
  /** Refers to the minimum number of days in advance that a purchase or reservation can be made. */
  minAdvancePurchaseDays?: Maybe<Scalars['Float']>
  purposeLabel?: Maybe<Scalars['String']>
  /** @deprecated Use `systemBehaviorType`. */
  systemBehaviorId?: Maybe<Scalars['String']>
  systemBehaviorType?: Maybe<SystemBehaviorType>
}

export type TravelPolicyList = {
  companyName?: Maybe<Scalars['String']>
  policyList?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TravelPreferences = {
  carrental: CarRentalPreference
  flight: AirTravelPreference
  hotel: HotelPreference
  rail: RailPreference
}

/** Travel preferences for all segment types */
export type TravelPreferencesInput = {
  carRental: CarRentalPreferenceInput
  flight: AirTravelPreferenceInputDto
  hotel: HotelPreferenceInput
  rail: RailPreferenceInput
}

export type TravelTime = {
  hours: Scalars['Int']
  minutes: Scalars['Int']
}

export enum TravelVertical {
  Air = 'AIR',
  Carrental = 'CARRENTAL',
  CarService = 'CAR_SERVICE',
  Cruise = 'CRUISE',
  Hotel = 'HOTEL',
  Limo = 'LIMO',
  Rail = 'RAIL',
  Unspecified = 'UNSPECIFIED',
}

/** Contains first name and surname of traveler for a given trip. */
export type Traveler = {
  /** Travelers first name. */
  firstName?: Maybe<Scalars['String']>
  /** Traveler's surname. */
  surname?: Maybe<Scalars['String']>
}

export type TravelerAddressInputDto = {
  /** City */
  city: Scalars['String']
  /** Country code */
  countryCode: Scalars['String']
  /** Postal code */
  postalCode?: InputMaybe<Scalars['String']>
  /** State code */
  stateCode?: InputMaybe<Scalars['String']>
  /** First street */
  street1?: InputMaybe<Scalars['String']>
  /** Second street */
  street2?: InputMaybe<Scalars['String']>
}

export type TravelerFieldsInputDto = {
  isAddressChangeable?: InputMaybe<Scalars['Boolean']>
  isAddressRequired?: InputMaybe<Scalars['Boolean']>
  isDateOfBirthChangeable?: InputMaybe<Scalars['Boolean']>
  isDateOfBirthRequired?: InputMaybe<Scalars['Boolean']>
  isDefaultChangeTravelerOptionEnabled?: InputMaybe<Scalars['Boolean']>
  isEMailChangeable?: InputMaybe<Scalars['Boolean']>
  isEMailRequired?: InputMaybe<Scalars['Boolean']>
  isFirstNameRequired?: InputMaybe<Scalars['Boolean']>
  isGenderChangeable?: InputMaybe<Scalars['Boolean']>
  isGenderRequired?: InputMaybe<Scalars['Boolean']>
  isLastNameRequired?: InputMaybe<Scalars['Boolean']>
  isMiddleNameRequired?: InputMaybe<Scalars['Boolean']>
  isNameChangeable?: InputMaybe<Scalars['Boolean']>
  isPhoneNumberChangeable?: InputMaybe<Scalars['Boolean']>
  isPhoneNumberRequired?: InputMaybe<Scalars['Boolean']>
  isPrimaryTraveler?: InputMaybe<Scalars['Boolean']>
  isSuffixChangeable?: InputMaybe<Scalars['Boolean']>
  isSuffixRequired?: InputMaybe<Scalars['Boolean']>
  isTitleChangeable?: InputMaybe<Scalars['Boolean']>
}

export type TravelerInputDto = {
  address?: InputMaybe<TravelerAddressInputDto>
  dateOfBirth?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  phoneNumber: Array<TravelerPhoneNumberInput>
}

export type TravelerOptions = {
  isAddressChangeable?: Maybe<Scalars['Boolean']>
  isAddressRequired?: Maybe<Scalars['Boolean']>
  isDateOfBirthChangeable?: Maybe<Scalars['Boolean']>
  isDateOfBirthRequired?: Maybe<Scalars['Boolean']>
  isDefaultChangeTravelerOptionEnabled?: Maybe<Scalars['Boolean']>
  isEMailChangeable?: Maybe<Scalars['Boolean']>
  isEMailRequired?: Maybe<Scalars['Boolean']>
  isFirstNameRequired?: Maybe<Scalars['Boolean']>
  isGenderChangeable?: Maybe<Scalars['Boolean']>
  isGenderRequired?: Maybe<Scalars['Boolean']>
  isLastNameRequired?: Maybe<Scalars['Boolean']>
  isMiddleNameRequired?: Maybe<Scalars['Boolean']>
  isNameChangeable?: Maybe<Scalars['Boolean']>
  isPassportRequired?: Maybe<Scalars['Boolean']>
  isPhoneNumberChangeable?: Maybe<Scalars['Boolean']>
  isPhoneNumberRequired?: Maybe<Scalars['Boolean']>
  /** Indicates whether a user can edit their suffix details when using Etta to reserve inventory. */
  isSuffixChangeable?: Maybe<Scalars['Boolean']>
  isSuffixRequired?: Maybe<Scalars['Boolean']>
  isTitleChangeable?: Maybe<Scalars['Boolean']>
}

export type TravelerPhoneNumberInput = {
  /** Country code */
  countryCode: Scalars['String']
  /** Phone number without country code */
  number: Scalars['String']
  /** Type of the number */
  type: Scalars['String']
}

export type TravelerPreferences = {
  airMemberships?: Maybe<Array<TravelMembership>>
  airSpecialRequestsCodes?: Maybe<Array<Scalars['String']>>
  carRentalMemberships?: Maybe<Array<TravelMembership>>
  carRentalSpecialRequestsCodes?: Maybe<Array<Scalars['String']>>
  hotelMemberships?: Maybe<Array<TravelMembership>>
  hotelSpecialRequestsCodes?: Maybe<Array<Scalars['String']>>
  mealCodes?: Maybe<Array<Scalars['String']>>
}

/** Specifies three boolean states: TRUE, FALSE, and NULL (as mapped to UNSPECIFIED). */
export enum TriStateBoolean {
  TriStateBooleanFalse = 'TRI_STATE_BOOLEAN_FALSE',
  TriStateBooleanTrue = 'TRI_STATE_BOOLEAN_TRUE',
  TriStateBooleanUnspecified = 'TRI_STATE_BOOLEAN_UNSPECIFIED',
}

export type TripCost = {
  base?: Maybe<Rate>
  carServiceCharges?: Maybe<CarServiceCharges>
  fareTaxInfo?: Maybe<Array<FareTaxInfo>>
  feesAndTaxes?: Maybe<Rate>
  passengerNumber?: Maybe<Scalars['Int']>
  serviceFee?: Maybe<Rate>
  ticketNumber?: Maybe<Scalars['Int']>
  total: Rate
  transactionFee?: Maybe<Rate>
}

export type TripCostDetails = {
  /** Payment summary of the reservations in the trip. */
  domainCostSummary: Array<DomainCostSummaryUnion>
  /** Estimated total amount to be charged across all reservations in the trip, inclusive of taxes and surcharges. */
  total: Rate
}

export type TripCostSummary = {
  allowUnusedTicketOptout?: Maybe<Scalars['Boolean']>
  fareTaxInfo?: Maybe<Array<FareTaxInfo>>
  hasOptOutUnusedTickets?: Maybe<Scalars['Boolean']>
  payLater?: Maybe<PayLater>
  payNow?: Maybe<PayNow>
  total: Rate
  totalWithUnusedTicket?: Maybe<Rate>
  unusedTicket?: Maybe<UnusedTicket>
}

export type TripDeliveryOption = {
  code: Scalars['String']
  name: Scalars['String']
}

export type TripDetailsCarRentalInteraction = {
  address?: Maybe<Address>
  airportCode?: Maybe<Scalars['String']>
  date: Scalars['String']
  isAirportLocation?: Maybe<Scalars['Boolean']>
  isDeliveryCollection?: Maybe<Scalars['Boolean']>
  phone?: Maybe<CarRentalPhone>
  workingHours?: Maybe<CarWorkingHours>
}

export type TripDetailsCarRentalSegment = {
  carCapacity?: Maybe<Scalars['Int']>
  carClass?: Maybe<CarRentalClassType>
  carId?: Maybe<Scalars['ID']>
  carImageUrl?: Maybe<Scalars['String']>
  carLuggage?: Maybe<Scalars['Int']>
  carName?: Maybe<Scalars['String']>
  carTransmission?: Maybe<CarRentalTransmissionType>
  carType?: Maybe<CarRentalType>
  confirmationNumber?: Maybe<Scalars['String']>
  contractName?: Maybe<Scalars['String']>
  dailyRate?: Maybe<Rate>
  daysCount?: Maybe<Scalars['Int']>
  dropOffLocation?: Maybe<TripDetailsCarRentalInteraction>
  dropOffTime?: Maybe<Scalars['String']>
  extraCharge?: Maybe<ExtraCharge>
  fees?: Maybe<Rate>
  fuelType?: Maybe<CarRentalFuelType>
  isCarAirConditioned?: Maybe<Scalars['Boolean']>
  isReserved?: Maybe<Scalars['Boolean']>
  isUnlimitedMileage?: Maybe<Scalars['Boolean']>
  membership?: Maybe<Array<CheckoutInfoCarMembershipData>>
  pickUpLocation?: Maybe<TripDetailsCarRentalInteraction>
  pickUpTime?: Maybe<Scalars['String']>
  policy?: Maybe<Policy>
  preferredDescription?: Maybe<Preference>
  recordLocator?: Maybe<Scalars['String']>
  safetyCheck?: Maybe<CarRentalSafetyCheck>
  serviceFee?: Maybe<ServiceFee>
  specialRequests?: Maybe<Array<Scalars['String']>>
  /** Represents the status of the trip segment. */
  status?: Maybe<TripStatus>
  taxes?: Maybe<Rate>
  taxesAndFees?: Maybe<Rate>
  totalBaseCost?: Maybe<Rate>
  totalCost?: Maybe<Rate>
  traveler: TripDetailsTraveler
  vendor?: Maybe<Vendor>
}

export type TripDetailsCarServiceCar = {
  capacity?: Maybe<Scalars['Int']>
  class?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type TripDetailsCarServiceCompanyService = {
  description?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TripDetailsCarServiceDetailsRules = {
  cancelPolicy?: Maybe<Scalars['String']>
  chargeDisclaimer?: Maybe<Scalars['String']>
  chargeQualifier?: Maybe<Scalars['String']>
  generalMessage?: Maybe<Scalars['String']>
}

export type TripDetailsCarServiceDropOff = {
  address?: Maybe<Address>
  dropOffInstructions?: Maybe<Scalars['String']>
  location?: Maybe<TripDetailsCarServiceDropOffLocation>
  time?: Maybe<Scalars['String']>
}

export type TripDetailsCarServiceDropOffLocation = {
  asDirected?: Maybe<Scalars['Boolean']>
  asDirectedMultiDay?: Maybe<Scalars['Boolean']>
  geocode?: Maybe<Geocode>
  requestedDuration?: Maybe<Scalars['Int']>
  requestedHours?: Maybe<Scalars['Int']>
}

export type TripDetailsCarServicePickUp = {
  address?: Maybe<Address>
  location?: Maybe<TripDetailsCarServicePickUpLocation>
  pickupInstructions?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
}

export type TripDetailsCarServicePickUpLocation = {
  geocode?: Maybe<Geocode>
}

export type TripDetailsCarServiceSegment = {
  car?: Maybe<TripDetailsCarServiceCar>
  confirmationNumber?: Maybe<Scalars['String']>
  detailsRules?: Maybe<TripDetailsCarServiceDetailsRules>
  dropOff?: Maybe<TripDetailsCarServiceDropOff>
  pickUp?: Maybe<TripDetailsCarServicePickUp>
  pickUpTime?: Maybe<Scalars['DateTime']>
  policy?: Maybe<Policy>
  price: CarServiceTripCost
  recordLocator?: Maybe<Scalars['String']>
  serviceCompany?: Maybe<TripDetailsCarServiceCompanyService>
  stops?: Maybe<Array<Address>>
  traveler: TripDetailsTraveler
}

export type TripDetailsCostSummary = {
  carRental?: Maybe<CarRentalCostSummary>
  carService?: Maybe<CarServiceCostSummary>
  flight?: Maybe<FlightCostSummary>
  hotel?: Maybe<HotelCostSummary>
  totalCost?: Maybe<Rate>
  train?: Maybe<TrainCostSummary>
}

export type TripDetailsFlightActions = {
  allowedSearchDateRange?: Maybe<TripDetailsReturnFlightSearchDateRange>
  isChangeAirportAllowed: Scalars['Boolean']
  isChangeCabinAllowed: Scalars['Boolean']
  isChangeCarrierAllowed: Scalars['Boolean']
  isChangeFareTypeAllowed: Scalars['Boolean']
}

export type TripDetailsFlightLeg = {
  arrivalDateTime?: Maybe<Scalars['String']>
  departureDateTime?: Maybe<Scalars['String']>
  distance?: Maybe<Distance>
  fareRestrictions?: Maybe<FareRestrictions>
  /** @deprecated Use stops instead. */
  flightStops?: Maybe<Scalars['Int']>
  flightTime?: Maybe<Duration>
  isRoundTripCost?: Maybe<Scalars['Boolean']>
  isRoundTripLeg?: Maybe<Scalars['Boolean']>
  isSameCarrierName?: Maybe<Scalars['Boolean']>
  legId?: Maybe<Scalars['ID']>
  pnrNumber?: Maybe<Scalars['String']>
  policy?: Maybe<Policy>
  rate?: Maybe<Rate>
  recordLocator?: Maybe<Scalars['String']>
  segmentState?: Maybe<SegmentState>
  segments?: Maybe<Array<TripDetailsFlightLegSegment>>
  serviceFee?: Maybe<Rate>
  /** @deprecated Use segmentState instead. */
  state?: Maybe<SegmentState>
  stops: Scalars['Int']
  taxes?: Maybe<Rate>
  totalCost?: Maybe<Rate>
  totalWithUnusedTicket?: Maybe<Rate>
  transactionFees?: Maybe<Rate>
  traveler?: Maybe<TripDetailsTraveler>
  unusedTicketRate?: Maybe<Rate>
}

export type TripDetailsFlightLegSegment = {
  actualArrivalDate?: Maybe<Scalars['String']>
  actualDepartureDate?: Maybe<Scalars['String']>
  amenities?: Maybe<FlightLegSegmentAmenities>
  arrival?: Maybe<AirportAddress>
  arrivalDate?: Maybe<Scalars['String']>
  arrivalGate?: Maybe<Scalars['String']>
  baggageClaim?: Maybe<Scalars['String']>
  baggageFees?: Maybe<BaggageFees>
  bookingCode?: Maybe<Scalars['String']>
  bookingProvider?: Maybe<Scalars['String']>
  carrierCode?: Maybe<Scalars['String']>
  checkIn: FlightSegmentCheckIn
  codeshareCarrier?: Maybe<CodeshareCarrier>
  confirmationNumber?: Maybe<Scalars['String']>
  departure?: Maybe<AirportAddress>
  departureDate?: Maybe<Scalars['String']>
  departureGate?: Maybe<Scalars['String']>
  destinationTerminal?: Maybe<Scalars['String']>
  distance?: Maybe<Distance>
  fare?: Maybe<Scalars['String']>
  flightNumber?: Maybe<Scalars['Int']>
  flightStatus?: Maybe<Scalars['String']>
  flightTime?: Maybe<Duration>
  /** @deprecated Use images.square instead. */
  imageUrl: Scalars['String']
  images: TripDetailsFlightLegSegmentImages
  isDepartAirportDifferentFromConnecting?: Maybe<Scalars['Boolean']>
  isSeatAvailable?: Maybe<Scalars['Boolean']>
  isSeatSelectable?: Maybe<Scalars['Boolean']>
  mealService?: Maybe<Array<MealType>>
  membership?: Maybe<Array<CheckoutInfoAirMembershipData>>
  name?: Maybe<Scalars['String']>
  operatedBy?: Maybe<Scalars['String']>
  originTerminal?: Maybe<Scalars['String']>
  penaltyFees?: Maybe<Array<PenaltyFee>>
  percentageOnTime?: Maybe<Scalars['Int']>
  plane?: Maybe<Plane>
  preferenceAttributes?: Maybe<Preference>
  preferredDescription?: Maybe<Preference>
  segmentId?: Maybe<Scalars['String']>
  serviceClass?: Maybe<SeatmapCabinClass>
  serviceFee?: Maybe<SegmentServiceFee>
  specialRequests?: Maybe<Array<Scalars['String']>>
  /** Represents the status of the trip segment. */
  status?: Maybe<TripStatus>
  travelerId?: Maybe<Scalars['String']>
  travelerSeat?: Maybe<Scalars['String']>
  /** trsItemId is item id corresponding to a segment (i.e. flight, hotel, etc.) after it is synced to TRS. */
  trsItemId?: Maybe<Scalars['ID']>
}

export type TripDetailsFlightLegSegmentImages = {
  rectangle?: Maybe<Scalars['String']>
  square?: Maybe<Scalars['String']>
}

export type TripDetailsHotelMembershipData = {
  /** List of hotel chains to which the membership applies. */
  appliesToChains: Array<Scalars['String']>
  /** ID of the hotel membership. */
  id: Scalars['String']
  /** Number associated with the hotel membership. */
  number: Scalars['String']
  /** Vendor code of the hotel membership. */
  vendorCode: Scalars['String']
  /** Vendor name of the hotel membership. */
  vendorName?: Maybe<Scalars['String']>
}

export type TripDetailsHotelSegment = {
  address?: Maybe<Address>
  averageRate?: Maybe<Rate>
  cancellationPolicy: CancellationPolicy
  cancellationPolicyDescription?: Maybe<Array<Scalars['String']>>
  checkIn?: Maybe<Scalars['String']>
  checkOut?: Maybe<Scalars['String']>
  confirmationNumber?: Maybe<Scalars['String']>
  dailyRates?: Maybe<Array<DailyRate>>
  feesAndTaxes?: Maybe<Money>
  hasDeposit?: Maybe<Scalars['Boolean']>
  hotelId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  imageUrl?: Maybe<Scalars['String']>
  isCancellable?: Maybe<Scalars['Boolean']>
  isReserved?: Maybe<Scalars['Boolean']>
  membership?: Maybe<Array<TripDetailsHotelMembershipData>>
  name?: Maybe<Scalars['String']>
  numberOfGuests?: Maybe<NumberOfGuests>
  numberOfNights: Scalars['Int']
  phone?: Maybe<Scalars['String']>
  photos?: Maybe<Array<Photo>>
  policy?: Maybe<Policy>
  preferredDescription?: Maybe<Preference>
  rateQualifier?: Maybe<Scalars['String']>
  recordLocator?: Maybe<Scalars['String']>
  roomDescription?: Maybe<Scalars['String']>
  roomType?: Maybe<RoomType>
  rulesAndRestrictions?: Maybe<Array<Scalars['String']>>
  serviceFeeRate?: Maybe<Money>
  source?: Maybe<Scalars['String']>
  specialRequests?: Maybe<Array<Scalars['String']>>
  starRating?: Maybe<Scalars['Float']>
  /** Represents the status of the trip segment. */
  status?: Maybe<TripStatus>
  totalCost?: Maybe<Money>
  traveler?: Maybe<TripDetailsTraveler>
  /** trsItemId is item id corresponding to a segment (i.e. flight, hotel, etc.) after it is synced to TRS. */
  trsItemId?: Maybe<Scalars['ID']>
}

/** Provides the input parameters for the tripDetails query. */
export type TripDetailsInput = {
  /** Segment associated with the trip (air, hotel, car rental, etc.). */
  domain?: InputMaybe<ReservationDomainType>
  /** Unique identifer for a given trip associated with the user. */
  tripId: Scalars['ID']
}

export type TripDetailsPostReservation = {
  entireFlightActions?: Maybe<TripDetailsFlightActions>
  isAddCarRentalAllowed?: Maybe<Scalars['Boolean']>
  isAddFlightAllowed?: Maybe<Scalars['Boolean']>
  isAddHotelAllowed?: Maybe<Scalars['Boolean']>
  isBookAgainTripAllowed?: Maybe<Scalars['Boolean']>
  isCancelTripAllowed?: Maybe<Scalars['Boolean']>
  isCarRentalCancelAllowed?: Maybe<Scalars['Boolean']>
  isCarRentalChangeAllowed?: Maybe<Scalars['Boolean']>
  isCarServiceCancelAllowed?: Maybe<Scalars['Boolean']>
  isCarServiceChangeAllowed?: Maybe<Scalars['Boolean']>
  isChangeTripAllowed?: Maybe<Scalars['Boolean']>
  isFlightCancelAllowed?: Maybe<Scalars['Boolean']>
  isFlightChangeAllowed?: Maybe<Scalars['Boolean']>
  isFlightChangeCarrierAllowed?: Maybe<Scalars['Boolean']>
  isFlightChangeDestinationAllowed?: Maybe<Scalars['Boolean']>
  /** @deprecated use returnFlightActions instead. */
  isFlightChangeReturnAllowed?: Maybe<Scalars['Boolean']>
  /** @deprecated use returnFlightActions instead. */
  isFlightChangeReturnCarrierAllowed?: Maybe<Scalars['Boolean']>
  isHotelCancelAllowed?: Maybe<Scalars['Boolean']>
  isHotelChangeAllowed?: Maybe<Scalars['Boolean']>
  isTrainCancelAllowed?: Maybe<Scalars['Boolean']>
  isTrainChangeAllowed?: Maybe<Scalars['Boolean']>
  returnFlightActions?: Maybe<TripDetailsFlightActions>
}

export type TripDetailsPostReservationV2 = {
  allowAddCar?: Maybe<Scalars['Boolean']>
  allowAddFlight?: Maybe<Scalars['Boolean']>
  allowAddHotel?: Maybe<Scalars['Boolean']>
  allowBookAgain?: Maybe<Scalars['Boolean']>
  allowCancel?: Maybe<Scalars['Boolean']>
  allowChange?: Maybe<Scalars['Boolean']>
  segmentChangeActions?: Maybe<SegmentChangeActions>
}

export type TripDetailsPrepaidCost = {
  carRental?: Maybe<TripCost>
  flight?: Maybe<Array<TripCost>>
  hotel?: Maybe<TripCost>
  train?: Maybe<Array<TripCost>>
}

export type TripDetailsReservedCost = {
  carRental?: Maybe<TripCost>
  carService?: Maybe<TripCost>
  hotel?: Maybe<TripCost>
}

export type TripDetailsReturnFlightSearchDateRange = {
  end: Scalars['String']
  start: Scalars['String']
}

/** Ride hail information represented in trip detail segment */
export type TripDetailsRideHailSegment = {
  bookingId: Scalars['String']
  /** Booking type (Reserve, On-demand) */
  bookingType?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  dropOffAddress?: Maybe<Scalars['String']>
  dropOffTime?: Maybe<Scalars['String']>
  /** @deprecated Use estimatedPriceRange. */
  estimatedPrice?: Maybe<Rate>
  estimatedPriceRange?: Maybe<RateRange>
  pickUpAddress?: Maybe<Scalars['String']>
  pickUpTime?: Maybe<Scalars['String']>
  price?: Maybe<Rate>
  productName?: Maybe<Scalars['String']>
  status?: Maybe<TripStatus>
  /** @deprecated Detail information comes from RHSS but TRS. */
  trsOrderId?: Maybe<Scalars['String']>
}

export type TripDetailsSegment = {
  carRental?: Maybe<TripDetailsCarRentalSegment>
  carService?: Maybe<TripDetailsCarServiceSegment>
  flightLeg?: Maybe<TripDetailsFlightLeg>
  hotel?: Maybe<TripDetailsHotelSegment>
  position?: Maybe<Scalars['Int']>
  rideHail?: Maybe<TripDetailsRideHailSegment>
  trainLeg?: Maybe<TripDetailsTrainLeg>
  type?: Maybe<SegmentType>
}

export type TripDetailsTrainLeg = {
  arrivalDateTime: Scalars['DateTime']
  arrivalStationCode?: Maybe<Scalars['String']>
  /** Fees for booking */
  bookingFees?: Maybe<Rate>
  collectionReferences?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Fees for delivery */
  deliveryFees?: Maybe<Rate>
  departureDateTime: Scalars['DateTime']
  departureStationCode?: Maybe<Scalars['String']>
  /** Fare information about the train leg */
  fare: TripDetailsTrainLegFare
  /** @deprecated Use `fare` instead */
  fareConditions?: Maybe<Array<Maybe<FareConditions>>>
  /** Fees contains all fees for journey */
  fees?: Maybe<Array<Maybe<Fee>>>
  /** Identifier of the train leg. */
  id?: Maybe<Scalars['String']>
  /** Fees for payment */
  paymentFees?: Maybe<Rate>
  permittedDestinationStations: Array<RailFarePermittedStation>
  permittedOriginStations: Array<RailFarePermittedStation>
  platform?: Maybe<Scalars['String']>
  railCards?: Maybe<Array<RailCard>>
  /** @deprecated Use `fare` instead */
  rate?: Maybe<Rate>
  recordLocator?: Maybe<Scalars['String']>
  returnValidity?: Maybe<Scalars['String']>
  routeType?: Maybe<RouteType>
  rulesAndRestrictions?: Maybe<Array<Scalars['String']>>
  segments?: Maybe<Array<TripDetailsTrainSegment>>
  selectedDeliveryOption?: Maybe<TripDeliveryOption>
  taxes?: Maybe<Rate>
  totalCost?: Maybe<Rate>
  totalJourneyTime?: Maybe<Duration>
  transactionFees?: Maybe<Rate>
  transactionId?: Maybe<Scalars['String']>
  travelStops: Scalars['Int']
  travelTime?: Maybe<Duration>
  traveler?: Maybe<TripDetailsTraveler>
}

export type TripDetailsTrainLegFare = {
  /** The terms and conditions associated with this fare. */
  fareConditions: Array<FareConditions>
  /** Indicates whether this fare is refundable */
  isRefundable: Scalars['Boolean']
  /** Per passenger fare inclusive of all fees. This value represents the price for a rail leg. */
  rate?: Maybe<Rate>
}

export type TripDetailsTrainSegment = {
  allocatedSeat?: Maybe<Array<TrainSegmentSeatInfo>>
  /** @deprecated Use segmentAmenities */
  amenities?: Maybe<FlightLegSegmentAmenities>
  arrivalDate?: Maybe<Scalars['String']>
  arrivalStationCode?: Maybe<Scalars['String']>
  carrierImage?: Maybe<Scalars['String']>
  /** Name of the carrier providing the rail service for a specific  segment. */
  carrierName?: Maybe<Scalars['String']>
  /** Time duration between previous segment's departure and current's segment arrival */
  changeDuration?: Maybe<TravelTime>
  confirmationNumber?: Maybe<Scalars['String']>
  departureDate?: Maybe<Scalars['String']>
  departureStationCode?: Maybe<Scalars['String']>
  /** @deprecated Field is deprecated */
  destination?: Maybe<Scalars['String']>
  duration?: Maybe<Duration>
  /** Identifier of the train segment. */
  id?: Maybe<Scalars['String']>
  /** @deprecated Use carrierName */
  name?: Maybe<Scalars['String']>
  segmentAmenities: Array<TrainSegmentAmenities>
  /** @deprecated Use serviceClassName */
  serviceClass?: Maybe<Scalars['String']>
  /** serviceClassName: Fare service class (Standard or First Class). */
  serviceClassName?: Maybe<Scalars['String']>
  stations?: Maybe<TripDetailsTrainStations>
  /** Represents the status of the trip segment. */
  status?: Maybe<TripStatus>
  /** trainId: Identifier of the train from train provider. */
  trainId?: Maybe<Scalars['String']>
  trainNumber?: Maybe<Scalars['String']>
  /** Represents the transportation mode between the rail legs. */
  transportationMode?: Maybe<RailTransportationMode>
  type?: Maybe<Scalars['String']>
}

export type TripDetailsTrainStation = {
  additionalInformation?: Maybe<Scalars['String']>
  countryName?: Maybe<Scalars['String']>
  isBusStop: Scalars['Boolean']
  location?: Maybe<Address>
  phoneNumber?: Maybe<Scalars['String']>
  stationCode?: Maybe<Scalars['String']>
  stationName?: Maybe<Scalars['String']>
  ticketMachineAvailable?: Maybe<TriStateBoolean>
  ticketOfficeSchedule?: Maybe<Array<TripDetailsTrainStationSchedule>>
  ticketPickupInformation?: Maybe<Scalars['String']>
}

export type TripDetailsTrainStationSchedule = {
  closeTime: Scalars['String']
  endingOnDayOfWeek: Scalars['Int']
  openTime: Scalars['String']
  startingOnDayOfWeek: Scalars['Int']
}

export type TripDetailsTrainStations = {
  arrival?: Maybe<TripDetailsTrainStation>
  departure?: Maybe<TripDetailsTrainStation>
}

export type TripDetailsTraveler = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  /** Traveler middle name */
  middleName?: Maybe<Scalars['String']>
}

export type TripDetailsTripCostSummary = {
  allowUnusedTicketOptout?: Maybe<Scalars['Boolean']>
  fareTaxInfo?: Maybe<Array<FareTaxInfo>>
  prepaid?: Maybe<TripDetailsPrepaidCost>
  reserved?: Maybe<TripDetailsReservedCost>
  total?: Maybe<Rate>
  totalPaymentFees?: Maybe<Rate>
  totalServiceFees?: Maybe<Rate>
  totalTaxCost?: Maybe<Rate>
  totalTripBaseCost?: Maybe<Rate>
  totalWithUnusedTicket?: Maybe<Rate>
  transactionFees?: Maybe<Rate>
  unusedTicket?: Maybe<Rate>
}

export type TripHoldRules = {
  /** This field indicates when the trip will be auto-cancelled after placing it on hold. */
  autoCancellationTime?: Maybe<Scalars['String']>
  holdAllowed?: Maybe<Scalars['Boolean']>
  reasonsForNotAllowing?: Maybe<Array<Scalars['String']>>
}

export type TripInput = {
  cityImageFormat?: InputMaybe<ImageFormat>
  companyId?: InputMaybe<Scalars['Int']>
  id: Scalars['String']
  isCityImageRequered?: InputMaybe<Scalars['Boolean']>
  transactionGroupId: Scalars['String']
  type?: InputMaybe<TripType>
}

export type TripMeetingInfoDto = {
  /** Information of the meeting id */
  id: Scalars['String']
  /** Information of the meeting name */
  name: Scalars['String']
}

/** Structured error response to indicate the requested trip was not found. */
export type TripNotFoundResponse = Error & {
  _uselessFieldForNest?: Maybe<Scalars['String']>
  /** Provides additional details about the error response. */
  message: Scalars['String']
}

export type TripPostReservation = {
  areExtraSegmentsAllowed: Scalars['Boolean']
  areSegmentsChangeable: Scalars['Boolean']
  areSegmentsCustomizable: Scalars['Boolean']
  areSegmentsRemovable: Scalars['Boolean']
  areTicketOptionsAllowed: Scalars['Boolean']
  isChangeTripAllowed: Scalars['Boolean']
  isHoldTripAllowed: Scalars['Boolean']
}

export enum TripStatus {
  ActionRequired = 'ACTION_REQUIRED',
  AutoCancelled = 'AUTO_CANCELLED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Reserved = 'RESERVED',
  Ticketed = 'TICKETED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
  WaitListed = 'WAIT_LISTED',
}

export enum TripStatusType {
  Canceled = 'CANCELED',
  Past = 'PAST',
  Unspecified = 'UNSPECIFIED',
  Upcoming = 'UPCOMING',
}

/** TripSummary provides the summary for a given trip associated with the user. */
export type TripSummary = {
  /** Unique list of domains in which reservations for the trip exist. */
  domains: Array<Domain>
  /** The end date of the trip. Format: YYYY-MM-DD in local TZ. */
  endDate: Scalars['String']
  /** Unique identifer for the trip whose summary is returned. */
  id: Scalars['ID']
  /** The start date of the trip. Format: YYYY-MM-DD in local TZ. */
  startDate: Scalars['String']
  /** Title of the trip. */
  title: Scalars['String']
}

export enum TripType {
  Cancelled = 'CANCELLED',
  OnHold = 'ON_HOLD',
  Past = 'PAST',
  Upcoming = 'UPCOMING',
}

export type UberError = {
  message: Scalars['String']
  type: UberErrorType
  uberErrorCode?: Maybe<UberErrorCode>
  uberHTTPStatusCode?: Maybe<Scalars['Int']>
}

export enum UberErrorCode {
  BadRequest = 'BAD_REQUEST',
  DestinationNotAllowed = 'DESTINATION_NOT_ALLOWED',
  DistanceExceeded = 'DISTANCE_EXCEEDED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  NoProductFound = 'NO_PRODUCT_FOUND',
  OutsideServiceArea = 'OUTSIDE_SERVICE_AREA',
  PickupNotAllowed = 'PICKUP_NOT_ALLOWED',
  RateLimited = 'RATE_LIMITED',
  SamePickupDropoff = 'SAME_PICKUP_DROPOFF',
  TripExists = 'TRIP_EXISTS',
  Undefined = 'UNDEFINED',
  UserNotAllowed = 'USER_NOT_ALLOWED',
}

export enum UberErrorType {
  UberAuthError = 'UBER_AUTH_ERROR',
  UberEstimatesError = 'UBER_ESTIMATES_ERROR',
}

export type UberEstimateDropOffInput = {
  address?: InputMaybe<Scalars['String']>
  geocode?: InputMaybe<GeocodeInput>
}

export type UberEstimateInput = {
  dropOff: UberEstimateDropOffInput
  pickUp: UberEstimatePickUpInput
}

export type UberEstimatePickUpInput = {
  address?: InputMaybe<Scalars['String']>
  geocode?: InputMaybe<GeocodeInput>
  time: Scalars['Int']
}

export type UberEstimateResult = {
  error?: Maybe<UberError>
  productEstimates?: Maybe<Array<UberProductEstimate>>
}

export type UberProduct = {
  backgroundImage: Scalars['String']
  capacity: Scalars['Int']
  description: Scalars['String']
  displayName: Scalars['String']
  image: Scalars['String']
  parentProductTypeId: Scalars['String']
  productId: Scalars['String']
  reserveInfo: UberProductReserveInfo
  schedulingEnabled: Scalars['Boolean']
  shared: Scalars['Boolean']
  shortDescription: Scalars['String']
  upfrontFareEnabled: Scalars['Boolean']
  vehicleViewId: Scalars['Int']
}

export type UberProductEstimate = {
  estimateInfo: UberProductEstimateInfo
  product: UberProduct
  seatCount?: Maybe<Scalars['Int']>
}

export type UberProductEstimateFareBreakdownItem = {
  name: Scalars['String']
  notice?: Maybe<Scalars['String']>
  type: Scalars['String']
  value: Scalars['Float']
}

export type UberProductEstimateFareInfo = {
  currencyCode: Scalars['String']
  display: Scalars['String']
  expiresAt: Scalars['Int']
  fareBreakdown: Array<UberProductEstimateFareBreakdownItem>
  fareId: Scalars['String']
  surgeMultiplier: Scalars['Float']
  value: Scalars['String']
}

export type UberProductEstimateInfo = {
  estimate?: Maybe<UberProductEstimateInfoEstimate>
  fare?: Maybe<UberProductEstimateFareInfo>
  fareId: Scalars['String']
  noCarsAvailable?: Maybe<Scalars['Boolean']>
  pricingExplanation?: Maybe<Scalars['String']>
  trip: UberProductEstimateTripInfo
}

export type UberProductEstimateInfoEstimate = {
  currency: Scalars['String']
  display: Scalars['String']
  highEstimate: Scalars['Int']
  lowEstimate: Scalars['Int']
}

export type UberProductEstimateTripInfo = {
  durationEstimate?: Maybe<Scalars['Int']>
}

export type UberProductReserveInfo = {
  enabled: Scalars['Boolean']
  freeCancellationThresholdMinutes: Scalars['Int']
  scheduledThresholdMinutes: Scalars['Int']
}

/** The response that is returned on some queries when they encounter unexpected error */
export type UnexpectedError = {
  /** LCID that can be used to track the error in Splunk. Will be present if received by the client */
  lcid?: Maybe<Scalars['String']>
  /** The message of the caught error */
  message: Scalars['String']
}

export type UniversalTicketAttribute = {
  assessment: OnTheFlyContentAssessment
  bagLimits?: Maybe<UniversalTicketAttributeBagLimits>
  callToAction?: Maybe<UniversalTicketAttributeCallToAction>
  category: Scalars['String']
  description: Scalars['String']
  feeRange?: Maybe<Array<UniversalTicketAttributeFeeRange>>
  headline: Scalars['String']
}

export type UniversalTicketAttributeBagLimits = {
  sizeLinearCm: Scalars['Float']
  weightKg: Scalars['Float']
}

export type UniversalTicketAttributeCallToAction = {
  text: Scalars['String']
  url: Scalars['String']
}

export type UniversalTicketAttributeFeeRange = {
  amount: UniversalTicketAttributeFeeRangeAmount
  currency: Scalars['String']
}

export type UniversalTicketAttributeFeeRangeAmount = {
  max: Scalars['Float']
  min: Scalars['Float']
}

export type UnusedTicket = {
  airline?: Maybe<Decode>
  airlineCode?: Maybe<Scalars['String']>
  changeFee?: Maybe<ChangeFee>
  expirationDate?: Maybe<Scalars['String']>
  originalCost?: Maybe<OriginalCost>
  recordLocator?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  ticketNumber?: Maybe<Scalars['String']>
  unvalidated?: Maybe<UnvalidatedTicket>
  validated?: Maybe<ValidatedTicket>
}

export type UnusedTicketDecode = {
  decode?: Maybe<Array<Maybe<Decode>>>
  type?: Maybe<Scalars['String']>
}

export type UnusedTicketInput = {
  itineraryId: Scalars['String']
}

export type UnusedTicketResponse = {
  decodes?: Maybe<Array<Maybe<UnusedTicketDecode>>>
  unusedTickets: Array<Maybe<UnusedTicket>>
}

export type UnvalidatedTicket = {
  addCollect?: Maybe<Rate>
  exchangedTicket?: Maybe<Rate>
  forfeiture?: Maybe<Rate>
  newTicket?: Maybe<Rate>
  original?: Maybe<Rate>
  penalty?: Maybe<Rate>
  residual?: Maybe<Rate>
  total: Rate
}

export type UpcomingCarSegment = {
  airConditioning: Scalars['String']
  carType: Scalars['String']
  confirmationNumber?: Maybe<Scalars['String']>
  endDateTime: Scalars['Date']
  endLocation: SegmentLocation
  extraDayCharge?: Maybe<Scalars['String']>
  extraHourCharge?: Maybe<Scalars['String']>
  isOutOfPolicy: Scalars['Boolean']
  providerDetails: ProviderDetails
  reservationId: Scalars['String']
  startDateTime: Scalars['Date']
  startLocation: SegmentLocation
  stringifiedCarKey: Scalars['String']
  transmission: Scalars['String']
  txDetails: TransactionDetails
}

export type UpcomingDetails = {
  beginDate: Scalars['String']
  cancellable?: Maybe<Scalars['Boolean']>
  carSegment?: Maybe<Array<UpcomingCarSegment>>
  changeable?: Maybe<Scalars['Boolean']>
  endDate: Scalars['String']
  firstName: Scalars['String']
  flightSegment?: Maybe<Array<UpcomingFlightSegment>>
  hotelSegment?: Maybe<Array<UpcomingHotelSegment>>
  lastName: Scalars['String']
  name: Scalars['String']
}

export type UpcomingFlightSegment = {
  airlineName: Scalars['String']
  checkinURL?: Maybe<Scalars['String']>
  checkinWindowHours?: Maybe<Scalars['Int']>
  confirmationNumber?: Maybe<Scalars['String']>
  distance: Scalars['Int']
  distanceUnits?: Maybe<Scalars['String']>
  endDateTime: Scalars['Date']
  endLocation?: Maybe<SegmentLocation>
  flightDetail: FlightDetail
  flightIndexInLeg?: Maybe<Scalars['Int']>
  flightKey: FlightKey
  isPaperlessBoardingSupported?: Maybe<Scalars['Boolean']>
  legId: Scalars['String']
  minutesInAir: Scalars['Int']
  numStops: Scalars['Int']
  onTimePercentage?: Maybe<Scalars['Float']>
  operatingAirlineName?: Maybe<Scalars['String']>
  providerDetails?: Maybe<ProviderDetails>
  reservationId: Scalars['String']
  seatAssignment: Scalars['String']
  serviceClass: Scalars['String']
  startDateTime: Scalars['Date']
  startLocation?: Maybe<SegmentLocation>
  txDetails?: Maybe<TransactionDetails>
}

export type UpcomingHotelSegment = {
  cancelationPolicy: Scalars['String']
  confirmationNumber?: Maybe<Scalars['String']>
  endDateTime: Scalars['Date']
  isOutOfPolicy: Scalars['Boolean']
  providerDetails: ProviderDetails
  reservationId: Scalars['String']
  roomDetails: Scalars['String']
  startDateTime: Scalars['Date']
  startLocation: SegmentLocation
  stringifiedHotelKey: Scalars['String']
  txDetails: TransactionDetails
}

export type UpdateAdditionalInformationCheckBoxInput = {
  checkBoxValue: Scalars['Boolean']
}

export type UpdateAdditionalInformationDateTimeInput = {
  /** ISO 8601 string w/o time information, if provided. */
  dateTimeValue: Scalars['String']
}

export type UpdateAdditionalInformationInput = {
  checkBoxProperties?: InputMaybe<UpdateAdditionalInformationCheckBoxInput>
  dateTimeProperties?: InputMaybe<UpdateAdditionalInformationDateTimeInput>
  /** Field Type associated with the additional information field. */
  fieldType: UserProfileCustomFieldType
  id: Scalars['Float']
  listProperties?: InputMaybe<UpdateAdditionalInformationListInput>
  numberProperties?: InputMaybe<UpdateAdditionalInformationNumberInput>
  textProperties?: InputMaybe<UpdateAdditionalInformationTextInput>
}

export type UpdateAdditionalInformationListInput = {
  listValue: Scalars['String']
}

export type UpdateAdditionalInformationNumberInput = {
  numberValue: Scalars['Float']
}

export type UpdateAdditionalInformationTextInput = {
  textValue: Scalars['String']
}

export type UpdateAuthorizersInput = {
  /** Authorizers input for profile update */
  authorizers: Array<InputMaybe<AuthorizersDetailsInput>>
}

export enum UpdateCarRentalClassType {
  Compact = 'COMPACT',
  CompactElite = 'COMPACT_ELITE',
  Economy = 'ECONOMY',
  EconomyElite = 'ECONOMY_ELITE',
  Fullsize = 'FULLSIZE',
  FullsizeElite = 'FULLSIZE_ELITE',
  Intermediate = 'INTERMEDIATE',
  IntermediateElite = 'INTERMEDIATE_ELITE',
  Luxury = 'LUXURY',
  LuxuryElite = 'LUXURY_ELITE',
  Mini = 'MINI',
  MiniElite = 'MINI_ELITE',
  None = 'NONE',
  Oversize = 'OVERSIZE',
  Premium = 'PREMIUM',
  PremiumElite = 'PREMIUM_ELITE',
  Special = 'SPECIAL',
  Standard = 'STANDARD',
  StandardElite = 'STANDARD_ELITE',
}

export type UpdateDelegatedIdCookieInput = {
  id?: InputMaybe<Scalars['Int']>
}

export type UpdateDelegatedIdCookieResponse = {
  success: Scalars['Boolean']
}

export type UpdateDelegatedIdTokenInput = {
  id?: InputMaybe<Scalars['Int']>
  token: Scalars['String']
}

export type UpdateDelegatedIdTokenResponse = {
  token: Scalars['String']
}

export type UpdateDelegatesInput = {
  /** Delegates input for profile update */
  delegates: Array<InputMaybe<DelegatesDetailsInput>>
}

export type UpdateMeetingIdCookieInput = {
  id?: InputMaybe<Scalars['String']>
}

export type UpdateMeetingIdCookieResponse = {
  success: Scalars['Boolean']
}

export type UpdateNationalIdCardInput = {
  /** Contains user's national ID details. */
  nationalIdCard: NationalIdCardInput
}

export type UpdateNationalIdCardResponse =
  | BaseResponse
  | InvalidInputError
  | NoPassportError
  | UnexpectedError

export type UpdatePassportInput = {
  /** Contains user's passport details. */
  passport: PassportInput
}

export type UpdatePassportResponse =
  | BaseResponse
  | InvalidInputError
  | NoPassportError
  | UnexpectedError

export type UpdateProfileBusinessAddressInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfileContactInformationInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfileEmergencyContactInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfileEmployeeInformationInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfileHomeAddressInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfilePersonalInformationInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateProfileTravelPreferencesInput = {
  additionalInformation: Array<UpdateAdditionalInformationInput>
}

export type UpdateTokensInput = {
  token: Scalars['String']
}

export type UpdateTokensResponse = {
  token: Scalars['String']
}

export type UpdateTripNameByProcessIdInput = {
  name: Scalars['String']
  processId: Scalars['String']
}

export type UpdateTripNameInput = {
  itineraryId: Scalars['String']
  name: Scalars['String']
}

export type UpdateVisaInput = {
  /** Contains details supporting long stay or short travel visas. */
  visa: VisaInput
}

export type UpdateVisaResponse = BaseResponse | UnexpectedError | UpdateVisaResponseDetails

export type UpdateVisaResponseDetails = {
  id?: Maybe<Scalars['String']>
}

export type UpsertMeetingInfoInput = {
  meetingId: Scalars['String']
}

export type UpsertMeetingInfoResponse = {
  /** True, if the participant is a delegator. */
  isMeetingModeDelegate: Scalars['Boolean']
  /** Information of the meeting. */
  meeting: MeetingInfo
  /** Information of the meeting participant. */
  participant: ParticipantInfo
}

export type UpsertMeetingInfoResponseUnion = UnexpectedError | UpsertMeetingInfoResponse

export type User = {
  companyId: Scalars['Int']
  currency?: Maybe<Scalars['String']>
  customerId: Scalars['Int']
  delegatedId?: Maybe<Scalars['Int']>
  impersonatedId?: Maybe<Scalars['Int']>
  locale?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  partnerId?: Maybe<Scalars['Int']>
  profile?: Maybe<Profile>
  /** Identifies the current session after logging in. Always has UUID format. */
  sessionId?: Maybe<Scalars['String']>
  sessionTimeout: Scalars['Int']
  trustedLocale?: Maybe<Scalars['String']>
}

/** Indicates the method of user creation */
export enum UserCreationSource {
  AdminUser = 'ADMIN_USER',
  Batch = 'BATCH',
  EndUser = 'END_USER',
  InboundSsoSync = 'INBOUND_SSO_SYNC',
  OpenEnrollment = 'OPEN_ENROLLMENT',
  SsoPartner = 'SSO_PARTNER',
  Webservice = 'WEBSERVICE',
}

export type UserCreditCard = {
  /** Credit card billing address */
  billingAddress?: Maybe<UserCreditCardBillingAddress>
  /** Credit card brand type. */
  brandType?: Maybe<CreditCardBrandType>
  /** Credit card number */
  cardNumber?: Maybe<Scalars['String']>
  /** Last 4 digits of credit card number. */
  cardNumberLast4?: Maybe<Scalars['String']>
  /** Expiration date for credit card. */
  expirationDate?: Maybe<Scalars['String']>
  /** Full alpha-numeric string ID. */
  fullLegacyId?: Maybe<Scalars['String']>
  /** User defined label for credit card. */
  label?: Maybe<Scalars['String']>
  /** Numeric string extracted from Gazoo's full alpha-numeric string ID. */
  legacyId?: Maybe<Scalars['String']>
  /** User defined name on the credit card. */
  name?: Maybe<Scalars['String']>
  /** Credit card security code */
  securityCode?: Maybe<Scalars['String']>
}

export type UserCreditCardBillingAddress = {
  /** -Municipality- equivalent of billing address. */
  city?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  /** -Principality- equivalent of credit card billing address. */
  stateCode?: Maybe<Scalars['String']>
  /** -addressLine1- equivalent of billing address. */
  street1?: Maybe<Scalars['String']>
  /** -addressLine2- equivalent of billing address. */
  street2?: Maybe<Scalars['String']>
}

/** Simple User object wich used in delegates and authorizers lists. */
export type UserDelegate = {
  /** User email */
  email?: Maybe<Scalars['String']>
  /** User first name */
  firstName?: Maybe<Scalars['String']>
  /** Server-provisioned ID, unique across all users */
  id?: Maybe<Scalars['Float']>
  /** User last name */
  lastName?: Maybe<Scalars['String']>
}

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  None = 'NONE',
  Unspecified = 'UNSPECIFIED',
}

export type UserInput = {
  /** Additional documents preferences */
  additionalDocumentsPreferences?: InputMaybe<AdditionalDocumentsPreferencesInput>
  /** Approver Ids input for a user for the different app services within Deem@Work */
  approvers?: InputMaybe<ApproversInput>
  /** Authorization input for profile update */
  authorization?: InputMaybe<Authorization>
  /** Authorizers input for profile update */
  authorizers?: InputMaybe<DelegatesOrAuthorizersInput>
  /** Business address object input with location fields */
  businessAddress?: InputMaybe<PersonalAddress>
  companyId: Scalars['Int']
  /** Confirmation email input used to send confirmation emails */
  confEmail?: InputMaybe<Scalars['String']>
  /** User country */
  country?: InputMaybe<Scalars['String']>
  /** Credit cards data input */
  creditCards?: InputMaybe<Array<CreditCardsInput>>
  /** Preferred currency of user */
  currency?: InputMaybe<Scalars['String']>
  customerId: Scalars['Int']
  /** Preferred dateFormat of user */
  dateFormat?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  /** Delegates input for profile update */
  delegates?: InputMaybe<DelegatesOrAuthorizersInput>
  email?: InputMaybe<Scalars['String']>
  /** Update of emergency contact field in profile */
  emergencyContact?: InputMaybe<EmergencyContactInput>
  employee?: InputMaybe<EmployeeInput>
  /** Fax phone object input related to business address */
  faxPhone?: InputMaybe<PersonalPhone>
  firstName: Scalars['String']
  /** This field is deprecated: use genderWithNone instead */
  gender?: InputMaybe<Gender>
  genderWithNone?: InputMaybe<UserGender>
  /** Groupware object input containing groupware ID */
  groupware?: InputMaybe<GroupwareInput>
  homeEmail?: InputMaybe<Scalars['String']>
  /** Home phone object input related to personal contact containing phone number and country code */
  homePhone?: InputMaybe<PersonalPhone>
  homePhoneExt?: InputMaybe<Scalars['String']>
  homePhoneNumber?: InputMaybe<Scalars['String']>
  /** Preferred hourlyTimeFormat of user */
  hourlyTimeFormat?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  /** Preferred locale of user */
  locale?: InputMaybe<Scalars['String']>
  /** Preferred measurementUnit of user */
  measurementUnit?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
  /** Generic management information system (MIS) reporting fields */
  mis?: InputMaybe<MisInput>
  /** Deprecated: Use mis fields 1 to 7 instead. */
  miscellaneousFields?: InputMaybe<MiscellaneousFields>
  /** Mobile phone object input related to business contact containing phone number and country code */
  mobilePhone?: InputMaybe<PersonalPhone>
  mobilePhoneExt?: InputMaybe<Scalars['String']>
  mobilePhoneNumber?: InputMaybe<Scalars['String']>
  /** Array of notification objects used to enable SMS and Email notifications for user */
  notification?: InputMaybe<Array<NotificationInput>>
  /** Office phone object input related to business address containing phone number and country code */
  officePhone?: InputMaybe<PersonalPhone>
  personalAddress?: InputMaybe<PersonalAddress>
  phone?: InputMaybe<Scalars['String']>
  /** Preferred credit cards data input */
  preferredCreditCards?: InputMaybe<Array<PreferredCreditCardsInput>>
  /** Profile pin input used in partner contexts and whose meaning is partner-specific */
  profilePin?: InputMaybe<Scalars['String']>
  suffix?: InputMaybe<UserSuffix>
  /** Preferred timeZone of user */
  timeZone?: InputMaybe<Scalars['String']>
  title?: InputMaybe<UserTitle>
  /** Username input used for enterprise login w/o SSO */
  username?: InputMaybe<Scalars['String']>
  welcomeMsgLastShown?: InputMaybe<Scalars['String']>
}

export type UserProfile = {
  businessAddress: UserProfileBusinessAddress
  /**
   * This field is deprecated: Information added to business address field
   * @deprecated Information added to business address field
   */
  contactInformation: UserProfileContactInformation
  emergencyContact: UserProfileEmergencyContact
  employeeInformation: UserProfileEmployeeInformation
  homeAddress: UserProfileHomeAddress
  personalInformation: UserProfilePersonalInformation
  travelPreferences: UserProfileTravelPreferences
}

export type UserProfileBusinessAddress = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserProfileContactInformation = {
  additionalInformation: Array<AdditionalInformation>
}

export enum UserProfileCustomFieldType {
  CheckBox = 'CHECK_BOX',
  DateTime = 'DATE_TIME',
  List = 'LIST',
  Number = 'NUMBER',
  Text = 'TEXT',
  Unspecified = 'UNSPECIFIED',
}

export type UserProfileEmergencyContact = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserProfileEmployeeInformation = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserProfileHomeAddress = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserProfilePersonalInformation = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserProfileTravelPreferences = {
  additionalInformation: Array<AdditionalInformation>
}

export type UserSeatPreferences = {
  carriageType?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<Scalars['String']>
  facilities?: InputMaybe<Array<Scalars['String']>>
  position?: InputMaybe<Scalars['String']>
}

export type UserSite = {
  isSelfServiceSite: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum UserSuffix {
  Ii = 'II',
  Iii = 'III',
  Iv = 'IV',
  Jr = 'JR',
  None = 'NONE',
  Phd = 'PHD',
  Sr = 'SR',
}

export enum UserTitle {
  Dr = 'DR',
  Master = 'MASTER',
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Mx = 'MX',
  None = 'NONE',
  Prof = 'PROF',
  Sir = 'SIR',
}

/** Provides the input parameters for the userTrips query. */
export type UserTripsInput = {
  /** The number of results to be retrieved. */
  pageSize?: InputMaybe<Scalars['Int']>
  /** The start index from which the results should be retrieved. */
  startIndex?: InputMaybe<Scalars['Int']>
  /** Provides an option to filter trips by status. Defaults to UPCOMING. */
  withStatus?: InputMaybe<TripStatusType>
}

export type ValidatedTicket = {
  addCollect?: Maybe<Rate>
  exchangedTicket: Rate
  forfeiture?: Maybe<Rate>
  newTicket?: Maybe<Rate>
  original?: Maybe<Rate>
  penalty?: Maybe<Rate>
  residual?: Maybe<Rate>
  total: Rate
}

export type Vendor = {
  code: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export enum VendorTravelVertical {
  Air = 'AIR',
  Carrental = 'CARRENTAL',
  Hotel = 'HOTEL',
}

export type VirtualPay = {
  isVirtualPayEnabled: Scalars['Boolean']
  isVirtualPayRestricted: Scalars['Boolean']
}

/** A visa (of any type) that is held by a user. A user can have multiple visas, and visas are compared using the tuple of "country" and "number", both of which are required. */
export type Visa = {
  /** ISO 3166-1 alpha-2 country code */
  country?: Maybe<Scalars['String']>
  /** ISO 8601 string w/o time information */
  expirationDate?: Maybe<Scalars['String']>
  /** Server-provisioned ID, can be used to delete a single visa directly */
  id?: Maybe<Scalars['Float']>
  /** Identifier number of the visa (max length = 50) */
  number?: Maybe<Scalars['String']>
  /** Period prior to expiration date that the user will be alerted, ISO 8601 period format and specified in days only – if unset no alert will be generated */
  preExpirationAlert?: Maybe<Scalars['String']>
  /** Key to a metadata API-based visa type */
  visaType?: Maybe<Scalars['String']>
}

export type VisaInput = {
  /** The country in which the visa was issued. */
  country: Scalars['String']
  /** Visa's expiry date. */
  expirationDate: Scalars['String']
  /** Credit card identifier, used as part of tokenized reference to the credit card. */
  id?: InputMaybe<Scalars['String']>
  /** Visa number. */
  number: Scalars['String']
  /** Expiration Alert of Visa. */
  preExpirationAlert?: InputMaybe<Scalars['String']>
  /** Visa type. For example, Business Visa. */
  visaType: Scalars['String']
}

/** Defines fields required to query voice search. */
export type VoiceSearchInput = {
  prompt: Scalars['String']
}

/** Union of different response objects that can be returned by addModifyToCart mutation. Returns known/expected errors in a structured manner to the client. */
export type AddModifyToCartResponseUnion = AddModifyToCartResponse | CartNotFoundResponse

export enum ChangeType {
  EntireFlight = 'ENTIRE_FLIGHT',
  ReturnLeg = 'RETURN_LEG',
}

export type ZooKeeperFlags = {
  /** Determines if the block basic economy is enabled. */
  blockBasicEconomyEnabled: Scalars['Boolean']
  /** Determines if the tsa hazmat message is disabled. */
  disableTsaHazmatMessageEnabled: Scalars['Boolean']
  /** Determine if the non-stop filter by default is enabled. */
  nonStopFilterByDefaultEnabled: Scalars['Boolean']
  /** Partner cobranding enable. */
  partnerCobrandingEnabled?: Maybe<Scalars['Boolean']>
  /** Determines if the policy indicator is only on the final flight results page. */
  policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled: Scalars['Boolean']
  /** Determines if the remote profile is enabled. */
  remoteProfileEnabled: Scalars['Boolean']
  /** Determines the remote profile message. */
  remoteProfileMessage: Scalars['String']
  /** Determines if the hotel star rating is removed. */
  removeHotelStarRatingEnabled: Scalars['Boolean']
  /** Determines if the notification section on the checkout page is removed. */
  removeNotificationSectionOnTheCheckoutPageEnabled: Scalars['Boolean']
  /** Determines if the per diem link is removed. */
  removePerDiemLinkEnabled?: Maybe<Scalars['Boolean']>
  /** Determines if the southwest check-in email notification is enabled. */
  southwestCheckInEmailNotificationEnabled: Scalars['Boolean']
  /** Determines if the southwest check-in sms notification is enabled. */
  southwestCheckInSmsNotificationEnabled: Scalars['Boolean']
  /** Determines if the tiered cost allocation is enabled. */
  tieredCostAllocationEnabled: Scalars['Boolean']
}

export type BookingInfoFragment = Pick<
  RideHailBooking,
  | 'bookingId'
  | 'description'
  | 'dropOffAddress'
  | 'pickUpAddress'
  | 'pickUpTime'
  | 'productName'
  | 'status'
> & {
  estimatedPriceRange?: Maybe<{
    primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
    secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
  }>
  price?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type PlaceFragment = Pick<
  PlaceAutocomplete,
  | 'placeId'
  | 'airportCode'
  | 'name'
  | 'shortName'
  | 'isHotelAddress'
  | 'latitude'
  | 'longitude'
  | 'placeType'
  | 'locationType'
  | 'uniqueCode'
  | 'isGrouped'
  | 'timezone'
> & {
  address?: Maybe<
    Pick<
      PlaceAutocompleteAddress,
      | 'city'
      | 'countryCode'
      | 'countryName'
      | 'stateCode'
      | 'postalCode'
      | 'street1'
      | 'lat'
      | 'lng'
    >
  >
}

export type CovidStatisticItemFragment = Pick<
  Covid19Statistic,
  | 'name'
  | 'confirmedNewDaily'
  | 'confirmedTotal'
  | 'confirmedFourteenDayTrend'
  | 'deathsNewDaily'
  | 'deathsTotal'
  | 'deathsFourteenDayTrend'
>

export type PolicyTripsFragment = Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
  outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
  outOfPolicyComplianceCodes?: Maybe<
    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
  >
}

export type RateTripsFragment = {
  primary: Pick<Money, 'amount' | 'currency'>
  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
}

export type MoneyTripsFragment = Pick<Money, 'amount' | 'currency'>

export type RateFragment = {
  primary: Pick<Money, 'amount' | 'currency'>
  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
}

export type MoneyFragment = Pick<Money, 'amount' | 'currency'>

export type PrimaryTravelerFragment = {
  travelerFields?: Maybe<
    Pick<
      CheckoutInfoTravelerFields,
      | 'isPrimaryTraveler'
      | 'isTitleChangeable'
      | 'isNameChangeable'
      | 'isDefaultChangeTravelerOptionEnabled'
      | 'isAddressRequired'
      | 'isAddressChangeable'
      | 'isDateOfBirthRequired'
      | 'isDateOfBirthChangeable'
      | 'isGenderRequired'
      | 'isGenderChangeable'
      | 'isPhoneNumberRequired'
      | 'isEMailRequired'
      | 'isFirstNameRequired'
      | 'isMiddleNameRequired'
      | 'isLastNameRequired'
      | 'isSuffixRequired'
      | 'isSuffixChangeable'
      | 'isPhoneNumberChangeable'
      | 'isEMailChangeable'
    >
  >
  data: Pick<
    CheckoutInfoTravelerData,
    | 'id'
    | 'dateOfBirth'
    | 'firstName'
    | 'lastName'
    | 'gender'
    | 'email'
    | 'primary'
    | 'suffix'
    | 'title'
    | 'middleInitial'
  > & {
    phoneNumber: Array<Pick<Phone, 'type' | 'countryCode' | 'number'>>
    address?: Maybe<
      Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    >
    homeAddress?: Maybe<
      Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    >
    passport?: Maybe<
      Pick<
        Passport,
        | 'id'
        | 'number'
        | 'issuingCountry'
        | 'country'
        | 'issueDate'
        | 'expirationDate'
        | 'unmaskedNumber'
        | 'preExpirationAlert'
      >
    >
    passports?: Maybe<
      Array<
        Pick<
          Passport,
          | 'id'
          | 'number'
          | 'issuingCountry'
          | 'country'
          | 'issueDate'
          | 'expirationDate'
          | 'unmaskedNumber'
          | 'preExpirationAlert'
        >
      >
    >
    knownTravelerNumbers?: Maybe<
      Array<
        Pick<CheckoutInfoTravelerKnownTravelerNumber, 'number' | 'countryCode' | 'expirationDate'>
      >
    >
    redressNumbers?: Maybe<Array<Pick<CheckoutInfoTravelerRedressNumber, 'number' | 'countryCode'>>>
  }
}

export type MembershipsFragment = {
  air?: Maybe<{
    data: Array<
      Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode' | 'carrierName'>
    >
    options: Array<Pick<CheckoutInfoAirMembershipOptions, 'carrierCode' | 'carrierName'>>
  }>
  carRental?: Maybe<{
    data: Array<Pick<CheckoutInfoCarMembershipData, 'id' | 'number' | 'vendorCode' | 'vendorName'>>
    options: Array<Pick<CheckoutInfoCarMembershipOptions, 'vendorCode' | 'vendorName'>>
  }>
  hotel?: Maybe<{
    data: Array<
      Pick<
        CheckoutInfoHotelMembershipData,
        'id' | 'appliesToChain' | 'vendorCode' | 'vendorName' | 'number'
      >
    >
    options: Array<Pick<CheckoutInfoHotelMembershipOptions, 'vendorCode' | 'vendorName'>>
  }>
}

export type AirCheckoutInfoMembershipFragment = {
  data: Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode' | 'carrierName'>>
  options: Array<Pick<CheckoutInfoAirMembershipOptions, 'carrierCode' | 'carrierName'>>
}

export type CarRentalCheckoutInfoMembershipFragment = {
  data: Array<Pick<CheckoutInfoCarMembershipData, 'id' | 'number' | 'vendorCode' | 'vendorName'>>
  options: Array<Pick<CheckoutInfoCarMembershipOptions, 'vendorCode' | 'vendorName'>>
}

export type HotelCheckoutInfoMembershipFragment = {
  data: Array<
    Pick<
      CheckoutInfoHotelMembershipData,
      'id' | 'appliesToChain' | 'vendorCode' | 'vendorName' | 'number'
    >
  >
  options: Array<Pick<CheckoutInfoHotelMembershipOptions, 'vendorCode' | 'vendorName'>>
}

export type MealRequestFragment = {
  data?: Maybe<Pick<CheckoutInfoMealRequestData, 'mealName' | 'code' | 'id'>>
  options: Array<Pick<CheckoutInfoMealRequestOption, 'mealName' | 'code'>>
}

export type BillingFragment = Pick<CheckoutInfoBilling, 'isSingleUseCreditCardAllowed'> & {
  air?: Maybe<{
    creditCards: Array<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    siteCards: Array<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
    defaultCard?: Maybe<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    defaultSiteCard?: Maybe<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
  }>
  carRental?: Maybe<{
    creditCards: Array<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    siteCards: Array<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
    defaultCard?: Maybe<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    defaultSiteCard?: Maybe<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
  }>
  hotel?: Maybe<{
    creditCards: Array<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    siteCards: Array<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
    defaultCard?: Maybe<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    defaultSiteCard?: Maybe<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
  }>
  rail?: Maybe<{
    creditCards: Array<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    siteCards: Array<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
    defaultCard?: Maybe<
      Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
        data: Pick<
          CheckoutInfoCreditCardData,
          'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
        >
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
    defaultSiteCard?: Maybe<
      Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
        data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    >
  }>
}

export type CheckoutInfoBillingDataFragment = {
  creditCards: Array<
    Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
      data: Pick<
        CheckoutInfoCreditCardData,
        'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
      >
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  siteCards: Array<
    Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
      data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
  defaultCard?: Maybe<
    Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
      data: Pick<
        CheckoutInfoCreditCardData,
        'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
      >
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  defaultSiteCard?: Maybe<
    Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
      data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
}

export type CreditCardFragment = Pick<
  CheckoutInfoCreditCard,
  'id' | 'isCorporateCard' | 'isCardOutOfPolicy'
> & {
  data: Pick<
    CheckoutInfoCreditCardData,
    'id' | 'type' | 'number' | 'cvv' | 'label' | 'nameOnTheCard' | 'expirationDate'
  >
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
}

export type SiteCardFragment = Pick<
  CheckoutInfoSiteCard,
  'id' | 'isRestricted' | 'isCardOutOfPolicy'
> & {
  data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
}

export type SpecialRequestsFragment = {
  air?: Maybe<{
    data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
    options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
  }>
  carRental?: Maybe<{
    data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
    options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
  }>
  hotel?: Maybe<{
    data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
    options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
  }>
}

export type SpecialRequestFragment = {
  data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
  options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
}

export type CustomFieldsConfigurationFragment = {
  customFieldCollection: Array<
    Pick<
      CustomFieldCollectionItem,
      'collectionId' | 'collectionLabel' | 'collectionDescription'
    > & {
      customFields: Array<
        Pick<
          CustomField,
          'optional' | 'name' | 'label' | 'tooltip' | 'value' | 'defaultValue' | 'type'
        > & {
          preTripApprovalDisplay?: Maybe<Pick<PreTripApprovalDisplay, 'ruleDescription'>>
          checkBoxDisplay?: Maybe<
            Pick<CustomFieldCheckBoxDisplay, 'isChecked' | 'isCheckedValueRequired'>
          >
          textDisplay?: Maybe<
            Pick<
              CustomFieldTextDisplay,
              'minLength' | 'maxLength' | 'numberOfLines' | 'characterWidth'
            >
          >
          enumerationDisplay?: Maybe<
            Pick<CustomFieldEnumerationDisplay, 'isRadioButtonsStyle'> & {
              option?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>>
            }
          >
          numberDisplay?: Maybe<
            Pick<
              CustomFieldNumberDisplay,
              'minValue' | 'maxValue' | 'decimalPlaces' | 'currencyCode'
            >
          >
          dateTimeDisplay?: Maybe<
            Pick<
              CustomFieldDateTimeDisplay,
              | 'isAllowedPastDateTime'
              | 'displayYear'
              | 'displayMonth'
              | 'displayDay'
              | 'displayHour'
              | 'displayMinute'
              | 'displayTimeZone'
              | 'minYear'
              | 'maxYear'
              | 'minMonth'
              | 'maxMonth'
              | 'minDay'
              | 'maxDay'
            >
          >
        }
      >
    }
  >
}

export type TripFragment = Pick<
  OrderDetails,
  | 'appliedRailCards'
  | 'isNDCTrip'
  | 'cityImageUrl'
  | 'processId'
  | 'tripId'
  | 'type'
  | 'recordLocator'
  | 'creationTime'
  | 'cancellationTime'
  | 'tripName'
  | 'startDate'
  | 'rollUpStartTime'
  | 'rollUpEndTime'
  | 'flightId'
  | 'isMultiCity'
  | 'agencyName'
> & {
  holdRules?: Maybe<
    Pick<TripHoldRules, 'autoCancellationTime' | 'holdAllowed' | 'reasonsForNotAllowing'>
  >
  supportedActionsV2?: Maybe<
    Pick<
      TripDetailsPostReservationV2,
      | 'allowAddFlight'
      | 'allowAddHotel'
      | 'allowAddCar'
      | 'allowBookAgain'
      | 'allowChange'
      | 'allowCancel'
    > & {
      segmentChangeActions?: Maybe<{
        flightBooking?: Maybe<
          Pick<
            FlightChangeCancelRules,
            | 'allowChangeDestination'
            | 'allowChangeCarrier'
            | 'allowChange'
            | 'allowCancel'
            | 'needCancelAcknowledgement'
          > & {
            flightChangeRules?: Maybe<
              Array<
                Pick<FlightChangeRules, 'changeType'> & {
                  flightChangeBehavior?: Maybe<
                    Pick<
                      FlightChangeBehavior,
                      | 'allowedSearchStartDate'
                      | 'allowedSearchEndDate'
                      | 'allowChangeCarrier'
                      | 'allowChangeFareType'
                      | 'allowChangeAirports'
                      | 'allowChangeCabin'
                    >
                  >
                }
              >
            >
            cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
          }
        >
        trainBooking?: Maybe<
          Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
            cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
          }
        >
        hotelBooking?: Maybe<
          Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
            cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
          }
        >
        carBooking?: Maybe<
          Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
            cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
          }
        >
      }>
    }
  >
  agencyInfo?: Maybe<Pick<AgencyInfo, 'agencyName' | 'agencyPhoneNumber' | 'agencyMessage'>>
  tripCost?: Maybe<
    Pick<TripDetailsTripCostSummary, 'allowUnusedTicketOptout'> & {
      prepaid?: Maybe<{
        flight?: Maybe<
          Array<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
        >
        hotel?: Maybe<
          Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            base?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFee?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            fareTaxInfo?: Maybe<
              Array<
                Pick<FareTaxInfo, 'code' | 'name'> & {
                  amount: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
            >
          }
        >
        carRental?: Maybe<
          Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            base?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFee?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            fareTaxInfo?: Maybe<
              Array<
                Pick<FareTaxInfo, 'code' | 'name'> & {
                  amount: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
            >
          }
        >
        train?: Maybe<
          Array<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
        >
      }>
      reserved?: Maybe<{
        hotel?: Maybe<
          Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            base?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFee?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            fareTaxInfo?: Maybe<
              Array<
                Pick<FareTaxInfo, 'code' | 'name'> & {
                  amount: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
            >
          }
        >
        carRental?: Maybe<
          Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            base?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFee?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            fareTaxInfo?: Maybe<
              Array<
                Pick<FareTaxInfo, 'code' | 'name'> & {
                  amount: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
            >
          }
        >
      }>
      unusedTicket?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFees?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalServiceFees?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalTaxCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalTripBaseCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalWithUnusedTicket?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
    }
  >
  messages?: Maybe<
    Array<
      Pick<
        DynamicSiteMessage,
        'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
      >
    >
  >
  segments?: Maybe<
    Array<
      Pick<TripDetailsSegment, 'position' | 'type'> & {
        flightLeg?: Maybe<
          Pick<
            TripDetailsFlightLeg,
            | 'legId'
            | 'pnrNumber'
            | 'departureDateTime'
            | 'arrivalDateTime'
            | 'stops'
            | 'flightStops'
            | 'recordLocator'
            | 'isSameCarrierName'
            | 'isRoundTripLeg'
            | 'isRoundTripCost'
            | 'fareRestrictions'
          > & {
            traveler?: Maybe<
              Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
            >
            flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
            distance?: Maybe<Pick<Distance, 'value' | 'units'>>
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            taxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            totalCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            unusedTicketRate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            policy?: Maybe<
              Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
            >
            segments?: Maybe<
              Array<
                Pick<
                  TripDetailsFlightLegSegment,
                  | 'confirmationNumber'
                  | 'segmentId'
                  | 'originTerminal'
                  | 'destinationTerminal'
                  | 'departureDate'
                  | 'arrivalDate'
                  | 'imageUrl'
                  | 'name'
                  | 'flightNumber'
                  | 'carrierCode'
                  | 'bookingCode'
                  | 'travelerSeat'
                  | 'travelerId'
                  | 'isDepartAirportDifferentFromConnecting'
                  | 'isSeatAvailable'
                  | 'isSeatSelectable'
                  | 'serviceClass'
                  | 'fare'
                  | 'percentageOnTime'
                  | 'operatedBy'
                  | 'mealService'
                  | 'bookingProvider'
                  | 'actualDepartureDate'
                  | 'actualArrivalDate'
                  | 'departureGate'
                  | 'arrivalGate'
                  | 'flightStatus'
                  | 'baggageClaim'
                  | 'trsItemId'
                  | 'specialRequests'
                  | 'status'
                > & {
                  checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
                  departure?: Maybe<
                    Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'locationName'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'stateCode'
                          | 'postalCode'
                          | 'countryCode'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                  >
                  arrival?: Maybe<
                    Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'locationName'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'stateCode'
                          | 'postalCode'
                          | 'countryCode'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                  >
                  images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
                  flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                  distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                  baggageFees?: Maybe<
                    Pick<BaggageFees, 'carrierUrl'> & {
                      items?: Maybe<
                        Array<
                          Maybe<
                            Pick<FlightBaggageFee, 'type'> & {
                              rate: {
                                primary: Pick<Money, 'amount' | 'currency'>
                                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                              }
                              baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                              baggageWeight?: Maybe<{
                                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                              }>
                              baggageSize?: Maybe<{
                                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                              }>
                            }
                          >
                        >
                      >
                    }
                  >
                  amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
                  preferredDescription?: Maybe<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                  preferenceAttributes?: Maybe<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                  penaltyFees?: Maybe<
                    Array<
                      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                        rates?: Maybe<
                          Array<
                            Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                          >
                        >
                        penaltyFee?: Maybe<
                          Array<
                            Maybe<
                              Pick<
                                TrainPenaltyFee,
                                | 'state'
                                | 'isRuleAllowed'
                                | 'effectiveStartDate'
                                | 'effectiveEndDate'
                              > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                            >
                          >
                        >
                      }
                    >
                  >
                  serviceFee?: Maybe<
                    Pick<SegmentServiceFee, 'id' | 'description'> & {
                      rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  >
                  codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
                  plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
                  membership?: Maybe<
                    Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>>
                  >
                }
              >
            >
            totalWithUnusedTicket?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
        hotel?: Maybe<
          Pick<
            TripDetailsHotelSegment,
            | 'checkIn'
            | 'checkOut'
            | 'numberOfNights'
            | 'confirmationNumber'
            | 'imageUrl'
            | 'name'
            | 'id'
            | 'hotelId'
            | 'rateQualifier'
            | 'isCancellable'
            | 'rulesAndRestrictions'
            | 'cancellationPolicyDescription'
            | 'cancellationPolicy'
            | 'phone'
            | 'roomType'
            | 'roomDescription'
            | 'recordLocator'
            | 'starRating'
            | 'source'
            | 'isReserved'
            | 'hasDeposit'
            | 'trsItemId'
            | 'specialRequests'
            | 'status'
          > & {
            averageRate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            dailyRates?: Maybe<
              Array<
                Pick<DailyRate, 'date'> & {
                  rate: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
            >
            feesAndTaxes?: Maybe<Pick<Money, 'amount' | 'currency'>>
            totalCost?: Maybe<Pick<Money, 'amount' | 'currency'>>
            preferredDescription?: Maybe<
              Pick<
                Preference,
                | 'id'
                | 'code'
                | 'highlight'
                | 'ownerType'
                | 'ownerName'
                | 'tooltipText'
                | 'shouldShowDefaultPreference'
              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
            >
            policy?: Maybe<
              Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
            >
            address?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
            numberOfGuests?: Maybe<Pick<NumberOfGuests, 'adults'>>
            traveler?: Maybe<
              Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
            >
            photos?: Maybe<Array<Pick<Photo, 'index' | 'size' | 'url'>>>
            membership?: Maybe<Array<Pick<TripDetailsHotelMembershipData, 'number' | 'vendorName'>>>
          }
        >
        carRental?: Maybe<
          Pick<
            TripDetailsCarRentalSegment,
            | 'carId'
            | 'carClass'
            | 'carType'
            | 'fuelType'
            | 'carImageUrl'
            | 'carTransmission'
            | 'pickUpTime'
            | 'dropOffTime'
            | 'isCarAirConditioned'
            | 'contractName'
            | 'carCapacity'
            | 'carLuggage'
            | 'isReserved'
            | 'confirmationNumber'
            | 'isUnlimitedMileage'
            | 'recordLocator'
            | 'daysCount'
            | 'specialRequests'
            | 'status'
          > & {
            vendor?: Maybe<Pick<Vendor, 'name' | 'imageUrl' | 'code'>>
            safetyCheck?: Maybe<Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>>
            policy?: Maybe<
              Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
            >
            pickUpLocation?: Maybe<
              Pick<
                TripDetailsCarRentalInteraction,
                'date' | 'isDeliveryCollection' | 'isAirportLocation'
              > & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
                workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
              }
            >
            dropOffLocation?: Maybe<
              Pick<
                TripDetailsCarRentalInteraction,
                'date' | 'isDeliveryCollection' | 'isAirportLocation'
              > & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
                workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
              }
            >
            traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
            dailyRate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            totalBaseCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            totalCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            taxesAndFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            serviceFee?: Maybe<
              Pick<ServiceFee, 'id' | 'description'> & {
                rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            >
            preferredDescription?: Maybe<
              Pick<
                Preference,
                | 'id'
                | 'code'
                | 'highlight'
                | 'ownerType'
                | 'ownerName'
                | 'tooltipText'
                | 'shouldShowDefaultPreference'
              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
            >
            membership?: Maybe<Array<Pick<CheckoutInfoCarMembershipData, 'number'>>>
            extraCharge?: Maybe<{
              perDayRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              perDayCharge?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              perHourCharge?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }>
          }
        >
        trainLeg?: Maybe<
          Pick<
            TripDetailsTrainLeg,
            | 'id'
            | 'departureDateTime'
            | 'arrivalDateTime'
            | 'departureStationCode'
            | 'arrivalStationCode'
            | 'routeType'
            | 'platform'
            | 'travelStops'
            | 'transactionId'
            | 'recordLocator'
            | 'collectionReferences'
          > & {
            taxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            totalCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            traveler?: Maybe<
              Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
            >
            travelTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
            totalJourneyTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            segments?: Maybe<
              Array<
                Pick<
                  TripDetailsTrainSegment,
                  | 'id'
                  | 'arrivalDate'
                  | 'arrivalStationCode'
                  | 'confirmationNumber'
                  | 'serviceClassName'
                  | 'trainNumber'
                  | 'trainId'
                  | 'type'
                  | 'carrierName'
                  | 'carrierImage'
                  | 'departureDate'
                  | 'departureStationCode'
                  | 'name'
                  | 'status'
                > & {
                  segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
                  changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                  duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                  stations?: Maybe<{
                    arrival?: Maybe<
                      Pick<
                        TripDetailsTrainStation,
                        | 'additionalInformation'
                        | 'isBusStop'
                        | 'ticketMachineAvailable'
                        | 'phoneNumber'
                        | 'stationCode'
                        | 'stationName'
                        | 'countryName'
                        | 'ticketPickupInformation'
                      > & {
                        location?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        ticketOfficeSchedule?: Maybe<
                          Array<
                            Pick<
                              TripDetailsTrainStationSchedule,
                              'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                            >
                          >
                        >
                      }
                    >
                    departure?: Maybe<
                      Pick<
                        TripDetailsTrainStation,
                        | 'additionalInformation'
                        | 'isBusStop'
                        | 'ticketMachineAvailable'
                        | 'phoneNumber'
                        | 'stationCode'
                        | 'stationName'
                        | 'countryName'
                        | 'ticketPickupInformation'
                      > & {
                        location?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        ticketOfficeSchedule?: Maybe<
                          Array<
                            Pick<
                              TripDetailsTrainStationSchedule,
                              'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                            >
                          >
                        >
                      }
                    >
                  }>
                  allocatedSeat?: Maybe<Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>>
                }
              >
            >
            selectedDeliveryOption?: Maybe<Pick<TripDeliveryOption, 'code' | 'name'>>
            fareConditions?: Maybe<
              Array<
                Maybe<{
                  tier: Pick<RailFareTier, 'id' | 'name'>
                  localizedFareConditions: Array<
                    Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
                      conditions: Array<Pick<Conditions, 'name' | 'description'>>
                    }
                  >
                }>
              >
            >
            permittedOriginStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
            permittedDestinationStations: Array<
              Pick<RailFarePermittedStation, 'code' | 'description'>
            >
          }
        >
        carService?: Maybe<
          Pick<
            TripDetailsCarServiceSegment,
            'pickUpTime' | 'confirmationNumber' | 'recordLocator'
          > & {
            car?: Maybe<Pick<TripDetailsCarServiceCar, 'class' | 'type' | 'capacity' | 'imageUrl'>>
            detailsRules?: Maybe<
              Pick<
                TripDetailsCarServiceDetailsRules,
                'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
              >
            >
            policy?: Maybe<
              Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
            >
            traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
            price: Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              taxes: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              timeBase?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              offHours?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              airportFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              meetAndGreet?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              surfaceTransport?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              parking?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fuelSurcharge?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              accountDiscount?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              rateQuote?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              stops?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              tolls?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              extraService?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              distanceBased?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              gratuity?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              accountService?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }
            serviceCompany?: Maybe<
              Pick<TripDetailsCarServiceCompanyService, 'name' | 'description' | 'logo'>
            >
            stops?: Maybe<
              Array<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
            >
            pickUp?: Maybe<
              Pick<TripDetailsCarServicePickUp, 'time' | 'pickupInstructions'> & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
                location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
              }
            >
            dropOff?: Maybe<
              Pick<TripDetailsCarServiceDropOff, 'time' | 'dropOffInstructions'> & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
                location?: Maybe<
                  Pick<
                    TripDetailsCarServiceDropOffLocation,
                    'asDirected' | 'requestedDuration' | 'requestedHours' | 'asDirectedMultiDay'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              }
            >
          }
        >
        rideHail?: Maybe<
          Pick<
            TripDetailsRideHailSegment,
            | 'bookingId'
            | 'description'
            | 'dropOffAddress'
            | 'dropOffTime'
            | 'pickUpAddress'
            | 'pickUpTime'
            | 'productName'
            | 'status'
          > & {
            price?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            estimatedPriceRange?: Maybe<{
              primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
              secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
            }>
          }
        >
      }
    >
  >
  costSummary: {
    carService?: Maybe<
      Pick<CarServiceCostSummary, 'isPaid'> & {
        cost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tax?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        carServiceCharges?: Maybe<{
          accountDiscount?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          accountServiceCharge?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          airPortFees?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          extraServiceCharge?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          fuelSurcharge?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          gratuity?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          offHoursCharge?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          parkingAmount?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          stopCharge?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }>
      }
    >
    train?: Maybe<{
      trainList: Array<
        Pick<TrainCostSummaryItem, 'origin' | 'destination' | 'isPaid'> & {
          cost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          tax?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }
      >
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fare?: Maybe<{
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        transactionFee: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        serviceFee: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        paymentFee: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        tax: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }>
    }>
    flight?: Maybe<{
      flightList: Array<
        Pick<FlightCostSummaryItem, 'origin' | 'destination' | 'isSameAirline' | 'isPaid'> & {
          cost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          tax?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          unusedTicketRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }>
    hotel?: Maybe<
      Pick<HotelCostSummary, 'name' | 'itemCount' | 'isPaid'> & {
        cost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tax?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    carRental?: Maybe<
      Pick<CarRentalCostSummary, 'vendorName' | 'itemCount' | 'isPaid'> & {
        cost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tax?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    totalCost?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
  }
  airTickets?: Maybe<
    Array<
      Pick<
        AirTicket,
        | 'ticketNumber'
        | 'travelerName'
        | 'issuingAgent'
        | 'iataNumber'
        | 'formOfPayment'
        | 'endorsement'
        | 'fareLinear'
        | 'issuingAirline'
        | 'isTicketRefundable'
        | 'isPenaltyValidated'
        | 'isPenaltyPayableFromResidual'
        | 'isExchangeForRefundableAllowed'
      > & {
        ticketSegmentInfo?: Maybe<
          Array<
            Pick<
              TicketSegment,
              | 'departTime'
              | 'carrierCode'
              | 'flightNumber'
              | 'origin'
              | 'destination'
              | 'serviceClass'
              | 'fareBasis'
              | 'status'
            >
          >
        >
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxItemDto, 'taxCode' | 'taxLabel'> & {
              taxAmount?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }
          >
        >
        baseFare?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        totalFare?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        penalty?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
  >
  billingInfo?: Maybe<
    Array<
      Pick<BillingInfo, 'id'> & {
        payment?: Maybe<{
          creditCard?: Maybe<Pick<CreditPaymentCard, 'label'>>
          siteCard?: Maybe<Pick<SitePaymentCard, 'label'>>
        }>
      }
    >
  >
  meetingInfo?: Maybe<
    Pick<MeetingInfo, 'name' | 'location'> & { dates: Pick<DateRange, 'startDate' | 'endDate'> }
  >
}

export type SupportedActionsV2Fragment = Pick<
  TripDetailsPostReservationV2,
  | 'allowAddFlight'
  | 'allowAddHotel'
  | 'allowAddCar'
  | 'allowBookAgain'
  | 'allowChange'
  | 'allowCancel'
> & {
  segmentChangeActions?: Maybe<{
    flightBooking?: Maybe<
      Pick<
        FlightChangeCancelRules,
        | 'allowChangeDestination'
        | 'allowChangeCarrier'
        | 'allowChange'
        | 'allowCancel'
        | 'needCancelAcknowledgement'
      > & {
        flightChangeRules?: Maybe<
          Array<
            Pick<FlightChangeRules, 'changeType'> & {
              flightChangeBehavior?: Maybe<
                Pick<
                  FlightChangeBehavior,
                  | 'allowedSearchStartDate'
                  | 'allowedSearchEndDate'
                  | 'allowChangeCarrier'
                  | 'allowChangeFareType'
                  | 'allowChangeAirports'
                  | 'allowChangeCabin'
                >
              >
            }
          >
        >
        cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
      }
    >
    trainBooking?: Maybe<
      Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
        cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
      }
    >
    hotelBooking?: Maybe<
      Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
        cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
      }
    >
    carBooking?: Maybe<
      Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
        cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
      }
    >
  }>
}

export type TripCostSummaryFragment = Pick<
  TripDetailsTripCostSummary,
  'allowUnusedTicketOptout'
> & {
  prepaid?: Maybe<{
    flight?: Maybe<
      Array<
        Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          base?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          transactionFee?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          fareTaxInfo?: Maybe<
            Array<
              Pick<FareTaxInfo, 'code' | 'name'> & {
                amount: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
          >
        }
      >
    >
    hotel?: Maybe<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
    carRental?: Maybe<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
    train?: Maybe<
      Array<
        Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          base?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          transactionFee?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          fareTaxInfo?: Maybe<
            Array<
              Pick<FareTaxInfo, 'code' | 'name'> & {
                amount: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
          >
        }
      >
    >
  }>
  reserved?: Maybe<{
    hotel?: Maybe<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
    carRental?: Maybe<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
  }>
  unusedTicket?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  transactionFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalServiceFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalTaxCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalTripBaseCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  total?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalWithUnusedTicket?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  fareTaxInfo?: Maybe<
    Array<
      Pick<FareTaxInfo, 'code' | 'name'> & {
        amount: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }
    >
  >
}

export type PrepaidCostFragment = {
  flight?: Maybe<
    Array<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
  >
  hotel?: Maybe<
    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      base?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      feesAndTaxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
    }
  >
  carRental?: Maybe<
    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      base?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      feesAndTaxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
    }
  >
  train?: Maybe<
    Array<
      Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        feesAndTaxes?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFee?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
  >
}

export type TripCostFragment = Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
  total: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  base?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  feesAndTaxes?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  transactionFee?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  fareTaxInfo?: Maybe<
    Array<
      Pick<FareTaxInfo, 'code' | 'name'> & {
        amount: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }
    >
  >
}

export type ReservedCostFragment = {
  hotel?: Maybe<
    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      base?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      feesAndTaxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
    }
  >
  carRental?: Maybe<
    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      base?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      feesAndTaxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
    }
  >
}

export type MessagesFragment = Pick<
  DynamicSiteMessage,
  'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
>

export type SegmentsFragment = Pick<TripDetailsSegment, 'position' | 'type'> & {
  flightLeg?: Maybe<
    Pick<
      TripDetailsFlightLeg,
      | 'legId'
      | 'pnrNumber'
      | 'departureDateTime'
      | 'arrivalDateTime'
      | 'stops'
      | 'flightStops'
      | 'recordLocator'
      | 'isSameCarrierName'
      | 'isRoundTripLeg'
      | 'isRoundTripCost'
      | 'fareRestrictions'
    > & {
      traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
      flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
      distance?: Maybe<Pick<Distance, 'value' | 'units'>>
      rate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      taxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFees?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      unusedTicketRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      policy?: Maybe<
        Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
      >
      segments?: Maybe<
        Array<
          Pick<
            TripDetailsFlightLegSegment,
            | 'confirmationNumber'
            | 'segmentId'
            | 'originTerminal'
            | 'destinationTerminal'
            | 'departureDate'
            | 'arrivalDate'
            | 'imageUrl'
            | 'name'
            | 'flightNumber'
            | 'carrierCode'
            | 'bookingCode'
            | 'travelerSeat'
            | 'travelerId'
            | 'isDepartAirportDifferentFromConnecting'
            | 'isSeatAvailable'
            | 'isSeatSelectable'
            | 'serviceClass'
            | 'fare'
            | 'percentageOnTime'
            | 'operatedBy'
            | 'mealService'
            | 'bookingProvider'
            | 'actualDepartureDate'
            | 'actualArrivalDate'
            | 'departureGate'
            | 'arrivalGate'
            | 'flightStatus'
            | 'baggageClaim'
            | 'trsItemId'
            | 'specialRequests'
            | 'status'
          > & {
            checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
            departure?: Maybe<
              Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              }
            >
            arrival?: Maybe<
              Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              }
            >
            images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
            flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
            distance?: Maybe<Pick<Distance, 'value' | 'units'>>
            baggageFees?: Maybe<
              Pick<BaggageFees, 'carrierUrl'> & {
                items?: Maybe<
                  Array<
                    Maybe<
                      Pick<FlightBaggageFee, 'type'> & {
                        rate: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                        baggageWeight?: Maybe<{
                          lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                          upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                        }>
                        baggageSize?: Maybe<{
                          lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                          upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                        }>
                      }
                    >
                  >
                >
              }
            >
            amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
            preferredDescription?: Maybe<
              Pick<
                Preference,
                | 'id'
                | 'code'
                | 'highlight'
                | 'ownerType'
                | 'ownerName'
                | 'tooltipText'
                | 'shouldShowDefaultPreference'
              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
            >
            preferenceAttributes?: Maybe<
              Pick<
                Preference,
                | 'id'
                | 'code'
                | 'highlight'
                | 'ownerType'
                | 'ownerName'
                | 'tooltipText'
                | 'shouldShowDefaultPreference'
              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
            >
            penaltyFees?: Maybe<
              Array<
                Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                  rates?: Maybe<
                    Array<
                      Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    >
                  >
                  penaltyFee?: Maybe<
                    Array<
                      Maybe<
                        Pick<
                          TrainPenaltyFee,
                          'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
                        > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                      >
                    >
                  >
                }
              >
            >
            serviceFee?: Maybe<
              Pick<SegmentServiceFee, 'id' | 'description'> & {
                rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            >
            codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
            plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
            membership?: Maybe<
              Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>>
            >
          }
        >
      >
      totalWithUnusedTicket?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }
  >
  hotel?: Maybe<
    Pick<
      TripDetailsHotelSegment,
      | 'checkIn'
      | 'checkOut'
      | 'numberOfNights'
      | 'confirmationNumber'
      | 'imageUrl'
      | 'name'
      | 'id'
      | 'hotelId'
      | 'rateQualifier'
      | 'isCancellable'
      | 'rulesAndRestrictions'
      | 'cancellationPolicyDescription'
      | 'cancellationPolicy'
      | 'phone'
      | 'roomType'
      | 'roomDescription'
      | 'recordLocator'
      | 'starRating'
      | 'source'
      | 'isReserved'
      | 'hasDeposit'
      | 'trsItemId'
      | 'specialRequests'
      | 'status'
    > & {
      averageRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      dailyRates?: Maybe<
        Array<
          Pick<DailyRate, 'date'> & {
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
      >
      feesAndTaxes?: Maybe<Pick<Money, 'amount' | 'currency'>>
      totalCost?: Maybe<Pick<Money, 'amount' | 'currency'>>
      preferredDescription?: Maybe<
        Pick<
          Preference,
          | 'id'
          | 'code'
          | 'highlight'
          | 'ownerType'
          | 'ownerName'
          | 'tooltipText'
          | 'shouldShowDefaultPreference'
        > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
      >
      policy?: Maybe<
        Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
      >
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
      numberOfGuests?: Maybe<Pick<NumberOfGuests, 'adults'>>
      traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
      photos?: Maybe<Array<Pick<Photo, 'index' | 'size' | 'url'>>>
      membership?: Maybe<Array<Pick<TripDetailsHotelMembershipData, 'number' | 'vendorName'>>>
    }
  >
  carRental?: Maybe<
    Pick<
      TripDetailsCarRentalSegment,
      | 'carId'
      | 'carClass'
      | 'carType'
      | 'fuelType'
      | 'carImageUrl'
      | 'carTransmission'
      | 'pickUpTime'
      | 'dropOffTime'
      | 'isCarAirConditioned'
      | 'contractName'
      | 'carCapacity'
      | 'carLuggage'
      | 'isReserved'
      | 'confirmationNumber'
      | 'isUnlimitedMileage'
      | 'recordLocator'
      | 'daysCount'
      | 'specialRequests'
      | 'status'
    > & {
      vendor?: Maybe<Pick<Vendor, 'name' | 'imageUrl' | 'code'>>
      safetyCheck?: Maybe<Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>>
      policy?: Maybe<
        Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
      >
      pickUpLocation?: Maybe<
        Pick<
          TripDetailsCarRentalInteraction,
          'date' | 'isDeliveryCollection' | 'isAirportLocation'
        > & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
          workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
          phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
        }
      >
      dropOffLocation?: Maybe<
        Pick<
          TripDetailsCarRentalInteraction,
          'date' | 'isDeliveryCollection' | 'isAirportLocation'
        > & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
          workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
          phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
        }
      >
      traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
      dailyRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalBaseCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      taxesAndFees?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      serviceFee?: Maybe<
        Pick<ServiceFee, 'id' | 'description'> & {
          rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      >
      preferredDescription?: Maybe<
        Pick<
          Preference,
          | 'id'
          | 'code'
          | 'highlight'
          | 'ownerType'
          | 'ownerName'
          | 'tooltipText'
          | 'shouldShowDefaultPreference'
        > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
      >
      membership?: Maybe<Array<Pick<CheckoutInfoCarMembershipData, 'number'>>>
      extraCharge?: Maybe<{
        perDayRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        perDayCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        perHourCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }>
    }
  >
  trainLeg?: Maybe<
    Pick<
      TripDetailsTrainLeg,
      | 'id'
      | 'departureDateTime'
      | 'arrivalDateTime'
      | 'departureStationCode'
      | 'arrivalStationCode'
      | 'routeType'
      | 'platform'
      | 'travelStops'
      | 'transactionId'
      | 'recordLocator'
      | 'collectionReferences'
    > & {
      taxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFees?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
      travelTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
      totalJourneyTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
      rate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      segments?: Maybe<
        Array<
          Pick<
            TripDetailsTrainSegment,
            | 'id'
            | 'arrivalDate'
            | 'arrivalStationCode'
            | 'confirmationNumber'
            | 'serviceClassName'
            | 'trainNumber'
            | 'trainId'
            | 'type'
            | 'carrierName'
            | 'carrierImage'
            | 'departureDate'
            | 'departureStationCode'
            | 'name'
            | 'status'
          > & {
            segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
            changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
            duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
            stations?: Maybe<{
              arrival?: Maybe<
                Pick<
                  TripDetailsTrainStation,
                  | 'additionalInformation'
                  | 'isBusStop'
                  | 'ticketMachineAvailable'
                  | 'phoneNumber'
                  | 'stationCode'
                  | 'stationName'
                  | 'countryName'
                  | 'ticketPickupInformation'
                > & {
                  location?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  ticketOfficeSchedule?: Maybe<
                    Array<
                      Pick<
                        TripDetailsTrainStationSchedule,
                        'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                      >
                    >
                  >
                }
              >
              departure?: Maybe<
                Pick<
                  TripDetailsTrainStation,
                  | 'additionalInformation'
                  | 'isBusStop'
                  | 'ticketMachineAvailable'
                  | 'phoneNumber'
                  | 'stationCode'
                  | 'stationName'
                  | 'countryName'
                  | 'ticketPickupInformation'
                > & {
                  location?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  ticketOfficeSchedule?: Maybe<
                    Array<
                      Pick<
                        TripDetailsTrainStationSchedule,
                        'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                      >
                    >
                  >
                }
              >
            }>
            allocatedSeat?: Maybe<Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>>
          }
        >
      >
      selectedDeliveryOption?: Maybe<Pick<TripDeliveryOption, 'code' | 'name'>>
      fareConditions?: Maybe<
        Array<
          Maybe<{
            tier: Pick<RailFareTier, 'id' | 'name'>
            localizedFareConditions: Array<
              Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
                conditions: Array<Pick<Conditions, 'name' | 'description'>>
              }
            >
          }>
        >
      >
      permittedOriginStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
      permittedDestinationStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
    }
  >
  carService?: Maybe<
    Pick<TripDetailsCarServiceSegment, 'pickUpTime' | 'confirmationNumber' | 'recordLocator'> & {
      car?: Maybe<Pick<TripDetailsCarServiceCar, 'class' | 'type' | 'capacity' | 'imageUrl'>>
      detailsRules?: Maybe<
        Pick<
          TripDetailsCarServiceDetailsRules,
          'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
        >
      >
      policy?: Maybe<
        Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
      >
      traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
      price: Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        taxes: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        base?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        timeBase?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        offHours?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        airportFees?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        meetAndGreet?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        surfaceTransport?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        parking?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fuelSurcharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        accountDiscount?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        rateQuote?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        stops?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tolls?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        extraService?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        distanceBased?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        gratuity?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        accountService?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
      serviceCompany?: Maybe<
        Pick<TripDetailsCarServiceCompanyService, 'name' | 'description' | 'logo'>
      >
      stops?: Maybe<
        Array<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      >
      pickUp?: Maybe<
        Pick<TripDetailsCarServicePickUp, 'time' | 'pickupInstructions'> & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
          location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
        }
      >
      dropOff?: Maybe<
        Pick<TripDetailsCarServiceDropOff, 'time' | 'dropOffInstructions'> & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
          location?: Maybe<
            Pick<
              TripDetailsCarServiceDropOffLocation,
              'asDirected' | 'requestedDuration' | 'requestedHours' | 'asDirectedMultiDay'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
        }
      >
    }
  >
  rideHail?: Maybe<
    Pick<
      TripDetailsRideHailSegment,
      | 'bookingId'
      | 'description'
      | 'dropOffAddress'
      | 'dropOffTime'
      | 'pickUpAddress'
      | 'pickUpTime'
      | 'productName'
      | 'status'
    > & {
      price?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      estimatedPriceRange?: Maybe<{
        primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
        secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
      }>
    }
  >
}

export type FlightFragment = Pick<
  TripDetailsFlightLeg,
  | 'legId'
  | 'pnrNumber'
  | 'departureDateTime'
  | 'arrivalDateTime'
  | 'stops'
  | 'flightStops'
  | 'recordLocator'
  | 'isSameCarrierName'
  | 'isRoundTripLeg'
  | 'isRoundTripCost'
  | 'fareRestrictions'
> & {
  traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
  flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
  distance?: Maybe<Pick<Distance, 'value' | 'units'>>
  rate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  taxes?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  transactionFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  unusedTicketRate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  policy?: Maybe<
    Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
      outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
    }
  >
  segments?: Maybe<
    Array<
      Pick<
        TripDetailsFlightLegSegment,
        | 'confirmationNumber'
        | 'segmentId'
        | 'originTerminal'
        | 'destinationTerminal'
        | 'departureDate'
        | 'arrivalDate'
        | 'imageUrl'
        | 'name'
        | 'flightNumber'
        | 'carrierCode'
        | 'bookingCode'
        | 'travelerSeat'
        | 'travelerId'
        | 'isDepartAirportDifferentFromConnecting'
        | 'isSeatAvailable'
        | 'isSeatSelectable'
        | 'serviceClass'
        | 'fare'
        | 'percentageOnTime'
        | 'operatedBy'
        | 'mealService'
        | 'bookingProvider'
        | 'actualDepartureDate'
        | 'actualArrivalDate'
        | 'departureGate'
        | 'arrivalGate'
        | 'flightStatus'
        | 'baggageClaim'
        | 'trsItemId'
        | 'specialRequests'
        | 'status'
      > & {
        checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
        departure?: Maybe<
          Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
            address?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
          }
        >
        arrival?: Maybe<
          Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
            address?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
          }
        >
        images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
        flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
        distance?: Maybe<Pick<Distance, 'value' | 'units'>>
        baggageFees?: Maybe<
          Pick<BaggageFees, 'carrierUrl'> & {
            items?: Maybe<
              Array<
                Maybe<
                  Pick<FlightBaggageFee, 'type'> & {
                    rate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                    baggageWeight?: Maybe<{
                      lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                      upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                    }>
                    baggageSize?: Maybe<{
                      lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                      upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                    }>
                  }
                >
              >
            >
          }
        >
        amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
        preferredDescription?: Maybe<
          Pick<
            Preference,
            | 'id'
            | 'code'
            | 'highlight'
            | 'ownerType'
            | 'ownerName'
            | 'tooltipText'
            | 'shouldShowDefaultPreference'
          > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
        >
        preferenceAttributes?: Maybe<
          Pick<
            Preference,
            | 'id'
            | 'code'
            | 'highlight'
            | 'ownerType'
            | 'ownerName'
            | 'tooltipText'
            | 'shouldShowDefaultPreference'
          > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
        >
        penaltyFees?: Maybe<
          Array<
            Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
              rates?: Maybe<
                Array<
                  Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                >
              >
              penaltyFee?: Maybe<
                Array<
                  Maybe<
                    Pick<
                      TrainPenaltyFee,
                      'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
                    > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                  >
                >
              >
            }
          >
        >
        serviceFee?: Maybe<
          Pick<SegmentServiceFee, 'id' | 'description'> & {
            rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        >
        codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
        plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
        membership?: Maybe<
          Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>>
        >
      }
    >
  >
  totalWithUnusedTicket?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type TravelerFragment = Pick<
  TripDetailsTraveler,
  'firstName' | 'lastName' | 'middleName' | 'email'
>

export type PolicyFragment = Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
  outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
}

export type OutOfPolicyDataFragment = Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>

export type FlightSegmentFragment = Pick<
  TripDetailsFlightLegSegment,
  | 'confirmationNumber'
  | 'segmentId'
  | 'originTerminal'
  | 'destinationTerminal'
  | 'departureDate'
  | 'arrivalDate'
  | 'imageUrl'
  | 'name'
  | 'flightNumber'
  | 'carrierCode'
  | 'bookingCode'
  | 'travelerSeat'
  | 'travelerId'
  | 'isDepartAirportDifferentFromConnecting'
  | 'isSeatAvailable'
  | 'isSeatSelectable'
  | 'serviceClass'
  | 'fare'
  | 'percentageOnTime'
  | 'operatedBy'
  | 'mealService'
  | 'bookingProvider'
  | 'actualDepartureDate'
  | 'actualArrivalDate'
  | 'departureGate'
  | 'arrivalGate'
  | 'flightStatus'
  | 'baggageClaim'
  | 'trsItemId'
  | 'specialRequests'
  | 'status'
> & {
  checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
  departure?: Maybe<
    Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  arrival?: Maybe<
    Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
  flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
  distance?: Maybe<Pick<Distance, 'value' | 'units'>>
  baggageFees?: Maybe<
    Pick<BaggageFees, 'carrierUrl'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<FlightBaggageFee, 'type'> & {
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
              baggageWeight?: Maybe<{
                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
              }>
              baggageSize?: Maybe<{
                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
              }>
            }
          >
        >
      >
    }
  >
  amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
  preferredDescription?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
  preferenceAttributes?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
  penaltyFees?: Maybe<
    Array<
      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
        rates?: Maybe<
          Array<
            Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          >
        >
        penaltyFee?: Maybe<
          Array<
            Maybe<
              Pick<
                TrainPenaltyFee,
                'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
              > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
            >
          >
        >
      }
    >
  >
  serviceFee?: Maybe<
    Pick<SegmentServiceFee, 'id' | 'description'> & {
      rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
  >
  codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
  plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
  membership?: Maybe<Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>>>
}

export type AirportAddressFragment = Pick<
  AirportAddress,
  'airportCode' | 'airportName' | 'airportCity'
> & {
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
}

export type AddressFragment = Pick<
  Address,
  | 'airportCode'
  | 'locationName'
  | 'street1'
  | 'street2'
  | 'city'
  | 'stateCode'
  | 'postalCode'
  | 'countryCode'
> & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }

export type BaggageAllowanceCountFragment = Pick<BaggageAllowanceCount, 'lower' | 'upper'>

export type BaggageAllowanceFragment = {
  lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
  upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
}

export type PreferenceFragment = Pick<
  Preference,
  | 'id'
  | 'code'
  | 'highlight'
  | 'ownerType'
  | 'ownerName'
  | 'tooltipText'
  | 'shouldShowDefaultPreference'
> & { customBadge: Pick<CustomBadge, 'name' | 'color'> }

export type PenaltyFeeFragment = Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
  rates?: Maybe<
    Array<
      Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    >
  >
  penaltyFee?: Maybe<
    Array<
      Maybe<
        Pick<
          TrainPenaltyFee,
          'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
        > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
      >
    >
  >
}

export type HotelFragment = Pick<
  TripDetailsHotelSegment,
  | 'checkIn'
  | 'checkOut'
  | 'numberOfNights'
  | 'confirmationNumber'
  | 'imageUrl'
  | 'name'
  | 'id'
  | 'hotelId'
  | 'rateQualifier'
  | 'isCancellable'
  | 'rulesAndRestrictions'
  | 'cancellationPolicyDescription'
  | 'cancellationPolicy'
  | 'phone'
  | 'roomType'
  | 'roomDescription'
  | 'recordLocator'
  | 'starRating'
  | 'source'
  | 'isReserved'
  | 'hasDeposit'
  | 'trsItemId'
  | 'specialRequests'
  | 'status'
> & {
  averageRate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  dailyRates?: Maybe<
    Array<
      Pick<DailyRate, 'date'> & {
        rate: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }
    >
  >
  feesAndTaxes?: Maybe<Pick<Money, 'amount' | 'currency'>>
  totalCost?: Maybe<Pick<Money, 'amount' | 'currency'>>
  preferredDescription?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
  policy?: Maybe<
    Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
      outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
    }
  >
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
  numberOfGuests?: Maybe<Pick<NumberOfGuests, 'adults'>>
  traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
  photos?: Maybe<Array<Pick<Photo, 'index' | 'size' | 'url'>>>
  membership?: Maybe<Array<Pick<TripDetailsHotelMembershipData, 'number' | 'vendorName'>>>
}

export type CarRentalFragment = Pick<
  TripDetailsCarRentalSegment,
  | 'carId'
  | 'carClass'
  | 'carType'
  | 'fuelType'
  | 'carImageUrl'
  | 'carTransmission'
  | 'pickUpTime'
  | 'dropOffTime'
  | 'isCarAirConditioned'
  | 'contractName'
  | 'carCapacity'
  | 'carLuggage'
  | 'isReserved'
  | 'confirmationNumber'
  | 'isUnlimitedMileage'
  | 'recordLocator'
  | 'daysCount'
  | 'specialRequests'
  | 'status'
> & {
  vendor?: Maybe<Pick<Vendor, 'name' | 'imageUrl' | 'code'>>
  safetyCheck?: Maybe<Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>>
  policy?: Maybe<
    Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
      outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
    }
  >
  pickUpLocation?: Maybe<
    Pick<TripDetailsCarRentalInteraction, 'date' | 'isDeliveryCollection' | 'isAirportLocation'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
      workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
      phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
    }
  >
  dropOffLocation?: Maybe<
    Pick<TripDetailsCarRentalInteraction, 'date' | 'isDeliveryCollection' | 'isAirportLocation'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
      workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
      phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
    }
  >
  traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
  dailyRate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalBaseCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  taxesAndFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  serviceFee?: Maybe<
    Pick<ServiceFee, 'id' | 'description'> & { rate?: Maybe<Pick<Money, 'amount' | 'currency'>> }
  >
  preferredDescription?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
  membership?: Maybe<Array<Pick<CheckoutInfoCarMembershipData, 'number'>>>
  extraCharge?: Maybe<{
    perDayRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    perDayCharge?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    perHourCharge?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
  }>
}

export type VendorFragment = Pick<Vendor, 'name' | 'imageUrl' | 'code'>

export type TripDetailsCarRentalInteractionFragment = Pick<
  TripDetailsCarRentalInteraction,
  'date' | 'isDeliveryCollection' | 'isAirportLocation'
> & {
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
  phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
}

export type TrainLegFragment = Pick<
  TripDetailsTrainLeg,
  | 'id'
  | 'departureDateTime'
  | 'arrivalDateTime'
  | 'departureStationCode'
  | 'arrivalStationCode'
  | 'routeType'
  | 'platform'
  | 'travelStops'
  | 'transactionId'
  | 'recordLocator'
  | 'collectionReferences'
> & {
  taxes?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  transactionFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  traveler?: Maybe<Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>>
  travelTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
  totalJourneyTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
  rate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  segments?: Maybe<
    Array<
      Pick<
        TripDetailsTrainSegment,
        | 'id'
        | 'arrivalDate'
        | 'arrivalStationCode'
        | 'confirmationNumber'
        | 'serviceClassName'
        | 'trainNumber'
        | 'trainId'
        | 'type'
        | 'carrierName'
        | 'carrierImage'
        | 'departureDate'
        | 'departureStationCode'
        | 'name'
        | 'status'
      > & {
        segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
        changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
        duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
        stations?: Maybe<{
          arrival?: Maybe<
            Pick<
              TripDetailsTrainStation,
              | 'additionalInformation'
              | 'isBusStop'
              | 'ticketMachineAvailable'
              | 'phoneNumber'
              | 'stationCode'
              | 'stationName'
              | 'countryName'
              | 'ticketPickupInformation'
            > & {
              location?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
              ticketOfficeSchedule?: Maybe<
                Array<
                  Pick<
                    TripDetailsTrainStationSchedule,
                    'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                  >
                >
              >
            }
          >
          departure?: Maybe<
            Pick<
              TripDetailsTrainStation,
              | 'additionalInformation'
              | 'isBusStop'
              | 'ticketMachineAvailable'
              | 'phoneNumber'
              | 'stationCode'
              | 'stationName'
              | 'countryName'
              | 'ticketPickupInformation'
            > & {
              location?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
              ticketOfficeSchedule?: Maybe<
                Array<
                  Pick<
                    TripDetailsTrainStationSchedule,
                    'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
                  >
                >
              >
            }
          >
        }>
        allocatedSeat?: Maybe<Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>>
      }
    >
  >
  selectedDeliveryOption?: Maybe<Pick<TripDeliveryOption, 'code' | 'name'>>
  fareConditions?: Maybe<
    Array<
      Maybe<{
        tier: Pick<RailFareTier, 'id' | 'name'>
        localizedFareConditions: Array<
          Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
            conditions: Array<Pick<Conditions, 'name' | 'description'>>
          }
        >
      }>
    >
  >
  permittedOriginStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
  permittedDestinationStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
}

export type TrainSegmentFragment = Pick<
  TripDetailsTrainSegment,
  | 'id'
  | 'arrivalDate'
  | 'arrivalStationCode'
  | 'confirmationNumber'
  | 'serviceClassName'
  | 'trainNumber'
  | 'trainId'
  | 'type'
  | 'carrierName'
  | 'carrierImage'
  | 'departureDate'
  | 'departureStationCode'
  | 'name'
  | 'status'
> & {
  segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
  changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
  duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
  stations?: Maybe<{
    arrival?: Maybe<
      Pick<
        TripDetailsTrainStation,
        | 'additionalInformation'
        | 'isBusStop'
        | 'ticketMachineAvailable'
        | 'phoneNumber'
        | 'stationCode'
        | 'stationName'
        | 'countryName'
        | 'ticketPickupInformation'
      > & {
        location?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
        ticketOfficeSchedule?: Maybe<
          Array<
            Pick<
              TripDetailsTrainStationSchedule,
              'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
            >
          >
        >
      }
    >
    departure?: Maybe<
      Pick<
        TripDetailsTrainStation,
        | 'additionalInformation'
        | 'isBusStop'
        | 'ticketMachineAvailable'
        | 'phoneNumber'
        | 'stationCode'
        | 'stationName'
        | 'countryName'
        | 'ticketPickupInformation'
      > & {
        location?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
        ticketOfficeSchedule?: Maybe<
          Array<
            Pick<
              TripDetailsTrainStationSchedule,
              'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
            >
          >
        >
      }
    >
  }>
  allocatedSeat?: Maybe<Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>>
}

export type TrainStationFragment = Pick<
  TripDetailsTrainStation,
  | 'additionalInformation'
  | 'isBusStop'
  | 'ticketMachineAvailable'
  | 'phoneNumber'
  | 'stationCode'
  | 'stationName'
  | 'countryName'
  | 'ticketPickupInformation'
> & {
  location?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
  ticketOfficeSchedule?: Maybe<
    Array<
      Pick<
        TripDetailsTrainStationSchedule,
        'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
      >
    >
  >
}

export type TicketOfficeScheduleFragment = Pick<
  TripDetailsTrainStationSchedule,
  'closeTime' | 'endingOnDayOfWeek' | 'openTime' | 'startingOnDayOfWeek'
>

export type FareConditionsFragment = {
  tier: Pick<RailFareTier, 'id' | 'name'>
  localizedFareConditions: Array<
    Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
      conditions: Array<Pick<Conditions, 'name' | 'description'>>
    }
  >
}

export type TierFragment = Pick<RailFareTier, 'id' | 'name'>

export type LocalizedFareConditionsFragment = Pick<
  LocalizedFareConditions,
  'fareTypeDescription' | 'summaryConditions'
> & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }

export type ConditionsFragment = Pick<Conditions, 'name' | 'description'>

export type CarServiceSegmentFragment = Pick<
  TripDetailsCarServiceSegment,
  'pickUpTime' | 'confirmationNumber' | 'recordLocator'
> & {
  car?: Maybe<Pick<TripDetailsCarServiceCar, 'class' | 'type' | 'capacity' | 'imageUrl'>>
  detailsRules?: Maybe<
    Pick<
      TripDetailsCarServiceDetailsRules,
      'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
    >
  >
  policy?: Maybe<
    Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
      outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
    }
  >
  traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
  price: Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
    total: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
    taxes: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
    base?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    timeBase?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    offHours?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    airportFees?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    meetAndGreet?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    surfaceTransport?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    parking?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    fuelSurcharge?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    accountDiscount?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    rateQuote?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    stops?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    tolls?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    extraService?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    distanceBased?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    gratuity?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    accountService?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
  }
  serviceCompany?: Maybe<Pick<TripDetailsCarServiceCompanyService, 'name' | 'description' | 'logo'>>
  stops?: Maybe<
    Array<
      Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    >
  >
  pickUp?: Maybe<
    Pick<TripDetailsCarServicePickUp, 'time' | 'pickupInstructions'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
      location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
    }
  >
  dropOff?: Maybe<
    Pick<TripDetailsCarServiceDropOff, 'time' | 'dropOffInstructions'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
      location?: Maybe<
        Pick<
          TripDetailsCarServiceDropOffLocation,
          'asDirected' | 'requestedDuration' | 'requestedHours' | 'asDirectedMultiDay'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
}

export type CarServiceSegmentCarFragment = Pick<
  TripDetailsCarServiceCar,
  'class' | 'type' | 'capacity' | 'imageUrl'
>

export type CarServiceSegmentDetailsRulesFragment = Pick<
  TripDetailsCarServiceDetailsRules,
  'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
>

export type CarServiceTripCostFragment = Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
  total: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  taxes: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  base?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  timeBase?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  offHours?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  airportFees?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  meetAndGreet?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  surfaceTransport?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  parking?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  fuelSurcharge?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  accountDiscount?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  rateQuote?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  stops?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  tolls?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  extraService?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  distanceBased?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  gratuity?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  accountService?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type CarServiceCompanyServiceFragment = Pick<
  TripDetailsCarServiceCompanyService,
  'name' | 'description' | 'logo'
>

export type CarServicePickUpFragment = Pick<
  TripDetailsCarServicePickUp,
  'time' | 'pickupInstructions'
> & {
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
  location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
}

export type GeocodeFragment = Pick<Geocode, 'lat' | 'long'>

export type CarServiceDropOffFragment = Pick<
  TripDetailsCarServiceDropOff,
  'time' | 'dropOffInstructions'
> & {
  address?: Maybe<
    Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
  location?: Maybe<
    Pick<
      TripDetailsCarServiceDropOffLocation,
      'asDirected' | 'requestedDuration' | 'requestedHours' | 'asDirectedMultiDay'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  >
}

export type RideHailSegmentFragment = Pick<
  TripDetailsRideHailSegment,
  | 'bookingId'
  | 'description'
  | 'dropOffAddress'
  | 'dropOffTime'
  | 'pickUpAddress'
  | 'pickUpTime'
  | 'productName'
  | 'status'
> & {
  price?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  estimatedPriceRange?: Maybe<{
    primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
    secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
  }>
}

export type CostSummaryFragment = {
  carService?: Maybe<
    Pick<CarServiceCostSummary, 'isPaid'> & {
      cost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      tax?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      carServiceCharges?: Maybe<{
        accountDiscount?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        accountServiceCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        airPortFees?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        extraServiceCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fuelSurcharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        gratuity?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        offHoursCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        parkingAmount?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        stopCharge?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }>
    }
  >
  train?: Maybe<{
    trainList: Array<
      Pick<TrainCostSummaryItem, 'origin' | 'destination' | 'isPaid'> & {
        cost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tax?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }
    >
    total?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    fare?: Maybe<{
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      base: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      transactionFee: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      serviceFee: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      paymentFee: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      tax: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }>
  }>
  flight?: Maybe<{
    flightList: Array<
      Pick<FlightCostSummaryItem, 'origin' | 'destination' | 'isSameAirline' | 'isPaid'> & {
        cost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        tax?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        unusedTicketRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    total?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
  }>
  hotel?: Maybe<
    Pick<HotelCostSummary, 'name' | 'itemCount' | 'isPaid'> & {
      cost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      tax?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }
  >
  carRental?: Maybe<
    Pick<CarRentalCostSummary, 'vendorName' | 'itemCount' | 'isPaid'> & {
      cost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      tax?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      total?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }
  >
  totalCost?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type AirTicketsFragment = Pick<
  AirTicket,
  | 'ticketNumber'
  | 'travelerName'
  | 'issuingAgent'
  | 'iataNumber'
  | 'formOfPayment'
  | 'endorsement'
  | 'fareLinear'
  | 'issuingAirline'
  | 'isTicketRefundable'
  | 'isPenaltyValidated'
  | 'isPenaltyPayableFromResidual'
  | 'isExchangeForRefundableAllowed'
> & {
  ticketSegmentInfo?: Maybe<
    Array<
      Pick<
        TicketSegment,
        | 'departTime'
        | 'carrierCode'
        | 'flightNumber'
        | 'origin'
        | 'destination'
        | 'serviceClass'
        | 'fareBasis'
        | 'status'
      >
    >
  >
  fareTaxInfo?: Maybe<
    Array<
      Pick<FareTaxItemDto, 'taxCode' | 'taxLabel'> & {
        taxAmount?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
  >
  baseFare?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  totalFare?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  penalty?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type TicketSegmentFragment = Pick<
  TicketSegment,
  | 'departTime'
  | 'carrierCode'
  | 'flightNumber'
  | 'origin'
  | 'destination'
  | 'serviceClass'
  | 'fareBasis'
  | 'status'
>

export type FareTaxItemFragment = Pick<FareTaxItemDto, 'taxCode' | 'taxLabel'> & {
  taxAmount?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
}

export type FailedBookingsFragment = {
  hotelFailedBooking?: Maybe<
    Pick<PurchaseItineraryHotelFailedBooking, 'error'> & {
      hotelData: Pick<
        PurchaseItineraryHotelFailedBookingData,
        'hotelId' | 'checkIn' | 'checkOut' | 'name' | 'numberOfNights' | 'roomType'
      > & {
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
    }
  >
}

export type TravelerPostPurchaseInfoFragment = Pick<
  PurchaseInfoTraveler,
  | 'lastName'
  | 'dateOfBirth'
  | 'firstName'
  | 'gender'
  | 'sex'
  | 'suffix'
  | 'dateBirth'
  | 'email'
  | 'primary'
  | 'middleInitial'
  | 'title'
> & {
  phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
  address: Pick<
    Address,
    'airportCode' | 'street1' | 'street2' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  options?: Maybe<
    Pick<
      TravelerOptions,
      | 'isTitleChangeable'
      | 'isNameChangeable'
      | 'isDefaultChangeTravelerOptionEnabled'
      | 'isAddressRequired'
      | 'isAddressChangeable'
      | 'isDateOfBirthRequired'
      | 'isDateOfBirthChangeable'
      | 'isGenderRequired'
      | 'isGenderChangeable'
      | 'isPhoneNumberRequired'
      | 'isEMailRequired'
      | 'isFirstNameRequired'
      | 'isMiddleNameRequired'
      | 'isLastNameRequired'
      | 'isSuffixRequired'
      | 'isSuffixChangeable'
      | 'isPassportRequired'
    >
  >
  passports?: Maybe<
    Array<
      Pick<
        Passport,
        'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
      >
    >
  >
}

export type TravelerPurchaseInfoFragment = Pick<
  PurchaseInfoTraveler,
  | 'lastName'
  | 'dateOfBirth'
  | 'firstName'
  | 'gender'
  | 'sex'
  | 'suffix'
  | 'dateBirth'
  | 'email'
  | 'primary'
  | 'middleInitial'
> & {
  phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
  address: Pick<
    Address,
    'airportCode' | 'street1' | 'street2' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  options?: Maybe<
    Pick<
      TravelerOptions,
      | 'isTitleChangeable'
      | 'isNameChangeable'
      | 'isDefaultChangeTravelerOptionEnabled'
      | 'isAddressRequired'
      | 'isAddressChangeable'
      | 'isDateOfBirthRequired'
      | 'isDateOfBirthChangeable'
      | 'isGenderRequired'
      | 'isGenderChangeable'
      | 'isPhoneNumberRequired'
      | 'isEMailRequired'
      | 'isFirstNameRequired'
      | 'isMiddleNameRequired'
      | 'isLastNameRequired'
      | 'isSuffixRequired'
      | 'isSuffixChangeable'
      | 'isPassportRequired'
    >
  >
  passports?: Maybe<
    Array<
      Pick<
        Passport,
        'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
      >
    >
  >
}

export type TripsFragment = Pick<TripSummary, 'domains' | 'endDate' | 'id' | 'title' | 'startDate'>

export type PageFragment = Pick<Page, 'start' | 'size' | 'end'>

export type CarRentalReservationFragment = Pick<
  CarRentalReservation,
  'confirmationNumber' | 'id' | 'isPaid' | 'domain' | 'status'
> & {
  reservedCarRental: Pick<
    CarRental,
    'dropOffDate' | 'dropOffTime' | 'key' | 'pickupDate' | 'pickupTime'
  > & {
    pickupLocation: Pick<CarRentalLocationDetails, 'isAirport' | 'locationId' | 'phone'> & {
      locationAddress: Pick<
        Address,
        | 'stateCode'
        | 'airportCode'
        | 'city'
        | 'countryCode'
        | 'locationName'
        | 'postalCode'
        | 'street1'
        | 'street2'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      openingHours: Array<
        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
          times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>>
        }
      >
    }
    carRentalLoyalty?: Maybe<Pick<LoyaltyProgram, 'label' | 'number' | 'vendorCode'>>
    dropOffLocation: Pick<CarRentalLocationDetails, 'isAirport' | 'locationId' | 'phone'> & {
      locationAddress: Pick<
        Address,
        | 'street1'
        | 'stateCode'
        | 'postalCode'
        | 'locationName'
        | 'countryCode'
        | 'city'
        | 'airportCode'
        | 'street2'
      > & { geocode?: Maybe<Pick<Geocode, 'long' | 'lat'>> }
      openingHours: Array<
        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
          times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'openingTime' | 'closingTime'>>
        }
      >
    }
    vendor: Pick<Vendor, 'name' | 'imageUrl' | 'code'>
    vehicle: Pick<
      CarRentalVehicle,
      | 'vehicleClass'
      | 'transmissionType'
      | 'passengerCapacity'
      | 'modelName'
      | 'isAirCondition'
      | 'fuelType'
      | 'imageUrl'
      | 'category'
      | 'baggageCapacity'
    >
    termsAndConditions: Array<Pick<CarRules, 'value' | 'header'>>
    safetyCheck: Pick<CarRentalSafetyCheck, 'url' | 'messageCode' | 'badgeUrl'>
    rate: Pick<CarRentalRate, 'rateCode' | 'distanceUnlimited' | 'daysCharged'> & {
      totalTaxes: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      totalRate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      dailyRate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      extraDayCharge?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      extraHourCharge?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }
  }
}

export type HotelReservationFragment = Pick<HotelReservation, 'domain' | 'id'>

export type CarRentalReservationsCostSummaryFragment = Pick<
  CarRentalReservationsCostSummary,
  'domain'
> & {
  totalCost: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  totalRate: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  totalTaxes: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
}

export type HotelReservationsCostSummaryFragment = Pick<HotelReservationsCostSummary, 'domain'>

export type ItineraryFragment = Pick<
  Itinerary,
  | 'itineraryId'
  | 'expiredAt'
  | 'flightId'
  | 'isMultiCity'
  | 'isTripAligned'
  | 'isMultiDestination'
  | 'isOnHold'
> & {
  virtualPay?: Maybe<Pick<VirtualPay, 'isVirtualPayEnabled' | 'isVirtualPayRestricted'>>
  availableSegments?: Maybe<
    Pick<AvailableSegments, 'canAddHotel' | 'canAddFlight' | 'canAddCarRental'>
  >
  tripStatus?: Maybe<
    Pick<
      TripPostReservation,
      | 'isChangeTripAllowed'
      | 'isHoldTripAllowed'
      | 'areSegmentsRemovable'
      | 'areSegmentsChangeable'
      | 'areSegmentsCustomizable'
      | 'areExtraSegmentsAllowed'
      | 'areTicketOptionsAllowed'
    >
  >
  trip: Pick<ItineraryTrip, 'name' | 'startDate' | 'endDate' | 'isNameEditable' | 'imageUrl'>
  tripCost?: Maybe<
    Pick<TripCostSummary, 'allowUnusedTicketOptout' | 'hasOptOutUnusedTickets'> & {
      payNow?: Maybe<{
        hotel?: Maybe<{
          total: {
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          serviceFee?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
        }>
        flight?: Maybe<
          Array<
            Maybe<
              Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                base?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                total: {
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }
                feesAndTaxes?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFee?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                fareTaxInfo?: Maybe<
                  Array<
                    Pick<FareTaxInfo, 'code' | 'name'> & {
                      amount: {
                        primary: Pick<Money, 'currency' | 'amount'>
                        secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                      }
                    }
                  >
                >
              }
            >
          >
        >
        train?: Maybe<
          Array<
            Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
          >
        >
        car?: Maybe<{
          base?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          total: {
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          serviceFee?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
        }>
        subTotal: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }>
      payLater?: Maybe<{
        hotel?: Maybe<{
          base?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          total: {
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          serviceFee?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
        }>
        car?: Maybe<{
          base?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          total: {
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }
          feesAndTaxes?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
          serviceFee?: Maybe<{
            primary: Pick<Money, 'currency' | 'amount'>
            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
          }>
        }>
        carServices?: Maybe<
          Array<
            Maybe<
              Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                base?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                timeBase?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                offHours?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                airportFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                meetAndGreet?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                surfaceTransport?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                parking?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                fuelSurcharge?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                accountDiscount?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                rateQuote?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                stops?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                tolls?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                extraService?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                distanceBased?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                gratuity?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                taxes: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                accountService?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
            >
          >
        >
        subTotal: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      }>
      unusedTicket?: Maybe<
        Pick<UnusedTicket, 'status'> & {
          unvalidated?: Maybe<{
            original?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            penalty?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            newTicket?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            exchangedTicket?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            residual?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            addCollect?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            forfeiture?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }>
          validated?: Maybe<{
            original?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            penalty?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            newTicket?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            exchangedTicket: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            residual?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            addCollect?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            forfeiture?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }>
        }
      >
      totalWithUnusedTicket?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      total: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      fareTaxInfo?: Maybe<
        Array<
          Pick<FareTaxInfo, 'code' | 'name'> & {
            amount: {
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }
          }
        >
      >
    }
  >
  itineraryItemResponse: Array<Pick<ItineraryItem, 'code' | 'fieldName' | 'severity'>>
  messages?: Maybe<
    Array<Pick<DynamicSiteMessage, 'text' | 'header' | 'isDialog' | 'hasDesktopBannerImage'>>
  >
  holdRules?: Maybe<
    Pick<HoldRules, 'autoCancellationTime' | 'reasonsForNotAllowing' | 'holdAllowed'>
  >
  segments?: Maybe<
    Array<
      Pick<Segment, 'uuid' | 'position' | 'type'> & {
        hotel?: Maybe<
          Pick<
            HotelSegment,
            | 'alignedWithFlightDate'
            | 'name'
            | 'checkIn'
            | 'checkOut'
            | 'segmentState'
            | 'rateQualifier'
            | 'imageUrl'
            | 'id'
            | 'roomType'
            | 'hasDeposit'
            | 'source'
            | 'isReserved'
            | 'rulesAndRestrictions'
            | 'isCancellable'
            | 'starRating'
            | 'cancellationPolicy'
            | 'cancellationPolicyDescription'
            | 'roomDescription'
            | 'roomCount'
            | 'medianReviewRating'
            | 'isPreferred'
            | 'isEcoFriendly'
            | 'phone'
            | 'hotelId'
            | 'vendorCode'
            | 'alignState'
          > & {
            totalCost: Pick<Money, 'amount' | 'currency'>
            averageRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            baseRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            totalCostRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            feesAndTaxesRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            dailyRates: Array<
              Pick<DailyRate, 'date'> & {
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
            netRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            address: Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            numberOfGuests: Pick<NumberOfGuests, 'adults'>
            amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
            exclusiveAmenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
            photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
            preferredDescription?: Maybe<
              Pick<
                Preference,
                | 'id'
                | 'code'
                | 'highlight'
                | 'ownerType'
                | 'ownerName'
                | 'tooltipText'
                | 'shouldShowDefaultPreference'
              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
            >
            policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
              outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              outOfPolicyComplianceCodes?: Maybe<
                Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
              >
            }
            distance?: Maybe<Pick<Distance, 'value' | 'units'>>
            serviceFeeRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
          }
        >
        carService?: Maybe<
          Pick<
            CarServiceSegment,
            | 'carId'
            | 'key'
            | 'noRateText'
            | 'rateType'
            | 'name'
            | 'numberOfPassengers'
            | 'chargeDisclaimer'
            | 'cancellationPolicy'
            | 'isReserved'
            | 'duration'
            | 'logo'
          > & {
            preferredDescription?: Maybe<
              Pick<
                PreferredDescription,
                'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
              >
            >
            preferenceAttributes?: Maybe<
              Array<
                Pick<
                  Preference,
                  | 'id'
                  | 'code'
                  | 'highlight'
                  | 'ownerType'
                  | 'ownerName'
                  | 'tooltipText'
                  | 'shouldShowDefaultPreference'
                > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
              >
            >
            stops?: Maybe<
              Array<
                Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              >
            >
            pickUp: Pick<CarServicePickUp, 'time'> & {
              location?: Maybe<{
                flight: Pick<
                  AirportPickUpLocationFlight,
                  | 'airportName'
                  | 'isCommercial'
                  | 'carrierCode'
                  | 'flightNumber'
                  | 'dateTime'
                  | 'airportCode'
                >
                geocode: Pick<Geocode, 'lat' | 'long'>
              }>
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
            }
            dropOff: Pick<CarServiceDropOff, 'asDirected' | 'time'> & {
              location?: Maybe<
                Pick<
                  DirectedDropOffLocation,
                  'asDirected' | 'requestedHours' | 'requestedDuration' | 'asDirectedMultiDay'
                > & {
                  flight?: Maybe<
                    Pick<
                      AirportPickUpLocationFlight,
                      | 'airportName'
                      | 'isCommercial'
                      | 'carrierCode'
                      | 'flightNumber'
                      | 'dateTime'
                      | 'airportCode'
                    >
                  >
                  geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                }
              >
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
            }
            provider?: Maybe<Pick<CarServiceProvider, 'logo' | 'name'>>
            polylineGeocodes?: Maybe<Array<Pick<Geocode, 'lat' | 'long'>>>
            policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
              outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
            }
            car: Pick<CarServiceSegmentCar, 'capacity' | 'imageUrl' | 'description' | 'type'>
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
        carRental?: Maybe<
          Pick<
            CarRentalSegment,
            | 'carId'
            | 'name'
            | 'segmentState'
            | 'alignedWithFlightDate'
            | 'alignedWithFlightTime'
            | 'imageUrl'
            | 'carImageUrl'
            | 'vendorImage'
            | 'carClass'
            | 'carType'
            | 'carTransmission'
            | 'vendor'
            | 'vendorCode'
            | 'isCarAirConditioned'
            | 'contractName'
            | 'carCapacity'
            | 'carLuggage'
            | 'isReserved'
            | 'pickUpTime'
            | 'dropOffTime'
            | 'pickupLocationId'
            | 'dropoffLocationId'
            | 'isPreferred'
            | 'alignState'
          > & {
            carDetails: Pick<
              CarRentalSegmentCarDetails,
              | 'imageUrl'
              | 'class'
              | 'type'
              | 'transmission'
              | 'fuel'
              | 'capacity'
              | 'luggage'
              | 'isAirConditioned'
              | 'contractName'
            >
            vendorDetails: Pick<CarRentalSegmentVendorDetails, 'imageUrl' | 'code' | 'name'>
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            baseRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            totalCostRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            feesAndTaxesRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            netRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFeeRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFee?: Maybe<
              Pick<ServiceFee, 'id' | 'description'> & {
                rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            >
            carLocationType?: Maybe<{
              pickup: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
              dropoff: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
            }>
            policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
              outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              outOfPolicyComplianceCodes?: Maybe<
                Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
              >
            }
            preferredDescription?: Maybe<
              Pick<
                PreferredDescription,
                'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
              >
            >
            carCosts: Pick<CarCostsCarSegment, 'isMileageUnlimited'> & {
              extraDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
              extraHour?: Maybe<Pick<Money, 'amount' | 'currency'>>
              perDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
              perWeek?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
              extraDayRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              extraHourRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              perDayRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              perWeekRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }
            pickUpLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
              phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
              workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
              address: Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            }
            dropOffLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
              phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
              workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
              address: Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            }
            safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>
          }
        >
        trainLeg?: Maybe<
          Pick<
            TrainLegSegment,
            | 'legId'
            | 'departureDateTime'
            | 'arrivalDateTime'
            | 'rulesAndRestrictions'
            | 'departureStationCode'
            | 'arrivalStationCode'
            | 'travelStops'
            | 'imageUrl'
            | 'name'
            | 'isReserved'
            | 'isRefundable'
            | 'isEticket'
            | 'seatsRequested'
            | 'platform'
            | 'ecoInfo'
            | 'segmentState'
            | 'appliedRailCards'
            | 'routeType'
          > & {
            bookingFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            deliveryFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            deliveryOption?: Maybe<Pick<DeliveryOptions, 'name' | 'code'>>
            fareConditions?: Maybe<{
              tier: Pick<RailFareTier, 'id' | 'name'>
              localizedFareConditions: Array<
                Pick<
                  LocalizedFareConditions,
                  'fareTypeDescription' | 'summaryConditions' | 'language' | 'validityPeriod'
                > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
              >
            }>
            travelTime: Pick<TravelTime, 'hours' | 'minutes'>
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            taxes?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            transactionFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            totalCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
              outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              outOfPolicyComplianceCodes?: Maybe<
                Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
              >
            }
            penaltyFees?: Maybe<
              Array<
                Maybe<
                  Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                    rates?: Maybe<
                      Array<
                        Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                      >
                    >
                    penaltyFee?: Maybe<
                      Array<
                        Maybe<
                          Pick<
                            TrainPenaltyFee,
                            'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
                          > & { amount?: Maybe<Pick<Money, 'currency' | 'amount'>> }
                        >
                      >
                    >
                  }
                >
              >
            >
            tier?: Maybe<Pick<TrainTier, 'name' | 'description'>>
            segments: Array<
              Pick<
                TrainOrBusSegment,
                | 'segmentId'
                | 'imageUrl'
                | 'marketingServiceName'
                | 'type'
                | 'transportNumber'
                | 'trainId'
                | 'serviceClassName'
                | 'carrierCode'
                | 'carrierName'
                | 'service'
                | 'trainData'
                | 'segmentComment'
                | 'departureDate'
                | 'arrivalDate'
                | 'carrierLogoUrl'
                | 'transportationMode'
              > & {
                allocatedSeats?: Maybe<
                  Array<Pick<ItineraryTrainSegmentSeatInfo, 'seat' | 'carriage'>>
                >
                stops?: Maybe<Array<Pick<TrainStop, 'departingTime' | 'stationName'>>>
                changeDuration?: Maybe<Pick<TravelTime, 'minutes' | 'hours'>>
                travelTime: Pick<TravelTime, 'minutes' | 'hours'>
                amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                arrival: Pick<
                  TrainStationInfo,
                  'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                > & {
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
                departure: Pick<
                  TrainStationInfo,
                  'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                > & {
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
              }
            >
          }
        >
        flightLeg?: Maybe<
          Pick<
            FlightLegSegment,
            | 'departureDateTime'
            | 'segmentState'
            | 'arrivalDateTime'
            | 'legId'
            | 'flightStops'
            | 'stops'
            | 'fareRules'
            | 'fareAttributes'
            | 'isNDCFare'
            | 'isReserved'
            | 'isSameCarrierName'
            | 'isRoundTripLeg'
            | 'isRoundTripCost'
          > & {
            luggageOptions?: Maybe<
              Array<
                Pick<LuggageOption, 'code' | 'value'> & {
                  cost?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }
              >
            >
            flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
            totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
            fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
            rate: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            baseRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            netRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            totalCostRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            feesAndTaxesRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            unusedTicketRate?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
              outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              outOfPolicyComplianceCodes?: Maybe<
                Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
              >
            }
            segments: Array<
              Pick<
                FlightLegSubSegment,
                | 'segmentId'
                | 'name'
                | 'flightNumber'
                | 'travelerSeat'
                | 'travelerId'
                | 'carrierCode'
                | 'bookingCode'
                | 'departureDate'
                | 'arrivalDate'
                | 'imageUrl'
                | 'operatedBy'
                | 'isSeatAvailable'
                | 'isSeatSelectable'
                | 'isDepartAirportDifferentFromConnecting'
                | 'isInstantPurchaseCarrier'
                | 'serviceClass'
                | 'mealService'
                | 'bookingProvider'
                | 'originTerminal'
                | 'destinationTerminal'
                | 'percentageOnTime'
                | 'fare'
              > & {
                flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                images: Pick<FlightLegSubSegmentImages, 'square' | 'rectangle'>
                serviceFee?: Maybe<
                  Pick<ServiceFee, 'id' | 'description'> & {
                    rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                >
                penaltyFees?: Maybe<
                  Array<
                    Maybe<
                      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                        penaltyFee?: Maybe<
                          Array<
                            Maybe<
                              Pick<
                                TrainPenaltyFee,
                                | 'state'
                                | 'isRuleAllowed'
                                | 'effectiveStartDate'
                                | 'effectiveEndDate'
                              > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                            >
                          >
                        >
                        rates?: Maybe<
                          Array<
                            Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                          >
                        >
                      }
                    >
                  >
                >
                preferredDescription?: Maybe<
                  Pick<
                    Preference,
                    | 'id'
                    | 'code'
                    | 'highlight'
                    | 'ownerType'
                    | 'ownerName'
                    | 'tooltipText'
                    | 'shouldShowDefaultPreference'
                  > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                >
                preferenceAttributes?: Maybe<
                  Pick<
                    Preference,
                    | 'id'
                    | 'code'
                    | 'highlight'
                    | 'ownerType'
                    | 'ownerName'
                    | 'tooltipText'
                    | 'shouldShowDefaultPreference'
                  > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                >
                baggageFees?: Maybe<
                  Pick<BaggageFees, 'carrierUrl'> & {
                    items?: Maybe<
                      Array<
                        Maybe<
                          Pick<FlightBaggageFee, 'type'> & {
                            rate: {
                              primary: Pick<Money, 'currency' | 'amount'>
                              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                            }
                            baggageSize?: Maybe<{
                              lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                              upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                            }>
                            baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                            baggageWeight?: Maybe<{
                              lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                              upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                            }>
                          }
                        >
                      >
                    >
                  }
                >
                amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                plane?: Maybe<Pick<PlaneData, 'type' | 'onTime' | 'code'>>
                aircraft?: Maybe<Pick<AircraftData, 'type' | 'onTime' | 'code'>>
                departure: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'stateCode'
                      | 'postalCode'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'countryCode'
                      | 'locationName'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
                arrival: Pick<AirportAddress, 'airportCode' | 'airportName'> & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'stateCode'
                      | 'postalCode'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'countryCode'
                      | 'locationName'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
              }
            >
          }
        >
      }
    >
  >
  complianceMessage?: Maybe<
    Pick<ComplianceMessage, 'errors' | 'infos' | 'warns' | 'countryRestrictions'>
  >
  continuityMessage?: Maybe<
    Array<Pick<ContinuityMessage, 'code' | 'intent' | 'data' | 'followUpMessages' | 'segmentId'>>
  >
  overlappingTrips?: Maybe<Array<Pick<OverlappingTrip, 'name' | 'id'>>>
  airPriceChangeInfo?: Maybe<
    Pick<PriceChangeInfo, 'type' | 'reason'> & {
      amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
  >
  carPriceChangeInfo?: Maybe<
    Pick<PriceChangeInfo, 'type' | 'reason'> & {
      amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
  >
  priceChanges: {
    air?: Maybe<
      Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
        rate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    car?: Maybe<
      Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
        rate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
  }
  hotelLocationInformation?: Maybe<
    Array<
      Pick<
        HotelLocationInformation,
        'id' | 'name' | 'chainCode' | 'email' | 'additionalInformation'
      > & {
        location?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
        phoneNumber?: Maybe<
          Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
        >
        faxNumber?: Maybe<
          Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
        >
      }
    >
  >
  tripLevelPolicy?: Maybe<
    Array<
      Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
        outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        outOfPolicyComplianceCodes?: Maybe<
          Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
        >
      }
    >
  >
  railCards?: Maybe<
    Array<Pick<RailCard, 'code' | 'groupName' | 'name' | 'vendorCode' | 'vendorName'>>
  >
}

export type ItineraryMetadataFragment = Pick<ItineraryMetadata, 'alignAttemptedSegmentTypes'> & {
  hotelMatchDetails?: Maybe<
    Pick<
      HotelMatchDetails,
      'matchFound' | 'isExactMatch' | 'isRoomTypeDifferent' | 'isRoomRateDifferent'
    >
  >
  hotelSearch?: Maybe<
    Pick<HotelSearchParams, 'checkIn' | 'checkOut' | 'hotelKey' | 'hotelName'> & {
      address?: Maybe<
        Pick<
          Address,
          | 'airportCode'
          | 'locationName'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      >
    }
  >
  carRentalMatchDetails?: Maybe<Pick<MatchDetails, 'matchFound' | 'isExactMatch'>>
  carRentalSearch?: Maybe<{
    dropOff: Pick<
      CarRentalSearchLocationParams,
      'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
    > & {
      address: Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    }
    pickUp: Pick<
      CarRentalSearchLocationParams,
      'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
    > & {
      address: Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    }
  }>
  flightSearch?: Maybe<
    Array<
      Pick<
        FlightSearchParams,
        'originAirportCode' | 'destinationAirportCode' | 'departureDate' | 'serviceClass'
      >
    >
  >
  flightMatchDetails?: Maybe<
    Pick<
      FlightMatchDetails,
      | 'matchFound'
      | 'isExactMatch'
      | 'isFlightTimeMatch'
      | 'isFlightTimeWithinThreshold'
      | 'isFlightAirlineMatch'
    >
  >
}

export type CarRentalDomainFragment = { __typename: 'CarRental' } & Pick<
  CarRental,
  'dropOffDate' | 'dropOffTime' | 'key' | 'pickupDate' | 'pickupTime'
> & {
    dropOffLocation: Pick<CarRentalLocationDetails, 'isAirport' | 'locationId' | 'phone'> & {
      locationAddress: Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      openingHours: Array<
        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
          times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>>
        }
      >
    }
    pickupLocation: Pick<CarRentalLocationDetails, 'isAirport' | 'locationId' | 'phone'> & {
      locationAddress: Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      openingHours: Array<
        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
          times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>>
        }
      >
    }
    rate: Pick<CarRentalRate, 'daysCharged' | 'distanceUnlimited' | 'rateCode'> & {
      dailyRate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      extraDayCharge?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      extraHourCharge?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      totalRate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      totalTaxes: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }
    vendor: Pick<Vendor, 'code' | 'imageUrl' | 'name'>
    vehicle: Pick<
      CarRentalVehicle,
      | 'baggageCapacity'
      | 'category'
      | 'fuelType'
      | 'isAirCondition'
      | 'modelName'
      | 'passengerCapacity'
      | 'transmissionType'
      | 'vehicleClass'
      | 'imageUrl'
    >
    termsAndConditions: Array<Pick<CarRules, 'header' | 'value'>>
    safetyCheck: Pick<CarRentalSafetyCheck, 'badgeUrl' | 'messageCode' | 'url'>
  }

export type CarRentalLocationDetailsFragment = Pick<
  CarRentalLocationDetails,
  'isAirport' | 'locationId' | 'phone'
> & {
  locationAddress: Pick<
    Address,
    | 'airportCode'
    | 'locationName'
    | 'street1'
    | 'street2'
    | 'city'
    | 'stateCode'
    | 'postalCode'
    | 'countryCode'
  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
  openingHours: Array<
    Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
      times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>>
    }
  >
}

export type OpeningHoursFragment = Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
  times: Array<Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>>
}

export type HotelDomainFragment = { __typename: 'HotelRoomDetails' } & Pick<
  HotelRoomDetails,
  | 'hotelId'
  | 'name'
  | 'checkIn'
  | 'checkOut'
  | 'starRating'
  | 'medianReviewRating'
  | 'phoneNumber'
  | 'chainCode'
  | 'logoPhotoUrl'
  | 'isPreferred'
  | 'hasDeposit'
  | 'isCancellable'
  | 'isEcoFriendly'
  | 'notes'
> & {
    address: Pick<
      Address,
      | 'airportCode'
      | 'locationName'
      | 'street1'
      | 'street2'
      | 'city'
      | 'stateCode'
      | 'postalCode'
      | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    distance: Pick<Distance, 'value' | 'units'>
    selectedRoom: Pick<
      Room,
      | 'id'
      | 'type'
      | 'description'
      | 'inventoryId'
      | 'isCorporateRate'
      | 'isFreeCancellable'
      | 'source'
      | 'isSelectable'
      | 'cancellationPolicy'
      | 'cancellationPolicyDescription'
      | 'preferredTag'
      | 'rateQualifier'
      | 'numberOfGuests'
    > & {
      totalCost: Pick<Money, 'amount' | 'currency'>
      feesAndTaxes: Pick<Money, 'amount' | 'currency'>
      averageRate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      baseRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      rate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      dailyRates: Array<
        Pick<DailyRate, 'date'> & {
          rate: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }
      >
      policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
        outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
      }
    }
    preference?: Maybe<
      Pick<
        Preference,
        | 'id'
        | 'code'
        | 'highlight'
        | 'ownerType'
        | 'ownerName'
        | 'tooltipText'
        | 'shouldShowDefaultPreference'
      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
    >
    policy?: Maybe<
      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
        outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
      }
    >
    photos: Array<Pick<Photo, 'index' | 'url' | 'size'>>
    amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
    exclusiveAmenities?: Maybe<Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>>
  }

export type DistanceFragment = Pick<Distance, 'value' | 'units'>

export type RoomFragment = Pick<
  Room,
  | 'id'
  | 'type'
  | 'description'
  | 'inventoryId'
  | 'isCorporateRate'
  | 'isFreeCancellable'
  | 'source'
  | 'isSelectable'
  | 'cancellationPolicy'
  | 'cancellationPolicyDescription'
  | 'preferredTag'
  | 'rateQualifier'
  | 'numberOfGuests'
> & {
  totalCost: Pick<Money, 'amount' | 'currency'>
  feesAndTaxes: Pick<Money, 'amount' | 'currency'>
  averageRate: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  baseRate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  rate?: Maybe<{
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }>
  dailyRates: Array<
    Pick<DailyRate, 'date'> & {
      rate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }
  >
  policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
    outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
  }
}

export type PhotoFragment = Pick<Photo, 'index' | 'url' | 'size'>

export type AmenityFragment = Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>

export type FlightDomainFragment = { __typename: 'FlightTripResult' } & {
  flightLegs: Array<
    Pick<
      FlightLegResult,
      | 'legId'
      | 'stops'
      | 'departureDateTime'
      | 'arrivalDateTime'
      | 'fareRules'
      | 'fareAttributes'
      | 'isReserved'
      | 'isRoundTripCost'
      | 'isRoundTripLeg'
      | 'isSameCarrierName'
    > & {
      flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
      totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
      luggageOptions?: Maybe<Array<Pick<LuggageOption, 'code' | 'value'>>>
      policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
        outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
      }
      rate: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      baseRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      feesAndTaxesRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      unusedTicketRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCostRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
      segments: Array<
        Pick<
          FlightLegSegmentResult,
          | 'segmentId'
          | 'arrivalDate'
          | 'departureDate'
          | 'name'
          | 'flightNumber'
          | 'carrierCode'
          | 'bookingCode'
          | 'fare'
          | 'mealService'
          | 'bookingProvider'
          | 'operatedBy'
          | 'originTerminal'
          | 'destinationTerminal'
          | 'percentageOnTime'
          | 'isDepartAirportDifferentFromConnecting'
          | 'serviceClass'
        > & {
          flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
          departure: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
            address?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
          }
          arrival: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
            address?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
          }
          baggageFees?: Maybe<
            Pick<BaggageFees, 'carrierUrl'> & {
              items?: Maybe<
                Array<
                  Maybe<
                    Pick<FlightBaggageFee, 'type'> & {
                      rate: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                      baggageWeight?: Maybe<{
                        lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                        upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                      }>
                      baggageSize?: Maybe<{
                        lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                        upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                      }>
                    }
                  >
                >
              >
            }
          >
          amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
          aircraft?: Maybe<Pick<AircraftData, 'type' | 'code' | 'onTime'>>
          preferredDescription?: Maybe<
            Pick<
              Preference,
              | 'id'
              | 'code'
              | 'highlight'
              | 'ownerType'
              | 'ownerName'
              | 'tooltipText'
              | 'shouldShowDefaultPreference'
            > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
          >
          penaltyFees?: Maybe<
            Array<
              Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                rates?: Maybe<
                  Array<
                    Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                  >
                >
                penaltyFee?: Maybe<
                  Array<
                    Maybe<
                      Pick<
                        TrainPenaltyFee,
                        'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
                      > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                    >
                  >
                >
              }
            >
          >
          serviceFee?: Maybe<
            Pick<ServiceFee, 'description' | 'id'> & {
              rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          >
          codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
          preferenceAttributes?: Maybe<
            Pick<
              Preference,
              | 'id'
              | 'code'
              | 'highlight'
              | 'ownerType'
              | 'ownerName'
              | 'tooltipText'
              | 'shouldShowDefaultPreference'
            > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
          >
        }
      >
    }
  >
}

export type TravelTimeFragment = Pick<TravelTime, 'hours' | 'minutes'>

export type LuggageOptionFragment = Pick<LuggageOption, 'code' | 'value'>

export type FareLabelFragment = Pick<FareLabel, 'text' | 'tooltip'>

export type FlightLegSegmentFragment = Pick<
  FlightLegSegmentResult,
  | 'segmentId'
  | 'arrivalDate'
  | 'departureDate'
  | 'name'
  | 'flightNumber'
  | 'carrierCode'
  | 'bookingCode'
  | 'fare'
  | 'mealService'
  | 'bookingProvider'
  | 'operatedBy'
  | 'originTerminal'
  | 'destinationTerminal'
  | 'percentageOnTime'
  | 'isDepartAirportDifferentFromConnecting'
  | 'serviceClass'
> & {
  flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
  departure: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
    address?: Maybe<
      Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    >
  }
  arrival: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
    address?: Maybe<
      Pick<
        Address,
        | 'airportCode'
        | 'locationName'
        | 'street1'
        | 'street2'
        | 'city'
        | 'stateCode'
        | 'postalCode'
        | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    >
  }
  baggageFees?: Maybe<
    Pick<BaggageFees, 'carrierUrl'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<FlightBaggageFee, 'type'> & {
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
              baggageWeight?: Maybe<{
                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
              }>
              baggageSize?: Maybe<{
                lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
              }>
            }
          >
        >
      >
    }
  >
  amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
  aircraft?: Maybe<Pick<AircraftData, 'type' | 'code' | 'onTime'>>
  preferredDescription?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
  penaltyFees?: Maybe<
    Array<
      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
        rates?: Maybe<
          Array<
            Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          >
        >
        penaltyFee?: Maybe<
          Array<
            Maybe<
              Pick<
                TrainPenaltyFee,
                'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
              > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
            >
          >
        >
      }
    >
  >
  serviceFee?: Maybe<
    Pick<ServiceFee, 'description' | 'id'> & { rate?: Maybe<Pick<Money, 'amount' | 'currency'>> }
  >
  codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
  preferenceAttributes?: Maybe<
    Pick<
      Preference,
      | 'id'
      | 'code'
      | 'highlight'
      | 'ownerType'
      | 'ownerName'
      | 'tooltipText'
      | 'shouldShowDefaultPreference'
    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
  >
}

export type BaggageFeesFragment = Pick<BaggageFees, 'carrierUrl'> & {
  items?: Maybe<
    Array<
      Maybe<
        Pick<FlightBaggageFee, 'type'> & {
          rate: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
          baggageWeight?: Maybe<{
            lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
            upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
          }>
          baggageSize?: Maybe<{
            lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
            upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
          }>
        }
      >
    >
  >
}

export type CartSummaryFragment = Pick<CartSummary, 'startDate' | 'endDate'> & {
  cartCost: {
    total: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
    payLater: {
      domainCosts: Array<
        Pick<DomainCostSummary, 'domain'> & {
          totalTaxes: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          totalRate: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          totalCost: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }
      >
      subTotal: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }
  }
}

export type CartCostSummaryFragment = {
  subTotal: {
    primary: Pick<Money, 'amount' | 'currency'>
    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
  }
  intentCosts: Array<
    Pick<IntentCost, 'domain' | 'passengerCount'> & {
      baseCost: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
      taxes?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      serviceFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      transactionFee?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      totalCost: {
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    }
  >
}

export type MoneyTicketsFragment = Pick<Money, 'amount' | 'currency'>

export type RateTicketsFragment = {
  primary: Pick<Money, 'amount' | 'currency'>
  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
}

export type AdditionalInformationFragment = { __typename: 'AdditionalInformation' } & Pick<
  AdditionalInformation,
  'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
> & {
    additionalFieldProperties:
      | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
          CheckBoxAdditionalInformation,
          'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
        >)
      | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
          DateTimeAdditionalInformation,
          | 'isAllowedPastDateTime'
          | 'displayYear'
          | 'displayMonth'
          | 'displayDay'
          | 'displayHour'
          | 'displayMinute'
          | 'displayTimeZone'
          | 'minYear'
          | 'maxYear'
          | 'minMonth'
          | 'maxMonth'
          | 'minDay'
          | 'maxDay'
          | 'dateValue'
        >)
      | ({ __typename: 'ListAdditionalInformation' } & Pick<
          ListAdditionalInformation,
          'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
        > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
      | ({ __typename: 'NumberAdditionalInformation' } & Pick<
          NumberAdditionalInformation,
          | 'defaultNumber'
          | 'minNumber'
          | 'maxNumber'
          | 'numberDecimal'
          | 'numberType'
          | 'numberValue'
        >)
      | ({ __typename: 'TextAdditionalInformation' } & Pick<
          TextAdditionalInformation,
          'singleLine' | 'numbersOfLine' | 'minLength' | 'maxLength' | 'defaultText' | 'textValue'
        >)
  }

export type TextAdditionalInformationFragment = { __typename: 'TextAdditionalInformation' } & Pick<
  TextAdditionalInformation,
  'singleLine' | 'numbersOfLine' | 'minLength' | 'maxLength' | 'defaultText' | 'textValue'
>

export type NumberAdditionalInformationFragment = {
  __typename: 'NumberAdditionalInformation'
} & Pick<
  NumberAdditionalInformation,
  'defaultNumber' | 'minNumber' | 'maxNumber' | 'numberDecimal' | 'numberType' | 'numberValue'
>

export type CheckBoxAdditionalInformationFragment = {
  __typename: 'CheckBoxAdditionalInformation'
} & Pick<CheckBoxAdditionalInformation, 'defaultChecked' | 'checkedRequired' | 'checkBoxValue'>

export type ListAdditionalInformationFragment = { __typename: 'ListAdditionalInformation' } & Pick<
  ListAdditionalInformation,
  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
> & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> }

export type DateTimeAdditionalInformationFragment = {
  __typename: 'DateTimeAdditionalInformation'
} & Pick<
  DateTimeAdditionalInformation,
  | 'isAllowedPastDateTime'
  | 'displayYear'
  | 'displayMonth'
  | 'displayDay'
  | 'displayHour'
  | 'displayMinute'
  | 'displayTimeZone'
  | 'minYear'
  | 'maxYear'
  | 'minMonth'
  | 'maxMonth'
  | 'minDay'
  | 'maxDay'
  | 'dateValue'
>

export type AckNotificationEventMutationVariables = Exact<{
  input: AckNotificationEventInput
}>

export type AckNotificationEventMutation = {
  ackNotificationEvent: Pick<BaseResponse, 'code' | 'errorCodes' | 'message' | 'success'>
}

export type AddCarServiceMutationVariables = Exact<{
  itineraryId: Scalars['String']
  key: Scalars['String']
}>

export type AddCarServiceMutation = {
  addCarService: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type AddCreditCardMutationVariables = Exact<{
  input: AddCreditCardInput
}>

export type AddCreditCardMutation = { addCreditCard: Pick<BaseResponse, 'success'> }

export type AddSeatAsssignmentMutationVariables = Exact<{
  input: AddSeatAssignmentInput
}>

export type AddSeatAsssignmentMutation = {
  addSeatAssignment: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type BookRailItineraryMutationVariables = Exact<{
  input: BookItineraryInput
}>

export type BookRailItineraryMutation = {
  bookRailItinerary: Pick<BaseResponse, 'success' | 'errorCodes'>
}

export type BookRideHailWithErrorMutationVariables = Exact<{
  input: BookRideHailRequest
}>

export type BookRideHailWithErrorMutation = {
  bookRideHailWithError:
    | ({ __typename: 'BookRideHailErrorResponse' } & Pick<BookRideHailErrorResponse, 'reason'>)
    | ({ __typename: 'BookRideHailResponse' } & Pick<BookRideHailResponse, 'bookingID'> & {
          bookingInfo?: Maybe<
            Pick<
              RideHailBooking,
              | 'bookingId'
              | 'description'
              | 'dropOffAddress'
              | 'pickUpAddress'
              | 'pickUpTime'
              | 'productName'
              | 'status'
            > & {
              estimatedPriceRange?: Maybe<{
                primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
                secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
              }>
              price?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }
          >
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type ClearDomainRcSessionCookieMutationVariables = Exact<{ [key: string]: never }>

export type ClearDomainRcSessionCookieMutation = Pick<Mutation, 'clearDomainRcSessionCookie'>

export type ClearSiteRcSessionCookieMutationVariables = Exact<{ [key: string]: never }>

export type ClearSiteRcSessionCookieMutation = Pick<Mutation, 'clearSiteRcSessionCookie'>

export type CreateRailItineraryMutationVariables = Exact<{
  input?: InputMaybe<CreateItineraryInput>
}>

export type CreateRailItineraryMutation = { createRailItinerary: Pick<CreateItineraryResult, 'id'> }

export type CreateRailSearchMutationVariables = Exact<{
  input: CreateRailSearchInput
}>

export type CreateRailSearchMutation = {
  createRailSearch?: Maybe<Pick<CreateRailSearchPayload, 'id'>>
}

export type EditCreditCardMutationVariables = Exact<{
  input: EditCreditCardInput
}>

export type EditCreditCardMutation = { editCreditCard: Pick<BaseResponse, 'success'> }

export type LogEventMutationVariables = Exact<{
  input: LogEventInput
}>

export type LogEventMutation = { logEvent: Pick<BaseResponse, 'code' | 'success' | 'message'> }

export type LoginMutationVariables = Exact<{ [key: string]: never }>

export type LoginMutation = { login: Pick<Login, 'token'> }

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = Pick<Mutation, 'logout'>

export type RegisterClientForNotificationMutationVariables = Exact<{
  input: RegisterClientForNotificationInput
}>

export type RegisterClientForNotificationMutation = {
  registerClientForNotification: Pick<RegisterNotificationResponse, 'clientId'>
}

export type RegisterPushNotificationClientMutationVariables = Exact<{
  input: RegisterPushNotificationClientInput
}>

export type RegisterPushNotificationClientMutation = {
  registerPushNotificationClient: Pick<BaseResponse, 'success'>
}

export type RemoveCarServiceMutationVariables = Exact<{
  itineraryId: Scalars['String']
  carKey: Scalars['String']
}>

export type RemoveCarServiceMutation = {
  removeCarService: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type RemoveSeatAsssignmentMutationVariables = Exact<{
  input: RemoveSeatAssignmentInput
}>

export type RemoveSeatAsssignmentMutation = {
  removeSeatAssignment: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type ReplaceFlightMutationVariables = Exact<{
  input: ReplaceFlightInput
}>

export type ReplaceFlightMutation = {
  replaceFlight: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type ToggleNotificationPreferenceMutationVariables = Exact<{
  input: ToggleNotificationPreferenceInput
}>

export type ToggleNotificationPreferenceMutation = {
  toggleNotificationPreference: Pick<BaseResponse, 'code' | 'errorCodes' | 'message' | 'success'>
}

export type UberEstimateMutationVariables = Exact<{
  input: UberEstimateInput
}>

export type UberEstimateMutation = {
  uberEstimate: {
    error?: Maybe<Pick<UberError, 'message' | 'uberHTTPStatusCode' | 'uberErrorCode' | 'type'>>
    productEstimates?: Maybe<
      Array<
        Pick<UberProductEstimate, 'seatCount'> & {
          product: Pick<
            UberProduct,
            | 'productId'
            | 'vehicleViewId'
            | 'displayName'
            | 'description'
            | 'shortDescription'
            | 'parentProductTypeId'
            | 'image'
            | 'backgroundImage'
            | 'schedulingEnabled'
            | 'upfrontFareEnabled'
            | 'shared'
            | 'capacity'
          > & {
            reserveInfo: Pick<
              UberProductReserveInfo,
              'enabled' | 'scheduledThresholdMinutes' | 'freeCancellationThresholdMinutes'
            >
          }
          estimateInfo: Pick<
            UberProductEstimateInfo,
            'fareId' | 'noCarsAvailable' | 'pricingExplanation'
          > & {
            trip: Pick<UberProductEstimateTripInfo, 'durationEstimate'>
            estimate?: Maybe<
              Pick<
                UberProductEstimateInfoEstimate,
                'display' | 'lowEstimate' | 'highEstimate' | 'currency'
              >
            >
            fare?: Maybe<
              Pick<
                UberProductEstimateFareInfo,
                'fareId' | 'value' | 'currencyCode' | 'display' | 'expiresAt' | 'surgeMultiplier'
              > & {
                fareBreakdown: Array<
                  Pick<UberProductEstimateFareBreakdownItem, 'type' | 'value' | 'name' | 'notice'>
                >
              }
            >
          }
        }
      >
    >
  }
}

export type UnregisterClientFromNotificationMutationVariables = Exact<{
  clientId: Scalars['ID']
}>

export type UnregisterClientFromNotificationMutation = {
  unregisterClientFromNotification: Pick<
    BaseResponse,
    'code' | 'errorCodes' | 'message' | 'success'
  >
}

export type UpdateAirPreferenceExcerptMutationVariables = Exact<{
  input: AirPreferenceExcerptInput
}>

export type UpdateAirPreferenceExcerptMutation = {
  updateAirPreferenceExcerpt: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type UpdateDelegatedIdCookieMutationVariables = Exact<{
  input: UpdateDelegatedIdCookieInput
}>

export type UpdateDelegatedIdCookieMutation = {
  updateDelegatedIdCookie: Pick<UpdateDelegatedIdCookieResponse, 'success'>
}

export type UpdateDelegatedIdTokenMutationVariables = Exact<{
  input: UpdateDelegatedIdTokenInput
}>

export type UpdateDelegatedIdTokenMutation = {
  updateDelegatedIdToken: Pick<UpdateDelegatedIdTokenResponse, 'token'>
}

export type UpdateRailJourneyPreferencesMutationVariables = Exact<{
  input: RailPreferencesInput
}>

export type UpdateRailJourneyPreferencesMutation = {
  updateRailJourneyPreferences: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type UpdateTokensWithCookieMutationVariables = Exact<{ [key: string]: never }>

export type UpdateTokensWithCookieMutation = Pick<Mutation, 'updateTokensWithCookie'>

export type UpdateTokensMutationVariables = Exact<{
  input: UpdateTokensInput
}>

export type UpdateTokensMutation = { updateTokens: Pick<UpdateTokensResponse, 'token'> }

export type UpdateTripNameByProcessIdMutationVariables = Exact<{
  input: UpdateTripNameByProcessIdInput
}>

export type UpdateTripNameByProcessIdMutation = {
  updateTripNameByProcessId: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type UpdateUserMutationVariables = Exact<{
  input: UserInput
}>

export type UpdateUserMutation = { user: Pick<BaseResponse, 'success' | 'message' | 'code'> }

export type GetAirSeatMapQueryVariables = Exact<{
  input: SeatMapInput
}>

export type GetAirSeatMapQuery = {
  seatMap: Pick<
    SeatMapResult,
    'departureDate' | 'flightNumber' | 'origin' | 'destination' | 'readOnly'
  > & {
    carrier: Pick<FlightCarrier, 'id' | 'name' | 'imageUrl'>
    rows: Array<
      Pick<SeatMapRow, 'number' | 'cabinClass'> & {
        seat: Array<
          Pick<
            SeatMapRowSeat,
            | 'number'
            | 'column'
            | 'available'
            | 'isOverWing'
            | 'isNearWindow'
            | 'isPaid'
            | 'isNearAisle'
            | 'isPremium'
            | 'isExitRowSeat'
            | 'hasBulkhead'
            | 'isUpperDeck'
            | 'hasGalley'
            | 'hasLavatory'
            | 'isSmokingAllowed'
            | 'isSuitableForHandicapped'
          >
        >
      }
    >
  }
}

export type GetAirportDetailsQueryVariables = Exact<{
  airportCode: Scalars['String']
}>

export type GetAirportDetailsQuery = {
  airportDetails: { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
}

export type GetAlertsQueryVariables = Exact<{
  input?: InputMaybe<AlertsInput>
}>

export type GetAlertsQuery = {
  alerts: Pick<Alerts, 'totalAlerts'> & {
    alerts: Array<Pick<AlertItem, 'title' | 'message' | 'kind' | 'docId'>>
  }
}

export type GetAutocompletePlacesQueryVariables = Exact<{
  input: PlacesAutocompleteInput
}>

export type GetAutocompletePlacesQuery = {
  autocompletePlaces: Array<
    Pick<
      PlaceAutocomplete,
      | 'placeId'
      | 'airportCode'
      | 'name'
      | 'shortName'
      | 'isHotelAddress'
      | 'latitude'
      | 'longitude'
      | 'placeType'
      | 'locationType'
      | 'uniqueCode'
      | 'isGrouped'
      | 'timezone'
    > & {
      address?: Maybe<
        Pick<
          PlaceAutocompleteAddress,
          | 'city'
          | 'countryCode'
          | 'countryName'
          | 'stateCode'
          | 'postalCode'
          | 'street1'
          | 'lat'
          | 'lng'
        >
      >
      subPlaces?: Maybe<
        Array<
          Pick<
            PlaceAutocomplete,
            | 'placeId'
            | 'airportCode'
            | 'name'
            | 'shortName'
            | 'isHotelAddress'
            | 'latitude'
            | 'longitude'
            | 'placeType'
            | 'locationType'
            | 'uniqueCode'
            | 'isGrouped'
            | 'timezone'
          > & {
            address?: Maybe<
              Pick<
                PlaceAutocompleteAddress,
                | 'city'
                | 'countryCode'
                | 'countryName'
                | 'stateCode'
                | 'postalCode'
                | 'street1'
                | 'lat'
                | 'lng'
              >
            >
          }
        >
      >
    }
  >
}

export type GetBookRideHailQueryVariables = Exact<{
  input: BookRideHailRequest
}>

export type GetBookRideHailQuery = { bookRideHail: Pick<BookRideHailResponse, 'bookingID'> }

export type CalculateCarEmissionsBatchQueryVariables = Exact<{
  input: CalculateCarEmissionsBatchInput
}>

export type CalculateCarEmissionsBatchQuery = {
  calculateCarEmissionsBatch: Pick<
    CalculateCarEmissionsBatchResult,
    'averageEmissionsTonnes' | 'errors'
  > & {
    cars?: Maybe<
      Array<
        Pick<CalculateCarEmissionsBatchResultCar, 'customRef' | 'tonnesOfEmissions'> & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
  }
}

export type CalculateFlightEmissionsBatchQueryVariables = Exact<{
  input: CalculateFlightEmissionsBatchInput
}>

export type CalculateFlightEmissionsBatchQuery = {
  calculateFlightEmissionsBatch: Pick<
    CalculateFlightEmissionsBatchResult,
    'averageEmissionsTonnes' | 'errors'
  > & {
    flights?: Maybe<
      Array<
        Pick<CalculateFlightEmissionsBatchResultFlight, 'customRef' | 'tonnesOfEmissions'> & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
  }
}

export type CalculateHotelEmissionsBatchQueryVariables = Exact<{
  input: CalculateHotelEmissionsBatchInput
}>

export type CalculateHotelEmissionsBatchQuery = {
  calculateHotelEmissionsBatch: Pick<
    CalculateHotelEmissionsBatchResult,
    'averageEmissionsTonnes' | 'averageSustainabilityScore' | 'errors'
  > & {
    hotels?: Maybe<
      Array<
        Pick<
          CalculateHotelEmissionsBatchResultHotel,
          'customRef' | 'tonnesOfEmissions' | 'sustainabilityScore'
        > & {
          certifications: Array<
            Pick<CalculateEmissionsResultHotelCertification, 'isGSTCApproved' | 'name' | 'type'>
          >
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
  }
}

export type CalculateRailEmissionsBatchQueryVariables = Exact<{
  input: CalculateRailEmissionsBatchInput
}>

export type CalculateRailEmissionsBatchQuery = {
  calculateRailEmissionsBatch: Pick<
    CalculateRailEmissionsBatchResult,
    'averageEmissionsTonnes' | 'errors'
  > & {
    rails?: Maybe<
      Array<
        Pick<
          CalculateRailEmissionsBatchResultRail,
          'customRef' | 'travelClass' | 'tonnesOfEmissions'
        > & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
  }
}

export type CalculateTripEmissionsQueryVariables = Exact<{
  input: CalculateTripEmissionsInput
}>

export type CalculateTripEmissionsQuery = {
  calculateTripEmissions: {
    hotels?: Maybe<
      Array<
        Pick<
          CalculateTripEmissionsResultHotel,
          | 'customRef'
          | 'averageLocationEmissionsTonnes'
          | 'averageLocationSustainabilityScore'
          | 'sustainabilityScore'
          | 'tonnesOfEmissions'
        > & {
          certifications: Array<
            Pick<CalculateEmissionsResultHotelCertification, 'isGSTCApproved' | 'name' | 'type'>
          >
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
    flights?: Maybe<
      Array<
        Pick<
          CalculateTripEmissionsResultFlight,
          'tonnesOfEmissions' | 'averageRouteEmissionTonnes' | 'customRef'
        > & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
    rails?: Maybe<
      Array<
        Pick<
          CalculateTripEmissionsResultRail,
          'tonnesOfEmissions' | 'averageRouteEmissionTonnes' | 'customRef'
        > & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
    cars?: Maybe<
      Array<
        Pick<
          CalculateTripEmissionsResultCar,
          'tonnesOfEmissions' | 'averageEmissionTonnes' | 'customRef'
        > & {
          equivalences: Array<
            Pick<CalculateEmissionsBatchResultEquivalence, 'amount' | 'type' | 'unit'>
          >
        }
      >
    >
    errors?: Maybe<Array<Pick<CalculateTripEmissionsResultError, 'message' | 'type'>>>
  }
}

export type GetCustomerUberAccountStatusQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerUberAccountStatusQuery = {
  customerUberAccountStatus: Pick<
    CustomerUberAccountStatusResponse,
    'status' | 'linkingUrl' | 'userAccountSelfCreationEnabled'
  >
}

export type GetDeemHeaderQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  meetingsModeElements?: InputMaybe<MeetingsModeSessionElements>
}>

export type GetDeemHeaderQuery = {
  deemHeader: Pick<DeemHeader, 'header' | 'headerAssets' | 'footer'> & {
    metadata?: Maybe<{ brandingMetadata: Pick<DeemHeaderBrandingMetadata, 'siteName'> }>
  }
}

export type GetDelegatedUsersQueryVariables = Exact<{
  input: DelegatedUsersInput
}>

export type GetDelegatedUsersQuery = {
  delegatedUsers?: Maybe<{
    delegatedUsers: Array<Pick<Delegate, 'email' | 'firstName' | 'lastName' | 'siteId' | 'userId'>>
  }>
}

export type GetFlightFareRulesQueryVariables = Exact<{
  input: FlightFareRulesInput
}>

export type GetFlightFareRulesQuery = {
  flightFareRules: { rules: Array<Maybe<Pick<FlightFareRule, 'header' | 'value'>>> }
}

export type GetGeosureScoresQueryVariables = Exact<{
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}>

export type GetGeosureScoresQuery = {
  geosureScores: Pick<GeosureScoresResult, 'compositeScore' | 'distance' | 'district' | 'city'> & {
    scores: Array<Pick<GeosureScore, 'value' | 'label'>>
  }
}

export type GetHotelNamesQueryVariables = Exact<{
  logsMetadata?: InputMaybe<LogsMetadata>
  hotelResultId?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  hotelName?: InputMaybe<Scalars['String']>
  distance?: InputMaybe<Scalars['Float']>
  starRatings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  amenityIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  guests: Scalars['Int']
  groupId?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  airportCode?: InputMaybe<Scalars['String']>
  stationCode?: InputMaybe<Scalars['String']>
  locationName?: InputMaybe<Scalars['String']>
  nameSearch?: InputMaybe<Scalars['Boolean']>
  hideSoldOut?: InputMaybe<Scalars['Boolean']>
  deviceClass?: InputMaybe<DeviceClass>
}>

export type GetHotelNamesQuery = { hotelNames?: Maybe<Pick<HotelNamesResult, 'hotelNames'>> }

export type GetHotelReviewsQueryVariables = Exact<{
  hotelId: Scalars['ID']
  logsMetadata?: InputMaybe<LogsMetadata>
}>

export type GetHotelReviewsQuery = {
  hotelReviews: Pick<
    HotelReviewsResult,
    | 'reviewsProvider'
    | 'totalReviewCount'
    | 'medianReviewRating'
    | 'medianReviewRatingImageUrl'
    | 'hotelReviewsDetailsUrl'
  > & {
    reviews: Array<
      Maybe<
        Pick<
          HotelReview,
          'title' | 'summary' | 'content' | 'date' | 'userRating' | 'ratingImageUrl'
        >
      >
    >
  }
}

export type GetHotelRoomDetailsQueryVariables = Exact<{
  input: HotelRoomDetailsInput
  logsMetadata?: InputMaybe<LogsMetadata>
}>

export type GetHotelRoomDetailsQuery = { hotelRoomDetails: Pick<RoomDetails, 'id' | 'rateDetails'> }

export type GetIdentityHashQueryVariables = Exact<{
  email: Scalars['String']
}>

export type GetIdentityHashQuery = { identityHash: Pick<HeapIdentity, 'identityHash'> }

export type GetLocationRefinementOptionsQueryVariables = Exact<{
  input: LocationRefinementOptionsRequest
}>

export type GetLocationRefinementOptionsQuery = {
  getLocationRefinementOptions:
    | ({ __typename: 'LocationRefinementErrorResponse' } & Pick<
        LocationRefinementErrorResponse,
        'reason'
      >)
    | ({ __typename: 'LocationRefinementOptionsResponse' } & {
        zoneInformation: {
          accessPoints: Array<
            Pick<LocationRefinementAccessPoint, 'id' | 'label' | 'note'> & {
              location: Pick<LocationRefinementLocation, 'address'> & {
                geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
              }
            }
          >
          rootZone?: Maybe<
            Pick<LocationRefinementZone, 'name' | 'polygon'> & {
              childZones: Array<
                Pick<LocationRefinementZone, 'name' | 'polygon'> & {
                  accessPoints: Array<
                    Pick<LocationRefinementAccessPoint, 'id' | 'label' | 'note'> & {
                      location: Pick<LocationRefinementLocation, 'address'> & {
                        geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                      }
                    }
                  >
                  childZones: Array<Pick<LocationRefinementZone, 'name' | 'polygon'>>
                  location: Pick<LocationRefinementLocation, 'address'> & {
                    geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                  }
                }
              >
              accessPoints: Array<
                Pick<LocationRefinementAccessPoint, 'id' | 'label' | 'note'> & {
                  location: Pick<LocationRefinementLocation, 'address'> & {
                    geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                  }
                }
              >
            }
          >
        }
      })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type MultiLayerCostSegmentsQueryVariables = Exact<{ [key: string]: never }>

export type MultiLayerCostSegmentsQuery = {
  multiLayerCostSegments?: Maybe<
    Pick<
      MultiLayerCostSegments,
      'topLayer' | 'firstChildLayer' | 'secondChildLayer' | 'bottomLayer'
    >
  >
}

export type GetMultiTierCostAllocationQueryVariables = Exact<{
  input?: InputMaybe<MultiTierCostAllocationInput>
}>

export type GetMultiTierCostAllocationQuery = {
  multiTierCostAllocation: Pick<CostAllocation, 'totalResults'> & {
    allocation: Array<
      Pick<CostAllocationUnit, 'name' | 'code' | 'segmentId' | 'allocationId'> & {
        ancestors?: Maybe<
          Array<Pick<CostAllocationAncestor, 'name' | 'segmentId' | 'allocationId'>>
        >
      }
    >
  }
}

export type GetPreBookInfoQueryVariables = Exact<{
  input: BookItineraryInput
}>

export type GetPreBookInfoQuery = {
  preBookInfo: Pick<PreBookInfoPayload, 'isBookingAllowed' | 'isTermsUserInteractionAllowed'> & {
    termsAndConditions?: Maybe<Array<Pick<TermsAndConditions, 'format' | 'title' | 'content'>>>
  }
}

export type GetRailCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetRailCardsQuery = {
  railCards: {
    cards: Array<Pick<RailCard, 'groupName' | 'vendorName' | 'vendorCode' | 'name' | 'code'>>
  }
}

export type GetRailFareAttributesQueryVariables = Exact<{
  input: RailFareAttributesInput
}>

export type GetRailFareAttributesQuery = {
  railFareAttributes: {
    fareAttributes: Array<
      Pick<FareAttributes, 'fareId'> & {
        comparison: {
          brand: Pick<FlightComparisonBrand, 'name' | 'codes'>
          utas: Array<
            Pick<
              UniversalTicketAttribute,
              'category' | 'headline' | 'description' | 'assessment'
            > & {
              callToAction?: Maybe<Pick<UniversalTicketAttributeCallToAction, 'text' | 'url'>>
              feeRange?: Maybe<
                Array<
                  Pick<UniversalTicketAttributeFeeRange, 'currency'> & {
                    amount: Pick<UniversalTicketAttributeFeeRangeAmount, 'min' | 'max'>
                  }
                >
              >
              bagLimits?: Maybe<
                Pick<UniversalTicketAttributeBagLimits, 'weightKg' | 'sizeLinearCm'>
              >
            }
          >
        }
        content: Array<
          Pick<OnTheFlyContent, 'location' | 'severity' | 'display' | 'linkTitle'> & {
            items: Array<
              Pick<OnTheFlyContentItem, 'headline' | 'body' | 'categories'> & {
                details: Array<
                  Pick<OnTheFlyContentItemDetail, 'body'> & {
                    image: Pick<OnTheFlyContentItemDetailImage, 'medium' | 'large' | 'thumbnail'>
                  }
                >
              }
            >
          }
        >
      }
    >
  }
}

export type GetRailFareConditionsQueryVariables = Exact<{
  input: RailFareConditionsInput
}>

export type GetRailFareConditionsQuery = {
  railFareConditions: {
    fareConditions: Array<{
      tier: Pick<RailFareTier, 'id' | 'name'>
      localizedFareConditions: Array<
        Pick<
          LocalizedFareConditions,
          'fareTypeDescription' | 'summaryConditions' | 'validityPeriod' | 'language'
        > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
      >
    }>
  }
}

export type GetRailJourneyPreferencesQueryVariables = Exact<{
  input: RailJourneyPreferencesInput
}>

export type GetRailJourneyPreferencesQuery = {
  railJourneyPreferences: Pick<
    RailPreferencesResult,
    'inwardSeatReservationType' | 'outwardSeatReservationType'
  > & {
    deliveryOptions: Array<Pick<DeliveryOptions, 'name' | 'code'>>
    seatPreferences: {
      seatPositions: Array<Pick<SeatPreference, 'code' | 'name'>>
      seatDirections: Array<Pick<SeatPreference, 'code' | 'name'>>
      carriageTypes: Array<Pick<SeatPreference, 'code' | 'name'>>
      seatFacilities: Array<Pick<SeatPreference, 'code' | 'name'>>
    }
  }
}

export type GetReferencePointsQueryVariables = Exact<{
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  pageSize?: InputMaybe<Scalars['Int']>
}>

export type GetReferencePointsQuery = {
  referencePoints: Pick<ReferencePoints, 'actualPageSize'> & {
    referencePoints: Array<
      Pick<ReferencePoint, 'label'> & {
        address: Pick<
          ReferencePointAddress,
          'street1' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
        > & { geocode: Pick<Geocode, 'lat' | 'long'> }
      }
    >
  }
}

export type GetRideHailSuggestedLocationsQueryVariables = Exact<{
  input: RideHailSuggestedLocationsRequest
}>

export type GetRideHailSuggestedLocationsQuery = {
  rideHailSuggestedLocations:
    | ({ __typename: 'RideHailSuggestedLocationsError' } & Pick<
        RideHailSuggestedLocationsError,
        'reason'
      >)
    | ({ __typename: 'RideHailSuggestedLocationsResponse' } & {
        rideHailSuggestedLocations: Array<
          Pick<
            RideHailSuggestedLocation,
            'name' | 'suggestedLocationType' | 'countryCode' | 'airportCode'
          > & {
            location: Pick<RideHailSuggestionLocation, 'address'> & {
              geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
            }
          }
        >
      })
}

export type GetRideHailSuggestionsQueryVariables = Exact<{ [key: string]: never }>

export type GetRideHailSuggestionsQuery = {
  rideHailSuggestions: {
    rideHailSuggestions: Array<
      Pick<
        RideHailSuggestion,
        | 'canBeBookedWithinEtta'
        | 'pickUpName'
        | 'pickUpDate'
        | 'pickUpTime'
        | 'pickUpTimeZoneUtcOffset'
        | 'pickUpLocationType'
        | 'pickUpSegmentId'
        | 'pickUpSegmentType'
        | 'pickUpSegmentEndDate'
        | 'pickUpSegmentEndTime'
        | 'pickUpSegmentEndTimeZoneUtcOffset'
        | 'dropOffName'
        | 'dropOffDate'
        | 'dropOffTime'
        | 'dropOffTimeZoneUtcOffset'
        | 'dropOffLocationType'
        | 'dropOffSegmentId'
        | 'dropOffSegmentType'
        | 'dropOffSegmentStartDate'
        | 'dropOffSegmentStartTime'
        | 'dropOffSegmentStartTimeZoneUtcOffset'
        | 'processId'
      > & {
        pickUpLocation: Pick<RideHailSuggestionLocation, 'address'> & {
          geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
        }
        dropOffLocation: Pick<RideHailSuggestionLocation, 'address'> & {
          geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
        }
      }
    >
  }
}

export type GetRoomDetailsQueryVariables = Exact<{
  logsMetadata?: InputMaybe<LogsMetadata>
  hotelResultId?: InputMaybe<Scalars['String']>
  hotelId: Scalars['ID']
  roomId: Scalars['ID']
  checkIn: Scalars['String']
  checkOut: Scalars['String']
}>

export type GetRoomDetailsQuery = { roomDetails: Pick<RoomDetails, 'id' | 'rateDetails'> }

export type GetSafetyCheckQueryVariables = Exact<{
  lat: Scalars['Float']
  lng: Scalars['Float']
}>

export type GetSafetyCheckQuery = {
  geosureScores: Pick<GeosureScoresResult, 'compositeScore' | 'distance' | 'district' | 'city'> & {
    scores: Array<Pick<GeosureScore, 'value' | 'label'>>
    covid19Summary?: Maybe<{
      country?: Maybe<
        Pick<
          Covid19Statistic,
          | 'name'
          | 'confirmedNewDaily'
          | 'confirmedTotal'
          | 'confirmedFourteenDayTrend'
          | 'deathsNewDaily'
          | 'deathsTotal'
          | 'deathsFourteenDayTrend'
        >
      >
      county?: Maybe<
        Pick<
          Covid19Statistic,
          | 'name'
          | 'confirmedNewDaily'
          | 'confirmedTotal'
          | 'confirmedFourteenDayTrend'
          | 'deathsNewDaily'
          | 'deathsTotal'
          | 'deathsFourteenDayTrend'
        >
      >
      state?: Maybe<
        Pick<
          Covid19Statistic,
          | 'name'
          | 'confirmedNewDaily'
          | 'confirmedTotal'
          | 'confirmedFourteenDayTrend'
          | 'deathsNewDaily'
          | 'deathsTotal'
          | 'deathsFourteenDayTrend'
        >
      >
      province?: Maybe<
        Pick<
          Covid19Statistic,
          | 'name'
          | 'confirmedNewDaily'
          | 'confirmedTotal'
          | 'confirmedFourteenDayTrend'
          | 'deathsNewDaily'
          | 'deathsTotal'
          | 'deathsFourteenDayTrend'
        >
      >
    }>
  }
}

export type GetSiteSsoSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetSiteSsoSettingsQuery = {
  siteSsoSettings: Pick<SiteSsoSettings, 'sessionTimeoutRedirectURL' | 'logoutRedirectURL'>
}

export type GetSupportArticleByIdQueryVariables = Exact<{
  input: SupportArticleByIdInput
}>

export type GetSupportArticleByIdQuery = {
  supportArticleById?: Maybe<
    Pick<SupportArticle, 'id' | 'title' | 'body' | 'isFeatured'> & {
      image?: Maybe<Pick<SupportArticleImage, 'url' | 'alt' | 'title'>>
    }
  >
}

export type GetSupportArticlesQueryVariables = Exact<{
  input: SupportArticlesInput
}>

export type GetSupportArticlesQuery = {
  supportArticles: Array<
    Pick<SupportArticle, 'id' | 'title' | 'body' | 'isFeatured'> & {
      image?: Maybe<Pick<SupportArticleImage, 'url' | 'alt' | 'title'>>
    }
  >
}

export type GetSupportInformationQueryVariables = Exact<{ [key: string]: never }>

export type GetSupportInformationQuery = {
  supportInformation: {
    phoneNumbers: Array<Pick<PhoneSupport, 'label' | 'phoneNumber'>>
    email?: Maybe<Pick<EmailSupport, 'label' | 'emailAddress'>>
  }
}

export type GetTravelPolicyListQueryVariables = Exact<{ [key: string]: never }>

export type GetTravelPolicyListQuery = {
  travelPolicyList?: Maybe<Pick<TravelPolicyList, 'companyName' | 'policyList'>>
}

export type CreateAirSearchMutationVariables = Exact<{
  input: CreateFlightSearchInput
}>

export type CreateAirSearchMutation = {
  createFlightSearch?: Maybe<Pick<CreateFlightSearchPayload, 'id'>>
}

export type GetFlightFareAttributesQueryVariables = Exact<{
  input: FlightFareAttributesInBatchInput
}>

export type GetFlightFareAttributesQuery = {
  flightFareAttributesInBatch: Array<
    Pick<FlightFareAttributesInBatchResponseDto, 'legId'> & {
      fareAttributes: Array<
        Pick<FareAttributes, 'fareId'> & {
          comparison: {
            brand: Pick<FlightComparisonBrand, 'name'>
            utas: Array<Pick<UniversalTicketAttribute, 'category' | 'description' | 'assessment'>>
          }
          content: Array<
            Pick<OnTheFlyContent, 'location' | 'segmentIndex' | 'linkIconClass'> & {
              items: Array<
                Pick<OnTheFlyContentItem, 'headline' | 'body'> & {
                  details: Array<
                    Pick<OnTheFlyContentItemDetail, 'body'> & {
                      image: Pick<OnTheFlyContentItemDetailImage, 'thumbnail'>
                    }
                  >
                  callToAction?: Maybe<Pick<OnTheFlyContentItemCallToAction, 'url'>>
                }
              >
            }
          >
        }
      >
    }
  >
}

export type GetAlternatePolicyTripsQueryVariables = Exact<{
  input: GetAlternatePolicyTripsInput
}>

export type GetAlternatePolicyTripsQuery = {
  getAlternatePolicyTrips:
    | ({ __typename: 'GetAlternatePolicyTripsErrorResponse' } & Pick<
        GetAlternatePolicyTripsErrorResponse,
        'reason'
      >)
    | ({ __typename: 'GetAlternatePolicyTripsResponse' } & {
        policyTrips: Array<
          Pick<PolicyTripDto, 'tripId'> & {
            flightLegs: Array<
              Pick<
                SearchFlightLeg,
                | 'legId'
                | 'daysInBetween'
                | 'stops'
                | 'flightStops'
                | 'origin'
                | 'destination'
                | 'departureDate'
                | 'arrivalDate'
                | 'isSelectable'
                | 'isUnusedTicketApplicable'
                | 'hasTechnicalStop'
              > & {
                flightTime: Pick<TravelTime, 'hours' | 'minutes'>
                policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                totalTime: Pick<TravelTime, 'hours' | 'minutes'>
                rate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                segments: Array<
                  Pick<
                    SearchFlightLegSubSegment,
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'id'
                    | 'percentageOnTime'
                    | 'flightNumber'
                    | 'isSeatMapAvailable'
                    | 'isSeatSelectable'
                    | 'preferredBadgeText'
                    | 'isPreferredAny'
                    | 'destinationTerminal'
                    | 'originTerminal'
                    | 'isDepartAirportDifferentFromConnecting'
                    | 'isInstantPurchaseCarrier'
                  > & {
                    origin: Pick<FlightLegSubSegmentAirport, 'code' | 'name' | 'city' | 'terminal'>
                    destination: Pick<
                      FlightLegSubSegmentAirport,
                      'code' | 'name' | 'city' | 'terminal'
                    >
                    carrier?: Maybe<
                      Pick<
                        FlightCarrier,
                        'id' | 'name' | 'imageUrl' | 'logoImageUrl' | 'logoRectangleImageUrl'
                      >
                    >
                    amenities?: Maybe<Array<Pick<FlightAmenity, 'id' | 'name'>>>
                    preference?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    flightTime: Pick<TravelTime, 'hours' | 'minutes'>
                    aircraft?: Maybe<Pick<FlightLegSegmentAircraft, 'code' | 'name'>>
                    codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                  }
                >
                fares: Array<
                  Pick<
                    FlightFare,
                    | 'tripId'
                    | 'restriction'
                    | 'isRefundable'
                    | 'isWebFare'
                    | 'showOutOfPolicy'
                    | 'isSelectable'
                    | 'isNDCFare'
                    | 'providerAttributesStr'
                    | 'isUnusedTicketApplicable'
                    | 'fareLabel'
                  > & {
                    tier: Pick<FlightFareTier, 'id' | 'name'>
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    segments?: Maybe<
                      Array<
                        Pick<
                          FlightFareSegment,
                          | 'segmentId'
                          | 'serviceClass'
                          | 'bookingCode'
                          | 'basisCode'
                          | 'privateCode'
                          | 'displayServiceClass'
                        >
                      >
                    >
                    rulesAndRestrictions?: Maybe<
                      Array<
                        Pick<
                          FlightFareRulesAndRestrictions,
                          'id' | 'type' | 'rulesAndRestriction'
                        > & {
                          penaltyFee?: Maybe<
                            Array<
                              Maybe<{
                                primary: Pick<Money, 'amount' | 'currency'>
                                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                              }>
                            >
                          >
                        }
                      >
                    >
                    unusedTickets?: Maybe<{
                      min?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      max?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      unusedTicket?: Maybe<
                        Pick<
                          UnusedTicket,
                          | 'airlineCode'
                          | 'ticketNumber'
                          | 'recordLocator'
                          | 'expirationDate'
                          | 'status'
                        > & {
                          originalCost?: Maybe<
                            Pick<
                              OriginalCost,
                              | 'code'
                              | 'amount'
                              | 'requestedCurrencyUnit'
                              | 'requestedCurrencyAmount'
                            >
                          >
                          changeFee?: Maybe<
                            Pick<
                              ChangeFee,
                              | 'code'
                              | 'amount'
                              | 'requestedCurrencyUnit'
                              | 'requestedCurrencyAmount'
                            >
                          >
                          validated?: Maybe<{
                            original?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            penalty?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            newTicket?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            exchangedTicket: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                            residual?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            addCollect?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            forfeiture?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            total: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }>
                          unvalidated?: Maybe<{
                            original?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            penalty?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            newTicket?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            exchangedTicket?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            residual?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            addCollect?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            forfeiture?: Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                            total: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }>
                          airline?: Maybe<Pick<Decode, 'code' | 'value' | 'imageUrl'>>
                        }
                      >
                    }>
                    policy?: Maybe<
                      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                        outOfPolicyData?: Maybe<
                          Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                        >
                        outOfPolicyComplianceCodes?: Maybe<
                          Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                        >
                      }
                    >
                  }
                >
                connectionInfo: Array<
                  Pick<FlightLegConnectionInfo, 'segmentId' | 'connectionTimeMinutes'>
                >
              }
            >
          }
        >
      })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type GetFlightDynamicSiteMessagesQueryVariables = Exact<{
  input: GetFlightDynamicSiteMessagesInputDto
}>

export type GetFlightDynamicSiteMessagesQuery = {
  getFlightDynamicSiteMessages: {
    messages: Array<
      Pick<
        DynamicSiteMessage,
        'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
      >
    >
  }
}

export type GetFlightsQueryVariables = Exact<{
  input: FlightsInput
}>

export type GetFlightsQuery = {
  flights: Pick<
    FlightsResult,
    | 'totalCount'
    | 'pageStart'
    | 'pageEnd'
    | 'maxResultsSize'
    | 'isCustomTimeReplaced'
    | 'isDsmEnabled'
  > & {
    flightLegs: Array<
      Pick<
        SearchFlightLeg,
        | 'legId'
        | 'daysInBetween'
        | 'flightStops'
        | 'stops'
        | 'origin'
        | 'destination'
        | 'departureDate'
        | 'arrivalDate'
        | 'hasTechnicalStop'
        | 'isSelectable'
        | 'isUnusedTicketApplicable'
      > & {
        flightTime: Pick<TravelTime, 'hours' | 'minutes'>
        totalTime: Pick<TravelTime, 'hours' | 'minutes'>
        policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
        rate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        distance?: Maybe<Pick<Distance, 'value' | 'units'>>
        segments: Array<
          Pick<
            SearchFlightLegSubSegment,
            | 'id'
            | 'departureDate'
            | 'arrivalDate'
            | 'percentageOnTime'
            | 'flightNumber'
            | 'isSeatMapAvailable'
            | 'isSeatSelectable'
            | 'preferredBadgeText'
            | 'isPreferredAny'
            | 'isDepartAirportDifferentFromConnecting'
            | 'isInstantPurchaseCarrier'
          > & {
            flightTime: Pick<TravelTime, 'hours' | 'minutes'>
            origin: Pick<FlightLegSubSegmentAirport, 'city' | 'terminal' | 'code' | 'name'>
            destination: Pick<FlightLegSubSegmentAirport, 'city' | 'terminal' | 'code' | 'name'>
            carrier?: Maybe<
              Pick<
                FlightCarrier,
                'id' | 'name' | 'imageUrl' | 'logoImageUrl' | 'logoRectangleImageUrl'
              >
            >
            amenities?: Maybe<Array<Pick<FlightAmenity, 'id' | 'name'>>>
            preference?: Maybe<
              Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
                customBadge: Pick<CustomBadge, 'name' | 'color'>
              }
            >
            aircraft?: Maybe<Pick<FlightLegSegmentAircraft, 'code' | 'name'>>
            codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
          }
        >
        fares: Array<
          Pick<
            FlightFare,
            | 'fareId'
            | 'tripId'
            | 'restriction'
            | 'isRefundable'
            | 'isWebFare'
            | 'showOutOfPolicy'
            | 'isNDCFare'
            | 'providerAttributesStr'
            | 'isSelectable'
            | 'isUnusedTicketApplicable'
            | 'fareLabel'
          > & {
            tier: Pick<FlightFareTier, 'id' | 'name'>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            segments?: Maybe<
              Array<
                Pick<
                  FlightFareSegment,
                  | 'segmentId'
                  | 'serviceClass'
                  | 'bookingCode'
                  | 'basisCode'
                  | 'privateCode'
                  | 'displayServiceClass'
                >
              >
            >
            policy?: Maybe<
              Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
            >
            rulesAndRestrictions?: Maybe<
              Array<
                Pick<FlightFareRulesAndRestrictions, 'id' | 'type' | 'rulesAndRestriction'> & {
                  penaltyFee?: Maybe<
                    Array<
                      Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    >
                  >
                }
              >
            >
            unusedTickets?: Maybe<{
              min?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              max?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              unusedTicket?: Maybe<{
                validated?: Maybe<{
                  original?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  penalty?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  newTicket?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  exchangedTicket: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                  residual?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  addCollect?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  forfeiture?: Maybe<{
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    primary: Pick<Money, 'amount' | 'currency'>
                  }>
                  total: {
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    primary: Pick<Money, 'currency' | 'amount'>
                  }
                }>
                unvalidated?: Maybe<{
                  original?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  penalty?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  newTicket?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  exchangedTicket?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  residual?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  addCollect?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  forfeiture?: Maybe<{
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    primary: Pick<Money, 'amount' | 'currency'>
                  }>
                  total: {
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    primary: Pick<Money, 'currency' | 'amount'>
                  }
                }>
              }>
            }>
          }
        >
        connectionInfo: Array<Pick<FlightLegConnectionInfo, 'segmentId' | 'connectionTimeMinutes'>>
      }
    >
    messages?: Maybe<
      Array<
        Pick<
          DynamicSiteMessage,
          'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
        >
      >
    >
    filters: {
      airlines: Array<Pick<AirlineFilter, 'id' | 'value' | 'name' | 'imageUrl'>>
      airport: Array<
        Pick<AirportFilter, 'id'> & {
          origin: Pick<AirportFilterAirport, 'code' | 'name' | 'city'>
          destination: Pick<AirportFilterAirport, 'code' | 'name' | 'city'>
        }
      >
      stops: Array<Pick<StopsFilter, 'id' | 'value' | 'isSelected'>>
      fareTier?: Maybe<Array<Pick<FareTierFilter, 'id' | 'value' | 'displayName'>>>
      originTimeRange: Pick<TimeFilter, 'start' | 'end'>
      destinationTime: Pick<TimeFilter, 'start' | 'end'>
    }
  }
}

export type GetHomeAirportQueryVariables = Exact<{ [key: string]: never }>

export type GetHomeAirportQuery = {
  homeAirport: Array<
    Pick<
      PlaceAutocomplete,
      'placeId' | 'airportCode' | 'name' | 'latitude' | 'longitude' | 'placeType' | 'timezone'
    > & {
      address?: Maybe<
        Pick<
          PlaceAutocompleteAddress,
          'city' | 'countryCode' | 'stateCode' | 'postalCode' | 'street1' | 'lat' | 'lng'
        >
      >
    }
  >
}

export type GetSitesWithActiveUserAccountsQueryVariables = Exact<{
  input: SitesByUserEmailInput
}>

export type GetSitesWithActiveUserAccountsQuery = {
  sitesByUserEmail: Array<
    Pick<
      SiteAccount,
      'siteId' | 'siteName' | 'ssoType' | 'mobileSsoEnabled' | 'companySsoEndpointUrl' | 'url'
    >
  >
}

export type LoginByCredentialsMutationVariables = Exact<{
  input: LoginByCredentialsInput
}>

export type LoginByCredentialsMutation = {
  loginByCredentials: Pick<
    Login,
    'token' | 'passwordExpirationDate' | 'passwordExpirationDaysRemaining'
  >
}

export type LoginByKeyMutationVariables = Exact<{
  input: LoginByKeyInput
}>

export type LoginByKeyMutation = { loginByKey: Pick<Login, 'token'> }

export type LoginBySsoTokenMutationVariables = Exact<{
  input: LoginBySsoTokenInput
}>

export type LoginBySsoTokenMutation = { loginBySSOToken: Pick<Login, 'token'> }

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput
}>

export type ResetPasswordMutation = {
  resetPasswordWithError:
    | ({ __typename: 'ResetPasswordError' } & Pick<ResetPasswordError, 'code' | 'message'>)
    | ({ __typename: 'ResetPasswordErrorResponse' } & {
        securityQuestions: Array<Pick<SecurityQuestion, 'id' | 'question'>>
      })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type CheckoutCartMutationVariables = Exact<{
  input: CheckoutCartInput
}>

export type CheckoutCartMutation = {
  checkoutCart:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'CheckoutCartResponse' } & Pick<
        CheckoutCartResponse,
        'code' | 'message' | 'reservationId'
      >)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type GetCheckoutInfoQueryVariables = Exact<{
  input: CheckoutInfoInput
}>

export type GetCheckoutInfoQuery = {
  getCheckoutInfo:
    | ({ __typename: 'CheckoutInfoResult' } & Pick<
        CheckoutInfoResult,
        'preBookRequestRequirement' | 'isPassportRequired' | 'isRulesAcknowledgementRequired'
      > & {
          primaryTraveler: {
            travelerFields?: Maybe<
              Pick<
                CheckoutInfoTravelerFields,
                | 'isPrimaryTraveler'
                | 'isTitleChangeable'
                | 'isNameChangeable'
                | 'isDefaultChangeTravelerOptionEnabled'
                | 'isAddressRequired'
                | 'isAddressChangeable'
                | 'isDateOfBirthRequired'
                | 'isDateOfBirthChangeable'
                | 'isGenderRequired'
                | 'isGenderChangeable'
                | 'isPhoneNumberRequired'
                | 'isEMailRequired'
                | 'isFirstNameRequired'
                | 'isMiddleNameRequired'
                | 'isLastNameRequired'
                | 'isSuffixRequired'
                | 'isSuffixChangeable'
                | 'isPhoneNumberChangeable'
                | 'isEMailChangeable'
              >
            >
            data: Pick<
              CheckoutInfoTravelerData,
              | 'id'
              | 'dateOfBirth'
              | 'firstName'
              | 'lastName'
              | 'gender'
              | 'email'
              | 'primary'
              | 'suffix'
              | 'title'
              | 'middleInitial'
            > & {
              phoneNumber: Array<Pick<Phone, 'type' | 'countryCode' | 'number'>>
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
              homeAddress?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
              passport?: Maybe<
                Pick<
                  Passport,
                  | 'id'
                  | 'number'
                  | 'issuingCountry'
                  | 'country'
                  | 'issueDate'
                  | 'expirationDate'
                  | 'unmaskedNumber'
                  | 'preExpirationAlert'
                >
              >
              passports?: Maybe<
                Array<
                  Pick<
                    Passport,
                    | 'id'
                    | 'number'
                    | 'issuingCountry'
                    | 'country'
                    | 'issueDate'
                    | 'expirationDate'
                    | 'unmaskedNumber'
                    | 'preExpirationAlert'
                  >
                >
              >
              knownTravelerNumbers?: Maybe<
                Array<
                  Pick<
                    CheckoutInfoTravelerKnownTravelerNumber,
                    'number' | 'countryCode' | 'expirationDate'
                  >
                >
              >
              redressNumbers?: Maybe<
                Array<Pick<CheckoutInfoTravelerRedressNumber, 'number' | 'countryCode'>>
              >
            }
          }
          memberships?: Maybe<{
            air?: Maybe<{
              data: Array<
                Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode' | 'carrierName'>
              >
              options: Array<Pick<CheckoutInfoAirMembershipOptions, 'carrierCode' | 'carrierName'>>
            }>
            carRental?: Maybe<{
              data: Array<
                Pick<CheckoutInfoCarMembershipData, 'id' | 'number' | 'vendorCode' | 'vendorName'>
              >
              options: Array<Pick<CheckoutInfoCarMembershipOptions, 'vendorCode' | 'vendorName'>>
            }>
            hotel?: Maybe<{
              data: Array<
                Pick<
                  CheckoutInfoHotelMembershipData,
                  'id' | 'appliesToChain' | 'vendorCode' | 'vendorName' | 'number'
                >
              >
              options: Array<Pick<CheckoutInfoHotelMembershipOptions, 'vendorCode' | 'vendorName'>>
            }>
          }>
          mealRequest?: Maybe<{
            data?: Maybe<Pick<CheckoutInfoMealRequestData, 'mealName' | 'code' | 'id'>>
            options: Array<Pick<CheckoutInfoMealRequestOption, 'mealName' | 'code'>>
          }>
          billing?: Maybe<
            Pick<CheckoutInfoBilling, 'isSingleUseCreditCardAllowed'> & {
              air?: Maybe<{
                creditCards: Array<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                siteCards: Array<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
                defaultCard?: Maybe<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                defaultSiteCard?: Maybe<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
              }>
              carRental?: Maybe<{
                creditCards: Array<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                siteCards: Array<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
                defaultCard?: Maybe<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                defaultSiteCard?: Maybe<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
              }>
              hotel?: Maybe<{
                creditCards: Array<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                siteCards: Array<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
                defaultCard?: Maybe<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                defaultSiteCard?: Maybe<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
              }>
              rail?: Maybe<{
                creditCards: Array<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                siteCards: Array<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                metadata: Pick<BillingMetadata, 'isBillingAddressRequired' | 'isCvvRequired'>
                defaultCard?: Maybe<
                  Pick<CheckoutInfoCreditCard, 'id' | 'isCorporateCard' | 'isCardOutOfPolicy'> & {
                    data: Pick<
                      CheckoutInfoCreditCardData,
                      | 'id'
                      | 'type'
                      | 'number'
                      | 'cvv'
                      | 'label'
                      | 'nameOnTheCard'
                      | 'expirationDate'
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
                defaultSiteCard?: Maybe<
                  Pick<CheckoutInfoSiteCard, 'id' | 'isRestricted' | 'isCardOutOfPolicy'> & {
                    data: Pick<CheckoutInfoSiteCardData, 'id' | 'label'>
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                >
              }>
            }
          >
          specialRequests?: Maybe<{
            air?: Maybe<{
              data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
              options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
            }>
            carRental?: Maybe<{
              data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
              options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
            }>
            hotel?: Maybe<{
              data: Array<Pick<CheckoutInfoSpecialRequestData, 'name' | 'value'>>
              options: Array<Pick<CheckoutInfoSpecialRequestOption, 'name' | 'value'>>
            }>
          }>
          dynamicSiteMessages: Array<
            Pick<
              DynamicSiteMessage,
              'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
            >
          >
          customFieldsConfiguration?: Maybe<{
            customFieldCollection: Array<
              Pick<
                CustomFieldCollectionItem,
                'collectionId' | 'collectionLabel' | 'collectionDescription'
              > & {
                customFields: Array<
                  Pick<
                    CustomField,
                    'optional' | 'name' | 'label' | 'tooltip' | 'value' | 'defaultValue' | 'type'
                  > & {
                    preTripApprovalDisplay?: Maybe<Pick<PreTripApprovalDisplay, 'ruleDescription'>>
                    checkBoxDisplay?: Maybe<
                      Pick<CustomFieldCheckBoxDisplay, 'isChecked' | 'isCheckedValueRequired'>
                    >
                    textDisplay?: Maybe<
                      Pick<
                        CustomFieldTextDisplay,
                        'minLength' | 'maxLength' | 'numberOfLines' | 'characterWidth'
                      >
                    >
                    enumerationDisplay?: Maybe<
                      Pick<CustomFieldEnumerationDisplay, 'isRadioButtonsStyle'> & {
                        option?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>>
                      }
                    >
                    numberDisplay?: Maybe<
                      Pick<
                        CustomFieldNumberDisplay,
                        'minValue' | 'maxValue' | 'decimalPlaces' | 'currencyCode'
                      >
                    >
                    dateTimeDisplay?: Maybe<
                      Pick<
                        CustomFieldDateTimeDisplay,
                        | 'isAllowedPastDateTime'
                        | 'displayYear'
                        | 'displayMonth'
                        | 'displayDay'
                        | 'displayHour'
                        | 'displayMinute'
                        | 'displayTimeZone'
                        | 'minYear'
                        | 'maxYear'
                        | 'minMonth'
                        | 'maxMonth'
                        | 'minDay'
                        | 'maxDay'
                      >
                    >
                  }
                >
              }
            >
          }>
          costAllocations: Array<Pick<CheckoutInfoCostAllocation, 'costAllocationSegmentId'>>
          displaySettings?: Maybe<
            Pick<
              DisplaySettings,
              | 'shouldShowAirLoyaltyPreferences'
              | 'shouldShowAirMealPreferences'
              | 'shouldShowAirSpecialRequestPreferences'
              | 'shouldShowShareTravelerContact'
              | 'shouldShowNdcFareAcknowledgment'
            >
          >
        })
    | { __typename: 'ItineraryNotFoundResponse' }
    | { __typename: 'UnexpectedError' }
}

export type CheckoutItineraryMutationVariables = Exact<{
  input: CheckoutItineraryInput
}>

export type CheckoutItineraryMutation = {
  checkoutItinerary:
    | ({ __typename: 'OrderDetails' } & Pick<
        OrderDetails,
        | 'appliedRailCards'
        | 'isNDCTrip'
        | 'cityImageUrl'
        | 'processId'
        | 'tripId'
        | 'type'
        | 'recordLocator'
        | 'creationTime'
        | 'cancellationTime'
        | 'tripName'
        | 'startDate'
        | 'rollUpStartTime'
        | 'rollUpEndTime'
        | 'flightId'
        | 'isMultiCity'
        | 'agencyName'
      > & {
          holdRules?: Maybe<
            Pick<TripHoldRules, 'autoCancellationTime' | 'holdAllowed' | 'reasonsForNotAllowing'>
          >
          supportedActionsV2?: Maybe<
            Pick<
              TripDetailsPostReservationV2,
              | 'allowAddFlight'
              | 'allowAddHotel'
              | 'allowAddCar'
              | 'allowBookAgain'
              | 'allowChange'
              | 'allowCancel'
            > & {
              segmentChangeActions?: Maybe<{
                flightBooking?: Maybe<
                  Pick<
                    FlightChangeCancelRules,
                    | 'allowChangeDestination'
                    | 'allowChangeCarrier'
                    | 'allowChange'
                    | 'allowCancel'
                    | 'needCancelAcknowledgement'
                  > & {
                    flightChangeRules?: Maybe<
                      Array<
                        Pick<FlightChangeRules, 'changeType'> & {
                          flightChangeBehavior?: Maybe<
                            Pick<
                              FlightChangeBehavior,
                              | 'allowedSearchStartDate'
                              | 'allowedSearchEndDate'
                              | 'allowChangeCarrier'
                              | 'allowChangeFareType'
                              | 'allowChangeAirports'
                              | 'allowChangeCabin'
                            >
                          >
                        }
                      >
                    >
                    cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
                  }
                >
                trainBooking?: Maybe<
                  Pick<
                    ChangeCancelRules,
                    'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'
                  > & {
                    cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
                  }
                >
                hotelBooking?: Maybe<
                  Pick<
                    ChangeCancelRules,
                    'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'
                  > & {
                    cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
                  }
                >
                carBooking?: Maybe<
                  Pick<
                    ChangeCancelRules,
                    'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'
                  > & {
                    cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
                  }
                >
              }>
            }
          >
          agencyInfo?: Maybe<Pick<AgencyInfo, 'agencyName' | 'agencyPhoneNumber' | 'agencyMessage'>>
          tripCost?: Maybe<
            Pick<TripDetailsTripCostSummary, 'allowUnusedTicketOptout'> & {
              prepaid?: Maybe<{
                flight?: Maybe<
                  Array<
                    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                      total: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      base?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      feesAndTaxes?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      transactionFee?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      fareTaxInfo?: Maybe<
                        Array<
                          Pick<FareTaxInfo, 'code' | 'name'> & {
                            amount: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }
                        >
                      >
                    }
                  >
                >
                hotel?: Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }
                        }
                      >
                    >
                  }
                >
                carRental?: Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }
                        }
                      >
                    >
                  }
                >
                train?: Maybe<
                  Array<
                    Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                      total: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      base?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      feesAndTaxes?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      transactionFee?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      fareTaxInfo?: Maybe<
                        Array<
                          Pick<FareTaxInfo, 'code' | 'name'> & {
                            amount: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }
                        >
                      >
                    }
                  >
                >
              }>
              reserved?: Maybe<{
                hotel?: Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }
                        }
                      >
                    >
                  }
                >
                carRental?: Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }
                        }
                      >
                    >
                  }
                >
              }>
              unusedTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalServiceFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalTaxCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalTripBaseCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              total?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalWithUnusedTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
          messages?: Maybe<
            Array<
              Pick<
                DynamicSiteMessage,
                'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
              >
            >
          >
          segments?: Maybe<
            Array<
              Pick<TripDetailsSegment, 'position' | 'type'> & {
                flightLeg?: Maybe<
                  Pick<
                    TripDetailsFlightLeg,
                    | 'legId'
                    | 'pnrNumber'
                    | 'departureDateTime'
                    | 'arrivalDateTime'
                    | 'stops'
                    | 'flightStops'
                    | 'recordLocator'
                    | 'isSameCarrierName'
                    | 'isRoundTripLeg'
                    | 'isRoundTripCost'
                    | 'fareRestrictions'
                  > & {
                    traveler?: Maybe<
                      Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
                    >
                    flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                    distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                    rate?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    taxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    totalCost?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFees?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    unusedTicketRate?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    policy?: Maybe<
                      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                        outOfPolicyData?: Maybe<
                          Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                        >
                      }
                    >
                    segments?: Maybe<
                      Array<
                        Pick<
                          TripDetailsFlightLegSegment,
                          | 'confirmationNumber'
                          | 'segmentId'
                          | 'originTerminal'
                          | 'destinationTerminal'
                          | 'departureDate'
                          | 'arrivalDate'
                          | 'imageUrl'
                          | 'name'
                          | 'flightNumber'
                          | 'carrierCode'
                          | 'bookingCode'
                          | 'travelerSeat'
                          | 'travelerId'
                          | 'isDepartAirportDifferentFromConnecting'
                          | 'isSeatAvailable'
                          | 'isSeatSelectable'
                          | 'serviceClass'
                          | 'fare'
                          | 'percentageOnTime'
                          | 'operatedBy'
                          | 'mealService'
                          | 'bookingProvider'
                          | 'actualDepartureDate'
                          | 'actualArrivalDate'
                          | 'departureGate'
                          | 'arrivalGate'
                          | 'flightStatus'
                          | 'baggageClaim'
                          | 'trsItemId'
                          | 'specialRequests'
                          | 'status'
                        > & {
                          checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
                          departure?: Maybe<
                            Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                              address?: Maybe<
                                Pick<
                                  Address,
                                  | 'airportCode'
                                  | 'locationName'
                                  | 'street1'
                                  | 'street2'
                                  | 'city'
                                  | 'stateCode'
                                  | 'postalCode'
                                  | 'countryCode'
                                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                              >
                            }
                          >
                          arrival?: Maybe<
                            Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                              address?: Maybe<
                                Pick<
                                  Address,
                                  | 'airportCode'
                                  | 'locationName'
                                  | 'street1'
                                  | 'street2'
                                  | 'city'
                                  | 'stateCode'
                                  | 'postalCode'
                                  | 'countryCode'
                                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                              >
                            }
                          >
                          images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
                          flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                          distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                          baggageFees?: Maybe<
                            Pick<BaggageFees, 'carrierUrl'> & {
                              items?: Maybe<
                                Array<
                                  Maybe<
                                    Pick<FlightBaggageFee, 'type'> & {
                                      rate: {
                                        primary: Pick<Money, 'amount' | 'currency'>
                                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                      }
                                      baggageCount?: Maybe<
                                        Pick<BaggageAllowanceCount, 'lower' | 'upper'>
                                      >
                                      baggageWeight?: Maybe<{
                                        lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                        upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                      }>
                                      baggageSize?: Maybe<{
                                        lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                        upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                      }>
                                    }
                                  >
                                >
                              >
                            }
                          >
                          amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
                          preferredDescription?: Maybe<
                            Pick<
                              Preference,
                              | 'id'
                              | 'code'
                              | 'highlight'
                              | 'ownerType'
                              | 'ownerName'
                              | 'tooltipText'
                              | 'shouldShowDefaultPreference'
                            > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                          >
                          preferenceAttributes?: Maybe<
                            Pick<
                              Preference,
                              | 'id'
                              | 'code'
                              | 'highlight'
                              | 'ownerType'
                              | 'ownerName'
                              | 'tooltipText'
                              | 'shouldShowDefaultPreference'
                            > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                          >
                          penaltyFees?: Maybe<
                            Array<
                              Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                                rates?: Maybe<
                                  Array<
                                    Maybe<{
                                      primary: Pick<Money, 'amount' | 'currency'>
                                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                    }>
                                  >
                                >
                                penaltyFee?: Maybe<
                                  Array<
                                    Maybe<
                                      Pick<
                                        TrainPenaltyFee,
                                        | 'state'
                                        | 'isRuleAllowed'
                                        | 'effectiveStartDate'
                                        | 'effectiveEndDate'
                                      > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                                    >
                                  >
                                >
                              }
                            >
                          >
                          serviceFee?: Maybe<
                            Pick<SegmentServiceFee, 'id' | 'description'> & {
                              rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          >
                          codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
                          plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
                          membership?: Maybe<
                            Array<
                              Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>
                            >
                          >
                        }
                      >
                    >
                    totalWithUnusedTicket?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                  }
                >
                hotel?: Maybe<
                  Pick<
                    TripDetailsHotelSegment,
                    | 'checkIn'
                    | 'checkOut'
                    | 'numberOfNights'
                    | 'confirmationNumber'
                    | 'imageUrl'
                    | 'name'
                    | 'id'
                    | 'hotelId'
                    | 'rateQualifier'
                    | 'isCancellable'
                    | 'rulesAndRestrictions'
                    | 'cancellationPolicyDescription'
                    | 'cancellationPolicy'
                    | 'phone'
                    | 'roomType'
                    | 'roomDescription'
                    | 'recordLocator'
                    | 'starRating'
                    | 'source'
                    | 'isReserved'
                    | 'hasDeposit'
                    | 'trsItemId'
                    | 'specialRequests'
                    | 'status'
                  > & {
                    averageRate?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    dailyRates?: Maybe<
                      Array<
                        Pick<DailyRate, 'date'> & {
                          rate: {
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }
                        }
                      >
                    >
                    feesAndTaxes?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    totalCost?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    preferredDescription?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    policy?: Maybe<
                      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                        outOfPolicyData?: Maybe<
                          Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                        >
                      }
                    >
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                    numberOfGuests?: Maybe<Pick<NumberOfGuests, 'adults'>>
                    traveler?: Maybe<
                      Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
                    >
                    photos?: Maybe<Array<Pick<Photo, 'index' | 'size' | 'url'>>>
                    membership?: Maybe<
                      Array<Pick<TripDetailsHotelMembershipData, 'number' | 'vendorName'>>
                    >
                  }
                >
                carRental?: Maybe<
                  Pick<
                    TripDetailsCarRentalSegment,
                    | 'carId'
                    | 'carClass'
                    | 'carType'
                    | 'fuelType'
                    | 'carImageUrl'
                    | 'carTransmission'
                    | 'pickUpTime'
                    | 'dropOffTime'
                    | 'isCarAirConditioned'
                    | 'contractName'
                    | 'carCapacity'
                    | 'carLuggage'
                    | 'isReserved'
                    | 'confirmationNumber'
                    | 'isUnlimitedMileage'
                    | 'recordLocator'
                    | 'daysCount'
                    | 'specialRequests'
                    | 'status'
                  > & {
                    vendor?: Maybe<Pick<Vendor, 'name' | 'imageUrl' | 'code'>>
                    safetyCheck?: Maybe<
                      Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>
                    >
                    policy?: Maybe<
                      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                        outOfPolicyData?: Maybe<
                          Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                        >
                      }
                    >
                    pickUpLocation?: Maybe<
                      Pick<
                        TripDetailsCarRentalInteraction,
                        'date' | 'isDeliveryCollection' | 'isAirportLocation'
                      > & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                        phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
                      }
                    >
                    dropOffLocation?: Maybe<
                      Pick<
                        TripDetailsCarRentalInteraction,
                        'date' | 'isDeliveryCollection' | 'isAirportLocation'
                      > & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                        phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
                      }
                    >
                    traveler: Pick<
                      TripDetailsTraveler,
                      'firstName' | 'lastName' | 'middleName' | 'email'
                    >
                    dailyRate?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    totalBaseCost?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    totalCost?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    taxesAndFees?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    serviceFee?: Maybe<
                      Pick<ServiceFee, 'id' | 'description'> & {
                        rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    >
                    preferredDescription?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    membership?: Maybe<Array<Pick<CheckoutInfoCarMembershipData, 'number'>>>
                    extraCharge?: Maybe<{
                      perDayRate?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      perDayCharge?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      perHourCharge?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    }>
                  }
                >
                trainLeg?: Maybe<
                  Pick<
                    TripDetailsTrainLeg,
                    | 'id'
                    | 'departureDateTime'
                    | 'arrivalDateTime'
                    | 'departureStationCode'
                    | 'arrivalStationCode'
                    | 'routeType'
                    | 'platform'
                    | 'travelStops'
                    | 'transactionId'
                    | 'recordLocator'
                    | 'collectionReferences'
                  > & {
                    taxes?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    totalCost?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    transactionFees?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    traveler?: Maybe<
                      Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
                    >
                    travelTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                    totalJourneyTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                    rate?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    segments?: Maybe<
                      Array<
                        Pick<
                          TripDetailsTrainSegment,
                          | 'id'
                          | 'arrivalDate'
                          | 'arrivalStationCode'
                          | 'confirmationNumber'
                          | 'serviceClassName'
                          | 'trainNumber'
                          | 'trainId'
                          | 'type'
                          | 'carrierName'
                          | 'carrierImage'
                          | 'departureDate'
                          | 'departureStationCode'
                          | 'name'
                          | 'status'
                        > & {
                          segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
                          changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                          duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                          stations?: Maybe<{
                            arrival?: Maybe<
                              Pick<
                                TripDetailsTrainStation,
                                | 'additionalInformation'
                                | 'isBusStop'
                                | 'ticketMachineAvailable'
                                | 'phoneNumber'
                                | 'stationCode'
                                | 'stationName'
                                | 'countryName'
                                | 'ticketPickupInformation'
                              > & {
                                location?: Maybe<
                                  Pick<
                                    Address,
                                    | 'airportCode'
                                    | 'locationName'
                                    | 'street1'
                                    | 'street2'
                                    | 'city'
                                    | 'stateCode'
                                    | 'postalCode'
                                    | 'countryCode'
                                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                                >
                                ticketOfficeSchedule?: Maybe<
                                  Array<
                                    Pick<
                                      TripDetailsTrainStationSchedule,
                                      | 'closeTime'
                                      | 'endingOnDayOfWeek'
                                      | 'openTime'
                                      | 'startingOnDayOfWeek'
                                    >
                                  >
                                >
                              }
                            >
                            departure?: Maybe<
                              Pick<
                                TripDetailsTrainStation,
                                | 'additionalInformation'
                                | 'isBusStop'
                                | 'ticketMachineAvailable'
                                | 'phoneNumber'
                                | 'stationCode'
                                | 'stationName'
                                | 'countryName'
                                | 'ticketPickupInformation'
                              > & {
                                location?: Maybe<
                                  Pick<
                                    Address,
                                    | 'airportCode'
                                    | 'locationName'
                                    | 'street1'
                                    | 'street2'
                                    | 'city'
                                    | 'stateCode'
                                    | 'postalCode'
                                    | 'countryCode'
                                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                                >
                                ticketOfficeSchedule?: Maybe<
                                  Array<
                                    Pick<
                                      TripDetailsTrainStationSchedule,
                                      | 'closeTime'
                                      | 'endingOnDayOfWeek'
                                      | 'openTime'
                                      | 'startingOnDayOfWeek'
                                    >
                                  >
                                >
                              }
                            >
                          }>
                          allocatedSeat?: Maybe<
                            Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>
                          >
                        }
                      >
                    >
                    selectedDeliveryOption?: Maybe<Pick<TripDeliveryOption, 'code' | 'name'>>
                    fareConditions?: Maybe<
                      Array<
                        Maybe<{
                          tier: Pick<RailFareTier, 'id' | 'name'>
                          localizedFareConditions: Array<
                            Pick<
                              LocalizedFareConditions,
                              'fareTypeDescription' | 'summaryConditions'
                            > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
                          >
                        }>
                      >
                    >
                    permittedOriginStations: Array<
                      Pick<RailFarePermittedStation, 'code' | 'description'>
                    >
                    permittedDestinationStations: Array<
                      Pick<RailFarePermittedStation, 'code' | 'description'>
                    >
                  }
                >
                carService?: Maybe<
                  Pick<
                    TripDetailsCarServiceSegment,
                    'pickUpTime' | 'confirmationNumber' | 'recordLocator'
                  > & {
                    car?: Maybe<
                      Pick<TripDetailsCarServiceCar, 'class' | 'type' | 'capacity' | 'imageUrl'>
                    >
                    detailsRules?: Maybe<
                      Pick<
                        TripDetailsCarServiceDetailsRules,
                        'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
                      >
                    >
                    policy?: Maybe<
                      Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                        outOfPolicyData?: Maybe<
                          Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                        >
                      }
                    >
                    traveler: Pick<
                      TripDetailsTraveler,
                      'firstName' | 'lastName' | 'middleName' | 'email'
                    >
                    price: Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                      total: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      taxes: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      base?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      timeBase?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      offHours?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      airportFees?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      meetAndGreet?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      surfaceTransport?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      parking?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      fuelSurcharge?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      accountDiscount?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      rateQuote?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      stops?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      tolls?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      extraService?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      distanceBased?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      gratuity?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      accountService?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    }
                    serviceCompany?: Maybe<
                      Pick<TripDetailsCarServiceCompanyService, 'name' | 'description' | 'logo'>
                    >
                    stops?: Maybe<
                      Array<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'locationName'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'stateCode'
                          | 'postalCode'
                          | 'countryCode'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    >
                    pickUp?: Maybe<
                      Pick<TripDetailsCarServicePickUp, 'time' | 'pickupInstructions'> & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
                      }
                    >
                    dropOff?: Maybe<
                      Pick<TripDetailsCarServiceDropOff, 'time' | 'dropOffInstructions'> & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                        location?: Maybe<
                          Pick<
                            TripDetailsCarServiceDropOffLocation,
                            | 'asDirected'
                            | 'requestedDuration'
                            | 'requestedHours'
                            | 'asDirectedMultiDay'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                      }
                    >
                  }
                >
                rideHail?: Maybe<
                  Pick<
                    TripDetailsRideHailSegment,
                    | 'bookingId'
                    | 'description'
                    | 'dropOffAddress'
                    | 'dropOffTime'
                    | 'pickUpAddress'
                    | 'pickUpTime'
                    | 'productName'
                    | 'status'
                  > & {
                    price?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    estimatedPriceRange?: Maybe<{
                      primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
                      secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
                    }>
                  }
                >
              }
            >
          >
          costSummary: {
            carService?: Maybe<
              Pick<CarServiceCostSummary, 'isPaid'> & {
                cost?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                tax?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                carServiceCharges?: Maybe<{
                  accountDiscount?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  accountServiceCharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  airPortFees?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  extraServiceCharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  fuelSurcharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  gratuity?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  offHoursCharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  parkingAmount?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  stopCharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }>
              }
            >
            train?: Maybe<{
              trainList: Array<
                Pick<TrainCostSummaryItem, 'origin' | 'destination' | 'isPaid'> & {
                  cost?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  tax?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  total: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
              total?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fare?: Maybe<{
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                base: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                transactionFee: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                serviceFee: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                paymentFee: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                tax: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }>
            }>
            flight?: Maybe<{
              flightList: Array<
                Pick<
                  FlightCostSummaryItem,
                  'origin' | 'destination' | 'isSameAirline' | 'isPaid'
                > & {
                  cost?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  tax?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  total: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                  unusedTicketRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }
              >
              total?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }>
            hotel?: Maybe<
              Pick<HotelCostSummary, 'name' | 'itemCount' | 'isPaid'> & {
                cost?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                tax?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
            >
            carRental?: Maybe<
              Pick<CarRentalCostSummary, 'vendorName' | 'itemCount' | 'isPaid'> & {
                cost?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                tax?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
            >
            totalCost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
          airTickets?: Maybe<
            Array<
              Pick<
                AirTicket,
                | 'ticketNumber'
                | 'travelerName'
                | 'issuingAgent'
                | 'iataNumber'
                | 'formOfPayment'
                | 'endorsement'
                | 'fareLinear'
                | 'issuingAirline'
                | 'isTicketRefundable'
                | 'isPenaltyValidated'
                | 'isPenaltyPayableFromResidual'
                | 'isExchangeForRefundableAllowed'
              > & {
                ticketSegmentInfo?: Maybe<
                  Array<
                    Pick<
                      TicketSegment,
                      | 'departTime'
                      | 'carrierCode'
                      | 'flightNumber'
                      | 'origin'
                      | 'destination'
                      | 'serviceClass'
                      | 'fareBasis'
                      | 'status'
                    >
                  >
                >
                fareTaxInfo?: Maybe<
                  Array<
                    Pick<FareTaxItemDto, 'taxCode' | 'taxLabel'> & {
                      taxAmount?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    }
                  >
                >
                baseFare?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                totalFare?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                penalty?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
            >
          >
          billingInfo?: Maybe<
            Array<
              Pick<BillingInfo, 'id'> & {
                payment?: Maybe<{
                  creditCard?: Maybe<Pick<CreditPaymentCard, 'label'>>
                  siteCard?: Maybe<Pick<SitePaymentCard, 'label'>>
                }>
              }
            >
          >
          meetingInfo?: Maybe<
            Pick<MeetingInfo, 'name' | 'location'> & {
              dates: Pick<DateRange, 'startDate' | 'endDate'>
            }
          >
          failedBookings?: Maybe<
            Array<{
              hotelFailedBooking?: Maybe<
                Pick<PurchaseItineraryHotelFailedBooking, 'error'> & {
                  hotelData: Pick<
                    PurchaseItineraryHotelFailedBookingData,
                    'hotelId' | 'checkIn' | 'checkOut' | 'name' | 'numberOfNights' | 'roomType'
                  > & {
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                }
              >
            }>
          >
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type AddPassportMutationVariables = Exact<{
  input?: InputMaybe<AddPassportInput>
}>

export type AddPassportMutation = {
  addPassport:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | ({ __typename: 'InvalidInputError' } & Pick<InvalidInputError, 'message'> & {
          fields: Array<Pick<FieldError, 'type' | 'errorType' | 'message'>>
        })
    | ({ __typename: 'NoPassportError' } & Pick<NoPassportError, 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type RemovePassportMutationVariables = Exact<{
  input?: InputMaybe<RemovePassportInput>
}>

export type RemovePassportMutation = {
  removePassport: Pick<BaseResponse, 'code' | 'success' | 'message'>
}

export type GetPostPurchaseInfoQueryVariables = Exact<{
  input: PostPurchaseInfoInput
}>

export type GetPostPurchaseInfoQuery = {
  postPurchaseInfo: {
    primaryTraveler: Pick<
      PurchaseInfoTraveler,
      | 'lastName'
      | 'dateOfBirth'
      | 'firstName'
      | 'gender'
      | 'sex'
      | 'suffix'
      | 'dateBirth'
      | 'email'
      | 'primary'
      | 'middleInitial'
      | 'title'
    > & {
      phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
      address: Pick<
        Address,
        'airportCode' | 'street1' | 'street2' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      options?: Maybe<
        Pick<
          TravelerOptions,
          | 'isTitleChangeable'
          | 'isNameChangeable'
          | 'isDefaultChangeTravelerOptionEnabled'
          | 'isAddressRequired'
          | 'isAddressChangeable'
          | 'isDateOfBirthRequired'
          | 'isDateOfBirthChangeable'
          | 'isGenderRequired'
          | 'isGenderChangeable'
          | 'isPhoneNumberRequired'
          | 'isEMailRequired'
          | 'isFirstNameRequired'
          | 'isMiddleNameRequired'
          | 'isLastNameRequired'
          | 'isSuffixRequired'
          | 'isSuffixChangeable'
          | 'isPassportRequired'
        >
      >
      passports?: Maybe<
        Array<
          Pick<
            Passport,
            'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
          >
        >
      >
    }
    additionalTravelers: Array<
      Pick<
        PurchaseInfoTraveler,
        | 'lastName'
        | 'dateOfBirth'
        | 'firstName'
        | 'gender'
        | 'sex'
        | 'suffix'
        | 'dateBirth'
        | 'email'
        | 'primary'
        | 'middleInitial'
        | 'title'
      > & {
        phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        options?: Maybe<
          Pick<
            TravelerOptions,
            | 'isTitleChangeable'
            | 'isNameChangeable'
            | 'isDefaultChangeTravelerOptionEnabled'
            | 'isAddressRequired'
            | 'isAddressChangeable'
            | 'isDateOfBirthRequired'
            | 'isDateOfBirthChangeable'
            | 'isGenderRequired'
            | 'isGenderChangeable'
            | 'isPhoneNumberRequired'
            | 'isEMailRequired'
            | 'isFirstNameRequired'
            | 'isMiddleNameRequired'
            | 'isLastNameRequired'
            | 'isSuffixRequired'
            | 'isSuffixChangeable'
            | 'isPassportRequired'
          >
        >
        passports?: Maybe<
          Array<
            Pick<
              Passport,
              'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
            >
          >
        >
      }
    >
    details: Pick<PurchaseInfoDetails, 'costAllocationSegment'> & {
      agency: Pick<PurchaseInfoDetailsAgency, 'id' | 'message' | 'name' | 'phoneNumber'> & {
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      }
      emergencyContact: Pick<
        PurchaseInfoDetailsEmergencyContact,
        'id' | 'fullName' | 'relationship' | 'email'
      > & {
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
      billing: Array<
        Pick<PurchaseInfoDetailsBilling, 'id'> & {
          info: Array<
            Pick<PurchaseInfoDetailsBillingInfo, 'id' | 'isCardOutOfPolicy'> & {
              payment?: Maybe<{
                creditCard?: Maybe<
                  Pick<
                    PurchaseInfoDetailsBillingInfoPaymentCreditCard,
                    'id' | 'type' | 'number' | 'label' | 'nameOnTheCard' | 'expirationDate'
                  >
                >
              }>
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
            }
          >
        }
      >
      availablePreferences?: Maybe<{
        hotelSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        airSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        carRentalSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        meals?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        airMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        hotelMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        carRentalMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
      }>
    }
    billingParameters?: Maybe<
      Array<
        Maybe<
          Pick<BillingParameter, 'travelVertical'> & {
            creditCards: Array<
              Pick<
                CreditCard,
                | 'id'
                | 'billingId'
                | 'label'
                | 'cardNumber'
                | 'expirationDate'
                | 'brandType'
                | 'isSiteCard'
                | 'isCardOutOfPolicy'
                | 'name'
              > & {
                metadata: Pick<
                  CreditCardMetadata,
                  'isRestricted' | 'isEditable' | 'isBillingAddressRequired' | 'type'
                >
                billingAddress?: Maybe<
                  Pick<
                    Address,
                    'countryCode' | 'postalCode' | 'stateCode' | 'city' | 'street1' | 'street2'
                  >
                >
              }
            >
            metadata: Pick<
              BillingParameterMetadata,
              'isBillingAddressRequired' | 'isCvvRequired' | 'isSingleUseCreditCardAllowed'
            >
          }
        >
      >
    >
    customFieldsConfiguration?: Maybe<{
      customFieldCollection: Array<
        Pick<
          CustomFieldCollectionItem,
          | 'collectionId'
          | 'collectionLabel'
          | 'collectionDescription'
          | 'isNotAppliedServerSideJavascript'
        > & {
          customFields: Array<
            Pick<
              CustomField,
              | 'type'
              | 'optional'
              | 'name'
              | 'label'
              | 'defaultValue'
              | 'isHasClientSideJavascriptValidator'
              | 'isNotAppliedServerSideJavascriptInitializer'
            > & {
              checkBoxDisplay?: Maybe<
                Pick<CustomFieldCheckBoxDisplay, 'isChecked' | 'isCheckedValueRequired'>
              >
              preTripApprovalDisplay?: Maybe<Pick<PreTripApprovalDisplay, 'ruleDescription'>>
              textDisplay?: Maybe<
                Pick<
                  CustomFieldTextDisplay,
                  'minLength' | 'maxLength' | 'numberOfLines' | 'characterWidth'
                >
              >
              enumerationDisplay?: Maybe<
                Pick<CustomFieldEnumerationDisplay, 'isRadioButtonsStyle'> & {
                  option?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>>
                }
              >
              numberDisplay?: Maybe<
                Pick<CustomFieldNumberDisplay, 'decimalPlaces' | 'minValue' | 'maxValue'>
              >
              dateTimeDisplay?: Maybe<
                Pick<
                  CustomFieldDateTimeDisplay,
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'isAllowedPastDateTime'
                >
              >
            }
          >
        }
      >
    }>
    dynamicSiteMessages: Array<
      Pick<
        DynamicSiteMessage,
        'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
      >
    >
  }
}

export type GetPurchaseInfoQueryVariables = Exact<{
  input: PurchaseInfoInput
}>

export type GetPurchaseInfoQuery = {
  purchaseInfo: {
    primaryTraveler: Pick<
      PurchaseInfoTraveler,
      | 'lastName'
      | 'dateOfBirth'
      | 'firstName'
      | 'gender'
      | 'sex'
      | 'suffix'
      | 'dateBirth'
      | 'email'
      | 'primary'
      | 'middleInitial'
    > & {
      phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
      address: Pick<
        Address,
        'airportCode' | 'street1' | 'street2' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      options?: Maybe<
        Pick<
          TravelerOptions,
          | 'isTitleChangeable'
          | 'isNameChangeable'
          | 'isDefaultChangeTravelerOptionEnabled'
          | 'isAddressRequired'
          | 'isAddressChangeable'
          | 'isDateOfBirthRequired'
          | 'isDateOfBirthChangeable'
          | 'isGenderRequired'
          | 'isGenderChangeable'
          | 'isPhoneNumberRequired'
          | 'isEMailRequired'
          | 'isFirstNameRequired'
          | 'isMiddleNameRequired'
          | 'isLastNameRequired'
          | 'isSuffixRequired'
          | 'isSuffixChangeable'
          | 'isPassportRequired'
        >
      >
      passports?: Maybe<
        Array<
          Pick<
            Passport,
            'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
          >
        >
      >
    }
    additionalTravelers: Array<
      Pick<
        PurchaseInfoTraveler,
        | 'lastName'
        | 'dateOfBirth'
        | 'firstName'
        | 'gender'
        | 'sex'
        | 'suffix'
        | 'dateBirth'
        | 'email'
        | 'primary'
        | 'middleInitial'
      > & {
        phone: Array<Pick<Phone, 'number' | 'type' | 'countryCode'>>
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        options?: Maybe<
          Pick<
            TravelerOptions,
            | 'isTitleChangeable'
            | 'isNameChangeable'
            | 'isDefaultChangeTravelerOptionEnabled'
            | 'isAddressRequired'
            | 'isAddressChangeable'
            | 'isDateOfBirthRequired'
            | 'isDateOfBirthChangeable'
            | 'isGenderRequired'
            | 'isGenderChangeable'
            | 'isPhoneNumberRequired'
            | 'isEMailRequired'
            | 'isFirstNameRequired'
            | 'isMiddleNameRequired'
            | 'isLastNameRequired'
            | 'isSuffixRequired'
            | 'isSuffixChangeable'
            | 'isPassportRequired'
          >
        >
        passports?: Maybe<
          Array<
            Pick<
              Passport,
              'id' | 'number' | 'issuingCountry' | 'country' | 'issueDate' | 'expirationDate'
            >
          >
        >
      }
    >
    details: Pick<PurchaseInfoDetails, 'costAllocationSegment' | 'preBookRequestRequirement'> & {
      agency: Pick<PurchaseInfoDetailsAgency, 'id' | 'message' | 'name' | 'phoneNumber'> & {
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
      }
      emergencyContact: Pick<
        PurchaseInfoDetailsEmergencyContact,
        'id' | 'fullName' | 'relationship' | 'email'
      > & {
        address?: Maybe<
          Pick<
            Address,
            | 'airportCode'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        >
      }
      billing: Array<
        Pick<PurchaseInfoDetailsBilling, 'id'> & {
          info: Array<
            Pick<PurchaseInfoDetailsBillingInfo, 'id' | 'isCardOutOfPolicy'> & {
              payment?: Maybe<{
                creditCard?: Maybe<
                  Pick<
                    PurchaseInfoDetailsBillingInfoPaymentCreditCard,
                    'id' | 'type' | 'number' | 'label' | 'nameOnTheCard' | 'expirationDate'
                  >
                >
              }>
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
            }
          >
        }
      >
      availablePreferences?: Maybe<{
        hotelSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        airSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        carRentalSpecialRequests?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        meals?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        airMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        hotelMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
        carRentalMemberships?: Maybe<Array<Pick<PreferenceOptions, 'value' | 'label'>>>
      }>
      displaySettings?: Maybe<
        Pick<DisplaySettings, 'shouldShowShareTravelerContact' | 'shouldShowNdcFareAcknowledgment'>
      >
    }
    billingParameters?: Maybe<
      Array<
        Maybe<
          Pick<BillingParameter, 'travelVertical' | 'defaultCardId'> & {
            creditCards: Array<
              Pick<
                CreditCard,
                | 'id'
                | 'billingId'
                | 'label'
                | 'cardNumber'
                | 'expirationDate'
                | 'brandType'
                | 'isSiteCard'
                | 'isCardOutOfPolicy'
                | 'name'
              > & {
                metadata: Pick<
                  CreditCardMetadata,
                  'isRestricted' | 'isEditable' | 'isBillingAddressRequired' | 'type'
                >
                billingAddress?: Maybe<
                  Pick<
                    Address,
                    'countryCode' | 'postalCode' | 'stateCode' | 'city' | 'street1' | 'street2'
                  >
                >
              }
            >
            metadata: Pick<
              BillingParameterMetadata,
              'isBillingAddressRequired' | 'isCvvRequired' | 'isSingleUseCreditCardAllowed'
            >
          }
        >
      >
    >
    customFieldsConfiguration?: Maybe<{
      customFieldCollection: Array<
        Pick<
          CustomFieldCollectionItem,
          | 'collectionId'
          | 'collectionLabel'
          | 'collectionDescription'
          | 'isNotAppliedServerSideJavascript'
        > & {
          customFields: Array<
            Pick<
              CustomField,
              | 'type'
              | 'optional'
              | 'name'
              | 'tooltip'
              | 'label'
              | 'defaultValue'
              | 'isHasClientSideJavascriptValidator'
              | 'isNotAppliedServerSideJavascriptInitializer'
            > & {
              checkBoxDisplay?: Maybe<
                Pick<CustomFieldCheckBoxDisplay, 'isChecked' | 'isCheckedValueRequired'>
              >
              preTripApprovalDisplay?: Maybe<Pick<PreTripApprovalDisplay, 'ruleDescription'>>
              textDisplay?: Maybe<
                Pick<
                  CustomFieldTextDisplay,
                  'minLength' | 'maxLength' | 'numberOfLines' | 'characterWidth'
                >
              >
              enumerationDisplay?: Maybe<
                Pick<CustomFieldEnumerationDisplay, 'isRadioButtonsStyle'> & {
                  option?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>>
                }
              >
              numberDisplay?: Maybe<
                Pick<CustomFieldNumberDisplay, 'decimalPlaces' | 'minValue' | 'maxValue'>
              >
              dateTimeDisplay?: Maybe<
                Pick<
                  CustomFieldDateTimeDisplay,
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'isAllowedPastDateTime'
                >
              >
            }
          >
        }
      >
    }>
    dynamicSiteMessages: Array<
      Pick<
        DynamicSiteMessage,
        'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
      >
    >
  }
}

export type GetBrandConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type GetBrandConfigurationQuery = {
  brandConfiguration: Pick<
    BrandConfiguration,
    | 'isThemeEnabled'
    | 'theme'
    | 'partnerLogoUrl'
    | 'brandTitleLabel'
    | 'customMobilePartnerLogoUrl'
    | 'customMobileCompanyLogoUrl'
    | 'termsOfUseLinkEnabled'
  >
}

export type GetCarDetailsQueryVariables = Exact<{
  id: Scalars['String']
  input?: InputMaybe<CarDetailsInput>
  searchId?: InputMaybe<Scalars['String']>
  logsMetadata?: InputMaybe<LogsMetadata>
}>

export type GetCarDetailsQuery = {
  carDetails: {
    carCosts: Pick<CarCostsCarRental, 'isMileageUnlimited'> & {
      extraHour?: Maybe<Pick<Money, 'currency' | 'amount'>>
      extraDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
      perDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
    }
    carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
      extraDayRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      extraHourRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      perDayRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
      perWeekRate?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }
    taxes: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
    totalCost: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
    baseRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    netRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    serviceFeeRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    rate: {
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }
  }
}

export type GetCarRentalMultiVendorLocationsQueryVariables = Exact<{
  input: CarRentalLocationsInput
}>

export type GetCarRentalMultiVendorLocationsQuery = {
  carRentalMultiVendorLocations: {
    filter: Pick<CarRentalFilter, 'type'> & {
      filters: Array<Pick<CarRentalFiltersFilter, 'name' | 'selected' | 'enabled' | 'count'>>
    }
    message: Array<
      Pick<
        DynamicSiteMessage,
        'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
      >
    >
    preferenceAttribute: Array<
      Pick<CarRentalPreferenceAttribute, 'id'> & {
        preference?: Maybe<
          Pick<
            Preference,
            | 'id'
            | 'code'
            | 'highlight'
            | 'ownerType'
            | 'ownerName'
            | 'tooltipText'
            | 'shouldShowDefaultPreference'
          > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
        >
        vendor: Pick<Vendor, 'code' | 'name' | 'imageUrl'>
      }
    >
    location: Array<
      Pick<
        CarRentalMultiVendorLocation,
        | 'preferenceAttributeId'
        | 'distanceAsMiles'
        | 'distanceAsKilometers'
        | 'airportCode'
        | 'airportName'
        | 'privateLocation'
        | 'airportLocation'
        | 'companyLocationName'
        | 'locationId'
        | 'isOpen'
      > & {
        hours: Array<
          Pick<CarRentalLocationHours, 'allDay'> & {
            hours: Array<Pick<CarRentalLocationHoursItem, 'start' | 'end'>>
          }
        >
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
          | 'city'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        phone: Pick<CarRentalPhone, 'type' | 'number'>
        vendors: Array<Pick<Vendor, 'name' | 'code' | 'imageUrl'>>
      }
    >
  }
}

export type GetCarRentalsQueryVariables = Exact<{
  input: CarRentalsInput
}>

export type GetCarRentalsQuery = {
  carRentals: Pick<
    CarRentalsResult,
    | 'maxResults'
    | 'includesPreferred'
    | 'includesOutOfPolicy'
    | 'primaryVendorsMissing'
    | 'secondaryVendorsMissing'
  > & {
    page: Pick<Page, 'start' | 'end' | 'size'>
    car: Array<
      Pick<CarRentalCar, 'key' | 'isSelectable' | 'isPreferred' | 'unlimitedMileage'> & {
        details: Pick<
          CarRentalCarDetails,
          | 'type'
          | 'class'
          | 'transmission'
          | 'fuel'
          | 'passenger'
          | 'luggage'
          | 'imageUrl'
          | 'carImageUrl'
          | 'carDetailsImageUrl'
          | 'isAirConditioned'
          | 'contractName'
        >
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
        policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data'>>>
        }
        carCosts: Pick<CarCostsCarRental, 'isMileageUnlimited'> & {
          extraHour?: Maybe<Pick<Money, 'currency' | 'amount'>>
          extraDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
          perDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
        }
        carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
          extraDayRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          extraHourRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          perDayRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          perWeekRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
        serviceFee?: Maybe<
          Pick<ServiceFee, 'description' | 'id'> & {
            rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        >
        taxes: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        totalCost: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        rate: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        netRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        serviceFeeRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        pickUp: Pick<
          CarRentalCarInteraction,
          'locationId' | 'date' | 'time' | 'isAirportLocation'
        > & {
          workingHours: Pick<CarRentalWorkingHours, 'start' | 'end' | 'isAllDay'>
          phone: Pick<CarRentalPhone, 'number' | 'type'>
          address: Pick<
            Address,
            | 'city'
            | 'countryCode'
            | 'stateCode'
            | 'postalCode'
            | 'airportCode'
            | 'street1'
            | 'street2'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        dropOff: Pick<
          CarRentalCarInteraction,
          'locationId' | 'date' | 'time' | 'isAirportLocation'
        > & {
          workingHours: Pick<CarRentalWorkingHours, 'start' | 'end' | 'isAllDay'>
          phone: Pick<CarRentalPhone, 'number' | 'type'>
          address: Pick<
            Address,
            | 'city'
            | 'countryCode'
            | 'stateCode'
            | 'postalCode'
            | 'airportCode'
            | 'street1'
            | 'street2'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'url' | 'badgeUrl'>
        baseRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    filters: {
      class: Array<Pick<CarRentalClassFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>>
      type: Array<
        Pick<CarRentalTypeFilter, 'code' | 'selected' | 'count' | 'enabled'> & {
          lowestCost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      vendor: Array<
        Pick<CarRentalVendorFilter, 'code' | 'name' | 'imageUrl' | 'selected' | 'count' | 'enabled'>
      >
      transmission: Array<
        Pick<CarRentalTransmissionFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>
      >
      fuel: Array<Pick<CarRentalFuelTypeFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>>
    }
    message: Array<
      Pick<
        DynamicSiteMessage,
        'isDialog' | 'text' | 'mobileText' | 'header' | 'hasDesktopBannerImage'
      >
    >
    preferenceAttribute: Array<
      Pick<CarRentalPreferenceAttribute, 'id'> & {
        preference?: Maybe<
          Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
            customBadge: Pick<CustomBadge, 'name' | 'color'>
          }
        >
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
      }
    >
    location: Array<
      Pick<
        CarRentalLocation,
        | 'preferenceAttributeId'
        | 'distanceAsMiles'
        | 'distanceAsKilometers'
        | 'airportCode'
        | 'privateLocation'
        | 'airportLocation'
        | 'companyLocationName'
        | 'locationId'
        | 'isOpen'
      > & {
        hours: Array<
          Pick<CarRentalLocationHours, 'allDay'> & {
            hours: Array<Pick<CarRentalLocationHoursItem, 'start' | 'end'>>
          }
        >
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
          | 'city'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        phone: Pick<CarRentalPhone, 'type' | 'number'>
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
      }
    >
  }
}

export type GetCarRulesQueryVariables = Exact<{
  itineraryId: Scalars['String']
  carSegmentId: Scalars['String']
}>

export type GetCarRulesQuery = { carRules: Array<Pick<CarRules, 'header' | 'value'>> }

export type CreateCarRentalSearchMutationVariables = Exact<{
  input: CarRentalsInput
}>

export type CreateCarRentalSearchMutation = {
  createCarRentalSearch: Pick<
    CarRentalsResult,
    | 'maxResults'
    | 'includesPreferred'
    | 'includesOutOfPolicy'
    | 'primaryVendorsMissing'
    | 'secondaryVendorsMissing'
    | 'searchId'
  > & {
    page: Pick<Page, 'start' | 'end' | 'size'>
    car: Array<
      Pick<
        CarRentalCar,
        'key' | 'isSelectable' | 'isPreferred' | 'unlimitedMileage' | 'daysCharged'
      > & {
        details: Pick<
          CarRentalCarDetails,
          | 'type'
          | 'class'
          | 'transmission'
          | 'fuel'
          | 'passenger'
          | 'luggage'
          | 'imageUrl'
          | 'carImageUrl'
          | 'carDetailsImageUrl'
          | 'isAirConditioned'
          | 'contractName'
          | 'name'
        >
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
        policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'data' | 'type'>>>
        }
        carCosts: Pick<CarCostsCarRental, 'isMileageUnlimited'> & {
          extraHour?: Maybe<Pick<Money, 'currency' | 'amount'>>
          extraDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
          perDay?: Maybe<Pick<Money, 'currency' | 'amount'>>
        }
        carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
          extraDayRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          extraHourRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          perDayRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          perWeekRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
        serviceFee?: Maybe<
          Pick<ServiceFee, 'description' | 'id'> & {
            rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        >
        taxes: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        totalCost: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        rate: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        pickUp: Pick<
          CarRentalCarInteraction,
          'locationId' | 'date' | 'time' | 'isAirportLocation'
        > & {
          workingHours: Pick<CarRentalWorkingHours, 'start' | 'end' | 'isAllDay'>
          phone: Pick<CarRentalPhone, 'number' | 'type'>
          address: Pick<
            Address,
            | 'city'
            | 'countryCode'
            | 'stateCode'
            | 'postalCode'
            | 'airportCode'
            | 'street1'
            | 'street2'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        dropOff: Pick<
          CarRentalCarInteraction,
          'locationId' | 'date' | 'time' | 'isAirportLocation'
        > & {
          workingHours: Pick<CarRentalWorkingHours, 'start' | 'end' | 'isAllDay'>
          phone: Pick<CarRentalPhone, 'number' | 'type'>
          address: Pick<
            Address,
            | 'city'
            | 'countryCode'
            | 'stateCode'
            | 'postalCode'
            | 'airportCode'
            | 'street1'
            | 'street2'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'url' | 'badgeUrl'>
        baseRate?: Maybe<{
          primary: Pick<Money, 'currency' | 'amount'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    filters: {
      class: Array<Pick<CarRentalClassFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>>
      type: Array<
        Pick<CarRentalTypeFilter, 'code' | 'selected' | 'count' | 'enabled'> & {
          lowestCost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      vendor: Array<
        Pick<CarRentalVendorFilter, 'code' | 'name' | 'imageUrl' | 'selected' | 'count' | 'enabled'>
      >
      transmission: Array<
        Pick<CarRentalTransmissionFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>
      >
      fuel: Array<Pick<CarRentalFuelTypeFilter, 'code' | 'name' | 'selected' | 'count' | 'enabled'>>
    }
    message: Array<
      Pick<
        DynamicSiteMessage,
        'isDialog' | 'text' | 'mobileText' | 'header' | 'hasDesktopBannerImage'
      >
    >
    preferenceAttribute: Array<
      Pick<CarRentalPreferenceAttribute, 'id'> & {
        preference?: Maybe<
          Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
            customBadge: Pick<CustomBadge, 'name' | 'color'>
          }
        >
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
      }
    >
    location: Array<
      Pick<
        CarRentalLocation,
        | 'preferenceAttributeId'
        | 'distanceAsMiles'
        | 'distanceAsKilometers'
        | 'airportCode'
        | 'privateLocation'
        | 'airportLocation'
        | 'companyLocationName'
        | 'locationId'
        | 'isOpen'
      > & {
        hours: Array<
          Pick<CarRentalLocationHours, 'allDay'> & {
            hours: Array<Pick<CarRentalLocationHoursItem, 'start' | 'end'>>
          }
        >
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
          | 'city'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        phone: Pick<CarRentalPhone, 'type' | 'number'>
        vendor: Pick<Vendor, 'name' | 'code' | 'imageUrl'>
      }
    >
  }
}

export type GetCostSegmentsQueryVariables = Exact<{ [key: string]: never }>

export type GetCostSegmentsQuery = {
  costSegments?: Maybe<{
    segments: Array<
      Pick<
        CostSegment,
        | 'description'
        | 'id'
        | 'isActive'
        | 'isRequired'
        | 'isSplitCostAllocation'
        | 'isSplitCostRequired'
        | 'name'
        | 'splitCostAllocationTooltip'
        | 'tooltip'
      >
    >
  }>
}

export type GetMultiLayerCostAllocationQueryVariables = Exact<{
  input: MultiLayerCostAllocationInput
}>

export type GetMultiLayerCostAllocationQuery = {
  multiLayerCostAllocation?: Maybe<
    Pick<MultiLayerCostAllocation, 'totalResults'> & {
      allocation: Array<
        Pick<
          MultiLayerCostAllocationUnit,
          'name' | 'segmentId' | 'allocationId' | 'childrenRef' | 'parentRef' | 'code'
        > & { stats?: Maybe<Pick<MultiLayerCostAllocationStats, 'activeChildren'>> }
      >
    }
  >
}

export type GetDisplayConfigurationQueryVariables = Exact<{
  input?: InputMaybe<DisplayConfigurationInput>
}>

export type GetDisplayConfigurationQuery = {
  displayConfiguration: Pick<
    DisplayConfiguration,
    | 'isAdminRoleEnabled'
    | 'isMod2FlowEnabled'
    | 'isFlightSearchServiceEnabled'
    | 'areRecommendationsEnabled'
    | 'isPhoenixHotelSearchEnabled'
    | 'isPhoenixCarRentalSearchEnabled'
    | 'maxNumberOfGuestsPerRoom'
    | 'isTripAdvisorReviewsEnabled'
    | 'mapProvider'
    | 'isPerDiemDisplayEnabled'
    | 'isStarRatingsFilterEnabled'
    | 'isHoldTripAllowed'
    | 'isSoldOutFilterAvailable'
    | 'isPreBookCostAllocationEnabled'
    | 'isShowTravelerAcknowledgement'
    | 'isExcludeBasicFareBlock'
    | 'isSouthwestDirectConnectionEnabled'
    | 'isExpiringCreditCardForHotelAllowed'
    | 'isCreditCardSavingEnabled'
    | 'isAppleLockSettingsEnabled'
    | 'appleDsmMessage'
    | 'enableNearByAirport'
    | 'bookAndChangeAdvanceTime'
    | 'selfDelegation'
    | 'userDelegation'
    | 'optinoutOfUnusedTicket'
    | 'hideUnusedTickets'
    | 'defaultCarSize'
    | 'isMod2DoubleWriteEnabled'
  > & {
    explore: Pick<
      ExploreDisplayConfiguration,
      | 'isFlightsSearchEnabled'
      | 'isHotelsSearchEnabled'
      | 'isTrainsSearchEnabled'
      | 'isCarRentalsSearchEnabled'
      | 'searchDefaultsAirEnabled'
      | 'searchDefaultsHotelEnabled'
      | 'searchDefaultsCarEnabled'
    > & {
      messages: Array<
        Pick<DynamicSiteMessage, 'header' | 'text' | 'isDialog' | 'hasDesktopBannerImage'>
      >
      message?: Maybe<Pick<SearchMessage, 'label' | 'message'>>
    }
    flights: Pick<
      FlightsDisplayConfiguration,
      | 'defaultSort'
      | 'areNearbyAirportsIncluded'
      | 'isMorningAfternoonEveningEnabled'
      | 'isAnytimeEnabled'
      | 'isDefaultAnyTime'
      | 'isFirstClassEnabled'
      | 'isBusinessClassEnabled'
      | 'isPremiumEconomyClassEnabled'
      | 'isEconomyClassEnabled'
      | 'firstClassCustomLabel'
      | 'businessClassCustomLabel'
      | 'premiumEconomyClassCustomLabel'
      | 'economyClassCustomLabel'
      | 'defaultAirTravelType'
      | 'isOutOfPolicyFlightsSelectable'
    > & {
      fares: Pick<
        FaresFlightsDisplayConfiguration,
        'defaultFareType' | 'areRefundableEnabled' | 'areUnrestrictedEnabled'
      >
    }
    carRentals: Pick<
      CarRentalsDisplayConfiguration,
      'areDeliveryAndCollectionSupported' | 'requireCarPaymentForm'
    >
    hotels: Pick<HotelDisplayConfiguration, 'isStarRatingEnabled'>
    geosureReport: Pick<GeosureReport, 'enabled' | 'variant'>
    flightSearchTimeRange?: Maybe<Pick<FlightSearchTimeRange, 'before' | 'after'>>
    flightSearchTimeRangeNonUS?: Maybe<Pick<FlightSearchTimeRange, 'before' | 'after'>>
    hotelSearchRadius: Pick<HotelSearchRadius, 'default' | 'max'>
    privacyPolicy: Pick<PrivacyPolicyConfiguration, 'url' | 'label'>
    support: {
      mobile: {
        email?: Maybe<Pick<EmailSupport, 'emailAddress' | 'label'>>
        phoneNumbers: Array<Pick<PhoneSupport, 'phoneNumber' | 'label'>>
      }
      desktop: Pick<DesktopSupportDisplayConfiguration, 'email'>
      technicalSupport: Pick<TechnicalSupportDisplayConfiguration, 'isEnabled' | 'text'>
    }
    externalLinks: Pick<ExternalLinks, 'isExternalLinksEnabled'> & {
      links: Array<Pick<ExternalLink, 'url' | 'label'>>
    }
    companyResourceConfiguration: Pick<
      CompanyResourceConfiguration,
      'id' | 'descriptionMessage'
    > & { resourceTexts: Array<Pick<CompanyResourceText, 'label' | 'url'>> }
    serviceFeeConfiguration: Array<
      Pick<
        ServiceFeeConfigurationDto,
        | 'id'
        | 'description'
        | 'label'
        | 'name'
        | 'serviceFeesAmount'
        | 'serviceFeesAmountCurrency'
        | 'serviceId'
        | 'transactionFeesChangeText'
        | 'type'
        | 'vendorCodes'
        | 'vendorCodesList'
        | 'vendorNamesList'
      >
    >
  }
}

export type ZooKeeperFlagsQueryVariables = Exact<{ [key: string]: never }>

export type ZooKeeperFlagsQuery = {
  zooKeeperFlags: Pick<
    ZooKeeperFlags,
    | 'policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled'
    | 'southwestCheckInEmailNotificationEnabled'
    | 'southwestCheckInSmsNotificationEnabled'
    | 'removeNotificationSectionOnTheCheckoutPageEnabled'
    | 'nonStopFilterByDefaultEnabled'
    | 'blockBasicEconomyEnabled'
    | 'remoteProfileMessage'
    | 'remoteProfileEnabled'
    | 'removeHotelStarRatingEnabled'
    | 'disableTsaHazmatMessageEnabled'
    | 'tieredCostAllocationEnabled'
    | 'removePerDiemLinkEnabled'
  >
}

export type CreateMapKitTokenMutationVariables = Exact<{ [key: string]: never }>

export type CreateMapKitTokenMutation = { createMapKitToken: Pick<MapKitToken, 'token'> }

export type GetFeatureFlagsQueryVariables = Exact<{
  input: FeatureFlagsInput
}>

export type GetFeatureFlagsQuery = {
  featureFlags: Pick<
    FeatureFlags,
    | 'isToggleEttaThemeEnabled'
    | 'isWhatFixPWAEnabled'
    | 'isSSOEnabled'
    | 'isRailServiceEnabled'
    | 'isMultiCityEnabled'
    | 'isFlightSafetyCheckEnabled'
    | 'isHotelSafetyCheckEnabled'
    | 'isHotelRateCapEnabled'
    | 'isDelegatedFlowEnabled'
    | 'isUnusedTicketsEnabled'
    | 'isUnusedTicketsOptOutEnabled'
    | 'isDesktopUnusedTicketsOptOutEnabled'
    | 'isDesktopUnusedTicketsEnabled'
    | 'isDesktopLayoutFlightFlowEnabled'
    | 'isDesktopLayoutHotelFlowEnabled'
    | 'isDesktopLayoutRTPFlowEnabled'
    | 'isEttaAutoTranslationEnabled'
    | 'isDesktopLayoutPurchaseFlowEnabled'
    | 'isSustainabilityEnabled'
    | 'isDesktopTripCartEnabled'
    | 'isSplitCostAllocationEnabled'
    | 'isDesktopLayoutTripsFlowEnabled'
    | 'isFijiPurchaseFixEnabled'
    | 'isAddTrainToItineraryEnabled'
    | 'isPasswordExpirationFlowEnabled'
    | 'isDesktopDelegatedFlowEnabled'
    | 'isUberEnabled'
    | 'isReserveAirportPickupEnabled'
    | 'isMobilityExpenseEnabled'
    | 'isMobilityZoningEnabled'
    | 'isMobilityDesktopEnabled'
    | 'isMobilityMultiModalEnabled'
    | 'isMobilitySearchFormV3Enabled'
    | 'isMobilityBookTabEnabled'
    | 'isDesktopLayoutTrainTabEnabled'
    | 'isDesktopLayoutMultiCityEnabled'
    | 'isRailAccreditationEnabled'
    | 'isRailSearchFiltersEnabled'
    | 'isRailSearchSortEnabled'
    | 'isRailCardSelectionEnabled'
    | 'isRailOutofPolicyEnabled'
    | 'isMobileHeldTripsFlowEnabled'
    | 'isKeyboardNavigationStylesEnabled'
    | 'isDesktopHeldTripsFlowEnabled'
    | 'isFeedbackEnabled'
    | 'isTripExpiredDateGeneratedByUTCEnable'
    | 'isRecentSearchesEnabled'
    | 'isAppleMapProviderEnabled'
    | 'isCustomLogoutRedirectEnabled'
    | 'isOnHoldEnabled'
    | 'isSettingsPageInformationSectionEnabled'
    | 'isSettingsPageSettingsSectionEnabled'
    | 'isSettingsPageOrganizationSectionEnabled'
    | 'isRedirectOnSiteDomainEnabled'
    | 'isMobilityOnDemandRidesEnabled'
    | 'isNewAirFiltersModalEnabled'
    | 'isNewAirExposedFiltersEnabled'
    | 'isNewHotelExposedFiltersEnabled'
    | 'isNewHotelFiltersModalEnabled'
    | 'isAppleBannerEnabled'
    | 'isBookItineraryTravelPreferencesEnabled'
    | 'isSsoSettingsEnabled'
    | 'isAlertsEnabled'
    | 'isCarRentalsNewFiltersEnabled'
    | 'isRecentSearchesFilteringEnabled'
    | 'isExternalLinksEnabled'
    | 'isActiveItineraryPurchaseFlowEnabled'
    | 'isHotelRetryWorkflowEnabled'
    | 'isNewCheckoutPageLayoutEnabled'
    | 'isTripAlignmentWorkflowEnabled'
    | 'isChangingReturnFlightEnabled'
    | 'isBookAgainEnabled'
    | 'isNewEttaOnlyEnabled'
    | 'isActivateAccountFlowEnabled'
    | 'isNewHelpCenterLinkEnabled'
    | 'isBookAgainDelegateFlowEnabled'
    | 'isNewTripDetailsPageEnabled'
    | 'isNewLoadingConfirmationEnabled'
    | 'isOOPModalOnSearchResultFlightPageEnabled'
    | 'isServiceFeeEnabled'
    | 'isUserActivationEnabled'
    | 'isQualtricsEnabled'
    | 'isOOPModalEnabled'
    | 'isPWACookieConsentEnabled'
    | 'isNewEmissionsBrandingEnabled'
    | 'isRetailShoppingUIEnabled'
  >
}

export type CreateSearchMutationVariables = Exact<{
  itineraryId: Scalars['String']
  searchBy: Scalars['Int']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  distance?: InputMaybe<Scalars['Float']>
  distanceUnit?: InputMaybe<Scalars['String']>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  guests: Scalars['Int']
  hotelName?: InputMaybe<Scalars['String']>
  airportCode?: InputMaybe<Scalars['String']>
}>

export type CreateSearchMutation = { createSearch: Pick<CreateSearchResult, 'hotelResultId'> }

export type GetHotelQueryVariables = Exact<{
  logsMetadata?: InputMaybe<LogsMetadata>
  hotelResultId?: InputMaybe<Scalars['String']>
  groupId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  guests: Scalars['Int']
}>

export type GetHotelQuery = {
  hotel: Pick<
    Hotel,
    | 'id'
    | 'isEcoFriendly'
    | 'name'
    | 'starRating'
    | 'phoneNumber'
    | 'chainIconUrl'
    | 'chainCode'
    | 'logoPhotoUrl'
    | 'isPreferred'
    | 'isCancellable'
    | 'isSoldOut'
    | 'reviewsProvider'
    | 'totalReviewCount'
    | 'medianReviewRating'
    | 'medianReviewRatingImageUrl'
    | 'hotelReviewsDetailsUrl'
    | 'resultKey'
    | 'notes'
  > & {
    descriptions?: Maybe<Array<Maybe<Pick<HotelDescription, 'title' | 'body'>>>>
    address: Pick<
      Address,
      'airportCode' | 'street1' | 'street2' | 'city' | 'stateCode' | 'postalCode' | 'countryCode'
    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
    distance: Pick<Distance, 'value' | 'units'>
    policy?: Maybe<Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'>>
    rate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    perDiemRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
    privateRate?: Maybe<
      Pick<PrivateRate, 'realTime' | 'corporate'> & {
        averageRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }
    >
    rooms?: Maybe<
      Array<
        Pick<
          Room,
          | 'id'
          | 'type'
          | 'description'
          | 'inventoryId'
          | 'isCorporateRate'
          | 'isFreeCancellable'
          | 'source'
          | 'isSelectable'
          | 'cancellationPolicy'
          | 'cancellationPolicyDescription'
          | 'preferredTag'
          | 'rateQualifier'
          | 'numberOfGuests'
        > & {
          totalCost: Pick<Money, 'amount' | 'currency'>
          totalCostRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          feesAndTaxes: Pick<Money, 'amount' | 'currency'>
          feesAndTaxesRate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          averageRate: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          dailyRates: Array<
            Pick<DailyRate, 'date'> & {
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
          policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'>
        }
      >
    >
    amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
    reviews: Array<
      Maybe<
        Pick<
          HotelReview,
          'title' | 'summary' | 'content' | 'date' | 'userRating' | 'ratingImageUrl'
        >
      >
    >
    photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
    preference?: Maybe<
      Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
        customBadge: Pick<CustomBadge, 'name' | 'color'>
      }
    >
    exclusiveAmenities?: Maybe<Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>>
  }
}

export type GetHotelsQueryVariables = Exact<{
  logsMetadata?: InputMaybe<LogsMetadata>
  hotelResultId?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  hotelName?: InputMaybe<Scalars['String']>
  distance?: InputMaybe<Scalars['Float']>
  starRatings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  amenityIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  guests: Scalars['Int']
  groupId?: InputMaybe<Scalars['String']>
  searchBy?: InputMaybe<Scalars['String']>
  airportCode?: InputMaybe<Scalars['String']>
  stationCode?: InputMaybe<Scalars['String']>
  locationName?: InputMaybe<Scalars['String']>
  nameSearch?: InputMaybe<Scalars['Boolean']>
  hideSoldOut?: InputMaybe<Scalars['Boolean']>
  deviceClass?: InputMaybe<DeviceClass>
  failedBooking?: InputMaybe<FailedBooking>
}>

export type GetHotelsQuery = {
  hotels: Pick<HotelsResult, 'totalCount' | 'warningCodes'> & {
    hotels: Array<
      Pick<
        Hotel,
        | 'id'
        | 'isEcoFriendly'
        | 'name'
        | 'starRating'
        | 'phoneNumber'
        | 'chainIconUrl'
        | 'chainCode'
        | 'logoPhotoUrl'
        | 'isPreferred'
        | 'isCancellable'
        | 'isSoldOut'
        | 'reviewsProvider'
        | 'totalReviewCount'
        | 'medianReviewRating'
        | 'medianReviewRatingImageUrl'
        | 'hotelReviewsDetailsUrl'
        | 'resultKey'
        | 'notes'
      > & {
        descriptions?: Maybe<Array<Maybe<Pick<HotelDescription, 'title' | 'body'>>>>
        address: Pick<
          Address,
          | 'airportCode'
          | 'street1'
          | 'street2'
          | 'city'
          | 'stateCode'
          | 'postalCode'
          | 'countryCode'
        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        distance: Pick<Distance, 'value' | 'units'>
        policy?: Maybe<Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'>>
        rate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        perDiemRate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        privateRate?: Maybe<
          Pick<PrivateRate, 'realTime' | 'corporate'> & {
            averageRate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
        rooms?: Maybe<
          Array<
            Pick<
              Room,
              | 'id'
              | 'type'
              | 'description'
              | 'inventoryId'
              | 'isCorporateRate'
              | 'isFreeCancellable'
              | 'source'
              | 'isSelectable'
              | 'cancellationPolicy'
              | 'cancellationPolicyDescription'
              | 'preferredTag'
              | 'rateQualifier'
              | 'numberOfGuests'
            > & {
              totalCost: Pick<Money, 'amount' | 'currency'>
              totalCostRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes: Pick<Money, 'amount' | 'currency'>
              feesAndTaxesRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              averageRate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              dailyRates: Array<
                Pick<DailyRate, 'date'> & {
                  rate: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
              policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'>
            }
          >
        >
        amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
        reviews: Array<
          Maybe<
            Pick<
              HotelReview,
              'title' | 'summary' | 'content' | 'date' | 'userRating' | 'ratingImageUrl'
            >
          >
        >
        photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
        preference?: Maybe<
          Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
            customBadge: Pick<CustomBadge, 'name' | 'color'>
          }
        >
        exclusiveAmenities?: Maybe<Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>>
      }
    >
    groups?: Maybe<Array<Pick<HotelGroup, 'id' | 'name' | 'count'>>>
    defaultGroup?: Maybe<Pick<HotelGroup, 'id' | 'name' | 'count'>>
    superBrandFilters: Array<
      Pick<SuperBrandFilter, 'brandId' | 'brandDisplayName' | 'hotelCount'> & {
        brands: Array<Pick<BrandFilter, 'brandId' | 'brandDisplayName' | 'hotelCount'>>
      }
    >
    brandFilters: Array<Pick<BrandFilter, 'brandId' | 'brandDisplayName' | 'hotelCount'>>
    amenityFilters: Array<Pick<AmenityFilter, 'amenityId' | 'amenityDisplayName' | 'hotelCount'>>
    messages?: Maybe<
      Array<Pick<DynamicSiteMessage, 'header' | 'text' | 'isDialog' | 'hasDesktopBannerImage'>>
    >
    perDiemRate?: Maybe<{
      primary: Pick<Money, 'amount' | 'currency'>
      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
    }>
  }
}

export type ClearMeetingInfoMutationVariables = Exact<{ [key: string]: never }>

export type ClearMeetingInfoMutation = {
  clearMeetingInfo: Pick<BaseResponse, 'code' | 'success' | 'message' | 'errorCodes'>
}

export type GetMeetingInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetMeetingInfoQuery = {
  getMeetingInfo:
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
    | ({ __typename: 'UpsertMeetingInfoResponse' } & Pick<
        UpsertMeetingInfoResponse,
        'isMeetingModeDelegate'
      > & {
          meeting: Pick<
            MeetingInfo,
            | 'id'
            | 'externalId'
            | 'name'
            | 'location'
            | 'provider'
            | 'confirmationNumber'
            | 'message'
          > & {
            policies: Pick<
              MeetingPolicies,
              'isFlightEnabled' | 'isHotelEnabled' | 'isCarEnabled'
            > & {
              arrival?: Maybe<Pick<DateRange, 'startDate' | 'endDate'>>
              departure?: Maybe<Pick<DateRange, 'startDate' | 'endDate'>>
              flightPolicies?: Maybe<
                Pick<FlightPolicies, 'destinationAirport' | 'alternateDestinationAirportAllowed'>
              >
            }
            dates: Pick<DateRange, 'startDate' | 'endDate'>
            customFields: Array<Pick<MeetingCustomField, 'name' | 'value'>>
          }
          participant: Pick<ParticipantInfo, 'email' | 'firstName' | 'lastName'> & {
            customFields: Array<Pick<ParticipantCustomField, 'name' | 'value'>>
          }
        })
}

export type UpdateMeetingIdCookieMutationVariables = Exact<{
  input: UpdateMeetingIdCookieInput
}>

export type UpdateMeetingIdCookieMutation = {
  updateMeetingIdCookie: Pick<UpdateMeetingIdCookieResponse, 'success'>
}

export type UpsertMeetingInfoMutationVariables = Exact<{
  input: UpsertMeetingInfoInput
}>

export type UpsertMeetingInfoMutation = {
  upsertMeetingInfo:
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
    | ({ __typename: 'UpsertMeetingInfoResponse' } & Pick<
        UpsertMeetingInfoResponse,
        'isMeetingModeDelegate'
      > & {
          meeting: Pick<
            MeetingInfo,
            | 'id'
            | 'externalId'
            | 'name'
            | 'location'
            | 'provider'
            | 'confirmationNumber'
            | 'message'
          > & {
            policies: Pick<
              MeetingPolicies,
              'isFlightEnabled' | 'isHotelEnabled' | 'isCarEnabled'
            > & {
              arrival?: Maybe<Pick<DateRange, 'startDate' | 'endDate'>>
              departure?: Maybe<Pick<DateRange, 'startDate' | 'endDate'>>
              flightPolicies?: Maybe<
                Pick<FlightPolicies, 'destinationAirport' | 'alternateDestinationAirportAllowed'>
              >
            }
            dates: Pick<DateRange, 'startDate' | 'endDate'>
            customFields: Array<Pick<MeetingCustomField, 'name' | 'value'>>
          }
          participant: Pick<ParticipantInfo, 'email' | 'firstName' | 'lastName'> & {
            customFields: Array<Pick<ParticipantCustomField, 'name' | 'value'>>
          }
        })
}

export type SearchMobilityMultiModalQueryVariables = Exact<{
  input: MobilityMultiModalSearchRequest
}>

export type SearchMobilityMultiModalQuery = {
  searchMobilityMultiModal:
    | ({ __typename: 'MobilityMultiModalSearchErrorResponse' } & Pick<
        MobilityMultiModalSearchErrorResponse,
        'reason'
      >)
    | ({ __typename: 'MobilityMultiModalSearchResponse' } & {
        routes: Array<
          Pick<
            MobilityMultiModalRoutes,
            'routeName' | 'routeType' | 'routeDistance' | 'routeDescription' | 'estimatedDuration'
          > & {
            startLocation: Pick<MobilityMultiModalLocation, 'placeId' | 'address'> & {
              geocode?: Maybe<Pick<Geocode, 'long' | 'lat'>>
            }
            estimatePrice: Pick<Money, 'currency' | 'amount'>
            endLocation: Pick<MobilityMultiModalLocation, 'placeId' | 'address'> & {
              geocode?: Maybe<Pick<Geocode, 'long' | 'lat'>>
            }
          }
        >
      })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type SetRcSessionCookieWithCookieMutationVariables = Exact<{ [key: string]: never }>

export type SetRcSessionCookieWithCookieMutation = Pick<Mutation, 'setRcSessionCookieWithCookie'>

export type SetRcSessionCookieMutationVariables = Exact<{
  input: SetRcSessionCookieInput
}>

export type SetRcSessionCookieMutation = Pick<Mutation, 'setRcSessionCookie'>

export type PartnerCoBrandingConfigurationQueryVariables = Exact<{
  input: PartnerCoBrandingConfigurationInput
}>

export type PartnerCoBrandingConfigurationQuery = {
  partnerCoBrandingConfiguration: Pick<
    PartnerCoBrandingCofiguration,
    | 'partnerLogoUrl'
    | 'customPartnerLogoUrl'
    | 'customCompanyLogoUrl'
    | 'customMobilePartnerLogoUrl'
    | 'customMobileCompanyLogoUrl'
    | 'termsOfUseLabel'
    | 'termsOfUseLinkEnabled'
    | 'termsOfUseLinkUrl'
    | 'termsOfUse'
    | 'copyright'
    | 'privacyPolicyEnabled'
    | 'privacyPolicyLinkEnabled'
    | 'privacyPolicyText'
    | 'privacyPolicyLabel'
    | 'privacyPolicyLinkUrl'
    | 'customFooterEnabled'
    | 'customFooter'
    | 'supportTextEnabled'
    | 'supportText'
    | 'faqLinkEnabled'
    | 'faqLabel'
    | 'faqLinkUrl'
    | 'partnerCoBrandingEnabled'
    | 'theme'
  >
}

export type GetOrdersQueryVariables = Exact<{
  input: OrdersInput
}>

export type GetOrdersQuery = {
  orders: Pick<OrdersResult, 'hasNextPage' | 'startIndex' | 'pageSize' | 'totalResults'> & {
    transactionGroups: Array<
      Pick<
        TransactionGroup,
        | 'transactionId'
        | 'name'
        | 'rollUpStartTime'
        | 'rollUpEndTime'
        | 'serviceCategoryList'
        | 'transactionGroupId'
        | 'cityImageUrl'
        | 'travelerId'
        | 'travelerName'
      > & { meetingInfo?: Maybe<Pick<TripMeetingInfoDto, 'id' | 'name'>> }
    >
  }
}

export type GetUserTripsQueryVariables = Exact<{
  input: UserTripsInput
}>

export type GetUserTripsQuery = {
  userTrips:
    | ({ __typename: 'GetUserTripsResponse' } & Pick<
        GetUserTripsResponse,
        'totalResults' | 'tripsType'
      > & {
          trips: Array<Pick<TripSummary, 'domains' | 'endDate' | 'id' | 'title' | 'startDate'>>
          page: Pick<Page, 'start' | 'size' | 'end'>
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type CancelReservationMutationVariables = Exact<{
  input: CancelReservationInput
}>

export type CancelReservationMutation = {
  cancelReservation:
    | ({ __typename: 'CancelReservationResponse' } & Pick<
        CancelReservationResponse,
        'success' | 'reservationId'
      >)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type GetTripDetailsQueryVariables = Exact<{
  input: TripDetailsInput
}>

export type GetTripDetailsQuery = {
  tripDetails:
    | ({ __typename: 'GetTripDetailsResponse' } & Pick<
        GetTripDetailsResponse,
        'tripId' | 'tripType'
      > & {
          reservations: Array<
            | ({ __typename: 'CarRentalReservation' } & Pick<
                CarRentalReservation,
                'confirmationNumber' | 'id' | 'isPaid' | 'domain' | 'status'
              > & {
                  reservedCarRental: Pick<
                    CarRental,
                    'dropOffDate' | 'dropOffTime' | 'key' | 'pickupDate' | 'pickupTime'
                  > & {
                    pickupLocation: Pick<
                      CarRentalLocationDetails,
                      'isAirport' | 'locationId' | 'phone'
                    > & {
                      locationAddress: Pick<
                        Address,
                        | 'stateCode'
                        | 'airportCode'
                        | 'city'
                        | 'countryCode'
                        | 'locationName'
                        | 'postalCode'
                        | 'street1'
                        | 'street2'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      openingHours: Array<
                        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
                          times: Array<
                            Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>
                          >
                        }
                      >
                    }
                    carRentalLoyalty?: Maybe<
                      Pick<LoyaltyProgram, 'label' | 'number' | 'vendorCode'>
                    >
                    dropOffLocation: Pick<
                      CarRentalLocationDetails,
                      'isAirport' | 'locationId' | 'phone'
                    > & {
                      locationAddress: Pick<
                        Address,
                        | 'street1'
                        | 'stateCode'
                        | 'postalCode'
                        | 'locationName'
                        | 'countryCode'
                        | 'city'
                        | 'airportCode'
                        | 'street2'
                      > & { geocode?: Maybe<Pick<Geocode, 'long' | 'lat'>> }
                      openingHours: Array<
                        Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
                          times: Array<
                            Pick<CarRentalOpeningHoursTimeInterval, 'openingTime' | 'closingTime'>
                          >
                        }
                      >
                    }
                    vendor: Pick<Vendor, 'name' | 'imageUrl' | 'code'>
                    vehicle: Pick<
                      CarRentalVehicle,
                      | 'vehicleClass'
                      | 'transmissionType'
                      | 'passengerCapacity'
                      | 'modelName'
                      | 'isAirCondition'
                      | 'fuelType'
                      | 'imageUrl'
                      | 'category'
                      | 'baggageCapacity'
                    >
                    termsAndConditions: Array<Pick<CarRules, 'value' | 'header'>>
                    safetyCheck: Pick<CarRentalSafetyCheck, 'url' | 'messageCode' | 'badgeUrl'>
                    rate: Pick<CarRentalRate, 'rateCode' | 'distanceUnlimited' | 'daysCharged'> & {
                      totalTaxes: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      totalRate: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      dailyRate: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                      extraDayCharge?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      extraHourCharge?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                      totalCost: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    }
                  }
                })
            | ({ __typename: 'HotelReservation' } & Pick<HotelReservation, 'domain' | 'id'>)
          >
          tripCostDetails: {
            domainCostSummary: Array<
              | ({ __typename: 'CarRentalReservationsCostSummary' } & Pick<
                  CarRentalReservationsCostSummary,
                  'domain'
                > & {
                    totalCost: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    totalRate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    totalTaxes: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  })
              | ({ __typename: 'HotelReservationsCostSummary' } & Pick<
                  HotelReservationsCostSummary,
                  'domain'
                >)
            >
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
          tripSummary: Pick<TripSummary, 'domains' | 'endDate' | 'id' | 'startDate' | 'title'>
        })
    | ({ __typename: 'TripNotFoundResponse' } & Pick<TripNotFoundResponse, 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type BookAgainTripMutationVariables = Exact<{
  input: BookAgainTripInput
}>

export type BookAgainTripMutation = {
  bookAgainTrip: {
    itinerary: Pick<
      Itinerary,
      | 'itineraryId'
      | 'expiredAt'
      | 'flightId'
      | 'isMultiCity'
      | 'isTripAligned'
      | 'isMultiDestination'
      | 'isOnHold'
    > & {
      virtualPay?: Maybe<Pick<VirtualPay, 'isVirtualPayEnabled' | 'isVirtualPayRestricted'>>
      availableSegments?: Maybe<
        Pick<AvailableSegments, 'canAddHotel' | 'canAddFlight' | 'canAddCarRental'>
      >
      tripStatus?: Maybe<
        Pick<
          TripPostReservation,
          | 'isChangeTripAllowed'
          | 'isHoldTripAllowed'
          | 'areSegmentsRemovable'
          | 'areSegmentsChangeable'
          | 'areSegmentsCustomizable'
          | 'areExtraSegmentsAllowed'
          | 'areTicketOptionsAllowed'
        >
      >
      trip: Pick<ItineraryTrip, 'name' | 'startDate' | 'endDate' | 'isNameEditable' | 'imageUrl'>
      tripCost?: Maybe<
        Pick<TripCostSummary, 'allowUnusedTicketOptout' | 'hasOptOutUnusedTickets'> & {
          payNow?: Maybe<{
            hotel?: Maybe<{
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            flight?: Maybe<
              Array<
                Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    base?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    total: {
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    serviceFee?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'currency' | 'amount'>
                            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                          }
                        }
                      >
                    >
                  }
                >
              >
            >
            train?: Maybe<
              Array<
                Maybe<{
                  base?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  total: {
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }
                  feesAndTaxes?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  transactionFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  serviceFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                }>
              >
            >
            car?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
          payLater?: Maybe<{
            hotel?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            car?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            carServices?: Maybe<
              Array<
                Maybe<
                  Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    timeBase?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    offHours?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    airportFees?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    meetAndGreet?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    surfaceTransport?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    parking?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fuelSurcharge?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    accountDiscount?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    rateQuote?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    stops?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    tolls?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    extraService?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    distanceBased?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    gratuity?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    taxes: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    accountService?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                  }
                >
              >
            >
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
          unusedTicket?: Maybe<
            Pick<UnusedTicket, 'status'> & {
              unvalidated?: Maybe<{
                original?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                penalty?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                newTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                exchangedTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                residual?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                addCollect?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                forfeiture?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }>
              validated?: Maybe<{
                original?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                penalty?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                newTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                exchangedTicket: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                residual?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                addCollect?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                forfeiture?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }>
            }
          >
          totalWithUnusedTicket?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          fareTaxInfo?: Maybe<
            Array<
              Pick<FareTaxInfo, 'code' | 'name'> & {
                amount: {
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }
              }
            >
          >
        }
      >
      itineraryItemResponse: Array<Pick<ItineraryItem, 'code' | 'fieldName' | 'severity'>>
      messages?: Maybe<
        Array<Pick<DynamicSiteMessage, 'text' | 'header' | 'isDialog' | 'hasDesktopBannerImage'>>
      >
      holdRules?: Maybe<
        Pick<HoldRules, 'autoCancellationTime' | 'reasonsForNotAllowing' | 'holdAllowed'>
      >
      segments?: Maybe<
        Array<
          Pick<Segment, 'uuid' | 'position' | 'type'> & {
            hotel?: Maybe<
              Pick<
                HotelSegment,
                | 'alignedWithFlightDate'
                | 'name'
                | 'checkIn'
                | 'checkOut'
                | 'segmentState'
                | 'rateQualifier'
                | 'imageUrl'
                | 'id'
                | 'roomType'
                | 'hasDeposit'
                | 'source'
                | 'isReserved'
                | 'rulesAndRestrictions'
                | 'isCancellable'
                | 'starRating'
                | 'cancellationPolicy'
                | 'cancellationPolicyDescription'
                | 'roomDescription'
                | 'roomCount'
                | 'medianReviewRating'
                | 'isPreferred'
                | 'isEcoFriendly'
                | 'phone'
                | 'hotelId'
                | 'vendorCode'
                | 'alignState'
              > & {
                totalCost: Pick<Money, 'amount' | 'currency'>
                averageRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                dailyRates: Array<
                  Pick<DailyRate, 'date'> & {
                    rate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                address: Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                numberOfGuests: Pick<NumberOfGuests, 'adults'>
                amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                exclusiveAmenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
                preferredDescription?: Maybe<
                  Pick<
                    Preference,
                    | 'id'
                    | 'code'
                    | 'highlight'
                    | 'ownerType'
                    | 'ownerName'
                    | 'tooltipText'
                    | 'shouldShowDefaultPreference'
                  > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                >
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                serviceFeeRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
              }
            >
            carService?: Maybe<
              Pick<
                CarServiceSegment,
                | 'carId'
                | 'key'
                | 'noRateText'
                | 'rateType'
                | 'name'
                | 'numberOfPassengers'
                | 'chargeDisclaimer'
                | 'cancellationPolicy'
                | 'isReserved'
                | 'duration'
                | 'logo'
              > & {
                preferredDescription?: Maybe<
                  Pick<
                    PreferredDescription,
                    'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                  >
                >
                preferenceAttributes?: Maybe<
                  Array<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                >
                stops?: Maybe<
                  Array<
                    Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  >
                >
                pickUp: Pick<CarServicePickUp, 'time'> & {
                  location?: Maybe<{
                    flight: Pick<
                      AirportPickUpLocationFlight,
                      | 'airportName'
                      | 'isCommercial'
                      | 'carrierCode'
                      | 'flightNumber'
                      | 'dateTime'
                      | 'airportCode'
                    >
                    geocode: Pick<Geocode, 'lat' | 'long'>
                  }>
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
                dropOff: Pick<CarServiceDropOff, 'asDirected' | 'time'> & {
                  location?: Maybe<
                    Pick<
                      DirectedDropOffLocation,
                      'asDirected' | 'requestedHours' | 'requestedDuration' | 'asDirectedMultiDay'
                    > & {
                      flight?: Maybe<
                        Pick<
                          AirportPickUpLocationFlight,
                          | 'airportName'
                          | 'isCommercial'
                          | 'carrierCode'
                          | 'flightNumber'
                          | 'dateTime'
                          | 'airportCode'
                        >
                      >
                      geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                    }
                  >
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
                provider?: Maybe<Pick<CarServiceProvider, 'logo' | 'name'>>
                polylineGeocodes?: Maybe<Array<Pick<Geocode, 'lat' | 'long'>>>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
                car: Pick<CarServiceSegmentCar, 'capacity' | 'imageUrl' | 'description' | 'type'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
            carRental?: Maybe<
              Pick<
                CarRentalSegment,
                | 'carId'
                | 'name'
                | 'segmentState'
                | 'alignedWithFlightDate'
                | 'alignedWithFlightTime'
                | 'imageUrl'
                | 'carImageUrl'
                | 'vendorImage'
                | 'carClass'
                | 'carType'
                | 'carTransmission'
                | 'vendor'
                | 'vendorCode'
                | 'isCarAirConditioned'
                | 'contractName'
                | 'carCapacity'
                | 'carLuggage'
                | 'isReserved'
                | 'pickUpTime'
                | 'dropOffTime'
                | 'pickupLocationId'
                | 'dropoffLocationId'
                | 'isPreferred'
                | 'alignState'
              > & {
                carDetails: Pick<
                  CarRentalSegmentCarDetails,
                  | 'imageUrl'
                  | 'class'
                  | 'type'
                  | 'transmission'
                  | 'fuel'
                  | 'capacity'
                  | 'luggage'
                  | 'isAirConditioned'
                  | 'contractName'
                >
                vendorDetails: Pick<CarRentalSegmentVendorDetails, 'imageUrl' | 'code' | 'name'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFeeRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFee?: Maybe<
                  Pick<ServiceFee, 'id' | 'description'> & {
                    rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                >
                carLocationType?: Maybe<{
                  pickup: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
                  dropoff: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                preferredDescription?: Maybe<
                  Pick<
                    PreferredDescription,
                    'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                  >
                >
                carCosts: Pick<CarCostsCarSegment, 'isMileageUnlimited'> & {
                  extraDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  extraHour?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  perDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  perWeek?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
                  extraDayRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  extraHourRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  perDayRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  perWeekRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }
                pickUpLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                  phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
                dropOffLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                  phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
                safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>
              }
            >
            trainLeg?: Maybe<
              Pick<
                TrainLegSegment,
                | 'legId'
                | 'departureDateTime'
                | 'arrivalDateTime'
                | 'rulesAndRestrictions'
                | 'departureStationCode'
                | 'arrivalStationCode'
                | 'travelStops'
                | 'imageUrl'
                | 'name'
                | 'isReserved'
                | 'isRefundable'
                | 'isEticket'
                | 'seatsRequested'
                | 'platform'
                | 'ecoInfo'
                | 'segmentState'
                | 'appliedRailCards'
                | 'routeType'
              > & {
                bookingFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                deliveryFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                deliveryOption?: Maybe<Pick<DeliveryOptions, 'name' | 'code'>>
                fareConditions?: Maybe<{
                  tier: Pick<RailFareTier, 'id' | 'name'>
                  localizedFareConditions: Array<
                    Pick<
                      LocalizedFareConditions,
                      'fareTypeDescription' | 'summaryConditions' | 'language' | 'validityPeriod'
                    > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
                  >
                }>
                travelTime: Pick<TravelTime, 'hours' | 'minutes'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                taxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                totalCost?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                penaltyFees?: Maybe<
                  Array<
                    Maybe<
                      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                        rates?: Maybe<
                          Array<
                            Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                          >
                        >
                        penaltyFee?: Maybe<
                          Array<
                            Maybe<
                              Pick<
                                TrainPenaltyFee,
                                | 'state'
                                | 'isRuleAllowed'
                                | 'effectiveStartDate'
                                | 'effectiveEndDate'
                              > & { amount?: Maybe<Pick<Money, 'currency' | 'amount'>> }
                            >
                          >
                        >
                      }
                    >
                  >
                >
                tier?: Maybe<Pick<TrainTier, 'name' | 'description'>>
                segments: Array<
                  Pick<
                    TrainOrBusSegment,
                    | 'segmentId'
                    | 'imageUrl'
                    | 'marketingServiceName'
                    | 'type'
                    | 'transportNumber'
                    | 'trainId'
                    | 'serviceClassName'
                    | 'carrierCode'
                    | 'carrierName'
                    | 'service'
                    | 'trainData'
                    | 'segmentComment'
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'carrierLogoUrl'
                    | 'transportationMode'
                  > & {
                    allocatedSeats?: Maybe<
                      Array<Pick<ItineraryTrainSegmentSeatInfo, 'seat' | 'carriage'>>
                    >
                    stops?: Maybe<Array<Pick<TrainStop, 'departingTime' | 'stationName'>>>
                    changeDuration?: Maybe<Pick<TravelTime, 'minutes' | 'hours'>>
                    travelTime: Pick<TravelTime, 'minutes' | 'hours'>
                    amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                    arrival: Pick<
                      TrainStationInfo,
                      'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                    > & {
                      address: Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    }
                    departure: Pick<
                      TrainStationInfo,
                      'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                    > & {
                      address: Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    }
                  }
                >
              }
            >
            flightLeg?: Maybe<
              Pick<
                FlightLegSegment,
                | 'departureDateTime'
                | 'segmentState'
                | 'arrivalDateTime'
                | 'legId'
                | 'flightStops'
                | 'stops'
                | 'fareRules'
                | 'fareAttributes'
                | 'isNDCFare'
                | 'isReserved'
                | 'isSameCarrierName'
                | 'isRoundTripLeg'
                | 'isRoundTripCost'
              > & {
                luggageOptions?: Maybe<
                  Array<
                    Pick<LuggageOption, 'code' | 'value'> & {
                      cost?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    }
                  >
                >
                flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                unusedTicketRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                segments: Array<
                  Pick<
                    FlightLegSubSegment,
                    | 'segmentId'
                    | 'name'
                    | 'flightNumber'
                    | 'travelerSeat'
                    | 'travelerId'
                    | 'carrierCode'
                    | 'bookingCode'
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'imageUrl'
                    | 'operatedBy'
                    | 'isSeatAvailable'
                    | 'isSeatSelectable'
                    | 'isDepartAirportDifferentFromConnecting'
                    | 'isInstantPurchaseCarrier'
                    | 'serviceClass'
                    | 'mealService'
                    | 'bookingProvider'
                    | 'originTerminal'
                    | 'destinationTerminal'
                    | 'percentageOnTime'
                    | 'fare'
                  > & {
                    flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                    images: Pick<FlightLegSubSegmentImages, 'square' | 'rectangle'>
                    serviceFee?: Maybe<
                      Pick<ServiceFee, 'id' | 'description'> & {
                        rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    >
                    penaltyFees?: Maybe<
                      Array<
                        Maybe<
                          Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                            penaltyFee?: Maybe<
                              Array<
                                Maybe<
                                  Pick<
                                    TrainPenaltyFee,
                                    | 'state'
                                    | 'isRuleAllowed'
                                    | 'effectiveStartDate'
                                    | 'effectiveEndDate'
                                  > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                                >
                              >
                            >
                            rates?: Maybe<
                              Array<
                                Maybe<{
                                  primary: Pick<Money, 'amount' | 'currency'>
                                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                }>
                              >
                            >
                          }
                        >
                      >
                    >
                    preferredDescription?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    preferenceAttributes?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    baggageFees?: Maybe<
                      Pick<BaggageFees, 'carrierUrl'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<FlightBaggageFee, 'type'> & {
                                rate: {
                                  primary: Pick<Money, 'currency' | 'amount'>
                                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                                }
                                baggageSize?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                  upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                }>
                                baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                                baggageWeight?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                  upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                }>
                              }
                            >
                          >
                        >
                      }
                    >
                    amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                    codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                    plane?: Maybe<Pick<PlaneData, 'type' | 'onTime' | 'code'>>
                    aircraft?: Maybe<Pick<AircraftData, 'type' | 'onTime' | 'code'>>
                    departure: Pick<
                      AirportAddress,
                      'airportCode' | 'airportName' | 'airportCity'
                    > & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'stateCode'
                          | 'postalCode'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'countryCode'
                          | 'locationName'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                    arrival: Pick<AirportAddress, 'airportCode' | 'airportName'> & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'stateCode'
                          | 'postalCode'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'countryCode'
                          | 'locationName'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                  }
                >
              }
            >
          }
        >
      >
      complianceMessage?: Maybe<
        Pick<ComplianceMessage, 'errors' | 'infos' | 'warns' | 'countryRestrictions'>
      >
      continuityMessage?: Maybe<
        Array<
          Pick<ContinuityMessage, 'code' | 'intent' | 'data' | 'followUpMessages' | 'segmentId'>
        >
      >
      overlappingTrips?: Maybe<Array<Pick<OverlappingTrip, 'name' | 'id'>>>
      airPriceChangeInfo?: Maybe<
        Pick<PriceChangeInfo, 'type' | 'reason'> & {
          amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      >
      carPriceChangeInfo?: Maybe<
        Pick<PriceChangeInfo, 'type' | 'reason'> & {
          amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      >
      priceChanges: {
        air?: Maybe<
          Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
        car?: Maybe<
          Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
      }
      hotelLocationInformation?: Maybe<
        Array<
          Pick<
            HotelLocationInformation,
            'id' | 'name' | 'chainCode' | 'email' | 'additionalInformation'
          > & {
            location?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
            phoneNumber?: Maybe<
              Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
            >
            faxNumber?: Maybe<
              Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
            >
          }
        >
      >
      tripLevelPolicy?: Maybe<
        Array<
          Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
            outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
            outOfPolicyComplianceCodes?: Maybe<
              Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
            >
          }
        >
      >
      railCards?: Maybe<
        Array<Pick<RailCard, 'code' | 'groupName' | 'name' | 'vendorCode' | 'vendorName'>>
      >
    }
    itineraryMetadata: Pick<ItineraryMetadata, 'alignAttemptedSegmentTypes'> & {
      hotelMatchDetails?: Maybe<
        Pick<
          HotelMatchDetails,
          'matchFound' | 'isExactMatch' | 'isRoomTypeDifferent' | 'isRoomRateDifferent'
        >
      >
      hotelSearch?: Maybe<
        Pick<HotelSearchParams, 'checkIn' | 'checkOut' | 'hotelKey' | 'hotelName'> & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
        }
      >
      carRentalMatchDetails?: Maybe<Pick<MatchDetails, 'matchFound' | 'isExactMatch'>>
      carRentalSearch?: Maybe<{
        dropOff: Pick<
          CarRentalSearchLocationParams,
          'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
        > & {
          address: Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        pickUp: Pick<
          CarRentalSearchLocationParams,
          'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
        > & {
          address: Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
      }>
      flightSearch?: Maybe<
        Array<
          Pick<
            FlightSearchParams,
            'originAirportCode' | 'destinationAirportCode' | 'departureDate' | 'serviceClass'
          >
        >
      >
      flightMatchDetails?: Maybe<
        Pick<
          FlightMatchDetails,
          | 'matchFound'
          | 'isExactMatch'
          | 'isFlightTimeMatch'
          | 'isFlightTimeWithinThreshold'
          | 'isFlightAirlineMatch'
        >
      >
    }
  }
}

export type CancelRideHailMutationVariables = Exact<{
  input: CancelRideHailInput
}>

export type CancelRideHailMutation = {
  cancelRideHailWithError:
    | ({ __typename: 'CancelRideHailErrorResponse' } & Pick<CancelRideHailErrorResponse, 'reason'>)
    | ({ __typename: 'CancelRideHailResponse' } & Pick<CancelRideHailResponse, 'response'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type CancelTripSegmentMutationVariables = Exact<{
  input: CancelTripSegmentInput
}>

export type CancelTripSegmentMutation = {
  cancelTripSegment: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type CancelTripMutationVariables = Exact<{
  tripId: Scalars['Int']
}>

export type CancelTripMutation = {
  cancelTrip: Pick<BaseResponse, 'success' | 'message' | 'code' | 'errorCodes'>
}

export type ChangeFlightSeatsMutationVariables = Exact<{
  input: ChangeFlightSeatsInput
}>

export type ChangeFlightSeatsMutation = {
  changeFlightSeats: Pick<BaseResponse, 'code' | 'success' | 'message'>
}

export type GetTripQueryVariables = Exact<{
  input: TripInput
}>

export type GetTripQuery = {
  trip: Pick<
    OrderDetails,
    | 'appliedRailCards'
    | 'isNDCTrip'
    | 'cityImageUrl'
    | 'processId'
    | 'tripId'
    | 'type'
    | 'recordLocator'
    | 'creationTime'
    | 'cancellationTime'
    | 'tripName'
    | 'startDate'
    | 'rollUpStartTime'
    | 'rollUpEndTime'
    | 'flightId'
    | 'isMultiCity'
    | 'agencyName'
  > & {
    holdRules?: Maybe<
      Pick<TripHoldRules, 'autoCancellationTime' | 'holdAllowed' | 'reasonsForNotAllowing'>
    >
    supportedActionsV2?: Maybe<
      Pick<
        TripDetailsPostReservationV2,
        | 'allowAddFlight'
        | 'allowAddHotel'
        | 'allowAddCar'
        | 'allowBookAgain'
        | 'allowChange'
        | 'allowCancel'
      > & {
        segmentChangeActions?: Maybe<{
          flightBooking?: Maybe<
            Pick<
              FlightChangeCancelRules,
              | 'allowChangeDestination'
              | 'allowChangeCarrier'
              | 'allowChange'
              | 'allowCancel'
              | 'needCancelAcknowledgement'
            > & {
              flightChangeRules?: Maybe<
                Array<
                  Pick<FlightChangeRules, 'changeType'> & {
                    flightChangeBehavior?: Maybe<
                      Pick<
                        FlightChangeBehavior,
                        | 'allowedSearchStartDate'
                        | 'allowedSearchEndDate'
                        | 'allowChangeCarrier'
                        | 'allowChangeFareType'
                        | 'allowChangeAirports'
                        | 'allowChangeCabin'
                      >
                    >
                  }
                >
              >
              cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
            }
          >
          trainBooking?: Maybe<
            Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
              cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
            }
          >
          hotelBooking?: Maybe<
            Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
              cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
            }
          >
          carBooking?: Maybe<
            Pick<ChangeCancelRules, 'allowChange' | 'allowCancel' | 'needCancelAcknowledgement'> & {
              cancelMessage?: Maybe<Array<Pick<MessageDescription, 'severity' | 'code'>>>
            }
          >
        }>
      }
    >
    agencyInfo?: Maybe<Pick<AgencyInfo, 'agencyName' | 'agencyPhoneNumber' | 'agencyMessage'>>
    tripCost?: Maybe<
      Pick<TripDetailsTripCostSummary, 'allowUnusedTicketOptout'> & {
        prepaid?: Maybe<{
          flight?: Maybe<
            Array<
              Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                base?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                feesAndTaxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                fareTaxInfo?: Maybe<
                  Array<
                    Pick<FareTaxInfo, 'code' | 'name'> & {
                      amount: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    }
                  >
                >
              }
            >
          >
          hotel?: Maybe<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
          carRental?: Maybe<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
          train?: Maybe<
            Array<
              Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                base?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                feesAndTaxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                fareTaxInfo?: Maybe<
                  Array<
                    Pick<FareTaxInfo, 'code' | 'name'> & {
                      amount: {
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    }
                  >
                >
              }
            >
          >
        }>
        reserved?: Maybe<{
          hotel?: Maybe<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
          carRental?: Maybe<
            Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              base?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFee?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              fareTaxInfo?: Maybe<
                Array<
                  Pick<FareTaxInfo, 'code' | 'name'> & {
                    amount: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
            }
          >
        }>
        unusedTicket?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        transactionFees?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        totalServiceFees?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        totalTaxCost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        totalTripBaseCost?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        totalWithUnusedTicket?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
        >
      }
    >
    messages?: Maybe<
      Array<
        Pick<
          DynamicSiteMessage,
          'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
        >
      >
    >
    segments?: Maybe<
      Array<
        Pick<TripDetailsSegment, 'position' | 'type'> & {
          flightLeg?: Maybe<
            Pick<
              TripDetailsFlightLeg,
              | 'legId'
              | 'pnrNumber'
              | 'departureDateTime'
              | 'arrivalDateTime'
              | 'stops'
              | 'flightStops'
              | 'recordLocator'
              | 'isSameCarrierName'
              | 'isRoundTripLeg'
              | 'isRoundTripCost'
              | 'fareRestrictions'
            > & {
              traveler?: Maybe<
                Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
              >
              flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
              distance?: Maybe<Pick<Distance, 'value' | 'units'>>
              rate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              taxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              unusedTicketRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              policy?: Maybe<
                Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
              >
              segments?: Maybe<
                Array<
                  Pick<
                    TripDetailsFlightLegSegment,
                    | 'confirmationNumber'
                    | 'segmentId'
                    | 'originTerminal'
                    | 'destinationTerminal'
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'imageUrl'
                    | 'name'
                    | 'flightNumber'
                    | 'carrierCode'
                    | 'bookingCode'
                    | 'travelerSeat'
                    | 'travelerId'
                    | 'isDepartAirportDifferentFromConnecting'
                    | 'isSeatAvailable'
                    | 'isSeatSelectable'
                    | 'serviceClass'
                    | 'fare'
                    | 'percentageOnTime'
                    | 'operatedBy'
                    | 'mealService'
                    | 'bookingProvider'
                    | 'actualDepartureDate'
                    | 'actualArrivalDate'
                    | 'departureGate'
                    | 'arrivalGate'
                    | 'flightStatus'
                    | 'baggageClaim'
                    | 'trsItemId'
                    | 'specialRequests'
                    | 'status'
                  > & {
                    checkIn: Pick<FlightSegmentCheckIn, 'airlineUrl' | 'checkInWindowInHours'>
                    departure?: Maybe<
                      Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                      }
                    >
                    arrival?: Maybe<
                      Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                        address?: Maybe<
                          Pick<
                            Address,
                            | 'airportCode'
                            | 'locationName'
                            | 'street1'
                            | 'street2'
                            | 'city'
                            | 'stateCode'
                            | 'postalCode'
                            | 'countryCode'
                          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        >
                      }
                    >
                    images: Pick<TripDetailsFlightLegSegmentImages, 'square' | 'rectangle'>
                    flightTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                    distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                    baggageFees?: Maybe<
                      Pick<BaggageFees, 'carrierUrl'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<FlightBaggageFee, 'type'> & {
                                rate: {
                                  primary: Pick<Money, 'amount' | 'currency'>
                                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                }
                                baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                                baggageWeight?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                  upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                }>
                                baggageSize?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                  upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                }>
                              }
                            >
                          >
                        >
                      }
                    >
                    amenities?: Maybe<Pick<FlightLegSegmentAmenities, 'codes' | 'values'>>
                    preferredDescription?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    preferenceAttributes?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    penaltyFees?: Maybe<
                      Array<
                        Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                          rates?: Maybe<
                            Array<
                              Maybe<{
                                primary: Pick<Money, 'amount' | 'currency'>
                                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                              }>
                            >
                          >
                          penaltyFee?: Maybe<
                            Array<
                              Maybe<
                                Pick<
                                  TrainPenaltyFee,
                                  | 'state'
                                  | 'isRuleAllowed'
                                  | 'effectiveStartDate'
                                  | 'effectiveEndDate'
                                > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                              >
                            >
                          >
                        }
                      >
                    >
                    serviceFee?: Maybe<
                      Pick<SegmentServiceFee, 'id' | 'description'> & {
                        rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    >
                    codeshareCarrier?: Maybe<Pick<CodeshareCarrier, 'name' | 'code'>>
                    plane?: Maybe<Pick<Plane, 'type' | 'code' | 'onType'>>
                    membership?: Maybe<
                      Array<Pick<CheckoutInfoAirMembershipData, 'id' | 'number' | 'carrierCode'>>
                    >
                  }
                >
              >
              totalWithUnusedTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }
          >
          hotel?: Maybe<
            Pick<
              TripDetailsHotelSegment,
              | 'checkIn'
              | 'checkOut'
              | 'numberOfNights'
              | 'confirmationNumber'
              | 'imageUrl'
              | 'name'
              | 'id'
              | 'hotelId'
              | 'rateQualifier'
              | 'isCancellable'
              | 'rulesAndRestrictions'
              | 'cancellationPolicyDescription'
              | 'cancellationPolicy'
              | 'phone'
              | 'roomType'
              | 'roomDescription'
              | 'recordLocator'
              | 'starRating'
              | 'source'
              | 'isReserved'
              | 'hasDeposit'
              | 'trsItemId'
              | 'specialRequests'
              | 'status'
            > & {
              averageRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              dailyRates?: Maybe<
                Array<
                  Pick<DailyRate, 'date'> & {
                    rate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
              >
              feesAndTaxes?: Maybe<Pick<Money, 'amount' | 'currency'>>
              totalCost?: Maybe<Pick<Money, 'amount' | 'currency'>>
              preferredDescription?: Maybe<
                Pick<
                  Preference,
                  | 'id'
                  | 'code'
                  | 'highlight'
                  | 'ownerType'
                  | 'ownerName'
                  | 'tooltipText'
                  | 'shouldShowDefaultPreference'
                > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
              >
              policy?: Maybe<
                Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
              >
              address?: Maybe<
                Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              >
              numberOfGuests?: Maybe<Pick<NumberOfGuests, 'adults'>>
              traveler?: Maybe<
                Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
              >
              photos?: Maybe<Array<Pick<Photo, 'index' | 'size' | 'url'>>>
              membership?: Maybe<
                Array<Pick<TripDetailsHotelMembershipData, 'number' | 'vendorName'>>
              >
            }
          >
          carRental?: Maybe<
            Pick<
              TripDetailsCarRentalSegment,
              | 'carId'
              | 'carClass'
              | 'carType'
              | 'fuelType'
              | 'carImageUrl'
              | 'carTransmission'
              | 'pickUpTime'
              | 'dropOffTime'
              | 'isCarAirConditioned'
              | 'contractName'
              | 'carCapacity'
              | 'carLuggage'
              | 'isReserved'
              | 'confirmationNumber'
              | 'isUnlimitedMileage'
              | 'recordLocator'
              | 'daysCount'
              | 'specialRequests'
              | 'status'
            > & {
              vendor?: Maybe<Pick<Vendor, 'name' | 'imageUrl' | 'code'>>
              safetyCheck?: Maybe<Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>>
              policy?: Maybe<
                Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
              >
              pickUpLocation?: Maybe<
                Pick<
                  TripDetailsCarRentalInteraction,
                  'date' | 'isDeliveryCollection' | 'isAirportLocation'
                > & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
                }
              >
              dropOffLocation?: Maybe<
                Pick<
                  TripDetailsCarRentalInteraction,
                  'date' | 'isDeliveryCollection' | 'isAirportLocation'
                > & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  phone?: Maybe<Pick<CarRentalPhone, 'number' | 'type'>>
                }
              >
              traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
              dailyRate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalBaseCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              taxesAndFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              serviceFee?: Maybe<
                Pick<ServiceFee, 'id' | 'description'> & {
                  rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              >
              preferredDescription?: Maybe<
                Pick<
                  Preference,
                  | 'id'
                  | 'code'
                  | 'highlight'
                  | 'ownerType'
                  | 'ownerName'
                  | 'tooltipText'
                  | 'shouldShowDefaultPreference'
                > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
              >
              membership?: Maybe<Array<Pick<CheckoutInfoCarMembershipData, 'number'>>>
              extraCharge?: Maybe<{
                perDayRate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                perDayCharge?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                perHourCharge?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }>
            }
          >
          trainLeg?: Maybe<
            Pick<
              TripDetailsTrainLeg,
              | 'id'
              | 'departureDateTime'
              | 'arrivalDateTime'
              | 'departureStationCode'
              | 'arrivalStationCode'
              | 'routeType'
              | 'platform'
              | 'travelStops'
              | 'transactionId'
              | 'recordLocator'
              | 'collectionReferences'
            > & {
              taxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              traveler?: Maybe<
                Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
              >
              travelTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
              totalJourneyTime?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
              rate?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              segments?: Maybe<
                Array<
                  Pick<
                    TripDetailsTrainSegment,
                    | 'id'
                    | 'arrivalDate'
                    | 'arrivalStationCode'
                    | 'confirmationNumber'
                    | 'serviceClassName'
                    | 'trainNumber'
                    | 'trainId'
                    | 'type'
                    | 'carrierName'
                    | 'carrierImage'
                    | 'departureDate'
                    | 'departureStationCode'
                    | 'name'
                    | 'status'
                  > & {
                    segmentAmenities: Array<Pick<TrainSegmentAmenities, 'code' | 'value'>>
                    changeDuration?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                    duration?: Maybe<Pick<Duration, 'hours' | 'minutes'>>
                    stations?: Maybe<{
                      arrival?: Maybe<
                        Pick<
                          TripDetailsTrainStation,
                          | 'additionalInformation'
                          | 'isBusStop'
                          | 'ticketMachineAvailable'
                          | 'phoneNumber'
                          | 'stationCode'
                          | 'stationName'
                          | 'countryName'
                          | 'ticketPickupInformation'
                        > & {
                          location?: Maybe<
                            Pick<
                              Address,
                              | 'airportCode'
                              | 'locationName'
                              | 'street1'
                              | 'street2'
                              | 'city'
                              | 'stateCode'
                              | 'postalCode'
                              | 'countryCode'
                            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                          >
                          ticketOfficeSchedule?: Maybe<
                            Array<
                              Pick<
                                TripDetailsTrainStationSchedule,
                                | 'closeTime'
                                | 'endingOnDayOfWeek'
                                | 'openTime'
                                | 'startingOnDayOfWeek'
                              >
                            >
                          >
                        }
                      >
                      departure?: Maybe<
                        Pick<
                          TripDetailsTrainStation,
                          | 'additionalInformation'
                          | 'isBusStop'
                          | 'ticketMachineAvailable'
                          | 'phoneNumber'
                          | 'stationCode'
                          | 'stationName'
                          | 'countryName'
                          | 'ticketPickupInformation'
                        > & {
                          location?: Maybe<
                            Pick<
                              Address,
                              | 'airportCode'
                              | 'locationName'
                              | 'street1'
                              | 'street2'
                              | 'city'
                              | 'stateCode'
                              | 'postalCode'
                              | 'countryCode'
                            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                          >
                          ticketOfficeSchedule?: Maybe<
                            Array<
                              Pick<
                                TripDetailsTrainStationSchedule,
                                | 'closeTime'
                                | 'endingOnDayOfWeek'
                                | 'openTime'
                                | 'startingOnDayOfWeek'
                              >
                            >
                          >
                        }
                      >
                    }>
                    allocatedSeat?: Maybe<Array<Pick<TrainSegmentSeatInfo, 'carriage' | 'seat'>>>
                  }
                >
              >
              selectedDeliveryOption?: Maybe<Pick<TripDeliveryOption, 'code' | 'name'>>
              fareConditions?: Maybe<
                Array<
                  Maybe<{
                    tier: Pick<RailFareTier, 'id' | 'name'>
                    localizedFareConditions: Array<
                      Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
                        conditions: Array<Pick<Conditions, 'name' | 'description'>>
                      }
                    >
                  }>
                >
              >
              permittedOriginStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
              permittedDestinationStations: Array<
                Pick<RailFarePermittedStation, 'code' | 'description'>
              >
            }
          >
          carService?: Maybe<
            Pick<
              TripDetailsCarServiceSegment,
              'pickUpTime' | 'confirmationNumber' | 'recordLocator'
            > & {
              car?: Maybe<
                Pick<TripDetailsCarServiceCar, 'class' | 'type' | 'capacity' | 'imageUrl'>
              >
              detailsRules?: Maybe<
                Pick<
                  TripDetailsCarServiceDetailsRules,
                  'cancelPolicy' | 'chargeDisclaimer' | 'chargeQualifier' | 'generalMessage'
                >
              >
              policy?: Maybe<
                Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
              >
              traveler: Pick<TripDetailsTraveler, 'firstName' | 'lastName' | 'middleName' | 'email'>
              price: Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                taxes: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                base?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                timeBase?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                offHours?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                airportFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                meetAndGreet?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                surfaceTransport?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                parking?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                fuelSurcharge?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                accountDiscount?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                rateQuote?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                stops?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                tolls?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                extraService?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                distanceBased?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                gratuity?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                accountService?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
              serviceCompany?: Maybe<
                Pick<TripDetailsCarServiceCompanyService, 'name' | 'description' | 'logo'>
              >
              stops?: Maybe<
                Array<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              >
              pickUp?: Maybe<
                Pick<TripDetailsCarServicePickUp, 'time' | 'pickupInstructions'> & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  location?: Maybe<{ geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }>
                }
              >
              dropOff?: Maybe<
                Pick<TripDetailsCarServiceDropOff, 'time' | 'dropOffInstructions'> & {
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                  location?: Maybe<
                    Pick<
                      TripDetailsCarServiceDropOffLocation,
                      'asDirected' | 'requestedDuration' | 'requestedHours' | 'asDirectedMultiDay'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
              >
            }
          >
          rideHail?: Maybe<
            Pick<
              TripDetailsRideHailSegment,
              | 'bookingId'
              | 'description'
              | 'dropOffAddress'
              | 'dropOffTime'
              | 'pickUpAddress'
              | 'pickUpTime'
              | 'productName'
              | 'status'
            > & {
              price?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              estimatedPriceRange?: Maybe<{
                primary: Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>
                secondary?: Maybe<Pick<MoneyRange, 'amountMax' | 'amountMin' | 'currency'>>
              }>
            }
          >
        }
      >
    >
    costSummary: {
      carService?: Maybe<
        Pick<CarServiceCostSummary, 'isPaid'> & {
          cost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          tax?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          carServiceCharges?: Maybe<{
            accountDiscount?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            accountServiceCharge?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            airPortFees?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            extraServiceCharge?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            fuelSurcharge?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            gratuity?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            offHoursCharge?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            parkingAmount?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            stopCharge?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }>
        }
      >
      train?: Maybe<{
        trainList: Array<
          Pick<TrainCostSummaryItem, 'origin' | 'destination' | 'isPaid'> & {
            cost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            tax?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }
        >
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        fare?: Maybe<{
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          base: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          transactionFee: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          serviceFee: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          paymentFee: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          tax: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }>
      }>
      flight?: Maybe<{
        flightList: Array<
          Pick<FlightCostSummaryItem, 'origin' | 'destination' | 'isSameAirline' | 'isPaid'> & {
            cost?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            tax?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            unusedTicketRate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
        total?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
      }>
      hotel?: Maybe<
        Pick<HotelCostSummary, 'name' | 'itemCount' | 'isPaid'> & {
          cost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          tax?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      carRental?: Maybe<
        Pick<CarRentalCostSummary, 'vendorName' | 'itemCount' | 'isPaid'> & {
          cost?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          tax?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      totalCost?: Maybe<{
        primary: Pick<Money, 'amount' | 'currency'>
        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }>
    }
    airTickets?: Maybe<
      Array<
        Pick<
          AirTicket,
          | 'ticketNumber'
          | 'travelerName'
          | 'issuingAgent'
          | 'iataNumber'
          | 'formOfPayment'
          | 'endorsement'
          | 'fareLinear'
          | 'issuingAirline'
          | 'isTicketRefundable'
          | 'isPenaltyValidated'
          | 'isPenaltyPayableFromResidual'
          | 'isExchangeForRefundableAllowed'
        > & {
          ticketSegmentInfo?: Maybe<
            Array<
              Pick<
                TicketSegment,
                | 'departTime'
                | 'carrierCode'
                | 'flightNumber'
                | 'origin'
                | 'destination'
                | 'serviceClass'
                | 'fareBasis'
                | 'status'
              >
            >
          >
          fareTaxInfo?: Maybe<
            Array<
              Pick<FareTaxItemDto, 'taxCode' | 'taxLabel'> & {
                taxAmount?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
            >
          >
          baseFare?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          totalFare?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          penalty?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
    >
    billingInfo?: Maybe<
      Array<
        Pick<BillingInfo, 'id'> & {
          payment?: Maybe<{
            creditCard?: Maybe<Pick<CreditPaymentCard, 'label'>>
            siteCard?: Maybe<Pick<SitePaymentCard, 'label'>>
          }>
        }
      >
    >
    meetingInfo?: Maybe<
      Pick<MeetingInfo, 'name' | 'location'> & { dates: Pick<DateRange, 'startDate' | 'endDate'> }
    >
  }
}

export type GetSearchInitiateInfoQueryVariables = Exact<{
  input?: InputMaybe<SearchInitiateInfoInput>
}>

export type GetSearchInitiateInfoQuery = {
  searchInitiateInfo: {
    customFieldsConfiguration?: Maybe<{
      customFieldCollection: Array<
        Pick<
          CustomFieldCollectionItem,
          | 'collectionId'
          | 'collectionLabel'
          | 'collectionDescription'
          | 'isNotAppliedServerSideJavascript'
        > & {
          customFields: Array<
            Pick<
              CustomField,
              | 'type'
              | 'optional'
              | 'name'
              | 'label'
              | 'defaultValue'
              | 'isHasClientSideJavascriptValidator'
              | 'isNotAppliedServerSideJavascriptInitializer'
              | 'tooltip'
            > & {
              checkBoxDisplay?: Maybe<
                Pick<CustomFieldCheckBoxDisplay, 'isChecked' | 'isCheckedValueRequired'>
              >
              textDisplay?: Maybe<
                Pick<
                  CustomFieldTextDisplay,
                  'minLength' | 'maxLength' | 'numberOfLines' | 'characterWidth'
                >
              >
              enumerationDisplay?: Maybe<
                Pick<CustomFieldEnumerationDisplay, 'isRadioButtonsStyle'> & {
                  option?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>>
                }
              >
              numberDisplay?: Maybe<
                Pick<CustomFieldNumberDisplay, 'decimalPlaces' | 'minValue' | 'maxValue'>
              >
              dateTimeDisplay?: Maybe<
                Pick<
                  CustomFieldDateTimeDisplay,
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'isAllowedPastDateTime'
                >
              >
            }
          >
        }
      >
    }>
  }
}

export type GetRailETicketsQueryVariables = Exact<{
  input: RailETicketInput
}>

export type GetRailETicketsQuery = { getRailETickets: Pick<RailETicketResult, 'tickets'> }

export type GetRailQueryVariables = Exact<{
  input: RailInput
}>

export type GetRailQuery = {
  rail: Pick<
    RailResult,
    'totalCount' | 'pageStart' | 'pageEnd' | 'maxResultsSize' | 'isCustomTimeReplaced'
  > & {
    railLegs: Array<
      Pick<
        SearchRailLeg,
        | 'legId'
        | 'labels'
        | 'daysInBetween'
        | 'railStops'
        | 'origin'
        | 'originCode'
        | 'destination'
        | 'destinationCode'
        | 'departureDate'
        | 'arrivalDate'
        | 'isSelectable'
        | 'ecoInfo'
        | 'platform'
        | 'isUnusedTicketApplicable'
      > & {
        railTime: Pick<TravelTime, 'hours' | 'minutes'>
        policy: Pick<Policy, 'isInPolicy'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
        }
        rate?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        distance?: Maybe<Pick<Distance, 'value' | 'units'>>
        segments: Array<
          Pick<
            SearchRailLegSubSegment,
            | 'id'
            | 'trainIdentifier'
            | 'departureDate'
            | 'arrivalDate'
            | 'percentageOnTime'
            | 'trainNumber'
            | 'trainId'
            | 'isSeatMapAvailable'
            | 'isSeatSelectable'
            | 'preferredBadgeText'
            | 'isPreferredAny'
            | 'transportationMode'
          > & {
            railTime: Pick<TravelTime, 'hours' | 'minutes'>
            origin: Pick<RailLegSubSegmentStation, 'city' | 'terminal' | 'code' | 'name'>
            destination: Pick<RailLegSubSegmentStation, 'city' | 'terminal' | 'code' | 'name'>
            carrier?: Maybe<Pick<RailCarrier, 'id' | 'name' | 'imageUrl' | 'logoImageUrl' | 'code'>>
            amenities?: Maybe<Array<Pick<RailAmenity, 'id' | 'name'>>>
            preference?: Maybe<
              Pick<Preference, 'code' | 'highlight' | 'ownerType' | 'ownerName' | 'tooltipText'> & {
                customBadge: Pick<CustomBadge, 'name' | 'color'>
              }
            >
            train?: Maybe<Pick<RailLegSegmentTrain, 'code' | 'name'>>
            codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
            stops?: Maybe<Array<Pick<TrainStop, 'departingTime' | 'stationName' | 'time'>>>
            changeDuration?: Maybe<Pick<TravelTime, 'minutes' | 'hours'>>
          }
        >
        fares: Array<
          Pick<
            RailFare,
            | 'tripId'
            | 'restriction'
            | 'isRefundable'
            | 'isWebFare'
            | 'showOutOfPolicy'
            | 'isSelectable'
            | 'appliedRailCards'
          > & {
            tier: Pick<RailFareTier, 'id' | 'name'>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            segments?: Maybe<
              Array<
                Pick<
                  RailFareSegment,
                  'segmentId' | 'serviceClass' | 'bookingCode' | 'basisCode' | 'displayServiceClass'
                >
              >
            >
            rulesAndRestrictions?: Maybe<
              Array<
                Pick<RailFareRulesAndRestrictions, 'id' | 'type' | 'rulesAndRestriction'> & {
                  penaltyFee?: Maybe<
                    Array<
                      Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    >
                  >
                }
              >
            >
            permittedOriginStations: Array<Pick<RailFarePermittedStation, 'code' | 'description'>>
            permittedDestinationStations: Array<
              Pick<RailFarePermittedStation, 'code' | 'description'>
            >
          }
        >
        connectionInfo: Array<Pick<RailLegConnectionInfo, 'segmentId' | 'connectionTimeMinutes'>>
        totalTime: Pick<TravelTime, 'hours' | 'minutes'>
      }
    >
    messages?: Maybe<
      Array<
        Pick<
          DynamicSiteMessage,
          'header' | 'text' | 'mobileText' | 'isDialog' | 'hasDesktopBannerImage'
        >
      >
    >
    filters: {
      trainCompany: Array<Pick<TrainCompanyFilter, 'id' | 'value' | 'name' | 'imageUrl'>>
      station: Array<
        Pick<StationFilter, 'id'> & {
          origin: Pick<StationFilterStation, 'code' | 'name' | 'city'>
          destination: Pick<StationFilterStation, 'code' | 'name' | 'city'>
        }
      >
      stops: Array<Pick<StopsFilter, 'id' | 'value'>>
      fareTier?: Maybe<Array<Pick<FareTierFilter, 'id' | 'value' | 'displayName'>>>
      originTimeRange: Pick<TimeFilter, 'start' | 'end'>
      destinationTime: Pick<TimeFilter, 'start' | 'end'>
    }
    fareConditions: Array<{
      tier: Pick<RailFareTier, 'id' | 'name'>
      localizedFareConditions: Array<
        Pick<LocalizedFareConditions, 'fareTypeDescription' | 'summaryConditions'> & {
          conditions: Array<Pick<Conditions, 'name' | 'description'>>
        }
      >
    }>
    railPolicies: Pick<RailPolicies, 'isOutOfPolicyFaresBookable' | 'isRailFirstClassAllowed'>
  }
}

export type RecentCarRentalSearchesWithoutDatesQueryVariables = Exact<{ [key: string]: never }>

export type RecentCarRentalSearchesWithoutDatesQuery = {
  recentCarRentalSearchesWithoutDates: {
    items: Array<
      Pick<RecentCarRentalSearchesWithoutDatesResultItem, 'id'> & {
        carRentalSearchParams: {
          pickUp: {
            location: Pick<RecentSearchesLocation, 'name' | 'airportCode' | 'countryCode'> & {
              geocode: Pick<Geocode, 'lat' | 'long'>
            }
          }
          dropOff: {
            location: Pick<RecentSearchesLocation, 'name' | 'airportCode' | 'countryCode'> & {
              geocode: Pick<Geocode, 'lat' | 'long'>
            }
          }
        }
      }
    >
  }
}

export type GetRecentCarRentalSearchesQueryVariables = Exact<{
  input: RecentCarRentalSearchesInput
}>

export type GetRecentCarRentalSearchesQuery = {
  recentCarRentalSearches: Pick<RecentCarRentalSearchesResult, 'isOk' | 'errorMessages'> & {
    items: Array<
      Pick<RecentCarRentalSearchesResultItem, 'id'> & {
        carRentalSearchParams: {
          pickUp: Pick<RecentCarRentalSearchesInteractionItem, 'date' | 'time'> & {
            location: Pick<RecentSearchesLocation, 'airportCode' | 'name'> & {
              geocode: Pick<Geocode, 'lat' | 'long'>
            }
          }
          dropOff: Pick<RecentCarRentalSearchesInteractionItem, 'date' | 'time'> & {
            location: Pick<RecentSearchesLocation, 'airportCode' | 'name'> & {
              geocode: Pick<Geocode, 'lat' | 'long'>
            }
          }
        }
      }
    >
  }
}

export type RecentFlightSearchesWithoutDatesQueryVariables = Exact<{ [key: string]: never }>

export type RecentFlightSearchesWithoutDatesQuery = {
  recentFlightSearchesWithoutDates: {
    items: Array<
      Pick<RecentFlightSearchesWithoutDatesResultItem, 'id'> & {
        flightSearchParams: Pick<
          RecentFlightSearchesWithoutDatesResultItemFlightSearchParams,
          'flightType'
        > & {
          segments: Array<
            Pick<RecentFlightSearchesWithoutDatesResultItemFlightSearchSegment, 'cabinClass'> & {
              destination: Pick<RecentSearchesLocation, 'airportCode' | 'countryCode' | 'name'> & {
                geocode: Pick<Geocode, 'lat' | 'long'>
              }
              origin: Pick<RecentSearchesLocation, 'airportCode' | 'countryCode' | 'name'> & {
                geocode: Pick<Geocode, 'lat' | 'long'>
              }
              searchTimeRange: Pick<
                RecentSearchesResultSearchTimeRange,
                'type' | 'start' | 'end' | 'customHours'
              >
            }
          >
        }
      }
    >
  }
}

export type GetRecentFlightSearchesQueryVariables = Exact<{
  input: RecentFlightSearchesInput
}>

export type GetRecentFlightSearchesQuery = {
  recentFlightSearches: Pick<RecentFlightSearchesResult, 'isOk' | 'errorMessages'> & {
    items: Array<
      Pick<RecentFlightSearchesResultItem, 'id'> & {
        flightSearchParams: Pick<RecentFlightSearchesResultItemFlightSearchParams, 'flightType'> & {
          segments: Array<
            Pick<
              RecentFlightSearchesResultItemFlightSearchSegment,
              'cabinClass' | 'departureDate'
            > & {
              destination: Pick<RecentSearchesLocation, 'airportCode' | 'countryCode' | 'name'> & {
                geocode: Pick<Geocode, 'lat' | 'long'>
              }
              origin: Pick<RecentSearchesLocation, 'airportCode' | 'countryCode' | 'name'> & {
                geocode: Pick<Geocode, 'lat' | 'long'>
              }
              searchTimeRange: Pick<
                RecentSearchesResultSearchTimeRange,
                'type' | 'end' | 'start' | 'customHours'
              >
            }
          >
        }
      }
    >
  }
}

export type RecentHotelSearchesWithoutDatesQueryVariables = Exact<{ [key: string]: never }>

export type RecentHotelSearchesWithoutDatesQuery = {
  recentHotelSearchesWithoutDates: Pick<
    RecentHotelSearchesWithoutDatesResult,
    'isOk' | 'errorMessages'
  > & {
    items: Array<
      Pick<RecentHotelSearchesWithoutDatesResultItem, 'id'> & {
        hotelSearchParams: Pick<
          RecentHotelSearchesWithoutDatesResultItemHotelSearchParams,
          'searchRadius'
        > & {
          location: Pick<RecentHotelSearchesResultLocation, 'name'> & {
            geocode: Pick<Geocode, 'lat' | 'long'>
          }
        }
      }
    >
  }
}

export type GetRecentHotelSearchesQueryVariables = Exact<{
  input: RecentHotelSearchesInput
}>

export type GetRecentHotelSearchesQuery = {
  recentHotelSearches: Pick<RecentHotelSearchesResult, 'isOk' | 'errorMessages'> & {
    items: Array<
      Pick<RecentHotelSearchesResultItem, 'id'> & {
        hotelSearchParams: Pick<
          RecentHotelSearchesResultItemHotelSearchParams,
          'checkIn' | 'checkOut' | 'searchRadius'
        > & {
          location: Pick<RecentHotelSearchesResultLocation, 'name'> & {
            geocode: Pick<Geocode, 'lat' | 'long'>
          }
        }
      }
    >
  }
}

export type GetRecentRailSearchesQueryVariables = Exact<{
  input: RecentRailSearchesInput
}>

export type GetRecentRailSearchesQuery = {
  recentRailSearches: Pick<RecentRailSearchesResult, 'isOk' | 'errorMessages'> & {
    items: Array<
      Pick<RecentRailSearchesResultItem, 'id'> & {
        railSearchParams: Pick<RecentRailSearchesResultItemRailSearchParams, 'railType'> & {
          railCards: Array<
            Pick<
              RecentRailSearchesResultItemRailSearchCard,
              'code' | 'groupName' | 'name' | 'vendorCode' | 'vendorName'
            >
          >
          segments: Array<
            Pick<RecentRailSearchesResultItemRailSearchSegment, 'departureDate'> & {
              origin: Pick<RecentRailSearchesLocation, 'locationCode' | 'name'> & {
                geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
              }
              destination: Pick<RecentRailSearchesLocation, 'locationCode' | 'name'> & {
                geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
              }
              searchTimeRange: Pick<
                RecentSearchesResultSearchTimeRange,
                'end' | 'start' | 'type' | 'customHours'
              >
            }
          >
        }
      }
    >
  }
}

export type SubmitNewCarRentalSearchMutationVariables = Exact<{
  input: SubmitNewCarRentalSearchInput
}>

export type SubmitNewCarRentalSearchMutation = {
  submitNewCarRentalSearch: Pick<SubmitNewCarRentalSearchResult, 'isOk' | 'errorMessages'>
}

export type SubmitNewFlightSearchMutationVariables = Exact<{
  input: SubmitNewFlightSearchInput
}>

export type SubmitNewFlightSearchMutation = {
  submitNewFlightSearch: Pick<SubmitNewFlightSearchResult, 'isOk' | 'errorMessages'>
}

export type SubmitNewHotelSearchMutationVariables = Exact<{
  input: SubmitNewHotelSearchInput
}>

export type SubmitNewHotelSearchMutation = {
  submitNewHotelSearch: Pick<SubmitNewHotelSearchResult, 'isOk' | 'errorMessages'>
}

export type SubmitNewRailSearchMutationVariables = Exact<{
  input: SubmitNewRailSearchInput
}>

export type SubmitNewRailSearchMutation = {
  submitNewRailSearch: Pick<SubmitNewRailSearchResult, 'isOk' | 'errorMessages'>
}

export type AddModifyToCartMutationVariables = Exact<{
  input: AddModifyToCartInput
}>

export type AddModifyToCartMutation = {
  addModifyToCart:
    | ({ __typename: 'AddModifyToCartResponse' } & Pick<AddModifyToCartResponse, 'intentId'>)
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
}

export type AddToCartMutationVariables = Exact<{
  input: AddToCartInput
}>

export type AddToCartMutation = {
  addToCart:
    | ({ __typename: 'AddToCartResponse' } & Pick<AddToCartResponse, 'intentId'>)
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | { __typename: 'GenericCartErrorResponse' }
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type CartCostAllocationQueryVariables = Exact<{
  cartId: Scalars['String']
}>

export type CartCostAllocationQuery = {
  cartCostAllocation:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'GetCostAllocationResponse' } & Pick<GetCostAllocationResponse, 'cartId'> & {
          costAllocations: Pick<
            CartCostAllocation,
            'segmentId' | 'allocationId' | 'costSegmentSplit'
          >
        })
}

export type GetCartQueryVariables = Exact<{
  input: GetCartInput
}>

export type GetCartQuery = {
  cart:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'GetCartResponse' } & Pick<
        GetCartResponse,
        'cartId' | 'cartName' | 'isCartNameEditable'
      > & {
          intents: Array<
            Pick<Intent, 'state' | 'domain' | 'id'> & {
              domainObject:
                | ({ __typename: 'CarRental' } & Pick<
                    CarRental,
                    'dropOffDate' | 'dropOffTime' | 'key' | 'pickupDate' | 'pickupTime'
                  > & {
                      dropOffLocation: Pick<
                        CarRentalLocationDetails,
                        'isAirport' | 'locationId' | 'phone'
                      > & {
                        locationAddress: Pick<
                          Address,
                          | 'airportCode'
                          | 'locationName'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'stateCode'
                          | 'postalCode'
                          | 'countryCode'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        openingHours: Array<
                          Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
                            times: Array<
                              Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>
                            >
                          }
                        >
                      }
                      pickupLocation: Pick<
                        CarRentalLocationDetails,
                        'isAirport' | 'locationId' | 'phone'
                      > & {
                        locationAddress: Pick<
                          Address,
                          | 'airportCode'
                          | 'locationName'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'stateCode'
                          | 'postalCode'
                          | 'countryCode'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                        openingHours: Array<
                          Pick<CarRentalDailyOpeningHours, 'dayOfWeek'> & {
                            times: Array<
                              Pick<CarRentalOpeningHoursTimeInterval, 'closingTime' | 'openingTime'>
                            >
                          }
                        >
                      }
                      rate: Pick<
                        CarRentalRate,
                        'daysCharged' | 'distanceUnlimited' | 'rateCode'
                      > & {
                        dailyRate: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        extraDayCharge?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        extraHourCharge?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        totalCost: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        totalRate: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        totalTaxes: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                      }
                      vendor: Pick<Vendor, 'code' | 'imageUrl' | 'name'>
                      vehicle: Pick<
                        CarRentalVehicle,
                        | 'baggageCapacity'
                        | 'category'
                        | 'fuelType'
                        | 'isAirCondition'
                        | 'modelName'
                        | 'passengerCapacity'
                        | 'transmissionType'
                        | 'vehicleClass'
                        | 'imageUrl'
                      >
                      termsAndConditions: Array<Pick<CarRules, 'header' | 'value'>>
                      safetyCheck: Pick<CarRentalSafetyCheck, 'badgeUrl' | 'messageCode' | 'url'>
                    })
                | ({ __typename: 'FlightTripResult' } & {
                    flightLegs: Array<
                      Pick<
                        FlightLegResult,
                        | 'legId'
                        | 'stops'
                        | 'departureDateTime'
                        | 'arrivalDateTime'
                        | 'fareRules'
                        | 'fareAttributes'
                        | 'isReserved'
                        | 'isRoundTripCost'
                        | 'isRoundTripLeg'
                        | 'isSameCarrierName'
                      > & {
                        flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                        totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                        luggageOptions?: Maybe<Array<Pick<LuggageOption, 'code' | 'value'>>>
                        policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                          outOfPolicyData?: Maybe<
                            Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                          >
                        }
                        rate: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        baseRate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        feesAndTaxesRate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        unusedTicketRate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        totalCostRate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
                        segments: Array<
                          Pick<
                            FlightLegSegmentResult,
                            | 'segmentId'
                            | 'arrivalDate'
                            | 'departureDate'
                            | 'name'
                            | 'flightNumber'
                            | 'carrierCode'
                            | 'bookingCode'
                            | 'fare'
                            | 'mealService'
                            | 'bookingProvider'
                            | 'operatedBy'
                            | 'originTerminal'
                            | 'destinationTerminal'
                            | 'percentageOnTime'
                            | 'isDepartAirportDifferentFromConnecting'
                            | 'serviceClass'
                          > & {
                            flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                            departure: Pick<
                              AirportAddress,
                              'airportCode' | 'airportName' | 'airportCity'
                            > & {
                              address?: Maybe<
                                Pick<
                                  Address,
                                  | 'airportCode'
                                  | 'locationName'
                                  | 'street1'
                                  | 'street2'
                                  | 'city'
                                  | 'stateCode'
                                  | 'postalCode'
                                  | 'countryCode'
                                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                              >
                            }
                            arrival: Pick<
                              AirportAddress,
                              'airportCode' | 'airportName' | 'airportCity'
                            > & {
                              address?: Maybe<
                                Pick<
                                  Address,
                                  | 'airportCode'
                                  | 'locationName'
                                  | 'street1'
                                  | 'street2'
                                  | 'city'
                                  | 'stateCode'
                                  | 'postalCode'
                                  | 'countryCode'
                                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                              >
                            }
                            baggageFees?: Maybe<
                              Pick<BaggageFees, 'carrierUrl'> & {
                                items?: Maybe<
                                  Array<
                                    Maybe<
                                      Pick<FlightBaggageFee, 'type'> & {
                                        rate: {
                                          primary: Pick<Money, 'amount' | 'currency'>
                                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                        }
                                        baggageCount?: Maybe<
                                          Pick<BaggageAllowanceCount, 'lower' | 'upper'>
                                        >
                                        baggageWeight?: Maybe<{
                                          lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                          upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                        }>
                                        baggageSize?: Maybe<{
                                          lower: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                          upper: Pick<BaggageAllowanceValue, 'value' | 'unit'>
                                        }>
                                      }
                                    >
                                  >
                                >
                              }
                            >
                            amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                            aircraft?: Maybe<Pick<AircraftData, 'type' | 'code' | 'onTime'>>
                            preferredDescription?: Maybe<
                              Pick<
                                Preference,
                                | 'id'
                                | 'code'
                                | 'highlight'
                                | 'ownerType'
                                | 'ownerName'
                                | 'tooltipText'
                                | 'shouldShowDefaultPreference'
                              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                            >
                            penaltyFees?: Maybe<
                              Array<
                                Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                                  rates?: Maybe<
                                    Array<
                                      Maybe<{
                                        primary: Pick<Money, 'amount' | 'currency'>
                                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                      }>
                                    >
                                  >
                                  penaltyFee?: Maybe<
                                    Array<
                                      Maybe<
                                        Pick<
                                          TrainPenaltyFee,
                                          | 'state'
                                          | 'isRuleAllowed'
                                          | 'effectiveStartDate'
                                          | 'effectiveEndDate'
                                        > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                                      >
                                    >
                                  >
                                }
                              >
                            >
                            serviceFee?: Maybe<
                              Pick<ServiceFee, 'description' | 'id'> & {
                                rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                              }
                            >
                            codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                            preferenceAttributes?: Maybe<
                              Pick<
                                Preference,
                                | 'id'
                                | 'code'
                                | 'highlight'
                                | 'ownerType'
                                | 'ownerName'
                                | 'tooltipText'
                                | 'shouldShowDefaultPreference'
                              > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                            >
                          }
                        >
                      }
                    >
                  })
                | ({ __typename: 'HotelRoomDetails' } & Pick<
                    HotelRoomDetails,
                    | 'hotelId'
                    | 'name'
                    | 'checkIn'
                    | 'checkOut'
                    | 'starRating'
                    | 'medianReviewRating'
                    | 'phoneNumber'
                    | 'chainCode'
                    | 'logoPhotoUrl'
                    | 'isPreferred'
                    | 'hasDeposit'
                    | 'isCancellable'
                    | 'isEcoFriendly'
                    | 'notes'
                  > & {
                      address: Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      distance: Pick<Distance, 'value' | 'units'>
                      selectedRoom: Pick<
                        Room,
                        | 'id'
                        | 'type'
                        | 'description'
                        | 'inventoryId'
                        | 'isCorporateRate'
                        | 'isFreeCancellable'
                        | 'source'
                        | 'isSelectable'
                        | 'cancellationPolicy'
                        | 'cancellationPolicyDescription'
                        | 'preferredTag'
                        | 'rateQualifier'
                        | 'numberOfGuests'
                      > & {
                        totalCost: Pick<Money, 'amount' | 'currency'>
                        feesAndTaxes: Pick<Money, 'amount' | 'currency'>
                        averageRate: {
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }
                        baseRate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        rate?: Maybe<{
                          primary: Pick<Money, 'amount' | 'currency'>
                          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                        }>
                        dailyRates: Array<
                          Pick<DailyRate, 'date'> & {
                            rate: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }
                        >
                        policy: Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                          outOfPolicyData?: Maybe<
                            Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                          >
                        }
                      }
                      preference?: Maybe<
                        Pick<
                          Preference,
                          | 'id'
                          | 'code'
                          | 'highlight'
                          | 'ownerType'
                          | 'ownerName'
                          | 'tooltipText'
                          | 'shouldShowDefaultPreference'
                        > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                      >
                      policy?: Maybe<
                        Pick<Policy, 'isInPolicy' | 'outOfPolicyReasons'> & {
                          outOfPolicyData?: Maybe<
                            Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                          >
                        }
                      >
                      photos: Array<Pick<Photo, 'index' | 'url' | 'size'>>
                      amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                      exclusiveAmenities?: Maybe<
                        Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                      >
                    })
                | {}
            }
          >
          cartSummary: Pick<CartSummary, 'startDate' | 'endDate'> & {
            cartCost: {
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              payLater: {
                domainCosts: Array<
                  Pick<DomainCostSummary, 'domain'> & {
                    totalTaxes: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    totalRate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    totalCost: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
                subTotal: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            }
          }
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type CreateCartMutationVariables = Exact<{ [key: string]: never }>

export type CreateCartMutation = {
  createCart:
    | ({ __typename: 'CreateCartResponse' } & Pick<CreateCartResponse, 'cartId'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type GetCostSummaryQueryVariables = Exact<{
  cartId: Scalars['ID']
}>

export type GetCostSummaryQuery = {
  costSummary:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'CostSummaryResponse' } & Pick<CostSummaryResponse, 'cartId'> & {
          estimatedTotal: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          prepaidCost: {
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            intentCosts: Array<
              Pick<IntentCost, 'domain' | 'passengerCount'> & {
                baseCost: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                taxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                serviceFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                totalCost: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
          }
          reservedCost: {
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            intentCosts: Array<
              Pick<IntentCost, 'domain' | 'passengerCount'> & {
                baseCost: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                taxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                serviceFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                totalCost: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
          }
        })
}

export type GetOopInfoQueryVariables = Exact<{
  cartId: Scalars['ID']
}>

export type GetOopInfoQuery = {
  oopInfo:
    | { __typename: 'CartNotFoundResponse' }
    | ({ __typename: 'GetOOPInfoResponse' } & Pick<
        GetOopInfoResponse,
        'cartId' | 'isCartOutOfPolicy' | 'isOOPAllowed' | 'message'
      > & {
          intentComplianceCodes: Array<
            Pick<IntentComplianceCode, 'intentId' | 'domain' | 'code'> & {
              options: Array<Pick<ComplianceCodeOption, 'code' | 'label'>>
            }
          >
          explanation?: Maybe<Pick<OopExplanation, 'required' | 'label' | 'value'>>
        })
}

export type RemoveFromCartMutationVariables = Exact<{
  input: RemoveFromCartInput
}>

export type RemoveFromCartMutation = {
  removeFromCart:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'RemoveFromCartResponse' } & Pick<RemoveFromCartResponse, 'code' | 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type SetCostAllocationMutationVariables = Exact<{
  input: SetCostAllocationInput
}>

export type SetCostAllocationMutation = {
  setCostAllocation:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'SetCostAllocationResponse' } & Pick<SetCostAllocationResponse, 'cartId'>)
}

export type SetOutOfPolicyJustificationMutationVariables = Exact<{
  input: SetOutOfPolicyJustificationInput
}>

export type SetOutOfPolicyJustificationMutation = {
  setOutOfPolicyJustification:
    | ({ __typename: 'CartNotFoundResponse' } & Pick<CartNotFoundResponse, 'message'>)
    | ({ __typename: 'SetOutOfPolicyJustificationResponse' } & Pick<
        SetOutOfPolicyJustificationResponse,
        'cartId'
      >)
}

export type AddCarRentalMutationVariables = Exact<{
  input: AddCarRentalInput
}>

export type AddCarRentalMutation = { addCarRental: Pick<BaseResponse, 'success'> }

export type AddFlightMutationVariables = Exact<{
  input: AddFlightInput
}>

export type AddFlightMutation = {
  addFlight: Pick<AddFlightResponse, 'success'> & {
    data?: Maybe<
      Pick<AddFlightResponseData, 'isSoldOut' | 'isRepriceFailed'> & {
        airPriceChange?: Maybe<
          Pick<SegmentPriceChangeDetails, 'reason' | 'type'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
      }
    >
  }
}

export type AddRailMutationVariables = Exact<{
  input: AddRailInput
}>

export type AddRailMutation = {
  addRail: Pick<AddRailResponse, 'success'> & {
    data?: Maybe<Pick<AddRailResponseData, 'isSoldOut'>>
  }
}

export type AlignTripMutationVariables = Exact<{
  input: AlignTripInput
}>

export type AlignTripMutation = {
  alignTrip: {
    itineraryMetadata: Pick<ItineraryMetadata, 'alignAttemptedSegmentTypes'> & {
      hotelMatchDetails?: Maybe<
        Pick<
          HotelMatchDetails,
          'matchFound' | 'isExactMatch' | 'isRoomTypeDifferent' | 'isRoomRateDifferent'
        >
      >
      hotelSearch?: Maybe<
        Pick<HotelSearchParams, 'checkIn' | 'checkOut' | 'hotelKey' | 'hotelName'> & {
          address?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
        }
      >
      carRentalMatchDetails?: Maybe<Pick<MatchDetails, 'matchFound' | 'isExactMatch'>>
      carRentalSearch?: Maybe<{
        dropOff: Pick<
          CarRentalSearchLocationParams,
          'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
        > & {
          address: Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
        pickUp: Pick<
          CarRentalSearchLocationParams,
          'airportCode' | 'airportLocation' | 'dateTime' | 'locationId'
        > & {
          address: Pick<
            Address,
            | 'airportCode'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'stateCode'
            | 'postalCode'
            | 'countryCode'
          > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
        }
      }>
      flightSearch?: Maybe<
        Array<
          Pick<
            FlightSearchParams,
            'originAirportCode' | 'destinationAirportCode' | 'departureDate' | 'serviceClass'
          >
        >
      >
      flightMatchDetails?: Maybe<
        Pick<
          FlightMatchDetails,
          | 'matchFound'
          | 'isExactMatch'
          | 'isFlightTimeMatch'
          | 'isFlightTimeWithinThreshold'
          | 'isFlightAirlineMatch'
        >
      >
    }
    itinerary: Pick<
      Itinerary,
      | 'itineraryId'
      | 'expiredAt'
      | 'flightId'
      | 'isMultiCity'
      | 'isTripAligned'
      | 'isMultiDestination'
      | 'isOnHold'
    > & {
      virtualPay?: Maybe<Pick<VirtualPay, 'isVirtualPayEnabled' | 'isVirtualPayRestricted'>>
      availableSegments?: Maybe<
        Pick<AvailableSegments, 'canAddHotel' | 'canAddFlight' | 'canAddCarRental'>
      >
      tripStatus?: Maybe<
        Pick<
          TripPostReservation,
          | 'isChangeTripAllowed'
          | 'isHoldTripAllowed'
          | 'areSegmentsRemovable'
          | 'areSegmentsChangeable'
          | 'areSegmentsCustomizable'
          | 'areExtraSegmentsAllowed'
          | 'areTicketOptionsAllowed'
        >
      >
      trip: Pick<ItineraryTrip, 'name' | 'startDate' | 'endDate' | 'isNameEditable' | 'imageUrl'>
      tripCost?: Maybe<
        Pick<TripCostSummary, 'allowUnusedTicketOptout' | 'hasOptOutUnusedTickets'> & {
          payNow?: Maybe<{
            hotel?: Maybe<{
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            flight?: Maybe<
              Array<
                Maybe<
                  Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                    base?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    total: {
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }
                    feesAndTaxes?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    transactionFee?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    serviceFee?: Maybe<{
                      primary: Pick<Money, 'currency' | 'amount'>
                      secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                    }>
                    fareTaxInfo?: Maybe<
                      Array<
                        Pick<FareTaxInfo, 'code' | 'name'> & {
                          amount: {
                            primary: Pick<Money, 'currency' | 'amount'>
                            secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                          }
                        }
                      >
                    >
                  }
                >
              >
            >
            train?: Maybe<
              Array<
                Maybe<{
                  base?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  total: {
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }
                  feesAndTaxes?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  transactionFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  serviceFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                }>
              >
            >
            car?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
          payLater?: Maybe<{
            hotel?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            car?: Maybe<{
              base?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              total: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
              feesAndTaxes?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }>
            carServices?: Maybe<
              Array<
                Maybe<
                  Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                    total: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    base?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    timeBase?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    offHours?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    airportFees?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    meetAndGreet?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    surfaceTransport?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    parking?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    fuelSurcharge?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    accountDiscount?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    rateQuote?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    stops?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    tolls?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    extraService?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    distanceBased?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    gratuity?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                    taxes: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                    accountService?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                  }
                >
              >
            >
            subTotal: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
          unusedTicket?: Maybe<
            Pick<UnusedTicket, 'status'> & {
              unvalidated?: Maybe<{
                original?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                penalty?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                newTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                exchangedTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                residual?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                addCollect?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                forfeiture?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }>
              validated?: Maybe<{
                original?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                penalty?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                newTicket?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                exchangedTicket: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                residual?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                addCollect?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                total: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                forfeiture?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }>
            }
          >
          totalWithUnusedTicket?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
          total: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
          fareTaxInfo?: Maybe<
            Array<
              Pick<FareTaxInfo, 'code' | 'name'> & {
                amount: {
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }
              }
            >
          >
        }
      >
      itineraryItemResponse: Array<Pick<ItineraryItem, 'code' | 'fieldName' | 'severity'>>
      messages?: Maybe<
        Array<Pick<DynamicSiteMessage, 'text' | 'header' | 'isDialog' | 'hasDesktopBannerImage'>>
      >
      holdRules?: Maybe<
        Pick<HoldRules, 'autoCancellationTime' | 'reasonsForNotAllowing' | 'holdAllowed'>
      >
      segments?: Maybe<
        Array<
          Pick<Segment, 'uuid' | 'position' | 'type'> & {
            hotel?: Maybe<
              Pick<
                HotelSegment,
                | 'alignedWithFlightDate'
                | 'name'
                | 'checkIn'
                | 'checkOut'
                | 'segmentState'
                | 'rateQualifier'
                | 'imageUrl'
                | 'id'
                | 'roomType'
                | 'hasDeposit'
                | 'source'
                | 'isReserved'
                | 'rulesAndRestrictions'
                | 'isCancellable'
                | 'starRating'
                | 'cancellationPolicy'
                | 'cancellationPolicyDescription'
                | 'roomDescription'
                | 'roomCount'
                | 'medianReviewRating'
                | 'isPreferred'
                | 'isEcoFriendly'
                | 'phone'
                | 'hotelId'
                | 'vendorCode'
                | 'alignState'
              > & {
                totalCost: Pick<Money, 'amount' | 'currency'>
                averageRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                dailyRates: Array<
                  Pick<DailyRate, 'date'> & {
                    rate: {
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  }
                >
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                address: Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                numberOfGuests: Pick<NumberOfGuests, 'adults'>
                amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                exclusiveAmenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
                photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
                preferredDescription?: Maybe<
                  Pick<
                    Preference,
                    | 'id'
                    | 'code'
                    | 'highlight'
                    | 'ownerType'
                    | 'ownerName'
                    | 'tooltipText'
                    | 'shouldShowDefaultPreference'
                  > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                >
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                distance?: Maybe<Pick<Distance, 'value' | 'units'>>
                serviceFeeRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
              }
            >
            carService?: Maybe<
              Pick<
                CarServiceSegment,
                | 'carId'
                | 'key'
                | 'noRateText'
                | 'rateType'
                | 'name'
                | 'numberOfPassengers'
                | 'chargeDisclaimer'
                | 'cancellationPolicy'
                | 'isReserved'
                | 'duration'
                | 'logo'
              > & {
                preferredDescription?: Maybe<
                  Pick<
                    PreferredDescription,
                    'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                  >
                >
                preferenceAttributes?: Maybe<
                  Array<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                >
                stops?: Maybe<
                  Array<
                    Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  >
                >
                pickUp: Pick<CarServicePickUp, 'time'> & {
                  location?: Maybe<{
                    flight: Pick<
                      AirportPickUpLocationFlight,
                      | 'airportName'
                      | 'isCommercial'
                      | 'carrierCode'
                      | 'flightNumber'
                      | 'dateTime'
                      | 'airportCode'
                    >
                    geocode: Pick<Geocode, 'lat' | 'long'>
                  }>
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
                dropOff: Pick<CarServiceDropOff, 'asDirected' | 'time'> & {
                  location?: Maybe<
                    Pick<
                      DirectedDropOffLocation,
                      'asDirected' | 'requestedHours' | 'requestedDuration' | 'asDirectedMultiDay'
                    > & {
                      flight?: Maybe<
                        Pick<
                          AirportPickUpLocationFlight,
                          | 'airportName'
                          | 'isCommercial'
                          | 'carrierCode'
                          | 'flightNumber'
                          | 'dateTime'
                          | 'airportCode'
                        >
                      >
                      geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                    }
                  >
                  address?: Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                }
                provider?: Maybe<Pick<CarServiceProvider, 'logo' | 'name'>>
                polylineGeocodes?: Maybe<Array<Pick<Geocode, 'lat' | 'long'>>>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                }
                car: Pick<CarServiceSegmentCar, 'capacity' | 'imageUrl' | 'description' | 'type'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              }
            >
            carRental?: Maybe<
              Pick<
                CarRentalSegment,
                | 'carId'
                | 'name'
                | 'segmentState'
                | 'alignedWithFlightDate'
                | 'alignedWithFlightTime'
                | 'imageUrl'
                | 'carImageUrl'
                | 'vendorImage'
                | 'carClass'
                | 'carType'
                | 'carTransmission'
                | 'vendor'
                | 'vendorCode'
                | 'isCarAirConditioned'
                | 'contractName'
                | 'carCapacity'
                | 'carLuggage'
                | 'isReserved'
                | 'pickUpTime'
                | 'dropOffTime'
                | 'pickupLocationId'
                | 'dropoffLocationId'
                | 'isPreferred'
                | 'alignState'
              > & {
                carDetails: Pick<
                  CarRentalSegmentCarDetails,
                  | 'imageUrl'
                  | 'class'
                  | 'type'
                  | 'transmission'
                  | 'fuel'
                  | 'capacity'
                  | 'luggage'
                  | 'isAirConditioned'
                  | 'contractName'
                >
                vendorDetails: Pick<CarRentalSegmentVendorDetails, 'imageUrl' | 'code' | 'name'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFeeRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFee?: Maybe<
                  Pick<ServiceFee, 'id' | 'description'> & {
                    rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                >
                carLocationType?: Maybe<{
                  pickup: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
                  dropoff: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                preferredDescription?: Maybe<
                  Pick<
                    PreferredDescription,
                    'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                  >
                >
                carCosts: Pick<CarCostsCarSegment, 'isMileageUnlimited'> & {
                  extraDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  extraHour?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  perDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  perWeek?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
                  extraDayRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  extraHourRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  perDayRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  perWeekRate?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }
                pickUpLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                  phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
                dropOffLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                  phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                  workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                  address: Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                }
                safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>
              }
            >
            trainLeg?: Maybe<
              Pick<
                TrainLegSegment,
                | 'legId'
                | 'departureDateTime'
                | 'arrivalDateTime'
                | 'rulesAndRestrictions'
                | 'departureStationCode'
                | 'arrivalStationCode'
                | 'travelStops'
                | 'imageUrl'
                | 'name'
                | 'isReserved'
                | 'isRefundable'
                | 'isEticket'
                | 'seatsRequested'
                | 'platform'
                | 'ecoInfo'
                | 'segmentState'
                | 'appliedRailCards'
                | 'routeType'
              > & {
                bookingFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                deliveryFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                deliveryOption?: Maybe<Pick<DeliveryOptions, 'name' | 'code'>>
                fareConditions?: Maybe<{
                  tier: Pick<RailFareTier, 'id' | 'name'>
                  localizedFareConditions: Array<
                    Pick<
                      LocalizedFareConditions,
                      'fareTypeDescription' | 'summaryConditions' | 'language' | 'validityPeriod'
                    > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
                  >
                }>
                travelTime: Pick<TravelTime, 'hours' | 'minutes'>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                taxes?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                transactionFees?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                totalCost?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                penaltyFees?: Maybe<
                  Array<
                    Maybe<
                      Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                        rates?: Maybe<
                          Array<
                            Maybe<{
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }>
                          >
                        >
                        penaltyFee?: Maybe<
                          Array<
                            Maybe<
                              Pick<
                                TrainPenaltyFee,
                                | 'state'
                                | 'isRuleAllowed'
                                | 'effectiveStartDate'
                                | 'effectiveEndDate'
                              > & { amount?: Maybe<Pick<Money, 'currency' | 'amount'>> }
                            >
                          >
                        >
                      }
                    >
                  >
                >
                tier?: Maybe<Pick<TrainTier, 'name' | 'description'>>
                segments: Array<
                  Pick<
                    TrainOrBusSegment,
                    | 'segmentId'
                    | 'imageUrl'
                    | 'marketingServiceName'
                    | 'type'
                    | 'transportNumber'
                    | 'trainId'
                    | 'serviceClassName'
                    | 'carrierCode'
                    | 'carrierName'
                    | 'service'
                    | 'trainData'
                    | 'segmentComment'
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'carrierLogoUrl'
                    | 'transportationMode'
                  > & {
                    allocatedSeats?: Maybe<
                      Array<Pick<ItineraryTrainSegmentSeatInfo, 'seat' | 'carriage'>>
                    >
                    stops?: Maybe<Array<Pick<TrainStop, 'departingTime' | 'stationName'>>>
                    changeDuration?: Maybe<Pick<TravelTime, 'minutes' | 'hours'>>
                    travelTime: Pick<TravelTime, 'minutes' | 'hours'>
                    amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                    arrival: Pick<
                      TrainStationInfo,
                      'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                    > & {
                      address: Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    }
                    departure: Pick<
                      TrainStationInfo,
                      'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                    > & {
                      address: Pick<
                        Address,
                        | 'airportCode'
                        | 'locationName'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'stateCode'
                        | 'postalCode'
                        | 'countryCode'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    }
                  }
                >
              }
            >
            flightLeg?: Maybe<
              Pick<
                FlightLegSegment,
                | 'departureDateTime'
                | 'segmentState'
                | 'arrivalDateTime'
                | 'legId'
                | 'flightStops'
                | 'stops'
                | 'fareRules'
                | 'fareAttributes'
                | 'isNDCFare'
                | 'isReserved'
                | 'isSameCarrierName'
                | 'isRoundTripLeg'
                | 'isRoundTripCost'
              > & {
                luggageOptions?: Maybe<
                  Array<
                    Pick<LuggageOption, 'code' | 'value'> & {
                      cost?: Maybe<{
                        primary: Pick<Money, 'amount' | 'currency'>
                        secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }>
                    }
                  >
                >
                flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
                rate: {
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
                baseRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                netRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                totalCostRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                feesAndTaxesRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                unusedTicketRate?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                  outOfPolicyData?: Maybe<
                    Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>
                  >
                  outOfPolicyComplianceCodes?: Maybe<
                    Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                  >
                }
                segments: Array<
                  Pick<
                    FlightLegSubSegment,
                    | 'segmentId'
                    | 'name'
                    | 'flightNumber'
                    | 'travelerSeat'
                    | 'travelerId'
                    | 'carrierCode'
                    | 'bookingCode'
                    | 'departureDate'
                    | 'arrivalDate'
                    | 'imageUrl'
                    | 'operatedBy'
                    | 'isSeatAvailable'
                    | 'isSeatSelectable'
                    | 'isDepartAirportDifferentFromConnecting'
                    | 'isInstantPurchaseCarrier'
                    | 'serviceClass'
                    | 'mealService'
                    | 'bookingProvider'
                    | 'originTerminal'
                    | 'destinationTerminal'
                    | 'percentageOnTime'
                    | 'fare'
                  > & {
                    flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                    images: Pick<FlightLegSubSegmentImages, 'square' | 'rectangle'>
                    serviceFee?: Maybe<
                      Pick<ServiceFee, 'id' | 'description'> & {
                        rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                      }
                    >
                    penaltyFees?: Maybe<
                      Array<
                        Maybe<
                          Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                            penaltyFee?: Maybe<
                              Array<
                                Maybe<
                                  Pick<
                                    TrainPenaltyFee,
                                    | 'state'
                                    | 'isRuleAllowed'
                                    | 'effectiveStartDate'
                                    | 'effectiveEndDate'
                                  > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                                >
                              >
                            >
                            rates?: Maybe<
                              Array<
                                Maybe<{
                                  primary: Pick<Money, 'amount' | 'currency'>
                                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                                }>
                              >
                            >
                          }
                        >
                      >
                    >
                    preferredDescription?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    preferenceAttributes?: Maybe<
                      Pick<
                        Preference,
                        | 'id'
                        | 'code'
                        | 'highlight'
                        | 'ownerType'
                        | 'ownerName'
                        | 'tooltipText'
                        | 'shouldShowDefaultPreference'
                      > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                    >
                    baggageFees?: Maybe<
                      Pick<BaggageFees, 'carrierUrl'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<FlightBaggageFee, 'type'> & {
                                rate: {
                                  primary: Pick<Money, 'currency' | 'amount'>
                                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                                }
                                baggageSize?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                  upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                }>
                                baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                                baggageWeight?: Maybe<{
                                  lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                  upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                }>
                              }
                            >
                          >
                        >
                      }
                    >
                    amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                    codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                    plane?: Maybe<Pick<PlaneData, 'type' | 'onTime' | 'code'>>
                    aircraft?: Maybe<Pick<AircraftData, 'type' | 'onTime' | 'code'>>
                    departure: Pick<
                      AirportAddress,
                      'airportCode' | 'airportName' | 'airportCity'
                    > & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'stateCode'
                          | 'postalCode'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'countryCode'
                          | 'locationName'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                    arrival: Pick<AirportAddress, 'airportCode' | 'airportName'> & {
                      address?: Maybe<
                        Pick<
                          Address,
                          | 'airportCode'
                          | 'stateCode'
                          | 'postalCode'
                          | 'street1'
                          | 'street2'
                          | 'city'
                          | 'countryCode'
                          | 'locationName'
                        > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                      >
                    }
                  }
                >
              }
            >
          }
        >
      >
      complianceMessage?: Maybe<
        Pick<ComplianceMessage, 'errors' | 'infos' | 'warns' | 'countryRestrictions'>
      >
      continuityMessage?: Maybe<
        Array<
          Pick<ContinuityMessage, 'code' | 'intent' | 'data' | 'followUpMessages' | 'segmentId'>
        >
      >
      overlappingTrips?: Maybe<Array<Pick<OverlappingTrip, 'name' | 'id'>>>
      airPriceChangeInfo?: Maybe<
        Pick<PriceChangeInfo, 'type' | 'reason'> & {
          amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      >
      carPriceChangeInfo?: Maybe<
        Pick<PriceChangeInfo, 'type' | 'reason'> & {
          amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
      >
      priceChanges: {
        air?: Maybe<
          Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
        car?: Maybe<
          Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
      }
      hotelLocationInformation?: Maybe<
        Array<
          Pick<
            HotelLocationInformation,
            'id' | 'name' | 'chainCode' | 'email' | 'additionalInformation'
          > & {
            location?: Maybe<
              Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
            >
            phoneNumber?: Maybe<
              Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
            >
            faxNumber?: Maybe<
              Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
            >
          }
        >
      >
      tripLevelPolicy?: Maybe<
        Array<
          Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
            outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
            outOfPolicyComplianceCodes?: Maybe<
              Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
            >
          }
        >
      >
      railCards?: Maybe<
        Array<Pick<RailCard, 'code' | 'groupName' | 'name' | 'vendorCode' | 'vendorName'>>
      >
    }
  }
}

export type ChangeFlightMutationVariables = Exact<{
  input: ChangeFlightInput
}>

export type ChangeFlightMutation = {
  changeFlight: Pick<ChangeFlightResponse, 'success' | 'message' | 'code'> & {
    data?: Maybe<
      Pick<ChangeFlightResponseData, 'isSoldOut'> & {
        airPriceChange?: Maybe<
          Pick<SegmentPriceChangeDetails, 'reason' | 'type'> & {
            rate?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
          }
        >
      }
    >
  }
}

export type ChangeSeatsAssignmentMutationVariables = Exact<{
  input: ChangeSeatsAssignmentInput
}>

export type ChangeSeatsAssignmentMutation = {
  changeSeatsAssignment: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type CreateItineraryMutationVariables = Exact<{
  input?: InputMaybe<CreateItineraryInput>
}>

export type CreateItineraryMutation = { createItinerary: Pick<CreateItineraryResult, 'id'> }

export type GetItineraryQueryVariables = Exact<{
  itineraryId: Scalars['String']
  deviceClass?: InputMaybe<DeviceClass>
  imageOptions?: InputMaybe<ImageInputOptions>
}>

export type GetItineraryQuery = {
  itinerary: Pick<
    Itinerary,
    | 'itineraryId'
    | 'expiredAt'
    | 'flightId'
    | 'isMultiCity'
    | 'isTripAligned'
    | 'isMultiDestination'
    | 'isOnHold'
  > & {
    virtualPay?: Maybe<Pick<VirtualPay, 'isVirtualPayEnabled' | 'isVirtualPayRestricted'>>
    availableSegments?: Maybe<
      Pick<AvailableSegments, 'canAddHotel' | 'canAddFlight' | 'canAddCarRental'>
    >
    tripStatus?: Maybe<
      Pick<
        TripPostReservation,
        | 'isChangeTripAllowed'
        | 'isHoldTripAllowed'
        | 'areSegmentsRemovable'
        | 'areSegmentsChangeable'
        | 'areSegmentsCustomizable'
        | 'areExtraSegmentsAllowed'
        | 'areTicketOptionsAllowed'
      >
    >
    trip: Pick<ItineraryTrip, 'name' | 'startDate' | 'endDate' | 'isNameEditable' | 'imageUrl'>
    tripCost?: Maybe<
      Pick<TripCostSummary, 'allowUnusedTicketOptout' | 'hasOptOutUnusedTickets'> & {
        payNow?: Maybe<{
          hotel?: Maybe<{
            total: {
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFee?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
          }>
          flight?: Maybe<
            Array<
              Maybe<
                Pick<TripCost, 'passengerNumber' | 'ticketNumber'> & {
                  base?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  total: {
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }
                  feesAndTaxes?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  transactionFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  serviceFee?: Maybe<{
                    primary: Pick<Money, 'currency' | 'amount'>
                    secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                  }>
                  fareTaxInfo?: Maybe<
                    Array<
                      Pick<FareTaxInfo, 'code' | 'name'> & {
                        amount: {
                          primary: Pick<Money, 'currency' | 'amount'>
                          secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                        }
                      }
                    >
                  >
                }
              >
            >
          >
          train?: Maybe<
            Array<
              Maybe<{
                base?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                total: {
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }
                feesAndTaxes?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                transactionFee?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
                serviceFee?: Maybe<{
                  primary: Pick<Money, 'currency' | 'amount'>
                  secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                }>
              }>
            >
          >
          car?: Maybe<{
            base?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            total: {
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFee?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
          }>
          subTotal: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }>
        payLater?: Maybe<{
          hotel?: Maybe<{
            base?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            total: {
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFee?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
          }>
          car?: Maybe<{
            base?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            total: {
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }
            feesAndTaxes?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
            serviceFee?: Maybe<{
              primary: Pick<Money, 'currency' | 'amount'>
              secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
            }>
          }>
          carServices?: Maybe<
            Array<
              Maybe<
                Pick<CarServiceTripCost, 'type' | 'minHours' | 'days'> & {
                  total: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                  base?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  timeBase?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  offHours?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  airportFees?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  meetAndGreet?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  surfaceTransport?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  parking?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  fuelSurcharge?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  accountDiscount?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  rateQuote?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  stops?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  tolls?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  extraService?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  distanceBased?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  gratuity?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                  taxes: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                  accountService?: Maybe<{
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }>
                }
              >
            >
          >
          subTotal: {
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }
        }>
        unusedTicket?: Maybe<
          Pick<UnusedTicket, 'status'> & {
            unvalidated?: Maybe<{
              original?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              penalty?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              newTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              exchangedTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              residual?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              addCollect?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              forfeiture?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }>
            validated?: Maybe<{
              original?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              penalty?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              newTicket?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              exchangedTicket: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              residual?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              addCollect?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              total: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              forfeiture?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
            }>
          }
        >
        totalWithUnusedTicket?: Maybe<{
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }>
        total: {
          primary: Pick<Money, 'amount' | 'currency'>
          secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
        }
        fareTaxInfo?: Maybe<
          Array<
            Pick<FareTaxInfo, 'code' | 'name'> & {
              amount: {
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }
            }
          >
        >
      }
    >
    itineraryItemResponse: Array<Pick<ItineraryItem, 'code' | 'fieldName' | 'severity'>>
    messages?: Maybe<
      Array<Pick<DynamicSiteMessage, 'text' | 'header' | 'isDialog' | 'hasDesktopBannerImage'>>
    >
    holdRules?: Maybe<
      Pick<HoldRules, 'autoCancellationTime' | 'reasonsForNotAllowing' | 'holdAllowed'>
    >
    segments?: Maybe<
      Array<
        Pick<Segment, 'uuid' | 'position' | 'type'> & {
          hotel?: Maybe<
            Pick<
              HotelSegment,
              | 'alignedWithFlightDate'
              | 'name'
              | 'checkIn'
              | 'checkOut'
              | 'segmentState'
              | 'rateQualifier'
              | 'imageUrl'
              | 'id'
              | 'roomType'
              | 'hasDeposit'
              | 'source'
              | 'isReserved'
              | 'rulesAndRestrictions'
              | 'isCancellable'
              | 'starRating'
              | 'cancellationPolicy'
              | 'cancellationPolicyDescription'
              | 'roomDescription'
              | 'roomCount'
              | 'medianReviewRating'
              | 'isPreferred'
              | 'isEcoFriendly'
              | 'phone'
              | 'hotelId'
              | 'vendorCode'
              | 'alignState'
            > & {
              totalCost: Pick<Money, 'amount' | 'currency'>
              averageRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              baseRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              totalCostRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              feesAndTaxesRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              dailyRates: Array<
                Pick<DailyRate, 'date'> & {
                  rate: {
                    primary: Pick<Money, 'amount' | 'currency'>
                    secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                  }
                }
              >
              netRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              address: Pick<
                Address,
                | 'airportCode'
                | 'locationName'
                | 'street1'
                | 'street2'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              numberOfGuests: Pick<NumberOfGuests, 'adults'>
              amenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
              exclusiveAmenities: Array<Pick<Amenity, 'name' | 'isOfProperty' | 'isOfRoom'>>
              photos: Array<Pick<Photo, 'index' | 'size' | 'url'>>
              preferredDescription?: Maybe<
                Pick<
                  Preference,
                  | 'id'
                  | 'code'
                  | 'highlight'
                  | 'ownerType'
                  | 'ownerName'
                  | 'tooltipText'
                  | 'shouldShowDefaultPreference'
                > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
              >
              policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
                outOfPolicyComplianceCodes?: Maybe<
                  Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                >
              }
              distance?: Maybe<Pick<Distance, 'value' | 'units'>>
              serviceFeeRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
            }
          >
          carService?: Maybe<
            Pick<
              CarServiceSegment,
              | 'carId'
              | 'key'
              | 'noRateText'
              | 'rateType'
              | 'name'
              | 'numberOfPassengers'
              | 'chargeDisclaimer'
              | 'cancellationPolicy'
              | 'isReserved'
              | 'duration'
              | 'logo'
            > & {
              preferredDescription?: Maybe<
                Pick<
                  PreferredDescription,
                  'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                >
              >
              preferenceAttributes?: Maybe<
                Array<
                  Pick<
                    Preference,
                    | 'id'
                    | 'code'
                    | 'highlight'
                    | 'ownerType'
                    | 'ownerName'
                    | 'tooltipText'
                    | 'shouldShowDefaultPreference'
                  > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                >
              >
              stops?: Maybe<
                Array<
                  Maybe<
                    Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  >
                >
              >
              pickUp: Pick<CarServicePickUp, 'time'> & {
                location?: Maybe<{
                  flight: Pick<
                    AirportPickUpLocationFlight,
                    | 'airportName'
                    | 'isCommercial'
                    | 'carrierCode'
                    | 'flightNumber'
                    | 'dateTime'
                    | 'airportCode'
                  >
                  geocode: Pick<Geocode, 'lat' | 'long'>
                }>
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              }
              dropOff: Pick<CarServiceDropOff, 'asDirected' | 'time'> & {
                location?: Maybe<
                  Pick<
                    DirectedDropOffLocation,
                    'asDirected' | 'requestedHours' | 'requestedDuration' | 'asDirectedMultiDay'
                  > & {
                    flight?: Maybe<
                      Pick<
                        AirportPickUpLocationFlight,
                        | 'airportName'
                        | 'isCommercial'
                        | 'carrierCode'
                        | 'flightNumber'
                        | 'dateTime'
                        | 'airportCode'
                      >
                    >
                    geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>>
                  }
                >
                address?: Maybe<
                  Pick<
                    Address,
                    | 'airportCode'
                    | 'locationName'
                    | 'street1'
                    | 'street2'
                    | 'city'
                    | 'stateCode'
                    | 'postalCode'
                    | 'countryCode'
                  > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                >
              }
              provider?: Maybe<Pick<CarServiceProvider, 'logo' | 'name'>>
              polylineGeocodes?: Maybe<Array<Pick<Geocode, 'lat' | 'long'>>>
              policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
              }
              car: Pick<CarServiceSegmentCar, 'capacity' | 'imageUrl' | 'description' | 'type'>
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
            }
          >
          carRental?: Maybe<
            Pick<
              CarRentalSegment,
              | 'carId'
              | 'name'
              | 'segmentState'
              | 'alignedWithFlightDate'
              | 'alignedWithFlightTime'
              | 'imageUrl'
              | 'carImageUrl'
              | 'vendorImage'
              | 'carClass'
              | 'carType'
              | 'carTransmission'
              | 'vendor'
              | 'vendorCode'
              | 'isCarAirConditioned'
              | 'contractName'
              | 'carCapacity'
              | 'carLuggage'
              | 'isReserved'
              | 'pickUpTime'
              | 'dropOffTime'
              | 'pickupLocationId'
              | 'dropoffLocationId'
              | 'isPreferred'
              | 'alignState'
            > & {
              carDetails: Pick<
                CarRentalSegmentCarDetails,
                | 'imageUrl'
                | 'class'
                | 'type'
                | 'transmission'
                | 'fuel'
                | 'capacity'
                | 'luggage'
                | 'isAirConditioned'
                | 'contractName'
              >
              vendorDetails: Pick<CarRentalSegmentVendorDetails, 'imageUrl' | 'code' | 'name'>
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              baseRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              totalCostRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              feesAndTaxesRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              netRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFeeRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              serviceFee?: Maybe<
                Pick<ServiceFee, 'id' | 'description'> & {
                  rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }
              >
              carLocationType?: Maybe<{
                pickup: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
                dropoff: Pick<CarLocationDetails, 'type' | 'code' | 'name'>
              }>
              policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
                outOfPolicyComplianceCodes?: Maybe<
                  Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                >
              }
              preferredDescription?: Maybe<
                Pick<
                  PreferredDescription,
                  'name' | 'type' | 'code' | 'bold' | 'customColor' | 'customName'
                >
              >
              carCosts: Pick<CarCostsCarSegment, 'isMileageUnlimited'> & {
                extraDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                extraHour?: Maybe<Pick<Money, 'amount' | 'currency'>>
                perDay?: Maybe<Pick<Money, 'amount' | 'currency'>>
                perWeek?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              carCostRates: Pick<CarCostRates, 'isMileageUnlimited'> & {
                extraDayRate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                extraHourRate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                perDayRate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
                perWeekRate?: Maybe<{
                  primary: Pick<Money, 'amount' | 'currency'>
                  secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                }>
              }
              pickUpLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                address: Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              }
              dropOffLocation: Pick<CarRentalInteraction, 'date' | 'isDeliveryCollection'> & {
                phone?: Maybe<Pick<CarRentalPhone, 'type' | 'number'>>
                workingHours?: Maybe<Pick<CarWorkingHours, 'range' | 'isAllDay'>>
                address: Pick<
                  Address,
                  | 'airportCode'
                  | 'locationName'
                  | 'street1'
                  | 'street2'
                  | 'city'
                  | 'stateCode'
                  | 'postalCode'
                  | 'countryCode'
                > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
              }
              safetyCheck: Pick<CarRentalSafetyCheck, 'messageCode' | 'badgeUrl' | 'url'>
            }
          >
          trainLeg?: Maybe<
            Pick<
              TrainLegSegment,
              | 'legId'
              | 'departureDateTime'
              | 'arrivalDateTime'
              | 'rulesAndRestrictions'
              | 'departureStationCode'
              | 'arrivalStationCode'
              | 'travelStops'
              | 'imageUrl'
              | 'name'
              | 'isReserved'
              | 'isRefundable'
              | 'isEticket'
              | 'seatsRequested'
              | 'platform'
              | 'ecoInfo'
              | 'segmentState'
              | 'appliedRailCards'
              | 'routeType'
            > & {
              bookingFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              deliveryFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              deliveryOption?: Maybe<Pick<DeliveryOptions, 'name' | 'code'>>
              fareConditions?: Maybe<{
                tier: Pick<RailFareTier, 'id' | 'name'>
                localizedFareConditions: Array<
                  Pick<
                    LocalizedFareConditions,
                    'fareTypeDescription' | 'summaryConditions' | 'language' | 'validityPeriod'
                  > & { conditions: Array<Pick<Conditions, 'name' | 'description'>> }
                >
              }>
              travelTime: Pick<TravelTime, 'hours' | 'minutes'>
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              taxes?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              transactionFees?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              totalCost?: Maybe<{
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }>
              policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
                outOfPolicyComplianceCodes?: Maybe<
                  Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                >
              }
              penaltyFees?: Maybe<
                Array<
                  Maybe<
                    Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                      rates?: Maybe<
                        Array<
                          Maybe<{
                            primary: Pick<Money, 'amount' | 'currency'>
                            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                          }>
                        >
                      >
                      penaltyFee?: Maybe<
                        Array<
                          Maybe<
                            Pick<
                              TrainPenaltyFee,
                              'state' | 'isRuleAllowed' | 'effectiveStartDate' | 'effectiveEndDate'
                            > & { amount?: Maybe<Pick<Money, 'currency' | 'amount'>> }
                          >
                        >
                      >
                    }
                  >
                >
              >
              tier?: Maybe<Pick<TrainTier, 'name' | 'description'>>
              segments: Array<
                Pick<
                  TrainOrBusSegment,
                  | 'segmentId'
                  | 'imageUrl'
                  | 'marketingServiceName'
                  | 'type'
                  | 'transportNumber'
                  | 'trainId'
                  | 'serviceClassName'
                  | 'carrierCode'
                  | 'carrierName'
                  | 'service'
                  | 'trainData'
                  | 'segmentComment'
                  | 'departureDate'
                  | 'arrivalDate'
                  | 'carrierLogoUrl'
                  | 'transportationMode'
                > & {
                  allocatedSeats?: Maybe<
                    Array<Pick<ItineraryTrainSegmentSeatInfo, 'seat' | 'carriage'>>
                  >
                  stops?: Maybe<Array<Pick<TrainStop, 'departingTime' | 'stationName'>>>
                  changeDuration?: Maybe<Pick<TravelTime, 'minutes' | 'hours'>>
                  travelTime: Pick<TravelTime, 'minutes' | 'hours'>
                  amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                  arrival: Pick<
                    TrainStationInfo,
                    'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                  > & {
                    address: Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  }
                  departure: Pick<
                    TrainStationInfo,
                    'ticketMachineAvailable' | 'stationCode' | 'stationName' | 'phoneNumber'
                  > & {
                    address: Pick<
                      Address,
                      | 'airportCode'
                      | 'locationName'
                      | 'street1'
                      | 'street2'
                      | 'city'
                      | 'stateCode'
                      | 'postalCode'
                      | 'countryCode'
                    > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                  }
                }
              >
            }
          >
          flightLeg?: Maybe<
            Pick<
              FlightLegSegment,
              | 'departureDateTime'
              | 'segmentState'
              | 'arrivalDateTime'
              | 'legId'
              | 'flightStops'
              | 'stops'
              | 'fareRules'
              | 'fareAttributes'
              | 'isNDCFare'
              | 'isReserved'
              | 'isSameCarrierName'
              | 'isRoundTripLeg'
              | 'isRoundTripCost'
            > & {
              luggageOptions?: Maybe<
                Array<
                  Pick<LuggageOption, 'code' | 'value'> & {
                    cost?: Maybe<{
                      primary: Pick<Money, 'amount' | 'currency'>
                      secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }>
                  }
                >
              >
              flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
              totalTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
              fareLabel?: Maybe<Pick<FareLabel, 'text' | 'tooltip'>>
              rate: {
                primary: Pick<Money, 'amount' | 'currency'>
                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
              }
              baseRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              netRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              totalCostRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              feesAndTaxesRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              unusedTicketRate?: Maybe<{
                primary: Pick<Money, 'currency' | 'amount'>
                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
              }>
              policy: Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
                outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
                outOfPolicyComplianceCodes?: Maybe<
                  Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
                >
              }
              segments: Array<
                Pick<
                  FlightLegSubSegment,
                  | 'segmentId'
                  | 'name'
                  | 'flightNumber'
                  | 'travelerSeat'
                  | 'travelerId'
                  | 'carrierCode'
                  | 'bookingCode'
                  | 'departureDate'
                  | 'arrivalDate'
                  | 'imageUrl'
                  | 'operatedBy'
                  | 'isSeatAvailable'
                  | 'isSeatSelectable'
                  | 'isDepartAirportDifferentFromConnecting'
                  | 'isInstantPurchaseCarrier'
                  | 'serviceClass'
                  | 'mealService'
                  | 'bookingProvider'
                  | 'originTerminal'
                  | 'destinationTerminal'
                  | 'percentageOnTime'
                  | 'fare'
                > & {
                  flightTime?: Maybe<Pick<TravelTime, 'hours' | 'minutes'>>
                  images: Pick<FlightLegSubSegmentImages, 'square' | 'rectangle'>
                  serviceFee?: Maybe<
                    Pick<ServiceFee, 'id' | 'description'> & {
                      rate?: Maybe<Pick<Money, 'amount' | 'currency'>>
                    }
                  >
                  penaltyFees?: Maybe<
                    Array<
                      Maybe<
                        Pick<PenaltyFee, 'id' | 'type' | 'days'> & {
                          penaltyFee?: Maybe<
                            Array<
                              Maybe<
                                Pick<
                                  TrainPenaltyFee,
                                  | 'state'
                                  | 'isRuleAllowed'
                                  | 'effectiveStartDate'
                                  | 'effectiveEndDate'
                                > & { amount?: Maybe<Pick<Money, 'amount' | 'currency'>> }
                              >
                            >
                          >
                          rates?: Maybe<
                            Array<
                              Maybe<{
                                primary: Pick<Money, 'amount' | 'currency'>
                                secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                              }>
                            >
                          >
                        }
                      >
                    >
                  >
                  preferredDescription?: Maybe<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                  preferenceAttributes?: Maybe<
                    Pick<
                      Preference,
                      | 'id'
                      | 'code'
                      | 'highlight'
                      | 'ownerType'
                      | 'ownerName'
                      | 'tooltipText'
                      | 'shouldShowDefaultPreference'
                    > & { customBadge: Pick<CustomBadge, 'name' | 'color'> }
                  >
                  baggageFees?: Maybe<
                    Pick<BaggageFees, 'carrierUrl'> & {
                      items?: Maybe<
                        Array<
                          Maybe<
                            Pick<FlightBaggageFee, 'type'> & {
                              rate: {
                                primary: Pick<Money, 'currency' | 'amount'>
                                secondary?: Maybe<Pick<Money, 'currency' | 'amount'>>
                              }
                              baggageSize?: Maybe<{
                                lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                              }>
                              baggageCount?: Maybe<Pick<BaggageAllowanceCount, 'lower' | 'upper'>>
                              baggageWeight?: Maybe<{
                                lower: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                                upper: Pick<BaggageAllowanceValue, 'unit' | 'value'>
                              }>
                            }
                          >
                        >
                      >
                    }
                  >
                  amenities?: Maybe<Pick<TransportAmenities, 'codes' | 'values'>>
                  codeshareCarrier: Pick<CodeshareCarrier, 'name' | 'code'>
                  plane?: Maybe<Pick<PlaneData, 'type' | 'onTime' | 'code'>>
                  aircraft?: Maybe<Pick<AircraftData, 'type' | 'onTime' | 'code'>>
                  departure: Pick<AirportAddress, 'airportCode' | 'airportName' | 'airportCity'> & {
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'stateCode'
                        | 'postalCode'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'countryCode'
                        | 'locationName'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                  arrival: Pick<AirportAddress, 'airportCode' | 'airportName'> & {
                    address?: Maybe<
                      Pick<
                        Address,
                        | 'airportCode'
                        | 'stateCode'
                        | 'postalCode'
                        | 'street1'
                        | 'street2'
                        | 'city'
                        | 'countryCode'
                        | 'locationName'
                      > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
                    >
                  }
                }
              >
            }
          >
        }
      >
    >
    complianceMessage?: Maybe<
      Pick<ComplianceMessage, 'errors' | 'infos' | 'warns' | 'countryRestrictions'>
    >
    continuityMessage?: Maybe<
      Array<Pick<ContinuityMessage, 'code' | 'intent' | 'data' | 'followUpMessages' | 'segmentId'>>
    >
    overlappingTrips?: Maybe<Array<Pick<OverlappingTrip, 'name' | 'id'>>>
    airPriceChangeInfo?: Maybe<
      Pick<PriceChangeInfo, 'type' | 'reason'> & {
        amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    >
    carPriceChangeInfo?: Maybe<
      Pick<PriceChangeInfo, 'type' | 'reason'> & {
        amount?: Maybe<Pick<Money, 'amount' | 'currency'>>
      }
    >
    priceChanges: {
      air?: Maybe<
        Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
          rate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
      car?: Maybe<
        Pick<SegmentPriceChangeDetails, 'type' | 'reason'> & {
          rate?: Maybe<{
            primary: Pick<Money, 'amount' | 'currency'>
            secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
          }>
        }
      >
    }
    hotelLocationInformation?: Maybe<
      Array<
        Pick<
          HotelLocationInformation,
          'id' | 'name' | 'chainCode' | 'email' | 'additionalInformation'
        > & {
          location?: Maybe<
            Pick<
              Address,
              | 'airportCode'
              | 'locationName'
              | 'street1'
              | 'street2'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            > & { geocode?: Maybe<Pick<Geocode, 'lat' | 'long'>> }
          >
          phoneNumber?: Maybe<
            Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
          >
          faxNumber?: Maybe<
            Pick<PhoneNumber, 'countryCode' | 'areaCode' | 'number' | 'type' | 'formattedNumber'>
          >
        }
      >
    >
    tripLevelPolicy?: Maybe<
      Array<
        Pick<Policy, 'outOfPolicyReasons' | 'isInPolicy'> & {
          outOfPolicyData?: Maybe<Array<Pick<OutOfPolicyData, 'type' | 'data' | 'currency'>>>
          outOfPolicyComplianceCodes?: Maybe<
            Array<Pick<OutOfPolicyComplianceCode, 'code' | 'val' | 'description'>>
          >
        }
      >
    >
    railCards?: Maybe<
      Array<Pick<RailCard, 'code' | 'groupName' | 'name' | 'vendorCode' | 'vendorName'>>
    >
  }
}

export type ReactivateItineraryMutationVariables = Exact<{
  input: ReactivateItineraryInput
}>

export type ReactivateItineraryMutation = {
  reactivateItinerary: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type RemoveCarRentalMutationVariables = Exact<{
  itineraryId: Scalars['String']
  carId: Scalars['String']
}>

export type RemoveCarRentalMutation = {
  removeCarRental: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type RemoveFlightSegmentMutationVariables = Exact<{
  itineraryId: Scalars['String']
  flightId: Scalars['String']
}>

export type RemoveFlightSegmentMutation = {
  removeFlight: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type RemoveItineraryMutationVariables = Exact<{
  itineraryId: Scalars['String']
}>

export type RemoveItineraryMutation = {
  removeItinerary: Pick<BaseResponse, 'code' | 'success' | 'message'>
}

export type RemoveRailSegmentMutationVariables = Exact<{
  itineraryId: Scalars['String']
  railId: Scalars['String']
}>

export type RemoveRailSegmentMutation = {
  removeRail: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type RemoveRoomMutationVariables = Exact<{
  roomKey: Scalars['String']
  itineraryId: Scalars['String']
}>

export type RemoveRoomMutation = { removeRoom: Pick<BaseResponse, 'code' | 'success' | 'message'> }

export type ReplaceCarRentalMutationVariables = Exact<{
  input: ReplaceCarRentalInput
}>

export type ReplaceCarRentalMutation = {
  replaceCarRental: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type ReplaceRoomMutationVariables = Exact<{
  hotelId: Scalars['String']
  hotelResultId?: InputMaybe<Scalars['String']>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  oldRoomKey: Scalars['String']
  newRoomKey: Scalars['String']
  itineraryId: Scalars['String']
}>

export type ReplaceRoomMutation = {
  replaceRoom: Pick<BaseResponse, 'code' | 'success' | 'message'>
}

export type SelectRoomMutationVariables = Exact<{
  roomKey: Scalars['String']
  itineraryId?: InputMaybe<Scalars['String']>
  hotelId: Scalars['String']
  hotelResultId?: InputMaybe<Scalars['String']>
  checkIn: Scalars['String']
  checkOut: Scalars['String']
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
}>

export type SelectRoomMutation = { selectRoom: Pick<BaseResponse, 'code' | 'success' | 'message'> }

export type UpdateTripNameMutationVariables = Exact<{
  input: UpdateTripNameInput
}>

export type UpdateTripNameMutation = {
  updateTripName: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type GetOutOfThePolicyConfigurationQueryVariables = Exact<{
  segments: Array<InputMaybe<Scalars['String']>>
}>

export type GetOutOfThePolicyConfigurationQuery = {
  outOfPolicyReason?: Maybe<
    Array<Maybe<Pick<OutOfPolicyReason, 'reason' | 'segment' | 'id' | 'codeForBooking'>>>
  >
}

export type SendOopCodesMutationVariables = Exact<{
  input: SendOopCodesInput
}>

export type SendOopCodesMutation = {
  sendOopCodes: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type CreateCustomerUberAccountMutationVariables = Exact<{ [key: string]: never }>

export type CreateCustomerUberAccountMutation = {
  createCustomerUberAccount:
    | ({ __typename: 'CreateCustomerUberAccountErrorResponse' } & Pick<
        CreateCustomerUberAccountErrorResponse,
        'reason'
      >)
    | ({ __typename: 'CreateCustomerUberAccountResponse' } & Pick<
        CreateCustomerUberAccountResponse,
        'linkingUrl'
      >)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type GetSearchRideHailWithErrorQueryVariables = Exact<{
  input: SearchRideHailRequest
}>

export type GetSearchRideHailWithErrorQuery = {
  searchRideHailWithError:
    | ({ __typename: 'SearchRideHailErrorResponse' } & Pick<SearchRideHailErrorResponse, 'reason'>)
    | ({ __typename: 'SearchRideHailResponse' } & {
        recommendedResults: Array<
          Pick<
            SearchRideHailResult,
            | 'provider'
            | 'id'
            | 'displayName'
            | 'shortDescription'
            | 'imageUrl'
            | 'estimatedWaitingTime'
            | 'estimatedRideDuration'
            | 'passengerCapacity'
          > & { estimatedPrice?: Maybe<Pick<Money, 'amount' | 'currency'>> }
        >
        results: Array<
          Pick<
            SearchRideHailResult,
            | 'provider'
            | 'id'
            | 'displayName'
            | 'shortDescription'
            | 'imageUrl'
            | 'estimatedWaitingTime'
            | 'estimatedRideDuration'
            | 'passengerCapacity'
          > & { estimatedPrice?: Maybe<Pick<Money, 'amount' | 'currency'>> }
        >
      })
}

export type RideHailUpcomingFlightsQueryVariables = Exact<{ [key: string]: never }>

export type RideHailUpcomingFlightsQuery = {
  rideHailUpcomingFlights:
    | ({ __typename: 'RideHailUpcomingFlightsErrorResponse' } & Pick<
        RideHailUpcomingFlightsErrorResponse,
        'reason'
      >)
    | ({ __typename: 'RideHailUpcomingFlightsResponse' } & {
        rideHailUpcomingFlights: Array<
          Pick<
            RideHailUpcomingFlight,
            | 'airportCode'
            | 'designatorCode'
            | 'arrivalDate'
            | 'arrivalTime'
            | 'airlineName'
            | 'airlineImage'
          >
        >
      })
    | { __typename: 'UnexpectedError' }
}

export type RideHailExpenseConfigQueryVariables = Exact<{
  input: RideHailExpenseConfigInput
}>

export type RideHailExpenseConfigQuery = {
  rideHailExpenseConfig:
    | ({ __typename: 'RideHailExpenseConfigErrorResponse' } & Pick<
        RideHailExpenseConfigErrorResponse,
        'reason'
      >)
    | ({ __typename: 'RideHailExpenseConfigResponse' } & Pick<
        RideHailExpenseConfigResponse,
        'expenseEnabled' | 'customExpenseEnabled' | 'memoEnabled' | 'memoRequired'
      > & { expenseCodes: Array<Pick<RideHailExpenseCode, 'id' | 'description'>> })
}

export type GetCarRulesByTransactionIdQueryVariables = Exact<{
  input: GetCarRentalRulesByTransactionIdInput
}>

export type GetCarRulesByTransactionIdQuery = {
  carRulesByTransactionId: Array<Pick<CarRules, 'header' | 'value'>>
}

export type GetFlightFareRulesByTransactionIdQueryVariables = Exact<{
  input: FlightFareRulesByTransactionIdInput
}>

export type GetFlightFareRulesByTransactionIdQuery = {
  flightFareRulesByTransactionId: { rules: Array<Pick<FlightFareRule, 'header' | 'value'>> }
}

export type GetAirEnhancedSeatMapQueryVariables = Exact<{
  input: EnhancedSeatMapInput
}>

export type GetAirEnhancedSeatMapQuery = {
  enhancedSeatMap:
    | ({ __typename: 'EnhancedSeatMap' } & Pick<
        EnhancedSeatMap,
        'departureDate' | 'destination' | 'flightNumber' | 'origin' | 'readOnly'
      > & {
          cabins: Array<
            Pick<EnhancedSeatMapCabin, 'cabinClass'> & {
              rows: Array<
                Pick<EnhancedSeatMapRow, 'number'> & {
                  seat: Array<
                    Pick<
                      EnhancedSeatMapSeat,
                      | 'number'
                      | 'column'
                      | 'available'
                      | 'selectable'
                      | 'type'
                      | 'isOverWing'
                      | 'isNearWindow'
                      | 'isPaid'
                      | 'isNearAisle'
                      | 'isPremium'
                      | 'isPreferred'
                      | 'isExitRowSeat'
                      | 'isFrequentFlyerEntitled'
                      | 'hasBulkhead'
                      | 'isUpperDeck'
                      | 'hasGalley'
                      | 'hasLavatory'
                      | 'isSmokingAllowed'
                      | 'isSuitableForHandicapped'
                    > & {
                      price?: Maybe<
                        Array<
                          Pick<EnhancedSeatMapPrice, 'name' | 'id' | 'passengers'> & {
                            rate: {
                              primary: Pick<Money, 'amount' | 'currency'>
                              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
                            }
                          }
                        >
                      >
                    }
                  >
                }
              >
              sections?: Maybe<Array<Pick<EnhancedSeatMapRowOffset, 'first' | 'last'>>>
              overWingRows?: Maybe<Pick<EnhancedSeatMapRowOffset, 'first' | 'last'>>
            }
          >
          carrier: Pick<
            FlightCarrier,
            'id' | 'name' | 'imageUrl' | 'logoImageUrl' | 'logoRectangleImageUrl'
          >
        })
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type AddPaymentCardMutationVariables = Exact<{
  input: AddPaymentCardInput
}>

export type AddPaymentCardMutation = {
  addPaymentCard:
    | ({ __typename: 'PaymentCard' } & Pick<PaymentCard, 'cardId' | 'legacyId'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput
}>

export type ChangePasswordMutation = {
  changePassword:
    | ({ __typename: 'BaseResponse' } & Pick<
        BaseResponse,
        'code' | 'success' | 'message' | 'errorCodes'
      >)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type DeleteCreditCardMutationVariables = Exact<{
  input: DeleteCreditCardInput
}>

export type DeleteCreditCardMutation = {
  deleteCreditCard: { __typename: 'BaseResponse' } & Pick<
    BaseResponse,
    'code' | 'success' | 'message'
  >
}

export type GetAvailableDelegatesQueryVariables = Exact<{
  query: Scalars['String']
}>

export type GetAvailableDelegatesQuery = {
  getAvailableDelegates?: Maybe<{
    availableDelegates?: Maybe<
      Array<Pick<AvailableDelegates, 'firstName' | 'lastName' | 'email' | 'isDelegate' | 'id'>>
    >
  }>
}

export type GetAvailableSpecialRequestsQueryVariables = Exact<{
  input: SpecialRequestsInput
}>

export type GetAvailableSpecialRequestsQuery = {
  availableSpecialRequests?: Maybe<{
    airSpecialRequests?: Maybe<Array<Pick<SpecialRequestOption, 'label' | 'value'>>>
    hotelSpecialRequests?: Maybe<Array<Pick<SpecialRequestOption, 'label' | 'value'>>>
    carRentalSpecialRequests?: Maybe<Array<Pick<SpecialRequestOption, 'label' | 'value'>>>
  }>
}

export type GetAvailableVendorsQueryVariables = Exact<{
  input: AvailableVendorsInput
}>

export type GetAvailableVendorsQuery = {
  availableVendors?: Maybe<{
    availableAirVendors?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
    availableHotelVendors?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
    availableCarRentalVendors?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
  }>
}

export type GetCreditCardsQueryVariables = Exact<{
  isCCNumberMasked?: InputMaybe<Scalars['Boolean']>
}>

export type GetCreditCardsQuery = {
  creditCards: Array<
    Pick<
      UserCreditCard,
      | 'name'
      | 'label'
      | 'cardNumberLast4'
      | 'expirationDate'
      | 'legacyId'
      | 'fullLegacyId'
      | 'brandType'
      | 'cardNumber'
      | 'securityCode'
    > & {
      billingAddress?: Maybe<
        Pick<
          UserCreditCardBillingAddress,
          'street1' | 'street2' | 'countryCode' | 'stateCode' | 'postalCode' | 'city'
        >
      >
    }
  >
}

export type GetCurrenciesQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrenciesQuery = {
  getCurrencies?: Maybe<{ currencies?: Maybe<Array<Pick<Currency, 'code' | 'value'>>> }>
}

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never }>

export type GetLanguagesQuery = {
  getLanguages?: Maybe<{ languages?: Maybe<Array<Pick<Language, 'code' | 'value'>>> }>
}

export type GetProfileFieldsSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileFieldsSettingsQuery = {
  profileFieldsSettings: {
    fields: Array<
      Pick<
        ProfileFieldSettings,
        | 'isRequired'
        | 'isEditable'
        | 'labelToDisplay'
        | 'labelDefault'
        | 'profileMetadataId'
        | 'categoryId'
        | 'shouldDisplayInSettings'
        | 'shouldDisplayInRegistration'
      > & {
        fieldMetadata?: Maybe<
          Pick<
            ProfileFieldMetadata,
            | 'fieldType'
            | 'minLength'
            | 'maxLength'
            | 'allowCharacters'
            | 'sortAlphabetically'
            | 'defaultListValue'
            | 'defaultValue'
          > & { options?: Maybe<Array<Pick<ProfileFieldListOptions, 'name' | 'value'>>> }
        >
      }
    >
  }
}

export type GetSiteCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetSiteCardsQuery = {
  siteCards: {
    cards: Array<
      Pick<
        SiteCardsDetails,
        | 'id'
        | 'isRestricted'
        | 'isActive'
        | 'isEnabledForAir'
        | 'isEnabledForHotel'
        | 'isEnabledForCarRental'
        | 'isEnabledForCarService'
        | 'isRequiredForAir'
        | 'isRequiredForHotel'
      > & { card: Pick<SiteCardPersonalData, 'brandType'> }
    >
  }
}

export type GetSuggestedHomeAirportsQueryVariables = Exact<{
  input: SuggestedHomeAirportsInput
}>

export type GetSuggestedHomeAirportsQuery = {
  suggestedHomeAirports: Array<
    Pick<PlaceAutocomplete, 'airportCode' | 'airportName' | 'name' | 'shortName'> & {
      address?: Maybe<Pick<PlaceAutocompleteAddress, 'city' | 'countryName' | 'stateCode'>>
    }
  >
}

export type GetTimeZonesQueryVariables = Exact<{ [key: string]: never }>

export type GetTimeZonesQuery = {
  getTimeZones?: Maybe<{ timeZones?: Maybe<Array<Pick<TimeZone, 'code' | 'value'>>> }>
}

export type RemoveNationalIdCardMutationVariables = Exact<{
  input: RemoveNationalIdCardInput
}>

export type RemoveNationalIdCardMutation = {
  removeNationalIdCard:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type RemoveVisaMutationVariables = Exact<{
  input: RemoveVisaInput
}>

export type RemoveVisaMutation = {
  removeVisa:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type UpdateAuthorizersMutationVariables = Exact<{
  input: UpdateAuthorizersInput
}>

export type UpdateAuthorizersMutation = {
  updateAuthorizers: Pick<BaseResponse, 'code' | 'success' | 'message' | 'errorCodes'>
}

export type UpdateDelegatesMutationVariables = Exact<{
  input: UpdateDelegatesInput
}>

export type UpdateDelegatesMutation = {
  updateDelegates: Pick<BaseResponse, 'code' | 'success' | 'message' | 'errorCodes'>
}

export type UpdateNationalIdCardMutationVariables = Exact<{
  input: UpdateNationalIdCardInput
}>

export type UpdateNationalIdCardMutation = {
  updateNationalIdCard:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | { __typename: 'InvalidInputError' }
    | { __typename: 'NoPassportError' }
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
}

export type UpdatePassportMutationVariables = Exact<{
  input: UpdatePassportInput
}>

export type UpdatePassportMutation = {
  updatePassport:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | ({ __typename: 'InvalidInputError' } & Pick<InvalidInputError, 'message'> & {
          fields: Array<Pick<FieldError, 'type' | 'errorType' | 'message'>>
        })
    | ({ __typename: 'NoPassportError' } & Pick<NoPassportError, 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export type UpdateVisaMutationVariables = Exact<{
  input: UpdateVisaInput
}>

export type UpdateVisaMutation = {
  updateVisa:
    | ({ __typename: 'BaseResponse' } & Pick<BaseResponse, 'code' | 'success' | 'message'>)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message'>)
    | { __typename: 'UpdateVisaResponseDetails' }
}

export type GetTravelPolicyQueryVariables = Exact<{ [key: string]: never }>

export type GetTravelPolicyQuery = {
  travelPolicy: Pick<
    TravelPolicy,
    'purposeLabel' | 'isPurposeRequired' | 'systemBehaviorType' | 'minAdvancePurchaseDays'
  >
}

export type GetTravelPreferencesQueryVariables = Exact<{ [key: string]: never }>

export type GetTravelPreferencesQuery = {
  travelPreferences?: Maybe<{
    flight: Pick<
      AirTravelPreference,
      | 'homeAirport'
      | 'homeAirportLabel'
      | 'searchBy'
      | 'seatPreference'
      | 'redressNumber'
      | 'redressNumberIssuingCountry'
      | 'resultsView'
      | 'knownTravelerNumber'
      | 'knownTravelerIssuingCountry'
      | 'noteToArranger'
    > & {
      meal?: Maybe<Pick<PreferenceOptions, 'label' | 'value'>>
      specialRequest: Array<Pick<PreferenceOptions, 'label' | 'value'>>
      loyaltyProgram: Array<Pick<LoyaltyProgram, 'vendorCode' | 'label' | 'number' | 'imgUrl'>>
      mealOptions?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
      specialRequestOptions?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
    }
    hotel: Pick<HotelPreference, 'hotelAmenity'> & {
      specialRequest: Array<Pick<PreferenceOptions, 'label' | 'value'>>
      loyaltyProgram: Array<Pick<LoyaltyProgram, 'vendorCode' | 'label' | 'number' | 'imgUrl'>>
      specialRequestOptions?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
    }
    carrental: Pick<CarRentalPreference, 'carClass'> & {
      specialRequest: Array<Pick<PreferenceOptions, 'label' | 'value'>>
      loyaltyProgram: Array<Pick<LoyaltyProgram, 'vendorCode' | 'label' | 'number' | 'imgUrl'>>
      specialRequestOptions?: Maybe<Array<Pick<PreferenceOptions, 'label' | 'value'>>>
    }
    rail: {
      specialRequest: Array<Pick<PreferenceOptions, 'label' | 'value'>>
      loyaltyProgram: Array<Pick<LoyaltyProgram, 'vendorCode' | 'label' | 'number' | 'imgUrl'>>
    }
  }>
}

export type Document360SupportArticleByIdQueryVariables = Exact<{
  input: Document360SupportArticleByIdInput
}>

export type Document360SupportArticleByIdQuery = {
  document360SupportArticleById: Pick<Document360SupportArticle, 'title' | 'htmlContent'>
}

export type ApplyUnusedTicketMutationVariables = Exact<{
  input: UnusedTicketInput
}>

export type ApplyUnusedTicketMutation = {
  applyUnusedTicket: Pick<BaseResponse, 'code' | 'errorCodes' | 'message' | 'success'>
}

export type RemoveUnusedTicketMutationVariables = Exact<{
  input: UnusedTicketInput
}>

export type RemoveUnusedTicketMutation = {
  removeUnusedTicket: Pick<BaseResponse, 'success' | 'message' | 'code' | 'errorCodes'>
}

export type GetUnusedTicketsQueryVariables = Exact<{ [key: string]: never }>

export type GetUnusedTicketsQuery = {
  unusedTickets: {
    unusedTickets: Array<
      Maybe<
        Pick<
          UnusedTicket,
          'airlineCode' | 'expirationDate' | 'recordLocator' | 'status' | 'ticketNumber'
        > & {
          airline?: Maybe<Pick<Decode, 'code' | 'imageUrl' | 'value'>>
          changeFee?: Maybe<
            Pick<ChangeFee, 'amount' | 'code' | 'requestedCurrencyAmount' | 'requestedCurrencyUnit'>
          >
          originalCost?: Maybe<
            Pick<
              OriginalCost,
              'amount' | 'code' | 'requestedCurrencyAmount' | 'requestedCurrencyUnit'
            >
          >
          unvalidated?: Maybe<{
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
          validated?: Maybe<{
            exchangedTicket: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
            newTicket?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            original?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            penalty?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            residual?: Maybe<{
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }>
            total: {
              primary: Pick<Money, 'amount' | 'currency'>
              secondary?: Maybe<Pick<Money, 'amount' | 'currency'>>
            }
          }>
        }
      >
    >
  }
}

export type UpdateAirTravelPreferenceMutationVariables = Exact<{
  input: AirTravelPreferenceInput
}>

export type UpdateAirTravelPreferenceMutation = {
  airTravelPreference: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type UpdateCarRentalTravelPreferenceMutationVariables = Exact<{
  input: CarRentalTravelPreferenceInput
}>

export type UpdateCarRentalTravelPreferenceMutation = {
  carRentalTravelPreference: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type UpdateHotelTravelPreferenceMutationVariables = Exact<{
  input: HotelTravelPreferenceInput
}>

export type UpdateHotelTravelPreferenceMutation = {
  hotelTravelPreference: Pick<BaseResponse, 'success' | 'message' | 'code'>
}

export type GetUserProfileQueryVariables = Exact<{
  includeOpenEnrollmentRegistrationCustomFields?: InputMaybe<Scalars['Boolean']>
}>

export type GetUserProfileQuery = {
  getUserProfile: {
    businessAddress: { __typename: 'UserProfileBusinessAddress' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
    homeAddress: { __typename: 'UserProfileHomeAddress' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
    personalInformation: { __typename: 'UserProfilePersonalInformation' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
    emergencyContact: { __typename: 'UserProfileEmergencyContact' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
    employeeInformation: { __typename: 'UserProfileEmployeeInformation' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
    travelPreferences: { __typename: 'UserProfileTravelPreferences' } & {
      additionalInformation: Array<
        { __typename: 'AdditionalInformation' } & Pick<
          AdditionalInformation,
          'id' | 'label' | 'name' | 'mandatory' | 'fieldType' | 'tooltip'
        > & {
            additionalFieldProperties:
              | ({ __typename: 'CheckBoxAdditionalInformation' } & Pick<
                  CheckBoxAdditionalInformation,
                  'defaultChecked' | 'checkedRequired' | 'checkBoxValue'
                >)
              | ({ __typename: 'DateTimeAdditionalInformation' } & Pick<
                  DateTimeAdditionalInformation,
                  | 'isAllowedPastDateTime'
                  | 'displayYear'
                  | 'displayMonth'
                  | 'displayDay'
                  | 'displayHour'
                  | 'displayMinute'
                  | 'displayTimeZone'
                  | 'minYear'
                  | 'maxYear'
                  | 'minMonth'
                  | 'maxMonth'
                  | 'minDay'
                  | 'maxDay'
                  | 'dateValue'
                >)
              | ({ __typename: 'ListAdditionalInformation' } & Pick<
                  ListAdditionalInformation,
                  'listType' | 'listAlignment' | 'defaultValue' | 'isAlphabeticallySorted' | 'value'
                > & { options?: Maybe<Array<Pick<CustomFieldOption, 'name' | 'value'>>> })
              | ({ __typename: 'NumberAdditionalInformation' } & Pick<
                  NumberAdditionalInformation,
                  | 'defaultNumber'
                  | 'minNumber'
                  | 'maxNumber'
                  | 'numberDecimal'
                  | 'numberType'
                  | 'numberValue'
                >)
              | ({ __typename: 'TextAdditionalInformation' } & Pick<
                  TextAdditionalInformation,
                  | 'singleLine'
                  | 'numbersOfLine'
                  | 'minLength'
                  | 'maxLength'
                  | 'defaultText'
                  | 'textValue'
                >)
          }
      >
    }
  }
}

export type UpdateUserProfileBusinessAddressMutationVariables = Exact<{
  input: UpdateProfileBusinessAddressInput
}>

export type UpdateUserProfileBusinessAddressMutation = {
  updateUserProfileBusinessAddress: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type UpdateUserProfileContactInformationMutationVariables = Exact<{
  input: UpdateProfileContactInformationInput
}>

export type UpdateUserProfileContactInformationMutation = {
  updateUserProfileContactInformation: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type UpdateUserProfileEmergencyContactMutationVariables = Exact<{
  input: UpdateProfileEmergencyContactInput
}>

export type UpdateUserProfileEmergencyContactMutation = {
  updateUserProfileEmergencyContact: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type UpdateUserProfileEmployeeInformationMutationVariables = Exact<{
  input: UpdateProfileEmployeeInformationInput
}>

export type UpdateUserProfileEmployeeInformationMutation = {
  updateUserProfileEmployeeInformation: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type UpdateUserProfileHomeAddressMutationVariables = Exact<{
  input: UpdateProfileHomeAddressInput
}>

export type UpdateUserProfileHomeAddressMutation = {
  updateUserProfileHomeAddress: Pick<BaseResponse, 'code' | 'success' | 'message' | 'errorCodes'>
}

export type UpdateUserProfilePersonalInformationMutationVariables = Exact<{
  input: UpdateProfilePersonalInformationInput
}>

export type UpdateUserProfilePersonalInformationMutation = {
  updateUserProfilePersonalInformation: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type UpdateUserProfileTravelPreferencesMutationVariables = Exact<{
  input: UpdateProfileTravelPreferencesInput
}>

export type UpdateUserProfileTravelPreferencesMutation = {
  updateUserProfileTravelPreferences: Pick<
    BaseResponse,
    'code' | 'success' | 'message' | 'errorCodes'
  >
}

export type GetPasswordSecurityConfigureQueryVariables = Exact<{ [key: string]: never }>

export type GetPasswordSecurityConfigureQuery = {
  passwordSecurityConfigure: Pick<
    PasswordSecurityConfigure,
    | 'dictionaryWordsNotAllowed'
    | 'forceAlphaNumericPassword'
    | 'passwordMinLength'
    | 'passwordReusability'
  >
}

export type GetUserQueryVariables = Exact<{ [key: string]: never }>

export type GetUserQuery = {
  user: Pick<
    User,
    | 'locale'
    | 'trustedLocale'
    | 'currency'
    | 'customerId'
    | 'companyId'
    | 'partnerId'
    | 'sessionTimeout'
    | 'delegatedId'
    | 'impersonatedId'
    | 'meetingId'
  > & {
    profile?: Maybe<
      Pick<
        Profile,
        | 'locale'
        | 'currency'
        | 'isGuest'
        | 'registrationStatus'
        | 'forceNewPassword'
        | 'creationSource'
        | 'timeZone'
        | 'dateFormat'
        | 'country'
        | 'identityHash'
        | 'measurementUnit'
        | 'firstName'
        | 'lastName'
        | 'middleName'
        | 'suffix'
        | 'suffixOptions'
        | 'rawSuffix'
        | 'title'
        | 'titleOptions'
        | 'dateOfBirth'
        | 'gender'
        | 'genderOptions'
        | 'hourlyTimeFormat'
        | 'confEmail'
        | 'username'
        | 'profilePin'
      > & {
        pwaWelcomeMessage?: Maybe<Pick<PwaWelcomeMessage, 'shouldShowWelcomeMessage'>>
        site?: Maybe<Pick<UserSite, 'name' | 'url' | 'isSelfServiceSite'>>
        personalContact?: Maybe<
          Pick<PersonalContact, 'email' | 'homeEmail'> & {
            address?: Maybe<
              Pick<
                PersonalContactAddress,
                | 'street1'
                | 'street2'
                | 'mailStop'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              >
            >
            phone?: Maybe<
              Pick<PersonalContactPhone, 'ext' | 'number' | 'officeExtension' | 'countryCode'>
            >
          }
        >
        businessContact?: Maybe<{
          address?: Maybe<
            Pick<
              PersonalContactAddress,
              | 'street1'
              | 'street2'
              | 'mailStop'
              | 'city'
              | 'stateCode'
              | 'postalCode'
              | 'countryCode'
            >
          >
          mobilePhone?: Maybe<
            Pick<PersonalContactPhone, 'ext' | 'number' | 'officeExtension' | 'countryCode'>
          >
          officePhone?: Maybe<
            Pick<PersonalContactPhone, 'ext' | 'number' | 'officeExtension' | 'countryCode'>
          >
          faxPhone?: Maybe<
            Pick<PersonalContactPhone, 'ext' | 'number' | 'officeExtension' | 'countryCode'>
          >
        }>
        notification?: Maybe<
          Array<
            Pick<Notification, 'id' | 'topic' | 'device' | 'email'> & {
              phone?: Maybe<Pick<NotificationPhone, 'country' | 'isoCountryCode' | 'number'>>
            }
          >
        >
        passports?: Maybe<
          Array<
            Pick<
              Passport,
              | 'id'
              | 'number'
              | 'issuingCountry'
              | 'country'
              | 'issueDate'
              | 'expirationDate'
              | 'preExpirationAlert'
              | 'unmaskedNumber'
            >
          >
        >
        visas?: Maybe<
          Array<
            Pick<
              Visa,
              'id' | 'number' | 'visaType' | 'country' | 'expirationDate' | 'preExpirationAlert'
            >
          >
        >
        nationalIdCards?: Maybe<
          Array<
            Pick<
              NationalIdCard,
              'id' | 'number' | 'country' | 'issueDate' | 'expirationDate' | 'preExpirationAlert'
            >
          >
        >
        emergencyContact?: Maybe<
          Pick<EmergencyContact, 'name' | 'relationship' | 'email'> & {
            primaryPhone?: Maybe<Pick<PersonalContactPhone, 'number' | 'ext' | 'countryCode'>>
            alternatePhone?: Maybe<Pick<PersonalContactPhone, 'number' | 'ext' | 'countryCode'>>
            address?: Maybe<
              Pick<
                PersonalContactAddress,
                | 'street1'
                | 'street2'
                | 'mailStop'
                | 'city'
                | 'stateCode'
                | 'postalCode'
                | 'countryCode'
              >
            >
          }
        >
        employee?: Maybe<
          Pick<
            Employee,
            | 'businessUnit'
            | 'costCenter'
            | 'defaultApproverId'
            | 'departmentCode'
            | 'departmentName'
            | 'division'
            | 'employeeId'
            | 'employeeType'
            | 'isActiveInCompany'
            | 'isVip'
            | 'jobLevel'
            | 'jobTitle'
            | 'companyName'
          > & { manager?: Maybe<Pick<Manager, 'id' | 'externalId'>> }
        >
        groupware?: Maybe<Pick<Groupware, 'groupwareId'>>
        preferredCreditCards?: Maybe<Array<Pick<PreferredCreditCard, 'id' | 'segments'>>>
        approvers?: Maybe<
          Pick<Approvers, 'expenseApproverId' | 'purchaseApproverId' | 'travelApproverId'>
        >
        mis?: Maybe<Pick<Mis, 'mis1' | 'mis2' | 'mis3' | 'mis4' | 'mis5' | 'mis6' | 'mis7'>>
        creditCards?: Maybe<Array<Pick<ProfileCreditCard, 'id' | 'preExpirationAlert'>>>
        delegates?: Maybe<Array<Pick<UserDelegate, 'id' | 'firstName' | 'lastName' | 'email'>>>
        authorizers?: Maybe<Array<Pick<UserDelegate, 'id' | 'firstName' | 'lastName' | 'email'>>>
        additionalDocumentsPreferences: {
          knownTravelerNumbers?: Maybe<
            Array<
              Pick<
                KnownTravelerNumber,
                'id' | 'number' | 'country' | 'expirationDate' | 'preExpirationAlert'
              >
            >
          >
          redressNumbers?: Maybe<Array<Pick<RedressNumber, 'number' | 'country'>>>
        }
      }
    >
  }
}

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput
}>

export type SetPasswordMutation = {
  setPassword:
    | ({ __typename: 'BaseResponse' } & Pick<
        BaseResponse,
        'code' | 'errorCodes' | 'message' | 'success'
      >)
    | ({ __typename: 'UnexpectedError' } & Pick<UnexpectedError, 'message' | 'lcid'>)
}

export const BookingInfoFragmentDoc = gql`
  fragment bookingInfo on RideHailBooking {
    bookingId
    description
    dropOffAddress
    estimatedPriceRange {
      primary {
        amountMax
        amountMin
        currency
      }
      secondary {
        amountMax
        amountMin
        currency
      }
    }
    pickUpAddress
    pickUpTime
    price {
      primary {
        amount
        currency
      }
      secondary {
        amount
        currency
      }
    }
    productName
    status
  }
`
export const PlaceFragmentDoc = gql`
  fragment Place on PlaceAutocomplete {
    placeId
    airportCode
    name
    shortName
    isHotelAddress
    latitude
    longitude
    address {
      city
      countryCode
      countryName
      stateCode
      postalCode
      street1
      lat
      lng
    }
    placeType
    locationType
    uniqueCode
    isGrouped
    timezone
  }
`
export const CovidStatisticItemFragmentDoc = gql`
  fragment CovidStatisticItem on Covid19Statistic {
    name
    confirmedNewDaily
    confirmedTotal
    confirmedFourteenDayTrend
    deathsNewDaily
    deathsTotal
    deathsFourteenDayTrend
  }
`
export const PolicyTripsFragmentDoc = gql`
  fragment policyTrips on Policy {
    isInPolicy
    outOfPolicyReasons
    outOfPolicyData {
      type
      data
      currency
    }
    outOfPolicyComplianceCodes {
      code
      val
      description
    }
  }
`
export const MoneyTripsFragmentDoc = gql`
  fragment moneyTrips on Money {
    amount
    currency
  }
`
export const RateTripsFragmentDoc = gql`
  fragment rateTrips on Rate {
    primary {
      ...moneyTrips
    }
    secondary {
      ...moneyTrips
    }
  }
  ${MoneyTripsFragmentDoc}
`
export const CustomFieldsConfigurationFragmentDoc = gql`
  fragment customFieldsConfiguration on CustomFieldConfiguration {
    customFieldCollection {
      collectionId
      collectionLabel
      collectionDescription
      customFields {
        optional
        name
        label
        tooltip
        value
        defaultValue
        type
        preTripApprovalDisplay {
          ruleDescription
        }
        checkBoxDisplay {
          isChecked
          isCheckedValueRequired
        }
        textDisplay {
          minLength
          maxLength
          numberOfLines
          characterWidth
        }
        enumerationDisplay {
          isRadioButtonsStyle
          option {
            name
            value
          }
        }
        numberDisplay {
          minValue
          maxValue
          decimalPlaces
          currencyCode
        }
        dateTimeDisplay {
          isAllowedPastDateTime
          displayYear
          displayMonth
          displayDay
          displayHour
          displayMinute
          displayTimeZone
          minYear
          maxYear
          minMonth
          maxMonth
          minDay
          maxDay
        }
      }
    }
  }
`
export const SpecialRequestFragmentDoc = gql`
  fragment specialRequest on CheckoutInfoSpecialRequest {
    data {
      name
      value
    }
    options {
      name
      value
    }
  }
`
export const SpecialRequestsFragmentDoc = gql`
  fragment specialRequests on CheckoutInfoSpecialRequests {
    air {
      ...specialRequest
    }
    carRental {
      ...specialRequest
    }
    hotel {
      ...specialRequest
    }
  }
  ${SpecialRequestFragmentDoc}
`
export const CreditCardFragmentDoc = gql`
  fragment creditCard on CheckoutInfoCreditCard {
    id
    data {
      id
      type
      number
      cvv
      label
      nameOnTheCard
      expirationDate
    }
    isCorporateCard
    address {
      airportCode
      locationName
      geocode {
        lat
        long
      }
      street1
      street2
      city
      stateCode
      postalCode
      countryCode
    }
    isCardOutOfPolicy
  }
`
export const SiteCardFragmentDoc = gql`
  fragment siteCard on CheckoutInfoSiteCard {
    id
    data {
      id
      label
    }
    isRestricted
    address {
      airportCode
      locationName
      geocode {
        lat
        long
      }
      street1
      street2
      city
      stateCode
      postalCode
      countryCode
    }
    isCardOutOfPolicy
  }
`
export const CheckoutInfoBillingDataFragmentDoc = gql`
  fragment checkoutInfoBillingData on CheckoutInfoBillingData {
    creditCards {
      ...creditCard
    }
    siteCards {
      ...siteCard
    }
    metadata {
      isBillingAddressRequired
      isCvvRequired
    }
    defaultCard {
      ...creditCard
    }
    defaultSiteCard {
      ...siteCard
    }
  }
  ${CreditCardFragmentDoc}
  ${SiteCardFragmentDoc}
`
export const BillingFragmentDoc = gql`
  fragment billing on CheckoutInfoBilling {
    air {
      ...checkoutInfoBillingData
    }
    carRental {
      ...checkoutInfoBillingData
    }
    hotel {
      ...checkoutInfoBillingData
    }
    rail {
      ...checkoutInfoBillingData
    }
    isSingleUseCreditCardAllowed
  }
  ${CheckoutInfoBillingDataFragmentDoc}
`
export const MealRequestFragmentDoc = gql`
  fragment mealRequest on CheckoutInfoMealRequest {
    data {
      mealName
      code
      id
    }
    options {
      mealName
      code
    }
  }
`
export const AirCheckoutInfoMembershipFragmentDoc = gql`
  fragment airCheckoutInfoMembership on CheckoutInfoAirMembership {
    data {
      id
      number
      carrierCode
      carrierName
    }
    options {
      carrierCode
      carrierName
    }
  }
`
export const CarRentalCheckoutInfoMembershipFragmentDoc = gql`
  fragment carRentalCheckoutInfoMembership on CheckoutInfoCarRentalMembership {
    data {
      id
      number
      vendorCode
      vendorName
    }
    options {
      vendorCode
      vendorName
    }
  }
`
export const HotelCheckoutInfoMembershipFragmentDoc = gql`
  fragment hotelCheckoutInfoMembership on CheckoutInfoHotelMembership {
    data {
      id
      appliesToChain
      vendorCode
      vendorName
      number
    }
    options {
      vendorCode
      vendorName
    }
  }
`
export const MembershipsFragmentDoc = gql`
  fragment memberships on CheckoutInfoMemberships {
    air {
      ...airCheckoutInfoMembership
    }
    carRental {
      ...carRentalCheckoutInfoMembership
    }
    hotel {
      ...hotelCheckoutInfoMembership
    }
  }
  ${AirCheckoutInfoMembershipFragmentDoc}
  ${CarRentalCheckoutInfoMembershipFragmentDoc}
  ${HotelCheckoutInfoMembershipFragmentDoc}
`
export const PrimaryTravelerFragmentDoc = gql`
  fragment primaryTraveler on CheckoutInfoTraveler {
    travelerFields {
      isPrimaryTraveler
      isTitleChangeable
      isNameChangeable
      isDefaultChangeTravelerOptionEnabled
      isAddressRequired
      isAddressChangeable
      isDateOfBirthRequired
      isDateOfBirthChangeable
      isGenderRequired
      isGenderChangeable
      isPhoneNumberRequired
      isEMailRequired
      isFirstNameRequired
      isMiddleNameRequired
      isLastNameRequired
      isSuffixRequired
      isSuffixChangeable
      isPhoneNumberChangeable
      isEMailChangeable
    }
    data {
      id
      dateOfBirth
      phoneNumber {
        type
        countryCode
        number
      }
      address {
        airportCode
        locationName
        geocode {
          lat
          long
        }
        street1
        street2
        city
        stateCode
        postalCode
        countryCode
      }
      homeAddress {
        airportCode
        locationName
        geocode {
          lat
          long
        }
        street1
        street2
        city
        stateCode
        postalCode
        countryCode
      }
      firstName
      lastName
      gender
      email
      primary
      suffix
      title
      middleInitial
      passport {
        id
        number
        issuingCountry
        country
        issueDate
        expirationDate
        unmaskedNumber
        preExpirationAlert
      }
      passports {
        id
        number
        issuingCountry
        country
        issueDate
        expirationDate
        unmaskedNumber
        preExpirationAlert
      }
      knownTravelerNumbers {
        number
        countryCode
        expirationDate
      }
      redressNumbers {
        number
        countryCode
      }
    }
  }
`
export const AddressFragmentDoc = gql`
  fragment address on Address {
    airportCode
    locationName
    geocode {
      lat
      long
    }
    street1
    street2
    city
    stateCode
    postalCode
    countryCode
  }
`
export const FailedBookingsFragmentDoc = gql`
  fragment failedBookings on PurchaseItineraryFailedBookings {
    hotelFailedBooking {
      error
      hotelData {
        hotelId
        checkIn
        checkOut
        name
        numberOfNights
        address {
          ...address
        }
        roomType
      }
    }
  }
  ${AddressFragmentDoc}
`
export const TravelerPostPurchaseInfoFragmentDoc = gql`
  fragment travelerPostPurchaseInfo on PurchaseInfoTraveler {
    lastName
    dateOfBirth
    phone {
      number
      type
      countryCode
    }
    address {
      airportCode
      geocode {
        lat
        long
      }
      street1
      street2
      city
      stateCode
      postalCode
      countryCode
    }
    firstName
    lastName
    gender
    sex
    suffix
    dateBirth
    email
    primary
    middleInitial
    options {
      isTitleChangeable
      isNameChangeable
      isDefaultChangeTravelerOptionEnabled
      isAddressRequired
      isAddressChangeable
      isDateOfBirthRequired
      isDateOfBirthChangeable
      isGenderRequired
      isGenderChangeable
      isPhoneNumberRequired
      isEMailRequired
      isFirstNameRequired
      isMiddleNameRequired
      isLastNameRequired
      isSuffixRequired
      isSuffixChangeable
      isPassportRequired
    }
    passports {
      id
      number
      issuingCountry
      country
      issueDate
      expirationDate
    }
    title
  }
`
export const TravelerPurchaseInfoFragmentDoc = gql`
  fragment travelerPurchaseInfo on PurchaseInfoTraveler {
    lastName
    dateOfBirth
    phone {
      number
      type
      countryCode
    }
    address {
      airportCode
      geocode {
        lat
        long
      }
      street1
      street2
      city
      stateCode
      postalCode
      countryCode
    }
    firstName
    lastName
    gender
    sex
    suffix
    dateBirth
    email
    primary
    middleInitial
    options {
      isTitleChangeable
      isNameChangeable
      isDefaultChangeTravelerOptionEnabled
      isAddressRequired
      isAddressChangeable
      isDateOfBirthRequired
      isDateOfBirthChangeable
      isGenderRequired
      isGenderChangeable
      isPhoneNumberRequired
      isEMailRequired
      isFirstNameRequired
      isMiddleNameRequired
      isLastNameRequired
      isSuffixRequired
      isSuffixChangeable
      isPassportRequired
    }
    passports {
      id
      number
      issuingCountry
      country
      issueDate
      expirationDate
    }
  }
`
export const TripsFragmentDoc = gql`
  fragment trips on TripSummary {
    domains
    endDate
    id
    title
    startDate
  }
`
export const PageFragmentDoc = gql`
  fragment page on Page {
    start
    size
    end
  }
`
export const HotelReservationsCostSummaryFragmentDoc = gql`
  fragment hotelReservationsCostSummary on HotelReservationsCostSummary {
    domain
  }
`
export const MoneyFragmentDoc = gql`
  fragment money on Money {
    amount
    currency
  }
`
export const RateFragmentDoc = gql`
  fragment rate on Rate {
    primary {
      ...money
    }
    secondary {
      ...money
    }
  }
  ${MoneyFragmentDoc}
`
export const CarRentalReservationsCostSummaryFragmentDoc = gql`
  fragment carRentalReservationsCostSummary on CarRentalReservationsCostSummary {
    domain
    totalCost {
      ...rate
    }
    totalRate {
      ...rate
    }
    totalTaxes {
      ...rate
    }
  }
  ${RateFragmentDoc}
`
export const HotelReservationFragmentDoc = gql`
  fragment hotelReservation on HotelReservation {
    domain
    id
  }
`
export const CarRentalReservationFragmentDoc = gql`
  fragment carRentalReservation on CarRentalReservation {
    confirmationNumber
    id
    isPaid
    domain
    reservedCarRental {
      pickupLocation {
        isAirport
        locationAddress {
          stateCode
          airportCode
          city
          countryCode
          geocode {
            lat
            long
          }
          locationName
          postalCode
          street1
          street2
        }
        locationId
        openingHours {
          dayOfWeek
          times {
            closingTime
            openingTime
          }
        }
        phone
      }
      carRentalLoyalty {
        label
        number
        vendorCode
      }
      dropOffDate
      dropOffLocation {
        isAirport
        locationAddress {
          street1
          stateCode
          postalCode
          locationName
          geocode {
            long
            lat
          }
          countryCode
          city
          airportCode
          street2
        }
        locationId
        openingHours {
          dayOfWeek
          times {
            openingTime
            closingTime
          }
        }
        phone
      }
      dropOffTime
      key
      pickupDate
      pickupTime
      vendor {
        name
        imageUrl
        code
      }
      vehicle {
        vehicleClass
        transmissionType
        passengerCapacity
        modelName
        isAirCondition
        fuelType
        imageUrl
        category
        baggageCapacity
      }
      termsAndConditions {
        value
        header
      }
      safetyCheck {
        url
        messageCode
        badgeUrl
      }
      rate {
        totalTaxes {
          ...rate
        }
        totalRate {
          ...rate
        }
        rateCode
        distanceUnlimited
        daysCharged
        dailyRate {
          ...rate
        }
        extraDayCharge {
          ...rate
        }
        extraHourCharge {
          ...rate
        }
        totalCost {
          ...rate
        }
      }
    }
    status
  }
  ${RateFragmentDoc}
`
export const SupportedActionsV2FragmentDoc = gql`
  fragment supportedActionsV2 on TripDetailsPostReservationV2 {
    allowAddFlight
    allowAddHotel
    allowAddCar
    allowBookAgain
    allowChange
    allowCancel
    segmentChangeActions {
      flightBooking {
        allowChangeDestination
        allowChangeCarrier
        flightChangeRules {
          flightChangeBehavior {
            allowedSearchStartDate
            allowedSearchEndDate
            allowChangeCarrier
            allowChangeFareType
            allowChangeAirports
            allowChangeCabin
          }
          changeType
        }
        cancelMessage {
          severity
          code
        }
        allowChange
        allowCancel
        needCancelAcknowledgement
      }
      trainBooking {
        cancelMessage {
          severity
          code
        }
        allowChange
        allowCancel
        needCancelAcknowledgement
      }
      hotelBooking {
        cancelMessage {
          severity
          code
        }
        allowChange
        allowCancel
        needCancelAcknowledgement
      }
      carBooking {
        cancelMessage {
          severity
          code
        }
        allowChange
        allowCancel
        needCancelAcknowledgement
      }
    }
  }
`
export const TripCostFragmentDoc = gql`
  fragment tripCost on TripCost {
    total {
      ...rate
    }
    base {
      ...rate
    }
    feesAndTaxes {
      ...rate
    }
    transactionFee {
      ...rate
    }
    fareTaxInfo {
      code
      name
      amount {
        ...rate
      }
    }
    passengerNumber
    ticketNumber
  }
  ${RateFragmentDoc}
`
export const PrepaidCostFragmentDoc = gql`
  fragment prepaidCost on TripDetailsPrepaidCost {
    flight {
      ...tripCost
    }
    hotel {
      ...tripCost
    }
    carRental {
      ...tripCost
    }
    train {
      ...tripCost
    }
  }
  ${TripCostFragmentDoc}
`
export const ReservedCostFragmentDoc = gql`
  fragment reservedCost on TripDetailsReservedCost {
    hotel {
      ...tripCost
    }
    carRental {
      ...tripCost
    }
  }
  ${TripCostFragmentDoc}
`
export const TripCostSummaryFragmentDoc = gql`
  fragment tripCostSummary on TripDetailsTripCostSummary {
    prepaid {
      ...prepaidCost
    }
    reserved {
      ...reservedCost
    }
    unusedTicket {
      ...rate
    }
    allowUnusedTicketOptout
    transactionFees {
      ...rate
    }
    totalServiceFees {
      ...rate
    }
    totalTaxCost {
      ...rate
    }
    totalTripBaseCost {
      ...rate
    }
    total {
      ...rate
    }
    totalWithUnusedTicket {
      ...rate
    }
    fareTaxInfo {
      code
      name
      amount {
        ...rate
      }
    }
  }
  ${PrepaidCostFragmentDoc}
  ${ReservedCostFragmentDoc}
  ${RateFragmentDoc}
`
export const MessagesFragmentDoc = gql`
  fragment messages on DynamicSiteMessage {
    header
    text
    mobileText
    isDialog
    hasDesktopBannerImage
  }
`
export const TravelerFragmentDoc = gql`
  fragment traveler on TripDetailsTraveler {
    firstName
    lastName
    middleName
    email
  }
`
export const OutOfPolicyDataFragmentDoc = gql`
  fragment outOfPolicyData on OutOfPolicyData {
    type
    data
    currency
  }
`
export const PolicyFragmentDoc = gql`
  fragment policy on Policy {
    isInPolicy
    outOfPolicyReasons
    outOfPolicyData {
      ...outOfPolicyData
    }
  }
  ${OutOfPolicyDataFragmentDoc}
`
export const AirportAddressFragmentDoc = gql`
  fragment airportAddress on AirportAddress {
    airportCode
    airportName
    address {
      ...address
    }
    airportCity
  }
  ${AddressFragmentDoc}
`
export const BaggageAllowanceCountFragmentDoc = gql`
  fragment baggageAllowanceCount on BaggageAllowanceCount {
    lower
    upper
  }
`
export const BaggageAllowanceFragmentDoc = gql`
  fragment baggageAllowance on BaggageAllowance {
    lower {
      value
      unit
    }
    upper {
      value
      unit
    }
  }
`
export const PreferenceFragmentDoc = gql`
  fragment preference on Preference {
    id
    code
    highlight
    ownerType
    ownerName
    tooltipText
    customBadge {
      name
      color
    }
    shouldShowDefaultPreference
  }
`
export const PenaltyFeeFragmentDoc = gql`
  fragment penaltyFee on PenaltyFee {
    id
    type
    rates {
      ...rate
    }
    days
    penaltyFee {
      state
      amount {
        amount
        currency
      }
      isRuleAllowed
      effectiveStartDate
      effectiveEndDate
    }
  }
  ${RateFragmentDoc}
`
export const FlightSegmentFragmentDoc = gql`
  fragment flightSegment on TripDetailsFlightLegSegment {
    checkIn {
      airlineUrl
      checkInWindowInHours
    }
    confirmationNumber
    departure {
      ...airportAddress
    }
    arrival {
      ...airportAddress
    }
    segmentId
    originTerminal
    destinationTerminal
    departureDate
    arrivalDate
    imageUrl
    images {
      square
      rectangle
    }
    name
    flightNumber
    carrierCode
    bookingCode
    flightTime {
      hours
      minutes
    }
    distance {
      value
      units
    }
    travelerSeat
    travelerId
    baggageFees {
      items {
        type
        rate {
          ...rate
        }
        baggageCount {
          ...baggageAllowanceCount
        }
        baggageWeight {
          ...baggageAllowance
        }
        baggageSize {
          ...baggageAllowance
        }
      }
      carrierUrl
    }
    isDepartAirportDifferentFromConnecting
    isSeatAvailable
    isSeatSelectable
    serviceClass
    fare
    amenities {
      codes
      values
    }
    percentageOnTime
    preferredDescription {
      ...preference
    }
    preferenceAttributes {
      ...preference
    }
    penaltyFees {
      ...penaltyFee
    }
    serviceFee {
      id
      description
      rate {
        ...money
      }
    }
    operatedBy
    mealService
    bookingProvider
    codeshareCarrier {
      name
      code
    }
    plane {
      type
      code
      onType
    }
    actualDepartureDate
    actualArrivalDate
    departureGate
    arrivalGate
    flightStatus
    baggageClaim
    trsItemId
    membership {
      id
      number
      carrierCode
    }
    specialRequests
    status
  }
  ${AirportAddressFragmentDoc}
  ${RateFragmentDoc}
  ${BaggageAllowanceCountFragmentDoc}
  ${BaggageAllowanceFragmentDoc}
  ${PreferenceFragmentDoc}
  ${PenaltyFeeFragmentDoc}
  ${MoneyFragmentDoc}
`
export const FlightFragmentDoc = gql`
  fragment flight on TripDetailsFlightLeg {
    legId
    pnrNumber
    departureDateTime
    arrivalDateTime
    stops
    flightStops
    recordLocator
    isSameCarrierName
    isRoundTripLeg
    isRoundTripCost
    traveler {
      ...traveler
    }
    flightTime {
      hours
      minutes
    }
    distance {
      value
      units
    }
    fareRestrictions
    rate {
      ...rate
    }
    taxes {
      ...rate
    }
    totalCost {
      ...rate
    }
    transactionFees {
      ...rate
    }
    unusedTicketRate {
      ...rate
    }
    policy {
      ...policy
    }
    segments {
      ...flightSegment
    }
    totalWithUnusedTicket {
      ...rate
    }
  }
  ${TravelerFragmentDoc}
  ${RateFragmentDoc}
  ${PolicyFragmentDoc}
  ${FlightSegmentFragmentDoc}
`
export const HotelFragmentDoc = gql`
  fragment hotel on TripDetailsHotelSegment {
    checkIn
    checkOut
    numberOfNights
    confirmationNumber
    imageUrl
    name
    id
    hotelId
    averageRate {
      ...rate
    }
    dailyRates {
      rate {
        ...rate
      }
      date
    }
    feesAndTaxes {
      ...money
    }
    totalCost {
      ...money
    }
    rateQualifier
    isCancellable
    rulesAndRestrictions
    preferredDescription {
      ...preference
    }
    cancellationPolicyDescription
    cancellationPolicy
    policy {
      ...policy
    }
    phone
    address {
      ...address
    }
    roomType
    roomDescription
    recordLocator
    numberOfGuests {
      adults
    }
    starRating
    source
    traveler {
      ...traveler
    }
    isReserved
    hasDeposit
    photos {
      index
      size
      url
    }
    trsItemId
    membership {
      number
      vendorName
    }
    specialRequests
    status
  }
  ${RateFragmentDoc}
  ${MoneyFragmentDoc}
  ${PreferenceFragmentDoc}
  ${PolicyFragmentDoc}
  ${AddressFragmentDoc}
  ${TravelerFragmentDoc}
`
export const VendorFragmentDoc = gql`
  fragment vendor on Vendor {
    name
    imageUrl
    code
  }
`
export const TripDetailsCarRentalInteractionFragmentDoc = gql`
  fragment tripDetailsCarRentalInteraction on TripDetailsCarRentalInteraction {
    address {
      ...address
    }
    date
    workingHours {
      range
      isAllDay
    }
    isDeliveryCollection
    phone {
      number
      type
    }
    isAirportLocation
  }
  ${AddressFragmentDoc}
`
export const CarRentalFragmentDoc = gql`
  fragment carRental on TripDetailsCarRentalSegment {
    carId
    vendor {
      ...vendor
    }
    safetyCheck {
      messageCode
      badgeUrl
      url
    }
    carClass
    carType
    fuelType
    carImageUrl
    policy {
      ...policy
    }
    carTransmission
    pickUpLocation {
      ...tripDetailsCarRentalInteraction
    }
    dropOffLocation {
      ...tripDetailsCarRentalInteraction
    }
    pickUpTime
    dropOffTime
    isCarAirConditioned
    contractName
    carCapacity
    carLuggage
    isReserved
    confirmationNumber
    isUnlimitedMileage
    recordLocator
    traveler {
      ...traveler
    }
    daysCount
    dailyRate {
      ...rate
    }
    totalBaseCost {
      ...rate
    }
    totalCost {
      ...rate
    }
    taxesAndFees {
      ...rate
    }
    serviceFee {
      rate {
        amount
        currency
      }
      id
      description
    }
    preferredDescription {
      ...preference
    }
    membership {
      number
    }
    specialRequests
    status
    extraCharge {
      perDayRate {
        ...rate
      }
      perDayCharge {
        ...rate
      }
      perHourCharge {
        ...rate
      }
    }
  }
  ${VendorFragmentDoc}
  ${PolicyFragmentDoc}
  ${TripDetailsCarRentalInteractionFragmentDoc}
  ${TravelerFragmentDoc}
  ${RateFragmentDoc}
  ${PreferenceFragmentDoc}
`
export const TicketOfficeScheduleFragmentDoc = gql`
  fragment ticketOfficeSchedule on TripDetailsTrainStationSchedule {
    closeTime
    endingOnDayOfWeek
    openTime
    startingOnDayOfWeek
  }
`
export const TrainStationFragmentDoc = gql`
  fragment trainStation on TripDetailsTrainStation {
    additionalInformation
    isBusStop
    ticketMachineAvailable
    location {
      ...address
    }
    phoneNumber
    stationCode
    stationName
    countryName
    ticketOfficeSchedule {
      ...ticketOfficeSchedule
    }
    ticketPickupInformation
  }
  ${AddressFragmentDoc}
  ${TicketOfficeScheduleFragmentDoc}
`
export const TrainSegmentFragmentDoc = gql`
  fragment trainSegment on TripDetailsTrainSegment {
    id
    arrivalDate
    arrivalStationCode
    segmentAmenities {
      code
      value
    }
    confirmationNumber
    changeDuration {
      hours
      minutes
    }
    duration {
      hours
      minutes
    }
    serviceClassName
    trainNumber
    trainId
    type
    carrierName
    carrierImage
    departureDate
    departureStationCode
    name
    stations {
      arrival {
        ...trainStation
      }
      departure {
        ...trainStation
      }
    }
    allocatedSeat {
      carriage
      seat
    }
    status
  }
  ${TrainStationFragmentDoc}
`
export const TierFragmentDoc = gql`
  fragment tier on RailFareTier {
    id
    name
  }
`
export const ConditionsFragmentDoc = gql`
  fragment conditions on Conditions {
    name
    description
  }
`
export const LocalizedFareConditionsFragmentDoc = gql`
  fragment localizedFareConditions on LocalizedFareConditions {
    fareTypeDescription
    conditions {
      ...conditions
    }
    summaryConditions
  }
  ${ConditionsFragmentDoc}
`
export const FareConditionsFragmentDoc = gql`
  fragment fareConditions on FareConditions {
    tier {
      ...tier
    }
    localizedFareConditions {
      ...localizedFareConditions
    }
  }
  ${TierFragmentDoc}
  ${LocalizedFareConditionsFragmentDoc}
`
export const TrainLegFragmentDoc = gql`
  fragment trainLeg on TripDetailsTrainLeg {
    id
    departureDateTime
    arrivalDateTime
    departureStationCode
    arrivalStationCode
    routeType
    platform
    taxes {
      ...rate
    }
    totalCost {
      ...rate
    }
    transactionFees {
      ...rate
    }
    traveler {
      ...traveler
    }
    travelTime {
      hours
      minutes
    }
    totalJourneyTime {
      hours
      minutes
    }
    travelStops
    rate {
      ...rate
    }
    segments {
      ...trainSegment
    }
    selectedDeliveryOption {
      code
      name
    }
    transactionId
    recordLocator
    collectionReferences
    fareConditions {
      ...fareConditions
    }
    permittedOriginStations {
      code
      description
    }
    permittedDestinationStations {
      code
      description
    }
  }
  ${RateFragmentDoc}
  ${TravelerFragmentDoc}
  ${TrainSegmentFragmentDoc}
  ${FareConditionsFragmentDoc}
`
export const CarServiceSegmentCarFragmentDoc = gql`
  fragment carServiceSegmentCar on TripDetailsCarServiceCar {
    class
    type
    capacity
    imageUrl
  }
`
export const CarServiceSegmentDetailsRulesFragmentDoc = gql`
  fragment carServiceSegmentDetailsRules on TripDetailsCarServiceDetailsRules {
    cancelPolicy
    chargeDisclaimer
    chargeQualifier
    generalMessage
  }
`
export const CarServiceTripCostFragmentDoc = gql`
  fragment carServiceTripCost on CarServiceTripCost {
    type
    total {
      ...rate
    }
    taxes {
      ...rate
    }
    base {
      ...rate
    }
    timeBase {
      ...rate
    }
    offHours {
      ...rate
    }
    airportFees {
      ...rate
    }
    meetAndGreet {
      ...rate
    }
    surfaceTransport {
      ...rate
    }
    parking {
      ...rate
    }
    fuelSurcharge {
      ...rate
    }
    accountDiscount {
      ...rate
    }
    rateQuote {
      ...rate
    }
    stops {
      ...rate
    }
    tolls {
      ...rate
    }
    extraService {
      ...rate
    }
    distanceBased {
      ...rate
    }
    gratuity {
      ...rate
    }
    accountService {
      ...rate
    }
    minHours
    days
  }
  ${RateFragmentDoc}
`
export const CarServiceCompanyServiceFragmentDoc = gql`
  fragment carServiceCompanyService on TripDetailsCarServiceCompanyService {
    name
    description
    logo
  }
`
export const GeocodeFragmentDoc = gql`
  fragment geocode on Geocode {
    lat
    long
  }
`
export const CarServicePickUpFragmentDoc = gql`
  fragment carServicePickUp on TripDetailsCarServicePickUp {
    time
    address {
      ...address
    }
    location {
      geocode {
        ...geocode
      }
    }
    pickupInstructions
  }
  ${AddressFragmentDoc}
  ${GeocodeFragmentDoc}
`
export const CarServiceDropOffFragmentDoc = gql`
  fragment carServiceDropOff on TripDetailsCarServiceDropOff {
    time
    address {
      ...address
    }
    location {
      geocode {
        ...geocode
      }
      asDirected
      requestedDuration
      requestedHours
      asDirectedMultiDay
    }
    dropOffInstructions
  }
  ${AddressFragmentDoc}
  ${GeocodeFragmentDoc}
`
export const CarServiceSegmentFragmentDoc = gql`
  fragment carServiceSegment on TripDetailsCarServiceSegment {
    pickUpTime
    confirmationNumber
    car {
      ...carServiceSegmentCar
    }
    detailsRules {
      ...carServiceSegmentDetailsRules
    }
    recordLocator
    policy {
      ...policy
    }
    traveler {
      ...traveler
    }
    price {
      ...carServiceTripCost
    }
    serviceCompany {
      ...carServiceCompanyService
    }
    stops {
      ...address
    }
    pickUp {
      ...carServicePickUp
    }
    dropOff {
      ...carServiceDropOff
    }
  }
  ${CarServiceSegmentCarFragmentDoc}
  ${CarServiceSegmentDetailsRulesFragmentDoc}
  ${PolicyFragmentDoc}
  ${TravelerFragmentDoc}
  ${CarServiceTripCostFragmentDoc}
  ${CarServiceCompanyServiceFragmentDoc}
  ${AddressFragmentDoc}
  ${CarServicePickUpFragmentDoc}
  ${CarServiceDropOffFragmentDoc}
`
export const RideHailSegmentFragmentDoc = gql`
  fragment rideHailSegment on TripDetailsRideHailSegment {
    bookingId
    description
    dropOffAddress
    dropOffTime
    pickUpAddress
    pickUpTime
    price {
      primary {
        amount
        currency
      }
      secondary {
        amount
        currency
      }
    }
    productName
    status
    estimatedPriceRange {
      primary {
        amountMax
        amountMin
        currency
      }
      secondary {
        amountMax
        amountMin
        currency
      }
    }
  }
`
export const SegmentsFragmentDoc = gql`
  fragment segments on TripDetailsSegment {
    position
    type
    flightLeg {
      ...flight
    }
    hotel {
      ...hotel
    }
    carRental {
      ...carRental
    }
    trainLeg {
      ...trainLeg
    }
    carService {
      ...carServiceSegment
    }
    rideHail {
      ...rideHailSegment
    }
  }
  ${FlightFragmentDoc}
  ${HotelFragmentDoc}
  ${CarRentalFragmentDoc}
  ${TrainLegFragmentDoc}
  ${CarServiceSegmentFragmentDoc}
  ${RideHailSegmentFragmentDoc}
`
export const CostSummaryFragmentDoc = gql`
  fragment costSummary on TripDetailsCostSummary {
    carService {
      cost {
        ...rate
      }
      tax {
        ...rate
      }
      total {
        ...rate
      }
      isPaid
      carServiceCharges {
        accountDiscount {
          ...rate
        }
        accountServiceCharge {
          ...rate
        }
        airPortFees {
          ...rate
        }
        extraServiceCharge {
          ...rate
        }
        fuelSurcharge {
          ...rate
        }
        gratuity {
          ...rate
        }
        offHoursCharge {
          ...rate
        }
        parkingAmount {
          ...rate
        }
        stopCharge {
          ...rate
        }
      }
    }
    train {
      trainList {
        origin
        destination
        cost {
          ...rate
        }
        tax {
          ...rate
        }
        total {
          ...rate
        }
        isPaid
      }
      total {
        ...rate
      }
      fare {
        total {
          ...rate
        }
        base {
          ...rate
        }
        transactionFee {
          ...rate
        }
        serviceFee {
          ...rate
        }
        paymentFee {
          ...rate
        }
        tax {
          ...rate
        }
      }
    }
    flight {
      flightList {
        origin
        destination
        isSameAirline
        cost {
          ...rate
        }
        tax {
          ...rate
        }
        total {
          ...rate
        }
        isPaid
        unusedTicketRate {
          ...rate
        }
      }
      total {
        ...rate
      }
    }
    hotel {
      name
      itemCount
      cost {
        ...rate
      }
      tax {
        ...rate
      }
      total {
        ...rate
      }
      isPaid
    }
    carRental {
      vendorName
      itemCount
      cost {
        ...rate
      }
      tax {
        ...rate
      }
      total {
        ...rate
      }
      isPaid
    }
    totalCost {
      ...rate
    }
  }
  ${RateFragmentDoc}
`
export const TicketSegmentFragmentDoc = gql`
  fragment ticketSegment on TicketSegment {
    departTime
    carrierCode
    flightNumber
    origin
    destination
    serviceClass
    fareBasis
    status
  }
`
export const FareTaxItemFragmentDoc = gql`
  fragment fareTaxItem on FareTaxItemDto {
    taxAmount {
      ...rate
    }
    taxCode
    taxLabel
  }
  ${RateFragmentDoc}
`
export const AirTicketsFragmentDoc = gql`
  fragment airTickets on AirTicket {
    ticketNumber
    travelerName
    ticketSegmentInfo {
      ...ticketSegment
    }
    issuingAgent
    iataNumber
    formOfPayment
    endorsement
    fareLinear
    fareTaxInfo {
      ...fareTaxItem
    }
    baseFare {
      ...rate
    }
    totalFare {
      ...rate
    }
    penalty {
      ...rate
    }
    issuingAirline
    isTicketRefundable
    isPenaltyValidated
    isPenaltyPayableFromResidual
    isExchangeForRefundableAllowed
  }
  ${TicketSegmentFragmentDoc}
  ${FareTaxItemFragmentDoc}
  ${RateFragmentDoc}
`
export const TripFragmentDoc = gql`
  fragment trip on OrderDetails {
    appliedRailCards
    isNDCTrip
    cityImageUrl
    processId
    tripId
    type
    recordLocator
    creationTime
    cancellationTime
    holdRules {
      autoCancellationTime
      holdAllowed
      reasonsForNotAllowing
    }
    supportedActionsV2 {
      ...supportedActionsV2
    }
    tripName
    startDate
    rollUpStartTime
    rollUpEndTime
    flightId
    isMultiCity
    agencyName
    agencyInfo {
      agencyName
      agencyPhoneNumber
      agencyMessage
    }
    tripCost {
      ...tripCostSummary
    }
    messages {
      ...messages
    }
    segments {
      ...segments
    }
    costSummary {
      ...costSummary
    }
    airTickets {
      ...airTickets
    }
    billingInfo {
      id
      payment {
        creditCard {
          label
        }
        siteCard {
          label
        }
      }
    }
    meetingInfo {
      name
      location
      dates {
        startDate
        endDate
      }
    }
  }
  ${SupportedActionsV2FragmentDoc}
  ${TripCostSummaryFragmentDoc}
  ${MessagesFragmentDoc}
  ${SegmentsFragmentDoc}
  ${CostSummaryFragmentDoc}
  ${AirTicketsFragmentDoc}
`
export const CartSummaryFragmentDoc = gql`
  fragment cartSummary on CartSummary {
    startDate
    endDate
    cartCost {
      total {
        ...rate
      }
      payLater {
        domainCosts {
          totalTaxes {
            ...rate
          }
          totalRate {
            ...rate
          }
          totalCost {
            ...rate
          }
          domain
        }
        subTotal {
          ...rate
        }
      }
    }
  }
  ${RateFragmentDoc}
`
export const TravelTimeFragmentDoc = gql`
  fragment travelTime on TravelTime {
    hours
    minutes
  }
`
export const LuggageOptionFragmentDoc = gql`
  fragment luggageOption on LuggageOption {
    code
    value
  }
`
export const FareLabelFragmentDoc = gql`
  fragment fareLabel on FareLabel {
    text
    tooltip
  }
`
export const BaggageFeesFragmentDoc = gql`
  fragment baggageFees on BaggageFees {
    items {
      type
      rate {
        ...rate
      }
      baggageCount {
        ...baggageAllowanceCount
      }
      baggageWeight {
        ...baggageAllowance
      }
      baggageSize {
        ...baggageAllowance
      }
    }
    carrierUrl
  }
  ${RateFragmentDoc}
  ${BaggageAllowanceCountFragmentDoc}
  ${BaggageAllowanceFragmentDoc}
`
export const FlightLegSegmentFragmentDoc = gql`
  fragment flightLegSegment on FlightLegSegmentResult {
    segmentId
    flightTime {
      ...travelTime
    }
    arrivalDate
    departureDate
    departure {
      ...airportAddress
    }
    arrival {
      ...airportAddress
    }
    name
    flightNumber
    carrierCode
    bookingCode
    baggageFees {
      ...baggageFees
    }
    amenities {
      codes
      values
    }
    fare
    mealService
    bookingProvider
    aircraft {
      type
      code
      onTime
    }
    preferredDescription {
      ...preference
    }
    penaltyFees {
      ...penaltyFee
    }
    serviceFee {
      rate {
        ...money
      }
      description
      id
    }
    operatedBy
    originTerminal
    destinationTerminal
    percentageOnTime
    codeshareCarrier {
      name
      code
    }
    preferenceAttributes {
      ...preference
    }
    isDepartAirportDifferentFromConnecting
    serviceClass
  }
  ${TravelTimeFragmentDoc}
  ${AirportAddressFragmentDoc}
  ${BaggageFeesFragmentDoc}
  ${PreferenceFragmentDoc}
  ${PenaltyFeeFragmentDoc}
  ${MoneyFragmentDoc}
`
export const FlightDomainFragmentDoc = gql`
  fragment flightDomain on FlightTripResult {
    __typename
    flightLegs {
      legId
      flightTime {
        ...travelTime
      }
      totalTime {
        ...travelTime
      }
      luggageOptions {
        ...luggageOption
      }
      stops
      policy {
        ...policy
      }
      rate {
        ...rate
      }
      baseRate {
        ...rate
      }
      feesAndTaxesRate {
        ...rate
      }
      unusedTicketRate {
        ...rate
      }
      totalCostRate {
        ...rate
      }
      departureDateTime
      arrivalDateTime
      fareRules
      fareLabel {
        ...fareLabel
      }
      fareAttributes
      isReserved
      isRoundTripCost
      isRoundTripLeg
      isSameCarrierName
      segments {
        ...flightLegSegment
      }
    }
  }
  ${TravelTimeFragmentDoc}
  ${LuggageOptionFragmentDoc}
  ${PolicyFragmentDoc}
  ${RateFragmentDoc}
  ${FareLabelFragmentDoc}
  ${FlightLegSegmentFragmentDoc}
`
export const DistanceFragmentDoc = gql`
  fragment distance on Distance {
    value
    units
  }
`
export const RoomFragmentDoc = gql`
  fragment room on Room {
    id
    type
    description
    inventoryId
    totalCost {
      ...money
    }
    feesAndTaxes {
      ...money
    }
    feesAndTaxes {
      ...money
    }
    averageRate {
      primary {
        ...money
      }
      secondary {
        ...money
      }
    }
    baseRate {
      primary {
        ...money
      }
      secondary {
        ...money
      }
    }
    rate {
      primary {
        ...money
      }
      secondary {
        ...money
      }
    }
    isCorporateRate
    dailyRates {
      rate {
        primary {
          ...money
        }
        secondary {
          ...money
        }
      }
      date
    }
    isFreeCancellable
    policy {
      ...policy
    }
    source
    isSelectable
    cancellationPolicy
    cancellationPolicyDescription
    preferredTag
    rateQualifier
    numberOfGuests
  }
  ${MoneyFragmentDoc}
  ${PolicyFragmentDoc}
`
export const PhotoFragmentDoc = gql`
  fragment photo on Photo {
    index
    url
    size
  }
`
export const AmenityFragmentDoc = gql`
  fragment amenity on Amenity {
    name
    isOfProperty
    isOfRoom
  }
`
export const HotelDomainFragmentDoc = gql`
  fragment hotelDomain on HotelRoomDetails {
    __typename
    hotelId
    name
    checkIn
    checkOut
    address {
      ...address
    }
    distance {
      ...distance
    }
    starRating
    medianReviewRating
    selectedRoom {
      ...room
    }
    phoneNumber
    chainCode
    logoPhotoUrl
    isPreferred
    hasDeposit
    isCancellable
    preference {
      ...preference
    }
    policy {
      ...policy
    }
    photos {
      ...photo
    }
    isEcoFriendly
    notes
    amenities {
      ...amenity
    }
    exclusiveAmenities {
      ...amenity
    }
  }
  ${AddressFragmentDoc}
  ${DistanceFragmentDoc}
  ${RoomFragmentDoc}
  ${PreferenceFragmentDoc}
  ${PolicyFragmentDoc}
  ${PhotoFragmentDoc}
  ${AmenityFragmentDoc}
`
export const CustomBadgeFragmentDoc = gql`
  fragment customBadge on CustomBadge {
    name
    color
  }
`
export const OpeningHoursFragmentDoc = gql`
  fragment openingHours on CarRentalDailyOpeningHours {
    dayOfWeek
    times {
      closingTime
      openingTime
    }
  }
`
export const CarRentalLocationDetailsFragmentDoc = gql`
  fragment carRentalLocationDetails on CarRentalLocationDetails {
    isAirport
    locationId
    phone
    locationAddress {
      ...address
    }
    openingHours {
      ...openingHours
    }
  }
  ${AddressFragmentDoc}
  ${OpeningHoursFragmentDoc}
`
export const CarRentalDomainFragmentDoc = gql`
  fragment carRentalDomain on CarRental {
    __typename
    dropOffDate
    dropOffTime
    key
    pickupDate
    pickupTime
    dropOffLocation {
      ...carRentalLocationDetails
    }
    pickupLocation {
      ...carRentalLocationDetails
    }
    rate {
      dailyRate {
        ...rate
      }
      extraDayCharge {
        ...rate
      }
      extraHourCharge {
        ...rate
      }
      totalCost {
        ...rate
      }
      totalRate {
        ...rate
      }
      totalTaxes {
        ...rate
      }
      daysCharged
      distanceUnlimited
      rateCode
    }
    vendor {
      code
      imageUrl
      name
    }
    vehicle {
      baggageCapacity
      category
      fuelType
      isAirCondition
      modelName
      passengerCapacity
      transmissionType
      vehicleClass
      imageUrl
    }
    termsAndConditions {
      header
      value
    }
    safetyCheck {
      badgeUrl
      messageCode
      url
    }
  }
  ${CarRentalLocationDetailsFragmentDoc}
  ${RateFragmentDoc}
`
export const CartCostSummaryFragmentDoc = gql`
  fragment cartCostSummary on CostSummary {
    subTotal {
      ...rate
    }
    subTotal {
      ...rate
    }
    intentCosts {
      domain
      baseCost {
        ...rate
      }
      taxes {
        ...rate
      }
      serviceFee {
        ...rate
      }
      transactionFee {
        ...rate
      }
      totalCost {
        ...rate
      }
      passengerCount
    }
  }
  ${RateFragmentDoc}
`
export const ItineraryMetadataFragmentDoc = gql`
  fragment itineraryMetadata on ItineraryMetadata {
    alignAttemptedSegmentTypes
    hotelMatchDetails {
      matchFound
      isExactMatch
      isRoomTypeDifferent
      isRoomRateDifferent
    }
    hotelSearch {
      address {
        ...address
      }
      checkIn
      checkOut
      hotelKey
      hotelName
    }
    carRentalMatchDetails {
      matchFound
      isExactMatch
    }
    carRentalSearch {
      dropOff {
        address {
          ...address
        }
        airportCode
        airportLocation
        dateTime
        locationId
      }
      pickUp {
        address {
          ...address
        }
        airportCode
        airportLocation
        dateTime
        locationId
      }
    }
    flightSearch {
      originAirportCode
      destinationAirportCode
      departureDate
      serviceClass
    }
    flightMatchDetails {
      matchFound
      isExactMatch
      isFlightTimeMatch
      isFlightTimeWithinThreshold
      isFlightAirlineMatch
    }
  }
  ${AddressFragmentDoc}
`
export const ItineraryFragmentDoc = gql`
  fragment itinerary on Itinerary {
    itineraryId
    expiredAt
    flightId
    isMultiCity
    isTripAligned
    isMultiDestination
    isOnHold
    virtualPay {
      isVirtualPayEnabled
      isVirtualPayRestricted
    }
    availableSegments {
      canAddHotel
      canAddFlight
      canAddCarRental
    }
    tripStatus {
      isChangeTripAllowed
      isHoldTripAllowed
      areSegmentsRemovable
      areSegmentsChangeable
      areSegmentsCustomizable
      areExtraSegmentsAllowed
      areTicketOptionsAllowed
    }
    trip {
      name
      startDate
      endDate
      isNameEditable
      imageUrl
    }
    tripCost {
      payNow {
        hotel {
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
        }
        flight {
          base {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          transactionFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          fareTaxInfo {
            code
            name
            amount {
              primary {
                currency
                amount
              }
              secondary {
                currency
                amount
              }
            }
          }
          passengerNumber
          ticketNumber
        }
        train {
          base {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          transactionFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
        }
        car {
          base {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
        }
        subTotal {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
      }
      payLater {
        hotel {
          base {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
        }
        car {
          base {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          total {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          feesAndTaxes {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
          serviceFee {
            primary {
              currency
              amount
            }
            secondary {
              currency
              amount
            }
          }
        }
        carServices {
          type
          total {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          base {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          timeBase {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          offHours {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          airportFees {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          meetAndGreet {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          surfaceTransport {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          parking {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          fuelSurcharge {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          accountDiscount {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          rateQuote {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          stops {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          tolls {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          extraService {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          distanceBased {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          gratuity {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          taxes {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          accountService {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          minHours
          days
        }
        subTotal {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
      }
      unusedTicket {
        status
        unvalidated {
          original {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          penalty {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          newTicket {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          exchangedTicket {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          residual {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          addCollect {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          total {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          forfeiture {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
        }
        validated {
          original {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          penalty {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          newTicket {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          exchangedTicket {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          residual {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          addCollect {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          total {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          forfeiture {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
        }
      }
      totalWithUnusedTicket {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      total {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      fareTaxInfo {
        code
        name
        amount {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
      }
      allowUnusedTicketOptout
      hasOptOutUnusedTickets
    }
    itineraryItemResponse {
      code
      fieldName
      severity
    }
    messages {
      text
      header
      isDialog
      hasDesktopBannerImage
    }
    holdRules {
      autoCancellationTime
      reasonsForNotAllowing
      holdAllowed
    }
    segments {
      uuid
      position
      type
      hotel {
        alignedWithFlightDate
        name
        checkIn
        checkOut
        segmentState
        totalCost {
          amount
          currency
        }
        averageRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        baseRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        totalCostRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        feesAndTaxesRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        dailyRates {
          rate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          date
        }
        netRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        rateQualifier
        imageUrl
        id
        roomType
        hasDeposit
        source
        isReserved
        address {
          airportCode
          locationName
          street1
          street2
          city
          stateCode
          postalCode
          countryCode
          geocode {
            lat
            long
          }
        }
        numberOfGuests {
          adults
        }
        rulesAndRestrictions
        isCancellable
        starRating
        amenities {
          name
          isOfProperty
          isOfRoom
        }
        exclusiveAmenities {
          name
          isOfProperty
          isOfRoom
        }
        photos {
          index
          size
          url
        }
        cancellationPolicy
        cancellationPolicyDescription
        preferredDescription {
          id
          code
          highlight
          ownerType
          ownerName
          tooltipText
          customBadge {
            name
            color
          }
          shouldShowDefaultPreference
        }
        policy {
          outOfPolicyReasons
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyComplianceCodes {
            code
            val
            description
          }
        }
        roomDescription
        roomCount
        medianReviewRating
        distance {
          value
          units
        }
        isPreferred
        isEcoFriendly
        phone
        hotelId
        source
        vendorCode
        serviceFeeRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        alignState
      }
      carService {
        carId
        preferredDescription {
          name
          type
          code
          bold
          customColor
          customName
        }
        preferenceAttributes {
          id
          code
          highlight
          ownerType
          ownerName
          tooltipText
          customBadge {
            name
            color
          }
          shouldShowDefaultPreference
        }
        key
        noRateText
        rateType
        name
        stops {
          airportCode
          locationName
          street1
          street2
          city
          stateCode
          postalCode
          countryCode
          geocode {
            lat
            long
          }
        }
        pickUp {
          time
          location {
            flight {
              airportName
              isCommercial
              carrierCode
              flightNumber
              dateTime
              airportCode
            }
            geocode {
              lat
              long
            }
          }
          address {
            airportCode
            locationName
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
            geocode {
              lat
              long
            }
          }
        }
        dropOff {
          asDirected
          time
          location {
            asDirected
            requestedHours
            requestedDuration
            asDirectedMultiDay
            flight {
              airportName
              isCommercial
              carrierCode
              flightNumber
              dateTime
              airportCode
            }
            geocode {
              lat
              long
            }
          }
          address {
            airportCode
            locationName
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
            geocode {
              lat
              long
            }
          }
        }
        numberOfPassengers
        chargeDisclaimer
        cancellationPolicy
        isReserved
        duration
        provider {
          logo
          name
        }
        polylineGeocodes {
          lat
          long
        }
        policy {
          outOfPolicyReasons
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
        }
        car {
          capacity
          imageUrl
          description
          type
        }
        logo
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
      }
      carRental {
        carId
        name
        segmentState
        alignedWithFlightDate
        alignedWithFlightTime
        carDetails {
          imageUrl
          class
          type
          transmission
          fuel
          capacity
          luggage
          isAirConditioned
          contractName
        }
        vendorDetails {
          imageUrl
          code
          name
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        baseRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        totalCostRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        feesAndTaxesRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        netRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        serviceFeeRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        imageUrl
        carImageUrl
        vendorImage
        carClass
        carType
        carTransmission
        vendor
        vendorCode
        isCarAirConditioned
        contractName
        carCapacity
        carLuggage
        isReserved
        serviceFee {
          rate {
            amount
            currency
          }
          id
          description
        }
        carLocationType {
          pickup {
            type
            code
            name
          }
          dropoff {
            type
            code
            name
          }
        }
        policy {
          outOfPolicyReasons
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyComplianceCodes {
            code
            val
            description
          }
        }
        preferredDescription {
          name
          type
          code
          bold
          customColor
          customName
        }
        carCosts {
          extraDay {
            amount
            currency
          }
          extraHour {
            amount
            currency
          }
          perDay {
            amount
            currency
          }
          perWeek {
            amount
            currency
          }
          isMileageUnlimited
        }
        carCostRates {
          extraDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          extraHourRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perWeekRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          isMileageUnlimited
        }
        pickUpLocation {
          date
          phone {
            type
            number
          }
          isDeliveryCollection
          workingHours {
            range
            isAllDay
          }
          address {
            airportCode
            locationName
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
            geocode {
              lat
              long
            }
          }
        }
        dropOffLocation {
          date
          phone {
            type
            number
          }
          isDeliveryCollection
          workingHours {
            range
            isAllDay
          }
          address {
            airportCode
            locationName
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
            geocode {
              lat
              long
            }
          }
        }
        pickUpTime
        dropOffTime
        safetyCheck {
          messageCode
          badgeUrl
          url
        }
        pickupLocationId
        dropoffLocationId
        isPreferred
        alignState
      }
      trainLeg {
        legId
        departureDateTime
        arrivalDateTime
        rulesAndRestrictions
        departureStationCode
        arrivalStationCode
        bookingFees {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        deliveryFees {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        deliveryOption {
          name
          code
        }
        fareConditions {
          tier {
            id
            name
          }
          localizedFareConditions {
            fareTypeDescription
            conditions {
              name
              description
            }
            summaryConditions
            language
            validityPeriod
          }
        }
        travelTime {
          hours
          minutes
        }
        travelStops
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        taxes {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        transactionFees {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        totalCost {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        policy {
          outOfPolicyReasons
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyComplianceCodes {
            code
            val
            description
          }
        }
        penaltyFees {
          id
          type
          days
          rates {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          penaltyFee {
            state
            amount {
              currency
              amount
            }
            isRuleAllowed
            effectiveStartDate
            effectiveEndDate
          }
        }
        imageUrl
        name
        isReserved
        tier {
          name
          description
        }
        isRefundable
        isEticket
        seatsRequested
        segments {
          allocatedSeats {
            seat
            carriage
          }
          segmentId
          imageUrl
          marketingServiceName
          type
          transportNumber
          trainId
          serviceClassName
          carrierCode
          carrierName
          service
          trainData
          stops {
            departingTime
            stationName
          }
          changeDuration {
            minutes
            hours
          }
          travelTime {
            minutes
            hours
          }
          segmentComment
          departureDate
          arrivalDate
          amenities {
            codes
            values
          }
          arrival {
            ticketMachineAvailable
            stationCode
            stationName
            phoneNumber
            address {
              airportCode
              locationName
              geocode {
                lat
                long
              }
              street1
              street2
              city
              stateCode
              postalCode
              countryCode
            }
          }
          departure {
            ticketMachineAvailable
            stationCode
            stationName
            phoneNumber
            address {
              airportCode
              locationName
              geocode {
                lat
                long
              }
              street1
              street2
              city
              stateCode
              postalCode
              countryCode
            }
          }
          carrierLogoUrl
          transportationMode
        }
        platform
        ecoInfo
        segmentState
        appliedRailCards
        routeType
      }
      flightLeg {
        departureDateTime
        segmentState
        arrivalDateTime
        legId
        luggageOptions {
          code
          value
          cost {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
        }
        flightTime {
          hours
          minutes
        }
        totalTime {
          hours
          minutes
        }
        flightStops
        stops
        fareRules
        fareAttributes
        fareLabel {
          text
          tooltip
        }
        isNDCFare
        isReserved
        isSameCarrierName
        isRoundTripLeg
        isRoundTripCost
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        baseRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        netRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        totalCostRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        feesAndTaxesRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        unusedTicketRate {
          primary {
            currency
            amount
          }
          secondary {
            currency
            amount
          }
        }
        policy {
          outOfPolicyReasons
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyComplianceCodes {
            code
            val
            description
          }
        }
        segments {
          segmentId
          flightTime {
            hours
            minutes
          }
          name
          flightNumber
          travelerSeat
          travelerId
          carrierCode
          bookingCode
          departureDate
          arrivalDate
          imageUrl
          images {
            square
            rectangle
          }
          serviceFee {
            id
            description
            rate {
              amount
              currency
            }
          }
          penaltyFees {
            id
            type
            days
            penaltyFee {
              state
              amount {
                amount
                currency
              }
              isRuleAllowed
              effectiveStartDate
              effectiveEndDate
            }
            rates {
              primary {
                amount
                currency
              }
              secondary {
                amount
                currency
              }
            }
          }
          preferredDescription {
            id
            code
            highlight
            ownerType
            ownerName
            tooltipText
            customBadge {
              name
              color
            }
            shouldShowDefaultPreference
          }
          preferenceAttributes {
            id
            code
            highlight
            ownerType
            ownerName
            tooltipText
            customBadge {
              name
              color
            }
            shouldShowDefaultPreference
          }
          baggageFees {
            carrierUrl
            items {
              type
              rate {
                primary {
                  currency
                  amount
                }
                secondary {
                  currency
                  amount
                }
              }
              baggageSize {
                lower {
                  unit
                  value
                }
                upper {
                  unit
                  value
                }
              }
              baggageCount {
                lower
                upper
              }
              baggageWeight {
                lower {
                  unit
                  value
                }
                upper {
                  unit
                  value
                }
              }
            }
          }
          operatedBy
          amenities {
            codes
            values
          }
          isSeatAvailable
          isSeatSelectable
          isDepartAirportDifferentFromConnecting
          isInstantPurchaseCarrier
          serviceClass
          mealService
          bookingProvider
          originTerminal
          destinationTerminal
          percentageOnTime
          codeshareCarrier {
            name
            code
          }
          plane {
            type
            onTime
            code
          }
          aircraft {
            type
            onTime
            code
          }
          fare
          departure {
            airportCode
            airportName
            airportCity
            address {
              airportCode
              stateCode
              postalCode
              street1
              street2
              city
              countryCode
              locationName
              geocode {
                lat
                long
              }
            }
          }
          arrival {
            airportCode
            airportName
            address {
              airportCode
              stateCode
              postalCode
              street1
              street2
              city
              countryCode
              locationName
              geocode {
                lat
                long
              }
            }
          }
        }
      }
    }
    complianceMessage {
      errors
      infos
      warns
      countryRestrictions
    }
    continuityMessage {
      code
      intent
      data
      followUpMessages
      segmentId
    }
    overlappingTrips {
      name
      id
    }
    airPriceChangeInfo {
      amount {
        amount
        currency
      }
      type
      reason
    }
    carPriceChangeInfo {
      amount {
        amount
        currency
      }
      type
      reason
    }
    priceChanges {
      air {
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        type
        reason
      }
      car {
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        type
        reason
      }
    }
    hotelLocationInformation {
      location {
        airportCode
        locationName
        geocode {
          lat
          long
        }
        street1
        street2
        city
        stateCode
        postalCode
        countryCode
      }
      phoneNumber {
        countryCode
        areaCode
        number
        type
        formattedNumber
      }
      faxNumber {
        countryCode
        areaCode
        number
        type
        formattedNumber
      }
      id
      name
      chainCode
      email
      additionalInformation
    }
    tripLevelPolicy {
      outOfPolicyReasons
      isInPolicy
      outOfPolicyData {
        type
        data
        currency
      }
      outOfPolicyComplianceCodes {
        code
        val
        description
      }
    }
    railCards {
      code
      groupName
      name
      vendorCode
      vendorName
    }
  }
`
export const MoneyTicketsFragmentDoc = gql`
  fragment moneyTickets on Money {
    amount
    currency
  }
`
export const RateTicketsFragmentDoc = gql`
  fragment rateTickets on Rate {
    primary {
      ...moneyTickets
    }
    secondary {
      ...moneyTickets
    }
  }
  ${MoneyTicketsFragmentDoc}
`
export const TextAdditionalInformationFragmentDoc = gql`
  fragment textAdditionalInformation on TextAdditionalInformation {
    __typename
    singleLine
    numbersOfLine
    minLength
    maxLength
    defaultText
    textValue
  }
`
export const NumberAdditionalInformationFragmentDoc = gql`
  fragment numberAdditionalInformation on NumberAdditionalInformation {
    __typename
    defaultNumber
    minNumber
    maxNumber
    numberDecimal
    numberType
    numberValue
  }
`
export const CheckBoxAdditionalInformationFragmentDoc = gql`
  fragment checkBoxAdditionalInformation on CheckBoxAdditionalInformation {
    __typename
    defaultChecked
    checkedRequired
    checkBoxValue
  }
`
export const ListAdditionalInformationFragmentDoc = gql`
  fragment listAdditionalInformation on ListAdditionalInformation {
    __typename
    listType
    listAlignment
    defaultValue
    isAlphabeticallySorted
    value
    options {
      name
      value
    }
  }
`
export const DateTimeAdditionalInformationFragmentDoc = gql`
  fragment dateTimeAdditionalInformation on DateTimeAdditionalInformation {
    __typename
    isAllowedPastDateTime
    displayYear
    displayMonth
    displayDay
    displayHour
    displayMinute
    displayTimeZone
    minYear
    maxYear
    minMonth
    maxMonth
    minDay
    maxDay
    dateValue
  }
`
export const AdditionalInformationFragmentDoc = gql`
  fragment additionalInformation on AdditionalInformation {
    __typename
    id
    label
    name
    mandatory
    fieldType
    tooltip
    additionalFieldProperties {
      ...textAdditionalInformation
      ...numberAdditionalInformation
      ...checkBoxAdditionalInformation
      ...listAdditionalInformation
      ...dateTimeAdditionalInformation
    }
  }
  ${TextAdditionalInformationFragmentDoc}
  ${NumberAdditionalInformationFragmentDoc}
  ${CheckBoxAdditionalInformationFragmentDoc}
  ${ListAdditionalInformationFragmentDoc}
  ${DateTimeAdditionalInformationFragmentDoc}
`
export const AckNotificationEventDocument = gql`
  mutation ackNotificationEvent($input: AckNotificationEventInput!) {
    ackNotificationEvent(input: $input) {
      code
      errorCodes
      message
      success
    }
  }
`
export type AckNotificationEventMutationFn = ApolloReactCommon.MutationFunction<
  AckNotificationEventMutation,
  AckNotificationEventMutationVariables
>

/**
 * __useAckNotificationEventMutation__
 *
 * To run a mutation, you first call `useAckNotificationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAckNotificationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ackNotificationEventMutation, { data, loading, error }] = useAckNotificationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAckNotificationEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AckNotificationEventMutation,
    AckNotificationEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    AckNotificationEventMutation,
    AckNotificationEventMutationVariables
  >(AckNotificationEventDocument, options)
}
export type AckNotificationEventMutationHookResult = ReturnType<
  typeof useAckNotificationEventMutation
>
export type AckNotificationEventMutationResult = ApolloReactCommon.MutationResult<AckNotificationEventMutation>
export type AckNotificationEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AckNotificationEventMutation,
  AckNotificationEventMutationVariables
>
export const AddCarServiceDocument = gql`
  mutation addCarService($itineraryId: String!, $key: String!) {
    addCarService(input: { key: $key, itineraryId: $itineraryId }) {
      success
      message
      code
    }
  }
`
export type AddCarServiceMutationFn = ApolloReactCommon.MutationFunction<
  AddCarServiceMutation,
  AddCarServiceMutationVariables
>

/**
 * __useAddCarServiceMutation__
 *
 * To run a mutation, you first call `useAddCarServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCarServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCarServiceMutation, { data, loading, error }] = useAddCarServiceMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAddCarServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCarServiceMutation,
    AddCarServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddCarServiceMutation, AddCarServiceMutationVariables>(
    AddCarServiceDocument,
    options,
  )
}
export type AddCarServiceMutationHookResult = ReturnType<typeof useAddCarServiceMutation>
export type AddCarServiceMutationResult = ApolloReactCommon.MutationResult<AddCarServiceMutation>
export type AddCarServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCarServiceMutation,
  AddCarServiceMutationVariables
>
export const AddCreditCardDocument = gql`
  mutation addCreditCard($input: AddCreditCardInput!) {
    addCreditCard(input: $input) {
      success
    }
  }
`
export type AddCreditCardMutationFn = ApolloReactCommon.MutationFunction<
  AddCreditCardMutation,
  AddCreditCardMutationVariables
>

/**
 * __useAddCreditCardMutation__
 *
 * To run a mutation, you first call `useAddCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditCardMutation, { data, loading, error }] = useAddCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreditCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCreditCardMutation,
    AddCreditCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddCreditCardMutation, AddCreditCardMutationVariables>(
    AddCreditCardDocument,
    options,
  )
}
export type AddCreditCardMutationHookResult = ReturnType<typeof useAddCreditCardMutation>
export type AddCreditCardMutationResult = ApolloReactCommon.MutationResult<AddCreditCardMutation>
export type AddCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCreditCardMutation,
  AddCreditCardMutationVariables
>
export const AddSeatAsssignmentDocument = gql`
  mutation addSeatAsssignment($input: AddSeatAssignmentInput!) {
    addSeatAssignment(input: $input) {
      success
      message
      code
    }
  }
`
export type AddSeatAsssignmentMutationFn = ApolloReactCommon.MutationFunction<
  AddSeatAsssignmentMutation,
  AddSeatAsssignmentMutationVariables
>

/**
 * __useAddSeatAsssignmentMutation__
 *
 * To run a mutation, you first call `useAddSeatAsssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSeatAsssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSeatAsssignmentMutation, { data, loading, error }] = useAddSeatAsssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSeatAsssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSeatAsssignmentMutation,
    AddSeatAsssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    AddSeatAsssignmentMutation,
    AddSeatAsssignmentMutationVariables
  >(AddSeatAsssignmentDocument, options)
}
export type AddSeatAsssignmentMutationHookResult = ReturnType<typeof useAddSeatAsssignmentMutation>
export type AddSeatAsssignmentMutationResult = ApolloReactCommon.MutationResult<AddSeatAsssignmentMutation>
export type AddSeatAsssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSeatAsssignmentMutation,
  AddSeatAsssignmentMutationVariables
>
export const BookRailItineraryDocument = gql`
  mutation bookRailItinerary($input: BookItineraryInput!) {
    bookRailItinerary(input: $input) {
      success
      errorCodes
    }
  }
`
export type BookRailItineraryMutationFn = ApolloReactCommon.MutationFunction<
  BookRailItineraryMutation,
  BookRailItineraryMutationVariables
>

/**
 * __useBookRailItineraryMutation__
 *
 * To run a mutation, you first call `useBookRailItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookRailItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookRailItineraryMutation, { data, loading, error }] = useBookRailItineraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookRailItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BookRailItineraryMutation,
    BookRailItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    BookRailItineraryMutation,
    BookRailItineraryMutationVariables
  >(BookRailItineraryDocument, options)
}
export type BookRailItineraryMutationHookResult = ReturnType<typeof useBookRailItineraryMutation>
export type BookRailItineraryMutationResult = ApolloReactCommon.MutationResult<BookRailItineraryMutation>
export type BookRailItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BookRailItineraryMutation,
  BookRailItineraryMutationVariables
>
export const BookRideHailWithErrorDocument = gql`
  mutation bookRideHailWithError($input: BookRideHailRequest!) {
    bookRideHailWithError(input: $input) {
      __typename
      ... on BookRideHailResponse {
        bookingID
        bookingInfo {
          ...bookingInfo
        }
      }
      ... on BookRideHailErrorResponse {
        reason
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
  ${BookingInfoFragmentDoc}
`
export type BookRideHailWithErrorMutationFn = ApolloReactCommon.MutationFunction<
  BookRideHailWithErrorMutation,
  BookRideHailWithErrorMutationVariables
>

/**
 * __useBookRideHailWithErrorMutation__
 *
 * To run a mutation, you first call `useBookRideHailWithErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookRideHailWithErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookRideHailWithErrorMutation, { data, loading, error }] = useBookRideHailWithErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookRideHailWithErrorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BookRideHailWithErrorMutation,
    BookRideHailWithErrorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    BookRideHailWithErrorMutation,
    BookRideHailWithErrorMutationVariables
  >(BookRideHailWithErrorDocument, options)
}
export type BookRideHailWithErrorMutationHookResult = ReturnType<
  typeof useBookRideHailWithErrorMutation
>
export type BookRideHailWithErrorMutationResult = ApolloReactCommon.MutationResult<BookRideHailWithErrorMutation>
export type BookRideHailWithErrorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BookRideHailWithErrorMutation,
  BookRideHailWithErrorMutationVariables
>
export const ClearDomainRcSessionCookieDocument = gql`
  mutation ClearDomainRcSessionCookie {
    clearDomainRcSessionCookie
  }
`
export type ClearDomainRcSessionCookieMutationFn = ApolloReactCommon.MutationFunction<
  ClearDomainRcSessionCookieMutation,
  ClearDomainRcSessionCookieMutationVariables
>

/**
 * __useClearDomainRcSessionCookieMutation__
 *
 * To run a mutation, you first call `useClearDomainRcSessionCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDomainRcSessionCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDomainRcSessionCookieMutation, { data, loading, error }] = useClearDomainRcSessionCookieMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearDomainRcSessionCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearDomainRcSessionCookieMutation,
    ClearDomainRcSessionCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ClearDomainRcSessionCookieMutation,
    ClearDomainRcSessionCookieMutationVariables
  >(ClearDomainRcSessionCookieDocument, options)
}
export type ClearDomainRcSessionCookieMutationHookResult = ReturnType<
  typeof useClearDomainRcSessionCookieMutation
>
export type ClearDomainRcSessionCookieMutationResult = ApolloReactCommon.MutationResult<ClearDomainRcSessionCookieMutation>
export type ClearDomainRcSessionCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearDomainRcSessionCookieMutation,
  ClearDomainRcSessionCookieMutationVariables
>
export const ClearSiteRcSessionCookieDocument = gql`
  mutation ClearSiteRcSessionCookie {
    clearSiteRcSessionCookie
  }
`
export type ClearSiteRcSessionCookieMutationFn = ApolloReactCommon.MutationFunction<
  ClearSiteRcSessionCookieMutation,
  ClearSiteRcSessionCookieMutationVariables
>

/**
 * __useClearSiteRcSessionCookieMutation__
 *
 * To run a mutation, you first call `useClearSiteRcSessionCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSiteRcSessionCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSiteRcSessionCookieMutation, { data, loading, error }] = useClearSiteRcSessionCookieMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearSiteRcSessionCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearSiteRcSessionCookieMutation,
    ClearSiteRcSessionCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ClearSiteRcSessionCookieMutation,
    ClearSiteRcSessionCookieMutationVariables
  >(ClearSiteRcSessionCookieDocument, options)
}
export type ClearSiteRcSessionCookieMutationHookResult = ReturnType<
  typeof useClearSiteRcSessionCookieMutation
>
export type ClearSiteRcSessionCookieMutationResult = ApolloReactCommon.MutationResult<ClearSiteRcSessionCookieMutation>
export type ClearSiteRcSessionCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearSiteRcSessionCookieMutation,
  ClearSiteRcSessionCookieMutationVariables
>
export const CreateRailItineraryDocument = gql`
  mutation createRailItinerary($input: CreateItineraryInput) {
    createRailItinerary(input: $input) {
      id
    }
  }
`
export type CreateRailItineraryMutationFn = ApolloReactCommon.MutationFunction<
  CreateRailItineraryMutation,
  CreateRailItineraryMutationVariables
>

/**
 * __useCreateRailItineraryMutation__
 *
 * To run a mutation, you first call `useCreateRailItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRailItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRailItineraryMutation, { data, loading, error }] = useCreateRailItineraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRailItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRailItineraryMutation,
    CreateRailItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CreateRailItineraryMutation,
    CreateRailItineraryMutationVariables
  >(CreateRailItineraryDocument, options)
}
export type CreateRailItineraryMutationHookResult = ReturnType<
  typeof useCreateRailItineraryMutation
>
export type CreateRailItineraryMutationResult = ApolloReactCommon.MutationResult<CreateRailItineraryMutation>
export type CreateRailItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRailItineraryMutation,
  CreateRailItineraryMutationVariables
>
export const CreateRailSearchDocument = gql`
  mutation createRailSearch($input: CreateRailSearchInput!) {
    createRailSearch(input: $input) {
      id
    }
  }
`
export type CreateRailSearchMutationFn = ApolloReactCommon.MutationFunction<
  CreateRailSearchMutation,
  CreateRailSearchMutationVariables
>

/**
 * __useCreateRailSearchMutation__
 *
 * To run a mutation, you first call `useCreateRailSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRailSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRailSearchMutation, { data, loading, error }] = useCreateRailSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRailSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRailSearchMutation,
    CreateRailSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateRailSearchMutation, CreateRailSearchMutationVariables>(
    CreateRailSearchDocument,
    options,
  )
}
export type CreateRailSearchMutationHookResult = ReturnType<typeof useCreateRailSearchMutation>
export type CreateRailSearchMutationResult = ApolloReactCommon.MutationResult<CreateRailSearchMutation>
export type CreateRailSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRailSearchMutation,
  CreateRailSearchMutationVariables
>
export const EditCreditCardDocument = gql`
  mutation editCreditCard($input: EditCreditCardInput!) {
    editCreditCard(input: $input) {
      success
    }
  }
`
export type EditCreditCardMutationFn = ApolloReactCommon.MutationFunction<
  EditCreditCardMutation,
  EditCreditCardMutationVariables
>

/**
 * __useEditCreditCardMutation__
 *
 * To run a mutation, you first call `useEditCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCreditCardMutation, { data, loading, error }] = useEditCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCreditCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditCreditCardMutation,
    EditCreditCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<EditCreditCardMutation, EditCreditCardMutationVariables>(
    EditCreditCardDocument,
    options,
  )
}
export type EditCreditCardMutationHookResult = ReturnType<typeof useEditCreditCardMutation>
export type EditCreditCardMutationResult = ApolloReactCommon.MutationResult<EditCreditCardMutation>
export type EditCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditCreditCardMutation,
  EditCreditCardMutationVariables
>
export const LogEventDocument = gql`
  mutation logEvent($input: LogEventInput!) {
    logEvent(input: $input) {
      code
      success
      message
    }
  }
`
export type LogEventMutationFn = ApolloReactCommon.MutationFunction<
  LogEventMutation,
  LogEventMutationVariables
>

/**
 * __useLogEventMutation__
 *
 * To run a mutation, you first call `useLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logEventMutation, { data, loading, error }] = useLogEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogEventMutation, LogEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LogEventMutation, LogEventMutationVariables>(
    LogEventDocument,
    options,
  )
}
export type LogEventMutationHookResult = ReturnType<typeof useLogEventMutation>
export type LogEventMutationResult = ApolloReactCommon.MutationResult<LogEventMutation>
export type LogEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogEventMutation,
  LogEventMutationVariables
>
export const LoginDocument = gql`
  mutation login {
    login {
      token
    }
  }
`
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  )
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>
export const RegisterClientForNotificationDocument = gql`
  mutation registerClientForNotification($input: RegisterClientForNotificationInput!) {
    registerClientForNotification(input: $input) {
      clientId
    }
  }
`
export type RegisterClientForNotificationMutationFn = ApolloReactCommon.MutationFunction<
  RegisterClientForNotificationMutation,
  RegisterClientForNotificationMutationVariables
>

/**
 * __useRegisterClientForNotificationMutation__
 *
 * To run a mutation, you first call `useRegisterClientForNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterClientForNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerClientForNotificationMutation, { data, loading, error }] = useRegisterClientForNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterClientForNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterClientForNotificationMutation,
    RegisterClientForNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RegisterClientForNotificationMutation,
    RegisterClientForNotificationMutationVariables
  >(RegisterClientForNotificationDocument, options)
}
export type RegisterClientForNotificationMutationHookResult = ReturnType<
  typeof useRegisterClientForNotificationMutation
>
export type RegisterClientForNotificationMutationResult = ApolloReactCommon.MutationResult<RegisterClientForNotificationMutation>
export type RegisterClientForNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterClientForNotificationMutation,
  RegisterClientForNotificationMutationVariables
>
export const RegisterPushNotificationClientDocument = gql`
  mutation registerPushNotificationClient($input: RegisterPushNotificationClientInput!) {
    registerPushNotificationClient(input: $input) {
      success
    }
  }
`
export type RegisterPushNotificationClientMutationFn = ApolloReactCommon.MutationFunction<
  RegisterPushNotificationClientMutation,
  RegisterPushNotificationClientMutationVariables
>

/**
 * __useRegisterPushNotificationClientMutation__
 *
 * To run a mutation, you first call `useRegisterPushNotificationClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushNotificationClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushNotificationClientMutation, { data, loading, error }] = useRegisterPushNotificationClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPushNotificationClientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterPushNotificationClientMutation,
    RegisterPushNotificationClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RegisterPushNotificationClientMutation,
    RegisterPushNotificationClientMutationVariables
  >(RegisterPushNotificationClientDocument, options)
}
export type RegisterPushNotificationClientMutationHookResult = ReturnType<
  typeof useRegisterPushNotificationClientMutation
>
export type RegisterPushNotificationClientMutationResult = ApolloReactCommon.MutationResult<RegisterPushNotificationClientMutation>
export type RegisterPushNotificationClientMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterPushNotificationClientMutation,
  RegisterPushNotificationClientMutationVariables
>
export const RemoveCarServiceDocument = gql`
  mutation removeCarService($itineraryId: String!, $carKey: String!) {
    removeCarService(input: { carKey: $carKey, itineraryId: $itineraryId }) {
      success
      message
      code
    }
  }
`
export type RemoveCarServiceMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCarServiceMutation,
  RemoveCarServiceMutationVariables
>

/**
 * __useRemoveCarServiceMutation__
 *
 * To run a mutation, you first call `useRemoveCarServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCarServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCarServiceMutation, { data, loading, error }] = useRemoveCarServiceMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      carKey: // value for 'carKey'
 *   },
 * });
 */
export function useRemoveCarServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCarServiceMutation,
    RemoveCarServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveCarServiceMutation, RemoveCarServiceMutationVariables>(
    RemoveCarServiceDocument,
    options,
  )
}
export type RemoveCarServiceMutationHookResult = ReturnType<typeof useRemoveCarServiceMutation>
export type RemoveCarServiceMutationResult = ApolloReactCommon.MutationResult<RemoveCarServiceMutation>
export type RemoveCarServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCarServiceMutation,
  RemoveCarServiceMutationVariables
>
export const RemoveSeatAsssignmentDocument = gql`
  mutation removeSeatAsssignment($input: RemoveSeatAssignmentInput!) {
    removeSeatAssignment(input: $input) {
      success
      message
      code
    }
  }
`
export type RemoveSeatAsssignmentMutationFn = ApolloReactCommon.MutationFunction<
  RemoveSeatAsssignmentMutation,
  RemoveSeatAsssignmentMutationVariables
>

/**
 * __useRemoveSeatAsssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveSeatAsssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeatAsssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeatAsssignmentMutation, { data, loading, error }] = useRemoveSeatAsssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSeatAsssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveSeatAsssignmentMutation,
    RemoveSeatAsssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RemoveSeatAsssignmentMutation,
    RemoveSeatAsssignmentMutationVariables
  >(RemoveSeatAsssignmentDocument, options)
}
export type RemoveSeatAsssignmentMutationHookResult = ReturnType<
  typeof useRemoveSeatAsssignmentMutation
>
export type RemoveSeatAsssignmentMutationResult = ApolloReactCommon.MutationResult<RemoveSeatAsssignmentMutation>
export type RemoveSeatAsssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveSeatAsssignmentMutation,
  RemoveSeatAsssignmentMutationVariables
>
export const ReplaceFlightDocument = gql`
  mutation replaceFlight($input: ReplaceFlightInput!) {
    replaceFlight(input: $input) {
      success
      message
      code
    }
  }
`
export type ReplaceFlightMutationFn = ApolloReactCommon.MutationFunction<
  ReplaceFlightMutation,
  ReplaceFlightMutationVariables
>

/**
 * __useReplaceFlightMutation__
 *
 * To run a mutation, you first call `useReplaceFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceFlightMutation, { data, loading, error }] = useReplaceFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceFlightMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReplaceFlightMutation,
    ReplaceFlightMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ReplaceFlightMutation, ReplaceFlightMutationVariables>(
    ReplaceFlightDocument,
    options,
  )
}
export type ReplaceFlightMutationHookResult = ReturnType<typeof useReplaceFlightMutation>
export type ReplaceFlightMutationResult = ApolloReactCommon.MutationResult<ReplaceFlightMutation>
export type ReplaceFlightMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReplaceFlightMutation,
  ReplaceFlightMutationVariables
>
export const ToggleNotificationPreferenceDocument = gql`
  mutation ToggleNotificationPreference($input: ToggleNotificationPreferenceInput!) {
    toggleNotificationPreference(input: $input) {
      code
      errorCodes
      message
      success
    }
  }
`
export type ToggleNotificationPreferenceMutationFn = ApolloReactCommon.MutationFunction<
  ToggleNotificationPreferenceMutation,
  ToggleNotificationPreferenceMutationVariables
>

/**
 * __useToggleNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useToggleNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNotificationPreferenceMutation, { data, loading, error }] = useToggleNotificationPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleNotificationPreferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleNotificationPreferenceMutation,
    ToggleNotificationPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ToggleNotificationPreferenceMutation,
    ToggleNotificationPreferenceMutationVariables
  >(ToggleNotificationPreferenceDocument, options)
}
export type ToggleNotificationPreferenceMutationHookResult = ReturnType<
  typeof useToggleNotificationPreferenceMutation
>
export type ToggleNotificationPreferenceMutationResult = ApolloReactCommon.MutationResult<ToggleNotificationPreferenceMutation>
export type ToggleNotificationPreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleNotificationPreferenceMutation,
  ToggleNotificationPreferenceMutationVariables
>
export const UberEstimateDocument = gql`
  mutation uberEstimate($input: UberEstimateInput!) {
    uberEstimate(input: $input) {
      error {
        message
        uberHTTPStatusCode
        uberErrorCode
        type
      }
      productEstimates {
        product {
          productId
          vehicleViewId
          displayName
          description
          shortDescription
          parentProductTypeId
          image
          backgroundImage
          schedulingEnabled
          upfrontFareEnabled
          shared
          capacity
          reserveInfo {
            enabled
            scheduledThresholdMinutes
            freeCancellationThresholdMinutes
          }
        }
        estimateInfo {
          fareId
          noCarsAvailable
          pricingExplanation
          trip {
            durationEstimate
          }
          estimate {
            display
            lowEstimate
            highEstimate
            currency
          }
          fare {
            fareId
            value
            currencyCode
            display
            expiresAt
            surgeMultiplier
            fareBreakdown {
              type
              value
              name
              notice
            }
          }
        }
        seatCount
      }
    }
  }
`
export type UberEstimateMutationFn = ApolloReactCommon.MutationFunction<
  UberEstimateMutation,
  UberEstimateMutationVariables
>

/**
 * __useUberEstimateMutation__
 *
 * To run a mutation, you first call `useUberEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUberEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uberEstimateMutation, { data, loading, error }] = useUberEstimateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUberEstimateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UberEstimateMutation,
    UberEstimateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UberEstimateMutation, UberEstimateMutationVariables>(
    UberEstimateDocument,
    options,
  )
}
export type UberEstimateMutationHookResult = ReturnType<typeof useUberEstimateMutation>
export type UberEstimateMutationResult = ApolloReactCommon.MutationResult<UberEstimateMutation>
export type UberEstimateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UberEstimateMutation,
  UberEstimateMutationVariables
>
export const UnregisterClientFromNotificationDocument = gql`
  mutation unregisterClientFromNotification($clientId: ID!) {
    unregisterClientFromNotification(clientId: $clientId) {
      code
      errorCodes
      message
      success
    }
  }
`
export type UnregisterClientFromNotificationMutationFn = ApolloReactCommon.MutationFunction<
  UnregisterClientFromNotificationMutation,
  UnregisterClientFromNotificationMutationVariables
>

/**
 * __useUnregisterClientFromNotificationMutation__
 *
 * To run a mutation, you first call `useUnregisterClientFromNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterClientFromNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterClientFromNotificationMutation, { data, loading, error }] = useUnregisterClientFromNotificationMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useUnregisterClientFromNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnregisterClientFromNotificationMutation,
    UnregisterClientFromNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UnregisterClientFromNotificationMutation,
    UnregisterClientFromNotificationMutationVariables
  >(UnregisterClientFromNotificationDocument, options)
}
export type UnregisterClientFromNotificationMutationHookResult = ReturnType<
  typeof useUnregisterClientFromNotificationMutation
>
export type UnregisterClientFromNotificationMutationResult = ApolloReactCommon.MutationResult<UnregisterClientFromNotificationMutation>
export type UnregisterClientFromNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnregisterClientFromNotificationMutation,
  UnregisterClientFromNotificationMutationVariables
>
export const UpdateAirPreferenceExcerptDocument = gql`
  mutation updateAirPreferenceExcerpt($input: AirPreferenceExcerptInput!) {
    updateAirPreferenceExcerpt(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateAirPreferenceExcerptMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAirPreferenceExcerptMutation,
  UpdateAirPreferenceExcerptMutationVariables
>

/**
 * __useUpdateAirPreferenceExcerptMutation__
 *
 * To run a mutation, you first call `useUpdateAirPreferenceExcerptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAirPreferenceExcerptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAirPreferenceExcerptMutation, { data, loading, error }] = useUpdateAirPreferenceExcerptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAirPreferenceExcerptMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAirPreferenceExcerptMutation,
    UpdateAirPreferenceExcerptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateAirPreferenceExcerptMutation,
    UpdateAirPreferenceExcerptMutationVariables
  >(UpdateAirPreferenceExcerptDocument, options)
}
export type UpdateAirPreferenceExcerptMutationHookResult = ReturnType<
  typeof useUpdateAirPreferenceExcerptMutation
>
export type UpdateAirPreferenceExcerptMutationResult = ApolloReactCommon.MutationResult<UpdateAirPreferenceExcerptMutation>
export type UpdateAirPreferenceExcerptMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAirPreferenceExcerptMutation,
  UpdateAirPreferenceExcerptMutationVariables
>
export const UpdateDelegatedIdCookieDocument = gql`
  mutation updateDelegatedIdCookie($input: UpdateDelegatedIdCookieInput!) {
    updateDelegatedIdCookie(input: $input) {
      success
    }
  }
`
export type UpdateDelegatedIdCookieMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDelegatedIdCookieMutation,
  UpdateDelegatedIdCookieMutationVariables
>

/**
 * __useUpdateDelegatedIdCookieMutation__
 *
 * To run a mutation, you first call `useUpdateDelegatedIdCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDelegatedIdCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDelegatedIdCookieMutation, { data, loading, error }] = useUpdateDelegatedIdCookieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDelegatedIdCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDelegatedIdCookieMutation,
    UpdateDelegatedIdCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateDelegatedIdCookieMutation,
    UpdateDelegatedIdCookieMutationVariables
  >(UpdateDelegatedIdCookieDocument, options)
}
export type UpdateDelegatedIdCookieMutationHookResult = ReturnType<
  typeof useUpdateDelegatedIdCookieMutation
>
export type UpdateDelegatedIdCookieMutationResult = ApolloReactCommon.MutationResult<UpdateDelegatedIdCookieMutation>
export type UpdateDelegatedIdCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDelegatedIdCookieMutation,
  UpdateDelegatedIdCookieMutationVariables
>
export const UpdateDelegatedIdTokenDocument = gql`
  mutation updateDelegatedIdToken($input: UpdateDelegatedIdTokenInput!) {
    updateDelegatedIdToken(input: $input) {
      token
    }
  }
`
export type UpdateDelegatedIdTokenMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDelegatedIdTokenMutation,
  UpdateDelegatedIdTokenMutationVariables
>

/**
 * __useUpdateDelegatedIdTokenMutation__
 *
 * To run a mutation, you first call `useUpdateDelegatedIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDelegatedIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDelegatedIdTokenMutation, { data, loading, error }] = useUpdateDelegatedIdTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDelegatedIdTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDelegatedIdTokenMutation,
    UpdateDelegatedIdTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateDelegatedIdTokenMutation,
    UpdateDelegatedIdTokenMutationVariables
  >(UpdateDelegatedIdTokenDocument, options)
}
export type UpdateDelegatedIdTokenMutationHookResult = ReturnType<
  typeof useUpdateDelegatedIdTokenMutation
>
export type UpdateDelegatedIdTokenMutationResult = ApolloReactCommon.MutationResult<UpdateDelegatedIdTokenMutation>
export type UpdateDelegatedIdTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDelegatedIdTokenMutation,
  UpdateDelegatedIdTokenMutationVariables
>
export const UpdateRailJourneyPreferencesDocument = gql`
  mutation updateRailJourneyPreferences($input: RailPreferencesInput!) {
    updateRailJourneyPreferences(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateRailJourneyPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateRailJourneyPreferencesMutation,
  UpdateRailJourneyPreferencesMutationVariables
>

/**
 * __useUpdateRailJourneyPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateRailJourneyPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRailJourneyPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRailJourneyPreferencesMutation, { data, loading, error }] = useUpdateRailJourneyPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRailJourneyPreferencesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRailJourneyPreferencesMutation,
    UpdateRailJourneyPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateRailJourneyPreferencesMutation,
    UpdateRailJourneyPreferencesMutationVariables
  >(UpdateRailJourneyPreferencesDocument, options)
}
export type UpdateRailJourneyPreferencesMutationHookResult = ReturnType<
  typeof useUpdateRailJourneyPreferencesMutation
>
export type UpdateRailJourneyPreferencesMutationResult = ApolloReactCommon.MutationResult<UpdateRailJourneyPreferencesMutation>
export type UpdateRailJourneyPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRailJourneyPreferencesMutation,
  UpdateRailJourneyPreferencesMutationVariables
>
export const UpdateTokensWithCookieDocument = gql`
  mutation updateTokensWithCookie {
    updateTokensWithCookie
  }
`
export type UpdateTokensWithCookieMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTokensWithCookieMutation,
  UpdateTokensWithCookieMutationVariables
>

/**
 * __useUpdateTokensWithCookieMutation__
 *
 * To run a mutation, you first call `useUpdateTokensWithCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokensWithCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokensWithCookieMutation, { data, loading, error }] = useUpdateTokensWithCookieMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateTokensWithCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTokensWithCookieMutation,
    UpdateTokensWithCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateTokensWithCookieMutation,
    UpdateTokensWithCookieMutationVariables
  >(UpdateTokensWithCookieDocument, options)
}
export type UpdateTokensWithCookieMutationHookResult = ReturnType<
  typeof useUpdateTokensWithCookieMutation
>
export type UpdateTokensWithCookieMutationResult = ApolloReactCommon.MutationResult<UpdateTokensWithCookieMutation>
export type UpdateTokensWithCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTokensWithCookieMutation,
  UpdateTokensWithCookieMutationVariables
>
export const UpdateTokensDocument = gql`
  mutation updateTokens($input: UpdateTokensInput!) {
    updateTokens(input: $input) {
      token
    }
  }
`
export type UpdateTokensMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTokensMutation,
  UpdateTokensMutationVariables
>

/**
 * __useUpdateTokensMutation__
 *
 * To run a mutation, you first call `useUpdateTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokensMutation, { data, loading, error }] = useUpdateTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTokensMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTokensMutation,
    UpdateTokensMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateTokensMutation, UpdateTokensMutationVariables>(
    UpdateTokensDocument,
    options,
  )
}
export type UpdateTokensMutationHookResult = ReturnType<typeof useUpdateTokensMutation>
export type UpdateTokensMutationResult = ApolloReactCommon.MutationResult<UpdateTokensMutation>
export type UpdateTokensMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTokensMutation,
  UpdateTokensMutationVariables
>
export const UpdateTripNameByProcessIdDocument = gql`
  mutation updateTripNameByProcessId($input: UpdateTripNameByProcessIdInput!) {
    updateTripNameByProcessId(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateTripNameByProcessIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTripNameByProcessIdMutation,
  UpdateTripNameByProcessIdMutationVariables
>

/**
 * __useUpdateTripNameByProcessIdMutation__
 *
 * To run a mutation, you first call `useUpdateTripNameByProcessIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripNameByProcessIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripNameByProcessIdMutation, { data, loading, error }] = useUpdateTripNameByProcessIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTripNameByProcessIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripNameByProcessIdMutation,
    UpdateTripNameByProcessIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateTripNameByProcessIdMutation,
    UpdateTripNameByProcessIdMutationVariables
  >(UpdateTripNameByProcessIdDocument, options)
}
export type UpdateTripNameByProcessIdMutationHookResult = ReturnType<
  typeof useUpdateTripNameByProcessIdMutation
>
export type UpdateTripNameByProcessIdMutationResult = ApolloReactCommon.MutationResult<UpdateTripNameByProcessIdMutation>
export type UpdateTripNameByProcessIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTripNameByProcessIdMutation,
  UpdateTripNameByProcessIdMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($input: UserInput!) {
    user(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const GetAirSeatMapDocument = gql`
  query getAirSeatMap($input: SeatMapInput!) {
    seatMap(input: $input) {
      carrier {
        id
        name
        imageUrl
      }
      departureDate
      flightNumber
      origin
      destination
      readOnly
      rows {
        number
        cabinClass
        seat {
          number
          column
          available
          isOverWing
          isNearWindow
          isPaid
          isNearAisle
          isPremium
          isExitRowSeat
          hasBulkhead
          isUpperDeck
          hasGalley
          hasLavatory
          isSmokingAllowed
          isSuitableForHandicapped
        }
      }
    }
  }
`

/**
 * __useGetAirSeatMapQuery__
 *
 * To run a query within a React component, call `useGetAirSeatMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirSeatMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirSeatMapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAirSeatMapQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetAirSeatMapQuery, GetAirSeatMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAirSeatMapQuery, GetAirSeatMapQueryVariables>(
    GetAirSeatMapDocument,
    options,
  )
}
export function useGetAirSeatMapLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAirSeatMapQuery,
    GetAirSeatMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetAirSeatMapQuery, GetAirSeatMapQueryVariables>(
    GetAirSeatMapDocument,
    options,
  )
}
export type GetAirSeatMapQueryHookResult = ReturnType<typeof useGetAirSeatMapQuery>
export type GetAirSeatMapLazyQueryHookResult = ReturnType<typeof useGetAirSeatMapLazyQuery>
export type GetAirSeatMapQueryResult = ApolloReactCommon.QueryResult<
  GetAirSeatMapQuery,
  GetAirSeatMapQueryVariables
>
export const GetAirportDetailsDocument = gql`
  query getAirportDetails($airportCode: String!) {
    airportDetails(airportCode: $airportCode) {
      geocode {
        lat
        long
      }
    }
  }
`

/**
 * __useGetAirportDetailsQuery__
 *
 * To run a query within a React component, call `useGetAirportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirportDetailsQuery({
 *   variables: {
 *      airportCode: // value for 'airportCode'
 *   },
 * });
 */
export function useGetAirportDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAirportDetailsQuery,
    GetAirportDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAirportDetailsQuery, GetAirportDetailsQueryVariables>(
    GetAirportDetailsDocument,
    options,
  )
}
export function useGetAirportDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAirportDetailsQuery,
    GetAirportDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetAirportDetailsQuery, GetAirportDetailsQueryVariables>(
    GetAirportDetailsDocument,
    options,
  )
}
export type GetAirportDetailsQueryHookResult = ReturnType<typeof useGetAirportDetailsQuery>
export type GetAirportDetailsLazyQueryHookResult = ReturnType<typeof useGetAirportDetailsLazyQuery>
export type GetAirportDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetAirportDetailsQuery,
  GetAirportDetailsQueryVariables
>
export const GetAlertsDocument = gql`
  query getAlerts($input: AlertsInput) {
    alerts(input: $input) {
      totalAlerts
      alerts {
        title
        message
        kind
        docId
      }
    }
  }
`

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAlertsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options,
  )
}
export function useGetAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options,
  )
}
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>
export type GetAlertsLazyQueryHookResult = ReturnType<typeof useGetAlertsLazyQuery>
export type GetAlertsQueryResult = ApolloReactCommon.QueryResult<
  GetAlertsQuery,
  GetAlertsQueryVariables
>
export const GetAutocompletePlacesDocument = gql`
  query getAutocompletePlaces($input: PlacesAutocompleteInput!) {
    autocompletePlaces(input: $input) {
      ...Place
      subPlaces {
        ...Place
      }
    }
  }
  ${PlaceFragmentDoc}
`

/**
 * __useGetAutocompletePlacesQuery__
 *
 * To run a query within a React component, call `useGetAutocompletePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutocompletePlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutocompletePlacesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutocompletePlacesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAutocompletePlacesQuery,
    GetAutocompletePlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAutocompletePlacesQuery, GetAutocompletePlacesQueryVariables>(
    GetAutocompletePlacesDocument,
    options,
  )
}
export function useGetAutocompletePlacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAutocompletePlacesQuery,
    GetAutocompletePlacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetAutocompletePlacesQuery,
    GetAutocompletePlacesQueryVariables
  >(GetAutocompletePlacesDocument, options)
}
export type GetAutocompletePlacesQueryHookResult = ReturnType<typeof useGetAutocompletePlacesQuery>
export type GetAutocompletePlacesLazyQueryHookResult = ReturnType<
  typeof useGetAutocompletePlacesLazyQuery
>
export type GetAutocompletePlacesQueryResult = ApolloReactCommon.QueryResult<
  GetAutocompletePlacesQuery,
  GetAutocompletePlacesQueryVariables
>
export const GetBookRideHailDocument = gql`
  query getBookRideHail($input: BookRideHailRequest!) {
    bookRideHail(input: $input) {
      bookingID
    }
  }
`

/**
 * __useGetBookRideHailQuery__
 *
 * To run a query within a React component, call `useGetBookRideHailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookRideHailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookRideHailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBookRideHailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetBookRideHailQuery,
    GetBookRideHailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetBookRideHailQuery, GetBookRideHailQueryVariables>(
    GetBookRideHailDocument,
    options,
  )
}
export function useGetBookRideHailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBookRideHailQuery,
    GetBookRideHailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetBookRideHailQuery, GetBookRideHailQueryVariables>(
    GetBookRideHailDocument,
    options,
  )
}
export type GetBookRideHailQueryHookResult = ReturnType<typeof useGetBookRideHailQuery>
export type GetBookRideHailLazyQueryHookResult = ReturnType<typeof useGetBookRideHailLazyQuery>
export type GetBookRideHailQueryResult = ApolloReactCommon.QueryResult<
  GetBookRideHailQuery,
  GetBookRideHailQueryVariables
>
export const CalculateCarEmissionsBatchDocument = gql`
  query calculateCarEmissionsBatch($input: CalculateCarEmissionsBatchInput!) {
    calculateCarEmissionsBatch(input: $input) {
      averageEmissionsTonnes
      cars {
        customRef
        tonnesOfEmissions
        equivalences {
          amount
          type
          unit
        }
      }
      errors
    }
  }
`

/**
 * __useCalculateCarEmissionsBatchQuery__
 *
 * To run a query within a React component, call `useCalculateCarEmissionsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateCarEmissionsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateCarEmissionsBatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateCarEmissionsBatchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CalculateCarEmissionsBatchQuery,
    CalculateCarEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CalculateCarEmissionsBatchQuery,
    CalculateCarEmissionsBatchQueryVariables
  >(CalculateCarEmissionsBatchDocument, options)
}
export function useCalculateCarEmissionsBatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CalculateCarEmissionsBatchQuery,
    CalculateCarEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CalculateCarEmissionsBatchQuery,
    CalculateCarEmissionsBatchQueryVariables
  >(CalculateCarEmissionsBatchDocument, options)
}
export type CalculateCarEmissionsBatchQueryHookResult = ReturnType<
  typeof useCalculateCarEmissionsBatchQuery
>
export type CalculateCarEmissionsBatchLazyQueryHookResult = ReturnType<
  typeof useCalculateCarEmissionsBatchLazyQuery
>
export type CalculateCarEmissionsBatchQueryResult = ApolloReactCommon.QueryResult<
  CalculateCarEmissionsBatchQuery,
  CalculateCarEmissionsBatchQueryVariables
>
export const CalculateFlightEmissionsBatchDocument = gql`
  query calculateFlightEmissionsBatch($input: CalculateFlightEmissionsBatchInput!) {
    calculateFlightEmissionsBatch(input: $input) {
      averageEmissionsTonnes
      flights {
        customRef
        tonnesOfEmissions
        equivalences {
          amount
          type
          unit
        }
      }
      errors
    }
  }
`

/**
 * __useCalculateFlightEmissionsBatchQuery__
 *
 * To run a query within a React component, call `useCalculateFlightEmissionsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateFlightEmissionsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateFlightEmissionsBatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateFlightEmissionsBatchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CalculateFlightEmissionsBatchQuery,
    CalculateFlightEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CalculateFlightEmissionsBatchQuery,
    CalculateFlightEmissionsBatchQueryVariables
  >(CalculateFlightEmissionsBatchDocument, options)
}
export function useCalculateFlightEmissionsBatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CalculateFlightEmissionsBatchQuery,
    CalculateFlightEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CalculateFlightEmissionsBatchQuery,
    CalculateFlightEmissionsBatchQueryVariables
  >(CalculateFlightEmissionsBatchDocument, options)
}
export type CalculateFlightEmissionsBatchQueryHookResult = ReturnType<
  typeof useCalculateFlightEmissionsBatchQuery
>
export type CalculateFlightEmissionsBatchLazyQueryHookResult = ReturnType<
  typeof useCalculateFlightEmissionsBatchLazyQuery
>
export type CalculateFlightEmissionsBatchQueryResult = ApolloReactCommon.QueryResult<
  CalculateFlightEmissionsBatchQuery,
  CalculateFlightEmissionsBatchQueryVariables
>
export const CalculateHotelEmissionsBatchDocument = gql`
  query calculateHotelEmissionsBatch($input: CalculateHotelEmissionsBatchInput!) {
    calculateHotelEmissionsBatch(input: $input) {
      averageEmissionsTonnes
      averageSustainabilityScore
      hotels {
        customRef
        tonnesOfEmissions
        sustainabilityScore
        certifications {
          isGSTCApproved
          name
          type
        }
        equivalences {
          amount
          type
          unit
        }
      }
      errors
    }
  }
`

/**
 * __useCalculateHotelEmissionsBatchQuery__
 *
 * To run a query within a React component, call `useCalculateHotelEmissionsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateHotelEmissionsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateHotelEmissionsBatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateHotelEmissionsBatchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CalculateHotelEmissionsBatchQuery,
    CalculateHotelEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CalculateHotelEmissionsBatchQuery,
    CalculateHotelEmissionsBatchQueryVariables
  >(CalculateHotelEmissionsBatchDocument, options)
}
export function useCalculateHotelEmissionsBatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CalculateHotelEmissionsBatchQuery,
    CalculateHotelEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CalculateHotelEmissionsBatchQuery,
    CalculateHotelEmissionsBatchQueryVariables
  >(CalculateHotelEmissionsBatchDocument, options)
}
export type CalculateHotelEmissionsBatchQueryHookResult = ReturnType<
  typeof useCalculateHotelEmissionsBatchQuery
>
export type CalculateHotelEmissionsBatchLazyQueryHookResult = ReturnType<
  typeof useCalculateHotelEmissionsBatchLazyQuery
>
export type CalculateHotelEmissionsBatchQueryResult = ApolloReactCommon.QueryResult<
  CalculateHotelEmissionsBatchQuery,
  CalculateHotelEmissionsBatchQueryVariables
>
export const CalculateRailEmissionsBatchDocument = gql`
  query calculateRailEmissionsBatch($input: CalculateRailEmissionsBatchInput!) {
    calculateRailEmissionsBatch(input: $input) {
      averageEmissionsTonnes
      rails {
        customRef
        travelClass
        tonnesOfEmissions
        equivalences {
          amount
          type
          unit
        }
      }
      errors
    }
  }
`

/**
 * __useCalculateRailEmissionsBatchQuery__
 *
 * To run a query within a React component, call `useCalculateRailEmissionsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRailEmissionsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRailEmissionsBatchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateRailEmissionsBatchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CalculateRailEmissionsBatchQuery,
    CalculateRailEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CalculateRailEmissionsBatchQuery,
    CalculateRailEmissionsBatchQueryVariables
  >(CalculateRailEmissionsBatchDocument, options)
}
export function useCalculateRailEmissionsBatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CalculateRailEmissionsBatchQuery,
    CalculateRailEmissionsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CalculateRailEmissionsBatchQuery,
    CalculateRailEmissionsBatchQueryVariables
  >(CalculateRailEmissionsBatchDocument, options)
}
export type CalculateRailEmissionsBatchQueryHookResult = ReturnType<
  typeof useCalculateRailEmissionsBatchQuery
>
export type CalculateRailEmissionsBatchLazyQueryHookResult = ReturnType<
  typeof useCalculateRailEmissionsBatchLazyQuery
>
export type CalculateRailEmissionsBatchQueryResult = ApolloReactCommon.QueryResult<
  CalculateRailEmissionsBatchQuery,
  CalculateRailEmissionsBatchQueryVariables
>
export const CalculateTripEmissionsDocument = gql`
  query calculateTripEmissions($input: CalculateTripEmissionsInput!) {
    calculateTripEmissions(input: $input) {
      hotels {
        customRef
        averageLocationEmissionsTonnes
        averageLocationSustainabilityScore
        sustainabilityScore
        tonnesOfEmissions
        certifications {
          isGSTCApproved
          name
          type
        }
        equivalences {
          amount
          type
          unit
        }
      }
      flights {
        tonnesOfEmissions
        averageRouteEmissionTonnes
        customRef
        equivalences {
          amount
          type
          unit
        }
      }
      rails {
        tonnesOfEmissions
        averageRouteEmissionTonnes
        customRef
        equivalences {
          amount
          type
          unit
        }
      }
      cars {
        tonnesOfEmissions
        averageEmissionTonnes
        customRef
        equivalences {
          amount
          type
          unit
        }
      }
      errors {
        message
        type
      }
    }
  }
`

/**
 * __useCalculateTripEmissionsQuery__
 *
 * To run a query within a React component, call `useCalculateTripEmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateTripEmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateTripEmissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateTripEmissionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CalculateTripEmissionsQuery,
    CalculateTripEmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CalculateTripEmissionsQuery,
    CalculateTripEmissionsQueryVariables
  >(CalculateTripEmissionsDocument, options)
}
export function useCalculateTripEmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CalculateTripEmissionsQuery,
    CalculateTripEmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CalculateTripEmissionsQuery,
    CalculateTripEmissionsQueryVariables
  >(CalculateTripEmissionsDocument, options)
}
export type CalculateTripEmissionsQueryHookResult = ReturnType<
  typeof useCalculateTripEmissionsQuery
>
export type CalculateTripEmissionsLazyQueryHookResult = ReturnType<
  typeof useCalculateTripEmissionsLazyQuery
>
export type CalculateTripEmissionsQueryResult = ApolloReactCommon.QueryResult<
  CalculateTripEmissionsQuery,
  CalculateTripEmissionsQueryVariables
>
export const GetCustomerUberAccountStatusDocument = gql`
  query getCustomerUberAccountStatus {
    customerUberAccountStatus {
      status
      linkingUrl
      userAccountSelfCreationEnabled
    }
  }
`

/**
 * __useGetCustomerUberAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetCustomerUberAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerUberAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerUberAccountStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerUberAccountStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCustomerUberAccountStatusQuery,
    GetCustomerUberAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetCustomerUberAccountStatusQuery,
    GetCustomerUberAccountStatusQueryVariables
  >(GetCustomerUberAccountStatusDocument, options)
}
export function useGetCustomerUberAccountStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCustomerUberAccountStatusQuery,
    GetCustomerUberAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetCustomerUberAccountStatusQuery,
    GetCustomerUberAccountStatusQueryVariables
  >(GetCustomerUberAccountStatusDocument, options)
}
export type GetCustomerUberAccountStatusQueryHookResult = ReturnType<
  typeof useGetCustomerUberAccountStatusQuery
>
export type GetCustomerUberAccountStatusLazyQueryHookResult = ReturnType<
  typeof useGetCustomerUberAccountStatusLazyQuery
>
export type GetCustomerUberAccountStatusQueryResult = ApolloReactCommon.QueryResult<
  GetCustomerUberAccountStatusQuery,
  GetCustomerUberAccountStatusQueryVariables
>
export const GetDeemHeaderDocument = gql`
  query getDeemHeader($locale: String, $meetingsModeElements: MeetingsModeSessionElements) {
    deemHeader(locale: $locale, meetingsModeElements: $meetingsModeElements) {
      header
      headerAssets
      footer
      metadata {
        brandingMetadata {
          siteName
        }
      }
    }
  }
`

/**
 * __useGetDeemHeaderQuery__
 *
 * To run a query within a React component, call `useGetDeemHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeemHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeemHeaderQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      meetingsModeElements: // value for 'meetingsModeElements'
 *   },
 * });
 */
export function useGetDeemHeaderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeemHeaderQuery, GetDeemHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetDeemHeaderQuery, GetDeemHeaderQueryVariables>(
    GetDeemHeaderDocument,
    options,
  )
}
export function useGetDeemHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDeemHeaderQuery,
    GetDeemHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetDeemHeaderQuery, GetDeemHeaderQueryVariables>(
    GetDeemHeaderDocument,
    options,
  )
}
export type GetDeemHeaderQueryHookResult = ReturnType<typeof useGetDeemHeaderQuery>
export type GetDeemHeaderLazyQueryHookResult = ReturnType<typeof useGetDeemHeaderLazyQuery>
export type GetDeemHeaderQueryResult = ApolloReactCommon.QueryResult<
  GetDeemHeaderQuery,
  GetDeemHeaderQueryVariables
>
export const GetDelegatedUsersDocument = gql`
  query getDelegatedUsers($input: DelegatedUsersInput!) {
    delegatedUsers(input: $input) {
      delegatedUsers {
        email
        firstName
        lastName
        siteId
        userId
      }
    }
  }
`

/**
 * __useGetDelegatedUsersQuery__
 *
 * To run a query within a React component, call `useGetDelegatedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDelegatedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDelegatedUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDelegatedUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDelegatedUsersQuery,
    GetDelegatedUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetDelegatedUsersQuery, GetDelegatedUsersQueryVariables>(
    GetDelegatedUsersDocument,
    options,
  )
}
export function useGetDelegatedUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDelegatedUsersQuery,
    GetDelegatedUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetDelegatedUsersQuery, GetDelegatedUsersQueryVariables>(
    GetDelegatedUsersDocument,
    options,
  )
}
export type GetDelegatedUsersQueryHookResult = ReturnType<typeof useGetDelegatedUsersQuery>
export type GetDelegatedUsersLazyQueryHookResult = ReturnType<typeof useGetDelegatedUsersLazyQuery>
export type GetDelegatedUsersQueryResult = ApolloReactCommon.QueryResult<
  GetDelegatedUsersQuery,
  GetDelegatedUsersQueryVariables
>
export const GetFlightFareRulesDocument = gql`
  query getFlightFareRules($input: FlightFareRulesInput!) {
    flightFareRules(input: $input) {
      rules {
        header
        value
      }
    }
  }
`

/**
 * __useGetFlightFareRulesQuery__
 *
 * To run a query within a React component, call `useGetFlightFareRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightFareRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightFareRulesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightFareRulesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightFareRulesQuery,
    GetFlightFareRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFlightFareRulesQuery, GetFlightFareRulesQueryVariables>(
    GetFlightFareRulesDocument,
    options,
  )
}
export function useGetFlightFareRulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightFareRulesQuery,
    GetFlightFareRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFlightFareRulesQuery, GetFlightFareRulesQueryVariables>(
    GetFlightFareRulesDocument,
    options,
  )
}
export type GetFlightFareRulesQueryHookResult = ReturnType<typeof useGetFlightFareRulesQuery>
export type GetFlightFareRulesLazyQueryHookResult = ReturnType<
  typeof useGetFlightFareRulesLazyQuery
>
export type GetFlightFareRulesQueryResult = ApolloReactCommon.QueryResult<
  GetFlightFareRulesQuery,
  GetFlightFareRulesQueryVariables
>
export const GetGeosureScoresDocument = gql`
  query getGeosureScores($latitude: Float!, $longitude: Float!) {
    geosureScores(latitude: $latitude, longitude: $longitude) {
      compositeScore
      distance
      district
      city
      scores {
        value
        label
      }
    }
  }
`

/**
 * __useGetGeosureScoresQuery__
 *
 * To run a query within a React component, call `useGetGeosureScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeosureScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeosureScoresQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetGeosureScoresQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGeosureScoresQuery,
    GetGeosureScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetGeosureScoresQuery, GetGeosureScoresQueryVariables>(
    GetGeosureScoresDocument,
    options,
  )
}
export function useGetGeosureScoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeosureScoresQuery,
    GetGeosureScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetGeosureScoresQuery, GetGeosureScoresQueryVariables>(
    GetGeosureScoresDocument,
    options,
  )
}
export type GetGeosureScoresQueryHookResult = ReturnType<typeof useGetGeosureScoresQuery>
export type GetGeosureScoresLazyQueryHookResult = ReturnType<typeof useGetGeosureScoresLazyQuery>
export type GetGeosureScoresQueryResult = ApolloReactCommon.QueryResult<
  GetGeosureScoresQuery,
  GetGeosureScoresQueryVariables
>
export const GetHotelNamesDocument = gql`
  query getHotelNames(
    $logsMetadata: LogsMetadata
    $hotelResultId: String
    $first: Int
    $offset: Int
    $orderBy: String
    $hotelName: String
    $distance: Float
    $starRatings: [Int]
    $brandIds: [String]
    $amenityIds: [String]
    $checkIn: String!
    $checkOut: String!
    $latitude: Float!
    $longitude: Float!
    $guests: Int!
    $groupId: String
    $searchBy: String
    $airportCode: String
    $stationCode: String
    $locationName: String
    $nameSearch: Boolean
    $hideSoldOut: Boolean
    $deviceClass: DeviceClass
  ) {
    hotelNames(
      logsMetadata: $logsMetadata
      hotelResultId: $hotelResultId
      first: $first
      offset: $offset
      orderBy: $orderBy
      hotelName: $hotelName
      distance: $distance
      starRatings: $starRatings
      brandIds: $brandIds
      amenityIds: $amenityIds
      checkIn: $checkIn
      checkOut: $checkOut
      latitude: $latitude
      longitude: $longitude
      guests: $guests
      groupId: $groupId
      searchBy: $searchBy
      airportCode: $airportCode
      stationCode: $stationCode
      locationName: $locationName
      nameSearch: $nameSearch
      hideSoldOut: $hideSoldOut
      deviceClass: $deviceClass
    ) {
      hotelNames
    }
  }
`

/**
 * __useGetHotelNamesQuery__
 *
 * To run a query within a React component, call `useGetHotelNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelNamesQuery({
 *   variables: {
 *      logsMetadata: // value for 'logsMetadata'
 *      hotelResultId: // value for 'hotelResultId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      hotelName: // value for 'hotelName'
 *      distance: // value for 'distance'
 *      starRatings: // value for 'starRatings'
 *      brandIds: // value for 'brandIds'
 *      amenityIds: // value for 'amenityIds'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      guests: // value for 'guests'
 *      groupId: // value for 'groupId'
 *      searchBy: // value for 'searchBy'
 *      airportCode: // value for 'airportCode'
 *      stationCode: // value for 'stationCode'
 *      locationName: // value for 'locationName'
 *      nameSearch: // value for 'nameSearch'
 *      hideSoldOut: // value for 'hideSoldOut'
 *      deviceClass: // value for 'deviceClass'
 *   },
 * });
 */
export function useGetHotelNamesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetHotelNamesQuery, GetHotelNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHotelNamesQuery, GetHotelNamesQueryVariables>(
    GetHotelNamesDocument,
    options,
  )
}
export function useGetHotelNamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelNamesQuery,
    GetHotelNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHotelNamesQuery, GetHotelNamesQueryVariables>(
    GetHotelNamesDocument,
    options,
  )
}
export type GetHotelNamesQueryHookResult = ReturnType<typeof useGetHotelNamesQuery>
export type GetHotelNamesLazyQueryHookResult = ReturnType<typeof useGetHotelNamesLazyQuery>
export type GetHotelNamesQueryResult = ApolloReactCommon.QueryResult<
  GetHotelNamesQuery,
  GetHotelNamesQueryVariables
>
export const GetHotelReviewsDocument = gql`
  query getHotelReviews($hotelId: ID!, $logsMetadata: LogsMetadata) {
    hotelReviews(hotelId: $hotelId, logsMetadata: $logsMetadata) {
      reviewsProvider
      totalReviewCount
      medianReviewRating
      medianReviewRatingImageUrl
      hotelReviewsDetailsUrl
      reviews {
        title
        summary
        content
        date
        userRating
        ratingImageUrl
      }
    }
  }
`

/**
 * __useGetHotelReviewsQuery__
 *
 * To run a query within a React component, call `useGetHotelReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelReviewsQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      logsMetadata: // value for 'logsMetadata'
 *   },
 * });
 */
export function useGetHotelReviewsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelReviewsQuery,
    GetHotelReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHotelReviewsQuery, GetHotelReviewsQueryVariables>(
    GetHotelReviewsDocument,
    options,
  )
}
export function useGetHotelReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelReviewsQuery,
    GetHotelReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHotelReviewsQuery, GetHotelReviewsQueryVariables>(
    GetHotelReviewsDocument,
    options,
  )
}
export type GetHotelReviewsQueryHookResult = ReturnType<typeof useGetHotelReviewsQuery>
export type GetHotelReviewsLazyQueryHookResult = ReturnType<typeof useGetHotelReviewsLazyQuery>
export type GetHotelReviewsQueryResult = ApolloReactCommon.QueryResult<
  GetHotelReviewsQuery,
  GetHotelReviewsQueryVariables
>
export const GetHotelRoomDetailsDocument = gql`
  query getHotelRoomDetails($input: HotelRoomDetailsInput!, $logsMetadata: LogsMetadata) {
    hotelRoomDetails(logsMetadata: $logsMetadata, input: $input) {
      id
      rateDetails
    }
  }
`

/**
 * __useGetHotelRoomDetailsQuery__
 *
 * To run a query within a React component, call `useGetHotelRoomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelRoomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelRoomDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      logsMetadata: // value for 'logsMetadata'
 *   },
 * });
 */
export function useGetHotelRoomDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelRoomDetailsQuery,
    GetHotelRoomDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHotelRoomDetailsQuery, GetHotelRoomDetailsQueryVariables>(
    GetHotelRoomDetailsDocument,
    options,
  )
}
export function useGetHotelRoomDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelRoomDetailsQuery,
    GetHotelRoomDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHotelRoomDetailsQuery, GetHotelRoomDetailsQueryVariables>(
    GetHotelRoomDetailsDocument,
    options,
  )
}
export type GetHotelRoomDetailsQueryHookResult = ReturnType<typeof useGetHotelRoomDetailsQuery>
export type GetHotelRoomDetailsLazyQueryHookResult = ReturnType<
  typeof useGetHotelRoomDetailsLazyQuery
>
export type GetHotelRoomDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetHotelRoomDetailsQuery,
  GetHotelRoomDetailsQueryVariables
>
export const GetIdentityHashDocument = gql`
  query getIdentityHash($email: String!) {
    identityHash(email: $email) {
      identityHash
    }
  }
`

/**
 * __useGetIdentityHashQuery__
 *
 * To run a query within a React component, call `useGetIdentityHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityHashQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetIdentityHashQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetIdentityHashQuery,
    GetIdentityHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetIdentityHashQuery, GetIdentityHashQueryVariables>(
    GetIdentityHashDocument,
    options,
  )
}
export function useGetIdentityHashLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetIdentityHashQuery,
    GetIdentityHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetIdentityHashQuery, GetIdentityHashQueryVariables>(
    GetIdentityHashDocument,
    options,
  )
}
export type GetIdentityHashQueryHookResult = ReturnType<typeof useGetIdentityHashQuery>
export type GetIdentityHashLazyQueryHookResult = ReturnType<typeof useGetIdentityHashLazyQuery>
export type GetIdentityHashQueryResult = ApolloReactCommon.QueryResult<
  GetIdentityHashQuery,
  GetIdentityHashQueryVariables
>
export const GetLocationRefinementOptionsDocument = gql`
  query getLocationRefinementOptions($input: LocationRefinementOptionsRequest!) {
    getLocationRefinementOptions(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on LocationRefinementErrorResponse {
        reason
      }
      ... on LocationRefinementOptionsResponse {
        zoneInformation {
          accessPoints {
            id
            label
            location {
              address
              geocode {
                lat
                long
              }
            }
            note
          }
          rootZone {
            childZones {
              name
              polygon
              accessPoints {
                id
                label
                note
                location {
                  address
                  geocode {
                    lat
                    long
                  }
                }
              }
              childZones {
                name
                polygon
              }
              location {
                address
                geocode {
                  lat
                  long
                }
              }
            }
            name
            polygon
            accessPoints {
              id
              label
              location {
                address
                geocode {
                  lat
                  long
                }
              }
              note
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetLocationRefinementOptionsQuery__
 *
 * To run a query within a React component, call `useGetLocationRefinementOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationRefinementOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationRefinementOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLocationRefinementOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLocationRefinementOptionsQuery,
    GetLocationRefinementOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetLocationRefinementOptionsQuery,
    GetLocationRefinementOptionsQueryVariables
  >(GetLocationRefinementOptionsDocument, options)
}
export function useGetLocationRefinementOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLocationRefinementOptionsQuery,
    GetLocationRefinementOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetLocationRefinementOptionsQuery,
    GetLocationRefinementOptionsQueryVariables
  >(GetLocationRefinementOptionsDocument, options)
}
export type GetLocationRefinementOptionsQueryHookResult = ReturnType<
  typeof useGetLocationRefinementOptionsQuery
>
export type GetLocationRefinementOptionsLazyQueryHookResult = ReturnType<
  typeof useGetLocationRefinementOptionsLazyQuery
>
export type GetLocationRefinementOptionsQueryResult = ApolloReactCommon.QueryResult<
  GetLocationRefinementOptionsQuery,
  GetLocationRefinementOptionsQueryVariables
>
export const MultiLayerCostSegmentsDocument = gql`
  query multiLayerCostSegments {
    multiLayerCostSegments {
      topLayer
      firstChildLayer
      secondChildLayer
      bottomLayer
    }
  }
`

/**
 * __useMultiLayerCostSegmentsQuery__
 *
 * To run a query within a React component, call `useMultiLayerCostSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiLayerCostSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiLayerCostSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiLayerCostSegmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MultiLayerCostSegmentsQuery,
    MultiLayerCostSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    MultiLayerCostSegmentsQuery,
    MultiLayerCostSegmentsQueryVariables
  >(MultiLayerCostSegmentsDocument, options)
}
export function useMultiLayerCostSegmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MultiLayerCostSegmentsQuery,
    MultiLayerCostSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    MultiLayerCostSegmentsQuery,
    MultiLayerCostSegmentsQueryVariables
  >(MultiLayerCostSegmentsDocument, options)
}
export type MultiLayerCostSegmentsQueryHookResult = ReturnType<
  typeof useMultiLayerCostSegmentsQuery
>
export type MultiLayerCostSegmentsLazyQueryHookResult = ReturnType<
  typeof useMultiLayerCostSegmentsLazyQuery
>
export type MultiLayerCostSegmentsQueryResult = ApolloReactCommon.QueryResult<
  MultiLayerCostSegmentsQuery,
  MultiLayerCostSegmentsQueryVariables
>
export const GetMultiTierCostAllocationDocument = gql`
  query getMultiTierCostAllocation($input: MultiTierCostAllocationInput) {
    multiTierCostAllocation(input: $input) {
      allocation {
        name
        code
        segmentId
        allocationId
        ancestors {
          name
          segmentId
          allocationId
        }
      }
      totalResults
    }
  }
`

/**
 * __useGetMultiTierCostAllocationQuery__
 *
 * To run a query within a React component, call `useGetMultiTierCostAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiTierCostAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiTierCostAllocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMultiTierCostAllocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMultiTierCostAllocationQuery,
    GetMultiTierCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetMultiTierCostAllocationQuery,
    GetMultiTierCostAllocationQueryVariables
  >(GetMultiTierCostAllocationDocument, options)
}
export function useGetMultiTierCostAllocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMultiTierCostAllocationQuery,
    GetMultiTierCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetMultiTierCostAllocationQuery,
    GetMultiTierCostAllocationQueryVariables
  >(GetMultiTierCostAllocationDocument, options)
}
export type GetMultiTierCostAllocationQueryHookResult = ReturnType<
  typeof useGetMultiTierCostAllocationQuery
>
export type GetMultiTierCostAllocationLazyQueryHookResult = ReturnType<
  typeof useGetMultiTierCostAllocationLazyQuery
>
export type GetMultiTierCostAllocationQueryResult = ApolloReactCommon.QueryResult<
  GetMultiTierCostAllocationQuery,
  GetMultiTierCostAllocationQueryVariables
>
export const GetPreBookInfoDocument = gql`
  query getPreBookInfo($input: BookItineraryInput!) {
    preBookInfo(input: $input) {
      isBookingAllowed
      isTermsUserInteractionAllowed
      termsAndConditions {
        format
        title
        content
      }
    }
  }
`

/**
 * __useGetPreBookInfoQuery__
 *
 * To run a query within a React component, call `useGetPreBookInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreBookInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreBookInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPreBookInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetPreBookInfoQuery, GetPreBookInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetPreBookInfoQuery, GetPreBookInfoQueryVariables>(
    GetPreBookInfoDocument,
    options,
  )
}
export function useGetPreBookInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPreBookInfoQuery,
    GetPreBookInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetPreBookInfoQuery, GetPreBookInfoQueryVariables>(
    GetPreBookInfoDocument,
    options,
  )
}
export type GetPreBookInfoQueryHookResult = ReturnType<typeof useGetPreBookInfoQuery>
export type GetPreBookInfoLazyQueryHookResult = ReturnType<typeof useGetPreBookInfoLazyQuery>
export type GetPreBookInfoQueryResult = ApolloReactCommon.QueryResult<
  GetPreBookInfoQuery,
  GetPreBookInfoQueryVariables
>
export const GetRailCardsDocument = gql`
  query getRailCards {
    railCards {
      cards {
        groupName
        vendorName
        vendorCode
        name
        code
      }
    }
  }
`

/**
 * __useGetRailCardsQuery__
 *
 * To run a query within a React component, call `useGetRailCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRailCardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetRailCardsQuery, GetRailCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRailCardsQuery, GetRailCardsQueryVariables>(
    GetRailCardsDocument,
    options,
  )
}
export function useGetRailCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRailCardsQuery,
    GetRailCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetRailCardsQuery, GetRailCardsQueryVariables>(
    GetRailCardsDocument,
    options,
  )
}
export type GetRailCardsQueryHookResult = ReturnType<typeof useGetRailCardsQuery>
export type GetRailCardsLazyQueryHookResult = ReturnType<typeof useGetRailCardsLazyQuery>
export type GetRailCardsQueryResult = ApolloReactCommon.QueryResult<
  GetRailCardsQuery,
  GetRailCardsQueryVariables
>
export const GetRailFareAttributesDocument = gql`
  query getRailFareAttributes($input: RailFareAttributesInput!) {
    railFareAttributes(input: $input) {
      fareAttributes {
        fareId
        comparison {
          brand {
            name
            codes
          }
          utas {
            category
            headline
            description
            assessment
            callToAction {
              text
              url
            }
            feeRange {
              amount {
                min
                max
              }
              currency
            }
            bagLimits {
              weightKg
              sizeLinearCm
            }
          }
        }
        content {
          location
          severity
          display
          linkTitle
          items {
            headline
            body
            details {
              body
              image {
                medium
                large
                thumbnail
              }
            }
            categories
          }
        }
      }
    }
  }
`

/**
 * __useGetRailFareAttributesQuery__
 *
 * To run a query within a React component, call `useGetRailFareAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailFareAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailFareAttributesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRailFareAttributesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRailFareAttributesQuery,
    GetRailFareAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRailFareAttributesQuery, GetRailFareAttributesQueryVariables>(
    GetRailFareAttributesDocument,
    options,
  )
}
export function useGetRailFareAttributesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRailFareAttributesQuery,
    GetRailFareAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRailFareAttributesQuery,
    GetRailFareAttributesQueryVariables
  >(GetRailFareAttributesDocument, options)
}
export type GetRailFareAttributesQueryHookResult = ReturnType<typeof useGetRailFareAttributesQuery>
export type GetRailFareAttributesLazyQueryHookResult = ReturnType<
  typeof useGetRailFareAttributesLazyQuery
>
export type GetRailFareAttributesQueryResult = ApolloReactCommon.QueryResult<
  GetRailFareAttributesQuery,
  GetRailFareAttributesQueryVariables
>
export const GetRailFareConditionsDocument = gql`
  query getRailFareConditions($input: RailFareConditionsInput!) {
    railFareConditions(input: $input) {
      fareConditions {
        tier {
          id
          name
        }
        localizedFareConditions {
          fareTypeDescription
          conditions {
            name
            description
          }
          summaryConditions
          validityPeriod
          language
        }
      }
    }
  }
`

/**
 * __useGetRailFareConditionsQuery__
 *
 * To run a query within a React component, call `useGetRailFareConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailFareConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailFareConditionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRailFareConditionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRailFareConditionsQuery,
    GetRailFareConditionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRailFareConditionsQuery, GetRailFareConditionsQueryVariables>(
    GetRailFareConditionsDocument,
    options,
  )
}
export function useGetRailFareConditionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRailFareConditionsQuery,
    GetRailFareConditionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRailFareConditionsQuery,
    GetRailFareConditionsQueryVariables
  >(GetRailFareConditionsDocument, options)
}
export type GetRailFareConditionsQueryHookResult = ReturnType<typeof useGetRailFareConditionsQuery>
export type GetRailFareConditionsLazyQueryHookResult = ReturnType<
  typeof useGetRailFareConditionsLazyQuery
>
export type GetRailFareConditionsQueryResult = ApolloReactCommon.QueryResult<
  GetRailFareConditionsQuery,
  GetRailFareConditionsQueryVariables
>
export const GetRailJourneyPreferencesDocument = gql`
  query getRailJourneyPreferences($input: RailJourneyPreferencesInput!) {
    railJourneyPreferences(input: $input) {
      deliveryOptions {
        name
        code
      }
      seatPreferences {
        seatPositions {
          code
          name
        }
        seatDirections {
          code
          name
        }
        carriageTypes {
          code
          name
        }
        seatFacilities {
          code
          name
        }
      }
      inwardSeatReservationType
      outwardSeatReservationType
    }
  }
`

/**
 * __useGetRailJourneyPreferencesQuery__
 *
 * To run a query within a React component, call `useGetRailJourneyPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailJourneyPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailJourneyPreferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRailJourneyPreferencesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRailJourneyPreferencesQuery,
    GetRailJourneyPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRailJourneyPreferencesQuery,
    GetRailJourneyPreferencesQueryVariables
  >(GetRailJourneyPreferencesDocument, options)
}
export function useGetRailJourneyPreferencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRailJourneyPreferencesQuery,
    GetRailJourneyPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRailJourneyPreferencesQuery,
    GetRailJourneyPreferencesQueryVariables
  >(GetRailJourneyPreferencesDocument, options)
}
export type GetRailJourneyPreferencesQueryHookResult = ReturnType<
  typeof useGetRailJourneyPreferencesQuery
>
export type GetRailJourneyPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetRailJourneyPreferencesLazyQuery
>
export type GetRailJourneyPreferencesQueryResult = ApolloReactCommon.QueryResult<
  GetRailJourneyPreferencesQuery,
  GetRailJourneyPreferencesQueryVariables
>
export const GetReferencePointsDocument = gql`
  query getReferencePoints($latitude: Float!, $longitude: Float!, $pageSize: Int) {
    referencePoints(latitude: $latitude, longitude: $longitude, pageSize: $pageSize) {
      actualPageSize
      referencePoints {
        address {
          geocode {
            lat
            long
          }
          street1
          city
          stateCode
          postalCode
          countryCode
        }
        label
      }
    }
  }
`

/**
 * __useGetReferencePointsQuery__
 *
 * To run a query within a React component, call `useGetReferencePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencePointsQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetReferencePointsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetReferencePointsQuery,
    GetReferencePointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetReferencePointsQuery, GetReferencePointsQueryVariables>(
    GetReferencePointsDocument,
    options,
  )
}
export function useGetReferencePointsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReferencePointsQuery,
    GetReferencePointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetReferencePointsQuery, GetReferencePointsQueryVariables>(
    GetReferencePointsDocument,
    options,
  )
}
export type GetReferencePointsQueryHookResult = ReturnType<typeof useGetReferencePointsQuery>
export type GetReferencePointsLazyQueryHookResult = ReturnType<
  typeof useGetReferencePointsLazyQuery
>
export type GetReferencePointsQueryResult = ApolloReactCommon.QueryResult<
  GetReferencePointsQuery,
  GetReferencePointsQueryVariables
>
export const GetRideHailSuggestedLocationsDocument = gql`
  query getRideHailSuggestedLocations($input: RideHailSuggestedLocationsRequest!) {
    rideHailSuggestedLocations(input: $input) {
      __typename
      ... on RideHailSuggestedLocationsError {
        reason
      }
      ... on RideHailSuggestedLocationsResponse {
        rideHailSuggestedLocations {
          name
          location {
            geocode {
              lat
              long
            }
            address
          }
          suggestedLocationType
          countryCode
          airportCode
        }
      }
    }
  }
`

/**
 * __useGetRideHailSuggestedLocationsQuery__
 *
 * To run a query within a React component, call `useGetRideHailSuggestedLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRideHailSuggestedLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRideHailSuggestedLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRideHailSuggestedLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRideHailSuggestedLocationsQuery,
    GetRideHailSuggestedLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRideHailSuggestedLocationsQuery,
    GetRideHailSuggestedLocationsQueryVariables
  >(GetRideHailSuggestedLocationsDocument, options)
}
export function useGetRideHailSuggestedLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRideHailSuggestedLocationsQuery,
    GetRideHailSuggestedLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRideHailSuggestedLocationsQuery,
    GetRideHailSuggestedLocationsQueryVariables
  >(GetRideHailSuggestedLocationsDocument, options)
}
export type GetRideHailSuggestedLocationsQueryHookResult = ReturnType<
  typeof useGetRideHailSuggestedLocationsQuery
>
export type GetRideHailSuggestedLocationsLazyQueryHookResult = ReturnType<
  typeof useGetRideHailSuggestedLocationsLazyQuery
>
export type GetRideHailSuggestedLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetRideHailSuggestedLocationsQuery,
  GetRideHailSuggestedLocationsQueryVariables
>
export const GetRideHailSuggestionsDocument = gql`
  query getRideHailSuggestions {
    rideHailSuggestions {
      rideHailSuggestions {
        canBeBookedWithinEtta
        pickUpName
        pickUpDate
        pickUpTime
        pickUpTimeZoneUtcOffset
        pickUpLocation {
          geocode {
            lat
            long
          }
          address
        }
        pickUpLocationType
        pickUpSegmentId
        pickUpSegmentType
        pickUpSegmentEndDate
        pickUpSegmentEndTime
        pickUpSegmentEndTimeZoneUtcOffset
        dropOffName
        dropOffDate
        dropOffTime
        dropOffTimeZoneUtcOffset
        dropOffLocation {
          geocode {
            lat
            long
          }
          address
        }
        dropOffLocationType
        dropOffSegmentId
        dropOffSegmentType
        dropOffSegmentStartDate
        dropOffSegmentStartTime
        dropOffSegmentStartTimeZoneUtcOffset
        processId
      }
    }
  }
`

/**
 * __useGetRideHailSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetRideHailSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRideHailSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRideHailSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRideHailSuggestionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRideHailSuggestionsQuery,
    GetRideHailSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRideHailSuggestionsQuery,
    GetRideHailSuggestionsQueryVariables
  >(GetRideHailSuggestionsDocument, options)
}
export function useGetRideHailSuggestionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRideHailSuggestionsQuery,
    GetRideHailSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRideHailSuggestionsQuery,
    GetRideHailSuggestionsQueryVariables
  >(GetRideHailSuggestionsDocument, options)
}
export type GetRideHailSuggestionsQueryHookResult = ReturnType<
  typeof useGetRideHailSuggestionsQuery
>
export type GetRideHailSuggestionsLazyQueryHookResult = ReturnType<
  typeof useGetRideHailSuggestionsLazyQuery
>
export type GetRideHailSuggestionsQueryResult = ApolloReactCommon.QueryResult<
  GetRideHailSuggestionsQuery,
  GetRideHailSuggestionsQueryVariables
>
export const GetRoomDetailsDocument = gql`
  query getRoomDetails(
    $logsMetadata: LogsMetadata
    $hotelResultId: String
    $hotelId: ID!
    $roomId: ID!
    $checkIn: String!
    $checkOut: String!
  ) {
    roomDetails(
      logsMetadata: $logsMetadata
      hotelResultId: $hotelResultId
      hotelId: $hotelId
      roomId: $roomId
      checkIn: $checkIn
      checkOut: $checkOut
    ) {
      id
      rateDetails
    }
  }
`

/**
 * __useGetRoomDetailsQuery__
 *
 * To run a query within a React component, call `useGetRoomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomDetailsQuery({
 *   variables: {
 *      logsMetadata: // value for 'logsMetadata'
 *      hotelResultId: // value for 'hotelResultId'
 *      hotelId: // value for 'hotelId'
 *      roomId: // value for 'roomId'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *   },
 * });
 */
export function useGetRoomDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetRoomDetailsQuery, GetRoomDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRoomDetailsQuery, GetRoomDetailsQueryVariables>(
    GetRoomDetailsDocument,
    options,
  )
}
export function useGetRoomDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRoomDetailsQuery,
    GetRoomDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetRoomDetailsQuery, GetRoomDetailsQueryVariables>(
    GetRoomDetailsDocument,
    options,
  )
}
export type GetRoomDetailsQueryHookResult = ReturnType<typeof useGetRoomDetailsQuery>
export type GetRoomDetailsLazyQueryHookResult = ReturnType<typeof useGetRoomDetailsLazyQuery>
export type GetRoomDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetRoomDetailsQuery,
  GetRoomDetailsQueryVariables
>
export const GetSafetyCheckDocument = gql`
  query getSafetyCheck($lat: Float!, $lng: Float!) {
    geosureScores(latitude: $lat, longitude: $lng) {
      compositeScore
      distance
      district
      city
      scores {
        value
        label
      }
      covid19Summary {
        country {
          ...CovidStatisticItem
        }
        county {
          ...CovidStatisticItem
        }
        state {
          ...CovidStatisticItem
        }
        province {
          ...CovidStatisticItem
        }
      }
    }
  }
  ${CovidStatisticItemFragmentDoc}
`

/**
 * __useGetSafetyCheckQuery__
 *
 * To run a query within a React component, call `useGetSafetyCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSafetyCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSafetyCheckQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useGetSafetyCheckQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetSafetyCheckQuery, GetSafetyCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSafetyCheckQuery, GetSafetyCheckQueryVariables>(
    GetSafetyCheckDocument,
    options,
  )
}
export function useGetSafetyCheckLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSafetyCheckQuery,
    GetSafetyCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetSafetyCheckQuery, GetSafetyCheckQueryVariables>(
    GetSafetyCheckDocument,
    options,
  )
}
export type GetSafetyCheckQueryHookResult = ReturnType<typeof useGetSafetyCheckQuery>
export type GetSafetyCheckLazyQueryHookResult = ReturnType<typeof useGetSafetyCheckLazyQuery>
export type GetSafetyCheckQueryResult = ApolloReactCommon.QueryResult<
  GetSafetyCheckQuery,
  GetSafetyCheckQueryVariables
>
export const GetSiteSsoSettingsDocument = gql`
  query getSiteSsoSettings {
    siteSsoSettings {
      sessionTimeoutRedirectURL
      logoutRedirectURL
    }
  }
`

/**
 * __useGetSiteSsoSettingsQuery__
 *
 * To run a query within a React component, call `useGetSiteSsoSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSsoSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSsoSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteSsoSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSiteSsoSettingsQuery,
    GetSiteSsoSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSiteSsoSettingsQuery, GetSiteSsoSettingsQueryVariables>(
    GetSiteSsoSettingsDocument,
    options,
  )
}
export function useGetSiteSsoSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSiteSsoSettingsQuery,
    GetSiteSsoSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetSiteSsoSettingsQuery, GetSiteSsoSettingsQueryVariables>(
    GetSiteSsoSettingsDocument,
    options,
  )
}
export type GetSiteSsoSettingsQueryHookResult = ReturnType<typeof useGetSiteSsoSettingsQuery>
export type GetSiteSsoSettingsLazyQueryHookResult = ReturnType<
  typeof useGetSiteSsoSettingsLazyQuery
>
export type GetSiteSsoSettingsQueryResult = ApolloReactCommon.QueryResult<
  GetSiteSsoSettingsQuery,
  GetSiteSsoSettingsQueryVariables
>
export const GetSupportArticleByIdDocument = gql`
  query getSupportArticleById($input: SupportArticleByIdInput!) {
    supportArticleById(input: $input) {
      id
      title
      body
      image {
        url
        alt
        title
      }
      isFeatured
    }
  }
`

/**
 * __useGetSupportArticleByIdQuery__
 *
 * To run a query within a React component, call `useGetSupportArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportArticleByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportArticleByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSupportArticleByIdQuery,
    GetSupportArticleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSupportArticleByIdQuery, GetSupportArticleByIdQueryVariables>(
    GetSupportArticleByIdDocument,
    options,
  )
}
export function useGetSupportArticleByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSupportArticleByIdQuery,
    GetSupportArticleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSupportArticleByIdQuery,
    GetSupportArticleByIdQueryVariables
  >(GetSupportArticleByIdDocument, options)
}
export type GetSupportArticleByIdQueryHookResult = ReturnType<typeof useGetSupportArticleByIdQuery>
export type GetSupportArticleByIdLazyQueryHookResult = ReturnType<
  typeof useGetSupportArticleByIdLazyQuery
>
export type GetSupportArticleByIdQueryResult = ApolloReactCommon.QueryResult<
  GetSupportArticleByIdQuery,
  GetSupportArticleByIdQueryVariables
>
export const GetSupportArticlesDocument = gql`
  query getSupportArticles($input: SupportArticlesInput!) {
    supportArticles(input: $input) {
      id
      title
      body
      image {
        url
        alt
        title
      }
      isFeatured
    }
  }
`

/**
 * __useGetSupportArticlesQuery__
 *
 * To run a query within a React component, call `useGetSupportArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportArticlesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportArticlesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSupportArticlesQuery,
    GetSupportArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSupportArticlesQuery, GetSupportArticlesQueryVariables>(
    GetSupportArticlesDocument,
    options,
  )
}
export function useGetSupportArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSupportArticlesQuery,
    GetSupportArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetSupportArticlesQuery, GetSupportArticlesQueryVariables>(
    GetSupportArticlesDocument,
    options,
  )
}
export type GetSupportArticlesQueryHookResult = ReturnType<typeof useGetSupportArticlesQuery>
export type GetSupportArticlesLazyQueryHookResult = ReturnType<
  typeof useGetSupportArticlesLazyQuery
>
export type GetSupportArticlesQueryResult = ApolloReactCommon.QueryResult<
  GetSupportArticlesQuery,
  GetSupportArticlesQueryVariables
>
export const GetSupportInformationDocument = gql`
  query getSupportInformation {
    supportInformation {
      phoneNumbers {
        label
        phoneNumber
      }
      email {
        label
        emailAddress
      }
    }
  }
`

/**
 * __useGetSupportInformationQuery__
 *
 * To run a query within a React component, call `useGetSupportInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSupportInformationQuery,
    GetSupportInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSupportInformationQuery, GetSupportInformationQueryVariables>(
    GetSupportInformationDocument,
    options,
  )
}
export function useGetSupportInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSupportInformationQuery,
    GetSupportInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSupportInformationQuery,
    GetSupportInformationQueryVariables
  >(GetSupportInformationDocument, options)
}
export type GetSupportInformationQueryHookResult = ReturnType<typeof useGetSupportInformationQuery>
export type GetSupportInformationLazyQueryHookResult = ReturnType<
  typeof useGetSupportInformationLazyQuery
>
export type GetSupportInformationQueryResult = ApolloReactCommon.QueryResult<
  GetSupportInformationQuery,
  GetSupportInformationQueryVariables
>
export const GetTravelPolicyListDocument = gql`
  query getTravelPolicyList {
    travelPolicyList {
      companyName
      policyList
    }
  }
`

/**
 * __useGetTravelPolicyListQuery__
 *
 * To run a query within a React component, call `useGetTravelPolicyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTravelPolicyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTravelPolicyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTravelPolicyListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTravelPolicyListQuery,
    GetTravelPolicyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTravelPolicyListQuery, GetTravelPolicyListQueryVariables>(
    GetTravelPolicyListDocument,
    options,
  )
}
export function useGetTravelPolicyListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTravelPolicyListQuery,
    GetTravelPolicyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTravelPolicyListQuery, GetTravelPolicyListQueryVariables>(
    GetTravelPolicyListDocument,
    options,
  )
}
export type GetTravelPolicyListQueryHookResult = ReturnType<typeof useGetTravelPolicyListQuery>
export type GetTravelPolicyListLazyQueryHookResult = ReturnType<
  typeof useGetTravelPolicyListLazyQuery
>
export type GetTravelPolicyListQueryResult = ApolloReactCommon.QueryResult<
  GetTravelPolicyListQuery,
  GetTravelPolicyListQueryVariables
>
export const CreateAirSearchDocument = gql`
  mutation createAirSearch($input: CreateFlightSearchInput!) {
    createFlightSearch(input: $input) {
      id
    }
  }
`
export type CreateAirSearchMutationFn = ApolloReactCommon.MutationFunction<
  CreateAirSearchMutation,
  CreateAirSearchMutationVariables
>

/**
 * __useCreateAirSearchMutation__
 *
 * To run a mutation, you first call `useCreateAirSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAirSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAirSearchMutation, { data, loading, error }] = useCreateAirSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAirSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateAirSearchMutation,
    CreateAirSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateAirSearchMutation, CreateAirSearchMutationVariables>(
    CreateAirSearchDocument,
    options,
  )
}
export type CreateAirSearchMutationHookResult = ReturnType<typeof useCreateAirSearchMutation>
export type CreateAirSearchMutationResult = ApolloReactCommon.MutationResult<CreateAirSearchMutation>
export type CreateAirSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAirSearchMutation,
  CreateAirSearchMutationVariables
>
export const GetFlightFareAttributesDocument = gql`
  query getFlightFareAttributes($input: FlightFareAttributesInBatchInput!) {
    flightFareAttributesInBatch(input: $input) {
      legId
      fareAttributes {
        fareId
        comparison {
          brand {
            name
          }
          utas {
            category
            description
            assessment
          }
        }
        content {
          location
          items {
            headline
            body
            details {
              body
              image {
                thumbnail
              }
            }
            callToAction {
              url
            }
          }
          segmentIndex
          linkIconClass
        }
      }
    }
  }
`

/**
 * __useGetFlightFareAttributesQuery__
 *
 * To run a query within a React component, call `useGetFlightFareAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightFareAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightFareAttributesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightFareAttributesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightFareAttributesQuery,
    GetFlightFareAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetFlightFareAttributesQuery,
    GetFlightFareAttributesQueryVariables
  >(GetFlightFareAttributesDocument, options)
}
export function useGetFlightFareAttributesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightFareAttributesQuery,
    GetFlightFareAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetFlightFareAttributesQuery,
    GetFlightFareAttributesQueryVariables
  >(GetFlightFareAttributesDocument, options)
}
export type GetFlightFareAttributesQueryHookResult = ReturnType<
  typeof useGetFlightFareAttributesQuery
>
export type GetFlightFareAttributesLazyQueryHookResult = ReturnType<
  typeof useGetFlightFareAttributesLazyQuery
>
export type GetFlightFareAttributesQueryResult = ApolloReactCommon.QueryResult<
  GetFlightFareAttributesQuery,
  GetFlightFareAttributesQueryVariables
>
export const GetAlternatePolicyTripsDocument = gql`
  query getAlternatePolicyTrips($input: GetAlternatePolicyTripsInput!) {
    getAlternatePolicyTrips(input: $input) {
      __typename
      ... on GetAlternatePolicyTripsResponse {
        policyTrips {
          flightLegs {
            legId
            flightTime {
              hours
              minutes
            }
            policy {
              ...policyTrips
            }
            totalTime {
              hours
              minutes
            }
            daysInBetween
            stops
            flightStops
            origin
            destination
            departureDate
            arrivalDate
            rate {
              ...rateTrips
            }
            distance {
              value
              units
            }
            segments {
              departureDate
              arrivalDate
              id
              origin {
                code
                name
                city
                terminal
              }
              destination {
                code
                name
                city
                terminal
              }
              percentageOnTime
              carrier {
                id
                name
                imageUrl
                logoImageUrl
                logoRectangleImageUrl
              }
              amenities {
                id
                name
              }
              preference {
                id
                code
                highlight
                ownerType
                ownerName
                tooltipText
                customBadge {
                  name
                  color
                }
                shouldShowDefaultPreference
              }
              flightNumber
              flightTime {
                hours
                minutes
              }
              aircraft {
                code
                name
              }
              codeshareCarrier {
                name
                code
              }
              isSeatMapAvailable
              isSeatSelectable
              preferredBadgeText
              isPreferredAny
              destinationTerminal
              originTerminal
              isDepartAirportDifferentFromConnecting
              isInstantPurchaseCarrier
            }
            fares {
              tripId
              tier {
                id
                name
              }
              total {
                ...rateTrips
              }
              segments {
                segmentId
                serviceClass
                bookingCode
                basisCode
                privateCode
                displayServiceClass
              }
              restriction
              isRefundable
              isWebFare
              showOutOfPolicy
              isSelectable
              isNDCFare
              providerAttributesStr
              rulesAndRestrictions {
                id
                type
                rulesAndRestriction
                penaltyFee {
                  ...rateTrips
                }
              }
              unusedTickets {
                min {
                  ...rateTrips
                }
                max {
                  ...rateTrips
                }
                unusedTicket {
                  airlineCode
                  ticketNumber
                  recordLocator
                  expirationDate
                  originalCost {
                    code
                    amount
                    requestedCurrencyUnit
                    requestedCurrencyAmount
                  }
                  changeFee {
                    code
                    amount
                    requestedCurrencyUnit
                    requestedCurrencyAmount
                  }
                  status
                  validated {
                    original {
                      ...rateTrips
                    }
                    penalty {
                      ...rateTrips
                    }
                    newTicket {
                      ...rateTrips
                    }
                    exchangedTicket {
                      ...rateTrips
                    }
                    residual {
                      ...rateTrips
                    }
                    addCollect {
                      ...rateTrips
                    }
                    forfeiture {
                      ...rateTrips
                    }
                    total {
                      ...rateTrips
                    }
                  }
                  unvalidated {
                    original {
                      ...rateTrips
                    }
                    penalty {
                      ...rateTrips
                    }
                    newTicket {
                      ...rateTrips
                    }
                    exchangedTicket {
                      ...rateTrips
                    }
                    residual {
                      ...rateTrips
                    }
                    addCollect {
                      ...rateTrips
                    }
                    forfeiture {
                      ...rateTrips
                    }
                    total {
                      ...rateTrips
                    }
                  }
                  airline {
                    code
                    value
                    imageUrl
                  }
                }
              }
              isUnusedTicketApplicable
              fareLabel
              policy {
                ...policyTrips
              }
            }
            connectionInfo {
              segmentId
              connectionTimeMinutes
            }
            isSelectable
            isUnusedTicketApplicable
            hasTechnicalStop
          }
          tripId
        }
      }
      ... on GetAlternatePolicyTripsErrorResponse {
        reason
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
  ${PolicyTripsFragmentDoc}
  ${RateTripsFragmentDoc}
`

/**
 * __useGetAlternatePolicyTripsQuery__
 *
 * To run a query within a React component, call `useGetAlternatePolicyTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlternatePolicyTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlternatePolicyTripsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAlternatePolicyTripsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAlternatePolicyTripsQuery,
    GetAlternatePolicyTripsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetAlternatePolicyTripsQuery,
    GetAlternatePolicyTripsQueryVariables
  >(GetAlternatePolicyTripsDocument, options)
}
export function useGetAlternatePolicyTripsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAlternatePolicyTripsQuery,
    GetAlternatePolicyTripsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetAlternatePolicyTripsQuery,
    GetAlternatePolicyTripsQueryVariables
  >(GetAlternatePolicyTripsDocument, options)
}
export type GetAlternatePolicyTripsQueryHookResult = ReturnType<
  typeof useGetAlternatePolicyTripsQuery
>
export type GetAlternatePolicyTripsLazyQueryHookResult = ReturnType<
  typeof useGetAlternatePolicyTripsLazyQuery
>
export type GetAlternatePolicyTripsQueryResult = ApolloReactCommon.QueryResult<
  GetAlternatePolicyTripsQuery,
  GetAlternatePolicyTripsQueryVariables
>
export const GetFlightDynamicSiteMessagesDocument = gql`
  query getFlightDynamicSiteMessages($input: GetFlightDynamicSiteMessagesInputDto!) {
    getFlightDynamicSiteMessages(GetFlightDynamicSiteMessagesInputDto: $input) {
      messages {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
    }
  }
`

/**
 * __useGetFlightDynamicSiteMessagesQuery__
 *
 * To run a query within a React component, call `useGetFlightDynamicSiteMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightDynamicSiteMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightDynamicSiteMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightDynamicSiteMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightDynamicSiteMessagesQuery,
    GetFlightDynamicSiteMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetFlightDynamicSiteMessagesQuery,
    GetFlightDynamicSiteMessagesQueryVariables
  >(GetFlightDynamicSiteMessagesDocument, options)
}
export function useGetFlightDynamicSiteMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightDynamicSiteMessagesQuery,
    GetFlightDynamicSiteMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetFlightDynamicSiteMessagesQuery,
    GetFlightDynamicSiteMessagesQueryVariables
  >(GetFlightDynamicSiteMessagesDocument, options)
}
export type GetFlightDynamicSiteMessagesQueryHookResult = ReturnType<
  typeof useGetFlightDynamicSiteMessagesQuery
>
export type GetFlightDynamicSiteMessagesLazyQueryHookResult = ReturnType<
  typeof useGetFlightDynamicSiteMessagesLazyQuery
>
export type GetFlightDynamicSiteMessagesQueryResult = ApolloReactCommon.QueryResult<
  GetFlightDynamicSiteMessagesQuery,
  GetFlightDynamicSiteMessagesQueryVariables
>
export const GetFlightsDocument = gql`
  query getFlights($input: FlightsInput!) {
    flights(input: $input) {
      totalCount
      pageStart
      pageEnd
      maxResultsSize
      isCustomTimeReplaced
      flightLegs {
        legId
        flightTime {
          hours
          minutes
        }
        totalTime {
          hours
          minutes
        }
        daysInBetween
        flightStops
        stops
        origin
        destination
        departureDate
        arrivalDate
        policy {
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyReasons
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        distance {
          value
          units
        }
        segments {
          id
          flightTime {
            hours
            minutes
          }
          origin {
            city
            terminal
            code
            name
          }
          destination {
            city
            terminal
            code
            name
          }
          departureDate
          arrivalDate
          percentageOnTime
          carrier {
            id
            name
            imageUrl
            logoImageUrl
            logoRectangleImageUrl
          }
          amenities {
            id
            name
          }
          preference {
            code
            highlight
            ownerType
            ownerName
            tooltipText
            customBadge {
              name
              color
            }
          }
          flightNumber
          aircraft {
            code
            name
          }
          codeshareCarrier {
            name
            code
          }
          isSeatMapAvailable
          isSeatSelectable
          preferredBadgeText
          isPreferredAny
          isDepartAirportDifferentFromConnecting
          isInstantPurchaseCarrier
        }
        fares {
          fareId
          tripId
          tier {
            id
            name
          }
          total {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          segments {
            segmentId
            serviceClass
            bookingCode
            basisCode
            privateCode
            displayServiceClass
          }
          restriction
          isRefundable
          isWebFare
          showOutOfPolicy
          isNDCFare
          providerAttributesStr
          policy {
            isInPolicy
            outOfPolicyData {
              type
              data
              currency
            }
            outOfPolicyReasons
          }
          rulesAndRestrictions {
            id
            type
            rulesAndRestriction
            penaltyFee {
              primary {
                amount
                currency
              }
              secondary {
                amount
                currency
              }
            }
          }
          isSelectable
          isUnusedTicketApplicable
          fareLabel
          unusedTickets {
            min {
              ...rate
            }
            max {
              ...rate
            }
            unusedTicket {
              validated {
                original {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                penalty {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                newTicket {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                exchangedTicket {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                residual {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                addCollect {
                  primary {
                    currency
                    amount
                  }
                  secondary {
                    currency
                    amount
                  }
                }
                forfeiture {
                  secondary {
                    amount
                    currency
                  }
                  primary {
                    amount
                    currency
                  }
                }
                total {
                  secondary {
                    amount
                    currency
                  }
                  primary {
                    currency
                    amount
                  }
                }
              }
              unvalidated {
                original {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                penalty {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                newTicket {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                exchangedTicket {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                residual {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                addCollect {
                  primary {
                    currency
                    amount
                  }
                  secondary {
                    currency
                    amount
                  }
                }
                forfeiture {
                  secondary {
                    amount
                    currency
                  }
                  primary {
                    amount
                    currency
                  }
                }
                total {
                  secondary {
                    amount
                    currency
                  }
                  primary {
                    currency
                    amount
                  }
                }
              }
            }
          }
        }
        connectionInfo {
          segmentId
          connectionTimeMinutes
        }
        hasTechnicalStop
        isSelectable
        isUnusedTicketApplicable
      }
      messages {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
      filters {
        airlines {
          id
          value
          name
          imageUrl
        }
        airport {
          id
          origin {
            code
            name
            city
          }
          destination {
            code
            name
            city
          }
        }
        stops {
          id
          value
          isSelected
        }
        fareTier {
          id
          value
          displayName
        }
        originTimeRange {
          start
          end
        }
        destinationTime {
          start
          end
        }
      }
      isDsmEnabled
    }
  }
  ${RateFragmentDoc}
`

/**
 * __useGetFlightsQuery__
 *
 * To run a query within a React component, call `useGetFlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFlightsQuery, GetFlightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFlightsQuery, GetFlightsQueryVariables>(
    GetFlightsDocument,
    options,
  )
}
export function useGetFlightsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFlightsQuery, GetFlightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFlightsQuery, GetFlightsQueryVariables>(
    GetFlightsDocument,
    options,
  )
}
export type GetFlightsQueryHookResult = ReturnType<typeof useGetFlightsQuery>
export type GetFlightsLazyQueryHookResult = ReturnType<typeof useGetFlightsLazyQuery>
export type GetFlightsQueryResult = ApolloReactCommon.QueryResult<
  GetFlightsQuery,
  GetFlightsQueryVariables
>
export const GetHomeAirportDocument = gql`
  query getHomeAirport {
    homeAirport {
      placeId
      airportCode
      name
      latitude
      longitude
      address {
        city
        countryCode
        stateCode
        postalCode
        street1
        lat
        lng
      }
      placeType
      timezone
    }
  }
`

/**
 * __useGetHomeAirportQuery__
 *
 * To run a query within a React component, call `useGetHomeAirportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeAirportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeAirportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeAirportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHomeAirportQuery,
    GetHomeAirportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHomeAirportQuery, GetHomeAirportQueryVariables>(
    GetHomeAirportDocument,
    options,
  )
}
export function useGetHomeAirportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHomeAirportQuery,
    GetHomeAirportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHomeAirportQuery, GetHomeAirportQueryVariables>(
    GetHomeAirportDocument,
    options,
  )
}
export type GetHomeAirportQueryHookResult = ReturnType<typeof useGetHomeAirportQuery>
export type GetHomeAirportLazyQueryHookResult = ReturnType<typeof useGetHomeAirportLazyQuery>
export type GetHomeAirportQueryResult = ApolloReactCommon.QueryResult<
  GetHomeAirportQuery,
  GetHomeAirportQueryVariables
>
export const GetSitesWithActiveUserAccountsDocument = gql`
  query getSitesWithActiveUserAccounts($input: SitesByUserEmailInput!) {
    sitesByUserEmail(input: $input) {
      siteId
      siteName
      ssoType
      mobileSsoEnabled
      companySsoEndpointUrl
      url
    }
  }
`

/**
 * __useGetSitesWithActiveUserAccountsQuery__
 *
 * To run a query within a React component, call `useGetSitesWithActiveUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesWithActiveUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesWithActiveUserAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSitesWithActiveUserAccountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSitesWithActiveUserAccountsQuery,
    GetSitesWithActiveUserAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetSitesWithActiveUserAccountsQuery,
    GetSitesWithActiveUserAccountsQueryVariables
  >(GetSitesWithActiveUserAccountsDocument, options)
}
export function useGetSitesWithActiveUserAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSitesWithActiveUserAccountsQuery,
    GetSitesWithActiveUserAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSitesWithActiveUserAccountsQuery,
    GetSitesWithActiveUserAccountsQueryVariables
  >(GetSitesWithActiveUserAccountsDocument, options)
}
export type GetSitesWithActiveUserAccountsQueryHookResult = ReturnType<
  typeof useGetSitesWithActiveUserAccountsQuery
>
export type GetSitesWithActiveUserAccountsLazyQueryHookResult = ReturnType<
  typeof useGetSitesWithActiveUserAccountsLazyQuery
>
export type GetSitesWithActiveUserAccountsQueryResult = ApolloReactCommon.QueryResult<
  GetSitesWithActiveUserAccountsQuery,
  GetSitesWithActiveUserAccountsQueryVariables
>
export const LoginByCredentialsDocument = gql`
  mutation loginByCredentials($input: LoginByCredentialsInput!) {
    loginByCredentials(input: $input) {
      token
      passwordExpirationDate
      passwordExpirationDaysRemaining
    }
  }
`
export type LoginByCredentialsMutationFn = ApolloReactCommon.MutationFunction<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>

/**
 * __useLoginByCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginByCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByCredentialsMutation, { data, loading, error }] = useLoginByCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginByCredentialsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginByCredentialsMutation,
    LoginByCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    LoginByCredentialsMutation,
    LoginByCredentialsMutationVariables
  >(LoginByCredentialsDocument, options)
}
export type LoginByCredentialsMutationHookResult = ReturnType<typeof useLoginByCredentialsMutation>
export type LoginByCredentialsMutationResult = ApolloReactCommon.MutationResult<LoginByCredentialsMutation>
export type LoginByCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>
export const LoginByKeyDocument = gql`
  mutation loginByKey($input: LoginByKeyInput!) {
    loginByKey(input: $input) {
      token
    }
  }
`
export type LoginByKeyMutationFn = ApolloReactCommon.MutationFunction<
  LoginByKeyMutation,
  LoginByKeyMutationVariables
>

/**
 * __useLoginByKeyMutation__
 *
 * To run a mutation, you first call `useLoginByKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByKeyMutation, { data, loading, error }] = useLoginByKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginByKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginByKeyMutation,
    LoginByKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LoginByKeyMutation, LoginByKeyMutationVariables>(
    LoginByKeyDocument,
    options,
  )
}
export type LoginByKeyMutationHookResult = ReturnType<typeof useLoginByKeyMutation>
export type LoginByKeyMutationResult = ApolloReactCommon.MutationResult<LoginByKeyMutation>
export type LoginByKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginByKeyMutation,
  LoginByKeyMutationVariables
>
export const LoginBySsoTokenDocument = gql`
  mutation loginBySSOToken($input: LoginBySSOTokenInput!) {
    loginBySSOToken(input: $input) {
      token
    }
  }
`
export type LoginBySsoTokenMutationFn = ApolloReactCommon.MutationFunction<
  LoginBySsoTokenMutation,
  LoginBySsoTokenMutationVariables
>

/**
 * __useLoginBySsoTokenMutation__
 *
 * To run a mutation, you first call `useLoginBySsoTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginBySsoTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginBySsoTokenMutation, { data, loading, error }] = useLoginBySsoTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginBySsoTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginBySsoTokenMutation,
    LoginBySsoTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LoginBySsoTokenMutation, LoginBySsoTokenMutationVariables>(
    LoginBySsoTokenDocument,
    options,
  )
}
export type LoginBySsoTokenMutationHookResult = ReturnType<typeof useLoginBySsoTokenMutation>
export type LoginBySsoTokenMutationResult = ApolloReactCommon.MutationResult<LoginBySsoTokenMutation>
export type LoginBySsoTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginBySsoTokenMutation,
  LoginBySsoTokenMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPasswordWithError(input: $input) {
      __typename
      ... on ResetPasswordErrorResponse {
        securityQuestions {
          id
          question
        }
      }
      ... on ResetPasswordError {
        code
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  )
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const CheckoutCartDocument = gql`
  mutation checkoutCart($input: CheckoutCartInput!) {
    checkoutCart(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CartNotFoundResponse {
        __typename
        message
      }
      ... on CheckoutCartResponse {
        __typename
        code
        message
        reservationId
      }
    }
  }
`
export type CheckoutCartMutationFn = ApolloReactCommon.MutationFunction<
  CheckoutCartMutation,
  CheckoutCartMutationVariables
>

/**
 * __useCheckoutCartMutation__
 *
 * To run a mutation, you first call `useCheckoutCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCartMutation, { data, loading, error }] = useCheckoutCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckoutCartMutation,
    CheckoutCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CheckoutCartMutation, CheckoutCartMutationVariables>(
    CheckoutCartDocument,
    options,
  )
}
export type CheckoutCartMutationHookResult = ReturnType<typeof useCheckoutCartMutation>
export type CheckoutCartMutationResult = ApolloReactCommon.MutationResult<CheckoutCartMutation>
export type CheckoutCartMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CheckoutCartMutation,
  CheckoutCartMutationVariables
>
export const GetCheckoutInfoDocument = gql`
  query getCheckoutInfo($input: CheckoutInfoInput!) {
    getCheckoutInfo(input: $input) {
      __typename
      ... on CheckoutInfoResult {
        primaryTraveler {
          ...primaryTraveler
        }
        memberships {
          ...memberships
        }
        mealRequest {
          ...mealRequest
        }
        billing {
          ...billing
        }
        specialRequests {
          ...specialRequests
        }
        dynamicSiteMessages {
          header
          text
          mobileText
          isDialog
          hasDesktopBannerImage
        }
        customFieldsConfiguration {
          ...customFieldsConfiguration
        }
        costAllocations {
          costAllocationSegmentId
        }
        preBookRequestRequirement
        isPassportRequired
        displaySettings {
          shouldShowAirLoyaltyPreferences
          shouldShowAirMealPreferences
          shouldShowAirSpecialRequestPreferences
          shouldShowShareTravelerContact
          shouldShowNdcFareAcknowledgment
        }
        isRulesAcknowledgementRequired
      }
    }
  }
  ${PrimaryTravelerFragmentDoc}
  ${MembershipsFragmentDoc}
  ${MealRequestFragmentDoc}
  ${BillingFragmentDoc}
  ${SpecialRequestsFragmentDoc}
  ${CustomFieldsConfigurationFragmentDoc}
`

/**
 * __useGetCheckoutInfoQuery__
 *
 * To run a query within a React component, call `useGetCheckoutInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCheckoutInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCheckoutInfoQuery,
    GetCheckoutInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCheckoutInfoQuery, GetCheckoutInfoQueryVariables>(
    GetCheckoutInfoDocument,
    options,
  )
}
export function useGetCheckoutInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCheckoutInfoQuery,
    GetCheckoutInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCheckoutInfoQuery, GetCheckoutInfoQueryVariables>(
    GetCheckoutInfoDocument,
    options,
  )
}
export type GetCheckoutInfoQueryHookResult = ReturnType<typeof useGetCheckoutInfoQuery>
export type GetCheckoutInfoLazyQueryHookResult = ReturnType<typeof useGetCheckoutInfoLazyQuery>
export type GetCheckoutInfoQueryResult = ApolloReactCommon.QueryResult<
  GetCheckoutInfoQuery,
  GetCheckoutInfoQueryVariables
>
export const CheckoutItineraryDocument = gql`
  mutation checkoutItinerary($input: CheckoutItineraryInput!) {
    checkoutItinerary(input: $input) {
      __typename
      ... on OrderDetails {
        ...trip
        failedBookings {
          ...failedBookings
        }
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
  ${TripFragmentDoc}
  ${FailedBookingsFragmentDoc}
`
export type CheckoutItineraryMutationFn = ApolloReactCommon.MutationFunction<
  CheckoutItineraryMutation,
  CheckoutItineraryMutationVariables
>

/**
 * __useCheckoutItineraryMutation__
 *
 * To run a mutation, you first call `useCheckoutItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutItineraryMutation, { data, loading, error }] = useCheckoutItineraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckoutItineraryMutation,
    CheckoutItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CheckoutItineraryMutation,
    CheckoutItineraryMutationVariables
  >(CheckoutItineraryDocument, options)
}
export type CheckoutItineraryMutationHookResult = ReturnType<typeof useCheckoutItineraryMutation>
export type CheckoutItineraryMutationResult = ApolloReactCommon.MutationResult<CheckoutItineraryMutation>
export type CheckoutItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CheckoutItineraryMutation,
  CheckoutItineraryMutationVariables
>
export const AddPassportDocument = gql`
  mutation addPassport($input: AddPassportInput) {
    addPassport(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on InvalidInputError {
        message
        fields {
          type
          errorType
          message
        }
      }
      ... on NoPassportError {
        message
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type AddPassportMutationFn = ApolloReactCommon.MutationFunction<
  AddPassportMutation,
  AddPassportMutationVariables
>

/**
 * __useAddPassportMutation__
 *
 * To run a mutation, you first call `useAddPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPassportMutation, { data, loading, error }] = useAddPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPassportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPassportMutation,
    AddPassportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddPassportMutation, AddPassportMutationVariables>(
    AddPassportDocument,
    options,
  )
}
export type AddPassportMutationHookResult = ReturnType<typeof useAddPassportMutation>
export type AddPassportMutationResult = ApolloReactCommon.MutationResult<AddPassportMutation>
export type AddPassportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPassportMutation,
  AddPassportMutationVariables
>
export const RemovePassportDocument = gql`
  mutation removePassport($input: RemovePassportInput) {
    removePassport(input: $input) {
      code
      success
      message
    }
  }
`
export type RemovePassportMutationFn = ApolloReactCommon.MutationFunction<
  RemovePassportMutation,
  RemovePassportMutationVariables
>

/**
 * __useRemovePassportMutation__
 *
 * To run a mutation, you first call `useRemovePassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePassportMutation, { data, loading, error }] = useRemovePassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePassportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePassportMutation,
    RemovePassportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemovePassportMutation, RemovePassportMutationVariables>(
    RemovePassportDocument,
    options,
  )
}
export type RemovePassportMutationHookResult = ReturnType<typeof useRemovePassportMutation>
export type RemovePassportMutationResult = ApolloReactCommon.MutationResult<RemovePassportMutation>
export type RemovePassportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePassportMutation,
  RemovePassportMutationVariables
>
export const GetPostPurchaseInfoDocument = gql`
  query getPostPurchaseInfo($input: PostPurchaseInfoInput!) {
    postPurchaseInfo(input: $input) {
      primaryTraveler {
        ...travelerPostPurchaseInfo
      }
      additionalTravelers {
        ...travelerPostPurchaseInfo
      }
      details {
        agency {
          id
          message
          name
          phoneNumber
          address {
            airportCode
            geocode {
              lat
              long
            }
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
          }
        }
        emergencyContact {
          id
          fullName
          relationship
          email
          address {
            airportCode
            geocode {
              lat
              long
            }
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
          }
        }
        billing {
          id
          info {
            id
            isCardOutOfPolicy
            payment {
              creditCard {
                id
                type
                number
                label
                nameOnTheCard
                expirationDate
              }
            }
            address {
              airportCode
              geocode {
                lat
                long
              }
              street1
              street2
              city
              stateCode
              postalCode
              countryCode
            }
          }
        }
        availablePreferences {
          hotelSpecialRequests {
            value
            label
          }
          airSpecialRequests {
            value
            label
          }
          carRentalSpecialRequests {
            value
            label
          }
          meals {
            value
            label
          }
          airMemberships {
            value
            label
          }
          hotelMemberships {
            value
            label
          }
          carRentalMemberships {
            value
            label
          }
        }
        costAllocationSegment
      }
      billingParameters {
        travelVertical
        creditCards {
          id
          billingId
          label
          cardNumber
          expirationDate
          brandType
          isSiteCard
          isCardOutOfPolicy
          name
          metadata {
            isRestricted
            isEditable
            isBillingAddressRequired
            type
          }
          billingAddress {
            countryCode
            postalCode
            stateCode
            city
            street1
            street2
          }
        }
        metadata {
          isBillingAddressRequired
          isCvvRequired
          isSingleUseCreditCardAllowed
        }
      }
      customFieldsConfiguration {
        customFieldCollection {
          customFields {
            checkBoxDisplay {
              isChecked
              isCheckedValueRequired
            }
            preTripApprovalDisplay {
              ruleDescription
            }
            textDisplay {
              minLength
              maxLength
              numberOfLines
              characterWidth
            }
            enumerationDisplay {
              option {
                name
                value
              }
              isRadioButtonsStyle
            }
            numberDisplay {
              decimalPlaces
              minValue
              maxValue
            }
            dateTimeDisplay {
              displayYear
              displayMonth
              displayDay
              displayHour
              displayMinute
              displayTimeZone
              minYear
              maxYear
              minMonth
              maxMonth
              minDay
              maxDay
              isAllowedPastDateTime
            }
            type
            optional
            name
            label
            defaultValue
            isHasClientSideJavascriptValidator
            isNotAppliedServerSideJavascriptInitializer
          }
          collectionId
          collectionLabel
          collectionDescription
          isNotAppliedServerSideJavascript
        }
      }
      dynamicSiteMessages {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
    }
  }
  ${TravelerPostPurchaseInfoFragmentDoc}
`

/**
 * __useGetPostPurchaseInfoQuery__
 *
 * To run a query within a React component, call `useGetPostPurchaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostPurchaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostPurchaseInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPostPurchaseInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPostPurchaseInfoQuery,
    GetPostPurchaseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetPostPurchaseInfoQuery, GetPostPurchaseInfoQueryVariables>(
    GetPostPurchaseInfoDocument,
    options,
  )
}
export function useGetPostPurchaseInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPostPurchaseInfoQuery,
    GetPostPurchaseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetPostPurchaseInfoQuery, GetPostPurchaseInfoQueryVariables>(
    GetPostPurchaseInfoDocument,
    options,
  )
}
export type GetPostPurchaseInfoQueryHookResult = ReturnType<typeof useGetPostPurchaseInfoQuery>
export type GetPostPurchaseInfoLazyQueryHookResult = ReturnType<
  typeof useGetPostPurchaseInfoLazyQuery
>
export type GetPostPurchaseInfoQueryResult = ApolloReactCommon.QueryResult<
  GetPostPurchaseInfoQuery,
  GetPostPurchaseInfoQueryVariables
>
export const GetPurchaseInfoDocument = gql`
  query getPurchaseInfo($input: PurchaseInfoInput!) {
    purchaseInfo(input: $input) {
      primaryTraveler {
        ...travelerPurchaseInfo
      }
      additionalTravelers {
        ...travelerPurchaseInfo
      }
      details {
        agency {
          id
          message
          name
          phoneNumber
          address {
            airportCode
            geocode {
              lat
              long
            }
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
          }
        }
        emergencyContact {
          id
          fullName
          relationship
          email
          address {
            airportCode
            geocode {
              lat
              long
            }
            street1
            street2
            city
            stateCode
            postalCode
            countryCode
          }
        }
        billing {
          id
          info {
            id
            isCardOutOfPolicy
            payment {
              creditCard {
                id
                type
                number
                label
                nameOnTheCard
                expirationDate
              }
            }
            address {
              airportCode
              geocode {
                lat
                long
              }
              street1
              street2
              city
              stateCode
              postalCode
              countryCode
            }
          }
        }
        availablePreferences {
          hotelSpecialRequests {
            value
            label
          }
          airSpecialRequests {
            value
            label
          }
          carRentalSpecialRequests {
            value
            label
          }
          meals {
            value
            label
          }
          airMemberships {
            value
            label
          }
          hotelMemberships {
            value
            label
          }
          carRentalMemberships {
            value
            label
          }
        }
        costAllocationSegment
        preBookRequestRequirement
        displaySettings {
          shouldShowShareTravelerContact
          shouldShowNdcFareAcknowledgment
        }
      }
      billingParameters {
        travelVertical
        creditCards {
          id
          billingId
          label
          cardNumber
          expirationDate
          brandType
          isSiteCard
          isCardOutOfPolicy
          name
          metadata {
            isRestricted
            isEditable
            isBillingAddressRequired
            type
          }
          billingAddress {
            countryCode
            postalCode
            stateCode
            city
            street1
            street2
          }
        }
        metadata {
          isBillingAddressRequired
          isCvvRequired
          isSingleUseCreditCardAllowed
        }
        defaultCardId
      }
      customFieldsConfiguration {
        customFieldCollection {
          customFields {
            checkBoxDisplay {
              isChecked
              isCheckedValueRequired
            }
            preTripApprovalDisplay {
              ruleDescription
            }
            textDisplay {
              minLength
              maxLength
              numberOfLines
              characterWidth
            }
            enumerationDisplay {
              option {
                name
                value
              }
              isRadioButtonsStyle
            }
            numberDisplay {
              decimalPlaces
              minValue
              maxValue
            }
            dateTimeDisplay {
              displayYear
              displayMonth
              displayDay
              displayHour
              displayMinute
              displayTimeZone
              minYear
              maxYear
              minMonth
              maxMonth
              minDay
              maxDay
              isAllowedPastDateTime
            }
            type
            optional
            name
            tooltip
            label
            defaultValue
            isHasClientSideJavascriptValidator
            isNotAppliedServerSideJavascriptInitializer
          }
          collectionId
          collectionLabel
          collectionDescription
          isNotAppliedServerSideJavascript
        }
      }
      dynamicSiteMessages {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
    }
  }
  ${TravelerPurchaseInfoFragmentDoc}
`

/**
 * __useGetPurchaseInfoQuery__
 *
 * To run a query within a React component, call `useGetPurchaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPurchaseInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPurchaseInfoQuery,
    GetPurchaseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetPurchaseInfoQuery, GetPurchaseInfoQueryVariables>(
    GetPurchaseInfoDocument,
    options,
  )
}
export function useGetPurchaseInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPurchaseInfoQuery,
    GetPurchaseInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetPurchaseInfoQuery, GetPurchaseInfoQueryVariables>(
    GetPurchaseInfoDocument,
    options,
  )
}
export type GetPurchaseInfoQueryHookResult = ReturnType<typeof useGetPurchaseInfoQuery>
export type GetPurchaseInfoLazyQueryHookResult = ReturnType<typeof useGetPurchaseInfoLazyQuery>
export type GetPurchaseInfoQueryResult = ApolloReactCommon.QueryResult<
  GetPurchaseInfoQuery,
  GetPurchaseInfoQueryVariables
>
export const GetBrandConfigurationDocument = gql`
  query getBrandConfiguration {
    brandConfiguration {
      isThemeEnabled
      theme
      partnerLogoUrl
      brandTitleLabel
      customMobilePartnerLogoUrl
      customMobileCompanyLogoUrl
      termsOfUseLinkEnabled
    }
  }
`

/**
 * __useGetBrandConfigurationQuery__
 *
 * To run a query within a React component, call `useGetBrandConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBrandConfigurationQuery,
    GetBrandConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetBrandConfigurationQuery, GetBrandConfigurationQueryVariables>(
    GetBrandConfigurationDocument,
    options,
  )
}
export function useGetBrandConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBrandConfigurationQuery,
    GetBrandConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetBrandConfigurationQuery,
    GetBrandConfigurationQueryVariables
  >(GetBrandConfigurationDocument, options)
}
export type GetBrandConfigurationQueryHookResult = ReturnType<typeof useGetBrandConfigurationQuery>
export type GetBrandConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetBrandConfigurationLazyQuery
>
export type GetBrandConfigurationQueryResult = ApolloReactCommon.QueryResult<
  GetBrandConfigurationQuery,
  GetBrandConfigurationQueryVariables
>
export const GetCarDetailsDocument = gql`
  query getCarDetails(
    $id: String!
    $input: CarDetailsInput
    $searchId: String
    $logsMetadata: LogsMetadata
  ) {
    carDetails(id: $id, input: $input, searchId: $searchId, logsMetadata: $logsMetadata) {
      carCosts {
        extraHour {
          currency
          amount
        }
        extraDay {
          currency
          amount
        }
        perDay {
          currency
          amount
        }
        isMileageUnlimited
      }
      carCostRates {
        extraDayRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        extraHourRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        perDayRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        perWeekRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        isMileageUnlimited
      }
      taxes {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      totalCost {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      baseRate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      netRate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      serviceFeeRate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      rate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
    }
  }
`

/**
 * __useGetCarDetailsQuery__
 *
 * To run a query within a React component, call `useGetCarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      searchId: // value for 'searchId'
 *      logsMetadata: // value for 'logsMetadata'
 *   },
 * });
 */
export function useGetCarDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCarDetailsQuery, GetCarDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCarDetailsQuery, GetCarDetailsQueryVariables>(
    GetCarDetailsDocument,
    options,
  )
}
export function useGetCarDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCarDetailsQuery,
    GetCarDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCarDetailsQuery, GetCarDetailsQueryVariables>(
    GetCarDetailsDocument,
    options,
  )
}
export type GetCarDetailsQueryHookResult = ReturnType<typeof useGetCarDetailsQuery>
export type GetCarDetailsLazyQueryHookResult = ReturnType<typeof useGetCarDetailsLazyQuery>
export type GetCarDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetCarDetailsQuery,
  GetCarDetailsQueryVariables
>
export const GetCarRentalMultiVendorLocationsDocument = gql`
  query getCarRentalMultiVendorLocations($input: CarRentalLocationsInput!) {
    carRentalMultiVendorLocations(input: $input) {
      filter {
        type
        filters {
          name
          selected
          enabled
          count
        }
      }
      message {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
      preferenceAttribute {
        id
        preference {
          id
          code
          highlight
          ownerType
          ownerName
          tooltipText
          shouldShowDefaultPreference
          customBadge {
            name
            color
          }
        }
        vendor {
          code
          name
          imageUrl
        }
      }
      location {
        hours {
          hours {
            start
            end
          }
          allDay
        }
        address {
          airportCode
          geocode {
            lat
            long
          }
          street1
          street2
          stateCode
          postalCode
          countryCode
          city
        }
        phone {
          type
          number
        }
        preferenceAttributeId
        distanceAsMiles
        distanceAsKilometers
        vendors {
          name
          code
          imageUrl
        }
        airportCode
        airportName
        privateLocation
        airportLocation
        companyLocationName
        locationId
        isOpen
      }
    }
  }
`

/**
 * __useGetCarRentalMultiVendorLocationsQuery__
 *
 * To run a query within a React component, call `useGetCarRentalMultiVendorLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarRentalMultiVendorLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarRentalMultiVendorLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarRentalMultiVendorLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCarRentalMultiVendorLocationsQuery,
    GetCarRentalMultiVendorLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetCarRentalMultiVendorLocationsQuery,
    GetCarRentalMultiVendorLocationsQueryVariables
  >(GetCarRentalMultiVendorLocationsDocument, options)
}
export function useGetCarRentalMultiVendorLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCarRentalMultiVendorLocationsQuery,
    GetCarRentalMultiVendorLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetCarRentalMultiVendorLocationsQuery,
    GetCarRentalMultiVendorLocationsQueryVariables
  >(GetCarRentalMultiVendorLocationsDocument, options)
}
export type GetCarRentalMultiVendorLocationsQueryHookResult = ReturnType<
  typeof useGetCarRentalMultiVendorLocationsQuery
>
export type GetCarRentalMultiVendorLocationsLazyQueryHookResult = ReturnType<
  typeof useGetCarRentalMultiVendorLocationsLazyQuery
>
export type GetCarRentalMultiVendorLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetCarRentalMultiVendorLocationsQuery,
  GetCarRentalMultiVendorLocationsQueryVariables
>
export const GetCarRentalsDocument = gql`
  query getCarRentals($input: CarRentalsInput!) {
    carRentals(input: $input) {
      maxResults
      page {
        start
        end
        size
      }
      car {
        key
        isSelectable
        isPreferred
        unlimitedMileage
        details {
          type
          class
          transmission
          fuel
          passenger
          luggage
          imageUrl
          carImageUrl
          carDetailsImageUrl
          isAirConditioned
          contractName
        }
        vendor {
          name
          code
          imageUrl
        }
        policy {
          isInPolicy
          outOfPolicyReasons
          outOfPolicyData {
            type
            data
          }
        }
        carCosts {
          extraHour {
            currency
            amount
          }
          extraDay {
            currency
            amount
          }
          perDay {
            currency
            amount
          }
          isMileageUnlimited
        }
        carCostRates {
          extraDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          extraHourRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perWeekRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          isMileageUnlimited
        }
        serviceFee {
          description
          id
          rate {
            amount
            currency
          }
        }
        taxes {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        totalCost {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        netRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        serviceFeeRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        pickUp {
          locationId
          date
          time
          isAirportLocation
          workingHours {
            start
            end
            isAllDay
          }
          phone {
            number
            type
          }
          address {
            city
            countryCode
            geocode {
              lat
              long
            }
            stateCode
            postalCode
            airportCode
            street1
            street2
          }
        }
        dropOff {
          locationId
          date
          time
          isAirportLocation
          workingHours {
            start
            end
            isAllDay
          }
          phone {
            number
            type
          }
          address {
            city
            countryCode
            geocode {
              lat
              long
            }
            stateCode
            postalCode
            airportCode
            street1
            street2
          }
        }
        safetyCheck {
          messageCode
          url
          badgeUrl
        }
        baseRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
      }
      filters {
        class {
          code
          name
          selected
          count
          enabled
        }
        type {
          lowestCost {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          code
          selected
          count
          enabled
        }
        vendor {
          code
          name
          imageUrl
          selected
          count
          enabled
        }
        transmission {
          code
          name
          selected
          count
          enabled
        }
        fuel {
          code
          name
          selected
          count
          enabled
        }
      }
      message {
        isDialog
        text
        mobileText
        header
        hasDesktopBannerImage
      }
      includesPreferred
      includesOutOfPolicy
      primaryVendorsMissing
      secondaryVendorsMissing
      preferenceAttribute {
        preference {
          code
          highlight
          ownerType
          ownerName
          customBadge {
            name
            color
          }
          tooltipText
        }
        id
        vendor {
          name
          code
          imageUrl
        }
      }
      location {
        hours {
          hours {
            start
            end
          }
          allDay
        }
        address {
          airportCode
          geocode {
            lat
            long
          }
          street1
          street2
          stateCode
          postalCode
          countryCode
          city
        }
        phone {
          type
          number
        }
        preferenceAttributeId
        distanceAsMiles
        distanceAsKilometers
        vendor {
          name
          code
          imageUrl
        }
        airportCode
        privateLocation
        airportLocation
        companyLocationName
        locationId
        isOpen
      }
    }
  }
`

/**
 * __useGetCarRentalsQuery__
 *
 * To run a query within a React component, call `useGetCarRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarRentalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarRentalsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCarRentalsQuery, GetCarRentalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCarRentalsQuery, GetCarRentalsQueryVariables>(
    GetCarRentalsDocument,
    options,
  )
}
export function useGetCarRentalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCarRentalsQuery,
    GetCarRentalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCarRentalsQuery, GetCarRentalsQueryVariables>(
    GetCarRentalsDocument,
    options,
  )
}
export type GetCarRentalsQueryHookResult = ReturnType<typeof useGetCarRentalsQuery>
export type GetCarRentalsLazyQueryHookResult = ReturnType<typeof useGetCarRentalsLazyQuery>
export type GetCarRentalsQueryResult = ApolloReactCommon.QueryResult<
  GetCarRentalsQuery,
  GetCarRentalsQueryVariables
>
export const GetCarRulesDocument = gql`
  query getCarRules($itineraryId: String!, $carSegmentId: String!) {
    carRules(itineraryId: $itineraryId, carSegmentId: $carSegmentId) {
      header
      value
    }
  }
`

/**
 * __useGetCarRulesQuery__
 *
 * To run a query within a React component, call `useGetCarRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarRulesQuery({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      carSegmentId: // value for 'carSegmentId'
 *   },
 * });
 */
export function useGetCarRulesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCarRulesQuery, GetCarRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCarRulesQuery, GetCarRulesQueryVariables>(
    GetCarRulesDocument,
    options,
  )
}
export function useGetCarRulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCarRulesQuery, GetCarRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCarRulesQuery, GetCarRulesQueryVariables>(
    GetCarRulesDocument,
    options,
  )
}
export type GetCarRulesQueryHookResult = ReturnType<typeof useGetCarRulesQuery>
export type GetCarRulesLazyQueryHookResult = ReturnType<typeof useGetCarRulesLazyQuery>
export type GetCarRulesQueryResult = ApolloReactCommon.QueryResult<
  GetCarRulesQuery,
  GetCarRulesQueryVariables
>
export const CreateCarRentalSearchDocument = gql`
  mutation createCarRentalSearch($input: CarRentalsInput!) {
    createCarRentalSearch(input: $input) {
      maxResults
      page {
        start
        end
        size
      }
      car {
        key
        isSelectable
        isPreferred
        unlimitedMileage
        details {
          type
          class
          transmission
          fuel
          passenger
          luggage
          imageUrl
          carImageUrl
          carDetailsImageUrl
          isAirConditioned
          contractName
          name
        }
        vendor {
          name
          code
          imageUrl
        }
        policy {
          isInPolicy
          outOfPolicyReasons
          outOfPolicyData {
            data
            type
          }
        }
        carCosts {
          extraHour {
            currency
            amount
          }
          extraDay {
            currency
            amount
          }
          perDay {
            currency
            amount
          }
          isMileageUnlimited
        }
        carCostRates {
          extraDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          extraHourRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perDayRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          perWeekRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          isMileageUnlimited
        }
        serviceFee {
          description
          id
          rate {
            amount
            currency
          }
        }
        taxes {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        totalCost {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        pickUp {
          locationId
          date
          time
          isAirportLocation
          workingHours {
            start
            end
            isAllDay
          }
          phone {
            number
            type
          }
          address {
            city
            countryCode
            geocode {
              lat
              long
            }
            stateCode
            postalCode
            airportCode
            street1
            street2
          }
        }
        dropOff {
          locationId
          date
          time
          isAirportLocation
          workingHours {
            start
            end
            isAllDay
          }
          phone {
            number
            type
          }
          address {
            city
            countryCode
            geocode {
              lat
              long
            }
            stateCode
            postalCode
            airportCode
            street1
            street2
          }
        }
        safetyCheck {
          messageCode
          url
          badgeUrl
        }
        baseRate {
          primary {
            currency
            amount
          }
          secondary {
            amount
            currency
          }
        }
        daysCharged
      }
      filters {
        class {
          code
          name
          selected
          count
          enabled
        }
        type {
          code
          selected
          count
          enabled
          lowestCost {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
        }
        vendor {
          code
          name
          imageUrl
          selected
          count
          enabled
        }
        transmission {
          code
          name
          selected
          count
          enabled
        }
        fuel {
          code
          name
          selected
          count
          enabled
        }
      }
      message {
        isDialog
        text
        mobileText
        header
        hasDesktopBannerImage
      }
      includesPreferred
      includesOutOfPolicy
      primaryVendorsMissing
      secondaryVendorsMissing
      preferenceAttribute {
        preference {
          code
          highlight
          ownerType
          ownerName
          customBadge {
            name
            color
          }
          tooltipText
        }
        id
        vendor {
          name
          code
          imageUrl
        }
      }
      location {
        hours {
          hours {
            start
            end
          }
          allDay
        }
        address {
          airportCode
          geocode {
            lat
            long
          }
          street1
          street2
          stateCode
          postalCode
          countryCode
          city
        }
        phone {
          type
          number
        }
        preferenceAttributeId
        distanceAsMiles
        distanceAsKilometers
        vendor {
          name
          code
          imageUrl
        }
        airportCode
        privateLocation
        airportLocation
        companyLocationName
        locationId
        isOpen
      }
      searchId
    }
  }
`
export type CreateCarRentalSearchMutationFn = ApolloReactCommon.MutationFunction<
  CreateCarRentalSearchMutation,
  CreateCarRentalSearchMutationVariables
>

/**
 * __useCreateCarRentalSearchMutation__
 *
 * To run a mutation, you first call `useCreateCarRentalSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarRentalSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarRentalSearchMutation, { data, loading, error }] = useCreateCarRentalSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarRentalSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCarRentalSearchMutation,
    CreateCarRentalSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CreateCarRentalSearchMutation,
    CreateCarRentalSearchMutationVariables
  >(CreateCarRentalSearchDocument, options)
}
export type CreateCarRentalSearchMutationHookResult = ReturnType<
  typeof useCreateCarRentalSearchMutation
>
export type CreateCarRentalSearchMutationResult = ApolloReactCommon.MutationResult<CreateCarRentalSearchMutation>
export type CreateCarRentalSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCarRentalSearchMutation,
  CreateCarRentalSearchMutationVariables
>
export const GetCostSegmentsDocument = gql`
  query getCostSegments {
    costSegments {
      segments {
        description
        id
        isActive
        isRequired
        isSplitCostAllocation
        isSplitCostRequired
        name
        splitCostAllocationTooltip
        tooltip
      }
    }
  }
`

/**
 * __useGetCostSegmentsQuery__
 *
 * To run a query within a React component, call `useGetCostSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostSegmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCostSegmentsQuery,
    GetCostSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCostSegmentsQuery, GetCostSegmentsQueryVariables>(
    GetCostSegmentsDocument,
    options,
  )
}
export function useGetCostSegmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCostSegmentsQuery,
    GetCostSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCostSegmentsQuery, GetCostSegmentsQueryVariables>(
    GetCostSegmentsDocument,
    options,
  )
}
export type GetCostSegmentsQueryHookResult = ReturnType<typeof useGetCostSegmentsQuery>
export type GetCostSegmentsLazyQueryHookResult = ReturnType<typeof useGetCostSegmentsLazyQuery>
export type GetCostSegmentsQueryResult = ApolloReactCommon.QueryResult<
  GetCostSegmentsQuery,
  GetCostSegmentsQueryVariables
>
export const GetMultiLayerCostAllocationDocument = gql`
  query getMultiLayerCostAllocation($input: MultiLayerCostAllocationInput!) {
    multiLayerCostAllocation(input: $input) {
      allocation {
        name
        segmentId
        allocationId
        childrenRef
        parentRef
        code
        stats {
          activeChildren
        }
      }
      totalResults
    }
  }
`

/**
 * __useGetMultiLayerCostAllocationQuery__
 *
 * To run a query within a React component, call `useGetMultiLayerCostAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiLayerCostAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiLayerCostAllocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMultiLayerCostAllocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetMultiLayerCostAllocationQuery,
    GetMultiLayerCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetMultiLayerCostAllocationQuery,
    GetMultiLayerCostAllocationQueryVariables
  >(GetMultiLayerCostAllocationDocument, options)
}
export function useGetMultiLayerCostAllocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMultiLayerCostAllocationQuery,
    GetMultiLayerCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetMultiLayerCostAllocationQuery,
    GetMultiLayerCostAllocationQueryVariables
  >(GetMultiLayerCostAllocationDocument, options)
}
export type GetMultiLayerCostAllocationQueryHookResult = ReturnType<
  typeof useGetMultiLayerCostAllocationQuery
>
export type GetMultiLayerCostAllocationLazyQueryHookResult = ReturnType<
  typeof useGetMultiLayerCostAllocationLazyQuery
>
export type GetMultiLayerCostAllocationQueryResult = ApolloReactCommon.QueryResult<
  GetMultiLayerCostAllocationQuery,
  GetMultiLayerCostAllocationQueryVariables
>
export const GetDisplayConfigurationDocument = gql`
  query getDisplayConfiguration($input: DisplayConfigurationInput) {
    displayConfiguration(input: $input) {
      isAdminRoleEnabled
      isMod2FlowEnabled
      isFlightSearchServiceEnabled
      areRecommendationsEnabled
      isPhoenixHotelSearchEnabled
      isPhoenixCarRentalSearchEnabled
      maxNumberOfGuestsPerRoom
      isTripAdvisorReviewsEnabled
      mapProvider
      isPerDiemDisplayEnabled
      isStarRatingsFilterEnabled
      isHoldTripAllowed
      isSoldOutFilterAvailable
      isPreBookCostAllocationEnabled
      isShowTravelerAcknowledgement
      isExcludeBasicFareBlock
      isSouthwestDirectConnectionEnabled
      isExpiringCreditCardForHotelAllowed
      isCreditCardSavingEnabled
      isAppleLockSettingsEnabled
      appleDsmMessage
      enableNearByAirport
      bookAndChangeAdvanceTime
      selfDelegation
      userDelegation
      explore {
        messages {
          header
          text
          isDialog
          hasDesktopBannerImage
        }
        message {
          label
          message
        }
        isFlightsSearchEnabled
        isHotelsSearchEnabled
        isTrainsSearchEnabled
        isCarRentalsSearchEnabled
        searchDefaultsAirEnabled
        searchDefaultsHotelEnabled
        searchDefaultsCarEnabled
      }
      flights {
        defaultSort
        areNearbyAirportsIncluded
        isMorningAfternoonEveningEnabled
        isAnytimeEnabled
        isDefaultAnyTime
        isFirstClassEnabled
        isBusinessClassEnabled
        isPremiumEconomyClassEnabled
        isEconomyClassEnabled
        firstClassCustomLabel
        businessClassCustomLabel
        premiumEconomyClassCustomLabel
        economyClassCustomLabel
        fares {
          defaultFareType
          areRefundableEnabled
          areUnrestrictedEnabled
        }
        defaultAirTravelType
        isOutOfPolicyFlightsSelectable
      }
      carRentals {
        areDeliveryAndCollectionSupported
        requireCarPaymentForm
      }
      hotels {
        isStarRatingEnabled
      }
      geosureReport {
        enabled
        variant
      }
      optinoutOfUnusedTicket
      flightSearchTimeRange {
        before
        after
      }
      flightSearchTimeRangeNonUS {
        before
        after
      }
      hotelSearchRadius {
        default
        max
      }
      privacyPolicy {
        url
        label
      }
      support {
        mobile {
          email {
            emailAddress
            label
          }
          phoneNumbers {
            phoneNumber
            label
          }
        }
        desktop {
          email
        }
        technicalSupport {
          isEnabled
          text
        }
      }
      hideUnusedTickets
      defaultCarSize
      isMod2DoubleWriteEnabled
      externalLinks {
        isExternalLinksEnabled
        links {
          url
          label
        }
      }
      companyResourceConfiguration {
        id
        descriptionMessage
        resourceTexts {
          label
          url
        }
      }
      serviceFeeConfiguration {
        id
        description
        label
        name
        serviceFeesAmount
        serviceFeesAmountCurrency
        serviceId
        transactionFeesChangeText
        type
        vendorCodes
        vendorCodesList
        vendorNamesList
      }
    }
  }
`

/**
 * __useGetDisplayConfigurationQuery__
 *
 * To run a query within a React component, call `useGetDisplayConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDisplayConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDisplayConfigurationQuery,
    GetDisplayConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetDisplayConfigurationQuery,
    GetDisplayConfigurationQueryVariables
  >(GetDisplayConfigurationDocument, options)
}
export function useGetDisplayConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDisplayConfigurationQuery,
    GetDisplayConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetDisplayConfigurationQuery,
    GetDisplayConfigurationQueryVariables
  >(GetDisplayConfigurationDocument, options)
}
export type GetDisplayConfigurationQueryHookResult = ReturnType<
  typeof useGetDisplayConfigurationQuery
>
export type GetDisplayConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetDisplayConfigurationLazyQuery
>
export type GetDisplayConfigurationQueryResult = ApolloReactCommon.QueryResult<
  GetDisplayConfigurationQuery,
  GetDisplayConfigurationQueryVariables
>
export const ZooKeeperFlagsDocument = gql`
  query zooKeeperFlags {
    zooKeeperFlags {
      policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled
      southwestCheckInEmailNotificationEnabled
      southwestCheckInSmsNotificationEnabled
      removeNotificationSectionOnTheCheckoutPageEnabled
      nonStopFilterByDefaultEnabled
      blockBasicEconomyEnabled
      remoteProfileMessage
      remoteProfileEnabled
      removeHotelStarRatingEnabled
      disableTsaHazmatMessageEnabled
      tieredCostAllocationEnabled
      removePerDiemLinkEnabled
    }
  }
`

/**
 * __useZooKeeperFlagsQuery__
 *
 * To run a query within a React component, call `useZooKeeperFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useZooKeeperFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZooKeeperFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useZooKeeperFlagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ZooKeeperFlagsQuery,
    ZooKeeperFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<ZooKeeperFlagsQuery, ZooKeeperFlagsQueryVariables>(
    ZooKeeperFlagsDocument,
    options,
  )
}
export function useZooKeeperFlagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ZooKeeperFlagsQuery,
    ZooKeeperFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<ZooKeeperFlagsQuery, ZooKeeperFlagsQueryVariables>(
    ZooKeeperFlagsDocument,
    options,
  )
}
export type ZooKeeperFlagsQueryHookResult = ReturnType<typeof useZooKeeperFlagsQuery>
export type ZooKeeperFlagsLazyQueryHookResult = ReturnType<typeof useZooKeeperFlagsLazyQuery>
export type ZooKeeperFlagsQueryResult = ApolloReactCommon.QueryResult<
  ZooKeeperFlagsQuery,
  ZooKeeperFlagsQueryVariables
>
export const CreateMapKitTokenDocument = gql`
  mutation createMapKitToken {
    createMapKitToken {
      token
    }
  }
`
export type CreateMapKitTokenMutationFn = ApolloReactCommon.MutationFunction<
  CreateMapKitTokenMutation,
  CreateMapKitTokenMutationVariables
>

/**
 * __useCreateMapKitTokenMutation__
 *
 * To run a mutation, you first call `useCreateMapKitTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMapKitTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMapKitTokenMutation, { data, loading, error }] = useCreateMapKitTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMapKitTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMapKitTokenMutation,
    CreateMapKitTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CreateMapKitTokenMutation,
    CreateMapKitTokenMutationVariables
  >(CreateMapKitTokenDocument, options)
}
export type CreateMapKitTokenMutationHookResult = ReturnType<typeof useCreateMapKitTokenMutation>
export type CreateMapKitTokenMutationResult = ApolloReactCommon.MutationResult<CreateMapKitTokenMutation>
export type CreateMapKitTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMapKitTokenMutation,
  CreateMapKitTokenMutationVariables
>
export const GetFeatureFlagsDocument = gql`
  query getFeatureFlags($input: FeatureFlagsInput!) {
    featureFlags(input: $input) {
      isToggleEttaThemeEnabled
      isWhatFixPWAEnabled
      isSSOEnabled
      isRailServiceEnabled
      isMultiCityEnabled
      isFlightSafetyCheckEnabled
      isHotelSafetyCheckEnabled
      isHotelRateCapEnabled
      isDelegatedFlowEnabled
      isUnusedTicketsEnabled
      isUnusedTicketsOptOutEnabled
      isDesktopUnusedTicketsOptOutEnabled
      isDesktopUnusedTicketsEnabled
      isDesktopLayoutFlightFlowEnabled
      isDesktopLayoutHotelFlowEnabled
      isDesktopLayoutRTPFlowEnabled
      isEttaAutoTranslationEnabled
      isDesktopLayoutPurchaseFlowEnabled
      isSustainabilityEnabled
      isDesktopTripCartEnabled
      isSplitCostAllocationEnabled
      isDesktopLayoutTripsFlowEnabled
      isFijiPurchaseFixEnabled
      isAddTrainToItineraryEnabled
      isPasswordExpirationFlowEnabled
      isDesktopDelegatedFlowEnabled
      isUberEnabled
      isReserveAirportPickupEnabled
      isMobilityExpenseEnabled
      isMobilityZoningEnabled
      isMobilityDesktopEnabled
      isMobilityMultiModalEnabled
      isMobilitySearchFormV3Enabled
      isMobilityBookTabEnabled
      isDesktopLayoutTrainTabEnabled
      isDesktopLayoutMultiCityEnabled
      isRailAccreditationEnabled
      isRailSearchFiltersEnabled
      isRailSearchSortEnabled
      isRailCardSelectionEnabled
      isRailOutofPolicyEnabled
      isMobileHeldTripsFlowEnabled
      isKeyboardNavigationStylesEnabled
      isDesktopHeldTripsFlowEnabled
      isFeedbackEnabled
      isTripExpiredDateGeneratedByUTCEnable
      isRecentSearchesEnabled
      isAppleMapProviderEnabled
      isCustomLogoutRedirectEnabled
      isOnHoldEnabled
      isSettingsPageInformationSectionEnabled
      isSettingsPageSettingsSectionEnabled
      isSettingsPageOrganizationSectionEnabled
      isRedirectOnSiteDomainEnabled
      isMobilityOnDemandRidesEnabled
      isNewAirFiltersModalEnabled
      isNewAirExposedFiltersEnabled
      isNewHotelExposedFiltersEnabled
      isNewHotelFiltersModalEnabled
      isAppleBannerEnabled
      isBookItineraryTravelPreferencesEnabled
      isSsoSettingsEnabled
      isAlertsEnabled
      isCarRentalsNewFiltersEnabled
      isRecentSearchesFilteringEnabled
      isExternalLinksEnabled
      isActiveItineraryPurchaseFlowEnabled
      isHotelRetryWorkflowEnabled
      isNewCheckoutPageLayoutEnabled
      isTripAlignmentWorkflowEnabled
      isChangingReturnFlightEnabled
      isBookAgainEnabled
      isNewEttaOnlyEnabled
      isActivateAccountFlowEnabled
      isNewHelpCenterLinkEnabled
      isBookAgainDelegateFlowEnabled
      isNewTripDetailsPageEnabled
      isNewLoadingConfirmationEnabled
      isOOPModalOnSearchResultFlightPageEnabled
      isServiceFeeEnabled
      isUserActivationEnabled
      isQualtricsEnabled
      isOOPModalEnabled
      isPWACookieConsentEnabled
      isNewEmissionsBrandingEnabled
      isRetailShoppingUIEnabled
    }
  }
`

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFeatureFlagsQuery,
    GetFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(
    GetFeatureFlagsDocument,
    options,
  )
}
export function useGetFeatureFlagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFeatureFlagsQuery,
    GetFeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(
    GetFeatureFlagsDocument,
    options,
  )
}
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>
export type GetFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<
  GetFeatureFlagsQuery,
  GetFeatureFlagsQueryVariables
>
export const CreateSearchDocument = gql`
  mutation createSearch(
    $itineraryId: String!
    $searchBy: Int!
    $latitude: Float!
    $longitude: Float!
    $distance: Float
    $distanceUnit: String
    $checkIn: String!
    $checkOut: String!
    $guests: Int!
    $hotelName: String
    $airportCode: String
  ) {
    createSearch(
      itineraryId: $itineraryId
      searchBy: $searchBy
      latitude: $latitude
      longitude: $longitude
      distance: $distance
      distanceUnit: $distanceUnit
      checkIn: $checkIn
      checkOut: $checkOut
      guests: $guests
      hotelName: $hotelName
      airportCode: $airportCode
    ) {
      hotelResultId
    }
  }
`
export type CreateSearchMutationFn = ApolloReactCommon.MutationFunction<
  CreateSearchMutation,
  CreateSearchMutationVariables
>

/**
 * __useCreateSearchMutation__
 *
 * To run a mutation, you first call `useCreateSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSearchMutation, { data, loading, error }] = useCreateSearchMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      searchBy: // value for 'searchBy'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      distance: // value for 'distance'
 *      distanceUnit: // value for 'distanceUnit'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      guests: // value for 'guests'
 *      hotelName: // value for 'hotelName'
 *      airportCode: // value for 'airportCode'
 *   },
 * });
 */
export function useCreateSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSearchMutation,
    CreateSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateSearchMutation, CreateSearchMutationVariables>(
    CreateSearchDocument,
    options,
  )
}
export type CreateSearchMutationHookResult = ReturnType<typeof useCreateSearchMutation>
export type CreateSearchMutationResult = ApolloReactCommon.MutationResult<CreateSearchMutation>
export type CreateSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSearchMutation,
  CreateSearchMutationVariables
>
export const GetHotelDocument = gql`
  query getHotel(
    $logsMetadata: LogsMetadata
    $hotelResultId: String
    $groupId: String
    $id: ID!
    $checkIn: String!
    $checkOut: String!
    $latitude: Float!
    $longitude: Float!
    $guests: Int!
  ) {
    hotel(
      logsMetadata: $logsMetadata
      hotelResultId: $hotelResultId
      groupId: $groupId
      id: $id
      checkIn: $checkIn
      checkOut: $checkOut
      latitude: $latitude
      longitude: $longitude
      guests: $guests
    ) {
      id
      isEcoFriendly
      name
      descriptions {
        title
        body
      }
      address {
        airportCode
        geocode {
          lat
          long
        }
        street1
        street2
        city
        stateCode
        postalCode
        countryCode
      }
      distance {
        value
        units
      }
      starRating
      phoneNumber
      chainIconUrl
      chainCode
      logoPhotoUrl
      isPreferred
      isCancellable
      policy {
        isInPolicy
        outOfPolicyReasons
      }
      isSoldOut
      rate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      perDiemRate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      privateRate {
        averageRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        realTime
        corporate
      }
      rooms {
        id
        type
        description
        inventoryId
        totalCost {
          amount
          currency
        }
        totalCostRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        feesAndTaxes {
          amount
          currency
        }
        feesAndTaxes {
          amount
          currency
        }
        feesAndTaxesRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        averageRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        isCorporateRate
        dailyRates {
          rate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          date
        }
        isFreeCancellable
        policy {
          isInPolicy
          outOfPolicyReasons
        }
        source
        isSelectable
        cancellationPolicy
        cancellationPolicyDescription
        preferredTag
        rateQualifier
        numberOfGuests
      }
      amenities {
        name
        isOfProperty
        isOfRoom
      }
      reviewsProvider
      totalReviewCount
      medianReviewRating
      medianReviewRatingImageUrl
      hotelReviewsDetailsUrl
      reviews {
        title
        summary
        content
        date
        userRating
        ratingImageUrl
      }
      photos {
        index
        size
        url
      }
      preference {
        code
        highlight
        ownerType
        ownerName
        tooltipText
        customBadge {
          name
          color
        }
      }
      resultKey
      notes
      exclusiveAmenities {
        name
        isOfProperty
        isOfRoom
      }
    }
  }
`

/**
 * __useGetHotelQuery__
 *
 * To run a query within a React component, call `useGetHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelQuery({
 *   variables: {
 *      logsMetadata: // value for 'logsMetadata'
 *      hotelResultId: // value for 'hotelResultId'
 *      groupId: // value for 'groupId'
 *      id: // value for 'id'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      guests: // value for 'guests'
 *   },
 * });
 */
export function useGetHotelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetHotelQuery, GetHotelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHotelQuery, GetHotelQueryVariables>(GetHotelDocument, options)
}
export function useGetHotelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelQuery, GetHotelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHotelQuery, GetHotelQueryVariables>(
    GetHotelDocument,
    options,
  )
}
export type GetHotelQueryHookResult = ReturnType<typeof useGetHotelQuery>
export type GetHotelLazyQueryHookResult = ReturnType<typeof useGetHotelLazyQuery>
export type GetHotelQueryResult = ApolloReactCommon.QueryResult<
  GetHotelQuery,
  GetHotelQueryVariables
>
export const GetHotelsDocument = gql`
  query getHotels(
    $logsMetadata: LogsMetadata
    $hotelResultId: String
    $first: Int
    $offset: Int
    $orderBy: String
    $hotelName: String
    $distance: Float
    $starRatings: [Int]
    $brandIds: [String]
    $amenityIds: [String]
    $checkIn: String!
    $checkOut: String!
    $latitude: Float!
    $longitude: Float!
    $guests: Int!
    $groupId: String
    $searchBy: String
    $airportCode: String
    $stationCode: String
    $locationName: String
    $nameSearch: Boolean
    $hideSoldOut: Boolean
    $deviceClass: DeviceClass
    $failedBooking: FailedBooking
  ) {
    hotels(
      logsMetadata: $logsMetadata
      hotelResultId: $hotelResultId
      first: $first
      offset: $offset
      orderBy: $orderBy
      hotelName: $hotelName
      distance: $distance
      starRatings: $starRatings
      brandIds: $brandIds
      amenityIds: $amenityIds
      checkIn: $checkIn
      checkOut: $checkOut
      latitude: $latitude
      longitude: $longitude
      guests: $guests
      groupId: $groupId
      searchBy: $searchBy
      airportCode: $airportCode
      stationCode: $stationCode
      locationName: $locationName
      nameSearch: $nameSearch
      hideSoldOut: $hideSoldOut
      deviceClass: $deviceClass
      failedBooking: $failedBooking
    ) {
      totalCount
      hotels {
        id
        isEcoFriendly
        name
        descriptions {
          title
          body
        }
        address {
          airportCode
          geocode {
            lat
            long
          }
          street1
          street2
          city
          stateCode
          postalCode
          countryCode
        }
        distance {
          value
          units
        }
        starRating
        phoneNumber
        chainIconUrl
        chainCode
        logoPhotoUrl
        isPreferred
        isCancellable
        policy {
          isInPolicy
          outOfPolicyReasons
        }
        isSoldOut
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        perDiemRate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        privateRate {
          averageRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          realTime
          corporate
        }
        rooms {
          id
          type
          description
          inventoryId
          totalCost {
            amount
            currency
          }
          totalCostRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          feesAndTaxes {
            amount
            currency
          }
          feesAndTaxes {
            amount
            currency
          }
          feesAndTaxesRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          averageRate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          isCorporateRate
          dailyRates {
            rate {
              primary {
                amount
                currency
              }
              secondary {
                amount
                currency
              }
            }
            date
          }
          isFreeCancellable
          policy {
            isInPolicy
            outOfPolicyReasons
          }
          source
          isSelectable
          cancellationPolicy
          cancellationPolicyDescription
          preferredTag
          rateQualifier
          numberOfGuests
        }
        amenities {
          name
          isOfProperty
          isOfRoom
        }
        reviewsProvider
        totalReviewCount
        medianReviewRating
        medianReviewRatingImageUrl
        hotelReviewsDetailsUrl
        reviews {
          title
          summary
          content
          date
          userRating
          ratingImageUrl
        }
        photos {
          index
          size
          url
        }
        preference {
          code
          highlight
          ownerType
          ownerName
          tooltipText
          customBadge {
            name
            color
          }
        }
        resultKey
        notes
        exclusiveAmenities {
          name
          isOfProperty
          isOfRoom
        }
      }
      groups {
        id
        name
        count
      }
      defaultGroup {
        id
        name
        count
      }
      superBrandFilters {
        brandId
        brandDisplayName
        hotelCount
        brands {
          brandId
          brandDisplayName
          hotelCount
        }
      }
      brandFilters {
        brandId
        brandDisplayName
        hotelCount
      }
      amenityFilters {
        amenityId
        amenityDisplayName
        hotelCount
      }
      messages {
        header
        text
        isDialog
        hasDesktopBannerImage
      }
      perDiemRate {
        primary {
          amount
          currency
        }
        secondary {
          amount
          currency
        }
      }
      warningCodes
    }
  }
`

/**
 * __useGetHotelsQuery__
 *
 * To run a query within a React component, call `useGetHotelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelsQuery({
 *   variables: {
 *      logsMetadata: // value for 'logsMetadata'
 *      hotelResultId: // value for 'hotelResultId'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      hotelName: // value for 'hotelName'
 *      distance: // value for 'distance'
 *      starRatings: // value for 'starRatings'
 *      brandIds: // value for 'brandIds'
 *      amenityIds: // value for 'amenityIds'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      guests: // value for 'guests'
 *      groupId: // value for 'groupId'
 *      searchBy: // value for 'searchBy'
 *      airportCode: // value for 'airportCode'
 *      stationCode: // value for 'stationCode'
 *      locationName: // value for 'locationName'
 *      nameSearch: // value for 'nameSearch'
 *      hideSoldOut: // value for 'hideSoldOut'
 *      deviceClass: // value for 'deviceClass'
 *      failedBooking: // value for 'failedBooking'
 *   },
 * });
 */
export function useGetHotelsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetHotelsQuery, GetHotelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetHotelsQuery, GetHotelsQueryVariables>(
    GetHotelsDocument,
    options,
  )
}
export function useGetHotelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelsQuery, GetHotelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetHotelsQuery, GetHotelsQueryVariables>(
    GetHotelsDocument,
    options,
  )
}
export type GetHotelsQueryHookResult = ReturnType<typeof useGetHotelsQuery>
export type GetHotelsLazyQueryHookResult = ReturnType<typeof useGetHotelsLazyQuery>
export type GetHotelsQueryResult = ApolloReactCommon.QueryResult<
  GetHotelsQuery,
  GetHotelsQueryVariables
>
export const ClearMeetingInfoDocument = gql`
  mutation ClearMeetingInfo {
    clearMeetingInfo {
      code
      success
      message
      errorCodes
    }
  }
`
export type ClearMeetingInfoMutationFn = ApolloReactCommon.MutationFunction<
  ClearMeetingInfoMutation,
  ClearMeetingInfoMutationVariables
>

/**
 * __useClearMeetingInfoMutation__
 *
 * To run a mutation, you first call `useClearMeetingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearMeetingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearMeetingInfoMutation, { data, loading, error }] = useClearMeetingInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearMeetingInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearMeetingInfoMutation,
    ClearMeetingInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ClearMeetingInfoMutation, ClearMeetingInfoMutationVariables>(
    ClearMeetingInfoDocument,
    options,
  )
}
export type ClearMeetingInfoMutationHookResult = ReturnType<typeof useClearMeetingInfoMutation>
export type ClearMeetingInfoMutationResult = ApolloReactCommon.MutationResult<ClearMeetingInfoMutation>
export type ClearMeetingInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearMeetingInfoMutation,
  ClearMeetingInfoMutationVariables
>
export const GetMeetingInfoDocument = gql`
  query getMeetingInfo {
    getMeetingInfo {
      ... on UpsertMeetingInfoResponse {
        __typename
        meeting {
          id
          externalId
          name
          location
          policies {
            arrival {
              startDate
              endDate
            }
            departure {
              startDate
              endDate
            }
            isFlightEnabled
            isHotelEnabled
            isCarEnabled
            flightPolicies {
              destinationAirport
              alternateDestinationAirportAllowed
            }
          }
          dates {
            startDate
            endDate
          }
          provider
          confirmationNumber
          message
          customFields {
            name
            value
          }
        }
        participant {
          email
          firstName
          lastName
          customFields {
            name
            value
          }
        }
        isMeetingModeDelegate
      }
      ... on UnexpectedError {
        __typename
        message
      }
    }
  }
`

/**
 * __useGetMeetingInfoQuery__
 *
 * To run a query within a React component, call `useGetMeetingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeetingInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMeetingInfoQuery,
    GetMeetingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetMeetingInfoQuery, GetMeetingInfoQueryVariables>(
    GetMeetingInfoDocument,
    options,
  )
}
export function useGetMeetingInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMeetingInfoQuery,
    GetMeetingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetMeetingInfoQuery, GetMeetingInfoQueryVariables>(
    GetMeetingInfoDocument,
    options,
  )
}
export type GetMeetingInfoQueryHookResult = ReturnType<typeof useGetMeetingInfoQuery>
export type GetMeetingInfoLazyQueryHookResult = ReturnType<typeof useGetMeetingInfoLazyQuery>
export type GetMeetingInfoQueryResult = ApolloReactCommon.QueryResult<
  GetMeetingInfoQuery,
  GetMeetingInfoQueryVariables
>
export const UpdateMeetingIdCookieDocument = gql`
  mutation updateMeetingIdCookie($input: UpdateMeetingIdCookieInput!) {
    updateMeetingIdCookie(input: $input) {
      success
    }
  }
`
export type UpdateMeetingIdCookieMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMeetingIdCookieMutation,
  UpdateMeetingIdCookieMutationVariables
>

/**
 * __useUpdateMeetingIdCookieMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingIdCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingIdCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingIdCookieMutation, { data, loading, error }] = useUpdateMeetingIdCookieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingIdCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMeetingIdCookieMutation,
    UpdateMeetingIdCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateMeetingIdCookieMutation,
    UpdateMeetingIdCookieMutationVariables
  >(UpdateMeetingIdCookieDocument, options)
}
export type UpdateMeetingIdCookieMutationHookResult = ReturnType<
  typeof useUpdateMeetingIdCookieMutation
>
export type UpdateMeetingIdCookieMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingIdCookieMutation>
export type UpdateMeetingIdCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMeetingIdCookieMutation,
  UpdateMeetingIdCookieMutationVariables
>
export const UpsertMeetingInfoDocument = gql`
  mutation UpsertMeetingInfo($input: UpsertMeetingInfoInput!) {
    upsertMeetingInfo(input: $input) {
      ... on UpsertMeetingInfoResponse {
        __typename
        meeting {
          id
          externalId
          name
          location
          policies {
            arrival {
              startDate
              endDate
            }
            departure {
              startDate
              endDate
            }
            isFlightEnabled
            isHotelEnabled
            isCarEnabled
            flightPolicies {
              destinationAirport
              alternateDestinationAirportAllowed
            }
          }
          dates {
            startDate
            endDate
          }
          provider
          confirmationNumber
          message
          customFields {
            name
            value
          }
        }
        participant {
          email
          firstName
          lastName
          customFields {
            name
            value
          }
        }
        isMeetingModeDelegate
      }
      ... on UnexpectedError {
        __typename
        message
      }
    }
  }
`
export type UpsertMeetingInfoMutationFn = ApolloReactCommon.MutationFunction<
  UpsertMeetingInfoMutation,
  UpsertMeetingInfoMutationVariables
>

/**
 * __useUpsertMeetingInfoMutation__
 *
 * To run a mutation, you first call `useUpsertMeetingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMeetingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMeetingInfoMutation, { data, loading, error }] = useUpsertMeetingInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMeetingInfoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertMeetingInfoMutation,
    UpsertMeetingInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpsertMeetingInfoMutation,
    UpsertMeetingInfoMutationVariables
  >(UpsertMeetingInfoDocument, options)
}
export type UpsertMeetingInfoMutationHookResult = ReturnType<typeof useUpsertMeetingInfoMutation>
export type UpsertMeetingInfoMutationResult = ApolloReactCommon.MutationResult<UpsertMeetingInfoMutation>
export type UpsertMeetingInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertMeetingInfoMutation,
  UpsertMeetingInfoMutationVariables
>
export const SearchMobilityMultiModalDocument = gql`
  query searchMobilityMultiModal($input: MobilityMultiModalSearchRequest!) {
    searchMobilityMultiModal(input: $input) {
      ... on MobilityMultiModalSearchResponse {
        __typename
        routes {
          routeName
          startLocation {
            placeId
            geocode {
              long
              lat
            }
            address
          }
          routeType
          routeDistance
          routeDescription
          estimatedDuration
          estimatePrice {
            currency
            amount
          }
          endLocation {
            placeId
            geocode {
              long
              lat
            }
            address
          }
        }
      }
      ... on MobilityMultiModalSearchErrorResponse {
        __typename
        reason
      }
      ... on UnexpectedError {
        __typename
        message
      }
    }
  }
`

/**
 * __useSearchMobilityMultiModalQuery__
 *
 * To run a query within a React component, call `useSearchMobilityMultiModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMobilityMultiModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMobilityMultiModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchMobilityMultiModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SearchMobilityMultiModalQuery,
    SearchMobilityMultiModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    SearchMobilityMultiModalQuery,
    SearchMobilityMultiModalQueryVariables
  >(SearchMobilityMultiModalDocument, options)
}
export function useSearchMobilityMultiModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchMobilityMultiModalQuery,
    SearchMobilityMultiModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    SearchMobilityMultiModalQuery,
    SearchMobilityMultiModalQueryVariables
  >(SearchMobilityMultiModalDocument, options)
}
export type SearchMobilityMultiModalQueryHookResult = ReturnType<
  typeof useSearchMobilityMultiModalQuery
>
export type SearchMobilityMultiModalLazyQueryHookResult = ReturnType<
  typeof useSearchMobilityMultiModalLazyQuery
>
export type SearchMobilityMultiModalQueryResult = ApolloReactCommon.QueryResult<
  SearchMobilityMultiModalQuery,
  SearchMobilityMultiModalQueryVariables
>
export const SetRcSessionCookieWithCookieDocument = gql`
  mutation setRcSessionCookieWithCookie {
    setRcSessionCookieWithCookie
  }
`
export type SetRcSessionCookieWithCookieMutationFn = ApolloReactCommon.MutationFunction<
  SetRcSessionCookieWithCookieMutation,
  SetRcSessionCookieWithCookieMutationVariables
>

/**
 * __useSetRcSessionCookieWithCookieMutation__
 *
 * To run a mutation, you first call `useSetRcSessionCookieWithCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRcSessionCookieWithCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRcSessionCookieWithCookieMutation, { data, loading, error }] = useSetRcSessionCookieWithCookieMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetRcSessionCookieWithCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetRcSessionCookieWithCookieMutation,
    SetRcSessionCookieWithCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SetRcSessionCookieWithCookieMutation,
    SetRcSessionCookieWithCookieMutationVariables
  >(SetRcSessionCookieWithCookieDocument, options)
}
export type SetRcSessionCookieWithCookieMutationHookResult = ReturnType<
  typeof useSetRcSessionCookieWithCookieMutation
>
export type SetRcSessionCookieWithCookieMutationResult = ApolloReactCommon.MutationResult<SetRcSessionCookieWithCookieMutation>
export type SetRcSessionCookieWithCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetRcSessionCookieWithCookieMutation,
  SetRcSessionCookieWithCookieMutationVariables
>
export const SetRcSessionCookieDocument = gql`
  mutation setRcSessionCookie($input: SetRcSessionCookieInput!) {
    setRcSessionCookie(input: $input)
  }
`
export type SetRcSessionCookieMutationFn = ApolloReactCommon.MutationFunction<
  SetRcSessionCookieMutation,
  SetRcSessionCookieMutationVariables
>

/**
 * __useSetRcSessionCookieMutation__
 *
 * To run a mutation, you first call `useSetRcSessionCookieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRcSessionCookieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRcSessionCookieMutation, { data, loading, error }] = useSetRcSessionCookieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRcSessionCookieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetRcSessionCookieMutation,
    SetRcSessionCookieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SetRcSessionCookieMutation,
    SetRcSessionCookieMutationVariables
  >(SetRcSessionCookieDocument, options)
}
export type SetRcSessionCookieMutationHookResult = ReturnType<typeof useSetRcSessionCookieMutation>
export type SetRcSessionCookieMutationResult = ApolloReactCommon.MutationResult<SetRcSessionCookieMutation>
export type SetRcSessionCookieMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetRcSessionCookieMutation,
  SetRcSessionCookieMutationVariables
>
export const PartnerCoBrandingConfigurationDocument = gql`
  query partnerCoBrandingConfiguration($input: PartnerCoBrandingConfigurationInput!) {
    partnerCoBrandingConfiguration(input: $input) {
      partnerLogoUrl
      customPartnerLogoUrl
      customCompanyLogoUrl
      customMobilePartnerLogoUrl
      customMobileCompanyLogoUrl
      termsOfUseLabel
      termsOfUseLinkEnabled
      termsOfUseLinkUrl
      termsOfUse
      copyright
      privacyPolicyEnabled
      privacyPolicyLinkEnabled
      privacyPolicyText
      privacyPolicyLabel
      privacyPolicyLinkUrl
      customFooterEnabled
      customFooter
      supportTextEnabled
      supportText
      faqLinkEnabled
      faqLabel
      faqLinkUrl
      partnerCoBrandingEnabled
      theme
    }
  }
`

/**
 * __usePartnerCoBrandingConfigurationQuery__
 *
 * To run a query within a React component, call `usePartnerCoBrandingConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerCoBrandingConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerCoBrandingConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartnerCoBrandingConfigurationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PartnerCoBrandingConfigurationQuery,
    PartnerCoBrandingConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    PartnerCoBrandingConfigurationQuery,
    PartnerCoBrandingConfigurationQueryVariables
  >(PartnerCoBrandingConfigurationDocument, options)
}
export function usePartnerCoBrandingConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerCoBrandingConfigurationQuery,
    PartnerCoBrandingConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    PartnerCoBrandingConfigurationQuery,
    PartnerCoBrandingConfigurationQueryVariables
  >(PartnerCoBrandingConfigurationDocument, options)
}
export type PartnerCoBrandingConfigurationQueryHookResult = ReturnType<
  typeof usePartnerCoBrandingConfigurationQuery
>
export type PartnerCoBrandingConfigurationLazyQueryHookResult = ReturnType<
  typeof usePartnerCoBrandingConfigurationLazyQuery
>
export type PartnerCoBrandingConfigurationQueryResult = ApolloReactCommon.QueryResult<
  PartnerCoBrandingConfigurationQuery,
  PartnerCoBrandingConfigurationQueryVariables
>
export const GetOrdersDocument = gql`
  query getOrders($input: OrdersInput!) {
    orders(input: $input) {
      hasNextPage
      startIndex
      pageSize
      totalResults
      transactionGroups {
        transactionId
        name
        rollUpStartTime
        rollUpEndTime
        serviceCategoryList
        transactionGroupId
        cityImageUrl
        travelerId
        travelerName
        meetingInfo {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  )
}
export function useGetOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  )
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>
export type GetOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>
export const GetUserTripsDocument = gql`
  query getUserTrips($input: UserTripsInput!) {
    userTrips(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on GetUserTripsResponse {
        totalResults
        tripsType
        trips {
          ...trips
        }
        page {
          ...page
        }
      }
    }
  }
  ${TripsFragmentDoc}
  ${PageFragmentDoc}
`

/**
 * __useGetUserTripsQuery__
 *
 * To run a query within a React component, call `useGetUserTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTripsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserTripsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetUserTripsQuery, GetUserTripsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetUserTripsQuery, GetUserTripsQueryVariables>(
    GetUserTripsDocument,
    options,
  )
}
export function useGetUserTripsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserTripsQuery,
    GetUserTripsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetUserTripsQuery, GetUserTripsQueryVariables>(
    GetUserTripsDocument,
    options,
  )
}
export type GetUserTripsQueryHookResult = ReturnType<typeof useGetUserTripsQuery>
export type GetUserTripsLazyQueryHookResult = ReturnType<typeof useGetUserTripsLazyQuery>
export type GetUserTripsQueryResult = ApolloReactCommon.QueryResult<
  GetUserTripsQuery,
  GetUserTripsQueryVariables
>
export const CancelReservationDocument = gql`
  mutation cancelReservation($input: CancelReservationInput!) {
    cancelReservation(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CancelReservationResponse {
        success
        reservationId
      }
    }
  }
`
export type CancelReservationMutationFn = ApolloReactCommon.MutationFunction<
  CancelReservationMutation,
  CancelReservationMutationVariables
>

/**
 * __useCancelReservationMutation__
 *
 * To run a mutation, you first call `useCancelReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReservationMutation, { data, loading, error }] = useCancelReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReservationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelReservationMutation,
    CancelReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CancelReservationMutation,
    CancelReservationMutationVariables
  >(CancelReservationDocument, options)
}
export type CancelReservationMutationHookResult = ReturnType<typeof useCancelReservationMutation>
export type CancelReservationMutationResult = ApolloReactCommon.MutationResult<CancelReservationMutation>
export type CancelReservationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelReservationMutation,
  CancelReservationMutationVariables
>
export const GetTripDetailsDocument = gql`
  query getTripDetails($input: TripDetailsInput!) {
    tripDetails(input: $input) {
      __typename
      ... on GetTripDetailsResponse {
        reservations {
          __typename
          ... on CarRentalReservation {
            ...carRentalReservation
          }
          ... on HotelReservation {
            ...hotelReservation
          }
        }
        tripId
        tripType
        tripCostDetails {
          domainCostSummary {
            __typename
            ... on CarRentalReservationsCostSummary {
              ...carRentalReservationsCostSummary
            }
            ... on HotelReservationsCostSummary {
              ...hotelReservationsCostSummary
            }
          }
          total {
            ...rate
          }
        }
        tripSummary {
          domains
          endDate
          id
          startDate
          title
        }
      }
      ... on TripNotFoundResponse {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
  ${CarRentalReservationFragmentDoc}
  ${HotelReservationFragmentDoc}
  ${CarRentalReservationsCostSummaryFragmentDoc}
  ${HotelReservationsCostSummaryFragmentDoc}
  ${RateFragmentDoc}
`

/**
 * __useGetTripDetailsQuery__
 *
 * To run a query within a React component, call `useGetTripDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTripDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetTripDetailsQuery, GetTripDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTripDetailsQuery, GetTripDetailsQueryVariables>(
    GetTripDetailsDocument,
    options,
  )
}
export function useGetTripDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTripDetailsQuery,
    GetTripDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTripDetailsQuery, GetTripDetailsQueryVariables>(
    GetTripDetailsDocument,
    options,
  )
}
export type GetTripDetailsQueryHookResult = ReturnType<typeof useGetTripDetailsQuery>
export type GetTripDetailsLazyQueryHookResult = ReturnType<typeof useGetTripDetailsLazyQuery>
export type GetTripDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetTripDetailsQuery,
  GetTripDetailsQueryVariables
>
export const BookAgainTripDocument = gql`
  mutation bookAgainTrip($input: BookAgainTripInput!) {
    bookAgainTrip(input: $input) {
      itinerary {
        ...itinerary
      }
      itineraryMetadata {
        ...itineraryMetadata
      }
    }
  }
  ${ItineraryFragmentDoc}
  ${ItineraryMetadataFragmentDoc}
`
export type BookAgainTripMutationFn = ApolloReactCommon.MutationFunction<
  BookAgainTripMutation,
  BookAgainTripMutationVariables
>

/**
 * __useBookAgainTripMutation__
 *
 * To run a mutation, you first call `useBookAgainTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAgainTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAgainTripMutation, { data, loading, error }] = useBookAgainTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAgainTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BookAgainTripMutation,
    BookAgainTripMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<BookAgainTripMutation, BookAgainTripMutationVariables>(
    BookAgainTripDocument,
    options,
  )
}
export type BookAgainTripMutationHookResult = ReturnType<typeof useBookAgainTripMutation>
export type BookAgainTripMutationResult = ApolloReactCommon.MutationResult<BookAgainTripMutation>
export type BookAgainTripMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BookAgainTripMutation,
  BookAgainTripMutationVariables
>
export const CancelRideHailDocument = gql`
  mutation cancelRideHail($input: CancelRideHailInput!) {
    cancelRideHailWithError(input: $input) {
      __typename
      ... on CancelRideHailResponse {
        response
      }
      ... on CancelRideHailErrorResponse {
        reason
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`
export type CancelRideHailMutationFn = ApolloReactCommon.MutationFunction<
  CancelRideHailMutation,
  CancelRideHailMutationVariables
>

/**
 * __useCancelRideHailMutation__
 *
 * To run a mutation, you first call `useCancelRideHailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRideHailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRideHailMutation, { data, loading, error }] = useCancelRideHailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelRideHailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelRideHailMutation,
    CancelRideHailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CancelRideHailMutation, CancelRideHailMutationVariables>(
    CancelRideHailDocument,
    options,
  )
}
export type CancelRideHailMutationHookResult = ReturnType<typeof useCancelRideHailMutation>
export type CancelRideHailMutationResult = ApolloReactCommon.MutationResult<CancelRideHailMutation>
export type CancelRideHailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelRideHailMutation,
  CancelRideHailMutationVariables
>
export const CancelTripSegmentDocument = gql`
  mutation cancelTripSegment($input: CancelTripSegmentInput!) {
    cancelTripSegment(input: $input) {
      success
      message
      code
    }
  }
`
export type CancelTripSegmentMutationFn = ApolloReactCommon.MutationFunction<
  CancelTripSegmentMutation,
  CancelTripSegmentMutationVariables
>

/**
 * __useCancelTripSegmentMutation__
 *
 * To run a mutation, you first call `useCancelTripSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTripSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTripSegmentMutation, { data, loading, error }] = useCancelTripSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTripSegmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelTripSegmentMutation,
    CancelTripSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CancelTripSegmentMutation,
    CancelTripSegmentMutationVariables
  >(CancelTripSegmentDocument, options)
}
export type CancelTripSegmentMutationHookResult = ReturnType<typeof useCancelTripSegmentMutation>
export type CancelTripSegmentMutationResult = ApolloReactCommon.MutationResult<CancelTripSegmentMutation>
export type CancelTripSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelTripSegmentMutation,
  CancelTripSegmentMutationVariables
>
export const CancelTripDocument = gql`
  mutation CancelTrip($tripId: Int!) {
    cancelTrip(input: { tripId: $tripId }) {
      success
      message
      code
      errorCodes
    }
  }
`
export type CancelTripMutationFn = ApolloReactCommon.MutationFunction<
  CancelTripMutation,
  CancelTripMutationVariables
>

/**
 * __useCancelTripMutation__
 *
 * To run a mutation, you first call `useCancelTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTripMutation, { data, loading, error }] = useCancelTripMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useCancelTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelTripMutation,
    CancelTripMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CancelTripMutation, CancelTripMutationVariables>(
    CancelTripDocument,
    options,
  )
}
export type CancelTripMutationHookResult = ReturnType<typeof useCancelTripMutation>
export type CancelTripMutationResult = ApolloReactCommon.MutationResult<CancelTripMutation>
export type CancelTripMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelTripMutation,
  CancelTripMutationVariables
>
export const ChangeFlightSeatsDocument = gql`
  mutation changeFlightSeats($input: ChangeFlightSeatsInput!) {
    changeFlightSeats(input: $input) {
      code
      success
      message
    }
  }
`
export type ChangeFlightSeatsMutationFn = ApolloReactCommon.MutationFunction<
  ChangeFlightSeatsMutation,
  ChangeFlightSeatsMutationVariables
>

/**
 * __useChangeFlightSeatsMutation__
 *
 * To run a mutation, you first call `useChangeFlightSeatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFlightSeatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFlightSeatsMutation, { data, loading, error }] = useChangeFlightSeatsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeFlightSeatsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeFlightSeatsMutation,
    ChangeFlightSeatsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ChangeFlightSeatsMutation,
    ChangeFlightSeatsMutationVariables
  >(ChangeFlightSeatsDocument, options)
}
export type ChangeFlightSeatsMutationHookResult = ReturnType<typeof useChangeFlightSeatsMutation>
export type ChangeFlightSeatsMutationResult = ApolloReactCommon.MutationResult<ChangeFlightSeatsMutation>
export type ChangeFlightSeatsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeFlightSeatsMutation,
  ChangeFlightSeatsMutationVariables
>
export const GetTripDocument = gql`
  query getTrip($input: TripInput!) {
    trip(input: $input) {
      ...trip
    }
  }
  ${TripFragmentDoc}
`

/**
 * __useGetTripQuery__
 *
 * To run a query within a React component, call `useGetTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTripQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetTripQuery, GetTripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTripQuery, GetTripQueryVariables>(GetTripDocument, options)
}
export function useGetTripLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTripQuery, GetTripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTripQuery, GetTripQueryVariables>(
    GetTripDocument,
    options,
  )
}
export type GetTripQueryHookResult = ReturnType<typeof useGetTripQuery>
export type GetTripLazyQueryHookResult = ReturnType<typeof useGetTripLazyQuery>
export type GetTripQueryResult = ApolloReactCommon.QueryResult<GetTripQuery, GetTripQueryVariables>
export const GetSearchInitiateInfoDocument = gql`
  query getSearchInitiateInfo($input: SearchInitiateInfoInput) {
    searchInitiateInfo(input: $input) {
      customFieldsConfiguration {
        customFieldCollection {
          customFields {
            checkBoxDisplay {
              isChecked
              isCheckedValueRequired
            }
            textDisplay {
              minLength
              maxLength
              numberOfLines
              characterWidth
            }
            enumerationDisplay {
              option {
                name
                value
              }
              isRadioButtonsStyle
            }
            numberDisplay {
              decimalPlaces
              minValue
              maxValue
            }
            dateTimeDisplay {
              displayYear
              displayMonth
              displayDay
              displayHour
              displayMinute
              displayTimeZone
              minYear
              maxYear
              minMonth
              maxMonth
              minDay
              maxDay
              isAllowedPastDateTime
            }
            type
            optional
            name
            label
            defaultValue
            isHasClientSideJavascriptValidator
            isNotAppliedServerSideJavascriptInitializer
            tooltip
          }
          collectionId
          collectionLabel
          collectionDescription
          isNotAppliedServerSideJavascript
        }
      }
    }
  }
`

/**
 * __useGetSearchInitiateInfoQuery__
 *
 * To run a query within a React component, call `useGetSearchInitiateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchInitiateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchInitiateInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchInitiateInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSearchInitiateInfoQuery,
    GetSearchInitiateInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSearchInitiateInfoQuery, GetSearchInitiateInfoQueryVariables>(
    GetSearchInitiateInfoDocument,
    options,
  )
}
export function useGetSearchInitiateInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSearchInitiateInfoQuery,
    GetSearchInitiateInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSearchInitiateInfoQuery,
    GetSearchInitiateInfoQueryVariables
  >(GetSearchInitiateInfoDocument, options)
}
export type GetSearchInitiateInfoQueryHookResult = ReturnType<typeof useGetSearchInitiateInfoQuery>
export type GetSearchInitiateInfoLazyQueryHookResult = ReturnType<
  typeof useGetSearchInitiateInfoLazyQuery
>
export type GetSearchInitiateInfoQueryResult = ApolloReactCommon.QueryResult<
  GetSearchInitiateInfoQuery,
  GetSearchInitiateInfoQueryVariables
>
export const GetRailETicketsDocument = gql`
  query getRailETickets($input: RailETicketInput!) {
    getRailETickets(input: $input) {
      tickets
    }
  }
`

/**
 * __useGetRailETicketsQuery__
 *
 * To run a query within a React component, call `useGetRailETicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailETicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailETicketsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRailETicketsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRailETicketsQuery,
    GetRailETicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRailETicketsQuery, GetRailETicketsQueryVariables>(
    GetRailETicketsDocument,
    options,
  )
}
export function useGetRailETicketsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRailETicketsQuery,
    GetRailETicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetRailETicketsQuery, GetRailETicketsQueryVariables>(
    GetRailETicketsDocument,
    options,
  )
}
export type GetRailETicketsQueryHookResult = ReturnType<typeof useGetRailETicketsQuery>
export type GetRailETicketsLazyQueryHookResult = ReturnType<typeof useGetRailETicketsLazyQuery>
export type GetRailETicketsQueryResult = ApolloReactCommon.QueryResult<
  GetRailETicketsQuery,
  GetRailETicketsQueryVariables
>
export const GetRailDocument = gql`
  query getRail($input: RailInput!) {
    rail(input: $input) {
      totalCount
      pageStart
      pageEnd
      maxResultsSize
      isCustomTimeReplaced
      railLegs {
        legId
        railTime {
          hours
          minutes
        }
        labels
        daysInBetween
        railStops
        origin
        originCode
        destination
        destinationCode
        departureDate
        arrivalDate
        policy {
          isInPolicy
          outOfPolicyData {
            type
            data
            currency
          }
          outOfPolicyData {
            data
            type
            currency
          }
        }
        rate {
          primary {
            amount
            currency
          }
          secondary {
            amount
            currency
          }
        }
        distance {
          value
          units
        }
        segments {
          id
          trainIdentifier
          railTime {
            hours
            minutes
          }
          origin {
            city
            terminal
            code
            name
          }
          destination {
            city
            terminal
            code
            name
          }
          departureDate
          arrivalDate
          percentageOnTime
          carrier {
            id
            name
            imageUrl
            logoImageUrl
            code
          }
          amenities {
            id
            name
          }
          preference {
            code
            highlight
            ownerType
            ownerName
            tooltipText
            customBadge {
              name
              color
            }
          }
          trainNumber
          trainId
          train {
            code
            name
          }
          codeshareCarrier {
            name
            code
          }
          isSeatMapAvailable
          isSeatSelectable
          preferredBadgeText
          isPreferredAny
          stops {
            departingTime
            stationName
            time
          }
          changeDuration {
            minutes
            hours
          }
          transportationMode
        }
        fares {
          tripId
          tier {
            id
            name
          }
          total {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          segments {
            segmentId
            serviceClass
            bookingCode
            basisCode
            displayServiceClass
          }
          restriction
          isRefundable
          isWebFare
          showOutOfPolicy
          rulesAndRestrictions {
            id
            type
            rulesAndRestriction
            penaltyFee {
              primary {
                amount
                currency
              }
              secondary {
                amount
                currency
              }
            }
          }
          isSelectable
          appliedRailCards
          permittedOriginStations {
            code
            description
          }
          permittedDestinationStations {
            code
            description
          }
        }
        connectionInfo {
          segmentId
          connectionTimeMinutes
        }
        isSelectable
        ecoInfo
        platform
        isUnusedTicketApplicable
        totalTime {
          hours
          minutes
        }
      }
      messages {
        header
        text
        mobileText
        isDialog
        hasDesktopBannerImage
      }
      filters {
        trainCompany {
          id
          value
          name
          imageUrl
        }
        station {
          id
          origin {
            code
            name
            city
          }
          destination {
            code
            name
            city
          }
        }
        stops {
          id
          value
        }
        fareTier {
          id
          value
          displayName
        }
        originTimeRange {
          start
          end
        }
        destinationTime {
          start
          end
        }
      }
      fareConditions {
        tier {
          id
          name
        }
        localizedFareConditions {
          fareTypeDescription
          conditions {
            name
            description
          }
          summaryConditions
        }
      }
      railPolicies {
        isOutOfPolicyFaresBookable
        isRailFirstClassAllowed
      }
    }
  }
`

/**
 * __useGetRailQuery__
 *
 * To run a query within a React component, call `useGetRailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetRailQuery, GetRailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRailQuery, GetRailQueryVariables>(GetRailDocument, options)
}
export function useGetRailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRailQuery, GetRailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetRailQuery, GetRailQueryVariables>(
    GetRailDocument,
    options,
  )
}
export type GetRailQueryHookResult = ReturnType<typeof useGetRailQuery>
export type GetRailLazyQueryHookResult = ReturnType<typeof useGetRailLazyQuery>
export type GetRailQueryResult = ApolloReactCommon.QueryResult<GetRailQuery, GetRailQueryVariables>
export const RecentCarRentalSearchesWithoutDatesDocument = gql`
  query recentCarRentalSearchesWithoutDates {
    recentCarRentalSearchesWithoutDates {
      ... on RecentCarRentalSearchesWithoutDatesResult {
        items {
          id
          carRentalSearchParams {
            pickUp {
              location {
                name
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
              }
            }
            dropOff {
              location {
                name
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useRecentCarRentalSearchesWithoutDatesQuery__
 *
 * To run a query within a React component, call `useRecentCarRentalSearchesWithoutDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentCarRentalSearchesWithoutDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentCarRentalSearchesWithoutDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentCarRentalSearchesWithoutDatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RecentCarRentalSearchesWithoutDatesQuery,
    RecentCarRentalSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    RecentCarRentalSearchesWithoutDatesQuery,
    RecentCarRentalSearchesWithoutDatesQueryVariables
  >(RecentCarRentalSearchesWithoutDatesDocument, options)
}
export function useRecentCarRentalSearchesWithoutDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RecentCarRentalSearchesWithoutDatesQuery,
    RecentCarRentalSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    RecentCarRentalSearchesWithoutDatesQuery,
    RecentCarRentalSearchesWithoutDatesQueryVariables
  >(RecentCarRentalSearchesWithoutDatesDocument, options)
}
export type RecentCarRentalSearchesWithoutDatesQueryHookResult = ReturnType<
  typeof useRecentCarRentalSearchesWithoutDatesQuery
>
export type RecentCarRentalSearchesWithoutDatesLazyQueryHookResult = ReturnType<
  typeof useRecentCarRentalSearchesWithoutDatesLazyQuery
>
export type RecentCarRentalSearchesWithoutDatesQueryResult = ApolloReactCommon.QueryResult<
  RecentCarRentalSearchesWithoutDatesQuery,
  RecentCarRentalSearchesWithoutDatesQueryVariables
>
export const GetRecentCarRentalSearchesDocument = gql`
  query getRecentCarRentalSearches($input: RecentCarRentalSearchesInput!) {
    recentCarRentalSearches(input: $input) {
      ... on RecentCarRentalSearchesResult {
        isOk
        errorMessages
        items {
          id
          carRentalSearchParams {
            pickUp {
              date
              time
              location {
                airportCode
                geocode {
                  lat
                  long
                }
                name
              }
            }
            dropOff {
              date
              time
              location {
                airportCode
                geocode {
                  lat
                  long
                }
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetRecentCarRentalSearchesQuery__
 *
 * To run a query within a React component, call `useGetRecentCarRentalSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentCarRentalSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentCarRentalSearchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecentCarRentalSearchesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRecentCarRentalSearchesQuery,
    GetRecentCarRentalSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRecentCarRentalSearchesQuery,
    GetRecentCarRentalSearchesQueryVariables
  >(GetRecentCarRentalSearchesDocument, options)
}
export function useGetRecentCarRentalSearchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecentCarRentalSearchesQuery,
    GetRecentCarRentalSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRecentCarRentalSearchesQuery,
    GetRecentCarRentalSearchesQueryVariables
  >(GetRecentCarRentalSearchesDocument, options)
}
export type GetRecentCarRentalSearchesQueryHookResult = ReturnType<
  typeof useGetRecentCarRentalSearchesQuery
>
export type GetRecentCarRentalSearchesLazyQueryHookResult = ReturnType<
  typeof useGetRecentCarRentalSearchesLazyQuery
>
export type GetRecentCarRentalSearchesQueryResult = ApolloReactCommon.QueryResult<
  GetRecentCarRentalSearchesQuery,
  GetRecentCarRentalSearchesQueryVariables
>
export const RecentFlightSearchesWithoutDatesDocument = gql`
  query recentFlightSearchesWithoutDates {
    recentFlightSearchesWithoutDates {
      ... on RecentFlightSearchesWithoutDatesResult {
        items {
          flightSearchParams {
            flightType
            segments {
              destination {
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
                name
              }
              origin {
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
                name
              }
              searchTimeRange {
                type
                start
                end
                customHours
              }
              cabinClass
            }
          }
          id
        }
      }
    }
  }
`

/**
 * __useRecentFlightSearchesWithoutDatesQuery__
 *
 * To run a query within a React component, call `useRecentFlightSearchesWithoutDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentFlightSearchesWithoutDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentFlightSearchesWithoutDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentFlightSearchesWithoutDatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RecentFlightSearchesWithoutDatesQuery,
    RecentFlightSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    RecentFlightSearchesWithoutDatesQuery,
    RecentFlightSearchesWithoutDatesQueryVariables
  >(RecentFlightSearchesWithoutDatesDocument, options)
}
export function useRecentFlightSearchesWithoutDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RecentFlightSearchesWithoutDatesQuery,
    RecentFlightSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    RecentFlightSearchesWithoutDatesQuery,
    RecentFlightSearchesWithoutDatesQueryVariables
  >(RecentFlightSearchesWithoutDatesDocument, options)
}
export type RecentFlightSearchesWithoutDatesQueryHookResult = ReturnType<
  typeof useRecentFlightSearchesWithoutDatesQuery
>
export type RecentFlightSearchesWithoutDatesLazyQueryHookResult = ReturnType<
  typeof useRecentFlightSearchesWithoutDatesLazyQuery
>
export type RecentFlightSearchesWithoutDatesQueryResult = ApolloReactCommon.QueryResult<
  RecentFlightSearchesWithoutDatesQuery,
  RecentFlightSearchesWithoutDatesQueryVariables
>
export const GetRecentFlightSearchesDocument = gql`
  query getRecentFlightSearches($input: RecentFlightSearchesInput!) {
    recentFlightSearches(input: $input) {
      ... on RecentFlightSearchesResult {
        isOk
        errorMessages
        items {
          id
          flightSearchParams {
            flightType
            segments {
              cabinClass
              departureDate
              destination {
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
                name
              }
              origin {
                airportCode
                geocode {
                  lat
                  long
                }
                countryCode
                name
              }
              searchTimeRange {
                type
                end
                start
                customHours
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetRecentFlightSearchesQuery__
 *
 * To run a query within a React component, call `useGetRecentFlightSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentFlightSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentFlightSearchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecentFlightSearchesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRecentFlightSearchesQuery,
    GetRecentFlightSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRecentFlightSearchesQuery,
    GetRecentFlightSearchesQueryVariables
  >(GetRecentFlightSearchesDocument, options)
}
export function useGetRecentFlightSearchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecentFlightSearchesQuery,
    GetRecentFlightSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRecentFlightSearchesQuery,
    GetRecentFlightSearchesQueryVariables
  >(GetRecentFlightSearchesDocument, options)
}
export type GetRecentFlightSearchesQueryHookResult = ReturnType<
  typeof useGetRecentFlightSearchesQuery
>
export type GetRecentFlightSearchesLazyQueryHookResult = ReturnType<
  typeof useGetRecentFlightSearchesLazyQuery
>
export type GetRecentFlightSearchesQueryResult = ApolloReactCommon.QueryResult<
  GetRecentFlightSearchesQuery,
  GetRecentFlightSearchesQueryVariables
>
export const RecentHotelSearchesWithoutDatesDocument = gql`
  query recentHotelSearchesWithoutDates {
    recentHotelSearchesWithoutDates {
      ... on RecentHotelSearchesWithoutDatesResult {
        isOk
        errorMessages
        items {
          id
          hotelSearchParams {
            searchRadius
            location {
              name
              geocode {
                lat
                long
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useRecentHotelSearchesWithoutDatesQuery__
 *
 * To run a query within a React component, call `useRecentHotelSearchesWithoutDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentHotelSearchesWithoutDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentHotelSearchesWithoutDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentHotelSearchesWithoutDatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RecentHotelSearchesWithoutDatesQuery,
    RecentHotelSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    RecentHotelSearchesWithoutDatesQuery,
    RecentHotelSearchesWithoutDatesQueryVariables
  >(RecentHotelSearchesWithoutDatesDocument, options)
}
export function useRecentHotelSearchesWithoutDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RecentHotelSearchesWithoutDatesQuery,
    RecentHotelSearchesWithoutDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    RecentHotelSearchesWithoutDatesQuery,
    RecentHotelSearchesWithoutDatesQueryVariables
  >(RecentHotelSearchesWithoutDatesDocument, options)
}
export type RecentHotelSearchesWithoutDatesQueryHookResult = ReturnType<
  typeof useRecentHotelSearchesWithoutDatesQuery
>
export type RecentHotelSearchesWithoutDatesLazyQueryHookResult = ReturnType<
  typeof useRecentHotelSearchesWithoutDatesLazyQuery
>
export type RecentHotelSearchesWithoutDatesQueryResult = ApolloReactCommon.QueryResult<
  RecentHotelSearchesWithoutDatesQuery,
  RecentHotelSearchesWithoutDatesQueryVariables
>
export const GetRecentHotelSearchesDocument = gql`
  query getRecentHotelSearches($input: RecentHotelSearchesInput!) {
    recentHotelSearches(input: $input) {
      ... on RecentHotelSearchesResult {
        isOk
        items {
          id
          hotelSearchParams {
            checkIn
            checkOut
            location {
              name
              geocode {
                lat
                long
              }
            }
            searchRadius
          }
        }
        errorMessages
      }
    }
  }
`

/**
 * __useGetRecentHotelSearchesQuery__
 *
 * To run a query within a React component, call `useGetRecentHotelSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentHotelSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentHotelSearchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecentHotelSearchesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRecentHotelSearchesQuery,
    GetRecentHotelSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetRecentHotelSearchesQuery,
    GetRecentHotelSearchesQueryVariables
  >(GetRecentHotelSearchesDocument, options)
}
export function useGetRecentHotelSearchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecentHotelSearchesQuery,
    GetRecentHotelSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRecentHotelSearchesQuery,
    GetRecentHotelSearchesQueryVariables
  >(GetRecentHotelSearchesDocument, options)
}
export type GetRecentHotelSearchesQueryHookResult = ReturnType<
  typeof useGetRecentHotelSearchesQuery
>
export type GetRecentHotelSearchesLazyQueryHookResult = ReturnType<
  typeof useGetRecentHotelSearchesLazyQuery
>
export type GetRecentHotelSearchesQueryResult = ApolloReactCommon.QueryResult<
  GetRecentHotelSearchesQuery,
  GetRecentHotelSearchesQueryVariables
>
export const GetRecentRailSearchesDocument = gql`
  query getRecentRailSearches($input: RecentRailSearchesInput!) {
    recentRailSearches(input: $input) {
      ... on RecentRailSearchesResult {
        isOk
        errorMessages
        items {
          id
          railSearchParams {
            railType
            railCards {
              code
              groupName
              name
              vendorCode
              vendorName
            }
            segments {
              departureDate
              origin {
                locationCode
                name
                geocode {
                  lat
                  long
                }
              }
              destination {
                locationCode
                name
                geocode {
                  lat
                  long
                }
              }
              searchTimeRange {
                end
                start
                type
                customHours
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetRecentRailSearchesQuery__
 *
 * To run a query within a React component, call `useGetRecentRailSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentRailSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentRailSearchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecentRailSearchesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRecentRailSearchesQuery,
    GetRecentRailSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRecentRailSearchesQuery, GetRecentRailSearchesQueryVariables>(
    GetRecentRailSearchesDocument,
    options,
  )
}
export function useGetRecentRailSearchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecentRailSearchesQuery,
    GetRecentRailSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetRecentRailSearchesQuery,
    GetRecentRailSearchesQueryVariables
  >(GetRecentRailSearchesDocument, options)
}
export type GetRecentRailSearchesQueryHookResult = ReturnType<typeof useGetRecentRailSearchesQuery>
export type GetRecentRailSearchesLazyQueryHookResult = ReturnType<
  typeof useGetRecentRailSearchesLazyQuery
>
export type GetRecentRailSearchesQueryResult = ApolloReactCommon.QueryResult<
  GetRecentRailSearchesQuery,
  GetRecentRailSearchesQueryVariables
>
export const SubmitNewCarRentalSearchDocument = gql`
  mutation submitNewCarRentalSearch($input: SubmitNewCarRentalSearchInput!) {
    submitNewCarRentalSearch(input: $input) {
      ... on SubmitNewCarRentalSearchResult {
        isOk
        errorMessages
      }
    }
  }
`
export type SubmitNewCarRentalSearchMutationFn = ApolloReactCommon.MutationFunction<
  SubmitNewCarRentalSearchMutation,
  SubmitNewCarRentalSearchMutationVariables
>

/**
 * __useSubmitNewCarRentalSearchMutation__
 *
 * To run a mutation, you first call `useSubmitNewCarRentalSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNewCarRentalSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNewCarRentalSearchMutation, { data, loading, error }] = useSubmitNewCarRentalSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitNewCarRentalSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitNewCarRentalSearchMutation,
    SubmitNewCarRentalSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SubmitNewCarRentalSearchMutation,
    SubmitNewCarRentalSearchMutationVariables
  >(SubmitNewCarRentalSearchDocument, options)
}
export type SubmitNewCarRentalSearchMutationHookResult = ReturnType<
  typeof useSubmitNewCarRentalSearchMutation
>
export type SubmitNewCarRentalSearchMutationResult = ApolloReactCommon.MutationResult<SubmitNewCarRentalSearchMutation>
export type SubmitNewCarRentalSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitNewCarRentalSearchMutation,
  SubmitNewCarRentalSearchMutationVariables
>
export const SubmitNewFlightSearchDocument = gql`
  mutation submitNewFlightSearch($input: SubmitNewFlightSearchInput!) {
    submitNewFlightSearch(input: $input) {
      ... on SubmitNewFlightSearchResult {
        isOk
        errorMessages
      }
    }
  }
`
export type SubmitNewFlightSearchMutationFn = ApolloReactCommon.MutationFunction<
  SubmitNewFlightSearchMutation,
  SubmitNewFlightSearchMutationVariables
>

/**
 * __useSubmitNewFlightSearchMutation__
 *
 * To run a mutation, you first call `useSubmitNewFlightSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNewFlightSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNewFlightSearchMutation, { data, loading, error }] = useSubmitNewFlightSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitNewFlightSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitNewFlightSearchMutation,
    SubmitNewFlightSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SubmitNewFlightSearchMutation,
    SubmitNewFlightSearchMutationVariables
  >(SubmitNewFlightSearchDocument, options)
}
export type SubmitNewFlightSearchMutationHookResult = ReturnType<
  typeof useSubmitNewFlightSearchMutation
>
export type SubmitNewFlightSearchMutationResult = ApolloReactCommon.MutationResult<SubmitNewFlightSearchMutation>
export type SubmitNewFlightSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitNewFlightSearchMutation,
  SubmitNewFlightSearchMutationVariables
>
export const SubmitNewHotelSearchDocument = gql`
  mutation submitNewHotelSearch($input: SubmitNewHotelSearchInput!) {
    submitNewHotelSearch(input: $input) {
      ... on SubmitNewHotelSearchResult {
        isOk
        errorMessages
      }
    }
  }
`
export type SubmitNewHotelSearchMutationFn = ApolloReactCommon.MutationFunction<
  SubmitNewHotelSearchMutation,
  SubmitNewHotelSearchMutationVariables
>

/**
 * __useSubmitNewHotelSearchMutation__
 *
 * To run a mutation, you first call `useSubmitNewHotelSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNewHotelSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNewHotelSearchMutation, { data, loading, error }] = useSubmitNewHotelSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitNewHotelSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitNewHotelSearchMutation,
    SubmitNewHotelSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SubmitNewHotelSearchMutation,
    SubmitNewHotelSearchMutationVariables
  >(SubmitNewHotelSearchDocument, options)
}
export type SubmitNewHotelSearchMutationHookResult = ReturnType<
  typeof useSubmitNewHotelSearchMutation
>
export type SubmitNewHotelSearchMutationResult = ApolloReactCommon.MutationResult<SubmitNewHotelSearchMutation>
export type SubmitNewHotelSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitNewHotelSearchMutation,
  SubmitNewHotelSearchMutationVariables
>
export const SubmitNewRailSearchDocument = gql`
  mutation submitNewRailSearch($input: SubmitNewRailSearchInput!) {
    submitNewRailSearch(input: $input) {
      ... on SubmitNewRailSearchResult {
        isOk
        errorMessages
      }
    }
  }
`
export type SubmitNewRailSearchMutationFn = ApolloReactCommon.MutationFunction<
  SubmitNewRailSearchMutation,
  SubmitNewRailSearchMutationVariables
>

/**
 * __useSubmitNewRailSearchMutation__
 *
 * To run a mutation, you first call `useSubmitNewRailSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNewRailSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNewRailSearchMutation, { data, loading, error }] = useSubmitNewRailSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitNewRailSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitNewRailSearchMutation,
    SubmitNewRailSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SubmitNewRailSearchMutation,
    SubmitNewRailSearchMutationVariables
  >(SubmitNewRailSearchDocument, options)
}
export type SubmitNewRailSearchMutationHookResult = ReturnType<
  typeof useSubmitNewRailSearchMutation
>
export type SubmitNewRailSearchMutationResult = ApolloReactCommon.MutationResult<SubmitNewRailSearchMutation>
export type SubmitNewRailSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitNewRailSearchMutation,
  SubmitNewRailSearchMutationVariables
>
export const AddModifyToCartDocument = gql`
  mutation addModifyToCart($input: AddModifyToCartInput!) {
    addModifyToCart(input: $input) {
      ... on CartNotFoundResponse {
        __typename
        message
      }
      ... on AddModifyToCartResponse {
        __typename
        intentId
      }
    }
  }
`
export type AddModifyToCartMutationFn = ApolloReactCommon.MutationFunction<
  AddModifyToCartMutation,
  AddModifyToCartMutationVariables
>

/**
 * __useAddModifyToCartMutation__
 *
 * To run a mutation, you first call `useAddModifyToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddModifyToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addModifyToCartMutation, { data, loading, error }] = useAddModifyToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddModifyToCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddModifyToCartMutation,
    AddModifyToCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddModifyToCartMutation, AddModifyToCartMutationVariables>(
    AddModifyToCartDocument,
    options,
  )
}
export type AddModifyToCartMutationHookResult = ReturnType<typeof useAddModifyToCartMutation>
export type AddModifyToCartMutationResult = ApolloReactCommon.MutationResult<AddModifyToCartMutation>
export type AddModifyToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddModifyToCartMutation,
  AddModifyToCartMutationVariables
>
export const AddToCartDocument = gql`
  mutation addToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CartNotFoundResponse {
        __typename
        message
      }
      ... on AddToCartResponse {
        __typename
        intentId
      }
    }
  }
`
export type AddToCartMutationFn = ApolloReactCommon.MutationFunction<
  AddToCartMutation,
  AddToCartMutationVariables
>

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddToCartMutation, AddToCartMutationVariables>(
    AddToCartDocument,
    options,
  )
}
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>
export type AddToCartMutationResult = ApolloReactCommon.MutationResult<AddToCartMutation>
export type AddToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddToCartMutation,
  AddToCartMutationVariables
>
export const CartCostAllocationDocument = gql`
  query cartCostAllocation($cartId: String!) {
    cartCostAllocation(cartId: $cartId) {
      __typename
      ... on GetCostAllocationResponse {
        cartId
        costAllocations {
          segmentId
          allocationId
          costSegmentSplit
        }
      }
      ... on CartNotFoundResponse {
        message
      }
    }
  }
`

/**
 * __useCartCostAllocationQuery__
 *
 * To run a query within a React component, call `useCartCostAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartCostAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartCostAllocationQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartCostAllocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CartCostAllocationQuery,
    CartCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<CartCostAllocationQuery, CartCostAllocationQueryVariables>(
    CartCostAllocationDocument,
    options,
  )
}
export function useCartCostAllocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CartCostAllocationQuery,
    CartCostAllocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<CartCostAllocationQuery, CartCostAllocationQueryVariables>(
    CartCostAllocationDocument,
    options,
  )
}
export type CartCostAllocationQueryHookResult = ReturnType<typeof useCartCostAllocationQuery>
export type CartCostAllocationLazyQueryHookResult = ReturnType<
  typeof useCartCostAllocationLazyQuery
>
export type CartCostAllocationQueryResult = ApolloReactCommon.QueryResult<
  CartCostAllocationQuery,
  CartCostAllocationQueryVariables
>
export const GetCartDocument = gql`
  query getCart($input: GetCartInput!) {
    cart(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CartNotFoundResponse {
        __typename
        message
      }
      ... on GetCartResponse {
        __typename
        intents {
          state
          domain
          domainObject {
            ...carRentalDomain
            ...hotelDomain
            ...flightDomain
          }
          id
        }
        cartId
        cartName
        isCartNameEditable
        cartSummary {
          ...cartSummary
        }
      }
    }
  }
  ${CarRentalDomainFragmentDoc}
  ${HotelDomainFragmentDoc}
  ${FlightDomainFragmentDoc}
  ${CartSummaryFragmentDoc}
`

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCartQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCartQuery, GetCartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options)
}
export function useGetCartLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCartQuery, GetCartQueryVariables>(
    GetCartDocument,
    options,
  )
}
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>
export type GetCartQueryResult = ApolloReactCommon.QueryResult<GetCartQuery, GetCartQueryVariables>
export const CreateCartDocument = gql`
  mutation createCart {
    createCart {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CreateCartResponse {
        __typename
        cartId
      }
    }
  }
`
export type CreateCartMutationFn = ApolloReactCommon.MutationFunction<
  CreateCartMutation,
  CreateCartMutationVariables
>

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCartMutation,
    CreateCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateCartMutation, CreateCartMutationVariables>(
    CreateCartDocument,
    options,
  )
}
export type CreateCartMutationHookResult = ReturnType<typeof useCreateCartMutation>
export type CreateCartMutationResult = ApolloReactCommon.MutationResult<CreateCartMutation>
export type CreateCartMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCartMutation,
  CreateCartMutationVariables
>
export const GetCostSummaryDocument = gql`
  query getCostSummary($cartId: ID!) {
    costSummary(cartId: $cartId) {
      __typename
      ... on CostSummaryResponse {
        cartId
        estimatedTotal {
          ...rate
        }
        prepaidCost {
          ...cartCostSummary
        }
        reservedCost {
          ...cartCostSummary
        }
        __typename
      }
      ... on CartNotFoundResponse {
        message
        __typename
      }
    }
  }
  ${RateFragmentDoc}
  ${CartCostSummaryFragmentDoc}
`

/**
 * __useGetCostSummaryQuery__
 *
 * To run a query within a React component, call `useGetCostSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostSummaryQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useGetCostSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCostSummaryQuery, GetCostSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCostSummaryQuery, GetCostSummaryQueryVariables>(
    GetCostSummaryDocument,
    options,
  )
}
export function useGetCostSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCostSummaryQuery,
    GetCostSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCostSummaryQuery, GetCostSummaryQueryVariables>(
    GetCostSummaryDocument,
    options,
  )
}
export type GetCostSummaryQueryHookResult = ReturnType<typeof useGetCostSummaryQuery>
export type GetCostSummaryLazyQueryHookResult = ReturnType<typeof useGetCostSummaryLazyQuery>
export type GetCostSummaryQueryResult = ApolloReactCommon.QueryResult<
  GetCostSummaryQuery,
  GetCostSummaryQueryVariables
>
export const GetOopInfoDocument = gql`
  query getOopInfo($cartId: ID!) {
    oopInfo(cartId: $cartId) {
      __typename
      ... on GetOOPInfoResponse {
        cartId
        isCartOutOfPolicy
        isOOPAllowed
        message
        intentComplianceCodes {
          intentId
          domain
          code
          options {
            code
            label
          }
        }
        explanation {
          required
          label
          value
        }
        __typename
      }
      ... on CartNotFoundResponse {
        __typename
      }
    }
  }
`

/**
 * __useGetOopInfoQuery__
 *
 * To run a query within a React component, call `useGetOopInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOopInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOopInfoQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useGetOopInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOopInfoQuery, GetOopInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetOopInfoQuery, GetOopInfoQueryVariables>(
    GetOopInfoDocument,
    options,
  )
}
export function useGetOopInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOopInfoQuery, GetOopInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetOopInfoQuery, GetOopInfoQueryVariables>(
    GetOopInfoDocument,
    options,
  )
}
export type GetOopInfoQueryHookResult = ReturnType<typeof useGetOopInfoQuery>
export type GetOopInfoLazyQueryHookResult = ReturnType<typeof useGetOopInfoLazyQuery>
export type GetOopInfoQueryResult = ApolloReactCommon.QueryResult<
  GetOopInfoQuery,
  GetOopInfoQueryVariables
>
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      __typename
      ... on UnexpectedError {
        message
        lcid
      }
      ... on CartNotFoundResponse {
        __typename
        message
      }
      ... on RemoveFromCartResponse {
        __typename
        code
        message
      }
    }
  }
`
export type RemoveFromCartMutationFn = ApolloReactCommon.MutationFunction<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromCartMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(
    RemoveFromCartDocument,
    options,
  )
}
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>
export type RemoveFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveFromCartMutation>
export type RemoveFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>
export const SetCostAllocationDocument = gql`
  mutation setCostAllocation($input: SetCostAllocationInput!) {
    setCostAllocation(input: $input) {
      __typename
      ... on SetCostAllocationResponse {
        cartId
      }
      ... on CartNotFoundResponse {
        message
      }
    }
  }
`
export type SetCostAllocationMutationFn = ApolloReactCommon.MutationFunction<
  SetCostAllocationMutation,
  SetCostAllocationMutationVariables
>

/**
 * __useSetCostAllocationMutation__
 *
 * To run a mutation, you first call `useSetCostAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCostAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCostAllocationMutation, { data, loading, error }] = useSetCostAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCostAllocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetCostAllocationMutation,
    SetCostAllocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SetCostAllocationMutation,
    SetCostAllocationMutationVariables
  >(SetCostAllocationDocument, options)
}
export type SetCostAllocationMutationHookResult = ReturnType<typeof useSetCostAllocationMutation>
export type SetCostAllocationMutationResult = ApolloReactCommon.MutationResult<SetCostAllocationMutation>
export type SetCostAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetCostAllocationMutation,
  SetCostAllocationMutationVariables
>
export const SetOutOfPolicyJustificationDocument = gql`
  mutation setOutOfPolicyJustification($input: SetOutOfPolicyJustificationInput!) {
    setOutOfPolicyJustification(input: $input) {
      __typename
      ... on SetOutOfPolicyJustificationResponse {
        cartId
      }
      ... on CartNotFoundResponse {
        message
      }
    }
  }
`
export type SetOutOfPolicyJustificationMutationFn = ApolloReactCommon.MutationFunction<
  SetOutOfPolicyJustificationMutation,
  SetOutOfPolicyJustificationMutationVariables
>

/**
 * __useSetOutOfPolicyJustificationMutation__
 *
 * To run a mutation, you first call `useSetOutOfPolicyJustificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOutOfPolicyJustificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOutOfPolicyJustificationMutation, { data, loading, error }] = useSetOutOfPolicyJustificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOutOfPolicyJustificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetOutOfPolicyJustificationMutation,
    SetOutOfPolicyJustificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    SetOutOfPolicyJustificationMutation,
    SetOutOfPolicyJustificationMutationVariables
  >(SetOutOfPolicyJustificationDocument, options)
}
export type SetOutOfPolicyJustificationMutationHookResult = ReturnType<
  typeof useSetOutOfPolicyJustificationMutation
>
export type SetOutOfPolicyJustificationMutationResult = ApolloReactCommon.MutationResult<SetOutOfPolicyJustificationMutation>
export type SetOutOfPolicyJustificationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetOutOfPolicyJustificationMutation,
  SetOutOfPolicyJustificationMutationVariables
>
export const AddCarRentalDocument = gql`
  mutation addCarRental($input: AddCarRentalInput!) {
    addCarRental(input: $input) {
      success
    }
  }
`
export type AddCarRentalMutationFn = ApolloReactCommon.MutationFunction<
  AddCarRentalMutation,
  AddCarRentalMutationVariables
>

/**
 * __useAddCarRentalMutation__
 *
 * To run a mutation, you first call `useAddCarRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCarRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCarRentalMutation, { data, loading, error }] = useAddCarRentalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCarRentalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCarRentalMutation,
    AddCarRentalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddCarRentalMutation, AddCarRentalMutationVariables>(
    AddCarRentalDocument,
    options,
  )
}
export type AddCarRentalMutationHookResult = ReturnType<typeof useAddCarRentalMutation>
export type AddCarRentalMutationResult = ApolloReactCommon.MutationResult<AddCarRentalMutation>
export type AddCarRentalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCarRentalMutation,
  AddCarRentalMutationVariables
>
export const AddFlightDocument = gql`
  mutation addFlight($input: AddFlightInput!) {
    addFlight(input: $input) {
      success
      data {
        isSoldOut
        isRepriceFailed
        airPriceChange {
          rate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          reason
          type
        }
      }
    }
  }
`
export type AddFlightMutationFn = ApolloReactCommon.MutationFunction<
  AddFlightMutation,
  AddFlightMutationVariables
>

/**
 * __useAddFlightMutation__
 *
 * To run a mutation, you first call `useAddFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlightMutation, { data, loading, error }] = useAddFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFlightMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddFlightMutation, AddFlightMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddFlightMutation, AddFlightMutationVariables>(
    AddFlightDocument,
    options,
  )
}
export type AddFlightMutationHookResult = ReturnType<typeof useAddFlightMutation>
export type AddFlightMutationResult = ApolloReactCommon.MutationResult<AddFlightMutation>
export type AddFlightMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddFlightMutation,
  AddFlightMutationVariables
>
export const AddRailDocument = gql`
  mutation addRail($input: AddRailInput!) {
    addRail(input: $input) {
      success
      data {
        isSoldOut
      }
    }
  }
`
export type AddRailMutationFn = ApolloReactCommon.MutationFunction<
  AddRailMutation,
  AddRailMutationVariables
>

/**
 * __useAddRailMutation__
 *
 * To run a mutation, you first call `useAddRailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRailMutation, { data, loading, error }] = useAddRailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddRailMutation, AddRailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddRailMutation, AddRailMutationVariables>(
    AddRailDocument,
    options,
  )
}
export type AddRailMutationHookResult = ReturnType<typeof useAddRailMutation>
export type AddRailMutationResult = ApolloReactCommon.MutationResult<AddRailMutation>
export type AddRailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRailMutation,
  AddRailMutationVariables
>
export const AlignTripDocument = gql`
  mutation alignTrip($input: AlignTripInput!) {
    alignTrip(input: $input) {
      itineraryMetadata {
        ...itineraryMetadata
      }
      itinerary {
        ...itinerary
      }
    }
  }
  ${ItineraryMetadataFragmentDoc}
  ${ItineraryFragmentDoc}
`
export type AlignTripMutationFn = ApolloReactCommon.MutationFunction<
  AlignTripMutation,
  AlignTripMutationVariables
>

/**
 * __useAlignTripMutation__
 *
 * To run a mutation, you first call `useAlignTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlignTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alignTripMutation, { data, loading, error }] = useAlignTripMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlignTripMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AlignTripMutation, AlignTripMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AlignTripMutation, AlignTripMutationVariables>(
    AlignTripDocument,
    options,
  )
}
export type AlignTripMutationHookResult = ReturnType<typeof useAlignTripMutation>
export type AlignTripMutationResult = ApolloReactCommon.MutationResult<AlignTripMutation>
export type AlignTripMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AlignTripMutation,
  AlignTripMutationVariables
>
export const ChangeFlightDocument = gql`
  mutation changeFlight($input: ChangeFlightInput!) {
    changeFlight(input: $input) {
      success
      message
      code
      data {
        isSoldOut
        airPriceChange {
          rate {
            primary {
              amount
              currency
            }
            secondary {
              amount
              currency
            }
          }
          reason
          type
        }
      }
    }
  }
`
export type ChangeFlightMutationFn = ApolloReactCommon.MutationFunction<
  ChangeFlightMutation,
  ChangeFlightMutationVariables
>

/**
 * __useChangeFlightMutation__
 *
 * To run a mutation, you first call `useChangeFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFlightMutation, { data, loading, error }] = useChangeFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeFlightMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeFlightMutation,
    ChangeFlightMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ChangeFlightMutation, ChangeFlightMutationVariables>(
    ChangeFlightDocument,
    options,
  )
}
export type ChangeFlightMutationHookResult = ReturnType<typeof useChangeFlightMutation>
export type ChangeFlightMutationResult = ApolloReactCommon.MutationResult<ChangeFlightMutation>
export type ChangeFlightMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeFlightMutation,
  ChangeFlightMutationVariables
>
export const ChangeSeatsAssignmentDocument = gql`
  mutation changeSeatsAssignment($input: ChangeSeatsAssignmentInput!) {
    changeSeatsAssignment(input: $input) {
      success
      message
      code
    }
  }
`
export type ChangeSeatsAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  ChangeSeatsAssignmentMutation,
  ChangeSeatsAssignmentMutationVariables
>

/**
 * __useChangeSeatsAssignmentMutation__
 *
 * To run a mutation, you first call `useChangeSeatsAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSeatsAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSeatsAssignmentMutation, { data, loading, error }] = useChangeSeatsAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSeatsAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeSeatsAssignmentMutation,
    ChangeSeatsAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ChangeSeatsAssignmentMutation,
    ChangeSeatsAssignmentMutationVariables
  >(ChangeSeatsAssignmentDocument, options)
}
export type ChangeSeatsAssignmentMutationHookResult = ReturnType<
  typeof useChangeSeatsAssignmentMutation
>
export type ChangeSeatsAssignmentMutationResult = ApolloReactCommon.MutationResult<ChangeSeatsAssignmentMutation>
export type ChangeSeatsAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeSeatsAssignmentMutation,
  ChangeSeatsAssignmentMutationVariables
>
export const CreateItineraryDocument = gql`
  mutation createItinerary($input: CreateItineraryInput) {
    createItinerary(input: $input) {
      id
    }
  }
`
export type CreateItineraryMutationFn = ApolloReactCommon.MutationFunction<
  CreateItineraryMutation,
  CreateItineraryMutationVariables
>

/**
 * __useCreateItineraryMutation__
 *
 * To run a mutation, you first call `useCreateItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItineraryMutation, { data, loading, error }] = useCreateItineraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateItineraryMutation,
    CreateItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateItineraryMutation, CreateItineraryMutationVariables>(
    CreateItineraryDocument,
    options,
  )
}
export type CreateItineraryMutationHookResult = ReturnType<typeof useCreateItineraryMutation>
export type CreateItineraryMutationResult = ApolloReactCommon.MutationResult<CreateItineraryMutation>
export type CreateItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateItineraryMutation,
  CreateItineraryMutationVariables
>
export const GetItineraryDocument = gql`
  query getItinerary(
    $itineraryId: String!
    $deviceClass: DeviceClass
    $imageOptions: ImageInputOptions
  ) {
    itinerary(itineraryId: $itineraryId, deviceClass: $deviceClass, imageOptions: $imageOptions) {
      ...itinerary
    }
  }
  ${ItineraryFragmentDoc}
`

/**
 * __useGetItineraryQuery__
 *
 * To run a query within a React component, call `useGetItineraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItineraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItineraryQuery({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      deviceClass: // value for 'deviceClass'
 *      imageOptions: // value for 'imageOptions'
 *   },
 * });
 */
export function useGetItineraryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetItineraryQuery, GetItineraryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetItineraryQuery, GetItineraryQueryVariables>(
    GetItineraryDocument,
    options,
  )
}
export function useGetItineraryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetItineraryQuery,
    GetItineraryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetItineraryQuery, GetItineraryQueryVariables>(
    GetItineraryDocument,
    options,
  )
}
export type GetItineraryQueryHookResult = ReturnType<typeof useGetItineraryQuery>
export type GetItineraryLazyQueryHookResult = ReturnType<typeof useGetItineraryLazyQuery>
export type GetItineraryQueryResult = ApolloReactCommon.QueryResult<
  GetItineraryQuery,
  GetItineraryQueryVariables
>
export const ReactivateItineraryDocument = gql`
  mutation reactivateItinerary($input: ReactivateItineraryInput!) {
    reactivateItinerary(input: $input) {
      success
      message
      code
    }
  }
`
export type ReactivateItineraryMutationFn = ApolloReactCommon.MutationFunction<
  ReactivateItineraryMutation,
  ReactivateItineraryMutationVariables
>

/**
 * __useReactivateItineraryMutation__
 *
 * To run a mutation, you first call `useReactivateItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateItineraryMutation, { data, loading, error }] = useReactivateItineraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivateItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReactivateItineraryMutation,
    ReactivateItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ReactivateItineraryMutation,
    ReactivateItineraryMutationVariables
  >(ReactivateItineraryDocument, options)
}
export type ReactivateItineraryMutationHookResult = ReturnType<
  typeof useReactivateItineraryMutation
>
export type ReactivateItineraryMutationResult = ApolloReactCommon.MutationResult<ReactivateItineraryMutation>
export type ReactivateItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReactivateItineraryMutation,
  ReactivateItineraryMutationVariables
>
export const RemoveCarRentalDocument = gql`
  mutation removeCarRental($itineraryId: String!, $carId: String!) {
    removeCarRental(input: { carId: $carId, itineraryId: $itineraryId }) {
      success
      message
      code
    }
  }
`
export type RemoveCarRentalMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCarRentalMutation,
  RemoveCarRentalMutationVariables
>

/**
 * __useRemoveCarRentalMutation__
 *
 * To run a mutation, you first call `useRemoveCarRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCarRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCarRentalMutation, { data, loading, error }] = useRemoveCarRentalMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      carId: // value for 'carId'
 *   },
 * });
 */
export function useRemoveCarRentalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCarRentalMutation,
    RemoveCarRentalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveCarRentalMutation, RemoveCarRentalMutationVariables>(
    RemoveCarRentalDocument,
    options,
  )
}
export type RemoveCarRentalMutationHookResult = ReturnType<typeof useRemoveCarRentalMutation>
export type RemoveCarRentalMutationResult = ApolloReactCommon.MutationResult<RemoveCarRentalMutation>
export type RemoveCarRentalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCarRentalMutation,
  RemoveCarRentalMutationVariables
>
export const RemoveFlightSegmentDocument = gql`
  mutation removeFlightSegment($itineraryId: String!, $flightId: String!) {
    removeFlight(input: { flightId: $flightId, itineraryId: $itineraryId }) {
      success
      message
      code
    }
  }
`
export type RemoveFlightSegmentMutationFn = ApolloReactCommon.MutationFunction<
  RemoveFlightSegmentMutation,
  RemoveFlightSegmentMutationVariables
>

/**
 * __useRemoveFlightSegmentMutation__
 *
 * To run a mutation, you first call `useRemoveFlightSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFlightSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFlightSegmentMutation, { data, loading, error }] = useRemoveFlightSegmentMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      flightId: // value for 'flightId'
 *   },
 * });
 */
export function useRemoveFlightSegmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveFlightSegmentMutation,
    RemoveFlightSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RemoveFlightSegmentMutation,
    RemoveFlightSegmentMutationVariables
  >(RemoveFlightSegmentDocument, options)
}
export type RemoveFlightSegmentMutationHookResult = ReturnType<
  typeof useRemoveFlightSegmentMutation
>
export type RemoveFlightSegmentMutationResult = ApolloReactCommon.MutationResult<RemoveFlightSegmentMutation>
export type RemoveFlightSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveFlightSegmentMutation,
  RemoveFlightSegmentMutationVariables
>
export const RemoveItineraryDocument = gql`
  mutation removeItinerary($itineraryId: String!) {
    removeItinerary(itineraryId: $itineraryId) {
      code
      success
      message
    }
  }
`
export type RemoveItineraryMutationFn = ApolloReactCommon.MutationFunction<
  RemoveItineraryMutation,
  RemoveItineraryMutationVariables
>

/**
 * __useRemoveItineraryMutation__
 *
 * To run a mutation, you first call `useRemoveItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItineraryMutation, { data, loading, error }] = useRemoveItineraryMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *   },
 * });
 */
export function useRemoveItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveItineraryMutation,
    RemoveItineraryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveItineraryMutation, RemoveItineraryMutationVariables>(
    RemoveItineraryDocument,
    options,
  )
}
export type RemoveItineraryMutationHookResult = ReturnType<typeof useRemoveItineraryMutation>
export type RemoveItineraryMutationResult = ApolloReactCommon.MutationResult<RemoveItineraryMutation>
export type RemoveItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveItineraryMutation,
  RemoveItineraryMutationVariables
>
export const RemoveRailSegmentDocument = gql`
  mutation removeRailSegment($itineraryId: String!, $railId: String!) {
    removeRail(input: { itineraryId: $itineraryId, railId: $railId }) {
      success
      message
      code
    }
  }
`
export type RemoveRailSegmentMutationFn = ApolloReactCommon.MutationFunction<
  RemoveRailSegmentMutation,
  RemoveRailSegmentMutationVariables
>

/**
 * __useRemoveRailSegmentMutation__
 *
 * To run a mutation, you first call `useRemoveRailSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRailSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRailSegmentMutation, { data, loading, error }] = useRemoveRailSegmentMutation({
 *   variables: {
 *      itineraryId: // value for 'itineraryId'
 *      railId: // value for 'railId'
 *   },
 * });
 */
export function useRemoveRailSegmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRailSegmentMutation,
    RemoveRailSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RemoveRailSegmentMutation,
    RemoveRailSegmentMutationVariables
  >(RemoveRailSegmentDocument, options)
}
export type RemoveRailSegmentMutationHookResult = ReturnType<typeof useRemoveRailSegmentMutation>
export type RemoveRailSegmentMutationResult = ApolloReactCommon.MutationResult<RemoveRailSegmentMutation>
export type RemoveRailSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveRailSegmentMutation,
  RemoveRailSegmentMutationVariables
>
export const RemoveRoomDocument = gql`
  mutation RemoveRoom($roomKey: String!, $itineraryId: String!) {
    removeRoom(roomKey: $roomKey, itineraryId: $itineraryId) {
      code
      success
      message
    }
  }
`
export type RemoveRoomMutationFn = ApolloReactCommon.MutationFunction<
  RemoveRoomMutation,
  RemoveRoomMutationVariables
>

/**
 * __useRemoveRoomMutation__
 *
 * To run a mutation, you first call `useRemoveRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoomMutation, { data, loading, error }] = useRemoveRoomMutation({
 *   variables: {
 *      roomKey: // value for 'roomKey'
 *      itineraryId: // value for 'itineraryId'
 *   },
 * });
 */
export function useRemoveRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRoomMutation,
    RemoveRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveRoomMutation, RemoveRoomMutationVariables>(
    RemoveRoomDocument,
    options,
  )
}
export type RemoveRoomMutationHookResult = ReturnType<typeof useRemoveRoomMutation>
export type RemoveRoomMutationResult = ApolloReactCommon.MutationResult<RemoveRoomMutation>
export type RemoveRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveRoomMutation,
  RemoveRoomMutationVariables
>
export const ReplaceCarRentalDocument = gql`
  mutation replaceCarRental($input: ReplaceCarRentalInput!) {
    replaceCarRental(input: $input) {
      success
      message
      code
    }
  }
`
export type ReplaceCarRentalMutationFn = ApolloReactCommon.MutationFunction<
  ReplaceCarRentalMutation,
  ReplaceCarRentalMutationVariables
>

/**
 * __useReplaceCarRentalMutation__
 *
 * To run a mutation, you first call `useReplaceCarRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceCarRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceCarRentalMutation, { data, loading, error }] = useReplaceCarRentalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceCarRentalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReplaceCarRentalMutation,
    ReplaceCarRentalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ReplaceCarRentalMutation, ReplaceCarRentalMutationVariables>(
    ReplaceCarRentalDocument,
    options,
  )
}
export type ReplaceCarRentalMutationHookResult = ReturnType<typeof useReplaceCarRentalMutation>
export type ReplaceCarRentalMutationResult = ApolloReactCommon.MutationResult<ReplaceCarRentalMutation>
export type ReplaceCarRentalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReplaceCarRentalMutation,
  ReplaceCarRentalMutationVariables
>
export const ReplaceRoomDocument = gql`
  mutation ReplaceRoom(
    $hotelId: String!
    $hotelResultId: String
    $checkIn: String!
    $checkOut: String!
    $latitude: Float
    $longitude: Float
    $oldRoomKey: String!
    $newRoomKey: String!
    $itineraryId: String!
  ) {
    replaceRoom(
      hotelId: $hotelId
      hotelResultId: $hotelResultId
      checkIn: $checkIn
      checkOut: $checkOut
      latitude: $latitude
      longitude: $longitude
      oldRoomKey: $oldRoomKey
      newRoomKey: $newRoomKey
      itineraryId: $itineraryId
    ) {
      code
      success
      message
    }
  }
`
export type ReplaceRoomMutationFn = ApolloReactCommon.MutationFunction<
  ReplaceRoomMutation,
  ReplaceRoomMutationVariables
>

/**
 * __useReplaceRoomMutation__
 *
 * To run a mutation, you first call `useReplaceRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceRoomMutation, { data, loading, error }] = useReplaceRoomMutation({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      hotelResultId: // value for 'hotelResultId'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      oldRoomKey: // value for 'oldRoomKey'
 *      newRoomKey: // value for 'newRoomKey'
 *      itineraryId: // value for 'itineraryId'
 *   },
 * });
 */
export function useReplaceRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReplaceRoomMutation,
    ReplaceRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ReplaceRoomMutation, ReplaceRoomMutationVariables>(
    ReplaceRoomDocument,
    options,
  )
}
export type ReplaceRoomMutationHookResult = ReturnType<typeof useReplaceRoomMutation>
export type ReplaceRoomMutationResult = ApolloReactCommon.MutationResult<ReplaceRoomMutation>
export type ReplaceRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReplaceRoomMutation,
  ReplaceRoomMutationVariables
>
export const SelectRoomDocument = gql`
  mutation SelectRoom(
    $roomKey: String!
    $itineraryId: String
    $hotelId: String!
    $hotelResultId: String
    $checkIn: String!
    $checkOut: String!
    $latitude: Float
    $longitude: Float
  ) {
    selectRoom(
      roomKey: $roomKey
      itineraryId: $itineraryId
      hotelId: $hotelId
      hotelResultId: $hotelResultId
      checkIn: $checkIn
      checkOut: $checkOut
      latitude: $latitude
      longitude: $longitude
    ) {
      code
      success
      message
    }
  }
`
export type SelectRoomMutationFn = ApolloReactCommon.MutationFunction<
  SelectRoomMutation,
  SelectRoomMutationVariables
>

/**
 * __useSelectRoomMutation__
 *
 * To run a mutation, you first call `useSelectRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectRoomMutation, { data, loading, error }] = useSelectRoomMutation({
 *   variables: {
 *      roomKey: // value for 'roomKey'
 *      itineraryId: // value for 'itineraryId'
 *      hotelId: // value for 'hotelId'
 *      hotelResultId: // value for 'hotelResultId'
 *      checkIn: // value for 'checkIn'
 *      checkOut: // value for 'checkOut'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useSelectRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SelectRoomMutation,
    SelectRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<SelectRoomMutation, SelectRoomMutationVariables>(
    SelectRoomDocument,
    options,
  )
}
export type SelectRoomMutationHookResult = ReturnType<typeof useSelectRoomMutation>
export type SelectRoomMutationResult = ApolloReactCommon.MutationResult<SelectRoomMutation>
export type SelectRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SelectRoomMutation,
  SelectRoomMutationVariables
>
export const UpdateTripNameDocument = gql`
  mutation updateTripName($input: UpdateTripNameInput!) {
    updateTripName(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateTripNameMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTripNameMutation,
  UpdateTripNameMutationVariables
>

/**
 * __useUpdateTripNameMutation__
 *
 * To run a mutation, you first call `useUpdateTripNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTripNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTripNameMutation, { data, loading, error }] = useUpdateTripNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTripNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTripNameMutation,
    UpdateTripNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateTripNameMutation, UpdateTripNameMutationVariables>(
    UpdateTripNameDocument,
    options,
  )
}
export type UpdateTripNameMutationHookResult = ReturnType<typeof useUpdateTripNameMutation>
export type UpdateTripNameMutationResult = ApolloReactCommon.MutationResult<UpdateTripNameMutation>
export type UpdateTripNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTripNameMutation,
  UpdateTripNameMutationVariables
>
export const GetOutOfThePolicyConfigurationDocument = gql`
  query getOutOfThePolicyConfiguration($segments: [String]!) {
    outOfPolicyReason(segments: $segments) {
      reason
      segment
      id
      codeForBooking
    }
  }
`

/**
 * __useGetOutOfThePolicyConfigurationQuery__
 *
 * To run a query within a React component, call `useGetOutOfThePolicyConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutOfThePolicyConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutOfThePolicyConfigurationQuery({
 *   variables: {
 *      segments: // value for 'segments'
 *   },
 * });
 */
export function useGetOutOfThePolicyConfigurationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOutOfThePolicyConfigurationQuery,
    GetOutOfThePolicyConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetOutOfThePolicyConfigurationQuery,
    GetOutOfThePolicyConfigurationQueryVariables
  >(GetOutOfThePolicyConfigurationDocument, options)
}
export function useGetOutOfThePolicyConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOutOfThePolicyConfigurationQuery,
    GetOutOfThePolicyConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetOutOfThePolicyConfigurationQuery,
    GetOutOfThePolicyConfigurationQueryVariables
  >(GetOutOfThePolicyConfigurationDocument, options)
}
export type GetOutOfThePolicyConfigurationQueryHookResult = ReturnType<
  typeof useGetOutOfThePolicyConfigurationQuery
>
export type GetOutOfThePolicyConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetOutOfThePolicyConfigurationLazyQuery
>
export type GetOutOfThePolicyConfigurationQueryResult = ApolloReactCommon.QueryResult<
  GetOutOfThePolicyConfigurationQuery,
  GetOutOfThePolicyConfigurationQueryVariables
>
export const SendOopCodesDocument = gql`
  mutation sendOopCodes($input: SendOopCodesInput!) {
    sendOopCodes(input: $input) {
      success
      message
      code
    }
  }
`
export type SendOopCodesMutationFn = ApolloReactCommon.MutationFunction<
  SendOopCodesMutation,
  SendOopCodesMutationVariables
>

/**
 * __useSendOopCodesMutation__
 *
 * To run a mutation, you first call `useSendOopCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOopCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOopCodesMutation, { data, loading, error }] = useSendOopCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOopCodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendOopCodesMutation,
    SendOopCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<SendOopCodesMutation, SendOopCodesMutationVariables>(
    SendOopCodesDocument,
    options,
  )
}
export type SendOopCodesMutationHookResult = ReturnType<typeof useSendOopCodesMutation>
export type SendOopCodesMutationResult = ApolloReactCommon.MutationResult<SendOopCodesMutation>
export type SendOopCodesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendOopCodesMutation,
  SendOopCodesMutationVariables
>
export const CreateCustomerUberAccountDocument = gql`
  mutation createCustomerUberAccount {
    createCustomerUberAccount {
      __typename
      ... on CreateCustomerUberAccountResponse {
        linkingUrl
      }
      ... on CreateCustomerUberAccountErrorResponse {
        reason
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`
export type CreateCustomerUberAccountMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerUberAccountMutation,
  CreateCustomerUberAccountMutationVariables
>

/**
 * __useCreateCustomerUberAccountMutation__
 *
 * To run a mutation, you first call `useCreateCustomerUberAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerUberAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerUberAccountMutation, { data, loading, error }] = useCreateCustomerUberAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCustomerUberAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerUberAccountMutation,
    CreateCustomerUberAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    CreateCustomerUberAccountMutation,
    CreateCustomerUberAccountMutationVariables
  >(CreateCustomerUberAccountDocument, options)
}
export type CreateCustomerUberAccountMutationHookResult = ReturnType<
  typeof useCreateCustomerUberAccountMutation
>
export type CreateCustomerUberAccountMutationResult = ApolloReactCommon.MutationResult<CreateCustomerUberAccountMutation>
export type CreateCustomerUberAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerUberAccountMutation,
  CreateCustomerUberAccountMutationVariables
>
export const GetSearchRideHailWithErrorDocument = gql`
  query getSearchRideHailWithError($input: SearchRideHailRequest!) {
    searchRideHailWithError(input: $input) {
      __typename
      ... on SearchRideHailResponse {
        recommendedResults {
          provider
          id
          displayName
          shortDescription
          estimatedPrice {
            amount
            currency
          }
          imageUrl
          estimatedWaitingTime
          estimatedRideDuration
          passengerCapacity
        }
        results {
          provider
          id
          displayName
          shortDescription
          estimatedPrice {
            amount
            currency
          }
          imageUrl
          estimatedWaitingTime
          estimatedRideDuration
          passengerCapacity
        }
      }
      ... on SearchRideHailErrorResponse {
        reason
      }
    }
  }
`

/**
 * __useGetSearchRideHailWithErrorQuery__
 *
 * To run a query within a React component, call `useGetSearchRideHailWithErrorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRideHailWithErrorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRideHailWithErrorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchRideHailWithErrorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSearchRideHailWithErrorQuery,
    GetSearchRideHailWithErrorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetSearchRideHailWithErrorQuery,
    GetSearchRideHailWithErrorQueryVariables
  >(GetSearchRideHailWithErrorDocument, options)
}
export function useGetSearchRideHailWithErrorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSearchRideHailWithErrorQuery,
    GetSearchRideHailWithErrorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSearchRideHailWithErrorQuery,
    GetSearchRideHailWithErrorQueryVariables
  >(GetSearchRideHailWithErrorDocument, options)
}
export type GetSearchRideHailWithErrorQueryHookResult = ReturnType<
  typeof useGetSearchRideHailWithErrorQuery
>
export type GetSearchRideHailWithErrorLazyQueryHookResult = ReturnType<
  typeof useGetSearchRideHailWithErrorLazyQuery
>
export type GetSearchRideHailWithErrorQueryResult = ApolloReactCommon.QueryResult<
  GetSearchRideHailWithErrorQuery,
  GetSearchRideHailWithErrorQueryVariables
>
export const RideHailUpcomingFlightsDocument = gql`
  query rideHailUpcomingFlights {
    rideHailUpcomingFlights {
      __typename
      ... on RideHailUpcomingFlightsResponse {
        rideHailUpcomingFlights {
          airportCode
          designatorCode
          arrivalDate
          arrivalTime
          airlineName
          airlineImage
        }
      }
      ... on RideHailUpcomingFlightsErrorResponse {
        reason
      }
    }
  }
`

/**
 * __useRideHailUpcomingFlightsQuery__
 *
 * To run a query within a React component, call `useRideHailUpcomingFlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideHailUpcomingFlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideHailUpcomingFlightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRideHailUpcomingFlightsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RideHailUpcomingFlightsQuery,
    RideHailUpcomingFlightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    RideHailUpcomingFlightsQuery,
    RideHailUpcomingFlightsQueryVariables
  >(RideHailUpcomingFlightsDocument, options)
}
export function useRideHailUpcomingFlightsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RideHailUpcomingFlightsQuery,
    RideHailUpcomingFlightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    RideHailUpcomingFlightsQuery,
    RideHailUpcomingFlightsQueryVariables
  >(RideHailUpcomingFlightsDocument, options)
}
export type RideHailUpcomingFlightsQueryHookResult = ReturnType<
  typeof useRideHailUpcomingFlightsQuery
>
export type RideHailUpcomingFlightsLazyQueryHookResult = ReturnType<
  typeof useRideHailUpcomingFlightsLazyQuery
>
export type RideHailUpcomingFlightsQueryResult = ApolloReactCommon.QueryResult<
  RideHailUpcomingFlightsQuery,
  RideHailUpcomingFlightsQueryVariables
>
export const RideHailExpenseConfigDocument = gql`
  query rideHailExpenseConfig($input: RideHailExpenseConfigInput!) {
    rideHailExpenseConfig(input: $input) {
      __typename
      ... on RideHailExpenseConfigResponse {
        expenseEnabled
        customExpenseEnabled
        memoEnabled
        memoRequired
        expenseCodes {
          id
          description
        }
      }
      ... on RideHailExpenseConfigErrorResponse {
        reason
      }
    }
  }
`

/**
 * __useRideHailExpenseConfigQuery__
 *
 * To run a query within a React component, call `useRideHailExpenseConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideHailExpenseConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideHailExpenseConfigQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRideHailExpenseConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RideHailExpenseConfigQuery,
    RideHailExpenseConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<RideHailExpenseConfigQuery, RideHailExpenseConfigQueryVariables>(
    RideHailExpenseConfigDocument,
    options,
  )
}
export function useRideHailExpenseConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RideHailExpenseConfigQuery,
    RideHailExpenseConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    RideHailExpenseConfigQuery,
    RideHailExpenseConfigQueryVariables
  >(RideHailExpenseConfigDocument, options)
}
export type RideHailExpenseConfigQueryHookResult = ReturnType<typeof useRideHailExpenseConfigQuery>
export type RideHailExpenseConfigLazyQueryHookResult = ReturnType<
  typeof useRideHailExpenseConfigLazyQuery
>
export type RideHailExpenseConfigQueryResult = ApolloReactCommon.QueryResult<
  RideHailExpenseConfigQuery,
  RideHailExpenseConfigQueryVariables
>
export const GetCarRulesByTransactionIdDocument = gql`
  query getCarRulesByTransactionId($input: GetCarRentalRulesByTransactionIdInput!) {
    carRulesByTransactionId(input: $input) {
      header
      value
    }
  }
`

/**
 * __useGetCarRulesByTransactionIdQuery__
 *
 * To run a query within a React component, call `useGetCarRulesByTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarRulesByTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarRulesByTransactionIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarRulesByTransactionIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCarRulesByTransactionIdQuery,
    GetCarRulesByTransactionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetCarRulesByTransactionIdQuery,
    GetCarRulesByTransactionIdQueryVariables
  >(GetCarRulesByTransactionIdDocument, options)
}
export function useGetCarRulesByTransactionIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCarRulesByTransactionIdQuery,
    GetCarRulesByTransactionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetCarRulesByTransactionIdQuery,
    GetCarRulesByTransactionIdQueryVariables
  >(GetCarRulesByTransactionIdDocument, options)
}
export type GetCarRulesByTransactionIdQueryHookResult = ReturnType<
  typeof useGetCarRulesByTransactionIdQuery
>
export type GetCarRulesByTransactionIdLazyQueryHookResult = ReturnType<
  typeof useGetCarRulesByTransactionIdLazyQuery
>
export type GetCarRulesByTransactionIdQueryResult = ApolloReactCommon.QueryResult<
  GetCarRulesByTransactionIdQuery,
  GetCarRulesByTransactionIdQueryVariables
>
export const GetFlightFareRulesByTransactionIdDocument = gql`
  query getFlightFareRulesByTransactionId($input: FlightFareRulesByTransactionIdInput!) {
    flightFareRulesByTransactionId(input: $input) {
      rules {
        header
        value
      }
    }
  }
`

/**
 * __useGetFlightFareRulesByTransactionIdQuery__
 *
 * To run a query within a React component, call `useGetFlightFareRulesByTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightFareRulesByTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightFareRulesByTransactionIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightFareRulesByTransactionIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightFareRulesByTransactionIdQuery,
    GetFlightFareRulesByTransactionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetFlightFareRulesByTransactionIdQuery,
    GetFlightFareRulesByTransactionIdQueryVariables
  >(GetFlightFareRulesByTransactionIdDocument, options)
}
export function useGetFlightFareRulesByTransactionIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightFareRulesByTransactionIdQuery,
    GetFlightFareRulesByTransactionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetFlightFareRulesByTransactionIdQuery,
    GetFlightFareRulesByTransactionIdQueryVariables
  >(GetFlightFareRulesByTransactionIdDocument, options)
}
export type GetFlightFareRulesByTransactionIdQueryHookResult = ReturnType<
  typeof useGetFlightFareRulesByTransactionIdQuery
>
export type GetFlightFareRulesByTransactionIdLazyQueryHookResult = ReturnType<
  typeof useGetFlightFareRulesByTransactionIdLazyQuery
>
export type GetFlightFareRulesByTransactionIdQueryResult = ApolloReactCommon.QueryResult<
  GetFlightFareRulesByTransactionIdQuery,
  GetFlightFareRulesByTransactionIdQueryVariables
>
export const GetAirEnhancedSeatMapDocument = gql`
  query getAirEnhancedSeatMap($input: EnhancedSeatMapInput!) {
    enhancedSeatMap(input: $input) {
      __typename
      ... on EnhancedSeatMap {
        cabins {
          cabinClass
          rows {
            number
            seat {
              number
              column
              available
              selectable
              price {
                rate {
                  primary {
                    amount
                    currency
                  }
                  secondary {
                    amount
                    currency
                  }
                }
                name
                id
                passengers
              }
              type
              isOverWing
              isNearWindow
              isPaid
              isNearAisle
              isPremium
              isPreferred
              isExitRowSeat
              isFrequentFlyerEntitled
              hasBulkhead
              isUpperDeck
              hasGalley
              hasLavatory
              isSmokingAllowed
              isSuitableForHandicapped
            }
          }
          sections {
            first
            last
          }
          overWingRows {
            first
            last
          }
        }
        carrier {
          id
          name
          imageUrl
          logoImageUrl
          logoRectangleImageUrl
        }
        departureDate
        destination
        flightNumber
        origin
        readOnly
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`

/**
 * __useGetAirEnhancedSeatMapQuery__
 *
 * To run a query within a React component, call `useGetAirEnhancedSeatMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirEnhancedSeatMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirEnhancedSeatMapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAirEnhancedSeatMapQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAirEnhancedSeatMapQuery,
    GetAirEnhancedSeatMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAirEnhancedSeatMapQuery, GetAirEnhancedSeatMapQueryVariables>(
    GetAirEnhancedSeatMapDocument,
    options,
  )
}
export function useGetAirEnhancedSeatMapLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAirEnhancedSeatMapQuery,
    GetAirEnhancedSeatMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetAirEnhancedSeatMapQuery,
    GetAirEnhancedSeatMapQueryVariables
  >(GetAirEnhancedSeatMapDocument, options)
}
export type GetAirEnhancedSeatMapQueryHookResult = ReturnType<typeof useGetAirEnhancedSeatMapQuery>
export type GetAirEnhancedSeatMapLazyQueryHookResult = ReturnType<
  typeof useGetAirEnhancedSeatMapLazyQuery
>
export type GetAirEnhancedSeatMapQueryResult = ApolloReactCommon.QueryResult<
  GetAirEnhancedSeatMapQuery,
  GetAirEnhancedSeatMapQueryVariables
>
export const AddPaymentCardDocument = gql`
  mutation addPaymentCard($input: AddPaymentCardInput!) {
    addPaymentCard(input: $input) {
      __typename
      ... on PaymentCard {
        cardId
        legacyId
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type AddPaymentCardMutationFn = ApolloReactCommon.MutationFunction<
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables
>

/**
 * __useAddPaymentCardMutation__
 *
 * To run a mutation, you first call `useAddPaymentCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentCardMutation, { data, loading, error }] = useAddPaymentCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPaymentCardMutation,
    AddPaymentCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddPaymentCardMutation, AddPaymentCardMutationVariables>(
    AddPaymentCardDocument,
    options,
  )
}
export type AddPaymentCardMutationHookResult = ReturnType<typeof useAddPaymentCardMutation>
export type AddPaymentCardMutationResult = ApolloReactCommon.MutationResult<AddPaymentCardMutation>
export type AddPaymentCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables
>
export const ChangePasswordDocument = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
        errorCodes
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options,
  )
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const DeleteCreditCardDocument = gql`
  mutation deleteCreditCard($input: DeleteCreditCardInput!) {
    deleteCreditCard(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
    }
  }
`
export type DeleteCreditCardMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCreditCardMutation,
  DeleteCreditCardMutationVariables
>

/**
 * __useDeleteCreditCardMutation__
 *
 * To run a mutation, you first call `useDeleteCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditCardMutation, { data, loading, error }] = useDeleteCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCreditCardMutation,
    DeleteCreditCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>(
    DeleteCreditCardDocument,
    options,
  )
}
export type DeleteCreditCardMutationHookResult = ReturnType<typeof useDeleteCreditCardMutation>
export type DeleteCreditCardMutationResult = ApolloReactCommon.MutationResult<DeleteCreditCardMutation>
export type DeleteCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCreditCardMutation,
  DeleteCreditCardMutationVariables
>
export const GetAvailableDelegatesDocument = gql`
  query getAvailableDelegates($query: String!) {
    getAvailableDelegates(query: $query) {
      availableDelegates {
        firstName
        lastName
        email
        isDelegate
        id
      }
    }
  }
`

/**
 * __useGetAvailableDelegatesQuery__
 *
 * To run a query within a React component, call `useGetAvailableDelegatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDelegatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDelegatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetAvailableDelegatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAvailableDelegatesQuery,
    GetAvailableDelegatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAvailableDelegatesQuery, GetAvailableDelegatesQueryVariables>(
    GetAvailableDelegatesDocument,
    options,
  )
}
export function useGetAvailableDelegatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableDelegatesQuery,
    GetAvailableDelegatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetAvailableDelegatesQuery,
    GetAvailableDelegatesQueryVariables
  >(GetAvailableDelegatesDocument, options)
}
export type GetAvailableDelegatesQueryHookResult = ReturnType<typeof useGetAvailableDelegatesQuery>
export type GetAvailableDelegatesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableDelegatesLazyQuery
>
export type GetAvailableDelegatesQueryResult = ApolloReactCommon.QueryResult<
  GetAvailableDelegatesQuery,
  GetAvailableDelegatesQueryVariables
>
export const GetAvailableSpecialRequestsDocument = gql`
  query getAvailableSpecialRequests($input: SpecialRequestsInput!) {
    availableSpecialRequests(input: $input) {
      airSpecialRequests {
        label
        value
      }
      hotelSpecialRequests {
        label
        value
      }
      carRentalSpecialRequests {
        label
        value
      }
    }
  }
`

/**
 * __useGetAvailableSpecialRequestsQuery__
 *
 * To run a query within a React component, call `useGetAvailableSpecialRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableSpecialRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableSpecialRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAvailableSpecialRequestsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAvailableSpecialRequestsQuery,
    GetAvailableSpecialRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetAvailableSpecialRequestsQuery,
    GetAvailableSpecialRequestsQueryVariables
  >(GetAvailableSpecialRequestsDocument, options)
}
export function useGetAvailableSpecialRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableSpecialRequestsQuery,
    GetAvailableSpecialRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetAvailableSpecialRequestsQuery,
    GetAvailableSpecialRequestsQueryVariables
  >(GetAvailableSpecialRequestsDocument, options)
}
export type GetAvailableSpecialRequestsQueryHookResult = ReturnType<
  typeof useGetAvailableSpecialRequestsQuery
>
export type GetAvailableSpecialRequestsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableSpecialRequestsLazyQuery
>
export type GetAvailableSpecialRequestsQueryResult = ApolloReactCommon.QueryResult<
  GetAvailableSpecialRequestsQuery,
  GetAvailableSpecialRequestsQueryVariables
>
export const GetAvailableVendorsDocument = gql`
  query getAvailableVendors($input: AvailableVendorsInput!) {
    availableVendors(input: $input) {
      availableAirVendors {
        label
        value
      }
      availableHotelVendors {
        label
        value
      }
      availableCarRentalVendors {
        label
        value
      }
    }
  }
`

/**
 * __useGetAvailableVendorsQuery__
 *
 * To run a query within a React component, call `useGetAvailableVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableVendorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAvailableVendorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAvailableVendorsQuery,
    GetAvailableVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetAvailableVendorsQuery, GetAvailableVendorsQueryVariables>(
    GetAvailableVendorsDocument,
    options,
  )
}
export function useGetAvailableVendorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableVendorsQuery,
    GetAvailableVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetAvailableVendorsQuery, GetAvailableVendorsQueryVariables>(
    GetAvailableVendorsDocument,
    options,
  )
}
export type GetAvailableVendorsQueryHookResult = ReturnType<typeof useGetAvailableVendorsQuery>
export type GetAvailableVendorsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableVendorsLazyQuery
>
export type GetAvailableVendorsQueryResult = ApolloReactCommon.QueryResult<
  GetAvailableVendorsQuery,
  GetAvailableVendorsQueryVariables
>
export const GetCreditCardsDocument = gql`
  query getCreditCards($isCCNumberMasked: Boolean) {
    creditCards(isCCNumberMasked: $isCCNumberMasked) {
      name
      label
      cardNumberLast4
      expirationDate
      legacyId
      fullLegacyId
      brandType
      cardNumber
      securityCode
      billingAddress {
        street1
        street2
        countryCode
        stateCode
        postalCode
        city
      }
    }
  }
`

/**
 * __useGetCreditCardsQuery__
 *
 * To run a query within a React component, call `useGetCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditCardsQuery({
 *   variables: {
 *      isCCNumberMasked: // value for 'isCCNumberMasked'
 *   },
 * });
 */
export function useGetCreditCardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCreditCardsQuery,
    GetCreditCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCreditCardsQuery, GetCreditCardsQueryVariables>(
    GetCreditCardsDocument,
    options,
  )
}
export function useGetCreditCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCreditCardsQuery,
    GetCreditCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCreditCardsQuery, GetCreditCardsQueryVariables>(
    GetCreditCardsDocument,
    options,
  )
}
export type GetCreditCardsQueryHookResult = ReturnType<typeof useGetCreditCardsQuery>
export type GetCreditCardsLazyQueryHookResult = ReturnType<typeof useGetCreditCardsLazyQuery>
export type GetCreditCardsQueryResult = ApolloReactCommon.QueryResult<
  GetCreditCardsQuery,
  GetCreditCardsQueryVariables
>
export const GetCurrenciesDocument = gql`
  query getCurrencies {
    getCurrencies {
      currencies {
        code
        value
      }
    }
  }
`

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(
    GetCurrenciesDocument,
    options,
  )
}
export function useGetCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrenciesQuery,
    GetCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(
    GetCurrenciesDocument,
    options,
  )
}
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>
export type GetCurrenciesQueryResult = ApolloReactCommon.QueryResult<
  GetCurrenciesQuery,
  GetCurrenciesQueryVariables
>
export const GetLanguagesDocument = gql`
  query getLanguages {
    getLanguages {
      languages {
        code
        value
      }
    }
  }
`

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    options,
  )
}
export function useGetLanguagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLanguagesQuery,
    GetLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(
    GetLanguagesDocument,
    options,
  )
}
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>
export type GetLanguagesQueryResult = ApolloReactCommon.QueryResult<
  GetLanguagesQuery,
  GetLanguagesQueryVariables
>
export const GetProfileFieldsSettingsDocument = gql`
  query getProfileFieldsSettings {
    profileFieldsSettings {
      fields {
        isRequired
        isEditable
        labelToDisplay
        labelDefault
        profileMetadataId
        categoryId
        shouldDisplayInSettings
        shouldDisplayInRegistration
        fieldMetadata {
          fieldType
          minLength
          maxLength
          allowCharacters
          sortAlphabetically
          defaultListValue
          defaultValue
          options {
            name
            value
          }
        }
      }
    }
  }
`

/**
 * __useGetProfileFieldsSettingsQuery__
 *
 * To run a query within a React component, call `useGetProfileFieldsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFieldsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFieldsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileFieldsSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProfileFieldsSettingsQuery,
    GetProfileFieldsSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetProfileFieldsSettingsQuery,
    GetProfileFieldsSettingsQueryVariables
  >(GetProfileFieldsSettingsDocument, options)
}
export function useGetProfileFieldsSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProfileFieldsSettingsQuery,
    GetProfileFieldsSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetProfileFieldsSettingsQuery,
    GetProfileFieldsSettingsQueryVariables
  >(GetProfileFieldsSettingsDocument, options)
}
export type GetProfileFieldsSettingsQueryHookResult = ReturnType<
  typeof useGetProfileFieldsSettingsQuery
>
export type GetProfileFieldsSettingsLazyQueryHookResult = ReturnType<
  typeof useGetProfileFieldsSettingsLazyQuery
>
export type GetProfileFieldsSettingsQueryResult = ApolloReactCommon.QueryResult<
  GetProfileFieldsSettingsQuery,
  GetProfileFieldsSettingsQueryVariables
>
export const GetSiteCardsDocument = gql`
  query getSiteCards {
    siteCards {
      cards {
        id
        isRestricted
        isActive
        isEnabledForAir
        isEnabledForHotel
        isEnabledForCarRental
        isEnabledForCarService
        isRequiredForAir
        isRequiredForHotel
        card {
          brandType
        }
      }
    }
  }
`

/**
 * __useGetSiteCardsQuery__
 *
 * To run a query within a React component, call `useGetSiteCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteCardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteCardsQuery, GetSiteCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSiteCardsQuery, GetSiteCardsQueryVariables>(
    GetSiteCardsDocument,
    options,
  )
}
export function useGetSiteCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSiteCardsQuery,
    GetSiteCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetSiteCardsQuery, GetSiteCardsQueryVariables>(
    GetSiteCardsDocument,
    options,
  )
}
export type GetSiteCardsQueryHookResult = ReturnType<typeof useGetSiteCardsQuery>
export type GetSiteCardsLazyQueryHookResult = ReturnType<typeof useGetSiteCardsLazyQuery>
export type GetSiteCardsQueryResult = ApolloReactCommon.QueryResult<
  GetSiteCardsQuery,
  GetSiteCardsQueryVariables
>
export const GetSuggestedHomeAirportsDocument = gql`
  query getSuggestedHomeAirports($input: SuggestedHomeAirportsInput!) {
    suggestedHomeAirports(input: $input) {
      airportCode
      airportName
      name
      shortName
      address {
        city
        countryName
        stateCode
      }
    }
  }
`

/**
 * __useGetSuggestedHomeAirportsQuery__
 *
 * To run a query within a React component, call `useGetSuggestedHomeAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedHomeAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedHomeAirportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSuggestedHomeAirportsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSuggestedHomeAirportsQuery,
    GetSuggestedHomeAirportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetSuggestedHomeAirportsQuery,
    GetSuggestedHomeAirportsQueryVariables
  >(GetSuggestedHomeAirportsDocument, options)
}
export function useGetSuggestedHomeAirportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSuggestedHomeAirportsQuery,
    GetSuggestedHomeAirportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetSuggestedHomeAirportsQuery,
    GetSuggestedHomeAirportsQueryVariables
  >(GetSuggestedHomeAirportsDocument, options)
}
export type GetSuggestedHomeAirportsQueryHookResult = ReturnType<
  typeof useGetSuggestedHomeAirportsQuery
>
export type GetSuggestedHomeAirportsLazyQueryHookResult = ReturnType<
  typeof useGetSuggestedHomeAirportsLazyQuery
>
export type GetSuggestedHomeAirportsQueryResult = ApolloReactCommon.QueryResult<
  GetSuggestedHomeAirportsQuery,
  GetSuggestedHomeAirportsQueryVariables
>
export const GetTimeZonesDocument = gql`
  query getTimeZones {
    getTimeZones {
      timeZones {
        code
        value
      }
    }
  }
`

/**
 * __useGetTimeZonesQuery__
 *
 * To run a query within a React component, call `useGetTimeZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeZonesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimeZonesQuery, GetTimeZonesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTimeZonesQuery, GetTimeZonesQueryVariables>(
    GetTimeZonesDocument,
    options,
  )
}
export function useGetTimeZonesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTimeZonesQuery,
    GetTimeZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTimeZonesQuery, GetTimeZonesQueryVariables>(
    GetTimeZonesDocument,
    options,
  )
}
export type GetTimeZonesQueryHookResult = ReturnType<typeof useGetTimeZonesQuery>
export type GetTimeZonesLazyQueryHookResult = ReturnType<typeof useGetTimeZonesLazyQuery>
export type GetTimeZonesQueryResult = ApolloReactCommon.QueryResult<
  GetTimeZonesQuery,
  GetTimeZonesQueryVariables
>
export const RemoveNationalIdCardDocument = gql`
  mutation removeNationalIdCard($input: RemoveNationalIdCardInput!) {
    removeNationalIdCard(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type RemoveNationalIdCardMutationFn = ApolloReactCommon.MutationFunction<
  RemoveNationalIdCardMutation,
  RemoveNationalIdCardMutationVariables
>

/**
 * __useRemoveNationalIdCardMutation__
 *
 * To run a mutation, you first call `useRemoveNationalIdCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNationalIdCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNationalIdCardMutation, { data, loading, error }] = useRemoveNationalIdCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveNationalIdCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveNationalIdCardMutation,
    RemoveNationalIdCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RemoveNationalIdCardMutation,
    RemoveNationalIdCardMutationVariables
  >(RemoveNationalIdCardDocument, options)
}
export type RemoveNationalIdCardMutationHookResult = ReturnType<
  typeof useRemoveNationalIdCardMutation
>
export type RemoveNationalIdCardMutationResult = ApolloReactCommon.MutationResult<RemoveNationalIdCardMutation>
export type RemoveNationalIdCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveNationalIdCardMutation,
  RemoveNationalIdCardMutationVariables
>
export const RemoveVisaDocument = gql`
  mutation removeVisa($input: RemoveVisaInput!) {
    removeVisa(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type RemoveVisaMutationFn = ApolloReactCommon.MutationFunction<
  RemoveVisaMutation,
  RemoveVisaMutationVariables
>

/**
 * __useRemoveVisaMutation__
 *
 * To run a mutation, you first call `useRemoveVisaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVisaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVisaMutation, { data, loading, error }] = useRemoveVisaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVisaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveVisaMutation,
    RemoveVisaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveVisaMutation, RemoveVisaMutationVariables>(
    RemoveVisaDocument,
    options,
  )
}
export type RemoveVisaMutationHookResult = ReturnType<typeof useRemoveVisaMutation>
export type RemoveVisaMutationResult = ApolloReactCommon.MutationResult<RemoveVisaMutation>
export type RemoveVisaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveVisaMutation,
  RemoveVisaMutationVariables
>
export const UpdateAuthorizersDocument = gql`
  mutation updateAuthorizers($input: UpdateAuthorizersInput!) {
    updateAuthorizers(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateAuthorizersMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAuthorizersMutation,
  UpdateAuthorizersMutationVariables
>

/**
 * __useUpdateAuthorizersMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorizersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorizersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorizersMutation, { data, loading, error }] = useUpdateAuthorizersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthorizersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAuthorizersMutation,
    UpdateAuthorizersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateAuthorizersMutation,
    UpdateAuthorizersMutationVariables
  >(UpdateAuthorizersDocument, options)
}
export type UpdateAuthorizersMutationHookResult = ReturnType<typeof useUpdateAuthorizersMutation>
export type UpdateAuthorizersMutationResult = ApolloReactCommon.MutationResult<UpdateAuthorizersMutation>
export type UpdateAuthorizersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAuthorizersMutation,
  UpdateAuthorizersMutationVariables
>
export const UpdateDelegatesDocument = gql`
  mutation updateDelegates($input: UpdateDelegatesInput!) {
    updateDelegates(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateDelegatesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDelegatesMutation,
  UpdateDelegatesMutationVariables
>

/**
 * __useUpdateDelegatesMutation__
 *
 * To run a mutation, you first call `useUpdateDelegatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDelegatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDelegatesMutation, { data, loading, error }] = useUpdateDelegatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDelegatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDelegatesMutation,
    UpdateDelegatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateDelegatesMutation, UpdateDelegatesMutationVariables>(
    UpdateDelegatesDocument,
    options,
  )
}
export type UpdateDelegatesMutationHookResult = ReturnType<typeof useUpdateDelegatesMutation>
export type UpdateDelegatesMutationResult = ApolloReactCommon.MutationResult<UpdateDelegatesMutation>
export type UpdateDelegatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDelegatesMutation,
  UpdateDelegatesMutationVariables
>
export const UpdateNationalIdCardDocument = gql`
  mutation updateNationalIdCard($input: UpdateNationalIdCardInput!) {
    updateNationalIdCard(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`
export type UpdateNationalIdCardMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNationalIdCardMutation,
  UpdateNationalIdCardMutationVariables
>

/**
 * __useUpdateNationalIdCardMutation__
 *
 * To run a mutation, you first call `useUpdateNationalIdCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNationalIdCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNationalIdCardMutation, { data, loading, error }] = useUpdateNationalIdCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNationalIdCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNationalIdCardMutation,
    UpdateNationalIdCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateNationalIdCardMutation,
    UpdateNationalIdCardMutationVariables
  >(UpdateNationalIdCardDocument, options)
}
export type UpdateNationalIdCardMutationHookResult = ReturnType<
  typeof useUpdateNationalIdCardMutation
>
export type UpdateNationalIdCardMutationResult = ApolloReactCommon.MutationResult<UpdateNationalIdCardMutation>
export type UpdateNationalIdCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNationalIdCardMutation,
  UpdateNationalIdCardMutationVariables
>
export const UpdatePassportDocument = gql`
  mutation updatePassport($input: UpdatePassportInput!) {
    updatePassport(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on InvalidInputError {
        message
        fields {
          type
          errorType
          message
        }
      }
      ... on NoPassportError {
        message
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type UpdatePassportMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePassportMutation,
  UpdatePassportMutationVariables
>

/**
 * __useUpdatePassportMutation__
 *
 * To run a mutation, you first call `useUpdatePassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassportMutation, { data, loading, error }] = useUpdatePassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePassportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePassportMutation,
    UpdatePassportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdatePassportMutation, UpdatePassportMutationVariables>(
    UpdatePassportDocument,
    options,
  )
}
export type UpdatePassportMutationHookResult = ReturnType<typeof useUpdatePassportMutation>
export type UpdatePassportMutationResult = ApolloReactCommon.MutationResult<UpdatePassportMutation>
export type UpdatePassportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePassportMutation,
  UpdatePassportMutationVariables
>
export const UpdateVisaDocument = gql`
  mutation updateVisa($input: UpdateVisaInput!) {
    updateVisa(input: $input) {
      __typename
      ... on BaseResponse {
        code
        success
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`
export type UpdateVisaMutationFn = ApolloReactCommon.MutationFunction<
  UpdateVisaMutation,
  UpdateVisaMutationVariables
>

/**
 * __useUpdateVisaMutation__
 *
 * To run a mutation, you first call `useUpdateVisaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisaMutation, { data, loading, error }] = useUpdateVisaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateVisaMutation,
    UpdateVisaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateVisaMutation, UpdateVisaMutationVariables>(
    UpdateVisaDocument,
    options,
  )
}
export type UpdateVisaMutationHookResult = ReturnType<typeof useUpdateVisaMutation>
export type UpdateVisaMutationResult = ApolloReactCommon.MutationResult<UpdateVisaMutation>
export type UpdateVisaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateVisaMutation,
  UpdateVisaMutationVariables
>
export const GetTravelPolicyDocument = gql`
  query getTravelPolicy {
    travelPolicy {
      purposeLabel
      isPurposeRequired
      systemBehaviorType
      minAdvancePurchaseDays
    }
  }
`

/**
 * __useGetTravelPolicyQuery__
 *
 * To run a query within a React component, call `useGetTravelPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTravelPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTravelPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTravelPolicyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTravelPolicyQuery,
    GetTravelPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTravelPolicyQuery, GetTravelPolicyQueryVariables>(
    GetTravelPolicyDocument,
    options,
  )
}
export function useGetTravelPolicyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTravelPolicyQuery,
    GetTravelPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTravelPolicyQuery, GetTravelPolicyQueryVariables>(
    GetTravelPolicyDocument,
    options,
  )
}
export type GetTravelPolicyQueryHookResult = ReturnType<typeof useGetTravelPolicyQuery>
export type GetTravelPolicyLazyQueryHookResult = ReturnType<typeof useGetTravelPolicyLazyQuery>
export type GetTravelPolicyQueryResult = ApolloReactCommon.QueryResult<
  GetTravelPolicyQuery,
  GetTravelPolicyQueryVariables
>
export const GetTravelPreferencesDocument = gql`
  query getTravelPreferences {
    travelPreferences {
      flight {
        homeAirport
        homeAirportLabel
        meal {
          label
          value
        }
        specialRequest {
          label
          value
        }
        loyaltyProgram {
          vendorCode
          label
          number
          imgUrl
        }
        mealOptions {
          label
          value
        }
        specialRequestOptions {
          label
          value
        }
        searchBy
        seatPreference
        redressNumber
        redressNumberIssuingCountry
        resultsView
        knownTravelerNumber
        knownTravelerIssuingCountry
        noteToArranger
      }
      hotel {
        specialRequest {
          label
          value
        }
        loyaltyProgram {
          vendorCode
          label
          number
          imgUrl
        }
        specialRequestOptions {
          label
          value
        }
        hotelAmenity
      }
      carrental {
        specialRequest {
          label
          value
        }
        loyaltyProgram {
          vendorCode
          label
          number
          imgUrl
        }
        specialRequestOptions {
          label
          value
        }
        carClass
      }
      rail {
        specialRequest {
          label
          value
        }
        loyaltyProgram {
          vendorCode
          label
          number
          imgUrl
        }
      }
    }
  }
`

/**
 * __useGetTravelPreferencesQuery__
 *
 * To run a query within a React component, call `useGetTravelPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTravelPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTravelPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTravelPreferencesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTravelPreferencesQuery,
    GetTravelPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTravelPreferencesQuery, GetTravelPreferencesQueryVariables>(
    GetTravelPreferencesDocument,
    options,
  )
}
export function useGetTravelPreferencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTravelPreferencesQuery,
    GetTravelPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetTravelPreferencesQuery,
    GetTravelPreferencesQueryVariables
  >(GetTravelPreferencesDocument, options)
}
export type GetTravelPreferencesQueryHookResult = ReturnType<typeof useGetTravelPreferencesQuery>
export type GetTravelPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetTravelPreferencesLazyQuery
>
export type GetTravelPreferencesQueryResult = ApolloReactCommon.QueryResult<
  GetTravelPreferencesQuery,
  GetTravelPreferencesQueryVariables
>
export const Document360SupportArticleByIdDocument = gql`
  query document360SupportArticleById($input: Document360SupportArticleByIdInput!) {
    document360SupportArticleById(input: $input) {
      title
      htmlContent
    }
  }
`

/**
 * __useDocument360SupportArticleByIdQuery__
 *
 * To run a query within a React component, call `useDocument360SupportArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocument360SupportArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocument360SupportArticleByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocument360SupportArticleByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Document360SupportArticleByIdQuery,
    Document360SupportArticleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    Document360SupportArticleByIdQuery,
    Document360SupportArticleByIdQueryVariables
  >(Document360SupportArticleByIdDocument, options)
}
export function useDocument360SupportArticleByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Document360SupportArticleByIdQuery,
    Document360SupportArticleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    Document360SupportArticleByIdQuery,
    Document360SupportArticleByIdQueryVariables
  >(Document360SupportArticleByIdDocument, options)
}
export type Document360SupportArticleByIdQueryHookResult = ReturnType<
  typeof useDocument360SupportArticleByIdQuery
>
export type Document360SupportArticleByIdLazyQueryHookResult = ReturnType<
  typeof useDocument360SupportArticleByIdLazyQuery
>
export type Document360SupportArticleByIdQueryResult = ApolloReactCommon.QueryResult<
  Document360SupportArticleByIdQuery,
  Document360SupportArticleByIdQueryVariables
>
export const ApplyUnusedTicketDocument = gql`
  mutation applyUnusedTicket($input: UnusedTicketInput!) {
    applyUnusedTicket(input: $input) {
      code
      errorCodes
      message
      success
    }
  }
`
export type ApplyUnusedTicketMutationFn = ApolloReactCommon.MutationFunction<
  ApplyUnusedTicketMutation,
  ApplyUnusedTicketMutationVariables
>

/**
 * __useApplyUnusedTicketMutation__
 *
 * To run a mutation, you first call `useApplyUnusedTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyUnusedTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyUnusedTicketMutation, { data, loading, error }] = useApplyUnusedTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyUnusedTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApplyUnusedTicketMutation,
    ApplyUnusedTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ApplyUnusedTicketMutation,
    ApplyUnusedTicketMutationVariables
  >(ApplyUnusedTicketDocument, options)
}
export type ApplyUnusedTicketMutationHookResult = ReturnType<typeof useApplyUnusedTicketMutation>
export type ApplyUnusedTicketMutationResult = ApolloReactCommon.MutationResult<ApplyUnusedTicketMutation>
export type ApplyUnusedTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApplyUnusedTicketMutation,
  ApplyUnusedTicketMutationVariables
>
export const RemoveUnusedTicketDocument = gql`
  mutation removeUnusedTicket($input: UnusedTicketInput!) {
    removeUnusedTicket(input: $input) {
      success
      message
      code
      errorCodes
    }
  }
`
export type RemoveUnusedTicketMutationFn = ApolloReactCommon.MutationFunction<
  RemoveUnusedTicketMutation,
  RemoveUnusedTicketMutationVariables
>

/**
 * __useRemoveUnusedTicketMutation__
 *
 * To run a mutation, you first call `useRemoveUnusedTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUnusedTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUnusedTicketMutation, { data, loading, error }] = useRemoveUnusedTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUnusedTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveUnusedTicketMutation,
    RemoveUnusedTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    RemoveUnusedTicketMutation,
    RemoveUnusedTicketMutationVariables
  >(RemoveUnusedTicketDocument, options)
}
export type RemoveUnusedTicketMutationHookResult = ReturnType<typeof useRemoveUnusedTicketMutation>
export type RemoveUnusedTicketMutationResult = ApolloReactCommon.MutationResult<RemoveUnusedTicketMutation>
export type RemoveUnusedTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveUnusedTicketMutation,
  RemoveUnusedTicketMutationVariables
>
export const GetUnusedTicketsDocument = gql`
  query getUnusedTickets {
    unusedTickets {
      unusedTickets {
        airlineCode
        airline {
          code
          imageUrl
          value
        }
        changeFee {
          amount
          code
          requestedCurrencyAmount
          requestedCurrencyUnit
        }
        expirationDate
        originalCost {
          amount
          code
          requestedCurrencyAmount
          requestedCurrencyUnit
        }
        recordLocator
        status
        ticketNumber
        unvalidated {
          total {
            primary {
              ...moneyTickets
            }
            secondary {
              ...moneyTickets
            }
          }
        }
        validated {
          exchangedTicket {
            ...rateTickets
          }
          newTicket {
            ...rateTickets
          }
          original {
            ...rateTickets
          }
          penalty {
            ...rateTickets
          }
          residual {
            ...rateTickets
          }
          total {
            ...rateTickets
          }
        }
      }
    }
  }
  ${MoneyTicketsFragmentDoc}
  ${RateTicketsFragmentDoc}
`

/**
 * __useGetUnusedTicketsQuery__
 *
 * To run a query within a React component, call `useGetUnusedTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnusedTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnusedTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnusedTicketsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUnusedTicketsQuery,
    GetUnusedTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetUnusedTicketsQuery, GetUnusedTicketsQueryVariables>(
    GetUnusedTicketsDocument,
    options,
  )
}
export function useGetUnusedTicketsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUnusedTicketsQuery,
    GetUnusedTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetUnusedTicketsQuery, GetUnusedTicketsQueryVariables>(
    GetUnusedTicketsDocument,
    options,
  )
}
export type GetUnusedTicketsQueryHookResult = ReturnType<typeof useGetUnusedTicketsQuery>
export type GetUnusedTicketsLazyQueryHookResult = ReturnType<typeof useGetUnusedTicketsLazyQuery>
export type GetUnusedTicketsQueryResult = ApolloReactCommon.QueryResult<
  GetUnusedTicketsQuery,
  GetUnusedTicketsQueryVariables
>
export const UpdateAirTravelPreferenceDocument = gql`
  mutation updateAirTravelPreference($input: AirTravelPreferenceInput!) {
    airTravelPreference(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateAirTravelPreferenceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAirTravelPreferenceMutation,
  UpdateAirTravelPreferenceMutationVariables
>

/**
 * __useUpdateAirTravelPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateAirTravelPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAirTravelPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAirTravelPreferenceMutation, { data, loading, error }] = useUpdateAirTravelPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAirTravelPreferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAirTravelPreferenceMutation,
    UpdateAirTravelPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateAirTravelPreferenceMutation,
    UpdateAirTravelPreferenceMutationVariables
  >(UpdateAirTravelPreferenceDocument, options)
}
export type UpdateAirTravelPreferenceMutationHookResult = ReturnType<
  typeof useUpdateAirTravelPreferenceMutation
>
export type UpdateAirTravelPreferenceMutationResult = ApolloReactCommon.MutationResult<UpdateAirTravelPreferenceMutation>
export type UpdateAirTravelPreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAirTravelPreferenceMutation,
  UpdateAirTravelPreferenceMutationVariables
>
export const UpdateCarRentalTravelPreferenceDocument = gql`
  mutation updateCarRentalTravelPreference($input: CarRentalTravelPreferenceInput!) {
    carRentalTravelPreference(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateCarRentalTravelPreferenceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCarRentalTravelPreferenceMutation,
  UpdateCarRentalTravelPreferenceMutationVariables
>

/**
 * __useUpdateCarRentalTravelPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateCarRentalTravelPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarRentalTravelPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarRentalTravelPreferenceMutation, { data, loading, error }] = useUpdateCarRentalTravelPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarRentalTravelPreferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCarRentalTravelPreferenceMutation,
    UpdateCarRentalTravelPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateCarRentalTravelPreferenceMutation,
    UpdateCarRentalTravelPreferenceMutationVariables
  >(UpdateCarRentalTravelPreferenceDocument, options)
}
export type UpdateCarRentalTravelPreferenceMutationHookResult = ReturnType<
  typeof useUpdateCarRentalTravelPreferenceMutation
>
export type UpdateCarRentalTravelPreferenceMutationResult = ApolloReactCommon.MutationResult<UpdateCarRentalTravelPreferenceMutation>
export type UpdateCarRentalTravelPreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCarRentalTravelPreferenceMutation,
  UpdateCarRentalTravelPreferenceMutationVariables
>
export const UpdateHotelTravelPreferenceDocument = gql`
  mutation updateHotelTravelPreference($input: HotelTravelPreferenceInput!) {
    hotelTravelPreference(input: $input) {
      success
      message
      code
    }
  }
`
export type UpdateHotelTravelPreferenceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateHotelTravelPreferenceMutation,
  UpdateHotelTravelPreferenceMutationVariables
>

/**
 * __useUpdateHotelTravelPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateHotelTravelPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotelTravelPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotelTravelPreferenceMutation, { data, loading, error }] = useUpdateHotelTravelPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHotelTravelPreferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHotelTravelPreferenceMutation,
    UpdateHotelTravelPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateHotelTravelPreferenceMutation,
    UpdateHotelTravelPreferenceMutationVariables
  >(UpdateHotelTravelPreferenceDocument, options)
}
export type UpdateHotelTravelPreferenceMutationHookResult = ReturnType<
  typeof useUpdateHotelTravelPreferenceMutation
>
export type UpdateHotelTravelPreferenceMutationResult = ApolloReactCommon.MutationResult<UpdateHotelTravelPreferenceMutation>
export type UpdateHotelTravelPreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateHotelTravelPreferenceMutation,
  UpdateHotelTravelPreferenceMutationVariables
>
export const GetUserProfileDocument = gql`
  query getUserProfile($includeOpenEnrollmentRegistrationCustomFields: Boolean) {
    getUserProfile(
      includeOpenEnrollmentRegistrationCustomFields: $includeOpenEnrollmentRegistrationCustomFields
    ) {
      businessAddress {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
      homeAddress {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
      personalInformation {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
      emergencyContact {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
      employeeInformation {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
      travelPreferences {
        __typename
        additionalInformation {
          ...additionalInformation
        }
      }
    }
  }
  ${AdditionalInformationFragmentDoc}
`

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      includeOpenEnrollmentRegistrationCustomFields: // value for 'includeOpenEnrollmentRegistrationCustomFields'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export function useGetUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>
export type GetUserProfileQueryResult = ApolloReactCommon.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>
export const UpdateUserProfileBusinessAddressDocument = gql`
  mutation updateUserProfileBusinessAddress($input: UpdateProfileBusinessAddressInput!) {
    updateUserProfileBusinessAddress(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileBusinessAddressMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileBusinessAddressMutation,
  UpdateUserProfileBusinessAddressMutationVariables
>

/**
 * __useUpdateUserProfileBusinessAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileBusinessAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileBusinessAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileBusinessAddressMutation, { data, loading, error }] = useUpdateUserProfileBusinessAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileBusinessAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileBusinessAddressMutation,
    UpdateUserProfileBusinessAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileBusinessAddressMutation,
    UpdateUserProfileBusinessAddressMutationVariables
  >(UpdateUserProfileBusinessAddressDocument, options)
}
export type UpdateUserProfileBusinessAddressMutationHookResult = ReturnType<
  typeof useUpdateUserProfileBusinessAddressMutation
>
export type UpdateUserProfileBusinessAddressMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileBusinessAddressMutation>
export type UpdateUserProfileBusinessAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileBusinessAddressMutation,
  UpdateUserProfileBusinessAddressMutationVariables
>
export const UpdateUserProfileContactInformationDocument = gql`
  mutation updateUserProfileContactInformation($input: UpdateProfileContactInformationInput!) {
    updateUserProfileContactInformation(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileContactInformationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileContactInformationMutation,
  UpdateUserProfileContactInformationMutationVariables
>

/**
 * __useUpdateUserProfileContactInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileContactInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileContactInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileContactInformationMutation, { data, loading, error }] = useUpdateUserProfileContactInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileContactInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileContactInformationMutation,
    UpdateUserProfileContactInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileContactInformationMutation,
    UpdateUserProfileContactInformationMutationVariables
  >(UpdateUserProfileContactInformationDocument, options)
}
export type UpdateUserProfileContactInformationMutationHookResult = ReturnType<
  typeof useUpdateUserProfileContactInformationMutation
>
export type UpdateUserProfileContactInformationMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileContactInformationMutation>
export type UpdateUserProfileContactInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileContactInformationMutation,
  UpdateUserProfileContactInformationMutationVariables
>
export const UpdateUserProfileEmergencyContactDocument = gql`
  mutation updateUserProfileEmergencyContact($input: UpdateProfileEmergencyContactInput!) {
    updateUserProfileEmergencyContact(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileEmergencyContactMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileEmergencyContactMutation,
  UpdateUserProfileEmergencyContactMutationVariables
>

/**
 * __useUpdateUserProfileEmergencyContactMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileEmergencyContactMutation, { data, loading, error }] = useUpdateUserProfileEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileEmergencyContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileEmergencyContactMutation,
    UpdateUserProfileEmergencyContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileEmergencyContactMutation,
    UpdateUserProfileEmergencyContactMutationVariables
  >(UpdateUserProfileEmergencyContactDocument, options)
}
export type UpdateUserProfileEmergencyContactMutationHookResult = ReturnType<
  typeof useUpdateUserProfileEmergencyContactMutation
>
export type UpdateUserProfileEmergencyContactMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileEmergencyContactMutation>
export type UpdateUserProfileEmergencyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileEmergencyContactMutation,
  UpdateUserProfileEmergencyContactMutationVariables
>
export const UpdateUserProfileEmployeeInformationDocument = gql`
  mutation updateUserProfileEmployeeInformation($input: UpdateProfileEmployeeInformationInput!) {
    updateUserProfileEmployeeInformation(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileEmployeeInformationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileEmployeeInformationMutation,
  UpdateUserProfileEmployeeInformationMutationVariables
>

/**
 * __useUpdateUserProfileEmployeeInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileEmployeeInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileEmployeeInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileEmployeeInformationMutation, { data, loading, error }] = useUpdateUserProfileEmployeeInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileEmployeeInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileEmployeeInformationMutation,
    UpdateUserProfileEmployeeInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileEmployeeInformationMutation,
    UpdateUserProfileEmployeeInformationMutationVariables
  >(UpdateUserProfileEmployeeInformationDocument, options)
}
export type UpdateUserProfileEmployeeInformationMutationHookResult = ReturnType<
  typeof useUpdateUserProfileEmployeeInformationMutation
>
export type UpdateUserProfileEmployeeInformationMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileEmployeeInformationMutation>
export type UpdateUserProfileEmployeeInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileEmployeeInformationMutation,
  UpdateUserProfileEmployeeInformationMutationVariables
>
export const UpdateUserProfileHomeAddressDocument = gql`
  mutation updateUserProfileHomeAddress($input: UpdateProfileHomeAddressInput!) {
    updateUserProfileHomeAddress(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileHomeAddressMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileHomeAddressMutation,
  UpdateUserProfileHomeAddressMutationVariables
>

/**
 * __useUpdateUserProfileHomeAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileHomeAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileHomeAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileHomeAddressMutation, { data, loading, error }] = useUpdateUserProfileHomeAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileHomeAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileHomeAddressMutation,
    UpdateUserProfileHomeAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileHomeAddressMutation,
    UpdateUserProfileHomeAddressMutationVariables
  >(UpdateUserProfileHomeAddressDocument, options)
}
export type UpdateUserProfileHomeAddressMutationHookResult = ReturnType<
  typeof useUpdateUserProfileHomeAddressMutation
>
export type UpdateUserProfileHomeAddressMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileHomeAddressMutation>
export type UpdateUserProfileHomeAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileHomeAddressMutation,
  UpdateUserProfileHomeAddressMutationVariables
>
export const UpdateUserProfilePersonalInformationDocument = gql`
  mutation updateUserProfilePersonalInformation($input: UpdateProfilePersonalInformationInput!) {
    updateUserProfilePersonalInformation(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfilePersonalInformationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfilePersonalInformationMutation,
  UpdateUserProfilePersonalInformationMutationVariables
>

/**
 * __useUpdateUserProfilePersonalInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfilePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfilePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfilePersonalInformationMutation, { data, loading, error }] = useUpdateUserProfilePersonalInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfilePersonalInformationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfilePersonalInformationMutation,
    UpdateUserProfilePersonalInformationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfilePersonalInformationMutation,
    UpdateUserProfilePersonalInformationMutationVariables
  >(UpdateUserProfilePersonalInformationDocument, options)
}
export type UpdateUserProfilePersonalInformationMutationHookResult = ReturnType<
  typeof useUpdateUserProfilePersonalInformationMutation
>
export type UpdateUserProfilePersonalInformationMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfilePersonalInformationMutation>
export type UpdateUserProfilePersonalInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfilePersonalInformationMutation,
  UpdateUserProfilePersonalInformationMutationVariables
>
export const UpdateUserProfileTravelPreferencesDocument = gql`
  mutation updateUserProfileTravelPreferences($input: UpdateProfileTravelPreferencesInput!) {
    updateUserProfileTravelPreferences(input: $input) {
      code
      success
      message
      errorCodes
    }
  }
`
export type UpdateUserProfileTravelPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileTravelPreferencesMutation,
  UpdateUserProfileTravelPreferencesMutationVariables
>

/**
 * __useUpdateUserProfileTravelPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileTravelPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileTravelPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileTravelPreferencesMutation, { data, loading, error }] = useUpdateUserProfileTravelPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileTravelPreferencesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileTravelPreferencesMutation,
    UpdateUserProfileTravelPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    UpdateUserProfileTravelPreferencesMutation,
    UpdateUserProfileTravelPreferencesMutationVariables
  >(UpdateUserProfileTravelPreferencesDocument, options)
}
export type UpdateUserProfileTravelPreferencesMutationHookResult = ReturnType<
  typeof useUpdateUserProfileTravelPreferencesMutation
>
export type UpdateUserProfileTravelPreferencesMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileTravelPreferencesMutation>
export type UpdateUserProfileTravelPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileTravelPreferencesMutation,
  UpdateUserProfileTravelPreferencesMutationVariables
>
export const GetPasswordSecurityConfigureDocument = gql`
  query getPasswordSecurityConfigure {
    passwordSecurityConfigure {
      dictionaryWordsNotAllowed
      forceAlphaNumericPassword
      passwordMinLength
      passwordReusability
    }
  }
`

/**
 * __useGetPasswordSecurityConfigureQuery__
 *
 * To run a query within a React component, call `useGetPasswordSecurityConfigureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordSecurityConfigureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordSecurityConfigureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPasswordSecurityConfigureQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPasswordSecurityConfigureQuery,
    GetPasswordSecurityConfigureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetPasswordSecurityConfigureQuery,
    GetPasswordSecurityConfigureQueryVariables
  >(GetPasswordSecurityConfigureDocument, options)
}
export function useGetPasswordSecurityConfigureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPasswordSecurityConfigureQuery,
    GetPasswordSecurityConfigureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetPasswordSecurityConfigureQuery,
    GetPasswordSecurityConfigureQueryVariables
  >(GetPasswordSecurityConfigureDocument, options)
}
export type GetPasswordSecurityConfigureQueryHookResult = ReturnType<
  typeof useGetPasswordSecurityConfigureQuery
>
export type GetPasswordSecurityConfigureLazyQueryHookResult = ReturnType<
  typeof useGetPasswordSecurityConfigureLazyQuery
>
export type GetPasswordSecurityConfigureQueryResult = ApolloReactCommon.QueryResult<
  GetPasswordSecurityConfigureQuery,
  GetPasswordSecurityConfigureQueryVariables
>
export const GetUserDocument = gql`
  query getUser {
    user {
      locale
      trustedLocale
      currency
      customerId
      companyId
      partnerId
      sessionTimeout
      delegatedId
      impersonatedId
      meetingId
      profile {
        locale
        currency
        isGuest
        registrationStatus
        forceNewPassword
        creationSource
        pwaWelcomeMessage {
          shouldShowWelcomeMessage
        }
        timeZone
        dateFormat
        country
        site {
          name
          url
          isSelfServiceSite
        }
        identityHash
        measurementUnit
        personalContact {
          email
          homeEmail
          address {
            street1
            street2
            mailStop
            city
            stateCode
            postalCode
            countryCode
          }
          phone {
            ext
            number
            officeExtension
            countryCode
          }
        }
        businessContact {
          address {
            street1
            street2
            mailStop
            city
            stateCode
            postalCode
            countryCode
          }
          mobilePhone {
            ext
            number
            officeExtension
            countryCode
          }
          officePhone {
            ext
            number
            officeExtension
            countryCode
          }
          faxPhone {
            ext
            number
            officeExtension
            countryCode
          }
        }
        firstName
        lastName
        middleName
        suffix
        suffixOptions
        rawSuffix
        title
        titleOptions
        notification {
          id
          topic
          device
          email
          phone {
            country
            isoCountryCode
            number
          }
        }
        dateOfBirth
        gender
        genderOptions
        passports {
          id
          number
          issuingCountry
          country
          issueDate
          expirationDate
          preExpirationAlert
          unmaskedNumber
        }
        visas {
          id
          number
          visaType
          country
          expirationDate
          preExpirationAlert
        }
        nationalIdCards {
          id
          number
          country
          issueDate
          expirationDate
          preExpirationAlert
        }
        hourlyTimeFormat
        emergencyContact {
          name
          relationship
          primaryPhone {
            number
            ext
            countryCode
          }
          alternatePhone {
            number
            ext
            countryCode
          }
          email
          address {
            street1
            street2
            mailStop
            city
            stateCode
            postalCode
            countryCode
          }
        }
        employee {
          businessUnit
          costCenter
          defaultApproverId
          departmentCode
          departmentName
          division
          employeeId
          employeeType
          isActiveInCompany
          isVip
          jobLevel
          jobTitle
          companyName
          manager {
            id
            externalId
          }
        }
        confEmail
        groupware {
          groupwareId
        }
        preferredCreditCards {
          id
          segments
        }
        username
        profilePin
        approvers {
          expenseApproverId
          purchaseApproverId
          travelApproverId
        }
        mis {
          mis1
          mis2
          mis3
          mis4
          mis5
          mis6
          mis7
        }
        creditCards {
          id
          preExpirationAlert
        }
        delegates {
          id
          firstName
          lastName
          email
        }
        authorizers {
          id
          firstName
          lastName
          email
        }
        additionalDocumentsPreferences {
          knownTravelerNumbers {
            id
            number
            country
            expirationDate
            preExpirationAlert
          }
          redressNumbers {
            number
            country
          }
        }
      }
    }
  }
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options)
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>
export const SetPasswordDocument = gql`
  mutation setPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      __typename
      ... on BaseResponse {
        code
        errorCodes
        message
        success
      }
      ... on UnexpectedError {
        message
        lcid
      }
    }
  }
`
export type SetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  SetPasswordMutation,
  SetPasswordMutationVariables
>

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPasswordMutation,
    SetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(
    SetPasswordDocument,
    options,
  )
}
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>
export type SetPasswordMutationResult = ApolloReactCommon.MutationResult<SetPasswordMutation>
export type SetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPasswordMutation,
  SetPasswordMutationVariables
>
