import { createErrorClass } from '@etta/core/errors'

export namespace GetFlightDynamicSiteMessagesErrors {
  export const UnexpectedErrorGetFlightDynamicSiteMessages = createErrorClass(
    'Unexpected error while getting flight dynamic site messages',
  )

  export const GetFlightDSMError = createErrorClass('Get flight dynamic site messages error')
}

export type GetFlightDynamicSiteMessagesErrorsInstances = InstanceType<
  typeof GetFlightDynamicSiteMessagesErrors[keyof typeof GetFlightDynamicSiteMessagesErrors]
>
