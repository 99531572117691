import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { formatRate } from '@fiji/utils/money'
import { LuggageButton, Row, ButtonTitle } from './add-luggage-button.styled'
import type { Props } from './types'

const i18nBase = 'ReviewTrip'

export function AddLuggageButton({ selectetedLuggageOption, onClick }: Props) {
  const { t } = useTranslation()

  const { mainCost: luggageCost } = formatRate(selectetedLuggageOption?.cost, {
    morpheme: 'none',
  })

  return (
    <LuggageButton onClick={onClick}>
      <Row>
        <ButtonTitle>
          <Icon size={24} color="bodyText" name="baggageIconPWA" />
          <Text variant="footnoteStrong" textOverflow="ellipsis-two-line">
            {t(i18nBase + '.Baggage')}
          </Text>
        </ButtonTitle>
        <Link size="small">
          {selectetedLuggageOption ? t(i18nBase + '.Change') : t(i18nBase + '.Add')}
        </Link>
      </Row>
      {selectetedLuggageOption && (
        <Row isFooter>
          <Text textOverflow="ellipsis" color="mainText" variant="footnoteMedium">
            {selectetedLuggageOption.value}
          </Text>
          <Text noWrap color="mainText" variant="footnoteMedium">
            {luggageCost}
          </Text>
        </Row>
      )}
    </LuggageButton>
  )
}
