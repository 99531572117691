import styled, { css } from 'styled-components'
import { footnoteMedium, footnoteStrong } from '@fiji/style'

export const EditButton = styled.button`
  position: absolute;
  right: -1px;
  top: -1px;
  background: transparent;
  border-width: 0;
`

export const ExcerptContent = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 12px;
`

export const CountryTitle = styled.div`
  ${footnoteStrong};
`
export const NumberAndTitle = styled.div<{ isOneLine?: boolean }>`
  ${footnoteMedium};
  padding-top: 2px;
  color: ${(p) => p.theme.colors.bodyText1};
  ${({ isOneLine }) =>
    isOneLine &&
    css`
      display: flex;
      gap: 8px;
      text-align: center;
    `}
`
export const ExpiredExcerpt = styled.div`
  padding-left: 6px;
  color: ${(p) => p.theme.colors.error};
  margin-top: 1px;
  ${footnoteMedium};
`
export const NumberExcerpt = styled.div``

export const ErrorExcerpt = styled.div`
  display: flex;
`
