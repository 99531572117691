import { MediaLayout } from '@etta/ui/media-layout'
import type { AddButtonComponentProps } from '../../types'
import { TripReviewAddButtonDesktop } from './desktop'
import { TripReviewAddButtonMobile } from './mobile'

export function TripReviewAddButtonLayout(props: AddButtonComponentProps) {
  return (
    <MediaLayout
      mobileSlot={<TripReviewAddButtonMobile {...props} />}
      desktopSlot={<TripReviewAddButtonDesktop {...props} />}
    />
  )
}
