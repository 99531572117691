import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalLocationLayoutProps } from '../types'
import { CarRentalLocationMobile } from './mobile'
import { CarRentalLocationDesktop } from './desktop'

export function CarRentalLocationLayout(props: CarRentalLocationLayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalLocationMobile {...props} />}
      desktopSlot={<CarRentalLocationDesktop {...props} />}
    />
  )
}
