import type { MutableRefObject } from 'react'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useCombobox } from 'downshift'
import { useTranslation } from 'react-i18next'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { useLocationAutocomplete } from '@fiji/hooks/use-location-autocomplete/use-location-autocomplete'
import type { AddressInput, PlaceAutocompleteSort } from '@fiji/graphql/types'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'
import { getNoResultMessage } from '@fiji/utils/search-mechanism'
import { useAutocompleteContext } from '@etta/modules/autocomplete/interface'
import { getPlaceSearchType } from './get-place-search-type'

type Args = {
  searchType?: SearchType
  latestPlaces: Place[]
  suggestionPlaces: Place[]
  locationsSortOrder?: PlaceAutocompleteSort[]
  useNewLocationSearch?: boolean
  isModalOpen: boolean
  isOutboundTrip?: boolean
  onChange: (value: Place, address?: AddressInput) => void
  onModalClose: () => void
}

export function useSearchMechanism({
  searchType,
  latestPlaces,
  suggestionPlaces,
  locationsSortOrder,
  useNewLocationSearch,
  isModalOpen,
  isOutboundTrip,
  onChange,
  onModalClose,
}: Args) {
  const { t } = useTranslation()
  const i18Base = 'Mobility.SearchPage'
  const { anchorRef, scrollContainerRef } = useHeaderScroll()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isFetchStarted, setIsFetchStarted] = useState(false)
  const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [places, setPlaces] = useState<Place[]>([])
  const { autocompleteStore } = useAutocompleteContext()
  const modifiedPlaces = places.filter(
    (item) => item.placeType !== PlaceAutocompleteType.ReferencePoint,
  )
  const referencePoints = places.filter(
    (item) => item.placeType === PlaceAutocompleteType.ReferencePoint,
  )

  const isHotelSearch = searchType === 'hotel'

  const { handleFindPlaces, handleSelectedItemChange } = useLocationAutocomplete({
    onModalClose,
    onChange,
    onComplete: setPlaces,
    setErrorMessage,
    setIsLoading,
    useNewService: useNewLocationSearch,
    setIsFetchStarted,
  })

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    reset,
  } = useCombobox({
    items: isHotelSearch
      ? [...modifiedPlaces, ...referencePoints, ...suggestionPlaces, ...latestPlaces]
      : [...places, ...suggestionPlaces, ...latestPlaces],
    selectedItem: null,
    itemToString: (item) => item?.name || '',
    initialHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem }) => handleSelectedItemChange({ selectedItem }),
    onInputValueChange: ({ inputValue }) => {
      autocompleteStore.setQuery(inputValue)
      handleFindPlaces({
        place: inputValue,
        sortOrder: locationsSortOrder,
        searchType: getPlaceSearchType(searchType),
      })
    },
  })

  const hasAnyPlaces = Boolean(
    places.length > 0 || latestPlaces.length > 0 || suggestionPlaces.length > 0,
  )
  const hasSearchValue = searchInputRef.current?.defaultValue.length > 0

  const notResultMessage = getNoResultMessage({
    searchType,
    hasAnyPlaces,
    hasSearchValue,
    isFetchStarted,
    isLoading,
  })

  const isTrainNoResultVisible =
    (places.length > 0 ? false : hasSearchValue) &&
    searchType === 'train' &&
    isFetchStarted &&
    !isLoading

  const isResultsListOpen = isOpen || hasAnyPlaces || errorMessage
  const localPlaceholderPath = 'TripPlanner.BaseSearch.Input'

  const searchFieldPlaceholder = useMemo(() => {
    if (searchType === 'car-rental') {
      return t(`${localPlaceholderPath}.${isOutboundTrip ? 'PickupLocation' : 'DropoffLocation'}`)
    }
    if (isHotelSearch) {
      return t(`${localPlaceholderPath}.Location`)
    }

    if (searchType === 'mobility') {
      return t(`${i18Base}.${isOutboundTrip ? 'PickUp' : 'DropOff'}`)
    }

    return t(`${localPlaceholderPath}.${isOutboundTrip ? 'From' : 'To'}`)
  }, [isHotelSearch, isOutboundTrip, searchType, t])

  const handleCurrentLocation = useCallback(
    (place: Place, address?: AddressInput) => {
      onChange(place, address)
      if (onModalClose) {
        onModalClose()
      }
    },
    [onChange, onModalClose],
  )

  useEffect(() => {
    if (isModalOpen && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
    }
  }, [isModalOpen, searchInputRef])

  return {
    anchorRef,
    scrollContainerRef,
    searchInputRef,
    places,
    modifiedPlaces,
    referencePoints,
    searchFieldPlaceholder,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    notResultMessage,
    errorMessage,
    hasAnyPlaces,
    isResultsListOpen,
    isTrainNoResultVisible,
    isLoading,
    isHotelSearch,
    setIsLoading,
    handleCurrentLocation,
    reset,
  }
}
