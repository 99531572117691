import styled, { css } from 'styled-components'

export const DailyRatesRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`

export const AvgSummaryStyles = css`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding-bottom: 20px;
`

export const RateSummaryStyles = css`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  padding: 10px 0 20px 0;
`

export const Summary = styled.div<{ isAverageSummary: boolean }>`
  ${(p) => (p.isAverageSummary ? AvgSummaryStyles : RateSummaryStyles)}
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 1.8rem;
`
