import styled from 'styled-components'
import { headline } from '@fiji/style'

export const LabelContainer = styled.div`
  ${headline};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.mainText};
`

export const Label = styled.div`
  margin-left: 6px;
`
