import type { CreditCard } from '@fiji/graphql/types'
import type { getCardLabelString } from './get-card-label'
import { getCardLabel } from './get-card-label'

type Props = {
  creditCard?: CreditCard
  getCardLabelString?: typeof getCardLabelString
}

export function usePaymentCard({ creditCard, getCardLabelString }: Props) {
  if (creditCard) {
    return getCardLabel(creditCard, getCardLabelString)
  }

  return ''
}
