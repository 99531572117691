import { useCallback, useState } from 'react'
import { useAppDispatch } from '@fiji/store'
import { useAirSearchQuery } from '@fiji/hooks/search-queries/use-air-search-query/use-air-search-query'
import { useFlightTimeConfiguration } from '@fiji/hooks/time-configuration/use-flight/use-flight-time-configuration'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import { updateFlightId } from '@fiji/store/segment-ids'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import type { ViewState } from '@etta/ui/view-state-modal'
import { FlightDirection, TimeRangeDirection } from '@fiji/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { FlightActionChangeType } from '@etta/modules/post-booking/core/value-objects/trip-details/trip-details-flight-actions-value-object'
import { getChangeQueryParams } from './get-change-query-params'
import { getReturnChangeQueryParams } from './get-return-change-query-params'

type Props = {
  processId: string
  itineraryId: string
  flightId?: string | null
}

type HandleModifyFlightArgs = {
  isReturnChangeAction: boolean
}

type HandleModifyFlight = (args?: HandleModifyFlightArgs) => void

export function useModifyFlight({ processId, itineraryId, flightId }: Props) {
  const {
    postBookingTripStore,
    postBookingCancellationStore,
    postBookingAddSegmentAction,
  } = usePostBookingContext()
  const { flightSegments, trip } = postBookingTripStore
  const { supportedActions } = trip
  const { canceledTripSegmentToggle } = postBookingCancellationStore
  const dispatch = useAppDispatch()
  const { appendQueryParams, navigateTo } = useAirSearchQuery()
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const { cabinClassesOptions } = useSeatmapCabinClasss()
  const { getTimeForInitialFlight } = useFlightTimeConfiguration()

  const [viewState, setViewState] = useState<ViewState>('hidden')

  const onCloseViewState = () => {
    setViewState('hidden')
  }

  const handleModifyFlight: HandleModifyFlight = useCallback(
    (args) => {
      const { isReturnChangeAction } = args || {}
      const departureFlight = flightSegments[0]
      const returnFlight = flightSegments[1]
      const departureFlightStatus = departureFlight.segments?.[0].status

      if (departureFlightStatus === TripStatus.Cancelled) {
        canceledTripSegmentToggle.handleOpen()
        return
      }

      try {
        dispatch(updateFlightId({ flightId }))
        dispatch(updatePostPurchaseEntrance('fromTripDetails'))
        const { isFlightChangeCarrierAllowed, isFlightChangeDestinationAllowed } = supportedActions

        const leg0Time = getTimeForInitialFlight(
          TimeRangeDirection.Departure,
          FlightDirection.Origin,
        )
        const leg1Time = getTimeForInitialFlight(
          TimeRangeDirection.Departure,
          FlightDirection.Return,
        )
        const changeRules = trip.supportedActions.flightChangeRules.find(
          (r) => r.changeType === FlightActionChangeType.ReturnLeg,
        )

        const queryParams = isReturnChangeAction
          ? getReturnChangeQueryParams({
              cabinClassesOptions,
              departureFlight,
              returnFlight,
              itineraryId,
              leg0Time,
              leg1Time,
              processId,
              returnFlightSearchStartDate: changeRules?.allowedSearchDateRange?.start,
              returnFlightSearchEndDate: changeRules?.allowedSearchDateRange?.end,
            })
          : getChangeQueryParams({
              cabinClassesOptions,
              departureFlight,
              flightSegments,
              isFlightChangeCarrierAllowed,
              isFlightChangeDestinationAllowed,
              itineraryId,
              leg0Time,
              leg1Time,
              processId,
              returnFlight,
            })

        if (isMobile) {
          navigateTo(ROUTES.air.search, queryParams)
        } else {
          appendQueryParams(queryParams)
        }
        postBookingAddSegmentAction.handleAddFlight()
      } catch {
        setViewState('error')
      }
    },
    [
      flightSegments,
      canceledTripSegmentToggle,
      dispatch,
      flightId,
      supportedActions,
      getTimeForInitialFlight,
      cabinClassesOptions,
      itineraryId,
      processId,
      isMobile,
      postBookingAddSegmentAction,
      navigateTo,
      appendQueryParams,
      trip.supportedActions.flightChangeRules,
    ],
  )
  return {
    handleModifyFlight,
    viewState,
    onCloseViewState,
  }
}
