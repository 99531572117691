import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { CarRentalDetailsEcoCheckModal } from '@etta/components/car-rental-details-eco-check-modal/car-rental-details-eco-check-modal'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  BodyRight,
  LearnMore,
  MainContainer,
  BottomLine,
  LeftContainer,
  EmissionsDescription,
} from './car-rental-details-eco-check-desktop-styled'
import { BodyLeftBlock } from './body-left-block'
import EcoCheckAverage from './assets/eco-check-average.svg?url'
import EcoCheckPoluted from './assets/eco-check-poluted.svg?url'
import EcoCheckGreen from './assets/eco-check-green.svg?url'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

export type Props = {
  tonnesOfEmissions: number
  averageEmissionsTonnes: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function CarRentalDetailsEcoCheckDesktopComponent({
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.CarRentalDetails'
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const bodyRight = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.AverageEmissions')
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.LowerEmissions')
      case EmissionsDeviationLevel.More:
        return t(i18nBase + '.HigherEmissions')
    }
  }, [deviationLevel, t])

  const ecoCheckBackground = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return EcoCheckAverage
      case EmissionsDeviationLevel.Less:
        return EcoCheckGreen
      case EmissionsDeviationLevel.More:
        return EcoCheckPoluted
    }
  }, [deviationLevel])

  const { handleKeyDown } = useA11yKeydown(handleOpen)

  return (
    <>
      <Container
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={handleOpen}
        data-tracking-id="flight-details-eco-check-action">
        <MainContainer>
          <LeftContainer>
            <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
              <img src={EcoCheckLogo} alt="eco check logo" />
            </Title>
            <Body>
              <BodyLeftBlock deviationLevel={deviationLevel} percentDeviation={percentDeviation} />
              <BodyRight aria-label={bodyRight}>
                <EmissionsDescription dangerouslySetInnerHTML={{ __html: bodyRight }} />
                <LearnMore
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  aria-label={
                    t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
                  }>
                  {t('Emissions.DetailsModal.LearnMore')}
                </LearnMore>
              </BodyRight>
            </Body>
          </LeftContainer>
          <EcoCheckBackground src={ecoCheckBackground} />
        </MainContainer>
        <BottomLine deviationLevel={deviationLevel} />
      </Container>
      <CarRentalDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageEmissionsTonnes}
      />
    </>
  )
}
