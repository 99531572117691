import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { FC, PropsWithChildren } from 'react'
import { Block } from '@etta/ui/block'
import { useScreenType, ScreenType } from '@fiji/modes'

const i18nBase = 'CarRentalResults'

type Props = {
  hasBottomMargin?: boolean
}

export const FilterSearchInfoWrapper: FC<PropsWithChildren<Props>> = observer(
  function FilterSearchInfo({ children, hasBottomMargin = true }) {
    const { t } = useTranslation()
    const screenType = useScreenType()
    const isMobile = screenType === ScreenType.Mobile
    const bottomMargin = isMobile ? 8 : -8
    return (
      <Block
        aria-label={t(`${i18nBase}.CompanySettingFilters`)}
        paddingHorizontal={16}
        marginTop={isMobile ? 4 : 24}
        marginBottom={hasBottomMargin ? bottomMargin : 0}>
        {children}
      </Block>
    )
  },
)
