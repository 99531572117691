import { Action, Inject } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { QueryParams } from '@etta/interface/services/query-params/query-params'
import { CheckoutInfoActions } from '../../checkout-info/checkout-info.actions'
import { PurchaseInfoService } from '../services'

@Action()
export class PurchaseInfoActions {
  private readonly queryParams = new QueryParams<{ bookingId?: string }>(
    {},
    {
      caseStyle: 'kebab-case',
    },
  )
  constructor(
    @Inject()
    private readonly purchaseInfoService: PurchaseInfoService,
    @Inject()
    private readonly checkoutInfoActions: CheckoutInfoActions,
    @Inject() private historyStore: HistoryStore,
  ) {}

  async loadPurchaseInfo() {
    const { bookingId } = this.queryParams.getQueryParams(this.historyStore.search)
    await this.getCheckoutInfo(bookingId)
  }

  async fetchTravelerDataFromCheckoutInfo() {
    const { bookingId } = this.queryParams.getQueryParams(this.historyStore.search)
    await this.checkoutInfoActions.fetchTravelerDataFromCheckoutInfo(bookingId)
  }

  async fetchAdditionalDataFromCheckoutInfo() {
    const { bookingId } = this.queryParams.getQueryParams(this.historyStore.search)
    await this.checkoutInfoActions.fetchAdditionalDataFromCheckoutInfo(bookingId)
  }

  async fetchBillingFromPurchaseInfo() {
    const { bookingId } = this.queryParams.getQueryParams(this.historyStore.search)
    await this.checkoutInfoActions.fetchPaymentDataFromCheckoutInfo(bookingId)
  }

  private async getCheckoutInfo(bookingId?: string) {
    await this.checkoutInfoActions.loadCheckoutInfo(bookingId)
  }

  public async setIsDOBTouched(value: boolean) {
    await this.purchaseInfoService.setIsDOBTouched(value)
  }

  public async setIsGenderTouched(value: boolean) {
    await this.purchaseInfoService.setIsGenderTouched(value)
  }

  public async onOpenProfileModal() {
    this.purchaseInfoService.openProfileModal()
  }

  public async closeProfileModal() {
    this.purchaseInfoService.closeProfileModal()
  }

  public isProfileModalOpen() {
    return this.purchaseInfoService.isProfileModalOpen()
  }
}
