import { useTranslation } from 'react-i18next'
import type { TripDetailsHotelSegment } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useHotelFormattedDate } from '../../../../use-formatted-date'
import {
  SegmentContainer,
  SegmentDescriptionContainer,
  List,
  ListItem,
} from '../duplicate-list-styled'

type Props = {
  hotel?: TripDetailsHotelSegment | null
}

export function DuplicateTripHotelSegment({ hotel }: Props) {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'
  const formattedDate = useHotelFormattedDate({
    start: hotel?.checkIn,
    end: hotel?.checkOut,
  })

  if (!hotel || !hotel.checkIn || !hotel.checkOut) {
    return null
  }
  return (
    <SegmentContainer>
      <Block marginRight={13}>
        <Icon name={'hotelPWA'} size={32} />
      </Block>
      <div>
        <Text variant="headline" color="mainText">
          {hotel.name}
        </Text>
        <SegmentDescriptionContainer>
          <List>
            <ListItem>{formattedDate}</ListItem>
            <ListItem>{hotel.address?.city}</ListItem>
            <ListItem>{hotel.roomType}</ListItem>
            <ListItem>
              {t(`${i18Base}Night`, {
                count: hotel.numberOfNights,
              })}
            </ListItem>
          </List>
        </SegmentDescriptionContainer>
      </div>
    </SegmentContainer>
  )
}
