import { CreditCardBrandType, Gender } from '@etta/core/enums'
import { Inject, Service } from '@etta/di'
import { CreditCardTypes, TravelVertical } from '@etta/modules/booking/core/enums'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import type { PurchaseInfoValueObject } from '../../../core/value-objects'
import { PurchaseInfoAdapter } from '../../../infra/purchase-info/'
import { PurchaseInfoStore } from '../stores'

@Service()
export class PurchaseInfoService {
  constructor(
    @Inject()
    private readonly purchaseInfoAdapter: PurchaseInfoAdapter,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly purchaseInfoStore: PurchaseInfoStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  async loadPurchaseInfo() {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      this.setPurchaseInfoForMod2Flow()
      return
    }

    this.purchaseInfoStore.setIsLoading(true)
    this.purchaseInfoStore.setIsError(false)
    this.purchaseInfoStore.setPurchaseInfo(null)

    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.purchaseInfoAdapter.getPurchaseInfo({
      itineraryId,
      forceUpdate: true,
      isCCNumberMasked: true,
    })

    result.match({
      Err: () => {
        this.purchaseInfoStore.setIsLoading(false)
        this.purchaseInfoStore.setIsError(true)
      },
      Ok: (data) => {
        this.purchaseInfoStore.setPurchaseInfo(data)
        this.purchaseInfoStore.setIsLoading(false)
      },
    })
  }

  async loadPostPurchaseInfo(bookingId: string) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      this.setPurchaseInfoForMod2Flow()

      return
    }
    this.purchaseInfoStore.setIsLoading(true)
    this.purchaseInfoStore.setIsError(false)
    this.purchaseInfoStore.setPurchaseInfo(null)

    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.purchaseInfoAdapter.getPostPurchaseInfo({
      itineraryId,
      bookingId,
      forceUpdate: true,
      isCCNumberMasked: true,
    })

    result.match({
      Err: () => {
        this.purchaseInfoStore.setIsLoading(false)
        this.purchaseInfoStore.setIsError(true)
      },
      Ok: (data) => {
        this.purchaseInfoStore.setPurchaseInfo(data)
        this.purchaseInfoStore.setIsLoading(false)
      },
    })
  }

  async setIsDOBTouched(value: boolean) {
    this.purchaseInfoStore.setIsDobTouched(value)
  }
  async setIsGenderTouched(value: boolean) {
    this.purchaseInfoStore.setIsGenderTouched(value)
  }
  async openProfileModal() {
    this.purchaseInfoStore.setIsDobTouched(false)
    this.purchaseInfoStore.setIsGenderTouched(false)
    this.purchaseInfoStore.handleToggleOpen()
  }
  async closeProfileModal() {
    this.purchaseInfoStore.handleToggleClose()
  }
  public isProfileModalOpen() {
    return this.purchaseInfoStore.isToggleOpen()
  }
  async reloadPurchaseInfo() {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      this.setPurchaseInfoForMod2Flow()

      return
    }
    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.purchaseInfoAdapter.getPurchaseInfo({
      itineraryId,
      forceUpdate: true,
      isCCNumberMasked: true,
    })

    result.match({
      Err: () => {},
      Ok: (data) => {
        this.purchaseInfoStore.setPurchaseInfo(data)
      },
    })
  }

  async reloadPostPurchaseInfo(bookingId: string) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      this.setPurchaseInfoForMod2Flow()
      return
    }
    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.purchaseInfoAdapter.getPostPurchaseInfo({
      itineraryId,
      bookingId,
      forceUpdate: true,
      isCCNumberMasked: true,
    })

    result.match({
      Err: () => {},
      Ok: (data) => {
        this.purchaseInfoStore.setPurchaseInfo(data)
      },
    })
  }
  private setPurchaseInfoForMod2Flow() {
    const purchaseInfo: PurchaseInfoValueObject = {
      primaryTraveler: {
        address: {
          city: '',
          countryCode: '',
        },
        dateOfBirth: '',
        email: '',
        firstName: '',
        lastName: '',
        gender: Gender.Male,
        phone: [],
        primary: true,
        options: {
          isAddressChangeable: true,
          isAddressRequired: false,
          isDateOfBirthChangeable: true,
          isDateOfBirthRequired: false,
          isDefaultChangeTravelerOptionEnabled: true,
          isEMailRequired: true,
          isFirstNameRequired: true,
          isGenderChangeable: true,
          isGenderRequired: true,
          isLastNameRequired: true,
          isMiddleNameRequired: false,
          isNameChangeable: true,
          isPassportRequired: false,
          isPhoneNumberRequired: true,
          isSuffixRequired: false,
          isSuffixChangeable: true,
          isTitleChangeable: true,
        },
      },
      additionalTravelers: [],
      dynamicSiteMessages: [],
      billingParameters: [
        {
          creditCards: [
            {
              id: 'test',
              billingId: 'test',
              brandType: CreditCardBrandType.Vi,
              cardNumber: 'XXXXXXXXXXXXXXXX71003',
              label: 'Test',
              name: 'Test',
              expirationDate: '2026-03-31',
              metadata: {
                isEditable: false,
                isBillingAddressRequired: false,
                isRestricted: false,
                type: CreditCardTypes.PersonalCreditCard,
              },
            },
          ],
          metadata: {
            isBillingAddressRequired: false,
            isCvvRequired: false,
            isSingleUseCreditCardAllowed: false,
          },
          travelVertical: TravelVertical.Air,
        },
        {
          creditCards: [
            {
              id: 'test',
              billingId: 'test',
              brandType: CreditCardBrandType.Ax,
              cardNumber: 'XXXXXXXXXXXXXXXX71003',
              label: 'Test',
              name: 'Test',
              expirationDate: '2026-03-31',
              metadata: {
                isEditable: false,
                isBillingAddressRequired: false,
                isRestricted: false,
                type: CreditCardTypes.PersonalCreditCard,
              },
            },
          ],
          metadata: {
            isBillingAddressRequired: false,
            isCvvRequired: false,
            isSingleUseCreditCardAllowed: false,
          },
          travelVertical: TravelVertical.Hotel,
        },
        {
          creditCards: [
            {
              id: 'test',
              billingId: 'test',
              brandType: CreditCardBrandType.Ax,
              cardNumber: 'XXXXXXXXXXXXXXXX71003',
              label: 'Test',
              name: 'Test',
              expirationDate: '2026-03-31',
              metadata: {
                isEditable: false,
                isBillingAddressRequired: false,
                isRestricted: false,
                type: CreditCardTypes.PersonalCreditCard,
              },
            },
          ],
          metadata: {
            isBillingAddressRequired: false,
            isCvvRequired: false,
            isSingleUseCreditCardAllowed: false,
          },
          travelVertical: TravelVertical.Carrental,
        },
      ],
      details: {
        billing: [],
        emergencyContact: {},
        agency: {
          address: {
            city: '',
            countryCode: '',
          },
          id: '',
          name: '',
          phoneNumber: '',
        },
      },
    }
    this.purchaseInfoStore.setPurchaseInfo(purchaseInfo)
    this.purchaseInfoStore.setIsLoading(false)
  }
}
