import { useHotelCancellationLabel } from '@fiji/hooks/use-hotel-cancellation-label'
import type { CancellationPolicy } from '@fiji/graphql/types'
import { CancellationBadge, CancellationDescription } from './cancellation-styled'

type Props = {
  isWrapped?: boolean
  cancellationPolicyDescription?: string[] | null
  cancellationPolicy: CancellationPolicy
}

export function Cancellation({
  cancellationPolicyDescription,
  isWrapped,
  cancellationPolicy,
}: Props) {
  const { cancellationPolicyLabel } = useHotelCancellationLabel({
    cancellationPolicy,
    cancellationPolicyDescription,
  })
  const icon = isWrapped ? 'refundablePWA' : 'refundableFilledPWA'
  const iconColor = 'bodyText1'

  if (!cancellationPolicyLabel) {
    return null
  }

  return (
    <CancellationDescription data-tracking-id="room-cancellation-description" isWrapped={isWrapped}>
      <CancellationBadge
        icon={icon}
        title={cancellationPolicyLabel}
        textColor="bodyText"
        iconColor={iconColor}
      />
    </CancellationDescription>
  )
}
