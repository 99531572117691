import { Modal } from '@etta/ui/modal'
import { RailDetailsLegend } from '@etta/components/rail-details-legend/rail-details-legend'
import { RailDetailsHeader } from '@etta/components/rail-details/rail-details-header'
import { FareBlock } from '@etta/components/rail-details/fare-block'
import type { Props } from '../../types'
import { Container } from './rail-details-content-mobile-styled'

export function RailDetailsContentMobile({
  activeTab,
  actualRailEmission,
  adaptedSegments,
  fareClassLabel,
  faresToShow,
  headerTitle,
  isPreferred,
  handleOpenTicketRestrictions,
  handleOpenPermittedStations,
  onSelectFareSeats,
  onTabChange,
  railLabels,
  railLogo,
  squareRailLogos,
  fareConditions,
  rail,
  railPolicies,
}: Props) {
  return (
    <>
      <Modal.Title withBorder>
        <RailDetailsHeader
          title={headerTitle}
          railLabels={railLabels}
          railLogo={railLogo}
          squareRailLogos={squareRailLogos}
        />
      </Modal.Title>

      <Modal.Body>
        <Container>
          <RailDetailsLegend onTabChange={onTabChange} activeTab={activeTab} rail={rail} />
        </Container>
        <FareBlock
          fareClassLabel={fareClassLabel}
          isPreferred={!!isPreferred}
          faresToShow={faresToShow}
          adaptedSegments={adaptedSegments}
          onSelectFareSeats={onSelectFareSeats}
          onInfoIconClick={handleOpenTicketRestrictions}
          handleOpenPermittedStations={handleOpenPermittedStations}
          fareConditions={fareConditions}
          actualRailEmission={actualRailEmission}
          railPolicies={railPolicies}
          activeTab={activeTab}
        />
      </Modal.Body>
    </>
  )
}
