import { useTranslation } from 'react-i18next'
import type { PropsWithChildren } from 'react'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import type { Rate, SegmentState, TripCost } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { SegmentStatus } from '@etta/components/segment-status/segment-status'
import { TripType, Container } from '../../payment-summary-styled'
import { ChangedSegmentRate } from '../changed-segment-rate'
import { FeesAndTaxes } from '../fees-and-taxes'
import { DueTodayCost } from '../due-today-cost'
import { SectionContainer } from '../section-container'
import { ServiceFee } from '../service-fee'
import { CarRentalSegment } from './car-rental-segment'

type Props = PropsWithChildren<{
  segments?: CarRentalSegmentEntity[]
  segmentState?: SegmentState
  carTripCost?: TripCost
  isPayingNow?: boolean
  previousBookingRate?: Rate | null
  isFromPreviousBooking?: boolean
  isDesktopTripCostSummary?: boolean
  withMarginTop?: boolean
}>

const i18nBase = 'PaymentSummary.'

export function CarRentals({
  segments,
  segmentState,
  carTripCost,
  isPayingNow,
  previousBookingRate,
  children,
  isFromPreviousBooking,
  isDesktopTripCostSummary,
  withMarginTop,
}: Props) {
  const { t } = useTranslation()
  const isLeadingNewSegment = !!(!isFromPreviousBooking && segmentState)

  if (!segments || segments.length === 0) {
    return <></>
  }

  return (
    <SectionContainer isLeadingNewSegment={isLeadingNewSegment} withMarginTop={withMarginTop}>
      <Container>
        <TripType>
          <span aria-label={t(i18nBase + 'CarRental')}>{t(i18nBase + 'CarRental')}</span>
          <SegmentStatus
            segmentState={segmentState}
            isFromPreviousBooking={isFromPreviousBooking}
          />
        </TripType>
        <ChangedSegmentRate
          segmentState={segmentState}
          rate={previousBookingRate}
          isFromPreviousBooking={isFromPreviousBooking}
          type={SegmentType.CarRental}
        />
        {segments.map((segment) => {
          if (!segment) {
            return null
          }

          return (
            <>
              <CarRentalSegment key={segment.carId} segment={segment} />
              <FeesAndTaxes feesAndTaxes={segment.feesAndTaxesRate} />
            </>
          )
        })}
        <ServiceFee serviceFee={carTripCost?.serviceFee} />
      </Container>

      <DueTodayCost
        rate={carTripCost?.total}
        segmentState={segmentState}
        isPayingNow={isPayingNow}
        isDesktopTripCostSummary={isDesktopTripCostSummary}
      />

      {children}
    </SectionContainer>
  )
}
