import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { SegmentStateLabel } from '../../segment-state-label'
import type { Props } from '../types'
import { Inline, Label, EditLink } from './header-desktop-styled'

export function HeaderDesktop({
  segmentState,
  iconName,
  title,
  onEdit,
  areAllPreferencesUnavailable,
  link,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TravelerInfo'

  return (
    <>
      <Inline paddingHorizontal={20} paddingBottom={12}>
        <Icon name={iconName} color="bodyText" />
        <Label aria-label={title} color="bodyText" variant="captionStrongCaps">
          {title}
        </Label>
        {segmentState && <SegmentStateLabel segmentState={segmentState} />}
        {!areAllPreferencesUnavailable && (
          <EditLink aria-label={t(i18Base + '.EditPreferences')} size="small" onClick={onEdit}>
            {link}
          </EditLink>
        )}
      </Inline>
    </>
  )
}
