import { PhotoSize } from '@fiji/graphql/types'
import type { HotelDescription } from '@fiji/graphql/types'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'
import type { HotelEntity } from '@etta/modules/hotel-search/core/entities/hotel.entity'

export function mapHotelDetailsFromHotel({
  hotel,
  checkIn,
  checkOut,
}: {
  hotel: HotelEntity | null
  checkIn: Date
  checkOut: Date
}): HotelDetailsType | null {
  if (!hotel) {
    return null
  }

  const {
    descriptions,
    id,
    rooms,
    isSoldOut,
    exclusiveAmenities,
    hotelReviewsDetailsUrl,
    photos,
    ...restHotel
  } = hotel

  return {
    ...restHotel,
    hotelDescriptions: descriptions?.filter((item): item is HotelDescription => !!item) || [],
    hotelId: id,
    hotelReviewsDetailsUrl: hotelReviewsDetailsUrl || '',
    checkIn: checkIn!.toJSON(),
    checkOut: checkOut!.toJSON(),
    rooms: hotel.rooms || [],
    isSoldOut: !!isSoldOut,
    exclusiveAmenities: exclusiveAmenities || [],
    photos: photos.filter((item) => item.size === PhotoSize.Large),
  }
}
