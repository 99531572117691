import { Container, Description } from './payment-styled'

type Props = {
  title: string
  value?: string
}

export function Payment({ title, value }: Props) {
  if (!value) {
    return null
  }

  return (
    <Container>
      <Description aria-label={title}>{title}</Description>
      <Description aria-label={value}>{value}</Description>
    </Container>
  )
}
