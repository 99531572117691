import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useRideHailSearchContext } from '../../interface'
import type { PageSwitcherRef } from '../page-switcher'
import { PageSwitcher } from '../page-switcher'
import { SelectExpenseCode } from '../select-expense-code'
import { EnterCustomExpenseCode } from '../enter-custom-expense-code'
import { AddExpenseCodeMemo } from '../add-expense-code-memo'
import { ExpenseCodePage } from '../../interface/types'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const ExpenseCodeFlowModal = observer(function ExpenseCodeFlowModal({
  isOpen,
  handleClose,
}: Props) {
  const pageSwitcherRef = useRef<PageSwitcherRef | null>(null)
  const type = useScreenType()
  const modalPosition = type === ScreenType.Mobile ? 'center' : 'right'
  const { expenseFlowStore } = useRideHailSearchContext()

  useEffect(() => {
    pageSwitcherRef.current?.gotoPage(expenseFlowStore.currentPage)
  }, [expenseFlowStore.currentPage])

  useEffect(() => {
    if (expenseFlowStore.shouldCloseExpenseCodeModal) {
      expenseFlowStore.setShouldCloseExpenseCodeModal(false)
    }
  }, [expenseFlowStore, expenseFlowStore.shouldCloseExpenseCodeModal])

  return (
    <Modal isVisible={isOpen} position={modalPosition} handleModalVisibility={handleClose}>
      <Modal.Body>
        <PageSwitcher ref={pageSwitcherRef}>
          <PageSwitcher.Page pageKey={ExpenseCodePage.SelectExpenseCode} transitionIndex={1}>
            <SelectExpenseCode />
          </PageSwitcher.Page>
          <PageSwitcher.Page pageKey={ExpenseCodePage.EnterCustomExpenseCode} transitionIndex={2}>
            <EnterCustomExpenseCode />
          </PageSwitcher.Page>
          <PageSwitcher.Page pageKey={ExpenseCodePage.AddExpenseCode} transitionIndex={3}>
            <AddExpenseCodeMemo />
          </PageSwitcher.Page>
        </PageSwitcher>
      </Modal.Body>
    </Modal>
  )
})
