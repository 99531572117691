import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const GrayDash = styled.hr`
  width: 0;
  height: 100%;
  color: ${({ theme }) => theme.colors.borderDark};
  margin: 0 8px;
`

export const SortBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  gap: 27px;
  align-items: center;
`
