import styled, { css } from 'styled-components'
import type { HeaderLayout } from '../types'

export const CenterSlot = styled.div<{
  layout: HeaderLayout
  isRightSlotExist: boolean
  isLeftSlotExist: boolean
}>`
  min-width: 0;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.isRightSlotExist) {
      return ''
    }

    return css`
      margin-right: 8px;
    `
  }};

  ${(props) => {
    switch (props.layout) {
      case 'seat-map':
        return css`
          flex: auto;
        `
      case 'content-view':
        return css`
          margin-left: 16px;
          margin-bottom: 15px;
          margin-top: ${props.isLeftSlotExist ? 0 : '40px'};
        `
      case 'sub-title':
        return css`
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        `
      case 'aligned':
        return ''
      case 'search-input':
        return ''
    }
  }}
`
