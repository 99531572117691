import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { NDCFareBadge } from '@etta/components/air-badges/components'
import type { LayoutProps } from '../../types'
import { AirBadges } from '../../air-badges'
import { FareBrand, FareAmenities } from '../../branded-fare-details'
import { BaggageInformation } from '../../baggage-information'
import {
  Container,
  Header,
  ServiceClass,
  BadgesContainer,
  Footer,
  ControlLink,
  SelectSeatsTitle,
  SelectSeatsList,
} from './flight-details-fares-mobile-styled'

const i18Base = 'FlightDetailsModal.FaresDetails.'

export function FlightDetailsFaresMobile({
  flight,
  fareAttributes,
  classType,
  cabinClassLabel,
  flightSeatsTitle,
  seatButtonLabel,
  baggageButtonLabel,
  isSeatMapAvailable,
  onSeatMapOpen,
  onBaggageModalOpen,
  baggageInfo,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <ServiceClass aria-label={t(i18Base + 'CabinClass', { class: cabinClassLabel })}>
          {cabinClassLabel}
        </ServiceClass>
        <FareBrand classType={classType} />
        <BadgesContainer>
          {flight.isNDCFare && <NDCFareBadge skin="big-space" />}
          <AirBadges flight={flight} />
          <FareAmenities amenities={fareAttributes} />
        </BadgesContainer>
      </Header>
      <Footer>
        {isSeatMapAvailable && (
          <ControlLink aria-label={t(i18Base + 'SelectSeatsTitle')}>
            {flightSeatsTitle && (
              <>
                <SelectSeatsTitle>{t(i18Base + 'SelectSeatsTitle')}</SelectSeatsTitle>
                <SelectSeatsList data-tracking-id="selected-seats-list">
                  {flightSeatsTitle}
                </SelectSeatsList>
              </>
            )}
            <Link
              aria-label={t(i18Base + 'ChangeSeats', { button: seatButtonLabel })}
              size="small"
              onClick={onSeatMapOpen}>
              {seatButtonLabel}
            </Link>
          </ControlLink>
        )}

        <BaggageInformation
          onBaggageModalOpen={onBaggageModalOpen}
          baggageButtonLabel={baggageButtonLabel}
          baggageInfo={baggageInfo}
        />
      </Footer>
    </Container>
  )
}
