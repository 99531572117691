import { Action, Inject } from '@etta/di'
import { UserProfileService } from '../services/get-user-profile.service'

@Action()
export class UserProfileActions {
  constructor(@Inject() private getUserProfileService: UserProfileService) {}

  async getUserProfileWithAdditionalInfo() {
    await this.getUserProfileService.getUserProfileWithAdditionalInfo()
  }

  async fetchUserProfileWithOpenEnrollmentRegistrationCustomFields() {
    await this.getUserProfileService.fetchUserProfile(true)
  }
}
