import { useEffect, useRef } from 'react'
import { useGlobalModalsContext } from '@etta/modules/global-modals/interface/global-modals.context'
import { getTokenExpireTime } from './get-token-expire-time'
import { useExpirationTimeUpdate } from './use-expiration-time-update'
import { getTimeBeforeShowModal } from './get-time-before-show-modal'
import { UPDATE_MODAL_TIMER, DAY_IN_MS } from './constants'

export function useUpdateSessionModalTimer() {
  const timerToShowModalRef = useRef<number | null>(null)
  const checkerTimerValidityTimer = useRef<number | null>(null)
  const { globalModalsStore } = useGlobalModalsContext()
  const { isOpen, handleOpen, handleClose } = globalModalsStore.sessionModalToggle
  const { isExpirationTimeUpdated, handleSetTokenExpireTime } = useExpirationTimeUpdate()

  useEffect(() => {
    const handleUpdateTimer = () => {
      if (checkerTimerValidityTimer.current) {
        window.clearTimeout(checkerTimerValidityTimer.current)
      }
      checkerTimerValidityTimer.current = window.setTimeout(() => {
        handleUpdateTimer()
      }, UPDATE_MODAL_TIMER)

      if (isExpirationTimeUpdated()) {
        return
      }

      const newTimeBeforeExpired = getTokenExpireTime()
      handleSetTokenExpireTime(newTimeBeforeExpired)

      if (timerToShowModalRef.current) {
        clearTimeout(timerToShowModalRef.current)
      }

      const timeBeforeShowModal = getTimeBeforeShowModal(newTimeBeforeExpired)

      if (!timeBeforeShowModal || timeBeforeShowModal >= DAY_IN_MS) {
        return
      }

      timerToShowModalRef.current = window.setTimeout(() => {
        handleOpen()
      }, timeBeforeShowModal)
    }

    handleUpdateTimer()
  }, [isExpirationTimeUpdated, handleSetTokenExpireTime, handleOpen])

  return {
    modalTimerId: timerToShowModalRef.current,
    isOpen: isOpen,
    handleOpen,
    handleClose,
  }
}
