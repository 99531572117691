import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Screen } from '@etta/ui/screen'
import { RailDeliveryOptionCheckoutWrapper } from '@etta/components/rail-delivery-option/rail-delivery-option-checkout-wrapper'
import { HeaderBlock } from '../../header-block'
import { CarBlock } from '../../car-block'
import { FlightBlock } from '../../flight-block'
import { HotelBlock } from '../../hotel-block'
import { RailBlock } from '../../rail-block'
import { TripTotalBlock } from '../../trip-total-block'
import type { LayoutProps } from '../../types'
import { Footer } from './purchase-reservation-success-mobile-styled'

export function PurchaseReservationSuccessMobile({
  onDone,
  onAnotherTrip,
  onShareTripsDetail,
  carRentals,
  hotels,
  flights,
  dynamicSiteMessagesSlot,
  isPostBooking,
  rail,
  tripTotal,
  travelerEmail,
  isRailEticketDeliveryOption,
  confirmationNumbersData,
  isStationTicketMachineDisabled,
  isTripLoading,
  pnrNumber,
  autoCancellationTime,
  isTripOnHold,
  doneButtonLabel,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Screen>
      <Screen.Container>
        {dynamicSiteMessagesSlot}
        <Block marginHorizontal={24} paddingTop={50}>
          <HeaderBlock
            isPostBooking={isPostBooking}
            onShareTripsDetail={onShareTripsDetail}
            isRailEticket={isRailEticketDeliveryOption}
            isTripLoading={isTripLoading}
            pnrNumber={pnrNumber}
            autoCancellationTime={autoCancellationTime}
            isTripOnHold={isTripOnHold}
          />
          {flights && (
            <FlightBlock
              flights={flights}
              confirmationNumbersData={confirmationNumbersData}
              isTripLoading={isTripLoading}
            />
          )}
          {rail && <RailBlock rail={rail} />}
          {hotels && (
            <HotelBlock
              hotels={hotels}
              confirmationNumbersData={confirmationNumbersData}
              isTripLoading={isTripLoading}
            />
          )}
          {carRentals && (
            <CarBlock
              carRentals={carRentals}
              confirmationNumbersData={confirmationNumbersData}
              isTripLoading={isTripLoading}
            />
          )}
          {tripTotal && <TripTotalBlock tripTotal={tripTotal} />}
        </Block>
        {!!rail && (
          <RailDeliveryOptionCheckoutWrapper
            isStationTicketMachineDisabled={isStationTicketMachineDisabled}
            isEticket={isRailEticketDeliveryOption}
            email={travelerEmail || ''}
            stationName={rail.departureStationName}
            isPurchaseSuccessScreen
          />
        )}
      </Screen.Container>
      <Screen.Footer withBorder backgroundColor="white">
        <Footer>
          <Button
            variant="outline"
            fullWidth
            style={{ marginBottom: 16 }}
            data-tracking-id="book-another-trip-button"
            onClick={onAnotherTrip}>
            {t('PurchaseReservationSuccess.BookAnotherTrip')}
          </Button>
          <Button fullWidth onClick={onDone} data-tracking-id="see-upcoming-trips-button">
            {doneButtonLabel}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
