import { useTranslation } from 'react-i18next'
import { useTripDetails } from '@fiji/hooks/use-trip-details'
import type { FlightSegmentEntity, TrainSegmentEntity } from '@etta/modules/review-trip/core'
import {
  TripReviewDateType,
  getDateRangeTitleWithoutYear,
  isoToDate,
  tripReviewFormatDate,
} from '@fiji/utils/dates'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { useShowDelegateName } from '@fiji/hooks/delegates'
import { SegmentType } from '@etta/core/enums'
import { useSegments } from '../use-payment-summary/use-segments/use-segments'
import type { FlightSegment, HotelSegment, CarRentalSegment, RailSegment } from './types'

type Args = {
  isHoldFlow?: boolean
  isPostBookingFlow?: boolean
}

type GetTripTitleArgs = {
  tripName: string
  isHoldFlow?: boolean
  isPostBookingFlow?: boolean
  travelerName?: string | null
  isShowTravelerName?: boolean
}

const i18nBase = 'PaymentSummary'

export function useBookingAnimationContainer({ isHoldFlow, isPostBookingFlow }: Args) {
  const { t } = useTranslation()
  const { isShowDelegateName } = useShowDelegateName()
  const { tripOverview } = useTripDetails()
  const { getCabinClassLabel } = useCabinClassLabels()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { reviewTripStore, segmentTypeCheckActions } = useReviewTripContext()

  const { flightSegments, hotelSegments, carRentalSegments, railSegments } = useSegments(
    reviewTripStore.trip?.segments ?? [],
  )

  const { isNewLoadingConfirmationEnabled } = featureFlagsStore.flags
  const { tripName, dateRange, travelerName, travelerFullName } = tripOverview
  const { isFlightSegment, isTrainSegment } = segmentTypeCheckActions

  const includeAllSegments = true

  const tripTitle = t(
    ...getTripTitle({
      tripName,
      isHoldFlow,
      isPostBookingFlow,
      travelerName,
      isShowTravelerName: isShowDelegateName,
    }),
  )

  function getHotelDateRange({ startDate, endDate }: { startDate: string; endDate: string }) {
    const dayOfTheWeek = tripReviewFormatDate(TripReviewDateType.DayOfTheWeek, startDate) ?? ''

    const dateRange = getDateRangeTitleWithoutYear({
      startDate: isoToDate(startDate),
      endDate: isoToDate(endDate),
    })

    return `${dayOfTheWeek}, ${dateRange}`
  }

  function getDayMonthDateAndTime(date?: string) {
    return tripReviewFormatDate(TripReviewDateType.DayMonthDateAndTime, date)
  }

  function getDepartureAddress(segment: FlightSegmentEntity | TrainSegmentEntity) {
    if (isFlightSegment(segment)) {
      return segment.segments.at(0)?.departure.airportCode ?? ''
    }

    if (isTrainSegment(segment)) {
      return segment.segments.at(0)?.departure?.stationName ?? ''
    }

    return ''
  }

  function getArrivalAddress(segment: FlightSegmentEntity | TrainSegmentEntity) {
    if (isFlightSegment(segment)) {
      return segment.segments.at(-1)?.arrival?.airportCode ?? ''
    }

    if (isTrainSegment(segment)) {
      return segment.segments.at(-1)?.arrival?.stationName ?? ''
    }

    return ''
  }

  const flights = flightSegments
    .filter(({ segmentState }) => (isPostBookingFlow ? !!segmentState : includeAllSegments))
    .map(
      (flight): FlightSegment => {
        return {
          id: flight.legId,
          segmentState: flight.segmentState,
          departureDateTime: getDayMonthDateAndTime(flight.departureDateTime),
          departureAirportCode: getDepartureAddress(flight),
          arrivalAirportCode: getArrivalAddress(flight),
          cabinClass: getCabinClassLabel(flight.segments.at(0)?.serviceClass),
        }
      },
    )

  const rails = railSegments
    .filter(({ segmentState }) => (isPostBookingFlow ? !!segmentState : includeAllSegments))
    .map(
      (rail): RailSegment => {
        return {
          id: rail.legId,
          segmentState: rail.segmentState,
          departureDateTime: getDayMonthDateAndTime(rail.departureDateTime),
          departureStationName: getDepartureAddress(rail),
          arrivalStationName: getArrivalAddress(rail),
        }
      },
    )

  const hotels = hotelSegments
    .filter(({ segmentState }) => (isPostBookingFlow ? !!segmentState : includeAllSegments))
    .map(
      (hotel): HotelSegment => {
        return {
          id: hotel.id,
          name: hotel.name,
          segmentState: hotel.segmentState,
          dateRange: getHotelDateRange({
            startDate: hotel.checkIn,
            endDate: hotel.checkOut,
          }),
        }
      },
    )

  const carRentals = carRentalSegments
    .filter(({ segmentState }) => (isPostBookingFlow ? !!segmentState : includeAllSegments))
    .map(
      (carRental): CarRentalSegment => {
        return {
          id: carRental.carId,
          vendorName: carRental.vendorDetails.name,
          segmentState: carRental.segmentState,
          pickUpTime: getDayMonthDateAndTime(carRental.pickUpTime),
          dropOffTime: getDayMonthDateAndTime(carRental.dropOffTime),
        }
      },
    )

  const bookingEntitiesKeys = [
    flights.length ? SegmentType.Flight : undefined,
    rails.length ? SegmentType.Train : undefined,
    hotels.length ? SegmentType.Hotel : undefined,
    carRentals.length ? SegmentType.CarRental : undefined,
  ].filter((x): x is SegmentType => x !== undefined)

  return {
    flights,
    rails,
    hotels,
    carRentals,
    tripTitle,
    tripDateRange: dateRange,
    travelerFullName,
    isNewLoadingConfirmationEnabled,
    bookingEntitiesKeys,
  }
}

function getTripTitle({
  tripName,
  travelerName,
  isHoldFlow,
  isShowTravelerName,
  isPostBookingFlow,
}: GetTripTitleArgs) {
  let key = `${i18nBase}.BookingYourTrip`

  if (isShowTravelerName) {
    key = isPostBookingFlow ? `${i18nBase}.DelegateUpdatingTrip` : `${i18nBase}.DelegateBookingTrip`
  }

  if (!isShowTravelerName && isPostBookingFlow) {
    key = `${i18nBase}.UpdatingYourTrip`
  }

  if (isHoldFlow) {
    key = `${i18nBase}.HoldingYourTrip`
  }

  return [key, { tripName, travelerName: isShowTravelerName ? travelerName : undefined }] as const
}
