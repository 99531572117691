import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  flex: 1;
`

export const Section = styled.div`
  margin: 24px 32px;
  position: relative;
`

export const DetailsButton = styled(Button)`
  color: ${({ theme }) => theme.colors.mainText};
  height: 60px;
  margin-bottom: 44px;
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colors.mainText};
  }
`
