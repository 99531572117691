import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { useCreateSearchMutation, useGetHotelNamesLazyQuery } from '@fiji/graphql/hooks'
import { DeviceClass } from '@fiji/graphql/types'
import type { HotelFiltersType } from '@fiji/types'
import type { AppState } from '@fiji/store'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query/use-hotel-search-query'
import type { SelectSearchOptionType } from '@etta/ui/select-search/types'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { getHotelNamesSearchInput, getHotelNamesData, getCreateSearchInput } from './mappers'

type GetNewHotelNamesSearchInputParams = {
  newFilters?: HotelFiltersType
  hotelName?: string
  hotelResultId?: string
}

const START_SEARCH_LENGTH = 3
const PAGE_SIZE = 30

export function useSearchHotelsFilter() {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()

  const { queryParams } = useHotelSearchQuery()
  const [searchText, setSearchText] = useState(hotelFiltersStore.selectedFilters.hotelName)
  const [hotelNames, setHotelNames] = useState<string[]>()
  const [isNoDataError, setNoDataError] = useState(false)
  const [isHotelNamesLoading, setHotelNamesLoading] = useState(false)
  const { isPhoenixHotelSearchEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const setHotelName = useCallback(
    (newHotelName) => {
      hotelFiltersAction.handleSetFilter('hotelName', newHotelName)
    },
    [hotelFiltersAction],
  )

  const handleSelectedItemChange = useCallback(
    (name: string) => {
      setHotelName(name)
      setSearchText(name)
    },
    [setHotelName],
  )

  const handleClearFilter = useCallback(() => {
    hotelFiltersAction.handleSetFilter('hotelName', '')
    setSearchText('')
    setHotelNames([])
  }, [hotelFiltersAction])

  const fetchAutocompletePlacesAbort = useRef<AbortController | null>(null)

  const [createSearch, { error: createSearchError }] = useCreateSearchMutation({
    onCompleted: (data) => {
      handleHotelNamesSearch({
        hotelName: searchText,
        hotelResultId: data.createSearch.hotelResultId,
      })
    },
  })

  const handleCreateSearch = useCallback(async () => {
    if (!isPhoenixHotelSearchEnabled) {
      return
    }
    const controller = new AbortController()
    fetchAutocompletePlacesAbort.current?.abort()

    fetchAutocompletePlacesAbort.current = controller

    await createSearch({
      variables: getCreateSearchInput({
        queryParams,
        filters: hotelFiltersStore.selectedFilters,
        hotelName: searchText,
      }),
      context: {
        queryDeduplication: false,
        fetchOptions: {
          // If we want make request cancellable, we pass AbortController instance to 'controller' property because of apollo-link-timeout
          // https://github.com/drcallaway/apollo-link-timeout/blob/6ffe29f8f86d0946923405d27a8c3b62458d9046/src/timeoutLink.ts#L31
          // It's a bit confusing since apollo-link-http uses 'signal' property and expects AbortController.signal instead whole instance
          // https://github.com/apollographql/apollo-link/blob/eeb1d520b1f6db9f357330eee0baed75f796f1d2/packages/apollo-link-http/src/httpLink.ts#L102
          controller,
        },
      },
    })
  }, [
    isPhoenixHotelSearchEnabled,
    createSearch,
    queryParams,
    hotelFiltersStore.selectedFilters,
    searchText,
  ])

  const [getHotelNamesQuery, { error: hotelNamesError }] = useGetHotelNamesLazyQuery({
    onCompleted: (data) => {
      setHotelNamesLoading(false)
      data.hotelNames && setHotelNames(getHotelNamesData(data))
      hotelNames && setNoDataError(data.hotelNames?.hotelNames.length === 0)
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const getNewHotelSearchInput = useCallback(
    ({ hotelName, hotelResultId }: GetNewHotelNamesSearchInputParams) =>
      getHotelNamesSearchInput({
        queryParams,
        hotelResultId,
        offset: 0,
        pageSize: PAGE_SIZE,
        deviceClass: DeviceClass.Mobile,
        filters: hotelFiltersStore.selectedFilters,
        hotelName,
      }),
    [hotelFiltersStore.selectedFilters, queryParams],
  )

  const handleHotelNamesSearch = useCallback(
    (params: GetNewHotelNamesSearchInputParams) => {
      const controller = new AbortController()
      fetchAutocompletePlacesAbort.current?.abort()

      fetchAutocompletePlacesAbort.current = controller

      if (!params.hotelName?.length) {
        return
      }
      getHotelNamesQuery({
        variables: getNewHotelSearchInput(params),
        context: {
          queryDeduplication: false,
          fetchOptions: {
            // If we want make request cancellable, we pass AbortController instance to 'controller' property because of apollo-link-timeout
            // https://github.com/drcallaway/apollo-link-timeout/blob/6ffe29f8f86d0946923405d27a8c3b62458d9046/src/timeoutLink.ts#L31
            // It's a bit confusing since apollo-link-http uses 'signal' property and expects AbortController.signal instead whole instance
            // https://github.com/apollographql/apollo-link/blob/eeb1d520b1f6db9f357330eee0baed75f796f1d2/packages/apollo-link-http/src/httpLink.ts#L102
            controller,
          },
        },
      })
    },
    [getHotelNamesQuery, getNewHotelSearchInput],
  )

  const handleSearch = useDebouncedCallback(async () => {
    if (isPhoenixHotelSearchEnabled) {
      await handleCreateSearch()
      return
    }

    handleHotelNamesSearch({
      hotelName: searchText,
    })
  }, 500)

  const handleSearchEnter = useCallback(
    (query: string) => {
      setSearchText(query)
      setHotelNames([])

      if (query.length < START_SEARCH_LENGTH) {
        fetchAutocompletePlacesAbort.current?.abort()
        setHotelNamesLoading(false)
        return
      }

      setHotelNamesLoading(true)

      handleSearch()
    },
    [handleSearch],
  )

  const isHotelNamesFetchError = !!hotelNamesError || !!createSearchError || isNoDataError

  const options: SelectSearchOptionType[] = useMemo(() => {
    if (isHotelNamesFetchError) {
      return []
    }
    if (!hotelNames) {
      return []
    }
    return hotelNames.map((value) => {
      return {
        label: value,
        value,
        iconName: 'hotelPWA',
        iconSize: 'medium',
        iconColor: 'primary',
        dataTrackingId: 'search-hotel-mechanism-item',
      }
    })
  }, [hotelNames, isHotelNamesFetchError])

  return {
    options,
    handleClearFilter,
    handleHotelNamesSearch: handleSearchEnter,
    handleSelectedItemChange,
    isLoading: isHotelNamesLoading,
  }
}
