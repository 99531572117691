import { Button } from '@etta/ui/button'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import type { Props } from '../types'
import useDatePickerModal from '../use-date-picker-modal'
import { Container, StyledDatePicker, Footer } from './date-picker-modal-desktop-styled'

export function DatePickerModalDesktop({
  minDate,
  maxDate,
  date,
  dateEnd,
  isRange,
  isOpen,
  onSelect,
  onClose,
  startTitle,
  endTitle,
  withConfirmOneWayButton = true,
  withConfirmButton = false,
  isEndDateSame,
  numberOfMonths,
  isOutsideRange,
  customElementHeight = 465,
  withNextButton = false,
  isDepartureDatePickerDisabled,
}: Props) {
  const {
    selectHandler,
    footerTitle,
    isFooterVisible,
    dates,
    handleDatesSelect,
    ref,
  } = useDatePickerModal({
    date,
    dateEnd,
    onSelect,
    onClose,
    isOpen,
    withConfirmOneWayButton,
    shouldCloseOnClickOutside: true,
    withConfirmButton,
    withNextButton,
    isDepartureDatePickerDisabled,
  })
  useShouldOpenInViewport({
    elementHeight: customElementHeight,
    isVisible: isOpen,
    ref,
  })

  if (!isOpen) {
    return null
  }

  return (
    <Container ref={ref}>
      <StyledDatePicker
        isEndDateSame={isEndDateSame}
        minDate={minDate}
        maxDate={maxDate}
        date={dates.date}
        dateEnd={dates.dateEnd}
        isRange={isRange}
        onSelect={handleDatesSelect}
        startTitle={startTitle}
        endTitle={endTitle}
        numberOfMonths={numberOfMonths}
        isOutsideRange={isOutsideRange}
        isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
        onClose={onClose}
      />

      {isFooterVisible && footerTitle && (
        <Footer>
          <Button size="normal" onClick={selectHandler}>
            {footerTitle}
          </Button>
        </Footer>
      )}
    </Container>
  )
}
