import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../air-seat-map.types'
import { AirSeatMapMobile } from './mobile'
import { AirSeatMapDesktop } from './desktop'

export function AirSeatMapLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<AirSeatMapMobile {...props} />}
      desktopSlot={<AirSeatMapDesktop {...props} />}
    />
  )
}
