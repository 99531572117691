import styled from 'styled-components'
import { title3 } from '@fiji/style'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const Score = styled.span`
  position: absolute;
  top: 30%;

  ${title3}
  font-size: 64px;
`
