import { useEffect, useMemo } from 'react'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { HotelSearchQueryType } from '@fiji/hooks/search-queries/use-hotel-search-query/types'
import { useEffectOnMount } from '@fiji/hooks/use-effect-on-mount'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { redirectParamsOnRefetchFail } from '@fiji/utils/redirect-params-on-refetch-fail'
import { SegmentType } from '@fiji/graphql/types'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ROUTES } from '@fiji/routes'

type Props = {
  queryParams: HotelSearchQueryType
}

export function useFetchHotels({ queryParams }: Props) {
  const { loadHotelsAction, hotelSearchResultsStore } = useHotelSearchResultsContext()
  const { activeTripStore } = useReviewTripContext()
  const { postBookingTripActions } = usePostBookingContext()

  const screenType = useScreenType()
  const { itineraryId, hotelFilters: appliedFilters, bookingId } = queryParams

  const { isEmptyItinerary } = activeTripStore

  const redirectParamsOnFail = useMemo(() => {
    return redirectParamsOnRefetchFail({
      isMobile: screenType === ScreenType.Mobile,
      mobileNextPage: ROUTES.hotel.search,
      hasTrip: !isEmptyItinerary,
      nextPage: ROUTES.explore,
      segmentType: SegmentType.Hotel,
    })
  }, [isEmptyItinerary, screenType])

  const { handleRefetchRequest } = useRefetchRequest({
    refetch: async () => {
      await loadHotelsAction.retryLoadHotels()
    },
    loading: hotelSearchResultsStore.isLoading,
    redirectRouteOnFail: redirectParamsOnFail.page,
    redirectQueryParamsOnFail: redirectParamsOnFail.params,
  })

  const hotelsResult = {
    handleRefetchRequest,
  }

  useEffect(() => {
    if (!hotelSearchResultsStore.isCreateSearchLoading) {
      loadHotelsAction.loadInitialHotels()
    }
  }, [loadHotelsAction, hotelSearchResultsStore])

  useEffectOnMount(() => {
    if (!bookingId) {
      postBookingTripActions.dropTripStore()
      return
    }
    postBookingTripActions.getTripDetails({ id: String(bookingId) })
  })

  return {
    hotelsResult,
    filterOptions: hotelSearchResultsStore.filterOptions,
    appliedFilters,
    itineraryId,
  }
}
