import { useCallback, useMemo, useState } from 'react'
import type { CreditCard } from '@fiji/graphql/types'

type Args = {
  singleTimeUseCard?: Partial<CreditCard>
  billingCreditCards: CreditCard[]
  isSingleUseCreditCardAllowed: boolean
}

const MIN_FILTER_CARDS = 5

export function useCreditCards({
  singleTimeUseCard,
  billingCreditCards,
  isSingleUseCreditCardAllowed,
}: Args) {
  const [filterValue, setFilterString] = useState('')
  const handleChangeFilterValue = useCallback((value) => {
    setFilterString(value)
  }, [])

  const creditCards: Partial<CreditCard>[] = useMemo(() => {
    return singleTimeUseCard ? [singleTimeUseCard, ...billingCreditCards] : billingCreditCards
  }, [singleTimeUseCard, billingCreditCards])

  const creditCardsToUse = useMemo(() => {
    const isThereRestrictedCard = !!creditCards.find((card) => card.metadata?.isRestricted)

    if (isThereRestrictedCard) {
      return creditCards.filter((card) => !card.isSiteCard || card.metadata?.isRestricted)
    }

    return creditCards
  }, [creditCards])

  const filteredCards = useMemo(() => {
    const lowerFilterValue = filterValue.toLowerCase()
    return creditCardsToUse.filter((card) => {
      if (!filterValue) {
        return true
      }
      if (card.cardNumber?.includes(filterValue)) {
        return true
      }
      if (card.label?.toLowerCase().includes(lowerFilterValue)) {
        return true
      }
      return false
    })
  }, [creditCardsToUse, filterValue])

  const canAddNewCreditCard = useMemo(() => {
    const hasMoreOneRestrictedCard =
      creditCards.filter((card) => card.metadata?.isRestricted).length > 1
    return !hasMoreOneRestrictedCard && isSingleUseCreditCardAllowed
  }, [creditCards, isSingleUseCreditCardAllowed])

  const isAddNewCardAvailable = canAddNewCreditCard && !filterValue
  const isFilterAvailable = creditCards.length > MIN_FILTER_CARDS
  const isSearchResultEmpty = creditCardsToUse.length > 0 && filteredCards.length === 0

  return {
    isSearchResultEmpty,
    isFilterAvailable,
    filterValue,
    handleChangeFilterValue,
    isAddNewCardAvailable,
    creditCardsToUse: filteredCards,
  }
}
