import type { ReactNode } from 'react'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { Label, RadioInput, RadioPoint } from './radio-styled'

type Props = {
  name?: string
  value?: string
  className?: string
  'data-tracking-id'?: string
  'data-testvalue'?: string | number
  'aria-label'?: string
  children?: ReactNode
  isChecked: boolean
  onChange: (value?: string) => void
  isDisabled?: boolean
  isHidden?: boolean
  isDisabledAndSelected?: boolean
  tabIndex?: number
}

export function Radio({
  className,
  children,
  isChecked,
  onChange,
  name,
  value,
  isDisabled,
  isHidden,
  isDisabledAndSelected,
  'data-tracking-id': dataTrackingId,
  'data-testvalue': dataTestValue,
  'aria-label': ariaLabel,
  tabIndex = 0,
}: Props) {
  const { handleKeyDown } = useA11yKeydown(onChange)
  return (
    <Label
      tabIndex={-1}
      className={className}
      data-tracking-id={dataTrackingId}
      data-testvalue={dataTestValue}
      isHidden={isHidden}>
      <RadioInput
        tabIndex={-1}
        onChange={() => onChange(value)}
        type="radio"
        checked={isChecked}
        aria-checked={isChecked}
        name={name}
        disabled={isDisabledAndSelected || isDisabled}
        isDisabledAndSelected={isDisabledAndSelected}
        aria-label={ariaLabel}
      />
      <RadioPoint
        role="button"
        aria-hidden
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex}
        isChecked={isChecked}
      />
      {children}
    </Label>
  )
}
