import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type Params = {
  type: 'price-details' | 'price-breakdown'
}

export function usePriceDetailsHeaderDesktop({ type }: Params) {
  const { t } = useTranslation()
  const headerTranslation = useMemo(() => {
    switch (type) {
      case 'price-breakdown':
        return t('TripReview.TripCostSummary.PriceInfo')
      default:
      case 'price-details':
        return t('PriceDetails.Title')
    }
  }, [type, t])

  return {
    title: headerTranslation,
  }
}
