import styled, { css } from 'styled-components'

export const TRANSITION_NAME = 'HIDE-FOOTER'
export const TIMEOUT = 200
const ANIMATION = 'cubic-bezier(0.4, 0, 0.2, 1)'

const hideFooter = css`
  &.${TRANSITION_NAME}-enter-done {
    opacity: 1;
  }
  &.${TRANSITION_NAME}-enter {
    opacity: 0;
  }

  &.${TRANSITION_NAME}-enter-active {
    transition: opacity ${TIMEOUT}ms ${ANIMATION};
    opacity: 1;
  }

  &.${TRANSITION_NAME}-exit-active {
    transition: opacity ${TIMEOUT}ms ${ANIMATION};
    opacity: 0;
  }
`

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  height: 60px;
  ${hideFooter};
`
