import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { SkeletonContainer, Content } from './flight-segment-styled'

type Props = {
  isActive?: boolean
}

export function FlightSegmentSkeleton({ isActive }: Props) {
  return (
    <SkeletonContainer isActive={isActive}>
      <Block marginRight={8}>
        <Skeleton width="24px" height="24px">
          <rect width="24px" height="24px" />
        </Skeleton>
      </Block>
      <Content>
        <Swap
          is={isActive}
          isSlot={
            <Skeleton width="100%" height="48px">
              <rect width="80px" height="15px" />
              <rect y="23" width="176px" height="12px" />
            </Skeleton>
          }>
          <Skeleton width="100%" height="16px">
            <rect width="156px" height="16px" />
          </Skeleton>
        </Swap>
      </Content>
    </SkeletonContainer>
  )
}
