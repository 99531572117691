// eslint-disable-next-line import/no-restricted-paths
import type { Address, AddressInputDto, InputMaybe } from '@fiji/graphql/types'

export function addressMapper(address?: Address | null): InputMaybe<AddressInputDto> | undefined {
  if (!address) {
    return undefined
  }

  const cityInput: AddressInputDto = {
    airportCode: address.airportCode,
    city: address.city || '',
    countryCode: address.countryCode || '',
    locationName: address.locationName,
    postalCode: address.postalCode,
    stateCode: address.stateCode,
    street1: address.street1,
    street2: address.street2,
  }

  if (address.geocode) {
    cityInput.geocode = {
      lat: address.geocode.lat,
      long: address.geocode.long,
    }
  }

  return cityInput
}
