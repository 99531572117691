import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PhotoSize } from '@fiji/graphql/types'
import type { RateQualifier } from '@fiji/graphql/types'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { HotelDetailsType } from '@etta/components/hotel-details-modal'
import { useDeleteHotel } from '@etta/screens/review-trip-page/hooks'
import type { HotelSegmentEntity } from '@etta/modules/review-trip/core'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useTripContent } from '../../use-trip-content'
import { useChangeRoom } from '../../../hotel-details-modal/use-change-hotel'

type Args = {
  segment: HotelSegmentEntity
  isChangeAllowed: boolean
  itineraryId: string
  isTripExist: boolean
}

export function useHotelSegmentRtp({ segment, isChangeAllowed, itineraryId, isTripExist }: Args) {
  const { t } = useTranslation()
  const hotelDetailsToggle = useTogglePopup()
  const changeHotelModalToggle = useTogglePopup()
  const removalDialogToggle = useTogglePopup()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore

  const updateChangeModalOpen = () => {
    changeHotelModalToggle.handleOpen()
  }

  const handleCloseModal = () => {
    hotelDetailsToggle.handleClose()
    changeHotelModalToggle.handleClose()
  }

  const handleChangeHotelLink = isTripExist && isChangeAllowed ? updateChangeModalOpen : undefined
  const hotel = segment
  const checkIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, hotel.checkIn)!
  const checkOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, hotel.checkOut)!
  const numberOfNights = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    hotel.checkOut,
    hotel.checkIn,
  )

  const londDateLabel = `${tripReviewFormatDate(
    TripReviewDateType.DayAndDate,
    hotel.checkIn,
  )} - ${tripReviewFormatDate(TripReviewDateType.DayAndDate, hotel.checkOut)}`

  const { handleChangeRoom } = useChangeRoom({
    itineraryId,
    id: hotel.id,
    checkIn: hotel.checkIn,
    checkOut: hotel.checkOut,
    address: hotel.address,
    handleCloseChangeModal: handleCloseModal,
  })

  const { cardStatus } = useTripContent({ segmentState: hotel.segmentState })

  const {
    handleRemoveHotel,
    viewStateParams: { viewState: removalViewState, title: removalTitle },
  } = useDeleteHotel({ itineraryId, roomKey: hotel.id })

  const handleConfirmRemoveHotel = useCallback(() => {
    handleRemoveHotel()
    removalDialogToggle.handleClose()
  }, [handleRemoveHotel, removalDialogToggle])

  const cardActions = []

  if (!handleChangeHotelLink && !isTripExist && isChangeAllowed) {
    cardActions.push({
      label: t('HotelDetailsModal.ChangeHotel.Title'),
      onClick: changeHotelModalToggle.handleOpen,
    })
  }

  const hotelDetails: HotelDetailsType = {
    hotelId: hotel.hotelId,
    name: hotel.name,
    address: hotel.address,
    amenities: hotel.amenities || [],
    phoneNumber: hotel.phone,
    rooms: [
      {
        id: hotel.id,
        type: hotel.roomType,
        dailyRates: hotel.dailyRates,
        description: hotel.roomDescription || '',
        totalCost: hotel.totalCost,
        feesAndTaxes: hotel.feesAndTaxesRate?.primary || { amount: 0, currency: 'USD' },
        policy: hotel.policy,
        isFreeCancellable: hotel.isCancellable,
        source: hotel.source,
        rateQualifier: hotel.rateQualifier as RateQualifier,
        cancellationPolicy: hotel.cancellationPolicy,
        cancellationPolicyDescription: hotel.cancellationPolicyDescription,
        averageRate: hotel.averageRate || { primary: { amount: 0, currency: 'USD' } },

        // : TODO implement fields after gazoo implements ISLA-66
        isCorporateRate: false,
      },
    ],
    baseRate: hotel.baseRate,
    netRate: hotel.netRate,
    serviceFeeRate: hotel.serviceFeeRate,
    totalCostRate: hotel.totalCostRate,
    feesAndTaxesRate: hotel.feesAndTaxesRate,
    starRating: hotel.starRating || null,
    medianReviewRating: hotel.medianReviewRating || null,
    isPreferred: hotel.isPreferred || null,
    distance: hotel.distance || undefined,
    photos: hotel.photos.filter((item) => item.size === PhotoSize.Large) || [],
    checkIn: hotel.checkIn,
    checkOut: hotel.checkOut,
    hotelDescriptions: [],
    exclusiveAmenities: hotel.exclusiveAmenities,
    segmentState: hotel.segmentState,
    reviews: [],
    totalReviewCount: 0,
    hotelReviewsDetailsUrl: '',
    policy: hotel.policy,
  }

  const shouldShowServiceFeeLabel = flags.isServiceFeeEnabled && !!hotel.serviceFeeRate

  return {
    hotelDetails,
    hotelDetailsToggle,
    hotel,
    checkIn,
    checkOut,
    londDateLabel,
    cardActions,
    numberOfNights,
    changeHotelModalToggle,
    handleChangeHotelLink,
    handleChangeRoom,
    handleCloseModal,
    handleConfirmRemoveHotel,
    cardStatus,
    removalDialogToggle,
    removalViewState,
    removalTitle,
    shouldShowServiceFeeLabel,
  }
}
