import type { LayoutProps } from '../../types'
import { CarRentalDetailsEcoCheckMobileComponent } from './car-rental-details-eco-check-mobile-component'
import { CarRentalDetailsEcoCheckMobileSkeleton } from './car-rental-details-eco-check-mobile-skeleton'

export function CarRentalDetailsEcoCheckMobile({
  loading,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: LayoutProps) {
  if (loading) {
    return <CarRentalDetailsEcoCheckMobileSkeleton />
  }

  if (!tonnesOfEmissions || !averageEmissionsTonnes) {
    return null
  }

  return (
    <CarRentalDetailsEcoCheckMobileComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageEmissionsTonnes={averageEmissionsTonnes}
    />
  )
}
