import { useEffect } from 'react'
import { trackAction } from '@fiji/utils/tracking/track-action'

const ACTION_NAME = 'mobility-where-to-page'

export type Props = {
  isOpen: boolean
}

export function useTrackMobilityWhereTo({ isOpen }: Props) {
  useEffect(() => {
    if (isOpen) {
      trackAction(ACTION_NAME)
    }
  }, [isOpen])
}
