import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { HotelSearchQueryType } from '@fiji/hooks/search-queries'

@Store()
export class HotelQueryStore {
  hotelSearchQueryParams = new QueryParams<HotelSearchQueryType>({}, { caseStyle: 'kebab-case' })

  constructor(@Inject() private historyStore: HistoryStore) {}

  get searchId() {
    return this.hotelSearchQueryParams.getQueryParams(this.historyStore.search).hotelResultId
  }

  get hotelSearchQuery() {
    const {
      location,
      hotelFilters,
      checkInDate,
      checkOutDate,
      postBookingAction,
      itineraryId,
      hotelResultId,
      isSearchByNewArea,
      bookingId,
      transactionGroupId,
    } = this.hotelSearchQueryParams.getQueryParams(this.historyStore.search)
    return {
      location,
      hotelFilters,
      checkInDate,
      checkOutDate,
      postBookingAction,
      itineraryId,
      hotelResultId,
      isSearchByNewArea,
      bookingId,
      transactionGroupId,
    }
  }
}
