import styled, { css } from 'styled-components'
import { buttonSmall, captionStrongCaps } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'

const CLOSED_LABEL_HEIGHT = '1.8rem'

export const PlaceContainer = styled.div<{ isClickable: boolean; isDisabled?: boolean }>`
  display: inline-flex;
  width: 100%;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 10px;
  padding: 16px 10px;
  transition: box-shadow ease 0.5s;
  margin-bottom: 14px;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.08);
      }
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
    `}
`

export const TypeIndicator = styled.div`
  margin-left: -1px;
  margin-top: 0;
`

export const Description = styled.div`
  flex: 1;
  margin-left: 12px;
  padding-top: 3px;
`

export const ClosedLabel = styled.span`
  ${captionStrongCaps};
  display: block;
  height: ${CLOSED_LABEL_HEIGHT};
  color: ${(p) => p.theme.colors.error};
`

export const Name = styled.div`
  ${buttonSmall};
  line-height: 1.46;
  margin-bottom: 6px;
  color: ${(p) => p.theme.colors.mainText};
`

export const Distance = styled.span`
  color: ${(p) => p.theme.colors.bodyText};
  margin-left: 4px;
`

export const LogoContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`

export const LoadingImage = styled.div`
  height: 28px;
  width: 28px;
  margin-right: 3px;
  ${glowingFragment};
`

export const LoadingName = styled.div`
  height: 14px;
  width: 244px;
  margin-bottom: 8px;
  ${glowingFragment};
`

export const LoadingLogos = styled.div`
  height: 14px;
  width: 46px;
  ${glowingFragment};
`
