import type { CovidStatistic, CovidStatisticArea } from '../../types'

type Args = {
  i18nBase: string
  covidStatistic?: CovidStatistic
}

export function useCovidInformationContent({ covidStatistic, i18nBase }: Args) {
  const covidStatisticValues = Object.values(covidStatistic || {}).filter(
    (item): item is CovidStatisticArea => !!item,
  )
  const i18nStatistic = `${i18nBase}.CovidInformationStatistic`
  const isEmpty = !covidStatisticValues.length
  const isManyStatistics = covidStatisticValues.length > 1

  return {
    isEmpty,
    isManyStatistics,
    covidStatisticValues,
    i18nStatistic,
  }
}
