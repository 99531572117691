import type { GetSearchRideHailWithErrorQuery } from '@fiji/graphql/hooks'
import type {
  SearchRideHailResultsValueObject,
  SearchRideHailResultValueObject,
} from '../../core/value-objects'

type GqlSearchRideHailResultInfo = Extract<
  GetSearchRideHailWithErrorQuery['searchRideHailWithError'],
  { results: {} }
>
type GqlSearchRideHailResult = NonNullable<GqlSearchRideHailResultInfo['results'][number]>

export class SearchRideHailMapper {
  static toSearchRideHailResultsValueObject(
    rideHail: GetSearchRideHailWithErrorQuery['searchRideHailWithError'],
  ): SearchRideHailResultsValueObject {
    if (rideHail.__typename === 'SearchRideHailErrorResponse') {
      return {
        results: [],
      }
    }

    const { results } = rideHail

    return {
      results: results.map(SearchRideHailMapper.toSearchRideHailResultValueObject),
    }
  }

  static toSearchRideHailResultValueObject(
    results: GqlSearchRideHailResult,
  ): SearchRideHailResultValueObject {
    return {
      id: results.id,
      displayName: results.displayName,
      estimatedPrice: results.estimatedPrice,
      estimatedRideDuration: results.estimatedRideDuration,
      estimatedWaitingTime: results.estimatedWaitingTime,
      imageUrl: results.imageUrl,
      passengerCapacity: results.passengerCapacity,
      provider: results.provider,
      shortDescription: results.shortDescription,
    }
  }
}
