import { useTranslation } from 'react-i18next'
import { useReviewTripPageContextSelector } from '@etta/screens/review-trip-page/review-trip-page-provider'
import { DesktopNavigation } from '@etta/modules/navigation'
import { DesktopFooter } from '@etta/components/desktop-footer/desktop-footer'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import { LazyContent } from '@etta/ui/lazy-content'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import {
  LeftSide,
  RightSide,
  Title,
  Content,
  Main,
  SubHeader,
  PriceInformation,
} from './review-trip-page-desktop-styled'

export function ReviewTripPageDesktop({
  isLoading,
  scrollContainerRef,
  contentSlot,
  desktopPriceSummarySlot,
}: Omit<LayoutProps, 'mobileHeaderSlot' | 'mobileFooterSlot'>) {
  const { t } = useTranslation()
  const { isHoldTripAllowed } = useReviewTripPageContextSelector((state) => state)

  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation isTripCartDisplayed={false} />
      </ScreenDesktop.Header>
      <LazyContent isLoading={isLoading}>
        <PriceInformation>
          <Icon name="refundableFilledPWA" size="medium" />
          <Text variant="headline">
            {isHoldTripAllowed
              ? t('TripReview.Header.HoldTripPriceInfo')
              : t('TripReview.Header.PriceInfo')}
          </Text>
        </PriceInformation>
        <ScreenDesktop.Content
          as={'main'}
          ref={scrollContainerRef}
          backgroundColor="background"
          maxWidth={1079}>
          <Content>
            <SubHeader isFullWidth isFlexBlock>
              <Title
                as={'h1'}
                aria-label={t('TripReview.Header.ReviewYourTrip')}
                variant="largeTitle"
                color="mainText">
                {t('TripReview.Header.ReviewYourTrip')}
              </Title>
            </SubHeader>
            <Main>
              <LeftSide>{contentSlot}</LeftSide>
              <RightSide>{desktopPriceSummarySlot}</RightSide>
            </Main>
          </Content>
        </ScreenDesktop.Content>
      </LazyContent>
      <DesktopFooter />
    </ScreenDesktop>
  )
}
