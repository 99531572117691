import styled from 'styled-components'

const BADGE_SIZE = '44px'

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Badge = styled.img`
  width: ${BADGE_SIZE};
  height: ${BADGE_SIZE};
`
