import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Swap } from '@etta/ui/swap'
import { ErrorContainer } from '@etta/ui/error-container'
import { LazyContent } from '@etta/ui/lazy-content'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import { useCarRentalRulesContext } from '../../interface/car-rental-rules-modal/use-car-rental-rules.context'
import { RulesModalLayout } from './layout'

type Props = {
  carSegmentId: string
  transactionId: string
  onClose: () => void
}

export const RulesModalContent = observer(({ carSegmentId, transactionId, onClose }: Props) => {
  const { t } = useTranslation()
  const title = t('CarRentalDetailsModal.RulesModal.Title')
  const { carRentalRulesActions, carRentalRulesStore } = useCarRentalRulesContext()

  useEffect(() => {
    carRentalRulesActions.getCarRentalRules(carSegmentId, transactionId)
  }, [carSegmentId, transactionId, carRentalRulesActions])

  return (
    <>
      <InfoModal.Header title={title} onClose={onClose} />
      <Swap
        is={carRentalRulesStore.isError}
        isSlot={
          <ErrorContainer
            onReload={() => carRentalRulesActions.getCarRentalRules(carSegmentId, transactionId)}
          />
        }>
        <LazyContent isLoading={carRentalRulesStore.isLoading}>
          <InfoModal.Content>
            <RulesModalLayout carRules={carRentalRulesStore.carRules} />
          </InfoModal.Content>
        </LazyContent>
      </Swap>
    </>
  )
})
