// Make the nested object fully partial
export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr]
}

const COORDINATE_LOWER_VALUE = -180
const COORDINATE_HIGHER_VALUE = 180

export function isDate(date?: Date | null) {
  return date instanceof Date
}

export function isCoordinate(coordinate?: number | null) {
  if (!coordinate) {
    return false
  }

  return coordinate >= COORDINATE_LOWER_VALUE && coordinate <= COORDINATE_HIGHER_VALUE
}

export function isEmptyString(string?: string | null) {
  if (typeof string !== 'string') {
    return true
  }

  return string.length === 0
}

export function isZeroNumber(number?: number | null) {
  if (typeof number !== 'number') {
    return true
  }

  return number === 0
}
