import styled, { css } from 'styled-components'
import { Link } from '@etta/ui/link'

const transitionRules = css`
  transition: background-color 0.2s, color 0.2s;
  transition-timing-function: ease-out;
`

export const RadioWrapper = styled.div`
  display: flex;
  gap: 30px;
  > * {
    width: auto;
  }
`
export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const FlightTypeItem = styled(Link)<{ isActive?: boolean; isHidden?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.bodyText1)};
  ${transitionRules}
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

  &:hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.mainText};
  }
`

export const WrapperNew = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const LeftSide = styled.div`
  display: flex;
  gap: 16px;
`
export const RightSide = styled.div``
