import styled from 'styled-components'
import { UnstyledButton, getKeyboardNavigationStyle } from '@fiji/style'

export const DelegatesItemContainer = styled(UnstyledButton)<{ onClick?: () => void }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${(p) => p.onClick && 'cursor: pointer;'}

  ${getKeyboardNavigationStyle({ offset: 5, borderRadius: 2 })}
`

export const FlexBlock = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`

export const SpinnerContainer = styled.div`
  width: 32px;
  height: 32px;
`
