import type { FlightLegConnectionInfo, SearchFlightLegSubSegment } from '@fiji/graphql/types'
import { getHoursAndMinutesFromMinutes } from '@fiji/utils/dates'
import { formatTripTime } from '@fiji/utils/format-trip-time'

type Result = {
  code: SearchFlightLegSubSegment['destination']['code']
  name: SearchFlightLegSubSegment['destination']['name']
  flightTime: string
}[]

export function getFlightStops(
  connectionInfo: FlightLegConnectionInfo[],
  segments: SearchFlightLegSubSegment[],
): Result {
  if (segments.length === 1) {
    return []
  }

  return segments.slice(0, -1).map(({ destination: { code, name } }, idx) => ({
    code,
    name,
    flightTime: formatTripTime(
      getHoursAndMinutesFromMinutes(connectionInfo[idx].connectionTimeMinutes),
    ),
  }))
}
