import type { TimeRange, TimeRangeTypeOptions } from '@fiji/types'
import { TimeRangeOption } from '@fiji/enums'

export const airTimeRangeOptions: TimeRangeTypeOptions = {
  [TimeRangeOption.AnyTime]: {
    id: TimeRangeOption.AnyTime,
    i18next: 'FlightSearch.TimePicker.AnyTime',
    startTimeRange: '00:00:00',
    endTimeRange: '23:59:59',
  },
  [TimeRangeOption.Morning]: {
    id: TimeRangeOption.Morning,
    i18next: 'FlightSearch.TimePicker.Morning',
    startTimeRange: '06:00:00',
    endTimeRange: '12:00:00',
    startTimeHours: 6,
    endTimeHours: 12,
  },
  [TimeRangeOption.Afternoon]: {
    id: TimeRangeOption.Afternoon,
    i18next: 'FlightSearch.TimePicker.Afternoon',
    startTimeRange: '12:00:00',
    endTimeRange: '18:00:00',
    startTimeHours: 12,
    endTimeHours: 18,
  },
  [TimeRangeOption.Evening]: {
    id: TimeRangeOption.Evening,
    i18next: 'FlightSearch.TimePicker.Evening',
    startTimeRange: '18:00:00',
    endTimeRange: '23:59:59',
    startTimeHours: 18,
    endTimeHours: 24,
  },
  [TimeRangeOption.CustomTime]: {
    id: TimeRangeOption.CustomTime,
    i18next: 'FlightSearch.TimePicker.CustomTime',
    startTimeRange: '8:00:00',
    endTimeRange: '11:00:00',
    startTimeHours: 8,
    endTimeHours: 11,
    customTimeHours: 9,
  },
}

export const initialCustomTime = airTimeRangeOptions[TimeRangeOption.CustomTime]

export const customOriginTime: TimeRange = {
  ...airTimeRangeOptions[TimeRangeOption.CustomTime],
}

export const customReturnTime: TimeRange = {
  ...airTimeRangeOptions[TimeRangeOption.CustomTime],
  startTimeRange: '16:00:00',
  endTimeRange: '18:00:00',
  startTimeHours: 16,
  endTimeHours: 18,
  customTimeHours: 17,
}

export const anyTimeOption: TimeRange = {
  ...airTimeRangeOptions[TimeRangeOption.AnyTime],
}
