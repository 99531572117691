import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { footnoteMedium, subHeadStrong } from '@fiji/style'

export const ExpenseCodeRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
  cursor: pointer;
`

export const ExpenseReceiptIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  margin-left: 10px;
  flex-shrink: 0;
`

export const ExpenseCodeRowContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-left: 8px;
  padding: 12px 10px 12px 0;
  height: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.background1};
`

export const ExpenseCodeRowContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
`

export const RowContentCode = styled.span`
  color: ${(props) => props.theme.colors.mainText};
  ${subHeadStrong}
  margin-bottom: 1px;
`

export const RowContentDescription = styled.span`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium}
`

export const RowContentCheckedIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 12px;
`
