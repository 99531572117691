/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'

import { Dialog } from '@etta/ui/dialog'
import type { LayoutProps, ValueType } from '../../types'

import { Icon, TextField } from './select-mobile-styled'

import { Option } from './option/option'

const i18nBase = 'Accessibility.Options'

export function SelectMobile<T = ValueType>({
  label,
  options,
  value,
  helperType,
  helperText,
  isDisabled,
  onOptionsDialogOpen,
  optionLabel,
  onChangeHandler,
  isOptionsDialogVisible,
  onOptionsDialogClose,
  isBlurred,
}: LayoutProps<T>) {
  const { t } = useTranslation()

  return (
    <>
      <div aria-label={t(i18nBase + '.SelectReason')} onClick={onOptionsDialogOpen}>
        <TextField
          label={label}
          value={optionLabel}
          onChange={() => {}}
          endSlot={!isDisabled && <Icon name="expandMorePWA" size="medium" color="mainText" />}
          isDisabled={isDisabled}
          helperText={helperText}
          helperType={helperType}
          isBlurred={isBlurred}
        />
      </div>

      <Dialog isOpen={isOptionsDialogVisible} onClose={onOptionsDialogClose} isScrollable>
        {options.map((option, index) => {
          return (
            <Option
              label={option.label}
              isChosen={value === option.value}
              key={index}
              onClick={() => onChangeHandler(option)}
            />
          )
        })}
      </Dialog>
    </>
  )
}
