import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

export const Title = styled.div`
  ${subHeadStrong};
  color: ${(p) => p.theme.colors.mainText};
  margin-bottom: 18px;
`

export const Separator = styled.div`
  width: 100%;
  border-top: 1px dashed ${(p) => p.theme.colors.borderDark};
  margin: 14px 0;
`
