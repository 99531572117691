import type { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { SegmentType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { formatRate } from '@fiji/utils/money/format-rate'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { Tooltip } from '@etta/ui/tooltip'
import { WebFareTooltipContainer } from '@etta/components/air-badges/components/webfare/webfare'
import { useUnusedTicketCostBreakdownContext } from '@etta/modules/unused-ticket-cost-breakdown/use-unused-ticket-cost-breakdown.context'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { RailOpenReturnDetails } from '@etta/components/rail-open-return-details'
import { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import type { RtpItineraryCardProps } from '../../types'
import { RailActions } from '../../rail-actions'
import { RTPItineraryCardBaggageInformation as BaggageInformation } from '../../baggage-information/baggage-information'
import {
  Container,
  Header,
  HeaderDateContainer,
  StyledDate,
  Cost,
  Body,
  Title,
  List,
  ChangeBlock,
  ChangeBlockSeparator,
  ChangeContainer,
  CardStatus,
  CardStatusBlock,
  ChevronIconContainer,
  CostWrapper,
  UnusedTicket,
  DateBox,
  BodyContent,
  UnusedTicketAppliedLabel,
} from './rtp-itinerary-card-mobile-styled'
import { RtpItineraryCardMobileFooter } from './rtp-itinerary-card-mobile-footer'

const i18nBase = 'ReviewTrip'

export const RTPItineraryCardMobile: FunctionComponent<RtpItineraryCardProps> = observer(function ({
  serviceType,
  startDate,
  fullStartDate,
  endDate,
  rate,
  titleSlot,
  informationSlot,
  children,
  onClick,
  outOfPolicyExp,
  isOutOfPolicy = false,
  hasComplianceCode = false,
  isInvalid = false,
  onSelectReason,
  showAddBaggageModal,
  isCheckoutActivated,
  changeLinkText,
  onChange,
  cardStatus = 'default',
  priceChangeType,
  isPriceIncluded = false,
  safetyCheckSlot,
  bodyAriaLabel,
  'data-tracking-id': dataTrackingId,
  railActions,
  strongTextSlot,
  operatedBySlot,
  selectedBaggageOption,
  showLuggageOptions,
  flightLegSegments,
  shouldShowAirportChangeBadge,
  isWebFare,
  isNDCSegment,
  isWarningIcon,
  appliedRailCard,
  isRailOpenReturn,
  openReturnProperties,
}) {
  const { featureFlags } = useFeatureFlags()
  const isPriceInteger = serviceType !== SegmentType.Train
  const { mainCost } = formatRate(rate, { morpheme: 'none', isInteger: isPriceInteger })
  const { reviewTripStore } = useReviewTripContext()
  const rateWithUnusedTicket = rate ? reviewTripStore.getTotalRateWithUnusedTickets(rate) : null
  const { mainCost: fotmattedRateWithUnusedTicket } = formatRate(rateWithUnusedTicket, {
    morpheme: 'none',
    isInteger: isPriceInteger,
  })
  const isUnusedTicketApplicable =
    featureFlags.isUnusedTicketsEnabled && !!reviewTripStore.unusedTicketsRate
  const { t } = useTranslation()
  const iconName = getIconNameBySegment(serviceType)

  // TODO: resolve all conditions of this invalid state
  const isComponentInvalid =
    isInvalid || !!(isOutOfPolicy && !outOfPolicyExp && !hasComplianceCode && isCheckoutActivated)

  const isHeaderHasBorder = cardStatus === 'default' && !priceChangeType
  const isCardChanged = cardStatus === 'changed'
  const isCardAdded = cardStatus === 'added'

  const isShowingCardStatusBlock = priceChangeType || isCardChanged || isCardAdded
  const activeRailActions =
    serviceType !== SegmentType.Train ? [] : railActions?.filter((action) => action.isActive)

  const { unusedTicketCostBreakdownAction } = useUnusedTicketCostBreakdownContext()
  let titleHeader = startDate
  if (isRailOpenReturn) {
    titleHeader += t(`${i18nBase}.OpenReturn.HeaderText`)
  }
  return (
    <Container data-tracking-id={dataTrackingId} hasBorder={isComponentInvalid}>
      {isShowingCardStatusBlock && (
        <CardStatusBlock>
          {isCardChanged && <CardStatus>{t('ReviewTripPWA.Card.Changed')}</CardStatus>}
          {isCardAdded && <CardStatus>{t('ReviewTripPWA.Card.Added')}</CardStatus>}
          {priceChangeType && (
            <CardStatus isHidingBeforeStatus>
              <Swap
                is={priceChangeType === PriceChangeTypeValueObject.Increase}
                isSlot={
                  <>
                    {t('ReviewTripPWA.PriceChange.PriceIncreased')}{' '}
                    <Icon name="arrowUpwardPWA" size="small" />
                  </>
                }>
                <>
                  {t('ReviewTripPWA.PriceChange.PriceDecreased')}{' '}
                  <Icon name="arrowDownwardPWA" size="small" />
                </>
              </Swap>
            </CardStatus>
          )}
        </CardStatusBlock>
      )}
      <Header hasBorder={isHeaderHasBorder} onClick={onClick}>
        <HeaderDateContainer aria-label={`${startDate} - ${endDate}`}>
          {iconName && <Icon name={iconName} color="bodyText" size="medium" />}
          <DateBox data-tracking-id={`${serviceType}-date-text`}>
            {startDate && (
              <StyledDate data-tracking-id={`${serviceType}-start-date`}>
                {titleHeader} {endDate && '-'}
              </StyledDate>
            )}
            {endDate && (
              <StyledDate data-tracking-id={`${serviceType}-end-date`}>{endDate}</StyledDate>
            )}
          </DateBox>
          {isWarningIcon && <Icon name="warningFilledPWA" color="error" />}
        </HeaderDateContainer>
        {isPriceIncluded ? (
          <Text variant="captionStrong" color="bodyText">
            {t(`${i18nBase}.PriceIncluded`)}
          </Text>
        ) : (
          <CostWrapper>
            {isWebFare && (
              <Block marginRight={6}>
                <Tooltip slot={<WebFareTooltipContainer />} place="top" isIndependentOfParentWidth>
                  <Icon name="directBookingIcon" size={25} />
                </Tooltip>
              </Block>
            )}
            {isNDCSegment && (
              <Block marginRight={6}>
                {' '}
                <Icon name="NDCFarePWA" size="small" />{' '}
              </Block>
            )}
            {appliedRailCard && <Icon name="loyaltyCardPWA" size={'small'} color="success" />}
            <Cost
              data-tracking-id="flight-cost-text"
              isCrossedOut={isUnusedTicketApplicable}
              aria-label={mainCost}>
              {mainCost}
            </Cost>
            {isUnusedTicketApplicable && (
              <Cost data-tracking-id="flight-cost-text" aria-label={fotmattedRateWithUnusedTicket}>
                {fotmattedRateWithUnusedTicket}
              </Cost>
            )}
          </CostWrapper>
        )}
      </Header>
      <Body aria-label={bodyAriaLabel} hasBorderRadius={!isOutOfPolicy && !showLuggageOptions}>
        <BodyContent onClick={onClick}>
          {titleSlot && (
            <Title>
              {titleSlot}{' '}
              <ChevronIconContainer>
                <Icon
                  name="chevronRightPWA"
                  color="bodyText"
                  aria-label={t('Accessibility.Header.ArrowRight')}
                />
              </ChevronIconContainer>
            </Title>
          )}
          {safetyCheckSlot && <Block marginBottom={8}>{safetyCheckSlot}</Block>}
          {strongTextSlot && (
            <Block marginBottom={3}>
              <Text variant="footnoteStrong" color="mainText">
                {strongTextSlot}
              </Text>
            </Block>
          )}
          <List>{informationSlot}</List>
          {operatedBySlot && <List>{operatedBySlot}</List>}
          {!isRailOpenReturn && <List>{children}</List>}
          {isRailOpenReturn && <RailOpenReturnDetails {...openReturnProperties} />}
        </BodyContent>
        {activeRailActions?.map((action) => (
          <RailActions key={action.key} action={action} />
        ))}
        {shouldShowAirportChangeBadge && flightLegSegments?.length && (
          <Block marginTop={8}>
            <AirportChangeBadge flightLegSegments={flightLegSegments} size="small" />
          </Block>
        )}
        {fullStartDate && isFuture(fullStartDate) && onChange && changeLinkText && (
          <ChangeContainer role="button" onClick={onChange}>
            <ChangeBlockSeparator />
            <ChangeBlock>
              <Link data-tracking-id="rtp-itinerary-card-change-link">{changeLinkText}</Link>
              <Icon
                name="chevronRightPWA"
                color="bodyText"
                aria-label={t('Accessibility.Header.ArrowRight')}
              />
            </ChangeBlock>
          </ChangeContainer>
        )}
        {isUnusedTicketApplicable && (
          <UnusedTicket
            onClick={() =>
              unusedTicketCostBreakdownAction.handleOpen(reviewTripStore.unusedTicket)
            }>
            <Icon name="unusedTicketPWA" color="success" />
            <UnusedTicketAppliedLabel aria-label={t(`${i18nBase}.UnusedTicketsApplied`)}>
              {t(`${i18nBase}.UnusedTicketsApplied`)}
            </UnusedTicketAppliedLabel>
          </UnusedTicket>
        )}
      </Body>

      <BaggageInformation flightLegSegments={flightLegSegments} />

      <RtpItineraryCardMobileFooter
        showLuggageOptions={showLuggageOptions}
        isOutOfPolicy={isOutOfPolicy}
        selectedBaggageOption={selectedBaggageOption}
        showAddBaggageModal={showAddBaggageModal}
        isComponentInvalid={isComponentInvalid}
        outOfPolicyExp={outOfPolicyExp}
        onSelectReason={onSelectReason}
        hasComplianceCode={hasComplianceCode}
      />
    </Container>
  )
})
