import { useMemo } from 'react'
import { Block } from '@etta/ui/block'
import { TextFieldGroup, Header } from '../custom-field-styled'
import { getLabel } from './get-label'
import type { Props } from './types'
import { getGroupedCustomFields } from './get-grouped-custom-fields'
import { useRenderCustomFields } from './use-render-custom-fields'

export function ComputeFields({ fields, onChange, values, errors }: Props) {
  const { renderFunctions } = useRenderCustomFields({ values, errors, onChange })

  const Component = useMemo(() => {
    const customFields = getGroupedCustomFields({ fields })

    return customFields.map(({ fields, type, header }) => {
      return (
        <TextFieldGroup hasMarginBottom={!!header}>
          {!!header && (
            <Block>
              <Header>{header}</Header>
            </Block>
          )}
          {fields.map((field) => {
            const label = getLabel(field)
            return renderFunctions[type](field, label)
          })}
        </TextFieldGroup>
      )
    })
  }, [fields, renderFunctions])

  return <>{Component}</>
}
