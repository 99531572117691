/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { getResults, getFocusedInput } from '@fiji/hooks/use-date-picker'
import type { ScreenType } from '@fiji/modes'
import { DatePickerContainer } from './date-picker-componets'
import Controls from './controls'
import Calendar from './calendar'

type Props = {
  date?: Date | null
  dateEnd?: Date | null
  minDate?: Date
  maxDate?: Date
  isEndDateSame?: boolean
  className?: string
  isRange?: boolean
  onSelect: (result: Dates) => void
  startTitle?: string
  endTitle?: string
  highlightDates?: (Date | undefined)[]
  numberOfMonths?: number
  isOutsideRange?: () => boolean
  forceScreenType?: null | ScreenType
  dateTitleFormat?: string
  isShowCalendarIcon?: boolean
  isDepartureDatePickerDisabled?: boolean
  onClose?: () => void
}

export function DatePicker({
  className,
  date,
  dateEnd,
  onSelect,
  isRange,
  minDate,
  maxDate,
  startTitle,
  endTitle,
  isEndDateSame,
  highlightDates,
  numberOfMonths,
  isOutsideRange,
  forceScreenType,
  dateTitleFormat,
  isShowCalendarIcon,
  isDepartureDatePickerDisabled,
}: Props) {
  const focusedInput = getFocusedInput({ date, dateEnd, isRange, isDepartureDatePickerDisabled })
  const handleDaySelect = useCallback(
    (dates: Dates) => {
      const results = getResults(dates, !!isRange, focusedInput)
      onSelect(results)
    },
    [onSelect],
  )

  return (
    <DatePickerContainer className={className}>
      <Controls
        focusedInput={focusedInput}
        date={date}
        dateEnd={dateEnd}
        isRange={isRange}
        startTitle={startTitle}
        endTitle={endTitle}
        forceScreenType={forceScreenType}
        dateTitleFormat={dateTitleFormat}
        isShowCalendarIcon={isShowCalendarIcon}
      />
      <Calendar
        isEndDateSame={isEndDateSame}
        date={date}
        dateEnd={isRange ? dateEnd : null}
        minDate={minDate}
        maxDate={maxDate}
        onDaySelect={handleDaySelect}
        focusedInput={focusedInput}
        highlightDates={highlightDates}
        numberOfMonths={numberOfMonths}
        isOutsideRange={isOutsideRange}
        forceScreenType={forceScreenType}
      />
    </DatePickerContainer>
  )
}
