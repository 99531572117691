import { Modal } from '@etta/ui/modal'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { EditMode } from '../layout/desktop/edit-mode/edit-mode'

type Props = {
  isOpen: boolean
}

export function EditRailSearchModal({ isOpen }: Props) {
  const { railSearchActions } = useRailContext()

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={railSearchActions.handleEditRailSearchModalClose}
      position="top"
      disableOverflow={false}>
      <SearchResultsHeaderFull.Container
        isEditOpen
        viewSlot={null}
        editSlot={<EditMode onClose={railSearchActions.handleConfirmEditSearch} />}
      />
    </Modal>
  )
}
