import { CarRentalType } from '@fiji/graphql/types'
import imgFilterTypeCars from './assets/filter-type-cars.png'
import imgFilterTypeSuv from './assets/filter-type-suv.png'
import imgFilterTypeVan from './assets/filter-type-van.png'
import imgFilterTypePickup from './assets/filter-type-pickup.png'

export const imageByFilterType = {
  [CarRentalType.Car]: imgFilterTypeCars,
  [CarRentalType.Suv]: imgFilterTypeSuv,
  [CarRentalType.Van]: imgFilterTypeVan,
  [CarRentalType.Pickup]: imgFilterTypePickup,
}

export const FILTER_I18N_KEYS: { [key: string]: string } = {
  CAR: 'CarRentalTypeFilterBar.Cars',
  VAN: 'CarRentalTypeFilterBar.Van',
  SUV: 'CarRentalTypeFilterBar.SUV',
  PICKUP: 'CarRentalTypeFilterBar.Pickup',
}
