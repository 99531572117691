import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DelegateModal } from '@etta/components/delegate-modal/delegate-modal'
import { DelegateNotch } from '@etta/components/delegate-notch/delegate-notch'
import { Icon } from '@etta/ui/icon'
import type { DelegateSwitchDesktopLayoutProps } from '../../types'
import { DelegateToggle } from './delegate-switch-desktop.styled'
import { DelegateExitDialog } from './delegate-exit-dialog'

export function DelegateSwitchDesktop({
  as,
  isDelegatesExist,
  isDelegateExitModalOpen,
  isUserInfoUpdating,
  isUserInDelegatedMode,
  isDelegateModalOpen,
  handleDelegateModalOpen,
  handleSelectDelegateDesktop,
  handleExitFromDelegateMode,
  handleDelegateModalClose,
  handleDelegateExitModalClose,
  handleDelegateExitModalOpen,
  delegatedUsersWithoutCustomer,
  fullName,
  delegateGuestTitle,
}: DelegateSwitchDesktopLayoutProps) {
  const { t } = useTranslation()

  const Toggle = useMemo(() => {
    if (as !== undefined) {
      return as
    }

    return (
      <DelegateToggle
        aria-label={t('Delegate.DelegateSwitchLabel', { name: fullName })}
        isVisible={!isUserInDelegatedMode}
        leftIcon={<Icon name="peopleNewFieldPWA" />}
        onClick={handleDelegateModalOpen}
        btnType="secondary"
        variant="outline">
        {t('TripPlanner.MainNavigation.DelegateSwitch')}
      </DelegateToggle>
    )
  }, [as, fullName, handleDelegateModalOpen, isUserInDelegatedMode, t])

  if (!isDelegatesExist) {
    return null
  }

  return (
    <>
      {Toggle}

      <DelegateNotch
        isVisible={isUserInDelegatedMode}
        delegatedName={fullName}
        delegateGuestTitle={delegateGuestTitle}
        onChange={handleDelegateModalOpen}
        onExit={handleDelegateExitModalOpen}
      />

      <DelegateExitDialog
        isOpen={isDelegateExitModalOpen}
        isLoading={isUserInfoUpdating}
        onConfirm={handleExitFromDelegateMode}
        onClose={handleDelegateExitModalClose}
      />

      <DelegateModal
        isLoading={isUserInfoUpdating}
        delegates={delegatedUsersWithoutCustomer}
        isModalVisible={isDelegateModalOpen}
        handleClose={handleDelegateModalClose}
        handleSelectDelegate={handleSelectDelegateDesktop}
      />
    </>
  )
}
