import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import {
  AddPaymentCardDocument,
  ChangePasswordDocument,
  GetPasswordSecurityConfigureDocument,
  GetSitesWithActiveUserAccountsDocument,
  GetUserDocument,
  SetPasswordDocument,
  UpdateUserDocument,
} from '@fiji/graphql/hooks'
import type {
  GetUserQueryVariables,
  GetUserQuery,
  UpdateUserMutationVariables,
  UpdateUserMutation,
  GetPasswordSecurityConfigureQuery,
  GetPasswordSecurityConfigureQueryVariables,
  SetPasswordMutation,
  SetPasswordMutationVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  GetSitesWithActiveUserAccountsQuery,
  GetSitesWithActiveUserAccountsQueryVariables,
  AddPaymentCardMutationVariables,
  AddPaymentCardMutation,
} from '@fiji/graphql/hooks'

@DataProvider()
export class UserDataProvider extends GraphqlDataProvider {
  addPaymentCard(input: AddPaymentCardMutationVariables['input']) {
    return this.client.mutate<AddPaymentCardMutation, AddPaymentCardMutationVariables>({
      mutation: AddPaymentCardDocument,
      variables: {
        input,
      },
    })
  }

  setUserPassword(input: SetPasswordMutationVariables['input']) {
    return this.client.mutate<SetPasswordMutation, SetPasswordMutationVariables>({
      mutation: SetPasswordDocument,
      variables: { input },
    })
  }

  changePassword(input: ChangePasswordMutationVariables['input']) {
    return this.client.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
      mutation: ChangePasswordDocument,
      variables: {
        input,
      },
    })
  }

  getSitesByEmail(input: GetSitesWithActiveUserAccountsQueryVariables['input']) {
    return this.client.query<
      GetSitesWithActiveUserAccountsQuery,
      GetSitesWithActiveUserAccountsQueryVariables
    >({
      query: GetSitesWithActiveUserAccountsDocument,
      variables: {
        input,
      },
      fetchPolicy: 'cache-first',
    })
  }

  getUserPasswordSecurityConfigure() {
    return this.client.query<
      GetPasswordSecurityConfigureQuery,
      GetPasswordSecurityConfigureQueryVariables
    >({
      query: GetPasswordSecurityConfigureDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })
  }

  getUser() {
    return this.client.query<GetUserQuery, GetUserQueryVariables>({
      query: GetUserDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })
  }

  updateUser(input: UpdateUserMutationVariables['input']) {
    return this.client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
      mutation: UpdateUserDocument,
      variables: {
        input,
      },
    })
  }
}
