import { useRef } from 'react'
import { getTokenExpireTime } from './get-token-expire-time'

export function useExpirationTimeUpdate() {
  const tokenExpireTimeRef = useRef<number | null>(null)

  function handleSetTokenExpireTime(expireTime: number) {
    tokenExpireTimeRef.current = expireTime
  }

  function isExpirationTimeUpdated() {
    if (!tokenExpireTimeRef.current) {
      return false
    }
    const currentTokenExpirationTime = getTokenExpireTime()

    return currentTokenExpirationTime === tokenExpireTimeRef.current
  }

  return {
    tokenExpireTime: tokenExpireTimeRef.current,
    handleSetTokenExpireTime,
    isExpirationTimeUpdated,
  }
}
