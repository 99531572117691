import { observer } from 'mobx-react-lite'
import { UserInfoFormLayout } from './layout'
import type { Props } from './types'
import { useUserInfoForm } from './use-user-info-form'

export const UserInfoForm = observer(function UserInfoForm({
  personalInfo,
  onSubmit,
  isAir,
  isKtnHidden,
  isRedressNumber,
  travelerOptions,
  preferenceExcerpt,
  primaryTraveler,
  profileOptions,
  onClose,
  isRail,
  isPostPurchase,
}: Props) {
  const { componentProps } = useUserInfoForm({
    personalInfo,
    onSubmit,
    travelerOptions,
    preferenceExcerpt,
    primaryTraveler,
    profileOptions,
    isAir,
    isRail,
    isPostPurchase,
    isKtnHidden,
    isRedressNumber,
    onClose,
  })

  return <UserInfoFormLayout {...componentProps} />
})
