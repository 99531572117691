import styled from 'styled-components'
import { UnstyledButton, subHeadMedium } from '@fiji/style'
import { HORIZONTAL_PADDING } from '../../../constants'

export const Container = styled.div`
  padding: ${HORIZONTAL_PADDING}px;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  border-left: 0;
  border-right: 0;
`

type TitleProps = {
  disabled: boolean
}

export const Title = styled(UnstyledButton)<TitleProps>`
  ${subHeadMedium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${(p) => p.theme.colors.mainText};
  background: 0;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`
