import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import {
  Wrapper,
  DatesParamsBlock,
  LocationsParamsBlock,
  InfoBlock,
  IconContainer,
} from './selected-search-parameters-block-styled'
import type { Props } from './types'

const i18nBase = 'SearchSegments.Header'

export function SelectedSearchParametersBlock({
  onLabelClick,
  isDisabled = false,
  locationsLabel,
  locationsSlot,
  datesLabel,
  withIcon,
}: Props) {
  const { t } = useTranslation()

  return (
    <Wrapper
      tabIndex={0}
      role="button"
      onClick={isDisabled ? undefined : onLabelClick}
      aria-label={t(`${i18nBase}.EditSearch`)}
      data-tracking-id="result-header-button"
      isDisabled={isDisabled}>
      {withIcon && (
        <IconContainer>
          <Icon name="searchPWA" size="medium" />
        </IconContainer>
      )}
      <InfoBlock>
        <LocationsParamsBlock
          data-tracking-id="result-header-origin-destination-block"
          isDisabled={isDisabled}>
          {locationsSlot || locationsLabel}
        </LocationsParamsBlock>
        <DatesParamsBlock
          data-tracking-id="result-header-date-block"
          isDisabled={isDisabled}
          aria-label={datesLabel}>
          {datesLabel}
        </DatesParamsBlock>
      </InfoBlock>
    </Wrapper>
  )
}
