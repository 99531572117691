import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 392px;
  padding-top: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 100;
`
