import { useTranslation } from 'react-i18next'
import { CarRentalSafetyCheckMessage } from '@fiji/graphql/types'
import AlamoImage from './assets/alamo.png'
import EnterpriseImage from './assets/enterprise.png'
import NationalImage from './assets/national.png'

type Args = {
  vendorName?: string | null
  vendorCode?: string | null
  type?: CarRentalSafetyCheckMessage | null
}

export function useCarSafetyCheck({ vendorCode, type, vendorName }: Args) {
  const { t } = useTranslation()
  const translatePath = 'SearchSegments.CarRental.SafetyCheck.'
  const isExtendedType = type === CarRentalSafetyCheckMessage.Extended
  const { translateKey, logo } = getTraslateKeyAndLogoByVendorCode(vendorCode)
  let title = `${vendorName ? `${vendorName} ` : ''}${t(translatePath + 'BasicTitle')}`
  if (isExtendedType && translateKey) {
    title = t(translatePath + translateKey)
  }
  const mainText = t(translatePath + (isExtendedType ? 'ExtendedText' : 'BasicText'))
  const shortMainText = t(translatePath + (isExtendedType ? 'ExtendedTextShort' : 'BasicTextShort'))

  return {
    title,
    mainText,
    shortMainText,
    logo,
    isExtendedType,
  }
}

const getTraslateKeyAndLogoByVendorCode = (vendorCode?: string | null) => {
  switch (vendorCode) {
    case 'ET':
      return {
        translateKey: 'ExtendedTitleEnterprise',
        logo: EnterpriseImage,
      }
    case 'ZL':
      return {
        translateKey: 'ExtendedTitleNational',
        logo: NationalImage,
      }
    case 'AL':
      return {
        translateKey: 'ExtendedTitleAlamo',
        logo: AlamoImage,
      }
    default:
      return {
        translateKey: null,
        logo: null,
      }
  }
}
