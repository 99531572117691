import { useParams } from 'react-router-dom'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'

export function useItinerary() {
  const {
    queryParams: { bookingId },
  } = useQueryParams<{ bookingId?: string }>()

  const { itineraryId } = useParams<{ itineraryId: string }>()
  return {
    itineraryId,
    bookingId: bookingId ? String(bookingId) : undefined,
  }
}
