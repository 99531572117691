import styled from 'styled-components'

export const TitleWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 40px auto 0;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 40px;
`

export const ModalContent = styled.div`
  padding-bottom: 56px;
  margin-top: -12px;
`
