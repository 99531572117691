import { toJS } from 'mobx'
import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type {
  ChangeableFilters,
  ChangeableFiltersHandlers,
  SelectedFiltersValueObject,
} from '../../core/value-objects/selected-filters.value-object'
import { HotelSearchFormQueryService } from '../services/hotel-search-form-query.service'
import { DEFAULT_DISTANCE_VALUE, DEFAULT_HOTEL_FILTERS } from './constants'
import { HotelSearchResultsStore } from './hotel-search-results.store'
import { HotelQueryStore } from './hotel-search-query.store'
import { HotelSearchFormStore } from './hotel-search-form.store'

@Store()
export class HotelFiltersStore {
  constructor(
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
  ) {}

  filterModal = new Toggle()

  selectedFilters: SelectedFiltersValueObject = {
    hotelName: '',
    distance: DEFAULT_DISTANCE_VALUE,
    starRatings: [],
    brandIds: [],
    amenityIds: [],
    groupId: '',
    initialDistance: DEFAULT_DISTANCE_VALUE,
  }

  get numberOfAppliedFilters() {
    const isStarRatingApplied = !checkIsEqual(
      toJS(this.hotelQueryStore.hotelSearchQuery.hotelFilters?.starRatings),
      [],
    )
    const isBrandsApplied = !checkIsEqual(
      toJS(this.hotelQueryStore.hotelSearchQuery.hotelFilters?.brandIds),
      [],
    )
    const isAmenitiesApplied = !checkIsEqual(
      toJS(this.hotelQueryStore.hotelSearchQuery.hotelFilters?.amenityIds),
      [],
    )
    const isGroupIdApplied = !checkIsEqual(
      toJS(this.hotelQueryStore.hotelSearchQuery.hotelFilters?.groupId),
      this.hotelSearchResultsStore.initialGroupId,
    )
    const isDistanceApplied = !checkIsEqual(
      toJS(this.hotelQueryStore.hotelSearchQuery.hotelFilters?.distance),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.initialDistance,
    )

    return [
      isStarRatingApplied,
      isBrandsApplied,
      isAmenitiesApplied,
      isGroupIdApplied,
      isDistanceApplied,
    ].filter(Boolean).length
  }

  get isFiltersEqual() {
    return checkIsEqual(
      toJS(this.selectedFilters),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters,
    )
  }

  get isClearButtonDisabled() {
    return checkIsEqual(toJS(this.selectedFilters), {
      ...DEFAULT_HOTEL_FILTERS,
      distance:
        this.hotelQueryStore.hotelSearchQuery.hotelFilters?.initialDistance ??
        DEFAULT_HOTEL_FILTERS.initialDistance,
      groupId: this.hotelSearchResultsStore.initialGroupId,
      initialDistance:
        this.hotelQueryStore.hotelSearchQuery.hotelFilters?.initialDistance ??
        DEFAULT_HOTEL_FILTERS.initialDistance,
    })
  }

  get isFiltersActive() {
    return !checkIsEqual(this.hotelQueryStore.hotelSearchQuery.hotelFilters, {
      ...DEFAULT_HOTEL_FILTERS,
      distance:
        this.hotelQueryStore.hotelSearchQuery.hotelFilters?.initialDistance ??
        DEFAULT_HOTEL_FILTERS.initialDistance,
      groupId: this.hotelSearchResultsStore.initialGroupId,
      initialDistance:
        this.hotelQueryStore.hotelSearchQuery.hotelFilters?.initialDistance ??
        DEFAULT_HOTEL_FILTERS.initialDistance,
    })
  }

  dropFiltersToInitial() {
    this.selectedFilters = {
      brandIds: [],
      distance:
        this.hotelSearchFormStore.hotelEditCache.distance || DEFAULT_HOTEL_FILTERS.initialDistance,
      hotelName: '',
      starRatings: [],
      amenityIds: [],
      groupId: this.hotelSearchResultsStore.initialGroupId,
      initialDistance:
        this.hotelSearchFormStore.hotelEditCache.distance || DEFAULT_HOTEL_FILTERS.initialDistance,
    }
  }

  async dropFiltersWithQueryUpdate() {
    this.dropFiltersToInitial()

    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.appendQueryParams({
          hotelFilters: this.selectedFilters,
        }),
      )
    })
  }

  isStarRatingSelected(value: number) {
    return this.selectedFilters.starRatings.includes(value)
  }

  isBrandIdSelected(value: string) {
    return this.selectedFilters.brandIds.includes(value)
  }

  isAmenityIdsSelected(value: string) {
    return this.selectedFilters.amenityIds.includes(value)
  }

  isGroupIdSelected(value: string) {
    return this.selectedFilters.groupId === value
  }

  setBrandIds(value: string[]) {
    this.selectedFilters.brandIds = value
  }

  setFilters(filters: SelectedFiltersValueObject) {
    this.selectedFilters = filters
  }

  dropStarRating() {
    this.selectedFilters.starRatings = []
  }

  dropBrandIds() {
    this.selectedFilters.brandIds = []
  }

  dropAmenities() {
    this.selectedFilters.amenityIds = []
  }

  dropGroupId() {
    this.selectedFilters.groupId = this.hotelSearchResultsStore.initialGroupId
  }

  handlers: ChangeableFiltersHandlers<keyof ChangeableFilters> = {
    hotelName: this.setHotelName.bind(this),
    distance: this.setDistance.bind(this),
    starRatings: this.setStartRating.bind(this),
    amenityIds: this.setAmenityId.bind(this),
    groupId: this.setGroupId.bind(this),
  }

  private setHotelName(value: string) {
    this.selectedFilters.hotelName = value
  }

  private setDistance(value: number) {
    this.selectedFilters.distance = value
  }

  private setStartRating(value: number) {
    if (this.isStarRatingSelected(value)) {
      this.selectedFilters.starRatings = this.selectedFilters.starRatings.filter(
        (starRating) => starRating !== value,
      )
      return
    }
    this.selectedFilters.starRatings = [...this.selectedFilters.starRatings, value]
  }

  private setAmenityId(value: string) {
    if (this.isAmenityIdsSelected(value)) {
      this.selectedFilters.amenityIds = this.selectedFilters.amenityIds.filter(
        (amenity) => amenity !== value,
      )
      return
    }
    this.selectedFilters.amenityIds = [...this.selectedFilters.amenityIds, value]
  }

  private setGroupId(value: string) {
    this.selectedFilters.groupId = value
  }
}
