import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { Skin } from '../types'
import { ContentWrapper } from './warning-cancel-round-flight-modal-styled'

type Props = {
  skin?: Skin
}

const i18nBase = 'PostBooking.TripDetails.WarningCancelRoundFlightModal.'
const i18nBaseAccessibility = 'Accessibility.'

export const WarningCancelRoundFlightModal = observer(function WarningCancelRoundFlightModal({
  skin = 'full-screen',
}: Props) {
  const { postBookingCancellationStore, postBookingCancellationActions } = usePostBookingContext()
  const { cancelFlightsWarningToggle, cancelTripToggle } = postBookingCancellationStore
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isDesktop = screenType === ScreenType.Desktop
  const ctaSpacingTop = skin === 'sidebar' ? 14 : 20

  const handleCancelAllFlights = () => {
    cancelFlightsWarningToggle.handleClose()
    cancelTripToggle.handleOpen()
  }

  const handleCloseModal = () => {
    cancelFlightsWarningToggle.handleClose()
    postBookingCancellationActions.handleRemoveCancellationSegment()
  }

  return (
    <Dialog
      onClose={cancelFlightsWarningToggle.handleClose}
      isOpen={cancelFlightsWarningToggle.isOpen}
      horizontalDimension={skin === 'sidebar' ? 'narrow-desktop' : undefined}>
      <ContentWrapper isDesktop={isDesktop}>
        <Text variant="title3" color="mainText" aria-label={t(i18nBase + 'CancelFlights')}>
          {t(i18nBase + 'CancelFlights')}
        </Text>
        <Block marginTop={24}>
          <Text
            variant="footnoteMedium"
            color="bodyText"
            align="center"
            aria-label={t('WarningMessage')}>
            {t(i18nBase + 'WarningMessage')}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          <Button
            variant="solid"
            onClick={handleCancelAllFlights}
            fullWidth
            aria-label={t(i18nBaseAccessibility + 'ButtonTapTo', {
              button: 'Cancel All Flights',
            })}>
            {t(i18nBase + 'CancelAllFlights')}
          </Button>
        </Block>
        <Block isFullWidth marginTop={ctaSpacingTop}>
          <Button
            variant="outline"
            onClick={handleCloseModal}
            fullWidth
            aria-label={t(i18nBaseAccessibility + 'ButtonTapTo', {
              button: 'Don’t Cancel',
            })}>
            {t(i18nBase + 'DontCancel')}
          </Button>
        </Block>
      </ContentWrapper>
    </Dialog>
  )
})
