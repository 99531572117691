import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'

type Props = {
  'data-tracking-id': string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export function WideModal({
  children,
  isOpen,
  onClose,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelInfo.Rooms'

  return (
    <Modal
      data-tracking-id={dataTrackingId}
      isVisible={isOpen}
      handleModalVisibility={onClose}
      horizontalDimension="content-760"
      position="right">
      <Modal.Header isMobile>
        <Header
          layout="title-icon"
          align="center"
          leftSlot={<IconButton icon="arrowBackPWA" size="medium" onClick={onClose} />}
          withBorderBottom>
          <Header.Title title={t(i18Base + '.RoomRateDetailsWithCancellation')} align="left" />
        </Header>
      </Modal.Header>

      <Modal.Body>
        <Block paddingHorizontal={56} paddingVertical={12}>
          {children}
        </Block>
      </Modal.Body>
    </Modal>
  )
}
