import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNormalizedDistance } from '@fiji/hooks/use-normalized-distance'
import type { Hotel } from '@fiji/graphql/types'
import type { AppState } from '@fiji/store'

type Args = {
  hotel: Hotel
}

const i18nBase = 'SearchResults.Hotel'

export function useHotelCardAriaLabel({ hotel }: Args) {
  const { t } = useTranslation()

  const { isTripAdvisorReviewsEnabled } = useSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const { name, starRating, medianReviewRating, distance } = hotel

  const { distance: formattedDistance, units } = useNormalizedDistance(distance)
  const distanceTitle = `${formattedDistance} ${units ? units.toLowerCase() : ''}`

  return useMemo(() => {
    const ariaLabels = [
      t(`${i18nBase}.HotelNameAriaLabel`, { hotelName: name }),
      t(`SearchResults.Hotel.HotelRatingAriaLabel`, { rating: starRating }),
    ]

    if (isTripAdvisorReviewsEnabled && !!medianReviewRating) {
      ariaLabels.push(t('SearchResults.Hotel.TripAdvisorReviewsAriaLabel'))
    }

    if (distanceTitle) {
      ariaLabels.push([t('SearchResults.Hotel.DistanceAriaLabel'), distanceTitle].join(' '))
    }

    ariaLabels.push(t(`${i18nBase}.HotelAriaLabel`))

    return ariaLabels.join(', ')
  }, [t, name, starRating, isTripAdvisorReviewsEnabled, medianReviewRating, distanceTitle])
}
