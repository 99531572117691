import { MediaLayout } from '@etta/ui/media-layout'
import { PassportBoxMobile } from './mobile'
import { PassportBoxDesktop } from './desktop'
import type { Props } from './types'

export function PassportBoxLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<PassportBoxMobile {...props} />}
      desktopSlot={<PassportBoxDesktop {...props} />}
    />
  )
}
