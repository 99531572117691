import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { NewBrandContainer, EttaLogo } from './build-info-styled'
import { useBuildInfo } from './use-build-info'

export const BuildInfo = observer(function BuildInfo() {
  const {
    isHidden,
    onClick,
    buildTime,
    commitHash,
    envTitle,
    partnerId,
    companyId,
    customerId,
  } = useBuildInfo()
  const { t } = useTranslation()
  const environment = `${envTitle} | ${commitHash} | ${buildTime}`
  const user = `p:${partnerId} | s:${companyId} | uid:${customerId}`
  return (
    <NewBrandContainer onClick={onClick} isHidden={isHidden}>
      <EttaLogo name="deemLogoPWA" size="large" color="bodyText1" />
      <Block paddingHorizontal={20}>
        <Text align="center" variant="footnoteMedium" color="bodyText1">
          {t('BuildInfo.Version', { version: environment + user })}
        </Text>
      </Block>
    </NewBrandContainer>
  )
})
