import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  Container,
  TitleContainer,
  SeatIconWrapper,
  SubTitleContainer,
} from '../../../blocks-desktop-styles'
import { FinishSeatSelectionButton } from '../../../../finish-seat-selection-button'
import { ContinueBlockContainer } from './block-continue-desktop-styled'

export function BlockContinueDesktop({
  title,
  subTitle,
  buttonTitle,
  isLoading,
  finishAllSeatsSelection,
  onContinue,
}: LayoutProps) {
  return (
    <Container>
      <FinishSeatSelectionButton onFinishSelectionClick={finishAllSeatsSelection} />
      <ContinueBlockContainer>
        <Block marginRight={32}>
          <TitleContainer>
            <SeatIconWrapper>
              <SeatIcon icon="alert" />
            </SeatIconWrapper>
            <Text variant="headline" color="mainText">
              {title}
            </Text>
          </TitleContainer>
          {subTitle && (
            <SubTitleContainer>
              <Text color="bodyText" variant="footnoteMedium" align="right">
                {subTitle}
              </Text>
            </SubTitleContainer>
          )}
        </Block>
        <Button
          variant="outline"
          size="normal"
          minWidth={13.6}
          onClick={onContinue}
          disabled={isLoading}>
          {buttonTitle}
        </Button>
      </ContinueBlockContainer>
    </Container>
  )
}
