import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Spinner } from '@etta/ui/spinner'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'
import { CurrentLocationSearch } from '../../current-location-search'
import { List } from '../../list'
import { NoStations } from '../../no-stations'
import {
  SearchFieldContent,
  PwaErrorMsg,
  PwaLoader,
  PwaMsgHandler,
  ResultsListContainer,
  Separator,
  BackButton,
  ButtonIcon,
} from './search-mechanism-mobile-styled'
import { SearchInput } from './search-input'

const i18nPath = 'SearchResults.'

export function SearchMechanismMobile({
  scrollContainerRef,
  onModalClose,
  anchorSlot,
  hasCurrentLocationSearch,
  setIsLoading,
  handleCurrentLocation,
  hasAnyPlaces,
  searchInputRef,
  customSearchDescription,
  isTrainNoResultVisible,
  isResultsListOpen,
  isLoading,
  errorMessage,
  searchType,
  referencePoints,
  isHotelSearch,
  getItemProps,
  latestPlaces,
  highlightedIndex,
  places,
  modifiedPlaces,
  menuProps,
  searchFieldPlaceholder,
  searchInputContainerProps,
  searchInputProps,
  isSearchInputDisabled,
  searchInputValue,
  noResultMessage,
  onReset,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Modal.Body ref={scrollContainerRef}>
      {anchorSlot}

      <Modal.Header isMobile>
        <SearchFieldContent>
          <BackButton onClick={onModalClose} aria-label={t('TripPlanner.BaseSearch.Header.Close')}>
            <ButtonIcon />
          </BackButton>

          <SearchInput
            isResultsListOpen={isResultsListOpen}
            searchInputRef={searchInputRef}
            containerProps={searchInputContainerProps}
            inputProps={searchInputProps}
            isDisabled={isSearchInputDisabled}
            placeholder={searchFieldPlaceholder}
            value={searchInputValue}
            onReset={onReset}
          />
        </SearchFieldContent>
      </Modal.Header>

      <Block position="relative">
        <Separator />
        {hasCurrentLocationSearch && (
          <CurrentLocationSearch
            dataTrackingId={searchType + '-search-mechanism-current-location-button'}
            setIsLoading={setIsLoading}
            onClick={handleCurrentLocation}
          />
        )}
        {(hasAnyPlaces ? false : searchInputRef.current?.defaultValue.length === 0) && (
          <PwaErrorMsg>{customSearchDescription || t(`${i18nPath}PwaFirstScreen`)}</PwaErrorMsg>
        )}
        {noResultMessage && <PwaErrorMsg>{noResultMessage}</PwaErrorMsg>}

        {isTrainNoResultVisible && <NoStations title={t(`${i18nPath}SearchResults`)} />}
        <ResultsListContainer {...menuProps} isOpen={isResultsListOpen}>
          <PwaMsgHandler>
            {isLoading && (
              <PwaLoader>
                <Spinner type="spinout" />
              </PwaLoader>
            )}
            {errorMessage && !isLoading && (
              <PwaErrorMsg>
                {t(`${i18nPath}PwaNoResults`)}
                <br />
                {t(`${i18nPath}PwaSearchOtherKeywords`)}
              </PwaErrorMsg>
            )}
          </PwaMsgHandler>
          <Swap is={!!errorMessage} isSlot={<em>{errorMessage}</em>}>
            <List
              searchType={searchType}
              title={t(`${i18nPath}SearchResults`)}
              list={isHotelSearch ? modifiedPlaces : places}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              iconSize="medium"
              dataTrackingId={searchType + '-search-mechanism-search-results-list'}
            />
            {isHotelSearch && referencePoints && (
              <List
                searchType={searchType}
                title={t(`${i18nPath}ReferencePoints`)}
                list={referencePoints}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                startIndex={modifiedPlaces.length}
                iconSize="medium"
                dataTrackingId={searchType + '-search-mechanism-reference-points-list'}
              />
            )}
          </Swap>
          <List
            searchType={searchType}
            title={t(`${i18nPath}RecentSearches`)}
            list={latestPlaces}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            startIndex={places.length}
            iconSize="medium"
            dataTrackingId={searchType + '-search-mechanism-recent-searches-list'}
          />
        </ResultsListContainer>
      </Block>
    </Modal.Body>
  )
}
