import { Action, Inject } from '@etta/di'
import type { SegmentPriceChangeDetails } from '@etta/modules/review-trip/core/value-objects'
import { AirCheckFarePolicyStore } from '../stores/air-check-fare-policy/air-check-fare-policy.store'
import {
  mapPriceChangeReasonValueObject,
  mapPriceChangeTypeValueObject,
} from '../../infra/mappers/price-change.mapper'

@Action()
export class AirPriceChangeActions {
  constructor(
    @Inject()
    private checkFarePolicyStore: AirCheckFarePolicyStore,
  ) {}

  setAirPriceChange(value: SegmentPriceChangeDetails | null) {
    if (value) {
      const priceChange = {
        rate: value.rate,
        reason: mapPriceChangeReasonValueObject(value.reason),
        type: mapPriceChangeTypeValueObject(value.type),
      }
      this.checkFarePolicyStore.setAirPriceChange(priceChange)
    }
  }

  dropPriceChange() {
    this.checkFarePolicyStore.setAirPriceChange(null)
  }
}
