import type { TripDetailsPostReservationValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { usePostBookingContext } from '@etta/modules/post-booking'

type Args = {
  supportedActions: TripDetailsPostReservationValueObject
  tripStartDate: string
  isMultiCityTrip: boolean
  hasReturnFlight: boolean
  isReturnFlightStarted: boolean
}

export const useDetailsButtonGroup = ({
  supportedActions,
  tripStartDate,
  isMultiCityTrip,
  hasReturnFlight,
  isReturnFlightStarted,
}: Args) => {
  const {
    displayConfigurationStore: { isMod2FlowEnabled },
  } = useDisplayConfigurationContext()
  const { postBookingTripStore } = usePostBookingContext()
  const isHotelModifyEnabled =
    supportedActions.isAddHotelAllowed || supportedActions.isHotelChangeAllowed
  const isCarRentalModifyEnabled =
    supportedActions.isAddCarRentalAllowed || supportedActions.isCarRentalChangeAllowed
  const isFlightModifyEnabled =
    supportedActions.isFlightChangeAllowed &&
    supportedActions.isFlightChangeDestinationAllowed &&
    supportedActions.isFlightChangeCarrierAllowed &&
    !isMultiCityTrip

  const isEnabledByFlag = isHotelModifyEnabled || isCarRentalModifyEnabled || isFlightModifyEnabled
  const isStartDateGone =
    postBookingTripStore.isReturnFlightChangeable && hasReturnFlight
      ? !isReturnFlightStarted
      : new Date(Date.now()) < new Date(tripStartDate)
  return {
    isModifyButtonEnabled: isEnabledByFlag && isStartDateGone && !isMod2FlowEnabled,
  }
}
