import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
// eslint-disable-next-line import/no-restricted-paths
import { RecentRailSearchesRailType } from '@fiji/graphql/types'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  Container,
  Header,
  HeaderText,
  List,
  ListItem,
  ArrowIconWrapper,
  Location,
  LeftBlock,
  RightBlock,
  BottomLine,
  CentralBlock,
  TopLine,
} from './rail-search-form-mobile-recent-searches-styled'

export const RailSearchFormMobileRecentSearches = observer(
  function RailSearchFormMobileRecentSearches() {
    const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
    const { t } = useTranslation()
    const i18nBase = 'RecentSearches'
    const recentSearchesList = recentSearchesStore.recentSearches.rail.items
    if (!recentSearchesList.length || recentSearchesStore.isRecentSearchDisabled) {
      return null
    }

    return (
      <Container>
        <Header>
          <Icon name="timePWA" color="bodyText" />
          <HeaderText>{t(`${i18nBase}.Title`)}</HeaderText>
        </Header>
        <List>
          {recentSearchesList?.map((item) => {
            const {
              bottomLine,
              destinationLocationName,
              recreateSearch,
              originLocationName,
            } = recentSearchDataAction.getRecentRailSearches(item.railSearchParams)
            return (
              <ListItem onClick={recreateSearch}>
                <LeftBlock>
                  <Icon name="railPWA" />
                </LeftBlock>
                <CentralBlock>
                  <TopLine>
                    <Location>{originLocationName}</Location>
                    <ArrowIconWrapper>
                      {item.railSearchParams.railType === RecentRailSearchesRailType.RoundTrip ? (
                        <Icon name="returnFlightArrowPWA" color="bodyText" />
                      ) : (
                        <Icon name="flightArrowPWA" color="bodyText" />
                      )}
                    </ArrowIconWrapper>
                    <Location>{destinationLocationName}</Location>
                  </TopLine>
                  <BottomLine>{bottomLine}</BottomLine>
                </CentralBlock>
                <RightBlock>
                  <Icon name="chevronRightPWA" color="borderLight" size={22} />
                </RightBlock>
              </ListItem>
            )
          })}
        </List>
      </Container>
    )
  },
)
