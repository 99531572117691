import type { PlainTime as Time } from '@fiji/types'

export function dateToTime(date: Date): Time {
  const seconds = date.getSeconds()
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: seconds !== 0 ? seconds : undefined,
  }
}
