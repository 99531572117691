import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { SegmentType } from '@etta/core/enums'
import { ScreenType, useScreenType } from '@fiji/modes'
import { RulesModal } from '@etta/modules/rules-modals/ui/car-rental-rules-modal'
import { FareRules } from '@etta/modules/rules-modals/ui/flight-fare-rules-modal'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useRoomRatesModal } from '@etta/components/hotel-details-modal/use-hotel-details-modal/use-room-rates-modal'
import { isoToDate } from '@fiji/utils/dates'
import { RoomRatesModal } from '@etta/components/room-rates-modal'
import { Item, ItemContent, ItemTitle, CostWrapper } from './trip-segment-styled'
import { TripSegmentName } from './trip-segment-name'

type Props = {
  icon: IconNames | null
  name: string | string[]
  cost: string
  id: string | number
  type: SegmentType
  isRoundTripLeg?: boolean | null
  isRoundTripCost?: boolean | null
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'

export const TripSegment = observer(({ icon, name, cost, type, id, isRoundTripLeg, isRoundTripCost }: Props) => {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const {
    postBookingCancellationStore,
    postBookingTripStore,
    postBookingCancellationActions,
  } = usePostBookingContext()

  useMemo(() => {
    postBookingCancellationActions.prepareModalPropsData()
  }, [postBookingCancellationActions])

  const {
    roomDetails,
    isRoomDetailsOpen,
    onRoomDetailsClose,
    onRoomDetailsSelect,
  } = useRoomRatesModal({
    hotelId: id as string,
    name:
      (postBookingCancellationStore.hotelRulesProps[id] &&
        postBookingCancellationStore.hotelRulesProps[id].name) ||
      '',
    checkIn: isoToDate(
      (postBookingCancellationStore.hotelRulesProps[id] &&
        postBookingCancellationStore.hotelRulesProps[id].checkIn) ||
        '',
    ),
    checkOut: isoToDate(
      (postBookingCancellationStore.hotelRulesProps[id] &&
        postBookingCancellationStore.hotelRulesProps[id].checkOut) ||
        '',
    ),
  })

  const isDesktop = screenType === ScreenType.Desktop
  const ariaLabel = t('Accessibility.CancelSegment', {
    name,
    cost,
  })

  const getLinkLabel = (type: SegmentType) => {
    switch (type) {
      case SegmentType.CarRental:
        return t(`${i18nBase}.DetailsAndRules`)
      case SegmentType.Flight:
        return t(`${i18nBase}.FlightFareRules`)
      case SegmentType.Hotel:
        return t(`${i18nBase}.RoomRateDetails`)
      default:
        return ''
    }
  }

  const handleOpenModal = (type: SegmentType, id: string | number) => {
    postBookingCancellationActions.handleOpenRulesModal(type, id, onRoomDetailsSelect)
  }

  return (
    <>
      <Item aria-label={ariaLabel}>
        {icon && <Icon name={icon} size="medium" />}
        <ItemContent isDesktop={isDesktop}>
          <ItemTitle>
            <TripSegmentName name={name} isRoundTrip={isRoundTripLeg && isRoundTripCost} segmentType={type} />
          </ItemTitle>
          <CostWrapper isDesktop={isDesktop}>
            <Text variant="captionStrongCaps" isBlock data-tracking-id="cancel-trip-price-text">
              {cost}
            </Text>
            <Link onClick={() => handleOpenModal(type, id)} size="small">
              {getLinkLabel(type)}
            </Link>
          </CostWrapper>
        </ItemContent>
      </Item>
      {type === SegmentType.Hotel && (
        <RoomRatesModal
          isOpen={isRoomDetailsOpen}
          onClose={onRoomDetailsClose}
          roomRates={roomDetails}
          processId={postBookingTripStore.trip.processId}
          layout={isDesktop ? 'wide-left-slide' : 'default'}
        />
      )}
      {type === SegmentType.CarRental && (
        <RulesModal
          transactionId={postBookingTripStore.trip.processId}
          carSegmentId={postBookingCancellationStore.carRentalRulesProps[id].carId}
          isOpen={postBookingCancellationStore.carRentalRulesProps[id].toggle.isOpen}
          onClose={postBookingCancellationStore.carRentalRulesProps[id].toggle.handleClose}
        />
      )}
      {type === SegmentType.Flight && (
        <FareRules
          flightSegmentId={id as string}
          transactionId={postBookingTripStore.trip.processId}
          airlineLogo={postBookingCancellationStore.flightFareRulesProps[id].airlineLogo}
          airlineName={postBookingCancellationStore.flightFareRulesProps[id].airlineName}
          bookingCodes={postBookingCancellationStore.flightFareRulesProps[id].bookingCodes}
          fares={postBookingCancellationStore.flightFareRulesProps[id].fares}
          isOpen={postBookingCancellationStore.flightFareRulesProps[id].toggle.isOpen}
          onClose={postBookingCancellationStore.flightFareRulesProps[id].toggle.handleClose}
        />
      )}
    </>
  )
})

