import type { CalculateEmissionsResultHotelCertification } from '@fiji/graphql/types'
import { CalculateEmissionsResultHotelCertificationType } from '@fiji/graphql/types'
import { getHotelSustainabilityDeviation } from '@fiji/hooks/sustainability/get-hotel-sustainability-deviation'

type Props = {
  averageSustainabilityScore: number
  sustainabilityScore: number
  tonnesOfEmissions: number
  certifications: CalculateEmissionsResultHotelCertification[]
}

const KG_IN_TONNE = 1000

export function useHotelDetailsEcoCheckModal({
  averageSustainabilityScore,
  sustainabilityScore,
  tonnesOfEmissions,
  certifications,
}: Props) {
  const tonnesToKg = (tonnes: number) => Number((tonnes * KG_IN_TONNE).toFixed(2))

  const { deviationLevel } = getHotelSustainabilityDeviation({
    averageSustainabilityScore,
    sustainabilityScore,
  })

  const hotelCertifications = certifications.filter(
    (certification) =>
      certification.type === CalculateEmissionsResultHotelCertificationType.HotelCertification,
  )

  return {
    kgOfEmissions: tonnesToKg(tonnesOfEmissions),
    deviationLevel,
    hotelCertifications,
  }
}
