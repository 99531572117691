import styled from 'styled-components'

export const ExplanationContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  border-radius: 10px;
  padding: 18px 16px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const Description = styled.div`
  margin: 15px 0;
`
