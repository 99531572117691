import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Title = styled(UnstyledButton)`
  width: 100%;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 6px 10px 20px;
  user-select: none;
  cursor: pointer;
`

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`
