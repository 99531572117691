import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { PurchaseReservationSuccessMobile } from './mobile'
import { PurchaseReservationSuccessDesktop } from './desktop'

export function PurchaseReservationSuccessLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<PurchaseReservationSuccessMobile {...props} />}
      desktopSlot={<PurchaseReservationSuccessDesktop {...props} />}
    />
  )
}
