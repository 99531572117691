import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { hexToRgba } from '@fiji/utils/hex-to-rgba'
import type { BadgeSkinType } from './types'

export const Item = styled.div<{ skin?: BadgeSkinType }>`
  color: ${(props) => props.theme.colors.bodyText};
  display: flex;
  align-items: center;
  line-height: 19px;
  margin-bottom: ${(p) => {
    switch (p.skin) {
      case 'big-space':
        return '9px'
      default:
        return '5px'
    }
  }};
  &:last-child {
    margin-bottom: 0;
  }
  ${footnoteMedium}
`

export const IconRow = styled.div<{ skin?: BadgeSkinType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => {
    switch (p.skin) {
      case 'small-inner-space':
        return '4px'
      default:
        return '6px'
    }
  }};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TooltipOuterContainer = styled.div<{ skin?: BadgeSkinType }>`
  display: flex;
  margin-bottom: ${(p) => {
    switch (p.skin) {
      case 'big-space':
        return '9px'
      default:
        return '5px'
    }
  }};
  &:last-child {
    margin-bottom: 0;
  }
`

export const TooltipTextContainer = styled.div`
  max-width: 270px;
`

export const TooltipTextItem = styled.div<{ showOutOfPolicyHeader?: boolean }>`
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => hexToRgba(props.theme.colors.borderLight, 0.3)};

  &:last-child {
    padding-bottom: 4px;
    border-bottom: none;
  }

  ${(props) =>
    !props.showOutOfPolicyHeader &&
    css`
      margin: 0;

      &:last-child {
        padding: 0;
      }
    `}
`
