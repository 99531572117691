import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import { Title, Container } from './rules-modal-desktop-styled'

export function RulesModalDesktop({ carRules }: LayoutProps) {
  return (
    <Container>
      {carRules.map((rule, index: number) => {
        if (!rule || !rule.value) {
          return null
        }

        return (
          <Block marginTop={24} key={index}>
            <Title aria-label={`${rule.header || ''}`}>{rule.header}</Title>
            <Block marginTop={12}>
              <Text aria-label={rule.value} variant="footnoteMedium" color="bodyText1">
                {rule.value}
              </Text>
            </Block>
          </Block>
        )
      })}
    </Container>
  )
}
