/* eslint import/no-restricted-paths: 0 */
import { Inject, Service } from '@etta/di'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { AddSegmentModalsStore } from '@etta/modules/review-trip/interface/stores/add-segment-modals.store'
import { SegmentAddressMapper } from '@etta/modules/review-trip/core'
import { AirSearchFormStore } from '@etta/modules/air-search/interface/stores/air-search-form.store'
import { deleteTimezone } from '@fiji/utils/dates'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import type { Place } from '@fiji/types'
import { SubmitButtonActions } from '@etta/modules/air-search/interface/actions/submit-button.action'
import { AirSearchFormService } from '@etta/modules/air-search/interface/services/air-search-form/air-search-form.service'
import { AirSearchFormInputsService } from '@etta/modules/air-search/interface/services/air-search-form/air-search-form-inputs.service'
import { AirSearchDefaultValuesStore } from '@etta/modules/air-search/interface/stores/air-search-default-values.store'
import { setUtcDate } from '@fiji/utils/dates/set-utc-date'
import { SegmentTypeCheckActions } from '../actions/segment-type-check.actions'

const FIRST_FLIGHT_LEG = 0

@Service()
export class AddFlightSegmentService {
  constructor(
    @Inject()
    private readonly addSegmentModalsStore: AddSegmentModalsStore,
    @Inject()
    private readonly reviewTripPageStore: ReviewTripStore,
    @Inject()
    private readonly airSearchFormInputsService: AirSearchFormInputsService,
    @Inject()
    private readonly airSearchFormService: AirSearchFormService,
    @Inject()
    private readonly segmentTypeCheck: SegmentTypeCheckActions,
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly airSearchDefaultValuesStore: AirSearchDefaultValuesStore,
    @Inject()
    private readonly submitButtonActions: SubmitButtonActions,
  ) {}

  public handleAddFlight() {
    const defaultValues = this.airSearchDefaultValuesStore
    const defaultFlights = defaultValues.defaultSearchFlights
    const defaultFlightType = defaultValues.defaultFlightType

    this.airSearchFormStore.updateSearchFlightsEditCache({
      itineraryId: this.reviewTripPageStore.itineraryId,
      flights: defaultFlights,
      flightType: defaultFlightType,
    })

    const hotelData = this.getFlightBasedOnHotel()
    const carData = this.getFlightBasedOnCarRental()

    if (hotelData) {
      this.handleFillSearch(hotelData)
      return
    }

    if (carData) {
      this.handleFillSearch(carData)
      return
    }

    this.addSegmentModalsStore.openAirSearchModal()
  }

  private handleFillSearch(flightFields: Partial<Flight>) {
    this.airSearchFormService.flightTypeChange(FlightType.Round)

    const { departureDate, returnDate } = flightFields
    const { destinationPlace, originPlace } = this.getFlightPlaces(flightFields)

    if (originPlace) {
      this.airSearchFormInputsService.handleOriginPlaceChange(FIRST_FLIGHT_LEG, originPlace)
    }

    if (destinationPlace) {
      this.airSearchFormInputsService.handleDestinationPlaceChange(
        FIRST_FLIGHT_LEG,
        destinationPlace,
      )
    }

    this.airSearchFormInputsService.handleDateChange(FIRST_FLIGHT_LEG, {
      date: departureDate,
      dateEnd: returnDate,
    })

    const flights = this.airSearchFormStore.searchFlights

    if (!flights[0]) {
      return
    }

    if (!this.submitButtonActions.canSubmitSearch) {
      this.addSegmentModalsStore.openAirSearchModal()
      return
    }

    this.submitButtonActions.canSubmitForm()
  }

  private getFlightBasedOnHotel() {
    const hotelSegment = this.reviewTripPageStore?.segments?.find(
      this.segmentTypeCheck.isHotelSegment,
    )

    if (!hotelSegment) {
      return null
    }

    const { checkIn, checkOut } = hotelSegment

    const departureDate = setUtcDate(new Date(deleteTimezone(checkIn)))
    const returnDate = setUtcDate(new Date(deleteTimezone(checkOut)))

    return {
      originPlace: this.airSearchFormStore.homeAirport ?? undefined,
      departureDate,
      returnDate,
      destinationPlace: SegmentAddressMapper.mapHotelSegmentAddressToLocation(hotelSegment),
    }
  }

  private getFlightBasedOnCarRental() {
    const carSegment = this.reviewTripPageStore?.segments?.find(
      this.segmentTypeCheck.isCarRentalSegment,
    )

    if (!carSegment) {
      return null
    }

    const { pickUpTime, dropOffTime } = carSegment

    const departureDate = new Date(deleteTimezone(pickUpTime))
    const returnDate = new Date(deleteTimezone(dropOffTime))

    return {
      originPlace: this.airSearchFormStore.homeAirport ?? undefined,
      departureDate,
      returnDate,
      destinationPlace: SegmentAddressMapper.mapCarSegmentAddressToLocation(carSegment, 'pickup'),
    }
  }

  private getFlightPlaces(flight: Partial<Flight>) {
    const { destinationPlace, originPlace } = flight

    const isPlacesAtTheSameLocation = this.isPlacesAtTheSameLocation(originPlace, destinationPlace)

    if (isPlacesAtTheSameLocation) {
      return {
        originPlace,
        destinationPlace: undefined,
      }
    }

    return {
      originPlace,
      destinationPlace,
    }
  }

  private isPlacesAtTheSameLocation(origin?: Place, destination?: Place) {
    const checkers = [this.areAirportEquals, this.areGeoPointsEquals, this.areNameEquals]

    if (origin === undefined || destination === undefined) {
      return false
    }

    const areSomehowEquals = checkers.some((fn) => {
      return fn(origin, destination)
    })

    return areSomehowEquals
  }

  private areGeoPointsEquals = (from: Place, to: Place) => {
    if (!from.latitude || !from.longitude || !to.latitude || !to.longitude) {
      return false
    }
    return checkIsEqual(from.latitude, to.latitude) && checkIsEqual(from.longitude, to.longitude)
  }
  private areNameEquals = (from: Place, to: Place) => {
    return checkIsEqual(from.name, to.name, 'both-strict')
  }
  private areAirportEquals = (from: Place, to: Place) => {
    return checkIsEqual(from.airportCode, to.airportCode, 'both-strict')
  }
}
