import { Text } from '@etta/ui/text'

type Props = {
  classType?: string
}

export function FareBrand({ classType }: Props) {
  return classType ? (
    <Text aria-label={classType} color="mainText" variant="headline">
      {classType}
    </Text>
  ) : (
    <></>
  )
}
