import type { RouteItem } from '../../types'
import { exploreRoutes } from './explore'
import { supportRoutes } from './support'
import { tripsRoutes } from './trips'
import { profileRoutes } from './profile'
import { purchaseRoutes } from './purchase'
import { airRoutes } from './air'
import { railRoutes } from './rail'
import { carRentalRoutes } from './car-rental'
import { hotelRoutes } from './hotel'
import { onboardingRoutes } from './onboarding'
import { travelerInformationRoutes } from './traveler-information'
import { tripReviewRoutes } from './trip-review'
import { checkoutRoutes } from './checkout'
import { mobilityRoutes } from './mobility'
import { meetingRoutes } from './meeting'

export const pwaRoutes: Array<RouteItem> = [
  ...exploreRoutes,
  ...airRoutes,
  ...railRoutes,
  ...carRentalRoutes,
  ...hotelRoutes,
  ...tripsRoutes,
  ...profileRoutes,
  ...purchaseRoutes,
  ...supportRoutes,
  ...onboardingRoutes,
  ...travelerInformationRoutes,
  ...tripReviewRoutes,
  ...checkoutRoutes,
  ...mobilityRoutes,
  ...meetingRoutes,
]
