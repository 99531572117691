import { Service, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { GetRecentSearchesService } from '../services/get-recent-searches.service'

@Service()
export class GetRecentSearchesActions {
  constructor(
    @Inject() private getRecentSearchesService: GetRecentSearchesService,
    @Inject()
    private featureFlagsStore: FeatureFlagsStore,
  ) {}

  getRecentCarRentalSearches() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getCarRetntalRecentSearch()
  }

  getRecentFlightSearches() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getFlightRecentSearch()
  }

  getRecentHotelSearches() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getHotelRecentSearch()
  }

  getRecentRailSearches() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getRailRecentSearch()
  }

  getRecentCarRentalSearchesWithoutDates() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getCarRentalRecentSearchWithoutDates()
  }

  getRecentHotelSearchesWithoutDates() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getHotelRecentSearchWithoutDates()
  }

  getRecentFlightSearchesWithoutDates() {
    this.featureFlagsStore.flags.isRecentSearchesEnabled &&
      this.getRecentSearchesService.getFlightRecentSearchWithoutDates()
  }
}
