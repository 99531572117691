import { TripsPage } from '@etta/screens/trips-page'
import { HeldTripsPage } from '@etta/screens/held-trips-page'
import { TripDetails } from '@etta/screens/trip-details'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const tripsRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.postBooking.trips(),
      exact: true,
    },
    documentTitle: getDocumentTitle('Trips'),
    transitionIndex: 5,
    transitionGroup: 'fade-through',
    component: () => <TripsPage />,
  },
  {
    route: {
      path: ROUTES.postBooking.heldTrips,
      exact: true,
    },
    documentTitle: getDocumentTitle('Trips'),
    transitionIndex: 5.1,
    transitionGroup: 'slide',
    component: () => <HeldTripsPage />,
  },
  {
    route: {
      path: ROUTES.postBooking.trip({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Trip Details'),
    transitionIndex: 5.2,
    transitionGroup: 'slide',
    component: () => <TripDetails />,
  },
]
