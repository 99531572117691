import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { MOBILE_FOOTER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../../layout/mobile/constants'
import type { LayoutProps } from '../../seat-map.types'
import { PinchIcon } from './pinch-icon'
import { Overlay, StyledSeatMapSegment, Wrapper } from './seat-map-mobile-styled'

export function SeatMapMobile({
  wrapperRef,
  zoomHandler,
  isLoading,
  defaultScale,
  maxScale,
  isZoomable,
  onInteractOnce,
  overlayHeight,
  scrollOffset,
  airSeatMapRef,
  shouldShowOverlay,
  seatRows,
  onUnavailableSeatMapContinue,
  footerHeight,
  seatsNumberList,
  onSeatChecked,
  isReadonly,
  airSeatMapHeight,
}: LayoutProps) {
  return (
    <Wrapper ref={wrapperRef}>
      <TransformWrapper
        onInit={zoomHandler}
        disabled={isLoading}
        minScale={defaultScale}
        maxScale={maxScale}
        alignmentAnimation={{
          size: 0,
        }}
        doubleClick={{
          disabled: true,
        }}
        zoomAnimation={{
          disabled: true,
        }}
        wheel={{
          disabled: !isZoomable,
        }}
        pinch={{
          disabled: !isZoomable,
        }}
        panning={{
          disabled: true,
        }}
        onPanningStart={onInteractOnce}
        onWheelStart={onInteractOnce}
        onPinchingStart={onInteractOnce}>
        {() => (
          <>
            {shouldShowOverlay && (
              <Overlay onClick={onInteractOnce} height={overlayHeight}>
                <PinchIcon />
              </Overlay>
            )}
            <TransformComponent
              contentStyle={{
                transform: `${
                  scrollOffset && `translate3d(0px, ${scrollOffset}px, 0px) scale(${defaultScale})`
                }`,
                minHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT}px - ${MOBILE_FOOTER_HEIGHT}px)`,
                height: `${airSeatMapHeight}px`,
              }}>
              <StyledSeatMapSegment
                ref={airSeatMapRef}
                seatRows={seatRows}
                onUnavailableSeatMapContinue={onUnavailableSeatMapContinue}
                footerHeight={footerHeight}
                onSeatChecked={onSeatChecked}
                seatsNumberList={seatsNumberList}
                isReadonly={isReadonly}
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </Wrapper>
  )
}
