import { useEffect } from 'react'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences/interface/use-travel-preferences.context'

export function useTravelPreferences() {
  const { travelPreferencesActions } = useTravelPreferencesContext()

  useEffect(() => {
    travelPreferencesActions.loadTravelPreferences()
  }, [travelPreferencesActions])
}
