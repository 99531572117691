import { default as NoPhotosIcon } from '@etta/components/hotel-image/assets/no-hotel-preview-icon.svg'
import { NoPhotosPlaceholder, NoPhotosContainer } from './hotel-details-photos-styled'

export function NoPhoto() {
  return (
    <NoPhotosContainer>
      <NoPhotosPlaceholder>
        <NoPhotosIcon />
      </NoPhotosPlaceholder>
    </NoPhotosContainer>
  )
}
