import { observer } from 'mobx-react-lite'
import { CarRentalFiltersType } from '@fiji/enums'
import { ListTypes } from '@etta/ui/drop-down-list'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import type { FilterProps } from '../types'
import { CarRentalOfficeDropdown } from '../car-rental-office-dropdown'
import { FilterContainer } from '../filter-container'
import { useSupplierFilter } from './use-supplier-filter'
import { SupplierFilterWrapper } from './supplier-filter-styled'
import { SuppliersOptions } from './suppliers-options'

export const SupplierFilter = observer(function SupplierFilter({
  isDropdownFilter = true,
}: FilterProps) {
  const {
    isCarRentalsNewFiltersEnabled,
    label,
    handleChangeSupplier,
    vendor,
    carFiltersStore,
    carRentalFilterActions,
    carRentalSearchStore,
    suppliersValue,
    isClearDisabled,
  } = useSupplierFilter()

  if (!isCarRentalsNewFiltersEnabled) {
    return (
      <SupplierFilterWrapper>
        <CarRentalOfficeDropdown
          label={label}
          data-tracking-id="suppliers-filter-dropdown"
          onChange={handleChangeSupplier}
          options={vendor.options}
          filters={vendor.options}
          defaultLabel="All"
          type={ListTypes.Select}
          filtersType={CarRentalFiltersType.Supplier}
          value={vendor.selectedOptions}
        />
      </SupplierFilterWrapper>
    )
  }

  if (isDropdownFilter) {
    return (
      <SupplierFilterWrapper>
        <QuickFilterLayout
          data-tracking-id="supplier-filter-dropdown"
          isOpen={carFiltersStore.supplierModal.isOpen}
          onOpen={carFiltersStore.supplierModal.handleOpen}
          onClose={carFiltersStore.supplierModal.handleClose}
          onClear={carRentalFilterActions.clearHandlers.clearVendors}
          onApply={carRentalFilterActions.handleApplySuppliersFilters}
          label={label}
          value={suppliersValue}
          isShimmerLoading={carRentalSearchStore.isLoading && !carRentalSearchStore.carRentals}
          isLoading={carRentalSearchStore.isLoading}
          selectedNumber={carFiltersStore.selectedFilters.vendors.length}
          isApplyButtonDisabled={carFiltersStore.isEqual}
          isClearButtonDisabled={isClearDisabled}>
          <SuppliersOptions />
        </QuickFilterLayout>
      </SupplierFilterWrapper>
    )
  }

  return (
    <FilterContainer
      title={label}
      selectedNumber={carFiltersStore.selectedFilters.vendors.length}
      isClearButtonDisabled={isClearDisabled}
      onClear={carRentalFilterActions.clearHandlers.clearVendors}>
      <SuppliersOptions withBottomBorder={false} optionsPaddingVertical={11} />
    </FilterContainer>
  )
})
