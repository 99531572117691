import type {
  GetCreditCardsQuery,
  GetSuggestedHomeAirportsQuery,
  GetAvailableVendorsQuery,
  GetProfileFieldsSettingsQuery,
  GetCurrenciesQuery,
  GetLanguagesQuery,
  GetTimeZonesQuery,
  GetSiteCardsQuery,
  GetDelegatedUsersQuery,
  GetAvailableSpecialRequestsQuery,
  GetAvailableDelegatesQuery,
} from '@fiji/graphql/hooks'
import type { DelegateEntity } from '@etta/modules/settings/core/entities/delegate-entity'
import type { UserCreditCardEntity } from '../../core/entities/credit-cards.entity'
import type { SuggestedHomeAirportEntity } from '../../core/entities/suggested-home-airport.entity'
import type { AvailableVendorsEntity } from '../../core/entities/available-vendors.entity'
import type { ProfileFieldsSettingsEntity } from '../../core/entities/profile-fields-settings-entity'
import type { SiteCardEntity } from '../../core/entities/site-cards.entity'
import type { TimeZoneEntity } from '../../core/entities/time-zone.entity'
import type { CurrencyEntity } from '../../core/entities/currency.entity'
import type { LanguageEntity } from '../../core/entities/language.entity'
import type { AvailableSpecialRequestsEntity } from '../../core/entities/available-special-requests.entity'
import type { AvailableDelegatesEntity } from '../../core/entities/available-delegates.entity'

export class SettingsMapper {
  static toCreditCardsEntity(
    creditCards: GetCreditCardsQuery['creditCards'],
  ): UserCreditCardEntity[] {
    return creditCards.map((card) => card)
  }

  static toSuggestedHomeAirportsEntity(
    suggestedHomeAirports: GetSuggestedHomeAirportsQuery['suggestedHomeAirports'],
  ): SuggestedHomeAirportEntity[] {
    return suggestedHomeAirports.map((suggestedAirport) => suggestedAirport)
  }

  static toAvailableVendorsEntity(
    availableVendors: GetAvailableVendorsQuery['availableVendors'],
  ): AvailableVendorsEntity {
    if (!availableVendors) {
      return {
        availableAirVendors: null,
        availableHotelVendors: null,
        availableCarRentalVendors: null,
      }
    }

    const {
      availableAirVendors,
      availableHotelVendors,
      availableCarRentalVendors,
    } = availableVendors

    return {
      availableAirVendors: availableAirVendors?.map((vendor) => vendor) || null,
      availableHotelVendors: availableHotelVendors?.map((vendor) => vendor) || null,
      availableCarRentalVendors: availableCarRentalVendors?.map((vendor) => vendor) || null,
    }
  }

  static toAvailableSpecialRequestsEntity(
    availableSpecialRequests: GetAvailableSpecialRequestsQuery['availableSpecialRequests'],
  ): AvailableSpecialRequestsEntity {
    if (!availableSpecialRequests) {
      return {
        airSpecialRequests: null,
        hotelSpecialRequests: null,
        carRentalSpecialRequests: null,
      }
    }
    const {
      airSpecialRequests,
      hotelSpecialRequests,
      carRentalSpecialRequests,
    } = availableSpecialRequests
    return {
      airSpecialRequests: airSpecialRequests?.map((request) => request) || null,
      hotelSpecialRequests: hotelSpecialRequests?.map((request) => request) || null,
      carRentalSpecialRequests: carRentalSpecialRequests?.map((request) => request) || null,
    }
  }

  static toProfileFieldsSettingsEntity(
    data: GetProfileFieldsSettingsQuery['profileFieldsSettings'],
  ): ProfileFieldsSettingsEntity[] {
    const uniqIds: number[] = []

    return data.fields
      .map((field) => {
        if (
          !field.profileMetadataId ||
          !field.categoryId ||
          uniqIds.includes(field.profileMetadataId)
        ) {
          return
        }

        uniqIds.push(field.profileMetadataId)

        return {
          id: field.profileMetadataId,
          isRequired: !!field.isRequired,
          isEditable: !!field.isEditable,
          label: field.labelToDisplay,
          categoryId: field.categoryId,
          shouldDisplayInSettings: field.shouldDisplayInSettings,
          shouldDisplayInRegistration: field.shouldDisplayInRegistration,
          fieldMetadata: field.fieldMetadata
            ? {
                ...field.fieldMetadata,
                options: field.fieldMetadata.options?.map((option) => ({
                  ...option,
                  name: option.name.startsWith('*') ? option.name.slice(1) : option.name,
                })),
              }
            : undefined,
        }
      })
      .filter(Boolean) as ProfileFieldsSettingsEntity[]
  }

  static toTimeZonesEntity(timeZones: GetTimeZonesQuery['getTimeZones']): TimeZoneEntity[] {
    if (!timeZones?.timeZones) {
      return []
    }

    return timeZones.timeZones.map((timeZone) => timeZone)
  }

  static toCurrenciesEntity(currencies: GetCurrenciesQuery['getCurrencies']): CurrencyEntity[] {
    if (!currencies?.currencies) {
      return []
    }

    return currencies.currencies.map((currency) => currency)
  }

  static toLanguagesEntity(languages: GetLanguagesQuery['getLanguages']): LanguageEntity[] {
    if (!languages?.languages) {
      return []
    }

    return languages.languages.map((language) => language)
  }

  static toSiteCardsEntity(siteCards: GetSiteCardsQuery['siteCards']): SiteCardEntity[] {
    return siteCards.cards
  }

  static toDelegatesEntity(delegates: GetDelegatedUsersQuery['delegatedUsers']): DelegateEntity[] {
    if (!delegates?.delegatedUsers) {
      return []
    }

    return delegates.delegatedUsers
  }

  static toAvailableDelegatesEntity(
    delegates: GetAvailableDelegatesQuery['getAvailableDelegates'],
  ): AvailableDelegatesEntity[] | null {
    if (!delegates || !delegates.availableDelegates?.length) {
      return null
    }
    return delegates.availableDelegates
  }
}
