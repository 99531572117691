import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import type { Section } from '@etta/screens/payment-summary/types'
import { ReservationNumber } from '../reservation-number'
import { SectionBlock } from '../section-block'
import { useRailDetails } from './use-rail-details'
import { RailRow } from './rail-row'
import { RailLoyaltyCard } from './rail-loyalty-card'
import { RailPaymentCard } from './rail-payment-card'

type Props = {
  rail: Section
}

const i18nBase = 'PurchaseReservationSuccess.'

export function RailBlock({ rail }: Props) {
  const { t } = useTranslation()
  const { creditCard, rate, rows } = useRailDetails({ rail })
  const paymentText = creditCard ? t(i18nBase + 'Payment') : t(i18nBase + 'PaymentDueAtRail')
  if (rows.length === 0) {
    return null
  }

  return (
    <SectionBlock
      paymentText={paymentText}
      title={t(i18nBase + 'RailTitle')}
      cardSlot={<RailPaymentCard creditCard={creditCard} />}
      rate={rate}>
      {rows.map(
        (
          {
            directions,
            reservationNumber,
            allocatedSeat,
            seatReservationType,
            appliedRailCardName,
          },
          index,
        ) => {
          return (
            <Fragment key={index}>
              <ReservationNumber number={reservationNumber} />
              {directions.map(
                (
                  { departDate, arrivalDate, destination, origin, service, transportationMode },
                  directionIndex,
                ) => {
                  return (
                    <RailRow
                      key={directionIndex}
                      origin={origin}
                      destination={destination}
                      departDate={departDate}
                      arrivalDate={arrivalDate}
                      serviceClass={service}
                      allocatedSeat={allocatedSeat ? allocatedSeat[directionIndex] : null}
                      railSeatReservationType={seatReservationType}
                      transportationMode={transportationMode}
                    />
                  )
                },
              )}
              {appliedRailCardName && <RailLoyaltyCard railCardName={appliedRailCardName} />}
            </Fragment>
          )
        },
      )}
    </SectionBlock>
  )
}
