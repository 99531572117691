import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { Block } from '@etta/ui/block'
import { IconButton } from '@etta/ui/icon-button'
import { useUnusedTicketCostBreakdownContext } from '@etta/modules/unused-ticket-cost-breakdown/use-unused-ticket-cost-breakdown.context'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { TooltipProps } from '@etta/components/unused-ticket-breakdown-tooltip/types'
import { UnusedTicketBreakdownContent } from '../unused-ticket-breakdown-content'
import {
  Background,
  SheetContainer,
  HeaderContainer,
  BodyContainer,
} from './unused-ticket-breakdown-bottom-sheet-mobile-styled'
import { TRANSITION_NAME } from './transntion/dialog-transition'

const i18nBase = 'AirSearchResults.UnusedTicketTooltip'

export const UnusedTicketBreakdownTooltipMobile = observer(
  function UnusedTicketBreakdownBottomSheetMobile({ isOpen, ...rest }: TooltipProps) {
    const { unusedTicketCostBreakdownAction } = useUnusedTicketCostBreakdownContext()

    const { t } = useTranslation()

    useEffect(() => {
      return () => {
        unusedTicketCostBreakdownAction.handleClose()
      }
    }, [unusedTicketCostBreakdownAction])

    if (!isOpen) {
      return null
    }

    return (
      <Background>
        <DialogAnimation
          isOpen={isOpen}
          onEntered={() => {}}
          onExited={() => {}}
          isAnimated
          transitionName={TRANSITION_NAME}>
          <SheetContainer>
            <HeaderContainer>
              <Block isFlexBlock>
                <Icon name="unusedTicketPWA" color="success"></Icon>
                <Block marginLeft={4}>
                  <Text variant="headline" color="mainText">
                    {t(`${i18nBase}.Header`)}
                  </Text>
                </Block>
              </Block>
              <IconButton
                icon="closeFilledPWA"
                size="medium"
                color="bodyText1"
                onClick={unusedTicketCostBreakdownAction.handleClose}
              />
            </HeaderContainer>
            <BodyContainer>
              <UnusedTicketBreakdownContent isMobile {...rest} />
            </BodyContainer>
          </SheetContainer>
        </DialogAnimation>
      </Background>
    )
  },
)
