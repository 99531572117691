import type {
  FlightLegValueObject,
  ItineraryFlightLegValueObject,
  SearchFlightLegValueObject,
} from '@etta/modules/seat-map/core/value-objects'
import { SegmentsMapper } from './segments.mapper'

export class FlightLegMapper {
  static toFlightLegEntities(
    searchFlightLegs: SearchFlightLegValueObject[],
  ): FlightLegValueObject[] {
    return searchFlightLegs.map(({ legId, fares, segments }) => ({
      legId: legId,
      segments: SegmentsMapper.toSegmentEntities(segments, fares) || [],
    }))
  }

  static toFlightLegEntitiesFromItinerary(
    searchFlightLegs: ItineraryFlightLegValueObject[],
  ): FlightLegValueObject[] {
    return searchFlightLegs.map(({ legId, segments }) => ({
      legId: legId,
      segments: SegmentsMapper.toSegmentEntitiesFromItineraryFlight(segments) || [],
    }))
  }
}
