import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'
import { useDeleteCarRental } from '../hooks/use-delete-car-rental'
import { getAmenities, getVendorInformation, getLocations } from './utils'

type Props = {
  carRental: CarRentalSegmentEntity
  carRentalId: string
  onClose: () => void
}

export function useCarRentalDetailsModal({ carRental, carRentalId, onClose }: Props) {
  const amenities = getAmenities(carRental)
  const imageUrl = carRental.vendorDetails.imageUrl
  const carImage = carRental.carDetails.imageUrl
  const isOutOfPolicy = !carRental.policy.isInPolicy
  const isPreferred = carRental.isPreferred
  const vendorInformation = getVendorInformation({ carRental })
  const locations = getLocations({ carRental })
  const { carRentalTitle } = translateCarNameDetails({
    carType: carRental.carDetails.type,
    carClass: carRental.carDetails.class,
  })
  const changeModalToggle = useTogglePopup()
  const removeDialogToggle = useTogglePopup()

  const { handleRemoveCarRental, viewStateParams } = useDeleteCarRental({
    carRentalId,
    onCloseAfterward: onClose,
  })

  return {
    carRentalTitle,
    carImage,
    imageUrl,
    isOutOfPolicy,
    isPreferred,
    amenities,
    vendorInformation,
    locations,
    changeModalToggle,
    removeDialogToggle,
    handleRemoveCarRental,
    viewStateParams,
  }
}
