import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import type { PlaceWithAddress } from '@etta/components/mobility-select-location-modal'
import { RideHailSuggestionLocationEnum } from '@etta/modules/ride-hail/core/enum/suggestion-location-type.enum'
import type { RideHailSuggestionValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-suggestion.value-object'
import {
  SuggestionSectionWrapper,
  SuggestionSectionItem,
  SuggestionTitle,
  TextWrapper,
  SuggestionDescription,
  SuggestionIConContainer,
} from './mobility-suggested-drop-off-styled'
import { useMobilitySuggestedDropOff } from './use-mobility-suggested-drop-off'
import { MobilitySuggestedDropOffSkeleton } from './mobility-suggested-drop-off-skeleton'

type Props = {
  onDropOffSelected: (place: PlaceWithAddress, address?: string) => void
}

export function MobilitySuggestedDropOff({ onDropOffSelected }: Props) {
  const { dropOffs, isLoading, error, getDescription } = useMobilitySuggestedDropOff()

  if (dropOffs.length === 0 && !error && !isLoading) {
    return null
  }

  const mapPlace = (ride: RideHailSuggestionValueObject) => {
    return {
      placeId: '',
      name: ride.dropOffName ?? '',
      address: ride.dropOffLocation.address ?? undefined,
      airportCode:
        ride.dropOffLocationType === RideHailSuggestionLocationEnum.Airport
          ? ride.dropOffName.slice(0, 3)
          : undefined,
      latitude: ride.dropOffLocation.geocode?.lat,
      longitude: ride.dropOffLocation.geocode?.long,
      countryCode:
        ride.dropOffLocationType === RideHailSuggestionLocationEnum.Airport ||
        ride.dropOffLocationType === RideHailSuggestionLocationEnum.Hotel
          ? 'US'
          : undefined,
    }
  }

  const mapAddress = (ride: RideHailSuggestionValueObject) => {
    return ride.dropOffLocation.address ?? undefined
  }

  return (
    <SuggestionSectionWrapper>
      <Swap is={isLoading} isSlot={<MobilitySuggestedDropOffSkeleton />}>
        {dropOffs.map((dropOffPlace) => (
          <SuggestionSectionItem
            data-tracking-id="mobility-where-to-list_suggestion"
            key={dropOffPlace.dropOffName}
            onClick={() => onDropOffSelected(mapPlace(dropOffPlace), mapAddress(dropOffPlace))}>
            <SuggestionIConContainer>
              <Icon name="locationPWA" size={32} color="primary" />
            </SuggestionIConContainer>
            <TextWrapper>
              <Block>
                <SuggestionTitle textOverflow="ellipsis" variant="subHeadStrong">
                  {dropOffPlace.dropOffName}
                </SuggestionTitle>
              </Block>
              <Block>
                <SuggestionDescription textOverflow="ellipsis" variant="footnoteMedium">
                  {getDescription(dropOffPlace)}
                </SuggestionDescription>
              </Block>
            </TextWrapper>
          </SuggestionSectionItem>
        ))}
      </Swap>
    </SuggestionSectionWrapper>
  )
}
