import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { KeyboardNavigationStyle } from '@fiji/style'
import { InputButtonGroupDirection } from './types'

const verticalToggleStyles = css`
  top: 50%;
  right: 10px;
  margin-top: -15px;
  svg {
    transform: rotate(180deg);
  }
  @keyframes rotate-arrows {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`

const horizonalToggleStyles = css`
  left: 50%;
  bottom: 8px;
  transform: translate(-50%, 0);
  svg {
    transform: rotate(90deg);
  }
  @keyframes rotate-arrows {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`

export const ToggleIconButton = styled.button<{
  isAnimated: boolean
  direction: InputButtonGroupDirection
}>`
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 0;
  border: 2px solid ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
  svg {
    animation: ${(props) => (props.isAnimated ? 'rotate-arrows' : 'none')} 0.3s 1;
  }
  ${(p) =>
    p.direction === InputButtonGroupDirection.Vertical
      ? verticalToggleStyles
      : horizonalToggleStyles}

  ${KeyboardNavigationStyle}
`

const HorizontalStyles = (contentMargin: number) => css`
  > * + * {
    margin-left: ${contentMargin}px;
    border: none !important;

    &:first-child {
      margin-left: 0;
    }
    /* those important rules are here to rewrite styles in components, which has own styles for borders, inside of group */
  }

  > *:first-child {
    width: 50%;
  }

  > *:last-child {
    width: 50%;
  }

  > *:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  > *:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
`

const VerticalStyles = (contentMargin: number) => css`
  > * + * {
    margin-top: ${contentMargin}px;
    border: none !important;
    border-top: 2px solid ${(props) => props.theme.colors.white} !important;
    &:first-child {
      margin-top: 0;
    }
    /* those important rules are here to rewrite styles in components, which has own styles for borders, inside of input-button-group */
  }

  > *:not(:only-child):first-child,
  > *:not(:only-child):first-child > input {
    border-radius: 8px 8px 0 0;
  }

  > *:not(:only-child):last-child,
  > *:not(:only-child):last-child > input {
    border-radius: 0 0 8px 8px;
  }

  > *:only-child,
  > *:only-child > input {
    border-radius: 8px;
  }
`

export const Root = styled.div`
  position: relative;
  flex: 1;
`

const containerErrorBorder = css`
  border: 1px solid ${(p) => p.theme.colors.error};
  border-radius: 8px;
`

export const Container = styled.div<{
  hasError?: boolean
  direction: InputButtonGroupDirection
  contentMargin: number
}>`
  display: flex;
  ${(p) => p.hasError && containerErrorBorder}

  > * {
    border-radius: 0;
  }

  flex-direction: ${(props) =>
    props.direction === InputButtonGroupDirection.Vertical ? 'column' : 'row'};

  ${(props) =>
    props.direction === InputButtonGroupDirection.Vertical
      ? VerticalStyles(props.contentMargin)
      : HorizontalStyles(props.contentMargin)}
`

export const ErrorMessage = styled(Text).attrs({ variant: 'captionMedium' })`
  margin: 0 5px;
  color: ${(p) => p.theme.colors.error};
`
