import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { CarRentalDetailsEcoCheck } from '@etta/components/car-rental-details-eco-check/car-rental-details-eco-check'
import { CarRentalServiceFee } from '@etta/components/car-rental-service-fee'
import type { LayoutProps } from '../../types'
import { CarRentalMobileAmenities } from './car-rental-mobile-amenities'
import { CarRentalMobileBooking } from './car-rental-mobile-booking/car-rental-mobile-booking'
import { CarRentalMobilePrice } from './car-rental-mobile-price'
import { CarRentalMobileSupplier } from './car-rental-mobile-supplier/car-rental-mobile-supplier'
import { CarRentalMobileFooter } from './car-rental-mobile-footer/car-rental-mobile-footer'
import { Wrapper, CarRentalDetailsEcoCheckContainer } from './car-rental-mobile-details-styled'
import { CarDetailsMobileHeader } from './car-details-mobile-header'

export function CarRentalMobileDetails({
  onClose,
  car,
  carImage,
  vendorName,
  vendorImage,
  isLoading = false,
  isSelectable,
  isError,
  onRetry,
  onAddCar,
  carEmission,
  isHybridFuel,
  carRentalTitle,
  isOutOfPolicy,
  isPreferred,
  customBadge,
}: LayoutProps) {
  const { t } = useTranslation()
  const { anchorRef, scrollContainerRef, headerRef } = useHeaderScroll({
    scrollAfterValue: 0.15,
    scrollAfterOffset: 6,
  })
  const { featureFlags } = useFeatureFlags()

  return (
    <Wrapper>
      <Swap
        is={isError}
        isSlot={
          <NoResultSearchScreen
            type="car-rental"
            title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
            subTitle={t('SearchSegments.NoResultErrorScreen.ErrorCarRentalSubTitle')}
            buttons={[
              {
                title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
                onClick: onRetry,
                buttonVariant: 'solid',
              },
            ]}
          />
        }>
        <Modal.Body ref={scrollContainerRef}>
          <CarDetailsMobileHeader
            vendorName={vendorName}
            vendorImage={vendorImage}
            onClose={onClose}
            headerRef={headerRef}
            isOutOfPolicy={isOutOfPolicy}
            carImageUrl={carImage}
            carRentalTitle={carRentalTitle}
            isPreferred={isPreferred}
            carPolicy={car?.policy}
            customBadge={customBadge}
          />
          <div ref={anchorRef} />
          <CarRentalMobileAmenities car={car} isHybridFuel={isHybridFuel} />
          <Block paddingHorizontal={16} marginBottom={32}>
            <CarRentalServiceFee vendorCode={car?.vendor.code} />
            <CarRentalMobileBooking pickUp={car?.pickUp} dropOff={car?.dropOff} />
            {featureFlags.isSustainabilityEnabled && (
              <CarRentalDetailsEcoCheckContainer>
                <CarRentalDetailsEcoCheck {...carEmission} />
              </CarRentalDetailsEcoCheckContainer>
            )}
            <CarRentalMobileSupplier car={car} />
            <CarRentalMobilePrice
              car={car}
              isLoading={isLoading}
              isServiceFeeEnabled={!!featureFlags.isServiceFeeEnabled}
            />
          </Block>
        </Modal.Body>
      </Swap>
      <CarRentalMobileFooter
        onButtonClick={onAddCar}
        isDisabled={!isSelectable || isLoading || isError}
      />
    </Wrapper>
  )
}
