import { observer } from 'mobx-react-lite'
import type { DynamicSiteMessage } from '@fiji/graphql/types'
import type { IconColorPalette } from '@etta/ui/icon'
import type { MobileButtonTypeProps } from '@etta/ui/button'
import type { Alert } from './types'
import { MessagesList, AlertsList, NoMessagesSection } from './blocks'
import { useDSMState } from './use-dsm-state'
import { DynamicSiteMessagesModal } from './dynamic-site-messages-modal'
import { DynamicSiteMessagesLayout } from './layout'

type Props = {
  messages?: DynamicSiteMessage[]
  alerts?: Alert[]
  isPreview?: boolean
  buttonColor?: MobileButtonTypeProps
  color?: IconColorPalette
  withMobileTextIfExist?: boolean
}

export const DynamicSiteMessages = observer(function DynamicSiteMessages({
  messages = [],
  alerts = [],
  isPreview = false,
  buttonColor = 'primary',
  color = 'plum',
  withMobileTextIfExist = false,
}: Props) {
  const {
    messagesToShow,
    alertsToShow,
    hasMessages,
    isWithAlerts,
    handleExpand,
    title,
    modalTitle,
    message,
    messageIndex,
    messageRef,
    messageSlot,
    isExpanded,
    handleModalOpen,
    viewMoreTitle,
    hasMultipleItems,
    isWithMessages,
    isMessageTruncated,
    modalToggle,
    handleRemoveItem,
    hasDesktopBannerImage,
    totalCount,
    hasAlertIcon,
    anchorId,
    handleSetAnchor,
    scrollContainerRef,
    shouldExpandMessages,
    handleUpdateMessageIndex,
    handleSetAnchorId,
  } = useDSMState({
    initialMessages: messages,
    initialAlerts: alerts,
    isPreview,
    withMobileTextIfExist,
  })

  if (!hasMessages && !modalToggle.isOpen) {
    return null
  }

  return (
    <>
      <DynamicSiteMessagesLayout
        title={title}
        message={message}
        messageIndex={messageIndex}
        messageRef={messageRef}
        messageSlot={messageSlot}
        totalCount={totalCount}
        color={color}
        buttonColor={buttonColor}
        viewMoreTitle={viewMoreTitle}
        isWithAlerts={isWithAlerts}
        isExpanded={isExpanded}
        isMessageTruncated={isMessageTruncated}
        hasDesktopBannerImage={hasDesktopBannerImage}
        hasMessages={hasMessages}
        hasMultipleItems={hasMultipleItems}
        hasAlertIcon={hasAlertIcon}
        handleExpand={handleExpand}
        handleModalOpen={handleModalOpen}
        handleUpdateMessageIndex={handleUpdateMessageIndex}
        handleSetAnchorId={handleSetAnchorId}
      />
      <DynamicSiteMessagesModal
        title={modalTitle}
        isOpen={modalToggle.isOpen}
        color={color}
        scrollContainerRef={scrollContainerRef}
        onClose={modalToggle.handleClose}>
        <NoMessagesSection alerts={alertsToShow} messages={messagesToShow} />
        <AlertsList
          alerts={alertsToShow}
          anchorId={anchorId}
          isWithMessages={isWithMessages}
          hasMultipleItems={hasMultipleItems}
          shouldExpandMessages={shouldExpandMessages}
          onClose={handleRemoveItem}
          handleSetAnchor={handleSetAnchor}
        />
        <MessagesList
          messages={messagesToShow}
          anchorId={anchorId}
          color={color}
          hasMultipleItems={hasMultipleItems}
          shouldExpandMessages={shouldExpandMessages}
          onClose={handleRemoveItem}
          handleSetAnchor={handleSetAnchor}
        />
      </DynamicSiteMessagesModal>
    </>
  )
})
