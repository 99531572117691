import type { MouseEvent } from 'react'
import type { PWAIconNames, IconColorPalette, IconSizes } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { IconWrapper } from './input-icon-styled'

type IconWrapperProps = {
  isValueExist: boolean
  isPlaceholder?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}

type InputIconProps = {
  icon?: PWAIconNames
  size?: IconSizes
  color?: IconColorPalette
} & IconWrapperProps

export const InputIcon = ({
  icon,
  isValueExist,
  isPlaceholder,
  color,
  size,
  onClick,
}: InputIconProps) => {
  if (!icon) {
    return null
  }

  return (
    <IconWrapper
      aria-hidden
      isValueExist={isValueExist}
      isPlaceholder={isPlaceholder}
      onClick={onClick}>
      <Icon name={icon} color={color} size={size} />
    </IconWrapper>
  )
}
