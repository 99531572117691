import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Maybe, Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { ListItem, ListItemLabel } from './bill-list-item-styled'

export type BillListItemProps = {
  price?: Maybe<Rate>
  children: ReactNode
  priceAriaLabel?: string
  isSubtract?: boolean
}

const i18nBase = 'PostBooking.TripDetails'

export function BillListItem({
  price,
  children,
  priceAriaLabel = String(children),
  isSubtract = false,
}: BillListItemProps) {
  const { t } = useTranslation()
  const { mainCost: formattedPrice } = formatRate(price, { morpheme: 'postfix' })
  const ariaLabel = t(`${i18nBase}.CostSummary.ItemCost`, {
    item: priceAriaLabel || String(children),
    price: formattedPrice,
  })

  const priceValue = useMemo(() => {
    if (isSubtract) {
      return `- ${formattedPrice}`
    }

    return formattedPrice
  }, [isSubtract, formattedPrice])

  if (!formattedPrice) {
    return null
  }

  return (
    <ListItem>
      <ListItemLabel aria-label={ariaLabel}>{children}</ListItemLabel>
      <span>{priceValue}</span>
    </ListItem>
  )
}
