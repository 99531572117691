import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import {
  DownArrow,
  DeviationDescriptionContainer,
} from '../flight-details-eco-check-modal-mobile-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
  percentDeviation: number
}

export function FlightDetailsModalFullPercentDeviationDescription({
  deviationLevel,
  percentDeviation,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  switch (deviationLevel) {
    case EmissionsDeviationLevel.Average: {
      const description = t(i18nBase + '.AverageEmissions')
      return (
        <DeviationDescriptionContainer aria-label={description}>
          <Icon name="averagePWA" size={25} />
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </DeviationDescriptionContainer>
      )
    }
    case EmissionsDeviationLevel.Less: {
      const description = percentDeviation + '% ' + t(i18nBase + '.LowerEmissions')
      return (
        <DeviationDescriptionContainer aria-label={description}>
          <DownArrow name="upArrowPWA" size={25} />
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </DeviationDescriptionContainer>
      )
    }
    case EmissionsDeviationLevel.More: {
      const description = percentDeviation + '% ' + t(i18nBase + '.HigherEmissions')
      return (
        <DeviationDescriptionContainer aria-label={description}>
          <Icon name="upArrowPWA" size={25} />
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </DeviationDescriptionContainer>
      )
    }
  }
}
