import type { CarRentalType, CarRentalClassType } from '@fiji/graphql/types'
import { translateCarClass } from './translate-car-class'
import { translateCarType } from './translate-car-type'

type Args = {
  carType?: CarRentalType | null
  carClass?: CarRentalClassType | null
}

export function translateCarNameDetails({ carType, carClass }: Args) {
  const carClassTranslated = translateCarClass(carClass)
  const carTypeTranslated = translateCarType(carType)
  const carRentalTitle = `${carClassTranslated} ${carTypeTranslated}`

  return {
    carRentalTitle,
  }
}
