import { MediaLayout } from '@etta/ui/media-layout'
import { UnusedTicketsLoadingMobile } from './mobile'
import { UnusedTicketsLoadingDesktop } from './desktop'

export function UnusedTicketsLoadingLayout() {
  return (
    <MediaLayout
      mobileSlot={<UnusedTicketsLoadingMobile />}
      desktopSlot={<UnusedTicketsLoadingDesktop />}
    />
  )
}
