import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  flex-grow: 1;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const Tickets = styled.div`
  margin-bottom: 44px;
  max-height: 450px;
  overflow-y: scroll;
`
