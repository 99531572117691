import { Container, Inject, Store } from '@etta/di'
import type {
  TrainSegmentEntity,
  CarRentalSegmentEntity,
  FailedSegmentValueObject,
  FlightSegmentEntity,
  HotelSegmentEntity,
  ReviewTripValueObject,
  TripSegmentValueObject,
} from '@etta/modules/review-trip/core'
// eslint-disable-next-line import/no-restricted-paths
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
// eslint-disable-next-line import/no-restricted-paths
import { FeatureFlagsStore } from '@etta/modules/feature-flags/interface/stores/feature-flags.store'
import { SegmentType } from '@etta/core/enums/segment-type.enum'
import type { RateValueObject } from '@etta/core/value-objects'
import { subtractRate } from '@fiji/utils/rate'
import type { FlightSetSeat, FlightUnselectSeat, ToggleFlightSeat } from '../types'
import type { ComplianceMessageValueObject } from '../../core/value-objects/compliance-message.value-object'

const UUT_UNAPPLIED_CODES = ['NEW_TICKET_BY_FARE_RULE', 'NEW_TICKET_BY_FORFEITURE', 'USER_OPT_OUT']
const GST_TAX_CODES = Array.of('UO', 'NZ', 'WG', 'QR', 'XG')
@Store()
export class ReviewTripStore {
  isTripLoading = true

  isTripCreatingLoading = false

  isTripRemovingLoading = false

  isError = false

  isTripCreatingError = false

  isTripRemovingError = false

  tripId: string = ''

  cartId: string = ''

  trip: ReviewTripValueObject | null = null

  private _failedSegments: FailedSegmentValueObject[] = []

  constructor(
    @Inject() private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  get isTripExist() {
    return !!this.trip?.segments.length
  }

  get isHoldTripAllowed() {
    return (
      this.displayConfigurationStore.isHoldTripAllowed && // this indicates whether or not user is allowed to hold a trip from display config
      this.featureFlagsStore.flags.isOnHoldEnabled && // this indicates feature flag
      !!this.trip?.holdRules?.holdAllowed && // this indicates whether or not trip is holdable, received from gazoo
      !!this.trip?.tripStatus?.isHoldTripAllowed // this is mapped from gql, on the moment it checkes whether the itinerary is new, if it is = allowes to hold the trip.
    )
  }

  get autoCancellationTime() {
    return this.trip?.holdRules?.autoCancellationTime
  }

  get itineraryId() {
    return this.tripId
  }

  // FRI-199 Only rail shows 'Trip Total', other services still show 'EST. Total'
  get isTotalPrice() {
    return (
      this.segments.every((segment) => segment.type === SegmentType.Train) &&
      this.segments.length !== 0
    )
  }

  get segments() {
    let ret = this.trip?.segments
    if (this._failedSegments.length) {
      ret = this.trip?.segments.filter((segment) => {
        let id: string
        switch (segment.type) {
          case SegmentType.Flight:
            id = (segment as FlightSegmentEntity).legId
            break
          case SegmentType.Hotel:
            id = (segment as HotelSegmentEntity).id
            break
          case SegmentType.CarRental:
            id = (segment as CarRentalSegmentEntity).carId
            break
          default:
            id = 'NotHanldedFailingSegmentId'
        }

        for (const failedSegment of this._failedSegments) {
          if (failedSegment.id === id) {
            return false
          }
        }

        return true
      })
    }
    return ret || []
  }

  get rail(): TrainSegmentEntity | undefined {
    const segment = this.segments.filter((segment) => segment.type === SegmentType.Train)
    if (segment.length === 0) {
      return undefined
    }
    return segment[0] as TrainSegmentEntity
  }

  get railSegments() {
    if (this.rail?.segments.length === 0 || this.rail === undefined) {
      return []
    }
    return this.rail.segments
  }

  get firstRailSegment() {
    if (this.railSegments.length === 0) {
      return undefined
    }
    return this.railSegments[0]
  }

  get lastRailLegSegment() {
    if (this.railSegments.length === 0) {
      return undefined
    }
    return this.railSegments[this.railSegments.length - 1]
  }

  get itineraryExpiredDate() {
    const expiredAt = this.trip?.expiredAt

    if (expiredAt) {
      return new Date(expiredAt)
    }

    return undefined
  }

  get appliedRailCardNames() {
    const railSegments: TripSegmentValueObject[] = this.segments.filter(
      (segment) => segment.type === SegmentType.Train,
    )
    return [
      ...new Set(
        railSegments.map((segment) => (segment as TrainSegmentEntity).appliedRailCards).flat(),
      ),
    ]
  }

  get isUnusedTicketApplied() {
    return Boolean(this.unvalidatedUnusedTickets || this.validatedUnusedTickets)
  }

  get unusedTicket() {
    return (
      this.trip?.tripCost?.unusedTicket?.validated ?? this.trip?.tripCost?.unusedTicket?.unvalidated
    )
  }

  get unvalidatedUnusedTickets() {
    return this.trip?.tripCost?.unusedTicket?.unvalidated
  }

  get validatedUnusedTickets() {
    return this.trip?.tripCost?.unusedTicket?.validated
  }

  get unusedTicketStatus() {
    return this.trip?.tripCost?.unusedTicket?.status
  }

  get unusedTicketOriginalPrice() {
    return this.validatedUnusedTickets?.original ?? this.unvalidatedUnusedTickets?.original
  }

  get unusedTicketsRate() {
    return this.unvalidatedUnusedTickets
      ? this.unvalidatedUnusedTickets.total
      : this.validatedUnusedTickets?.total
  }

  get airTaxes() {
    const taxes = (this.trip?.tripCost?.payNow?.flight || []).flatMap(
      (segment) => segment?.fareTaxInfo || [],
    )
    return {
      taxes: taxes,
      isGST: taxes.filter((tax) => GST_TAX_CODES.includes(tax.code)).length > 0,
    }
  }

  get complianceMessages() {
    let complianceMessage = this.trip?.complianceMessage
    if (this.unusedTicketStatus && UUT_UNAPPLIED_CODES.includes(this.unusedTicketStatus)) {
      if (!complianceMessage) {
        complianceMessage = { warns: [] } as ComplianceMessageValueObject
      }
      complianceMessage.warns = complianceMessage.warns || []
      switch (this.unusedTicketStatus) {
        case 'NEW_TICKET_BY_FARE_RULE':
          complianceMessage.warns.push('kI18nNewTcktByFareRuleUUT')
          break
        case 'NEW_TICKET_BY_FORFEITURE':
          complianceMessage.warns.push('kI18nNewTcktByForfeitureUUT')
          break
        case 'USER_OPT_OUT':
          complianceMessage.warns.push('kI18nUserOptOutUUT')
          break
      }
    }
    return complianceMessage
  }

  setIsTripLoading(value: boolean) {
    this.isTripLoading = value
  }

  setCartId(value: string) {
    this.cartId = value
  }

  setIsTripCreatingLoading(value: boolean) {
    this.isTripCreatingLoading = value
  }

  setIsTripRemovingLoading(value: boolean) {
    this.isTripRemovingLoading = value
  }

  setIsTripCreatingError(value: boolean) {
    this.isTripCreatingError = value
  }

  setIsTripRemovingError(value: boolean) {
    this.isTripRemovingError = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setTrip(value: ReviewTripValueObject | null) {
    this.trip = value
  }

  setTripId(value: string) {
    this.tripId = value
  }

  setFailedSegments(value: FailedSegmentValueObject[]) {
    this._failedSegments = value
  }

  get failedSegments() {
    return this._failedSegments
  }

  dropTripStore() {
    this.tripId = ''
    this.trip = null
    this._failedSegments = []
  }

  // TEMP: for splunk dashboard purpose
  static getInstance() {
    return Container.get(ReviewTripStore)
  }

  setSeatFlightToTrip({ legIndex, segmentIndex, seatNumber, travelerId }: FlightSetSeat) {
    this.toggleSeatFlightTrip({ legIndex, segmentIndex, seatNumber, travelerId })
  }

  removeSeatFlightFromTrip({ legIndex, segmentIndex }: FlightUnselectSeat) {
    this.toggleSeatFlightTrip({ legIndex, segmentIndex })
  }

  getTotalRateWithUnusedTickets(segmentPrice: RateValueObject) {
    if (!this.unusedTicketsRate) {
      return segmentPrice
    }
    return subtractRate(segmentPrice, this.unusedTicketsRate)
  }

  private toggleSeatFlightTrip({
    legIndex,
    segmentIndex,
    seatNumber = '',
    travelerId = '',
  }: ToggleFlightSeat) {
    const modifiedTrip = this.trip
    if (!modifiedTrip) {
      return
    }
    const flightLeg = modifiedTrip.segments[legIndex] as FlightSegmentEntity
    if (!flightLeg) {
      return
    }
    flightLeg.segments = flightLeg.segments.map((segment, index) =>
      index === segmentIndex ? { ...segment, travelerId, travelerSeat: seatNumber } : segment,
    )
    modifiedTrip.segments[legIndex] = flightLeg
    this.setTrip({ ...modifiedTrip } as ReviewTripValueObject)
  }
}
