import { useState, useMemo } from 'react'
import type { Photo } from '@fiji/graphql/types'
import { PhotoSize } from '@fiji/graphql/types'
import type { GroupedPhotos } from './types'

type Args = {
  photos?: Photo[]
}

export function useHotelPhotos({ photos }: Args) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0)

  const handleUpdateCurrentPhotoIndex = (i: number) => {
    setCurrentPhotoIndex(i)
  }

  const handleOpenLightboxByIndex = (i: number) => {
    setIsLightboxOpen(true)
    setCurrentPhotoIndex(i)
  }

  const handleCloseLightBox = () => {
    setIsLightboxOpen(false)
    setCurrentPhotoIndex(0)
  }

  const photoData = useMemo(() => {
    const groupedPhotos: GroupedPhotos[] =
      photos
        ?.map((photo) => {
          const index = photo.index
          return {
            index,
            thumb: photos
              ?.filter((img) => img.index === index)
              ?.find((img) => img.size === PhotoSize.Thumb)?.url,
            large: photos
              ?.filter((img) => img.index === index)
              ?.find((img) => img.size === PhotoSize.Large)?.url,
          }
        })
        .filter((photo, i, arr) => i === arr.findIndex((grouped) => grouped.index === photo.index))
        .map((arr, i) => ({ ...arr, order: i })) || []

    return {
      groupedPhotos,
      firstFivePhotos: groupedPhotos.slice(1, 5),
      isGalleryLink: (i: number) => i === 3 && groupedPhotos.length > 5,
    }
  }, [photos])

  return {
    currentPhotoIndex,
    isLightboxOpen,
    handleOpenLightboxByIndex,
    handleCloseLightBox,
    handleUpdateCurrentPhotoIndex,
    ...photoData,
  }
}
