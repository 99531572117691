import { getDifferenceInDays } from '@fiji/utils/dates/get-difference-in-days'
import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates/get-date-without-timezone-offset'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { Hotel, CalculateHotelEmissionsBatchHotelInput } from '@fiji/graphql/hooks'

export function mapToThrustCarbonHotel(
  hotel: Hotel,
  checkIn?: Date | null,
  checkOut?: Date | null,
): CalculateHotelEmissionsBatchHotelInput {
  const nights = mapNumberOfNights(checkIn, checkOut)

  const checkInDateWithoutTz = getDateWithoutTimezoneOffset(checkIn as Date)
  return {
    customRef: hotel.id,
    name: hotel.name,
    rating: hotel.starRating,
    location: {
      // We expect the values to always be present.
      // Any issues related to this query is tracked via Rollbar
      lat: hotel.address.geocode?.lat as number,
      long: hotel.address.geocode?.long as number,
    },
    checkIn: dateFormat(checkInDateWithoutTz, 'yyyy-MM-dd'),
    nights: nights,
    hotelId: hotel.id,
  }
}

export function mapNumberOfNights(checkIn?: Date | null, checkOut?: Date | null): number {
  if (!checkIn || !checkOut) {
    return 1
  }

  return getDifferenceInDays(checkOut, checkIn)
}
