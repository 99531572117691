import { Block } from '@etta/ui/block'
import type { Props } from '../../types'
import {
  OneRow,
  LocationSlot,
  RightSlot,
  InputsRow,
  CloseButton,
} from './air-search-flight-desktop-styled.new'

export function AirSearchFlightDesktopNew({
  cabinClassSlot,
  calendarSlot,
  locationSlot,
  warningSlot,
  isTwoSections,
  closeButton,
  isCabinClassInRow,
}: Props) {
  return (
    <>
      <OneRow>
        <InputsRow>
          <LocationSlot isTwoSections={isTwoSections}>{locationSlot}</LocationSlot>

          <RightSlot isTwoSections={isTwoSections}>
            {calendarSlot}
            {isCabinClassInRow && cabinClassSlot}
          </RightSlot>
        </InputsRow>
        {closeButton && <CloseButton>{closeButton}</CloseButton>}
      </OneRow>
      {warningSlot && <Block>{warningSlot}</Block>}
    </>
  )
}
