import { Action, Inject } from '@etta/di'
import { ZooKeeperConfigurationService } from '../services/'

@Action()
export class ZooKeeperConfigurationAction {
  constructor(
    @Inject()
    private readonly zooKeeperConfigurationService: ZooKeeperConfigurationService,
  ) {}

  async loadZooKeeperConfiguration() {
    await this.zooKeeperConfigurationService.loadZooKeeperConfiguration()
  }

  async refetchZooKeeperConfiguration() {
    await this.zooKeeperConfigurationService.refetchZooKeeperConfiguration()
  }
}
