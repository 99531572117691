import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace HomeAirportErrors {
  export const UnexpectedHomeAirportError = createErrorClass('UnexpectedHomeAirportError')
  export const GetHomeAirportError = createErrorClass('GetHomeAirportError')
  export const GetHomeAirportEmpty = createErrorClass('GetHomeAirportEmpty')
}

export type HomeAirportErrorsInstances = InstanceType<
  typeof HomeAirportErrors[keyof typeof HomeAirportErrors]
>
