import { useHistory } from 'react-router-dom'
import { useItinerary } from '@fiji/hooks/itinerary/use-itinerary'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import {
  tripReviewItineraryIdSelector,
  tripReviewStatusSelector,
} from '@fiji/store/trip-review-params'
import { postPurchaseEntranceSelector } from '@fiji/store/post-purchase-entrance'
import { useAppSelector } from '@fiji/store'
import { ROUTES } from '@fiji/routes'

export function useBackSearch() {
  const history = useHistory()
  const { queryParams } = useQueryParams<{ bookingId: string; transactionGroupId: string }>(
    {},
    { caseStyle: 'camelCase' },
  )
  const tripReviewStatus = useAppSelector(tripReviewStatusSelector)
  const tripReviewItineraryId = useAppSelector(tripReviewItineraryIdSelector)
  const postPurchaseNavigation = useAppSelector(postPurchaseEntranceSelector)

  const { itineraryId } = useItinerary()
  const { bookingId, transactionGroupId } = queryParams

  const onBack = () => {
    if (queryParams.bookingId && postPurchaseNavigation === 'fromTripDetails') {
      history.push(
        ROUTES.postBooking.trip({
          id: queryParams.bookingId,
          transactionGroupId,
          isForceRefetch: false,
        }),
      )
      return
    }
    switch (tripReviewStatus) {
      case 'update':
      case 'create':
        history.replace(
          ROUTES.reviewTrip.main({ itineraryId: itineraryId || tripReviewItineraryId, bookingId }),
        )
        return
      default:
        history.replace(ROUTES.explore)
    }
  }

  return { onBack }
}
