import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { Section } from '../filters-styled'
import { Headline } from './filter-category-layout-styled'

type Props = {
  label: string
  children: ReactNode
  onClear?: () => void
  isClearButtonDisabled?: boolean
  selectedNumber?: number
  isClearHidden?: boolean
  isHidden?: boolean
  'data-tracking-id'?: string
}
const i18nBase = 'QuickFilters'

export function FilterCategorySection({
  label,
  children,
  onClear,
  isClearButtonDisabled,
  selectedNumber,
  isClearHidden = true,
  isHidden = false,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelFiltersModalEnabled } = featureFlagsStore.flags

  if (isHidden) {
    return null
  }

  return (
    <Section withBorder data-tracking-id={dataTrackingId}>
      <Block marginBottom={10}>
        <Headline>
          <Text variant="headline" color="mainText">
            {label}
          </Text>
          {isNewHotelFiltersModalEnabled && (
            <>
              {!!selectedNumber && (
                <Text tabIndex={0} variant="footnoteMedium" color="bodyText">
                  {t(i18nBase + '.Selected', { selectedNumber })}
                </Text>
              )}
              {!isClearHidden && (
                <Link
                  aria-label={t(i18nBase + '.TabToClear', {
                    label: t(i18nBase + '.Clear'),
                  })}
                  isDisabled={isClearButtonDisabled}
                  onClick={onClear}>
                  {t(i18nBase + '.Clear')}
                </Link>
              )}
            </>
          )}
        </Headline>
      </Block>
      {children}
    </Section>
  )
}
