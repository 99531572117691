import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div<{ withFooter?: boolean; containerWidth?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ containerWidth }) => (containerWidth ? `${containerWidth}px` : '100%')};
  border: 1px solid ${(p) => p.theme.colors.borderDark};
  box-shadow: 0px 0px 14px rgb(0 0 0 / 14%);
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  z-index: 100;
  > button {
    background-color: ${(p) => p.theme.colors.white};
    border: 0;
  }
  height: 144px;
  max-height: 144px;
  transition: all 0.2s ease-out, opacity 0.2s;
  ${({ withFooter }) =>
    withFooter &&
    css`
      height: 224px;
      max-height: 224px;
    `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 64px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

const opacityAnimation = keyframes`
  from { opacity: 0; }
  30% { opacity: 0; }
  to { opacity: 1; }
`

export const Footer = styled.div`
  padding: 16px 24px;
  animation: ${opacityAnimation};
  animation-iteration-count: 1;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`
