import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  border-radius: 14px;
  background: ${(props) => props.theme.colors.white};
`

export const Heading = styled(Text)`
  text-transform: uppercase;
  padding: 14px 16px;
  color: ${(props) => props.theme.colors.bodyText};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Section = styled.div<{ isFooterExist: boolean }>`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  ${(props) => {
    if (props.isFooterExist) {
      return css`
        &:last-child {
          padding-bottom: 14px;
          margin-bottom: 16px;
          border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
        }
      `
    }
  }}
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TotalCost = styled.div<{ isFooterSlotExist: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.isFooterSlotExist &&
    css`
      border-bottom: 1px solid ${props.theme.colors.borderLight};
      padding-bottom: 14px;
    `};
`
