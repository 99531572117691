export enum MobilitySearchResultErrorType {
  Undefined = 'Undefined',
  NoAdvancedBookingAvailable = 'NoAdvancedBookingAvailable',
  NoRidesInLocation = 'NoRidesInLocation',
  ArrivalFlightNotFound = 'ArrivalFlightNotFound',
  ErrorPaymentMethodNotSet = 'ErrorPaymentMethodNotSet',
  ErrorLocationUnavailable = 'ErrorLocationUnavailable',
  AirportPickupOutOfPolicy = 'AirportPickupOutOfPolicy',
  DistanceExceeded = 'DistanceExceeded',
  AirportPickupNotAvailable = 'AirportPickupNotAvailable',
}
