import type { GazooMembership, LoyaltyProgram } from '@fiji/graphql/types'

type Props = {
  loyaltyFromProfile?: LoyaltyProgram[] | null
  loyaltyFromApp?: LoyaltyProgram[] | null
}

export function composeLoyaltyProgram({ loyaltyFromProfile, loyaltyFromApp }: Props) {
  if (!loyaltyFromProfile || !loyaltyFromApp) {
    return []
  }

  const extractAppLoyalty = loyaltyFromApp
    .map((el) => el.number && { vendorCode: el.vendorCode, number: el.number })
    .filter(Boolean)

  const extractProfileLoyalty = loyaltyFromProfile
    .map((profile) =>
      loyaltyFromApp.find((app) => app.vendorCode === profile.vendorCode)
        ? null
        : { vendorCode: profile.vendorCode, number: profile.number },
    )
    .filter(Boolean)

  return [...extractProfileLoyalty, ...extractAppLoyalty] as GazooMembership[]
}
