import { Result } from 'fnscript'
import { Inject, Service } from '@etta/di'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
// eslint-disable-next-line import/no-restricted-paths
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import { UserAdapter } from '@etta/modules/user/infra/user.adapter'
import type { UserAdapterResult } from '@etta/modules/user/infra/types'
import type { UserErrorsInstances } from '@etta/modules/user/core/errors'
import type {
  ApproversValueObject,
  MisValueObject,
  EmergencyContactValueObject,
  EmployeeValueObject,
  PreferredCardsInput,
  SettingsCardValueObject,
  NotificationValueObject,
  AdditionalDocumentsPreferencesInputValueObject,
  DelegatesUpdateInputValueObject,
} from '@etta/modules/user'
import { UserService } from '@etta/modules/user/interface/services/get-user.service'
import type { NewCreditCardInputValueObject } from '@etta/modules/user/core/value-objects/new-credit-card-input.value-object'
import { BaseSettingsStore } from '../stores/base.store'
import { FieldSettingsStore } from '../stores/field-settings'
import { SettingsAdapter } from '../../infra/settings.adapter'
import { AddressFields } from '../../core/enums/address-fields'
import type { EmployeeInformationInput } from '../stores/employee-information.store'
import type {
  SaveActionsInput,
  SaveActionsMode,
  SaveActionsReturn,
  PersonalInformationUpdateType,
  SaveActionsOperation,
} from './save-actions-type'

type SaveUserArgs = {
  mode: SaveActionsMode
  operation?: SaveActionsOperation
  input: SaveActionsInput
  handleClose: () => void
  customActionsIfSuccess?: () => void
  customActionsAfterReload?: () => void
}

@Service()
export class BaseSettingsService {
  constructor(
    @Inject()
    private readonly userStore: UserStore,
    @Inject()
    private readonly userService: UserService,
    @Inject()
    private readonly userAdapter: UserAdapter,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
    @Inject()
    private readonly fieldSettingsStore: FieldSettingsStore,
  ) {}

  getSaveUserInput(action: SaveActionsMode, input: SaveActionsInput): SaveActionsReturn {
    switch (true) {
      case action === 'notifications':
        return {
          notification: input,
        } as { notification: NotificationValueObject[] }
      case action === 'emergency-contact':
        return {
          emergencyContact: input,
        } as { emergencyContact: EmergencyContactValueObject }
      case action === 'employee-information':
        const { approvers, mis, ...employeeInput } = input as EmployeeInformationInput
        return {
          employee: {
            ...employeeInput,
            manager: undefined, // FIXME: SBB-64 This should be return properly when profile update mutation support updating with manager.externalId
            managerId: undefined,
          },
          approvers,
          mis,
        } as {
          employee: EmployeeValueObject
          approvers: ApproversValueObject
          mis: MisValueObject
        }
      case action === 'personal-information':
        return input as PersonalInformationUpdateType
      case action === 'preferred-cards':
        return {
          preferredCreditCards: input,
        } as { preferredCreditCards: PreferredCardsInput[] }
      case action === 'new-credit-card': {
        return input as NewCreditCardInputValueObject
      }
      case action === 'settings-card':
        return input as SettingsCardValueObject
      case action === 'additional-documents-preferences':
        return {
          additionalDocumentsPreferences: input as AdditionalDocumentsPreferencesInputValueObject,
        }
      case action === 'delegates-and-authorizers':
        return input as DelegatesUpdateInputValueObject

      default:
        return {}
    }
  }

  getProfileFromUser() {
    return this.userStore.profile
  }

  getUser() {
    return this.userStore.user
  }

  getStructuredProfile() {
    const profile = this.getProfileFromUser()
    return {
      fullName: `${profile?.firstName} ${profile?.lastName}`,
      email: profile?.personalContact?.email || '',
      homeAddress: {
        [AddressFields.Country]: this.getStrValueOr(profile?.personalContact?.address?.countryCode),
        [AddressFields.City]: this.getStrValueOr(profile?.personalContact?.address?.city),
        [AddressFields.State]: this.getStrValueOr(profile?.personalContact?.address?.stateCode),
        [AddressFields.Mailstop]: this.getStrValueOr(profile?.personalContact?.address?.mailStop),
        [AddressFields.Zip]: this.getStrValueOr(profile?.personalContact?.address?.postalCode),
        [AddressFields.Street1]: this.getStrValueOr(profile?.personalContact?.address?.street1),
        [AddressFields.Street2]: this.getStrValueOr(profile?.personalContact?.address?.street2),
        homeEmail: this.getStrValueOr(profile?.personalContact?.homeEmail),
        homePhone: {
          number: this.getStrValueOr(profile?.personalContact?.phone?.number),
          countryCode: profile?.personalContact?.phone?.countryCode,
        },
      },
      businessAddress: {
        [AddressFields.Country]: this.getStrValueOr(profile?.businessContact?.address?.countryCode),
        [AddressFields.City]: this.getStrValueOr(profile?.businessContact?.address?.city),
        [AddressFields.State]: this.getStrValueOr(profile?.businessContact?.address?.stateCode),
        [AddressFields.Mailstop]: this.getStrValueOr(profile?.businessContact?.address?.mailStop),
        [AddressFields.Zip]: this.getStrValueOr(profile?.businessContact?.address?.postalCode),
        [AddressFields.Street1]: this.getStrValueOr(profile?.businessContact?.address?.street1),
        [AddressFields.Street2]: this.getStrValueOr(profile?.businessContact?.address?.street2),
        officePhone: {
          ext: this.getStrValueOr(profile?.businessContact?.officePhone?.ext),
          number: this.getStrValueOr(profile?.businessContact?.officePhone?.number),
          officeExtension: profile?.businessContact?.officePhone?.officeExtension,
          countryCode: profile?.businessContact?.officePhone?.countryCode,
        },
        faxPhone: {
          number: this.getStrValueOr(profile?.businessContact?.faxPhone?.number),
          countryCode: profile?.businessContact?.faxPhone?.countryCode,
        },
        mobilePhone: {
          number: this.getStrValueOr(profile?.businessContact?.mobilePhone?.number),
          countryCode: profile?.businessContact?.mobilePhone?.countryCode,
        },
        confirmationEmail: this.getStrValueOr(profile?.confEmail),
        groupwareId: this.getStrValueOr(profile?.groupware?.groupwareId),
        businessEmail: this.getStrValueOr(profile?.personalContact?.email),
      },
      initials: `${this.getStrValueOr(profile?.firstName?.[0])}${this.getStrValueOr(
        profile?.lastName?.[0],
      )}`,
      personalInformation: {
        title: profile?.title || null,
        firstName: this.getStrValueOr(profile?.firstName),
        middleName: this.getStrValueOr(profile?.middleName),
        lastName: this.getStrValueOr(profile?.lastName),
        suffix: profile?.suffix || null,
        gender: profile?.gender || null,
        dateOfBirth: profile?.dateOfBirth || undefined,
        phone: profile?.personalContact?.phone || undefined,
        username: this.getStrValueOr(profile?.username),
        profilePin: this.getStrValueOr(profile?.profilePin),
      },
      emergencyContact: profile?.emergencyContact || {},
      employee: {
        // align isVip default value with the UI profile form default value
        isVip: profile?.employee?.isVip || false,
        isActiveInCompany: profile?.employee?.isActiveInCompany || null,
        employeeType: this.getStrValueOr(profile?.employee?.employeeType),
        employeeId: this.getStrValueOr(profile?.employee?.employeeId),
        jobTitle: this.getStrValueOr(profile?.employee?.jobTitle),
        jobLevel: this.getStrValueOr(profile?.employee?.jobLevel),
        managerId: this.getStrValueOr(profile?.employee?.manager?.externalId),
        defaultApproverId: profile?.employee?.defaultApproverId || null,
        costCenter: this.getStrValueOr(profile?.employee?.costCenter),
        departmentName: this.getStrValueOr(profile?.employee?.departmentName),
        departmentCode: this.getStrValueOr(profile?.employee?.departmentCode),
        division: this.getStrValueOr(profile?.employee?.division),
        businessUnit: this.getStrValueOr(profile?.employee?.businessUnit),
        companyName: this.getStrValueOr(profile?.employee?.companyName),
        approvers: {
          expenseApproverId: this.getStrValueOr(profile?.approvers?.expenseApproverId),
          purchaseApproverId: this.getStrValueOr(profile?.approvers?.purchaseApproverId),
          travelApproverId: this.getStrValueOr(profile?.approvers?.travelApproverId),
        },
        mis: {
          mis1: this.getStrValueOr(profile?.mis?.mis1),
          mis2: this.getStrValueOr(profile?.mis?.mis2),
          mis3: this.getStrValueOr(profile?.mis?.mis3),
          mis4: this.getStrValueOr(profile?.mis?.mis4),
          mis5: this.getStrValueOr(profile?.mis?.mis5),
          mis6: this.getStrValueOr(profile?.mis?.mis6),
          mis7: this.getStrValueOr(profile?.mis?.mis7),
        },
      },
      preferredCreditCards: profile?.preferredCreditCards,
      creditCards: profile?.creditCards,
      passports: profile?.passports,
      visas: profile?.visas,
      nationalIdCards: profile?.nationalIdCards,
      notification: profile?.notification,
      additionalDocumentsPreferences: profile?.additionalDocumentsPreferences,
    }
  }

  async saveUser({
    mode,
    input,
    operation,
    handleClose,
    customActionsIfSuccess,
    customActionsAfterReload,
  }: SaveUserArgs): UserAdapterResult<{ success: boolean }> {
    const { companyId, customerId, delegatedId } = this.userStore.userCommonData

    if (operation === 'delete') {
      this.baseSettingsStore.setDeletingState()
    } else {
      this.baseSettingsStore.setSavingState()
    }

    const { firstName, lastName, middleName } = this.getProfileFromUser() || {}

    if (!firstName || !lastName || !companyId || !customerId) {
      return Result.Ok<{ success: boolean }, UserErrorsInstances>({ success: false })
    }

    const result = await this.userAdapter.updateUser({
      input: {
        companyId,
        customerId: delegatedId || customerId,
        firstName,
        lastName,
        middleName,
        ...this.getSaveUserInput(mode, input),
      },
    })

    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()

      return result
    }

    customActionsIfSuccess?.()
    if (operation === 'delete') {
      this.baseSettingsStore.setDeletedState()
    } else {
      this.baseSettingsStore.setSavedState()
    }
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(1000)
    await this.userService.reloadUser(customActionsAfterReload)
    return result
  }

  async loadProfileFieldsSettings() {
    const result = await this.settingsAdapter.getProfileFieldsSettings()

    result.match({
      Ok: (data) => {
        this.fieldSettingsStore.setProfileFieldsSettings(data)
        this.userStore.setIsLoading(false)
      },
      Err: () => {
        this.userStore.setIsError(true)
        this.userStore.setIsLoading(false)
      },
    })
  }

  private getStrValueOr(value?: string | null, defaultValue: string = '') {
    if (value) {
      return value
    }
    return defaultValue
  }
}
