import type { PlainTime } from '@fiji/types'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { TimePickerProps } from './types'

export function useSwipingTimePickerDesktop({
  defaultValue,
  value,
  onApply,
  onClose,
}: {
  defaultValue: TimePickerProps['defaultValue']
  value: TimePickerProps['value']
  onApply: TimePickerProps['onApply']
  onClose: TimePickerProps['onClose']
}) {
  let timeLabel = ''

  if (value) {
    timeLabel = getFormattedTime(value, 'p')
  } else if (defaultValue) {
    timeLabel = getFormattedTime(defaultValue, 'p')
  }

  const timeRangeOptions = Array.from({ length: 24 }, (_, i) => i)
    .map((hour) => [
      { hours: hour, minutes: 0 },
      { hours: hour, minutes: 15 },
      { hours: hour, minutes: 30 },
      { hours: hour, minutes: 45 },
    ])
    .flat()

  const handleTimeSelect = (time: PlainTime) => () => {
    onApply(time)
    onClose()
  }

  return {
    timeLabel,
    timeRangeOptions,
    handleTimeSelect,
  }
}
