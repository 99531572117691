import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'

export function useGetInfoText(type: SegmentType) {
  const { t } = useTranslation()
  const i18nBase = 'ActionButtons.'

  switch (type) {
    case SegmentType.Flight:
      return t(i18nBase + 'InfoFlightButton')
    case SegmentType.Hotel:
      return null
    case SegmentType.CarRental:
      return t(i18nBase + 'InfoCarRentalButton')
    case SegmentType.CarService:
      return t(i18nBase + 'InfoCarServiceButton')
    case SegmentType.Train:
      return t(i18nBase + 'InfoTrainTicketRestrictionsButton')
    default:
      return 'Change'
  }
}
