import styled from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const Content = styled.div`
  padding: 16px;
`

export const Location = styled.div`
  position: relative;
  margin: 0 0 30px;
  padding-left: 23px;

  &:before {
    position: absolute;
    content: '';
    top: 5px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    top: 10px;
    bottom: -40px;
    left: 4px;
    width: 2px;
    background: ${(props) => props.theme.colors.borderDark};
  }

  &:first-child {
    &:before {
      background: ${(props) => props.theme.colors.success};
    }
  }

  &:last-child {
    margin: 0 0 3px;

    &:before {
      background: ${(props) => props.theme.colors.error};
    }

    &:after {
      content: none;
    }
  }
`

export const LocationItem = styled.div`
  margin: 0 0 3px;
`

export const MapWrapper = styled.div``
