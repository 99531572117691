import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.white};
`

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 7px;
`

export const IconWrapper = styled(Icon)`
  margin-top: -5px;
`

export const TitleAndPrices = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`

export const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`

export const PricesAndLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`

export const RoomRatesLinkWrapper = styled.div`
  text-align: right;
`

export const DatesWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`
