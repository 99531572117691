import { useMedia } from 'react-use'
import { screenSize } from '@fiji/style'
import type { LayoutProps } from '../../types'
import { HotelSearchFormDesktopContent } from './hotel-search-form-desktop-content'
import { ContainerPage } from './hotel-search-form-desktop-styled'

export function HotelSearchFormDesktop(props: LayoutProps) {
  const {
    handleSearch,
    handleLocationChange,
    location,
    placesHistory,
    checkOutDate,
    checkInDate,
    onDateChange,
  } = props

  const isMaxLaptop = useMedia(`only screen and (${screenSize.maxLaptop})`)

  return (
    <ContainerPage isMaxLaptop={isMaxLaptop}>
      <HotelSearchFormDesktopContent
        location={location}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        placesHistory={placesHistory}
        onSearchClick={handleSearch}
        onDateChange={onDateChange}
        handleLocationChange={handleLocationChange}
      />
    </ContainerPage>
  )
}
