import { Block } from '@etta/ui/block'
import type { Props } from '../types'
import { HotelDistanceSlider } from '../hotel-distance-slider'
import { Container } from './hotel-distance-desktop-styled'

export function HotelDistanceDesktop({ distance, onChange }: Props) {
  return (
    <Container>
      <Block paddingHorizontal={16} paddingVertical={16}>
        <HotelDistanceSlider onChange={onChange} distance={distance} />
      </Block>
    </Container>
  )
}
