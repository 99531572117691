import { Action, Inject } from '@etta/di'
import { CheckoutInfoStore } from './checkout-info.store'
import type { SetAirPreferences, SetHotelPreferences, SetCarRentalPreferences } from './types'

@Action()
export class CheckoutInfoPreferencesAction {
  constructor(@Inject() private checkoutInfoStore: CheckoutInfoStore) {}

  setAirPreferences: SetAirPreferences = ({ membership, specialRequest, meal }) => {
    this.checkoutInfoStore.memberships.appendMembershipValue('air', membership)
    this.checkoutInfoStore.specialRequests.appendSpecialRequestValue('air', specialRequest)
    this.checkoutInfoStore.meal.appendMealValue('air', meal)
  }

  setHotelPreferences: SetHotelPreferences = ({ membership, specialRequest }) => {
    this.checkoutInfoStore.memberships.appendMembershipValue('hotel', membership)
    this.checkoutInfoStore.specialRequests.appendSpecialRequestValue('hotel', specialRequest)
  }

  setCarRentalPreferences: SetCarRentalPreferences = ({ membership, specialRequest }) => {
    this.checkoutInfoStore.memberships.appendMembershipValue('carRental', membership)
    this.checkoutInfoStore.specialRequests.appendSpecialRequestValue('carRental', specialRequest)
  }
}
