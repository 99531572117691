import { toJS } from 'mobx'
import { Inject, Store } from '@etta/di'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import { Toggle } from '@etta/interface/services/toggle'
import { HotelSearchResultsStore } from './hotel-search-results.store'
import { HotelQueryStore } from './hotel-search-query.store'
import { HotelFiltersStore } from './hotel-filters.store'

@Store()
export class HotelQuickFiltersStore {
  constructor(
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly hotelFiltersStore: HotelFiltersStore,
  ) {}

  brandsModal = new Toggle()
  starRatingModal = new Toggle()
  categoriesModal = new Toggle()
  amenitiesModal = new Toggle()

  get hotelBrandsValue() {
    const brands = this.hotelSearchResultsStore.filterOptions.brands.brands.reduce(
      (acc: string[], brand) => {
        if (this.hotelQueryStore.hotelSearchQuery.hotelFilters?.brandIds.includes(brand.brandId)) {
          acc.push(brand.brandDisplayName)
        }
        return acc
      },
      [],
    )

    const superBrand = this.hotelSearchResultsStore.filterOptions.brands.superBrands.reduce(
      (acc: string[], brand) => {
        brand.brands.forEach((subBrand) => {
          if (
            this.hotelQueryStore.hotelSearchQuery.hotelFilters?.brandIds.some(
              (selectedBrandId) => selectedBrandId === subBrand.brandId,
            )
          ) {
            acc.push(brand.brandDisplayName)
          }
        })
        return acc
      },
      [],
    )

    return [...brands, ...superBrand].join(', ') ?? ''
  }

  get starRatingValues() {
    return (
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.starRatings
        .sort((a, b) => a - b)
        .join(', ') ?? ''
    )
  }

  get hotelGroupValue() {
    const group = this.hotelSearchResultsStore.filterOptions.groups.find(
      (group) => group.id === this.hotelQueryStore.hotelSearchQuery.hotelFilters?.groupId,
    )

    return group?.name ?? ''
  }

  get amenitiesValue() {
    const amenities = this.hotelSearchResultsStore.filterOptions.amenities.amenities.reduce(
      (acc: string[], amenity) => {
        if (
          this.hotelQueryStore.hotelSearchQuery.hotelFilters?.amenityIds.includes(amenity.amenityId)
        ) {
          acc.push(amenity.amenityDisplayName)
        }
        return acc
      },
      [],
    )
    return amenities.join(', ') ?? ''
  }

  get isAmenitiesApplyButtonDisable() {
    return checkIsEqual(
      toJS(this.hotelFiltersStore.selectedFilters.amenityIds),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.amenityIds,
    )
  }

  get isAmenitiesClearButtonDisable() {
    return checkIsEqual(toJS(this.hotelFiltersStore.selectedFilters.amenityIds), [])
  }

  get isCategoriesApplyButtonDisable() {
    return checkIsEqual(
      toJS(this.hotelFiltersStore.selectedFilters.groupId),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.groupId,
    )
  }

  get isCategoriesClearButtonDisable() {
    return checkIsEqual(
      toJS(this.hotelFiltersStore.selectedFilters.groupId),
      this.hotelSearchResultsStore.initialGroupId,
    )
  }

  get isStarRatingApplyButtonDisable() {
    return checkIsEqual(
      toJS(this.hotelFiltersStore.selectedFilters.starRatings),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.starRatings,
    )
  }

  get isStarRatingClearButtonDisable() {
    return checkIsEqual(toJS(this.hotelFiltersStore.selectedFilters.starRatings), [])
  }

  get isBrandsApplyButtonDisable() {
    return checkIsEqual(
      toJS(this.hotelFiltersStore.selectedFilters.brandIds),
      this.hotelQueryStore.hotelSearchQuery.hotelFilters?.brandIds,
    )
  }

  get isBrandClearButtonDisable() {
    return checkIsEqual(toJS(this.hotelFiltersStore.selectedFilters.brandIds), [])
  }
}
