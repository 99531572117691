import { useTranslation } from 'react-i18next'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { formatRate } from '@fiji/utils/money'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import { sumRates } from '@fiji/utils/sum-rate'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { TaxBreakdownList } from '@etta/components/tax-breakdown-list'
import {
  Container,
  CheckedIcon,
  DoubleCurrency,
  Unit,
  Separator,
} from '../segment-section/segment-section-styled'
import { FlightCostLabelAndName } from './flight-cost-label-and-name'

type Props = {
  segments: FlightPostBookingValueObject[]
}

const i18nBase = 'PaymentSummary.'

export function FlightSegments({ segments }: Props) {
  const { t } = useTranslation()

  const areRoundTripSegments = segments?.some((segment) => segment.isRoundTripLeg)

  const taxes = formatRate(sumRates(segments.map((segment) => segment.taxes)), {
    morpheme: 'none',
  }).mainCost

  const unusedTicket = formatRate(sumRates(segments.map((segment) => segment.unusedTicketRate)), {
    morpheme: 'none',
  }).mainCost

  const { mainCost: mainTotal, secondCost: secondaryTotal } = formatRate(
    sumRates(segments.map((segment) => segment?.totalWithUnusedTicket || segment?.totalCost)),
    {
      morpheme: 'postfix',
    },
  )

  const { postBookingTripStore } = usePostBookingContext()
  const { featureFlags } = useFeatureFlags()
  const isTripOnHold = postBookingTripStore.trip.type === TripStatus.OnHold
  const airTaxes = postBookingTripStore.airTaxes
  const showGSTTaxes = featureFlags.isRetailShoppingUIEnabled && airTaxes.isGST

  if (!segments.length) {
    return null
  }

  return (
    <Container>
      <Text variant="footnoteStrong">{t(i18nBase + 'Flight')}</Text>
      {segments?.map((segment, index) => {
        if (!segment.totalCost && areRoundTripSegments) {
          return null
        }

        return (
          <Block key={index} marginBottom={8}>
            <FlightCostLabelAndName flightFragment={segment} />
          </Block>
        )
      })}
      {unusedTicket && (
        <Unit>
          <Text variant="footnoteMedium" color="bodyText">
            {t(i18nBase + 'UnusedTicket')}
          </Text>
          <Text variant="footnoteMedium" color="bodyText">
            {`-${unusedTicket}`}
          </Text>
        </Unit>
      )}
      <Unit>
        <Text variant="footnoteMedium" color="bodyText">
          {t(i18nBase + 'TaxesAndFees')}
        </Text>
        <Text variant="footnoteMedium" color="bodyText">
          {taxes}
        </Text>
      </Unit>
      {showGSTTaxes && <TaxBreakdownList taxes={airTaxes.taxes} />}
      <Separator />
      <Unit>
        <Text variant="subHeadStrong">
          {isTripOnHold ? t(i18nBase + 'PayableNow') : t(i18nBase + 'Paid')}
          {!isTripOnHold && <CheckedIcon name="checkFilledPWA" size="small" color="success" />}
        </Text>
        <DoubleCurrency>
          <Text variant="subHeadStrong">{mainTotal}</Text>
          <Text variant="footnoteMedium" color="bodyText">
            {secondaryTotal}
          </Text>
        </DoubleCurrency>
      </Unit>
    </Container>
  )
}
