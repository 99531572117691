import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { StyledTextVariant } from '@fiji/enums'
import { ROUTES } from '@fiji/routes'
import { useSupportTravelContext } from '@etta/modules/support-travel/interface/use-support-travel.context'
import { SupportTravelBody } from '../../../components/support-travel-body'
import {
  PreviewContainer,
  TravelSupportHeader,
  TravelSupportHeaderTitle,
  NavItem,
  NavText,
} from './preview-support-travel-mobile-styled'

export const PreviewSupportTravelMobile = observer(() => {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support'

  const { supportTravelStore } = useSupportTravelContext()

  return (
    <PreviewContainer>
      <TravelSupportHeader>
        <TravelSupportHeaderTitle
          textVariant={StyledTextVariant.bodyLarge}
          data-tracking-id="travel-support-text">
          {t(i18Base + '.MainPage.TravelSupport')}
        </TravelSupportHeaderTitle>
        <NavItem
          disabled={supportTravelStore.isError || supportTravelStore.isLoading}
          to={ROUTES.support.travel}
          data-tracking-id="see-all-link">
          <NavText textVariant={StyledTextVariant.linkNormal}>
            {t(i18Base + '.MainPage.SeeAll')}
          </NavText>
        </NavItem>
      </TravelSupportHeader>
      <SupportTravelBody />
    </PreviewContainer>
  )
})
