import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'

export const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 36px;
  height: 36px;
  margin-right: 10px;
  background: ${(p) => p.theme.colors.bodyText};

  ${Text} {
    line-height: 22px;
  }
`

export const StyledBlock = styled(Block)`
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`
