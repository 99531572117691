import i18n from 'i18next'
import type { CustomFieldExtension } from '../types'

export function getLabel(customField: CustomFieldExtension): string {
  if (!customField.label) {
    return ''
  }

  if (customField.optional) {
    return `${customField.label} ${i18n.t('FormFields.OptionalFieldLabelSuffix')}`
  }

  return customField.label
}
