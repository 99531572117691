import { useMemo, useCallback } from 'react'
import type { NoResultsReason } from '@fiji/types'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

type Args = {
  isDesktop: boolean
  isFiltersApplied: boolean
  isRestrictedCountryError: boolean
  onDefaultAction?: () => void
}

export function useNoResultReason({
  isDesktop,
  isFiltersApplied,
  onDefaultAction,
  isRestrictedCountryError,
}: Args) {
  const { hotelSearchResultsStore, hotelFiltersAction } = useHotelSearchResultsContext()
  const noResultsReason: NoResultsReason | null = useMemo(() => {
    switch (true) {
      case hotelSearchResultsStore.isLoading:
        return null
      case isRestrictedCountryError:
        return 'restricted-country'
      case hotelSearchResultsStore.isError:
        return 'is-error'
      case !hotelSearchResultsStore.hotels.length &&
        !hotelSearchResultsStore.isError &&
        !isFiltersApplied:
        return isDesktop ? 'no-first-results' : 'no-results-hotel-default'
      case !hotelSearchResultsStore.hotels.length && !hotelSearchResultsStore.isError:
        return isDesktop ? 'too-many-filters' : 'no-results-hotel-default'
      default:
        return null
    }
  }, [
    hotelSearchResultsStore.isLoading,
    hotelSearchResultsStore.isError,
    hotelSearchResultsStore.hotels.length,
    isRestrictedCountryError,
    isFiltersApplied,
    isDesktop,
  ])

  const handleHotelSearchExtraAction = useCallback(() => {
    if (isDesktop && isFiltersApplied) {
      hotelFiltersAction.handleClearAllFilters()
      hotelFiltersAction.handleApplyFilters()
    }
    if (onDefaultAction) {
      onDefaultAction()
    }
  }, [hotelFiltersAction, isDesktop, isFiltersApplied, onDefaultAction])

  const withButtonAction = (isDesktop && isFiltersApplied) || onDefaultAction

  return {
    noResultsReason,
    handleHotelSearchExtraAction: withButtonAction ? handleHotelSearchExtraAction : undefined,
  }
}
