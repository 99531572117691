import type { ReactNode } from 'react'
import { useRef } from 'react'
import type * as H from 'history'
import { useTranslation } from 'react-i18next'
import { NavigationLink } from './desktop-navigation-styled'

type Props = {
  to: string
  title: string
  isActive?: (_: unknown, location: H.Location) => boolean
  children: ReactNode
}

const i18nBase = 'Accessibility.'

export const DesktopNavigationItem = ({ to, title, isActive, children, ...props }: Props) => {
  const { t } = useTranslation()
  const linkRef = useRef<HTMLAnchorElement>(null)
  const isActiveLink = linkRef.current?.className.includes('active')

  const linkLabel = `${title} ${
    isActiveLink ? t(i18nBase + 'Selected') : t(i18nBase + 'TapToSelect')
  }`

  return (
    <NavigationLink
      to={to}
      isActive={isActive}
      link-name={title}
      aria-label={linkLabel}
      innerRef={linkRef}
      {...props}>
      {children}
    </NavigationLink>
  )
}
