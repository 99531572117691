import type { UserDelegate } from '@fiji/graphql/types'

export function getFilteredDelegates(delegates?: UserDelegate[] | null, email?: string | null) {
  if (!delegates) {
    return
  }
  if (!email) {
    return delegates
  }
  return delegates.filter((delegate) => delegate.email !== email)
}
