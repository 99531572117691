import type { UseStore } from 'idb-keyval'
import { createStore, get, set, clear as clearIdb, entries, del } from 'idb-keyval'
import { resourceLogger } from '../resource-interceptor/logger'

const STORE_NAME = 'deem-graphql-store-v1'
const DB_NAME = 'deem-cache-db'

export class Store {
  idb: undefined | UseStore = undefined

  constructor() {
    this.testConnection()
      .then(() => {
        this.idb = createStore(DB_NAME, STORE_NAME)
      })
      .catch((err) => {
        resourceLogger.log('Could not connect to indexedDB', err)
      })
  }

  async testConnection() {
    return new Promise<void>((res, rej) => {
      const db = indexedDB.open('deem-test')
      db.onsuccess = () => {
        res()
      }
      db.onerror = () => {
        rej()
      }
    })
  }

  get<T>(key: IDBValidKey): Promise<T | undefined> {
    return get<T>(key, this.idb)
  }

  set(key: IDBValidKey, value: any): Promise<void> {
    return set(key, value, this.idb)
  }

  clear() {
    return clearIdb(this.idb)
  }

  del(key: IDBValidKey) {
    return del(key, this.idb)
  }

  entries<T extends IDBValidKey, K>() {
    return entries<T, K>(this.idb)
  }
}
