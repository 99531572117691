import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useSettingsContext } from '@etta/modules/settings/interface/use-settings-context'
import { DocumentType } from '@etta/modules/booking/interface/checkout-info/documents/checkout-info-documents.store'

export const useTravelerDocuments = () => {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isAppleLockSettingsEnabled } = displayConfigurationStore.displayConfiguration

  const { accountInformationActions } = useSettingsContext()
  const { isDelegatedOrImpersonated } = accountInformationActions.getAccountInformation()

  const { checkoutInfoDocumentAction, checkoutInfoDocumentStore } = useCheckoutInfoContext()

  const {
    travelerDocumentsToggle,
    travelerDocumentActionToggle,
    isSaveButtonDisabled,
    documentType,
  } = checkoutInfoDocumentStore

  const {
    getDocuments,
    handleTravelerDocumentOpen,
    handleTravelerDocumentClose,
    handleTravelerDocumentSubmit,
    handleTravelerDocumentEdit,
    handleRemoveTravelerDocument,
    getKnownTravelerNumbers,
    getRedressNumbers,
  } = checkoutInfoDocumentAction

  const documents = getDocuments()
  const knownTravelerNumbersSize = getKnownTravelerNumbers().length
  const redressNumbersSize = getRedressNumbers().length
  const i18Base =
    documentType === DocumentType.KTN
      ? 'TravelerInfo.KnownTravelerNumber.'
      : 'TravelerInfo.RedressNumber.'

  return {
    i18Base,
    documentType,
    documents,
    knownTravelerNumbersSize,
    redressNumbersSize,
    travelerDocumentsToggle,
    travelerDocumentActionToggle,
    handleTravelerDocumentOpen,
    handleTravelerDocumentClose,
    handleTravelerDocumentSubmit,
    handleTravelerDocumentEdit,
    handleRemoveTravelerDocument,
    isSaveButtonDisabled,
    isAppleLockSettingsEnabled,
    isDelegatedOrImpersonated,
  }
}
