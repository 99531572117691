export const PassportInfoFieldsSettings = {
  passportInfo: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}

export const VisaInfoFieldsSettings = {
  visaInfo: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}

export const NationalIdInfoFieldsSettings = {
  nationalIdInfo: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}

export const AdditionalTravelDocumentsFieldsSettings = {
  redressNumberCountry: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  redressNumber: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  knownTravelerNumber: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
  knownTravelerNumberCountry: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRendered: true,
  },
}
