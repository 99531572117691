import { lazy } from 'react'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

const MeetingStart = lazy(() => import('@etta/modules/meeting/ui'))

export const meetingRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.meeting({}),
      exact: true,
    },
    documentTitle: getDocumentTitle('Starting meeting mode'),
    transitionIndex: 2,
    transitionGroup: 'fade-through',
    component: () => <MeetingStart />,
  },
]
