import { useTranslation } from 'react-i18next'
import { DatePicker } from '@etta/ui/date-picker'
import { Modal } from '@etta/ui/modal'
import { ModalDetails } from '@etta/components/modal-details/modal-details'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { addDaysToDate } from '@fiji/utils/dates'
import { ScreenType } from '@fiji/modes'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface'

type Props = {
  isOpen: boolean
  onClose: () => void
  onDateSelect: (date: Date | null) => void
  date: Date | null
}

const i18nBase = 'Mobility.AddFlightModal'

export function FlightDatePickerModal({ isOpen, onClose, onDateSelect, date }: Props) {
  const { t } = useTranslation()

  const { searchRideHailActions, configurationStore } = useRideHailSearchContext()

  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={searchRideHailActions.openLeavingBookingFlowDialog}
      isAdaptivePosition>
      <Modal.Header isMobile withBorder>
        <Header
          leftSlot={
            <IconButton
              icon="arrowBackPWA"
              size="medium"
              color="mainText1"
              onClick={onClose}
              aria-label="back"
            />
          }
          backgroundColor="white">
          <Text variant="headline" color="mainText">
            {t(`${i18nBase}.DatePickerHeader`)}
          </Text>
        </Header>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          date={date}
          minDate={new Date()}
          maxDate={addDaysToDate(new Date(), configurationStore.advanceBookingInDays)}
          onSelect={(result) => {
            if (result.date) {
              onDateSelect(result.date)
            }
          }}
          forceScreenType={ScreenType.Mobile}
        />
      </Modal.Body>
      <Modal.Footer withBorder padding="normal">
        <Button btnType="primary" onClick={onClose} fullWidth disabled={false}>
          {t(`${i18nBase}.DatePickerButton`)}
        </Button>
      </Modal.Footer>
    </ModalDetails>
  )
}
