import { AirSearchRestrictions } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import type { AirFilterAirline } from '@etta/modules/air-search/core/value-objects/air-filters'

type Args = {
  searchRestrictions?: AirSearchRestrictions
  legs: FlightPostBookingValueObject[] | FlightSegmentEntity[]
}

export function getRestrictedAirCarrierFilters({
  searchRestrictions,
  legs,
}: Args): {
  filtersForFirstLeg: AirFilterAirline[]
  filtersForReturnLeg: AirFilterAirline[]
} {
  const isPostBookingFilterRestricted =
    searchRestrictions === AirSearchRestrictions.NoCarrierChange ||
    searchRestrictions === AirSearchRestrictions.NoCarrierNoDestinationChange

  const filteredCarriers: AirFilterAirline[][] = legs.map((leg) => {
    const totalCarriers: AirFilterAirline[] = []
    leg.segments?.forEach((segment) => {
      const newCarrier = {
        id: 'C-' + segment.carrierCode,
        value: segment.carrierCode || '',
        name: segment.name || '',
      }
      if (totalCarriers.some((carrier) => carrier.id === newCarrier.id)) {
        return
      }
      totalCarriers.push(newCarrier)
    })
    return totalCarriers
  })

  return {
    filtersForFirstLeg: isPostBookingFilterRestricted ? filteredCarriers[0] : [],
    filtersForReturnLeg: isPostBookingFilterRestricted ? filteredCarriers[1] : [],
  }
}
