import { Tabs } from '@etta/ui/tabs'
import type { LayoutProps } from '../../types'

export function RailTabsBlockMobile({ activeTab, onTabChange, tabs }: LayoutProps) {
  return (
    <Tabs onChangeTab={onTabChange} activeTab={activeTab}>
      <Tabs.Header>
        {tabs.map((tab, index) => (
          <Tabs.Tab key={index} value={tab.value}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.Header>
    </Tabs>
  )
}
