import { useHotelResultsContextSelector } from '@etta/screens/hotel-result-page/hotel-results-provider'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useSearchAreaBlock } from './use-search-area-block'
import { useFetchReferencePoints } from './use-fetch-reference-points'

export function useHotelList() {
  const {
    hotelsResult,
    hotelDetails,
    distance,
    longitude,
    latitude,
  } = useHotelResultsContextSelector((state) => state)

  const type = useScreenType()
  const mapToggle = useTogglePopup()
  const shouldLoadPoints = type === ScreenType.Mobile ? mapToggle.isOpen : true

  const { referencePoints } = useFetchReferencePoints({
    latitude,
    longitude,
    shouldLoadPoints,
  })

  const searchAreaBlock = useSearchAreaBlock()

  return {
    longitude,
    latitude,
    searchAreaBlock,
    referencePoints,
    hotelDetails,
    hotelsResult,
    distance,
    mapToggle,
  }
}
