import { Store } from '@etta/di'
import type { TravelPreferencesValueObject } from '../../core/value-objects/travel-preferences.value-object'
import { ShareTravelerContact } from '../../core/enums/share-traveler-contact.enum'

export const getInitialTravelPreferences = (): TravelPreferencesValueObject => {
  return {
    flight: {
      homeAirport: '',
      specialRequest: [],
      loyaltyProgram: [],
      specialRequestOptions: [],
    },
    carRental: {
      carClass: null,
      loyaltyProgram: [],
      specialRequest: [],
      specialRequestOptions: [],
    },
    hotel: {
      loyaltyProgram: [],
      specialRequest: [],
      specialRequestOptions: [],
    },
    rail: {
      loyaltyProgram: [],
      specialRequest: [],
      specialRequestOptions: [],
    },
  }
}

@Store()
export class TravelPreferencesStore {
  hasTravelerPreferencesBeenReset = false

  travelPreferences: TravelPreferencesValueObject = getInitialTravelPreferences()

  isLoading = false

  isError = false

  shareTravelerContact: ShareTravelerContact | null = null

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setSingleTravelPreference<T extends keyof TravelPreferencesValueObject>(
    newPreference: TravelPreferencesValueObject[T],
    preferenceName: T,
  ) {
    const newTravelPreferences = {
      ...this.travelPreferences,
      [preferenceName]: newPreference,
    }
    this.travelPreferences = newTravelPreferences
  }

  setTravelPreferences(travelPreferences: TravelPreferencesValueObject) {
    this.travelPreferences = travelPreferences
  }

  setShareTravelerContact(shareTravelerContact: string) {
    this.shareTravelerContact = ShareTravelerContact.getBy(shareTravelerContact)
  }

  resetTravelPreferences() {
    this.hasTravelerPreferencesBeenReset = true
    this.travelPreferences = getInitialTravelPreferences()
  }

  dropStore() {
    this.hasTravelerPreferencesBeenReset = false
    this.travelPreferences = getInitialTravelPreferences()
    this.shareTravelerContact = null
  }
}
