import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'
import type { CartErrorsInstances } from '../../core/errors'
import { CartErrors } from '../../core/errors'
import { CartDataProvider } from './cart.data-provider'
import type {
  GetCartArgs,
  AddToCartArgs,
  CartResults,
  RemoveFromCartArgs,
  ModifyToCartArgs,
  SetOopJustificationArgs,
  GetCartResult,
  CartOOPConfiguration,
  SetCostAllocationInput,
} from './types'
import { CartMapper } from './mapper'
import type { CartCost, CartResponse, OOPInfoResponse } from './mapper/types'
import { OOPMapper } from './mapper/oop.mapper'

const TEST_SEARCH_ID = 'gazoo_strangler'
@Adapter()
export class CartAdapter {
  constructor(
    @Inject()
    private cartDataProvider: CartDataProvider,
  ) {}

  async getCartOOPInfo(cartId: string, forceUpdate?: boolean): CartResults<CartOOPConfiguration> {
    try {
      const { data, errors } = await this.cartDataProvider.handleGetOOPInfo({ cartId }, forceUpdate)

      if (errors) {
        return Result.Err(new CartErrors.CartGetOOPInfoError(errors))
      }

      switch (data.oopInfo.__typename) {
        case 'CartNotFoundResponse':
          return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
        case 'GetOOPInfoResponse': {
          return Result.Ok(OOPMapper.toOOPReasons(data.oopInfo))
        }
        default: {
          return Result.Err(new CartErrors.CartGetOOPInfoUnexpectedError(''))
        }
      }
    } catch (e) {
      return Result.Err(new CartErrors.CartGetOOPInfoUnexpectedError(e))
    }
  }

  async getCostAllocation(
    cartId: string,
    forceUpdate?: boolean,
  ): CartResults<CostAllocationSubmitValueObject> {
    try {
      const { data, errors } = await this.cartDataProvider.handleGetCostAllocation(
        cartId,
        forceUpdate,
      )

      if (errors) {
        return Result.Err(new CartErrors.CartGetCostAllocationError(errors))
      }

      switch (data.cartCostAllocation.__typename) {
        case 'CartNotFoundResponse':
          return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
        case 'GetCostAllocationResponse': {
          return Result.Ok(data.cartCostAllocation.costAllocations)
        }
        default: {
          return Result.Err(new CartErrors.CartGetCostAllocationUnexpectedError(''))
        }
      }
    } catch (e) {
      return Result.Err(new CartErrors.CartGetCostAllocationUnexpectedError(e))
    }
  }

  async setCostAllocation(input: SetCostAllocationInput): CartResults<{ success: boolean }> {
    try {
      const { data, errors } = await this.cartDataProvider.handleSetCostAllocation(input)

      if (errors) {
        return Result.Err(new CartErrors.CartGetCostAllocationError(errors))
      }

      switch (data?.setCostAllocation.__typename) {
        case 'CartNotFoundResponse':
          return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
        case 'SetCostAllocationResponse': {
          return Result.Ok({ success: true })
        }
        default: {
          return Result.Err(new CartErrors.CartGetCostAllocationUnexpectedError(''))
        }
      }
    } catch (e) {
      return Result.Err(new CartErrors.CartGetCostAllocationUnexpectedError(e))
    }
  }

  async getCartCombinedInformation({ cartId, forceUpdate }: GetCartArgs): GetCartResult {
    try {
      const [cart, costSummary, oopInfo] = await Promise.all([
        this.getCart({ cartId, forceUpdate }),
        this.getCostSummaryForCart(cartId, forceUpdate),
        this.getOOPInfoForCart(cartId, forceUpdate),
      ])

      const errors = [cart, costSummary, oopInfo].reduce<CartErrorsInstances[]>((acc, res) => {
        if (res.isErr()) {
          acc.push(res.getError())
        }
        return acc
      }, [])

      if (errors.length) {
        return Result.Err(errors)
      }
      return Result.Ok({
        trip: CartMapper.toTripValueObject({
          cart: cart.getValue(),
          costSummary: costSummary.getValue(),
          oopInfo: oopInfo.getValue(),
        }),
        oopConfiguration: OOPMapper.toOOPReasons(oopInfo.getValue()),
      })
    } catch (e) {
      return Result.Err([new CartErrors.UnexpectedCartGetCartError(e)])
    }
  }

  private async getCart({ cartId, forceUpdate }: GetCartArgs): CartResults<CartResponse> {
    try {
      const { data, error } = await this.cartDataProvider.getCart({ cartId }, forceUpdate)

      if (error) {
        return Result.Err(new CartErrors.CartGetCartError(error))
      }

      if (data.cart.__typename === 'CartNotFoundResponse') {
        return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
      }

      if (data.cart.__typename === 'UnexpectedError') {
        return Result.Err(new CartErrors.UnexpectedCartGetCartError(data.cart.message))
      }

      return Result.Ok(data.cart)
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedCartGetCartError(e))
    }
  }

  private async getCostSummaryForCart(
    cartId: string,
    forceUpdate?: boolean,
  ): CartResults<CartCost> {
    try {
      const { data, error } = await this.cartDataProvider.getCostSummary(cartId, forceUpdate)

      if (error) {
        return Result.Err(new CartErrors.CartGetCartError(error))
      }

      if (data.costSummary.__typename === 'CartNotFoundResponse') {
        return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
      }

      return Result.Ok(data.costSummary)
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedCartGetCartError(e))
    }
  }

  async createCart(): CartResults<{ cartId: string }> {
    try {
      const { data, errors } = await this.cartDataProvider.createCart()

      if (errors) {
        return Result.Err(new CartErrors.CartCreateError(errors))
      }

      if (!data?.createCart) {
        return Result.Err(new CartErrors.CartCreateError('No cart id returned'))
      }

      if (data.createCart.__typename === 'UnexpectedError') {
        return Result.Err(new CartErrors.UnexpectedCartCreateError(data.createCart.message))
      }

      return Result.Ok({ cartId: data.createCart.cartId })
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedCartCreateError(e))
    }
  }

  async addToCart({
    cartId,
    intentDomain,
    inventoryId,
    searchId,
  }: AddToCartArgs): CartResults<{ intentId: string }> {
    try {
      const { data, errors } = await this.cartDataProvider.addToCart({
        cartId,
        searchId: searchId || TEST_SEARCH_ID,
        inventoryId,
        intentDomain,
      })

      if (errors) {
        return Result.Err(new CartErrors.CartAddToCartError(errors))
      }

      if (!data || data?.addToCart.__typename === 'CartNotFoundResponse') {
        return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
      }

      if (data.addToCart.__typename === 'UnexpectedError') {
        return Result.Err(new CartErrors.UnexpectedCartAddToCartError(data.addToCart.message))
      }

      if (data.addToCart.__typename === 'AddToCartResponse') {
        return Result.Ok({ intentId: data.addToCart.intentId })
      }

      return Result.Err(new CartErrors.UnexpectedCartAddToCartError('Unknown error'))
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedCartAddToCartError(e))
    }
  }

  async addModifyToCart({
    cartId,
    intentDomain,
    inventoryId,
    searchId,
    reservationId,
  }: ModifyToCartArgs): CartResults<{ intentId: string }> {
    if (!searchId) {
      return Result.Err(new CartErrors.CartNoSearchIdError(''))
    }
    try {
      const { data, errors } = await this.cartDataProvider.addModifyToCart({
        cartId,
        searchId,
        inventoryId,
        reservationId,
        domain: intentDomain,
      })
      if (errors) {
        return Result.Err(new CartErrors.CartAddToCartError(errors))
      }

      if (!data || data?.addModifyToCart.__typename === 'CartNotFoundResponse') {
        return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
      }

      return Result.Ok({ intentId: data.addModifyToCart.intentId })
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedModifyCartError(e))
    }
  }

  async removeFromCart({
    cartId,
    intentId,
  }: RemoveFromCartArgs): CartResults<{ success: boolean; message: string; code: string }> {
    if (!intentId) {
      return Result.Err(new CartErrors.CartRemoveError('No Intent'))
    }

    try {
      const { data, errors } = await this.cartDataProvider.removeFromCart({
        cartId,
        intentId,
      })

      if (errors) {
        return Result.Err(new CartErrors.CartRemoveError(errors))
      }

      if (!data || data?.removeFromCart.__typename === 'CartNotFoundResponse') {
        return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
      }

      if (data.removeFromCart.__typename === 'UnexpectedError') {
        return Result.Err(
          new CartErrors.UnexpectedCartRemoveFromCartError(data.removeFromCart.message),
        )
      }

      return Result.Ok({
        code: data.removeFromCart.code,
        message: data.removeFromCart.code,
        success: true,
      })
    } catch (e) {
      return Result.Err(new CartErrors.UnexpectedCartRemoveFromCartError(e))
    }
  }

  private async getOOPInfoForCart(
    cartId: string,
    forceUpdate?: boolean,
  ): CartResults<OOPInfoResponse> {
    try {
      const { data, errors } = await this.cartDataProvider.handleGetOOPInfo({ cartId }, forceUpdate)

      if (errors) {
        return Result.Err(new CartErrors.CartGetOOPInfoError(errors))
      }

      switch (data.oopInfo.__typename) {
        case 'CartNotFoundResponse':
          return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
        case 'GetOOPInfoResponse': {
          return Result.Ok(data.oopInfo)
        }
        default: {
          return Result.Err(new CartErrors.CartGetOOPInfoUnexpectedError(''))
        }
      }
    } catch (e) {
      return Result.Err(new CartErrors.CartGetOOPInfoUnexpectedError(e))
    }
  }

  async setOOPJustification(input: SetOopJustificationArgs): CartResults<{ cartId: string }> {
    try {
      const { data, errors } = await this.cartDataProvider.handleSetOOPReason(input)

      if (errors) {
        return Result.Err(new CartErrors.CartSetOOPJustificationError(errors))
      }

      switch (data?.setOutOfPolicyJustification.__typename) {
        case 'CartNotFoundResponse':
          return Result.Err(new CartErrors.CartNotFoundError('Cart not found'))
        case 'SetOutOfPolicyJustificationResponse': {
          return Result.Ok({ cartId: data.setOutOfPolicyJustification.cartId })
        }
        default: {
          return Result.Err(new CartErrors.CartSetOOPJustificationUnexpectedError(''))
        }
      }
    } catch (e) {
      return Result.Err(new CartErrors.CartSetOOPJustificationUnexpectedError(e))
    }
  }
}
