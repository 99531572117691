import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps, ValueType } from '../types'
import { SelectMobile } from './mobile'
import { SelectDesktop } from './desktop'

export function SelectLayout<T = ValueType>(props: LayoutProps<T>) {
  return (
    <MediaLayout
      mobileSlot={<SelectMobile {...props} />}
      desktopSlot={<SelectDesktop {...props} />}
    />
  )
}
