import { useTranslation } from 'react-i18next'
import { VIRTUAL_PAY } from '@fiji/constants'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { CreditCardItem } from '@etta/components/credit-card-item/credit-card-item'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { AddCreditCard } from '../../add-credit-card'
import { SearchCreditCard } from '../../search-credit-card'
import { EmptyResultState } from '../../empty-result-state'
import { CreditCardList } from '../../credit-card-list'
import type { LayoutProps } from '../types'

export function PurchasePaymentInformationFormDesktop({
  creditCards,
  filterValue,
  selectedCard,
  isFilterAvailable,
  isSearchResultEmpty,
  isAddNewCardAvailable,
  isHotelBooking,
  isVirtualPayEnabled,
  handleChangeFilterValue,
  handleOpenCreditCart,
  handleSelectCard,
  handleEditCreditCardClick,
  onClose,
  onSubmit,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'PurchasePaymentInformationForm'

  return (
    <>
      <CheckoutPageModal.Header
        title={t(`${i18nBase}.PaymentInfo`)}
        withBorderBottom
        onClose={onClose}
      />
      <CheckoutPageModal.Body>
        {isFilterAvailable && (
          <Block paddingBottom={27}>
            <SearchCreditCard value={filterValue} onChange={handleChangeFilterValue} />
          </Block>
        )}
        {isAddNewCardAvailable && (
          <Block paddingBottom={16}>
            <AddCreditCard onClick={handleOpenCreditCart} />
          </Block>
        )}
        <Swap is={isSearchResultEmpty} isSlot={<EmptyResultState />}>
          {isHotelBooking && isVirtualPayEnabled && (
            <CreditCardItem
              cardName={t('CreditCardItem.VirtualPayCard')}
              isSelectable
              isLast
              isSelected={selectedCard === VIRTUAL_PAY}
              onChange={() => handleSelectCard(VIRTUAL_PAY)}
              cardVendor={VIRTUAL_PAY}
            />
          )}
          <CreditCardList
            creditCards={creditCards}
            selectedCard={selectedCard}
            withBorderBottom
            handleSelectCard={handleSelectCard}
            handleEditCreditCardClick={handleEditCreditCardClick}
          />
        </Swap>
      </CheckoutPageModal.Body>
      <CheckoutPageModal.Footer
        submitLabel={t(`${i18nBase}.Select`)}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </>
  )
}
