import styled from 'styled-components'
import { slideUp } from './transntion'

export const SheetContainer = styled.div`
  border-radius: 14px;
  width: 100%;
  height: 50%;
  bottom: 0;
  position: absolute;
  z-index: 22;
  background-color: ${(p) => p.theme.colors.white};
  ${slideUp};
`

export const Background = styled.div`
  position: fixed;
  z-index: 21;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
`

export const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 20px;
  margin-bottom: 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderDark};
`

export const BodyContainer = styled.div`
  padding: 0 20px;
`
