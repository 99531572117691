import { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'

export const usePrintETicketReceipt = () => {
  const printItineraryToggle = useTogglePopup()
  const eTicketReceiptToggle = useTogglePopup()
  const [selectedETicketIndex, setSelectedETicketIndex] = useState<number>(0)
  const onOpenETicketReceipt = (index: number) => {
    setSelectedETicketIndex(index)
    eTicketReceiptToggle.handleOpen()
  }

  const printItineraryContentRef = useRef<HTMLDivElement>(null)
  const eTicketReceiptContentRef = useRef<HTMLDivElement>(null)
  const printItineraryContent = useReactToPrint({
    content: () => printItineraryContentRef.current,
  })
  const handleETicketReceipt = useReactToPrint({
    content: () => eTicketReceiptContentRef.current,
  })
  const printETicketReceipt = (index: number) => {
    setSelectedETicketIndex(index)
    if (handleETicketReceipt) {
      handleETicketReceipt()
    }
  }

  return {
    printItineraryToggle,
    eTicketReceiptToggle,
    onOpenETicketReceipt,
    selectedETicketIndex,
    printItineraryContentRef,
    eTicketReceiptContentRef,
    printItineraryContent,
    printETicketReceipt,
  }
}
