import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import { useRailTimeConfiguration } from '@fiji/hooks/time-configuration/use-rail/use-rail-time-configuration'
import { TimeRangeDirection } from '@fiji/enums'
import type { Place } from '@fiji/types'
import { RailTripType } from './types'
import type { RailSearchQueryType } from './types'

type Props = {
  originPlace?: Place
  destinationPlace?: Place
}

export function useRailSearchQuery({ originPlace, destinationPlace }: Props) {
  const { getInitialTimeRange } = useRailTimeConfiguration()

  const {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  } = useQueryParams<RailSearchQueryType>(
    {
      originTime: getInitialTimeRange(TimeRangeDirection.Departure),
      destinationTime: getInitialTimeRange(TimeRangeDirection.Arrival),
      filters: {},
      railTripType: RailTripType.OneWay,
      originPlace,
      destinationPlace,
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: ['railCards'],
    },
  )

  return {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  }
}
