import styled from 'styled-components'
import { footnoteMedium, title3 } from '@fiji/style'

export const Container = styled.div`
  text-align: center;
  padding: 20px 20px 4px 20px;
  width: 310px;
`

export const Title = styled.h5`
  margin: 0 0 24px;
  ${title3};
`

export const Content = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  margin: 0 0 32px;
  line-height: 1.5;
  white-space: pre-line;
  ${footnoteMedium};
`
