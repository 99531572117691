import { Badge } from './label-badge-styled'
import { getTitle } from './get-title'
import type { BadgeVariant } from './types'

type Props = {
  variant: BadgeVariant
}

export function LabelBadge({ variant }: Props) {
  const title = getTitle(variant)
  return (
    <Badge aria-label={title} variant={variant}>
      {title}
    </Badge>
  )
}
