import { Store, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import { Toggle } from '@etta/interface/services/toggle'
// TODO: remove fiji import
import type {
  AirSearchQueryType,
  Flight,
} from '@fiji/hooks/search-queries/use-air-search-query/types'
// TODO: remove fiji import
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { dateFormat } from '@fiji/utils/dates'
import { TimeRangeDirection } from '@fiji/enums'
import type { GeocodeValueObject } from '@etta/core/value-objects'
import type { FlightLegSearchInputValueObject } from '../../core/value-objects/flight-leg-search.input'
import { HomeAirportStore } from './configs/home-airport.store'
import { AirSearchQueryStore } from './air-search-query.store'
import { AirSearchDefaultValuesStore } from './air-search-default-values.store'

const MAX_FLIGHTS = 6

type SearchFlightsEditCache = Pick<AirSearchQueryType, 'flightType' | 'flights'> & {
  itineraryId?: string
}

export type ValidationErrors = {
  originPlace?: string
  destinationPlace?: string
  departureDate?: string
  returnDate?: string
}

@Store()
export class AirSearchFormStore {
  constructor(
    @Inject()
    private readonly historyStore: HistoryStore,
    @Inject()
    private readonly homeAirportStore: HomeAirportStore,
    @Inject()
    private readonly airSearchQueryStore: AirSearchQueryStore,
    @Inject()
    private readonly airSearchDefaultValuesStore: AirSearchDefaultValuesStore,
  ) {}

  editModeToggle = new Toggle()

  validationErrors: ValidationErrors[] = [{}]

  searchFlightsEditCache: SearchFlightsEditCache = {
    flightType: this.airSearchDefaultValuesStore.defaultFlightType,
    flights: [],
  }

  setSingleValidationError(index: number, key: keyof ValidationErrors, value: string) {
    if (!this.validationErrors[index]) {
      return
    }

    this.validationErrors[index] = { ...this.validationErrors[index], [key]: value }
  }

  updateSearchFlightsEditCache(newSearch: Partial<SearchFlightsEditCache>) {
    this.searchFlightsEditCache = {
      flights: newSearch.flights ?? this.searchFlightsEditCache.flights,
      flightType: newSearch.flightType ?? this.searchFlightsEditCache.flightType,
      itineraryId: newSearch.itineraryId ?? this.searchFlightsEditCache.itineraryId,
    }
  }

  get existingTripId() {
    return (
      this.airSearchQueryStore.additionalQueries.itineraryId ??
      this.searchFlightsEditCache.itineraryId
    )
  }

  get homeAirport() {
    return this.homeAirportStore.homeAirport
  }

  get isEditMode() {
    const currentPath = this.historyStore.currentPath
    const { itineraryId } = this.historyStore.getParams<{ itineraryId: string }>() || {}
    const isRTP = this.historyStore.matchPathname(ROUTES.reviewTrip.main({ itineraryId }))

    if (isRTP) {
      return true
    }

    return currentPath.includes(ROUTES.air.results({ legNumber: 0 }).slice(0, 25))
  }

  public getFightLegSearchInput(searchFlights?: Flight[], searchFlightType?: FlightType) {
    let flights = searchFlights ?? this.searchFlights
    const flightType = searchFlightType ?? this.searchFlightType
    if (flightType === FlightType.Round) {
      const firstLeg = flights[0]
      const returnFlight = {
        id: 999999,
        cabinClass: firstLeg.cabinClass,
        timeRange: firstLeg.returnTimeRange,
        departureDate: firstLeg.returnDate,
        destinationPlace: firstLeg.originPlace,
        originPlace: firstLeg.destinationPlace,
        returnTimeRange: firstLeg.returnTimeRange,
      }
      flights = [firstLeg, returnFlight]
    }

    let flightLegSearch = flights.map<FlightLegSearchInputValueObject>((flight, index) => {
      const { cabinClass, timeRange, departureDate, destinationPlace, originPlace } = flight
      const selectedCabinClass =
        flightType === FlightType.Round ? flights[0].cabinClass : cabinClass
      return {
        number: index,
        destination: destinationPlace?.airportCode,
        origin: originPlace?.airportCode,
        destinationLocation: this.assignGeocode({
          lat: destinationPlace?.latitude,
          long: destinationPlace?.longitude,
        }),
        originLocation: this.assignGeocode({
          lat: originPlace?.latitude,
          long: originPlace?.longitude,
        }),
        departureDate: departureDate ? dateFormat(departureDate, 'y-MM-dd') : '0000-00-00',
        timeRangeBy: timeRange.timeRangeBy || TimeRangeDirection.Departure,
        startTimeRange: timeRange.startTimeRange,
        endTimeRange: timeRange.endTimeRange,
        preferredTime: '',
        serviceClass: selectedCabinClass.id,
        destinationCity: destinationPlace?.city,
        destinationCountryCode: destinationPlace?.countryCode,
        destinationStateCode: destinationPlace?.stateCode,
        originCity: originPlace?.city,
        originCountryCode: originPlace?.countryCode,
        originStateCode: originPlace?.stateCode,
      }
    })

    if (flightType === FlightType.OneWay) {
      flightLegSearch = [flightLegSearch[0]]
    }

    if (flightType === FlightType.Round) {
      flightLegSearch = [flightLegSearch[0], flightLegSearch[1]]
    }

    return { flights, flightLegSearch }
  }

  get flightLegSearchInput() {
    return this.getFightLegSearchInput()
  }

  private assignGeocode({ lat, long }: Partial<GeocodeValueObject>) {
    return lat && long ? { lat, long } : undefined
  }

  get searchFlights(): Flight[] {
    let flights = this.airSearchQueryStore.searchQueryFlights

    if (this.isEditMode) {
      flights = this.searchFlightsEditCache.flights
    }

    if (!flights.length) {
      flights = this.airSearchDefaultValuesStore.defaultSearchFlights
    }

    this.fillValidationErrors(flights.length)

    return this.fillFlightsWithDefault(flights)
  }

  fillValidationErrors(length: number) {
    this.validationErrors = new Array(length).fill({})
  }

  get searchFlightsToShow() {
    return this.searchFlightType === FlightType.MultiCity
      ? this.searchFlights
      : this.searchFlights.slice(0, 1)
  }

  get searchFlightType() {
    if (this.isEditMode) {
      return this.searchFlightsEditCache.flightType
    }
    return (
      this.airSearchQueryStore.searchQueryFlightType ||
      this.airSearchDefaultValuesStore.defaultFlightType
    )
  }

  get isAbleToAddFlight() {
    return this.searchFlights.length < MAX_FLIGHTS
  }

  get isCloseButton() {
    return this.searchFlights.length > 2
  }

  get getPreviousDate() {
    if (this.searchFlightType !== FlightType.MultiCity) {
      return undefined
    }

    const lastFlight = this.searchFlights
      .filter((flight) => Boolean(flight.departureDate))
      .slice(-1)[0]
    const departureDate = lastFlight ? lastFlight.departureDate : undefined

    return departureDate
  }

  private fillFlightsWithDefault(flights: Flight[]) {
    const defaultValues = this.airSearchDefaultValuesStore

    return flights.map(
      (flight: Partial<Flight>, index): Flight => {
        return {
          id: flight.id || 999 + index,
          originPlace: flight.originPlace,
          destinationPlace: flight.destinationPlace,
          departureDate: flight.departureDate,
          timeRange: flight.timeRange || defaultValues.initialTimeRange,
          returnTimeRange: flight.returnTimeRange || defaultValues.initialReturnTimeRange,
          returnDate: flight.returnDate,
          cabinClass: flight.cabinClass || defaultValues.defaultCabinClass,
        }
      },
    )
  }
}
