import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LegendIconName } from '../legend-icon'
import { LegendIcon } from '../legend-icon'
import { IconContainer } from './legends-styled'

type Props = {
  text: string
  icon: LegendIconName
}

export function LegendNode({ text, icon }: Props) {
  return (
    <Block isFlexBlock>
      <IconContainer>
        <LegendIcon icon={icon} />
      </IconContainer>
      <Block marginRight={8}>
        <Text variant="footnoteMedium" color="bodyText1">
          {text}
        </Text>
      </Block>
    </Block>
  )
}
