import styled from 'styled-components'
import { linkSmall } from '@fiji/style'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Paper } from '../../../trip-hotel-styled'

export const Container = styled(Paper)`
  padding: 20px 20px 16px 20px;
`

export const CheckBlock = styled(Block)`
  & ${Text}:last-of-type {
    display: block;
    margin-top: 4px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: ${(p) => p.theme.colors.success};
      border-radius: 50%;
      margin-top: 5px;
    }
  }

  &:last-of-type ${Text}:last-of-type {
    &::before {
      background-color: ${(p) => p.theme.colors.error};
    }
  }
`

export const DateBlock = styled.div`
  position: relative;
  padding-left: 22px;

  &::before {
    content: '';
    position: absolute;
    left: 4px;
    top: 35px;
    width: 2px;
    height: 62px;
    background-color: ${(p) => p.theme.colors.borderDark};
  }
`

export const LinkWrapper = styled.div`
  align-self: flex-start;
  ${linkSmall}
`

export const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;

  ${Text} {
    max-width: 240px;
  }
`
export const TitleIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  & ${Text}:last-of-type {
    margin-left: 4px;
  }
`
