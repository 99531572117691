import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { formatRate } from '@fiji/utils/money'
import type { LayoutProps } from '../../types'
import {
  Container,
  HeaderText,
  BaggageListContainer,
  Radio,
  RadioContent,
  Title,
  Price,
} from './add-baggage-modal-mobile-styled'

const i18nBase = 'ReviewTrip.AddBaggageModal.'

export function AddBaggageModalMobile({
  isOpen,
  onClose,
  luggageOptions,
  luggageOption,
  setLuggageOption,
  handleSelectBaggageOption,
}: LayoutProps) {
  const { t } = useTranslation()
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Header withBorderBottom leftSlot={<Header.CloseButton onClick={onClose} />}>
        <Header.Title title={t(i18nBase + 'Title')} align="left" />
      </Header>
      <Modal.Body>
        <Container>
          <HeaderText>{t(i18nBase + 'Header')}</HeaderText>
          <BaggageListContainer>
            <Radio isChecked={!luggageOption} onChange={() => setLuggageOption(undefined)}>
              {t(i18nBase + 'None')}
            </Radio>
            {luggageOptions.map(({ code, value, cost }) => {
              const { mainCost: luggageCost } = formatRate(cost, {
                morpheme: 'none',
              })
              return (
                <Radio
                  key={code}
                  isChecked={code === luggageOption?.code}
                  onChange={() => setLuggageOption({ code, value, cost })}>
                  <RadioContent>
                    <Title>{value}</Title>
                    <Price>{luggageCost}</Price>
                  </RadioContent>
                </Radio>
              )
            })}
          </BaggageListContainer>
          <Block marginTop="auto" marginBottom={16} marginHorizontal={16} isFullWidth>
            <Button onClick={handleSelectBaggageOption} variant="solid" fullWidth>
              {t(i18nBase + 'Apply')}
            </Button>
          </Block>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
