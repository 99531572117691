import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { CarRentalSearchPageMobile } from './mobile'
import { CarRentalSearchPageDesktopNew } from './desktop'

export function CarRentalSearchPageLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalSearchPageMobile {...props} />}
      desktopSlot={<CarRentalSearchPageDesktopNew {...props} />}
    />
  )
}
