import styled, { css } from 'styled-components'
import { headline, footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderDark};
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  z-index: 2;
`

export const Content = styled.div`
  display: flex;
  padding: 20px 20px 16px;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 95px;
  overflow: hidden;
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`

const ellipsisOverflowStyles = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`

export const Title = styled.span<{ withEllipsisOverflow?: boolean }>`
  display: block;
  word-wrap: break-word;
  color: ${({ theme }) => theme.colors.mainText};
  ${({ withEllipsisOverflow }) => withEllipsisOverflow && ellipsisOverflowStyles}

  ${headline};

  p {
    margin: unset;
  }
`

const truncateStyles = css`
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const MessageSlotContainer = styled.div`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
  ${truncateStyles}
`
