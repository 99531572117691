import { Service, Inject } from '@etta/di'
import { SiteSsoSettingsAdapter } from '../../infra/site-sso-settings.adapter'
import { SiteSsoSettingsStore } from '../store/site-sso-settings.store'

@Service()
export class SiteSsoSettingsService {
  constructor(
    @Inject() private siteSsoSettingsAdapter: SiteSsoSettingsAdapter,
    @Inject() private siteSsoSettingsStore: SiteSsoSettingsStore,
  ) {}

  async fetchSsoSettings() {
    this.siteSsoSettingsStore.setLoading(true)

    const result = await this.siteSsoSettingsAdapter.getSiteSsoSettings()

    result.match({
      Ok: (value) => {
        this.siteSsoSettingsStore.setSiteSsoSettings(value)
      },
      Err: (err) => {
        this.siteSsoSettingsStore.setError(err)
      },
    })

    this.siteSsoSettingsStore.setLoading(false)
  }
}
