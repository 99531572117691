import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace MeetingModeError {
  // TODO : define erorr which relate to meeting mode
  export const UpsertMeetingModeFail = createErrorClass('Generic error')
  // TODO : define erorr which relate to meeting mode
  export const UpdateMeetingIdFail = createErrorClass('Generic error')
  // TODO : define erorr which relate to meeting mode
  export const GetMeetingInfoFail = createErrorClass('Generic error')
}

export type MeetingModeInstances = InstanceType<
  typeof MeetingModeError[keyof typeof MeetingModeError]
>
