import type { Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'

type Args = {
  cost?: Rate | null
}

export function useSegmentSpecificUnit({ cost }: Args) {
  const mainCost = formatRate(cost, {
    morpheme: 'none',
  }).mainCost
  return {
    mainCost,
  }
}
