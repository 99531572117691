import { useTranslation } from 'react-i18next'
import { EmptyState } from './empty-state-container-mobile-styled'

export function EmptyStateContainerMobile() {
  const { t } = useTranslation()

  return (
    <EmptyState>
      {t('SelectSearch.EmptyState.NoResults')} <br />{' '}
      {t('SelectSearch.EmptyState.SearchWithOtherKeywords')}
    </EmptyState>
  )
}
