import i18n from 'i18next'
import type { BaggageAllowance, BaggageAllowanceCount, FlightBaggageFee } from '@fiji/graphql/types'
import { BaggageType } from '@fiji/graphql/types'

const FlightMetrics = {
  INCH: 'in',
  POUND: 'lbs',
}

export function resolveBaggageCount(base: string, no: BaggageAllowanceCount) {
  return no.upper !== no.lower
    ? no.lower.toString() + ' - ' + no.upper + ' /' + i18n.t(base + 'PerPiece')
    : i18n.t(base + 'BaggageCount.' + no.lower.toString())
}

export function resolveBaggageDimensions(baggage: BaggageAllowance): string {
  return baggage.lower.value + ' - ' + baggage.upper.value + ' ' + FlightMetrics[baggage.lower.unit]
}

export function filterBaggageType(
  fee: (FlightBaggageFee | null)[] | null | undefined,
): Array<(FlightBaggageFee | null)[] | null> {
  return Object.values(BaggageType).map((type) =>
    fee ? fee.filter((el) => el && el.type === type) : null,
  )
}
