import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import { useFlightDetailsEcoCheckModalQuestion } from '../../use-flight-details-eco-check-modal-question'
import {
  EmisisonDetailsHeader,
  EmisisonDetailsDescription,
  EmisisonDetails,
  LearnMoreLinkWrapper,
} from '../flight-details-eco-check-modal-mobile-styled'

type Props = {
  deviationLevel: EmissionsDeviationLevel
}

export function FlightDetailsEcoCheckModalMobileLowerBody({ deviationLevel }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const { questions, answers } = useFlightDetailsEcoCheckModalQuestion({ deviationLevel })

  return (
    <>
      <EmisisonDetails>
        <EmisisonDetailsHeader isFirstHeader>{questions.howDoWeKnow}</EmisisonDetailsHeader>
        <EmisisonDetailsDescription>{answers.howDoWeKnow}</EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>{questions.howDoWeEstimate}</EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {answers.howDoWeEstimate}
          <LearnMoreLinkWrapper>
            <Link
              href="https://www.thrustcarbon.com/policies/methodology"
              target="_blank"
              size="small">
              {t('Emissions.DetailsModal.LearnMore')}
            </Link>
          </LearnMoreLinkWrapper>
        </EmisisonDetailsDescription>
      </EmisisonDetails>
      <EmisisonDetails>
        <EmisisonDetailsHeader>
          {t(i18nBase + '.OtherWaysToReduceEmissionsHeader')}
        </EmisisonDetailsHeader>
        <EmisisonDetailsDescription>
          {t(i18nBase + '.OtherWaysToReduceEmissionsFlightDescription')}
        </EmisisonDetailsDescription>
      </EmisisonDetails>
    </>
  )
}
