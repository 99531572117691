import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { ScreenType, useScreenType } from '@fiji/modes'
import { UberTrackingRideViaQRCode } from '@etta/components/uber-tracking-ride-via-qr-code/uber-tracking-ride-via-qr-code'
import { Swap } from '@etta/ui/swap'
import {
  Container,
  Content,
  IconWrapper,
  Title,
  Footnote,
  UberQrCodeWrapper,
} from './mobility-booking-error-styled'
import { useMobilityBookingError } from './use-mobility-booking-error'

export function MobilityBookingError() {
  const { t } = useTranslation()
  const i18nBasePath = 'Mobility.BookingErrorPage.'

  const { handleRedirectToSearch, handleOpenUber } = useMobilityBookingError()
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const { searchRideHailStore } = useRideHailSearchContext()
  const {
    pickUpPlace,
    pickUpDate,
    pickUpTime,
    dropOffPlace,
  } = searchRideHailStore.searchRideHailForm

  return (
    <Container>
      <Content>
        <IconWrapper>
          <Icon name="errorPWA" color="error" size={41} />
        </IconWrapper>
        <Title>{t(i18nBasePath + 'Title')}</Title>
        <Footnote>{t(i18nBasePath + 'Description')}</Footnote>
        <Button fullWidth style={{ marginBottom: '20px' }} onClick={handleRedirectToSearch}>
          {t(i18nBasePath + 'TryAgainButtonText')}
        </Button>
        <Swap
          is={isDesktop}
          isSlot={
            <UberQrCodeWrapper>
              <UberTrackingRideViaQRCode
                pickUpPlace={pickUpPlace}
                dropOffPlace={dropOffPlace}
                pickUpDate={pickUpDate}
                pickUpTime={pickUpTime}
                isUberWithParam
                title={t(i18nBasePath + 'ScanToTryBookingAgain')}
              />
            </UberQrCodeWrapper>
          }>
          <Button fullWidth variant="outline" onClick={handleOpenUber}>
            {t(i18nBasePath + 'BookInUberButtonText')}
          </Button>
        </Swap>
      </Content>
    </Container>
  )
}
