import styled from 'styled-components'

export const Footer = styled.footer`
  min-height: 72px;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const FooterTotalPriceLine = styled.div`
  display: flex;
  flex-direction: column;
`

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
`
