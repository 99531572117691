import type { SwipingTimePickerBars, SwipingTimePickerUnits, SwipingPickerSubtype } from '../types'
import type { YearBounds } from '../types'
import { getConstants } from './get-constants'

export function getEnvironment(
  variant?: SwipingPickerSubtype,
  hourlyRange?: number,
  defaultYearBounds?: YearBounds,
) {
  const constants = getConstants(variant, hourlyRange, defaultYearBounds)
  const { UNIT, INIT_BARS } = constants
  const getBarTop = (top: number) => (1 - top) * UNIT.height
  const countBarUnits = (type: SwipingTimePickerUnits): number => {
    switch (type) {
      case 'hour':
        return UNIT.hourRange.length
      case 'min':
        return UNIT.minuteRange.length
      case 'day':
        return UNIT.dayRange.length
      case 'month':
        return UNIT.monthRange.length
      case 'year':
        return UNIT.yearRange.length
    }
    return 0
  }
  const getBarHeight = (type: SwipingTimePickerUnits) => UNIT.height * countBarUnits(type)

  const getInitBarTop = (top: number, type: SwipingTimePickerUnits) =>
    INIT_BARS.map(
      (_el, i) => getBarTop(top) + (top > 15 ? i : i - 1) * getBarHeight(type),
    ) as SwipingTimePickerBars

  const getMinBar = (flipColumns: number, values: SwipingTimePickerBars) => {
    switch (flipColumns) {
      case 2:
        return Math.min(values[0], values[1])
      case 3:
        return Math.min(values[0], values[1], values[2])
      default:
        return Math.min(...values)
    }
  }
  const getMaxBar = (flipColumns: number, values: SwipingTimePickerBars) => {
    switch (flipColumns) {
      case 2:
        return Math.max(values[0], values[1])
      case 3:
        return Math.max(values[0], values[1], values[2])
      default:
        return Math.max(...values)
    }
  }

  return { countBarUnits, getInitBarTop, getBarHeight, getMinBar, getMaxBar, constants }
}
