import { initialCustomTime, anyTimeOption } from './constants'
import { useTimeOptions } from './use-time-options'
import { useTimeForInitialFlight } from './use-time-for-initial-flight'

export function useFlightTimeConfiguration() {
  const timeOptions = useTimeOptions()
  const { getTimeForInitialFlight } = useTimeForInitialFlight()

  return {
    timeOptions,
    initialCustomTime,
    anyTimeOption,
    getTimeForInitialFlight,
  }
}
