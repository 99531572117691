import { observer } from 'mobx-react-lite'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import type { Props } from './types'
import { DateSectionLayout } from './layout'

export const HotelDateSection = observer(function HotelDateSection(props: Props) {
  const { hotelSearchFormStore } = useHotelSearchFormContext()

  return <DateSectionLayout {...props} validationErrors={hotelSearchFormStore.validationErrors} />
})
