import styled, { css } from 'styled-components'

const wrapperMobileStyles = css`
  padding: 8px 20px 20px 20px;
  background-color: ${(props) => props.theme.colors.background4};
  border-radius: 14px;
`

export const Wrapper = styled.div<{ isMobile: boolean }>`
  margin-bottom: 24px;
  ${(p) => p.isMobile && wrapperMobileStyles};
`
