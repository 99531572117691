import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import type { CarWorkingHours, Vendor } from '@fiji/graphql/types'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Params = {
  vendor?: Vendor | null
  carRental: CarRentalPostBookingValueObject
}

function getWorkingHours(workingHours?: CarWorkingHours | null) {
  return (workingHours?.range && workingHours?.range[0]) || null
}

export function useVendorDetails({ vendor, carRental: { pickUpLocation } }: Params) {
  const { t } = useTranslation()

  const { address, workingHours, phone } = pickUpLocation || {}
  const title = useMemo(
    () => (address ? vendor?.name + ', ' + formatPickUpDropOffAddress(address) : vendor?.name),
    [address, vendor?.name],
  )
  const location = useMemo(
    () =>
      address && `${vendor?.name ? `${vendor.name}, ` : ''}${formatPickUpDropOffAddress(address)}`,
    [address, vendor?.name],
  )

  const workingHoursText = useMemo(
    () => (workingHours?.isAllDay ? t('CarRentalModal.OpenAllDay') : getWorkingHours(workingHours)),
    [workingHours, t],
  )
  const callEnterpriseTranslation = t('CarRentalModal.CallEnterprise')
  const deskTimingsTranslation = t('CarRentalDetails.DeskTimings')

  const vendorAriaLabel = t('CarRentalModal.VendorAriaLabel', {
    supplier: vendor?.name,
    location,
    timings: workingHoursText,
    phoneNumber: phone?.number || '-',
  })

  return {
    phone,
    location,
    workingHoursText,
    workingHours,
    title,
    callEnterpriseTranslation,
    deskTimingsTranslation,
    vendorAriaLabel,
  }
}
