import { MediaLayout } from '@etta/ui/media-layout'
import type { FailedAlignedSegment as Props } from './types'
import { FailedAlignedSegmentDesktop } from './layout/desktop/failed-aligned-segment.desktop'
import { FailedAlignedSegmentMobile } from './layout/mobile/failed-aligned-segment.mobile'

export function FailedAlignedSegment(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<FailedAlignedSegmentMobile {...props} />}
      desktopSlot={<FailedAlignedSegmentDesktop {...props} />}
    />
  )
}
