import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { MultiModalRouteList } from '../core/value-objects/multi-modal-route.value-object'
import { MultiModalSearchErrors } from '../core/errors/multi-modal-search.error'
import type { MultiModalSearchResult, MultiModalSearchArgs } from './types'
import { MultiModalSearchDataProvider } from './multi-modal-search.data-provider/multi-modal-search.data-provider'
import { MultiModalResponseMapper } from './mappers/multi-modal-response.mapper'

@Adapter()
export class MultiModalSearchAdapter {
  constructor(
    @Inject()
    private multiModalSearchDataProvider: MultiModalSearchDataProvider,
  ) {}

  async search(param: MultiModalSearchArgs): MultiModalSearchResult<MultiModalRouteList> {
    try {
      const { data, error } = await this.multiModalSearchDataProvider.search({ input: param })
      if (
        error ||
        data.searchMobilityMultiModal.__typename === 'UnexpectedError' ||
        data.searchMobilityMultiModal.__typename === 'MobilityMultiModalSearchErrorResponse'
      ) {
        return Result.Err(new MultiModalSearchErrors.UnexpectedMultiModalSearchError(error))
      }

      return Result.Ok(
        MultiModalResponseMapper.mapToMultiModalRouteValueObject(data.searchMobilityMultiModal),
      )
    } catch (error: any) {
      return Result.Err(new MultiModalSearchErrors.UnexpectedMultiModalSearchError(error))
    }
  }
}
