import { Fragment } from 'react'
import type { Delegate } from '@fiji/graphql/hooks'
import { DelegateItem } from '@etta/components/delegate-item/delegate-item'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'

type Props = {
  delegates: Delegate[]
  choosenDelegateId: number
  handleSelectDelegate: (userId: string) => void
}
export function DelegateList({ delegates, handleSelectDelegate, choosenDelegateId }: Props) {
  return (
    <Fragment>
      {delegates.map((user, index) => (
        <Fragment key={index}>
          <Block paddingHorizontal={16} paddingVertical={12}>
            <DelegateItem
              showEmail
              delegate={user}
              onClick={() => handleSelectDelegate(user.userId || '')}
              isSelected={choosenDelegateId === Number(user.userId)}
              isListItem
            />
          </Block>
          <Block marginLeft={20}>
            <Separator />
          </Block>
        </Fragment>
      ))}
    </Fragment>
  )
}
