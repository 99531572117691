import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type { ZookeeperConfigurationValueObject } from '../core/value-objects'
import type { ZookeeperConfigurationErrorsInstances } from '../core/errors'
import { ZookeeperConfigurationErrors } from '../core/errors'
import { ZookeeperConfigurationMapper } from './mapper'
import { ZookeeperConfigurationDataProvider } from './zookeeper-configuration.data-provider'

@Adapter()
export class ZooKeeperConfigurationAdapter {
  constructor(
    @Inject()
    private zooKeeperConfigurationDataProvider: ZookeeperConfigurationDataProvider,
  ) {}

  async getZookeeperConfiguration(): PromisedResult<
    ZookeeperConfigurationValueObject,
    ZookeeperConfigurationErrorsInstances
  > {
    try {
      const {
        data,
        error,
      } = await this.zooKeeperConfigurationDataProvider.getZookeeperConfiguration()

      if (error) {
        return Result.Err(new ZookeeperConfigurationErrors.ZookeeperConfigurationResponse(error))
      }

      const zooKeeperConfiguration = ZookeeperConfigurationMapper.toValueObject(data.zooKeeperFlags)

      return Result.Ok(zooKeeperConfiguration)
    } catch (e) {
      return Result.Err(new ZookeeperConfigurationErrors.ZookeeperConfigurationUnexpectedError(e))
    }
  }
}
