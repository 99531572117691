import defaultBackground from './background.svg?url'
import { Container, Image } from './background-styled'

export function Background() {
  return (
    <Container>
      <Image src={defaultBackground} />
    </Container>
  )
}
