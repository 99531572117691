import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 168px 65px 72px;
  overflow: auto;

  & > div:first-child {
    margin-top: unset;
    padding-top: unset;
  }
`
