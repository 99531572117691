import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  margin: 0 30px;
`

export const Section = styled.div`
  display: flex;
`

export const AirlinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const AirlineLogosWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

export const Ellipse = styled.span`
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.bodyText1};
  margin: 0 4px;
`
