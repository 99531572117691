import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CollapsedStationsProps } from '../../types'
import { FirstColumn, GridRow } from '../../rail-route-details-styled'

const i18nBase = 'RailDetails.'

export function CollapsedStationsView({
  travelTime,
  stops,
  isExpanded,
  toggleExpansion,
}: CollapsedStationsProps) {
  const { t } = useTranslation()
  const showHideText = isExpanded ? t(i18nBase + 'HideStops') : t(i18nBase + 'ViewStops')
  const iconName = isExpanded ? 'chevronUpPWA' : 'chevronDownPWA'

  const stopsExpandLabel = t(i18nBase + 'AriaLabels.StopsExpandLabel', {
    time: travelTime,
  })

  const isNonStopsInfo = stops && stops.length === 0

  return (
    <GridRow>
      <FirstColumn></FirstColumn>
      {isNonStopsInfo && (
        <Text variant="footnoteStrong" color="bodyText">
          {t(i18nBase + 'Stops.NonstopInfo')}
        </Text>
      )}
      {!!stops?.length && (
        <Block aria-label={stopsExpandLabel} isFlexBlock onClick={toggleExpansion}>
          <Text variant="linkSmall" color="primary1" role="button" tabIndex={0} aria-label={''}>
            {showHideText}
          </Text>
          <Block marginLeft={4}>
            <Icon name={iconName} size="small" color="primary1" />
          </Block>
        </Block>
      )}
    </GridRow>
  )
}
