import type { ReactNode } from 'react'
import { TabsContext } from './tabs-context'
import { useTabs } from './use-tabs'
import { TabHeader } from './tab-header/tab-header'
import { Tab } from './tab'
import { TabPanel } from './tab-panel'
import type { TabName } from './types'

type Props<T extends TabName> = {
  activeTab: T
  onChangeTab: (newTabName: T) => void
  children: ReactNode
}

function Tabs<T extends TabName>({ activeTab, onChangeTab, children }: Props<T>) {
  const { context } = useTabs({ activeTab, onChangeTab })
  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>
}

const TabNamespace = Object.assign(Tabs, {
  Header: TabHeader,
  Tab,
  Panel: TabPanel,
})

export { TabNamespace as Tabs }
