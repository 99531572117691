import { Inject, Service } from '@etta/di'
import { UserStore } from '@etta/modules/user'
import { MeetingModeAdapter } from '../../infra/meeting-mode.adapter'
import type { UpsertMeetingInfoInput } from '../../core/value-objects/meeting-mode.value-object'
import { MeetingModeStore } from '../stores'

@Service()
export class MeetingModeService {
  constructor(
    @Inject() private meetingModeAdapter: MeetingModeAdapter,
    @Inject() private readonly meetingModeStore: MeetingModeStore,
    @Inject() private readonly userStore: UserStore,
  ) {}

  async upsertMeetingInfo(input: UpsertMeetingInfoInput) {
    const result = await this.meetingModeAdapter.upsertMeetingInfo(input)

    result.match({
      Ok: (upsertMeetingInfo) => {
        this.meetingModeStore.setMeetingInfo(upsertMeetingInfo)
        this.meetingModeAdapter.updateMeetingIdInCookie({ meetingId: upsertMeetingInfo.meeting.id })
        this.userStore.setMeetingId(upsertMeetingInfo.meeting.id)
      },
      Err: () => {
        this.meetingModeStore.setIsError(true)
      },
    })
  }

  async getMeetingInfo() {
    const result = await this.meetingModeAdapter.getMeetingInfo()

    result.match({
      Ok: (upsertMeetingInfo) => {
        this.meetingModeStore.setMeetingInfo(upsertMeetingInfo)
      },
      Err: () => {
        this.meetingModeStore.setIsError(true)
      },
    })
  }
}
