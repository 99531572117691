import styled from 'styled-components'
import { body, title3, footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 27px 20px 30px;

  &:last-child {
    border: 0;
  }
`

export const ReasonMessage = styled.div`
  margin: 0 0 16px;
`

export const Heading = styled.div`
  margin: 0 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${title3};
`

export const Label = styled.div`
  margin: 0 0 8px;
  ${body};
`

export const Error = styled.div`
  color: ${(p) => p.theme.colors.error};
  margin-top: 5px;
  ${footnoteMedium};
`
