import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { CountrySearch } from '@etta/components/country-search/country-search'
import { DropDownList } from '@etta/ui/drop-down-list'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Button } from '@etta/ui/button'
import { TextFieldGroup } from '@etta/ui/text-field-group'
import { DateOfBirthField } from '@etta/ui/date-of-birth-picker'
import { helperTypeUndefinedCheck } from '@etta/screens/traveler-info/purchase-user-info/user-info-form/data-checks'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import type { Gender } from '@etta/core/enums'
import { default as countryCodesForPhone } from '../../country-codes-phone.json'
import type { ComponentProps } from '../../types'
import { getDropDownProps } from './get-drop-down-props'
import {
  Body,
  Footer,
  HalfBlock,
  HalfBlockInline,
  Headline,
  SaveButton,
  Subheadline,
} from './user-info-form-desktop-styled'

const MAX_LENGTH_40 = 40

const i18nBase = 'UserInfo.'

export function UserInfoFormDesktop({
  profileOptions,
  isDateOfBirthPopupVisible,
  onDateOfBirthOpen,
  onDateOfBirthClose,
  onChangeOfGender,
  errors,
  values,
  onFieldChange,
  handleSubmit,
  isGenderRequired,
  isDateOfBirthRequired,
  isFirstNameRequired,
  isLastNameRequired,
  isMiddleNameRequired,
  isSuffixRequired,
  isEMailRequired,
  isPhoneNumberRequired,
  isAddressRequired,
  isKtnHidden,
  isRedressNumber,
  isGenderInputDisabled,
  isDateOfBirthInputDisabled,
  isSuffixInputDisabled,
  onClose,
  isKtnEditingDisabled,
  isRedressNumberEditingDisabled,
  isTitleDisabled,
  isEmailInputDisabled,
  isFirstNameDisabled,
  isMiddleNameDisabled,
  isLastNameDisabled,
  isAddressDisabled,
  isPhoneNumberInputDisabled,
  isPersonalMobileInputDisabled,
}: ComponentProps) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()

  const { purchaseInfoStore } = usePurchaseInfoContext()

  const isUserInDelegatedOrImpersonatedMode = !!(userStore.delegatedId || userStore.impersonatedId)
  const isDobFieldObfuscated =
    isUserInDelegatedOrImpersonatedMode && !purchaseInfoStore.isDobTouched

  const isGenderFieldObfuscated =
    isUserInDelegatedOrImpersonatedMode && !purchaseInfoStore.isGenderTouched

  const {
    options,
    type,
    withListBodyPadding,
    withListHeader,
    withVisibleLabel,
    withThinLabel,
  } = getDropDownProps({
    fieldName: 'gender',
    options: profileOptions.genders,
    onFieldChange,
  })

  return (
    <>
      <Body>
        <Block paddingLeft={32} paddingRight={200} paddingBottom={32}>
          <Block marginTop={32}>
            <Headline>{t(i18nBase + 'Identification')}</Headline>
            <Subheadline>{t(i18nBase + 'ConfirmName')}</Subheadline>
          </Block>
          <HalfBlock marginTop={12}>
            <DropDownList
              label={t(i18nBase + 'OptionalTitle')}
              value={[values.title]}
              isDisabled={isTitleDisabled}
              {...getDropDownProps({
                fieldName: 'title',
                options: profileOptions.titles,
                onFieldChange,
              })}
              data-tracking-id="user-title-dropdown"
              withNoneOption
            />
          </HalfBlock>
          <Block marginTop={12}>
            <TextField
              value={values.firstName}
              onChange={onFieldChange('firstName')}
              label={t(i18nBase + (isFirstNameRequired ? 'FirstName' : 'OptionalFirstName'))}
              maxLength={MAX_LENGTH_40}
              helperType="error"
              helperText={errors.firstName}
              isDisabled={isFirstNameDisabled}
            />
          </Block>
          <Block marginTop={12}>
            <TextField
              value={values.middleName}
              onChange={onFieldChange('middleName')}
              label={t(i18nBase + (isMiddleNameRequired ? 'MiddleName' : 'OptionalMiddleName'))}
              maxLength={MAX_LENGTH_40}
              helperType="error"
              helperText={errors.middleName}
              isDisabled={isMiddleNameDisabled}
            />
          </Block>
          <Block marginTop={12}>
            <TextField
              value={values.lastName}
              onChange={onFieldChange('lastName')}
              label={t(i18nBase + (isLastNameRequired ? 'LastName' : 'OptionalLastName'))}
              maxLength={MAX_LENGTH_40}
              helperType="error"
              helperText={errors.lastName}
              isDisabled={isLastNameDisabled}
            />
          </Block>
          <HalfBlock marginTop={12}>
            <DropDownList
              label={t(i18nBase + (isSuffixRequired ? 'Suffix' : 'OptionalSuffix'))}
              value={[values.suffix]}
              isDisabled={isSuffixInputDisabled}
              helperType="error"
              helperText={errors.suffix}
              {...getDropDownProps({
                fieldName: 'suffix',
                options: profileOptions.suffixes,
                onFieldChange,
              })}
              data-tracking-id="user-suffix-dropdown"
              withNoneOption={!isSuffixRequired}
            />
          </HalfBlock>
          <Block marginTop={12}>
            <DropDownList<Gender>
              label={t(i18nBase + (isGenderRequired ? 'Gender' : 'OptionalGender'))}
              value={[values.gender]}
              isDisabled={isGenderInputDisabled}
              helperType={helperTypeUndefinedCheck(isGenderRequired)}
              helperText={errors.gender}
              options={options}
              type={type}
              withListBodyPadding={withListBodyPadding}
              withListHeader={withListHeader}
              withVisibleLabel={withVisibleLabel}
              withThinLabel={withThinLabel}
              isBlurred={isGenderFieldObfuscated}
              onChange={onChangeOfGender}
              data-tracking-id="user-gender-dropdown"
              withNoneOption={!isGenderRequired}
            />
          </Block>
          <Block marginTop={12}>
            <DateOfBirthField
              onChange={onFieldChange('dateOfBirth')}
              value={values.dateOfBirth}
              isOpen={isDateOfBirthPopupVisible}
              onClose={onDateOfBirthClose}
              onOpenPicker={onDateOfBirthOpen}
              helperType="error"
              helperText={errors.dateOfBirth}
              isDisabled={isDateOfBirthInputDisabled}
              isHideValue={isDobFieldObfuscated}
              isBlurred={isDobFieldObfuscated}
              label={t(i18nBase + (isDateOfBirthRequired ? 'DOB' : 'OptionalDOB'))}
            />
          </Block>
          <Block marginTop={12}>
            <TextField
              value={values.email}
              onChange={onFieldChange('email')}
              label={t(i18nBase + (isEMailRequired ? 'Email' : 'OptionalEmail'))}
              helperType="error"
              helperText={errors.email}
              isDisabled={isEmailInputDisabled}
            />
          </Block>
          <Block marginTop={12}>
            <TextFieldGroup>
              <CountrySearch
                customOptions={countryCodesForPhone}
                value={values.phoneCountryCode}
                setValue={onFieldChange('phoneCountryCode')}
                helperType="error"
                isDisabled={isPhoneNumberInputDisabled}
              />
              <TextField
                type="phone"
                value={values.phone}
                onChange={onFieldChange('phone')}
                label={t(i18nBase + (isPhoneNumberRequired ? 'Phone' : 'OptionalPhone'))}
                helperType="error"
                helperText={[errors.phone, errors.phoneCountryCode].filter(Boolean).join(', ')}
                isDisabled={isPhoneNumberInputDisabled}
              />
            </TextFieldGroup>
          </Block>
          <Block marginTop={12} marginBottom={40}>
            <TextFieldGroup>
              <CountrySearch
                customOptions={countryCodesForPhone}
                value={values.personalMobileCountryCode}
                setValue={onFieldChange('personalMobileCountryCode')}
                helperType="error"
                isDisabled={isPersonalMobileInputDisabled}
              />
              <TextField
                type="phone"
                value={values.personalMobile}
                onChange={onFieldChange('personalMobile')}
                label={t(i18nBase + (isPhoneNumberRequired ? 'Mobile' : 'OptionalMobile'))}
                helperType="error"
                helperText={[errors.personalMobile, errors.personalMobileCountryCode]
                  .filter(Boolean)
                  .join(', ')}
                isDisabled={isPersonalMobileInputDisabled}
              />
            </TextFieldGroup>
          </Block>
          {isAddressRequired && (
            <>
              <Headline>
                {t(i18nBase + (isAddressRequired ? 'Address' : 'AddressOptional'))}
              </Headline>
              <Block marginTop={5}>
                <CountrySearch
                  value={values.country}
                  setValue={onFieldChange('country')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.country}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <HalfBlockInline marginTop={12}>
                <TextField
                  value={values.zip}
                  label={t(i18nBase + 'ZIP')}
                  onChange={onFieldChange('zip')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.zip}
                  isDisabled={isAddressDisabled}
                />
                <TextField
                  value={values.state}
                  label={t(i18nBase + 'State')}
                  onChange={onFieldChange('state')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.state}
                  isDisabled={isAddressDisabled}
                />
              </HalfBlockInline>
              <Block marginTop={12}>
                <TextField
                  value={values.city}
                  label={t(i18nBase + 'City')}
                  onChange={onFieldChange('city')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.city}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <Block marginTop={12}>
                <TextField
                  value={values.street1}
                  label={t(i18nBase + 'Street1')}
                  onChange={onFieldChange('street1')}
                  helperType={helperTypeUndefinedCheck(isAddressRequired)}
                  helperText={errors.street1}
                  isDisabled={isAddressDisabled}
                />
              </Block>
              <Block marginTop={12} marginBottom={40}>
                <TextField
                  value={values.street2}
                  onChange={onFieldChange('street2')}
                  label={t(i18nBase + 'Street2')}
                  isDisabled={isAddressDisabled}
                />
              </Block>
            </>
          )}

          {!isKtnHidden && (
            <>
              <Headline>{t(i18nBase + 'KnownTravellerNumberTitle')}</Headline>
              <Block marginTop={12}>
                <TextField
                  value={values.knownTravelerNumber}
                  label={t(i18nBase + 'KnownTravellerNumber')}
                  onChange={onFieldChange('knownTravelerNumber')}
                  isDisabled={isKtnEditingDisabled}
                />
              </Block>
              <Block marginTop={12} marginBottom={40}>
                <CountrySearch
                  value={values.knownTravelerIssuingCountry}
                  customLabel={t(i18nBase + 'KnownTravellerNumber')}
                  setValue={onFieldChange('knownTravelerIssuingCountry')}
                  isDisabled={isKtnEditingDisabled}
                />
              </Block>
            </>
          )}
          {!isRedressNumber && (
            <>
              <Headline>{t(i18nBase + 'RedressNumber')}</Headline>
              <Block marginTop={12} marginBottom={40}>
                <TextField
                  value={values.redressNumber}
                  label={t(i18nBase + 'RedressNumber')}
                  onChange={onFieldChange('redressNumber')}
                  isDisabled={isRedressNumberEditingDisabled}
                />
              </Block>
            </>
          )}
        </Block>
      </Body>
      <Footer>
        <Button variant="link" onClick={onClose}>
          {t(i18nBase + 'Cancel')}
        </Button>
        <SaveButton onClick={handleSubmit} variant="solid" btnType="primary" minWidth={21}>
          {t(i18nBase + 'Save')}
        </SaveButton>
      </Footer>
    </>
  )
}
