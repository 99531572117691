import styled, { css } from 'styled-components'
import { forwardRef } from 'react'
import type { LottieAnimationProps, AnimationItem } from '@etta/ui/lottie-animation'
import { LottieAnimation } from '@etta/ui/lottie-animation'
import { LOTTIE_TRANSITION_TOTAL_DURATION } from './constants'

const FOOTER_PADDING_TOP = 26
const FOOTER_PADDING_BOTTOM = 16
const PREVIOUS_CONTENT_POSITION = 0
const CURRENT_CONTENT_POSITION = -100
const NEXT_CONTENT_POSITION = -200

export const RootContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 82.5%;
`

export const Background = styled.img`
  width: 100%;
  height: auto;
`

export const ScreenFooter = styled.div<{ height?: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${FOOTER_PADDING_TOP}px 0 ${FOOTER_PADDING_BOTTOM}px 0;
  border-radius: 14px 14px 0 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -17px 115px rgba(16, 24, 32, 0.1);
  backdrop-filter: blur(49px);
  z-index: 3;
  overflow: hidden;
  transition: height 200ms linear;
  ${(p) =>
    p.height &&
    css`
      height: ${p.height + FOOTER_PADDING_TOP + FOOTER_PADDING_BOTTOM}px;
    `};
`

export const ScreenFooterViewport = styled.div<{ showPrevious: boolean; showNext: boolean }>`
  display: flex;
  width: 100%;
  transform: translateX(
    ${(p) =>
      p.showNext
        ? NEXT_CONTENT_POSITION
        : p.showPrevious
        ? PREVIOUS_CONTENT_POSITION
        : CURRENT_CONTENT_POSITION}%
  );
  transition: transform 200ms linear;
`

export const StyledLottieAnimation = styled(
  forwardRef<AnimationItem, LottieAnimationProps>((props, ref) => (
    <LottieAnimation {...props} ref={ref} />
  )),
)<{
  zIndex: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
  margin-left: -10% !important;
`

export const IdleLottieAnimation = styled(StyledLottieAnimation)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.2)});
  transition: opacity ${LOTTIE_TRANSITION_TOTAL_DURATION * 0.85}ms ease-in-out
      ${LOTTIE_TRANSITION_TOTAL_DURATION * 0.15}ms,
    transform ${LOTTIE_TRANSITION_TOTAL_DURATION / 2}ms ease-out
      ${LOTTIE_TRANSITION_TOTAL_DURATION / 2}ms;
`
