import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import { getCarSecurityCodeScheme } from '@etta/components/credit-card-item/get-car-security-code-scheme'

type Args = {
  isCar?: boolean
  isFlight?: boolean
  isHotel?: boolean
  isRail?: boolean
}

export function usePaymentValidator({ isCar, isFlight, isHotel, isRail }: Args) {
  const { t } = useTranslation()
  const i18BaseErrors = 'CreditCardForm.Errors.'

  const securityCodeScheme = getCarSecurityCodeScheme()

  const schemeRequired = securityCodeScheme
    .required(t(i18BaseErrors + 'SecurityCode.Invalid'))
    .default('')
  const schemeOptional = yup.string().optional().default('')

  const cardScheme = useScheme(() => {
    return {
      securityCodeCar: isCar ? schemeRequired : schemeOptional,
      securityCodeFlight: isFlight ? schemeRequired : schemeOptional,
      securityCodeHotel: isHotel ? schemeRequired : schemeOptional,
      securityCodeRail: isRail ? schemeRequired : schemeOptional,
    }
  }, [isCar, isFlight, isHotel, isRail])

  const validator = useValidation(cardScheme)

  return validator
}
