import { useEffect } from 'react'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

type Props = {
  skip?: boolean
}

export function useLoadData({ skip = false }: Props) {
  const { tripActions, reviewTripStore } = useReviewTripContext()

  const { trip, isTripLoading, isError } = reviewTripStore

  const { tripCost, tripInfo, virtualPay } = trip || {}

  useEffect(() => {
    if (skip) {
      return
    }
    tripActions.setTripIdUrlParams()
    tripActions.loadTrip()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  const tripName = tripInfo?.name
  const startDate = tripInfo?.startDate
  const endDate = tripInfo?.endDate

  return {
    isDataError: isError,
    tripCost,
    tripName,
    startDate,
    endDate,
    virtualPay,
    segments: reviewTripStore.segments || [],
    isDataLoading: isTripLoading,
    refetchItinerary: tripActions.loadTrip,
  }
}
