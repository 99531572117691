import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { Props } from '../../types'
import { useUnusedTicket } from '../../use-unused-ticket'
import {
  AirlineLogo,
  AirlineLogoWrapper,
  Container,
  Content,
  Status,
  SubHeader,
  TicketDetail,
  TicketDetails,
} from './unused-ticket-desktop-styled'

const i18nBase = 'UnusedTickets.Ticket'
const i18nBaseAria = 'Accessibility.Settings'

export const UnusedTicketDesktop = observer(function UnusedTicketDesktop({ unusedTicket }: Props) {
  const { t } = useTranslation()
  const {
    expiresInColor,
    expirationLabel,
    statusColor,
    statusLabel,
    ticketDetails,
  } = useUnusedTicket({
    unusedTicket,
  })
  return (
    <Container>
      <AirlineLogoWrapper>
        <AirlineLogo
          src={unusedTicket.airline?.imageUrl ?? ''}
          alt={unusedTicket.airline?.value ?? ''}
        />
      </AirlineLogoWrapper>
      <Content>
        <Text
          variant="body"
          color="mainText"
          aria-label={t(`${i18nBaseAria}.Airline`, { label: unusedTicket.airline?.value || '' })}>
          {unusedTicket.airline?.value}
        </Text>
        <SubHeader>
          <Status color={statusColor}>
            <Text
              variant="captionStrongCaps"
              color={statusColor}
              aria-label={t(`${i18nBaseAria}.Status`, { label: statusLabel })}>
              {statusLabel}
            </Text>
          </Status>
          <Text isBlock variant="footnoteMedium" color={expiresInColor}>
            {expirationLabel}
          </Text>
        </SubHeader>

        <TicketDetails>
          {ticketDetails.map((ticketDetail, key) => (
            <TicketDetail aria-label={t(`${i18nBase}.${ticketDetail.title}`)} key={key}>
              <Text variant="footnoteMedium" color="bodyText">
                {t(`${i18nBase}.${ticketDetail.title}`)}
              </Text>
              <Text variant="subHeadStrong" color="mainText">
                {ticketDetail.detail}
              </Text>
            </TicketDetail>
          ))}
        </TicketDetails>
      </Content>
    </Container>
  )
})
