import { isoToDate } from './iso-to-date'
import { deleteTimezone } from './delete-timezone'
import { getDifferenceInDays } from './get-difference-in-days'

export function getDifferenceInDaysBetweenStartAndEndIsoStrings(
  startDate: string,
  endDate: string,
): number {
  if (!startDate || !endDate) {
    return 0
  }

  const start = isoToDate(deleteTimezone(startDate))
  const end = isoToDate(deleteTimezone(endDate))

  return Math.abs(getDifferenceInDays(end, start))
}
