import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import {
  ConfirmationButtonContainer,
  ConfirmationContainer,
  ConfirmationTextContainer,
} from './delegate-modal-desktop-styled'

const i18Base = 'Delegate.SwitchConfirmation'

type Props = {
  handleConfirm: () => void
  handleClose: () => void
}

export function DelegateModalDekstopConfirmation({ handleConfirm, handleClose }: Props) {
  const { t } = useTranslation()

  return (
    <ConfirmationContainer>
      <Icon color="secondary" size={110} name="warningFilledPWA" />
      <ConfirmationTextContainer>
        <Text isBlock align="center" color="mainText1" variant="body">
          {t(i18Base + '.Description')}
        </Text>
      </ConfirmationTextContainer>
      <ConfirmationButtonContainer>
        <Button variant="outline" onClick={handleClose}>
          {t(i18Base + '.NotNow')}
        </Button>
        <Button variant="solid" onClick={handleConfirm}>
          {t(i18Base + '.SwitchButton')}
        </Button>
      </ConfirmationButtonContainer>
    </ConfirmationContainer>
  )
}
