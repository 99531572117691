import { Store } from '@etta/di'

@Store()
export class QualtricsStore {
  private _modalVisible: boolean = false
  private _portalId: string | undefined = process.env.REACT_APP_QUALTRICS_PORTAL_ID
  private _interceptId: string | undefined = process.env.REACT_APP_QUALTRICS_INTERCEPT_ID

  setModalVisible(visible: boolean) {
    this._modalVisible = visible
  }

  get modalVisible() {
    return this._modalVisible
  }

  get portalId() {
    return this._portalId ?? 'QUALTRICS_PORTAL_ID'
  }

  get interceptId() {
    return this._interceptId ?? 'QUALTRICS_INTERCEPT_ID'
  }

  dropStore() {
    this._modalVisible = false
  }
}
