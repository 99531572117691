import ReactSlider from 'react-slider'
import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.div`
  height: 46px;
  position: relative;
`

export const ReactSliderWrapper = styled(ReactSlider)`
  top: 40%;
  position: absolute !important;
  left: 0;
  right: -30px;

  .track {
    margin-right: 30px;
  }
`

export type ThumbStyledProps = {
  isDisabled: boolean
  style: object
  className: string
  key: number
}

const ThumbStyledDisabled = css`
  background-color: ${(props) => props.theme.colors.borderDark};
  cursor: default;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
`

const ThumbStyledPoint = css`
  :before {
    content: '';
    position: absolute;
    width: 54px;
    height: 54px;
    opacity: 0;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-left: -12px;
    margin-top: -12px;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    transform: scale(0, 0);
  }

  :active:before {
    opacity: 0.22;
    transform: scale(1, 1);
  }
`

export const ThumbStyled = styled.div<ThumbStyledProps>`
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
  height: 30px;
  width: 30px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.16),
    0 2px 1px rgba(0, 0, 0, 0.1);
  cursor: grab;

  ${(props) => (props.isDisabled ? ThumbStyledDisabled : ThumbStyledPoint)}
  ${KeyboardNavigationStyle}
`

export type TrackStyledProps = {
  index: number
  isValueArray: boolean
  isMarksExists?: boolean
  isFilled?: boolean
  style: object
  className: string
  key: number
}
