import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { SelectedSearchParametersBlock } from '../shared/selected-search-parameters-block'
import { BackButtonRow, FilterButtonWrapper } from './search-results-header-compact-styled'

export type HeaderCompactProps = {
  onBackClick: () => void
  onFilterClick: () => void
  onLabelClick: () => void
  mobileLocationsLabel?: string
  locationsLabel?: string
  mobileDatesLabel?: string
  datesLabel?: string
  isFilterActive?: boolean
  isDisabled?: boolean
  isShowFilters?: boolean
}

export function SearchResultsHeaderCompact({
  datesLabel,
  mobileDatesLabel,
  mobileLocationsLabel,
  locationsLabel,
  onBackClick,
  onFilterClick,
  onLabelClick,
  isFilterActive = false,
  isDisabled = false,
  isShowFilters = true,
}: HeaderCompactProps) {
  const { t } = useTranslation()
  const label = mobileLocationsLabel || locationsLabel || ''
  const date = mobileDatesLabel || datesLabel || ''

  return (
    <Header
      data-tracking-id="hotel-search-header"
      withBorderBottom
      layout="search-result"
      leftSlot={
        <BackButtonRow>
          <Header.BackButton onClick={onBackClick} />
        </BackButtonRow>
      }
      rightSlot={
        isShowFilters ? (
          <FilterButtonWrapper isDisabled={isDisabled}>
            <IconButton
              icon={isFilterActive ? 'filtersHorizontalActivePWA' : 'filtersHorizontalPWA'}
              size="medium"
              color="mainText"
              onClick={isDisabled ? undefined : onFilterClick}
              aria-label={t('AirSearchResults.Header.Filters')}
            />
          </FilterButtonWrapper>
        ) : (
          <Block paddingHorizontal={3} />
        )
      }>
      <SelectedSearchParametersBlock
        datesLabel={date}
        isDisabled={isDisabled}
        locationsLabel={label}
        onLabelClick={onLabelClick}
      />
    </Header>
  )
}
