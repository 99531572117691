import styled from 'styled-components'
import { forwardRef } from 'react'

import type { LottieAnimationProps } from '@etta/ui/lottie-animation'
import type { AnimationItem } from '@etta/ui/lottie-animation'
import { LottieAnimation } from '@etta/ui/lottie-animation'
import { subHeadStrong } from '@fiji/style'

const LOTTIE_TRANSITION_TOTAL_DURATION = 600

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

export const StyledLottieAnimation = styled(
  forwardRef<AnimationItem, LottieAnimationProps>((props, ref) => (
    <LottieAnimation {...props} ref={ref} />
  )),
)<{
  zIndex: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
  margin-left: -10% !important;
`

export const IdleLottieAnimation = styled(StyledLottieAnimation)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.2)});
  transition: opacity ${LOTTIE_TRANSITION_TOTAL_DURATION * 0.85}ms ease-in-out
      ${LOTTIE_TRANSITION_TOTAL_DURATION * 0.15}ms,
    transform ${LOTTIE_TRANSITION_TOTAL_DURATION / 2}ms ease-out
      ${LOTTIE_TRANSITION_TOTAL_DURATION / 2}ms;
`

export const Description = styled.div`
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  ${subHeadStrong}
`
