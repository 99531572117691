import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SearchId } from './types'

const initialState: SearchId = ''

const slice = createSlice({
  name: 'search-id',
  initialState,
  reducers: {
    updateSearchId(_, action: PayloadAction<null | string>) {
      return action.payload || ''
    },
  },
})

export const searchIdReducer = slice.reducer
export const { updateSearchId } = slice.actions
