import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
import { Menu } from '@etta/ui/menu'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import type { GetHotelsSortBy } from '@etta/modules/hotel-search/core/value-objects/get-hotels-sorty-by.value-object'
import { Container, Title } from './hotel-sort-menu-styled'
import { SORTING_OPTION_TRANSLATION_MAPPER } from './constants'
import type { HotelSortMenuViewType } from './types'

type Props = {
  viewType?: HotelSortMenuViewType
}

const sortByi18nBase = 'SearchResults.Header.SortBy'

export const HotelSortMenu = observer(function HotelSortMenu({ viewType = 'only-icon' }: Props) {
  const { hotelSearchResultsStore, loadHotelsAction } = useHotelSearchResultsContext()
  const { t } = useTranslation()
  const sortingOptions: { id: GetHotelsSortBy; name: string }[] = useMemo(
    () => [
      {
        id: 'preference',
        name: t(SORTING_OPTION_TRANSLATION_MAPPER['preference']),
      },
      { id: 'policy', name: t(SORTING_OPTION_TRANSLATION_MAPPER['policy']) },
      {
        id: 'distance',
        name: t(SORTING_OPTION_TRANSLATION_MAPPER['distance']),
      },
      {
        id: 'price',
        name: t(SORTING_OPTION_TRANSLATION_MAPPER['price']),
      },
      {
        id: 'starrating',
        name: t(SORTING_OPTION_TRANSLATION_MAPPER['starrating']),
      },
    ],
    [t],
  )
  const selectedItemTitle = `${t(sortByi18nBase)} ${t(
    SORTING_OPTION_TRANSLATION_MAPPER[hotelSearchResultsStore.sortBy],
  )}`

  return (
    <Menu<GetHotelsSortBy>
      openByClickOnEl={
        <Container>
          <Icon name="sortPWA" size="normal" color="mainText" />
          {viewType === 'with-title' && <Title>{selectedItemTitle}</Title>}
        </Container>
      }
      shouldCloseOnClickOutside
      title="Sort By"
      options={sortingOptions}
      activeOptionId={hotelSearchResultsStore.sortBy}
      activeOptionIcon={<Icon name="tickPWA" size="small" color="primary" />}
      onOptionSelect={loadHotelsAction.onSortOptionChange}
      position={'right'}
    />
  )
})
