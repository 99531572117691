import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import * as IconPack from './assets'
import { Container, Content, Button, SeatContainer, SeatIconContainer } from './seat-action-styled'

type Props = {
  seatFeature?: string
  isLastSegmentInFlightLeg?: boolean
  isVisible: boolean
  onClick: () => void
  onClose?: () => void
}

const i18nBase = 'AirSeatMap.Footer'

export function SeatAction({
  seatFeature,
  isLastSegmentInFlightLeg,
  isVisible,
  onClick,
  onClose,
}: Props) {
  const { t } = useTranslation()

  const buttonText = isLastSegmentInFlightLeg
    ? t(`${i18nBase}.DoneSelection`)
    : t(`${i18nBase}.NextFlight`)

  const buttonAction = isLastSegmentInFlightLeg && onClose ? onClose : onClick

  return (
    <Container isVisible={isVisible}>
      <Content>
        {seatFeature && (
          <SeatContainer>
            <SeatIconContainer>
              <IconPack.Seat />
            </SeatIconContainer>
            <Text variant="headline" color="mainText">
              {seatFeature}
            </Text>
          </SeatContainer>
        )}
        <Button onClick={buttonAction}>{buttonText}</Button>
      </Content>
    </Container>
  )
}
