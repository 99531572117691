import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '@etta/ui/error-container'
import { Screen } from '@etta/ui/screen'
import { Form } from '@etta/ui/form'
import { LazyContent } from '@etta/ui/lazy-content'
import { Swap } from '@etta/ui/swap'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { OnAccountCreditCardInfo } from '@etta/screens/payment-summary/on-account-credit-card-info'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { LayoutProps } from '../../types'
import { PaymentDynamicSiteMessages } from '../../payment-dynamic-site-messages'
import { PaymentCreditCard } from '../../payment-credit-card'
import { CarRentals } from '../../sections/car-rentals'
import { Hotels } from '../../sections/hotels'
import { PreviousBooking } from '../../previous-booking'
import { Flights } from '../../sections/flights'
import { Rail } from '../../sections/rail'
import { Content, InfoTitle } from '../../payment-summary-styled'
import { AddLuggageButton } from '../../add-luggage-button'
import { TicketExchangeAcknowledgement } from '../../ticket-exchange-acknowledge'
import { CreditCardBlock } from './credit-card-block'

const i18nBase = 'PaymentSummary.'

export const PaymentSummaryMobile = observer(function PaymentSummaryMobile({
  headerSlot,
  shouldRenderPreviousBooking,
  footerSlot,
  dynamicSiteMessages,
  airCardCheck,
  carRentalCardCheck,
  carRentalDetails,
  flightDetails,
  hotelCardCheck,
  hotelDetails,
  isCarPaymentRequired,
  isCreditCardChangeable,
  isLoading,
  isLoadingError,
  newCarRentals,
  newFlights,
  newHotels,
  newRail,
  onBillingModalOpen,
  onPaymentSubmit,
  onReload,
  railCardCheck,
  selectedLuggageOption,
  handleOpenLuggageToggle,
  showLuggageOptions,
  railDetails,
  tripCost,
  validator,
  bookedCarRentals,
  bookedFlights,
  bookedHotels,
  isRailAccreditationEnabled,
  isTripOnHold,
  openAddCreditCardModal,
  isBilledToCompanyAccount,
}: LayoutProps) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const { t } = useTranslation()
  const mappedFlight = newFlights?.segments.filter(segmentTypeCheckActions.isFlightSegment)
  const mappedHotel = newHotels?.segments.filter(segmentTypeCheckActions.isHotelSegment)
  const mappedCarRental = newCarRentals?.segments.filter(segmentTypeCheckActions.isCarRentalSegment)
  const mappedRail = newRail?.segments.filter(segmentTypeCheckActions.isTrainSegment)

  const areFlightSegments = !!newFlights?.segments?.length
  const areHotelSegments = !!newHotels?.segments?.length
  const areCarRentalSegments = !!newCarRentals?.segments.length

  const { queryParams } = useCheckoutQuery()
  const { isHoldFlow } = queryParams

  const {
    featureFlagsStore: {
      flags: { isUnusedTicketsEnabled },
    },
  } = useFeatureFlagsContext()

  return (
    <Screen backgroundColor="background">
      <Screen.Header>{headerSlot}</Screen.Header>

      <Screen.Container as={'main'}>
        <PaymentDynamicSiteMessages dynamicSiteMessages={dynamicSiteMessages} />
        <Content>
          <InfoTitle
            data-tracking-id="payment-summary-title-text"
            aria-label={t(i18nBase + 'Headline')}>
            {t(i18nBase + 'Title')}
          </InfoTitle>
          <>
            {shouldRenderPreviousBooking && (
              <PreviousBooking
                tripCost={tripCost}
                flights={bookedFlights}
                hotels={bookedHotels}
                carRentals={bookedCarRentals}
                isExtendedByDefault={isTripOnHold}
              />
            )}
            <Swap
              is={isLoadingError}
              isSlot={
                <ErrorContainer onReload={onReload} title={t('PaymentSummary.UnableToLoad')} />
              }>
              <LazyContent isLoading={isLoading}>
                <Form onSubmit={onPaymentSubmit}>
                  <Flights
                    segments={mappedFlight}
                    segmentState={newFlights?.segmentState}
                    tripCostSummary={tripCost}
                    flightTripCost={newFlights?.tripCost}
                    isHoldFlow={isHoldFlow}>
                    {showLuggageOptions && (
                      <AddLuggageButton
                        selectetedLuggageOption={selectedLuggageOption}
                        onClick={handleOpenLuggageToggle}
                      />
                    )}

                    {isUnusedTicketsEnabled && <TicketExchangeAcknowledgement />}
                    <CreditCardBlock
                      creditCard={flightDetails?.creditCard}
                      onAddNewCreditCard={openAddCreditCardModal('flight')}
                      onEdit={onBillingModalOpen}
                      type={'flight'}
                      billingError={airCardCheck.billingError}>
                      {flightDetails?.creditCard && (
                        <PaymentCreditCard
                          creditCard={flightDetails?.creditCard}
                          mainValidator={validator}
                          type={'flight'}
                          onEdit={onBillingModalOpen}
                          isCvvRequired={
                            !flightDetails?.creditCard.isSiteCard &&
                            flightDetails?.billing?.metadata.isCvvRequired
                          }
                          isCreditCardChangeable={isCreditCardChangeable.flight}
                        />
                      )}
                    </CreditCardBlock>
                  </Flights>

                  <Hotels
                    segments={mappedHotel}
                    segmentState={newHotels?.segmentState}
                    hotelTripCost={newHotels?.tripCost}
                    isPayingNow={newHotels?.isPayNow && !isHoldFlow}
                    withMarginTop={areFlightSegments}
                    previousBookingRate={newHotels?.previousBookingRate}>
                    <CreditCardBlock
                      creditCard={hotelDetails?.creditCard}
                      onAddNewCreditCard={openAddCreditCardModal('hotel')}
                      isCardExpiringEarlierThanHotel={hotelCardCheck.isCardExpiringEarlierThanHotel}
                      onEdit={onBillingModalOpen}
                      type={'hotel'}
                      billingError={hotelCardCheck.billingError}>
                      {hotelDetails?.creditCard && (
                        <PaymentCreditCard
                          virtualPay={hotelDetails?.virtualPay}
                          creditCard={hotelDetails?.creditCard!}
                          mainValidator={validator}
                          type={'hotel'}
                          onEdit={onBillingModalOpen}
                          isCvvRequired={
                            !hotelDetails?.creditCard.isSiteCard &&
                            hotelDetails?.billing?.metadata.isCvvRequired
                          }
                          isCreditCardChangeable={isCreditCardChangeable.hotel}
                        />
                      )}
                    </CreditCardBlock>
                  </Hotels>

                  <CarRentals
                    segments={mappedCarRental}
                    segmentState={newCarRentals?.segmentState}
                    carTripCost={newCarRentals?.tripCost}
                    isPayingNow={newCarRentals?.isPayNow && !isHoldFlow}
                    withMarginTop={areHotelSegments || areFlightSegments}
                    previousBookingRate={newCarRentals?.previousBookingRate}>
                    {isCarPaymentRequired && (
                      <CreditCardBlock
                        creditCard={carRentalDetails?.creditCard}
                        onAddNewCreditCard={openAddCreditCardModal('car')}
                        onEdit={onBillingModalOpen}
                        type={'car'}
                        billingError={carRentalCardCheck.billingError}>
                        {carRentalDetails?.creditCard && (
                          <PaymentCreditCard
                            creditCard={carRentalDetails?.creditCard}
                            mainValidator={validator}
                            type={'car'}
                            onEdit={onBillingModalOpen}
                            isCvvRequired={
                              !carRentalDetails?.creditCard.isSiteCard &&
                              carRentalDetails.billing?.metadata.isCvvRequired
                            }
                            isCreditCardChangeable={isCreditCardChangeable.carRental}
                          />
                        )}
                      </CreditCardBlock>
                    )}
                  </CarRentals>

                  <Rail
                    segments={mappedRail}
                    segmentState={newRail?.segmentState}
                    previousBookingRate={newRail?.previousBookingRate}
                    tripCostSummary={tripCost}
                    withMarginTop={areHotelSegments || areFlightSegments || areCarRentalSegments}
                    railTripCost={newRail?.tripCost}
                    isRailAccreditationEnabled={isRailAccreditationEnabled}>
                    <CreditCardBlock
                      creditCard={railDetails?.creditCard}
                      onAddNewCreditCard={openAddCreditCardModal('rail')}
                      billingError={railCardCheck.billingError}
                      isBilledToCompanyAccount={isBilledToCompanyAccount}>
                      <OnAccountCreditCardInfo
                        backgroundColor={'secondary'}></OnAccountCreditCardInfo>
                    </CreditCardBlock>
                  </Rail>
                </Form>
              </LazyContent>
            </Swap>
          </>
        </Content>
      </Screen.Container>

      <Screen.Footer as={'footer'}>{footerSlot}</Screen.Footer>
    </Screen>
  )
})
