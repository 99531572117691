import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { Button } from '@etta/ui/button'
import { useUnusedTicketOptContext } from '@etta/modules/unused-ticket-opt'
import type { LayoutProps } from '../types'
import { CostWithCostWithCtaButtonsLayout } from '../cost-with-cta-buttons-layout'
import {
  Container,
  RightBlock,
  Main,
  Label,
  Cost,
  Extras,
  CostContainer,
} from './cost-with-cta-mobile-styled'
import { CostSkeleton } from './cost-skeleton'

export const CostWithCtaMobile = observer(function CostWithCtaMobile({
  rate,
  label,
  usedCost,
  buttonText,
  extraText,
  onSubmit,
  isDisabled,
  holdButtonText,
  isHoldButtonVisible,
  isHoldFlow,
  onHoldTrip,
  isCostDisplayed,
  'data-tracking-id': dataTrackingId,
}: LayoutProps) {
  const { unusedTicketOptStore } = useUnusedTicketOptContext()

  const isUnusedTicketOptLoading = unusedTicketOptStore.isLoading
  const isTwoButtons = Boolean(isHoldButtonVisible && !isHoldFlow)

  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const handleSubmit = async () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.disabled = true
      await onSubmit()
      submitButtonRef.current.disabled = false
    }
  }

  return (
    <Container>
      {extraText && <Extras>{extraText}</Extras>}
      <Main isColumn={isTwoButtons}>
        {rate && isCostDisplayed && (
          <CostContainer isTwoButtons={isTwoButtons}>
            <Label isTwoButtons={isTwoButtons} data-tracking-id="total-estimated-cost-label">
              {label}
            </Label>
            {isUnusedTicketOptLoading ? (
              <CostSkeleton />
            ) : (
              <Cost data-tracking-id="total-estimated-cost-text">{usedCost}</Cost>
            )}
          </CostContainer>
        )}
        <RightBlock isTwoButtons={isTwoButtons}>
          <CostWithCostWithCtaButtonsLayout
            isHoldButtonVisible={isHoldButtonVisible}
            isHoldFlow={isHoldFlow}
            buttonSlot={
              <Button
                ref={submitButtonRef}
                fullWidth={isTwoButtons}
                variant="solid"
                minWidth={14}
                onClick={handleSubmit}
                disabled={isUnusedTicketOptLoading || isDisabled}
                data-tracking-id={dataTrackingId}>
                {buttonText}
              </Button>
            }
            holdButtonSlot={
              <Button
                fullWidth={isTwoButtons}
                variant={isHoldFlow ? 'solid' : 'outline'}
                minWidth={14}
                onClick={onHoldTrip}
                disabled={isUnusedTicketOptLoading || isDisabled}>
                {holdButtonText}
              </Button>
            }
          />
        </RightBlock>
      </Main>
    </Container>
  )
})
