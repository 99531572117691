import { v4 as uuid } from 'uuid'
import { dateFormat, getDateWithoutTimezoneOffset } from '@fiji/utils/dates'
import { Service } from '@etta/di'
import type { HotelRecentSearch } from '../../../core/value-objects/get-hotel-searches.value-object'

const formatString = "iii', 'MMM dd"

@Service()
export class HotelRecentSearchDataService {
  get(searchParams: HotelRecentSearch['hotelSearchParams']) {
    const checkInDate = new Date(searchParams.checkIn)
    const checkInDateWithoutTimezone = getDateWithoutTimezoneOffset(checkInDate)
    const checkOutDate = new Date(searchParams.checkOut)
    const checkOutDateWithoutTimezone = getDateWithoutTimezoneOffset(checkOutDate)
    const location = {
      placeId: uuid(),
      name: searchParams.location.name,
      latitude: searchParams.location.geocode.lat,
      longitude: searchParams.location.geocode.long,
    }

    const bottomLine = [
      dateFormat(checkInDateWithoutTimezone, formatString),
      dateFormat(checkOutDateWithoutTimezone, formatString),
    ].join(' - ')

    return {
      bottomLine,
      checkInDate: checkInDateWithoutTimezone,
      checkOutDate: checkOutDateWithoutTimezone,
      location,
    }
  }
}
