import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container } from './hotel-service-fee-styled'

type Props = {
  serviceFeeLabel?: string
}

export function HotelServiceFee({ serviceFeeLabel }: Props) {
  if (!serviceFeeLabel) {
    return null
  }

  return (
    <Container data-tracking-id="hotel-service-fee">
      <Block marginRight={5}>
        <Icon name="serviceFeePWA" color="error" size={24} />
      </Block>
      <Block paddingTop={2}>
        <Text variant="subHeadMedium" color="bodyText">
          {serviceFeeLabel}
        </Text>
      </Block>
    </Container>
  )
}
