import styled, { css } from 'styled-components'
import { Block } from '@etta/ui/block'

export const Content = styled(Block)<{ isMobile?: boolean }>`
  background: ${(p) => p.theme.colors.white};

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-top: 16px;
          margin-bottom: 16px;
          border-radius: 10px;
        `
      : css`
          max-width: 576px;
          margin-left: auto;
          margin-right: auto;
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        `}
`

export const DefaultContent = styled(Block)<{ isMobile?: boolean }>`
  background: ${(p) => p.theme.colors.white};

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-top: 16px;
          margin-bottom: 16px;
          border-radius: 10px;
        `
      : css`
          max-width: 320px;
          margin-left: auto;
          margin-right: auto;
          border-radius: 14px;
        `}
`

export const ColorContainer = styled(Block)<{ isMobile?: boolean }>`
  background: ${(p) => p.theme.colors.background};
  ${({ isMobile }) =>
    isMobile &&
    css`
      max-width: 576px;
      margin: auto;
    `}
`
