import { defaultLoggerTransport } from '@fiji/utils/safe-logger'
import { LOG_LEVEL, logLevel } from '@fiji/utils/safe-logger/get-log-level'
import { rollbar } from '@fiji/rollbar'

export const transport = {
  log: (...args: any[]) => {
    defaultLoggerTransport?.log(...args)
  },
  warn: (...args: any[]) => {
    defaultLoggerTransport?.warn(...args)
  },
  error: (...args: any[]) => {
    defaultLoggerTransport?.error(...args)
    if (logLevel === LOG_LEVEL.ERROR) {
      rollbar.error(...args)
    }
  },
}
