import { useTranslation } from 'react-i18next'
import type { PreferenceContent } from '@fiji/types'
import { Icon } from '@etta/ui/icon'
import { Chevron } from '../../traveler-info-styled'
import { getPreferenceBoxData } from '../get-preference-box-data'
import type { AvailablePreferencesToSelect } from '../../types'
import type { PreferencesType } from '../types'
import {
  PreferenceIcons,
  AvailableIcons,
  UnavailablePreferences,
} from './selected-preferences-styled'

type Props = {
  preferences?: PreferenceContent
  type: PreferencesType
  onClick: () => void
  availableToSelect?: AvailablePreferencesToSelect
}

export function SelectedPreferences({ onClick, type, preferences, availableToSelect }: Props) {
  const { t } = useTranslation()
  // FLIGHT_PREFENRECE_TODO: all the code is commented here as we cannot implement flight notifications yet
  // All places that need to be uncommented mentioned as FLIGHT_PREFENRECE_TODO throughout whole project
  // so you can easily find these places
  const {
    loyaltyStatus,
    specialRequestStatus,
    // notificationStatus,
    mealStatus,
    areAllPreferencesDisabled,
    eTicketStatus,
    railCardStatus,
  } = getPreferenceBoxData({ preference: preferences, availability: availableToSelect || null })

  const isAir = type === 'flight'
  const isRail = type === 'rail'

  return (
    <PreferenceIcons onClick={onClick} disabled={areAllPreferencesDisabled}>
      <AvailableIcons>
        {/* {isAir && <Icon {...notificationStatus} />} */}
        {!isRail && <Icon {...loyaltyStatus} />}
        {isAir && <Icon {...mealStatus} />}
        {!isRail && <Icon {...specialRequestStatus} />}
        {isRail && <Icon {...railCardStatus} />}
        {isRail && <Icon {...eTicketStatus} />}
      </AvailableIcons>
      {!areAllPreferencesDisabled && <Chevron name="expandMorePWA" />}
      {areAllPreferencesDisabled && (
        <UnavailablePreferences>
          {t('TravelerInfo.UnavailableAllPreferences_' + type)}
        </UnavailablePreferences>
      )}
    </PreferenceIcons>
  )
}
