import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { Button as EttaButton } from '@etta/ui/button'

export const Wrapper = styled.div`
  padding: 16px 24px 24px;
`

export const PolicyBlock = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.bodyText1};
  ${footnoteMedium};
`

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Button = styled(EttaButton)`
  max-height: 44px;
`

export const DueTodayCostContainer = styled.div`
  margin-top: 10px;
  margin-left: -5px;
`
