import type { TripCost, CarServiceTripCost, Maybe } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import type { ResultType } from '../types'

type CostType = TripCost | TripCost[] | CarServiceTripCost[] | null | Maybe<TripCost>[]

type Props = {
  segmentType: SegmentType
  payNow?: CostType
  payLater?: CostType
}

function getPriceByType(type: SegmentType, cost?: CostType) {
  switch (type) {
    case SegmentType.CarService:
      return { carServicesCost: cost as CarServiceTripCost[] }
    case SegmentType.Hotel:
      return { cost: cost as TripCost }
    case SegmentType.Flight:
      return { flightCost: cost as TripCost[] }
    case SegmentType.CarRental:
      return { cost: cost as TripCost }
  }
}

export function getSegmentPrice({ payNow, payLater, segmentType }: Props): ResultType | null {
  if (payNow) {
    return {
      type: 'PayNow',
      ...getPriceByType(segmentType, payNow),
    }
  }
  if (payLater) {
    return {
      type: 'PayLater',
      ...getPriceByType(segmentType, payLater),
    }
  }

  return null
}
