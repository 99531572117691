import type { ReactNode } from 'react'
import { ModalDetails } from '@etta/components/modal-details'
import { CheckoutPageModalHeader } from './checkout-page-modal-header'
import { CheckoutPageModalBody } from './checkout-page-modal-body'
import { CheckoutPageModalFooter } from './checkout-page-modal-footer'

type Props = {
  children: ReactNode
  isVisible: boolean
  onClose: () => void
  'data-tracking-id'?: string
}

function CheckoutPageModal({
  children,
  isVisible,
  onClose,
  'data-tracking-id': dataTrackingId,
}: Props) {
  return (
    <ModalDetails
      isVisible={isVisible}
      horizontalDimension="content-760"
      onClose={onClose}
      data-tracking-id={dataTrackingId}
      isAdaptivePosition>
      {children}
    </ModalDetails>
  )
}

const CheckoutPageModalNamespace = Object.assign(CheckoutPageModal, {
  Header: CheckoutPageModalHeader,
  Body: CheckoutPageModalBody,
  Footer: CheckoutPageModalFooter,
})

export { CheckoutPageModalNamespace as CheckoutPageModal }
