import { SegmentType } from '@etta/core/enums'
import { SegmentCardLayout } from './layout'
import type { LayoutProps } from './types'

const segmentTypeMap: Record<string, string> = {
  [SegmentType.Flight]: 'flight',
  [SegmentType.Train]: 'train',
  [SegmentType.CarRental]: 'car-rental',
  [SegmentType.Hotel]: 'hotel',
  [SegmentType.CarService]: 'car-service',
  [SegmentType.RideHail]: 'ride-hail',
}

export function SegmentCard(props: LayoutProps) {
  return <SegmentCardLayout {...props} segmentType={segmentTypeMap[props.segmentType]} />
}
