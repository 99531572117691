import { Domain } from '@fiji/graphql/types'
import type { TripCostSummaryValueObject } from '@etta/modules/review-trip/core'
import { TripMoneyMapper } from './trip-money.mapper'
import type { CartCost } from './types'

export class TripCostMapper {
  static toTripCost(cartCost: CartCost): TripCostSummaryValueObject {
    return {
      total: TripMoneyMapper.toRateValueObject(cartCost.estimatedTotal),
      payNow: this.toPayNow(cartCost.prepaidCost),
      payLater: this.toPayLater(cartCost.reservedCost),
    }
  }

  static toPayNow({
    intentCosts,
    subTotal,
  }: CartCost['prepaidCost']): TripCostSummaryValueObject['payNow'] {
    const carRentalTotalPrice = intentCosts.find((cost) => cost.domain === Domain.CarRental)

    const hotelTotalPrice = intentCosts.find((cost) => cost.domain === Domain.Hotel)

    const airTotalPrices = intentCosts.filter((cost) => cost.domain === Domain.Air)

    return {
      subTotal: TripMoneyMapper.toRateValueObject(subTotal),
      ...(carRentalTotalPrice && {
        car: {
          total: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.totalCost),
          base: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.baseCost),
          feesAndTaxes: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.taxes),
        },
      }),
      ...(hotelTotalPrice && {
        hotel: {
          total: TripMoneyMapper.toRateValueObject(hotelTotalPrice.totalCost),
          base: TripMoneyMapper.toRateValueObject(hotelTotalPrice.baseCost),
          feesAndTaxes: TripMoneyMapper.toRateValueObject(hotelTotalPrice.taxes),
        },
      }),
      ...(airTotalPrices.length && {
        flight: airTotalPrices.map((air) => ({
          base: TripMoneyMapper.toRateValueObject(air.baseCost),
          feesAndTaxes: TripMoneyMapper.toRateValueObject(air.taxes),
          passengerNumber: air.passengerCount,
          serviceFee: TripMoneyMapper.toRateValueObject(air.serviceFee),
          total: TripMoneyMapper.toRateValueObject(air.totalCost),
          transactionFee: TripMoneyMapper.toRateValueObject(air.transactionFee),
        })),
      }),
    }
  }

  static toPayLater({
    intentCosts,
    subTotal,
  }: CartCost['reservedCost']): TripCostSummaryValueObject['payLater'] {
    const carRentalTotalPrice = intentCosts.find((cost) => cost.domain === Domain.CarRental)

    const hotelTotalPrice = intentCosts.find((cost) => cost.domain === Domain.Hotel)

    return {
      subTotal: TripMoneyMapper.toRateValueObject(subTotal),
      ...(carRentalTotalPrice && {
        car: {
          total: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.totalCost),
          base: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.baseCost),
          feesAndTaxes: TripMoneyMapper.toRateValueObject(carRentalTotalPrice.taxes),
        },
      }),
      ...(hotelTotalPrice && {
        hotel: {
          total: TripMoneyMapper.toRateValueObject(hotelTotalPrice.totalCost),
          base: TripMoneyMapper.toRateValueObject(hotelTotalPrice.baseCost),
          feesAndTaxes: TripMoneyMapper.toRateValueObject(hotelTotalPrice.taxes),
        },
      }),
    }
  }
}
