import styled, { css } from 'styled-components'
import { UnstyledButton, buttonSmall, subHeadStrong } from '@fiji/style'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll'

export const Container = styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 8px;
  min-height: 50px;
  flex-wrap: nowrap;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 1;

  border-bottom: 1px solid transparent;
  background-color: transparent;
  ${createHeaderScrollAnimation(
    'car-rental-type-filter-bar-header',
    css`
       {
        99% {
          background-color: transparent;
        }
        100% {
          background-color: ${(props) => props.theme.colors.white};
          border-bottom-color: ${(props) => props.theme.colors.borderLight};
        }
      }
    `,
  )}

  &:after {
    content: '';
    display: block;
    right: 15px;
    min-width: 15px;
    height: 1px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Title = styled.p`
  margin: 0;
  ${subHeadStrong};
`

export const FilterButton = styled(UnstyledButton)<{ isActive: boolean }>`
  margin: 0 3px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  ${buttonSmall};
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.mainText};
      color: ${theme.colors.mainText2};
      border-color: ${theme.colors.mainText};
    `}
`
