import styled, { css } from 'styled-components'
import { Badge } from '../badge'

export const CancellationDescription = styled.div<{ isWrapped?: boolean }>`
  ${(p) => {
    if (!p.isWrapped) {
      return ''
    }
    return css`
      background-color: ${(p) => p.theme.colors.background};
      border-radius: 8px;
      margin-top: 8px;
      padding: 9px 12px 9px 13px;
    `
  }};
`

export const CancellationBadge = styled(Badge)`
  align-items: flex-start;
  margin: 0;

  > :first-child {
    margin-right: 4px;
  }
`
