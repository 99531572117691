import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { DesktopNavigation } from '@etta/modules/navigation'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { DesktopFooter } from '@etta/components/desktop-footer/desktop-footer'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { TravelSupport } from '@etta/modules/travel-support/ui/travel-support'
import { generateHref } from '@etta/screens/support-page/layout/generate-href'
import { SupportContactGetHelpNow } from '../../components/support-contact-get-help-now/support-contact-get-help-now'
import { TechnicalSupportBlock } from './technical-support-block'
import { default as Illustration } from './assets/support-illustration.svg'

import {
  PageBlockWrapper,
  TravelSupportContainer,
  PageContainer,
  ContactsWrapper,
  ContactsContent,
  ContactsText,
  TitleText,
} from './support-page-desktop-styled'

const i18Base = 'TripPlanner.Support.MainPage'

export const SupportPageDesktop = observer(function SupportPageDesktop() {
  const { t } = useTranslation()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const {
    desktop: desktopSupport,
    technicalSupport,
  } = displayConfigurationStore.displayConfiguration.support
  const { email: supportContact } = desktopSupport
  const { isEnabled, text } = technicalSupport
  const isShowTechnicalBlock = Boolean(isEnabled && text)
  const { href, hrefText, target } = generateHref(supportContact) || {}

  return (
    <ScreenDesktop>
      <ScreenDesktop.Header as={'header'}>
        <DesktopNavigation />
      </ScreenDesktop.Header>
      <PageContainer as={'main'}>
        <ContactsWrapper>
          <ContactsContent>
            <ContactsText>
              <TitleText as={'h1'}>{t(`${i18Base}.DesktopTitle`)}</TitleText>
              <Text variant="subHeadMedium" color="bodyText">
                {t(`${i18Base}.Description`)}{' '}
                {href && (
                  <Link target={target} href={href} size="medium">
                    {hrefText}
                  </Link>
                )}
              </Text>
            </ContactsText>
            <Illustration />
          </ContactsContent>
        </ContactsWrapper>

        <PageBlockWrapper>
          {isShowTechnicalBlock && <TechnicalSupportBlock content={text || ''} />}
        </PageBlockWrapper>

        <TravelSupportContainer>
          <TravelSupport />
        </TravelSupportContainer>
        <SupportContactGetHelpNow />
        {/* add FAQBlock after requests are ready */}
        {/* <FAQBlock faqs={FAQS} /> */}
      </PageContainer>
      <DesktopFooter />
    </ScreenDesktop>
  )
})
