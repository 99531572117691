import { useContext, useEffect, useMemo } from 'react'
import { TabsContext } from '../tabs-context'

type Args = {
  tabBasis?: number
}
export function useTabHeader({ tabBasis }: Args) {
  const context = useContext(TabsContext)
  const totalTabsCount = context.totalTabsCount
  const currentTabIndex = context.currentTabIndex
  useEffect(() => {
    context.changeTabBasis(tabBasis)
  }, [context, tabBasis])

  const width = useMemo(() => {
    if (tabBasis) {
      return `${tabBasis}px`
    }

    const percentage = Math.floor(10000 / totalTabsCount) / 100
    return `${percentage}%`
  }, [tabBasis, totalTabsCount])

  return {
    width,
    currentTabIndex,
  }
}
