import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { ReservationNumber } from '../../../reservation-number'
import { SectionBlock } from '../../../section-block'
import { PaymentCard } from '../../../payment-card'
import { FlightRow } from '../../flight-row'
import type { LayoutProps } from '../../types'

const i18nBase = 'PurchaseReservationSuccess.'

export function FlightBlockMobile({
  creditCard,
  rate,
  rows,
  cardDetails,
  mainCost,
  secondCost,
  confirmationNumbersData,
  isTripLoading,
}: LayoutProps) {
  const { t } = useTranslation()

  const paymentText = creditCard ? t(i18nBase + 'Payment') : t(i18nBase + 'PaymentDueAtFlight')

  const paymentLabel = t(i18nBase + 'PaymentLabel', {
    card: cardDetails,
    amount: [mainCost, secondCost].filter(Boolean).join(', '),
  })

  if (rows.length === 0) {
    return null
  }

  return (
    <SectionBlock
      paymentText={paymentText}
      paymentLabel={paymentLabel}
      title={t(i18nBase + 'FlightsTitle')}
      cardSlot={<PaymentCard creditCard={creditCard} />}
      rate={rate}>
      {rows.map(({ directions, reservationNumber, legId }) => {
        return (
          <Block key={legId}>
            <ReservationNumber number={reservationNumber} />
            {directions.map(
              (
                { cabinClass, departDate, destination, origin, airlineName, segmentId },
                directionIndex,
              ) => {
                const confirmationData = confirmationNumbersData.find(({ id }) => id === segmentId)

                return (
                  <FlightRow
                    key={directionIndex}
                    origin={origin}
                    destination={destination}
                    departDate={departDate}
                    cabinClass={cabinClass}
                    airlineName={airlineName}
                    confirmationData={confirmationData}
                    isTripLoading={isTripLoading}
                  />
                )
              },
            )}
          </Block>
        )
      })}
    </SectionBlock>
  )
}
