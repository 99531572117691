import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { ServiceCategory } from '@fiji/graphql/types'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useDeleteCarRentalTitle } from './use-delete-car-rental-title'
import { useDeleteFlightTitle } from './use-delete-flight-title'
import { useDeleteHotelTitle } from './use-delete-hotel-title'
import { useDeleteRailTitle } from './use-delete-rail-title'

type Props = {
  serviceCategory: Exclude<ServiceCategory, ServiceCategory.RideHail | ServiceCategory.CarService>
  onRemoveSegment: () => Promise<void>
  onCloseAfterward?: () => void
}

export function useDeleteSegments({ serviceCategory, onRemoveSegment, onCloseAfterward }: Props) {
  const { tripActions } = useReviewTripContext()
  const viewStateParams = useViewState()
  const onChangeViewState = viewStateParams.onChangeViewState

  const segmentTitle = {
    [ServiceCategory.RentalCar]: useDeleteCarRentalTitle(viewStateParams.viewState),
    [ServiceCategory.Flight]: useDeleteFlightTitle(viewStateParams.viewState),
    [ServiceCategory.Hotel]: useDeleteHotelTitle(viewStateParams.viewState),
    [ServiceCategory.Train]: useDeleteRailTitle(viewStateParams.viewState),
  }

  const handleDeleteSegment = async () => {
    try {
      onChangeViewState('saving')
      await onRemoveSegment()
      await tripActions.updateTrip()
      onChangeViewState('saved')
      await delayForSuccess()
      onChangeViewState('hidden')
      onCloseAfterward?.()
    } catch {
      onChangeViewState('error')
    }
  }

  return {
    viewStateParams: {
      ...viewStateParams,
      title: segmentTitle[serviceCategory],
    },
    handleDeleteSegment,
  }
}
