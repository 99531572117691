import { Spinner } from '@etta/ui/spinner'
import { default as NoHotelIcon } from '../no-hotel-preview-icon.svg'
import { MainImg, MainImgBg, MainImgLoading } from './main-image-styled'
import { useMainImage } from './use-main-image'

type Props = {
  onClick: (e: React.MouseEvent<HTMLImageElement>) => void
  src?: string
}

export function MainImage({ onClick, src }: Props) {
  const {
    isImageLoaded,
    hasImageError,
    isReadyForRender,
    updateImageLoaded,
    updateImageError,
  } = useMainImage()

  const isError = hasImageError || !src

  return (
    <>
      <MainImg
        onClick={onClick}
        src={src}
        onLoad={updateImageLoaded}
        onError={updateImageError}
        isLoaded={isReadyForRender}
      />
      {!isImageLoaded && !isError && (
        <MainImgLoading>
          <Spinner type="spinout" />
        </MainImgLoading>
      )}
      {isError && (
        <MainImgBg>
          <NoHotelIcon />
        </MainImgBg>
      )}
    </>
  )
}
