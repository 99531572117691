import styled, { css } from 'styled-components'

type Props = {
  width?: number
  height?: number
  isClickable?: boolean
}
export const SafetyCheckTitleContainer = styled.div<Props>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`
