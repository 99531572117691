import styled from 'styled-components'
import { THUMB_HEIGHT, THUMB_MARGIN } from '../../lightbox/constants'

export const HotelButton = styled.button<{ isLoaded?: boolean }>`
  width: 100%;
  height: ${THUMB_HEIGHT}px;
  min-height: ${THUMB_HEIGHT}px;
  margin: ${THUMB_MARGIN}px 0;
  padding: 0;
  background: none;
  border: none;
  ${(p) => !p.isLoaded && 'display: none'};
`

export const Thumbnail = styled.img<{ isActive: boolean | undefined }>`
  width: 100%;
  height: 100%;
  object-fit: fill;
  border: 2px solid ${(p) => (p.isActive ? p.theme.colors.white : 'transparent')};
  transition: all 0.2s;
`

export const ThumbnailImageBg = styled.div<{ isActive?: boolean }>`
  background: ${(props) => props.theme.colors.bodyText};
  color: ${(props) => props.theme.colors.borderDark};
  border: 2px solid ${(p) => (p.isActive ? p.theme.colors.white : 'transparent')};
  position: relative;
  width: 100%;
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 36px;
    height: auto;
  }
`
