import * as R from 'ramda'
import type { SearchFlightLegSubSegment, FlightAmenity } from '@fiji/graphql/types'

export function getGeneralAmenities(segments: SearchFlightLegSubSegment[]): FlightAmenity[] {
  const amenitiesList = segments.reduce((prev: FlightAmenity[], curr) => {
    if (!curr.amenities) {
      return prev
    }

    return [...prev, ...curr.amenities]
  }, [])

  return R.uniqBy(R.path(['name']), amenitiesList)
}
