import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { InfoModalHeaderMobile } from './mobile'
import { InfoModalHeaderDesktop } from './desktop'

export function InfoModalHeaderLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<InfoModalHeaderMobile {...props} />}
      desktopSlot={<InfoModalHeaderDesktop {...props} />}
    />
  )
}
