import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import { Content, IconWrapper } from '../train-modes-styled'
import type { Props } from '../type'

const i18nBase = 'RailDetails'
export function ChangeDuration({ segment }: Props) {
  const { t } = useTranslation()
  const getDuration = useRailDuration()

  const changeDurationText = getDuration(segment.changeDuration)

  const stationName = segment.stations?.arrival?.stationName

  if (!stationName) {
    return null
  }

  return (
    <>
      <IconWrapper>
        <Icon name="changePWA" />
      </IconWrapper>
      <Content>
        <Text variant="footnoteStrong" color="bodyText">
          {t(`${i18nBase}.ChangeAtLabel`)}
          <br />
          {segment.stations?.arrival?.stationName}
        </Text>
        <Text variant="footnoteStrong" color="bodyText">
          {changeDurationText}
        </Text>
      </Content>
    </>
  )
}
