import { Result } from 'fnscript'
import { Service } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type { EttaMapEntity } from '../../core/etta-map.entity'
import { EttaAppleMap } from './etta-apple-map'

const MAP_URL = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'

@Service()
export class AppleMapService {
  public createMap(map: mapkit.Map): EttaMapEntity {
    return new EttaAppleMap(map)
  }

  public loadAppleMap(token: string): PromisedResult<{ success: boolean }, unknown> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = MAP_URL
      script.async = true
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        mapkit.init({
          authorizationCallback: (done) => {
            done(token)
          },
          language: 'en',
        })

        resolve(Result.Ok({ success: true }))
      })
      script.addEventListener('error', () => reject(Result.Err(new Error('Failed to load script'))))
    })
  }
}
