import type { Maybe, Result } from 'fnscript'
import { Just, Nothing, Error as NestedError, Ok, Err } from 'fnscript'
import { Service } from '@etta/di'
import { dateToIso, isBeforeDate, isSameDay } from '@fiji/utils/dates'
// eslint-disable-next-line import/no-restricted-paths
import type { SubmitNewHotelSearchInput } from '@fiji/graphql/types'
import type { SubmitHotelSearchInput } from '../../core/value-objects/submit-hotel-search-input.value-object'
import { isDate, isCoordinate, isEmptyString } from './validators'
import type { Subset } from './validators'

@Service()
export class HotelMapService {
  getHotel(input: SubmitHotelSearchInput): Result<SubmitNewHotelSearchInput, Maybe<NestedError>> {
    const submitSearch = {
      searchParams: {
        checkIn: input.checkInDate ? dateToIso(input.checkInDate) : undefined,
        checkOut: input.checkOutDate ? dateToIso(input.checkOutDate) : undefined,
        searchRadius: input.distance,
        location: {
          name: input.location?.name,
          geocode: {
            lat: input.location?.latitude,
            long: input.location?.longitude,
          },
        },
      },
    }

    if (this.checkIsSubmitNewHotelSearchFullyFilled(input, submitSearch)) {
      return Ok(submitSearch)
    }
    return Err(this.validateSubmitNewHotelSearchArgs(input))
  }

  private checkIsSubmitNewHotelSearchFullyFilled(
    input: SubmitHotelSearchInput,
    submitSearch: Subset<SubmitNewHotelSearchInput>,
  ): submitSearch is SubmitNewHotelSearchInput {
    if (this.validateSubmitNewHotelSearchArgs(input).isValue() && !submitSearch) {
      return false
    }
    return true
  }

  private validateSubmitNewHotelSearchArgs(params: SubmitHotelSearchInput): Maybe<NestedError> {
    const errors: NestedError[] = []

    if (!isDate(params.checkInDate)) {
      const error = NestedError.new('checkInDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (!isDate(params.checkOutDate)) {
      const error = NestedError.new('checkOutDate is missing or its type is not `Date`')
      errors.push(error)
    }

    if (
      params.checkInDate &&
      params.checkOutDate &&
      !isBeforeDate(params.checkInDate, params.checkOutDate) &&
      !isSameDay(params.checkInDate, params.checkOutDate)
    ) {
      const error = NestedError.new('params.checkInDate should be <= params.checkOutDate')
      errors.push(error)
    }

    if (!isCoordinate(params.location?.latitude)) {
      const error = NestedError.new('location.latitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (!isCoordinate(params.location?.longitude)) {
      const error = NestedError.new('location.longitude is missing or it has a wrong value')
      errors.push(error)
    }

    if (isEmptyString(params.location?.name)) {
      const error = NestedError.new('location.name is missing or it has a wrong value')
      errors.push(error)
    }

    if (typeof params.distance !== 'number') {
      const error = NestedError.new('filter.distance is missing or it has a wrong value')
      errors.push(error)
    }

    if (errors.length > 0) {
      const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

      return Just(
        NestedError.new(
          `failed to validate submitNewHotelSearch input params;${validationError.getMessage()}`,
        ),
      )
    }

    return Nothing()
  }
}
