import i18n from 'i18next'
import type { CarRentalSegment, FlightSegment, HotelSegment, RailSegment } from '../../../types'

const i18nBase = 'PaymentSummary'

export function useSegmentsContainerLabel() {
  function getFlightLabel({
    departureAirportCode,
    arrivalAirportCode,
    cabinClass,
    departureDateTime,
  }: FlightSegment) {
    const formattedArrivalAirportCode = cabinClass
      ? `${arrivalAirportCode} (${cabinClass})`
      : arrivalAirportCode
    const formattedDepartureDateTime = departureDateTime ? ` • ${departureDateTime}` : ''

    return {
      departureAirport: departureAirportCode,
      arrivalAirport: formattedArrivalAirportCode,
      departureDateTime: formattedDepartureDateTime,
    }
  }

  function getHotelLabel({ name, dateRange }: HotelSegment) {
    return {
      hotelLabel: `${name} • ${dateRange}`,
    }
  }

  function getCarRentalLabel({ pickUpTime, dropOffTime, name, vendorName }: CarRentalSegment) {
    const puckUpVendorName = i18n.t(`${i18nBase}.PickUpVendorName`, { vendorName })
    const dropOffVendorName = i18n.t(`${i18nBase}.DropOffVendorName`, { vendorName })

    const pickUpLabel = [name, puckUpVendorName, pickUpTime].filter(Boolean).join(' • ')
    const dropOffLabel = [dropOffVendorName, dropOffTime].filter(Boolean).join(' • ')

    return {
      pickUpLabel,
      dropOffLabel,
    }
  }

  function getRailLabel({
    departureStationName,
    arrivalStationName,
    departureDateTime,
  }: RailSegment) {
    const departureToArrival = i18n.t(`${i18nBase}.DepartureToArrival`, {
      departure: departureStationName,
      arrival: arrivalStationName,
    })
    const formattedDepartureDateTime = departureDateTime ? ` • ${departureDateTime}` : ''

    return {
      departureToArrival,
      departureDateTime: formattedDepartureDateTime,
    }
  }

  return {
    getFlightLabel,
    getHotelLabel,
    getCarRentalLabel,
    getRailLabel,
  }
}
