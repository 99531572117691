import { Inject, Action } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'
import { TravelSupportService } from '../services/travel-support.service'

@Action()
export class TravelSupportActions {
  constructor(
    @Inject() private historyService: HistoryService,
    @Inject() private travelSupportService: TravelSupportService,
  ) {}

  handleNavigateToMain() {
    this.historyService.push(ROUTES.support.main)
  }

  handleLoadSupportArticle(articleId: string) {
    this.travelSupportService.getDocument360SupportArticleById(articleId)
  }
}
