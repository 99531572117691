import { useCallback } from 'react'
import { IconButton } from '@etta/ui/icon-button'
import { useMobilityViewClose } from '@fiji/hooks/mobility'
import { Container } from './uber-info-header-styled'

type Props = {
  isCloseModalOnlyOnExit?: boolean
  onClose?: () => void
}

export function UberInfoHeader({ isCloseModalOnlyOnExit, onClose }: Props) {
  const { handleClose } = useMobilityViewClose()

  const handleCloseUberInfo = useCallback(() => {
    if (!isCloseModalOnlyOnExit) {
      handleClose()
      return
    }

    if (onClose) {
      onClose()
    }
  }, [handleClose, isCloseModalOnlyOnExit, onClose])
  return (
    <Container>
      <IconButton icon="closePWA" size="medium" color="mainText" onClick={handleCloseUberInfo} />
    </Container>
  )
}
