import type { Maybe } from 'fnscript'
import { Just, Nothing } from 'fnscript'
import type { CalculateTripEmissionsResultRail } from '@fiji/graphql/types'
import type { TrainSegmentEntity, TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions/segment-type-check.actions'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { RailEmissions } from './types'

export function getRailEmissionsSummary(
  segments: TripSegmentValueObject[],
  rawRailEmissions?: CalculateTripEmissionsResultRail[] | null,
): {
  railEmissions: RailEmissions[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  const railEmissions: RailEmissions[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    if (typeCheck.isTrainSegment(segment)) {
      const railEmission = rawRailEmissions?.find((rail) => rail.customRef === segment?.legId)

      tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, railEmission?.tonnesOfEmissions)
      averageTonnesOfEmissionsSummary = sum(
        averageTonnesOfEmissionsSummary,
        railEmission?.averageRouteEmissionTonnes,
      )

      const {
        plasticBagEquivalenceAmount,
        treeSeedlingEquivalenceAmount,
        smartphoneChargeEquivalenceAmount,
      } = getEquivalencesCount(railEmission?.equivalences)

      treeSeedlingsEquivalenceTotalCount = sum(
        treeSeedlingsEquivalenceTotalCount,
        treeSeedlingEquivalenceAmount,
      )

      plasticBagsEquivalenceTotalCount = sum(
        plasticBagsEquivalenceTotalCount,
        plasticBagEquivalenceAmount,
      )

      smartphoneChargeEquivalenceTotalCount = sum(
        smartphoneChargeEquivalenceTotalCount,
        smartphoneChargeEquivalenceAmount,
      )

      const originStationName = getDepartureStationName(segment)
      const destinationStationName = getArrivalStationName(segment)

      railEmissions.push({
        originStationCode: segment?.departureStationCode,
        originStationName: originStationName.withDefault(''),
        destinationStationName: destinationStationName.withDefault(''),
        destinationStationCode: segment?.arrivalStationCode,
        emissionsKg: tonnesToKg(railEmission?.tonnesOfEmissions || 0),
      })
    }
  })

  return {
    railEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}

function getDepartureStationName(railSegment: TrainSegmentEntity): Maybe<string> {
  const railList = railSegment.segments
  if (railList.length === 0) {
    return Nothing()
  }

  const firstRailOption = railList[0]

  return Just(firstRailOption.departure.stationName)
}
function getArrivalStationName(railSegment: TrainSegmentEntity): Maybe<string> {
  const railList = railSegment.segments
  if (railList.length === 0) {
    return Nothing()
  }

  const railLastSegmentIndex = railList.length - 1

  return Just(railList[railLastSegmentIndex].arrival.stationName)
}
