import { useTranslation } from 'react-i18next'
import { NoModifySegment } from '@etta/components/no-modify-segment/no-modify-segment'
import { SegmentType } from '@etta/core/enums'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { SegmentCard, useSegmentCard } from '../segment-card'
import { SectionDate } from '../date-segments-styled'
import type { CardInformationElement } from '../segment-card/types'
import { useTrainSegment } from './use-train-segment'
import type { LayoutProps } from './types'

const i18nBase = 'PostBooking.TripDetails'

export function TrainSegment({
  segment,
  isUpcoming,
  isOnHold,
  currentDate,
  onClick,
  onCancel,
  isTripInProgress,
}: LayoutProps) {
  const { t } = useTranslation()
  const { isPast, dateToShow } = useSegmentCard({ segment, isOnHold, currentDate })
  const {
    title,
    typeTitle,
    ariaLabel,
    trainDateLabel,
    trainStatus,
    departureTime,
    headerDate,
    dataTexts,
    buttons,
    handleShare,
    isAllowedTrainModalOpen,
    handleNotAllowedTrainModalClose,
    carrierImage,
    infoText,
    isOpenReturn,
    openReturnProperties,
    handleTicketRestrictionsClick,
  } = useTrainSegment({
    segment,
    dateToShow,
    isUpcoming,
    isOnHold,
    onCancel,
    isTripInProgress,
  })

  const cardInformation: CardInformationElement[] = [infoText]
  if (handleTicketRestrictionsClick) {
    cardInformation.push(
      <Block
        isFlexBlock
        onClick={(e) => {
          e.stopPropagation()
          handleTicketRestrictionsClick()
        }}>
        {t(`${i18nBase}.TicketRestrictions`)}
        <Icon size={20} name="infoPWA" />
      </Block>,
    )
  }
  return (
    <>
      <SegmentCard
        isPast={isPast}
        isUpcoming={isUpcoming}
        ariaLabel={ariaLabel}
        titleSlot={title}
        segmentIcon="railPWA"
        dateSlot={<SectionDate isDisabled>{dateToShow}</SectionDate>}
        onClick={onClick}
        segmentType={SegmentType.Train}
        departureTime={departureTime}
        dataTexts={dataTexts}
        buttons={buttons}
        onShare={handleShare}
        label={trainDateLabel}
        segmentStatus={trainStatus}
        headerDate={headerDate}
        cardTitle={title}
        cardSubTitle={trainDateLabel}
        segmentPreview={carrierImage}
        cardInfo={cardInformation}
        isOpenReturn={isOpenReturn}
        openReturnProperties={openReturnProperties}
        isOnHold={isOnHold}
      />
      <NoModifySegment
        isOpen={isAllowedTrainModalOpen}
        onClose={handleNotAllowedTrainModalClose}
        type={typeTitle}
      />
    </>
  )
}
