import type { CalculateTripEmissionsResultRail } from '@fiji/graphql/types'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { RailEmissions } from './types'

export function getRailEmissionsSummary(
  segments: TrainPostBookingValueObject[],
  rawRailEmissions?: CalculateTripEmissionsResultRail[] | null,
): {
  railEmissions: RailEmissions[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const railEmissions: RailEmissions[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    const railEmission = rawRailEmissions?.find((rail) => rail.customRef === segment.id)

    tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, railEmission?.tonnesOfEmissions)
    averageTonnesOfEmissionsSummary = sum(
      averageTonnesOfEmissionsSummary,
      railEmission?.averageRouteEmissionTonnes,
    )

    const {
      plasticBagEquivalenceAmount,
      treeSeedlingEquivalenceAmount,
      smartphoneChargeEquivalenceAmount,
    } = getEquivalencesCount(railEmission?.equivalences)

    treeSeedlingsEquivalenceTotalCount = sum(
      treeSeedlingsEquivalenceTotalCount,
      treeSeedlingEquivalenceAmount,
    )

    plasticBagsEquivalenceTotalCount = sum(
      plasticBagsEquivalenceTotalCount,
      plasticBagEquivalenceAmount,
    )

    smartphoneChargeEquivalenceTotalCount = sum(
      smartphoneChargeEquivalenceTotalCount,
      smartphoneChargeEquivalenceAmount,
    )

    railEmissions.push({
      originStationCode: segment?.departureStationCode,
      destinationStationCode: segment?.arrivalStationCode,
      originStationName: segment.segments
        ? segment.segments[segment.segments.length - 1].stations?.departure?.stationName
        : '',
      destinationStationName: segment.segments
        ? segment.segments[segment.segments.length - 1].stations?.arrival?.stationName
        : '',
      emissionsKg: tonnesToKg(railEmission?.tonnesOfEmissions || 0),
    })
  })

  return {
    railEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
