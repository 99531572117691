import styled from 'styled-components'
import { bodyLarge } from '@fiji/style'

type SelectedDateStatusProps = {
  isSelected?: boolean
  isStickToRight?: boolean
  isRange?: boolean
  isMobile: boolean
  startDate?: Date | null
  endDate?: Date | null
}

export const SelectedDateStatus = styled.div<SelectedDateStatusProps>`
  ${({ isMobile, isRange }) =>
    isMobile
      ? `
      border: none;
      background: none;
      justify-content: center;
      width: ${isRange ? '50%' : '100%'};
      `
      : `
        font-weight: bold;
        padding-left: 10px;
        width: 145px;
      `}

  height: 100%;
  align-items: center;
  display: flex;

  color: ${(props) =>
    props.isSelected || props.startDate || props.endDate ? props.theme.colors.primary : '#656B72'};

  &::after {
    content: '';
    height: ${({ isMobile }) => (isMobile ? '6px' : '3px')};
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${(props) =>
      props.isRange && props.isSelected ? props.theme.colors.primary : 'transparent'};
  }

  position: absolute;
  top: 0;
  ${({ isStickToRight }) => (isStickToRight ? `right: 0; ` : `left: 0;`)};
  bottom: 0;

  ${bodyLarge}
`

export const Separator = styled.div`
  width: 1px;
  background: ${(props) => props.theme.colors.borderLight};
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1;
`

export const ButtonsContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
  padding-top: ${({ isMobile }) => (isMobile ? '21%' : '7%')};
  position: relative;
  display: flex;
`

export const ControlContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  ${(props) => !props.isMobile && 'padding: 0 10px;'}
  width: ${({ isMobile }) => (isMobile ? '100%' : 'unset')};
  height: ${({ isMobile }) => (isMobile ? 'auto' : '56px')};
  display: flex;
  flex-direction: column;
`

export const IconWrapperAbsolute = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  position: absolute;
`
export const StartIconSmall = styled.div`
  min-width: 15px;
  height: 15px;
`
