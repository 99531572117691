import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Search } from '@etta/ui/search'
import { DatePickerModal } from '@etta/ui/date-picker-modal-temp'
import { SwipingTimePicker } from '@etta/components/sliders'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { PickUpPickerProps } from '../../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const PickUpPicker = observer(function PickUpPicker({
  pickUpTime,
  pickUpDate,
  dropOffDate,
  pickUpDateToggle,
  pickUpTimeToggle,
  onDatesSelect,
  onPickUpTimeChange,
  validationErrors,
}: PickUpPickerProps) {
  const { t } = useTranslation()

  return (
    <Search.Group percentageWidth={23.5} isTwoInputs>
      <Search.Element
        wrapperAriaLabel={t(i18Base + '.Input.DateSelectedAriaLabel', {
          label: t(i18Base + '.Input.PickUpDate'),
          value: pickUpDate ? dateFormat(pickUpDate, 'MMM dd') : undefined,
        })}
        labelTitle={t(`${i18Base}.Input.PickUpDate`)}
        value={pickUpDate ? dateFormat(pickUpDate, 'MMM dd') : undefined}
        onClick={pickUpDateToggle.handleOpen}
        placeholder={t(`${i18Base}.Input.Select`)}
        errorMessage={validationErrors.pickUpDate}
        shouldErrorMessageRightBorderBeFlat
        contentSlot={
          <DatePickerModal
            isEndDateSame
            isOpen={pickUpDateToggle.isOpen}
            onSelect={onDatesSelect}
            onClose={pickUpDateToggle.handleClose}
            startTitle={t(`${i18Base}.Input.PickUp`)}
            endTitle={t(`${i18Base}.Input.DropOff`)}
            isRange
            date={pickUpDate}
            dateEnd={dropOffDate}
            minDate={new Date()}
          />
        }
      />
      <Search.Element
        wrapperAriaLabel={t(i18Base + '.Input.TimeSelectedAriaLabel', {
          label: t(i18Base + '.Input.PickUpTime'),
          value: pickUpTime ? getFormattedTime(pickUpTime, 'p').toLowerCase() : undefined,
        })}
        isTextAlignRight
        labelTitle={t(`${i18Base}.Input.PickUpTime`)}
        value={pickUpTime ? getFormattedTime(pickUpTime, 'p').toLowerCase() : undefined}
        onClick={pickUpTimeToggle.handleOpen}
        shouldErrorMessageLeftBorderBeFlat
        errorMessage={validationErrors.pickUpTime}
        contentSlot={
          <SwipingTimePicker
            value={pickUpTime}
            label={t(`${i18Base}.Input.PickUpTime`)}
            isVisible={pickUpTimeToggle.isOpen}
            onClose={pickUpTimeToggle.handleClose}
            onApply={onPickUpTimeChange}
          />
        }
      />
    </Search.Group>
  )
})
