import { Trans, useTranslation } from 'react-i18next'
import { TrainDeliveryOptionCode } from '@fiji/enums'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Link } from '@etta/ui/link'
import type { TrainDelivery as TrainDeliveryType } from '../../../types'
import { CollectHeaderWrapper, WarningWrapper, Wrapper } from './train-delivery-styled'

type Props = {
  onDeliveryGuideOpen: () => void
  delivery?: TrainDeliveryType
}

const i18nBase = 'PostBooking.TripDetails'

export function TrainDelivery({ delivery, onDeliveryGuideOpen }: Props) {
  const { t } = useTranslation()
  if (!delivery || !delivery?.code) {
    return null
  }

  const {
    code,
    countStations,
    collectFromStation,
    travelerEmail,
    isCollectFromStationAvailable,
  } = delivery

  const isEticket = code === TrainDeliveryOptionCode.Eticket
  const isCollect = code === TrainDeliveryOptionCode.Collect

  if (isEticket) {
    return (
      <Wrapper>
        <Block paddingHorizontal={16} paddingVertical={16}>
          <Text variant="footnoteMedium" color="bodyText">
            {t(`${i18nBase}.ETicketReminder`)}
          </Text>
          &nbsp;
          <Text variant="footnoteMedium" color="primary">
            {travelerEmail}
          </Text>
        </Block>
      </Wrapper>
    )
  }

  if (isCollect) {
    return (
      <Wrapper>
        <Block paddingHorizontal={16} paddingVertical={16}>
          <CollectHeaderWrapper>
            <Icon name="kioskPWA" color="mainText" size="medium" />
            <Text variant="subHeadStrong" color="mainText1">
              {t(`${i18nBase}.CollectAt`)}
            </Text>
          </CollectHeaderWrapper>
          <Block>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.CollectFromStation`)}
            </Text>
            &nbsp;
            <Button paddings={false} variant="link" size="small" onClick={onDeliveryGuideOpen}>
              {t(`${i18nBase}.FindOutHow`)}
            </Button>
          </Block>
        </Block>
        {!isCollectFromStationAvailable && (
          <WarningWrapper>
            <Text variant="footnoteMedium" color="mainText1">
              <Trans
                i18nKey={`${i18nBase}.CantCollectFromStation`}
                components={{
                  a: (
                    <Link
                      href="https://support.thetrainline.com/en/support/solutions/articles/78000000556"
                      target="_blank"
                      size="small"
                    />
                  ),
                }}
                values={{
                  station: collectFromStation,
                  countStations,
                }}
              />
            </Text>
          </WarningWrapper>
        )}
      </Wrapper>
    )
  }

  return null
}
