import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import type { RideHailAirportPickupFlightValueObject } from '@etta/modules/ride-hail/core'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { dateFormat } from '@fiji/utils/dates'
import { MobilitySearchResultErrorType } from '@etta/components/mobility-search-result-dialog'

type Props = {
  onClose: () => void
  onSubmit: () => void
}
export function useAddFlightModal({ onClose, onSubmit }: Props) {
  const [flightNumber, setFlightNumber] = useState('')
  const [date, setDate] = useState<Date | null>(null)
  const [isValidating, setIsValidating] = useState(false)
  const dateString = date ? dateFormat(date, 'MMMM dd, y') : ''
  const {
    searchRideHailStore,
    searchRideHailActions,
    rideHailAirportPickupActions,
  } = useRideHailSearchContext()

  const { searchRideHailError, isLoading, searchRideHailForm } = searchRideHailStore

  const isFlightDetailValid =
    new RegExp('^([A-z0-9]{2}[A-z]?)( ?-?)([0-9]{1,4})$').test(flightNumber) && !!date

  const isArrivalFlightNotFound =
    searchRideHailError === MobilitySearchResultErrorType.ArrivalFlightNotFound
  const shouldShowLoadingState = isValidating || isLoading
  const shouldShowCheckmark = isFlightDetailValid && !isArrivalFlightNotFound
  const shouldShowWarning = (!isFlightDetailValid || isArrivalFlightNotFound) && flightNumber !== ''

  const shouldDisableButton =
    shouldShowLoadingState || !isFlightDetailValid || isArrivalFlightNotFound

  const handleSubmit = () => {
    if (!date) {
      return
    }
    rideHailAirportPickupActions.handleAddCustomFlight(date, flightNumber)
    onSubmit()
    setDate(null)
    setFlightNumber('')
  }

  const onDiscard = useCallback(() => {
    setDate(null)
    setFlightNumber('')
    onClose()
  }, [onClose])

  useEffect(() => {
    if (!!date && !!flightNumber) {
      setIsValidating(true)
    }
  }, [date, flightNumber])

  useDebounce(
    () => {
      setIsValidating(false)
    },
    1500,
    [date, flightNumber, isValidating],
  )

  useDebounce(
    () => {
      if (isFlightDetailValid) {
        const airportCode = searchRideHailForm.pickUpPlace?.airportCode ?? ''
        const flightDetail: RideHailAirportPickupFlightValueObject = {
          airlineName: '',
          flightDesignator: flightNumber,
          arrivalAirportCode: airportCode,
          arrivalDate: dateFormat(date, `yyyy-MM-dd`),
          imageUrl: '',
        }

        searchRideHailActions.loadSearchRideHailWithFlight(flightDetail)
      }
    },
    1500,
    [isFlightDetailValid, flightNumber, date],
  )

  return {
    flightNumber,
    handleFlightNumberChange: setFlightNumber,
    date,
    handleDateChange: setDate,
    dateString,
    onDiscard,
    shouldShowLoadingState,
    shouldShowCheckmark,
    shouldShowWarning,
    shouldDisableButton,
    handleSubmit,
  }
}
