import {
  ContentLayout,
  DueTodayLayout,
  FooterWrapperLayout,
  TextLayout,
  TripTypeLayout,
} from './layout'
import type {
  TextProps,
  DueTodayProps,
  TripTypeProps,
  ContentProps,
  FooterWrapperProps,
} from './types'

export function Text(props: TextProps) {
  return <TextLayout {...props} />
}

export function DueToday(props: DueTodayProps) {
  return <DueTodayLayout {...props} />
}

export function TripType(props: TripTypeProps) {
  return <TripTypeLayout {...props} />
}

export function Content(props: ContentProps) {
  return <ContentLayout {...props} />
}

export function FooterWrapper(props: FooterWrapperProps) {
  return <FooterWrapperLayout {...props} />
}
