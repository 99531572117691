import { RailSortBy } from '@fiji/graphql/types'

export const SORTING_OPTION_TRANSLATION_MAPPER = {
  [RailSortBy.Preference]: 'SortBy.Preference',
  [RailSortBy.Cost]: 'SortBy.Cost',
  [RailSortBy.Departure]: 'SortBy.Departure',
  [RailSortBy.Arrival]: 'SortBy.Arrival',
  [RailSortBy.Duration]: 'SortBy.Duration',
  [RailSortBy.Policy]: 'SortBy.Policy',
} as const
