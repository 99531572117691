import { useEffect } from 'react'
import type { OOPGenericData } from '@fiji/types'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy'

export function useOopReasons(descriptions: OOPGenericData[]) {
  const { outOfPolicyActions, outOfPolicyStore } = useOutOfPolicyContext()
  useEffect(() => {
    const segmentTypes =
      descriptions && descriptions.length > 0
        ? Array.from(new Set(descriptions.map((el) => el.type)))
        : []
    outOfPolicyActions.getOopConfiguration(segmentTypes)
  }, [descriptions, outOfPolicyActions])

  return { reasons: outOfPolicyStore.oopConfiguration }
}
