import { css } from 'styled-components'
import { MODAL_TRANSITION_TIMEOUT, MODAL_ANIMATION } from './constants'

export const MODAL_TRANSITION_SLIDE_UP = 'MODAL_SLIDE_UP'

export const slideUp = css`
  transition: transform ${MODAL_TRANSITION_TIMEOUT}ms ${MODAL_ANIMATION};

  transform: translateY(100%);

  &.${MODAL_TRANSITION_SLIDE_UP}-appear, &.${MODAL_TRANSITION_SLIDE_UP}-enter {
    transform: translateY(100%);
  }

  &.${MODAL_TRANSITION_SLIDE_UP}-appear-done,
    &.${MODAL_TRANSITION_SLIDE_UP}-enter-done,
    &.${MODAL_TRANSITION_SLIDE_UP}-appear-active,
    &.${MODAL_TRANSITION_SLIDE_UP}-enter-active {
    transform: translateY(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_UP}-exit-done, &.${MODAL_TRANSITION_SLIDE_UP}-exit {
    transform: translateY(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_UP}-exit-active {
    transform: translateY(100%);
  }
`
