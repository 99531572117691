import styled from 'styled-components'

export const Container = styled.div`
  padding: 22px 30px 10px 24px;
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 12px 0 24px;
`

export const FooterUnit = styled.div`
  margin: 0 30px 0 24px;
  padding: 20px 0 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`
