import { Inject } from 'typedi'
import { Store } from '@etta/di'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'
import { CreatePasswordStore } from '@etta/modules/user/interface/stores/create-password.store'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { LocationState, Step } from '../types'
import { BusinessContactStore } from './business-contact.store'
import { EmployeeInfoStore } from './employee-info.store'
import { PaymentInfoStore } from './payment-info.store'
import { TravelPreferencesStore } from './travel-preferences.store'

@Store()
export class UserActivationPaginationStore {
  private _isLoading = false

  constructor(
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly businessContactStore: BusinessContactStore,
    @Inject() private readonly employeeInfoStore: EmployeeInfoStore,
    @Inject() private readonly paymentInfoStore: PaymentInfoStore,
    @Inject() private readonly createPasswordStore: CreatePasswordStore,
    @Inject() private readonly travelPreferencesStore: TravelPreferencesStore,
    @Inject() private readonly historyStore: HistoryStore<LocationState>,
  ) {}

  setIsLoading(value: boolean) {
    this._isLoading = value
  }

  get steps(): Map<Step, boolean> {
    return new Map([
      ['create-password', this.shouldRenderedCreatePassword],
      ['user-information', true],
      [
        'company-information',
        Boolean(
          this.businessContactStore.shouldRenderBusinessContact ||
            this.businessContactStore.shouldRenderedAdditionalBusinessContactFields,
        ),
      ],
      [
        'employee-information',
        Boolean(
          this.employeeInfoStore.shouldRenderedEmployeeInfoFields ||
            this.paymentInfoStore.shouldRenderedPaymentInfoFields ||
            this.employeeInfoStore.shouldRenderedAdditionalEmployeeInformationFields,
        ),
      ],
      [
        'travel-preferences',
        Boolean(
          this.travelPreferencesStore.shouldRenderedTravelPreferencesFields ||
            this.travelPreferencesStore.shouldRenderedAdditionalTravelPreferencesFields,
        ),
      ],
    ])
  }

  get totalSteps() {
    return Array.from(this.steps).filter(([key, value]) => value && key !== 'create-password')
      .length
  }

  get currentStepType(): Step {
    return this.historyStore.state?.currentStepType ?? 'user-information'
  }

  get currentStepIndex() {
    return (
      Array.from(this.steps)
        .filter(([key, value]) => value && key !== 'create-password')
        .findIndex(([step]) => step === this.currentStepType) + 1
    )
  }

  get shouldRenderedCreatePassword() {
    return (
      Boolean(this.userStore.user?.profile?.forceNewPassword) &&
      !this.createPasswordStore.isSSOOnlySite &&
      !this.userStore.isDelegatedOrImpersonated
    )
  }

  get isLoading() {
    return this._isLoading
  }

  get lastStep() {
    const keys = Array.from(this.steps.keys())
    return keys.length > 0 ? keys[keys.length - 1] : undefined
  }

  get isLastStep() {
    return this.currentStepIndex === this.totalSteps
  }
}
