import type { PropsWithChildren } from 'react'
import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import type { HorizontalDimension } from '@etta/ui/modal/types'

type Props = {
  isVisible: boolean
  onClose: () => void
  horizontalDimension?: HorizontalDimension
  isAdaptivePosition?: boolean
  withOverlay?: boolean
  backgroundColor?: LayoutBackgroundColor
  'data-tracking-id'?: string
}

export function ModalDetails({
  isVisible,
  onClose,
  children,
  horizontalDimension,
  isAdaptivePosition,
  backgroundColor,
  withOverlay,
  'data-tracking-id': dataTrackingId,
}: PropsWithChildren<Props>) {
  const type = useScreenType()
  const modalPosition = isAdaptivePosition && type !== ScreenType.Mobile ? 'right' : 'center'
  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      position={modalPosition}
      horizontalDimension={horizontalDimension}
      withOverlay={withOverlay}
      backgroundColor={backgroundColor}
      data-tracking-id={dataTrackingId}>
      {children}
    </Modal>
  )
}
