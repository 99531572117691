import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useScheme } from '@fiji/hooks/use-validation/use-scheme'
import { useValidation } from '@fiji/hooks/use-validation/use-validation'
import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field/types'
import { getYupScheme } from '@fiji/hooks/use-custom-fields/use-get-yup-scheme'
import { usePaymentSummaryContext } from '@etta/modules/booking'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'

type Props = {
  fields: CustomFieldCollectionItemExtension[]
  onSubmit(values: Record<string, string>): void
}

export function useForm({ fields, onSubmit }: Props) {
  const { t } = useTranslation()
  const { costAllocationStore } = useCostAllocationContext()
  const {
    topLevelCostSegments,
    submittedCostAllocations,
    submittedSplitCostAllocations,
  } = costAllocationStore
  const { paymentSummaryStore } = usePaymentSummaryContext()
  const { customFields: storedCustomFields } = paymentSummaryStore
  const storedCustomFieldsMap = useMemo(
    () =>
      storedCustomFields.reduce((map: Record<string, { value?: string | Date }>, field) => {
        map[field.customUuid] = {
          ...field,
        }

        return map
      }, {}),
    [storedCustomFields],
  )

  const scheme = useScheme(() => {
    const customFieldsScheme = fields.reduce<{ [K: string]: any }>(
      (acc, item) => ({
        ...acc,
        ...item.customFields.reduce<typeof acc>((customAcc, customField) => {
          const yupScheme = getYupScheme({
            customField: {
              ...customField,
              defaultValue:
                storedCustomFieldsMap[customField.customUuid]?.value || customField.defaultValue,
            },
          })

          customAcc[customField.customUuid] = yupScheme

          return customAcc
        }, {}),
      }),
      {},
    )

    const allocationScheme = topLevelCostSegments.reduce<{ [K: string]: any }>((acc, segment) => {
      acc[`${segment.id}_allocation`] = (segment.isRequired
        ? yup.string().required(t('AdditionalInfoForm.Errors.RequiredField'))
        : yup.string().optional()
      ).default(submittedCostAllocations.get(segment.id)?.allocationId || '')

      if (segment.isSplitCostAllocation) {
        acc[`${segment.id}_splitCostAllocation`] = (segment.isSplitCostRequired
          ? yup.string().required(t('AdditionalInfoForm.Errors.RequiredField'))
          : yup.string().optional()
        )
          .default(submittedSplitCostAllocations.get(segment.id)?.allocationId || '')
          .test(
            'are-allocation-unique',
            t('AdditionalInfoForm.Errors.SplitCostAllocationDuplication'),
            function (splitCostAllocationValue, context) {
              if (splitCostAllocationValue) {
                return context.parent[`${segment.id}_allocation`] !== splitCostAllocationValue
              }
              return true
            },
          )
      }
      return acc
    }, {})

    return {
      ...customFieldsScheme,
      ...allocationScheme,
    }
  }, [fields, topLevelCostSegments, storedCustomFieldsMap])

  const { values, errors, submit, reset, onFieldChange } = useValidation(scheme)

  const onSubmitHandler = async () => {
    const submitData = await submit()

    if (submitData.isValid) {
      return onSubmit(values)
    }
  }

  return {
    values,
    errors,
    reset,
    onFieldChange,
    onSubmitHandler,
  }
}
