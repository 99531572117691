import { useEffect, useRef } from 'react'

type UseFocusReturn = {
  opened: boolean
  shouldReturnFocus?: boolean
}

export function useFocusReturn({ opened, shouldReturnFocus = true }: UseFocusReturn) {
  const lastActiveElement = useRef<HTMLElement>()
  const returnFocus = () => {
    if (
      lastActiveElement.current &&
      'focus' in lastActiveElement.current &&
      typeof lastActiveElement.current.focus === 'function'
    ) {
      lastActiveElement.current?.focus({ preventScroll: true })
    }
  }

  useEffect(() => {
    let timeout = -1

    const clearFocusTimeout = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        window.clearTimeout(timeout)
      }
    }

    document.addEventListener('keydown', clearFocusTimeout)

    if (opened) {
      lastActiveElement.current = document.activeElement as HTMLElement
    } else if (shouldReturnFocus) {
      timeout = window.setTimeout(returnFocus, 10)
    }

    return () => {
      window.clearTimeout(timeout)
      document.removeEventListener('keydown', clearFocusTimeout)
    }
  }, [opened, shouldReturnFocus])

  return returnFocus
}
