import { css } from 'styled-components'
import { screenSize } from '@fiji/style/screen-sizes'

// how to convert line-height px/rem to relatives
// relative = line-height px value / font-size px value

// Header styles
export const largeTitle = css`
  font-weight: 700;
  font-size: 4.8rem;
  letter-spacing: -0.8px;
  line-height: 0.8541; //4.1rem

  @media (${screenSize.maxTablet}) {
    font-size: 3.4rem;
    line-height: 1.2058; //4.1rem
  }
`

export const largeTitle2 = css`
  font-weight: 700;
  font-size: 3.4rem;
  letter-spacing: -0.008em;
  line-height: 1.2058;
`

export const title1 = css`
  font-weight: 700;
  font-size: 2.8rem;
  letter-spacing: -0.5px;
  line-height: 1.0625; //3.4rem;

  @media (${screenSize.maxTablet}) {
    font-size: 2.8rem;
    line-height: 1.2142; //3.4rem
  }
`

export const title2 = css`
  font-weight: 800;
  font-size: 2.4rem;
  letter-spacing: -0.5px;
  line-height: 1.3333; //3.2rem;
`

export const title3 = css`
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: -0.4px;
  line-height: 1.25; //2.5rem;
`

export const title4 = css`
  font-weight: 500;
  font-size: 1.8rem;
`

export const headline = css`
  font-weight: 700;
  font-size: 1.7rem;
  letter-spacing: 0;
  line-height: 1.2941; //2.2rem;
`

// Subheader styles
export const subHeadMedium = css`
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: -0.12px;
  line-height: 1.3333; //2rem;
`

export const subHeadStrong = css`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: -0.12px;
  line-height: 1.3333; // 2rem;
`

export const captionMedium = css`
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 0.1px;
  line-height: 1.3333; //1.6rem;
`

export const captionStrongCaps = css`
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.1%;
  text-transform: uppercase;
  line-height: 1.3333; //1.6rem;
`

// Body styles
export const body = css`
  font-weight: 500;
  font-size: 1.7rem;
  letter-spacing: -0.35px;
  line-height: 1.5294; //2.6rem;
`

export const bodyValue = css`
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: -0.1px;
  line-height: 1.3571;
`

export const bodyLarge = css`
  font-weight: 700;
  font-size: 2.2rem;
  letter-spacing: 0;
  line-height: 1.2727; // 2.8rem;
`

export const calloutMedium = css`
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: -0.3px;
  line-height: 1.375; //2.2rem;
`

export const calloutStrong = css`
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.375; //2.2rem;
`

export const footnoteMedium = css`
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0;
  line-height: 1.4615; //1.9rem;
`

export const footnoteStrong = css`
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.05px;
  line-height: 1.4615; // 1.9rem;
`

export const captionStrong = css`
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.1px;
  line-height: 1.25; //1.5rem;
`

// Control styles
export const buttonLarge = css`
  font-weight: 700;
  font-size: 2.2rem;
  letter-spacing: -0.4px;
  line-height: 1.2727; //2.8rem;
`

export const buttonNormal = css`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.3333; //2rem;
`

export const buttonSmall = css`
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0;
  line-height: 1.3846; //1.8rem;
`

export const linkNormal = css`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.3333; // 2rem;;
`

export const linkSmall = css`
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.1px;
  line-height: 1.3846; //1.8rem;
`

export const linkCaption = css`
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 0.1px;
`

export const linkMedium = css`
  font-weight: 700;
  font-size: 1.7rem;
  letter-spacing: 0.1px;
`

export const tabs = css`
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0;
  line-height: 1.4615; //1.9rem;
`

// Custom font variants
export const regularText = css`
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -0.1px;
`

export const smallText = css`
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: -0.1px;
`

export const mediumText = css`
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: -0.1px;
`

export const largeText = css`
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
`

export const smallTextSemiRegularCaps = css`
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 0;
  text-transform: uppercase;
`

export const regularListItem = css`
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.12px;
`

export const timePickerMedium = css`
  font-weight: 500;
  font-size: 2.4rem;
  letter-spacing: -0.4px;
`
