import type { FlightCarrierData, FlightSafetyCheckHeadline, SafetyCheckFlight } from '@fiji/types'
import type {
  FareAttributesValueObject,
  OnTheFlyContentValueObject,
} from '@etta/modules/air-search/core/value-objects/fare-attributes.value-object'
import { getFlightSafetyCheckIconNames } from './get-flight-safety-checks-icon-names'

export function getFlightSafetyChecks(
  carrierNames: FlightCarrierData[],
  fareAttributes: FareAttributesValueObject[],
): SafetyCheckFlight[] {
  return carrierNames.map((carrier, index) => {
    const airline = [carrier.carrierName, carrier.flightNumber].join(' - ')
    const airlineLogo = carrier.carrierLogo
    const headLines = getFlightSafetyCheckHeadlines(fareAttributes, index)
    return {
      airline,
      airlineLogo,
      headLines,
    }
  })
}

function getFlightSafetyCheckHeadlines(
  fareAttributes: FareAttributesValueObject[],
  segmentIndex: number,
): FlightSafetyCheckHeadline[] {
  return fareAttributes.reduce(
    (
      currentCovidAttributesHeadlines: FlightSafetyCheckHeadline[],
      fare: FareAttributesValueObject,
    ) => {
      const segmentContentFeatures = fare.content.filter(
        (item) => item.segmentIndex === segmentIndex && item.location === 'FEATURES',
      )

      const covidAttributesHeadlines: FlightSafetyCheckHeadline[] = getUniqCovidAttributesHeadlines(
        segmentContentFeatures,
        currentCovidAttributesHeadlines,
      )

      return [...currentCovidAttributesHeadlines, ...covidAttributesHeadlines]
    },
    [],
  )
}

/*
  getUniqCovidAttributesHeadlines - filter headlines from all legs of one segment id, to get headlines and show them in expand view
*/
function getUniqCovidAttributesHeadlines(
  attributes: OnTheFlyContentValueObject[],
  currentAttributes: FlightSafetyCheckHeadline[],
): FlightSafetyCheckHeadline[] {
  return attributes.reduce(
    (acc: FlightSafetyCheckHeadline[], attribute: OnTheFlyContentValueObject) => {
      if (attribute.items.length === 0) {
        return [...acc]
      }

      const currentHeadlineTitle = attribute.items[0].headline || '-'
      if (currentAttributes.findIndex((item) => item.title === currentHeadlineTitle) !== -1) {
        return [...acc]
      }

      const uniqHeadlines: FlightSafetyCheckHeadline = {
        iconName: getFlightSafetyCheckIconNames(attribute.linkIconClass || ''),
        title: currentHeadlineTitle,
      }

      return [...acc, uniqHeadlines]
    },
    [],
  )
}
