import type { PermissionTranslation } from '../shared'
import { PermissionScreen } from '../shared'
import animation from './animation.json'
import { useLocationEnabledTranslation } from './use-location-enabled-translation'

type Props = {
  onSuccess: () => void
  nextTranslation: PermissionTranslation
}

export function LocationEnabled({ onSuccess, nextTranslation }: Props) {
  const translation = useLocationEnabledTranslation()

  return (
    <PermissionScreen
      animationSrc={animation}
      onSuccess={onSuccess}
      nextTranslation={nextTranslation}
      translation={translation}
    />
  )
}
