import { DataProvider } from '@etta/di'
import type {
  CreateMapKitTokenMutation,
  CreateMapKitTokenMutationVariables,
} from '@fiji/graphql/hooks'
import { CreateMapKitTokenDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class EttaMapDataProvider extends GraphqlDataProvider {
  createAppleMapToken() {
    return this.client.mutate<CreateMapKitTokenMutation, CreateMapKitTokenMutationVariables>({
      mutation: CreateMapKitTokenDocument,
    })
  }
}
