import { useTranslation } from 'react-i18next'
import {
  CompanyResoursesTitleDescription,
  CompanyInformationTitle,
  CompanyResoursesModalBody,
  CompanyResoursesModalContainer,
  CompanyResoursesTitle,
  CompanyResoursesLinkItem,
  CompanyResoursesLinksContainer,
} from './desktop-navigation-company-resources-styled'

type Props = {
  isModalOpen: boolean
  resoursesLinks?: { label: string; url: string }[]
  descriptionMessage?: string | null
}

const i18nBase = 'CompanyResourcesModal.'

export function DesktopNavigationCompanyResoursesModal({
  isModalOpen,
  resoursesLinks,
  descriptionMessage,
}: Props) {
  const { t } = useTranslation()

  if (!isModalOpen) {
    return null
  }

  return (
    <CompanyResoursesModalContainer>
      <CompanyResoursesModalBody>
        <CompanyInformationTitle variant="subHeadStrong">
          {t(i18nBase + 'CompanyInformationTitle')}
        </CompanyInformationTitle>
        <CompanyResoursesTitleDescription
          variant="subHeadMedium"
          dangerouslySetInnerHTML={{
            __html: descriptionMessage || t(i18nBase + 'CompanyResourcesTitleDescription'),
          }}
        />
        {resoursesLinks && !!resoursesLinks.length && (
          <>
            <CompanyResoursesTitle variant="subHeadStrong">
              {t(i18nBase + 'CompanyResourcesTitle')}
            </CompanyResoursesTitle>
            <CompanyResoursesLinksContainer>
              {resoursesLinks.map((link) => (
                <CompanyResoursesLinkItem href={link.url} size="small" target="_blank">
                  {link.label}
                </CompanyResoursesLinkItem>
              ))}
            </CompanyResoursesLinksContainer>
          </>
        )}
      </CompanyResoursesModalBody>
    </CompanyResoursesModalContainer>
  )
}
