import { CSSTransition } from 'react-transition-group'
import { FooterWrapper, FooterContainer } from '../../seat-footer-styles'
import { TRANSITION_NAME, TRANSITION_DURATION } from '../../blocks'
import type { LayoutProps } from '../../seat-footer.types'

export function SeatFooterDesktop({
  animateFooter,
  isDesktopFooterVisible,
  setAnimateFooter,
  containerRef,
  footerBlockSlot,
  desktopFooterHeight,
}: LayoutProps) {
  return (
    <FooterWrapper height={desktopFooterHeight} animate={animateFooter}>
      <CSSTransition
        appear={isDesktopFooterVisible}
        in={isDesktopFooterVisible}
        timeout={TRANSITION_DURATION}
        classNames={TRANSITION_NAME}
        onEntered={() => setAnimateFooter(false)}
        onExit={() => setAnimateFooter(true)}>
        <FooterContainer ref={containerRef}>{footerBlockSlot}</FooterContainer>
      </CSSTransition>
    </FooterWrapper>
  )
}
