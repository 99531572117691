import styled from 'styled-components'
import { MediaLayout } from '@etta/ui/media-layout'
import { Button } from '@etta/ui/button'

const SectionMobile = styled.div`
  padding: 24px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  &:last-child {
    border-bottom: none;
  }
`

const SectionDesktop = styled(SectionMobile)`
  margin-left: 40px;
  padding-left: 0px;
`

export const Section = (props: any) => {
  return (
    <MediaLayout
      mobileSlot={<SectionMobile {...props} />}
      desktopSlot={<SectionDesktop {...props} />}
    />
  )
}

export const ShowMoreButton = styled(Button)`
  margin-top: 14px;
`
