import { useTranslation } from 'react-i18next'
import type { PrivacyPolicyConfiguration } from '@fiji/graphql/types'
import { useLogOut } from '@fiji/hooks/use-log-out'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useUserContext } from '@etta/modules/user'
import { useNavigationContext } from '@etta/modules/navigation/interface/use-navigation-context'
import { LinksContainer, LogoutLink, ExternalLink } from '../../profile-styled'
import { UnusedTickets } from '../unused-tickets'
import { DarkThemeToggle } from '../dark-theme-toggle'

type Props = {
  privacyPolicy: PrivacyPolicyConfiguration
}

const i18nBase = 'Profile'

export function Links({ privacyPolicy }: Props) {
  const { t } = useTranslation()
  const { onLogout } = useLogOut()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { userStore } = useUserContext()
  const { ettaGoAction } = useNavigationContext()
  const { handleSetRcSessionCookieForAdminPanel } = ettaGoAction

  const hideUnusedTickets = displayConfigurationStore.displayConfiguration.hideUnusedTickets

  return (
    <LinksContainer>
      {userStore.isSelfServiceSite && (
        <ExternalLink
          onClick={handleSetRcSessionCookieForAdminPanel}
          data-tracking-id="admin-access-button">
          {t(`${i18nBase}.AdminAccess`)}
        </ExternalLink>
      )}
      {hideUnusedTickets ? null : <UnusedTickets />}
      <DarkThemeToggle />
      <ExternalLink
        href="https://www.deem.com/privacy#Terms-of-Use"
        target="_blank"
        data-tracking-id="terms-of-use-button">
        {t(`${i18nBase}.TermsOfUse`)}
      </ExternalLink>
      <ExternalLink
        href={privacyPolicy.url}
        target="_blank"
        data-tracking-id="privacy-policies-button">
        {privacyPolicy.label || t(`${i18nBase}.PrivacyPolicy`)}
      </ExternalLink>
      <LogoutLink to="" onClick={onLogout} data-tracking-id="sign-out-button">
        {t('Profile.SignOut')}
      </LogoutLink>
    </LinksContainer>
  )
}
