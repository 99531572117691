import type { DeviceClass } from '@etta/core/enums'
import type { FareTierEnum } from '../core/enums/fare-tier.enum'
import type { RailSegmentTravelClassEnum } from '../core/enums/rail-segment-travel-class.enum'

export type RailSearchInput = {
  after?: number
  changesFilters?: number
  departArriveRanges?: DepartArriveRangesInput[]
  destinationStationFilters?: string[]
  deviceClass?: DeviceClass
  direction?: RailJourneyDirectionEnum
  excludeOutOfPolicy?: boolean
  filterFareTiers?: FareTierEnum[]
  first?: number
  includeNearbyStations?: boolean
  isCustomTimeReplaced?: boolean
  isCustomTimeSearch?: boolean
  legPosition?: number // could change to string, valid values is 0-7 (type in GQL is string but it's not used yet)
  originStationFilters?: string[]
  searchId: string
  selectedFareTiers?: FareTierEnum[]
  selectedLegs?: LegSelectionInput[]
  selectedServiceClasses?: string[][]
  sortBy?: RailSortByEnum
  stopFilters?: string[]
  takeoffLandingRanges?: TakeoffLandingRangesInput[]
  trainCompanyFilters?: string[]
  unusedTicket?: boolean
  webfareSupported?: boolean
  page: number
}

export type RailEmissionsInput = {
  compare: Array<RailEmissionsBatchCompareInput>
  rails: Array<RailEmissionsBatchRailInput>
}

export type CreateRailSearchInput = {
  railLegSearch: RailLegSearchInput[]
  numberOfPassengers: number
  routeType: RailRouteTypeEnum
}

type RailLegSearchInput = {
  departureDate: string // date in format 2023-01-31 (y-MM-dd)
  serviceClass: string
  timeRangeBy: string
  destination?: string // string in IATA airport code format eg. SFO
  destinationLocation?: GeocodeInput
  destinationUniqueCode?: string
  endTimeRange?: string
  number: number
  origin?: string // string in IATA airport code format eg. SFO
  originLocation?: GeocodeInput
  originUniqueCode?: string
  preferredTime: string
  railCards?: RailCardsInput
  startTimeRange?: string
}

type GeocodeInput = {
  lat: number
  long: number
}

type RailCardsInput = {
  cards: RailCard[]
}

type RailCard = {
  code: string
  groupName: string
  name: string
  vendorCode: string
  vendorName: string
}

export type RailEmissionsBatchCompareInput = {
  destination: RailEmissionsLocationInput
  origin: RailEmissionsLocationInput
}

export type RailEmissionsBatchRailInput = {
  customRef?: string
  segments: Array<RailEmissionsBatchRailSegmentInput>
}

export type RailEmissionsBatchRailSegmentInput = {
  carrierCode?: string
  class: RailSegmentTravelClassEnum
  customRef?: string
  departureDate: string
  destinationStationCode: string
  originStationCode: string
}

export type RailEmissionsLocationInput = {
  stationCode?: string
}

export type DepartArriveRangesInput = {
  arrive?: TimeRangeInput
  depart?: TimeRangeInput
  legPosition?: number
}

export type TimeRangeInput = {
  end?: Date
  start?: Date
}

export enum RailJourneyDirectionEnum {
  Inward = 'inward',
  Outward = 'outward',
  // Unspecified = 'unspecified',
}

export type LegSelectionInput = {
  id: string
  position: number
}

export enum RailRouteTypeEnum {
  OpenReturn = 'OPEN_RETURN',
  Return = 'RETURN',
  Single = 'SINGLE',
  Unspecified = 'UNSPECIFIED',
}

export enum RailSortByEnum {
  Arrival = 'ARRIVAL',
  Cost = 'COST',
  Departure = 'DEPARTURE',
  Duration = 'DURATION',
  Policy = 'POLICY',
  Preference = 'PREFERENCE',
}

export type TakeoffLandingRangesInput = {
  landing?: TimeRangeInput
  legPosition?: number
  takeoff?: TimeRangeInput
}

export type RailSearchInputFilter = Pick<
  RailSearchInput,
  | 'stopFilters'
  | 'trainCompanyFilters'
  | 'takeoffLandingRanges'
  | 'filterFareTiers'
  | 'originStationFilters'
  | 'destinationStationFilters'
  | 'excludeOutOfPolicy'
>

export enum RailETicketTypeEnum {
  Pdf = 'PDF',
  AppleWallet = 'APPLE_WALLET',
  QrCode = 'QR_CODE',
  Unspecified = 'UNSPECIFIED',
}

export type RailETicketInput = {
  itineraryId: string
  type: RailETicketTypeEnum
}
