// eslint-disable-next-line import/no-restricted-paths
import { SegmentType } from '@fiji/graphql/types'
import type {
  HotelSegmentEntity,
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  TripSegmentValueObject,
  TrainSegmentEntity,
} from '../'

export const isFlightSegment = (
  segment?: TripSegmentValueObject,
): segment is FlightSegmentEntity => {
  return segment?.type === SegmentType.Flight
}

export const isRoundTripFlight = (segment?: FlightSegmentEntity) => {
  return Boolean(segment?.isRoundTripLeg)
}

export const isHotelSegment = (segment?: TripSegmentValueObject): segment is HotelSegmentEntity => {
  return segment?.type === SegmentType.Hotel
}

export const isCarSegment = (
  segment?: TripSegmentValueObject,
): segment is CarRentalSegmentEntity => {
  return segment?.type === SegmentType.CarRental
}

export const isRailSegment = (segment?: TripSegmentValueObject): segment is TrainSegmentEntity => {
  return segment?.type === SegmentType.Train
}
