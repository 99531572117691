import { Modal } from '@etta/ui/modal'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { SearchBrandsModalContent } from './search-brands-modal-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  brands: BrandFilter[]
  superBrands: SuperBrandFilter[]
  selectedBrands: string[]
  onChange: (selected: string[]) => void
}

export function SearchBrandsModal({ isOpen, onClose, ...props }: Props) {
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <SearchBrandsModalContent onClose={onClose} {...props} />
    </Modal>
  )
}
