import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  border-radius: 14px;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  background-color: ${(p) => p.theme.colors.white};
  overflow: hidden;
  z-index: 2;
  height: 76px;
`

export const DecoratedLine = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.plum};
`

export const ShadowCard = styled.div`
  position: relative;
  height: 20px;
  margin: 0 12px;
  border-radius: 14px;
  opacity: 50%;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  z-index: 1;
`
