import styled from 'styled-components'
import { Text as UIText } from '@etta/ui/text'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 324px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.mainText};
  border-radius: 14px;
  overflow: hidden;
`

export const Wrap = styled.div`
  width: 85%;
`

export const SpaceBetween = styled.div<{ hasAlignItemsCenter?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ hasAlignItemsCenter }) => hasAlignItemsCenter && 'align-items: center;'}
`

export const Text = styled(UIText)<{ hasBreakWord?: boolean; withoutLineHeight?: boolean }>`
  ${({ hasBreakWord }) => hasBreakWord && 'word-break: break-word;'}
  ${({ withoutLineHeight }) => withoutLineHeight && 'line-height: initial;'}
`

export const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
`
