import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type { GetOutOfThePolicyConfigurationQuery } from '@fiji/graphql/hooks'
import { SendOopCodesDocument, GetOutOfThePolicyConfigurationDocument } from '@fiji/graphql/hooks'
import type {
  GetOutOfThePolicyConfigurationQueryVariables,
  SendOopCodesMutation,
  SendOopCodesMutationVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class OOPDataProvider extends GraphqlDataProvider {
  getOOPConfiguration(
    variables: GetOutOfThePolicyConfigurationQueryVariables,
    forceUpdate?: boolean,
  ) {
    return this.client.query<
      GetOutOfThePolicyConfigurationQuery,
      GetOutOfThePolicyConfigurationQueryVariables
    >({
      query: GetOutOfThePolicyConfigurationDocument,
      variables,
      fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first',
    })
  }

  sendOopCodes(input: SendOopCodesMutationVariables) {
    return this.client.mutate<SendOopCodesMutation, SendOopCodesMutationVariables>({
      mutation: SendOopCodesDocument,
      variables: input,
    })
  }
}
