import { displayConfigurationSelector } from '@fiji/store/display-configuration'
import { useAppSelector } from '@fiji/store'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import { getInitialFilters } from './get-initial-filters'
import type { HotelSearchQueryType } from './types'

export function useHotelSearchQuery() {
  const { hotelSearchRadius } = useAppSelector(displayConfigurationSelector)

  const {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  } = useQueryParams<HotelSearchQueryType>(
    {
      // initial distance sets on hotel search page
      hotelFilters: getInitialFilters({
        initialDistance: hotelSearchRadius.default,
      }),
    },
    { caseStyle: 'kebab-case' },
  )
  return {
    queryParams,
    appendQueryParams,
    navigateTo,
    clearQueryParams,
  }
}
