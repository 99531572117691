import { useTranslation } from 'react-i18next'
import type { Rate, TripDetailsCostSummary } from '@fiji/graphql/types'
import type {
  FlightPostBookingValueObject,
  TrainPostBookingValueObject,
} from '@etta/modules/post-booking/core/value-objects'

import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Body, Content, ItineraryList, StartSlotContainer } from './cost-summary-styled'
import { ItineraryItem } from './itinerary-item'
import { TotalCost } from './total-cost'
import { FlightSection } from './flight-section'
import { TrainSection } from './train-section'

type Props = {
  isVisible: boolean
  onClose: () => void
  costSummaryData?: TripDetailsCostSummary
  startSlot: JSX.Element | null
  totalWithUnusedTicket?: Rate | null
  flightSegments: FlightPostBookingValueObject[]
  railSegments: TrainPostBookingValueObject[]
}

const i18nBase = 'PostBooking.TripDetails.CostSummary'

export function CostSummary({
  isVisible,
  onClose,
  startSlot,
  costSummaryData = {},
  totalWithUnusedTicket,
  flightSegments,
  railSegments,
}: Props) {
  const { t } = useTranslation()
  const { flight, hotel, carRental, totalCost, train, carService } = costSummaryData
  const headerTitle = t(`${i18nBase}.Title`)

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Header isMobile>
        <Header leftSlot={<Header.CloseButton onClick={onClose} />} withBorderBottom>
          <Header.Title title={headerTitle} align="left" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <Content>
            <StartSlotContainer>{startSlot}</StartSlotContainer>
            <ItineraryList>
              {flight && (
                <ItineraryItem
                  title={t(`${i18nBase}.Flight`)}
                  isPaid={flight.flightList[0].isPaid}
                  totalCost={flight.total}>
                  {flightSegments.map((flightSegment) => {
                    return (
                      <FlightSection
                        currentSegment={flightSegment}
                        flightSegments={flightSegments}
                      />
                    )
                  })}
                </ItineraryItem>
              )}
              {train && (
                <ItineraryItem
                  title={t(`${i18nBase}.Train`)}
                  isPaid={train.trainList[0]?.isPaid}
                  totalCost={train.fare?.total}>
                  <TrainSection segments={railSegments} train={train} />
                </ItineraryItem>
              )}
              {hotel && (
                <ItineraryItem
                  title={t(`${i18nBase}.Hotel`)}
                  isPaid={hotel.isPaid}
                  totalCost={hotel.total}>
                  <ItineraryItem.BillListItem price={hotel.cost}>
                    {t(`${i18nBase}.HotelName`, {
                      count: hotel.itemCount,
                    })}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItemTax price={hotel.tax} />
                </ItineraryItem>
              )}
              {carRental && (
                <ItineraryItem
                  title={t(`${i18nBase}.CarRental`)}
                  isPaid={carRental.isPaid}
                  totalCost={carRental.total}>
                  <ItineraryItem.BillListItem price={carRental.cost}>
                    {t(`${i18nBase}.CarRentalName`, {
                      count: carRental.itemCount,
                    })}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItemTax price={carRental.tax} />
                </ItineraryItem>
              )}
              {carService && (
                <ItineraryItem
                  title={t(`${i18nBase}.CarRental`)}
                  isPaid={carService.isPaid}
                  totalCost={carService.total}>
                  <ItineraryItem.BillListItem price={carService.cost}>
                    {t(`${i18nBase}.Base`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.gratuity}>
                    {t(`${i18nBase}.Gratuity`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.stopCharge}>
                    {t(`${i18nBase}.STC`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.accountDiscount}>
                    {t(`${i18nBase}.Discount`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.fuelSurcharge}>
                    {t(`${i18nBase}.FuelSurcharge`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.accountDiscount}>
                    {t(`${i18nBase}.Discount`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem
                    price={carService.carServiceCharges?.accountServiceCharge}>
                    {t(`${i18nBase}.AccountServiceCharge`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.carServiceCharges?.airPortFees}>
                    {t(`${i18nBase}.AirportCharge`)}
                  </ItineraryItem.BillListItem>
                  <ItineraryItem.BillListItem price={carService.tax}>
                    {t(`${i18nBase}.Taxes`)}
                  </ItineraryItem.BillListItem>
                </ItineraryItem>
              )}
            </ItineraryList>
          </Content>
          <TotalCost rate={totalWithUnusedTicket ?? totalCost} />
        </Body>
      </Modal.Body>
    </Modal>
  )
}
