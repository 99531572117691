import { InputButton } from '@etta/ui/input-button'
import { InputButtonSize } from '@etta/ui/input-button/types'
import type { Props } from '../types'

export function InputDesktop({
  isLabelExist,
  label,
  ariaLabel,
  isDisabled,
  'data-tracking-id': dataTrackingId,
  onClick,
}: Props) {
  return (
    <InputButton
      disabled={isDisabled}
      label={label}
      value={isLabelExist}
      size={InputButtonSize.Big}
      onClick={onClick}
      aria-label={ariaLabel}
      data-tracking-id={dataTrackingId}
    />
  )
}
