import { useCallback, useEffect, useMemo, useState } from 'react'
import type { useTogglePopup } from './use-toggle-popup'

type Args = {
  handleClose: ReturnType<typeof useTogglePopup>['handleClose']
  delay?: number
}

export function useAutoclosePopup({ handleClose, delay = 5000 }: Args) {
  const [popupNode, setPopupNode] = useState<HTMLElement | null>(null)
  const [toggleNode, setToggleNode] = useState<HTMLElement | null>(null)
  const [closeTimeout, setCloseTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const handlePointerLeave = useCallback(() => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }

    setCloseTimeout(setTimeout(handleClose, delay))
  }, [closeTimeout, setCloseTimeout, handleClose, delay])
  const handlePointerEnter = useCallback(() => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }
  }, [closeTimeout])

  useEffect(() => {
    if (popupNode) {
      popupNode.addEventListener('pointerleave', handlePointerLeave)
      popupNode.addEventListener('pointerenter', handlePointerEnter)
    }
    if (toggleNode) {
      toggleNode.addEventListener('pointerleave', handlePointerLeave)
      toggleNode.addEventListener('pointerenter', handlePointerEnter)
    }

    return () => {
      if (popupNode) {
        popupNode.removeEventListener('pointerleave', handlePointerLeave)
        popupNode.removeEventListener('pointerenter', handlePointerEnter)
      }
      if (toggleNode) {
        toggleNode.removeEventListener('pointerleave', handlePointerLeave)
        toggleNode.removeEventListener('pointerenter', handlePointerEnter)
      }
    }
  }, [handlePointerLeave, handlePointerEnter, popupNode, toggleNode])

  return useMemo(
    () => ({
      popupRef: setPopupNode,
      toggleRef: setToggleNode,
    }),
    [setPopupNode, setToggleNode],
  )
}
