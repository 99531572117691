import { useState, useMemo } from 'react'
import type { Hotel, CalculateHotelEmissionsBatchResultHotel } from '@fiji/graphql/hooks'
import { useCalculateHotelEmissionsBatchQuery } from '@fiji/graphql/hooks'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { logValidationErrorToRollbar, SKIP_VALIDATION_ERROR } from '../common'
import { mapToThrustCarbonHotel, mapNumberOfNights } from './map-to-thrust-carbon-hotel'
import type { OptionalGeocode } from './types'
import { hotelsEmissionsQueryExecuteValidation } from './hotel-emissions-query-execute-validation'

type HotelsEmissionsArgs = {
  hotels: Hotel[]
  geocode: OptionalGeocode
  checkInDate?: Date | null
  checkOutDate?: Date | null
}

export function useHotelsEmissions({
  hotels,
  geocode,
  checkInDate,
  checkOutDate,
}: HotelsEmissionsArgs) {
  const {
    featureFlags: { isSustainabilityEnabled },
  } = useFeatureFlags()

  const [existingHotelEmissions, setExistingHotelEmissions] = useState<
    CalculateHotelEmissionsBatchResultHotel[]
  >([])
  const [averageSustainabilityScore, setAverageSustainabilityScore] = useState(0)

  const newHotels = useMemo(
    () =>
      hotels.filter((hotel) => {
        if (!existingHotelEmissions.find((existingHotel) => existingHotel.customRef === hotel.id)) {
          return hotel
        }
      }),
    [hotels, existingHotelEmissions],
  )

  const tcHotels = useMemo(
    () =>
      newHotels.map((hotel) => {
        return mapToThrustCarbonHotel(hotel, checkInDate, checkOutDate)
      }),
    [newHotels, checkInDate, checkOutDate],
  )

  const maybeHotelsValidationError = hotelsEmissionsQueryExecuteValidation({
    hotels: tcHotels,
    checkInDate,
    checkOutDate,
    geocode,
  })

  const isSkipValidationError =
    !maybeHotelsValidationError.isNothing() &&
    maybeHotelsValidationError.getValue().text() === SKIP_VALIDATION_ERROR

  if (!isSkipValidationError && !maybeHotelsValidationError.isNothing()) {
    logValidationErrorToRollbar(maybeHotelsValidationError, {
      hotels: tcHotels,
      checkInDate,
      checkOutDate,
      geocode,
    })
  }

  const { loading } = useCalculateHotelEmissionsBatchQuery({
    variables: {
      input: {
        compare: [
          {
            nights: mapNumberOfNights(checkInDate, checkOutDate),
            location: {
              // We expect the values to always be present.
              // Any issues related to this query is tracked via Rollbar
              lat: geocode.lat as number,
              long: geocode.long as number,
            },
          },
        ],
        hotels: tcHotels,
      },
    },
    onCompleted: (data) => {
      if (data.calculateHotelEmissionsBatch.errors) {
        return
      }

      const result = [...existingHotelEmissions, ...data?.calculateHotelEmissionsBatch?.hotels!]

      if (data?.calculateHotelEmissionsBatch.averageSustainabilityScore) {
        setAverageSustainabilityScore(data?.calculateHotelEmissionsBatch.averageSustainabilityScore)
      }

      setExistingHotelEmissions(result)
    },
    skip:
      !isSustainabilityEnabled || isSkipValidationError || !maybeHotelsValidationError.isNothing(),
  })

  return {
    averageSustainabilityScore,
    existingHotelEmissions,
    loading,
  }
}
