import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { DateSectionMobile } from './mobile'
import { DateSectionDesktop } from './desktop'

export function DateSectionLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<DateSectionMobile {...props} />}
      desktopSlot={<DateSectionDesktop {...props} />}
    />
  )
}
