import { useTranslation } from 'react-i18next'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import { DocumentType } from '@etta/modules/booking/interface/checkout-info/documents/checkout-info-documents.store'
import { PassportExcerpt } from '../../passport-excerpt'
import { getIsExpiredPassport } from '../../get-is-expired-passport'
import type { Props } from '../types'
import {
  Container,
  Header,
  ErrorContainer,
  ErrorMessage,
  AddNewPassport,
  DataContent,
  ContentRadio,
  RightBlock,
  NumberBadge,
} from './passport-box-desktop-styled'

export function PassportBoxDesktop({
  passports,
  boxValue,
  updateBoxValue,
  areNoPassport,
  isError,
  onAddNewPassport,
  onOpenExistingPassport,
  handleTravelerDocumentOpen,
  knownTravelerNumbersSize,
  redressNumbersSize,
  isPassportRequired,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TravelerInfo.Passport.'
  const title = isPassportRequired ? t(i18Base + 'HeaderWithPassport') : t(i18Base + 'Header')

  return (
    <Container isError={isError}>
      <Header isError={isError}>{title}</Header>
      {isPassportRequired && (
        <>
          {!areNoPassport && (
            <>
              <DataContent>
                {passports?.map((passport) => (
                  <ContentRadio
                    isError={isError}
                    key={passport.id}
                    isChecked={boxValue === passport.id}
                    onChange={() => updateBoxValue(passport.id || null)}
                    isDisabled={getIsExpiredPassport(passport)}
                    data-tracking-id="passport-box-update-passport-radio">
                    <PassportExcerpt
                      withNumbersInOneLine
                      withDots
                      passport={passport}
                      onEdit={onOpenExistingPassport}
                    />
                  </ContentRadio>
                ))}
              </DataContent>
              <AddNewPassport
                onClick={onAddNewPassport}
                data-tracking-id="passport-box-add-new-button">
                <Text variant="linkSmall" color="primary">
                  {`+ ${t(i18Base + 'AddPassport')}`}
                </Text>
              </AddNewPassport>
            </>
          )}
          {areNoPassport && (
            <ErrorContainer>
              <ErrorMessage>
                <Icon size="small" name="warningFilledPWA" />
                <Block paddingLeft={5}>
                  <span>{t(i18Base + 'ErrorMsg')}</span>
                </Block>
              </ErrorMessage>
              <Block paddingLeft={26}>
                <Link onClick={onAddNewPassport} size="small">{`+ ${t(
                  i18Base + 'AddPassport',
                )}`}</Link>
              </Block>
            </ErrorContainer>
          )}
        </>
      )}
      <AddNewPassport onClick={() => handleTravelerDocumentOpen(DocumentType.KTN)}>
        <Swap
          is={!!knownTravelerNumbersSize}
          isSlot={
            <>
              <Text variant="footnoteMedium" color="mainText1">
                {t(i18Base + 'KnownTravelerNumbers')}
              </Text>
              <RightBlock>
                <NumberBadge>
                  <Text variant="captionStrong" color="white">
                    {knownTravelerNumbersSize}
                  </Text>
                </NumberBadge>
                <Icon name="chevronRightPWA" size="small" />
              </RightBlock>
            </>
          }>
          <Text variant="linkSmall" color="primary">
            {`+ ${t(i18Base + 'AddKnownTravelerNumber')}`}
          </Text>
        </Swap>
      </AddNewPassport>
      <AddNewPassport onClick={() => handleTravelerDocumentOpen(DocumentType.Redress)}>
        <Swap
          is={!!redressNumbersSize}
          isSlot={
            <>
              <Text variant="footnoteMedium" color="mainText1">
                {t(i18Base + 'RedressNumbers')}
              </Text>
              <RightBlock>
                <NumberBadge>
                  <Text variant="captionStrong" color="white">
                    {redressNumbersSize}
                  </Text>
                </NumberBadge>
                <Icon name="chevronRightPWA" size="small" />
              </RightBlock>
            </>
          }>
          <Text variant="linkSmall" color="primary">
            {`+ ${t(i18Base + 'AddRedressNumber')}`}
          </Text>
        </Swap>
      </AddNewPassport>
    </Container>
  )
}
