import { useTranslation } from 'react-i18next'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates'

type Props = {
  street: string
  datetime: string
}

export function useFormattedSearchPoint({ street, datetime }: Props) {
  const { t } = useTranslation()
  const i18nPath = 'TripPlanner.CarRentalOfficeSearch.'

  const resolveDate = tripReviewFormatDate(TripReviewDateType.MonthAndDay, datetime)
  const resolveTime = tripReviewFormatDate(TripReviewDateType.TimePWA, datetime)
  return `${street}, ${resolveDate} ${t(i18nPath + 'At')} ${resolveTime}`
}
