import { useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import type { AnyObject } from 'yup/lib/types'

import { useSubmit } from '@fiji/hooks/use-validation/use-submit'
import type { ValidationScheme, Validator } from './types'
import { useInitialFields } from './use-initial-fields'
import { useFieldChange } from './use-field-change'

export function useValidation<T extends ValidationScheme = ValidationScheme>(
  scheme: T,
  context?: AnyObject,
): Validator<T> {
  const yupScheme = useMemo(() => yup.object().shape(scheme), [scheme])
  const defaults = useMemo(() => yupScheme.getDefault(), [yupScheme])
  const initialFields = useInitialFields<T>(defaults, scheme)
  const initialErrorFields = useInitialFields<T>(defaults, scheme, '')

  const [errors, setErrors] = useState(initialErrorFields)
  const [values, setValues] = useState(initialFields)
  const setError = useCallback((fieldName: string, message: string) => {
    setErrors((oldErrors) => ({
      ...oldErrors,
      [fieldName]: message,
    }))
  }, [])

  useEffect(() => {
    setValues(initialFields)
  }, [initialFields])

  const submit = useSubmit({ yupScheme, setErrors, values, context })
  const reset = useCallback(() => {
    setErrors({} as T)
    setValues(initialFields)
  }, [initialFields])
  const onFieldChange = useFieldChange(setErrors, setValues)

  return {
    reset,
    setError,
    errors,
    submit,
    values,
    onFieldChange,
  }
}
