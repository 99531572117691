import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'

const i18nBase = 'CarRentalResults'
const RESET_TO_DEFAULT_FILTER = 'CarRentalResults.ResetToDefaultFilters'

export function useCarRentalHeaderBlock() {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore
  const {
    carRentalFilterActions,
    carRentalSearchStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { isLoading } = carRentalSearchStore
  const {
    numberOfAppliedFilters,
    isDefaultFilterCompanySettingsExist,
    isShowResetToDefaultFiltersButton,
  } = carFiltersStore
  const { handleResetCarFilters, handleResetToDefaultFilters } = carRentalFilterActions
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const listResultHeaderTitle = t(`${i18nBase}.AllResults`)
  const resetToDefaultLinkLabel = t(RESET_TO_DEFAULT_FILTER)

  const filtersAppliedLabel = useMemo(() => {
    return numberOfAppliedFilters > 1
      ? t(`${i18nBase}.FiltersApplied`, { number: numberOfAppliedFilters })
      : t(`${i18nBase}.FilterApplied`, { number: numberOfAppliedFilters })
  }, [numberOfAppliedFilters, t])

  const handleClearFilters = useCallback(() => {
    carFiltersStore.clearHandlers.clearAllSelectedFilters()
    carRentalFilterActions.handleResetCarCompanyFilters()
    handleResetCarFilters()
  }, [carFiltersStore.clearHandlers, carRentalFilterActions, handleResetCarFilters])

  const shouldDisplayFiltersNumberBadge = useMemo(() => {
    return (
      !isLoading && !!numberOfAppliedFilters && !isMobile && flags.isCarRentalsNewFiltersEnabled
    )
  }, [isLoading, numberOfAppliedFilters, isMobile, flags.isCarRentalsNewFiltersEnabled])

  return {
    isMobile,
    handleResetToDefaultFilters,
    displayConfigurationStore,
    listResultHeaderTitle,
    resetToDefaultLinkLabel,
    filtersAppliedLabel,
    handleClearFilters,
    shouldDisplayFiltersNumberBadge,
    isDefaultFilterCompanySettingsExist,
    isShowResetToDefaultFiltersButton,
  }
}
