import styled, { css } from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const Container = styled.div`
  border-radius: 14px;
  margin: 16px 0 16px;
  background: ${(props) => props.theme.colors.white};
`

export const AirlineLogosWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
`

export const Heading = styled.div`
  padding: 16px 16px 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const HeadingTitle = styled.div`
  margin: 0 0 4px;
  ${headline}
`

export const AmenitiesContainer = styled.div`
  padding: 12px 16px;
  position: relative;
`

export const AmenitiesList = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: end;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      flex-direction: column;
      align-items: start;
    `};
`

export const AmenitiesItem = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  ${footnoteMedium}
`

export const IconRow = styled.div`
  margin: 0 10px 0 0;
`

export const ToggleButton = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
`
