import type { ItineraryTrip } from '@fiji/graphql/types'
import { getDateRangeTitle } from './get-date-range-title'

type Args = {
  itineraryTrip?: ItineraryTrip
}

export function useTrip({ itineraryTrip }: Args) {
  const titleDateRange = getDateRangeTitle(itineraryTrip?.startDate, itineraryTrip?.endDate)

  return {
    name: itineraryTrip?.name || '',
    isNameEditable: !!itineraryTrip?.isNameEditable,
    imageUrl: itineraryTrip?.imageUrl,
    titleDateRange,
  }
}
