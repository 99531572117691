import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { RailTransportationMode } from '@fiji/graphql/types' // TODO: remove `RailTransportationMode` once use enum in review page

export function getNonRailTransferIconNames(
  transportMode?: RailTransportationModeEnum | RailTransportationMode | null,
) {
  switch (transportMode) {
    case RailTransportationModeEnum.Bus:
    case RailTransportationMode.Bus:
      return 'busNewPWA'
    case RailTransportationModeEnum.Unspecified:
    case RailTransportationModeEnum.Metro:
    case RailTransportationMode.Unspecified:
    case RailTransportationMode.Metro:
      return 'tubePWA'
    case RailTransportationModeEnum.Walk:
    case RailTransportationMode.Walk:
      return 'walkFilledPWA'
    case RailTransportationModeEnum.Ferry:
    case RailTransportationMode.Ferry:
      return 'ferryPWA'
    case RailTransportationModeEnum.Tram:
    case RailTransportationMode.Tram:
      return 'tramPWA'
    default:
      return 'changePWA'
  }
}
