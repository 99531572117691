import { useTranslation } from 'react-i18next'

type Params = {
  dateToShow: string
}

const i18Base = 'PostBooking.TripDetails'

export function useCarServiceSegment({ dateToShow }: Params) {
  const { t } = useTranslation()

  const title = t(i18Base + '.ScheduledPickUp')
  const ariaLabel = t(`${i18Base}.SegmentAriaLabel`, { label: `${title}. ${dateToShow}` })

  return {
    title,
    ariaLabel,
  }
}
