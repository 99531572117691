import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from './accessibility'

export const DividerAfter = css`
  &:after {
    content: '';
    display: flex;
    height: 1px;
    width: 100%;
    background-color: #bdc3d6;
    opacity: 0.5;
    margin-top: 15px;
  }
`

export const DotAfter = css`
  &:after {
    content: '.';
    position: relative;
    display: inline-block;
    bottom: 4px;
    border-radius: 50%;
    color: #bdc3d6;
    opacity: 0.6;
    margin: 0px 7px;
    font-weight: bold;
  }
`

export const UnstyledButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0;
  text-align: left;
  cursor: pointer;
  ${KeyboardNavigationStyle}
`

export const VisuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
