import styled from 'styled-components'

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.borderLight};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.mainText};
  z-index: 2;
`

export const Timeline = styled.div`
  display: grid;
  align-content: space-between;
  position: relative;

  &:before {
    content: '';
    height: calc(100% - 40px);
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 6px;
    position: absolute;
    display: block;
    z-index: 1;
    background-color: ${(props) => props.theme.colors.borderLight};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
