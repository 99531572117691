import { MediaLayout } from '@etta/ui/media-layout/media-layout'
import type { LayoutProps } from '../seat-footer.types'
import { SeatFooterDesktop } from './desktop'
import { SeatFooterMobile } from './mobile'

export function SeatFooterLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SeatFooterMobile {...props} />}
      desktopSlot={<SeatFooterDesktop {...props} />}
    />
  )
}
