import styled from 'styled-components'
import { subHeadMedium, headline, title3, footnoteMedium, footnoteStrong, body } from '@fiji/style'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { Link } from '@etta/ui/link'

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`
export const Title = styled.div`
  padding-left: 8px;
  ${title3};
  color: ${(props) => props.theme.colors.mainText};
  margin: 0px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
  align-items: center;
  min-width: max-content;
`

export const UpperBody = styled.div`
  padding: 10px 0px 24px 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`

export const EmissionInformation = styled.div``

export const EmissionInformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const EmissionInformationType = styled.p`
  ${subHeadMedium}
`

export const EmissionInformationValue = styled.p<{ deviationLevel?: EmissionsDeviationLevel }>`
  color: ${(props) => props.theme.colors.mainText1};
  ${headline};
`

export const AverageEmissionInformationValue = styled.p<{
  deviationLevel?: EmissionsDeviationLevel
}>`
  color: ${(props) => props.theme.colors.bodyText};
  ${headline};
`

export const DeviationInfo = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Average
      ? props.theme.colors.mainText
      : props.theme.colors.mainText1};
  background-color: ${(props) => props.theme.colors.background};
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  ${body};
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const DownArrow = styled(Icon)`
  transform: rotate(180deg);
`

export const EquivalencesContainer = styled.div`
  margin-top: 8px;
`

export const EmisisonDetails = styled.div`
  padding: 0 72px;
`

export const EmisisonDetailsHeader = styled.p`
  margin: 0;
  margin-top: 24px;
  ${title3};
`

export const EmisisonDetailsDescription = styled.p<{ withBorder?: boolean }>`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  border-bottom: ${(props) => (props.withBorder ? '1px' : 0)} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 15px;
  margin: 0;
  margin-top: 8px;
`

export const EmisisonDetailsAboutDescription = styled.p`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  margin: 0;
  margin-top: 20px;
`

export const ClapContainer = styled.div`
  padding: 0 72px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`
export const ClapTitle = styled.p`
  margin-left: 10px;
  ${title3}
`

export const CardContainer = styled.div<{ withMarginRight: boolean }>`
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.mainText1};
  border-radius: 14px;
  margin-right: ${(props) => (props.withMarginRight ? '12px' : '0')};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100px;
  width: 50%;
`

export const LeftBlock = styled.div``
export const RightBlock = styled.div``

export const EmissionKg = styled.p`
  color: ${(props) => props.theme.colors.mainText2};
  margin: 0;
  ${headline};
`

export const DescriptionBlock = styled.p`
  margin: 0;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.mainText2};
  ${footnoteStrong};
`

export const EquivalentContainer = styled.div`
  padding: 22px 0px 0px 72px;
`
export const EquivalentEmission = styled.div`
  display: flex;
  gap: 2px;
`
export const EquivalentEmissionValue = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
`
export const ViewSourceLink = styled(Link)`
  display: inline-flex;
  align-items: center;
`
