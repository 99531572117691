import { useTranslation } from 'react-i18next'

import type { OptionType } from '@etta/ui/select'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { PreferencesLabel } from '@etta/components/preferences-label'
import { PreferenceMealUnavailable } from './preference-meal-unavailable'
import { PreferenceMealOptions } from './preference-meal-options'

type Props = {
  mealOptions?: OptionType<string>[] | null
  mealValue?: string | null
  onUpdateMeal: (data?: string | null) => void
}

export function PreferenceMeal({ mealOptions, mealValue, onUpdateMeal }: Props) {
  const { t } = useTranslation()

  return (
    <Block marginVertical={40}>
      <Block marginBottom={12}>
        <PreferencesLabel icon="mealPWA">{t('FlightPreferences.Meal')}</PreferencesLabel>
      </Block>
      <Swap is={!mealOptions || mealOptions.length === 0} isSlot={<PreferenceMealUnavailable />}>
        <PreferenceMealOptions
          mealOptions={mealOptions!}
          mealValue={mealValue}
          onUpdateMeal={onUpdateMeal}
        />
      </Swap>
    </Block>
  )
}
