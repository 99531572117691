import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { PreferenceSpecialRequests } from '../preference-special-requests'
import { LoyaltyPrograms } from '../loyalty-programs'
import { useSpecialRequest } from '../use-special-requests'
import { useLoyaltyProgram, composeLoyaltyProgram } from '../use-loyalty-program'
import type { CarRentalPreferencesType } from './types'

const i18nBase = 'CarRentalPreferences'

export const CarRentalPreferences = observer(function CarRentalPreferences({
  loyaltyProgram,
  specialRequest,
  onClose,
  onSubmit,
  existingMembership,
}: CarRentalPreferencesType) {
  const { t } = useTranslation()

  const { onChangeSpecialRequest, specialRequestsValues } = useSpecialRequest(specialRequest.value)
  const { getLoyaltyWithNumbers, loyaltyProgramValues, onChangeLoyaltyProgram } = useLoyaltyProgram(
    loyaltyProgram.value,
  )

  const handleSubmit = () => {
    onSubmit({
      membership: composeLoyaltyProgram({
        loyaltyFromProfile: existingMembership,
        loyaltyFromApp: getLoyaltyWithNumbers(),
      }),
      specialRequestCodes: specialRequestsValues,
      loyaltyProgramValues,
    })
  }

  return (
    <>
      <CheckoutPageModal.Header title={t(`${i18nBase}.Title`)} onClose={onClose} />
      <CheckoutPageModal.Body>
        {!!loyaltyProgram.options.length && (
          <LoyaltyPrograms
            label={t(`${i18nBase}.LoyaltyProgramMembership`)}
            selectLabel={t(`${i18nBase}.Program`)}
            memberLabel={t(`${i18nBase}.MembershipNumber`)}
            options={loyaltyProgram.options}
            value={loyaltyProgramValues}
            onUpdate={onChangeLoyaltyProgram}
            placeholder={t(`${i18nBase}.SearchForProgram`)}
            fromProfile={existingMembership}
          />
        )}
        {!!specialRequest.options.length && (
          <PreferenceSpecialRequests
            options={specialRequest.options}
            values={specialRequestsValues}
            onChange={onChangeSpecialRequest}
          />
        )}
      </CheckoutPageModal.Body>
      <CheckoutPageModal.Footer
        submitLabel={t(`${i18nBase}.Button`)}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </>
  )
})
