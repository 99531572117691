import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { useRailSearchResultContextSelector } from '@etta/screens/rail-search-result-page/rail-search-result-page-provider'

type Props = {
  onEdit: () => void
}

export function ViewMode({ onEdit }: Props) {
  const { locationsLabel, datesLabel } = useRailSearchResultContextSelector((state) => state)

  const { railActions } = useRailContext()
  const {
    featureFlagsStore: { flags },
  } = useFeatureFlagsContext()

  return (
    <SearchResultsHeaderFull.ViewMode
      onEdit={onEdit}
      datesLabel={datesLabel}
      locationsLabel={locationsLabel}
      onFilterClick={railActions.handleFiltersModalOpen}
      withFilterButton={flags.isRailSearchFiltersEnabled}
    />
  )
}
