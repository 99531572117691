import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { RailDetails } from '@etta/components/rail-details/rail-details'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { TravelPolicyDialog } from '@etta/components/travel-policy-dialog/travel-policy-dialog'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { TripStatusModal } from '@etta/components/trip-status-modal'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { RailLegType } from '@fiji/enums'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { RailTicketRestrictions } from '@etta/components/rail-ticket-restrictions'
import { RailSegmentTravelClassEnum } from '@etta/modules/rail/core/enums/rail-segment-travel-class.enum'
import { ConfirmationDialog as EditTimeDialog } from '@etta/ui/confirmation-dialog'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { RailPermittedStationsModal } from '@etta/components/rail-permitted-stations'
import { RailResults } from './rail-results'
import { RailSearchResultPageLayout } from './layout'
import { RailFilters } from './rail-filters'
import { SelectedDepartingRail } from './outward-journey-card/selected-departing-rail'
import { ContentWrapper } from './rail-search-result-page-content-styled'
import { EditRailSearchModal } from './edit-rail-search-modal/edit-rail-search-modal'

// TODO: see if we can remove this
type Props = {
  railLegType: RailLegType
}

const i18nBase = 'RailSearch.EditTimeConfirmation'

export const RailSearchResultPageContent = observer(function RailSearchResultPageContent({
  railLegType,
}: Props) {
  const { railStore, railActions, railQueryParamStore, railSearchActions } = useRailContext()
  const { t } = useTranslation()
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { railSearchDSMLightboxToggler } = dynamicSiteMessagesTogglersStore
  const { tripRailActions, reviewTripStore } = useReviewTripContext()
  const isMobile = useScreenType() === ScreenType.Mobile

  useEffect(() => {
    const fetchDataOnLoaded = async () => {
      await railActions.fetchItineraryId()
      await railActions.createRailSearch()
      await railActions.handleSearch(railLegType)
      await railActions.getRailEmissions(RailSegmentTravelClassEnum.Economy)
    }
    fetchDataOnLoaded()
  }, [railActions, railStore, railLegType])

  useEffect(() => {
    async function updateTrip() {
      if (
        railLegType === RailLegType.Return &&
        !reviewTripStore.trip &&
        railQueryParamStore.itineraryId
      ) {
        reviewTripStore.setTripId(railQueryParamStore.itineraryId)
        await tripRailActions.handleUpdateTrip()
      }
    }
    updateTrip()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RailSearchResultPageLayout
        contentSlot={
          <ContentWrapper>
            <Swap is={!railStore.dynamicSiteMessages?.length}>
              <Block marginTop={24} marginBottom={16} paddingHorizontal={16}>
                <DynamicSiteMessages messages={railStore.dynamicSiteMessages} />
              </Block>
            </Swap>
            {!isMobile && railLegType === RailLegType.Return && reviewTripStore.rail && (
              <SelectedDepartingRail />
            )}
            <RailResults railLegType={railLegType} />
          </ContentWrapper>
        }
      />

      <TravelPolicyDialog
        isVisible={railStore.isPolicyModalOpen}
        handleVisibility={railActions.handlePolicyModalClose}
      />
      <TripStatusModal
        tripStateStatus={railStore.selectRailStatus}
        onClick={railActions.handleTripStatusClose}
      />
      <RailFilters
        isOpen={railStore.isFiltersModalOpen}
        onClose={railActions.handleFiltersModalClose}
      />
      <RailDetails
        isVisible={!!railStore.activeLeg && railStore.isRailDetailsOpen}
        onClose={railActions.handleRailDetailsClose}
        railLegType={railLegType}
      />
      <RailTicketRestrictions
        isVisible={railStore.isTicketRestrictionsOpen}
        onClose={railActions.handleTicketRestrictionsInfoClose}
      />
      <RailPermittedStationsModal
        isVisible={railStore.isPermittedStationsOpen}
        onClose={railActions.handlePermittedStationsInfoClose}
      />
      <DynamicSiteMessagesLightboxModal
        messages={railStore.dynamicSiteMessages}
        isVisible={railSearchDSMLightboxToggler.isOpen}
        onClose={railSearchDSMLightboxToggler.handleClose}
      />
      <EditTimeDialog
        isOpen={railStore.isEditTimeModalOpen}
        title={t(`${i18nBase}.Title`)}
        content={t(`${i18nBase}.Content`)}
        onConfirm={() => {
          railSearchActions.handleConfirmEditTime(isMobile)
        }}
        confirmButtonText={t(`${i18nBase}.Confirm`)}
        onDecline={railSearchActions.handleEditTimeModalClose}
        declineButtonText={t(`${i18nBase}.Decline`)}
      />
      <EditRailSearchModal isOpen={railStore.isEditRailSearchModalOpen} />
    </>
  )
})
