import { Action, Inject } from '@etta/di'
import { PostBookingTripService } from '@etta/modules/post-booking'
import { RideHailSearchItineraryStore } from '../store/ride-hail-search-itinerary.store'
import { SearchRideHailStore } from '../store/search-ride-hail.store'

@Action()
export class RideHailSearchItineraryActions {
  constructor(
    @Inject() private postBookingTripService: PostBookingTripService,
    @Inject() private rideHailSearchItineraryStore: RideHailSearchItineraryStore,
    @Inject() private searchRideHailStore: SearchRideHailStore,
  ) {}

  async getTripDetails() {
    const { processId, transactionGroupId } = this.searchRideHailStore.searchRideHailTripInfo

    this.rideHailSearchItineraryStore.setIsLoading(true)
    this.rideHailSearchItineraryStore.setIsError(false)
    this.rideHailSearchItineraryStore.dropSegments()

    const result = await this.postBookingTripService.fetchTripDetails(
      {
        id: String(processId),
        transactionGroupId: String(transactionGroupId),
      },
      true,
    )

    result.match({
      Ok: (trip) => {
        this.rideHailSearchItineraryStore.setSegments(trip.segments)
        this.rideHailSearchItineraryStore.setIsLoading(false)
      },
      Err: () => {
        this.rideHailSearchItineraryStore.setIsError(true)
        this.rideHailSearchItineraryStore.setIsLoading(false)
      },
    })
  }

  dropItineraryStore() {
    this.rideHailSearchItineraryStore.dropStore()
  }
}
