import { useCallback } from 'react'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'

export function useFuelOptions() {
  const {
    carRentalSearchStore,
    carFiltersStore,
    carRentalFilterActions,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const options = filterOptions.fuelTypes

  const isChecked = carFiltersStore.selectionHandlers.isFuelTypeSelected

  const handleSelectFuelType = useCallback(
    (id: string) => () => {
      carRentalFilterActions.handlers.setFuelType(id)
    },
    [carRentalFilterActions.handlers],
  )

  return {
    options,
    isChecked,
    handleSelectFuelType,
  }
}
