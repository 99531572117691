import { useMemo } from 'react'
import type { ContinuityMessageSegmentKeys } from '@fiji/types'
import type {
  ComplianceMessage,
  ContinuityMessage,
  OverlappingTrip,
  DynamicSiteMessage,
} from '@fiji/graphql/types'
import type { Alert } from '@etta/components/dynamic-site-messages/types'
import { generateId } from '@fiji/utils/generate-id'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { useWarningAlerts } from './use-warning-alerts'
import { useTravelAlerts } from './use-travel-alerts'
import { continuityWarningAlert } from './continuity-warning-alert'
import { useInfoAlerts } from './use-info-alerts'

type Props = {
  dynamicSiteMessage?: DynamicSiteMessage[] | null
  complianceMessages?: ComplianceMessage | null
  continuityMessages?: ContinuityMessage[] | null
  overlappingTrips?: OverlappingTrip[] | null
  itineraryId: string
  firstHotelId?: string
  segmentKeys?: ContinuityMessageSegmentKeys
}

export function useTripReviewDynamicSiteMessages({
  dynamicSiteMessage,
  complianceMessages,
  continuityMessages,
  overlappingTrips,
  itineraryId,
  firstHotelId,
  segmentKeys,
}: Props) {
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { warningAlerts } = useWarningAlerts({
    complianceMessages,
    continuityMessages,
    overlappingTrips,
  })

  const { reviewTripDSMLightboxToggler } = dynamicSiteMessagesTogglersStore

  const dynamicSiteMessages = dynamicSiteMessage?.map((message) => {
    return {
      header: message.header,
      text: message.mobileText ? message.mobileText : message.text,
      isDialog: message.isDialog,
      hasDesktopBannerImage: message.hasDesktopBannerImage,
    }
  })

  const { travelAlerts } = useTravelAlerts({ overlappingTrips, complianceMessages })

  const continuityMessageWarnings = useMemo(() => {
    if (continuityMessages && continuityMessages?.length > 0) {
      return continuityMessages.filter((message) => message.intent === 'WARNING')
    }
  }, [continuityMessages])

  const messages: Alert[] = continuityMessageWarnings
    ? continuityMessageWarnings?.map((message) => {
        return {
          id: generateId('alert-for-trip-review', message.code),
          header: '',
          text: '',
          component: continuityWarningAlert({ message, itineraryId, firstHotelId, segmentKeys }),
        }
      })
    : []

  const { infoAlerts } = useInfoAlerts({ complianceMessages })

  return {
    alerts: [...warningAlerts, ...messages, ...travelAlerts, ...infoAlerts],
    dynamicSiteMessages: dynamicSiteMessages || [],
    reviewTripDSMLightboxToggler,
  }
}
