import { MediaLayout } from '@etta/ui/media-layout'
import { KnownTravelerNumberFormDesktop } from './desktop'
import { KnownTravelerNumberFormMobile } from './mobile'

export type Props = {}

export function KnownTravelerNumberFormLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<KnownTravelerNumberFormMobile {...props} />}
      desktopSlot={<KnownTravelerNumberFormDesktop {...props} />}
    />
  )
}
