import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

export const DepartureAndArrival = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Departure = styled.div`
  flex-shrink: 0;
  width: 94px;
  text-align: left;
`

export const ChangesLine = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 8px;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  align-self: flex-end;
  display: flex;
  align-items: center;
`

export const ChangesWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
`

export const ChangesDot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.borderDark};
  }
`

export const Arrival = styled.div`
  width: 94px;
  flex-shrink: 0;
  text-align: right;
`

export const Time = styled(Text)`
  margin-top: 2px;
  line-height: 25px;
`

export const Date = styled(Text)`
  margin-top: 2px;
  line-height: 20px;
`

export const StationWrapper = styled(Block)`
  display: flex;
  justify-content: space-between;
`

export const StationName = styled(Text)`
  margin-top: 2px;
  line-height: 19px;
  max-width: 110px;
`
