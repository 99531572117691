import type { TextProps } from '@etta/ui/text'
import type { IconColorPalette } from '@etta/ui/icon'
import type { IconBadgeVariant } from './types'

export function getTextProps(variant: IconBadgeVariant, color?: IconColorPalette): TextProps {
  switch (variant) {
    case 'tripAdvisor':
    case 'locationHotel':
    case 'preferredCaption':
      return { variant: 'captionMedium', color: color || 'mainText1' }
    case 'seatMapNotAvailable':
      return { variant: 'footnoteStrong', color: color || 'bodyText1' }
    default:
      return { variant: 'footnoteMedium', color: color || 'mainText1' }
  }
}
