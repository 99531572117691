import type { IconNames } from '@etta/ui/icon'
import type { PreferencesType } from '../types'

export function getIconByType(type: PreferencesType): IconNames {
  switch (type) {
    case 'car':
      return 'carRentalPWA'
    case 'flight':
      return 'flightPWA'
    case 'hotel':
      return 'hotelPWA'
    case 'rail':
      return 'railPWA'
  }
}
