import styled, { css } from 'styled-components'

export const SpinnerWrapper = styled.div<{ height?: string }>`
  margin: auto;
  ${({ height }) =>
    height &&
    css`
      display: flex;
      height: ${height};
    `}
`
