import { useCallback, useMemo } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { BrandFilter, SuperBrandFilter } from '@fiji/graphql/types'
import { screenMatcher, ScreenType } from '@fiji/modes'

type Args = {
  onChange: (selected: string[]) => void
  selected: string[]
  superBrands: SuperBrandFilter[]
  brands: BrandFilter[]
}

const TOTAL_VISIBLE_ROWS_MOBILE = 3
const TOTAL_VISIBLE_ROWS_DESKTOP = 5

export function useBrandsShowMore({ selected, onChange, brands, superBrands }: Args) {
  const collapseToggle = useTogglePopup()
  const TOTAL_VISIBLE_ROWS =
    screenMatcher.getScreenType() === ScreenType.Mobile
      ? TOTAL_VISIBLE_ROWS_MOBILE
      : TOTAL_VISIBLE_ROWS_DESKTOP

  const selectedIdsMap = useMemo(() => {
    return new Set(selected)
  }, [selected])

  const onSelect = useCallback(
    (idsMap: Set<string>) => {
      onChange(Array.from(idsMap))
    },
    [onChange],
  )

  const totalSelectedRows = superBrands.length + brands.length
  const visibleRowsSelected =
    totalSelectedRows > TOTAL_VISIBLE_ROWS ? TOTAL_VISIBLE_ROWS : totalSelectedRows
  const visibleRowsNotSelected =
    visibleRowsSelected > TOTAL_VISIBLE_ROWS ? 0 : TOTAL_VISIBLE_ROWS - visibleRowsSelected
  const isCollapsed = !collapseToggle.isOpen

  return {
    collapseToggle,
    selectedIdsMap,
    onSelect,
    isCollapsed,
    visibleRowsSelected,
    visibleRowsNotSelected,
  }
}
