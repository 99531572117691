import type { Maybe, AirportAddressFragment, AirportAddress } from '@fiji/graphql/types'

type Params = {
  airportAddress?: Maybe<AirportAddressFragment>
}

export function mapAirArrivalDeparture({ airportAddress }: Params): AirportAddress {
  const result = {
    airportCode: airportAddress?.airportCode || null,
    airportName: airportAddress?.airportName || '',
    address: {
      airportCode: airportAddress?.address?.airportCode || '',
      stateCode: airportAddress?.address?.stateCode || '',
      postalCode: airportAddress?.address?.postalCode || null,
      street1: airportAddress?.address?.street1 || null,
      street2: airportAddress?.address?.street2 || null,
      city: airportAddress?.address?.city || '',
      countryCode: airportAddress?.address?.countryCode || '',
      locationName: airportAddress?.address?.locationName || null,
      geocode: {
        lat: airportAddress?.address?.geocode?.lat || 0,
        long: airportAddress?.address?.geocode?.long || 0,
      },
    },
  }

  return result
}
