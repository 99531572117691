import styled, { css } from 'styled-components'
import { headline, bodyLarge } from '@fiji/style'

type SelectedDateStatusProps = {
  isSelected?: boolean
  isStickToRight?: boolean
  isRange?: boolean
  isMobile: boolean
  startDate?: Date | null
  endDate?: Date | null
}

export const SelectedDateStatus = styled.div<SelectedDateStatusProps>`
  ${({ isMobile, isRange }) =>
    isMobile
      ? `
      border: none;
      background: none;
      justify-content: center;
      width: ${isRange ? '50%' : '100%'};
      `
      : `
        font-weight: bold;
      `}

  height: 100%;
  align-items: center;
  display: flex;

  color: ${(props) =>
    props.isSelected || props.startDate || props.endDate ? props.theme.colors.primary : '#656B72'};

  position: relative;
  &::after {
    content: '';
    height: ${({ isMobile }) => (isMobile ? '6px' : '3px')};
    position: absolute;
    bottom: 0;
    right: ${({ isMobile }) => (isMobile ? 0 : '-8px')};
    left: 0;
    background-color: ${(props) =>
      props.isMobile && props.isRange && props.isSelected
        ? props.theme.colors.primary
        : 'transparent'};
  }

  ${headline} ${({ isMobile }) => (isMobile ? bodyLarge : headline)}
`

export const Separator = styled.div`
  width: 1px;
  background: ${(props) => props.theme.colors.borderLight};
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1;
`

export const ButtonsContainer = styled.div<{ shouldCenterItems?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  ${({ shouldCenterItems }) =>
    shouldCenterItems &&
    css`
      align-items: center;
    `}
`
