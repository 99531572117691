import type { SwipeableHandlers } from 'react-swipeable'
import { Header, Title } from '../swiping-time-picker-components'
import { Picker } from '../picker'
import type { SwipingTimePickerData, SwipingPickerSubtype } from '../types'
import { SelectedDate } from './selected-date'

type Props = {
  label: string
  day: number
  month: number
  year: number
  startYear: number
  data: SwipingTimePickerData
  variant?: SwipingPickerSubtype
  handleSwipe: [
    firstColumn: SwipeableHandlers,
    secondColum: SwipeableHandlers,
    thirdColumn: SwipeableHandlers,
  ]
}

export function DateLayout({
  label,
  day,
  month,
  year,
  data,
  variant,
  handleSwipe,
  startYear,
}: Props): JSX.Element {
  return (
    <>
      <Header>
        <Title>{label}</Title>
        <SelectedDate
          startYear={startYear}
          day={day}
          month={month}
          year={year}
          variant={variant || 'date'}
        />
      </Header>
      <Picker
        startYear={startYear}
        variant={variant || 'date'}
        handleSwipe={handleSwipe}
        data={data}
      />
    </>
  )
}
