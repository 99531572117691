import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { RailDetailsEcoCheckDesktop } from './desktop'
import { RailDetailsEcoCheckMobile } from './mobile'

export function RailDetailsEcoCheckLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<RailDetailsEcoCheckMobile {...props} />}
      desktopSlot={<RailDetailsEcoCheckDesktop {...props} />}
    />
  )
}
