import { SegmentTypeCheckActions } from '@etta/modules/review-trip/interface/actions'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import { SegmentType } from '@fiji/graphql/types'

function getSegmentVendorCodes(type: SegmentType, segments: TripSegmentValueObject[]) {
  const typeCheck = SegmentTypeCheckActions.getInstance()

  switch (type) {
    case SegmentType.CarRental:
      return segments.filter(typeCheck.isCarRentalSegment).map((segment) => segment?.vendorCode)
    case SegmentType.Flight:
      return segments
        .filter(typeCheck.isFlightSegment)
        .flatMap((segment) => segment.segments)
        .map((segment) => segment?.carrierCode)
    case SegmentType.Hotel:
      return segments.filter(typeCheck.isHotelSegment).map((segment) => segment?.vendorCode)
    case SegmentType.Train:
      return segments
        .filter(typeCheck.isTrainSegment)
        .flatMap((segment) => segment.segments)
        .map((segment) => segment?.carrierCode)

    default:
      return []
  }
}

export function getUsedVendorCodes(
  type: SegmentType,
  segments: TripSegmentValueObject[],
): string[] {
  const vendorCodes = getSegmentVendorCodes(type, segments)
  const vendorCodesFiltered = vendorCodes.filter((code): code is string => !!code)
  return Array.from(new Set(vendorCodesFiltered))
}
