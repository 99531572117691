import { getDifferenceInFullMinutes } from '@fiji/utils/dates/get-difference-in-full-minutes'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'

type Props = {
  segment: TripDetailsFlightLegSegment
  nextFlightStart: string
}

export function useLayover({ nextFlightStart, segment }: Props) {
  const nextFlightDate = new Date(nextFlightStart)
  const arrivalDate = new Date(segment.arrivalDate as string)

  const layoverDiff = getDifferenceInFullMinutes(nextFlightDate, arrivalDate)

  const getLayoverTime = (time: number) => {
    const ONE_HOUR = 60
    const hours = Math.floor(time / ONE_HOUR)
    const minutes = time - ONE_HOUR * hours
    const formattedHours = `${hours}h`
    const formattedMinutes = `${minutes}m`
    if (hours) {
      return [formattedHours, formattedMinutes].join(' ')
    }
    return formattedMinutes
  }

  const layoverTime = getLayoverTime(layoverDiff)
  return {
    layoverTime,
  }
}
