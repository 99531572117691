import { useEffect, useState } from 'react'
import type { MeetingsModeSessionElements } from '@fiji/graphql/types'
import { raygun } from '@fiji/utils/raygun/raygun'
import { appMode } from '@fiji/modes'

const PATH = '/rc/api/syncItinerarySessionAction.do?_action=getSessionElements'

export function useMeetingsModeElements() {
  const [payload, setPayload] = useState<MeetingsModeSessionElements | null>(null)
  const [isFetchFinished, setIsFetchFinished] = useState(false)

  useEffect(() => {
    fetchSessionElements()
  }, [])

  async function fetchSessionElements() {
    let payload: MeetingsModeSessionElements | null = null
    try {
      if (appMode.isFijiAll) {
        const response = await fetch(PATH)
        payload = await response.json()
        setPayload({
          meetingName: payload?.meetingName,
          meetingUserName: payload?.meetingUserName,
          flags: payload?.flags,
        })
      }
    } catch {
      raygun('send', {
        error: new Error(`Can't fetch ${PATH} for useMeetingsModeElements hook`),
      })
    } finally {
      setIsFetchFinished(true)
    }
  }

  return {
    isMeetingsMode: !!payload?.meetingName,
    meetingsModeElements: payload,
    isFetchMeetingsModeElementsFinished: isFetchFinished,
  }
}
