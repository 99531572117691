import { Button } from '@etta/ui/button'
import type { Props } from '../../types'

export const SubmitButtonMobile = ({ onClick, disabled, label, children }: Props) => {
  return (
    <Button
      btnType="primary"
      onClick={onClick}
      size="large"
      disabled={disabled}
      aria-label={label}
      data-tracking-id="header-search-button"
      fullWidth>
      {children}
    </Button>
  )
}
