export default function PeopleIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4854 21.5769C29.5781 21.5769 31.9431 19.1769 31.9431 16.0385C31.9431 12.9 29.5781 10.5 26.4854 10.5C23.3928 10.5 21.0278 12.9 21.0278 16.0385C21.0278 19.1769 23.5747 21.5769 26.4854 21.5769ZM26.4854 13.2692C27.9408 13.2692 29.2143 14.5615 29.2143 16.0385C29.2143 17.5154 27.9408 18.8077 26.4854 18.8077C25.0301 18.8077 23.7566 17.5154 23.7566 16.0385C23.7566 14.5615 25.0301 13.2692 26.4854 13.2692ZM26.484 23.423C31.2139 23.423 35.3981 26.3768 36.8535 30.8076C37.2173 31.7307 36.8535 32.6537 36.3077 33.3922C35.762 34.1307 34.6704 34.4999 33.7608 34.4999H26.484H19.2072C18.2976 34.4999 17.388 34.1307 16.6603 33.3922C15.9326 32.6537 15.7507 31.7307 16.1145 30.8076C17.7518 26.3768 21.7541 23.423 26.484 23.423ZM26.484 31.7307H33.7608C33.9427 31.7307 34.1707 31.6783 34.1247 31.546C33.0331 28.4076 29.9405 26.1922 26.484 26.1922C23.0275 26.1922 19.9349 28.223 18.8433 31.546C18.7827 31.7307 19.0253 31.7307 19.2072 31.7307H26.484ZM15.9337 28.0384H15.5699C13.2049 28.0384 10.8399 29.5153 9.74841 31.7307H10.1122H14.4783C14.4783 32.6538 14.8422 33.5769 15.5699 34.3153L15.7518 34.4999H10.1122C9.20265 34.4999 8.20556 34.2776 7.56536 33.3922C6.97096 32.5702 6.83768 31.7307 7.20152 30.8076C8.65689 27.4845 11.9315 25.2692 15.5699 25.2692C16.4795 25.2692 17.2071 25.4538 17.9348 25.6384C17.2071 26.3769 16.4795 27.1153 15.9337 28.0384ZM19.2088 18.8076C19.2088 21.3923 17.2077 23.423 14.6608 23.423C12.1139 23.423 10.1128 21.3923 10.1128 18.8076C10.1128 16.223 12.1139 14.1923 14.6608 14.1923C17.2077 14.1923 19.2088 16.223 19.2088 18.8076ZM16.48 18.8076C16.48 17.7 15.7523 16.9615 14.6608 16.9615C13.7512 16.9615 12.8416 17.7 12.8416 18.8076C12.8416 19.9153 13.5693 20.6538 14.6608 20.6538C15.7523 20.6538 16.48 19.9153 16.48 18.8076Z"
        fill="currentColor"
      />
    </svg>
  )
}
