import { makeAutoObservable, toJS } from 'mobx'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { CheckoutInfoResult } from '../../../core/value-objects/checkout-info.value-object'
import type { SpecialRequestPersistValueObject } from '../../../core/value-objects/checkout-info/special-request-persist.value-object'

export class CheckoutInfoSpecialRequest {
  private specialRequests: CheckoutInfoResult['specialRequests'] = null

  private specialRequestsPersist = new ItineraryStorage<SpecialRequestPersistValueObject>(
    'specialRequestSelection',
    {
      carRental: null,
      air: null,
      hotel: null,
    },
  )

  constructor() {
    makeAutoObservable(this, { isPersistEmpty: false })
  }

  get specialRequestData() {
    return this.specialRequestsPersist.values
  }

  get airSpecialRequestOptions() {
    return this.specialRequests?.air?.options || null
  }

  get airSpecialRequestValue() {
    return this.specialRequestsPersist.values.air
  }

  get hotelSpecialRequestOptions() {
    return this.specialRequests?.hotel?.options || null
  }

  get hotelSpecialRequestValue() {
    return this.specialRequestsPersist.values.hotel
  }

  get carSpecialRequestOptions() {
    return this.specialRequests?.carRental?.options || null
  }

  get carSpecialRequestValue() {
    return this.specialRequestsPersist.values.carRental
  }

  isPersistEmpty() {
    return !Object.values(toJS(this.specialRequestsPersist.values)).some((value) => value !== null)
  }

  private isSpecialRequestEmpty<T extends keyof SpecialRequestPersistValueObject>(key: T) {
    return this.specialRequestsPersist.values[key] === null
  }

  dropSpecialRequestData() {
    this.specialRequests = null
  }

  dropSpecialRequestDataByKey<T extends keyof SpecialRequestPersistValueObject>(key: T) {
    this.specialRequestsPersist.set({
      ...this.specialRequestsPersist.values,
      [key]: null,
    })
  }

  dropPersistedSpecialRequestData() {
    this.specialRequestsPersist.set({
      carRental: null,
      air: null,
      hotel: null,
    })
  }

  setSpecialRequestData(specialRequests: CheckoutInfoResult['specialRequests']) {
    this.specialRequests = specialRequests
  }

  setSpecialRequestValues(specialRequest: SpecialRequestPersistValueObject) {
    this.specialRequestsPersist.set(specialRequest)
  }

  appendSpecialRequestValue<T extends keyof SpecialRequestPersistValueObject>(
    key: T,
    specialRequest: SpecialRequestPersistValueObject[T],
  ) {
    this.specialRequestsPersist.set({
      ...this.specialRequestsPersist.values,
      [key]: specialRequest,
    })
  }

  setMembershipIfEmpty<T extends keyof SpecialRequestPersistValueObject>(
    key: T,
    specialRequest: SpecialRequestPersistValueObject[T],
  ) {
    if (this.isSpecialRequestEmpty(key)) {
      this.appendSpecialRequestValue(key, specialRequest)
    }
  }
}
