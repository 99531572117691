import type { CacheEntry, GraphQLOperation } from './types'

function getHeadersFromResponse(response: Response) {
  const headers: Record<string, string> = {}

  response.headers.forEach((value, key) => {
    headers[key] = value
  })

  return headers
}

export async function getResponseEntry(
  body: GraphQLOperation,
  response: Response,
): Promise<CacheEntry> {
  const headers = getHeadersFromResponse(response)

  return {
    timestamp: Date.now(),
    response: {
      body: await response.json(),
      status: response.status,
      statusText: response.statusText,
      headers,
    },
    body,
  }
}
