import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useItinerary } from '@fiji/hooks/itinerary/use-itinerary'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useStartOverModal } from '@fiji/hooks/use-start-over-modal/use-start-over-modal'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useLoadDelegates } from '@fiji/hooks/delegates/use-load-delegates'
import { useAppDispatch, useAppSelector } from '@fiji/store'
import {
  updateTripReviewStatus,
  updateTripReviewItinenaryId,
  updateTripReviewBookingId,
  resetTripReviewParams,
  tripReviewTransactionGroupIdSelector,
} from '@fiji/store/trip-review-params'
import { updateFlightId, updateCarRentalId, updateHotelId } from '@fiji/store/segment-ids'
import { SeatReservationType, OutOfPolicyEnum } from '@fiji/graphql/types'
import { MAXIMUM_SEGMENTS_COUNT } from '@fiji/constants'
import { ROUTES } from '@fiji/routes'
import { useAddButtonSettings } from '@fiji/hooks/use-add-button-settings'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { BookType } from '@etta/screens/payment-summary/use-payment-summary/types'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useCalculateItinerarySegmentsEmissions } from '@fiji/hooks/sustainability/use-calculate-itinerary-segments-emissions'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy/use-out-of-policy.context'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { useDelegateContext } from '@etta/modules/delegate/interface/use-delegate-context'
import type { PriceChangeValueObject } from '@etta/modules/air-search/core/value-objects/price-change.value-object'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import { useRulesAndRestrictions } from '@etta/components/rules-and-restrictions'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { useChangeFlight } from '../flight-details-modal/use-flight-details-modal/use-change-flight'
import type { FailedAlignError, FailedRebookError, SegmentToDisplay } from '../types'
import { useExtractPolicyFromSegments } from './filter-out-of-policy-segment'
import { useEditTripName } from './use-edit-trip-name'
import { checkCheckoutAbility } from './check-checkout-ability'
import { useChangeTripDetails } from './use-change-trip-details'
import { useItineraryData } from './use-itinerary-data'
import { useTravelPolicy } from './use-travel-policy'
import { resolveExtraDescription, useOopValidation } from './helpers'
import { useTrip } from './use-trip'
import { useRailSeatPreferences } from './use-rail-seat-preferences'
import { useAlignDates } from './use-align-dates'
import { useTripExpiredModal } from './use-trip-expired-modal'

export function useTripReviewPage() {
  const {
    segmentTypeCheckActions,
    addSegmentModalsStore,
    reviewTripStore,
    postBookingReviewTripPageStore,
    postBookingReviewTripPageActions: {
      setBookingIdFromQueryParams,
      clearPostBookingReviewTripStore,
    },
  } = useReviewTripContext()
  const { checkoutInfoActions } = useCheckoutInfoContext()
  const { airPriceChangeActions } = useAirSearchContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { multipleHotelProvidersStore } = useMultipleHotelProvidersContext()
  const { isShowDelegateName } = useShowDelegateName()
  const { delegateStore } = useDelegateContext()
  const { currentDelegatedUser } = useLoadDelegates()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const transactionGroupId = useAppSelector(tripReviewTransactionGroupIdSelector)

  const outOfPolicyToggle = useTogglePopup()
  const tripCostSummaryToggle = useTogglePopup()
  const tooManySegmentsToggle = useTogglePopup()
  const airPriceChangeToggle = useTogglePopup()
  const carPriceChangeToggle = useTogglePopup()
  const errorsTripToggle = useTogglePopup()

  const { travelPolicyData } = useTravelPolicy()
  const travelPolicy = travelPolicyData?.travelPolicy

  const { itineraryId } = useItinerary()

  const { editNameToggle, handleUpdateTripName } = useEditTripName()

  const {
    postBookingTripActions,
    postBookingTripStore,
    bookTripAgainService,
    bookTripAgainStore,
  } = usePostBookingContext()
  const {
    isLoading: isTripDetailsLoading,
    isOnHold,
    trip: tripDetails,
    isTripExist,
  } = postBookingTripStore
  const { trip } = reviewTripStore
  const { outOfPolicyStore } = useOutOfPolicyContext()
  const { oopSelections } = outOfPolicyStore
  const { multipleHotelProvidersRTPDialog } = multipleHotelProvidersStore
  const appliedRailCard = trip?.railCards?.[0]?.name ?? ''

  const bookingId = postBookingReviewTripPageStore.bookingId

  useEffect(() => {
    setBookingIdFromQueryParams()
    return () => {
      clearPostBookingReviewTripStore()
    }
  }, [setBookingIdFromQueryParams, clearPostBookingReviewTripStore])

  useEffect(() => {
    if (bookingId) {
      postBookingTripActions.getTripDetails({ id: bookingId })
    } else {
      postBookingTripActions.dropTripStore()
    }
  }, [bookingId, postBookingTripActions])

  const {
    segments,
    availableSegments,
    tripCost,
    isLoading,
    itineraryTrip,
    isHoldTripAllowed,
    flightId,
    messages,
    overlappingTrips,
    firstHotel,
    airPriceChangeInfo,
    carPriceChangeInfo,
    flightCost,
    carRentalCost,
    isMultiCity,
    areSegmentsChangeable,
    tripLevelPolicy,
  } = useItineraryData()

  const descriptions = useExtractPolicyFromSegments(segments, tripLevelPolicy)
  const isHotelRequiredForOvernightTrip = !!descriptions?.find((policy) =>
    policy.oopReasons?.includes(OutOfPolicyEnum.HotelRequiredForOvernightTrip),
  )

  const isCarRentalRequiredViolation = !!descriptions?.find((policy) =>
    policy.oopReasons?.includes(OutOfPolicyEnum.CarRentalRequiredViolation),
  )

  const flightSegments = useMemo(
    () => segments?.filter(segmentTypeCheckActions.isFlightSegment) || [],
    [segmentTypeCheckActions.isFlightSegment, segments],
  )
  const railSegments = segments?.filter(segmentTypeCheckActions.isTrainSegment) || []

  const {
    appliedPreferences,
    currentSeatReservationType,
    currentSelectedPreferenceOptions,
    currentSelectedSeatsLabel,
    isRailPreferenceOptionsExist,
    isRailPreferencesLoading,
    isUpdatedSeatPreferences,
    onPreferencesApply,
    railPreferencesError,
    railViewStateParams,
    seatPreferencesToggle,
    temporaryCurrentLayout,
    isRailEticket,
    onSkip,
    isSkipped,
  } = useRailSeatPreferences({ segments: railSegments, itineraryId })

  const isAbleToCheckout = useMemo(() => {
    if (isOnHold) {
      return true
    }
    if (!bookingId) {
      return true
    }
    return checkCheckoutAbility({ segments })
  }, [bookingId, segments, isOnHold])

  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const isPreventForward =
    isMobile && segments.length !== 0 && !bookTripAgainStore.isBookTripAgainFailed

  const { startOverModal, startOverModalSlot } = useStartOverModal({
    isLoading,
    isPreventBack: segments.length !== 0 && !!isAbleToCheckout,
    isPreventForward: isPreventForward || !!delegateStore.delegateId,
    shouldOpenExplore: trip?.segments.length === 0 && !bookTripAgainStore.isBookTripAgainFailed,
    bookingId,
  })

  const isPolicyExplanationRequired = resolveExtraDescription({ descriptions, travelPolicy })
  const tripData = useTrip({
    itineraryTrip,
  })

  useEffect(() => {
    if (!itineraryTrip) {
      return
    }
    if (!segments) {
      return
    }
    if (segments.length === 0) {
      return
    }

    dispatch(updateTripReviewItinenaryId(itineraryId))
    dispatch(updateTripReviewBookingId(bookingId))
    dispatch(updateTripReviewStatus('create'))
  }, [segments, itineraryTrip, itineraryId, bookingId, dispatch])

  useEffect(() => {
    dispatch(updateFlightId({ flightId: null }))
    dispatch(updateCarRentalId({ carRentalId: null }))
    dispatch(updateHotelId({ hotelId: null }))
  }, [dispatch])

  const { isOopResolved } = useOopValidation({
    oopSelections,
    segments,
    isDescriptionRequired: isPolicyExplanationRequired,
    bookingId,
    isOnHold,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
  })

  const [isCheckoutActivated, setActivatedCheckout] = useState(false)

  const bookType = useRef<BookType>('regular')

  const previousCheckoutArgs = useRef<Parameters<typeof handleCheckout>>()

  const onPreviousCheckout = () => {
    const previousArgs = previousCheckoutArgs.current
    if (!previousArgs) {
      return
    }
    handleCheckout(...previousArgs)
  }

  const tripDatesAlignment = useAlignDates({
    onCheckout: onPreviousCheckout,
    bookingId,
  })

  const rebookTripErrors = bookTripAgainService.rebookErrors(bookingId)

  const segmentsToDisplay = useMemo<SegmentToDisplay[]>(() => {
    if (!tripDatesAlignment.errors.length && !rebookTripErrors.length) {
      return segments
    }

    const firstFlightIndex = segments.findIndex(segmentTypeCheckActions.isFlightSegment)

    if (firstFlightIndex === -1 && tripDatesAlignment.errors.length) {
      return segments
    }

    const result: SegmentToDisplay[] = [...segments]
    const alignErrors: FailedAlignError[] = tripDatesAlignment.errors.map((error) => ({
      ...error,
      type: 'FailedAlignError',
    }))

    const rebookErrors: FailedRebookError[] = rebookTripErrors.map((error) => ({
      ...error,
      type: 'FailedRebookError',
    }))

    const preRes = result.slice(0, firstFlightIndex + 1)

    if (tripDatesAlignment.errors.length) {
      return preRes.concat(alignErrors, result.slice(firstFlightIndex + 1))
    }

    if (rebookErrors[0].travelVertical === 'Air') {
      return [...rebookErrors, ...result]
    }

    return preRes.concat(rebookErrors, result.slice(firstFlightIndex + 1))
  }, [
    segmentTypeCheckActions.isFlightSegment,
    segments,
    tripDatesAlignment.errors,
    rebookTripErrors,
  ])

  const handleCheckout = (newBookType: BookType) => {
    checkoutInfoActions.dropCheckoutInfo() // clean up check info before checkout

    previousCheckoutArgs.current = [newBookType]
    bookType.current = newBookType
    if (
      railSegments.length &&
      ((currentSeatReservationType.includes(SeatReservationType.Optional) && !isSkipped) ||
        currentSeatReservationType.includes(SeatReservationType.Mandatory)) &&
      !isUpdatedSeatPreferences
    ) {
      seatPreferencesToggle.handleOpen()
      return false
    }

    if (
      featureFlagsStore.flags.isTripAlignmentWorkflowEnabled &&
      tripDatesAlignment.hasMismatchedSegments &&
      !tripDatesAlignment.isAlignTripAlertShown
    ) {
      tripDatesAlignment.handleOpenAlignDatesAlert()
      return false
    }
    setActivatedCheckout(true)
    if (!isOopResolved) {
      outOfPolicyToggle.handleOpen()
      return false
    }
    if (overlappingTrips?.length && !errorsTripToggle.isOpen) {
      errorsTripToggle.handleOpen()
      return false
    }

    if (railSegments.length && !isUpdatedSeatPreferences) {
      onPreferencesApply()
    }

    const isHoldFlow = newBookType === 'hold'

    history.push(
      ROUTES.checkout({
        itineraryId,
        bookingId,
        checkoutStep: 'traveler-info',
        isHoldFlow: isHoldFlow || undefined,
      }),
    )
  }

  const onHold = () => handleCheckout('hold')

  const onCheckout = () => handleCheckout('regular')

  const onProceedToCheckout = () => {
    if (bookType.current === 'regular') {
      onCheckout()
      return
    }
    onHold()
  }

  const {
    totalPriceChange,
    isTotalPriceChangePositive,
    postPurchaseActions,
  } = useChangeTripDetails({
    isTripExist,
    tripDetails,
    bookingId,
    itineraryCosts: tripCost?.total,
    areSegmentsChangeable,
  })

  const { addButtonSettings } = useAddButtonSettings({
    itineraryId,
    availableSegments,
    segments,
    bookingId,
    isMultiCity: tripDetails.isMultiCity,
  })

  const onAbandonTripChanges = useCallback(() => {
    if (isAbleToCheckout) {
      startOverModal.handleOpen()
      return
    }

    if (bookingId) {
      history.push(
        ROUTES.postBooking.trip({ id: bookingId, transactionGroupId, isForceRefetch: false }),
      )
      dispatch(resetTripReviewParams())
    }
  }, [isAbleToCheckout, bookingId, dispatch, startOverModal, history, transactionGroupId])

  const areTooManySegments = segments.length >= MAXIMUM_SEGMENTS_COUNT
  const totalCost = tripCost?.totalWithUnusedTicket || tripCost?.total

  useEffect(() => {
    if (airPriceChangeInfo?.rate?.primary && airPriceChangeInfo?.type) {
      airPriceChangeToggle.handleOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airPriceChangeInfo])

  const [
    airPriceChangeInfoState,
    setAirPriceChangeInfoState,
  ] = useState<PriceChangeValueObject | null>(null)

  const handleAirPriceChangeClose = () => {
    airPriceChangeToggle.handleClose()
    if (airPriceChangeInfo && airPriceChangeInfo.type) {
      setAirPriceChangeInfoState(airPriceChangeInfo)
    }
    airPriceChangeActions.dropPriceChange()
  }

  const handleDuplicatedTripClick = (id?: number) => {
    if (!id) {
      return
    }
    history.push(ROUTES.postBooking.trip({ id: String(id), isForceRefetch: true }))
  }

  const {
    tripEmissionsResult,
    loading: areTripEmissionLoading,
  } = useCalculateItinerarySegmentsEmissions(segments)

  const { handleChangeFlights } = useChangeFlight({
    itineraryId,
    flightId: flightId!,
    flightSegments,
    handleCloseChangeModal: () => {},
  })

  const handleClickMultipleHotelProviders = () => {
    history.replace(ROUTES.support.main)
    multipleHotelProvidersRTPDialog.handleClose()
  }

  const { seatMapActions, seatMapPaginationActions } = useSeatMapContext()
  useEffect(() => {
    if (flightSegments.length) {
      seatMapActions.setFlightLegsFromItinerary(flightSegments)
    }
    return () => {
      seatMapActions.handleDropStore()
      seatMapPaginationActions.handleDropStore()
    }
  }, [flightSegments, seatMapActions, seatMapPaginationActions])

  const rtpSeatMapModalToggle = useTogglePopup()
  const rtpSeatModalLvl2Toggle = useTogglePopup()

  const onExpire = () => {
    if (startOverModal.isOpen) {
      startOverModal.handleClose()
    }
  }

  const { tripExpiredModalSlot, isTripRemovingLoading } = useTripExpiredModal()
  const {
    itineraryRules,
    rulesAndRestrictionsToggle,
    showRulesAndRestrictions,
  } = useRulesAndRestrictions({ segments })

  return {
    isAbleToCheckout,
    areTooManySegments,
    messages,
    overlappingTrips,
    firstHotel,
    oopSelections,
    tripCostSummaryToggle,
    addButtonSettings,
    segments,
    availableSegments,
    tripCost,
    totalCost,
    onHold,
    isHoldTripAllowed,
    onCheckout,
    onProceedToCheckout,
    isLoading:
      isLoading || isTripDetailsLoading || isRailPreferencesLoading || isTripRemovingLoading,
    tripData,
    isTripExist,
    descriptions,
    isCheckoutActivated,
    isPolicyExplanationRequired,
    travelPolicy,
    outOfPolicyToggle,
    itineraryId,
    flightId,
    flightSegments,
    onAbandonTripChanges,
    tooManySegmentsToggle,
    totalPriceChange,
    isTotalPriceChangePositive,
    postPurchaseActions,
    airPriceChangeToggle,
    airPriceChangeInfo,
    carPriceChangeToggle,
    carPriceChangeInfo,
    flightCost,
    carRentalCost,
    isMultiCity,
    errorsTripToggle,
    handleDuplicatedTripClick,
    isShowDelegateName,
    startOverModalSlot,
    currentDelegatedUser,
    airPriceChangeInfoState,
    handleAirPriceChangeClose,
    handleUpdateTripName,
    bookingId,
    railSegments,
    tripEmissionsResult,
    editNameToggle,
    areTripEmissionLoading,
    appliedPreferences,
    onPreferencesApply,
    seatPreferencesToggle,
    temporaryCurrentLayout,
    preferenceOptions: currentSelectedPreferenceOptions,
    seatReservationType: currentSeatReservationType,
    currentSelectedSeatsLabel,
    railViewStateParams,
    railPreferencesError,
    isRailPreferenceOptionsExist,
    handleChangeFlights,
    isRailEticket,
    isHotelRequiredForOvernightTrip,
    isCarRentalRequiredViolation,
    tripExpiredModalSlot,
    tripDatesAlignment,
    isAppleBannerShown:
      featureFlagsStore.flags.isAppleBannerEnabled && brandConfigurationStore.isAppleTheme,
    addSegmentModalsStore,
    rtpSeatMapModalToggle,
    rtpSeatModalLvl2Toggle,
    seatMapPaginationActions,
    segmentsToDisplay,
    isMobile,
    isBookTripAgainFailed: bookTripAgainStore.isBookTripAgainFailed,
    multipleHotelProvidersRTPDialog,
    handleClickMultipleHotelProviders,
    appliedRailCard,
    onSkip,
    onExpire,
    rulesAndRestrictionsToggle,
    itineraryRules,
    showRulesAndRestrictions,
  }
}
