import styled from 'styled-components'
import { SeatMapSegment } from '../../seat-map-segment'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;

  && > * {
    width: 100%;
    height: 100%;
  }
`

export const StyledSeatMapSegment = styled(SeatMapSegment)`
  align-items: baseline;
  && {
    display: flex;
    justify-content: center;
    height: auto;
  }
`

export const Overlay = styled.div<{ height: string }>`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(p) => p.height};
  background-color: rgba(16, 24, 32, 0.7);
  color: ${(p) => p.theme.colors.mainText2};
`
