import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { RailDeliveryOptionLayout } from './layout'
import { RailDeliveryGuideModal } from './rail-delivery-guide-modal'
import type { ContentProps } from './types'

export function RailDeliveryOptionContent(props: ContentProps) {
  const deliveryOptionToggle = useTogglePopup()

  return (
    <>
      <RailDeliveryOptionLayout
        {...props}
        isEticket={props.isEticket}
        stationName={props.stationName}
        isStationTicketMachineDisabled={props.isStationTicketMachineDisabled}
        onModalOpen={deliveryOptionToggle.handleOpen}
      />
      <CheckoutPageModal
        isVisible={deliveryOptionToggle.isOpen}
        onClose={deliveryOptionToggle.handleClose}>
        <RailDeliveryGuideModal
          isEticket={!!props.isEticket}
          onClose={deliveryOptionToggle.handleClose}
        />
      </CheckoutPageModal>
    </>
  )
}
