import { observer } from 'mobx-react-lite'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { SelectExpenseCodeLayout } from './layout'

export const SelectExpenseCode = observer(function SelectExpenseCode() {
  const {
    selectExpenseCodeActions,
    selectExpenseCodeStore,
    expenseFlowStore,
  } = useRideHailSearchContext()

  return (
    <SelectExpenseCodeLayout
      onBack={selectExpenseCodeActions.handleBack}
      onAddCustomExpenseCode={selectExpenseCodeActions.handleAddCustomExpenseCode}
      onContinue={selectExpenseCodeActions.handleContinue}
      isContinueButtonDisabled={selectExpenseCodeStore.isContinueButtonDisabled}
      isShowEnterCustomExpenseCode={expenseFlowStore.expenseConfig.customExpenseEnabled}
      expenseCodes={selectExpenseCodeStore.expenseCodes}
      selectedExpenseCodeId={selectExpenseCodeStore.selectedExpenseCodeId}
      searchTextValue={selectExpenseCodeStore.searchExpenseCodeValue}
      onSearchTextValueChanged={selectExpenseCodeActions.handleSearchTextValueChanged}
      onExpenseCodeSelected={selectExpenseCodeActions.handleExpenseCodeSelected}
    />
  )
})
