import type { GetRailETicketsQuery } from '@fiji/graphql/types'
import type { RailEticketsValueObject } from '../../core/value-objects/rail-e-tickets.value-object'
import { RailETicketTypeEnum } from '../types'

export function toRailETicketsValueObject(
  query: GetRailETicketsQuery,
  type: RailETicketTypeEnum,
): RailEticketsValueObject {
  const railEtickets = query.getRailETickets
  // Right now we are managing only pdf e-tickets
  const base64Type = RailETicketTypeEnum.Pdf === type ? 'data:application/pdf;base64,' : ''
  return {
    tickets: railEtickets.tickets.map((eTicket) => base64Type.concat(eTicket)),
  }
}
