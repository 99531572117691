import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { useTripDropActions } from '@etta/screens/trip-confirmation/hooks/use-new-trip-confirmation/use-trip-drop-actions'
import { usePreventBack } from '@fiji/hooks/use-prevent-navigation'
import { ROUTES } from '@fiji/routes'
import { BookingAnimationContainerLayout } from './layout'
import { useLottieAnimation } from './use-lottie-animation'
import { useBookingAnimationContainer } from './use-booking-animation-container'

type Props = {
  isHoldFlow?: boolean
  isPostBookingFlow?: boolean
}

export const BookingAnimationContainer = observer(function BookingAnimationContainer({
  isHoldFlow,
  isPostBookingFlow,
}: Props) {
  const {
    flights,
    hotels,
    carRentals,
    rails,
    tripTitle,
    tripDateRange,
    travelerFullName,
    isNewLoadingConfirmationEnabled,
    bookingEntitiesKeys,
  } = useBookingAnimationContainer({
    isHoldFlow,
    isPostBookingFlow,
  })

  const {
    animationLabel,
    setFlightAnimation,
    setCarRentalAnimation,
    setHotelAnimation,
    setTrainAnimation,
  } = useLottieAnimation({ bookingEntitiesKeys, isHoldFlow, isPostBookingFlow })

  const history = useHistory()
  const { dropTripAndPostBooking } = useTripDropActions()

  const onPreventBack = () => {
    dropTripAndPostBooking()
    setTimeout(() => {
      history.replace(ROUTES.explore)
    }, 0)
  }

  // See https://deem.atlassian.net/browse/FRI-1661
  // Prevent users from rebooking a trip by pressing back button
  usePreventBack({
    isBlocking: true,
    onPrevent: onPreventBack,
  })

  return (
    <BookingAnimationContainerLayout
      flights={flights}
      hotels={hotels}
      carRentals={carRentals}
      rails={rails}
      tripTitle={tripTitle}
      tripDateRange={tripDateRange}
      travelerFullName={travelerFullName}
      animationLabel={animationLabel}
      isNewLoadingConfirmationEnabled={isNewLoadingConfirmationEnabled}
      setFlightAnimation={setFlightAnimation}
      setCarRentalAnimation={setCarRentalAnimation}
      setHotelAnimation={setHotelAnimation}
      setTrainAnimation={setTrainAnimation}
    />
  )
})
