import { SegmentType } from '@fiji/graphql/types'
import { useRailSearchQuery } from '@fiji/hooks/search-queries/use-rail-search-query/use-rail-search-query'
import { useRailTimeConfiguration } from '@fiji/hooks/time-configuration/use-rail/use-rail-time-configuration'
import { useAppDispatch } from '@fiji/store'
import { TimeRangeDirection } from '@fiji/enums'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { appMode } from '@fiji/modes'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { ROUTES } from '@fiji/routes'
import { getQueryParamsRTP } from './get-query-params'

type Args = {
  itineraryId: string
  segments: TripSegmentValueObject[]
  bookingId?: string
}

export function useTrainPlaceRTP({ itineraryId, segments, bookingId }: Args) {
  const { navigateTo } = useRailSearchQuery({})
  const { getInitialTimeRange } = useRailTimeConfiguration()
  const dispatch = useAppDispatch()

  const onAddTrain = () => {
    const url = !appMode.isEttaMobile ? ROUTES.explore : ROUTES.rail.search
    const params = getQueryParamsRTP({
      segments,
      forType: SegmentType.Train,
    })

    dispatch(updateTripReviewStatus('update'))
    navigateTo(url, {
      itineraryId,
      bookingId,
      originDate: params?.startDate,
      destinationDate: params?.endDate,
      originTime: getInitialTimeRange(TimeRangeDirection.Departure),
      destinationTime: getInitialTimeRange(TimeRangeDirection.Arrival),
    })
  }

  return {
    onAddTrain,
  }
}
