import { Service } from '@etta/di'
import { trackAction } from '@fiji/utils/tracking'

type TrackArgs = {
  name: string
  status: 'success' | 'error'
  message?: string[] | string
}

@Service()
export class ActionTrackingService {
  track({ status, message, name }: TrackArgs) {
    const trackMessage = Array.isArray(message) ? message.join(', ') : message
    trackAction(name, {
      status,
      message: trackMessage,
    })
  }
}
