export default function AudioProgrammingIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9811 8C29.2114 8 35.0789 13.8581 35.0789 21.0306V22.9458C37.8044 23.2086 39.9621 25.4618 40 28.2406C40 31.2072 37.5773 33.573 34.6246 33.573H34.0946V33.7608C34.0946 34.4367 33.5268 35 32.8454 35H31.8612C31.1798 35 30.612 34.4367 30.612 33.7608V22.7204C30.612 22.0445 31.1798 21.4812 31.8612 21.4812H32.3155V21.0306C32.3155 15.3602 27.6972 10.7789 21.9811 10.7789C16.265 10.7789 11.6467 15.3978 11.6467 21.0306V21.4812H12.1009C12.7823 21.4812 13.3502 22.0445 13.3502 22.7204V33.7608C13.3502 34.4367 12.7823 35 12.1009 35H11.1167C10.4353 35 9.86751 34.4367 9.86751 33.7608V33.573H9.37539C6.38486 33.573 4 31.1697 4 28.2406C4 25.4618 6.15773 23.2086 8.88328 22.9458V21.0306C8.88328 13.8581 14.7508 8 21.9811 8ZM17.0806 24.3391C17.8377 24.3391 18.4515 24.9487 18.4515 25.7007V32.0548C18.4515 32.8068 17.8377 33.4164 17.0806 33.4164H17.0772C16.3201 33.4164 15.7063 32.8068 15.7063 32.0548V25.7007C15.7063 24.9487 16.3201 24.3391 17.0772 24.3391H17.0806ZM26.6886 24.3391C27.4457 24.3391 28.0595 24.9487 28.0595 25.7007V32.0548C28.0595 32.8068 27.4457 33.4164 26.6886 33.4164H26.6852C25.9281 33.4164 25.3143 32.8068 25.3143 32.0548V25.7007C25.3143 24.9487 25.9281 24.3391 26.6852 24.3391H26.6886ZM21.8829 26.1546C22.5146 26.1546 23.0267 26.6632 23.0267 27.2907V30.4649C23.0267 31.0923 22.5146 31.601 21.8829 31.601C21.2512 31.601 20.7391 31.0923 20.7391 30.4649V27.2907C20.7391 26.6632 21.2512 26.1546 21.8829 26.1546Z"
        fill="currentColor"
      />
    </svg>
  )
}
