import styled from 'styled-components'

export const HotelDetailsBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
`

export const HotelDetailsBodyWhite = styled(HotelDetailsBody)`
  background-color: ${(props) => props.theme.colors.white};
  padding-bottom: 16px;
`
