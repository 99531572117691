import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  bottom: 22px;
`

export const ChangeFlightHeader = styled.div`
  position: relative;
  padding: 26px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
`

export const SelectFlightButtonWrapper = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: 16px 20px 40px 20px;
`

export const AddFlightButtonWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  background-color: ${(props) => props.theme.colors.background};
  padding: 20px 18px;
  border-radius: 10px;
  ${(props) => {
    if (props.isDisabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `
    }
    return 'cursor: pointer;'
  }}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
