import { TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { SearchFlightLegSubSegment } from '@fiji/graphql/types'
import type { AdaptedFlightSegment } from '../../types'
import { getLayover } from '../../get-layover'

export function getAdaptedSegments(segments: SearchFlightLegSubSegment[]): AdaptedFlightSegment[] {
  return segments.map<AdaptedFlightSegment>((segment, index, array) => {
    const nextSegment = array[index + 1]

    return {
      id: segment.id,
      carrierCode: segment.carrier?.id || '',
      carrierName: segment.carrier?.name || '',
      departureAirportCode: segment.origin.code,
      departureFullDate: segment.departureDate,
      departureTime: tripReviewFormatDate(
        TripReviewDateType.TimePWA,
        segment.departureDate,
      ) as string,
      departureDate: tripReviewFormatDate(
        TripReviewDateType.DayAndDate,
        segment.departureDate,
      ) as string,
      departureCity: segment.origin.city,
      departureAirportName: segment.origin.name,
      departureTerminal: segment.origin.terminal,
      arrivalAirportCode: segment.destination.code,
      arrivalFullDate: segment.arrivalDate,
      arrivalTime: tripReviewFormatDate(TripReviewDateType.TimePWA, segment.arrivalDate) as string,
      arrivalDate: tripReviewFormatDate(
        TripReviewDateType.DayAndDate,
        segment.arrivalDate,
      ) as string,
      arrivalCity: segment.destination.city,
      arrivalAirportName: segment.destination.name,
      arrivalTerminal: segment.destination.terminal,
      airlineLogo: segment.carrier?.imageUrl ?? null,
      airlineLogoSquared: segment.carrier?.logoImageUrl ?? null,
      flightNumber: `${segment.carrier?.name ?? ''} ${segment.flightNumber}`,
      rawFlightNumber: segment.flightNumber,
      aircraft: segment.aircraft?.name ?? null,
      flightTime: segment.flightTime,
      amenities: segment?.amenities ?? [],
      operatedBy: segment?.codeshareCarrier?.name ?? null,
      layover: getLayover(segment.arrivalDate, nextSegment?.departureDate),
      isSeatMapAvailable: segment.isSeatMapAvailable,
      isPreferredAny: segment.isPreferredAny,
      isInstantPurchaseCarrier: segment.isInstantPurchaseCarrier,
    }
  })
}
