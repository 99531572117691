import styled from 'styled-components'
import { withStyledText } from '@fiji/style/text-styles'
import { Radio as MobileRadio } from '@etta/ui/radio'

export const Container = styled.div`
  height: 100%;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
`

export const TabLabel = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  margin-bottom: 4px;
  ${withStyledText};
`

export const AirportTitle = styled.div`
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.mainText};
  ${withStyledText};
`
export const TimeLabel = styled.div`
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.mainText};
  ${withStyledText};
`
export const TimeSelectorContainer = styled.div`
  margin-right: -16px;
  margin-bottom: 16px;
`

export const Radio = styled(MobileRadio)`
  margin: 18px 6px;
`

export const InputContainer = styled.div<{ isCustomTimeSelected: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  max-height: ${(props) => (props.isCustomTimeSelected ? `131px` : `56px`)};
  transition: all 250ms ease-out;
  overflow: ${({ isCustomTimeSelected }) => (isCustomTimeSelected ? '' : 'hidden')};
`

export const SubmitButton = styled.div`
  margin-top: 135px;
  padding: 16px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.mainText2};
  background-color: ${(props) => props.theme.colors.primary};
  ${withStyledText};
  &:active {
    background-color: ${(props) => props.theme.colors.primary2};
  }
`
export const SliderContainer = styled.div`
  width: inherit;
  padding: 0px 32px 0px 17px;
  margin-bottom: 25px;
`
