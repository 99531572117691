import { UnusedTicketExplanationLayout } from '../../../unused-ticket-explanation'
import type { Args } from '../../types'
import { Content, Wrapper } from './unused-tickets-desktop-styled'
import { UnusedTicketsContentDesktop } from './unused-tickets-content-desktop'

export function UnusedTicketsDesktop({ unusedTickets, isLoading, isWideSettingsModal }: Args) {
  return (
    <Wrapper>
      <UnusedTicketExplanationLayout isWideSettingsModal={isWideSettingsModal} />
      <Content>
        <UnusedTicketsContentDesktop isLoading={isLoading} unusedTickets={unusedTickets} />
      </Content>
    </Wrapper>
  )
}
