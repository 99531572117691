import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import { IconButton } from '@etta/ui/icon-button'
import type { LayoutProps } from '../types'
import {
  Title,
  Footer,
  ExpenseCodeSearchTextField,
  Border,
  ExpenseCodeList,
} from './mobile-components'
import { ExpenseCodeRow } from './expense-code-row'

const i18nBase = 'Mobility.SelectExpenseCodePage.'

export const SelectExpenseCodeMobile = ({
  onBack,
  onAddCustomExpenseCode,
  onContinue,
  isContinueButtonDisabled,
  isShowEnterCustomExpenseCode,
  expenseCodes,
  selectedExpenseCodeId,
  searchTextValue,
  onSearchTextValueChanged,
  onExpenseCodeSelected,
}: LayoutProps) => {
  const { t } = useTranslation()

  return (
    <Screen>
      <Screen.Header>
        <Header
          layout="sub-title"
          backgroundColor="white"
          leftSlot={<Header.BackButton color="mainText" onClick={onBack} />}
          rightSlot={
            isShowEnterCustomExpenseCode ? (
              <IconButton
                icon="addPWA"
                size="medium"
                color="primary"
                onClick={onAddCustomExpenseCode}
              />
            ) : undefined
          }>
          <Title>{t(i18nBase + 'Title')}</Title>
        </Header>
        <ExpenseCodeSearchTextField
          type="search"
          value={searchTextValue}
          onChange={onSearchTextValueChanged}
          size="small"
          placeholder={t(i18nBase + 'SearchTextFieldPlaceholder')}
        />
        <Border />
      </Screen.Header>
      <Screen.Container>
        <ExpenseCodeList>
          {expenseCodes.map((expenseCode) => {
            return (
              <ExpenseCodeRow
                key={expenseCode.id}
                expenseCode={expenseCode}
                onClick={() => {
                  onExpenseCodeSelected(expenseCode.id)
                }}
                isSelected={expenseCode.id === selectedExpenseCodeId}
              />
            )
          })}
        </ExpenseCodeList>
      </Screen.Container>
      <Screen.Footer withBorder backgroundColor="white">
        <Footer>
          <Button fullWidth minWidth={14} onClick={onContinue} disabled={isContinueButtonDisabled}>
            {t(i18nBase + 'ContinueButton')}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
