import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const Container = styled.div`
  border-radius: 14px;
  border: 1px solid ${(p) => p.theme.colors.error};
  background-color: ${(p) => p.theme.colors.white};
  padding: 16px 24px 16px 16px;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`

export const SearchButton = styled(Button)`
  height: 44px;
  margin-top: 24px;
`
