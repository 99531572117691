export default function UpgradeIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.992 5C31.3304 5 39 12.6696 39 22.008C39 31.3464 31.3304 39 22.008 39C12.6697 39 5 31.3464 5 22.008C5 12.6696 12.6536 5 21.992 5ZM22 13.7143C21.3966 13.7143 20.8988 13.9275 20.4765 14.3998L15.2121 19.9598C14.85 20.3406 14.7143 20.691 14.7143 21.1937C14.7143 22.1076 15.378 22.7627 16.3132 22.7627C16.7808 22.7627 17.2485 22.5494 17.5049 22.2295L19.1642 20.3711L20.3256 18.7869L20.1295 21.9401V28.9473C20.1295 29.9679 20.9441 30.7143 22 30.7143C23.0408 30.7143 23.8554 29.9679 23.8554 28.9473V21.9401L23.6744 18.7869L24.8358 20.4016L26.48 22.2295C26.7666 22.5647 27.2192 22.7627 27.6717 22.7627C28.622 22.7627 29.2857 22.1076 29.2857 21.1937C29.2857 20.691 29.1349 20.3406 28.7879 19.9598L23.5235 14.3998C23.1012 13.9428 22.6034 13.7143 22 13.7143Z"
        fill="currentColor"
      />
    </svg>
  )
}
