import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { isPast } from 'date-fns'
import type { TripDetailsPostReservationValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Icon } from '@etta/ui/icon'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useTripModifyModal } from '@fiji/hooks/use-trip-modify-modal'
import { ChangeTripNotAllowedModal } from '@etta/components/change-trip-not-allowed-modal'
import { BookTripAgainModal } from '@etta/modules/post-booking/ui/book-trip-again/book-trip-again-modal/book-trip-again-modal'
import { ActionButton, ButtonContainer } from './button-group-styled'
import { useDetailsButtonGroup } from './use-details-button-group'

type Props = {
  onShare: () => void
  onModify: () => void
  onETicketsDownloading: () => void
  isUpcoming: boolean
  supportedActions: TripDetailsPostReservationValueObject
  tripStartDate: string
  isMultiCityTrip: boolean
  tripName: string
  isOnHold?: boolean
  onOpenRulesAndRestrictions: () => void
  showRulesAndRestrictions: boolean
  printItineraryContent?: () => void
}

const i18Base = 'PostBooking.TripDetails'

export const ButtonGroup = observer(function ButtonGroup({
  onShare,
  onModify,
  onETicketsDownloading,
  isUpcoming,
  supportedActions,
  tripStartDate,
  isMultiCityTrip,
  tripName,
  isOnHold,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
  printItineraryContent,
}: Props) {
  const { t } = useTranslation()
  const {
    postBookingCancellationActions,
    bookTripAgainStore,
    bookTripAgainService,
    postBookingTripStore,
  } = usePostBookingContext()
  const { onCancelTrip } = postBookingCancellationActions
  const share = t(i18Base + '.Share')
  const modifyTrip = t(i18Base + '.ModifyTrip')
  const cancelTrip = t(i18Base + '.CancelTrip')
  const bookTripAgain = t(i18Base + '.BookTripAgain')
  const downloadETickets = t(i18Base + '.DownloadETickets')
  const { flightSegments, hasTrainSegmentsWithETicket } = postBookingTripStore
  const hasReturnFlight = flightSegments.length > 1
  const isReturnFlightStarted =
    hasReturnFlight && flightSegments[1].departureDateTime
      ? isPast(new Date(flightSegments[1].departureDateTime))
      : false
  const { isModifyButtonEnabled } = useDetailsButtonGroup({
    supportedActions,
    tripStartDate,
    isMultiCityTrip,
    hasReturnFlight,
    isReturnFlightStarted,
  })

  const { modalAction, handleViewSupport, toggleModifyModal } = useTripModifyModal({
    isAllowed: isModifyButtonEnabled,
    modifyAction: onModify,
  })

  const {
    flightSegment,
    hotelSegment,
    carRentalSegment,
  } = bookTripAgainService.tripToBookAgainSegments

  return (
    <>
      <ButtonContainer data-tracking-id="share-trip-button">
        <ActionButton
          onClick={onShare}
          aria-label={t(`${i18Base}.ShareAriaLabel`, { label: share })}>
          <div>{share}</div>
          <Icon name="sharePWA" size="medium" />
        </ActionButton>
      </ButtonContainer>

      {isUpcoming && (
        <>
          {hasTrainSegmentsWithETicket && (
            <ButtonContainer data-tracking-id="download-e-ticket-trip-button">
              <ActionButton
                onClick={onETicketsDownloading}
                isDownload
                aria-label={t(`${i18Base}.ShareAriaLabel`, { label: downloadETickets })}>
                <div>{downloadETickets}</div>
                <Icon name="eticketPWA" size="medium" />
              </ActionButton>
            </ButtonContainer>
          )}
          <ButtonContainer data-tracking-id="print-itinerary-button">
            <ActionButton onClick={printItineraryContent}>
              <div>{t(`PostBooking.TripDetails.PrintItinerary.PrintItinerary`)}</div>
              <Icon name="printPWA" size="medium" />
            </ActionButton>
          </ButtonContainer>
          {!isOnHold && (
            <ButtonContainer data-tracking-id="modify-trip-button">
              <ActionButton
                onClick={modalAction}
                aria-label={t(`${i18Base}.ShareAriaLabel`, { label: modifyTrip })}>
                <div>{modifyTrip}</div>
                <Icon name="editPWA" size="medium" />
              </ActionButton>
            </ButtonContainer>
          )}
          {showRulesAndRestrictions && (
            <ButtonContainer data-tracking-id="rules-and-restrictions-button">
              <ActionButton onClick={onOpenRulesAndRestrictions}>
                <div>{t(`TripReview.RulesAndRestrictions.title`)}</div>
                <Icon name="documentPWA" size="medium" />
              </ActionButton>
            </ButtonContainer>
          )}
          <ButtonContainer data-tracking-id="cancel-trip-button">
            <ActionButton
              onClick={onCancelTrip}
              isCancel
              aria-label={t(`${i18Base}.ShareAriaLabel`, { label: cancelTrip })}>
              <div>{cancelTrip}</div>
              <Icon name="cancelTripPWA" size="medium" />
            </ActionButton>
          </ButtonContainer>
        </>
      )}

      {bookTripAgainStore.isPostBookingBookAgainAllowed && (
        <ButtonContainer data-tracking-id="book-trip-again-button">
          <ActionButton
            onClick={() => bookTripAgainStore.bookTripAgainToggle.handleOpen()}
            aria-label={t(`${i18Base}.ShareAriaLabel`, { label: bookTripAgain })}>
            <div>{bookTripAgain}</div>
            <Icon name="tripPWA" size="medium" />
          </ActionButton>
        </ButtonContainer>
      )}

      <ChangeTripNotAllowedModal
        isOpen={toggleModifyModal.isOpen}
        onClose={toggleModifyModal.handleClose}
        onViewSupport={handleViewSupport}
        modalTitle={modifyTrip}
      />

      <BookTripAgainModal
        isOpen={bookTripAgainStore.bookTripAgainToggle.isOpen}
        onClose={bookTripAgainStore.bookTripAgainToggle.handleClose}
        tripName={tripName}
        flightSegment={flightSegment}
        hotelSegment={hotelSegment}
        carRentalSegment={carRentalSegment}
      />
    </>
  )
})
