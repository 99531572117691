import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ListTypes } from '@etta/ui/drop-down-list'
import { CarRentalFiltersType } from '@fiji/enums'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { CarRentalOfficeDropdown } from '../car-rental-office-dropdown'
import { FilterContainer } from '../filter-container'
import type { FilterProps } from '../types'
import { TransmissionFilterWrapper } from './transmission-filter-styled'
import { useTransmissionFilter } from './use-transmission-filter'
import { TranmissionOptions } from './transmission-options'

export const TransmissionFilter = observer(function TransmissionFilter({
  isDropdownFilter = true,
}: FilterProps) {
  const {
    isCarRentalsNewFiltersEnabled,
    handleChangeTransmission,
    transmission,
    carFiltersStore,
    carRentalFilterActions,
    label,
    carRentalSearchStore,
    transmissionValue,
    isClearDisabled,
  } = useTransmissionFilter()
  const { t } = useTranslation()
  const options = transmission.options.map((option) => ({
    ...option,
    label: t(`CarRentalFilters.ListSection.Transmission.${option.label}`),
  }))

  if (!isCarRentalsNewFiltersEnabled) {
    return (
      <TransmissionFilterWrapper>
        <CarRentalOfficeDropdown
          label={label}
          data-tracking-id="transmission-filter-dropdown"
          onChange={handleChangeTransmission}
          options={options}
          filters={transmission.options}
          defaultLabel={t('CarRentalFilters.DefaultValues.Any')}
          type={ListTypes.Radio}
          filtersType={CarRentalFiltersType.Transmission}
          value={transmission.selectedOptions}
        />
      </TransmissionFilterWrapper>
    )
  }

  if (isDropdownFilter) {
    return (
      <TransmissionFilterWrapper>
        <QuickFilterLayout
          data-tracking-id="transmission-filter-dropdown"
          isOpen={carFiltersStore.transmissionModal.isOpen}
          onOpen={carFiltersStore.transmissionModal.handleOpen}
          onClose={carFiltersStore.transmissionModal.handleClose}
          onClear={carRentalFilterActions.clearHandlers.clearTransmission}
          onApply={carRentalFilterActions.handleApplyTransmissionFilters}
          label={label}
          value={transmissionValue}
          isShimmerLoading={carRentalSearchStore.isLoading && !carRentalSearchStore.carRentals}
          isLoading={carRentalSearchStore.isLoading}
          selectedNumber={carFiltersStore.selectedFilters.transmission.length}
          isApplyButtonDisabled={carFiltersStore.isEqual}
          isClearButtonDisabled={isClearDisabled}>
          <TranmissionOptions />
        </QuickFilterLayout>
      </TransmissionFilterWrapper>
    )
  }

  return (
    <FilterContainer
      title={label}
      selectedNumber={carFiltersStore.selectedFilters.transmission.length}
      isClearButtonDisabled={isClearDisabled}
      onClear={carRentalFilterActions.clearHandlers.clearTransmission}>
      <TranmissionOptions optionsPaddingVertical={11} withBottomBorder={false} />
    </FilterContainer>
  )
})
