import { SupportPage } from '@etta/screens/support-page'
import { SupportTravelPage } from '@etta/screens/support-travel-page'
import { TravelSupportArticle } from '@etta/modules/travel-support/ui/travel-support-article'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const supportRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.support.main,
      exact: true,
    },
    documentTitle: getDocumentTitle('Support'),
    transitionIndex: 6,
    transitionGroup: 'fade-through',
    component: () => <SupportPage />,
  },
  {
    route: {
      path: ROUTES.support.travel,
      exact: true,
    },
    documentTitle: getDocumentTitle('Support'),
    transitionIndex: 6.1,
    transitionGroup: 'slide',
    component: () => <SupportTravelPage />,
  },
  {
    route: {
      path: ROUTES.support.article({}),
      exact: true,
    },
    transitionIndex: 6.2,
    documentTitle: getDocumentTitle('Support'),
    transitionGroup: 'slide',
    component: () => <TravelSupportArticle />,
    isNavBarHidden: true,
  },
]
