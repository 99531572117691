import { getNodes } from './get-nodes'
import type { FocusElement } from './types'

export function setFocusInContainer(
  parentNode: FocusElement,
  prevElement: HTMLElement | null,
  nextElement: HTMLElement | null,
) {
  const nodes = getNodes(parentNode)

  if (nextElement) {
    const childIndex = nodes.findIndex((focusItem) => focusItem.node === nextElement)

    switch (childIndex) {
      case 0:
        nodes[nodes.length - 1]?.node.focus()
        break
      case nodes.length - 1:
        nodes[0]?.node.focus()
        break
      default:
        nextElement.focus()
    }
  } else {
    const nextFocus = prevElement || nodes[0]?.node
    nextFocus?.focus()
  }
}
