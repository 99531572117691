import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field/types'

export function getCustomFieldsBookingList(
  customFields: CustomFieldCollectionItemExtension[],
  values: Record<string, string>,
): CustomFieldBookingValueObject[] {
  return customFields.reduce<CustomFieldBookingValueObject[]>((acc, collection) => {
    return acc.concat(
      collection.customFields.reduce<CustomFieldBookingValueObject[]>(
        (collectionAcc, customField) => {
          if (values[customField.customUuid]) {
            collectionAcc.push({
              collectionId: collection.collectionId,
              name: customField.name,
              value: String(values[customField.customUuid]),
            })
          }
          return collectionAcc
        },
        [],
      ),
    )
  }, [])
}
