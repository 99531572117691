import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { AirSearchFormDesktopNew } from './desktop/air-search-form-desktop.new'
import { AirSearchFormMobile } from './mobile'

export function AirSearchFormLayout(props: LayoutProps) {
  return (
    <MediaLayout
      desktopSlot={<AirSearchFormDesktopNew {...props} />}
      mobileSlot={<AirSearchFormMobile {...props} />}
    />
  )
}
