import styled from 'styled-components'
import { boldWeightText, withStyledText } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  font-size: 2.4rem;
  color: ${(props) => props.theme.secondaryColor};
  line-height: 30px;
  padding: 12px 12px 12px 20px;
  ${boldWeightText};
  ${withStyledText};
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled(IconButton)`
  margin-left: auto;
`
