import { MediaLayout } from '@etta/ui/media-layout'
import type {
  LayoutContentProps,
  LayoutDueTodayProps,
  LayoutTextProps,
  LayoutTripTypeProps,
  LayoutFooterWrapperProps,
} from '../types'
import {
  Text as TextDesktop,
  DueToday as DueTodayDesktop,
  TripType as TripTypeDesktop,
  Content as ContentDesktop,
  FooterWrapper as FooterWrapperDesktop,
} from './desktop'
import {
  Text as TextMobile,
  DueToday as DueTodayMobile,
  TripType as TripTypeMobile,
  Content as ContentMobile,
  FooterWrapper as FooterWrapperMobile,
} from './mobile'

export function TextLayout(props: LayoutTextProps) {
  return (
    <MediaLayout mobileSlot={<TextMobile {...props} />} desktopSlot={<TextDesktop {...props} />} />
  )
}

export function DueTodayLayout(props: LayoutDueTodayProps) {
  return (
    <MediaLayout
      mobileSlot={<DueTodayMobile {...props} />}
      desktopSlot={<DueTodayDesktop {...props} />}
    />
  )
}

export function TripTypeLayout(props: LayoutTripTypeProps) {
  return (
    <MediaLayout
      mobileSlot={<TripTypeMobile {...props} />}
      desktopSlot={<TripTypeDesktop {...props} />}
    />
  )
}

export function FooterWrapperLayout(props: LayoutFooterWrapperProps) {
  return (
    <MediaLayout
      mobileSlot={<FooterWrapperMobile {...props} />}
      desktopSlot={<FooterWrapperDesktop {...props} />}
    />
  )
}

export function ContentLayout(props: LayoutContentProps) {
  return (
    <MediaLayout
      mobileSlot={<ContentMobile {...props} />}
      desktopSlot={<ContentDesktop {...props} />}
    />
  )
}
