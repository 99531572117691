import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { Text } from '@etta/ui/text'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { SupportContactGetHelpNowImage } from '../../support-contact-get-help-now-image'
import {
  GetHelpNowWrapper,
  InformationContainer,
  ButtonWrapper,
  ImageContainer,
  ButtonLink,
} from './support-contact-get-help-now-mobile-styled'

export function SupportContactGetHelpNowMobile() {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support.MainPage.GetHelpNow'
  const { userStore } = useUserContext()
  const {
    featureFlags: { isNewHelpCenterLinkEnabled },
  } = useFeatureFlags()
  const identityHash = userStore.profile?.identityHash
  const siteID = userStore.companyId
  const siteName = encodeURIComponent(userStore.profile?.site?.name || '')
  const domain = isNewHelpCenterLinkEnabled
    ? 'https://new-deem.document360.io'
    : 'https://new-etta.document360.io'

  return (
    <GetHelpNowWrapper>
      <InformationContainer>
        <Text variant="subHeadStrong">{t(i18Base + '.Title')}</Text>
        <Text variant="footnoteMedium">{t(i18Base + '.DescriptionMobile')}</Text>
        <ButtonLink href={`${domain}/?h=${identityHash}&si=${siteID}&sn=${siteName}`}>
          <ButtonWrapper size="small" minWidth={18}>
            {t(i18Base + '.ButtonLabel')}
          </ButtonWrapper>
        </ButtonLink>
      </InformationContainer>
      <ImageContainer>
        <SupportContactGetHelpNowImage />
      </ImageContainer>
    </GetHelpNowWrapper>
  )
}
