import { Inject, Service } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { CarSearchFormStore } from '@etta/modules/car-rental-search/interface/stores/car-search-form.store'
import type { CarSearchQueryType } from '@fiji/hooks/search-queries/use-car-search-query/types'

@Service()
export class CarSearchFormQueryService {
  constructor(
    @Inject()
    private readonly carSearchFormStore: CarSearchFormStore,
    @Inject()
    private readonly historyService: HistoryService,
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  navigateTo(route: string, params?: Partial<CarSearchQueryType>) {
    const nextPath = this.carSearchFormStore.carSearchQueryParams.getNextPath({
      route,
      search: this.historyStore.search,
      newQueryParams: params,
    })
    this.historyService.push(nextPath)
  }

  appendQueryParams(params: Partial<CarSearchQueryType>) {
    const nextPath = this.carSearchFormStore.carSearchQueryParams.getNextPath({
      route: this.historyStore.pathname,
      search: this.historyStore.search,
      newQueryParams: params,
    })

    this.historyService.replace(nextPath)
  }
}
