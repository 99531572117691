import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { Title } from './flight-segment-list-styled'

const i18nBase = 'AirSeatMap'

type Props = {
  legIndex: number
}

const DEFAULT_FIRST_INDEX = 1
const DEFAULT_LAST_INDEX = 1

export const FlightLegTitle = observer(function FlightCardListTitle({ legIndex }: Props) {
  const { t } = useTranslation()
  const { seatMapStore, seatMapPaginationStore } = useSeatMapContext()
  const { flightLegs } = seatMapStore
  const { selectedLegIndex, selectedSegmentIndex } = seatMapPaginationStore
  const isActive = legIndex === selectedLegIndex
  const segments = flightLegs[legIndex]?.segments || []
  const location = segments[segments.length - 1]?.location || ''
  const firstIndex = segments.length || DEFAULT_FIRST_INDEX
  const lastIndex = isActive ? selectedSegmentIndex + 1 : DEFAULT_LAST_INDEX

  return (
    <Title isActive={isActive}>
      {t(`${i18nBase}.FlightTo`, {
        location: location,
        to: firstIndex,
        from: lastIndex,
      })}
    </Title>
  )
})
