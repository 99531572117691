import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { formatMinutes } from '@fiji/utils/dates'

type Params = {
  isActive?: boolean
  isEmptyItinerary?: boolean
  isExpiredTrip: boolean
  handleExpireEvent: () => void
}

export function useTripExpirationCountdownTimer({
  isActive = false,
  isEmptyItinerary = false,
  isExpiredTrip = false,
  handleExpireEvent,
}: Params) {
  const { reviewTripStore, activeTripStore } = useReviewTripContext()
  const { itineraryExpiredDate } = reviewTripStore
  const { activeItineraryExpiredDate } = activeTripStore
  const expiredDate = isActive ? activeItineraryExpiredDate : itineraryExpiredDate
  const isCorrectTimeStamp = !!expiredDate

  const { hours, seconds, minutes, restart, isRunning, pause } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: handleExpireEvent,
    autoStart: false,
  })

  const formattedTime = formatMinutes({ hours, minutes, seconds })
  const isLessThanTenMinuteLeft = (minutes !== 0 || seconds !== 0) && minutes < 10

  const shouldShowExpirationTimer = isLessThanTenMinuteLeft && !isExpiredTrip && isCorrectTimeStamp

  useEffect(() => {
    if (expiredDate && !isRunning && !isEmptyItinerary) {
      restart(expiredDate, true)
    }
  }, [expiredDate, restart, isRunning, isEmptyItinerary])

  useEffect(() => {
    if (isRunning && isEmptyItinerary) {
      pause()
    }
  }, [isEmptyItinerary, isRunning, pause])

  return {
    formattedTime,
    shouldShowExpirationTimer,
  }
}
