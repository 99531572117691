import styled from 'styled-components'
import type { StyledTextVariant } from '@fiji/enums'
import { withStyledText } from '@fiji/style'

export const Title = styled.div<{
  textVariant?: StyledTextVariant
}>`
  display: flex;
  margin: 0 0 26px;
  font-weight: bold;
  font-size: 2rem;
  padding-right: 26px;
  line-height: 21px;
  justify-content: center;
  ${withStyledText};
`
