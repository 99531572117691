import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 15px 0 18px;
  background-color: ${(p) => p.theme.colors.mainText};

  a {
    ${KeyboardNavigationStyle}
  }
`

export const Image = styled.img``

export const Wrapper = styled.div`
  width: 80%;
`

export const IconContainer = styled.div`
  height: 100%;
  width: 110px;
`

export const EttaLogo = styled(Icon)`
  width: 95px;
  height: 37px;
  min-height: 37px;
`

export const DeemLogo = styled(Icon)`
  width: 107px;
  height: 30px;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const EttaGoLogo = styled(Icon)`
  width: 143px;
  height: 42px;
  min-height: 42px;
`

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DownloadLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const GooglePlayLink = styled.div`
  width: 135px;
  height: 40px;
`

export const AppStoreLink = styled.div`
  width: 120px;
  height: 40px;
`

export const SocialNetworkLinksContainer = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
`

export const SocialNetworkBlock = styled.div`
  height: 26px;
  > svg {
    width: auto;
  }
`

export const CopyrightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const PrivacyLinksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin: auto;
`

export const PrivacyLink = styled.a`
  text-decoration: none;
`

export const SocialNetworkLinks = styled.div`
  display: flex;
  align-items: center;
  > a {
    margin-right: 14px;
  }

  > a:last-child {
    margin-right: 0px;
  }
`

export const Line = styled.div`
  margin: 18px 0;
  border: 1px solid ${(p) => p.theme.colors.bodyText};
  opacity: 0.4;
`
