import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { Amenity } from '@fiji/graphql/types'

export const renderAmenity = (amenity: Amenity) => {
  return (
    <Block marginBottom={5} key={amenity.name}>
      <Text variant="footnoteMedium" color="bodyText">
        {amenity.name}
      </Text>
    </Block>
  )
}
