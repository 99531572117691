import type { MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Element } from './element'
import { Group } from './group'
import { SwapLocationButton } from './swap-location-button'
import { Wrapper, Button, Elements } from './search-styled'

type Props = {
  onSearchClick?: MouseEventHandler<HTMLButtonElement>
  searchButtonAriaLabel?: string
  dataTrackingId?: string
  swapSearchButton?: boolean
  searchButtonSwapSlot?: ReactNode
}

function Search({
  children,
  onSearchClick,
  dataTrackingId,
  searchButtonAriaLabel,
  swapSearchButton,
  searchButtonSwapSlot,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation()
  const buttonAriaLabel = searchButtonAriaLabel || t('TripPlanner.BaseSearch.Buttons.Search')
  return (
    <Wrapper>
      <Elements>{children}</Elements>
      {swapSearchButton ? (
        searchButtonSwapSlot
      ) : (
        <Button
          data-tracking-id={dataTrackingId}
          aria-label={buttonAriaLabel}
          onClick={onSearchClick}>
          <Icon name="searchPWA" size={36} color="mainText2" />
        </Button>
      )}
    </Wrapper>
  )
}

const SearchNamespace = Object.assign(Search, {
  Group,
  Element,
  SwapLocationButton,
})

export { SearchNamespace as Search }
