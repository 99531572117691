import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import { CarRentalSafetyCheck } from '@etta/components/car-rental-safety-check/car-rental-safety-check'
import type { CarRentalVendorInformationLayoutProps } from '../../types'
import {
  Container,
  Section,
  CarVendorLogo,
  Information,
  InformationLabel,
  LogoImage,
} from './car-rental-vendor-information-mobile-styled'

export function CarRentalVendorInformationMobile({
  vendorInformation,
  safetyCheck,
}: CarRentalVendorInformationLayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalModal.'

  return (
    <Container>
      <Section>
        {vendorInformation.imageUrl && (
          <CarVendorLogo>
            <LogoImage src={vendorInformation.imageUrl} alt={vendorInformation.title} />
          </CarVendorLogo>
        )}
        <Text variant="footnoteMedium">{vendorInformation.title}</Text>
      </Section>
      {vendorInformation.workingHours && (
        <Section>
          <Information>
            <div>
              <InformationLabel>
                <Text variant="captionMedium" color="bodyText">
                  {t(i18Base + 'DeskTimings')}
                </Text>
              </InformationLabel>
              <Text variant="subHeadStrong">{vendorInformation.workingHours}</Text>
            </div>
            <Text variant="captionMedium" color="bodyText">
              <Icon name="timePWA" size="medium" />
            </Text>
          </Information>
        </Section>
      )}
      {vendorInformation.phone && (
        <Section>
          <Information>
            <div>
              <Link href={`tel:${vendorInformation.phone}`}>
                <InformationLabel>
                  <Text variant="captionMedium" color="bodyText">
                    {t(i18Base + 'CallEnterprise')}
                  </Text>
                </InformationLabel>
                <Text variant="subHeadStrong" color="mainText">
                  {vendorInformation.phone}
                </Text>
              </Link>
            </div>
            <Link href={`tel:${vendorInformation.phone}`}>
              <Icon name="phonePWA" size="medium" />
            </Link>
          </Information>
        </Section>
      )}
      <Section>
        <CarRentalSafetyCheck
          vendorName={vendorInformation.name}
          messageCode={safetyCheck.messageCode}
          url={safetyCheck.url}
          badgeUrl={safetyCheck.badgeUrl}
        />
      </Section>
    </Container>
  )
}
