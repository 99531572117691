import type { Rate } from '@fiji/graphql/types'
import { TripCostPriceLayout } from './layout'

type Props = {
  onSubmit?: () => void
  isDisabled?: boolean
  totalRate: Rate
}

export function TripCostPrice(props: Props) {
  return <TripCostPriceLayout {...props} />
}
