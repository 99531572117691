import { css } from 'styled-components'

export const TRANSITION_NAME = 'WHEEL_PICKER_DIALOG'
export const TRANSITION_TIMEOUT = 350

const ANIMATION = 'ease-in-out'

export const slideUp = css`
  transition: transform ${TRANSITION_TIMEOUT}ms ${ANIMATION}, opacity ${TRANSITION_TIMEOUT}ms;

  &.${TRANSITION_NAME}-enter, &.${TRANSITION_NAME}-appear {
    transform: translateY(100%);
    opacity: 1;
  }

  &.${TRANSITION_NAME}-appear-done,
    &.${TRANSITION_NAME}-enter-done,
    &.${TRANSITION_NAME}-appear-active,
    &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.${TRANSITION_NAME}-exit-active {
    transform: translateY(100%);
    opacity: 0;
  }

  &.${TRANSITION_NAME}-exit-done, &.${TRANSITION_NAME}-exit {
    transform: translateY(100%);
    opacity: 0;
  }
`
