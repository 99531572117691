import { useControlsFooterLabels } from '@fiji/hooks/use-date-picker/use-controls-footer-labels'
import type { Dates } from '@fiji/types'
import { ControlsFooterLayout } from './layout'

type ControlsFooterProps = Dates & { onClose?: () => void }

export default function ControlsFooter({ date, dateEnd, onClose }: ControlsFooterProps) {
  const legendLabel = useControlsFooterLabels({
    date,
    dateEnd,
  })

  if (!date || !dateEnd || !legendLabel) {
    return <></>
  }

  const props = {
    legendLabel,
    onClose,
  }

  return <ControlsFooterLayout {...props} />
}
