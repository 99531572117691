import { observer } from 'mobx-react-lite'
import { Icon } from '@etta/ui/icon'
// eslint-disable-next-line import/no-restricted-paths
import { RecentSearchesFlightSearchParamsFlightType } from '@fiji/graphql/types'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  List,
  ItemContainer,
  ListItem,
  ArrowIconWrapper,
  Location,
  LeftBlock,
  RightBlock,
  BottomLine,
  CentralBlock,
  TopLine,
} from './air-search-form-mobile-recent-searches-styled'

export const AirSearchFormMobileRecentSearchItems = observer(
  function AirSearchFormMobileRecentSearches() {
    const { recentSearchesStore, recentSearchDataAction } = useRecentSearchesContext()
    const recentSearchesList = recentSearchesStore.recentSearches.flight.items
    if (!recentSearchesStore.isShowFlightRecentSearch) {
      return null
    }

    return (
      <List>
        {recentSearchesList?.map((item) => {
          const {
            bottomLine,
            destinationLocationName,
            recreateSearch,
            originLocationName,
          } = recentSearchDataAction.getRecentFlightSearches(item.flightSearchParams)
          return (
            <li>
              <ItemContainer key={item.id} onClick={recreateSearch}>
                <ListItem data-tracking-id="air-recent-search-card">
                  <LeftBlock>
                    <Icon name="flightPWA" />
                  </LeftBlock>
                  <CentralBlock>
                    <TopLine>
                      <Location>{originLocationName}</Location>
                      <ArrowIconWrapper>
                        {item.flightSearchParams.flightType ===
                        RecentSearchesFlightSearchParamsFlightType.OneWay ? (
                          <Icon name="flightArrowPWA" color="bodyText" />
                        ) : (
                          <Icon name="returnFlightArrowPWA" color="bodyText" />
                        )}
                      </ArrowIconWrapper>
                      <Location>{destinationLocationName}</Location>
                    </TopLine>
                    <BottomLine>{bottomLine}</BottomLine>
                  </CentralBlock>
                  <RightBlock>
                    <Icon name="chevronRightPWA" color="borderLight" size={22} />
                  </RightBlock>
                </ListItem>
              </ItemContainer>
            </li>
          )
        })}
      </List>
    )
  },
)
