import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'
import { Image } from '@etta/ui/image'

export const CloseButton = styled(IconButton)`
  margin-left: auto;
`

export const LogoContainer = styled.div`
  margin-bottom: 16px;

  > div {
    width: auto;
    max-height: 24px;
    min-height: 24px;
  }
`

export const Wrapper = styled.div`
  padding: 24px;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
`

export const AgencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SegmentLogo = styled(Image)`
  max-height: 24px;
  width: auto;
  object-fit: contain;
  align-self: start;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Conditions = styled.div`
  print-color-adjust: exact;
  background-color: ${(p) => p.theme.colors.background4};
  padding: 16px;
  border-radius: 10px;
`

export const ButtonWrapper = styled.div`
  text-align: right;

  & button div:first-child {
    margin: 0;
  }

  & button div:nth-child(2) {
    flex: none;
  }
`

export const DisplayNone = styled.div`
  display: none;
`
