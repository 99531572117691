import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import type { CarRentalPreferencesType } from './types'
import { CarRentalPreferences } from './car-rental-preferences'

type Props = CarRentalPreferencesType & {
  isOpen: boolean
}

export function CarRentalPreferencesModal({ isOpen, onClose, ...props }: Props) {
  return (
    <CheckoutPageModal isVisible={isOpen} onClose={onClose}>
      <CarRentalPreferences onClose={onClose} {...props} />
    </CheckoutPageModal>
  )
}
