import { useHistory } from 'react-router-dom'
import { usePreventBack } from '@fiji/hooks/use-prevent-navigation'
import { ROUTES } from '@fiji/routes'
import { useTripDropActions } from './hooks/use-new-trip-confirmation/use-trip-drop-actions'

export function useTripConfirmation() {
  const history = useHistory()
  const { dropTripAndPostBooking } = useTripDropActions()

  const onPreventBack = () => {
    dropTripAndPostBooking()
    setTimeout(() => {
      history.replace(ROUTES.explore)
    }, 0)
  }

  // See https://deem.atlassian.net/browse/FRI-1612
  // Prevent users from rebooking a trip that has already been successfully completed.
  usePreventBack({
    isBlocking: true,
    onPrevent: onPreventBack,
  })
}
