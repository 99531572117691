import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

type LineType = 'solid' | 'dotted' | 'dashed'

export const Container = styled.div``

export const ExtraInfo = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
`

export const HorizontalDelimiter = styled.div<{ lineType?: LineType }>`
  margin-top: -16px;
  margin-bottom: 16px;
  width: 100%;
  height: 1px;
  border-bottom: 1px ${({ lineType = 'dashed' }) => lineType}
    ${(props) => props.theme.colors.borderLight};
`

export const ExtraInfoDescription = styled.p`
  ${footnoteMedium};
  margin: 0;
  padding: 0;
  padding-left: 24px;
  color: ${(props) => props.theme.colors.bodyText};
  flex-grow: 1;
`

export const DescriptionLink = styled.a`
  ${footnoteMedium}
  color: ${(props) => props.theme.btnBackground};
  text-decoration: none;
`
