import type { MutableRefObject, ReactNode } from 'react'
import { useHotelSearchLocation } from '@fiji/hooks/hotels'
import { Icon } from '@etta/ui/icon'
import type { Hotel, ReferencePoint } from '@fiji/graphql/types'
import { Tooltip } from '@etta/ui/tooltip'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { useHotelLocations } from './use-hotel-locations'
import { MapLocations } from './map-locations'
import { HotelMarker } from './hotel-marker'
import type { Location } from './map-locations/types'
import {
  HotelCardContainer,
  ReferencePointBg,
  ReferencePointContainer,
  ReferencePointIconContainer,
  ReferencePointTooltipContent,
  SearchPinPointIcon,
} from './hotel-map-styled'

type Props = {
  hotels: Hotel[]
  center: { lat: number; lng: number }
  selectedId?: string
  distance?: string | number
  onSelect: (id: string) => void
  referencePoints?: ReferencePoint[]
  onBoundsLeave: (newCenter: Location) => void
  onBoundsReturn: () => void
  onAnnotationDeselect?: () => void
  selectedHotelCardSlot?: ReactNode
  parentRef?: MutableRefObject<HTMLDivElement | null>
}

export function HotelMap({
  hotels,
  center,
  selectedId,
  distance,
  onSelect,
  referencePoints,
  onBoundsLeave,
  onBoundsReturn,
  onAnnotationDeselect,
  selectedHotelCardSlot,
  parentRef,
}: Props) {
  const { locations } = useHotelLocations({ hotels })
  const [initialLocation] = useHotelSearchLocation()

  return (
    <MapLocations
      locations={locations}
      center={center}
      onAnnotationDeselect={onAnnotationDeselect}
      onBoundsLeave={onBoundsLeave}
      onBoundsReturn={onBoundsReturn}
      distance={distance}>
      {initialLocation && (
        <EttaMap.Marker lat={initialLocation.lat} lng={initialLocation.lng}>
          <SearchPinPointIcon name="searchPinPWA" size="large" color="primary" />
        </EttaMap.Marker>
      )}
      {hotels.map((hotel) => {
        if (!hotel?.address?.geocode) {
          return null
        }

        const { lat, long } = hotel?.address?.geocode
        if (!lat || !long) {
          return null
        }
        const isSelected = selectedId === hotel.id
        const markerKey = hotel.id
        return (
          <EttaMap.Marker calloutAppearStrategy="click" key={markerKey} lat={lat} lng={long}>
            <HotelMarker
              hotel={hotel}
              selectedId={selectedId}
              onSelect={onSelect}
              data-tracking-id="hotel-marker-button"
            />

            <EttaMap.Dialog markerKey={markerKey}>
              {isSelected && selectedHotelCardSlot && (
                <HotelCardContainer>{selectedHotelCardSlot}</HotelCardContainer>
              )}
            </EttaMap.Dialog>
          </EttaMap.Marker>
        )
      })}
      {referencePoints?.map((referencePoint: ReferencePoint) => {
        const { lat, long } = referencePoint.address.geocode
        return (
          <EttaMap.Marker lat={lat} lng={long} key={referencePoint.label}>
            <Tooltip
              parentRef={parentRef}
              place="top"
              slot={
                <ReferencePointTooltipContent>{referencePoint.label}</ReferencePointTooltipContent>
              }
              placementStyle={{
                width: '44px',
                height: '44px',
                transform: 'translate(-22px, -22px)',
              }}
              isIndependentOfParentWidth>
              <ReferencePointContainer>
                <ReferencePointBg />
                <ReferencePointIconContainer>
                  <Icon name="organisationPWA" size="medium" color="primary" />
                </ReferencePointIconContainer>
              </ReferencePointContainer>
            </Tooltip>
          </EttaMap.Marker>
        )
      })}
    </MapLocations>
  )
}
