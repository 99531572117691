import { useTranslation } from 'react-i18next'
import type { OutOfPolicyEnum, OutOfPolicyData } from '@fiji/graphql/types'
import { useOutOfPolicyMessages } from '@fiji/hooks/use-out-of-policy-messages'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import {
  Container,
  BadgeContainer,
  IconRow,
  ReasonItem,
  OutOfPolicyText,
} from './hotel-out-of-policy-styled'

type Props = {
  outOfPolicyReasons?: OutOfPolicyEnum[] | null
  outOfPolicyData?: OutOfPolicyData[] | null
}

export function HotelOutOfPolicy({ outOfPolicyReasons, outOfPolicyData }: Props) {
  const { t } = useTranslation()
  const { messages } = useOutOfPolicyMessages({ outOfPolicyData })

  const badgeTitle = t('Badge.OutOfPolicy')
  const isOneOutOfPolicyReason = outOfPolicyReasons?.length === 1
  const isMultipleOutOfPolicyReasons = outOfPolicyReasons && outOfPolicyReasons.length > 1

  return (
    <Container>
      <BadgeContainer aria-label={badgeTitle}>
        <IconRow>
          <Icon name="outOfPolicyPWA" size="small" color="error" />
        </IconRow>
        <Text variant="footnoteMedium" color="mainText">
          {badgeTitle}
          {isOneOutOfPolicyReason && (
            <>
              <span>: </span>
              <OutOfPolicyText>{messages[outOfPolicyReasons[0]]}</OutOfPolicyText>
            </>
          )}
        </Text>
      </BadgeContainer>
      {isMultipleOutOfPolicyReasons && (
        <div>
          {outOfPolicyReasons.map((reason) => (
            <ReasonItem>
              <Text variant="footnoteMedium" color="mainText">
                {messages[reason]}
              </Text>
            </ReasonItem>
          ))}
        </div>
      )}
    </Container>
  )
}
