import { StyledTextVariant } from '@fiji/enums'
import type { DialogTitleField } from '../types'
import { Header, HeaderTitle, CloseButton } from './dialog-header-styles'

type Props = {
  className?: string
  title?: DialogTitleField
  withTextVariant?: boolean
  onClose: () => void
  closeIcon?: React.ReactNode
}

export function DialogHeader({ className, title, onClose, withTextVariant, closeIcon }: Props) {
  const textVariant = withTextVariant ? StyledTextVariant.title2 : undefined

  return (
    <Header textVariant={textVariant} className={className} data-tracking-id="dialog-header">
      <HeaderTitle>{title}</HeaderTitle>
      {closeIcon || (
        <CloseButton
          onClick={onClose}
          icon="closePWA"
          size="normal"
          color={'bodyText2'}
          tabIndex={1}
        />
      )}
    </Header>
  )
}
