import styled from 'styled-components'

const ICON_SIZE = 53
export const HotelMarkerWrapper = styled.div`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  border-radius: 50%;
  box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.363035);
  background-color: ${({ theme }) => theme.colors.mainText};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -${ICON_SIZE / 2}px;
  top: -${ICON_SIZE / 2}px;
`
