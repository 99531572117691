import { TripList } from '@etta/components/trip-list/trip-list'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { HeldTripsPageLayout } from './layout'
import { useHeldTripsPage } from './hooks'

export function HeldTripsPage() {
  const { handleGoBack } = useHeldTripsPage()

  return (
    <HeldTripsPageLayout
      tripListSlot={<TripList tripType={TripType.OnHold} />}
      handleGoBack={handleGoBack}
    />
  )
}
