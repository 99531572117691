import styled from 'styled-components'

export const MapAnimationWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4;
`

export const HotelExtras = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
`
