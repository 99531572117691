import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button as MobileButton } from '@etta/ui/button'
import { COLLAPSED_VIEW_ATTRIBUTES_ICONS_AMOUNT } from '../../../constants'
import type { Props } from '../../types'
import { AttrIcon } from '../../attr-icon'
import { AmenitiesContainer, ButtonContainer, Item } from './fare-card-attributes-mobile-styled'

export function FareCardAttributesMobile({ ticketAttributes, isFullView, fareInfoSlot }: Props) {
  const i18Base = 'TripPlanner.FlightAirFares'
  const { t } = useTranslation()
  const [isShowDetails, setIsShowDetails] = useState(!!isFullView)
  const buttonText = t(i18Base + (isShowDetails ? '.HideDetails' : '.ViewDetails'))
  const buttonAriaLabel = t(
    i18Base + (isShowDetails ? '.HideDetailsButtonAria' : '.ShowDetailsButtonAria'),
    { label: buttonText },
  )

  if (!ticketAttributes?.length) {
    return null
  }

  const filteredTicketAttributes = isShowDetails
    ? ticketAttributes
    : ticketAttributes.slice(0, COLLAPSED_VIEW_ATTRIBUTES_ICONS_AMOUNT)

  return (
    <AmenitiesContainer isShowDetails={isShowDetails}>
      {filteredTicketAttributes.map((attribute) => (
        <Item key={attribute.id} aria-label={attribute.name} isShowDetails={isShowDetails}>
          <AttrIcon assessment={attribute.assessment} attrId={attribute.id} />
          {isShowDetails && attribute.name}
        </Item>
      ))}
      {isShowDetails && fareInfoSlot}

      {!isFullView && (
        <ButtonContainer aria-label={buttonAriaLabel}>
          <MobileButton
            onClick={() => setIsShowDetails(!isShowDetails)}
            variant="link"
            size="small">
            {buttonText}
          </MobileButton>
        </ButtonContainer>
      )}
    </AmenitiesContainer>
  )
}
