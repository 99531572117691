import type { FlightLegSubSegment } from '@fiji/graphql/types'
import { capitalizeFirstLetter } from './capitalize-first-letter'

export function formatFlightNumber({
  carrierCode,
  flightNumber,
  serviceClass,
}: Pick<FlightLegSubSegment, 'carrierCode' | 'flightNumber' | 'serviceClass'>): string {
  return `${carrierCode}-${flightNumber} - ${capitalizeFirstLetter(serviceClass)}`
}

export const getDisplayServiceClass = (
  { serviceClass, displayServiceClass }: { serviceClass: string; displayServiceClass: string },
  flightServiceClassCustomLabels: { [index: string]: string | undefined | null },
): string => flightServiceClassCustomLabels[serviceClass] ?? displayServiceClass
