import { observer } from 'mobx-react-lite'
import type { PropsWithChildren, ReactNode } from 'react'
import { useEttaLoader } from './use-etta-loader'

type Props = PropsWithChildren<{ loaderSlot: ReactNode }>

export const EttaLoader = observer(function EttaLoader({ children, loaderSlot }: Props) {
  const { isHomeAirportLoading, isFeatureFlagsLoading } = useEttaLoader()

  if (isHomeAirportLoading || isFeatureFlagsLoading) {
    return <>{loaderSlot}</>
  }

  return <>{children}</>
})
