import type { SegmentType } from '@etta/core/enums'

type Args = {
  isMobile: boolean
  mobileNextPage: string
  nextPage: string
  segmentType: SegmentType
  hasTrip: boolean
}

export function redirectParamsOnRefetchFail({
  segmentType,
  nextPage,
  isMobile,
  mobileNextPage,
  hasTrip,
}: Args) {
  if (isMobile) {
    return {
      page: mobileNextPage,
    }
  }

  if (hasTrip) {
    return {
      page: nextPage,
      params: {
        selectedTab: undefined,
      },
    }
  }

  return {
    page: nextPage,
    params: {
      selectedTab: segmentType,
    },
  }
}
