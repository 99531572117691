import type { FC, Key, ReactElement, ReactPortal, SetStateAction, Dispatch } from 'react'
import { useState, createContext, useContext, useCallback, useRef } from 'react'

export type Callouts = Map<Key, ReactElement>

export type SetSelectedCallout = Dispatch<SetStateAction<ReactPortal | null>>

type ContextType = {
  selectedCallout: ReactPortal | null
  calloutsMap: Callouts
  setCallout: (key: Key, callout: ReactElement) => void
  setSelectedCallout: SetSelectedCallout
  mapInstance: mapkit.Map | null
  setMapInstance: (instance: mapkit.Map) => void
}

const AppleMapContext = createContext<ContextType>({
  calloutsMap: new Map(),
  setCallout: () => {},
  selectedCallout: null,
  setSelectedCallout: () => {},
  mapInstance: null,
  setMapInstance: () => {},
})

export function useAppleMapContext() {
  return useContext(AppleMapContext)
}

export const AppleMapContextProvider: FC = ({ children }) => {
  const [selectedCallout, setSelectedCallout] = useState<ReactPortal | null>(null)
  const calloutsMap = useRef(new Map<Key, ReactElement>())
  const setCallout = useCallback((key: Key, callout: ReactElement) => {
    calloutsMap.current.set(key, callout)
  }, [])
  const mapInstance = useRef<mapkit.Map | null>(null)
  const setMapInstance = useCallback((instance: mapkit.Map) => {
    mapInstance.current = instance
  }, [])

  return (
    <AppleMapContext.Provider
      value={{
        selectedCallout,
        setSelectedCallout,
        calloutsMap: calloutsMap.current,
        setCallout,
        mapInstance: mapInstance.current,
        setMapInstance,
      }}>
      {children}
    </AppleMapContext.Provider>
  )
}
