import { Search } from '@etta/ui/search'
import { HotelDistanceModal } from '@etta/components/sliders'
import type { Props } from '../../types'
import { useHotelDistance } from '../../use-hotel-distance'

export function HotelDistanceSectionDesktop({ distance, onDistanceChange }: Props) {
  const { value, ref, distanceKey, hotelDistanceModal, label } = useHotelDistance({
    distance,
    onDistanceChange,
    withUppercase: true,
  })

  return (
    <Search.Group ref={ref}>
      <Search.Element
        labelTitle={label}
        value={value}
        onClick={hotelDistanceModal?.handleOpen}
        contentSlot={
          <HotelDistanceModal
            distanceKey={distanceKey}
            modalWidth={368}
            isOpen={hotelDistanceModal?.isOpen || false}
            onChange={onDistanceChange}
            distance={distance}
            onClose={hotelDistanceModal?.handleClose}
          />
        }
      />
    </Search.Group>
  )
}
