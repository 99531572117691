import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { InfoModal } from '../../info-modal'
import { RulesModalContent } from './rules-modal-content'
import { RulesModalContentModFlow } from './rules-modal-content-mod-flow'

type Props = {
  itineraryId: string
  carSegmentId: string
  isOpen: boolean
  onClose: () => void
}

export function RulesModal({ isOpen, onClose, ...props }: Props) {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isMod2FlowEnabled } = displayConfigurationStore
  return (
    <InfoModal isVisible={isOpen} onClose={onClose}>
      {isMod2FlowEnabled ? (
        <RulesModalContentModFlow onClose={onClose} {...props} />
      ) : (
        <RulesModalContent onClose={onClose} {...props} />
      )}
    </InfoModal>
  )
}
