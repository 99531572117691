import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { Props } from '../types'
import { TripDetailsMobileLayout } from './mobile'
import { TripDetailsDesktopLayout } from './desktop'

export const TripDetailsLayout = observer(function TripDetailsLayout(props: Props) {
  const { featureFlags } = useFeatureFlags()
  const { postBookingTripStore, postBookingCancellationActions } = usePostBookingContext()
  const { isLoading, isError, trip } = postBookingTripStore

  const layoutProps = {
    ...props,
    onCancelModalOpen: postBookingCancellationActions.onCancelTrip,
    trip: trip,
    isLoading: isLoading,
    isError: isError,
  }

  return (
    <MediaLayout
      mobileSlot={<TripDetailsMobileLayout {...layoutProps} />}
      desktopSlot={<TripDetailsDesktopLayout {...layoutProps} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
})
