import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import type { Amenity } from '@fiji/graphql/types'
import { AmenityUnitContainer } from '../hotel-details-amenities-desktop-styled'

type Props = {
  amenity: Amenity
}

export function AmenityUnit({ amenity }: Props) {
  return (
    <AmenityUnitContainer aria-label={amenity.name}>
      <Icon color="bodyText" name="newTickPWA" size="small" />
      <Text variant="subHeadMedium" color="bodyText">
        {amenity.name}
      </Text>
    </AmenityUnitContainer>
  )
}
