import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { TextField } from '@etta/ui/text-field'
import { Button } from '@etta/ui/button'
import { Form } from '@etta/ui/form'
import {
  IconWrapper,
  EditSection,
  EditTitle,
  EditHeading,
  CharCounter,
} from './edit-trip-name-dialog-mobile-styled'

const INPUT_MAX_LENGTH = 64
const i18nBase = 'TripReview.EditTripNameDialog'

type Props = {
  onClose: () => void
  onSubmit: () => void
  value: string
  onChange: (value: string) => void
}

export function EditTripNameDialogMobileContent({ onClose, onSubmit, value, onChange }: Props) {
  const { t } = useTranslation()

  const handleSubmit = () => {
    onSubmit()
    onClose()
  }

  return (
    <EditSection>
      <EditTitle>
        <EditHeading>{t(`${i18nBase}.Title`)}</EditHeading>
        <IconWrapper onClick={onClose}>
          <Icon name="closeFilledPWA" size="medium" color="bodyText1" />
        </IconWrapper>
      </EditTitle>
      <Form onSubmit={handleSubmit}>
        <Block marginTop={16}>
          <TextField
            type="text"
            label={t(`${i18nBase}.TripName`)}
            value={value}
            onChange={onChange}
            maxLength={INPUT_MAX_LENGTH}
          />
          <CharCounter>
            {t(`${i18nBase}.CharValidation`, {
              length: value.length,
              maxLength: INPUT_MAX_LENGTH,
            })}
          </CharCounter>
        </Block>
        <Block marginTop={23}>
          <Button fullWidth isSubmit disabled={!value} data-tracking-id="edit-trip-name-save">
            {t(`${i18nBase}.Save`)}
          </Button>
        </Block>
      </Form>
    </EditSection>
  )
}
