import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { formatPrice } from '@fiji/utils/money/format-price'
import { MobilityRideDetails } from '@etta/components/mobility-ride-details/mobility-ride-details'
import { UberRideSupport } from '@etta/components/uber-ride-support/uber-ride-support'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import {
  Container,
  HeaderContainer,
  HeaderTitle,
  HeaderDescription,
  HeaderTextContainer,
  SeparatorBlock,
  HorizontalDelimiter,
  Circle,
  IconContainer,
} from './uber-confirmation-details-styled'
import { useUberConfirmationDetails } from './use-uber-confirmation-details'

export const UberConfirmationDetails = observer(function UberConfirmationDetails() {
  const { t } = useTranslation()
  const basePath = 'Mobility.ConfirmationPage.'
  const {
    searchRideHailStore,
    rideHailAirportPickupStore,
    rideHailBookStore,
  } = useRideHailSearchContext()

  const { bookingInfo } = useUberConfirmationDetails(
    rideHailBookStore.booking,
    searchRideHailStore.searchRideHailForm,
  )
  if (!searchRideHailStore.selectedSearchRideHail) {
    return null
  }

  const {
    displayName,
    estimatedPrice,
    estimatedWaitingTime,
  } = searchRideHailStore.selectedSearchRideHail

  const { arrivalFlight } = rideHailAirportPickupStore

  return (
    <Container>
      <HeaderContainer>
        <IconContainer>
          <Icon name="rideHailPWA" color={'plum'} size={'medium'} />
        </IconContainer>
        <HeaderTextContainer>
          <HeaderTitle>
            {t(basePath + 'RideTo')}
            {bookingInfo.dropOffAddress}
          </HeaderTitle>
          <HeaderDescription>
            {displayName}
            {t(basePath + 'EstimatedPrice')}{' '}
            <Text variant="footnoteStrong" color="mainText1">
              {estimatedPrice && formatPrice(estimatedPrice)}
            </Text>
          </HeaderDescription>
        </HeaderTextContainer>
      </HeaderContainer>
      <SeparatorBlock>
        <Circle />
        <HorizontalDelimiter />
        <Circle />
      </SeparatorBlock>
      <Block paddingTop={20} paddingBottom={36} paddingLeft={10}>
        <MobilityRideDetails
          isShowFlightDetails={!!arrivalFlight}
          isCustomFlight={rideHailAirportPickupStore.isCustomFlight}
          airlineName={arrivalFlight?.airlineName || ''}
          flightDesignator={arrivalFlight?.flightDesignator || ''}
          pickUpDate={bookingInfo.pickUpDate}
          pickUpTime={bookingInfo.pickUpTime}
          pickUpTimezoneOffset={rideHailBookStore.booking?.pickUpTimezoneOffset}
          pickUpAddress={bookingInfo.pickUpAddress}
          dropOffAddress={bookingInfo.dropOffAddress}
          estimatedWaitingTime={estimatedWaitingTime}
          isOnDemandRide={searchRideHailStore.isOnDemandRide}
          isBooked
        />
      </Block>
      <Block paddingLeft={10}>
        <UberRideSupport />
      </Block>
    </Container>
  )
})
