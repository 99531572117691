import type { TrainSegmentSeatInfo } from '@fiji/graphql/types'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Badge, Badges } from './train-details-styled'

type Props = {
  platform?: string | null
  allocatedSeat?: TrainSegmentSeatInfo[] | null
}
export function TrainDetails({ allocatedSeat, platform }: Props) {
  const { carriage, seat } = allocatedSeat?.find(Boolean) ?? {}
  return (
    <Block marginTop={7} marginBottom={16}>
      <Badges>
        {!!platform && (
          <Badge>
            <Text variant="captionMedium" color="mainText">
              Platform {platform}
            </Text>
          </Badge>
        )}
        {!!carriage && (
          <Badge>
            <Text variant="captionMedium" color="mainText">
              Carriage {carriage}
            </Text>
          </Badge>
        )}

        <Badge>
          <Text variant="captionMedium" color="mainText">
            {seat ? `Seat ${seat}` : 'Sit in any vacant unreserved seat'}
          </Text>
        </Badge>
      </Badges>
    </Block>
  )
}
