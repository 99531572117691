import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { PriceLayout } from './layout/price-layout'
import { usePrice } from './use-price'

type Props = {
  isTripCancelled: boolean
  carRental: CarRentalPostBookingValueObject
}

export function Price({ carRental, isTripCancelled }: Props) {
  const { priceDetailsList, formattedTotalCost } = usePrice({ carRental })
  return (
    <PriceLayout
      isTripCancelled={isTripCancelled}
      priceDetailsList={priceDetailsList}
      totalCost={formattedTotalCost}
    />
  )
}
