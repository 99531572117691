import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import { dateFormat, getDifferenceInDays, isoToDate } from '@fiji/utils/dates'
import type { TextColor } from '@etta/ui/text/types'
import type { ChangeFee, OriginalCost } from '@fiji/graphql/types'
import { formatMoney } from '@fiji/utils/money'
import type { Props } from './types'
import { UnusedTicketStatus } from './types'

const i18nBase = 'UnusedTickets.Ticket'

export function useUnusedTicket({ unusedTicket }: Props) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()

  const { locale } = userStore

  const expirationDate = new Date(unusedTicket.expirationDate || '')
  const expiresInDays = getDifferenceInDays(expirationDate, new Date())

  const isExpiring = expiresInDays <= 10

  const expiresInColor: TextColor = isExpiring ? 'error' : 'bodyText'

  const formattedExpiresInDays = unusedTicket.expirationDate
    ? dateFormat(new Date(isoToDate(unusedTicket.expirationDate)), `MMM dd, yyyy`)
    : ''

  const expirationLabel = t(`${i18nBase}.${isExpiring ? 'ExpiresIn' : 'ExpiresAt'}`, {
    days: isExpiring ? expiresInDays : formattedExpiresInDays,
  })

  const unusedTicketAriaLabel = t(`${i18nBase}.Label`, {
    label: unusedTicket.airline?.value,
    expiration: expirationLabel,
  })

  const statusColor: TextColor = useMemo(() => {
    switch (unusedTicket.status) {
      case UnusedTicketStatus.LOCKED:
        return 'warning'
      case UnusedTicketStatus.EXPIRED:
        return 'error'
      case UnusedTicketStatus.PENDING_REFUND:
        return 'warning'
      case UnusedTicketStatus.AVAILABLE:
      default:
        return 'success'
    }
  }, [unusedTicket.status])

  const statusLabel = useMemo(() => {
    switch (unusedTicket.status) {
      case UnusedTicketStatus.LOCKED:
        return t(`${i18nBase}.StatusPendingExchange`)
      case UnusedTicketStatus.EXPIRED:
        return t(`${i18nBase}.StatusExpired`)
      case UnusedTicketStatus.PENDING_REFUND:
        return t(`${i18nBase}.StatusPendingRefund`)
      case UnusedTicketStatus.AVAILABLE:
      default:
        return t(`${i18nBase}.StatusAvailable`)
    }
  }, [unusedTicket.status, t])

  const getCost = useCallback(
    (cost?: ChangeFee | OriginalCost | null) => {
      if (!cost) {
        return '-'
      }

      return formatMoney(
        {
          amount: cost?.amount || 0,
          currency: cost?.code || '',
        },
        {
          prefix: true,
          isRounded: true,
          locale,
        },
      )
    },
    [locale],
  )

  const ticketDetails = useMemo(() => {
    return [
      {
        title: 'RecordLocator',
        detail: unusedTicket.recordLocator,
      },
      {
        title: 'TicketNumber',
        detail: unusedTicket.ticketNumber,
      },
      {
        title: 'ChangeFee',
        detail: getCost(unusedTicket.changeFee),
      },
      {
        title: 'OriginalCost',
        detail: getCost(unusedTicket.originalCost),
      },
    ]
  }, [unusedTicket, getCost])

  return {
    expiresInDays,
    expiresInColor,
    isExpiring,
    formattedExpiresInDays,
    unusedTicketAriaLabel,
    expirationLabel,
    statusColor,
    statusLabel,
    ticketDetails,
  }
}
