import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Header } from '@etta/ui/header'
import { Block } from '@etta/ui/block'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { PurchaseReservationError } from '@etta/components/purchase-reservation-error'
import { PurchaseReservationSuccess } from '@etta/components/purchase-reservation-success'
import { CostWithCta } from '@etta/components/footer-costs/cost-with-cta/cost-with-cta'
import { CreditCardModal } from '@etta/components/credit-card-modal/credit-card-modal'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { NATIONAL_RAIL_CONDITIONS_URL } from '@fiji/constants'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useUserContext } from '@etta/modules/user'
import { AddBaggageModal } from '@etta/modules/booking/ui/luggage-options'
import { HoldTripConfirmationModal } from '@etta/components/hold-trip-confirmation-modal'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useNewTripConfirmation } from '../trip-confirmation/hooks'
import { TripConfirmation } from '../trip-confirmation'
import { BookingConditions } from './booking-conditions'
import { BillingModal } from './billing-modal'
import { Footer } from './footer'
import { Title, FooterWrapper } from './payment-summary-styled'
import { PaymentSummaryLayout } from './layout'
import { PaymentDynamicSiteMessages } from './payment-dynamic-site-messages'
import type { Props } from './types'
import { useSegmentStatusSplit } from './use-segment-status-split'
import { BookingAnimationContainer } from './booking-animation-container'

const i18nBase = 'PaymentSummary.'
const i18nCostBase = 'CostWithCTA.'

export const PaymentSummary = observer(function PaymentSummary({
  shouldRenderPurchaseResult = true,
}: Props) {
  const { t } = useTranslation()

  const paymentSummary = useCheckoutContextSelector((state) => state.paymentSummary)
  const { railPreferences } = useCheckoutContextSelector((state) => state.travelerInfo)
  const { userStore } = useUserContext()
  const { profile } = userStore

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewCheckoutPageLayoutEnabled } = featureFlagsStore.flags
  const {
    bookingToggle,
    flightDetails,
    hotelDetails,
    carRentalDetails,
    railDetails,
    isRailAccreditationEnabled,
    isBooked,
    isBookTripError,
    isLoadingError,
    tripCost,
    onCardChange,
    onPaymentSubmit,
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    onBack,
    isLoading,
    isBookTripLoading,
    tripDynamicSiteMessages,
    validator,
    addCreditCardToggle,
    handleAddCreditCard,
    billingModalToggle,
    holdTripConfirmationToggle,
    isHoldButtonVisible,
    autoCancellationTime,
    onBillingModalOpen,
    billingParams,
    onReload,
    hasDisablingConditions,
    carRentalCardCheck,
    hotelCardCheck,
    airCardCheck,
    railCardCheck,
    bookingId,
    chosenCards,
    errorMsg,
    privacyPolicy,
    isCreditCardChangeable,
    singleTimeUseCard,
    startOverModalSlot,
    isShowDelegateName,
    viewState,
    isTripOnHold,
    isTripLoading,
    isRailEticketDeliveryOption,
    confirmationNumbersData,
    pnrNumber,
    handleSelectLuggageOption,
    selectedLuggageOption,
    luggageOptions,
    showLuggageOptions,
    addLuggageToggle,
    openAddCreditCardModal,
    bookButtonText,
    isHoldFlow,
    holdButtonText,
    tripBookedType,
    onSubmit,
    onReset,
    isAppleBannerShown,
    isCarPaymentRequired,
    newFlightDetails,
    newHotelDetails,
    newCarDetails,
    newRailDetails,
    submitType,
    isPostBooking,
    isBilledToCompanyAccount,
  } = paymentSummary

  const tripConfirmationProps = useNewTripConfirmation({
    confirmationType: tripBookedType,
    autoCancellationTime: autoCancellationTime,
    totalRate: tripCost?.totalWithUnusedTicket,
    recordLocator: pnrNumber,
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    onReset,
    flightDetails: newFlightDetails,
    hotelDetails: newHotelDetails,
    carDetails: newCarDetails,
    railDetails: newRailDetails,
  })

  const headerText = isHoldFlow ? t('HoldTrip.HoldTrip') : t(`${i18nBase}Header`)
  const delegateGuestText = t(`Delegate.BookingFor`, { user: 'Guest' })
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const shouldShowGuestLabel = isMobile && userStore.isGuest

  const {
    bookedFlights,
    bookedCarRentals,
    bookedHotels,
    bookedRail,
    newFlights,
    newCarRentals,
    newHotels,
    newRail,
  } = useSegmentStatusSplit({
    flightDetails,
    hotelDetails,
    carRentalDetails,
    railDetails,
    isPostBooking,
  })

  const shouldRenderPreviousBooking =
    isPostBooking &&
    !isLoading &&
    !isLoadingError &&
    ((bookedFlights && bookedFlights.segments.length > 0) ||
      (bookedHotels && bookedHotels.segments.length > 0) ||
      (bookedCarRentals && bookedCarRentals.segments.length > 0) ||
      (bookedRail && bookedRail.segments.length > 0))

  if (shouldRenderPurchaseResult && !!isBookTripError) {
    return <PurchaseReservationError onDone={onDone} errorMsg={errorMsg} />
  }

  if (isBookTripLoading) {
    return (
      <BookingAnimationContainer
        isHoldFlow={submitType === 'hold'}
        isPostBookingFlow={isPostBooking}
      />
    )
  }

  if (shouldRenderPurchaseResult && isBooked) {
    if (isNewCheckoutPageLayoutEnabled && !bookingId) {
      return <TripConfirmation {...tripConfirmationProps} />
    }
    return (
      <PurchaseReservationSuccess
        autoCancellationTime={autoCancellationTime}
        isTripOnHold={tripBookedType === 'hold'}
        isStationTicketMachineDisabled={railPreferences.value.isStationTicketMachineDisabled}
        onDone={onDone}
        onAnotherTrip={onAnotherTrip}
        onShareTripsDetail={onShareTripsDetail}
        flights={newFlights}
        carRentals={newCarRentals}
        hotels={newHotels}
        rail={newRail}
        dynamicSiteMessages={tripDynamicSiteMessages}
        dynamicSiteMessagesSlot={
          <PaymentDynamicSiteMessages dynamicSiteMessages={tripDynamicSiteMessages} />
        }
        isAppleBannerShown={isAppleBannerShown}
        isPostBooking={isPostBooking}
        tripTotal={tripCost?.total}
        travelerEmail={profile?.personalContact?.email || ''}
        isRailEticketDeliveryOption={isRailEticketDeliveryOption}
        confirmationNumbersData={confirmationNumbersData}
        isTripLoading={isTripLoading}
        pnrNumber={pnrNumber}
      />
    )
  }

  return (
    <>
      <PaymentSummaryLayout
        openAddCreditCardModal={openAddCreditCardModal}
        isTripOnHold={isTripOnHold}
        shouldRenderPreviousBooking={shouldRenderPreviousBooking}
        showLuggageOptions={showLuggageOptions}
        selectedLuggageOption={selectedLuggageOption}
        handleOpenLuggageToggle={addLuggageToggle.handleOpen}
        addCreditCardToggle={addCreditCardToggle}
        airCardCheck={airCardCheck}
        carRentalCardCheck={carRentalCardCheck}
        carRentalDetails={carRentalDetails}
        flightDetails={flightDetails}
        hotelCardCheck={hotelCardCheck}
        hotelDetails={hotelDetails}
        isRailAccreditationEnabled={isRailAccreditationEnabled}
        isCarPaymentRequired={isCarPaymentRequired}
        isCreditCardChangeable={isCreditCardChangeable}
        isLoading={isLoading}
        isLoadingError={isLoadingError}
        newCarRentals={newCarRentals}
        newFlights={newFlights}
        newHotels={newHotels}
        newRail={newRail}
        onBillingModalOpen={onBillingModalOpen}
        onPaymentSubmit={onPaymentSubmit}
        onReload={onReload}
        railCardCheck={railCardCheck}
        railDetails={railDetails}
        tripCost={tripCost}
        validator={validator}
        bookedCarRentals={bookedCarRentals}
        bookedFlights={bookedFlights}
        bookedHotels={bookedHotels}
        isBilledToCompanyAccount={isBilledToCompanyAccount}
        headerSlot={
          <Header
            layout="sub-title"
            leftSlot={<Header.BackButton color="mainText2" onClick={onBack} />}
            backgroundColor="background2">
            <Title data-tracking-id="payment-summary-header-text">{headerText}</Title>
            {isShowDelegateName && (
              <Block isFlexBlock>
                <DelegateName isWithBooking color="mainText2" />
              </Block>
            )}
            {shouldShowGuestLabel && !isShowDelegateName && (
              <Block isFlexBlock color="white">
                <DelegateName
                  variant="footnoteMedium"
                  withSmallIcon
                  name={delegateGuestText}
                  color="white"
                />
              </Block>
            )}
          </Header>
        }
        footerSlot={
          <FooterWrapper>
            <CostWithCta
              isHoldFlow={isHoldFlow}
              isLoading={isLoading}
              isError={isLoadingError}
              isDisabled={hasDisablingConditions}
              rate={tripCost?.totalWithUnusedTicket}
              onHoldTrip={() => onPaymentSubmit('hold')}
              onSubmit={async () => {
                await onPaymentSubmit('regular')
              }}
              holdButtonText={holdButtonText}
              buttonText={bookButtonText}
              isHoldButtonVisible={isHoldButtonVisible}
              data-tracking-id="book-trip-button"
              label={
                isRailAccreditationEnabled
                  ? t(i18nCostBase + 'TotalEstimatedCostRailAccreditation')
                  : undefined
              }
              withAllignContentToBottom
              extraText={
                <Footer
                  onShowBookingConditions={
                    !isRailAccreditationEnabled ? bookingToggle.handleOpen : undefined
                  }
                  nationalRailConditions={
                    isRailAccreditationEnabled ? NATIONAL_RAIL_CONDITIONS_URL : undefined
                  }
                  privacyPolicy={privacyPolicy}
                />
              }
            />
          </FooterWrapper>
        }
      />

      <AddBaggageModal
        selectedBaggage={selectedLuggageOption}
        isOpen={addLuggageToggle.isOpen}
        onClose={addLuggageToggle.handleClose}
        luggageOptions={luggageOptions}
        handleSelectBaggageOption={handleSelectLuggageOption}
      />

      <HoldTripConfirmationModal
        isOpen={holdTripConfirmationToggle.isOpen}
        onConfirm={() => {
          holdTripConfirmationToggle.handleClose()
          onSubmit('hold')
        }}
        onCancel={holdTripConfirmationToggle.handleClose}
        autoCancellationTime={autoCancellationTime}
      />

      <BookingConditions isVisible={bookingToggle.isOpen} onClose={bookingToggle.handleClose} />

      <CreditCardModal
        isOpen={addCreditCardToggle.isOpen}
        onClose={addCreditCardToggle.handleClose}
        onSubmit={handleAddCreditCard}
        isSingleUseCreditCardAllowed={billingParams?.metadata.isSingleUseCreditCardAllowed}
        isBillingAddressRequired={billingParams?.metadata.isBillingAddressRequired}
      />

      {billingParams && (
        <BillingModal
          isVirtualPayEnabled={hotelDetails?.virtualPay?.isVirtualPayEnabled}
          isOpen={billingModalToggle.isOpen}
          onClose={billingModalToggle.handleClose}
          billingParameter={billingParams}
          onSubmit={onCardChange}
          chosenCards={chosenCards}
          onAddSingleTimeUseCard={singleTimeUseCard.handleAddCard}
          singleTimeUseCard={singleTimeUseCard.card}
        />
      )}

      {startOverModalSlot}

      <ViewStateModal viewState={viewState.viewState} onDone={viewState.onClose} />
    </>
  )
})
