import { useTranslation } from 'react-i18next'
import { Payment } from '../payment'
import { PayableBlock, PayableTitle, DetailsLink } from './payable-section-styled'

type Props = {
  isPayableNowExist: boolean
  isPayableLaterExist: boolean
  flightNowPrice?: string
  trainNowPrice?: string
  hotelNowPrice?: string
  hotelLaterPrice?: string
  carNowPrice?: string
  carLaterPrice?: string
  onDetailsClick?: () => void
  isHoldFlow?: boolean
  allowUnusedTicketOptout?: boolean
  unusedTicketsPrice?: string
}

const i18nBase = 'Purchase.PriceBlock'

export function PayableSection({
  isPayableNowExist,
  isPayableLaterExist,
  flightNowPrice,
  trainNowPrice,
  hotelNowPrice,
  hotelLaterPrice,
  carNowPrice,
  carLaterPrice,
  onDetailsClick,
  isHoldFlow,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      {isHoldFlow && (
        <PayableBlock>
          <PayableTitle aria-label={t(`${i18nBase}.PayableLater`)}>
            {t(`${i18nBase}.PayableLater`)}
          </PayableTitle>
          <Payment title={t(`${i18nBase}.Flights`)} value={flightNowPrice} />
          <Payment title={t(`${i18nBase}.Train`)} value={trainNowPrice} />
          <Payment title={t(`${i18nBase}.Hotels`)} value={hotelNowPrice || hotelLaterPrice} />
          <Payment title={t(`${i18nBase}.CarRental`)} value={carNowPrice || carLaterPrice} />
        </PayableBlock>
      )}
      {isPayableNowExist && (
        <PayableBlock>
          <PayableTitle aria-label={t(`${i18nBase}.PayableNow`)}>
            {t(`${i18nBase}.PayableNow`)}
          </PayableTitle>
          <Payment title={t(`${i18nBase}.Flights`)} value={flightNowPrice} />
          <Payment title={t(`${i18nBase}.Train`)} value={trainNowPrice} />
          <Payment title={t(`${i18nBase}.Hotels`)} value={hotelNowPrice} />
          <Payment title={t(`${i18nBase}.CarRental`)} value={carNowPrice} />
        </PayableBlock>
      )}
      {isPayableLaterExist && !isHoldFlow && (
        <PayableBlock>
          <PayableTitle aria-label={t(`${i18nBase}.PayableLater`)}>
            {t(`${i18nBase}.PayableLater`)}
          </PayableTitle>
          <Payment title={t(`${i18nBase}.Hotels`)} value={hotelLaterPrice} />
          <Payment title={t(`${i18nBase}.CarRental`)} value={carLaterPrice} />
        </PayableBlock>
      )}
      {onDetailsClick && (
        <DetailsLink
          aria-label={t(`${i18nBase}.ViewDetailsAria`)}
          size="small"
          onClick={onDetailsClick}>
          {t(`${i18nBase}.ViewDetails`)}
        </DetailsLink>
      )}
    </>
  )
}
