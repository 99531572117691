import type { PreferencesUK, PreferencesEU } from './types'

export const INITIAL_PREFERENCES_UK: PreferencesUK = {
  seatPositions: 'NONE',
  seatDirections: 'NONE',
  carriageTypes: 'NONE',
  seatFacilities: [],
  isSeatRequestedOnDepart: false,
  isSeatRequestedOnReturn: false,
}

export const INITIAL_PREFERENCES_EU: PreferencesEU = {
  seatPositions: 'NONE',
  seatDirections: 'NONE',
  carriageTypes: 'NONE',
  seatFacilities: [],
  sitNear: {
    seat: '',
    carriage: '',
  },
  isSeatRequestedOnDepart: null,
  isSeatRequestedOnReturn: null,
}
