import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace UserErrors {
  export const GetUserErrorResponse = createErrorClass('Get User Error Response')
  export const GetUserUnexpectedError = createErrorClass('Get User Unexpected Error')
  export const UpdateUserErrorResponse = createErrorClass('Update User Error Response')
  export const UpdateUserUnexpectedError = createErrorClass('Update User Unexpected Error')

  export const GetPasswordSecurityConfigureUnexpectedError = createErrorClass('Unexpected Error')

  export const ChangePasswordUnexpectedError = createErrorClass('Change Password Unexpected Error')
  export const ChangePasswordBusinessError = createErrorClass<{ errorCodes: string[] }>(
    'Change Password Business Error',
  )

  export const GetSitesUnexpectedError = createErrorClass('Get Sites Unexpected Error')

  export const SavePasswordUnexpectedError = createErrorClass('Unexpected Error')

  export const AddPaymentCardUnexpectedError = createErrorClass('Unexpected Error')
}

export type UserErrorsInstances = InstanceType<typeof UserErrors[keyof typeof UserErrors]>

export function isChangePasswordBusinessError(
  err: UserErrorsInstances,
): err is InstanceType<typeof UserErrors.ChangePasswordBusinessError> {
  return err instanceof UserErrors.ChangePasswordBusinessError
}
