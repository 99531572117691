import type { Result } from 'fnscript/dist/result'
import i18n from '@fiji/i18n'
import { Inject, Service } from '@etta/di'
import { UserService } from '@etta/modules/user'
import type {
  DelegateValueObject,
  UpdateDelegatesOrAuthorizerValueObject,
} from '@etta/modules/user'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { SettingsAdapter } from '../../infra/settings.adapter'
import { DelegateStore } from '../stores/delegate.store'
import { BaseSettingsStore } from '../stores/base.store'
import type { SettingsErrorsInstances } from '../../core/errors/settings.errors'
import { BaseSettingsService } from './base.service'

@Service()
export class DelegateService {
  constructor(
    @Inject() private settingsAdapter: SettingsAdapter,
    @Inject() private delegateStore: DelegateStore,
    @Inject() private baseSettingsService: BaseSettingsService,
    @Inject() private baseSettingsStore: BaseSettingsStore,
    @Inject() private userService: UserService,
  ) {}

  get delegates() {
    return this.baseSettingsService.getProfileFromUser()?.delegates || []
  }

  get authorizers() {
    return this.baseSettingsService.getProfileFromUser()?.authorizers || []
  }

  getModalTitle(type: 'delegate' | 'authorizer' | null) {
    const i18nBase = `Settings.Delegate.Modal.`

    if (!type) {
      return ''
    }

    if (type === 'delegate') {
      return this.delegates.length
        ? i18n.t(i18nBase + 'AccountsTitle', { count: this.delegates.length })
        : i18n.t(i18nBase + 'AccountsTitleZero')
    }

    if (!this.authorizers.length) {
      return i18n.t(i18nBase + 'AutorizedTitleZero')
    }

    return this.authorizers.length === 1
      ? i18n.t(i18nBase + 'AutorizedTitle', { count: this.authorizers.length })
      : i18n.t(i18nBase + 'AutorizedTitle_plural', { count: this.authorizers.length })
  }

  async getDelegates() {
    this.delegateStore.setDelegatesLoading(true)
    this.delegateStore.setIsError(false)
    this.delegateStore.setDelegates([])

    const result = await this.settingsAdapter.getDelegates({})

    result.match({
      Ok: (delegates) => {
        this.delegateStore.setDelegates(delegates)
        this.delegateStore.setDelegatesLoading(false)
      },
      Err: () => {
        this.delegateStore.setIsError(true)
        this.delegateStore.setDelegatesLoading(false)
      },
    })
  }

  async getAvailableDelegates(query: string) {
    this.delegateStore.setIsAvailableDelegatesLoading(true)
    this.delegateStore.setIsAvailableDelegatesError(false)

    const result = await this.settingsAdapter.getAvailableDelegates(query)

    result.match({
      Ok: (delegates) => {
        this.delegateStore.setAvailableDelegates(delegates || [])
        this.delegateStore.setIsAvailableDelegatesLoading(false)
      },
      Err: () => {
        this.delegateStore.setIsAvailableDelegatesError(true)
        this.delegateStore.setIsAvailableDelegatesLoading(false)
      },
    })
  }

  async updateDelegates({
    id,
    handleClose,
  }: {
    id: number
    handleClose: (isLast: boolean) => void
  }) {
    this.handlePreActionOfUpdating()

    const result = await this.settingsAdapter.updateDelegates({
      delegates: this.getDelegatesOrAuthorizerBody({ toUpdateList: this.delegates, id }),
    })

    await this.handlePostActionOfUpdating(result, handleClose, this.delegates.length === 1)
  }

  async updateAuthorizers({
    id,
    handleClose,
  }: {
    id: number
    handleClose: (isLast: boolean) => void
  }) {
    this.handlePreActionOfUpdating()

    const result = await this.settingsAdapter.updateAuthorizers({
      authorizers: this.getDelegatesOrAuthorizerBody({
        toUpdateList: this.authorizers,
        id,
      }),
    })

    await this.handlePostActionOfUpdating(result, handleClose, this.authorizers.length === 1)
  }

  private handlePreActionOfUpdating() {
    if (this.delegateStore.selectedDelegateForUpdate) {
      this.baseSettingsStore.setSavingState()
    } else {
      this.baseSettingsStore.setDeletingState()
    }
  }

  private async handlePostActionOfUpdating(
    result: Result<{ success: boolean }, SettingsErrorsInstances>,
    handleClose: (value: boolean) => void,
    isLastDelegate: boolean,
  ) {
    if (result.isErr()) {
      this.baseSettingsStore.setErrorState()
      return
    }

    if (result.isOk()) {
      if (this.delegateStore.selectedDelegateForUpdate) {
        this.baseSettingsStore.setSavedState()
      } else {
        this.baseSettingsStore.setDeletedState()
      }
    }

    await delayForSuccess(1000) // Leaving to ensure a good UX by showing the saved/deleted modal.
    await this.userService.reloadUser()
    this.baseSettingsStore.dropState()
    handleClose(isLastDelegate)
  }

  private getDelegatesOrAuthorizerBody({
    toUpdateList,
    id,
  }: {
    toUpdateList: DelegateValueObject[]
    id?: number | null
  }) {
    const result: DelegateValueObject[] = []

    result.push(...toUpdateList.filter((item) => item.id && item.id !== id))

    if (this.delegateStore.selectedDelegateForUpdate) {
      result.push(this.delegateStore.selectedDelegateForUpdate)
    }

    return result
      .filter((item): item is UpdateDelegatesOrAuthorizerValueObject => item.id !== null)
      .map((item) => {
        return {
          id: item.id,
          firstName: item.firstName,
          email: item.email,
          lastName: item.lastName,
        }
      })
  }
}
