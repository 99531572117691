import type { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@etta/core/enums'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import { Text } from '@etta/ui/text'
import { Separator } from '@etta/ui/separator'
import { Icon } from '@etta/ui/icon'
import { deleteTimezone, dateFormat } from '@fiji/utils/dates'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import { useRulesAndRestrictions } from '@etta/components/rules-and-restrictions'
import {
  Wrapper,
  Details,
  Rules,
  List,
  ListItem,
  Flex,
  LogoContainer,
  SummarySegmentWrapper,
} from './print-itinerary-modal.styled'
import { FlightSegment } from './flight-segment'
import { HotelSegment } from './hotel-segment'
import { CarRentalSegment } from './car-rental-segment'
import { TripCostSummary } from './trip-cost-summary'

type Props = {
  trip: TripEntity
  travelerName: string
  printableContentRef?: RefObject<HTMLDivElement>
}

export const PrintItineraryContent = ({ trip, travelerName, printableContentRef }: Props) => {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'

  const {
    name,
    rollUpStartTime,
    rollUpEndTime,
    bookingRef,
    segments,
    type,
    costSummary,
    creationTime,
    recordLocator,
    agencyInfo,
    billingInfo,
    airTickets,
    holdRules,
  } = trip

  const { itineraryRules, showRulesAndRestrictions } = useRulesAndRestrictions({ segments })

  const tripTimeLine =
    rollUpStartTime &&
    rollUpEndTime &&
    [
      dateFormat(new Date(deleteTimezone(rollUpStartTime)), 'ccc, LLL d'),
      dateFormat(new Date(deleteTimezone(rollUpEndTime)), 'ccc LLL d, yyyy'),
    ].join(' - ')

  return (
    <Wrapper ref={printableContentRef}>
      <LogoContainer>
        <Icon name={'deemLogoPWA'} size="medium" color={'mainText'} />
      </LogoContainer>
      <Flex>
        <div>
          <Text variant="headline" isBlock>
            {name}
          </Text>
          <Text variant="footnoteMedium" isBlock>
            {tripTimeLine}
          </Text>
          <Text variant="footnoteMedium" isBlock>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'Traveler')}:&nbsp;
            </Text>
            {travelerName}
          </Text>
          <Text variant="footnoteMedium" isBlock>
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'Agency')}:&nbsp;
            </Text>
            {agencyInfo?.agencyName}
          </Text>
          {agencyInfo?.agencyMessage && (
            <Text variant="footnoteMedium" isBlock>
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18nBase + 'Message')}:&nbsp;
              </Text>
              {agencyInfo.agencyMessage}
            </Text>
          )}
        </div>
        <div>
          <Text variant="footnoteMedium" isBlock align="right">
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'Status')}:&nbsp;
            </Text>
            {capitalizeFirstLetter(type.split('_').join(' '))}
          </Text>
          <Text variant="footnoteMedium" isBlock align="right">
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'ReferenceNumber')}:&nbsp;
            </Text>
            {bookingRef}
          </Text>
          <Text variant="footnoteMedium" isBlock align="right">
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'RecordLocator')}:&nbsp;
            </Text>
            {recordLocator}
          </Text>
          {airTickets?.map((airTicket) => (
            <Text variant="footnoteMedium" isBlock align="right" key={airTicket.ticketNumber}>
              <Text variant="footnoteMedium" color="bodyText">
                {t(i18nBase + 'TicketNumber')}:&nbsp;
              </Text>
              {airTicket.ticketNumber}
            </Text>
          ))}
          <Text variant="footnoteMedium" isBlock align="right">
            <Text variant="footnoteMedium" color="bodyText">
              {t(i18nBase + 'DateBooked')}:&nbsp;
            </Text>
            {creationTime && dateFormat(new Date(deleteTimezone(creationTime)), 'ccc LLL d, yyyy')}
          </Text>
        </div>
      </Flex>
      <Separator width={2} color={'borderDark'} margin={'16px 0px'} />
      <Details>
        {holdRules?.autoCancellationTime && type === TripStatus.OnHold && (
          <Text variant="footnoteStrong" color="mainText">
            {t(i18nBase + 'AutoCancellation', {
              time: dateFormat(
                new Date(deleteTimezone(holdRules.autoCancellationTime)),
                "cccc LLLL d 'at' h:mm a",
              ),
            })}
          </Text>
        )}
        <div>
          {segments.map((segment) => {
            if (segment.type === SegmentType.Flight) {
              return segment.segments?.map((leg, index) => (
                <FlightSegment
                  key={leg.segmentId}
                  segment={leg}
                  stops={segment.stops}
                  distance={segment.distance}
                  stopIndex={index}
                />
              ))
            } else if (segment.type === SegmentType.Hotel) {
              return <HotelSegment key={segment.id} segment={segment} />
            } else if (segment.type === SegmentType.CarRental) {
              return <CarRentalSegment key={segment.carId} segment={segment} />
            }
          })}
        </div>
        {costSummary && <TripCostSummary costSummary={costSummary} billingInfo={billingInfo} />}
        <div>
          {showRulesAndRestrictions && (
            <SummarySegmentWrapper>
              <Text variant="subHeadStrong" color="mainText" isBlock>
                {t(i18nBase + 'RulesAndRestrictions')}
              </Text>
              <div>
                {Object.keys(itineraryRules).map((key) => {
                  return itineraryRules[key as keyof typeof itineraryRules].map((ruleSet) => (
                    <>
                      <Text variant="footnoteStrong" color="bodyText">
                        {ruleSet.label}
                      </Text>
                      <Rules>
                        {ruleSet.rules.map((rule) => (
                          <ListItem key={rule} dangerouslySetInnerHTML={{ __html: rule }} />
                        ))}
                      </Rules>
                    </>
                  ))
                })}
              </div>
            </SummarySegmentWrapper>
          )}
          <List>
            <ListItem>{t(i18nBase + 'EstimatedTotals')}</ListItem>
            <ListItem>{t(i18nBase + 'FlightsCharged')}</ListItem>
            <ListItem>{t(i18nBase + 'HotelsCharged')}</ListItem>
            <ListItem>
              {t(i18nBase + 'Contact', {
                name: agencyInfo?.agencyName,
                phone: agencyInfo?.agencyPhoneNumber,
              })}
            </ListItem>
          </List>
        </div>
      </Details>
    </Wrapper>
  )
}
