import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { IconColorPalette } from '@etta/ui/icon'
import { CancellationPolicy } from '@fiji/graphql/types'

type Args = {
  cancellationPolicy: CancellationPolicy
}

export function useRoomBadges({ cancellationPolicy }: Args) {
  const { t } = useTranslation()

  const { cancellationTitle, cancellationTitleColor } = useMemo<{
    cancellationTitle: string
    cancellationTitleColor: IconColorPalette
  }>(() => {
    switch (cancellationPolicy) {
      case CancellationPolicy.Free:
        return { cancellationTitle: t('Badge.Cancellable'), cancellationTitleColor: 'bodyText' }
      case CancellationPolicy.Penalty:
        return { cancellationTitle: t('Badge.Penalty'), cancellationTitleColor: 'error' }
      default:
        return { cancellationTitle: '', cancellationTitleColor: 'bodyText' }
    }
  }, [cancellationPolicy, t])

  return {
    cancellationTitle,
    cancellationTitleColor,
  }
}
