export default function () {
  return (
    <svg
      width="101"
      height="87"
      viewBox="0 0 101 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="43.9043" cy="43.4043" r="43.4043" fill="currentColor" />
      <path
        d="M100.5 22.5531C100.5 31.7187 93.0684 39.1489 83.9043 39.1489C74.7385 39.1489 67.3086 31.7187 67.3086 22.5531C67.3086 13.3876 74.7385 5.9574 83.9043 5.9574C93.0684 5.9574 100.5 13.3876 100.5 22.5531Z"
        fill="#ECEDEE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M99.031 22.6714C99.031 30.9607 92.311 37.6812 84.0195 37.6812C75.7298 37.6812 69.0098 30.9607 69.0098 22.6714C69.0098 14.3804 75.7298 7.65991 84.0195 7.65991C92.311 7.65991 99.031 14.3804 99.031 22.6714Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.9084 33.1144C84.0759 33.1144 84.314 33.0406 84.5432 32.9207C89.6212 30.2272 91.3315 28.7513 91.3315 25.449V18.6691C91.3315 17.4791 90.926 17.0087 89.9474 16.5751C89.0305 16.1785 85.8568 15.0254 84.984 14.7303C84.649 14.6196 84.2434 14.5458 83.9084 14.5458C83.5646 14.5458 83.1679 14.6288 82.8329 14.7303C81.9513 15.0162 78.7775 16.1877 77.8695 16.5751C76.8821 16.9995 76.4766 17.4791 76.4766 18.6691V25.449C76.4766 28.7513 78.2486 30.1165 83.2737 32.9207C83.5029 33.0499 83.7409 33.1144 83.9084 33.1144ZM86.8794 27.7643C86.6061 27.7643 86.3857 27.6628 86.2182 27.4783L83.8996 25.0708L81.5986 27.4783C81.4223 27.6721 81.1931 27.7643 80.9286 27.7643C80.4085 27.7643 80.003 27.34 80.003 26.7957C80.003 26.5467 80.1087 26.3069 80.2851 26.1316L82.6037 23.7056L80.2851 21.2703C80.1087 21.0951 80.003 20.8552 80.003 20.6062C80.003 20.062 80.4085 19.6469 80.9286 19.6469C81.1931 19.6469 81.4135 19.7391 81.581 19.9236L83.8996 22.3404L86.2359 19.9236C86.421 19.7206 86.6238 19.6376 86.8882 19.6376C87.4084 19.6376 87.8051 20.0527 87.8051 20.597C87.8051 20.8552 87.7169 21.0766 87.5318 21.2611L85.2132 23.7056L87.5318 26.1224C87.6993 26.3069 87.8051 26.5375 87.8051 26.7957C87.8051 27.34 87.3996 27.7643 86.8794 27.7643Z"
        fill="#CA0F1C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.2379 16.5942L43.2868 14.3752L40.3357 16.5942V22.57H40.3471C29.68 24.0095 21.4531 33.1726 21.4531 44.2645C21.4531 51.7424 25.1932 58.3432 30.8956 62.2909L29.6186 63.7846V67.5947H33.4981L35.8443 64.8505C38.1681 65.6952 40.6748 66.1555 43.2868 66.1555C45.8025 66.1555 48.2206 65.7286 50.4717 64.9423L53.1173 67.5947H56.9553V63.7441L55.5759 62.3612C61.3357 58.4218 65.1204 51.787 65.1204 44.2645C65.1204 39.3218 63.4856 34.7598 60.7295 31.0955L63.2393 28.5869L63.174 24.604L59.2061 24.5385L56.73 27.0136C53.7527 24.6781 50.1545 23.1002 46.2261 22.57H46.2379V16.5942ZM27.1207 44.2645C27.1207 35.3157 34.357 28.0585 43.2868 28.0585C52.2167 28.0585 59.453 35.3157 59.453 44.2645C59.453 53.2133 52.2167 60.4705 43.2868 60.4705C34.357 60.4705 27.1207 53.2133 27.1207 44.2645ZM45.7069 33.5978L42.7577 30.641L39.8048 33.5855L39.8049 44.23C39.8049 46.1829 41.3835 47.7633 43.3276 47.7633H53.7067L56.6594 44.803L53.7067 41.8431H45.7069V33.5978Z"
        fill="white"
      />
    </svg>
  )
}
