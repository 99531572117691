import type { TripDetailsCarServiceCompanyService } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container, Image } from './service-company-styled'

type Props = {
  serviceCompany?: TripDetailsCarServiceCompanyService | null
}
export function ServiceCompany({ serviceCompany }: Props) {
  if (!serviceCompany) {
    return null
  }

  return (
    <Container>
      <Block>
        <Image src={serviceCompany?.logo || ''} alt="service company logo" />
      </Block>
      <Block marginVertical={5}>
        <Text variant="subHeadStrong" color="mainText">
          {serviceCompany?.name}
        </Text>
      </Block>
      <Block>
        <Text variant="footnoteMedium" color="bodyText">
          {serviceCompany?.description}
        </Text>
      </Block>
    </Container>
  )
}
