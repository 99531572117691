import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import type { StopsFilterEntity } from '@etta/modules/rail/core/entities/stops-filter.entity'

const i18Base = 'RailFilters.Rail.Stops.'

const DISPLAYED_STOPS_VALUES = ['0', '1']

type StopsAttributeMap = Record<string, { isChecked: boolean; name: string }>

export function useRailStopsFilter() {
  const { railFilterAction, railFiltersStore, railStore } = useRailContext()

  const [stopsAttributesMap, setStopAttributesMap] = useState<StopsAttributeMap>({})
  const [stopsToShow, setStopsToShow] = useState<StopsFilterEntity[]>([])

  const { t } = useTranslation()

  useEffect(() => {
    const stops = railStore
      .railFilters!.stops.filter((stop) => DISPLAYED_STOPS_VALUES.includes(stop.value))
      .sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10))

    const stopAttributesMap = stops.reduce((acc: StopsAttributeMap, stop) => {
      acc[stop.value] = {
        isChecked: !!railFiltersStore.changedFilters.stops?.find(
          ({ value }) => value === stop.value,
        ),
        name: t(i18Base + stop.value),
      }
      return acc
    }, {})
    setStopAttributesMap(stopAttributesMap)
    setStopsToShow(stops)
  }, [t, railFiltersStore.changedFilters.stops, railStore.railFilters])

  return {
    stopsToShow,
    onChangeStops: railFilterAction.onChangeStops,
    isAny: !railFiltersStore.changedFilters.stops?.length,
    stopsAttributesMap,
  }
}
