import styled from 'styled-components'
import { headline, footnoteMedium } from '@fiji/style'

export const DetailsNoContent = styled.div`
  margin-top: 94px;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const ErrorMessage = styled.div`
  ${headline};
  line-height: 19px;
`

export const ErrorDescription = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  line-height: 19px;
  text-align: center;
  margin: 8px 30px;
  width: 300px;
`
