import { Inject, Service } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { PreSearchAdapter } from '../../infra'
import { PreSearchStore } from '../stores'

@Service()
export class PreSearchService {
  constructor(
    @Inject()
    private readonly preSearchAdapter: PreSearchAdapter,
    @Inject()
    private readonly preSearchStore: PreSearchStore,
    @Inject()
    private readonly cookieStore: CookieStore,
  ) {}

  async loadSearchInitiateInfo() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    const maybeResult = await this.preSearchAdapter.getSearchInitiateInfo()

    if (maybeResult.isNothing()) {
      return
    }

    this.preSearchStore.setSearchInitiateInfo(maybeResult.getValue())
  }
}
