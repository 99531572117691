import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useAlertContext } from '@etta/modules/alert/interface'
import { Icon } from '@etta/ui/icon'
import { Button } from './alert-notification-button-mobile-styled'

const i18nBase = 'Alerts'

export const AlertNotificationButtonMobile = observer(function AlertNotificationButtonMobile() {
  const { t } = useTranslation()
  const { alertStore, alertActions } = useAlertContext()
  const { alertsCount } = alertStore

  return (
    <Button
      aria-label={t(`${i18nBase}.Ally.OpenModal`)}
      onClick={alertActions.openAlertModal}
      alertsCount={alertsCount}>
      <Icon name="alertPWA" color="error" size="large" />
    </Button>
  )
})
