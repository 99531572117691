import styled from 'styled-components'
import { headline, footnoteStrong, bodyLarge } from '@fiji/style'
import { Link } from '@etta/ui/link'

export const Container = styled.div``

export const CardContainerMobile = styled.div`
  align-items: center;
  padding: 12px 16px;
  background: ${(props) => props.theme.colors.background};
  border-radius: 14px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const LeftBlockMobile = styled.div``
export const RightBlockMobile = styled.div``

export const EmissionKg = styled.p`
  color: ${(props) => props.theme.colors.mainText2};
  margin: 0;
  ${headline};
`

export const DescriptionBlockMobile = styled.p`
  margin: 5px 0;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteStrong};
`

export const AmountBlockMobile = styled.p`
  margin: 5px 0;
  ${bodyLarge};
  color: ${(props) => props.theme.colors.mainText};
`

export const ViewSourceLinkMobile = styled(Link)`
  display: inline-flex;
  align-items: center;
`
