import { Action, Inject } from '@etta/di'
import { FeatureFlagsService } from '../services/feature-flags.service'

@Action()
export class FeatureFlagsAction {
  constructor(
    @Inject()
    private readonly featureFlagsService: FeatureFlagsService,
  ) {}

  async loadDefaultFlags() {
    await this.featureFlagsService.loadFlags()
  }

  async loadFlagsById(companyId: number) {
    await this.featureFlagsService.loadFlags(companyId)
  }
}
