import styled from 'styled-components'

export const PaymentValue = styled.div``

export const Price = styled.div<{
  isSecondary?: boolean
}>`
  text-align: right;
  color: ${(props) =>
    props.isSecondary ? props.theme.colors.bodyText : props.theme.colors.mainText};
  margin-top: ${(props) => (props.isSecondary ? '4px' : '0')};
`
