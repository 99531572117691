import { useTranslation } from 'react-i18next'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import {
  Footer,
  Body,
  ConfirmButton,
  CancelButton,
} from './hold-trip-confirmation-modal-desktop-styled'

const i18Base = 'HoldTrip.HoldTripConfirmationModal.'
export function HoldTripConfirmationModalDesktop({
  isOpen,
  onCancel,
  onConfirm,
  translationSlot,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Dialog title={t(i18Base + 'Title')} isOpen={isOpen} onClose={onCancel}>
      <Body>
        <Block>
          <Text variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'Description')}
          </Text>
        </Block>
        {translationSlot && (
          <>
            <Block marginTop={22}>
              <Text variant={'subHeadStrong'} color={'mainText1'}>
                {t(i18Base + 'CancellationInformation')}
              </Text>
            </Block>
            <Block marginTop={8}>
              <Text variant={'subHeadMedium'} color={'bodyText'}>
                {translationSlot}
              </Text>
            </Block>
          </>
        )}
      </Body>
      <Footer>
        <CancelButton variant={'text'} onClick={onCancel} data-tracking-id={'cancel-button'}>
          {t(i18Base + 'CancelButton')}
        </CancelButton>
        <ConfirmButton
          fullWidth
          variant={'solid'}
          onClick={onConfirm}
          data-tracking-id={'hold-trip-button'}>
          {t(i18Base + 'ConfirmButton')}
        </ConfirmButton>
      </Footer>
    </Dialog>
  )
}
