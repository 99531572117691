import { Select } from '@etta/ui/select'
import { Block } from '@etta/ui/block'
import type { CustomFieldOption } from '@fiji/graphql/types'
import { FieldDescription } from '@etta/ui/field-description'
import type { BaseTextFieldProps } from '@etta/ui/text-field/base-text-field'
import type { CustomFieldExtension } from '../types'
import { RadioContainer, StyledRadio, HelperContainer, BlockTitle } from '../custom-field-styled'

type Props = {
  value?: string
  onChangeHandler: (value: string) => void
  field: CustomFieldExtension
  helperText?: string
  options?: CustomFieldOption[]
  description?: string | null
} & Omit<BaseTextFieldProps, 'value'>

export function SelectField({
  onChangeHandler,
  value,
  field,
  helperText,
  options = [],
  label,
  description,
}: Props) {
  if (field.enumerationDisplay?.isRadioButtonsStyle) {
    return (
      <Block>
        <Block marginTop={40}>
          <BlockTitle>{label || field.label}</BlockTitle>
        </Block>
        {description && (
          <Block marginTop={10}>
            <FieldDescription description={description} />
          </Block>
        )}
        {options.map((option) => (
          <RadioContainer key={option.value}>
            <StyledRadio
              isChecked={option.value === value}
              onChange={() => onChangeHandler(option.value)}>
              {option.name}
            </StyledRadio>
          </RadioContainer>
        ))}
        <HelperContainer role="error">{helperText}</HelperContainer>
      </Block>
    )
  }

  return (
    <Select
      onChange={onChangeHandler}
      label={label || field.label || ''}
      value={value}
      key={field.customUuid}
      options={options.map((option) => ({
        label: option.name,
        value: option.value,
      }))}
      helperText={helperText}
      helperType="error"
      description={description}
      withNoneOption={false}
    />
  )
}
