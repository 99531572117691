import { Inject, Action } from '@etta/di'
import { MeetingModeService } from '../services/meeting-mode.service'
import type { UpsertMeetingInfoInput } from '../../core/value-objects/meeting-mode.value-object'
import { MeetingModeStore } from '../stores'

@Action()
export class MeetingModeActions {
  constructor(
    @Inject() private readonly meetingModeStore: MeetingModeStore,
    @Inject() private meetingModeService: MeetingModeService,
  ) {}

  async upsertMeetingInfo(input: UpsertMeetingInfoInput) {
    await this.meetingModeService.upsertMeetingInfo(input)
  }

  async getMeetingInfo() {
    await this.meetingModeService.getMeetingInfo()
  }

  dropStore() {
    this.meetingModeStore.dropStore()
  }
}
