import { Skeleton } from '@etta/ui/skeleton'
import { CityBackground } from '@etta/components/city-background/city-background'
import { SkeletonContainer } from './trip-costs-sidebar-styled'

type Params = {
  cityImageUrl?: string | null
}

export function TripCostsSidebarSkeleton({ cityImageUrl }: Params) {
  return (
    <SkeletonContainer role="region">
      <CityBackground cityImageUrl={cityImageUrl ? cityImageUrl : null} />
      <Skeleton width="320px" height="278px">
        <rect width="160" height="14" x="24" y="45" />
        <rect width="28" height="14" x="268" y="45" />
        <rect width="134" height="14" x="24" y="75" />
        <rect width="82" height="14" x="24" y="128" />
        <rect width="131" height="14" x="24" y="153" />
        <rect width="173" height="14" x="24" y="178" />
      </Skeleton>
    </SkeletonContainer>
  )
}
