import { Action, Inject } from '@etta/di'
import type { OutOfPolicyReasonValueObject } from '@etta/core/value-objects/out-of-policy-reason.value-object'
import { SegmentType } from '@etta/core/enums'
import { OOPAdapter } from '@etta/modules/review-trip/infra/out-of-policy/oop.provider'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration/interface/stores/display-configuration.store'
import type { OOPSelections } from '@etta/modules/review-trip/core/value-objects/oop-selections'
import { CartAdapter } from '@etta/modules/review-trip/infra/cart/cart.adapter'
import { OutOfPolicyStore } from '../stores'
import { ReviewTripStore } from '../../stores/review-trip-page.store'
import { SegmentTypeCheckService } from '../../services'

@Action()
export class OutOfPolicyActions {
  constructor(
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,
    @Inject()
    private readonly outOfPolicyAdapter: OOPAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  handleDropOopState() {
    this.outOfPolicyStore.dropOopState()
  }

  async handleSetOOPSelection(oopSelections: OOPSelections) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      const codes = this.reviewTripStore.segments.reduce<{ intentId: string; code: string }[]>(
        (acc, segment) => {
          if (
            this.segmentTypeCheckService.isFlightSegment(segment) &&
            oopSelections.oopSegments.air
          ) {
            acc.push({ intentId: segment.uuid, code: oopSelections.oopSegments.air })
          }
          if (
            this.segmentTypeCheckService.isCarRentalSegment(segment) &&
            oopSelections.oopSegments.carrental
          ) {
            acc.push({ intentId: segment.uuid, code: oopSelections.oopSegments.carrental })
          }
          if (
            this.segmentTypeCheckService.isTrainSegment(segment) &&
            oopSelections.oopSegments.rail
          ) {
            acc.push({ intentId: segment.uuid, code: oopSelections.oopSegments.rail })
          }
          if (
            this.segmentTypeCheckService.isHotelSegment(segment) &&
            oopSelections.oopSegments.hotel
          ) {
            acc.push({ intentId: segment.uuid, code: oopSelections.oopSegments.hotel })
          }

          return acc
        },
        [],
      )
      const result = await this.cartAdapter.setOOPJustification({
        cartId: this.reviewTripStore.tripId,
        explanation: oopSelections.oopExplanation || '',
        codes,
      })
      if (result.isOk()) {
        const newValues = await this.cartAdapter.getCartOOPInfo(this.reviewTripStore.tripId, true)
        if (newValues.isOk()) {
          this.outOfPolicyStore.setOOPSelection(newValues.getValue().selections)
        }

        return {
          success: true,
        }
      }
      return {
        success: false,
      }
    }

    this.outOfPolicyStore.setIsUpdatingOOP(true)

    // todo, add error handling
    await this.outOfPolicyAdapter.sendOopCodes({
      itineraryId: this.reviewTripStore.tripId,
      carRentalOutOfPolicyComplianceCode: oopSelections.oopSegments.carrental,
      flightOutOfPolicyComplianceCode: oopSelections.oopSegments.air,
      hotelOutOfPolicyComplianceCode: oopSelections.oopSegments.hotel,
      trainOutOfPolicyComplianceCode: oopSelections.oopSegments.rail,
    })

    this.outOfPolicyStore.setOOPSelection(oopSelections)
    this.outOfPolicyStore.setIsUpdatingOOP(false)
    return {
      success: true,
    }
  }

  getSegmentReasonId(
    reasons: OutOfPolicyReasonValueObject[],
    segmentType: SegmentType,
  ): number | undefined {
    const segmentReasons = reasons.filter((r) => r.segment === segmentType)

    switch (segmentType) {
      case SegmentType.Flight: {
        const reason = segmentReasons.find(
          (s) => s.reason === this.outOfPolicyStore.oopSegmentsReason.airReason,
        )

        return reason?.id
      }
      case SegmentType.Hotel: {
        const reason = segmentReasons.find(
          (s) => s.reason === this.outOfPolicyStore.oopSegmentsReason.hotelReason,
        )

        return reason?.id
      }
      case SegmentType.CarRental: {
        const reason = segmentReasons.find(
          (s) => s.reason === this.outOfPolicyStore.oopSegmentsReason.carrentalReason,
        )

        return reason?.id
      }
      case SegmentType.Train: {
        const reason = segmentReasons.find(
          (s) => s.reason === this.outOfPolicyStore.oopSegmentsReason.railReason,
        )

        return reason?.id
      }
      default:
        return undefined
    }
  }

  async getOopConfiguration(segments: SegmentType[]) {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      return
    }
    this.outOfPolicyStore.setIsOOPConfigurationLoading(true)
    this.outOfPolicyStore.setOOPConfiguration([])
    const result = await this.outOfPolicyAdapter.getOOPConfiguration(segments)

    if (result.isOk()) {
      this.outOfPolicyStore.setOOPConfiguration(result.getValue())
    }
    this.outOfPolicyStore.setIsOOPConfigurationLoading(false)
  }
}
