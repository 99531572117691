import styled from 'styled-components'
import { TextField as EttaTextField } from '@etta/ui/text-field'

export const SelectTextField = styled(EttaTextField)`
  flex: 1;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 0;
  width: 100%;
`
