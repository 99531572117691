import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Wrapper } from './rules-and-restrictions-button.styled'

type Props = {
  onOpenRulesAndRestrictions: () => void
}

export function RulesAndRestrictionsButton({ onOpenRulesAndRestrictions }: Props) {
  const { t } = useTranslation()

  return (
    <Wrapper onClick={onOpenRulesAndRestrictions}>
      <Block isFlexBlock flexGap={8}>
        <Icon name="documentPWA" size="normal" />
        <Text variant="subHeadStrong" color="mainText1">
          {t(`TripReview.RulesAndRestrictions.title`)}
        </Text>
      </Block>
      <Icon name="chevronRightPWA" size="normal" color="mainText1" />
    </Wrapper>
  )
}
