import { HotelRateCap } from '@etta/components/hotel-rate-cap/hotel-rate-cap'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { RoomsContent } from '../rooms-content'
import { HotelServiceFee } from '../desktop/hotel-service-fee'

export function RoomsContentLayoutMobile({
  rooms,
  perDiemRateCap,
  areRoomsSelectable,
  onRoomDetailsSelect,
  onRoomSelect,
  serviceFeeLabel,
  totalNights,
}: LayoutProps) {
  return (
    <>
      <HotelRateCap perDiemRateCap={perDiemRateCap} skin="hotel-details" />
      {serviceFeeLabel && (
        <Block marginHorizontal={16} marginVertical={16}>
          <HotelServiceFee serviceFeeLabel={serviceFeeLabel} />
        </Block>
      )}

      <RoomsContent
        rooms={rooms}
        areRoomsSelectable={areRoomsSelectable}
        onRoomSelect={onRoomSelect}
        onRoomDetailsSelect={onRoomDetailsSelect}
        totalNights={totalNights}
      />
    </>
  )
}
