import { Text } from '@etta/ui/text'
import { SegmentType } from '@etta/core/enums'
import { MediaLayout } from '@etta/ui/media-layout'
import { SegmentCard, useSegmentCard } from '../segment-card'
import { SectionDate } from '../date-segments-styled'
import type { LayoutProps } from './types'
import { useRideHailSegment } from './use-ride-hail-segment'

export function RideHailSegment({
  segment,
  isUpcoming,
  isOnHold,
  currentDate,
  onClick,
  onCancel,
}: LayoutProps) {
  const { isPast, dateToShow } = useSegmentCard({ segment, currentDate, isOnHold })

  const { titleRideHail, ariaLabel, pickUpLocationLabel, pickUpTimeLabel, buttons, handleShare } =
    useRideHailSegment({ segment, isUpcoming, onCancel })

  return (
    <SegmentCard
      isPast={isPast}
      isUpcoming={isUpcoming}
      ariaLabel={ariaLabel}
      segmentIcon="rideHailPWA"
      onClick={onClick}
      segmentType={SegmentType.RideHail}
      titleSlot={
        <MediaLayout
          mobileSlot={
            <Text
              variant="subHeadStrong"
              color="mainText"
              data-tracking-id="ride-hail-text"
              textOverflow="ellipsis-two-line"
            >
              {titleRideHail}
            </Text>
          }
          desktopSlot={
            <Text
              variant="title3"
              color="mainText"
              data-tracking-id="ride-hail-text"
              textOverflow="ellipsis-two-line"
            >
              {titleRideHail}
            </Text>
          }
        />
      }
      dataTexts={[pickUpLocationLabel]}
      departureTime={pickUpTimeLabel}
      buttons={buttons}
      dateSlot={
        <SectionDate data-tracking-id="ride-hail-pickup-time-text">{dateToShow}</SectionDate>
      }
      onShare={handleShare}
      isShareButtonAvailable={false}
    />
  )
}
