import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@fiji/store'
import type { AppState } from '@fiji/store'
import type { SegmentType } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { getFlightCustomTimeRange } from '@fiji/utils/dates/get-flight-custom-time-range'
import type { TimeRange, Place } from '@fiji/types'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'

type Props = {
  originPlace?: Place | null
  destinationPlace?: Place | null
  selectedTime: TimeRange
  date?: Date | null
  initialCustomTime: TimeRange
  forSegment?: SegmentType
  onSubmit?: (time: TimeRange) => void
  onClose: () => void
}
const i18Base = 'FlightSearch.TimePicker'

export function useTimeRangePicker({
  originPlace,
  destinationPlace,
  selectedTime,
  date,
  initialCustomTime,
  forSegment,
  onSubmit,
  onClose,
}: Props) {
  const { t } = useTranslation()
  const { flightSearchTimeRange, flightSearchTimeRangeNonUS } = useAppSelector(
    (state: AppState) => state.displayConfiguration,
  )

  const [pickedTime, setPickedTime] = useState(selectedTime)

  const handleClose = useCallback(() => {
    setPickedTime(selectedTime)
    onClose()
  }, [setPickedTime, selectedTime, onClose])

  const [customTimeValue, setCustomTimeValue] = useState(
    selectedTime.customTimeHours
      ? selectedTime.customTimeHours
      : initialCustomTime.customTimeHours!,
  )

  const getDateTitle = useCallback(
    (date?: Date | null) => {
      return date ? `${t(i18Base + '.OnDate')} ${dateFormat(date, 'iii, MMM d')}` : ''
    },
    [t],
  )

  const handleSubmit = useCallback(
    (time: TimeRange) => {
      onSubmit &&
        onSubmit({ ...time, timeRangeBy: selectedTime.timeRangeBy || TimeRangeDirection.Departure })
      onClose()
    },
    [onSubmit, onClose, selectedTime],
  )

  const convertTimeLabel = useCallback(
    (time: TimeRange) => {
      const label = t(time.i18next)
      if (time.id === TimeRangeOption.CustomTime && pickedTime.id === TimeRangeOption.CustomTime) {
        const customTime = getFormattedTime(
          {
            hours: customTimeValue,
          },
          'h:mmaaa',
        )
        return `${label}: ${t(i18Base + `.Around`)} ${customTime}`
      }
      if (time.id === TimeRangeOption.CustomTime || time.id === TimeRangeOption.AnyTime) {
        return label
      }
      const startTime = getFormattedTime(
        {
          hours: time.startTimeHours!,
        },
        'haaa',
      )
      const endTime = getFormattedTime(
        {
          hours: time.endTimeHours!,
        },
        'haaa',
      )

      return `${label} (${startTime} - ${endTime})`
    },
    [pickedTime, customTimeValue, t],
  )

  useEffect(() => {
    if (!pickedTime.customTimeHours) {
      return
    }

    const flightCustomTime = getFlightCustomTimeRange({
      value: pickedTime.customTimeHours,
      originPlace,
      destinationPlace,
      flightSearchTimeRange,
      flightSearchTimeRangeNonUS,
      forSegment,
    })

    setPickedTime((time) => ({
      ...time,
      ...flightCustomTime,
    }))

    onSubmit &&
      onSubmit({
        ...pickedTime,
        ...flightCustomTime,
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedTime.customTimeHours, originPlace?.airportCode, destinationPlace?.airportCode])

  const handleCustomTimeChange = useCallback(
    ({ value, time }: { value: number; time?: TimeRange }) => {
      const searchTimeRange = getFlightCustomTimeRange({
        value,
        originPlace,
        destinationPlace,
        flightSearchTimeRange,
        flightSearchTimeRangeNonUS,
        forSegment,
      })

      const timeToSet = time ? time : pickedTime
      const pickedTimeValue = {
        ...timeToSet!,
        ...searchTimeRange,
        customTimeHours: value,
      }
      setPickedTime(pickedTimeValue)
    },
    [
      setPickedTime,
      originPlace,
      destinationPlace,
      flightSearchTimeRange,
      flightSearchTimeRangeNonUS,
      forSegment,
      pickedTime,
    ],
  )

  const onCustomTimeChange = useCallback(
    (value: number) => {
      setCustomTimeValue(value)
      handleCustomTimeChange({ value })
    },
    [setCustomTimeValue, handleCustomTimeChange],
  )

  const onRadioButtonClick = (time: TimeRange) => {
    if (time.id === TimeRangeOption.CustomTime) {
      handleCustomTimeChange({ value: customTimeValue, time })
      return
    }
    setPickedTime({ ...time })
  }

  const airportTitle = useMemo(() => {
    const firstLocationName = (originPlace?.shortName ?? originPlace?.name)?.split(',')[0]
    const airportCode = originPlace?.airportCode ? `(${originPlace.airportCode})` : null
    const dateTitle = getDateTitle(date)
    return [firstLocationName, airportCode, dateTitle].filter(Boolean).join(' ')
  }, [date, getDateTitle, originPlace?.airportCode, originPlace?.name, originPlace?.shortName])

  return {
    convertTimeLabel,
    onCustomTimeChange,
    handleCustomTimeChange,
    onRadioButtonClick,
    customTimeValue,
    title: airportTitle,
    handleSubmit,
    pickedTime,
    handleClose,
  }
}
