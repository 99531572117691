import { observer } from 'mobx-react-lite'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { IconButton } from '@etta/ui/icon-button'
import { FlightDetailsEcoCheck } from '@etta/components/flight-details-eco-check/flight-details-eco-check'
import type { FlightEmission } from '@fiji/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { TripStatus } from '@etta/modules/post-booking/core/enums'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { WarningCancelRoundFlightModal } from '@etta/components/cancel-trip-modal/warning-cancel-round-flight-modal'
import { MobilitySearchFlowModal } from '@etta/screens/trip-details/details/mobility-search-flow-modal/mobility-search-flow-modal'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { DetailsContainer } from '../../trip-flight-styled'
import { Headline } from '../../headline'
import { Traveler } from '../../traveler'
import { FlightDetails } from '../../flight-details'
import { Price } from '../../price'
import { useTripFlight } from '../../use-trip-flight'
import { ContentWrapper } from './trip-flight-content-desktop-layout-styles'

export type TripFlightContentProps = {
  flight: FlightPostBookingValueObject
  travelerName?: string
  onClose: () => void
  tripType: TripStatus
  cancellationDate?: string | null
  onCancel: () => void
  startDate: string
  endDate: string
  tripName?: string | null
  processId: string
  itineraryId: string
  flightId?: string | null
  flightEmission: FlightEmission
  transactionRegistryServiceItemId?: string | null
  onSeatMapOpen: (segmentLegId?: string | null) => void
}

export const TripFlightContentDesktopLayout = observer(function TripFlightContentDesktopLayout({
  onClose,
  tripType,
  cancellationDate,
  tripName,
  startDate,
  endDate,
  processId,
  itineraryId,
  flight,
  flightId,
  flightEmission,
  transactionRegistryServiceItemId,
  onSeatMapOpen,
  travelerName,
}: TripFlightContentProps) {
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const { featureFlags } = useFeatureFlags()
  const { transactionFees, segments, rate, taxes, totalCost } = flight

  // TODO: update isCheckInAvailable after check-in work done
  const isCheckInAvailable = true

  const {
    destination,
    destinationAirport,
    isMultipleFlights,
    originAirport,
    headerSubtitle,
    checkInData,
    isTripCancelled,
    pnrNumber,
    confirmationNumber,
    viewState,
    onCloseViewState,
    onOpen,
    isSeatChangeAllowed,
    isCompleted,
    closeIconAriaLabel,
    handleStartMobilitySearchFlow,
    segmentStatus,
    isFlightCancelled,
  } = useTripFlight({
    segments,
    tripType,
    tripName,
    flight,
    startDate,
    endDate,
    processId,
    itineraryId,
    flightId,
    onSeatMapOpen,
    onClose,
  })
  const { searchRideHailStore, searchRideHailActions } = useRideHailSearchContext()

  const headerBackgroundColor = getTripDetailsSegmentBackground({
    tripStatus: tripType,
    segmentStatus,
  })

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor={headerBackgroundColor}
            rightSlot={
              <IconButton
                aria-label={closeIconAriaLabel}
                icon="CloseFilledDarkPWA"
                size="medium"
                onClick={onClose}
              />
            }></Header>
        </Modal.Header>
        <DetailsContainer color={headerBackgroundColor}>
          <Headline
            destination={destination}
            isMultipleFlights={isMultipleFlights}
            subTitle={headerSubtitle}
          />
        </DetailsContainer>
        <div ref={anchorRef} />
        <ContentWrapper>
          <Traveler
            isCompleted={isCompleted}
            pnrNumber={pnrNumber}
            isCheckInAvailable={isCheckInAvailable}
            isTripCancelled={isTripCancelled}
            isFlightCancelled={isFlightCancelled}
            cancellationDate={cancellationDate}
            confirmationNumber={confirmationNumber}
            checkInData={checkInData}
            travelerName={travelerName || '-'}
          />

          {featureFlags.isMobilityDesktopEnabled && (
            <Block marginTop={16}>
              <MobilityCallToAction
                processId={processId}
                dropOffSegmentId={transactionRegistryServiceItemId}
                tripType={tripType}
                segmentType={flight.type}
                onStartMobilitySearchFlow={handleStartMobilitySearchFlow}
              />
            </Block>
          )}
          {featureFlags.isMobilityDesktopEnabled && (
            <MobilitySearchFlowModal
              isOpen={searchRideHailStore.isOpenMobilitySearchDesktopModal}
              onClose={searchRideHailActions.closeMobilitySearchDesktopModal}
            />
          )}
          <Block marginTop={16}>
            {segments &&
              segments.map((segment, index) => {
                const nextSegment = segments[index + 1]
                const nextFlightStart = nextSegment ? (nextSegment.departureDate as string) : ''
                return (
                  <FlightDetails
                    key={segment.flightNumber}
                    segment={segment}
                    nextSegment={segments[index + 1]}
                    legNumber={index + 1}
                    legs={segments.length}
                    nextFlightStart={nextFlightStart}
                    isTripCancelled={isTripCancelled}
                    isSeatChangeAllowed={isSeatChangeAllowed}
                    isFlightCancelled={isFlightCancelled}
                    onSeatMapOpen={onOpen}
                  />
                )
              })}
          </Block>
          {featureFlags.isSustainabilityEnabled && (
            <Block marginTop={25} marginBottom={4}>
              <FlightDetailsEcoCheck {...flightEmission} />
            </Block>
          )}
          <Block marginTop={16} marginBottom={28}>
            <Price
              originAirport={originAirport}
              destinationAirport={destinationAirport}
              rate={rate}
              taxes={taxes}
              total={totalCost}
              transactionFees={transactionFees}
            />
          </Block>
        </ContentWrapper>
      </Modal.Body>
      <ViewStateModal viewState={viewState} onDone={onCloseViewState} />

      <WarningCancelRoundFlightModal />
    </>
  )
})
