import { useTranslation } from 'react-i18next'
import type { CheckboxOption } from '@fiji/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { PreferencesLabel } from '@etta/components/preferences-label'
import { SpecialRequestUnavailable } from './special-request-unavailable'
import { SpecialRequestOptions } from './special-request-options'

export type Props = {
  options?: CheckboxOption[] | null
  values?: string[]
  label?: string
  onChange: (values: string[]) => void
  errorText?: string
}

export function PreferenceSpecialRequests({ values, options, label, onChange, errorText }: Props) {
  const { t } = useTranslation()
  return (
    <Block paddingBottom={26}>
      <Block marginBottom={12} isFlexBlock>
        <PreferencesLabel icon="specialRequestPWA">
          {`${label || t('PreferenceSpecialRequests.SpecialRequests')} ${t(
            'PreferenceSpecialRequests.UpToThree',
          )}`}
        </PreferencesLabel>
        {errorText && (
          <Block marginLeft={8}>
            <Text variant="captionMedium" color="error">
              {errorText}
            </Text>
          </Block>
        )}
      </Block>
      {!options || options.length === 0 ? (
        <SpecialRequestUnavailable />
      ) : (
        <SpecialRequestOptions values={values} options={options} onChange={onChange} />
      )}
    </Block>
  )
}
