import { Block } from '@etta/ui/block'
import { Container, CarRentalCardSkeleton } from './car-rental-loading-placeholder-mobile-styled'

export function CarRentalLoadingPlaceholderMobile() {
  return (
    <Block paddingHorizontal={16} paddingVertical={16}>
      <Container>
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
        <CarRentalCardSkeleton />
      </Container>
    </Block>
  )
}
