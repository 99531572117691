import type { MutableRefObject, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDescendantsWrapper } from '@etta/components/descendant'
import { SearchField, SearchFieldContainer, StyledIconButton } from './search-input-styled'

type Props = {
  isResultsListOpen: boolean
  searchInputRef: MutableRefObject<HTMLInputElement>
  containerProps: object
  inputProps: object
  isDisabled: boolean
  placeholder: string
  value: string
  onReset: () => void
}

export function SearchInput({
  isResultsListOpen,
  searchInputRef,
  containerProps,
  inputProps,
  isDisabled,
  placeholder,
  value,
  onReset,
}: Props) {
  const { t } = useTranslation()
  const { shouldFocusFirstElement, handleFocusFirstListElement } = useDescendantsWrapper()

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' && !shouldFocusFirstElement) {
      handleFocusFirstListElement()
    }
  }

  return (
    <SearchFieldContainer {...containerProps} isOpen={isResultsListOpen}>
      <SearchField
        ref={searchInputRef}
        {...inputProps}
        disabled={isDisabled}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        aria-label={t('TripPlanner.BaseSearch.Input.PlaceAriaLabel', {
          label: placeholder,
        })}
      />
      {value && (
        <StyledIconButton
          width={40}
          height={40}
          onClick={onReset}
          color="bodyText"
          icon="closeFilledPWA"
          aria-label={t('TripPlanner.BaseSearch.Input.Clear')}
        />
      )}
    </SearchFieldContainer>
  )
}
