import { useState } from 'react'
import type { ImgHTMLAttributes } from 'react'

type ImageProps = ImgHTMLAttributes<HTMLImageElement>

const Image = ({ src, alt, ...props }: ImageProps) => {
  const [showImage, setShowImage] = useState<boolean>(true)

  const handleError = () => {
    setShowImage(false)
  }

  return <>{src && showImage && <img {...props} src={src} alt={alt} onError={handleError} />}</>
}

export { Image }
export type { ImageProps }
