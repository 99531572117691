import styled from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const ExpirationContainer = styled.div`
  position: relative;
  & > :nth-child(2) {
    width: 400px;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  ${getKeyboardNavigationStyle({ borderRadius: 10, offset: 0 })}
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 513px;
`

export const OneColumn = styled.div``

export const TwoColumns = styled.div`
  display: flex;
  gap: 12px;

  & > div {
    width: 250.5px;
  }
`
