import { useTranslation } from 'react-i18next'
import type { Phone } from '@fiji/graphql/types'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import type { PhoneValueObject } from '@etta/modules/booking/core/value-objects/phone.value-object'
import type { CheckoutInfoStore } from '@etta/modules/booking/interface/checkout-info/checkout-info.store'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { getSegmentsExisting } from '../use-load-traveler-info/get-segments-existing'

const isPhoneValid = (phone: PhoneValueObject | Phone | undefined): boolean => {
  return !!phone?.countryCode === !!phone?.number
}

function requiredFieldsValidation(
  checkoutInfoStore: CheckoutInfoStore,
  reviewTripStore: ReviewTripStore,
): {
  isPhoneError: boolean | undefined
  isGenderError: boolean | undefined
  isDateOfBirthError: boolean | undefined
  isAddressError: boolean | undefined
} {
  const segmentsExisting = getSegmentsExisting(reviewTripStore.segments)

  const { isPhoneNumberRequired, isGenderRequired, isAddressRequired, isDateOfBirthRequired } =
    checkoutInfoStore.primaryTraveler.travelerFields || {}
  const isPhoneDefined =
    !!checkoutInfoStore.primaryTraveler.travelerInfo.phone?.number ||
    !!checkoutInfoStore.primaryTraveler.travelerInfo.personalMobile?.number

  const isConfiguredPhoneValid =
    isPhoneValid(checkoutInfoStore.primaryTraveler.travelerInfo.phone) &&
    isPhoneValid(checkoutInfoStore.primaryTraveler.travelerInfo.personalMobile)

  const isGenderDefined = !!checkoutInfoStore.primaryTraveler.travelerInfo.gender
  const isDOBDefined = !!checkoutInfoStore.primaryTraveler.travelerInfo.dateOfBirth
  const isAddressDefined =
    !!checkoutInfoStore.primaryTraveler.travelerInfo.address?.city &&
    !!checkoutInfoStore.primaryTraveler.travelerInfo.address?.countryCode &&
    !!checkoutInfoStore.primaryTraveler.travelerInfo.address?.postalCode &&
    !!checkoutInfoStore.primaryTraveler.travelerInfo.address?.street1

  return {
    isPhoneError: !isConfiguredPhoneValid || (isPhoneNumberRequired && !isPhoneDefined),
    isGenderError: (isGenderRequired || segmentsExisting.isRailExist) && !isGenderDefined,
    isDateOfBirthError: (isDateOfBirthRequired || segmentsExisting.isRailExist) && !isDOBDefined,
    isAddressError: isAddressRequired && !isAddressDefined,
  }
}

export function useProfileIncompleteMsg(): string {
  const { t } = useTranslation()
  const { checkoutInfoStore } = useCheckoutInfoContext()
  const { reviewTripStore } = useReviewTripContext()

  const {
    isPhoneError,
    isGenderError,
    isDateOfBirthError,
    isAddressError,
  } = requiredFieldsValidation(checkoutInfoStore, reviewTripStore)
  const individualErrors = [
    isPhoneError && t('TravelerInfo.Phone'),
    isGenderError && t('TravelerInfo.Gender'),
    isDateOfBirthError && t('TravelerInfo.DOB'),
    isAddressError && t('TravelerInfo.Address'),
  ].filter((value): value is string => !!value)
  return individualErrors.length > 0
    ? `${t(`TravelerInfo.ProfileErrorMsg`)} (${individualErrors.join(', ')})`
    : ''
}
