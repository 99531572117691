import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { Props } from '../../layout'
import { TravelerDocumentsList } from '../../traveler-document-list'

export function TravelerDocumentsMobile({ isOpen, onClose, title, ...props }: Props) {
  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={isOpen}
      handleModalVisibility={onClose}
      position="center"
      withOverlayForMobile
      isHalfModal>
      <Modal.Header withBorder isMobile>
        <Header minHeight={64} align="center" leftSlot={<Header.CloseButton onClick={onClose} />}>
          <Header.Title title={title} align="left" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <TravelerDocumentsList {...props} />
      </Modal.Body>
    </Modal>
  )
}
