import { useTranslation } from 'react-i18next'
import type { PermissionTranslation } from '../shared'

export function useLocationDisabledTranslation(): PermissionTranslation {
  const { t } = useTranslation()

  return {
    title: t('Permissions.LocationDisabled.Title'),
    text: t('Permissions.LocationDisabled.Text'),
    buttonText: t('Permissions.LocationDisabled.ButtonText'),
  }
}
