import { observer } from 'mobx-react-lite'
import { CSSTransition } from 'react-transition-group'
import { useSeatMapContext } from '@etta/modules/seat-map/interface/use-seat-map-context'
import { Legends } from './legends'
import { LegendButton } from './legend-button'
import { Container, TRANSITION_DURATION, TRANSITION_NAME } from './footer-styled'
import { SeatAction } from './seat-action'

type Props = {
  onClose?: () => void
}

export const Footer = observer(function Footer({ onClose }: Props) {
  const { seatMapStore, seatMapPaginationStore, seatMapPaginationActions } = useSeatMapContext()
  const { moveToNextSeatMap } = seatMapPaginationActions
  const { isLastSegmentInFlightLeg } = seatMapPaginationStore
  const {
    legendsToggle,
    bookedSeat,
    isReadonly,
    seatMap: { isLoading },
  } = seatMapStore
  const { isOpen: legendsIsOpen, handleOpen, handleClose } = legendsToggle

  const hasLegendButton = !isLoading && (!legendsIsOpen || isReadonly)
  const hasSeatAction = !isLoading && !legendsIsOpen && !isReadonly

  return (
    <CSSTransition
      in
      timeout={TRANSITION_DURATION}
      classNames={TRANSITION_NAME}
      appear
      unmountOnExit>
      {(state) => (
        <Container>
          <LegendButton isVisible={hasLegendButton} isReadonly={isReadonly} onClick={handleOpen} />
          <Legends isVisible={legendsIsOpen} onClick={handleClose} />
          <SeatAction
            seatFeature={bookedSeat?.feature}
            isLastSegmentInFlightLeg={isLastSegmentInFlightLeg}
            isVisible={hasSeatAction && state === 'entered'}
            onClick={moveToNextSeatMap}
            onClose={onClose}
          />
        </Container>
      )}
    </CSSTransition>
  )
})
