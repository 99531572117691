import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { RailOpenReturnDetails } from '@etta/components/rail-open-return-details'
import { NDCFareBadge } from '@etta/components/air-badges/components'
import type { LayoutProps } from '../../types'
import { useSegmentCardStatus } from '../../use-segment-card-status'
import {
  Item,
  Header,
  Body,
  HeaderDate,
  CardTitle,
  SubTitle,
  Status,
  CardRow,
  CancelationBlock,
  CancelationIcon,
  CanceleationText,
  InfoLine,
} from './new-segment-card-mobile-styled'

export function NewSegmentCardMobile({
  segmentIcon,
  headerDate,
  cardTitle,
  cardSubTitle,
  label,
  segmentStatus,
  cardInfo,
  ariaLabel,
  onClick,
  segmentType,
  shouldShowAirportChangeBadge,
  flightLegSegments,
  cancellationPolicyLabel,
  handleOpenRateAndCancellationDetails = () => {},
  isOpenReturn,
  isNDCFare,
  openReturnProperties,
  infoLine,
}: LayoutProps) {
  const { displayStatus, statusColor } = useSegmentCardStatus({ label, segmentStatus })
  const cardStatus = cardSubTitle ? `• ${displayStatus}` : displayStatus

  return (
    <Item
      role="button"
      aria-label={ariaLabel}
      onClick={onClick}
      data-tracking-id={`trip-${segmentType}-segment-card`}>
      <Header>
        <Icon name={segmentIcon} size="medium" color="bodyText" />
        <HeaderDate>{headerDate}</HeaderDate>
      </Header>
      <Body>
        <CardTitle>{cardTitle}</CardTitle>
        {infoLine && <InfoLine>{infoLine}</InfoLine>}
        <SubTitle>
          {cardSubTitle}
          {displayStatus && <Status color={statusColor}>{cardStatus}</Status>}
        </SubTitle>
        {cardInfo?.map((info) => (
          <CardRow key={info?.toString()}>{info}</CardRow>
        ))}
        {shouldShowAirportChangeBadge && !!flightLegSegments?.length && (
          <Block marginTop={8}>
            <AirportChangeBadge flightLegSegments={flightLegSegments} size="small" isMaxWidth />
          </Block>
        )}
        {isOpenReturn && <RailOpenReturnDetails {...openReturnProperties} />}
        {cancellationPolicyLabel && (
          <CancelationBlock
            onClick={(e) => {
              e.preventDefault()
              handleOpenRateAndCancellationDetails()
            }}>
            <CancelationIcon />
            <CanceleationText>{cancellationPolicyLabel}</CanceleationText>
          </CancelationBlock>
        )}
        {isNDCFare && <NDCFareBadge />}
      </Body>
    </Item>
  )
}
