import { cloneElement } from 'react'
import type { LayoutProps } from '../types'
import { MobileOverlay } from './modal-mobile-styled'

export function ModalMobile({
  children,
  animationSlot,
  containerSlot,
  withOverlayForMobile,
  onCloseOverlay,
}: LayoutProps) {
  return (
    <>
      {withOverlayForMobile && <MobileOverlay onClick={onCloseOverlay} />}
      {cloneElement(animationSlot, {
        children: cloneElement(containerSlot, { children }),
      })}
    </>
  )
}
