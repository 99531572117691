import { css } from 'styled-components'
import { MODAL_TRANSITION_TIMEOUT, MODAL_ANIMATION } from './constants'

export const MODAL_TRANSITION_SLIDE_DOWN = 'MODAL_SLIDE_UP'

export const slideDown = css`
  transition: transform ${MODAL_TRANSITION_TIMEOUT}ms ${MODAL_ANIMATION};

  transform: translateY(-100%);

  &.${MODAL_TRANSITION_SLIDE_DOWN}-appear, &.${MODAL_TRANSITION_SLIDE_DOWN}-enter {
    transform: translateY(-100%);
  }

  &.${MODAL_TRANSITION_SLIDE_DOWN}-appear-done,
    &.${MODAL_TRANSITION_SLIDE_DOWN}-enter-done,
    &.${MODAL_TRANSITION_SLIDE_DOWN}-appear-active,
    &.${MODAL_TRANSITION_SLIDE_DOWN}-enter-active {
    transform: translateY(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_DOWN}-exit-done, &.${MODAL_TRANSITION_SLIDE_DOWN}-exit {
    transform: translateY(0%);
  }

  &.${MODAL_TRANSITION_SLIDE_DOWN}-exit-active {
    transform: translateY(-100%);
  }
`
