import { Action, Inject } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import type {
  ChangeableFilters,
  NonArrayValue,
} from '../../core/value-objects/selected-filters.value-object'
import { HotelSearchFormQueryService } from '../services/hotel-search-form-query.service'
import { LoadHotelsService } from '../services/load-hotels.service'
import { HotelFiltersStore } from '../stores/hotel-filters.store'
import { HotelQueryStore } from '../stores/hotel-search-query.store'
import { HotelSearchResultsStore } from '../stores/hotel-search-results.store'

@Action()
export class HotelFiltersAction {
  constructor(
    @Inject()
    private readonly hotelFiltersStore: HotelFiltersStore,
    @Inject()
    private readonly hotelQueryStore: HotelQueryStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly loadHotelsService: LoadHotelsService,
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  clearStarRatingFilter() {
    this.hotelFiltersStore.dropStarRating()
  }

  clearBrandIds() {
    this.hotelFiltersStore.dropBrandIds()
  }

  clearAmenities() {
    this.hotelFiltersStore.dropAmenities()
  }

  clearGroupId() {
    this.hotelFiltersStore.dropGroupId()
  }

  async handleApplyFilters() {
    const newFilters = this.hotelFiltersStore.selectedFilters

    this.hotelSearchResultsStore.setOffset(0)
    this.hotelSearchResultsStore.setIsLoading(true)

    if (this.hotelQueryStore.hotelSearchQuery.hotelFilters?.distance === newFilters.distance) {
      await this.handleApplyFiltersWithoutDistanceChange()
      return
    }
    await this.handleApplyFiltersWithDistanceChange()
  }

  handleAmenitiesSearchQueryChange(value: string) {
    this.hotelSearchResultsStore.setAmenitySearchQuery(value)
  }

  async handleClearAllFilters() {
    await this.hotelFiltersStore.dropFiltersToInitial()
  }

  async handleDropFiltersWithQueryUpdate() {
    await this.hotelFiltersStore.dropFiltersWithQueryUpdate()
  }

  handleOpenFiltersModal() {
    const filters = this.hotelQueryStore.hotelSearchQuery.hotelFilters

    if (!filters) {
      return
    }

    this.hotelFiltersStore.setFilters(filters)

    this.hotelFiltersStore.filterModal.handleOpen()
  }

  handleCloseFiltersModal() {
    this.hotelFiltersStore.filterModal.handleClose()
  }

  handleSetFilter<T extends keyof ChangeableFilters>(key: T, value: NonArrayValue<T>) {
    this.hotelFiltersStore.handlers[key](value)
  }

  handleSetBrandIds(value: string[]) {
    this.hotelFiltersStore.setBrandIds(value)
  }

  private async handleApplyFiltersWithDistanceChange() {
    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.appendQueryParams({
          hotelFilters: this.hotelFiltersStore.selectedFilters,
        }),
      )
    })

    if (this.displayConfigurationStore.isHotelSearchServiceEnabled) {
      await this.loadHotelsService.loadSearchId()
    }

    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  private async handleApplyFiltersWithoutDistanceChange() {
    await new Promise((res) => {
      res(
        this.hotelSearchFormQueryService.appendQueryParams({
          hotelFilters: this.hotelFiltersStore.selectedFilters,
        }),
      )
    })

    await this.loadHotelsService.loadHotels()

    this.hotelSearchResultsStore.setIsLoading(false)
  }

  /**
   * @deprecated do not use it
   */
  handleSetStarRating(value: number[]) {
    const currentFilters = this.hotelQueryStore.hotelSearchQuery.hotelFilters

    if (!currentFilters) {
      return
    }

    this.hotelFiltersStore.setFilters({
      ...currentFilters,
      starRatings: value,
    })
  }

  /**
   * @deprecated do not use it
   */
  handleSetAmenities(value: string[]) {
    const currentFilters = this.hotelQueryStore.hotelSearchQuery.hotelFilters

    if (!currentFilters) {
      return
    }
    this.hotelFiltersStore.setFilters({
      ...currentFilters,
      amenityIds: value,
    })
  }
}
