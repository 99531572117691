import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  word-wrap: break-word;
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium};

  * {
    color: ${(p) => p.theme.colors.bodyText} !important;
  }
`
