/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react'
import { useCopyToClipboard } from 'react-use'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import type { IconColorPalette, IconSizes } from '@etta/ui/icon/types'
import { Tooltip } from './tooltip'

const CLOSE_TIMEOUT = 800

type Props = {
  value?: string
  color?: IconColorPalette
  size?: IconSizes
}

export function ButtonCopyClipboard({ value, color, size = 'medium' }: Props) {
  const [, copyToClipboard] = useCopyToClipboard()
  const { t } = useTranslation()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const openTooltip = useCallback(() => setIsTooltipOpen(true), [])
  const closeTooltip = useCallback(() => setIsTooltipOpen(false), [])
  const handleClose = useCallback(() => {
    if (!value) {
      return
    }
    copyToClipboard(value)
    openTooltip()

    window.setTimeout(() => {
      closeTooltip()
    }, CLOSE_TIMEOUT)
  }, [value])

  return (
    <>
      <IconButton
        icon="copyPWA"
        color={color}
        onClick={handleClose}
        size={size}
        aria-label={t('Accessibility.CopyToClipboard')}
      />
      <Tooltip isOpen={isTooltipOpen} />
    </>
  )
}
