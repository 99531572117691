import styled from 'styled-components'
import { captionMedium, subHeadStrong } from '@fiji/style'

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const TravelerCell = styled.div`
  width: 100%;
`

export const StatusCell = styled.div`
  width: 100%;
  margin-left: 16px;
`

export const CellName = styled.div`
  color: ${(p) => p.theme.colors.bodyText};
  ${captionMedium};
`

export const CellValue = styled.div`
  margin-top: 4px;
`

export const ButtonCell = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: ${(p) => p.theme.colors.primary1};
`

export const ButtonCellValue = styled.div`
  max-width: 240px;
  ${subHeadStrong};
`
