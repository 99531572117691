import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { TripHotelContentProps } from './trip-hotel-content'
import { TripHotelContent } from './trip-hotel-content'

type Props = TripHotelContentProps & {
  isVisible: boolean
  onClose: () => void
  isAdaptivePosition?: boolean
}

export function TripHotel({ isVisible, onClose, isAdaptivePosition, ...props }: Props) {
  const type = useScreenType()

  const modalPosition = isAdaptivePosition && type !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      backgroundColor="background"
      position={modalPosition}
      horizontalDimension="content-760">
      <TripHotelContent {...props} onClose={onClose} />
    </Modal>
  )
}
