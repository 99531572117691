import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { useCovidInformation } from '../../../use-covid-information'
import { useCovidInformationDetails } from '../../use-covid-information-details'
import { LinkWithIcon, Hr } from '../covid-information-details-layout-styled'

export function CovidInformationDetailsMobile() {
  const { getTranslation } = useCovidInformation()
  const { links } = useCovidInformationDetails()
  return (
    <>
      <Modal.Body>
        <Block marginTop={24} marginHorizontal={16}>
          <Block marginBottom={8}>
            <Text
              aria-label={getTranslation('.VirusGlobalCases')}
              variant="headline"
              color="mainText">
              {getTranslation('.VirusGlobalCases')}
            </Text>
          </Block>
          <Block marginBottom={8}>
            <Text
              aria-label={getTranslation('.VirusGlobalCasesText')}
              variant="body"
              color="bodyText">
              {getTranslation('.VirusGlobalCasesText')}
            </Text>
          </Block>
          <Block marginBottom={16}>
            <Link
              aria-label={getTranslation('.SeeMoreDetails')}
              size="small"
              href={links.covidMap}
              target="_blank">
              {getTranslation('.SeeMoreDetails')}
            </Link>
          </Block>
          <Hr />
          <Block marginBottom={20}>
            <LinkWithIcon
              aria-label={getTranslation('.GetHelp')}
              size="small"
              href={links.getHelp}
              target="_blank">
              <Icon name="infoPWA" color="primary1" />
              <span>{getTranslation('.GetHelp')}</span>
            </LinkWithIcon>
          </Block>
          <Block marginBottom={20}>
            <LinkWithIcon
              aria-label={getTranslation('.HowCanIHelp')}
              size="small"
              href={links.donate}
              target="_blank">
              <Icon name="peoplePWA" color="primary1" />
              <span>{getTranslation('.HowCanIHelp')}</span>
            </LinkWithIcon>
          </Block>
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Block marginBottom={36} marginHorizontal={16}>
          <Text
            aria-label={getTranslation('.Copyright')}
            align="center"
            variant="footnoteMedium"
            color="bodyText">
            {getTranslation('.Copyright')}
          </Text>
        </Block>
      </Modal.Footer>
    </>
  )
}
