import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { BaggageInfoSegment } from '@fiji/types'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Row } from '../../flight-details-styled'
import {
  Cell,
  CellIcon,
  CellName,
  CellValue,
  SectionColumn,
  ChangeSeatButton,
  FullWidthCell,
} from './class-details-styled'

type Props = {
  cabinClass: string
  seat: string
  baggage: string
  onSeatMapOpen: () => void
  isTripCancelled: boolean
  areDetailsShown: boolean
  isSeatChangeAllowed?: boolean | null
  isFlightCancelled?: boolean | null
  baggageInfo: BaggageInfoSegment
}

const i18nBase = 'PostBooking.FlightDetails'
const i18nBaseBaggageInfo = 'BaggageInformation'

export function CabinClass({
  cabinClass,
  seat,
  baggage,
  onSeatMapOpen,
  isTripCancelled,
  areDetailsShown,
  isSeatChangeAllowed,
  isFlightCancelled,
  baggageInfo,
}: Props) {
  const { t } = useTranslation()
  const changeButtonText = t(`${i18nBase}.ChangeSeat`)
  const changeButtonLabel = t(`${i18nBase}.Button`, { label: changeButtonText })

  const isBaggageInfoDetailsUnavailable = Boolean(
    baggageInfo.baggageFees?.items === null || baggageInfo.baggageFees?.items?.length === 0,
  )

  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const shouldShowBaggageInfoUnavailableGuidance = isMobile && isBaggageInfoDetailsUnavailable

  return (
    <SectionColumn>
      <Separator lineType="dashed" />
      <Block marginTop={12} marginBottom={16}>
        <Row>
          <Cell aria-label={`${t(`${i18nBase}.Class`)} ${cabinClass}`}>
            <CellIcon name="economyPWA" color="bodyText" />
            <Block>
              <CellName>{t(`${i18nBase}.Class`)}</CellName>
              <CellValue data-tracking-id="cabin-class-text">{cabinClass}</CellValue>
            </Block>
          </Cell>
          <Cell aria-label={`${t(`${i18nBase}.Seat`)} ${seat}`}>
            <Block>
              <CellName data-tracking-id="seat-text">{t(`${i18nBase}.Seat`)}</CellName>
              <CellValue>
                <span data-tracking-id="seat-info-text">{seat}</span>
                {isSeatChangeAllowed && (
                  <ChangeSeatButton
                    aria-label={changeButtonLabel}
                    size="small"
                    disabled={!areDetailsShown || !!isFlightCancelled}
                    onClick={onSeatMapOpen}
                    data-tracking-id="change-seat-button">
                    {changeButtonText}
                  </ChangeSeatButton>
                )}
              </CellValue>
            </Block>
          </Cell>
        </Row>
      </Block>

      {!isTripCancelled && (
        <>
          <Row>
            <Cell aria-label={`${t(`${i18nBase}.BaggageClaim`)} ${baggage}`}>
              <CellIcon name="checkInBagPWA" color="bodyText" />
              <Block>
                <CellName data-tracking-id="baggage-claim-text">
                  {t(`${i18nBase}.BaggageClaim`)}
                </CellName>
                <CellValue data-tracking-id="baggage-claim-details-text">{baggage}</CellValue>
              </Block>
            </Cell>
          </Row>
          {shouldShowBaggageInfoUnavailableGuidance && (
            <Row>
              <FullWidthCell>
                <CellIcon name="noCheckedBagInfoPWA" color="bodyText" />
                <Block>
                  <Text variant="footnoteMedium" color="bodyText">
                    {t(`${i18nBaseBaggageInfo}.CheckedBaggageInfoUnavailableFull`)}&nbsp;
                    {baggageInfo.baggageFees?.carrierUrl ? (
                      <Link size="small" href={baggageInfo.baggageFees?.carrierUrl} target="_blank">
                        {baggageInfo.name}
                        <Icon name="externalLinkPWA" color="primary" size={16} />
                      </Link>
                    ) : (
                      <>{t(`${i18nBaseBaggageInfo}.CheckAirlineWebsite`)}</>
                    )}
                  </Text>
                </Block>
              </FullWidthCell>
            </Row>
          )}
        </>
      )}
    </SectionColumn>
  )
}
