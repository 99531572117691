import { SegmentType } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import {
  getCarRangeRTP,
  getFlightRangeRTP,
  getHotelRangeRTP,
  getTrainRangeRTP,
} from './selectors/selectors-rtp'
import { convertRangeToQuery } from './convert-range-to-query'

type Args = {
  forType: SegmentType
  segments: TripSegmentValueObject[]
}

function getRange({ segments, forType }: Args) {
  switch (forType) {
    default:
    case SegmentType.Flight:
      return getFlightRangeRTP(segments)
    case SegmentType.CarRental:
      return getCarRangeRTP(segments)
    case SegmentType.Hotel:
      return getHotelRangeRTP(segments)
    case SegmentType.Train:
      return getTrainRangeRTP(segments)
  }
}

export function getQueryParamsRTP({ segments, forType }: Args) {
  const range = getRange({ segments, forType })
  return convertRangeToQuery(range)
}
