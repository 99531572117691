import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { subHeadStrong } from '@fiji/style'

export const Item = styled.div<{ isDisabled?: boolean; isRound?: boolean }>`
  margin: 0;
  border: none;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: ${(p) => (p.isDisabled ? p.theme.colors.bodyText2 : p.theme.colors.mainText)};

  &:active {
    background-color: ${(p) => p.theme.colors.background1};
  }
  ${({ isRound }) =>
    isRound &&
    css`
      margin-bottom: 20px;
      background: ${({ theme }) => theme.colors.white};
      border-radius: 14px;
    `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ItemContent = styled.div`
  width: 100%;
  margin-left: 8px;
`

export const SectionTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  line-height: 20px;
  text-align: left;
  ${subHeadStrong};
`

export const DateLine = styled.div`
  display: flex;
  gap: 4px;
`

export const Status = styled(Text).attrs(({ color }) => ({
  variant: 'captionStrong',
  color,
}))``
