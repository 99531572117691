import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const Description = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'subHeadMedium',
  color: 'bodyText',
}))``
