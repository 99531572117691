import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { HotelPhotosCarousel } from '@etta/components/hotel-photos-carousel/hotel-photos-carousel'
import { PhotoSize } from '@fiji/graphql/types'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { HotelBadges } from '../../../hotel-badges'
import type { HotelDetailsHeaderProps } from '../../types'
import {
  CloseButton,
  HeaderSticky,
  HeaderTop,
  HotelOptions,
  HotelTitle,
  CloseButtonTop,
} from './hotel-details-header-mobile-styled'

const scrollConfig = {
  scrollAfterValue: 0.35,
  scrollAfterOffset: 6,
}

export function HotelDetailsHeaderMobile({
  scrollContainerRef,
  hotelDetails,
  onClose,
}: HotelDetailsHeaderProps) {
  const { anchorRef, scrollContainerRef: setScrollContainerRef, headerRef } = useHeaderScroll(
    scrollConfig,
  )
  useEffect(() => {
    setScrollContainerRef(scrollContainerRef?.current)
  }, [scrollContainerRef, setScrollContainerRef])

  const { name, photos } = hotelDetails

  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  return (
    <>
      <HotelPhotosCarousel
        photos={photos.filter((photo) => photo.size === PhotoSize.Large) || []}
      />
      <CloseButtonTop
        icon="closeFilledPWA"
        size="large"
        onClick={onClose}
        aria-label={t('Accessibility.Header.Close')}
      />
      <HeaderSticky ref={headerRef} data-tracking-id="segment-details-modal-header">
        <CloseButton
          size={'medium'}
          icon="closePWA"
          color="mainText"
          onClick={onClose}
          aria-label={t('Accessibility.Header.Close')}
        />
        <HeaderTop>
          <HotelTitle aria-label={t(`${i18Base}.HotelNameAriaLabel`, { hotelName: name })}>
            {name}
          </HotelTitle>
          <HotelOptions>
            <HotelBadges hotelDetails={hotelDetails} />
          </HotelOptions>
        </HeaderTop>
      </HeaderSticky>

      <div ref={anchorRef} />
    </>
  )
}
