import styled from 'styled-components'
import { captionStrongCaps } from '@fiji/style'

export const Container = styled.div`
  margin-top: 16px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 16px;
  overflow: hidden;
`

export const Title = styled.div`
  ${captionStrongCaps};
  color: ${(p) => p.theme.colors.bodyText};
  padding: 10px 16px;
`

export const Content = styled.div`
  padding: 16px;
`
