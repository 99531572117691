import { DataProvider } from '@etta/di'

import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  PartnerCoBrandingConfigurationQuery,
  PartnerCoBrandingConfigurationQueryVariables,
} from '@fiji/graphql/hooks'
import { PartnerCoBrandingConfigurationDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class PartnerCoBrandingConfigurationsDataProvider extends GraphqlDataProvider {
  getPartnerCoBrandingConfigurations(input: PartnerCoBrandingConfigurationQueryVariables['input']) {
    return this.client.query<
      PartnerCoBrandingConfigurationQuery,
      PartnerCoBrandingConfigurationQueryVariables
    >({
      query: PartnerCoBrandingConfigurationDocument,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    })
  }
}
