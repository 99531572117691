import * as yup from 'yup'
import { Validator } from '@etta/interface/services/validator'
import i18n from '@fiji/i18n'
import { validatePhoneInput } from '@fiji/utils/phone/format-validation'
import { AddressFields } from '../core/enums/address-fields'
import { FieldSettingsCategory } from '../core/enums/field-settings-category'
import type { FieldSettingsStore } from '../interface/stores/field-settings'

const i18nBase = 'Settings.AccountInformation.Modal'
const i18nBaseErrors = 'Settings.Errors'

const nullableString = Validator.scheme.string().nullable().default('')
const nullableBoolean = Validator.scheme.boolean().nullable().default(null)

export class ValidatorMaker {
  private fieldSettingsStore: FieldSettingsStore

  constructor(fieldSettingsStore: FieldSettingsStore) {
    this.fieldSettingsStore = fieldSettingsStore
  }
  makeHomeAddressValidator() {
    return new Validator({
      [AddressFields.Country]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Zip]: Validator.scheme.string().nullable().default(''),
      [AddressFields.State]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Mailstop]: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mailStop',
            FieldSettingsCategory.HomeAddress,
            i18n.t(`${i18nBase}.Mailstop`),
          ),
        )
        .nullable()
        .default(''),
      [AddressFields.City]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street1]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street2]: Validator.scheme.string().nullable().default(''),
      homeEmail: Validator.scheme
        .string()
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
            fieldName: i18n.t(`${i18nBase}.HomeEmail`),
          }),
        )
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homeEmail',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.HomeEmail`),
          ),
        )
        .nullable()
        .default(''),
      homePhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homePhone',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.HomePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.homePhoneCountryCode),
        )
        .nullable()
        .default(''),
      homePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homePhone',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.HomePhone`),
          ),
        )
        .nullable()
        .default(''),
    })
  }

  makeBusinessAddressValidator() {
    return new Validator({
      [AddressFields.Country]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Zip]: Validator.scheme.string().nullable().default(''),
      [AddressFields.State]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Mailstop]: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mailStop',
            FieldSettingsCategory.BusinessAddress,
            i18n.t(`${i18nBase}.Mailstop`),
          ),
        )
        .nullable()
        .default(''),
      [AddressFields.City]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street1]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street2]: Validator.scheme.string().nullable().default(''),
      businessEmail: Validator.scheme
        .string()
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
            fieldName: i18n.t(`${i18nBase}.BusinessEmail`),
          }),
        )
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'businessEmail',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.BusinessEmail`),
          ),
        )
        .nullable()
        .default(''),
      groupwareId: Validator.scheme
        .string()
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
            fieldName: i18n.t(`${i18nBase}.GroupwareID`),
          }),
        )
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'groupwareId',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.GroupwareID`),
          ),
        )
        .nullable()
        .default(''),
      officePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'officePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.OfficePhone`),
          ),
        )
        .nullable()
        .default(''),
      officePhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'officePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.OfficePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.officePhoneCountryCode),
        )
        .nullable()
        .default(''),
      officePhoneExtension: Validator.scheme.string().nullable().default(''),
      faxPhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'faxPhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.FaxPhone`),
          ),
        )
        .nullable()
        .default(''),
      faxPhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'faxPhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.FaxPhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.faxPhoneCountryCode),
        )
        .nullable()
        .default(''),
      mobilePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mobilePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.MobilePhone`),
          ),
        )
        .nullable()
        .default(''),
      mobilePhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mobilePhone',
            FieldSettingsCategory.BusinessContact,
            i18n.t(`${i18nBase}.MobilePhone`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.mobilePhoneCountryCode),
        )
        .nullable()
        .default(''),
      confirmationEmail: Validator.scheme
        .string()
        .email(
          i18n.t(`${i18nBaseErrors}.InvalidEmailField`, {
            fieldName: i18n.t(`${i18nBase}.ConfirmationEmail`),
          }),
        )
        .nullable()
        .default(''),
    })
  }

  makePersonalInformationValidator() {
    return new Validator({
      title: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'title',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Title`),
          ),
        )
        .default(null),
      firstName: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'firstName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.FirstName`),
          ),
        )
        .default(''),
      middleName: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'middleName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.MiddleName`),
          ),
        )
        .default(''),
      lastName: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'lastName',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.LastName`),
          ),
        )
        .default(''),
      suffix: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'suffix',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Suffix`),
          ),
        )
        .default(null),
      gender: Validator.scheme
        .string()
        .nullable()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'gender',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Gender`),
          ),
        )
        .default(null),
      dateOfBirth: Validator.scheme
        .date()
        .optional()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'dateOfBirth',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.DateOfBirth`),
          ),
        )
        .default(undefined),
      email: Validator.scheme
        .string()
        .email()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'email',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Email`),
          ),
        )
        .default(''),
      username: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'username',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.Username`),
          ),
        )
        .default(''),
      profilePin: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'profilePin',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.ProfilePin`),
          ),
        )
        .default(''),
      homePhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homePhone',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.PhoneNumber`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.homePhoneCountryCode),
        )
        .nullable()
        .default(''),
      homePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homePhone',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.CountryCode`),
          ),
        )
        .nullable()
        .default(''),
    })
  }

  // TODO: Check if it's needed to render on profile setting page
  makeEmployeeInformationValidator() {
    return new Validator({
      isVip: Validator.scheme
        .boolean()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'vipIndicator',
            FieldSettingsCategory.PersonalInformation,
            i18n.t(`${i18nBase}.VIPIndicator`),
          ),
        )
        .default(false),
      isActiveInCompany: nullableBoolean.when(
        '$',
        this.fieldSettingsStore.isFieldRequired(
          'employeeStatus',
          FieldSettingsCategory.EmployeeInformation,
          i18n.t(`${i18nBase}.EmployeeStatus`),
        ),
      ),
      employeeType: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'employeeType',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.EmployeeType`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'employeeType',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'employeeType' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'employeeType',
              value,
            }),
        ),
      employeeId: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'employeeID',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.EmployeeID`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'employeeID',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'employeeID' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'employeeID',
              value,
            }),
        ),
      jobTitle: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'jobTitle',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.JobTitle`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'jobTitle',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'jobTitle' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'jobTitle',
              value,
            }),
        ),
      jobLevel: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'jobLevel',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.JobLevel`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'jobLevel',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'jobLevel' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'jobLevel',
              value,
            }),
        ),
      managerId: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'managerID',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.ManagerID`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'managerID',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'managerID' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'managerID',
              value,
            }),
        ),
      costCenter: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'costCenter',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.CostCenter`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'costCenter',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'costCenter' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'costCenter',
              value,
            }),
        ),
      departmentCode: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'departmentCode',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.DepartmentCode`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'departmentCode',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'departmentCode' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'departmentCode',
              value,
            }),
        ),
      departmentName: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'departmentName',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.DepartmentName`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'departmentName',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'departmentName' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'departmentName',
              value,
            }),
        ),
      division: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'divisionCode',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.Division`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) => {
          return this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'divisionCode',
            value,
          })
        })
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'divisionCode' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'divisionCode',
              value,
            }),
        ),
      businessUnit: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'businessUnit',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.BusinessUnit`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'businessUnit',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'businessUnit' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'businessUnit',
              value,
            }),
        ),
      companyName: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'companyName',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.CompanyName`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'companyName',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'companyName' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'companyName',
              value,
            }),
        ),
      expenseApproverId: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'expenseApproverName',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.ExpenseApproverID`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'expenseApproverName',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () =>
            this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'expenseApproverName' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'expenseApproverName',
              value,
            }),
        ),
      purchaseApproverId: Validator.scheme.string(),
      travelApproverId: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'travelApproverID',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.TravelApproverID`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'travelApproverID',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'travelApproverID' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'travelApproverID',
              value,
            }),
        ),
      mis1: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis1',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField1`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis1',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis1' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis1',
              value,
            }),
        ),
      mis2: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis2',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField2`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis2',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis2' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis2',
              value,
            }),
        ),
      mis3: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis3',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField3`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis3',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis3' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis3',
              value,
            }),
        ),
      mis4: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis4',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField4`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis4',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis4' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis4',
              value,
            }),
        ),
      mis5: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis5',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField5`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis5',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis5' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis5',
              value,
            }),
        ),
      mis6: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis6',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField6`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis6',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis6' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis6',
              value,
            }),
        ),
      mis7: nullableString
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mis7',
            FieldSettingsCategory.EmployeeInformation,
            i18n.t(`${i18nBase}.MISField7`),
          ),
        )
        .test('are-characters-valid', i18n.t(`Settings.Errors.InvalidCharacters`), (value) =>
          this.fieldSettingsStore.getAllowedCharactersCheck({
            field: 'mis7',
            value,
          }),
        )
        .test(
          'are-characters-in-allowed-range',
          () => this.fieldSettingsStore.getTextLengthCheckErrorMsg({ field: 'mis7' }),
          (value) =>
            this.fieldSettingsStore.getTextLengthCheck({
              field: 'mis7',
              value,
            }),
        ),
    })
  }

  makeEmergencyContactValidator() {
    return new Validator({
      name: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'name',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.FullName`),
          ),
        )
        .nullable()
        .default(''),
      relationship: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'relationship',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Relationship`),
          ),
        )
        .nullable()
        .default(''),
      primaryPhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'primaryPhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.CountryCode`),
          ),
        )
        .nullable()
        .default(''),
      primaryPhoneExt: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'primaryPhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.PrimaryPhoneNumber`),
          ),
        )
        .nullable()
        .default(''),
      primaryPhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'primaryPhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.PrimaryPhoneNumber`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.primaryPhoneCountryCode),
        )
        .nullable()
        .default(''),
      alternatePhoneCountryCode: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'alternatePhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.CountryCode`),
          ),
        )
        .nullable()
        .default(''),
      alternatePhoneExt: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'alternatePhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.SecondaryPhoneNumber`),
          ),
        )
        .nullable()
        .default(''),
      alternatePhoneNumber: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'alternatePhone',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.SecondaryPhoneNumber`),
          ),
        )
        .test(
          'correct-phone-number-length',
          i18n.t(`${i18nBaseErrors}.InvalidPhone`),
          (value, context) => validatePhoneInput(value, context.parent.alternatePhoneCountryCode),
        )
        .nullable()
        .default(''),
      email: Validator.scheme
        .string()
        .email(i18n.t(`${i18nBaseErrors}.InvalidEmail`))
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'email',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Email`),
          ),
        )
        .nullable()
        .default(''),
      [AddressFields.City]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Country]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Zip]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Mailstop]: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'mailStop',
            FieldSettingsCategory.EmergencyContact,
            i18n.t(`${i18nBase}.Mailstop`),
          ),
        )
        .nullable()
        .default(''),
      [AddressFields.State]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street1]: Validator.scheme.string().nullable().default(''),
      [AddressFields.Street2]: Validator.scheme.string().nullable().default(''),
    })
  }

  // TODO: Check if it's needed to render on profile setting page
  makeFlightPreferencesValidator() {
    return new Validator({
      homeAirport: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'homeAirport',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.HomeAirport`),
          ),
        )
        .default(''),
      mealPreference: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'airMeal',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.Meal`),
          ),
        )
        .nullable()
        .default(''),
      seatPreference: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'airSeatPreference',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.SeatPreference`),
          ),
        )
        .default('WINDOW'),
      airSpecialRequest: Validator.scheme
        .array()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'airSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            const isRequired = this.fieldSettingsStore.isFieldRequiredBool(
              'airSpecialRequest',
              FieldSettingsCategory.TravelPreferences,
            )

            if (isRequired) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default([]),
      noteToArranger: Validator.scheme
        .string()
        .when(
          '$',
          this.fieldSettingsStore.isFieldRequired(
            'airTravelAgencyNote',
            FieldSettingsCategory.TravelPreferences,
            i18n.t(`${i18nBase}.TravelAgencyNote`),
          ),
        )
        .default(''),
      airMembership: Validator.scheme.array().default([]),
    })
  }

  // TODO: Check if it's needed to render on profile setting page
  makeHotelPreferencesValidator() {
    return new Validator({
      specialRequest: Validator.scheme
        .array()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'hotelSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            const isRequired = this.fieldSettingsStore.isFieldRequiredBool(
              'hotelSpecialRequest',
              FieldSettingsCategory.TravelPreferences,
            )

            if (isRequired) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default([]),
      membership: Validator.scheme.array().default([]),
      amenity: Validator.scheme.array().default([]), // not used in UI, to be removed after GQL schema update
    })
  }

  // TODO: Check if it's needed to render on profile setting page
  makeCarrentalPreferencesValidator() {
    return new Validator({
      carClass: Validator.scheme.string().nullable().default(undefined),
      specialRequest: Validator.scheme
        .array()
        .test(
          'has-elements',
          () =>
            i18n.t(`${i18nBaseErrors}.FieldIsRequired`, {
              fieldLabel: this.fieldSettingsStore.getFieldLabel(
                'carRentalSpecialRequest',
                FieldSettingsCategory.TravelPreferences,
                i18n.t(`${i18nBase}.SpecialRequests`),
              ),
            }),
          (value) => {
            const isRequired = this.fieldSettingsStore.isFieldRequiredBool(
              'carRentalSpecialRequest',
              FieldSettingsCategory.TravelPreferences,
            )

            if (isRequired) {
              return !!value && value.length > 0
            }

            return true
          },
        )
        .default([]),
      membership: Validator.scheme
        .array()
        .of(
          yup.object({
            number: yup.string(),
            vendorCode: yup.string(),
          }),
        )
        .default([]),
    })
  }
}
