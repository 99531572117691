import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import type { Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { CostWithCtaLayout } from './layout'

type Props = {
  rate?: Rate | null
  label?: string
  buttonText?: string
  isError?: boolean
  isLoading?: boolean
  onSubmit: () => Promise<void> | void
  onHoldTrip?: () => void
  extraText?: string | ReactNode
  isDisabled?: boolean
  isHoldFlow?: boolean
  holdButtonText?: string
  isCostDisplayed?: boolean
  withAllignContentToBottom?: boolean
  isHoldButtonVisible?: boolean
  // isRailAccreditationEnabled?: boolean
  'data-tracking-id'?: string
}

export function CostWithCta({
  rate,
  label,
  buttonText,
  extraText,
  onSubmit,
  isError,
  isHoldFlow,
  isLoading,
  isDisabled,
  holdButtonText,
  onHoldTrip,
  isHoldButtonVisible,
  isCostDisplayed = true,
  withAllignContentToBottom = false,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'CostWithCTA'
  const { mainCost } = formatRate(rate)
  const usedCost = isError ? '--' : mainCost
  const isCtaDisabled = isError || isLoading || isDisabled

  return (
    <CostWithCtaLayout
      holdButtonText={holdButtonText}
      onHoldTrip={onHoldTrip}
      isHoldButtonVisible={isHoldButtonVisible}
      rate={rate}
      usedCost={usedCost}
      label={label || t(`${i18Base}.TotalEstimatedCost`)}
      buttonText={buttonText || t(`${i18Base}.Continue`)}
      extraText={extraText}
      isDisabled={isCtaDisabled}
      isHoldFlow={isHoldFlow}
      isCostDisplayed={isCostDisplayed}
      onSubmit={onSubmit}
      data-tracking-id={dataTrackingId}
      withAllignButtonToBottom={withAllignContentToBottom}
    />
  )
}
