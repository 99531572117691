import { TextField } from '@etta/ui/text-field'
import { Text } from '@etta/ui/text'
import { CardIcon } from '../../../card-icon'
import { EditCardButton } from '../../../edit-card-button'
import { Edit } from '../../../edit'
import type { LayoutProps } from '../types'
import {
  CardInfoContainer,
  CardName,
  RowContainer,
  CardContainer,
  CVVFieldWrapper,
  CVVField,
} from './row-mobile-styled'

export function RowMobile({
  cardVendor,
  cardName,
  title,
  CVVValue,
  CVVHelperText,
  editLabel,
  layout,
  cardInformationColor,
  withEditIcon,
  withPadding,
  isChecked,
  isSelectable,
  isEditable,
  isVirtualPayRestricted,
  isCvvRequired,
  onChange,
  onEdit,
  onCVVFieldChange,
  isDisabled,
}: LayoutProps) {
  return (
    <CardContainer withPadding={withPadding}>
      <RowContainer withPaddingBottom={isCvvRequired}>
        <Edit
          isDisabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange}
          isSelectable={isSelectable}>
          <CardIcon cardVendor={cardVendor} />
          <CardInfoContainer layout={layout}>
            <CardName layout={layout}>{cardName}</CardName>
            {title && (
              <Text variant="footnoteMedium" color={cardInformationColor}>
                {title}
              </Text>
            )}
          </CardInfoContainer>
        </Edit>
        <EditCardButton
          isDisabled={isDisabled}
          editLabel={editLabel}
          isEditable={isEditable}
          isVirtualPayRestricted={isVirtualPayRestricted}
          withEditIcon={withEditIcon}
          onEdit={onEdit}
          withChevron={layout === 'summary'}
        />
      </RowContainer>
      {isCvvRequired && (
        <CVVFieldWrapper>
          <CVVField>
            <TextField
              type="cvv"
              value={CVVValue || ''}
              helperText={CVVHelperText}
              onChange={(value) => onCVVFieldChange?.('securityCode')(value)}
            />
          </CVVField>
        </CVVFieldWrapper>
      )}
    </CardContainer>
  )
}
