import styled from 'styled-components'
import { getKeyboardNavigationStyle } from '@fiji/style'

export const DateOfBirthWrapper = styled.div`
  position: relative;
  & > :nth-child(2) {
    width: 400px;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  ${getKeyboardNavigationStyle({ borderRadius: 10, offset: 0 })}
`
