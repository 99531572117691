import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { slideUp } from './transition'

export const SheetContainer = styled.div`
  width: 100%;
  height: 65%;
  top: -1;
  bottom: 0;
  position: absolute;
  z-index: 22;
`
export const SheetContent = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  ${slideUp};
`

export const MiniPill = styled.div`
  border-radius: 50rem;
  border: 2px solid ${(p) => p.theme.colors.borderDark};
  height: 1px;
  width: 36px;
  margin-top: 5px;
  margin-bottom: 15px;
`

export const HeaderContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  width: 100%;
`

export const SubHeaderContent = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  display: flex;
`

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ScrollContainer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
`
export const NextButtonWrapper = styled(Button)`
  margin-bottom: 16px;
  width: 90%;
`

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`
