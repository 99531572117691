import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { useRecentSearchesContext } from '../../../interface/use-recent-searches.context'
import {
  Container,
  Header,
  HeaderText,
  List,
} from './air-search-form-mobile-recent-searches-styled'
import { AirSearchFormMobileRecentSearchItems } from './air-search-form-mobile-recent-search-items'

export const AirSearchFormMobileRecentSearches = observer(
  function AirSearchFormMobileRecentSearches() {
    const { recentSearchesStore } = useRecentSearchesContext()
    const { t } = useTranslation()
    const i18nBase = 'RecentSearches'

    if (
      !recentSearchesStore.isShowFlightRecentSearch &&
      !recentSearchesStore.isShowFlightRecentSearchWithoutDates
    ) {
      return null
    }

    return (
      <Container>
        <Block paddingHorizontal={16}>
          <Header>
            <Icon name="timePWA" color="bodyText" />
            <HeaderText>{t(`${i18nBase}.Title`)}</HeaderText>
          </Header>
        </Block>
        <List>
          <AirSearchFormMobileRecentSearchItems />
        </List>
      </Container>
    )
  },
)
