import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { SpaceBetween } from '../../../rtp-price-summary-styled'
import { SectionDescription } from '../../payable-sections-styled'

type Props = {
  totalCarRentalTripCost: string
  hasCarRentalTripCost: boolean
}

export function CarRentalSection({ totalCarRentalTripCost, hasCarRentalTripCost }: Props) {
  const { t } = useTranslation()
  const i18nBasePath = 'ReviewTrip.PriceSummary'

  if (!hasCarRentalTripCost) {
    return null
  }

  return (
    <>
      <Block marginTop={6} />
      <SpaceBetween>
        <SectionDescription aria-label={t(i18nBasePath + '.CarRental')}>
          {t(i18nBasePath + '.CarRental')}
        </SectionDescription>
        <SectionDescription aria-label={totalCarRentalTripCost}>
          {totalCarRentalTripCost}
        </SectionDescription>
      </SpaceBetween>
    </>
  )
}
