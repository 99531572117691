import { SegmentType } from '@etta/core/enums'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import {
  getCarRangePostBooking,
  getFlightRangePostBooking,
  getTrainRangePostBooking,
  getHotelRangePostBooking,
} from './selectors'
import { convertRangeToQuery } from './convert-range-to-query'

type Args = {
  forType: SegmentType
  segments: SegmentPostBookingValueObject[]
}

function getRange({ segments, forType }: Args) {
  switch (forType) {
    default:
    case SegmentType.Flight:
      return getFlightRangePostBooking(segments)
    case SegmentType.CarRental:
      return getCarRangePostBooking(segments)
    case SegmentType.Hotel:
      return getHotelRangePostBooking(segments)
    case SegmentType.Train:
      return getTrainRangePostBooking(segments)
  }
}

export function getQueryParamsPostBooking({ segments, forType }: Args) {
  const range = getRange({ segments, forType })
  return convertRangeToQuery(range)
}
