import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import type { Place, PlainTime as Time } from '@fiji/types'
import { useUberUniversalLink } from '@fiji/hooks/mobility/use-uber-universal-link'
import {
  Container,
  DescriptionContainer,
  QRCodeRender,
  QRContainer,
} from './uber-tracking-ride-via-qr-code-styled'

type Props = {
  pickUpPlace?: Place
  dropOffPlace?: Place
  pickUpDate?: Date
  pickUpTime?: Time
  isUberWithParam: boolean
  title: string
}
export const UberTrackingRideViaQRCode = observer(function UberTrackingRideViaQRCode({
  pickUpPlace,
  dropOffPlace,
  pickUpDate,
  pickUpTime,
  isUberWithParam,
  title,
}: Props) {
  const { uberUrlWithoutParams, uberUrl } = useUberUniversalLink({
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  })
  return (
    <>
      <Container>
        <DescriptionContainer>
          <QRContainer>
            <QRCodeRender
              size={256}
              value={isUberWithParam ? uberUrl : uberUrlWithoutParams}
              viewBox={`0 0 256 256`}
            />
          </QRContainer>
          <Text variant="title3" color="mainText">
            {title}
          </Text>
        </DescriptionContainer>
      </Container>
    </>
  )
})
