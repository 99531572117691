import styled from 'styled-components'
import { linkSmall, UnstyledButton } from '@fiji/style'

export const EcoCheckSummaryContainer = styled.div`
  margin-top: 25px;
  padding: 0 16px;
`

export const List = styled.div`
  margin: -21px 16px 0px;
  border-radius: 14px;
  background-color: ${(p) => p.theme.colors.white};
  transition: height 200ms ease-in;
`

export const Column = styled.div`
  padding: 12px 0px;
  margin: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const TwoColumns = styled.div`
  padding: 12px 0px;
  margin: 0px 16px;
  display: flex;
  justify-content: space-between;

  & div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const ShowMoreButton = styled(UnstyledButton)`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  ${linkSmall}
`
