import moment from 'moment'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { UseControlsFooterParams } from './types'

export function useControlsFooterLabels({ date, dateEnd }: UseControlsFooterParams) {
  const type = useScreenType()
  const isMobile = type === ScreenType.Mobile

  const startDateTitle = date ? formatDate(date, isMobile) : ''
  const endDateTitle = dateEnd ? formatDate(dateEnd, isMobile) : ''

  function formatDate(date: Date, _?: boolean) {
    const format = 'MMM DD'
    return moment(date).format(format)
  }

  return { date: startDateTitle, dateEnd: endDateTitle }
}
