import { useCallback, useEffect, useRef, useState } from 'react'
import type Slider from 'react-slick'

type Props = {
  currentSlide?: number
  onSlideChange?: (selected: number) => void
}

const START_CAROUSEL_NUMBER = 0

export const useCarousel = ({ currentSlide, onSlideChange }: Props) => {
  const [isDragging, setDragging] = useState(false)
  const [selectedNumber, setSelectedNumber] = useState(START_CAROUSEL_NUMBER)
  const sliderRef = useRef<Slider>(null)
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentSlide!)
    }
  }, [currentSlide, sliderRef])

  const handleCounterChange = useCallback(
    (_: number, next: number) => {
      if (currentSlide === next) {
        return
      }

      if (onSlideChange && currentSlide !== next) {
        onSlideChange(next)
      } else {
        setSelectedNumber(next)
      }
    },
    [onSlideChange, currentSlide],
  )
  const handleBeforeChange = useCallback(
    (previous: number, next: number) => {
      if (previous === next) {
        return
      }

      setDragging(true)
      handleCounterChange(previous, next)
    },
    [handleCounterChange],
  )
  const handleAfterChange = useCallback(() => {
    setDragging(false)
  }, [])

  return {
    sliderRef,
    selectedNumber,
    isDragging,
    handleBeforeChange,
    handleAfterChange,
  }
}
