import styled from 'styled-components'
import { IconButton } from '@etta/ui/icon-button'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 32px;
`

export const Header = styled.div`
  padding: 12px 20px 13px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
`
export const CarIconContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButtonTop = styled(IconButton)`
  width: 26px;
  height: 26px;
  background: ${(props) => props.theme.colors.bodyText};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AmenitiesWrapper = styled.div`
  display: flex;
`

export const AmenitiesContainer = styled.div`
  width: 100%;
  min-height: 166px;
  position: relative;
  padding: 24px 0 24px 32px;
`

export const AmenitiesList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 0;
  margin: 0;
`

export const AmenitiesItem = styled.li`
  display: flex;
  align-items: center;
  flex: 0 1 25%;
`

export const AmenitiesIcon = styled.div`
  margin-right: 10px;
`

export const CarImage = styled.img`
  width: 100%;
`

export const CarImageContainer = styled.div`
  display: flex;
  width: 400px;
  margin-right: 32px;
`
