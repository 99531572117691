import type { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import { SegmentType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Badge } from '@etta/ui/badge'
import { formatRate } from '@fiji/utils/money/format-rate'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { Tooltip } from '@etta/ui/tooltip'
import { WebFareTooltipContainer } from '@etta/components/air-badges/components/webfare/webfare'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { RailOpenReturnDetails } from '@etta/components/rail-open-return-details'
import { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import type { RtpItineraryCardProps } from '../../types'
import { RailActions } from '../../rail-actions'
import { RTPItineraryCardBaggageInformation as BaggageInformation } from '../../baggage-information/baggage-information'
import {
  Container,
  Header,
  HeaderDateContainer,
  StyledDate,
  Cost,
  CostWrapper,
  Body,
  Title,
  List,
  BaggageContainer,
  BaggageLink,
  Footer,
  FooterBadge,
  SelectReasonButton,
  OopDesc,
  OopLine,
  DeleteLink,
  ActionContainer,
  CardStatus,
  CardStatusBlock,
  ChevronIconContainer,
  PrimaryLink,
  ChangePriceInfo,
  PriceIcon,
  PriceText,
  TrashIcon,
  StaticActionText,
  ViewDetailsTarget,
  InfoWrapper,
  ImageWrapper,
} from './rtp-itinerary-card-desktop-styled'

const i18nBase = 'ReviewTrip'

export const RTPItineraryCardDesktop: FunctionComponent<RtpItineraryCardProps> = function ({
  isWebFare,
  serviceType,
  fullStartDate: startDateComplete,
  startDate,
  endDate,
  longDateLabel,
  rate,
  titleSlot,
  informationSlot,
  operatedBySlot,
  children,
  onClick,
  outOfPolicyExp,
  isOutOfPolicy = false,
  hasComplianceCode = false,
  isInvalid = false,
  onSelectReason,
  showAddBaggageModal,
  isCheckoutActivated,
  changeLinkText,
  onChange,
  onDelete,
  actions,
  cardStatus = 'default',
  isPriceIncluded = false,
  safetyCheckSlot,
  bodyAriaLabel,
  airPriceChangeInfo,
  strongTextSlot,
  showSeatMapUnavailableBadge,
  'data-tracking-id': dataTrackingId,
  selectedBaggageOption,
  showLuggageOptions,
  flightLegSegments,
  shouldShowAirportChangeBadge,
  railActions,
  seatPreferencesSlot,
  isWarningIcon,
  isNDCSegment,
  appliedRailCard,
  unusedTicketSlot,
  isRailOpenReturn,
  openReturnProperties,
  vendor,
}) {
  const { featureFlags } = useFeatureFlags()
  const { reviewTripStore } = useReviewTripContext()

  const isPriceInteger = serviceType !== SegmentType.Train

  const { mainCost } = formatRate(rate, { morpheme: 'none', isInteger: isPriceInteger })
  const rateWithUnusedTicket = rate ? reviewTripStore.getTotalRateWithUnusedTickets(rate) : null
  const { mainCost: fotmattedRateWithUnusedTicket } = formatRate(rateWithUnusedTicket, {
    morpheme: 'none',
    isInteger: isPriceInteger,
  })

  const { mainCost: changeCost } = formatRate(airPriceChangeInfo?.rate, { morpheme: 'none' })

  const isUnusedTicketApplicable =
    featureFlags.isDesktopUnusedTicketsEnabled && !!reviewTripStore.unusedTicketsRate

  const { t } = useTranslation()
  const iconName = getIconNameBySegment(serviceType)
  const date = [startDate, endDate].filter(Boolean).join(' - ')
  let titleHeader = longDateLabel || date
  if (isRailOpenReturn) {
    titleHeader += t(`${i18nBase}.OpenReturn.HeaderText`)
  }
  const isComponentInvalid =
    isInvalid || !!(isOutOfPolicy && !outOfPolicyExp && !hasComplianceCode && isCheckoutActivated)
  const isCardChanged = cardStatus === 'changed'
  const isCardAdded = cardStatus === 'added'
  const outOfPolicyLabel = t(`${i18nBase}.OutOfPolicy`) + `${outOfPolicyExp ? ':' : ''}`
  const isShowingCardStatusBlock = isCardChanged || isCardAdded
  const activeRailActions =
    serviceType !== SegmentType.Train ? [] : railActions?.filter((action) => action.isActive)

  const { mainCost: selectedBaggageCost } = formatRate(selectedBaggageOption?.cost, {
    morpheme: 'none',
  })

  return (
    <Container data-tracking-id={dataTrackingId} hasBorder={isComponentInvalid}>
      <Header hasBorder>
        <HeaderDateContainer aria-label={titleHeader}>
          {iconName && <Icon name={iconName} color="bodyText" size="medium" />}
          <StyledDate data-tracking-id="flight-date-text">{titleHeader}</StyledDate>
          {isShowingCardStatusBlock && (
            <CardStatusBlock>
              {isCardChanged && <CardStatus>{t('ReviewTripPWA.Card.Changed')}</CardStatus>}
              {isCardAdded && <CardStatus>{t('ReviewTripPWA.Card.Added')}</CardStatus>}
            </CardStatusBlock>
          )}
          {isWarningIcon && <Icon name="warningFilledPWA" color="error" />}
        </HeaderDateContainer>
        {airPriceChangeInfo?.type && (
          <ChangePriceInfo>
            <Swap
              is={airPriceChangeInfo.type === PriceChangeTypeValueObject.Increase}
              isSlot={
                <>
                  <PriceText variant="footnoteMedium" color="bodyText">
                    {t('ReviewTripPWA.PriceChange.PriceIncreasedBy')} {changeCost}
                  </PriceText>{' '}
                  <PriceIcon name="upArrowPWA" color="error" />
                </>
              }>
              <>
                <PriceText variant="footnoteMedium" color="bodyText">
                  {t('ReviewTripPWA.PriceChange.PriceDecreasedBy')} {changeCost}
                </PriceText>{' '}
                <PriceIcon name="upArrowPWA" withRotate color="success" />
              </>
            </Swap>
          </ChangePriceInfo>
        )}
        {isPriceIncluded ? (
          <Text variant="captionStrong" color="bodyText">
            {t(`${i18nBase}.PriceIncluded`)}
          </Text>
        ) : (
          <CostWrapper>
            {isWebFare && (
              <Block marginRight={12}>
                <Tooltip slot={<WebFareTooltipContainer />} place="top" isIndependentOfParentWidth>
                  <Icon name="directBookingIcon" size={25} />
                </Tooltip>
              </Block>
            )}
            {isNDCSegment && mainCost && (
              <Block marginLeft="auto" paddingRight={6}>
                <Tooltip
                  slot={
                    <Text style={{ whiteSpace: 'pre-wrap' }} variant="footnoteStrong" isBlock>
                      {t(`${i18nBase}.Tooltip.NDCFare`)}
                    </Text>
                  }
                  place="top"
                  isIndependentOfParentWidth>
                  <Icon name="NDCFarePWA" size="normal" />
                </Tooltip>
              </Block>
            )}
            {appliedRailCard && (
              <Tooltip
                isIndependentOfParentWidth
                slot={
                  <>
                    <Text variant="subHeadStrong">{appliedRailCard}</Text>
                    <Text variant="subHeadMedium">&nbsp;{t(`${i18nBase}.Applied`)}</Text>
                  </>
                }>
                <Icon name="loyaltyCardPWA" color="success" />
              </Tooltip>
            )}
            <Cost
              data-tracking-id="flight-cost-text"
              isCrossedOut={isUnusedTicketApplicable}
              aria-label={mainCost}>
              {mainCost}
            </Cost>
            {isUnusedTicketApplicable && (
              <Cost data-tracking-id="flight-cost-text" aria-label={fotmattedRateWithUnusedTicket}>
                {fotmattedRateWithUnusedTicket}
              </Cost>
            )}
          </CostWrapper>
        )}
      </Header>
      <Body aria-label={bodyAriaLabel} hasBorderRadius={!isOutOfPolicy}>
        <ViewDetailsTarget onClick={onClick}>
          <Title>
            {titleSlot}{' '}
            <ChevronIconContainer>
              <Text variant="linkSmall" color="bodyText">
                {t(`${i18nBase}.ViewDetails`)}
              </Text>
              <Icon
                name="chevronRightPWA"
                color="bodyText"
                aria-label={t('Accessibility.Header.ArrowRight')}
              />
            </ChevronIconContainer>
          </Title>
          <Block marginBottom={8}>{safetyCheckSlot}</Block>
          {strongTextSlot && (
            <Block marginBottom={10}>
              <Text
                variant="headline"
                color="mainText"
                data-tracking-id="rtp-itinerary-card-desktop-segment-title">
                {strongTextSlot}
              </Text>
            </Block>
          )}
          <InfoWrapper>
            <div>
              <List>{informationSlot}</List>
              {operatedBySlot && <List>{operatedBySlot}</List>}
              {!isRailOpenReturn && <List>{children}</List>}
              {isRailOpenReturn && <RailOpenReturnDetails {...openReturnProperties} />}
              {seatPreferencesSlot && <List>{seatPreferencesSlot}</List>}
            </div>
            {vendor?.imageUrl && (
              <ImageWrapper>
                <img src={vendor.imageUrl} alt={vendor.name} />
              </ImageWrapper>
            )}
          </InfoWrapper>
        </ViewDetailsTarget>
        {showLuggageOptions && (
          <BaggageContainer onClick={showAddBaggageModal}>
            <Icon name="baggageIconPWA" color={selectedBaggageOption ? 'bodyText' : 'primary'} />
            {selectedBaggageOption ? (
              <>
                <Text variant="footnoteStrong" textOverflow="ellipsis-two-line">
                  {selectedBaggageOption.value}{' '}
                  {selectedBaggageCost ? `(${selectedBaggageCost})` : ''}
                </Text>
                <BaggageLink margin="0 8px">{t(i18nBase + '.Change')}</BaggageLink>
              </>
            ) : (
              <BaggageLink>{t(i18nBase + '.AddBaggage')}</BaggageLink>
            )}
          </BaggageContainer>
        )}
        {shouldShowAirportChangeBadge && flightLegSegments?.length && (
          <Block marginTop={8}>
            <AirportChangeBadge flightLegSegments={flightLegSegments} />
          </Block>
        )}
        {unusedTicketSlot}
        <BaggageInformation flightLegSegments={flightLegSegments} />
        {startDateComplete && isFuture(startDateComplete) && (
          <ActionContainer>
            {showSeatMapUnavailableBadge && <Badge variant="seatMapNotAvailable" iconSize={18} />}
            {activeRailActions?.map((action) => (
              <RailActions key={action.key} action={action} isDesktop />
            ))}
            {actions?.map(({ onClick, label }) => {
              if (!label) {
                return null
              }

              if (!onClick) {
                return (
                  <StaticActionText
                    key={label}
                    data-tracking-id={`rtp-itinerary-card-text_${label}`}>
                    {label}
                  </StaticActionText>
                )
              }

              return (
                <PrimaryLink
                  size="small"
                  key={label}
                  onClick={onClick}
                  aria-label={t('ReviewTrip.ButtonsLabel', { label })}
                  data-tracking-id={`rtp-itinerary-card-link_${label}`}>
                  {label}
                </PrimaryLink>
              )
            })}
            {onChange && changeLinkText && (
              <PrimaryLink
                size="small"
                onClick={onChange}
                aria-label={t('ReviewTrip.ButtonsLabel', { label: changeLinkText })}
                data-tracking-id="rtp-itinerary-card-change-link">
                {changeLinkText}
              </PrimaryLink>
            )}
            {onDelete && (
              <DeleteLink
                aria-label={t('ReviewTrip.DeleteIconLabel', { label: 'DeletePWA' })}
                onClick={onDelete}
                data-tracking-id="rtp-itinerary-card-delete-link">
                <TrashIcon />
              </DeleteLink>
            )}
          </ActionContainer>
        )}
      </Body>
      {isOutOfPolicy && (
        <Footer
          isInvalid={isComponentInvalid}
          data-tracking-id={'segment-policy-block'}
          isOutOfPolicyExp={Boolean(outOfPolicyExp)}>
          <FooterBadge>
            <Icon name="outOfPolicyPWA" />
            <OopLine aria-label={outOfPolicyLabel}>{outOfPolicyLabel}</OopLine>
          </FooterBadge>
          {outOfPolicyExp ? (
            <OopDesc>
              <OopLine>{outOfPolicyExp}</OopLine>
              <PrimaryLink size="small" onClick={onSelectReason}>
                {t(`${i18nBase}.EditReason`)}
              </PrimaryLink>
            </OopDesc>
          ) : (
            !hasComplianceCode && (
              <SelectReasonButton
                aria-label={t('ReviewTrip.ButtonsLabel', {
                  label: t(`${i18nBase}.SelectReason`),
                })}
                onClick={onSelectReason}>
                {t(`${i18nBase}.SelectReason`)}
              </SelectReasonButton>
            )
          )}
        </Footer>
      )}
    </Container>
  )
}
