import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 513px;
`

export const Banner = styled.div`
  padding: 12px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.secondary};
`
