import { HotelListSustainabilityLevel } from '@etta/components/hotel-list-sustainability-level/hotel-list-sustainability-score'
import type { CalculateEmissionsResultHotelCertification } from '@fiji/graphql/types'
import {
  HotelSustainabilityDeviationContainer,
  Loader,
} from './hotel-sustainability-deviation-styled'

type EmissionDeviationProps = {
  sustainabilityScore?: number | null
  averageLocationSustainabilityScore?: number
  certifications?: CalculateEmissionsResultHotelCertification[]
  loading: boolean
  isEcoFriendly?: boolean | null
}

export function HotelSustainabilityDeviation({
  sustainabilityScore,
  averageLocationSustainabilityScore,
  certifications,
  loading,
  isEcoFriendly,
}: EmissionDeviationProps) {
  if (loading) {
    return (
      <HotelSustainabilityDeviationContainer>
        <Loader />
      </HotelSustainabilityDeviationContainer>
    )
  }

  if (!!sustainabilityScore && !!averageLocationSustainabilityScore) {
    return (
      <HotelSustainabilityDeviationContainer>
        <HotelListSustainabilityLevel
          sustainabilityScore={sustainabilityScore}
          averageLocationSustainabilityScore={averageLocationSustainabilityScore}
          certifications={certifications || []}
          isEcoFriendly={isEcoFriendly}
        />
      </HotelSustainabilityDeviationContainer>
    )
  }

  return null
}
