import { useShareViaEmail } from '@fiji/hooks/use-share-via-email/use-share-via-email'
import { templates } from '@fiji/hooks/use-share-via-email'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'

type Props = {
  tripName?: string | null
  flight: FlightPostBookingValueObject
  startDate: string
  endDate: string
}

export function useActionButtons({ tripName, flight, startDate, endDate }: Props) {
  const handleShare = useShareViaEmail({
    subject: templates.postBookingSubject({ tripName, startDate, endDate }),
    body: templates.postBookingFlight({ segments: flight.segments }),
  })

  return { handleShare }
}
