/* eslint-disable react-hooks/exhaustive-deps */
import type { ForwardedRef } from 'react'
import { forwardRef, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import type { IPlayerProps } from '@lottiefiles/react-lottie-player'
import { Player } from '@lottiefiles/react-lottie-player'
import type { AnimationItem } from './types'

export type LottieAnimationProps = Omit<IPlayerProps, 'lottieRef'>

function LottieAnimationRender(
  { autoplay = false, keepLastFrame = true, ...props }: LottieAnimationProps,
  ref: ForwardedRef<null | AnimationItem>,
) {
  const setRef = useCallback((animation: AnimationItem) => {
    if (!ref) {
      return
    }

    if (typeof ref === 'function') {
      ref(animation)
    }
    if ('current' in ref) {
      ref.current = animation
    }
  }, [])

  return (
    <Player
      lottieRef={setRef}
      autoplay={autoplay}
      keepLastFrame={keepLastFrame}
      renderer="svg"
      id={uuid()}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
        progressiveLoad: true,
      }}
      {...props}
    />
  )
}

export const LottieAnimation = forwardRef(LottieAnimationRender)
