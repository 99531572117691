import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import BackendHttp from 'i18next-http-backend'

i18n
  .use(BackendHttp)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    lowerCaseLng: true,
    debug: false,
    initImmediate: false,
    backend: {
      loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-store',
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
