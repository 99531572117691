import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { formatTravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { DepartureAndArrival, ChangesLine } from './rail-departure-and-arrival-open-return-styled'

type Props = {
  rail: TrainSegmentEntity
}

const i18nBase = 'RailDetails.OpenReturn.'

export function RailDepartureAndArrivalOpenReturn({ rail }: Props) {
  const { t } = useTranslation()

  const { segments } = rail
  const [firstSegment] = segments
  const lastSegment = segments[segments.length - 1]
  const departure = formatTravelRailLocation(lastSegment?.arrival, rail.arrivalDateTime)
  const arrival = formatTravelRailLocation(firstSegment?.departure, rail.departureDateTime)

  return (
    <>
      <Text variant="captionMedium" color="bodyText1">
        {t(`${i18nBase}Legend`)}
      </Text>
      <DepartureAndArrival>
        <Text variant="title3" color="mainText">
          {departure.stationName}
        </Text>
        <ChangesLine />
        <Text variant="title3" color="mainText">
          {arrival.stationName}
        </Text>
      </DepartureAndArrival>
    </>
  )
}
