import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripCarRental } from '@etta/screens/trip-car-rental'
import { TripFlight } from '@etta/screens/trip-flight'
import { TripHotel } from '@etta/screens/trip-hotel'
import { TripCarService } from '@etta/screens/trip-car-service'
import { TripRideHail } from '@etta/screens/trip-ride-hail'
import { SegmentType } from '@etta/core/enums'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { getTripSegmentsEmission } from '@fiji/hooks/sustainability/get-trip-segments-emission'
import { TripTrainModal } from '@etta/components/trip-train-modal'
import { useTripDetailsContextSelector } from './trip-details-provider'

type Props = {
  onSeatMapOpen: () => void
  isTripInProgress?: boolean
}

export const TripDetailsModal = observer(function TripDetailsModal({
  isTripInProgress,
  onSeatMapOpen,
}: Props) {
  const {
    postBookingTripStore,
    postBookingCancellationActions,
    postBookingTripDetailsStore,
  } = usePostBookingContext()
  const {
    getCarRentalEmission,
    getHotelEmission,
    getFlightEmission,
    getRailEmission,
  } = getTripSegmentsEmission()
  const {
    tripName,
    tripEmissionsResult,
    areTripSegmentsEmissionsLoading,
  } = useTripDetailsContextSelector(
    ({ tripName, tripEmissionsResult, areTripSegmentsEmissionsLoading }) => ({
      tripName,
      tripEmissionsResult,
      areTripSegmentsEmissionsLoading,
    }),
  )
  const { featureFlags } = useFeatureFlags()
  const { trip } = postBookingTripStore
  const { onCancelSegment } = postBookingCancellationActions
  const { selectedDetails, selectedDetailsToggle } = postBookingTripDetailsStore

  if (!selectedDetails) {
    return null
  }

  const commonProps = {
    isVisible: selectedDetailsToggle.isOpen,
    onClose: selectedDetailsToggle.handleClose,
    tripName,
    onCancel: () => onCancelSegment(selectedDetails),
  }

  switch (selectedDetails.type) {
    case SegmentType.CarRental: {
      return (
        <TripCarRental
          {...commonProps}
          carRental={selectedDetails}
          startDate={selectedDetails.pickUpTime ?? trip.rollUpStartTime}
          endDate={selectedDetails.dropOffTime ?? trip.rollUpEndTime}
          carRentalEmission={getCarRentalEmission({
            carId: selectedDetails.carId,
            loading: areTripSegmentsEmissionsLoading,
            tripEmissionsResult,
          })}
          isAdaptivePosition={featureFlags.isDesktopLayoutTripsFlowEnabled}
          isTripInProgress={isTripInProgress}
        />
      )
    }
    case SegmentType.Flight: {
      return (
        <TripFlight
          {...commonProps}
          flight={selectedDetails}
          startDate={selectedDetails.departureDateTime ?? trip.rollUpStartTime}
          endDate={selectedDetails.arrivalDateTime ?? trip.rollUpEndTime}
          onSeatMapOpen={onSeatMapOpen}
          isAdaptivePosition={featureFlags.isDesktopLayoutFlightFlowEnabled}
          cancellationDate={trip.cancellationTime}
          flightEmission={getFlightEmission({
            legId: selectedDetails.legId,
            loading: areTripSegmentsEmissionsLoading,
            tripEmissionsResult,
          })}
          transactionRegistryServiceItemId={trip.transactionRegistryServiceItemId}
          isTripInProgress={isTripInProgress}
        />
      )
    }
    case SegmentType.Hotel: {
      return (
        <TripHotel
          {...commonProps}
          hotel={selectedDetails}
          startDate={selectedDetails.checkIn ?? trip.rollUpStartTime}
          endDate={selectedDetails.checkOut ?? trip.rollUpEndTime}
          hotelEmission={getHotelEmission({
            hotelId: selectedDetails?.hotelId,
            loading: areTripSegmentsEmissionsLoading,
            tripEmissionsResult,
          })}
          isAdaptivePosition={featureFlags.isDesktopLayoutTripsFlowEnabled}
          transactionRegistryServiceItemId={trip.transactionRegistryServiceItemId}
          isTripInProgress={isTripInProgress}
        />
      )
    }
    case SegmentType.CarService: {
      return <TripCarService {...commonProps} carService={selectedDetails} />
    }
    case SegmentType.Train: {
      return (
        <TripTrainModal
          {...commonProps}
          train={selectedDetails}
          railEmission={getRailEmission({
            legId: selectedDetails?.id,
            loading: areTripSegmentsEmissionsLoading,
            tripEmissionsResult,
          })}
        />
      )
    }

    case SegmentType.RideHail: {
      return <TripRideHail {...commonProps} rideHail={selectedDetails} />
    }
    default: {
      return null
    }
  }
})
