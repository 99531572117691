import styled from 'styled-components'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'
import { footnoteMedium, captionStrong, title2 } from '@fiji/style'

export const FlexWrapper = styled.div`
  display: flex;
`

export const Timeline = styled.div`
  display: flex;
  width: 100%;
`

export const TimelineSolidLine = styled.div`
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.colors.borderDark};
`

export const AirportCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AirportCode = styled.div`
  min-width: 32px;
  padding: 2px 3px;
  background: ${(props) => props.theme.colors.bodyText};
  border-radius: 4px;
  ${captionStrong};
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.mainText2};
`

export const TimelineBlock = styled.div`
  width: 100%;
  margin-left: 12px;
`

export const Location = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
`

export const FlightInfo = styled.div`
  position: relative;

  ${Location} {
    position: absolute;
    top: 45px;
    right: 0;
  }
`

export const Time = styled.div`
  ${title2};
  line-height: 25px;
  color: ${(props) => props.theme.colors.mainText};
  text-transform: lowercase;
`

export const AirportWithTerminal = styled.div`
  margin-top: 3px;
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
  max-width: 90%;
`

export const Duration = styled(Text).attrs<TextProps, TextProps>(() => ({
  variant: 'footnoteStrong',
  color: 'bodyText',
  isBlock: true,
}))`
  display: inline-block;
  align-items: center;
  padding: 3px 6px 2px 6px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.background4};
`
