import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { CarRentalDetailsEcoCheckModal } from '@etta/components/car-rental-details-eco-check-modal/car-rental-details-eco-check-modal'
import { EmissionsDeviationLevel } from '@fiji/enums'
import EcoCheckMore from '../../assets/eco-check-more.svg?url'
import EcoCheckLess from '../../assets/eco-check-less.svg?url'
import EcoCheckAverage from '../../assets/eco-check-average.svg?url'
import EcoCheckLogo from '../../assets/eco-check-logo.svg?url'
import {
  Container,
  EcoCheckBackground,
  Title,
  Body,
  BodyRight,
  LearnMore,
} from './car-rental-details-eco-check-mobile-styled'
import {
  CarRentalDetailsEcoCheckMobileBodyRight,
  CarRentalDetailsEcoCheckMobileBodyLeft,
} from './components'

export type Props = {
  tonnesOfEmissions: number
  averageEmissionsTonnes: number
  equivalences: CalculateEmissionsBatchResultEquivalence[]
}

export function CarRentalDetailsEcoCheckMobileComponent({
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: Props) {
  const { t } = useTranslation()
  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })
  const { handleClose, handleOpen, isOpen } = useTogglePopup()

  const ecoCheckBackground = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
        return EcoCheckAverage
      case EmissionsDeviationLevel.Less:
        return EcoCheckLess
      case EmissionsDeviationLevel.More:
        return EcoCheckMore
    }
  }, [deviationLevel])

  return (
    <>
      <Container onClick={handleOpen} data-tracking-id="car-rental-details-eco-check-action">
        <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
          <img src={EcoCheckLogo} alt="eco check logo" />
        </Title>
        <Body>
          <CarRentalDetailsEcoCheckMobileBodyLeft
            deviationLevel={deviationLevel}
            percentDeviation={percentDeviation}
          />
          <BodyRight>
            <CarRentalDetailsEcoCheckMobileBodyRight deviationLevel={deviationLevel} />
            <LearnMore
              aria-label={
                t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
              }>
              {t('Emissions.DetailsModal.LearnMore')}
            </LearnMore>
          </BodyRight>
        </Body>
        <EcoCheckBackground src={ecoCheckBackground} />
      </Container>
      <CarRentalDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        tonnesOfEmissions={tonnesOfEmissions}
        averageEmissionsTonnes={averageEmissionsTonnes}
      />
    </>
  )
}
