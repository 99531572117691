import { useCallback } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'

export function useEditTripName() {
  const { tripActions } = useReviewTripContext()
  const editNameToggle = useTogglePopup()

  const handleUpdateTripName = useCallback(
    async (name: string) => {
      const res = await tripActions.updateTripName(name.trim())
      if (res.isErr()) {
        throw new Error(res.getError().message)
      }
    },
    [tripActions],
  )

  return { editNameToggle, handleUpdateTripName }
}
