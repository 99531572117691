import { Text } from '@etta/ui/text'
import { StarRating } from '@etta/ui/star-rating'
import { dateToLocale } from '@fiji/utils/dates/date-to-locale'
import { Container, DetailsContainer, HeaderText } from './hotel-review-item-styled'

type Props = {
  title: string
  date: string
  rating?: number | null
  description: string
}

export function HotelReviewItem({ description, rating, date, title }: Props) {
  return (
    <Container>
      <HeaderText aria-label={title} variant="subHeadStrong" color="mainText1">
        {title}
      </HeaderText>
      <DetailsContainer>
        <Text aria-label={dateToLocale(date)} variant="footnoteMedium" color="bodyText">
          {dateToLocale(date)}
        </Text>
        {rating && <StarRating aria-label={`${rating}`} rating={rating} />}
      </DetailsContainer>
      <Text aria-label={description} variant="footnoteMedium" color="bodyText1">
        {description}
      </Text>
    </Container>
  )
}
