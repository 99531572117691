import styled from 'styled-components'
import { getLayoutBackgroundColor } from './get-layout-background-color'
import type { LayoutBackgroundColor } from './types'

export const Header = styled.div<{
  isStatic?: boolean
  backgroundColor?: LayoutBackgroundColor
}>`
  position: ${({ isStatic }) => (isStatic ? 'relative' : 'sticky')};
  top: ${({ isStatic }) => (isStatic ? '0' : '1px')};
  left: 0;
  right: 0;
  z-index: 5;
  background-color: ${(props) => getLayoutBackgroundColor(props.theme, props.backgroundColor)};
  // Prevent 1px gap between header and device screen
  // https://developer.mozilla.org/en-US/docs/Web/CSS/position#sticky_positioning
  &:before {
    background-color: transparent;
    content: '';
    position: absolute;
    height: 10px;
    width: 100%;
    top: -2px;
  }
`

export const Footer = styled.div<{
  withBorder?: boolean
  backgroundColor?: LayoutBackgroundColor
}>`
  ${(props) => props.withBorder && `border-top: 1px solid ${props.theme.colors.borderLight};`}

  background-color: ${(props) => getLayoutBackgroundColor(props.theme, props.backgroundColor)};
`

export const Container = styled.div<{
  isScrollX?: boolean
}>`
  overflow-x: ${(props) => (props.isScrollX ? 'auto' : 'hidden')};
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Root = styled.div<{
  backgroundColor?: LayoutBackgroundColor
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => getLayoutBackgroundColor(props.theme, props.backgroundColor)};
`
