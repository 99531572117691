import { GeoSafeScaleLayout } from './layout'

type Props = {
  isLoading: boolean
  scores?: number
}

export function GeoSafeScale({ scores, isLoading }: Props) {
  return <GeoSafeScaleLayout scores={scores} isLoading={isLoading} />
}
