import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import { ControlsContainerDesktopStyled } from './dekstop'
import { ControlsContainerMobileStyled } from './mobile'

export function ControlsContainerLayout(props: PropsWithChildren<unknown>) {
  return (
    <MediaLayout
      mobileSlot={<ControlsContainerMobileStyled {...props} />}
      desktopSlot={<ControlsContainerDesktopStyled {...props} />}
    />
  )
}
