import type { AddressInput } from '@fiji/graphql/types'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import { divideAddressArrayToObject } from '@fiji/utils/address'
import { getAirportCode } from '@fiji/utils/get-airport-code'

export type GetAddressByLatLongProps = {
  lat: number
  long: number
  resultHandler: (place?: Place, address?: AddressInput) => void
}

export function getAddressByLatLong({ lat, long, resultHandler }: GetAddressByLatLongProps) {
  const geocoder = new google.maps.Geocoder()

  geocoder.geocode({ location: { lat, lng: long } }, (results) => {
    if (!results || results.length === 0) {
      resultHandler()
      return
    }

    const airportResults = results.filter((result) => result.types.includes('airport'))

    if (airportResults.length > 0) {
      resultHandler(mapToPlace(airportResults[0]), mapToAddress(airportResults[0]))
      return
    }

    const targetedTypeResults = results.filter(
      (result) =>
        result.types.includes('street_address') ||
        result.types.includes('premise') || // named location, usually a building or collection of buildings with a common name
        result.types.includes('point_of_interest'), // named point of interest, e.g. Empire State Building, Eiffel Tower
    )

    if (targetedTypeResults.length === 0) {
      resultHandler()
      return
    }

    resultHandler(mapToPlace(targetedTypeResults[0]), mapToAddress(targetedTypeResults[0]))
  })
}

function mapToPlace(result: google.maps.GeocoderResult): Place {
  return {
    placeId: result.place_id,
    name: result.formatted_address,
    latitude: result.geometry.location.lat(),
    longitude: result.geometry.location.lng(),
    countryCode: getCountryCode(result),
    airportCode: getAirportCode(result.formatted_address),
    placeType: PlaceAutocompleteType.GooglePlaces,
  }
}

function mapToAddress(result: google.maps.GeocoderResult): AddressInput {
  const addressInput = {
    lat: result.geometry.location.lat(),
    long: result.geometry.location.lng(),
    ...divideAddressArrayToObject(result.address_components),
  }

  if (!addressInput.street1 && result.types.includes('airport')) {
    const airportComponent = result.address_components.find((component) =>
      component.types.includes('airport'),
    )
    addressInput.street1 = airportComponent?.long_name ?? ''
  }

  return addressInput
}

function getCountryCode(result: google.maps.GeocoderResult): string | undefined {
  return result.address_components.find((component) => component.types.includes('country'))
    ?.short_name
}
