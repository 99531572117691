import type { KeyToIcon } from '@etta/ui/icon'

export const fareAttributeToPwaIconMap: KeyToIcon = {
  'advance-change': 'advanceChangePWA',
  'boarding-priority': 'priorityBoardingPWA',
  cancellation: 'refundablePWA',
  'carry-on-allowance': 'carryOnBagPWA',
  'check-in-priority': 'checkInPriorityPWA',
  'checked-bag-allowance': 'checkedBagAllowancePWA',
  'lounge-access': 'loungePWA',
  'seat-selection': 'seatSelectionPWA',
  'upgrade-eligibility': 'upgradePWA',
  'same-day-change': 'sameDayPWA',
  'non-refundable-cancellation': 'nonRefundablePWA',
}
