import { Action, Inject } from '@etta/di'
import { DisplayConfigurationService } from '../services/'

@Action()
export class DisplayConfigurationAction {
  constructor(
    @Inject()
    private readonly displayConfigurationService: DisplayConfigurationService,
  ) {}

  async loadDisplayConfiguration() {
    await this.displayConfigurationService.loadDisplayConfiguration()
  }

  async refetchDispayConfiguration() {
    await this.displayConfigurationService.refetchDisplayConfiguration()
  }
}
