import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { SeatMapValueObject } from '@etta/modules/seat-map/core/value-objects'
import { SeatMapErrors } from '../core/errors/seat-map.errors'
import type { LoadSelectedSeatMapArgs, SeatMapAdapterResult } from './types'
import { SeatMapMapper } from './mappers'
import { SeatMapDataProvider } from './seat-map.data-provider/seat-map.data-provider'

@Adapter()
export class SeatMapAdapter {
  constructor(
    @Inject()
    private seatMapDataProvider: SeatMapDataProvider,
  ) {}

  async loadSelectedSeatMap(
    args: LoadSelectedSeatMapArgs,
  ): Promise<SeatMapAdapterResult<SeatMapValueObject>> {
    try {
      const { data, error } = await this.seatMapDataProvider.getEnhanchedSeatMap(args)

      if (error) {
        return Result.Err(new SeatMapErrors.UnexpectedSeatMapError(error))
      }

      if (data.enhancedSeatMap.__typename === 'EnhancedSeatMap') {
        return Result.Ok(SeatMapMapper.toSeatMapEntityFromEnhancedSeatMap(data.enhancedSeatMap))
      }
      return Result.Err(new SeatMapErrors.UnexpectedSeatMapError(''))
    } catch (e) {
      return Result.Err(new SeatMapErrors.UnexpectedSeatMapError(e))
    }
  }
}
