import { useTranslation } from 'react-i18next'
import type { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { useEquivalenceDescription } from '../use-equivalence-description'
import { EcoCheckEquivalenceCardImage } from '../eco-check-equivalences-card-image'
import {
  LeftBlock,
  RightBlock,
  AmountBlock,
  DescriptionBlock,
  EcoCheckEquivalenceCardImageContainer,
  CardContainer,
  ViewSourceLink,
} from './eco-check-equivalences-desktop-new-styled'

type CardProps = {
  amount: number
  type: CalculateEmissionsBatchResultEquivalenceType
  unit: string
  withMarginRight: boolean
}

export function EcoCheckEquivalenceCardNew({ amount, type }: CardProps) {
  const { t } = useTranslation()

  const equivalenceDescription = useEquivalenceDescription(type)

  return (
    <CardContainer>
      <LeftBlock>
        <DescriptionBlock aria-label={equivalenceDescription.firstPart}>
          {equivalenceDescription.firstPart}
        </DescriptionBlock>
        <AmountBlock>{amount.toLocaleString('en-US')}</AmountBlock>

        <ViewSourceLink
          aria-label={`${t('Emissions.DetailsModal.ViewSource')} - ${t('Accessibility.OpenLink')}`}
          target="_blank"
          size="small"
          href={equivalenceDescription.linkTo}>
          {t('Emissions.DetailsModal.ViewSource')}
          <Icon name={'openWindowPWA'} size={16} color="primary" />
        </ViewSourceLink>
      </LeftBlock>
      <RightBlock>
        <EcoCheckEquivalenceCardImageContainer>
          <EcoCheckEquivalenceCardImage type={type} />
        </EcoCheckEquivalenceCardImageContainer>
      </RightBlock>
    </CardContainer>
  )
}
