import { useTranslation } from 'react-i18next'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { RailTransportationMode } from '@fiji/graphql/types'
import { LONDON_UNDERGROUND_TUBE_URL, RAIL_LOGO_GENERIC } from '@fiji/constants'
import type { TicketRestrictionsInfo } from '@etta/modules/rail/core/value-objects/ticket-restrictions-info.value-object'

const i18nBase = 'RailDetailsModal.'

type Props = {
  railLeg: TrainSegmentEntity
}

export function useRailDetailsTicketRestrictionsModal({ railLeg }: Props) {
  const { t } = useTranslation()

  const fareConditions = railLeg.fareConditions
  const currentTripFare = fareConditions?.localizedFareConditions[0].fareTypeDescription || ''
  const currentTicketRestrictions = fareConditions?.localizedFareConditions[0].conditions || []

  const ticketRestrictions: TicketRestrictionsInfo = {
    tripFare: currentTripFare,
    ticketRestrictions: currentTicketRestrictions,
  }

  const [railSegment] = railLeg.segments
  const { carrierLogoUrl } = railSegment
  const squareRailLogos = railLeg.segments
    .map((segment) => {
      if (segment.transportationMode === RailTransportationMode.Train) {
        return segment.carrierLogoUrl || RAIL_LOGO_GENERIC
      }
      if (
        segment.transportationMode === RailTransportationMode.Unspecified ||
        segment.transportationMode === RailTransportationMode.Metro
      ) {
        return LONDON_UNDERGROUND_TUBE_URL
      }

      return ''
    })
    .filter(Boolean)
  const headerTitle = t(i18nBase + 'FaresDetails.FromToStations', {
    from: railLeg.segments[railLeg.segments.length - 1].arrival.stationName,
    to: railLeg.segments[0].departure.stationName,
  })

  const tripDate = ''

  return {
    headerTitle,
    carrierLogoUrl: carrierLogoUrl || '',
    ticketRestrictions,
    tripDate,
    squareRailLogos,
  }
}
