import type { BrandFilter } from '@fiji/graphql/types'
import { Checkbox } from '@etta/ui/checkbox'
import { Wrapper } from './brand-checkbox-styled'

type Props = {
  brand: BrandFilter
  isChecked: boolean
  onChange: (isChecked: boolean) => void
  isNewLayout?: boolean
}

export function BrandCheckbox({ brand, isChecked, onChange, isNewLayout = false }: Props) {
  return (
    <Wrapper hasNewLayout={isNewLayout}>
      <Checkbox
        data-tracking-id={`hotel-brand-${brand.brandId}`}
        checked={isChecked}
        label={brand.brandDisplayName}
        value={brand.brandId}
        onChange={() => onChange(!isChecked)}
      />
    </Wrapper>
  )
}
