import type { CovidStatisticItem } from '../../../../types'
import { ListItem } from './covid-information-statistic-rows-styled'

export function CovidInformationStatisticRows({
  list,
  i18nBase,
}: {
  list: CovidStatisticItem[]
  i18nBase: string
}) {
  return (
    <>
      {list.map((item) => (
        <ListItem
          key={item.type}
          i18nBase={`${i18nBase}.CovidInformationStatisticItem`}
          {...item}
        />
      ))}
    </>
  )
}
