import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { ScreenType, useScreenType } from '@fiji/modes'
import {
  TripReviewDateType,
  dateFormat,
  deleteTimezone,
  tripReviewFormatDate,
} from '@fiji/utils/dates'
import { getDisplayServiceClass } from '@fiji/utils/flights'
import type {
  FlightGroupedSegmentValueObject,
  TripDetailsAirTicketValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import { getAirPenalty } from '@fiji/utils/trip-review/air-data'
import { PenaltyType } from '@fiji/enums'

type Args = {
  segment: FlightGroupedSegmentValueObject
  airTicket?: TripDetailsAirTicketValueObject
}

const i18Base = 'PostBooking.TripDetails'
const TIME_FORMAT = 'h:mma'
const LABELS_DIVIDER = ' • '
const NONSTOP_TRANSLATION = 'SearchSegments.Stops.Nonstop'
const STOPS_TRANSLATION = 'SearchSegments.Stops.Stop'
const SEAT_TRANSLATION = 'PostBooking.FlightDetails.Seat'
const EMPTY_STR = ''

export function useGetFlightInfoLabels({ segment, airTicket }: Args) {
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const { t } = useTranslation()
  const headerDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, segment.departureDateTime)
  const serviceClassCustomLabels = displayConfigurationStore.flightServiceClassCustomLabels

  const [departureTime, arrivalTime] = useMemo(() => {
    const departureDateTime = segment.departureDateTime || EMPTY_STR
    const arrivalDateTime = segment.arrivalDateTime || EMPTY_STR
    const departureTime = dateFormat(new Date(deleteTimezone(departureDateTime)), TIME_FORMAT)
    const arrivalTime = dateFormat(new Date(deleteTimezone(arrivalDateTime)), TIME_FORMAT)

    return [departureTime, arrivalTime]
  }, [segment])

  const airlineLabel = useMemo(() => {
    const airlineName = (segment.segments && segment.segments[0]?.name) || EMPTY_STR
    const flightCode = (segment.segments && segment.segments[0]?.flightNumber) || EMPTY_STR

    return [
      `${departureTime.toLowerCase()} – ${arrivalTime.toLowerCase()}`,
      `${airlineName} ${flightCode}`,
    ]
      .filter(Boolean)
      .join(LABELS_DIVIDER)
  }, [segment, departureTime, arrivalTime])

  const operatedByText = useMemo(() => {
    const operatedBy = segment.segments
      ?.map((segment) => segment.operatedBy)
      .filter(Boolean)
      .join(', ')

    return operatedBy ? t('FlightDetails.OperatedBy', { operatedBy }) : ''
  }, [segment, t])

  const seat = useMemo(() => {
    const seatNumbers = segment.segments
      ?.map((segment) => segment.travelerSeat)
      .filter(Boolean)
      .join(', ')
    return seatNumbers ? `${seatNumbers} ${t(SEAT_TRANSLATION)}` : EMPTY_STR
  }, [segment, t])

  const infoText = useMemo(() => {
    const numberOfStops = segment.stops
    const stopsText = !numberOfStops
      ? t(NONSTOP_TRANSLATION)
      : t(STOPS_TRANSLATION, { count: numberOfStops })
    const serviceClass = (segment.segments && segment.segments[0]?.serviceClass) || EMPTY_STR
    const serviceClassText =
      serviceClass?.charAt(0).toUpperCase() + serviceClass?.slice(1).toLowerCase()
    const displayServiceClassText: string = getDisplayServiceClass(
      { serviceClass, displayServiceClass: serviceClassText },
      serviceClassCustomLabels,
    )

    return [displayServiceClassText, stopsText, seat].filter(Boolean).join(LABELS_DIVIDER)
  }, [segment, serviceClassCustomLabels, seat, t])

  const flightItemTitle = useMemo(() => {
    const destinationAirportCode = segment.destination
    const arrivalAirPortCode = segment.segments?.at(-1)?.departure?.airportCode
    const segmentsCount = segment.segments?.length || 0
    const airportCity =
      (segment.segments && segment.segments[segmentsCount - 1]?.arrival?.airportCity) ||
      segment.destination
    const titleCity = [t(i18Base + '.FlightTo'), airportCity].join(' ')

    if (isMobile) {
      return t(`${i18Base}.FlightFromTo`, [arrivalAirPortCode, destinationAirportCode])
    }

    return titleCity
  }, [segment, isMobile, t])

  const flightTime = useMemo(() => {
    if (isMobile) {
      return `${departureTime.toLowerCase()} – ${arrivalTime.toLowerCase()}`
    }

    return airlineLabel
  }, [isMobile, airlineLabel, departureTime, arrivalTime])

  const flightInfo = useMemo(() => {
    const { segments } = segment || {}
    const { departure, carrierCode, flightNumber, originTerminal, penaltyFees } =
      segments?.at(0) || {}
    const { airportCode } = departure || {}
    const terminalLabel = originTerminal && t(`${i18Base}.Terminal`, { terminal: originTerminal })
    const carrierLabel = !!(carrierCode && flightNumber) && `${carrierCode}${flightNumber}`

    if (isMobile) {
      return [airportCode, carrierLabel, terminalLabel].filter(Boolean).join(' • ')
    }

    const isRefundable = () => {
      if (airTicket?.isTicketRefundable !== undefined) {
        return Boolean(airTicket.isTicketRefundable)
      }
      return getAirPenalty(PenaltyType.NonRefundable, penaltyFees) === null
    }

    const refundableLabel = t(
      `ReviewTripPWA.Details.PenaltyType.${isRefundable() ? 'Refundable' : 'NonRefundable'}`,
    )

    return [operatedByText, infoText, refundableLabel, seat].filter(Boolean).join(' • ')
  }, [segment, isMobile, operatedByText, infoText, seat, t, airTicket])

  const segmentPreview = useMemo(() => {
    const { segments } = segment || {}
    const { images } = segments?.at(0) || {}
    return images?.rectangle
  }, [segment])

  return {
    departureTime,
    arrivalTime,
    airlineLabel,
    operatedByText,
    seat,
    infoText,
    flightItemTitle,
    flightTime,
    flightInfo,
    segmentPreview,
    headerDate,
  }
}
