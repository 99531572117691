import { MediaLayout } from '@etta/ui/media-layout'
import { BookTripAgainModalDesktop } from './desktop'
import { BookTripAgainModalMobile } from './mobile'
import type { LayoutProps } from './types'

export function BookTripAgainModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<BookTripAgainModalMobile {...props} />}
      desktopSlot={<BookTripAgainModalDesktop {...props} />}
    />
  )
}
