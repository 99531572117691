import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { InfiniteScroll } from '@etta/ui/infinite-scroll'
import { CarRentalTypeFilterBar } from '@etta/components/car-rental-type-filter-bar/layout/car-rental-type-filter-bar-layout'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { TravelPolicyDialog } from '@etta/components/travel-policy-dialog/travel-policy-dialog'
import { CarRentalLoadingPlaceholder } from '@etta/components/car-rental-loading-placeholder/car-rental-loading-placeholder'
import { CarRentalDetailsModal } from '@etta/components/car-rental-details/car-rental-details-modal'
import { useDynamicSiteMessagesContext } from '@etta/modules/dynamic-site-messages/interface/use-dynamic-site-messages.context'
import { DynamicSiteMessagesLightboxModal } from '@etta/modules/dynamic-site-messages/ui/lightbox'
import { TripStatusModal } from '@etta/components/trip-status-modal'
import { usePreventPullRefresh } from '@fiji/hooks/body/use-prevent-pull-refresh'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll'
import { CarRentalAllFiltersModal } from '@etta/components/car-rental-all-filters-modal'
import { SearchResultsActiveItineraryDialog } from '@etta/components/search-results-active-itinerary-dialog'
import { SearchResultsTripExpiredDialog } from '@etta/components/search-results-trip-expired-dialog'
import { SegmentBookingLimitDialog } from '@etta/components/segment-booking-limit-dialog'
import { SegmentType } from '@etta/core/enums'
import { useCarRentalSearchResultsContextSelector } from './car-rental-search-results-provider'
import { CarRentalHeaderBlock } from './car-rental-header-block'
import { CarRentalSearchResultsLayout } from './layout'
import { CarRentalList } from './car-rental-list'
import { FilterSearchInfo } from './filter-search-info'
import { LongRequestInfo } from './long-request-info'

const i18Base = 'SearchSegments.NoResultErrorScreen'

export const CarRentalSearchResultsContent = observer(function CarRentalSearchResultsContent() {
  const { t } = useTranslation()
  usePreventPullRefresh()
  const { anchorRef, scrollContainerRef, offsetRef, headerRef } = useHeaderScroll()
  const { dynamicSiteMessagesTogglersStore } = useDynamicSiteMessagesContext()
  const { carRentalSearchDSMLightboxToggler } = dynamicSiteMessagesTogglersStore
  const {
    availableCarFilters,
    carTypes,
    onChangeCarType,
    cars,
    carPreferenceAttribute,
    isLoadingMore,
    onLoadMore,
    isError,
    handleOpenCarRentalDetails,
    dynamicSiteMessages,
    isResultsEmpty,
    hasNextPage,
    isEttaMobile,
    travelPolicyToggle,
    viewState,
    onAddCar,
    handleCloseTripStatus,
    selectedCarDetails,
    bookingId,
    handleCloseDetailsModal,
    carRentalDetailsModal,
    actualCarEmission,
    carEmissions,
    sortBy,
    onChangeSortBy,
    isRestrictedCountryError,
    noResultButtons,
    restrictedErrorMessage,
    isSecondCarRequestWithoutFilters,
    isLoadingSearchResults,
    activeItineraryToggle,
    isDefaultFilterCompanySettingsExist,
    isShowClearFiltersInformer,
    handleAddToCurrentTrip,
    handleCreateNewTrip,
    tripExpiredToggle,
    handleGoToRTPAndCloseDialog,
    maximumQuantityToggle,
  } = useCarRentalSearchResultsContextSelector((state) => state)

  return (
    <>
      <CarRentalSearchResultsLayout
        contentSlot={
          <>
            <InfiniteScroll
              ref={scrollContainerRef}
              isLoading={isLoadingMore && !isLoadingSearchResults}
              hasNextPage={hasNextPage}
              onFetchMore={onLoadMore}>
              <>
                {!!dynamicSiteMessages?.length && (
                  <Block ref={offsetRef} marginTop={24} marginBottom={8} paddingHorizontal={16}>
                    <DynamicSiteMessages messages={dynamicSiteMessages} />
                  </Block>
                )}
                <CarRentalHeaderBlock
                  headerRef={headerRef}
                  sortBy={sortBy}
                  onChangeSortBy={onChangeSortBy}
                />
                <Swap
                  is={isResultsEmpty && !isLoadingSearchResults}
                  isSlot={
                    <>
                      <CarRentalTypeFilterBar
                        availableFilters={availableCarFilters?.type}
                        activeFilterOptions={carTypes}
                        onOptionClick={onChangeCarType}
                      />
                      {isRestrictedCountryError ? (
                        <NoResultSearchScreen
                          type="car-rental-alert"
                          title={t(i18Base + '.RestrictedCountryErrorTitle')}
                          subTitle={t(i18Base + '.ErrorCarRentalRestrictedCountrySubTitle', {
                            customMessage: restrictedErrorMessage?.length
                              ? `(${restrictedErrorMessage})`
                              : '',
                          })}
                          buttons={noResultButtons}
                        />
                      ) : (
                        <NoResultSearchScreen
                          type="car-rental"
                          title={t(`${i18Base}${isError ? '.ErrorTitle' : '.NoResultTitle'}`)}
                          subTitle={t(
                            `${i18Base}${
                              isError ? '.ErrorCarRentalSubTitle' : '.NoResultCarRentalSubTitle'
                            }`,
                          )}
                          buttons={noResultButtons}
                        />
                      )}
                    </>
                  }>
                  <div ref={anchorRef} />
                  {isDefaultFilterCompanySettingsExist && isShowClearFiltersInformer && (
                    <FilterSearchInfo />
                  )}
                  {isSecondCarRequestWithoutFilters && <LongRequestInfo />}
                  {!isLoadingSearchResults && (
                    <>
                      <CarRentalTypeFilterBar
                        availableFilters={availableCarFilters?.type}
                        activeFilterOptions={carTypes}
                        onOptionClick={onChangeCarType}
                      />
                      <Block paddingHorizontal={16}>
                        <CarRentalList
                          cars={cars}
                          carPreferenceAttribute={carPreferenceAttribute}
                          onCarRentalDetailsOpen={handleOpenCarRentalDetails}
                          withAnimation={!isLoadingSearchResults}
                          onAddCar={onAddCar}
                          carEmissions={carEmissions}
                        />
                      </Block>
                    </>
                  )}
                </Swap>
                {isLoadingSearchResults && !isLoadingMore && <CarRentalLoadingPlaceholder />}
              </>
            </InfiniteScroll>
            <TripStatusModal tripStateStatus={viewState} onClick={handleCloseTripStatus} />

            <CarRentalAllFiltersModal />

            {!isEttaMobile && (
              <TravelPolicyDialog
                isVisible={travelPolicyToggle.isOpen}
                handleVisibility={travelPolicyToggle.handleClose}
              />
            )}
          </>
        }
      />

      <CarRentalDetailsModal
        onClose={handleCloseDetailsModal}
        isShow={carRentalDetailsModal.isOpen}
        bookingId={bookingId || ''}
        carId={selectedCarDetails?.carId || ''}
        isHybridFuel={selectedCarDetails?.isHybridFuel}
        isSelectable={selectedCarDetails?.isSelectable}
        onAddCar={onAddCar}
        carEmission={actualCarEmission}
      />

      <SearchResultsActiveItineraryDialog
        isOpen={activeItineraryToggle.isOpen}
        onClose={activeItineraryToggle.handleClose}
        onAddToCurrentTrip={handleAddToCurrentTrip}
        onCreateNewTrip={handleCreateNewTrip}
        onBack={activeItineraryToggle.handleClose}
      />

      <SearchResultsTripExpiredDialog
        isOpen={tripExpiredToggle.isOpen}
        onClose={tripExpiredToggle.handleClose}
        onStartAsNewTrip={handleCreateNewTrip}
      />

      <DynamicSiteMessagesLightboxModal
        messages={dynamicSiteMessages}
        isVisible={carRentalSearchDSMLightboxToggler.isOpen}
        onClose={carRentalSearchDSMLightboxToggler.handleClose}
      />

      <SegmentBookingLimitDialog
        segmentType={SegmentType.CarRental}
        isOpen={maximumQuantityToggle.isOpen}
        onClick={handleGoToRTPAndCloseDialog}
        onClose={maximumQuantityToggle.handleClose}
      />
    </>
  )
})
