import { Inject, Store } from '@etta/di'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'

import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'
import { PreExpirationAlertValues } from '@etta/modules/settings/core/entities/credit-cards.entity'
import { getBrandTypeByCardNumber } from '@fiji/utils/credit-card/get-brand-type'
import { AccountActivationValidatorMaker } from '../validator-maker'

type PreExprirationType = typeof PreExpirationAlertValues[keyof typeof PreExpirationAlertValues]

@Store()
export class PaymentInfoStore {
  private readonly _paymentInformationValidator: ReturnType<
    typeof AccountActivationValidatorMaker.prototype.makePaymentInformationValidator
  >
  private _displayAlert: PreExprirationType = PreExpirationAlertValues.Never

  constructor(@Inject() private readonly fieldSettingsStore: FieldSettingsStore) {
    const validatorMaker = new AccountActivationValidatorMaker(this.fieldSettingsStore)
    this._paymentInformationValidator = validatorMaker.makePaymentInformationValidator()
  }

  setField(
    key: Parameters<typeof this._paymentInformationValidator.onFieldChange>[0],
    value: string | boolean | null,
  ) {
    this._paymentInformationValidator.onFieldChange(key, value)
  }

  get validator() {
    return this._paymentInformationValidator
  }

  get isPaymentFieldsEditable(): boolean {
    return !!this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.PaymentCards]
      .personalCards.isEditable
  }

  get shouldRenderedPaymentInfoFields() {
    return this.fieldSettingsStore.fieldsSettings[FieldSettingsCategory.PaymentCards].personalCards
      .isRenderedOnActivationPage
  }

  setDisplayAlert(value: PreExprirationType) {
    this._displayAlert = value
  }

  get displayAlert() {
    return this._displayAlert
  }

  get creditCardBrand() {
    return getBrandTypeByCardNumber(this.validator.values.cardNumber)
  }
}
