import { useMemo } from 'react'
import type { PreferenceOptions, SegmentType } from '@fiji/graphql/types'
import type { AvailablePreferences } from '@fiji/graphql/hooks'
import type { TravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'
import { filterSpecialRequest } from './filter-special-request'
import { getRequestsByType } from './get-requests-by-type'

type Args = {
  type: SegmentType
  preferenceData: TravelPreferencesValueObject
  availablePreferences?: AvailablePreferences
}

export function useSpecialRequest({ type, preferenceData, availablePreferences }: Args) {
  const { availableRequests, specialRequests } = getRequestsByType({
    type,
    preferenceData,
    availablePreferences,
  })

  return useMemo(
    () => ({
      value: filterSpecialRequest({
        specialRequests,
        availableRequests,
      }),
      options: (availableRequests || []) as PreferenceOptions[],
    }),
    [availableRequests, specialRequests],
  )
}
