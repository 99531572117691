import { useState, useEffect } from 'react'
import type { Address, AddressInput, CarRentalMultiVendorLocation } from '@fiji/graphql/types'
import { SegmentType } from '@fiji/graphql/types'
import { ROUTES } from '@fiji/routes'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/use-car-rental-search-context'
import { getLocationFromId } from './get-location-from-id'

export function useCarRentalLocations() {
  const { carRentalLocationActions, carRentalStore } = useCarRentalSearchContext()

  const {
    navigateTo,
    handleLoadData,
    onSearchValue: setSearchValue,
    handleDropStore,
  } = carRentalLocationActions
  const {
    pickUpPlace,
    pickUpAddress,
    dropOffPlace,
    dropOffAddress,
    bookingId,
    pickUpLocationId,
    dropOffLocationId,
  } = carRentalStore.queryParams

  const isDropOffPlaceSameAsPickUpPlace = carRentalStore.isDropOffPlaceSameAsPickUpPlace

  const dropOffAirport = dropOffPlace?.airportCode

  const isDropOff = carRentalStore.isDropOffPage
  const isPickUp = carRentalStore.isPickUpPage
  const isConfirmation = carRentalStore.isConfirmationPage
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  const initialLocationAddress = (): AddressInput | Address | undefined => {
    switch (true) {
      case isPickUp && pickUpAddress:
        return pickUpAddress
      case isDropOff && dropOffAddress:
        return dropOffAddress
      case isPickUp && pickUpPlace:
      default:
        return undefined
    }
  }

  const initialLocationName = isPickUp ? pickUpPlace?.name : dropOffPlace?.name

  const searchDateTime = carRentalStore.searchDateTimeByPage

  useEffect(() => {
    handleLoadData()

    return () => {
      if (!carRentalStore.isLocationsPage) {
        handleDropStore()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: move all those fields to needed components directly
  const pickUpLocations = carRentalStore.pickUpLocations
  const dropOffLocations = carRentalStore.dropOffLocations
  const messages = carRentalStore.messages
  const searchValue = carRentalStore.filterLocationsValue
  const geocode = carRentalStore.geocode

  const filteredLocations = carRentalStore.filteredLocations

  const hasLocations = carRentalStore.hasLocations

  const [
    selectedPickUpLocation,
    setSelectedPickUpLocation,
  ] = useState<CarRentalMultiVendorLocation | null>(null)
  const [
    selectedDropOffLocation,
    setSelectedDropOffLocation,
  ] = useState<CarRentalMultiVendorLocation | null>(null)
  const [isDropOffSameAsPickUp, setDropOffSameAsPickUp] = useState(false)

  useEffect(() => {
    let pickUpLocation: CarRentalMultiVendorLocation | null = null
    let dropOffLocation: CarRentalMultiVendorLocation | null = null
    const areBothLocationsSelected = selectedPickUpLocation && selectedDropOffLocation
    const confirmationCondition = isConfirmation && hasLocations && !areBothLocationsSelected
    const dropOffCondition = dropOffLocationId && !selectedDropOffLocation

    if (confirmationCondition) {
      pickUpLocation = getLocationFromId(pickUpLocations, pickUpLocationId) ?? pickUpLocations[0]
      setSelectedPickUpLocation(pickUpLocation)
    }

    if (confirmationCondition || dropOffCondition) {
      dropOffLocation =
        getLocationFromId(dropOffLocations, dropOffLocationId) ?? dropOffLocations[0]
      setSelectedDropOffLocation(dropOffLocation)
      setDropOffSameAsPickUp(pickUpLocation?.locationId === dropOffLocation?.locationId)
    }
  }, [
    isPickUp,
    isConfirmation,
    hasLocations,
    pickUpLocationId,
    dropOffLocationId,
    isDropOffSameAsPickUp,
    selectedDropOffLocation,
    selectedPickUpLocation,
    pickUpLocations,
    dropOffLocations,
  ])

  function handleSameDropOffToggle() {
    setDropOffSameAsPickUp((prevState) => {
      if (!prevState) {
        const matchingDropOffLocation = getLocationFromId(dropOffLocations, pickUpLocationId)
        setSelectedDropOffLocation(matchingDropOffLocation || selectedPickUpLocation)
        return !prevState
      }

      const prevDropOffLocation = getLocationFromId(dropOffLocations, dropOffLocationId)
      setSelectedDropOffLocation(prevDropOffLocation || selectedDropOffLocation)
      return !prevState
    })
  }

  function handleLocationClick(location: CarRentalMultiVendorLocation) {
    if (!location.isOpen) {
      return
    }
    setSearchValue('')

    const { locationId, address, vendors } = location
    let locationParams

    const composeAddressWithAirport: Address = {
      ...address,
      airportCode: location.airportLocation ? location.airportCode : address.airportCode,
    }

    if (isPickUp) {
      locationParams = {
        pickUpLocationId: locationId,
        pickUpAddress: composeAddressWithAirport,
        allowedVendors: vendors.map((vendor) => vendor.code),
      }

      if (!isMobile && isDropOffPlaceSameAsPickUpPlace) {
        locationParams = {
          ...locationParams,
          dropOffLocationId: locationId,
          dropOffAddress: address,
        }
      }
    } else {
      locationParams = {
        dropOffLocationId: locationId,
        dropOffAddress: composeAddressWithAirport,
      }
    }
    const params = {
      bookingId,
      ...locationParams,
    }
    const isConfirmationScreenAvailable =
      isDropOffPlaceSameAsPickUpPlace || isDropOff || dropOffAirport

    if (!isMobile && isConfirmationScreenAvailable) {
      navigateTo(ROUTES.carRental.confirmation, params)
      return
    }

    if (isPickUp && !dropOffAirport) {
      navigateTo(ROUTES.carRental.dropOff, params)
      return
    }

    navigateTo(ROUTES.carRental.results, params)
  }

  function handleConfirmationLocationChange(isPickUp: boolean) {
    if (isPickUp) {
      navigateTo(ROUTES.carRental.pickUp)
    } else {
      navigateTo(ROUTES.carRental.dropOff)
    }
  }

  function handleConfirmationLocationConfirm() {
    navigateTo(ROUTES.carRental.results)
  }

  function handleEditSearch() {
    navigateTo(ROUTES.explore, { selectedTab: SegmentType.CarRental })
  }

  return {
    handleLocationClick,
    locations: filteredLocations,
    messages,
    isDropOff,
    hasLocations,
    initialLocationAddress: initialLocationAddress(),
    initialLocationName,
    searchDateTime,
    setSearchValue,
    searchValue,
    isDropOffSameAsPickUp,
    geocode,
    selectedPickUpLocation,
    selectedDropOffLocation,
    handleConfirmationLocationChange,
    handleConfirmationLocationConfirm,
    handleSameDropOffToggle,
    handleEditSearch,
  }
}
