import { UnusedTicketLayout } from '@etta/components/unused-ticket'
import {
  NoUnusedTickets,
  UnusedTicketsLoadingLayout,
} from '@etta/components/unused-tickets-modal/components'
import type { Args } from '../../types'
import { Tickets } from './unused-tickets-mobile-styled'

export function UnusedTicketsContentMobile({ unusedTickets, isLoading }: Args) {
  if (isLoading) {
    return <UnusedTicketsLoadingLayout />
  }

  if (!unusedTickets?.length) {
    return <NoUnusedTickets />
  }

  return (
    <Tickets>
      {unusedTickets?.map((unusedTicket) => (
        <UnusedTicketLayout key={unusedTicket.ticketNumber} unusedTicket={unusedTicket} />
      ))}
    </Tickets>
  )
}
