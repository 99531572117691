import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import { RailSearchSegmentDesktop } from './layout/desktop/rail-search-segment-desktop'
import { RailSearchSegmentMobile } from './layout/mobile/rail-search-segment-mobile'
import type { LayoutProps } from './types'
import { useRailSearchSegment } from './use-rail-search-segment'

export const RailSearchSegment = observer(function RailSearchSegment(props: LayoutProps) {
  const value = useRailSearchSegment(props.segment)
  return (
    <MediaLayout
      mobileSlot={<RailSearchSegmentMobile {...props} useRailSearchSegmentValue={value} />}
      desktopSlot={<RailSearchSegmentDesktop {...props} useRailSearchSegmentValue={value} />}
    />
  )
})
