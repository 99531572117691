import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import { Toggle } from '@etta/interface/services/toggle'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { CarSearchQueryType } from '@fiji/hooks/search-queries/use-car-search-query'
import { ROUTES } from '@fiji/routes'

export type CarEditCache = Partial<
  Pick<
    CarSearchQueryType,
    | 'pickUpPlace'
    | 'pickUpAddress'
    | 'pickUpDate'
    | 'pickUpTime'
    | 'dropOffPlace'
    | 'dropOffAddress'
    | 'dropOffDate'
    | 'dropOffTime'
    | 'pickUpLocationId'
    | 'dropOffLocationId'
  >
> & {
  bookingId?: string
  itineraryId?: string
}

export type ValidationErrors = {
  pickUpPlace?: string
  dropOffPlace?: string
  pickUpDate?: string
  dropOffDate?: string
  dropOffTime?: string
  pickUpTime?: string
}

@Store()
export class CarSearchFormStore {
  constructor(
    @Inject()
    private readonly historyStore: HistoryStore,
  ) {}

  editModeToggle = new Toggle()

  validationErrors: ValidationErrors = {}
  carSearchQueryParams = new QueryParams<CarSearchQueryType>(
    {
      pickUpTime: { hours: 9, minutes: 0 },
      dropOffTime: { hours: 17, minutes: 0 },
      carTypes: [],
      isSelectable: false,
    },
    {
      caseStyle: 'kebab-case',
      arrayFields: [
        'carTypes',
        'allowedVendors',
        'carClass',
        'carTransmission',
        'carVendor',
        'carFuel',
      ],
    },
  )

  carEditCache: CarEditCache = {}

  setValidationErrors(input: ValidationErrors) {
    this.validationErrors = input
  }

  setSingleValidationError(key: keyof ValidationErrors, value?: string) {
    this.validationErrors = { ...this.validationErrors, [key]: value }
  }

  get isEditMode() {
    const { itineraryId } = this.historyStore.getParams<{ itineraryId: string }>() || {}

    return this.historyStore.matchPathname(ROUTES.reviewTrip.main({ itineraryId }))
  }

  get carForm() {
    const params = this.carSearchQuery

    if (this.isEditMode) {
      const {
        pickUpPlace,
        pickUpAddress,
        pickUpDate,
        pickUpTime,
        dropOffPlace,
        dropOffAddress,
        dropOffDate,
        dropOffTime,
        pickUpLocationId,
        dropOffLocationId,
        itineraryId,
        bookingId,
      } = this.carEditCache
      return {
        pickUpPlace,
        pickUpAddress,
        pickUpDate,
        pickUpTime,
        dropOffPlace,
        dropOffAddress,
        dropOffDate,
        dropOffTime,
        pickUpLocationId,
        dropOffLocationId,
        itineraryId,
        bookingId,
      }
    }

    return {
      pickUpPlace: params.pickUpPlace,
      pickUpAddress: params.pickUpAddress,
      pickUpDate: params.pickUpDate,
      pickUpTime: params.pickUpTime,
      dropOffPlace: params.dropOffPlace,
      dropOffAddress: params.dropOffAddress,
      dropOffDate: params.dropOffDate,
      dropOffTime: params.dropOffTime,
      pickUpLocationId: params.pickUpLocationId,
      dropOffLocationId: params.dropOffLocationId,
      itineraryId: params.itineraryId,
      bookingId: params.bookingId,
    }
  }

  get carSearchQuery() {
    const {
      pickUpPlace,
      pickUpAddress,
      pickUpDate,
      pickUpTime,
      dropOffPlace,
      dropOffAddress,
      dropOffDate,
      dropOffTime,
      pickUpLocationId,
      dropOffLocationId,
      itineraryId,
      bookingId,
    } = this.carSearchQueryParams.getQueryParams(this.historyStore.search)
    return {
      pickUpPlace,
      pickUpAddress,
      pickUpDate,
      pickUpTime,
      dropOffPlace,
      dropOffAddress,
      dropOffDate,
      dropOffTime,
      pickUpLocationId,
      dropOffLocationId,
      itineraryId,
      bookingId,
    }
  }

  updateEditCache(data: CarEditCache) {
    this.carEditCache = {
      ...this.carEditCache,
      ...data,
    }
  }
}
