import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@etta/ui/checkbox'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { InfoWrapper, TextWrapper } from '../cancel-trip-modal-styled'
import { AdditionalInformation } from '../additional-information'

type Props = {
  isChecked: boolean
  onChange: (value: boolean) => void
  hasError: boolean
  rideHailSegment?: ReactNode
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'

export function FullScreenDescriptiveActions({
  isChecked,
  onChange,
  hasError,
  rideHailSegment,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <InfoWrapper>
        <Icon name="infoOutlinePWA" color={hasError ? 'error' : 'bodyText'} />
        <TextWrapper>
          <Text variant="footnoteMedium" color={hasError ? 'error' : 'bodyText'}>
            {hasError
              ? t('PostBooking.TripDetails.CancelTripModal.CancellationFeeMayApply')
              : t('PostBooking.TripDetails.CancelTripModal.NonRefundable')}
          </Text>
        </TextWrapper>
      </InfoWrapper>
      {rideHailSegment}
      <AdditionalInformation />
      <Block marginLeft={2}>
        <Checkbox
          value="Cancel confirmation"
          label={
            <Text
              variant="footnoteMedium"
              color="bodyText"
              data-tracking-id="cancel-confirmation-agreement-text">
              {t(`${i18nBase}.Confirmation`)}
            </Text>
          }
          checked={isChecked}
          onChange={onChange}
          data-tracking-id="cancel-confirmation-agreement-checkbox"
        />
      </Block>
    </>
  )
}
