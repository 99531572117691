import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import { ListResultHeader } from '@etta/components/list-result-header/list-result-header'
import { HotelSortMenu } from '@etta/components/hotel-sort-menu/hotel-sort-menu'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import { HeaderSkeleton } from './header-skeleton'
import { useHeader } from './use-header'
import { WarningCodes } from './warning-codes'

export const Header = observer(function Header() {
  const {
    hotelSearchResultsStore,
    hotelFiltersStore,
    hotelFiltersAction,
  } = useHotelSearchResultsContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { featureFlags } = useFeatureFlags()
  const { hotelsCountLabel, viewMenuType } = useHeader({
    hotelsCount: hotelSearchResultsStore.hotelResults?.totalCount || 0,
  })

  if (hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.hotels.length) {
    return <HeaderSkeleton />
  }

  return (
    <>
      {!!hotelSearchResultsStore.hotelResults?.messages?.length && (
        <Block marginBottom={16}>
          <DynamicSiteMessages messages={hotelSearchResultsStore.hotelResults.messages} />
        </Block>
      )}
      {!!hotelSearchResultsStore.hotelResults?.warningCodes.length && (
        <WarningCodes warningCodes={hotelSearchResultsStore.hotelResults.warningCodes} />
      )}
      <ListResultHeader
        title={hotelsCountLabel}
        sortSlot={<HotelSortMenu viewType={viewMenuType} />}
        isAppliedFiltersVisible={
          featureFlags.isNewHotelExposedFiltersEnabled &&
          hotelFiltersStore.numberOfAppliedFilters > 0
        }
        numberOfAppliedFilters={hotelFiltersStore.numberOfAppliedFilters}
        onAppliedFiltersClick={() => {
          hotelFiltersAction.handleClearAllFilters()
          hotelFiltersAction.handleApplyFilters()
        }}
        serviceFeeConfiguration={displayConfigurationStore.getServiceFeeConfiguration.hotelFares}
      />
    </>
  )
})
