import type { TripDetailsCarServiceSegment } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'

type Props = {
  carService: TripDetailsCarServiceSegment
}

function formatDate(date?: string | null) {
  if (!date) {
    return ''
  }

  return dateFormat(new Date(deleteTimezone(date)), `eee, LLL dd 'at' p`)
}

export function postBookingCarService({ carService }: Props) {
  const { pickUp, dropOff, serviceCompany } = carService

  return `
${serviceCompany?.name || ''}
Pick-up: ${formatDate(pickUp?.time)}
${pickUp?.address ? formatPickUpDropOffAddress(pickUp.address) : ''}
Drop-off: ${formatDate(dropOff?.time)}
${dropOff?.address ? formatPickUpDropOffAddress(dropOff.address) : ''}
`.trim()
}
