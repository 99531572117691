import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import type { PassportEntity } from '@etta/core/entities'
import { getIsExpiredPassport } from '../get-is-expired-passport'
import {
  CountryTitle,
  NumberAndTitle,
  ExpiredExcerpt,
  ErrorExcerpt,
  NumberExcerpt,
  EditButton,
  ExcerptContent,
} from './passport-excerpt-styled'
import { usePassportExcerpt } from './use-passport-excerpt'

type Props = {
  passport: PassportEntity
  onEdit: (id?: number | null) => void
  withDots?: boolean
  withNumbersInOneLine?: boolean
}

export function PassportExcerpt({ passport, onEdit, withDots, withNumbersInOneLine }: Props) {
  const { t } = useTranslation()
  const i18Base = 'TravelerInfo.Passport.'
  const { passportNumber, passportExpirationDate, countryDecoded } = usePassportExcerpt({
    passport,
    withDots,
  })

  return (
    <ExcerptContent>
      <EditButton
        onClick={() => onEdit(passport.id)}
        data-tracking-id="passport-box-edit-passport-button">
        <Icon name="editPWA" />
      </EditButton>
      <CountryTitle>{countryDecoded}</CountryTitle>
      <NumberAndTitle isOneLine={withNumbersInOneLine}>
        <Swap
          is={getIsExpiredPassport(passport)}
          isSlot={
            <>
              <NumberExcerpt>{passportNumber}</NumberExcerpt>
              <ErrorExcerpt>
                <Icon name="infoFilledPWA" color="error" size="small" />
                <ExpiredExcerpt>{`${t(
                  i18Base + 'ExpiredOn',
                )} ${passportExpirationDate}`}</ExpiredExcerpt>
              </ErrorExcerpt>
            </>
          }>
          <NumberExcerpt>{`${passportNumber}, ${t(
            i18Base + 'Exp',
          )}: ${passportExpirationDate}`}</NumberExcerpt>
        </Swap>
      </NumberAndTitle>
    </ExcerptContent>
  )
}
