import { observer } from 'mobx-react-lite'
import { Spinner } from '@etta/ui/spinner'
import { useTravelSupportContext } from '../interface/use-travel-support.context'
import { Document360Wrapper } from './travel-support-article-content-styled'
import { Title } from './travel-support-article-styled'

export const TravelSupportArticleContent = observer(() => {
  const { travelSupportStore } = useTravelSupportContext()

  if (travelSupportStore.isSupportArticleLoading) {
    return <Spinner type="spinout" />
  }

  return (
    <>
      {travelSupportStore.supportArticle?.title && (
        <Title>{travelSupportStore.supportArticle?.title}</Title>
      )}
      <Document360Wrapper
        dangerouslySetInnerHTML={{ __html: travelSupportStore?.supportArticle?.htmlContent ?? '' }}
      />
    </>
  )
})
