import styled from 'styled-components'

export const Container = styled.div`
  min-width: 328px;
  max-width: 696px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 14px;
`

export const Header = styled.div`
  height: 40px;
  background-color: ${(props) => props.theme.colors.borderLight};
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`

export const Content = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 18px 16px;
`
