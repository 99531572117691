import { SegmentType } from '@etta/core/enums'
import type { SegmentPostBookingValueObject } from '../../../core/value-objects'
import type { MultipleSegmentsResult } from '../../types'

type SegmentCountResult = Record<SegmentType, number>

export function getIsMultipleSegments(
  segments: SegmentPostBookingValueObject[],
  isMultiCity?: boolean,
): MultipleSegmentsResult {
  if (isMultiCity) {
    return {
      [SegmentType.CarRental]: true,
      [SegmentType.Flight]: true,
      [SegmentType.Hotel]: true,
      [SegmentType.Train]: true,
      [SegmentType.CarService]: true,
      [SegmentType.RideHail]: true,
      [SegmentType.Walk]: true,
      [SegmentType.Unspecified]: true,
      [SegmentType.PublicTransit]: true,
      [SegmentType.Mobility]: true,
      [SegmentType.Drive]: true,
    }
  }
  const segmentCount = segments.reduce<SegmentCountResult>(
    (acc, segment) => {
      acc[segment.type] = acc[segment.type] + 1
      return acc
    },
    {
      [SegmentType.CarRental]: 0,
      [SegmentType.Flight]: 0,
      [SegmentType.Hotel]: 0,
      [SegmentType.Train]: 0,
      [SegmentType.CarService]: 0,
      [SegmentType.RideHail]: 0,
      [SegmentType.Walk]: 0,
      [SegmentType.Unspecified]: 0,
      [SegmentType.PublicTransit]: 0,
      [SegmentType.Mobility]: 0,
      [SegmentType.Drive]: 0,
    },
  )

  return {
    [SegmentType.CarRental]: segmentCount[SegmentType.CarRental] > 1,
    [SegmentType.Flight]: segmentCount[SegmentType.Flight] > 2,
    [SegmentType.Hotel]: segmentCount[SegmentType.Hotel] > 1,
    [SegmentType.Train]: segmentCount[SegmentType.Train] > 1,
    [SegmentType.CarService]: segmentCount[SegmentType.CarService] > 1,
    [SegmentType.RideHail]: segmentCount[SegmentType.RideHail] > 1,
    [SegmentType.Walk]: segmentCount[SegmentType.Walk] > 1,
    [SegmentType.Unspecified]: segmentCount[SegmentType.Unspecified] > 1,
    [SegmentType.PublicTransit]: segmentCount[SegmentType.PublicTransit] > 1,
    [SegmentType.Mobility]: segmentCount[SegmentType.Mobility] > 1,
    [SegmentType.Drive]: segmentCount[SegmentType.Drive] > 1,
  }
}
