import type { ViewState } from '@etta/ui/view-state-modal'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import { usePurchaseInfoContext } from '@etta/modules/booking'
import { useRailPreference } from './use-rail-preference'
import { useCheckoutFlightPreferences } from './checkout-flow/flight/use-flight-preferences'
import { useCheckoutCarPreferences } from './checkout-flow/car-rental/use-car-rental-preferences'
import { useCheckoutHotelPreferences } from './checkout-flow/hotel/use-hotel-preferences'
import type { UsePreferences } from './types'

type Args = {
  onChangeViewState: (viewState: ViewState) => void
}

export function usePreferences({ onChangeViewState }: Args): UsePreferences {
  const { reviewTripStore } = useReviewTripContext()
  const segments = reviewTripStore.segments

  const { travelPreferencesStore } = useTravelPreferencesContext()
  const { travelPreferences } = travelPreferencesStore
  const { purchaseInfoStore } = usePurchaseInfoContext()
  const availablePreferences =
    purchaseInfoStore.purchaseInfo?.details.availablePreferences || undefined

  const railPreferences = useRailPreference({
    preferenceData: travelPreferences,
    availablePreferences,
    segments,
    onChangeViewState,
  })

  const checkoutFlightPreferences = useCheckoutFlightPreferences({
    onChangeViewState,
  })

  const checkoutCarPreferences = useCheckoutCarPreferences({
    onChangeViewState,
  })

  const checkoutHotelPreferences = useCheckoutHotelPreferences({
    onChangeViewState,
  })

  return {
    flightPreferences: checkoutFlightPreferences,
    carRentalPreferences: checkoutCarPreferences,
    hotelPreferences: checkoutHotelPreferences,
    railPreferences,
    isPreferenceLoading: false,
    preferenceError: false,
  }
}
