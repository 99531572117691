import i18next from 'i18next'
import type { ActionType } from '../../core/constants'

type Args = {
  actionType?: ActionType | null
  providerName?: string | null
}

const i18nBase = 'PostBooking.MultipleHotelProvidersDialog'

export function getLabels({ actionType, providerName }: Args) {
  const title = getTitle({ actionType })
  const description = getDescription({ actionType, providerName })
  const buttonLabel = getButtonLabel({ actionType })

  return {
    title,
    description,
    buttonLabel,
  }
}

function getTitle({ actionType }: Args) {
  switch (actionType) {
    case 'add':
      return i18next.t(`${i18nBase}.AddHotel`)
    case 'modify':
      return i18next.t(`${i18nBase}.ModifyHotel`)
    case 'change':
      return i18next.t(`${i18nBase}.ChangeRoomSource`)
    default:
      return ''
  }
}

function getDescription({ actionType, providerName }: Args) {
  switch (actionType) {
    case 'add':
    case 'modify':
      return i18next.t(`${i18nBase}.MultipleHotelProviders`)
    case 'change':
      return i18next.t(`${i18nBase}.InvalidProviderRooms`, { providerName })
    default:
      return ''
  }
}

function getButtonLabel({ actionType }: Args) {
  switch (actionType) {
    case 'add':
    case 'modify':
      return i18next.t(`${i18nBase}.ViewSupport`)
    case 'change':
      return i18next.t(`${i18nBase}.SelectAnotherRoom`)
    default:
      return ''
  }
}
