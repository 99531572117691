import styled from 'styled-components'
import { subHeadMedium } from '@fiji/style'
import { Text } from '@etta/ui/text'

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const UpperBody = styled.div`
  padding: 10px 20px;
`

export const EmissionInformation = styled.div``

export const EmissionInformationRow = styled.div<{ withNegativeMarginTop?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const EmissionInformationValue = styled(Text)`
  margin: 12px 0px 4px;
`

export const CarouselContainer = styled.div`
  margin-top: 16px;
`

export const EmisisonDetails = styled.div`
  padding: 0 20px;
`

export const EmisisonDetailsHeader = styled(Text)<{ isFirstHeader?: boolean }>`
  margin: 0;
  margin-top: ${(props) => (props.isFirstHeader ? '6px' : '16px')};
`

export const EmisisonDetailsDescription = styled(Text)<{ withBorder?: boolean }>`
  border-bottom: ${(props) => (props.withBorder ? '1px' : 0)} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 15px;
  margin: 0;
  margin-top: 8px;
`

export const EmissionInformationDescription = styled.div`
  ${subHeadMedium}
`

export const RailDetailsEcoCheckModalImage = styled.img`
  width: 100%;
`
