import type { PenaltyFee, Rate } from '@fiji/graphql/types'
import { formatMoneyPOS } from '@fiji/utils/money/format-money-pos'
import type { FlightLegSubSegment } from '@fiji/graphql/hooks'

export function getAirPenalty(
  type: string,
  penalties?: Array<PenaltyFee | null> | null,
): PenaltyFee | null {
  return (
    (penalties && (penalties.find((penalty) => penalty && penalty.type === type) as PenaltyFee)) ||
    null
  )
}

export function getAirRate(type: string, penalties?: Array<PenaltyFee | null> | null): Rate | null {
  const getRate = getAirPenalty(type, penalties)
  return (
    (getRate &&
      getRate.rates &&
      getRate.rates.find(
        (rate) => rate !== null && rate.primary.amount !== 0 && formatMoneyPOS(rate),
      )) ||
    null
  )
}

export function shouldShowRefundableLabel(segments: FlightLegSubSegment[]): boolean {
  // We show the Refundable/NonRefundable label only if each flight's segment contains penaltyFees
  // by which we determine is flight refundable or not
  return segments?.every((segment) => Boolean(segment.penaltyFees?.length))
}
