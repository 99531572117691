import type { PromisedResult } from '@etta/core/type-utils'
import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace CostAllocationErrors {
  export const UnexpectedError = createErrorClass('Unexpected Error')
}

export type CostAllocationErrorsInstances = InstanceType<
  typeof CostAllocationErrors[keyof typeof CostAllocationErrors]
>

export type AdapterResult<T> = PromisedResult<T, CostAllocationErrorsInstances>
