/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { SeatmapCabinClass } from '@fiji/graphql/types'
import { flightDisplayConfigurationSelector } from '@fiji/store/display-configuration/selectors'

export function useCabinClassLabels() {
  const { t } = useTranslation()
  const {
    businessClassCustomLabel,
    economyClassCustomLabel,
    firstClassCustomLabel,
    premiumEconomyClassCustomLabel,
  } = useSelector(flightDisplayConfigurationSelector)

  const getServiceClassLabel = useCallback(
    (id: 'COACH' | 'BUSINESS' | 'FIRST' | 'PREMIUM_COACH' | 'PREMIUM COACH' | string) => {
      // TODO: update getting labels for service class as string
      switch (id.toUpperCase()) {
        case 'BUSINESS':
          return businessClassCustomLabel || t('FlightSearch.CabinClass.Business')
        case 'COACH':
          return economyClassCustomLabel || t('FlightSearch.CabinClass.Economy')
        case 'FIRST':
          return firstClassCustomLabel || t('FlightSearch.CabinClass.First')
        case 'PREMIUM COACH':
        case 'PREMIUM_COACH':
          return premiumEconomyClassCustomLabel || t('FlightSearch.CabinClass.PremiumEconomy')
        default:
          return ''
      }
    },
    [
      businessClassCustomLabel,
      economyClassCustomLabel,
      firstClassCustomLabel,
      premiumEconomyClassCustomLabel,
    ],
  )

  const getCabinClassLabel = useCallback(
    (id?: string): string => {
      switch (id) {
        case SeatmapCabinClass.Business:
          return businessClassCustomLabel || t('FlightSearch.CabinClass.Business')
        case SeatmapCabinClass.Coach:
          return economyClassCustomLabel || t('FlightSearch.CabinClass.Economy')
        case SeatmapCabinClass.First:
          return firstClassCustomLabel || t('FlightSearch.CabinClass.First')
        case SeatmapCabinClass.PremiumCoach:
          return premiumEconomyClassCustomLabel || t('FlightSearch.CabinClass.PremiumEconomy')
        default:
          return ''
      }
    },
    [
      businessClassCustomLabel,
      economyClassCustomLabel,
      firstClassCustomLabel,
      premiumEconomyClassCustomLabel,
    ],
  )

  return { getCabinClassLabel, getServiceClassLabel }
}
