import { SelectLayout } from './layout'
import type { ValueType, Props } from './types'
import { useSelect } from './use-select'

export function Select<T = ValueType>({
  onChange,
  options,
  isDisabled,
  value,
  helperType,
  helperText,
  label,
  description,
  isBlurred,
  withNoneOption,
}: Props<T>) {
  const {
    onChangeHandler,
    optionLabel,
    isOptionsDialogVisible,
    onOptionsDialogOpen,
    onOptionsDialogClose,
    displayOptions,
  } = useSelect<T>({
    onChange,
    options,
    isDisabled,
    value,
    withNoneOption,
  })

  return (
    <SelectLayout
      options={displayOptions}
      isDisabled={isDisabled}
      value={value}
      helperType={helperType}
      helperText={helperText}
      label={label}
      description={description}
      onChangeHandler={onChangeHandler}
      optionLabel={optionLabel}
      isOptionsDialogVisible={isOptionsDialogVisible}
      onOptionsDialogOpen={onOptionsDialogOpen}
      onOptionsDialogClose={onOptionsDialogClose}
      isBlurred={isBlurred}
    />
  )
}
