import { Inject, Service } from '@etta/di'
import { SegmentType } from '@etta/core/enums'
// TODO: remove fiji import
import type { TimeRange, SwitchPlacesProps, Place } from '@fiji/types'
// eslint-disable-next-line import/no-restricted-paths
import type { FlightSearchTimeRange, Address } from '@fiji/graphql/types'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { getFlightCustomTimeRange } from '@fiji/utils/dates/get-flight-custom-time-range'
import { AirSearchFormStore } from '../../stores/air-search-form.store'
import { CabinClassConfigurationStore } from '../../stores/configs/cabin-class-config.store'
import { AirSearchQueryService } from '../air-search-query/air-search-query.service'
import { AirSearchQueryStore } from '../../stores/air-search-query.store'
import { AirSearchDefaultValuesStore } from '../../stores/air-search-default-values.store'

type HandleChangeArgs = {
  flightType?: FlightType
  flights?: Flight[]
}

type HandleSwitchLocation = {
  originRange: TimeRange
  destinationRange: TimeRange
  originLocation?: Place
  destinationLocation?: Place
  flightSearchTimeRange?: FlightSearchTimeRange
  flightSearchTimeRangeNonUS?: FlightSearchTimeRange
}

let flightId: null | number = null

@Service()
export class AirSearchFormService {
  constructor(
    @Inject()
    private readonly airSearchFormStore: AirSearchFormStore,
    @Inject()
    private readonly airSearchFormQueryService: AirSearchQueryService,
    @Inject()
    private readonly airSearchQueryStore: AirSearchQueryStore,
    @Inject()
    private readonly cabinClassConfiguration: CabinClassConfigurationStore,
    @Inject()
    private readonly airSearchDefaultValuesStore: AirSearchDefaultValuesStore,
  ) {}

  handleSwitchPlaces({
    legNumber,
    flightSearchTimeRange,
    flightSearchTimeRangeNonUS,
  }: SwitchPlacesProps) {
    const { searchFlights } = this.airSearchFormStore
    const updatedFlights = searchFlights.map((flight, index) => {
      if (index === legNumber) {
        return {
          ...flight,
          originPlace: flight.destinationPlace,
          destinationPlace: flight.originPlace,
          ...this.getRecalculatedTimeRange({
            originLocation: flight.originPlace,
            destinationLocation: flight.destinationPlace,
            originRange: flight.timeRange,
            destinationRange: flight.returnTimeRange,
            flightSearchTimeRange,
            flightSearchTimeRangeNonUS,
          }),
        }
      }
      return flight
    })
    this.handleChange({ flights: updatedFlights })
  }

  handleAddFlightToSearch() {
    const { searchFlights, isAbleToAddFlight } = this.airSearchFormStore

    if (!isAbleToAddFlight) {
      return
    }

    if (flightId === null) {
      flightId = Math.max.call(null, ...searchFlights.map(({ id }, index) => id || index))
    }

    const flightsNumber = searchFlights.length
    const lastFlight = searchFlights[flightsNumber - 1]
    const lastFlightDestination = lastFlight?.destinationPlace

    searchFlights.push({
      id: ++flightId,
      originPlace: lastFlightDestination,
      cabinClass: this.cabinClassConfiguration.cabinClassOptions[0],
      timeRange: this.airSearchDefaultValuesStore.initialTimeRange,
      returnTimeRange: this.airSearchDefaultValuesStore.initialReturnTimeRange,
    })

    this.handleChange({
      flights: searchFlights,
    })
  }

  flightTypeChange(type: FlightType) {
    this.handleChange({ flightType: type })
  }

  handleRemoveFlight(id: number) {
    const { searchFlights } = this.airSearchFormStore

    const updatedFlights = searchFlights.filter((flight) => flight.id !== id)

    this.handleChange({ flights: updatedFlights })
  }

  getHighlightedDates(excludedDate?: Date) {
    const { searchFlights, searchFlightType: flightType } = this.airSearchFormStore

    if (flightType !== FlightType.MultiCity) {
      return []
    }

    return searchFlights
      .filter((flight) => flight.departureDate !== excludedDate)
      .map((flight) => flight.departureDate)
  }

  handleCheckIsErrorPlaces(legNumber: number) {
    const { searchFlights } = this.airSearchFormStore
    const flight = searchFlights[legNumber]

    if (!flight) {
      return false
    }

    const { destinationPlace, originPlace } = flight

    if (!destinationPlace || !originPlace) {
      return false
    }

    const isCities = Boolean(destinationPlace.city && originPlace.city)
    const isCountries = Boolean(destinationPlace.countryCode && originPlace.countryCode)
    const isCitiesEqual = isCities && destinationPlace.city === originPlace.city
    const isCountriesEqual = isCountries && destinationPlace.countryCode === originPlace.countryCode
    const isCitiesAndCountriesEqual = isCitiesEqual && isCountriesEqual
    const isAirports = Boolean(destinationPlace.airportCode && originPlace.airportCode)
    const isAirportsEqual = isAirports && destinationPlace.airportCode === originPlace.airportCode

    return (
      destinationPlace.name === originPlace.name ||
      destinationPlace.placeId === originPlace.placeId ||
      isAirportsEqual ||
      isCitiesAndCountriesEqual
    )
  }

  checkIsDateRangeValid() {
    const { searchFlightType: flightType, searchFlights } = this.airSearchFormStore

    if (flightType === FlightType.OneWay) {
      return true
    }
    if (flightType === FlightType.Round) {
      const originDate = searchFlights[0]?.departureDate
      const returnDate = searchFlights[0]?.returnDate
      return originDate && returnDate ? originDate <= returnDate : false
    }
    return searchFlights
      .map((flight) => flight.departureDate)
      .every((date, index, allDates) => {
        const nextDate = allDates[index + 1]
        if (!date) {
          return false
        }
        if (!nextDate) {
          return true
        }
        const isNextDateAfter = date <= nextDate
        return isNextDateAfter
      })
  }

  updateSearchFlightsEditCache() {
    const flights = this.airSearchQueryStore.searchQueryFlights
    const type = this.airSearchQueryStore.searchQueryFlightType
    this.airSearchFormStore.updateSearchFlightsEditCache({
      flights,
      flightType: type,
    })
  }

  private handleChange({ flights, flightType }: HandleChangeArgs) {
    const { searchFlights, searchFlightType } = this.airSearchFormStore
    if (this.airSearchFormStore.isEditMode) {
      this.airSearchFormStore.updateSearchFlightsEditCache({
        flights,
        flightType,
      })
      return
    }
    this.airSearchFormQueryService.appendQueryParams({
      flights: flights ?? searchFlights,
      flightType: flightType ?? searchFlightType,
    })
  }

  isLocationUsOrCa(location?: Place & { address?: Address }) {
    return (
      location?.countryCode === 'US' ||
      location?.countryCode === 'CA' ||
      location?.address?.countryCode === 'US' ||
      location?.address?.countryCode === 'CA'
    )
  }

  getFlightCoutries(
    origin?: Place & { address?: Address },
    destination?: Place & { address?: Address },
  ) {
    const originCountryUS = this.isLocationUsOrCa(origin)
    const destinationCountryUS = this.isLocationUsOrCa(destination)

    switch (true) {
      case originCountryUS && destinationCountryUS:
        return 'us-flight'
      case !originCountryUS && !destinationCountryUS:
        return 'non-us-flight'
      case originCountryUS:
        return 'origin-us'
      case destinationCountryUS:
        return 'destination-us'
      default:
        return 'us-flight'
    }
  }

  getRecalculatedTimeRange({
    originLocation,
    destinationLocation,
    originRange,
    destinationRange,
    flightSearchTimeRange,
    flightSearchTimeRangeNonUS,
  }: HandleSwitchLocation): {
    timeRange: TimeRange
    returnTimeRange: TimeRange
  } {
    const flightLocation = this.getFlightCoutries(originLocation, destinationLocation)

    switch (true) {
      case !originLocation || !destinationLocation:
      case (originRange.id !== 'custom-time' && destinationRange.id !== 'custom-time') ||
        flightLocation === 'us-flight' ||
        flightLocation === 'non-us-flight':
        return {
          timeRange: originRange,
          returnTimeRange: destinationRange,
        }

      case originRange.id === 'custom-time' &&
        destinationRange.id === 'custom-time' &&
        (flightLocation === 'origin-us' || flightLocation === 'destination-us'): {
        const originTimeAdjustment = getFlightCustomTimeRange({
          value: originRange.customTimeHours,
          originPlace: destinationLocation,
          destinationPlace: originLocation,
          flightSearchTimeRange,
          flightSearchTimeRangeNonUS,
          forSegment: SegmentType.Flight,
        })

        const destinationTimeAdjustment = getFlightCustomTimeRange({
          value: destinationRange.customTimeHours,
          originPlace: originLocation,
          destinationPlace: destinationLocation,
          flightSearchTimeRange,
          flightSearchTimeRangeNonUS,
          forSegment: SegmentType.Flight,
        })

        return {
          timeRange: {
            ...originRange,
            ...originTimeAdjustment,
          },
          returnTimeRange: {
            ...destinationRange,
            ...destinationTimeAdjustment,
          },
        }
      }

      case originRange.id === 'custom-time' &&
        (flightLocation === 'origin-us' || flightLocation === 'destination-us'): {
        const originTimeAdjustment = getFlightCustomTimeRange({
          value: originRange.customTimeHours,
          originPlace: destinationLocation,
          destinationPlace: originLocation,
          flightSearchTimeRange,
          flightSearchTimeRangeNonUS,
          forSegment: SegmentType.Flight,
        })

        return {
          timeRange: {
            ...originRange,
            ...originTimeAdjustment,
          },
          returnTimeRange: destinationRange,
        }
      }

      case destinationRange.id === 'custom-time' &&
        (flightLocation === 'origin-us' || flightLocation === 'destination-us'): {
        const destinationTimeAdjustment = getFlightCustomTimeRange({
          value: destinationRange.customTimeHours,
          originPlace: originLocation,
          destinationPlace: destinationLocation,
          flightSearchTimeRange,
          flightSearchTimeRangeNonUS,
          forSegment: SegmentType.Flight,
        })

        return {
          timeRange: originRange,
          returnTimeRange: {
            ...destinationRange,
            ...destinationTimeAdjustment,
          },
        }
      }

      default:
        return {
          timeRange: originRange,
          returnTimeRange: destinationRange,
        }
    }
  }

  dropEditCacheAndSwitchToViewMode() {
    this.airSearchFormStore.updateSearchFlightsEditCache({
      flights: this.airSearchQueryStore.searchQueryFlights,
      flightType:
        this.airSearchQueryStore.searchQueryFlightType || this.airSearchFormStore.searchFlightType,
    })
    this.airSearchFormStore.editModeToggle.handleClose()
  }
}
