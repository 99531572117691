import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import type { SwipingTimePickerBars } from '../types'
import { useDateHandlers } from '../handlers'
import { returnValidDate } from '../assets/swipe-effects'
import { asembleBarValues, getEnvironment } from '../assets'
import { AGO_40_YEARS_IN_MS } from '../constants'
import { DateLayout } from './date-layout'
import type { DatePickerProps } from './types'

export function DatePicker({
  date,
  defaultDate,
  label,
  variant,
  yearBounds: yearBoundsInput,
  areFutureDatesSelectable,
  onApply,
}: DatePickerProps) {
  const { t } = useTranslation()
  const basePath = 'SwipingDateTimePicker.'

  const isFutureDate = variant === 'future-date'

  const { getInitBarTop, constants } = getEnvironment(variant, undefined, yearBoundsInput)
  const { UNIT, INIT_BARS, START_YEAR, YEAR_BOUNDS } = constants
  const areFutureDateAllowed =
    areFutureDatesSelectable || new Date().getFullYear() < YEAR_BOUNDS.maxYear

  const startDate = useMemo(() => {
    const dateValue = date || defaultDate

    if (dateValue) {
      return dateValue
    }

    if (isFutureDate) {
      return new Date()
    }

    const AGO_40_YEARS = new Date(Date.now() - AGO_40_YEARS_IN_MS)

    if (AGO_40_YEARS.getFullYear() >= YEAR_BOUNDS?.minYear) {
      return AGO_40_YEARS
    }

    if (YEAR_BOUNDS?.minYear) {
      const now = new Date()
      now.setFullYear(YEAR_BOUNDS.minYear)
      return now
    }

    return AGO_40_YEARS
  }, [YEAR_BOUNDS.minYear, date, defaultDate, isFutureDate])

  // To make circle effect works, each hour and min columns consist of
  // four scrollable smaller sub-columns or bars with time stamps on them.
  // Once one of them is scrolled out, it is flipped back on top (or bottom).

  const [day, setDay] = useState(() => {
    if (isFutureDate) {
      return startDate.getDate()
    }
    return startDate.getDate() - 1
  })
  const [dayFlipping, setDayFlipping] = useState<SwipingTimePickerBars>(INIT_BARS)
  const [dayBarTop, setDayBarTop] = useState<SwipingTimePickerBars>(getInitBarTop(day, 'day'))
  const [daySwiping, setDaySwiping] = useState(0)

  const [month, setMonth] = useState(startDate.getMonth() || 0)
  const [monthFlipping, setMonthFlipping] = useState<SwipingTimePickerBars>(INIT_BARS)
  const [monthBarTop, setMonthBarTop] = useState<SwipingTimePickerBars>(
    getInitBarTop(month, 'month'),
  )
  const [monthSwiping, setMonthSwiping] = useState(0)

  const [year, setYear] = useState(() => {
    const yearDelta = (startDate.getFullYear() || 0) - START_YEAR

    if (yearDelta >= 0) {
      return yearDelta
    }

    return 0
  })
  const [yearFlipping, setYearFlipping] = useState<SwipingTimePickerBars>(INIT_BARS)
  const [yearBarTop, setYearBarTop] = useState<SwipingTimePickerBars>(getInitBarTop(year, 'year'))
  const [yearSwiping, setYearSwiping] = useState(0)

  const shouldDisableDateSet = () => {
    const currentDate = new Date(START_YEAR + year, month, day + 1)
    const isFutureDate = currentDate.getTime() > new Date().getTime()
    const isInvalidDate = !!returnValidDate({ day: day + 1, month, year })

    if (isInvalidDate) {
      return true
    }

    if (variant === 'future-date') {
      return !isFutureDate
    }

    if (variant !== 'date') {
      return false
    }

    if (areFutureDateAllowed) {
      return false
    }

    return isFutureDate
  }

  const { dayHandlers, monthHandlers, yearHandlers } = useDateHandlers({
    variant,
    day,
    month,
    year,
    setDay,
    setMonth,
    setYear,
    setDaySwiping,
    setMonthSwiping,
    setYearSwiping,
    setDayFlipping,
    setMonthFlipping,
    setYearFlipping,
    dayBarTop,
    monthBarTop,
    yearBarTop,
    setDayBarTop,
    setMonthBarTop,
    setYearBarTop,
    yearBoundsInput,
  })

  const onDateSelected = () => {
    const value = new Date(year + START_YEAR, month, day + 1)
    onApply(value)
  }

  return (
    <>
      <DateLayout
        startYear={START_YEAR}
        label={label}
        day={day}
        month={month}
        year={year}
        handleSwipe={[monthHandlers, dayHandlers, yearHandlers]}
        variant={variant}
        data={asembleBarValues({
          height: UNIT.height,
          types: ['month', 'day', 'year'],
          values: [month, day, year],
          ranges: [UNIT.monthRange, UNIT.dayRange, UNIT.yearRange],
          swipes: [monthSwiping, daySwiping, yearSwiping],
          flips: [monthFlipping, dayFlipping, yearFlipping],
          tops: [monthBarTop, dayBarTop, yearBarTop],
          unitGroup: [0, 0, 0],
        })}
      />
      <Block marginTop="auto" marginHorizontal={16}>
        <Button
          onClick={onDateSelected}
          btnType="primary"
          fullWidth
          disabled={shouldDisableDateSet()}>
          {t(basePath + 'Confirm')}
        </Button>
      </Block>
    </>
  )
}
