import styled from 'styled-components'
import { glowingFragment } from '@fiji/style/animations'

export const BoneWrapper = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 25px 0;
`

export const Bone = styled.div<{ width?: number }>`
  background: ${({ theme }) => theme.colors.background1};
  width: ${({ width = 70 }) => width}%;
  height: 18px;
  ${glowingFragment};
`
