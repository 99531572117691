import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { SegmentDateTimeMobile } from './mobile'
import { SegmentDateTimeDesktop } from './desktop'

export function SegmentDateTime(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<SegmentDateTimeMobile {...props} />}
      desktopSlot={<SegmentDateTimeDesktop {...props} />}
    />
  )
}
