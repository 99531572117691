import { ShowMoreContainer } from '@etta/ui/show-more-container'
import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { Block } from '@etta/ui/block'
import { ExpandedView } from '../../../expanded-view'
import { CollapsedView } from '../../../collapsed-view'
import type { LayoutProps } from '../../types'
import { Container, ShowDetails } from './flight-details-legend-with-toggle-desktop-styled'

export function FlightDetailsLegendWithToggleDesktop({
  flight,
  backgroundColor,
  buttonText,
  buttonTextAria,
  isExpanded,
  toggleExpansion,
  segmentDaysInBetween,
  flightLegSegments,
  shouldShowAirportChangeBadge,
}: LayoutProps) {
  return (
    <Container>
      <ShowMoreContainer
        collapsedSlot={<CollapsedView flight={flight} daysInBetween={segmentDaysInBetween} />}
        expandedSlot={
          <ExpandedView
            isExpanded={isExpanded}
            backgroundColor={backgroundColor}
            flight={flight}
            daysInBetween={segmentDaysInBetween}
            shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
            flightLegSegments={flightLegSegments}
          />
        }
        isExpanded={isExpanded}
        isAnimated
      />
      {shouldShowAirportChangeBadge && flightLegSegments?.length && !isExpanded && (
        <Block marginTop={18}>
          <AirportChangeBadge flightLegSegments={flightLegSegments} />
        </Block>
      )}
      <ShowDetails
        role="button"
        tabIndex={0}
        href="javascript:;"
        onClick={toggleExpansion}
        aria-label={buttonTextAria}
        data-tracking-id="flight-details-show-details-button">
        {buttonText}
      </ShowDetails>
    </Container>
  )
}
