import { Result } from 'fnscript'
import { ApolloError } from '@apollo/client'
import { Inject, Adapter } from '@etta/di'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass } from '@etta/core/enums'
import { getRestrictedErrorData } from '@fiji/utils/get-restricted-error-message'
import { getLastPermittedDateErrorData } from '@fiji/utils/get-last-permitted-date-error-date'
import { GetAlternatePolicyErrors } from '@etta/modules/air-search/core/errors/get-alternate-policy.errors'
import { AirSearchErrors } from '../core/errors/air-search.errors'
import type { CreateAirSearchInput } from '../core/value-objects/create-air-search.input'
import type { GetFlightsInput } from '../core/value-objects/get-flights.input'
import type { GetFlightsResult } from '../core/value-objects/get-flights.result'
import type { GetFareAttributesInputValueObject } from '../core/value-objects/get-fare-attributes-input.value-object'
import type { FareAttributesResponseValueObject } from '../core/value-objects/fare-attributes.value-object'
import type { GetFlightDynamicSitMessagesInput } from '../core/value-objects/get-flight-dynamic-site-messages.input'
import type { GetFlightDynamicSiteMessagesResult } from '../core/value-objects/get-flight-dynamic-site-messages.result'
import { GetFlightDynamicSiteMessagesErrors } from '../core/errors/get-flight-dynamic-site-messages.errors'
import type { GetAlternatePolicyTripsInput } from '../core/value-objects/get-alternate-policy-trips.input'
import type { AlternateFlightTripValueObject } from '../core/value-objects/alternate-flight-trip.value-object'
import { AirSearchDataProvider } from './air-search.data-provider/air-search.data-provider'
import type {
  AirSearchAdapterResult,
  CreateAirSearchResult,
  FlightDynamicSiteMessagesAdapterResult,
  GetAlternatePolicyAdapterResult,
} from './types'
import { mapFlightDynamicSiteMessagesResult } from './mappers/flight-dynamic-site-messages.mapper'
import { toFareAttributesResponseValueObject } from './air-search.data-provider/to-fare-attributes.mapper'
import { mapFlightTripsToValueObject } from './mappers/alternate-policy-trip.mapper'

@Adapter()
export class AirSearchAdapter {
  constructor(
    @Inject()
    private airSearchDataProvider: AirSearchDataProvider,
  ) {}

  async createAirSearch(
    input: CreateAirSearchInput,
  ): Promise<AirSearchAdapterResult<CreateAirSearchResult>> {
    try {
      const { data } = await this.airSearchDataProvider.createAirSearch(input)

      if (!data?.createFlightSearch?.id) {
        return Result.Err(new AirSearchErrors.CreateSearchError('No search id returned'))
      }

      return Result.Ok({ searchId: data.createFlightSearch.id })
    } catch (e) {
      if (e instanceof ApolloError && e.graphQLErrors) {
        if (e) {
          const { isRestrictedCountryError, restrictedErrorMessage } = getRestrictedErrorData(
            e.graphQLErrors || [],
            'restrictedMessage',
          )

          if (isRestrictedCountryError) {
            return Result.Err(
              new AirSearchErrors.RestrictedCountryError({ message: restrictedErrorMessage || '' }),
            )
          }

          const {
            isLastPermittedDateError,
            lastPermittedDateErrorDate,
          } = getLastPermittedDateErrorData(e.graphQLErrors || [], 'lastPermittedDate')

          if (isLastPermittedDateError) {
            return Result.Err(
              new AirSearchErrors.LastPermittedDateError({
                message: lastPermittedDateErrorDate || '',
              }),
            )
          }

          return Result.Err(new AirSearchErrors.CreateSearchError(e.graphQLErrors))
        }
      }
      return Result.Err(new AirSearchErrors.UnexpectedErrorCreateSearch(e))
    }
  }

  async getFlights(
    input: GetFlightsInput,
    forceRefetch = false,
  ): Promise<AirSearchAdapterResult<GetFlightsResult>> {
    try {
      const deviceClass =
        screenMatcher.getScreenType() === ScreenType.Mobile
          ? DeviceClass.Mobile
          : DeviceClass.Desktop

      const { data, error } = await this.airSearchDataProvider.getFlights(
        { deviceClass, ...input },
        forceRefetch,
      )

      if (error) {
        return Result.Err(new AirSearchErrors.GetFlightsError(error))
      }
      return Result.Ok(data.flights)
    } catch (e) {
      return Result.Err(new AirSearchErrors.UnexpectedErrorGetFlights(e))
    }
  }

  async getFlightDynamicSiteMessages(
    input: GetFlightDynamicSitMessagesInput,
    forceRefetch = false,
  ): Promise<FlightDynamicSiteMessagesAdapterResult<GetFlightDynamicSiteMessagesResult>> {
    try {
      const { data, error } = await this.airSearchDataProvider.getFlightDsmMessages(
        { searchId: input.searchId },
        forceRefetch,
      )

      if (error) {
        return Result.Err(new GetFlightDynamicSiteMessagesErrors.GetFlightDSMError(error))
      }
      return Result.Ok(mapFlightDynamicSiteMessagesResult(data))
    } catch (e) {
      return Result.Err(
        new GetFlightDynamicSiteMessagesErrors.UnexpectedErrorGetFlightDynamicSiteMessages(e),
      )
    }
  }

  async getFareAttributes(
    input: GetFareAttributesInputValueObject,
    forceRefetch = false,
  ): Promise<AirSearchAdapterResult<FareAttributesResponseValueObject[]>> {
    try {
      const { data, error } = await this.airSearchDataProvider.getFareAttributes(
        { ...input },
        forceRefetch,
      )

      if (error) {
        return Result.Err(new AirSearchErrors.GetFareAttributesError(error))
      }
      return Result.Ok(toFareAttributesResponseValueObject(data.flightFareAttributesInBatch))
    } catch (e) {
      return Result.Err(new AirSearchErrors.UnexpectedErrorGetFlights(e))
    }
  }

  async getAlternatePolicy(
    input: GetAlternatePolicyTripsInput,
    forceRefetch = false,
  ): Promise<GetAlternatePolicyAdapterResult<AlternateFlightTripValueObject[]>> {
    try {
      const { data, error } = await this.airSearchDataProvider.getAlternatePolicyTrips(
        input,
        forceRefetch,
      )

      if (data.getAlternatePolicyTrips.__typename === 'GetAlternatePolicyTripsResponse') {
        if (data.getAlternatePolicyTrips.policyTrips.length) {
          return Result.Ok(mapFlightTripsToValueObject(data.getAlternatePolicyTrips.policyTrips))
        }
      }

      return Result.Err(new GetAlternatePolicyErrors.UnavailableAlternatePolicyTrips(error))
    } catch (e) {
      return Result.Err(new GetAlternatePolicyErrors.UnavailableAlternatePolicyTrips(e))
    }
  }
}
