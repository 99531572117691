import { useTranslation } from 'react-i18next'
import type { ColorPalette } from '@fiji/types'
import { SegmentType } from '@fiji/graphql/types'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'

type Args = {
  type: SegmentType.Flight | SegmentType.Hotel | SegmentType.CarRental | SegmentType.Train
  isDisabled?: boolean
}

const i18nPath = 'TripReview.AddButtonsPWA.'
const i18nPathAccessability = 'Accessibility.ReviewTripPage'
const buttonTranslatesMap = {
  [SegmentType.Flight]: 'Flight',
  [SegmentType.Hotel]: 'Hotel',
  [SegmentType.CarRental]: 'CarRental',
  [SegmentType.Train]: 'Train',
}

export function useTripReviewAddButton({ type, isDisabled }: Args) {
  const { t } = useTranslation()
  const label = t(i18nPath + buttonTranslatesMap[type])
  const ariaLabel = isDisabled
    ? t(i18nPathAccessability + '.AddButton', { label })
    : t(i18nPathAccessability + '.AddButtonActive', { label })
  const iconName = getIconNameBySegment(type)
  const iconColor: keyof ColorPalette = isDisabled ? 'bodyText' : 'mainText'

  return {
    label,
    ariaLabel,
    iconName,
    iconColor,
  }
}
