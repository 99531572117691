import type { ReactNode, MutableRefObject } from 'react'
import { Portal } from '@etta/ui/portal'
import { Anchor, Container, Point, StyledContainer, Triangle } from './tooltip-styled'
import { useTooltip } from './use-tooltip'
import type { TooltipAlign, TooltipPlace } from './types'

export type TooltipProps = {
  slot: ReactNode
  children: ReactNode
  place?: TooltipPlace
  placementStyle?: object
  align?: TooltipAlign
  'aria-label'?: string
  isIndependentOfParentWidth?: boolean
  parentRef?: MutableRefObject<HTMLDivElement | null>
  isWhiteTooltip?: boolean
  displayOn?: 'click' | 'hover'
  fixWidth?: number
  isClickable?: boolean
  padding?: number
}

export function Tooltip({
  slot,
  children,
  place: defaultPlace = 'bottom',
  align: defaultAlign = 'center',
  'aria-label': ariaLabel,
  placementStyle = {},
  isIndependentOfParentWidth,
  fixWidth,
  isWhiteTooltip = false,
  parentRef,
  displayOn = 'hover',
  isClickable,
  padding = 12,
}: TooltipProps) {
  const { viewport, ref, handleEnter, handleLeave, maxWidth, place, align } = useTooltip({
    place: defaultPlace,
    align: defaultAlign,
    parentRef,
  })

  const onEnterEventHandler =
    displayOn === 'click'
      ? {
          onClick: handleEnter,
        }
      : {
          onPointerEnter: handleEnter,
          onPointerOver: handleEnter,
        }

  return (
    <>
      <Anchor ref={ref} />
      <div
        aria-label={ariaLabel}
        {...onEnterEventHandler}
        onMouseLeave={!isClickable ? handleLeave : undefined}
        onPointerLeave={!isClickable ? handleLeave : undefined}
        onPointerOut={!isClickable ? handleLeave : undefined}
        style={{ ...placementStyle }}>
        {children}
      </div>
      {viewport.isVisible && (
        <Portal target="#modals-container">
          <Point
            left={viewport.position.left}
            top={viewport.position.top}
            onMouseLeave={handleLeave}
            onClick={isClickable ? handleLeave : undefined}>
            <Triangle place={place} />
            <Container place={place} align={align}>
              <StyledContainer
                fixWidth={fixWidth}
                maxWidth={!isIndependentOfParentWidth ? maxWidth : undefined}
                padding={padding}
                isWhiteTooltip={isWhiteTooltip}>
                {slot}
              </StyledContainer>
            </Container>
          </Point>
        </Portal>
      )}
    </>
  )
}
