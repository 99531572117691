import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  padding: 0 32px;
  background-color: ${({ theme }) => theme.colors.background};
`

export const FlightDetailsLegendContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`

export const CustomActionsContainer = styled.div`
  border-radius: 10px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.colors.white};

  & > button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px 0 20px;
  }
`

export const Section = styled.div`
  margin: 24px 0;
`
