import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'

type Props = {
  onOpenTermAndPrivacyNotice: () => void
}

export function UberTermAndPrivacyNotice({ onOpenTermAndPrivacyNotice }: Props) {
  const { t } = useTranslation()
  const basePath = 'Mobility.ReviewPage.'

  return (
    <Block
      marginBottom={16}
      onClick={onOpenTermAndPrivacyNotice}
      data-tracking-id="uber-term-and-privacy-notice">
      <Text variant="footnoteMedium" color="bodyText1">
        {t(basePath + 'TermAndCondition.1')}
        <Text variant="footnoteMedium" color="primary">
          {t(basePath + 'TermAndCondition.2')}
        </Text>{' '}
        {t(basePath + 'TermAndCondition.3')}
        <Text variant="footnoteMedium" color="primary">
          {t(basePath + 'TermAndCondition.4')}
        </Text>{' '}
        {t(basePath + 'TermAndCondition.5')}
      </Text>
    </Block>
  )
}
