import { useCallback, useEffect, useRef, useState } from 'react'
import type { FareSegmentSeatRows } from '@fiji/types'
import { rollbar } from '@fiji/rollbar'
import { useFetchSeatMapSegment } from './use-fetch-seat-map-segment'
import type { FetchSeatMapSegment } from './types'

type Props = {
  fetchSeatMapSegments: (FetchSeatMapSegment | null)[]
  itineraryId?: string
  shouldFetch?: boolean
}

export function useFetchSeatRows({ fetchSeatMapSegments, itineraryId, shouldFetch = true }: Props) {
  const { fetchAirSeatMap, abortAllFetches } = useFetchSeatMapSegment()
  const [isLoading, setIsLoading] = useState(false)
  const [fareSegmentSeatRows, setFareSegmentsSeatRows] = useState<FareSegmentSeatRows[]>([])
  const isFetchedRef = useRef(false)

  const fetchData = useCallback(async () => {
    if (isFetchedRef.current) {
      return
    }
    setIsLoading(true)
    isFetchedRef.current = true

    try {
      const fareSegmentRequests = fetchSeatMapSegments.filter(Boolean).map((setMapSegment) => {
        if (!setMapSegment) {
          return null
        }

        return fetchAirSeatMap({ ...setMapSegment, itineraryId })
      })
      const results = await Promise.all(fareSegmentRequests)

      const fareSegmentSeatRowsResult = results.filter(Boolean) as FareSegmentSeatRows[]
      setFareSegmentsSeatRows(fareSegmentSeatRowsResult)
    } catch (e) {
      if (e instanceof Error) {
        rollbar.error(e)
      } else {
        rollbar.error(new Error('Unable to fetch seat rows'))
      }
    } finally {
      setIsLoading(false)
    }
  }, [fetchSeatMapSegments, fetchAirSeatMap, itineraryId])

  const retryFetch = useCallback(
    async (id: string) => {
      setIsLoading(true)
      const targetSegment = fetchSeatMapSegments.find(
        (fetchedSegment) => fetchedSegment?.segmentId === id,
      )
      if (!targetSegment) {
        return
      }
      try {
        const result = await fetchAirSeatMap({ ...targetSegment, itineraryId })
        setFareSegmentsSeatRows((fetched) =>
          fetched.map((n) => (n.segmentId === targetSegment.segmentId ? result : n)),
        )
      } catch (e) {
        if (e instanceof Error) {
          rollbar.error(e)
        } else {
          rollbar.error(new Error('Unable to refetch seat rows'))
        }
      } finally {
        setIsLoading(false)
      }
    },
    [fetchSeatMapSegments, fetchAirSeatMap, itineraryId],
  )

  useEffect(() => {
    if (shouldFetch) {
      abortAllFetches()
      fetchData()
      return abortAllFetches
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch])

  return {
    isLoading,
    fareSegmentSeatRows,
    retryFetch,
  }
}
