import type { ServiceCategory } from '@fiji/graphql/types'
import { TripType } from '@etta/core/enums/trip-type.enum'
import type { TripMeetingInfoValueObject } from '@etta/modules/post-booking-list/core/value-object/trips-list.value-object'
import { useTripCard } from './hooks'
import defaultBackground from './background.svg?url'
import { TripCardLayout } from './layout'

type Props = {
  name?: string | null
  transactionId?: string | null
  startDate: string
  endDate: string
  services: ServiceCategory[]
  tripType: TripType
  transactionGroupId?: string | null
  cityImageUrl: string
  isFirst: boolean
  view?: 'slim' | 'wide'
  travelerName?: string
  meetingInfo?: TripMeetingInfoValueObject | null
}

export function TripCard({
  name,
  startDate,
  endDate,
  services,
  transactionId,
  tripType,
  transactionGroupId,
  cityImageUrl,
  isFirst,
  view,
  travelerName,
  meetingInfo
}: Props) {
  const { tripDateText, tripCardAriaText, handleCardClick, cityImageText } = useTripCard({
    name,
    startDate,
    endDate,
    tripType,
    cityImageUrl,
    defaultBackground,
  })

  return (
    <TripCardLayout
      tripCardAriaText={tripCardAriaText}
      isFirst={isFirst}
      tripDateText={tripDateText}
      cityImageText={cityImageText}
      cityImageUrl={cityImageUrl}
      services={services}
      onCardClick={handleCardClick}
      transactionGroupId={transactionGroupId}
      transactionId={transactionId}
      name={name}
      isCancelled={tripType === TripType.Cancelled}
      view={view}
      travelerName={travelerName}
      meetingInfo={meetingInfo}
    />
  )
}
