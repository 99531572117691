import { Result } from 'fnscript'
import { Domain } from '@etta/core/enums'
import { Inject, Service } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import { AirSearchStore } from '@etta/modules/air-search/interface/stores/air-search/air-search.store'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CartAdapter, ItineraryAdapter } from '@etta/modules/review-trip/infra'
import type { PromisedResult } from '@etta/core/type-utils'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import type {
  AddFlightArgs,
  ChangeFlightArgs,
  ChangeFlightSeatsAssignmentArgs,
  RemoveFlightArgs,
} from '../types'
import type { CartErrorsInstances, ItineraryErrorsInstances } from '../../core/errors'
import type { AddFlightResult } from '../../infra/itinerary/types'
import { SegmentTypeCheckService } from './segment-type-check.service'

@Service()
export class ReviewTripFlightService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly airSearchStore: AirSearchStore,
    @Inject()
    private readonly segmentTypeCheckService: SegmentTypeCheckService,
  ) {}

  async removeFlight({ flightId }: RemoveFlightArgs) {
    const isMod2FlowEnabled = this.displayConfigurationStore.isMod2FlowEnabled

    if (isMod2FlowEnabled) {
      const flight = this.reviewTripStore.trip?.segments.find((segment) => {
        if (this.segmentTypeCheckService.isFlightSegment(segment)) {
          return segment.uuid === flightId
        }
      })

      const intentId = flight?.uuid
      const tripId = this.reviewTripStore.tripId

      const result = await this.cartAdapter.removeFromCart({ intentId, cartId: tripId })
      return result
    }

    return this.itineraryAdapter.removeFlight({
      itineraryId: this.reviewTripStore.itineraryId,
      flightId,
    })
  }

  changeFlight({ newKey, oldKey, addSeatAssignment }: ChangeFlightArgs) {
    return this.itineraryAdapter.changeFlight({
      itineraryId: this.reviewTripStore.itineraryId,
      newKey,
      oldKey,
      addSeatAssignment,
    })
  }

  async addFlight({
    key,
    addSeatAssignment,
  }: AddFlightArgs): PromisedResult<
    AddFlightResult,
    CartErrorsInstances | ItineraryErrorsInstances
  > {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      const result = await this.cartAdapter.addToCart({
        cartId: this.reviewTripStore.tripId,
        intentDomain: Domain.Air,
        inventoryId: key,
        searchId: this.airSearchStore.searchId || '',
      })

      if (result.isErr()) {
        return Result.Err(result.getError())
      }

      return Result.Ok({
        isSoldOut: false,
        isRepriceFailed: false,
        isSuccess: true,
        priceChange: null,
      })
    }

    return this.itineraryAdapter.addFlight({
      itineraryId: this.reviewTripStore.itineraryId,
      key,
      addSeatAssignment,
    })
  }

  changeFlightSeatsAssignment({
    addSeats,
    removeSeats,
    itineraryId,
  }: ChangeFlightSeatsAssignmentArgs) {
    return this.itineraryAdapter.changeFlightSeatsAssignment({
      addSeats,
      removeSeats,
      itineraryId,
    })
  }
}
