import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'

const i18Base = 'PostBooking.TripDetails'

export function useDetailsError() {
  const type = useScreenType()
  const { t } = useTranslation()

  const retryButtonText = t(i18Base + '.RetryButton')

  const translations = useMemo(
    () => ({
      heading: t(i18Base + '.ErrorMessage'),
      retryButtonAriaLabel: t(i18Base + 'RetryButtonAriaLabel', { label: retryButtonText }),
      description:
        type === ScreenType.Desktop
          ? t(i18Base + '.ErrorDescription')
          : t(i18Base + '.ErrorDescriptionMobile'),
    }),
    [type, t, retryButtonText],
  )

  return {
    translations,
    retryButtonText,
  }
}
