import styled from 'styled-components'

export const Segment = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.mainText};
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  align-items: center;
  font-size: 13px;

  color: ${(p) => p.theme.colors.mainText};
`

export const Title = styled.span`
  color: ${(p) => p.theme.colors.error};
  padding-left: 5px;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
`
