import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import type { CostSegmentValueObject } from '../core/value-objects/cost-segment.value-object'
import type { CostAllocationResultValueObject } from '../core/value-objects/cost-allocation-result.value-object'
import { CostAllocationProvider } from './data-providers/cost-allocation.data-provider'
import { CostAllocationErrors } from './types'
import type { AdapterResult } from './types'
import { toCostSegmentValueObject } from './mappers/cost-segment.mapper'
import { toCostAllocationResultValueObject } from './mappers/cost-allocation.mapper'

@Adapter()
export class CostAllocationAdapter {
  constructor(
    @Inject()
    private readonly costAllocationProvider: CostAllocationProvider,
  ) {}

  async getCostSegments(): AdapterResult<CostSegmentValueObject[]> {
    try {
      const { data, error } = await this.costAllocationProvider.getCostSegments()
      if (error) {
        return Result.Err(new CostAllocationErrors.UnexpectedError(error))
      }
      const cs = data.costSegments?.segments.map(toCostSegmentValueObject) || []
      return Result.Ok(cs)
    } catch (e) {
      return Result.Err(new CostAllocationErrors.UnexpectedError(e))
    }
  }

  async getCostAllocations(
    id: number,
    query?: string,
    url?: string,
    pageSize?: number,
  ): AdapterResult<CostAllocationResultValueObject | undefined> {
    try {
      const { data, error } = await this.costAllocationProvider.getCostAllocations({
        segmentId: id,
        query,
        url,
        pageSize,
      })
      if (error) {
        return Result.Err(new CostAllocationErrors.UnexpectedError(error))
      }

      if (!data.multiLayerCostAllocation) {
        return Result.Ok(undefined)
      }

      const allocation = toCostAllocationResultValueObject(data.multiLayerCostAllocation)
      return Result.Ok(allocation)
    } catch (e) {
      return Result.Err(new CostAllocationErrors.UnexpectedError(e))
    }
  }
}
