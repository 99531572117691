import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  align-items: center;
  ${({ isActive }) =>
    isActive &&
    css`
      z-index: 1;
    `}

  &:hover > div:nth-child(2) {
    display: block;
  }
`

export const ActiveMarkerTooltip = styled(Text)`
  position: absolute;
  left: 50px;
  z-index: 1;
  white-space: nowrap;
  pointer-events: none;
  transform: translatey(-30%);
  background: ${({ theme }) => theme.colors.background2};
  border-radius: 5px;
  height: min-content;
  padding: 5px;
`

export const ActiveMarkerContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`

export const Dialog = styled.div<{ isAppleMap: boolean }>`
  display: ${({ isAppleMap }) => (isAppleMap ? 'block' : 'none')};
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: 2;
  top: 25px;
  background: ${({ theme }) => theme.colors.white};
  width: 279px;
  padding: 14px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.mainText};
`

export const Flex = styled.div`
  display: flex;
`

export const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const VendorLogos = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  height: 24px;
  align-self: start;
`

export const TimeContainer = styled.div`
  border-top: 1px dashed ${({ theme }) => theme.colors.borderLight};
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`

export const TimeContainerText = styled(Text)`
  margin-left: 10px;
`

export const ButtonContainer = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderLight};
`
