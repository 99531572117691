import { Store } from '@etta/di'
import type { FlightDynamicSiteMessage } from '@etta/modules/air-search/core/value-objects/get-flight-dynamic-site-messages.result'

@Store()
export class AirDynamicSiteMessagesStore {
  private _isLoading: boolean = false
  private _dynamicSiteMessages: FlightDynamicSiteMessage[] = []

  get isLoading() {
    return this._isLoading
  }

  get dynamicSiteMessages() {
    return this._dynamicSiteMessages
  }

  setIsLoading = (isLoading: boolean) => {
    this._isLoading = isLoading
  }

  setDynamicSiteMessages = (value: FlightDynamicSiteMessage[]) => {
    this._dynamicSiteMessages = value
  }

  dropStore() {
    this.setIsLoading(false)
    this.setDynamicSiteMessages([])
  }
}
