import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Menu = styled.div<{ isForSearchHeader: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 8px !important;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  z-index: 3;
  overflow: hidden;
  ${({ isForSearchHeader }) =>
    isForSearchHeader &&
    css`
      min-width: 300px;
    `}
  > button {
    background-color: ${(p) => p.theme.colors.white};
    border: 0;
  }
`

export const List = styled.div`
  max-height: 368px;
  overflow-y: scroll;
`
