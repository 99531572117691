import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Portal } from '@etta/ui/portal'
import { TooltipContainer, TooltipLabel } from './tooptip.styles'

type Props = {
  isOpen: boolean
}

export function Tooltip(props: Props) {
  const { t } = useTranslation()
  return (
    <Portal>
      <TooltipContainer {...props}>
        <Icon name="tickPWA" size="large" color="mainText2" />
        <TooltipLabel>{t('CopyClipboard.Copied')}</TooltipLabel>
      </TooltipContainer>
    </Portal>
  )
}
