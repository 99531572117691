import { SeatFeatures } from '@etta/modules/seat-map/core/enums'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'

export function getSeatFeature(seat: Partial<SeatMapRowSeatValueObject>): SeatFeatures {
  const { isNearWindow, isExitRowSeat, isPremium, isPaid, isOverWing } = seat

  switch (true) {
    case isPremium && isPaid:
      return SeatFeatures.PaidPremium
    case isPremium:
      return SeatFeatures.Premium
    case isPaid:
      return SeatFeatures.Paid
    case isExitRowSeat:
      return SeatFeatures.ExitRowSeat
    case isOverWing:
      return SeatFeatures.OverWing
    case isNearWindow:
      return SeatFeatures.NearWindow
    default:
      return SeatFeatures.NoFeature
  }
}
