import { useEffect } from 'react'
import { SegmentType } from '@etta/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'

export function useOnNotificationReceive() {
  const {
    postBookingTripStore,
    postBookingTripDetailsActions,
    postBookingTripActions,
  } = usePostBookingContext()
  const { queryParams, groupedSegments } = postBookingTripStore

  useEffect(() => {
    if (!queryParams.airlineCode || !queryParams.airlineNumber) {
      return
    }
    if (!groupedSegments.length) {
      return
    }
    groupedSegments.forEach((group) => {
      group.segments.forEach((segment) => {
        if (segment.type !== SegmentType.Flight) {
          return
        }
        const flightSegments = segment.segments
        const isFlightFromNotification = flightSegments?.some((flight) => {
          return (
            flight.carrierCode === queryParams.airlineCode &&
            flight.flightNumber === queryParams.airlineNumber
          )
        })

        if (!isFlightFromNotification) {
          return
        }
        postBookingTripDetailsActions.handleOpenSelectedDetails(segment)
      })
    })
    postBookingTripActions.clearNotificationQueryParams()
  }, [
    postBookingTripActions,
    postBookingTripDetailsActions,
    queryParams.airlineCode,
    queryParams.airlineNumber,
    groupedSegments,
  ])
}
