import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { FooterDesktop } from './desktop'
import { FooterMobile } from './mobile'

export function FooterLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<FooterMobile {...props} />}
      desktopSlot={<FooterDesktop {...props} />}
    />
  )
}
