import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetRideHailSuggestionsQuery,
  GetRideHailSuggestionsQueryVariables,
} from '@fiji/graphql/hooks'
import { GetRideHailSuggestionsDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class RideHailSuggestionDataProvider extends GraphqlDataProvider {
  getRideHailSuggestion() {
    return this.client.query<GetRideHailSuggestionsQuery, GetRideHailSuggestionsQueryVariables>({
      query: GetRideHailSuggestionsDocument,
      fetchPolicy: 'no-cache',
    })
  }
}
