import { Inject, Action } from '@etta/di'
import { UnusedTicketService } from '../services/unused-ticket.service'

@Action()
export class UnusedTicketsAction {
  constructor(
    @Inject()
    private readonly unusedTicketService: UnusedTicketService,
  ) {}

  async getUnusedTickets() {
    await this.unusedTicketService.getUnusedTickets()
  }
}
