import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px 80px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  background-color: ${(p) => p.theme.colors.white};
`

export const FooterContentContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  color: ${(p) => p.theme.colors.bodyText};
  font-size: 1.2rem;
  line-height: 1.6rem;
`

export const LogoContainer = styled.div``

export const PartnerLogo = styled.img`
  max-width: 142px;
  max-height: 42px;
  object-fit: contain;
`

export const FooterContent = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const OverrideStylingDiv = styled.div`
  // We overwrite the text to make sure it is the same color, font-family, and weight in every theme
  word-wrap: break-word;

  * {
    color: ${(p) => p.theme.colors.bodyText} !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
  }

  p {
    margin: unset;
  }

  br {
    content: '';
  }

  br::after {
    content: '';
    display: block;
    margin: 5px;
  }

  a {
    color: ${(p) => p.theme.colors.primary} !important;
  }
`

export const CustomFooter = styled(OverrideStylingDiv)``

export const Copyright = styled(OverrideStylingDiv)``

export const LinksContainer = styled.div`
  display: flex;
  gap: 4px;
`

export const Separator = styled.span`
  &::before {
    content: '|';
    color: ${(p) => p.theme.colors.borderLight};
    font-size: 1.2rem;
  }
`

export const IconLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
`

export const DownloadLinksContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const SocialNetworkLinksContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
`

export const SocialNetworkLink = styled.a`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: auto;
  }
`
