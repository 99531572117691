import { HotelAddressCard } from '@etta/components/hotel-address-card/hotel-address-card'
import { HotelDetailsEcoCheck } from '@etta/components/hotel-details-eco-check/hotel-details-eco-check'
import { Modal } from '@etta/ui/modal'
import { Block } from '@etta/ui/block'
import { usePreventPullRefresh } from '@fiji/hooks/body/use-prevent-pull-refresh'
import { HotelReviews } from '@etta/components/hotel-reviews/hotel-reviews'
import { RoomRatesModal } from '../room-rates-modal'
import { HotelDetailsRooms } from './hotel-details-rooms'
import { HotelDetailsAmenities } from './hotel-details-amenities'
import type { HotelDetailsProps } from './types'
import { HotelSafetyCheck } from './hotel-safety-check'
import { useHotelDetailsModal } from './use-hotel-details-modal'
import { HotelDetailsLayout } from './layout'
import { HotelAbout } from './hotel-about'

export function HotelDetailsContent({
  isError,
  onRetry,
  hotelDetails,
  onClose,
  onRoomSelect,
  isHotelDetailsLoading,
  areHotelAboutDatesDisplayed = true,
  areHotelReviewsDisplayed = true,
  isHotelDescriptionDisplayed = true,
  children,
  hotelEmission,
  perDiemRateCap,
  serviceFeeLabel,
}: HotelDetailsProps) {
  usePreventPullRefresh()

  const {
    hotelId,
    name,
    address,
    amenities,
    isSoldOut,
    phoneNumber,
    rooms,
    exclusiveAmenities,
    totalReviewCount,
    medianReviewRating,
    reviews,
    hotelReviewsDetailsUrl,
    photos,
  } = hotelDetails

  const {
    scrollContainerRef,
    featureFlags,
    isMobile,
    isAmenitiesVisible,
    isAddressCardVisible,
    onRoomDetailsSelect,
    roomDetails,
    isRoomDetailsOpen,
    onRoomDetailsClose,
    totalNights,
  } = useHotelDetailsModal({ hotelDetails })

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <HotelDetailsLayout
          scrollContainerRef={scrollContainerRef}
          onClose={onClose}
          hotelDetails={hotelDetails}
          photos={photos}
          areHotelReviewsDisplayed={areHotelReviewsDisplayed}
          ecoCheckSlot={
            featureFlags.isSustainabilityEnabled && <HotelDetailsEcoCheck {...hotelEmission} />
          }
          aboutSlot={
            <HotelAbout
              hotelDetails={hotelDetails}
              areHotelAboutDatesDisplayed={areHotelAboutDatesDisplayed}
              isHotelDescriptionDisplayed={isHotelDescriptionDisplayed}
            />
          }
          roomsSlot={
            <HotelDetailsRooms
              isLoading={isHotelDetailsLoading}
              rooms={rooms}
              isSoldOut={!!isSoldOut}
              onRoomDetailsSelect={onRoomDetailsSelect}
              onRoomSelect={onRoomSelect}
              isError={isError}
              onRetry={onRetry}
              perDiemRateCap={perDiemRateCap}
              serviceFeeLabel={serviceFeeLabel}
              totalNights={totalNights}
            />
          }
          locationSlot={
            isAddressCardVisible && (
              <Block marginTop={32}>
                <HotelAddressCard hotelName={name} phone={phoneNumber} address={address} />
              </Block>
            )
          }
          safetyCheckSlot={
            address.geocode &&
            featureFlags.isHotelSafetyCheckEnabled && (
              <HotelSafetyCheck
                hotelName={name}
                lat={address.geocode.lat}
                lng={address.geocode.long}
              />
            )
          }
          amenitiesSlot={
            isAmenitiesVisible && (
              <HotelDetailsAmenities
                amenities={amenities}
                exclusiveAmenities={exclusiveAmenities}
              />
            )
          }
          reviewsSlot={
            <HotelReviews
              hotelId={hotelId}
              totalReviewCount={totalReviewCount}
              medianReviewRating={medianReviewRating}
              reviews={reviews}
              hotelReviewsDetailsUrl={hotelReviewsDetailsUrl}
            />
          }>
          {children}
        </HotelDetailsLayout>
      </Modal.Body>

      <RoomRatesModal
        isOpen={isRoomDetailsOpen}
        onClose={onRoomDetailsClose}
        roomRates={roomDetails}
        layout={isMobile ? 'default' : 'wide-left-slide'}
      />
    </>
  )
}
