import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { SegmentType } from '@fiji/graphql/types'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { Block } from '@etta/ui/block'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { MobilityCallToAction } from '@etta/components/mobility-call-to-action/mobility-call-to-action'
import { FlightDetailsEcoCheck } from '@etta/components/flight-details-eco-check/flight-details-eco-check'
import type { FlightEmission } from '@fiji/types'
import type { TripStatus } from '@etta/modules/post-booking/core/enums'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { ChangeTripNotAllowedModal } from '@etta/components/change-trip-not-allowed-modal'
import { WarningCancelRoundFlightModal } from '@etta/components/cancel-trip-modal/warning-cancel-round-flight-modal'
import { ChangeFlightsDialog } from '@etta/components/change-flights-dialog'
import { DetailsContainer } from '../../trip-flight-styled'
import { Headline } from '../../headline'
import { Traveler } from '../../traveler'
import { FlightDetails } from '../../flight-details'
import { Price } from '../../price'
import { useTripFlight } from '../../use-trip-flight'
import { CONTENT_MARGIN_TOP } from '../../constants'

export type TripFlightContentProps = {
  flight: FlightPostBookingValueObject
  travelerName?: string
  onClose: () => void
  tripType: TripStatus
  cancellationDate?: string | null
  onCancel: () => void
  startDate: string
  endDate: string
  tripName?: string | null
  processId: string
  itineraryId: string
  flightId?: string | null
  flightEmission: FlightEmission
  transactionRegistryServiceItemId?: string | null
  isMultiCity?: boolean
  isTripInProgress?: boolean
  onSeatMapOpen: () => void
}

export const TripFlightContentMobileLayout = observer(function TripFlightContentMobileLayout({
  flight,
  onClose,
  onCancel,
  tripType,
  cancellationDate,
  tripName,
  travelerName,
  startDate,
  endDate,
  processId,
  itineraryId,
  flightId,
  flightEmission,
  transactionRegistryServiceItemId,
  isMultiCity,
  onSeatMapOpen,
  isTripInProgress,
}: TripFlightContentProps) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.FlightDetails'
  const { featureFlags } = useFeatureFlags()
  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()
  const {
    segments,
    rate,
    taxes,
    transactionFees,
    totalCost,
    totalWithUnusedTicket,
    unusedTicketRate,
  } = flight

  // TODO: update isCheckInAvailable after check-in work done
  const isCheckInAvailable = true

  const {
    destination,
    destinationAirport,
    isMultipleFlights,
    originAirport,
    viaLocationText,
    checkInData,
    isTripCancelled,
    pnrNumber,
    confirmationNumber,
    actionButtons,
    handleModifyFlight,
    viewState,
    onCloseViewState,
    onOpen,
    isSeatChangeAllowed,
    isCompleted,
    handleViewSupport,
    toggleModifyFlight,
    segmentStatus,
    isFlightCancelled,
    isChangingReturnFlight,
    isChangingEntireFlight,
    returnFlightModal,
    handleSubmitModifyFlight,
    someSegmentsNotStarted,
  } = useTripFlight({
    segments,
    tripType,
    tripName,
    flight,
    startDate,
    endDate,
    processId,
    itineraryId,
    flightId,
    isMultiCity,
    isTripInProgress,
    onSeatMapOpen,
    onClose,
  })

  const headerTitle = t(i18nBase + '.FlightToDestination', { destination })

  const headerBackgroundColor = getTripDetailsSegmentBackground({
    tripStatus: tripType,
    segmentStatus,
  })

  return (
    <>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor={headerBackgroundColor}
            animation="opacity"
            leftSlot={<Header.BackButton color="mainText2" onClick={onClose} />}
          >
            <Header.Title title={headerTitle} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <DetailsContainer color={headerBackgroundColor}>
          <Headline
            destination={destination}
            isMultipleFlights={isMultipleFlights}
            viaLocationText={viaLocationText}
          />
        </DetailsContainer>
        <div ref={anchorRef} />
        <Block paddingHorizontal={16} marginTop={CONTENT_MARGIN_TOP}>
          <Traveler
            isCompleted={isCompleted}
            pnrNumber={pnrNumber}
            isCheckInAvailable={isCheckInAvailable}
            isTripCancelled={isTripCancelled}
            isFlightCancelled={isFlightCancelled}
            cancellationDate={cancellationDate}
            confirmationNumber={confirmationNumber}
            checkInData={checkInData}
            travelerName={travelerName || '-'}
          />
          <Block marginTop={16}>
            <MobilityCallToAction
              processId={processId}
              dropOffSegmentId={transactionRegistryServiceItemId}
              tripType={tripType}
              segmentType={flight.type}
            />
          </Block>
          <Block marginTop={16}>
            {segments &&
              segments.map((segment, index) => {
                const nextSegment = segments[index + 1]
                const nextFlightStart = nextSegment ? (nextSegment.departureDate as string) : ''
                return (
                  <FlightDetails
                    key={segment.flightNumber}
                    segment={segment}
                    nextSegment={segments[index + 1]}
                    legNumber={index + 1}
                    legs={segments.length}
                    nextFlightStart={nextFlightStart}
                    isTripCancelled={isTripCancelled}
                    isSeatChangeAllowed={isSeatChangeAllowed}
                    isFlightCancelled={isFlightCancelled}
                    onSeatMapOpen={onOpen}
                  />
                )
              })}
          </Block>
          {featureFlags.isSustainabilityEnabled && (
            <Block marginTop={16}>
              <FlightDetailsEcoCheck {...flightEmission} />
            </Block>
          )}
          <Block marginTop={16}>
            <Price
              originAirport={originAirport}
              destinationAirport={destinationAirport}
              rate={rate}
              taxes={taxes}
              total={totalCost}
              totalWithUnusedTicket={totalWithUnusedTicket}
              unusedTicketRate={unusedTicketRate}
              transactionFees={transactionFees}
            />
          </Block>
        </Block>
        <Block marginTop={28} marginBottom={32}>
          <ActionButtons
            type={SegmentType.Flight}
            isPostBooking
            onShareClick={actionButtons.handleShare}
            onCancelClick={onCancel}
            onChangeClick={handleModifyFlight}
            isCancel={!isCompleted && !isTripCancelled && someSegmentsNotStarted}
            isChange={!isCompleted && !isTripCancelled && someSegmentsNotStarted}
          />
        </Block>
      </Modal.Body>
      <ChangeFlightsDialog
        onModify={handleSubmitModifyFlight}
        isChangingReturnFlight={isChangingReturnFlight}
        isChangingEntireFlight={isChangingEntireFlight}
        isOpen={returnFlightModal.isOpen}
        onClose={returnFlightModal.handleClose}
      />
      <ChangeTripNotAllowedModal
        isOpen={toggleModifyFlight.isOpen}
        onClose={toggleModifyFlight.handleClose}
        onViewSupport={handleViewSupport}
        modalTitle={t(`${i18nBase}.Modify`)}
      />
      <ViewStateModal viewState={viewState} onDone={onCloseViewState} />
      <WarningCancelRoundFlightModal />
    </>
  )
})
