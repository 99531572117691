import { useTranslation } from 'react-i18next'
import { Wrapper, SwapLocationIcon } from './swap-location-button-styled'

type Props = {
  onClick?: () => void
  isCentered?: boolean
  isDisabled?: boolean
}
export function SwapLocationButton({ onClick, isCentered, isDisabled }: Props) {
  const { t } = useTranslation()
  return (
    <Wrapper
      aria-label={t('TripPlanner.BaseSearch.Buttons.SwapLocations')}
      onClick={onClick}
      isCentered={isCentered}
      disabled={isDisabled}>
      <SwapLocationIcon />
    </Wrapper>
  )
}
