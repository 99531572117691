import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { Marker } from '../../marker'
import type { CarRentalMapProps } from '../../types'
import { Container } from './car-rental-map-desktop-layout-styled'
import { TileSwitcher } from './tile-switcher'
import { useCarRentalMapDesktopLayout } from './use-car-rental-map-desktop-layout'

export function CarRentalMapDesktopLayout({
  locations,
  center,
  onLocationClick,
  selectedDropOffLocation,
  selectedPickUpLocation,
}: CarRentalMapProps) {
  const {
    handleTileChange,
    selectedTile,
    markerData,
    defaultCenter,
    isInteractive,
  } = useCarRentalMapDesktopLayout({
    locations,
    center,
    selectedPickUpLocation,
    selectedDropOffLocation,
  })

  return (
    <Container>
      <EttaMap
        defaultZoom={10}
        defaultCenter={defaultCenter}
        options={{
          clickableIcons: true,
          fullscreenControl: true,
          zoomControl: true,
          mapTypeId: selectedTile,
        }}>
        {markerData.map(({ location, street, ...props }, i) => {
          if (!props.lat || !props.lng) {
            return null
          }
          const key = `${street}-${i}`
          return (
            <EttaMap.Marker key={key} calloutAppearStrategy="hover" lat={props.lat} lng={props.lng}>
              <Marker
                {...props}
                isInteractive={isInteractive}
                onSelect={() => onLocationClick(location)}
                markerKey={key}
              />
            </EttaMap.Marker>
          )
        })}
      </EttaMap>
      <TileSwitcher handleTileChange={handleTileChange} selectedTile={selectedTile} />
    </Container>
  )
}
