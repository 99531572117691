import { DataProvider } from '@etta/di'
import { AddPassportDocument, RemovePassportDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  AddPassportMutation,
  AddPassportMutationVariables,
  RemovePassportMutation,
  RemovePassportMutationVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class PassportDataProvider extends GraphqlDataProvider {
  addPassport(input: AddPassportMutationVariables['input']) {
    return this.client.mutate<AddPassportMutation, AddPassportMutationVariables>({
      mutation: AddPassportDocument,
      variables: {
        input,
      },
    })
  }

  removePassport(input: RemovePassportMutationVariables['input']) {
    return this.client.mutate<RemovePassportMutation, RemovePassportMutationVariables>({
      mutation: RemovePassportDocument,
      variables: {
        input,
      },
    })
  }
}
