import { useTranslation } from 'react-i18next'
import { SafetyCheckTitleContainer } from './safety-check-title-styled'
import type { LogoSizes } from './types'
import { default as SafetyCheckIcon } from './safety-check-icon.svg'

type Props = {
  size?: LogoSizes
  onClick?: () => void
  width?: number
  height?: number
  'data-tracking-id'?: string
}

const i18nBase = 'FlightDetails.SafetyCheck'

function getLogoSize(size: LogoSizes) {
  switch (size) {
    case 'large':
      return {
        width: 168,
        height: 28,
      }
    case 'medium':
      return {
        width: 144,
        height: 24,
      }
    case 'extra-small':
      return {
        width: 72,
        height: 12,
      }
    case 'small':
    default:
      return {
        width: 120,
        height: 20,
      }
  }
}

export function SafetyCheckTitle({
  size = 'small',
  onClick,
  width,
  height,
  'data-tracking-id': dataTrackingId = 'safetycheck-logo',
}: Props) {
  const { t } = useTranslation()
  const { width: computedWidth, height: computedHeight } = getLogoSize(size)

  return (
    <SafetyCheckTitleContainer
      role="button"
      aria-label={t(i18nBase + '.Label')}
      data-tracking-id={dataTrackingId}
      onClick={onClick}
      width={width || computedWidth}
      height={height || computedHeight}
      isClickable={Boolean(onClick)}>
      <SafetyCheckIcon />
    </SafetyCheckTitleContainer>
  )
}
