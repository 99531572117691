import type { Place } from '@fiji/types'
import type { AddressInput } from '@fiji/graphql/types'
import { divideAddressArrayToObject } from '@fiji/utils/address/divide-address-array-to-object'
import { getAirportCode } from '@fiji/utils/get-airport-code'

type Props = {
  setIsLoading?: (value: boolean) => void
  onError: () => void
  onChange?: (value: Place, address?: AddressInput) => void
  onModalClose?: (e: boolean) => void
  onComplete?: (value: Place[]) => void
}

export const useGooglePlacesAutocomplete = ({
  setIsLoading,
  onError,
  onChange,
  onModalClose,
  onComplete,
}: Props) => {
  const autocompleteService = new google.maps.places.AutocompleteService()
  const PlacesServiceStatus = google.maps.places.PlacesServiceStatus
  const placesService = new google.maps.places.PlacesService(document.createElement('div'))
  const handleFindPlaces = ({ place }: { place?: string }) => {
    setIsLoading?.(true)

    autocompleteService.getQueryPredictions(
      {
        input: place,
      },
      (
        predictions: google.maps.places.QueryAutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        setIsLoading?.(false)
        if (status !== PlacesServiceStatus.OK) {
          onError()
          return
        }
        const places = predictions.map<Place>(
          (p: google.maps.places.QueryAutocompletePrediction) => ({
            placeId: p.place_id,
            name: p.description,
            airportCode: getAirportCode(p.description),
          }),
        )

        onComplete?.(places)
      },
    )
  }
  const handleSelectedItemChange = ({ selectedItem }: { selectedItem: Place }) => {
    setIsLoading?.(true)

    placesService.getDetails(
      {
        placeId: selectedItem!.placeId,
        fields: ['name', 'geometry', 'types', 'address_components', 'formatted_address'],
      },
      (
        googlePlace: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        setIsLoading?.(false)

        if (status !== 'OK') {
          // eslint-disable-next-line no-console
          console.error(status)
          return
        }

        const newPlace: Place = {
          placeId: selectedItem!.placeId,
          name: selectedItem.name,
        }

        if (googlePlace.geometry?.location) {
          newPlace.latitude = googlePlace.geometry.location.lat()
          newPlace.longitude = googlePlace.geometry.location.lng()
        }

        const address: AddressInput = {
          lat: newPlace.latitude,
          long: newPlace.longitude,
          ...divideAddressArrayToObject(googlePlace.address_components),
        }

        if (googlePlace.types?.includes('airport')) {
          newPlace.airportCode = getAirportCode(selectedItem!.name)
        }

        if (googlePlace.types?.includes('lodging')) {
          newPlace.isNameSearch = true
        }

        onChange && onChange(newPlace, address)
        onModalClose && onModalClose(false)
      },
    )
  }

  return { handleFindPlaces, handleSelectedItemChange }
}
