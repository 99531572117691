import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`
