import { useState } from 'react'
import { StyledTextVariant } from '@fiji/enums'
import { ROUTES } from '@fiji/routes'
import { getTrackIdFromTitle } from '@fiji/utils/get-tack-id-from-title'
import type { SupportArticlePreviewValueObject } from '../core/value-objects'
import {
  ArrowRightIcon,
  Body,
  ArticlesListItem,
  ArticleTitle,
  ArrowRightIconContainer,
  NavLink,
} from './travel-support-body-styled'

type BodyProps = {
  isPreview?: boolean
  supportArticles?: SupportArticlePreviewValueObject[]
}

export function TravelSupportBody({ supportArticles = [] }: BodyProps) {
  const [activeId, setActiveId] = useState('')

  return (
    <Body>
      {supportArticles.map(({ id, title }) => (
        <li key={id} onClick={() => setActiveId(id)}>
          <NavLink to={ROUTES.support.article({ articleId: id })} isSelected={activeId === id}>
            <ArticlesListItem>
              <ArticleTitle
                data-tracking-id={`support-page_${getTrackIdFromTitle(title)}-link`}
                textVariant={StyledTextVariant.body}>
                {title}
              </ArticleTitle>
              <ArrowRightIconContainer>
                <ArrowRightIcon />
              </ArrowRightIconContainer>
            </ArticlesListItem>
          </NavLink>
        </li>
      ))}
    </Body>
  )
}
