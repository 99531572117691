import { Row } from '../../row'
import type { Props } from '../types'
import { getIconName } from '../get-icon-name'
import { ListItem, Title } from './list-mobile-styled'

export function ListMobile({
  list,
  getItemProps,
  searchType,
  highlightedIndex,
  title,
  startIndex = 0,
  iconSize = 'large',
}: Props) {
  if (!list.length) {
    return null
  }

  return (
    <>
      <Title>{title}</Title>

      {list.map((item, i) => {
        const index = startIndex + i

        return (
          <ListItem
            {...getItemProps({ item, index })}
            key={item.placeId}
            active={highlightedIndex === index}>
            <Row
              searchType={searchType}
              countryName={item.countryName}
              isGrouped={item.isGrouped}
              icon={getIconName(item)}
              iconSize={iconSize}>
              {item.name}
            </Row>
          </ListItem>
        )
      })}
    </>
  )
}
