import { createErrorClass } from '@etta/core/errors'

export namespace GetAlternatePolicyErrors {
  export const UnavailableAlternatePolicyTrips = createErrorClass(
    'Alternate policy trips not found',
  )
}

export type GetAlternatePolicyErrorsInstances = InstanceType<
  typeof GetAlternatePolicyErrors[keyof typeof GetAlternatePolicyErrors]
>
