import { useTranslation } from 'react-i18next'
import { Skeleton } from '@etta/ui/skeleton'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import { setLinkTagsForContent } from '@fiji/utils/set-link-tags-for-content'
import { RoomDetailsText } from './room-rates-details-styled'

type Props = {
  onRefetch: () => void
  isLoading?: boolean
  isError?: boolean
  details?: string
}

export function RoomRatesDetails({ isLoading = false, onRefetch, isError, details }: Props) {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <Skeleton width="100%" height="140px">
        <rect width="100%" height="140px" />
      </Skeleton>
    )
  }

  if (isError) {
    return (
      <NoResultSearchScreen
        type="hotel"
        title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.ErrorHotelSubTitle')}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.RetryButton'),
            onClick: onRefetch,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  return (
    <RoomDetailsText
      aria-label={details}
      dangerouslySetInnerHTML={{
        __html: setLinkTagsForContent(details),
      }}
    />
  )
}
