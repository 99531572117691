import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Tabs } from '@etta/ui/tabs'
import { TimeRangeDirection } from '@fiji/enums'
import type { LayoutProps } from '../../types'

const i18Base = 'FlightSearch.TimePicker'

export function TimeRangePickerMobile({
  isVisible,
  onClose,
  tabsSlot,
  activeTab,
  departureSlot,
  handleChangeTab,
  returnSlot,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Modal.Title>{t(i18Base + '.Title')}</Modal.Title>

      <Modal.Body>
        <Tabs activeTab={activeTab} onChangeTab={handleChangeTab}>
          {tabsSlot}
          <Tabs.Panel value={TimeRangeDirection.Departure} withAnimation={false}>
            {departureSlot}
          </Tabs.Panel>
          <Tabs.Panel value={TimeRangeDirection.Arrival} withAnimation={false}>
            {returnSlot}
          </Tabs.Panel>
        </Tabs>
      </Modal.Body>
    </Modal>
  )
}
