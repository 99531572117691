import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;

  &:hover * {
    color: ${(p) => p.theme.colors.primary};
  }
`

export const IconWrapper = styled.div`
  margin: 0 10px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  padding-left: 5px;
`

export const Title = styled.div`
  width: 100%;
`
