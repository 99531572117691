import { v4 as uuid } from 'uuid'
import { Service } from '@etta/di'
import type { HotelRecentSearchWithoutDates } from '@etta/modules/recent-searches/core/value-objects/get-hotel-searches-without-dates.value-object'

@Service()
export class HotelRecentSearchWithoutDatesDataService {
  get(searchParams: HotelRecentSearchWithoutDates['hotelSearchParams']) {
    const location = {
      placeId: uuid(),
      name: searchParams.location.name,
      latitude: searchParams.location.geocode.lat,
      longitude: searchParams.location.geocode.long,
    }

    return {
      location,
    }
  }
}
