import styled from 'styled-components'
import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  padding-left: 36px;
`

export const EmptyResultText = styled(Text).attrs<TextProps, TextProps>((props) => ({
  ...props,
  variant: 'body',
  color: 'bodyText',
  isBlock: true,
}))``
