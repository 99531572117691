import i18n from 'i18next'
import * as R from 'ramda'
import type { RuleSet } from '../rules-and-restriction.types'

const i18nBasePath = 'TripReview.RulesAndRestrictions.'
export function formatAirRules(airRules: RuleSet[], isAirInternational: boolean): RuleSet[] {
  if (airRules.length === 0) {
    return []
  }

  if (airRules.length === 1) {
    return [
      {
        label: airRules[0].label,
        rules: [...airRules[0].rules, ...addStaticLines(isAirInternational)],
      },
    ]
  }

  return structureFareWithLegs(airRules).length === 1
    ? [
        {
          label: i18n.t(i18nBasePath + 'Flights'),
          rules: [...airRules[0].rules, ...addStaticLines(isAirInternational)],
        },
      ]
    : [...structureFareWithLegs(airRules), { rules: addStaticLines(isAirInternational) }]
}

function structureFareWithLegs(airRules: RuleSet[]): RuleSet[] {
  // if there are 2 air legs, get only the unique rules
  if (airRules.length === 2 && R.uniq([airRules[0].rules, airRules[1].rules]).length === 1) {
    return [airRules[0]]
  }

  return airRules.map((ruleSet) =>
    ruleSet.rules.length > 0
      ? ruleSet
      : {
          label: ruleSet.label,
          rules: ['-'],
        },
  )
}

function addStaticLines(isAirInternational: boolean) {
  return [
    i18n.t(i18nBasePath + 'QuotedAirfare'),
    ...(isAirInternational ? [i18n.t(i18nBasePath + 'InsecticideNotification')] : []),
  ]
}
