import { Service, Inject } from '@etta/di'
import { UserStore } from '@etta/modules/user'
import { ImageFormat } from '@etta/core/enums'
// import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import type { ChangeFlightSeatsArgs, LoadTripDetailsArgs } from '../types'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'
import { PostBookingTripPreviewStore } from '../stores/trip-preview/post-booking-trip-preview.store'
import { GazooPostBookingAdapter } from '../../infra/gazoo/gazoo-post-booking.adapter'
import { CartPostBookingAdapter } from '../../infra/cart/cart-post-booking.adapter'

@Service()
export class PostBookingTripService {
  constructor(
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private postBookingTripPreviewStore: PostBookingTripPreviewStore,
    @Inject() private userStore: UserStore,
    // @Inject() private displayConfigurationStore: DisplayConfigurationStore,
    @Inject() private cartPostBookingAdapter: CartPostBookingAdapter,
    @Inject() private gazooPostBookingAdapter: GazooPostBookingAdapter,
  ) {}
  async loadTrip(forceUpdate?: boolean) {
    // Disabled for now
    // if (this.displayConfigurationStore.isMod2FlowEnabled) {
    //   return this.getMod2Trip(this.postBookingTripStore.params.id, forceUpdate)
    // }

    this.postBookingTripStore.setIsError(false)
    this.postBookingTripStore.setIsLoading(true)
    this.postBookingTripStore.dropTrip()

    const { cityImageUrl } = this.postBookingTripPreviewStore.tripPreview
    const { queryParams, params } = this.postBookingTripStore
    const isCityImageRequered = !cityImageUrl
    const cityImageFormat = isCityImageRequered ? ImageFormat.Svg : undefined
    const id = params.id
    const transactionGroupId = queryParams.transactionGroupId
      ? String(queryParams.transactionGroupId)
      : undefined

    const result = await this.gazooPostBookingAdapter.getTrip(
      {
        id,
        cityImageFormat,
        isCityImageRequered,
        companyId: this.userStore.companyId,
        transactionGroupId,
      },
      forceUpdate,
    )

    result.match({
      Ok: (trip) => {
        this.postBookingTripStore.setTrip(trip)
        this.postBookingTripStore.setIsLoading(false)
      },
      Err: () => {
        this.postBookingTripStore.setIsError(true)
        this.postBookingTripStore.setIsLoading(false)
      },
    })
  }

  async getTripDetails(args: LoadTripDetailsArgs, forceUpdate?: boolean) {
    this.postBookingTripStore.setIsError(false)
    this.postBookingTripStore.setIsLoading(true)
    this.postBookingTripStore.dropTrip() // TODO move this into success handler, should clear after data is fetched
    const result = await this.fetchTripDetails(args, forceUpdate)

    result.match({
      Ok: (trip) => {
        this.postBookingTripStore.setTrip(trip)
        this.postBookingTripStore.setIsLoading(false)
      },
      Err: () => {
        this.postBookingTripStore.setIsError(true)
        this.postBookingTripStore.setIsLoading(false)
      },
    })

    return result
  }

  async getMod2Trip(tripId: string, forceUpdate?: boolean) {
    this.postBookingTripStore.setIsError(false)
    this.postBookingTripStore.setIsLoading(true)
    this.postBookingTripStore.dropTrip()
    const result = await this.fetchMod2Trip(tripId, forceUpdate)

    result.match({
      Ok: (trip) => {
        this.postBookingTripStore.setTrip(trip)
        this.postBookingTripStore.setIsLoading(false)
      },
      Err: () => {
        this.postBookingTripStore.setIsError(true)
        this.postBookingTripStore.setIsLoading(false)
      },
    })

    return result
  }

  // Fetch and return trip details without updating values to store
  fetchTripDetails({ id, transactionGroupId }: LoadTripDetailsArgs, forceUpdate?: boolean) {
    // Disabled for now
    // if (this.displayConfigurationStore.isMod2FlowEnabled) {
    //   return this.fetchMod2Trip(id, forceUpdate)
    // }

    return this.gazooPostBookingAdapter.getTrip(
      {
        id,
        transactionGroupId,
        companyId: this.userStore.companyId,
      },
      forceUpdate,
    )
  }

  fetchMod2Trip(tripId: string, forceUpdate?: boolean) {
    return this.cartPostBookingAdapter.getTripDetails(
      {
        tripId,
      },
      forceUpdate,
    )
  }

  changeFlightSeats({ addSeats, removeSeats, transactionId }: ChangeFlightSeatsArgs) {
    return this.gazooPostBookingAdapter.changeFlightSeats({
      addSeats,
      removeSeats,
      transactionId,
    })
  }
}
