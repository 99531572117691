import i18n from 'i18next'
import { SeatFeatures } from '@etta/modules/seat-map/core/enums'
import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { getSeatFeature } from '../../utils'

const i18nBase = 'AirSeatMap'

const seatTypeLocales: Record<string, string> = {
  [SeatFeatures.Paid]: `${i18nBase}.SeatTitleDetails.PaidSeat`,
  [SeatFeatures.Premium]: `${i18nBase}.SeatTitleDetails.PremiumSeat`,
  [SeatFeatures.PaidPremium]: `${i18nBase}.SeatTitleDetails.PremiumSeat`,
}

const seatFeatureLocales: Record<string, string> = {
  [SeatFeatures.OverWing]: `${i18nBase}.SeatTitle.OverWing`,
  [SeatFeatures.NoFeature]: `${i18nBase}.SeatTitle.NoFeature`,
  [SeatFeatures.NearWindow]: `${i18nBase}.SeatTitle.WindowSeat`,
  [SeatFeatures.ExitRowSeat]: `${i18nBase}.SeatTitle.ExitSeat`,
}

const seatTypeTextLocales: Record<string, string> = {
  [SeatFeatures.Paid]: `${i18nBase}.SeatDetails.Paid`,
  [SeatFeatures.PaidPremium]: `${i18nBase}.SeatDetails.PaidPremium`,
}

type Args = {
  seat: SeatMapRowSeatValueObject
  segmentId: string
  cabinClass: string
  isReadonly?: boolean
}

export function getSelectedSeatFeature({ seat, segmentId, cabinClass, isReadonly }: Args) {
  const { isNearWindow, isExitRowSeat, isOverWing, isPaid, isPremium, number } = seat
  const seatType = getSeatFeature({ isPaid, isPremium })
  const seatFeature = getSeatFeature({ isNearWindow, isExitRowSeat, isOverWing })
  const typeText = isReadonly
    ? i18n.t(`${i18nBase}.SeatDetails.Readonly`)
    : i18n.t(seatTypeTextLocales[seatType])

  return {
    number,
    segmentId,
    cabinClass,
    type: i18n.t(seatTypeLocales[seatType]),
    typeText,
    feature: `${i18n.t(seatFeatureLocales[seatFeature])} ${number}`,
  }
}
