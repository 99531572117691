import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { LocationSectionMobile } from './mobile'
import { LocationSectionDesktop } from './desktop'

export function LocationSectionLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<LocationSectionMobile {...props} />}
      desktopSlot={<LocationSectionDesktop {...props} />}
    />
  )
}
