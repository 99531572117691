import type { CabinClassType } from '@fiji/types'

type Props = {
  cabinClasses: CabinClassType[]
  classFromSegment?: string
}

export function getServiceClass({ cabinClasses, classFromSegment }: Props) {
  const classFromOptions = cabinClasses.find((cabinClass) => cabinClass.id === classFromSegment)
  return classFromOptions || cabinClasses[0]
}
