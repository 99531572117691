import type { ReactNode } from 'react'
import { screenSize } from '@fiji/style/screen-sizes'
import { Container, DialogWrapper } from './picker-dialog-styled'

type Props = {
  isVisible: boolean
  onClose: () => void
  children: ReactNode
}

export function PickerDialog({ isVisible, onClose, children }: Props) {
  return (
    <DialogWrapper isOpen={isVisible} onClose={onClose} customSize={[320, screenSize.maxMobile]}>
      <Container>{children}</Container>
    </DialogWrapper>
  )
}
