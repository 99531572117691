/* eslint import/no-restricted-paths: 0 */
import { Inject, Service } from '@etta/di'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { AddSegmentModalsStore } from '@etta/modules/review-trip/interface/stores/add-segment-modals.store'
import { HotelSearchFormStore } from '@etta/modules/hotel-search/interface/stores/hotel-search-form.store'
import { SegmentAddressMapper } from '@etta/modules/review-trip/core'
import type { Place } from '@fiji/types'
import { deleteTimezone } from '@fiji/utils/dates'
import { ROUTES } from '@fiji/routes'

import { HotelSearchFormQueryService } from '@etta/modules/hotel-search/interface/services/hotel-search-form-query.service'
import { AutocompleteService } from '@etta/modules/autocomplete'
import { PlaceAutocompleteType } from '@fiji/graphql/types'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { DEFAULT_HOTEL_FILTERS } from '@etta/modules/hotel-search/interface/stores/constants'
import { SegmentTypeCheckActions } from '../actions/segment-type-check.actions'

@Service()
export class AddHotelSegmentService {
  constructor(
    @Inject()
    private readonly autocompleteService: AutocompleteService,
    @Inject()
    private readonly addSegmentModalsStore: AddSegmentModalsStore,
    @Inject()
    private readonly reviewTripPageStore: ReviewTripStore,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly hotelSearchFormQueryService: HotelSearchFormQueryService,
    @Inject()
    private readonly segmentTypeCheck: SegmentTypeCheckActions,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  public prefillDataIfExist() {
    this.hotelSearchFormStore.updateEditCache({
      itineraryId: this.reviewTripPageStore.itineraryId,
      location: null,
      checkInDate: null,
      checkOutDate: null,
    })

    if (this.reviewTripPageStore.segments?.some(this.segmentTypeCheck.isHotelSegment)) {
      this.addSegmentModalsStore.openHotelSearchModal()
      return
    }

    const flights = this.reviewTripPageStore.segments?.filter(this.segmentTypeCheck.isFlightSegment)
    const isMultiCityTrip = flights.length > 2
    if (isMultiCityTrip) {
      this.addSegmentModalsStore.openHotelSearchModal()
      return
    }

    const flightData = this.findFlightData()
    const carData = this.findCarData()

    if (flightData) {
      this.prefillHotelForm(flightData.checkIn, flightData.checkOut, flightData.location)
      return
    }

    if (carData) {
      this.prefillHotelForm(carData.checkInDate, carData.checkOutDate, carData.location)
      return
    }

    this.addSegmentModalsStore.openHotelSearchModal()
  }

  private async prefillHotelForm(checkInDate?: Date, checkOutDate?: Date, targetLocation?: Place) {
    const location = await this.getCityLocation(targetLocation)

    const hotelData = {
      itineraryId: this.reviewTripPageStore.itineraryId,
      checkInDate,
      checkOutDate,
      location,
    }

    if (checkInDate && checkOutDate && location) {
      this.hotelSearchFormQueryService.navigateTo(ROUTES.hotel.results, {
        ...hotelData,
        hotelFilters: {
          ...DEFAULT_HOTEL_FILTERS,
          distance: this.displayConfigurationStore.displayConfiguration.hotelSearchRadius.default,
          initialDistance: this.displayConfigurationStore.displayConfiguration.hotelSearchRadius
            .default,
        },
      })
      this.hotelSearchFormStore.updateEditCache({
        ...hotelData,
        distance: this.displayConfigurationStore.displayConfiguration.hotelSearchRadius.default,
      })
      return
    }

    this.hotelSearchFormStore.updateEditCache(hotelData)
    this.addSegmentModalsStore.openHotelSearchModal()
  }

  private async getCityLocation(currentLocation?: Place) {
    if (!currentLocation) {
      return
    }

    const foundLocations = await this.autocompleteService.getPlaces(
      { query: currentLocation.name },
      [PlaceAutocompleteType.GooglePlaces],
    )

    if (!foundLocations?.length) {
      return currentLocation
    }

    return foundLocations[0]
  }

  private findFlightData() {
    const flights = this.reviewTripPageStore.segments?.filter(this.segmentTypeCheck.isFlightSegment)
    const isMultiCityTrip = flights?.length > 2

    if (!flights.length || isMultiCityTrip) {
      return null
    }

    // TODO: Discuss logic for detecting round trip
    const isRoundTrip = flights?.length === 2

    if (isRoundTrip) {
      const [startFlight, endFlight] = flights
      return {
        checkIn: new Date(deleteTimezone(startFlight.arrivalDateTime)),
        checkOut: new Date(deleteTimezone(endFlight.departureDateTime)),
        location: SegmentAddressMapper.mapAirSegmentAddressToLocation(startFlight, 'departure'),
        isRoundTrip,
      }
    }

    const [flight] = flights

    return {
      checkIn: new Date(deleteTimezone(flight.arrivalDateTime)),
      checkOut: undefined,
      location: SegmentAddressMapper.mapAirSegmentAddressToLocation(flight, 'departure'),
      isRoundTrip,
    }
  }

  private findCarData() {
    const carSegment = this.reviewTripPageStore.segments?.find(
      this.segmentTypeCheck.isCarRentalSegment,
    )

    if (!carSegment) {
      return null
    }

    const { pickUpTime, dropOffTime } = carSegment

    return {
      checkInDate: new Date(pickUpTime),
      checkOutDate: new Date(dropOffTime),
      location: SegmentAddressMapper.mapCarSegmentAddressToLocation(carSegment, 'pickup'),
    }
  }
}
