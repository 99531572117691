import { observer } from 'mobx-react-lite'
import type { PermissionTranslation } from '../shared'
import { PermissionScreen } from '../shared'
import animation from './animation.json'
import { useRequestNotifications } from './use-request-notifications'

type Props = {
  onSuccess: () => void
  onFail: () => void
  onSkip: () => void
  onBack: (args: { isGranted: boolean }) => void
  getPreviousTranslation: (args: { isGranted: boolean }) => PermissionTranslation
}

export const RequestNotifications = observer(function RequestNotifications({
  onSuccess,
  onFail,
  onSkip,
  onBack,
  getPreviousTranslation,
}: Props) {
  const { handleBack, notifications, isGeolocationGranted, translation } = useRequestNotifications({
    onBack,
  })

  return (
    <PermissionScreen
      animationSrc={animation}
      onBack={handleBack}
      onSkip={onSkip}
      onRequest={notifications.requestPermission}
      onSuccess={onSuccess}
      onFail={onFail}
      isButtonDisabled={notifications.isLoading}
      previousTranslation={
        isGeolocationGranted
          ? getPreviousTranslation({ isGranted: isGeolocationGranted })
          : undefined
      }
      translation={translation}
    />
  )
})
