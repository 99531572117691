import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  align-items: center;
`

export const IconRow = styled.div<{ isRow?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${(p) => (p.isRow ? '2px' : '7px')};
`
