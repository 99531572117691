import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { RailLegType } from '@fiji/enums'
import { PaginationButton } from './rail-pagination-button-styled'

type Props = {
  direction: 'earlier' | 'later'
  railLegType: RailLegType
}

const i18nBase = 'SearchSegments.Trains.PaginateLabel'

export const RailPaginationButton = observer(function RailPaginationButton({
  direction,
  railLegType,
}: Props) {
  const { railSearchActions } = useRailContext()
  const { t } = useTranslation()
  const screenType = useScreenType()

  const isMobile = screenType === ScreenType.Mobile

  const isEarlier = direction === 'earlier'

  const buttonProps = {
    onClick: () => {
      return isEarlier
        ? railSearchActions.onEarlierTrainClick(railLegType)
        : railSearchActions.onLaterTrainClick(railLegType)
    },
    label: isEarlier ? t(i18nBase + '.Earlier') : t(i18nBase + '.Later'),
    iconName: isEarlier ? 'chevronUpPWA' : 'chevronDownPWA',
  }

  return (
    <PaginationButton
      isMobile={isMobile}
      fullWidth={isMobile}
      variant={isMobile ? 'link' : 'text'}
      paddings={isMobile}
      size={isMobile ? 'small' : 'normal'}
      onClick={buttonProps.onClick}>
      {buttonProps.label} <Icon name={buttonProps.iconName as IconNames} size={'small'} />
    </PaginationButton>
  )
})
