import { useTranslation } from 'react-i18next'
import { SegmentLineSeperator } from '@etta/components/segment-line-seperator'
import { OperatedBy } from '../components/operated-by'
import { Details } from '../components/details'
import type { Props } from '../../types'
import { TechnicalStopLabel } from '../components/technical-stop-label'
import {
  Container,
  DepartureAndArrival,
  Departure,
  Arrival,
  AirportCode,
  Time,
  Date,
  ArrivesIn,
} from './collapsed-view-mobile-styled'

export function CollapsedViewMobile({ flight, daysInBetween }: Props) {
  const { t } = useTranslation()
  const { adaptedSegments, flightStops, totalFlightDuration, flightStopsLabel } = flight
  const firstSegment = adaptedSegments[0]
  const lastSegment = adaptedSegments[adaptedSegments.length - 1]

  return (
    <Container
      aria-label={t('AirSearchResults.AirSearchSegmentDetails', {
        originAirport: firstSegment.departureAirportCode,
        originTime: firstSegment.departureTime,
        destinationAirport: lastSegment.departureAirportCode,
        destinationTime: lastSegment.departureTime,
        duration: totalFlightDuration,
        stops: flightStopsLabel,
      })}>
      <DepartureAndArrival>
        <Departure>
          <AirportCode>{firstSegment.departureAirportCode}</AirportCode>
          <Time>{firstSegment.departureTime}</Time>
          <Date>{firstSegment.departureDate}</Date>
        </Departure>
        <SegmentLineSeperator maxStopsToShow={3} stops={flightStops.length} />

        <Arrival>
          <AirportCode>
            {lastSegment.arrivalAirportCode}
            {!!daysInBetween && (
              <ArrivesIn> {t('SearchSegments.Nights', { count: daysInBetween })}</ArrivesIn>
            )}
          </AirportCode>
          <Time>{lastSegment.arrivalTime}</Time>
          <Date>{lastSegment.arrivalDate}</Date>
        </Arrival>
      </DepartureAndArrival>

      <OperatedBy flight={flight} />

      <Details flight={flight} />

      {flight.hasTechnicalStop && <TechnicalStopLabel isFullWidth={true} />}
    </Container>
  )
}
