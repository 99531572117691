export default function NoSeatSelectionIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0803 5.1288C30.4276 4.53216 30.2254 3.76695 29.6288 3.41965C29.0322 3.07236 28.267 3.27449 27.9197 3.87112L8.41965 37.3711C8.07236 37.9678 8.27449 38.733 8.87112 39.0803C9.46776 39.4276 10.233 39.2254 10.5803 38.6288L30.0803 5.1288ZM16.176 18.6273H17.884L13.9083 25.4573L10.2677 14.837C10.2122 14.6756 10.1766 14.5244 10.1503 14.3781C10.0236 13.9885 10.0037 13.6495 10.0005 13.5606L9.99997 7.79926C9.99997 5.49999 12.1652 5.49999 12.1652 5.49999C14.2164 5.49999 14.3244 7.56359 14.3301 7.78083L14.3304 13.2458L14.3371 13.2635L16.176 18.6273ZM17.4556 31.1255C17.075 31.1255 16.7619 31.0543 16.5039 30.9372L20.1281 24.7109L24.5216 24.3029C24.6024 24.2951 24.6778 24.2944 24.7509 24.2971C24.8432 24.2818 24.9139 24.2772 24.9435 24.2759L24.9587 24.2753H32.3813C32.4326 24.2753 32.4754 24.2837 32.5184 24.2921L32.5184 24.2921C32.5431 24.2969 32.5678 24.3017 32.5942 24.305C32.7866 24.3142 32.9183 24.3513 32.9183 24.3513C34.2403 24.7272 33.9949 26.0517 33.9591 26.2158L33.9552 26.2325L33.0052 29.9956C32.7669 30.9411 32.1125 31.1203 31.6705 31.1266L31.5556 31.1244L25.8695 31.1255V35.0646H31.7976C32.7968 35.0646 32.8737 36.0441 32.8796 36.1948L32.8801 36.2142V36.3507C32.8801 37.4116 31.9577 37.4932 31.8158 37.4995L17.0829 37.5C16.0834 37.5 16.0065 36.5207 16.0006 36.3701L16.0001 36.2142C16.0001 35.153 16.9227 35.0714 17.0647 35.0651L23.011 35.0646V31.1255H17.4556ZM26.8672 21.7101L21.8747 21.7103L23.6693 18.6273H26.8488C28.196 18.6273 28.2859 19.9627 28.2918 20.1407L28.2923 20.1602C28.2923 21.6072 27.0348 21.7037 26.8672 21.7101Z"
        fill="currentColor"
      />
    </svg>
  )
}
