import styled from 'styled-components'
import { largeTitle, footnoteMedium, linkSmall } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
  overflow: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`

export const IconWrapper = styled.div`
  margin: auto auto 20px auto;
`

export const Title = styled.div`
  ${largeTitle};
  color: ${(props) => props.theme.colors.mainText};
  text-align: center;
  margin-bottom: 32px;
`

export const Footnote = styled.p`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  text-align: center;
  margin-bottom: 30px;
  white-space: break-spaces;
`

export const RideHailErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background: ${(props) => props.theme.colors.background4};
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
`

export const ErrorTitle = styled.text`
  color: ${(props) => props.theme.colors.mainText1};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.001em;
  text-transform: uppercase;
  margin-bottom: 8px;
`

export const ErrorContentWithoutUberLink = styled.text`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  text-align: left;
  line-height: 19px;
`

export const ErrorContentWithUberLink = styled.text`
  ${footnoteMedium};
  ${ErrorContentWithoutUberLink};
  margin-bottom: 12px;
`

export const ErrorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${(p) => p.theme.colors.borderDark};
  margin-bottom: 14px;
`

export const ErrorButtonContainer = styled.div`
  width: fit-content;
  min-height: 20px;
  background: none;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`

export const ErrorButtonText = styled.text`
  ${linkSmall};
  color: ${(props) => props.theme.colors.primary};
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const UberQrCodeWrapper = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.borderLight};
  border-radius: 14px;
  margin-top: 20px;
`
