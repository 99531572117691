import { ModalDetails } from '@etta/components/modal-details'
import type { TripCostSummary } from '@fiji/graphql/types'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import type { FlightEmission, SafetyCheckFlight, FlightSafetyCheckDetails } from '@fiji/types'
import { FlightDetailsModalContent } from './flight-details-modal-content'

type Props = {
  isOpen: boolean
  onClose: () => void
  flight: FlightSegmentEntity
  tripCost: TripCostSummary
  itineraryId: string
  flightId: string
  isTripExist: boolean
  isChangeAllowed?: boolean
  flightSegments: FlightSegmentEntity[]
  flightIndex: number
  isFlightFareAttributesLoading?: boolean
  isFlightFareAttributesError?: boolean
  flightSafetyChecks?: SafetyCheckFlight[]
  flightSafetyCheckDetails?: FlightSafetyCheckDetails[]
  refetchFlightFareAttributes?: () => void
  isFlightSafetyCheckEnabled?: boolean
  flightEmission: FlightEmission
  isMultiCity?: boolean
  shouldShowAirportChangeBadge?: boolean
  isWebFare?: boolean
  isNDCFare?: boolean
}

export function FlightDetailsModal({ isOpen, onClose, ...props }: Props) {
  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <FlightDetailsModalContent {...props} onClose={onClose} />
    </ModalDetails>
  )
}
