import { useTheme } from '@fiji/hooks/use-theme'

export const ExitHand = () => {
  const theme = useTheme()

  return (
    <svg viewBox="0 0 10 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6C6 2.68629 3.31371 0 0 0V50C3.31371 50 6 47.3137 6 44V6Z"
        fill={theme.colors.seatMap.charcoal7}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 5.34315 1.65685 4 0 4V46C1.65685 46 3 44.6569 3 43V7Z"
        fill={theme.colors.seatMap.white}
      />
    </svg>
  )
}
