import { useTranslation } from 'react-i18next'
import { TimeRangePicker } from '@etta/components/time-range-picker/time-range-picker'
import { TimeRangePicker as TimeRangePickerNew } from '@etta/components/sliders/time-range-picker'
import { useRailTimeConfiguration } from '@fiji/hooks/time-configuration/use-rail/use-rail-time-configuration'
import type { Place, TimeRange } from '@fiji/types'
import { useScreenType, ScreenType } from '@fiji/modes'
import { SegmentType } from '@fiji/graphql/types'
import { TimeRangeDirection } from '@fiji/enums'

type Props = {
  originPlace?: Place | null
  destinationPlace?: Place | null
  onSubmit?: (time: TimeRange) => void
  selectedTime: TimeRange
  isVisible: boolean
  onClose: () => void
  date?: Date | null
}

const i18Base = 'TripPlanner.BaseSearch'

export function RailTimeRangePicker({
  originPlace,
  destinationPlace,
  onSubmit,
  selectedTime,
  isVisible,
  onClose,
  date,
}: Props) {
  const { t } = useTranslation()
  const { initialCustomTime, timeOptions } = useRailTimeConfiguration()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (!isMobile) {
    return (
      <TimeRangePickerNew
        actionLabel={t(`${i18Base}.Input.Leaving`)}
        selectedTime={selectedTime}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
        onSubmit={onSubmit}
        initialCustomTime={initialCustomTime}
        timeRangeDirection={TimeRangeDirection.Departure}
        isVisible={isVisible}
        timeOptions={timeOptions}
        onClose={onClose}
        forSegment={SegmentType.Train}
        date={date}
      />
    )
  }

  return (
    <TimeRangePicker
      originPlace={originPlace}
      destinationPlace={destinationPlace}
      onSubmit={onSubmit}
      selectedTime={selectedTime}
      isVisible={isVisible}
      onClose={onClose}
      date={date}
      forSegment={SegmentType.Train}
      initialCustomTime={initialCustomTime}
      timeOptions={timeOptions}
    />
  )
}
