import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { TextField } from '@etta/ui/text-field'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { DateOfBirthPicker } from './date-of-birth-picker'
import type { DateOfBirthFieldProps } from './types'
import { DateOfBirthWrapper } from './date-of-bith-field-styled'

export function DateOfBirthField({
  onChange = () => {},
  value,
  isOpen,
  onClose,
  onOpenPicker,
  label,
  helperType,
  helperText,
  isDisabled,
  withClickOutsideHandle,
  areFutureYears,
  description,
  isHideValue,
  isBlurred,
  tabIndex = 0,
  yearsAfter,
  yearsBefore,
}: DateOfBirthFieldProps) {
  const { t } = useTranslation()

  const { handleKeyDown } = useA11yKeydown(onOpenPicker)

  const textFieldValue = useMemo(() => {
    if (value) {
      const date = format(value, 'LLL d y')
      if (isHideValue) {
        return date.replace(/[A-Za-z0-9]/g, '*')
      }

      return date
    }

    return ''
  }, [value, isHideValue])

  const datePickerValue = useMemo(() => {
    if (isHideValue) {
      return undefined
    }
    return value
  }, [value, isHideValue])

  const fieldLabel = label || t('UserInfo.DOB')

  return (
    <DateOfBirthWrapper tabIndex={isDisabled ? -1 : tabIndex} onKeyDown={handleKeyDown}>
      <TextField
        label={fieldLabel}
        helperType={helperType}
        helperText={helperText}
        isDisabled={isDisabled}
        value={textFieldValue}
        onClick={onOpenPicker}
        description={description}
        isBlurred={isBlurred}
        tabIndex={-1}
      />
      <DateOfBirthPicker
        onChange={onChange}
        value={datePickerValue}
        isOpen={isOpen}
        onClose={onClose}
        withClickOutsideHandle={withClickOutsideHandle}
        areFutureYears={areFutureYears}
        yearsAfter={yearsAfter}
        yearsBefore={yearsBefore}
      />
    </DateOfBirthWrapper>
  )
}
