export type GooglePlacesAddress = {
  long_name: string
  short_name: string
  types: string[]
}

export function divideAddressArrayToObject(addressComponents?: GooglePlacesAddress[]) {
  const recommendedAddress = {
    city: '',
    countryCode: '',
    stateCode: '',
    postalCode: '',
    street1: '',
  }

  addressComponents?.forEach((address) => {
    if (
      (address.types.includes('locality') && address.types.includes('political')) ||
      address.types.includes('postal_town')
    ) {
      recommendedAddress.city = address.long_name
    }

    if (address.types.includes('country') && address.types.includes('political')) {
      recommendedAddress.countryCode = address.short_name
    }

    if (address.types.includes('postal_code')) {
      recommendedAddress.postalCode = address.short_name
    }

    if (address.types.includes('street_number')) {
      recommendedAddress.street1 = address.long_name
    }

    if (address.types.includes('route')) {
      recommendedAddress.street1 = `${recommendedAddress.street1} ${address.long_name}`
    }

    if (
      address.types.includes('administrative_area_level_1') &&
      address.types.includes('political')
    ) {
      recommendedAddress.stateCode = address.short_name
    }
  })

  return recommendedAddress
}
