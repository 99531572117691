import { observer } from 'mobx-react-lite'
import { Slider } from '@etta/ui/slider'
import { useDistance } from './use-distance/use-distance'

export type Props = {
  distance?: number
  onChange: (value: number) => void
}

export const HotelDistanceSlider = observer(function HotelDistanceSlider({
  distance,
  onChange,
}: Props) {
  const {
    marks,
    distanceUnit,
    minDistance,
    maxDistance,
    handleDistanceChange,
    distanceRange,
    maxValue,
  } = useDistance({
    distance,
    onChange,
  })

  return (
    <Slider
      isFilledValue
      value={distanceRange}
      min={minDistance}
      max={maxDistance}
      marks={marks}
      markTitleType="distance-edge"
      onChange={handleDistanceChange}
      distanceUnit={distanceUnit}
      maxDistance={maxValue}
    />
  )
})
