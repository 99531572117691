import styled from 'styled-components'

export const ContentContainer = styled.div`
  width: 35%;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
  overflow: scroll;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  margin: 32px 32px 0;
`

export const CarRentalLocationsContentContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 0;
  background-color: white;
`

export const CarRentalLocationsMapContainer = styled.div`
  width: 65%;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const ErrorContainer = styled.div`
  margin-top: 250px;
`

export const NoResultsContainer = styled.div`
  margin-top: 195px;
`
