import { forwardRef } from 'react'
import {
  ActionButton,
  FooterContainer,
  ScreenFooterContent,
  Separator,
  Title,
  StyledText,
} from './footer-styled'

type Props = {
  isVisible: boolean
  onButtonClick: () => void
  isButtonDisabled: boolean
  title?: string
  text?: string
  buttonText?: string
}

export const Footer = forwardRef<HTMLDivElement, Props>(
  ({ isVisible, title, text, onButtonClick, isButtonDisabled, buttonText }, ref) => {
    return (
      <ScreenFooterContent ref={ref} isVisible={isVisible}>
        <FooterContainer>
          <Title>{title}</Title>
          <StyledText>{text}</StyledText>
        </FooterContainer>
        <Separator />
        <FooterContainer>
          <ActionButton
            aria-hidden={buttonText ? 'false' : 'true'}
            onClick={onButtonClick}
            disabled={isButtonDisabled}>
            {buttonText}
          </ActionButton>
        </FooterContainer>
      </ScreenFooterContent>
    )
  },
)
