import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { SpecialRequestOptionsMobile } from './mobile'
import { SpecialRequestOptionsDesktop } from './desktop'

export function SpecialRequestOptionsLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<SpecialRequestOptionsMobile {...props} />}
      desktopSlot={<SpecialRequestOptionsDesktop {...props} />}
    />
  )
}
