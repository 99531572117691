import { useTranslation } from 'react-i18next'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import type { ServiceFeeConfigurationValueObject } from '@etta/modules/display-configuration/core/value-objects/service-fee-configuration.value-object'
import { formatPrice } from '@fiji/utils/money'
import { ServiceFeeType } from '@etta/modules/display-configuration/core/enums'

type Args = {
  serviceFeeConfiguration: ServiceFeeConfigurationValueObject[]
}

type ReduceResult = {
  id: string
  name: string
  description: string
}

const i18nBase = 'SearchSegments'

export function useTaxesAndFeesModal({ serviceFeeConfiguration }: Args) {
  const { t } = useTranslation()
  const { featureFlagsStore } = useFeatureFlagsContext()

  const { isServiceFeeEnabled } = featureFlagsStore.flags

  const serviceFees = serviceFeeConfiguration.reduce<ReduceResult[]>((acc, serviceFee) => {
    if (serviceFee.type === ServiceFeeType.TransactionFee) {
      // don't do anything until https://deem.atlassian.net/browse/LUA-611 is fixed
      return acc
    }

    const amount = formatPrice({
      amount: serviceFee.serviceFeesAmount,
      currency: serviceFee.serviceFeesAmountCurrency,
    })

    serviceFee.vendorCodesList.forEach((vendor, index) => {
      acc.push({
        id: `${serviceFee.id}-${vendor}`,
        name: serviceFee.vendorNamesList[index],
        description: t(`${i18nBase}.ServiceFeeDescription`, {
          label: serviceFee.label,
          currency: serviceFee.serviceFeesAmountCurrency,
          amount,
          description: serviceFee.description,
        }),
      })
    })

    return acc
  }, [])

  return {
    serviceFees,
    isServiceFeeEnabled,
  }
}
