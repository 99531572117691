import { DEFAULT_LOCALE } from '@fiji/constants'
import type { GetUserQuery, Employee } from '@fiji/graphql/hooks'
import { UserCreationSource } from '@fiji/graphql/hooks'
import type { SiteEntity } from '@etta/modules/auth/core/entities/site.entity'
import { SsoVariantDataMapper } from '@etta/modules/auth/infra/mappers/sso-variant-data.mapper'
import type { Site } from '@etta/modules/auth/infra/mappers/site-data.mapper'
import type { UserEntity, ProfileEntity } from '../../core/entities'
import type { EmployeeValueObject } from '../../core/value-objects'
import { AccountCreationSource } from '../../core/enums/account-creation-source.enum'
import { getFilteredDelegates } from './get-filtered-delegates'

export class UserMapper {
  static toUserEntity(user: GetUserQuery['user']): UserEntity {
    const profile = this.toUserProfileEntity(user.profile)

    return {
      impersonatedId: user.impersonatedId,
      companyId: user.companyId,
      customerId: user.customerId,
      sessionTimeout: user.sessionTimeout,
      currency: user.profile?.currency,
      delegatedId: user.delegatedId,
      meetingId: user.meetingId,
      locale: profile?.locale || user.locale || DEFAULT_LOCALE,
      partnerId: user.partnerId,
      profile,
      trustedLocale: user.trustedLocale,
    }
  }

  static toUserProfileEntity(profile: GetUserQuery['user']['profile']): ProfileEntity | null {
    if (!profile) {
      return null
    }

    const {
      isGuest,
      genderOptions,
      identityHash,
      suffixOptions,
      businessContact,
      titleOptions,
      title,
      timeZone,
      dateOfBirth,
      firstName,
      gender,
      lastName,
      hourlyTimeFormat,
      measurementUnit,
      middleName,
      notification,
      passports,
      visas,
      nationalIdCards,
      personalContact,
      pwaWelcomeMessage,
      rawSuffix,
      site,
      suffix,
      emergencyContact,
      employee,
      confEmail,
      groupware,
      preferredCreditCards,
      creditCards,
      currency,
      username,
      profilePin,
      approvers,
      mis,
      dateFormat,
      country,
      delegates,
      authorizers,
      additionalDocumentsPreferences,
      locale,
      registrationStatus,
      forceNewPassword,
      creationSource,
    } = profile

    return {
      isGuest,
      genderOptions,
      identityHash,
      suffixOptions,
      titleOptions,
      title,
      businessContact,
      lastName,
      firstName,
      dateOfBirth,
      gender,
      hourlyTimeFormat,
      timeZone,
      suffix,
      site,
      measurementUnit,
      middleName,
      notification,
      passports,
      visas,
      nationalIdCards,
      pwaWelcomeMessage,
      rawSuffix,
      personalContact,
      emergencyContact,
      employee: this.toEmployeeValueObject(employee),
      confEmail,
      groupware,
      preferredCreditCards,
      creditCards,
      currency,
      username,
      profilePin,
      approvers,
      mis,
      dateFormat,
      country,
      delegates: getFilteredDelegates(delegates, personalContact?.email),
      authorizers: getFilteredDelegates(authorizers, personalContact?.email),
      additionalDocumentsPreferences,
      locale,
      registrationStatus,
      forceNewPassword,
      creationSource: this.mapCreationSourceToAccountCreationSourceEnum(creationSource),
    }
  }

  static mapCreationSourceToAccountCreationSourceEnum(
    creationSource?: UserCreationSource | null,
  ): AccountCreationSource | undefined {
    if (!creationSource) {
      return
    }

    switch (creationSource) {
      case UserCreationSource.AdminUser:
        return AccountCreationSource.AdminUser
      case UserCreationSource.Batch:
        return AccountCreationSource.Batch
      case UserCreationSource.EndUser:
        return AccountCreationSource.EndUser
      case UserCreationSource.InboundSsoSync:
        return AccountCreationSource.InboundSsoSync
      case UserCreationSource.OpenEnrollment:
        return AccountCreationSource.OpenEnrollment
      case UserCreationSource.SsoPartner:
        return AccountCreationSource.SsoPartner
      case UserCreationSource.Webservice:
        return AccountCreationSource.Webservice
    }
  }

  static toEmployeeValueObject(employee?: Employee | null): EmployeeValueObject | undefined {
    if (!employee) {
      return
    }

    return {
      ...employee,
      manager: employee.manager?.id ? employee.manager : undefined,
    }
  }

  static toSiteEntity(site: Site): SiteEntity {
    return {
      id: site.siteId,
      name: site.siteName,
      sso: {
        endpointUrl: site.companySsoEndpointUrl || '',
        isMobileEnabled: site.mobileSsoEnabled,
        typeVariant: SsoVariantDataMapper.toSsoTypeVariantEnum(site.ssoType),
      },
      url: site.url,
    }
  }
}
