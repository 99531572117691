import type { IconColorPalette, IconSizes, PWAIconNames } from '@etta/ui/icon'
import type { InputButtonGroupDirection } from '../input-button-group'

export enum InputButtonSize {
  Big = 'Big',
  Normal = 'Normal',
}

export type Props = {
  value?: string | JSX.Element | null
  label?: string
  icon?: PWAIconNames
  size?: InputButtonSize
  isMediumWeight?: boolean
  placeholder?: string
  onClick: () => void
  isFilled?: boolean
  isInteractive?: boolean
  className?: string
  'aria-label'?: string
  'data-tracking-id'?: string
  disabled?: boolean
  hasError?: boolean
  direction?: InputButtonGroupDirection
  isToggle?: boolean
  onToggle?: () => void
  isShowIconOnLabel?: boolean

  iconRight?: PWAIconNames
  iconRightSize?: IconSizes
  iconRightColor?: IconColorPalette
  onRightIconClick?: () => void
}
