import { addMinutes, addSeconds, subMinutes, addDays } from 'date-fns'

export function addMinutesToDate(date: Date, minutes: number): Date {
  return addMinutes(date, minutes)
}

export function addSecondsToDate(date: Date, seconds: number): Date {
  return addSeconds(date, seconds)
}

export function subMinutesFromDate(date: Date, minutes: number): Date {
  return subMinutes(date, minutes)
}

export function addDaysToDate(date: Date, days: number): Date {
  return addDays(date, days)
}
