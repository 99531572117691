export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M99.9999 22.8789C99.9999 33.2101 91.6231 41.5853 81.2935 41.5853C70.962 41.5853 62.5872 33.2101 62.5872 22.8789C62.5872 12.5477 70.962 4.17249 81.2935 4.17249C91.6231 4.17249 99.9999 12.5477 99.9999 22.8789Z"
        fill="#EFEFF4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.1555 23.0437C96.1555 31.1603 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1603 66.7598 23.0437C66.7598 14.9254 73.3397 8.34497 81.4568 8.34497C89.5755 8.34497 96.1555 14.9254 96.1555 23.0437Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.745 13.774C81.1965 12.9596 82.3229 12.9596 82.7743 13.774L90.2367 27.2361C90.6888 28.0518 90.1249 29.0723 89.2221 29.0723H74.2973C73.3944 29.0723 72.8305 28.0518 73.2826 27.2361L80.745 13.774ZM80.8506 18.1632L80.8505 22.7087L81.7597 23.6178L82.6687 22.7087L82.6688 18.1632H80.8506ZM80.6803 25.9296C80.6805 25.3238 81.1636 24.8325 81.7597 24.8325C82.3558 24.8325 82.8391 25.3239 82.839 25.9298C82.8389 26.5356 82.3556 27.0268 81.7594 27.0268C81.1634 27.0268 80.6802 26.5353 80.6803 25.9296Z"
        fill="white"
      />
      <path
        d="M61.3905 28.26V24.051H56.9478L47.1269 33.8719L29.5897 28.9615C27.9529 28.4938 26.0823 28.9615 24.9131 30.1306L23.9778 31.0659L40.5797 40.4191L33.7986 49.0708L30.7588 47.434C29.5897 46.7325 28.1867 46.9663 27.2514 47.9017L25.8484 49.3046L31.928 53.0459L35.6693 59.1255L37.0722 57.7225C38.0076 56.7872 38.2414 55.3842 37.7737 54.2151L36.3708 51.1753L45.0225 44.8619L54.1418 61.6976L55.311 60.5285C56.7139 59.1255 57.1816 57.2549 56.4801 55.618L51.5697 38.5485L61.3905 28.26Z"
        fill="white"
      />
    </svg>
  )
}
