import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useTheme } from '@fiji/hooks/use-theme'

type Props = {
  hasCreditCard: boolean
}
const i18nBase = 'PaymentSummary.'

export function AddCardTextWarning({ hasCreditCard }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (hasCreditCard) {
    return null
  }

  return (
    <Block color={theme.colors.error} isFlexBlock flexGap={4} marginTop={8}>
      <Icon name="errorFilledPWA" />
      <Text variant="footnoteMedium">{t(i18nBase + 'CreditCardIsRequired')}</Text>
    </Block>
  )
}
