import styled from 'styled-components'
import { headline, footnoteMedium, UnstyledButton } from '@fiji/style'

export const Container = styled(UnstyledButton)`
  width: 100%;
  border-radius: 14px;
  background: ${(p) => p.theme.colors.white};

  &:active {
    background: ${(p) => p.theme.colors.background};
  }
`

export const Content = styled.div`
  padding: 18px 14px 14px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const LogoContainer = styled.div`
  height: 22px;
  width: 55px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const Title = styled.div`
  ${headline};
  color: ${(p) => p.theme.colors.mainText};
`

export const Subtitle = styled.div`
  ${footnoteMedium};
  display: flex;
  color: ${(p) => p.theme.colors.bodyText};
  margin-top: 6px;
`

export const Capacity = styled.div`
  display: inline-flex;
  margin: 12px 0 0 -2px;
  align-items: center;
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
`

export const Unit = styled.div`
  margin-right: 12px;
  color: ${(p) => p.theme.colors.bodyText};
  margin-bottom: -2px;
`

export const Image = styled.img``
