import styled from 'styled-components'

export const ContainerWrapper = styled.section`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 0px ${({ theme }) => theme.colors.borderLight},
    inset 0px 1px 0px ${({ theme }) => theme.colors.borderLight};
`

export const EditSlotContainer = styled.div`
  z-index: 11;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 0px ${({ theme }) => theme.colors.borderLight},
    inset 0px 1px 0px ${({ theme }) => theme.colors.borderLight};
`
