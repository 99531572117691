import { CSSTransition } from 'react-transition-group'
import { FooterWrapper, FooterContainer } from '../../seat-footer-styles'
import { TRANSITION_NAME, TRANSITION_DURATION } from '../../blocks'
import type { LayoutProps } from '../../seat-footer.types'

export function SeatFooterMobile({
  containerHeight,
  animateFooter,
  isMobileFooterVisible,
  setAnimateFooter,
  containerRef,
  isAbsolutePosition,
  footerBlockSlot,
}: LayoutProps) {
  return (
    <FooterWrapper
      height={containerHeight}
      animate={animateFooter}
      isAbsolutePosition={isAbsolutePosition}>
      <CSSTransition
        appear={isMobileFooterVisible}
        in={isMobileFooterVisible}
        timeout={TRANSITION_DURATION}
        classNames={TRANSITION_NAME}
        onEntered={() => setAnimateFooter(false)}
        onExit={() => setAnimateFooter(true)}>
        <FooterContainer isAbsolutePosition={isAbsolutePosition} ref={containerRef}>
          {footerBlockSlot}
        </FooterContainer>
      </CSSTransition>
    </FooterWrapper>
  )
}
