import { Inject } from 'typedi'
import { Store } from '@etta/di'
import { FieldSettingsStore } from '@etta/modules/settings/interface/stores/field-settings'
import { FieldSettingsCategory } from '@etta/modules/settings/core/enums/field-settings-category'

@Store()
export class UserActivationStore {
  private _displayStatus: 'error' | 'loading' | 'success' | null = null

  private _isUserAcknowledge: boolean = false
  private _isUserAcknowledgeError: boolean = false

  constructor(@Inject() private readonly fieldSettingsStore: FieldSettingsStore) {}

  setUserAcknowledge(userAcknowledge: boolean) {
    this._isUserAcknowledge = userAcknowledge
  }

  setUserAcknowledgeError(userAcknowledgeError: boolean) {
    this._isUserAcknowledgeError = userAcknowledgeError
  }

  get fieldLabel() {
    return this.fieldSettingsStore.getFieldLabel
  }

  setDisplayStatus(status: 'error' | 'loading' | 'success' | null) {
    this._displayStatus = status
  }

  get displayStatus() {
    return this._displayStatus
  }

  get fieldSettings() {
    return {
      businessContactSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.BusinessContact
      ],
      businessAddressSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.BusinessAddress
      ],
      personalInformationSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.PersonalInformation
      ],
      homeAddressSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.HomeAddress
      ],
      emergencyContactSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.EmergencyContact
      ],
      employeeInfoSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.EmployeeInformation
      ],
      travelPreferencesSettings: this.fieldSettingsStore.fieldsSettings[
        FieldSettingsCategory.TravelPreferences
      ],
    }
  }

  get userAcknowledge() {
    return this._isUserAcknowledge
  }

  get userAcknowledgeError() {
    return this._isUserAcknowledgeError
  }

  dropStore() {
    this._displayStatus = null
    this._isUserAcknowledge = false
    this._isUserAcknowledgeError = false
  }
}
