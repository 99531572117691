import i18n from 'i18next'
import { GazooCarRentalType } from '@fiji/enums'

const i18nBasePathType = 'TripReview.CarRentalSegment.Types.'

const carTypesTranslations = {
  [GazooCarRentalType.Doors2To3]: i18nBasePathType + '23Doors',
  [GazooCarRentalType.Doors2To4]: i18nBasePathType + '24Doors',
  [GazooCarRentalType.Doors4To5]: i18nBasePathType + '45Doors',
  [GazooCarRentalType.Suv]: i18nBasePathType + 'SUV',
  [GazooCarRentalType.Van]: i18nBasePathType + 'PassengerVan',
  [GazooCarRentalType.Crossover]: i18nBasePathType + 'Crossover',
  [GazooCarRentalType.Coupe]: i18nBasePathType + 'Coupe',
  [GazooCarRentalType.Roadster]: i18nBasePathType + 'Roadster',
  [GazooCarRentalType.PickupRegCab]: i18nBasePathType + 'PickupRegularCab',
  [GazooCarRentalType.PickupExtCab]: i18nBasePathType + 'PickupExtCab',
  [GazooCarRentalType.Convertible]: i18nBasePathType + 'Convertible',
  [GazooCarRentalType.Special]: i18nBasePathType + 'Special',
  [GazooCarRentalType.Automatic]: i18nBasePathType + 'Automatic',
}

export function translateGazooCarType(carType?: GazooCarRentalType): string {
  if (!carType) {
    return ''
  }
  const key = carTypesTranslations[carType] || carType
  return i18n.t(key) || carType
}
