import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 30px 0;
  flex: 1;
`

export const HeldTripsButton = styled.button`
  margin: 24px 16px 0 16px;
  padding: 18px 24px 18px 16px;
  background-color: ${(p) => p.theme.colors.duskBlue};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
`
export const HeldTripsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const HeldTripIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: ${(p) => p.theme.colors.white};
`

export const NotificationButtonWrapper = styled.div`
  margin: 8px 0 0 auto;
`
