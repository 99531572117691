import styled from 'styled-components'
import { footnoteStrong } from '@fiji/style'

export const RefCodeSection = styled.div<{ showSeparator: boolean }>`
  border-bottom: ${(props) =>
    props.showSeparator ? `1px solid ${props.theme.colors.borderDark}` : 'none'};
  padding: ${({ showSeparator }) => (showSeparator ? `12px 0` : `12px 0 0`)};
  line-height: 16px;

  ${footnoteStrong};
`
