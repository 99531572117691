import styled, { css } from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div``

export const IconContainer = styled.span`
  margin-right: 8px;
`

export const StyledChevronIcon = styled(Icon)<{ rotate?: boolean }>`
  transition: transform 0.2s ease;

  ${({ rotate }) => css`
    transform: ${rotate ? 'rotate(-180deg)' : ''};
  `}
`

export const SafetyHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
