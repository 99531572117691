import type { Theme } from '@fiji/types'
import type { MobileButtonTypeProps } from './button-props'

export function getColorsByType(
  type: MobileButtonTypeProps,
  theme: Theme,
): { color: string; hoverColor: string } {
  switch (type) {
    case 'primary':
      return { color: theme.colors.primary, hoverColor: theme.colors.primary1 }
    case 'secondary':
      return { color: theme.colors.mainText, hoverColor: theme.colors.mainText1 }
    case 'tertiary':
      return { color: theme.colors.secondary, hoverColor: theme.colors.secondary }
    case 'error':
      return { color: theme.colors.error, hoverColor: theme.colors.error }
    case 'warning':
      return { color: theme.colors.warning, hoverColor: theme.colors.warning }
    case 'success':
      return { color: theme.colors.success, hoverColor: theme.colors.success }
  }
  return { color: '', hoverColor: '' }
}
