import styled from 'styled-components'
import { title1 } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const HeaderTitle = styled.div`
  ${title1};
  font-size: 3.4rem;
  color: ${(props) => props.theme.colors.mainText};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const HeaderTripName = styled.div<{ withBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 576px;
  padding-top: 24px;
  margin: 0 auto 24px;
  border-top: ${({ withBorder, theme }) =>
    withBorder ? `1px solid ${theme.colors.borderDark}` : 'none'};
`

export const HeaderIconBox = styled.div`
  margin-left: auto;
  cursor: pointer;
`

export const HeldTripIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.plum};
  border-radius: 50px;
`
