import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled(Block)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  flex: 1;
`
