import type { GetLocationRefinementOptionsQuery } from '@fiji/graphql/hooks'
import type {
  ZoneValueObject,
  AccessPointValueObject,
  TopLevelOptionsValueObject,
} from '../../core/value-objects'

type GqlLocationRefinementZoneInfo = Extract<
  GetLocationRefinementOptionsQuery['getLocationRefinementOptions'],
  { zoneInformation: {} }
>
type GqlLocationRefinementRootZone = NonNullable<
  GqlLocationRefinementZoneInfo['zoneInformation']['rootZone']
>
type GqlLocationRefinementZone = GqlLocationRefinementRootZone['childZones'][number]
type GqlLocationRefinementAccessPoint = GqlLocationRefinementRootZone['accessPoints'][number]

export class LocationRefinementMapper {
  static toTopLevelValueObject(
    options: GetLocationRefinementOptionsQuery['getLocationRefinementOptions'],
  ): TopLevelOptionsValueObject {
    if (options.__typename !== 'LocationRefinementOptionsResponse') {
      return {}
    }

    const { rootZone, accessPoints } = options.zoneInformation

    return {
      rootZone: rootZone ? LocationRefinementMapper.rootZoneToZone(rootZone) : undefined,
      accessPoints: accessPoints.map(LocationRefinementMapper.toAccessPointValueObject),
    }
  }

  static rootZoneToZone(zone: GqlLocationRefinementRootZone): ZoneValueObject {
    return {
      name: zone.name,
      childZones: zone.childZones.map(LocationRefinementMapper.toZonesValueObject),
      accessPoints: zone.accessPoints.map(LocationRefinementMapper.toAccessPointValueObject),
      polygon: zone.polygon,
      location: {},
    }
  }

  static toZonesValueObject(zone: Partial<GqlLocationRefinementZone>): ZoneValueObject {
    return {
      name: zone.name || '',
      childZones: zone.childZones?.map(LocationRefinementMapper.toZonesValueObject),
      accessPoints: zone.accessPoints?.map(LocationRefinementMapper.toAccessPointValueObject),
      polygon: zone.polygon || '',
      location: zone.location?.geocode
        ? { geocode: { lat: zone.location.geocode.lat, long: zone.location.geocode.long } }
        : {},
    }
  }

  static toAccessPointValueObject(
    accessPoint: GqlLocationRefinementAccessPoint,
  ): AccessPointValueObject {
    if (accessPoint.location.geocode?.lat && accessPoint.location.geocode?.long) {
      return {
        id: accessPoint.id,
        label: accessPoint.label,
        geocode: {
          lat: accessPoint.location.geocode?.lat,
          long: accessPoint.location.geocode?.long,
        },
      }
    }
    return {
      id: accessPoint.id,
      label: accessPoint.label,
    }
  }
}
