import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Container, Image, ImageWrapper, SecondImage, TitleWrapper } from './headline-styled'
import flightMobile from './flight-mobile.png'
import flightDesktop from './flight-desktop.png'

type Props = {
  destination: string
  isMultipleFlights: boolean
  viaLocationText?: string | null
  subTitle?: string
}

const i18nBase = 'PostBooking.FlightDetails'

export function Headline({ destination, isMultipleFlights, viaLocationText, subTitle }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const isMobile = screenType === ScreenType.Mobile

  const ariaLabel = `${t(i18nBase + '.FlightTo')} ${destination}`
  const flightImg = isMobile ? flightMobile : flightDesktop

  return (
    <Container isMobile={isMobile}>
      <TitleWrapper isMobile={isMobile} aria-label={ariaLabel}>
        <Text variant={isMobile ? 'title2' : 'title1'} color="mainText2" isBlock>
          {`${t(i18nBase + '.FlightTo')} ${!isMobile ? destination : ''}`}
        </Text>
        {isMobile ? (
          <Text variant="title2" color="mainText2" isBlock>
            {destination}
          </Text>
        ) : (
          <Block marginTop={8} paddingBottom={24}>
            <Text variant="subHeadMedium" color="mainText2">
              {subTitle}
            </Text>
          </Block>
        )}
        {isMobile && isMultipleFlights && viaLocationText && (
          <Text variant="footnoteMedium" color="mainText2">
            {viaLocationText}
          </Text>
        )}
      </TitleWrapper>
      <ImageWrapper isMultipleFlights={isMultipleFlights}>
        <Image src={flightImg} isMobile={isMobile} />
        {isMultipleFlights && <SecondImage src={flightImg} isMobile={isMobile} />}
      </ImageWrapper>
    </Container>
  )
}
