/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'
import { cleanupWithRedirectToLogin } from '@fiji/providers/auth-provider/cleanup-with-redirect-to-login'
import { isTokenExpiredError } from './is-token-expired-error'

export const errorLink = onError((error) => {
  if (isTokenExpiredError(error)) {
    cleanupWithRedirectToLogin()
  }

  if (process.env.NODE_ENV !== 'production') {
    const { graphQLErrors, networkError } = error

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions!.code === 'UNAUTHENTICATED' && path && path[0] === 'login') {
          console.error(message)
        }
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        return message
      })
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
  }
})
