import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Spinner } from '@etta/ui/spinner'
import { DialogWrapper } from './delegate-exit-dialog.styled'

type Props = {
  onClose: () => void
  onConfirm: () => void
  isOpen: boolean
  isLoading: boolean
}

const i18Base = 'Delegate.DelegateExitDialog'

export function DelegateExitDialog({ onClose, onConfirm, isLoading, isOpen }: Props) {
  const { t } = useTranslation()

  const title = t(i18Base + '.Title')
  const description = t(i18Base + '.Description')

  if (!isOpen) {
    return null
  }

  if (isLoading) {
    return (
      <DialogWrapper isLoader onClose={onClose} isOpen>
        <Spinner type="spinout" />
        <Block marginTop={16}>
          <Text isBlock align="center" color="mainText" variant="title3">
            {t(i18Base + '.LoadingText')}
          </Text>
        </Block>
      </DialogWrapper>
    )
  }

  return (
    <DialogWrapper onClose={onClose} isOpen>
      <Block marginBottom={24}>
        <Text aria-label={title} isBlock align="center" color="mainText" variant="title3">
          {title}
        </Text>
      </Block>
      <Block marginBottom={32}>
        <Text
          aria-label={description}
          isBlock
          align="center"
          color="bodyText"
          variant="footnoteMedium">
          {description}
        </Text>
      </Block>
      <Block>
        <Button aria-label={t(i18Base + '.ExitLabel')} onClick={onConfirm} fullWidth>
          {t(i18Base + '.Exit')}
        </Button>
      </Block>
      <Block marginTop={20}>
        <Button
          aria-label={t(i18Base + '.NotNowLabel')}
          onClick={onClose}
          variant="outline"
          fullWidth>
          {t(i18Base + '.NotNow')}
        </Button>
      </Block>
    </DialogWrapper>
  )
}
