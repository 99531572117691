import type { BookRideHailRequest } from '@fiji/graphql/hooks'
import type { BookRideHailArgs } from '../types'
import { buildRideHailFlightDetails } from './ride-hail-flight-details.builder'

export function buildBookRideHailRequest(args: BookRideHailArgs): BookRideHailRequest {
  return {
    provider: args.provider,
    searchResultID: args.searchResultID,
    pickUpLocation: args.pickUpLocation,
    dropOffLocation: args.dropOffLocation,
    pickUpDate: args.pickUpDate,
    pickUpTime: args.pickUpTime,
    processID: args.processID,
    expenseCodeID: args.expenseCodeID,
    expenseMemo: args.expenseMemo,
    arrivalFlightDetails: args.flight && buildRideHailFlightDetails(args.flight),
  }
}
