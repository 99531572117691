import type { AddFlightSeatAssignmentInput } from '@fiji/graphql/types'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'

function getTravelerIdFromSegment() {
  return '1'
}

export function getFlightSeatAssignment({ newSeats }: { newSeats: SegmentSeatNumber[] }) {
  return newSeats.reduce<AddFlightSeatAssignmentInput[]>((acc, newSeat) => {
    const { segmentId, seatNumber } = newSeat
    if (!seatNumber) {
      return acc
    }
    return [
      ...acc,
      {
        travelerId: getTravelerIdFromSegment() || '',
        seatNumber: seatNumber || '',
        segmentId: segmentId || '',
      },
    ]
  }, [])
}
