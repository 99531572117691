import type { CarRentalInputValueObject } from '../../core/value-objects'
import type { SelectedCarFiltersValueObject } from '../../core/value-objects/selected-car-filters.value-object'
import type { CarRentalFilters } from '../types'

export class CarRentalFilterMapper {
  static toCarRentalFilters(filters: SelectedCarFiltersValueObject): CarRentalFilters {
    return {
      carClass: filters.carClass,
      carFuel: filters.fuelType,
      carTransmission: filters.transmission,
      carVendor: filters.vendors,
    }
  }

  static toSelectedCarFiltersValueObject(
    carRentalFilters: CarRentalFilters,
  ): SelectedCarFiltersValueObject {
    return {
      carClass: carRentalFilters?.carClass || [],
      fuelType: carRentalFilters?.carFuel || [],
      transmission: carRentalFilters?.carTransmission || [],
      vendors: carRentalFilters?.carVendor || [],
    }
  }

  static toCarRentalInputValueObjectFilters(
    carRentalFilters: CarRentalFilters,
  ): Pick<CarRentalInputValueObject, 'classTypes' | 'transmissionTypes' | 'fuelTypes' | 'vendors'> {
    return {
      classTypes: carRentalFilters.carClass,
      fuelTypes: carRentalFilters.carFuel,
      transmissionTypes: carRentalFilters.carTransmission,
      vendors: carRentalFilters.carVendor,
    }
  }
}
