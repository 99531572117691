import { makeAutoObservable } from 'mobx'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import { PhoneType } from '@etta/core/enums'
import type { KnownTravelerNumberValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/known-traveler-number.value-object'
import type { RedressNumberValueObject } from '@etta/modules/booking/core/value-objects/checkout-info/redress-number.value-object'
import type { CheckoutInfoResult } from '../../../core/value-objects/checkout-info.value-object'
import type { PrimaryTravelerPersistValueObject } from '../../../core/value-objects/checkout-info/primary-traveler-persist.value-object'

export class CheckoutInfoTraveler {
  private readonly DEFAULT_US_COUNTRY_CODE = '1'
  private travelerPersist = new ItineraryStorage<PrimaryTravelerPersistValueObject | null>(
    'traveler',
    null,
  )

  travelerFields: CheckoutInfoResult['primaryTraveler']['travelerFields'] = null
  primaryTraveler: CheckoutInfoResult['primaryTraveler']['data'] | null = null

  constructor() {
    makeAutoObservable(this, { isPersistEmpty: false, mapTravelerToPersist: false })
  }

  get travelerData() {
    return this.travelerPersist.values
  }

  get travelerInfo() {
    return this.travelerPersist.values || this.mapTravelerToPersist(null)
  }

  mapTravelerToPersist(
    traveler: CheckoutInfoResult['primaryTraveler']['data'] | null,
  ): PrimaryTravelerPersistValueObject {
    return {
      id: traveler?.id || '',
      firstName: traveler?.firstName || '',
      lastName: traveler?.lastName || '',
      middleName: traveler?.middleInitial || undefined,
      title: traveler?.title || undefined,
      suffix: traveler?.suffix || undefined,
      email: traveler?.email || undefined,
      dateOfBirth: traveler?.dateOfBirth || undefined,
      gender: traveler?.gender || undefined,
      office: traveler?.address || undefined,
      phone: this.getPhoneNumberByType(traveler, PhoneType.Business),
      personalMobile: this.getPhoneNumberByType(traveler, PhoneType.Mobile),
      address: traveler?.homeAddress || undefined,
      knownTravelerNumbers: traveler?.knownTravelerNumbers || [],
      redressNumbers: traveler?.redressNumbers || [],
    }
  }

  isPersistEmpty() {
    return Boolean(!this.travelerPersist.values)
  }

  dropTravelerData() {
    this.travelerFields = null
    this.primaryTraveler = null
  }

  dropTravelerPersist() {
    this.travelerPersist.unset()
  }

  setTravelerData(traveler: CheckoutInfoResult['primaryTraveler']) {
    this.primaryTraveler = traveler.data
    this.travelerFields = traveler.travelerFields
  }

  setTravelerValue(traveler: PrimaryTravelerPersistValueObject) {
    this.travelerPersist.set(traveler)
  }

  setKnownTravelerNumbers(knownTravelerNumbers: KnownTravelerNumberValueObject[]) {
    this.travelerPersist.set({ ...this.travelerInfo, knownTravelerNumbers })
  }

  setRedressNumbers(redressNumbers: RedressNumberValueObject[]) {
    this.travelerPersist.set({ ...this.travelerInfo, redressNumbers })
  }

  private getPhoneNumberByType(
    traveler: CheckoutInfoResult['primaryTraveler']['data'] | null,
    type: PhoneType,
  ) {
    const phone = traveler?.phoneNumber.find((phone) => phone.type === type)
    return phone
      ? {
          countryCode: phone.countryCode || this.DEFAULT_US_COUNTRY_CODE,
          number: phone.number,
          type,
        }
      : undefined
  }
}
