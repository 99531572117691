import { useTranslation } from 'react-i18next'
import type { BaggageInfoSegment } from '@fiji/types'
import { Link } from '@etta/ui/link'
import { ScreenType, screenMatcher } from '@fiji/modes'
import { filterBaggageType } from '@fiji/utils/baggage-resolvers-and-filters'
import { BaggageFee } from '@etta/components/baggage-fee'
import { InfoModal } from '../../info-modal'
import {
  Section,
  SectionLogo,
  AirlineLogo,
  BaggageSeparator,
  BaggageFeeInfo,
} from './baggage-information-modal-styled'

type Props = {
  baggageInfo: BaggageInfoSegment[]
  onClose: () => void
}

export function BaggageInformationModalContent({ baggageInfo, onClose }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'BaggageInformation'
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile

  return (
    <>
      <InfoModal.Header title={t(`${i18nBase}.BaggageTitle`)} onClose={onClose} />
      <InfoModal.Content>
        {baggageInfo.map(({ baggageFees, name, imageUrl }, segmentIndex) => {
          if (!baggageFees) {
            return null
          }

          return (
            <Section key={segmentIndex}>
              {imageUrl && (
                <SectionLogo aria-label={name ?? undefined} isMobile={isMobile}>
                  <AirlineLogo
                    src={imageUrl}
                    alt={name || 'logo'}
                    title={name || 'logo'}
                    isMobile={isMobile}
                  />
                </SectionLogo>
              )}
              {filterBaggageType(baggageFees.items).map((baggage) =>
                baggage?.map(
                  (fee, i) =>
                    fee && (
                      <BaggageFee
                        key={'baggage-fee-' + fee.type + i}
                        {...fee}
                        title={'BaggageSections.' + fee.type}
                        iterator={i}
                      />
                    ),
                ),
              )}
              <BaggageSeparator />
              {baggageFees.carrierUrl && (
                <BaggageFeeInfo>
                  {t(`${i18nBase}.DetailedInfo`)}{' '}
                  <Link size="small" href={baggageFees.carrierUrl} target="_blank">
                    {name}
                  </Link>
                </BaggageFeeInfo>
              )}
              <BaggageFeeInfo aria-label={t(`${i18nBase}.InfoSize`)}>
                {t(`${i18nBase}.InfoSize`)}
              </BaggageFeeInfo>
            </Section>
          )
        })}
      </InfoModal.Content>
    </>
  )
}
