import { DeviceClass, GetItineraryDocument } from '@fiji/graphql/hooks'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { ImageFormat } from '@fiji/graphql/types'

export function getRefetchItineraryOptions(itineraryId: string) {
  return {
    query: GetItineraryDocument,
    variables: {
      itineraryId,
      deviceClass:
        screenMatcher.getScreenType() === ScreenType.Mobile
          ? DeviceClass.Mobile
          : DeviceClass.Desktop,
      imageOptions: {
        format: ImageFormat.Svg,
      },
    },
  }
}
