import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace PostBookingListErrors {
  export const GetTripError = createErrorClass('Get trip list error')
  export const GetTripUnexpectedError = createErrorClass('Get trip list unexpected error')
}

export type PostBookingListErrorsInstances = InstanceType<
  typeof PostBookingListErrors[keyof typeof PostBookingListErrors]
>
