import { Skeleton } from '@etta/ui/skeleton'
import { SkeletonWrapper } from './room-rates-modal-styled'

export function RoomRatesModalSkeleton() {
  return (
    <SkeletonWrapper>
      <Skeleton width="100%" height="140px">
        <rect width="180px" height="16px" />
        <rect width="65px" height="16px" y="24px" />
        <rect width="291px" height="16px" y="58px" />
        <rect width="260px" height="10px" y="82px" />
        <rect width="171px" height="10px" y="102px" />
        <rect width="171px" height="10px" y="130px" />
      </Skeleton>
    </SkeletonWrapper>
  )
}
