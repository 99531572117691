import styled from 'styled-components'
import { headline } from '@fiji/style'
import { TextField } from '@etta/ui/text-field'

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${headline}
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`

export const ExpenseCodeSearchTextField = styled(TextField)`
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 12px;
`

export const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ExpenseCodeList = styled.div`
  display: flex;
  flex-direction: column;
`
