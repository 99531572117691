import type { FieldsMap, ValidationScheme } from './types'

type Defaults = Record<string, any>

export function getInitialFields<T extends ValidationScheme = ValidationScheme>(
  defaults: Defaults,
  scheme: T,
  initialValue?: unknown,
) {
  const initialFields = Object.keys(scheme).reduce((acc, key) => {
    const valueFromDefaults = defaults[key]
    const usedValue = initialValue === undefined ? valueFromDefaults : initialValue
    acc[key] = usedValue === undefined ? '' : usedValue
    return acc
  }, {} as FieldsMap)

  return initialFields as Record<keyof T, any>
}
