import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'

type Props = {
  railCardName: string
}

export function RailLoyaltyCard({ railCardName }: Props) {
  return (
    <Block marginVertical={15} isFlexBlock>
      <Icon name="loyaltyCardPWA" color="bodyText" />
      <Block marginLeft={6}>
        <Text variant="footnoteMedium" color="bodyText">
          {railCardName}
        </Text>
      </Block>
    </Block>
  )
}
