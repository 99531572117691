import { MediaLayout } from '@etta/ui/media-layout'
import { EmptyStateContainerMobile } from './mobile'
import { EmptyStateContainerDesktop } from './desktop'

export function EmptyStateContainerLayout() {
  return (
    <MediaLayout
      mobileSlot={<EmptyStateContainerMobile />}
      desktopSlot={<EmptyStateContainerDesktop />}
    />
  )
}
