import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalDetailsModalProps } from './types'
import { CarRentalDetailsModalMobile } from './mobile'
import { CarRentalDetailsModalDesktop } from './desktop'

export function CarRentalDetailsModalLayout(props: CarRentalDetailsModalProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalDetailsModalMobile {...props} />}
      desktopSlot={<CarRentalDetailsModalDesktop {...props} />}
    />
  )
}
