import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalDetailsHeaderProps } from '../types'
import { CarRentalDetailsHeaderMobile } from './mobile'
import { CarRentalDetailsHeaderDesktop } from './desktop'

export function CarRentalDetailsHeaderLayout(props: CarRentalDetailsHeaderProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalDetailsHeaderMobile {...props} />}
      desktopSlot={<CarRentalDetailsHeaderDesktop {...props} />}
    />
  )
}
