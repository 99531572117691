import styled from 'styled-components'

export const Container = styled.div`
  margin: 16px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;

  &:not(:first-child) {
    margin-top: 0;
  }
`

export const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  display: flex;
  padding: 16px 0;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
  padding-top: 12px;
`

export const ContentRow = styled.div`
  display: flex;
  margin: 0 24px;
  align-items: flex-start;
`

export const ContentRightSection = styled.div`
  margin-left: auto;
  padding-left: 10px;
  flex-shrink: 0;
  text-align: right;
`

export const HeaderLeftSection = styled.div`
  margin-left: 24px;
`

export const PriceSection = styled.div`
  margin-right: 16px;
`

export const HeaderRightSection = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`
