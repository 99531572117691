import { getDateWithoutTimezoneOffset } from '@fiji/utils/dates/get-date-without-timezone-offset'

type Args = {
  isChangeable: boolean
  dobFromProfile?: Date | null
  dobFromPurchase?: string | null
}

export function resolveInitialDOB({ isChangeable, dobFromProfile, dobFromPurchase }: Args) {
  if (isChangeable) {
    return dobFromProfile || null
  }
  return dobFromPurchase ? getDateWithoutTimezoneOffset(dobFromPurchase) : dobFromProfile || null
}
