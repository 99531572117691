import styled, { css } from 'styled-components'

export const AirSearchDesktopWrapper = styled.div<{ isMaxLaptop?: boolean }>`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${({ isMaxLaptop }) =>
    isMaxLaptop &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 952px;
  margin-bottom: 16px;
`

export const Fields = styled.div<{ isOneRow: boolean }>`
  flex-grow: 1;
  max-width: 952px;

  & > div > div {
    ${(p) => (p.isOneRow ? '' : 'margin-bottom: 24px')};
  }
`
export const Buttons = styled.div`
  max-width: 952px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const oneRowStyle = css`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const twoRowStyle = css`
  display: flex;
  flex-direction: column;
`

export const Form = styled.div<{ isOneRow: boolean }>`
  ${(p) => (p.isOneRow ? oneRowStyle : twoRowStyle)}
`
