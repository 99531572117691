import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import type { SpinnerColor } from '../types'

export const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const IconWrapper = styled(Icon).attrs(() => ({
  name: 'spinnerPWA',
}))<{
  isAnimated: boolean
  color: SpinnerColor
}>`
  animation: spin 1s linear infinite;
  color: ${(props) => props.theme.colors[props.color]};
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
