import { useTranslation } from 'react-i18next'
import type { ColorPalette } from '@fiji/types'
import { SegmentType } from '@fiji/graphql/types'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'

type Args = {
  type: SegmentType.Flight | SegmentType.Hotel | SegmentType.CarRental
  isDisabled?: boolean
}

const i18nPath = 'PostBooking.TripDetails.'
const i18nPathAccessability = 'Accessibility.'
const buttonTranslatesMap = {
  [SegmentType.Flight]: 'AddFlights',
  [SegmentType.Hotel]: 'AddHotel',
  [SegmentType.CarRental]: 'AddCarRental',
}

export function useTripDetailsAddButton({ type, isDisabled }: Args) {
  const { t } = useTranslation()
  const label = t(i18nPath + buttonTranslatesMap[type])
  const ariaLabel = isDisabled
    ? t(i18nPathAccessability + 'AddButton', { label })
    : t(i18nPathAccessability + 'AddButtonActive', { label })
  const iconName = getIconNameBySegment(type)
  const iconColor: keyof ColorPalette = isDisabled ? 'bodyText' : 'mainText'

  return {
    label,
    ariaLabel,
    iconName,
    iconColor,
  }
}
