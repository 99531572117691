import { useCallback } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMobilityBookingDone } from '@fiji/hooks/mobility/use-mobility-booking-done'

export function useMobilityLeavingBookingFlowWarningDialog() {
  const { handleClose: handleCloseMobility } = useMobilityBookingDone()
  const { searchRideHailActions } = useRideHailSearchContext()

  const handleCancel = useCallback(() => {
    searchRideHailActions.closeLeavingBookingFlowDialog()
  }, [searchRideHailActions])

  const handleClose = useCallback(() => {
    searchRideHailActions.closeLeavingBookingFlowDialog()
    handleCloseMobility()
  }, [searchRideHailActions, handleCloseMobility])

  return {
    handleCancel,
    handleClose,
  }
}
