import { Action, Inject } from '@etta/di'
import type { Place, Dates, PlainTime as Time } from '@fiji/types'
import type { AddressInputValueObject } from '@etta/core/value-objects/address-input.value-object'
import { CarSearchFormService } from '../services/car-search-form.service'

@Action()
export class CarSearchFormActions {
  constructor(
    @Inject()
    private readonly carSearchFormService: CarSearchFormService,
  ) {}

  onPickUpPlaceChange(place: Place, address?: AddressInputValueObject) {
    this.carSearchFormService.changePickUpPlace(place, address)
  }

  onDropOffPlaceChange(place: Place, address?: AddressInputValueObject) {
    this.carSearchFormService.changeDropOffPlace(place, address)
  }

  onDatesSelect({ date, dateEnd }: Dates) {
    this.carSearchFormService.changeDates({ date, dateEnd })
  }

  onPickUpTimeChange(time: Time) {
    this.carSearchFormService.changePickUpTime(time)
  }

  onDropOffTimeChange(time: Time) {
    this.carSearchFormService.changeDropOffTime(time)
  }

  onSwitchLocations() {
    this.carSearchFormService.switchLocations()
  }
}
