import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { SubTitle } from '../../titles'

export const CarRowSubtitle = styled(SubTitle)`
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium}
`
export const CarRowContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`
