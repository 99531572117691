import { useTranslation } from 'react-i18next'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'
import { truncateText } from '@fiji/utils/get-truncate-text'
import type { CarRentalSafetyCheck as CarRentalSafetyCheckType } from '@fiji/graphql/types'
import { CarRentalSafetyCheckMessage } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { CarRentalSafetyCheckModal } from './car-rental-safety-check-modal'
import { CarRentalSafetyCheckSkeleton } from './car-rental-safety-check-skeleton'
import { CarRentalSafetyCheckEmpty } from './car-rental-safety-check-empty'
import { Badge, Content } from './car-rental-safety-check-styled'

type Props = Partial<CarRentalSafetyCheckType> & {
  vendorName?: string
  isLoading?: boolean
}

const i18n = 'CarRentalDetails.SafetyCheck'

export function CarRentalSafetyCheck({
  url,
  vendorName,
  messageCode,
  badgeUrl,
  isLoading = false,
}: Props) {
  const { t } = useTranslation()
  const message = messageCode
    ? t(`${i18n}.Message.${capitalizeFirstLetter(messageCode)}`).split('\n')
    : []
  const isExtended = messageCode === CarRentalSafetyCheckMessage.Extended
  const modal = useTogglePopup()
  const title = isExtended ? t(`${i18n}.VendorTitle`, { vendorName }) : vendorName

  if (isLoading) {
    return <CarRentalSafetyCheckSkeleton />
  }

  if (!url) {
    return <CarRentalSafetyCheckEmpty />
  }

  return (
    <>
      <div>
        <Block marginBottom={4}>
          <SafetyCheckTitle size="extra-small" />
        </Block>
        <Content>
          <div>
            <Block marginBottom={2}>
              <Text variant="footnoteMedium" color="mainText">
                {isExtended ? t(`${i18n}.VendorTitle`, { vendorName }) : vendorName}
              </Text>
            </Block>
            <Block marginBottom={4}>
              <Text variant="footnoteMedium" color="bodyText">
                {truncateText(message[0], isExtended ? 62 : 89)}
              </Text>
            </Block>
          </div>
          {badgeUrl && <Badge src={badgeUrl} />}
        </Content>
        <Button variant="link" size="small" paddings={false} onClick={modal.handleOpen}>
          {t(`${i18n}.ShowMore`)}
        </Button>
      </div>
      <CarRentalSafetyCheckModal
        title={title || ''}
        description={message}
        url={url}
        badgeUrl={badgeUrl}
        isOpen={modal.isOpen}
        onClose={modal.handleClose}
      />
    </>
  )
}
