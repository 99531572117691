export function appendSearchFields(fields: Record<string, any> = {}): string {
  const result =
    Object.keys(fields)
      .map((fieldKey) => {
        const value = fields[fieldKey]
        if (value === undefined || value === 'undefined') {
          return ''
        }
        return `${fieldKey}=${value}`
      })
      .filter(Boolean)
      .join('&') || ''

  if (result) {
    return `?${result}`
  }

  return ''
}
