import type { ReactNode } from 'react'

type Props = {
  isHoldButtonVisible?: boolean
  isHoldFlow?: boolean
  holdButtonSlot: ReactNode
  buttonSlot: ReactNode
}

export function CostWithCostWithCtaButtonsLayout({
  isHoldButtonVisible,
  isHoldFlow,
  holdButtonSlot,
  buttonSlot,
}: Props) {
  if (isHoldFlow) {
    return <>{holdButtonSlot}</>
  }

  return (
    <>
      {isHoldButtonVisible && holdButtonSlot}
      {buttonSlot}
    </>
  )
}
