import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { size } from '@fiji/style'

export const EditHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
`

export const ContainerWrapper = styled.div`
  padding: 8px 0;
  width: 80%;
  max-width: ${size.minLaptop};
`

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 12px 0;
  gap: 8px;
`

export const ChildrenWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  min-width: 0;
`

export const SearchButton = styled(Button)`
  display: flex;
  margin-left: auto;
  width: 64px;
  & > * {
    display: flex;
    justify-content: center;
  }
`
