import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import type { RangeResult } from '../types'

export function convertCarRentalToRangeRTP(carRental: CarRentalSegmentEntity): RangeResult {
  const { dropOffLocation, pickUpLocation, pickUpTime, dropOffTime } = carRental
  return {
    to: pickUpLocation.address,
    from: dropOffLocation.address,
    startDate: deleteTimezone(pickUpTime),
    endDate: deleteTimezone(dropOffTime),
  }
}
