export enum CarRentalClassType {
  Compact = 'COMPACT',
  CompactElite = 'COMPACT_ELITE',
  Economy = 'ECONOMY',
  EconomyElite = 'ECONOMY_ELITE',
  Fullsize = 'FULLSIZE',
  FullsizeElite = 'FULLSIZE_ELITE',
  Intermediate = 'INTERMEDIATE',
  IntermediateElite = 'INTERMEDIATE_ELITE',
  Luxury = 'LUXURY',
  LuxuryElite = 'LUXURY_ELITE',
  Mini = 'MINI',
  MiniElite = 'MINI_ELITE',
  Oversize = 'OVERSIZE',
  Premium = 'PREMIUM',
  PremiumElite = 'PREMIUM_ELITE',
  Special = 'SPECIAL',
  Standard = 'STANDARD',
  StandardElite = 'STANDARD_ELITE',
}
