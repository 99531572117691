import { PlacesSearchType } from '@fiji/graphql/types'
import type { SearchType } from '@fiji/utils/search-mechanism/types'

const placeByType: Record<SearchType, PlacesSearchType> = {
  air: PlacesSearchType.AirSearch,
  'car-rental': PlacesSearchType.CarRentalSearch,
  hotel: PlacesSearchType.HotelSearch,
  train: PlacesSearchType.TrainSearch,
  mobility: PlacesSearchType.MobilitySearch,
}

export function getPlaceSearchType(searchType?: SearchType): PlacesSearchType | undefined {
  if (!searchType) {
    return
  }
  return placeByType[searchType]
}
