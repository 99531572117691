import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { AutoCancellationMessage } from '@etta/components/auto-cancellation-message'
import { Skeleton } from '@etta/ui/skeleton'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { SubTitle } from '../../../titles'
import type { LayoutProps } from '../../types'
import {
  HeaderIconBox,
  HeaderTitle,
  HeaderTripName,
  Container,
  HeldTripIcon,
} from './header-block-mobile-styled'

const i18nBase = 'PurchaseReservationSuccess.'

export function HeaderBlockMobile({
  tripName,
  title,
  subtitleText,
  onShareTripsDetail,
  isTripLoading,
  pnrNumber,
  isTripOnHold,
  autoCancellationTime,
}: LayoutProps) {
  const { t } = useTranslation()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const isAutoCancellationTimeShown = isTripOnHold && autoCancellationTime

  const recordLocator = brandConfigurationStore.isAppleTheme
    ? t(`${i18nBase}AppleTravelReference`)
    : t(`${i18nBase}RecordLocator`)

  const iconSlot = useMemo(() => {
    if (isTripOnHold) {
      return <HeldTripIcon name="heldTripPWA" color="white" size={56} />
    }
    return <Icon name="checkFilledSmoothPWA" color="success" size={56} />
  }, [isTripOnHold])
  return (
    <Fragment>
      <Container withBorder={!isAutoCancellationTimeShown} aria-label={`${title} ${subtitleText}`}>
        <HeaderIconBox onClick={onShareTripsDetail}>
          <Icon name="sharePWA" size="medium" />
        </HeaderIconBox>
        {iconSlot}
        <HeaderTitle
          role="heading"
          aria-level={3}
          data-tracking-id="purchase-reservation-success-title-text">
          {title}
        </HeaderTitle>
        <Block marginTop={20} marginBottom={isTripLoading || pnrNumber ? 12 : 24}>
          <SubTitle data-tracking-id="purchase-reservation-success-sub-title-text">
            {subtitleText}
          </SubTitle>
        </Block>
        {isTripLoading && (
          <Block marginBottom={8}>
            <Skeleton width="188px" height="19px">
              <rect width="188px" height="19px" />
            </Skeleton>
          </Block>
        )}
        {!isTripLoading && pnrNumber && (
          <Block marginBottom={8}>
            <Text variant="footnoteStrong">
              {recordLocator}: {pnrNumber}
            </Text>
          </Block>
        )}
      </Container>
      {isAutoCancellationTimeShown && (
        <AutoCancellationMessage
          isHolded
          customBackground={'background'}
          time={autoCancellationTime}
        />
      )}
      {tripName && (
        <HeaderTripName>
          <Text variant="headline" color="mainText">
            {tripName}
          </Text>
        </HeaderTripName>
      )}
    </Fragment>
  )
}
