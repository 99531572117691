import { Inject, Service } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { CartAdapter, ItineraryAdapter } from '@etta/modules/review-trip/infra'
import type { ReviewTripValueObject } from '@etta/modules/review-trip/core'
import { ReviewTripStore } from '../stores/review-trip-page.store'
import { OutOfPolicyStore } from '../out-of-policy/stores/out-of-policy.store'
import { ActiveTripService } from './active-trip.service'

const ITINERARY_EXPIRED_TIMEOUT_IN_MINUTE = 30
@Service()
export class GetTripService {
  constructor(
    @Inject()
    private readonly itineraryAdapter: ItineraryAdapter,
    @Inject()
    private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly reviewTripStore: ReviewTripStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly outOfPolicyStore: OutOfPolicyStore,

    @Inject()
    private readonly activeTripService: ActiveTripService,
  ) {}

  async loadTrip() {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      await this.loadCart()
      return
    }
    await this.loadItinerary()
  }

  async updateTrip() {
    if (this.displayConfigurationStore.isMod2FlowEnabled) {
      this.updateCart()
      return
    }
    await this.updateItinerary()
  }

  private async updateItinerary() {
    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.itineraryAdapter.getItinerary({ itineraryId, forceUpdate: true })

    result.match({
      Err: () => {
        this.reviewTripStore.setIsError(true)
      },
      Ok: (data) => {
        this.setTripWithExpiredAt(this.adjustItineraryTimeOut(data))
        // update active itinerary info
        this.activeTripService.setActiveTripData(this.adjustItineraryTimeOut(data))
      },
    })
  }

  private async updateCart() {
    const result = await this.cartAdapter.getCartCombinedInformation({
      cartId: this.reviewTripStore.tripId,
      forceUpdate: true,
    })

    result.match({
      Err: () => {
        this.reviewTripStore.setIsError(true)
      },
      Ok: ({ trip, oopConfiguration }) => {
        this.reviewTripStore.setTrip(trip)
        // update active itinerary info
        // this.activeTripService.setActiveTripData(trip) // uncomment when cart is enabled (isMod2FlowEnabled)
        this.outOfPolicyStore.setOOPConfiguration(oopConfiguration.options)
        this.outOfPolicyStore.setOOPSelection(oopConfiguration.selections)
      },
    })
  }

  private async loadCart() {
    this.reviewTripStore.setIsTripLoading(true)
    this.outOfPolicyStore.setIsOOPConfigurationLoading(true)
    this.reviewTripStore.setIsError(false)
    this.reviewTripStore.setTrip(null)

    const result = await this.cartAdapter.getCartCombinedInformation({
      cartId: this.reviewTripStore.tripId,
    })

    result.match({
      Err: () => {
        this.reviewTripStore.setIsError(true)
        this.reviewTripStore.setIsTripLoading(false)
        this.outOfPolicyStore.setIsOOPConfigurationLoading(false)
      },
      Ok: ({ trip, oopConfiguration }) => {
        this.reviewTripStore.setTrip(trip)
        // update active itinerary info
        // this.activeTripService.setActiveTripData(trip) // uncomment when cart is enabled (isMod2FlowEnabled)
        this.outOfPolicyStore.setOOPConfiguration(oopConfiguration.options)
        this.outOfPolicyStore.setOOPSelection(oopConfiguration.selections)
        this.reviewTripStore.setIsTripLoading(false)
        this.outOfPolicyStore.setIsOOPConfigurationLoading(false)
      },
    })
  }

  private async loadItinerary() {
    this.reviewTripStore.setIsTripLoading(true)
    this.reviewTripStore.setIsError(false)
    this.reviewTripStore.setTrip(null)
    const itineraryId = this.reviewTripStore.itineraryId
    const result = await this.itineraryAdapter.getItinerary({ itineraryId })

    result.match({
      Err: () => {
        this.reviewTripStore.setIsError(true)
        this.reviewTripStore.setIsTripLoading(false)
      },
      Ok: (data) => {
        this.setTripWithExpiredAt(this.adjustItineraryTimeOut(data))
        // update active itinerary info
        this.activeTripService.setActiveTripData(this.adjustItineraryTimeOut(data))
        this.reviewTripStore.setIsTripLoading(false)
      },
    })
  }

  private adjustItineraryTimeOut(trip: ReviewTripValueObject) {
    trip.expiredAt = new Date(
      new Date().setMinutes(new Date().getMinutes() + ITINERARY_EXPIRED_TIMEOUT_IN_MINUTE),
    ).toISOString()
    return trip
  }

  private setTripWithExpiredAt(trip: ReviewTripValueObject) {
    const activeExpiredAt = this.activeTripService.getActiveExpiredAt(trip.itineraryId)
    trip.expiredAt = activeExpiredAt ? activeExpiredAt : trip.expiredAt
    this.reviewTripStore.setTrip(trip)
  }
}
