import { useTranslation } from 'react-i18next'
import type { ViewState } from '@etta/ui/view-state-modal'

export const useDeleteFlightTitle = (removeFlightStateStatus: ViewState): string => {
  const { t } = useTranslation()

  const i18Base = 'FlightDetailsModal'

  if (removeFlightStateStatus === 'saving') {
    return t(i18Base + '.RemovingFlight')
  }

  return t(i18Base + '.RemovedFlight')
}
