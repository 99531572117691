import { Redirect } from 'react-router-dom'
import type { RouteItem } from '@fiji/routes'
import { ROUTES, getDocumentTitle } from '@fiji/routes'
import { MobilityLearnMore } from '@etta/screens/mobility-learn-more'
import { MobilitySearchPage } from '@etta/screens/mobility-search-page'
import { MobilityConfirmationPage } from '@etta/screens/mobility-confirmation-page'
import { UberSupportInformationPage } from '@etta/screens/uber-support-information-page'
import { SelectExpenseCode } from '@etta/modules/ride-hail/ui/select-expense-code'
import { AddExpenseCodeMemo } from '@etta/modules/ride-hail/ui/add-expense-code-memo'
import { EnterCustomExpenseCode } from '@etta/modules/ride-hail/ui/enter-custom-expense-code'

export const mobilityRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.mobility.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Mobility Search'),
    transitionIndex: 9,
    isNavBarHidden: true,
    transitionGroup: 'slide-modal',
    component: () => <MobilitySearchPage />,
  },
  {
    route: {
      path: ROUTES.mobility.result,
      exact: true,
    },
    documentTitle: 'Mobility Search Result',
    transitionIndex: 9.1,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <MobilitySearchPage />,
  },
  {
    // This route is only used by Etta iOS
    route: {
      path: ROUTES.mobility.learnMore,
    },
    transitionIndex: 5.2,
    isNavBarHidden: true,
    isDeemWrapperUsed: false,
    transitionGroup: 'slide-modal',
    documentTitle: 'Mobility Information',
    component: () => <MobilityLearnMore />,
  },
  {
    route: {
      path: ROUTES.mobility.uberRideSupport,
    },
    transitionIndex: 11,
    isNavBarHidden: true,
    isDeemWrapperUsed: false,
    transitionGroup: 'slide-modal',
    documentTitle: 'Uber Support Information',
    component: () => <UberSupportInformationPage />,
  },
  {
    route: {
      path: ROUTES.mobility.selectExpenseCode,
      exact: true,
    },
    documentTitle: getDocumentTitle('Select Expense Code'),
    isNavBarHidden: true,
    isSkipAuth: false,
    transitionIndex: 12.1,
    transitionGroup: 'slide',
    component: () => <SelectExpenseCode />,
  },
  {
    route: {
      path: ROUTES.mobility.enterCustomExpenseCode,
      exact: true,
    },
    documentTitle: getDocumentTitle('Enter Custom Expense Code'),
    isNavBarHidden: true,
    isSkipAuth: false,
    transitionIndex: 12.2,
    transitionGroup: 'slide',
    component: () => <EnterCustomExpenseCode />,
  },
  {
    route: {
      path: ROUTES.mobility.addExpenseCodeMemo,
      exact: true,
    },
    documentTitle: getDocumentTitle('Add a memo'),
    isNavBarHidden: true,
    isSkipAuth: false,
    transitionIndex: 12.3,
    transitionGroup: 'slide',
    component: () => <AddExpenseCodeMemo />,
  },
  {
    route: {
      path: ROUTES.mobility.resultPostExpense,
      exact: true,
    },
    documentTitle: 'Mobility Search Result',
    transitionIndex: 12.4,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <MobilitySearchPage />,
  },
  {
    route: {
      path: ROUTES.mobility.confirm,
    },
    transitionIndex: 13,
    isNavBarHidden: true,
    isDeemWrapperUsed: false,
    documentTitle: 'Mobility Confirmation',
    component: () => <MobilityConfirmationPage />,
  },
  // These routes are in between transition process from /etta-mobility/ to /mobility/
  {
    route: {
      path: ROUTES.ettaMobility.uberRideSupport,
    },
    transitionIndex: 11,
    isNavBarHidden: true,
    isDeemWrapperUsed: false,
    transitionGroup: 'slide-modal',
    documentTitle: 'Uber Support Information',
    component: () => (
      <Redirect
        to={{
          pathname: ROUTES.mobility.uberRideSupport,
          search: window.location.search,
        }}
      />
    ),
  },
  {
    route: {
      path: ROUTES.ettaMobility.learnMore,
    },
    transitionIndex: 5.2,
    isNavBarHidden: true,
    isDeemWrapperUsed: false,
    transitionGroup: 'slide-modal',
    documentTitle: 'Mobility Information',
    component: () => (
      <Redirect
        to={{
          pathname: ROUTES.mobility.learnMore,
          search: window.location.search,
        }}
      />
    ),
  },
  {
    route: {
      path: ROUTES.ettaMobility.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Mobility Search'),
    transitionIndex: 9,
    isNavBarHidden: true,
    transitionGroup: 'slide-modal',
    component: () => (
      <Redirect
        to={{
          pathname: ROUTES.mobility.search,
          search: window.location.search,
        }}
      />
    ),
  },
]
