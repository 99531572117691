import { useTranslation } from 'react-i18next'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { default as NoHotelIcon } from './assets/no-hotel-preview-icon.svg'
import {
  Image,
  LoadingImage,
  LoadingContent,
  ErrorCentered,
  ErrorContent,
  ShowAllPhotos,
  DarkLayer,
} from './hotel-details-photos-styled'
import { useImageLoad } from './use-image-load'
import type { ImageContentSkin } from './types'

type Props = {
  skin?: ImageContentSkin
  src?: string
  photosNumber?: number
  onClick?: () => void
  alt: string
}

export function ImageContent({ skin, src, photosNumber, onClick, alt }: Props) {
  const { t } = useTranslation()
  const { isImageLoaded, isImageError, updateImageLoaded, updateImageError } = useImageLoad()
  const { handleKeyDown } = useA11yKeydown(onClick)
  return (
    <>
      <Image
        tabIndex={0}
        src={src}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        onError={updateImageError}
        onLoad={updateImageLoaded}
        isLoaded={isImageLoaded}
        alt={alt}
      />
      {!isImageLoaded && !isImageError && (
        <LoadingContent skin={skin}>
          <LoadingImage />
        </LoadingContent>
      )}
      {isImageError && (
        <LoadingContent skin={skin}>
          <ErrorContent>
            <ErrorCentered>
              <NoHotelIcon color="mainText2" />
            </ErrorCentered>
          </ErrorContent>
        </LoadingContent>
      )}
      {isImageLoaded && !isImageError && skin === 'gallery-link' && (
        <>
          <ShowAllPhotos
            aria-label={t('HotelDetails.Photos.ShowAllPhotosAriaLabel', { count: photosNumber })}
            variant="buttonNormal"
            color="mainText2"
            onClick={onClick}>
            {t('HotelDetails.Photos.ShowAllPhotos')} ({photosNumber})
          </ShowAllPhotos>
          <DarkLayer />
        </>
      )}
    </>
  )
}
