import { DataProvider } from '@etta/di'
import { ZooKeeperFlagsDocument } from '@fiji/graphql/hooks'
import type { ZooKeeperFlagsQuery, ZooKeeperFlagsQueryVariables } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class ZookeeperConfigurationDataProvider extends GraphqlDataProvider {
  getZookeeperConfiguration() {
    return this.client.query<ZooKeeperFlagsQuery, ZooKeeperFlagsQueryVariables>({
      query: ZooKeeperFlagsDocument,
      fetchPolicy: 'no-cache',
    })
  }
}
