import type {
  FlightPostBookingValueObject,
  SegmentPostBookingValueObject,
} from '@etta/modules/post-booking/core/value-objects'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core/value-objects'
import { SegmentType } from '@fiji/graphql/types'
import { validatedCosts } from './validated-costs'
import { extractId, extractPostBookingId } from './extract-id'
import { extractTypeAndCost } from './extract-type-and-cost'
import type { ExtractedCosts } from './types'

type Props = {
  bookedSegments: SegmentPostBookingValueObject[]
  currentSegments?: TripSegmentValueObject[]
}

export function useDeletedSegmentsCost({ bookedSegments, currentSegments }: Props) {
  if (!bookedSegments || !currentSegments) {
    return {
      hotel: null,
      carRental: null,
      flight: null,
    }
  }

  const currentSegmentsIds = currentSegments.map((segment) => extractId(segment)).filter(Boolean)

  const deletedSegments = bookedSegments
    .map((segment) => {
      const id = extractPostBookingId(segment)
      if (!id || currentSegmentsIds.includes(id)) {
        return null
      }

      return extractTypeAndCost(segment)
    })
    .filter(Boolean) as ExtractedCosts[]

  const deletedHotels = deletedSegments.filter((el) => el.type === SegmentType.Hotel)
  const deletedCarRentals = deletedSegments.filter((el) => el.type === SegmentType.CarRental)
  const deletedFlights = deletedSegments.filter((el) => el.type === SegmentType.Flight)
  const deletedRail = deletedSegments.filter((el) => el.type === SegmentType.Train)

  const isDeletedBothRoundTrip = deletedFlights.length === 2
  const isBookedFlightRoundTrip = bookedSegments
    .filter((el): el is FlightPostBookingValueObject => el.type === SegmentType.Flight)
    .every((s) => Boolean(s.isRoundTripCost))

  // Since a round trip flight booking results in total price returned on both departure and arrival segment.
  // Removal of the excessive deleted segment is needed to prevent
  // the double in cost when calculating price of the deleted round trip flights.
  if (isBookedFlightRoundTrip && isDeletedBothRoundTrip) {
    deletedFlights.pop()
  }

  return {
    hotel: validatedCosts(deletedHotels),
    carRental: validatedCosts(deletedCarRentals),
    flight: validatedCosts(deletedFlights),
    rail: validatedCosts(deletedRail),
  }
}
