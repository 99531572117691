import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { SegmentTypeCheckActions } from '@etta/modules/post-booking/interface/actions/post-booking-segment-type-check.actions'
import { convertCarRentalToRangePostBooking } from './convert-car-rental-to-rage'
import { convertFlightToRangePostBooking } from './convert-flight-to-range'
import { convertTrainToRangePostBooking } from './convert-train-to-range'
import {
  convertHotelForCarPostBooking,
  convertHotelForFlightPostBooking,
  convertHotelForTrainPostBooking,
} from './convert-hotel-to-range'

import type { RangeResult } from './types'

function getSegmentsPostBooking(segments: SegmentPostBookingValueObject[]) {
  const {
    isCarRentalSegment,
    isFlightSegment,
    isHotelSegment,
    isTrainSegment,
  } = SegmentTypeCheckActions.getInstance()
  const carRentalSegments = segments.filter(isCarRentalSegment)
  const flightSegments = segments.filter(isFlightSegment)
  const hotelSegments = segments.filter(isHotelSegment)
  const trainSegments = segments.filter(isTrainSegment)

  return {
    carRentalSegments,
    flightSegments,
    hotelSegments,
    trainSegments,
  }
}

export function getFlightRangePostBooking(
  segments: SegmentPostBookingValueObject[],
): RangeResult | undefined {
  const { hotelSegments, carRentalSegments } = getSegmentsPostBooking(segments)

  if (hotelSegments.length) {
    return convertHotelForFlightPostBooking(hotelSegments[0])
  }

  if (carRentalSegments.length) {
    return convertCarRentalToRangePostBooking(carRentalSegments[0])
  }
}

export function getCarRangePostBooking(
  segments: SegmentPostBookingValueObject[],
): RangeResult | undefined {
  const {
    hotelSegments,
    carRentalSegments,
    flightSegments,
    trainSegments,
  } = getSegmentsPostBooking(segments)

  if (carRentalSegments.length || flightSegments.length > 2) {
    return
  }

  if (flightSegments.length) {
    return convertFlightToRangePostBooking(flightSegments)
  }

  if (hotelSegments.length) {
    return convertHotelForCarPostBooking(hotelSegments[0])
  }

  if (trainSegments.length) {
    return convertTrainToRangePostBooking(trainSegments)
  }
}

export function getHotelRangePostBooking(
  segments: SegmentPostBookingValueObject[],
): RangeResult | undefined {
  const {
    hotelSegments,
    carRentalSegments,
    flightSegments,
    trainSegments,
  } = getSegmentsPostBooking(segments)

  if (hotelSegments.length || flightSegments.length > 2) {
    return
  }
  if (flightSegments.length) {
    return convertFlightToRangePostBooking(flightSegments)
  }
  if (carRentalSegments.length) {
    return convertCarRentalToRangePostBooking(carRentalSegments[0])
  }
  if (trainSegments.length) {
    return convertTrainToRangePostBooking(trainSegments)
  }
}

export function getTrainRangePostBooking(segments: SegmentPostBookingValueObject[]) {
  const { hotelSegments, carRentalSegments } = getSegmentsPostBooking(segments)
  if (hotelSegments.length) {
    return convertHotelForTrainPostBooking(hotelSegments[0])
  }
  if (carRentalSegments.length) {
    return convertCarRentalToRangePostBooking(carRentalSegments[0])
  }
}
