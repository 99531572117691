import { Inject, Service } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import type { GeocodeValueObject } from '@etta/core/value-objects'
import type {
  RideHailPlaceValueObject,
  LocationRefinementOptions,
} from '@etta/modules/ride-hail/core'
import { SelectExpenseCodeStore } from '@etta/modules/ride-hail/interface/store/select-expense-code.store'
import { AddExpenseCodeMemoStore } from '@etta/modules/ride-hail/interface/store/add-expense-code-memo.store'
import { EnterCustomExpenseCodeStore } from '@etta/modules/ride-hail/interface/store/enter-custom-expense-code.store'
import { RideHailSearchLocationRefinementStore } from '../store/ride-hail-search-location-refinement.store'
import { SearchRideHailStore } from '../store/search-ride-hail.store'
import { RideHailSearchAdapter } from '../../infra/ride-hail-search.adapter'
import {
  RideHailLocationRefinementType,
  RideHailPlaceProvider,
  RideHailProvider,
  RefinementOptionsEnum,
} from '../../core/enum'
import { ExpenseCodePage, RenderState } from '../types'
import { mapToRideHailLocation } from './map-to-ride-hail-location'

@Service()
export class RideHailSearchLocationRefinementService {
  constructor(
    @Inject() private rideHailSearchLocationRefinementStore: RideHailSearchLocationRefinementStore,
    @Inject() private rideHailSearchAdapter: RideHailSearchAdapter,
    @Inject() private searchRideHailStore: SearchRideHailStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
    @Inject() private expenseFlowStore: ExpenseFlowStore,
    @Inject() private selectExpenseCodeStore: SelectExpenseCodeStore,
    @Inject() private enterCustomExpenseCodeStore: EnterCustomExpenseCodeStore,
    @Inject() private addExpenseCodeMemoStore: AddExpenseCodeMemoStore,
  ) {}

  async loadLocationRefinementOptions() {
    this.rideHailSearchLocationRefinementStore.setIsError(false)
    this.rideHailSearchLocationRefinementStore.setIsLoading(true)

    const { locationPickUp, locationDropOff } = this.getLocationRefinementParams()

    const resultRefinementPickUp = await this.rideHailSearchAdapter.getLocationRefinementOptions({
      location: locationPickUp,
      locationRefinementType: RideHailLocationRefinementType.PickUp,
      provider: RideHailProvider.Uber,
      searchResultID: '',
      // MAAS-1251: Intentionally left blank as this currently cause an error on Uber side.
    })

    const resultRefinementDropOff = await this.rideHailSearchAdapter.getLocationRefinementOptions({
      location: locationDropOff,
      locationRefinementType: RideHailLocationRefinementType.DropOff,
      provider: RideHailProvider.Uber,
      searchResultID: '',
      // MAAS-1251: Intentionally left blank as this currently cause an error on Uber side.
    })

    resultRefinementPickUp.match({
      Ok: (optionPickUp) => {
        resultRefinementDropOff.match({
          Ok: (optionDropOff) => {
            this.setLocationRefinementOption(optionPickUp, optionDropOff)
            this.rideHailSearchLocationRefinementStore.setIsLoading(false)
          },
          Err: () => {
            this.rideHailSearchLocationRefinementStore.setIsError(true)
            this.rideHailSearchLocationRefinementStore.setIsLoading(false)
          },
        })
      },
      Err: () => {
        this.rideHailSearchLocationRefinementStore.setIsError(true)
        this.rideHailSearchLocationRefinementStore.setIsLoading(false)
      },
    })
  }

  async navigateToExpenseCodeSelection(defaultNavigation: () => void) {
    this.selectExpenseCodeStore.dropStore()
    this.enterCustomExpenseCodeStore.dropStore()
    this.addExpenseCodeMemoStore.dropStore()

    if (!this.featureFlagsStore.flags.isMobilityExpenseEnabled) {
      defaultNavigation()
      return
    }

    const {
      pickUpAddress,
      pickUpDate,
      pickUpTime,
      pickUpPlace,
      dropOffPlace,
      dropOffAddress,
    } = this.searchRideHailStore.searchRideHailForm
    const { processId } = this.searchRideHailStore.searchRideHailTripInfo

    if (!pickUpPlace || !this.searchRideHailStore.selectedSearchRideHail) {
      this.expenseFlowStore.setIsFailureOnFetchingRideHailConfig(true)
      return
    }

    let dropOffGeocodeInput: GeocodeValueObject | undefined
    if (dropOffPlace?.latitude && dropOffPlace?.longitude) {
      dropOffGeocodeInput = {
        lat: dropOffPlace.latitude,
        long: dropOffPlace.longitude,
      }
    }

    let pickUpPlaceInput: RideHailPlaceValueObject | undefined
    let dropOffPlaceInput: RideHailPlaceValueObject | undefined
    if (this.rideHailSearchLocationRefinementStore.selectedAccessPoint) {
      const placeInput = {
        placeId: this.rideHailSearchLocationRefinementStore.selectedAccessPoint.id,
        provider: RideHailPlaceProvider.UberPlaces, // MAAS-999: This should not be hardcoded
      }

      if (
        this.rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.PickUpZoning
      ) {
        pickUpPlaceInput = placeInput
      }
      if (
        this.rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.DropOffZoning
      ) {
        dropOffPlaceInput = placeInput
      }
    }

    let pickUpGeocodeInput: GeocodeValueObject | undefined
    if (pickUpPlace?.latitude && pickUpPlace?.longitude) {
      pickUpGeocodeInput = {
        lat: pickUpPlace.latitude,
        long: pickUpPlace.longitude,
      }
    }

    this.searchRideHailStore.setIsFetchingRideHailConfig(true)

    const result = await this.rideHailSearchAdapter.rideHailExpenseConfig({
      dropOff: {
        geocode: dropOffGeocodeInput,
        address: dropOffAddress,
        place: dropOffPlaceInput,
      },
      pickUp: {
        geocode: pickUpGeocodeInput,
        address: pickUpAddress,
        place: pickUpPlaceInput,
      },
      pickUpDate: pickUpDate
        ? {
            year: pickUpDate.getFullYear(),
            month: pickUpDate.getMonth(),
            day: pickUpDate.getDate(),
          }
        : undefined,
      pickUpTime: pickUpTime
        ? {
            hours: pickUpTime.hours,
            minutes: pickUpTime.minutes,
            seconds: pickUpTime.seconds || 0,
          }
        : undefined,
      processID: processId,
      provider: this.searchRideHailStore.selectedSearchRideHail.provider,
      searchResultID: this.searchRideHailStore.selectedSearchRideHail.id,
    })

    this.searchRideHailStore.setIsFetchingRideHailConfig(false)

    if (result.isErr()) {
      this.expenseFlowStore.setIsFailureOnFetchingRideHailConfig(true)
      return
    }

    this.expenseFlowStore.setIsFailureOnFetchingRideHailConfig(false)

    const expenseConfig = result.getValue()

    if (!expenseConfig.expenseEnabled) {
      defaultNavigation()
      return
    }

    this.expenseFlowStore.setPreviousRenderState(this.searchRideHailStore.renderState)

    this.expenseFlowStore.setExpenseConfig(expenseConfig)
    this.selectExpenseCodeStore.setExpenseCodes(expenseConfig.expenseCodes)

    this.searchRideHailStore.setRenderState(RenderState.expenseCode)
    this.expenseFlowStore.setCurrentPage(ExpenseCodePage.SelectExpenseCode)
  }

  goBack(defaultBack: () => void) {
    this.expenseFlowStore.setIsFailureOnFetchingRideHailConfig(false)
    this.clearLocationRefinementOptionSelection()

    if (!this.expenseFlowStore.expenseConfig.expenseEnabled) {
      defaultBack()
      return
    }

    this.searchRideHailStore.setRenderState(RenderState.expenseCode)
    if (this.expenseFlowStore.expenseConfig.memoEnabled) {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.AddExpenseCode)
    } else if (this.enterCustomExpenseCodeStore.customExpenseCodeValue) {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.EnterCustomExpenseCode)
    } else {
      this.expenseFlowStore.setCurrentPage(ExpenseCodePage.SelectExpenseCode)
    }
  }

  goBackFromLocationRefinement() {
    if (this.rideHailSearchLocationRefinementStore.renderType === 'AccessPoint') {
      this.rideHailSearchLocationRefinementStore.setSelectedAccessPoint(null)

      if (this.rideHailSearchLocationRefinementStore.selectedZone) {
        this.rideHailSearchLocationRefinementStore.setRenderType('ChildZone')
        return
      }
      this.searchRideHailStore.dropResults()
      this.rideHailSearchLocationRefinementStore.dropStore()
      this.searchRideHailStore.setRenderState(RenderState.searchResult)
    }

    if (this.rideHailSearchLocationRefinementStore.renderType === 'ChildZone') {
      this.searchRideHailStore.dropResults()
      this.rideHailSearchLocationRefinementStore.dropStore()
      this.searchRideHailStore.setRenderState(RenderState.searchResult)
    }
  }

  cancelLocationRefinementRequest() {
    this.rideHailSearchAdapter.cancelLocationRefinementRequest()
  }

  cancelRequests() {
    this.rideHailSearchAdapter.cancelRequests()
    this.searchRideHailStore.setIsFetchingRideHailConfig(false)
  }

  private getLocationRefinementParams() {
    const {
      pickUpPlace,
      pickUpAddress,
      dropOffPlace,
      dropOffAddress,
      reserveItemId,
    } = this.searchRideHailStore.searchRideHailForm

    return {
      locationPickUp: mapToRideHailLocation(pickUpPlace, pickUpAddress),
      locationDropOff: mapToRideHailLocation(dropOffPlace, dropOffAddress),
      searchResultID: reserveItemId ?? '',
    }
  }

  private clearLocationRefinementOptionSelection() {
    if (this.rideHailSearchLocationRefinementStore.selectedAccessPoint) {
      this.rideHailSearchLocationRefinementStore.setRenderType('AccessPoint')
    }
    if (this.rideHailSearchLocationRefinementStore.selectedZone) {
      this.rideHailSearchLocationRefinementStore.setRenderType('ChildZone')
    }
    this.rideHailSearchLocationRefinementStore.setSelectedAccessPoint(null)
    this.rideHailSearchLocationRefinementStore.setSelectedZone(null)
  }

  private setLocationRefinementOption(
    optionsPickUp: LocationRefinementOptions,
    optionsDropOff: LocationRefinementOptions,
  ) {
    if (optionsPickUp.rootZone) {
      this.rideHailSearchLocationRefinementStore.setRefinementOptionsAction(
        RefinementOptionsEnum.PickUpZoning,
      )
      this.rideHailSearchLocationRefinementStore.refinementOptions = optionsPickUp
      if (optionsPickUp.rootZone.accessPoints && optionsPickUp.rootZone.accessPoints.length > 0) {
        this.rideHailSearchLocationRefinementStore.setRenderType('AccessPoint')
        return
      }
      if (optionsPickUp.rootZone.childZones && optionsPickUp.rootZone.childZones.length > 0) {
        this.rideHailSearchLocationRefinementStore.setRenderType('ChildZone')
        return
      }
    }
    if (
      optionsDropOff.rootZone &&
      (!this.searchRideHailStore.isAirportDropOff || this.searchRideHailStore.isOnDemandRide)
    ) {
      this.rideHailSearchLocationRefinementStore.setRefinementOptionsAction(
        RefinementOptionsEnum.DropOffZoning,
      )
      this.rideHailSearchLocationRefinementStore.refinementOptions = optionsDropOff
      if (optionsDropOff.rootZone.accessPoints && optionsDropOff.rootZone.accessPoints.length > 0) {
        this.rideHailSearchLocationRefinementStore.setRenderType('AccessPoint')
        return
      }
      if (optionsDropOff.rootZone.childZones && optionsDropOff.rootZone.childZones.length > 0) {
        this.rideHailSearchLocationRefinementStore.setRenderType('ChildZone')
        return
      }
    }
    if (optionsPickUp.accessPoints && optionsPickUp.accessPoints.length > 0) {
      this.rideHailSearchLocationRefinementStore.setRefinementOptionsAction(
        RefinementOptionsEnum.PickUpZoning,
      )
      this.rideHailSearchLocationRefinementStore.refinementOptions = optionsPickUp
      this.rideHailSearchLocationRefinementStore.setRenderType('AccessPoint')
      return
    }
    if (optionsDropOff.accessPoints && optionsDropOff.accessPoints.length > 0) {
      this.rideHailSearchLocationRefinementStore.setRefinementOptionsAction(
        RefinementOptionsEnum.NoRefinement,
      )
      this.rideHailSearchLocationRefinementStore.refinementOptions = {}
      this.rideHailSearchLocationRefinementStore.selectedAccessPoint =
        optionsDropOff.accessPoints[0]
      this.rideHailSearchLocationRefinementStore.renderType = null
      return
    }
    this.rideHailSearchLocationRefinementStore.setRefinementOptionsAction(
      RefinementOptionsEnum.NoRefinement,
    )
    this.rideHailSearchLocationRefinementStore.refinementOptions = {}
  }
}
