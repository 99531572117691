import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { MainStationCircle, FirstColumn, GridRow } from '../rail-route-details-styled'

type Props = {
  stationTime: string
  stationName?: string | null
  isRtp: boolean
}

const i18nBase = 'RailDetails'
export function MainStationRow({ stationTime, stationName, isRtp }: Props) {
  const { t } = useTranslation()

  const departsAtLabel = t(`${i18nBase}.AriaLabels.DepartsAtLabel`, {
    time: stationTime,
  })

  const stationLabel = t(`${i18nBase}.AriaLabels.StationLabel`, {
    name: stationName,
  })

  return (
    <GridRow>
      <FirstColumn>
        <MainStationCircle isRtp={isRtp} />
      </FirstColumn>
      <Text aria-label={stationLabel} variant="subHeadMedium" color="mainText">
        {stationName}
      </Text>
      <Text aria-label={departsAtLabel} variant="subHeadStrong" color="mainText" align="right">
        {stationTime}
      </Text>
    </GridRow>
  )
}
