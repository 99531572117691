import styled from 'styled-components'
import QRCode from 'react-qr-code'
import { Text } from '@etta/ui/text'
import CallToActionBackgroundImage from './assets/mobility-entry-point.svg?url'

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 14px;
  overflow: hidden;
  padding: 20px 16px 24px 20px;
  background-image: url(${CallToActionBackgroundImage});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
`

export const DescriptionContainer = styled.div`
  display: flex;
`

export const Title = styled(Text)`
  padding-left: 16px;
`

export const QRContainer = styled.div`
  min-width: 120px;
  width: 120px;
  height: 120px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1.5px solid #000000;
`

export const QRCodeRender = styled(QRCode)`
  height: auto;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 4px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  background-color: ${(p) => p.theme.colors.white};
`
