import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { useScreenType, ScreenType } from '@fiji/modes'
import { SkeletonWrapper } from './filter-search-info-styled'
import { FilterSearchInfoWrapper } from './filter-search-info-wrapper'

const LONG_LINE_HEIGHT = 11
const MOBILE_LONG_LINE_HEIGHT = 9

export const FilterSearchInfoSkeleton = () => {
  const screenType = useScreenType()

  const isMobile = screenType === ScreenType.Mobile

  return (
    <FilterSearchInfoWrapper hasBottomMargin={false}>
      <SkeletonWrapper>
        <Skeleton width="24px" height="24px">
          <rect width="24px" height="24px" />
        </Skeleton>

        <Block isFullWidth>
          <Skeleton width="85%" height={isMobile ? MOBILE_LONG_LINE_HEIGHT : LONG_LINE_HEIGHT}>
            <rect width="100%" height={isMobile ? MOBILE_LONG_LINE_HEIGHT : LONG_LINE_HEIGHT} />
          </Skeleton>
          {isMobile && (
            <Block isFullWidth marginTop={6}>
              <Skeleton width="85%" height={MOBILE_LONG_LINE_HEIGHT}>
                <rect width="100%" height={MOBILE_LONG_LINE_HEIGHT} />
              </Skeleton>
            </Block>
          )}
        </Block>
      </SkeletonWrapper>
    </FilterSearchInfoWrapper>
  )
}
