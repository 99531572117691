import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { PurchaseHeader } from '@etta/components/purchase-header/purchase-header'
import { DelegateSwitch } from '@etta/modules/delegate'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { LazyContent } from '@etta/ui/lazy-content'
import { Swap } from '@etta/ui/swap'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { PriceSummaryCard } from '@etta/screens/checkout-page/layout/desktop/price-summary-card'
import { useUserContext } from '@etta/modules/user'
import { TravelFusionLinksContent } from '../../travel-fusion-links-content/travel-fusion-links-content'
import type { LayoutProps } from '../../types'
import { ErrorState } from '../../error-state'
import {
  Container,
  Header,
  Content,
  LeftSide,
  RightSide,
} from './travel-fusion-links-desktop-styled'

export const TravelFusionLinksDesktop = observer(function TravelFusionLinksDesktop({
  termsAndConditions,
  isError,
  isLoading,
  onBack,
  handleGetTermsFormatType,
}: LayoutProps) {
  const { t } = useTranslation()
  const { userStore } = useUserContext()
  const i18nBase = 'TravelFusionLinks'
  const {
    featureFlags: { isDesktopDelegatedFlowEnabled, isDelegatedFlowEnabled },
  } = useFeatureFlags()
  const { delegatedId } = userStore
  const isDelegateSwitchEnabled =
    isDelegatedFlowEnabled && isDesktopDelegatedFlowEnabled && !!delegatedId

  return (
    <ScreenDesktop>
      <ScreenDesktop.Header>
        <PurchaseHeader onBack={onBack} />
        {isDelegateSwitchEnabled && <DelegateSwitch />}
      </ScreenDesktop.Header>
      <ScreenDesktop.Content maxWidth={960} backgroundColor="background">
        <Container>
          <LeftSide>
            <Content>
              <Header>
                <Text variant="title3" color="mainText">
                  {t(`${i18nBase}.SubTitle`)}
                </Text>
              </Header>
              <Swap is={isError} isSlot={<ErrorState onSubmit={onBack} />}>
                <LazyContent isLoading={isLoading}>
                  <Block paddingVertical={16} paddingHorizontal={24}>
                    <TravelFusionLinksContent
                      termsAndConditions={termsAndConditions}
                      hasSubTitle={false}
                      handleGetTermsFormatType={handleGetTermsFormatType}
                    />
                  </Block>
                </LazyContent>
              </Swap>
            </Content>
          </LeftSide>
          <RightSide>
            <PriceSummaryCard isDueTodayCostDisplayed />
          </RightSide>
        </Container>
      </ScreenDesktop.Content>
    </ScreenDesktop>
  )
})
