import { Modal } from '@etta/ui/modal'
import type { TripCostSummaryProps } from './types'
import { useTripCostSummary } from './use-trip-cost-summary'
import { TripCostSummaryLayout } from './layout'

export function TripCostSummary({
  segments,
  tripCost,
  isOpen,
  onClose,
  onCheckout,
  isCheckoutDisabled,
  isHoldFlow,
  showTaxBreakDown,
}: TripCostSummaryProps) {
  const tripCostSummaryData = useTripCostSummary({
    tripCost,
    segments,
  })

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <TripCostSummaryLayout
        onClose={onClose}
        onCheckout={onCheckout}
        isCheckoutDisabled={isCheckoutDisabled}
        tripCost={tripCost}
        isHoldFlow={isHoldFlow}
        showTaxBreakdown={showTaxBreakDown}
        {...tripCostSummaryData}
      />
    </Modal>
  )
}
