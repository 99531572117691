import { useCallback } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'

type Args = {
  onSearch: () => void
}

export function useHeaderFull({ onSearch }: Args) {
  const editModeToggle = useTogglePopup()
  const handlerSearch = useCallback(() => {
    editModeToggle.handleClose()
    onSearch()
  }, [editModeToggle, onSearch])
  return {
    editModeToggle,
    handlerSearch,
  }
}
