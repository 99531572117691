import { Action, Inject } from '@etta/di'
// eslint-disable-next-line import/no-restricted-paths
import type { Room } from '@fiji/graphql/types'
import type { TripStateStatus } from '@etta/components/trip-status-modal'
import { HotelSearchResultsStore } from '../stores/hotel-search-results.store'

@Action()
export class HotelResultsAction {
  constructor(
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
  ) {}

  handleSetSelectedRoom(room: Room | null) {
    this.hotelSearchResultsStore.setSelectedRoom(room)
  }

  handleClearSelectedRoom() {
    this.hotelSearchResultsStore.setSelectedRoom(null)
  }

  pickHotel(hotelId: string) {
    const hotel = this.hotelSearchResultsStore.hotels.find((hotel) => hotel.id === hotelId)
    if (!hotel) {
      return
    }

    this.hotelSearchResultsStore.setPickedHotel(hotel)
  }

  dropHotelStore() {
    this.hotelSearchResultsStore.dropHotelStore()
  }

  handleSetViewState(value: TripStateStatus) {
    this.hotelSearchResultsStore.setViewState(value)
  }

  handleResetViewState() {
    this.hotelSearchResultsStore.setViewState(null)
  }

  handleSetSelectedHotelId(value: string) {
    this.hotelSearchResultsStore.setSelectHotelId(value)
  }

  handleResetSelectedHotelId() {
    this.hotelSearchResultsStore.setSelectHotelId(null)
  }
}
