import type { CustomUuidArgs } from './types'
import { CustomFieldPrefix } from './types'

export const generateCustomUuid = ({
  itemIndex,
  name,
  parentIndex,
  prefix = CustomFieldPrefix.None,
}: CustomUuidArgs): string => {
  return `${prefix}-${name}-${parentIndex}-${itemIndex}`
}
