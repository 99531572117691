import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SpinnerImageWrapper = styled.div<{
  size: 'large' | 'medium' | 'small' | 'normal'
  isNarrowButton?: boolean
  isPrimaryButton?: boolean
  isRelativeLink?: boolean
  isSecondaryButton?: boolean
  isDefaultInline?: boolean
  isPrimaryModalCostButton?: boolean
  isSecondaryModalCostButton?: boolean
  rtpAddButtonPosition?: number
}>`
  ${(p) => {
    switch (p.size) {
      case 'large':
        return `
          width: 100px;
          height: 100px;
        `
      case 'medium':
        return `
          width: 40px;
          height: 40px;
        `
      case 'normal':
        return `
          width: 24px;
          height: 24px;
        `
      case 'small':
        return `
          width: 20px;
          height: 20px;
        `
    }
  }}

  ${(p) =>
    p.isPrimaryButton &&
    css`
      left: 35px;
      position: absolute;
    `}
  ${(p) =>
    p.isNarrowButton &&
    css`
      left: 2px;
      width: 17px;
      height: 17px;
      padding-top: 1px;
      position: absolute;
    `}
  ${(p) =>
    p.isRelativeLink &&
    css`
      width: 18px;
      margin-bottom: -2px;
      margin-left: 4px;
    `}
  ${(p) =>
    p.isSecondaryButton &&
    css`
      font-size: 1.4rem;
      text-align: center;
      left: 22px;
      position: absolute;
    `}

  ${(p) =>
    p.isDefaultInline &&
    css`
      left: -16px;
      width: 15px;
      position: absolute;
    `}
  ${(p) =>
    p.isPrimaryModalCostButton &&
    css`
      width: 16px;
      height: 16px;
      position: absolute;
      left: 16px;
    `}

  ${(p) =>
    p.isSecondaryModalCostButton &&
    css`
      width: 16px;
      height: 16px;
      position: absolute;
      left: 13px;
    `}

  ${(p) =>
    p.rtpAddButtonPosition &&
    css`
      width: 16px;
      height: 16px;
      position: absolute;
      left: calc(50% + ${p.rtpAddButtonPosition}px);
    `}

  img, svg {
    width: 100%;
    height: auto;
  }
`
