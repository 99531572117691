import { useTranslation } from 'react-i18next'
import { TextField } from '@etta/ui/text-field'

type Props = {
  value: string
  onChange: (value: string) => void
}

export function SearchCreditCard({ value, onChange }: Props) {
  const { t } = useTranslation()
  const placeholder = t('PurchasePaymentInformationForm.CardFilterPlaceholder')

  return (
    <TextField
      type="search"
      size="small"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      aria-label={`${t('Accessibility.SearchBar')}. ${t('Accessibility.TapTo')} ${placeholder}`}
    />
  )
}
