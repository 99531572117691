import { Service, Inject } from '@etta/di'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import { CarRentalQueryService } from '@etta/modules/car-rental-search/interface/services/car-rental-query.service'
import { updateCarRentalId } from '@fiji/store/segment-ids'
import { PostBookingAction } from '@fiji/enums'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { ROUTES } from '@fiji/routes'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'
import type { CarRentalPostBookingValueObject } from '../../core/value-objects'
import { PostBookingAddSegmentAction } from '../actions/post-booking-add-segment.action'
import { CarRentalModifyService } from './post-booking-trip-modify.helpers'

@Service()
export class PostBookingTripModifyService {
  constructor(
    @Inject()
    private tripStore: PostBookingTripStore,
    @Inject()
    private carRentalQueryService: CarRentalQueryService,
    @Inject()
    private proxyService: FijiStoreProxyService,
    @Inject()
    private displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private carRentalModifyService: CarRentalModifyService,
    @Inject()
    private addSegmentActions: PostBookingAddSegmentAction,
  ) {}

  handleModifyCarRental(carRental: CarRentalPostBookingValueObject) {
    const { bookingRef, processId } = this.tripStore.trip

    this.proxyService.dispatch(updateCarRentalId({ carRentalId: carRental.carId }))
    this.proxyService.dispatch(updatePostPurchaseEntrance('fromTripDetails'))

    // IF is MOD 2 flow we need to recreate the cart in modify flow.
    const itineraryId = this.displayConfigurationStore.isMod2FlowEnabled ? undefined : bookingRef

    const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
    const queryParams = {
      itineraryId,
      bookingId: processId,
      postBookingAction: PostBookingAction.Modify,
      pickUpLocationId: '',
      pickUpTime: this.carRentalModifyService.resolveTime(carRental.pickUpTime),
      pickUpDate: this.carRentalModifyService.resolveDate(carRental.pickUpTime),
      pickUpPlace: this.carRentalModifyService.resolveLocation(carRental.pickUpLocation?.address),
      dropOffLocationId: '',
      dropOffTime: this.carRentalModifyService.resolveTime(carRental.dropOffTime),
      dropOffDate: this.carRentalModifyService.resolveDate(carRental.dropOffTime),
      dropOffPlace: this.carRentalModifyService.resolveLocation(carRental.dropOffLocation?.address),
    }

    if (isMobile) {
      this.carRentalQueryService.navigateTo(ROUTES.carRental.search, queryParams)
    } else {
      this.carRentalQueryService.appendQueryParams(queryParams)
    }

    this.addSegmentActions.handleAddCar()
  }
}
