import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  flex-direction: row;
`

export const ItemContent = styled.div`
  overflow: hidden;
  flex-grow: 1;
  margin-left: 8px;
`

export const ItemTitle = styled.div`
  margin-bottom: 6px;
`

export const RideHailSegmentContainer = styled.div`
  padding: 16px;
  background: ${(p) => p.theme.colors.background};
`
