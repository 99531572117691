import { useEffect, useMemo, useState } from 'react'
import type { AdaptiveFlight } from '@etta/components/flight-details/types'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useFetchSeatRows } from '@fiji/hooks/use-fetch-seat-rows'
import type { FlightLegSubSegment, SeatmapCabinClass } from '@fiji/graphql/types'
import type { SegmentSeatNumber } from '@etta/modules/seat-map'
import { rollbar } from '@fiji/rollbar'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type { AddSeatStateStatus } from '@etta/screens/review-trip-page/flight-details-modal/types'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useFetchSeatMapSegments } from './use-fetch-seat-map-segments'
import { getSeatsInput } from './get-seats-input'

type Args = {
  itineraryId: string
  bookingId?: string
  segments?: FlightLegSubSegment[]
  adaptiveFlight: AdaptiveFlight
  onClose: () => void
  onSeatsChanged?: () => void
}

// TODO: separate this hook and depended component to separateted one. Because in use by post booking and RTP pages
export function useFareSeatMaps({
  itineraryId,
  bookingId,
  adaptiveFlight,
  segments,
  onClose,
  onSeatsChanged,
}: Args) {
  const { postBookingTripActions } = usePostBookingContext()
  const { tripActions, tripFlightActions } = useReviewTripContext()
  const fetchSeatMapSegments = useFetchSeatMapSegments({ adaptiveFlight, segments })

  const { isLoading, fareSegmentSeatRows, retryFetch: retryFetchSeatMap } = useFetchSeatRows({
    fetchSeatMapSegments,
    itineraryId,
  })
  const [selectedSegmentId, setSelectedSegmentId] = useState('')

  useEffect(() => {
    setSelectedSegmentId(fareSegmentSeatRows[0]?.segmentId)
  }, [fareSegmentSeatRows])

  const onSelectedSegmentIdChange = (segmentId: string) => {
    setSelectedSegmentId(segmentId)
  }

  const initialSegmentSeatNumberList = useMemo(() => {
    return (
      segments?.map(({ segmentId, travelerSeat }) => ({
        segmentId: segmentId || '',
        seatNumber: travelerSeat,
      })) || []
    )
  }, [segments])

  const { getCabinClassLabel } = useCabinClassLabels()

  const displayServiceClass = useMemo(() => {
    const firstSegment = segments?.find(({ segmentId }) => segmentId === selectedSegmentId)
    return firstSegment ? getCabinClassLabel(firstSegment.serviceClass as SeatmapCabinClass) : ''
  }, [selectedSegmentId, segments, getCabinClassLabel])

  const [seatStateStatus, setSeatStateStatus] = useState<AddSeatStateStatus>('idle')
  const resetSeatStateStatus = () => {
    setSeatStateStatus('idle')
  }

  const onSeatsApprove = async (newSeats: SegmentSeatNumber[]) => {
    const { addSeats, removeSeats } = getSeatsInput({
      initialSeats: initialSegmentSeatNumberList,
      newSeats,
    })
    const input = {
      itineraryId,
      addSeats,
      removeSeats,
    }

    if (!input.addSeats.length && !input.removeSeats.length) {
      onClose()
      return
    }

    try {
      setSeatStateStatus('loading')

      if (bookingId) {
        const postBookingResponse = await postBookingTripActions.changeFlightSeats({
          ...getSeatsInput({ segments, initialSeats: initialSegmentSeatNumberList, newSeats }),
          transactionId: bookingId,
        })

        if (postBookingResponse.isErr()) {
          throw new Error(postBookingResponse.getValue().message)
        }
        setSeatStateStatus('success')
        await delayForSuccess(500)
        resetSeatStateStatus()
        onClose()
        if (onSeatsChanged) {
          onSeatsChanged()
        }
        return
      }

      const response = await tripFlightActions.handleChangeFlightSeatsAssignment(input)

      if (response.isErr()) {
        throw new Error(response.getValue().message)
      }
      await tripActions.updateTrip()
      setSeatStateStatus('success')
      await delayForSuccess(500)
      resetSeatStateStatus()
      onClose()
    } catch (e) {
      setSeatStateStatus('error')

      if (e instanceof Error) {
        rollbar.error(e)
      } else {
        rollbar.error(new Error('Unknown error'))
      }
    }
  }

  return {
    initialSegmentSeatNumberList,
    displayServiceClass,
    isLoading,
    seatStateStatus,
    resetSeatStateStatus,
    fareSegmentSeatRows,
    retryFetchSeatMap,
    selectedSegmentId,
    onSelectedSegmentIdChange,
    onSeatsApprove,
  }
}
