import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../types'
import {
  Title,
  Border,
  Footer,
  Container,
  Description,
  CustomExpenseCodeTextField,
  Message,
} from './mobile-components'

const i18nBase = 'Mobility.EnterCustomExpenseCodePage.'

export const EnterCustomExpenseCodeMobile = ({
  onBack,
  onContinue,
  onCustomCodeValueChanged,
  customCodeValue,
  isContinueButtonDisabled,
  maxCustomCodeLength,
}: LayoutProps) => {
  const { t } = useTranslation()

  return (
    <Screen>
      <Screen.Header>
        <Header
          layout="sub-title"
          backgroundColor="white"
          leftSlot={<Header.BackButton color="mainText" onClick={onBack} />}>
          <Title>{t(i18nBase + 'Title')}</Title>
        </Header>
        <Border />
      </Screen.Header>
      <Screen.Container>
        <Container>
          <Description>{t(i18nBase + 'Description')}</Description>
          <CustomExpenseCodeTextField
            label={t(i18nBase + 'ExpenseCodePlaceholder')}
            value={customCodeValue}
            onChange={onCustomCodeValueChanged}
            maxLength={maxCustomCodeLength}
          />
          <Message>
            {t(i18nBase + 'CharValidation', {
              length: maxCustomCodeLength - customCodeValue.length,
            })}
          </Message>
        </Container>
      </Screen.Container>
      <Screen.Footer withBorder backgroundColor="white">
        <Footer>
          <Button fullWidth minWidth={14} onClick={onContinue} disabled={isContinueButtonDisabled}>
            {t(i18nBase + 'ContinueButton')}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
