import styled from 'styled-components'
import { captionMedium } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  margin: 24px 0;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
`

export const LeftSection = styled.div`
  width: 50%;
  margin: 20px;
  margin-right: 0;
`

export const RightSection = styled.div<{ hasClassType?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: ${({ hasClassType }) => (hasClassType ? '65px' : '40px')} 20px 20px 0;
  padding-left: 20px;
  border-left: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const ServiceClass = styled.div`
  margin-bottom: 2px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.bodyText};

  ${captionMedium};
`

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`

export const ButtonWrap = styled.div`
  &:first-child {
    margin-bottom: 2px;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:not(:first-child) {
    border-top: 1px dashed ${({ theme }) => theme.colors.borderLight};
    padding-top: 20px;
  }
`

export const IconWrap = styled.div<{ hasValue?: boolean }>`
  ${({ hasValue }) => hasValue && 'align-self: flex-start;'}
  margin-right: 22px;
`

export const ButtonIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 10px;
`
