import styled from 'styled-components'
import { body } from '@fiji/style'

export const Description = styled.div`
  ${body};
  color: ${({ theme }) => theme.colors.mainText};
  width: 300px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 26px;
`
