import styled from 'styled-components'
import { headline } from '@fiji/style/typography'

export const Container = styled.div`
  ul {
    padding: 0;
  }
`

export const SearchWrapper = styled.div`
  padding: 12px 16px;
`

export const Label = styled.label`
  margin-bottom: 2px;
`

export const InputWrapper = styled.div`
  > div {
    height: 22px;
    background-color: ${(p) => p.theme.colors.white};
    > input {
      height: 22px;
      padding: 0;
      background-color: ${(p) => p.theme.colors.white};
      ${headline};
      &::placeholder {
        color: transparent;
      }
    }
  }
`
