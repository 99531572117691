import { isValid } from 'date-fns'
import { Inject, Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import { HistoryStore } from '@etta/interface/stores/history.store'
import type { Dates } from '@fiji/types'
import { UserStore } from '@etta/modules/user'
import type {
  DateRangeValueObject,
  MeetingInfoValueObject,
} from '../../core/value-objects/meeting-mode.value-object'

@Store()
export class MeetingModeStore {
  private _meetingInfo: MeetingInfoValueObject | null = null
  private _isError = false
  meetingModeQueryParams = new QueryParams<{ meetingId: string }>({}, { caseStyle: 'kebab-case' })

  constructor(
    @Inject() private historyStore: HistoryStore,
    @Inject() private userStore: UserStore,
  ) {}

  get meetingId() {
    const meetingId = this.meetingModeQueryParams.getQueryParams(this.historyStore.search).meetingId
    if (!meetingId) {
      return ''
    }
    return String(meetingId)
  }

  get meetingInfo(): MeetingInfoValueObject | null {
    return this._meetingInfo
  }

  get destinationAirport(): string | null {
    return this._meetingInfo?.meeting.policies.flightPolicies?.destinationAirport ?? null
  }

  get meetingPolicies() {
    return this._meetingInfo?.meeting.policies
  }

  get dates(): Dates {
    return this.dateRangeValueObjectToDates(this._meetingInfo?.meeting.dates)
  }

  get additionalDateRange(): Dates {
    const startDate = this.arrivalDates.date
    const endDate = this.departureDates.dateEnd
    return { date: startDate, dateEnd: endDate }
  }

  get meetingName(): string | null {
    return this.meetingInfo?.meeting.name ?? null
  }

  get participantName(): string | null {
    return this.meetingInfo?.participant.firstName ?? null
  }

  get isMeetingModeDelegate(): boolean {
    return this.meetingInfo?.isMeetingModeDelegate ?? false
  }

  private get arrivalDates(): Dates {
    return this.dateRangeValueObjectToDates(this._meetingInfo?.meeting.policies.arrival)
  }

  private get departureDates(): Dates {
    return this.dateRangeValueObjectToDates(this._meetingInfo?.meeting.policies.departure)
  }

  setMeetingInfo(value: MeetingInfoValueObject) {
    this._meetingInfo = value
  }

  get isInMeetingMode() {
    return Boolean(this.userStore.meetingId)
  }

  dropStore() {
    this._meetingInfo = null
  }

  private dateRangeValueObjectToDates(dateRanges: DateRangeValueObject | undefined) {
    const { startDate, endDate } = dateRanges ?? {}
    const date = startDate ? new Date(startDate) : null
    const dateEnd = endDate ? new Date(endDate) : null
    return {
      date: isValid(date) ? date : null,
      dateEnd: isValid(dateEnd) ? dateEnd : null,
    }
  }

  get isError() {
    return this._isError
  }

  setIsError(value: boolean) {
    this._isError = value
  }
}
