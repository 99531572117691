import i18n from 'i18next'
import { StopsValue } from '@etta/modules/air-search/core/enums/stops'
import { Action, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { AirSearchQueryStore } from '../stores/air-search-query.store'
import { AirFiltersStore } from '../stores/air-search/air-filters.store'
import { AirSearchQueryService } from '../services/air-search-query/air-search-query.service'
import { AirSearchService } from '../services/air-search/air-search.service'

@Action()
export class AirQuickFilterActions {
  constructor(
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airFiltersStore: AirFiltersStore,
    @Inject() private airSearchQueryService: AirSearchQueryService,
    @Inject() private airSearchService: AirSearchService,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}

  getStopsValue(value?: string) {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags
    if (!value || value === StopsValue.Any) {
      return isNewAirExposedFiltersEnabled ? '' : i18n.t('AirFilters.Stops.Any')
    }

    if (value === StopsValue.Zero) {
      return i18n.t('AirFilters.Stops.Nonstop')
    }
    if (value === StopsValue.One) {
      return i18n.t('AirFilters.Stops.1Stop')
    }
    return `${value} ${i18n.t('AirFilters.Stops.Stops')}`
  }

  getAirlinesNames() {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags
    if (!this.airSearchQueryStore.additionalQueries.filters?.airlines?.length) {
      return isNewAirExposedFiltersEnabled ? '' : i18n.t('AirFilters.AllAirlines')
    }
    const airlines = this.airSearchQueryStore.additionalQueries.filters?.airlines.map(
      (airline) => airline.name,
    )
    return airlines.join(', ')
  }

  getAirports() {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags
    const originAirports = this.airSearchQueryStore.additionalQueries.filters?.originAirports || []
    const destinationAirports =
      this.airSearchQueryStore.additionalQueries.filters?.destinationAirports || []
    if (!originAirports?.length && !destinationAirports?.length) {
      return isNewAirExposedFiltersEnabled ? '' : i18n.t('AirFilters.AllAirports')
    }

    return [...originAirports, ...destinationAirports].map((airport) => airport.code).join(', ')
  }

  getFareTypes() {
    const { isNewAirExposedFiltersEnabled } = this.featureFlagsStore.flags
    const fareTypes = this.airSearchQueryStore.additionalQueries.filters?.fareTypes
    if (!fareTypes?.length) {
      return isNewAirExposedFiltersEnabled ? '' : i18n.t('AirFilters.AllFareTypes')
    }
    return fareTypes.map((fare) => fare.displayName).join(', ')
  }

  async handleApplyQuickFilter(type: 'airline' | 'fareType' | 'airports' | 'stops') {
    const filters = this.airFiltersStore.newFilters
    const existingFilters = this.airSearchQueryStore.additionalQueries.filters
    if (!existingFilters) {
      return
    }

    switch (type) {
      case 'stops':
        this.airSearchQueryService.appendQueryParams({
          filters: { ...existingFilters, stops: filters.stops },
        })
        break
      case 'airline':
        this.airSearchQueryService.appendQueryParams({
          filters: { ...existingFilters, airlines: filters.airlines },
        })
        break
      case 'fareType':
        this.airSearchQueryService.appendQueryParams({
          filters: {
            ...existingFilters,
            fareTypes: filters.fareTypes,
          },
        })
        break
      case 'airports':
        this.airSearchQueryService.appendQueryParams({
          filters: {
            ...existingFilters,
            originAirports: filters.originAirports,
            destinationAirports: filters.destinationAirports,
          },
        })
        break
      default:
        break
    }
    this.airFiltersStore.stopsModal.handleClose()
    this.airFiltersStore.airLinesModal.handleClose()
    this.airFiltersStore.airportsModal.handleClose()
    this.airFiltersStore.fareTypeModal.handleClose()
    this.airFiltersStore.filterModal.handleClose()
    await this.airSearchService.getFlights({ filters })
  }

  handleOpenAirportsFilter() {
    const filters = this.airSearchQueryStore.additionalQueries.filters
    if (filters) {
      this.airFiltersStore.setFilters(filters)
    }

    this.airFiltersStore.airportsModal.handleOpen()
  }
}
