import { observer } from 'mobx-react-lite'
import { Checkbox } from '@etta/ui/checkbox'
import type { FilterOptionsProps } from '../../types'
import { StyledBlock } from '../../car-rental-filters-styled'
import { useFuelOptions } from './use-fuel-options'

export const FuelOptions = observer(function FuelOptions({
  withBottomBorder = true,
  optionsPaddingVertical = 10,
}: FilterOptionsProps) {
  const { options, isChecked, handleSelectFuelType } = useFuelOptions()

  return (
    <>
      {options.map((option) => {
        return (
          <StyledBlock
            paddingVertical={optionsPaddingVertical}
            key={option.id}
            withBottomBorder={withBottomBorder}>
            <Checkbox
              data-tracking-id="fuel-type-filter"
              value={option.id}
              label={option.title}
              checked={isChecked(option.id)}
              onChange={handleSelectFuelType(option.id)}
            />
          </StyledBlock>
        )
      })}
    </>
  )
})
