import { observer } from 'mobx-react-lite'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { Segments, TripDetails as TripDetailsType } from '../../types'
import { TripOverview } from './trip-overview'
import { TripSegment } from './trip-segment'
import { Wrapper } from './trip-details-styled'
import { TripCost } from './trip-cost'
import { TrainDelivery } from './train-delivery'

type Props = {
  tripDetails: TripDetailsType
  segments: Segments
  onShareClick?: () => void
  mobilityCallToActionSlot?: React.ReactNode
  isNDCTrip: boolean
}
export const TripDetails = observer(function ({
  tripDetails,
  onShareClick,
  segments,
  mobilityCallToActionSlot,
  isNDCTrip,
}: Props) {
  const railDeliveryGuideModal = useTogglePopup()

  return (
    <Wrapper>
      <TripOverview onShareClick={onShareClick} />
      {mobilityCallToActionSlot}
      <TripSegment
        segments={segments}
        isOpen={railDeliveryGuideModal.isOpen}
        handleClose={railDeliveryGuideModal.handleClose}
        isNDCTrip={isNDCTrip}
      />
      <TripCost tripCost={tripDetails.tripCost} />
      <TrainDelivery
        delivery={segments.rail?.delivery}
        onDeliveryGuideOpen={railDeliveryGuideModal.handleOpen}
      />
    </Wrapper>
  )
})
