import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const MainSection = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 14px 18px 24px 14px;
  display: flex;
  justify-content: space-between;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 18px 27px 14px;
`
