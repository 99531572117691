import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface/'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Wrapper, InfoBody } from './filter-search-info-styled'
import { FilterSearchInfoSkeleton } from './filter-search-info-skeleton'
import { FilterSearchInfoWrapper } from './filter-search-info-wrapper'

const i18nBase = 'CarRentalResults'

export const FilterSearchInfo = observer(function FilterSearchInfo() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const { carRentalFilterActions, carRentalSearchStore } = useCarRentalSearchContext()
  const { handleResetCarCompanyFilters } = carRentalFilterActions
  const { isLoadingMore, isLoading } = carRentalSearchStore

  if (isLoadingMore || isLoading) {
    return <FilterSearchInfoSkeleton />
  }

  return (
    <FilterSearchInfoWrapper>
      <Wrapper>
        <Block paddingVertical={2} paddingHorizontal={4}>
          <Icon name="filtersHorizontalPWA" size="small" />
        </Block>
        <InfoBody columnView={isMobile}>
          <Text variant="footnoteStrong">{t(`${i18nBase}.CompanySettingFilters`)}</Text>
          <Link
            size="normal"
            onClick={handleResetCarCompanyFilters}
            aria-label={t(`${i18nBase}.ClearCompanySettingFiltersAccessibilityLabel`)}>
            {t(`${i18nBase}.ClearCompanySettingFilters`)}
          </Link>
        </InfoBody>
      </Wrapper>
    </FilterSearchInfoWrapper>
  )
})
