import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { HotelCardLayoutProps } from './types'
import { HotelCardMobile } from './mobile'
import { HotelCardDesktop } from './desktop'

export function HotelCardLayout({ ...props }: HotelCardLayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<HotelCardMobile {...props} />}
      desktopSlot={<HotelCardDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
