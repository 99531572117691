import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const MonthWrapper = styled.div`
  margin-bottom: 18px;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
