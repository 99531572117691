import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Block } from '@etta/ui/block'
import { Modal } from '@etta/ui/modal'
import { TripStatus } from '@fiji/graphql/types'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { SegmentType } from '@etta/core/enums'
import { Text } from '@etta/ui/text'
import type { CancelTripDataValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Body, SqareContent, SegmentContainer } from '../cancel-trip-modal-styled'
import { RideHailSegmentContainer } from '../ride-hail-segment/full-screen-styled'
import { FullScreenRideHailSegment as RideHailSegment } from '../ride-hail-segment/full-screen'
import { TripSegment } from '../trip-segment'
import { FullScreenDescriptiveActions as DescriptiveAction } from '../descriptive-actions'
import { FullScreenHeader as Header } from '../header'
import { FullScreenFooter as Footer } from '../footer'
import { FullScreenRideHailCancelSection as RideHailCancelSection } from '../ride-hail-cancel-section/full-screen'
import { TripTotalCost } from '../trip-total-cost'
import type { VariantProps } from './types'

export const CancelTripModalFullScreen = observer(function CancelTripModalFullScreen({
  isChecked,
  titleDate,
  statement,
  onChange,
  onClose,
}: VariantProps) {
  const { postBookingCancellationStore, postBookingTripStore } = usePostBookingContext()

  const tripSegments = postBookingCancellationStore.cancellationSegments.filter(
    (segment) => segment.type !== SegmentType.RideHail,
  )

  const { filteredSegments } = useMemo(
    () =>
      tripSegments.reduce(
        (acc, currSegment) => {
          if (currSegment.isRoundTripLeg && currSegment.isRoundTripCost && !Object.keys(acc.roundSegment).length)
          {
            acc.roundSegment = { ...currSegment }

            return acc
          }

          if (currSegment.isRoundTripLeg && currSegment.isRoundTripCost && Object.keys(acc.roundSegment).length)
          {
            acc.filteredSegments.push(acc.roundSegment)
            return acc
          }

          acc.filteredSegments.push(currSegment)
          return acc
        },
        {
          roundSegment: {} as CancelTripDataValueObject,
          filteredSegments: [] as CancelTripDataValueObject[],
        },
      ),
    [tripSegments],
  )

  const rideHailSegments = postBookingCancellationStore.cancellationSegments.filter(
    (segment) => segment.type === SegmentType.RideHail && segment.status === TripStatus.Confirmed,
  )
  const isCancelRideHailSegment =
    postBookingCancellationStore.cancellationSegment?.type === SegmentType.RideHail
  const targetRideHailSegment = rideHailSegments.find((rs) => {
    if (postBookingCancellationStore.cancellationSegment?.type === SegmentType.RideHail) {
      return Number(postBookingCancellationStore.cancellationSegment.bookingId) === rs.id
    }
    return false
  })

  return (
    <>
      <Header statement={statement} onClose={onClose} />
      <Modal.Body>
        <Body>
          <SqareContent>
            <Text variant="headline" isBlock>
              {postBookingTripStore.trip.name}
            </Text>
            <Text variant="captionStrongCaps" isBlock data-tracking-id="travel-dates-text">
              {titleDate}
            </Text>
            <Block paddingVertical={10}>
              <SegmentContainer>
                {filteredSegments.map((item) => {
                  return <TripSegment key={item.id} {...item} />
                })}
                {targetRideHailSegment && (
                  <RideHailSegmentContainer>
                    <RideHailSegment
                      key={targetRideHailSegment.id}
                      name={targetRideHailSegment.name}
                      cost={targetRideHailSegment.cost}
                      dropOffName={targetRideHailSegment.dropOffAddress}
                      pickUpDateTime={targetRideHailSegment.startDate}
                    />
                  </RideHailSegmentContainer>
                )}
              </SegmentContainer>
            </Block>
            <TripTotalCost
              costSummary={postBookingTripStore.trip.costSummary}
              segmentType={postBookingCancellationStore.cancellationSegment?.type}
            />
            <DescriptiveAction
              isChecked={isChecked}
              onChange={onChange}
              hasError={isCancelRideHailSegment}
              rideHailSegment={
                !isCancelRideHailSegment &&
                rideHailSegments.length !== 0 && (
                  <Block marginTop={34}>
                    <RideHailCancelSection rideHailSegmentData={rideHailSegments} />
                  </Block>
                )
              }
            />
          </SqareContent>
          <Footer statement={statement} isChecked={isChecked} />
        </Body>
      </Modal.Body>
    </>
  )
})
