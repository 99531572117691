import i18next from 'i18next'
import type { CarWorkingHours } from '@fiji/graphql/types'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import type { CarVendorInformation } from '../types'

type Props = {
  carRental: CarRentalSegmentEntity
}

function getWorkingHours(workingHours?: CarWorkingHours | null) {
  return (workingHours?.range && workingHours?.range[0]) || null
}

export function getVendorInformation({ carRental }: Props): CarVendorInformation {
  const carRentalWorkingHours = carRental.pickUpLocation.workingHours
  const imageUrl = carRental.vendorDetails.imageUrl
  const title = carRental.name + ', ' + formatPickUpDropOffAddress(carRental.pickUpLocation.address)
  const workingHours = carRentalWorkingHours?.isAllDay
    ? i18next.t('CarRentalModal.OpenAllDay')
    : getWorkingHours(carRentalWorkingHours)
  const phone = carRental.pickUpLocation.phone?.number

  return { imageUrl, title, workingHours, phone, name: carRental.name }
}
