import { useEffect, useRef } from 'react'
import { getTokenExpireTime } from './get-token-expire-time'
import { useExpirationTimeUpdate } from './use-expiration-time-update'
import { REMOVE_MODAL_TIMER } from './constants'

type Args = {
  modalTimerId: number | null
  isOpen: boolean
  handleClose: () => void
}

export function useRemoveSessionModalTimer({ modalTimerId, isOpen, handleClose }: Args) {
  const checkerTimerValidityTimer = useRef<number | null>(null)
  const { isExpirationTimeUpdated, handleSetTokenExpireTime } = useExpirationTimeUpdate()

  useEffect(() => {
    const handleCloseModalIfExpirationTimeUpdated = () => {
      if (checkerTimerValidityTimer.current) {
        window.clearTimeout(checkerTimerValidityTimer.current)
      }
      checkerTimerValidityTimer.current = window.setTimeout(() => {
        handleCloseModalIfExpirationTimeUpdated()
      }, REMOVE_MODAL_TIMER)

      if (isExpirationTimeUpdated()) {
        return
      }

      const newTimeBeforeExpired = getTokenExpireTime()
      handleSetTokenExpireTime(newTimeBeforeExpired)

      if (modalTimerId && newTimeBeforeExpired && isOpen) {
        window.clearTimeout(modalTimerId)
        handleClose()
      }
    }

    handleCloseModalIfExpirationTimeUpdated()
  }, [modalTimerId, isOpen, isExpirationTimeUpdated, handleSetTokenExpireTime, handleClose])
}
