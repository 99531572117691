import { useTranslation } from 'react-i18next'
import { useLuggageOptionsContext } from '@etta/modules/booking'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { useDelegateContext } from '@etta/modules/delegate'
import { useTogglePopup } from '../use-toggle-popup'
import { usePreventLocationNavigation } from './use-prevent-location-navigation'

type Args = {
  isLoading: boolean
  isPreventBack?: boolean
  isPreventForward?: boolean
  shouldOpenExplore?: boolean
  bookingId?: string
  canOpenPostBookingTripInsteadOfExplore?: boolean
}

const i18Base = 'ReviewTrip'

export function useStartOverModal({
  isLoading,
  isPreventBack = true,
  isPreventForward = true,
  canOpenPostBookingTripInsteadOfExplore = true,
  shouldOpenExplore = false,
  bookingId,
}: Args) {
  const { t } = useTranslation()
  const startOverModal = useTogglePopup()
  const { tripActions, reviewTripStore } = useReviewTripContext()
  const { luggageOptionsActions } = useLuggageOptionsContext()
  const { delegateStore, delegateActions } = useDelegateContext()
  const {
    delegateActions: { handleSelectDelegate },
  } = useDelegateContext()
  const { removeTrip } = tripActions
  const { isTripRemovingLoading } = reviewTripStore

  const handleOnPrevent = () => {
    if (delegateStore.onExitDelegateMode) {
      delegateActions.dropDelegateId()
      handleReset()
      return
    }
    startOverModal.handleOpen()
  }

  const { handleOpenNewLocation } = usePreventLocationNavigation({
    isLoading,
    isPreventBack,
    isPreventForward,
    shouldOpenExplore,
    bookingId,
    onPrevent: handleOnPrevent,
    canOpenPostBookingTripInsteadOfExplore,
  })

  const handleConfirm = () => {
    if (delegateStore.delegateId) {
      handleSelectDelegate(delegateStore.delegateId)
    }
    startOverModal.handleClose()
    handleReset()
  }

  const handleReset = () => {
    luggageOptionsActions.resetLuggageOption()
    removeTrip()
    handleOpenNewLocation()
  }

  const startOverModalSlot = (
    <ConfirmationDialog
      title={t(i18Base + (bookingId ? '.DiscardChanges' : '.StartOver'))}
      content={t(i18Base + (bookingId ? '.DiscardChangesDescription' : '.StartOverDescription'))}
      isOpen={startOverModal.isOpen}
      onDecline={startOverModal.handleClose}
      onConfirm={handleConfirm}
      confirmButtonText={t(i18Base + (bookingId ? '.DiscardChanges' : '.StartOver'))}
      declineButtonText={t(i18Base + (bookingId ? '.ContinueEditing' : '.Cancel'))}
    />
  )

  return {
    startOverModal,
    startOverModalSlot,
    isTripRemovingLoading,
  }
}
