import { useTranslation } from 'react-i18next'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { Container, TitleWrapper } from './headline-styled'

type Props = {
  name?: string
  checkIn?: string
  checkOut?: string
}

const i18nBase = 'PostBooking.HotelDetails'

export function Headline({ name, checkIn, checkOut }: Props) {
  const { t } = useTranslation()
  const titleDate = useDateRangeTitle(checkIn || '', checkOut || '')
  const totalDays = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(
    checkIn || '',
    checkOut || '',
  )
  const dateText = `${titleDate} (${totalDays && t(`${i18nBase}.Nights`, { count: totalDays })})`
  const ariaLabel = `${name}. ${dateText}`

  return (
    <Container aria-label={ariaLabel}>
      <TitleWrapper>
        <Text variant="title2" color="mainText2" isBlock>
          {name || ''}
        </Text>
        <Block marginTop={8}>
          <Text variant="footnoteMedium" color="mainText2">
            {dateText}
          </Text>
        </Block>
      </TitleWrapper>
    </Container>
  )
}
