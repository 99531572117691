import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import { Container } from '@etta/di'
import { ApolloService } from '@etta/infra/services/apollo.service/apollo.service'
import { deferredTokenUpdate } from './token-process-link/deffered-token-update'

let client: ApolloClient<NormalizedCacheObject>

deferredTokenUpdate()

export function getClient() {
  if (client) {
    return client
  }

  const instance = Container.get(ApolloService)
  client = instance.createClient()
  return client
}
export { ApolloProvider }
