import styled, { css } from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
`

const newExplorePageStyled = css`
  padding: 50px 130px 40px;
`

export const SearchBlock = styled.div<{ isNewLayout?: boolean }>`
  padding: 50px 64px 40px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.06),
    inset 0px 1px 0px ${({ theme }) => theme.colors.borderLight};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${({ isNewLayout }) => isNewLayout && newExplorePageStyled}
`

export const SearchLine = styled.div`
  display: flex;
`

export const SerachLine = styled(Block)`
  gap: 10px;
`

export const PageIcon = styled(Icon)`
  width: 120px;
  height: 120px;
`

export const SearchButton = styled(Button)`
  display: flex;
  margin-left: 10px;
  width: 64px;
  height: 64px;
  & > * {
    display: flex;
    justify-content: center;
  }
`

export const Header = styled.div<{ isMaxLaptop?: boolean }>`
  width: 100%;
  max-width: 1024px;
  display: flex;
  padding-bottom: 24px;
  ${({ isMaxLaptop }) =>
    isMaxLaptop &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`
