import { useScreenType, ScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'

type Props = {
  confirmation: string
}

export function FlightLegConfirmation({ confirmation }: Props) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (isMobile) {
    const [firstText, secondText] = confirmation.split(',')
    return (
      <>
        {firstText && (
          <Text variant="footnoteMedium" color="bodyText1">
            {firstText}
          </Text>
        )}
        {secondText && (
          <Text variant="footnoteMedium" color="bodyText1">
            {secondText}
          </Text>
        )}
      </>
    )
  }

  return (
    <Text variant="footnoteMedium" color="bodyText1">
      {confirmation}
    </Text>
  )
}
