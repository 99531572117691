import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { ErrorContainer } from '@etta/ui/error-container'
import { Form } from '@etta/ui/form'
import { LazyContent } from '@etta/ui/lazy-content'
import { Swap } from '@etta/ui/swap'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { OnAccountCreditCardInfo } from '@etta/screens/payment-summary/on-account-credit-card-info'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import type { LayoutProps } from '../../types'
import { PaymentCreditCard } from '../../payment-credit-card'
import { CarRentals } from '../../sections/car-rentals'
import { Hotels } from '../../sections/hotels'
import { PreviousBooking } from '../../previous-booking'
import { Flights } from '../../sections/flights'
import { Rail } from '../../sections/rail'
import { Content } from '../../payment-summary-styled'
import { AddLuggageButton } from '../../add-luggage-button'
import { TicketExchangeAcknowledgement } from '../../ticket-exchange-acknowledge'
import { CreditCardBlock } from './credit-card-block'

export const PaymentSummaryDesktop = observer(function PaymentSummaryDesktop({
  footerSlot,
  shouldRenderPreviousBooking,
  airCardCheck,
  carRentalCardCheck,
  carRentalDetails,
  flightDetails,
  hotelCardCheck,
  hotelDetails,
  isRailAccreditationEnabled,
  isCarPaymentRequired,
  isCreditCardChangeable,
  isLoading,
  isLoadingError,
  selectedLuggageOption,
  handleOpenLuggageToggle,
  showLuggageOptions,
  newCarRentals,
  newFlights,
  newHotels,
  newRail,
  onBillingModalOpen,
  onPaymentSubmit,
  onReload,
  railCardCheck,
  railDetails,
  tripCost,
  validator,
  bookedCarRentals,
  bookedFlights,
  bookedHotels,
  isTripOnHold,
  openAddCreditCardModal,
  isBilledToCompanyAccount,
}: LayoutProps) {
  const { segmentTypeCheckActions } = useReviewTripContext()
  const {
    featureFlagsStore: {
      flags: { isDesktopUnusedTicketsEnabled },
    },
  } = useFeatureFlagsContext()
  const { t } = useTranslation()
  const areFlightSegments = !!newFlights?.segments?.length
  const areHotelSegments = !!newHotels?.segments?.length
  const areCarRentalSegments = !!newCarRentals?.segments?.length

  const mappedFlight = newFlights?.segments.filter(segmentTypeCheckActions.isFlightSegment)
  const mappedHotel = newHotels?.segments.filter(segmentTypeCheckActions.isHotelSegment)
  const mappedCarRental = newCarRentals?.segments.filter(segmentTypeCheckActions.isCarRentalSegment)
  const mappedRail = newRail?.segments.filter(segmentTypeCheckActions.isTrainSegment)

  const { queryParams } = useCheckoutQuery()
  const { isHoldFlow } = queryParams

  return (
    <>
      <Content>
        <>
          {shouldRenderPreviousBooking && (
            <PreviousBooking
              tripCost={tripCost}
              flights={bookedFlights}
              hotels={bookedHotels}
              carRentals={bookedCarRentals}
              isExtendedByDefault={isTripOnHold}
            />
          )}
          <Swap
            is={isLoadingError}
            isSlot={
              <ErrorContainer
                onReload={onReload}
                title={t('PaymentSummary.UnableToLoadInfo')}
                align="central"
              />
            }>
            <LazyContent isLoading={isLoading} height="174px">
              <Form onSubmit={onPaymentSubmit}>
                <Flights
                  segments={mappedFlight}
                  segmentState={newFlights?.segmentState}
                  tripCostSummary={tripCost}
                  flightTripCost={newFlights?.tripCost}
                  isHoldFlow={isHoldFlow}
                  showTaxBreakdown={true}>
                  {showLuggageOptions && (
                    <AddLuggageButton
                      selectetedLuggageOption={selectedLuggageOption}
                      onClick={handleOpenLuggageToggle}
                    />
                  )}

                  {isDesktopUnusedTicketsEnabled && <TicketExchangeAcknowledgement />}

                  <CreditCardBlock
                    creditCard={flightDetails?.creditCard}
                    onAddNewCreditCard={() => onBillingModalOpen('flight')}
                    billingError={airCardCheck.billingError}>
                    {flightDetails?.creditCard && (
                      <PaymentCreditCard
                        creditCard={flightDetails?.creditCard}
                        mainValidator={validator}
                        type={'flight'}
                        onEdit={onBillingModalOpen}
                        isCvvRequired={
                          !flightDetails?.creditCard.isSiteCard &&
                          flightDetails?.billing?.metadata.isCvvRequired
                        }
                        isCreditCardChangeable={isCreditCardChangeable.flight}
                      />
                    )}
                  </CreditCardBlock>
                </Flights>

                <Hotels
                  segments={mappedHotel}
                  segmentState={newHotels?.segmentState}
                  hotelTripCost={newHotels?.tripCost}
                  isPayingNow={newHotels?.isPayNow && !isHoldFlow}
                  previousBookingRate={newHotels?.previousBookingRate}
                  withMarginTop={areFlightSegments}>
                  <CreditCardBlock
                    creditCard={hotelDetails?.creditCard}
                    onAddNewCreditCard={() => onBillingModalOpen('hotel')}
                    isCardExpiringEarlierThanHotel={hotelCardCheck.isCardExpiringEarlierThanHotel}
                    billingError={hotelCardCheck.billingError}>
                    {hotelDetails?.creditCard && (
                      <PaymentCreditCard
                        creditCard={hotelDetails?.creditCard}
                        virtualPay={hotelDetails?.virtualPay}
                        mainValidator={validator}
                        type={'hotel'}
                        onEdit={onBillingModalOpen}
                        isCvvRequired={
                          !hotelDetails?.creditCard.isSiteCard &&
                          hotelDetails?.billing?.metadata.isCvvRequired
                        }
                        isCreditCardChangeable={isCreditCardChangeable.hotel}
                      />
                    )}
                  </CreditCardBlock>
                </Hotels>

                <CarRentals
                  segments={mappedCarRental}
                  segmentState={newCarRentals?.segmentState}
                  carTripCost={newCarRentals?.tripCost}
                  isPayingNow={newCarRentals?.isPayNow && !isHoldFlow}
                  previousBookingRate={newCarRentals?.previousBookingRate}
                  withMarginTop={areHotelSegments || areFlightSegments}>
                  {isCarPaymentRequired && (
                    <CreditCardBlock
                      creditCard={carRentalDetails?.creditCard}
                      onAddNewCreditCard={() => onBillingModalOpen('car')}
                      billingError={carRentalCardCheck.billingError}>
                      {carRentalDetails?.creditCard && (
                        <PaymentCreditCard
                          creditCard={carRentalDetails?.creditCard}
                          mainValidator={validator}
                          type={'car'}
                          onEdit={onBillingModalOpen}
                          isCvvRequired={
                            !carRentalDetails?.creditCard.isSiteCard &&
                            carRentalDetails.billing?.metadata.isCvvRequired
                          }
                          isCreditCardChangeable={isCreditCardChangeable.carRental}
                        />
                      )}
                    </CreditCardBlock>
                  )}
                </CarRentals>

                <Rail
                  segments={mappedRail}
                  segmentState={newRail?.segmentState}
                  previousBookingRate={newRail?.previousBookingRate}
                  tripCostSummary={tripCost}
                  railTripCost={newRail?.tripCost}
                  isRailAccreditationEnabled={isRailAccreditationEnabled}
                  withMarginTop={areFlightSegments || areHotelSegments || areCarRentalSegments}>
                  <CreditCardBlock
                    creditCard={railDetails?.creditCard}
                    onAddNewCreditCard={openAddCreditCardModal('rail')}
                    billingError={railCardCheck.billingError}
                    isBilledToCompanyAccount={isBilledToCompanyAccount}>
                    <OnAccountCreditCardInfo backgroundColor={'white'}></OnAccountCreditCardInfo>
                  </CreditCardBlock>
                </Rail>
              </Form>
            </LazyContent>
          </Swap>
        </>
      </Content>

      {footerSlot}
    </>
  )
})
