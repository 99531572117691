import ApolloLinkTimeout from 'apollo-link-timeout'
import { createHttpLink } from './create-http-link'

type Props = {
  url: string
}

export function createTimeoutHttpLink({ url }: Props) {
  const httpLink = createHttpLink({ url })
  const timeoutLink = new ApolloLinkTimeout(5 * 60 * 1000) // timeout 5 secs
  return timeoutLink.concat(httpLink)
}
