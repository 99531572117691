import { Swap } from '@etta/ui/swap'
import { ImageContainer } from '@etta/components/image-container/image-container'
import { ImageRow } from './flight-image-styled'
import { default as Multiple40 } from './assets/multiple-40.svg'
import { default as SingleFlight40 } from './assets/single-flight-40.svg'

export type Props = {
  isMultipleAirlines?: boolean
  image?: string | null
  alt?: string
  skin?: 'small-logo' | 'big-logo' | 'default-logo'
}

export function FlightImage({ isMultipleAirlines, image = '', skin = 'default-logo', alt }: Props) {
  return (
    <>
      <ImageRow skin={skin}>
        <Swap is={isMultipleAirlines} isSlot={<Multiple40 />}>
          <ImageContainer url={image} alt={alt} errorSlot={<SingleFlight40 />} />
        </Swap>
      </ImageRow>
    </>
  )
}
