import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalVendorInformationLayoutProps } from '../types'
import { CarRentalVendorInformationMobile } from './mobile'
import { CarRentalVendorInformationDesktop } from './desktop'

export function CarRentalVendorInformationLayout(props: CarRentalVendorInformationLayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalVendorInformationMobile {...props} />}
      desktopSlot={<CarRentalVendorInformationDesktop {...props} />}
    />
  )
}
