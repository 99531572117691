import styled from 'styled-components'
import ReactDotsOverrides from '../react-slick-overrides'

type Props = {
  translateWidth: number
  dotsLength: number
}

const DOT_WIDTH = 20

export const DotsContainer = styled.div`
  ${ReactDotsOverrides}
`

export const DotsWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100px;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`

export const Dots = styled.ul<Props>`
  transform: ${(props) => `translateX(${props.translateWidth}px)`};
  width: ${(props) => `${props.dotsLength * DOT_WIDTH}px`};
  transition: all 0.2s;
`
