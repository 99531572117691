import styled, { css } from 'styled-components'
import {
  title3,
  footnoteMedium,
  captionMedium,
  subHeadMedium,
  getKeyboardNavigationStyle,
} from '@fiji/style'

export const Container = styled.div``

export const Placeholder = styled.div`
  position: absolute;
  padding-top: 6px;
  top: 0;
  transition: 0.2s ease all;
  width: calc(100% - 18px);
  color: ${(p) => p.theme.colors.bodyText2};
  background: ${(p) => p.theme.colors.white};
  padding-left: 12px;
  z-index: 1;
`
export const AreaContainer = styled.div<{
  doesValueExist: boolean
  isDisabled?: boolean
  isError: boolean
}>`
  position: relative;
  overflow: hidden;
  border: 1px solid ${(p) => (p.isError ? p.theme.colors.error : p.theme.colors.borderLight)};
  border-radius: 8px;
  margin-top: 17px;
  ${(p) => p.isDisabled && `pointer-events: none;`}

  &:focus-within {
    ${(props) => {
      if (props.doesValueExist) {
        return
      }
      return css`
        ${Placeholder} {
          transform: translateY(0px);
          ${footnoteMedium};
        }
      `
    }};
  }

  ${(props) => {
    if (props.doesValueExist) {
      return css`
        ${footnoteMedium};
      `
    }
    return css`
      ${Placeholder} {
        transform: translateY(10px);
        ${subHeadMedium};
      }
    `
  }};
`

export const Title = styled.div`
  ${title3};
  padding-bottom: 7px;
`

export const Info = styled.div`
  ${footnoteMedium};
  color: ${(p) => p.theme.colors.bodyText};
`

export const TextArea = styled.textarea<{ withFloatPlaceholder?: boolean }>`
  resize: none;
  display: block;
  ${subHeadMedium};
  background: ${(p) => p.theme.colors.white};
  border: none;
  width: 100%;
  min-height: 100px;
  margin: 0;
  line-height: 1.25;
  padding: ${(p) => (p.withFloatPlaceholder ? '22px 12px' : '9px 12px')};
  ${getKeyboardNavigationStyle({ offset: -3, borderRadius: 8 })}
`

export const Message = styled.div<{ isOpen?: boolean; isError?: boolean }>`
  display: flex;
  ${captionMedium};
  margin-top: 2px;
  color: ${(p) => p.theme.colors.bodyText};
  height: ${(p) => (p.isOpen ? '20px' : '0')};
  transition: height 0.3s;
  line-height: 20px;
  overflow: hidden;
`

export const Error = styled.div`
  color: ${(p) => p.theme.colors.error};
`
