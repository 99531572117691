import { useMemo } from 'react'
import type { Amenity } from '@fiji/graphql/types'

type Props = {
  amenities: Amenity[]
  exclusiveAmenities?: Amenity[] | null
}

const MAX_AMENITIES = 5

export function useAmenities({ amenities, exclusiveAmenities }: Props) {
  const amenityNames = useMemo(() => {
    const amenitiesCombined = [...amenities, ...(exclusiveAmenities || [])]
    const amenitiesSet = new Set(amenitiesCombined.map((amenity) => amenity.name))
    return Array.from(amenitiesSet)
  }, [amenities, exclusiveAmenities])

  const isMore = amenityNames.length > MAX_AMENITIES
  const moreCount = isMore ? amenityNames.length - MAX_AMENITIES : 0

  const slicedAmenityNames = useMemo(() => {
    return amenityNames.slice(0, MAX_AMENITIES)
  }, [amenityNames])

  return {
    amenityNames,
    slicedAmenityNames,
    isMore,
    moreCount,
  }
}
