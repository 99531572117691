import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { useTranslation } from 'react-i18next'
import { useQualtricsContext } from '@etta/modules/qualtrics/interface/use-qualtrics-context'
import { CloseButton, Intercept, InterceptModal } from './qualtrics-intercept.style'

export const QualtricsIntercept = observer(function QualtricsIntercept() {
  const { t } = useTranslation()
  const { qualtricsActions, qualtricsStore } = useQualtricsContext()
  const { modalVisible, portalId } = qualtricsStore

  const onClose = useCallback((): void => {
    qualtricsActions.handleDropStore()
  }, [qualtricsActions])

  return (
    <InterceptModal isVisible={modalVisible} withMaxHeight={false} handleModalVisibility={onClose}>
      <CloseButton
        color="bodyText"
        width={40}
        height={40}
        size="normal"
        onClick={onClose}
        icon="closeFilledPWA"
        aria-label={t('Accessibility.Header.CloseLabel')}
      />
      <Intercept data-tracking-id="qualtrics-intercept" id={portalId} />
    </InterceptModal>
  )
})
