/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useTheme } from 'styled-components'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { getLayoutBackgroundColor } from '@etta/ui/layout'

export function useThemeColor(bgColor?: LayoutBackgroundColor) {
  const theme = useTheme()
  useEffect(() => {
    const meta = document.querySelector('meta[name="theme-color"]')
    if (!meta) {
      return
    }

    const color = getLayoutBackgroundColor(theme, bgColor)
    const usedColor = color === 'transparent' ? theme.colors.white : color
    meta.setAttribute('content', usedColor)
  }, [bgColor])
}
