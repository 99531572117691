import { Action, Inject } from '@etta/di'
import { CreateTripService } from '@etta/modules/review-trip/interface/services/create-trip.service'
import { GetTripService } from '@etta/modules/review-trip/interface/services/get-trip.service'
import { RemoveTripService } from '@etta/modules/review-trip/interface/services/remove-trip.service'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { CheckoutInfoStore } from '@etta/modules/booking/interface/checkout-info/checkout-info.store'
import { UpdateTripNameService } from '../services/update-trip-name.service'

@Action()
export class TripActions {
  constructor(
    @Inject()
    private readonly getTripService: GetTripService,

    @Inject()
    private readonly reviewTripStore: ReviewTripStore,

    @Inject()
    private readonly createTripService: CreateTripService,

    @Inject()
    private readonly updateTripNameService: UpdateTripNameService,

    @Inject()
    private readonly removeTripService: RemoveTripService,

    @Inject()
    private readonly historyStore: HistoryStore,

    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
  ) {}

  async loadTrip() {
    await this.getTripService.loadTrip()
  }

  async updateTrip() {
    await this.getTripService.updateTrip()
  }

  updateTripName(tripName: string) {
    return this.updateTripNameService.updateTripName(tripName)
  }

  async createTrip() {
    await this.createTripService.createTrip()
    return this.reviewTripStore.tripId
  }

  removeTrip() {
    this.removeTripService.removeTrip()
  }

  dropTripStore() {
    this.reviewTripStore.dropTripStore()
  }

  dropTrip() {
    this.removeTrip()
    this.checkoutInfoStore.specialRequests.dropPersistedSpecialRequestData()
    this.dropTripStore()
  }

  setTripIdUrlParams() {
    const { itineraryId } = this.historyStore.getParams<{ itineraryId?: string }>()

    if (itineraryId) {
      this.reviewTripStore.setTripId(itineraryId)
    }
  }

  setTripId(tripId: string) {
    this.reviewTripStore.setTripId(tripId)
  }
}
