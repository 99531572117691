import styled, { css } from 'styled-components'

export const Separator = styled.div`
  width: 1px;
  background: ${(props) => props.theme.colors.borderLight};
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1;
`

export const ButtonsContainer = styled.div<{ shouldCenterItems?: boolean }>`
  position: relative;
  min-height: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  ${({ shouldCenterItems }) =>
    shouldCenterItems &&
    css`
      align-items: center;
    `}
`
