export default function () {
  return (
    <svg
      width="100"
      height="87"
      viewBox="0 0 100 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="43.4043" cy="43.4043" r="43.4043" fill="currentColor" />
      <path
        d="M100 22.5531C100 31.7187 92.5683 39.1489 83.4042 39.1489C74.2384 39.1489 66.8085 31.7187 66.8085 22.5531C66.8085 13.3876 74.2384 5.9574 83.4042 5.9574C92.5683 5.9574 100 13.3876 100 22.5531Z"
        fill="#ECEDEE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.5317 22.6714C98.5317 30.9607 91.8118 37.6812 83.5203 37.6812C75.2305 37.6812 68.5105 30.9607 68.5105 22.6714C68.5105 14.3804 75.2305 7.65991 83.5203 7.65991C91.8118 7.65991 98.5317 14.3804 98.5317 22.6714Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.4086 33.1144C83.5761 33.1144 83.8141 33.0406 84.0433 32.9207C89.1213 30.2272 90.8316 28.7513 90.8316 25.449V18.6691C90.8316 17.4791 90.4261 17.0087 89.4475 16.5751C88.5306 16.1785 85.3569 15.0254 84.4841 14.7303C84.1491 14.6196 83.7436 14.5458 83.4086 14.5458C83.0647 14.5458 82.668 14.6288 82.333 14.7303C81.4514 15.0162 78.2777 16.1877 77.3696 16.5751C76.3822 16.9995 75.9767 17.4791 75.9767 18.6691V25.449C75.9767 28.7513 77.7487 30.1165 82.7738 32.9207C83.003 33.0499 83.2411 33.1144 83.4086 33.1144ZM86.3795 27.7643C86.1062 27.7643 85.8858 27.6628 85.7183 27.4783L83.3997 25.0708L81.0988 27.4783C80.9225 27.6721 80.6932 27.7643 80.4288 27.7643C79.9086 27.7643 79.5031 27.34 79.5031 26.7957C79.5031 26.5467 79.6089 26.3069 79.7852 26.1316L82.1038 23.7056L79.7852 21.2703C79.6089 21.0951 79.5031 20.8552 79.5031 20.6062C79.5031 20.062 79.9086 19.6469 80.4288 19.6469C80.6932 19.6469 80.9136 19.7391 81.0811 19.9236L83.3997 22.3404L85.736 19.9236C85.9211 19.7206 86.1239 19.6376 86.3884 19.6376C86.9085 19.6376 87.3052 20.0527 87.3052 20.597C87.3052 20.8552 87.2171 21.0766 87.0319 21.2611L84.7133 23.7056L87.0319 26.1224C87.1994 26.3069 87.3052 26.5375 87.3052 26.7957C87.3052 27.34 86.8997 27.7643 86.3795 27.7643Z"
        fill="#CA0F1C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.7383 16.5942L42.7872 14.3752L39.8361 16.5942V22.57H39.8474C29.1803 24.0095 20.9535 33.1726 20.9535 44.2645C20.9535 51.7424 24.6936 58.3432 30.396 62.2909L29.1189 63.7846V67.5947H32.9984L35.3447 64.8505C37.6685 65.6952 40.1752 66.1555 42.7872 66.1555C45.3029 66.1555 47.7209 65.7286 49.9721 64.9423L52.6177 67.5947H56.4556V63.7441L55.0763 62.3612C60.8361 58.4218 64.6207 51.787 64.6207 44.2645C64.6207 39.3218 62.986 34.7598 60.2299 31.0955L62.7396 28.5869L62.6744 24.604L58.7065 24.5385L56.2303 27.0136C53.2531 24.6781 49.6549 23.1002 45.7265 22.57H45.7383V16.5942ZM26.621 44.2645C26.621 35.3157 33.8574 28.0585 42.7872 28.0585C51.717 28.0585 58.9534 35.3157 58.9534 44.2645C58.9534 53.2133 51.717 60.4705 42.7872 60.4705C33.8574 60.4705 26.621 53.2133 26.621 44.2645ZM45.2072 33.5978L42.258 30.641L39.3052 33.5855L39.3052 44.23C39.3052 46.1829 40.8839 47.7633 42.8279 47.7633H53.2071L56.1598 44.803L53.207 41.8431H45.2072V33.5978Z"
        fill="white"
      />
    </svg>
  )
}
