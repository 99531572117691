import styled from 'styled-components'

export const PreferenceBoxContainer = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 13px 0 12px;
  position: relative;
  background: ${(p) => (p.isError ? p.theme.colors.error : p.theme.colors.white)};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 40px;
  }
`
