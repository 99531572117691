import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const SeeMoreSection = styled(ReactRouterNavLink)<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 25px 0 27px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    `}
`
