import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  gap: 4px;
`

export const SkeletonWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
`

export const SkeletonLongLine = styled.div`
  max-width: 670px;
  width: 80%;
`

export const InfoBody = styled.div<{ columnView?: boolean }>`
  display: flex;
  flex-direction: ${({ columnView }) => (columnView ? 'column' : 'row')};
  ${({ columnView }) =>
    !columnView &&
    css`
      align-items: center;
    `};
  gap: 8px;
`
