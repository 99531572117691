import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header } from '@etta/ui/header'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Modal } from '@etta/ui/modal/modal'
import { ChangeFlightModal } from '../change-flight-modal'
import { AddFlightModal } from '../add-flight-modal'
import FooterBackground from './assets/footer-background.svg?url'
import { RideHailCustomFlightDetail } from './layout/mobile/ride-hail-custom-flight-detail'
import { RideHailFlightDetails } from './layout/mobile/ride-hail-flight-details'
import { RideHailNoFlightDetails } from './layout/mobile/ride-hail-no-flight-details'
import {
  Title,
  SubTitle,
  DescriptionContainer,
  DescriptionRow,
  DescriptionText,
  IconWrapper,
  BackgroundImage,
  ContinueButtonWrapper,
  Footer,
  TextIconWrapper,
  ScrollContainer,
  RideHailNoFlightContainer,
  IconContainer,
} from './layout/mobile/mobile-components'

const i18nBase = 'Mobility.ReviewFlightDetails'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const ReviewFlightDetailsModal = observer(function ReviewFlightDetailsModal({
  isOpen,
  handleClose,
}: Props) {
  const { t } = useTranslation()
  const { rideHailAirportPickupStore, rideHailAirportPickupActions } = useRideHailSearchContext()
  const hasArrivalFlight = !!rideHailAirportPickupStore.arrivalFlight
  const addFlightModalToggle = useTogglePopup()
  const changeFlightModalToggle = useTogglePopup()

  const openFlightChangeModal = () => {
    changeFlightModalToggle.handleOpen()
    rideHailAirportPickupActions.handleChangeFlight()
  }

  return (
    <Modal
      isVisible={isOpen}
      handleModalVisibility={handleClose}
      backgroundColor="duskBlue"
      position="right"
      withMaxHeight>
      <Screen backgroundColor="duskBlue">
        <Screen.Header>
          <Header
            layout="sub-title"
            backgroundColor="duskBlue"
            leftSlot={
              <IconWrapper onClick={rideHailAirportPickupActions.handleBack}>
                <Icon name="arrowBackPWA" color="duskBlue" />
              </IconWrapper>
            }
          />
        </Screen.Header>
        <Swap
          is={!hasArrivalFlight}
          isSlot={
            <RideHailNoFlightContainer>
              <RideHailNoFlightDetails onOpenAddFlightModal={addFlightModalToggle.handleOpen} />
            </RideHailNoFlightContainer>
          }>
          <Title>{t(`${i18nBase}.Title`)}</Title>
          <SubTitle>{t(`${i18nBase}.SubTitle`)}</SubTitle>
          <ScrollContainer>
            <DescriptionContainer>
              <DescriptionRow>
                <IconContainer>
                  <Icon name="thickCheckMarkPWA" color="primary" size="medium" />
                </IconContainer>
                <DescriptionText showSeparator={true}>
                  {t(`${i18nBase}.RideDescription`)}
                </DescriptionText>
              </DescriptionRow>
              <DescriptionRow>
                <IconContainer>
                  <Icon name="thickCheckMarkPWA" color="primary" size="medium" />
                </IconContainer>
                <DescriptionText showSeparator={false}>
                  {t(`${i18nBase}.WaitTimeDescription`)}
                </DescriptionText>
              </DescriptionRow>
            </DescriptionContainer>
            <Block paddingLeft={20}>
              <Text variant="subHeadStrong" color="white">
                {t(`${i18nBase}.YourFlight`)}
              </Text>
            </Block>
            <Block paddingHorizontal={20} paddingTop={12} paddingBottom={14}>
              <Swap
                is={rideHailAirportPickupStore.isCustomFlight}
                isSlot={
                  <RideHailCustomFlightDetail
                    flightDesignator={rideHailAirportPickupStore.arrivalFlight?.flightDesignator}
                    flightDate={rideHailAirportPickupStore.arrivalFlight?.arrivalDate}
                  />
                }>
                <RideHailFlightDetails
                  flight={rideHailAirportPickupStore.arrivalFlight}
                  isWhiteBackground={false}
                  isSelectable={false}
                  isSelected={false}
                />
              </Swap>
            </Block>
            <Block paddingLeft={20}>
              <Button
                variant="text"
                style={{ color: `rgba(246, 178, 35, 1)` }}
                fullWidth={false}
                paddings={false}
                onClick={openFlightChangeModal}>
                <TextIconWrapper>
                  <Text variant="captionStrongCaps">
                    {t(`${i18nBase}.ChooseOrAddAnotherFlight`)}
                  </Text>
                  <Icon name="chevronRightPWA" size="small" />
                </TextIconWrapper>
              </Button>
            </Block>
          </ScrollContainer>
        </Swap>

        <Footer>
          <BackgroundImage name={FooterBackground} />
          <ContinueButtonWrapper>
            {hasArrivalFlight && (
              <Button
                btnType="primary"
                onClick={rideHailAirportPickupActions.handleContinue}
                fullWidth>
                {t(`${i18nBase}.Continue`)}
              </Button>
            )}
          </ContinueButtonWrapper>
        </Footer>
        <ChangeFlightModal
          onOpenAddFlightModal={addFlightModalToggle.handleOpen}
          onClose={changeFlightModalToggle.handleClose}
          isOpen={changeFlightModalToggle.isOpen}
        />
        <AddFlightModal
          isOpen={addFlightModalToggle.isOpen}
          onClose={addFlightModalToggle.handleClose}
          onSubmit={() => {
            addFlightModalToggle.handleClose()
            changeFlightModalToggle.handleClose()
          }}
        />
      </Screen>
    </Modal>
  )
})
