import { Inject, Store } from '@etta/di'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { FlightSort } from '../../core/enums/flights-sort'
import { FlightsSortBy } from '../../core/enums/flights-sort-by'
import { InitialFlightTimeService } from '../services/get-initial-flight-time.service'
import { CabinClassConfigurationStore } from './configs/cabin-class-config.store'
import { HomeAirportStore } from './configs/home-airport.store'

function getDefaultSort(defaultSort: FlightSort): FlightsSortBy {
  switch (defaultSort) {
    case FlightSort.PolicyOrPrefered:
      return FlightsSortBy.Preference
    case FlightSort.Cost:
      return FlightsSortBy.Cost
    case FlightSort.Departure:
      return FlightsSortBy.Departure
    case FlightSort.Arrival:
      return FlightsSortBy.Arrival
    case FlightSort.Duration:
      return FlightsSortBy.Duration
    default:
      return FlightsSortBy.Preference
  }
}

@Store()
export class AirSearchDefaultValuesStore {
  constructor(
    @Inject() private cabinClassConfiguration: CabinClassConfigurationStore,
    @Inject() private homeAirportStore: HomeAirportStore,
    @Inject() private initialFlightTimeService: InitialFlightTimeService,
    @Inject() private displayConfigurationStore: DisplayConfigurationStore,
  ) {}

  get defaultCabinClass() {
    return this.cabinClassConfiguration.defaultCabinClass
  }

  get defaultSortBy() {
    return getDefaultSort(this.displayConfigurationStore.flightConfigurations.defaultSort)
  }

  get defaultFlightType() {
    return this.displayConfigurationStore.flightConfigurations.defaultAirTravelType ===
      FlightType.OneWay
      ? FlightType.OneWay
      : FlightType.Round
  }

  get initialTimeRange() {
    return this.initialFlightTimeService.timeRange
  }

  get initialReturnTimeRange() {
    return this.initialFlightTimeService.returnTimeRange
  }

  get defaultSearchFlights() {
    const defaultCabinClass = this.cabinClassConfiguration.defaultCabinClass
    return [
      {
        id: -1,
        originPlace: this.homeAirportStore.homeAirport ?? undefined,
        cabinClass: this.cabinClassConfiguration.defaultCabinClass,
        timeRange: this.initialTimeRange,
        returnTimeRange: this.initialReturnTimeRange,
      },
      {
        id: -2,
        cabinClass: defaultCabinClass,
        timeRange: this.initialTimeRange,
        returnTimeRange: this.initialReturnTimeRange,
      },
    ]
  }
}
