import { addMinutesToDate } from '@fiji/utils/dates/dates-calculations'
import { dateToStringWithoutTZ } from '@fiji/utils/dates/date-to-string-without-tz'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'

const ARRIVAL_MINUTES = 30
const RETURN_MINUTES = -90

function changeDate(value: string, minutes: number): string {
  const date = new Date(deleteTimezone(value))

  const changedDate = addMinutesToDate(date, minutes)
  return dateToStringWithoutTZ(changedDate)
}

export function changeArrivalDate(value: string) {
  return changeDate(value, ARRIVAL_MINUTES)
}

export function changeReturnDate(value: string) {
  return changeDate(value, RETURN_MINUTES)
}
