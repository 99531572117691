import { Icon } from '@etta/ui/icon'
import type { Props } from '../../types'
import { ButtonStyled } from './submit-button-desktop-styled'

export const SubmitButtonDesktop = ({ onClick, disabled, label }: Props) => {
  return (
    <ButtonStyled
      data-tracking-id={'header-search-button'}
      aria-label={label}
      disabled={disabled}
      onClick={onClick}>
      <Icon name="searchPWA" size={36} color="mainText2" />
    </ButtonStyled>
  )
}
