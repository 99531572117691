import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'

const i18nBase = 'HotelResultPage.HotelMap'

type Props = {
  onClick: () => void
}

export function SearchAreaButton({ onClick }: Props) {
  const { t } = useTranslation()
  return (
    <Button btnType="secondary" size="small" onClick={onClick}>
      {t(`${i18nBase}.SearchThisArea`)}
    </Button>
  )
}
