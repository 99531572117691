import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { HotelPreferences } from './hotel-preferences'
import type { HotelPreferencesProps } from './types'

type Props = HotelPreferencesProps & {
  isOpen: boolean
}

export function HotelPreferencesModal({ isOpen, onClose, ...props }: Props) {
  return (
    <CheckoutPageModal isVisible={isOpen} onClose={onClose}>
      <HotelPreferences onClose={onClose} {...props} />
    </CheckoutPageModal>
  )
}
