import styled from 'styled-components'

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`

export const Image = styled.img<{ isFullWidth: boolean }>`
  display: block;
  min-height: 143px;

  ${({ isFullWidth }) =>
    isFullWidth &&
    `
      width: 100%;
  `}
`
