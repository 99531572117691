import { Service, Inject } from '@etta/di'
import { AirSearchStore } from '@etta/modules/air-search/interface/stores/air-search/air-search.store'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { SeatMapAdapter } from '@etta/modules/seat-map/infra/seat-map.adapter'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { SeatMapStore } from '../store/seat-map.store'

@Service()
export class SeatMapService {
  constructor(
    @Inject() private readonly reviewTripStore: ReviewTripStore,
    @Inject() private readonly seatMapStore: SeatMapStore,
    @Inject() private readonly seatMapAdapter: SeatMapAdapter,
    @Inject()
    private readonly airSearchStore: AirSearchStore,
  ) {}

  async loadSelectedSeatMap(forceUpdate?: boolean) {
    this.seatMapStore.setIsSeatMapLoading(true)
    this.seatMapStore.setIsSeatMapError(false)
    this.seatMapStore.setSeatRows([])

    const {
      id,
      fares,
      carrierCode,
      departureDate,
      destination,
      flightNumber,
      origin,
    } = this.seatMapStore.selectedSegment
    const fareSegments = fares.map(({ segments }) =>
      segments?.find(({ segmentId }) => segmentId === id),
    )

    const flightInfo = this.airSearchStore.flightLegsFromSearchResult.find(({ segments }) =>
      this.seatMapStore.selectedFlightLeg.legId.includes(segments[0]?.id),
    )

    // use segment id to find fare, as tripId can be empty
    const fareInfo = flightInfo?.fares.find(({ segments }) =>
      segments?.find(({ segmentId }) => segmentId.endsWith(id)),
    )

    const isNDCFare = Boolean(fareInfo?.isNDCFare)
    const providerAttributesStr = fareInfo?.providerAttributesStr

    const result = await this.seatMapAdapter.loadSelectedSeatMap({
      input: {
        bookingCode: fareSegments[0]?.bookingCode || '',
        carrierCode: carrierCode || '',
        departureDate: getFormattedTime(new Date(deleteTimezone(departureDate)), 'yyyy-MM-dd'),
        destination: destination || '',
        flightNumber,
        origin: origin || '',
        equipmentCode: fareSegments[0]?.serviceClass || '',
        logMetadata: {
          itineraryId: this.reviewTripStore.tripId,
        },
        fareBasisCode: fareSegments[0]?.basisCode || '',
        ...(isNDCFare && providerAttributesStr && { isNdc: isNDCFare, providerAttributesStr }),
      },
      forceUpdate,
    })

    result.match({
      Ok: (seatMap) => {
        this.seatMapStore.setIsSeatMapLoading(false)
        this.seatMapStore.setSeatMap(seatMap)
      },
      Err: () => {
        this.seatMapStore.setIsSeatMapLoading(false)
        this.seatMapStore.setIsSeatMapError(true)
      },
    })
  }
}
