import styled from 'styled-components'
import { Modal } from '@etta/ui/modal'

export const ModalBody = styled(Modal.Body)`
  overflow-x: visible;
  overflow-y: visible;
`

export const BodyContainer = styled.div`
  width: 80%;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`
