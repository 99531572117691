import type { Place } from '@fiji/types'
import type { Address } from '@fiji/graphql/types'

export function resolveLocation(address?: Address | null): Place | undefined {
  if (!address) {
    return undefined
  }
  return {
    placeId: '',
    name: address.city + ', ' + (address.stateCode || address.countryCode),
    latitude: address.geocode?.lat,
    longitude: address.geocode?.long,
  }
}
