import type { SeatMapRowSeatValueObject } from '@etta/modules/seat-map/core/value-objects'
import { useSeatFooter } from './use-seat-footer'
import { SeatFooterLayout } from './layout'

type Props = {
  finishAllSeatsSelection?: () => void
  seatsForApprove: Array<SeatMapRowSeatValueObject>
  onSelect: (seat: SeatMapRowSeatValueObject) => void
  isSeatSelectionAvailable: boolean
  isLoading: boolean
  isSeatAssignFailed: boolean
  onSeatAssignFailedContinue: (seat: SeatMapRowSeatValueObject) => void
  selectedSegmentId: string
  onSeatSelectionUnavailableContinue: () => void
  onChangeFooterHeight: (state: number) => void
  isAbsolutePosition?: boolean
  isSeatMapLoading?: boolean
}

export function SeatFooter({
  seatsForApprove,
  onSelect,
  isSeatSelectionAvailable,
  isLoading,
  isSeatAssignFailed,
  onSeatAssignFailedContinue,
  selectedSegmentId,
  onSeatSelectionUnavailableContinue,
  onChangeFooterHeight,
  isAbsolutePosition,
  finishAllSeatsSelection,
  isSeatMapLoading,
}: Props) {
  const {
    containerHeight,
    animateFooter,
    isDesktopFooterVisible,
    isMobileFooterVisible,
    setAnimateFooter,
    containerRef,
    footerBlockSlot,
    desktopFooterHeight,
  } = useSeatFooter({
    selectedSegmentId,
    isSeatAssignFailed,
    seatsForApprove,
    isSeatSelectionAvailable,
    isSeatMapLoading,
    onChangeFooterHeight,
    onSelect,
    isLoading,
    onSeatAssignFailedContinue,
    onSeatSelectionUnavailableContinue,
    finishAllSeatsSelection,
  })

  return (
    <SeatFooterLayout
      containerHeight={containerHeight}
      animateFooter={animateFooter}
      isDesktopFooterVisible={isDesktopFooterVisible}
      isMobileFooterVisible={isMobileFooterVisible}
      isAbsolutePosition={isAbsolutePosition}
      setAnimateFooter={setAnimateFooter}
      containerRef={containerRef}
      footerBlockSlot={footerBlockSlot}
      desktopFooterHeight={desktopFooterHeight}
    />
  )
}
