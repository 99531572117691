import { Store } from '@etta/di'

@Store()
export class EnterCustomExpenseCodeStore {
  readonly maxCustomCodeLength = 64
  private _customExpenseCodeValue: string = ''
  private _isContinueButtonDisabled: boolean = true

  get customExpenseCodeValue() {
    return this._customExpenseCodeValue
  }

  get isContinueButtonDisabled() {
    return this._isContinueButtonDisabled
  }

  setCustomExpenseCodeValue(value: string) {
    this._customExpenseCodeValue = value
  }

  setIsContinueButtonDisabled(value: boolean) {
    this._isContinueButtonDisabled = value
  }

  dropStore() {
    this._customExpenseCodeValue = ''
    this._isContinueButtonDisabled = true
  }
}
