import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { TripTotalBlockMobile } from './mobile'
import { TripTotalBlockDesktop } from './desktop'

export function TripTotalBlockLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<TripTotalBlockMobile {...props} />}
      desktopSlot={<TripTotalBlockDesktop {...props} />}
    />
  )
}
