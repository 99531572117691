import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'

export const Inline = styled(Block)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const Label = styled(Text)`
  margin-left: 6px;
`

export const EditLink = styled(Link)`
  margin-left: auto;
  padding-top: 3px;
`
