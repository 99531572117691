import { useMemo, useEffect } from 'react'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useTripDetails } from '@fiji/hooks/use-trip-details'
import { useBookTripContext } from '@etta/modules/booking/interface/book-trip/use-book-trip.context'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import type { RateValueObject } from '@etta/core/value-objects'
import type { TripConfirm } from '../../types'
import { useTripState } from './use-trip-state'
import { useTripActions } from './use-trip-actions'
import { useTripSegments } from './use-trip-segments'
import { useFailedBookings } from './use-failed-bookings'
import type { Args } from './types'

// Remove the New after the new checkout page is the only checkout page
export function useNewTripConfirmation({
  confirmationType,
  autoCancellationTime,
  totalRate,
  recordLocator,
  onDone,
  onAnotherTrip,
  onShareTripsDetail,
  onReset,
  flightDetails,
  hotelDetails,
  carDetails,
  railDetails,
}: Args) {
  const { getCustomerUberAccountStatusActions } = useRideHailSearchContext()
  useEffect(() => {
    getCustomerUberAccountStatusActions.getCustomerUberAccountStatus()
  }, [getCustomerUberAccountStatusActions])

  const { onChangeViewState, viewState } = useViewState()
  const { actions, failedBookingActions } = useTripActions({
    onDone,
    onAnotherTrip,
    onShareTripsDetail,
    onChangeViewState,
    onReset,
  })
  const { failedSegments, failedBookingReasons } = useFailedBookings({
    failedBookingActions,
    confirmationType,
  })
  const tripState = useTripState({ confirmationType, autoCancellationTime, failedBookingReasons })

  // This hacky code is to exclude price of failed segments.
  // totalRate comes from GQL response and does not have failed segments info,
  // so not trivial to change it in deeper level.
  // consider to put it in useMemo or move to GQL
  let rateOfSuccessBookings: RateValueObject | undefined
  if (totalRate) {
    let primaryAmount = 0,
      secondaryAmount = 0
    if (flightDetails.segments?.length) {
      primaryAmount += flightDetails.tripCost.total.primary.amount
      if (flightDetails.tripCost.total.secondary) {
        secondaryAmount += flightDetails.tripCost.total.secondary.amount
      }
    }
    if (hotelDetails.segments?.length) {
      primaryAmount += hotelDetails.tripCost.total.primary.amount
      if (hotelDetails.tripCost.total.secondary) {
        secondaryAmount += hotelDetails.tripCost.total.secondary.amount
      }
    }
    if (carDetails.segments?.length) {
      primaryAmount += carDetails.tripCost.total.primary.amount
      if (carDetails.tripCost.total.secondary) {
        secondaryAmount += carDetails.tripCost.total.secondary.amount
      }
    }
    if (railDetails.segments?.length) {
      primaryAmount += railDetails.tripCost.total.primary.amount
      if (railDetails.tripCost.total.secondary) {
        secondaryAmount += railDetails.tripCost.total.secondary.amount
      }
    }
    rateOfSuccessBookings = {
      primary: {
        amount: primaryAmount,
        currency: totalRate.primary.currency,
      },
    }
    if (totalRate.secondary) {
      rateOfSuccessBookings.secondary = {
        amount: secondaryAmount,
        currency: totalRate.secondary.currency,
      }
    }
  }

  const tripDetails = useTripDetails({ totalRate: rateOfSuccessBookings, recordLocator })

  const segments = useTripSegments({ flightDetails, hotelDetails, carDetails, railDetails })

  const {
    bookTripStore: { bookedProcessId },
  } = useBookTripContext()

  const tripConfirm: TripConfirm = useMemo(() => {
    return {
      tripState,
      tripDetails,
      actions,
      segments,
      failedBooks: failedSegments,
      viewState,
      bookedProcessId: bookedProcessId ?? '',
    }
  }, [tripState, tripDetails, actions, segments, failedSegments, viewState, bookedProcessId])

  return tripConfirm
}
