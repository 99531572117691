import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Collapsed, Expanded } from './show-more-container-styled'
import { useShowMoreWithAnimation } from './use-show-more-container'

type Props = {
  collapsedSlot: ReactNode
  expandedSlot: ReactNode
  isExpanded: boolean
  isAnimated?: boolean
}

const i18BaseAmenities = 'TripPlanner.FlightAirFares'

export function ShowMoreContainer({ collapsedSlot, expandedSlot, isExpanded, isAnimated }: Props) {
  const { t } = useTranslation()
  const { collapsedRef, expandedRef, minHeight } = useShowMoreWithAnimation({
    isExpanded,
  })

  return (
    <Container
      isAnimated={isAnimated}
      minHeight={minHeight}
      aria-label={
        isExpanded
          ? t(i18BaseAmenities + '.ExpandedAriaLabel')
          : t(i18BaseAmenities + '.CollapsedAriaLabel')
      }>
      <Collapsed isAnimated={isAnimated} ref={collapsedRef} isExpanded={isExpanded}>
        {collapsedSlot}
      </Collapsed>
      <Expanded isAnimated={isAnimated} ref={expandedRef} isExpanded={isExpanded}>
        {expandedSlot}
      </Expanded>
    </Container>
  )
}
