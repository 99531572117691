import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { ProfileBoxMobile } from './mobile'
import { ProfileBoxDesktop } from './desktop'

export function ProfileBoxLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<ProfileBoxMobile {...props} />}
      desktopSlot={<ProfileBoxDesktop {...props} />}
    />
  )
}
