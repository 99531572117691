import type { CurrentPositionModalState, ModalId } from './types'
import { getModalIds } from './get-modal-ids'

export function checkCurrentState(currentId: ModalId): CurrentPositionModalState {
  const modalIds = getModalIds()

  if (!modalIds.length) {
    return 'none'
  }

  const lastId = modalIds[modalIds.length - 1]
  if (!lastId) {
    return 'none'
  }

  if (lastId === currentId) {
    return 'last'
  }

  return 'not-last'
}
