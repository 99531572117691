type Props = {
  isDisabled?: boolean
  onClick?: () => void
}

export function useFilterButton({ isDisabled, onClick }: Props) {
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    onClick?.()
  }

  return {
    handleClick,
  }
}
