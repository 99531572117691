import { useTranslation } from 'react-i18next'
import type { BaggageInfoSegment } from '@fiji/types'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Link } from '@etta/ui/link'
import { filterBaggageType } from '@fiji/utils/baggage-resolvers-and-filters'
import { BaggageFee } from '@etta/components/baggage-fee'
import { ScreenType, useScreenType } from '@fiji/modes'
import {
  Container,
  Section,
  SectionLogo,
  BaggageFeeInfo,
  BaggageSeparator,
} from './baggage-information-modal-styled'

type Props = {
  baggageInfo: BaggageInfoSegment[]
  isOpen: boolean
  onClose: () => void
  isAdaptivePosition?: boolean
}

export function BaggageInformationModal({
  baggageInfo,
  isOpen,
  onClose,
  isAdaptivePosition,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'BaggageInformation.'
  const screenType = useScreenType()

  const modalPosition = isAdaptivePosition && screenType !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      position={modalPosition}
      isVisible={isOpen}
      handleModalVisibility={onClose}
      horizontalDimension="content-760">
      <Header withBorderBottom leftSlot={<Header.BackButton onClick={onClose} />}>
        <Header.Title
          aria-label={t(i18nBase + 'BaggageTitle')}
          title={t(i18nBase + 'BaggageTitle')}
          align="left"
        />
      </Header>
      <Modal.Body>
        <Container screenType={screenType}>
          {baggageInfo.map(({ baggageFees, name, imageUrl }, segmentIndex) => {
            if (!baggageFees) {
              return null
            }

            return (
              <Section key={segmentIndex} screenType={screenType}>
                {imageUrl && (
                  <SectionLogo aria-label={name ?? undefined} screenType={screenType}>
                    <img src={imageUrl} alt={name || 'logo'} title={name || 'logo'} />
                  </SectionLogo>
                )}
                {filterBaggageType(baggageFees.items).map((baggage) =>
                  baggage?.map(
                    (fee, i) =>
                      fee && (
                        <BaggageFee
                          key={'baggage-fee-' + fee.type + i}
                          {...fee}
                          title={'BaggageSections.' + fee.type}
                          iterator={i}
                        />
                      ),
                  ),
                )}
                <BaggageSeparator />
                {baggageFees.carrierUrl && (
                  <BaggageFeeInfo
                    aria-label={`${t(i18nBase + 'DetailedInfo')}. ${t('Accessibility.OpenLink')}`}>
                    {t(i18nBase + 'DetailedInfo')}{' '}
                    <Link target="_blank" size="small" href={baggageFees.carrierUrl}>
                      {name}
                    </Link>
                  </BaggageFeeInfo>
                )}
                <BaggageFeeInfo aria-label={t(i18nBase + 'InfoSize')}>
                  {t(i18nBase + 'InfoSize')}
                </BaggageFeeInfo>
              </Section>
            )
          })}
        </Container>
      </Modal.Body>
    </Modal>
  )
}
