import styled from 'styled-components'
import { footnoteStrong, bodyLarge } from '@fiji/style'
import { Link } from '@etta/ui/link'

export const EquivalencesContainerWrapper = styled.div``

export const EquivalencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  overflow-x: scroll;
  padding: 0px 0px 20px 72px;
`

export const CardContainer = styled.div`
  align-items: center;
  padding: 12px 16px;
  min-width: 305px;
  background: ${(props) => props.theme.colors.background};
  border-radius: 14px;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
export const LeftBlock = styled.div``
export const RightBlock = styled.div``

export const DescriptionBlock = styled.p`
  margin: 5px 0;
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteStrong};
`

export const AmountBlock = styled.p`
  margin: 5px 0;
  ${bodyLarge};
  color: ${(props) => props.theme.colors.mainText};
`

export const EcoCheckEquivalenceCardImageContainer = styled.div`
  max-width: 58px;
`
export const ViewSourceLink = styled(Link)`
  display: inline-block;
`
