import styled, { css } from 'styled-components'

export const PinContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  transform: translate(-10%, -50%);
`

export const Marker = styled.div<{ isPickUp: boolean }>`
  background: ${(p) => p.theme.colors.white};
  width: 16px;
  height: 16px;
  border-radius: ${(p) => (p.isPickUp ? '100%' : '0%')};
  border: 4px solid ${(p) => p.theme.colors.mainText1};
`

export const PulseAnimation = css<{ delay?: string }>`
  box-shadow: 0 0 0px 0.8px ${(p) => p.theme.colors.mainText1};
  animation: ring 1.2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: ${({ delay }) => delay};

  @keyframes ring {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
`

export const PulseBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  opacity: 0;
  z-index: 10;
  ${PulseAnimation}
`
