import styled from 'styled-components'
import { footnoteMedium, headline } from '@fiji/style'

export const BookingConditionsTitle = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${headline};
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 17px 0 30px;
`

export const Item = styled.li`
  line-height: 19px;
  color: ${(p) => p.theme.colors.bodyText};
  & + & {
    margin-top: 20px;
  }

  ${footnoteMedium}
`
