import styled from 'styled-components'
import { Block } from '@etta/ui/block'
import { slideUp, fadeIn } from './transition'

export const Container = styled.div`
  top: -1;
  bottom: 0;
  position: fixed;
  z-index: 20;
  display: inline-block
  width: 100%;
`
export const Content = styled.div`
  padding: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  ${slideUp};
`

export const Header = styled.div`
  padding-bottom: 20px;
  padding-right: 2px;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  ${slideUp};
`

export const Footer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  width: 100%;
  padding: 20px;
  ${fadeIn};
`

export const ImageContainer = styled.div`
  margin-right: 8px;
  width: 64px;
  min-width: 64px;
  height: 64px;
  overflow: hidden;
`

export const Image = styled.img`
  width: 64px;
  height: 64px;
`

export const RideDetailContainer = styled.div`
  width: 100%;
`

export const RideContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
  flex-wrap: wrap;
`

export const RideEstimatePrice = styled.div`
  flex: none;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`

export const RideInformation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const Price = styled.div`
  margin-left: 6px;
  margin-right: 4.5px;
`

export const IconWrapper = styled(Block)`
  alignSelf: 'flex-start';
  paddingTop: '1.5px':
`
