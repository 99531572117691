import type { PropsWithChildren, ReactNode } from 'react'
import { useHeaderFull } from './use-header-full'
import { HeaderFullViewMode } from './header-full-view-mode'
import { HeaderFullEditMode } from './header-full-edit-mode'
import { SearchResultsHeaderBlock } from './search-results-header-block'

type Props = PropsWithChildren<{
  viewSlot?: ReactNode
  editSlot?: ReactNode
  controlSlot?: ReactNode
  onSearch: () => void
  datesLabel?: string
  isDisabled?: boolean
  locationsLabel?: string
  withFilterButton?: boolean
  onFilterClick?: () => void
}>

const SearchResultsHeaderFull = ({
  datesLabel = '',
  isDisabled,
  locationsLabel = '',
  withFilterButton,
  onSearch,
  onFilterClick,
  controlSlot,
  editSlot,
  viewSlot,
}: Props) => {
  const { editModeToggle, handlerSearch } = useHeaderFull({ onSearch })

  return (
    <SearchResultsHeaderBlock
      isEditOpen={editModeToggle.isOpen}
      viewSlot={
        <HeaderFullViewMode
          onEdit={editModeToggle.handleOpen}
          datesLabel={datesLabel}
          locationsLabel={locationsLabel}
          isDisabled={isDisabled}
          onFilterClick={onFilterClick}
          withFilterButton={withFilterButton}>
          {viewSlot}
        </HeaderFullViewMode>
      }
      editSlot={
        <HeaderFullEditMode
          controlSlot={controlSlot}
          onClose={editModeToggle.handleClose}
          onSearch={handlerSearch}>
          {editSlot}
        </HeaderFullEditMode>
      }
    />
  )
}

const SearchResultsHeaderFullNameSpace = Object.assign(SearchResultsHeaderFull, {
  Container: SearchResultsHeaderBlock,
  ViewMode: HeaderFullViewMode,
  EditMode: HeaderFullEditMode,
})

export { SearchResultsHeaderFullNameSpace as SearchResultsHeaderFull }
