import { Action, Inject } from '@etta/di'
import { PostBookingTripStore } from '@etta/modules/post-booking/interface/stores/trip/post-booking-trip.store'
import { ReactivateTripService } from '../services/reactivate-trip.service'
import { ActiveTripStore } from '../stores/active-trip.store'

@Action()
export class ReactivateTripActions {
  constructor(
    @Inject() private readonly reactivateTripService: ReactivateTripService,
    @Inject() private readonly activeTripStore: ActiveTripStore,
    @Inject() private readonly postBookingTripStore: PostBookingTripStore,
  ) {}

  async handleReactivateTrip() {
    const processId = this.postBookingTripStore.bookingId
    return this.reactivate(processId)
  }

  async handleReactivateTripById(processId: string) {
    return this.reactivate(processId)
  }

  private async reactivate(processId?: string) {
    let isReactivateSuccess = true
    if (!processId) {
      return isReactivateSuccess
    }

    // Since currentlyReactivatedID is a value from localStorage and can be Number at runtime
    // we need to cast it into string and to safely compare it's value with processId
    if (processId.toString() === this.activeTripStore.currentlyReactivatedID?.toString()) {
      return isReactivateSuccess
    }

    isReactivateSuccess = await this.reactivateTripService.reactivateTrip(processId)

    if (isReactivateSuccess) {
      this.activeTripStore.setCurrentlyReactivatedID(processId)
    }

    return isReactivateSuccess
  }
}
