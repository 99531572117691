import styled from 'styled-components'
import { footnoteStrong, subHeadMedium, title3 } from '@fiji/style'

export const Container = styled.div`
  width: 64%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TimeContainer = styled.div<{ useRightText?: boolean }>`
  flex-shrink: 0;
  width: 94px;
  text-align: ${({ useRightText }) => (useRightText ? 'right' : 'left')};
`

export const AirportCode = styled.div`
  ${footnoteStrong};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Time = styled.div`
  margin-top: 2px;
  ${title3};
  line-height: 25px;
  color: ${(props) => props.theme.colors.mainText};
`

export const Date = styled.div`
  margin-top: 2px;
  ${subHeadMedium};
  line-height: 20px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const AirportCodeAndNights = styled.div`
  ${footnoteStrong};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
  display: flex;
  justify-content: flex-end;
`

export const ArrivesIn = styled.span`
  ${footnoteStrong};
  line-height: 20px;
  color: ${(props) => props.theme.btnBackgroundHover};
  margin-left: 3px;
`
