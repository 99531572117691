import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { useLottieAnimation } from './use-lottie-animation'
import {
  Container,
  StyledLottieAnimation,
  IdleLottieAnimation,
  Description,
} from './mobility-booking-loading-styled'
import ettaLottieAnimation from './animations/lottie.json'
import kanLottieAnimation from './animations/kan-lottie.json'
import idleAnimation from './animations/idle.json'

const i18nBase = 'Mobility.'

export function MobilityBookingLoading() {
  const { t } = useTranslation()
  const { animationState, setIdleAnimation, setTransitionAnimation } = useLottieAnimation()
  const { searchRideHailStore } = useRideHailSearchContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const isOnDemand = searchRideHailStore.isOnDemandRide

  const loadingDiscription = useMemo(() => {
    if (isOnDemand) {
      return t(i18nBase + 'LoadingOnDemandDescription')
    }
    return t(i18nBase + 'LoadingDescription')
  }, [isOnDemand, t])

  return (
    <Container>
      <StyledLottieAnimation
        ref={setTransitionAnimation}
        src={brandConfigurationStore.isAppleTheme ? kanLottieAnimation : ettaLottieAnimation}
        zIndex={2}
        keepLastFrame
      />
      <IdleLottieAnimation
        ref={setIdleAnimation}
        zIndex={1}
        isVisible={!animationState.isIdlePlayed && animationState.isIdlePlaying}
        src={idleAnimation}
        loop
      />
      <Description>{loadingDiscription}</Description>
    </Container>
  )
}
