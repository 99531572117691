import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Container, SubheadWrapper } from './headline-styled'
import type { Props } from './type'
import { useHeadline } from './use-headline'

const i18nBase = 'PostBooking.TrainDetails'

export function Headline(props: Props) {
  const { t } = useTranslation()
  const { headLineSubTitle, ariaLabel, stationName, arrivalCountryName } = useHeadline(props)

  return (
    <Container>
      <Block aria-label={ariaLabel}>
        <Text variant="title2" color="mainText2" isBlock>
          {t(i18nBase + '.TrainTo', {
            stationName,
          })}
        </Text>
        <Text variant="title2" color="mainText2" isBlock>
          {arrivalCountryName}
        </Text>
      </Block>
      <Block aria-label={headLineSubTitle} marginTop={8}>
        <SubheadWrapper>
          <Text variant="footnoteStrong" color="mainText2" isBlock>
            {headLineSubTitle}
          </Text>
        </SubheadWrapper>
      </Block>
    </Container>
  )
}
