import { useMemo } from 'react'
import { useAppSelector } from '@fiji/store'
import { userSelector } from '@fiji/store/user'
import { featureFlagsSelector } from '@fiji/store/feature-flags'
import type { Delegate } from '@fiji/graphql/hooks'
import { useGetDelegatedUsersQuery } from '@fiji/graphql/hooks'

export function useLoadDelegates(externalData?: Delegate[]) {
  const { customerId, delegatedId, locale } = useAppSelector(userSelector)
  const {
    values: { isDelegatedFlowEnabled },
  } = useAppSelector(featureFlagsSelector)

  const {
    data: delegatedUsersResult,
    loading: isDelegatedUsersLoading,
    refetch: refetchDelegatedUsers,
    error: delegatedUserError,
  } = useGetDelegatedUsersQuery({
    variables: {
      input: {
        locale: locale.replace('-', '_'),
      },
    },
    fetchPolicy: 'network-only',
    skip: !isDelegatedFlowEnabled || !customerId,
  })

  const delegates = useMemo(
    () =>
      externalData && !delegatedId
        ? externalData
        : delegatedUsersResult?.delegatedUsers?.delegatedUsers,
    [delegatedId, externalData, delegatedUsersResult],
  )

  const delegatedUsers: Delegate[] = useMemo(() => {
    return delegates || []
  }, [delegates])

  const delegatedUsersWithoutCustomer: Delegate[] = useMemo(() => {
    return delegatedUsers.filter((user) => Number(user.userId) !== customerId)
  }, [delegatedUsers, customerId])

  const currentUser = useMemo(
    () => delegatedUsers.find((user) => Number(user.userId) === customerId),
    [customerId, delegatedUsers],
  )

  const currentDelegatedUser: Delegate | undefined = useMemo(
    () => delegatedUsers.find((user) => Number(user.userId) === delegatedId),
    [delegatedId, delegatedUsers],
  )

  const currentDelegatedUserFullName = [
    currentDelegatedUser?.firstName,
    currentDelegatedUser?.lastName,
  ]
    .filter(Boolean)
    .join(' ')

  return {
    delegatedUserError,
    isDelegatedUsersLoading,
    refetchDelegatedUsers,
    currentDelegatedUser,
    currentUser,
    delegatedUsers,
    delegatedUsersWithoutCustomer,
    currentDelegatedUserFullName,
  }
}
