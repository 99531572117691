type TrackEventType = {
  event: string
  category?: string
  value?: number | string
}

type PageViewType = {
  companyId: number
  partnerId: number
}

export function trackEvent({ event, category = '', value = '' }: TrackEventType): void {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event,
      category,
      value,
    })
  }
}

export function pushPageView({ companyId, partnerId }: PageViewType): void {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: 'Pageview',
      companyId,
      partnerId,
    })
  }
}
