import { useTranslation } from 'react-i18next'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Container, Flex } from './slider-styled'
import { useSlider } from './use-slider'

type Props = {
  isLoading: boolean
  totalHotels: number
  currentHotels?: number
  slideSize: number
  onNextSlide: () => void
  onPrevSlide: () => void
}

export function Slider({
  isLoading,
  totalHotels,
  slideSize,
  onNextSlide,
  onPrevSlide,
  currentHotels,
}: Props) {
  const { t } = useTranslation()
  const { mainText, currentSlide, maxSlides, handlePrevSlide, handleNextSlide } = useSlider({
    totalHotels,
    slideSize,
    onNextSlide,
    onPrevSlide,
    currentHotels,
  })

  return (
    <Container>
      <Flex>
        <IconButton
          isDisabled={isLoading || currentSlide === 0}
          size="medium"
          icon="arrowBackPWA"
          onClick={handlePrevSlide}
          aria-label={t(
            isLoading || currentSlide === 0
              ? 'Accessibility.DisabledButtonAriaLabel'
              : 'Accessibility.ButtonHotelBack',
            { count: slideSize },
          )}
        />
        <Block marginHorizontal={45}>
          {isLoading ? (
            <Text variant="subHeadStrong" color="bodyText">
              {t('SearchResults.Hotels.Map.SearchingMapArea')}
            </Text>
          ) : (
            <Text variant="subHeadStrong">{mainText}</Text>
          )}
        </Block>
        <IconButton
          isDisabled={isLoading || currentSlide === maxSlides}
          size="medium"
          icon="arrowForwardPWA"
          onClick={handleNextSlide}
          aria-label={t(
            isLoading || currentSlide === maxSlides
              ? 'Accessibility.DisabledButtonAriaLabel'
              : 'Accessibility.ButtonHotelForward',
            { count: slideSize },
          )}
        />
      </Flex>
    </Container>
  )
}
