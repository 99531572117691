import { css } from 'styled-components'
import { calloutMedium } from '@fiji/style/typography'

export const Day = css`
  .CalendarDay {
    ${calloutMedium}
  }

  .CalendarDay__default {
    border: none;
    padding: 0;
    border-top: 1px solid ${(props) => props.theme.colors.borderLight};
    color: ${(props) => props.theme.colors.mainText};
    position: relative;
    background-color: transparent;
  }
  .CalendarDay__default .CalendarDay__aspect {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 100%;
  }
  .CalendarDay__default .CalendarDay__title {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  // only hover when pointer device is such as a mouse
  @media (hover: hover) and (pointer: fine) {
    .CalendarDay__default:hover .CalendarDay__title {
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary2};
      color: ${(props) => props.theme.colors.mainText2};
    }
  }

  .CalendarDay__selected:hover .CalendarDay__title {
    // override day bg color when both selected and on hover to be rendered correctly on mobile
    background-color: ${(props) => props.theme.colors.primary1};
  }

  .CalendarDay__firstDayOfWeek .CalendarDay__title,
  .CalendarDay__lastDayOfWeek .CalendarDay__title {
    background-color: transparent;
    color: ${(props) => props.theme.colors.bodyText};
  }

  .CalendarDay__highlighted_calendar .CalendarDay__title {
    color: ${(props) => props.theme.colors.primary1};
  }

  .CalendarDay__hovered_span .CalendarDay__title,
  .CalendarDay__selected_span:hover .CalendarDay__title,
  .CalendarDay__selected_span .CalendarDay__title {
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.primary2};
    color: ${(props) => props.theme.colors.mainText2};
  }

  .CalendarDay__after_hovered_start:not(.CalendarDay__selected_span) .CalendarDay__title {
    background-color: transparent;
    color: ${(props) => props.theme.colors.mainText};
  }

  .CalendarDay__selected_end .CalendarDay__title,
  .CalendarDay__selected_start .CalendarDay__title {
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary1};
    color: ${(props) => props.theme.colors.mainText2};
  }

  .CalendarDay__hovered_span.CalendarDay__default:hover .CalendarDay__title,
  .CalendarDay__hovered_span.CalendarDay__default .CalendarDay__title {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .CalendarDay__hovered_span:last-child .CalendarDay__title,
  .CalendarDay__hovered_span:last-child:hover .CalendarDay__title {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .CalendarDay__selected_end .CalendarDay__background,
  .CalendarDay__selected_start .CalendarDay__background {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary2};
  }

  .CalendarDay__selected_start .CalendarDay__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  .CalendarDay__selected_end .CalendarDay__background {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .CalendarDay__selected_start .CalendarDay__background {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .CalendarDay__selected_start_no_selected_end .CalendarDay__background {
    border-radius: 50%;
  }

  .CalendarDay__selected_start_in_hovered_span .CalendarDay__background {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .CalendarDay__before_hovered_end.CalendarDay__hovered_span .CalendarDay__title {
    background-color: transparent !important;
    color: ${(props) => props.theme.colors.mainText};
  }

  .CalendarDay__before_hovered_end.CalendarDay__hovered_span.CalendarDay__firstDayOfWeek
    .CalendarDay__title {
    color: ${(props) => props.theme.colors.bodyText};
  }

  .CalendarDay__selected_end.CalendarDay__selected_start .CalendarDay__background {
    border-radius: 50%;
  }
`
