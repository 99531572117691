import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { useCheckoutContext } from './use-checkout-context'
import { CheckoutProvider } from './checkout-context-provider'

type Props = PropsWithChildren<{}>

export const CheckoutContext = observer(function CheckoutContext({ children }: Props) {
  const checkoutContext = useCheckoutContext()

  return <CheckoutProvider value={checkoutContext}>{children}</CheckoutProvider>
})
