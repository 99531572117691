import { useTranslation } from 'react-i18next'
import { ABOUT_GEO_SAFE_SCORES_URL } from '@fiji/constants'
import { Block } from '@etta/ui/block'
import { Link } from '@etta/ui/link'
import { ScoreListItem } from '../../score-list-item'
import { useScoreList, ANIMATION_DURATION } from '../../use-score-list'
import type { LayoutProps } from '../types'
import { Container, List, ListItem } from './score-list-mobile-styled'

const i18nBase = 'HotelSafetyCheck.ConcernScoresList.ScoreList'

export function ScoreListMobile({ isOpen, concernList }: LayoutProps) {
  const { t } = useTranslation()
  const { contentAnimation } = useScoreList({ isOpen })

  return (
    <Container {...contentAnimation}>
      <Block marginTop={16} />
      <List>
        {concernList.map((concern) => (
          <ListItem data-tracking-id={`list-item-concern_${concern.label}`} key={concern.label}>
            <ScoreListItem
              isVisible={isOpen}
              contentAnimationDuration={ANIMATION_DURATION}
              {...concern}
            />
          </ListItem>
        ))}
      </List>
      <Block marginTop={16} marginBottom={8}>
        <Link size="small" href={ABOUT_GEO_SAFE_SCORES_URL}>
          {t(`${i18nBase}.LearnMore`)}
        </Link>
      </Block>
    </Container>
  )
}
