import { Block } from '@etta/ui/block'
import { DynamicSiteMessages } from '@etta/components/dynamic-site-messages/dynamic-site-messages'
import type { LayoutProps } from '../../types'

export function PaymentDynamicSiteMessagesMobile({ dynamicSiteMessages }: LayoutProps) {
  return (
    <Block marginTop={24} marginBottom={16} paddingHorizontal={12}>
      <DynamicSiteMessages messages={dynamicSiteMessages} />
    </Block>
  )
}
