import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import type { Rate, TripCost, SegmentState } from '@fiji/graphql/types'
import { RouteType } from '@fiji/graphql/types'
import { Text, CostContainer } from '@etta/screens/payment-summary/payment-summary-styled'
import { Icon } from '@etta/ui/icon'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { DueTodayCost } from '../due-today-cost'
import { RailCard } from './rail-card'

type Props = {
  segments: TrainSegmentEntity[]
  railTripCost?: TripCost
  segmentState?: SegmentState
  isRailAccreditationEnabled?: boolean
}

const i18nBase = 'PaymentSummary.'

export function RailSegment({
  segments,
  railTripCost,
  segmentState,
  isRailAccreditationEnabled,
}: Props) {
  const { t } = useTranslation()
  const { reviewTripStore } = useReviewTripContext()
  const { appliedRailCardNames } = reviewTripStore
  const railCardName = appliedRailCardNames.length > 0 ? appliedRailCardNames[0] : ''
  const baseSegment = segments[0]
  const returnSegment = segments.length === 2 ? segments[1] : null
  const baseSegmentLegs = baseSegment.segments
  if (!baseSegment.rate) {
    return null
  }

  const isOpenReturn = RouteType.OpenReturn === baseSegment.routeType

  const originAddress = baseSegmentLegs?.[0].departure.stationName
  const destinationAddress = baseSegmentLegs?.[baseSegment?.segments.length - 1].arrival.stationName

  const { mainCost: baseRateCost } = formatRate(baseSegment?.rate, {
    morpheme: 'none',
  })

  const trainTitle = `${originAddress} - ${destinationAddress}`

  const zeroRate: Rate = {
    primary: {
      amount: 0,
      currency: baseSegment?.rate.primary.currency,
    },
  }
  if (baseSegment?.rate.secondary) {
    zeroRate.secondary = {
      amount: 0,
      currency: baseSegment?.rate.secondary.currency,
    }
  }

  const returnLegCost = formatRate(isOpenReturn ? zeroRate : returnSegment?.rate, {
    morpheme: 'none',
  }).mainCost

  return (
    <>
      <CostContainer
        aria-label={t(i18nBase + 'SegmentAccessibilityLabel', {
          name: trainTitle,
          amount: baseRateCost,
        })}>
        <Text isStrong>
          {originAddress} <Icon name={'flightArrowPWA'} size="small" /> {destinationAddress}
        </Text>
        <Text data-tracking-id="base-rate-cost-text">{baseRateCost}</Text>
      </CostContainer>
      {baseSegment.departureDateTime && (
        <CostContainer>
          <Text data-tracking-id="rail-departure-date-time">
            {getFormattedTime(
              new Date(deleteTimezone(String(baseSegment.departureDateTime))),
              'EEE, MMM d, h:mm a',
            )}
          </Text>
        </CostContainer>
      )}
      {baseSegment.departureDateTime && (isOpenReturn || !!returnSegment) && (
        <CostContainer
          aria-label={t(i18nBase + 'SegmentAccessibilityLabel', {
            name: `${destinationAddress} - ${originAddress}`,
            amount: returnLegCost,
          })}>
          <Text isStrong>
            {originAddress} <Icon name={'flightReturnArrowPWA'} size="small" /> {destinationAddress}
          </Text>
          <Text data-tracking-id="base-rate-open-return-text">{returnLegCost}</Text>
        </CostContainer>
      )}
      {returnSegment?.departureDateTime && (
        <CostContainer>
          <Text data-tracking-id="return-rail-departure-date-time">
            {getFormattedTime(
              new Date(deleteTimezone(String(returnSegment.departureDateTime))),
              'EEE, MMM d, h:mm a',
            )}
          </Text>
        </CostContainer>
      )}
      {baseSegment.departureDateTime && isOpenReturn && (
        <CostContainer>
          <Text data-tracking-id="rail-open-return-validity">
            {t(i18nBase + 'Rail.OpenReturnText')}
          </Text>
        </CostContainer>
      )}
      <CostContainer
        aria-label={t(i18nBase + 'BookingFeeAccessibilityLabel', {
          amount: formatRate(railTripCost?.transactionFee || zeroRate, { morpheme: 'none' })
            .mainCost,
        })}>
        <Text data-tracking-id="booking-fees-text">{t(i18nBase + 'BookingFees')}</Text>
        <Text data-tracking-id="booking-fees-price-text">
          {formatRate(railTripCost?.transactionFee || zeroRate, { morpheme: 'none' }).mainCost}
        </Text>
      </CostContainer>
      <CostContainer
        aria-label={t(i18nBase + 'DeliveryFeeAccessibilityLabel', {
          amount: formatRate(railTripCost?.serviceFee || zeroRate, { morpheme: 'none' }).mainCost,
        })}>
        <Text data-tracking-id="delivery-fees-text">{t(i18nBase + 'DeliveryFee')}</Text>
        <Text data-tracking-id="delivery-fees-price-text">
          {formatRate(railTripCost?.serviceFee || zeroRate, { morpheme: 'none' }).mainCost}
        </Text>
      </CostContainer>
      <CostContainer
        aria-label={t(i18nBase + 'TaxesAccessibilityLabel', {
          amount: formatRate(railTripCost?.feesAndTaxes || zeroRate, { morpheme: 'none' }).mainCost,
        })}>
        <Text data-tracking-id="taxes-and-fees-text">{t(i18nBase + 'TaxesAndFees')}</Text>
        <Text data-tracking-id="taxes-and-fees-price-text">
          {formatRate(railTripCost?.feesAndTaxes || zeroRate, { morpheme: 'none' }).mainCost}
        </Text>
      </CostContainer>
      {railCardName && <RailCard railCardName={railCardName} />}
      <DueTodayCost
        rate={railTripCost?.total}
        segmentState={segmentState}
        isPayingNow={!isRailAccreditationEnabled}
        isDesktopTripCostSummary
      />
    </>
  )
}
