import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'
import { BookingConditionsTitle, Item, List } from './booking-conditions-mobile-styled'

export function BookingConditionsMobile({
  isVisible,
  onClose,
  titleText,
  firstConditionText,
  secondConditionText,
  thirdConditionText,
  conditionsLabel,
}: LayoutProps) {
  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose}>
      <Header leftSlot={<Header.CloseButton onClick={onClose} />} withBorderBottom>
        <BookingConditionsTitle aria-label={titleText}>{titleText}</BookingConditionsTitle>
      </Header>
      <Modal.Body aria-label={conditionsLabel}>
        <List>
          <Item>{firstConditionText}</Item>
          <Item>{secondConditionText}</Item>
          <Item>{thirdConditionText}</Item>
        </List>
      </Modal.Body>
    </Modal>
  )
}
