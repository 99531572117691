import { SegmentType } from '@fiji/graphql/types'
import type { AvailablePreferences } from '@fiji/graphql/hooks'
import type { TravelPreferencesValueObject } from '@etta/modules/travel-preferences/core/value-objects/travel-preferences.value-object'
import { getMockedRailMemberships } from './get-mocked-rail-memberships'

type Args = {
  type: SegmentType
  preferenceData: TravelPreferencesValueObject
  availablePreferences?: AvailablePreferences
}

export function getProgramByType({ preferenceData, type, availablePreferences }: Args) {
  switch (type) {
    case SegmentType.Hotel:
      return {
        loyaltyProgram: preferenceData?.hotel.loyaltyProgram,
        options: availablePreferences?.hotelMemberships,
      }
    case SegmentType.CarRental:
      return {
        loyaltyProgram: preferenceData?.carRental.loyaltyProgram,
        options: availablePreferences?.carRentalMemberships,
      }
    case SegmentType.Flight:
      return {
        loyaltyProgram: preferenceData?.flight.loyaltyProgram,
        options: availablePreferences?.airMemberships,
      }
    case SegmentType.Train:
      return {
        loyaltyProgram: preferenceData?.rail.loyaltyProgram,
        options: getMockedRailMemberships(),
      }
  }

  return {
    loyaltyProgram: [],
    options: [],
  }
}
