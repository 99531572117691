import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'

type Props = {
  hours?: number
  minutes?: number
  seconds?: number
}

const HOURS_TIME_FORMAT = 'HH:mm:ss'
const NO_HOURS_TIME_FORMAT = 'mm:ss'

export function formatMinutes(params: Props) {
  if (params.hours) {
    return getFormattedTime(params, HOURS_TIME_FORMAT)
  }

  return getFormattedTime(params, NO_HOURS_TIME_FORMAT)
}
