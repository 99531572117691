import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const HeaderSticky = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
`

export const HotelOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  > ${Text}:last-child {
    margin-left: 4px;
  }
`
