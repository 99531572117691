import { Store } from '@etta/di'
import { ExpenseCodePage, RenderState } from '@etta/modules/ride-hail/interface/types'
import type { RideHailExpenseConfigResponseValueObject } from '../../core/value-objects'

@Store()
export class ExpenseFlowStore {
  currentPage: ExpenseCodePage = ExpenseCodePage.SelectExpenseCode
  shouldCloseExpenseCodeModal: boolean = false

  private _expenseConfig: RideHailExpenseConfigResponseValueObject = {
    expenseEnabled: false,
    customExpenseEnabled: false,
    memoEnabled: false,
    memoRequired: false,
    expenseCodes: [],
  }

  private _isFailureOnFetchingRideHailConfig = false

  private _previousRenderState: RenderState = RenderState.pickUpConfirm

  setExpenseConfig(expenseConfig: RideHailExpenseConfigResponseValueObject) {
    this._expenseConfig = expenseConfig
  }

  setIsFailureOnFetchingRideHailConfig(isFailureOnFetchingRideHailConfig: boolean) {
    this._isFailureOnFetchingRideHailConfig = isFailureOnFetchingRideHailConfig
  }

  setPreviousRenderState(value: RenderState) {
    this._previousRenderState = value
  }

  setCurrentPage(page: ExpenseCodePage) {
    this.currentPage = page
  }

  setShouldCloseExpenseCodeModal(shouldCloseExpenseCodeModal: boolean) {
    this.shouldCloseExpenseCodeModal = shouldCloseExpenseCodeModal
  }

  get expenseConfig() {
    return this._expenseConfig
  }

  get isFailureOnFetchingRideHailConfig() {
    return this._isFailureOnFetchingRideHailConfig
  }

  get previousRenderState() {
    return this._previousRenderState
  }
}
