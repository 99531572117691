import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Tooltip } from '@etta/ui/tooltip'
import { RailPermittedStationsContent } from '@etta/components/rail-permitted-stations/rail-permitted-stations-content'
import type { LayoutProps } from '../../types'
import { useService } from '../../use-service'
import {
  Container,
  Header,
  Heading,
  PriceWrapper,
  Title,
  Footer,
  FareRules,
  Price,
  InfoIconWrapper,
  FareAttributes,
  RailCardContainer,
} from './rail-fare-card-desktop.styled'

const i18Base = 'RailDetails.Fare'

export function RailFareCardDesktop({
  fare,
  fareConditions,
  onSelect,
  onInfoIconClick,
}: LayoutProps) {
  const { t } = useTranslation()
  const {
    price,
    fareName,
    fareAttributes,
    hasPermittedStations,
    permittedOriginStations,
    permittedDestinationStations,
  } = useService({ fare, fareConditions })

  const buttonText = t(i18Base + '.Buttons.Select')
  const buttonAriaLabel = t(i18Base + '.Buttons.SelectFareAria', { label: buttonText })
  const railCardName = fare?.appliedRailCards?.[0]

  return (
    <Container>
      <Header>
        <div role="region">
          <Heading>
            <Title>
              <Text variant="subHeadStrong">{fareName}</Text>
              {fareName && hasPermittedStations && (
                <Tooltip
                  slot={
                    <RailPermittedStationsContent
                      permittedOriginStations={permittedOriginStations}
                      permittedDestinationStations={permittedDestinationStations}
                    />
                  }
                  place="top"
                  displayOn="click"
                  align="right"
                  isWhiteTooltip={true}
                  fixWidth={220}>
                  <InfoIconWrapper>
                    <Icon size={16} name="infoPWA" />
                  </InfoIconWrapper>
                </Tooltip>
              )}
            </Title>
            <PriceWrapper>
              <Price variant="headline" data-tracking-id="fare-price">
                {price}
              </Price>
              <Button
                minWidth={10.8}
                onClick={onSelect}
                size="small"
                disabled={false}
                aria-label={buttonAriaLabel}
                data-tracking-id="add-rail-button">
                {buttonText}
              </Button>
            </PriceWrapper>
          </Heading>
        </div>
      </Header>
      {!!fareAttributes?.length && (
        <FareAttributes>
          {fareAttributes.map((item, index) => (
            <Block key={index} marginTop={4}>
              <Text variant="footnoteMedium" color="bodyText">
                {item}
              </Text>
            </Block>
          ))}
        </FareAttributes>
      )}
      {fareName && (
        <Footer>
          <FareRules
            isBlock
            variant="linkSmall"
            color="primary"
            onClick={() => onInfoIconClick(fareName)}>
            {t(i18Base + '.FareRules')}
          </FareRules>
          {railCardName && (
            <RailCardContainer>
              <Icon name="loyaltyCardPWA" color="success" />
              <Text color="success" variant="footnoteStrong">
                {railCardName}
              </Text>
              <Text color="success" variant="footnoteMedium">
                {t(i18Base + '.Applied')}
              </Text>
            </RailCardContainer>
          )}
        </Footer>
      )}
    </Container>
  )
}
