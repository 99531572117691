import { Action, Inject } from '@etta/di'
import type { AvailableDelegatesEntity } from '../../core/entities/available-delegates.entity'
import { DelegateService } from '../services/delegate.service'
import { DelegateStore } from '../stores/delegate.store'

@Action()
export class DelegateActions {
  constructor(
    @Inject()
    private readonly delegateService: DelegateService,
    @Inject()
    private readonly delegateStore: DelegateStore,
  ) {}

  async loadDelegates() {
    await this.delegateService.getDelegates()
  }

  handleModalOpen() {
    this.delegateService.getDelegates()
    this.delegateStore.toggle.handleOpen()
  }

  handleModalClose() {
    this.delegateStore.toggle.handleClose()
  }

  handleMoreDelegatesModalOpen({ type }: { type: 'delegate' | 'authorizer' }) {
    const { delegates, authorizers } = this.delegateService
    this.delegateStore.moreDelegatesToggle.handleOpen()
    this.delegateStore.setInitialUsers(type === 'delegate' ? delegates : authorizers)
    this.delegateStore.setFilteredUsers(type === 'delegate' ? delegates : authorizers)
    this.delegateStore.setActiveDelegateType(type)
  }

  handleMoreDelegatesModalClose() {
    this.delegateStore.moreDelegatesToggle.handleClose()
    this.delegateStore.setInitialUsers()
    this.delegateStore.setFilteredUsers()
    this.delegateStore.setFilteredUsersSearch()
    this.delegateStore.setActiveDelegateType(null)
  }

  handleRemoveDelegateModalOpen(id?: number | null, type: 'delegate' | 'authorizer' | null = null) {
    if (!id) {
      return
    }
    this.delegateStore.removeDelegateToggle.handleOpen()
    this.delegateStore.setActiveDelegateId(id)
    this.delegateStore.setActiveDelegateType(type)
  }

  handleRemoveDelegateCleanup(isLastDelegate?: boolean) {
    const isDelegate = this.delegateStore.activeDelegateType === 'delegate'
    const data = isDelegate ? this.delegateService.delegates : this.delegateService.authorizers
    this.delegateStore.removeDelegateToggle.handleClose()
    this.delegateStore.setFilteredUsers(data)
    this.delegateStore.setInitialUsers(data)
    this.delegateStore.setFilteredUsersSearch()
    if (isLastDelegate) {
      this.delegateStore.setActiveDelegateId(null)
      this.delegateStore.setActiveDelegateType(null)
      this.handleMoreDelegatesModalClose()
    }
  }

  handleDelegateActionOpen(isDelegate: boolean) {
    this.delegateStore.setIsDelegateAction(isDelegate)
    this.delegateStore.addDelegateToggle.handleOpen()
  }

  handleAvailableDelegatesSearch(query: string) {
    this.delegateStore.setUsersSearch(query)
    if (query.length < 3) {
      this.delegateStore.setSelectedDelegateForUpdate(null)
      return
    }
    this.delegateService.getAvailableDelegates(query)
  }

  handleFilteredUsers(input: string) {
    const lowercaseInput = input.toLowerCase()
    const users = this.delegateStore.initialUsers
    this.delegateStore.setFilteredUsersSearch(input)

    if (!input.length) {
      this.delegateStore.setFilteredUsers(users)
      return
    }

    const filteredString = users?.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(lowercaseInput),
    )
    this.delegateStore.setFilteredUsers(filteredString || [])
  }

  get delegates() {
    return this.delegateStore.delegates
  }

  handleDelegateSelection(delegate: AvailableDelegatesEntity) {
    this.delegateStore.setSelectedDelegateForUpdate(delegate)
  }

  handleDelegateSave() {
    if (!this.delegateStore.selectedDelegateForUpdate) {
      return
    }

    if (this.delegateStore.isDelegateAction) {
      this.delegateService.updateDelegates({
        id: this.delegateStore.selectedDelegateForUpdate.id,
        handleClose: this.handleAddDelegateClose,
      })
    } else {
      this.delegateService.updateAuthorizers({
        id: this.delegateStore.selectedDelegateForUpdate.id,
        handleClose: this.handleAddDelegateClose,
      })
    }
  }

  handleRemoveDelegate() {
    if (!this.delegateStore.activeDelegateId) {
      return
    }
    this.delegateStore.removeDelegateToggle.handleClose()

    if (this.delegateStore.activeDelegateType === 'delegate') {
      this.delegateService.updateDelegates({
        id: this.delegateStore.activeDelegateId,
        handleClose: this.handleRemoveDelegateCleanup,
      })
    } else {
      this.delegateService.updateAuthorizers({
        id: this.delegateStore.activeDelegateId,
        handleClose: this.handleRemoveDelegateCleanup,
      })
    }
  }

  handleAddDelegateClose() {
    this.delegateStore.addDelegateToggle.handleClose()
    this.delegateStore.setSelectedDelegateForUpdate(null)
    this.delegateStore.setIsDelegateAction(false)
    this.delegateStore.setUsersSearch('')
  }
}
