import styled, { css } from 'styled-components'

export const Container = styled.div<{ withAnimation?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  @keyframes easyRenderTabPanel {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${(p) =>
    p.withAnimation
      ? css`
          animation: easyRenderTabPanel 0.2s 1;
        `
      : ''};
`
