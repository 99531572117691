import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { CarRentalPlaceDesktop } from '@etta/screens/car-rental-search-page/car-rental-place/desktop'
import { SegmentDateTime } from '@etta/components/segment-date-time/segment-date-time'
import { SegmentType } from '@fiji/graphql/types'
import { CarLegType } from '@fiji/enums'
import { useCarRentalSearchFormContext } from '@etta/modules/car-rental-search/interface'
import { useCarRentalSearchResultsContextSelector } from '../../car-rental-search-results-provider'

const i18Base = 'TripPlanner.BaseSearch'

export const EditMode = observer(function EditMode() {
  const state = useCarRentalSearchResultsContextSelector((state) => state)
  const { carSearchFormStore } = useCarRentalSearchFormContext()
  const { validationErrors } = carSearchFormStore
  const { t } = useTranslation()

  return (
    <SearchResultsHeaderFull.EditMode
      onClose={carSearchFormStore.editModeToggle.handleClose}
      onSearch={state.applyNewFilters}>
      <CarRentalPlaceDesktop
        onSwitchLocations={state.onSwitchLocations}
        onPickUpPlaceChange={state.onPickUpPlaceChange}
        onDropOffPlaceChange={state.onDropOffPlaceChange}
        placesHistory={state.placesHistory}
        pickUpPlace={state.editPickUpPlace}
        dropOffPlace={state.editDropOffPlace}
        isForSearchHeader
        validationErrors={validationErrors}
      />
      <SegmentDateTime
        segmentType={SegmentType.CarRental}
        type={CarLegType.PickUp}
        onOpenDatePicker={() => {}}
        onOpenTimePicker={() => {}}
        date={state.editDate.date || state.pickUpDate}
        time={state.editPickUpTime}
        onDateSelect={state.onDatesSelect}
        startDateTitle={t(`${i18Base}.Input.PickUp`)}
        endDateTitle={t(`${i18Base}.Input.DropOff`)}
        timePickerTitle={t(`${i18Base}.Time.PickUp`)}
        timePickerLabel={t(`${i18Base}.Input.PickUpTime`)}
        datePicker={state.editDate.date}
        datePickerEnd={state.editDate.dateEnd}
        onApplyTimePicker={state.onPickUpTimeChange}
      />

      <SegmentDateTime
        isDatePickerReturnDate
        segmentType={SegmentType.CarRental}
        type={CarLegType.DropOff}
        onOpenDatePicker={() => {}}
        onOpenTimePicker={() => {}}
        date={state.editDate.dateEnd || state.dropOffDate}
        time={state.editDropOffTime}
        onDateSelect={state.onDatesSelect}
        startDateTitle={t(`${i18Base}.Input.PickUp`)}
        endDateTitle={t(`${i18Base}.Input.DropOff`)}
        timePickerTitle={t(`${i18Base}.Time.DropOff`)}
        timePickerLabel={t(`${i18Base}.Input.DropoffTime`)}
        datePicker={state.editDate.date}
        datePickerEnd={state.editDate.dateEnd}
        onApplyTimePicker={state.onDropOffTimeChange}
        timeErrorMessage={validationErrors.dropOffTime}
      />
    </SearchResultsHeaderFull.EditMode>
  )
})
