import type { ForwardedRef, PropsWithChildren, ReactNode } from 'react'
import { forwardRef } from 'react'
import { SearchElementGroup, ErrorMessage } from './group-styled'

type Props = {
  percentageWidth?: number
  gap?: number
  children?: ReactNode
  errorMessage?: string
  isTwoInputs?: boolean
}

function GroupBase(
  { children, percentageWidth, gap, errorMessage, isTwoInputs }: PropsWithChildren<Props>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <SearchElementGroup
      ref={ref}
      gap={gap}
      percentageWidth={percentageWidth}
      hasError={!!errorMessage}
      isTwoInputs={isTwoInputs}>
      {children}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </SearchElementGroup>
  )
}

export const Group = forwardRef(GroupBase)
