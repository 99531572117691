import type {
  HotelMembershipOption,
  HotelSpecialRequestValue,
  HotelSpecialRequestOption,
  HotelMembershipValue,
} from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import type { PreferenceOptions } from '@etta/modules/booking/core/value-objects/purchase-info-details.value-object'
import type { LoyaltyProgram } from '@fiji/graphql/hooks'

export const mapHotelMembershipOption = (
  hotelMembershipOption: HotelMembershipOption,
): PreferenceOptions => {
  return {
    label: hotelMembershipOption.vendorName || '',
    value: hotelMembershipOption.vendorCode,
  }
}

export const mapHotelSpecialRequestOption = (
  hotelSpecialRequestOption: HotelSpecialRequestOption,
): PreferenceOptions => {
  return {
    label: hotelSpecialRequestOption.name || '',
    value: hotelSpecialRequestOption.value,
  }
}

export const mapHotelSpecialRequestValue = (
  hotelSpecialRequestValue: HotelSpecialRequestValue,
): PreferenceOptions => {
  return { label: hotelSpecialRequestValue.name || '', value: hotelSpecialRequestValue.value }
}

export const mapHotelMembershipValue = (
  hotelMembershipValue: HotelMembershipValue,
): PreferenceOptions => {
  return {
    label: hotelMembershipValue.vendorName || '',
    value: hotelMembershipValue.vendorCode,
  }
}

export const toSpecialRequestValues = (
  codes: string[],
  options: HotelSpecialRequestOption[],
): HotelSpecialRequestValue[] => {
  return options.reduce<HotelSpecialRequestValue[]>((acc, option) => {
    if (codes.includes(option.value)) {
      return [...acc, { name: option.name, value: option.value, id: 'md_hotel_specialRequest' }]
    }
    return acc
  }, [])
}

export const toHotelMembership = (
  programs: LoyaltyProgram[],
  options: HotelMembershipOption[],
  savedHotelMemberShip?: HotelMembershipValue[],
): HotelMembershipValue[] => {
  return options.reduce<HotelMembershipValue[]>((acc, option) => {
    const program = programs.find((program) => program.vendorCode === option.vendorCode)
    const membership = savedHotelMemberShip?.find(
      (membership) => membership.vendorCode === option.vendorCode,
    )
    if (program) {
      return [
        ...acc,
        {
          vendorCode: option.vendorCode,
          vendorName: option.vendorName,
          id: 'md_hotel_membership',
          number: program.number || '',
          appliesToChain: membership?.appliesToChain ?? [],
        },
      ]
    }
    return acc
  }, [])
}
