import { useCallback } from 'react'
import { GOOGLE_MAPS_URL } from '@fiji/constants'
import { CarRentalLocationLayout } from './layout'
import type { CarRentalLocationProps } from './types'

export function CarRentalLocation({ locations }: CarRentalLocationProps) {
  const onAddressClick = useCallback(() => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${GOOGLE_MAPS_URL}${locations.geocode?.lat},${locations.geocode?.lng}`)
  }, [locations.geocode])

  return <CarRentalLocationLayout locations={locations} onAddressClick={onAddressClick} />
}
