import { useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'
import { TripType } from '@etta/core/enums/trip-type.enum'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'

type Params = {
  isDelegatedFlowEnabled: boolean
  handleChangeTab: (value: TripType) => void
}

const i18Base = 'Trips'

export function useTripsMobile({ handleChangeTab, isDelegatedFlowEnabled }: Params) {
  const {
    featureFlagsStore: { flags },
  } = useFeatureFlagsContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isHoldTripAllowed } = displayConfigurationStore
  const { isMobileHeldTripsFlowEnabled } = flags

  const { tripsListStore: ordersStore, tripsListActions } = useTripsListContext()

  useEffect(() => {
    if (!isHoldTripAllowed) {
      return
    }
    if (!isMobileHeldTripsFlowEnabled) {
      return
    }
    tripsListActions.load(TripType.OnHold)
  }, [tripsListActions, isHoldTripAllowed, isMobileHeldTripsFlowEnabled])

  const heldTrips = ordersStore.orders[TripType.OnHold]

  const totalHeldTrips = heldTrips?.totalResults

  const containerRef = useRef<HTMLDivElement | null>(null)

  const { anchorRef, scrollContainerRef } = useHeaderScroll({})
  const { t } = useTranslation()
  const screenType = useScreenType()
  const updateRef = useCallback(
    (node: HTMLDivElement | null) => {
      scrollContainerRef(node)
      containerRef.current = node
    },
    [scrollContainerRef],
  )

  function onChangeTab(tab: TripType) {
    handleChangeTab(tab)
    containerRef.current?.scrollTo({
      top: 0,
    })
  }
  const headerTitle = isDelegatedFlowEnabled ? t(i18Base + '.TitleShort') : t(i18Base + '.Title')
  const isDelegateSwitchEnabled = isDelegatedFlowEnabled && screenType === ScreenType.Mobile

  const heldTripsAriaLabel = t(i18Base + '.HeldTripsAriaLabel', {
    count: totalHeldTrips || 0,
  })

  return {
    headerTitle,
    heldTripsAriaLabel,
    onChangeTab,
    anchorRef,
    updateRef,
    isDelegateSwitchEnabled,
    isMobileHeldTripsFlowEnabled,
    totalHeldTrips,
  }
}
