import { useCallback } from 'react'
import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'

type Props = {
  onChange?(field: string): (value: string) => void
}

export function usePresearchMultiLayerCostAllocation({ onChange }: Props) {
  const { costAllocationActions, costAllocationStore } = useCostAllocationContext()

  const onCostAllocationSubmit = useCallback(
    (segmentId: number) => (val: CostAllocationSubmitValueObject, displayName: string) => {
      costAllocationStore.setSubmittedCostAllocation(segmentId, val)
      costAllocationActions.setActiveCostAllocationDisplayName(Number(segmentId), displayName)
      onChange?.(`${segmentId}_allocation`)?.(val.allocationId)
    },
    [costAllocationActions, costAllocationStore, onChange],
  )

  return {
    onCostAllocationSubmit,
  }
}
