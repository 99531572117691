import { useState, useRef } from 'react'
import type { MutableRefObject, TouchEvent } from 'react'

type Props = {
  onSheetHeightChange?: (transitionProgressInPercent: number) => void
  scrollValue?: number
  scrollContainerRef?: MutableRefObject<HTMLDivElement | null>
}

export function useDraggableBottomSheet({
  onSheetHeightChange,
  scrollValue,
  scrollContainerRef,
}: Props) {
  const sheetRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLDivElement | null>(null)

  const [positionY, setPositionY] = useState<number>(0)
  const [positionYCollapse, setPositionYCollapse] = useState<number>(0)
  const [sheetHeight, setSheetHeight] = useState<number>(0) // keep as PX
  const [deltaY, setDeltaY] = useState<number>(0)
  const [latestDragDirection, setLatestDragDirection] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const initialSheetHeightInVH: number = 50
  const initialHeaderHeightInVH: number = 7.36

  // SET STYLED
  const setStyledHeaderRef = (height: number) => {
    // startDrag = (height - 50)
    // dragStartToEnd = (50 - 7.36)

    const calToPercent =
      ((height - initialSheetHeightInVH) / (initialSheetHeightInVH - initialHeaderHeightInVH)) * 100

    if (headerRef.current) {
      headerRef.current.style.transform = `translateY(${-100 + calToPercent}%)`
      headerRef.current.style.opacity = `1`
    }
  }

  const setStyledSheetRef = (height: number) => {
    if (sheetRef.current) {
      setSheetHeight(convertViewPortHeightToPixels(height))
      sheetRef.current.style.height = `${Math.max(0, Math.min(100, height))}vh`
    }
  }

  // EVENT EXPAND
  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 0) {
      return
    }
    if (sheetRef.current) {
      setSheetHeight(sheetRef.current.clientHeight)
    }

    setTransition(false)
    setPositionY(e.touches[0].pageY)
  }

  const handleOnDrag = (e: TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 0) {
      return
    }
    const currentTouchPosition = e.touches[0].pageY
    if (
      currentTouchPosition > convertViewPortHeightToPixels(initialSheetHeightInVH) ||
      currentTouchPosition < headerRef.current?.clientHeight!
    ) {
      return
    }

    const diffPositionY = positionY - currentTouchPosition

    if (diffPositionY > 0) {
      setLatestDragDirection('UP')
    } else {
      setLatestDragDirection('DOWN')
    }

    const deltaHeight = (diffPositionY / window.innerHeight) * 100

    const convertSheetHeightPXtoVH = convertPixelsToViewPortHeight(sheetHeight)
    const nextDialogHeight =
      ((convertSheetHeightPXtoVH + deltaHeight - initialSheetHeightInVH) /
        (initialSheetHeightInVH - initialHeaderHeightInVH)) *
      100

    setStyledSheetRef(convertSheetHeightPXtoVH + deltaHeight)
    setStyledHeaderRef(convertSheetHeightPXtoVH + deltaHeight)
    onSheetHeightChange?.(nextDialogHeight)
    setPositionY(currentTouchPosition)
    setDeltaY(diffPositionY)
  }

  const handleTouchEnd = () => {
    setTransition(true)
    setPositionY(0)

    const headHeight = headerRef.current?.clientHeight ?? 0
    const sheetRefHeight = parseInt(sheetRef.current?.style.height.replace('vh', '') ?? '')

    // deltaY > 4 use for ensure the minimum change in Y axis
    if (
      (latestDragDirection === 'UP' && deltaY > 4) ||
      (latestDragDirection === 'UP' && sheetRefHeight - initialSheetHeightInVH > 15)
    ) {
      // (full screen - header height) in VH
      setStyledSheetRef(100 - convertPixelsToViewPortHeight(headHeight))
      setStyledHeaderRef(100 - initialHeaderHeightInVH)
      onSheetHeightChange?.(100)
      setIsExpanded(true)

      if (sheetRef.current) {
        sheetRef.current.style.maxHeight = `${window.innerHeight - headHeight}px`
      }

      return
    }
    handleCloseExpand()
  }

  // EVENT COLLAPSE
  const handleTouchStartCollapse = (e: TouchEvent<HTMLDivElement>) => {
    if (!isExpanded) {
      return
    }
    if (e.touches.length === 0) {
      return
    }

    setTransition(false)
    setPositionYCollapse(e.touches[0].pageY)
  }

  const handleOnDragCollapse = (e: TouchEvent<HTMLDivElement>) => {
    if (!isExpanded) {
      return
    }

    if (sheetRef.current?.clientHeight! < convertViewPortHeightToPixels(initialSheetHeightInVH)) {
      return
    }

    const currentTouchPosition = e.touches[0].pageY
    const diffPositionY = positionYCollapse - currentTouchPosition

    if (diffPositionY > 0) {
      setLatestDragDirection('UP')
    } else {
      setLatestDragDirection('DOWN')
    }

    const deltaHeight = (diffPositionY / window.innerHeight) * 100
    const convertSheetHeightPXtoVH = convertPixelsToViewPortHeight(sheetHeight)

    if (scrollContainerRef?.current && sheetRef.current) {
      const maxSheetHeight = parseInt(
        getComputedStyle(sheetRef.current).maxHeight.replace('px', ''),
      )

      scrollContainerRef.current.style.overflowY = `auto`
      if (scrollValue === 0 && sheetRef.current?.clientHeight === maxSheetHeight) {
        if (deltaHeight > 0) {
          return
        }
        setStyledSheetRef(convertSheetHeightPXtoVH + deltaHeight)
        setStyledHeaderRef(convertSheetHeightPXtoVH + deltaHeight)
      }

      if (scrollValue === 0 && sheetRef.current?.clientHeight < maxSheetHeight) {
        setStyledSheetRef(convertSheetHeightPXtoVH + deltaHeight)
        setStyledHeaderRef(convertSheetHeightPXtoVH + deltaHeight)

        scrollContainerRef.current.style.overflowY = 'hidden'
      }
    }

    setPositionYCollapse(currentTouchPosition)
    setDeltaY(diffPositionY)
  }

  const handleTouchEndCollapse = () => {
    if (!isExpanded) {
      return
    }

    if (scrollValue !== 0) {
      return
    }

    setTransition(true)
    setPositionYCollapse(0)

    const headHeight = headerRef.current?.clientHeight ?? 0
    const sheetRefHeight = parseInt(sheetRef.current?.style.height.replace('vh', '') ?? '')

    if (
      (latestDragDirection === 'DOWN' && deltaY < -4) ||
      (latestDragDirection === 'DOWN' && sheetRefHeight - initialSheetHeightInVH < 25)
    ) {
      if (scrollContainerRef?.current) {
        scrollContainerRef.current.style.overflowY = `auto`
      }
      handleCloseExpand()
      return
    }
    setStyledSheetRef(100 - convertPixelsToViewPortHeight(headHeight))
    setStyledHeaderRef(100 - initialHeaderHeightInVH)
    onSheetHeightChange?.(100)
    setIsExpanded(true)
  }

  const handleCloseExpand = () => {
    setLatestDragDirection('DOWN')
    setStyledSheetRef(initialSheetHeightInVH)
    setStyledHeaderRef(initialSheetHeightInVH)
    onSheetHeightChange?.(0)
    setPositionY(0)
    setIsExpanded(false)
  }

  // CONVERT UNIT
  const convertViewPortHeightToPixels = (height: number) => {
    return (window.visualViewport.height * height) / 100
  }

  const convertPixelsToViewPortHeight = (height: number) => {
    return (100 * height) / window.visualViewport.height
  }

  const setTransition = (isTransition: boolean) => {
    if (!sheetRef.current || !headerRef.current) {
      return
    }

    if (isTransition) {
      sheetRef.current.style.transition = 'height 0.5s'
      headerRef.current.style.transition = 'transform 0.5s'
      return
    }
    sheetRef.current.style.transition = 'height 0s'
    headerRef.current.style.transition = 'transform 0s'
  }

  return {
    headerRef,
    sheetRef,
    isExpanded,
    handleTouchStart,
    handleOnDrag,
    handleTouchEnd,
    handleCloseExpand,
    handleTouchStartCollapse,
    handleOnDragCollapse,
    handleTouchEndCollapse,
  }
}
