import type { Address } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

type Args = {
  pickUpAddress: Address
  dropOffAddress: Address
  pickUpPlace: Place
  dropOffPlace: Place
}

export function useLocationsLabel({
  pickUpAddress,
  dropOffAddress,
  pickUpPlace,
  dropOffPlace,
}: Args) {
  const dropOffAirport = dropOffPlace?.airportCode
  const pickUpAirport = pickUpPlace?.airportCode
  const pickupName = pickUpPlace.shortName ?? pickUpPlace.name
  const dropOffName = dropOffPlace.shortName ?? dropOffPlace.name
  const mobilePickUpLabel = pickUpAirport || pickupName
  const mobileDropOffLabel = dropOffAirport || dropOffName
  const desktopPickUpLabel = pickupName || pickUpAirport
  const desktopDropOffLabel = dropOffName || dropOffAirport

  if (!pickUpAddress || !dropOffAddress) {
    return {
      mobileLocationsLabel: `${mobilePickUpLabel} - ${mobileDropOffLabel}`,
      desktopLocationsLabel: `${desktopPickUpLabel} - ${desktopDropOffLabel}`,
    }
  }

  const pickUpAirportText = pickUpAirport ? `(${pickUpAirport})` : ''
  const dropOffAirportText = dropOffAirport ? `(${dropOffAirport})` : ''
  const mobileLocationsLabel = `${pickUpAddress.city} ${pickUpAirportText} - ${dropOffAddress.city} ${dropOffAirportText}`

  const pickUpCountryText = pickUpAddress.stateCode
    ? `${pickUpAddress.stateCode}, ${pickUpAddress.countryCode}`
    : pickUpAddress.countryCode
  const dropOffCountryText = dropOffAddress.stateCode
    ? `${dropOffAddress.stateCode}, ${dropOffAddress.countryCode}`
    : dropOffAddress.countryCode
  const desktopLocationsLabel = `${pickUpAddress.city}, ${pickUpCountryText} - ${dropOffAddress.city}, ${dropOffCountryText}`

  return {
    mobileLocationsLabel,
    desktopLocationsLabel,
  }
}
