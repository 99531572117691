import { HotelDetailsModal } from '@etta/components/hotel-details-modal/hotel-details-modal'
import { Block } from '@etta/ui/block'
import { PriceDetails } from '@etta/components/price-details'
import type { LayoutProps } from '../types'

export function HotelDetailsModalDesktop({
  totalCostTitle,
  hotelDetails,
  priceDetailsList,
  isVisible,
  isError,
  onRetry,
  onClose,
  hotelEmission,
}: LayoutProps) {
  return (
    <HotelDetailsModal
      hotelEmission={hotelEmission}
      isVisible={isVisible}
      onClose={onClose}
      hotelDetails={hotelDetails}
      isError={isError}
      areHotelReviewsDisplayed={false}
      isHotelDescriptionDisplayed={false}
      onRetry={onRetry}>
      {totalCostTitle && (
        <Block marginHorizontal={32} marginVertical={16}>
          <PriceDetails priceDetailsList={priceDetailsList} totalCost={totalCostTitle} />
        </Block>
      )}
    </HotelDetailsModal>
  )
}
