import { Service, Inject } from '@etta/di'
import { SeatMapStore } from '../store/seat-map.store'
import { SeatMapPaginationStore } from '../store/seat-map-pagination.store'
import { SeatMapService } from '../services/seat-map.service'

@Service()
export class SeatMapPaginationService {
  constructor(
    @Inject() private readonly seatMapStore: SeatMapStore,
    @Inject() private readonly seatMapPaginationStore: SeatMapPaginationStore,
    @Inject() private readonly seatMapService: SeatMapService,
  ) {}

  async moveToNextSeatMap() {
    this.closePopup()
    this.seatMapStore.dropSeat()

    if (this.seatMapPaginationStore.isLastSegmentInFlightLeg) {
      this.seatMapStore.modalToggle.handleClose()
      this.seatMapPaginationStore.dropIndexes()
      return
    }

    this.seatMapPaginationStore.moveToNextSeatMap()
    await this.loadSeatMap()
  }

  async handleSelectFlightCard({
    legIndex,
    segmentIndex,
  }: {
    legIndex: number
    segmentIndex: number
  }) {
    this.seatMapPaginationStore.setSelectedLegIndex(legIndex)
    this.seatMapPaginationStore.setSelectedSegmentIndex(segmentIndex)
    this.closePopup()
    this.seatMapStore.dropSeat()
    await this.loadSeatMap()
  }

  private async loadSeatMap() {
    if (
      this.seatMapStore.seatMap?.rows.length ||
      this.seatMapStore.selectedSegment.isInstantPurchaseCarrier
    ) {
      return
    }

    await this.seatMapService.loadSelectedSeatMap()
  }

  private closePopup() {
    if (this.seatMapStore.seatPopup.isOpen) {
      this.seatMapStore.seatPopup.handleClose()
    }
  }
}
