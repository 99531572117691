/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect } from 'react'

type Props = {
  startDateValue: moment.Moment | null
  handleChangeMonth: (value: number) => void
}

export function useInitialRender({ startDateValue, handleChangeMonth }: Props) {
  useEffect(() => {
    if (startDateValue && startDateValue?.month() !== moment().month()) {
      return
    }

    setTimeout(() => handleChangeMonth(3), 0)
  }, [startDateValue])
}
