import styled from 'styled-components'

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 32px;
`

export const AddButtonBlock = styled.div<{ borderRadius: number }>`
  display: flex;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${({ theme }) => theme.colors.white};
  :not(:last-of-type) {
    margin-right: 12px;
  }
`

export const ItineraryBlock = styled.div<{ borderRadius: number }>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`
