import styled from 'styled-components'
import { headline, smallText, captionMedium, footnoteMedium } from '@fiji/style'

export const Footer = styled.div`
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`

export const Status = styled.div`
  ${captionMedium}
  display: flex;
  align-items: center;
`

export const Label = styled.div`
  color: ${(p) => p.theme.colors.mainText};
  margin-left: 2px;
  margin-bottom: -1px;
  ${footnoteMedium}
`

export const Price = styled.div`
  ${smallText}
  color: ${(p) => p.theme.colors.bodyText};
  display: flex;
  align-items: flex-end;
  margin-right: 3px;
`

export const Cost = styled.span`
  margin-right: 4px;
  ${headline}
  color: ${(p) => p.theme.colors.mainText};
  line-height: 0.9;
`
