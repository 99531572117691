import { useCallback } from 'react'
import { PurchasePaymentInformationForm } from '@etta/components/purchase-payment-information-form/purchase-payment-information-form'
import type { BillingParameter, CreditCard, TravelVertical } from '@fiji/graphql/types'
import type { ChosenCardsMap } from '@etta/screens/payment-summary/types'

type Props = {
  billingParameter: BillingParameter
  onClose: () => void
  onSubmit: (data: { travelVertical: TravelVertical; id: string }) => void
  chosenCards: ChosenCardsMap
  isOpen: boolean
  onAddSingleTimeUseCard: (card: Partial<CreditCard>) => void
  singleTimeUseCard?: Partial<CreditCard>
  isVirtualPayEnabled?: boolean
}

export function BillingModal({
  billingParameter,
  onClose,
  onSubmit,
  isOpen,
  chosenCards,
  onAddSingleTimeUseCard,
  singleTimeUseCard,
  isVirtualPayEnabled,
}: Props) {
  const handleSubmit = useCallback(
    (data) => {
      onSubmit({ travelVertical: billingParameter.travelVertical, id: data.id })
      onClose()
    },
    [billingParameter.travelVertical, onClose, onSubmit],
  )

  return (
    <PurchasePaymentInformationForm
      isVirtualPayEnabled={isVirtualPayEnabled}
      billingParameter={billingParameter}
      onClose={onClose}
      onSubmit={handleSubmit}
      chosenCards={chosenCards}
      isOpen={isOpen}
      onAddSingleTimeUseCard={onAddSingleTimeUseCard}
      singleTimeUseCard={singleTimeUseCard}
    />
  )
}
