import i18next from 'i18next'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects/trip-segments/flight-postbooking-value-object'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects/trip-segments/hotel-postbooking.value-object'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects/trip-segments/car-rental-postbooking-value-object'
import type { RateValueObject } from '@etta/core/value-objects'
import type {
  RailTrain as RailTrainInfo,
  HotelRoom as HotelRoomInfo,
  FlightLeg as FlightLegInfo,
  RentalCar as CarRentalInfo,
} from '@etta/screens/trip-confirmation/types'
import { formatPrice } from '@fiji/utils/money/format-price'
import { formatRate } from '@fiji/utils/money/format-rate'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'

const i18nBase = 'PostBooking.TripDetails'

type FlightLegConfirmationInfo = {
  id: string
  title: string
  aircraftWithClass: string
  time: string
} & FlightLegInfo

export function toFlightLegConfirmationInfo(
  segment: FlightPostBookingValueObject,
): FlightLegConfirmationInfo | null {
  if (!segment.segments) {
    return null
  }
  const firstSegment = segment.segments[0]
  const lastSegment = segment.segments[segment.segments.length - 1]

  const connector = ['', i18next.t(`${i18nBase}.To`), ''].join(' ')

  const departureGetDirectionLablel = [
    firstSegment.departure?.address?.city ?? '',
    `(${firstSegment.departure?.airportCode})`,
  ].join(' ')
  const arrivalGetDirectionLablel = [
    lastSegment.arrival?.address?.city ?? '',
    `(${lastSegment.arrival?.airportCode})`,
  ].join(' ')
  const direction = [departureGetDirectionLablel, arrivalGetDirectionLablel].join(connector)

  const departureGetTimeLable =
    tripReviewFormatDate(
      TripReviewDateType.DayMonthDateAndTime,
      deleteTimezone(firstSegment.departureDate || ''),
    ) ?? ''
  const arrivalGetTimeLable =
    tripReviewFormatDate(
      TripReviewDateType.DayMonthDateAndTime,
      deleteTimezone(lastSegment.arrivalDate || ''),
    ) ?? ''
  const time = [departureGetTimeLable, arrivalGetTimeLable].join(' - ')

  const aircrafts = segment.segments.map<string>((value) => {
    return [value.name ?? value.carrierCode, value.flightNumber].join(' ')
  })
  const aircraftLabel = aircrafts.join(', ')
  const aircraftWithClass = [aircraftLabel, firstSegment.serviceClass].filter(Boolean).join(' • ')

  return {
    id: segment.legId ?? 'legId',
    title: direction,
    aircraftWithClass: aircraftWithClass,
    time: time,
    direction,
    aircraft: aircraftLabel,
    confirmation: segment.pnrNumber ?? '',
    cabinClass: firstSegment.serviceClass ?? '',
    isWebFare: firstSegment.bookingProvider === 'TRAVEL_FUSION',
  }
}

type HotelRoomConfirmationInfo = {
  id: string
  title: string
  info: string
  segmentCost?: RateValueObject
} & HotelRoomInfo

export function toHotelRoomConfirmationInfo(
  segment: HotelPostBookingValueObject,
  isPaid: boolean = false,
): HotelRoomConfirmationInfo {
  const { numberOfNights, checkIn, checkOut, name, roomType } = segment
  const roomNights = i18next.t(`${i18nBase}.HotelNights`, { count: numberOfNights })

  const formattedCheckIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkIn)
  const formattedCheckOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, checkOut)
  const dates = [formattedCheckIn, formattedCheckOut].join(' - ')

  const hotelInfo = [dates, roomNights, roomType].filter(Boolean).join(' • ')
  const roomTypeLabel = [numberOfNights, roomType].join(' x ')

  // TODO use primary for both priadd secondary cost in GQL
  let costPrimary = ''
  if (segment.totalCost) {
    costPrimary = formatPrice(segment.totalCost, {
      isInteger: false,
      morpheme: 'postfix',
    })
  }

  return {
    id: segment.id ?? 'hotelId',
    title: name ?? '-',
    info: hotelInfo,
    hotelName: name ?? '-',
    dates: dates,
    nightsCount: numberOfNights,
    confirmation: segment.confirmationNumber ?? undefined,
    roomType: roomTypeLabel,
    payment: {
      title: isPaid
        ? i18next.t(`${i18nBase}.SegmentsHeadline.Payment`)
        : i18next.t(`${i18nBase}.PaymentDueAtHotel`),
      mainTotal: costPrimary,
      secondaryTotal: '',
    },
  }
}

type CarRentalConfirmationInfo = {
  id: string
  pickUpLabel: string
  dropOffLabel: string
  segmentCost?: RateValueObject
} & CarRentalInfo

export function toCarRentalConfirmationInfo(
  segment: CarRentalPostBookingValueObject,
  isPaid: boolean = false,
): CarRentalConfirmationInfo {
  const {
    pickUpLocation,
    pickUpTime,
    dropOffLocation,
    dropOffTime,
    carType,
    carClass,
    vendor,
  } = segment
  const connector = ['', i18next.t(`${i18nBase}.On`), ''].join(' ')

  // make this a function after location type is moved to using value object
  const pickUpStreetAddress = [
    pickUpLocation?.address?.street1,
    pickUpLocation?.address?.city,
    pickUpLocation?.address?.stateCode,
    pickUpLocation?.address?.countryCode,
  ]
    .filter(Boolean)
    .join(', ')
  const pickUpAddress = `${pickUpStreetAddress || '-'}(${
    pickUpLocation?.address?.airportCode || '-'
  })`
  const pickUpTimeFormatted = pickUpTime
    ? tripReviewFormatDate(TripReviewDateType.MonthAndDayAndTime, deleteTimezone(pickUpTime)) ?? ''
    : ''
  const pickUpLabel = [pickUpAddress, pickUpTimeFormatted].join(connector)

  const dropOffStreetAddress = [
    dropOffLocation?.address?.street1,
    dropOffLocation?.address?.city,
    dropOffLocation?.address?.stateCode,
    dropOffLocation?.address?.countryCode,
  ]
    .filter(Boolean)
    .join(', ')
  const dropOffAddress = `${dropOffStreetAddress || '-'}(${
    dropOffLocation?.address?.airportCode || '-'
  })`
  const dropOffTimeFormatted = dropOffTime
    ? tripReviewFormatDate(TripReviewDateType.MonthAndDayAndTime, deleteTimezone(dropOffTime)) ?? ''
    : ''
  const dropOffLabel = [dropOffAddress, dropOffTimeFormatted].join(connector)

  const { mainCost, secondCost } = formatRate(segment.totalCost)

  const { carRentalTitle } = translateCarNameDetails({ carClass, carType })

  return {
    id: segment.carId ?? 'carId',
    title: carRentalTitle,
    pickUpLabel: pickUpLabel,
    dropOffLabel: dropOffLabel,
    segmentCost: segment.totalCost ?? undefined,
    vendorName: vendor?.name,
    pickUp: pickUpLabel,
    dropOff: dropOffLabel,
    payment: {
      title: isPaid
        ? i18next.t(`${i18nBase}.SegmentsHeadline.Payment`)
        : i18next.t(`${i18nBase}.PaymentDueAtPickUp`),
      mainTotal: mainCost,
      secondaryTotal: secondCost,
    },
  }
}

type RailLegConfirmationInfo = {
  id: string
  title: string
} & RailTrainInfo // TODO merge these two types

type TrainStation = {
  departure?: TrainStationInfoValueObject | null
  arrival?: TrainStationInfoValueObject | null
}

export type TrainStationInfoValueObject = {
  stationCode?: string | null
  stationName?: string | null
}

export type RailLegPartialValueObject = {
  id?: string | null
  departureDate?: string | null
  arrivalDate?: string | null
  stations?: TrainStation | null
  allocatedSeat?: RailAllocatedSeatInfo[] | null
}

type RailAllocatedSeatInfo = {
  carriage: string
  seat: string
}

export function toRailLegConfirmationInfo(
  segment: RailLegPartialValueObject,
): RailLegConfirmationInfo {
  // const time = getTimeLabel(segment.departureDate, segment.arrivalDate, ' - ') // TODO move this util function to new arch
  const departureGetTimeLabel =
    tripReviewFormatDate(
      TripReviewDateType.DayMonthDateAndTime,
      deleteTimezone(segment.departureDate || ''),
    ) ?? ''
  const arrivalGetTimeLabel =
    tripReviewFormatDate(
      TripReviewDateType.DayMonthDateAndTime,
      deleteTimezone(segment.arrivalDate || ''),
    ) ?? ''
  const time = [departureGetTimeLabel, arrivalGetTimeLabel].join(' - ')

  // const direction = getDirectionLabel(
  //   segment.departure.stationName,
  //   segment.arrival.stationName,
  //   ' - ',
  // )
  const direction = [
    segment.stations?.departure?.stationName,
    segment.stations?.arrival?.stationName,
  ]
    .filter(Boolean)
    .join('-')

  return {
    id: segment.id ?? 'railLegId',
    title: direction,
    time: time,
    direction,
    carriage: segment.allocatedSeat?.length ? segment.allocatedSeat[0].carriage : undefined,
    seat: segment.allocatedSeat?.length ? segment.allocatedSeat[0].seat : undefined,
  }
}
