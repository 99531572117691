import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatPrice } from '@fiji/utils/money'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'

type Args = {
  vendorCode?: string
}

export function useCarRentalServiceFee({ vendorCode }: Args) {
  const { t } = useTranslation()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { displayConfigurationStore } = useDisplayConfigurationContext()
  const { isServiceFeeEnabled } = featureFlagsStore.flags
  const { getServiceFeeConfiguration } = displayConfigurationStore

  const serviceFeeLabel = useMemo(() => {
    if (!isServiceFeeEnabled || !vendorCode) {
      return
    }

    const serviceFee = getServiceFeeConfiguration.carRentalFares.find((hotelFare) =>
      hotelFare.vendorCodesList.some((vc) => vc === vendorCode),
    )

    if (!serviceFee) {
      return
    }

    const serviceFeesAmount = formatPrice({
      amount: serviceFee.serviceFeesAmount,
      currency: serviceFee.serviceFeesAmountCurrency,
    })

    return t('SearchSegments.CarRental.ServiceFeeLabel', {
      description: serviceFee.description,
      serviceFeesAmount,
      serviceFeesCurrency: serviceFee.serviceFeesAmountCurrency,
    })
  }, [getServiceFeeConfiguration.carRentalFares, isServiceFeeEnabled, t, vendorCode])

  return {
    serviceFeeLabel,
  }
}
