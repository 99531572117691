import { Service } from '@etta/di'
import { setUserToRaygun } from '@fiji/utils/raygun/set-user'
import { rollbar } from '@fiji/rollbar'
import type { UserEntity } from '../../core/entities'

// TODO: move rollbar and raygun into the module
@Service()
export class UserLoggerService {
  configureUserLogger(user: UserEntity) {
    const userUid = `${user.partnerId}/${user.companyId}/${user.customerId}`
    if (window._config.isRaygunEnabled) {
      setUserToRaygun(userUid, user)
    }

    rollbar.configure({
      payload: {
        person: {
          id: userUid,
        },
      },
    })
  }
}
