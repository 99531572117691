import { MediaLayout } from '@etta/ui/media-layout'
import type { PolicyReasonProps } from '../types'

import { PolicyReasonMobile } from './mobile'
import { PolicyReasonDesktop } from './desktop'

export function PolicyReasonLayout(props: PolicyReasonProps) {
  return (
    <MediaLayout
      mobileSlot={<PolicyReasonMobile {...props} />}
      desktopSlot={<PolicyReasonDesktop {...props} />}
    />
  )
}
