import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'

export function useNewCarRentalFiltersModal() {
  const { carFiltersModalStore, carRentalFilterActions } = useCarRentalSearchContext()

  // const handleVisibility =

  return {
    carRentalFilterActions,
    carFiltersModalStore,
  }
}
