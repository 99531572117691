import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Block } from '@etta/ui/block'
import { useUserContext } from '@etta/modules/user'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { ColumnWrap, SpaceBetween, Text } from '../rtp-price-summary/rtp-price-summary-styled'
import { TravelerContainer, AvatarContainer } from './traveler-styled'

const i18nBasePath = 'ReviewTrip.PriceSummary'

type Props = {
  hasBorderTop?: boolean
}

export const TravelerExcerpt = observer(function TravelerExcerpt({ hasBorderTop }: Props) {
  const { userStore } = useUserContext()
  const { postBookingTripStore } = usePostBookingContext()
  const { userNames, delegatedId } = userStore
  const { travelerNames } = postBookingTripStore
  const { initials: userInitials, fullName: userFullName } = userNames
  const { travelerInitials, travelerFullName } = travelerNames || {}
  const { t } = useTranslation()
  const isDelegatedFlowEnabled = !!delegatedId
  const fullName = travelerFullName ? travelerFullName : userFullName
  const initials = travelerInitials ? travelerInitials : userInitials

  return (
    <TravelerContainer isDelegateMode={isDelegatedFlowEnabled} hasBorderTop={hasBorderTop}>
      <SpaceBetween hasAlignItemsCenter>
        <ColumnWrap>
          <Text
            aria-label={`${t(i18nBasePath + '.Traveler')} ${fullName}`}
            variant="footnoteStrong"
            color="bodyText">
            {t(i18nBasePath + '.Traveler')}
          </Text>
          <Block marginTop={2} />
          <Text aria-label={fullName} variant="headline" hasBreakWord>
            {fullName}
          </Text>
        </ColumnWrap>

        <AvatarContainer isDelegateMode={isDelegatedFlowEnabled}>
          <Text variant="headline" textTransform="uppercase" color="mainText2" withoutLineHeight>
            {initials}
          </Text>
        </AvatarContainer>
      </SpaceBetween>
    </TravelerContainer>
  )
})
