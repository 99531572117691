import { Block } from '@etta/ui/block'
import { Skeleton } from '@etta/ui/skeleton'
import { Container } from './footer-skeleton-mobile-styled'

export function FooterSkeletonMobile() {
  return (
    <Container>
      <Block>
        <Skeleton width={137} height={76} variant="secondary1">
          <rect y={16} width="50%" height={14} />
          <rect y={36} width="100%" height={24} />
        </Skeleton>
      </Block>
      <Block>
        <Skeleton width={140} height={76} variant="secondary1">
          <rect y={17} rx={7} ry={7} width="100%" height={43} />
        </Skeleton>
      </Block>
    </Container>
  )
}
