import styled from 'styled-components'
import { body } from '@fiji/style'

export const EmptyState = styled.div`
  display: flex;
  width: 100%;
  margin: 130px auto 0px;
  padding: 0 40px;
  color: ${(props) => props.theme.colors.bodyText};
  ${body};
`
