import { useExpand } from '@fiji/hooks/use-expand/use-expand'
import { ShowMoreContainer } from '@etta/ui/show-more-container'
import type { ShowMoreStationsProps } from '../types'
import { CollapsedStationsView } from './collapsed-stations-view'
import { StationRow } from './station-row'

export function ShowMoreStations({ travelTime, stops, isRtp }: ShowMoreStationsProps) {
  const { isExpanded, toggleExpansion } = useExpand()

  return (
    <ShowMoreContainer
      collapsedSlot={
        <CollapsedStationsView
          isRtp={isRtp}
          travelTime={travelTime}
          stops={stops}
          isExpanded={isExpanded}
          toggleExpansion={toggleExpansion}
        />
      }
      expandedSlot={
        <>
          <CollapsedStationsView
            isRtp={isRtp}
            travelTime={travelTime}
            stops={stops}
            isExpanded={isExpanded}
            toggleExpansion={toggleExpansion}
          />
          {stops?.map((stop, index) => (
            <StationRow isRtp={isRtp} key={index} station={stop} />
          ))}
        </>
      }
      isExpanded={isExpanded}
      isAnimated
    />
  )
}
