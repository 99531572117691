import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'
import { CarRentalDetailsMap } from '@etta/components/car-rental-details-map/car-rental-details-map'
import type { CarRentalCarInteractionValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import { CarRentalMobileSection } from '../car-rental-mobile-section'
import {
  Title,
  Section,
  Description,
  SectionTitle,
  Content,
} from './car-rental-mobile-booking-styled'

type Props = {
  pickUp?: CarRentalCarInteractionValueObject
  dropOff?: CarRentalCarInteractionValueObject
}

export function CarRentalMobileBooking({ pickUp, dropOff }: Props) {
  const i18Base = 'CarRentalDetails'
  const { t } = useTranslation()
  const pickUpDateFormatted = pickUp?.date && dateFormat(new Date(pickUp?.date), "iii', 'MMM dd")
  const pickUpTimeFormatted = tripReviewFormatDate(TripReviewDateType.Time, pickUp?.time)
  const dropOffDateFormatted = dropOff?.date && dateFormat(new Date(dropOff?.date), "iii', 'MMM dd")
  const dropOffTimeFormatted = tripReviewFormatDate(TripReviewDateType.Time, dropOff?.time)

  return (
    <CarRentalMobileSection>
      <CarRentalDetailsMap
        carCoords={{
          lat: pickUp?.address.geocode?.lat ?? 0,
          lng: pickUp?.address.geocode?.long ?? 0,
        }}
      />
      <CarRentalMobileSection.Content>
        <Content>
          <Section>
            <SectionTitle role="heading" aria-level={5}>
              {t(i18Base + '.PickUp')}
            </SectionTitle>
            <Title isStart>
              {pickUpDateFormatted} {t(`${i18Base}.Booking.At`)} {pickUpTimeFormatted}
            </Title>
            <Description>
              {pickUp?.address && formatPickUpDropOffAddress(pickUp?.address)}
            </Description>
          </Section>

          <Section>
            <SectionTitle role="heading" aria-level={5}>
              {t(i18Base + '.DropOff')}
            </SectionTitle>
            <Title>
              {dropOffDateFormatted} {t(`${i18Base}.Booking.At`)} {dropOffTimeFormatted}
            </Title>
            <Description>
              {dropOff?.address && formatPickUpDropOffAddress(dropOff?.address)}
            </Description>
          </Section>
        </Content>
      </CarRentalMobileSection.Content>
    </CarRentalMobileSection>
  )
}
