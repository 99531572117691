import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { ScreenType, useScreenType } from '@fiji/modes'

const i18Base = 'CarRentalFilters'

export function useCarRentalFiltersModalContent() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const {
    carRentalFilterActions,
    carFiltersModalStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { carFiltersToggle } = carFiltersModalStore
  const {
    handleApplySelectedFilters,
    handleResetToDefaultFilters: handleResetToDefaultFiltersAction,
  } = carRentalFilterActions

  const {
    isEqual: isApplyDisabled,
    isDefaultFilterCompanySettingsExist: isShowResetToDefaultFiltersButton,
    isShowResetToDefaultFiltersButton: isResetToDefaultFiltersButtonEnabled,
  } = carFiltersStore

  const modalTitle = t(`${i18Base}.Title`)
  const resetToDefaultFiltersLabel = t(`${i18Base}.ResetToDefaultFilters`)
  const resetToDefaultFiltersAriaLabel = useMemo(() => {
    return isResetToDefaultFiltersButtonEnabled
      ? t(`${i18Base}.ResetToDefaultFiltersAccessibilityLabel`)
      : t(`${i18Base}.ResetToDefaultFiltersAccessibilityLabelDisabled`)
  }, [isResetToDefaultFiltersButtonEnabled, t])
  const clearAllLabel = t('CarRentalFilters.Buttons.ClearAll')
  const applyFiltersLabel = t('CarRentalFilters.Buttons.ApplyFilters')

  const handleResetToDefaultFilters = useCallback(() => {
    if (isResetToDefaultFiltersButtonEnabled) {
      carFiltersToggle.handleClose()
      handleResetToDefaultFiltersAction()
    }
  }, [isResetToDefaultFiltersButtonEnabled, carFiltersToggle, handleResetToDefaultFiltersAction])

  function handleClearAllFilters() {
    carRentalFilterActions.clearHandlers.clearAllSelectedFilters()
  }

  function handleApplyFilters() {
    handleApplySelectedFilters()
  }

  return {
    modalTitle,
    handleResetToDefaultFilters,
    resetToDefaultFiltersLabel,
    resetToDefaultFiltersAriaLabel,
    clearAllLabel,
    applyFiltersLabel,
    handleClearAllFilters,
    handleApplyFilters,
    isApplyDisabled,
    isShowResetToDefaultFiltersButton,
    isResetToDefaultFiltersButtonEnabled,
    isMobile,
  }
}
