import * as yup from 'yup'
import i18n from 'i18next'
import type { Props } from '../types'
import { getBaseScheme } from './get-base-scheme'
import { getDecimalLength } from './get-decimal-length'

const i18nBase = 'AdditionalInfoForm.'

export const getNumberScheme = ({ customField }: Props) => {
  let yupScheme = yup.string()

  if (customField.numberDisplay?.decimalPlaces) {
    yupScheme = yupScheme.test(
      'test-decimal-places',
      i18n.t(`${i18nBase}Errors.DecimalPlaces`, {
        number: customField.numberDisplay.decimalPlaces,
      }),
      (value) => {
        if (!value) {
          return true
        }

        if (!customField.numberDisplay?.decimalPlaces) {
          return true
        }

        return getDecimalLength(Number(value)) <= customField.numberDisplay.decimalPlaces
      },
    )
  }

  if (customField.numberDisplay?.minValue) {
    yupScheme = yupScheme.test(
      'test-min-number',
      i18n.t(`${i18nBase}Errors.MinNumber`, { number: customField.numberDisplay.minValue }),
      (value) => Number(value) >= customField.numberDisplay!.minValue!,
    )
  }

  if (customField.numberDisplay?.maxValue) {
    yupScheme = yupScheme.test(
      'test-max-number',
      i18n.t(`${i18nBase}Errors.MaxNumber`, { number: customField.numberDisplay.maxValue }),
      (value) => Number(value) <= customField.numberDisplay!.maxValue!,
    )
  }

  if (
    customField.numberDisplay?.maxValue &&
    customField.numberDisplay?.minValue &&
    customField.numberDisplay?.maxValue === customField.numberDisplay?.minValue
  ) {
    yupScheme = yupScheme.test(
      'test-valid-number',
      i18n.t(`${i18nBase}Errors.InvalidNumber`, { number: customField.numberDisplay.maxValue }),
      (value) => Number(value) === customField.numberDisplay!.maxValue!,
    )
  }

  return getBaseScheme({ scheme: yupScheme, customField })
}
