import { ComplexPickUpType } from '@etta/modules/ride-hail/core'
import { Action, Inject } from '@etta/di'
import { FeatureFlagsStore } from '@etta/modules/feature-flags/interface/stores/feature-flags.store'
import { SearchRideHailStore } from '@etta/modules/ride-hail/interface/store/search-ride-hail.store'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import type { RideHailAirportPickupFlightValueObject } from '@etta/modules/ride-hail/core/value-objects/ride-hail-airport-pickup.value-object'
import { RideHailAirportPickupService } from '../services/ride-hail-airport-pickup-service'
import { RideHailAirportPickupStore } from '../store/ride-hail-airport-pickup-store'

@Action()
export class RideHailAirportPickupActions {
  constructor(
    @Inject() private readonly rideHailAirportPickupService: RideHailAirportPickupService,
    @Inject() private readonly rideHailAirportPickupStore: RideHailAirportPickupStore,
    @Inject() private readonly searchRideHailStore: SearchRideHailStore,
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore,
  ) {}

  dropStore() {
    this.rideHailAirportPickupStore.dropStore()
  }

  handleBack() {
    this.searchRideHailStore.setRenderState(RenderState.whereTo)
    this.rideHailAirportPickupStore.dropStore()
    this.searchRideHailStore.dropSearchRideHailForm()
  }

  async handleStartSearch() {
    const { isReserveAirportPickupEnabled } = this.featureFlagsStore.flags
    const {
      pickUpPlace,
      dropOffPlace,
      pickUpDate,
      pickUpTime,
    } = this.searchRideHailStore.searchRideHailForm
    const isOnDemand = !pickUpDate && !pickUpTime
    if (
      this.rideHailAirportPickupService.checkComplexPickUp(pickUpPlace, dropOffPlace) ===
        ComplexPickUpType.Airport &&
      isReserveAirportPickupEnabled &&
      !isOnDemand
    ) {
      this.searchRideHailStore.setRenderState(RenderState.loading)
      await this.rideHailAirportPickupService.loadFlightAirportPickup()

      if (this.rideHailAirportPickupStore.isError) {
        return
      }

      this.searchRideHailStore.setRenderState(RenderState.reserveAirportPickUp)
      return
    }
    this.searchRideHailStore.setRenderState(RenderState.searchResult)
  }

  handleSelectFlight(flight: RideHailAirportPickupFlightValueObject | null) {
    this.rideHailAirportPickupStore.setArrivalFlight(flight)
    this.rideHailAirportPickupStore.setIsCustomFlight(false)
  }

  async handleChangeFlight() {
    await this.rideHailAirportPickupService.getUpcomingFlightsList()
  }

  handleContinue() {
    this.searchRideHailStore.setRenderState(RenderState.searchResult)
  }

  handleAddCustomFlight(flightDate: Date, flightDesignator: string) {
    this.rideHailAirportPickupService.selectFlight(
      flightDate,
      flightDesignator,
      this.searchRideHailStore.searchRideHailForm.pickUpPlace?.airportCode,
    )
  }

  handleCancelFlightAirportPickupRequest() {
    this.searchRideHailStore.setRenderState(RenderState.whereTo)
    this.rideHailAirportPickupService.cancelFlightAirportPickupRequest()
  }
}
