import type { Vendor } from '@fiji/graphql/types'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useVendorDetails } from './use-vendor-details'
import {
  Logo,
  Container,
  PhoneAndAddressContainer,
  DetailsWrapper,
  VerticalSeparator,
  PhoneAndTimeWrapper,
} from './vendor-details-styled'

type Props = {
  vendor?: Vendor | null
  carRental: CarRentalPostBookingValueObject
}

export function VendorDetails({ vendor, carRental }: Props) {
  const {
    workingHoursText,
    phone,
    title,
    location,
    workingHours,
    callEnterpriseTranslation,
    deskTimingsTranslation,
    vendorAriaLabel,
  } = useVendorDetails({ vendor, carRental })
  return (
    <Container aria-label={vendorAriaLabel}>
      {vendor?.imageUrl && <Logo src={vendor.imageUrl} alt={title} aria-label={vendor.name} />}
      <Text variant="footnoteMedium">{location}</Text>
      <Block marginTop={16} marginBottom={10}>
        <Separator lineType="dashed" color="borderDark" />
      </Block>
      <PhoneAndAddressContainer>
        {phone?.number && (
          <>
            <DetailsWrapper data-tracking-id="car-rental-trip-segment-phone-container">
              <Link href={`tel:${phone.number}`}>
                <PhoneAndTimeWrapper>
                  <Text variant="captionMedium" color="bodyText">
                    {callEnterpriseTranslation}
                  </Text>
                  <Text variant="subHeadStrong" color="mainText">
                    {phone.number}
                  </Text>
                </PhoneAndTimeWrapper>
              </Link>
              <Link href={`tel:${phone.number}`}>
                <Icon name="phonePWA" color="bodyText" size="medium" />
              </Link>
            </DetailsWrapper>
          </>
        )}
        {phone?.number && workingHours && <VerticalSeparator />}
        {workingHours && (
          <DetailsWrapper>
            <PhoneAndTimeWrapper>
              <Text variant="captionMedium" color="bodyText">
                {deskTimingsTranslation}
              </Text>
              <Text variant="subHeadStrong">{workingHoursText}</Text>
            </PhoneAndTimeWrapper>
            <Icon name="timePWA" color="bodyText" size="medium" />
          </DetailsWrapper>
        )}
      </PhoneAndAddressContainer>
    </Container>
  )
}
