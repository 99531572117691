import { Skeleton } from '@etta/ui/skeleton'

export function CovidInformationHeaderLearnMoreSkeleton() {
  return (
    <Skeleton width="72" height="11">
      <rect width="100%" height="100%" />
    </Skeleton>
  )
}

export function CovidInformationHeaderUpdatedSkeleton() {
  return (
    <Skeleton width="125" height="19">
      <rect width="100%" height="13" y="5" />
    </Skeleton>
  )
}
