import { useTranslation } from 'react-i18next'
import type { TimeRange } from '@fiji/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { ScreenType, screenMatcher, useScreenType } from '@fiji/modes'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'

export function useTimeUtils() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const getTimeLabel = (time: TimeRange) => {
    if (!isMobile) {
      return time.id === TimeRangeOption.CustomTime ? '.Input.LeavingAround' : '.Input.ReturnTime'
    }

    return time.timeRangeBy === TimeRangeDirection.Departure ? '.Input.Leaving' : '.Input.Arriving'
  }

  const getTimeValue = (time: TimeRange, isShortCustomTime?: boolean) => {
    const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
    if (time.id === TimeRangeOption.AnyTime) {
      return `${t(time.i18next)}`
    }
    if (time.id !== TimeRangeOption.CustomTime) {
      const startTime = getFormattedTime(
        {
          hours: time.startTimeHours!,
        },
        'haaa',
      )
      const endTime = getFormattedTime(
        {
          hours: time.endTimeHours!,
        },
        'haaa',
      )
      if (isMobile) {
        return (
          <div>
            <div>{t(time.i18next)}</div>
            <div>{`(${startTime} - ${endTime})`}</div>
          </div>
        )
      }

      return `${startTime} - ${endTime}`
    }
    if (time.id === TimeRangeOption.CustomTime) {
      const customTime = getFormattedTime(
        {
          hours: time.customTimeHours!,
        },
        'h:mmaaa',
      )
      if (!isShortCustomTime) {
        return `${t('FlightSearch.TimePicker.Around')} ${customTime}`
      }

      return customTime
    }
  }

  const getTimeValueString = (time: TimeRange, isShortCustomTime?: boolean): string | undefined => {
    const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
    if (time.id === TimeRangeOption.AnyTime) {
      return t(time.i18next)
    }
    if (time.id !== TimeRangeOption.CustomTime) {
      const startTime = getFormattedTime(
        {
          hours: time.startTimeHours!,
        },
        'haaa',
      )
      const endTime = getFormattedTime(
        {
          hours: time.endTimeHours!,
        },
        'haaa',
      )
      if (isMobile) {
        return `(${startTime} - ${endTime})`
      }

      return `${startTime} - ${endTime}`
    }
    if (time.id === TimeRangeOption.CustomTime) {
      const customTime = getFormattedTime(
        {
          hours: time.customTimeHours!,
        },
        'h:mmaaa',
      )
      if (!isShortCustomTime) {
        return `${t('FlightSearch.TimePicker.Around')} ${customTime}`
      }

      return customTime
    }
  }

  const convertDateToString = (date?: Date | null) => {
    return date ? dateFormat(date, 'iii, MMM dd') : ''
  }

  return {
    getTimeLabel,
    getTimeValue,
    getTimeValueString,
    convertDateToString,
  }
}
