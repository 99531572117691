import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

type Params = {
  isCancelled: boolean
  confirmationNumber?: string | null
  recordLocator?: string | null
  rentalVendorName?: string | null
  travelerName: string
}

const i18nBase = 'PostBooking.TripCarTravel'

export function useTripCarTraveler({
  isCancelled,
  confirmationNumber,
  recordLocator,
  rentalVendorName,
  travelerName,
}: Params) {
  const { t } = useTranslation()
  const type = useScreenType()
  const { brandConfigurationStore } = useBrandConfigurationContext()

  const topAriaLabel = useMemo(
    () =>
      `${t(`${i18nBase}.Traveler`)}: ${travelerName}. ${t(
        `${i18nBase}.RecordLocator`,
      )}: ${recordLocator}`,
    [t, travelerName, recordLocator],
  )
  const bottomAriaLabel = useMemo(
    () => `${rentalVendorName} ${t(`${i18nBase}.Confirmation`)}: ${confirmationNumber}`,
    [t, rentalVendorName, confirmationNumber],
  )

  const translations = useMemo(() => {
    return {
      cancelledBookTranslation: t('PostBooking.TripDetails.CanceledBook'),
      confirmationNumberTranslation: `${rentalVendorName} ${t(`${i18nBase}.Confirmation`)}`,
      recordLocatorTranslation: brandConfigurationStore.isAppleTheme
        ? t(`${i18nBase}.AppleTravelReference`)
        : t(`${i18nBase}.RecordLocator`),
      travelerTranslation: t(`${i18nBase}.Traveler`),
    }
  }, [t, rentalVendorName, brandConfigurationStore])

  const verticalPadding = useMemo(() => (isCancelled && type === ScreenType.Desktop ? 20 : 16), [
    isCancelled,
    type,
  ])
  const horizontalPadding = useMemo(() => (type === ScreenType.Desktop ? 20 : 16), [type])

  return {
    topAriaLabel,
    bottomAriaLabel,
    translations,
    verticalPadding,
    horizontalPadding,
  }
}
