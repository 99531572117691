import type { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'

type Props = {
  onFinishSelectionClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export function FinishSeatSelectionButton({ onFinishSelectionClick, disabled }: Props) {
  const { t } = useTranslation()

  if (!onFinishSelectionClick) {
    return null
  }

  return (
    <Button disabled={!!disabled} onClick={onFinishSelectionClick} size="normal" variant="outline">
      {t('AirSeatMap.DoneWithSeatSelection')}
    </Button>
  )
}
