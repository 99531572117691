import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { MediaLayout } from '@etta/ui/media-layout'
import { DesktopScreenByFeatureFlag } from '@etta/components/desktop-screen-by-feature-flag/desktop-screen-by-feature-flag'
import type { LayoutProps } from '../types'
import { PurchaseAdditionalInfoFormDesktop } from './desktop'
import { PurchaseAdditionalInfoFormMobile } from './mobile'

export function PurchaseAdditionalInfoFormLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={
        <DesktopScreenByFeatureFlag
          isDesktopLayoutEnabled={featureFlags.isDesktopLayoutPurchaseFlowEnabled}
          isTripCartDisplayed={false}>
          <PurchaseAdditionalInfoFormMobile {...props} />
        </DesktopScreenByFeatureFlag>
      }
      desktopSlot={<PurchaseAdditionalInfoFormDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutPurchaseFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
