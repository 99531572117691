import { observer } from 'mobx-react-lite'
import { useTripsListContext } from '@etta/modules/post-booking-list/interface/user-orders.context'
import { useNetworkStatus } from '@fiji/hooks/use-network-status'
import type { Props } from './types'
import { TripListContentLayout } from './layout'

export const TripListContent = observer(function TripListContent({
  tripType,
  view,
  bannerSlot,
}: Props) {
  const { tripsListStore: ordersStore, tripsListActions } = useTripsListContext()

  const { isOnline } = useNetworkStatus()

  const { orders, isFetchMoreLoading } = ordersStore
  return (
    <TripListContentLayout
      groups={orders[tripType]?.groups}
      tripType={tripType}
      bannerSlot={bannerSlot}
      withAnimation={false}
      view={view}
      handleFetchMore={() => tripsListActions.fetchMore(tripType)}
      hasNextPage={isOnline && (orders[tripType]?.hasNextPage || false)}
      isFetchMoreLoading={isFetchMoreLoading[tripType]}
    />
  )
})
