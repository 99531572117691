import { useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { BillingParameter } from '@fiji/graphql/types'
import type { CreditCardType } from '../types'

type Props = {
  flightBilling?: BillingParameter
  carRentalBilling?: BillingParameter
  hotelBilling?: BillingParameter
  railBilling?: BillingParameter
}

function getBillingByType({
  billingModalType,
  carRentalBilling,
  hotelBilling,
  flightBilling,
  railBilling,
}: Props & { billingModalType: CreditCardType | null }) {
  switch (billingModalType) {
    case 'car':
      return carRentalBilling
    case 'flight':
      return flightBilling
    case 'hotel':
      return hotelBilling
    case 'rail':
      return railBilling
    default:
      return null
  }
}

export function useBillingModal({
  carRentalBilling,
  hotelBilling,
  flightBilling,
  railBilling,
}: Props) {
  const billingModalToggle = useTogglePopup()
  const [billingModalType, setBillingModalType] = useState<CreditCardType | null>(null)

  const onBillingModalOpen = (type: CreditCardType) => {
    setBillingModalType(type)
    billingModalToggle.handleOpen()
  }

  const billingParams = getBillingByType({
    billingModalType,
    carRentalBilling,
    hotelBilling,
    flightBilling,
    railBilling,
  })

  return {
    billingModalToggle,
    onBillingModalOpen,
    billingParams,
    setBillingModalType,
  }
}
