import { Fragment } from 'react'
import type { HeaderColors } from '@etta/ui/header'
import type { Message } from '../../types'
import { Anchor } from '../anchor'
import { MessageItem } from './message-item'

type Props = {
  messages: Message[]
  anchorId: string | null
  color: HeaderColors
  hasMultipleItems: boolean
  shouldExpandMessages: boolean
  onClose: (id: string) => void
  handleSetAnchor: (node: HTMLDivElement | null) => void
}

export function MessagesList({
  messages,
  anchorId,
  color,
  hasMultipleItems,
  shouldExpandMessages,
  onClose,
  handleSetAnchor,
}: Props) {
  if (!messages.length) {
    return null
  }

  return (
    <>
      {messages.map(({ id, text, header, hasDesktopBannerImage }) => (
        <Fragment key={id}>
          <Anchor messageId={id} anchorId={anchorId} handleSetAnchor={handleSetAnchor} />
          <MessageItem
            key={id}
            title={header}
            message={text}
            color={color}
            hasMultipleItems={hasMultipleItems}
            hasDesktopBannerImage={!!hasDesktopBannerImage}
            shouldExpandMessages={shouldExpandMessages}
            onClose={() => onClose(id)}
          />
        </Fragment>
      ))}
    </>
  )
}
