import { usePreSearch } from '@fiji/hooks/use-custom-fields/use-pre-search'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { useRailContext } from '@etta/modules/rail/use-rail.context'

type Props = {
  onSubmit?: () => void
}

export function useSearch({ onSubmit }: Props) {
  const { submitRecentSearchActions } = useRecentSearchesContext()
  const { railSearchButtonActions, railSearchFormStore } = useRailSearchFormContext()
  const {
    originPlace,
    destinationPlace,
    originDate,
    originTime,
    destinationDate,
    destinationTime,
    railTripType,
    railCards,
  } = railSearchFormStore.railForm

  const {
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    shouldShowPreSearchModal,
    handleOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  } = usePreSearch({ onSubmit: railSearchButtonActions.handleSubmit })

  const { railActions } = useRailContext()

  const handleSearchClick = () => {
    onSubmit?.()
    submitRecentSearchActions.submitNewRailSearch({
      destinationPlace,
      destinationTime,
      originDate,
      originPlace,
      originTime,
      railTripType,
      destinationDate,
      railCards,
    })

    if (shouldShowPreSearchModal) {
      handleOpenPreSearchCustomFields()
      return
    }

    railActions.handleSearchFormSubmit()

    railSearchButtonActions.handleSubmit()
  }

  return {
    handleSearchClick,
    customFields,
    values,
    errors,
    onFieldChange,
    onPreSearchBack,
    isOpenPreSearchCustomFields,
    onSubmitCustomFieldsHandler,
  }
}
