import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import type { Hotel } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import { toKebabCase } from '@fiji/utils/text'
import { Container, IconWrapper, MarkerContainer } from './hotel-marker-styled'
import { useMapMarker } from './use-map-marker'

type Props = {
  hotel: Hotel
  selectedId?: string
  onSelect: (id: string) => void
  'data-tracking-id'?: string
}

const i18Base = 'SearchResults.Hotels'

export const HotelMarker = observer(function HotelMarker({
  hotel,
  selectedId,
  onSelect,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const { isActive, isSoldOut, handleSelect, rate, iconName, iconColor } = useMapMarker({
    hotel,
    selectedId,
    onSelect,
  })

  const soldOutText = t(i18Base + '.SoldOut')
  const usedDataTrackingId = dataTrackingId || hotel.id + '-marker'

  return (
    <MarkerContainer
      aria-label={t(`${i18Base}.MarkerAriaLabel`, { cost: isSoldOut ? soldOutText : rate })}
      data-tracking-id={usedDataTrackingId}
      data-test-id={`${toKebabCase(hotel.name)}-marker`}
      isActive={isActive}
      onClick={handleSelect}
      isIcon={isSoldOut ? false : !!iconName}>
      <Container>
        <Swap
          is={isSoldOut}
          isSlot={
            <Text variant="subHeadStrong" color={isActive ? 'white' : 'bodyText'}>
              {soldOutText}
            </Text>
          }>
          <Text variant="subHeadStrong" color={isActive ? 'white' : 'mainText'}>
            {rate}
          </Text>
          {iconName && (
            <IconWrapper>
              <Icon name={iconName} size="small" color={iconColor} />
            </IconWrapper>
          )}
        </Swap>
      </Container>
    </MarkerContainer>
  )
})
