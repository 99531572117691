import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useExpand } from '@fiji/hooks/use-expand/use-expand'
import type { CarRentalAmenities } from '../../../types'
import {
  AmenitiesContainer,
  ToggleButton,
  AmenitiesList,
  AmenitiesItem,
  AmenitiesIcon,
} from './car-rental-details-header-mobile-styled'

type Props = {
  amenities: CarRentalAmenities[]
}

export function Amenities({ amenities }: Props) {
  const { isExpanded, toggleExpansion } = useExpand()

  const { t } = useTranslation()

  return (
    <AmenitiesContainer
      aria-label={t('CarRentalDetails.Amenities.AriaLabel')}
      onClick={toggleExpansion}>
      <AmenitiesList isExpanded={isExpanded} role="list">
        {amenities.map(({ name, value }) => {
          return (
            <AmenitiesItem key={name} role="listitem">
              <AmenitiesIcon>
                <Icon name={name} />
              </AmenitiesIcon>
              {isExpanded && (
                <Text textTransform="capitalize" variant="footnoteMedium">
                  {value}
                </Text>
              )}
            </AmenitiesItem>
          )
        })}
      </AmenitiesList>
      <ToggleButton>
        <Icon name={isExpanded ? 'expandLessPWA' : 'expandMorePWA'} />
      </ToggleButton>
    </AmenitiesContainer>
  )
}
