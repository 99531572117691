import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StopsLine } from '@etta/components/segment-search-details/components'
import {
  Container,
  TimeContainer,
  AirportCode,
  Time,
  Date,
  AirportCodeAndNights,
  ArrivesIn,
} from '@etta/components/flight-details-legend/collapsed-view/layout/desktop/components/departure-arrival/departure-arrival-styled'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'

type Props = {
  flight: AdaptiveFlight
  daysInBetween?: number
}

export const DepartureArrival: FC<Props> = ({ flight, daysInBetween }) => {
  const { t } = useTranslation()

  const firstSegment = flight.adaptedSegments[0]
  const lastSegment = flight.adaptedSegments[flight.adaptedSegments.length - 1]

  return (
    <Container>
      <TimeContainer>
        <AirportCode>{firstSegment.departureAirportCode}</AirportCode>

        <Time>{firstSegment.departureTime}</Time>

        <Date>{firstSegment.departureDate}</Date>
      </TimeContainer>

      <StopsLine stopsInfo={flight.flightStops} topPosition={12} maxStopsInView={3} />

      <TimeContainer useRightText>
        <AirportCodeAndNights>
          {lastSegment.arrivalAirportCode}{' '}
          {!!daysInBetween && (
            <ArrivesIn> {t('SearchSegments.Nights', { count: daysInBetween })}</ArrivesIn>
          )}
        </AirportCodeAndNights>

        <Time>{lastSegment.arrivalTime}</Time>

        <Date>{lastSegment.arrivalDate}</Date>
      </TimeContainer>
    </Container>
  )
}
