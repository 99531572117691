import styled, { css } from 'styled-components'
import { captionStrongCaps } from '@fiji/style'
import type { SegmentSkinType } from './types'

export const CardStatusBlock = styled.span<{ skin?: SegmentSkinType }>`
  position: relative;
  text-transform: uppercase;
  margin-left: 25px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.primary};

  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${(p) => (p.skin === 'gray-background' ? '5px' : '-14px')};
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
  }

  ${(p) =>
    p.skin === 'gray-background' &&
    css`
      background: ${(p) => p.theme.colors.borderDark};
      color: ${(p) => p.theme.colors.mainText};
      ${captionStrongCaps};
      padding: 2px 6px 3px 17px;
      border-radius: 4px;
      margin-left: 15px;
      top: -2px;
    `}
`
