type Args = {
  firstName?: string | null
  lastName?: string | null
  middleName?: string | null
}

export function getUserNames({ firstName, middleName, lastName }: Args) {
  const firstNameChar = firstName ? firstName.charAt(0) : ''
  const lastNameChar = lastName ? lastName.charAt(0) : ''
  const initials = [firstNameChar, lastNameChar].join('')
  const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ')

  return {
    initials,
    fullName,
  }
}
