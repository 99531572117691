import { useCallback, useEffect } from 'react'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { RefinementOptionsEnum } from '@etta/modules/ride-hail/core'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useEttaMapContext } from '@etta/modules/etta-map/interface/use-etta-map.context'
import type { EttaMapEntity } from '@etta/modules/etta-map/core/etta-map.entity'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import type { Coords } from '@etta/modules/etta-map/core/coords'
import { useMobilityDialogRef } from './use-mobility-dialog-ref'

type Props = {
  map: EttaMapEntity | null
  pickUpCoordinate?: Coords
  dropOffCoordinate?: Coords
  currentCoordinate: Coords
  loadingCoordinate: Coords
}

export function useMobilitySearchMapControl({
  map,
  pickUpCoordinate,
  dropOffCoordinate,
  currentCoordinate,
  loadingCoordinate,
}: Props) {
  const {
    dialogHeight: dialogWhereToHeight,
    ref: dialogWhereToContainerRef,
  } = useMobilityDialogRef()
  const { dialogHeight: dialogResultHeight, ref: dialogResultContainerRef } = useMobilityDialogRef()
  const { dialogHeight: dialogReviewHeight, ref: dialogReviewContainerRef } = useMobilityDialogRef()
  const {
    ettaMapStore: { isAppleMap },
  } = useEttaMapContext()
  const {
    featureFlagsStore: {
      flags: { isMobilityZoningEnabled },
    },
  } = useFeatureFlagsContext()

  const {
    rideHailSearchLocationRefinementStore,
    searchRideHailActions,
    searchRideHailStore,
  } = useRideHailSearchContext()
  const { setRenderState } = searchRideHailActions
  const { renderState } = searchRideHailStore

  const handlePanAndZoom = useCallback(
    async (routing: () => void) => {
      const ettaMap = map
      if (!ettaMap) {
        routing()
        return
      }
      const coords =
        rideHailSearchLocationRefinementStore.refinementOptionsAction ===
        RefinementOptionsEnum.DropOffZoning
          ? dropOffCoordinate
          : pickUpCoordinate

      if (coords) {
        ettaMap.panTo(coords.lat, coords.lng)
      }
      await delayForSuccess(200)
      if (!isAppleMap) {
        const maxZoom =
          rideHailSearchLocationRefinementStore.refinementOptionsAction ===
          RefinementOptionsEnum.NoRefinement
            ? 20
            : 16

        await ettaMap.zoomOn(maxZoom)
      }

      routing()
    },
    [
      map,
      rideHailSearchLocationRefinementStore.refinementOptionsAction,
      dropOffCoordinate,
      pickUpCoordinate,
      isAppleMap,
    ],
  )

  useEffect(() => {
    const ettaMap = map
    if (!ettaMap) {
      return
    }

    if (renderState === RenderState.whereTo) {
      const bounds = ettaMap.createBoundsByCoordinates([currentCoordinate])
      const padding = getPadding(dialogWhereToHeight, isAppleMap)
      ettaMap.setBounds(bounds, padding)

      // Workaround for Google Maps API's fitBounds with one coordinate does not accounting padding
      // https://issuetracker.google.com/issues/139464498
      ettaMap.panBy(0, padding.bottom / 2)

      return
    }

    if (renderState === RenderState.searchResult) {
      ettaMap.clearMarkers()
      ettaMap.clearPolygon()
      ettaMap.setRoutingBounds(getPadding(dialogResultHeight, isAppleMap))
      ettaMap.startRouting()
      return
    }

    if (renderState === RenderState.loading) {
      if (isAppleMap) {
        ettaMap.setRoutingBounds({ top: 0, bottom: 0, left: 0, right: 0 })
      }
      ettaMap.stopRouting()
      ettaMap.clearPolygon()
      ettaMap.panTo(loadingCoordinate.lat, loadingCoordinate.lng)

      return
    }

    if (renderState === RenderState.zoning) {
      ettaMap.stopRouting()
      return
    }

    if (renderState === RenderState.pickUpConfirm) {
      if (isAppleMap) {
        ettaMap.setRoutingBounds({ top: 0, bottom: 0, left: 0, right: 0 })
      }
      ettaMap.stopRouting()
      const coords = pickUpCoordinate
      if (coords) {
        ettaMap.panTo(coords.lat, coords.lng)
      }
      ettaMap.zoomOn(20)
      return
    }

    if (renderState === RenderState.review) {
      ettaMap.setRoutingBounds(getPadding(dialogReviewHeight, isAppleMap))
      ettaMap.startRouting()
    }
  }, [
    isMobilityZoningEnabled,
    renderState,
    dialogWhereToHeight,
    dialogResultHeight,
    dialogReviewHeight,
    setRenderState,
    pickUpCoordinate,
    loadingCoordinate,
    currentCoordinate,
    isAppleMap,
    map,
  ])

  return {
    dialogWhereToContainerRef,
    dialogResultContainerRef,
    dialogReviewContainerRef,
    handlePanAndZoom,
    loadingCoordinate,
  }
}

const getPadding = (offSet: number, isAppleMap: boolean) => {
  return { bottom: offSet + 20, left: 20, right: 20, top: isAppleMap ? 60 : 88 }
}
