import { useTranslation } from 'react-i18next'
import { ModalDetails } from '@etta/components/modal-details'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { IconButton } from '@etta/ui/icon-button'
import { Button } from '@etta/ui/button'
import { formatRate } from '@fiji/utils/money'
import type { LayoutProps } from '../../types'
import {
  Container,
  HeaderText,
  HeaderContainer,
  BaggageListContainer,
  Radio,
  RadioContent,
  Title,
  Price,
  Footer,
} from './add-baggage-modal-desktop-styled'

const i18nBase = 'ReviewTrip.AddBaggageModal.'

export function AddBaggageModalDesktop({
  isOpen,
  onClose,
  luggageOptions,
  luggageOption,
  setLuggageOption,
  handleSelectBaggageOption,
}: LayoutProps) {
  const { t } = useTranslation()
  return (
    <ModalDetails
      isVisible={isOpen}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <Header withBorderBottom>
        <HeaderContainer>
          <Header.Title title={t(i18nBase + 'Title')} align="left" />
          <IconButton
            onClick={onClose}
            icon="closeFilledPWA"
            size="medium"
            color="bodyText"
            aria-label={t('Accessibility.Header.CloseLabel')}
          />
        </HeaderContainer>
      </Header>
      <Modal.Body>
        <Container>
          <HeaderText>{t(i18nBase + 'Header')}</HeaderText>
          <BaggageListContainer>
            <Radio isChecked={!luggageOption} onChange={() => setLuggageOption(undefined)}>
              {t(i18nBase + 'None')}
            </Radio>
            {luggageOptions.map(({ code, value, cost }) => {
              const { mainCost: luggageCost } = formatRate(cost, {
                morpheme: 'none',
              })
              return (
                <Radio
                  key={code}
                  isChecked={code === luggageOption?.code}
                  onChange={() => setLuggageOption({ code, value, cost })}>
                  <RadioContent>
                    <Title>{value}</Title>
                    <Price>{luggageCost}</Price>
                  </RadioContent>
                </Radio>
              )
            })}
          </BaggageListContainer>
        </Container>
        <Footer>
          <Button onClick={onClose} variant="link">
            {t(i18nBase + 'Cancel')}
          </Button>
          <Button onClick={handleSelectBaggageOption} variant="solid">
            {t(i18nBase + 'Apply')}
          </Button>
        </Footer>
      </Modal.Body>
    </ModalDetails>
  )
}
