import { CalculateEmissionsFlightSegmentTravelClass } from '@fiji/graphql/hooks'
import { SeatmapCabinClass } from '@fiji/graphql/types'

export function mapToThrustCarbonCabinClass(
  cabinClass?: SeatmapCabinClass | string,
): CalculateEmissionsFlightSegmentTravelClass {
  switch (cabinClass) {
    case SeatmapCabinClass.Coach:
      return CalculateEmissionsFlightSegmentTravelClass.Economy
    case SeatmapCabinClass.PremiumCoach:
      return CalculateEmissionsFlightSegmentTravelClass.Premium
    case SeatmapCabinClass.Business:
      return CalculateEmissionsFlightSegmentTravelClass.Business
    case SeatmapCabinClass.First:
      return CalculateEmissionsFlightSegmentTravelClass.First
    default:
      return CalculateEmissionsFlightSegmentTravelClass.Economy
  }
}
