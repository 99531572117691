import { useTranslation } from 'react-i18next'
import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import { ecoCheckEquivalencesLinks } from '../constants'

export function useEquivalenceDescription(type: CalculateEmissionsBatchResultEquivalenceType) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.EquivalenceNew'

  switch (type) {
    case CalculateEmissionsBatchResultEquivalenceType.Seedling:
      return {
        firstPart: t(i18nBase + '.Seedling1'),
        linkTo: ecoCheckEquivalencesLinks.seedlingLink,
      }
    case CalculateEmissionsBatchResultEquivalenceType.PlasticBag:
      return {
        firstPart: t(i18nBase + '.PlasticBag1'),
        linkTo: ecoCheckEquivalencesLinks.plasticBagLink,
      }
    case CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge:
      return {
        firstPart: t(i18nBase + '.SmartphoneCharge1'),
        linkTo: ecoCheckEquivalencesLinks.smartphoneChargeLink,
      }
  }
}
