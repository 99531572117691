import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { GetRideHailSuggestionErrors } from '../../core/errors/get-suggestion.error'
import type { RideHailSuggestionValueObject } from '../../core/value-objects/ride-hail-suggestion.value-object'
import type { RideHailGetSuggestionResult } from '../types'
import { mapToRideHailSuggestionValueObject } from '../mappers/ride-hail-seggestion.mapper'
import { RideHailSuggestionDataProvider } from './ride-hail-suggestion.data-provider'

@Adapter()
export class RideHailSuggestionAdapter {
  constructor(
    @Inject()
    private rideHailSuggestionDataProvider: RideHailSuggestionDataProvider,
  ) {}

  async getRideHailSuggestion(): RideHailGetSuggestionResult<RideHailSuggestionValueObject[]> {
    try {
      const { data, error } = await this.rideHailSuggestionDataProvider.getRideHailSuggestion()

      if (error) {
        return Result.Err(new GetRideHailSuggestionErrors.UnexpectedGetSuggestionError(error))
      }

      return Result.Ok(mapToRideHailSuggestionValueObject(data))
    } catch (error) {
      return Result.Err(new GetRideHailSuggestionErrors.UnexpectedGetSuggestionError(error))
    }
  }
}
