import i18n from '@fiji/i18n'
import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { TimeZoneEntity } from '@etta/modules/settings/core/entities/time-zone.entity'
import type { LanguageEntity } from '@etta/modules/settings/core/entities/language.entity'
import type { CurrencyEntity } from '@etta/modules/settings/core/entities/currency.entity'
import type { SettingsCardValueObject } from '@etta/modules/user'
import type { HighlightElementValueObject } from '@etta/modules/settings/core/value-objects/highlight-element.value-object'
import { areHighlightedElementEquals } from '@etta/modules/settings/core/value-objects/highlight-element.value-object'

const defaultSettingsCardInput: SettingsCardValueObject = {
  dateFormat: '',
  hourlyTimeFormat: '',
  timeZone: '',
  measurementUnit: '',
  currency: '',
  country: '',
  locale: '',
}

const I18nBasePath = 'Settings.LoyaltyPrograms.Modal.LoyaltyNumberRequired'

@Store()
export class SettingsStore {
  isSaveButtonDisabled = true
  toggle = new Toggle()
  removeToggle = new Toggle()

  settingsCardInput: SettingsCardValueObject = defaultSettingsCardInput

  timeZoneOptions: TimeZoneEntity[] | null = null
  currencyOptions: CurrencyEntity[] | null = null
  languageOptions: LanguageEntity[] | null = null

  isTimeZoneOptionsLoading: boolean = false
  isCurrencyOptionsLoading: boolean = false
  isCountryOptionsLoading: boolean = false
  isLanguageOptionsLoading: boolean = false

  loyaltyProgramValidation: {
    vendorError?: string
    numberError?: string
  } = {
    vendorError: undefined,
    numberError: undefined,
  }

  highlightedElement: HighlightElementValueObject | null = null

  get isLoading() {
    return (
      this.isTimeZoneOptionsLoading ||
      this.isCurrencyOptionsLoading ||
      this.isCountryOptionsLoading ||
      this.isLanguageOptionsLoading
    )
  }

  setSettingsCardInputValue<T extends keyof SettingsCardValueObject>(
    field: T,
    value: SettingsCardValueObject[T],
  ) {
    const newSettingsCardInput = { ...this.settingsCardInput, [field]: value }
    this.settingsCardInput = newSettingsCardInput
  }

  setTimeZoneOptions(timeZoneOptions: TimeZoneEntity[] | null) {
    this.timeZoneOptions = timeZoneOptions
  }

  setCurrencyOptions(currencyOptions: CurrencyEntity[] | null) {
    this.currencyOptions = currencyOptions
  }

  setLanguageOptions(languageOptions: LanguageEntity[] | null) {
    this.languageOptions = languageOptions
  }

  setIsTimeZoneOptionsLoading(isTimeZoneOptionsLoading: boolean) {
    this.isTimeZoneOptionsLoading = isTimeZoneOptionsLoading
  }

  setIsCurrencyOptionsLoading(isCurrencyOptionsLoading: boolean) {
    this.isCurrencyOptionsLoading = isCurrencyOptionsLoading
  }

  setIsLanguageOptionsLoading(isLanguageOptionsLoading: boolean) {
    this.isLanguageOptionsLoading = isLanguageOptionsLoading
  }

  isHighlighted(element: HighlightElementValueObject) {
    if (!this.highlightedElement) {
      return false
    }

    return areHighlightedElementEquals(this.highlightedElement, element)
  }

  setHighlightedElement(element: HighlightElementValueObject) {
    this.highlightedElement = element
  }

  dropHighlightedElement() {
    this.highlightedElement = null
  }

  setLoyaltyProgramsValidation({
    vendorCode,
    number,
  }: {
    vendorCode: string | null
    number: string | null
  }) {
    if (!vendorCode && !number) {
      return (this.loyaltyProgramValidation = {
        vendorError: ' ',
        numberError: i18n.t(I18nBasePath),
      })
    }
    if (!vendorCode) {
      return (this.loyaltyProgramValidation = {
        vendorError: ' ',
        numberError: undefined,
      })
    }
    if (!number) {
      return (this.loyaltyProgramValidation = {
        vendorError: undefined,
        numberError: i18n.t(I18nBasePath),
      })
    }
    this.loyaltyProgramValidation = {
      vendorError: undefined,
      numberError: undefined,
    }
  }

  resetLoyaltyProgramsValidation() {
    this.loyaltyProgramValidation = {
      vendorError: undefined,
      numberError: undefined,
    }
  }

  setIsSaveButtonDisabled(isDisabled: boolean) {
    this.isSaveButtonDisabled = isDisabled
  }
}
