import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightDetailsEcoCheckMobile } from './mobile'
import { FlightDetailsEcoCheckDesktop } from './desktop'

export function FlightDetailsEcoCheckLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightDetailsEcoCheckMobile {...props} />}
      desktopSlot={<FlightDetailsEcoCheckDesktop {...props} />}
    />
  )
}
