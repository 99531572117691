import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace TravelSupportErrors {
  export const GetDocument360SupportArticleByIdFail = createErrorClass(
    'Get Document360 Support Article by Id failed',
  )
}

export type TravelSupportInstances = InstanceType<
  typeof TravelSupportErrors[keyof typeof TravelSupportErrors]
>
