import { StyledTextVariant } from '@fiji/enums'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useShouldOpenInViewport } from '@fiji/hooks/use-should-open-in-viewport'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { Text } from '@etta/ui/text'
import { ModalAnimation } from '@etta/ui/modal-animation'
import type { TimePickerProps } from './types'
import { useSwipingTimePickerDesktop } from './use-swiping-time-picker'
import { Container, Header, List, Option, Wrapper } from './swiping-time-picker-styled'

export function SwipingTimePicker({
  isVisible,
  label,
  defaultValue,
  value,
  onApply,
  onClose,
}: TimePickerProps) {
  const { ref } = useClickOutside(() => {
    if (isVisible) {
      onClose()
    }
  })
  useShouldOpenInViewport({ isVisible, ref })

  const { timeLabel, timeRangeOptions, handleTimeSelect } = useSwipingTimePickerDesktop({
    defaultValue,
    value,
    onApply,
    onClose,
  })

  return (
    <ModalAnimation isOpen={isVisible}>
      <Wrapper>
        <Container ref={ref}>
          <Header>
            <Text variant={StyledTextVariant.captionMedium} color="bodyText">
              {label}
            </Text>
            <Text variant={StyledTextVariant.headline} color="mainText">
              {timeLabel}
            </Text>
          </Header>

          <List>
            {timeRangeOptions.map((time, i) => (
              <Option key={i} onClick={handleTimeSelect(time)}>
                <Text variant={StyledTextVariant.subHeadMedium} color="mainText">
                  {getFormattedTime(time, 'p')}
                </Text>
              </Option>
            ))}
          </List>
        </Container>
      </Wrapper>
    </ModalAnimation>
  )
}
