import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '24px 16px;' : '24px 32px;')};
  background-color: ${(props) => props.theme.colors.white};
`

export const ItemWrapper = styled(Block)`
  display: flex;
  flex-direction: column;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`
