import styled from 'styled-components'
import type { ColorPalette } from '@fiji/types'
import { transition, TRANSITION_DURATION } from './transition'

export const Container = styled.div`
  width: 100%;
  ${transition};
`

type ContentProps = {
  backgroundColor: keyof ColorPalette
  isNoAnimate: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  transition: background-color ${(p) => (p.isNoAnimate ? 0 : TRANSITION_DURATION)}ms linear;
`
