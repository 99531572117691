import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background4};
  border-radius: 0 0 14px 14px;
  padding: 0 16px;
`

export const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`

export const Mode = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 21px;

  &:not(:last-of-type) {
    ${Content} {
      border-bottom: 1px dashed ${(props) => props.theme.colors.borderDark};
    }
  }
`
