import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { BaggageInformationDesktop } from './desktop/baggage-information-desktop'
import { BaggageInformationMobile } from './mobile/baggage-information-mobile'

export function BaggageInformationLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<BaggageInformationMobile {...props} />}
      desktopSlot={<BaggageInformationDesktop {...props} />}
    />
  )
}
