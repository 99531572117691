import { useTranslation } from 'react-i18next'
import type { TripDetailsCostSummaryValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money'
import type { TripEntity } from '@etta/modules/post-booking/core/entities/trip.entity'
import { sumRates } from '@fiji/utils/sum-rate'
import { SummaryWrapper, SummarySegmentWrapper, Row } from './print-itinerary-modal.styled'
import { SegmentCostSummary } from './segment-cost-summary'

type Props = {
  costSummary: TripDetailsCostSummaryValueObject
  billingInfo?: TripEntity['billingInfo']
}

export function TripCostSummary({ costSummary, billingInfo }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PostBooking.TripDetails.PrintItinerary.'
  const { hotel, flight, carRental, totalCost } = costSummary
  const flightCards = getCards(billingInfo, 'AIR')
  const hotelCards = getCards(billingInfo, 'HOTEL')
  const carRentalCards = getCards(billingInfo, 'CAR_RENTALS')
  const paidFlights = flight?.flightList.filter((flight) => flight.isPaid) ?? []
  const payLaterFlights = flight?.flightList.filter((flight) => !flight.isPaid) ?? []

  return (
    <SummaryWrapper>
      <Text variant="subHeadStrong">{t(i18nBase + 'TripCostSummary')}</Text>
      <SummarySegmentWrapper>
        {(paidFlights.length > 0 || hotel?.isPaid || carRental?.isPaid) && (
          <Text variant="captionStrong" isBlock>
            {t(i18nBase + 'Paid')}
          </Text>
        )}
        {paidFlights.length > 0 && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'Flights')}
            cost={sumRates(paidFlights.map((flight) => flight.cost))}
            tax={sumRates(paidFlights.map((flight) => flight.tax))}
            total={sumRates(paidFlights.map((flight) => flight.total))}
            paymentCards={flightCards}
          />
        )}
        {hotel && hotel?.isPaid && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'Hotels')}
            cost={hotel.cost}
            tax={hotel.tax}
            total={hotel.total}
            paymentCards={hotelCards}
          />
        )}
        {carRental && carRental?.isPaid && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'CarRentals')}
            cost={carRental.cost}
            tax={carRental.tax}
            total={carRental.total}
            paymentCards={carRentalCards}
          />
        )}
      </SummarySegmentWrapper>
      <SummarySegmentWrapper>
        {(payLaterFlights.length > 0 ||
          (hotel && !hotel?.isPaid) ||
          (carRental && !carRental?.isPaid)) && (
          <Text variant="captionStrong" isBlock>
            {t(i18nBase + 'PayLater')}
          </Text>
        )}
        {payLaterFlights.length > 0 && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'Flights')}
            cost={sumRates(payLaterFlights.map((flight) => flight.cost))}
            tax={sumRates(payLaterFlights.map((flight) => flight.tax))}
            total={sumRates(payLaterFlights.map((flight) => flight.total))}
            isEstimated
            paymentCards={flightCards}
          />
        )}
        {hotel && !hotel?.isPaid && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'Hotels')}
            cost={hotel.cost}
            tax={hotel.tax}
            total={hotel.total}
            isEstimated
            paymentCards={hotelCards}
          />
        )}
        {carRental && !carRental?.isPaid && (
          <SegmentCostSummary
            segmentLabel={t(i18nBase + 'CarRentals')}
            cost={carRental.cost}
            tax={carRental.tax}
            total={carRental.total}
            isEstimated
            paymentCards={carRentalCards}
          />
        )}
      </SummarySegmentWrapper>
      <div>
        <Row>
          <Text variant="subHeadStrong">{t(i18nBase + 'TotalTripCost')}</Text>
          <Text variant="subHeadStrong">
            {formatRate(totalCost, { morpheme: 'prefix' }).mainCost}
          </Text>
        </Row>
        <Row>
          <span></span>
          <Text variant="footnoteMedium" color="bodyText">
            {formatRate(totalCost, { morpheme: 'prefix' }).secondCost}
          </Text>
        </Row>
      </div>
    </SummaryWrapper>
  )
}

const getCards = (billingInfo: TripEntity['billingInfo'], type: string) => {
  return billingInfo
    ?.filter((billingItem) => billingItem.id === type)
    ?.map(
      (billingItem) =>
        billingItem.payment?.siteCard?.label || billingItem.payment?.creditCard?.label || '',
    )
}
