import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import type { TextProps } from '@etta/ui/text'

export const Title = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'title2',
  color: 'mainText',
}))`
  margin-bottom: 16px;
`
