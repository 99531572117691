import { useTheme } from 'styled-components'
import type { SpinnerSizes, SpinnerType } from '../types'
import { Container, SpinnerImageWrapper } from './spinner-components'
import { default as Loader } from './loader.svg'
import LoaderAlt from './loader-alt'
import { default as LoaderStatic } from './loader-static.svg'
import { default as LoaderAltStatic } from './loader-alt-static.svg'

type Props = {
  size?: SpinnerSizes
  type?: SpinnerType
  isStatic?: boolean
  isPrimaryButton?: boolean
  isNarrowButton?: boolean
  isRelativeLink?: boolean
  isSecondaryButton?: boolean
  isDefaultInline?: boolean
  isPrimaryModalCostButton?: boolean
  isSecondaryModalCostButton?: boolean
  rtpAddButtonPosition?: number
}

export function SpinnerImage({
  size = 'large',
  type = 'primary',
  isStatic,
  isPrimaryButton,
  isRelativeLink,
  isSecondaryButton,
  isNarrowButton,
  isDefaultInline,
  isPrimaryModalCostButton,
  isSecondaryModalCostButton,
  rtpAddButtonPosition,
}: Props) {
  const theme = useTheme()
  if (type === 'secondary') {
    return (
      <SpinnerImageWrapper size={size}>
        {isStatic ? <LoaderAltStatic /> : <LoaderAlt />}
      </SpinnerImageWrapper>
    )
  }
  if (type === 'inline') {
    return (
      <SpinnerImageWrapper
        size={size}
        isNarrowButton={isNarrowButton}
        isRelativeLink={isRelativeLink}
        isSecondaryButton={isSecondaryButton}
        isDefaultInline={isDefaultInline}
        isPrimaryButton={isPrimaryButton}
        isPrimaryModalCostButton={isPrimaryModalCostButton}
        isSecondaryModalCostButton={isSecondaryModalCostButton}
        rtpAddButtonPosition={rtpAddButtonPosition}>
        <LoaderAlt
          color={
            isPrimaryButton || isPrimaryModalCostButton || isNarrowButton
              ? null
              : theme.primaryColor
          }
        />
      </SpinnerImageWrapper>
    )
  }

  return (
    <Container>
      <SpinnerImageWrapper size={size}>
        {isStatic ? <LoaderStatic /> : <Loader />}
      </SpinnerImageWrapper>
    </Container>
  )
}
