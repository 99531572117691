import type { FocusEvent } from 'react'
import type { DropDownListProps, ValueType } from './types'
import { ListTypes } from './types'
import { List } from './list'
import { ListWrapper, OutsideContainer, Wrapper } from './drop-down-list-styled'
import { useDropDownList } from './use-drop-down-list'
import { DropDownListLabel } from './drop-down-list-label'

export function DropDownList<T extends ValueType = ValueType>({
  label,
  options,
  value,
  onChange,
  isDisabled,
  type = ListTypes.Radio,
  defaultLabel,
  withListBodyPadding = true,
  withThinLabel,
  withListHeader = true,
  withVisibleLabel = false,
  helperText,
  helperType,
  isOutsideAlignRight = false,
  'data-tracking-id': dataTrackingId,
  isFixedPositioned = false,
  withFullHeight = false,
  scrollParentRef,
  shouldCloseOnScroll = false,
  renderMode = 'simple',
  customAriaLabel,
  isBlurred,
  withNoneOption,
}: DropDownListProps<T>) {
  const {
    listToggle,
    handleOpenList,
    handleChange,
    selectedLabel,
    selectedComponent,
    dropdownWrapperRef,
    listConfig,
    listWrapperRef,
    ariaLabel,
    overflowContentHeight,
    displayOptions,
  } = useDropDownList({
    type,
    isDisabled,
    label,
    value,
    options,
    defaultLabel,
    onChange,
    isFixedPositioned,
    scrollParentRef,
    shouldCloseOnScroll,
    renderMode,
    customAriaLabel,
    withNoneOption,
  })

  const valueOflist = isBlurred ? [] : value

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      listToggle.handleClose()
    }
  }

  return (
    <Wrapper
      isOutsideAlignRight={isOutsideAlignRight}
      data-tracking-id={dataTrackingId}
      aria-expanded={listToggle.isOpen}
      onBlur={handleBlur}
      ref={dropdownWrapperRef}>
      {renderMode === 'simple' && (
        <DropDownListLabel
          isOpen={listToggle.isOpen}
          onOpen={handleOpenList}
          label={label}
          value={selectedLabel}
          isDisabled={isDisabled}
          withThinLabel={withThinLabel}
          withVisibleLabel={withVisibleLabel}
          helperText={helperText}
          helperType={helperType}
          renderMode={renderMode}
          ariaLabel={ariaLabel}
          isBlurred={isBlurred}
        />
      )}
      {renderMode === 'component' && (
        <DropDownListLabel
          isOpen={listToggle.isOpen}
          onOpen={handleOpenList}
          label={label}
          value={selectedComponent ? ' ' : ''}
          isDisabled={isDisabled}
          withThinLabel={withThinLabel}
          withVisibleLabel={withVisibleLabel}
          helperText={helperText}
          helperType={helperType}
          renderMode={renderMode}
          ariaLabel={ariaLabel}>
          {selectedComponent}
          isBlurred={isBlurred}
        </DropDownListLabel>
      )}
      <OutsideContainer
        isOpen={listToggle.isOpen}
        onClose={listToggle.handleClose}
        withTopPadding={withVisibleLabel}
        isFixedPositioned={isFixedPositioned}
        listConfig={listConfig}
        overflowContentHeight={overflowContentHeight}>
        <ListWrapper ref={listWrapperRef} tabIndex={-1}>
          <List
            options={displayOptions}
            label={label}
            type={type}
            value={valueOflist}
            onChange={handleChange}
            withListBodyPadding={withListBodyPadding}
            withListHeader={withListHeader}
            withFullHeight={withFullHeight}
            excludeHeight={listConfig?.excludeHeight}
          />
        </ListWrapper>
      </OutsideContainer>
    </Wrapper>
  )
}

DropDownList.Container = Wrapper
DropDownList.Label = DropDownListLabel
