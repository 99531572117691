import type { SeatList } from '../seat-map-segment.types'

export function checkShowRowNumber(
  seatIndex: number,
  seatList: SeatList,
  mostFrequentAislesColumns: string[],
): boolean {
  const prevSeat = seatList[seatIndex - 1]
  const currentSeat = seatList[seatIndex]

  const includesCurrent = mostFrequentAislesColumns.includes(currentSeat.column)
  const includesPrev = mostFrequentAislesColumns.includes(prevSeat?.column)

  return (includesCurrent && !prevSeat) || (includesCurrent && includesPrev)
}
