import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace ZookeeperConfigurationErrors {
  export const ZookeeperConfigurationResponse = createErrorClass('Failed zookeeper configuration')
  export const ZookeeperConfigurationUnexpectedError = createErrorClass(
    'Processed zookeeper configuration',
  )
}

export type ZookeeperConfigurationErrorsInstances = InstanceType<
  typeof ZookeeperConfigurationErrors[keyof typeof ZookeeperConfigurationErrors]
>
