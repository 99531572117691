import styled from 'styled-components'

export const Badges = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Badge = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 4px 8px;
  border-right: unset;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right: 1px solid ${(props) => props.theme.colors.borderDark};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
