import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Swap } from '@etta/ui/swap'
import { Message } from '../../message'
import type { LayoutProps } from '../types'
import {
  Container,
  Title,
  IconSection,
  MessageSection,
  Content,
  DecoratedLine,
  ShadowCard,
} from './dynamic-site-messages-mobile-styled'

export function DynamicSiteMessagesMobile({
  title,
  message,
  color,
  buttonColor,
  viewMoreTitle,
  isWithAlerts,
  isExpanded,
  hasMessages,
  hasMultipleItems,
  handleExpand,
  handleModalOpen,
}: LayoutProps) {
  return (
    <>
      {hasMessages && (
        <Container onClick={handleExpand}>
          <DecoratedLine color={color} />
          <Content>
            <IconSection>
              {isWithAlerts ? (
                <Icon name="warningFilledPWA" color="warning" />
              ) : (
                <Icon name="infoFilledPWA" color={color} />
              )}
            </IconSection>

            <MessageSection>
              <Swap is={title.length > 0} isSlot={<Title>{title}</Title>}>
                <Title dangerouslySetInnerHTML={{ __html: message }} />
              </Swap>
              {isExpanded && <Message message={message} />}

              <Button
                variant="text"
                btnType={buttonColor}
                size="small"
                onClick={handleModalOpen}
                paddings={false}>
                {viewMoreTitle}
              </Button>
            </MessageSection>
          </Content>
        </Container>
      )}
      {hasMultipleItems && <ShadowCard />}
    </>
  )
}
