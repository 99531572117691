import type { Location } from '../../types'
import { GeoSafeLocationLayout } from './layout'

type Props = {
  isLoading: boolean
  location: Location | undefined
  hotelName?: string
}

export function GeoSafeLocation({ hotelName, location, isLoading }: Props) {
  return <GeoSafeLocationLayout hotelName={hotelName} location={location} isLoading={isLoading} />
}
