/* eslint import/no-restricted-paths: 0 */
import type {
  UpdateAirTravelPreferenceMutationHookResult,
  GazooMembership,
} from '@fiji/graphql/hooks'
import { VendorTravelVertical } from '@fiji/graphql/hooks'
import { Action, Inject } from '@etta/di'
import type { AirTravelPreferenceInputValueObject } from '../../core/value-objects/air-travel-preference-input.value-object'
import { FlightPreferencesStore } from '../stores/flight-preferences.store'
import { TravelPreferencesService } from '../services/travel-preferences.service'
import { HomeAirportService } from '../services/home-airport.service'
import { SettingsStore } from '../stores/settings.store'

@Action()
export class FlightPreferencesActions {
  constructor(
    @Inject()
    private readonly flightPreferencesStore: FlightPreferencesStore,
    @Inject()
    private readonly travelPreferencesService: TravelPreferencesService,
    @Inject()
    private readonly homeAirportService: HomeAirportService,
    @Inject()
    private readonly settingsStore: SettingsStore,
  ) {}

  handleFieldChange<T extends keyof AirTravelPreferenceInputValueObject>(field: T) {
    return (value: AirTravelPreferenceInputValueObject[T]) => {
      this.flightPreferencesStore.setIsSaveButtonDisabled(false)
      this.flightPreferencesStore.setFlightPreferencesValue(field, value)
    }
  }

  handleHomeAirportSelect(value: AirTravelPreferenceInputValueObject['homeAirport']) {
    this.handleFieldChange('homeAirport')(value)
    this.flightPreferencesStore.resetSuggestedHomeAirports()
  }

  async handleFlightPreferencesSave(handleUpdate: UpdateAirTravelPreferenceMutationHookResult) {
    const validatorCheck = await this.flightPreferencesStore.flightPreferencesValidator.submit()
    const hasValidationErrors = !!validatorCheck.errors

    if (hasValidationErrors) {
      return
    }

    await this.travelPreferencesService.saveFlightPreferences({
      input: this.flightPreferencesStore.flightPreferencesValidator.values,
      handleUpdate,
      handleClose: () => {
        this.flightPreferencesStore.setIsSaveButtonDisabled(true)
        this.flightPreferencesStore.toggle.handleClose()
      },
    })
  }

  handleEditModalOpening() {
    this.flightPreferencesStore.setIsSaveButtonDisabled(true)
    const flightPreferencesInput = this.travelPreferencesService.getFlightPreferencesInput()
    this.flightPreferencesStore.setFlightPreferencesInput(flightPreferencesInput)

    if (!this.flightPreferencesStore.flightAvailableSpecialRequests) {
      this.fetchFlightSpecialRequests()
    }

    this.flightPreferencesStore.toggle.handleOpen()
  }

  async fetchSuggestedHomeAirports(query: string) {
    this.flightPreferencesStore.setIsSuggestedHomeAirportsLoading(true)
    const suggestedHomeAirports = await this.homeAirportService.getSuggestedHomeAirports({ query })
    this.flightPreferencesStore.setIsSuggestedHomeAirportsLoading(false)

    if (suggestedHomeAirports) {
      this.flightPreferencesStore.setSuggestedHomeAirports(suggestedHomeAirports)
      return
    }

    this.flightPreferencesStore.setSuggestedHomeAirports([])
  }

  handleEditAirLoyaltyProgramModalOpen() {
    this.flightPreferencesStore.setIsSaveButtonDisabled(true)
    const flightPreferencesInput = this.travelPreferencesService.getFlightPreferencesInput()
    this.flightPreferencesStore.setFlightPreferencesInput(flightPreferencesInput)

    if (!this.flightPreferencesStore.availableAirVendors) {
      this.fetchAvailableAirVendors()
    }

    this.flightPreferencesStore.airLoyaltyProgramActionToggle.handleOpen()
  }

  handleEditExistingAirLoyaltyProgram(vendorCode?: string | number | null) {
    this.flightPreferencesStore.setIsEditExistingProgramMode(true)
    this.handleEditAirLoyaltyProgramModalOpen()

    const airMemberships = this.flightPreferencesStore.flightPreferencesValidator.values
      .airMembership as GazooMembership[]
    const existingLoyaltyProgram = airMemberships.find((item) => item.vendorCode === vendorCode)

    if (existingLoyaltyProgram) {
      this.flightPreferencesStore.setAirLoyaltyProgram(existingLoyaltyProgram)
    }
  }

  handleEditAirLoyaltyProgramModalClose() {
    this.flightPreferencesStore.setIsSaveButtonDisabled(true)
    this.flightPreferencesStore.airLoyaltyProgramActionToggle.handleClose()
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.flightPreferencesStore.setIsEditExistingProgramMode(false)
    this.flightPreferencesStore.resetAirLoyaltyProgram()
  }

  handleLoyaltyProgramChange(loyaltyProgram: GazooMembership) {
    this.flightPreferencesStore.setIsSaveButtonDisabled(false)
    this.settingsStore.resetLoyaltyProgramsValidation()
    this.flightPreferencesStore.setAirLoyaltyProgram(loyaltyProgram)
  }

  handleLoyaltyProgramVendorChange(vendorCode: string) {
    this.settingsStore.resetLoyaltyProgramsValidation()
    const airMemberships = this.flightPreferencesStore.flightPreferencesValidator.values
      .airMembership as GazooMembership[]
    const existingMembership = airMemberships.find((item) => item.vendorCode === vendorCode)

    this.handleLoyaltyProgramChange({
      vendorCode,
      number: existingMembership?.number || '',
    })

    this.flightPreferencesStore.setIsEditExistingProgramMode(!!existingMembership)
  }

  async handleAirLoyaltyProgramSave(handleUpdate: UpdateAirTravelPreferenceMutationHookResult) {
    const { airLoyaltyProgram, airLoyaltyProgramActionToggle } = this.flightPreferencesStore
    const { vendorCode, number } = airLoyaltyProgram
    const airMemberships = this.flightPreferencesStore.flightPreferencesValidator.values
      .airMembership as GazooMembership[]
    const isExistingMembership = this.flightPreferencesStore.isViewingExistingMembership

    this.settingsStore.setLoyaltyProgramsValidation({ vendorCode, number })

    if (!vendorCode || !number) {
      return
    }

    if (isExistingMembership) {
      const filteredMemberships = airMemberships.filter((item) => item.vendorCode !== vendorCode)
      this.handleFieldChange('airMembership')([...filteredMemberships, airLoyaltyProgram])
    } else {
      this.handleFieldChange('airMembership')([...airMemberships, airLoyaltyProgram])
    }

    const result = await this.travelPreferencesService.saveFlightPreferences({
      input: this.flightPreferencesStore.flightPreferencesValidator.values,
      handleUpdate,
      handleClose: airLoyaltyProgramActionToggle.handleClose,
    })

    if (result?.isErr()) {
      return
    }

    this.flightPreferencesStore.resetAirLoyaltyProgram()
    this.flightPreferencesStore.setIsEditExistingProgramMode(false)
  }

  async handleRemoveExistingLoyaltyProgram(
    handleUpdate: UpdateAirTravelPreferenceMutationHookResult,
  ) {
    if (!this.flightPreferencesStore.isViewingExistingMembership) {
      return
    }
    const {
      airLoyaltyProgram,
      flightPreferencesValidator,
      airLoyaltyProgramActionToggle,
      airLoyaltyProgramRemoveToggle,
    } = this.flightPreferencesStore
    const airMembership = flightPreferencesValidator.values.airMembership as GazooMembership[]
    const filteredMemberships = airMembership.filter(
      (item) => item.vendorCode !== airLoyaltyProgram.vendorCode,
    )

    this.handleFieldChange('airMembership')(filteredMemberships)
    airLoyaltyProgramRemoveToggle.handleClose()

    await this.travelPreferencesService.saveFlightPreferences({
      input: this.flightPreferencesStore.flightPreferencesValidator.values,
      handleUpdate,
      handleClose: airLoyaltyProgramActionToggle.handleClose,
    })
    this.flightPreferencesStore.resetAirLoyaltyProgram()
    this.flightPreferencesStore.setIsEditExistingProgramMode(false)
  }

  async fetchAvailableAirVendors() {
    this.flightPreferencesStore.setIsAvailableAirVendorsLoading(true)
    const availableVendors = await this.travelPreferencesService.fetchAvailableVendors(
      VendorTravelVertical.Air,
    )

    this.flightPreferencesStore.setAvailableAirVendors(
      availableVendors?.availableAirVendors || null,
    )
    this.flightPreferencesStore.setIsAvailableAirVendorsLoading(false)
  }

  async fetchFlightSpecialRequests() {
    this.flightPreferencesStore.setIsAvailableFlightSpecialRequestsLoading(true)
    const specialRequests = await this.travelPreferencesService.fetchAvailableSpecialRequets(
      VendorTravelVertical.Air,
    )

    this.flightPreferencesStore.setFlightAvailableSpecialRequests(
      specialRequests?.airSpecialRequests || null,
    )
    this.flightPreferencesStore.setIsAvailableFlightSpecialRequestsLoading(false)
  }
}
