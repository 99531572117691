import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../types'
import { StyledDatePicker, Footer } from './date-picker-modal-mobile-styled'

export function DatePickerModalMobile({
  minDate,
  maxDate,
  isRange,
  isOpen,
  onSelect,
  onDatesSelect,
  onClose,
  startTitle,
  endTitle,
  isEndDateSame,
  highlightDates,
  isFooterVisible,
  footerTitle,
  dates,
  isReturnDate,
  isDepartureDatePickerDisabled,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <Modal.Header isMobile>
        <Header leftSlot={<Header.CloseButton onClick={onClose} />} withBorderBottom>
          <Header.Title title={t('DatePickerModal.ChooseDates')} align="left" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <StyledDatePicker
          isOpen={true}
          isEndDateSame={isEndDateSame}
          minDate={minDate}
          maxDate={maxDate}
          date={dates.date}
          dateEnd={dates.dateEnd}
          isRange={isRange}
          highlightDates={highlightDates}
          onSelect={onDatesSelect}
          startTitle={startTitle}
          endTitle={endTitle}
          isReturnDate={isReturnDate}
          isDepartureDatePickerDisabled={isDepartureDatePickerDisabled}
        />
      </Modal.Body>
      {isFooterVisible && (
        <Footer>
          <Button fullWidth size="normal" onClick={onSelect}>
            {footerTitle}
          </Button>
        </Footer>
      )}
    </Modal>
  )
}
