/* eslint import/no-restricted-paths: 0 */
import { Result } from 'fnscript'
import { Inject, Service } from '@etta/di'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type {
  UpdateHotelTravelPreferenceMutationHookResult,
  HotelTravelPreferenceInput,
  UpdateCarRentalTravelPreferenceMutationHookResult,
  CarRentalTravelPreferenceInput,
  AirTravelPreferenceInput,
  UpdateAirTravelPreferenceMutationHookResult,
  VendorTravelVertical,
} from '@fiji/graphql/hooks'
import { TravelPreferencesActions, TravelPreferencesStore } from '@etta/modules/travel-preferences'
import type { ListItemValueObject } from '@etta/modules/settings/ui/components/icon-items-list/types'
import type { PreferenceOptionsValueObject } from '@etta/modules/travel-preferences/core/value-objects/preference-options.value-object'
import type { LoyaltyProgramValueObject } from '@etta/modules/travel-preferences/core/value-objects/loyalty-program.value-object'
import { SettingsErrors } from '@etta/modules/settings/core/errors/settings.errors'
import { UserProfileService } from '@etta/modules/user/interface/services/get-user-profile.service'
import { SettingsAdapter } from '../../infra/settings.adapter'
import { BaseSettingsStore } from '../stores/base.store'

type SaveHotelPreferencesArgs = {
  input: HotelTravelPreferenceInput
  handleUpdate: UpdateHotelTravelPreferenceMutationHookResult
  handleClose: () => void
}

type SaveCarRentalPreferencesArgs = {
  input: CarRentalTravelPreferenceInput
  handleUpdate: UpdateCarRentalTravelPreferenceMutationHookResult
  handleClose: () => void
}

type SaveFlightPreferencesArgs = {
  input: AirTravelPreferenceInput
  handleUpdate: UpdateAirTravelPreferenceMutationHookResult
  handleClose: () => void
}

@Service()
export class TravelPreferencesService {
  constructor(
    @Inject()
    private readonly preferencesActions: TravelPreferencesActions,
    @Inject()
    private readonly preferencesStore: TravelPreferencesStore,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
    @Inject()
    private userProfileService: UserProfileService,
  ) {}

  getPreferences() {
    return this.preferencesStore.travelPreferences
  }

  getMappedLoyaltyPrograms() {
    const { flight, hotel, carRental } = this.preferencesStore.travelPreferences

    return {
      flight: this.mapLoyaltyProgram(flight.loyaltyProgram),
      hotel: this.mapLoyaltyProgram(hotel.loyaltyProgram),
      carRental: this.mapLoyaltyProgram(carRental.loyaltyProgram),
    }
  }

  getSpecialRequestValues(specialRequests: PreferenceOptionsValueObject[]) {
    if (!specialRequests.length) {
      return '-'
    }

    return specialRequests.map((request) => request.label)
  }

  async saveHotelPreferences({ input, handleUpdate, handleClose }: SaveHotelPreferencesArgs) {
    this.baseSettingsStore.setSavingState()

    const [updatePreferences] = handleUpdate

    try {
      const result = await updatePreferences({
        variables: { input },
      })

      if (!result.data?.hotelTravelPreference.success) {
        this.baseSettingsStore.setErrorState()
        throw new Error(result.data?.hotelTravelPreference.message)
      }
    } catch (e) {
      this.baseSettingsStore.setErrorState()
      return Result.Err(new SettingsErrors.UpdateHotelPreferencesUnexpectedError(e))
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.preferencesActions.refetchTravelPreferences()
  }

  getHotelPreferencesInput(): HotelTravelPreferenceInput {
    const {
      hotel: { specialRequest, loyaltyProgram, hotelAmenity },
    } = this.getPreferences()

    return {
      specialRequest: specialRequest.map(({ value }) => value),
      membership: loyaltyProgram.map(({ vendorCode, number }) => ({
        vendorCode,
        number: number || '',
      })),
      amenity: hotelAmenity || [],
    }
  }

  async saveCarRentalPreferences({
    input,
    handleUpdate,
    handleClose,
  }: SaveCarRentalPreferencesArgs) {
    this.baseSettingsStore.setSavingState()

    const [updatePreferences] = handleUpdate

    try {
      const result = await updatePreferences({
        variables: { input },
      })

      if (!result.data?.carRentalTravelPreference.success) {
        this.baseSettingsStore.setErrorState()
        throw new Error(result.data?.carRentalTravelPreference.message)
      }
    } catch (e) {
      this.baseSettingsStore.setErrorState()
      return Result.Err(new SettingsErrors.UpdateCarRentalPreferencesUnexpectedError(e))
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.preferencesActions.refetchTravelPreferences()
  }

  getCarRentalPreferencesInput(): CarRentalTravelPreferenceInput {
    const {
      carRental: { specialRequest, loyaltyProgram, carClass },
    } = this.getPreferences()

    return {
      specialRequest: specialRequest.map(({ value }) => value),
      membership: loyaltyProgram.map(({ vendorCode, number }) => ({
        vendorCode,
        number: number || '',
      })),
      carClass: carClass,
    }
  }

  async saveFlightPreferences({ input, handleUpdate, handleClose }: SaveFlightPreferencesArgs) {
    this.baseSettingsStore.setSavingState()

    const [updatePreferences] = handleUpdate

    try {
      const result = await updatePreferences({
        variables: {
          input: {
            ...(Object.fromEntries(
              Object.entries(input).filter((item) => item[1]),
            ) as AirTravelPreferenceInput),
            mealPreference: input.mealPreference,
          },
        },
      })

      if (!result.data?.airTravelPreference.success) {
        this.baseSettingsStore.setErrorState()
        throw new Error(result.data?.airTravelPreference.message)
      }
    } catch (e) {
      this.baseSettingsStore.setErrorState()
      return Result.Err(new SettingsErrors.UpdateFlightPreferencesUnexpectedError(e))
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)
    await this.preferencesActions.refetchTravelPreferences()
  }

  getFlightPreferencesInput(): AirTravelPreferenceInput {
    const {
      flight: { loyaltyProgram, homeAirport, meal, seatPreference, specialRequest, noteToArranger },
    } = this.getPreferences()

    return {
      airMembership: loyaltyProgram.map(({ vendorCode, number }) => ({
        vendorCode,
        number: number || '',
      })),

      homeAirport: homeAirport || '',
      mealPreference: meal?.value || '',
      seatPreference: seatPreference || '',

      airSpecialRequest: specialRequest.map(({ value }) => value),
      noteToArranger: noteToArranger || '',
    }
  }

  async fetchAvailableVendors(travelVertical: VendorTravelVertical) {
    const result = await this.settingsAdapter.getAvailableVendors({ travelVertical })

    if (result.isErr()) {
      return
    }

    return result.getValue()
  }

  async fetchAvailableSpecialRequets(travelVertical: VendorTravelVertical) {
    const result = await this.settingsAdapter.getAvailableSpecialRequests({ travelVertical })

    if (result.isErr()) {
      return
    }

    return result.getValue()
  }

  mapLoyaltyProgram(loyaltyProgram: LoyaltyProgramValueObject[]): ListItemValueObject[] {
    return loyaltyProgram.map(({ vendorCode, label, number, imgUrl }) => ({
      number: number || '',
      id: vendorCode,
      label: label || '',
      value: number || '',
      imgUrl: imgUrl || '',
    }))
  }

  async saveAdditionalInformation(additionalInfo: Record<string, any>, handleClose: () => void) {
    this.baseSettingsStore.setSavingState()

    const isUpdateAdditionalInformationSuccessful = await this.userProfileService.saveUserProfileTravelPreferences(
      additionalInfo,
    )
    if (!isUpdateAdditionalInformationSuccessful) {
      this.baseSettingsStore.setErrorState()
      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(600)
    this.baseSettingsStore.dropState(handleClose)
    await delayForSuccess(600)

    this.userProfileService.fetchUserProfile()
  }
}
