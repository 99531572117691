import { Block } from '@etta/ui/block'
import { ScoreListItem } from '../../score-list-item'
import { ANIMATION_DURATION } from '../../use-score-list'
import type { LayoutProps } from '../types'
import { Container, List, ListItem } from './score-list-desktop-styled'

export function ScoreListDesktop({ concernList }: LayoutProps) {
  return (
    <Container>
      <Block marginTop={16} />
      <List>
        {concernList.map((concern) => (
          <ListItem data-tracking-id={`list-item-concern_${concern.label}`} key={concern.label}>
            <ScoreListItem isVisible contentAnimationDuration={ANIMATION_DURATION} {...concern} />
          </ListItem>
        ))}
      </List>
    </Container>
  )
}
