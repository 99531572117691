import { Service, Inject } from '@etta/di'
import { GetRecentSearchesAdapter } from '../../infra/get-recent-search.adapter'
import { RecentSearchesStore } from '../stores/recent-searches.store'

@Service()
export class GetRecentSearchesService {
  constructor(
    @Inject() private recentSearchesStore: RecentSearchesStore,
    @Inject() private getRecentSearchesAdapter: GetRecentSearchesAdapter,
  ) {}

  async getCarRetntalRecentSearch() {
    this.recentSearchesStore.setIsLoadingRecentCarRentalSearches(true)
    const result = await this.getRecentSearchesAdapter.getCarRentalRecentSearches()

    result.match({
      Ok: (recentSearches) => {
        this.recentSearchesStore.setRecentCarRentalSearches(recentSearches)
        this.recentSearchesStore.setIsLoadingRecentCarRentalSearches(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentCarRentalSearches(false)
      },
    })
  }

  async getFlightRecentSearch() {
    this.recentSearchesStore.setIsLoadingRecentFlighSearches(true)
    const result = await this.getRecentSearchesAdapter.getFlightRecentSearches()

    result.match({
      Ok: (recentSearches) => {
        this.recentSearchesStore.setRecentFlighSearches(recentSearches)
        this.recentSearchesStore.setIsLoadingRecentFlighSearches(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentFlighSearches(false)
      },
    })
  }

  async getHotelRecentSearch() {
    this.recentSearchesStore.setIsLoadingRecentHotelSearches(true)
    const result = await this.getRecentSearchesAdapter.getHotelRecentSearches()

    result.match({
      Ok: (recentSearches) => {
        this.recentSearchesStore.setRecentHotelSearches(recentSearches)
        this.recentSearchesStore.setIsLoadingRecentHotelSearches(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentHotelSearches(false)
      },
    })
  }

  async getRailRecentSearch() {
    this.recentSearchesStore.setIsLoadingRecentRailSearches(true)
    const result = await this.getRecentSearchesAdapter.getRailRecentSearches()

    result.match({
      Ok: (recentSearches) => {
        this.recentSearchesStore.setRecentRailSearches(recentSearches)
        this.recentSearchesStore.setIsLoadingRecentRailSearches(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentRailSearches(false)
      },
    })
  }

  async getCarRentalRecentSearchWithoutDates() {
    this.recentSearchesStore.setIsLoadingRecentCarRentalSearchesWithoutDates(true)
    const result = await this.getRecentSearchesAdapter.getCarRentalRecentSearchesWithoutDates()

    result.match({
      Ok: (recentSearchesWithoutDates) => {
        this.recentSearchesStore.setRecentCarRentalSearchesWithoutDates(recentSearchesWithoutDates)
        this.recentSearchesStore.setIsLoadingRecentCarRentalSearchesWithoutDates(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentCarRentalSearchesWithoutDates(false)
      },
    })
  }

  async getHotelRecentSearchWithoutDates() {
    this.recentSearchesStore.setIsLoadingRecentHotelSearches(true)
    const result = await this.getRecentSearchesAdapter.getHotelRecentSearchesWithoutDates()

    result.match({
      Ok: (recentSearchesWithoutDates) => {
        this.recentSearchesStore.setRecentHotelSearchesWithoutDates(recentSearchesWithoutDates)
        this.recentSearchesStore.setIsLoadingRecentHotelSearches(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentHotelSearches(false)
      },
    })
  }

  async getFlightRecentSearchWithoutDates() {
    this.recentSearchesStore.setIsLoadingRecentHotelSearchesWithoutDates(true)
    const result = await this.getRecentSearchesAdapter.getFlightRecentSearchesWithoutDates()

    result.match({
      Ok: (recentSearchesWithoutDates) => {
        this.recentSearchesStore.setRecentFlightSearchesWithoutDates(recentSearchesWithoutDates)
        this.recentSearchesStore.setIsLoadingRecentHotelSearchesWithoutDates(false)
      },
      Err: () => {
        this.recentSearchesStore.setIsLoadingRecentHotelSearchesWithoutDates(false)
      },
    })
  }
}
