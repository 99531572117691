import type { CalculateTripEmissionsResult } from '@fiji/graphql/types'
import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import type { Result } from './types'
import { tonnesToKg } from './utils'
import { getHotelsEmissionsSummary } from './hotel'
import { getFlightEmissionsSummary } from './flight'
import { getCarRentalEmissionsSummary } from './car-rental'
import { getRailEmissionsSummary } from './rail'

type Props = {
  segments: TripSegmentValueObject[]
  tripEmissionsResult: CalculateTripEmissionsResult
}

export function useRtpEcoCheckSummary({ segments, tripEmissionsResult }: Props): Result {
  const hotelEmissionsData = getHotelsEmissionsSummary(segments, tripEmissionsResult.hotels)
  const flightEmissionsData = getFlightEmissionsSummary(segments, tripEmissionsResult.flights)
  const carRentalEmissionsData = getCarRentalEmissionsSummary(segments, tripEmissionsResult.cars)
  const railEmissionsData = getRailEmissionsSummary(segments, tripEmissionsResult.rails)

  const tripEmissionsTonnesSummary =
    hotelEmissionsData.tonnesOfEmissionsSummary +
    flightEmissionsData.tonnesOfEmissionsSummary +
    carRentalEmissionsData.tonnesOfEmissionsSummary +
    railEmissionsData.tonnesOfEmissionsSummary

  const tripEmissionsAverageTonnesSummary =
    hotelEmissionsData.averageTonnesOfEmissionsSummary +
    flightEmissionsData.averageTonnesOfEmissionsSummary +
    carRentalEmissionsData.averageTonnesOfEmissionsSummary +
    railEmissionsData.averageTonnesOfEmissionsSummary

  const treeSeedlingsEquivalenceTotalCount =
    hotelEmissionsData.treeSeedlingsEquivalenceTotalCount +
    flightEmissionsData.treeSeedlingsEquivalenceTotalCount +
    carRentalEmissionsData.treeSeedlingsEquivalenceTotalCount +
    railEmissionsData.treeSeedlingsEquivalenceTotalCount

  const plasticBagsEquivalenceTotalCount =
    hotelEmissionsData.plasticBagsEquivalenceTotalCount +
    flightEmissionsData.plasticBagsEquivalenceTotalCount +
    carRentalEmissionsData.plasticBagsEquivalenceTotalCount +
    railEmissionsData.plasticBagsEquivalenceTotalCount

  const smartphoneChargeEquivalenceTotalCount =
    hotelEmissionsData.smartphoneChargeEquivalenceTotalCount +
    flightEmissionsData.smartphoneChargeEquivalenceTotalCount +
    carRentalEmissionsData.smartphoneChargeEquivalenceTotalCount +
    railEmissionsData.smartphoneChargeEquivalenceTotalCount

  const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
    tonnesOfEmissions: tripEmissionsTonnesSummary,
    averageEmissionsTonnes: tripEmissionsAverageTonnesSummary,
  })

  return {
    tripEmissions: {
      summaryKg: tonnesToKg(tripEmissionsTonnesSummary),
      deviationLevel,
      percentDeviation: percentDeviation,
    },
    hotelEmissions: hotelEmissionsData.hotelEmissions,
    flightEmissions: flightEmissionsData.flightEmissions,
    carRentalEmissions: carRentalEmissionsData.carRentalEmissions,
    railEmissions: railEmissionsData.railEmissions,
    equivalences: [
      {
        type: CalculateEmissionsBatchResultEquivalenceType.PlasticBag,
        amount: plasticBagsEquivalenceTotalCount,
        unit: '',
      },
      {
        type: CalculateEmissionsBatchResultEquivalenceType.Seedling,
        amount: treeSeedlingsEquivalenceTotalCount,
        unit: '',
      },
      {
        type: CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge,
        amount: smartphoneChargeEquivalenceTotalCount,
        unit: '',
      },
    ],
  }
}
