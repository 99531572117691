import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'

export const Wrapper = styled.div`
  margin-top: 16px;
  padding: 12px 0;
  border-top: 1px dashed ${(props) => props.theme.colors.borderDark};
  border-bottom: 1px dashed ${(props) => props.theme.colors.borderDark};
`

export const Description = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: normal;
  gap: 4px;
`

export const Cost = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
`

export const RemoveButton = styled(Button)`
  margin: 0;
  padding: 0;
  text-align: left;
`

export const ApplyUnusedTicketsWrapper = styled.div<{ mobileView: boolean }>`
  height: 48px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  margin-top: 16px;
  ${({ mobileView, theme }) =>
    mobileView
      ? css`
          background-color: ${(props) => props.theme.colors.white};
        `
      : css`
          border: 1px solid ${theme.colors.borderLight};
          border-radius: 8px;
        `}

  &:hover {
    cursor: pointer;
  }
`
