import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { RideHailItinerarySegment } from '@etta/modules/ride-hail/core'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { Container, Detail, Title, DetailLine } from './mobility-itinerary-segment-styled'

type Prop = {
  segment: RideHailItinerarySegment
}

const i18nBase = 'Mobility.ItineraryDialog'
const DATE_FORMAT = 'MMM d, h:mm aa'

export function MobilityItineraryRideHailSegment({ segment }: Prop) {
  const { t } = useTranslation()

  const pickUpDate = segment.pickUpDateTime
    ? getFormattedTime(new Date(deleteTimezone(String(segment.pickUpDateTime))), DATE_FORMAT)
    : null

  return (
    <Container>
      <Icon name={'rideHailPWA'} />
      <Detail>
        <Title variant="footnoteStrong" color="mainText" textOverflow="ellipsis">
          {segment.description}
        </Title>
        {pickUpDate && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.PickUp`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {pickUpDate}
            </Text>
          </DetailLine>
        )}
      </Detail>
    </Container>
  )
}
