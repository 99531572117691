import i18n from 'i18next'
import type { ViewStateStatus } from '@etta/modules/seat-map/core/types'

const i18nBase = 'AirSeatMap.ViewState'

const titleLocales: Record<ViewStateStatus, string> = {
  saving: `${i18nBase}.Save`,
  saved: `${i18nBase}.Save`,
  removing: `${i18nBase}.Remove`,
  removed: `${i18nBase}.Update`,
  error: `${i18nBase}.ErrorTitle`,
  hidden: '',
}

const textLocales: Record<string, string> = {
  error: `${i18nBase}.ErrorText`,
}

export function getModalText(status: ViewStateStatus) {
  return {
    title: i18n.t(titleLocales[status]),
    text: i18n.t(textLocales[status]),
  }
}
