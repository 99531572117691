import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { CreateSearchDocument, GetHotelDocument, GetHotelsDocument } from '@fiji/graphql/hooks'
import type {
  CreateSearchMutation,
  CreateSearchMutationVariables,
  GetHotelQuery,
  GetHotelQueryVariables,
  GetHotelsQuery,
  GetHotelsQueryVariables,
} from '@fiji/graphql/types'

@DataProvider()
export class HotelsDataProvider extends GraphqlDataProvider {
  createHotelSearch(input: CreateSearchMutationVariables) {
    return this.client.mutate<CreateSearchMutation, CreateSearchMutationVariables>({
      mutation: CreateSearchDocument,
      variables: input,
    })
  }

  getHotels(input: GetHotelsQueryVariables, forceRefetch = false) {
    return this.client.query<GetHotelsQuery, GetHotelsQueryVariables>({
      query: GetHotelsDocument,
      variables: input,
      fetchPolicy: forceRefetch ? 'network-only' : 'cache-first',
    })
  }

  getHotelById(input: GetHotelQueryVariables) {
    return this.client.query<GetHotelQuery, GetHotelsQueryVariables>({
      query: GetHotelDocument,
      variables: input,
      fetchPolicy: 'network-only',
    })
  }
}
