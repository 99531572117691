import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TripStatus } from '@fiji/graphql/types'
import { useUserContext } from '@etta/modules/user'
import { capitalizeFirstLetter } from '@fiji/utils/capitalize-first-letter'

type Props = {
  tripStatus?: TripStatus | null
}

const i18nBase = 'PostBooking.RideHailDetails.RailHailStatus'

export function useTraveler({ tripStatus }: Props) {
  const { t } = useTranslation()

  const { userStore } = useUserContext()

  const profile = userStore.profile

  const tripStatusText = useMemo(() => {
    switch (tripStatus) {
      case TripStatus.Cancelled:
        return t(`${i18nBase}.Cancelled`)
      case TripStatus.Completed:
        return t(`${i18nBase}.Completed`)
      default:
        return ''
    }
  }, [t, tripStatus])

  return {
    tripStatusText,
    isTripConfirm: tripStatus !== TripStatus.Cancelled && tripStatus !== TripStatus.Completed,
    isTripCancelled: tripStatus === TripStatus.Cancelled,
    isTripComplete: tripStatus === TripStatus.Completed,
    travelerFullName: `${capitalizeFirstLetter(profile?.firstName ?? '')}  ${capitalizeFirstLetter(
      profile?.lastName ?? '',
    )}`,
  }
}
