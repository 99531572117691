import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useExpand } from '@fiji/hooks/use-expand/use-expand'
import { ShowMoreContainer } from '@etta/ui/show-more-container'
import type { OverlappingTrip, TripDetailsSegment } from '@fiji/graphql/types'
import { useTripDuplicate } from '../../../use-trip-duplicate'
import { DuplicateSegmentLoader } from './duplicate-segment-loader'
import { DuplicateSegment } from './duplicate-segment'
import {
  DuplicateContainer,
  TripName,
  CollapseButton,
  DatesLoader,
  TripDates,
  HeaderContainer,
  CollapsedContainer,
  BodyContainer,
} from './duplicate-list-styled'

type Props = {
  overlappingTrip: OverlappingTrip
  setExpandedTrip: (num: number) => void
  expandedTrip?: number
}

export function Duplicate({ overlappingTrip, setExpandedTrip, expandedTrip }: Props) {
  const { isExpanded, toggleExpansion } = useExpand()

  const collapse = () => {
    if (!isExpanded) {
      setExpandedTrip(overlappingTrip.id)
    }

    toggleExpansion()
  }

  useEffect(() => {
    if (expandedTrip !== overlappingTrip.id && isExpanded) {
      toggleExpansion()
    }
  }, [expandedTrip, isExpanded, toggleExpansion, overlappingTrip])

  const { isLoading, formattedDate, trip } = useTripDuplicate({ overlappingTrip })

  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'
  return (
    <ShowMoreContainer
      isAnimated={true}
      isExpanded={isExpanded}
      expandedSlot={
        <DuplicateContainer>
          <HeaderContainer>
            <TripName>{overlappingTrip.name}</TripName>
            {isLoading ? <DatesLoader /> : <TripDates>{formattedDate}</TripDates>}
          </HeaderContainer>
          <BodyContainer>
            {isLoading ? (
              <>
                <DuplicateSegmentLoader />
                <DuplicateSegmentLoader />
                <DuplicateSegmentLoader />{' '}
              </>
            ) : (
              <>
                {trip?.segments?.map((segment) => {
                  return (
                    <DuplicateSegment
                      key={segment.position}
                      segment={segment as TripDetailsSegment}
                    />
                  )
                })}
              </>
            )}
            <CollapseButton variant="text" paddings={false} onClick={collapse}>
              {t(`${i18Base}SeeLess`)}
            </CollapseButton>
          </BodyContainer>
        </DuplicateContainer>
      }
      collapsedSlot={
        <DuplicateContainer>
          <CollapsedContainer>
            <TripName>{overlappingTrip.name}</TripName>
            {isLoading ? <DatesLoader /> : <TripDates>{formattedDate}</TripDates>}
            <CollapseButton variant="text" paddings={false} onClick={collapse}>
              {t(`${i18Base}SeeMore`)}
            </CollapseButton>
          </CollapsedContainer>
        </DuplicateContainer>
      }
    />
  )
}
