import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'

export const Content = styled(Text).attrs(() => ({
  variant: 'regularText',
  isBlock: true,
}))`
  margin: 12px 0 0;
  opacity: 0.7;
  color: ${(props) => props.theme.primaryColor};
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 17px;
`

export const StyledButton = styled(Button)`
  padding-left: 4rem;
  padding-right: 4rem;
`
