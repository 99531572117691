import { Action, Inject } from '@etta/di'
import { HistoryStore } from '@etta/interface/stores/history.store'
import { ReviewTripStore } from '@etta/modules/review-trip/interface/stores/review-trip-page.store'
import { CreateTripService } from '@etta/modules/review-trip/interface/services/create-trip.service'
import type {
  AirSearchQueryType,
  Flight,
  FlightType,
  SelectedFlight,
} from '@fiji/hooks/search-queries/use-air-search-query/types'
import { ROUTES } from '@fiji/routes'
import type { TripStateStatus } from '@etta/core/entities'
import type { FareData } from '@fiji/types'
import { getAirlinesLogos } from '@fiji/hooks/use-adaptive-flight/use-search-adaptive-flight/getters/get-airlines-logos'
import { deleteTimezone } from '@fiji/utils/dates'
import { getFlightStops } from '@fiji/hooks/use-adaptive-flight/use-search-adaptive-flight/getters/get-flight-stops'
import type { FlightLegValueObject } from '@etta/core/value-objects/search-flight-leg.value-object'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core/entity/flight-segment.entity'
import { AirSearchQueryService } from '../services/air-search-query/air-search-query.service'
import { AirSearchService } from '../services/air-search/air-search.service'
import { AirSearchQueryStore } from '../stores/air-search-query.store'
import { AirFiltersStore } from '../stores/air-search/air-filters.store'
import { AirSearchStore } from '../stores/air-search/air-search.store'
import type {
  AppendSelectFlightArgs,
  SelectedFlightDetails,
  MapToSelectedFlightArgs,
} from '../types'
import { AirCheckFarePolicyStore } from '../stores/air-check-fare-policy/air-check-fare-policy.store'
import { AirDynamicSiteMessagesService } from '../services/air-dynamic-site-messages/air-dynamic-site-messages.service'
import type { FlightLegSearchInputValueObject } from '../../core/value-objects/flight-leg-search.input'
import { AirSearchFormStore } from '../stores/air-search-form.store'
import { mapToFlightSegmentInfo } from '../services/air-search/air-search-selected-flight.mapper'

@Action()
export class AirSearchActions {
  constructor(
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private airSearchFormStore: AirSearchFormStore,
    @Inject() private airSearchService: AirSearchService,
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airSearchQueryService: AirSearchQueryService,
    @Inject() private createTripService: CreateTripService,
    @Inject() private historyStore: HistoryStore,
    @Inject() private tripStore: ReviewTripStore,
    @Inject() private airFiltersStore: AirFiltersStore,
    @Inject() private airCheckFarePolicyStore: AirCheckFarePolicyStore,
    @Inject() private airDynamicSiteMessagesService: AirDynamicSiteMessagesService,
  ) {}

  closeOutOfPolicyModal() {
    this.airSearchStore.outOfPolicyModalToggle.handleClose()
    this.airCheckFarePolicyStore.dropStore()
  }

  public async createAirSearch(flightLegSearch: FlightLegSearchInputValueObject[] | null = null) {
    await this.airSearchService.createAirSearch(flightLegSearch)

    if (!this.airSearchStore.searchId) {
      this.appendQueryParams({ searchId: null })
      return
    }

    this.appendQueryParams({ searchId: this.airSearchStore.searchId })
  }

  /**
   * Eager flight search feature :
   * When a user clicks to open custom fields during a flight search, initiate a background search.
   */
  public async searchInBackground(searchFlights?: Flight[], searchFlightType?: FlightType) {
    const flightLegSearch = this.airSearchFormStore.getFightLegSearchInput(
      searchFlights,
      searchFlightType,
    ).flightLegSearch
    await this.createAirSearch(flightLegSearch)
    if (!this.airSearchStore.isLoading && this.airSearchStore.searchId) {
      this.airSearchStore.setLegNumber(0)
      this.airSearchStore.setIsBackgroundSearchDataReady(true)
      this.getFlights()
    }
  }

  public async getFlights(forceRefetch = true) {
    const newFilters = this.airSearchQueryStore.isPreSearchFilters
      ? this.airSearchStore.preSearchFilters
      : this.airSearchQueryStore.additionalQueries.filters

    await this.airSearchService.getFlights({ filters: newFilters }, forceRefetch)
    this.airDynamicSiteMessagesService.getDynamicSiteMessages()

    const filters = this.initializeFilters()

    this.airFiltersStore.setFilters(filters)

    await new Promise((res) =>
      res(
        this.appendQueryParams({
          filters,
          flights: this.initializeFlights(),
        }),
      ),
    )
  }

  public handleSetSelectedBrandedFareLabel(value: string) {
    this.airSearchStore.setSelectedBrandedFareLabel(value)
  }

  public handleSetSelectedFareData(value: FareData) {
    this.airSearchStore.setSelectedFareData(value)
  }

  public handleResetSelectedFareData() {
    this.airSearchStore.setSelectedFareData(null)
  }

  public handleSetShowActiveItineraryModal(value: boolean) {
    this.airSearchStore.setShowActiveItineraryModal(value)
  }

  public handleUpdateFlightStatus(value: TripStateStatus, tripId: string) {
    this.handleAddSelectedFaresStatus(tripId, value)
    this.handleSetSelectFlightStatus(value)
  }

  public handleUpdateIsPartialSearchResults(value: boolean) {
    this.airSearchStore.isPartialSearchResults = value
  }

  public handleAppendSelectFlight({
    selectedFare,
    seats,
    selectedLegId,
    selectedLeg,
    onBeforeSuccess,
    headerTitle,
    brandedFare,
  }: AppendSelectFlightArgs) {
    onBeforeSuccess()
    const legNumber = this.airSearchStore.legNumber
    if (!selectedLeg || legNumber === null) {
      return
    }

    const selectedFlights = this.airSearchQueryStore.selectedFlights

    const selectedBrandedFareLabel = brandedFare || this.airSearchStore.selectedBrandedFareLabel

    const newSelectedFlight: SelectedFlight | null = this.mapToSelectedFlight({
      selectedFare,
      selectedLegId,
      selectedBrandedFareLabel,
      selectedLeg: selectedLeg!,
      seats,
      headerTitle,
    })

    if (!newSelectedFlight) {
      return
    }

    const updatedSelectedFlight = (selectedFlights || []).slice(0, legNumber + 1)
    updatedSelectedFlight[legNumber] = newSelectedFlight

    const previousLegFilters = this.airSearchQueryStore.filters
    const nextLegFilters = this.airSearchQueryStore.nextLegFilters

    this.airSearchQueryService.navigateTo(ROUTES.air.results({ legNumber: legNumber + 1 }), {
      selectedFlights: updatedSelectedFlight,
      filters: nextLegFilters,
      previousLegFilters: nextLegFilters ? previousLegFilters : undefined,
    })
  }

  public mapToSelectedFlight({
    selectedFare,
    selectedLegId,
    selectedBrandedFareLabel,
    selectedLeg,
    seats,
    headerTitle,
  }: MapToSelectedFlightArgs): SelectedFlight | null {
    if (!selectedLeg) {
      return null
    }

    const {
      destination,
      origin,
      flightTime,
      totalTime,
      segments = [],
      connectionInfo,
      arrivalDate,
      departureDate,
    } = selectedLeg

    return {
      id: selectedLegId,
      selectedFareTier: selectedFare.tier.id || '',
      selectedFareTierName: selectedFare.tier.name || '',
      selectedFareId: selectedFare.fareId || '',
      selectedServiceClasses: selectedFare.segments?.map((segment) => segment.serviceClass) || [''],
      selectedBrandedFare: selectedBrandedFareLabel,
      selectedSeats: seats,
      isRefundable: selectedFare.isRefundable,
      stops: getFlightStops(connectionInfo, segments),
      departurePlaceCode: origin,
      returnPlaceCode: destination,
      flightTime,
      flightLogos: getAirlinesLogos(segments),
      headerTitle,
      arrivalDate: deleteTimezone(arrivalDate),
      departureDate: deleteTimezone(departureDate),
      totalTime,
      isOutOfPolicy: selectedFare.showOutOfPolicy,
      totalPrice: selectedFare.total,
      segments: selectedLeg.segments.map(mapToFlightSegmentInfo),
    }
  }

  public setSelectedFlights(flights: SelectedFlight[]) {
    this.airSearchStore.setSelectedFlights(flights)
  }

  public resetSelectedFlights() {
    this.airSearchStore.resetSelectedFlights()
  }

  public async createTrip() {
    const tripId = this.airSearchQueryStore.additionalQueries.itineraryId

    if (tripId) {
      this.tripStore.setTripId(tripId)
      return tripId
    }

    await this.createTripService.createTrip()

    this.appendQueryParams({ itineraryId: this.tripStore.tripId })
    return this.tripStore.tripId
  }

  public handleSetSearchId(searchId: string) {
    this.airSearchStore.setSearchId(searchId)
  }

  public async fetchMoreFlights() {
    await this.airSearchService.getMoreFlights()
  }

  public async refetchFlights() {
    await this.airSearchService.getFlights({}, true)
  }

  public async handleReloadSearchResults() {
    await this.createAirSearch()
    this.getFlights()
  }

  public setLegNumber = (legNumber: number) => {
    this.airSearchStore.setLegNumber(legNumber)
  }

  public handleSetSelectedFlightDetails(flightDetails: SelectedFlightDetails) {
    this.airSearchStore.setSelectedFlightDetails(flightDetails)
  }

  public handleClearSelectedFlightDetails() {
    this.airSearchStore.setSelectedFlightDetails(null)
  }

  public handleSetSelectFlightStatus(value: TripStateStatus) {
    this.airSearchStore.setSelectFlightStatus(value)
  }

  public handleResetSelectFlightStatus() {
    this.airSearchStore.setSelectFlightStatus(null)
  }

  public handleCheckTripChanged() {
    const tripId = this.airSearchQueryStore.additionalQueries.itineraryId

    if (tripId && tripId !== this.tripStore.tripId) {
      this.tripStore.dropTripStore()
      this.tripStore.setTripId(tripId)
    }
  }

  public handleClearSearchId(itineraryId: string) {
    if (this.airSearchQueryStore.additionalQueries.itineraryId !== itineraryId) {
      this.airSearchStore.setSearchId(null)
    }
  }

  public async getFareAttributes(flightLeg: FlightLegValueObject[], itinerary?: string) {
    await this.airSearchService.getFareAttributes(flightLeg, itinerary)
  }

  public async getFareAttributesForItineraryFlights(
    flightLeg: Pick<FlightSegmentEntity, 'segments' | 'legId'>[],
    itinerary?: string,
    forceRefetch: boolean = false,
  ) {
    await this.airSearchService.getFareAttributesForItineraryFlights(
      flightLeg,
      itinerary,
      forceRefetch,
    )
  }

  private initializeFilters() {
    const appliedStop = this.airSearchStore.filterOptions?.stops.find((stop) => stop.isSelected)

    return {
      ...this.airSearchStore.afterSearchFilters,
      ...this.airSearchQueryStore.additionalQueries.filters,
      stops: appliedStop ? [appliedStop] : this.airSearchStore.afterSearchFilters.stops,
    }
  }

  initializeFlights(): Flight[] {
    if (!this.airSearchStore.flightResult?.isCustomTimeReplaced) {
      return this.airSearchQueryStore.searchQueryFlights
    }
    const legNumber = this.airSearchStore.legNumber!

    const updatedFlights = this.airSearchQueryStore.searchQueryFlights.map((flight, index) => {
      if (index === legNumber) {
        return flight
      }

      return flight
    })

    return updatedFlights
  }

  resetBackgroundSearch() {
    this.airSearchStore.setIsBackgroundSearchDataReady(false)
  }

  private appendQueryParams(params: Partial<AirSearchQueryType>) {
    if (!this.isSearchResultPage()) {
      return
    }

    this.airSearchQueryService.appendQueryParams(params)
  }

  private isSearchResultPage() {
    return this.historyStore.matchPathname(ROUTES.air.results({ legNumber: 0 }).slice(0, 25))
  }

  private handleAddSelectedFaresStatus(fareId: string, status: TripStateStatus) {
    this.airSearchStore.addSelectedFareDetails(fareId, status)
  }
}
