import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { Container } from './container'
import type { ViewState } from './types'
import { StateLoading } from './state-loading'
import { StateError } from './state-error'
import { StateSaved } from './state-saved'
import { StateDownloaded } from './state-downloaded'

type Props = {
  'data-tracking-id'?: string
  viewState: ViewState
  title?: string
  text?: string
  error?: string
  onDone?: () => void
  children?: ReactNode
}

export function ViewStateModalContent({
  viewState,
  title,
  text,
  error,
  onDone,
  'data-tracking-id': dataTrackingId,
  children,
}: Props) {
  const child = useMemo(() => {
    switch (viewState) {
      case 'saving':
        return <StateLoading title={title} viewState={viewState} />
      case 'removing':
        return <StateLoading title={title} viewState={viewState} />
      case 'loading':
        return <StateLoading title={title} viewState={viewState} />
      case 'downloading':
        return <StateLoading title={title} viewState={viewState} />
      case 'error':
        return <StateError title={error} text={text} onClick={onDone} viewState={viewState} />
      case 'ride-hail-error':
        return <StateError title={error} onClick={onDone} viewState={viewState} />
      case 'ride-hail-cancel-already-complete-error':
        return <StateError title={error} onClick={onDone} viewState={viewState} />
      case 'saved':
        return <StateSaved title={title} />
      case 'downloaded':
        return <StateDownloaded title={title} />
      case 'check-availability':
        return <StateLoading title={title} viewState={viewState} />
      case 'custom':
        return children
      default:
        return null
    }
  }, [viewState, title, text, error, onDone, children])

  return <Container data-tracking-id={dataTrackingId}>{child}</Container>
}
