import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import { Text } from '@etta/ui/text'
import type { CarRentalVendorInformationLayoutProps } from '../../types'
import {
  Container,
  Section,
  CarVendorLogo,
  Information,
  InformationLabel,
  LogoImage,
  Row,
  LeftSection,
  RightSection,
} from './car-rental-vendor-information-desktop-styled'

export function CarRentalVendorInformationDesktop({
  vendorInformation,
}: CarRentalVendorInformationLayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalModal.'

  return (
    <Container>
      <Section>
        {vendorInformation.imageUrl && (
          <CarVendorLogo>
            <LogoImage src={vendorInformation.imageUrl} alt={vendorInformation.title} />
          </CarVendorLogo>
        )}
        <Text aria-label={vendorInformation.title} color="mainText" variant="footnoteMedium">
          {vendorInformation.title}
        </Text>
      </Section>
      <Row>
        {vendorInformation.phone && (
          <LeftSection>
            <Information aria-label={vendorInformation.phone}>
              <div>
                <Link href={`tel:${vendorInformation.phone}`}>
                  <InformationLabel>
                    <Text variant="captionMedium" color="bodyText">
                      {t(i18Base + 'CallEnterprise')}
                    </Text>
                  </InformationLabel>
                  <Text variant="subHeadStrong" color="mainText">
                    {vendorInformation.phone}
                  </Text>
                </Link>
              </div>
              <Link href={`tel:${vendorInformation.phone}`}>
                <Icon name="phonePWA" color="bodyText" size="medium" />
              </Link>
            </Information>
          </LeftSection>
        )}
        {vendorInformation.workingHours && (
          <RightSection>
            <Information aria-label={vendorInformation.workingHours}>
              <div>
                <InformationLabel>
                  <Text variant="captionMedium" color="bodyText">
                    {t(i18Base + 'DeskTimings')}
                  </Text>
                </InformationLabel>
                <Text variant="subHeadStrong">{vendorInformation.workingHours}</Text>
              </div>
              <Text variant="captionMedium" color="bodyText">
                <Icon name="timePWA" size="medium" />
              </Text>
            </Information>
          </RightSection>
        )}
      </Row>
    </Container>
  )
}
