import { observer } from 'mobx-react-lite'
import { InfiniteScroll } from '@etta/ui/infinite-scroll'
import { HotelCard } from '@etta/components/hotel-card/hotel-card'
import { HotelRateCap } from '@etta/components/hotel-rate-cap/hotel-rate-cap'
import { HotelCardSkeleton } from '@etta/components/hotel-card/skeleton'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import type { LayoutProps } from '../types'
import { getHotelEmission } from '../../../../use-hotel-result'
import {
  Container,
  ContainerScroll,
  List,
  SkeletonContainer,
  SpaceContainer,
} from './hotel-list-results-mobile-styled'

export const HotelListResultsMobile = observer(function HotelListResultsMobile({
  headerSlot,
  handleOpen,
  hotelsEmissions,
}: LayoutProps) {
  const { hotelSearchResultsStore, loadHotelsAction } = useHotelSearchResultsContext()

  if (hotelSearchResultsStore.isLoading) {
    return (
      <SkeletonContainer>
        <HotelCardSkeleton />
        <HotelCardSkeleton />
        <HotelCardSkeleton />
      </SkeletonContainer>
    )
  }

  return (
    <ContainerScroll>
      <Container>
        {headerSlot}
        <HotelRateCap perDiemRateCap={hotelSearchResultsStore.hotelResults?.perDiemRate} />
        <InfiniteScroll
          isLoading={hotelSearchResultsStore.isFetchMoreLoading}
          hasNextPage={hotelSearchResultsStore.isNextPage}
          onFetchMore={loadHotelsAction.loadMoreHotels}
          spinnerVariant="hotel">
          <List>
            <InfiniteScroll.List withAnimation={!hotelSearchResultsStore.isFetchMoreLoading}>
              {hotelSearchResultsStore.hotels.map((hotel) => {
                return (
                  <HotelCard
                    key={hotel.id}
                    hotel={hotel}
                    onClick={handleOpen}
                    as="li"
                    hotelEmission={getHotelEmission({ hotelId: hotel.id, hotelsEmissions })}
                  />
                )
              })}
            </InfiniteScroll.List>
            {hotelSearchResultsStore.hotels.length ===
              hotelSearchResultsStore.hotelResults?.totalCount && <SpaceContainer />}
          </List>
        </InfiniteScroll>
      </Container>
    </ContainerScroll>
  )
})
