import type { UserPersonalAddressValueObject } from '@etta/modules/user/core/value-objects/user-personal-address.value-object'
import { default as StateCodesForUSA } from '../../core/constants/state-codes-for-usa.json'
import { default as CountryCodesForShortcodes } from '../../core/constants/country-codes-shortcodes.json'

export function getFullCountry(code?: string | null) {
  if (!code) {
    return null
  }
  return CountryCodesForShortcodes.find((pair) => pair.value === code)?.label || code
}

function getFullStateUS(code?: string | null) {
  if (!code) {
    return null
  }
  return StateCodesForUSA.find((pair) => pair.value === code)?.label || code
}

export function getAddressFromProfile(address?: UserPersonalAddressValueObject | null) {
  if (!address) {
    return ''
  }

  const { city, countryCode, stateCode, postalCode, mailStop, street1, street2 } = address
  const fullAddressComposition = [
    mailStop || street1,
    street2,
    !mailStop ? postalCode : null,
    city,
    getFullStateUS(stateCode),
    getFullCountry(countryCode),
  ].filter(Boolean)

  return fullAddressComposition.join(', ')
}
