import styled from 'styled-components'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 14px;
  padding: 0 16px;
`

export const Section = styled.div`
  padding: 16px 0;
  border-bottom: 1px dashed ${(props) => props.theme.colors.borderDark};

  &:last-child {
    border: 0;
  }
`

export const CarVendorLogo = styled.div`
  margin: 0 0 2px;
`

export const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InformationLabel = styled.div`
  margin: 0 0 2px;
`

export const LogoImage = styled.img`
  max-width: 100px;
`
