import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import { EcoCheckEquivalencesLayout } from './layout'
import type { LayoutProps } from './types'

export function EcoCheckEquivalences(props: LayoutProps) {
  const {
    featureFlagsStore: {
      flags: { isNewEmissionsBrandingEnabled },
    },
  } = useFeatureFlagsContext()
  const EquivalenceType = isNewEmissionsBrandingEnabled
    ? CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge
    : CalculateEmissionsBatchResultEquivalenceType.Seedling
  const equivalences = [...props.equivalences].sort((a) => (a.type === EquivalenceType ? -1 : 1))
  return <EcoCheckEquivalencesLayout {...props} equivalences={equivalences} />
}
