import { Result } from 'fnscript'
import { Action, Inject } from '@etta/di'
import { UserStore } from '@etta/modules/user'
import type { PassportInput } from '../stores'
import { PassportStore } from '../stores'
import { PassportService } from '../services'
import type { AddPassportArgs, RemovePassportArgs } from '../types'

type Passport = {
  country?: string | null
  expirationDate?: string | null
  id?: number | null
  issueDate?: string | null
  issuingCountry?: string | null
  number: string | null
  unmaskedNumber?: string | null
}

@Action()
export class PassportActions {
  constructor(
    @Inject()
    private readonly passportStore: PassportStore,

    @Inject()
    private readonly passportService: PassportService,

    @Inject()
    private readonly userStore: UserStore,
  ) {}

  async addPassport(args: AddPassportArgs) {
    if (this.userStore.isGuest) {
      this.passportStore.setGuestPassportInput(args.passport)
      return Result.Ok({
        code: '200',
        success: true,
        message: 'Success',
      })
    }

    return this.passportService.addPassport(args)
  }

  async removePassport(args: RemovePassportArgs) {
    if (this.userStore.isGuest) {
      this.passportStore.setGuestPassportInput(null)
      return Result.Ok({
        code: '200',
        success: true,
        message: 'Success',
      })
    }

    return this.passportService.removePassport(args)
  }

  handleFieldChange<T extends keyof PassportInput>(field: T) {
    return (value: PassportInput[T]) => {
      return this.passportStore.setPassportInputValue(field, value)
    }
  }

  updateSelectedPassportId(id: number | null) {
    this.passportStore.updateSelectedPassportId(id)
  }

  setPassportInput(passport?: Passport | null) {
    const { country, issueDate, issuingCountry, expirationDate, number } = passport ?? {}

    this.passportStore.setPassportInput({
      number: number ?? '',
      country: country ?? '',
      issuingCountry: issuingCountry ?? '',
      issuingDate: issueDate ? new Date(issueDate) : undefined,
      expirationDate: expirationDate ? new Date(expirationDate) : undefined,
    })
  }

  dropState() {
    this.passportStore.dropState()
  }
}
