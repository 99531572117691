import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  GetTripDetailsQuery,
  GetTripDetailsQueryVariables,
  CancelReservationMutation,
  CancelReservationMutationVariables,
} from '@fiji/graphql/hooks'
import { GetTripDetailsDocument, CancelReservationDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class CartPostBookingDataProvider extends GraphqlDataProvider {
  getTripDetailsAbortController: AbortController | null = null

  getTripDetails(input: GetTripDetailsQueryVariables['input'], forceUpdate?: boolean) {
    const controller = new AbortController()
    this.getTripDetailsAbortController?.abort()
    this.getTripDetailsAbortController = controller
    return this.client.query<GetTripDetailsQuery, GetTripDetailsQueryVariables>({
      query: GetTripDetailsDocument,
      variables: {
        input,
      },
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
      context: {
        queryDeduplication: false,
        fetchOptions: {
          controller: controller,
        },
      },
    })
  }

  cancelReservation(input: CancelReservationMutationVariables['input']) {
    return this.client.mutate<CancelReservationMutation, CancelReservationMutationVariables>({
      mutation: CancelReservationDocument,
      variables: {
        input,
      },
    })
  }
}
