export default function SameDayIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 45"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 6.5H17.3571V9.10465L15.9286 10.2209L14.5 9.10465V6.5Z" fill="currentColor" />
      <path
        d="M25.2994 18.9248V17.4474H26.7396L29.9213 20.6542L31.3243 22.0932L29.9174 23.5283L26.7069 26.7391H25.2994V25.2617L27.41 23.1423H13.5364V21.0441H27.4099L25.2994 18.9248Z"
        fill="currentColor"
      />
      <path
        d="M18.7006 26.4751L16.5901 28.5946H30.4636V30.6926H16.59L18.7006 32.8121V34.2895H17.2931L14.0826 31.0786L12.6757 29.6436L14.0787 28.2047L17.2604 24.9977H18.7006V26.4751Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5714 7.61626H18.4286V9.84879L15.9286 11.7093L13.4286 9.84879V7.61626H7V34.8162C7 36.8626 8.58728 38.5 10.5714 38.5H33.4286C35.4128 38.5 37 36.8626 37 34.8162V7.61626H30.5714V9.84879L28.0713 11.7093L25.5714 9.84879V7.61626ZM34.1429 34.7789C34.1429 35.151 34.1429 35.5232 33.7856 35.5232H10.2143C9.85714 35.3371 9.85714 35.2256 9.85714 34.8162V15.8023H34.1429V34.7789Z"
        fill="currentColor"
      />
      <path
        d="M26.6428 6.5V9.10465L28.0713 10.2209L29.4999 9.10465V6.5H26.6428Z"
        fill="currentColor"
      />
    </svg>
  )
}
