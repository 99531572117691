import { useMemo } from 'react'
import * as R from 'ramda'
import { formatPrice } from '@fiji/utils/money/format-price'
import type { RailFareValueObject } from '@etta/modules/rail/core/value-objects/rail-fare.value-object'
import type { FareConditionValueObject } from '@etta/modules/rail/core/value-objects/rail-search-result.value-object'
import type { RateValueObject } from '@etta/core/value-objects'

export type Args = {
  fare?: RailFareValueObject
  fareConditions?: FareConditionValueObject[]
}

export function useService({ fare, fareConditions }: Args) {
  return useMemo(() => {
    const serviceClasses = fare?.segments
      ? fare.segments.map((segment) => segment.displayServiceClass).filter(Boolean)
      : []
    const uniqServiceClasses = R.uniqBy(R.identity, serviceClasses).join(' / ')
    const fareTierId = R.path<string>(['tier', 'id'], fare)
    const fareName = R.path<string>(['tier', 'name'], fare)
    const fareRate = R.path<RateValueObject>(['total'], fare)
    const cost = fareRate?.secondary ?? fareRate?.primary
    const price = cost?.amount ? formatPrice(cost, { isInteger: false }) : ''

    const fareAttributes: string[] =
      fareConditions
        ?.find((item) => item.tier.name === fareName)
        ?.localizedFareConditions[0]?.summaryConditions.split('.')
        .filter(Boolean) || []

    const permittedOriginStations = fare?.permittedOriginStations || []
    const permittedDestinationStations = fare?.permittedDestinationStations || []

    const hasPermittedStations =
      permittedOriginStations.length > 0 || permittedDestinationStations.length > 0

    return {
      serviceClasses: uniqServiceClasses,
      fareName,
      price,
      fareAttributes,
      fareTierId,
      hasPermittedStations,
      permittedOriginStations,
      permittedDestinationStations,
    }
  }, [fare, fareConditions])
}
