import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getHotelSustainabilityDeviation } from '@fiji/hooks/sustainability/get-hotel-sustainability-deviation'
import type { CalculateEmissionsResultHotelCertification } from '@fiji/graphql/types'
import { CalculateEmissionsResultHotelCertificationType } from '@fiji/graphql/types'
import { EmissionsDeviationLevel } from '@fiji/enums'

type Props = {
  sustainabilityScore: number
  averageSustainabilityScore: number
  certifications?: CalculateEmissionsResultHotelCertification[]
}

export function useHotelDetailsSustainability(props: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.HotelDetails'

  const { deviationLevel } = getHotelSustainabilityDeviation(props)

  const isHotelCertificationPresent = useMemo(() => {
    return props.certifications?.find(
      (certification) =>
        certification.type === CalculateEmissionsResultHotelCertificationType.HotelCertification,
    )
  }, [props.certifications])

  const bodyRightDescription = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Less:
        return t(i18nBase + '.AboveAverage')
      case EmissionsDeviationLevel.More:
        return t(i18nBase + '.UnderAverage')
      case EmissionsDeviationLevel.Average:
        return t(i18nBase + '.Average')
    }
  }, [deviationLevel, t])

  return {
    isHotelCertificationPresent,
    bodyRightDescription,
    deviationLevel,
  }
}
