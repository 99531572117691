import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

export const NewPriceContainer = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
`

export const PriceChangeContainer = styled.div`
  display: flex;
  color: mainText;

  & span {
    ${subHeadStrong};
    line-height: 20px;
  }
`
