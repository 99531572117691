import { Store } from '@etta/di'
import type {
  SupportArticlePreviewValueObject,
  SupportArticleValueObject,
} from '../../core/value-objects'

type SupportArticleNavigation = {
  prev?: SupportArticlePreviewValueObject
  next?: SupportArticlePreviewValueObject
  isFirst: boolean
  isLast: boolean
}

@Store()
export class TravelSupportStore {
  supportArticles: SupportArticlePreviewValueObject[] = [
    {
      title: 'Getting Started with New Deem web experience',
      id: '6c2ce25f-a6ea-4cb7-97ab-f7b3dafe6b4e',
    },
    {
      title: 'How to Book a Car Rental',
      id: '9b030a08-a173-4380-a15d-7edeca1bd049',
    },
    {
      title: 'How to Book a Flight',
      id: '5484548e-b1c3-4d55-978e-676fc9dd76bc',
    },
    {
      title: 'How to Book a Hotel',
      id: '8223654c-a434-44eb-bb98-c0001d29f116',
    },
  ]

  supportArticle?: SupportArticleValueObject = undefined
  isSupportArticleLoading = false

  setIsSupportArticleLoading(value: boolean) {
    this.isSupportArticleLoading = value
  }

  setSupportArticle(value: SupportArticleValueObject) {
    this.supportArticle = value
  }

  getSupportArticleNavigation(id: string): SupportArticleNavigation {
    const currentIndex = this.supportArticles.findIndex((article) => article.id === id)

    const prevIndex = currentIndex - 1
    const nextIndex = currentIndex + 1

    const prev = prevIndex >= 0 ? this.supportArticles[prevIndex] : undefined
    const next =
      nextIndex < this.supportArticles.length ? this.supportArticles[nextIndex] : undefined

    const isFirst = currentIndex === 0
    const isLast = currentIndex === this.supportArticles.length - 1

    return { prev, next, isFirst, isLast }
  }
}
