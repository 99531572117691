import type { MutableRefObject, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Text } from '@etta/ui/text'
import type { PriceDetailsItem } from './types'
import { Section, Container, TotalCost, Footer } from './price-details-styled'
import { PriceDetailsHeader } from './price-details-header'

type Props = {
  headerLevel?: number
  priceRef?: MutableRefObject<HTMLDivElement | null>
  priceDetailsList?: (PriceDetailsItem | undefined | null)[]
  totalCost?: string
  footerSlot?: ReactNode
  type?: 'price-details' | 'price-breakdown'
  isRideHailPriceDetails?: boolean
  rideHailPriceTitle?: string
  isCarServicePriceDetails?: boolean
}

const i18nBase = 'PriceDetails'

export function PriceDetails({
  priceRef,
  priceDetailsList,
  headerLevel = 5,
  totalCost,
  footerSlot,
  type,
  isRideHailPriceDetails,
  isCarServicePriceDetails,
  rideHailPriceTitle,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const isFooterExist = !!(totalCost || footerSlot)
  const isMobile = screenType === ScreenType.Mobile
  const footerTitle = isMobile ? '.EstimatedTotal' : '.Total'
  const blockOuterPadding = isMobile || isCarServicePriceDetails ? 16 : 20

  return (
    <Container ref={priceRef} data-tracking-id="price-details">
      <PriceDetailsHeader
        headerLevel={headerLevel}
        type={type}
        isCarServicePriceDetails={isCarServicePriceDetails}
      />
      <Block
        paddingTop={16}
        paddingBottom={blockOuterPadding}
        paddingHorizontal={blockOuterPadding}>
        <Block>
          {priceDetailsList?.map((details, index) => {
            if (!details || !details.value) {
              return
            }
            const { testId, title, value, color, titleTestId, valueTestId } = details

            return (
              <Section
                key={index}
                data-tracking-id={testId}
                isFooterExist={isFooterExist}
                aria-label={t(i18nBase + '.ItemAriaLabel', {
                  title,
                  amount: value,
                })}>
                <Text variant="footnoteMedium" color={color} data-tracking-id={titleTestId}>
                  {title}
                </Text>
                <Text variant="footnoteMedium" color={color} data-tracking-id={valueTestId}>
                  {value}
                </Text>
              </Section>
            )
          })}
        </Block>
        {isFooterExist && (
          <Footer>
            {totalCost && (
              <Swap
                is={isRideHailPriceDetails}
                isSlot={
                  <TotalCost
                    isFooterSlotExist={!!footerSlot}
                    aria-label={t(i18nBase + '.ItemAriaLabel', {
                      title: rideHailPriceTitle,
                      amount: totalCost,
                    })}>
                    <Text variant="headline" data-tracking-id="est-total-text">
                      {rideHailPriceTitle}
                    </Text>
                    <Text variant="headline" data-tracking-id="est-total-amount">
                      {totalCost}
                    </Text>
                  </TotalCost>
                }>
                <TotalCost
                  isFooterSlotExist={!!footerSlot}
                  aria-label={t(i18nBase + '.ItemAriaLabel', {
                    title: t(i18nBase + footerTitle),
                    amount: totalCost,
                  })}>
                  <Text variant="headline" data-tracking-id="est-total-text">
                    {t(i18nBase + footerTitle)}
                  </Text>
                  <Text variant="headline" data-tracking-id="est-total-amount">
                    {totalCost}
                  </Text>
                </TotalCost>
              </Swap>
            )}
            {footerSlot}
          </Footer>
        )}
      </Block>
    </Container>
  )
}
