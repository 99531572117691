import { Spinner } from '@etta/ui/spinner'
import { ErrorContainer } from '@etta/ui/error-container'
import { StyledTextVariant } from '@fiji/enums'
import {
  Container,
  Dot,
  PolicyContainer,
  PolicyText,
  SectionContainer,
  SpinnerContainer,
} from './travel-policy-list-components'
import { useTravelPolicyList } from './use-travel-policy-list'

export function TravelPolicyList() {
  const { data, loading, error, handleRefetchRequest } = useTravelPolicyList()

  if (error) {
    return <ErrorContainer onReload={handleRefetchRequest} />
  }

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner type="spinout" />
      </SpinnerContainer>
    )
  }

  return (
    <Container>
      <PolicyContainer>
        {data?.travelPolicyList?.policyList?.map((policy, index) => (
          <SectionContainer key={index}>
            <Dot />
            <PolicyText
              textVariant={StyledTextVariant.footnoteMedium}
              data-tracking-id="travel-policy">
              {policy}
            </PolicyText>
          </SectionContainer>
        ))}
      </PolicyContainer>
    </Container>
  )
}
