import styled, { css } from 'styled-components'

export const ViewListWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 75px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  display: none;

  ${(props) =>
    props.isVisible &&
    css`
      display: block;
    `}
`

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`
