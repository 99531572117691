import type { MutableRefObject } from 'react'
import { useCallback } from 'react'
import { PAGE_HOTEL_SIZE } from '@etta/screens/hotel-result-page/constants'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'

type Args = {
  anchorRef: MutableRefObject<HTMLDivElement | null>
}

export function useHotelListResultsDesktop({ anchorRef }: Args) {
  const { hotelSearchResultsStore, loadHotelsAction } = useHotelSearchResultsContext()
  const hotelsCount = hotelSearchResultsStore.hotelResults?.totalCount || 0
  const currentPage = Math.floor(hotelSearchResultsStore.offset / PAGE_HOTEL_SIZE) + 1
  const totalPages = hotelsCount >= PAGE_HOTEL_SIZE ? Math.ceil(hotelsCount / PAGE_HOTEL_SIZE) : 1

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      loadHotelsAction.loadMoreHotelsDesktop((pageIndex - 1) * PAGE_HOTEL_SIZE)
      anchorRef.current?.scrollIntoView()
    },
    [anchorRef, loadHotelsAction],
  )

  const isFooterVisible = !!hotelsCount
  const isSkeletonVisible =
    hotelSearchResultsStore.isFetchMoreLoading || hotelSearchResultsStore.isLoading
  const isPageSkeletonVisible = hotelSearchResultsStore.isLoading || isFooterVisible
  const firstIndex = currentPage * PAGE_HOTEL_SIZE + 1 - PAGE_HOTEL_SIZE
  const lastIndex = Math.min(firstIndex + PAGE_HOTEL_SIZE - 1, hotelsCount)

  return {
    firstIndex,
    lastIndex,
    handlePageChange,
    isSkeletonVisible,
    isFooterVisible,
    isPageSkeletonVisible,
    currentPage,
    totalPages,
    hotelsCount,
  }
}
