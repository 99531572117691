import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { StateErrorDesktop } from './desktop'
import { StateErrorMobile } from './mobile'

export function StateErrorLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<StateErrorMobile {...props} />}
      desktopSlot={<StateErrorDesktop {...props} />}
    />
  )
}
