import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { TripRideHailContentProps } from './types'
import { TripRideHailContentLayout } from './trip-ride-hail-content'

type Props = TripRideHailContentProps & {
  isVisible: boolean
}

export function TripRideHail({ isVisible, onClose, ...props }: Props) {
  const screenType = useScreenType()
  const modalPosition = screenType !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      backgroundColor="background"
      position={modalPosition}>
      <TripRideHailContentLayout onClose={onClose} {...props} />
    </Modal>
  )
}
