import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Swap } from '@etta/ui/swap'
import { Spinner } from '@etta/ui/spinner'
import { EmptyStateContainer } from '../../empty-state-container'
import type { LayoutProps } from '../types'
import { InfiniteScrollPlaceHolder } from '../../select-search-styled'
import {
  RootContainer,
  SelectTextField,
  InputContainer,
  OptionContainer,
} from './select-search-mobile-styled'
import { OptionRow } from './option-row'

export function SelectSearchMobile({
  placeholder,
  inputValue,
  disabledOptions,
  filteredOptions,
  isModalVisible,
  isLoading,
  onHideModal,
  onChangeInput,
  onClear,
  onSelect,
  renderOptionMobile,
  'data-tracking-id': dataTrackingId,
  hasNextPageForOptions,
  onFetchMoreForOptions,
}: LayoutProps) {
  const [loaderRef] = useInfiniteScroll({
    loading: !!isLoading,
    hasNextPage: hasNextPageForOptions,
    onLoadMore: onFetchMoreForOptions,
  })

  return (
    <Modal
      isVisible={isModalVisible}
      handleModalVisibility={onHideModal}
      data-tracking-id={dataTrackingId}>
      <Header
        withBorderBottom
        leftSlot={<Header.CloseButton onClick={onHideModal} />}
        layout="search-input">
        <InputContainer>
          <SelectTextField
            data-autofocus="true"
            type="search"
            value={inputValue}
            onChange={onChangeInput}
            onClear={onClear}
            placeholder={placeholder}
            size="small"
          />
        </InputContainer>
      </Header>
      <Modal.Body>
        <RootContainer>
          <Swap is={isLoading} isSlot={<Spinner type="spinout" />}>
            <Swap is={!filteredOptions.length} isSlot={<EmptyStateContainer />}>
              <OptionContainer>
                {filteredOptions.map((item, index) => {
                  const isDisabled = disabledOptions?.includes(item.value)

                  return (
                    <OptionRow
                      index={index}
                      key={`${index}-${item.label}-${item.value}`}
                      item={item}
                      onSelect={onSelect}
                      renderOptionMobile={renderOptionMobile}
                      isDisabled={isDisabled}
                    />
                  )
                })}
                {hasNextPageForOptions && <InfiniteScrollPlaceHolder ref={loaderRef} />}
              </OptionContainer>
            </Swap>
          </Swap>
        </RootContainer>
      </Modal.Body>
    </Modal>
  )
}
