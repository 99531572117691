import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import { Container } from './meeting-name-badge-desktop.styled'

export type MeetingNameBadgeDesktopProps = {
  meetingName: string
}

export const MeetingNameBadgeDesktop = ({ meetingName }: MeetingNameBadgeDesktopProps) => {
  const {
    brandConfigurationStore: { isAppleTheme },
  } = useBrandConfigurationContext()
  return (
    <Container isAppleTheme={isAppleTheme}>
      <Block flexGap={3} isFlexBlock>
        <Icon size={20} name="calendarPWA" color="white" />
        <Text variant="subHeadStrong" color="white">
          {meetingName}
        </Text>
      </Block>
    </Container>
  )
}
