import { MediaLayout } from '@etta/ui/media-layout'
import type { TimePickerProps } from './types'
import { SwipingTimePickerMobile } from './mobile'
import { SwipingTimePickerDesktop } from './desktop'

export function SwipingTimePicker(props: TimePickerProps) {
  return (
    <MediaLayout
      mobileSlot={<SwipingTimePickerMobile {...props} />}
      desktopSlot={<SwipingTimePickerDesktop {...props} />}
    />
  )
}
