import type { PropsWithChildren } from 'react'
import { ServiceSearchLayout } from '@etta/components/service-search-layout'
import { SegmentType } from '@fiji/graphql/hooks'
import type { PostBookingAction } from '@fiji/enums'

type Props = PropsWithChildren<{
  onBack: () => void
  postBookingAction?: PostBookingAction
}>

export function HotelSearchPageDesktop({ children, onBack, postBookingAction }: Props) {
  return (
    <ServiceSearchLayout
      type={SegmentType.Hotel}
      onBack={onBack}
      postBookingAction={postBookingAction}
      isNewLayout>
      {children}
    </ServiceSearchLayout>
  )
}
