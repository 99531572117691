export function getNextIndex(current: number, max: number, loop: boolean) {
  let next = current + 1
  if (loop && next >= max) {
    next = 0
  }
  return next
}

export function getPrevIndex(current: number, max: number, loop: boolean) {
  let next = current - 1
  if (loop && next < 0) {
    next = max
  }
  return next
}
