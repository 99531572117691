import { v4 as uuid } from 'uuid'
import type { Place } from '@fiji/types'
import { Service } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import type { CarRentalRecentSearchWithoutDates } from '@etta/modules/recent-searches/core/value-objects/get-car-rental-searches-without-dates.value-object'

@Service()
export class CarRentalRecentSearchWithoutDatesDataService {
  get(input: CarRentalRecentSearchWithoutDates['carRentalSearchParams']) {
    const queryParams = this.getQueryParams(input)
    const locationName = this.getName(input)
    const route = this.getRoute({
      pickUpAirport: queryParams.pickUpPlace.airportCode,
      dropOffAirport: queryParams.dropOffPlace.airportCode,
    })
    return { queryParams, locationName, route }
  }

  private getRoute({
    pickUpAirport,
    dropOffAirport,
  }: {
    pickUpAirport?: string
    dropOffAirport?: string
  }) {
    if (!pickUpAirport) {
      return ROUTES.carRental.pickUp
    }
    if (!dropOffAirport) {
      return ROUTES.carRental.dropOff
    }
    return ROUTES.carRental.results
  }

  private getName({ dropOff, pickUp }: CarRentalRecentSearchWithoutDates['carRentalSearchParams']) {
    const originLocationName = pickUp.location.name
    const destinationLocationName = dropOff.location.name

    return originLocationName !== destinationLocationName
      ? [originLocationName, destinationLocationName].join(' - ')
      : originLocationName
  }

  private getQueryParams({
    dropOff,
    pickUp,
  }: CarRentalRecentSearchWithoutDates['carRentalSearchParams']) {
    const pickUpPlace = {
      placeId: uuid(),
      name: pickUp.location.name,
      airportCode: pickUp.location.airportCode || undefined,
      latitude: pickUp.location.geocode.lat,
      longitude: pickUp.location.geocode.long,
    }

    const dropOffPlace: Place = {
      placeId: uuid(),
      name: dropOff.location.name,
      airportCode: dropOff.location.airportCode || undefined,
      latitude: dropOff.location.geocode.lat,
      longitude: dropOff.location.geocode.long,
    }

    return {
      dropOffPlace,
      pickUpPlace,
    }
  }
}
