import styled, { css } from 'styled-components'
import { captionStrongCaps } from '@fiji/style'
import type { BadgeVariant } from './types'

const BadgeStyles = css`
  ${captionStrongCaps};
  background: ${(props) => props.theme.colors.duskBlue};
  color: ${(props) => props.theme.colors.mainText2};
`

const TripSourceBadgeStyles = css`
  ${captionStrongCaps};
  background: ${(props) => props.theme.colors.glacierBlue};
  color: ${(props) => props.theme.colors.mainText2};
`

const VariantMap: Record<BadgeVariant, any> = {
  gbt_direct: BadgeStyles,
  booking: BadgeStyles,
  trip_source: TripSourceBadgeStyles,
}

export const Badge = styled.div<{ variant: BadgeVariant }>`
  display: inline-flex;
  padding: 0 5px;
  margin-left: 5px;
  border-radius: 2px;
  ${(props) => VariantMap[props.variant]};
`
