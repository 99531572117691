export function decimals(oldValue: string, value: string) {
  const result = parseFloat(value)
  if (!value) {
    return value
  }

  if (isNaN(result)) {
    return String(oldValue)
  }

  // If the input is a valid decimal number,
  // check for trailing zeros and return the input string
  const trailingZeroRegex = /\.\d*0$/
  if (trailingZeroRegex.test(value)) {
    return value
  }

  return result.toString()
}
