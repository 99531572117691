import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { RecentSearchesListMobile } from './mobile'

export function RecentSearchesList(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<RecentSearchesListMobile {...props} />}
      desktopSlot={<RecentSearchesListMobile {...props} />}
    />
  )
}
