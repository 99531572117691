import styled from 'styled-components'

export const PaymentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const PaymentDetails = styled.div<{ alightRight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: ${(props) => (props.alightRight ? 'flex-end' : 'flex-start')};
  &:first-child {
    flex: 1;
  }
`
