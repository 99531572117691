import { useTranslation } from 'react-i18next'
import type { BillListItemProps } from '../bill-list-item'
import { BillListItem } from '../bill-list-item'

const i18nBase = 'PostBooking.TripDetails'

type Props = {
  price: BillListItemProps['price']
}

export function BillListItemTax({ price }: Props) {
  const { t } = useTranslation()
  const text = t(`${i18nBase}.CostSummary.Tax`)

  return <BillListItem price={price}>{text}</BillListItem>
}
