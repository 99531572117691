import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import { RailDeliveryOptionContent } from './rail-delivery-option-content'
import type { ContentProps } from './types'

export function RailDeliveryOptionCheckoutWrapper(props: ContentProps) {
  const { travelerInfo } = useCheckoutContextSelector((state) => state)
  const { railPreferences } = travelerInfo

  return (
    <RailDeliveryOptionContent
      {...props}
      isEticket={props.isEticket || railPreferences?.value.isETicket}
      stationName={props.stationName || railPreferences?.departureStationName}
      isStationTicketMachineDisabled={
        props.isStationTicketMachineDisabled ||
        railPreferences?.value.isStationTicketMachineDisabled
      }
    />
  )
}
