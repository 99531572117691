import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { FlightSafetyCheckItem } from '@etta/components/flight-safety-check-item'
import { InfoModal } from '@etta/screens/review-trip-page/info-modal'
import type { LayoutProps } from '../../types'

export function FlightSafetyCheckModalMobile({ flightSafetyCheckDetails, onClose }: LayoutProps) {
  return (
    <>
      <InfoModal.Header title={<SafetyCheckTitle size="medium" />} onClose={onClose} />
      {flightSafetyCheckDetails &&
        flightSafetyCheckDetails.map((detail, index) => (
          <FlightSafetyCheckItem key={index} detail={detail} />
        ))}
    </>
  )
}
