import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import type { PrivacyPolicyConfiguration } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { useReviewTripContext } from '@etta/modules/review-trip'
import { Wrapper, PolicyBlock, PriceRow, Button, DueTodayCostContainer } from './footer-styled'
import { FooterButtons } from './footer-buttons'

const i18nBase = 'PaymentSummary'

type Props = {
  isBooking?: boolean
  isHoldFlow?: boolean
  onShowBookingConditions?: () => void
  nationalRailConditions?: string
  privacyPolicy?: PrivacyPolicyConfiguration
  buttonLabel: string
  priceString?: string
  dueTodayCost?: string
  holdButtonLabel?: string
  disabledButton?: boolean
  isHoldButtonVisible?: boolean
  onHoldTrip?: () => void
  isDueTodayCostDisplayed: boolean
  onSubmit?: () => Promise<void>
}

export function Footer({
  isBooking,
  onShowBookingConditions,
  nationalRailConditions,
  privacyPolicy,
  buttonLabel,
  priceString,
  dueTodayCost,
  disabledButton,
  isDueTodayCostDisplayed,
  onHoldTrip,
  isHoldFlow,
  isHoldButtonVisible,
  holdButtonLabel,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const startText = t(`${i18nBase}.BookingConditionPhraseStart`)
  const bookingConditionText = t(`${i18nBase}.BookingConditionLink`)
  const nationalRailConditionsText = t(`${i18nBase}.NationalRailConditionsLink`)
  const termsOfServiceText = t(`${i18nBase}.TermsOfService`)
  const privacyPolicyText = privacyPolicy?.label || t(`${i18nBase}.PrivacyPolicy`)
  const ariaLabel = t(`${i18nBase}.FooterLabel`, {
    start_text: startText,
    booking_condition: onShowBookingConditions ? bookingConditionText : nationalRailConditionsText,
    terms_of_service: termsOfServiceText,
    privacy_policy: privacyPolicyText,
  })
  const { reviewTripStore } = useReviewTripContext()

  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const handleSubmit = async () => {
    if (submitButtonRef.current && onSubmit) {
      submitButtonRef.current.disabled = true
      await onSubmit()
      submitButtonRef.current.disabled = false
    }
  }

  return (
    <Wrapper>
      {isBooking && (
        <PolicyBlock aria-label={ariaLabel}>
          {startText}{' '}
          {onShowBookingConditions && (
            <>
              <Link
                size="small"
                onClick={onShowBookingConditions}
                data-tracking-id="booking-condition-link">
                {bookingConditionText}
              </Link>
              {', '}
            </>
          )}
          {nationalRailConditions && (
            <>
              <Link
                size="small"
                href={nationalRailConditions}
                target="_blank"
                data-tracking-id="national-rail-conditions-link">
                {nationalRailConditionsText}
              </Link>
              {', '}
            </>
          )}
          <Link
            size="small"
            href="https://www.deem.com/privacy#Terms-of-Use"
            target="_blank"
            data-tracking-id="terms-of-service-link">
            {termsOfServiceText}
          </Link>
          <span>{` ${t(`${i18nBase}.And`)} `}</span>
          <Link
            size="small"
            href={privacyPolicy?.url}
            target="_blank"
            data-tracking-id="privacy-policy-link">
            {privacyPolicyText}
          </Link>
        </PolicyBlock>
      )}
      <Block marginBottom={12}>
        <PriceRow>
          <Text
            aria-label={
              reviewTripStore.isTotalPrice
                ? t('Purchase.PriceBlock.TripTotal')
                : t('Purchase.PriceBlock.EstimatedTotal')
            }
            variant="headline"
            color="mainText">
            {reviewTripStore.isTotalPrice
              ? t('Purchase.PriceBlock.TripTotal')
              : t('Purchase.PriceBlock.EstimatedTotal')}
          </Text>
          <Text aria-label={priceString} variant="headline" color="mainText">
            {priceString}
          </Text>
        </PriceRow>
        {isDueTodayCostDisplayed && dueTodayCost && (
          <DueTodayCostContainer>
            <PriceRow>
              <Text
                aria-label={t('PaymentSummary.DueToday')}
                variant="footnoteStrong"
                color="bodyText">
                {t('PaymentSummary.DueToday')}
              </Text>
              <Text aria-label={dueTodayCost} variant="footnoteStrong" color="bodyText">
                {dueTodayCost}
              </Text>
            </PriceRow>
          </DueTodayCostContainer>
        )}
      </Block>
      <FooterButtons
        isHoldButtonVisible={isHoldButtonVisible}
        isHoldFlow={isHoldFlow}
        holdButtonSlot={
          <Button
            disabled={disabledButton}
            fullWidth
            onClick={onHoldTrip}
            variant={isHoldFlow ? 'solid' : 'outline'}>
            {holdButtonLabel}
          </Button>
        }
        buttonSlot={
          <Button
            ref={submitButtonRef}
            aria-label={t('Purchase.ContinueAria', {
              buttonLabel,
            })}
            disabled={disabledButton}
            data-tracking-id="book-trip-button"
            fullWidth
            onClick={handleSubmit}>
            {buttonLabel}
          </Button>
        }
      />
    </Wrapper>
  )
}
