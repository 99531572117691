import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { ValueType } from '@etta/ui/drop-down-list'
import { useCarRentalSearchContext } from '@etta/modules/car-rental-search/interface'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { prepareFilters } from '../prepare-filters'

const i18nBase = 'CarRentalFilters.SectionTitles'

export function useCarClassFilter() {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isCarRentalsNewFiltersEnabled } = featureFlagsStore.flags
  const { t } = useTranslation()

  const {
    carRentalFilterActions,
    carRentalSearchStore,
    carFiltersStore,
  } = useCarRentalSearchContext()
  const { filterOptions } = carRentalSearchStore

  const carClass = prepareFilters(
    filterOptions.carClasses,
    carFiltersStore.selectedFilters.carClass,
  )

  const handleApplyCarClassFilters = () => {
    carRentalFilterActions.handleNotUseCompanyFiltersInRequest()
    carRentalFilterActions.handleApplyCarClassFilters()
  }

  const handleChangeCarClass = (values: ValueType[]) => {
    carRentalFilterActions.handlers.setCarClass(
      values?.map((v: ValueType) => v?.toString() || '').filter(Boolean),
    )
    handleApplyCarClassFilters()
  }

  const label = t(`${i18nBase}.CarClass`)

  const carClassValue = useMemo(() => {
    const filterValues = []
    for (const filterOption of carRentalSearchStore.filterOptions.carClasses) {
      if (carFiltersStore.appliedFilters.carClass.includes(filterOption.id)) {
        filterValues.push(filterOption.title)
      }
    }
    if (!filterValues.length) {
      return ''
    }
    return filterValues.join(', ')
  }, [carFiltersStore.appliedFilters.carClass, carRentalSearchStore.filterOptions.carClasses])

  const isClearDisabled = useMemo(() => {
    return carFiltersStore.isEqual && !carFiltersStore.selectedFilters.carClass.length
  }, [carFiltersStore.isEqual, carFiltersStore.selectedFilters.carClass])

  return {
    isCarRentalsNewFiltersEnabled,
    handleChangeCarClass,
    carClass,
    label,
    carFiltersStore,
    carRentalFilterActions,
    carRentalSearchStore,
    carClassValue,
    isClearDisabled,
    handleApplyCarClassFilters,
  }
}
