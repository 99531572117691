import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Dates } from '@fiji/hooks/use-date-picker'
import { MediaLayout } from '@etta/ui/media-layout'
import { getResults } from '@fiji/hooks/use-date-picker'

import type { PlainTime as Time } from '@fiji/types'
import { TimePicker } from '@etta/ui/swiping-time-date-picker'
import Calendar from '@etta/ui/calendar'
import { Text } from '@etta/ui/text'
import { ScreenType } from '@fiji/modes'
import { Switch } from '../switch/switch'
import { CustomFocusedInput } from './controls/types'
import {
  DateTimePickerContainer,
  Row,
  TimePickerContainer,
  TimePickerWrapper,
} from './date-time-picker-components'
import { WeekHeader, WeekHeaderContainer } from './controls/week-header'
import Controls from './controls'

type Props = {
  date?: Date | null
  time?: Time | null
  minDate?: Date
  maxDate?: Date
  onDateSelect: (date?: Date) => void
  onTimeSelect: (date?: Time) => void
  dateTitle?: string
  timeTitle?: string
  highlightDates?: (Date | undefined)[]
  numberOfMonths?: number
  isOutsideRange?: () => boolean
  isOpen: boolean
  tabIndex?: number
  isReturnDate: boolean
  focusedInput: CustomFocusedInput
  changeFocusedInput: (value: CustomFocusedInput) => void
  defaultTime?: Time
  shouldShowNowToggle?: boolean
  forceScreenType?: null | ScreenType
}

const i18Base = 'Mobility.SelectLocationModal'

export function DateTimePicker({
  date,
  time,
  onDateSelect,
  onTimeSelect,
  minDate,
  maxDate,
  dateTitle,
  timeTitle,
  highlightDates,
  numberOfMonths,
  focusedInput,
  changeFocusedInput,
  defaultTime,
  shouldShowNowToggle,
  forceScreenType,
}: Props) {
  const [isNowSelected, setIsNowSelected] = useState(false)
  const { t } = useTranslation()
  const shouldShowTimePicker = !shouldShowNowToggle || !isNowSelected

  const handleDaySelect = useCallback(
    (dates: Dates) => {
      const results = getResults(dates, false, 'startDate')
      if (results?.date) {
        onDateSelect(results?.date)
      }
    },
    [onDateSelect],
  )

  const handleTimeSelect = useCallback(
    (time: Time) => {
      onTimeSelect(time)
    },
    [onTimeSelect],
  )

  const handleClickTabDate = useCallback(() => {
    if (shouldShowNowToggle && isNowSelected) {
      return
    }
    changeFocusedInput(CustomFocusedInput.date)
  }, [isNowSelected, changeFocusedInput, shouldShowNowToggle])

  const handleClickTabTime = useCallback(() => {
    if (shouldShowNowToggle && isNowSelected) {
      return
    }
    if (defaultTime) {
      onTimeSelect(defaultTime)
    }
    changeFocusedInput(CustomFocusedInput.time)
  }, [changeFocusedInput, defaultTime, onTimeSelect, isNowSelected, shouldShowNowToggle])

  const toggleNow = useCallback(() => {
    const nextCheckedValue = !isNowSelected
    setIsNowSelected(nextCheckedValue)
    if (nextCheckedValue) {
      onTimeSelect(undefined)
    } else {
      onTimeSelect(defaultTime)
    }
  }, [isNowSelected, onTimeSelect, defaultTime])

  return (
    <DateTimePickerContainer>
      <Controls
        isOpen
        forceScreenType={forceScreenType}
        focusedInput={focusedInput}
        date={date}
        time={time}
        dateTitle={dateTitle}
        timeTitle={timeTitle}
        handleClickTabDate={handleClickTabDate}
        handleClickTabTime={handleClickTabTime}
        isNowSelected={isNowSelected}
      />
      {focusedInput === CustomFocusedInput.date && (
        <Calendar
          forceScreenType={ScreenType.Mobile}
          date={date}
          minDate={minDate}
          maxDate={maxDate}
          onDaySelect={handleDaySelect}
          focusedInput={'startDate'}
          highlightDates={highlightDates}
          numberOfMonths={numberOfMonths}
        />
      )}
      {focusedInput === CustomFocusedInput.time && (
        <>
          {shouldShowNowToggle && (
            <Row>
              <Text variant="body" color="mainText">
                {t(`${i18Base}.PickupNow`)}
              </Text>
              <Switch
                isPWA
                label=""
                labelPlacement="left"
                checked={isNowSelected}
                onChange={toggleNow}
              />
            </Row>
          )}
          <TimePickerContainer>
            {shouldShowTimePicker && (
              <TimePickerWrapper>
                <TimePicker
                  label=""
                  value={time ?? undefined}
                  shouldShowWheelsOnly
                  onApply={() => {}}
                  onChange={handleTimeSelect}
                />
              </TimePickerWrapper>
            )}
          </TimePickerContainer>
        </>
      )}
      {focusedInput === CustomFocusedInput.date && (
        <MediaLayout
          forceScreenType={forceScreenType}
          mobileSlot={null}
          desktopSlot={
            <>
              <WeekHeaderContainer weekHeaderOfFirstMonth={true}>
                <WeekHeader />
              </WeekHeaderContainer>
              {numberOfMonths && numberOfMonths > 1 && (
                <WeekHeaderContainer weekHeaderOfFirstMonth={false}>
                  <WeekHeader />
                </WeekHeaderContainer>
              )}
            </>
          }
        />
      )}
    </DateTimePickerContainer>
  )
}
