import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Screen } from '@etta/ui/screen'

export const ScreenContainer = styled(Screen)`
  overflow-y: auto;
  overflow-x: hidden;
`

export const UberTripManagement = styled(Block)`
  background: ${(props) => props.theme.colors.plum};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

export const ListItem = styled(Block)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const TripManagementTitle = styled(Text)`
  margin-top: 24px;
`

export const LogoIconContainer = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  background: ${(props) => props.theme.colors.white};
`

export const DashLine = styled.div<{ color: string }>`
  margin-bottom: 18px;
  margin-top: 18px;
  opacity: 0.2;
  height: 0px;
  border-bottom: 1px dashed
    ${(props) =>
      props.color === 'secondary' ? props.theme.colors.secondary : props.theme.colors.bodyText1};
`

export const FeatureContainer = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  margin-left: 20px;
  margin-right: 20px;
`

export const IconWrapper = styled.div<{ color: string }>`
  border-radius: 50%;
  padding-left: 22px;
  padding-right: 22px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: flex-start;
  background: ${(props) =>
    props.color === 'plum' ? props.theme.colors.plum : props.theme.colors.white};
`

export const HeaderIconWrapper = styled.div`
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FeatureText = styled(Text)`
  margin-left: 14px;
`
