import { useCallback, useMemo, useState } from 'react'
import type { Place } from '@fiji/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { AddressInput, CustomerUberAccountStatus as Status } from '@fiji/graphql/types'
import type {
  PlaceWithAddress,
  SelectedDateTime,
  SelectedValue,
} from '@etta/components/mobility-select-location-modal'
import { formatAddressInput } from '@etta/screens/mobility-search-page/utils'
import { useQueryParams } from '@fiji/hooks/use-query/use-query-params/use-query-params'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useMultiModalSearchContext } from '@etta/modules/multi-modal-search/interface/use-multi-modal-search-context'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'

type Prop = {
  handleStartSearch: () => void
}

export function useMobilityWhereToDialog({ handleStartSearch }: Prop) {
  const [pickUpPlace, setPickUpPlace] = useState<{ place?: Place; address?: AddressInput }>()
  const [dropOffPlace, setDropOffPlace] = useState<{ place?: Place; address?: AddressInput }>()
  const [dropOffAddress, setDropOffAddress] = useState<string>()
  const [dateTime, setDateTime] = useState<SelectedDateTime>()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [isSearchSubmit, setIsSearchSubmit] = useState<boolean>(false)
  const [isCustomDropOff, setIsCustomDropOff] = useState<boolean>(false)
  const [isModalSearchOpen, setIsModalSearchOpen] = useState<boolean>(false)
  const [isModalSearchVisible, setIsModalSearchVisible] = useState<boolean>(false)
  const {
    queryParams: { 'user-status': status },
  } = useQueryParams<{ ['user-status']: Status }>()
  const { searchRideHailActions, searchRideHailStore } = useRideHailSearchContext()
  const { multiModalSearchActions } = useMultiModalSearchContext()
  const uberInfoModal = useTogglePopup()
  const screenType = useScreenType()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isMobilitySearchFormV3Enabled } = featureFlagsStore.flags

  const handleSelectSuggestedAsDropOff = useCallback((place: Place, address?: string) => {
    setDropOffPlace({ place })
    setDropOffAddress(address)
    setModalOpen(true)
    setIsCustomDropOff(false)
    setModalVisible(true)
  }, [])

  const handleSelectWhereTo = useCallback(() => {
    setModalOpen(true)
    setIsCustomDropOff(true)
    setModalVisible(true)
  }, [])

  const handleSubmitSearch = useCallback(
    (selected: SelectedValue) => {
      setDropOffPlace(selected.pickUp)
      setPickUpPlace(selected.dropOff)
      setDateTime(selected.dateTime)

      searchRideHailActions.setSearchRideHailForm({
        pickUpPlace: selected.pickUp.place,
        pickUpAddress: getPlaceAddress(selected.pickUp),
        dropOffPlace: selected.dropOff.place,
        dropOffAddress: getPlaceAddress(selected.dropOff),
        pickUpDate: selected.dateTime.date,
        pickUpTime: selected.dateTime.time,
      })

      multiModalSearchActions.handleSetSearchRideHailForm({
        originAddress: selected.pickUp.place?.name ?? getPlaceAddress(selected.pickUp) ?? '',
        destinationAddress: selected.dropOff.place?.name ?? getPlaceAddress(selected.dropOff) ?? '',
        departureDate: selected.dateTime.date,
        departureTime: selected.dateTime.time,
      })

      if (isMobilitySearchFormV3Enabled) {
        setIsModalSearchOpen(true)
      } else {
        setIsSearchSubmit(true)
      }
    },
    [searchRideHailActions, multiModalSearchActions, isMobilitySearchFormV3Enabled],
  )

  const handleModalClose = useCallback(() => {
    setDropOffPlace(undefined)
    setModalVisible(false)
  }, [])

  const handleExited = useCallback(() => {
    if (isSearchSubmit) {
      handleStartSearch()
    }

    if (isModalSearchOpen) {
      setIsModalSearchVisible(true)
    }

    setModalOpen(false)
  }, [isSearchSubmit, isModalSearchOpen, handleStartSearch])

  const isSelectLocationModalVisible = useMemo(() => {
    if (screenType !== ScreenType.Mobile) {
      return isModalVisible && searchRideHailStore.isOpenMobilitySearchDesktopModal
    }
    return isModalVisible
  }, [screenType, isModalVisible, searchRideHailStore.isOpenMobilitySearchDesktopModal])

  const handleModalSearchClose = useCallback(() => {
    setIsModalSearchVisible(false)
  }, [])

  const handleModalSearchExited = useCallback(() => {
    setIsModalSearchOpen(false)
  }, [])

  const isModalSearchFromVisible = useMemo(() => {
    if (screenType !== ScreenType.Mobile) {
      return isModalSearchVisible && searchRideHailStore.isOpenMobilitySearchDesktopModal
    }
    return isModalSearchVisible
  }, [screenType, isModalSearchVisible, searchRideHailStore.isOpenMobilitySearchDesktopModal])

  return {
    handleSelectSuggestedAsDropOff,
    handleSubmitSearch,
    handleModalClose,
    handleExited,
    isModalOpen,
    pickUpPlace,
    dropOffPlace,
    dropOffAddress,
    dateTime,
    handleSelectWhereTo,
    isCustomDropOff,
    isSelectLocationModalVisible,
    isModalInfoOpen: uberInfoModal.isOpen,
    handleModalInfoClose: uberInfoModal.handleClose,
    handleModalInfoOpen: uberInfoModal.handleOpen,
    isModalSearchOpen,
    isModalSearchVisible: isModalSearchFromVisible,
    handleModalSearchClose,
    handleModalSearchExited,
    status,
  }
}

function getPlaceAddress(selectedPlace: {
  place?: PlaceWithAddress
  address?: AddressInput
}): string | undefined {
  if (selectedPlace.place && selectedPlace.place?.address) {
    return selectedPlace.place?.address
  }

  if (selectedPlace.address) {
    return formatAddressInput(selectedPlace.address)
  }

  return undefined
}
