import { useTranslation } from 'react-i18next'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useDistanceKey } from '@etta/components/hotel-distance/use-distance-key'
import type { Props } from './types'

const i18Base = 'TripPlanner.BaseSearch'

export function useHotelDistance({ distance, withUppercase }: Props) {
  const { t } = useTranslation()
  const hotelDistanceModal = useTogglePopup()
  const { ref } = useClickOutside(() => {
    if (hotelDistanceModal.isOpen) {
      hotelDistanceModal.handleClose()
    }
  })
  const { distanceKey } = useDistanceKey(withUppercase)
  const value = distance ? t(i18Base + distanceKey, { count: distance }) : undefined

  const label = t(i18Base + '.Distance.ShowHotels')
  const ariaLabel = value
    ? t(i18Base + '.Input.PlaceAriaLabel', { label: label + ' ' + value })
    : t(i18Base + '.Input.PlaceAriaLabel', { label })

  return {
    value,
    ref,
    hotelDistanceModal,
    distanceKey: i18Base + distanceKey,
    ariaLabel,
    label,
  }
}
