import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'

type Props = {
  segment: TripDetailsFlightLegSegment
}

export function useBaggageModal({ segment }: Props) {
  const baggageModalToggle = useTogglePopup()

  const baggageInfo = {
    imageUrl: segment.images.rectangle || '',
    baggageFees: segment.baggageFees,
    name: segment.name || '',
  }
  const firstBaggageItem =
    baggageInfo.baggageFees && baggageInfo?.baggageFees?.items?.length
      ? baggageInfo?.baggageFees?.items[0]
      : undefined
  const firstBaggageRate = firstBaggageItem ? firstBaggageItem.rate : undefined
  const { mainCost: firstBaggageCost } = formatRate(firstBaggageRate, {
    morpheme: 'none',
    isInteger: true,
  })

  return {
    baggageInfo,
    baggageModalToggle,
    isBaggageShow: baggageInfo.baggageFees,
    firstBaggageCost,
  }
}
