import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import type { PromisedResult } from '@etta/core/type-utils'
import type {
  AddCreditCardMutationVariables,
  EditCreditCardMutationVariables,
  GetSuggestedHomeAirportsQueryVariables,
  GetAvailableVendorsQueryVariables,
  UpdateVisaMutationVariables,
  UpdatePassportMutationVariables,
  UpdateNationalIdCardMutationVariables,
  RemoveVisaMutationVariables,
  RemoveNationalIdCardMutationVariables,
  DeleteCreditCardMutationVariables,
  GetDelegatedUsersQueryVariables,
  ChangePasswordMutationVariables,
  GetAvailableSpecialRequestsQueryVariables,
  GetAvailableDelegatesQueryVariables,
  UpdateDelegatesMutationVariables,
  UpdateAuthorizersMutationVariables,
} from '@fiji/graphql/hooks'
// eslint-disable-next-line import/no-restricted-paths
import { PassportMapper } from '@etta/modules/booking/infra/passport/mappers'
import type { DelegateEntity } from '@etta/modules/settings/core/entities/delegate-entity'
import { SettingsErrors } from '../core/errors/settings.errors'
import type { SettingsErrorsInstances } from '../core/errors/settings.errors'
import type { UserCreditCardEntity } from '../core/entities/credit-cards.entity'
import type { SuggestedHomeAirportEntity } from '../core/entities/suggested-home-airport.entity'
import type { AvailableVendorsEntity } from '../core/entities/available-vendors.entity'
import type { ProfileFieldsSettingsEntity } from '../core/entities/profile-fields-settings-entity'
import type { SiteCardEntity } from '../core/entities/site-cards.entity'
import type { TimeZoneEntity } from '../core/entities/time-zone.entity'
import type { CurrencyEntity } from '../core/entities/currency.entity'
import type { LanguageEntity } from '../core/entities/language.entity'
import type { AvailableSpecialRequestsEntity } from '../core/entities/available-special-requests.entity'
import type { AvailableDelegatesEntity } from '../core/entities/available-delegates.entity'
import { SettingsMapper } from './mappers/settings-mapper'
import { SettingsDataProvider } from './settings.data-provider/settings.data-provider'

@Adapter()
export class SettingsAdapter {
  constructor(
    @Inject()
    private settingsDataProvider: SettingsDataProvider,
  ) {}

  async getCreditCards(): PromisedResult<UserCreditCardEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getCreditCards()

      if (error) {
        return Result.Err(new SettingsErrors.GetCreditCardsResponseError(error))
      }

      return Result.Ok(SettingsMapper.toCreditCardsEntity(data.creditCards))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetCreditCardsUnexpectedError(e))
    }
  }

  async addCreditCard(
    input: AddCreditCardMutationVariables['input'],
  ): PromisedResult<{ legacyId?: string | null }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.addPaymentCard(input)
      const typename = data?.addPaymentCard.__typename

      if (typename === 'PaymentCard' && data?.addPaymentCard.cardId) {
        return Result.Ok({ legacyId: data.addPaymentCard.legacyId })
      }
      return Result.Err(new SettingsErrors.AddCreditCardResponseError(errors))
    } catch (e) {
      return Result.Err(new SettingsErrors.AddCreditCardUnexpectedError(e))
    }
  }

  async editCreditCard(
    input: EditCreditCardMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.editCreditCard(input)

      if (errors || !data?.editCreditCard.success) {
        return Result.Err(new SettingsErrors.EditCreditCardResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.EditCreditCardUnexpectedError(e))
    }
  }

  async deleteCreditCard(
    input: DeleteCreditCardMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.deleteCreditCard(input)

      if (errors || !data?.deleteCreditCard.success) {
        return Result.Err(new SettingsErrors.DeleteCreditCardResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.DeleteCreditCardUnexpectedError(e))
    }
  }

  async getSuggestedHomeAirports(
    input: GetSuggestedHomeAirportsQueryVariables['input'],
  ): PromisedResult<SuggestedHomeAirportEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getSuggestedHomeAirports(input)

      if (error) {
        return Result.Err(new SettingsErrors.GetSuggestedHomeAirportsResponseError(error))
      }

      return Result.Ok(SettingsMapper.toSuggestedHomeAirportsEntity(data.suggestedHomeAirports))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetSuggestedHomeAirportsUnexpectedError(e))
    }
  }

  async updateVisa(
    input: UpdateVisaMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.updateVisa(input)
      const typename = data?.updateVisa.__typename

      if (
        (typename === 'BaseResponse' && !data?.updateVisa.success) ||
        typename === 'UnexpectedError' ||
        errors
      ) {
        return Result.Err(new SettingsErrors.UpdateVisaResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.UpdateVisaUnexpectedError(e))
    }
  }

  async removeVisa(
    input: RemoveVisaMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.removeVisa(input)

      if (
        (data?.removeVisa.__typename === 'BaseResponse' && !data?.removeVisa.success) ||
        data?.removeVisa.__typename === 'UnexpectedError'
      ) {
        return Result.Err(new SettingsErrors.RemoveVisaResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.RemoveVisaUnexpectedError(e))
    }
  }

  async updatePassport(
    input: UpdatePassportMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.updatePassport(input)

      if (data?.updatePassport.__typename === 'InvalidInputError') {
        return Result.Err(
          new SettingsErrors.UpdatePassportInputError(
            data.updatePassport.fields.map(PassportMapper.toFieldErrorValueObject),
          ),
        )
      }

      if (
        (data?.updatePassport.__typename === 'BaseResponse' && !data?.updatePassport.success) ||
        data?.updatePassport.__typename === 'UnexpectedError' ||
        errors
      ) {
        return Result.Err(new SettingsErrors.UpdatePassportResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.UpdatePassportUnexpectedError(e))
    }
  }

  async updateNationalIdCard(
    input: UpdateNationalIdCardMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.updateNationalIdCard(input)
      const typename = data?.updateNationalIdCard.__typename

      if (
        (typename === 'BaseResponse' && !data?.updateNationalIdCard.success) ||
        typename === 'UnexpectedError' ||
        errors
      ) {
        return Result.Err(new SettingsErrors.UpdateNationalIdCardResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.UpdateNationalIdCardUnexpectedError(e))
    }
  }

  async removeNationalIdCard(
    input: RemoveNationalIdCardMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.removeNationalIdCard(input)

      if (
        (data?.removeNationalIdCard.__typename === 'BaseResponse' &&
          !data?.removeNationalIdCard.success) ||
        data?.removeNationalIdCard.__typename === 'UnexpectedError'
      ) {
        return Result.Err(new SettingsErrors.RemoveNationalIdCardResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.RemoveNationalIdCardUnexpectedError(e))
    }
  }

  async getAvailableVendors(
    input: GetAvailableVendorsQueryVariables['input'],
  ): PromisedResult<AvailableVendorsEntity, SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getAvailableVendors(input)

      if (error) {
        return Result.Err(new SettingsErrors.GetAvailableVendorsResponseError(error))
      }

      return Result.Ok(SettingsMapper.toAvailableVendorsEntity(data.availableVendors))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetAvailableVendorsUnexpectedError(e))
    }
  }

  async getAvailableSpecialRequests(
    input: GetAvailableSpecialRequestsQueryVariables['input'],
  ): PromisedResult<AvailableSpecialRequestsEntity, SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getAvailableSpecialRequests(input)

      if (error) {
        return Result.Err(new SettingsErrors.GetAvailableSpecialRequestsResponseError(error))
      }

      return Result.Ok(
        SettingsMapper.toAvailableSpecialRequestsEntity(data.availableSpecialRequests),
      )
    } catch (e) {
      return Result.Err(new SettingsErrors.GetAvailableSpecialRequestsUnexpectedError(e))
    }
  }

  async getTimeZones(): PromisedResult<TimeZoneEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getTimeZones()

      if (error) {
        return Result.Err(new SettingsErrors.GetTimeZonesResponseError(error))
      }

      return Result.Ok(SettingsMapper.toTimeZonesEntity(data.getTimeZones))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetTimeZonesUnexpectedError(e))
    }
  }

  async getCurrencies(): PromisedResult<CurrencyEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getCurrencies()

      if (error) {
        return Result.Err(new SettingsErrors.GetCurrenciesResponseError(error))
      }

      return Result.Ok(SettingsMapper.toCurrenciesEntity(data.getCurrencies))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetCurrenciesUnexpectedError(e))
    }
  }

  async getLanguages(): PromisedResult<LanguageEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getLanguages()

      if (error) {
        return Result.Err(new SettingsErrors.GetLanguagesResponseError(error))
      }

      return Result.Ok(SettingsMapper.toLanguagesEntity(data.getLanguages))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetLanguagesUnexpectedError(e))
    }
  }

  async getProfileFieldsSettings(): PromisedResult<
    ProfileFieldsSettingsEntity[],
    SettingsErrorsInstances
  > {
    try {
      const { data, error } = await this.settingsDataProvider.getProfileFieldsSettings()

      if (error) {
        return Result.Err(new SettingsErrors.GetProfileFieldsSettingsResponseError(error))
      }

      return Result.Ok(SettingsMapper.toProfileFieldsSettingsEntity(data.profileFieldsSettings))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetProfileFieldsSettingsUnexpectedError(e))
    }
  }

  async getSiteCards(): PromisedResult<SiteCardEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getSiteCards()

      if (error) {
        return Result.Err(new SettingsErrors.GetCreditCardsResponseError(error))
      }

      return Result.Ok(SettingsMapper.toSiteCardsEntity(data.siteCards))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetCreditCardsUnexpectedError(e))
    }
  }

  async getDelegates(
    input: GetDelegatedUsersQueryVariables['input'],
  ): PromisedResult<DelegateEntity[], SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getDelegates(input)

      if (error) {
        return Result.Err(new SettingsErrors.GetDelegatesResponseError(error))
      }

      return Result.Ok(SettingsMapper.toDelegatesEntity(data.delegatedUsers))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetDelegatesUnexpectedError(e))
    }
  }

  async updateDelegates(
    input: UpdateDelegatesMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.updateDelegates(input)

      if (errors || !data?.updateDelegates.success) {
        return Result.Err(new SettingsErrors.UpdateDelegatesResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.UpdateDelegatesUnexpectedError(e))
    }
  }

  async updateAuthorizers(
    input: UpdateAuthorizersMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.updateAuthorizer(input)

      if (errors || !data?.updateAuthorizers.success) {
        return Result.Err(new SettingsErrors.UpdateAuthorizerResponseError(errors))
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.UpdateAuthorizerUnexpectedError(e))
    }
  }

  async getAvailableDelegates(
    query: GetAvailableDelegatesQueryVariables['query'],
  ): PromisedResult<AvailableDelegatesEntity[] | null, SettingsErrorsInstances> {
    try {
      const { data, error } = await this.settingsDataProvider.getAvailableDelegates(query)

      if (error) {
        return Result.Err(new SettingsErrors.GetAvailableDelegatesResponseError(error))
      }

      return Result.Ok(SettingsMapper.toAvailableDelegatesEntity(data.getAvailableDelegates))
    } catch (e) {
      return Result.Err(new SettingsErrors.GetAvailableDelegatesUnexpectedError(e))
    }
  }

  async changePassword(
    input: ChangePasswordMutationVariables['input'],
  ): PromisedResult<{ success: true }, SettingsErrorsInstances> {
    try {
      const { data, errors } = await this.settingsDataProvider.changePassword(input)

      if (data?.changePassword.__typename === 'BaseResponse' && !data?.changePassword.success) {
        switch (data.changePassword.code) {
          case 'BAD_CREDENTIALS':
            return Result.Err(new SettingsErrors.ChangePasswordBadCredentials(''))
          case 'PASSWORD_MISMATCH':
            return Result.Err(new SettingsErrors.ChangePasswordMismatch(''))
          case 'WEAK_PASSWORD':
            return Result.Err(new SettingsErrors.ChangePasswordWeak(''))
          default:
            return Result.Err(new SettingsErrors.ChangePasswordResponseError(errors))
        }
      }

      if (data?.changePassword.__typename === 'UnexpectedError') {
        return Result.Err(
          new SettingsErrors.ChangePassswordUnexpectedError(data.changePassword.message),
        )
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new SettingsErrors.ChangePassswordUnexpectedError(e))
    }
  }
}
