import { observer } from 'mobx-react-lite'
import { useAppSelector } from '@fiji/store'
import type { AppState } from '@fiji/store'
import { FlightType } from '@fiji/hooks/search-queries/use-air-search-query/types'
import { useAirSearchFormContext } from '@etta/modules/air-search/interface/use-air-search-form.context'
import { usePlacesStorageContext } from '@etta/modules/places-storage/interface/use-places-storage.context'
import { PostBookingAction } from '@fiji/enums'
import { useMeetingModeContext } from '@etta/modules/meeting/use-meeting-mode.context'
import { IndexLabel } from '../index-label/index-label'
import { LocationSection } from '../location-section'
import { CalendarTimeSection } from '../calendar-time-section'
import { AirSearchListItem } from '../air-search-form-styled'
import { CabinClassSection } from '../cabin-class-section'
import { CloseButton } from '../close-button/close-button'
import type { AirSearchFlightProps } from './types'
import { AirSearchFlightLayout } from './layout'

export const AirSearchFlight = observer(function AirSearchFlight({
  legNumber,
  costAllocationSlot,
  warningSlot,
  flightLegInputs,
  isLoading,
}: AirSearchFlightProps) {
  const { placesStorageStore } = usePlacesStorageContext()
  const {
    airSearchFormInputsActions,
    airSearchFormStore,
    airSearchQueryStore,
  } = useAirSearchFormContext()
  const postBookingAction = airSearchQueryStore.additionalQueries.postBookingAction
  const { flightSearchTimeRange, flightSearchTimeRangeNonUS } = useAppSelector(
    (state: AppState) => state.displayConfiguration,
  )
  const {
    meetingModeStore: { additionalDateRange },
  } = useMeetingModeContext()

  const {
    id,
    cabinClass,
    returnTimeRange,
    timeRange,
    departureDate,
    destinationPlace,
    originPlace,
    returnDate,
  } = flightLegInputs

  const isMultiCity = airSearchFormStore.searchFlightType === FlightType.MultiCity
  const isLocationDisabled = airSearchQueryStore.additionalQueries.isLocationDisabled
  const validationErrors = airSearchFormStore.validationErrors[legNumber] || {}

  // this is intended for return flight only change search
  const returnFlightSearchStartDate = airSearchQueryStore.returnFlightSearchStartDate
    ? new Date(airSearchQueryStore.returnFlightSearchStartDate)
    : undefined
  const returnFlightSearchEndDate = airSearchQueryStore.returnFlightSearchEndDate
    ? new Date(airSearchQueryStore.returnFlightSearchEndDate)
    : undefined

  return (
    <AirSearchListItem key={id} data-tracking-id={`leg-${legNumber}`}>
      {isMultiCity && (
        <IndexLabel
          isCloseButtonShow={airSearchFormStore.isCloseButton}
          onButtonClick={airSearchFormInputsActions.createRemoveFlight(id)}
          number={legNumber + 1}
        />
      )}

      <AirSearchFlightLayout
        isTwoSections={airSearchFormStore.searchFlightType !== FlightType.OneWay}
        isCabinClassInRow={isMultiCity}
        locationSlot={
          <LocationSection
            originPlace={originPlace}
            destinationPlace={destinationPlace}
            flightType={airSearchFormStore.searchFlightType}
            isErrorInput={airSearchFormInputsActions.checkIsErrorPlaces(legNumber)}
            onSwitch={airSearchFormInputsActions.switchPlaces({
              legNumber,
              flightSearchTimeRange,
              flightSearchTimeRangeNonUS,
            })}
            onOriginPlaceChange={airSearchFormInputsActions.createOriginPlaceChnage(legNumber)}
            onDestinationPlaceChange={airSearchFormInputsActions.createDestinationPlaceChnage(
              legNumber,
            )}
            placesHistory={placesStorageStore.getPlaces('air')}
            isDisabled={isLocationDisabled}
            validationErrors={validationErrors}
            isLoading={isLoading}
          />
        }
        calendarSlot={
          <CalendarTimeSection
            isDepartureDatePickerDisabled={postBookingAction === PostBookingAction.ModifyReturn}
            isDepartureTimeRangeDisabled={postBookingAction === PostBookingAction.ModifyReturn}
            timeRange={timeRange}
            returnTimeRange={returnTimeRange}
            departureDate={departureDate}
            returnDate={returnDate}
            dateLegend={additionalDateRange}
            originPlace={originPlace}
            destinationPlace={destinationPlace}
            flightType={airSearchFormStore.searchFlightType}
            onDateChange={airSearchFormInputsActions.createDateChange(legNumber)}
            isDateRangeValid={airSearchFormInputsActions.checkIsDateRangeValid}
            onTimeChange={airSearchFormInputsActions.createTimeChange(legNumber)}
            onReturnTimeRangeChange={airSearchFormInputsActions.createReturnTimeChnage(legNumber)}
            highlightDates={airSearchFormInputsActions.getHighlightedDates(departureDate)}
            previousDate={airSearchFormStore.getPreviousDate}
            validationErrors={validationErrors}
            startDate={returnFlightSearchStartDate}
            endDate={returnFlightSearchEndDate}
            isLoading={isLoading}
          />
        }
        cabinClassSlot={
          <CabinClassSection
            isInputButton
            cabinClass={cabinClass}
            onChange={airSearchFormInputsActions.createCabinClassChange(legNumber)}
          />
        }
        closeButton={
          isMultiCity && airSearchFormStore.isCloseButton ? (
            <CloseButton
              onClick={airSearchFormInputsActions.createRemoveFlight(id)}
              index={legNumber}
            />
          ) : null
        }
        costAllocationSlot={costAllocationSlot}
        warningSlot={warningSlot}
      />
    </AirSearchListItem>
  )
})
