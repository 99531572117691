import { css } from 'styled-components'

export const getKeyboardNavigationStyle = ({
  offset = 1,
  borderRadius = 0,
}: {
  offset?: number
  borderRadius?: number
}) => css`
  &:focus-visible {
    // !important was used to fix styling on Mozilla. By default it is overwriting by "default" styles
    outline: 2px solid ${({ theme }) => theme.colors.primary3} !important;
    outline-offset: ${offset}px;
    z-index: 10;
    ${borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `}
  }
`

export const KeyboardNavigationStyle = css`
  ${getKeyboardNavigationStyle({})}
`

export const visuallyHiddenStyle = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
`
