import styled, { css } from 'styled-components'
import { footnoteStrong, KeyboardNavigationStyle } from '@fiji/style'
import chevronLeft from '@etta/ui/icon/pwa-icon/assets/chevron-left.svg?url'
import chevronRight from '@etta/ui/icon/pwa-icon/assets/chevron-right.svg?url'

const MonthCaption = css`
  .CalendarMonth__title {
    margin: 10px 0 -10px 0;
    ${footnoteStrong};
    color: ${({ theme }) => theme.colors.mainText};
  }

  .CalendarMonth_table {
    margin-top: 6px;
  }
`

export const Picker = css`
  .DayPicker {
    width: 100% !important;
    overflow: hidden;
    box-shadow: 0 -5px 1px -5px #333;
    border-top: 1px ${({ theme }) => theme.colors.borderLight};
  }

  .DayPicker_focusRegion {
    box-shadow: 0 5px 1px -5px #333;
  }

  .DayPickerNavigation_button {
    border: none;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-top: -7px;
    color: #8a8f94;

    ${KeyboardNavigationStyle}
  }

  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 5px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    background-image: url(${chevronRight});
    margin-right: 4px;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    background-image: url(${chevronLeft});
    margin-left: -4px;
  }

  .CalendarMonthGrid_month__horizontal,
  .DayPicker_weekHeader {
    margin-top: -20px;
  }
  .DayPicker_weekHeader {
    visibility: hidden;
  }

  .DayPickerNavigation_svg__horizontal {
    display: none;
  }

  .DayPicker_weekHeader {
    font-weight: 600;
    height: 20px;
    color: #101820;
    background-color: #ecedee;
  }

  .DayPicker_weekHeader ul li:first-child,
  .DayPicker_weekHeader ul li:last-child {
    color: #757b81;
  }
`

export const CalendarDesktopContainer = styled.div`
  ${Picker}
  ${MonthCaption}
`
