import { UserStore } from '@etta/modules/user'
import { DisplayConfigurationStore } from '@etta/modules/display-configuration'
import { dateFormat } from '@fiji/utils/dates'
import { getHotelSearchBy } from '@fiji/utils/graphql'
import { Service, Inject } from '@etta/di'
import { SearchBy } from '@fiji/types'
import type { CreateHotelSearchInput } from '../../core/value-objects/create-hotel-search.input'
import type { GetHotelByIdInput } from '../../core/value-objects/get-hotel-by-id.input'
import type { GetHotelsInput } from '../../core/value-objects/get-hotels.input'
import { HotelSearchFormStore } from '../stores'
import { HotelSearchResultsStore, PAGE_SIZE } from '../stores/hotel-search-results.store'

@Service()
export class GetHotelsInputs {
  constructor(
    @Inject()
    private readonly hotelSearchResultsStore: HotelSearchResultsStore,
    @Inject()
    private readonly hotelSearchFormStore: HotelSearchFormStore,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly userStore: UserStore,
  ) {}

  hotelsInput(): GetHotelsInput | undefined {
    const {
      checkInDate,
      checkOutDate,
      filters,
      location,
      hotelResultId,
    } = this.hotelSearchFormStore.hotelSearchQuery

    const { offset, sortBy } = this.hotelSearchResultsStore
    const { distance, initialDistance } = filters || {}

    if (!checkInDate || !checkOutDate || !location?.latitude || !location?.longitude) {
      return
    }

    return {
      latitude: location.latitude,
      longitude: location.longitude,
      distance: distance ?? initialDistance,
      checkIn: dateFormat(checkInDate, 'y-MM-dd'),
      checkOut: dateFormat(checkOutDate, 'y-MM-dd'),
      guests: 1,
      airportCode: location?.airportCode || '',
      offset,
      locationName: location?.name,
      first: PAGE_SIZE,
      groupId: filters?.groupId ?? this.hotelSearchResultsStore.initialGroupId,
      hideSoldOut: false,
      starRatings: filters?.starRatings,
      brandIds: filters?.brandIds,
      amenityIds: filters?.amenityIds,
      hotelName: filters?.hotelName,
      hotelResultId,
      logsMetadata: this.buildMetadataLogs(),
      nameSearch: false,
      stationCode: '',
      orderBy: sortBy || '',
      searchBy: this.buildSearchBy(),
    }
  }

  hotelByIdInput(id: string): GetHotelByIdInput | undefined {
    const {
      checkInDate,
      checkOutDate,
      filters,
      location,
    } = this.hotelSearchFormStore.hotelSearchQuery
    return {
      checkIn: checkInDate ? dateFormat(checkInDate, 'yyyy-LL-dd') : '',
      checkOut: checkOutDate ? dateFormat(checkOutDate, 'yyyy-LL-dd') : '',
      groupId: filters?.groupId ?? this.hotelSearchResultsStore.initialGroupId,
      guests: 1,
      hotelResultId: '',
      id: id,
      latitude: location?.latitude!,
      logsMetadata: this.buildMetadataLogs(),
      longitude: location?.longitude!,
    }
  }

  createSearchInput(): CreateHotelSearchInput | undefined {
    const {
      checkInDate,
      checkOutDate,
      filters,
      itineraryId,
      location,
    } = this.hotelSearchFormStore.hotelSearchQuery

    const { profile } = this.userStore

    if (!checkInDate || !checkOutDate) {
      return
    }

    const searchBy = this.buildSearchBy()
    const hotelName = location?.isHotelAddress ? location?.shortName : ''
    return {
      itineraryId: itineraryId || '',
      searchBy: getHotelSearchBy(searchBy),
      latitude: location?.latitude || 0,
      longitude: location?.longitude || 0,
      distance: filters?.distance || filters?.initialDistance || 0,
      distanceUnit: this.getDistanceUnit(profile?.measurementUnit),
      checkIn: dateFormat(checkInDate, 'y-MM-dd') || '',
      checkOut: dateFormat(checkOutDate, 'y-MM-dd') || '',
      guests: 1,
      hotelName: hotelName || '',
      airportCode: location?.airportCode || '',
    }
  }

  private getDistanceUnit(measurementUnit?: string | null): 'km' | 'miles' {
    switch (measurementUnit) {
      case 'METRIC':
        return 'km'
      case 'US_IMPERIAL':
        return 'miles'
      default:
        return 'miles'
    }
  }

  private buildSearchBy() {
    if (this.hotelSearchFormStore.hotelSearchQuery.isSearchByNewArea) {
      return SearchBy.Geocode
    }
    if (this.hotelSearchFormStore.hotelSearchQuery.location?.airportCode) {
      return SearchBy.Airport
    }

    return SearchBy.Address
  }

  private buildMetadataLogs() {
    if (
      this.displayConfigurationStore.isHotelSearchServiceEnabled &&
      this.hotelSearchFormStore.hotelSearchQuery.itineraryId
    ) {
      return {
        // TO DO: grab itineraryId from itinerary store
        itineraryId: this.hotelSearchFormStore.hotelSearchQuery.itineraryId,
      }
    }
  }
}
