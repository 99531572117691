import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import {
  SegmentContainer,
  SegmentLeftBlock,
  SegmentRightBlock,
  SegmentRightBlockTitle,
} from '../rtp-eco-check-modal-mobile-styled'

type Props = {
  title: string | React.ReactNode
  emissionsKg: number
}

export function RtpEcoCheckModalRailSegment({ title, emissionsKg }: Props) {
  return (
    <SegmentContainer>
      <SegmentLeftBlock>
        <SegmentRightBlockTitle>{title}</SegmentRightBlockTitle>
      </SegmentLeftBlock>
      <SegmentRightBlock aria-label={emissionsKg + ' Kg CO2'}>
        <KgOfCo2 kgOfEmissions={emissionsKg} />
      </SegmentRightBlock>
    </SegmentContainer>
  )
}
