import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { FlightSafetyCheckModalMobile } from './mobile'
import { FlightSafetyCheckModalDesktop } from './desktop'

export function FlightSafetyCheckModalLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<FlightSafetyCheckModalMobile {...props} />}
      desktopSlot={<FlightSafetyCheckModalDesktop {...props} />}
    />
  )
}
