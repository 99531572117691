import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const Container = styled.div`
  padding: 0 16px;
`

export const Section = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};

  &:last-child {
    border: 0;
  }
`

export const SectionLogo = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) => !isMobile && 'margin-bottom: 12px;'}
`

export const AirlineLogo = styled.img<{ isMobile?: boolean }>`
  display: block;
  height: ${({ isMobile }) => (isMobile ? '24px' : '60px')};
`

export const BaggageFeeInfo = styled.div`
  padding: 10px 0 6px;
  color: ${(p) => p.theme.colors.mainText};
  ${footnoteMedium}
`

export const BaggageSeparator = styled.div`
  margin-top: 12px;
  border-top: 1px solid ${(p) => p.theme.colors.borderLight};
`
