import type { SupportedActionsV2Fragment } from '@fiji/graphql/types'
import type {
  TripSegmentValueObject,
  AvailableSegmentsValueObject,
} from '@etta/modules/review-trip/core'
import { useButtonVisibleStates } from './use-button-visible-states'
import { useResolveTripReviewAddButtons } from './use-resolve-trip-review-add-buttons'

type Args = {
  availableSegments?: AvailableSegmentsValueObject | null
  supportedActions?: SupportedActionsV2Fragment | null
  segments: TripSegmentValueObject[]
  isPostPurchase: boolean
  isMultiCity?: boolean
}

export function useButtonStates({
  availableSegments,
  supportedActions,
  segments,
  isPostPurchase,
  isMultiCity,
}: Args) {
  const {
    canAddFlight: isFlightEnabled,
    canAddHotel: isHotelEnabled,
    canAddCarRental: isCarRentalEnabled,
    canAddTrain: isTrainEnabled,
  } = useResolveTripReviewAddButtons({
    segments: segments,
    availableSegments,
    supportedActions,
    isPostPurchase,
    isMultiCity,
  })

  const visibleStates = useButtonVisibleStates({
    segments,
    availableSegments,
  })

  const enableStates = {
    isFlight: isFlightEnabled,
    isHotel: isHotelEnabled,
    isCarRental: isCarRentalEnabled,
    isTrain: isTrainEnabled,
  }

  return {
    visibleStates,
    enableStates,
  }
}
