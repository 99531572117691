import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Text } from '@etta/ui/text'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import { Swap } from '@etta/ui/swap'
import { MobilityPickupConfirmationDialogError } from '@etta/components/mobility-search-result-dialog/mobility-pickup-confirmation-dialog-error'
import { Spinner } from '@etta/ui/spinner'
import {
  ScrollContainer,
  ScrollContent,
} from '@etta/components/mobility-search-result-dialog/mobility-search-result-dialog-styled'
import {
  Body,
  Container,
  Footer,
  HeaderTextWrapper,
} from './mobility-pickup-confirmation-dialog-styled'
import { TRANSITION_NAME } from './transition'
import { useTrackMobilityPickUpConfirmation } from './hooks'

type Prop = {
  pickUpAddress: string | null | undefined
  isOpen: boolean
  handleOnEntered: () => void
  onConfirmButtonClick: () => void
  isFailureOnFetchingRideHailConfig: boolean
  isFetchingRideHailConfig: boolean
}

const i18nBase = 'Mobility.ConfirmResultDialog'

export function MobilityPickupConfirmationDialog({
  pickUpAddress,
  isOpen,
  handleOnEntered,
  onConfirmButtonClick,
  isFailureOnFetchingRideHailConfig,
  isFetchingRideHailConfig,
}: Prop) {
  const { t } = useTranslation()
  useTrackMobilityPickUpConfirmation({ isOpen, isError: isFailureOnFetchingRideHailConfig })

  return (
    <DialogAnimation
      isOpen={isOpen}
      onEntered={handleOnEntered}
      onExited={() => {}}
      isAnimated
      transitionName={TRANSITION_NAME}>
      <Container>
        <Swap
          is={isFailureOnFetchingRideHailConfig}
          isSlot={
            <ScrollContainer>
              <ScrollContent>
                <MobilityPickupConfirmationDialogError />
              </ScrollContent>
            </ScrollContainer>
          }>
          <Body>
            <HeaderTextWrapper>
              <Text variant="subHeadMedium" color="bodyText">
                {t(`${i18nBase}.PickupLabel`)}
              </Text>
            </HeaderTextWrapper>
            <Text variant="headline" color="mainText1">
              {pickUpAddress}
            </Text>
          </Body>
          <Footer>
            <Button
              fullWidth
              variant="solid"
              size="normal"
              onClick={onConfirmButtonClick}
              rightIcon={
                isFetchingRideHailConfig ? (
                  <Spinner type="spinout" color="white" size="small" />
                ) : (
                  <></>
                )
              }>
              {isFetchingRideHailConfig
                ? t(`${i18nBase}.LoadingButton`)
                : t(`${i18nBase}.ConfirmButton`)}
            </Button>
          </Footer>
        </Swap>
      </Container>
    </DialogAnimation>
  )
}
