import i18n from 'i18next'
import type { PreferencesType } from '../types'

export function getTitleByType(type: PreferencesType): string {
  const i18nBase = 'TravelerInfo.'

  switch (type) {
    case 'car':
      return i18n.t(i18nBase + 'CarRentalPreferences')
    case 'flight':
      return i18n.t(i18nBase + 'FlightPreferences')
    case 'hotel':
      return i18n.t(i18nBase + 'HotelPreferences')
    case 'rail':
      return i18n.t(i18nBase + 'RailPreferences')
  }
}
