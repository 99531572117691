import { useCallback, useEffect, useMemo, useState } from 'react'
import { isValid } from 'date-fns'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import type { PlainTime } from '@fiji/types'
import type { Dates } from '@fiji/hooks/use-date-picker/types'
import type { AddressInput } from '@fiji/graphql/types'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { dateFormat } from '@fiji/utils/dates'
import { appMode } from '@fiji/modes'
import { formatAddressInput } from '@etta/screens/mobility-search-page/utils/format-address-input'
import { useStoragePlace } from '@fiji/hooks/use-storage-place'
import type { PlaceWithAddress, SelectionState } from './select-location-modal/types'

const DATE_FORMAT = 'E, MMM dd'
const TIME_FORMAT = 'h:mm aa'

export function useMobilitySearchFormDialog() {
  const { isAndroid, isEttaDesktop } = appMode
  const [isOnDemand, setIsOnDemand] = useState(false)
  const [previousPickupDate, setPreviousPickupDate] = useState<Date | undefined>(undefined)
  const uberInfoModal = useTogglePopup()
  const selectDateIOSModal = useTogglePopup()
  const selectDateAndroidModal = useTogglePopup()
  const selectTimeAndroidModal = useTogglePopup()
  const {
    searchRideHailStore,
    searchRideHailActions,
    configurationStore,
  } = useRideHailSearchContext()
  const [isSelectLocationModalVisible, setSelectLocationModalVisible] = useState<boolean>(false)
  const [selectLocationState, setSelectLocationState] = useState<SelectionState>('pickUp')
  const [isItineraryOpen, setIsItineraryOpen] = useState<boolean>(false)
  const { addNewPlace } = useStoragePlace('mobility')

  const {
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
  } = searchRideHailStore.searchRideHailForm

  const formattedPickUpTime = pickUpTime
    ? dateFormat(new Date().setHours(pickUpTime.hours, pickUpTime.minutes), TIME_FORMAT)
    : ''

  const formattedPickUpDate = useMemo(() => {
    if (isOnDemand && previousPickupDate) {
      return dateFormat(previousPickupDate, DATE_FORMAT)
    }
    return pickUpDate ? dateFormat(new Date(pickUpDate), DATE_FORMAT) : ''
  }, [isOnDemand, pickUpDate, previousPickupDate])

  const pickUpDateTime = useMemo(() => {
    const defaultDate = new Date()
    const defaultTime = { hours: 12, minutes: 0 }

    const date = pickUpDate && isValid(pickUpDate) ? new Date(pickUpDate) : defaultDate
    const { hours, minutes } = pickUpTime || defaultTime

    date.setHours(hours, minutes)
    return date
  }, [pickUpDate, pickUpTime])

  useEffect(() => {
    if (!searchRideHailStore.isOpenMobilitySearchDesktopModal) {
      setSelectLocationModalVisible(false)
    }
  }, [searchRideHailStore.isOpenMobilitySearchDesktopModal, setSelectLocationModalVisible])

  const isDisabledSearch = useMemo(() => {
    return !isOnDemand && (!pickUpDate || !pickUpTime)
  }, [isOnDemand, pickUpDate, pickUpTime])

  const handleTapOnDateInput = useCallback(() => {
    if (isOnDemand) {
      return
    }

    if (isAndroid || isEttaDesktop) {
      selectDateAndroidModal.handleOpen()
      return
    }
    selectDateIOSModal.handleOpen()
  }, [isAndroid, isEttaDesktop, isOnDemand, selectDateAndroidModal, selectDateIOSModal])

  const handleTapOnTimeInput = useCallback(() => {
    if (isOnDemand) {
      return
    }

    if (isAndroid || isEttaDesktop) {
      selectTimeAndroidModal.handleOpen()
      return
    }
    selectDateIOSModal.handleOpen()
  }, [isAndroid, isEttaDesktop, isOnDemand, selectDateIOSModal, selectTimeAndroidModal])

  const handleSubmitDate = useCallback(
    ({ date }: Dates) => {
      if (!date) {
        return
      }
      searchRideHailActions.setSearchRideHailForm({
        pickUpDate: date,
      })

      selectTimeAndroidModal.handleOpen()
    },
    [searchRideHailActions, selectTimeAndroidModal],
  )

  const handleSubmitTime = useCallback(
    (time: PlainTime) => {
      searchRideHailActions.setSearchRideHailForm({
        pickUpTime: {
          hours: time.hours,
          minutes: time.minutes,
        },
      })
    },
    [searchRideHailActions],
  )

  const handleSubmitDateTime = useCallback(
    (value: Date) => {
      searchRideHailActions.setSearchRideHailForm({
        pickUpDate: value,
        pickUpTime: {
          hours: value.getHours(),
          minutes: value.getMinutes(),
        },
      })
    },
    [searchRideHailActions],
  )

  const handleToggleOnDemand = useCallback(
    (isNow: boolean) => {
      setIsOnDemand(isNow)
      setPreviousPickupDate(pickUpDate)

      if (isNow) {
        searchRideHailActions.setSearchRideHailForm({
          pickUpDate: undefined,
          pickUpTime: undefined,
        })
      } else {
        searchRideHailActions.setSearchRideHailForm({
          pickUpDate: previousPickupDate,
        })
      }
    },
    [searchRideHailActions, previousPickupDate, pickUpDate],
  )

  const handleClickPickUp = useCallback(() => {
    setSelectLocationModalVisible(true)
    setSelectLocationState('pickUp')
  }, [])

  const handleClickDropOff = useCallback(() => {
    setSelectLocationModalVisible(true)
    setSelectLocationState('dropOff')
  }, [])

  const handleSelectLocationModalClose = useCallback(() => {
    setSelectLocationModalVisible(false)
  }, [])

  const handleSelectPickUpDropOff = useCallback(
    (place?: PlaceWithAddress, address?: AddressInput) => {
      if (!(place || address)) {
        return
      }

      if (place) {
        addNewPlace(place)
      }

      if (selectLocationState === 'pickUp') {
        searchRideHailActions.setSearchRideHailForm({
          pickUpPlace: place,
          pickUpAddress: getPlaceAddress({ place, address }),
        })
      } else {
        searchRideHailActions.setSearchRideHailForm({
          dropOffPlace: place,
          dropOffAddress: getPlaceAddress({ place, address }),
        })
      }
      handleSelectLocationModalClose()
    },
    [selectLocationState, searchRideHailActions, handleSelectLocationModalClose, addNewPlace],
  )

  const handleItineraryClose = useCallback(() => {
    setIsItineraryOpen(false)
  }, [])

  const handleItineraryButtonClick = useCallback(() => {
    setIsItineraryOpen(!isItineraryOpen)
  }, [isItineraryOpen])

  function getPlaceAddress(selectedPlace: {
    place?: PlaceWithAddress
    address?: AddressInput
  }): string | undefined {
    if (selectedPlace.place && selectedPlace.place?.address) {
      return selectedPlace.place?.address
    }

    if (selectedPlace.address) {
      return formatAddressInput(selectedPlace.address)
    }

    return undefined
  }

  return {
    pickUpPlace,
    dropOffPlace,
    pickUpDate,
    pickUpTime,
    formattedPickUpDate,
    formattedPickUpTime,
    isDisabledSearch,

    // On-demand
    isOnDemand,
    handleToggleOnDemand,

    // Info modal
    isModalInfoOpen: uberInfoModal.isOpen,
    handleModalInfoClose: uberInfoModal.handleClose,
    handleModalInfoOpen: uberInfoModal.handleOpen,
    advanceBookingInDays: configurationStore.advanceBookingInDays,

    // Select location modal
    isSelectLocationModalVisible,
    handleSelectPickUpDropOff,
    selectLocationState,
    handleClickPickUp,
    handleClickDropOff,
    handleSelectLocationModalClose,

    // Date & time input handlers
    handleTapOnDateInput,
    handleTapOnTimeInput,

    // Date & time modals
    pickUpDateTime,
    isSelectDateIOSModalOpen: selectDateIOSModal.isOpen,
    isSelectDateAndroidModalOpen: selectDateAndroidModal.isOpen,
    isSelectTimeAndroidModalOpen: selectTimeAndroidModal.isOpen,
    handleCloseIOSDateTimeModal: selectDateIOSModal.handleClose,
    handleCloseAndroidDateModal: selectDateAndroidModal.handleClose,
    handleCloseAndroidTimeModal: selectTimeAndroidModal.handleClose,
    handleSubmitDate,
    handleSubmitTime,
    handleSubmitDateTime,

    // Itinerary dialog
    isItineraryOpen,
    handleItineraryButtonClick,
    handleItineraryClose,
  }
}
