import { useTranslation } from 'react-i18next'
import { Wrapper, IconElement, TextElement, BlockElement } from './long-request-info-styled'

const i18nBase = 'CarRentalResults'

export function LongRequestInfo() {
  const { t } = useTranslation()

  return (
    <BlockElement>
      <Wrapper>
        <IconElement />
        <TextElement>{t(`${i18nBase}.LongRequest`)}</TextElement>
      </Wrapper>
    </BlockElement>
  )
}
