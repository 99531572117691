import { useTranslation } from 'react-i18next'
import type { TextVariant } from '@etta/ui/text/types'
import { useLoadDelegates } from '@fiji/hooks/delegates/use-load-delegates'

const i18nPath = 'Delegate.'

export const useDelegateName = (
  isWithBooking?: boolean,
  isDesktopMode?: boolean,
  name?: string,
) => {
  const { t } = useTranslation()
  const { currentDelegatedUser } = useLoadDelegates()

  const fullName = [currentDelegatedUser?.firstName, currentDelegatedUser?.lastName]
    .filter(Boolean)
    .join(' ')
  const travelerNameWithBooking = t(i18nPath + 'BookingFor', { user: fullName })
  const loadedName = isWithBooking ? travelerNameWithBooking : fullName

  let variant: TextVariant = 'footnoteMedium'
  if (isDesktopMode) {
    variant = 'subHeadStrong'
  }
  if (name) {
    variant = 'subHeadMedium'
  }

  return {
    loadedName,
    textVariant: variant,
  }
}
