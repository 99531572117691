import { useTranslation } from 'react-i18next'
import { NoResultSearchScreen } from '@etta/components/no-result-search-screen/no-result-search-screen'
import type { Room, Rate } from '@fiji/graphql/types'
import { HotelRoomSkeleton } from '@etta/components/hotel-room/skeleton'
import { SoldOut } from './sold-out'
import { RoomsContentLayout } from './rooms-content'

type Props = {
  onRoomSelect?: (room: Room) => void
  onRoomDetailsSelect: (room: Room) => void
  isSoldOut?: boolean
  rooms?: Room[]
  isLoading?: boolean
  onRetry?: () => void
  isError?: boolean
  perDiemRateCap?: Rate | null
  serviceFeeLabel?: string
  totalNights: number
}

export function HotelDetailsRooms({
  rooms = [],
  isSoldOut,
  onRoomSelect,
  onRoomDetailsSelect,
  isLoading,
  onRetry,
  isError,
  perDiemRateCap,
  serviceFeeLabel,
  totalNights,
}: Props) {
  const { t } = useTranslation()
  const areRoomsSelectable = !!onRoomSelect

  if (isLoading) {
    return (
      <>
        <HotelRoomSkeleton />
        <HotelRoomSkeleton />
        <HotelRoomSkeleton />
      </>
    )
  }

  if (isError) {
    return (
      <NoResultSearchScreen
        type="hotel"
        title={t('SearchSegments.NoResultErrorScreen.ErrorTitle')}
        subTitle={t('SearchSegments.NoResultErrorScreen.ErrorHotelSubTitle')}
        buttons={[
          {
            title: t('SearchSegments.NoResultErrorScreen.SearchButton'),
            onClick: onRetry,
            buttonVariant: 'solid',
          },
        ]}
      />
    )
  }

  if (isSoldOut) {
    return <SoldOut />
  }

  return (
    <RoomsContentLayout
      rooms={rooms}
      serviceFeeLabel={serviceFeeLabel}
      perDiemRateCap={perDiemRateCap}
      areRoomsSelectable={areRoomsSelectable}
      onRoomSelect={onRoomSelect}
      onRoomDetailsSelect={onRoomDetailsSelect}
      totalNights={totalNights}
    />
  )
}
