import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  margin: 0 8px 9px 0;
`
