import { useTranslation } from 'react-i18next'
import {
  NoUnusedTickets,
  UnusedTicketsLoadingLayout,
} from '@etta/components/unused-tickets-modal/components'
import { Text } from '@etta/ui/text'
import { UnusedTicketLayout } from '@etta/components/unused-ticket'
import type { Args } from '../../types'
import { Badge, ContentHeader, Tickets } from './unused-tickets-desktop-styled'

const i18nBase = 'UnusedTickets'

export function UnusedTicketsContentDesktop({ isLoading, unusedTickets }: Args) {
  const { t } = useTranslation()

  if (isLoading) {
    return <UnusedTicketsLoadingLayout />
  }
  if (!unusedTickets?.length) {
    return <NoUnusedTickets />
  }

  return (
    <>
      <ContentHeader>
        <Text variant="headline" color="mainText">
          {t(`${i18nBase}.ListHeader`)}
        </Text>
        <Badge>
          <Text variant="captionStrong" color="white">
            {unusedTickets?.length}
          </Text>
        </Badge>
      </ContentHeader>
      <Tickets>
        {unusedTickets?.map((unusedTicket) => (
          <UnusedTicketLayout key={unusedTicket.ticketNumber} unusedTicket={unusedTicket} />
        ))}
      </Tickets>
    </>
  )
}
