import { MediaLayout } from '@etta/ui/media-layout'
import type { CarRentalMapProps } from '../types'
import { CarRentalMapDesktopLayout } from './car-rental-map-desktop-layout'

export function CarRentalMapLayout({
  locations,
  center,
  onLocationClick,
  selectedDropOffLocation,
  selectedPickUpLocation,
}: CarRentalMapProps) {
  return (
    <MediaLayout
      mobileSlot={<div />}
      desktopSlot={
        <CarRentalMapDesktopLayout
          locations={locations}
          center={center}
          onLocationClick={onLocationClick}
          selectedDropOffLocation={selectedDropOffLocation}
          selectedPickUpLocation={selectedPickUpLocation}
        />
      }
    />
  )
}
