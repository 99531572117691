import type { OverlappingTrip } from '@fiji/graphql/types'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useGetTripQuery } from '@fiji/graphql/hooks'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { useTripFormattedDate } from './use-formatted-date'

type Props = {
  overlappingTrip: OverlappingTrip
}

const FULL_DATE_STR_LENGHT = 29 // '2020-10-06T10:00:00.000-07:00'

export function useTripDuplicate({ overlappingTrip }: Props) {
  const { postBookingTripStore } = usePostBookingContext()

  const { data, loading } = useGetTripQuery({
    variables: {
      input: {
        id: `${overlappingTrip.id}`,
        transactionGroupId: postBookingTripStore.queryParams.transactionGroupId || '',
      },
    },
  })

  const startDate =
    data?.trip.rollUpStartTime.length === FULL_DATE_STR_LENGHT
      ? deleteTimezone(data?.trip.rollUpStartTime)
      : data?.trip.rollUpStartTime
  const endDate =
    data?.trip.rollUpEndTime.length === FULL_DATE_STR_LENGHT
      ? deleteTimezone(data?.trip.rollUpEndTime)
      : data?.trip.rollUpEndTime

  const formattedDate = useTripFormattedDate({
    start: startDate,
    end: endDate,
  })

  return { isLoading: loading, formattedDate, trip: data?.trip }
}
