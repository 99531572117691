import { useTranslation } from 'react-i18next'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { Header } from '@etta/ui/header'
import { Screen } from '@etta/ui/screen'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Swap } from '@etta/ui/swap'
import { ErrorContainer } from '@etta/ui/error-container'
import type { LayoutProps } from '../../types'
import { Title, InfoTitle, Content, Footer } from './purchase-additional-info-form-mobile-styled'

export function PurchaseAdditionalInfoFormMobile({
  onBack,
  isShowDelegateName,
  contentSlot,
  onSubmitHandler,
  isButtonDisabled,
  isError,
  isHoldFlow,
  onReload,
}: LayoutProps) {
  const i18nBase = 'AdditionalInfoForm.'
  const { t } = useTranslation()

  const headerText = isHoldFlow ? t('HoldTrip.HoldTrip') : t(`${i18nBase}Title`)

  return (
    <Screen backgroundColor="background">
      <Screen.Header>
        <Header
          layout="sub-title"
          leftSlot={<Header.BackButton color="mainText2" onClick={onBack} />}
          backgroundColor="background2">
          <Title>{headerText}</Title>
          {isShowDelegateName && (
            <Block isFlexBlock>
              <DelegateName isWithBooking color="mainText2" />
            </Block>
          )}
        </Header>
      </Screen.Header>

      <Screen.Container as={'main'}>
        <Content>
          <InfoTitle>{t(`${i18nBase}AdditionalInformation`)}</InfoTitle>
          <Swap
            is={isError}
            isSlot={
              <ErrorContainer
                onReload={onReload}
                title={t('AdditionalInfoForm.Errors.UnableToLoad')}
              />
            }>
            {contentSlot}
          </Swap>
        </Content>
      </Screen.Container>
      <Screen.Footer as={'footer'}>
        <Footer>
          <Button
            fullWidth
            minWidth={14}
            onClick={onSubmitHandler}
            disabled={isButtonDisabled}
            data-tracking-id="additional-info-continue-button">
            {t(`${i18nBase}ButtonText`)}
          </Button>
        </Footer>
      </Screen.Footer>
    </Screen>
  )
}
