import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@etta/modules/user'
import type { TripConfirmationType } from '@etta/modules/booking/core/value-objects/trip-confirmation-type.value-object'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { formatCancellationDate } from '@fiji/utils/dates/cancellation-date-format'
import { useShowDelegateName } from '@fiji/hooks/delegates'
import type { FailedBookingReasons } from './types'

type Args = {
  confirmationType: TripConfirmationType
  autoCancellationTime?: string | null
  failedBookingReasons: FailedBookingReasons
}

const i18nBase = 'PostBooking.TripDetails'

export function useTripState({
  confirmationType,
  autoCancellationTime,
  failedBookingReasons,
}: Args) {
  const { t } = useTranslation()
  const { isShowDelegateName } = useShowDelegateName()
  const { userStore } = useUserContext()
  const timeZone = userStore.profile?.timeZone
  const { postBookingTripStore } = usePostBookingContext()

  const travelerFullname = postBookingTripStore.travelerNames?.travelerFullName ?? ''

  const getTitle = useCallback(
    (label: string, delegateLabel: string): string => {
      return isShowDelegateName
        ? t(`${i18nBase}.TripHead.${delegateLabel}`, {
            traveler: travelerFullname,
          })
        : t(`${i18nBase}.TripHead.${label}`)
    },
    [travelerFullname, isShowDelegateName, t],
  )

  const title = useMemo(() => {
    switch (confirmationType) {
      case 'hold':
        return getTitle('Hold', 'HoldTraveler')
      case 'partial':
        return getTitle('PartiallyConfirmation', 'PartiallyConfirmationTraveler')
      case 'unconfirmed': {
        if (failedBookingReasons.hotel.confirmationFails.length) {
          return getTitle('FailedHotel', 'FailedHotelTraveler')
        }
        if (failedBookingReasons.hotel.directConnectFails.length) {
          return getTitle('FailedHotel', 'FailedHotelTraveler')
        }
        if (failedBookingReasons.hotel.creditCardFails.length) {
          return getTitle('FailedCreditCard', 'FailedCreditCardTraveler')
        }
        return getTitle('FailedHotel', 'FailedHotelTraveler')
      }
      case 'regular':
      default:
        return getTitle('Confirmation', 'ConfirmationTraveler')
    }
  }, [confirmationType, getTitle, failedBookingReasons])

  const subTitle = useMemo(() => {
    switch (confirmationType) {
      case 'unconfirmed':
        return undefined
      case 'partial':
        return t(`${i18nBase}.PartialConfirmationSubTitle`)
      default:
        t(`${i18nBase}.WeWillSendEmail`)
    }
  }, [confirmationType, t])

  return {
    confirmationType,
    title,
    subTitle,
    failedBookingReasons,
    autoCancelTripDate:
      confirmationType === 'hold' && autoCancellationTime
        ? formatCancellationDate(autoCancellationTime, timeZone)
        : '',
  }
}
