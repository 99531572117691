import { MediaLayout } from '@etta/ui/media-layout'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { ScreenType } from '@fiji/modes'
import type { LayoutProps } from '../types'
import { TripDetailsDynamicSiteMessagesDesktop } from './desktop/trip-details-dynamic-site-messages-desktop'
import { TripDetailsDynamicSiteMessagesMobile } from './mobile/trip-details-dynamic-site-messages-mobile'

export function TripDetailsDynamicSiteMessagesLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<TripDetailsDynamicSiteMessagesMobile {...props} />}
      desktopSlot={<TripDetailsDynamicSiteMessagesDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutTripsFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
