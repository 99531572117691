export enum CustomFieldType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Enumeration = 'ENUMERATION',
  Monetary = 'MONETARY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  PreTrip = 'PRE_TRIP',
  Text = 'TEXT',
  Time = 'TIME',
}
