import styled from 'styled-components'
import { buttonSmall } from '@fiji/style'
import { IconButton } from '@etta/ui/icon-button'

export const Text = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.colors.mainText1};
`

export const Icon = styled(IconButton)``

export const Wrapper = styled.button`
  width: 100%;
  padding: 12px 0;
  margin: 0;
  border: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  height: 64px;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: default;
    ${Text} {
      color: ${(props) => props.theme.colors.bodyText};
      opacity: 0.6;
    }
    ${Icon} {
      opacity: 0.6;
    }
  }
`
