import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import {
  HeaderContainer,
  HeaderTitle,
  HeaderDescription,
  HeaderTextContainer,
  BodyContainer,
  BodyBlock,
  BlockTitle,
  BlockDescription,
  DescriptionLink,
  UberLogo,
} from './uber-terms-condition-modal-styled'

type Props = {
  isVisible: boolean
  isAnimated: boolean
  onClose: () => void
}

export function UberTermsConditionModal({ isVisible, isAnimated, onClose }: Props) {
  const { t } = useTranslation()

  const i18nBasePath = 'Mobility.TermsModal.'

  return (
    <Modal
      isVisible={isVisible}
      handleModalVisibility={onClose}
      isAnimated={isAnimated}
      data-tracking-id="uber-terms-modal">
      <Modal.Title withBorder closeButtonPosition="right">
        <HeaderContainer>
          <UberLogo name="uberLogoPWA" aria-label="uber logo" />
          <HeaderTextContainer>
            <HeaderTitle>Uber</HeaderTitle>
            <HeaderDescription>{t(i18nBasePath + 'AdditionalInformation')}</HeaderDescription>
          </HeaderTextContainer>
        </HeaderContainer>
      </Modal.Title>
      <Modal.Body>
        <BodyContainer>
          <BodyBlock>
            <BlockTitle>{t(i18nBasePath + 'FareInformation')}</BlockTitle>
            <BlockDescription>
              {t(i18nBasePath + 'VisitUberFareWebsite')}
              <br />
              <DescriptionLink
                href={'https://www.uber.com/global/en/price-estimate'}
                target="_blank"
                rel="noopener noreferrer">
                https://www.uber.com/global/en/price-estimate
              </DescriptionLink>
            </BlockDescription>
          </BodyBlock>

          <BodyBlock>
            <BlockTitle>{t(i18nBasePath + 'CancellationPolicy4UberTrips')}</BlockTitle>
            <BlockDescription>{t(i18nBasePath + 'CancellationPolicyDescription')}</BlockDescription>
          </BodyBlock>

          <BodyBlock>
            <BlockTitle>{t(i18nBasePath + 'TravelPolicyProgram')}</BlockTitle>
            <BlockDescription>{t(i18nBasePath + 'TravelPolicyDescription')}</BlockDescription>
          </BodyBlock>

          <BodyBlock>
            <BlockTitle>{t(i18nBasePath + 'UbersTermsAndConditions')}</BlockTitle>
            <BlockDescription>
              {t(i18nBasePath + 'VisitUberTermsWebsite')}
              <br />
              <DescriptionLink
                href={'https://www.uber.com/terms'}
                target="_blank"
                rel="noopener noreferrer">
                https://www.uber.com/terms
              </DescriptionLink>
            </BlockDescription>
          </BodyBlock>

          <BodyBlock>
            <BlockTitle>{t(i18nBasePath + 'UbersPolicyNotice')}</BlockTitle>
            <BlockDescription>
              {t(i18nBasePath + 'UbersPolicyDescription')}
              <br />
              <DescriptionLink
                href={'https://www.uber.com/privacy'}
                target="_blank"
                rel="noopener noreferrer">
                https://www.uber.com/privacy
              </DescriptionLink>
            </BlockDescription>
          </BodyBlock>
        </BodyContainer>
      </Modal.Body>
    </Modal>
  )
}
