import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import type { ServiceFeeConfigurationValueObject } from '@etta/modules/display-configuration/core/value-objects/service-fee-configuration.value-object'
import { useTaxesAndFeesModal } from './use-taxes-and-fees-modal'
import { Container, List, ListItem, InlineText } from './taxes-and-fees-modal-styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  serviceFeeConfiguration: ServiceFeeConfigurationValueObject[]
}

const i18nBase = 'SearchSegments'

export function TaxesAndFeesModal({ isOpen, onClose, serviceFeeConfiguration }: Props) {
  const { t } = useTranslation()

  const { isServiceFeeEnabled, serviceFees } = useTaxesAndFeesModal({
    serviceFeeConfiguration,
  })

  if (!isServiceFeeEnabled) {
    return null
  }

  return (
    <Modal
      data-tracking-id="taxes-and-fees-modal"
      isVisible={isOpen}
      handleModalVisibility={onClose}>
      <Modal.Title minHeight={62} leftSeparatorWidth={20} withBorder>
        {t(`${i18nBase}.TaxesAndFees`)}
      </Modal.Title>
      <Modal.Body>
        <Container>
          <List>
            <ListItem>
              <InlineText variant="subHeadMedium" color="bodyText">
                {t(`${i18nBase}.TaxesAndFeesDisclaimer`)}
              </InlineText>
            </ListItem>
            {serviceFees.map(({ id, name, description }) => (
              <ListItem key={id}>
                <InlineText variant="subHeadStrong" color="bodyText">
                  {name}:&nbsp;
                  <InlineText variant="subHeadMedium" color="bodyText">
                    {description}
                  </InlineText>
                </InlineText>
              </ListItem>
            ))}
          </List>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
