import { useEffect } from 'react'
import type { RailLegType } from '@fiji/enums'
import { usePreventPullRefresh } from '@fiji/hooks/body/use-prevent-pull-refresh'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import { useRailSearchFormContext } from '@etta/modules/rail-search-form/interface/use-rail-search-form.context'
import { useHeaderTripReviewTitle } from './use-header-trip-review-title'

type Args = {
  railLegType: RailLegType
}

export function useRailSearchResultPage({ railLegType }: Args) {
  const { railSearchFormActions } = useRailSearchFormContext()

  useEffect(() => {
    railSearchFormActions.dropEditCache()
  }, [railSearchFormActions])

  usePreventPullRefresh()

  // TODO: FRI-111 check we need this in new code
  // const redirectParamsOnFail = useMemo(() => {
  //   return redirectParamsOnRefetchFail({
  //     isMobile: screenType === ScreenType.Mobile,
  //     mobileNextPage: ROUTES.rail.search,
  //     hasTrip: !isEmptyItinerary,
  //     nextPage: ROUTES.explore,
  //     segmentType: SegmentType.Train,
  //   })
  // }, [isEmptyItinerary, screenType])

  const { locationsLabel, datesLabel } = useHeaderTripReviewTitle({
    railLegType,
  })

  const { railActions } = useRailContext()

  const onBackByType = () => railActions.onBackByType(railLegType)

  return {
    onBackByType,
    locationsLabel,
    datesLabel,
  }
}
