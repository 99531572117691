import { SegmentType } from '@etta/core/enums'
import { Service } from '@etta/di'
import type {
  TrainSegmentEntity,
  CarRentalSegmentEntity,
  FlightSegmentEntity,
  HotelSegmentEntity,
} from '../../core/entity'
import type { TripSegmentValueObject } from '../../core/value-objects'

@Service()
export class SegmentTypeCheckService {
  isHotelSegment(segment: TripSegmentValueObject): segment is HotelSegmentEntity {
    if (segment.type === SegmentType.Hotel) {
      return true
    }
    return false
  }

  isCarRentalSegment(segment: TripSegmentValueObject): segment is CarRentalSegmentEntity {
    if (segment.type === SegmentType.CarRental) {
      return true
    }
    return false
  }

  isFlightSegment(segment: TripSegmentValueObject): segment is FlightSegmentEntity {
    if (segment.type === SegmentType.Flight) {
      return true
    }
    return false
  }

  isTrainSegment(segment: TripSegmentValueObject): segment is TrainSegmentEntity {
    if (segment.type === SegmentType.Train) {
      return true
    }
    return false
  }
}
