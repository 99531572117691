import type { MouseEvent } from 'react'
import { useCallback, useState } from 'react'
import { useNetworkStatus } from '@fiji/hooks/use-network-status'

type Props = {
  onButtonClick?: (event: MouseEvent) => void
}

export function useOfflineBlock({ onButtonClick }: Props) {
  const isShowButton = !!onButtonClick
  const [isRetried, setIsRetried] = useState(navigator.onLine ? true : !isShowButton)
  const { isOffline, isOnline } = useNetworkStatus({
    onOffline: () => setIsRetried(!isShowButton),
  })
  const handleButtonClick = useCallback(
    (ev) => {
      if (isOnline) {
        setIsRetried(true)
      }

      onButtonClick?.(ev)
    },
    [isOnline, onButtonClick],
  )

  return {
    isShowChildren: !(isOffline || !isRetried),
    handleButtonClick,
    isShowButton: !!onButtonClick,
  }
}
