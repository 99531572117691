import type { AnchorType } from '../types'

export function getMarginForZero(zeroAnchor: AnchorType, topAnchor: AnchorType) {
  if (!zeroAnchor.current) {
    return 0
  }
  if (!topAnchor.current) {
    return 0
  }
  return (
    zeroAnchor.current.getBoundingClientRect().top - topAnchor.current.getBoundingClientRect().top
  )
}
