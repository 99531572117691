import styled from 'styled-components'
import { subHeadStrong, footnoteMedium } from '@fiji/style'

export const TooltipOopReasonsContainer = styled.div`
  ${footnoteMedium}
`

export const OutOfPolicyReasonText = styled.span``
export const OutOfPolicyReasonContainer = styled.div`
  ${footnoteMedium}
`
export const OutOfPolicyMultipleReasonsContainer = styled.div``
export const OutOfPolicyMultipleReasonsTitle = styled.span`
  ${subHeadStrong}
`
export const OutOfPolicyReasonTitle = styled.span``
export const OutOfPolicyReasonTextList = styled.li`
  list-style: none;
  border-bottom: 1px solid rgba(225, 230, 234, 0.3);
  margin-top: 10px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
`
