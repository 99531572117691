import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { TrainBookingInfo } from '@etta/components/train-booking-info'
import { RailDetailsEcoCheck } from '@etta/components/rail-details-eco-check/rail-details-eco-check'
import type { TrainPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Block } from '@etta/ui/block'
import type { RailEmission } from '@fiji/types'
import { RailDetailsPermittedStations } from '../rail-details-permitted-stations'
import { Content, HeadlineWrapper, TrainBookingOverlay } from './trip-train-modal-styled'
import { Headline, InfoCard, Price, TicketDelivery } from './components'
import { useTripTrainModal } from './use-trip-train-modal'

type Props = {
  train: TrainPostBookingValueObject
  railEmission: RailEmission
  onClose: () => void
  isVisible: boolean
  isLoading?: boolean
}

export function TripTrainModal({ isVisible, onClose, train, railEmission }: Props) {
  const {
    isCollect,
    collectionRefNumber,
    headerRef,
    headerTitle,
    scrollContainerRef,
    modalPosition,
    travelerName,
    transactionId,
    isMobile,
    segments,
    originStation,
    destinationStation,
    permittedOriginStations,
    permittedDestinationStations,
    hasPermittedStations,
    ticketRestrictions,
  } = useTripTrainModal({ train })

  return (
    <Modal
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}
      position={modalPosition}>
      <Modal.Body ref={scrollContainerRef}>
        <Modal.Header isMobile backgroundColor="transparent">
          <Header
            headerRef={headerRef}
            backgroundColor="plum"
            animation="opacity"
            leftSlot={<Header.BackButton color="mainText2" onClick={onClose} />}>
            <Header.Title title={headerTitle} align="left" color="mainText2" />
          </Header>
        </Modal.Header>
        <HeadlineWrapper>
          <Headline train={train} />
        </HeadlineWrapper>
        <Content isMobile={isMobile}>
          <InfoCard
            travelerName={travelerName}
            transactionId={transactionId}
            collectionRefNumber={collectionRefNumber}
            isCollect={isCollect}
          />
          <Block marginTop={24} marginBottom={46}>
            <TicketDelivery
              deliveryOptionCode={train.selectedDeliveryOption?.code}
              collectionReferences={train.collectionReferences}
              email={train.traveler?.email}
            />
          </Block>
          {hasPermittedStations && (
            <Block marginTop={24} marginBottom={46}>
              <RailDetailsPermittedStations
                originStation={originStation}
                destinationStation={destinationStation}
                permittedOriginStations={permittedOriginStations}
                permittedDestinationStations={permittedDestinationStations}
                ticketRestrictions={ticketRestrictions}
              />
            </Block>
          )}
          <Block>
            {segments?.map((segment, index) => (
              <TrainBookingOverlay>
                <TrainBookingInfo
                  key={segment.id}
                  segment={segment}
                  platform={train.platform}
                  trainIndex={index}
                />
              </TrainBookingOverlay>
            ))}
          </Block>
          {railEmission && (
            <Block paddingBottom={24}>
              <RailDetailsEcoCheck {...railEmission} />
            </Block>
          )}
          <Price
            rate={train.rate}
            taxes={train.taxes}
            total={train.totalCost}
            transactionFees={train.transactionFees}
          />
        </Content>
      </Modal.Body>
    </Modal>
  )
}
