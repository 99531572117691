import type { CalculateEmissionsBatchResultEquivalence } from '@fiji/graphql/types'
import { CarRentalDetailsEcoCheckDesktopComponent } from './car-rental-details-eco-check-desktop-component'
import { CarRentalDetailsEcoCheckDesktopSkeleton } from './car-rental-details-eco-check-desktop-skeleton'

type Props = {
  loading: boolean
  tonnesOfEmissions?: number
  averageEmissionsTonnes?: number
  equivalences?: CalculateEmissionsBatchResultEquivalence[]
}

export function CarRentalDetailsEcoCheckDesktop({
  loading,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: Props) {
  if (loading) {
    return <CarRentalDetailsEcoCheckDesktopSkeleton />
  }

  if (!tonnesOfEmissions || !averageEmissionsTonnes) {
    return null
  }

  return (
    <CarRentalDetailsEcoCheckDesktopComponent
      equivalences={equivalences || []}
      tonnesOfEmissions={tonnesOfEmissions}
      averageEmissionsTonnes={averageEmissionsTonnes}
    />
  )
}
