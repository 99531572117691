import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style'
import { Button } from '@etta/ui/button'

export const Field = styled.div`
  ${footnoteMedium};
  height: 44px;
  background: ${(p) => p.theme.colors.background};
  color: ${(p) => p.theme.colors.mainText};
  line-height: 1.9rem;
  border-radius: 8px;
  padding-left: 42px;
  border: none;
  width: 100%;
`

export const Section = styled.div<{ withBorder?: boolean }>`
  padding: 24px 20px;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
    `}
`

export const Container = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClearButton = styled(Button)`
  margin-right: 16px;
  padding-left: 0;
`

export const ApplyButton = styled(Button)`
  min-width: 22rem;
`

export const FilterSelectionRow = styled.div<{
  withSpace: boolean
  withBorder: boolean
  withExtraSpace?: boolean
}>`
  padding: ${(props) => (props.withSpace ? `${props.withExtraSpace ? '20px' : '10px'} 0` : '0')};

  ${(props) =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.borderLight};
    `}
`
