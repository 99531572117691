import type { Place, TimeRange } from '@fiji/types'
import { TimeTabLayout } from './time-tab-layout'
import { useTimeTab } from './use-time-tab'

type Props = {
  place?: Place | null
  actionLabel: string
  selectedTime: TimeRange
  setPickedTime: (time: TimeRange) => void
  onSubmit: (time: TimeRange) => void
  date?: Date | null
  initialCustomTime: TimeRange
  timeOptions: TimeRange[]
}

export function TimeTab({
  actionLabel,
  onSubmit,
  selectedTime,
  setPickedTime,
  date,
  place,
  initialCustomTime,
  timeOptions,
}: Props) {
  const {
    customTimeValue,
    title,
    convertTimeLabel,
    onCustomTimeChange,
    onRadioButtonClick,
  } = useTimeTab({
    place,
    selectedTime,
    setPickedTime,
    date,
    initialCustomTime,
  })

  return (
    <TimeTabLayout
      actionLabel={actionLabel}
      selectedTime={selectedTime}
      onSubmit={onSubmit}
      convertTimeLabel={convertTimeLabel}
      customTimeValue={customTimeValue}
      onCustomTimeChange={onCustomTimeChange}
      onRadioButtonClick={onRadioButtonClick}
      timeOptions={timeOptions}
      title={title}
    />
  )
}
