import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { LocationPicker as EttaLocationPicker } from '@etta/components/location-picker'
import { Search } from '@etta/ui/search'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import type { LocationPickerProps } from '../../../types'

const i18Base = 'TripPlanner.BaseSearch.Input'

export const LocationPicker = observer(function LocationPicker({
  location,
  placesHistory,
  handleLocationChange,
}: LocationPickerProps) {
  const { t } = useTranslation()
  const locationLabel = t(i18Base + '.Location')
  const value = location?.airportCode || location?.name
  const { hotelSearchFormStore } = useHotelSearchFormContext()

  const wrapperAriaLabel = value
    ? t(i18Base + '.PlaceSelectedAriaLabel', {
        label: locationLabel,
        value,
      })
    : t(i18Base + '.PlaceAriaLabel', { label: locationLabel })

  return (
    <Search.Group>
      <EttaLocationPicker
        recentSearchesType="hotel"
        onChange={handleLocationChange}
        value={location}
        latestPlaces={placesHistory}
        searchType="hotel"
        label={locationLabel}
        isOutboundTrip
        hasCurrentLocationSearch
        data-tracking-id="hotel-location-search"
        inputSlot={
          <Search.Element
            wrapperAriaLabel={wrapperAriaLabel}
            labelTitle={locationLabel}
            value={value}
            shouldTruncateValueAt={45}
            errorMessage={hotelSearchFormStore.validationErrors.location}
          />
        }
      />
    </Search.Group>
  )
})
