import type { RoutePlace } from '@etta/modules/etta-map/core/route'
import type { Place } from '@fiji/types'

export function mapToDirectionSearch(place: Place, address?: string): RoutePlace {
  if (place.latitude && place.longitude) {
    return {
      lat: place.latitude,
      lng: place.longitude,
    }
  }

  if (place.placeId && place.name) {
    return { placeId: place.placeId, name: place.name }
  }

  if (address) {
    return address
  }

  return place.name
}
