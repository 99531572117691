import styled, { css } from 'styled-components'
import { footnoteMedium } from '@fiji/style/typography'

export const Container = styled.div<{ isMobile?: boolean }>`
  flex-grow: 1;
  margin-bottom: ${(p) => (p.isMobile ? '12px' : '7px')};
  ${(p) =>
    p.isMobile &&
    css`
      padding-bottom: 12px;
      border-bottom: 1px solid ${p.theme.colors.borderLight};
    `}
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ReasonItem = styled.div`
  position: relative;
  margin-top: 6px;
  padding-left: 22px;

  &:before {
    position: absolute;
    content: '';
    top: 7px;
    left: 12px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.bodyText};
  }

  &:first-child {
    margin-top: 7px;
  }
`

export const IconRow = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => (p.isMobile ? '7px' : '2px')};
`

export const OutOfPolicyText = styled.span`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  line-height: 20px;
`
