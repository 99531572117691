import ContentLoader from 'react-content-loader'
import styled, { css } from 'styled-components'
import { getAlign } from './get-align'
import type { SkeletonAlign, SkeletonSkin } from './types'

export const ContentLoaderWrapper = styled(ContentLoader)`
  display: flex;
`

type SkinProps = {
  skin?: SkeletonSkin
  align?: SkeletonAlign
  height: number | string
  width: number | string
}

export const Skin = styled.div<SkinProps>`
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  ${(p) => getAlign(p.align)};
  ${(p) => {
    switch (p.skin) {
      case 'bg-white-round-14':
        return css`
          background-color: ${p.theme.colors.white};
          border-radius: 14px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
          overflow: hidden;
        `
      default:
      case 'default':
        return ''
    }
  }}
`
