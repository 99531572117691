import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { SearchButtonMobile } from './mobile'
import { SearchButtonDesktop } from './desktop'

export function SearchButonSectionLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<SearchButtonMobile {...props} />}
      desktopSlot={<SearchButtonDesktop {...props} />}
    />
  )
}
