import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { setLinkTagsForContent } from '@fiji/utils/set-link-tags-for-content'
import type { LayoutProps } from '../../types'
import { Container, AirlineLogo, StyledText, Title } from './fare-rules-desktop-styled'

export function FareRulesDesktop({
  flightFareRules,
  airlineLogo,
  airlineName,
  segmentsBasis,
  bookingCodes,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'FareRules.'

  return (
    <Container>
      {airlineLogo && (
        <Block>
          <AirlineLogo src={airlineLogo} alt={airlineName} title={airlineName} />
        </Block>
      )}

      {segmentsBasis && (
        <Text variant="footnoteStrong">{`${t(i18nBase + 'Basis')} ${segmentsBasis}`}</Text>
      )}
      {bookingCodes && (
        <Text variant="footnoteStrong">{`${t(i18nBase + 'BookingCode')} ${bookingCodes}`}</Text>
      )}

      {flightFareRules.map((fare, index) => (
        <Block marginTop={24} key={index}>
          {fare.header && <Title aria-label={fare.header}>{fare.header}</Title>}
          <StyledText
            aria-label={fare.value}
            dangerouslySetInnerHTML={{
              __html: setLinkTagsForContent(fare.value),
            }}
          />
        </Block>
      ))}
    </Container>
  )
}
