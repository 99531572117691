import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { useSegmentDetailsEcoCheckModal } from '@fiji/hooks/sustainability/use-segment-details-eco-check-modal'
import { EcoCheckEquivalences } from '@etta/components/eco-check-equivalences/eco-check-equivalences'
import { Icon } from '@etta/ui/icon'
import { EmissionsDeviationLevel } from '@fiji/enums'
import { KgOfCo2 } from '@etta/components/kg-of-co2'
import type { LayoutProps } from '../../types'
import {
  FlightDetailsEcoCheckModalImage,
  FlightDetailsEcoCheckModalDesktopLowerBodyNew,
  FlightDetailsEcoCheckModalDesktopUpperBodyNew,
  FlightDetailsEcoCheckHeaderNew,
} from './components'
import {
  EquivalencesContainer,
  EquivalentContainer,
  EquivalentEmission,
  EquivalentEmissionValue,
} from './flight-details-eco-check-modal-desktop-new-styled'

export function FlightDetailsEcoCheckModalDesktopNew({
  isVisible,
  onClose,
  tonnesOfEmissions,
  averageEmissionsTonnes,
  equivalences,
}: LayoutProps) {
  const { t } = useTranslation()

  const {
    kgOfEmissions,
    averageEmissionsKg,
    deviationLevel,
    percentDeviation,
  } = useSegmentDetailsEcoCheckModal({
    tonnesOfEmissions,
    averageEmissionsTonnes,
  })

  const emissionsCloudIcon = useMemo(() => {
    switch (deviationLevel) {
      case EmissionsDeviationLevel.Average:
      case EmissionsDeviationLevel.More:
        return 'AverageEmissionsCloudsPWA'
      case EmissionsDeviationLevel.Less:
        return 'lowerEmissionsCloudsPWA'
    }
  }, [deviationLevel])

  return (
    <Modal
      position="right"
      horizontalDimension="content-760"
      isVisible={isVisible}
      handleModalVisibility={onClose}>
      <Modal.Header withBorder isMobile>
        <Header
          animation="opacity"
          backgroundColor="white"
          leftSlot={<FlightDetailsEcoCheckHeaderNew onClose={onClose} />}></Header>
      </Modal.Header>
      <Modal.Body>
        <FlightDetailsEcoCheckModalDesktopUpperBodyNew
          averageEmissionsKg={averageEmissionsKg}
          percentDeviation={percentDeviation}
          deviationLevel={deviationLevel}
          kgOfEmissions={kgOfEmissions}
        />

        <FlightDetailsEcoCheckModalImage deviationLevel={deviationLevel} />
        <EquivalentContainer>
          <EquivalentEmission>
            <Icon name={emissionsCloudIcon} />
            <EquivalentEmissionValue>
              <KgOfCo2 kgOfEmissions={kgOfEmissions} />
              <span> {t('Emissions.DetailsModal.KgEquivalent')}</span>
            </EquivalentEmissionValue>
          </EquivalentEmission>
        </EquivalentContainer>

        <EquivalencesContainer>
          <EcoCheckEquivalences equivalences={equivalences} kgOfEmissions={kgOfEmissions} />
        </EquivalencesContainer>
        <FlightDetailsEcoCheckModalDesktopLowerBodyNew deviationLevel={deviationLevel} />
      </Modal.Body>
    </Modal>
  )
}
