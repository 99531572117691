import type { HotelMarker, MapDataRadius } from '@fiji/types'
import type { Geocode } from '@fiji/graphql/types'
import { MapProvider } from '@fiji/graphql/types'
import { MAP_DISTANCE_CHANGE_COEFFICIENT } from '@fiji/constants'

export function roundNum(code: any) {
  // eslint-disable-next-line security/detect-unsafe-regex
  return Number(code.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0])
}

export function getDistance(point1: Geocode, point2: Geocode, unit: string = 'mi') {
  if (point1.lat === point2.lat && point1.long === point2.long) {
    return 0
  }
  const radlat1 = (Math.PI * point1.lat) / 180
  const radlat2 = (Math.PI * point2.lat) / 180
  const theta = point1.long - point2.long
  const radtheta = (Math.PI * theta) / 180
  let distance =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (distance > 1) {
    distance = 1
  }
  distance = ((Math.acos(distance) * 180) / Math.PI) * 60 * 1.1515
  if (unit === 'km') {
    distance = distance * 1.609344
  }
  if (unit === 'nm') {
    distance = distance * 0.8684
  }
  return roundNum(distance)
}

export function getSerializedMarkerIds(markers: HotelMarker[]) {
  if (markers && markers.length) {
    return markers
      .sort((a: any, b: any) => {
        if (a.hotelId < b.hotelId) {
          return -1
        }
        if (a.hotelId > b.hotelId) {
          return 1
        }
        return 0
      })
      .map((marker) => marker.hotelId)
      .join('_MARKER_')
  }
  return null
}

export function serializeLatLng(location: Geocode) {
  return location.lat + '__' + location.long
}

type CalculateRadiusArgs = {
  centerLat: number
  centerLng: number
  NorthEastLat: number
  NorthEastLng: number
  unit?: string
}

function calculateRadius({
  centerLat,
  centerLng,
  NorthEastLat,
  NorthEastLng,
  unit,
}: CalculateRadiusArgs) {
  let earthRadius = 3963
  if (unit === 'km') {
    earthRadius = 6378
  } else if (unit === 'nm') {
    earthRadius = 3441
  }
  return +(
    earthRadius *
    Math.acos(
      Math.sin(centerLat) * Math.sin(NorthEastLat) +
        Math.cos(centerLat) * Math.cos(NorthEastLat) * Math.cos(NorthEastLng - centerLng),
    )
  ).toFixed(5)
}

export function getMapRegionRadius(mapData: any, mapProvider: MapProvider, unit?: string) {
  if (mapProvider === MapProvider.AppleMapkit) {
    const { latitude: centerLat, longitude: centerLng } = mapData.map.center
    const {
      northLatitude: NorthEastLat,
      eastLongitude: NorthEastLng,
    } = mapData.map.region.toBoundingRegion()

    return calculateRadius({
      centerLat,
      centerLng,
      NorthEastLat,
      NorthEastLng,
      unit,
    })
  }
  const borders = mapData.map.getBounds()
  const center = borders.getCenter()
  const northEast = borders.getNorthEast()

  const centerLat = center.lat() / 57.2958
  const centerLng = center.lng() / 57.2958
  const NorthEastLat = northEast.lat() / 57.2958
  const NorthEastLng = northEast.lng() / 57.2958

  return calculateRadius({
    centerLat,
    centerLng,
    NorthEastLat,
    NorthEastLng,
    unit,
  })
}

export function calculateAppleMapRadius({ latitudeDelta, longitudeDelta, unit }: MapDataRadius) {
  // different size of one degree for Miles (69) and Kilometers (111)
  const distanceInOneDegree = unit && unit === 'mi' ? 69 : 69
  return Math.abs(roundNum((latitudeDelta - longitudeDelta) * distanceInOneDegree))
}

export function roundMapCoordinate(value: number): number {
  return +value.toFixed(7)
}

export function convertGoogleMapRadiusToZoom(radius: number): number {
  return Math.round(13.5 - Math.log(radius) / Math.LN2) + 1
}

export function calculateAppleMapDistanceChange(baseLatitude: number, currentLatitude: number) {
  const distance = Math.abs(baseLatitude - currentLatitude)
  return distance > MAP_DISTANCE_CHANGE_COEFFICIENT
}
