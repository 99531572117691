import { Header } from '@etta/ui/header'
import type { ModalTitleLayoutProps } from './types'

export function ModalTitleMobile({
  onClose,
  children,
  color,
  backgroundColor,
  'aria-label': ariaLabel,
  closeButtonPosition = 'left',
}: ModalTitleLayoutProps) {
  if (closeButtonPosition === 'right') {
    return (
      <Header
        backgroundColor={backgroundColor}
        leftSlot={
          <Header.Title color={color} align="center" title={children} aria-label={ariaLabel} />
        }>
        <div style={{ marginLeft: 'auto' }}>
          <Header.CloseButton color={color} onClick={onClose} />
        </div>
      </Header>
    )
  }

  return (
    <Header
      backgroundColor={backgroundColor}
      layout="search-input"
      leftSlot={<Header.CloseButton color={color} onClick={onClose} />}>
      <Header.Title color={color} align="left" title={children} aria-label={ariaLabel} />
    </Header>
  )
}
