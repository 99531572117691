import { Modal } from '@etta/ui/modal'
import { ScreenType, useScreenType } from '@fiji/modes'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { HotelFiltersContent } from './hotel-filters-content'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export function HotelFilters({ isOpen, onClose }: Props) {
  const { featureFlagsStore } = useFeatureFlagsContext()
  const screenType = useScreenType()
  const { isNewHotelFiltersModalEnabled } = featureFlagsStore.flags
  const modalPosition =
    isNewHotelFiltersModalEnabled && screenType !== ScreenType.Mobile ? 'right' : 'center'

  return (
    <Modal
      position={modalPosition}
      horizontalDimension={isNewHotelFiltersModalEnabled ? 'content-760' : 'regular'}
      isVisible={isOpen}
      handleModalVisibility={onClose}
      withShadow={true}>
      <HotelFiltersContent />
    </Modal>
  )
}
