import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useUnusedTicketRate } from '@fiji/hooks/use-unused-ticket-rate'
import type { BaggageInfoSegment } from '@fiji/types'
import type { TripCostSummary, FlightLegSubSegment } from '@fiji/graphql/types'
import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { SegmentType } from '@fiji/graphql/types'
import { useResultAdaptiveFlight } from '@fiji/hooks/use-adaptive-flight'
import { getSegmentPrice } from '@etta/screens/review-trip-page/trip-cost-summary/use-trip-cost-summary/get-segment-price'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'
import { useDeleteFlight } from '../../hooks/use-delete-flight'
import { useChangeFlight } from './use-change-flight'

type Args = {
  flight: FlightSegmentEntity
  tripCost: TripCostSummary
  itineraryId: string
  flightId: string
  flightSegments: FlightSegmentEntity[]
  onClose: () => void
  flightIndex: number
}

export function useFlightDetailsModal({
  itineraryId,
  flight,
  flightId,
  flightSegments,
  tripCost,
  onClose,
  flightIndex,
}: Args) {
  const { airSearchStore } = useAirSearchContext()
  const { searchId } = airSearchStore

  const seatMapToggle = useTogglePopup()
  const baggageModalToggle = useTogglePopup()
  const changeModalToggle = useTogglePopup()
  const fareRulesModalToggle = useTogglePopup()
  const removeDialogToggle = useTogglePopup()

  function handleSeatMapOpen() {
    seatMapToggle.handleOpen()
  }

  const adaptiveFlightData = useResultAdaptiveFlight(flight)
  const unusedTicketRate = useUnusedTicketRate({ unusedTicket: tripCost.unusedTicket })

  const _flightCost = getSegmentPrice({
    payNow: tripCost.payNow?.flight,
    segmentType: SegmentType.Flight,
  })

  const flightCost = _flightCost?.flightCost

  const flightCostSegment = flightCost?.[flightIndex] || flightCost?.[0]
  const { handleRemoveFlight, viewStateParams } = useDeleteFlight({
    flightId,
    onCloseAfterward: onClose,
  })
  const { handleChangeFlights } = useChangeFlight({
    itineraryId,
    handleCloseChangeModal: changeModalToggle.handleClose,
    flightSegments,
    flightId,
    searchId,
  })

  const baggageInfo: BaggageInfoSegment[] = flight.segments.reduce(
    (acc: BaggageInfoSegment[], segment: FlightLegSubSegment) => {
      const mappedSegment = {
        imageUrl: segment.images.rectangle,
        baggageFees: segment.baggageFees,
        name: segment.name,
      }

      if (!acc.length) {
        return [mappedSegment]
      }
      if (acc.some((infoItem: BaggageInfoSegment) => infoItem.name !== segment.name)) {
        return [...acc, mappedSegment]
      }
      return acc
    },
    [],
  )

  const bookingCodes = flight.segments.map((segment) => segment.bookingCode || '')
  const fares = flight.segments.map((segment) => segment.fare || '')

  return {
    adaptiveFlightData,
    baggageModalToggle,
    changeModalToggle,
    fareRulesModalToggle,
    removeDialogToggle,
    seatMapToggle,
    handleChangeFlights,
    flightCostSegment,
    handleRemoveFlight,
    viewStateParams,
    baggageInfo,
    bookingCodes,
    fares,
    unusedTicketRate,
    handleSeatMapOpen,
  }
}
