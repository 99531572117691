import { observer } from 'mobx-react-lite'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useSuccessDialog } from './use-success-dialog'

export const SuccessDialog = observer(function SuccessDialog() {
  const { postBookingCancellationActions, postBookingCancellationStore } = usePostBookingContext()
  const { isSuccess, isRideHailError, cancellationTypeTitle } = postBookingCancellationStore
  const { title, description, button } = useSuccessDialog({
    type: cancellationTypeTitle,
    isCancellationTripWithRideHailError: isRideHailError,
  })

  return (
    <ViewStateModal
      viewState={isSuccess ? 'custom' : 'hidden'}
      title={title}
      onDone={postBookingCancellationActions.onCancellationDone}>
      <>
        <Text variant="title3" color="mainText">
          {title}
        </Text>
        <Block marginTop={24}>
          <Text variant="footnoteMedium" color="bodyText" align="center">
            {description}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          <Button
            variant="solid"
            onClick={postBookingCancellationActions.onCancellationDone}
            fullWidth>
            {button}
          </Button>
        </Block>
      </>
    </ViewStateModal>
  )
})
