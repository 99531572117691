import type { AddressValueObject } from '@etta/core/value-objects'
import type { Place } from '@fiji/types'

export function resolveHotelLocation(address?: AddressValueObject | null): Place | null {
  if (!address) {
    return null
  }

  return {
    placeId: '',
    name: [address.city, address.stateCode ?? address.countryCode].filter(Boolean).join(', '),
    latitude: address.geocode?.lat,
    longitude: address.geocode?.long,
  }
}
