import { PriceDetails } from '@etta/components/price-details'
import type { CarRentalDetailsPrice } from '@fiji/utils/get-car-rental-details-price'
import { CarRentalPriceDetailsSkeleton } from './car-rental-price-details-skeleton'

type Props = {
  priceDetailsList: CarRentalDetailsPrice[]
  estTotalCost: string
  isLoading?: boolean
}

export function CarRentalPriceDetails({ priceDetailsList, estTotalCost, isLoading }: Props) {
  if (isLoading) {
    return <CarRentalPriceDetailsSkeleton />
  }

  return <PriceDetails priceDetailsList={priceDetailsList} totalCost={estTotalCost} />
}
