import { useLocation } from 'react-router-dom'
import type * as H from 'history'
import { useCallback } from 'react'
import {
  tripReviewItineraryIdSelector,
  tripReviewStatusSelector,
  tripReviewBookingIdSelector,
} from '@fiji/store/trip-review-params'
import { useAppSelector } from '@fiji/store'
import {
  updateMainNavigationExplore,
  mainNavigationExploreSelector,
  MAINNAVIGATIONVALUE,
} from '@fiji/store/main-navigation'
import { ROUTES } from '@fiji/routes'
import { postPurchaseEntranceSelector } from '@fiji/store/post-purchase-entrance'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { useGlobalExploreRouteFlowTracker } from './use-global-explore-route-flow-tracker'
import { useGlobalLatestRoute } from './use-global-latest-route'

export function useGlobalExploreRoute() {
  const { pathname } = useLocation()
  const { usedPath } = useGlobalLatestRoute({
    detectPath: MAINNAVIGATIONVALUE.EXPLORE,
    selector: mainNavigationExploreSelector,
    action: updateMainNavigationExplore,
  })
  useGlobalExploreRouteFlowTracker({ exploreLink: usedPath })

  const tripReviewStatus = useAppSelector(tripReviewStatusSelector)
  const tripReviewItineraryId = useAppSelector(tripReviewItineraryIdSelector)
  const tripReviewBookingId = useAppSelector(tripReviewBookingIdSelector)
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const postPurchaseNavigation = useAppSelector(postPurchaseEntranceSelector)

  const tripReviewLink = ROUTES.reviewTrip.main({
    itineraryId: tripReviewItineraryId,
    bookingId: tripReviewBookingId,
  })

  const isExploreActiveHandle = useCallback(
    (_: unknown, location: H.Location) => {
      const isTripMatch = (location.pathname + location.search).indexOf(tripReviewLink) === 0
      const isExploreMatch = location.pathname.indexOf(MAINNAVIGATIONVALUE.EXPLORE) === 0

      return isExploreMatch || isTripMatch
    },
    [tripReviewLink],
  )

  if (tripReviewStatus === 'none' || !tripReviewItineraryId) {
    return {
      exploreLink: postPurchaseNavigation ? MAINNAVIGATIONVALUE.EXPLORE : usedPath,
      isExploreActiveHandle,
    }
  }

  const isInTripReview = pathname.indexOf(tripReviewLink) === 0
  if (isInTripReview) {
    return {
      exploreLink: MAINNAVIGATIONVALUE.EXPLORE,
      isExploreActiveHandle,
    }
  }

  return {
    exploreLink: isMobile ? tripReviewLink : MAINNAVIGATIONVALUE.EXPLORE,
    isExploreActiveHandle,
  }
}
