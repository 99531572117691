import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Amenities } from '../../../amenities'
import { Image, DetailsWrapper } from './trip-car-rental-desktop-styled'

type Props = {
  carRental: CarRentalPostBookingValueObject
  carRentalTitle: string
  carImageUrl: string
}

export function Details({ carRental, carImageUrl, carRentalTitle }: Props) {
  return (
    <DetailsWrapper>
      <Amenities carRental={carRental} />
      <Image src={carImageUrl} alt={carRentalTitle} />
    </DetailsWrapper>
  )
}
