import { useTranslation } from 'react-i18next'
import type { Maybe, SegmentState } from '@fiji/graphql/types'
import type { PreferencesType } from '../types'
import { getIconByType } from './get-icon-by-type'
import { getTitleByType } from './get-titlte-by-type'
import { HeaderLayout } from './layout'

type Props = {
  type: PreferencesType
  isEmpty: boolean
  onEdit: () => void
  segmentState?: Maybe<SegmentState>
  areAllPreferencesUnavailable: boolean
}

export function Header({
  type,
  onEdit,
  isEmpty,
  segmentState,
  areAllPreferencesUnavailable,
}: Props) {
  const i18nBase = 'TravelerInfo.'
  const { t } = useTranslation()

  const link = t(`${i18nBase}${isEmpty ? 'Add' : 'Edit'}`)
  const title = getTitleByType(type)
  const iconName = getIconByType(type)

  return (
    <HeaderLayout
      segmentState={segmentState}
      iconName={iconName}
      title={title}
      onEdit={onEdit}
      areAllPreferencesUnavailable={areAllPreferencesUnavailable}
      link={link}
    />
  )
}
