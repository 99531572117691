import { useCarSafetyCheck } from './use-car-safety-check'
import { Container } from './container'
import { Content } from './content'
import type { CarSafetyCheckProps } from './types'

export function CarSafetyCheck({
  type,
  readMoreLink,
  isVisible = false,
  onClose = () => {},
  vendorCode,
  viewType,
  vendorName,
}: CarSafetyCheckProps) {
  const { title, mainText, logo, isExtendedType, shortMainText } = useCarSafetyCheck({
    vendorName,
    vendorCode,
    type,
  })
  const vendorLogo = isExtendedType ? logo : undefined

  return (
    <Container viewType={viewType} onClose={onClose} isVisible={isVisible}>
      <Content
        viewType={viewType}
        mainText={mainText}
        title={title}
        logo={vendorLogo}
        readMoreLink={readMoreLink}
        shortMainText={shortMainText}
      />
    </Container>
  )
}
