import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { InputButtonGroupDirection } from './types'
import { Root, Container, ToggleIconButton, ErrorMessage } from './input-button-group-components'

type Props = {
  children: ReactNode
  contentMargin?: number
  'aria-label'?: string
  direction?: InputButtonGroupDirection
  isToggle?: boolean
  onToggle?: () => void
  errorMessage?: string
  hasError?: boolean
}

export function InputButtonGroup({
  direction = InputButtonGroupDirection.Horizontal,
  contentMargin = 0,
  'aria-label': ariaLabel,
  children,
  isToggle,
  onToggle,
  hasError = false,
  errorMessage,
}: Props) {
  const [isAnimated, toggleAnimate] = useState(false)
  const [isToggled, setIsToggled] = useState(false)
  const { t } = useTranslation()

  return (
    <Root role="group" aria-label={ariaLabel}>
      <Container direction={direction} contentMargin={contentMargin} hasError={hasError}>
        {children}
      </Container>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {isToggle && (
        <ToggleIconButton
          role="switch"
          aria-checked={!!isToggled}
          aria-label={t('Accessibility.InputButtonGroup.Toggle')}
          isAnimated={isAnimated}
          direction={direction}
          onAnimationEnd={() => {
            toggleAnimate(false)
          }}
          onClick={() => {
            toggleAnimate(true)
            onToggle && onToggle()
            setIsToggled((prev) => !prev)
          }}>
          <Icon name="switchPWA" />
        </ToggleIconButton>
      )}
    </Root>
  )
}
