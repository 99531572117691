import type { CarServiceTripCost } from '@fiji/graphql/types'
import { PriceDetails } from '@etta/components/price-details'
import { usePrice } from './use-price'

type Props = {
  price: CarServiceTripCost
}

export function Price({ price }: Props) {
  const { priceDetailsList, formattedTotalCost } = usePrice({ price })
  return (
    <PriceDetails
      priceDetailsList={priceDetailsList}
      totalCost={formattedTotalCost}
      isCarServicePriceDetails
    />
  )
}
