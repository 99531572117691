import type { AppState, RootSelector } from '@fiji/store'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { toSnakeCase } from '@fiji/utils/text'
import type { TripReviewParams, ItinerarySessionIds } from './types'

export const tripReviewParamsSelector: RootSelector<TripReviewParams> = ({
  tripReviewParams: { tripReviewParams },
}) => ({
  ...tripReviewParams,
  hotelKey: toSnakeCase(tripReviewParams.hotelKey || ''),
  checkInDate: tripReviewFormatDate(TripReviewDateType.DateSlash, tripReviewParams.checkInDate),
  checkOutDate: tripReviewFormatDate(TripReviewDateType.DateSlash, tripReviewParams.checkOutDate),
})

export const itinerarySessionIdsSelector: RootSelector<ItinerarySessionIds> = ({
  tripReviewParams,
}) => tripReviewParams.itinerarySessionIds

export function tripReviewStatusSelector(state: AppState) {
  return state.tripReviewParams.tripReviewStatus
}

export function tripReviewItineraryIdSelector(state: AppState) {
  return state.tripReviewParams.tripReviewItineraryId
}

export function tripReviewBookingIdSelector(state: AppState) {
  return state.tripReviewParams.tripReviewBookingId
}

export function tripReviewTransactionGroupIdSelector(state: AppState) {
  return state.tripReviewParams.tripReviewTransactionGroupId
}
