import { clone } from 'ramda'

import type { ZooKeeperConfiguration } from './types'

export const initialZooKeeperConfiguration: ZooKeeperConfiguration = {
  policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled: false,
  southwestCheckInEmailNotificationEnabled: false,
  southwestCheckInSmsNotificationEnabled: false,
  removeNotificationSectionOnTheCheckoutPageEnabled: false,
  nonStopFilterByDefaultEnabled: false,
  blockBasicEconomyEnabled: false,
  remoteProfileMessage: '',
  remoteProfileEnabled: false,
  removeHotelStarRatingEnabled: false,
  disableTsaHazmatMessageEnabled: false,
  tieredCostAllocationEnabled: false,
  removePerDiemLinkEnabled: null,
  zooKeeperConfigLoading: false,
}

export const getInitialState = (): ZooKeeperConfiguration => clone(initialZooKeeperConfiguration)
