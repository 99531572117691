import { Action, Inject } from '@etta/di'
import { UserStore } from '@etta/modules/user'
import type { PasswordInput } from '../stores/password.store'
import { PasswordStore } from '../stores/password.store'
import { PasswordService } from '../services/password.service'

@Action()
export class PasswordActions {
  constructor(
    @Inject()
    private readonly passwordStore: PasswordStore,
    @Inject()
    private readonly passwordService: PasswordService,
    @Inject()
    private readonly userStore: UserStore,
  ) {}

  handleModalOpen() {
    const { delegatedId } = this.userStore.userCommonData
    if (delegatedId) {
      return
    }
    this.passwordStore.setIsSaveButtonDisabled(true)
    this.passwordStore.toggle.handleOpen()
  }

  handleModalClose() {
    this.passwordStore.setIsSaveButtonDisabled(true)
    this.passwordStore.toggle.handleClose()
    this.passwordStore.passwordValidator.reset()
  }

  handleFieldChange<T extends keyof PasswordInput>(field: T) {
    return (value: PasswordInput[T]) => {
      this.passwordStore.setIsSaveButtonDisabled(false)
      return this.passwordStore.setPasswordValue(field, value)
    }
  }

  async handlePasswordSave() {
    const validatorCheck = await this.passwordStore.passwordValidator.submit()

    if (!validatorCheck.isValid) {
      return
    }

    await this.passwordService.changePassword({
      input: this.passwordStore.passwordValidator.values,
      handleClose: this.handleModalClose,
    })
  }
}
