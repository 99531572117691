import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import type { LayoutProps } from '../../types'
import {
  BookingConditionsTitle,
  Item,
  List,
  CloseButton,
} from './booking-conditions-desktop-styled'

export function BookingConditionsDesktop({
  isVisible,
  onClose,
  titleText,
  firstConditionText,
  secondConditionText,
  thirdConditionText,
  conditionsLabel,
}: LayoutProps) {
  return (
    <Modal isVisible={isVisible} handleModalVisibility={onClose} borderRadius={14}>
      <Header
        rightSlot={
          <CloseButton>
            <Header.CloseButton onClick={onClose} />
          </CloseButton>
        }
        withBorderBottom>
        <BookingConditionsTitle aria-label={titleText}>{titleText}</BookingConditionsTitle>
      </Header>
      <Modal.Body aria-label={conditionsLabel}>
        <List>
          <Item>{firstConditionText}</Item>
          <Item>{secondConditionText}</Item>
          <Item>{thirdConditionText}</Item>
        </List>
      </Modal.Body>
    </Modal>
  )
}
