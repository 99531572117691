import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CarRentalAmenities } from '../../../types'
import {
  AmenitiesContainer,
  AmenitiesList,
  AmenitiesItem,
  AmenitiesIcon,
} from './car-rental-details-header-desktop-styled'

type Props = {
  amenities: CarRentalAmenities[]
}

export function Amenities({ amenities }: Props) {
  return (
    <AmenitiesContainer>
      <AmenitiesList>
        {amenities.map(({ name, value }) => {
          return (
            <AmenitiesItem key={name} role="listitem" aria-label={value}>
              <AmenitiesIcon>
                <Icon name={name} color="bodyText" />
              </AmenitiesIcon>
              <Text textTransform="capitalize" variant="footnoteMedium" color="bodyText">
                {value}
              </Text>
            </AmenitiesItem>
          )
        })}
      </AmenitiesList>
    </AmenitiesContainer>
  )
}
