import type { IconNames } from '@etta/ui/icon'
import { Skeleton } from '@etta/ui/skeleton'
import { Icon } from '@etta/ui/icon'
import { IconContainer } from './safety-check-icons-styled'

type Props = {
  safetyCheckIcons: IconNames[]
  isLoading?: boolean
}

export function SafetyCheckIcons({ isLoading, safetyCheckIcons }: Props) {
  if (isLoading) {
    return (
      <Skeleton skin="default" width={73} height={14} variant="secondary1">
        <rect width={73} height={14} />
      </Skeleton>
    )
  }

  return (
    <>
      {safetyCheckIcons.map((icon, index) => (
        <IconContainer key={index}>
          <Icon name={icon} color="bodyText" />
        </IconContainer>
      ))}
    </>
  )
}
