import type { PropsWithChildren, ReactNode } from 'react'
import { useFijiAuth } from './use-fiji-auth'

export function FijiAuth({ children, loaderSlot }: PropsWithChildren<{ loaderSlot: ReactNode }>) {
  const { isLoading, isAuthorized } = useFijiAuth()

  if (isLoading) {
    return <>{loaderSlot}</>
  }
  if (!isAuthorized) {
    return <>{loaderSlot}</>
  }

  return <>{children}</>
}
