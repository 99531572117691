import { MediaLayout } from '@etta/ui/media-layout'
import { ChangeFlightModalMobile } from './mobile'
import type { LayoutProps } from './types'

export function ChangeFlightModalLayout(props: LayoutProps) {
  // TODO: Replace desktop layout
  return (
    <MediaLayout
      mobileSlot={<ChangeFlightModalMobile {...props} />}
      desktopSlot={<ChangeFlightModalMobile {...props} />}
    />
  )
}
