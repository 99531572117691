import type { ReactNode } from 'react'
import type { Props, SliderValues } from '../../types'
import { calculateMarkTitle } from '../../mark-title'
import { SliderMark, SliderTrack, useSlider } from '../shared'
import type { ThumbStyledProps, TrackStyledProps } from './slider-mobile-components'
import { Container, ReactSliderWrapper, ThumbStyled } from './slider-mobile-components'

export function SliderMobile<T extends SliderValues>({
  isDisabled = false,
  min = 0,
  max = 100,
  step = 1,
  minDistance,
  value,
  onChange,
  marks,
  markTitleType,
  isFilledValue = false,
  distanceUnit,
  maxDistance,
  withoutMarksAligment = true,
}: Props<T>) {
  const isValueArray = Array.isArray(value)
  const hasMarks = !!marks

  const getMarkTitle = (markValue: number): ReactNode =>
    calculateMarkTitle({
      markValue,
      markTitleType,
      min,
      max,
      distanceUnit,
      maxDistance,
    })

  const { handleSliderOnChange } = useSlider<T>({
    isDisabled,
    onChange,
  })

  return (
    <Container>
      <ReactSliderWrapper
        ariaLabel="slider"
        minDistance={minDistance}
        disabled={isDisabled}
        marks={marks}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleSliderOnChange}
        renderMark={(props: React.HTMLProps<HTMLSpanElement>) => (
          <SliderMark
            key={props.key}
            props={props}
            isDisabled={isDisabled}
            getMarkTitle={getMarkTitle}
            min={min}
            max={max}
            value={value}
            isFilledValue={isFilledValue}
            withoutMarksAligment={withoutMarksAligment}
          />
        )}
        renderTrack={(
          props: React.HTMLProps<HTMLDivElement>,
          state: { index: number; value: number | number[] },
        ) => (
          <SliderTrack
            {...(props as TrackStyledProps)}
            isDisabled={isDisabled}
            index={state.index}
            isFilledValue={isFilledValue}
            isValueArray={isValueArray}
            isMarksExists={hasMarks}
          />
        )}
        renderThumb={(props: React.HTMLProps<HTMLDivElement>) => (
          <ThumbStyled {...(props as ThumbStyledProps)} isDisabled={isDisabled} />
        )}
      />
    </Container>
  )
}
