import { useCallback } from 'react'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import type { CostAllocationSubmitValueObject } from '@etta/modules/booking'

type Props = {
  segmentId: number
  onChange(field: string): (value: string) => void
}

export function useCheckoutMultiLayerCostAllocation({ onChange, segmentId }: Props) {
  const { costAllocationActions, costAllocationStore } = useCostAllocationContext()

  const onCostAllocationSubmit = useCallback(
    (val: CostAllocationSubmitValueObject, displayName: string) => {
      costAllocationStore.setActiveCostAllocationDisplayName(segmentId, displayName)
      costAllocationStore.setSubmittedCostAllocation(segmentId, val)
      onChange(`${segmentId}_allocation`)?.(val.allocationId)
    },
    [segmentId, costAllocationStore, onChange],
  )

  const onSplitCostAllocationSubmit = useCallback(
    (val: CostAllocationSubmitValueObject, displayName: string) => {
      costAllocationActions.setActiveSplitCostAllocationDisplayName(segmentId, displayName)
      costAllocationStore.setSubmittedSplitCostAllocation(segmentId, val)
      onChange(`${segmentId}_splitCostAllocation`)?.(val.allocationId)
    },
    [segmentId, onChange, costAllocationStore, costAllocationActions],
  )

  return {
    costAllocationModal: costAllocationStore.getCostAllocationModalToggleById(segmentId),
    splitCostAllocationModal: costAllocationStore.getSplitCostAllocationModalToggleById(segmentId),
    activeCostAllocationDisplayName: costAllocationStore.getCostAllocationDisplayNameById(
      segmentId,
    ),
    activeSplitCostAllocationDisplayName: costAllocationStore.getSplitCostAllocationDisplayNameById(
      segmentId,
    ),
    onCostAllocationSubmit,
    onSplitCostAllocationSubmit,
  }
}
