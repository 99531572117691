import type { TextFieldHelperType, TextFieldSize } from '@etta/ui/text-field/types'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { Container } from './select-search-styled'
import type { SelectSearchOptionType } from './types'
import { useSelectSearch } from './use-select-search'
import { SelectSearchLayout } from './layout'
import { FieldView } from './field-view'
import type { FieldViewType } from './field-view/types'

type Props = {
  options: SelectSearchOptionType[]
  disabledOptions?: string[]
  onOptionSelect(value: SelectSearchOptionType['value']): void
  onChangeValue?(value: string): void
  onClear?(): void
  renderOption?: (option: SelectSearchOptionType, inputValue: string) => JSX.Element
  renderOptionMobile?: (option: SelectSearchOptionType, index: number) => JSX.Element
  label: string
  placeholder?: string
  value?: string
  defaultValue?: string
  isDisabled?: boolean
  isLoading?: boolean
  helperType?: TextFieldHelperType
  helperText?: string
  fieldType?: FieldViewType
  size?: TextFieldSize
  customValueToShow?: string
  optionLabelSource?: 'label' | 'value'
  ariaLabel?: string
  'data-tracking-id'?: string
  'data-tracking-field-id'?: string
  skipInputRequirement?: boolean
  updateExternalInputValue?: (value: string | null | undefined) => void
  enablePaginationForOptions?: boolean
}

export function SelectSearch({
  placeholder,
  options,
  disabledOptions,
  onOptionSelect,
  onChangeValue,
  onClear,
  renderOption,
  renderOptionMobile,
  value = '',
  defaultValue,
  isDisabled,
  isLoading,
  helperType,
  helperText,
  label,
  fieldType,
  size,
  optionLabelSource,
  ariaLabel,
  customValueToShow,
  'data-tracking-id': dataTrackingId,
  'data-tracking-field-id': dataTrackingFieldId,
  skipInputRequirement = false,
  updateExternalInputValue,
  enablePaginationForOptions,
}: Props) {
  const {
    handleOpen,
    modalToggle,
    isModalVisible,
    hasInputValue,
    filteredOptions,
    onSelect,
    onChangeInput,
    optionLabel,
    inputValue,
    hasNextPageInPagination,
    onFetchMoreInPagination,
  } = useSelectSearch({
    value,
    defaultValue,
    options,
    isDisabled,
    optionLabelSource,
    onOptionSelect,
    onChangeValue,
    updateExternalInputValue,
    customValueToShow,
    enablePaginationForOptions: enablePaginationForOptions,
  })
  const { handleKeyDown } = useA11yKeydown(handleOpen)

  return (
    <Container onKeyDown={handleKeyDown} tabIndex={0} aria-label={ariaLabel}>
      <FieldView
        onClick={handleOpen}
        fieldType={fieldType}
        size={size}
        label={label}
        value={optionLabel}
        isDisabled={isDisabled}
        helperText={helperText}
        helperType={helperType}
        onClear={onClear}
        placeholder={placeholder}
        data-tracking-id={dataTrackingFieldId}
      />

      <SelectSearchLayout
        size={size}
        placeholder={placeholder}
        inputValue={inputValue}
        filteredOptions={filteredOptions}
        disabledOptions={disabledOptions}
        isModalVisible={isModalVisible}
        isLoading={isLoading}
        hasInputValue={hasInputValue}
        onHideModal={modalToggle.handleClose}
        onSelect={onSelect}
        onClear={onClear}
        onChangeInput={onChangeInput}
        renderOption={renderOption}
        renderOptionMobile={renderOptionMobile}
        data-tracking-id={dataTrackingId}
        skipInputRequirement={skipInputRequirement}
        hasNextPageForOptions={hasNextPageInPagination}
        onFetchMoreForOptions={onFetchMoreInPagination}
      />
    </Container>
  )
}
