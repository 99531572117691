import { useTranslation } from 'react-i18next'
import { Dialog } from '@etta/ui/dialog'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { RatePrice } from '@etta/components/rate-price/rate-price'
import { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import type { Props } from '../../types'
import {
  Container,
  NewPriceContainer,
  PriceChangeContainer,
  PriceIcon,
} from './price-chage-dialog-desktop-styled'

export function PriceChangeDialogDesktop({
  isOpen,
  onClose,
  onChangeFlight,
  priceChangeInfo,
  currentPrice,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'ReviewTripPWA.PriceChange.'

  if (!priceChangeInfo) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Container>
        <Icon name="warningFilledPWA" size="large" color="warning" />
        <Block marginTop={4}>
          <Text variant="title3" color="mainText">
            {priceChangeInfo.type === PriceChangeTypeValueObject.Increase
              ? t(`${i18nBase}PriceIncreased`)
              : t(`${i18nBase}PriceDecreased`)}
          </Text>
        </Block>
        <Block marginTop={16}>
          <Text variant="footnoteMedium" color="bodyText">
            {priceChangeInfo.type === PriceChangeTypeValueObject.Increase
              ? t(`${i18nBase}PriceIncreasedDescription`)
              : t(`${i18nBase}PriceDecreasedDescription`)}
          </Text>
        </Block>
        {priceChangeInfo.rate?.primary && priceChangeInfo.type && (
          <Block marginTop={8}>
            <PriceChangeContainer>
              <RatePrice
                rate={{ primary: priceChangeInfo.rate.primary }}
                textVariant="subHeadStrong"
                textColor={
                  priceChangeInfo.type === PriceChangeTypeValueObject.Increase ? 'error' : 'success'
                }
                formatOptions={{ morpheme: 'none' }}
              />{' '}
              <PriceIcon
                withRotate={priceChangeInfo.type === PriceChangeTypeValueObject.Decrease}
                name="upArrowPWA"
                color={
                  priceChangeInfo.type === PriceChangeTypeValueObject.Increase ? 'error' : 'success'
                }
                size="small"
              />
            </PriceChangeContainer>
          </Block>
        )}

        {currentPrice && (
          <NewPriceContainer>
            <Text variant="subHeadStrong" color="mainText">
              {t(`${i18nBase}NewPrice`)}
            </Text>
            <RatePrice
              rate={currentPrice}
              textVariant="subHeadStrong"
              formatOptions={{ morpheme: 'prefix' }}
            />
          </NewPriceContainer>
        )}
        <Block marginBottom={12} isFullWidth>
          <Button variant="outline" fullWidth onClick={onChangeFlight}>
            {t(`${i18nBase}ChangeFlight`)}
          </Button>
        </Block>
        <Button fullWidth onClick={onClose}>
          {t(`${i18nBase}Continue`)}
        </Button>
        <Block />
      </Container>
    </Dialog>
  )
}
