import { useEffect, useState } from 'react'
import {
  useGetRailJourneyPreferencesLazyQuery,
  useUpdateRailJourneyPreferencesMutation,
} from '@fiji/graphql/hooks'
import { SeatReservationType, Operation } from '@fiji/graphql/types'
import type { DeliveryOptions, SeatPreferences } from '@fiji/graphql/types'
import { RailLayoutType, TrainDeliveryOptionCodeRTP } from '@fiji/enums'
import {
  INITIAL_PREFERENCES_EU,
  INITIAL_PREFERENCES_UK,
} from '@etta/screens/trip-review-seat-preferences/constants'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import type {
  Preferences,
  PreferencesEU,
  PreferencesUK,
} from '@etta/screens/trip-review-seat-preferences/types'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useViewState } from '@fiji/hooks/use-view-state'
import { useSelectedSeatsLabel } from './use-selected-seats-label'

type PreferenceOptionsItem = {
  legId: string
  seatPreferences: SeatPreferences
  seatReservationType: SeatReservationType
  deliveryOptions: DeliveryOptions[]
}

type Props = {
  itineraryId: string
  segments: TrainSegmentEntity[]
}

const initialOptionsItem: PreferenceOptionsItem = {
  legId: '',
  seatPreferences: {
    carriageTypes: [],
    seatDirections: [],
    seatFacilities: [],
    seatPositions: [],
  },
  seatReservationType: SeatReservationType.None,
  deliveryOptions: [],
}

export function useRailSeatPreferences({ itineraryId, segments }: Props) {
  const seatPreferencesToggle = useTogglePopup()

  const railLegIds = segments.map((segment) => segment?.legId || '')
  const legParts = railLegIds[0]?.split('__')
  const searchId = legParts?.[0] || ''
  const journeyId = legParts?.[1] || ''
  const fareId = legParts?.[2] || ''

  const [preferenceOptionsItems, setPreferenceOptionsItems] = useState<PreferenceOptionsItem[]>([
    initialOptionsItem,
  ])
  const [
    currentSelectedPreferenceOptions,
    setCurrentSelectedPreferenceOptions,
  ] = useState<SeatPreferences>(initialOptionsItem.seatPreferences)
  const [inwardSeatReservationType, setInwardSeatReservationType] = useState<SeatReservationType>(
    initialOptionsItem.seatReservationType,
  )
  const [outwardSeatReservationType, setOutwardSeatReservationType] = useState<SeatReservationType>(
    initialOptionsItem.seatReservationType,
  )

  const currentSeatReservationType = [outwardSeatReservationType]

  if (railLegIds.length > 1) {
    currentSeatReservationType.push(inwardSeatReservationType)
  }

  const [currentDeliveryOptions, setCurrentDeliveryOptions] = useState<DeliveryOptions[]>([])

  // FIXME: Should be in trainLeg segment!
  const temporaryCurrentLayout = RailLayoutType.UK || RailLayoutType.EU

  // FIXME: Should be in separate request to graphQL!
  const [appliedPreferencesUK, setAppliedPreferencesUK] = useState(INITIAL_PREFERENCES_UK)
  const [appliedPreferencesEU, setAppliedPreferencesEU] = useState(INITIAL_PREFERENCES_EU)

  const [isUpdatedSeatPreferences, setIsUpdatedSeatPreferences] = useState<boolean>(false)
  const [isSkipped, setIsSkipped] = useState<boolean>(false)

  const getInitialPreferences = (layout: RailLayoutType) => {
    if (layout === RailLayoutType.UK) {
      return appliedPreferencesUK
    }
    return appliedPreferencesEU
  }

  const isRailEticket = currentDeliveryOptions.find(
    (option) => option.name === TrainDeliveryOptionCodeRTP.Eticket,
  )

  const appliedPreferences = getInitialPreferences(temporaryCurrentLayout)

  const getNewPreferences = (newPreferences?: Partial<Preferences>) => {
    if (!newPreferences) {
      return
    }

    if (temporaryCurrentLayout === RailLayoutType.UK) {
      setAppliedPreferencesUK(newPreferences as PreferencesUK)
      return
    }
    setAppliedPreferencesEU(newPreferences as PreferencesEU)
  }

  const { currentSelectedSeatsLabel } = useSelectedSeatsLabel({
    appliedPreferencesUK,
    appliedPreferencesEU,
    preferenceOptions: currentSelectedPreferenceOptions || initialOptionsItem.seatPreferences,
    currentSeatReservationType,
    isUpdatedSeatPreferences,
  })

  const railViewStateParams = useViewState()
  const onChangeViewState = railViewStateParams.onChangeViewState

  const [
    getRailJourneyPreferences,
    { loading: isRailPreferencesLoading, error: railPreferencesError, called },
  ] = useGetRailJourneyPreferencesLazyQuery({
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.railJourneyPreferences?.seatPreferences) {
        setPreferenceOptionsItems([...preferenceOptionsItems, initialOptionsItem])
      }

      setCurrentSelectedPreferenceOptions(data.railJourneyPreferences.seatPreferences)
      setOutwardSeatReservationType(data.railJourneyPreferences.outwardSeatReservationType)
      setInwardSeatReservationType(data.railJourneyPreferences.inwardSeatReservationType)
      setCurrentDeliveryOptions(data.railJourneyPreferences.deliveryOptions)
    },
  })

  useEffect(() => {
    if (railLegIds.length > 0 && !called) {
      const legPartsInward = railLegIds[1]?.split('__')
      const journeyIdInward = legPartsInward?.[1] || ''
      const fareIdInward = legPartsInward?.[2] || ''

      getRailJourneyPreferences({
        variables: {
          input: {
            searchId,
            outwardJourney: {
              journeyId,
              fareId,
            },
            inwardJourney:
              journeyIdInward && fareIdInward
                ? {
                    journeyId: journeyIdInward,
                    fareId: fareIdInward,
                  }
                : undefined,
          },
        },
      })
    }
  }, [
    called,
    fareId,
    getRailJourneyPreferences,
    journeyId,
    railLegIds,
    railLegIds.length,
    searchId,
  ])

  const [updateRailJourneyPreferencesMutation] = useUpdateRailJourneyPreferencesMutation()

  const onPreferencesApply = async (newPreferences?: Partial<Preferences>) => {
    onChangeViewState('saving')
    try {
      railLegIds.forEach(async (legId, index) => {
        const legParts = legId?.split('__')
        const journeyId = legParts?.[1] || ''
        const seatRequested = isSeatRequested(index, currentSeatReservationType, newPreferences)
        const selectedPreferences = seatRequested ? newPreferences : INITIAL_PREFERENCES_UK

        const result = await updateRailJourneyPreferencesMutation({
          variables: {
            input: {
              itineraryId,
              journeyId,
              searchId,
              operation: Operation.Update,
              seatPreferences: {
                position: selectedPreferences?.seatPositions,
                direction: selectedPreferences?.seatDirections,
                carriageType: selectedPreferences?.carriageTypes,
                facilities: selectedPreferences?.seatFacilities,
              },
              allocateSeat: seatRequested,
            },
          },
        })

        if (!result?.data?.updateRailJourneyPreferences.success) {
          setIsUpdatedSeatPreferences(false)
          throw new Error(result.data?.updateRailJourneyPreferences.message)
        }
      })

      const isNotRequestedOptional =
        currentSeatReservationType.every((el) => el === SeatReservationType.None) &&
        !newPreferences?.isSeatRequestedOnDepart &&
        !newPreferences?.isSeatRequestedOnReturn

      setIsUpdatedSeatPreferences(true)
      getNewPreferences(isNotRequestedOptional ? INITIAL_PREFERENCES_UK : newPreferences)
      onChangeViewState('saved')
      await delayForSuccess()
      onChangeViewState('hidden')
    } catch (error) {
      setIsUpdatedSeatPreferences(false)
      onChangeViewState('error')
    }
    seatPreferencesToggle.handleClose()
  }

  const isPreferenceSeatRequested = (
    index: number,
    preferences: Partial<PreferencesUK> | undefined,
  ) => {
    if (!preferences) {
      return false
    }
    return (
      (index === 0 && preferences.isSeatRequestedOnDepart) ||
      (index === 1 && preferences.isSeatRequestedOnReturn)
    )
  }

  const isSeatRequested = (
    index: number,
    currentSeatReservationType: SeatReservationType[],
    preferences: Partial<PreferencesUK> | undefined,
  ) => {
    const seatType = currentSeatReservationType[index]

    if (seatType === SeatReservationType.None) {
      return false
    }

    if (seatType === SeatReservationType.Mandatory) {
      return true
    }
    return isPreferenceSeatRequested(index, preferences)
  }

  const onSkip = () => {
    setIsSkipped(true)
    seatPreferencesToggle.handleClose()
  }

  const isRailPreferenceOptionsExist =
    !!currentSelectedPreferenceOptions?.carriageTypes.length ||
    !!currentSelectedPreferenceOptions?.seatDirections.length ||
    !!currentSelectedPreferenceOptions?.seatFacilities.length ||
    !!currentSelectedPreferenceOptions?.seatPositions.length

  return {
    appliedPreferences,
    currentSeatReservationType,
    currentSelectedPreferenceOptions,
    currentDeliveryOptions,
    currentSelectedSeatsLabel,
    isRailPreferenceOptionsExist,
    isRailPreferencesLoading,
    isUpdatedSeatPreferences,
    onPreferencesApply,
    preferenceOptionsItems,
    railPreferencesError,
    railViewStateParams,
    seatPreferencesToggle,
    temporaryCurrentLayout,
    isRailEticket,
    isSkipped,
    onSkip,
    setInwardSeatReservationType,
    setOutwardSeatReservationType,
  }
}
