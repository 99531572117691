import { Skeleton } from '@etta/ui/skeleton'
import { Content } from './unused-tickets-loading-mobile-styled'

export function UnusedTicketsLoadingMobile() {
  const loadingStates = Array.from({ length: 4 })
  return (
    <Content>
      {loadingStates.map((_, key) => (
        <Skeleton key={key} width="100%" height="84">
          <rect x="0" y="10" width="32" height="32" />
          <rect x="47" y="10" width="164" height="16" />
          <rect x="47" y="35" width="120" height="12" />
          <rect x="47" y="55" width="164" height="12" />
        </Skeleton>
      ))}
    </Content>
  )
}
