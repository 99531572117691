import type { CalculateTripEmissionsResultFlight } from '@fiji/graphql/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { getCalculateEmissionsDeviation } from '@fiji/hooks/sustainability/get-calculate-emissions-deviation'
import { sum, tonnesToKg } from './utils'
import { getEquivalencesCount } from './equivalences'
import type { FlightEmission } from './types'

export function getFlightEmissionsSummary(
  segments: FlightPostBookingValueObject[],
  rawFlightEmissions?: CalculateTripEmissionsResultFlight[] | null,
): {
  flightEmissions: FlightEmission[]
  tonnesOfEmissionsSummary: number
  averageTonnesOfEmissionsSummary: number
  treeSeedlingsEquivalenceTotalCount: number
  plasticBagsEquivalenceTotalCount: number
  smartphoneChargeEquivalenceTotalCount: number
} {
  const flightEmissions: FlightEmission[] = []
  let tonnesOfEmissionsSummary = 0
  let averageTonnesOfEmissionsSummary = 0
  let treeSeedlingsEquivalenceTotalCount = 0
  let plasticBagsEquivalenceTotalCount = 0
  let smartphoneChargeEquivalenceTotalCount = 0

  segments.forEach((segment) => {
    const flightEmission = rawFlightEmissions?.find((flight) => flight.customRef === segment.legId)

    tonnesOfEmissionsSummary = sum(tonnesOfEmissionsSummary, flightEmission?.tonnesOfEmissions)
    averageTonnesOfEmissionsSummary = sum(
      averageTonnesOfEmissionsSummary,
      flightEmission?.averageRouteEmissionTonnes,
    )

    const {
      plasticBagEquivalenceAmount,
      treeSeedlingEquivalenceAmount,
      smartphoneChargeEquivalenceAmount,
    } = getEquivalencesCount(flightEmission?.equivalences)

    treeSeedlingsEquivalenceTotalCount = sum(
      treeSeedlingsEquivalenceTotalCount,
      treeSeedlingEquivalenceAmount,
    )

    plasticBagsEquivalenceTotalCount = sum(
      plasticBagsEquivalenceTotalCount,
      plasticBagEquivalenceAmount,
    )

    smartphoneChargeEquivalenceTotalCount = sum(
      smartphoneChargeEquivalenceTotalCount,
      smartphoneChargeEquivalenceAmount,
    )

    const { deviationLevel, percentDeviation } = getCalculateEmissionsDeviation({
      averageEmissionsTonnes: flightEmission?.averageRouteEmissionTonnes || 0,
      tonnesOfEmissions: flightEmission?.tonnesOfEmissions || 0,
    })

    flightEmissions.push({
      originAirportCode: segment.segments ? segment.segments[0]?.departure?.airportCode : undefined,
      destiationAirportCode: segment.segments
        ? segment.segments[segment.segments.length - 1].arrival?.airportCode
        : undefined,
      emissionsKg: tonnesToKg(flightEmission?.tonnesOfEmissions || 0),
      deviationLevel,
      percentDeviation,
    })
  })

  return {
    flightEmissions,
    tonnesOfEmissionsSummary,
    averageTonnesOfEmissionsSummary,
    treeSeedlingsEquivalenceTotalCount,
    plasticBagsEquivalenceTotalCount,
    smartphoneChargeEquivalenceTotalCount,
  }
}
