export enum TimeStrFormat {
  ISO,
  Itinerary,
}

export function deleteTimezone(stamp: string, timeFormat?: TimeStrFormat) {
  // For itienerary date str
  // input: '2020-10-06T10:00:00.000-07:00'
  // output: '2020-10-06T10:00:00.000'
  const defaultItinerary = stamp.slice(0, 23)

  switch (timeFormat) {
    case TimeStrFormat.Itinerary:
      return defaultItinerary

    case TimeStrFormat.ISO:
      // input: '2022-08-29T10:05:00-06:00'
      // output: '2022-08-29T10:05:00'
      return stamp.slice(0, 19)
    default:
      return defaultItinerary
  }
}
