import { useMemo } from 'react'
import type { FlightLegSubSegment } from '@fiji/graphql/types'
import type { AdaptiveFlight } from '@fiji/hooks/use-adaptive-flight/types'
import { getSeatMapSegmentFetchStructure } from '@fiji/hooks/use-fetch-seat-rows/get-seat-map-segment-fetch-structure'

type Args = {
  segments?: FlightLegSubSegment[]
  adaptiveFlight: AdaptiveFlight
}

export function useFetchSeatMapSegments({ segments, adaptiveFlight }: Args) {
  return useMemo(() => {
    return (
      adaptiveFlight.adaptedSegments.map((adaptiveSegment) => {
        const segment = segments?.find((segment) => segment.segmentId === adaptiveSegment.id)
        if (!segment) {
          return null
        }

        return getSeatMapSegmentFetchStructure(adaptiveSegment, segment)
      }) || []
    ).filter(Boolean)
  }, [adaptiveFlight, segments])
}
