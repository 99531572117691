import { Skeleton } from '@etta/ui/skeleton'
import { CardContainer } from './hotel-card-desktop-skeleton-styled'

export const HotelCardDesktopSkeleton = (
  <CardContainer>
    <Skeleton width="100%" height="196px">
      <path d="M20,10h252v176h-252a10,10 0 0 1 -10,-10v-156a10,10 0 0 1 10,-10z" />

      <rect y="21" x="288" width="12%" height="12px" />
      <rect y="45" x="288" width="22%" height="18px" />
      <rect y="86" x="288" width="40%" height="12px" />
      <rect y="106" x="288" width="22%" height="12px" />
      <rect y="165" x="288" width="22%" height="12px" />
    </Skeleton>
    <Skeleton width="100px" height="196px" align="absolute-right">
      <rect y="139" x="27" width="50" height="18px" />
      <rect y="165" x="0" width="77px" height="12px" />
    </Skeleton>
  </CardContainer>
)
