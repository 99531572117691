import styled from 'styled-components'
import { UnstyledButton, footnoteMedium } from '@fiji/style'

export const DetailedPreferences = styled(UnstyledButton)`
  position: relative;
  background: transparent;
  border-width: 0;
  outline-width: 0;
  padding: 5px 32px 6px 20px;
  text-align: left;
`

export const PreferenceLine = styled.div`
  margin: 11px 0;
  display: flex;
  align-items: flext-start;
  color: ${(p) => p.theme.colors.mainText};
`

export const Description = styled.div`
  ${footnoteMedium};
  margin: 3px 0 0 7px;
  line-height: 1.8rem;
  white-space: pre-wrap;
`

export const NonFilled = styled.span`
  color: ${(p) => p.theme.colors.bodyText};
`
