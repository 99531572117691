import styled from 'styled-components'
import { headline, subHeadStrong } from '@fiji/style'

export const SectionTitle = styled.h2`
  ${headline};
  margin: 0 0 14px -16px;
  display: flex;
  gap: 10px;
  align-items: baseline;
`

export const SelectedText = styled.span`
  ${subHeadStrong};
  color: ${({ theme }) => theme.colors.bodyText};
`
