import { Inject, Service } from '@etta/di'
import { RideHailSuggestionAdapter } from '../../infra/suggestion/ride-hail-suggestion.adapter'
import { SuggestionStore } from '../store/suggestion.store'

@Service()
export class SuggestionService {
  constructor(
    @Inject() private readonly suggestionStore: SuggestionStore,
    @Inject() private readonly rideHailSuggestionAdapter: RideHailSuggestionAdapter,
  ) {}

  async getSuggestions() {
    this.suggestionStore.setIsLoading(true)
    this.suggestionStore.setIsError(false)

    const result = await this.rideHailSuggestionAdapter.getRideHailSuggestion()

    if (result.isErr()) {
      this.suggestionStore.setIsError(true)
      this.suggestionStore.setIsLoading(false)
      this.suggestionStore.setSuggestions([])
      return
    }

    this.suggestionStore.setIsError(false)
    this.suggestionStore.setIsLoading(false)
    this.suggestionStore.setSuggestions(result.getValue())
  }
}
