import { useMemo } from 'react'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { getDateRangeTitle } from '@fiji/utils/dates/get-date-range-title/get-date-range-title'
import { isoToDate } from '@fiji/utils/dates/iso-to-date'

export function useDateRangeTitle(startDate?: string, endDate?: string | null) {
  const dateRange = useMemo(() => {
    if (!startDate) {
      return ''
    }

    const endDateChecked = endDate ? isoToDate(deleteTimezone(endDate)) : undefined

    return getDateRangeTitle({
      startDate: isoToDate(deleteTimezone(startDate)),
      endDate: endDateChecked,
    })
  }, [startDate, endDate])

  return dateRange
}
