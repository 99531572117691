import type { CustomFieldExtension } from '../types'
import { ComputeFields } from './compute-fields'

type Props = {
  fields: CustomFieldExtension[]
  onChange(field: string): (value: string | Date | boolean | null | undefined) => void
  values: Record<string, string>
  errors: Record<string | number, string>
}

export function CustomFieldComponent({ fields, onChange, values, errors }: Props) {
  if (!fields.length) {
    return null
  }

  return <ComputeFields fields={fields} onChange={onChange} values={values} errors={errors} />
}
