import styled from 'styled-components'

export const Wrapper = styled.div``

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 279px;
  height: 480px;
  border: 1px solid ${(p) => p.theme.colors.borderDark};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  overflow: hidden;
  z-index: 3;
`

export const Header = styled.div`
  min-height: 64px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderDark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const List = styled.div`
  overflow-y: scroll;
`

export const Option = styled.div`
  padding: 12px 16px;
  cursor: pointer;
`
