import { useMemo } from 'react'
import type { LoyaltyProgram } from '@fiji/graphql/types'
import type { OptionType } from '@etta/ui/select'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import {
  getMockedRailUkCardsData,
  getMockedRailUkSavedCardsData,
} from '@etta/components/rail-card-modal/mocks'
import { useTravelPreferencesContext } from '@etta/modules/travel-preferences'
import type { HandleCardClickProps } from './../../rail-card-modal/types'

type Props = {
  options: OptionType<string>[]
  value: LoyaltyProgram[] | null
  onUpdate: (data: LoyaltyProgram[]) => void
}

export function useLoyalty({ value, options, onUpdate }: Props) {
  const { handleOpen, isOpen, handleClose } = useTogglePopup()
  const { travelPreferencesStore } = useTravelPreferencesContext()
  const rail = travelPreferencesStore.travelPreferences.rail

  // FIXME: This Mocked functions should be removed when rail membership cards request will be ready on Phoenix side
  const savedCards = useMemo(() => {
    const railPrograms = rail.loyaltyProgram.filter((card) => card.number)
    if (!railPrograms.length) {
      return getMockedRailUkSavedCardsData()
    }
    return railPrograms
  }, [rail.loyaltyProgram])
  const cardGroups = getMockedRailUkCardsData()

  const onSelectUKCard = (card: HandleCardClickProps) => {
    if (!value) {
      return
    }

    const res = [card]

    onUpdate(res)
  }

  const onSelectEUCard = (numbers: string[], savedCards: LoyaltyProgram[]) => {
    if (!numbers) {
      return
    }
    const res = numbers.map((number) => ({
      vendorCode: savedCards.find((item) => item.number === number)?.vendorCode || '',
      number: number,
      label: savedCards.find((item) => item.number === number)?.label,
    }))

    onUpdate(res)
  }

  const onNumberUpdate = (number: string, index: number, membership: LoyaltyProgram) => {
    if (!value) {
      return
    }

    const res = value.map((el, i) =>
      i === index
        ? {
            vendorCode: membership.vendorCode,
            number,
            label: options.find((el) => el.value === membership.vendorCode)?.label,
          }
        : el,
    )
    onUpdate(res)
  }

  return {
    onSelectUKCard,
    onSelectEUCard,
    onHideModal: handleClose,
    onShowModal: handleOpen,
    isOpen,
    savedCards,
    cardGroups,
    onNumberUpdate,
  }
}
