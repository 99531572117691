import { Store } from '@etta/di'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import type { CheckoutInfoResult } from '../../core/value-objects/checkout-info.value-object'
import type { PhoneValueObject } from '../../core/value-objects/phone.value-object'
import { CheckoutInfoSpecialRequest } from './specialRequests/checkout-info-special-request'
import { CheckoutInfoMemberships } from './memberships/checkout-info-memberships'
import { CheckoutInfoMeal } from './meal/checkout-info-meal'
import { CheckoutInfoBilling } from './billing/checkout-info-billing.store'
import { CheckoutInfoTraveler } from './traveler/checkout-info-traveler.store'

@Store()
export class CheckoutInfoStore {
  dynamicSiteMessages: DynamicSiteMessageValueObject[] = []
  customFieldsConfiguration: CheckoutInfoResult['customFieldsConfiguration'] = null
  costAllocations: CheckoutInfoResult['costAllocations'] = []
  preBookRequestRequirement: CheckoutInfoResult['preBookRequestRequirement'] | null = null
  meal = new CheckoutInfoMeal()
  specialRequests = new CheckoutInfoSpecialRequest()
  memberships = new CheckoutInfoMemberships()
  billing = new CheckoutInfoBilling()
  primaryTraveler = new CheckoutInfoTraveler()
  isPassportRequired = false
  showTravelerContact?: boolean | null = null
  isLoading = false
  isError = false
  isRulesAcknowledgementRequired = false
  shouldShowNdcFareAcknowledgment?: boolean | null = null
  private _isLoaded = false // used to prevent excessive call to get checkout info
  private _isTravelerDataLoading = false
  private _isAdditionalDataLoading = false
  private _isBillingDataLoading = false
  private _isTravelerDataError = false
  private _isAdditionalDataError = false
  private _isBillingDataError = false

  get address() {
    return this.primaryTraveler.travelerData?.address
  }

  get dateOfBirth() {
    return this.primaryTraveler.travelerData?.dateOfBirth
  }

  get gender() {
    return this.primaryTraveler.travelerData?.gender
  }

  get phoneNumbers(): PhoneValueObject[] {
    return [
      this.primaryTraveler.travelerData?.phone,
      this.primaryTraveler.travelerData?.personalMobile,
    ]
      .filter((item) => item !== undefined)
      .filter((item) => item?.number !== '') as PhoneValueObject[]
  }

  get isLoaded() {
    return this._isLoaded
  }

  get isTravelerDataLoading() {
    return this.isLoading || this._isTravelerDataLoading
  }

  get isAdditionalDataLoading() {
    return this.isLoading || this._isAdditionalDataLoading
  }

  get isBillingDataLoading() {
    return this.isLoading || this._isBillingDataLoading
  }

  get isTravelerDataError() {
    return this.isError || this._isTravelerDataError
  }

  get isAdditionalDataError() {
    return this.isError || this._isAdditionalDataError
  }

  get isBillingDataError() {
    return this.isError || this._isBillingDataError
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  setIsLoaded(value: boolean) {
    this._isLoaded = value
  }

  setIsTravelerDataLoading(value: boolean) {
    this._isTravelerDataLoading = value
  }

  setIsAdditionalDataLoading(value: boolean) {
    this._isAdditionalDataLoading = value
  }

  setIsBillingDataLoading(value: boolean) {
    this._isBillingDataLoading = value
  }

  setIsTravelerDataError(value: boolean) {
    this._isTravelerDataError = value
  }

  setIsAdditionalDataError(value: boolean) {
    this._isAdditionalDataError = value
  }

  setIsBillingDataError(value: boolean) {
    this._isBillingDataError = value
  }

  dropCheckoutInfo() {
    this.dynamicSiteMessages = []
    this.customFieldsConfiguration = null
    this.meal.dropMealRequestData()
    this.specialRequests.dropSpecialRequestData()
    this.memberships.dropMembershipData()
    this.billing.dropBillingData()
    this.primaryTraveler.dropTravelerData()
    this.costAllocations = []
    this.preBookRequestRequirement = null
    this.isPassportRequired = false
    this.showTravelerContact = null
    this.isRulesAcknowledgementRequired = false
    this.shouldShowNdcFareAcknowledgment = null
    this._isLoaded = false
    this._isTravelerDataLoading = false
    this._isTravelerDataError = false
    this._isAdditionalDataLoading = false
    this._isAdditionalDataError = false
    this._isBillingDataLoading = false
    this._isBillingDataError = false
  }

  dropPersisted() {
    this.primaryTraveler.dropTravelerPersist()
  }

  // not only traveler data is included, can be refined
  setTravelerData(checkoutInfo: CheckoutInfoResult) {
    this.dynamicSiteMessages = checkoutInfo.dynamicSiteMessages
    this.meal.setMealRequestData(checkoutInfo.mealRequest)
    this.specialRequests.setSpecialRequestData(checkoutInfo.specialRequests)
    this.memberships.setMembershipData(checkoutInfo.memberships)
    this.primaryTraveler.setTravelerData(checkoutInfo.primaryTraveler)
    this.costAllocations = checkoutInfo.costAllocations
    this.preBookRequestRequirement = checkoutInfo.preBookRequestRequirement
    this.isPassportRequired = !!checkoutInfo.isPassportRequired
    this.showTravelerContact = checkoutInfo?.displaySettings?.shouldShowShareTravelerContact
    this.isRulesAcknowledgementRequired = !!checkoutInfo.isRulesAcknowledgementRequired
    this.shouldShowNdcFareAcknowledgment =
      checkoutInfo?.displaySettings?.shouldShowNdcFareAcknowledgment
  }

  setAdditionalData(customFieldsConfiguration?: CheckoutInfoResult['customFieldsConfiguration']) {
    this.customFieldsConfiguration = customFieldsConfiguration
  }

  setBillingData(billing: CheckoutInfoResult['billing']) {
    this.billing.setBillingData(billing)
  }
}
