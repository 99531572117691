import type { PlaceAutocomplete } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

export function mapGraphqlPlaces(places: PlaceAutocomplete[]): Place[] {
  const result = places.map(
    ({
      airportCode,
      placeId,
      latitude,
      longitude,
      locationType,
      name,
      shortName,
      isHotelAddress,
      placeType,
      uniqueCode,
      isGrouped,
      subPlaces,
      address,
      timezone,
    }) => ({
      airportCode: airportCode || undefined,
      placeId,
      latitude: latitude || undefined,
      longitude: longitude || undefined,
      locationType: locationType || undefined,
      name,
      shortName: shortName || undefined,
      isHotelAddress: isHotelAddress || undefined,
      placeType: placeType || undefined,
      uniqueCode: uniqueCode || undefined,
      isGrouped,
      subPlaces: subPlaces ? mapGraphqlPlaces(subPlaces) : undefined,
      countryCode: address?.countryCode || undefined,
      countryName: address?.countryName || undefined,
      stateCode: address?.stateCode || undefined,
      city: address?.city || undefined,
      timezone: timezone || undefined,
    }),
  )

  return result
}
