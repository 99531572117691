import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Container } from './alert-error-styled'

const i18nBase = 'SearchSegments.NoResultErrorScreen'

export const AlertError = observer(function AlertError() {
  const { t } = useTranslation()
  const onReload = () => {}

  return (
    <Container>
      <Block marginBottom={8}>
        <Icon name="warningFilledPWA" size="large" color="bodyText" />
      </Block>
      <Block marginBottom={5}>
        <Text variant="subHeadMedium" color="mainText">
          {t(`${i18nBase}.ErrorTitle`)}
        </Text>
      </Block>
      <Button variant="link" color="primary" size="small" onClick={onReload}>
        {t(`${i18nBase}.TryAgain`)}
      </Button>
    </Container>
  )
})
