import { observer } from 'mobx-react-lite'
import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useHotelSearchFormContext } from '@etta/modules/hotel-search/interface/use-hotel-search-form.context'
import { ViewMode } from './view-mode'
import { EditMode } from './edit-mode'

export const HotelResultHeaderDesktop = observer(function HotelResultHeaderDesktop() {
  const { hotelSearchFormActions, hotelSearchFormStore } = useHotelSearchFormContext()

  return (
    <SearchResultsHeaderFull.Container
      isEditOpen={hotelSearchFormStore.editFormModalToggle.isOpen}
      viewSlot={<ViewMode onEdit={hotelSearchFormActions.handleOpenEditModal} />}
      editSlot={<EditMode onClose={hotelSearchFormActions.handleCloseEditModal} />}
    />
  )
})
