import { distanceRecalculationRatio } from '@fiji/constants'
import type { Distance } from '@fiji/graphql/types'

export const DISTANCE_MIN = 0.25
export const DISTANCE_MAX_DEFAULT = 25
export const COEFFICIENT_MILES_CONVERSION = 0.621371

export function parseDistance(
  distance?: string | number,
  maxDistance = DISTANCE_MAX_DEFAULT,
): number {
  const numericDistance = Number(distance)

  if (isNaN(numericDistance) || numericDistance > maxDistance) {
    return maxDistance
  }

  return Math.max(numericDistance, DISTANCE_MIN)
}

export function convertMilesToKilometers(miles: number): number {
  return miles * distanceRecalculationRatio
}

export function convertDistanceToMeters(distance: Distance) {
  const kilometers =
    distance.units === 'km' ? distance.value : convertMilesToKilometers(distance.value)

  return parseFloat((kilometers * 1000).toFixed(1))
}

export function convertDistanceToAppleMapsAltitude(distance: Distance) {
  return convertDistanceToMeters(distance) * 2
}

export function convertMetersInToMiles(meters: number) {
  return ((meters / 1000) * COEFFICIENT_MILES_CONVERSION).toFixed(1)
}
