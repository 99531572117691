import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { CarRentalCarDetailsValueObject } from '@etta/modules/car-rental-search/core/value-objects'
import type { RateValueObject } from '@etta/core/value-objects'

type Args = {
  car: CarRentalCarDetailsValueObject | null
  isServiceFeeEnabled: boolean
}

type PriceItem = {
  testId: string
  title: string
  value?: string
  variant: 'secondary' | 'primary'
}

type UseCarRentalPriceResult = {
  priceList: PriceItem[]
  totalPrice: string
}

function formatMainRate(rate?: RateValueObject | null) {
  return formatRate(rate, {
    morpheme: 'none',
  }).mainCost
}

export function useCarRentalPrice({ car, isServiceFeeEnabled }: Args): UseCarRentalPriceResult {
  const i18Base = 'CarRentalDetails'
  const { t } = useTranslation()

  return useMemo<UseCarRentalPriceResult>(() => {
    if (!car) {
      return {
        priceList: [],
        totalPrice: '',
      }
    }

    const perDayRate = formatMainRate(car.carCostRates?.perDayRate)
    const extraDayRate = formatMainRate(car.carCostRates?.extraDayRate)
    const extraHourRate = formatMainRate(car.carCostRates?.extraHourRate)
    const taxesRate = formatMainRate(car.taxes)
    const serviceFeeRate = formatMainRate(car.serviceFeeRate)
    const serviceFeeCost = isServiceFeeEnabled ? serviceFeeRate : undefined
    const baseRate = formatMainRate(car.baseRate)
    const netRate = formatMainRate(car.netRate)
    const baseCost = isServiceFeeEnabled ? netRate : baseRate
    const totalPrice = formatMainRate(car?.totalCost)

    return {
      priceList: [
        {
          testId: 'CostPerDay',
          variant: 'primary',
          title: t(i18Base + '.PriceDetails.CostPerDay'),
          value: perDayRate,
        },
        {
          testId: 'ExtraDay',
          variant: 'primary',
          title: t(i18Base + '.PriceDetails.ExtraDay'),
          value: extraDayRate,
        },
        {
          testId: 'ExtraHour',
          variant: 'primary',
          title: t(i18Base + '.PriceDetails.ExtraHour'),
          value: extraHourRate,
        },
        {
          testId: 'TotalRentalCost',
          variant: 'secondary',
          title: t(i18Base + '.PriceDetails.TotalRentalCost'),
          value: baseCost,
        },
        {
          testId: 'Taxes',
          variant: 'secondary',
          title: t(i18Base + '.PriceDetails.Taxes'),
          value: taxesRate,
        },
        {
          testId: 'ServiceFee',
          variant: 'secondary',
          title: t(i18Base + '.PriceDetails.ServiceFee'),
          value: serviceFeeCost,
        },
      ],
      totalPrice,
    }
  }, [car, isServiceFeeEnabled, t])
}
