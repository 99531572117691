import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 12px 0 24px;
`

export const ContentWrapper = styled.div`
  padding: 18px 24px;
`

export const ListItem = styled.li`
  color: ${(p) => p.theme.colors.bodyText};
  ${footnoteMedium}
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 19px;
  margin: 8px 0px;
`
