import styled from 'styled-components'

export const IconWrapper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StartIconMedium = styled.div`
  min-width: 18px;
  height: 18px;
  margin-bottom: -2px;
`

export const SelectedDateText = styled.span`
  margin-left: -5px;
`
