import i18n from 'i18next'
import { getFormattedTime } from '@fiji/utils/dates'

const i18Base = 'TripPlanner.BaseSearch'

type Args = {
  i18next: string
  customHours?: number
  startHours?: number
  endHours?: number
  label?: string
  value?: string | JSX.Element
}

function getFormattedHours(hours?: number) {
  if (!hours) {
    return
  }

  return getFormattedTime({ hours }, 'haaa')
}

export function getTimeAriaLabel({
  customHours,
  startHours,
  endHours,
  value,
  label,
  i18next,
}: Args) {
  const leadingLabel = i18n.t(i18next)

  const formattedStartTimeHours = startHours ? getFormattedHours(startHours) : undefined
  const formattedEndTimeHours = endHours ? getFormattedHours(endHours) : undefined
  const formattedCustomTimeHours = customHours ? getFormattedHours(customHours) : undefined

  if (!value) {
    return i18n.t(i18Base + '.Input.TimeAriaLabel', { label })
  }
  const i18TimeSelected = i18Base + '.Input.TimeSelectedAriaLabel'

  if (formattedCustomTimeHours) {
    return i18n.t(i18TimeSelected, {
      value: `${i18n.t('FlightSearch.TimePicker.Around')} ${formattedCustomTimeHours}`,
      label,
    })
  }
  if (formattedStartTimeHours && formattedEndTimeHours) {
    return i18n.t(i18TimeSelected, {
      value: `${leadingLabel} (${formattedStartTimeHours} - ${formattedEndTimeHours})`,
      label,
    })
  }

  return i18n.t(i18TimeSelected, {
    value: leadingLabel,
    label,
  })
}
