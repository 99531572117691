import DocumentTitle from 'react-document-title'
import { Route, Switch, useLocation } from 'react-router-dom'
import { MobileNavigation } from '@etta/modules/navigation'
import { appMode } from '@fiji/modes'
import type { RouteItem } from '@fiji/routes'
import { useCurrentRoute } from '@etta/modules/app/ui/use-current-route'
import { HistoryParamsAdapter } from '@etta/modules/app/ui/app-routing/history-params-adapter'
import { useHistoryContext } from '@etta/modules/app/interface/use-history-context'
import { RouteContainer } from './route-container'
import { SwitchScreen } from './switch-screen'
import { PageLayout } from './page-layout'
import { useProgressiveFooterVisible } from './use-progressive-footer-visible'
import { Footer } from './footer'

type Props = {
  routes: Array<RouteItem>
}

export function RoutesSwitcher({ routes }: Props) {
  const { isEttaMobile } = appMode

  const { currentRouteItem } = useCurrentRoute()
  const { historyStore } = useHistoryContext()
  const { isNavBarHidden } = currentRouteItem || {}
  const { isFooterVisible } = useProgressiveFooterVisible(currentRouteItem)

  const location = useLocation()

  historyStore.updateCurrentPath(currentRouteItem?.route.path)

  return (
    <RouteContainer isAnimationAvailable={isEttaMobile}>
      <SwitchScreen
        isFooterVisible={isFooterVisible && !isNavBarHidden}
        isAnimationAvailable={isEttaMobile}
        isUsePreload={isEttaMobile}>
        <Switch location={location}>
          {routes.map((routeItem: RouteItem) => {
            const {
              route,
              component: ScreenComponent,
              documentTitle,
              isDeemWrapperUsed,
            } = routeItem

            return (
              <Route key={route.path} {...route}>
                <HistoryParamsAdapter />
                {documentTitle && <DocumentTitle title={documentTitle} />}
                <PageLayout isDeemWrapperUsed={isDeemWrapperUsed}>
                  <ScreenComponent />
                </PageLayout>
              </Route>
            )
          })}
        </Switch>
      </SwitchScreen>
      <Footer isVisible={isFooterVisible}>
        <MobileNavigation />
      </Footer>
    </RouteContainer>
  )
}
