import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { TextFieldHelperType } from '@etta/ui/text-field/types'
import { SelectSearch } from '@etta/ui/select-search'
import type { SelectSearchOptionType } from '@etta/ui/select-search/types'
import countries from './countries-v2.json'

type Props = {
  value?: string
  setValue: (e: string) => void
  helperText?: string
  isDisabled?: boolean
  helperType?: TextFieldHelperType
  customOptions?: SelectSearchOptionType[]
  disabledOptions?: string[]
  customLabel?: string
  customPlaceholder?: string
  customAriaLabel?: string
}

const i18nBase = 'CountrySearch'

export function CountrySearch({
  value,
  setValue,
  helperText,
  isDisabled,
  helperType,
  customOptions,
  disabledOptions,
  customLabel,
  customPlaceholder,
  customAriaLabel,
}: Props) {
  const [options, setOptions] = useState<typeof countries>([])

  const { t } = useTranslation()

  useEffect(() => {
    setOptions(countries)
  }, [])

  return (
    <SelectSearch
      value={value}
      onOptionSelect={setValue}
      label={customLabel || t(`${i18nBase}.Label`)}
      placeholder={customPlaceholder || t(`${i18nBase}.Placeholder`)}
      options={customOptions || options}
      disabledOptions={disabledOptions}
      helperText={helperText}
      isDisabled={isDisabled}
      helperType={helperType}
      fieldType={!isDisabled ? 'select' : 'text'}
      data-tracking-id="modal-country-search"
      ariaLabel={
        customAriaLabel || t('Accessibility.Settings.Button', { label: t(`${i18nBase}.Label`) })
      }
    />
  )
}
