import styled from 'styled-components'
import { screenSize } from '@fiji/style'
import type { ColorPalette } from '@fiji/types'
import type { TripConfirmationType } from '@etta/modules/booking/core/value-objects/trip-confirmation-type.value-object'

const getBackgroundColor = (colors: ColorPalette, confirmationType: TripConfirmationType) => {
  switch (confirmationType) {
    case 'unconfirmed': {
      return colors.transparent
    }
    case 'hold':
      return colors.duskBlue
    case 'partial':
      return colors.transparent
    case 'regular':
    default:
      return colors.plum
  }
}

export const Wrapper = styled.div<{ confirmationType: TripConfirmationType }>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => getBackgroundColor(props.theme.colors, props.confirmationType)};
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 43px;
  padding-bottom: 64px;

  @media (${screenSize.maxMobile}) {
    justify-content: center;
    padding-bottom: 48px;
  }
`

export const TripIconHeld = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.warning};
  display: flex;
  justify-content: center;
  align-items: center;
`
