import styled, { css } from 'styled-components'
import { Button } from '@etta/ui/button'
import { linkSmall, footnoteMedium } from '@fiji/style'
import { glowingFragment } from '@fiji/style/animations'

export const Container = styled.div`
  padding: 0 22px 0 54px;
`

export const DuplicateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 16px;
  border-radius: 14px;
`

export const CollapseButton = styled(Button)`
  ${linkSmall};
  padding: 0;
`

export const DatesLoader = styled.div`
  ${glowingFragment};
  width: 135px;
  height: 16px;
  margin: 6px 0;
`

export const CollapsedContainer = styled.div`
  padding: 16px;
`

export const CustomHeader = styled.div`
  position: relative;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
`

export const DuplicateTripBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  padding: 16px;
`

export const SegmentContainer = styled.div`
  display: flex;
  background-color: white;
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.background};

  &:first-of-type {
    border-radius: 14px 14px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 14px 14px;
    border-bottom: none;
  }
`

const textCSS = css`
  color: ${({ theme }) => theme.colors.bodyText};
  ${footnoteMedium};
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
`

export const ListItem = styled.li<{ textTransform?: 'capitalize' | 'none' }>`
  display: flex;
  flex-shrink: 0;
  text-transform: ${(p) => p.textTransform || 'capitalize'};
  max-width: 100%;
  ${textCSS};
  &:not(:first-of-type):before {
    content: '\\2022';
    margin: 0 5px;
  }
  line-height: 1.5;
`

export const SegmentDescriptionContainer = styled.div`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  margin-top: 6px;
`

export const AirSegmentTitleContainer = styled.div`
  display: flex;
`
