import { useTranslation } from 'react-i18next'
import { Tabs } from '@etta/ui/tabs'

type Props = {
  activeTab: string
  areHotelReviewsDisplayed?: boolean
  onTabChange: (tab: string) => void
}

const tabs = [
  {
    value: 'about',
    label: 'About',
  },
  {
    value: 'rooms',
    label: 'Rooms',
  },
  {
    value: 'amenities',
    label: 'Amenities',
  },
  {
    value: 'location',
    label: 'Location',
  },
  {
    value: 'safety-check',
    label: 'SafetyCheck',
  },
  {
    value: 'reviews',
    label: 'Reviews',
  },
]

export function TabsBlock({ activeTab, areHotelReviewsDisplayed, onTabChange }: Props) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails.Tabs.'

  return (
    <Tabs activeTab={activeTab} onChangeTab={onTabChange}>
      <Tabs.Header withBottomBorder={false}>
        {tabs.map(({ value, label }) => {
          if (!areHotelReviewsDisplayed && value === 'reviews') {
            return null
          }

          return (
            <Tabs.Tab
              key={value}
              value={value}
              aria-label={label}
              data-tracking-id={`hotel-details-header-tab_${label}`}>
              {t(i18Base + label)}
            </Tabs.Tab>
          )
        })}
      </Tabs.Header>
    </Tabs>
  )
}
