import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { useAirportChangeText } from '@fiji/hooks/use-airport-change-text'

const i18nBase = 'PostBooking.FlightDetails'

type Props = {
  flightLegSegments: TripDetailsFlightLegSegment[]
  layoverTime: string
  airportName: string
  airportCode: string
}

export function useLayoverTexts({
  flightLegSegments,
  layoverTime,
  airportName,
  airportCode,
}: Props) {
  const { t } = useTranslation()
  const layoverText = t(i18nBase + '.Layover', {
    layoverTime,
    airportName,
    airportCode,
  })
  const layoverTimeText = t(i18nBase + '.LayoverTime', {
    layoverTime,
  })
  const layoverLabel = t(i18nBase + '.LayoverLabel', {
    layoverTime,
    airportName,
    airportCode,
  })

  const { changeText } = useAirportChangeText({
    flightLegSegments,
  })

  const finalText = useMemo(() => {
    if (!changeText) {
      return layoverText
    }
    return `${changeText} - ${layoverTimeText}`
  }, [changeText, layoverText, layoverTimeText])

  return {
    finalText,
    layoverLabel,
  }
}
