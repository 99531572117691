import styled from 'styled-components'
import { screenSize } from '@fiji/style'

export const HotelCategoriesFilterWrapper = styled.div`
  display: none;
  @media ${screenSize.isDesktopMd}, ${screenSize.isDesktopLg} {
    display: block;
  }
`

export const HotelAmenitiesFilterWrapper = styled.div`
  display: none;
  @media ${screenSize.isDesktopSm}, ${screenSize.isDesktopMd}, ${screenSize.isDesktopLg} {
    display: block;
  }
`
