import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { UnusedTicketsModal } from '@etta/components/unused-tickets-modal'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { Link } from '@etta/ui/link'
import { useDesktopNavigation } from './use-desktop-navigation'
import {
  Container,
  Wrapper,
  LogoContainer,
  NavigationLinksContainer,
  NavigationItemsContainer,
  NavigationLink,
} from './desktop-navigation-styled'
import { DesktopNavigationProfileContent } from './desktop-navigation-profile-content'
import { DesktopNavigationItem } from './desktop-navigation-item'

type Props = {
  isTripCartDisplayed?: boolean
  fullWidth?: boolean
  shouldDisplayNavigationLinks?: boolean
}

export const DesktopNavigation = observer(function DesktopNavigation({
  isTripCartDisplayed = true,
  fullWidth = true,
  shouldDisplayNavigationLinks = true,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.MainNavigation'

  const {
    exploreLink,
    bookLinkText,
    handleSiteSwitch,
    navigationItems,
    isTripCartEnabled,
    isInDelegateMode,
    isDelegateSwitchEnabled,
    isSelfServiceSite,
    switchModalSlot,
    isNavigationModalOpen,
    currentUser,
    handleNavigateProfile,
    onLogout,
    unusedTickets,
    unusedTicketsCount,
    unusedTicketsIsLoading,
    unusedModalIsOpen,
    handleCloseUnusedModal,
    handleOpenUnusedModal,
    handleOpenDelegateModal,
    externalLinks,
  } = useDesktopNavigation()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { brandConfigurationStore } = useBrandConfigurationContext()
  const { isAppleTheme } = brandConfigurationStore
  const { isExternalLinksEnabled } = featureFlagsStore.flags
  const logoName = isAppleTheme
    ? t(`${i18Base}.Apple`)
    : isSelfServiceSite
    ? t(`${i18Base}.Etta`)
    : t(`${i18Base}.Deem`)

  const renderLogo = useMemo(() => {
    const iconName: IconNames = (() => {
      if (isAppleTheme) {
        return 'appleLogoIconPWA'
      }

      if (isSelfServiceSite) {
        return 'ettaLogoGoPWA'
      }

      return 'deemLogoPWA'
    })()

    return (
      <LogoContainer>
        <Icon name={iconName} size="medium" color={'mainText'} />
      </LogoContainer>
    )
  }, [isAppleTheme, isSelfServiceSite])

  return (
    <Container>
      {switchModalSlot}
      <Wrapper fullWidth={fullWidth}>
        <NavigationItemsContainer>
          <NavigationLink
            to={exploreLink}
            link-name={bookLinkText}
            aria-label={t(`${i18Base}.Logo`, { name: logoName })}>
            {renderLogo}
          </NavigationLink>
          {shouldDisplayNavigationLinks && (
            <NavigationLinksContainer>
              {navigationItems.map((item) => (
                <Block marginRight={20} key={item.title}>
                  <DesktopNavigationItem to={item.to} title={item.title} isActive={item.isActive}>
                    <Text variant="subHeadStrong">{item.title}</Text>
                  </DesktopNavigationItem>
                </Block>
              ))}
              {externalLinks.isExternalLinksEnabled && isExternalLinksEnabled && (
                <Block marginRight={20}>
                  <Link href={externalLinks.links[0].url} target="_blank" size="small">
                    <Text color="bodyText" variant="subHeadStrong">
                      {externalLinks.links[0].label}
                    </Text>
                  </Link>
                </Block>
              )}
            </NavigationLinksContainer>
          )}
        </NavigationItemsContainer>

        <DesktopNavigationProfileContent
          handleSiteSwitch={handleSiteSwitch}
          isDelegateSwitchEnabled={isDelegateSwitchEnabled}
          isTripCartEnabled={isTripCartEnabled}
          isInDelegateMode={isInDelegateMode}
          isTripCartDisplayed={isTripCartDisplayed}
          isSelfServiceSite={isSelfServiceSite}
          isModalOpen={isNavigationModalOpen}
          currentUser={currentUser}
          handleNavigateProfile={handleNavigateProfile}
          handleLogout={onLogout}
          handleOpenUnusedModal={handleOpenUnusedModal}
          unusedTicketsCount={unusedTicketsCount}
          handleOpenDelegateModal={handleOpenDelegateModal}
        />
      </Wrapper>
      <UnusedTicketsModal
        isLoading={unusedTicketsIsLoading}
        unusedTickets={unusedTickets}
        onClose={handleCloseUnusedModal}
        isVisible={unusedModalIsOpen}
      />
    </Container>
  )
})
