import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { subHeadMedium } from '@fiji/style'
import { Button as UIButton } from '@etta/ui/button'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 auto;
  max-width: 285px;
  color: ${(props) => props.theme.colors.plum};
`

export const Title = styled(Text)`
  margin: 27px 0 8px;
`

export const SubTitle = styled(Text)<{ isBold?: boolean }>`
  margin-bottom: 24px;
  white-space: pre-line;
  ${(props) =>
    props.isBold
      ? css`
          margin: 4px 0;
          ${subHeadMedium};
          color: ${({ theme }) => theme.colors.mainText};
        `
      : css`
          margin-bottom: 24px;
        `}
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 168px;
`

export const Button = styled(UIButton).attrs(({ variant, paddings }) => ({
  variant,
  paddings,
  btnType: 'primary',
  size: 'small',
}))`
  &:not(:last-of-type) {
    margin-right: 12px;
  }
`
