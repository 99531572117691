import styled from 'styled-components'

export const CarOutOfPolicyContainer = styled.div<{
  isInline?: boolean
}>`
  display: ${({ isInline }) => (isInline ? 'flex' : 'block')};
  align-items: center;
  width: 100%;
`

export const CarOutOfPolicyTitle = styled.div`
  display: flex;
`

export const OutOfPolicy = styled.span`
  letter-spacing: normal;
  margin-left: 5px;
`

export const OutOfPolicyReasonContainer = styled.ul`
  letter-spacing: normal;
  list-style-position: outside;
  padding-inline-start: 25px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const OutOfPolicyReasonTextList = styled.li`
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.bodyText};
`

export const OutOfPolicyReasonText = styled.span`
  padding-left: 5px;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.bodyText};
`
