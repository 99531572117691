import { Inject, Service } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { DisplayConfigurationAdapter } from '../../infra'
import { DisplayConfigurationStore } from '../stores'

@Service()
export class DisplayConfigurationService {
  constructor(
    @Inject()
    private readonly displayConfigurationAdapter: DisplayConfigurationAdapter,
    @Inject()
    private readonly displayConfigurationStore: DisplayConfigurationStore,
    @Inject()
    private readonly cookieStore: CookieStore,
  ) {}

  async loadDisplayConfiguration() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.displayConfigurationStore.setIsLoading(true)

    const result = await this.displayConfigurationAdapter.getDisplayConfiguration()

    result.match({
      Err: () => {
        this.displayConfigurationStore.setIsLoading(false)
      },
      Ok: (data) => {
        this.displayConfigurationStore.setDisplayConfiguration(data)
        this.displayConfigurationStore.setIsLoading(false)
      },
    })
  }

  async refetchDisplayConfiguration() {
    const result = await this.displayConfigurationAdapter.getDisplayConfiguration()

    result.match({
      Err: () => {},
      Ok: (data) => {
        this.displayConfigurationStore.setDisplayConfiguration(data)
      },
    })
  }
}
