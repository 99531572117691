import type { ViewState } from '@etta/ui/view-state-modal'
import type { TripStateStatus } from '../types'

export function getViewState(tripStateStatus: TripStateStatus): ViewState {
  switch (tripStateStatus) {
    case 'loading':
    case 'loading-with-empty-text': // title will be empty string
      return 'loading'
    case 'loading-without-messages':
      return 'loading-without-messages'
    case 'error':
      return 'error'
    case 'success':
      return 'saved'
    case 'sold-out':
      return 'custom'
    case 'fare-reprice-fail':
      return 'custom'
    case 'change-return-flight-not-allowed':
      return 'custom'
    default:
      return 'hidden'
  }
}
