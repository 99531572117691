import { observer } from 'mobx-react-lite'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { HotelResultsMap } from '../hotel-results-map'
import { useHotelResultsMap } from './use-hotel-results-map'
import { HotelListLayout } from './layout'
import { useHotelList } from './use-hotel-list'
import { SearchAreaButton } from './search-area-button'
import { HotelListResults } from './hotel-list-results'

export const HotelListContent = observer(function HotelListContent() {
  const { loadHotelsAction, hotelSearchResultsStore } = useHotelSearchResultsContext()

  const {
    longitude,
    latitude,
    searchAreaBlock,
    referencePoints,
    hotelDetails,
    distance,
    mapToggle,
  } = useHotelList()
  const { handleOpen } = hotelDetails

  const {
    centerPoint,
    handleSelected,
    selectedHotel,
    highlightedHotel,
    highlightHotelCard,
  } = useHotelResultsMap({
    longitude,
    latitude,
  })

  return (
    <>
      <HotelListLayout
        isMapVisible={mapToggle.isOpen}
        mapSlot={
          <HotelResultsMap
            fetchMore={loadHotelsAction.loadMoreHotels}
            isLoading={hotelSearchResultsStore.isLoading}
            isFetchMoreLoading={hotelSearchResultsStore.isFetchMoreLoading}
            hotelsCount={hotelSearchResultsStore.hotelResults?.totalCount || 0}
            onCardClick={handleOpen}
            onMapHide={mapToggle.handleClose}
            distance={distance}
            longitude={longitude}
            latitude={latitude}
            hotels={hotelSearchResultsStore.hotels}
            referencePoints={referencePoints}
            onBoundsLeave={searchAreaBlock.onBoundsLeave}
            onBoundsReturn={searchAreaBlock.onBoundsReturn}
            centerPoint={centerPoint}
            onSelect={handleSelected}
            selectedHotel={selectedHotel}
            searchAreaSlot={
              searchAreaBlock.isNewAreaSearchVisible && (
                <SearchAreaButton onClick={searchAreaBlock.onNewAreaSearchClick} />
              )
            }
          />
        }
        hotelListSlot={
          <HotelListResults
            selectedHotelId={highlightedHotel?.id}
            handleSelected={highlightHotelCard}
          />
        }
        onMapOpen={mapToggle.handleOpen}
        isMapToggleAvailable={
          !!hotelSearchResultsStore.hotels.length &&
          !hotelSearchResultsStore.isFetchMoreLoading &&
          Boolean(hotelSearchResultsStore.hotels.length)
        }
      />
    </>
  )
})
