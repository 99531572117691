import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { UberInfoModal } from '@etta/components/uber-info-modal'
import type { SegmentType } from '@etta/core/enums'
import type { TripStatus } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { useMobilityCallToAction } from './use-mobility-call-to-action'
import { useTrackMobilityCallToAction } from './hooks'
import {
  Container,
  DescriptionContainer,
  HeaderContainer,
  CTABlock,
  IconText,
  CTATitle,
} from './mobility-call-to-action-styled'

type Props = {
  processId: string
  dropOffSegmentId?: string | null
  tripType?: TripStatus | null
  segmentType?: SegmentType
  onStartMobilitySearchFlow?: () => void
}

export const MobilityCallToAction = observer(function MobilityCallToAction({
  processId,
  dropOffSegmentId,
  tripType,
  segmentType,
  onStartMobilitySearchFlow,
}: Props) {
  const {
    info: { title, description, actionText },
    isShown,
    onOpenCallToAction,
    isModalOpen,
    handleModalClose,
    status,
  } = useMobilityCallToAction({ processId, dropOffSegmentId, tripType, onStartMobilitySearchFlow })
  useTrackMobilityCallToAction({ segmentType, status, isShown })

  if (!isShown) {
    return null
  }

  if (status === UberAccountStatusEnum.Ready) {
    return (
      <CTABlock data-tracking-id="mobility-cta-book-a-ride-button" onClick={onOpenCallToAction}>
        <IconText>
          <Icon name="rideHailPWA" size="medium" color="primary"></Icon>
          <CTATitle variant="subHeadStrong" color="primary" align="center">
            {title}
          </CTATitle>
        </IconText>
        <Icon name="addPWA" size="medium" color="primary"></Icon>
      </CTABlock>
    )
  }

  return (
    <>
      <Container data-tracking-id="mobility-cta-find-out-how-button" onClick={onOpenCallToAction}>
        <HeaderContainer>
          <Text variant="title3" color="mainText">
            {title}
          </Text>
        </HeaderContainer>
        <DescriptionContainer>
          <Text variant="subHeadMedium" color="bodyText">
            {description}
          </Text>
        </DescriptionContainer>
        <DescriptionContainer>
          <Text variant={'linkSmall'} color="primary">
            {actionText}
          </Text>
        </DescriptionContainer>
      </Container>
      <UberInfoModal
        isVisible={isModalOpen}
        onClose={handleModalClose}
        isCloseModalOnlyOnExit={true}
      />
    </>
  )
})
