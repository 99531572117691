import { HotelSearchPage } from '@etta/screens/hotel-search-page/hotel-search-page'
import { HotelResultPage } from '@etta/screens/hotel-result-page'
import { ROUTES } from '../../routes'
import type { RouteItem } from '../../types'
import { getDocumentTitle } from '../../get-document-title'

export const hotelRoutes: Array<RouteItem> = [
  {
    route: {
      path: ROUTES.hotel.search,
      exact: true,
    },
    documentTitle: getDocumentTitle('Hotel Search'),
    transitionIndex: 4,
    isNavBarHidden: true,
    transitionGroup: 'slide',
    component: () => <HotelSearchPage />,
  },
  {
    route: {
      path: ROUTES.hotel.results,
      exact: true,
    },
    documentTitle: getDocumentTitle('Hotel Search'),
    transitionIndex: 4.1,
    transitionGroup: 'slide',
    component: () => <HotelResultPage />,
  },
]
