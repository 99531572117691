import { Block } from '@etta/ui/block'
import { Container, Section } from '../../../room-rates-modal-styled'
import type { LayoutProps } from '../types'

export function RoomRatesModalContentMobile({
  roomRatesSlot,
  roomDetailsSlot,
  roomTypeTextSlot,
  hotelNameTextSlot,
  isUpcoming,
}: LayoutProps) {
  return (
    <Container>
      <Section>
        {hotelNameTextSlot}
        <Block marginTop={5}>{roomTypeTextSlot}</Block>
      </Section>
      <Section>{roomRatesSlot}</Section>
      {isUpcoming && <Section>{roomDetailsSlot}</Section>}
    </Container>
  )
}
