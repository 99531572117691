import type { TripCost, TripDetailsCarRentalInteraction, Vendor } from '@fiji/graphql/types'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { formatPickUpDropOffAddress } from '@fiji/utils/address/format-pickup-drop-off-address'

type TripData = {
  vendor?: Vendor | string | null
  pickUpTime?: string | null
  pickUpLocation?: TripDetailsCarRentalInteraction | null
  dropOffTime?: string | null
  dropOffLocation?: TripDetailsCarRentalInteraction | null
  tripCost?: TripCost | null
}

type Props = {
  carRental?: TripData | null
}

function formatDate(date?: string | null) {
  if (!date) {
    return ''
  }

  return dateFormat(new Date(deleteTimezone(date)), `eee, LLL dd 'at' p`)
}

export function postBookingCarRental({ carRental }: Props) {
  if (!carRental) {
    return undefined
  }
  const { vendor, pickUpTime, pickUpLocation, dropOffTime, dropOffLocation, tripCost } = carRental
  const vendorName = typeof vendor === 'string' ? vendor : vendor?.name

  return `
${vendorName || ''}
Pick-up: ${formatDate(pickUpTime)}
${pickUpLocation?.address ? formatPickUpDropOffAddress(pickUpLocation.address) : ''}
Drop-off: ${formatDate(dropOffTime)}
${dropOffLocation?.address ? formatPickUpDropOffAddress(dropOffLocation.address) : ''}
${
  tripCost
    ? `Cost: ${tripCost?.total?.primary.amount || ''} ${tripCost?.total?.primary?.currency || ''}`
    : ''
}
`.trim()
}
