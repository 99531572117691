export default function TickIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7313 30.6999L19.1722 31.277L19.554 30.6593L31.2952 11.6633C31.2954 11.6629 31.2957 11.6626 31.2959 11.6622C31.4763 11.3742 31.6291 11.2202 31.7731 11.1325C31.9105 11.0488 32.0816 11 32.3476 11C32.7164 11 32.9976 11.1259 33.1855 11.3143C33.3735 11.5027 33.5 11.7855 33.5 12.158C33.5 12.4119 33.4199 12.7076 33.1698 13.1087C33.1697 13.1089 33.1696 13.1091 33.1695 13.1093L20.4423 33.3306C20.1413 33.7834 19.7425 34 19.1964 34C18.9135 34 18.6951 33.9455 18.5052 33.8445C18.3116 33.7414 18.1188 33.5745 17.9112 33.3083L17.91 33.3067L10.8942 24.381L10.8943 24.3809L10.8874 24.3724C10.5976 24.02 10.5 23.7149 10.5 23.4188C10.5 22.7292 10.979 22.247 11.6795 22.247C11.9122 22.247 12.0822 22.2893 12.2266 22.3662C12.3745 22.4449 12.5288 22.5777 12.7024 22.8079L12.7023 22.8079L12.7043 22.8104L18.7313 30.6999Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
