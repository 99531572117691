import type { BillingParameter, CreditCard } from '@fiji/graphql/types'
import type { ChosenCardsMap } from '@etta/screens/payment-summary/types'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { PurchasePaymentInformationContent } from './purchase-payment-information-content'
import type { SubmitType } from './type'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: SubmitType) => void
  billingParameter: BillingParameter
  chosenCards: ChosenCardsMap
  onAddSingleTimeUseCard: (card: Partial<CreditCard>) => void
  singleTimeUseCard?: Partial<CreditCard>
  isVirtualPayEnabled?: boolean
}

export function PurchasePaymentInformationForm({ isOpen, onClose, ...props }: Props) {
  return (
    <CheckoutPageModal
      isVisible={isOpen}
      onClose={onClose}
      data-tracking-id="modal-purchase-payment-info">
      <PurchasePaymentInformationContent onClose={onClose} {...props} />
    </CheckoutPageModal>
  )
}
