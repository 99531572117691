import type { CovidStatistic } from '../../types'
import { CovidTabs } from './covid-tabs'
import { CovidInformationStatistic } from './covid-information-statistic'
import { CovidInformationEmpty } from './covid-information-empty'
import { CovidInformationContentSkeleton } from './covid-information-content-skeleton'
import { useCovidInformationContent } from './use-covid-information-content'

type Props = {
  covidStatistic?: CovidStatistic
  isLoading: boolean
}

const i18nBase = 'HotelSafetyCheck.CovidInformation.CovidInformationContent'

export function CovidInformationContent({ covidStatistic, isLoading }: Props) {
  const {
    isManyStatistics,
    covidStatisticValues,
    i18nStatistic,
    isEmpty,
  } = useCovidInformationContent({ covidStatistic, i18nBase })
  if (isLoading) {
    return <CovidInformationContentSkeleton />
  }
  if (isEmpty) {
    return <CovidInformationEmpty />
  }

  if (isManyStatistics) {
    return <CovidTabs covidStatistic={covidStatistic} />
  }

  return (
    <CovidInformationStatistic
      i18nBase={i18nStatistic}
      covidStatistic={covidStatisticValues[0]}
      shouldColumnNameRender={false}
    />
  )
}
