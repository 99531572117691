import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { Block } from '@etta/ui/block'
import { Search } from '@etta/ui/search'
import { DatePickerModal as DatePickerModalNew } from '@etta/ui/date-picker-modal-temp'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { Props } from '../../types'

function formatDate(date?: Date | null) {
  if (!date) {
    return ''
  }
  return dateFormat(date, 'LLL d')
}

const i18Base = 'TripPlanner.BaseSearch'

export function DateSectionDesktop({ checkInDate, checkOutDate, onDateChange }: Props) {
  const { t } = useTranslation()

  const checkInDatePickerModalToggle = useTogglePopup()
  const checkOutDatePickerModalToggle = useTogglePopup()

  return (
    <Block position="relative">
      <Search.Group gap={2}>
        <Search.Element
          onClick={checkInDatePickerModalToggle.handleOpen}
          labelTitle={t(`${i18Base}.Input.Check-in`)}
          value={formatDate(checkInDate)}
          placeholder={t(`${i18Base}.Input.Select`)}
        />
        <Search.Element
          onClick={checkOutDatePickerModalToggle.handleOpen}
          labelTitle={t(`${i18Base}.Input.Check-out`)}
          value={formatDate(checkOutDate)}
          placeholder={t(`${i18Base}.Input.Select`)}
        />
      </Search.Group>
      <DatePickerModalNew
        date={checkInDate}
        dateEnd={checkOutDate}
        isRange
        minDate={new Date()}
        highlightDates={[]}
        startTitle={t(`${i18Base}.Input.Checkin`)}
        endTitle={t(`${i18Base}.Input.Checkout`)}
        onSelect={onDateChange}
        isOpen={checkInDatePickerModalToggle.isOpen}
        onClose={checkInDatePickerModalToggle.handleClose}
      />
      <DatePickerModalNew
        date={checkInDate}
        dateEnd={checkOutDate}
        isRange
        isReturnDate
        minDate={new Date()}
        highlightDates={[]}
        startTitle={t(`${i18Base}.Input.Checkin`)}
        endTitle={t(`${i18Base}.Input.Checkout`)}
        onSelect={onDateChange}
        isOpen={checkOutDatePickerModalToggle.isOpen}
        onClose={checkOutDatePickerModalToggle.handleClose}
      />
    </Block>
  )
}
