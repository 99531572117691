import { Icon } from '@etta/ui/icon'
import { AddressLocation, IconWrapper } from './marker-styled'

type Props = {
  address?: string | null
}

export function Marker(props: Props) {
  const { address } = props
  return (
    <>
      {address && <AddressLocation>{address}</AddressLocation>}
      <IconWrapper>
        <Icon name="carServicePWA" color="mainText2" size="large" />
      </IconWrapper>
    </>
  )
}
