import { useTranslation } from 'react-i18next'
import type { DisabledMembership } from '@fiji/types'
import { MembershipWarning, MembershipWarningUnits } from './disabled-membership-components'

type Props = {
  isVisible: boolean
  units: DisabledMembership[]
}

export function DisabledMembershipWarning({ isVisible, units }: Props) {
  const { t } = useTranslation()

  if (!isVisible) {
    return <></>
  }
  return (
    <MembershipWarning>
      {t('DisabledMembership.Preunits')}
      <MembershipWarningUnits>
        {units?.map((unit, i, arr) => unit.name + (i < arr.length - 1 ? ', ' : ' ')) || ''}
      </MembershipWarningUnits>
      {t('DisabledMembership.Postunits')}
    </MembershipWarning>
  )
}
