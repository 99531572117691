import i18n from '@fiji/i18n'
import { Action } from '@etta/di'
import type { DynamicSiteMessageValueObject, AlertsValueObject } from '@etta/core/value-objects'
import { generateId } from '@fiji/utils/generate-id'

export type Message = DynamicSiteMessageValueObject & {
  id: string
}

@Action()
export class DynamicSiteMessagesAction {
  public mapMessages(
    messages: DynamicSiteMessageValueObject[],
    isMobileText: boolean = false,
  ): Message[] {
    if (isMobileText) {
      return messages
        .filter(({ mobileText }) => !!mobileText)
        .map((message) => this.mapMessage('mobileText')(message))
    }
    return messages.map((message) => this.mapMessage('text')(message))
  }

  public mapAlerts(messages: AlertsValueObject[]): AlertsValueObject[] {
    return messages.map(({ header, text, ...message }) => ({
      header: this.getAlertHeader(this.parseMessage(header)),
      text: this.parseMessage(text),
      ...message,
    }))
  }

  private getAlertHeader(header: string) {
    if (!header.length) {
      return i18n.t('DynamicSiteMessage.AlertHeader')
    }

    return header
  }

  private parseMessage(value: string): string {
    let parsed = value
    try {
      parsed = this.convertUnicodeStringToSymbol(value)
    } catch {}

    return parsed.replace(/\\/g, '')
  }

  private convertUnicodeStringToSymbol(value: string): string {
    return decodeURIComponent(
      JSON.parse(`"${value.replace(/'/g, "\\\\\\'").replace(/%/g, '%25')}"`),
    )
  }

  private mapMessage(
    textField: 'text' | 'mobileText',
  ): (message: DynamicSiteMessageValueObject) => Message {
    return (message: DynamicSiteMessageValueObject) => {
      const { header, ...item } = message
      const textToParse = item[textField]

      return {
        ...item,
        id: generateId('dynamic-message', header),
        header: this.truncateHeader(this.parseMessage(header)),
        text: this.parseMessage(textToParse || ''),
      }
    }
  }

  private truncateHeader(headerText: string): string {
    // Max characters to fit within three lines (add '...' if exceeded)
    const maxLengthForThreeLines = 163

    if (headerText.length > maxLengthForThreeLines) {
      return headerText.slice(0, maxLengthForThreeLines).trim() + '...'
    }

    return headerText.trim()
  }
}
