import { useTranslation } from 'react-i18next'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import type { TripEmissions } from '../../../types'
import {
  UpperBody,
  TotalEmissionHeader,
  SummaryKgContainer,
  TotalDeviationDescriptionContainer,
} from '../rtp-eco-check-modal-mobile-styled'
import { RtpEcoCheckModalDeviationDescription } from '../../common/rtp-eco-check-modal-deviation-description'

type Props = {
  tripEmissions: TripEmissions
  showSummaryDeviationLevel: boolean
}

export function RtpEcoCheckModalMobileUpperBody({
  tripEmissions,
  showSummaryDeviationLevel,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  return (
    <UpperBody>
      <TotalEmissionHeader aria-label={t(i18nBase + '.TotalEmission')}>
        {t(i18nBase + '.TotalEmission')}
      </TotalEmissionHeader>

      <SummaryKgContainer>
        <KgOfCo2 kgOfEmissions={tripEmissions.summaryKg} />
      </SummaryKgContainer>
      {showSummaryDeviationLevel && (
        <TotalDeviationDescriptionContainer>
          <RtpEcoCheckModalDeviationDescription
            isTotalDescription={true}
            deviationLevel={tripEmissions.deviationLevel}
            percentDeviation={tripEmissions.percentDeviation}
          />
        </TotalDeviationDescriptionContainer>
      )}
    </UpperBody>
  )
}
