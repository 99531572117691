import { useTranslation } from 'react-i18next'
import type { TripDetailsFlightLeg } from '@fiji/graphql/types'
import { PenaltyType } from '@fiji/enums'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { getAirPenalty } from '@fiji/utils/trip-review/air-data'
import {
  SegmentContainer,
  SegmentTitle,
  AirSegmentTitleContainer,
  SegmentDescriptionContainer,
  ListItem,
  List,
  SegmentDetailsContainer,
} from '../duplicate-list-styled'

import { useDuplicateFlightSegment } from '../../../../use-duplicate-trip-flight-segment'

type Props = {
  flightLeg: TripDetailsFlightLeg
}

export function DuplicateTripFlightSegment({ flightLeg }: Props) {
  const { t } = useTranslation()
  const {
    formatTime,
    operatedBy,
    cabinClassLabel,
    showRefundableLabel,
  } = useDuplicateFlightSegment({ flightLeg })

  if (!flightLeg.segments) {
    return null
  }

  return (
    <SegmentContainer>
      <Block marginRight={13}>
        <Icon name={'flightPWA'} size={30} />
      </Block>
      <SegmentDetailsContainer>
        <AirSegmentTitleContainer>
          <SegmentTitle>{flightLeg.segments[0]?.departure?.airportCode}</SegmentTitle>
          <Block marginLeft={3} marginRight={3}>
            <Icon name="flightArrowPWA" size={19} />
          </Block>
          <SegmentTitle>
            {flightLeg.segments[flightLeg.segments.length - 1]?.arrival?.airportCode}
          </SegmentTitle>
        </AirSegmentTitleContainer>
        <SegmentDescriptionContainer>
          <List>
            <ListItem>
              {formatTime(flightLeg.departureDateTime)} &mdash;{' '}
              {formatTime(flightLeg.arrivalDateTime)}
            </ListItem>
            <ListItem>{`${flightLeg.segments[0].name} ${flightLeg.segments[0].flightNumber}`}</ListItem>
          </List>
          {operatedBy && (
            <List>
              <ListItem>{operatedBy}</ListItem>
            </List>
          )}

          <List>
            <ListItem>{cabinClassLabel}</ListItem>
            <ListItem>
              {t(`ReviewTripPWA.Details.${flightLeg.stops > 0 ? 'Stops' : 'Nonstop'}`, {
                count: flightLeg.stops,
              })}
            </ListItem>
            {showRefundableLabel && (
              <ListItem>
                {t(
                  `ReviewTripPWA.Details.PenaltyType.${
                    getAirPenalty(PenaltyType.NonRefundable, flightLeg.segments[0].penaltyFees)
                      ? 'NonRefundable'
                      : 'Refundable'
                  }`,
                )}
              </ListItem>
            )}
          </List>
        </SegmentDescriptionContainer>
      </SegmentDetailsContainer>
    </SegmentContainer>
  )
}
