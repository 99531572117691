import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { getDisplayServiceClass } from '@fiji/utils/flights'
import { useDisplayConfigurationContext } from '@etta/modules/display-configuration'
import type { FlightLeg as FlightLegType } from '../../../../types'
import { FlightLegConfirmation } from './flight-leg-confirmation'

export type Props = {
  leg: FlightLegType
  isWebFare?: boolean
}

export function FlightLeg({ leg }: Props) {
  const { displayConfigurationStore } = useDisplayConfigurationContext()

  const { aircraft, confirmation, time, direction, cabinClass } = leg
  const serviceClassCustomLabels = displayConfigurationStore.flightServiceClassCustomLabels
  const displayCabinClass: string = getDisplayServiceClass(
    { serviceClass: cabinClass ?? '', displayServiceClass: cabinClass ?? '' },
    serviceClassCustomLabels,
  )
  const aircraftWithClass = [aircraft, displayCabinClass].filter(Boolean).join(' • ')

  return (
    <Block marginBottom={24}>
      <Block marginBottom={4}>
        <Text variant="footnoteStrong" color="mainText">
          {direction}
        </Text>
      </Block>
      {time && (
        <Block marginBottom={2}>
          <Text variant="footnoteMedium" color="bodyText1">
            {time}
          </Text>
        </Block>
      )}
      {aircraftWithClass && (
        <Block marginBottom={2}>
          <Text variant="footnoteMedium" color="bodyText1">
            {aircraftWithClass}
          </Text>
        </Block>
      )}
      {confirmation && (
        <Block marginBottom={2}>
          <FlightLegConfirmation confirmation={confirmation} />
        </Block>
      )}
    </Block>
  )
}
