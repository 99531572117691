/* eslint-disable no-console */
import { stageNameEnv } from '@fiji/config/stage-name-env'
import { STAGE_NAME } from '@fiji/enums'
import { isLocalhost } from '@fiji/utils/is-localhost'

// eslint-disable-next-line no-restricted-globals
const context = typeof window === 'undefined' ? self : window

export enum LOG_LEVEL {
  TRACE = 5,
  DEBUG = 4,
  INFO = 3,
  WARN = 2,
  ERROR = 1,
}

const isWorker = typeof window === 'undefined'
const stageName = stageNameEnv().stageName

export const logLevel = (() => {
  switch (true) {
    case isLocalhost:
    case stageName === STAGE_NAME.QA10:
    case stageName === STAGE_NAME.STAGE2:
      return LOG_LEVEL.TRACE
    case stageName === STAGE_NAME.STAGE3:
      return LOG_LEVEL.INFO
    default:
      return LOG_LEVEL.ERROR
  }
})()

if (logLevel > LOG_LEVEL.ERROR) {
  console.log('---logger', {
    logLevel,
    stageName,
    hostname: context.location.hostname,
    context: isWorker ? 'self' : 'window',
  })
}
