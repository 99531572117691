export type CurrencyMorpheme = 'prefix' | 'postfix' | 'none'

export function insertMorpheme(
  formattedMoney: string,
  currency: string,
  morpheme?: CurrencyMorpheme,
): string {
  if (!morpheme) {
    return formattedMoney
  }
  const isCurrencyIncluded = formattedMoney.includes(currency)

  const money = isCurrencyIncluded ? formattedMoney.replace(currency, '').trim() : formattedMoney

  switch (morpheme) {
    case 'postfix':
      return [money, currency].join(' ')
    case 'prefix':
      return [currency, money].join(' ')
    default:
      return isCurrencyIncluded ? formattedMoney : money
  }
}
