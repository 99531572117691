import styled from 'styled-components'

export const MapContainer = styled.div`
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  height: 100%;
  width: 100%;
  position: absolute;
`

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
`

export const IconWrapper = styled.span`
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.mainText};
  border-radius: 100%;
  position: fixed;
  z-index: 20;
  margin: 20px;
`
