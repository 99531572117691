import { useTranslation } from 'react-i18next'
import { Separator } from '@etta/ui/separator'
import type { SafetyCheckFlight } from '@fiji/types'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import { ExpandedViewList } from '../../expanded-view-list'
import { StyledChevronIcon, SafetyHeader } from '../../../collapsed-view/collapses-view-styled'
import { Container, SeeMoreDetails } from './expanded-view-mobile-styled'

type Props = {
  flightSafetyChecks: SafetyCheckFlight[]
  isExpanded: boolean
  handleExpand: () => void
  onModalOpenClick: () => void
  hasDetails: boolean
}

export function ExpandedViewMobile({
  isExpanded,
  handleExpand,
  flightSafetyChecks,
  onModalOpenClick,
  hasDetails,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'
  const seeMoreLink = t(i18nBase + '.SeeMoreLink')
  const seeMoreLinkLabel = t(i18nBase + '.SeeMoreLinkLabel', { label: seeMoreLink })
  const multipleFlights = flightSafetyChecks.length > 1

  return (
    <Container data-tracking-id="flight-details-modal-safety-check-expanded">
      <SafetyHeader onClick={handleExpand}>
        <SafetyCheckTitle />
        <StyledChevronIcon
          name="chevronDownPWA"
          rotate={isExpanded}
          color="mainText"
          size="small"
          aria-label={
            isExpanded
              ? t(i18nBase + '.TapToCollapseDetailsLabel')
              : t(i18nBase + '.TapToViewDetailsLabel')
          }
        />
      </SafetyHeader>
      {flightSafetyChecks.map(
        (safetyCheck, index) =>
          !!safetyCheck.headLines.length && (
            <ExpandedViewList
              key={index}
              safetyCheck={safetyCheck}
              multipleFlights={multipleFlights}
            />
          ),
      )}
      {hasDetails && (
        <>
          <Separator lineType="solid" color="borderLight" />
          <SeeMoreDetails
            role="button"
            tabIndex={0}
            onClick={onModalOpenClick}
            aria-label={seeMoreLinkLabel}>
            {seeMoreLink}
          </SeeMoreDetails>
        </>
      )}
    </Container>
  )
}
