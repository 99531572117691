import type { GraphQLError } from 'graphql'
import type { InvalidTravelErrorDetailInfo } from '@fiji/types'

type RestrictedErrorData = {
  isRestrictedCountryError: boolean
  restrictedErrorMessage?: string
}

type ErrorParameterName = 'restrictedMessage' | 'restrictionMessage'

export function getRestrictedErrorData(
  errors: Readonly<GraphQLError[]>,
  errorParameterName: ErrorParameterName,
): RestrictedErrorData {
  const isRestrictedCountryError = !!errors.some((err) => err?.extensions?.isRestrictedCountryError)

  if (!errors.length) {
    return {
      isRestrictedCountryError,
      restrictedErrorMessage: '',
    }
  }

  const errorDetails = errors[0].extensions?.details as InvalidTravelErrorDetailInfo
  const restrictedErrorMessage =
    isRestrictedCountryError && errorDetails && errorDetails.errorDetail
      ? errorDetails.errorDetail[0].parameter?.find((item) => item.name === errorParameterName)
          ?.value
      : ''

  return {
    isRestrictedCountryError,
    restrictedErrorMessage,
  }
}
