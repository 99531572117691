import * as R from 'ramda'
import { dateFormat } from '@fiji/utils/dates/date-format'
import type { Maybe } from '@fiji/graphql/types'

type Props = {
  time: Date
  displayYear?: Maybe<boolean>
  displayMonth?: Maybe<boolean>
  displayDay?: Maybe<boolean>
  displayHour?: Maybe<boolean>
  displayMinute?: Maybe<boolean>
  displayTimeZone?: Maybe<boolean>
}

export const formatTimeField = ({ time, displayHour, displayMinute, displayTimeZone }: Props) => {
  const format = R.pipe(
    () => {
      if (displayHour && displayMinute) {
        return ['h:mm aa']
      }

      if (displayHour) {
        return ['h aa']
      }

      if (displayMinute) {
        return ['mm']
      }

      return []
    },
    (currentFormat) => (displayTimeZone ? [...currentFormat, 'O'] : currentFormat),
  )()

  const formattedDate = dateFormat(time, format.filter(Boolean).join(' '))

  return formattedDate
}
