import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Container = styled.div<{ isMobile?: boolean }>`
  > * {
    position: relative;
  }

  > *:not(:last-child):after {
    content: '';
    display: flex;
    position: absolute;
    left: ${({ isMobile }) => (isMobile ? 28 : 40)}px;
    bottom: -60px;
    width: 1px;
    height: 60px;
    background-color: ${(props) => props.theme.colors.borderDark};
  }

  > *:not(:first-child) {
    margin-top: 56px;
  }

  > *:last-child {
    margin-bottom: 32px;
  }
`

export const RtpEcoCheckSummaryContainer = styled.div`
  padding-bottom: 40px;
`

export const Wrapper = styled(UnstyledButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 16px;
`
