import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { SupportTravelBody } from '../../../components/support-travel-body'
import {
  PreviewWrapper,
  PreviewContainer,
  TravelSupportHeader,
  TravelSupportHeaderTitle,
} from './preview-support-travel-desktop-styled'

const i18Base = 'TripPlanner.Support'

export function PreviewSupportTravelDesktop() {
  const { t } = useTranslation()

  return (
    <PreviewWrapper data-tracking-id="preview-support-travel">
      <PreviewContainer>
        <TravelSupportHeader>
          <TravelSupportHeaderTitle
            textVariant={StyledTextVariant.title2}
            data-tracking-id="travel-support-text">
            {t(i18Base + '.MainPage.DeemHelpCenter')}
          </TravelSupportHeaderTitle>
        </TravelSupportHeader>
        <SupportTravelBody />
      </PreviewContainer>
    </PreviewWrapper>
  )
}
