import styled, { css } from 'styled-components'
import { subHeadStrong, subHeadMedium, KeyboardNavigationStyle } from '@fiji/style'

export const YearPickerContainer = styled.div<{ isHide?: boolean }>`
  ${({ isHide }) =>
    isHide &&
    css`
      display: none;
      overflow: hidden;
    `}
`

export const YearPickerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 249px;
  overflow: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 15px 10px 10px;
`

export const YearPickerHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  height: 45px;
  ${KeyboardNavigationStyle}
`

export const YearItem = styled.div<{ isActive?: boolean }>`
  width: 55px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${subHeadMedium};
  margin: 6px 2.2%;
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary1 : theme.colors.primary2};
    color: ${({ theme }) => theme.colors.white};
    ${subHeadStrong}
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.primary1};
      color: ${({ theme }) => theme.colors.white};
      ${subHeadStrong}
    `}
  ${KeyboardNavigationStyle}
`
