import cardValidator from 'card-validator'
import { CreditCardBrandType } from '@fiji/graphql/types'

export function getBrandTypeByCardNumber(cardNumber: string) {
  const numberValidation = cardValidator.number(cardNumber)
  switch (numberValidation.card?.type) {
    case 'american-express':
      return CreditCardBrandType.Ax
    case 'visa':
      return CreditCardBrandType.Vi
    case 'mastercard':
      return CreditCardBrandType.Ca
    case 'diners-club':
      return CreditCardBrandType.Dc
    case 'jcb':
      return CreditCardBrandType.Jc
    case 'discover':
      return CreditCardBrandType.Ds
    default:
      return CreditCardBrandType.Other
  }
}
