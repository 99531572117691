import { Separator } from '@etta/ui/separator'
import { BaggageInformationModal } from '@etta/components/baggage-information-modal/baggage-information-modal'
import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'
import { Paper } from '../trip-flight-styled'
import { StatusBar } from './flight-details-styled'
import { Header, CabinClass, Amenities, BaggageInfo, Layover, FlightTimeLine } from './components'
import { useFlightDetails } from './use-flight-details'

type Props = {
  segment: TripDetailsFlightLegSegment
  nextSegment?: TripDetailsFlightLegSegment
  legNumber: number
  legs: number
  nextFlightStart: string
  isTripCancelled: boolean
  isSeatChangeAllowed?: boolean | null
  isFlightCancelled?: boolean | null
  onSeatMapOpen: () => void
}

export function FlightDetails({
  segment,
  nextSegment,
  legNumber,
  legs,
  nextFlightStart,
  isTripCancelled,
  isSeatChangeAllowed,
  onSeatMapOpen,
  isFlightCancelled,
}: Props) {
  const {
    isMultipleFlights,
    flightNumber,
    airlineTitle,
    aircraftNumber,
    isDelayed,
    airlineLogo,
    flightStatus,
    cabinClass,
    seat,
    baggageClaim,
    isBaggageShow,
    baggageModalToggle,
    firstBaggageCost,
    isAmenitiesShown,
    amenities,
    baggageInfo,
    layoverTime,
    isLastSegment,
    flightLegSegments,
  } = useFlightDetails({
    segment,
    nextSegment,
    legNumber,
    legs,
    nextFlightStart,
  })

  return (
    <>
      <Paper>
        {isMultipleFlights && (
          <StatusBar role="header" aria-label={flightNumber} aria-level={3}>
            {flightNumber}
          </StatusBar>
        )}
        <Header
          airlineTitle={airlineTitle}
          aircraftNumber={aircraftNumber}
          isDelayed={isDelayed}
          logo={airlineLogo}
          isTripCancelled={isTripCancelled}
          flightStatus={flightStatus}
        />
        <Separator />
        <FlightTimeLine segment={segment} />
        <CabinClass
          areDetailsShown={true}
          cabinClass={cabinClass}
          seat={seat}
          baggage={baggageClaim}
          onSeatMapOpen={onSeatMapOpen}
          isTripCancelled={isTripCancelled}
          isSeatChangeAllowed={isSeatChangeAllowed}
          isFlightCancelled={isFlightCancelled}
          baggageInfo={baggageInfo}
        />
        {isBaggageShow && (
          <BaggageInfo
            onBaggageClick={baggageModalToggle.handleOpen}
            firstBaggageCost={firstBaggageCost}
            baggageInfo={baggageInfo}
          />
        )}
        {isAmenitiesShown && (
          <>
            <Separator />
            <Amenities amenities={amenities} />
          </>
        )}
        <BaggageInformationModal
          baggageInfo={[baggageInfo]}
          onClose={baggageModalToggle.handleClose}
          isOpen={baggageModalToggle.isOpen}
          isAdaptivePosition
        />
      </Paper>
      {isMultipleFlights && !isLastSegment && (
        <Layover
          flightLegSegments={flightLegSegments}
          layoverTime={layoverTime}
          airportName={segment.arrival?.airportName || ''}
          airportCode={segment.arrival?.airportCode || '-'}
        />
      )}
    </>
  )
}
