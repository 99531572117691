import { Skeleton } from '@etta/ui/skeleton'
import { Wrapper, Body, Footer, Button, DueTodayCostContainer } from './loading-skeleton-styled'

type Props = {
  buttonLabel: string
  isDueTodayCostDisplayed: boolean
}

export function LoadingSkeleton({ buttonLabel, isDueTodayCostDisplayed }: Props) {
  return (
    <Wrapper>
      <Body>
        <Skeleton width="100%" height={130}>
          <rect width="88px" height="14px" />
          <rect y={24} width="78px" height="14px" />
          <rect y={24} x={221} width="52px" height="14px" />
          <rect y={67} width="108px" height="14px" />
          <rect y={91} width="78px" height="14px" />
          <rect y={91} x={221} width="52px" height="14px" />
          <rect y={116} width="78px" height="14px" />
          <rect y={116} x={231} width="42px" height="14px" />
        </Skeleton>
      </Body>
      <Footer>
        <Skeleton width="100%" height={14}>
          <rect width="82px" height="14px" />
          <rect x={202} width="71px" height="14px" />
        </Skeleton>
        {isDueTodayCostDisplayed && (
          <DueTodayCostContainer>
            <Skeleton width="100%" height={13}>
              <rect width="25%" height="13px" />
              <rect x="80%" width="20%" height="13px" />
            </Skeleton>
          </DueTodayCostContainer>
        )}
        <Button fullWidth disabled>
          {buttonLabel}
        </Button>
      </Footer>
    </Wrapper>
  )
}
