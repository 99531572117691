import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 0;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.background}; ;
`

export const Section = styled.div`
  margin: 16px 0;
  position: relative;
`

export const RailDetailsEcoCheckContainer = styled.div`
  padding-bottom: 16px;
`

export const RailDetailsSection = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
`
