import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
`

export const Content = styled.div`
  padding: 0 16px 40px 16px;
`

export const StartSlotContainer = styled.div`
  > * {
    padding: 16px 0;
  }
`

export const ItineraryList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > * {
    margin-top: 40px;
  }
`

export const FlightName = styled.div`
  display: flex;
  align-items: center;
`

export const StyledFlightArrowIcon = styled(Icon).attrs(() => ({
  color: 'bodyText',
  size: 'small',
}))`
  margin: 0 6px;
`
