import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import type { SegmentType } from '@fiji/graphql/types'
import type { Section } from '../types'

type SegmentsResult = {
  newSegments: TripSegmentValueObject[]
  bookedSegments: TripSegmentValueObject[]
}

type DetailsResult = {
  newDetails?: Section
  bookedDetails?: Section
}

type GetDetailsArgs = {
  type: SegmentType
  details?: Section
}

type GetSegmentsArgs = {
  type: SegmentType
  details: Section
}

const getSegments = ({ details, type }: GetSegmentsArgs): SegmentsResult => {
  return details.segments.reduce<SegmentsResult>(
    (acc, segment) => {
      if (segment.type !== type) {
        return acc
      }
      const segmentState = segment.segmentState
      if (segmentState) {
        acc.newSegments.push(segment)
      } else {
        acc.bookedSegments.push(segment)
      }
      return acc
    },
    { newSegments: [], bookedSegments: [] },
  )
}

export const getSegmentDetails = ({ details, type }: GetDetailsArgs): DetailsResult => {
  if (!details) {
    return { newDetails: undefined, bookedDetails: undefined }
  }
  const { newSegments, bookedSegments } = getSegments({ details, type })

  return {
    newDetails: {
      ...details,
      segments: newSegments,
    },
    bookedDetails: {
      ...details,
      segments: bookedSegments,
    },
  }
}
