import styled, { css } from 'styled-components'
import * as Typography from '@fiji/style/typography'
import { KeyboardNavigationStyle } from '@fiji/style'
import { InputButtonSize } from '../types'

export const Button = styled.button<{ disabled?: boolean }>`
  width: 100%;
  min-height: 64px;
  height: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: left;
  border: none;
  outline: none;

  cursor: pointer;

  ${(props) => {
    switch (props.disabled) {
      case true:
        return css`
          background: ${props.theme.colors.background4};
          opacity: 0.4;
        `
      default:
        return css`
          background: ${props.theme.colors.background4};
        `
    }
  }}

  ${KeyboardNavigationStyle}
`

type LabelProps = {
  isValueExist: boolean
  isIconExist: boolean
  size: InputButtonSize
}

export const Label = styled.div<LabelProps>`
  display: flex;
  color: ${(props) =>
    props.isValueExist ? props.theme.colors.bodyText : props.theme.colors.bodyText1};

  ${(props) => {
    if (props.size === InputButtonSize.Big) {
      return props.isValueExist ? Typography.captionMedium : Typography.headline
    }

    if (props.isIconExist && !props.isValueExist) {
      return Typography.subHeadMedium
    }

    return props.isValueExist ? Typography.captionMedium : Typography.subHeadStrong
  }}
`

type ValueProps = {
  size: InputButtonSize
  isMediumWeight?: boolean
  isAvalible: boolean
}

export const Value = styled.div<ValueProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) =>
    props.isAvalible ? props.theme.colors.mainText : props.theme.colors.bodyText2};

  ${(props) => {
    if (props.size === InputButtonSize.Big) {
      return Typography.headline
    }

    if (props.isMediumWeight) {
      return Typography.subHeadMedium
    }

    return Typography.subHeadStrong
  }};
`

export const ValueWrapper = styled.div<{ size: InputButtonSize; isLabelExist: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.size === InputButtonSize.Big || !props.isLabelExist ? '2px' : '8px'};
`

export const Placeholder = styled.span`
  color: ${(props) => props.theme.colors.bodyText1};
`
