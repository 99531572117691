import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetHomeAirportDocument } from '@fiji/graphql/hooks'
import type { GetHomeAirportQuery } from '@fiji/graphql/types'

@DataProvider()
export class HomeAirportDataProvider extends GraphqlDataProvider {
  get(forceUpdate?: boolean) {
    return this.client.query<GetHomeAirportQuery>({
      query: GetHomeAirportDocument,
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
