import { Text } from '@etta/ui/text'
import { StyledTextVariant } from '@fiji/enums'
import { useScreenType, ScreenType } from '@fiji/modes'
import { HeaderSection, Initials, Info, Name, CustomLogo } from '../profile-styled'

type Props = {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  customLogoURL?: string | null
}

export function Header({ firstName, lastName, email, customLogoURL }: Props) {
  const screenType = useScreenType()
  const isCustomLogoShown = screenType === ScreenType.Mobile && customLogoURL
  return (
    <HeaderSection>
      {firstName && lastName && (
        <Initials textVariant={StyledTextVariant.headline}>
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Initials>
      )}
      <Info>
        {firstName && lastName && <Name>{`${firstName} ${lastName}`}</Name>}
        <Text
          noWrap
          color="bodyText1"
          textOverflow="ellipsis"
          variant="subHeadMedium"
          title={email || undefined}>
          {email}
        </Text>
      </Info>
      {isCustomLogoShown && <CustomLogo src={customLogoURL} alt="custom_logo" />}
    </HeaderSection>
  )
}
