import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DropDownList, ListTypes } from '@etta/ui/drop-down-list'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { QuickFilterLayout } from '@etta/ui/quick-filter-layout'
import { HotelGroupFilter } from '@etta/screens/hotel-result-page/hotel-filters/hotel-group-filter'
import { HotelCategoriesFilterWrapper } from './view-mode-styled'

const i18nBase = 'HotelFilters.Hotel'

export const HotelCategoriesFilter = observer(function HotelCategoriesFilter() {
  const {
    hotelSearchResultsStore,
    hotelFiltersAction,
    hotelFiltersStore,
    hotelQuickFiltersStore,
    hotelQuickFiltersAction,
  } = useHotelSearchResultsContext()
  const { t } = useTranslation()

  const options = hotelSearchResultsStore.filterOptions.groups.map(({ name, id }) => ({
    label: name,
    value: id,
  }))

  const onChange = useCallback(
    (newValue) => {
      hotelFiltersAction.handleSetFilter('groupId', newValue[0])
      hotelFiltersAction.handleApplyFilters()
    },
    [hotelFiltersAction],
  )

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isNewHotelExposedFiltersEnabled } = featureFlagsStore.flags

  if (!hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.filterOptions.groups.length) {
    return null
  }

  if (isNewHotelExposedFiltersEnabled && !hotelSearchResultsStore.filterOptions.groups.length) {
    return null
  }

  if (isNewHotelExposedFiltersEnabled) {
    return (
      <HotelCategoriesFilterWrapper>
        <QuickFilterLayout
          data-tracking-id="hotel-categories-filter-dropdown"
          isOpen={hotelQuickFiltersStore.categoriesModal.isOpen}
          onOpen={hotelQuickFiltersStore.categoriesModal.handleOpen}
          onClose={hotelQuickFiltersStore.categoriesModal.handleClose}
          onClear={hotelFiltersAction.clearGroupId}
          onApply={hotelQuickFiltersAction.handleApplyCategories}
          label={t(i18nBase + '.HotelCategories')}
          value={hotelQuickFiltersStore.hotelGroupValue}
          isShimmerLoading={
            hotelSearchResultsStore.isLoading && !hotelSearchResultsStore.hotelResults
          }
          isLoading={hotelSearchResultsStore.isLoading}
          selectedNumber={hotelFiltersStore.selectedFilters.groupId ? 1 : 0}
          isApplyButtonDisabled={hotelQuickFiltersStore.isCategoriesApplyButtonDisable}
          isClearButtonDisabled={hotelQuickFiltersStore.isCategoriesClearButtonDisable}>
          <HotelGroupFilter />
        </QuickFilterLayout>
      </HotelCategoriesFilterWrapper>
    )
  }

  return (
    <HotelCategoriesFilterWrapper>
      <DropDownList
        label={t(i18nBase + '.HotelCategories')}
        options={options}
        value={[hotelFiltersStore.selectedFilters.groupId]}
        onChange={onChange}
        type={ListTypes.Radio}
        defaultLabel={t(i18nBase + '.Any')}
        data-tracking-id="hotel-categories-filter-dropdown"
        withNoneOption={false}
      />
    </HotelCategoriesFilterWrapper>
  )
})
