import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'

export const IconContainer = styled(Icon)`
  margin-right: 10px;
`

export const ModelBlock = styled(Block)`
  display: flex;
  > span {
    padding-top: 4px;
  }
  svg {
    margin-left: -2px;
  }
`
