import styled from 'styled-components'
import { body } from '@fiji/style/typography'

export const Container = styled.div`
  position: relative;
`

export const InputButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputButton = styled.button`
  background-color: ${(p) => p.theme.colors.secondary};
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  padding: 14px 14px 14px 42px;
  cursor: pointer;
  text-align: left;

  & + & {
    margin-top: 1px;
  }

  &:first-of-type {
    border-radius: 8px 8px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 8px 8px;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.background1};
  }
`

export const Value = styled.div`
  ${body}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: ${(props) => props.theme.colors.mainText};
`

export const LocationIconContainer = styled.div`
  position: absolute;
  top: calc(((100% - 1px) / 4) - 7px);
  left: 14px;
  bottom: calc(((100% - 1px) / 4) - 7px);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LocationIcon = styled.div<{ icon: 'pick-up' | 'drop-off' }>`
  background: ${(p) => p.theme.colors.white};
  width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: ${(p) => (p.icon === 'pick-up' ? '100%' : '0%')};
  border: 4px solid ${(p) => p.theme.colors.mainText1};
`

export const VerticalDelimiter = styled.div`
  width: 1px;
  height: 100%;
  border-left: 1px dashed ${(props) => props.theme.colors.mainText};
  background-color: ${(props) => props.theme.colors.white};
`
