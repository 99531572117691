import type { MapOptions as GoogleMapOptions } from 'google-map-react'
import { Action, Inject } from '@etta/di'
import { MapProvider } from '@etta/modules/display-configuration/core/enums'
import type { EttaMapEntity } from '../core/etta-map.entity'
import type { EttaMapOptions } from '../core/etta-map.options'
import { GoogleMapService } from './google'
import { AppleMapService } from './apple'
import { EttaMapStore } from './etta-map.store'
import { EttaMapService } from './etta-map.service'
import type { CreateMapArgs } from './types'

@Action()
export class EttaMapActions {
  constructor(
    @Inject() private ettaMapService: EttaMapService,
    @Inject() private ettaMapStore: EttaMapStore,
    @Inject() private googleMapService: GoogleMapService,
    @Inject() private appleMapService: AppleMapService,
  ) {}

  public async loadMap() {
    if (this.ettaMapStore.mapToken) {
      return
    }
    await this.ettaMapService.loadMap()
  }

  public createEttaMap({ provider, map }: CreateMapArgs): EttaMapEntity {
    if (provider === MapProvider.AppleMapkit) {
      return this.appleMapService.createMap(map)
    }
    return this.googleMapService.createMap(map)
  }

  public buildGoogleMapOptions(options: EttaMapOptions, isDarkTheme: boolean): GoogleMapOptions {
    return this.googleMapService.buildGoogleMapOptions(options, isDarkTheme)
  }
}
