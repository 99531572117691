import { Action, Inject } from '@etta/di'
import type { CommonCreditCard } from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import { CheckoutInfoStore } from '../checkout-info.store'
import type { BillingPersistValueObject } from '../../../core/value-objects/checkout-info/billing-persist.value-object'

@Action()
export class CheckoutInfoBillingAction {
  constructor(
    @Inject()
    private readonly checkoutInfoStore: CheckoutInfoStore,
  ) {}

  setSingleUseCreditCard({
    key,
    creditCard,
  }: {
    key?: keyof BillingPersistValueObject
    creditCard: CommonCreditCard
  }) {
    if (!key) {
      return
    }
    if (key === 'air') {
      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
      })
    }

    if (key === 'carRental') {
      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
      })
    }

    if (key === 'hotel') {
      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
      })
    }

    if (key === 'rail') {
      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
      })
    }
  }

  //   This is tmp to set card by id from UI
  setCardById(key: keyof BillingPersistValueObject, id: string | null) {
    if (key === 'air') {
      const creditCard = this.checkoutInfoStore.billing.air?.creditCards.find(
        (card) => card.data.id === id,
      )
      const siteCard = this.checkoutInfoStore.billing.air?.siteCards.find(
        (card) => card.data.id === id,
      )
      if (!creditCard && !siteCard) {
        return
      }

      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
        siteCard: siteCard,
      })
    }

    if (key === 'carRental') {
      const creditCard = this.checkoutInfoStore.billing.carRental?.creditCards.find(
        (card) => card.data.id === id,
      )
      const siteCard = this.checkoutInfoStore.billing.carRental?.siteCards.find(
        (card) => card.data.id === id,
      )
      if (!creditCard && !siteCard) {
        return
      }

      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
        siteCard: siteCard,
      })
    }

    if (key === 'hotel') {
      const creditCard = this.checkoutInfoStore.billing.hotel?.creditCards.find(
        (card) => card.data.id === id,
      )
      const siteCard = this.checkoutInfoStore.billing.hotel?.siteCards.find(
        (card) => card.data.id === id,
      )
      if (!creditCard && !siteCard) {
        return
      }

      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
        siteCard: siteCard,
      })
    }

    if (key === 'rail') {
      const creditCard = this.checkoutInfoStore.billing.rail?.creditCards.find(
        (card) => card.data.id === id,
      )
      const siteCard = this.checkoutInfoStore.billing.rail?.siteCards.find(
        (card) => card.data.id === id,
      )
      if (!creditCard && !siteCard) {
        return
      }

      this.checkoutInfoStore.billing.appendBillingValue(key, {
        creditCard: creditCard,
        siteCard: siteCard,
      })
    }
  }
}
