import type { ZooKeeperFlagsQuery } from '@fiji/graphql/types'
import type { ZookeeperConfigurationValueObject } from '../../core/value-objects'

export class ZookeeperConfigurationMapper {
  static toValueObject(
    zookeeperConfiguration: ZooKeeperFlagsQuery['zooKeeperFlags'],
  ): ZookeeperConfigurationValueObject {
    return {
      policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled:
        zookeeperConfiguration.policyIndicatorOnlyOnTheFinalFlightResultsPageEnabled,
      southwestCheckInEmailNotificationEnabled:
        zookeeperConfiguration.southwestCheckInEmailNotificationEnabled,
      southwestCheckInSmsNotificationEnabled:
        zookeeperConfiguration.southwestCheckInSmsNotificationEnabled,
      removeNotificationSectionOnTheCheckoutPageEnabled:
        zookeeperConfiguration.removeNotificationSectionOnTheCheckoutPageEnabled,
      nonStopFilterByDefaultEnabled: zookeeperConfiguration.nonStopFilterByDefaultEnabled,
      blockBasicEconomyEnabled: zookeeperConfiguration.blockBasicEconomyEnabled,
      remoteProfileMessage: zookeeperConfiguration.remoteProfileMessage,
      remoteProfileEnabled: zookeeperConfiguration.remoteProfileEnabled,
      removeHotelStarRatingEnabled: zookeeperConfiguration.removeHotelStarRatingEnabled,
      disableTsaHazmatMessageEnabled: zookeeperConfiguration.disableTsaHazmatMessageEnabled,
      tieredCostAllocationEnabled: zookeeperConfiguration.tieredCostAllocationEnabled,
      removePerDiemLinkEnabled: zookeeperConfiguration.removePerDiemLinkEnabled,
    }
  }
}
