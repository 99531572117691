export default function AppleLogoIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 453 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M452.152 21.2116V113.001H437.136V21.2116H452.152ZM170.135 38.7702V113.001H154.514V38.7702H127.631V25.6316H197.078V38.7702H170.135ZM213.4 74.5534V113.001H198.384V46.823H212.855V57.1159H213.884C215.7 50.5769 222.845 45.7331 231.382 45.7331C233.562 45.7331 236.226 45.9753 237.618 46.3386V60.0222C236.468 59.5984 232.472 59.1745 229.929 59.1745C220.181 59.1745 213.4 65.2292 213.4 74.5534ZM261.629 114.091C270.287 114.091 277.553 110.337 281.246 103.919H282.276V113.001H296.746V67.7722C296.746 53.7859 287.18 45.5515 270.166 45.5515C254.424 45.5515 243.465 52.9382 242.254 64.5027H256.422C257.815 59.9616 262.537 57.4792 269.44 57.4792C277.553 57.4792 281.912 61.1726 281.912 67.7722V73.4636L264.838 74.4929C248.733 75.4011 239.711 82.364 239.711 94.2312C239.711 106.341 248.854 114.091 261.629 114.091ZM254.606 93.5046C254.606 99.196 259.389 102.526 266.049 102.526C275.131 102.526 281.912 96.5925 281.912 88.7214V83.3327L266.957 84.362C258.541 84.907 254.606 87.9949 254.606 93.5046ZM341.343 113.001L364.775 46.823H348.912L333.714 98.2878H332.685L317.367 46.823H301.261L324.814 113.001H341.343ZM426.506 94.8972C423.782 106.825 412.702 114.333 397.081 114.333C377.584 114.333 365.838 101.376 365.838 80.1843C365.838 58.9324 377.766 45.5515 396.959 45.5515C415.85 45.5515 427.172 58.0847 427.172 78.8523V83.6355H380.915V84.4226C381.278 95.6238 387.635 102.647 397.504 102.647C404.952 102.647 410.098 99.862 412.278 94.8972H426.506ZM412.157 73.6452H380.975C381.641 63.7156 387.938 57.237 396.899 57.237C405.92 57.237 411.854 63.5945 412.157 73.6452Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.9312 18.3999C71.121 13.4274 73.223 8.05525 73.223 2.30158V2.30151C73.223 1.53839 73.1875 0.769158 73.1023 0C70.1836 0.151403 67.0803 1.03568 63.7994 2.63464C60.5115 4.25782 57.8001 6.29259 55.6683 8.73975C51.3982 13.573 48.9936 19.4842 48.9936 25.0078C48.9936 25.7712 49.0426 26.4976 49.1271 27.1879C55.7592 27.7269 61.8749 24.3111 66.9312 18.3999ZM89.2368 100.777C91.6229 97.3758 93.6823 93.6759 95.4222 89.6565C96.1394 87.9522 96.8141 86.1768 97.4532 84.3233C94.5061 83.0877 91.8572 81.3478 89.4854 79.0966C84.2516 74.2464 81.5886 68.1377 81.5104 60.7969C81.4252 51.3726 85.7074 44.0261 94.3641 38.781C89.528 31.9068 82.2561 28.0912 72.5768 27.31C69.0048 27.0068 64.6445 27.7766 59.4782 29.6358C54.015 31.6405 50.7924 32.6461 49.8415 32.6461C48.5697 32.6461 45.6687 31.7861 41.1508 30.0903C36.62 28.4001 32.9742 27.5401 30.1939 27.5401C25.1129 27.6246 20.3947 28.9455 16.028 31.5375C11.6613 34.1296 8.17235 37.6604 5.54979 42.1364C2.21281 47.709 0.546814 54.3588 0.546814 62.0688C0.546814 68.7996 1.78247 75.7519 4.24738 82.9314C6.54896 89.5784 9.48683 95.4087 13.0603 100.437C16.3909 105.145 19.1711 108.468 21.3938 110.407C24.8764 113.645 28.3653 115.186 31.8656 115.037C34.1672 114.959 37.1775 114.171 40.9143 112.658C44.6454 111.153 48.1336 110.407 51.3861 110.407C54.4873 110.407 57.8789 111.153 61.5696 112.658C65.2481 114.171 68.4083 114.916 71.0287 114.916C74.6788 114.831 78.0875 113.333 81.269 110.407C83.3213 108.624 85.9844 105.415 89.2368 100.777Z"
        fill="currentColor"
      />
    </svg>
  )
}
