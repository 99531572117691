import { MediaLayout } from '@etta/ui/media-layout'
import { PassportFormDatePickerFieldDesktop } from './desktop'
import { PassportFormDatePickerFieldMobile } from './mobile'
import type { LayoutProps } from './types'

export function PassportFormDatePickerField(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<PassportFormDatePickerFieldMobile {...props} />}
      desktopSlot={<PassportFormDatePickerFieldDesktop {...props} />}
    />
  )
}
