import styled from 'styled-components'

export const IconWrapper = styled.div`
  position: absolute;
  top: -44px;
  left: -22px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.mainText};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.2);

  svg {
    width: 20px;
  }
`

export const AddressLocation = styled.div`
  position: absolute;
  top: -60px;
  left: 18px;
  max-width: 130px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.mainText};
  border-radius: 2px;
  padding: 5px 10px;
`
