import { getTime, separateLabels } from '../../utils'

export const getTimeLabel = (departureDate: string, arrivalDate: string, separator: string) => {
  return separateLabels([getTime(departureDate), getTime(arrivalDate)], separator)
}

export const getDirectionLabel = (
  destinationStationName: string,
  arrivalStationName: string,
  separator: string,
) => {
  return separateLabels([destinationStationName, arrivalStationName], separator)
}
