import { createErrorClass } from '@etta/core/errors/create-error-class'

export namespace BookTripErrors {
  export const BookTripResponse = createErrorClass('Book trip failed')
  export const BookTripResponseWithMessages = createErrorClass<string[]>(
    'Book trip failed with message',
  )
  export const BookTripNotFoundResponse = createErrorClass('Book trip. Trip not found')
  export const BookTripValidationError = createErrorClass('Book trip. Validation error')
  export const UnexpectedErrorBookTrip = createErrorClass('Book trip failed')
}

export type BookTripErrorsInstances = InstanceType<
  typeof BookTripErrors[keyof typeof BookTripErrors]
>
