import React from 'react'
import type { CalendarLayoutProps } from '@etta/ui/date-picker/types'
import { CalendarDesktopContainer } from './calendar-desktop-components'

export function CalendarDesktop({
  calendarSlot,
  renderDayContents,
  renderMonthText,
}: CalendarLayoutProps) {
  return (
    <CalendarDesktopContainer>
      {React.cloneElement(calendarSlot, {
        renderDayContents,
        renderMonthText,
        daySize: 40,
      })}
    </CalendarDesktopContainer>
  )
}
