import { useTranslation } from 'react-i18next'
import type { PermissionTranslation } from '../shared'

export function useRequestNotificationsTranslation(): PermissionTranslation {
  const { t } = useTranslation()

  return {
    title: t('Permissions.Notifications.Title'),
    text: t('Permissions.Notifications.Text'),
    buttonText: t('Permissions.Notifications.ButtonText'),
  }
}
