/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useCookieContext } from '@etta/modules/cookie'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'

export function useBrandConfiguration() {
  const { getBrandConfigurationAction } = useBrandConfigurationContext()
  const { cookieStore } = useCookieContext()
  const isLoggedIn = cookieStore.isLoggedIn()

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const handleLoad = async () => {
      await getBrandConfigurationAction.getBrandConfiguration()
    }
    handleLoad()
  }, [isLoggedIn])
}
