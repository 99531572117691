import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import type { AlertMessageValueObject } from '@etta/modules/alert/core/value-objects/alert.value-object'

@Store()
export class AlertStore {
  alertModalToggle = new Toggle()

  alerts: AlertMessageValueObject[] = []

  alertsCount: number | null = null

  isError: boolean = false

  isLoading: boolean = false

  setAlerts(alerts: AlertMessageValueObject[]) {
    this.alerts = alerts
  }

  setAlertsCount(count: number | null) {
    this.alertsCount = count
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  dropState() {
    this.alerts = []
    this.alertsCount = null
    this.isError = false
    this.isLoading = false
  }
}
