import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { HotelSearchResultsStore } from './stores/hotel-search-results.store'
import { LoadHotelsAction } from './actions/load-hotels.action'
import { HotelResultsAction } from './actions/hotel-results.action'
import { HotelFiltersStore } from './stores/hotel-filters.store'
import { HotelFiltersAction } from './actions/hotel-filters.action'
import { HotelQuickFiltersStore } from './stores/quick-filters.store'
import { HotelQuickFiltersAction } from './actions/hotel-quick-filters.action'

export const { useModuleContext: useHotelSearchResultsContext } = hookContextFactory({
  hotelSearchResultsStore: HotelSearchResultsStore,
  loadHotelsAction: LoadHotelsAction,
  hotelResultsAction: HotelResultsAction,
  hotelFiltersStore: HotelFiltersStore,
  hotelFiltersAction: HotelFiltersAction,
  hotelQuickFiltersStore: HotelQuickFiltersStore,
  hotelQuickFiltersAction: HotelQuickFiltersAction,
})
