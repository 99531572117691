import styled from 'styled-components'

export const MainStationCircle = styled.div<{ isRtp: boolean }>`
  width: 14px;
  height: 14px;
  background: ${(p) => p.theme.colors.white};
  border: 2px solid ${({ theme, isRtp }) => theme.colors[isRtp ? 'bodyText' : 'borderDark']};
  border-radius: 50%;
  z-index: 2;
`

export const StationCircle = styled.div<{ isRtp: boolean }>`
  width: 10px;
  height: 10px;
  background: ${(p) => p.theme.colors.borderDark};
  border: 2px solid ${({ theme, isRtp }) => theme.colors[isRtp ? 'bodyText' : 'borderDark']};
  z-index: 2;
  border-radius: 50%;
`

export const FirstColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 14px 20fr 20fr;
  gap: 0 17px;
  padding: 10px 0;
  width: 100%;
`
