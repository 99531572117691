import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { TripStatus } from '@fiji/graphql/types'
import { ROUTES } from '@fiji/routes'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import { useRulesAndRestrictions } from '@etta/components/rules-and-restrictions'
import { useUserContext } from '@etta/modules/user'
import { useTripDetails } from './use-trip-details'
import { useEditTripName } from './use-edit-trip-name'
import { useOnNotificationReceive } from './use-on-notification-receive'
import { useDownloadETickets } from './use-download-e-tickets'
import { usePrintETicketReceipt } from './use-print-e-ticket-receipt'

const i18nBase = 'PostBooking.TripDetails'

export function useTripDetailsPage() {
  const { t } = useTranslation()
  const history = useHistory()
  const tripDetails = useTripDetails()
  const { multipleHotelProvidersStore } = useMultipleHotelProvidersContext()
  const { postBookingTripDetailsStore, postBookingTripStore } = usePostBookingContext()
  const { viewStateParams: eTicketViewStateParams, handleDownloadETicket } = useDownloadETickets(
    t(`${i18nBase}.DownloadETicketsError`),
  )
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const isTripOnHold = tripDetails.trip.type === TripStatus.OnHold

  useOnNotificationReceive()

  const { userStore } = useUserContext()
  const { userNames } = userStore
  const { travelerNames } = postBookingTripStore
  const { fullName: userFullName } = userNames
  const { travelerFullName } = travelerNames || {}
  const travelerName = travelerFullName || userFullName

  const {
    selectedDetailsToggle,
    ticketRestrictionsToggle,
    permittedStationsToggle,
    isTicketRestrictionsOpen,
    isPermittedStationsOpen,
  } = postBookingTripDetailsStore
  const { actionType, multipleHotelProvidersTripDialog } = multipleHotelProvidersStore

  const { editNameToggle, handleUpdateTripName, tripName } = useEditTripName({
    processId: tripDetails.trip.processId,
    name: tripDetails.trip.name,
  })

  const isMobileHeldTrips = isMobile && isTripOnHold
  const handleBackButtonClick = useCallback(() => {
    if (isMobileHeldTrips) {
      history.replace(ROUTES.postBooking.heldTrips)
      return
    }
    history.replace(ROUTES.postBooking.trips())
  }, [history, isMobileHeldTrips])

  function handleClickMultipleHotelProviders() {
    history.replace(ROUTES.support.main)
    multipleHotelProvidersTripDialog.handleClose()
  }

  function handleBackMultipleHotelProviders() {
    if (selectedDetailsToggle.isOpen) {
      selectedDetailsToggle.handleClose()
    }

    multipleHotelProvidersTripDialog.handleClose()
  }

  const isTrainTicketRefundable = false
  const isTrainCancelAllowed = tripDetails.trip.supportedActions.isTrainChangeAllowed

  const trainCancelModalTitle = isTrainTicketRefundable
    ? t(`${i18nBase}.CancelTrainTripModal.RefundTitle`)
    : t(`${i18nBase}.CancelTrainTripModal.CancelTitle`)

  // FIXME: Remove static train fare name when it will be available in getTrip
  const refundModalDescription = isTrainCancelAllowed
    ? t(`${i18nBase}.CancelTrainTripModal.RefundAllowedDescription`)
    : t(`${i18nBase}.CancelTrainTripModal.RefundNotAllowedDescription`, {
        fareName: 'Advanced Single',
      })

  // FIXME: Remove static train fare name when it will be available in getTrip
  const cancelModalDescription = isTrainCancelAllowed
    ? t(`${i18nBase}.CancelTrainTripModal.CancelAllowedDescription`)
    : t(`${i18nBase}.CancelTrainTripModal.CancelNotAllowedDescription`, {
        fareName: 'Advanced Single',
      })

  const getIsTripInProgress = () => {
    const startTimeOfTrip = tripDetails.trip.rollUpStartTime
    if (!startTimeOfTrip) {
      return false
    }

    const startTripDate = new Date(startTimeOfTrip)
    const presentMoment = new Date()
    return presentMoment > startTripDate
  }

  const {
    itineraryRules,
    rulesAndRestrictionsToggle,
    showRulesAndRestrictions,
  } = useRulesAndRestrictions({
    segments: tripDetails.segments,
  })

  const {
    printItineraryToggle,
    eTicketReceiptToggle,
    onOpenETicketReceipt,
    selectedETicketIndex,
    printItineraryContentRef,
    eTicketReceiptContentRef,
    printItineraryContent,
    printETicketReceipt,
  } = usePrintETicketReceipt()

  return {
    isTripOnHold,
    tripDetails,
    tripName,
    editNameToggle,
    handleUpdateTripName,
    handleBackButtonClick,
    tripEmissionsResult: tripDetails.tripEmissionsResult,
    areTripSegmentsEmissionsLoading: tripDetails.areTripSegmentsEmissionsLoading,
    trainCancelModalTitle,
    trainCancelModalDescription: isTrainTicketRefundable
      ? refundModalDescription
      : cancelModalDescription,
    isTripInProgress: getIsTripInProgress(),
    actionType,
    multipleHotelProvidersTripDialog,
    handleClickMultipleHotelProviders,
    handleBackMultipleHotelProviders,
    ticketRestrictionsToggle,
    permittedStationsToggle,
    isTicketRestrictionsOpen,
    isPermittedStationsOpen,
    eTicketViewStateParams,
    handleDownloadETicket,
    rulesAndRestrictionsToggle,
    itineraryRules,
    showRulesAndRestrictions,
    printItineraryToggle,
    eTicketReceiptToggle,
    onOpenETicketReceipt,
    selectedETicketIndex,
    travelerName,
    printItineraryContentRef,
    eTicketReceiptContentRef,
    printItineraryContent,
    printETicketReceipt,
  }
}
