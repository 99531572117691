import { observer } from 'mobx-react-lite'
import type { TripCostSummary, CalculateTripEmissionsResult } from '@fiji/graphql/types'
import { ScreenType, screenMatcher } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags/use-feature-flags'
import { getTripSegmentsEmission } from '@fiji/hooks/sustainability/get-trip-segments-emission'
import type { OopSegmentSelection } from '@etta/components/out-of-policy-modal/types'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { FlightSegmentEntity, TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentState } from '@fiji/graphql/types'
import type { UnmatchedSegment } from '@etta/components/align-dates/align-dates-modal/types'
import type { PriceChangeTypeValueObject } from '@etta/modules/air-search/core/enums/price-change-type.enum'
import type { PriceChangeValueObject } from '@etta/modules/air-search/core/value-objects/price-change.value-object'
import { FailedAlignedSegment } from '../failed-aligned-segment/failed-aligned-segment'
import type { SegmentToDisplay } from '../types'
import { AirSegmentRTP, CarRentalSegmentRTP, HotelSegmentRTP, RailSegmentRTP } from './segments'
import { Container } from './trip-content-styled'
import { EcoCheckSummary } from './eco-check-sumary'
import { RulesAndRestrictionsButton } from './rules-and-restrictions-button'

type Props = {
  segments: TripSegmentValueObject[]
  segmentsToDisplay: SegmentToDisplay[]
  onOpenOOP: () => void
  oopSelections: OopSegmentSelection
  isCheckoutActivated: boolean
  tripCost: TripCostSummary
  isTripExist: boolean
  itineraryId: string
  flightId?: string | null
  flightSegments: FlightSegmentEntity[]
  postPurchaseActions: {
    canChangeFlight: boolean
    canChangeCarRental: boolean
    canChangeHotel: boolean
    canChangeCarrier: boolean
    canChangeFlightDestination: boolean
    canChangeTrain: boolean
    canRemoveFlight: boolean
    canRemoveCarRental: boolean
    canRemoveHotel: boolean
  }
  airPriceChangeType?: PriceChangeTypeValueObject | null
  carPriceChangeType?: PriceChangeTypeValueObject | null
  isMultiCity: boolean
  airPriceChangeInfo?: PriceChangeValueObject | null
  tripEmissionsResult?: CalculateTripEmissionsResult
  areTripEmissionLoading: boolean
  mismatchedSegments: UnmatchedSegment[]
  onOpenRulesAndRestrictions: () => void
  showRulesAndRestrictions: boolean
}

export const TripContent = observer(function TripContent({
  segments,
  segmentsToDisplay,
  onOpenOOP,
  oopSelections,
  isCheckoutActivated,
  tripCost,
  itineraryId,
  isTripExist,
  flightId,
  flightSegments,
  postPurchaseActions,
  airPriceChangeType,
  carPriceChangeType,
  isMultiCity,
  airPriceChangeInfo,
  tripEmissionsResult,
  areTripEmissionLoading,
  mismatchedSegments,
  onOpenRulesAndRestrictions,
  showRulesAndRestrictions,
}: Props) {
  const { segmentTypeCheckActions } = useReviewTripContext()

  let flightIndex = 0
  const isMobile = screenMatcher.getScreenType() === ScreenType.Mobile
  const { featureFlags } = useFeatureFlags()
  const {
    getFlightEmission,
    getCarRentalEmission,
    getHotelEmission,
    getRailEmission,
  } = getTripSegmentsEmission()

  const checkIsSegmentCancellable = (
    segmentState: SegmentState | undefined | null,
    postPurchaseAction: boolean,
  ) => {
    if (!isTripExist) {
      return postPurchaseAction
    }
    return postPurchaseAction && segmentState === SegmentState.New
  }

  return (
    <>
      <Container isMobile={isMobile}>
        {segmentsToDisplay.map((segment) => {
          if (segment.type === 'FailedAlignError') {
            return (
              <FailedAlignedSegment
                key={segment.travelVertical}
                onSearchClick={segment.onSearchClick}
                travelVertical={segment.travelVertical}
                customLabel={segment.customLabel}
              />
            )
          }

          if (segment.type === 'FailedRebookError') {
            return (
              <FailedAlignedSegment
                key={segment.travelVertical}
                onSearchClick={segment.onSearchClick}
                travelVertical={segment.travelVertical}
                customLabel={segment.customLabel}
              />
            )
          }

          if (segmentTypeCheckActions.isFlightSegment(segment) && flightId) {
            const node = (
              <AirSegmentRTP
                segment={segment}
                key={segment.uuid}
                onOpenOOP={onOpenOOP}
                tripCost={tripCost}
                outOfPolicyExp={oopSelections.flight}
                isCheckoutActivated={isCheckoutActivated}
                itineraryId={itineraryId}
                flightId={flightId}
                flightSegments={flightSegments}
                isChangeAllowed={postPurchaseActions.canChangeFlight}
                isChangeCarrierAllowed={postPurchaseActions.canChangeCarrier}
                isChangeDestinationAllowed={postPurchaseActions.canChangeFlightDestination}
                isRemoveAllowed={checkIsSegmentCancellable(
                  segment.segmentState,
                  postPurchaseActions.canRemoveFlight,
                )}
                isTripExist={isTripExist}
                airPriceChangeType={airPriceChangeType}
                flightIndex={flightIndex}
                isMultiCity={isMultiCity}
                airPriceChangeInfo={airPriceChangeInfo}
                flightEmission={getFlightEmission({
                  legId: segment.legId,
                  tripEmissionsResult,
                  loading: areTripEmissionLoading,
                })}
              />
            )

            flightIndex += 1

            return node
          }

          if (segmentTypeCheckActions.isTrainSegment(segment)) {
            return (
              <RailSegmentRTP
                itineraryId={itineraryId}
                isTripExist={isTripExist}
                segment={segment}
                key={segment.uuid}
                onOpenOOP={onOpenOOP}
                isChangeAllowed={checkIsSegmentCancellable(
                  segment.segmentState,
                  postPurchaseActions.canChangeTrain,
                )}
                railEmission={getRailEmission({
                  legId: segment.legId,
                  tripEmissionsResult,
                  loading: areTripEmissionLoading,
                })}
                isRoundTrip={segments.length > 1}
              />
            )
          }

          if (segmentTypeCheckActions.isCarRentalSegment(segment) && segment.carId) {
            return (
              <CarRentalSegmentRTP
                segment={segment}
                key={segment.uuid}
                onOpenOOP={onOpenOOP}
                carRentalId={segment.carId}
                outOfPolicyExp={oopSelections.carRentals}
                isCheckoutActivated={isCheckoutActivated}
                itineraryId={itineraryId}
                isChangeAllowed={postPurchaseActions.canChangeCarRental}
                isRemoveAllowed={checkIsSegmentCancellable(
                  segment.segmentState,
                  postPurchaseActions.canRemoveCarRental,
                )}
                isMismatched={!!mismatchedSegments.find((item) => item.id === segment.uuid)}
                isTripExist={isTripExist}
                carPriceChangeType={carPriceChangeType}
                carRentalEmission={getCarRentalEmission({
                  carId: segment.carId,
                  tripEmissionsResult,
                  loading: areTripEmissionLoading,
                })}
              />
            )
          }

          if (segmentTypeCheckActions.isHotelSegment(segment) && segment.id) {
            return (
              <HotelSegmentRTP
                segment={segment}
                key={segment.uuid}
                onOpenOOP={onOpenOOP}
                outOfPolicyExp={oopSelections.hotel}
                isMismatched={!!mismatchedSegments.find((item) => item.id === segment.uuid)}
                itineraryId={itineraryId}
                roomKey={segment.id}
                isCheckoutActivated={isCheckoutActivated}
                isChangeAllowed={postPurchaseActions.canChangeHotel}
                isRemoveAllowed={checkIsSegmentCancellable(
                  segment.segmentState,
                  postPurchaseActions.canRemoveHotel,
                )}
                isTripExist={isTripExist}
                hotelEmission={getHotelEmission({
                  loading: areTripEmissionLoading,
                  hotelId: segment.hotelId,
                  tripEmissionsResult,
                })}
              />
            )
          }
        })}
      </Container>

      {featureFlags.isSustainabilityEnabled && (
        <EcoCheckSummary
          segments={segments}
          tripEmissionsResult={tripEmissionsResult}
          loading={areTripEmissionLoading}
        />
      )}

      {showRulesAndRestrictions && (
        <RulesAndRestrictionsButton onOpenRulesAndRestrictions={onOpenRulesAndRestrictions} />
      )}
    </>
  )
})
