import { css } from 'styled-components'
import { headerScrollAnimationStyles } from './animation-basic'

const opacityShow = css`
  animation-name: header-scroll-opacity-show;
  opacity: 0;
  visibility: hidden;

  @keyframes header-scroll-opacity-show {
    to {
      opacity: 1;
      visibility: visible;
    }
  }
`

const opacityHide = css`
  animation-name: header-scroll-opacity-hide;
  opacity: 1;

  @keyframes header-scroll-opacity-hide {
    to {
      opacity: 0;
    }
  }
`

type Animations = 'opacity-show' | 'opacity-hide'

export function getBasicHeaderScrollAnimation(animation: Animations) {
  return css`
    ${headerScrollAnimationStyles};
    ${() => {
      switch (animation) {
        case 'opacity-hide':
          return opacityHide
        case 'opacity-show':
          return opacityShow
      }
    }}
  `
}
