import type { LayoutProps } from '../types'
import { Segment } from '../segment'
import { StyledBlock } from './date-segment-desktop-styled'

export function DateSegmentDesktop({
  date,
  segments,
  isUpcoming,
  isOnHold,
  itineraryId,
  bookingId,
  isTripInProgress,
  onSeatMapOpen,
  flightId,
  airTickets,
}: LayoutProps) {
  return (
    <StyledBlock data-tracking-id={`segments-under-date_${date}`}>
      {segments.map((segment, index) => (
        <Segment
          key={index}
          isOnHold={isOnHold}
          isUpcoming={isUpcoming}
          segment={segment}
          onSeatMapOpen={onSeatMapOpen}
          itineraryId={itineraryId}
          bookingId={bookingId}
          flightId={flightId}
          isTripInProgress={isTripInProgress}
          airTicket={airTickets?.[index]}
        />
      ))}
    </StyledBlock>
  )
}
