import type { KeyboardEvent, MouseEvent, WheelEvent } from 'react'
import { useState, useEffect, useRef } from 'react'
import type { GroupedPhotos } from '../types'
import { THUMB_TOTAL_HEIGHT, GALLERY_BOTTOM } from './constants'

type Args = {
  maxPhotos: number
  index: number
  onChange: (i: number) => void
}

export function useLightbox({ maxPhotos, index, onChange }: Args) {
  const [visibleIndex, setVisibleIndex] = useState<number>(index || 0)
  const [galleryHeight, setGalleryHeight] = useState(window.innerHeight - GALLERY_BOTTOM)
  const thumbsInView = Math.floor(galleryHeight / THUMB_TOTAL_HEIGHT)
  const remainThumbs = galleryHeight % THUMB_TOTAL_HEIGHT
  const ref = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const handleResize = () => {
      setGalleryHeight(window.innerHeight - GALLERY_BOTTOM)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const updateActiveIndex = (index: number, e?: MouseEvent<HTMLButtonElement>) => {
    const indexWithBoundaries = (() => {
      switch (true) {
        case index < 0:
          return 0
        case index >= maxPhotos - 1:
          return maxPhotos - 1
        default:
          return index
      }
    })()

    onChange(indexWithBoundaries)
    setVisibleIndex(indexWithBoundaries)
    e?.stopPropagation()
  }

  const scrollHandler = (e: WheelEvent) => {
    const { deltaY } = e

    const incrementIndex = (i: 1 | -1) => {
      if (!visibleIndex && i < 0) {
        return 0
      }
      if (visibleIndex === maxPhotos && i > 0) {
        return maxPhotos
      }
      return visibleIndex + i
    }

    if (deltaY > 0) {
      setVisibleIndex(incrementIndex(1))
    }
    if (deltaY < 0) {
      setVisibleIndex(incrementIndex(-1))
    }
  }

  const handleKeyboardNavigation = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      updateActiveIndex(index + 1)
    }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      updateActiveIndex(index - 1)
    }
  }

  useEffect(() => {
    ref.current?.focus()
  }, [ref])

  const getMainImage = (photo?: GroupedPhotos) => {
    if (!photo) {
      return
    }
    return photo.large || photo.thumb
  }

  return {
    visibleIndex,
    thumbsInView,
    remainThumbs,
    getMainImage,
    handleKeyboardNavigation,
    updateActiveIndex,
    scrollHandler,
    ref,
  }
}
