import type { FunctionComponent, PropsWithChildren } from 'react'
import { useCallback } from 'react'
import { FocusScope } from 'react-aria'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { useEscPressed } from '@fiji/hooks/use-esc-pressed'
import { Portal } from '@etta/ui/portal'
import { usePosition } from '../../hooks/use-position'
import {
  ContainerWrapper,
  ListBody,
  FullHeightListBody,
  Anchor,
  Wrapper,
  BackgroundWrapper,
} from './outside-container-styled'
import { HeaderTitle } from './outside-container-headers/header-title'
import { HeaderSelected } from './outside-container-headers/header-selected'
import { OutsideContainerFooter } from './outside-container-footer'

type CompoundComponents = {
  HeaderTitle: typeof HeaderTitle
  HeaderSelected: typeof HeaderSelected
  Body: typeof ListBody
  Footer: typeof OutsideContainerFooter
  FullHeightBody: typeof FullHeightListBody
}

type Props = PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  className?: string
  isQuickFilterDesign?: boolean
  isFarePopupDesign?: boolean
}>

export const OutsideContainer: FunctionComponent<Props> & CompoundComponents = function ({
  isOpen,
  onClose,
  className,
  children,
  isQuickFilterDesign,
}) {
  const handleClose = useCallback(() => {
    if (isOpen) {
      onClose()
    }
  }, [isOpen, onClose])
  useEscPressed(handleClose)
  const { ref } = useClickOutside(handleClose)

  const { positionRef, viewport } = usePosition({ isOpen, popoverRef: ref })

  if (!isOpen) {
    return null
  }

  if (isQuickFilterDesign) {
    return (
      <>
        <Anchor tabIndex={-1} ref={positionRef} />
        <Portal target="#modals-container">
          <BackgroundWrapper tabIndex={-1} />
          {isOpen && (
            <Wrapper
              top={viewport.position.top}
              left={viewport.position.left}
              right={viewport.position.right}
              ref={ref}
              tabIndex={-1}
              className={className}>
              <FocusScope contain restoreFocus autoFocus>
                {children}
              </FocusScope>
            </Wrapper>
          )}
        </Portal>
      </>
    )
  }

  return (
    <ContainerWrapper tabIndex={-1} className={className} ref={ref}>
      {children}
    </ContainerWrapper>
  )
}

OutsideContainer.HeaderTitle = HeaderTitle
OutsideContainer.HeaderSelected = HeaderSelected
OutsideContainer.Footer = OutsideContainerFooter
OutsideContainer.Body = ListBody
OutsideContainer.FullHeightBody = FullHeightListBody
