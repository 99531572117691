import { observer } from 'mobx-react-lite'
import { Screen } from '@etta/ui/screen'
import { LazyContent } from '@etta/ui/lazy-content'
import { useUnusedTicketCostBreakdownContext } from '@etta/modules/unused-ticket-cost-breakdown/use-unused-ticket-cost-breakdown.context'
import { UnusedTicketBreakdownTooltipLayout } from '@etta/components/unused-ticket-breakdown-tooltip'
import { usePostBookingContext } from '@etta/modules/post-booking/interface/use-post-booking-context'
import type { LayoutProps } from '../../types'
import { Content } from './review-trip-page-mobile-styled'

export const ReviewTripPageMobile = observer(function ReviewTripPageMobile({
  isLoading,
  scrollContainerRef,
  mobileHeaderSlot,
  contentSlot,
  mobileFooterSlot,
  mobileTopContentSlot,
}: Omit<LayoutProps, 'desktopPriceSummarySlot'>) {
  const {
    unusedTicketCostBreakdownStore: { unsuedTicket, isOpen },
  } = useUnusedTicketCostBreakdownContext()
  const { postBookingTripStore } = usePostBookingContext()

  return (
    <Screen backgroundColor="background">
      <UnusedTicketBreakdownTooltipLayout
        isRTP
        isPostBooking={!!postBookingTripStore.queryParams.bookingId}
        isOpen={isOpen}
        isUnusedTicketValidated={!!unsuedTicket}
        addCollect={unsuedTicket?.addCollect}
        forfeiture={unsuedTicket?.forfeiture}
        unusedTicketPrice={unsuedTicket?.original}
        newTicketPrice={unsuedTicket?.newTicket}
        penalty={unsuedTicket?.penalty}
        unusedTicketRate={unsuedTicket?.original}
      />
      <LazyContent isLoading={isLoading}>
        <Screen.Container as={'main'} ref={scrollContainerRef}>
          <Screen.Header backgroundColor="transparent">{mobileHeaderSlot}</Screen.Header>
          {mobileTopContentSlot}
          <Content>{contentSlot}</Content>
        </Screen.Container>
        <Screen.Footer as={'footer'}>{mobileFooterSlot}</Screen.Footer>
      </LazyContent>
    </Screen>
  )
})
