import { useTranslation } from 'react-i18next'
import type { TripStateStatus } from '../types'

export const useStatusTitle = (tripStateStatus: TripStateStatus): string | undefined => {
  const { t } = useTranslation()

  const i18nBasePath = 'TripStatus'

  switch (tripStateStatus) {
    default:
    case 'loading':
      return t(i18nBasePath + '.AddingSelection')
    case 'error':
      return t(i18nBasePath + '.Error')
    case 'success':
      return t(i18nBasePath + '.AddedSelection')
    case 'loading-with-empty-text':
      return ' '
    case 'loading-without-messages':
      return undefined
  }
}
