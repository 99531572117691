import styled from 'styled-components'
import { subHeadMedium, headline } from '@fiji/style'

export const Title = styled.h6`
  margin: 0;
  color: ${(p) => p.theme.colors.mainText};
  ${headline}
`

export const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.borderDark};
  padding: 16px;
  display: flex;
  justify-content: flex-end;
`

export const Container = styled.div`
  padding: 22px 20px;
`

export const Description = styled.span`
  ${subHeadMedium}
  color: ${(p) => p.theme.colors.bodyText};
`
