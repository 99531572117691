import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@fiji/graphql/types'
import { RTPPriceSummarySkeleton } from '@etta/components/rtp-price-summary/rtp-price-summary-skeleton'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { ScreenDesktop } from '@etta/ui/screen'
import { DesktopNavigation } from '@etta/modules/navigation'
import { ROUTES } from '@fiji/routes'
import { DynamicSiteMessagesSkeleton } from '@etta/components/dynamic-site-messages-skeleton'
import { RtpItineraryCardSkeleton } from '@etta/components/rtp-itinerary-card-skeleton'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { AddButton } from '../../add-button'
import {
  Container,
  ButtonsRow,
  DsmWrapper,
  List,
  Container1,
  Container2,
} from './rtp-book-trip-again-fail-state-desktop-styled'

export function RtpBookTripAgainFailStateDesktop() {
  const { t } = useTranslation()
  const i18nBase = 'TripReview.BookAgain.'
  const history = useHistory()
  const navigateToEditSearch = useCallback(() => history.push(ROUTES.explore), [history])

  return (
    <>
      <ScreenDesktop>
        <ScreenDesktop.Header>
          <DesktopNavigation isTripCartDisplayed={false} />
        </ScreenDesktop.Header>
        <Container2>
          <Block paddingLeft={16} paddingBottom={24}>
            <Text variant="title2">{t('TripReview.Header.ReviewYourTrip')}</Text>
          </Block>
          <Container1>
            <Container>
              <ButtonsRow>
                <AddButton type={SegmentType.Flight} isDisabled />
                <AddButton type={SegmentType.Hotel} isDisabled />
                <AddButton type={SegmentType.CarRental} isDisabled />
              </ButtonsRow>
              <DsmWrapper>
                <DynamicSiteMessagesSkeleton />
              </DsmWrapper>
              <List>
                <>
                  <RtpItineraryCardSkeleton />
                  <RtpItineraryCardSkeleton />
                </>
              </List>
            </Container>
            <Block marginLeft={40}>
              <RTPPriceSummarySkeleton />
            </Block>
          </Container1>
        </Container2>
      </ScreenDesktop>
      <ConfirmationDialog
        isOpen={true}
        title={t(i18nBase + 'SameServicesNotAvailable')}
        content={t(i18nBase + 'PreviousItineraryNotAvailable')}
        confirmButtonText={t(i18nBase + 'StartWithNewSearch')}
        onConfirm={navigateToEditSearch}
        onDecline={() => {}}
      />
    </>
  )
}
