import type { LoyaltyProgram } from '@fiji/graphql/types'
import type { OptionType } from '@etta/ui/select'

type Props = {
  options: OptionType<string>[]
  value: LoyaltyProgram[] | null
  fromProfile?: LoyaltyProgram[] | null
  onUpdate: (data: LoyaltyProgram[]) => void
}

export function useLoyalty({ value, fromProfile, options, onUpdate }: Props) {
  const onNumberUpdate = (number: string, index: number, membership: LoyaltyProgram) => {
    if (!value) {
      return
    }

    const res = value.map((el, i) =>
      i === index
        ? {
            vendorCode: membership.vendorCode,
            number,
            label: options.find((el) => el.value === membership.vendorCode)?.label,
          }
        : el,
    )
    onUpdate(res)
  }

  const onVendorUpdate = (vendorCode: string, index: number) => {
    if (!value) {
      return
    }

    const vendorInProfile = fromProfile?.find((membership) => membership.vendorCode === vendorCode)
    const isVendorHasNumber = !!vendorInProfile?.number

    if (isVendorHasNumber) {
      const res = value.map((el, i) => (i === index ? vendorInProfile : el))
      onUpdate(res)
      return
    }

    const res = value.map((el, i) =>
      i === index
        ? {
            vendorCode: vendorCode!,
            number: fromProfile?.find((membership) => membership.vendorCode === vendorCode)?.number,
            label: options.find((el) => el.value === vendorCode)?.label,
          }
        : el,
    )
    onUpdate(res)
  }

  return {
    onNumberUpdate,
    onVendorUpdate,
  }
}
