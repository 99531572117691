import type { GetDisplayConfigurationQuery } from '@fiji/graphql/types'
import type { DynamicSiteMessageValueObject } from '@etta/core/value-objects'
import type { DisplayConfigurationValueObject } from '../../core/value-objects'
import { classTypeMapper } from './class-type.mapper'

export class DisplayConfigurationMapper {
  static toValueObject(
    displayConfiguration: GetDisplayConfigurationQuery['displayConfiguration'],
  ): DisplayConfigurationValueObject {
    return {
      isAdminRoleEnabled: displayConfiguration.isAdminRoleEnabled || undefined,
      isMod2FlowEnabled: displayConfiguration.isMod2FlowEnabled,
      isFlightSearchServiceEnabled: displayConfiguration.isFlightSearchServiceEnabled,
      isPhoenixHotelSearchEnabled: !!displayConfiguration.isPhoenixHotelSearchEnabled,
      isPhoenixCarRentalSearchEnabled: !!displayConfiguration.isPhoenixCarRentalSearchEnabled,
      isTripAdvisorReviewsEnabled: displayConfiguration.isTripAdvisorReviewsEnabled,
      isExpiringCreditCardForHotelAllowed: displayConfiguration.isExpiringCreditCardForHotelAllowed,
      isCreditCardSavingEnabled: displayConfiguration.isCreditCardSavingEnabled,
      isAppleLockSettingsEnabled: displayConfiguration.isAppleLockSettingsEnabled,
      appleDsmMessage: displayConfiguration.appleDsmMessage || undefined,
      maxNumberOfGuestsPerRoom: displayConfiguration.maxNumberOfGuestsPerRoom,
      mapProvider: displayConfiguration.mapProvider,
      isPerDiemDisplayEnabled: displayConfiguration.isPerDiemDisplayEnabled,
      isStarRatingsFilterEnabled: displayConfiguration.isStarRatingsFilterEnabled,
      geosureReport: displayConfiguration.geosureReport,
      optinoutOfUnusedTicket: displayConfiguration.optinoutOfUnusedTicket,
      hotelSearchRadius: displayConfiguration.hotelSearchRadius,
      isHoldTripAllowed: displayConfiguration.isHoldTripAllowed,
      isSoldOutFilterAvailable: displayConfiguration.isSoldOutFilterAvailable || undefined,
      enableNearByAirport: !!displayConfiguration.enableNearByAirport,
      explore: {
        isCarRentalsSearchEnabled: displayConfiguration.explore.isCarRentalsSearchEnabled,
        isFlightsSearchEnabled: displayConfiguration.explore.isFlightsSearchEnabled,
        isHotelsSearchEnabled: displayConfiguration.explore.isHotelsSearchEnabled,
        isTrainsSearchEnabled: displayConfiguration.explore.isTrainsSearchEnabled,
        messages: displayConfiguration.explore.messages.map(
          (messageItem): DynamicSiteMessageValueObject => ({
            header: messageItem.header,
            isDialog: messageItem.isDialog,
            text: messageItem.text,
            hasDesktopBannerImage: messageItem.hasDesktopBannerImage,
          }),
        ),
        searchDefaultsAirEnabled: displayConfiguration.explore.searchDefaultsAirEnabled,
        searchDefaultsCarEnabled: displayConfiguration.explore.searchDefaultsCarEnabled,
        searchDefaultsHotelEnabled: displayConfiguration.explore.searchDefaultsHotelEnabled,
        message: displayConfiguration.explore.message,
      },
      flights: displayConfiguration.flights,
      carRentals: displayConfiguration.carRentals,
      hotels: displayConfiguration.hotels,
      privacyPolicy: displayConfiguration.privacyPolicy,
      isPreBookCostAllocationEnabled: displayConfiguration.isPreBookCostAllocationEnabled,
      flightSearchTimeRange: {
        before: displayConfiguration.flightSearchTimeRange?.before || undefined,
        after: displayConfiguration.flightSearchTimeRange?.after || undefined,
      },
      support: displayConfiguration.support,
      flightSearchTimeRangeNonUS: {
        before: displayConfiguration.flightSearchTimeRangeNonUS?.before || undefined,
        after: displayConfiguration.flightSearchTimeRangeNonUS?.after || undefined,
      },
      hideUnusedTickets: displayConfiguration.hideUnusedTickets,
      defaultCarSize: classTypeMapper(displayConfiguration?.defaultCarSize),
      isMod2DoubleWriteEnabled: displayConfiguration.isMod2DoubleWriteEnabled,
      isShowTravelerAcknowledgement: displayConfiguration.isShowTravelerAcknowledgement,
      externalLinks: displayConfiguration.externalLinks,
      bookAndChangeAdvanceTime: displayConfiguration.bookAndChangeAdvanceTime,
      isSouthwestDirectConnectionEnabled: displayConfiguration.isSouthwestDirectConnectionEnabled,
      companyResourceConfiguration: displayConfiguration.companyResourceConfiguration,
      selfDelegation: displayConfiguration.selfDelegation || undefined,
      userDelegation: displayConfiguration.userDelegation || undefined,
      serviceFeeConfiguration: displayConfiguration.serviceFeeConfiguration,
    }
  }
}
