import type { LayoutProps } from '../types'
import { default as Background } from '../../background.svg'
import { Description } from '../../trip-no-content-styles'
import { DescriptionContent } from '../../description-content'
import { BookATripButton, Wrapper } from './trip-no-content-desktop-styled'

export function TripNoContentDesktop({
  descriptionAriaLabel,
  tripTypeTranslation,
  tripsTranslation,
  noContentInfoTranslation,
  buttonLabel,
  buttonAriaLabelCaption,
  onButtonClick,
}: LayoutProps) {
  return (
    <Wrapper>
      <Background />
      <Description role="heading" aria-level={2} aria-label={descriptionAriaLabel}>
        <DescriptionContent
          noContentInfoTranslation={noContentInfoTranslation}
          tripTypeTranslation={tripTypeTranslation}
          tripsTranslation={tripsTranslation}
        />
      </Description>
      <BookATripButton onClick={onButtonClick} aria-label={buttonAriaLabelCaption}>
        {buttonLabel}
      </BookATripButton>
    </Wrapper>
  )
}
