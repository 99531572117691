import { differenceInSeconds } from 'date-fns'
import { ceilNumberIgnoringSign } from '../ceil-number-ignoring-sign'

const SECONDS_IN_DAY = 60 * 60 * 24
export function getDifferenceInDays(firstDate: Date, secondDate: Date) {
  const seconds = differenceInSeconds(firstDate, secondDate)
  const days = seconds / SECONDS_IN_DAY

  return ceilNumberIgnoringSign(days)
}
