import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { useTravelSupportContext } from '../interface/use-travel-support.context'

import {
  PreviewWrapper,
  PreviewContainer,
  TravelSupportHeader,
  TravelSupportHeaderTitle,
} from './travel-support-styled'
import { TravelSupportBody } from './travel-support-body'

export const TravelSupport = observer(() => {
  const { t } = useTranslation()
  const i18Base = 'TripPlanner.Support'
  const { travelSupportStore } = useTravelSupportContext()
  return (
    <PreviewWrapper aria-labelledby="travel-support-text">
      <PreviewContainer>
        <TravelSupportHeader>
          <TravelSupportHeaderTitle
            textVariant={StyledTextVariant.title2}
            id="travel-support-text"
            data-tracking-id="travel-support-text">
            {t(i18Base + '.MainPage.TravelSupport')}
          </TravelSupportHeaderTitle>
        </TravelSupportHeader>
        <TravelSupportBody supportArticles={travelSupportStore.supportArticles} />
      </PreviewContainer>
    </PreviewWrapper>
  )
})
