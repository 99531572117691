import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

let temporaryState: Record<string, Set<string>> = {}

type Args = {
  initialState: string[]
}

export function useStateView({ initialState }: Args) {
  const { location } = useHistory()
  const [idList, setIdList] = useState(temporaryState[location.pathname] || new Set(initialState))
  const initialStateLength = initialState.length

  useEffect(() => {
    if (initialStateLength) {
      setIdList(temporaryState[location.pathname] || new Set(initialState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStateLength])

  const handleRemoveItem = (value: string) => {
    setIdList((prevState) => {
      const nextState = new Set(prevState)
      nextState.delete(value)

      temporaryState = {
        ...temporaryState,
        [location.pathname]: nextState,
      }

      return nextState
    })
  }

  return {
    idList,
    handleRemoveItem,
  }
}
