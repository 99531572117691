import { useTranslation } from 'react-i18next'
import { deleteTimezone } from '@fiji/utils/dates'
import { getSubtitleDate } from './get-subtitle-date'
import type { Props } from './type'

const i18nBase = 'PostBooking.TrainDetails'

export function useHeadline({ train }: Props) {
  const { t } = useTranslation()

  const { segments } = train
  const segmentsNumber = segments?.length

  const lastSegment = segments?.[segments?.length - 1]

  const originDate = segments?.[0].departureDate
    ? new Date(deleteTimezone(segments?.[0].departureDate))
    : undefined

  const destinationDate = lastSegment?.arrivalDate
    ? new Date(deleteTimezone(lastSegment?.arrivalDate))
    : undefined

  const headLineDate = getSubtitleDate({ originDate, destinationDate })
  const hours = train.totalJourneyTime?.hours
    ? t(i18nBase + '.Headline.Hours', { hours: train.totalJourneyTime?.hours })
    : undefined

  const minutes = train.totalJourneyTime?.minutes
    ? t(i18nBase + '.Headline.Minutes', { minutes: train.totalJourneyTime?.minutes })
    : undefined

  const totalTime = [hours, minutes].join(' ')

  const stops =
    segmentsNumber && segmentsNumber > 1
      ? t(i18nBase + '.Headline.Changes', { count: segmentsNumber - 1 })
      : undefined
  const headLineSubTitle = [headLineDate, totalTime, stops].filter(Boolean).join(' · ')

  const arrivalCountryName = lastSegment?.stations?.arrival?.countryName
  const stationName = lastSegment?.stations?.arrival?.stationName
  const arrivalPlace = lastSegment?.stations?.arrival?.stationName + ', ' + arrivalCountryName

  const ariaLabel = `${t(i18nBase + '.TrainTo')} ${arrivalPlace}`
  return { headLineSubTitle, ariaLabel, arrivalCountryName, stationName }
}
