import { Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import type { BookRideHailErrorReason } from '@fiji/graphql/hooks'
import type { RideHailBookingValueObject } from '../../core/value-objects'
import type { BookRideHailQueryParamsType } from '../types'
import {
  mapBookRideHailErrorReasonToErrorMessage,
  mapBookRideHailErrorReasonToIsShowUberExternalLink,
} from './map-book-ride-hail-error'

@Store()
export class RideHailBookStore {
  bookQueryParams = new QueryParams<BookRideHailQueryParamsType>({}, { caseStyle: 'kebab-case' })

  booking: RideHailBookingValueObject | null = null
  isLoading: boolean = false
  isError: boolean = false
  errorMessage: string | null = null
  isShowUberExternalLink: boolean = true

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setErrorMessage(error: BookRideHailErrorReason | null) {
    const errorMessage = mapBookRideHailErrorReasonToErrorMessage(error)
    this.errorMessage = errorMessage ?? null
  }

  setIsShowUberExternalLink(error: BookRideHailErrorReason | null) {
    this.isShowUberExternalLink = mapBookRideHailErrorReasonToIsShowUberExternalLink(error)
  }

  setBooking(booking: RideHailBookingValueObject) {
    this.booking = booking
  }

  dropStore() {
    this.isError = false
    this.isLoading = false
    this.booking = null
    this.errorMessage = null
  }
}
