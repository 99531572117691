import { useMemo } from 'react'
import type { Props } from './types'

export function useMobilitySelectLocationModal({ date, time }: Props) {
  const dateTime = useMemo(() => {
    if (date && time) {
      return { date: date, time: time }
    }
    return { date: undefined, time: undefined }
  }, [date, time])

  return {
    dateTime,
  }
}
