import type { PropsWithChildren, ReactNode } from 'react'
import { appMode } from '@fiji/modes'
import { FijiAuth } from './fiji-auth'
import { EttaAuth } from './etta-auth'

export function AppAuth({
  children,
  loaderSlot,
  previousPath,
}: PropsWithChildren<{ loaderSlot: ReactNode; previousPath: string }>) {
  if (appMode.isFijiAll) {
    return <FijiAuth loaderSlot={loaderSlot}>{children}</FijiAuth>
  }

  return (
    <EttaAuth loaderSlot={loaderSlot} previousPath={previousPath}>
      {children}
    </EttaAuth>
  )
}
