import { useTranslation } from 'react-i18next'
import type { Props } from '../../types'
import { TechnicalStopLabel } from '../components/technical-stop-label'
import { Details } from './components/details'
import { OperatedBy } from './components/operated-by'
import { DepartureArrival } from './components/departure-arrival'
import { Container } from './collapsed-view-desktop-styled'

export function CollapsedViewDesktop({ flight, daysInBetween }: Props) {
  const { t } = useTranslation()

  const { adaptedSegments, totalFlightDuration, flightStopsLabel } = flight
  const firstSegment = adaptedSegments[0]
  const lastSegment = adaptedSegments[adaptedSegments.length - 1]

  return (
    <Container
      aria-label={t('AirSearchResults.AirSearchSegmentDetails', {
        originAirport: firstSegment.departureAirportCode,
        originTime: firstSegment.departureTime,
        destinationAirport: lastSegment.departureAirportCode,
        destinationTime: lastSegment.departureTime,
        duration: totalFlightDuration,
        stops: flightStopsLabel,
      })}>
      <DepartureArrival flight={flight} daysInBetween={daysInBetween} />

      <OperatedBy flight={flight} />

      <Details flight={flight} />

      {flight.hasTechnicalStop && <TechnicalStopLabel isFullWidth={false} />}
    </Container>
  )
}
