import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import type { EttaMapEntity } from '@etta/modules/etta-map/core/etta-map.entity'
import type { Bounds } from '@etta/modules/etta-map/core/bounds'
import { convertGoogleMapRadiusToZoom } from '@fiji/utils/map'
import { parseDistance } from '@fiji/utils/distance'
import type { Location } from './types'

type Props = {
  distance?: string | number
  locations: Location[]
  boundsDistanceMeters?: number
  center: Location
  onBoundsLeave: (newCenter: Location) => void
  onBoundsReturn: () => void
}

const ON_BOUNDS_CHANGED_DELAY = 100

export function useMapLocations({
  locations,
  onBoundsLeave,
  onBoundsReturn,
  boundsDistanceMeters,
  distance,
  center,
}: Props) {
  const mapRef = useRef<EttaMapEntity | null>(null)
  const boundsRef = useRef<Bounds | null>(null)

  const defaultZoom = useMemo(() => convertGoogleMapRadiusToZoom(parseDistance(distance)), [
    distance,
  ])

  const onBoundsChange = useDebouncedCallback(() => {
    const map = mapRef.current
    if (!map) {
      return
    }
    if (!boundsRef.current) {
      return
    }
    const isOutOfRange = map.checkOutOfRange({
      boundsDistanceMeters,
      mapCenter: map.getCenter(),
      boundsCenter: boundsRef.current.center,
    })
    if (!isOutOfRange) {
      onBoundsReturn()
      return
    }

    const centerPoint = map.getCenter()

    if (map.checkIsCoordinatesEqual(centerPoint, center)) {
      return
    }

    onBoundsLeave(centerPoint)
  }, ON_BOUNDS_CHANGED_DELAY)

  const updateBounds = useCallback(() => {
    if (!mapRef.current) {
      return
    }
    if (!locations.length) {
      return
    }

    const bounds = mapRef.current.createBoundsByCoordinates(locations)

    boundsRef.current = bounds
    return bounds
  }, [locations])

  const onMapLoaded = (map: EttaMapEntity) => {
    mapRef.current = map
    const bounds = updateBounds()
    if (bounds && locations.length) {
      mapRef.current.setBounds(bounds)
    }
    mapRef.current.onBoundsChange(onBoundsChange)
  }

  useEffect(() => {
    if (!locations.length) {
      return
    }
    updateBounds()
  }, [locations, updateBounds])

  return {
    defaultZoom,
    onMapLoaded,
  }
}
