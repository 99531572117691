import { useTranslation } from 'react-i18next'
import { Skeleton } from '@etta/ui/skeleton'
import { Button } from '@etta/ui/button'
import { TravelerExcerpt } from '@etta/components/traveler-excerpt/traveler'
import { CityBackground } from '@etta/components/city-background/city-background'
import {
  Container,
  Wrap,
  TripHeaderContainer,
  FooterCheckoutContainer,
  PayableSectionsContainer,
} from './rtp-price-summary-skeleton-styled'

type Props = {
  cityImageUrl?: string | null
}

export function RTPPriceSummarySkeleton({ cityImageUrl }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'ReviewTrip'

  return (
    <Container>
      <CityBackground cityImageUrl={cityImageUrl} />

      <Wrap>
        <TripHeaderContainer>
          <Skeleton width="100%" height="50px">
            <rect y="6" width="160px" height="14px" />
            <rect y="6" x="244" width="28px" height="14px" />
            <rect y="36" width="134px" height="14px" />
          </Skeleton>
        </TripHeaderContainer>

        <TravelerExcerpt />

        <PayableSectionsContainer>
          <Skeleton width="100%" height="130px">
            <rect width="69px" height="14px" />
            <rect y="27" width="101px" height="14px" />
            <rect y="27" x="183" width="91px" height="14px" />
            <rect y="65" width="60px" height="14px" />
            <rect y="90" width="95px" height="14px" />
            <rect y="90" x="189" width="87px" height="14px" />
            <rect y="115" width="126px" height="14px" />
            <rect y="115" x="210" width="66px" height="14px" />
          </Skeleton>
        </PayableSectionsContainer>

        <FooterCheckoutContainer>
          <Skeleton width="100%" height="28px">
            <rect width="101px" height="14px" />
            <rect x="194" width="79px" height="14px" />
          </Skeleton>

          <Button disabled fullWidth>
            {t(i18nBase + '.Checkout')}
          </Button>
        </FooterCheckoutContainer>
      </Wrap>
    </Container>
  )
}
