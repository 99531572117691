import { MediaLayout } from '@etta/ui/media-layout'
import type { RailSearchSegmentDetailsProps } from '../types'
import { RailSearchSegmentDetailsDesktop } from './desktop/rail-search-segment-details-desktop'
import { RailSearchSegmentDetailsMobile } from './mobile/rail-search-segment-details-mobile'

export function RailSearchSegmentDetailsLayout(props: RailSearchSegmentDetailsProps) {
  return (
    <MediaLayout
      mobileSlot={<RailSearchSegmentDetailsMobile {...props} />}
      desktopSlot={<RailSearchSegmentDetailsDesktop {...props} />}
    />
  )
}
