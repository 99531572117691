import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from './types'
import { CarFooterBlockDesktop, CarFooterBlockMobile } from './layout'

export function CarFooterBlock(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<CarFooterBlockMobile {...props} />}
      desktopSlot={<CarFooterBlockDesktop {...props} />}
    />
  )
}
