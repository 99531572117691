import type { RideHailFlightDetails } from '@fiji/graphql/hooks'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { RideHailAirportPickupFlightValueObject } from '../../core/value-objects'

export function buildRideHailFlightDetails(
  rideHailAirportPickupFlight: Omit<RideHailAirportPickupFlightValueObject, 'imageUrl'>,
): RideHailFlightDetails {
  return {
    airportCode: rideHailAirportPickupFlight.arrivalAirportCode,
    designatorCode: rideHailAirportPickupFlight.flightDesignator,
    arrivalDate: getFormattedTime(new Date(rideHailAirportPickupFlight.arrivalDate), 'yyyy-MM-dd'),
    airlineName: rideHailAirportPickupFlight.airlineName,
  }
}
