import { SegmentType } from '@fiji/graphql/types'

export function getPreviousCostMsg(type: SegmentType) {
  switch (type) {
    case SegmentType.Hotel:
      return 'PreviousHotelCost'
    case SegmentType.CarRental:
      return 'PreviousCarRentalCost'
    case SegmentType.Flight:
      return 'PreviousFlightCost'
    default:
      return ''
  }
}
