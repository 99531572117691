import type { Maybe } from 'fnscript'
import { Just, Nothing } from 'fnscript'
import type { SearchFlightLeg } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'

export function maybeGetOriginAirportCode(
  flightList: SearchFlightLeg[],
  place?: Place,
): Maybe<string> {
  if (place?.airportCode) {
    return Just(place?.airportCode)
  }

  if (flightList.length === 0) {
    return Nothing()
  }

  const firstFlightOption = flightList[0]

  return Just(firstFlightOption.segments[0].origin.code)
}

export function maybeGetDestinationAirportCode(
  flightList: SearchFlightLeg[],
  place?: Place,
): Maybe<string> {
  if (place?.airportCode) {
    return Just(place?.airportCode)
  }

  if (flightList.length === 0) {
    return Nothing()
  }

  const firstFlightOption = flightList[0]

  const flightLastSegmentIndex = firstFlightOption.segments.length - 1

  return Just(firstFlightOption.segments[flightLastSegmentIndex].destination.code)
}
