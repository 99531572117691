/* eslint-disable react-hooks/exhaustive-deps */
import type { MutableRefObject } from 'react'
import { useEffect, useState } from 'react'
import type { MenuPosition } from '../types'

export function useGetPosition(ref: MutableRefObject<HTMLButtonElement | null>) {
  const [position, setPosition] = useState<MenuPosition>('left')

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const screenWidth = document.body.offsetWidth
    const { x } = ref.current.getBoundingClientRect()

    if (x > screenWidth / 2) {
      setPosition('right')
    } else {
      setPosition('left')
    }
  }, [])

  return { position }
}
