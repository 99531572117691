import { createGlobalStyle } from 'styled-components'
import '@fortawesome/fontawesome-free/css/all.css'
import { keyboardNavigationStyled } from './styles/keyboard-navigation-styled'
import { bodyStyled } from './styles/body-styled'
import { mediaStyled } from './styles/media-styled'
import { deemHeaderStyled } from './styles/deem-header-styled'
import { deemFooterStyled } from './styles/deem-footer-styled'
import { reactBnbGalleryStyled } from './styles/react-bnb-gallery-styled'
import { destygoStyled } from './styles/destygo-styled'
import { resetStyled } from './styles/reset-styled'
import { dimensionsStyled } from './styles/dimensions-styled'
import { annotationStyled } from './styles/annotations-styled'
import { preformattedTextStyled } from './styles/preformatted-text-styled'

export const GlobalStyle = createGlobalStyle<{
  bodyMargin?: string
  isKeyboardNavigationStylingEnabled: boolean
}>`

  ${resetStyled}

  ${bodyStyled};

  ${dimensionsStyled}

  ${annotationStyled}

  ${mediaStyled};

  ${deemHeaderStyled};

  ${deemFooterStyled};

  ${reactBnbGalleryStyled};

  ${destygoStyled};

  ${preformattedTextStyled};

  ${(p) => p.isKeyboardNavigationStylingEnabled && keyboardNavigationStyled}

`
