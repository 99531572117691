import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const OperatorLogoWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export const OperatorInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const OperatorNameWrapper = styled(Text)`
  margin-left: 12px;
`

export const TrainNumberWrapper = styled(Text)`
  margin-left: auto;
  margin-right: 0;
`
