import styled from 'styled-components'
import { footnoteMedium, footnoteStrong, headline, subHeadStrong, screenSize } from '@fiji/style'

export const SubTitle = styled.div<{ marginBottom?: number }>`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText};
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0')};

  & > span {
    margin-right: 2px;
  }
`

export const Title = styled.div`
  color: ${(props) => props.theme.colors.mainText};
  display: flex;
  align-items: center;

  ${headline}

  @media (${screenSize.maxMobile}) {
    ${subHeadStrong};
  }
`

export const VendorTitle = styled.div<{ marginBottom?: number }>`
  color: ${(props) => props.theme.colors.mainText};
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '12px')};
  text-transform: capitalize;

  ${subHeadStrong}

  @media (${screenSize.maxMobile}) {
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '2px')};

    ${footnoteStrong};
  }
`
