import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import { CarRentalDesktopDetails } from './layout/desktop'
import { CarRentalMobileDetails } from './layout/mobile'
import type { LayoutProps } from './types'

export const CarRentalDetails = observer(function CarRentalDetails(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<CarRentalMobileDetails {...props} />}
      desktopSlot={<CarRentalDesktopDetails {...props} />}
    />
  )
})
