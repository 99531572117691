import styled, { css } from 'styled-components'

export const Container = styled.div<{ withBottomBorder: boolean }>`
  flex-direction: column;
  display: flex;
  position: relative;
  ${(p) =>
    p.withBottomBorder &&
    css`
      border-bottom: 1px solid ${(p) => p.theme.colors.borderDark};
    `};
`

export const List = styled.div`
  display: flex;
  align-items: stretch;
  padding-top: 2px;
`

export const Indicator = styled.div<{
  width: string
  currentIndex: number
}>`
  position: absolute;
  transition: transform 200ms ease;
  transform: translateX(${(p) => p.currentIndex * 100}%);
  bottom: -1px;
  width: ${(p) => p.width};
  height: 2px;
  background-color: ${(p) => p.theme.colors.primary};
`
