import type { GetTravelPolicyQuery} from '@fiji/graphql/types';
import { SystemBehaviorType } from '@fiji/graphql/types'
import type { TravelPolicyValueObject } from '../../core/value-objects'
import { SystemBehaviorTypeEnum } from '../../core/enums'

const mapSystemBehaviorType = {
  [SystemBehaviorType.CollectCompInfo]: SystemBehaviorTypeEnum.CollectCompInfo,
  [SystemBehaviorType.UserCompExplain]: SystemBehaviorTypeEnum.UserCompExplain,
  [SystemBehaviorType.PermForNonComp]: SystemBehaviorTypeEnum.PermForNonComp,
  [SystemBehaviorType.ReselectCompTrip]: SystemBehaviorTypeEnum.ReselectCompTrip,
  [SystemBehaviorType.ValidatePreApproval]: SystemBehaviorTypeEnum.ValidatePreApproval,
}

export class TravelPolicyMapper {
  static toValueObject(
    travelPolicy: GetTravelPolicyQuery['travelPolicy'],
  ): TravelPolicyValueObject {
    return {
      purposeLabel: travelPolicy.purposeLabel,
      isPurposeRequired: travelPolicy.isPurposeRequired,
      systemBehaviorType: travelPolicy.systemBehaviorType
        ? mapSystemBehaviorType[travelPolicy.systemBehaviorType]
        : undefined,
      minAdvancePurchaseDays: travelPolicy.minAdvancePurchaseDays,
    }
  }
}
