import i18n from 'i18next'
import type { FlightLegValueObject } from '../../core/value-objects'

const i18nSeatTranslation = 'AirSeatMap.SeatTitle.NoFeature'

export function getBookedSeatsFromFlightLegs(legs: FlightLegValueObject[]) {
  return legs
    .map(({ segments }) =>
      segments
        .filter((segment) => Boolean(segment.travelerSeat))
        .map((segment) => ({
          number: segment?.travelerSeat || '',
          segmentId: segment?.id || '',
          cabinClass: '',
          type: '',
          typeText: '',
          feature: segment?.travelerSeat
            ? `${i18n.t(i18nSeatTranslation)} ${segment.travelerSeat}`
            : '',
        })),
    )
    .flat()
}
