import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { DeleteButton } from './close-button.styled'

type Props = {
  onClick: () => void
  index: number
}

const i18Base = 'TripPlanner.BaseSearch'
export function CloseButton({ onClick, index }: Props) {
  const { t } = useTranslation()

  return (
    <DeleteButton onClick={onClick} aria-label={t(i18Base + '.Input.RemoveFlightLabel', { index })}>
      <Icon name="closePWA" color="mainText" size="medium" />
    </DeleteButton>
  )
}
