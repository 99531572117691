import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 32px;
  width: 100%;
`

const ListContainer = css`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 16px;
  }
  >: first-child {
    margin-top: 6px;
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 8px 0 8px 0;
  padding: 0;
  gap: 10px;
`

export const Footer = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
`

export const RightBlock = styled.div`
  margin-left: 10%;
`

export const SkeletonContainer = styled.div`
  ${ListContainer};
  margin-bottom: 24px;
`
