import { useTranslation } from 'react-i18next'
import { Container, EmptyResultText } from './empty-result-state-styled'

export function EmptyResultState() {
  const { t } = useTranslation()
  const i18nBase = 'PurchasePaymentInformationForm'

  return (
    <Container>
      <EmptyResultText>{t(`${i18nBase}.NoResultsFound`)}</EmptyResultText>
      <EmptyResultText>{t(`${i18nBase}.SearchWithOtherKeywords`)}</EmptyResultText>
    </Container>
  )
}
