import { useTranslation } from 'react-i18next'
import { ShowMore } from '@etta/ui/show-more'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { SpecialRequestItem } from '../../../special-request-item'
import type { LayoutProps } from '../../types'
import { Container } from './special-request-options-mobile-styled'

const SLICE_SORTED_REQUESTS = 5

export function SpecialRequestOptionsMobile({ values, options, onChange }: LayoutProps) {
  const { t } = useTranslation()

  return (
    <>
      <ShowMore
        notExpandedContent={
          <Container>
            <SpecialRequestItem
              options={options.slice(0, SLICE_SORTED_REQUESTS)}
              onChange={onChange}
              values={values}
            />
          </Container>
        }
        expandedContent={
          <Container>
            <SpecialRequestItem options={options} onChange={onChange} values={values} />
          </Container>
        }
        renderButton={(isExpanded, handleButtonClick) => (
          <Block marginTop={8}>
            <Button variant="text" onClick={handleButtonClick}>
              {t(`ExpandButton.${isExpanded ? 'ShowLess' : 'ShowMore'}`)}
            </Button>
          </Block>
        )}
      />
    </>
  )
}
