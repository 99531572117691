import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { RailLegType } from '@fiji/enums'
import { Text } from '@etta/ui/text'
import { ScreenType, useScreenType } from '@fiji/modes'
import { dateFormat, deleteTimezone } from '@fiji/utils/dates'
import { RailLegDateWrapper } from './rail-leg-date-header-styled'

type Props = {
  railLegType: RailLegType
  legDepartureDate: string
}

const i18nBase = 'SearchSegments.Trains.DateHeaderLabel'

export const RailLegDateHeader = observer(function RailLegDateHeader({
  railLegType,
  legDepartureDate,
}: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  return (
    <RailLegDateWrapper isMobile={isMobile}>
      <Text variant="subHeadStrong" color="mainText">
        {`${
          railLegType === RailLegType.Departure
            ? t(i18nBase + '.Departure')
            : t(i18nBase + '.Return')
        } ${dateFormat(new Date(deleteTimezone(legDepartureDate)), 'PP')}`}
      </Text>
    </RailLegDateWrapper>
  )
})
