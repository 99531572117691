import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DesktopNavigation } from '@etta/modules/navigation'
import { Header } from '@etta/ui/header'
import { Icon } from '@etta/ui/icon'
import { ScreenDesktop } from '@etta/ui/screen/desktop'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { BookTripAgainModal } from '@etta/modules/post-booking/ui/book-trip-again/book-trip-again-modal/book-trip-again-modal'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useDetailsHeader } from '../../use-details-header'
import type { LayoutProps } from '../../types'
import {
  DesktopHeaderWrapper,
  TripDetailsHeader,
  HeadingData,
  HeadingSection,
  IconWrapper,
  ShareButton,
  Status,
  TripTitle,
  ActionButton,
  ActionsWrapper,
} from './details-header-desktop-styles'

export const DetailsHeaderDesktop = observer(function DetailsHeaderDesktop({
  tripName,
  tripStatus,
  startTime,
  endTime,
  onShare,
  onBackButtonClick,
  isLoading,
  isError,
  isTripNameChangable,
  onEdit,
  isTripExist,
  isTripOnHold,
  isOpenReturn,
  onOpenPrintItinerary,
}: LayoutProps) {
  const i18nBase = 'PostBooking.TripDetails.'
  const { t } = useTranslation()

  const {
    titleDate,
    tripTitle,
    statusTitle,
    statusColor,
    shareTitle,
    editIconAriaLabel,
    statusAriaLabel,
    shareButtonAriaLabel,
  } = useDetailsHeader({
    startTime,
    endTime,
    tripName,
    tripStatus,
    isOpenReturn,
  })

  const { bookTripAgainStore, bookTripAgainService, postBookingTripStore } = usePostBookingContext()
  const {
    flightSegment,
    hotelSegment,
    carRentalSegment,
  } = bookTripAgainService.tripToBookAgainSegments

  return (
    <>
      <DesktopHeaderWrapper withBorderBottom>
        <ScreenDesktop.Header as={'header'}>
          <DesktopNavigation />
        </ScreenDesktop.Header>
      </DesktopHeaderWrapper>
      <DesktopHeaderWrapper isTripOnHold={isTripOnHold}>
        <TripDetailsHeader>
          <HeadingSection>
            <Header.BackButton
              onClick={onBackButtonClick}
              color={isTripOnHold ? 'secondary' : 'mainText'}
            />
            <HeadingData>
              <Block paddingLeft={7} aria-label={statusAriaLabel}>
                <Text variant="footnoteStrong" color={isTripOnHold ? 'secondary' : 'bodyText1'}>
                  {titleDate}
                </Text>
                {statusTitle && (
                  <Status variant="captionStrongCaps" color={statusColor}>
                    • {statusTitle}
                  </Status>
                )}
              </Block>
              <Block>
                <TripTitle
                  variant="title2"
                  color={isTripOnHold ? 'secondary' : 'mainText'}
                  aria-label={tripTitle}>
                  {tripTitle}
                </TripTitle>
                {isTripNameChangable && (
                  <IconWrapper
                    role="button"
                    tabIndex={0}
                    onClick={onEdit}
                    data-tracking-id="edit-trip-name"
                    aria-label={editIconAriaLabel}>
                    <Icon
                      name="editPWA"
                      size="medium"
                      color={isTripOnHold ? 'secondary' : 'primary1'}
                    />
                  </IconWrapper>
                )}
              </Block>
            </HeadingData>
          </HeadingSection>
          <ActionsWrapper>
            {!postBookingTripStore.isLoading && bookTripAgainStore.isPostBookingBookAgainAllowed && (
              <ActionButton
                data-tracking-id="book-again-button"
                disabled={isLoading || isError || !isTripExist}
                leftIcon={<Icon name={'tripPWA'} />}
                onClick={bookTripAgainStore.bookTripAgainToggle.handleOpen}
                paddings={false}
                variant="text">
                {t(`${i18nBase}PrintItinerary.BookAgain`)}
              </ActionButton>
            )}
            {!postBookingTripStore.isLoading && (
              <ActionButton
                variant="text"
                leftIcon={<Icon name={'printPWA'} />}
                paddings={false}
                onClick={onOpenPrintItinerary}>
                {t(`${i18nBase}PrintItinerary.PrintItinerary`)}
              </ActionButton>
            )}
            <ShareButton
              aria-label={shareButtonAriaLabel}
              data-tracking-id="share-trip-button"
              disabled={isLoading || isError || !isTripExist}
              leftIcon={<Icon name={'sharePWA'} />}
              onClick={onShare}>
              {shareTitle}
            </ShareButton>
          </ActionsWrapper>
        </TripDetailsHeader>
      </DesktopHeaderWrapper>
      {!postBookingTripStore.isLoading && bookTripAgainStore.isPostBookingBookAgainAllowed && (
        <BookTripAgainModal
          isOpen={bookTripAgainStore.bookTripAgainToggle.isOpen}
          onClose={bookTripAgainStore.bookTripAgainToggle.handleClose}
          tripName={tripName}
          flightSegment={flightSegment}
          hotelSegment={hotelSegment}
          carRentalSegment={carRentalSegment}
        />
      )}
    </>
  )
})
