import { useTranslation } from 'react-i18next'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { KgOfCo2 } from '@etta/components/kg-of-co2/kg-of-co2'
import { RailDetailsEcoCheckModal } from '@etta/components/rail-details-eco-check-modal'
import type { CalculateEmissionsEquivalenceValueObject } from '@etta/core/value-objects/calculate-emissions-equivalence.object-value'
import {
  Container,
  RailEcoCheckImage,
  Title,
  Body,
  RailEmissionDescriptionWrapper,
  LearnMore,
  RailEmissionDescription,
  EmissionsSummary,
  LeftBlock,
  BottomLine,
} from './rail-details-eco-check-desktop-styled'
import EcoCheckRailIllustration from './assets/eco-check-rail-illustration.svg?url'
import EcoCheckLogo from './assets/eco-check-logo.svg?url'

type Props = {
  tonnesOfEmissions: number
  averageRouteEmissionTonnes: number
  kgOfActualEmission: number
  equivalences: CalculateEmissionsEquivalenceValueObject[]
}

export function RailDetailsEcoCheckDesktopComponent({ kgOfActualEmission, equivalences }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.RailDetails'
  const { handleOpen, handleClose, isOpen } = useTogglePopup()

  return (
    <>
      <Container onClick={handleOpen} data-tracking-id="flight-details-eco-check-action">
        <Body>
          <LeftBlock>
            <Title aria-label={t('Accessibility.EcoCheck.EcoCheck')}>
              <img src={EcoCheckLogo} alt="eco-check-logo" />
            </Title>
            <EmissionsSummary>
              <KgOfCo2 kgOfEmissions={kgOfActualEmission} />
            </EmissionsSummary>
            <RailEmissionDescriptionWrapper>
              <RailEmissionDescription
                variant="footnoteMedium"
                color="bodyText"
                aria-label={t(i18nBase + '.RailEmissionInfo')}>
                {t(i18nBase + '.RailEmissionInfo')}
              </RailEmissionDescription>
              <LearnMore
                aria-label={
                  t('Emissions.DetailsModal.LearnMore') + ' ' + t('Accessibility.TapToView')
                }>
                {t('Emissions.DetailsModal.LearnMore')}
              </LearnMore>
            </RailEmissionDescriptionWrapper>
          </LeftBlock>
          <RailEcoCheckImage src={EcoCheckRailIllustration} />
        </Body>
        <BottomLine />
      </Container>
      <RailDetailsEcoCheckModal
        equivalences={equivalences}
        isVisible={isOpen}
        onClose={handleClose}
        kgOfActualEmission={kgOfActualEmission}
      />
    </>
  )
}
