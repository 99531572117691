import { useTranslation } from 'react-i18next'
import type { CarRentalSegmentEntity } from '@etta/modules/review-trip/core'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import type { CarEmission } from '@fiji/types'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { ViewStateModal } from '@etta/ui/view-state-modal'
import { RulesModal } from './rules-modal/rules-modal'
import { useCarRentalDetailsModal } from './use-car-rental-details-modal'
import { useChangeCarRental } from './use-change-car-rental'
import { CarRentalDetailsModalLayout } from './layout'

type Props = {
  onClose: () => void
  carRental: CarRentalSegmentEntity
  isTripExist: boolean
  itineraryId: string
  carRentalId: string
  isRulesModalOpen: boolean
  onOpenRulesModal: () => void
  onCloseRulesModal: () => void
  carRentalEmission: CarEmission
}

export function CarRentalDetailsContent({
  onClose,
  carRental,
  carRentalId,
  isTripExist,
  itineraryId,
  isRulesModalOpen,
  onOpenRulesModal,
  onCloseRulesModal,
  carRentalEmission,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'CarRentalDetailsModal.'
  const { handleChangeCarRental } = useChangeCarRental({
    itineraryId,
    carRental,
    handleCloseChangeModal: onClose,
  })
  const { headerRef, scrollContainerRef } = useHeaderScroll()

  const {
    carRentalTitle,
    imageUrl,
    amenities,
    carImage,
    isOutOfPolicy,
    isPreferred,
    vendorInformation,
    locations,
    changeModalToggle,
    removeDialogToggle,
    handleRemoveCarRental,
    viewStateParams,
  } = useCarRentalDetailsModal({
    carRental,
    carRentalId,
    onClose,
  })

  return (
    <>
      <CarRentalDetailsModalLayout
        onClose={onClose}
        carRental={carRental}
        isTripExist={isTripExist}
        headerRef={headerRef}
        scrollContainerRef={scrollContainerRef}
        carRentalTitle={carRentalTitle}
        imageUrl={imageUrl}
        amenities={amenities}
        isOutOfPolicy={isOutOfPolicy}
        isPreferred={isPreferred}
        vendorInformation={vendorInformation}
        locations={locations}
        carImage={carImage}
        onCancelClick={removeDialogToggle.handleOpen}
        onChangeClick={changeModalToggle.handleOpen}
        onDetailsClick={onOpenRulesModal}
        carRentalEmission={carRentalEmission}
      />
      <ConfirmationDialog
        title={t(i18Base + 'ChangeCarRental.Title')}
        content={t(i18Base + 'ChangeCarRental.InfoText')}
        isOpen={changeModalToggle.isOpen}
        onDecline={changeModalToggle.handleClose}
        onConfirm={handleChangeCarRental}
        confirmButtonText={t(i18Base + 'ChangeCarRental.ConfirmButtonText')}
        declineButtonText={t(i18Base + 'ChangeCarRental.DeclineButtonText')}
      />
      <ConfirmationDialog
        isOpen={removeDialogToggle.isOpen}
        onDecline={removeDialogToggle.handleClose}
        onConfirm={() => {
          handleRemoveCarRental()
          removeDialogToggle.handleClose()
        }}
        title={t(i18Base + 'RemoveCarRental')}
        content={t(i18Base + 'RemoveCarRentalExplanation')}
        confirmButtonText={t(i18Base + 'RemoveConfirmButton')}
        declineButtonText={t(i18Base + 'RemoveDeclineButton')}
      />
      <ViewStateModal
        viewState={viewStateParams.viewState}
        onDone={() => {
          removeDialogToggle.handleClose()
          viewStateParams.onClose()
        }}
        title={viewStateParams.title}
      />
      <RulesModal
        itineraryId={itineraryId}
        carSegmentId={carRental.carId}
        isOpen={isRulesModalOpen}
        onClose={onCloseRulesModal}
      />
    </>
  )
}
