import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Link } from '@etta/ui/link'
import { ListFooter, ButtonWrapper } from './outside-container-styled'

type Props = {
  isDisabled?: boolean
  onApply: () => void
  isQuickFilterDesign?: boolean
  onClear: () => void
  shouldHideClear?: boolean
  isClearButtonDisabled?: boolean
}

const i18Base = 'QuickFilters.'

export function OutsideContainerFooter({
  onApply,
  isDisabled,
  isQuickFilterDesign,
  isClearButtonDisabled,
  onClear,
  shouldHideClear,
}: Props) {
  const { t } = useTranslation()

  return (
    <ListFooter isInlineView isBorder={!isQuickFilterDesign}>
      <ButtonWrapper>
        <Button
          aria-label={t(`${i18Base}TabToApply`, {
            label: t(i18Base + 'Apply'),
          })}
          onClick={onApply}
          fullWidth
          disabled={isDisabled}>
          {t(i18Base + 'Apply')}
        </Button>
      </ButtonWrapper>
      {!shouldHideClear && (
        <Link
          aria-label={t(`${i18Base}TabToClear`, {
            label: t(i18Base + 'Clear'),
          })}
          isDisabled={isClearButtonDisabled}
          onClick={onClear}>
          {t(i18Base + 'Clear')}
        </Link>
      )}
    </ListFooter>
  )
}
