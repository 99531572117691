import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import type { CarRentalPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import type { RangeResult } from '../types'

export function convertCarRentalToRangePostBooking(
  carRental: CarRentalPostBookingValueObject,
): RangeResult {
  const { dropOffLocation, pickUpLocation, pickUpTime, dropOffTime } = carRental
  return {
    to: pickUpLocation?.address,
    from: dropOffLocation?.address,
    startDate: pickUpTime ? deleteTimezone(pickUpTime) : undefined,
    endDate: dropOffTime ? deleteTimezone(dropOffTime) : undefined,
  }
}
