import { useTranslation } from 'react-i18next'
import { Search } from '@etta/ui/search'
import type { DesktopContentProps } from '../../../types'
import { LocationPickers } from './location-pickers'
import { DropOffPicker } from './drop-off-picker'
import { PickUpPicker } from './pick-up-picker'

export function CarRentalSearchPageDesktopContent({
  pickUpPlace,
  onPickUpPlaceChange,
  dropOffPlace,
  onDropOffPlaceChange,
  pickUpDate,
  dropOffDate,
  onDatesSelect,
  pickUpTime,
  dropOffTime,
  onDropOffTimeChange,
  onPickUpTimeChange,
  onSearchClick,
  placesHistory,
  pickUpDateToggle,
  dropOffDateToggle,
  pickUpTimeToggle,
  dropOffTimeToggle,
  onSwitchLocations,
  pickUpAddress,
  dropOffAddress,
  validationErrors,
}: DesktopContentProps) {
  const { t } = useTranslation()
  return (
    <Search
      dataTrackingId="header-search-button"
      searchButtonAriaLabel={t('Accessibility.AddButton', {
        label: t('TripPlanner.BaseSearch.Buttons.Search'),
      })}
      onSearchClick={onSearchClick}>
      <LocationPickers
        pickUpPlace={pickUpPlace}
        pickUpAddress={pickUpAddress}
        dropOffPlace={dropOffPlace}
        dropOffAddress={dropOffAddress}
        placesHistory={placesHistory}
        onPickUpPlaceChange={onPickUpPlaceChange}
        onDropOffPlaceChange={onDropOffPlaceChange}
        onSwitchLocations={onSwitchLocations}
        validationErrors={validationErrors}
      />
      <PickUpPicker
        pickUpTime={pickUpTime}
        pickUpDate={pickUpDate}
        dropOffDate={dropOffDate}
        pickUpDateToggle={pickUpDateToggle}
        pickUpTimeToggle={pickUpTimeToggle}
        onDatesSelect={onDatesSelect}
        onPickUpTimeChange={onPickUpTimeChange}
        validationErrors={validationErrors}
      />
      <DropOffPicker
        dropOffDate={dropOffDate}
        pickUpDate={pickUpDate}
        dropOffTime={dropOffTime}
        dropOffDateToggle={dropOffDateToggle}
        dropOffTimeToggle={dropOffTimeToggle}
        onDatesSelect={onDatesSelect}
        onDropOffTimeChange={onDropOffTimeChange}
        validationErrors={validationErrors}
      />
    </Search>
  )
}
