import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { AppSpinner } from '@etta/modules/app'
import { useCleanupModalHistory } from '@fiji/hooks/use-close-modal-by-browser-back-button'
import { getClient, ApolloProvider } from '@fiji/providers'
import { store } from '@fiji/store'
import { InitialSpinnerRemoval } from './initial-spinner-removal'
import { DevSelector } from './dev-selector'
import { Content } from '@root-app/content'
import { Theme } from '@root-app/theme'
import { AppRouting } from '@root-app/app-routing'

export function App() {
  useCleanupModalHistory()

  return (
    <ApolloProvider client={getClient()}>
      <Provider store={store}>
        <Theme>
          <Suspense fallback={<AppSpinner />}>
            <DevSelector />
            <AppRouting>
              <InitialSpinnerRemoval />
              <Content />
            </AppRouting>
          </Suspense>
        </Theme>
      </Provider>
    </ApolloProvider>
  )
}
