import { CustomFieldType } from '@fiji/graphql/types'
import type { CustomFieldExtension } from '../types'
import type { CustomFieldRenderType } from './types'
import { getLabel } from './get-label'

type Args = {
  fields: CustomFieldExtension[]
}

type ReduceResult = Array<{
  type: CustomFieldRenderType
  fields: CustomFieldExtension[]
  header?: string
}>

type ModifyFieldValueArgs = {
  value: CustomFieldExtension
  isFirstElement?: boolean
  customType: CustomFieldRenderType
}

const customTypeToRenderType: Record<CustomFieldType, CustomFieldRenderType> = {
  [CustomFieldType.Date]: 'Date',
  [CustomFieldType.Datetime]: 'Date',
  [CustomFieldType.Time]: 'Date',
  [CustomFieldType.Text]: 'Text',
  [CustomFieldType.PreTrip]: 'PreTrip',
  [CustomFieldType.Enumeration]: 'Enumeration',
  [CustomFieldType.Checkbox]: 'Checkbox',
  [CustomFieldType.Number]: 'Number',
  [CustomFieldType.Monetary]: 'Number',
  [CustomFieldType.Percentage]: 'Number',
}

const fieldsWithHeader: Array<CustomFieldRenderType> = ['Date']

function modifyFieldVaue({ value, isFirstElement, customType }: ModifyFieldValueArgs) {
  const _value = { ...value }
  if (customType === 'Date' && !isFirstElement) {
    _value.tooltip = null
  }

  return _value
}

export function getGroupedCustomFields({ fields }: Args) {
  return fields.reduce<ReduceResult>((acc, value) => {
    if (!value.type) {
      return acc
    }

    const customType = customTypeToRenderType[value.type]
    const header = fieldsWithHeader.includes(customType) ? getLabel(value) : undefined
    const prevElement = acc[acc.length - 1]
    const isFirstElement = !prevElement?.fields.length

    if (prevElement && prevElement.type === customType && prevElement.header === header) {
      const fieldValue = modifyFieldVaue({
        value,
        isFirstElement,
        customType,
      })

      prevElement.fields.push(fieldValue)
      return acc
    }
    return [...acc, { type: customType, fields: [value], header: header || undefined }]
  }, [])
}
