import { useTranslation } from 'react-i18next'
import { Modal } from '@etta/ui/modal'

const i18nBase = 'UnusedTickets'

export function UnusedTicketsModalHeaderMobile() {
  const { t } = useTranslation()
  return (
    <Modal.Header isMobile withBorder>
      <Modal.Title>{t(`${i18nBase}.Headline`)}</Modal.Title>
    </Modal.Header>
  )
}
