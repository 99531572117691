import type { FocusedInputShape } from 'react-dates'

const FOCUSED_START_DATE = 'startDate'
const FOCUSED_END_DATE = 'endDate'

type Args = {
  date?: Date | null
  dateEnd?: Date | null
  isRange?: boolean
  isReturnDate?: boolean
  isDepartureDatePickerDisabled?: boolean
}

export function getFocusedInput({
  date,
  dateEnd,
  isRange,
  isReturnDate,
  isDepartureDatePickerDisabled,
}: Args): FocusedInputShape {
  if (!isRange || !date) {
    return FOCUSED_START_DATE
  }

  if (!isReturnDate && dateEnd && !isDepartureDatePickerDisabled) {
    return FOCUSED_START_DATE
  }

  return FOCUSED_END_DATE
}
