import { Icon } from '@etta/ui/icon'
import { HotelRoom } from '@etta/components/hotel-room'
import type { Room } from '@fiji/graphql/types'
import { LinkContainer } from '../hotel-details-rooms-styled'
import { useHotelDetailsRooms } from '../use-hotel-details-rooms'

type Props = {
  onRoomSelect?: (room: Room) => void
  onRoomDetailsSelect: (room: Room) => void
  rooms?: Room[]
  areRoomsSelectable?: boolean
  totalNights: number
}

export function RoomsContent({
  rooms = [],
  areRoomsSelectable,
  onRoomSelect,
  onRoomDetailsSelect,
  totalNights,
}: Props) {
  const {
    isMoreRoomsAvailable,
    linkMoreRoomsIcon,
    linkMoreRoomsText,
    handleToggleMoreRooms,
    visibleRooms,
    handleSelectRoom,
  } = useHotelDetailsRooms({ rooms, onRoomSelect })

  return (
    <>
      {visibleRooms?.map((room, index) => {
        return (
          <HotelRoom
            key={index}
            onSelect={handleSelectRoom}
            onDetailsClick={onRoomDetailsSelect}
            room={room}
            isSelectable={areRoomsSelectable}
            totalNights={totalNights}
          />
        )
      })}
      {isMoreRoomsAvailable && (
        <LinkContainer aria-label={linkMoreRoomsText} onClick={handleToggleMoreRooms}>
          {linkMoreRoomsText}
          <Icon name={linkMoreRoomsIcon} color="mainText1" size={32} />
        </LinkContainer>
      )}
    </>
  )
}
