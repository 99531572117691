import { toJS } from 'mobx'
import { Inject, Store } from '@etta/di'
import { checkIsEqual } from '@fiji/utils/check-is-equal'
import { Toggle } from '@etta/interface/services/toggle'
import type {
  AirFilterAirline,
  AirFilterAirport,
  AirFilterFareType,
  AirFilterStop,
  AirFiltersType,
  FilterTime,
} from '@etta/modules/air-search/core/value-objects/air-filters'
import { anyStopsOption } from '@etta/modules/air-search/core/constants/stops'
import { FeatureFlagsStore } from '@etta/modules/feature-flags'
import { AirSearchQueryStore } from '../air-search-query.store'
import { AirSearchStore } from './air-search.store'

@Store()
export class AirFiltersStore {
  constructor(
    @Inject() private airSearchQueryStore: AirSearchQueryStore,
    @Inject() private airSearchStore: AirSearchStore,
    @Inject() private featureFlagsStore: FeatureFlagsStore,
  ) {}
  filterModal = new Toggle()

  stopsModal = new Toggle()
  airLinesModal = new Toggle()
  fareTypeModal = new Toggle()
  airportsModal = new Toggle()

  private selectedFilters: AirFiltersType = {
    stops: [],
    originTime: null,
    destinationTime: null,
    airlines: [],
    fareTypes: [],
    originAirports: [],
    destinationAirports: [],
    isMatchedWithPolicy: false,
  }
  get selectedOriginTime() {
    return this.selectedFilters.originTime || null
  }
  get selectedDestinationTime() {
    return this.selectedFilters.destinationTime || null
  }
  get selectedStops() {
    return this.selectedFilters.stops || []
  }
  get selectedAirlines() {
    return this.selectedFilters.airlines || []
  }
  get selectedFareTypes() {
    return this.selectedFilters.fareTypes || []
  }
  get selectedOriginAirports() {
    return this.selectedFilters.originAirports || []
  }
  get selectedDestinationAirports() {
    return this.selectedFilters.destinationAirports || []
  }
  get isMatchedWithPolicy() {
    return this.selectedFilters.isMatchedWithPolicy || false
  }

  get selectedStopsNumber() {
    return this.selectedStops.some((stop) => stop.id === anyStopsOption.id)
      ? 0
      : this.selectedStops.length
  }

  get isStopsEqual() {
    return checkIsEqual(
      toJS(this.newFilters.stops),
      this.airSearchQueryStore.additionalQueries.filters?.stops,
    )
  }

  get isStopClearButtonDisabled() {
    return checkIsEqual(toJS(this.newFilters.stops), this.airSearchStore.afterSearchFilters.stops)
  }

  get isAirlinesEqual() {
    return checkIsEqual(
      toJS(this.newFilters.airlines),
      this.airSearchQueryStore.additionalQueries.filters?.airlines,
    )
  }

  get isAirlinesClearButtonDisabled() {
    return checkIsEqual(
      toJS(this.newFilters.airlines),
      this.airSearchStore.afterSearchFilters.airlines,
    )
  }

  get isFareTypesEqual() {
    return checkIsEqual(
      toJS(this.newFilters.fareTypes),
      this.airSearchQueryStore.additionalQueries.filters?.fareTypes,
    )
  }

  get isFareTypeClearButtonDisabled() {
    return checkIsEqual(
      toJS(this.newFilters.fareTypes),
      this.airSearchStore.afterSearchFilters?.fareTypes,
    )
  }

  get isAirportsEqual() {
    return (
      checkIsEqual(
        toJS(this.newFilters.originAirports),
        this.airSearchQueryStore.additionalQueries.filters?.originAirports,
      ) &&
      checkIsEqual(
        toJS(this.newFilters.destinationAirports),
        this.airSearchQueryStore.additionalQueries.filters?.destinationAirports,
      )
    )
  }

  get isAirportClearButtonDisabled() {
    return (
      checkIsEqual(
        toJS(this.newFilters.originAirports),
        this.airSearchStore.afterSearchFilters.originAirports,
      ) &&
      checkIsEqual(
        toJS(this.newFilters.destinationAirports),
        this.airSearchStore.afterSearchFilters.destinationAirports,
      )
    )
  }

  fareDialog = new Toggle()

  get isSelectedFareTypesEmpty() {
    return !this.selectedFareTypes.length
  }

  get isFiltersEqual() {
    if (!this.airSearchQueryStore.additionalQueries.filters) {
      return false
    }

    return !checkIsEqual(toJS(this.newFilters), this.airSearchQueryStore.additionalQueries.filters)
  }

  get newFilters(): AirFiltersType {
    const isAllFareSelected =
      this.selectedFareTypes.length === this.airSearchStore.filterOptions?.fareTypes?.length
    const isOriginAirportsEmpty = this.selectedOriginAirports.length === 0
    const isDestinationAirportsEmpty = this.selectedDestinationAirports.length === 0

    return {
      stops: this.selectedStops,
      destinationTime: this.selectedDestinationTime,
      originTime: this.selectedOriginTime,
      airlines: this.selectedAirlines,
      fareTypes:
        isAllFareSelected && this.airSearchStore.filterOptions?.fareTypes
          ? this.airSearchStore.filterOptions.fareTypes
          : this.selectedFareTypes,
      originAirports:
        isOriginAirportsEmpty && !isDestinationAirportsEmpty
          ? this.airSearchStore.filterOptions?.originAirports
          : this.selectedOriginAirports,
      destinationAirports:
        !isOriginAirportsEmpty && isDestinationAirportsEmpty
          ? this.airSearchStore.filterOptions?.destinationAirports
          : this.selectedDestinationAirports,
      isMatchedWithPolicy: this.isMatchedWithPolicy,
    }
  }

  get isClearButtonDisabled() {
    if (!this.featureFlagsStore.flags.isNewAirFiltersModalEnabled) {
      return false
    }
    if (this.airSearchStore.isFiltersApplied) {
      return checkIsEqual(this.newFilters, this.airSearchStore.afterSearchFilters)
    }

    return !this.isFiltersEqual
  }

  setFilters = (filters: AirFiltersType) => {
    this.selectedFilters = filters
  }

  setOriginTime = (time: FilterTime | null) => {
    this.selectedFilters.originTime = time
  }
  setDestinationTime = (time: FilterTime | null) => {
    this.selectedFilters.destinationTime = time
  }

  setSelectedStops = (stops: AirFilterStop[]) => {
    this.selectedFilters.stops = stops
  }

  isStopsSelected = (incomingStops: AirFilterStop) => {
    return this.selectedStops.some((stops) => stops.id === incomingStops.id)
  }

  setSelectedAirlines = (airlines: AirFilterAirline[]) => {
    this.selectedFilters.airlines = airlines
  }

  isAirlinesSelected = (incomingAirline: AirFilterAirline) => {
    return this.selectedAirlines.some((airline) => airline.id === incomingAirline.id)
  }

  setSelectedFareTypes = (fareTypes: AirFilterFareType[]) => {
    this.selectedFilters.fareTypes = fareTypes
  }

  isFareTypeSelected = (incomingFareType: AirFilterFareType) => {
    return this.selectedFareTypes.some((fareType) => fareType.id === incomingFareType.id)
  }

  setSelectedOriginAirports = (originAirports: AirFilterAirport[]) => {
    this.selectedFilters.originAirports = originAirports
  }

  isOriginAirportSelected = (incomingAirport: AirFilterAirport) => {
    return this.selectedOriginAirports.some((airport) => airport.code === incomingAirport.code)
  }

  setSelectedDestinationAirports = (destinationAirports: AirFilterAirport[]) => {
    this.selectedFilters.destinationAirports = destinationAirports
  }

  isDestinationAirportSelected = (incomingAirport: AirFilterAirport) => {
    return this.selectedDestinationAirports.some((airport) => airport.code === incomingAirport.code)
  }

  setIsMatchedWithPolicy = (value: boolean) => {
    this.selectedFilters.isMatchedWithPolicy = value
  }
}
