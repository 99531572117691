import { Text } from '@etta/ui/text'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import type { StationRowProps } from '../../types'
import { StationCircle, FirstColumn, GridRow } from '../../rail-route-details-styled'

export function StationRow({ station, isRtp }: StationRowProps) {
  const stationTime = tripReviewFormatDate(TripReviewDateType.TimePWA, station.departingTime) || '-'

  return (
    <GridRow>
      <FirstColumn>
        <StationCircle isRtp={isRtp} />
      </FirstColumn>
      <Text variant="footnoteMedium" color="bodyText">
        {station.stationName}
      </Text>
      <Text variant="footnoteStrong" color="bodyText" align="right">
        {stationTime}
      </Text>
    </GridRow>
  )
}
