import { Inject, Service } from '@etta/di'
import i18n from '@fiji/i18n'
import { delayForSuccess } from '@fiji/utils/delay-for-success'
import { SettingsAdapter } from '../../infra/settings.adapter'
import type { PasswordInput } from '../stores/password.store'
import { PasswordStore } from '../stores/password.store'
import { BaseSettingsStore } from '../stores/base.store'
import { SettingsErrors } from '../../core/errors/settings.errors'

const i18nBase = `Settings.ChangePassword`

@Service()
export class PasswordService {
  constructor(
    @Inject()
    private readonly settingsAdapter: SettingsAdapter,
    @Inject()
    private readonly baseSettingsStore: BaseSettingsStore,
    @Inject()
    private readonly passwordStore: PasswordStore,
  ) {}

  async changePassword({ input, handleClose }: { input: PasswordInput; handleClose: () => void }) {
    this.baseSettingsStore.setSavingState()

    const result = await this.settingsAdapter.changePassword(input)

    if (result.isErr()) {
      const error = result.getError()
      let message = null

      switch (true) {
        case error instanceof SettingsErrors.ChangePasswordBadCredentials:
          this.passwordStore.passwordValidator.setError(
            'currentPassword',
            i18n.t(`${i18nBase}.Errors.CurrentPasswordIsInvalid`),
          )
          this.baseSettingsStore.dropState()
          break
        case error instanceof SettingsErrors.ChangePasswordMismatch:
          message = i18n.t(`${i18nBase}.Errors.NewPasswordsMismatch`)
          this.passwordStore.passwordValidator.setError('newPassword', message)
          this.passwordStore.passwordValidator.setError('newPasswordConfirmation', message)
          this.baseSettingsStore.dropState()
          break
        case error instanceof SettingsErrors.ChangePasswordWeak:
          message = i18n.t(`${i18nBase}.Errors.NewPasswordWeak`)
          this.passwordStore.passwordValidator.setError('newPassword', message)
          this.passwordStore.passwordValidator.setError('newPasswordConfirmation', message)
          this.baseSettingsStore.dropState()
          break
        default:
          this.baseSettingsStore.setErrorState()
      }

      return
    }

    this.baseSettingsStore.setSavedState()
    await delayForSuccess(300)
    this.baseSettingsStore.dropState(handleClose)
  }
}
