import type { CreditCard } from '@fiji/graphql/types'
import { getCardCheck } from '@etta/screens/payment-summary/use-payment-summary/get-card-check'

type Args = {
  creditCard: Partial<CreditCard>
}

export function getBillingConditions({ creditCard }: Args) {
  const { billingError } = getCardCheck({
    mustHaveAddress: !!creditCard.metadata?.isBillingAddressRequired,
    address: creditCard.billingAddress,
    isCardOop: !!creditCard.isCardOutOfPolicy,
    isSiteCard: !!creditCard.isSiteCard,
    brandType: creditCard.brandType,
    isCardExist: !!creditCard,
  })

  const restrictedBillingConditions = [
    billingError.address,
    creditCard.isCardOutOfPolicy,
    billingError.brandType,
  ]

  return {
    isBillingAllowed: restrictedBillingConditions.every((condition) => !condition),
    billingError,
  }
}
