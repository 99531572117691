import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const IndexLabelRow = styled.div`
  padding: 0 16px;
  margin-bottom: 2px;
  min-height: 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const IconButton = styled(UnstyledButton)``
