import { Action, Inject } from '@etta/di'
import { CreatePasswordService } from '@etta/modules/user/interface/services/create-password.service'
import { CreatePasswordStore } from '@etta/modules/user/interface/stores/create-password.store'
import { UserStore } from '@etta/modules/user/interface/stores/user.store'

@Action()
export class CreatePasswordActions {
  constructor(
    @Inject() private readonly createPasswordService: CreatePasswordService,
    @Inject() private readonly createPasswordStore: CreatePasswordStore,
    @Inject() private readonly userStore: UserStore,
  ) {}

  async handleCheckSSO() {
    const entity = await this.createPasswordService.handleCheckSSO()
    const companyId = this.userStore.companyId

    if (!entity) {
      return
    }
    const site = entity.find((site) => site.id === companyId)

    if (!site) {
      return
    }
    this.createPasswordStore.setSsoType(site?.sso.typeVariant)
  }

  async handleGetUserPasswordSecurityConfigure() {
    if (!this.userStore.profile?.forceNewPassword) {
      return
    }

    const entity = await this.createPasswordService.getUserPasswordSecurityConfigure()

    if (entity) {
      this.createPasswordStore.setPasswordSecurityConfigureEntity(entity)
    }
  }

  handlePassword(value: string) {
    this.createPasswordStore.setPassword(value)
  }

  handleConfirmPassword(value: string) {
    this.createPasswordStore.setConfirmPassword(value)
  }

  dropStore() {
    this.createPasswordStore.dropStore()
  }
}
