import type { TripDetailsFlightLegSegment } from '@fiji/graphql/types'

type Props = {
  segment: TripDetailsFlightLegSegment
}

const DELAYED_STATUS = 'DELAYED'

export function useHeader({ segment }: Props) {
  const airlineTitle = segment.name || ''
  const aircraftNumber = [segment.carrierCode, segment.flightNumber].join('') || '-'
  const airlineLogo = segment.images?.square
  const flightStatus = segment.flightStatus || ''
  const isDelayed = segment.flightStatus === DELAYED_STATUS

  return {
    isDelayed,
    airlineTitle,
    aircraftNumber,
    airlineLogo,
    flightStatus,
  }
}
