import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { TimeRange, Place } from '@fiji/types'
import { TimeRangeOption } from '@fiji/enums'

type Props = {
  place?: Place | null
  selectedTime: TimeRange
  setPickedTime: (time: TimeRange) => void
  date?: Date | null
  initialCustomTime: TimeRange
}
const i18Base = 'FlightSearch.TimePicker'
const TIME_STEP = 2

export function useTimeTab({ place, selectedTime, setPickedTime, date, initialCustomTime }: Props) {
  const { t } = useTranslation()

  const [customTimeValue, setCustomTimeValue] = useState(
    selectedTime.customTimeHours
      ? selectedTime.customTimeHours
      : initialCustomTime.customTimeHours!,
  )

  const getDateTitle = (date?: Date | null) => {
    return date ? `${t(i18Base + '.OnDate')} ${dateFormat(date, 'iii, MMM d')}` : ''
  }

  const convertTimeLabel = useCallback(
    (time: TimeRange) => {
      const label = t(time.i18next)
      if (
        time.id === TimeRangeOption.CustomTime &&
        selectedTime.id === TimeRangeOption.CustomTime
      ) {
        const customTime = getFormattedTime(
          {
            hours: customTimeValue,
          },
          'h:mmaaa',
        )
        return `${label}: ${t(i18Base + `.Around`)} ${customTime}`
      }
      if (time.id === TimeRangeOption.CustomTime || time.id === TimeRangeOption.AnyTime) {
        return label
      }
      const startTime = getFormattedTime(
        {
          hours: time.startTimeHours!,
        },
        'haaa',
      )
      const endTime = getFormattedTime(
        {
          hours: time.endTimeHours!,
        },
        'haaa',
      )

      return `${label} (${startTime} - ${endTime})`
    },
    [selectedTime, customTimeValue, t],
  )

  const handleCustomTimeChange = ({ value, time }: { value: number; time?: TimeRange }) => {
    const startTimeValue = value - 1 < 0 ? 0 : value - 1
    const startTimeRange = getFormattedTime({ hours: startTimeValue })
    const endTimeRange =
      startTimeValue + TIME_STEP === 24
        ? '23:59:59'
        : getFormattedTime({ hours: startTimeValue + TIME_STEP })
    const timeToSet = time ? time : selectedTime
    const pickedTimeValue = {
      ...timeToSet!,
      startTimeHours: value - 1,
      endTimeHours: value + 1,
      startTimeRange,
      endTimeRange,
      customTimeHours: value,
    }
    setPickedTime(pickedTimeValue)
  }

  const onCustomTimeChange = (value: number) => {
    setCustomTimeValue(value)
    handleCustomTimeChange({ value })
  }

  const onRadioButtonClick = (time: TimeRange) => {
    if (time.id === TimeRangeOption.CustomTime) {
      handleCustomTimeChange({ value: customTimeValue, time })
      return
    }
    setPickedTime({ ...time })
  }

  const airportTitle = () => {
    const firstLocationName = (place?.shortName ?? place?.name)?.split(',')[0]
    const airportCode = place?.airportCode ? `(${place.airportCode})` : null
    const dateTitle = getDateTitle(date)
    return [firstLocationName, airportCode, dateTitle].filter(Boolean).join(' ')
  }
  const title = airportTitle()

  return {
    convertTimeLabel,
    onCustomTimeChange,
    handleCustomTimeChange,
    onRadioButtonClick,
    customTimeValue,
    title,
  }
}
