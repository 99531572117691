import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle, captionMedium, headline } from '@fiji/style'

export const Label = styled.label`
  ${captionMedium};
  line-height: 16px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Value = styled.div<{ isLabel: boolean }>`
  ${headline};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  ${(props) =>
    props.isLabel &&
    css`
      color: ${(props) => props.theme.colors.bodyText1};
    `}
`

const containerErrorBorder = css`
  outline: 1px solid ${(p) => p.theme.colors.error};
  outline-offset: -1px;
  border: none;
`

export const InputContainer = styled.button<{ disabled?: boolean; hasError: boolean }>`
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : props.theme.inputBorderColor)};
  ${(p) => p.hasError && containerErrorBorder}
  text-align: left;
  padding: 0 17px;
  color: ${(props) => props.theme.inputColor};
  max-height: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) =>
    props.disabled ? props.theme.colors.background4 : props.theme.colors.background};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  &:active {
    background-color: ${(props) => props.theme.colors.background1};
  }
  ${KeyboardNavigationStyle}
`
