import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { useBrandConfigurationContext } from '@etta/modules/brand-configuration/interface/use-display-configuration-context'
import NoUpcomingFlight from '../../assets/no-up-coming-flight.svg?url'

type Props = {
  onOpenAddFlightModal: () => void
}

export const RideHailNoFlightDetails = ({ onOpenAddFlightModal }: Props) => {
  const { t } = useTranslation()
  const i18nBase = 'Mobility.ReviewFlightDetails.NoUpcomingFlight'
  const i18nBaseForBrand = 'Mobility.UberNewInformationPage.Brand.'

  const { brandConfigurationStore } = useBrandConfigurationContext()
  const brandLabel = brandConfigurationStore.isAppleTheme
    ? t(i18nBaseForBrand + 'Kan')
    : t(i18nBaseForBrand + 'Deem')

  return (
    <Block marginHorizontal={30}>
      <Block marginBottom={18}>
        <img src={NoUpcomingFlight} alt="noUpcomingFlight" />
      </Block>
      <Block marginBottom={18}>
        <Text variant="title2" color="white">
          {t(`${i18nBase}.Title`)}
        </Text>
      </Block>
      <Block marginBottom={18}>
        <Text variant="footnoteMedium" color="white">
          {t(`${i18nBase}.Description`, { brand: brandLabel })}
        </Text>
      </Block>
      <Button size="small" onClick={onOpenAddFlightModal}>
        {t(`${i18nBase}.Button`)}
      </Button>
    </Block>
  )
}
