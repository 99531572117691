import { useMemo } from 'react'
import type { GroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { dateFormat } from '@fiji/utils/dates/date-format'
import { TripStatus } from '@etta/modules/post-booking-list/core/value-object/trips-list.value-object'
import { SegmentType } from '@etta/core/enums'

type Params = {
  isOnHold: boolean
  currentDate: Date
  segment: GroupedSegmentValueObject
}

export function useSegmentCard({ segment, currentDate, isOnHold }: Params) {
  const date = useMemo(() => new Date(segment.date), [segment.date])
  const dateToShow = useMemo(() => dateFormat(date, 'h:mm aa'), [date])

  switch (segment.type) {
    case SegmentType.RideHail:
      return {
        dateToShow,
        isPast: segment.tripStatus === TripStatus.Completed,
      }
    default:
      return {
        dateToShow,
        isPast: !isOnHold && date < currentDate,
      }
  }
}
