// eslint-disable-next-line import/no-restricted-paths
import type { TimeFilter } from '@fiji/graphql/types'
import type { Flight } from '@fiji/hooks/search-queries/use-air-search-query/types'
import type { TimeRange } from '@fiji/types'
import { deleteTimezone } from '@fiji/utils/dates/delete-timezone'
import { TimeRangeDirection, TimeRangeOption } from '@fiji/enums'

type Props = {
  flight?: Flight
  originTimeRange: TimeFilter | null
  destinationTime: TimeFilter | null
  isCustomTimeReplaced?: boolean | null
}

type ValidateProps = {
  timeGap: {
    start: Date
    end: Date
  }
  selectedTime?: TimeRange
}

export function takeoffLandingRanges({
  originTimeRange,
  destinationTime,
  isCustomTimeReplaced,
  flight,
}: Props) {
  const selectedTime = flight?.timeRange
  const timeRangeBy = flight?.timeRange.timeRangeBy
  const takeoffTime = {
    start: new Date(deleteTimezone(originTimeRange?.start)),
    end: new Date(deleteTimezone(originTimeRange?.end)),
  }
  const landingTime = {
    start: new Date(deleteTimezone(destinationTime?.start)),
    end: new Date(deleteTimezone(destinationTime?.end)),
  }
  if (isCustomTimeReplaced) {
    return { takeoff: takeoffTime, landing: landingTime }
  }

  if (timeRangeBy === TimeRangeDirection.Departure) {
    return {
      takeoff: validatedTimeRange({
        timeGap: takeoffTime,
        selectedTime,
      }),
      landing: landingTime,
    }
  }

  if (timeRangeBy === TimeRangeDirection.Arrival) {
    return {
      takeoff: takeoffTime,
      landing: validatedTimeRange({
        timeGap: landingTime,
        selectedTime,
      }),
    }
  }
  return { takeoff: takeoffTime, landing: landingTime }
}

function setHour(date: Date, hour: number) {
  date.setHours(hour)
  date.setMinutes(0)
  return date
}

export function validatedTimeRange({ timeGap, selectedTime }: ValidateProps) {
  const timeGapStart = timeGap.start
  const timeGapEnd = timeGap.end
  const timeStartHour = selectedTime?.startTimeHours
  const timeEndHour = selectedTime?.endTimeHours
  const isCustomTime = selectedTime?.id === TimeRangeOption.CustomTime

  if (!timeStartHour || !timeEndHour) {
    return timeGap
  }

  if (isCustomTime) {
    const preFilledStart = validateCustomTime({
      selectedTime: timeStartHour,
      timeGap,
      isStart: true,
    })
    const preFilledEnd = validateCustomTime({ selectedTime: timeEndHour, timeGap })
    return { start: preFilledStart, end: preFilledEnd }
  }

  const preFilledStart =
    timeStartHour < timeGapStart.getHours() ? timeGapStart : setHour(timeGapStart, timeStartHour)
  const preFilledEnd =
    timeEndHour > timeGapEnd.getHours() ? timeGapEnd : setHour(timeGapEnd, timeEndHour)
  return { start: preFilledStart, end: preFilledEnd }
}

function validateCustomTime({
  selectedTime,
  timeGap,
  isStart,
}: {
  selectedTime: number
  timeGap: { start: Date; end: Date }
  isStart?: boolean
}) {
  const timeGapStart = timeGap.start.getHours()
  const timeGapEnd = timeGap.end.getHours()
  const isEndNextDay =
    timeGap.end.getDay() > timeGap.start.getDay() ||
    timeGap.end.getMonth() > timeGap.start.getMonth() ||
    timeGap.end.getFullYear() > timeGap.start.getFullYear()

  if (selectedTime <= timeGapStart) {
    return timeGap.start
  }
  if (selectedTime > timeGapStart && (selectedTime <= timeGapEnd || isEndNextDay)) {
    return setHour(isStart ? timeGap.start : timeGap.end, selectedTime)
  }
  if (selectedTime >= timeGapEnd) {
    return timeGap.end
  }
  return isStart ? timeGap.start : timeGap.end
}
