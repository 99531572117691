import type { ReactNode } from 'react'
import { ModalDetails } from '@etta/components/modal-details'
import { InfoModalHeader } from './info-modal-header'
import { InfoModalContent } from './info-modal-content'

type Props = {
  isVisible: boolean
  onClose: () => void
  children: ReactNode
  'data-tracking-id'?: string
}

function InfoModal({ isVisible, onClose, children, 'data-tracking-id': dataTrackingId }: Props) {
  return (
    <ModalDetails
      isVisible={isVisible}
      onClose={onClose}
      horizontalDimension="content-760"
      data-tracking-id={dataTrackingId}
      isAdaptivePosition>
      {children}
    </ModalDetails>
  )
}

const InfoModalNamespace = Object.assign(InfoModal, {
  Header: InfoModalHeader,
  Content: InfoModalContent,
})

export { InfoModalNamespace as InfoModal }
