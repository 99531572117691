import { Fragment } from 'react'
import { Block } from '@etta/ui/block'
import { Skeleton } from '@etta/ui/skeleton'
import { ItineraryBlock } from '../../details-skeleton-styled'
import {
  ActionsBlock,
  ActionsBody,
  ActionsContainer,
  ItineraryBlockContent,
  Separator,
  TitleWrapper,
} from './details-skeleton-mobile-styled'

const itineraryItem = (
  <ItineraryBlockContent>
    <Skeleton width="100%" height="36px">
      <rect width="30" height="30" />
      <rect x="40" width="252" height="16" />
      <rect x="40" y="24" width="70" height="12" />
    </Skeleton>
  </ItineraryBlockContent>
)

const separator = (
  <Separator>
    <Skeleton width="100%" height="1px">
      <rect width="100%" height="1" />
    </Skeleton>
  </Separator>
)

const itineraryBlockSkeleton = [
  itineraryItem,
  separator,
  itineraryItem,
  separator,
  itineraryItem,
].map((item, index) => <Fragment key={index}>{item}</Fragment>)

const action = (
  <ActionsContainer>
    <ActionsBody>
      <Block>
        <Skeleton width="132px" height="20px" variant="secondary1">
          <rect width="100%" height="100%" />
        </Skeleton>
      </Block>
      <Block>
        <Skeleton width="20px" height="20px" variant="secondary1">
          <rect width="100%" height="100%" />
        </Skeleton>
      </Block>
    </ActionsBody>
    <Block>
      <Skeleton width="100%" height="43px" variant="secondary1">
        <rect y="24" width="100%" height="1px" />
      </Skeleton>
    </Block>
  </ActionsContainer>
)

const actionBlock = new Array(3)
  .fill(action)
  .map((item, index) => <Fragment key={index}>{item}</Fragment>)

export function DetailsSkeletonMobile() {
  return (
    <>
      <TitleWrapper>
        <Skeleton width="100%" height="32px" variant="secondary1">
          <rect y="16" width="140px" height="16px" />
        </Skeleton>
      </TitleWrapper>
      <ItineraryBlock borderRadius={16}>{itineraryBlockSkeleton}</ItineraryBlock>
      <ActionsBlock>{actionBlock}</ActionsBlock>
    </>
  )
}
