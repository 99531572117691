import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { AirSearchActions } from './actions/air-search.actions'
import { AirSearchStore } from './stores/air-search/air-search.store'
import { AirSearchFilterActions } from './actions/air-search-filter.actions'
import { AirFiltersStore } from './stores/air-search/air-filters.store'
import { AirQuickFilterActions } from './actions/quick-filters.actions'
import { AirSearchQueryStore } from './stores/air-search-query.store'
import { AirSearchQueryService } from './services/air-search-query/air-search-query.service'
import { AirPriceChangeActions } from './actions/air-price-change.actions'
import { AirCheckFarePolicyStore } from './stores/air-check-fare-policy/air-check-fare-policy.store'
import { AirDynamicSiteMessagesStore } from './stores/air-dynamic-site-messages/air-dynamic-site-messages.store'

export const { useModuleContext: useAirSearchContext } = hookContextFactory({
  airSearchActions: AirSearchActions,
  airSearchFilterActions: AirSearchFilterActions,
  airQuickFilterActions: AirQuickFilterActions,
  airSearchStore: AirSearchStore,
  airFiltersStore: AirFiltersStore,
  airSearchQueryStore: AirSearchQueryStore,
  airSearchFormQueryService: AirSearchQueryService,
  airPriceChangeActions: AirPriceChangeActions,
  airCheckFarePolicyStore: AirCheckFarePolicyStore,
  airDynamicSiteMessagesStore: AirDynamicSiteMessagesStore,
})
