import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from '../types'
import { DateFieldMobile } from './mobile'
import { DateFieldDesktop } from './desktop'

export function DateFieldLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<DateFieldMobile {...props} />}
      desktopSlot={<DateFieldDesktop {...props} />}
    />
  )
}
