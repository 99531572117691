import type { Section } from '../types'
import { getCardCheck } from './get-card-check'

type Props = {
  details?: Section
  isCardOop?: boolean | null
  isExpiringCreditCardForHotelAllowed?: boolean
}

export function getBillingConditions({
  details,
  isCardOop,
  isExpiringCreditCardForHotelAllowed,
}: Props) {
  const { billingError, isCardExpiringEarlierThanHotel } = getCardCheck({
    mustHaveAddress: !!details?.billing?.metadata.isBillingAddressRequired,
    address: details?.creditCard?.billingAddress,
    isExpiringCreditCardForHotelAllowed,
    cardExpiration: details?.creditCard?.expirationDate,
    segments: details?.segments,
    isCardOop,
    isSiteCard: !!details?.creditCard?.isSiteCard,
    brandType: details?.creditCard?.brandType,
    isCardExist: !!details?.creditCard,
  })

  const restrictedBillingConditions = [
    billingError.address,
    isCardOop,
    billingError.brandType,
    billingError.brandTypeBookingCom,
    isCardExpiringEarlierThanHotel,
  ]

  const isBillingAllowed = restrictedBillingConditions.every((condition) => !condition)

  return {
    isBillingAllowed,
    isCardExpiringEarlierThanHotel,
    billingError,
  }
}
