import type { IconSizes } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { CardIcon } from '@etta/components/credit-card-item/card-icon'
import type { CreditCardBrandType } from '@etta/core/enums'
import { StartSlotBlock } from './credit-card-number-styled'

type Props = {
  brandType?: CreditCardBrandType
  isBrandTypeDisplay: boolean
  creditIconSize: IconSizes
}

export function BrandTypeWithinNumber({ isBrandTypeDisplay, brandType, creditIconSize }: Props) {
  if (isBrandTypeDisplay) {
    return (
      <Block marginLeft={8} marginRight={7}>
        <CardIcon cardVendor={brandType} />
      </Block>
    )
  }
  return (
    <StartSlotBlock iconSize={creditIconSize}>
      <Icon name="cardPWA" size={creditIconSize} />
    </StartSlotBlock>
  )
}
