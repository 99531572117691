import type { CustomFieldBookingValueObject } from '@etta/core/value-objects/custom-field-booking.value-object'
import type { CustomFieldCollectionItemExtension } from '@etta/ui/custom-field/types'

export type StoredCustomFieldBooking = Pick<
  CustomFieldBookingValueObject,
  'collectionId' | 'name'
> & {
  customUuid: string
  value: string | Date
}

export function getStoredCustomFieldsBookingList(
  customFields: CustomFieldCollectionItemExtension[],
  values: Record<string, string | Date>,
): StoredCustomFieldBooking[] {
  const result: StoredCustomFieldBooking[] = []

  customFields.forEach((collection) => {
    collection.customFields.forEach((customField) => {
      if (values[customField.customUuid]) {
        result.push({
          collectionId: collection.collectionId,
          name: customField.name,
          customUuid: customField.customUuid,
          value: values[customField.customUuid],
        })
      }
    })
  })

  return result
}
