import { Select } from '@etta/ui/select'
import type { LayoutProps } from '../types'

export function PreferenceMealOptionsMobile({
  label,
  mealOptions,
  mealValue,
  onUpdateMeal,
  withNoneOption,
}: LayoutProps) {
  return (
    <Select
      label={label}
      options={mealOptions}
      value={mealValue}
      onChange={onUpdateMeal}
      withNoneOption={withNoneOption}
    />
  )
}
