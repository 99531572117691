import { SegmentType } from '@etta/core/enums'
import { Action, Inject } from '@etta/di'
import { HistoryService } from '@etta/interface/services/history.service'
import { ROUTES } from '@fiji/routes'
import type { OpenHotelRulesModalArgs } from '@etta/components/hotel-details-modal/use-hotel-details-modal/use-room-rates-modal'
import { UnusedTicketService } from '@etta/modules/unused-tickets/interface/services/unused-ticket.service'
import { PostBookingTripDetailsStore } from '../stores/trip-details/post-booking-trip-details.store'
import { PostBookingTripStore } from '../stores/trip/post-booking-trip.store'
import { PostBookingCancellationStore } from '../stores/trip-cancellation/post-booking-cancellation.store'
import { PostBookingCancellationService } from '../services/post-booking-cancellation.service'
import { PostBookingTripService } from '../services/post-booking-trip.service'
import type {
  HotelPostBookingValueObject,
  FlightPostBookingValueObject,
  GroupedSegmentValueObject,
  CarRentalPostBookingValueObject,
} from '../../core/value-objects'
import { PostBookingCancellationPreparePropsService } from '../services/post-booking-cancellation-prepare-props.service'
import { TripStatus } from '../../core/enums'

const MINIMUM_EMPTY_SEGMENTS = 1

type OpenHotelRulesModalHandler = ({
  dailyRates,
  totalCost,
  id,
  type,
  averageRate,
  feesAndTaxes,
}: OpenHotelRulesModalArgs) => void

@Action()
export class PostBookingCancellationActions {
  constructor(
    @Inject() private postBookingCancellationStore: PostBookingCancellationStore,
    @Inject() private postBookingTripStore: PostBookingTripStore,
    @Inject() private postBookingTripDetailsStore: PostBookingTripDetailsStore,
    @Inject() private postBookingCancellationService: PostBookingCancellationService,
    @Inject() private postBookingTripService: PostBookingTripService,
    @Inject()
    private postBookingCancellationPreparePropsService: PostBookingCancellationPreparePropsService,
    @Inject() private historyService: HistoryService,
    @Inject() private readonly unusedTicketService: UnusedTicketService,
  ) {}

  async onSubmitCancel() {
    const cancellationSegment = this.postBookingCancellationStore.cancellationSegment
    switch (cancellationSegment?.type) {
      case SegmentType.Hotel: {
        return this.postBookingCancellationService.cancelHotel(cancellationSegment.id || '')
      }
      case SegmentType.CarRental: {
        return this.postBookingCancellationService.cancelCarRental(cancellationSegment.carId || '')
      }
      case SegmentType.RideHail:
        return this.postBookingCancellationService.cancelRideHail(cancellationSegment.bookingId)
    }

    if (cancellationSegment?.type === SegmentType.Flight) {
      await this.postBookingCancellationService.cancelFlight()
      await this.unusedTicketService.forceGetUnusedTickets()
      return
    }

    await this.postBookingCancellationService.cancelTrip()
    await this.unusedTicketService.forceGetUnusedTickets()
    
  }

  handleRemoveCancellationSegment() {
    this.postBookingCancellationStore.setCancellationSegment(null)
  }

  onCancelTrip() {
    this.postBookingCancellationStore.setCancellationSegment(null)
    this.postBookingCancellationStore.cancelTripToggle.handleOpen()
  }

  onCancelSegment(segment: GroupedSegmentValueObject) {
    const isMultiFlight = this.postBookingTripStore.flightSegments.length > 1
    const cancelledFlight = this.postBookingTripStore.flightSegments[0]?.segments?.some(
      ({ status }) => status === TripStatus.Cancelled,
    )
    this.postBookingCancellationStore.setCancellationSegment(segment)

    if (
      segment.type === SegmentType.Flight &&
      cancelledFlight &&
      !this.postBookingCancellationStore.canceledTripSegmentToggle.isOpen
    ) {
      this.postBookingCancellationStore.canceledTripSegmentToggle.handleOpen()
      return
    }

    if (
      segment.type === SegmentType.Flight &&
      isMultiFlight &&
      !this.postBookingCancellationStore.cancelFlightsWarningToggle.isOpen
    ) {
      this.postBookingCancellationStore.cancelFlightsWarningToggle.handleOpen()
      return
    }

    this.postBookingCancellationStore.cancelTripToggle.handleOpen()
  }

  onCancellationDone() {
    if (!this.postBookingCancellationStore.isSuccess) {
      return
    }

    this.postBookingCancellationStore.cancelTripToggle.handleClose()
    this.postBookingCancellationStore.setIsSuccess(false)
    this.postBookingCancellationStore.setIsRideHailError(false)
    this.postBookingTripDetailsStore.selectedDetailsToggle.handleClose()

    const isEmptySegments = this.postBookingTripStore.segments.length <= MINIMUM_EMPTY_SEGMENTS
    if (isEmptySegments || !this.postBookingCancellationStore.cancellationSegment) {
      this.historyService.replace(ROUTES.postBooking.trips({ isForceRefetch: true }))
      return
    }

    this.postBookingTripService.loadTrip(true)
  }

  onCloseViewState() {
    const cancellationSegment = this.postBookingCancellationStore.cancellationSegment

    const isRideHail = cancellationSegment?.type === SegmentType.RideHail

    if (isRideHail) {
      this.postBookingCancellationStore.cancelTripToggle.handleClose()
    }

    this.postBookingCancellationStore.setViewState('hidden')
  }

  onViewSupport() {
    this.historyService.push(ROUTES.support.main)
  }

  dropCancellationStore() {
    this.postBookingCancellationStore.dropCancellationStore()
  }

  prepareModalPropsData() {
    this.postBookingTripStore.trip.segments.forEach((segment) => {
      switch (segment.type) {
        case SegmentType.CarRental:
          this.postBookingCancellationPreparePropsService.handleCarRentalRulesProps(
            segment as CarRentalPostBookingValueObject,
          )
          return
        case SegmentType.Flight:
          this.postBookingCancellationPreparePropsService.handleFlightFareRulesProps(
            segment as FlightPostBookingValueObject,
          )
          return
        case SegmentType.Hotel:
          this.postBookingCancellationPreparePropsService.handleHotelRulesProps(
            segment as HotelPostBookingValueObject,
          )
      }
    })
  }

  handleOpenHotelRules = (handler: OpenHotelRulesModalHandler, hotelId: string | number) => {
    const {
      dailyRates,
      totalCost,
      id,
      averageRate,
      feesAndTaxes,
      roomType,
    } = this.postBookingCancellationStore.hotelRulesProps[hotelId]

    if (!dailyRates || !totalCost || !id || !roomType || !averageRate || !feesAndTaxes) {
      return
    }

    return handler({
      dailyRates,
      totalCost,
      id,
      type: roomType,
      averageRate,
      feesAndTaxes,
    })
  }

  handleOpenRulesModal = (
    type: SegmentType,
    id: string | number,
    hotelRulesOpenHandler: OpenHotelRulesModalHandler,
  ) => {
    switch (type) {
      case SegmentType.CarRental:
        return this.postBookingCancellationStore.carRentalRulesProps[id].toggle.handleOpen()
      case SegmentType.Flight:
        return this.postBookingCancellationStore.flightFareRulesProps[id].toggle.handleOpen()
      case SegmentType.Hotel:
        return hotelRulesOpenHandler && this.handleOpenHotelRules(hotelRulesOpenHandler, id)
    }
  }
}
