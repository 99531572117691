import { observer } from 'mobx-react-lite'
import { useProfile } from '@fiji/hooks/use-profile/use-profile'
import { Header, Links } from './components'
import { BuildInfo } from './build-info'
import { ProfileLayout } from './layout'

import { ProfileContainer } from './profile-styled'

export const Profile = observer(function Profile() {
  const { firstName, lastName, email, privacyPolicy, customLogoURL } = useProfile()

  return (
    <ProfileLayout
      headerSlot={
        <Header
          firstName={firstName}
          lastName={lastName}
          email={email}
          customLogoURL={customLogoURL}
        />
      }
      contentSlot={
        <>
          <ProfileContainer>
            <Links privacyPolicy={privacyPolicy} />
          </ProfileContainer>
          <BuildInfo />
        </>
      }
    />
  )
})
