import { useTranslation } from 'react-i18next'
import { VIRTUAL_PAY } from '@fiji/constants'
import { CheckoutPageModal } from '@etta/screens/checkout-page/checkout-page-modal'
import { CreditCardItem } from '@etta/components/credit-card-item/credit-card-item'
import { Block } from '@etta/ui/block'
import { Swap } from '@etta/ui/swap'
import { Separator } from '@etta/ui/separator'
import { AddCreditCard } from '../../add-credit-card'
import { SearchCreditCard } from '../../search-credit-card'
import { EmptyResultState } from '../../empty-result-state'
import { CreditCardList } from '../../credit-card-list'
import type { LayoutProps } from '../types'

export function PurchasePaymentInformationFormMobile({
  creditCards,
  filterValue,
  selectedCard,
  isFilterAvailable,
  isSearchResultEmpty,
  isAddNewCardAvailable,
  isHotelBooking,
  handleChangeFilterValue,
  handleOpenCreditCart,
  handleSelectCard,
  handleEditCreditCardClick,
  onClose,
  onSubmit,
  isVirtualPayEnabled,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18nBase = 'PurchasePaymentInformationForm'

  return (
    <>
      <CheckoutPageModal.Header
        title={t(`${i18nBase}.PaymentInfo`)}
        layout="search-result"
        withBorderBottom={false}
        onClose={onClose}>
        {isFilterAvailable && (
          <Block paddingBottom={16} paddingHorizontal={16}>
            <SearchCreditCard value={filterValue} onChange={handleChangeFilterValue} />
          </Block>
        )}
        <Separator />
      </CheckoutPageModal.Header>
      <CheckoutPageModal.Body withPadding={false}>
        <Block paddingHorizontal={20} paddingVertical={16}>
          {isAddNewCardAvailable && (
            <Block paddingBottom={16}>
              <AddCreditCard onClick={handleOpenCreditCart} />
            </Block>
          )}
          <Swap is={isSearchResultEmpty} isSlot={<EmptyResultState />}>
            {isHotelBooking && isVirtualPayEnabled && (
              <CreditCardItem
                cardName={t('CreditCardItem.VirtualPayCard')}
                isSelectable
                isLast
                isSelected={selectedCard === VIRTUAL_PAY}
                onChange={() => handleSelectCard(VIRTUAL_PAY)}
                cardVendor={VIRTUAL_PAY}
              />
            )}
            <CreditCardList
              creditCards={creditCards}
              selectedCard={selectedCard}
              handleSelectCard={handleSelectCard}
              handleEditCreditCardClick={handleEditCreditCardClick}
            />
          </Swap>
        </Block>
      </CheckoutPageModal.Body>
      <CheckoutPageModal.Footer
        submitLabel={t(`${i18nBase}.Button`)}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </>
  )
}
