import { RailDepartureAndArrival } from '@etta/components/rail-departure-and-arrival/rail-departure-and-arrival'
import { RailTravelAdditionalDetails } from '@etta/components/rail-travel-additional-details/rail-travel-additional-details'
import { formatTravelRailLocation } from '@fiji/utils/format-travel-rail-location'
import { getNonRailTransferIconNames } from '@fiji/utils/get-non-rail-transfer-icon-names'
import { useRailDuration } from '@fiji/hooks/use-rail-duration'
import { RailTransportationMode } from '@fiji/graphql/types'
import type { Props } from './types'

export function CollapsedView({ rail }: Props) {
  const { segments, platform, travelStops, ecoInfo, travelTime } = rail
  const getRailDuration = useRailDuration()
  const [firstSegment] = segments

  const uniqueTransportTypes = [
    ...new Set(segments.map((segment) => segment.transportationMode)),
  ].filter(
    (item) =>
      item === RailTransportationMode.Bus ||
      item === RailTransportationMode.Ferry ||
      item === RailTransportationMode.Tram ||
      item === RailTransportationMode.Unspecified,
  )
  const uniqueTransportIconNames = uniqueTransportTypes.map((item) =>
    getNonRailTransferIconNames(item),
  )

  const countStops = segments.reduce((acc, segment) => {
    if (segment.stops) {
      return acc + segment.stops.length
    }
    return acc
  }, 0)

  const departure = formatTravelRailLocation(firstSegment?.departure, rail.departureDateTime)
  const lastSegment = segments[segments.length - 1]
  const arrival = formatTravelRailLocation(lastSegment?.arrival, rail.arrivalDateTime)

  return (
    <>
      <RailDepartureAndArrival
        departure={departure}
        arrival={arrival}
        travelChanges={travelStops}
        iconNames={uniqueTransportIconNames}
      />
      <RailTravelAdditionalDetails
        platform={platform}
        ecoInfo={ecoInfo}
        railDuration={getRailDuration(travelTime)}
        travelChanges={travelStops}
        countStops={countStops}
      />
    </>
  )
}
