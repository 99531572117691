import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button/button'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Container } from './mobility-leaving-booking-flow-warning-dialog-styled'
import { useMobilityLeavingBookingFlowWarningDialog } from './use-mobility-leaving-booking-flow-warning-dialog'

type Props = {
  isOpen: boolean
}

const i18nBase = 'Mobility.LeavingBookingFlowWarningDialog'

export function MobilityLeavingBookingFlowWarningDialog({ isOpen }: Props) {
  const { t } = useTranslation()
  const { handleCancel, handleClose } = useMobilityLeavingBookingFlowWarningDialog()

  if (!isOpen) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => {}}>
      <Dialog.Body>
        <Container>
          <Block marginBottom={24}>
            <Text variant="title3" color="mainText" align="center">
              {t(`${i18nBase}.Title`)}
            </Text>
          </Block>
          <Block marginBottom={32}>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {t(`${i18nBase}.Description`)}
            </Text>
          </Block>
          <Block marginBottom={16} isFullWidth>
            <Button onClick={handleCancel} fullWidth>
              {t(`${i18nBase}.StayOnBooking`)}
            </Button>
          </Block>
          <Button variant="outline" onClick={handleClose} fullWidth>
            {t(`${i18nBase}.GoBack`)}
          </Button>
        </Container>
      </Dialog.Body>
    </Dialog>
  )
}
