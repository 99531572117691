import { useState, useEffect } from 'react'
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader'
import { bootstrapURLKeys } from '@fiji/constants/bootstrapURLKeys'

const googleMapsLoader = new GoogleMapsLoader({
  apiKey: bootstrapURLKeys.key,
  libraries: ['places', 'geometry'],
  language: 'en',
})

export function useGoogleMapLoader() {
  const [isLoading, setIsLoading] = useState(true)
  const onDone = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    googleMapsLoader.load().then(onDone).catch(onDone)
  }, [])

  return {
    isLoading,
  }
}
