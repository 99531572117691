import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Dialog } from '@etta/ui/dialog'
import { Text } from '@etta/ui/text'
import { Content } from '@etta/components/no-modify-segment/no-modify-segment-styled'

type HandleModifyFlightArgs = {
  isReturnChangeAction: boolean
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onModify: (args: HandleModifyFlightArgs) => void
  isChangingReturnFlight: boolean
  isChangingEntireFlight: boolean
}

const i18nBase = 'PostBooking.TripDetails.ChangeFlightsModal'

export function ChangeFlightsDialog({
  isOpen,
  onClose,
  onModify,
  isChangingReturnFlight,
  isChangingEntireFlight,
}: Props) {
  const { t } = useTranslation()

  const { buttonSlot, messageKey } = useMemo(() => {
    return {
      buttonSlot: (
        <>
          {isChangingEntireFlight && (
            <Button
              variant="solid"
              onClick={() => onModify({ isReturnChangeAction: false })}
              fullWidth>
              {t(`${i18nBase}.ChangeAllFlights`)}
            </Button>
          )}

          {isChangingReturnFlight && (
            <Block marginTop={isChangingEntireFlight ? 12 : 0}>
              <Button
                variant="outline"
                onClick={() => onModify({ isReturnChangeAction: true })}
                fullWidth>
                {t(`${i18nBase}.ChangeReturnFlight`)}
              </Button>
            </Block>
          )}

          {!isChangingEntireFlight && !isChangingReturnFlight && (
            <Button
              variant="solid"
              onClick={() => onModify({ isReturnChangeAction: false })}
              fullWidth>
              {t(`${i18nBase}.Change`)}
            </Button>
          )}
        </>
      ),
      messageKey: 'Message',
    }
  }, [isChangingEntireFlight, isChangingReturnFlight, onModify, t])

  return (
    <Dialog onClose={onClose} isOpen={isOpen} horizontalDimension="narrow-desktop">
      <Content>
        <Text variant="title3" color="mainText">
          {`${t(`${i18nBase}.Title`)}`}
        </Text>
        <Block marginTop={24}>
          <Text variant="footnoteMedium" color="bodyText" align="center">
            {t(`${i18nBase}.${messageKey}`)}
          </Text>
        </Block>
        <Block isFullWidth marginTop={32}>
          {buttonSlot}
        </Block>
        <Block isFullWidth marginTop={14}>
          <Button variant="link" onClick={onClose} fullWidth>
            {t(`${i18nBase}.Cancel`)}
          </Button>
        </Block>
      </Content>
    </Dialog>
  )
}
