import type { TrainLegFragment, TrainLegSegment } from '@fiji/graphql/types'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'

type Props = {
  segment?: TrainLegFragment | TrainLegSegment | null
}

export function useRailCostLabelAndName({ segment }: Props) {
  if (!segment) {
    return
  }

  const cost = (segment as TrainLegFragment).rate || (segment as TrainLegSegment).rate

  const originAddress = (segment as TrainLegFragment)?.segments?.[0]?.stations?.departure
    ?.stationName
  const destinationAddress = (segment as TrainLegFragment)?.segments?.[
    (segment?.segments?.length || 1) - 1
  ]?.stations?.arrival?.stationName

  const departureDateTime = getFormattedTime(
    new Date(deleteTimezone(String(segment.departureDateTime))),
    'EEE, MMM d, h:mm a',
  )

  return {
    cost,
    originAddress,
    destinationAddress,
    departureDateTime,
  }
}
