import { rgba } from 'polished'
import type { ColorPalette, Theme } from '@fiji/types'
import { tripReviewPageGreyColor } from '@fiji/style'

const colors: ColorPalette = {
  primary: '#D93E0A',
  primary1: '#BA2707',
  primary2: '#feb79a',
  primary3: '#FC4C02',
  secondary: '#ecedee',
  mainText: '#101820',
  mainText1: '#27313b',
  mainText2: '#ffffff',
  bodyText: '#656b72',
  bodyText1: '#71777D',
  bodyText2: '#8a8f94',
  borderDark: '#cbd0d5',
  borderLight: '#e1e6ea',
  background: '#ecedee',
  background1: '#dddfe0',
  background2: '#27313b',
  background3: '#ECEDEE',
  background4: '#f2f2f2',
  white: '#ffffff',
  error: '#D6000F',
  error1: '#fdf5f6',
  warning: '#ff9500',
  success: '#277D1E',
  segmentBus: '#009688',
  duskBlue: '#23387e',
  glacierBlue: '#7DAFD4',
  plum: '#5d295f',
  seatMap: {
    white: '#fff',
    charcoal1: '#414B54',
    charcoal2: '#5D646C',
    charcoal3: '#767B80',
    charcoal4: '#9FA5AC',
    charcoal5: '#ACB9C3',
    charcoal6: '#BCC1C7',
    charcoal61: '#BAC9D4',
    charcoal7: '#C8D4DD',
    charcoal8: '#D1D5DA',
    charcoal9: '#DADEE2',
    charcoal10: '#E6EAF0',
    charcoal11: '#EFEFF4',
    charcoal12: '#F2F5F8',
    tangerine1: '#C13A01',
    tangerine2: '#D54101',
    tangerine3: '#E54501',
    tangerine4: '#FC4C02',
    gradiant1: '#E6EAF0',
    gradiant2: '#BFC4CA',
    red1: '#FF3B30',
    dark1: '#373737',
    dark2: '#868686',
    dark3: '#EEEEEE',
  },
  transparent: 'transparent',
  ecoCheck: '#277D1E',
  ecoCheck1: '#ECF5E7',
  ecoCheck2: '#7AA9CC',
  thulianPink: '#E564A7',
}

export const etta: Theme = {
  name: 'etta',
  primaryColor: colors.mainText,
  secondaryColor: colors.mainText1,
  dateRangeColor: rgba(colors.primary, 0.14),
  dateRangeHoverColor: colors.primary2,
  dateRangeSelectedColor: colors.primary,
  btnBackground: colors.primary,
  btnBackgroundHover: colors.primary1,
  inputLabelColor: colors.bodyText,
  inputColor: colors.mainText,
  inputBorderColor: colors.background,
  inputBackgroundColor: colors.background,
  helperText: tripReviewPageGreyColor,
  colors,
}
