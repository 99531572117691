import type { Maybe } from 'fnscript'
import { Error as NestedError, Just, Nothing } from 'fnscript'
import type { Printable } from 'fnscript/dist/printable'
import type { CalculateTripEmissionsInput } from '@fiji/graphql/types'
import { validateTcFlights } from '../use-air-emissions/air-emissions-query-execute-validation'
import { validateTcCars } from '../use-car-rental-emissions/car-rental-emissions-query-execute-validation'
import { validateTcHotels } from '../use-hotel-emissions/hotel-emissions-query-execute-validation'
import { validateTcRails } from '../use-rail-emissions/rail-emissions-query-execute-validation'
import { SKIP_VALIDATION_ERROR } from './constants'

type ValidationArgs = {
  input: CalculateTripEmissionsInput
}

export function tripEmissionsInputValidation({ input }: ValidationArgs): Maybe<NestedError> {
  const inputKeys = Object.keys(input) as Array<keyof CalculateTripEmissionsInput>

  const isRailsEmpty = (input.rails && input.rails?.length === 0) || !input.rails
  const isFlightsEmpty = (input.flights && input.flights?.length === 0) || !input.flights
  const isHotelsEmpty = (input.hotels && input.hotels?.length === 0) || !input.hotels
  const isCarRentalsEmpty = (input.cars && input.cars?.length === 0) || !input.cars

  if (isRailsEmpty && isFlightsEmpty && isHotelsEmpty && isCarRentalsEmpty) {
    return Just(NestedError.new(SKIP_VALIDATION_ERROR))
  }

  const maybeRequestErrors = inputKeys.map((key) => validateSegments(key, input))
  const isValidationFailed = maybeRequestErrors.some((error) => error.isValue())

  if (isValidationFailed) {
    return Just(
      NestedError.new(
        maybeRequestErrors
          .map((curr) => curr.isValue() && curr.getValue())
          .filter((error) => error)
          .join(';'),
      ),
    )
  }

  return Nothing()
}

function validateSegments(
  key: keyof CalculateTripEmissionsInput,
  input: CalculateTripEmissionsInput,
): Maybe<NestedError> {
  const validators = {
    cars: validateTcCars,
    flights: validateTcFlights,
    hotels: validateTcHotels,
    rails: validateTcRails,
  }

  const errors: NestedError[] = []
  const validationResult = validators[key](input[key] as any)

  if (validationResult.isValue()) {
    errors.push(NestedError.new(validationResult.getValue() as Printable))
  }

  if (errors.length > 0) {
    const validationError = NestedError.new(errors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}
