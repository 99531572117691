import type { Rate } from '@fiji/graphql/types'
import type { ExtractedCosts } from './types'

export function validatedCosts(costs: ExtractedCosts[]): Rate | null {
  if (costs.length === 0 || !costs.find((unit) => unit.cost)) {
    return null
  }

  const primaryValue = costs.map((unit) => unit.cost.primary.amount).reduce((sum, val) => sum + val)
  const secondaryValue = costs
    .map((unit) => unit.cost.secondary?.amount)
    .reduce((sum, val) => (sum || 0) + (val || 0))

  if (!primaryValue) {
    return null
  }

  return {
    primary: {
      amount: primaryValue,
      currency: costs.find((unit) => unit.cost)?.cost.primary.currency || '',
    },
    secondary: secondaryValue
      ? {
          amount: secondaryValue,
          currency: costs.find((unit) => unit.cost)?.cost.secondary?.currency || '',
        }
      : null,
  }
}
