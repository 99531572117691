import { useGetCarRulesQuery } from '@fiji/graphql/hooks'

type Props = {
  itineraryId: string
  carSegmentId: string
}

export function useRulesModal({ itineraryId, carSegmentId }: Props) {
  const { data, loading: isLoading, error: isError, refetch } = useGetCarRulesQuery({
    variables: { itineraryId, carSegmentId },
  })

  const carRules = data?.carRules || []

  const onReload = () => {
    refetch && refetch()
  }

  return {
    carRules,
    isLoading,
    isError,
    onReload,
  }
}
