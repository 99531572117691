import { TextField } from '@etta/ui/text-field'
import type { SingleFieldViewProps } from '../types'

export function Search({
  size,
  value,
  helperType,
  helperText,
  label,
  placeholder,
  isDisabled,
  onClick,
  onClear,
  'data-tracking-id': dataTrackingId,
}: SingleFieldViewProps) {
  return (
    <TextField
      data-tracking-id={dataTrackingId}
      onClick={onClick}
      onClear={onClear}
      label={label}
      placeholder={placeholder}
      value={value}
      size={size}
      type="search"
      skin="default"
      isDisabled={isDisabled}
      helperText={helperText}
      helperType={helperType}
    />
  )
}
