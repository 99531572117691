import { Modal } from '@etta/ui/modal'
import type { Props } from './types'
import { useAlignDatesModal } from './use-align-dates-modal'
import { AlignDatesModalContent } from './align-dates-modal-content'

export function AlignDatesModal({
  handleClose,
  handleSubmit,
  isLoading,
  mistmatchedSegments,
  tripFlightSegments,
  isOpen,
  flightStartDateTime,
  lastFlightStartDateTime,
}: Props) {
  const { handleSelect, selectedTypes } = useAlignDatesModal({
    mistmatchedSegments,
    isOpen,
  })
  return (
    <Modal
      position="right"
      isVisible={isOpen}
      handleModalVisibility={handleClose}
      horizontalDimension="content-760">
      <AlignDatesModalContent
        handleSelect={handleSelect}
        selectedTypes={selectedTypes}
        mismatchedSegments={mistmatchedSegments}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        tripFlightSegments={tripFlightSegments}
        isLoading={isLoading}
        flightStartDateTime={flightStartDateTime}
        lastFlightStartDateTime={lastFlightStartDateTime}
      />
    </Modal>
  )
}
