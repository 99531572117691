import { Inject, Service } from '@etta/di'
import { CookieStore } from '@etta/modules/cookie'
import { TravelPolicyAdapter } from '../../infra'
import { TravelPolicyStore } from '../stores'

@Service()
export class TravelPolicyService {
  constructor(
    @Inject()
    private readonly travelPolicyAdapter: TravelPolicyAdapter,
    @Inject()
    private readonly travelPolicyStore: TravelPolicyStore,
    @Inject()
    private readonly cookieStore: CookieStore,
  ) {}

  async loadDisplayConfiguration() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    this.travelPolicyStore.setIsLoading(true)

    const result = await this.travelPolicyAdapter.getTravelPolicy()

    result.match({
      Err: () => {
        this.travelPolicyStore.setIsLoading(false)
      },
      Ok: (data) => {
        this.travelPolicyStore.setTravelPolicy(data)
        this.travelPolicyStore.setIsLoading(false)
      },
    })
  }

  async refetchTravelPolicy() {
    const isLoggedIn = this.cookieStore.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    const result = await this.travelPolicyAdapter.getTravelPolicy()

    result.match({
      Err: () => {},
      Ok: (data) => {
        this.travelPolicyStore.setTravelPolicy(data)
      },
    })
  }
}
