import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderLight};
  padding-bottom: 16px;

  & + & {
    padding-top: 16px;
  }
`
