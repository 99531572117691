import { useTranslation } from 'react-i18next'
import type { FlightSafetyCheckDetails } from '@fiji/types'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Swap } from '@etta/ui/swap'
import { NoImageIcon } from '../../images'
import {
  Container,
  LinkWrapper,
  ItemContainer,
  OpeningImageWrapper,
  DisclaimerBlock,
} from './flight-safety-check-item-desktop-styled'

type Props = {
  detail: FlightSafetyCheckDetails
  isAirlineName: boolean
  isDisclaimer: boolean
  isSubtitle: boolean
}

export function FlightSafetyCheckItemDesktop({
  detail,
  isAirlineName,
  isDisclaimer,
  isSubtitle,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'
  const linkLabel = `${t(i18nBase + '.ViewDetails')}-button`
  const { imageUrl, headline, description, url } = detail

  if (isAirlineName || isSubtitle) {
    return (
      <Block paddingTop={38} paddingBottom={15}>
        <Text aria-label={headline} variant="title3">
          {headline}
        </Text>
      </Block>
    )
  }

  if (isDisclaimer) {
    return (
      <Container>
        <DisclaimerBlock>
          <Text aria-label={headline} variant="headline">
            {t(i18nBase + '.Disclaimer')}
          </Text>
          <Text variant="footnoteMedium">{description}</Text>
        </DisclaimerBlock>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <ItemContainer>
          <OpeningImageWrapper>
            <Swap
              is={!!imageUrl}
              isSlot={<img src={imageUrl} alt={headline} data-tracking-id={headline} />}>
              <NoImageIcon />
            </Swap>
          </OpeningImageWrapper>
          <Block marginTop={-2}>
            <Block marginBottom={4}>
              <Text color="mainText" aria-label={headline} variant="subHeadStrong">
                {headline}
              </Text>
            </Block>
            <Text color="bodyText" variant="footnoteMedium" aria-label={description}>
              {description}
            </Text>
            {url && (
              <LinkWrapper>
                <Link
                  size="small"
                  href={url}
                  target="_blank"
                  aria-label={linkLabel}
                  data-tracking-id={linkLabel}>
                  {t(i18nBase + '.ViewDetails')}
                </Link>
              </LinkWrapper>
            )}
          </Block>
        </ItemContainer>
      </Container>
      <Separator />
    </>
  )
}
