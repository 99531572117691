import { HotelCarouselCard } from '@etta/components/hotel-carousel-card/hotel-carousel-card'
import type { Hotel } from '@fiji/graphql/types'
import { Block } from '@etta/ui/block'
import { Carousel } from '@etta/ui/carousel'
import { HotelCardsCarouselPlaceholder } from './components'

type Props = {
  isLoading?: boolean
  slide: number
  onSlideChange?: (selected: number) => void
  hotels: Array<Hotel>
  onCardClick: (hotelId: string) => void
}

export function HotelCardsCarousel({
  isLoading,
  hotels,
  slide,
  onSlideChange,
  onCardClick,
}: Props) {
  if (isLoading) {
    return <HotelCardsCarouselPlaceholder />
  }

  return (
    <Block marginBottom={10}>
      <Carousel isCenterMode centerPadding={15} currentSlide={slide} onSlideChange={onSlideChange}>
        {hotels.map((hotel) => (
          <HotelCarouselCard hotel={hotel} key={hotel.id} onClick={onCardClick} />
        ))}
      </Carousel>
    </Block>
  )
}
