import ScoreScale0 from './assets/score-scale-0.png'
import ScoreScale10 from './assets/score-scale-10.png'
import ScoreScale20 from './assets/score-scale-20.png'
import ScoreScale30 from './assets/score-scale-30.png'
import ScoreScale40 from './assets/score-scale-40.png'
import ScoreScale50 from './assets/score-scale-50.png'
import ScoreScale60 from './assets/score-scale-60.png'
import ScoreScale70 from './assets/score-scale-70.png'
import ScoreScale80 from './assets/score-scale-80.png'
import ScoreScale90 from './assets/score-scale-90.png'
import ScoreScale100 from './assets/score-scale-100.png'

export const SCORE_SCALES = [
  ScoreScale0,
  ScoreScale10,
  ScoreScale20,
  ScoreScale30,
  ScoreScale40,
  ScoreScale50,
  ScoreScale60,
  ScoreScale70,
  ScoreScale80,
  ScoreScale90,
  ScoreScale100,
]

export const getScaleImage = (score: number) => {
  if (score <= 0) {
    return SCORE_SCALES[0]
  }
  if (score > 100) {
    return SCORE_SCALES[10]
  }
  return SCORE_SCALES[Math.ceil(score / 10)]
}
