import { useScreenType, ScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'

type Props = {
  dates: string
  roomType?: string
  roomNights: string
}

export function HotelRoomDatesAndType({ dates, roomType, roomNights }: Props) {
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  if (isMobile) {
    const nightsAndRoom = [roomNights, roomType].filter(Boolean).join(' • ')
    return (
      <>
        {dates && (
          <Text variant="footnoteMedium" color="bodyText1">
            {dates}
          </Text>
        )}
        {nightsAndRoom && (
          <Text variant="footnoteMedium" color="bodyText1">
            {nightsAndRoom}
          </Text>
        )}
      </>
    )
  }

  const datesWithNightsAndRoom = [dates, roomNights, roomType].filter(Boolean).join(' • ')

  return (
    <Text variant="footnoteMedium" color="bodyText1">
      {datesWithNightsAndRoom}
    </Text>
  )
}
