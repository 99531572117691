export enum Device {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS',
}

export type NotificationPhone = {
  country?: number | null
  isoCountryCode: string
  number: string
}

export type NotificationEntity = {
  id: number
  device: Device
  email?: string | null
  phone?: NotificationPhone | null
  topic: string
}
