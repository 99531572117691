import { useTimer } from 'react-timer-hook'
import { useEffect } from 'react'

type Args = {
  intervalInSeconds: number
  isRunning: boolean
  handleOnExpire: () => void
}

export function useIntervalTimer({ intervalInSeconds, isRunning, handleOnExpire }: Args) {
  const { pause, restart, isRunning: isTimerRunning } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      handleOnExpire()
    },
    autoStart: false,
  })

  useEffect(() => {
    if (!isTimerRunning && isRunning) {
      const time = new Date()
      time.setSeconds(time.getSeconds() + intervalInSeconds)

      restart(time)
    }

    if (!isRunning) {
      pause()
    }
  }, [intervalInSeconds, restart, pause, isRunning, isTimerRunning])
}
