import styled from 'styled-components'

export const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  height: 205px;
  display: grid;
  grid-template-columns: 192px 1fr 224px;
  gap: 32px 32px;
  border-radius: 10px;
`

export const LeftBlock = styled.div`
  padding: 24px 0 0px 24px;
`

export const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 0 0;
`

export const Footer = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  margin-top: auto;
  border-top: dashed ${({ theme }) => theme.colors.borderLight};
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 24px 24px 0;
`
