import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import type { HotelPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { tripReviewFormatDate, TripReviewDateType } from '@fiji/utils/dates/trip-review-format-date'
import { Segment, Title } from './hotel-booking-failed-styled'

type Props = {
  segments: HotelPostBookingValueObject[]
}

export function HotelBookingFailed({ segments }: Props) {
  const { t } = useTranslation()
  const i18Base = 'TripReview.HotelBookingFailed'

  if (!segments?.length) {
    return null
  }

  return (
    <Segment aria-hidden>
      <Block>
        <Icon name="infoOutlinePWA" color="error" />
        <Title>{t(i18Base + '.Title')}</Title>
      </Block>
      <Block marginTop={10}>{t(i18Base + '.Description')}</Block>
      {segments.map((segment, index) => {
        const checkIn = tripReviewFormatDate(TripReviewDateType.DayAndDate, segment.checkIn)
        const checkOut = tripReviewFormatDate(TripReviewDateType.DayAndDate, segment.checkOut)
        return (
          <Fragment key={index}>
            <Block marginTop={15}>
              <Text variant="footnoteStrong" color="mainText">
                {segment.name}
              </Text>
            </Block>
            <Block>
              <Text variant="footnoteStrong" color="bodyText">
                {checkIn} {checkOut && '–'} {checkOut}
              </Text>
            </Block>
            <Block>
              <Text variant="footnoteStrong" color="bodyText">
                {t(i18Base + '.Nights', { count: segment.numberOfNights })} • 1 x {segment.roomType}{' '}
                {t(i18Base + '.Room')}
              </Text>
            </Block>
          </Fragment>
        )
      })}
    </Segment>
  )
}
