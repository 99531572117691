import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function useTripErrorsModal() {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'
  const headerTitle = t(`${i18Base}Title`)
  const [isApplied, setApply] = useState(false)
  const handleChangeApply = useCallback(() => {
    setApply((value) => !value)
  }, [])

  return {
    headerTitle,
    isApplied,
    handleChangeApply,
  }
}
