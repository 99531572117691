import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const BookATripButton = styled(Button)`
  padding: 12px 68px;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: ${(props) => props.theme.colors.plum};
`
