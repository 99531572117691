import styled, { css } from 'styled-components'

const ContainerWrapperCSS = css`
  border-radius: 14px;
  min-width: 320px;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.06);
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  z-index: 11;
  overflow: hidden;
`

export const ContainerWrapper = styled.div<{ withTopPadding?: boolean }>`
  width: 100%;
  max-height: 520px;
  ${ContainerWrapperCSS}
`

export const Wrapper = styled.div<{
  withTopPadding?: boolean
  top?: number
  left?: number
  right?: number
}>`
  ${ContainerWrapperCSS};
  top: ${({ top }) => (top ? top + 'px' : 'unset')};
  left: ${({ left }) => (left ? left + 'px' : 'unset')};
  right: ${({ right }) => (right ? right + 'px' : 'unset')};
`

export const BackgroundWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 10;
`

export const Anchor = styled.div``

const ListBodyStyle = css<{ withoutPadding?: boolean }>`
  padding-left: 16px;
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 6px;
    margin: 7px 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    width: 6px;
    background-color: ${({ theme }) => theme.colors.borderDark};
  }
  ${({ withoutPadding }) =>
    withoutPadding &&
    css`
      padding-left: 0;
    `}
`

export const ListBody = styled.div<{ withoutPadding?: boolean; height?: number }>`
  overflow: hidden auto;
  max-height: 390px;
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
  ${ListBodyStyle}
`

const BOTTOM_DISTANCE = 10

export const FullHeightListBody = styled.div<{ withoutPadding?: boolean; excludeHeight?: number }>`
  ${ListBodyStyle}
  overflow: auto;

  max-height: calc(100vh - ${({ excludeHeight = 210 }) => excludeHeight + BOTTOM_DISTANCE}px);
`
