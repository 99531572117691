import { MediaLayout } from '@etta/ui/media-layout'
import { RedressNumberFormDesktop } from './desktop'
import { RedressNumberFormMobile } from './mobile'

export type Props = {}

export function RedressNumberFormLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<RedressNumberFormMobile {...props} />}
      desktopSlot={<RedressNumberFormDesktop {...props} />}
    />
  )
}
