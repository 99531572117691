export default function () {
  return (
    <svg
      width="100"
      height="85"
      viewBox="0 0 100 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="42.4999" cy="42.4999" r="42.4999" fill="currentColor" />
      <path
        d="M99.9999 22.8789C99.9999 33.2101 91.6231 41.5853 81.2935 41.5853C70.962 41.5853 62.5872 33.2101 62.5872 22.8789C62.5872 12.5477 70.962 4.17249 81.2935 4.17249C91.6231 4.17249 99.9999 12.5477 99.9999 22.8789Z"
        fill="#EFEFF4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96.1555 23.0437C96.1555 31.1603 89.5755 37.7407 81.4568 37.7407C73.3397 37.7407 66.7598 31.1603 66.7598 23.0437C66.7598 14.9254 73.3397 8.34497 81.4568 8.34497C89.5755 8.34497 96.1555 14.9254 96.1555 23.0437Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.745 13.774C81.1965 12.9596 82.3229 12.9596 82.7743 13.774L90.2367 27.2361C90.6888 28.0518 90.1249 29.0723 89.2221 29.0723H74.2973C73.3944 29.0723 72.8305 28.0518 73.2826 27.2361L80.745 13.774ZM80.8506 18.1632L80.8505 22.7087L81.7597 23.6178L82.6687 22.7087L82.6688 18.1632H80.8506ZM80.6803 25.9296C80.6805 25.3238 81.1636 24.8325 81.7597 24.8325C82.3558 24.8325 82.8391 25.3239 82.839 25.9298C82.8389 26.5356 82.3556 27.0268 81.7594 27.0268C81.1634 27.0268 80.6802 26.5353 80.6803 25.9296Z"
        fill="white"
      />
      <path
        d="M22.6415 35.7699H26.3994L27.9026 37.2156L31.9111 29.9872C32.4122 29.0234 33.6649 28.0596 35.1681 28.0596H50.701C52.2042 28.0596 53.4569 29.0234 54.2085 30.2281L57.7159 37.2156L58.9686 35.7699H62.7266C62.7266 37.6975 61.2234 39.1432 59.2191 39.1432H58.7181L60.9728 43.4803V53.8411V57.4553H56.2127L54.7095 53.8411L30.6585 54.082L29.1553 57.4553H24.3952V54.082V43.4803L26.65 39.1432H26.1489C24.1447 39.1432 22.6415 37.6975 22.6415 35.7699ZM51.4526 31.9147C50.9516 31.1919 50.2 30.4691 49.1978 30.4691H36.4207C35.6691 30.4691 34.9175 30.951 34.4165 31.6738L30.6585 38.9023H54.7095L51.4526 31.9147ZM52.4548 47.0945L55.7117 45.8898C57.4654 45.4079 57.7159 44.2031 56.9643 42.9984L50.2 45.4079L52.4548 47.0945ZM29.9069 45.6488L33.4143 47.0945L35.9197 45.6488L28.6542 42.7574C27.6521 43.9622 27.9026 44.926 29.9069 45.6488Z"
        fill="white"
      />
    </svg>
  )
}
