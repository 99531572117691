import { dateFormat } from '@fiji/utils/dates/date-format'

export function dateToStringWithoutTZ(dateValue?: Date) {
  if (!dateValue) {
    return ''
  }
  const date = dateFormat(dateValue, 'yyyy-MM-dd')
  const time = dateFormat(dateValue, 'HH:mm:ss.SSS')

  return [date, time].join('T')
}
