import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin: 16px;
  border-radius: 10px;
  padding: 18px 20px;

  &:not(:last-child) {
    margin-bottom: 0;
  }
`
export const LeftBlock = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const RightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Footer = styled.div`
  padding-top: 14px;
  display: flex;
  justify-content: flex-end;
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
`
