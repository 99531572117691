import { Result } from 'fnscript'
import { Inject, Adapter } from '@etta/di'
import { screenMatcher, ScreenType } from '@fiji/modes'
import { DeviceClass } from '@fiji/graphql/types'
import { ActionTrackingService } from '@etta/modules/action-tracker'
import type { PurchaseInfoValueObject } from '../../core/value-objects'
import type { GetPurchaseInfoArgs, PurchaseInfoResults, GetPostPurchaseInfoArgs } from './types'
import { PurchaseInfoDataProvider } from './purchase-info.data-provider'
import { PurchaseInfoMapper } from './mappers'

const ACTION_NAME = 'purchase-info'
@Adapter()
export class PurchaseInfoAdapter {
  constructor(
    @Inject()
    private purchaseInfoDataProvider: PurchaseInfoDataProvider,
    @Inject()
    private actionTrackingService: ActionTrackingService,
  ) {}

  async getPurchaseInfo({
    itineraryId,
    forceUpdate,
    isCCNumberMasked,
  }: GetPurchaseInfoArgs): PurchaseInfoResults<PurchaseInfoValueObject> {
    const deviceClass =
      screenMatcher.getScreenType() === ScreenType.Desktop
        ? DeviceClass.Desktop
        : DeviceClass.Mobile

    const { data, error } = await this.purchaseInfoDataProvider.getPurchaseInfo(
      {
        itineraryId,
        deviceClass,
        isCCNumberMasked,
      },
      forceUpdate,
    )

    if (error) {
      this.actionTrackingService.track({
        status: 'error',
        message: error.message,
        name: ACTION_NAME,
      })
      return Result.Err(new Error(error.message))
    }

    return Result.Ok(PurchaseInfoMapper.toValueObject(data.purchaseInfo))
  }

  async getPostPurchaseInfo({
    itineraryId,
    bookingId,
    forceUpdate,
    isCCNumberMasked,
  }: GetPostPurchaseInfoArgs): PurchaseInfoResults<PurchaseInfoValueObject> {
    const deviceClass =
      screenMatcher.getScreenType() === ScreenType.Desktop
        ? DeviceClass.Desktop
        : DeviceClass.Mobile

    const { data, error } = await this.purchaseInfoDataProvider.getPostPurchaseInfo(
      {
        itineraryId,
        deviceClass,
        bookingId,
        isCCNumberMasked,
      },
      forceUpdate,
    )

    if (error) {
      this.actionTrackingService.track({
        status: 'error',
        message: error.message,
        name: ACTION_NAME,
      })
      return Result.Err(new Error(error.message))
    }

    return Result.Ok(PurchaseInfoMapper.toValueObject(data.postPurchaseInfo))
  }
}
