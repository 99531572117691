import type { SearchRailLegSubSegmentEntity } from '@etta/modules/rail/core/entities/search-rail-leg-subsegment.entity'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { LONDON_UNDERGROUND_TUBE_URL, RAIL_LOGO_GENERIC } from '@fiji/constants'

export function getRailLogos(segments: SearchRailLegSubSegmentEntity[]): string[] {
  return segments
    .map((segment) => {
      if (segment.transportationMode === RailTransportationModeEnum.Train) {
        return segment.carrier?.logoImageUrl || RAIL_LOGO_GENERIC
      }
      if (
        segment.transportationMode === RailTransportationModeEnum.Unspecified ||
        segment.transportationMode === RailTransportationModeEnum.Metro
      ) {
        return LONDON_UNDERGROUND_TUBE_URL
      }

      return ''
    })
    .filter(Boolean)
}
