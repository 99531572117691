import { PlaceAutocompleteType } from '@fiji/graphql/types'
import { SearchByType, AlgoliaLocationType } from '@fiji/enums'
import type { Place } from '@fiji/types'

export function getIconName(place: Place) {
  switch (true) {
    case place.placeType === PlaceAutocompleteType.AlgoliaPlaces &&
      place.locationType === SearchByType.Station:
      return 'railPWA'
    case !!place.airportCode:
      return 'flightPWA'
    // @ts-ignore TypeScript thinks `placeType` can't be ReferencePoint
    case place.placeType === PlaceAutocompleteType.ReferencePoint:
      return 'organisationPWA'
    case place.locationType === AlgoliaLocationType.StationGroup:
      return 'organisationPWA'
    default:
      return 'searchPinPWA'
  }
}
