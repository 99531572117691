import { useTranslation } from 'react-i18next'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { Rate } from '@fiji/graphql/types'
import { CostContainer, Text } from '../payment-summary-styled'

type Props = {
  feesAndTaxes?: Rate | null
  showGSTIncluded?: boolean
}

const i18nBase = 'PaymentSummary.'

export function FeesAndTaxes({ feesAndTaxes, showGSTIncluded }: Props) {
  const { t } = useTranslation()

  if (!feesAndTaxes) {
    return null
  }

  const cost = formatRate(feesAndTaxes, { morpheme: 'none' }).mainCost

  return (
    <CostContainer aria-label={t(i18nBase + 'TaxesAndFeesAccessibilityLabel', { amount: cost })}>
      <Text data-tracking-id="taxes-and-fees-text">
        {showGSTIncluded ? t(i18nBase + 'TaxesAndFeesGST') : t(i18nBase + 'TaxesAndFees')}
      </Text>
      <Text data-tracking-id="taxes-and-fees-price-text">{cost}</Text>
    </CostContainer>
  )
}
