import 'react-bnb-gallery/dist/style.css'
import { css } from 'styled-components'

export const reactBnbGalleryStyled = css`
  .hide .gallery-figcaption--inner {
    transform: translateY(70px);
  }

  .gallery-modal button {
    outline: none;
  }
`
