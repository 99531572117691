import { Action, Inject } from '@etta/di'
import { QualtricsStore } from '../store/qualtrics.store'

@Action()
export class QualtricsActions {
  constructor(@Inject() private readonly qualtricsStore: QualtricsStore) {}

  handleDropStore() {
    this.qualtricsStore.dropStore()
  }
}
