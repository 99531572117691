import type { TripCost } from '@fiji/graphql/types'
import { sumRate } from './sum-rate'

export function calculateTripTotal(tripCosts?: TripCost[] | null) {
  if (!tripCosts || tripCosts.length === 0) {
    return null
  }

  const { total, base, feesAndTaxes, transactionFee, serviceFee } = tripCosts[0]
  return tripCosts.slice(1).reduce(
    (totalCost, tripCost) => {
      return {
        base: sumRate({ totalRate: totalCost.base, itemRate: tripCost.base }) || totalCost.base,
        total: sumRate({ totalRate: totalCost.total, itemRate: tripCost.total }) || totalCost.total,
        feesAndTaxes: sumRate({
          totalRate: totalCost.feesAndTaxes,
          itemRate: tripCost.feesAndTaxes,
        }),
        transactionFee: sumRate({
          totalRate: totalCost.transactionFee,
          itemRate: tripCost.transactionFee,
        }),
        serviceFee: sumRate({
          totalRate: totalCost.serviceFee,
          itemRate: tripCost.serviceFee,
        }),
      }
    },
    { total, base, feesAndTaxes, transactionFee, serviceFee },
  )
}
