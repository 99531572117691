import { useRef } from 'react'
import { HotelMap } from '@etta/components/hotel-map/hotel-map'
import type { LayoutProps } from '../types'
import { Container, ButtonWrapper } from './hotel-result-desktop-styled'
import { HotelCardBlock } from './hotel-card-block/hotel-card-block'

export const HotelResultsMapDesktop = ({
  hotels,
  distance,
  referencePoints,
  onBoundsLeave,
  onBoundsReturn,
  searchAreaSlot,
  centerPoint,
  onSelect,
  selectedHotel,
  onCardClick,
}: LayoutProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <Container ref={ref}>
      {!!searchAreaSlot && <ButtonWrapper>{searchAreaSlot}</ButtonWrapper>}
      <HotelMap
        parentRef={ref}
        center={centerPoint}
        hotels={hotels}
        onSelect={onSelect}
        selectedId={selectedHotel?.id}
        distance={distance}
        referencePoints={referencePoints}
        onBoundsLeave={onBoundsLeave}
        onBoundsReturn={onBoundsReturn}
        onAnnotationDeselect={() => onSelect(null)}
        selectedHotelCardSlot={
          selectedHotel && (
            <HotelCardBlock
              parentRef={ref}
              hotel={selectedHotel}
              onSelect={onCardClick}
              onClose={() => onSelect(null)}
            />
          )
        }
      />
    </Container>
  )
}
