import type { ChangeEvent } from 'react'
import { useMemo } from 'react'
import type {
  TextFieldHelperType,
  TextFieldSize,
  TextFieldType,
  TextFieldValue,
} from '@etta/ui/text-field/types'

type Args = {
  onChange?: (text: string) => void
  onClick?: () => void
  valueParser?: (oldValue: string, newValue: string) => string
  value: TextFieldValue
  maxLength?: number
  helperText?: string
  helperType?: TextFieldHelperType
  type?: TextFieldType
  isDisabled?: boolean
  size?: TextFieldSize
  placeholder?: string
  label?: string
  isLabelEnabled: boolean
}

export function useBaseTextField({
  onChange,
  onClick,
  valueParser,
  value,
  maxLength,
  helperText,
  helperType,
  type,
  isDisabled,
  size,
  placeholder,
  label,
  isLabelEnabled,
}: Args) {
  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }

    const { value: newValue } = e.currentTarget
    const checkValue = valueParser ? valueParser(String(value), String(newValue)) : newValue
    const isMaxLength =
      maxLength !== undefined && checkValue.length && checkValue.length > maxLength
    const usedValue = isMaxLength ? checkValue.slice(0, maxLength) : checkValue
    onChange(usedValue)
  }

  const hasValue = !!value
  const isError = helperType === 'error' && !!helperText
  const hasNoBorder = helperType === 'no-border'

  const inputType = useMemo(() => {
    switch (type) {
      case 'phone':
        return 'text'
      case 'numeric':
      case 'decimal':
        return 'number'
      default:
        return type
    }
  }, [type])

  const isClickable = !isDisabled && !!onClick
  const handleClick = isClickable ? onClick : undefined

  const isSmallSize = size === 'small'
  const isLabelExists = isSmallSize ? false : !!label
  const usedPlaceholder = useMemo(() => {
    if (!isSmallSize) {
      return label === placeholder ? '' : placeholder
    }
    if (!hasValue) {
      return label || placeholder
    }
    return placeholder || label
  }, [hasValue, isSmallSize, label, placeholder])

  const displayLabel = useMemo(
    () => isLabelEnabled && isLabelExists && (!usedPlaceholder || hasValue),
    [isLabelExists, usedPlaceholder, hasValue, isLabelEnabled],
  )

  return {
    usedPlaceholder,
    isLabelExists,
    handleClick,
    isClickable,
    inputType,
    hasNoBorder,
    isError,
    hasValue,
    handlerChange,
    displayLabel,
  }
}
