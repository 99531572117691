import styled from 'styled-components'

export const IconRow = styled.div`
  margin-right: 8px;
  display: flex;
  width: 17px;
  height: 19px;
  align-items: center;
  justify-content: center;
`
