import styled from 'styled-components'

const BADGE_SIZE = '58px'

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const Badge = styled.img`
  margin-left: 12px;
  width: ${BADGE_SIZE};
  height: ${BADGE_SIZE};
  align-self: flex-start;
`

export const ButtonWrapper = styled.a`
  text-decoration: none;
`
