import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'
import { useCabinClassLabels } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-labels'
import { useSeatmapCabinClasss } from '@fiji/hooks/use-cabin-class-configuration/use-cabin-class-options'
import type { Props } from '../types'
import {
  Container,
  InputContainer,
  CabinClassSelectorContainer,
  Title,
  Radio,
} from './cabin-class-mobile-styled'

export function CabinClassMobile({
  pickedCabinClass,
  onChange,
  isVisible,
  onClose,
  'data-tracking-id': dataTrackingId,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'FlightSearch.CabinClass'

  const { getCabinClassLabel } = useCabinClassLabels()
  const { cabinClassesOptions } = useSeatmapCabinClasss()

  const [selectedCabinClass, setSelectedCabinClass] = useState(pickedCabinClass)
  const title = t(i18Base + '.Title')

  const handleClose = () => {
    if (pickedCabinClass.id !== selectedCabinClass.id) {
      setSelectedCabinClass(pickedCabinClass)
    }
    onClose()
  }

  const handleSubmit = () => {
    onChange(selectedCabinClass)
    onClose()
  }

  return (
    <Modal isVisible={isVisible} handleModalVisibility={handleClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body>
        <Container>
          <Title role="heading" aria-level={3} textVariant={StyledTextVariant.headline}>
            {title}
          </Title>
          <CabinClassSelectorContainer>
            {cabinClassesOptions.map((cabinClass) => {
              const label = getCabinClassLabel(cabinClass.id)
              return (
                <InputContainer key={cabinClass.id}>
                  <Radio
                    isChecked={cabinClass.id === selectedCabinClass.id}
                    onChange={() => {
                      setSelectedCabinClass(cabinClass)
                    }}
                    data-tracking-id={`${dataTrackingId}_option_${label}`}>
                    {label}
                  </Radio>
                </InputContainer>
              )
            })}
          </CabinClassSelectorContainer>
          <Block marginTop="auto" marginBottom={16} marginHorizontal={16}>
            <Button onClick={handleSubmit} variant="solid" fullWidth>
              {t(i18Base + '.ConfirmButton')}
            </Button>
          </Block>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
