import type { ReactNode } from 'react'
import type { RoomRatesModalLayout } from '../types'
import { WideModal } from './wide-modal'
import { RegularModal } from './regular-modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  layout?: RoomRatesModalLayout
}

export function RoomRatesModalWrapper({ children, isOpen, onClose, layout }: Props) {
  const dataTrackingId = 'room-rates-modal'

  if (layout === 'wide-left-slide') {
    return (
      <WideModal data-tracking-id={dataTrackingId} isOpen={isOpen} onClose={onClose}>
        {children}
      </WideModal>
    )
  }

  return (
    <RegularModal data-tracking-id={dataTrackingId} isOpen={isOpen} onClose={onClose}>
      {children}
    </RegularModal>
  )
}
