import { format as dateFormat } from 'date-fns'
import { TZDate } from '@date-fns/tz'
import { deleteTimezone } from './delete-timezone'

const format = "EEEE',' MMMM d 'at' p"

export const formatCancellationDate = (date: string, timeZone?: string | null) => {
  const dateObj = timeZone ? new TZDate(date, timeZone) : new Date(deleteTimezone(date))

  if (!timeZone) {
    return dateFormat(dateObj, format)
  }

  const formattedDate = dateFormat(dateObj, format)

  const timeZoneName = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short',
  })
    .formatToParts(dateObj)
    .find((part) => part.type === 'timeZoneName')?.value

  return timeZoneName ? `${formattedDate} ${timeZoneName}` : formattedDate
}
