import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { MessageSectionMobile } from './mobile'
import { MessageSectionDesktop } from './desktop'

export function MessageSectionLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<MessageSectionMobile {...props} />}
      desktopSlot={<MessageSectionDesktop {...props} />}
    />
  )
}
