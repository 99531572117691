import styled from 'styled-components'

export const LuggageButton = styled.button`
  margin-top: 12px;
  width: 100%;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 8px;
`
export const ButtonTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
export const Row = styled.div<{ isFooter?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) =>
    p.isFooter &&
    `
  padding-left:4px;`}
`
