import { useTranslation } from 'react-i18next'
import type { Concern } from '../../../types'
import {
  Container,
  Score,
  ConcernContainer,
  ConcernTitle,
  ConcernScoreScaleContainer,
  ConcernScoreScaleBackground,
  ConcernScoreScaleFillContainer,
  ConcernScoreScaleFill,
} from './score-list-item-styled'
import { useScoreListItem } from './use-score-list-item'

type Props = Concern & {
  isVisible: boolean
  contentAnimationDuration: number
}
const i18nBase = `HotelSafetyCheck.ConcernScoresList.ScoreList.ScoreListItem`
export function ScoreListItem({ label, value, isVisible, contentAnimationDuration }: Props) {
  const { t } = useTranslation()
  const { scaleAnimation } = useScoreListItem({ isVisible, contentAnimationDuration })

  return (
    <Container>
      <Score aria-label={t(`${i18nBase}.ValueAriaLabel`, { value })}>{value}</Score>
      <ConcernContainer>
        <ConcernTitle>{label}</ConcernTitle>
        <ConcernScoreScaleContainer>
          <ConcernScoreScaleBackground />
          <ConcernScoreScaleFillContainer {...scaleAnimation}>
            <ConcernScoreScaleFill value={value} />
          </ConcernScoreScaleFillContainer>
        </ConcernScoreScaleContainer>
      </ConcernContainer>
    </Container>
  )
}
