import { Store } from '@etta/di'

@Store()
export class AutocompleteStore {
  query?: string = ''

  setQuery(query?: string) {
    this.query = query
  }

  clearQuery() {
    this.query = ''
  }
}
