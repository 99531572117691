import * as R from 'ramda'

type RespectNullableValuesKind = 'non-strict' | 'both-strict'

export function checkIsEqual<T>(a: T, b: T, respectNullableValues?: RespectNullableValuesKind) {
  const kind = respectNullableValues ?? 'non-strict'
  if (kind === 'both-strict') {
    if (!a && !b) {
      return false
    }
  }

  return R.equals(a, b)
}
