import { Store } from '@etta/di'

@Store()
export class AddExpenseCodeMemoStore {
  maxMemoLength = 64
  private _memoValue: string = ''
  private _isContinueButtonDisabled: boolean = true

  get memoValue() {
    return this._memoValue
  }

  get isContinueButtonDisabled() {
    return this._isContinueButtonDisabled
  }

  setMemoValue(value: string) {
    this._memoValue = value
  }

  setIsContinueButtonDisabled(value: boolean) {
    this._isContinueButtonDisabled = value
  }

  dropStore() {
    this._memoValue = ''
    this._isContinueButtonDisabled = true
  }
}
