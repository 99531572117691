import { useTranslation } from 'react-i18next'
import type { TripCost } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import type { PayType } from './types'
import {
  Section,
  SectionContent,
  SectionItem,
  SectionSum,
  SectionSumMain,
  SectionSumPrice,
  SectionSumSecondary,
  SectionTitle,
} from './trip-cost-summary-styled'

type Props = {
  cost?: TripCost
  title: string
  subTitle: string
  type: PayType
  index?: number
}

export function TripCostSummarySection({ cost, title, subTitle, type, index }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'TripCostSummary.'
  const isPriceOnConfirmation = cost?.total.primary.amount === 0

  const { mainCost: totalMainCost, secondCost: totalSecondCost } = formatRate(cost?.total, {
    morpheme: 'postfix',
  })
  const { mainCost: taxesMainCost } = formatRate(cost?.feesAndTaxes, {
    morpheme: 'none',
  })
  const { mainCost: baseMainCost } = formatRate(cost?.base, {
    morpheme: 'none',
  })

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionContent>
        {isPriceOnConfirmation && (
          <SectionItem>
            <span>
              {subTitle} {index}
            </span>
            <span>{t(i18nBase + 'PriceOnConfirmation')}</span>
          </SectionItem>
        )}
        {baseMainCost && (
          <SectionItem>
            <span>
              {subTitle} {index}
            </span>
            <span>{baseMainCost}</span>
          </SectionItem>
        )}
        {taxesMainCost && (
          <SectionItem>
            <span>{t(i18nBase + 'FeesAndTaxes')}</span>
            <span>{taxesMainCost}</span>
          </SectionItem>
        )}
      </SectionContent>
      {!isPriceOnConfirmation && (
        <SectionSum>
          <SectionSumMain>{t(i18nBase + type)}</SectionSumMain>
          <SectionSumPrice>
            <SectionSumMain>{totalMainCost}</SectionSumMain>
            <SectionSumSecondary>{totalSecondCost}</SectionSumSecondary>
          </SectionSumPrice>
        </SectionSum>
      )}
    </Section>
  )
}
