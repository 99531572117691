import { useState } from 'react'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { Amenity } from '@fiji/graphql/types'
import type { IconNames } from '@etta/ui/icon'

type Args = {
  amenities: Amenity[]
  exclusiveAmenities: Amenity[]
}

export function useHotelDetailsAmenities({ amenities, exclusiveAmenities }: Args) {
  const [isOpenAmenities, setOpenAmenities] = useState(false)
  const hotelAmenitiesToggle = useTogglePopup()
  const roomAmenitiesToggle = useTogglePopup()
  const negotiatedAmenitiesToggle = useTogglePopup()

  const hotelAmenities = amenities.filter((item) => item.isOfProperty)
  const roomAmenities = amenities.filter((item) => item.isOfRoom)
  const openAmenitiesIcon: IconNames = isOpenAmenities ? 'expandLessPWA' : 'expandMorePWA'
  const handleAmenitiesToggle = () => {
    setOpenAmenities((prev) => !prev)
  }

  return {
    handleAmenitiesToggle,
    openAmenitiesIcon,
    isOpenAmenities,
    hotelAmenities,
    roomAmenities,
    negotiatedAmenities: exclusiveAmenities,
    hotelAmenitiesToggle,
    roomAmenitiesToggle,
    negotiatedAmenitiesToggle,
  }
}
