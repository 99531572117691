/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = PositionOptions

const geolocation = navigator.geolocation

export function useGeolocation({
  enableHighAccuracy = false,
  timeout = 10000,
  maximumAge,
}: Props = {}) {
  const { t } = useTranslation()
  const errorMessage = t('Geolocation.Error')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [position, setPosition] = useState<GeolocationPosition | null>(null)
  const getCurrentPosition = useCallback(
    () =>
      new Promise<GeolocationPosition>((resolve, reject) => {
        const onError = (e: string = errorMessage) => {
          setLoading(false)
          setError(e)
          reject(e)
        }

        if (!geolocation) {
          onError()
        }

        setLoading(true)
        setError(null)
        setPosition(null)

        geolocation.getCurrentPosition(
          (position) => {
            setLoading(false)
            setPosition(position)

            resolve(position)
          },
          () => {
            setLoading(false)
            setError(errorMessage)

            reject(errorMessage)
          },
          {
            enableHighAccuracy,
            timeout,
            maximumAge,
          },
        )
      }),
    [enableHighAccuracy, timeout, maximumAge],
  )

  return { isLoading, error, position, getCurrentPosition }
}
