import type { CreditCard } from '@fiji/graphql/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { SubTitle } from '../titles'
import { usePaymentCard } from './use-payment-card'

type Props = {
  creditCard?: CreditCard
  isPayNow?: boolean
}

export function PaymentCard({ creditCard, isPayNow = true }: Props) {
  const isMobile = useScreenType() === ScreenType.Mobile
  const title = usePaymentCard({
    creditCard,
    getCardLabelString: isMobile
      ? undefined
      : ({ formattedCardNumber, vendor, label }) =>
          [label, vendor, formattedCardNumber].filter(Boolean).join(' '),
  })

  if (!isPayNow) {
    return null
  }

  return <SubTitle>{title}</SubTitle>
}
