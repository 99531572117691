import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CountrySearch } from '@etta/components/country-search'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { ConfirmationDialog } from '@etta/ui/confirmation-dialog'
import { Text } from '@etta/ui/text'
import { TextField } from '@etta/ui/text-field'
import { default as CountryCodesForShortcodes } from '@etta/modules/settings/core/constants/country-codes-shortcodes.json'
import { useRedressNumber } from '../../use-redress-number'
import { FormWrapper, Banner } from './redress-number-form-mobile.styled'

const i18nBase = 'TravelerInfo.RedressNumber.'
const i18nBaseAria = 'Accessibility.Settings'

export const RedressNumberFormMobile = observer(function RedressNumberFormMobile() {
  const { t } = useTranslation()

  const {
    handleTravelerDocumentFieldChange,
    handleRemoveTravelerDocument,
    handleDocumentFieldFocusClear,
    getDisabledCountries,
    removeToggle,
    redressNumberValidator,
    isDocumentTouched,
    oldDocument,
    isDelegatedOrImpersonated,
  } = useRedressNumber()

  const {
    values: { number, countryCode },
    errors,
  } = redressNumberValidator

  const isEditForm = !!oldDocument

  return (
    <>
      <FormWrapper>
        <Banner>
          <Text variant="footnoteMedium" color="bodyText">
            {`${t(i18nBase + 'SubHeading')}`}
          </Text>
        </Banner>
        <TextField
          type="text"
          label={t(`${i18nBase}Number`)}
          value={number || ''}
          helperText={errors.number}
          helperType="error"
          onChange={(value) => handleTravelerDocumentFieldChange('number', value)}
          isBlurred={isEditForm && !isDocumentTouched && isDelegatedOrImpersonated}
          onFocus={handleDocumentFieldFocusClear}
        />
        <CountrySearch
          customOptions={CountryCodesForShortcodes}
          disabledOptions={getDisabledCountries()}
          value={countryCode || ''}
          helperText={errors.countryCode}
          helperType="error"
          customLabel={t(`${i18nBase}Country`)}
          customPlaceholder={t(`${i18nBase}Country`)}
          setValue={(value) => handleTravelerDocumentFieldChange('countryCode', value)}
        />
      </FormWrapper>
      {isEditForm && (
        <Block marginTop={24}>
          <Button
            variant="link"
            onClick={removeToggle.handleOpen}
            paddings={false}
            aria-label={t(`${i18nBaseAria}.Button`, {
              label: t(`${i18nBase}RemoveNumber`),
            })}>
            <Text variant="linkSmall" color="error">
              {t(i18nBase + 'RemoveNumber')}
            </Text>
          </Button>
        </Block>
      )}
      <ConfirmationDialog
        title={t(i18nBase + 'RemoveTitle')}
        content={t(i18nBase + 'RemoveDescription')}
        isOpen={removeToggle.isOpen}
        onConfirm={handleRemoveTravelerDocument}
        onDecline={removeToggle.handleClose}
        confirmButtonText={t(i18nBase + 'Remove')}
        declineButtonText={t(i18nBase + 'Cancel')}
      />
    </>
  )
})
