import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  margin: 0 0 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
`

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
`

export const Body = styled.div`
  padding: 18px 16px;
  display: flex;
  justify-content: space-between;
  height: 50px;
`

export const Left = styled.div``

export const Right = styled.div``
