import { useMemo } from 'react'
import { ChangeDuration } from './modes'
import type { Props } from './type'

export function useTrainModes({ segment }: Props) {
  const modes = useMemo(() => {
    const modes = []

    if (segment.changeDuration) {
      modes.push(<ChangeDuration segment={segment} />)
    }

    return modes
  }, [segment])

  return { modes }
}
