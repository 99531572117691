import { dateFormat } from '@fiji/utils/dates/date-format'
import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import type { PlainTime as Time } from '@fiji/types'

export function placeFormatDate(date?: Date) {
  if (!date) {
    return ''
  }

  return dateFormat(date, "iii', 'MMM dd")
}

export function placeFormatTime(time?: Time) {
  if (!time) {
    return ''
  }

  return getFormattedTime(time, 'p')
}
