import { getFormattedTime } from '@fiji/utils/dates/get-formatted-time'
import { isSameMonth } from '@fiji/utils/dates/is-same-month'
import { isSameYear } from '@fiji/utils/dates/is-same-year'

type Props = {
  start?: string | null
  end?: string | null
}

const fullDateFormat = 'MMM dd, yyyy'

export function useTripFormattedDate({ start, end }: Props) {
  if (!start || !end) {
    return ''
  }

  const startDateParsed = new Date(start)
  const endDateParsed = new Date(end)

  if (
    isSameYear(startDateParsed, endDateParsed) &&
    isSameMonth(startDateParsed, endDateParsed) &&
    startDateParsed.getDate() === endDateParsed.getDate()
  ) {
    return getFormattedTime(startDateParsed, 'MMM dd, yyyy')
  }

  const startDateFormatted = getFormattedTime(
    startDateParsed,
    isSameYear(startDateParsed, endDateParsed) ? 'MMM dd' : fullDateFormat,
  )
  const endDateFormatted = getFormattedTime(
    endDateParsed,
    isSameMonth(startDateParsed, endDateParsed) ? 'dd, yyyy' : fullDateFormat,
  )

  return `${startDateFormatted} - ${endDateFormatted}`
}

export function useCarRentalFormattedDate({ start, end }: Props) {
  if (!start || !end) {
    return ''
  }

  const startDateParsed = new Date(start)
  const endDateParsed = new Date(end)

  const startDateFormatted = getFormattedTime(startDateParsed, "MMM dd, h:mmaaaaa'm'").replace(
    /^0(?:0:0?)?/,
    '',
  )
  const endDateFormatted = getFormattedTime(endDateParsed, "MMM dd, h:mmaaaaa'm'").replace(
    /^0(?:0:0?)?/,
    '',
  )

  return `${startDateFormatted} - ${endDateFormatted}`
}

export function useHotelFormattedDate({ start, end }: Props) {
  if (!start || !end) {
    return ''
  }

  const startDateParsed = new Date(start)
  const endDateParsed = new Date(end)

  const startDateFormatted = getFormattedTime(startDateParsed, 'MMM dd')
  const endDateFormatted = getFormattedTime(endDateParsed, 'dd')

  return `${startDateFormatted} - ${endDateFormatted}`
}
