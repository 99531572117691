import { useEffect, useState } from 'react'
import { useBodyOverflowed } from '@fiji/hooks/body/use-body-overflowed'
import { useCloseModalByBrowserBackButton } from '@fiji/hooks/use-close-modal-by-browser-back-button/use-close-modal-by-browser-back-button'
import { useFocusLock } from '@fiji/hooks/use-focus-lock'
import { useDialogScrollbar } from './use-dialog-scrollbar'

type Args = {
  isOpen: boolean
  onClose: (e: any) => void
}

export function useDialog({ isOpen, onClose }: Args) {
  useCloseModalByBrowserBackButton({ isOpen, onClose })
  useBodyOverflowed(isOpen)
  useDialogScrollbar()

  const [isAvailable, updateAvailable] = useState(false)
  useEffect(() => {
    if (isOpen) {
      updateAvailable(isOpen)
    }
  }, [isOpen])

  const { focusLockContainerRef, catchFocus } = useFocusLock({
    disabled: !isOpen,
  })

  const onExited = () => {
    updateAvailable(false)
  }

  const onEntered = () => {
    catchFocus()
  }

  const isShown = isOpen || isAvailable

  return {
    isShown,
    onEntered,
    onExited,
    focusLockContainerRef,
  }
}
