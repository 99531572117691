import { useTranslation } from 'react-i18next'
import { StyledTextVariant } from '@fiji/enums'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags/interface/use-feature-flags-context'
import { useRecentSearchesContext } from '@etta/modules/recent-searches/interface/use-recent-searches.context'
import { Text } from '@etta/ui/text'
import { DescendantList, DescendantsWrapper } from '@etta/components/descendant'
import type { LayoutProps } from '../../types'
import { NoStations } from '../../no-stations'
import { CurrentLocationSearch } from '../../current-location-search'
import { SearchMechanismDesktopList } from './search-mechanism-desktop-list'
import {
  Container,
  SearchWrapper,
  Label,
  ResultsListContainer,
  Separator,
  PwaErrorMsg,
  ListWrapper,
} from './search-mechanism-desktop-styled'
import { SearchInput } from './search-input'

const i18nPath = 'SearchResults.'

export function SearchMechanismDesktop({
  searchFieldPlaceholder,
  hasCurrentLocationSearch,
  setIsLoading,
  handleCurrentLocation,
  hasAnyPlaces,
  searchInputRef,
  customSearchDescription,
  isTrainNoResultVisible,
  menuProps,
  isResultsListOpen,
  isLoading,
  errorMessage,
  searchType,
  referencePoints,
  isHotelSearch,
  getItemProps,
  latestPlaces,
  highlightedIndex,
  places,
  modifiedPlaces,
  searchInputContainerProps,
  searchInputProps,
  isSearchInputDisabled,
  searchInputValue,
  noResultMessage,
  recentSearchesType,
  onReset,
  onModalClose,
}: LayoutProps) {
  const { t } = useTranslation()
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { recentSearchesStore } = useRecentSearchesContext()
  const hasListWrapperTopBorder =
    !hasCurrentLocationSearch && !places.length && !modifiedPlaces.length && !referencePoints.length
  const isRecentSearchesEnabled = featureFlagsStore.flags.isRecentSearchesEnabled
  const isRecentSearchesListEmpty = recentSearchesStore.isSegmentListEmpty(recentSearchesType)
  const hasNoPlaces = !hasAnyPlaces
  const inputIsEmpty = searchInputRef.current?.defaultValue.length === 0

  return (
    <DescendantsWrapper>
      <Container>
        <SearchWrapper>
          <Label>
            <Text variant={StyledTextVariant.captionMedium} color="bodyText">
              {searchFieldPlaceholder}
            </Text>
          </Label>

          <SearchInput
            isResultsListOpen={isResultsListOpen}
            searchInputRef={searchInputRef}
            containerProps={searchInputContainerProps}
            inputProps={searchInputProps}
            isDisabled={isSearchInputDisabled}
            placeholder={searchFieldPlaceholder}
            value={searchInputValue}
            onReset={onReset}
          />
        </SearchWrapper>
        <DescendantList>
          <ListWrapper hasTopBorder={hasListWrapperTopBorder}>
            <Separator />
            {hasCurrentLocationSearch && (
              <CurrentLocationSearch
                dataTrackingId={searchType + '-search-mechanism-current-location-button'}
                setIsLoading={setIsLoading}
                onClick={handleCurrentLocation}
              />
            )}
            {((isRecentSearchesEnabled && isRecentSearchesListEmpty) ||
              (!isRecentSearchesEnabled && hasNoPlaces)) &&
              inputIsEmpty && (
                <PwaErrorMsg>
                  {customSearchDescription || t(`${i18nPath}PwaFirstScreen`)}
                </PwaErrorMsg>
              )}
            {isRecentSearchesListEmpty && noResultMessage && (
              <PwaErrorMsg>{noResultMessage}</PwaErrorMsg>
            )}

            {isTrainNoResultVisible && <NoStations title={t(`${i18nPath}SearchResults`)} />}
            <ResultsListContainer
              {...menuProps}
              isOpen={isRecentSearchesEnabled || isResultsListOpen}>
              <SearchMechanismDesktopList
                isLoading={isLoading}
                errorMessage={errorMessage}
                searchType={searchType}
                referencePoints={referencePoints}
                isHotelSearch={isHotelSearch}
                getItemProps={getItemProps}
                latestPlaces={latestPlaces}
                highlightedIndex={highlightedIndex}
                places={places}
                modifiedPlaces={modifiedPlaces}
                shouldShowYourDestinationsText={!hasCurrentLocationSearch}
                recentSearchesType={recentSearchesType}
                onModalClose={onModalClose}
              />
            </ResultsListContainer>
          </ListWrapper>
        </DescendantList>
      </Container>
    </DescendantsWrapper>
  )
}
