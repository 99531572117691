import { Action, Inject } from '@etta/di'
import type {
  SearchRideHailForm,
  SearchRideHailTripInfo,
} from '@etta/modules/ride-hail/interface/types'
import { RenderState } from '@etta/modules/ride-hail/interface/types'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import { PostBookingTripActions } from '@etta/modules/post-booking/interface/actions/post-booking-trip.actions'
import { PostBookingTripPreviewStore } from '@etta/modules/post-booking/interface/stores/trip-preview/post-booking-trip-preview.store'
import { PostBookingTripPreviewActions } from '@etta/modules/post-booking/interface/actions/post-booking-trip-preview.actions'
import type {
  RideHailAirportPickupFlightValueObject,
  SearchRideHailResultValueObject,
} from '../../core/value-objects'
import { SearchRideHailService } from '../services/search-ride-hail.service'
import { SearchRideHailStore } from '../store/search-ride-hail.store'

@Action()
export class SearchRideHailActions {
  constructor(
    @Inject()
    private searchRideHailService: SearchRideHailService,
    @Inject()
    private searchRideHailStore: SearchRideHailStore,
    @Inject()
    private expenseFlowStore: ExpenseFlowStore,
    @Inject()
    private postBookingTripPreviewStore: PostBookingTripPreviewStore,
    @Inject()
    private postBookingTripPreviewActions: PostBookingTripPreviewActions,
    @Inject()
    private postBookingTripActions: PostBookingTripActions,
  ) {}

  async loadSearchRideHail(isOnDemand: boolean) {
    await this.searchRideHailService.loadSearchRideHail(isOnDemand)
  }

  async loadSearchRideHailWithFlight(flight: RideHailAirportPickupFlightValueObject) {
    await this.searchRideHailService.loadSearchRideHailWithFlight(flight)
  }

  async loadSearchRideHailWithLocationOption() {
    await this.searchRideHailService.loadSearchRideHailWithLocationOption()
  }

  setSelectedRideHail(selected: SearchRideHailResultValueObject) {
    this.searchRideHailStore.setSelectedRideHail(selected)
  }

  setRenderState(renderState: RenderState) {
    if (renderState === RenderState.pickUpConfirm) {
      this.expenseFlowStore.setIsFailureOnFetchingRideHailConfig(false)
    }
    this.searchRideHailStore.setRenderState(renderState)
  }

  setSearchRideHailTripInfo(tripInfo: Partial<SearchRideHailTripInfo>) {
    this.searchRideHailStore.setSearchRideHailTripInfo({
      ...this.searchRideHailStore.searchRideHailTripInfo,
      ...tripInfo,
    })
  }

  setSearchRideHailForm(form: Partial<SearchRideHailForm>) {
    const newFormValue = {
      ...this.searchRideHailStore.searchRideHailForm,
      ...form,
    }
    this.searchRideHailStore.setSearchRideHailForm(newFormValue)
  }

  dropSearchRideHailForm() {
    this.searchRideHailStore.dropSearchRideHailForm()
  }

  dropSearchResults() {
    this.searchRideHailStore.dropResults()
  }

  dropSearchRideHail() {
    this.searchRideHailStore.dropStore()
  }

  openMobilitySearchDesktopModal() {
    this.searchRideHailStore.setMobilitySearchDesktopModel(true)
  }

  closeMobilitySearchDesktopModal() {
    this.searchRideHailStore.setMobilitySearchDesktopModel(false)
  }

  openLeavingBookingFlowDialog() {
    if (!this.searchRideHailStore.isOpenMobilitySearchDesktopModal) {
      return
    }
    this.searchRideHailStore.setLeavingBookingFlowDialog(true)
  }

  closeLeavingBookingFlowDialog() {
    this.searchRideHailStore.setLeavingBookingFlowDialog(false)
  }

  refreshTrips() {
    const { tripPreview } = this.postBookingTripPreviewStore
    this.postBookingTripActions.loadTrip()
    this.postBookingTripPreviewActions.updateTitle(tripPreview.title)
    this.postBookingTripPreviewActions.updateTimes({
      startTime: tripPreview.startTime,
      endTime: tripPreview.endTime,
    })
    this.postBookingTripPreviewActions.updateTripType(tripPreview.tripType)
  }
}
