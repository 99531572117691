import type { IconSizes } from '../types'

export function getDimensionSize(size?: IconSizes) {
  if (typeof size === 'number') {
    return size
  }
  switch (size) {
    case 'large':
      return 44
    case 'medium':
      return 32
    case 'normal':
      return 24
    case 'small':
    default:
      return 20
  }
}
