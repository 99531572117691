import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import { GetSiteSsoSettingsDocument } from '@fiji/graphql/hooks'
import type { GetSiteSsoSettingsQuery } from '@fiji/graphql/hooks'

@DataProvider()
export class SiteSsoSettingsDataProvider extends GraphqlDataProvider {
  getSiteSsoSettings() {
    return this.client.query<GetSiteSsoSettingsQuery>({
      query: GetSiteSsoSettingsDocument,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        isCCNumberMasked: true,
      },
    })
  }
}
