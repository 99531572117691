import type { Place } from '@fiji/types'
import type { ComplexPickUpType } from '../types'

export function checkComplexPickUp(pickUpPlace?: Place, dropOffPlace?: Place): ComplexPickUpType {
  if (!pickUpPlace || !dropOffPlace) {
    return null
  }

  const isPickUpAirport = pickUpPlace.airportCode !== undefined
  const isPickUpCountryCodeDefined = !!pickUpPlace.countryCode
  const isDropOffCountryCodeDefined = !!dropOffPlace.countryCode
  const isPickUpInUS = pickUpPlace.countryCode === 'US'
  const isDropOffInUS = dropOffPlace.countryCode === 'US'

  if (!isPickUpCountryCodeDefined && !isDropOffCountryCodeDefined) {
    return null
  }

  if (isPickUpAirport && isPickUpInUS) {
    return 'airport'
  }

  if (isPickUpCountryCodeDefined && isDropOffCountryCodeDefined) {
    if (!isPickUpInUS || !isDropOffInUS) {
      return 'outside-US'
    }
    return null
  }

  if (!isPickUpCountryCodeDefined && !isDropOffInUS) {
    return 'outside-US'
  }

  if (!isDropOffCountryCodeDefined && !isPickUpInUS) {
    return 'outside-US'
  }

  return null
}
