export const PaymentCardsFieldsSettings = {
  personalCards: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRenderedOnActivationPage: false,
    isRendered: true,
  },
  corporateCards: {
    label: null,
    isEditable: true,
    isRequired: false,
    isRenderedOnActivationPage: false,
    isRendered: true,
  },
}
