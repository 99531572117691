import { SearchResultsHeaderFull } from '@etta/components/search-results-header/header-full/search-results-header-full'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { ViewMode } from './view-mode/view-mode'
import { EditMode } from './edit-mode/edit-mode'

export function RailResultsHeaderDesktop() {
  const editModeToggle = useTogglePopup()

  return (
    <SearchResultsHeaderFull.Container
      isEditOpen={editModeToggle.isOpen}
      viewSlot={<ViewMode onEdit={editModeToggle.handleOpen} />}
      editSlot={<EditMode onClose={editModeToggle.handleClose} />}
    />
  )
}
