import type { Maybe } from 'fnscript'
import { Error as NestedError, Just, Nothing } from 'fnscript'
import type { CalculateCarEmissionsBatchCarInput } from '@fiji/graphql/types'
import type { Place } from '@fiji/types'
import {
  CAR_ERROR_MESSAGES,
  isCoordinate,
  isDate,
  isEmptyString,
  isZeroNumber,
  SKIP_VALIDATION_ERROR,
} from '../common'

type ValidationArgs = {
  cars: CalculateCarEmissionsBatchCarInput[]
  pickUpDate: Date
  dropOffDate: Date
  pickUpPlace: Place
  dropOffPlace: Place
}

const ERROR_MESSAGE = 'CalculateCarRentalEmissionsBatch Error'

type CarRentalCompareSegment = {
  pickUpDate: Date
  dropOffDate: Date
  pickUpPlace: Place
  dropOffPlace: Place
}

export function carRentalEmissionsQueryExecuteValidation({
  cars,
  pickUpDate,
  dropOffDate,
  pickUpPlace,
  dropOffPlace,
}: ValidationArgs): Maybe<NestedError> {
  if (!cars || cars.length === 0) {
    return Just(NestedError.new(SKIP_VALIDATION_ERROR))
  }

  const maybeCarErrors = validateTcCars(cars)
  const maybeCarCompareSegmentsErrors = validateCarCompareSegments({
    pickUpDate,
    dropOffDate,
    pickUpPlace,
    dropOffPlace,
  })

  if (maybeCarErrors.isValue() || maybeCarCompareSegmentsErrors.isValue()) {
    return Just(
      NestedError.new(
        `${ERROR_MESSAGE}: ${!maybeCarErrors.isNothing() && maybeCarErrors.getValue()} ${
          !maybeCarCompareSegmentsErrors.isNothing() && maybeCarCompareSegmentsErrors.getValue()
        }`,
      ),
    )
  }

  return Nothing()
}

function validateCarCompareSegments({
  pickUpDate,
  dropOffDate,
  pickUpPlace,
  dropOffPlace,
}: CarRentalCompareSegment) {
  const compareSegmentErrors: NestedError[] = []

  if (!isCoordinate(pickUpPlace.latitude)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.PICK_UP_PLACE_LATITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isCoordinate(pickUpPlace.longitude)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.PICK_UP_PLACE_LONGITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isCoordinate(dropOffPlace.latitude)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DROP_OFF_PLACE_LATITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isCoordinate(dropOffPlace.longitude)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DROP_OFF_PLACE_LONGITUDE)
    compareSegmentErrors.push(error)
  }

  if (!isDate(pickUpDate)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.PICK_UP_DATE)
    compareSegmentErrors.push(error)
  }

  if (!isDate(dropOffDate)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DROP_OFF_DATE)
    compareSegmentErrors.push(error)
  }

  if (compareSegmentErrors.length > 0) {
    const validationError = NestedError.new(compareSegmentErrors.map((e) => e.text()).join(';'))

    return Just(
      NestedError.new(`failed to validate car compare object;${validationError.getMessage()}`),
    )
  }

  return Nothing()
}

export function validateTcCars(cars: CalculateCarEmissionsBatchCarInput[]) {
  const carErrors: NestedError[] = []

  cars.forEach((car, idx) => {
    const maybeCarErrors = validateTcCar(car)

    if (maybeCarErrors.isValue()) {
      carErrors.push(
        NestedError.new(`failed to validate car with index: ${idx};${maybeCarErrors.getValue()}`),
      )
    }
  })

  if (carErrors.length > 0) {
    const validationError = NestedError.new(carErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}

function validateTcCar(car: CalculateCarEmissionsBatchCarInput) {
  const segmentErrors: NestedError[] = []

  if (!car.days || isZeroNumber(car.days)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DAYS)
    segmentErrors.push(error)
  }

  if (!isCoordinate(car.origin.lat)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.ORIGIN_LATITUDE)
    segmentErrors.push(error)
  }

  if (!isCoordinate(car.origin.long)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.ORIGIN_LONGITUDE)
    segmentErrors.push(error)
  }

  if (!isCoordinate(car.destination?.lat)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DESTINATION_LATITUDE)
    segmentErrors.push(error)
  }

  if (!isCoordinate(car.destination.long)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.DESTINATION_LONGITUDE)
    segmentErrors.push(error)
  }

  if (isEmptyString(car.pickUpDateTime)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.PICK_UP_DATE_TIME)
    segmentErrors.push(error)
  }

  if (isEmptyString(car.vehicleCode)) {
    const error = NestedError.new(CAR_ERROR_MESSAGES.VEHICLE_CODE)
    segmentErrors.push(error)
  }

  if (segmentErrors.length > 0) {
    const validationError = NestedError.new(segmentErrors.map((e) => e.text()).join(';'))

    return Just(NestedError.new(validationError.getMessage()))
  }

  return Nothing()
}
