import { useTranslation } from 'react-i18next'
import type { FlightSafetyCheckDetails } from '@fiji/types'
import { Block } from '@etta/ui/block'
import { Separator } from '@etta/ui/separator'
import { Text } from '@etta/ui/text'
import { Link } from '@etta/ui/link'
import { Swap } from '@etta/ui/swap'
import { NoImageIcon } from '../../images'
import { Container, LinkWrapper, ImageWrapper } from './flight-safety-check-item-mobile-styled'

type Props = {
  detail: FlightSafetyCheckDetails
  isAirlineName: boolean
  isDisclaimer: boolean
  isSubtitle: boolean
}

export function FlightSafetyCheckItemMobile({
  detail,
  isAirlineName,
  isDisclaimer,
  isSubtitle,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'FlightDetails.SafetyCheck'
  const linkLabel = `${t(i18nBase + '.ViewDetails')}-button`
  const { imageUrl, headline, description, url } = detail

  if (isAirlineName || isSubtitle) {
    return (
      <Block paddingHorizontal={16} paddingVertical={12}>
        <Text aria-label={headline} variant="title3">
          {headline}
        </Text>
      </Block>
    )
  }

  if (isDisclaimer) {
    return (
      <Container>
        <Block>
          <Text aria-label={headline} variant="headline">
            {t(i18nBase + '.Disclaimer')}
          </Text>
          <Text variant="footnoteMedium">{description}</Text>
        </Block>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <Block>
          <Text aria-label={headline} variant="headline">
            {headline}
          </Text>
          <Text variant="footnoteMedium">{description}</Text>
          {url && (
            <LinkWrapper>
              <Link size="small" href={url} aria-label={linkLabel} data-tracking-id={linkLabel}>
                {t(i18nBase + '.ViewDetails')}
              </Link>
            </LinkWrapper>
          )}
        </Block>
        <ImageWrapper>
          <Swap
            is={!!imageUrl}
            isSlot={<img src={imageUrl} alt={headline} data-tracking-id={headline} />}>
            <NoImageIcon />
          </Swap>
        </ImageWrapper>
      </Container>
      <Separator />
    </>
  )
}
