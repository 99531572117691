import type { Dispatch, SetStateAction } from 'react'
import { useSwipeable } from 'react-swipeable'
import type { SwipingTimePickerBars, SwipingPickerSubtype, YearBounds } from '../types'
import { calcSwipeAssets, setSwipeAction, flipColumn, returnValidDate } from '../assets'
import { getConstants } from '../assets/get-constants'

type Props = {
  variant?: SwipingPickerSubtype
  day: number
  month: number
  year: number
  dayBarTop: SwipingTimePickerBars
  monthBarTop: SwipingTimePickerBars
  yearBarTop: SwipingTimePickerBars
  setDay: Dispatch<SetStateAction<number>>
  setMonth: Dispatch<SetStateAction<number>>
  setYear: Dispatch<SetStateAction<number>>
  setDaySwiping: Dispatch<SetStateAction<number>>
  setMonthSwiping: Dispatch<SetStateAction<number>>
  setYearSwiping: Dispatch<SetStateAction<number>>
  setDayFlipping: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setMonthFlipping: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setYearFlipping: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setDayBarTop: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setMonthBarTop: Dispatch<SetStateAction<SwipingTimePickerBars>>
  setYearBarTop: Dispatch<SetStateAction<SwipingTimePickerBars>>
  yearBoundsInput?: YearBounds
}

export function useDateHandlers({
  variant,
  day,
  month,
  year,
  dayBarTop,
  monthBarTop,
  yearBarTop,
  setDay,
  setMonth,
  setYear,
  setDaySwiping,
  setMonthSwiping,
  setYearSwiping,
  setDayFlipping,
  setMonthFlipping,
  setYearFlipping,
  setDayBarTop,
  setMonthBarTop,
  setYearBarTop,
  yearBoundsInput,
}: Props) {
  const { START_YEAR } = getConstants(variant, undefined, yearBoundsInput)
  const dayHandlers = useSwipeable({
    onSwiped: (e) => {
      setDaySwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right') {
        return
      }
      const swipeData = calcSwipeAssets({ e, type: 'day', increment: day })
      const { sweep, unitHeight } = swipeData
      setSwipeAction({ e, swipeData, setIncrement: setDay })
      const flipEffect = flipColumn({ values: dayBarTop, swipeData, variant })
      setDayFlipping(flipEffect)
      setDayBarTop(
        dayBarTop.map(
          (_el, i) => dayBarTop[i] - sweep * unitHeight + flipEffect[i],
        ) as SwipingTimePickerBars,
      )
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setDaySwiping(1.5)
      }
      if (e.dir === 'Up') {
        return setDaySwiping(-1.5)
      }
    },
    trackMouse: true,
  })

  const monthHandlers = useSwipeable({
    onSwiped: (e) => {
      setMonthSwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right') {
        return
      }
      const swipeData = calcSwipeAssets({ e, type: 'month', increment: month })
      const { sweep, unitHeight } = swipeData
      const newMonth = setSwipeAction({ e, swipeData, setIncrement: setMonth })
      const correctDay = returnValidDate({ day: day + 1, month: newMonth, year })
      const flipEffect = flipColumn({ values: monthBarTop, swipeData, variant })
      setMonthFlipping(flipEffect)
      setMonthBarTop(
        monthBarTop.map(
          (_el, i) => monthBarTop[i] - sweep * unitHeight + flipEffect[i],
        ) as SwipingTimePickerBars,
      )
      if (correctDay) {
        setDay(correctDay - 1)
        setDayBarTop(
          dayBarTop.map(
            (_el, i) => dayBarTop[i] - (correctDay - day - 1) * unitHeight,
          ) as SwipingTimePickerBars,
        )
      }
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setMonthSwiping(1.5)
      }
      if (e.dir === 'Up') {
        return setMonthSwiping(-1.5)
      }
    },
    trackMouse: true,
  })

  const yearHandlers = useSwipeable({
    onSwiped: (e) => {
      setYearSwiping(0)
      if (e.dir === 'Left' || e.dir === 'Right') {
        return
      }
      const swipeData = calcSwipeAssets({ e, type: 'year', increment: year, yearBoundsInput })
      const { sweep, unitHeight } = swipeData
      const newYear = setSwipeAction({
        e,
        swipeData,
        setIncrement: setYear,
        variant,
        yearBoundsInput,
      })
      const correctDay = returnValidDate({
        day: day + 1,
        month,
        year: START_YEAR + newYear,
        variant,
      })
      const flipEffect = flipColumn({ values: yearBarTop, swipeData, variant, yearBoundsInput })
      setYearFlipping(flipEffect)
      setYearBarTop(
        yearBarTop.map(
          (_el, i) => yearBarTop[i] - sweep * unitHeight + flipEffect[i],
        ) as SwipingTimePickerBars,
      )
      if (correctDay) {
        setDay(correctDay - 1)
        setDayBarTop(
          dayBarTop.map(
            (_el, i) => dayBarTop[i] - (correctDay - day - 1) * unitHeight,
          ) as SwipingTimePickerBars,
        )
      }
    },
    onSwiping: (e) => {
      if (e.dir === 'Down') {
        return setYearSwiping(1.5)
      }
      if (e.dir === 'Up') {
        return setYearSwiping(-1.5)
      }
    },
    trackMouse: true,
  })

  return { dayHandlers, monthHandlers, yearHandlers }
}
