import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Wrapper = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 16px;
  border-radius: 14px;
  background-color: ${(p) => p.theme.colors.white};

  &:last-child {
    margin-bottom: 95px;
  }
`
