import styled, { css } from 'styled-components'

export const InfoWrapper = styled.div<{ isInfoRtpCarDetails?: boolean }>`
  padding: 0 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ isInfoRtpCarDetails }) =>
    isInfoRtpCarDetails &&
    css`
      border-radius: 14px;
      padding: 0 20px;
    `}
`
export const InfoContent = styled.div`
  padding: 14px 0;
`
