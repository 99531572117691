import { clone } from 'ramda'
import { FlightFareType, FlightSort, MapProvider } from '@fiji/graphql/types'
import type { DisplayConfiguration } from './types'

export const initialDisplayConfiguration: DisplayConfiguration = {
  isPhoenixHotelSearchEnabled: undefined,
  isPhoenixCarRentalSearchEnabled: undefined,
  isTripAdvisorReviewsEnabled: false,
  maxNumberOfGuestsPerRoom: 1,
  isSiteSpinnerVisible: false,
  isSiteMessagesVisible: true,
  isMapVisible: true,
  isMobileMapVisible: false,
  mapProvider: MapProvider.GoogleMaps,
  displayConfigLoading: true,
  isPerDiemDisplayEnabled: true,
  isStarRatingsFilterEnabled: true,
  isExpiringCreditCardForHotelAllowed: true,
  isCreditCardSavingEnabled: true,
  flightSearchTimeRange: {
    before: null,
    after: null,
  },
  flightSearchTimeRangeNonUS: {
    before: null,
    after: null,
  },
  geosureReport: {
    enabled: false,
    variant: 'scores',
  },
  optinoutOfUnusedTicket: false,
  hotelSearchRadius: {
    default: 5,
    max: 25,
  },
  hotels: {
    isStarRatingEnabled: true,
  },
  isFooterVisible: true,
  isHoldTripAllowed: true,
  carRentals: {
    areDeliveryAndCollectionSupported: false,
    requireCarPaymentForm: false,
  },
  flights: {
    isOutOfPolicyFlightsSelectable: false,
    areNearbyAirportsIncluded: false,
    defaultSort: FlightSort.PolicyOrPrefered,
    fares: {
      areRefundableEnabled: false,
      areUnrestrictedEnabled: false,
      defaultFareType: FlightFareType.All,
    },
    isAnytimeEnabled: false,
    isBusinessClassEnabled: false,
    isEconomyClassEnabled: false,
    isFirstClassEnabled: false,
    isMorningAfternoonEveningEnabled: false,
    isPremiumEconomyClassEnabled: false,
    businessClassCustomLabel: undefined,
    economyClassCustomLabel: undefined,
    firstClassCustomLabel: undefined,
    premiumEconomyClassCustomLabel: undefined,
    isDefaultAnyTime: false,
    defaultAirTravelType: undefined,
  },
  explore: {
    isCarRentalsSearchEnabled: false,
    isFlightsSearchEnabled: false,
    isHotelsSearchEnabled: false,
    isTrainsSearchEnabled: false,
    searchDefaultsAirEnabled: false,
    searchDefaultsHotelEnabled: false,
    searchDefaultsCarEnabled: false,
    message: undefined,
    messages: [],
  },
  privacyPolicy: {
    url: '',
    label: '',
  },
  isPreBookCostAllocationEnabled: false,
  hideUnusedTickets: false,
  externalLinks: {
    isExternalLinksEnabled: false,
    links: [],
  },
}

export function getInitialState() {
  return clone(initialDisplayConfiguration)
}
