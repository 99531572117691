import { SpinnerImage } from './spinner-image'
import type { SpinnerColor, SpinnerSizes, SpinnerType } from './types'
import { Spinout } from './spinout'

type Props = {
  size?: SpinnerSizes
  type?: SpinnerType
  color?: SpinnerColor
  isStatic?: boolean
  isPrimaryButton?: boolean
  isNarrowButton?: boolean
  isRelativeLink?: boolean
  isSecondaryButton?: boolean
  isDefaultInline?: boolean
  isPrimaryModalCostButton?: boolean
  isSecondaryModalCostButton?: boolean
  rtpAddButtonPosition?: number
  className?: string
}

export function Spinner({ size = 'large', type = 'primary', color, className, ...props }: Props) {
  if (type === 'spinout') {
    return <Spinout size={size} color={color} className={className} />
  }

  return <SpinnerImage size={size} type={type} {...props} />
}
