import { useTranslation } from 'react-i18next'
import type { Rate } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { UnusedTicketLabelWrapper } from './unused-ticket-label-styled'

type Props = {
  unusedTicketRate?: Rate | null
}

const i18nBase = 'FlightDetailsModal.UnusedTickets'

export function UnusedTicketLabel({ unusedTicketRate }: Props) {
  const { t } = useTranslation()
  if (!unusedTicketRate) {
    return null
  }

  const { mainCost } = formatRate(unusedTicketRate, { morpheme: 'none' })

  return (
    <UnusedTicketLabelWrapper>
      <Icon name="unusedTicketPWA" color="success" />
      <Text variant="captionMedium" color="bodyText">
        {t(`${i18nBase}.AppliedUnusedTicket`, { cost: mainCost })}
      </Text>
    </UnusedTicketLabelWrapper>
  )
}
