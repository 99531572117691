import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { ModalHeaderMobile } from './mobile'
import { ModalHeaderDesktop } from './desktop'

export function ModalHeaderLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<ModalHeaderMobile {...props} />}
      desktopSlot={<ModalHeaderDesktop />}
    />
  )
}
