import styled from 'styled-components'
import type { SvgIconProps } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowIcon = styled(Icon).attrs<SvgIconProps, SvgIconProps>(() => ({
  name: 'flightArrowPWA',
}))`
  margin: 0 8px;
`
