import { Skeleton } from '@etta/ui/skeleton'
import { Container, Header, Body, Left, Right } from './rtp-itinerary-card-skeleton-styled-mobile'

export function RtpItineraryCardSkeletonMobile() {
  return (
    <Container>
      <Header>
        <Left>
          <Skeleton width={40} height={15}>
            <rect width={40} height={15} />
          </Skeleton>
        </Left>
        <Right>
          <Skeleton width={40} height={15}>
            <rect width={40} height={15} />
          </Skeleton>
        </Right>
      </Header>
      <Body>
        <Left>
          <Skeleton width={150} height="100%">
            <rect y={22} width={150} height={19} />
            <rect y={56} width={156} height={14} />
            <rect y={86} width={90} height={14} />
          </Skeleton>
        </Left>
        <Right>
          <Skeleton width={93} height="100%">
            <rect y={22} width={93} height={19} />
            <rect x={77} y={86} width={15} height={14} />
          </Skeleton>
        </Right>
      </Body>
    </Container>
  )
}
