import { useTranslation } from 'react-i18next'
import { default as SoldOutHotelImage } from './sold-out-state.svg'
import { SoldOutContainer } from './sold-out-styled'

const i18nBase = 'HotelDetails'

export function SoldOut() {
  const { t } = useTranslation()

  return (
    <SoldOutContainer aria-label={t(`${i18nBase}.SoldOut`)} data-tracking-id="soldOut">
      <SoldOutHotelImage />
    </SoldOutContainer>
  )
}
