import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Button } from '@etta/ui/button'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { Checkbox } from '@etta/ui/checkbox'
import { Text } from '@etta/ui/text'
import {
  NarrowLeftButtonWrapper,
  Footer,
  Separator,
  ConfirmCheckboxWrapper,
} from '../cancel-trip-modal-styled'

type Props = {
  statement: string
  isChecked: boolean
  onChange: (value: boolean) => void
}

const i18nBase = 'PostBooking.TripDetails.CancelTripModal'

export const SidebarFooter = observer(function SidebarFooter({
  statement,
  isChecked,
  onChange,
}: Props) {
  const { postBookingCancellationActions } = usePostBookingContext()
  const { t } = useTranslation()
  const checkBoxAriaLabel = t('Accessibility.Options.Select', {
    option: 'Checkbox',
  })

  return (
    <Footer>
      <Separator />
      <ConfirmCheckboxWrapper>
        <Checkbox
          aria-label={checkBoxAriaLabel}
          value="Cancel confirmation"
          label={
            <Text
              variant="subHeadMedium"
              color="bodyText"
              data-tracking-id="cancel-confirmation-agreement-text">
              {t(`${i18nBase}.Confirmation`)}
            </Text>
          }
          checked={isChecked}
          onChange={onChange}
          data-tracking-id="cancel-confirmation-agreement-checkbox"
        />
      </ConfirmCheckboxWrapper>
      <NarrowLeftButtonWrapper>
        <Button
          aria-label={t('Accessibility.Options.Select', { option: statement })}
          data-tracking-id="cancel-trip-confirmation-button"
          variant="solid"
          disabled={!isChecked}
          onClick={postBookingCancellationActions.onSubmitCancel}
          size="normal-wide"
          fullWidth>
          {statement}
        </Button>
      </NarrowLeftButtonWrapper>
    </Footer>
  )
})
