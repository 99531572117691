import { MediaLayout } from '@etta/ui/media-layout'
import { UnusedTicketsDesktop, UnusedTicketsMobile } from './layout'
import type { Args } from './types'

export function UnusedTicketsLayout(props: Args) {
  return (
    <MediaLayout
      mobileSlot={<UnusedTicketsMobile {...props} />}
      desktopSlot={<UnusedTicketsDesktop {...props} />}
    />
  )
}
