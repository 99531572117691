import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { HotelDetailsDescription } from '../../hotel-details-description'
import { CallEHI } from '../../call-ehi'
import type { HotelAboutLayoutProps } from '../../types'
import { DatesContainer, HotelDetailsDescriptionTitle } from './hotel-about-desktop-styled'

export function HotelAboutDesktop({
  hotelDetails,
  dateRangeTitle,
  areDatesDisplayed,
  isHotelDescriptionDisplayed,
}: HotelAboutLayoutProps) {
  const { hotelDescriptions, notes } = hotelDetails
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'
  const isBlockWithContent =
    (isHotelDescriptionDisplayed && !!hotelDescriptions?.length) || areDatesDisplayed || notes

  return (
    <>
      {isBlockWithContent && (
        <Block>
          {isHotelDescriptionDisplayed && !!hotelDescriptions?.length && (
            <>
              <HotelDetailsDescriptionTitle variant="headline">
                {t(`${i18Base}.AboutThisHotelTitle`)}
              </HotelDetailsDescriptionTitle>
              <HotelDetailsDescription hotelDescriptions={hotelDescriptions} />
            </>
          )}
          {areDatesDisplayed && (
            <DatesContainer aria-label={`${t(`${i18Base}.DatesFrom`)} ${dateRangeTitle}`}>
              <Text variant="subHeadStrong" color="mainText">
                {t(i18Base + '.Dates')}
              </Text>
              <Block marginLeft={6}>
                <Text variant="subHeadMedium" color="bodyText">
                  {dateRangeTitle}
                </Text>
              </Block>
            </DatesContainer>
          )}
          {notes && <CallEHI notes={notes} />}
        </Block>
      )}
    </>
  )
}
