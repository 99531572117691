/* eslint-disable react-hooks/exhaustive-deps */
import type { MutableRefObject } from 'react'
import { useCallback, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { HEADER_SCROLL_DELAY } from '../constants'
import { getPercent } from './get-percent'

const DEFAULT_DEBOUNCE_TIMEOUT = 200
const MAX_DELAY = 1
type TapState = 'none' | 'down' | 'up'

type Ref = MutableRefObject<HTMLElement | null>
type Args = {
  baseRef: Ref
  headerRef: Ref
  anchorRef: Ref
  offsetRef: Ref
  anchorValueRef: MutableRefObject<number>
  defaultAnchorPosition: number
  scrollAfterValue: number
  scrollAfterOffset: number
}
export function useHandlers({
  baseRef,
  headerRef,
  anchorRef,
  offsetRef,
  anchorValueRef,
  defaultAnchorPosition,
  scrollAfterValue,
  scrollAfterOffset,
}: Args) {
  const currentDelayRef = useRef(0)
  const tapState = useRef<TapState>('none')

  // -> this function is responsible for scrolling when user scrolls via tap.
  const debouncedCallback = useDebouncedCallback(() => {
    if (tapState.current !== 'up') {
      return
    }
    if (currentDelayRef.current >= MAX_DELAY) {
      return
    }

    tapState.current = 'none'

    if (anchorValueRef.current && baseRef.current) {
      // scroll to top if not scrolled enough
      if (currentDelayRef.current < scrollAfterValue) {
        baseRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        return
      }
      // scroll to the anchor if scrolled enough AND enough space to scroll
      const anchorOffsetTop = anchorValueRef.current + scrollAfterOffset
      const availableScrollSpace = baseRef.current.scrollHeight - baseRef.current.clientHeight
      if (availableScrollSpace < anchorOffsetTop) {
        return
      }
      baseRef.current.scrollTo({
        top: anchorOffsetTop,
        behavior: 'smooth',
      })
    }
  }, DEFAULT_DEBOUNCE_TIMEOUT)

  const onDelaySet = useCallback((e: Event) => {
    const baseElement = e.currentTarget as HTMLElement

    if (!anchorValueRef.current && headerRef.current && anchorRef.current) {
      const top = anchorRef.current.getBoundingClientRect().top
      const height = headerRef.current.getBoundingClientRect().height
      anchorValueRef.current = top - height
    }

    const anchorValue = anchorValueRef.current || defaultAnchorPosition
    const delay = getPercent(baseElement, anchorValue) / 100
    currentDelayRef.current = delay

    const headerHeight = headerRef.current?.getBoundingClientRect().height
    const anchorTop = anchorRef.current?.getBoundingClientRect().top
    const isRefsNotEmpty =
      baseRef.current && (Number.isInteger(headerHeight) || Number.isInteger(anchorTop))
    const isAnimationDisabled =
      offsetRef.current && offsetRef.current.offsetTop > baseElement.scrollTop
    if (
      (isRefsNotEmpty &&
        baseRef.current!.scrollHeight - (headerHeight || anchorTop || 0) < window.screen.height) ||
      isAnimationDisabled
    ) {
      baseElement.style.setProperty(HEADER_SCROLL_DELAY, '0')
    } else {
      baseElement.style.setProperty(HEADER_SCROLL_DELAY, delay + '')
    }

    debouncedCallback()
  }, [])

  const onTapStart = useCallback(() => {
    tapState.current = 'down'
  }, [])

  const onTapEnd = useCallback(() => {
    if (tapState.current !== 'down') {
      return
    }

    tapState.current = 'up'
    debouncedCallback()
  }, [])

  return { onDelaySet, onTapEnd, onTapStart }
}
