import type { IconNames } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { IconRow, Item } from '../flight-details-fares-styles'

type Props = {
  icon: IconNames
  title: string
}

export function Badge({ icon, title }: Props) {
  return (
    <Item aria-label={title}>
      <IconRow>
        <Icon name={icon} size="small" />
      </IconRow>
      {title}
    </Item>
  )
}
