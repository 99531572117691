import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TripReviewDateType,
  dateFormat,
  deleteTimezone,
  tripReviewFormatDate,
} from '@fiji/utils/dates'
import type { CarRentalGroupedSegmentValueObject } from '@etta/modules/post-booking/core/value-objects'
import { formatPickUpDropOffAddress } from '@fiji/utils/address'
import { ScreenType, useScreenType } from '@fiji/modes'
import { translateCarNameDetails } from '@fiji/utils/translate-car-rental'

type Args = {
  segment: CarRentalGroupedSegmentValueObject
}

const NEW_DATE_FORMAT = 'MMM d, h:mm a'
const PICK_UP_AT_TRANSLATION = 'PostBooking.TripDetails.PickUpAt'
const EMPTY_STR = ''

export function useGetInfoLabels({ segment }: Args) {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const [headerDate] = useMemo(() => {
    const { pickUpTime, dropOffTime } = segment
    const pickUpFormatDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, pickUpTime)
    const dropOffFormatDate = tripReviewFormatDate(TripReviewDateType.DayAndDate, dropOffTime)

    return [`${pickUpFormatDate} - ${dropOffFormatDate}`]
  }, [segment])

  const { carRentalTitle } = translateCarNameDetails({
    carType: segment.carType,
    carClass: segment.carClass,
  })

  const [cardInfo, segmentPreview] = useMemo(() => {
    const { vendor } = segment
    const { address } = segment.pickUpLocation || {}
    const vendorLabel = t(`${t(PICK_UP_AT_TRANSLATION)} ${vendor?.name}`)
    const addressLabel = (address && `, ${formatPickUpDropOffAddress(address)}`) || ''
    const location = `${vendorLabel}${addressLabel}`

    return [location, vendor?.imageUrl]
  }, [segment, t])

  const cardSubTitle = useMemo(() => {
    const { pickUpTime, vendor } = segment || {}

    const rentalDateTime = dateFormat(
      new Date(deleteTimezone(pickUpTime || EMPTY_STR)),
      NEW_DATE_FORMAT,
    )

    if (isMobile) {
      return rentalDateTime
    }

    return [vendor?.name, rentalDateTime].filter(Boolean).join(' • ')
  }, [segment, isMobile])

  const infoLine = segment.vendor?.name

  return {
    headerDate,
    carRentalTitle,
    cardInfo,
    segmentPreview,
    cardSubTitle,
    infoLine,
  }
}
