import { useTranslation } from 'react-i18next'
import type { TripDetailsHotelSegment } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import {
  SegmentContainer,
  SegmentTitle,
  SegmentDescriptionContainer,
  ListItem,
  List,
  SegmentDetailsContainer,
} from '../duplicate-list-styled'
import { useHotelFormattedDate } from '../../../../use-formatted-date'

type Props = {
  hotel?: TripDetailsHotelSegment | null
}

export function DuplicateTripHotelSegment({ hotel }: Props) {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'
  const formattedDate = useHotelFormattedDate({
    start: hotel?.checkIn,
    end: hotel?.checkOut,
  })

  if (!hotel || !hotel.checkIn || !hotel.checkOut) {
    return null
  }

  return (
    <SegmentContainer>
      <Block marginRight={13}>
        <Icon name={'hotelPWA'} size={30} />
      </Block>
      <SegmentDetailsContainer>
        <SegmentTitle>{hotel.name}</SegmentTitle>
        <SegmentDescriptionContainer>
          <List>
            <ListItem>{formattedDate}</ListItem>
            <ListItem>{hotel.address?.city}</ListItem>
            <ListItem>{hotel.roomType}</ListItem>
            <ListItem>
              {t(`${i18Base}Night`, {
                count: hotel.numberOfNights,
              })}
            </ListItem>
          </List>
        </SegmentDescriptionContainer>
      </SegmentDetailsContainer>
    </SegmentContainer>
  )
}
