import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RailTabsBlockLayout } from './layout/rail-tabs-block-layout'
import type { Props } from './types'

const i18Base = 'RailDetails'

export function RailTabsBlock({ activeTab, onTabChange }: Props) {
  const { t } = useTranslation()

  const tabs = useMemo(
    () => [
      { value: 'standard', label: t(i18Base + '.Tabs.Standard') },
      { value: 'firstclass', label: t(i18Base + '.Tabs.FirstClass') },
    ],
    [t],
  )

  const layoutProps = { activeTab, onTabChange, tabs }
  return <RailTabsBlockLayout {...layoutProps} />
}
