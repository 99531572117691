import { observer } from 'mobx-react-lite'
import { useTripDetailsPage } from './use-trip-details'
import { TripDetailsProvider } from './trip-details-provider'
import { TripDetailsContent } from './trip-details-content'

export const TripDetails = observer(function TripDetails() {
  const value = useTripDetailsPage()

  return (
    <TripDetailsProvider value={value}>
      <TripDetailsContent />
    </TripDetailsProvider>
  )
})
