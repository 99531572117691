import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../types'
import { BodyText, Description, Row, Separator } from './geo-safe-location-mobile-styled'
import { GeoSafeLocationSkeleton } from './geo-safe-location-mobile-skeleton'

const skeleton = <GeoSafeLocationSkeleton />

const i18nBase = `HotelSafetyCheck.GeoSafeScores.GeoSafeLocation`

export function GeoSafeLocationMobile({ location, isLoading }: LayoutProps) {
  const { t } = useTranslation()

  if (isLoading || !location) {
    return skeleton
  }

  const locationAway = t(`${i18nBase}.LocationAway`, { distance: location.distance })

  return (
    <div aria-label={t(`${i18nBase}.AriaLabel`, { location: location.name, locationAway })}>
      <Row>
        <BodyText isBlock>{location.name}</BodyText>
      </Row>
      <Block marginTop={2}>
        <Row>
          <Description>{t(`${i18nBase}.NearestScoreFound`)}</Description>
          <Separator />
          <BodyText>{locationAway}</BodyText>
        </Row>
      </Block>
    </div>
  )
}
