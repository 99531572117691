import { observer } from 'mobx-react-lite'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { TripHotelContentProps } from './types'
import { TripHotelContentLayout } from './layout'

export const TripHotelContent = observer(function TripHotelContent(props: TripHotelContentProps) {
  const { postBookingTripStore } = usePostBookingContext()
  const {
    cancellationTime,
    processId,
    bookingRef,
    supportedActions,
    type,
  } = postBookingTripStore.trip
  const { travelerNames } = postBookingTripStore
  const travelerName = travelerNames?.travelerFullName || '-'

  return (
    <TripHotelContentLayout
      tripType={type}
      cancellationDate={cancellationTime}
      processId={processId}
      itineraryId={bookingRef}
      actions={supportedActions}
      isMultipleHotels={postBookingTripStore.isMultipleSegments.HOTEL}
      travelerName={travelerName}
      {...props}
    />
  )
})
