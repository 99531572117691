import type { HTMLAttributes, MutableRefObject, PropsWithChildren, ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { FieldDescription } from '@etta/ui/field-description'
import type {
  TextFieldHelperType,
  TextFieldValue,
  TextFieldSize,
  TextAlignTypes,
  TextFieldType,
} from '../types'
import type { SkinType } from './base-text-field-styles'
import {
  FieldContainer,
  HelperContainer,
  Label,
  LabelContainer,
  Input,
  Container,
  StartSlot,
  CenterSlot,
  EndSlot,
} from './base-text-field-styles'
import { useBaseTextField } from './use-base-text-field'

export type BaseTextFieldProps = Omit<
  HTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'type'
> & {
  inputRef?: MutableRefObject<HTMLInputElement>
  value: TextFieldValue
  label?: string
  className?: string
  valueParser?: (oldValue: string, newValue: string) => string
  type?: TextFieldType
  size?: TextFieldSize
  helperText?: string
  helperType?: TextFieldHelperType
  isHelperTextSpaceReserved?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  isLoading?: boolean
  autoFocus?: boolean
  startSlot?: ReactNode
  endSlot?: ReactNode
  autocomplete?: string
  maxLength?: number
  placeholder?: string
  onChange?: (text: string) => void
  onClick?: () => void
  onClear?: () => void
  textAlign?: TextAlignTypes
  skin?: SkinType
  isLabelEnabled?: boolean
  isFieldWithValue?: boolean
  description?: string | null
  'data-autofocus'?: string
  firstErrorUUID?: string
  fieldUUID?: string
  isBlurred?: boolean
  customAriaLabel?: string
  tabIndex?: number
}

export function BaseTextField({
  children,
  inputRef,
  startSlot,
  endSlot,
  label,
  value,
  className,
  valueParser,
  type = 'text',
  helperText = '',
  helperType = 'error',
  isReadonly,
  isRequired,
  isDisabled,
  autoFocus,
  maxLength,
  onChange,
  onClick,
  placeholder,
  size = 'normal',
  isHelperTextSpaceReserved = false,
  textAlign,
  skin = 'default',
  isLabelEnabled = true,
  isFieldWithValue,
  isLoading,
  description,
  isBlurred,
  customAriaLabel,
  onMouseDown,
  onMouseUp,
  onFocus,
  onBlur,
  ...restInputProps
}: PropsWithChildren<BaseTextFieldProps>) {
  const defaultInputRef = useRef<HTMLInputElement>(null)

  const {
    usedPlaceholder,
    isLabelExists,
    handleClick,
    isClickable,
    inputType,
    hasNoBorder,
    isError,
    hasValue,
    handlerChange,
    displayLabel,
  } = useBaseTextField({
    size,
    value,
    helperType,
    helperText,
    label,
    type,
    maxLength,
    onChange,
    onClick,
    placeholder,
    isDisabled,
    valueParser,
    isLabelEnabled,
  })

  const [isFocusedByTab, setFocusedByTab] = useState(false)
  const [leftMouseDown, setLeftMouseDown] = useState(false)
  const [isTouched, setIsTouched] = useState(!autoFocus)
  const handleMouseDown: React.MouseEventHandler = (e) => {
    setLeftMouseDown(true)

    if (onMouseDown) {
      onMouseDown(e as React.MouseEvent<HTMLInputElement>)
    }
  }
  const handleMouseUp: React.MouseEventHandler = (e) => {
    setTimeout(() => setLeftMouseDown(false))

    if (onMouseUp) {
      onMouseUp(e as React.MouseEvent<HTMLInputElement>)
    }
  }

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!isTouched) {
      setIsTouched(true)
    }

    if (!leftMouseDown && isTouched) {
      setFocusedByTab(true)
    }

    if (onFocus) {
      onFocus(e)
    }
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFocusedByTab(false)

    if (onBlur) {
      onBlur(e)
    }
  }

  useEffect(() => {
    if (!value && type === 'text' && autoFocus) {
      inputRef?.current.focus()
      defaultInputRef?.current?.focus?.()
    }
  }, [inputRef, type, value, autoFocus])

  return (
    <FieldContainer isFocusedByTab={isFocusedByTab} className={className}>
      <LabelContainer
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        aria-label={customAriaLabel || label}
        aria-invalid={isError}
        aria-disabled={isDisabled}
        aria-readonly={isReadonly}
        aria-required={isRequired}>
        {description && <FieldDescription description={description} />}
        <Container
          hasValue={hasValue}
          isError={isError}
          size={size}
          hasNoBorder={hasNoBorder}
          skin={skin}
          isDisabled={isDisabled}
          isFieldWithValue={isFieldWithValue}
          isLoading={isLoading}>
          <StartSlot>{startSlot}</StartSlot>
          <CenterSlot isStartSlotExist={!!startSlot} isEndSlotExist={!!endSlot}>
            {displayLabel && <Label aria-hidden>{label}</Label>}
            <Input
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={inputRef || defaultInputRef}
              autoFocus={autoFocus}
              type={inputType}
              value={value}
              isLabelExists={isLabelExists}
              isClickable={isClickable}
              onChange={handlerChange}
              disabled={isDisabled}
              readOnly={isReadonly || isClickable}
              maxLength={maxLength}
              placeholder={usedPlaceholder}
              textAlign={textAlign}
              isBlurred={isBlurred}
              fontSize={size}
              {...restInputProps}
            />
            {children}
          </CenterSlot>
          <EndSlot>{endSlot}</EndSlot>
        </Container>
        <HelperContainer
          helperType={helperType}
          role={isError ? 'alert' : 'none'}
          isHelperTextSpaceReserved={isHelperTextSpaceReserved}>
          {helperText}
        </HelperContainer>
      </LabelContainer>
    </FieldContainer>
  )
}
