import styled, { css, keyframes } from 'styled-components'
import { body } from '@fiji/style'
import { createHeaderScrollAnimation } from '@fiji/hooks/use-header-scroll/animations/animation-basic'

const opacity = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  animation: ${opacity} 0.6s ease-in-out;

  ul {
    padding: 0;
  }
`

export const SearchWrapper = styled.div`
  padding: 12px 16px;
`

export const Label = styled.label`
  margin-bottom: 2px;
`

export const PwaLoader = styled.div`
  display: grid;
  place-items: center;
  overflow: hidden;
  flex: 1;
`

export const PwaErrorMsg = styled.div`
  ${body};
  line-height: 26px;
  padding: 30px 28px 0 53px;
  color: ${(p) => p.theme.colors.bodyText};
  white-space: pre-line;
`

export const ResultsListContainer = styled.ul<{ isOpen: boolean }>`
  top: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  margin: 0;
  list-style: none;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0 0 7px 7px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  position: static;
  box-shadow: none;

  ${(p) =>
    p.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const Separator = styled.div`
  position: sticky;
  top: 56px;
  border-bottom: 1px solid transparent;

  ${createHeaderScrollAnimation(
    'search-mechanism-separator',
    css`
      0% {
        border-bottom-color: transparent;
      }
      1%,
      100% {
        border-bottom-color: ${(props) => props.theme.colors.secondary};
      }
    `,
  )}
`

export const ListWrapper = styled.div<{ hasTopBorder: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.hasTopBorder &&
    css`
      border-top: 1px solid ${(p) => p.theme.colors.borderLight};
    `}
`
