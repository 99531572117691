import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import type { Money } from '@fiji/graphql/types'
import { formatPrice } from '@fiji/utils/money/format-price'
import { Container, PriceItemEstimated, PriceItemToday } from './total-cost-styled'

type Props = {
  estimatedCost: Money
  dueTodayCost: Money
  buttonText?: string
  isDisabled?: boolean
  onSubmit: () => void
}

export function TotalCost({
  estimatedCost,
  dueTodayCost,
  buttonText,
  isDisabled,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  const i18Base = 'TotalCost.'

  return (
    <Container>
      <PriceItemEstimated>
        <div>{t(i18Base + 'EstimatedCost')}</div>
        <div>{formatPrice(estimatedCost, { morpheme: 'postfix' })}</div>
      </PriceItemEstimated>
      <PriceItemToday>
        <div>{t(i18Base + 'DueToday')}</div>
        <div>{formatPrice(dueTodayCost, { morpheme: 'postfix' })}</div>
      </PriceItemToday>
      <Block paddingTop={6}>
        <Button fullWidth variant="solid" onClick={onSubmit} disabled={isDisabled}>
          {buttonText || t(i18Base + 'Submit')}
        </Button>
      </Block>
    </Container>
  )
}
