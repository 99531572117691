import styled from 'styled-components'
import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'

export const FooterContainer = styled(Modal.Footer)`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClearButton = styled(Button)`
  margin-right: 16px;
  padding-left: 0;
`

export const ApplyButton = styled(Button)`
  min-width: 22rem;
`
