import styled from 'styled-components'

export const Content = styled.div<{ isMobile: boolean }>`
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 ${(props) => (props.isMobile ? '16px' : '32px')};
`

export const HeadlineWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.plum};
  padding: 8px 0 86px 24px;
`

export const TrainBookingOverlay = styled.div`
  &:not(:first-of-type) {
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      background-color: ${(props) => props.theme.colors.borderDark};
      margin-left: 28px;
    }
  }

  &:last-child {
    margin-bottom: 24px;
  }
`
