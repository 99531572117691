import { useTranslation } from 'react-i18next'
import { Skeleton } from '@etta/ui/skeleton'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { SkeletonContainer } from './car-rental-details-footer-styled'

const i18Base = 'CarRentalDetails'

export function CarRentalDetailsFooterSkeleton() {
  const { t } = useTranslation()

  return (
    <SkeletonContainer>
      <Block paddingHorizontal={30} paddingVertical={16}>
        <Skeleton width={141} height={40}>
          <rect width="50%" height="13px" />
          <rect y={20} width="100%" height="17px" />
        </Skeleton>
      </Block>
      <Block paddingHorizontal={24} paddingVertical={14}>
        <Button minWidth={24} disabled>
          {t(`${i18Base}.Select`)}
        </Button>
      </Block>
    </SkeletonContainer>
  )
}
