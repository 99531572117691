import type { MapOptions as GoogleMapOptions } from 'google-map-react'
import { Service } from '@etta/di'
import type { EttaMapOptions } from '../../core/etta-map.options'
import type { EttaMapEntity } from '../../core/etta-map.entity'
import { EttaGoogleMap } from './etta-google-map'
import { darkThemeStyles } from './const'

@Service()
export class GoogleMapService {
  public createMap(map: google.maps.Map): EttaMapEntity {
    return new EttaGoogleMap(map)
  }

  public buildGoogleMapOptions(options: EttaMapOptions, isDarkTheme: boolean): GoogleMapOptions {
    const {
      clickableIcons,
      disableDoubleClickZoom,
      draggableCursor,
      scaleControl,
      zoomControl,
      draggable,
      draggingCursor,
      fullscreenControl,
      scrollwheel,
      mapTypeId,
      maxZoom,
      keyboardShortcuts,
    } = options

    const DEFAULT_OPTIONS: GoogleMapOptions = {
      fullscreenControl: false,
      clickableIcons: false,
      zoomControl: false,
    }

    const googleMapOptions: GoogleMapOptions = {
      draggable,
      disableDoubleClickZoom,
      draggableCursor,
      scaleControl,
      draggingCursor,
      zoomControl: zoomControl ?? DEFAULT_OPTIONS.zoomControl,
      clickableIcons: clickableIcons ?? DEFAULT_OPTIONS.clickableIcons,
      fullscreenControl: fullscreenControl ?? DEFAULT_OPTIONS.fullscreenControl,
      scrollwheel,
      mapTypeId,
      maxZoom,
      keyboardShortcuts,
      styles: isDarkTheme ? darkThemeStyles : undefined,
    }

    return googleMapOptions
  }
}
