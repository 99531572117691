import { createErrorClass } from '@etta/core/errors'

export namespace GetCustomerUberAccountStatusError {
  export const UnexpectedGetCustomerUberAccountStatusError = createErrorClass(
    'UnexpectedCreateCustomerUberAccountError',
  )
}

export type GetCustomerUberAccountStatusErrorInstances = InstanceType<
  typeof GetCustomerUberAccountStatusError[keyof typeof GetCustomerUberAccountStatusError]
>
