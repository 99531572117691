import type { StopInfo } from '@etta/components/air-search-segment/types'

type Args = {
  stopsInfo: StopInfo[]
}

export function useStopsLine({ stopsInfo }: Args) {
  const stopsCount = stopsInfo.length
  const firstStopInfo = stopsCount > 0 ? stopsInfo[0] : null
  const lastStopInfo = stopsCount > 1 ? stopsInfo[stopsCount - 1] : null
  const hiddenStopsInfo = stopsCount > 2 ? stopsInfo.slice(1, -1) : null

  return {
    firstStopInfo,
    lastStopInfo,
    hiddenStopsInfo,
  }
}
