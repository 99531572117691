import type { AnySchema } from 'yup'
import i18n from '@fiji/i18n'
import { Store } from '@etta/di'
/* eslint import/no-restricted-paths: 0 */
import { AllowedCharacters } from '@fiji/graphql/types'
import type {
  ProfileFieldSettingsValueObject,
  AccountInformationFieldsSettingsValueObject,
  EmergencyContactFieldsSettingsValueObject,
  TravelDocumentsFieldsSettingsValueObject,
  TravelPreferencesFieldsSettingsValueObject,
  DisplaySettingsFieldsSettingsValueObject,
} from '../../core/value-objects/profile-fields-settings.value-object'
import type { AddressFields } from '../../core/enums/address-fields'
import type { ProfileFieldsSettingsEntity } from '../../core/entities/profile-fields-settings-entity'
import { AccountInformationIdentifiers } from '../../core/constants/profile-fields-identifiers/account-information'
import { EmergencyContactIdentifiers } from '../../core/constants/profile-fields-identifiers/emergency-contact'
import { PaymentCardsIdentifiers } from '../../core/constants/profile-fields-identifiers/payment-cards'
import { TravelDocumentsIdentifiers } from '../../core/constants/profile-fields-identifiers/travel-documents'
import { TravelPreferencesIdentifiers } from '../../core/constants/profile-fields-identifiers/travel-preferences'
import { LoyaltyProgramsIdentifiers } from '../../core/constants/profile-fields-identifiers/loyalty-programs'
import { DisplaySettingsIdentifiers } from '../../core/constants/profile-fields-identifiers/display-settings'
import { FieldSettingsCategory } from '../../core/enums/field-settings-category'
import {
  PersonalInformationFieldsSettings,
  EmployeeInformationFieldsSettings,
  HomeAddressFieldsSettings,
  BusinessAddressFieldsSettings,
  BusinessContactFieldsSettings,
} from './profile-field-settings/account-information-fields'
import { EmergencyContactFieldsSettings } from './profile-field-settings/emergency-contact-fields'
import { LoyaltyProgramsFieldsSettings } from './profile-field-settings/loyalty-programs-fields'
import { PaymentCardsFieldsSettings } from './profile-field-settings/payment-card-fields'
import { TravelPreferencesFieldsSettings } from './profile-field-settings/travel-preferences-fields'
import {
  PassportInfoFieldsSettings,
  VisaInfoFieldsSettings,
  NationalIdInfoFieldsSettings,
  AdditionalTravelDocumentsFieldsSettings,
} from './profile-field-settings/travel-documents-fields'
import { DisplaySettingsFieldsSettings } from './profile-field-settings/display-settings-fields'

const i18BaseErrors = 'Settings.Errors'

type Field =
  | keyof AccountInformationFieldsSettingsValueObject
  | keyof EmergencyContactFieldsSettingsValueObject
  | keyof TravelDocumentsFieldsSettingsValueObject
  | keyof TravelPreferencesFieldsSettingsValueObject
  | keyof DisplaySettingsFieldsSettingsValueObject
  | keyof typeof PaymentCardsFieldsSettings

type IndetifierKey =
  | keyof typeof AccountInformationIdentifiers
  | keyof typeof EmergencyContactIdentifiers
  | keyof typeof TravelDocumentsIdentifiers
  | keyof typeof TravelPreferencesIdentifiers
  | keyof typeof LoyaltyProgramsIdentifiers
  | keyof typeof DisplaySettingsIdentifiers
  | keyof typeof PaymentCardsIdentifiers

type FieldsSettings = Record<
  FieldSettingsCategory,
  Record<string | AddressFields, ProfileFieldSettingsValueObject>
>

type FieldsSettingsCheck = {
  field: Field
  value?: string | null
}

@Store()
export class FieldSettingsStore {
  fieldsIdentifiers = {
    ...AccountInformationIdentifiers,
    ...EmergencyContactIdentifiers,
    ...PaymentCardsIdentifiers,
    ...TravelDocumentsIdentifiers,
    ...TravelPreferencesIdentifiers,
    ...LoyaltyProgramsIdentifiers,
    ...DisplaySettingsIdentifiers,
  }

  fieldsSettings: FieldsSettings = {
    [FieldSettingsCategory.PersonalInformation]: PersonalInformationFieldsSettings,
    [FieldSettingsCategory.EmployeeInformation]: EmployeeInformationFieldsSettings,
    [FieldSettingsCategory.HomeAddress]: HomeAddressFieldsSettings,
    [FieldSettingsCategory.BusinessAddress]: BusinessAddressFieldsSettings,
    [FieldSettingsCategory.BusinessContact]: BusinessContactFieldsSettings,
    [FieldSettingsCategory.EmergencyContact]: EmergencyContactFieldsSettings,
    [FieldSettingsCategory.PaymentCards]: PaymentCardsFieldsSettings,
    [FieldSettingsCategory.PassportInfo]: PassportInfoFieldsSettings,
    [FieldSettingsCategory.VisaInfo]: VisaInfoFieldsSettings,
    [FieldSettingsCategory.NationalIdInfo]: NationalIdInfoFieldsSettings,
    [FieldSettingsCategory.TravelPreferences]: {
      ...TravelPreferencesFieldsSettings,
      ...AdditionalTravelDocumentsFieldsSettings,
      ...LoyaltyProgramsFieldsSettings,
    },
    [FieldSettingsCategory.DisplaySettings]: DisplaySettingsFieldsSettings,
  }

  setProfileFieldsSettings(data: ProfileFieldsSettingsEntity[]) {
    data.forEach((field) => {
      const key = field.id as IndetifierKey
      const identifier = this.fieldsIdentifiers[key]

      if (!identifier) {
        return
      }

      const category = field.categoryId as FieldSettingsCategory

      this.fieldsSettings[category][identifier] = {
        label: field.label || null,
        isEditable: field.isEditable,
        isRequired: field.isRequired,
        isRendered: field.shouldDisplayInSettings,
        isRenderedOnActivationPage: field.shouldDisplayInRegistration,
        metadata: field.fieldMetadata,
      }
    })
  }

  getFieldLabel = (field: Field, category: FieldSettingsCategory, defaultLabel: string = '') => {
    return this.fieldsSettings[category][field]?.label || defaultLabel
  }

  isFieldRequired(field: Field, category: FieldSettingsCategory, defaultLabel: string = '') {
    return (_: string | string[], schema: AnySchema) => {
      if (this.isFieldEditable(field, category) && this.isFieldRequiredBool(field, category)) {
        return schema.required(
          i18n.t(i18BaseErrors + '.FieldIsRequired', {
            fieldLabel: this.getFieldLabel(field, category, defaultLabel),
          }),
        )
      }
      return schema
    }
  }

  getAllowedCharactersCheck({ field, value }: FieldsSettingsCheck) {
    const isOptionField =
      this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]?.metadata?.fieldType ===
      'LIST'
    const allowedCharacters = this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]
      ?.metadata?.allowCharacters

    if (isOptionField) {
      return true
    }

    if (!allowedCharacters?.length || !value?.length) {
      return true
    }

    if (!allowedCharacters.includes(AllowedCharacters.Alpha) && value.match(/[a-zA-Z]/g)) {
      return false
    }

    if (!allowedCharacters.includes(AllowedCharacters.Numeric) && value.match(/[0-9]/g)) {
      return false
    }

    if (
      !allowedCharacters.includes(AllowedCharacters.Special) &&
      value.match(/[!@#$%^&*(),.?":{}|<>]/g)
    ) {
      return false
    }

    return true
  }

  getTextLengthCheck({ field, value }: FieldsSettingsCheck) {
    const isTextField =
      this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]?.metadata?.fieldType ===
      'TEXT'

    const minLength = this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]
      ?.metadata?.minLength

    const maxLength = this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]
      ?.metadata?.maxLength

    if (!isTextField) {
      return true
    }

    if (minLength && minLength > 0 && value && value.length < minLength) {
      return false
    }

    if (maxLength && maxLength > 0 && value && value.length > maxLength) {
      return false
    }

    return true
  }

  getTextLengthCheckErrorMsg({ field }: FieldsSettingsCheck) {
    const minLength = this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]
      ?.metadata?.minLength

    const maxLength = this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]
      ?.metadata?.maxLength

    if (minLength && maxLength) {
      return i18n.t(`Settings.Errors.InvalidLengthBothSides`, { min: minLength, max: maxLength })
    }

    if (minLength) {
      return i18n.t(`Settings.Errors.InvalidLengthMinOnly`, { min: minLength })
    }

    if (maxLength) {
      return i18n.t(`Settings.Errors.InvalidLengthMaxOnly`, { min: maxLength })
    }

    return i18n.t(`Settings.Errors.InvalidNumberOfCharacters`)
  }

  getEmployeeInfoDefaultValue(field: string) {
    return (
      this.fieldsSettings[FieldSettingsCategory.EmployeeInformation][field]?.metadata
        ?.defaultValue || null
    )
  }

  isFieldRequiredBool(field: Field, category: FieldSettingsCategory) {
    return !!this.fieldsSettings[category][field]?.isRequired
  }

  isFieldEditable(field: Field, category: FieldSettingsCategory) {
    return !!this.fieldsSettings[category][field]?.isEditable
  }
}
