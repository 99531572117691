import { Store } from '@etta/di'
import { QueryParams } from '@etta/interface/services/query-params'
import type { LocationRefinementQueryParamsType } from '../types'

import type { LocationRefinementOptions, ZoneValueObject } from '../../core/value-objects'
import { RefinementOptionsEnum } from '../../core/enum'
import type { AccessPointValueObject } from './../../core/value-objects/access-point.value-object'

@Store()
export class RideHailSearchLocationRefinementStore {
  isLoading: boolean = false
  isError: boolean = false
  refinementOptions: LocationRefinementOptions = {}
  renderType: 'ChildZone' | 'AccessPoint' | null = null
  refinementOptionsAction: RefinementOptionsEnum = RefinementOptionsEnum.Unspecified
  selectedAccessPoint: AccessPointValueObject | null = null
  selectedZone: ZoneValueObject | null = null
  readonly locationRefinementQueryParams = new QueryParams<LocationRefinementQueryParamsType>(
    {},
    { caseStyle: 'kebab-case' },
  )

  get locationRefinementOptions() {
    return this.refinementOptions
  }

  get childZones() {
    return this.refinementOptions.rootZone?.childZones
  }

  get zoneName() {
    return this.locationRefinementOptions?.rootZone?.name
  }

  get accessPoints() {
    if (this.selectedZone?.accessPoints && this.selectedZone?.accessPoints.length > 0) {
      return this.selectedZone.accessPoints
    }

    const accessPointsWithinRootZone = this.locationRefinementOptions?.rootZone?.accessPoints
    if (accessPointsWithinRootZone && accessPointsWithinRootZone.length > 0) {
      return accessPointsWithinRootZone
    }

    const accessPointsOutsideRootZone = this.locationRefinementOptions?.accessPoints
    if (accessPointsOutsideRootZone && accessPointsOutsideRootZone.length > 0) {
      return accessPointsOutsideRootZone
    }

    return undefined
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isError = isError
  }

  setSelectedAccessPoint(accessPoint: AccessPointValueObject | null) {
    this.selectedAccessPoint = accessPoint
  }

  setSelectedZone(zone: ZoneValueObject | null) {
    this.selectedZone = zone
  }

  setRenderType(type: 'ChildZone' | 'AccessPoint') {
    this.renderType = type
  }

  setRefinementOptionsAction(option: RefinementOptionsEnum) {
    this.refinementOptionsAction = option
  }

  dropStore() {
    this.isError = false
    this.isLoading = false
    this.refinementOptions = {}
    this.selectedAccessPoint = null
    this.selectedZone = null
    this.renderType = null
    this.refinementOptionsAction = RefinementOptionsEnum.Unspecified
  }
}
