import type { MutableRefObject } from 'react'
import { useEffect, useRef } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { DescendantItem, DescendantList, DescendantsWrapper } from '@etta/components/descendant'
import { Swap } from '@etta/ui/swap'
import { useClickOutside } from '@fiji/hooks/use-click-outside'
import { EmptyStateContainer } from '../../empty-state-container'
import type { LayoutProps } from '../types'
import { InfiniteScrollPlaceHolder } from '../../select-search-styled'
import { OptionLabel } from './option-label'
import { Option, OptionContainer, PopUpContainer } from './select-search-desktop-styled'
import { TextInput } from './text-input'

export function SelectSearchDesktop({
  placeholder,
  inputValue,
  filteredOptions,
  disabledOptions,
  isModalVisible,
  isLoading,
  hasInputValue,
  skipInputRequirement,
  onHideModal,
  onChangeInput,
  size,
  onSelect,
  onClear,
  renderOption,
  'data-tracking-id': dataTrackingId,
  hasNextPageForOptions,
  onFetchMoreForOptions,
}: LayoutProps) {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const { ref } = useClickOutside(() => {
    if (isModalVisible) {
      onHideModal()
    }
  })

  useEffect(() => {
    if (!isModalVisible) {
      return
    }
    if (!inputRef.current) {
      return
    }
    inputRef.current.focus()
  }, [isModalVisible])

  const isContainerVisible = !isLoading && (hasInputValue || skipInputRequirement)

  const [loaderRef, { rootRef }] = useInfiniteScroll({
    loading: !!isLoading,
    hasNextPage: hasNextPageForOptions,
    onLoadMore: onFetchMoreForOptions,
  })

  return (
    <DescendantsWrapper>
      {isModalVisible && (
        <PopUpContainer
          ref={ref}
          hasBorderRadius={!isContainerVisible}
          data-tracking-id={dataTrackingId}>
          <TextInput
            inputRef={inputRef}
            size={size}
            inputValue={inputValue}
            onChangeInput={onChangeInput}
            onClear={onClear}
            placeholder={placeholder}
            isLoading={isLoading}
          />
          {isContainerVisible && (
            <OptionContainer ref={rootRef}>
              <Swap is={!filteredOptions.length} isSlot={<EmptyStateContainer />}>
                <DescendantList>
                  {filteredOptions.map((option, index) => {
                    const isDisabled = disabledOptions?.includes(option.value)

                    return (
                      <DescendantItem
                        key={`${index}-${option.label}-${option.value}`}
                        isActiveStateDisabled={isDisabled}
                        onSelect={!isDisabled ? () => onSelect(option) : () => {}}>
                        {renderOption ? (
                          renderOption(option, inputValue)
                        ) : (
                          <Option isDisabled={isDisabled} data-tracking-id={option.dataTrackingId}>
                            <OptionLabel label={option.label} inputValue={inputValue} />
                          </Option>
                        )}
                      </DescendantItem>
                    )
                  })}
                  {hasNextPageForOptions && <InfiniteScrollPlaceHolder ref={loaderRef} />}
                </DescendantList>
              </Swap>
            </OptionContainer>
          )}
        </PopUpContainer>
      )}
    </DescendantsWrapper>
  )
}
