import { makeAutoObservable } from 'mobx'
import { ItineraryStorage } from '@etta/interface/services/itinerary-storage/itinerary-storage'
import type { CheckoutInfoResult } from '../../../core/value-objects/checkout-info.value-object'
import type { MealPersistValueObject } from '../../../core/value-objects/checkout-info/meal-persist.value-object'

export class CheckoutInfoMeal {
  private mealRequest: CheckoutInfoResult['mealRequest'] = null

  private mealPersist = new ItineraryStorage<MealPersistValueObject>('mealSelection', {
    air: null,
  })

  constructor() {
    makeAutoObservable(this, { isPersistEmpty: false })
  }

  get mealRequestOptions() {
    return this.mealRequest?.options || null
  }

  get mealRequestValue() {
    return this.mealPersist.values.air
  }

  isPersistEmpty() {
    return Boolean(!this.mealPersist.values.air)
  }

  dropMealRequestData() {
    this.mealRequest = null
  }

  setMealRequestData(mealRequest: CheckoutInfoResult['mealRequest']) {
    this.mealRequest = mealRequest
  }

  setMealValues(meal: MealPersistValueObject) {
    this.mealPersist.set(meal)
  }

  appendMealValue<T extends keyof MealPersistValueObject>(key: T, meal: MealPersistValueObject[T]) {
    this.mealPersist.set({
      ...this.mealPersist.values,
      [key]: meal,
    })
  }
}
