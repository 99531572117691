import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'

type Props = {
  title?: string
}

export function StateSaved({ title }: Props) {
  const { t } = useTranslation()
  const usedTitle = title || t('ViewStateModal.Saved')

  return (
    <>
      <Block marginTop={16}>
        <Icon name="checkOutlinePWA" color="primary" size={56} />
      </Block>
      <Block marginTop={20} marginBottom={8}>
        <Text variant="subHeadStrong" color="mainText" align="center">
          {usedTitle}
        </Text>
      </Block>
    </>
  )
}
