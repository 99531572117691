import type { KeyToIcon, IconSizes } from '@etta/ui/icon'
import { Icon } from '@etta/ui/icon'
import { ServiceCategory } from '@fiji/graphql/types'

type Props = {
  name: ServiceCategory
  size?: IconSizes
}

const mapServiceCategoryToIcon: KeyToIcon = {
  [ServiceCategory.Flight]: 'flightPWA',
  [ServiceCategory.Hotel]: 'hotelPWA',
  [ServiceCategory.RentalCar]: 'carRentalPWA',
  [ServiceCategory.Train]: 'railPWA',
  [ServiceCategory.CarService]: 'carServicePWA',
  [ServiceCategory.RideHail]: 'rideHailPWA',
}

export function TripCardServiceIcon({ name, size = 'normal' }: Props) {
  const iconName =
    mapServiceCategoryToIcon[name] || mapServiceCategoryToIcon[ServiceCategory.RentalCar]
  return <Icon name={iconName} color="bodyText1" size={size} />
}
