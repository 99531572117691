import type { Delegate } from '@fiji/graphql/types'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { DelegateNameWithInitials } from '@etta/components/delegate-name-initials/delegate-name-initials'
import { DelegateSwitchLayout } from '@fiji/enums'
import { useDelegateItem } from './use-delegate-item'
import { DelegatesItemContainer, FlexBlock } from './delegate-item-styled'
import { IconBlock } from './icon-block'

type Props = {
  onClick?: () => void
  isListItem?: boolean
  delegate?: Delegate
  isLoading?: boolean
  isSelected?: boolean
  showEmail?: boolean
  layout?: DelegateSwitchLayout
}

export function DelegateItem({
  onClick,
  isListItem,
  delegate,
  isLoading,
  isSelected,
  showEmail,
  layout = DelegateSwitchLayout.Explore,
}: Props) {
  const {
    delegateItemLabel,
    initials,
    fullName,
    userEmail,
    showNameWithInitials,
    showName,
  } = useDelegateItem({
    delegate,
    isSelected,
    layout,
  })

  if (!delegate) {
    return null
  }

  return (
    <DelegatesItemContainer aria-label={delegateItemLabel} onClick={onClick}>
      <FlexBlock>
        {showNameWithInitials && (
          <DelegateNameWithInitials
            initials={initials}
            name={fullName}
            email={userEmail}
            showEmail={showEmail}
            isListItem={isListItem}
          />
        )}
        {!showNameWithInitials && showName && (
          <DelegateName name={fullName} isLoading={isLoading} />
        )}
      </FlexBlock>
      <IconBlock
        isListItem={isListItem}
        isLoading={isLoading}
        isSelected={isSelected}
        layout={layout}
      />
    </DelegatesItemContainer>
  )
}
