import type { UserPreset } from '../types'

export const prod: UserPreset[] = [
  {
    site: 'fijiweb1',
    user: 'fijiweb',
    rcSession:
      '40800030-05699DFF610FE438FBBB42B6ADF5C42D-C33CD48D531A98A1A5EBE54EC48B8FC5BF55C54EF4EFCEAC886D74FF82603B0A1CAF58332280B0430B0349C064732C5CBD3D068FDBDFE8F7A2D9CE0F9E251F109E54112FFFB614FE31C10A07E6129E2D81AC9DB6065D8E81D4369CF446A43F35485CD5FF55D764819584E4ED3B7C119477561AA4271DB4CD642F3D3BAD499AE78E37411F0427E574460E1D74BAC1CC70491FD20B0E6CF5C3313C587EF462127C',
  },
  {
    site: 'fijiweb2',
    user: 'fijiweb',
    rcSession:
      '40783275-9548DA2E44E26662FDA500B7CF8C152A-C3185A21EEA2FBB213A399026252E1DC78204E142417EA365E0922B3ED61D3FC2BE0800739B8D6007C689F5D90D8EDAECC1A909BE68BC0F10663A3FBBB7508DEFEFF87353C29E92FD8C2096FF22968740B29F4853BB543B63485A215EC898AED6FB3DA24F72C265B418B88D6341F842830C1E314E52EC424E01080FBF9874A48471A27F0067256713971EB367E4C894E399D89DF7EB4F4D3ACFF6E1D42E28E8A',
  },
  {
    site: 'applefijibeta',
    user: 'c888899',
    rcSession:
      '40783275-9683409150F0A9A12179E1083A1C8AB5-DBAA78FE25B09C6B15519258389D19DBB9CE9F41AA058DA2203B416F451B2FF2BFB8F6051FBED5D186C79D2CE6E0FBDFBC52BA965A54FBA072243B2AF729DE0D0F1917365704886E297817B670C451E16BF7F5D8B42AF445B8E7BA9D97FAA2A342EB6F8C2F43795F8C1034E7C7363C1379689F846CE56EB3F6BF210846396EB9E681C040795ED957FF864D5B24B303180EB20EE79AFE60CACD41CFE3A3FBCFCB',
  },
  {
    site: 'fijiwebapple',
    user: 'fijiweb',
    rcSession:
      '40783275-243B68198B79C3F2BBBA9ACFE3E578EA-7301E394A48603633F1B9F0763E4735A10E4C6CB41F0B368623DDA8FEDD8CD09F735837E0F102091CC73BA99BB8812303F8BB1A5EB008365385201B9672DA5575752DCB791CF88DF0C87DF8D38A261A7D3C3E984B4F1BCD00A41541D1B5684FD79BEF23C743A86A2D05DD7EED4E637EDC117E55368CFBC318CB1F1470F8A8D81D2A023CBF963C404A4F54DEDE51D9CB783DF1982C91D5285A897BEBE6B6E059F',
  },
  {
    site: 'fijialpha',
    user: 'fijiweb',
    rcSession:
      '40787685-6AE833A3C41CB4D7CD31B4471255E436-EAC517EC91DA6369521ECD108D35A930E4E91ED702712363A67EF2135996C11E8E4C4A92D3490DFF0FAD96E2A0EB8C8BC10FFD2935DB76B9C6CFB2BE38E3786523B1BAFAE0504103424195A3C066BFBA9B4C54231A1405A9E647DD1DDF38379F4E5D15ED0581B7B0E17E6A69F8032EC66D8BCC47AAE5A16463382DE2B15D049CDBA2E7351C55507635C9919195250029B61AFEAD44137BFDBE5123C2C7054A1F',
  },
  {
    site: 'fijiphx',
    user: 'fijiweb',
    rcSession:
      '40783275-88695C380474871F1217500B1437B7CD-A49B55975B8C24785E83F00507E859E7F31C57CD6745A4D27ED6F0CA59490DB64FA58A044E3485E7BC04E2882BF99B59F4C183FB59370BC59ED90D16C0C69600709095A1D33DB7444D18FF54120E1882660D459A4E2F7CC16DBF529D847285DBD391DC4C573860A99581B14751805CB76894BE668A0787E278E5CDA452D6026D248874EEBFFCDF22A3ED2B415FF53F28DC4759D834CE638324687117708F87D1',
  },
  {
    site: 'fijiphxtest',
    user: 'fijiweb',
    rcSession:
      '40783275-A45E25273149A798F47DB4E2882E8BE8-53ACE606CFA8587948B7131F56EC77D07BC6CE314BBB9E302125C0D2CE5CE802AD3A7652A66EBF47B8AD024DE481397167916A56CC12B85D57F025F34C997D89F73D1F192B308E86976B88A7DC4892D492549D48DCC3E4B389EBA7F8E73EACF2F70E78A8BFF3F40A3FF6323F85BBFEC5A725D4A3C2AD83079102A8C84F032921E8480DA62C46F2FC36E83ADB5D408D18F7CD38DAA53D190B5C5D72D9EB099C1C',
  },
  {
    site: 'fijiwebmobi',
    user: 'fijiweb',
    rcSession:
      '40792980-C19D24F965BFDE8AB805FC5CF6EA8B2F-FEABB8E60168E5AA9F7F37FD3F2E393511A66C708745643634E38136A19557453BF11090A7A4AD34A7141D3D317FAC140B9320EAABCA9B7407B78EAC395F432989E5BCD290FE4E2F493A0ABC4A21F9867669489287824FA4493AC3437107143975112F94DF8A29A27B3D2A7380DAEA5386569DD9C63A8ADA419942C7E5EC81CE67E201B6EE65B35E41B764171D07160099673D87186AE5ACAA4EB7EF2DB0E23C',
  },
  {
    site: 'fijiphxtest',
    user: 'fijisabre',
    rcSession:
      '40803210-08E6F3A61B90A91C656090BDAEAC4EC0-E707DCEEDC8A2AA1493272EF5BAADC45FFCC4EB410574C5F04EE8E253B7CDECD85967400C685E9A192482C8103DA818E718ACDF492DF1D1B7A36D3B802D7E479518237A9BF19411E7F7735D900D6C3F3BCEF7B856CC88BFCC33FB6637CC1C718E8B2AC038C6FCAB39D7AA025466DAC765DDD428877C0BBCF3F165E5C3B709863BA38D50E5B4FD3F7F09CD138038EC692382CAE0B2405485A38C6FEA8A3E2BE5D',
  },
]
