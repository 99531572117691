import { SegmentType, IntentState } from '@fiji/graphql/types'
import type { FlightSegmentEntity, FlightSubSegmentEntity } from '../../../core/entity'
import { SegmentState } from '../../../core/enum/segment-state.enum'
import type { FlightLeg, FlightLegSubSegment } from './types'
import { TripMoneyMapper } from './trip-money.mapper'

export class FlightDomainMapper {
  static toFlightSegmentEntity({
    flightLeg,
    intentId,
    state,
    isInPolicy,
  }: {
    flightLeg: FlightLeg
    intentId: string
    state: IntentState
    isInPolicy: boolean
  }): FlightSegmentEntity {
    const segmentState = state === IntentState.Modified ? SegmentState.Changed : undefined

    const {
      arrivalDateTime,
      departureDateTime,
      fareAttributes,
      legId,
      rate,
      segments,
      stops,
      baseRate,
      fareLabel,
      fareRules,
      feesAndTaxesRate,
      flightTime,
      isReserved,
      isRoundTripCost,
      isRoundTripLeg,
      isSameCarrierName,
      luggageOptions,
      totalCostRate,
      totalTime,
      unusedTicketRate,
    } = flightLeg

    return {
      position: 0,
      uuid: intentId,
      type: SegmentType.Flight,
      segmentState,
      arrivalDateTime,
      departureDateTime,
      flightStops: segments.length,
      legId,
      policy: {
        isInPolicy,
      },
      rate: TripMoneyMapper.toRateValueObject(totalCostRate || rate),
      segments: segments.map((subSegment) => FlightDomainMapper.toFlightLegSubSegment(subSegment)),
      stops,
      baseRate: TripMoneyMapper.toRateValueObject(baseRate),
      fareAttributes,
      fareLabel,
      fareRules,
      feesAndTaxesRate,
      flightTime,
      isReserved,
      isRoundTripCost,
      isRoundTripLeg,
      isSameCarrierName,
      luggageOptions,
      totalCostRate: TripMoneyMapper.toRateValueObject(totalCostRate),
      totalTime,
      unusedTicketRate,
    }
  }

  private static toFlightLegSubSegment(subSegment: FlightLegSubSegment): FlightSubSegmentEntity {
    const {
      arrival,
      arrivalDate,
      carrierCode,
      codeshareCarrier,
      departure,
      departureDate,
      flightNumber,
      name,
      serviceClass,
      aircraft,
      amenities,
      baggageFees,
      bookingCode,
      bookingProvider,
      destinationTerminal,
      fare,
      flightTime,
      isDepartAirportDifferentFromConnecting,
      mealService,
      operatedBy,
      originTerminal,
      penaltyFees,
      percentageOnTime,
      preferenceAttributes,
      preferredDescription,
      segmentId,
      serviceFee,
    } = subSegment

    return {
      arrival,

      arrivalDate,
      carrierCode,
      codeshareCarrier,
      departure,
      departureDate,
      flightNumber,
      images: { rectangle: '', square: '' },
      isSeatAvailable: false,
      isSeatSelectable: false,
      name,
      serviceClass,
      travelerId: '1',
      travelerSeat: '',
      aircraft,
      amenities,
      baggageFees,
      bookingCode,
      bookingProvider,
      destinationTerminal,
      fare,
      flightTime,
      isDepartAirportDifferentFromConnecting,
      mealService,
      operatedBy,
      originTerminal,
      penaltyFees,
      percentageOnTime,
      preferenceAttributes,
      preferredDescription,
      segmentId,
      serviceFee,
      imageUrl: '',
    }
  }
}
