import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Button } from '@etta/ui/button'
import { Checkbox } from '@etta/ui/checkbox'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../../types'
import {
  BottomSeparator,
  CheckboxWrapper,
  ClearAllButton,
  Footer,
  DescriptionBody,
  DescriptionContainer,
  DescriptionIcon,
  DescriptionTitle,
} from './duplicate-trips-modal-content-mobile-styled'
import { DuplicateList } from './duplicate-list'

export function DuplicateTripsModalContentMobile({
  onApply,
  onClose,
  headerTitle,
  toggleApplied,
  isApplied,
  overlappingTrips,
}: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'ReviewTrip.DuplicateTripModal.'

  return (
    <>
      <Modal.Header isMobile withBorder>
        <Header
          leftSlot={<Header.CloseButton onClick={onClose} color="mainText" />}
          backgroundColor="white">
          <Header.Title title={headerTitle} align="left" color="mainText" />
        </Header>
      </Modal.Header>
      <Modal.Body>
        <DescriptionContainer>
          <DescriptionIcon>
            <Icon name="errorFilledPWA" color="mainText" size={23} />
          </DescriptionIcon>
          <DescriptionBody>
            <DescriptionTitle>{t(`${i18Base}DuplicateTrip`)}</DescriptionTitle>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18Base}Description`)}
            </Text>
          </DescriptionBody>
        </DescriptionContainer>
        <DuplicateList overlappingTrips={overlappingTrips} />
        <CheckboxWrapper>
          <Checkbox
            label={<Text variant="subHeadStrong">{t(`${i18Base}Understand`)}</Text>}
            value="isApplied"
            checked={isApplied}
            onChange={toggleApplied}
          />
        </CheckboxWrapper>
        <BottomSeparator />
      </Modal.Body>
      <Modal.Footer>
        <Footer>
          <ClearAllButton variant="outline" onClick={onClose}>
            {t(`${i18Base}Cancel`)}
          </ClearAllButton>
          <Button disabled={!isApplied} onClick={onApply} fullWidth>
            {t(`${i18Base}ContinueToCheckout`)}
          </Button>
        </Footer>
      </Modal.Footer>
    </>
  )
}
