import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Text } from '@etta/ui/text'
import { ROUTES } from '@fiji/routes'
import { useTravelSupportContext } from '../interface/use-travel-support.context'
import { LeftItem, RightItem, Row } from './travel-support-article-pagination-styled'

type Props = {
  articleId: string
}

const i18Base = 'TripPlanner.Support.MainPage'

export const TravelSupportArticlePagination = observer(({ articleId }: Props) => {
  const { t } = useTranslation()
  const { travelSupportStore } = useTravelSupportContext()
  const { isFirst, isLast, next, prev } = travelSupportStore.getSupportArticleNavigation(articleId)

  return (
    <Row>
      {!isFirst && (
        <LeftItem to={ROUTES.support.article({ articleId: prev?.id })}>
          <Text variant="subHeadStrong" color="primary">
            {t(`${i18Base}.Prev`)}
          </Text>{' '}
          : {prev?.title}
        </LeftItem>
      )}
      {!isLast && (
        <RightItem to={ROUTES.support.article({ articleId: next?.id })}>
          <Text variant="subHeadStrong" color="primary">
            {t(`${i18Base}.Next`)}
          </Text>{' '}
          : {next?.title}
        </RightItem>
      )}
    </Row>
  )
})
