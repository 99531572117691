import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Swap } from '@etta/ui/swap'
import { Block } from '@etta/ui/block'
import { Spinner } from '@etta/ui/spinner'
import { Text } from '@etta/ui/text'
import { LoadingContainer, SpinnerContainer, Container, Wrapper } from './seat-map-loading.styled'

type Props = {
  isNewSeatMap: boolean
}

export const SeatMapLoading = observer(function SeatMapLoading({ isNewSeatMap }: Props) {
  const { t } = useTranslation()

  return (
    <Swap
      is={isNewSeatMap}
      isSlot={
        <Container>
          <Wrapper>
            <Block>
              <Spinner type="spinout" />
            </Block>
            <Block marginTop={16}>
              <Text variant="subHeadStrong" color="mainText">
                {t('AirSeatMap.Loading')}
              </Text>
            </Block>
          </Wrapper>
        </Container>
      }>
      <LoadingContainer>
        <SpinnerContainer>
          <Spinner type="spinout" />
        </SpinnerContainer>
      </LoadingContainer>
    </Swap>
  )
})
