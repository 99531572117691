import { MediaLayout } from '@etta/ui/media-layout'
import type { LayoutProps } from './types'
import { BlockUnavailableMobile } from './mobile'
import { BlockUnavailableDesktop } from './desktop'

export function BlockUnavailableLayout(props: LayoutProps) {
  return (
    <MediaLayout
      mobileSlot={<BlockUnavailableMobile {...props} />}
      desktopSlot={<BlockUnavailableDesktop {...props} />}
    />
  )
}
