import type { CarRentalFragment, Segment } from '@fiji/graphql/types'
import { SegmentSpecificUnit } from '../segment-specific-unit'
import { useCarRentalCostLabelAndName } from './use-car-rental-cost-label-and-name'

type Props = {
  carRentalFragment?: CarRentalFragment | null
  carRentalSegment?: Segment | null
}

export function CarRentalCostLabelAndName({ carRentalFragment, carRentalSegment }: Props) {
  const { cost, label } =
    useCarRentalCostLabelAndName({
      segment: carRentalFragment || carRentalSegment?.carRental,
    }) || {}

  if (!cost || !label) {
    return <></>
  }

  return <SegmentSpecificUnit cost={cost} labelSlot={label} />
}
