import { Text } from '@etta/ui/text'
import {
  ImageWrapper,
  HeaderWrapper,
  RailCompanyLogosWrapper,
  RailCompanyList,
  RailCompanyWrapper,
} from './rail-details-header-styles'
import { RailDetailsHeaderLogos } from './rail-details-header-logos'

type Props = {
  title: string
  railLogo: string
  railLabels: string[]
  squareRailLogos?: string[]
}

export function RailDetailsHeader({ title, railLogo, railLabels, squareRailLogos }: Props) {
  return (
    <HeaderWrapper>
      <RailCompanyLogosWrapper>
        <ImageWrapper>
          <RailDetailsHeaderLogos railLogo={railLogo} squareRailLogos={squareRailLogos} />
        </ImageWrapper>
      </RailCompanyLogosWrapper>
      <RailCompanyWrapper>
        <Text variant="headline" color="mainText" noWrap textOverflow="ellipsis">
          {title}
        </Text>
        <RailCompanyList variant="footnoteMedium" color="bodyText" noWrap textOverflow="ellipsis">
          {railLabels.filter(Boolean).join(', ')}
        </RailCompanyList>
      </RailCompanyWrapper>
    </HeaderWrapper>
  )
}
