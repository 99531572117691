import styled, { css } from 'styled-components'
import { subHeadMedium } from '@fiji/style'

const BORDER_RADIUS = 8

export const PopUpContainer = styled.div<{ hasBorderRadius?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: ${BORDER_RADIUS}px;

  ${({ hasBorderRadius }) =>
    !hasBorderRadius &&
    css`
      & > *:first-child > label > div {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`

export const OptionContainer = styled.ul`
  max-height: 220px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 3px 0 0 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: ${BORDER_RADIUS}px;
`

export const Option = styled.li<{ isDisabled?: boolean }>`
  padding: 17px 24px;
  cursor: pointer;
  white-space: pre-wrap;

  :hover {
    background-color: ${({ theme }) => theme.colors.background};

    :last-child {
      border-bottom-left-radius: ${BORDER_RADIUS}px;
      border-bottom-right-radius: ${BORDER_RADIUS}px;
    }
  }
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
  }

  ${subHeadMedium}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: auto;
      color: ${({ theme }) => theme.colors.bodyText};

      :hover {
        background-color: transparent;
      }
    `}
`

export const IconContainer = styled.div`
  padding-right: 8px;
`
