import styled, { css } from 'styled-components'

export const RailSearchDesktopWrapper = styled.div<{ isMaxLaptop?: boolean }>`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${({ isMaxLaptop }) =>
    isMaxLaptop &&
    css`
      padding-left: 32px;
      padding-right: 32px;
    `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 952px;
  margin-bottom: 8px;
`

export const Buttons = styled.div`
  max-width: 952px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const OneRow = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
`

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  min-height: 64px;
  align-items: stretch;
`

export const LocationSlot = styled.div<{ isTwoSections?: boolean }>`
  width: ${(p) => (p.isTwoSections ? '47%' : '65%')};
`

export const RightSlot = styled.div<{ isTwoSections?: boolean }>`
  width: ${(p) => (p.isTwoSections ? '53%' : '35%')};
  display: flex;
  gap: 8px;

  & > div {
    width: ${(p) => (p.isTwoSections ? '50%' : '100%')};
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`
