import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(Text).attrs(() => ({
  color: 'mainText',
  variant: 'headline',
  isBlock: true,
}))`
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const StyledText = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText1',
}))`
  line-height: 19px;
  margin-top: 8px;
`

export const AirlineLogo = styled.img`
  display: block;
  height: 60px;
`
