import { useState } from 'react'

export function useImageLoad() {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [isImageError, setIsImageError] = useState<boolean>(false)
  const updateImageLoaded = () => {
    setIsImageLoaded(true)
  }
  const updateImageError = () => {
    setIsImageError(true)
  }
  return {
    isImageLoaded,
    isImageError,
    updateImageLoaded,
    updateImageError,
  }
}
