export default function InfoIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0882 28.0294V28.5294H23.5882H25.4118C25.757 28.5294 25.9853 28.7796 25.9853 29.0588C25.9853 29.3583 25.7667 29.5882 25.4118 29.5882H19.4265C19.0715 29.5882 18.8529 29.3583 18.8529 29.0588C18.8529 28.7796 19.0812 28.5294 19.4265 28.5294H21.25H21.75V28.0294V21.2794V20.7794H21.25H19.6765C19.3215 20.7794 19.1029 20.5495 19.1029 20.25C19.1029 19.9707 19.3312 19.7206 19.6765 19.7206H22.4412C22.6889 19.7206 22.8266 19.8023 22.9118 19.9047C23.0078 20.0198 23.0882 20.2239 23.0882 20.5441V28.0294ZM36.5 22C36.5 29.9297 29.9297 36.5 22 36.5C14.0846 36.5 7.5 29.9294 7.5 22C7.5 14.0843 14.0709 7.5 21.9853 7.5C29.9153 7.5 36.5 14.0853 36.5 22ZM9.01471 22C9.01471 29.2166 14.7819 35 22 35C29.2164 35 35.0153 29.2181 35 21.9989C34.9847 14.7977 29.2032 9 21.9853 9C14.7661 9 9.01471 14.7993 9.01471 22ZM23.2794 14.8529C23.2794 15.6381 22.6625 16.2647 21.8676 16.2647C21.085 16.2647 20.4559 15.6356 20.4559 14.8529C20.4559 14.053 21.0875 13.4265 21.8676 13.4265C22.66 13.4265 23.2794 14.0505 23.2794 14.8529Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}
