import { useTranslation } from 'react-i18next'
import type { Rate, SegmentType } from '@fiji/graphql/types'
import { SegmentState } from '@fiji/graphql/types'
import { formatRate } from '@fiji/utils/money/format-rate'
import { CostContainer, Text } from '../payment-summary-styled'
import { getPreviousCostMsg } from './get-previous-cost-msg'

type Props = {
  segmentState?: SegmentState
  rate?: Rate | null
  isFromPreviousBooking?: boolean
  type: SegmentType
}

export function ChangedSegmentRate({ segmentState, rate, isFromPreviousBooking, type }: Props) {
  const { t } = useTranslation()
  const i18nBase = 'PaymentSummary.'

  if (!rate || !segmentState || isFromPreviousBooking) {
    return null
  }

  return (
    <CostContainer>
      <Text aria-label={t(i18nBase + 'PreviousBookingAccessibilityLabel')}>
        {t(i18nBase + getPreviousCostMsg(type))}
      </Text>
      <Text>
        {segmentState === SegmentState.Changed ? '- ' : ''}
        {formatRate(rate, { morpheme: 'none' }).mainCost}
      </Text>
    </CostContainer>
  )
}
