import styled from 'styled-components'
import { subHeadMedium, subHeadStrong, headline, title3, footnoteMedium } from '@fiji/style'
import { Icon } from '@etta/ui/icon'
import { Link } from '@etta/ui/link'
import type { EmissionsDeviationLevel } from '@fiji/enums'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: max-content;
`

export const UpperBody = styled.div`
  padding: 10px 20px;
`

export const EmissionInformation = styled.div``

export const EmissionInformationRow = styled.div<{ withNegativeMarginTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: ${(props) => (props.withNegativeMarginTop ? '-5px' : 0)};
`

export const EmissionInformationType = styled.p`
  ${subHeadMedium}
  color: ${(props) => props.theme.colors.mainText1};
`

export const EmissionInformationValue = styled.p<{ deviationLevel?: EmissionsDeviationLevel }>`
  color: ${(props) => props.theme.colors.mainText};
  ${subHeadStrong};
`

export const AverageEmissionInformationValue = styled.p<{
  deviationLevel?: EmissionsDeviationLevel
}>`
  color: ${(props) => props.theme.colors.bodyText};
  ${subHeadStrong};
`

export const DeviationInfo = styled.div<{ deviationLevel: EmissionsDeviationLevel }>`
  color: ${(props) => props.theme.colors.mainText};
  background-color: ${(props) => props.theme.colors.background};
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  ${subHeadMedium};
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const DownArrow = styled(Icon)`
  transform: rotate(180deg);
`

export const CarouselContainer = styled.div`
  margin-top: 16px;
`

export const EmisisonDetailsMobile = styled.div`
  padding: 0 20px;
`

export const EmisisonDetailsHeaderMobile = styled.p<{ isFirstHeader?: boolean }>`
  margin: 0;
  margin-top: ${(props) => (props.isFirstHeader ? '6px' : '16px')};
  ${headline};
`

export const EmisisonDetailsDescriptionMobile = styled.p<{ withBorder?: boolean }>`
  color: ${(props) => props.theme.colors.bodyText};
  ${footnoteMedium};
  border-bottom: ${(props) => (props.withBorder ? '1px' : 0)} solid
    ${(props) => props.theme.colors.borderLight};
  padding-bottom: 15px;
  margin: 0;
  margin-top: 8px;
`

export const ClapContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
`
export const ClapTitle = styled.p`
  margin-left: 10px;
  ${title3}
`

export const EquivalentContainer = styled.div`
  padding: 22px 0px 0px 16px;
`
export const EquivalentEmission = styled.div`
  display: flex;
  gap: 2px;
`
export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`
export const Title = styled.div`
  padding-left: 8px;
  ${headline};

  margin: 0px;
`

export const ViewSourceLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary1};
`
export const EquivalentEmissionValue = styled.div`
  ${headline};
  color: ${(props) => props.theme.colors.mainText};
`
