import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import type { Place } from '@fiji/types'
import type { AddressInput } from '@fiji/graphql/types'
import { DescendantItem } from '@etta/components/descendant'
import type { GoogleLocationTypes } from './get-place-by-lat-long'
import { Container, Text, TextWrapper } from './current-location-search-styled'
import { DisabledGeoDialog } from './disable-geo-dialog/disabled-geo-dialog'
import { useCurrentLocationSearch } from './use-current-location-search'

export type Props = {
  customIcon?: JSX.Element
  customTitle?: string
  setIsLoading?: (v: boolean) => void
  searchTypes?: GoogleLocationTypes[]
  onClick: (value: Place, address?: AddressInput) => void
  dataTrackingId?: string
}

const i18nPath = 'SearchResults.'

export function CurrentLocationSearch({
  customIcon,
  customTitle,
  onClick,
  setIsLoading,
  searchTypes,
  dataTrackingId,
}: Props) {
  const { t } = useTranslation()

  const { isActive, isDenied, handleGeolocation, handleDeny } = useCurrentLocationSearch({
    onClick,
    setIsLoading,
    searchTypes,
  })

  return (
    <DescendantItem onSelect={handleGeolocation}>
      <Container onClick={handleGeolocation} data-tracking-id={dataTrackingId}>
        {customIcon || <Icon name="searchPinPWA" size="medium" />}
        <TextWrapper>
          <Text
            active={isActive}
            aria-label={customTitle || t(`${i18nPath}CurrentLocation.CurrentLocationVoice`)}>
            {customTitle || t(`${i18nPath}CurrentLocation.CurrentLocation`)}
          </Text>
        </TextWrapper>
      </Container>
      <DisabledGeoDialog isOpen={isDenied} setOpen={handleDeny} />
    </DescendantItem>
  )
}
