import type { SegmentType } from '@fiji/graphql/hooks'
import type { OOPGenericData } from '@fiji/types'
import type { OutOfPolicyEnum, OutOfPolicyData } from '@fiji/graphql/types'

export enum FilterPolicyType {
  ByDescription = 'ByDescription',
  ByData = 'ByData',
}

export function filterOutPolicy<T = OutOfPolicyEnum[] | OutOfPolicyData[]>(
  policy: OOPGenericData[],
  segmentType: SegmentType,
  filterType: FilterPolicyType,
): T {
  const policyFromType = policy
    .map((policyUnit: OOPGenericData) => {
      if (policyUnit && policyUnit.type === segmentType) {
        switch (filterType) {
          case FilterPolicyType.ByDescription:
            return policyUnit.oopReasons
          case FilterPolicyType.ByData:
            return policyUnit.oopData
          default:
            return []
        }
      }
      return []
    })
    .flat()
    .filter(Boolean)

  // @ts-expect-error
  return Array.from(new Set(policyFromType))
}
