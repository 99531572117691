import { TrainServiceClass } from '@fiji/enums'
import type {
  CalculateRailEmissionsBatchRailSegmentInput,
  CalculateRailEmissionsBatchRailInput,
  SearchRailLeg,
} from '@fiji/graphql/hooks'
import { CalculateEmissionsRailSegmentTravelClassDto } from '@fiji/graphql/types'

export function mapToThrustCarbonRail(
  railLeg: SearchRailLeg,
  travelClass: CalculateEmissionsRailSegmentTravelClassDto,
): CalculateRailEmissionsBatchRailInput {
  return {
    customRef: railLeg.legId,
    segments: mapToThrustCarbonRailSegments(railLeg, travelClass),
  }
}

export function mapToThrustCarbonRailSegments(
  railLeg: SearchRailLeg,
  travelClass: CalculateEmissionsRailSegmentTravelClassDto,
): CalculateRailEmissionsBatchRailSegmentInput[] {
  return railLeg.segments.map((segment) => {
    return {
      carrierCode: segment.carrier?.code,
      class: travelClass,
      departureDate: segment.departureDate,
      originStationCode: segment.origin.code,
      destinationStationCode: segment.destination.code,
    }
  })
}

export function mapToThrustCarbonRailClass(
  travelClass: TrainServiceClass,
): CalculateEmissionsRailSegmentTravelClassDto {
  switch (travelClass) {
    case TrainServiceClass.Standard:
      return CalculateEmissionsRailSegmentTravelClassDto.Economy
    case TrainServiceClass.FirstClass:
      return CalculateEmissionsRailSegmentTravelClassDto.First
    default:
      return CalculateEmissionsRailSegmentTravelClassDto.Economy
  }
}
