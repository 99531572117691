import type { MouseEvent } from 'react'
import { useCallback, useEffect } from 'react'
import { logOut } from '@fiji/providers/auth-provider'
import { useAppSelector } from '@fiji/store'
import { userSelector } from '@fiji/store/user'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { useSiteSsoSettingsContext } from '@etta/modules/site-sso-settings'
import { useReviewTripContext } from '@etta/modules/review-trip'

type Args = {
  isSessionExpired?: boolean
}

export function useLogOut(args?: Args) {
  const { isSessionExpired = false } = args || {}

  const { profile, companyId } = useAppSelector(userSelector)
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { siteSsoSettingsStore, siteSsoSettingsActions } = useSiteSsoSettingsContext()
  const { sessionTimeoutRedirectURL, logoutRedirectURL } =
    siteSsoSettingsStore.siteSsoSettings || {}
  const { activeTripActions } = useReviewTripContext()

  const { isCustomLogoutRedirectEnabled, isSsoSettingsEnabled } = featureFlagsStore.flags

  useEffect(() => {
    if (isSsoSettingsEnabled) {
      void siteSsoSettingsActions.fetchSiteSsoSettings()
    }
  }, [isSsoSettingsEnabled, siteSsoSettingsActions])

  const logoutSsoUrl = isSessionExpired ? sessionTimeoutRedirectURL : logoutRedirectURL

  const onLogout = useCallback(
    (e?: MouseEvent) => {
      // remove current itinerary to not appear in another account
      activeTripActions.clearActiveTrip()

      const url = profile?.site.url

      if (!url) {
        throw new Error('Site url does not exist')
      }

      const apolloClientUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL || '/app/graphql'

      e?.preventDefault()
      logOut({
        apolloClientUrl,
        isCustomLogoutRedirectEnabled,
        companyId,
        logoutSsoUrl,
      })
    },
    [profile, isCustomLogoutRedirectEnabled, companyId, logoutSsoUrl, activeTripActions],
  )

  return { onLogout }
}
