import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { IconButton } from '@etta/ui/icon-button'
import { Text } from '@etta/ui/text'
import type { PWAIconProps } from '@etta/ui/icon/pwa-icon'

export const Filter = styled.div`
  background: ${(p) => p.theme.colors.background};
  height: 44px;
  padding: 14px 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`

export const FilterWrapper = styled.div`
  position: relative;
`

export const StyledText = styled(Text)`
  display: inline-block;
  width: calc(100% - 50px);
`

export const StyledIconButton = styled(IconButton)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 15px;
  right: 20px;
`

export const StyledIcon = styled(Icon).attrs<PWAIconProps>(() => ({
  color: 'mainText',
  size: 'small',
}))``
