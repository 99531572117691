import { observer } from 'mobx-react-lite'
import { useModifyFlight } from '@etta/screens/trip-flight/use-trip-flight'
import { NoModifySegment } from '@etta/components/no-modify-segment/no-modify-segment'
import { CanceledTripSegmentDialog } from '@etta/components/canceled-trip-segment-dialog'
import { SegmentType } from '@etta/core/enums'
import { ChangeFlightsDialog } from '@etta/components/change-flights-dialog'
import { SegmentCard, useSegmentCard } from '../segment-card'
import { SectionDate } from '../date-segments-styled'
import type { LayoutProps } from './types'
import { useFlightSegment } from './use-flight-segment'

export const FlightSegment = observer(function FlightSegment({
  segment,
  isUpcoming,
  isOnHold,
  currentDate,
  onClick,
  onCancel,
  onSeatMapOpen,
  itineraryId,
  bookingId,
  flightId,
  isTripInProgress,
  airTicket,
  isNDCTrip,
}: LayoutProps) {
  const { isPast, dateToShow } = useSegmentCard({ segment, currentDate, isOnHold })

  const { handleModifyFlight } = useModifyFlight({
    itineraryId: itineraryId || '',
    processId: bookingId || '',
    flightId: flightId,
  })

  const {
    titleAirportCode,
    titleCity,
    ariaLabel,
    departureTime,
    dataTexts,
    buttons,
    typeTitle,
    handleShare,
    isReturnFlightModalOpen,
    handleReturnFlightModalClose,
    isAllowedFlightModalOpen,
    handleNotAllowedFlightModalClose,
    isChangingReturnFlight,
    isChangingEntireFlight,
    operatedByText,
    shouldShowAirportChangeBadge,
    airlineLabel,
    flightStatus,
    headerDate,
    flightItemTitle,
    flightTime,
    flightInfo,
    segmentPreview,
  } = useFlightSegment({
    segment,
    dateToShow,
    isUpcoming,
    isOnHold,
    isTripInProgress,
    onCancel,
    onModify: handleModifyFlight,
    onSeatMapOpen,
    airTicket,
  })

  return (
    <>
      <SegmentCard
        isPast={isPast}
        isUpcoming={isUpcoming}
        ariaLabel={ariaLabel}
        segmentIcon="flightPWA"
        onClick={onClick}
        titleSlot={<span data-tracking-id="flight-to-text">{titleAirportCode}</span>}
        titleText={titleCity}
        dateSlot={<SectionDate data-tracking-id="flight-time-text">{dateToShow}</SectionDate>}
        departureTime={departureTime}
        dataTexts={dataTexts}
        label={airlineLabel}
        segmentStatus={flightStatus}
        buttons={buttons}
        onShare={handleShare}
        operatedByText={operatedByText}
        flightLegSegments={segment.segments}
        shouldShowAirportChangeBadge={shouldShowAirportChangeBadge}
        segmentType={SegmentType.Flight}
        headerDate={headerDate}
        cardTitle={flightItemTitle}
        cardSubTitle={flightTime}
        cardInfo={[flightInfo]}
        segmentPreview={segmentPreview}
        isNDCFare={isNDCTrip}
        isOnHold={isOnHold}
      />
      <ChangeFlightsDialog
        onModify={handleModifyFlight}
        isChangingReturnFlight={isChangingReturnFlight}
        isChangingEntireFlight={isChangingEntireFlight}
        isOpen={isReturnFlightModalOpen}
        onClose={handleReturnFlightModalClose}
      />
      <NoModifySegment
        isOpen={isAllowedFlightModalOpen}
        onClose={handleNotAllowedFlightModalClose}
        type={typeTitle}
      />
      <CanceledTripSegmentDialog type={SegmentType.Flight} />
    </>
  )
})
