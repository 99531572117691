import { Icon } from '@etta/ui/icon'
import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { ClockIconWrapper, LayoverWrapper } from './expanded-view-styled'

type Props = {
  contentSlot: JSX.Element | string
  backgroundColor?: LayoutBackgroundColor
}

export function Layover({ contentSlot, backgroundColor }: Props) {
  if (!contentSlot) {
    return null
  }
  return (
    <LayoverWrapper data-tracking-id="layover">
      {contentSlot}
      <ClockIconWrapper backgroundColor={backgroundColor}>
        <Icon name="timerPWA" size="normal" color="bodyText" />
      </ClockIconWrapper>
    </LayoverWrapper>
  )
}
