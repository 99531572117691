import type { FlightSegmentEntity } from '@etta/modules/review-trip/core'
import { getFlightSafetyChecks } from '@fiji/utils/flight-safety-check/get-flight-safety-checks'
import { getUniqFlightSafetyCheckIconNames } from '@fiji/utils/flight-safety-check/get-flight-safety-checks-icon-names'
import { getFlightSafetyCheckDetails } from '@fiji/utils/flight-safety-check/get-flight-safety-check-details'
import { useAirSearchContext } from '@etta/modules/air-search/interface/use-air-search.context'

type Args = {
  segment: FlightSegmentEntity
  isFlightSafetyCheckEnabled: boolean
  itineraryId?: string
}

export function useFareAttributesData({ segment, isFlightSafetyCheckEnabled, itineraryId }: Args) {
  const flightLegSegment = segment
  const carrierNames = flightLegSegment
    ? flightLegSegment.segments.map((segment) => {
        return {
          carrierName: segment.name || '',
          carrierLogo: segment.images.rectangle || '',
          flightNumber: segment.flightNumber,
        }
      })
    : []

  const { airSearchStore, airSearchActions } = useAirSearchContext()

  const handleRefetch = async () => {
    await airSearchActions.getFareAttributesForItineraryFlights(
      [flightLegSegment],
      itineraryId,
      isFlightSafetyCheckEnabled,
    )
  }

  const fareAttributes = airSearchStore.fareAttributes.get(flightLegSegment.legId) || []

  const flightSafetyChecks =
    carrierNames && fareAttributes && !!fareAttributes.length
      ? getFlightSafetyChecks(carrierNames, fareAttributes)
      : []
  const flightSafetyCheckIcons =
    flightSafetyChecks && !!flightSafetyChecks.length
      ? getUniqFlightSafetyCheckIconNames(flightSafetyChecks)
      : []
  const flightSafetyCheckDetails =
    fareAttributes && !!fareAttributes.length ? getFlightSafetyCheckDetails(fareAttributes) : []

  return {
    flightSafetyCheckIcons,
    flightSafetyChecks,
    isFlightFareAttributesLoading: airSearchStore.isFareAttributesLoading,
    isFlightFareAttributesError: airSearchStore.isFareAttributesError,
    refetchFlightFareAttributes: handleRefetch,
    flightSafetyCheckDetails,
  }
}
