import { Skeleton } from '@etta/ui/skeleton'
import { Container } from './hotel-room-skeleton-desktop-styled'

export const HotelRoomSkeletonDesktop = (
  <Container>
    <Skeleton width="100%" height="132">
      <rect x="0" y="0" width="18%" height="18" />
      <rect width="100%" height="1" x="0" y="37" />

      <rect x="0" y="50" width="25%" height="12" />
      <rect x="0" y="70" width="60%" height="12" />

      <rect x="0" y="101" width="60%" height="12" />
      <rect x="0" y="121" width="32%" height="12" />
    </Skeleton>
    <Skeleton width="281px" height="100%" align="absolute-right">
      <rect x="0" y="18" width="50" height="18" />
      <rect x="54" y="18" width="90" height="18" />
      <rect x="160" y="16" width="97" height="32" />

      <rect x="141" y="70" width="116" height="12" />
    </Skeleton>
  </Container>
)
