import type { KeyboardEvent, KeyboardEventHandler } from 'react'
import { useCallback } from 'react'
import type { DescendantsManager } from '@etta/components/descendant'

export function useDescendantsKeyDown<
  T extends HTMLElement = HTMLElement,
  K extends Record<string, any> = {}
>(descendants: DescendantsManager<T, K>, focusIndex: number) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const nextTab = () => {
        const next = descendants.nextEnabled(focusIndex)
        if (next) {
          next.node?.focus()
        }
      }
      const prevTab = () => {
        const prev = descendants.prevEnabled(focusIndex)
        if (prev) {
          prev.node?.focus()
        }
      }

      const firstTab = () => {
        const first = descendants.firstEnabled()
        if (first) {
          first.node?.focus()
        }
      }
      const lastTab = () => {
        const last = descendants.lastEnabled()
        if (last) {
          last.node?.focus()
        }
      }

      const eventKey = event.key

      const keyMap: Record<string, KeyboardEventHandler> = {
        ArrowDown: nextTab,
        ArrowUp: prevTab,
        Home: firstTab,
        End: lastTab,
      }

      const action = keyMap[eventKey]

      if (action) {
        event.preventDefault()
        action(event)
      }
    },
    [descendants, focusIndex],
  )

  return { handleKeyDown }
}
