import styled, { css } from 'styled-components'
import { subHeadStrong, headline } from '@fiji/style'
import { slideUp } from './transition'

export const IconWrapper = styled.div`
  padding-left: 10px;
  padding-right: 6px;
`

export const VerticalDivider = styled.div`
  border-left: 2px solid ${({ theme }) => theme.colors.borderLight};
  padding-right: 10px;
  height: 40%;
  width: 1px;
`

export const SearchCriteriaContainer = styled.div`
  display: flex;
  flex: 1;
  padding-right: 15px;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
`

export const LocationSection = styled.div`
  display: flex;
  min-width: 0;
`

const LocationText = css<{ isHeader?: boolean }>`
  color: ${(p) => (p.isHeader ? p.theme.colors.bodyText : p.theme.colors.primary1)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  ${subHeadStrong};
`

export const PickUpText = styled.span`
  ${LocationText}
  flex-shrink: 0;
  max-width: calc(50% - 16px);
`

export const DropOffText = styled.span`
  ${LocationText};
`

export const ArrowIconWrapper = styled.span`
  padding: 0px 6px;
`

export const SearchLocationCard = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  border-radius: 10px;
  height: 61px;
  display: flex;
  align-items: center;
`

export const ArrivalFlightCard = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 48px;
  margin-top: 14px;
  padding-left: 12px;
`

export const SheetContent = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${(p) => p.theme.colors.background};
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.14);
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  ${slideUp};
`

export const MiniPillWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const MiniPill = styled.div`
  background: ${(p) => p.theme.colors.borderDark};
  border-radius: 50rem;
  height: 4px;
  width: 42px;
  margin-top: 11px;
  margin-bottom: 15px;
  flex-shrink: 0;
`

export const SheetContainer = styled.div`
  width: 100%;
  height: 50%;
  top: -1;
  bottom: 0;
  position: absolute;
  z-index: 22;
`

export const SearchTitleSection = styled.div`
  align-self: flex-start;
  margin-top: 30px;
`

export const TitleText = styled.div`
  float: left;
  ${headline};
`

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  flex-grow: 1;
`

export const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  padding: 0px 16px;
`

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`

export const ReserveAirportPickupInfoModule = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin-top: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
`

export const ViewMoreRideContainer = styled.div`
  background-color: white;
  margin-top: -8px;
  margin-bottom: 24px;
  border-radius: 14px;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
`

export const HeaderLocation = styled.div`
  display: flex;
  align-items: center;
`
