import { Link } from '@etta/ui/link'
import { Icon } from '@etta/ui/icon'
import { SegmentStateLabel } from '../../segment-state-label'
import type { Props } from '../types'
import { Inline, Label } from './header-mobile-styled'

export function HeaderMobile({
  segmentState,
  iconName,
  title,
  onEdit,
  areAllPreferencesUnavailable,
  link,
}: Props) {
  return (
    <>
      {segmentState && <SegmentStateLabel segmentState={segmentState} />}
      <Inline paddingHorizontal={16} paddingBottom={10}>
        <Icon name={iconName} color="bodyText1" />
        <Label>{title}</Label>
        {!areAllPreferencesUnavailable && (
          <Link size="small" onClick={onEdit}>
            {link}
          </Link>
        )}
      </Inline>
    </>
  )
}
