import { Inject, Service } from '@etta/di'
import { DelegateAdapter } from '../../infra/delegate.adapter'

@Service()
export class DelegateTokenService {
  constructor(
    @Inject()
    private readonly delegateAdapter: DelegateAdapter,
  ) {}

  async updateDelegateTokenCookie(id: number) {
    const result = await this.delegateAdapter.updateDelegatedIdCookie({
      id: isNaN(id) ? undefined : id,
    })

    return result.isOk()
  }
}
