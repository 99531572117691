import type { AddFlightSeatAssignmentInput } from '@fiji/graphql/types'

type Args = {
  addSeats: AddFlightSeatAssignmentInput[]
  removeSeats: AddFlightSeatAssignmentInput[]
}

export function filterRemoveSeats({ addSeats, removeSeats }: Args) {
  return removeSeats.filter((seat) => {
    return !!addSeats.find(({ segmentId }) => seat.segmentId === segmentId)
  })
}
