export default function CarRentalIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 16.7705H9.8125L10.9375 17.8525L13.9375 12.4426C14.3125 11.7213 15.25 11 16.375 11H28C29.125 11 30.0625 11.7213 30.625 12.623L33.25 17.8525L34.1875 16.7705H37C37 18.2131 35.875 19.2951 34.375 19.2951H34L35.6875 22.541V30.2951V33H32.125L31 30.2951L13 30.4754L11.875 33H8.3125V30.4754V22.541L10 19.2951H9.625C8.125 19.2951 7 18.2131 7 16.7705ZM28.5625 13.8852C28.1875 13.3443 27.625 12.8033 26.875 12.8033H17.3125C16.75 12.8033 16.1875 13.1639 15.8125 13.7049L13 19.1148H31L28.5625 13.8852ZM29.3125 25.2459L31.75 24.3443C33.0625 23.9836 33.25 23.082 32.6875 22.1803L27.625 23.9836L29.3125 25.2459ZM12.4375 24.1639L15.0625 25.2459L16.9375 24.1639L11.5 22C10.75 22.9016 10.9375 23.623 12.4375 24.1639Z"
        fill="currentColor"
      />
    </svg>
  )
}
