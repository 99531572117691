import { SegmentType } from '@fiji/graphql/types'
import { ActionButtons } from '@etta/components/action-buttons/action-buttons'
import { HotelDetailsModal } from '@etta/components/hotel-details-modal/hotel-details-modal'
import { Block } from '@etta/ui/block'
import { PriceDetails } from '@etta/components/price-details'
import type { LayoutProps } from '../types'

export function HotelDetailsModalMobile({
  hotelDetails,
  totalCostTitle,
  priceDetailsList,
  isVisible,
  isError,
  isCancelable,
  onRetry,
  onClose,
  onChange,
  onCancel,
  hotelEmission,
}: LayoutProps) {
  return (
    <HotelDetailsModal
      hotelEmission={hotelEmission}
      isVisible={isVisible}
      areHotelReviewsDisplayed={false}
      isHotelDescriptionDisplayed={false}
      onClose={onClose}
      hotelDetails={hotelDetails}
      isError={isError}
      onRetry={onRetry}>
      {totalCostTitle && (
        <Block marginHorizontal={16} marginVertical={16}>
          <PriceDetails priceDetailsList={priceDetailsList} totalCost={totalCostTitle} />
        </Block>
      )}
      <Block marginTop={8} marginBottom={34}>
        <ActionButtons
          type={SegmentType.Hotel}
          isCancel={isCancelable}
          onChangeClick={onChange}
          onCancelClick={onCancel}
        />
      </Block>
    </HotelDetailsModal>
  )
}
