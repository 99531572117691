export default function PaidCarryOnBagIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 8V4H13V8H15V12H13C10.7909 12 9 13.7909 9 16V32C9 34.2091 10.7909 36 13 36V38C13 39.1046 13.8954 40 15 40C16.1046 40 17 39.1046 17 38V36H21V38C21 39.1046 21.8954 40 23 40C24.1046 40 25 39.1046 25 38V36C27.2091 36 29 34.2091 29 32V16C29 13.7909 27.2091 12 25 12H23V8H25ZM17 8H21V12H17V8ZM13.5 14.5C12.3954 14.5 11.5 15.3954 11.5 16.5V31.5C11.5 32.6046 12.3954 33.5 13.5 33.5H24.5C25.6046 33.5 26.5 32.6046 26.5 31.5V16.5C26.5 15.3954 25.6046 14.5 24.5 14.5H13.5Z"
        fill="currentColor"
      />
      <path
        d="M28 10C31.866 10 35 13.134 35 17C35 20.866 31.866 24 28 24C24.134 24 21 20.866 21 17C21 13.134 24.134 10 28 10Z"
        fill="#F44901"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17C20 12.5817 23.5817 9 28 9C32.4183 9 36 12.5817 36 17C36 21.4183 32.4183 25 28 25C23.5817 25 20 21.4183 20 17ZM28 11C24.6863 11 22 13.6863 22 17C22 20.3137 24.6863 23 28 23C31.3137 23 34 20.3137 34 17C34 13.6863 31.3137 11 28 11Z"
        fill="white"
      />
    </svg>
  )
}
