import type { ReactNode } from 'react'
import type { SkeletonVariantKey } from './variants'
import { variants } from './variants'
import type { SkeletonAlign, SkeletonSkin } from './types'
import { ContentLoaderWrapper, Skin } from './skeleton-styled'

export type SkeletonProps = {
  skin?: SkeletonSkin
  variant?: SkeletonVariantKey
  align?: SkeletonAlign
  width: number | string
  height: number | string
  children: ReactNode | ReactNode[]
}

export function Skeleton({
  skin,
  variant = 'primary1',
  align,
  width,
  height,
  children,
}: SkeletonProps) {
  const variantProps = variants[variant]

  return (
    <Skin skin={skin} align={align} height={height} width={width}>
      <ContentLoaderWrapper
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        {...variantProps}>
        {children}
      </ContentLoaderWrapper>
    </Skin>
  )
}
