import styled, { css } from 'styled-components'

const whiteBackground = css`
  background-color: ${(p) => p.theme.colors.white};
`

const blackBackground = css`
  background-color: ${(p) => p.theme.colors.mainText};
  z-index: 10;
`

const withIcon = css`
  padding: 2px 2px 2px 5px;
`

const withoutIcon = css`
  padding: 2px 6px;
`

export const MarkerContainer = styled.div<{ isActive: boolean; isIcon: boolean }>`
  display: inline-block;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  z-index: 2;

  ${(p) => (p.isActive ? blackBackground : whiteBackground)}

  ${(p) => (p.isIcon ? withIcon : withoutIcon)}
`

export const Container = styled.div`
  white-space: nowrap;
  display: flex;
`

export const IconWrapper = styled.div`
  margin-left: 3px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 1px;
    background-color: ${(p) => p.theme.colors.borderDark};
    top: 50%;
    transform: translateY(-50%);
  }
`
