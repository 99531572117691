import type { ReviewTripValueObject, TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { SegmentType } from '@etta/core/enums'
import { CarRentalDomainMapper } from './car-rental-domain.mapper'
import { HotelDomainMapper } from './hotel-domain.mapper'
import { CheckDomain } from './check-domain'
import { FlightDomainMapper } from './flight-domain.mapper'
import { TripCostMapper } from './trip-cost.mapper'
import type { CartResponse, CartCost, OOPInfoResponse } from './types'

type CartArgs = {
  cart: CartResponse
  oopInfo: OOPInfoResponse
  costSummary: CartCost
}

export class CartMapper {
  static toTripValueObject({ cart, costSummary, oopInfo }: CartArgs): ReviewTripValueObject {
    const { intents, cartName, isCartNameEditable, cartId, cartSummary } = cart
    const tripCost = TripCostMapper.toTripCost(costSummary)

    const { endDate, startDate } = cartSummary

    const segments = intents.reduce(
      (acc: TripSegmentValueObject[], { domain, domainObject, id, state }) => {
        const isInPolicy = !oopInfo.intentComplianceCodes.find((item) => item.intentId === id)
        if (CheckDomain.isCar(domain, domainObject)) {
          const carSegment = CarRentalDomainMapper.toCarRentalSegmentEntity({
            domainObject,
            intentId: id,
            state,
            isInPolicy,
          })
          acc.push(carSegment)
        }
        if (CheckDomain.isHotel(domain, domainObject)) {
          const hotelSegment = HotelDomainMapper.toHotelSegmentEntity({
            domainObject,
            intentId: id,
            state,
            isInPolicy,
          })
          acc.push(hotelSegment)
        }
        if (CheckDomain.isFlight(domain, domainObject)) {
          const flightSegments = domainObject.flightLegs.map((flightLeg) =>
            FlightDomainMapper.toFlightSegmentEntity({
              flightLeg,
              intentId: id,
              state,
              isInPolicy,
            }),
          )
          acc.push(...flightSegments)
        }
        return acc
      },
      [],
    )

    return {
      expiredAt: '',
      isMultiCity: false,
      isMultiDestination: false,
      // rename key to tripId
      itineraryId: cartId,
      itineraryItemResponse: [],
      priceChanges: {},
      availableSegments: {
        canAddFlight: true,
        canAddCarRental: true,
        canAddHotel: true,
      },

      flightId: segments.find((segment) => segment.type === SegmentType.Flight)?.uuid,
      segments,
      tripCost,
      tripInfo: {
        endDate: endDate,
        imageUrl: '',
        isNameEditable: isCartNameEditable,
        name: cartName,
        startDate: startDate,
      },
      tripStatus: {
        areExtraSegmentsAllowed: false,
        areSegmentsChangeable: true,
        areSegmentsCustomizable: false,
        areSegmentsRemovable: true,
        areTicketOptionsAllowed: false,
        isChangeTripAllowed: false,
        isHoldTripAllowed: false,
      },
      isTripAligned: true,
    }
  }
}
