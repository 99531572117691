import { CalculateEmissionsBatchResultEquivalenceType } from '@fiji/graphql/types'
import PlantSVG from './assets/plant.svg?url'
import PlasticBagSVG from './assets/plastic-bag.svg?url'
import SmartphoneSVG from './assets/smartphone.svg?url'

type Props = {
  type: CalculateEmissionsBatchResultEquivalenceType
}

export function EcoCheckEquivalenceCardImage({ type }: Props) {
  switch (type) {
    case CalculateEmissionsBatchResultEquivalenceType.Seedling:
      return <img src={PlantSVG} alt="plant" />
    case CalculateEmissionsBatchResultEquivalenceType.PlasticBag:
      return <img src={PlasticBagSVG} alt="plastic bag" />
    case CalculateEmissionsBatchResultEquivalenceType.SmartphoneCharge:
      return <img src={SmartphoneSVG} alt="smartphone charge" />
  }
}
