import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { SegmentType } from '@fiji/graphql/types'
import { Header } from '@etta/ui/header'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { formatRate } from '@fiji/utils/money/format-rate'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import { usePostBookingContext } from '@etta/modules/post-booking'
import type { UnitVariant } from '../types'
import { SegmentSection } from './segment-section'
import { Container, HeaderContentWrapper, FooterUnit } from './cost-summary-styled'
import { FlightSegments } from './flight'
import { CarRentalCostLabelAndName } from './car-rental'
import { HotelCostLabelAndName } from './hotel'
import { RailSegments } from './rail/rail-segments'

type Props = {
  onClose: () => void
}

const i18nBase = 'TripCostSummary.'

export const CostSummary = observer(function CostSummary({ onClose }: Props) {
  const { t } = useTranslation()

  const { postBookingTripStore } = usePostBookingContext()
  const {
    appliedRailCard,
    flightSegments,
    hotelSegments,
    carSegments,
    trip,
    railSegments,
  } = postBookingTripStore

  const { costSummary, type } = trip

  const hotelPayStatus: UnitVariant =
    type === TripStatus.Completed || costSummary?.hotel?.isPaid ? 'paid' : 'payable-later'
  const carRentalPayStatus: UnitVariant =
    type === TripStatus.Completed || costSummary?.carRental?.isPaid ? 'paid' : 'payable-later'

  const total = formatRate(costSummary?.totalCost, {
    morpheme: 'postfix',
  }).mainCost

  return (
    <Modal isVisible={true} handleModalVisibility={onClose}>
      <Header withBorderBottom>
        <HeaderContentWrapper>
          <Header.Title title={t(i18nBase + 'Title')} color="mainText" align="left" />
          <Header.CloseButton color="mainText" onClick={onClose} />
        </HeaderContentWrapper>
      </Header>
      <Modal.Body>
        <Container>
          <FlightSegments segments={flightSegments} />
          {hotelSegments.map(
            (hotel) =>
              hotel.totalCost && (
                <SegmentSection
                  key={SegmentType.Hotel + hotel.id}
                  variant={hotelPayStatus}
                  segmentType={SegmentType.Hotel}
                  segmentSpecificSlot={<HotelCostLabelAndName hotelFragment={hotel} />}
                  taxesCostMoney={hotel.feesAndTaxes}
                  totalAmountMoney={hotel.totalCost}
                />
              ),
          )}
          {carSegments.map(
            (car) =>
              car.totalCost && (
                <SegmentSection
                  key={SegmentType.CarRental + car.carId}
                  variant={carRentalPayStatus}
                  segmentType={SegmentType.CarRental}
                  segmentSpecificSlot={<CarRentalCostLabelAndName carRentalFragment={car} />}
                  taxesCost={car.taxesAndFees}
                  totalAmount={car.totalCost}
                />
              ),
          )}
          <RailSegments
            segments={railSegments}
            appliedRailCard={appliedRailCard}
            costSummary={costSummary?.train}
          />
        </Container>
        <FooterUnit>
          <Text variant="subHeadStrong" color="mainText">
            {t(i18nBase + 'TripTotalText')}
          </Text>
          <Text variant="title3" color="mainText">
            {total}
          </Text>
        </FooterUnit>
      </Modal.Body>
    </Modal>
  )
})
