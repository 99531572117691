import { useMemo, useState } from 'react'
import type { TabName } from './types'

type Args<T extends TabName> = {
  activeTab: T
  onChangeTab: (newTabName: T) => void
}

export function useTabs<T extends TabName>({ onChangeTab, activeTab }: Args<T>) {
  const [tabsList, setTabsList] = useState<TabName[]>([])
  const [tabBasis, setTabBasis] = useState<number | undefined>()

  const currentTabIndex = useMemo(() => {
    return tabsList.indexOf(activeTab)
  }, [activeTab, tabsList])

  const context = useMemo(() => {
    return {
      tabBasis,
      changeTabBasis: (value?: number) => {
        setTabBasis(value)
      },
      changeActiveTab: (tabName: T) => {
        onChangeTab(tabName)
      },
      activeTab,
      addTab: (tabName: T) => {
        setTabsList((prevState) => [...prevState, tabName])
      },
      removeTab: (tabName: T) => {
        setTabsList((prevState) => {
          const index = prevState.indexOf(tabName)
          const newState = [...prevState]
          newState.splice(index, 1)
          return newState
        })
      },
      totalTabsCount: tabsList.length,
      currentTabIndex,
    }
  }, [activeTab, currentTabIndex, onChangeTab, tabBasis, tabsList.length])

  return {
    context,
  }
}
