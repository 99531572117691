import styled, { keyframes } from 'styled-components'

const BANNER_HEIGHT = 37

const slideAnimation = keyframes`
  from {
    margin-top: -${BANNER_HEIGHT}px;
  };
  to {
    margin-top: 0;
  };
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${BANNER_HEIGHT}px;
  padding: 0 25px;
  animation: ${slideAnimation} 0.6s ease-in-out;
  background: ${({ theme }) => theme.colors.plum};
`
