import { useCallback } from 'react'
import { ScreenType, useScreenType } from '@fiji/modes'
import { MediaLayout } from '@etta/ui/media-layout'
import type { CalendarLayoutProps } from '@etta/ui/date-picker/types'
import { DayContent, MonthText } from '../calendar-renders'
import { CalendarMobile } from './mobile/calendar-mobile'
import { CalendarDesktop } from './desktop/calendar-desktop'

export function CalendarLayout(props: CalendarLayoutProps) {
  const type = useScreenType()
  const isMobile = type === ScreenType.Mobile

  const renderDayContents = useCallback((date: moment.Moment) => <DayContent date={date} />, [])
  const renderMonthText = useCallback(
    (day: moment.Moment) => <MonthText day={day} isMobile={isMobile} />,
    [isMobile],
  )

  const modifiedProps = {
    ...props,
    renderDayContents: renderDayContents,
    renderMonthText: renderMonthText,
  }

  return (
    <MediaLayout
      forceScreenType={props.forceScreenType}
      mobileSlot={<CalendarMobile {...modifiedProps} />}
      desktopSlot={<CalendarDesktop {...modifiedProps} />}
    />
  )
}
