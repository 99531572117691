import { Action, Inject } from '@etta/di'
import { ROUTES } from '@fiji/routes'
import { RailLegType } from '@fiji/enums'
import { RailService } from '../services/rail.service'
import { RailStore } from '../stores/rail.store'
import { RailQueryParamsService } from '../services/rail-query-params.service'

@Action()
export class RailSearchActions {
  constructor(
    @Inject()
    private readonly railService: RailService,
    @Inject()
    private readonly railStore: RailStore,
    @Inject()
    private readonly railQueryParamsService: RailQueryParamsService,
  ) {}

  async onEarlierTrainClick(railLegType: RailLegType) {
    if (railLegType === RailLegType.Return) {
      this.railStore.setEditTimeModalOpen()
      return
    }

    const nextPage = this.railStore.currentPage - 1

    await this.railService.getRailSearchResults(railLegType, nextPage)
  }

  async onLaterTrainClick(railLegType: RailLegType) {
    if (railLegType === RailLegType.Return) {
      this.railStore.setEditTimeModalOpen()
      return
    }

    const nextPage = this.railStore.currentPage + 1

    await this.railService.getRailSearchResults(railLegType, nextPage)
  }

  handleConfirmEditTime(shouldRedirectToSearch: boolean = false) {
    this.railStore.setEditTimeModalClose()
    if (shouldRedirectToSearch) {
      // FIXME: FRI-82 this will remove the itinerary and affect other segment
      this.railService.removeItinerary()
      this.railStore.dropStore()
      this.railQueryParamsService.navigateTo(ROUTES.rail.search, {
        filters: {},
      })
      return
    }
    this.railStore.setEditRailSearchModalOpen()
  }

  handleConfirmEditSearch() {
    this.railService.removeItinerary()
    this.railStore.setEditRailSearchModalClose()
  }

  handleEditTimeModalClose() {
    this.railStore.setEditTimeModalClose()
  }

  handleEditTimeModalOpen() {
    this.railStore.setEditTimeModalOpen()
  }

  handleEditRailSearchModalClose() {
    this.railStore.setEditRailSearchModalClose()
  }
}
