import type {
  CarRentalMembershipOptions,
  CarRentalSpecialRequestValue,
  CarRentalSpecialRequestOption,
  CarRentalMembershipValue,
} from '@etta/modules/booking/core/value-objects/checkout-info.value-object'
import type { PreferenceOptions } from '@etta/modules/booking/core/value-objects/purchase-info-details.value-object'
import type { LoyaltyProgram } from '@fiji/graphql/hooks'

export const mapCarMembershipOption = (
  carMembershipOption: CarRentalMembershipOptions,
): PreferenceOptions => {
  return {
    label: carMembershipOption.vendorName || '',
    value: carMembershipOption.vendorCode,
  }
}

export const mapCarSpecialRequestOption = (
  carSpecialRequestOption: CarRentalSpecialRequestOption,
): PreferenceOptions => {
  return {
    label: carSpecialRequestOption.name || '',
    value: carSpecialRequestOption.value,
  }
}

export const mapCarSpecialRequestValue = (
  carSpecialRequestValue: CarRentalSpecialRequestValue,
): PreferenceOptions => {
  return { label: carSpecialRequestValue.name || '', value: carSpecialRequestValue.value }
}

export const mapCarMembershipValue = (
  carMembershipValue: CarRentalMembershipValue,
): PreferenceOptions => {
  return {
    label: carMembershipValue.vendorName || '',
    value: carMembershipValue.vendorCode,
  }
}

export const toSpecialRequestValues = (
  codes: string[],
  options: CarRentalSpecialRequestOption[],
): CarRentalSpecialRequestValue[] => {
  return options.reduce<CarRentalSpecialRequestValue[]>((acc, option) => {
    if (codes.includes(option.value)) {
      return [...acc, { name: option.name, value: option.value, id: 'md_car_special_request' }]
    }
    return acc
  }, [])
}

export const toCarRentalMembership = (
  programs: LoyaltyProgram[],
  options: CarRentalMembershipOptions[],
): CarRentalMembershipValue[] => {
  return options.reduce<CarRentalMembershipValue[]>((acc, option) => {
    const program = programs.find((program) => program.vendorCode === option.vendorCode)
    if (program) {
      return [
        ...acc,
        {
          vendorCode: option.vendorCode,
          vendorName: option.vendorName,
          id: 'md_car_membership',
          number: program.number || '',
        },
      ]
    }
    return acc
  }, [])
}
