import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { TripType } from '@etta/core/enums/trip-type.enum'

type Params = {
  tripType: TripType
}

export function useTripListLayoutErrorDesktop({ tripType }: Params) {
  const { t } = useTranslation()

  const translations = useMemo(
    () => ({
      title: t('PostBooking.TripDetails.ErrorMessage'),
      errorText: t('Trips.ErrorDesktop.Text', { tripType: tripType.toLowerCase() }),
      retryButtonText: t('TravelFusionLinks.Error.Button'),
    }),
    [t, tripType],
  )

  return {
    translations,
  }
}
