import { Text } from '@etta/ui/text'
import { Container, Image, ImageWrapper, TitleWrapper } from './headline-styled'
import rideHail from './ride-hail.png'

type Props = {
  title: string
  subTitle: string
}

export function Headline({ title, subTitle }: Props) {
  return (
    <Container>
      <TitleWrapper>
        <div>
          <Text variant="title2" color="mainText2">
            {title}
          </Text>
        </div>
        <div>
          <Text variant="footnoteMedium" color="mainText2" textOverflow="ellipsis-two-line">
            {subTitle}
          </Text>
        </div>
      </TitleWrapper>
      <ImageWrapper>
        <Image src={rideHail} />
      </ImageWrapper>
    </Container>
  )
}
