import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { filterOutPolicy, FilterPolicyType } from '@fiji/utils/out-of-policy/filter-out-policy'
import { SegmentType } from '@fiji/graphql/hooks'
import { Modal } from '@etta/ui/modal'
import { Header } from '@etta/ui/header'
import { Button } from '@etta/ui/button'
import { TextAreaDescription } from '@etta/ui/textarea-description'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy'
import { Spinner } from '@etta/ui/spinner'
import { PolicyReasonHeading } from '../../policy-reason-heading'
import { PolicyReason } from '../../policy-reason'
import { FlightTitleName } from '../../flight-title-name'
import type { OutOfPolicyModalComponentProps } from '../../types'
import {
  Container,
  Footer,
  PolicyExplanation,
  PolicyExplanationTitle,
} from './out-of-policy-modal-mobile-styled'

const i18nBase = 'OutOfPolicyModal.'

export function OutOfPolicyModalMobile({
  isOpen,
  descriptions,
  onClose,
  travelPolicy,
  isPolicyExplanationRequired = false,
  hotelHeading,
  values,
  flights,
  hotels,
  carRentals,
  rails,
  reasons,
  onSaveOopValidation,
  onFieldChange,
  errors,
  isLoading,
  isHotelRequiredForOvernightTrip,
  isCarRentalRequiredViolation,
}: OutOfPolicyModalComponentProps) {
  const { t } = useTranslation()

  const { outOfPolicyActions, outOfPolicyStore } = useOutOfPolicyContext()

  // TODO: Remove after moving schema validation to new architecture
  useEffect(() => {
    outOfPolicyStore.oopSegmentsReason.airReason &&
      'flightReason' in values &&
      onFieldChange('flightReason')(
        outOfPolicyActions.getSegmentReasonId(reasons, SegmentType.Flight),
      )
    outOfPolicyStore.oopSegmentsReason.hotelReason &&
      'hotelReason' in values &&
      onFieldChange('hotelReason')(
        outOfPolicyActions.getSegmentReasonId(reasons, SegmentType.Hotel),
      )
    outOfPolicyStore.oopSegmentsReason.carrentalReason &&
      'carRentalReason' in values &&
      onFieldChange('carRentalReason')(
        outOfPolicyActions.getSegmentReasonId(reasons, SegmentType.CarRental),
      )
    outOfPolicyStore.oopSegmentsReason.railReason &&
      'railReason' in values &&
      onFieldChange('railReason')(outOfPolicyActions.getSegmentReasonId(reasons, SegmentType.Train))
    // Fixing infinity loop issue due to updating 'values'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfPolicyStore.oopSegmentsReason, reasons, outOfPolicyActions, onFieldChange])

  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <>
        <Header withBorderBottom leftSlot={<Header.CloseButton onClick={onClose} />}>
          <Header.Title title={t(i18nBase + 'Title')} align="left" />
        </Header>
        <Modal.Body>
          <Container>
            {reasons && (
              <>
                {flights.length > 0 && (
                  <PolicyReason
                    reasons={reasons}
                    segment={SegmentType.Flight}
                    dataTrackingId={SegmentType.Flight}
                    description={filterOutPolicy(
                      descriptions,
                      SegmentType.Flight,
                      FilterPolicyType.ByDescription,
                    )}
                    policyData={filterOutPolicy(
                      descriptions,
                      SegmentType.Flight,
                      FilterPolicyType.ByData,
                    )}
                    onChangeValue={onFieldChange('flightReason')}
                    value={values.flightReason}
                    heading={flights.map((flight) => {
                      if (!flight.baseRate) {
                        return null
                      }
                      const flightSegments = flight.segments
                      if (!flight.segments.length) {
                        return <></>
                      }

                      const origin = flightSegments[0].departure.airportCode
                      const destination =
                        flightSegments[flightSegments.length - 1].arrival.airportCode

                      const ariaLabel = t(i18nBase + 'FlightAriaLabel', { origin, destination })
                      return (
                        <PolicyReasonHeading
                          ariaLabel={ariaLabel}
                          rate={flight.totalCostRate}
                          type={SegmentType.Flight}
                          title={<FlightTitleName flight={flight} />}
                          key={flight.legId}
                        />
                      )
                    })}
                    error={errors.flightReason}
                  />
                )}
                {(hotels.length > 0 || isHotelRequiredForOvernightTrip) && (
                  <PolicyReason
                    reasons={reasons}
                    segment={SegmentType.Hotel}
                    dataTrackingId={SegmentType.Hotel}
                    description={filterOutPolicy(
                      descriptions,
                      SegmentType.Hotel,
                      FilterPolicyType.ByDescription,
                    )}
                    policyData={filterOutPolicy(
                      descriptions,
                      SegmentType.Hotel,
                      FilterPolicyType.ByData,
                    )}
                    onChangeValue={onFieldChange('hotelReason')}
                    value={values.hotelReason}
                    heading={
                      hotelHeading ||
                      hotels.map((hotel) => (
                        <PolicyReasonHeading
                          ariaLabel={hotel.name}
                          rate={hotel.totalCostRate}
                          type={SegmentType.Hotel}
                          title={hotel.name}
                          key={hotel.id}
                        />
                      ))
                    }
                    error={errors.hotelReason}
                  />
                )}
                {(carRentals.length > 0 || isCarRentalRequiredViolation) && (
                  <PolicyReason
                    reasons={reasons}
                    segment={SegmentType.CarRental}
                    dataTrackingId={SegmentType.CarRental}
                    description={filterOutPolicy(
                      descriptions,
                      SegmentType.CarRental,
                      FilterPolicyType.ByDescription,
                    )}
                    policyData={filterOutPolicy(
                      descriptions,
                      SegmentType.CarRental,
                      FilterPolicyType.ByData,
                    )}
                    onChangeValue={onFieldChange('carRentalReason')}
                    value={values.carRentalReason}
                    heading={carRentals.map((carRental) => (
                      <PolicyReasonHeading
                        rate={carRental.rate}
                        type={SegmentType.CarRental}
                        title={carRental.name}
                        key={carRental.carId}
                        ariaLabel={carRental.name}
                      />
                    ))}
                    error={errors.carRentalReason}
                  />
                )}
                {rails.length > 0 && (
                  <PolicyReason
                    reasons={reasons}
                    segment={SegmentType.Train}
                    dataTrackingId={SegmentType.Train}
                    description={filterOutPolicy(
                      descriptions,
                      SegmentType.Train,
                      FilterPolicyType.ByDescription,
                    )}
                    policyData={filterOutPolicy(
                      descriptions,
                      SegmentType.Train,
                      FilterPolicyType.ByData,
                    )}
                    onChangeValue={onFieldChange('railReason')}
                    value={values.railReason}
                    heading={rails.map((rail, index) => (
                      <PolicyReasonHeading
                        rate={rail.rate}
                        type={SegmentType.Train}
                        title={rail.name}
                        key={index}
                        ariaLabel={rail.name}
                      />
                    ))}
                    error={errors.railReason}
                  />
                )}
              </>
            )}
            {isPolicyExplanationRequired && (
              <PolicyExplanation>
                <PolicyExplanationTitle>
                  {travelPolicy?.purposeLabel || t(i18nBase + 'PolicyExplanationSubTitle')}
                </PolicyExplanationTitle>
                <div>{t(i18nBase + 'ExplanationDescription')}</div>
                <TextAreaDescription
                  ariaLabel={t(i18nBase + 'TextAreaAriaLabel')}
                  error={errors.policyExplanation}
                  maxChar={250}
                  value={values.policyExplanation}
                  onChange={onFieldChange('policyExplanation')}
                  placeholder={t(i18nBase + 'PolicyExplanationPlaceholder')}
                />
              </PolicyExplanation>
            )}
          </Container>
        </Modal.Body>
        <Footer>
          <Button
            disabled={isLoading}
            aria-label={t(i18nBase + 'SaveButtonAriaLabel', { button: t(i18nBase + 'SaveButton') })}
            onClick={onSaveOopValidation}
            fullWidth>
            {isLoading && <Spinner color="mainText2" type="spinout" size="medium" />}
            {!isLoading && t(i18nBase + 'SaveButton')}
          </Button>
        </Footer>
      </>
    </Modal>
  )
}
