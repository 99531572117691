import type { CalculateTripEmissionsResult } from '@fiji/graphql/types'
import type { TripSegmentValueObject } from '@etta/modules/review-trip/core'
import { RtpEcoCheckSummary } from '@etta/components/rtp-eco-check-summary/rtp-eco-check-summary'
import { EcoCheckSummaryBlock } from './eco-check-sumary-styled'
import { EcoCheckSummarySkeleton } from './eco-check-sumary-skeleton'

type Props = {
  loading: boolean
  segments: TripSegmentValueObject[]
  tripEmissionsResult?: CalculateTripEmissionsResult
}

export function EcoCheckSummary({ loading, segments, tripEmissionsResult }: Props) {
  if (loading) {
    return (
      <EcoCheckSummaryBlock>
        <EcoCheckSummarySkeleton />
      </EcoCheckSummaryBlock>
    )
  }

  if (tripEmissionsResult && tripEmissionsResult.errors?.length === 0 && segments) {
    return (
      <EcoCheckSummaryBlock>
        <RtpEcoCheckSummary segments={segments} tripEmissionsResult={tripEmissionsResult} />
      </EcoCheckSummaryBlock>
    )
  }

  return null
}
