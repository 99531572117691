import styled from 'styled-components'
import { Button } from '@etta/ui/button'

export const TravelPolicyButton = styled(Button)<{ isDelegateMode?: boolean }>`
  padding: 0;
  ${({ theme, isDelegateMode }) =>
    isDelegateMode &&
    `
      padding-left: 5px;
      &:before {
        content: '·';
        padding-right: 5px;
        color: ${theme.colors.bodyText};
      }
    `}
`
