import { useCallback } from 'react'
import { GOOGLE_MAPS_URL } from '@fiji/constants'
import type { Address } from '@fiji/graphql/types'

export function useHotelAddress(address?: Address | null) {
  const addressRow = [
    address?.street1 || '',
    address?.street2 || '',
    address?.city || '',
    address?.stateCode || '',
    address?.postalCode || '',
    address?.countryCode || '',
  ]
    .filter((item) => item.trim())
    .join(', ')
  const googleUrl = `${GOOGLE_MAPS_URL}${address?.geocode?.lat},${address?.geocode?.long}`
  const handleAddressClick = useCallback(() => {
    if (!address?.geocode) {
      return
    }
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(googleUrl)
  }, [address?.geocode, googleUrl])

  return {
    addressRow,
    handleAddressClick,
    googleUrl,
  }
}
