import { Icon } from '@etta/ui/icon'
import { getIconNameBySegment } from '@fiji/utils/get-icon-name-by-segment'
import type { TripCartListItemProps } from '../types'
import {
  ItemContainer,
  IconBlock,
  InfoBlock,
  Title,
  InfoLabel,
  TitleLabel,
  PriceLabel,
} from './list-item-styled'

export function ListItem({ type, infoLabel, price, titleLabel }: TripCartListItemProps) {
  const iconName = getIconNameBySegment(type)

  return (
    <ItemContainer>
      <IconBlock>
        <Icon color="mainText" size="normal" name={iconName} />
      </IconBlock>
      <InfoBlock>
        <Title>
          <TitleLabel variant="subHeadStrong">{titleLabel}</TitleLabel>
          <PriceLabel variant="subHeadStrong">{price}</PriceLabel>
        </Title>
        <InfoLabel color="bodyText" noWrap textOverflow="ellipsis" variant="footnoteMedium">
          {infoLabel}
        </InfoLabel>
      </InfoBlock>
    </ItemContainer>
  )
}
