import styled, { css } from 'styled-components'

const horizontalStyles = css`
  height: 110px;
`
const verticalStyles = css`
  height: 380px;
`

const loadedStyles = css`
  outline: 2px solid ${(p) => p.theme.colors.bodyText1};
  outline-offset: -2px;
`

export const Image = styled.img<{ orientation: 'horizontal' | 'vertical'; isLoaded: boolean }>`
  border-radius: 14px;
  width: 100%;
  ${(p) => (p.isLoaded ? loadedStyles : '')}
  ${(p) => (p.orientation === 'horizontal' ? horizontalStyles : verticalStyles)}
`
