import { Text } from '@etta/ui/text'
import { ModalHeaderContentWrapper } from './trip-hotel-content-desktop-styled'

type Props = {
  name: string
  dateText: string
}

export function ModalHeaderContent({ name, dateText }: Props) {
  return (
    <ModalHeaderContentWrapper aria-label={`${name}, ${dateText}`}>
      <Text variant="title3">{name}</Text>
      <Text variant="footnoteMedium">{dateText}</Text>
    </ModalHeaderContentWrapper>
  )
}
