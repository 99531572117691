import { Fragment } from 'react'
import { Button } from '@etta/ui/button'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { SafetyCheckTitle } from '@etta/components/safety-check-title/safety-check-title'
import type { CarRentalSafetyCheckContentProps } from './types'
import { Headline, Badge, ButtonWrapper } from './car-rental-safety-check-styled'

export function CarRentalSafetyCheckContent({
  title,
  badgeUrl,
  description,
  url,
}: CarRentalSafetyCheckContentProps) {
  return (
    <>
      <Modal.Title withBorder>
        <SafetyCheckTitle size="medium" />
      </Modal.Title>
      <Modal.Body padding="normal">
        <Headline>
          <Text variant="title2" color="mainText">
            {title}
          </Text>
          {badgeUrl && <Badge src={badgeUrl} />}
        </Headline>
        {description.map((item, index, array) => (
          <Fragment key={index}>
            <Text variant="body" color="bodyText">
              {item}
            </Text>
            {index !== array.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer padding="normal" withBorder>
        <ButtonWrapper href={url}>
          <Button btnType="primary" fullWidth>
            Learn more
          </Button>
        </ButtonWrapper>
      </Modal.Footer>
    </>
  )
}
