import { createErrorClass } from '@etta/core/errors'

export namespace OOPErrors {
  export const UnexpectedGetOOPConfigurationError = createErrorClass(
    'UnexpectedGetOOPConfigurationError',
  )
  export const GetOOPConfigurationError = createErrorClass('GetOOPConfigurationError')
  export const SendOopCodesError = createErrorClass('Error sending OOP codes')
  export const UnexpectedSendOopCodesError = createErrorClass('Unexpected error sending OOP codes')
}

export type OOPErrorsInstances = InstanceType<typeof OOPErrors[keyof typeof OOPErrors]>
