import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { Modal } from '@etta/ui/modal'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { useCheckoutContextSelector } from '@etta/screens/checkout-context'
import type { Props } from '../../types'
import {
  DescriptionSecondPartWrapper,
  LinkWrapper,
} from '../desktop/traveler-acknowlege-confirmation-modal-desktop-styled'

const i18Base = 'TravelerAcknowledgeModal.'
export function TravelerAcknowledgeConfirmationMobile({ isOpen, handleClose, type }: Props) {
  const { t } = useTranslation()

  const { onSubmit } = useCheckoutContextSelector((state) => state.paymentSummary)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)

  const handleContinueClick = async () => {
    if (confirmButtonRef.current) {
      confirmButtonRef.current.disabled = true
      handleClose()
      await onSubmit(type)
      confirmButtonRef.current.disabled = false
    }
  }

  return (
    <Modal isVisible={isOpen} handleModalVisibility={handleClose}>
      <Modal.Title aria-label={t(i18Base + 'Title')}>{t(i18Base + 'Title')}</Modal.Title>
      <Modal.Body padding="normal">
        <Block>
          <Text variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'DescriptionFirstPart')}
          </Text>
          <DescriptionSecondPartWrapper variant={'subHeadMedium'} color={'bodyText'}>
            {t(i18Base + 'DescriptionSecondPart')}
            <LinkWrapper
              href="https://www.tsa.gov/travel/security-screening/whatcanibring/all"
              target="_blank">
              {t(i18Base + 'Link')}
            </LinkWrapper>
          </DescriptionSecondPartWrapper>
        </Block>
      </Modal.Body>
      <Modal.Footer padding="normal" withBorder>
        <Button
          ref={confirmButtonRef}
          fullWidth
          variant={'solid'}
          onClick={handleContinueClick}
          data-tracking-id="continue-travel-acknowledgment-modal-button"
          data-test-id="continue-travel-acknowledgment-modal-button">
          {t(i18Base + 'ContinueButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
