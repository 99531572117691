import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { updateFlightId, updateCarRentalId, updateHotelId } from '@fiji/store/segment-ids'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import { useAppDispatch } from '@fiji/store'
import { ROUTES } from '@fiji/routes'
import { useOutOfPolicyContext } from '@etta/modules/review-trip/interface/out-of-policy'

type Args = {
  canOpen: boolean
  bookingId?: string
  newLocation: string
  canOpenPostBookingTripInsteadOfExplore?: boolean
}

export function useOpenNewLocation({
  canOpen,
  bookingId,
  newLocation,
  canOpenPostBookingTripInsteadOfExplore = true,
}: Args) {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { outOfPolicyActions } = useOutOfPolicyContext()
  const { handleDropOopState } = outOfPolicyActions

  const handleOpenNewLocation = () => {
    dispatch(updateFlightId({ flightId: null }))
    dispatch(updateCarRentalId({ carRentalId: null }))
    dispatch(updateHotelId({ hotelId: null }))
    dispatch(updateTripReviewStatus('none'))
    dispatch(updatePostPurchaseEntrance(null))
    handleDropOopState()

    if (newLocation.startsWith(ROUTES.explore) && canOpenPostBookingTripInsteadOfExplore) {
      history.push(
        bookingId
          ? ROUTES.postBooking.trip({ id: bookingId, isForceRefetch: true })
          : ROUTES.explore,
      )

      return
    }

    history.push(newLocation)
  }

  useEffect(() => {
    if (!canOpen) {
      return
    }
    handleOpenNewLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canOpen])
}
