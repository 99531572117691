export default function NoCheckedBagAllowanceIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.6829 4.21379C34.4452 4.6539 34.7064 5.62862 34.2663 6.3909L14.7923 40.1208C14.3522 40.883 13.3775 41.1442 12.6152 40.7041C11.8529 40.264 11.5918 39.2893 12.0319 38.527L31.5058 4.79715C31.9459 4.03487 32.9207 3.77368 33.6829 4.21379ZM26.25 5.69923C26.5845 5.69923 26.9009 5.77652 27.1824 5.91419L26.0779 7.82423H17.75V12.0742H23.6235L22.3974 14.1992H15.625V25.9271L11.375 33.2881V14.1992H8.1875C7.6659 14.1992 7.23208 14.5751 7.14212 15.0707L7.125 15.2617V32.2617C7.125 32.7833 7.50086 33.2171 7.99651 33.3071L8.1875 33.3242H11.3538L10.1276 35.4492H8.1875C6.42709 35.4492 5 34.0221 5 32.2617V15.2617C5 13.5013 6.42709 12.0742 8.1875 12.0742H15.625V7.82423C15.625 6.65063 16.5764 5.69923 17.75 5.69923H26.25ZM35.8125 12.0742C37.5729 12.0742 39 13.5013 39 15.2617V32.2617C39 34.0221 37.5729 35.4492 35.8125 35.4492H21.1691L22.3953 33.3242H28.375V22.967L32.625 15.606V33.3242H35.8125C36.3341 33.3242 36.7679 32.9484 36.8579 32.4527L36.875 32.2617V15.2617C36.875 14.7401 36.4991 14.3063 36.0035 14.2164L35.8125 14.1992H33.4368L34.665 12.0742H35.8125Z"
        fill="currentColor"
      />
    </svg>
  )
}
