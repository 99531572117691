import styled, { css } from 'styled-components'
import { KeyboardNavigationStyle } from '@fiji/style'
import type { TextVariant, TextOverflow, TextColor, TextTransform } from './types'
import { textVariantToStyleMap } from './text-variant-to-style-map'

export type TextProps = {
  variant: TextVariant
  color?: TextColor
  textTransform?: TextTransform
  textOverflow?: TextOverflow
  noWrap?: boolean
  align?: 'left' | 'center' | 'right'
  isBlock?: boolean
  cursor?: 'pointer' | 'auto'
  isBlurred?: boolean
  preLine?: boolean
  isInline?: boolean
}

export const Text = styled.span<TextProps>`
  ${({
    theme,
    variant,
    isBlock,
    color,
    noWrap,
    textTransform,
    textOverflow,
    align = 'left',
    cursor,
    isBlurred,
    preLine = false,
    isInline = false,
  }) => css`
    margin: 0;
    ${textVariantToStyleMap[variant]};
    display: ${isInline ? 'inline' : isBlock ? 'block' : 'inline-block'};
    color: ${color ? theme.colors[color] : 'inherit'};
    text-align: ${align};
    ${isBlurred && 'filter: blur(3px); user-select: none;'}
    ${cursor &&
    css`
      cursor: ${cursor};
    `}
    ${noWrap &&
    css`
      white-space: nowrap;
    `};
    ${textTransform &&
    css`
      text-transform: ${textTransform};
    `};
    ${preLine &&
    css`
      white-space: pre-line;
    `};

    ${() => {
      switch (textOverflow) {
        case 'ellipsis':
          return css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
          `
        case 'clip':
          return css`
            text-overflow: clip;
            white-space: nowrap;
            overflow: hidden;
          `
        case 'ellipsis-two-line':
          return css`
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          `
        default:
          return ''
      }
    }};
  `};

  ${KeyboardNavigationStyle}
`
