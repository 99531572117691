import { Icon } from '@etta/ui/icon'
import { WarningCodesText } from '@etta/components/warning-codes-text'
import { Warning, WarningIcon, WarningText } from './warning-codes-styled'

type Props = {
  warningCodes: string[]
}

export function WarningCodes({ warningCodes }: Props) {
  return (
    <Warning>
      <WarningIcon>
        <Icon color="warning" name="warningFilledPWA" />
      </WarningIcon>
      <WarningText>
        <WarningCodesText warningCodes={warningCodes} />
      </WarningText>
    </Warning>
  )
}
