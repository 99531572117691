import { SpecialRequestItem } from '../../../special-request-item'
import type { LayoutProps } from '../../types'
import { Container } from './special-request-options-desktop-styled'

export function SpecialRequestOptionsDesktop({ values, options, onChange }: LayoutProps) {
  return (
    <Container>
      <SpecialRequestItem values={values} options={options} onChange={onChange} />
    </Container>
  )
}
