import styled from 'styled-components'
import { tabs } from '@fiji/style'
import { EmissionsDeviationLevel } from '@fiji/enums'

export const Container = styled.div``

export const SustainabilityScoreContainer = styled.div`
  display: inline;
  white-space: nowrap;
`

export const SustainabilityScore = styled.span<{ deviationLevel: EmissionsDeviationLevel }>`
  ${tabs};
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.bodyText2};
  padding-left: 3px;
`

export const EcoCertifiedContainer = styled.div`
  display: inline;
`

export const Separator = styled.span<{ deviationLevel: EmissionsDeviationLevel }>`
  color: ${(props) =>
    props.deviationLevel === EmissionsDeviationLevel.Less
      ? props.theme.colors.success
      : props.theme.colors.bodyText2};
  padding: 0 3px;
`

export const Label = styled.span`
  ${tabs}
  color: ${(props) => props.theme.colors.success};
  white-space: nowrap;
`
