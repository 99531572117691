import type { CreditCard, PaymentDetails } from '@fiji/graphql/types'

type Props = {
  singleTimeUseCard?: Partial<CreditCard>
  paymentDetails: PaymentDetails | null
}

export function getSingleTimeUseCard({ singleTimeUseCard, paymentDetails }: Props) {
  if (singleTimeUseCard) {
    return singleTimeUseCard
  }

  if (paymentDetails?.creditCard?.id === 'single-use-card') {
    return {
      id: paymentDetails.creditCard.id,
      billingAddress: paymentDetails.billingAddress,
      ...paymentDetails.creditCardData,
    }
  }
}
