import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { FareSegmentSeatRows } from '@fiji/types'
import { FlightCard, getFlightCardStatus } from '@etta/components/flight-card'
import type { AdaptedFlightSegment } from '@etta/components/flight-details/types'
import type { SegmentSeatNumber } from '../../air-seat-map.types'
import { getSeatFeaturedTitle } from '../../get-seat-featured-title'

type Props = {
  approvedSegmentsSeatNumbers: SegmentSeatNumber[]
  segmentsSeatRows: FareSegmentSeatRows[]
  adaptedFlightSegments: AdaptedFlightSegment[]
  usedSegmentId: string
  onSelectedSegmentIdChange?: (id: string) => void
  allowSkipSeatSelection?: boolean
  skipSeatSelection?: () => void
  hasNextSegment?: boolean
  isReadonly?: boolean
  headline: string
}

export function LeftColumnContent({
  skipSeatSelection,
  segmentsSeatRows,
  adaptedFlightSegments,
  usedSegmentId,
  onSelectedSegmentIdChange,
  approvedSegmentsSeatNumbers,
  isReadonly,
  headline,
  hasNextSegment,
  allowSkipSeatSelection,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Block marginBottom={8}>
        <Text aria-label={headline} variant="headline" color="mainText">
          {headline}
        </Text>
      </Block>
      {segmentsSeatRows.map(({ segmentId, destination, origin, readOnly, isSeatMapAvailable }) => {
        const adaptiveFlightSegment = adaptedFlightSegments.find(({ id }) => id === segmentId)
        const seat = approvedSegmentsSeatNumbers.find(
          (seat) => seat.segmentId === segmentId && !!seat.seatNumber.length,
        )

        const status = getFlightCardStatus(
          Boolean(isSeatMapAvailable),
          isReadonly || readOnly,
          !!seat,
        )

        return (
          <Block key={segmentId} marginBottom={8}>
            <FlightCard
              isLastFlight={!hasNextSegment}
              id={segmentId}
              selectedSeat={seat && getSeatFeaturedTitle(seat, t)}
              active={usedSegmentId === segmentId}
              airlineLogo={adaptiveFlightSegment?.airlineLogo || ''}
              airports={{
                to: destination,
                from: origin,
              }}
              flightInfo={{
                airlineName: adaptiveFlightSegment?.flightNumber || '',
                flightNumber: adaptiveFlightSegment?.planeType || '',
                aircraftType: '',
              }}
              status={status}
              onCardClick={onSelectedSegmentIdChange}
              onEditSeatClick={onSelectedSegmentIdChange}
              showSkipButton={allowSkipSeatSelection}
              onSkipSeatSelectionClick={skipSeatSelection}
            />
          </Block>
        )
      })}
    </>
  )
}
