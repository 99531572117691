import { Inject, Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { LocalStorageItem } from '@etta/interface/services/local-storage-item'
import { SegmentTypeCheckService } from '../services/segment-type-check.service'
import { MAXIMUM_HOTEL_QUANTITY, MAXIMUM_CAR_RENTAL_QUANTITY } from '../../core/constants'
import type { ActiveItineraryEntity } from '../../core/entity/active-itinerary.entity'

@Store()
export class ActiveTripStore {
  private _activeTripId = ''

  private _activeTripFlightId = ''

  shouldFetchActiveItinerary = true

  isTripExpired = false

  maximumQuantityToggle = new Toggle()

  // put current itinerary in storage in case of refresh
  private _activeItinerary = new LocalStorageItem<ActiveItineraryEntity>('active-itinerary')
  private _currentlyReactivatedID = new LocalStorageItem<string>('currently-reactivated-id')

  constructor(@Inject() private segmentTypeCheckService: SegmentTypeCheckService) {}

  get activeTripId() {
    return this._activeItinerary.value?.id || this._activeTripId
  }

  get activeTripFlightId() {
    return this._activeItinerary.value?.flightId || this._activeTripFlightId
  }

  get activeItineraryExpiredDate() {
    if (this._activeItinerary.value?.expiredAt) {
      return new Date(this._activeItinerary.value?.expiredAt)
    }

    return undefined
  }

  get isEmptyItinerary() {
    return !this._activeItinerary.value || this._activeItinerary.value.segments.length === 0
  }

  get hasExistingFlight() {
    return (
      this._activeItinerary.value &&
      this._activeItinerary.value.segments.some(this.segmentTypeCheckService.isFlightSegment)
    )
  }

  get isMaximumHotelQuantity() {
    return (
      this._activeItinerary.value &&
      this._activeItinerary.value.segments.filter(this.segmentTypeCheckService.isHotelSegment)
        .length >= MAXIMUM_HOTEL_QUANTITY
    )
  }

  get isMaximumCarRentalQuantity() {
    return (
      this._activeItinerary.value &&
      this._activeItinerary.value.segments.filter(this.segmentTypeCheckService.isCarRentalSegment)
        .length >= MAXIMUM_CAR_RENTAL_QUANTITY
    )
  }

  get tripName() {
    return this._activeItinerary.value?.tripName ?? ''
  }

  get segments() {
    return this._activeItinerary.value?.segments ?? []
  }

  get availableSegments() {
    return this._activeItinerary.value?.availableSegments
  }

  get startDate() {
    return this._activeItinerary.value?.startDate ?? ''
  }

  get endDate() {
    return this._activeItinerary.value?.endDate
  }

  get expiredAt() {
    return this._activeItinerary.value?.expiredAt
  }

  get currentlyReactivatedID() {
    return this._currentlyReactivatedID.value
  }

  setCurrentlyReactivatedID(value: string) {
    this._currentlyReactivatedID.set(value)
  }

  setIsTripExpired(value: boolean) {
    this.isTripExpired = value
  }

  setActiveTripFlightId(value: string) {
    this._activeTripFlightId = value
  }

  setActiveTripId(value: string) {
    this._activeTripId = value
  }

  setShouldFetchActiveItinerary(value: boolean) {
    this.shouldFetchActiveItinerary = value
  }

  setActiveItinerary(data: ActiveItineraryEntity) {
    this._activeItinerary.set(data)
  }

  dropCurrentlyReactivatedID() {
    this._currentlyReactivatedID.remove()
  }

  clearStore() {
    this._activeTripId = ''

    this._activeTripFlightId = ''

    this.shouldFetchActiveItinerary = false

    this.isTripExpired = false

    this.maximumQuantityToggle = new Toggle()

    this._activeItinerary.remove()
  }
}
