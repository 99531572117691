import styled, { css } from 'styled-components'
import { subHeadStrong, UnstyledButton } from '@fiji/style'
import { TRANSITION_TIMEOUT, TRANSITION_NAME } from './constants'
import type { MenuPosition } from './types'

export const fade = css`
  transition: all ${TRANSITION_TIMEOUT}ms linear;

  &.${TRANSITION_NAME}-enter {
    opacity: 0;
    transform: translateX(50px);
  }

  &.${TRANSITION_NAME}-enter-active, &.${TRANSITION_NAME}-enter-done {
    transform: translateX(0);
    opacity: 1;
  }

  &.${TRANSITION_NAME}-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.${TRANSITION_NAME}-exit-active, &.${TRANSITION_NAME}-exit-done {
    opacity: 0;
    transform: translateX(50px);
  }
`

export const Container = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const TriggerElementWrapper = styled(UnstyledButton)``

export const MenuWrapper = styled.div<{ position: MenuPosition }>`
  position: absolute;
  top: calc(100% + 5px);
  max-width: 300px;
  padding: 16px 20px 12px;
  background: #fff;
  border-radius: 6px;
  z-index: 11;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.2);
  ${fade};
  ${({ position }) =>
    position === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`

export const Title = styled.div`
  margin-bottom: 16px;
  ${subHeadStrong};
  line-height: 20px;
  color: #000;
`
