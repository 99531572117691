import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Image = styled.img<{
  isImagePortrait: boolean
  isFullHeight?: boolean
}>`
  flex: 1;
  ${(props) => (props.isImagePortrait ? 'width: 100%;' : 'max-height: 100%;')}
  ${(props) =>
    props.isFullHeight &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}
`
