import { Action, Inject } from '@etta/di'
import { PaymentInfoStore } from '../stores/payment-info.store'

@Action()
export class PaymentInfoActions {
  constructor(@Inject() private readonly paymentInfoStore: PaymentInfoStore) {}

  handlePaymentInfoFieldChange(key: Parameters<typeof this.paymentInfoStore.setField>[0]) {
    return (value: string | boolean) => this.paymentInfoStore.setField(key, value)
  }

  handlePreExprirationChange(value: Parameters<typeof this.paymentInfoStore.setDisplayAlert>[0]) {
    this.paymentInfoStore.setDisplayAlert(value)
  }
}
