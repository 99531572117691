import { useTranslation } from 'react-i18next'
import { Button as MobileButton } from '@etta/ui/button'
import { Container } from './car-rental-mobile-footer-styled'

type Props = {
  isDisabled?: boolean
  onButtonClick: () => void
}

const i18Base = 'CarRentalDetails'

export function CarRentalMobileFooter({ isDisabled, onButtonClick }: Props) {
  const { t } = useTranslation()

  return (
    <Container>
      <MobileButton
        fullWidth
        disabled={isDisabled}
        onClick={onButtonClick}
        data-tracking-id="add-car-rental-button">
        {t(i18Base + '.AddToTripButton')}
      </MobileButton>
    </Container>
  )
}
