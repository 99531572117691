import { useHistory } from 'react-router'
import { useAppDispatch } from '@fiji/store'
import { resetMainNavigation } from '@fiji/store/main-navigation'
import { resetTripReviewParams } from '@fiji/store/trip-review-params'
import { ROUTES } from '@fiji/routes'

export function useResetDelegateActiveBookingFlow() {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleRedirect = () => {
    setTimeout(() => {
      // Redirect to explore page and wipe navigation history
      // to prevent unauthorized page access,
      // e.g., DelegateeB access DelegateeA trip details page.
      history.push(ROUTES.explore)
      dispatch(resetMainNavigation())
    }, 0)
  }

  const onReset = () => {
    dispatch(resetMainNavigation())
    dispatch(resetTripReviewParams())
  }

  return { onReset, handleRedirect }
}
