import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Block } from '@etta/ui/block'
import type { LayoutProps } from '../../types'
import { useHotelDetailsAmenities } from '../../use-hotel-details-amenities'
import { AmenitiesGrid } from './amenities-grid'
import { Container } from './hotel-details-amenities-desktop-styled'

export function HotelDetailsAmenitiesLayoutDesktop({ amenities, exclusiveAmenities }: LayoutProps) {
  const { t } = useTranslation()
  const i18Base = 'HotelDetails'

  const {
    hotelAmenitiesToggle,
    roomAmenitiesToggle,
    negotiatedAmenitiesToggle,
    hotelAmenities,
    roomAmenities,
    negotiatedAmenities,
  } = useHotelDetailsAmenities({ amenities, exclusiveAmenities })

  return (
    <Container>
      {!!hotelAmenities.length && (
        <>
          <Block marginBottom={6}>
            <Text aria-label={t(i18Base + '.HotelAmenities')} variant="title3" color="mainText">
              {t(i18Base + '.HotelAmenities')}
            </Text>
          </Block>
          <AmenitiesGrid
            amenities={hotelAmenities}
            onToggle={hotelAmenitiesToggle.handleToggle}
            isOpen={hotelAmenitiesToggle.isOpen}
          />
        </>
      )}
      {!!roomAmenities.length && (
        <>
          <Block marginBottom={8}>
            <Text
              aria-label={t(i18Base + '.InroomAmenities')}
              variant="subHeadStrong"
              color="mainText">
              {t(i18Base + '.InroomAmenities')}
            </Text>
          </Block>
          <AmenitiesGrid
            amenities={roomAmenities}
            onToggle={roomAmenitiesToggle.handleToggle}
            isOpen={roomAmenitiesToggle.isOpen}
          />
        </>
      )}
      {!!negotiatedAmenities.length && (
        <>
          <Block marginBottom={8}>
            <Text
              aria-label={t(i18Base + '.NegotiatedAmenities')}
              variant="subHeadStrong"
              color="mainText">
              {t(i18Base + '.NegotiatedAmenities')}
            </Text>
          </Block>
          <AmenitiesGrid
            amenities={negotiatedAmenities}
            onToggle={negotiatedAmenitiesToggle.handleToggle}
            isOpen={negotiatedAmenitiesToggle.isOpen}
          />
        </>
      )}
    </Container>
  )
}
