import { useCallback, useEffect, useMemo, useState } from 'react'
import type { PlainTime } from '@fiji/types'
import type { Mode } from '../material-time-picker'

type Props = {
  initialTime?: PlainTime
}

const MINUTE_STEP = 5
const AMPM_HOURS = 12
const SWITCH_MODE_ANIMATION_TIME_IN_MS = 400

export function useMaterialTimePicker({ initialTime }: Props) {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [ampm, setAMPM] = useState<'am' | 'pm'>('am')
  const [mode, setMode] = useState<Mode>('hours')
  const [isModeTransitionNeeded, setIsModeTransitionNeeded] = useState(false)

  useEffect(() => {
    setHours(getInitialHoursValue(initialTime))
    setMinutes(getInitialMinutesValue(initialTime))
    setAMPM(getInitialAMPM(initialTime))
    switchMode('hours')
  }, [initialTime])

  const changeValue = useCallback(
    (value: number, committed: boolean) => {
      if (mode === 'hours') {
        setHours(value)
        if (committed) {
          setTimeout(() => {
            switchMode('minutes')
          })
        }
      } else {
        setMinutes(value * MINUTE_STEP)
      }
    },
    [mode, setHours, setMinutes],
  )

  const currentValue = useMemo(() => {
    if (mode === 'hours') {
      return hours
    }
    return minutes / MINUTE_STEP
  }, [mode, hours, minutes])

  const switchMode = (to: Mode) => {
    setMode(to)
    setIsModeTransitionNeeded(true)
    setTimeout(() => {
      setIsModeTransitionNeeded(false)
    }, SWITCH_MODE_ANIMATION_TIME_IN_MS)
  }

  return {
    hours,
    minutes,
    ampm,
    setAMPM,
    mode,
    isModeTransitionNeeded,
    changeValue,
    currentValue,
    switchMode,
  }
}

function getInitialHoursValue(time?: PlainTime): number {
  if (time) {
    return time.hours % AMPM_HOURS
  }
  return new Date().getHours() % AMPM_HOURS
}

function getInitialMinutesValue(time?: PlainTime): number {
  let minutes = new Date().getMinutes()
  if (time) {
    minutes = time.minutes
  }
  if (minutes % MINUTE_STEP !== 0) {
    minutes += MINUTE_STEP - (minutes % MINUTE_STEP)
    minutes = minutes % 60
  }
  return minutes
}

function getInitialAMPM(time?: PlainTime): 'am' | 'pm' {
  if (time) {
    return time.hours >= AMPM_HOURS ? 'pm' : 'am'
  }
  return new Date().getHours() >= AMPM_HOURS ? 'pm' : 'am'
}
