import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Screen } from '@etta/ui/screen'
import { Header as MobileHeader } from '@etta/ui/header'

const i18Base = 'TripPlanner.BaseSearch'

type Props = PropsWithChildren<{
  onBack: () => void
}>

export function RailSearchPageMobile({ children, onBack }: Props) {
  const { t } = useTranslation()
  return (
    <Screen>
      <Screen.Container>
        <Screen.Header>
          <MobileHeader leftSlot={<MobileHeader.CloseButton onClick={onBack} />}>
            <MobileHeader.Title title={t(i18Base + '.Header.Rail')} align="left" />
          </MobileHeader>
        </Screen.Header>
        {children}
      </Screen.Container>
    </Screen>
  )
}
