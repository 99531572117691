import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 20px 0;
`

export const RailEcoCheckContainer = styled.div`
  background: #fff;
  padding: 17px 17px 0 17px;
`

export const SkeletonWrapper = styled.div`
  > * {
    margin: 16px;
    &:fist-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
`
