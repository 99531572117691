import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { AppState } from '@fiji/store'
import { StarRating } from '@etta/ui/star-rating'
import { Badge } from '@etta/ui/badge'
import type { Distance, Maybe, Preference, PrivateRate } from '@fiji/graphql/types'
import type { TooltipPlace } from '@etta/ui/tooltip'
import { useNormalizedDistance } from '@fiji/hooks/use-normalized-distance'
import { OutOfPolicyBadge } from './out-of-policy-badge'
import { PreferredBadge } from './preferred-badge'
import { PrivateRateBadge } from './private-rate-badge'
import { Container, ReviewsLoader } from './hotel-info-list-styled'

type Props = {
  starRating?: number | null
  isTripAdvisorReviewsEnabled?: boolean
  medianReviewRating?: number | null
  distance?: Distance
  isPreferred?: boolean | null
  preference?: Preference | null
  isOutOfPolicy?: boolean
  isAllRoomsOutOfPolicy?: boolean
  isEcoFriendly?: boolean
  isSmallIcons?: boolean
  privateRate?: Maybe<PrivateRate>
  isReviewLoading?: boolean
  outOfPolicyTexts?: string[]
  tooltipPlace?: TooltipPlace
}

export function HotelInfoList({
  starRating,
  isTripAdvisorReviewsEnabled,
  medianReviewRating,
  distance,
  isPreferred,
  preference,
  isOutOfPolicy,
  isAllRoomsOutOfPolicy,
  privateRate,
  isSmallIcons,
  isReviewLoading,
  outOfPolicyTexts,
  isEcoFriendly,
  tooltipPlace = 'top',
}: Props) {
  const { t } = useTranslation()
  const {
    hotels: { isStarRatingEnabled },
  } = useSelector((state: AppState) => state.displayConfiguration)
  const badgeSize = isSmallIcons ? 'small' : 'normal'

  const { distance: formattedDistance, units } = useNormalizedDistance(distance)
  const distanceTitle = `${formattedDistance} ${units ? units.toLowerCase() : ''}`
  const showOutOfPolicy = !isPreferred && (isOutOfPolicy || isAllRoomsOutOfPolicy)
  const hasCustomBadge =
    preference?.customBadge &&
    !!preference?.customBadge?.name &&
    preference?.customBadge?.name !== '-1'

  return (
    <Container>
      {isStarRatingEnabled && !!starRating && (
        <StarRating
          color="bodyText"
          aria-label={t(`SearchResults.Hotel.HotelRatingAriaLabel`, { rating: starRating })}
          rating={starRating}
          variant="withBackground"
        />
      )}
      {isTripAdvisorReviewsEnabled && isReviewLoading && <ReviewsLoader />}
      {isTripAdvisorReviewsEnabled && !!medianReviewRating && (
        <Badge
          variant="tripAdvisor"
          label={String(medianReviewRating)}
          color="bodyText"
          iconSize={badgeSize}
        />
      )}
      {distance && (
        <Badge variant="distance" label={distanceTitle} color="bodyText" iconSize={badgeSize} />
      )}
      {isEcoFriendly && <Badge variant="ecoFriendly" color="bodyText" iconSize={badgeSize} />}
      {showOutOfPolicy && (
        <OutOfPolicyBadge
          outOfPolicyTexts={outOfPolicyTexts}
          badgeSize={badgeSize}
          tooltipPlace={tooltipPlace}
        />
      )}
      {hasCustomBadge && preference?.customBadge?.name && (
        <Badge variant="customPreferred" color="bodyText" label={preference?.customBadge?.name} />
      )}
      {isPreferred && !hasCustomBadge && (
        <PreferredBadge preference={preference} badgeSize={badgeSize} tooltipPlace={tooltipPlace} />
      )}
      {!!privateRate && <PrivateRateBadge privateRate={privateRate} badgeSize={badgeSize} />}
    </Container>
  )
}
