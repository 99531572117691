import styled from 'styled-components'
import { Dialog } from '@etta/ui/dialog'
import { captionMedium, title3 } from '@fiji/style'

export const DialogWrapper = styled(Dialog)`
  border-radius: 14px;
`

export const EditSection = styled.div`
  padding: 24px 20px 27px;
`

export const EditTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EditHeading = styled.div`
  line-height: 25px;
  color: ${(props) => props.theme.colors.mainText};
  ${title3}
`

export const CharCounter = styled.div`
  margin-top: 6px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.bodyText};
  ${captionMedium}
`

export const IconWrapper = styled.div`
  cursor: pointer;
`
