import {
  TRANSITION_GROUP_TO_NAMES,
  PAGE_DEFAULT_ANIMATION_TIMEOUT,
} from '@etta/modules/app/ui/content/routes-switcher/page-transitions'
import useGroups from './use-groups'
import useBackDetect from './use-back-detect'
import useTransitionPage from './use-transition-page'

export default function useNextTransition() {
  const { isPageChanged, transitionIndex } = useTransitionPage()
  const { isBack } = useBackDetect({ transitionIndex, isPageChanged })
  const { usedTransitionGroup } = useGroups({ isBack, isPageChanged })

  const nextTransition = isBack
    ? TRANSITION_GROUP_TO_NAMES[usedTransitionGroup].back
    : TRANSITION_GROUP_TO_NAMES[usedTransitionGroup].front

  return {
    nextTransition,
    timeout: PAGE_DEFAULT_ANIMATION_TIMEOUT,
  }
}
