import { MediaLayout } from '@etta/ui/media-layout'
import { ScreenType } from '@fiji/modes'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProps } from './types'
import { RoomsContentLayoutMobile } from './mobile'
import { RoomsContentLayoutDesktop } from './desktop'

export function RoomsContentLayout(props: LayoutProps) {
  const { featureFlags } = useFeatureFlags()

  return (
    <MediaLayout
      mobileSlot={<RoomsContentLayoutMobile {...props} />}
      desktopSlot={<RoomsContentLayoutDesktop {...props} />}
      forceScreenType={featureFlags.isDesktopLayoutHotelFlowEnabled ? null : ScreenType.Mobile}
    />
  )
}
