import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@etta/ui/tooltip'
import { Text } from '@etta/ui/text'
import { ScreenType, useScreenType } from '@fiji/modes'
import type { BadgeSkinType } from '../types'
import { TooltipOuterContainer } from '../air-badges-components'
import { Badge } from './badge'

const i18BaseBadges = 'SearchSegments.Badges'
const i18nBaseTooltip = 'AirSearchResults.Tooltip'

type Props = {
  skin?: BadgeSkinType
}

export function NDCFareTooltip({ children, skin }: PropsWithChildren<Props>) {
  const { t } = useTranslation()

  return (
    <TooltipOuterContainer skin={skin}>
      <Tooltip
        slot={
          <Text style={{ whiteSpace: 'pre-wrap' }} variant="footnoteStrong" isBlock>
            {t(`${i18nBaseTooltip}.NDCFare`)}
          </Text>
        }
        place="top"
        isIndependentOfParentWidth>
        {children}
      </Tooltip>
    </TooltipOuterContainer>
  )
}

export function NDCFareBadge({ skin }: Props) {
  const { t } = useTranslation()
  const screenType = useScreenType()

  const isDesktop = screenType === ScreenType.Desktop

  if (!isDesktop) {
    return (
      <Badge
        skin={skin}
        key="ndc-fare"
        title={t(`${i18BaseBadges}.NDCFare`)}
        icon="NDCFarePWA"
        data-tracking-id="ndc-fare-badge"
      />
    )
  }

  return (
    <NDCFareTooltip>
      <Badge
        skin={skin}
        key="ndc-fare"
        title={t(`${i18BaseBadges}.NDCFare`)}
        icon="NDCFarePWA"
        data-tracking-id="ndc-fare-badge"
      />
    </NDCFareTooltip>
  )
}
