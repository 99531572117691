import { lazy } from 'react'
import { ROUTES, getDocumentTitle } from '@fiji/routes'
import type { RouteItem } from '@fiji/routes/types'

const AdminPage = lazy(() => import('./admin-page'))
const HeapAnalyticsIdentityForm = lazy(() => import('./heap-analytics-identity-form'))

export const adminRoutes: Array<RouteItem> = [
  {
    route: {
      path: `${ROUTES.admin.main}`,
      exact: true,
    },
    documentTitle: getDocumentTitle('Admin'),
    isNavBarHidden: true,
    isSkipAuth: false,
    transitionIndex: 1,
    transitionGroup: 'fade-through',
    component: () => <AdminPage />,
  },
  {
    route: {
      path: `${ROUTES.admin.heap}`,
      exact: true,
    },
    documentTitle: getDocumentTitle('Admin | Heap'),
    isNavBarHidden: true,
    isSkipAuth: false,
    transitionIndex: 1,
    transitionGroup: 'fade-through',
    component: () => <HeapAnalyticsIdentityForm />,
  },
]
