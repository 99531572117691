import { cloneElement } from 'react'
import type { LayoutProps } from '../../types'

export function LocationSearchFieldMobile({ inputSlot, modalSlot, listSlot }: LayoutProps) {
  return (
    <>
      {inputSlot}

      {cloneElement(modalSlot, {
        children: listSlot,
      })}
    </>
  )
}
