import { SegmentType } from '@etta/core/enums'
import { useHotelSearchQuery } from '@fiji/hooks/search-queries/use-hotel-search-query'
import { useAppDispatch } from '@fiji/store'
import { updateTripReviewStatus } from '@fiji/store/trip-review-params'
import { updatePostPurchaseEntrance } from '@fiji/store/post-purchase-entrance'
import type { SegmentPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { ScreenType, useScreenType } from '@fiji/modes'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useMultipleHotelProvidersContext } from '@etta/modules/multiple-hotel-providers'
import { getQueryParamsPostBooking } from './get-query-params'
import { useGetPlaceByCityName } from './use-get-place-by-city-name'

type Args = {
  itineraryId: string
  segments: SegmentPostBookingValueObject[]
  bookingId?: string
}

export function useHotelPlacePostBooking({ itineraryId, segments, bookingId }: Args) {
  const { navigateTo, appendQueryParams } = useHotelSearchQuery()
  const dispatch = useAppDispatch()
  const params = getQueryParamsPostBooking({
    segments,
    forType: SegmentType.Hotel,
  })
  const { place } = useGetPlaceByCityName({ cityName: params?.to.city })
  const { postBookingAddSegmentAction } = usePostBookingContext()
  const {
    multipleHotelProvidersStore,
    multipleHotelProvidersActions,
  } = useMultipleHotelProvidersContext()
  const { areMultipleProvidersExist, handleSetActionType } = multipleHotelProvidersActions
  const { multipleHotelProvidersTripDialog } = multipleHotelProvidersStore
  const isDesktop = useScreenType() !== ScreenType.Mobile

  const onAddHotel = () => {
    if (areMultipleProvidersExist()) {
      handleSetActionType('add')
      multipleHotelProvidersTripDialog.handleOpen()
      return
    }

    const hotelSearchFormUrl = '/explore/hotel-search'
    const hotelSearchResultsUrl = '/explore/hotel-results'

    const isShowHotelForm = !params || !params.endDate || !params.endTime

    if (isDesktop && isShowHotelForm) {
      appendQueryParams({
        itineraryId,
        location: place ? place : params?.to,
        checkInDate: params?.startDate,
        checkOutDate: params?.endDate,
        bookingId,
        selectedTab: SegmentType.Hotel,
      })
      postBookingAddSegmentAction.handleAddHotel()

      return
    }

    dispatch(updateTripReviewStatus('update'))
    dispatch(updatePostPurchaseEntrance('fromTripDetails'))

    navigateTo(isShowHotelForm ? hotelSearchFormUrl : hotelSearchResultsUrl, {
      itineraryId,
      location: place ? place : params?.to,
      checkInDate: params?.startDate,
      checkOutDate: params?.endDate,
      bookingId,
      selectedTab: SegmentType.Hotel,
    })
  }

  return {
    onAddHotel,
  }
}
