import { useTranslation } from 'react-i18next'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { CarServiceItinerarySegment } from '@etta/modules/ride-hail/core'
import { deleteTimezone, getFormattedTime } from '@fiji/utils/dates'
import { Container, Detail, Title, DetailLine } from './mobility-itinerary-segment-styled'

type Prop = {
  segment: CarServiceItinerarySegment
}

const i18nBase = 'Mobility.ItineraryDialog'
const DATE_TIME_FORMAT = 'MMM d, h:mm aa'

export function MobilityItineraryCarServiceSegment({ segment }: Prop) {
  const { t } = useTranslation()

  const pickUpDateTime = segment.pickUpDateTime
    ? getFormattedTime(new Date(deleteTimezone(String(segment.pickUpDateTime))), DATE_TIME_FORMAT)
    : null

  const dropOffDateTime = segment.dropOffDateTime
    ? getFormattedTime(new Date(deleteTimezone(String(segment.dropOffDateTime))), DATE_TIME_FORMAT)
    : null

  return (
    <Container>
      <Icon name={'carServicePWA'} />
      <Detail>
        <Title variant="footnoteStrong" color="mainText" textOverflow="ellipsis">
          {segment.city}
        </Title>
        {pickUpDateTime && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.PickUp`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {pickUpDateTime}
            </Text>
          </DetailLine>
        )}
        {dropOffDateTime && (
          <DetailLine>
            <Text variant="footnoteMedium" color="bodyText">
              {t(`${i18nBase}.DropOff`)}:
            </Text>
            &nbsp;
            <Text variant="footnoteMedium" color="mainText">
              {dropOffDateTime}
            </Text>
          </DetailLine>
        )}
      </Detail>
    </Container>
  )
}
