import { useTranslation } from 'react-i18next'
import { ScreenType, useScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Container, BadgeContainer, IconRow } from './webfare-styled'

export function WebfareDescription() {
  const { t } = useTranslation()
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile

  const badgeTitle = t('Badge.WebFare')

  return (
    <Container isMobile={isMobile}>
      <BadgeContainer aria-label={badgeTitle}>
        <IconRow isMobile={isMobile}>
          <Icon name="directBookingIcon" size="small" color="primary" />
        </IconRow>
        <Text variant="footnoteMedium">
          {t('WebFareDescription.DirectBooking')}: {t('WebFareDescription.Description')}
        </Text>
      </BadgeContainer>
    </Container>
  )
}
