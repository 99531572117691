import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '@fiji/routes'
import { useRouteMatch } from '@fiji/hooks/navigation/use-route-match'
import { useShowDelegateName } from '@fiji/hooks/delegates/use-show-delegate-name'
import { useStartOverModal } from '@fiji/hooks/use-start-over-modal/use-start-over-modal'
import { getStoredCustomFieldsBookingList } from '@fiji/hooks/use-custom-fields/get-stored-custom-fields-booking-list'
import { StepName } from '@etta/screens/checkout-page/types'
import { usePaymentSummaryContext, usePurchaseInfoContext } from '@etta/modules/booking'
import { useCheckoutQuery } from '@fiji/hooks/search-queries'
import { useLoadPurchaseInfo } from '@etta/screens/payment-summary/use-payment-summary/use-load-purchase-info'
import { useCostAllocationContext } from '@etta/modules/cost-allocation'
import { useCheckoutInfoContext } from '@etta/modules/booking/interface/checkout-info/use-checkout-info.context'
import { useForm } from './use-form'

export function usePurchaseAdditionalInfoForm() {
  const history = useHistory()
  const { itineraryId } = useParams<{ itineraryId: string }>()
  const { queryParams } = useCheckoutQuery()
  const { bookingId, isHoldFlow } = queryParams
  const isAdditionalInfoPage = useRouteMatch(StepName.AdditionalInfo)

  const {
    customFields,
    areCustomFieldsExist,
    isCostAllocationExist,
    dynamicSiteMessages,
  } = useLoadPurchaseInfo()

  const { purchaseInfoActions } = usePurchaseInfoContext()

  const { costAllocationStore } = useCostAllocationContext()
  const { paymentSummaryActions } = usePaymentSummaryContext()

  const { isShowDelegateName } = useShowDelegateName()

  const {
    checkoutInfoStore: { isAdditionalDataLoading, isAdditionalDataError },
  } = useCheckoutInfoContext()

  const isLoading = isAdditionalDataLoading || costAllocationStore.isTopLevelCostSegmentLoading

  const isError = !!isAdditionalDataError

  const onSubmit = useCallback(
    (values: Record<string, string>) => {
      const customFieldsBookingList = getStoredCustomFieldsBookingList(customFields, values)

      paymentSummaryActions.updateCustomFields(customFieldsBookingList)
      history.push(
        ROUTES.checkout({
          itineraryId,
          bookingId,
          checkoutStep: 'payment-info',
          isHoldFlow: isHoldFlow,
        }),
      )
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [customFields],
  )

  const { values, errors, reset, onFieldChange, onSubmitHandler } = useForm({
    fields: customFields,
    onSubmit,
  })

  const onBack = useCallback(() => {
    history.push(
      ROUTES.checkout({
        itineraryId,
        bookingId,
        checkoutStep: 'traveler-info',
        isHoldFlow: isHoldFlow,
      }),
    )
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onReload = () => {
    purchaseInfoActions.fetchAdditionalDataFromCheckoutInfo()
  }

  const { startOverModalSlot } = useStartOverModal({
    isLoading,
    bookingId,
    isPreventForward: isAdditionalInfoPage,
    isPreventBack: false,
  })

  const hasFormErrors = Object.values(errors).some(Boolean)

  return {
    values,
    errors,
    onFieldChange,
    onSubmitHandler,
    isLoading,
    isError,
    hasFormErrors,
    customFields,
    onBack,
    onReload,
    startOverModalSlot,
    isShowDelegateName,
    handleResetFormErrors: reset,
    areCustomFieldsExist,
    isCostAllocationExist,
    isHoldFlow,
    messages: dynamicSiteMessages,
  }
}
