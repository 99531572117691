import styled from 'styled-components'
import { Block } from '@etta/ui/block'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 14px 0;
`

export const LinkWrapper = styled.div`
  margin-top: 8px;
`

export const ItemContainer = styled.div`
  display: flex;
`

export const OpeningImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 12px;

  img {
    height: 100%;
    border-radius: 4px;
  }
`

export const DisclaimerBlock = styled(Block)`
  display: flex;
  flex-flow: column wrap;
  > :first-child {
    margin-bottom: 1px;
  }
`
