import { useTranslation } from 'react-i18next'
import type { CarRentalEmission, FlightEmission, HotelEmission, RailEmission } from '../../../types'
import { LowerBody, SegmentTitle, SegmentTypeContainer } from '../rtp-eco-check-modal-mobile-styled'
import { RtpEcoCheckModalFlightSegment } from './rtp-eco-check-modal-flight-segment'
import { RtpEcoCheckModalHotelSegment } from './rtp-eco-check-modal-hotel-segment'
import { RtpEcoCheckModalCarRentalSegment } from './rtp-eco-check-modal-car-rental-segment'
import { RtpEcoCheckModalFlightTitle } from './rtp-eco-check-modal-flight-title'
import { RtpEcoCheckModalRailSegment } from './rtp-eco-check-modal-rail-segment'
import { RtpEcoCheckModalRailTitle } from './rtp-eco-check-modal-rail-title'

type Props = {
  carRentalEmissions: CarRentalEmission[]
  hotelEmissions: HotelEmission[]
  flightEmissions: FlightEmission[]
  railEmissions: RailEmission[]
  isHotelOnly: boolean
}

export function RtpEcoCheckModalMobileLowerBody({
  carRentalEmissions,
  hotelEmissions,
  flightEmissions,
  railEmissions,
  isHotelOnly,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  const flightTitle =
    flightEmissions.length > 1 ? t(i18nBase + '.MultipleFlights') : t(i18nBase + '.OneFlight')
  const railTitle =
    railEmissions.length > 1 ? t(i18nBase + '.MultipleTrains') : t(i18nBase + '.OneTrain')
  const carRentalTitle =
    carRentalEmissions.length > 1
      ? t(i18nBase + '.MultipleCarRentals')
      : t(i18nBase + '.OneCarRental')
  const hotelTitle =
    hotelEmissions.length > 1 ? t(i18nBase + '.MultipleHotels') : t(i18nBase + '.OneHotel')

  return (
    <LowerBody>
      {flightEmissions.length > 0 && (
        <SegmentTypeContainer
          withBorderBottom={carRentalEmissions.length + hotelEmissions.length > 0}>
          <SegmentTitle aria-label={flightTitle}>{flightTitle}</SegmentTitle>
          {flightEmissions.map((flightEmission) => {
            return (
              <RtpEcoCheckModalFlightSegment
                deviationLevel={flightEmission.deviationLevel}
                emissionsKg={flightEmission.emissionsKg}
                title={
                  <RtpEcoCheckModalFlightTitle
                    origin={flightEmission.originAirportCode}
                    destination={flightEmission.destiationAirportCode}
                  />
                }
                percentDeviation={flightEmission.percentDeviation}
              />
            )
          })}
        </SegmentTypeContainer>
      )}
      {carRentalEmissions.length > 0 && (
        <SegmentTypeContainer withBorderBottom={hotelEmissions.length > 0}>
          <SegmentTitle aria-label={carRentalTitle}>{carRentalTitle}</SegmentTitle>
          {carRentalEmissions.map((carRentalEmisison) => {
            return (
              <RtpEcoCheckModalCarRentalSegment
                deviationLevel={carRentalEmisison.deviationLevel}
                emissionsKg={carRentalEmisison.emissionsKg}
                title={carRentalEmisison.name}
                percentDeviation={carRentalEmisison.percentDeviation}
              />
            )
          })}
        </SegmentTypeContainer>
      )}
      {hotelEmissions.length > 0 && (
        <SegmentTypeContainer withBorderBottom={railEmissions.length > 0}>
          <SegmentTitle aria-label={hotelTitle}>{hotelTitle}</SegmentTitle>
          {hotelEmissions.map((hotelEmission) => {
            return (
              <RtpEcoCheckModalHotelSegment
                isHotelOnly={isHotelOnly}
                emissionsKg={hotelEmission.emissionsKg}
                sustainabilityScore={hotelEmission.sustainabilityScore}
                deviationLevel={hotelEmission.deviationLevel}
                title={hotelEmission.name}
              />
            )
          })}
        </SegmentTypeContainer>
      )}

      {railEmissions.length > 0 && (
        <SegmentTypeContainer>
          <SegmentTitle aria-label={railTitle}>{railTitle}</SegmentTitle>
          {railEmissions.map((railEmission) => {
            return (
              <RtpEcoCheckModalRailSegment
                emissionsKg={railEmission.emissionsKg}
                title={
                  <RtpEcoCheckModalRailTitle
                    origin={railEmission.originStationName}
                    destination={railEmission.destinationStationName}
                  />
                }
              />
            )
          })}
        </SegmentTypeContainer>
      )}
    </LowerBody>
  )
}
