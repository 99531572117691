import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { TripStatus } from '@fiji/graphql/types'
import type { FlightPostBookingValueObject } from '@etta/modules/post-booking/core/value-objects'
import { useTripModifyModal } from '@fiji/hooks/use-trip-modify-modal'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useCheckInData } from './use-check-in-data'
import { useModifyFlight } from './use-modify-flight'
import { useActionButtons } from './use-action-buttons'

type SubsSegment = FlightPostBookingValueObject['segments']

type Props = {
  segments: SubsSegment
  tripType: TripStatus
  tripName?: string | null
  flight: FlightPostBookingValueObject
  startDate: string
  endDate: string
  processId: string
  itineraryId: string
  flightId?: string | null
  isMultiCity?: boolean
  isTripInProgress?: boolean
  onSeatMapOpen: (segmentLegId?: string | null) => void
  onClose: () => void
}

export function useTripFlight({
  segments,
  tripType,
  flight,
  tripName,
  startDate,
  endDate,
  processId,
  itineraryId,
  flightId,
  isMultiCity,
  isTripInProgress,
  onSeatMapOpen,
  onClose,
}: Props) {
  const { t } = useTranslation()
  const { postBookingTripStore } = usePostBookingContext()
  const { supportedActions } = postBookingTripStore.trip
  const returnFlightModal = useTogglePopup()

  const {
    featureFlagsStore: {
      flags: { isChangingReturnFlightEnabled },
    },
  } = useFeatureFlagsContext()
  const { searchRideHailActions } = useRideHailSearchContext()

  const originAirport = segments?.[0].departure?.airportCode || ''
  const lastFlightLeg = (segments && segments.length - 1) || 0
  const destinationAirport = segments?.[lastFlightLeg].arrival?.airportCode || ''
  const destination = segments?.[lastFlightLeg].arrival?.airportCity || '-'
  const isDoubleFlights = (segments && segments.length === 2) ?? false
  const isTripleAndMoreFlights = (segments && segments.length > 2) ?? false
  const isMultipleFlights = isDoubleFlights || isTripleAndMoreFlights
  const pnrNumber = flight.pnrNumber || ''
  const separator = isTripleAndMoreFlights ? ', ' : ' • '
  const segmentStatus = segments?.[0].status

  const viaAirport = useMemo(() => {
    if (!segments?.length || !isMultipleFlights) {
      return ''
    }

    return segments.reduce((acc, segment, index) => {
      const isBetweenSegment = index < segments.length - 1
      const isFirstLeg = index === 0
      const viaAirport = segment.arrival?.airportCity
      return isBetweenSegment ? `${acc}${isFirstLeg ? '' : ', '}${viaAirport}` : acc
    }, '')
  }, [segments, isMultipleFlights])

  const airlineNameAndNumber = useMemo(() => {
    if (!segments?.length) {
      return ''
    }

    return segments.reduce((acc, segment, index) => {
      const isFirstLeg = index === 0

      if (isDoubleFlights && !isFirstLeg) {
        return acc
      }

      const airlineName = segment?.name || segment?.carrierCode || ''
      const airlineNumber = segment?.flightNumber || ''
      const airlinePlane = segment?.plane?.type || ''

      return `${acc}${isFirstLeg ? '' : ' • '}${airlineName} ${airlineNumber}${
        airlinePlane && separator
      }${airlinePlane}`
    }, '')
  }, [segments, isDoubleFlights, separator])

  const viaLocationText = viaAirport
    ? t('PostBooking.FlightDetails.ViaLocation', { location: viaAirport })
    : t('SearchSegments.Stops.Nonstop')

  const headerSubtitle = [airlineNameAndNumber, viaLocationText].join(' • ')

  const checkInData = useCheckInData({ segments })

  const confirmationNumber = segments?.[0].confirmationNumber || '-'

  const actionButtons = useActionButtons({ flight, tripName, startDate, endDate })

  const { handleModifyFlight, viewState, onCloseViewState } = useModifyFlight({
    processId,
    itineraryId,
    flightId,
  })

  const isTripCancelled = tripType === TripStatus.Cancelled
  const isFlightCancelled = segments?.some(({ status }) => status === TripStatus.Cancelled)
  const isOnHold = tripType === TripStatus.OnHold
  const isCompleted = tripType === TripStatus.Completed

  const isChangeFlightAllowed =
    !isTripCancelled &&
    supportedActions.isFlightChangeAllowed &&
    !isMultiCity &&
    !isOnHold &&
    !isTripInProgress

  const isCancelFlightAllowed =
    supportedActions.isFlightCancelAllowed && !isOnHold && !isTripInProgress

  const isSeatChangeAllowed = supportedActions.isFlightChangeAllowed

  const onOpen = useCallback(() => {
    onSeatMapOpen?.(flight.legId)
  }, [onSeatMapOpen, flight.legId])

  const closeIconAriaLabel = t('Accessibility.Header.CloseLabel')

  const isChangingReturnFlight = useMemo(() => {
    if (!isChangingReturnFlightEnabled) {
      return false
    }
    const hasTwoFlights = postBookingTripStore.flightSegments.length === 2

    if (!hasTwoFlights) {
      return false
    }

    return (
      postBookingTripStore.isReturnFlightChangeable &&
      postBookingTripStore.flightSegments.findIndex((item) => item.legId === flight.legId) === 1
    )
  }, [
    isChangingReturnFlightEnabled,
    postBookingTripStore.flightSegments,
    flight.legId,
    postBookingTripStore.isReturnFlightChangeable,
  ])

  const isChangingEntireFlight = useMemo(() => postBookingTripStore.isEntireFlightChangeable, [
    postBookingTripStore.isEntireFlightChangeable,
  ])

  const handleOpenReturnFlightModal = returnFlightModal.handleOpen

  const handlerModify = useCallback(() => {
    if (isChangingReturnFlight) {
      return handleOpenReturnFlightModal()
    }

    return handleModifyFlight()
  }, [handleModifyFlight, handleOpenReturnFlightModal, isChangingReturnFlight])

  const { modalAction, handleViewSupport, toggleModifyModal } = useTripModifyModal({
    isAllowed: isChangeFlightAllowed || isChangingReturnFlight,
    modifyAction: handlerModify,
  })

  const handleStartMobilitySearchFlow = useCallback(() => {
    onClose()
    searchRideHailActions.openMobilitySearchDesktopModal()
  }, [onClose, searchRideHailActions])

  const someSegmentsNotStarted = segments?.some(({ departureDate }) =>
    departureDate ? isFuture(new Date(departureDate)) : false,
  )

  return {
    destination,
    viaLocationText,
    originAirport,
    destinationAirport,
    isMultipleFlights,
    headerSubtitle,
    checkInData,
    isTripCancelled,
    isChangeFlightAllowed,
    pnrNumber,
    confirmationNumber,
    actionButtons,
    handleModifyFlight: modalAction,
    handleSubmitModifyFlight: handleModifyFlight,
    viewState,
    onOpen,
    onCloseViewState,
    isCancelFlightAllowed,
    isSeatChangeAllowed,
    isCompleted,
    closeIconAriaLabel,
    handleViewSupport,
    toggleModifyFlight: toggleModifyModal,
    handleStartMobilitySearchFlow,
    segmentStatus,
    isFlightCancelled,
    isChangingReturnFlight,
    isChangingEntireFlight,
    returnFlightModal,
    someSegmentsNotStarted,
  }
}
