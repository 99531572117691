import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { parse, stringify } from 'query-string'
import type { Step } from './types'

type QueryType = {
  step?: Step
}

export function useStep() {
  const history = useHistory()
  const { search, pathname } = useLocation()
  const query = parse(search) as QueryType
  const [step, setStep] = useState<Step>(query.step || 'location')
  const changeStep = useCallback(
    (nextStep: Step) => {
      setStep(nextStep)

      history.push(`${pathname}?${stringify({ step: nextStep })}`)
    },
    [history, pathname],
  )

  return { step, changeStep }
}
