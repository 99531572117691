import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { ROUTES } from '@fiji/routes'
import { TripStatus } from '@fiji/graphql/types'
import { useMobilitySearchQuery } from '@fiji/hooks/search-queries/use-mobility-search-query/use-mobility-search-query'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import { useAppDispatch } from '@fiji/store'
import { updateCallbackPath, updateForceFetchSuggestions } from '@fiji/store/mobility'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { useBookTripContext } from '@etta/modules/booking/interface/book-trip/use-book-trip.context'
import { UberAccountStatusEnum } from '@etta/modules/ride-hail/core/enum/uber-account-status.enum'
import { useCallToActionInfo } from './hooks'

const ShowLearnMoreStatus = [
  UberAccountStatusEnum.NotFound,
  UberAccountStatusEnum.NotLinked,
  UberAccountStatusEnum.SiteEmptyMapping,
]
const ShowReserveRideStatus = UberAccountStatusEnum.Ready
const PROHIBITED_TRIP_STATUSES = [TripStatus.Completed, TripStatus.Cancelled]

type Props = {
  processId: string
  dropOffSegmentId?: string | null
  tripType?: TripStatus | null
  onStartMobilitySearchFlow?: () => void
}

export function useMobilityCallToAction({
  processId,
  dropOffSegmentId,
  tripType,
  onStartMobilitySearchFlow,
}: Props) {
  const { getCustomerUberAccountStatusStore } = useRideHailSearchContext()
  const { bookTripActions } = useBookTripContext()

  const { status, linkingUrl, isUserAccountSelfCreationEnabled } = getCustomerUberAccountStatusStore

  const isTripTypeValid = tripType ? !PROHIBITED_TRIP_STATUSES.includes(tripType) : true

  const isValidStatus = status
    ? ShowLearnMoreStatus.includes(status) || ShowReserveRideStatus.includes(status)
    : false

  const info = useCallToActionInfo({ status })
  const uberInfoModal = useTogglePopup()

  const history = useHistory()
  const { searchRideHailActions } = useRideHailSearchContext()
  const { navigateTo, queryParams } = useMobilitySearchQuery()
  const dispatch = useAppDispatch()

  const handleEventOpenCallToAction = useCallback(() => {
    if (status) {
      if (ShowLearnMoreStatus.includes(status)) {
        uberInfoModal.handleOpen()
        return
      }
      if (ShowReserveRideStatus.includes(status)) {
        const targetPath = ROUTES.mobility.search
        // This validation check is necessary to avoid a navigation loop bug.
        // When using the callbackPath as a reference path for exiting the search flow,
        // setting the callbackPath to the same value as the target path could result in a continuous navigation loop.
        if (targetPath !== history.location.pathname) {
          dispatch(
            updateCallbackPath({
              callbackPath: history.location.pathname,
              callbackParams: history.location.search,
            }),
          )
        }
        dispatch(updateForceFetchSuggestions({ forceFetchSuggestions: true }))

        searchRideHailActions.setSearchRideHailTripInfo({
          processId,
          dropOffSegmentId: dropOffSegmentId || undefined,
          transactionGroupId: queryParams.transactionGroupId,
        })

        if (onStartMobilitySearchFlow) {
          onStartMobilitySearchFlow()
          return
        }

        bookTripActions.handleSetShouldKeepBookTripState(true)

        const params = dropOffSegmentId === null ? { processId } : { processId, dropOffSegmentId }
        navigateTo(targetPath, params)
      }
    }
  }, [
    status,
    uberInfoModal,
    dropOffSegmentId,
    processId,
    dispatch,
    history.location.pathname,
    history.location.search,
    searchRideHailActions,
    queryParams.transactionGroupId,
    navigateTo,
    onStartMobilitySearchFlow,
    bookTripActions,
  ])

  return {
    info,
    isShown: isValidStatus && isTripTypeValid,
    onOpenCallToAction: handleEventOpenCallToAction,
    isModalOpen: uberInfoModal.isOpen,
    handleModalClose: uberInfoModal.handleClose,
    status,
    linkingUrl,
    isUserAccountSelfCreationEnabled,
  }
}
