import { useTranslation } from 'react-i18next'
import type { Rate } from '@fiji/graphql/types'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { formatRate } from '@fiji/utils/money/format-rate'
import {
  Container,
  Label,
  Wrapper,
  PriceChangeBlock,
  PriceChangedIconContainer,
  ButtonContainer,
} from './footer-checkout-styled'

type Props = {
  isDisabled?: boolean
  totalCost: Rate
  onCheckout: () => void
  onHold: () => void
  onOpenTripCostSummary: () => void
  changedCost?: Rate | null
  isCostChangePositive?: boolean | null
  isHoldTripAllowed: boolean
}

const i18nPathAccessability = 'Accessibility.ReviewTripPage'

export function FooterCheckout({
  isDisabled,
  totalCost,
  onCheckout,
  onOpenTripCostSummary,
  changedCost,
  isCostChangePositive,
  isHoldTripAllowed,
  onHold,
}: Props) {
  const { t } = useTranslation()
  const { mainCost } = formatRate(totalCost, { morpheme: 'none', isInteger: false })
  const { mainCost: formattedChangedCost } = formatRate(changedCost, {
    morpheme: 'none',
    isInteger: false,
  })
  const label = t('ReviewTrip.TripTotal')
  const buttonText = t('ReviewTrip.Checkout')
  const labelTextVariant = isHoldTripAllowed ? 'headline' : 'captionStrongCaps'
  const labelTextTransform = isHoldTripAllowed ? 'capitalize' : 'uppercase'

  return (
    <Container isHoldTripAllowed={isHoldTripAllowed} data-tracking-id="trip-total-checkout">
      <Wrapper
        isHoldTripAllowed={isHoldTripAllowed}
        onClick={onOpenTripCostSummary}
        aria-label={`${label}, ${mainCost}`}>
        <Label>
          <Text variant={labelTextVariant} textTransform={labelTextTransform} color="mainText2">
            {label}
          </Text>
          {!formattedChangedCost && <Icon name="infoPWA" color="mainText2" size="small" />}

          {!!formattedChangedCost && (
            <PriceChangeBlock>
              <Text variant="captionStrongCaps" color="borderLight">
                {formattedChangedCost}
              </Text>
              <PriceChangedIconContainer isCostChangePositive={isCostChangePositive}>
                <Icon name="arrowBgDownwardPWA" size="small" color="borderLight" />
              </PriceChangedIconContainer>
            </PriceChangeBlock>
          )}
        </Label>
        <Text variant="bodyLarge" color="mainText2">
          {mainCost}
        </Text>
      </Wrapper>
      <ButtonContainer
        isHoldTripAllowed={isHoldTripAllowed}
        aria-label={t(i18nPathAccessability + '.Checkout')}>
        {isHoldTripAllowed && (
          <Button variant="outline-white" onClick={onHold} disabled={isDisabled} fullWidth>
            {t('ReviewTrip.Hold')}
          </Button>
        )}
        <Button
          onClick={onCheckout}
          disabled={isDisabled}
          data-tracking-id="rtp-checkout-button"
          fullWidth>
          {buttonText}
        </Button>
      </ButtonContainer>
    </Container>
  )
}
