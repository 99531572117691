import { cloneElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import type { AddressInput } from '@fiji/graphql/types'
import { useStoragePlace } from '@fiji/hooks/use-storage-place'
import { Modal } from '@etta/ui/modal'
import { Icon } from '@etta/ui/icon'
import { useDateTimePickerControls } from '@etta/ui/date-time-picker'
import type { PlainTime as Time, Place } from '@fiji/types'
import { ScreenType, useScreenType } from '@fiji/modes'
import { CustomFocusedInput } from '@etta/ui/date-time-picker/controls/types'
import { SearchMechanism } from '@etta/components/search-mechanism'
import { useRideHailSearchContext } from '@etta/modules/ride-hail'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { MobilitySelectDateTime } from './mobility-select-date-time'
import type { PlaceWithAddress, SelectedValue } from './types'
import {
  AnimateContainer,
  MobileBackButton,
  TransitionContainer,
} from './mobility-select-location-modal-styled'
import {
  useTrackMobilitySelectLocationModal,
  useMobilitySelectLocationModal,
  useMobilitySuggestedLocations,
  useMobilityGetSuggestedDate,
} from './hooks'
import { SelectionState } from './types'

type Props = {
  isOnlyPickUp?: boolean
  isVisible: boolean
  onClose: () => void
  onSubmit: (value: SelectedValue) => void
  onExited: () => void
  pickUpPlace?: { place?: Place; address?: AddressInput }
  pickUpAddress?: string
  dropOffPlace?: { place?: Place; address?: AddressInput }
  dropOffAddress?: string
}

const i18Base = 'Mobility.SelectLocationModal'

export function MobilitySelectLocationModal({
  isOnlyPickUp,
  isVisible,
  onClose,
  onSubmit,
  onExited,
  dropOffPlace,
  dropOffAddress,
  pickUpAddress,
}: Props) {
  const { t } = useTranslation()
  const { places, addNewPlace } = useStoragePlace('mobility')
  const { focusedInput, changeFocusedInput } = useDateTimePickerControls({})
  const [currentState, setCurrentState] = useState<SelectionState>(
    isOnlyPickUp ? SelectionState.pickUp : SelectionState.dropOff,
  )
  const { suggestedPlaces, isLoading } = useMobilitySuggestedLocations()
  const [date, setDate] = useState<Date | undefined>()
  const [time, setTime] = useState<Time | undefined>()
  const [dropOff, setDropOff] = useState<{ place?: Place; address?: AddressInput } | undefined>()
  const [pickUp, setPickUp] = useState<{ place?: Place; address?: AddressInput } | undefined>()
  const [isNextDirection, setIsNextDirection] = useState(true)
  const [isPickUpFocused, setIsPickUpFocused] = useState(isOnlyPickUp ?? false)
  const [isDropOffFocused, setIsDropOffFocused] = useState(!isOnlyPickUp)
  const { dateTime } = useMobilitySelectLocationModal({ date, time })
  const screenType = useScreenType()
  const isDesktop = screenType !== ScreenType.Mobile
  const { getSuggestedDate } = useMobilityGetSuggestedDate()
  const { searchRideHailActions } = useRideHailSearchContext()

  const { featureFlagsStore } = useFeatureFlagsContext()
  const { isMobilitySearchFormV3Enabled } = featureFlagsStore.flags

  useTrackMobilitySelectLocationModal({ isVisible, state: currentState })

  const handleChangeState = useCallback((state: SelectionState) => {
    setCurrentState(state)
  }, [])

  const handleContinueButton = useCallback(() => {
    if (focusedInput === CustomFocusedInput.date) {
      changeFocusedInput(CustomFocusedInput.time)

      return
    }

    const currentDropOff = dropOffPlace?.place || dropOffPlace?.address ? dropOffPlace : dropOff

    if (currentDropOff && pickUp) {
      onSubmit({
        dropOff: currentDropOff,
        dropOffAddress,
        pickUp,
        pickUpAddress,
        dateTime: dateTime,
      })
      onClose()
    }
  }, [
    changeFocusedInput,
    dropOff,
    dropOffAddress,
    dropOffPlace,
    focusedInput,
    onClose,
    onSubmit,
    pickUp,
    pickUpAddress,
    dateTime,
  ])

  const handleDropOffChange = useCallback(
    (place?: Place, address?: AddressInput) => {
      if (place) {
        addNewPlace(place)
      }

      setDropOff({ place, address })
      setIsNextDirection(true)
      handleChangeState(SelectionState.pickUp)
    },
    [handleChangeState, addNewPlace],
  )

  const blurActiveElement = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) {
      activeElement.blur()
    }
  }, [])

  const handleSelectLocation = useCallback(
    (pickupLocation?: { place?: PlaceWithAddress; address?: AddressInput }) => {
      const currentDropOff = dropOffPlace?.place || dropOffPlace?.address ? dropOffPlace : dropOff
      const suggestedDate = getSuggestedDate(pickupLocation?.place, currentDropOff?.place)
      const suggestedDateFormatted = suggestedDate ? new Date(suggestedDate) : undefined

      if (currentDropOff && pickupLocation) {
        onSubmit({
          dropOff: currentDropOff,
          dropOffAddress,
          pickUp: pickupLocation,
          pickUpAddress,
          dateTime: { date: suggestedDateFormatted, time: undefined },
        })
        onClose()
      }
    },
    [dropOff, dropOffAddress, dropOffPlace, onClose, onSubmit, pickUpAddress, getSuggestedDate],
  )

  const handlePickUpChange = useCallback(
    (place?: Place, address?: AddressInput) => {
      blurActiveElement()
      if (place) {
        addNewPlace(place)
      }

      setPickUp({ place, address })

      if (isMobilitySearchFormV3Enabled) {
        setIsNextDirection(false)
        handleSelectLocation({ place, address })
        onClose()
        return
      }

      setIsNextDirection(true)

      const currentDropOff = dropOffPlace?.place || dropOffPlace?.address ? dropOffPlace : dropOff
      const suggestedDate = getSuggestedDate(place, currentDropOff?.place)

      if (suggestedDate) {
        setDate(new Date(suggestedDate))
      } else {
        setDate(undefined)
      }

      handleChangeState(SelectionState.dateTime)
    },
    [
      blurActiveElement,
      dropOffPlace,
      dropOff,
      getSuggestedDate,
      handleChangeState,
      addNewPlace,
      handleSelectLocation,
      onClose,
      isMobilitySearchFormV3Enabled,
    ],
  )

  const childFactory = (direction: string) => (child: any) =>
    cloneElement(child, {
      classNames: direction,
    })

  return (
    <Modal
      position={isDesktop ? 'right' : 'center'}
      isVisible={isVisible}
      handleModalVisibility={searchRideHailActions.openLeavingBookingFlowDialog}
      onModalExited={onExited}>
      <TransitionContainer>
        <TransitionGroup
          childFactory={childFactory(isNextDirection ? 'slide-right' : 'slide-left')}>
          {currentState === SelectionState.dropOff && (
            <CSSTransition
              key={currentState}
              classNames={isNextDirection ? 'slide-right' : 'slide-left'}
              timeout={300}
              onEntered={() => setIsDropOffFocused(true)}
              onExited={() => setIsDropOffFocused(false)}>
              <AnimateContainer>
                <SearchMechanism
                  forceScreenType={ScreenType.Mobile}
                  key={'dropOff'}
                  useNewLocationSearch={true}
                  searchType={'mobility'}
                  isModalOpen={isDropOffFocused}
                  hasCurrentLocationSearch
                  currentLocationSearchPrecision={'address'}
                  onChange={handleDropOffChange}
                  placeholder={t(`${i18Base}.WhereTo`)}
                  onModalClose={() => {}}
                  latestPlaces={places}
                  suggestionPlaces={suggestedPlaces}
                  isExternalLoading={isLoading}
                  customBackButtonSlot={
                    <MobileBackButton onClick={onClose}>
                      <Icon name={'closePWA'} size="medium" color="mainText" />
                    </MobileBackButton>
                  }
                />
              </AnimateContainer>
            </CSSTransition>
          )}
          {currentState === SelectionState.pickUp && (
            <CSSTransition
              key={currentState}
              classNames={isNextDirection ? 'slide-right' : 'slide-left'}
              timeout={300}
              onEntered={() => setIsPickUpFocused(true)}
              onExited={() => setIsPickUpFocused(false)}>
              <AnimateContainer>
                <SearchMechanism
                  forceScreenType={ScreenType.Mobile}
                  key={'pickup'}
                  searchType={'mobility'}
                  isModalOpen={isPickUpFocused}
                  hasCurrentLocationSearch
                  currentLocationSearchPrecision={'address'}
                  useNewLocationSearch={true}
                  onChange={handlePickUpChange}
                  placeholder={t(`${i18Base}.PickUpFrom`)}
                  onModalClose={() => {}}
                  latestPlaces={places}
                  suggestionPlaces={suggestedPlaces}
                  isExternalLoading={isLoading}
                  customBackButtonSlot={
                    <>
                      {isOnlyPickUp ? (
                        <MobileBackButton onClick={onClose}>
                          <Icon name={'closePWA'} size="medium" color="mainText" />
                        </MobileBackButton>
                      ) : (
                        <MobileBackButton
                          onClick={() => {
                            setIsDropOffFocused(true)
                            setIsNextDirection(false)
                            handleChangeState(SelectionState.dropOff)
                          }}>
                          <Icon name={'arrowBackLargePWA'} size="medium" color="mainText" />
                        </MobileBackButton>
                      )}
                    </>
                  }
                />
              </AnimateContainer>
            </CSSTransition>
          )}
          {currentState === SelectionState.dateTime && (
            <CSSTransition
              key={currentState}
              classNames={isNextDirection ? 'slide-right' : 'slide-left'}
              timeout={300}>
              <AnimateContainer>
                <MobilitySelectDateTime
                  date={date}
                  time={time}
                  focusedInput={focusedInput}
                  changeFocusedInput={changeFocusedInput}
                  onContinue={handleContinueButton}
                  onBack={() => {
                    setIsPickUpFocused(true)
                    setIsNextDirection(false)
                    handleChangeState(SelectionState.pickUp)
                  }}
                  onDateSelect={(result) => setDate(result)}
                  onTimeSelect={(result) => setTime(result)}
                />
              </AnimateContainer>
            </CSSTransition>
          )}
        </TransitionGroup>
      </TransitionContainer>
      <div id="mobility-select-location-modals-container" />
    </Modal>
  )
}
