import { useMeetingModeContext } from '@etta/modules/meeting/use-meeting-mode.context'
import { MeetingNotch } from '../../../components/meeting-notch'

type MeetingSwitchDesktopProps = {
  afterExit?: () => void
}

export const MeetingSwitchDesktop = ({ afterExit }: MeetingSwitchDesktopProps) => {
  const { meetingModeStore, meetingModeActions } = useMeetingModeContext()
  const { isMeetingModeDelegate, participantName, meetingName } = meetingModeStore
  const onExit = () => {
    meetingModeActions.dropStore()
    afterExit?.()
  }

  return (
    <>
      <MeetingNotch
        meetingName={meetingName ?? ''}
        participantName={participantName ?? ''}
        isMeetingModeDelegate={isMeetingModeDelegate}
        isVisible={meetingModeStore.isInMeetingMode}
        onExit={onExit}
      />
    </>
  )
}
