export default function SearchIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3807 31.3181C21.882 31.3181 24.226 30.5866 26.1767 29.3371L33.0671 36.0266C33.5233 36.4533 34.1054 36.6666 34.7347 36.6666C36.0404 36.6666 37 35.6762 37 34.4266C37 33.8476 36.7955 33.2838 36.355 32.8571L29.5118 26.1981C30.9276 24.2476 31.7614 21.8857 31.7614 19.3257C31.7614 12.7276 26.1924 7.33331 19.3807 7.33331C12.5847 7.33331 7 12.7276 7 19.3257C7 25.9238 12.569 31.3181 19.3807 31.3181ZM19.3807 28.1181C14.3938 28.1181 10.3036 24.1562 10.3036 19.3257C10.3036 14.4952 14.3938 10.5333 19.3807 10.5333C24.3676 10.5333 28.4578 14.4952 28.4578 19.3257C28.4578 24.1562 24.3676 28.1181 19.3807 28.1181Z"
        fill="currentColor"
      />
    </svg>
  )
}
