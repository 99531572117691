import styled from 'styled-components'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import { linkSmall, getKeyboardNavigationStyle } from '@fiji/style'
import { Icon } from '@etta/ui/icon'

export const CardWrapper = styled.button`
  position: relative;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin-bottom: 44px;
  border-radius: 14px;
  padding: 0;

  ${getKeyboardNavigationStyle({ offset: -2 })}

  &:after {
    content: '';
    position: absolute;
    bottom: -44px;
    left: 40px;
    height: 44px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.borderDark};
  }
  &:last-of-type {
    &:after {
      content: none;
    }
  }
`

export const CardHeader = styled.div`
  display: flex;
  padding: 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`

export const CardHeaderTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
`

export const ViewDetailsLink = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: ${({ theme }) => theme.colors.bodyText};
  white-space: nowrap;
  ${linkSmall};
`

export const CardTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const CardInfoBlock = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
`

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`

export const CardImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-basis: 100px;
  min-width: 100px;
`

export const CardImage = styled.img<{ isRailSegment?: boolean }>`
  max-width: ${(p) => (p.isRailSegment ? '32px' : '100%')};
  max-height: ${(p) => (p.isRailSegment ? '32px' : '100%')};
`

export const Footer = styled.div`
  padding: 0 24px 16px;
`

export const CardTitleText = styled(Text).attrs(() => ({
  variant: 'title2',
}))``

export const CardHeaderTitleDate = styled(Text).attrs(() => ({
  variant: 'captionStrongCaps',
  color: 'bodyText',
}))``

export const ShareButton = styled(Button).attrs((...p) => ({
  variant: 'link',
  minWidth: 0,
  paddings: false,
  ...p,
}))`
  margin-left: auto;
`

export const SubTitleText = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))``

export const Status = styled(Text).attrs(({ color }) => ({
  variant: 'captionStrong',
  color,
}))``

export const CardRow = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'bodyText',
}))`
  white-space: pre-line;
`

export const CancelationBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CancelationIcon = styled(Icon).attrs(() => ({
  name: 'infoFilledPWA',
  color: 'warning',
}))``

export const CanceleationText = styled(Text).attrs(() => ({
  variant: 'footnoteMedium',
  color: 'mainText1',
}))``
