import { useTranslation } from 'react-i18next'
import { DelegateName } from '@etta/components/delegate-name/delegate-name'
import { SearchTravelPolicyButton } from '@etta/components/search-travel-policy-button/travel-policy-button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Button } from '@etta/ui/button'
import type { LayoutProps } from '../types'
import { ContentHeader, ContentSubHeader } from './list-result-header-mobile-styled'

export function ListResultHeaderMobile({
  title,
  dataTrackingId,
  sortSlot,
  policyDialogOnOpen,
  taxesDialogOnOpen,
  isShowDelegateName,
  hasServiceFeeConfiguration,
  isTravelPolicyVisible,
}: LayoutProps) {
  const { t } = useTranslation()
  return (
    <>
      <ContentHeader>
        <Text
          as={'h2'}
          id={dataTrackingId || `search-header-title`}
          data-tracking-id={dataTrackingId || `search-header-title`}
          variant="title3"
          aria-label={title}>
          {title}
        </Text>
        {sortSlot && <Block marginLeft="auto">{sortSlot}</Block>}
      </ContentHeader>
      <ContentSubHeader>
        {isShowDelegateName && <DelegateName />}
        {isTravelPolicyVisible && (
          <SearchTravelPolicyButton
            isMobile
            onClick={policyDialogOnOpen}
            isDelegateMode={isShowDelegateName}
            dataTrackingId="travel-policy-button"
          />
        )}
        {hasServiceFeeConfiguration && (
          <Button
            aria-label={t('Accessibility.TapToViewWithLabel', {
              label: t('SearchSegments.TaxesAndFees'),
            })}
            data-tracking-id="taxes-and-fees-button"
            variant="text"
            size="small"
            paddings={false}
            onClick={taxesDialogOnOpen}>
            {t('SearchSegments.TaxesAndFees')}
          </Button>
        )}
      </ContentSubHeader>
    </>
  )
}
