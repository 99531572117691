import type { PWAIconProps } from './pwa-icon'
import { PWAIcon } from './pwa-icon'
import { pwaIcons } from './pwa-icon/icons'
import type { IconColorPalette, IconSizes, IconNames } from './types'

export type SvgIconProps = {
  className?: string
  size?: IconSizes
  color?: IconColorPalette
  name: IconNames
  'aria-label'?: string
}

const isPwaIcon = (props: SvgIconProps): props is PWAIconProps => {
  return props.name in pwaIcons
}

export function Icon(props: SvgIconProps) {
  if (isPwaIcon(props)) {
    return <PWAIcon {...props} />
  }

  if (process.env.NODE_ENV === 'development') {
    const { name } = props
    throw new Error(
      `Looks like you passed wrong icon name "${name}", please check usage components/icon/icon.tsx`,
    )
  }

  return null
}
