import { AirportChangeBadge } from '@etta/components/airport-change-badge'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { useFeatureFlags } from '@fiji/hooks/use-feature-flags'
import type { LayoutProps } from '../../types'
import { useSegmentCardStatus } from '../../use-segment-card-status'
import {
  Item,
  ItemContent,
  SectionTitle,
  Wrapper,
  DateLine,
  Status,
} from './segment-card-mobile-styled'

export function SegmentCardMobile({
  ariaLabel,
  titleSlot,
  isPast,
  onClick,
  segmentIcon,
  dateSlot,
  operatedByText,
  flightLegSegments,
  shouldShowAirportChangeBadge,
  segmentType,
  label,
  segmentStatus,
}: LayoutProps) {
  const { displayStatus, statusColor } = useSegmentCardStatus({ label, segmentStatus })
  const cardStatus = dateSlot ? `• ${displayStatus}` : displayStatus
  const { featureFlags } = useFeatureFlags()
  const { isNewTripDetailsPageEnabled } = featureFlags

  return (
    <Item
      role="button"
      aria-label={ariaLabel}
      onClick={onClick}
      data-tracking-id={`trip-${segmentType}-segment-card`}
      isRound={isNewTripDetailsPageEnabled}>
      <Wrapper>
        <Icon name={segmentIcon} size="medium" />
        <ItemContent>
          <SectionTitle>
            {titleSlot}
            {isPast && <Icon name="checkFilledPWA" size="small" color="bodyText1" />}
          </SectionTitle>
          <DateLine>
            {dateSlot}
            {displayStatus && <Status color={statusColor}>{cardStatus}</Status>}
          </DateLine>
          {operatedByText && (
            <Text variant="footnoteMedium" color="bodyText1">
              {operatedByText}
            </Text>
          )}
        </ItemContent>
      </Wrapper>
      {shouldShowAirportChangeBadge && !!flightLegSegments?.length && (
        <Block marginTop={8}>
          <AirportChangeBadge flightLegSegments={flightLegSegments} size="small" isMaxWidth />
        </Block>
      )}
    </Item>
  )
}
