import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { ROUTES } from '@fiji/routes'

export function useHeldTripsPage() {
  const history = useHistory()

  const handleGoBack = useCallback(() => {
    history.push(ROUTES.postBooking.trips())
  }, [history])

  return {
    handleGoBack,
  }
}
