import type { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButton } from './close-button-rounded-styled'

type Props = {
  top?: number
  right?: number
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export function CloseButtonRounded({ top, right, onClick }: Props) {
  const { t } = useTranslation()

  return (
    <CloseButton
      icon="closeFilledPWA"
      color="bodyText"
      onClick={onClick}
      size="medium"
      aria-label={t('Accessibility.Settings.CloseButton')}
      top={top}
      right={right}
    />
  )
}
