export default function LoungeIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.8146 5C36.2936 5 36.7259 5.29138 36.9096 5.73819C37.093 6.18556 36.9924 6.69954 36.6527 7.04214L23.1858 20.6277V34.1694L30.9891 37.9873C31.2104 38.0965 31.3287 38.3447 31.2727 38.5861C31.2181 38.8287 31.0053 39 30.7592 39H13.2412C12.9951 39 12.782 38.8287 12.7271 38.5861C12.6716 38.3447 12.79 38.0965 13.0106 37.9873L20.8145 34.1695V20.6277L7.34756 7.04214C7.00798 6.69954 6.90661 6.18556 7.09068 5.73819C7.27385 5.29138 7.70609 5 8.18582 5H35.8146ZM32.9522 7.39195H11.0475L13.749 10.1169C16.528 10.4273 19.5921 12.8617 22.3624 12.1609C24.8526 11.5309 27.1212 10.0678 29.8416 10.5302L32.9522 7.39195Z"
        fill="currentColor"
      />
    </svg>
  )
}
