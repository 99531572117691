import type { ReactElement, MouseEvent, PointerEvent } from 'react'
import { memo, useState, useEffect, useRef, Fragment, useCallback } from 'react'
import { createPortal } from 'react-dom'
import type { CalloutAppearStrategy } from '../../core/callout-appear-strategy'
import { ClickWrapper } from './apple-map-styled'
import { useAppleMapContext } from './apple-map.context'

type Props = {
  lat: number
  lng: number
  child: ReactElement
  instance: mapkit.Map
  calloutAppearStrategy?: CalloutAppearStrategy
}

const ANCHOR_CLASS = 'mapkit-anchor'
const CALLOUT_CLASS = 'mapkit-callout'

export const AppleMapMarker = memo(function ({
  lat,
  lng,
  child,
  instance,
  calloutAppearStrategy,
}: Props) {
  const { calloutsMap, setSelectedCallout } = useAppleMapContext()
  const calloutRef = useRef<HTMLDivElement | null>(null)
  const [marker, setMarker] = useState<mapkit.Annotation | null>(null)

  useEffect(() => {
    const coordinates = new mapkit.Coordinate(lat, lng)

    const calloutOptions: mapkit.AnnotationCalloutDelegate = {
      calloutShouldAppearForAnnotation: () => {
        return Boolean(child.key && calloutsMap.has(child.key))
      },
      calloutElementForAnnotation: () => {
        const anchorCallout = document.createElement('div')
        anchorCallout.className = CALLOUT_CLASS
        const element = calloutsMap.get(child.key!)!
        const portal = createPortal(<div ref={calloutRef}>{element}</div>, anchorCallout)
        setSelectedCallout(portal)
        return anchorCallout
      },
    }

    const marker = new mapkit.Annotation(
      coordinates,
      () => {
        const anchor = document.createElement('div')
        anchor.className = ANCHOR_CLASS
        return anchor
      },
      { callout: calloutOptions, size: { width: 0, height: 0 } },
    )
    instance.addAnnotation(marker)

    setMarker(marker)
  }, [lat, lng, child.key, calloutsMap, setSelectedCallout, instance])

  const handlePreventPropagation = useCallback((e: PointerEvent | MouseEvent) => {
    e.stopPropagation()
  }, [])

  const handleSelectMarker = () => {
    setTimeout(() => {
      if (marker) {
        marker.selected = true
      }
    })
  }

  useEffect(() => {
    return () => {
      if (marker && instance.element) {
        instance.removeAnnotation(marker)
      }
    }
  }, [marker, instance])

  if (!marker) {
    return null
  }

  const renderChild = () => {
    switch (calloutAppearStrategy) {
      case 'hover': {
        const handleMouseEnter = () => {
          if (marker) {
            marker.selected = true
          }
        }

        const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
          if (
            e.relatedTarget &&
            calloutRef.current &&
            calloutRef.current.contains(e.relatedTarget as Node)
          ) {
            return
          }

          if (marker) {
            marker.selected = false
          }
        }

        return (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {child}
          </div>
        )
      }

      case 'click': {
        return (
          <ClickWrapper
            onMouseDown={handlePreventPropagation}
            onMouseUp={handlePreventPropagation}
            onPointerDown={handlePreventPropagation}
            onPointerUp={handlePreventPropagation}
            onClick={handleSelectMarker}>
            {child}
          </ClickWrapper>
        )
      }

      default: {
        return <Fragment>{child}</Fragment>
      }
    }
  }

  return createPortal(renderChild(), marker.element)
})
