import type { Rate } from '@fiji/graphql/types'

type NullableRate = Rate | null | undefined

export function sumRate({
  totalRate,
  itemRate,
}: {
  totalRate: NullableRate
  itemRate: NullableRate
}): NullableRate {
  if (!totalRate || !itemRate) {
    return totalRate || itemRate
  }

  const secondary = totalRate.secondary
    ? {
        amount: (totalRate.secondary.amount || 0) + (itemRate?.secondary?.amount || 0),
        currency: totalRate.secondary.currency,
      }
    : undefined
  return {
    primary: {
      amount: totalRate.primary.amount + (itemRate?.primary.amount || 0),
      currency: totalRate.primary.currency,
    },
    secondary,
  }
}

export function sumRates(rates: NullableRate[]): NullableRate {
  return rates.reduce<NullableRate>(
    (total, rate) => sumRate({ totalRate: total, itemRate: rate }),
    undefined,
  )
}
