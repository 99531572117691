import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useA11yKeydown } from '@fiji/hooks/use-a11y-keydown'
import { useTogglePopup } from '@fiji/hooks/use-toggle-popup'
import type { SuperBrandFilter } from '@fiji/graphql/types'
import { useBrandChange } from '../use-brand-change'

type Args = {
  isSuperBrandExpanded?: boolean
  superBrand: SuperBrandFilter
  selectedIdsMap: Set<string>
  onSelect: (idsMap: Set<string>) => void
}

export function useSuperBrandSelect({
  selectedIdsMap,
  onSelect,
  superBrand,
  isSuperBrandExpanded = false,
}: Args) {
  const brandsToggle = useTogglePopup(isSuperBrandExpanded)
  const expandedRef = useRef(isSuperBrandExpanded)
  const isExpandedChanged = expandedRef.current === isSuperBrandExpanded
  expandedRef.current = isSuperBrandExpanded
  useEffect(() => {
    if (isExpandedChanged) {
      return
    }

    if (isSuperBrandExpanded) {
      brandsToggle.handleOpen()
    }
    if (!isSuperBrandExpanded) {
      brandsToggle.handleClose()
    }
  }, [brandsToggle, isSuperBrandExpanded, isExpandedChanged])

  const superBrandId = superBrand.brandId
  const brandsIdsMap = useMemo<Set<string>>(() => {
    return new Set(superBrand.brands.map(({ brandId }) => brandId))
  }, [superBrand])

  const selectedBrandsLength = useMemo(() => {
    let count = 0
    superBrand.brands.forEach(({ brandId }) => {
      if (selectedIdsMap.has(brandId)) {
        count++
      }
    })
    return count
  }, [selectedIdsMap, superBrand.brands])

  const isAllSelected = useMemo(() => {
    return superBrand.brands.every(({ brandId }) => selectedIdsMap.has(brandId))
  }, [selectedIdsMap, superBrand])

  const isMixedSelected = useMemo(() => {
    if (isAllSelected) {
      return false
    }
    return superBrand.brands.some(({ brandId }) => selectedIdsMap.has(brandId))
  }, [selectedIdsMap, superBrand, isAllSelected])

  const onSuperBrandChange = useCallback(() => {
    if (isAllSelected || isMixedSelected) {
      // deselect all brands
      const extractedSet = new Set([...Array.from(selectedIdsMap)])
      brandsIdsMap.forEach((node) => {
        extractedSet.delete(node)
      })

      onSelect(extractedSet)
      return
    }

    // select all brands
    const mergedSet = new Set([...Array.from(selectedIdsMap), ...Array.from(brandsIdsMap)])
    onSelect(mergedSet)
  }, [isMixedSelected, isAllSelected, selectedIdsMap, brandsIdsMap, onSelect])

  const onBrandChange = useBrandChange({ selectedIdsMap, onSelect })
  const { handleKeyDown } = useA11yKeydown(brandsToggle.handleToggle)

  return {
    superBrandId,
    isAllSelected,
    isMixedSelected,
    brandsToggle,
    selectedBrandsLength,
    onSuperBrandChange,
    onBrandChange,
    handleKeyDown,
  }
}
