import styled from 'styled-components'
import { Text } from '@etta/ui/text'

export const UnusedTicket = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 8px 0;
`

export const UnusedTicketAppliedLabel = styled(Text).attrs({
  variant: 'footnoteMedium',
  color: 'bodyText',
})`
  border-bottom: dashed 1px;
`
