import { useEffect, useState } from 'react'
import type { Place } from '@fiji/types'
import { PlaceAutocompleteType, PlacesSearchType } from '@fiji/graphql/types'
import { useLocationAutocomplete } from '@fiji/hooks/use-location-autocomplete/use-location-autocomplete'

type Args = {
  stationName?: string
}

export function useGetPlaceByStationName({ stationName }: Args) {
  const [places, setPlaces] = useState<Place[]>([])
  const setFilteredPlaces = (places: Place[]) => {
    const algoliaPlaces = places.filter(
      (place) => place?.placeType === PlaceAutocompleteType.AlgoliaPlaces,
    )
    setPlaces(algoliaPlaces)
  }

  const { handleFindPlaces } = useLocationAutocomplete({
    onComplete: setFilteredPlaces,
    useNewService: true,
  })

  useEffect(
    () => handleFindPlaces({ place: stationName, searchType: PlacesSearchType.TrainSearch }),
    [handleFindPlaces, stationName],
  )

  return {
    place: places[0],
  }
}
