import styled from 'styled-components'
import { Dialog as EttaDialog } from '@etta/ui/dialog'

export const Dialog = styled(EttaDialog)`
  padding: 20px;
  max-width: 345px;
  text-align: center;
`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
