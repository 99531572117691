import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useReviewTripContext } from '@etta/modules/review-trip/interface/use-review-trip.context'
import type { ViewState } from '@etta/ui/view-state-modal'
import { useSegmentSearchContext } from '@etta/modules/segment-search'
import { useFeatureFlagsContext } from '@etta/modules/feature-flags'
import { ROUTES } from '@fiji/routes'

type Params = {
  isActive?: boolean
  isEmptyItinerary?: boolean
  onExpire?: () => void
}

export function useTripExpirationTimer({
  isActive = false,
  onExpire,
}: Params) {
  const [isExpiredTrip, setIsExpiredTrip] = useState(false)
  const [viewState, setViewState] = useState<ViewState>('hidden')

  const { reloadSegmentSearchActions } = useSegmentSearchContext()

  const {
    tripActions,
    reviewTripStore,
    activeTripActions
  } = useReviewTripContext()
  const { removeTrip } = tripActions
  const { featureFlagsStore } = useFeatureFlagsContext()
  const { flags } = featureFlagsStore
  const { removeActiveTrip } = activeTripActions
  const { isTripRemovingLoading } = reviewTripStore
  const history = useHistory()

  const handleExpireEvent = useCallback(() => {
    setIsExpiredTrip(true)
    onExpire?.()
  }, [onExpire])

  async function handleDeleteTrip() {
    setViewState('loading')
    isActive ? removeActiveTrip() : removeTrip()
    setViewState('hidden')
    if (flags.isActiveItineraryPurchaseFlowEnabled) {
      await reloadSegmentSearchActions.reloadCurrentSegmentSearch()
      return
    }
    history.push(ROUTES.explore)
  }

  return {
    isExpiredTrip,
    isTripRemovingLoading,
    handleDeleteTrip,
    viewState,
    handleExpireEvent
  }
}
