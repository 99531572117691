import { Module } from '@etta/di'
import { reportWebVitalsToRaygun } from '@fiji/utils/raygun/report-web-vitals'
import { renderRoot } from './ui/render-root'

@Module()
export class AppModule {
  start() {
    reportWebVitalsToRaygun()
    renderRoot()
  }

  // declare all other external apis
}
