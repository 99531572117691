import { useCallback, useMemo, useState, useEffect } from 'react'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import type { Delegate } from '@fiji/graphql/hooks'
import { useAppDispatch } from '@fiji/store'
import { resetMainNavigation } from '@fiji/store/main-navigation'
import { usePostBookingContext } from '@etta/modules/post-booking'
import { useUserContext } from '@etta/modules/user'
import type { HookProps } from '../types'
import { useDelegateConfirmationDialog } from './use-delegate-confirmation-dialog'

export function useDelegateModal({ delegates, handleSelectDelegate, handleClose }: HookProps) {
  const dispatch = useAppDispatch()

  const { anchorRef, scrollContainerRef } = useHeaderScroll()
  const { userStore } = useUserContext()
  const { customerId, delegatedId } = userStore
  const [delegateFilter, setDelegateFilter] = useState('')
  const [choosenDelegateId, setChoosenDelegateId] = useState(
    Number(delegatedId) || Number(customerId),
  )
  const { postBookingTripPreviewActions } = usePostBookingContext()

  const setDefaultDelegateId = useCallback(() => {
    setChoosenDelegateId(Number(delegatedId) || Number(customerId))
  }, [customerId, delegatedId])

  useEffect(() => {
    setDefaultDelegateId()
  }, [delegatedId, customerId, setDefaultDelegateId])

  const filteredDelegates: Delegate[] = useMemo(() => {
    return delegates.filter((user) => {
      if (delegateFilter) {
        const filter = delegateFilter.toLowerCase()
        return (
          user.firstName?.toLowerCase().includes(filter) ||
          user.lastName?.toLowerCase().includes(filter)
        )
      }

      return user
    })
  }, [delegateFilter, delegates])

  const handleDelegatesFilter = useCallback((query: string) => {
    setDelegateFilter(query)
  }, [])

  const handlePreSelect = (userId: string) => {
    setChoosenDelegateId(Number(userId))
  }

  const handleSelect = async () => {
    setDelegateFilter('')
    setDefaultDelegateId()
    await handleSelectDelegate(choosenDelegateId)
  }

  const handleDelegatesModalClose = () => {
    setDefaultDelegateId()
    handleClose()
  }

  const handleConfirm = async () => {
    if (!choosenDelegateId) {
      return
    }
    if (isOngoingBookingFlow) {
      confirmationDialog.handleOpen()
      return
    }
    dispatch(resetMainNavigation())
    postBookingTripPreviewActions.resetTrip()
    await handleSelect()
  }

  const isConfirmUserButtonDisabled = useMemo(
    () => (!delegatedId && choosenDelegateId === customerId) || choosenDelegateId === delegatedId,
    [choosenDelegateId, customerId, delegatedId],
  )

  const { confirmationDialog, isOngoingBookingFlow } = useDelegateConfirmationDialog({
    handleSelect,
  })

  return {
    anchorRef,
    scrollContainerRef,
    delegateFilter,
    filteredDelegates,
    handleDelegatesFilter,
    handleDelegatesModalClose,
    handleConfirm,
    handlePreSelect,
    isConfirmUserButtonDisabled,
    choosenDelegateId,
    confirmationDialog,
    handleClose,
  }
}
