import { ModalDetails } from '@etta/components/modal-details'
import type { LayoutProps } from '../../types'
import { DelegateModalDesktopDetails } from './delegate-modal-desktop-details'

export function DelegateModalDesktop({ isModalVisible, handleClose, ...props }: LayoutProps) {
  return (
    <ModalDetails
      horizontalDimension="content-760"
      isAdaptivePosition
      isVisible={isModalVisible}
      onClose={handleClose}>
      <DelegateModalDesktopDetails handleClose={handleClose} {...props} />
    </ModalDetails>
  )
}
