import { Inject, Service } from '@etta/di'
import { NavigationAdapter } from '@etta/modules/navigation/infra/navigation.adapter'
import { RedirectUrl } from '../../core/enums'

@Service()
export class NavigationService {
  constructor(
    @Inject()
    private navigationAdapter: NavigationAdapter,
  ) {}

  async handleSetRcSessionCookie(url: string, isEttaGoAdmin?: boolean, redirectTo?: RedirectUrl) {
    const fullUrl = url.startsWith('http') ? url : `https://${url}`
    const apolloClientUrl = `${fullUrl}/app/graphql`

    await this.navigationAdapter.setRcSessionCookie({ url: apolloClientUrl })

    if (isEttaGoAdmin) {
      const adminPanelUrl = `${fullUrl}/rc/sitedashboard/sitedashboard.do`
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(adminPanelUrl)
      return
    }

    const newUrl = this.getGazooUrl(fullUrl, redirectTo)
    window.location.href = newUrl
  }

  private getGazooUrl(fullUrl: string, redirectTo = RedirectUrl.GAZOO_TRIP_PLANNER_URL) {
    return `${fullUrl}${redirectTo}`
  }
}
