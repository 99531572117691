import styled, { css } from 'styled-components'
import { footnoteMedium, footnoteStrong, KeyboardNavigationStyle, title3 } from '@fiji/style'

import type { LayoutBackgroundColor } from '@etta/ui/layout'
import { getLayoutBackgroundColor } from '@etta/ui/layout'
import { ScreenType } from '@fiji/modes'

export const Container = styled.div`
  margin: 5px 10px;
  ${KeyboardNavigationStyle}
`

export const FlightDetailsContainer = styled.div``

export const BlockContainer = styled.div`
  ${KeyboardNavigationStyle}
`
export const FlexWrapper = styled(BlockContainer)`
  display: flex;
`

export const Timeline = styled.div`
  display: flex;
  width: 100%;
`

export const TimelineSolidLine = styled.div`
  height: 100%;
  border-left: 2px solid ${(props) => props.theme.colors.bodyText};
`

export const TimelineDashedLine = styled.div`
  height: 100%;
  margin: 3px 0;
  border-left: 1px dashed #d1d1d6;
`

export const TechnicalStopDashed = styled.div`
  height: 100%;
  margin: 3px 0;
  border-left: 1px dashed ${(props) => props.theme.colors.borderLight};
`

export const AirportCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AirportCode = styled.div`
  min-width: 32px;
  padding: 1px 1px;
  background: ${(props) => props.theme.colors.bodyText};
  border-radius: 4px;
  ${footnoteStrong};
  line-height: 19px;
  text-align: center;
  color: #fff;
`

export const TimelineBlock = styled.div`
  width: 100%;
  margin-left: 12px;
`

export const Time = styled.div`
  ${title3};
  line-height: 25px;
  color: ${(props) => props.theme.colors.mainText};
`

export const Date = styled.div`
  ${footnoteMedium};
  line-height: 20px;
  color: ${(props) => props.theme.colors.mainText};
`

export const ArrivesIn = styled.span`
  ${footnoteMedium};
  line-height: 20px;
  color: ${(props) => props.theme.colors.error};
`

export const AirportWithTerminal = styled.div`
  margin-top: 3px;
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Terminal = styled.span`
  white-space: nowrap;
`

export const FlightBlock = styled(BlockContainer)`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: 6px;
`

export const AirlineLogoWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 3px;
  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export const FlightNumber = styled.div`
  ${footnoteMedium};
  font-weight: 600;
  line-height: 19px;
  color: ${(props) => props.theme.colors.mainText};
  white-space: nowrap;
`

export const FlightAircraft = styled.div`
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const FlightTime = styled.div`
  margin-top: 3px;
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Amenities = styled.div<{ screenType?: ScreenType }>`
  display: flex;
  max-width: fit-content;
  max-height: 32px;
  margin: 3px 0 5px 0;

  ${(p) => {
    switch (p.screenType) {
      case ScreenType.Desktop:
        return css`
          border-radius: 100px;
          background-color: ${p.theme.colors.background};
          padding: 4px 8px;
        `
      default:
        return ''
    }
  }}
`

export const RowWrapper = styled.div<{ screenType?: ScreenType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) => {
    switch (p.screenType) {
      case ScreenType.Mobile:
        return css`
          flex-direction: column;
          align-items: flex-start;
        `
      default:
        return css`
          flex-direction: row;
        `
    }
  }}
`

export const AmenityIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 0 1px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-left: 0;
  }
`

export const OperatedBy = styled.div`
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`

export const Divider = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
`

export const ClockIconWrapper = styled.div<{ backgroundColor?: LayoutBackgroundColor }>`
  position: absolute;
  top: -6px;
  left: -42px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${(props) => getLayoutBackgroundColor(props.theme, props.backgroundColor)};
`

export const LayoverWrapper = styled.div`
  position: relative;
  margin: 30px 0 40px;
  ${footnoteMedium};
  line-height: 19px;
  color: ${(props) => props.theme.colors.bodyText};
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const Ellipse = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.borderLight};
`

export const EllipseContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 32px;
`
