import type { Props } from '../../types'
import { Cost, Price } from './mobile-styled'

export function CarPriceBlockMobile({ priceAriaLabel, costLabel, perDayLabel }: Props) {
  return (
    <Price aria-label={priceAriaLabel}>
      <Cost>{costLabel}</Cost>
      {perDayLabel}
    </Price>
  )
}
