import styled from 'styled-components'
import { UnstyledButton } from '@fiji/style'

export const Container = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  background: ${(p) => p.theme.colors.background};
  padding-bottom: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
`

export const Button = styled(UnstyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MiniPill = styled.div`
  background: ${(p) => p.theme.colors.borderDark};
  border-radius: 50rem;
  height: 4px;
  width: 42px;
  margin-top: 6px;
`
