import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SegmentType } from '@etta/core/enums'
import { useRideHailSearchContext } from '@etta/modules/ride-hail/interface/use-ride-hail-search-context'
import { Swap } from '@etta/ui/swap'
import { Portal } from '@etta/ui/portal'
import { DialogAnimation } from '@etta/ui/dialog/dialog-animation'
import {
  Header,
  HeaderSection,
  SegmentSection,
  Container,
  BackDrop,
  DialogContainerRoot,
  DialogContainer,
} from './mobility-itinerary-dialog-styled'
import {
  MobilityItineraryFlightSegment,
  MobilityItineraryHotelSegment,
  MobilityItineraryTrainSegment,
  MobilityItineraryRideHailSegment,
  MobilityItineraryCarRentalSegment,
  MobilityItineraryCarServiceSegment,
} from './mobility-itinerary-segment'
import { MobilityItinerarySkeleton } from './mobility-itinerary-skeleton/mobility-itinerary-skeleton'

type Prop = {
  isOpen: boolean
  onClose: () => void
}

const i18nBase = 'Mobility.ItineraryDialog'

export const MobilityItineraryDialog = observer(function MobilityItineraryDialog({
  isOpen,
  onClose,
}: Prop) {
  const { t } = useTranslation()
  const {
    rideHailSearchItineraryActions,
    rideHailSearchItineraryStore,
  } = useRideHailSearchContext()

  const segments = rideHailSearchItineraryStore.itinerarySegments
  const isLoading = rideHailSearchItineraryStore.isLoading

  const [isShownDialog, setIsShownDialog] = useState(false)

  useEffect(() => {
    rideHailSearchItineraryActions.getTripDetails()
  }, [rideHailSearchItineraryActions])

  return (
    <Portal target="#mobility-select-location-modals-container">
      <Container isShown={isShownDialog || isOpen} role={'dialog'}>
        <BackDrop aria-hidden onClick={onClose} />
        <DialogAnimation
          isOpen={isOpen}
          onEntered={() => setIsShownDialog(true)}
          onExited={() => setIsShownDialog(false)}
          isAnimated>
          <DialogContainerRoot>
            <DialogContainer>
              <HeaderSection>
                <Header>{t(`${i18nBase}.Title`)}</Header>
              </HeaderSection>
              <Swap
                is={isLoading}
                isSlot={
                  <SegmentSection>
                    <MobilityItinerarySkeleton />
                    <MobilityItinerarySkeleton />
                  </SegmentSection>
                }></Swap>
              <SegmentSection>
                {segments.map((segment) => {
                  switch (segment.type) {
                    case SegmentType.Flight:
                      return <MobilityItineraryFlightSegment segment={segment} />
                    case SegmentType.Hotel:
                      return <MobilityItineraryHotelSegment segment={segment} />
                    case SegmentType.RideHail:
                      return <MobilityItineraryRideHailSegment segment={segment} />
                    case SegmentType.Train:
                      return <MobilityItineraryTrainSegment segment={segment} />
                    case SegmentType.CarRental:
                      return <MobilityItineraryCarRentalSegment segment={segment} />
                    case SegmentType.CarService:
                      return <MobilityItineraryCarServiceSegment segment={segment} />
                  }
                })}
              </SegmentSection>
            </DialogContainer>
          </DialogContainerRoot>
        </DialogAnimation>
      </Container>
    </Portal>
  )
})
