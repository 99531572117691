import styled, { css } from 'styled-components'
import { Text } from '@etta/ui/text'
import { KeyboardNavigationStyle } from '@fiji/style'

export const Wrapper = styled.div<{ hasNewLayout: boolean }>`
  ${(props) =>
    props.hasNewLayout &&
    css`
      padding: 10px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
    `}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.div<{ isExpanded: boolean }>`
  padding-left: 30px;
  display: ${(props) => (props.isExpanded ? ' block' : 'none')};
`

export const NestedHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ControlButton = styled.div<{ isOpen: boolean }>`
  width: 8px;
  height: 8px;
  border-right: 1px solid ${(props) => props.theme.colors.bodyText};
  border-top: 1px solid ${(props) => props.theme.colors.bodyText};
  transform: rotate(135deg);
  transition: rotate ease 0.2s;
  margin-top: -5px;
  cursor: pointer;
  user-select: none;
  margin-left: 13px;

  &:hover {
    opacity: 0.85;
  }

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(-45deg);
      margin-top: 3px;
    `}

  ${KeyboardNavigationStyle}
`

export const SelectedBrandsCount = styled(Text)`
  padding: 4px 5px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.primary1};
`
