import type { Coords } from '@etta/modules/etta-map/core/coords'
import { EttaMap } from '@etta/modules/etta-map/ui/etta-map'
import { Container } from './car-rental-details-map-components'
import { CarMarker } from './car-marker'

type Props = {
  carCoords: Coords
  isTopRounded?: boolean
  isBottomRounded?: boolean
  fullHeight?: boolean
}

export function CarRentalDetailsMap({
  carCoords,
  isTopRounded = true,
  isBottomRounded = true,
  fullHeight,
}: Props) {
  return (
    <Container
      aria-hidden
      data-tracking-id="map"
      isTopRounded={isTopRounded}
      isBottomRounded={isBottomRounded}
      fullHeight={fullHeight}>
      <EttaMap
        center={carCoords}
        options={{ scaleControl: true, draggableCursor: 'pointer', draggable: false }}>
        <EttaMap.Marker lat={carCoords.lat} lng={carCoords.lng}>
          <CarMarker />
        </EttaMap.Marker>
      </EttaMap>
    </Container>
  )
}
