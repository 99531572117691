import type { PropsWithChildren } from 'react'
import { MediaLayout } from '@etta/ui/media-layout'
import type { ModalProps } from '../types'
import { CovidInformationModalLayoutDesktop } from './desktop'
import { CovidInformationModalLayoutMobile } from './mobile'

export function CovidInformationModalLayout({
  isOpen,
  onClose,
  children,
}: PropsWithChildren<ModalProps>) {
  return (
    <MediaLayout
      mobileSlot={
        <CovidInformationModalLayoutMobile isOpen={isOpen} onClose={onClose}>
          {children}
        </CovidInformationModalLayoutMobile>
      }
      desktopSlot={
        <CovidInformationModalLayoutDesktop isOpen={isOpen} onClose={onClose}>
          {children}
        </CovidInformationModalLayoutDesktop>
      }
    />
  )
}
