import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TripStatus } from '@fiji/graphql/types'
import type { TextColor } from '@etta/ui/text/types'

type Params = {
  tripStatus: TripStatus
}

type StatusDetails = {
  statusTitle: string
  statusColor: TextColor
}

const i18nBase = 'PostBooking.TripDetails'
const EMPTY_RESULT = { statusTitle: '', statusColor: 'warning' } as StatusDetails

export function useStatusDetails({ tripStatus }: Params): StatusDetails {
  const { t } = useTranslation()

  const statusTitleText = useMemo(() => {
    const tripStatusKeys = Object.keys(TripStatus)
    const tripStatusValues = Object.values(TripStatus)
    const tripStatusIndex = tripStatusValues.indexOf(tripStatus)

    return tripStatusKeys[tripStatusIndex] || ''
  }, [tripStatus])

  const statusTitle = statusTitleText && t(`${i18nBase}.${statusTitleText}`)

  switch (tripStatus) {
    case TripStatus.Unknown:
      return EMPTY_RESULT
    case TripStatus.Confirmed:
      return { statusTitle, statusColor: 'success' }
    default:
      return { statusTitle, statusColor: 'warning' }
  }
}
