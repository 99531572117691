import type { AppState } from '@fiji/store'

export function mainNavigationSupportSelector(state: AppState) {
  return state.mainNavigation.support
}

export function mainNavigationExploreSelector(state: AppState) {
  return state.mainNavigation.explore
}

export function mainNavigationTripsSelector(state: AppState) {
  return state.mainNavigation.trips
}

export function mainNavigationProfileSelector(state: AppState) {
  return state.mainNavigation.profile
}

export function mainNavigationSelector(state: AppState) {
  return state.mainNavigation
}

export function mainNavigationOngoingBookingFlowSelector(state: AppState) {
  return state.mainNavigation.isOngoingBookingFlowActive
}

export function mainNavigationIsTripsForceUpdateEnabledSelector(state: AppState) {
  return state.mainNavigation.isTripsForceUpdateEnabled
}
