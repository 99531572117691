import { useTranslation } from 'react-i18next'
import type { EmissionsDeviationLevel } from '@fiji/enums'
import {
  UpperBody,
  SustainabilityInformation,
  SustainabilityInformationTitle,
  SustainabilityRow,
  SustainabilityDescriptionRow,
  SustainabilityBar,
  SustainabilityBarContainer,
  SustainabilityScoreDescription,
  SustainabilityScore,
  SustainabilityOutOfHundredScore,
  OutOfHundred,
} from '../hotel-details-eco-check-modal-mobile-styled'

type Props = {
  sustainabilityScore: number
  averageSustainabilityScore: number
  deviationLevel: EmissionsDeviationLevel
}
export function HotelDetailsEcoCheckModalMobileUpperBody({
  sustainabilityScore,
  deviationLevel,
  averageSustainabilityScore,
}: Props) {
  const { t } = useTranslation()
  const i18nBase = 'Emissions.DetailsModal'

  return (
    <UpperBody>
      <SustainabilityInformation>
        <SustainabilityInformationTitle aria-label={t(i18nBase + '.SustainabilityScore')}>
          {t(i18nBase + '.SustainabilityScore')}
        </SustainabilityInformationTitle>

        <SustainabilityRow>
          <SustainabilityDescriptionRow>
            <SustainabilityScoreDescription>
              {t(i18nBase + '.ThisHotel')}
            </SustainabilityScoreDescription>
            <SustainabilityOutOfHundredScore
              deviationLevel={deviationLevel}
              aria-label={t('Accessibility.EcoCheck.OutOFHundred', {
                score: sustainabilityScore,
              })}>
              <SustainabilityScore>{Math.round(sustainabilityScore)}/</SustainabilityScore>
              <OutOfHundred>100</OutOfHundred>
            </SustainabilityOutOfHundredScore>
          </SustainabilityDescriptionRow>
          <SustainabilityBarContainer>
            <SustainabilityBar
              deviationLevel={deviationLevel}
              sustainabilityScore={sustainabilityScore}
            />
          </SustainabilityBarContainer>
        </SustainabilityRow>
        <SustainabilityRow>
          <SustainabilityDescriptionRow>
            <SustainabilityScoreDescription>
              {t(i18nBase + '.AverageForThisLocation')}
            </SustainabilityScoreDescription>
            <SustainabilityOutOfHundredScore
              deviationLevel={averageSustainabilityScore}
              aria-label={t('Accessibility.EcoCheck.OutOFHundred', {
                score: Math.round(averageSustainabilityScore),
              })}>
              <SustainabilityScore>{Math.round(averageSustainabilityScore)}/</SustainabilityScore>
              <OutOfHundred>100</OutOfHundred>
            </SustainabilityOutOfHundredScore>
          </SustainabilityDescriptionRow>
          <SustainabilityBarContainer>
            <SustainabilityBar sustainabilityScore={averageSustainabilityScore} />
          </SustainabilityBarContainer>
        </SustainabilityRow>
      </SustainabilityInformation>
    </UpperBody>
  )
}
