import { useTranslation } from 'react-i18next'
import type { BaggageInfoSegment } from '@fiji/types'
import type { FlightLegSegment } from '@fiji/graphql/types'
import { useFlightDetailsFares } from './use-flight-details-fares'
import { useFareAttributes } from './branded-fare-details'
import { FlightDetailsFaresLayout } from './layout'

type Props = {
  flight: FlightLegSegment
  onBaggageModalOpen: () => void
  onSeatMapOpen: () => void
  baggageInfo?: BaggageInfoSegment[]
}

const i18Base = 'FlightDetailsModal.FaresDetails.'

export function FlightDetailsFares({
  flight,
  onBaggageModalOpen,
  onSeatMapOpen,
  baggageInfo,
}: Props) {
  const { t } = useTranslation()

  const { cabinClassLabel, flightSeats, isSeatMapAvailable } = useFlightDetailsFares({ flight })
  const { filteredFareAttributes, classType } = useFareAttributes({ flight })

  const seatButtonLabel = t(i18Base + (flightSeats ? 'ChangeSeatsButton' : 'SelectSeatsButton'))
  const baggageButtonLabel = t(i18Base + 'BaggageInformationButton')

  return (
    <FlightDetailsFaresLayout
      flight={flight}
      fareAttributes={filteredFareAttributes}
      classType={classType}
      cabinClassLabel={cabinClassLabel}
      flightSeatsTitle={flightSeats}
      seatButtonLabel={seatButtonLabel}
      baggageButtonLabel={baggageButtonLabel}
      isSeatMapAvailable={isSeatMapAvailable}
      onSeatMapOpen={onSeatMapOpen}
      onBaggageModalOpen={onBaggageModalOpen}
      baggageInfo={baggageInfo}
    />
  )
}
