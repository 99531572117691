import { useTranslation } from 'react-i18next'
import { Block } from '@etta/ui/block'
import { Button } from '@etta/ui/button'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import { Swap } from '@etta/ui/swap'
import type { LayoutProps } from '../../types'
import { ButtonWrapper, TextWrapper, Content } from './state-error-desktop-styled'

export function StateErrorDesktop({ title, text, onClick }: LayoutProps) {
  const { t } = useTranslation()

  return (
    <>
      <Block marginTop={7}>
        <Icon name="errorPWA" color="error" size="large" />
      </Block>
      <Content>
        <Text variant="subHeadStrong" color="mainText" align="center">
          <Swap is={!!title} isSlot={title}>
            <div>{t('ViewStateModal.Oops')}</div>
            <div>{t('ViewStateModal.TryAgain')}</div>
          </Swap>
        </Text>
        {!!text && (
          <Block marginTop={24}>
            <Text variant="footnoteMedium" color="bodyText" align="center">
              {text}
            </Text>
          </Block>
        )}
      </Content>
      {onClick && (
        <ButtonWrapper>
          <Button onClick={onClick} fullWidth>
            <TextWrapper>{t('ViewStateModal.Ok')}</TextWrapper>
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}
