import { Inject, Container, Store } from '@etta/di'
import { FijiStoreProxyService } from '@etta/interface/services/fiji-store-proxy.service'
import {
  updateBrandConfigurationLoading,
  updateBrandConfiguration,
} from '@fiji/store/brand-configuration'
import type { BrandConfigurationValueObject } from '../../core/value-object/brand-configration.value-object'

@Store()
export class BrandConfigurationStore {
  constructor(
    @Inject()
    private readonly fijiStoreProxy: FijiStoreProxyService,
  ) {}

  isLoading: boolean = false

  isLoaded: boolean = false

  isError: boolean = false

  configuration: BrandConfigurationValueObject = {
    isThemeEnabled: true,
    theme: 'etta',
    brandTitleLabel: '',
    partnerLogoUrl: '',
    termsOfUseLinkEnabled: false,
  }

  get theme() {
    return this.configuration.theme
  }

  get isAppleTheme() {
    return this.theme === 'kan'
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
    this.fijiStoreProxy.dispatch(updateBrandConfigurationLoading({ isLoading: value }))
  }

  setIsLoaded(value: boolean) {
    this.isLoaded = value
  }

  setIsError(value: boolean) {
    this.isError = value
  }

  setConfiguration(config: BrandConfigurationValueObject) {
    this.configuration = config
    this.fijiStoreProxy.dispatch(updateBrandConfiguration(config))
  }

  static getInstance() {
    return Container.get(BrandConfigurationStore)
  }
}
