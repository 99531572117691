import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Headline, Content, Container } from './driver-instructions-styled'

const i18nBase = 'CarServiceDetails.PickUpAndDropOff.DriverInstructions'

type Props = {
  pickUpInstructions?: string | null
  dropOffInstructions?: string | null
}

type InstructionProps = {
  translationKey: string
  description?: string | null
}

function Instruction({ translationKey, description }: InstructionProps) {
  const { t } = useTranslation()

  if (!description) {
    return null
  }

  return (
    <Block marginTop={16}>
      <Text variant="captionStrongCaps" color="mainText">
        {t(`${i18nBase}.${translationKey}`)}
      </Text>
      <Block marginVertical={5}>
        <Text variant="footnoteMedium" color="mainText">
          {description}
        </Text>
      </Block>
    </Block>
  )
}

export function DriverInstructions({ pickUpInstructions, dropOffInstructions }: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()

  const toggleExpand = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  if (!pickUpInstructions && !dropOffInstructions) {
    return null
  }

  return (
    <Container onClick={toggleExpand}>
      <Headline>
        <Text variant="footnoteStrong" color="mainText">
          {t(`${i18nBase}.Title`)}
        </Text>
        <Block>
          <Icon name={isExpanded ? 'chevronUpPWA' : 'chevronDownPWA'} />
        </Block>
      </Headline>
      <Content isExpanded={isExpanded}>
        <Block paddingBottom={16} paddingHorizontal={16}>
          <Instruction translationKey="PickUp" description={pickUpInstructions} />
          <Instruction translationKey="DropOff" description={dropOffInstructions} />
        </Block>
      </Content>
    </Container>
  )
}
