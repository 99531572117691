import { DataProvider } from '@etta/di'
import type { GetUnusedTicketsQuery, GetUnusedTicketsQueryVariables } from '@fiji/graphql/hooks'
import { GetUnusedTicketsDocument } from '@fiji/graphql/hooks'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'

@DataProvider()
export class UnusedTicketProvider extends GraphqlDataProvider {
  getUnusedTickets(forceUpdate: boolean = false) {
    return this.client.query<GetUnusedTicketsQuery, GetUnusedTicketsQueryVariables>({
      query: GetUnusedTicketsDocument,
      fetchPolicy: forceUpdate ? 'network-only' : 'cache-first',
    })
  }
}
