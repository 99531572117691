import type { PropsWithChildren } from 'react'
import { ServiceSearchLayout } from '@etta/components/service-search-layout'
import { SegmentType } from '@fiji/graphql/hooks'

type Props = PropsWithChildren<{
  onBack: () => void
}>

export function RailSearchPageDesktop({ children, onBack }: Props) {
  return (
    <ServiceSearchLayout onBack={onBack} type={SegmentType.Flight} isNewLayout>
      {children}
    </ServiceSearchLayout>
  )
}
