import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { SeatReservationType } from '@fiji/graphql/types'
import type { TrainSegmentEntity } from '@etta/modules/review-trip/core'
import { Container, DetailColumn, DetailRow } from './rail-travel-information-styled'

export type Props = {
  rail: TrainSegmentEntity
  seatReservationType: SeatReservationType
  isRailEticket: boolean
  isSeatRequested: boolean
  appliedRailCard: string
}

const i18nBase = 'RailDetailsModal.FaresDetails'
const NO_REFUNDS_FARE = 'No refunds'

export function RailTravelInformation({
  rail,
  seatReservationType,
  isRailEticket,
  isSeatRequested,
  appliedRailCard,
}: Props) {
  const { t } = useTranslation()
  const { fareConditions } = rail
  const fareAttributes: string[] =
    fareConditions?.localizedFareConditions[0]?.summaryConditions.split('.').filter(Boolean) || []

  const isRefundable = fareAttributes.findIndex((item) => item.trim() === NO_REFUNDS_FARE) === -1

  const seatPreferencesText = () => {
    switch (seatReservationType) {
      case SeatReservationType.None:
        return t(`${i18nBase}.Seat.None`)

      case SeatReservationType.Mandatory:
        return t(`${i18nBase}.Seat.Mandatory`)

      case SeatReservationType.Optional:
        return isSeatRequested
          ? t(`${i18nBase}.Seat.Optional.WillReserved`)
          : t(`${i18nBase}.Seat.Optional.WillNotReserved`)
      default:
        return ''
    }
  }

  return (
    <Container>
      <Text isBlock variant="subHeadStrong" color="mainText">
        {fareConditions?.tier.name}
      </Text>
      {!!fareAttributes.length &&
        fareAttributes.map((item, index) => (
          <Block key={index} marginTop={4}>
            <Text variant="captionMedium" color="bodyText1">
              {item}
            </Text>
          </Block>
        ))}
      <Block marginTop={24}>
        <DetailRow>
          <Icon name="lieFlatSeatPWA" size="small" />
          <DetailColumn>
            <Text variant="captionStrong" color="mainText1">
              {seatPreferencesText()}
            </Text>
          </DetailColumn>
        </DetailRow>
        {isRefundable && (
          <DetailRow alignCenter>
            <Icon name="refundablePWA" size="small" />
            <Text variant="captionMedium" color="mainText1">
              {t(`${i18nBase}.Refundable`)}
            </Text>
          </DetailRow>
        )}
        {appliedRailCard && (
          <DetailRow alignCenter>
            <Icon name="loyaltyCardPWA" color="success" size={20} aria-label="" />
            <Text color="mainText1" variant="captionMedium">
              {appliedRailCard} {t(`${i18nBase}.Applied`)}
            </Text>
          </DetailRow>
        )}
        <DetailRow alignCenter>
          <Icon name="eticketPWA" size="small" />
          <Text variant="captionMedium" color="mainText1">
            {isRailEticket ? t(`${i18nBase}.Eticket`) : t(`${i18nBase}.Collect`)}
          </Text>
        </DetailRow>
      </Block>
    </Container>
  )
}
