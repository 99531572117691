import { useTranslation } from 'react-i18next'
import { RailDetailsHeader } from '@etta/components/rail-details/rail-details-header'
import { Modal } from '@etta/ui/modal'
import { RailDetailsEcoCheck } from '@etta/components/rail-details-eco-check/rail-details-eco-check'
import { Block } from '@etta/ui/block'
import { Icon } from '@etta/ui/icon'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { RailDetailsLegend } from '../../rail-details-legend'
import { RailTravelInformation } from '../../rail-travel-information'
import { RailDetailsPrices } from '../../rail-details-prices'
import {
  Container,
  Section,
  RailDetailsEcoCheckContainer,
  TicketRestrictionsWrapper,
  IconWrapper,
  RailDetailsSection,
} from './rail-details-modal-desktop-styled'

const i18nBase = 'RailDetails'

export function RailDetailsModalDesktop({
  headerTitle,
  carrierLogoUrl,
  informationText,
  railLeg,
  seatReservationType,
  onDetailsClick,
  isRailEticket,
  squareRailLogos,
  railEmission,
  isSeatRequested,
  appliedRailCard,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <>
      <Modal.Title withBorder>
        <RailDetailsHeader
          title={headerTitle}
          railLabels={informationText}
          railLogo={carrierLogoUrl}
          squareRailLogos={squareRailLogos}
        />
      </Modal.Title>
      <Modal.Body>
        <RailDetailsSection>
          <RailDetailsLegend rail={railLeg} />
        </RailDetailsSection>
        <Container>
          <Block paddingHorizontal={32}>
            <Section>
              <RailTravelInformation
                rail={railLeg}
                seatReservationType={seatReservationType}
                isRailEticket={isRailEticket}
                isSeatRequested={isSeatRequested}
                appliedRailCard={appliedRailCard}
              />
            </Section>
            {railEmission && (
              <RailDetailsEcoCheckContainer>
                <RailDetailsEcoCheck {...railEmission} />
              </RailDetailsEcoCheckContainer>
            )}
            <Section>
              <RailDetailsPrices
                rate={railLeg.rate}
                taxes={railLeg.taxes}
                totalCost={railLeg.totalCost}
                transactionFees={railLeg.transactionFees}
              />
            </Section>
            <Section>
              <TicketRestrictionsWrapper
                onClick={onDetailsClick}
                aria-label={t(i18nBase + '.RestrictionTicket.Title')}
                data-tracking-id={'ticket-restrictions-button'}>
                <Text variant="subHeadStrong" color="mainText">
                  {t(i18nBase + '.RestrictionTicket.Title')}
                </Text>
                <IconWrapper>
                  <Icon name="chevronRightPWA" size="small" />
                </IconWrapper>
              </TicketRestrictionsWrapper>
            </Section>
          </Block>
        </Container>
      </Modal.Body>
    </>
  )
}
