import type { RailLayoutType } from '@fiji/enums'
import type { SeatPreferences, SeatReservationType } from '@fiji/graphql/types'
import { ModalDetails } from '@etta/components/modal-details'
import type { Preferences } from './types'
import { TripReviewSeatPreferencesContent } from './trip-review-seat-preferences-content'

type Props = {
  isVisible: boolean
  onClose: () => void
  layout: RailLayoutType
  appliedPreferences: Preferences
  preferenceOptions: SeatPreferences
  seatReservationTypesArray?: SeatReservationType[]
  onPreferencesApply: (newPreferences: Preferences) => void
  isRoundTrip: boolean
  isFirstClass: boolean
  onSkip: () => void
}

export function TripReviewSeatPreferences({ isVisible, onClose, ...props }: Props) {
  return (
    <ModalDetails
      isVisible={isVisible}
      onClose={onClose}
      horizontalDimension="content-760"
      isAdaptivePosition>
      <TripReviewSeatPreferencesContent onClose={onClose} {...props} />
    </ModalDetails>
  )
}
