import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { RailDetailsLegendDesktop } from './desktop'
import { RailDetailsLegendMobile } from './mobile'

export function RailDetailsLegendLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<RailDetailsLegendMobile {...props} />}
      desktopSlot={<RailDetailsLegendDesktop {...props} />}
    />
  )
}
