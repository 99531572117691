import { Modal } from '@etta/ui/modal'

import { RailFiltersContent } from './rail-filters-content'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export function RailFilters({ isOpen, onClose }: Props) {
  return (
    <Modal isVisible={isOpen} handleModalVisibility={onClose}>
      <RailFiltersContent />
    </Modal>
  )
}
