import { createErrorClass } from "@etta/core/errors/create-error-class";

export namespace CreateCustomerUberAccountError {
  export const AccountAlreadyExistsCreateCustomerUberAccountError = createErrorClass('AccountAlreadyExistsCreateCustomerUberAccountError')

  export const NotEnabledCreateCustomerUberAccountError = createErrorClass('NotEnabledCreateCustomerUberAccountError')

  export const UnexpectedCreateCustomerUberAccountError = createErrorClass('UnexpectedCreateCustomerUberAccountError')
}

export type CreateCustomerUberAccountErrorInstances = InstanceType<
  typeof CreateCustomerUberAccountError[keyof typeof CreateCustomerUberAccountError]
  >
