import styled from 'styled-components'
import { Button as UIButton } from '@etta/ui/button'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 8px;
`

export const Elements = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

export const Button = styled(UIButton)`
  display: flex;
  margin-left: auto;
  width: 64px;
  & > * {
    display: flex;
    justify-content: center;
  }
`
