import styled from 'styled-components'
import { footnoteMedium } from '@fiji/style'

export const RoomDetailsText = styled.div`
  ${footnoteMedium};
  color: ${(props) => props.theme.colors.bodyText1};
  white-space: pre-line;

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`
