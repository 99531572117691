import styled from 'styled-components'

export const PriceWrapperOneRow = styled.div`
  display: flex;
  text-align: right;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`

export const PriceWrapperTwoRows = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`
