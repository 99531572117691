import { DataProvider } from '@etta/di'
import { GraphqlDataProvider } from '@etta/infra/data-providers/graphql.data-provider'
import type {
  SearchMobilityMultiModalQuery,
  SearchMobilityMultiModalQueryVariables,
} from '@fiji/graphql/hooks'
import { SearchMobilityMultiModalDocument } from '@fiji/graphql/hooks'

@DataProvider()
export class MultiModalSearchDataProvider extends GraphqlDataProvider {
  multiModalAbortController: AbortController | null = null

  search({ input }: SearchMobilityMultiModalQueryVariables) {
    const controller = new AbortController()
    this.multiModalAbortController?.abort()
    this.multiModalAbortController = controller

    return this.client.query<SearchMobilityMultiModalQuery, SearchMobilityMultiModalQueryVariables>(
      {
        query: SearchMobilityMultiModalDocument,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
        context: {
          queryDeduplication: false,
          fetchOptions: {
            controller: controller,
          },
        },
      },
    )
  }
}
