import type { IconNames } from '@etta/ui/icon'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import { Icon } from '@etta/ui/icon'
import { ItemWrapper, IconWrapper, TextBlock } from './item-block-styled'

type Props = {
  iconName: IconNames
  itemText: string
}

export function ItemBlock({ iconName, itemText }: Props) {
  return (
    <ItemWrapper>
      <Block>
        <IconWrapper>
          <Icon name={iconName} />
        </IconWrapper>
      </Block>
      <TextBlock marginLeft={20}>
        <Text variant="footnoteMedium" color="bodyText">
          {itemText}
        </Text>
      </TextBlock>
    </ItemWrapper>
  )
}
