import styled from 'styled-components'
import { subHeadStrong } from '@fiji/style'

import type { TextProps } from '@etta/ui/text'
import { Text } from '@etta/ui/text'

const SCALE_HEIGHT = '10px'
const POINTER_DIAMETER = '8px'
const POINTER_BORDER = '2px'
const POINTER_TIP_DIAMETER = '28px'
const POINTER_TIP_ARROW_HEIGHT = '8px'
const POINTER_TIP_ARROW_VERTICAL_OFFSET = '-3px'
const POINTER_TIP_ARROW_HALF_WIDTH = '4px'

export const ScaleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: calc(
    ${SCALE_HEIGHT} + ${POINTER_BORDER} + ${POINTER_TIP_DIAMETER} + ${POINTER_TIP_ARROW_HEIGHT} +
      ${POINTER_TIP_ARROW_VERTICAL_OFFSET}
  );
`

export const Scale = styled.div`
  position: relative;
  width: 100%;
  height: ${SCALE_HEIGHT};
  background-image: linear-gradient(
    90deg,
    #ca0f1c 0%,
    #ff9500 31.56%,
    #3da00f 69.58%,
    #337415 100%
  );
  border-radius: ${SCALE_HEIGHT};
  margin-bottom: calc(${POINTER_BORDER} / 2);
`

export const PointerTip = styled.div`
  position: absolute;
  bottom: calc(${SCALE_HEIGHT} + (${POINTER_BORDER} / 2));
  left: calc(
    (${POINTER_TIP_DIAMETER} / -2) + ((${POINTER_DIAMETER} + (${POINTER_BORDER} * 2)) / 2)
  );
  &::before {
    ${subHeadStrong};
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0.7;
    width: ${POINTER_TIP_DIAMETER};
    height: ${POINTER_TIP_DIAMETER};
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.mainText1};
    color: ${(p) => p.theme.colors.mainText2};
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin: ${POINTER_TIP_ARROW_VERTICAL_OFFSET} 0 0
      calc((${POINTER_TIP_DIAMETER} / 2) - ${POINTER_TIP_ARROW_HALF_WIDTH});
    border-style: solid;
    border-width: ${POINTER_TIP_ARROW_HEIGHT} ${POINTER_TIP_ARROW_HALF_WIDTH} 0
      ${POINTER_TIP_ARROW_HALF_WIDTH};
    border-color: ${(p) => p.theme.colors.mainText1} transparent transparent transparent;
  }
`

type PointerContainerProps = {
  progress: number
}

export const PointerContainer = styled.div<PointerContainerProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  > div {
    width: calc(100% - (${POINTER_DIAMETER} + ${POINTER_BORDER} + ${POINTER_BORDER}));
    transform: translateX(${(p) => p.progress}%);
  }
  > ${PointerTip}::before {
    content: '${(p) => p.progress}';
  }
`

export const Pointer = styled.div`
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: ${POINTER_DIAMETER};
    height: ${POINTER_DIAMETER};
    background-color: ${(p) => p.theme.colors.mainText};
    border: ${POINTER_BORDER} solid ${(p) => p.theme.colors.white};
    border-radius: 50%;
  }
`

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
`

export const Label = styled(Text).attrs<TextProps, TextProps>((p) => ({
  ...p,
  variant: 'footnoteStrong',
  color: 'bodyText',
}))``
