import { default as Generic } from './generic.svg'
import { default as Amex } from './amex.svg'
import { default as MasterCard } from './master-card.svg'
import { default as Visa } from './visa.svg'

const icons = {
  generic: Generic,
  amex: Amex,
  masterCard: MasterCard,
  visa: Visa,
}

type Props = {
  name: keyof typeof icons
}

export function Icon({ name }: Props) {
  const Icon = icons[name]
  return <Icon />
}
