import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '@fiji/routes'
import { TripType } from '@etta/core/enums/trip-type.enum'

const i18nBase = 'Trips'

const translatesMap = {
  [TripType.Upcoming]: 'Trips.Upcoming',
  [TripType.Past]: 'Trips.Past',
  [TripType.Cancelled]: 'Trips.Canceled',
  [TripType.OnHold]: 'Trips.Hold',
}

type Params = {
  tripType: TripType
}

export function useTripNoContent({ tripType }: Params) {
  const { t } = useTranslation()
  const history = useHistory()

  const handleRedirect = () => {
    history.push(ROUTES.explore)
  }

  const noContentInfoTranslation = useMemo(() => t(i18nBase + `.NoContentInfo`), [t])
  const tripTypeTranslation = useMemo(() => t(translatesMap[tripType]), [t, tripType])
  const tripsTranslation = useMemo(() => t(i18nBase + `.Trips`), [t])

  const descriptionAriaLabel = useMemo(
    () => [noContentInfoTranslation, tripTypeTranslation, tripsTranslation].join(' '),
    [noContentInfoTranslation, tripTypeTranslation, tripsTranslation],
  )

  const buttonLabel = t(i18nBase + '.Book')

  const buttonAriaLabelCaption = t(i18nBase + '.BookLabel', { label: t(i18nBase + '.Book') })

  return {
    noContentInfoTranslation,
    tripTypeTranslation,
    tripsTranslation,
    handleRedirect,
    descriptionAriaLabel,
    buttonLabel,
    buttonAriaLabelCaption,
  }
}
