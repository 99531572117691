import { useTranslation } from 'react-i18next'
import { Button } from '@etta/ui/button'
import { Block } from '@etta/ui/block'
import { Text } from '@etta/ui/text'
import type { LayoutProps } from '../types'
import { SeatIcon } from '../../../../../seat-icon'
import {
  Container,
  AlignCenter,
  SeatIconSmallWrapper,
  IconWrapper,
} from '../../../blocks-desktop-styles'
import { FinishSeatSelectionButton } from '../../../../finish-seat-selection-button'

export function BlockDefaultDesktop({
  title,
  seatNumber,
  isLoading,
  isSeatSelected,
  onSelect,
  finishAllSeatsSelection,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Container>
      <FinishSeatSelectionButton
        disabled={!isSeatSelected}
        onFinishSelectionClick={finishAllSeatsSelection}
      />
      <AlignCenter>
        {isSeatSelected && (
          <Block marginRight={24} isFlexBlock>
            <Block marginRight={4}>
              <IconWrapper withBackgroundColor>
                <SeatIconSmallWrapper>
                  <SeatIcon icon="seat" />
                </SeatIconSmallWrapper>
              </IconWrapper>
            </Block>
            <Text variant="headline" color="mainText">
              {title}
            </Text>
          </Block>
        )}
        <Button
          size="normal"
          minWidth={13.6}
          onClick={onSelect}
          disabled={isLoading || !isSeatSelected}
          data-tracking-id={`select-seat-${seatNumber}-button`}>
          {t('AirSeatMap.Continue')}
        </Button>
      </AlignCenter>
    </Container>
  )
}
