import type { Money } from '@fiji/graphql/types'

type Options = {
  isInteger?: boolean
}

export const formatMoneyRTP = (locale: string, money: Money, options?: Options) => {
  const { isInteger } = options || {}
  const params = {
    ...(isInteger && { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: money.currency,
    ...params,
  }).format(money.amount)
}
