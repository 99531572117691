import { observer } from 'mobx-react-lite'
import type { AmenityFilter } from '@fiji/graphql/types'
import { Checkbox } from '@etta/ui/checkbox'
import { useHotelSearchResultsContext } from '@etta/modules/hotel-search/interface/use-hotel-search-results.context'
import { FilterSelectionRow } from '../../filters-styled'
import { Container } from './amenities-multiple-items-styled'

export type Props = {
  options: AmenityFilter[]
  withBorder?: boolean
  withSpace?: boolean
  isGridLayout?: boolean
}

const ROW_VIEW_MAX_ITEMS = 4

export const AmenitiesMultipleItems = observer(function AmenitiesMultipleItems({
  options,
  withBorder = false,
  withSpace = false,
  isGridLayout = false,
}: Props) {
  const { hotelFiltersAction, hotelFiltersStore } = useHotelSearchResultsContext()

  return (
    <>
      {options.map((option) => {
        return (
          <Container
            key={option.amenityId}
            isGridLayout={isGridLayout && options.length > ROW_VIEW_MAX_ITEMS}>
            <FilterSelectionRow withBorder={withBorder} withSpace={withSpace}>
              <Checkbox
                data-tracking-id="amenity-filter"
                value={option.amenityId}
                label={option.amenityDisplayName}
                checked={hotelFiltersStore.isAmenityIdsSelected(option.amenityId)}
                onChange={() => hotelFiltersAction.handleSetFilter('amenityIds', option.amenityId)}
              />
            </FilterSelectionRow>
          </Container>
        )
      })}
    </>
  )
})
