import styled from 'styled-components'
import { Icon } from '@etta/ui/icon'
import { headline, footnoteMedium, subHeadStrong, subHeadMedium } from '@fiji/style'

const descriptionTextColor = '#656b72'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
`

export const HeaderTitle = styled.p`
  ${headline}
  padding: 0;
  margin: 0;
`

export const HeaderDescription = styled.p`
  padding: 0;
  margin: 0;
  ${footnoteMedium};
  color: ${descriptionTextColor};
`
export const HeaderTextContainer = styled.div`
  padding-left: 14px;
`

export const BodyContainer = styled.div`
  padding: 10px 20px;
`

export const BodyBlock = styled.div`
  padding-bottom: 10px;
`

export const BlockTitle = styled.p`
  ${subHeadStrong}
  margin: 10px 0;
`

export const BlockDescription = styled.p`
  ${subHeadMedium}
  padding: 0;
  margin: 0;
  color: ${descriptionTextColor};
`

export const DescriptionLink = styled.a`
  color: ${(props) => props.theme.btnBackground};
`

export const UberLogo = styled(Icon)`
  height: 48px;
  width: 48px;
`
