import { observer } from 'mobx-react-lite'
import { MediaLayout } from '@etta/ui/media-layout'
import type { Props } from '../types'
import { AirSearchFlightMobile } from './mobile'
import { AirSearchFlightDesktopNew } from './desktop/air-search-flight-desktop.new'

export const AirSearchFlightLayout = observer(function AirSearchFlightLayout(props: Props) {
  return (
    <MediaLayout
      mobileSlot={<AirSearchFlightMobile {...props} />}
      desktopSlot={<AirSearchFlightDesktopNew {...props} />}
    />
  )
})
