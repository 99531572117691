import { RoomOutOfPolicy } from '@etta/components/hotel-room/room-out-of-policy'
import { RoomBadges } from '@etta/components/hotel-room/room-badges'
import { Price } from '@etta/components/hotel-room/price'
import { Description } from '@etta/components/hotel-room/description'
import { Cancellation } from '@etta/components/hotel-room/cancellation'
import { RoomTypeHeader } from '@etta/components/hotel-room/room-type-header'
import type { LayoutProp } from '../types'
import {
  Container,
  Content,
  ContentRow,
  Header,
  HeaderLeftSection,
  HeaderRightSection,
  PriceSection,
} from './hotel-room-desktop-styled'
import { HotelRateButtonDesktop } from './hotel-rate-button-desktop'

export function HotelRoomDesktop({
  submitButtonSlot,
  room,
  ratesLinkSlot,
  'data-tracking-id': dataTrackingId,
  showDifferingRatesWarning,
}: LayoutProp) {
  const {
    policy,
    cancellationPolicy,
    preferredTag,
    rateQualifier,
    description,
    cancellationPolicyDescription,
    averageRate,
    type,
    source,
    dailyRates,
  } = room
  const { isInPolicy, outOfPolicyReasons, outOfPolicyData } = policy

  return (
    <Container data-tracking-id={dataTrackingId}>
      <Header>
        <HeaderLeftSection>
          <RoomTypeHeader rateQualifier={rateQualifier} roomType={type} source={source} />
        </HeaderLeftSection>
        <HeaderRightSection>
          <PriceSection>
            <Price averageRate={averageRate} dailyRates={dailyRates} skin="one-row" />
          </PriceSection>
          {submitButtonSlot}
        </HeaderRightSection>
      </Header>
      <Content>
        {!isInPolicy && (
          <ContentRow>
            <RoomOutOfPolicy
              outOfPolicyReasons={outOfPolicyReasons}
              outOfPolicyData={outOfPolicyData}
              rightSlot={
                <HotelRateButtonDesktop
                  ratesLinkSlot={ratesLinkSlot}
                  showDifferingRatesWarning={showDifferingRatesWarning}
                />
              }
              mainTextColor="mainText"
            />
          </ContentRow>
        )}
        <ContentRow>
          <RoomBadges
            isFilled
            preferredTag={preferredTag}
            rateQualifier={rateQualifier}
            cancellationPolicy={cancellationPolicy}
          />
          {isInPolicy && (
            <HotelRateButtonDesktop
              ratesLinkSlot={ratesLinkSlot}
              showDifferingRatesWarning={showDifferingRatesWarning}
            />
          )}
        </ContentRow>
        <ContentRow>
          <Cancellation
            cancellationPolicy={cancellationPolicy}
            cancellationPolicyDescription={cancellationPolicyDescription}
          />
        </ContentRow>
        <ContentRow>
          <Description description={description} isUpperCase />
        </ContentRow>
      </Content>
    </Container>
  )
}
