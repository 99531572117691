import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import { TripReviewDateType, tripReviewFormatDate } from '@fiji/utils/dates/trip-review-format-date'
import { getNonRailTransferIconNames } from '@fiji/utils/get-non-rail-transfer-icon-names'
import { screenSize } from '@fiji/style'
import { TrainLabel, TrainServiceClass } from '@fiji/enums'
import { useActualRailEmissions } from '@fiji/hooks/sustainability'
import { useRailContext } from '@etta/modules/rail/use-rail.context'
import type { SearchRailLegEntity } from '@etta/modules/rail/core/entities/search-rail-leg.entity'
import { RailTransportationModeEnum } from '@etta/modules/rail/core/enums/rail-transportation-mode.enum'
import { useHeaderTitle, useRailwayImages } from '.'

const i18Base = 'RailDetails'

export function useRailSearchSegment(segment: SearchRailLegEntity) {
  const { t } = useTranslation()
  const isSmallMobile = useMedia(`only screen and (${screenSize.maxMobileSmall})`)

  const {
    segments,
    departureDate,
    standardClassFares,
    firstClassFares,
    arrivalDate,
    railTime,
    daysInBetween,
    rate,
  } = segment

  const fares = [...standardClassFares, ...firstClassFares]

  const railwayCarriersInfo = useRailwayImages(segments)
  const headerTitle = useHeaderTitle(railwayCarriersInfo, segments)
  const start = tripReviewFormatDate(TripReviewDateType.TimePWA, departureDate) as string
  const end = tripReviewFormatDate(TripReviewDateType.TimePWA, arrivalDate) as string
  const tripTime = `
      ${railTime.hours ? `${railTime.hours}h` : ''} ${
    railTime.minutes !== 0 ? `${railTime.minutes}m` : ''
  }
  `
  const travelChangesNumber = segments.length - 1
  const travelChangesLabel =
    segments.length > 1
      ? t(i18Base + '.Change', { count: travelChangesNumber })
      : t(i18Base + '.Stops.Nonstop')

  const changeStations = segments
    .map((segment, index) => {
      if (index === segments.length - 1) {
        return ''
      }
      return segment.destination.name
    })
    .filter(Boolean)

  const isSoldOut = ![...standardClassFares, ...firstClassFares].length
  const soldOutLabel = t(i18Base + '.Status.SoldOut.Title')

  const segmentPrice = rate?.secondary ?? rate?.primary ?? null
  const firstClassPriceTotal = firstClassFares.find(
    (item) =>
      item?.segments && item.segments[0]?.displayServiceClass === TrainServiceClass.FirstClass,
  )?.total
  const firstClassPrice = firstClassPriceTotal?.secondary ?? firstClassPriceTotal?.primary ?? null
  const fare = fares.find((_fare) =>
    _fare?.segments?.some((_segments) => _segments.segmentId === segments[0].id),
  )
  const rateId = `rail-search-rate-${headerTitle.replace(/\s/g, '')}`
  const isPreferred = segments.every((segment) => segment.isPreferredAny)
  const isOutOfPolicy = !segment.policy.isInPolicy

  const segmentLabel = t(i18Base + '.SegmentLabel')

  const uniqueTransportTypes = useMemo(
    () =>
      [...new Set(segments.map((segment) => segment.transportationMode))].filter(
        (item) =>
          item &&
          [
            RailTransportationModeEnum.Bus,
            RailTransportationModeEnum.Ferry,
            RailTransportationModeEnum.Tram,
            RailTransportationModeEnum.Unspecified,
          ].includes(item),
      ),
    [segments],
  )

  const uniqueTransportIconNames = uniqueTransportTypes.map((item) =>
    getNonRailTransferIconNames(item),
  )

  const { railEmissionStore } = useRailContext()

  const actualRailEmission = useActualRailEmissions(
    segment.legId,
    railEmissionStore.railEmissions ?? undefined,
  )

  const isCheapest = segment.labels?.includes(TrainLabel.Cheapest)
  const isFastest = segment.labels?.includes(TrainLabel.Fastest)

  const standardClassLabel = t(i18Base + '.Fare.Standard')
  const firstClassLabel = t(i18Base + '.Fare.FirstClass')
  const applicableRailCard = fares.find((fare) => fare.appliedRailCards.length > 0)
    ?.appliedRailCards?.[0]

  return {
    start,
    end,
    tripTime,
    travelChangesNumber,
    travelChangesLabel,
    daysInBetween,
    railwayCarriersInfo,
    fare,
    isPreferred,
    isOutOfPolicy,
    isSmallMobile,
    rateId,
    segmentPrice,
    segmentLabel,
    firstClassPrice,
    soldOutLabel,
    isSoldOut,
    uniqueTransportIconNames,
    actualRailEmission,
    isCheapest,
    isFastest,
    standardClassFares,
    firstClassFares,
    standardClassLabel,
    firstClassLabel,
    changeStations,
    applicableRailCard,
  }
}

export type UseRailSearchSegmentReturnType = ReturnType<typeof useRailSearchSegment>
