import { useTranslation } from 'react-i18next'
import { useScreenType, ScreenType } from '@fiji/modes'
import { Text } from '@etta/ui/text'
import type { Payment } from '../../../../types'
import { PaymentDetails, PaymentWrapper } from './payment-block-styled'

type Props = {
  payment: Payment
  isRailPayment?: boolean
}

export function PaymentBlock({ payment, isRailPayment }: Props) {
  const { title, card, secondaryTotal, mainTotal } = payment
  const screenType = useScreenType()
  const isMobile = screenType === ScreenType.Mobile
  const textVariant = isMobile ? 'subHeadStrong' : 'headline'

  const { t } = useTranslation()
  const i18Base = 'PostBooking.TripDetails.'

  return (
    <PaymentWrapper>
      <PaymentDetails>
        <Text variant={textVariant} color="mainText">
          {title}
        </Text>
        {card && (
          <Text variant="footnoteMedium" color="bodyText">
            {isRailPayment ? t(i18Base + 'OnAccount') : card}
          </Text>
        )}
      </PaymentDetails>
      <PaymentDetails alightRight>
        <Text variant={textVariant} color="mainText">
          {mainTotal}
        </Text>
        {secondaryTotal && (
          <Text variant="footnoteMedium" color="bodyText1">
            {secondaryTotal}
          </Text>
        )}
      </PaymentDetails>
    </PaymentWrapper>
  )
}
