import { Result } from 'fnscript'
import { Adapter, Inject } from '@etta/di'
import { CancelRideHailErrorReason } from '@fiji/graphql/hooks'
import { PostBookingErrors } from '../../core/errors'
import type { PostBookingAdapterResult } from '../types'
import type { TripEntity } from '../../core/entities/trip.entity'
import { GazooPostBookingDataProvider } from './gazoo-post-booking.data-provider/gazoo-post-booking.data-provider'
import type {
  CancelRideHailArgs,
  CancelTripSegmentArgs,
  ChangeFlightSeatsArgs,
  GetTripArgs,
} from './types'
import { TripMapper } from './mappers'

@Adapter()
export class GazooPostBookingAdapter {
  constructor(
    @Inject()
    private gazooPostBookingDataProvider: GazooPostBookingDataProvider,
  ) {}

  cancelGetTripRequest() {
    this.gazooPostBookingDataProvider.cancelGetTripRequest()
  }

  async getTrip(
    { id, cityImageFormat, companyId, isCityImageRequered, transactionGroupId, type }: GetTripArgs,
    forceUpdate?: boolean,
  ): PostBookingAdapterResult<TripEntity> {
    try {
      const { data, error } = await this.gazooPostBookingDataProvider.getTrip(
        {
          id,
          transactionGroupId: transactionGroupId ?? 'null',
          cityImageFormat,
          companyId,
          isCityImageRequered,
          type,
        },
        forceUpdate,
      )
      if (error) {
        return Result.Err(new PostBookingErrors.GetTripError(error))
      }
      return Result.Ok(TripMapper.toTripEntity({ trip: data.trip, processId: id }))
    } catch (e) {
      return Result.Err(new PostBookingErrors.GetTripUnexpectedError(e))
    }
  }

  async cancelTrip(tripId: number): PostBookingAdapterResult<{ success: boolean }> {
    try {
      const { data } = await this.gazooPostBookingDataProvider.cancelTrip({ tripId })
      const errorCodes = data?.cancelTrip.errorCodes || []
      if (errorCodes.some((errorCode) => errorCode === 'ride-hail-cancelations-failed')) {
        return Result.Err(
          new PostBookingErrors.CancelRideHailErrorInWholeTripCancel(data?.cancelTrip.message),
        )
      }
      if (!data?.cancelTrip.success) {
        return Result.Err(new PostBookingErrors.CancelTripError(data?.cancelTrip.message))
      }

      return Result.Ok({ success: data.cancelTrip.success })
    } catch (e) {
      return Result.Err(new PostBookingErrors.CancelTripUnexpectedError(e))
    }
  }

  async cancelRideHail({
    bookings,
  }: CancelRideHailArgs): PostBookingAdapterResult<{ success: boolean }> {
    try {
      const { data, errors } = await this.gazooPostBookingDataProvider.cancelRideHail({ bookings })

      if (errors || !data) {
        return Result.Err(
          new PostBookingErrors.CancelRideHailError('Ride Hail cancellation failed'),
        )
      }

      if (data?.cancelRideHailWithError.__typename === 'CancelRideHailErrorResponse') {
        switch (data.cancelRideHailWithError.reason) {
          case CancelRideHailErrorReason.TripCompleted:
            return Result.Err(
              new PostBookingErrors.CancelRideHailAlreadyCompletedError(
                data.cancelRideHailWithError.reason,
              ),
            )
          case CancelRideHailErrorReason.Unknown:
            return Result.Err(
              new PostBookingErrors.CancelRideHailError(data.cancelRideHailWithError.reason),
            )
        }
      }

      if (data?.cancelRideHailWithError.__typename === 'UnexpectedError') {
        throw data.cancelRideHailWithError.__typename
      }

      return Result.Ok({ success: true })
    } catch (e) {
      return Result.Err(new PostBookingErrors.CancelRideHailUnexpectedError(e))
    }
  }

  async cancelTripSegment({
    transactionId,
    carRentalKeys,
    flightKeys,
    hotelKeys,
  }: CancelTripSegmentArgs): PostBookingAdapterResult<{ success: boolean }> {
    try {
      const { data } = await this.gazooPostBookingDataProvider.cancelTripSegment({
        transactionId,
        carRentalKeys,
        flightKeys,
        hotelKeys,
      })

      if (!data?.cancelTripSegment.success) {
        return Result.Err(
          new PostBookingErrors.CancelTripSegmentError(data?.cancelTripSegment.message),
        )
      }
      return Result.Ok({ success: data.cancelTripSegment.success })
    } catch (e) {
      return Result.Err(new PostBookingErrors.CancelTripSegmentUnexpectedError(e))
    }
  }

  async changeFlightSeats(
    input: ChangeFlightSeatsArgs,
  ): PostBookingAdapterResult<{ message: string }> {
    try {
      const { data } = await this.gazooPostBookingDataProvider.changeFlightSeats(input)

      if (!data?.changeFlightSeats.success) {
        return Result.Err(new PostBookingErrors.ChangeFlightSeatsError('Failed to change seats'))
      }

      return Result.Ok(data?.changeFlightSeats)
    } catch (e) {
      return Result.Err(new PostBookingErrors.UnexpectedErrorChangeFlightSeats(e))
    }
  }
}
