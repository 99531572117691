import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  &:first-child {
    margin-top: 16px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 16px;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
`
