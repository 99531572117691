import type { Props } from './types'
import { useTripErrorsModal } from './use-trip-errors-modal'
import { DuplicateTripsModalContentLayout } from './layout'

export function DuplicateTripsModalContent({
  onApply,
  onClose,
  errors,
  overlappingTrips,
}: Omit<Props, 'isOpen'>) {
  const { headerTitle, handleChangeApply, isApplied } = useTripErrorsModal()

  return (
    <DuplicateTripsModalContentLayout
      onApply={onApply}
      onClose={onClose}
      overlappingTrips={overlappingTrips}
      errors={errors}
      headerTitle={headerTitle}
      toggleApplied={handleChangeApply}
      isApplied={isApplied}
    />
  )
}
