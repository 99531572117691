import { hookContextFactory } from '@etta/interface/services/hook-context-factory'
import { ExpenseFlowStore } from '@etta/modules/ride-hail/interface/store/expense-flow.store'
import { GetCustomerUberAccountStatusStore } from './store/get-customer-uber-account-status.store'
import { GetCustomerUberAccountStatusActions } from './actions/get-customer-uber-account-status.actions'
import { CreateCustomerUberAccountActions } from './actions/create-customer-uber-account.actions'
import { CreateCustomerUberAccountStore } from './store/create-customer-uber-account.store'
import { AddExpenseCodeMemoActions } from './actions/add-expense-code-memo.actions'
import { SelectExpenseCodeActions } from './actions/select-expense-code.actions'
import { RideHailSearchLocationRefinementStore } from './store/ride-hail-search-location-refinement.store'
import { RideHailSearchLocationRefinementActions } from './actions/ride-hail-search-location-refinement.actions'
import { RideHailSearchItineraryStore } from './store/ride-hail-search-itinerary.store'
import { RideHailSearchItineraryActions } from './actions/ride-hail-search-itinerary.actions'
import { SearchRideHailStore } from './store/search-ride-hail.store'
import { RideHailBookStore } from './store/ride-hail-book.store'

import { SearchRideHailActions } from './actions/search-ride-hail.actions'
import { RideHailBookActions } from './actions/ride-hail-book.actions'
import { SelectExpenseCodeStore } from './store/select-expense-code.store'
import { AddExpenseCodeMemoStore } from './store/add-expense-code-memo.store'
import { EnterCustomExpenseCodeActions } from './actions/enter-custom-expense-code.actions'
import { EnterCustomExpenseCodeStore } from './store/enter-custom-expense-code.store'
import { RideHailAirportPickupActions } from './actions/ride-hail-airport-pickup-actions'
import { RideHailAirportPickupStore } from './store/ride-hail-airport-pickup-store'
import { SuggestionActions } from './actions/suggestion.actions'
import { SuggestionStore } from './store/suggestion.store'
import { ConfigurationStore } from './store/configuration.store'
import { RideHailSearchOnBookTabActions } from './actions/ride-hail-search-on-book-tab.actions'
import { RideHailSearchOnBookTabStore } from './store/ride-hail-search-on-book-tab.store'

export const { useModuleContext: useRideHailSearchContext } = hookContextFactory({
  rideHailBookStore: RideHailBookStore,
  rideHailBookActions: RideHailBookActions,
  rideHailSearchLocationRefinementStore: RideHailSearchLocationRefinementStore,
  rideHailSearchLocationRefinementActions: RideHailSearchLocationRefinementActions,
  rideHailSearchItineraryStore: RideHailSearchItineraryStore,
  rideHailSearchItineraryActions: RideHailSearchItineraryActions,
  rideHailAirportPickupStore: RideHailAirportPickupStore,
  rideHailAirportPickupActions: RideHailAirportPickupActions,
  searchRideHailStore: SearchRideHailStore,
  searchRideHailActions: SearchRideHailActions,
  selectExpenseCodeStore: SelectExpenseCodeStore,
  selectExpenseCodeActions: SelectExpenseCodeActions,
  addExpenseCodeMemoActions: AddExpenseCodeMemoActions,
  addExpenseCodeMemoStore: AddExpenseCodeMemoStore,
  enterCustomExpenseCodeActions: EnterCustomExpenseCodeActions,
  enterCustomExpenseCodeStore: EnterCustomExpenseCodeStore,
  expenseFlowStore: ExpenseFlowStore,
  suggestionActions: SuggestionActions,
  suggestionStore: SuggestionStore,
  createCustomerUberAccountStore: CreateCustomerUberAccountStore,
  createCustomerUberAccountActions: CreateCustomerUberAccountActions,
  getCustomerUberAccountStatusStore: GetCustomerUberAccountStatusStore,
  getCustomerUberAccountStatusActions: GetCustomerUberAccountStatusActions,
  configurationStore: ConfigurationStore,
  rideHailSearchOnBookTabActions: RideHailSearchOnBookTabActions,
  rideHailSearchOnBookTabStore: RideHailSearchOnBookTabStore,
})
