import type { ReferencePoint } from '@fiji/graphql/types'

export function uniqueReferencePoints(referencePoints: ReferencePoint[]): ReferencePoint[] {
  const flags: Set<string> = new Set()
  return referencePoints.filter((point) => {
    if (flags.has(point.label)) {
      return false
    }
    flags.add(point.label)
    return true
  })
}
