import { useTranslation } from 'react-i18next'
import { useDateRangeTitle } from '@fiji/hooks/use-date-range-title'
import { useHeaderScroll } from '@fiji/hooks/use-header-scroll/use-header-scroll'
import { getDifferenceInFullDaysBetweenStartAndEndIsoStrings } from '@fiji/utils/dates/get-difference-in-full-days-between-start-and-end-Iso-strings'
import { getTripDetailsSegmentBackground } from '@fiji/utils/trip-details/get-trip-details-segment-background'
import { TripStatus } from '@etta/modules/post-booking/core/enums'
import type { HeaderColors } from '@etta/ui/header'

type Params = {
  tripType: TripStatus
  hotelStatus?: TripStatus | null
  checkIn: string
  checkOut: string
}

const i18nBase = 'PostBooking.HotelDetails'

export function useTripHotelContentDesktop({ tripType, hotelStatus, checkIn, checkOut }: Params) {
  const { t } = useTranslation()

  const titleDate = useDateRangeTitle(checkIn, checkOut)
  const totalDays = getDifferenceInFullDaysBetweenStartAndEndIsoStrings(checkIn, checkOut)
  const dateText = `${titleDate} (${totalDays && t(`${i18nBase}.Nights`, { count: totalDays })})`

  const { scrollContainerRef, headerRef, anchorRef } = useHeaderScroll()

  const isCancelled = tripType === TripStatus.Cancelled
  const isUpcoming = tripType === TripStatus.Reserved
  const isCompleted = tripType === TripStatus.Completed

  const headerColor: HeaderColors = getTripDetailsSegmentBackground({
    tripStatus: tripType,
    segmentStatus: hotelStatus,
  })

  const closeIconAriaLabel = t('Accessibility.Header.CloseLabel')

  return {
    scrollContainerRef,
    headerRef,
    anchorRef,
    isCancelled,
    isUpcoming,
    isCompleted,
    headerColor,
    dateText,
    closeIconAriaLabel,
  }
}
