import { useHistory } from 'react-router-dom'
import { useGetTravelPolicyListQuery } from '@fiji/graphql/hooks'
import { useRefetchRequest } from '@fiji/hooks/use-refetch-request'

export const useTravelPolicyList = () => {
  const { data, loading, error, refetch } = useGetTravelPolicyListQuery({
    notifyOnNetworkStatusChange: true,
  })
  const history = useHistory()

  const onFail = () => {
    history.goBack()
  }

  const { handleRefetchRequest } = useRefetchRequest({
    refetch,
    loading,
    onFail,
  })

  return {
    data,
    loading,
    error,
    handleRefetchRequest,
  }
}
