import styled from 'styled-components'
import { footnoteStrong } from '@fiji/style'
import { Button } from '@etta/ui/button'

export const Container = styled(Button)`
  border-radius: 10px;
  height: 72px;
  padding: 17px 15px;

  & > div:first-child {
    margin-right: 0;
  }
  & > div:nth-child(2) {
    flex: none;
  }
  & > div:last-child {
    margin-left: auto;
  }
`

export const CardIconContainer = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.mainText};
  ${footnoteStrong};
  margin-left: 16px;
`
