import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 0px 14px 0px;
  overflow: hidden;

  &:not(:last-of-type) {
    border-bottom: 1px dashed ${(props) => props.theme.colors.borderLight};
  }
`
