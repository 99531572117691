import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { LocationPicker as EttaLocationPicker } from '@etta/components/location-picker'
import { Search } from '@etta/ui/search'
import type { LayoutProps } from '../../types'

const i18Base = 'TripPlanner.BaseSearch'

export const LocationSectionDesktop = observer(function LocationSectionDesktop({
  location,
  onChange,
  placesHistory,
  validationErrors,
}: LayoutProps) {
  const { t } = useTranslation()

  return (
    <Search.Group>
      <EttaLocationPicker
        recentSearchesType="hotel"
        onChange={onChange}
        value={location}
        latestPlaces={placesHistory}
        searchType="hotel"
        label={t(`${i18Base}.Input.Location`)}
        isOutboundTrip
        hasCurrentLocationSearch
        data-tracking-id="hotel-location-search"
        inputSlot={
          <Search.Element
            labelTitle={t(`${i18Base}.Input.Location`)}
            value={location?.airportCode || location?.name}
            shouldTruncateValueAt={45}
            errorMessage={validationErrors.location}
          />
        }
      />
    </Search.Group>
  )
})
