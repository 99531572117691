import { Store } from '@etta/di'
import { Toggle } from '@etta/interface/services/toggle'
import { DEFAULT_LOCALE } from '@fiji/constants'
import type { PersonalInfo } from '@fiji/types'
import type { UserEntity } from '../../core/entities'
import { RegistrationStatus } from '../../core/enums/registration-status.enum'
import { AccountCreationSource } from '../../core/enums/account-creation-source.enum'
import { getUserName } from './get-user-name'

@Store()
export class UserStore {
  guestPersonalInfo: PersonalInfo | null = null

  isUserLoading = false

  isUserError = false

  isUserUpdating = false

  isUserUpdatingError = false

  user: UserEntity | null = null

  toggleActivateAccountModal = new Toggle()

  get profile() {
    return this.user?.profile
  }

  get isGuest() {
    return this.user?.profile?.isGuest ?? false
  }

  get locale() {
    return this.user?.profile?.locale || DEFAULT_LOCALE
  }

  get customerId() {
    return this.user?.customerId
  }

  get partnerId() {
    return this.user?.partnerId
  }

  get companyId() {
    return this.user?.companyId
  }

  get delegatedId() {
    return this.user?.delegatedId
  }

  get meetingId() {
    return this.user?.meetingId
  }

  setMeetingId(meetingId: string) {
    if (this.user) {
      this.user.meetingId = meetingId
    }
  }

  get impersonatedId() {
    return this.user?.impersonatedId
  }

  get isDelegatedOrImpersonated() {
    return !!(this.user?.delegatedId || this.user?.impersonatedId)
  }

  get userNames() {
    const { fullName, initials } = getUserName({
      firstName: this.user?.profile?.firstName,
      middleName: this.user?.profile?.middleName,
      lastName: this.user?.profile?.lastName,
    })

    return { fullName, initials }
  }

  get guestUserNames() {
    const { fullName, initials } = getUserName({
      firstName: this.guestPersonalInfo?.firstName,
      lastName: this.guestPersonalInfo?.lastName,
    })

    return { fullName, initials }
  }

  get userCommonData() {
    const { companyId, customerId, delegatedId } = this.user || {}
    const { lastName, firstName } = this.user?.profile || {}
    return {
      companyId,
      customerId,
      delegatedId,
      lastName,
      firstName,
    }
  }

  get userWelcomeMessage() {
    return this.user?.profile?.pwaWelcomeMessage
  }

  get isSelfServiceSite() {
    return !!this.user?.profile?.site?.isSelfServiceSite
  }

  get hasGuestPersonalInfo() {
    return this.isGuest && !!this.guestPersonalInfo
  }

  get isActivationRequired() {
    return (
      this.user?.profile?.registrationStatus !== RegistrationStatus.Completed && this.isRegularUser
    )
  }

  get isResetPassword() {
    return this.user?.profile?.forceNewPassword && this.isRegularUser
  }

  get isRegularUser() {
    return this.user?.delegatedId === 0 && this.user?.impersonatedId === 0 && !this.isGuest
  }

  get isOpenEnrollmentAccount() {
    return this.user?.profile?.creationSource === AccountCreationSource.OpenEnrollment
  }

  setGuestPersonalInfo(personalInfo: PersonalInfo | null) {
    this.guestPersonalInfo = personalInfo
  }

  setIsLoading(isLoading: boolean) {
    this.isUserLoading = isLoading
  }

  setIsError(isError: boolean) {
    this.isUserError = isError
  }

  setIsUpdating(isUpdating: boolean) {
    this.isUserUpdating = isUpdating
  }

  setIsUpdatingError(isUpdatingError: boolean) {
    this.isUserUpdatingError = isUpdatingError
  }

  setUser(user: UserEntity | null) {
    this.user = user
  }

  setShouldShowWelcomeMessage(shouldShowWelcomeMessage: boolean) {
    if (!this.user?.profile) {
      return
    }

    this.user.profile.pwaWelcomeMessage = {
      shouldShowWelcomeMessage,
    }
  }

  dropGuestInformation() {
    this.guestPersonalInfo = null
  }
}
