import { useTranslation } from 'react-i18next'
import { Text } from '@etta/ui/text'
import type { LayoutControlsFooterProps } from '../../../types'
import {
  Container,
  MainRow,
  DoneButton,
  ContentHeader,
} from './controls-footer-layout.desktop.styled'

const i18nBaseAria = 'Accessibility.Settings'
const i18nBaseMeeting = 'Meeting.DateLegend'
const i18nBaseDatePicker = 'DatePicker'

export function ControlsFooterLayoutDesktop({ legendLabel, onClose }: LayoutControlsFooterProps) {
  const { t } = useTranslation()

  function handleCloseDatePicker() {
    onClose?.()
  }

  return (
    <Container>
      <MainRow>
        <ContentHeader>
          <Text variant="subHeadStrong" color="mainText">
            {t(`${i18nBaseMeeting}.Prefix`)}
          </Text>
          <Text variant="subHeadMedium" color="bodyText">
            {t(`${i18nBaseMeeting}.Between`)}
          </Text>
          <Text variant="subHeadStrong" color="mainText">
            {t(`${i18nBaseMeeting}.To`, legendLabel)}
          </Text>
        </ContentHeader>
        <DoneButton
          variant="solid"
          size="small"
          minWidth={7.3}
          onClick={handleCloseDatePicker}
          aria-label={t(`${i18nBaseAria}.Button`, {
            label: t(`${i18nBaseDatePicker}.Done`),
          })}>
          {t(`${i18nBaseDatePicker}.Done`)}
        </DoneButton>
      </MainRow>
    </Container>
  )
}
